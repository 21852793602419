import { GenerateInternalTaskDataObject } from '../CreateTask/FormatData';
import {
  formatCommentList,
  formatCurrentUserSubTaskStatusDetails,
  formatEditInternalTask,
  formatSubTaskStatusArray,
} from '../SagaTransformers/formatInternalTaskData';
import { taskDetailsActions } from '../store/Tasks.redux';

export const setEntityStickChoosen = (entity, dispatch: any) => {
  dispatch(
    taskDetailsActions.setTaskStickChosen({
      entity: entity,
      stick: null,
    }),
  );
};

export const formatCommentData = (comments, updatedSubTask, userInfo) => {
  const subTaskStatusList = formatSubTaskStatusArray(comments);
  const currentUserSubTaskStatusDetails = formatCurrentUserSubTaskStatusDetails(comments, userInfo?.userInfo);
  const availableComments = formatCommentList(comments, subTaskStatusList, currentUserSubTaskStatusDetails);
  const availableSubtask = availableComments?.find(comment => comment.taskId === updatedSubTask?.taskId);
  return { availableComments, availableSubtask };
};

export const formatTaskDetailUpdateData = (data, initialTask) => {
  const latestData = GenerateInternalTaskDataObject(data);
  const checkInitialTask = formatEditInternalTask(latestData, initialTask);
  return { latestData, checkInitialTask };
};
