import React from 'react';
import { Grid } from "amn-ui-core"
import { useSelector } from "react-redux";
import { selectRequestedCoverage } from 'store/redux-store/user-coverage/selectors';

export const RequestedCoverage = () => {
    const requestedCoverage = useSelector(selectRequestedCoverage);
    return (
        <>
            {requestedCoverage?.map(request => {(
                <>
                    <Grid>
                        {'user: ' + {...request.requestedBy}}
                    </Grid>
                    <Grid>
                        {'date range: ' + request.dateRange}
                    </Grid>
                    <Grid>
                        {'prefs: ' + request.preferences}
                    </Grid>
                    <Grid>
                        {'urgent?: ' + request.urgent}
                    </Grid>
                </>
            )})}
        </>
    );
};