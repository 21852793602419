import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEditableRequirementTypes } from '../../../../../../../../store/redux-store/placement-summary/selectors';
import { Requirements } from 'app/models/Placement/PlacementRequirements';
import { placementSummaryActions } from '../../../../../../../../store/redux-store/placement-summary/slice';
import { useEditableRequirementSSN } from './EditableRequirements/SSNRequirement';
import { useEditableRequirementDatesConfirmed } from './EditableRequirements/DatesConfirmedRequirement';
import { useEditableRequirementDOB } from './EditableRequirements/DOBRequirement';
import { useEditableRequirementTravelConfirmed } from './EditableRequirements/travelConfirmedRequirement';
import { useEditableRequirementGender } from './EditableRequirements/genderRequirement';
import { useEditableRequirementTravelTimePreference } from './EditableRequirements/travelTimePreferenceRequirement';
import { useEditableRequirementDepartureAirport } from './EditableRequirements/DepartureAirportRequirement';
import { useEditableRequirementTransportation } from './EditableRequirements/transportationRequirement';
import { useEditableRequirementDepartureItineraryLink } from './EditableRequirements/DepartureItineraryLinkRequirement';
import { useEditableRequirementDnsCleared } from './EditableRequirements/DnsClearedRequirement';
import { useEditableRequirementArrivalDate } from './EditableRequirements/ArrivalDateRequirement';
import { useEditableRequirementArrivalTime } from './EditableRequirements/ArrivalTimeRequirement';

/**
 * OFFER CONFIRMATION REQUIREMENT COUNTS
 */
const useOfferConfirmationRequirementCounts = () => {
  useEditableRequirementDatesConfirmed();
  useEditableRequirementDnsCleared();
  useEditableRequirementTravelConfirmed();
  const { datesConfirmed, travelConfirmed, dnsCleared } = useSelector(selectEditableRequirementTypes);
  const [count, setCount] = useState<Requirements>();

  React.useEffect(() => {
    /** OFFER CONFIRMATIONS REQUIREMENT COUNT CALCULATIONS */
    const counts = new Requirements();
    // Dates confirmed
    counts.add(datesConfirmed?.requirement);
    // DNS Cleared
    counts.add(dnsCleared?.requirement);
    // Travel Cconfirmed
    counts.add(travelConfirmed?.requirement);

    setCount(counts);
  }, [datesConfirmed, dnsCleared, travelConfirmed]);

  return count;
};

/**
 * OFFER TRANSPORTATION REQUIREMENT COUNTS
 */
const useOfferTransportationRequirementCounts = ({ transportationType }: { transportationType: string }) => {
  useEditableRequirementSSN();
  useEditableRequirementDOB();
  useEditableRequirementGender();
  useEditableRequirementTravelTimePreference();
  useEditableRequirementDepartureAirport();
  useEditableRequirementTransportation();
  useEditableRequirementDepartureItineraryLink();
  useEditableRequirementArrivalDate();
  useEditableRequirementArrivalTime();
  const { ssn,
    dob,
    gender,
    travelTimePreference,
    departureAirport,
    departureItineraryLink,
    arrivalDate,
    arrivalTime } = useSelector(
      selectEditableRequirementTypes,
    );
  const [count, setCount] = useState<Requirements>();
  React.useEffect(() => {
    const counts = new Requirements();

    if (transportationType === 'Unknown') {
      //counts.add(requirementType.fail);
      counts.add(arrivalDate?.requirement);
    } else if (transportationType === 'Drive') {
      //counts.add(requirementType.pass);
      counts.add(arrivalDate?.requirement);
      counts.add(arrivalTime?.requirement);
    } else if (transportationType === 'Fly') {
      counts.add(arrivalDate?.requirement);

      counts.add(travelTimePreference?.requirement);
      // Gender
      counts.add(gender?.requirement);
      // DOB
      counts.add(dob?.requirement);
      // SSN
      counts.add(ssn?.requirement);
      // Departure Airport
      counts.add(departureAirport?.requirement);
      // Itinerary Link
      counts.add(departureItineraryLink?.requirement);
    }

    setCount(counts);
  }, [transportationType, departureAirport, departureItineraryLink, dob,
    gender, ssn, travelTimePreference, arrivalDate, arrivalTime]);
  return count;
};

export const useOfferRequirementCounts = ({ transportation }) => {
  const dispatch = useDispatch();
  const confirmationCounts = useOfferConfirmationRequirementCounts();
  const transportationCounts = useOfferTransportationRequirementCounts({
    transportationType: transportation?.transportation,
  });

  React.useEffect(() => {
    dispatch(
      placementSummaryActions.setRequirementCounts({
        offerConfirmationReqCounts: confirmationCounts,
        offerTransportationReqCounts: transportationCounts,
      }),
    );
  }, [confirmationCounts, dispatch, transportationCounts]);

  return {
    confirmationCounts,
    transportationCounts,
  };
};
