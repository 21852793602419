import { DataGridProProps, GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import { Grid, IconButton, Typography, materialUiXGrid } from 'amn-ui-core';
import React, { useEffect } from 'react';
import { CustomTooltip } from '@AMIEWEB/Common';

export const VersionGridCell = (props: { param: GridRenderCellParams, setVersionOpen }) => {
  const api = materialUiXGrid.useGridApiContext();
  const isEditing = props.param.cellMode == 'edit';
  const versionRowCount = props.param.row.versionRows?.length || 0;

  return (
    <CustomTooltip
      hideToolTip={isEditing || !(versionRowCount > 0)}
      tooltipContent={
        <Typography style={{ fontSize: '14px', color: '#333 !important' }}>
          {`${versionRowCount} variations available`}
        </Typography>
      }>
      <IconButton
        color="secondary"
        aria-label="more"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={() => {
          api?.current.toggleDetailPanel(props.param.id);
          props.setVersionOpen(true);
        }}
        disabled={!isEditing && versionRowCount > 0 ? false : true}
        size="small"
        style={{ color: (!isEditing && versionRowCount > 0) ? '#333' : '#ccc' }}
      >
          <HistoryOutlinedIcon />
      </IconButton>
      </CustomTooltip>
  );
};
