import React, { useState } from 'react';
import { Box, Grid, Link, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';

const useUnitDetailsStyles = makeStyles<{
  isHeaderHovered: boolean;
  isRemoved;
  showIcon: boolean;
  subSection: boolean;
  customBackgroundColor: string | undefined;
  minHeight: string | undefined;
  isDisable: boolean;
}>()((theme, { isHeaderHovered, isRemoved, subSection, showIcon, customBackgroundColor, isDisable, minHeight }) => ({
  container: {
    background: customBackgroundColor
      ? customBackgroundColor
      : subSection
      ? theme.palette.system.brightGray
      : theme.palette.components.grid.content.backgroundColor,
    border: `1px solid ${theme.palette.system.lightGrey}`,
    borderRadius: '4px',
    opacity: 1,
    position: 'relative',
    minHeight: minHeight ? minHeight : 'none',
  },
  header: {
    height: '48px',
    background: theme.palette.system.skyBlue,
    borderBottom: `1px solid ${theme.palette.system.lightGrey}`,
    opacity: 1,
    alignItems: 'center',
    '&:hover': {
      background: theme.palette.system.dimSkyBlue,
      opacity: 1,
    },
  },
  titleSubSection: {
    paddingLeft: '12px',
  },

  titleSubSectionIcon: {
    display: !showIcon ? 'none' : 'block',
    paddingLeft: '12px',
  },
  removeButton: {
    display: isHeaderHovered ? 'block' : 'none',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    position: 'absolute',
    right: '12px',
    fontSize: '12px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  disabledHeader: {
    height: '48px',
    background: theme.palette.framework.system.whisper,
    borderBottom: `1px solid ${theme.palette.system.lightGrey}`,
    opacity: 1,
    alignItems: 'center',
    '&:hover': {
      background: theme.palette.system.dimLightGray,
      opacity: 1,
    },
  },
  titleStyles: {
    color:
      isDisable === false
        ? theme.palette.system.buttonDarkGray
        : !isRemoved
        ? theme.palette.components.typography.color
        : theme.palette.system.buttonDarkGray,
    fontWeight: '500',
    fontSize: '16px !important',
  },
}));

const UnitDetailCard = ({
  icon,
  title,
  children,
  isIconVisible = false,
  hasSubSection = false,
  customBackgroundColor = undefined,
  minHeight = undefined,
  isDisable,
  hideRemoveSection = true,
}: {
  icon?;
  title: string;
  children;
  isIconVisible?: boolean;
  hasSubSection?: boolean;
  customBackgroundColor?: string | undefined;
  isDisable?: boolean | undefined;
  hideRemoveSection?: boolean;
  minHeight?: string | undefined;
}) => {
  const { t } = useTranslation();
  const [isRemoved, setRemoved] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const { classes } = useUnitDetailsStyles({
    isHeaderHovered: isHovered,
    isRemoved,
    showIcon: isIconVisible,
    subSection: hasSubSection,
    customBackgroundColor: customBackgroundColor,
    isDisable,
    minHeight,
  });

  return (
    <Grid container item direction="column" className={classes.container} data-testId="unit-details-card">
      <Grid
        container
        item
        className={isDisable === false ? classes.disabledHeader : !isRemoved ? classes.header : classes.disabledHeader}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Grid item className={classes.titleSubSectionIcon}>
          <Box
            component="span"
            sx={{
              color: theme =>
                !isRemoved ? theme.palette.components.box.titleBar.color : theme.palette.system.buttonDarkGray,
            }}
          >
            {icon}
          </Box>
        </Grid>
        <Grid item className={classes.titleSubSection}>
          <Typography className={classes.titleStyles}>{title}</Typography>
        </Grid>
        {hideRemoveSection && (
          <Grid className={classes.removeButton}>
            <Link onClick={() => setRemoved(prevState => !prevState)} data-testId="unit-remove-include-link">
              {t(isRemoved ? 'facility.units.unitDetails.include' : 'facility.units.unitDetails.remove')}
            </Link>
          </Grid>
        )}
      </Grid>
      <Grid container item sx={{ display: isDisable === false ? 'none' : isRemoved ? 'none' : 'block' }} p={2}>
        {children}
      </Grid>
    </Grid>
  );
};

export default UnitDetailCard;
