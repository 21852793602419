import { theme } from 'styles/global-styles';
import { makeStyles } from 'tss-react/mui';

export const useSampleCheckBoxStyles = makeStyles()(() => ({
  checkboxPadding: {
    padding: 0,
    marginRight: 8,
  },
  checkboxLabel: {
    fontSize: 14,
    paddingLeft: 8,
    color: theme.palette.framework.system.charcoal,
  },
  checkboxLabelDeActivated: {
    fontSize: 14,
    paddingLeft: 8,
    color: theme.palette.framework.system.charcoal,
    cursor: 'default',
  },
  disabled: {
    cursor: 'not-allowed',
  },
}));
