import React from 'react';
import { StatusChip } from '../../CustomComponents/StatusChip';
import { getPlacementStatusChip } from './helper';

export const PlacementStatusChip = ({ placementStatus, placementStatusId, ...props }) => {
  const chip = React.useMemo(() => (
    <StatusChip 
      status={placementStatus} 
      chipStyle={getPlacementStatusChip(placementStatusId)} 
    />
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [placementStatusId]);

  return chip;
};
