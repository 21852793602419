import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './PlacementStatus.redux';

const nullCount = {
  placementsCount: 0,
  expiringCount: 0,
  offer: 0,
  offerByFacility: 0,
  offerBookedByTraveler: 0,
  facilityConfirms: 0,
  travelerAccepts: 0,
  bookedCategory: 0,
  bookedSubCategory: 0,
  bookingApproved: 0,
  waitingGoodToGo: 0,
  postponementWarning: 0,
  goodToGo: 0,
  onAssignment: 0,
};

const selectDomain = (state: RootState) => state?.credentialingWorkDeskPlacementStatus || initialState;

export const selectPipelineCounts = createSelector(
  [selectDomain],
  credentialingState => credentialingState.counts || { ...nullCount },
);

export const selectPlacementGridData = createSelector(
  [selectDomain],
  credentialingState => credentialingState.filteredData || credentialingState.flattenedData,
);

export const selectPlacementSourceData = createSelector(
  [selectDomain],
  credentialingState => credentialingState.flattenedData,
);
export const selectCredWorkdeskDataLoaded = createSelector([selectDomain], state => state.dataLoaded);

export const selectFilterSpecs = createSelector([selectDomain], credentialingState => credentialingState.filterSpecs);

export const selectFilterChipSelected = createSelector(
  [selectDomain],
  credentialingState => credentialingState.filterChipSelected,
);

export const selectPlacementDeskChips = createSelector([selectDomain], credentialingState => credentialingState.chips);

export const selectPlacementLoading = createSelector([selectDomain], credentialingState => credentialingState.loading);

export const selectExpiringCounts = createSelector(
  [selectDomain],
  credentialingState => credentialingState.expiringTotalCount,
);
