export const OrderConstants = {
  OrderPrioritiesLabel: {
    magnetStatus: 'Magnet Status',
    teaching: 'Teaching facility',
    traumaCenter: 'Trauma center',
    bedSize: 'Bed size less than 200',
  },
  STATUS_ON_HOLD: 'No bk/ext allowed',
  STATUS_PENDING: 'Pending',
  STATUS_MAX_BOOKING_LIMIT_WARNING:
    '{0} has reached the max booking limit. Please reach out to your leader for approval.',
  STATUS_CREDIT_ON_HOLD_WARNING:
    '{0} has a credit hold. Please reach out to the client contracts department with questions. Order creation is restricted at this time.',
  ERROR_SKILLSET_LIMITATION:
    'The skillset cannot be added. This AMN Division does not currently allow Orders that will accept various Discipline/Specialty combinations.',
  SUCCESS_MESSAGE: 'New Order Creation Completed Successfully',
  ORDER_UPDATE_SUCCESS_MESSAGE: 'Order updated successfully',
  FAILURE_MESSAGE: 'Order was unable to be created, please try again later.',
  ORDER_UPDATE_FAILURE_MESSAGE: 'Order was unable to be updated, please try again later.',
  SERVER_PAGESIZE: 100,
  UNIT_CREATION_SUCCESS_MESSAGE: 'Unit Creation Completed Successfully',
  UNIT_CREATION_FAILURE_MESSAGE: 'Unit was unable to be created, please try again later.',
  UNIT_UPDATE_SUCCESS_MESSAGE: 'Unit details have been successfully updated.',
  UNIT_UPDATE_FAILURE_MESSAGE: 'Unit details could not be updated.  Please try again.',
  UNIT_INFO_UNAVILABLE_MESSAGE: 'Unable to find information about selected facility unit',
  UNIT_CREATION_REQUIRED_MESSAGE: 'Required',
  UNIT_CREATION_UNIT_NAME_VALIDATION_FAILURE_MESSAGE:
    'Bummer! The Unit Name you chose is taken for this facility. Rename the Unit and resubmit.',
  UNIT_CREATION_SKILLSET_PRIMARY_HELPER: 'First Skillset selected will be labeled as "Primary"',
  UNIT_CREATION_EXTERNAL_JOB_HELPER: 'External Job description field posts to external websites.',
  ERROR_UNIQUE_CHIP_NOT_CHOSEN: 'The skillset combination has already been selected',
  WAARRING_UNIQUE_CHIP_NOT_CHOSEN: `Existing skillset cannot be modified - the current order has placements associated with it.`,
};

export const AdditionalInformationData = [
  {
    name: 'hideFacilityDescription',
    label: 'Hide Facility Description',
  },
  {
    name: 'suppressExternalPosting',
    label: 'Suppress External Posting',
  },
  {
    name: 'CF',
    label: 'Clinical Fellowship',
  },
  {
    name: 'newGrad',
    label: 'New Grad',
  },
  {
    name: 'maxFilesSent',
    label: 'Max Files Sent',
  },
];

export const ReasonsForMaxFileSent = [
  'On Hold/Facility Reviewing Profiles',
  'Maxed by VMS',
  'Max Files Sent',
  'Filled by AMN',
  'Order Validation in Progress',
  'Other/Reach out to Account Manager',
  'Order Suppression',
];

export const PacketTemplate = [
  { id: 1, label: "AMN Default", value: "AMNDefault" }
];

export const OrderReason = [
  { id: 1, reason: 'Backfill' },
  { id: 2, reason: 'Competitor Backfill' },
  { id: 3, reason: 'New Order' },
  { id: 4, reason: 'Request for Proposal' },
];

export const offContractColor = [
  { statusId: 1, color: '#FC9508' },
  { statusId: 2, color: '#FC9508' },
  { statusId: 3, color: '#13A01E' },
  { statusId: 4, color: '#CC0000' }
]