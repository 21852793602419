import { MFSOpts, PositionOpts, getOrderPriorities } from '@AMIEWEB/GlobalSearch/Order/FilterUtils';
import { getFacilitySearchLookups } from '@AMIEWEB/GlobalSearch/helper';
import { FilterCompTypes } from 'app/ComponentLibrary/Filter/utils';
import { AMNDivision } from 'app/models/enums/AMNDivisionType';
import { getSearchLookups } from 'app/services/SharedServices/SharedServices';
import i18next from 'i18next';

export enum orderBonusFilterName {
  division = 'division',
  bonusType = 'bonusType',
  pricingStatus = 'pricingStatus',
  positions = 'positions',
  OID = 'OID',
  orderPriority = 'orderPriorityIds',
  facility = 'facility',
  facilityStatus = 'facilityStatus',
  discipline = 'discipline',
  specialty = 'specialty',
  subSpecialty = 'subSpecialty',
  shift = 'shift',
  orderBillRate = 'orderBillRate',
  mspClient = 'mspClient',
  MFS = 'MFS',
}

export interface IOrderBonusFilters {
  division?: number[];
  bonusType?: number[];
  pricingStatus?: number[];
  OID?: number[];
  positions?: any;
  orderPriorityIds?: number[];
  facility?: number[];
  facilityStatus?: number[];
  discipline?: number[];
  specialty?: number[];
  subSpecialty?: number[];
  shift?: number[];
  orderBillRate?: any;
  mspClient?: number[];
  MFS?: number;
}

export const bonusTypeOptions = [
  { id: 10, name: 'Additional Pay', value: 'Additional Pay' },
  { id: 2, name: 'Date Booked', value: 'Date Booked' },
  { id: 12, name: 'Market Adjustment', value: 'Market Adjustment' },
  { id: 1, name: 'Start Date', value: 'Start Date' },
];

export const pricingStatusOptions = [
  { id: 1, name: 'Complete', value: 'Complete' },
  { id: 2, name: 'Pending', value: 'Pending' },
];

export const defaultPricingFilters = {
  [orderBonusFilterName.division]: [
    { id: 1, name: AMNDivision.Allied, value: '1' },
    { id: 6, name: AMNDivision.Travel, value: '3' },
  ],
  [orderBonusFilterName.bonusType]: [
    { id: 10, name: 'Additional Pay', value: 'Additional Pay' },
    { id: 12, name: 'Market Adjustment', value: 'Market Adjustment' },
  ],
  [orderBonusFilterName.pricingStatus]: [{ id: 2, name: 'Pending', value: 'Pending' }],
  [orderBonusFilterName.positions]: PositionOpts[1],
  [orderBonusFilterName.MFS]: null,
  [orderBonusFilterName.mspClient]: null,
  [orderBonusFilterName.OID]: null,
  [orderBonusFilterName.facility]: null,
  [orderBonusFilterName.facilityStatus]: [{ id: 1, name: 'Active', value: '1' }],
  [orderBonusFilterName.orderBillRate]: null,
  [orderBonusFilterName.orderPriority]: null,
  [orderBonusFilterName.discipline]: null,
  [orderBonusFilterName.subSpecialty]: null,
  [orderBonusFilterName.specialty]: null,
  [orderBonusFilterName.shift]: null,
};

export const defaultValues = {
  bonusType: ['Additional Pay', 'Market Adjustment'],
  pricingStatus: ['Pending'],
};

export class PricingFilter {
  constructor(public filterSpecs: any, public styleClasses: any, public gridStateFilters: any) {}

  getPricingFilters = () => [
    {
      filterName: i18next.t('search.globalSearch.order.filterNames.division'),
      name: orderBonusFilterName.division,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      options: this.filterSpecs.divisionOpts,
      selectAllValue: 'All',
    },
    {
      filterName: i18next.t('home.pricingWorkDesk.filterHeading.bonusType'),
      name: orderBonusFilterName.bonusType,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      options: bonusTypeOptions,
      isMultiSelect: true,
    },
    {
      filterName: i18next.t('home.pricingWorkDesk.filterHeading.pricingStatus'),
      name: orderBonusFilterName.pricingStatus,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      options: pricingStatusOptions,
    },
    {
      filterName: i18next.t('home.pricingWorkDesk.filterHeading.positions'),
      name: orderBonusFilterName.positions,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      options: PositionOpts,
    },
    {
      filterName: i18next.t('home.pricingWorkDesk.filterHeading.facility'),
      name: orderBonusFilterName.facility,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      fetchOptions: getFacilitySearchLookups,
      isMultiSelect: true,
    },
    {
      filterName: i18next.t('home.pricingWorkDesk.filterHeading.facilityStatus'),
      name: orderBonusFilterName.facilityStatus,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      options: this.filterSpecs.facilityStatusesOpts,
      isMultiSelect: true,
      selectAllValue: 'All',
    },
    {
      filterName: i18next.t('search.globalSearch.order.filterNames.discipline'),
      name: orderBonusFilterName.discipline,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      options: this.filterSpecs.disciplinesOpts,
      selectAllValue: 'All',
    },
    {
      filterName: i18next.t('search.globalSearch.order.filterNames.specialty'),
      name: orderBonusFilterName.specialty,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      options: this.filterSpecs.specialtiesOpts,
      selectAllValue: 'All',
    },
    {
      filterName: i18next.t('search.globalSearch.order.filterNames.subSpecialty'),
      name: orderBonusFilterName.subSpecialty,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      options: this.filterSpecs.subSpecialtiesOpts,
      selectAllValue: 'All',
    },
    {
      filterName: i18next.t('search.globalSearch.order.filterNames.shift'),
      name: orderBonusFilterName.shift,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      options: this.filterSpecs.shiftOpts,
      selectAllValue: 'All',
    },
    {
      filterName: i18next.t('home.pricingWorkDesk.filterHeading.OID'),
      name: orderBonusFilterName.OID,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      fetchOptions: async key => await getSearchLookups('OID', key),
      isMultiSelect: true,
      applyOnClickAway: true,
      searchOnCharacterCount: 1,
    },
    {
      filterName: i18next.t('search.globalSearch.order.filterNames.orderPriority'),
      name: orderBonusFilterName.orderPriority,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      fetchOptions: async key => await getOrderPriorities(this.filterSpecs.orderPrioritiesOpts, key),
      selectAllValue: 'All',
      searchOnCharacterCount: 1,
    },
    {
      filterName: i18next.t('home.pricingWorkDesk.filterHeading.orderBillRate'),
      name: orderBonusFilterName.orderBillRate,
      type: FilterCompTypes.NUMBERRANGE,
      placeholder: i18next.t('search.globalSearch.order.filterNames.orderBillRate'),
      numberRangeType: 'currency',
    },
    {
      filterName: i18next.t('home.pricingWorkDesk.filterHeading.mspClient'),
      name: orderBonusFilterName.mspClient,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      options: this.filterSpecs.mspClientOpts,
      selectAllValue: 'All',
    },
    {
      filterName: i18next.t('home.pricingWorkDesk.filterHeading.MFS'),
      name: orderBonusFilterName.MFS,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      options: MFSOpts,
    },
  ];
}
