import { determineChipSelected } from '@AMIEWEB/AmWorkDesk/PlacementsDesk/filterHelper';
import { generateChips } from '@AMIEWEB/GlobalSearch/helper';
import { Grid } from '@mui/material';
import { ChipsContainer } from 'app/ComponentLibrary/Filter';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectAutoTaskManagement } from 'store/redux-store/placement-requests/selectors';

const AutoTaskChips = ({ handleSubmit }: { handleSubmit: (filters: any, subFilter?: any) => any }) => {
  const hiddenSubmitButton = React.useRef<HTMLButtonElement>();
  const { filters, selectedFilters } = useSelector(selectAutoTaskManagement);
  const [chips, setChips] = React.useState({});
  const [selectedChips, setSelectedChips] = React.useState<any>({});
  const { t } = useTranslation();
  const { reset } = useFormContext();

  React.useEffect(() => {
    const newChips = filters ? generateChips(filters, t, 'Auto Tasks', false, selectedFilters) : {};
    setChips(newChips);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, selectedFilters]);

  const getNewSelection = (key, chip) => ({
    ...selectedChips,
    [key]: determineChipSelected(chip, selectedChips ? selectedChips[key] : undefined),
  });
  const handleChipDelete = (field, chip) => {
    const updatedFilters = { ...filters, [field]: filters[field].filter(filter => filter.value !== chip.value.value) };
    reset(updatedFilters);
    handleSubmit(updatedFilters);
  };

  const handleChipClick = (field, chip) => {
    const newSelections = getNewSelection(field, chip);
    setSelectedChips(newSelections);
    handleSubmit(filters, newSelections);
  };
  return (
    <Grid container>
      <Grid item xs={12} flex={'row'} justifyContent={'space-between'} marginTop={1} marginBottom={1}>
        <ChipsContainer
          chips={chips}
          onDelete={(field, chip, index) => handleChipDelete(field, chip)}
          onClick={(field, chip, index) => handleChipClick(field, chip)}
        />
        <button ref={hiddenSubmitButton} hidden type="submit" />
      </Grid>
    </Grid>
  );
};

export default AutoTaskChips;
