import { FilterCompTypes } from 'app/ComponentLibrary/Filter/utils';
import { getSearchLookups } from 'app/services/SharedServices/SharedServices';
import { userManagementFilterNames } from './Helper';

export class UserManagementFilters {
  constructor(public filterSpecs: any, public t: any) { }

  getUserManagementFilterSpecs = () => {
    return [
      {
        filterName: 'Employee ID',
        name: userManagementFilterNames.eid,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookups('EID', key),
      },
      {
        filterName: 'User Name',
        name: userManagementFilterNames.usernames,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookups('usernames', key),
        isDisabled: !!this.filterSpecs?.eidSelected,
      },
      {
        filterName: 'Employee Name',
        name: userManagementFilterNames.employeenames,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: false,
        fetchOptions: async key => await getSearchLookups('employeenames', key),
        isDisabled: !!this.filterSpecs?.eidSelected,
      },
      {
        filterName: 'Brand',
        name: userManagementFilterNames.companies,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: false,
        options: this.filterSpecs.companyOpts,
        isDisabled: !!this.filterSpecs?.eidSelected,
      },
      {
        filterName: 'Status',
        name: userManagementFilterNames.employeestatus,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: false,
        options: this.filterSpecs.statusOpts,
        isDisabled: !!this.filterSpecs?.eidSelected,
      },
      {
        filterName: 'Role',
        name: userManagementFilterNames.employeerole,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: false,
        options: this.filterSpecs.roleOpts,
        isDisabled: !!this.filterSpecs?.eidSelected,
      },
    ];
  };
}
