/* eslint-disable i18next/no-literal-string */
import React from 'react';
import _ from 'lodash';
import { Expandable } from 'app/components/Common/Expandable/Expandable';
import { IconTitle } from './Titles/IconTitle';
import { List, ListItem, ListItemText, ListItemAvatar, Grid, useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { getPlacementListIcon } from 'app/components/Placement/PlacementDetails/helper';

const useStyles = makeStyles()(theme => ({
  root: {
    maxHeight: 190,
    overflowY: 'scroll',
  },
  primary: {
    color: theme.palette.framework.system.charcoal,
    fontWeight: 450,
  },
  secondary: {
    color: theme.palette.framework.system.doveGray,
    marginTop: '1px',
    fontSize: '0.9rem',
    marginLeft: '6px',
  },
  item: {
    padding: 0,
    fontSize: 16,
  },
  icon: {
    minWidth: 26,
    flexShrink: 0,
    marginTop: 2,
  },
  listContainer: {
    paddingLeft: '36px',
  },
  iconClass: {
    color: theme.palette.system.dimDarkRed,
  },
  iconText: {
    color: theme.palette.system.dimDarkRed,
    position: 'relative',
    left: '-1.04rem',
    top: '0.1rem',
    fontSize: '14px',
    fontWeight: 600,
  },
}));

export const ClassicPacketOkToSendWarningContainer = ({ title, message }: { title: string, message: string }) => {
  const { classes } = useStyles();
  const theme = useTheme();

  const titleContentStyle = {
    color: theme.palette.system.white,
  };

  return (
    <Grid item xs={12}>
        <Expandable
            id="placement-summary-packet-warning"
            titleComponent={
                <IconTitle
                    title={title}
                    icon={null}
                    titleSupportComponent={null}
                    titleContentStyle={titleContentStyle}
                />
            }
            expanded={true}
            variant={'error'}
        >
            <List
                disablePadding
                classes={{ root: classes.root }}
                data-testid="placementsubmissionpacketwarning-itemContainer"
                id={'placementSummaryDetailsSubmissionPacketWarningContainer'}
            >
                {message && (
                    <ListItem
                        classes={{ root: classes.item }}
                        className={classes.listContainer}
                        alignItems="flex-start"
                        aria-label="placementsubmissionpacketwarning-item"
                    >
                        <ListItemAvatar classes={{ root: classes.icon }}>{getPlacementListIcon(-1)}</ListItemAvatar>
                        <ListItemText
                            secondary={message}
                            classes={{
                                primary: classes.primary,
                                secondary: classes.secondary,
                            }}
                        />
                    </ListItem>
                )}
            </List>
        </Expandable>
    </Grid>
  );
};