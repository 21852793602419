import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPlacementCandidateRecords,
  selectPlacementModalCategoryData,
} from '../../../../../../store/redux-store/placement-details/selectors';
import { Row, RowContainer, RowContent, RowHead } from '../../styles';
import { makeStyles } from 'tss-react/mui';
import { CustomGrid } from 'app/components/Common/Grid/Grid';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import {
  placementDetailReducer,
  placementDetailsAction,
  placementDetailSliceKey,
} from '../../../../../../store/redux-store/placement-details/slice';
import { placementDetailsSaga } from '../../../../../../store/redux-store/placement-details/saga';
import { useParams } from 'react-router-dom';
import { CandidatePlacementCategory } from 'app/models/Placement/PlacementDetails';
import { modalColumns } from './Helpers/CandidatePlacementUtils';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';

const useStyles = makeStyles()({
  clickable: {
    cursor: 'pointer !important',
    color: '#006FB9 !important',
    fontWeight: 450,
  },
});

export const CandidatePlacements = () => {
  const dispatch = useDispatch();
  const params = useParams<{ placementId: string; categoryId: string }>();
  const { t } = useTranslation();
  const [openCandidate, setOpenCandidate] = useState<boolean>(false);
  const { candidatePlacementCounts } = useSelector(selectPlacementCandidateRecords);
  const rows: any = useSelector(selectPlacementModalCategoryData);
  const { classes, cx } = useStyles();
  const [categoryId, setCategoryId] = useState<any>();
  const [modalTitle, setModalTitle] = useState<string>();

  const onCandidatePlacementToggle = (placementid: number, categoryId: any, count: number) => () => {
    if (count === 0 || isNaN(count)) return;

    switch (categoryId) {
      case 1:
        setModalTitle(t('placement.profile.tabs.candidatePlacementsTab.submittals'));
        break;
      case 2:
        setModalTitle(t('placement.profile.tabs.candidatePlacementsTab.interview'));
        break;
      case 3:
        setModalTitle(t('placement.profile.tabs.candidatePlacementsTab.offers/Accepts'));
        break;
      case 4:
        setModalTitle(t('placement.profile.tabs.candidatePlacementsTab.sameMsp'));
        break;
    }
    setCategoryId(categoryId);
    if (!rows[categoryId]) {
      dispatch(
        placementDetailsAction.getModalCategoryData({
          placementId: placementid,
          categoryId: categoryId,
        }),
      );
    }
    setOpenCandidate(prevState => !prevState);
  };

  const RowSection = props => {
    return (
      <LayoutGridItem item id={props.id}>
        <RowContainer>
          <Row>
            <RowHead style={{ wordBreak: 'keep-all' }}>{props.header}</RowHead>
            <RowContent
              onClick={onCandidatePlacementToggle(
                parseInt(params.placementId),
                CandidatePlacementCategory[props.header.toLowerCase().replace(/[^a-zA-Z]+/g, '')],
                props.data,
              )}
              className={cx({
                [classes.clickable]: typeof props.data === 'number' && props.data,
              })}
            >
              {props.data}
            </RowContent>
          </Row>
        </RowContainer>
      </LayoutGridItem>
    );
  };

  const displayModal = () => {
    return (
      <React.Fragment>
        {categoryId && rows[categoryId] && (
          <GenericDialog
            open={openCandidate}
            fullWidth
            maxWidth="lg"
            variant="blue"
            dialogTitleProps={{ text: modalTitle ?? '', closeButton: true }}
            onClose={() => {
              setOpenCandidate(prevState => !prevState);
            }}
          >
            <CustomGrid
              columns={modalColumns}
              rows={rows[categoryId] as any[]}
              searchTerm={undefined}
              component={''}
              showCount={false}
              disableGutters
              noPadding={true}
              fullWidth
              showPagination={false}
              showViewOptions={false}
              currentPageNumber={1}
              fixedItemsPerPage={1000}
              disablePaper
              centerHoverView
              isInDialog={false}
              stickyTable
              maxHeight={'360px'}
            />
          </GenericDialog>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <LayoutGrid direction="column">
        <RowSection
          header={t('placement.profile.tabs.candidatePlacementsTab.submittals')}
          data={candidatePlacementCounts.submittals}
          className={cx({
            [classes.clickable]: candidatePlacementCounts.submittals > 0,
          })}
          id={'placementDetailsTabsCandidatePlacementsTabSubmittals'}
        />
        <RowSection
          header={t('placement.profile.tabs.candidatePlacementsTab.interview')}
          data={candidatePlacementCounts.interviews}
          className={cx({
            [classes.clickable]: candidatePlacementCounts.interviews > 0,
          })}
          id={'placementDetailsTabsCandidatePlacementsTabInterview'}
        />
        <RowSection
          header={t('placement.profile.tabs.candidatePlacementsTab.offers/Accepts')}
          data={candidatePlacementCounts.offersAccepts}
          className={cx({
            [classes.clickable]: candidatePlacementCounts.offersAccepts > 0,
          })}
          id={'placementDetailsTabsCandidatePlacementsTabOffersAccepts'}
        />
        <RowSection
          header={t('placement.profile.tabs.candidatePlacementsTab.sameMsp')}
          data={candidatePlacementCounts.hasMspClient ? candidatePlacementCounts.sameMspPlacements : 'N/A'}
          className={cx({
            [classes.clickable]:
              candidatePlacementCounts.hasMspClient && candidatePlacementCounts.sameMspPlacements > 0,
          })}
          id={'placementDetailsTabsCandidatePlacementsTabSameMsp'}
        />
      </LayoutGrid>
      {displayModal()}
    </React.Fragment>
  );
};
