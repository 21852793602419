import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GetMomentOfDate } from 'utils/dates/moment';
import { Concatenate } from 'utils/string/string';
import {
  selectNewPlacementChoice,
  selectScreenSelectionEnabled,
} from '../../../../../store/redux-store/new-placement/selectors';
import { SkillsetTree } from '../../../Common/SkillsetTree/SkillsetTree';
import { OverflowTooltip } from 'app/components/Common/Tooltips';
import { colors } from 'styles/styleVariables';
import { SelectPlacementSkillsets } from './PlacementSkillsets';
import { missingField } from 'app/constants';
import styledComponent from 'styled-components';
import { SelectScreen } from './SelectScreen';
import { theme } from 'styles/global-styles';

const useStyles = makeStyles()(() => ({
  root: {
    padding: 12,
    border: '1px solid #E3DFDF',
    boxShadow: '0px 1px 5px #0000001F',
    maxHeight: 380,
    minHeight: 380,
    overflowX: 'hidden',
  },
  ternaryTitle: {
    color: colors.amnGrey,
    fontWeight: 400,
  },
}));

const overriddenTheme = createTheme(theme, {
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#FCFCFD',
          color: '#333333',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '6px 0px',
          verticalAlign: 'baseline',
          border: 'transparent !important',
          width: '50%',
        },
      },
    },
  },
});
export const SelectedOrder = () => {
  const { t } = useTranslation();
  const { order } = useSelector(selectNewPlacementChoice);
  const { classes } = useStyles();
  const dateMoment = GetMomentOfDate((order?.startDate || null) as unknown as string);
  const screenSelectionEnabled = useSelector(selectScreenSelectionEnabled);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={overriddenTheme}>
        <Paper classes={{ root: classes.root }}>
          <OverflowTooltip
            value={order?.facility}
            contentStyle={{ fontWeight: 450, fontSize: 16, color: '#006FB9', maxWidth: 290 }}
          />
          <Typography variant="body1" classes={{ body1: classes.ternaryTitle }}>
            {Concatenate([order?.city || '', order?.state || ''], ', ')}
          </Typography>
          <Table>
            <TableBody>
              <ConditionalRow
                header={t('placement.create.popup.orderSkillset')}
                hasMissingField={!order?.groupedSkillsets?.length}
              >
                <SkillsetTree skillset={order?.groupedSkillsets || []} />
              </ConditionalRow>
              <TableRow>
                <KeyCell> {t('placement.create.popup.shift')}</KeyCell>
                <ValueCell>
                  {order && order.shifts.length !== 0 ? Concatenate(order?.shifts || [], ', ') : missingField}
                </ValueCell>
              </TableRow>
              <TableRow>
                <KeyCell> {t('placement.create.popup.startDate')}</KeyCell>
                <ValueCell>
                  {order ? (dateMoment.isValid() ? dateMoment.format('MM/DD/YYYY') : t('common.asap')) : missingField}
                </ValueCell>
              </TableRow>
              <TableRow>
                <KeyCell> {t('placement.create.popup.length')}</KeyCell>
                <ValueCell>
                  {order?.placementLength
                    ? `${order?.placementLength} ${t('placement.create.popup.weeks')}`
                    : missingField}
                </ValueCell>
              </TableRow>
            </TableBody>
          </Table>
          {screenSelectionEnabled ? <SelectScreen /> : null}
          <SelectPlacementSkillsets />
        </Paper>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const ConditionalRow = props =>
  !props.hasMissingField ? (
    <>
      <TableRow>
        <KeyCell colSpan={2}>{props.header}</KeyCell>
      </TableRow>
      <TableRow>
        <ValueCell colSpan={2}>{props.children}</ValueCell>
      </TableRow>
    </>
  ) : (
    <TableRow>
      <KeyCell>{props.header}</KeyCell>
      <ValueCell>{missingField}</ValueCell>
    </TableRow>
  );

const KeyCell = styledComponent(TableCell)`
  color: #333333;
  font-size: 13px;
  font-weight: 400;
`;

const ValueCell = styledComponent(TableCell)`
  color: #555555;
  font-size: 14px;
  font-weight: 450;
`;
