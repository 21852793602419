import { Divider } from 'amn-ui-core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectMiscellaneousData,
  selectNewPlacementChoice,
} from '../../../../../store/redux-store/new-placement/selectors';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomSelect } from '../CustomComponents/CustomSelect';

export const SelectScreen = () => {
  const { t } = useTranslation();

  const { control, setValue } = useFormContext();
  const { newPlacementId } = useSelector(selectNewPlacementChoice);

  const { screenOptions } = useSelector(selectMiscellaneousData);

  return (
    <>
      <Divider style={{ margin: '8px 0px' }} />
      <LayoutGridItem container spacing={2} direction="column">
        <LayoutGridItem>
          <Controller
            control={control}
            name="screenOption"
            render={({ ref, ...rest }) => (
              <CustomSelect
                options={screenOptions}
                label={t('Screen *')}
                disabled={newPlacementId !== null}
                {...rest}
              />
            )}
          />
        </LayoutGridItem>
      </LayoutGridItem>
    </>
  );
};
