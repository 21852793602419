import { debounce } from 'amn-ui-core';
import DialogActions from '@mui/material/DialogActions';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInputText } from '../../../PersonalInfoTab/Form/Controls/FormInputText';
import { isWithinInterval, isAfter, isBefore } from 'date-fns';
import { FormInputDatePicker } from '../../../PersonalInfoTab/Form/Controls/FormInputDatePicker';
import { ITypeAheadOption } from '../../../PreferencesTab/CustomComponents/ControlledTypeAhead';
import { getCitiesAndZipsByStates } from 'app/services/SharedServices/SharedServices';
import { FormInputCheckbox } from '../../../PersonalInfoTab/Form/Controls/FormInputCheckbox';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { candidateDetailsSelection } from '../../../../Profile/CandidateDetails.selector';
import { useSelector } from 'react-redux';
import { ff_ui_candidate_manage_EquipmentProcedure } from 'app/constants/FeatureFlags/Candidate/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { TypeAhead } from '@AMIEWEB/Common';
import statesData from 'app/assets/jsons/State.json';
import { IOption } from '../../../ProfileTab/Education/FormInputDropdown';
import FormInputTextField from '../../../../WorkExperience/Edit/components/FormInputTextField';
import { EnhancedEditWorkExperieneStyles } from './WorkExperienceModal.style';
import { theme } from 'styles/global-styles';
import { GenericDialogButton } from '@AMIEWEB/Alerts/GenericDialog';
import { selectSelectedWorkExperience } from 'store/redux-store/candidate-work-experience/selector';

enum CountryCodes {
  USA = 1,
  CANADA = 2,
}

export const EnhancedEditWorkExperience = props => {
  const { travelCompanyData, isTravelWorkExp, typeAheadOptions, setSaveDisabled, countriesData, dialogActions } = props;
  const { classes } = EnhancedEditWorkExperieneStyles();
  const { t } = useTranslation();
  const selectedWorkExperience = useSelector(selectSelectedWorkExperience);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const [filteredSpecialty, setFilteredSpecialty] = React.useState<ITypeAheadOption[]>([]);
  const [candidateManageEquipmentProcedure] = useDecision(ff_ui_candidate_manage_EquipmentProcedure);
  const [pSupervisor, setPSupervisor] = useState<boolean>(selectedWorkExperience?.isSupervisor);
  const [pTeletherapy, setPTeletherapy] = useState<boolean>(selectedWorkExperience?.isTeletherapy);
  const [pHospital, setPHospital] = useState<boolean>(
    selectedWorkExperience?.isTeachingHospital === 'Y' || selectedWorkExperience?.isTeachingHospital === true,
  );
  const [currentEmployer, setCurrentEmployer] = useState<boolean>(selectedWorkExperience?.currentlyEmployed || false);
  const [pOngoing, setPOngoing] = useState<boolean>(selectedWorkExperience?.currentlyEmployed || false);
  const [addWorkExperience, setAddWorkExperience] = useState<boolean>(true);
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [enableSaveOnOverlap, setEnableSaveOnOverlap] = useState<boolean>(true);
  const [isDatesChanged, setIsDatesChanged] = useState<boolean>(false);
  const [startValue, setStartValue] = useState<Date>(null);
  const [endValue, setEndValue] = useState<Date>(null);
  const [addTimeOff, setAddTimeOff] = useState<boolean>(false);
  const { errors, getValues, setValue, clearErrors, control, setError, formState, watch, trigger } = useFormContext();
  const [charCount, setCharCount] = useState<number>(0);
  const [cityOptions, setCityOptions] = useState<IOption[]>([]);
  const [statesOptions, setStatesOptions] = useState<IOption[]>([]);
  const [defaultCities, setDefaultCities] = useState<IOption[]>([]);
  const isInitialDiscipline = useRef(true);
  const isInitialCountry = useRef(true);
  const facilityEmployer = watch('pFacilityEmployer');
  const discipline = watch('piDiscipline');
  const speciality = watch('piSpecialty');
  const selectedState = watch('pState');
  const selectedCity = watch('piCity');
  const zipCode = watch('piZip');
  const onGoing = watch('pOngoing');
  const selectedCountry = watch('pCountry');
  useEffect(() => {
    if (!getValues('pAddWorkExperience') && addWorkExperience) {
      setValue('pAddTimeOff', !addWorkExperience);
      setValue('pAddWorkExperience', addWorkExperience);
    }
    setAddWorkExperience(isTravelWorkExp);
    setAddTimeOff(!isTravelWorkExp);
    setStartValue(getValues('pStart'));
    setEndValue(getValues('pEnd'));
  }, []);
  useEffect(() => {
    enableSaveOnChanges();
  }, [
    facilityEmployer,
    discipline,
    speciality,
    selectedState,
    selectedCity,
    startValue,
    endValue,
    onGoing,
    zipCode,
    errorMessageOpen,
    selectedState,
    selectedCity,
  ]);
  useEffect(() => {
    if (discipline) {
      const filteredData = typeAheadOptions?.specialties?.filter(data => {
        return data.object.DisciplineID === discipline.object.ID;
      });
      filteredData.sort((a, b) => a.label?.localeCompare(b.label));
      setFilteredSpecialty(filteredData);

      if (isInitialDiscipline.current) {
        isInitialDiscipline.current = false;
        return;
      }
      filteredData.length && setValue('piSpecialty', filteredData[0], { shouldDirty: true });
    }
  }, [discipline]);

  useEffect(() => {
    const country = getValues('pCountry');
    if (country?.id === CountryCodes.USA || country?.id === CountryCodes.CANADA) {
      const states = statesData.states
        .filter(e => e.CountryID === country?.id)
        .map(e => ({ id: e.ID, label: e.Description, value: e.StateNumber }));
      setStatesOptions(states);
      if (isInitialCountry.current) {
        isInitialCountry.current = false;
        return;
      }
      if (addWorkExperience) {
        setValue('pState', states[0]);
      } else {
        setValue('pState', '');
        setValue('piCity', '');
      }
    } else if (formState['dirtyFields']['pCountry'] || formState['touched']['pCountry']) {
      setStatesOptions([]);

      // DO NOT REMOVE SetTimeout otherwise state wont reset once you change state from USA to non-USA.
      setTimeout(() => {
        setValue('pState', '');
      }, 0);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCountry?.id === CountryCodes.USA && selectedState) {
      fetchCities();
    } else {
      setCityOptions([]);
    }
    if (
      formState['dirtyFields']['pCountry'] ||
      formState['touched']['pCountry'] ||
      formState['dirtyFields']['pState'] ||
      formState['touched']['pState']
    ) {
      setValue('piCity', '');
      trigger(['piCity']);
    }
  }, [selectedState]);
  useEffect(() => {
    const endFromDate = endValue ? new Date(endValue) : null;
    const startDate = startValue ? new Date(startValue) : null;

    if (isDatesChanged) {
      if (hasDateOverlapping()) {
        setErrorMessageOpen(true);
      } else {
        setErrorMessageOpen(false);
      }
    }

    const currentlyWorking = getValues('currentlyWorking');
    if (!currentlyWorking && !pOngoing && endFromDate?.getTime() < startDate?.getTime()) {
      setError('pEnd', {
        type: 'validate',
        message: t('candidate.dialogActions.endDateError'),
      });
    } else {
      clearErrors('pEnd');
    }
    if (startDate?.getTime() > endFromDate?.getTime()) {
      setError('pStart', {
        type: 'validate',
        message: t('candidate.dialogActions.startDateError'),
      });
    } else {
      clearErrors('pStart');
    }
  }, [startValue, endValue]);

  const handleStartDateChange = event => {
    setValue('pStart', event, { shouldDirty: true });
    setStartValue(new Date(event));
    clearErrors('pStart');
    setEnableSaveOnOverlap(true);
    setIsDatesChanged(true);
  };
  const handleEndDateChange = event => {
    setValue('pEnd', event, { shouldDirty: true });
    setEndValue(new Date(event));
    clearErrors('pEnd');
    setEnableSaveOnOverlap(true);
    setIsDatesChanged(true);
  };

  const handlePNotes = event => {
    setValue('pnotes', event.target.value, { shouldDirty: true });
    setCharCount(event.target.value?.length);
  };

  const hasDateOverlapping = () => {
    const endFromDate = endValue ? new Date(endValue) : null;
    const startDate = startValue ? new Date(startValue) : null;

    if (addTimeOff) {
      if (isOverlappingDates(startDate, endFromDate)) {
        return true;
      } else {
        return false;
      }
    }
  };

  const isOverlappingDates = (startDate: Date, endDate: Date) => {
    let isOverlapping = false;
    candidateDetails?.experience?.employment.forEach(item => {
      if (
        item.endedOn &&
        (isWithinInterval(startDate, {
          start: new Date(item.startedOn),
          end: item.endedOn ? new Date(item.endedOn) : null,
        }) ||
          isWithinInterval(endDate, {
            start: new Date(item.startedOn),
            end: item.endedOn ? new Date(item.endedOn) : null,
          }))
      ) {
        isOverlapping = true;
      } else if (
        !item.endedOn &&
        (isAfter(startDate, new Date(item.startedOn)) || isAfter(endDate, new Date(item.startedOn)))
      ) {
        isOverlapping = true;
      } else if (
        item.endedOn &&
        isBefore(startDate, new Date(item.startedOn)) &&
        isAfter(endDate, new Date(item.endedOn))
      ) {
        isOverlapping = true;
      }
    });
    return isOverlapping;
  };

  const handleCurrentlyWorkingChange = event => {
    const isChecked = event;

    if (isChecked) {
      errors['pReason'] = '';
    }

    setValue('currentlyWorking', isChecked, { shouldDirty: true });
    setCurrentEmployer(isChecked);
  };

  const handleWorkEndDateChange = event => {
    setValue('pEnd', event, { shouldDirty: true });
    setEndValue(new Date(event));
    clearErrors('pEnd');
    if (event && event !== '') {
      const currentDate = new Date().getTime();
      const endDate = new Date(event).getTime();
      const isCurrentlyWorking = currentDate < endDate;

      if (isCurrentlyWorking) {
        errors['pReason'] = '';
      }
      setValue('currentlyWorking', isCurrentlyWorking, { shouldDirty: true });
      setCurrentEmployer(isCurrentlyWorking);
    }
  };

  const fetchCities = async () => {
    const selectedStates = typeof selectedState ? [selectedState?.label] : [];
    const result = getCitiesAndZipsByStates({
      states: selectedStates,
      cities: [],
    });

    let filteredCities = [];
    result.then(res => {
      filteredCities = res[0]?.cities?.map(item => {
        return { id: item.id, label: item.name };
      });
      setCityOptions(filteredCities);
      setDefaultCities(filteredCities);
    });
  };

  const onTextSearch = async (key: string, searchMode: 'c' | 'z') => {
    const selectedStates = selectedState ? [selectedState?.label] : [];
    if (searchMode === 'c') {
      const result = getCitiesAndZipsByStates({
        states: selectedStates,
        cities: [],
        searchMode: searchMode,
        searchValue: key,
      });

      let filteredCities = [];
      result.then(res => {
        filteredCities = res[0]?.cities?.map(item => {
          return { id: item.id, label: item.name };
        });

        setCityOptions(filteredCities);
      });
    }
  };

  const yesAction = () => {
    setErrorMessageOpen(false);
    setSaveDisabled(false);
    setEnableSaveOnOverlap(true);
  };

  const enableSaveOnChanges = () => {
    const values = getValues();
    const { pFacilityEmployer, piDiscipline, piSpecialty, pState, piCity, pStart, pCountry, piZip } = values;

    if (addWorkExperience) {
      if (!pFacilityEmployer) {
        setSaveDisabled(true);
        return;
      } else if (!piDiscipline) {
        setSaveDisabled(true);
        return;
      } else if (!piSpecialty) {
        setSaveDisabled(true);
        return;
      } else if (!pState && (pCountry?.id === CountryCodes.USA || pCountry?.id === CountryCodes.CANADA)) {
        setSaveDisabled(true);
        return;
      } else if (!piCity && pCountry?.id === CountryCodes.USA) {
        setSaveDisabled(true);
        return;
      } else if (!pStart) {
        setSaveDisabled(true);
        return;
      } else {
        setSaveDisabled(false);
        return;
      }
    } else {
      if (!pStart) {
        setSaveDisabled(true);
        return;
      } else if (errorMessageOpen) {
        setSaveDisabled(true);
        return;
      } else if (!!pState && !piCity && pCountry?.id === CountryCodes.USA) {
        props.setSaveDisabled(true);
        return;
      } else if (
        piZip?.length > 5 ||
        (!(pCountry?.id === CountryCodes.USA || pCountry?.id === CountryCodes.CANADA) && pState?.length > 100) ||
        (pCountry?.id !== CountryCodes.USA && piCity?.length > 100)
      ) {
        props.setSaveDisabled(true);
        return;
      } else {
        if (isDatesChanged) {
          if (hasDateOverlapping()) {
            if (enableSaveOnOverlap) {
              props.setSaveDisabled(false);
            } else {
              props.setSaveDisabled(true);
            }
          }
        } else {
          setSaveDisabled(false);
        }
        return;
      }
    }
  };

  return (
    <>
      <div className={classes.contentContainer}>
        <LayoutGrid container direction="column" className={classes.root} spacing={4}>
          {addWorkExperience ? (
            <>
              <LayoutGridItem container display="flex" flexDirection="row">
                <LayoutGridItem xs={8.35}></LayoutGridItem>
              </LayoutGridItem>
              <LayoutGridItem item>
                <LayoutGrid
                  container
                  direction="row"
                  data-testid="container"
                  justifyContent="flex-start"
                  spacing={6}
                  sx={theme => ({ marginBottom: theme.spacing(9) })}
                >
                  <LayoutGridItem item xs={5}>
                    <LayoutGridItem container direction="column" spacing={4}>
                      <LayoutGridItem container>
                        <LayoutGridItem item xs={9}>
                          <Controller
                            name="piTravelerCompany"
                            defaultValue={null}
                            control={control}
                            render={({ ref, ...rest }) => (
                              <TypeAhead
                                options={travelCompanyData || []}
                                label={t('candidate.dialogActions.staffingAgency')}
                                {...rest}
                                isError={errors['piTravelerCompany']}
                              />
                            )}
                          />
                        </LayoutGridItem>
                      </LayoutGridItem>
                      <LayoutGridItem container>
                        <LayoutGridItem item xs={12}>
                          <FormInputText
                            name="pFacilityEmployer"
                            label={'Facility/Employer *'}
                            inputValue={getValues('pFacilityEmployer')}
                            message={errors['pFacilityEmployer']?.message || ''}
                          />
                        </LayoutGridItem>
                      </LayoutGridItem>
                      <LayoutGridItem container>
                        <LayoutGridItem item xs={9}>
                          <Controller
                            name="pCountry"
                            defaultValue={null}
                            control={control}
                            render={({ ref, ...rest }) => (
                              <TypeAhead
                                options={countriesData}
                                label="Country"
                                {...rest}
                                isError={errors['pCountry']}
                              />
                            )}
                          />
                        </LayoutGridItem>
                      </LayoutGridItem>
                      <LayoutGridItem container display="flex" direction="row" spacing={2}>
                        <LayoutGridItem item xs={5}>
                          {watch('pCountry')?.id === CountryCodes.USA ||
                          watch('pCountry')?.id === CountryCodes.CANADA ? (
                            <Controller
                              name="pState"
                              defaultValue={statesOptions[0] || null}
                              control={control}
                              render={({ ref, ...rest }) => (
                                <TypeAhead
                                  options={statesOptions || []}
                                  label="State *"
                                  {...rest}
                                  isError={errors['pState']}
                                />
                              )}
                            />
                          ) : (
                            <FormInputTextField name="pState" label={'State'} />
                          )}
                        </LayoutGridItem>
                        <LayoutGridItem item xs={4}>
                          {selectedCountry?.id === CountryCodes.USA ? (
                            <Controller
                              name="piCity"
                              control={control}
                              render={({ ref, ...rest }) => (
                                <TypeAhead
                                  options={cityOptions || []}
                                  label="City *"
                                  {...rest}
                                  onInputChange={(text, reason) =>
                                    reason != 'reset' && debounce(text => onTextSearch(text, 'c'), 1500)(text)
                                  }
                                  onFocus={() => {
                                    const cityOpts =
                                      !!rest.value && !defaultCities.some(item => item.id === rest.value.id)
                                        ? [rest.value, ...defaultCities]
                                        : defaultCities;
                                    setCityOptions(cityOpts);
                                  }}
                                />
                              )}
                            />
                          ) : (
                            <FormInputTextField name="piCity" label={'City'} required={false} />
                          )}
                        </LayoutGridItem>
                        <LayoutGridItem item xs={3}>
                          <FormInputText
                            name="piZip"
                            label={'Zip Code'}
                            inputValue={getValues('piZip')}
                            message={errors['piZip']?.message || ''}
                          />
                        </LayoutGridItem>
                      </LayoutGridItem>
                      <LayoutGridItem container display="flex" direction="row" spacing={2}>
                        <LayoutGridItem item xs={5}>
                          <FormInputCheckbox
                            name={`pSupervisor`}
                            isChecked={pSupervisor}
                            handleChecked={() => {
                              setPSupervisor(!pSupervisor);
                            }}
                            checkboxLabel={`Supervisor/Charge`}
                            isDisabled={false}
                            checkboxErrorName={`CheckboxError pSupervisor`}
                          ></FormInputCheckbox>
                        </LayoutGridItem>
                        <LayoutGridItem item>
                          <FormInputCheckbox
                            name={`pTeletherapy`}
                            isChecked={pTeletherapy}
                            handleChecked={() => {
                              setPTeletherapy(!pTeletherapy);
                            }}
                            checkboxLabel={`Telehealth/Teletherapy`}
                            isDisabled={false}
                            checkboxErrorName={`CheckboxError pTeletherapy`}
                          ></FormInputCheckbox>
                        </LayoutGridItem>
                      </LayoutGridItem>
                      <LayoutGridItem container>
                        <LayoutGridItem item xs={5} pt={'10px'}>
                          <FormInputCheckbox
                            name={`pHospital`}
                            isChecked={pHospital}
                            handleChecked={() => {
                              setPHospital(!pHospital);
                            }}
                            checkboxLabel={`Teaching Hospital`}
                            isDisabled={false}
                            checkboxErrorName={`CheckboxError pHospital`}
                          ></FormInputCheckbox>
                        </LayoutGridItem>
                      </LayoutGridItem>
                    </LayoutGridItem>
                  </LayoutGridItem>
                  <LayoutGridItem item xs={3}>
                    <LayoutGridItem container direction="column" spacing={4}>
                      <LayoutGridItem container>
                        <LayoutGridItem item xs={12}>
                          <Controller
                            name="piDiscipline"
                            control={control}
                            render={({ ref, ...rest }) => (
                              <TypeAhead
                                options={typeAheadOptions?.disciplines || []}
                                label="Discipline *"
                                {...rest}
                                isError={errors.piDiscipline ? true : false}
                                isDisabled={false}
                              />
                            )}
                          />
                        </LayoutGridItem>
                      </LayoutGridItem>
                      <LayoutGridItem container>
                        <LayoutGridItem item xs={12}>
                          <Controller
                            name="piSpecialty"
                            control={control}
                            defaultValue={getValues('piSpecialty') || {}}
                            render={({ ref, ...rest }) => (
                              <TypeAhead
                                options={filteredSpecialty.length ? filteredSpecialty : []}
                                label="Specialty *"
                                {...rest}
                                isError={errors['piSpecialty']}
                                isDisabled={false}
                              />
                            )}
                          />
                        </LayoutGridItem>
                      </LayoutGridItem>
                      <LayoutGridItem container>
                        <LayoutGridItem item xs={9}>
                          <FormInputText
                            name="pBeds"
                            label={'# of Beds in Facility'}
                            require={false}
                            type={'number'}
                            inputValue={getValues('pBeds')}
                            message={errors['pBeds']?.message || ''}
                          />
                        </LayoutGridItem>
                      </LayoutGridItem>
                      <LayoutGridItem container>
                        <LayoutGridItem item xs={12}>
                          <FormInputText
                            name="pUnitFloorDept"
                            label={'Unit/Floor/Dept'}
                            require={false}
                            inputValue={getValues('pUnitFloorDept')}
                            message={errors['pUnitFloorDept']?.message || ''}
                            readOnly={false}
                          />
                        </LayoutGridItem>
                      </LayoutGridItem>
                      <LayoutGridItem container>
                        <LayoutGridItem item xs={12}>
                          <FormInputText
                            name="pPosition"
                            label={'Position Held'}
                            require={false}
                            inputValue={getValues('pPosition')}
                            message={errors['pPosition']?.message || ''}
                          />
                        </LayoutGridItem>
                      </LayoutGridItem>
                    </LayoutGridItem>
                  </LayoutGridItem>
                  <LayoutGridItem item xs={4}>
                    <LayoutGridItem
                      container
                      direction="column"
                      spacing={4}
                      sx={theme => ({ marginTop: theme.spacing(0.5) })}
                    >
                      <LayoutGridItem sx={{ marginLeft: theme.spacing(2) }}>
                        <FormInputCheckbox
                          name={`currentlyWorking`}
                          handleChecked={e => handleCurrentlyWorkingChange(e)}
                          isChecked={getValues('currentlyWorking') || currentEmployer}
                          checkboxLabel={'Current Employer'}
                          color="secondary"
                        ></FormInputCheckbox>
                      </LayoutGridItem>
                      <LayoutGridItem container display="flex" direction="row" xs={4} spacing={2}>
                        <LayoutGridItem item xs={5}>
                          <FormInputDatePicker
                            name={'pStart'}
                            label={'Start Date *'}
                            handleChange={handleStartDateChange}
                            customClass={classes.datePickerInput}
                            labelClass={classes.label}
                            trailingIconVisible={true}
                            inputValue={getValues('pStart')}
                          ></FormInputDatePicker>
                        </LayoutGridItem>
                        <LayoutGridItem item xs={5}>
                          <FormInputDatePicker
                            name={'pEnd'}
                            label={'End Date'}
                            handleChange={handleWorkEndDateChange}
                            readOnly={false}
                            customClass={classes.datePickerInput}
                            labelClass={classes.label}
                            trailingIconVisible={true}
                            inputValue={getValues('pEnd')}
                          ></FormInputDatePicker>
                        </LayoutGridItem>
                      </LayoutGridItem>
                      <LayoutGridItem container>
                        <LayoutGridItem item xs={12}>
                          <Controller
                            name="pReason"
                            defaultValue={null}
                            control={control}
                            render={({ ref, ...rest }) => (
                              <TypeAhead
                                options={typeAheadOptions?.reasonForLeaving || []}
                                label="Reason for Leaving"
                                {...rest}
                                isError={errors['pReason']?.message || ''}
                              />
                            )}
                          />
                        </LayoutGridItem>
                      </LayoutGridItem>

                      {candidateManageEquipmentProcedure.enabled && (
                        <>
                          <LayoutGridItem item xs={12} sx={{ marginTop: theme.spacing(-0.5) }}>
                            <FormInputText
                              name="pnotes"
                              label={t('candidate.referenceGrid.equipmentProcedures')}
                              handleChange={handlePNotes}
                              inputValue={getValues('pnotes')}
                              message={errors['pnotes']?.message || ''}
                              multiline
                              spellCheck
                              inputLength={1000}
                              charCount={charCount}
                              customClass={classes.textBox}
                            />
                          </LayoutGridItem>
                        </>
                      )}
                    </LayoutGridItem>
                  </LayoutGridItem>
                </LayoutGrid>
              </LayoutGridItem>
            </>
          ) : (
            <>
              {errorMessageOpen && (
                <LayoutGridItem style={{ height: theme.spacing(17) }}>
                  <LayoutGrid
                    container
                    direction="row"
                    className={classes.root}
                    spacing={2}
                    style={{
                      backgroundColor: `${theme.palette.system.lightPaleRed}`,
                      padding: '10px',
                      paddingBottom: '15px',
                    }}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    xs={12}
                  >
                    <LayoutGridItem>
                      <LayoutGrid container direction="row" spacing={2}>
                        <LayoutGridItem>
                          <ErrorOutlineIcon style={{ color: `${theme.palette.system.errorIconRed}` }} />
                        </LayoutGridItem>
                        <LayoutGridItem>
                          <LayoutGrid container direction="column" spacing={0}>
                            <LayoutGridItem>
                              <span
                                style={{
                                  color: `${theme.palette.system.persianPlum}`,
                                  fontSize: '14px',
                                  fontWeight: 400,
                                }}
                              >
                                {t('candidate.dialogActions.datesOverlapMessage')}
                              </span>
                            </LayoutGridItem>
                            <LayoutGridItem>
                              <span
                                style={{
                                  color: `${theme.palette.system.persianPlum}`,
                                  fontSize: '14px',
                                  fontWeight: 400,
                                }}
                              >
                                {t('candidate.dialogActions.processConfirmation')}
                              </span>
                            </LayoutGridItem>
                          </LayoutGrid>
                        </LayoutGridItem>
                      </LayoutGrid>
                    </LayoutGridItem>
                    <LayoutGridItem>
                      <LayoutGrid
                        container
                        direction="row"
                        className={classes.root}
                        spacing={2}
                        style={{ paddingRight: '15px' }}
                      >
                        <LayoutGridItem>
                          <span
                            className={classes.buttonStyle}
                            onClick={() => {
                              setErrorMessageOpen(false);
                              setEnableSaveOnOverlap(false);
                            }}
                          >
                            {'No'}
                          </span>
                        </LayoutGridItem>
                        <LayoutGridItem>
                          <span className={classes.buttonStyle} onClick={() => yesAction()}>
                            {'Yes'}
                          </span>
                        </LayoutGridItem>
                      </LayoutGrid>
                    </LayoutGridItem>
                  </LayoutGrid>
                </LayoutGridItem>
              )}
              <LayoutGridItem item>
                <LayoutGrid
                  container
                  direction="row"
                  data-testid="container"
                  justifyContent="flex-start"
                  alignItems={'center'}
                  spacing={'12px'}
                  xs={10}
                >
                  <LayoutGridItem item xs={2.5}>
                    <FormInputDatePicker
                      name={'pStart'}
                      label={'Start Date *'}
                      handleChange={handleStartDateChange}
                      customClass={classes.datePickerInput}
                      trailingIconVisible={true}
                      inputValue={getValues('pStart')}
                    ></FormInputDatePicker>
                  </LayoutGridItem>
                  <LayoutGridItem item xs={2.5}>
                    <FormInputDatePicker
                      name={'pEnd'}
                      label={'End Date'}
                      handleChange={handleEndDateChange}
                      customClass={classes.datePickerInput}
                      trailingIconVisible={true}
                      inputValue={getValues('pEnd')}
                    ></FormInputDatePicker>
                  </LayoutGridItem>
                  <LayoutGridItem item xs={2.5}>
                    <FormInputCheckbox
                      name={`pOngoing`}
                      isChecked={pOngoing}
                      handleChecked={() => {
                        setPOngoing(!pOngoing);
                        if (!pOngoing == true) {
                          setValue('pEnd', null);
                          setEndValue(null);
                        }
                      }}
                      checkboxLabel={`Ongoing`}
                      isDisabled={false}
                      checkboxErrorName={`CheckboxError pOngoing`}
                    ></FormInputCheckbox>
                  </LayoutGridItem>
                </LayoutGrid>
              </LayoutGridItem>
              <LayoutGridItem
                item
                sx={{
                  marginTop: errors.pEnd || errors.Start ? '-36px' : '0px',
                }}
              >
                <LayoutGrid
                  container
                  direction="row"
                  data-testid="container"
                  justifyContent="flex-start"
                  alignItems={'center'}
                  spacing={'12px'}
                  xs={10}
                  sx={{ marginBottom: theme.spacing(3) }}
                >
                  <LayoutGridItem item xs={4}>
                    <Controller
                      name="pCountry"
                      defaultValue={null}
                      control={control}
                      render={({ ref, ...rest }) => (
                        <TypeAhead options={countriesData} label="Country" {...rest} isError={errors['pCountry']} />
                      )}
                    />
                  </LayoutGridItem>
                  <LayoutGridItem item xs={2.7}>
                    {watch('pCountry')?.id === CountryCodes.USA || watch('pCountry')?.id === CountryCodes.CANADA ? (
                      <Controller
                        name="pState"
                        defaultValue={null}
                        control={control}
                        render={({ ref, ...rest }) => (
                          <TypeAhead options={statesOptions || []} label="State" {...rest} isError={errors['pState']} />
                        )}
                      />
                    ) : (
                      <div className={classes.textFieldError}>
                        <FormInputTextField
                          name="pState"
                          label={'State'}
                          maxLength={100}
                          maxLengthErrorMessage={t(`global.textValidations.maxCharacterLimit`)}
                        />
                      </div>
                    )}
                  </LayoutGridItem>
                  <LayoutGridItem item xs={2.7}>
                    {watch('pCountry')?.id === CountryCodes.USA ? (
                      <Controller
                        name="piCity"
                        control={control}
                        render={({ ref, ...rest }) => (
                          <TypeAhead
                            options={cityOptions || []}
                            label={
                              selectedCountry?.id === CountryCodes.USA && !!selectedState?.label ? 'City *' : 'City'
                            }
                            onInputChange={debounce(text => onTextSearch(text, 'c'), 1500)}
                            {...rest}
                          />
                        )}
                      />
                    ) : (
                      <div className={classes.textFieldError}>
                        <FormInputTextField
                          name="piCity"
                          label="City"
                          maxLength={100}
                          maxLengthErrorMessage={t(`global.textValidations.maxCharacterLimit`)}
                        />
                      </div>
                    )}
                  </LayoutGridItem>
                  <LayoutGridItem item xs={2.6}>
                    <div className={classes.textFieldError}>
                      <FormInputTextField
                        name="piZip"
                        label={'Zip Code'}
                        maxLength={5}
                        maxLengthErrorMessage={t(`global.textValidations.maxCharacterLimit`)}
                      />
                    </div>
                  </LayoutGridItem>
                </LayoutGrid>
              </LayoutGridItem>
            </>
          )}
        </LayoutGrid>
        <DialogActions
          {...props.dialogActionsProps}
          color={props.variant === 'blue' ? 'primary' : 'secondary'}
          classes={{
            ...props.dialogActionsProps?.classes,
            root: classes.actionButton,
          }}
        >
          {dialogActions.map((button, index) => (
            <div key={`generic-dialog-button-${index}`}>
              {!button.hidden &&
                (button.renderer ? (
                  button.renderer(button)
                ) : (
                  <GenericDialogButton {...button} onClick={button.useDialogOnCloseEvent ? onclose : button.onClick} />
                ))}
            </div>
          ))}
        </DialogActions>
      </div>
    </>
  );
};
