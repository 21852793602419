import { Box } from 'amn-ui-core';
import { Expandable } from 'app/components/Common/Expandable/Expandable';
import React from 'react';
import styledComponent from 'styled-components';
import { ControlledSwitch } from '.';

export const CustomExpandable = ({
  expanded = false,
  content,
  label,
  switchState,
  handleSwitchChange,
  disabled = false,
  disableSwitch = true,
  hiddenSwitch = false,
  titleAddOn = null as JSX.Element | null,
  unmountOnExit = true,
}) => (
  <Expandable
    expanded={expanded}
    disabled={disabled}
    unmountOnExit={unmountOnExit}
    titleComponent={
      <TitleHolder>
        <ControlledSwitch
          label={label}
          switchState={switchState}
          handleChange={handleSwitchChange}
          disabled={disableSwitch}
          hiddenSwitch={hiddenSwitch}
        />
        {titleAddOn}
      </TitleHolder>
    }
  >
    {content}
  </Expandable>
);

const TitleHolder = styledComponent(Box)`
  display: flex;
  flex-direction: row;
  gap: 6px;
`;
