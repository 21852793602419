import { IFacilityPreferences } from './types';

export const initialState: IFacilityPreferences = {
  submissionLimits: { activePlacementCounts: null },
  candidateTasks: false,
  rfoToSent: false,
  sendPacket: false,
  wfcToRfo: false,
  updateInprogress: false,
  templateUpdateInProgress: false,
  confirmationTemplates: [],
  facilityTemplate: null,
  bookingTemplate: {
    value: '',
    name: '',
  },
  bookingFacilityDirty: false,
  bookingFacilityReset:false,
  confirmationDialog:false,
  isPreferenceChanged:false,
  tabValue: 0,
  miscellaneous: {
    disableEmailContainer: true,
    disablePacketContainer: true,
  },
  savePacket: false,
  submissionPacketSetup: null,
  submissionEmailSetup: null,
  existingUnits:[],
  updateDialogOpen: false,
  preferenceSelection: [],
};
