import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './Tasks.redux';
import { RootState } from 'types';

const selectDomain = (state: RootState) => state?.taskDetails || initialState;

export const selectTaskCreation = createSelector([selectDomain], task => task);

export const selectInternalTaskChangedFlag = createSelector([selectDomain], task => task.internalTaskFlagChanged);

export const selectExternalTaskChangedFlag = createSelector([selectDomain], task => task.externalTaskFlagChanged);

export const selectInternalTaskDetailsData = createSelector([selectDomain], task => task.internalTaskDetailsData);

export const selectExternalTaskDetailsData = createSelector([selectDomain], task => task.externalTaskDetailsData);

export const selectTaskComments = createSelector([selectDomain], task => task.commentList);

export const selectCurrentComment = createSelector([selectDomain], task => task.newComment);

export const selectTaskId = createSelector([selectDomain], task => task.newId);

export const selectStatusUpdateId = createSelector([selectDomain], task => task.taskStatusUpdateModalId);

export const selectShowStatusModal = createSelector([selectDomain], task => task.showTaskStatusModal);

export const selectIsDetailsModalOpen = createSelector([selectDomain], task => task.isDetailsModelOpen);

export const selectTaskStatusUpdateFlag = createSelector([selectDomain], task => task.taskStatusUpdateFlag);

export const SelectedContainer = createSelector([selectDomain], task => task.currentContainer);

export const selectInternalTaskListData = createSelector([selectDomain], task => task.internalTaskListData);

export const selectInternalTaskItem = createSelector([selectDomain], task => task.internalTaskdata);

export const selectExternalTaskItem = createSelector([selectDomain], task => task.externalTaskdata);

export const selectTaskErrors = createSelector([selectDomain], task => task.errors);

export const selectedUpdatedId = createSelector([selectDomain], task => task.updatedId);

export const selectTaskUpdatedData = createSelector([selectDomain], task => task.updatedData);

export const selectedTaskGridDataItems = createSelector([selectDomain], task => task.taskGridData?.items);

export const selectedTaskGridDataPaging = createSelector([selectDomain], task => task.taskGridData?.paging);

export const selectedCategoryList = createSelector([selectDomain], task => task.categoryList);

export const selectTaskEntity = createSelector([selectDomain], task => task.taskEntity);

export const selectEntityId = createSelector([selectDomain], task => task.entityId);

export const selectTaskGroupedList = createSelector([selectDomain], task => task.groupedList);

export const selectCreatedTaskData = createSelector([selectDomain], task => task.createdTaskData);

export const selectTaskStatusData = createSelector([selectDomain], task => task.taskStatusData);

export const selectNotificationContainer = createSelector([selectDomain], task => task.notificationContainer);

export const selectStatusContainer = createSelector([selectDomain], task => task.statusContainer);

export const selectNotificationPrimaryContext = createSelector([selectDomain], task => task.notificationPrimaryContext);

export const selectUnreadLogCount = createSelector([selectDomain], task => task.unreadNotificationLog?.totalRecords);

export const selectPlacementBrandId = createSelector([selectDomain], task => task.placementBrandId);

export const selectNewTaskAttachments = createSelector([selectDomain], task => task.newTaskAttachments);

export const selectExistingTaskAttachments = createSelector([selectDomain], task => task.existingAttachments);

export const selectNewCommentCreated = createSelector([selectDomain], task => task.createdComment);

export const selectAttachmentDeleted = createSelector([selectDomain], task => task.isAttachmentDeleted);

export const selectExternalTaskCreated = createSelector([selectDomain], task => task.externalTaskCreatedData);

export const selectExternalTaskId = createSelector([selectDomain], task => task.externalTaskId);

export const selectExternalTaskCategoryList = createSelector([selectDomain], task => task.externalCategoryList);

export const selectExternalTaskList = createSelector([selectDomain], task => task.externalTaskList);

export const selectIsExternal = createSelector([selectDomain], task => task.isExternal);

export const selectCurrentExternalTask = createSelector([selectDomain], task => task.currentExternalTask);

export const selectTaskStickChosen = createSelector([selectDomain], task => task.taskStickChosen);

export const selectSidePanelChoosen = createSelector([selectDomain], task => task.sidePanelChoosen);

export const selectCueRecipient = createSelector([selectDomain], task => task.cueRecipientList);

export const selectUserCuedTo = createSelector([selectDomain], task => task.isUserCueTo);

export const selectCurrentUserCuedTo = createSelector([selectDomain], task => task.currentUserCued);

export const selectCueNotesList = createSelector([selectDomain], task => task.cueNotesList);

export const selectCueNoteCreated = createSelector([selectDomain], task => task.createdCueNoteData);

export const selectCueNoteId = createSelector([selectDomain], task => task.cueNoteId);

export const selectLastRecipient = createSelector([selectDomain], task => task.lastRecipientDetails);

export const selectCheckPanelChange = createSelector([selectDomain], task => task.checkPanelChange);

export const selectedIsExternalTaskUpdated = createSelector([selectDomain], task => task.isExternalTaskUpdated);

export const selectCueNoteReplyParent = createSelector([selectDomain], task => task.cueNoteReplyParent);

export const selectIsCueNoteCreated = createSelector([selectDomain], task => task.isCueNoteCreated);

export const newCueNoteData = createSelector([selectDomain], task => task.cueCreatedData);

export const selectNotificationLog = createSelector([selectDomain], task => task.notificationLogData);

export const selectEmailDeliveryAssociateRecords = createSelector(
  [selectDomain],
  task => task.emailDeliveryAssociateRecors,
);

export const selectActivityFeedFilterList = createSelector([selectDomain], task => task.activityFeedFilterList);

export const selectisFilterApplied = createSelector([selectDomain], task => task.isFilterApplied);

export const selectCueOptionsforPlacement = createSelector([selectDomain], task => task.cueListforPlacementOptions);

export const selectSubCategory1 = createSelector([selectDomain], task => task.subCategory1);

export const selectSubCategory2 = createSelector([selectDomain], task => task.subCategory2);

export const selectVersionUpdated = createSelector([selectDomain], task => task.versionUpdated);

export const selectStatusAppliedAll = createSelector([selectDomain], task => task.isStatusAppliedAll);

export const selectDueDateFirstView = createSelector([selectDomain], task => task.dueDateFirstView);

export const selectIsTaskUpdateFlag = createSelector([selectDomain], task => task.isUpdatingTask);

export const selectInternalTaskComments = createSelector([selectDomain], task => task.internalTaskDetailsData.comments);

export const selectSubTaskName = createSelector([selectDomain], task => task.subTaskName);

export const selectDismissNotificationId = createSelector([selectDomain], task => task.dismissNotificationId);

//#region Notifications Split Design
export const unReadNotificationCount = createSelector([selectDomain], task => {
  return {
    unreadTaskNotificationsCount: task.notifications?.unreadTaskNotifications,
    unreadOrderMatchNotificationsCount: task.notifications?.unreadOrderMatchNotifications,
    unreadAlertNotificationsCount: task.notifications?.unreadAlertNotifications,
  };
});
export const selectRequestedAlertCategory = createSelector([selectDomain], task => task.requestedAlertCategory);

//#endregion
