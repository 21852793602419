import { Grid } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { deriveFieldName } from '../helper';
import { BillRateField } from './BillRateRowItem';
import { LocalBillRateField } from './LocalBillRateField';
import { useSelector } from 'react-redux';
import { selectCreateOrderAttributes } from 'store/redux-store/create-order-ocbr/selector';

const useStyles = makeStyles()(theme => ({
  itemStyle: {
    padding: '6px',
  },
  fadeOut: {
    opacity: 0,
    width: 0,
    height: 0,
    transition: 'width 0.5s linear, height 0.5s linear, opacity 0.5s',
    pointerEvents: 'none',
  },
  fadeIn: {
    opacity: 1,
    width: '20px',
    height: '76px',
    transition: 'width 0.5s, height 0.5s, opacity 0.5s linear',
  },
}));

const deriveKeyFromSkill = (skillSet, ...args) => {
  return deriveFieldName([skillSet.disciplineAbbreviation, skillSet.specialtyName, skillSet.subSpecialtyName, ...args]);
};

export const FlatRateItem = ({ skillsetShiftInfo }) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const { showLocalBillRate } = useSelector(selectCreateOrderAttributes);

  return (
    <Grid container direction="column" xs={10}>
      <Grid item classes={{ item: classes.itemStyle }}>
        {skillsetShiftInfo?.shiftDetails[0]?.shiftRatesArray[0]?.shiftID === null && (
          <BillRateField
            label={t('order.newOrder.rateCard.travelBillRate')}
            name={deriveKeyFromSkill(skillsetShiftInfo, 'travel')}
            shiftRates={skillsetShiftInfo?.shiftDetails[0]?.shiftRatesArray}
            shiftName={skillsetShiftInfo?.shiftDetails[0]?.shiftName}
          />
        )}
      </Grid>
      <Grid item classes={{ item: cx(classes.itemStyle, showLocalBillRate ? classes.fadeIn : classes.fadeOut) }}>
        {skillsetShiftInfo?.shiftDetails[0]?.shiftRatesArray[0]?.shiftID === null && (
          <LocalBillRateField
            label={t('order.newOrder.rateCard.localBillRate')}
            name={deriveKeyFromSkill(skillsetShiftInfo, 'local')}
            shiftRates={skillsetShiftInfo?.shiftDetails[0]?.localBillRateOptions}
          />
        )}
      </Grid>
    </Grid>
  );
};
