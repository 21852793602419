import { generateFieldChips } from 'app/components/AmWorkDesk/PlacementsDesk/filterHelper';
import moment from 'moment-timezone';
import _isArray from 'lodash/isArray';
import _ from 'lodash';

export enum TaskSearchFilterName {
  candidateName = 'candidateName',
  taskName = 'taskName',
  taskCategory = 'taskCategories',
  placementId = 'placementIds',
  candidateId = 'candidateIds',
  facilityId = 'facilityIds',
  orderId = 'orderIds',
  status = 'taskStatusIds',
  facility = 'facility',
  dueDate = 'dueDate',
  assignedTo = 'assignedToIds',
  createdBy = 'createdByIds',
}

export const defaultTaskFilterValues = {
  [TaskSearchFilterName.candidateName]: null,
  [TaskSearchFilterName.taskName]: null,
  [TaskSearchFilterName.taskCategory]: null,
  [TaskSearchFilterName.placementId]: null,
  [TaskSearchFilterName.candidateId]: null,
  [TaskSearchFilterName.facilityId]: null,
  [TaskSearchFilterName.orderId]: null,
  [TaskSearchFilterName.status]: null,
  [TaskSearchFilterName.facility]: null,
  [TaskSearchFilterName.dueDate]: null,
  [TaskSearchFilterName.assignedTo]: null,
  [TaskSearchFilterName.createdBy]: null,
};

export const returnTaskFilterBody = (filterName, filter, data, remove = false) => {
  if (!data || !filter) return;
  const temp = { ...data };
  const formattedFilterData = _isArray(filter) ? filter.map(f => f.value) : filter;
  // organizes filter items
  switch (filterName) {
    case TaskSearchFilterName.candidateId:
      if (remove) {
        temp[TaskSearchFilterName.candidateId] =
          temp[TaskSearchFilterName.candidateId]?.filter(t => t !== formattedFilterData?.value) || [];
      } else {
        temp[TaskSearchFilterName.candidateId] = formattedFilterData;
      }
      break;
    case TaskSearchFilterName.candidateName:
      if (remove) {
        temp[TaskSearchFilterName.candidateName] =
          temp[TaskSearchFilterName.candidateName]?.filter(t => t !== formattedFilterData?.value) || [];
      } else {
        temp[TaskSearchFilterName.candidateName] = formattedFilterData;
      }
      break;
    case TaskSearchFilterName.facilityId:
      if (remove) {
        temp[TaskSearchFilterName.facilityId] =
          temp[TaskSearchFilterName.facilityId]?.filter(t => t !== formattedFilterData?.value) || [];
      } else {
        temp[TaskSearchFilterName.facilityId] = formattedFilterData;
      }
      break;
    case TaskSearchFilterName.facility:
      if (remove) {
        temp[TaskSearchFilterName.facility] =
          temp[TaskSearchFilterName.facility]?.filter(t => t !== formattedFilterData?.value) || [];
      } else {
        temp[TaskSearchFilterName.facility] = formattedFilterData;
      }
      break;
    case TaskSearchFilterName.dueDate:
      if (remove) {
        temp.dueDateStart = null;
        temp.dueDateEnd = null;
      } else {
        temp.dueDateStart = moment(filter?.startDate).format();
        temp.dueDateEnd = moment(filter?.endDate).format();
      }
      break;
    default:
      if (remove && temp[filterName]) {
        temp[filterName] = temp[filterName]?.filter(t => t !== formattedFilterData?.value) || [];
      } else if (temp[filterName]) {
        temp[filterName] = formattedFilterData;
      }
      break;
  }
  return temp;
};

export const generateTaskChips = (values, disableDelete, selectedChips?) =>
  Object.fromEntries(
    Object.entries(values).map(field => [
      field[0],
      generateFieldChips(
        field[1],
        selectedChips ? selectedChips[field[0]] : undefined,
        field[0] === 'dueDate' ? 'Due Date' : field[0] === 'orderIds' ? 'OID' : undefined,
        false,
        null,
        disableDelete,
      ),
    ]),
  );

export const formatFilterValues = values => {
  const formValues = { ...values };
  if (values?.dueDate) {
    const startDateValue = new Date(values?.dueDate?.startDate?.setHours(18));
    const endDatevalue = new Date(values?.dueDate?.endDate?.setHours(13));
    formValues.dueDate = {
      startDate: startDateValue,
      endDate: endDatevalue,
    };
  }
  return formValues;
};

interface KeyValue {
  [key: string]: any;
}

export const convertArrayToKeyValuePairs = (arr: [string, any][]) => {
  const result: KeyValue = {};
  for (const [key, value] of arr) {
    switch (key) {
      case 'assignedToIds':
      case 'taskStatusIds':
        result[key] = value.map(item =>
          key === 'assignedToIds'
            ? {
                id: item.id,
                value: item.value,
                name: item.name,
              }
            : {
                id: item.id,
                name: item.name,
                value: item.value,
              },
        );
        break;
      case 'dueDate':
        result[key] = new Date(value?.startDate)?.toLocaleString('en-US', {
          timeZone: 'America/Los_Angeles',
        });
        result[key] = new Date(value?.endDate)?.toLocaleString('en-US', {
          timeZone: 'America/Los_Angeles',
        });
      default:
        result[key] = value;
        break;
    }
  }
  return result;
};

export const convertSelectedFilterValue = (arr: [string, any][]) => {
  const result: KeyValue = {};
  const formattedDate: any = { startDate: null, endDate: null };

  for (const [key, value] of arr) {
    if (key === 'dueDate') {
      const dateValue = value;
      if (dateValue) {
        const startDateValue = moment.tz(dateValue?.startDate, 'America/Los_Angeles').format();
        const endDateValue = moment.tz(dateValue?.endDate, 'America/Los_Angeles').format();
        formattedDate.startDate = startDateValue;
        formattedDate.endDate = endDateValue;
      }
    }
    switch (key) {
      case 'assignedToIds':
      case 'taskCategories':
      case 'taskStatusIds':
      case 'candidateName':
      case 'candidateIds':
      case 'placementIds':
      case 'orderIds':
      case 'facility':
      case 'facilityIds':
      case 'createdByIds':
        result[key] = value.map(item => item.value);
        break;
      case 'dueDate':
        result[key] = formattedDate;
        break;
      default:
        result[key] = value;
        break;
    }
  }
  result.dueDateStart = formattedDate?.startDate;
  result.dueDateEnd = formattedDate?.endDate;
  return result;
};

export const setFormValues = (formData, setValue, DefaultDateValue?) => {
  setValue('assignedToIds', formData?.assignedToIds);
  setValue('taskCategories', formData?.taskCategories);
  setValue('taskStatusIds', formData?.taskStatusIds);
  setValue('dueDate', DefaultDateValue);
  setValue('candidateName', formData?.candidateName);
  setValue('candidateIds', formData?.candidateIds);
  setValue('placementIds', formData?.placementIds);
  setValue('orderIds', formData?.orderIds);
  setValue('facility', formData?.facility);
  setValue('facilityIds', formData?.facilityIds);
  setValue('createdByIds', formData?.createdByIds);
};

export const formatDateValue = selectedFilters => {
  const startDateValue = new Date(selectedFilters?.dueDate?.startDate);
  const endDateValue = new Date(selectedFilters?.dueDate?.endDate);
  const formattedDateValue = { startDate: startDateValue, endDate: endDateValue };
  return formattedDateValue;
};

export const formatSelectedFilter = (selectedFilter: any) => {
  const dueDate = selectedFilter?.find(([key]) => key === 'dueDate');
  const dueDateIndex = selectedFilter?.findIndex(([key]) => key === 'dueDate');
  if (dueDate) {
    const startDateValue = dueDate[1] ? new Date(dueDate[1]?.startDate)?.toLocaleString() : new Date().toLocaleString();
    const endDateValue = dueDate[1] ? new Date(dueDate[1]?.endDate)?.toLocaleString() : new Date().toLocaleString();
    const formatedDateValue = { startDate: startDateValue, endDate: endDateValue };
    const newDueDateArray = ['dueDate', dueDate[1] ? formatedDateValue : null];
    const formatedFilterData = [...selectedFilter];
    formatedFilterData[dueDateIndex] = newDueDateArray;
    return formatedFilterData;
  }
  return selectedFilter;
};

export const formatDate = (dateStr: string): string => {
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

export const checkDuplicateFilters = (obj1, obj2) => {
  const formattedObj1 = {
    ...obj1,
    dueDate: obj1?.dueDate
      ? {
          startDate: new Date(obj1.dueDate?.startDate),
          endDate: new Date(obj1.dueDate?.endDate),
        }
      : null,
  };
  const formattedObj2 = {
    ...obj2,
    dueDate: obj2?.dueDate
      ? {
          startDate: obj2.dueDate?.startDate,
          endDate: obj2.dueDate?.endDate,
        }
      : null,
  };
  if (formattedObj1.dueDate) {
    formattedObj1.dueDate.startDate = formatDate(formattedObj1.dueDate?.startDate);
    formattedObj1.dueDate.endDate = formatDate(formattedObj1.dueDate?.endDate);
  }

  if (formattedObj2.dueDate) {
    formattedObj2.dueDate.startDate = formatDate(obj2.dueDate?.startDate);
    formattedObj2.dueDate.endDate = formatDate(obj2.dueDate?.endDate);
  }

  // Check if objects are the same
  const checkSameObjects = _.isEqual(formattedObj1, formattedObj2);
  return checkSameObjects;
};
