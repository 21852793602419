import { Typography } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ListContent = ({ list }) => {
  const { t } = useTranslation();
  return (
    <ul className="unordered-list unordered-list-item">
      {list.map(item =>
        Array.isArray(item) ? (
          <ListContent list={item} />
        ) : (
          <li>
            <Typography variant="body1">{t(item)}</Typography>
          </li>
        ),
      )}
    </ul>
  );
};
