/* eslint-disable i18next/no-literal-string */
import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import React from 'react';

const useStyles = makeStyles<{ height: number; isCredentialing: boolean }>()((theme, { height, isCredentialing }) => ({
  header: {
    padding: '7px 0px',
    fontSize: '14px',
    marginLeft: '-5%',
    maxHeight: '35px',
  },
  headerValue: {
    textAlign: 'center',
    color: 'white',
    fontSize: '14px',
  },
  dataValue: {
    fontSize: '12px',
    '@media (min-width: 1280px)': {
      fontSize: '14px',
    },
  },
  subCategoryParent: {
    backgroundColor: 'rgb(217, 217, 217)',
    border: '1px solid rgba(224, 224, 224, 1)',
    // -------------START small container----------------
    '& [data-id]': {
      width: '34%',
    },
    '& [data-id="0"]': {
      width: '30%',
      backgroundColor: 'rgba(224, 224, 224, 1)',
    },
    '& [data-id="0"][data-parent-id="1"]': {
      backgroundColor: 'rgba(224, 224, 224, 1)',
    },
    '& [data-id="1"]': {
      marginLeft: '-8% !important',
      backgroundColor: 'rgba(224, 224, 224, 1)',
      paddingLeft: '3%',
    },
    '& [data-id="2"]': {
      marginLeft: '-8% !important',
      marginRight: '-8% !important',
      backgroundColor: 'rgba(224, 224, 224, 1)',
      paddingLeft: '3%',
    },
    '& [data-id="3"]': {
      backgroundColor: 'rgba(224, 224, 224, 1)',
      paddingLeft: '3%',
    },
    // --------------END small container-----------------
    // -------------START large container----------------
    '& [data-parent-large="true"]': {
      width: '30% !important',
    },
    '& [data-id="0"][data-parent-large="true"]': {
      marginLeft: '-1%',
      backgroundColor: 'rgba(224, 224, 224, 1)',
    },
    '& [data-id="1"][data-parent-large="true"]': {
      marginLeft: '-7% !important',
      backgroundColor: 'rgba(224, 224, 224, 1)',
    },
    '& [data-id="2"][data-parent-large="true"]': {
      marginLeft: '-6.4% !important',
      marginRight: '-6.4% !important',
      backgroundColor: 'rgba(224, 224, 224, 1)',
    },
    '& [data-id="3"][data-parent-large="true"]': {
      marginRight: '-6.3%',
      backgroundColor: 'rgba(224, 224, 224, 1)',
    },
    // --------------END large container-----------------
  },
  subCategoryFirst: {
    // minWidth: '80px',
    width: '25%',
    height: `${height}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    clipPath: 'polygon(75% 0, 98% 50%, 76% 100%, 0% 100%, 0% 0%)',
  },
  subCategoryLargeFirst: {
    width: '25%',
    height: `${height}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    clipPath: 'polygon(75% 0, 96% 50%, 76% 100%, 0% 100%, 0% 0%)',
  },
  subCategory: {
    width: '25%',
    height: `${height}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    clipPath: 'polygon(4% 0%, 78% 0%, 98% 50%, 79% 100%, 5% 100%, 24% 50%)',
    //clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%, 24% 51%)',
  },
  subCategoryLast: {
    width: '100% !important',
    height: `${height}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sectionFirst: {
    clipPath: 'polygon(88.2% 0, 98.3% 63%, 93.1% 100%, 0% 100%, 0 0)',
    cursor: 'pointer',
    borderTopLeftRadius: '4px',
  },
  secondMiddle: {
    clipPath: 'polygon(0 0, 88.2% 0, 98.3% 63%, 93.1% 100%, 4% 100%, 10.1% 63%)',
    cursor: 'pointer',
  },
  sectionMiddleLarge: {
    clipPath: isCredentialing
      ? 'polygon(0px 0px, 88.2% 0px, 98.3% 63%, 93.1% 100%, 3.7% 100%, 8.4% 63%)'
      : 'polygon(0 0, 88.2% 0, 98.3% 63%, 93.1% 100%, 4% 100%, 8.6% 63%)',
    cursor: 'pointer',
  },
  sectionEnd: {
    clipPath: isCredentialing
      ? 'polygon(0px 0px, 100% 0px, 100% 100%, 9% 100%, 19% 65%)'
      : 'polygon(0px 0px, 100% 0px, 100% 100%, 7.5% 100%, 14% 64%)',
    cursor: 'pointer',
  },
  sectionTopRadius: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    overflow: 'hidden',
  },
  sectionBottomRadius: {
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
  tooltipPlacementBottom: {
    margin: 0,
    marginTop: 22,
  },
}));

export interface IGraphicStepper {
  category: { tag: string; showTooltip: boolean; tooltipContent?: string; value?: any; color: string };
  subcategory: {
    tag: string;
    showTooltip?: boolean;
    tooltipContent?: string;
    data: any;
    value?: any;
    icon?: JSX.Element;
  }[];
}

export interface IProps {
  items: IGraphicStepper[];
  handleItemClick: (category, subcategory, value?: any, event?) => void;
  selected: { category: number; subcategory: number | null }[];
  noValueText?: string;
  height?: number;
  isCredentialing?: boolean; // HACK: This is a temporary hack for credentialing while a global multiplier is identified
}

export const GraphicStepper = ({
  items,
  handleItemClick,
  selected,
  noValueText,
  height = 90,
  isCredentialing,
}: IProps) => {
  const { classes } = useStyles({ height: height, isCredentialing: isCredentialing });

  const alpha = [1, 0.83, 0.7, 0.45, 0.2, 0.55, 0.5, 0.45, 0.4, 0.35, 0.3, 0.25, 0.2, 0.15, 0.1, 0];
  const backgroundColor = (index, length) => `rgba(255, 255, 255, ${alpha[index]})`;

  return (
    <Grid item style={{ width: '100%' }}>
      <Grid container wrap="nowrap" style={{ display: 'flex', flexGrow: 1, width: '100%', borderRadius: '4px' }}>
        {items.map((item, a) => {
          const hasSelection = selected && selected.length > 0;
          const isCategorySelected = selected?.find(i => i.category === a) !== undefined;

          return (
            <Grid
              item
              className={classes.sectionTopRadius}
              style={{
                //TODO: @Raj - Refactor composition (Calculating section with and margins) - using canvas ==> Due date: 4th Oct 2022
                width:
                  a === items.length - 1 ? '23%' : `${item.subcategory.length * (isCredentialing ? 9.82 : 6.709)}%`,
                marginRight: `${(isCredentialing ? -2 : 0) + (item.subcategory.length === 5 ? -4.1 : -3.3)}%`,
              }}
              key={a}
            >
              {/* ------------------------ */}
              <Grid
                container
                wrap="nowrap"
                direction="column"
                className={
                  a === 0
                    ? classes.sectionFirst
                    : item.subcategory.length === 4
                    ? classes.secondMiddle
                    : item.subcategory.length === 5
                    ? classes.sectionMiddleLarge
                    : a === items.length - 1
                    ? classes.sectionEnd
                    : classes.secondMiddle
                }
              >
                <Grid
                  item
                  className={classes.header}
                  onClick={e => {
                    !item.category.showTooltip && handleItemClick(a, null, item.category.value, e);
                  }}
                  style={{
                    textAlign: 'center',
                    backgroundColor:
                      !hasSelection || (hasSelection && isCategorySelected) ? item.category.color : '#C0C0C0',
                    color: 'white',
                    marginLeft: a !== items.length - 1 ? '-7%' : '0%',
                    maxWidth: a === items.length - 1 ? '100%' : '100%',
                  }}
                >
                  {/* HEAD */}
                  {item.category.showTooltip && (
                    <CustomTooltip standardMargin tooltipContent={noValueText}>
                      <div className={classes.headerValue}>{item.category.tag}</div>
                    </CustomTooltip>
                  )}
                  {!item.category.showTooltip && <div className={classes.headerValue}>{item.category.tag}</div>}
                </Grid>
                <Grid item style={{ marginLeft: a > 0 ? '4%' : '0%', maxWidth: '100%' }}>
                  {/* ++++++++++++++++++++++++ */}
                  <Grid
                    container
                    wrap="nowrap"
                    className={`${classes.subCategoryParent} ${
                      a === 0 || a === items.length - 1 ? classes.sectionBottomRadius : ''
                    }`}
                  >
                    {item.subcategory.map((subCategoryItem, subCategoryIndex) => {
                      const isSelected =
                        selected?.find(i => i.category === a && i.subcategory === subCategoryIndex) !== undefined;

                      const content = (
                        <Grid
                          container
                          spacing={1}
                          direction="column"
                          alignContent="center"
                          style={{ textAlign: 'center' }}
                        >
                          <Grid item style={{ color: isSelected ? '#FFF' : item.category.color, fontWeight: 'bold' }}>
                            {subCategoryItem.data}
                          </Grid>
                          <Grid item style={{ color: isSelected ? '#FFF' : '#555555', fontWeight: 600 }}>
                            {subCategoryItem.tag}
                          </Grid>
                        </Grid>
                      );

                      return (
                        <Grid
                          data-last={a === items.length}
                          data-parent-large={item.subcategory.length > 4}
                          data-parent-id={a}
                          data-id={subCategoryIndex}
                          item
                          onClick={e => {
                            subCategoryItem.data && handleItemClick(a, subCategoryIndex, subCategoryItem.value, e);
                          }}
                          className={`${
                            subCategoryIndex === 0 && item.subcategory.length === 4
                              ? classes.subCategoryFirst
                              : subCategoryIndex === 0 && item.subcategory.length === 5
                              ? classes.subCategoryLargeFirst
                              : a !== items.length - 1
                              ? classes.subCategory
                              : classes.subCategoryLast
                          } ${a === 0 || a === items.length - 1 ? classes.sectionBottomRadius : ''}`}
                          style={{
                            backgroundColor: isSelected
                              ? item.category.color
                              : backgroundColor(subCategoryIndex, item.subcategory.length),
                          }}
                          key={subCategoryIndex}
                        >
                          {subCategoryItem.data ? (
                            <div className={classes.dataValue}>{content}</div>
                          ) : (
                            <CustomTooltip
                              tooltipContent={noValueText}
                              tooltipPlacementBottom={classes.tooltipPlacementBottom}
                            >
                              <div className={classes.dataValue}>{content}</div>
                            </CustomTooltip>
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                  {/* ++++++++++++++++++++++++ */}
                </Grid>
              </Grid>
              {/* ------------------------ */}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
