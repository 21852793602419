import React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export const convertBooleanToCheckbox = value => {
  return value ? <CheckBoxIcon fontSize="small" /> : <CheckBoxOutlineBlankIcon fontSize="small" />;
};

export function isOverflown(element: Element): boolean {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

export const isPositiveNumber = (val: number | string) => {
  return parseInt(`${val}`) > 0;
};

export const decodeBase64String = (str) => {
  if (str === '' || str.trim() === '') { return str; }
  try {
    if(btoa(atob(str)) == str){
      return atob(str);
    }
    else{
      return str;
    }
  } catch (err) {
    return str;
  }
}