/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import {
  getPlacementGridColumns,
  notInSubmissionColumns,
  notBookedColumns,
  notOAColumns,
  notOfferColumns,
  notDefaultColumns,
  getPlacementGridColumnVisibilityModel,
} from '../../MyPlacementsDesk/utils';
import {
  selectWorkDeskGridData,
  selectDisplayGridData,
  selectFilterChips,
} from '../../MyPlacementsDesk/RecruiterPlacements.selector';
import { gridStateActions } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import { selectRecPlacementsGrid } from '../../../Common/WorkDesk/PipelineChoice/pipelineChoice.selectors';
import { useTranslation } from 'react-i18next';
import { simpleGroupBy } from 'app/helpers/arrayHelpers';
import { XGrid } from 'app/components/Common/XGrid/XGrid';
import { getHomePageSupportComponentHeight } from 'app/layout/pages/HomePage';
import { useWindowResize } from 'utils/customHooks/useWindowResize';
import { calculateComponentRemainingHeight } from 'app/components/Common/XGrid/Hooks/useGridHeight';
import { DrawerFitted } from 'app/components/Common/Drawer/Drawer';
import { CandidateDrawerPreviewer } from 'app/components/GlobalSearch/Candidate/CandidateDrawerPreviewer';
import _ from 'lodash';
import { selectGridSelection } from 'app/components/Common/Grid/GridSelection/GridSelection.selector';
import { selectGridState } from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import {
  useEnableBulkEmail,
  useEnableBulkSms,
  useEnableEmailCommunication,
  useEnableSmsCommunication,
} from 'oidc/CommunicationEnabler';
import { useBulkCommunication } from 'app/components/Notification/Common/BulkCommunication';
import { globalSearchActions } from 'store/redux-store/global-search/slice';
import { selectSearchResults } from 'store/redux-store/global-search/selectors';
import {
  GridApi,
  GridColumnVisibilityModel,
  GridSortModel,
  GridState,
  GridColumns as XGridColumns,
} from '@mui/x-data-grid-pro';
import { navigationProfilesAction } from '@AMIEWEB/GlobalSearch/store/NavigationProfiles.redux';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { GridColumns, SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import { RootState } from 'types';
import { SearchGrid } from 'app/constants/FeatureFlags/smsKeys';
import { SearchRecommendation } from '@AMIEWEB/Tasks/TaskManagement/SearchRecommendation';
import { isOneRecruiterSelected } from './helper';
import { useDecision } from '@optimizely/react-sdk';
import { ff_placement_ui_arbitration_agreement_grids } from 'app/constants/FeatureFlags/Global/keys';


const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
  },
}));

export const WorkDeskGrid = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [arbitrationAgreementFlag] = useDecision(ff_placement_ui_arbitration_agreement_grids);
  const apiRef = React.useRef<GridApi>();
  const gridTagPrefix = 'recruitment-workdesk-';
  const userPreference = useSelector(selectUserPreference);
  const gridPreferences = useSelector((state: RootState) => {
    return state.userPreferenceData.userGridPreferences;
  });
  const appliedFilterValues = useSelector(selectFilterChips);
  const flattenedData = useSelector(selectWorkDeskGridData);
  const displayData = useSelector(selectDisplayGridData);
  const pipelineSelection = useSelector(selectRecPlacementsGrid);
  const [tableData, setTableData] = useState<any[]>(displayData);
  const dispatch = useDispatch();
  const { items, drawerData } = useSelector(selectSearchResults);
  const { selectedData } = useSelector(selectGridSelection);
  const enableSmsCommunication = useEnableSmsCommunication();
  const enableEmailCommunication = useEnableEmailCommunication();
  const enableBulkSms = useEnableBulkSms(SearchGrid.recruitmentWorkDesk);
  const enableBulkEmail = useEnableBulkEmail(SearchGrid.recruitmentWorkDesk);
  const MAX_ALLOWED_EMAIL_SELECTION = 250;
  const MAX_ALLOWED_SMS_SELECTION = 150;
  const CREATE_MESSAGE_WARNING = `Maximum of ${MAX_ALLOWED_SMS_SELECTION} recipients permitted. Please reduce your selections and try again.`;
  const CREATE_EMAIL_WARNING = `Maximum of ${MAX_ALLOWED_EMAIL_SELECTION} recipients permitted. Please reduce your selections and try again.`;
  const gridState = useSelector(selectGridState);
  const { handleCreateEmail, handleSmsSelection, handleSelectedSmsData, enableSmsCreate } = useBulkCommunication();
  const activate = enableSmsCreate();
  const [gridHeight, setGridHeight] = React.useState<number | undefined>(50);
  const updateGridHeight = () => {
    if (isOneRecruiterSelected(appliedFilterValues))
      setGridHeight(calculateComponentRemainingHeight('home-page-paper', getHomePageSupportComponentHeight, 70));
    else setGridHeight(380);
  };
  useWindowResize(updateGridHeight, updateGridHeight);

  const [category, setCategoty] = React.useState<string>('4');
  //xgrid useState
  const defaultColumns = [...getPlacementGridColumns(t, false, arbitrationAgreementFlag.enabled)];
  const [defaultColumnVisibilityModel, setDefaultColumnVisibilityModel] = React.useState<any>(
    _.cloneDeep(getPlacementGridColumnVisibilityModel),
  );
  const [defaultSortModel, setDefaultSortModel] = React.useState<any[]>([{ field: 'statusAge', sort: 'asc' }]);

  const [currentColumns, setCurrentColumns] =
    React.useState<any[]>([...getPlacementGridColumns(t, false, arbitrationAgreementFlag.enabled)]);
  const [currentColumnVisibilityModel, setCurrentColumnVisibilityModel] = React.useState<any>(
    _.cloneDeep(getPlacementGridColumnVisibilityModel),
  );
  const [currentSortModel, setCurrentSortModel] = React.useState<any[]>([{ field: 'statusAge', sort: 'asc' }]);

  const reArrangeColumns = (notColumns, flexColumns) => {
    let visibilityModel = _.cloneDeep(getPlacementGridColumnVisibilityModel);

    for (let k = 0; k < notColumns?.length; k++) {
      visibilityModel[notColumns[k]] = false;
    }
    setDefaultColumnVisibilityModel(_.cloneDeep(visibilityModel));
    setCurrentColumnVisibilityModel(_.cloneDeep(visibilityModel));
    setCurrentColumns([...getPlacementGridColumns(t, false, arbitrationAgreementFlag.enabled)]);
  };

  const onSortModelChange = (model: GridSortModel) => {
    const gridTag = gridTagPrefix + category;
    if (model?.length > 0) {
      saveSortModel(model, gridTag);
    }
    setCurrentSortModel(model);
  };

  const loadGridData = (selected?: { category: string[]; subcategory: string[] }) => {
    let newTableData = [...displayData];

    if (selected) {
      newTableData = [
        ...displayData.filter(
          item => selected.category.includes(item.activityType) || selected.subcategory.includes(item.statusAbbr),
        ),
      ];
    }
    setTableData(newTableData);
  };

  useEffect(() => {
    const selectedGroups = simpleGroupBy(pipelineSelection, 'category');
    const categoriesSelected = Object.keys(selectedGroups);
    pipelineSelection.length
      ? loadGridData({
        category: [
          // categories are unique selections --> check for unique values in selectedGroups
          ...categoriesSelected.reduce(
            (resultArray, item) =>
              selectedGroups[item].length === 1 && selectedGroups[item][0].subcategory === null
                ? [...resultArray, selectedGroups[item][0].value]
                : resultArray,
            [] as string[],
          ),
        ],
        subcategory: [
          ...pipelineSelection.reduce(
            (resultArray, item) => (item.subcategory !== null ? [...resultArray, item.value] : resultArray),
            [] as string[],
          ),
        ],
      })
      : loadGridData();
    updateGridHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipelineSelection, flattenedData, displayData]);

  useEffect(() => {
    const selectedGroups = simpleGroupBy(pipelineSelection, 'category');
    const categoriesSelected = Object.keys(selectedGroups);
    if ((categoriesSelected?.length === 0 || categoriesSelected?.length > 1) && category !== '4') {
      setCategoty('4');
      arrangeDefaultColumns('4');
    } else if (categoriesSelected?.length === 1 && categoriesSelected?.[0] !== category) {
      setCategoty(categoriesSelected?.[0]);
      arrangeDefaultColumns(categoriesSelected?.[0]);
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipelineSelection]);

  const arrangeDefaultColumns = (category: string) => {
    reArrangeColumns(
      category === '4'
        ? notDefaultColumns
        : category === '0'
          ? notInSubmissionColumns
          : category === '1'
            ? notOfferColumns
            : category === '2'
              ? notBookedColumns
              : notOAColumns,
      category === '4',
    );
  };

  const saveSortModel = React.useCallback(
    (sortModel, gridTag) => {
      const currentGridPreferences = gridPreferences.find(item => item.id === gridTag);
      const sortedColumn =
        sortModel?.length > 0
          ? {
            column: sortModel[0].field,
            direction: sortModel[0].sort,
          }
          : undefined;
      if (!_.isEqual(sortedColumn, gridState?.sortedColumn) && !!category) {
        const preferenceData = {
          id: gridTag,
          value: {
            ...(currentGridPreferences?.value || {}),
            sortedColumn: sortedColumn,
          },
        };
        dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentColumnVisibilityModel, dispatch, gridPreferences, gridState?.sortedColumn],
  );

  const savePreferences = React.useCallback(
    ({
      field,
      columns: gridColumns,
      visibilityModel,
      gridTag,
    }: {
      field?: string;
      columns: XGridColumns;
      visibilityModel: GridColumnVisibilityModel;
      gridTag: string;
    }) => {
      const currentGridPreferences = gridPreferences.find(item => item.id === gridTag);
      if (!currentColumns || currentColumns.length === 0 || !userPreference.loaded) return;
      const currentGridColumns = new GridColumns(gridColumns).columns;

      if (currentGridColumns && currentGridColumns.length > 0 && currentGridColumns[0].field === '__check__')
        currentGridColumns.shift();

      const comparableColumns = new GridColumns(
        currentGridPreferences?.value?.columns,
        currentGridPreferences?.value?.columnVisibilityModel,
      );
      const columnsEqual = comparableColumns.isEqual(currentGridColumns);
      const visibilityEqual = comparableColumns.isVisibilityModelEqual(visibilityModel);

      if ((!columnsEqual || !visibilityEqual) && !!category) {
        const preferenceData = {
          id: gridTag,
          value: {
            ...(currentGridPreferences?.value || {}),
            columns: currentGridColumns,
            columnVisibilityModel: visibilityModel,
          },
        };
        dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentColumns, userPreference.loaded, defaultColumns?.length, gridPreferences, dispatch],
  );

  const getColumnPreferences = (cols: any[], gridTag: string) => {
    try {
      const currentGridPreferences = gridPreferences.find(item => item.id === gridTag);
      const columnPreferences = currentGridPreferences?.value;
      if (columnPreferences && columnPreferences.columns) {
        const newColumns = cols.map(column => {
          const foundIndex = columnPreferences.columns.findIndex(item => item?.field === column?.field);
          if (foundIndex !== -1) {
            return {
              ...column,
              ...columnPreferences.columns[foundIndex],
            };
          } else {
            return column;
          }
        });
        return newColumns.sort((a, b) => {
          return (
            columnPreferences.columns.findIndex(i => i.field === a.field) -
            columnPreferences.columns.findIndex(i => i.field === b.field)
          );
        });
      }
      return cols;
    } catch (err) {
      return cols;
    }
  };

  const getVisibilityPreferences = (cols: any, gridTag: string) => {
    const currentGridPreferences = gridPreferences.find(item => item.id === gridTag);
    const columnPreferences = currentGridPreferences?.value;
    if (columnPreferences && columnPreferences?.columnVisibilityModel) {
      return columnPreferences?.columnVisibilityModel;
    }
    return cols;
  };

  const getSortModelPreferences = (sortModel: any[], gridTag: string) => {
    const currentGridPreferences = gridPreferences.find(item => item.id === gridTag);
    const columnPreferences = currentGridPreferences?.value;
    if (columnPreferences && columnPreferences?.sortedColumn) {
      const preferenceSortModel = columnPreferences?.sortedColumn;
      return [{ field: preferenceSortModel?.column, sort: preferenceSortModel?.direction }];
    }
    return sortModel;
  };

  const determineGridColumns = (gridTag: string) => {
    const currentGridColumns = getColumnPreferences(defaultColumns, gridTag);
    setCurrentColumns(currentGridColumns);
  };

  const determineVisibilityModel = (gridTag: string) => {
    const currentVisibilityModel = getVisibilityPreferences(defaultColumnVisibilityModel, gridTag);
    setCurrentColumnVisibilityModel(currentVisibilityModel);
  };

  const determineSortModel = (gridTag: string) => {
    const currentGridSortModel = getSortModelPreferences(defaultSortModel, gridTag);
    setCurrentSortModel(currentGridSortModel);
    if (
      !gridState?.sortedColumn ||
      (gridState?.sortedColumn &&
        (gridState.sortedColumn.column !== currentGridSortModel[0].field ||
          gridState.sortedColumn.direction !== currentGridSortModel[0].sort))
    ) {
      dispatch(
        gridStateActions.setSortedColumn({
          column: currentGridSortModel[0].field,
          direction: currentGridSortModel[0].sort,
        }),
      );
    }
  };

  useEffect(() => {
    const gridTag = gridTagPrefix + category;
    arrangeDefaultColumns(category);
    setDefaultSortModel([{ field: 'statusAge', sort: 'asc' }]);
    if (userPreference?.loaded) {
      determineGridColumns(gridTag);
      determineVisibilityModel(gridTag);
      determineSortModel(gridTag);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreference?.loaded, category]);

  React.useEffect(() => {
    dispatch(gridStateActions.setPageNumber(1));
  }, [dispatch, tableData]);

  React.useEffect(() => {
    const clonedTable = _.cloneDeep(tableData);
    if (clonedTable?.length > 0) {
      dispatch(
        globalSearchActions.setSearchResults({
          items: clonedTable,
          count: clonedTable.length,
          miscellaneous: {},
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  useEffect(() => {
    const sortedRows = apiRef.current.getSortedRows();
    dispatch(
      globalSearchActions.setSearchResults({
        items: sortedRows,
        count: sortedRows?.length,
        miscellaneous: {},
      }),
    );
    dispatch(
      navigationProfilesAction.setNavigationData({
        items: sortedRows,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSortModel, category, tableData]);

  const handleStateChange = (state: GridState) => {
    const clonedTable = _.cloneDeep(tableData);
    const searchItem = state.sorting.sortedRows.reduce((resultArray, i) => {
      const element = clonedTable.find(j => j.id === i);
      return element ? [...resultArray, element] : [...resultArray];
    }, []);

    if (searchItem.length === tableData.length) {
      searchItem.map((item, index) => (item.indexCounter = index));
    }
  };

  const leftActions = React.useMemo(() => {
    if (gridState.gridTag === 'RecPlacementDesk' && items.length > 0) {
      return [
        {
          title: 'global.xgrid.toolbar.email',
          Icon: EmailOutlinedIcon,
          disabled:
            !enableEmailCommunication ||
            !enableBulkEmail ||
            selectedData?.length < 1 ||
            selectedData?.length > MAX_ALLOWED_EMAIL_SELECTION,
          onClick: handleCreateEmail,
          tooltipProps: {
            tooltipContent: CREATE_EMAIL_WARNING,
            disabled: selectedData?.length <= MAX_ALLOWED_EMAIL_SELECTION,
          },
        },
        {
          title: 'global.xgrid.toolbar.sms',
          Icon: ChatOutlinedIcon,
          disabled:
            !activate ||
            !enableSmsCommunication ||
            !enableBulkSms ||
            handleSelectedSmsData()?.length < 1 ||
            handleSelectedSmsData()?.length > MAX_ALLOWED_SMS_SELECTION,
          onClick: handleSmsSelection,
          tooltipProps: {
            tooltipContent: CREATE_MESSAGE_WARNING,
            disabled: handleSelectedSmsData()?.length <= MAX_ALLOWED_SMS_SELECTION,
          },
        },
      ];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gridState.gridTag,
    items.length,
    enableEmailCommunication,
    selectedData?.length,
    handleCreateEmail,
    CREATE_EMAIL_WARNING,
    activate,
    enableSmsCommunication,
    handleSelectedSmsData,
    CREATE_MESSAGE_WARNING,
  ]);
  return (
    <div className={classes.container}>
      <XGrid
        apiRef={apiRef}
        defaultColumns={defaultColumns}
        columns={currentColumns}
        defaultColumnVisibilityModel={defaultColumnVisibilityModel}
        columnVisibilityModel={currentColumnVisibilityModel}
        defaultSortModel={defaultSortModel}
        sortModel={currentSortModel}
        onColumnVisibilityModelChange={(model, details) => {
          const gridTag = gridTagPrefix + category;
          dispatch(gridStateActions.setColumnVisibilityModel(model));
          setCurrentColumnVisibilityModel(_.cloneDeep(model));
          savePreferences({
            field: undefined,
            columns: _.cloneDeep(apiRef?.current?.getAllColumns()),
            visibilityModel: _.cloneDeep(model),
            gridTag: gridTag,
          });
        }}
        onColumnResizeStop={_columns => {
          const gridTag = gridTagPrefix + category;
          savePreferences({
            field: undefined,
            columns: _columns,
            visibilityModel: _.cloneDeep(currentColumnVisibilityModel ?? defaultColumnVisibilityModel),
            gridTag: gridTag,
          });
        }}
        onColumnOrderChange={(params, event, details, _columns) => {
          const gridTag = gridTagPrefix + category;
          savePreferences({
            field: params.field,
            columns: _columns,
            visibilityModel: _.cloneDeep(currentColumnVisibilityModel ?? defaultColumnVisibilityModel),
            gridTag: gridTag,
          });
        }}
        rows={tableData || []}
        autoHeight={false}
        height={gridHeight}
        rowsPerPageOptions={[20, 50, 100, 250]}
        disableSelectionOnClick
        loading={false}
        rowCount={(tableData || []).length}
        onSortModelChange={onSortModelChange}
        onStateChange={handleStateChange}
        checkboxSelection
        toolbarProps={{
          leftActions: leftActions,
          resetColumns: () => {
            setCurrentColumns([...getPlacementGridColumns(t, false, arbitrationAgreementFlag?.enabled)]);
            setCurrentColumnVisibilityModel(defaultColumnVisibilityModel);
            dispatch(gridStateActions.setSortedColumn({ column: 'statusAge', direction: 'asc' }));
            setCurrentSortModel(defaultSortModel);
            const gridTag = gridTagPrefix + category;
            const preferenceData = {
              id: gridTag,
              value: {
                columns: defaultColumns,
                columnVisibilityModel: defaultColumnVisibilityModel,
                sortedColumn: { column: 'statusAge', direction: 'asc' },
              },
            };
            dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
            dispatch(
              globalActions.setSnackBar({
                message: 'Columns have been reset.',
                severity: 'success',
              }),
            );
          },
        }}
        components={{
          NoRowsOverlay: isOneRecruiterSelected(appliedFilterValues)
            ? undefined
            : () => <SearchRecommendation entity={SearchType.amWorkdesk} />,
        }}
      />
      {drawerData?.open && (
        <DrawerFitted
          onDrawerClose={() => {
            dispatch(globalSearchActions.setDrawerData({ open: false, data: undefined }));
          }}
          width={400}
          top={0}
          backgroundColor={theme.palette.framework.system.whisper}
          open={drawerData?.open || false}
        >
          {drawerData?.data ? <CandidateDrawerPreviewer isSearchGrid={true} isWorkDesk={true} /> : null}
        </DrawerFitted>
      )}
    </div>
  );
};
