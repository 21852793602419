import { FormControlLabel, Radio, RadioGroup } from 'amn-ui-core';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export const FormInputRadio = props => {
  const { control, watch } = useFormContext();

  const defaultValue = watch(props.name) || props.value; // Get the default value from watch()

  return (
    <Controller
      control={control}
      name={props.name}
      defaultValue={defaultValue}
      render={({ onBlur, onChange, value }) => (
        <RadioGroup
          row
          value={value}
          onBlur={onBlur}
          onChange={e => {
            if (props.handleChange) props.handleChange(e);
            onChange(e);
          }}
        >
          {value != undefined && value != null && (
            <>
              <FormControlLabel value={true} control={<Radio disabled={props.disabled} />} label="Yes" />
              <FormControlLabel value={false} control={<Radio disabled={props.disabled} />} label="No" />
            </>
          )}
        </RadioGroup>
      )}
    />
  );
};
