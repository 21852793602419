import { convertToPSTMoment } from 'app/helpers/dateHelper';
import { VerifiedSkill } from 'app/models/Candidate/CandidateProfile';
import moment from 'moment-timezone';

export const getTotalValidExperienceFromSkillset = (skillsets: VerifiedSkill[]) => {
  let numberOfMonths = 0;
  skillsets?.forEach(e => {
    numberOfMonths += e.yearsOfExperience * 12 + e.monthsOfExperience;
  });
  const years = Math.floor(numberOfMonths / 12);
  const yearsInMonths = years * 12;
  const months = numberOfMonths - yearsInMonths;
  return getExperienceData(years, months);
};

export function getExperienceData(years: number | undefined, months: number | undefined) {
  if (years == null && months == null) return '';
  else if (years == null || years === 0) return `${months}mo`;
  else if (months == null || months === 0) return `${years}yr`;
  else return `${years}yr, ${months}mo`;
}

export const formatEngagementDate = (dateString: string | null | undefined): string | null => {
  if (!dateString) return null;

  const pstNow = moment().tz('America/Los_Angeles');
  const targetDateInPst = moment.utc(dateString).tz('America/Los_Angeles');
  const duration = moment.duration(pstNow.diff(targetDateInPst));

  if (duration.asMinutes() < 60) {
    const minutes = Math.floor(duration.asMinutes());
    return `${minutes} min`;
  }

  if (duration.asHours() < 24) {
    const hours = Math.floor(duration.asHours());
    return `${hours} h`;
  }

  if (duration.asDays() < 30) {
    const days = Math.floor(duration.asDays());
    return `${days} d`;
  }

  if (duration.asMonths() < 12) {
    const months = Math.floor(duration.asMonths());
    const days = Math.floor(duration.asDays() % 30);
    return `${months} mo ${days} d`;
  }

  const years = Math.floor(duration.asYears());
  const months = Math.floor(duration.asMonths() % 12);
  return `${years} yr ${months} mo`;
};
