import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { FormatDate } from '@AMIEWEB/Notification/Tasks/Common/FormatData';
import { selectUser } from 'oidc/user.selectors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { coverageActions } from 'store/redux-store/user-coverage/async-actions';

export const DenyCoverageModal = ({
  openDialog,
  setOpenDialog,
  selectedDenyCoverage,
}: {
  openDialog: boolean;
  setOpenDialog: (e) => void;
  selectedDenyCoverage: any[];
}) => {
  const { t } = useTranslation();
  const userInfo = useSelector(selectUser);
  const dispatch = useDispatch();

  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleDialogYes = () => {
    const denyCoverageDetails = {
      ids: selectedDenyCoverage,
      changedByEmployeeId: userInfo?.userInfo?.employeeId,
      changedByFirstName: userInfo?.userInfo?.firstName,
      changedByLastName: userInfo?.userInfo?.lastName,
      changedDate: FormatDate(new Date()),
      statusId: 3,
    };
    dispatch(coverageActions.denyCoverageRequestAction(denyCoverageDetails));
    setOpenDialog(false);
  };

  return (
    <GenericDialog
      open={openDialog}
      onClose={(e, reason) => {
        if (reason === 'backdropClick') {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
      }}
      fullWidth
      dialogTitleProps={{
        text: t('home.coverageWorkDesk.tabs.request.denyCoverageTitle'),
      }}
      dialogContentProps={{ variant: 'text' }}
      dialogActions={[
        {
          text: t('notification.taskSidePanel.cueNotes.alertBtnCancel'),
          color: 'tertiary',
          onClick: handleDialogClose,
        },
        {
          text: t('notification.coverage.assignModal.confirm'),
          variant: 'contained',
          color: 'primary',
          onClick: handleDialogYes,
        },
      ]}
    >
      {t('home.coverageWorkDesk.tabs.request.denyCoverageModalText')}
    </GenericDialog>
  );
};
