import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';

import { initialState } from './JobMatch.redux';

const selectDomain = (state: RootState) => state?.jobMatch || initialState;

export const selectJobMatches = createSelector([selectDomain], jobMatches => jobMatches.data);
export const selectJobMatchCount = createSelector([selectDomain], jobMatches => ({
  totalCount: jobMatches.count,
  filteredCount: jobMatches.filteredCount,
}));
export const selectJobMatchesGridData = createSelector([selectDomain], jobMatches => jobMatches.flattenedData);
export const selectJoMatchLocations = createSelector([selectDomain], jobMatches => jobMatches.jobLocations);
export const selectMatchServiceFailure = createSelector([selectDomain], jobMatches => jobMatches.serviceFailure);
export const selectMatchDrawerData = createSelector([selectDomain], jobMatches => jobMatches.drawerData);
