import { createSlice } from "@reduxjs/toolkit";
import { consolidatedServiceActions } from "./consolidatedStoreActions";

export interface IPlacementSummaryConsolidatedStore {
    placementId: number;
    consolidatedResult?: any | null | undefined,
    stage0: boolean | false,
    stage1: boolean | false,
    isLoadingCompleted: boolean | false,
    isError: boolean | false,
    message: string | null | undefined
  }

export const initialState: IPlacementSummaryConsolidatedStore = {
    placementId: 0,
    consolidatedResult: null,
    stage0 : false,
    stage1 : false,
    isLoadingCompleted: false,
    isError: false,
    message : null
}

const placementSummaryConsolidatedStoreSlice = createSlice({
  name: 'placementSummaryConsolidatedStore',
  initialState,
  reducers: {
    setPlacementDetails(state, action) {
        return {
          ...state,
          placementId: action.payload.placementId,
          consolidatedResult: action.payload.consolidatedResult,
          isError: false,
          message: null
        };
      },
      setPlacementInitialDetails(state, action) {
        if(action.payload.isError){
          state.consolidatedResult = null;
          state.placementId = 0;
        }
        else{
          const { consolidatedResult } = state;
          const {
            consolidatedResult: newConsolidatedResult,
          } = action.payload;
          if (consolidatedResult) {
            state.consolidatedResult.header = newConsolidatedResult?.header;
            state.consolidatedResult.placementOrderType = newConsolidatedResult?.placementOrderType;
            state.consolidatedResult.common.timestampDetails = newConsolidatedResult?.common?.timestampDetails;
            state.placementId = action.payload.placementId;
          } else {
            state.consolidatedResult = newConsolidatedResult;
            state.placementId = action.payload.placementId;
          }
        }
        state.stage0 = true;
        state.isError = action.payload.isError;
        state.message = action.payload.message;
      },
      setPlacementInitialBulkDetails(state, action) {
        if(action.payload.isError){
          state.consolidatedResult = null;
          state.placementId = 0;
        }
        else{
          const { consolidatedResult } = state;
          const {
            consolidatedResult: newConsolidatedResult,
          } = action.payload;
          if(consolidatedResult){
            const mergedConsolidatedResult = {
              ...newConsolidatedResult,
            };
            mergedConsolidatedResult.header = consolidatedResult?.header;
            mergedConsolidatedResult.placementOrderType = consolidatedResult?.placementOrderType;
            mergedConsolidatedResult.common.timestampDetails = consolidatedResult?.common?.timestampDetails;
            state.consolidatedResult = mergedConsolidatedResult;
            state.placementId = action.payload.placementId;
          }
          else{
            state.consolidatedResult = newConsolidatedResult;
            state.placementId = action.payload.placementId;
          }
        }
        state.stage1 = true;
        state.isError = action.payload.isError;
        state.message = action.payload.message;
      },
      setIsLoadingCompletedStatus(state, action){
        return {
          ...state,
          isLoadingCompleted: action.payload.status
        };
      },
      setErrorStatus(state, action){
        state.isError = action.payload.isError;
        state.message = action.payload.message;
        if(action.payload.isError){
          state.stage0 = true;
          state.stage1 = true;
          state.consolidatedResult = null;
          state.placementId = 0;
        }
      },
      reset() {
        return { ...initialState };
      } 
  }
});

export const placementSummaryConsolidatedStoreActions = 
{ ...placementSummaryConsolidatedStoreSlice.actions, ...consolidatedServiceActions };

export const { 
  setPlacementDetails, 
  setPlacementInitialDetails, 
  setPlacementInitialBulkDetails,
  setIsLoadingCompletedStatus,
  setErrorStatus,
  reset 
} = placementSummaryConsolidatedStoreSlice.actions;

export const { name: placementSummaryConsolidatedStoreSliceKey, reducer: placementSummaryConsolidatedStoreReducer } 
= placementSummaryConsolidatedStoreSlice;