import { Button, CircularProgress, Grid, TextField, Typography } from 'amn-ui-core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { ActionModal, IActionModalProps } from '../customComponents/ActionModal';
import { ITemplate } from 'store/redux-store/confirmation-template-editor/types';
import { CustomTooltip } from '@AMIEWEB/Common';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';

const useStyles = makeStyles<{ hasError: boolean }>()((theme, { hasError }) => ({
  container: {
    padding: '6px 12px',
    backgroundColor: theme.palette.system.smokeWhite,
  },
  filled: {
    backgroundColor: 'transparent',
    '&:hover, &.Mui-focused, &.Mui-disabled': {
      backgroundColor: 'transparent',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    padding: '7px 0 6px 0 !important',
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.system.darkGray,
    lineSpacing: '19px',
  },
  helperText: {
    margin: 0,
  },
  btnContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'end',
    marginBottom: hasError ? 20 : undefined,
  },
  noTemplateSelected: {
    fontSize: '14px',
    fontWeight: 400,
    fontHeight: '19px',
    letterSpacing: '0px',
    color: theme.palette.system.lightGrey,
    opacity: 1,
    padding: '6px 0',
  },
  secondaryBtn: {
    backgroundColor: theme.palette.system.buttonGray,
    ':hover': {
      backgroundColor: theme.palette.system.buttonDarkGray,
    },
  },
}));

export const TemplateTitle: React.FC<{
  isTemplateSelected: boolean;
  selectedTemplate: ITemplate;
  isNewTemplate: boolean;
  isDirty: boolean;
  templateTitle: string | null;
  templates: ITemplate[];
  onCancel: (callbackFn) => void;
  onClose: () => void;
  onSave: () => void;
  onPublish: (templateTitle) => void;
  onTitleChange: (templateTitle) => void;
  forceModalCloseFlag?: boolean;
  isSavingDraft?: boolean;
  isPublishing?: boolean;
  userIsReadOnly: boolean;
}> = ({
  isTemplateSelected,
  selectedTemplate,
  isNewTemplate,
  templates,
  isDirty,
  templateTitle,
  onTitleChange,
  onCancel = undefined,
  onClose = undefined,
  onSave = undefined,
  onPublish = undefined,
  forceModalCloseFlag,
  isSavingDraft,
  isPublishing,
  userIsReadOnly,
}) => {
  const { t } = useTranslation();
  const errorState = useState(null);
  const { classes, cx } = useStyles({ hasError: !!errorState[0] });

  // @ts-ignore
  const [modalProps, setModalProps] = useState<IActionModalProps>({ open: false });

  // @ts-ignore
  const closeModal = React.useCallback(() => setModalProps({ open: false }), []);

  const {
    userInfo: {
      externalSystemDetails: { confirmationTemplate: userCanPublish },
    },
  } = useSelector(selectUser);

  const titleDisplay = React.useMemo(() => {
    return !isTemplateSelected ? (
      <Typography className={classes.noTemplateSelected}>{t('templateEditor.noSelection')}</Typography>
    ) : (
      <TitleField
        errorState={errorState}
        onChange={onTitleChange}
        value={templateTitle}
        classes={classes}
        existingTemplates={templates}
        selectedTemplate={selectedTemplate}
        isNewTemplate={isNewTemplate}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewTemplate, isTemplateSelected, selectedTemplate, templateTitle, templates, errorState[0]]);

  useEffect(() => {
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceModalCloseFlag]);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _evaluateError = React.useCallback((title, eTemplates, selected) => {
    const error = evaluateError(title, eTemplates, selected);
    errorState[1]?.(error);
    return !!error;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** To reset error, every time teplate selection changes */
  useEffect(() => {
    errorState[1]?.(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate]);

  const btnRender = React.useMemo(() => {
    const disablePublish =
      userIsReadOnly || !userCanPublish
        ? true
        : selectedTemplate?.templateId === Number.MIN_VALUE || selectedTemplate?.isDraft
        ? false
        : !isDirty;
    const btns: {
      children: string;
      disabled: boolean;
      onClick: () => void;
      startIcon?: JSX.Element;
      tooltip?: JSX.Element;
      classes?: any;
      color?: 'secondary';
      component?: JSX.Element;
    }[] = [
      {
        children: t('templateEditor.save'),
        /** Enabled for a new template, as well as if dirty otherwise: Disabled if user is readonly  */
        disabled: userIsReadOnly ? true : selectedTemplate?.templateId === Number.MIN_VALUE ? false : !isDirty,
        onClick: () => {
          const hasError = _evaluateError(templateTitle, templates, selectedTemplate);
          if (hasError) return;
          onSave?.();
        },
        startIcon: isSavingDraft ? (
          <CircularProgress sx={theme => ({ color: theme.palette.components.circularProgress.color })} size={12} />
        ) : undefined,
      },
      {
        children: t('templateEditor.publish'),
        /** Enabled for either a new template or a draft, as well as if dirty otherwise: Disabled if user is readonly  */
        disabled: disablePublish,
        onClick: () => {
          const hasError = _evaluateError(templateTitle, templates, selectedTemplate);
          if (hasError) return;
          setModalProps({
            open: true,
            title: t('templateEditor.modal.title.publishTemplate'),
            message:
              isNewTemplate || !selectedTemplate?.fromExistingTemplate
                ? t('templateEditor.modal.message.publishNewTemplate')
                : t('templateEditor.modal.message.replaceTemplate'),
            primaryBtns: [
              {
                text: t('templateEditor.publish'),
                onClick: () => {
                  onPublish(templateTitle);
                },
              },
            ],
            secondaryBtns: [
              {
                text: t('cancelModal.confirmation.cancel'),
                onClick: closeModal,
              },
            ],
          });
        },
        tooltip: disablePublish
          ? !userCanPublish
            ? t('templateEditor.disableToolTip.accessRestriction')
            : undefined
          : undefined,
      },
    ];

    btns.unshift(
      isDirty || isNewTemplate
        ? {
            children: t('templateEditor.cancel'),
            disabled: !isTemplateSelected,
            classes: { containedSecondary: classes.secondaryBtn },
            color: 'secondary',
            onClick: () =>
              setModalProps({
                open: true,
                title: t('cancelModal.undoConfirmation'),
                message: isNewTemplate
                  ? t('templateEditor.modal.message.discardNewTemplate')
                  : t('templateEditor.modal.message.discardTemplateChanges'),
                primaryBtns: [
                  {
                    text: t('cancelModal.confirmation.yes'),
                    onClick: () => {
                      onCancel(closeModal);
                      if (isNewTemplate) onClose();
                    },
                  },
                ],
                secondaryBtns: [
                  {
                    text: t('cancelModal.confirmation.cancel'),
                    onClick: closeModal,
                  },
                ],
              }),
          }
        : {
            children: t('templateEditor.close'),
            disabled: !isTemplateSelected,
            classes: { containedSecondary: classes.secondaryBtn },
            color: 'secondary',
            onClick: onClose,
          },
    );
    return btns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userIsReadOnly,
    userCanPublish,
    selectedTemplate,
    isDirty,
    isSavingDraft,
    isNewTemplate,
    isTemplateSelected,
    onClose,
    _evaluateError,
    templateTitle,
    templates,
    onSave,
    closeModal,
    onPublish,
    onCancel,
  ]);

  return (
    <Grid container direction="row" justifyContent="space-between" classes={{ container: classes.container }}>
      <Grid item classes={{ root: cx({ [classes.titleContainer]: isTemplateSelected }) }}>
        {titleDisplay}
      </Grid>
      <Grid item classes={{ root: classes.btnContainer }}>
        <Grid container direction="row" gap={2}>
          {btnRender.map((btn, index) => (
            <CustomTooltip key={index.toString()} disabled={!btn.tooltip} tooltipContent={btn.tooltip} standardMargin>
              <Button variant="contained" size="small" type="button" {...btn} />
            </CustomTooltip>
          ))}
        </Grid>
      </Grid>
      <ActionModal {...modalProps} loading={isPublishing} />
    </Grid>
  );
};

const TITLE_REGEX = /^[a-zA-Z0-9,\-[\]/? ().]+$/;

const TitleField = ({ onChange, errorState, value, classes, existingTemplates, selectedTemplate, isNewTemplate }) => {
  const [error, setError] = errorState;
  const ref = React.useRef(null);

  const handleChange = (newValue: string) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const _error = evaluateError(newValue, existingTemplates, selectedTemplate);
    setError(_error);
    onChange(newValue);
  };

  React.useEffect(() => {
    if (isNewTemplate) {
      ref.current.getElementsByTagName('input')[0].focus();
    }
  }, [isNewTemplate]);

  return (
    <TextField
      ref={ref}
      onChange={e => handleChange(e.target.value)}
      value={value}
      variant="filled"
      size="small"
      InputProps={{ classes: { root: classes.filled, input: classes.input } }}
      inputProps={{ maxLength: 100 }}
      FormHelperTextProps={{
        classes: { root: classes.helperText },
      }}
      error={!!error}
      helperText={error}
    />
  );
};

const evaluateError = (value, existingTemplates, selectedTemplate) =>
  !value
    ? i18next.t('templateEditor.templateName.error.required')
    : !TITLE_REGEX.test(value)
    ? i18next.t('templateEditor.templateName.error.symbols')
    : existingTemplates
        /** Filter out the congruent (published and own drafts) templates of the selected templates */
        .filter(x => {
          if ((x.isDraft && selectedTemplate.isDraft) || (!x.isDraft && !selectedTemplate.isDraft))
            return x.id !== selectedTemplate?.id;
          else if (selectedTemplate.isDraft) return selectedTemplate.id.split('-draft')[0] !== x.id;
          else return x.id.split('-draft')[0] !== selectedTemplate?.id;
        })
        .some(x => x.templateName.toLowerCase() === value.toLowerCase())
    ? i18next.t('templateEditor.templateName.error.exists')
    : null;
