import CloseIcon from '@mui/icons-material/Close';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  DatePicker,
  TimePicker,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Toolbar,
  Typography,
  LocalizationProvider,
  InputAdornment,
  useTheme,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { candidateDetailActions } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { candidateDetailsSelection } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { CustomBackdrop } from 'app/components/Common/Backdrop/CustomBackdrop';
import { PhoneNumber } from 'app/components/Common/PhoneNumber/Phone';
import { convertToFormat } from 'app/helpers/dateHelper';
import { formatPhoneNumber, JabberExtensionCode, unformatPhoneNumber } from 'app/helpers/phoneHelpers';
import { IDisposition } from 'app/models/Notification/Notification';
import { SaveCallLog } from 'app/services/NotificationServices/NotificationService';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast, Zoom } from 'react-toastify';
import styledComponent from 'styled-components';
import { CapitalizeEveryFirstLetter } from 'utils/string/string';
import { ChangeBanner } from '../../Common/ChangeBanner';
import { CloseConfirmation } from '../../Common/CloseDialog';
import { ToasterContent } from '../../Common/ToasterContent';
import { ToasterHeader } from '../../Common/ToasterHeader';
import { COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT, ICallOutcome, ChannelType, IDirection } from '../../Constants';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { NotificationContext } from '../../NotificationWrapper';
import { OutcomeIcon } from './OutcomeGridIcons';
import { withReadOnly } from 'oidc/withReadOnly';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const useStyles = makeStyles()(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '32vw',
    height: '100%',
    outline: 'none',
    boxShadow: '0px 0px 2px 6px #8888',
    // background: '#111111 0% 0% no-repeat padding-box',
    borderRadius: '6px',
  },
  actionButton: {
    marginTop: '12px',
    color: '#fff',
  },
  cancelButton: { backgroundColor: '#8e9195' },
  box: {
    border: '1px solid #B9BBBC',
    borderRadius: '10px',
    width: '6.5vw',
    height: '12vh',
    backgroundColor: theme.palette.components.box.container.backgroundColor,
    margin: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridIcon: {
    display: 'flex',
    margin: '0.5%',
  },
  gridText: {
    display: 'flex',
    margin: '8% 6% 0 6%',
    textAlign: 'center',
    fontSize: '0.75rem',
    fontWeight: 500,
    font: 'normal normal medium Roboto',
    letterSpacing: '0px',
    opacity: '1',
  },
  notes: {
    minHeight: '160px',
    maxHeight: '160px',
  },
  avatar: {
    margin: '2% 3% 2% 0',

    color: theme.palette.components.avatar.color,
    fontSize: '30px',
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(6),
      width: theme.spacing(6),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(10),
      width: theme.spacing(10),
    },
  },
  modalIcon: {
    color: '#BDBEBF',
    cursor: 'pointer',
    fontSize: '2rem',
    margin: '5px',
  },
  modalContent: {
    margin: '2% 5%',
    color: '#393939',
  },
  invertItemColor: {
    filter: `invert(31%) sepia(70%) saturate(1672%) hue-rotate(181deg) brightness(80%) contrast(80%)`,
  },
  greenUnderline: {
    '&:before': {
      backgroundColor: '#0f0',
    },
  },
  phoneField: {
    background: '#fff',
    border: '1px solid #b9bbbc',
    borderRadius: '5px',
    '&:hover': {
      background: 'none',
    },
  },
  picker: {
    background: '#F5F5F5',
    border: '1px solid #b9bbbc',
    borderRadius: '5px',
  },
  typeRadio: {
    color: '#333',
    fontWeight: 500,
  },
  errorText: {
    fontSize: '12px',
    justifyContent: 'center',
    color: theme.palette.system.errorRed,
  },
  characterCount: {
    fontSize: '12px',
    justifyContent: 'center',
    color: '#888888',
  },
  errorInfoIcon: {
    width: '16px',
    height: '16px',
  },
}));

const ModalAppBar = styledComponent(AppBar)`
  width: 100%;
  height: 10vh;
  padding-top: 1%;
  padding-left: 34px;
  background: #f4f5f7;
  cursor: all-scroll;
  box-shadow: none;
`;
const Notes = styledComponent(TextField)`
  margin-top: 1%;
  width: 100%;
`;

const FieldHeader = styledComponent(Typography)`
  margin-bottom: 1%;
  margin-top: 2%;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: left;
  font: normal normal medium 16px/26px Roboto;
  letter-spacing: 0px;
  color: #393939;
  opacity: 1;
`;

const Header = styledComponent(Typography)`
  text-align: left;
  font: normal normal medium Roboto;
  letter-spacing: 0px;
  color: #34b233;
  opacity: 1;

  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 1.5%;
`;
const SubHeader = styledComponent(Typography)`
  color: #333333;
  font-weight: 600;
  font-size: 1rem;
  opacity: 1;
  text-align: left;
  font: normal normal medium 14px/16px Roboto;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  @media (max-width: 1650px) {
    font-size: 0.7rem;
  }
`;

export const ManualCallLog = props => {
  const { notes = '', setNotes = () => {} } = props;
  const dispatch = useDispatch();
  const globalData = useSelector(notificationSelection);
  const { onClose, handleSubmitResponse } = useContext(NotificationContext);
  const data = globalData?.log?.data;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const theme = useTheme();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const [disposition, setDisposition] = useState<IDisposition>({
    ...data,
    date: data?.date ?? new Date(),
    time: data?.time ?? new Date(),
    direction: data.direction ?? IDirection.Outbound,
    phoneNumber: data?.tos?.[0].phoneNumber ?? '',
    sender: data.sender,
    notes: data.notes ?? '',
    disposition: data.disposition ?? null,
    initiatedOn: new Date(),
  });

  const closeModalData = {
    log: {
      data: null,
      open: false,
      minimized: false,
    },
  };

  const [action, setAction] = useState({
    minimize: false,
    openManualLog: true,
  });
  const [loader, setLoader] = useState<boolean>(false);
  const saveManualCallLog = () => {
    setLoader(true);
    const voiceData = JSON.parse(JSON.stringify(disposition));
    if (data && data?.tos.find(Boolean)?.phoneNumber) {
      voiceData.tos.find(Boolean).phoneNumber =
        JabberExtensionCode + unformatPhoneNumber(voiceData?.tos.find(Boolean)?.phoneNumber);
    }
    voiceData.initiatedOn = moment(
      convertToFormat(voiceData.date, 'MM/DD/YYYY') + ' ' + convertToFormat(voiceData.time, 'HH:mm'),
    ).format();
    SaveCallLog(voiceData)
      .then(res => {
        setLoader(false);
        handleSubmitResponse(true, 'success', t('notification.voice.loggedSuccessfully'));
        dispatch(
          notificationDataActions.setLogdata({
            log: { data: globalData.log?.data, open: false, minimized: false },
            snackbarData: {
              showCloseAction: false,
              showResponse: true,
              message: t('notification.voice.loggedSuccessfully'),
              severity: 'success',
            },
          }),
        );
        setAction({ ...action, openManualLog: false });
        if (
          candidateDetails.lastContactedOn === null ||
          moment(voiceData.initiatedOn).diff(moment(candidateDetails.lastContactedOn)) > 1
        ) {
          dispatch(candidateDetailActions.updateLastContactedOn(moment(voiceData.initiatedOn).toISOString()));
          dispatch(
            candidateDetailActions.setKpis({
              ...candidateDetails.kpis,
              lastContacted: moment(voiceData.initiatedOn).toISOString(),
            }),
          );
        }
        setNotes('');
      })
      .catch(() => {
        setLoader(false);
        handleSubmitResponse(true, 'error', t('notification.voice.LoggingFailed'));
      });
  };

  const handleDisposition = (value, key) => {
    const dispositionData = JSON.parse(JSON.stringify(disposition));
    dispositionData[key] = value;
    if (key === 'direction' && value === IDirection.Inbound) {
      dispositionData.disposition = ICallOutcome.SpokeToCandidate;
      setDisposition(prevState => ({ ...prevState, [key]: value, disposition: ICallOutcome.SpokeToCandidate }));
    } else setDisposition(prevState => ({ ...prevState, [key]: value }));
    dispatch(
      notificationDataActions.setNotificationData({
        ...globalData,
        log: { data: dispositionData, open: true, minimized: false },
      }),
    );
  };

  const handleRecipientPhoneNumber = event => {
    const dispositionData = JSON.parse(JSON.stringify(disposition));
    dispositionData.tos.find(Boolean).phoneNumber = unformatPhoneNumber(event?.target?.value);
    setDisposition(dispositionData);
    dispatch(
      notificationDataActions.setLogdata({
        data: dispositionData,
        log: { data: dispositionData, open: true, minimized: false },
      }),
    );
  };

  const handleMinimize = () => {
    const toasterId = 'manualVoiceCall';
    setAction({ ...action, minimize: true, openManualLog: false });
    dispatch(notificationDataActions.setLogInteraction({ open: false, minimized: true }));
    dispatch(notificationDataActions.setSnackBarData(null));
    dispatch(notificationDataActions.incrementActiveToaster());
    toast(
      <ToasterContent
        channel={ChannelType.voice}
        isManualLog
        id={toasterId}
        info={globalData?.log?.data}
        header={ToasterHeader(data?.tos.find(Boolean)?.name, data?.tos.find(Boolean)?.contactId)}
        subheader={`Log`}
        onClose={onClose}
      />,
      {
        toastId: toasterId,
        containerId: 'channel',
        position: 'bottom-right',
        closeButton: false,
        hideProgressBar: true,
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
        transition: Zoom,
      },
    );
  };

  const outcomeHandler = props => {
    handleDisposition(props.value, 'disposition');
    setDisposition({ ...disposition, disposition: props.value });
  };

  const handleModalClose = () => {
    dispatch(
      notificationDataActions.setNotificationData({
        ...globalData,
        ...closeModalData,
      }),
    );
    dispatch(notificationDataActions.setSnackBarData(null));
    setNotes('');
  };

  const Outcome = props => {
    return (
      <Box
        boxShadow={3}
        style={{
          cursor: disposition.direction === IDirection.Inbound ? 'content-menu' : 'pointer',
          border: props.value === disposition.disposition ? '#006fb9 2px solid' : '#dbdbdb 1px solid',
          background:
            disposition.direction === IDirection.Inbound && props.value !== ICallOutcome.SpokeToCandidate
              ? '#EEE'
              : '#FFF',
        }}
        m={1}
        className={classes.box}
        onClick={() => (disposition.direction !== IDirection.Inbound ? outcomeHandler(props) : null)}
      >
        <Grid>
          <Grid
            item
            justifyContent="center"
            style={{
              color: props.value === disposition.disposition ? '#006fb9' : '#75787B',
            }}
            className={`${classes.gridIcon} ${props.value === disposition.disposition ? classes.invertItemColor : ''}`}
          >
            {props.icon}
          </Grid>
          <Grid
            item
            justifyContent="center"
            style={{
              color: props.value === disposition.disposition ? '#006fb9' : '#75787B',
              fontSize: '12px',
            }}
            className={classes.gridText}
          >
            <span>{props.name}</span>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Modal open hideBackdrop className={classes.modal} closeAfterTransition>
      <>
        {loader && <CustomBackdrop open />}
        <Draggable handle="#draggableArea" bounds="parent">
          <div className={classes.paper}>
            <ModalAppBar id="draggableArea" position="static">
              <Toolbar disableGutters style={{ background: '#88888' }}>
                <Avatar className={classes.avatar} style={{ background: data.manualLog ? '#008b95' : '#6638B7' }}>
                  {data?.tos.find(Boolean)?.name
                    ? data?.tos
                        .find(Boolean)
                        ?.name?.split(' ')
                        .map(n => n[0])
                        .join('')
                        .replace(/[^a-zA-Z]/g, '')
                        .substring(0, 2)
                    : 'UNK'}
                </Avatar>
                <Grid>
                  <Grid item>
                    <Header style={{ color: data.manualLog ? '#008b95' : '#6638b7' }}>{`${t(
                      'Log call for ',
                    )}  ${CapitalizeEveryFirstLetter(data?.tos.find(Boolean)?.name?.toLowerCase() || '')}`}</Header>
                  </Grid>
                  <Grid item>
                    <SubHeader variant="subtitle1">
                      {formatPhoneNumber(data?.tos?.find(Boolean).phoneNumber)} {t(' | ')} {t(' CID ')}{' '}
                      {data?.tos.find(Boolean)?.contactId}
                    </SubHeader>
                  </Grid>
                </Grid>
                <div style={{ marginLeft: 'auto', marginRight: '4%' }}>
                  <IndeterminateCheckBoxOutlinedIcon onClick={handleMinimize} className={classes.modalIcon} />
                  <CloseIcon onClick={() => onClose(ChannelType.voice, true)} className={classes.modalIcon} />
                </div>
              </Toolbar>
            </ModalAppBar>

            <div className={classes.modalContent}>
              {globalData?.snackbarData?.channel === ChannelType.voice &&
                globalData?.snackbarData?.manual &&
                globalData?.snackbarData?.changeWarning && (
                  <ChangeBanner message={t('notification.finishLogWarning')} />
                )}
              <FieldHeader style={{ marginBottom: '0' }}>{t('Log call')}</FieldHeader>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <DatePicker
                          inputFormat="MM/dd/yyyy"
                          label="Date"
                          value={disposition.date}
                          maxDate={new Date()}
                          className={classes.picker}
                          readOnly={true}
                          onChange={date => handleDisposition(date, 'date')}
                          renderInput={p => (
                            <TextField
                              {...p}
                              fullWidth
                              id="dateSelector"
                              variant="filled"
                              aria-label="change date"
                              InputLabelProps={{
                                shrink: true,
                                color: 'primary',
                              }}
                              InputProps={{
                                ...p.InputProps,
                                disableUnderline: true,
                                autoComplete: 'off',
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TimePicker
                          label="Time"
                          value={disposition.time}
                          className={classes.picker}
                          readOnly={true}
                          onChange={time => handleDisposition(time, 'time')}
                          renderInput={p => (
                            <TextField
                              {...p}
                              fullWidth
                              id="timeSelector"
                              aria-label="change time"
                              variant="filled"
                              InputProps={{
                                ...p.InputProps,
                                disableUnderline: true,
                                autoComplete: 'off',
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <PhoneNumber
                        disableUnderline
                        fullWidth
                        variant="filled"
                        label="Candidate number"
                        value={formatPhoneNumber(data?.tos.find(Boolean)?.phoneNumber) || ''}
                        className={classes.phoneField}
                        onChange={handleRecipientPhoneNumber}
                        InputProps={{ classes: { underline: classes.greenUnderline } }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl component="fieldset">
                        <FieldHeader style={{ marginBottom: '0', marginTop: '0' }}>{t('Type')}</FieldHeader>
                        <RadioGroup
                          row
                          aria-label="type"
                          name="Direction"
                          value={disposition.direction}
                          className={classes.typeRadio}
                          onChange={event => handleDisposition(event.target.value, 'direction')}
                        >
                          <FormControlLabel value={IDirection.Outbound} control={<Radio />} label="Outbound" />
                          <FormControlLabel value={IDirection.Inbound} control={<Radio />} label="Inbound" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <FieldHeader>{t('Outcome')}</FieldHeader>
              <Grid container>
                {OutcomeIcon().map(item => (
                  <Grid key={item.name} item>
                    <Outcome icon={item.icon} name={item.name} value={item.value} />
                  </Grid>
                ))}
              </Grid>

              <FieldHeader>{t('Add note')}</FieldHeader>

              <Notes
                label={t('notification.voice.enterNotes')}
                rows={5}
                value={notes}
                inputProps={{
                  maxLength: COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT,
                }}
                InputProps={{
                  classes: { input: classes.notes },
                  startAdornment:
                    disposition.notes.length >= COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT ? (
                      <InputAdornment
                        position="start"
                        style={{ position: 'absolute', top: '0', right: '0', color: theme.palette.system.errorRed }}
                      >
                        <InfoOutlinedIcon className={classes.errorInfoIcon} />
                      </InputAdornment>
                    ) : (
                      <></>
                    ),
                }}
                onChange={event => setNotes(event.target.value)}
                onBlur={() => handleDisposition(notes.trim(), 'notes')}
                variant="filled"
                multiline
                error={notes.length === COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT}
              />
              <LayoutGrid container justifyContent="space-between" style={{ width: '100%', marginLeft: '0px' }} xs={12}>
                <LayoutGridItem style={{ paddingLeft: '12px' }}>
                  <label className={classes.errorText}>{`${
                    notes.length === COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT
                      ? t('notification.voice.maxCharLimit')
                      : ' '
                  }`}</label>
                </LayoutGridItem>
                <LayoutGridItem>
                  <label
                    className={
                      notes.length === COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT
                        ? classes.errorText
                        : classes.characterCount
                    }
                  >{`${notes.length}/${COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT}`}</label>
                </LayoutGridItem>
              </LayoutGrid>

              <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center">
                <Grid item>
                  {globalData?.snackbarData?.channel === ChannelType.voice &&
                    globalData?.snackbarData?.manual &&
                    !globalData?.snackbarData?.changeWarning && (
                      <CloseConfirmation
                        yesAction={handleModalClose}
                        noAction={() => dispatch(notificationDataActions.setSnackBarData(null))}
                      />
                    )}
                </Grid>
              </Grid>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <Button
                    className={`${classes.actionButton} ${classes.cancelButton}`}
                    onClick={() => onClose(ChannelType.voice, true)}
                    variant="contained"
                  >
                    {`Cancel`}
                  </Button>
                </Grid>
                <Grid item>
                  {withReadOnly(Button)({
                    onClick: saveManualCallLog,
                    disabled:
                      unformatPhoneNumber(globalData.log.data.tos.find(Boolean).phoneNumber)?.length !== 10 ||
                      globalData.log.data.disposition == null,
                    className: classes.actionButton,
                    variant: 'contained',
                    color: 'primary',
                    children: t('Add Call Log'),
                  })}
                </Grid>
              </Grid>
            </div>
          </div>
        </Draggable>
      </>
    </Modal>
  );
};
