import { Grid, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shiftOptions, rateElementsOptions } from '../../helper';
import { RateTable } from './RateTable/RateTable';

const useStyles = makeStyles<{ disabled: boolean }>()((theme, props) =>
({
  disabledView: {
    opacity: '0.4',
  },
}));

export const RateElementsSection = props => {
  const { disabled, setError } = props;
  const { t } = useTranslation();
  const formMethods = useForm({ mode: 'all' });
  const { classes } = useStyles({ disabled });

  return (
    <FormProvider {...formMethods}>
      <Grid container className={disabled && classes.disabledView} style={{ padding: '12px 0px', marginBottom: '20px' }}>
        <Grid container style={{ display: 'flex', alignItems: 'center' }}>
          <Grid>
            <Typography variant="subtitle1" style={{ fontWeight: 500, padding : '8px 0px' }}>
              {t('order.offContractModel.rateElements')}
            </Typography>
          </Grid>
        </Grid>
        <RateTable
          tableTitle={t('order.offContractModel.rateElements')}
          columnOptions={shiftOptions}
          rowOptions={rateElementsOptions}
          disabled={disabled}
          disableRowMenu={false}
          getTableData={props.setRateElemsTableData}
          setError={setError}
        />
      </Grid>
    </FormProvider>
  );
};
