import { makeStyles } from 'tss-react/mui';
import React, { createContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CreatePlacement } from './CreatePlacement';
import {
  selectCreatePlacementOrderType,
  selectNewPlacementChoice,
} from '../../../../store/redux-store/new-placement/selectors';
import { newPlacementActions } from '../../../../store/redux-store/new-placement/slice';
import { createPlacementConfirmDialogTabbingOrder, createPlacementTabbingOrder, createPlacementTabIDs } from './helper';
import { useHistory } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';
import { CircularProgress } from 'amn-ui-core';
import { useTabbingOrder } from 'utils/customHooks/useTabbingOrder';
import { useConfirmExit } from 'utils/customHooks/useConfirmExit';
import { placementStatusAction } from '../../../../store/redux-store/placement-status/slice';
import { DefaultCurrentPlacementStatus } from '../NextPlacementStatus/StatusDefaults';
import { selectPlacementStatus } from '../../../../store/redux-store/placement-status/selectors';
import { trackPageView } from 'app-insights/appInsightsTracking';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { Cancel } from 'app/components/Common/CancelModal/Cancel';
import Auto from 'app/assets/images/Order/Auto.svg';
import { selectAutomationStatus } from 'app/components/Order/OrderDetails/OrderPreferences/store/OrderPreference.selector';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import {
  orderPreferenceReducer,
  orderPreferenceSliceKey,
} from 'app/components/Order/OrderDetails/OrderPreferences/store/OrderPreference.redux';
import { orderPreferenceSaga } from 'app/components/Order/OrderDetails/OrderPreferences/store/OrderPreference.saga';
import useDocumentTitle from 'utils/customHooks/useDocumentTitle';
import { pageNames } from 'app/constants/PageNames';
import { removeSessionValue, SessionKey, StorageType } from 'utils/customHooks/sessionStorage/sessionHelpers';
import { PlacementOrderType } from 'app/models/Placement/PlacementDetails';
import { lookupActions } from 'store/redux-store/lookup/lookup.slice';

const useStyles = makeStyles()({
  dialog: {
    maxWidth: '1050px',
    overflow: 'hidden',
  },
  validationLoader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: '50%',
  },
  inlineSteperIcon: {
    position: 'relative',
    left: '5px',
  },
});

export const CreatePlacementContext = createContext<{
  isDefaultOrder: boolean;
  isDefaultCandidate: boolean;
  setCurrentFocusId: (id) => void;
  closeCreateModal?: () => void;
  useNewCreatePlacementModalFunctions?: boolean;
}>({
  isDefaultOrder: false,
  isDefaultCandidate: false,
  setCurrentFocusId: id => {},
  closeCreateModal: () => {},
  useNewCreatePlacementModalFunctions: false,
});

export const CreatePlacementWrapper = ({
  isDefaultCandidate,
  isDefaultOrder,
  open,
  handleClose,
  initialArgs,
  isOrderRefreshRequest,
  isCandidateRefreshRequest,
  sessionKey,
}: {
  open: boolean;
  handleClose: () => void;
  isDefaultCandidate?: boolean;
  isDefaultOrder?: boolean;
  /** To invoke OnInit saga action */
  initialArgs: { orderId?: number; candidateId?: number; brandId?: number };
  isOrderRefreshRequest?: boolean;
  isCandidateRefreshRequest?: boolean;
  sessionKey?: string | null;
}) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { newPlacementId } = useSelector(selectNewPlacementChoice);
  const { currentPlacementStatus } = useSelector(selectPlacementStatus);
  const orderType = useSelector(selectCreatePlacementOrderType);
  const dispatch = useDispatch();
  const { promiseInProgress: isSubmitting } = usePromiseTracker({ area: 'new-placement-submit', delay: 0 });
  const { promiseInProgress: isValidating } = usePromiseTracker({ area: 'new-placement-get-validations', delay: 0 });
  const { blockExit, allowExit } = useConfirmExit();
  const isSubmissionAutomationEnabled = useSelector(selectAutomationStatus);
  useInjectReducer({ key: orderPreferenceSliceKey, reducer: orderPreferenceReducer });
  useInjectSaga({ key: orderPreferenceSliceKey, saga: orderPreferenceSaga });
  useDocumentTitle({ title: pageNames.createPlacement, revertToPreviousTitle: true });
  const { createScreenEnabled } = useSelector(selectNewPlacementChoice);

  const { setCurrentFocusId, updateIdList } = useTabbingOrder({
    tabOrderList: createPlacementTabbingOrder,
    onUndo: event => {
      event.preventDefault();
      event.stopPropagation();
    },
    onSave: event => {
      if (!validPlacementCreated()) {
        event.preventDefault();
        event.stopPropagation();
        document.getElementById(createPlacementTabIDs.createPlacementSubmitButton)?.focus();
        document.getElementById(createPlacementTabIDs.createPlacementSubmitButton)?.click();
      }
    },
  });

  const formMethods = useForm({
    defaultValues: {
      skillset: null,
      candidate: null,
      order: null,
      availabilityDate: null,
      sellingPoints: null,
      requestedTimeOff: [],
      requestedShift: null,
      recruiter: null,
      screenOption: null,
    },
    shouldUnregister: false,
  });

  const {
    formState: { isDirty },
  } = formMethods;

  const navigateToNewPlacement = () => {
    if (validPlacementCreated()) {
      dispatch(placementStatusAction.reset());
      history.push({ pathname: `/placement/${newPlacementId}`, state: { navigateBack: true } });
      handleClose();
    }
  };

  const validPlacementCreated = () => newPlacementId !== null && newPlacementId !== -1;

  const _onClose = () => {
    //for cancel refresh mfs
    removeSessionValue(SessionKey.createPlacementMFS, StorageType.sessionStorage);
    if (isDirty && !validPlacementCreated()) {
      document.getElementById(createPlacementTabIDs.createPlacementCancelButton)?.focus();
      updateIdList(createPlacementConfirmDialogTabbingOrder, 1);
      setConfirmDialogOpen(true);
      window.requestAnimationFrame(() => {
        document.getElementById(createPlacementTabIDs.createPlacementConfirmCancelButton)?.focus();
      });
    } else {
      updateIdList(createPlacementTabbingOrder);
      handleClose();
    }
  };

  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState<boolean>(false);

  const onCancel = () => {
    updateIdList(createPlacementTabbingOrder);
    setConfirmDialogOpen(false);
  };

  const onDiscardChanges = () => {
    setConfirmDialogOpen(false);
    handleClose();
  };

  React.useEffect(() => {
    dispatch(lookupActions.getAllLookup());
    trackPageView({ name: pageNames.createPlacement });
    dispatch(placementStatusAction.setCurrentPlacementStatus(DefaultCurrentPlacementStatus));
    return () => {
      dispatch(placementStatusAction.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (orderType) dispatch(newPlacementActions.calculatePlacementStatus({ status: undefined }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlacementStatus, orderType]);

  React.useEffect(() => {
    if (isDirty) {
      blockExit();
    } else {
      allowExit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  React.useEffect(() => {
    if (open) dispatch(newPlacementActions.onInitAction({ ...initialArgs }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <CreatePlacementContext.Provider value={{ setCurrentFocusId, isDefaultCandidate, isDefaultOrder }}>
      <FormProvider {...formMethods}>
        <form>
          <GenericDialog
            open={open}
            fullWidth
            maxWidth="md"
            onClose={_onClose}
            classes={{
              paperFullWidth: cx(classes.dialog),
            }}
            dialogTitleProps={{
              leadingIcon: isSubmissionAutomationEnabled ? (
                <CustomTooltip tooltipContent={`Automation is On`}>
                  <img src={Auto} className={classes.inlineSteperIcon} alt="automation" />
                </CustomTooltip>
              ) : undefined,
              text:
                !createScreenEnabled || orderType === PlacementOrderType.StrikeOrderInHsg
                  ? t('placement.create.popup.headerContent')
                  : t('placement.create.popup.createScreenHeaderContent'),
              closeButton: true,
            }}
            dialogActions={[
              {
                id: createPlacementTabIDs.createPlacementCancelButton,
                text: validPlacementCreated() ? 'Close' : 'Cancel',
                variant: 'text',
                color: 'tertiary',
                onClick: _onClose,
              },
              {
                id: createPlacementTabIDs.createPlacementSubmitButton,
                text: 'Go to Placement',
                variant: 'contained',
                hidden: !validPlacementCreated(),
                onClick: navigateToNewPlacement,
              },
            ]}
          >
            <div style={{ overflow: 'hidden' }}>
              <CreatePlacement
                newPlacementId={newPlacementId}
                isDefaultOrder={isDefaultOrder}
                isDefaultCandidate={isDefaultCandidate}
                isOrderRefreshRequest={isOrderRefreshRequest}
                isCandidateRefreshRequest={isCandidateRefreshRequest}
                sessionKey={sessionKey}
                initialArgs={initialArgs}
              />
              {(isValidating || isSubmitting) && <CircularProgress className={classes.validationLoader} />}
              <Cancel
                openDialog={confirmDialogOpen}
                handleCancelAction={onCancel}
                handleConfirmAction={onDiscardChanges}
                cancelButtonId={createPlacementTabIDs.createPlacementConfirmCancelButton}
                confirmButtonId={createPlacementTabIDs.createPlacementConfirmDiscardButton}
              />
            </div>
          </GenericDialog>
        </form>
      </FormProvider>
    </CreatePlacementContext.Provider>
  );
};
