import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { DefinedRange, DateRange } from '../types';
import { isSameDay } from 'date-fns';

type DefinedRangesProps = {
  setRange: (range: DefinedRange) => void;
  selectedRange: DateRange;
  ranges: DefinedRange[];
};

const isSameRange = (first: DateRange, second: DateRange) => {
  const { startDate: fStart, endDate: fEnd } = first;
  const { startDate: sStart, endDate: sEnd } = second;
  if (fStart && sStart && fEnd && sEnd) {
    return isSameDay(fStart, sStart) && isSameDay(fEnd, sEnd);
  }
  return false;
};

const DefinedRanges: React.FunctionComponent<DefinedRangesProps> = props => {
  return (
    <List>
      {props.ranges.map((range, idx) => (
        <ListItem
          style={{ backgroundColor: isSameRange(range, props.selectedRange) ? '#EFEFEF' : '#ffffff' }}
          button
          key={idx}
          onClick={() => props.setRange(range)}
        >
          <ListItemText
            primaryTypographyProps={{
              variant: 'body2',
            }}
          >
            {range.label}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

export default DefinedRanges;
