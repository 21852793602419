import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Popper from '@mui/material/Popper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectFilters } from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import { ClickAwayListener } from 'amn-ui-core';
import { FiltersPopUp } from 'app/ComponentLibrary/Filter/NestedFilters/FiltersPopuUp';
import _ from 'lodash';
const useStyles = makeStyles()(theme => ({
  filterLabel: {
    fontSize: '12px',
    minHeight: '36.7px',
    fontWeight: 400,
    borderRadius: '4px',
    textTransform: 'none',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    padding: '5px 15px',

    '&:hover': {
      textDecoration: 'none',
      border: '1px solid rgba(0, 0, 0, 0.5)',
    },
  },
}));

export function NestedFilters({
  item: { manipulateDefaultValue = (...args) => {}, hasDefaultValue = false, showOperations = true, ...item },
  onApply,
  value,
  applyOnClickAway = false,
  applyOnEnter = false,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const { setValue, getValues, setError, clearErrors } = useFormContext();
  const [prevState, setPrevState] = useState<any>(value?.value);
  const [selectedCount, setSelectedCount] = useState<number>(0);
  const appliedFilterValues = useSelector(selectFilters);
  const { classes } = useStyles();

  useEffect(() => {
    setSelectedCount(((value && value.value) || []).length);
  }, [value]);

  const handleClick = () => event => {
    setAnchorEl(event.currentTarget);
    if (!open && (!value || (value && value?.value && value?.value?.length === 0 && hasDefaultValue))) {
      manipulateDefaultValue(item.name, setValue, value);
    }
    setOpen(prev => !prev);
    setPrevState(value?.value);
  };

  const handleClose = () => {
    resetNestedControlErrors();
    hasDefaultValue ? manipulateDefaultValue(item.name, setValue, null) : setValue(item.name, null);
  };

  const resetNestedControlErrors = () => {
    const res = getValues(item.name);
    if (res && res.value) {
      const mandatoryFields = getMandatoryFields();
      const errorsToRemove: string[] = [];
      for (let i = 0; i < res.value.length; i++) {
        mandatoryFields?.forEach(mF => {
          errorsToRemove.push(`${item.name}-${i}-${mF}`);
        });
      }

      clearErrors(errorsToRemove);
    }
  };

  const handleCancel = event => {
    if (event?.target?.type && event?.target?.type === 'submit') return;
    resetNestedControlErrors();
    const res = appliedFilterValues && appliedFilterValues.filters ? appliedFilterValues.filters[item.name] : null;
    setValue(item.name, res);
    setOpen(false);
  };

  const stringifiedFilters = JSON.stringify(item.filters);

  const getMandatoryFields = React.useCallback((): string[] => {
    const mandatoryFields = item.filters.filter(x => x.isRequired).map(x => x.name);
    return mandatoryFields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedFilters]);

  const isFormControlsValid = React.useCallback((): boolean => {
    const res = getValues(item.name);
    let hasErrors = false;
    if (res && res.value) {
      const mandatoryFields = getMandatoryFields();
      for (let i = 0; i < res.value.length; i++) {
        mandatoryFields?.forEach(mF => {
          if (!res.value[i][mF]) {
            hasErrors = true;
            setError(`${item.name}-${i}-${mF}`, { message: 'hasError' });
          }
        });
      }
    }
    return !hasErrors;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMandatoryFields]);

  const handleApply = React.useCallback(() => {
    if (!isFormControlsValid()) return;

    const res = getValues(item.name);
    setSelectedCount(((res && res.value) || []).length);
    setValue(item.name, res, { shouldDirty: true });
    onApply(res);
    setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormControlsValid, onApply]);

  const onClickAway = () => {
    const isEqual = _.isEqual(prevState, value?.value);
    if (!isEqual) {
      handleApply();
    } else {
      handleCancel({});
    }
  };

  const handleKeyDown = React.useCallback(
    event => {
      /**
       * Code: click on Enter key invokes submission
       * if open && applyOnEnter
       */
      if (open && applyOnEnter && event.key === 'Enter') {
        handleApply();
      }
      /** Add future key down features below */
    },
    [applyOnEnter, handleApply, open],
  );

  React.useEffect(() => {
    document.body.addEventListener('keydown', handleKeyDown);

    return () => {
      document.body.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div>
      <Popper open={open} anchorEl={anchorEl} placement={'bottom-start'} transition style={{ zIndex: 4 }}>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={applyOnClickAway ? onClickAway : handleCancel}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <FiltersPopUp
                  filters={item.filters}
                  controlName={item.name}
                  value={value}
                  nestedlabelPrefix={item.nestedlabelPrefix}
                  handleClose={handleClose}
                  handleApply={handleApply}
                  handleCancel={handleCancel}
                  resetNestedControlErrors={resetNestedControlErrors}
                  isFormControlsValid={isFormControlsValid}
                  selectedCount={selectedCount}
                  hasDefaultValue={hasDefaultValue}
                  showOperations={showOperations}
                  manipulateDefaultValue={manipulateDefaultValue}
                />
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
      <Grid container>
        <Grid item>
          <Button
            onClick={handleClick()}
            className={classes.filterLabel}
            style={{ color: open || selectedCount > 0 ? 'rgb(82, 66, 154)' : 'rgb(192, 192, 192)' }}
          >
            {selectedCount > 0 ? '(' + selectedCount + ') ' : ''}
            {item.filterName} {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
