import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { GenericDialog, IGenericDialogButton } from 'app/components/Alerts/GenericDialog';
import { Theme } from 'amn-ui-core';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogTitle: {
    padding: '30px 30px 20px 30px',
    fontSize: '20px',
  },
  dialogContent: {
    padding: '0px 30px 20px 30px',
    fontSize: '14px',
    whiteSpace: 'pre-line',
  },
}));

export interface IActionModalProps {
  open: boolean;
  primaryBtns: IGenericDialogButton[];
  secondaryBtns: IGenericDialogButton[];
  title: string;
  message: string;
  loading?: boolean;
}

export const ActionModal: React.FC<IActionModalProps> = ({
  open,
  primaryBtns = [],
  secondaryBtns = [],
  title,
  message,
  loading,
}) => {
  const { classes } = useStyles();

  const dialogActions = React.useMemo(() => {
    // @ts-ignore
    const actions: IGenericDialogButton[] = [
      ...secondaryBtns.map(btnProps => ({
        ...btnProps,
        color: 'tertiary',
      })),
      ...primaryBtns.map(btnProps => ({
        ...btnProps,
        variant: 'contained',
        loading: loading,
      })),
    ];
    return actions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondaryBtns, primaryBtns]);

  return (
    <GenericDialog
      open={open}
      maxWidth="sm"
      dialogTitleProps={{ classes: { root: classes.dialogTitle }, text: title }}
      dialogContentProps={{ classes: { root: classes.dialogContent } }}
      dialogActions={dialogActions}
    >
      {message}
    </GenericDialog>
  );
};
