import { makeStyles } from 'tss-react/mui';
import {
  PreviewContent,
  PreviewContentTitle,
} from 'app/components/Order/OrderCreationForm/DefineUnit/UnitPreview/UnitPreviewDesign';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from 'amn-ui-core';
import { XGrid } from 'amn-ui-core';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTooltip } from '@AMIEWEB/Common';
import { OffContractIcon } from 'app/ComponentLibrary/Icons/OffContractIcon';
import { getColorById } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/RateCardTab/RateCardSections/RateElement';
import { getDisplayStatusName } from '@AMIEWEB/Order/OrderCreationForm/RateCard/helper';
import { selectEffectiveDates, selectRateCard } from '@AMIEWEB/Order/Store/Order.selectors';
import { missingField } from 'app/constants';
import { orderDataActions } from '@AMIEWEB/Order/Store/Order.redux';
import EditIcon from '@mui/icons-material/Edit';
import _ from 'lodash';
import styled from 'styled-components';
import { colors } from 'styles/styleVariables';
import { selectOcbrDetails } from 'store/redux-store/create-order-ocbr/selector';
import moment from 'moment';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { selectUser } from 'oidc/user.selectors';

const useStyles = makeStyles()(theme => ({
  grid: {
    border: 'none',
    '& .MuiDataGrid-row': {
      borderBottom: '1px solid #D5D5D5',
    },
    '& .MuiDataGrid-virtualScroller': {
      maxHeight: 'auto',
      height: 'auto',
      borderBottom: '1px solid #D5D5D5',
    },
  },
  container: {
    '& [data-id="edit-icon"]': {
      opacity: 0,
      transition: 'opacity 0.25s',
    },
    '&:hover': {
      '& [data-id="edit-icon"]': {
        opacity: 1,
        transition: 'opacity 0.25s',
      },
    },
  },
  editIcon: {
    height: '20px',
    width: '20px',
    cursor: 'pointer',
    color: '#8E9195',
  },
  effectiveDateHeader: {
    fontWeight: 'bold',
    paddingTop: '12px !important',
    fontSize: '12px',
  },
  effectiveDate: {
    paddingTop: '0 !important',
  },
}));

export const OrderDetailsRateCard = ({ disableEdit }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { rates, rateColumns, skillsets, skillsetColumns } = useSelector(selectRateCard);
  const { effectiveDates } = useSelector(selectOcbrDetails);
  const user = useSelector(selectUser);

  const [xGridSortModel] = React.useState<any>([{ field: 'skillSet', sort: 'asc' }]);
  const [readOnly, setReadOnly] = React.useState<boolean>(false);

  const onEditIconClick = React.useCallback(() => {
    dispatch(orderDataActions.setDetailsCurrentStep('editRateCard'));
  }, []);

  React.useEffect(() => {
    if (
      Authorized(
        [
          userRoles.payrollSpecialist,
          userRoles.customerSupport_Leadership,
          userRoles.customerSupport_TeamMember,
          userRoles.accountsPayableSpecialist,
        ],
        user.userInfo,
      )
    ) {
      setReadOnly(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.userInfo]);

  const RateElementsGrid = React.useMemo(
    () => (
      <GridContainer>
        <XGrid
          // @ts-ignore
          columns={skillsetColumns}
          rows={skillsets}
          className={classes.grid}
          autoHeight={true}
          headerHeight={48}
          rowHeight={39}
          initialState={{ pinnedColumns: { left: ['skillSet', 'billRate'] } }}
          hideFooter
          disableMultipleSelection={true}
          sortModel={xGridSortModel}
        />
      </GridContainer>
    ),
    [skillsetColumns, skillsets],
  );

  const SkillsetGrid = React.useMemo(
    () => (
      <GridContainer>
        <XGrid
          // @ts-ignore
          columns={rateColumns}
          rows={rates}
          className={classes.grid}
          autoHeight={true}
          headerHeight={48}
          rowHeight={39}
          initialState={{ pinnedColumns: { left: ['rateElement'] } }}
          hideFooter
          disableMultipleSelection={true}
        />
      </GridContainer>
    ),
    [rateColumns, rates],
  );

  return (
    <PreviewContent variant="outlined" elevation={0} className={classes.container}>
      <Grid container direction="column" spacing={3}>
        {/* Title */}
        <Grid item>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <PreviewContentTitle>{t('order.createUnit.rateCard')}</PreviewContentTitle>
            </Grid>
            {!readOnly && (
              <Grid item data-id="edit-icon">
                <EditIcon
                  className={classes.editIcon}
                  id={'OrderDetailsExperienceEditTrigger'}
                  onClick={onEditIconClick}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* Effective Date */}
        <Grid item xs={12} className={classes.effectiveDateHeader}>
          Effective Date
        </Grid>
        <Grid item xs={12} className={classes.effectiveDate}>
          {moment(effectiveDates?.startDate).format('MM/DD/YYYY')} -{' '}
          {moment(effectiveDates?.endDate).format('MM/DD/YYYY')}
        </Grid>
        {/* <Grid item></Grid> */}
        {/* Skill grid */}
        <Grid item>{RateElementsGrid}</Grid>
        {/* Rate Elements */}
        <Grid item>{SkillsetGrid}</Grid>
      </Grid>
    </PreviewContent>
  );
};

const GridContainer = styled.div`
  border: 1px solid #cccccc;
  border-radius: 4px;
  & .super-app-theme--header {
    background-color: #f4f5f7;
  }
`;

export const OffContractTableCell = param => {
  const { t } = useTranslation();
  return (
    <Grid container xs={12} display="flex">
      <Grid item xs={10} display="flex">
        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', paddingTop: '4px' }}>
          {param.value ?? missingField}
        </span>
      </Grid>
      {!!param.row.toolTipContent.length && (
        <Grid item xs={2} display="flex" justifyContent="flex-end" alignItems="center">
          <CustomTooltip
            tooltipContent={
              <Box sx={{ width: '100%' }}>
                <Typography color="inherit" variant="body2">
                  {t('order.createUnit.offContract')}
                </Typography>
                {(param.row.toolTipContent || []).map((item, index) => {
                  return (
                    <Box key={index.toString()}>
                      <Typography variant="body2">
                        <span style={{ fontWeight: 450 }}>{`${item.name}, ${item.shift}: ${item.value}`}</span>
                      </Typography>
                      <Typography variant="body2">
                        <span style={{ fontWeight: 450 }}>{t('Status: ')}</span>
                        <span style={{ color: getColorById(item.status) }}>{getDisplayStatusName(item.status)}</span>
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            }
          >
            <OffContractIcon />
          </CustomTooltip>
        </Grid>
      )}
    </Grid>
  );
};
