import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { useWatch } from 'react-hook-form';
import { useEditableRequirement } from './EditRequirementsHelper';
import { ArrivalDateCheck } from './EditablePassFailLogic';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPlacementStatus } from 'store/redux-store/placement-status/selectors';
import { placementStatus } from '@AMIEWEB/RecruiterWorkDesk/MyPlacementsDesk/utils';

export const useEditableRequirementArrivalDate = () => {
  // DOB
  const arrivalDate: string | undefined = useWatch({ name: 'arrivalDate' });
  const transportation: any = useWatch({ name: 'transportationChosen' });
  const { currentPlacementStatus } = useSelector(selectPlacementStatus);
  const secondaryData = useMemo(() => ({
    transportation: transportation?.transportation, placementStatus: currentPlacementStatus?.activityStatus
  }),
    [transportation?.transportation, currentPlacementStatus?.activityStatus]);

  useEditableRequirement({
    data: arrivalDate,
    secondaryData: secondaryData,
    requirementId: IPlacementRequirementKey.arrivalDate,
    requirementKey: 'arrivalDate',
    requirementCheck: ArrivalDateCheck,
    shouldAddFailedRequirement:
      currentPlacementStatus?.activityStatus?.toLowerCase() === placementStatus.travelerAccepts.toLowerCase(),
    failRequirement: {
      id: IPlacementRequirementKey.arrivalDate,
      message: 'Arrival Date: Missing',
      tooltip: {
        message: 'Arrival Date is required.',
      },
    },
  });
};
