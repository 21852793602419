import { ChipsContainer } from 'app/ComponentLibrary/Filter';
import { instanceOfISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import { determineChipSelected } from 'app/components/AmWorkDesk/PlacementsDesk/filterHelper';
import { selectFilters, selectGridState } from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createFilterChips } from 'app/components/RecruiterWorkDesk/MyPlacementsDesk/WorkdeskFilter/filterHelper';
import { selectOrderMatchChips } from '../store/candidateOrderMatch.selector';
import { getCandidateOrderMatchActions } from '../store/candidateOrderMatch.redux';
import { Collapse, Grid } from 'amn-ui-core';
import { SearchPageIcons } from 'app/ComponentLibrary/Filter/SearchPageIcons';
import { makeStyles } from 'tss-react/mui';
import { OrderMatchFlterName } from './OrderMatchFilterUtils';
import { instanceOfAggregateCandidate } from 'app/ComponentLibrary/Filter/CandidateSearch';
import { instanceOfCandidateSkillSetFilter } from 'app/ComponentLibrary/Filter/CandidateSkillset';
import { deleteAdditionalSkillSetFilters, getFilteredSkillSets } from '@AMIEWEB/GlobalSearch/SearchResultsChips';
import { totalCountNodes } from '@AMIEWEB/Common/TreeView/TreeViewHelpers';

const useStyles = makeStyles()({
  fillterCollapse: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const OrderMatchChips = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { setValue, getValues } = useFormContext();
  const appliedFilter = useSelector(selectFilters);
  const hiddenSubmitButton = createRef<HTMLButtonElement>();
  const chips = useSelector(selectOrderMatchChips);
  const dispatch = useDispatch();
  const [showChipsCollapseBtn, setShowChipsCollapseBtn] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  const handleChange = () => {
    setChecked(prev => !prev);
  };

  const gridState = useSelector(selectGridState);

  const getNewSelection = (key, chip) => ({
    ...appliedFilter?.selectedChipFilters,
    [key]: determineChipSelected(
      chip,
      appliedFilter?.selectedChipFilters ? appliedFilter?.selectedChipFilters[key] : undefined,
    ),
  });
  const handleChipDelete = (field, chip) => {
    const { value: chipValue } = chip;

    const fieldValues = getValues(field);
    let newFieldValues: any = null;

    if (instanceOfCandidateSkillSetFilter(fieldValues)) {
      const skillsetsCount = totalCountNodes(fieldValues);

      newFieldValues = {
        isVerifiedOnly: deleteAdditionalSkillSetFilters('isVerifiedOnly', chip, skillsetsCount)
          ? false
          : fieldValues?.isVerifiedOnly,
        operation: deleteAdditionalSkillSetFilters('operation', chip, skillsetsCount) ? '' : fieldValues?.operation,
        allowableMismatch: deleteAdditionalSkillSetFilters('allowableMismatch', chip, skillsetsCount)
          ? false
          : fieldValues?.allowableMismatch,
        skillsets: getFilteredSkillSets(fieldValues?.skillsets, chip),
      };
    } else if (Array.isArray(fieldValues)) {
      newFieldValues = [...fieldValues];
      newFieldValues.splice(
        newFieldValues.findIndex(opt => (instanceOfISelection(chip) ? opt.value : opt) === chipValue?.value),
        1,
      );
      setValue(field, newFieldValues);
    } else setValue(field, null);

    if (field === OrderMatchFlterName.state) {
      const compactOptIndex = fieldValues.findIndex(
        item => item.isCompactOpt && item.value.some(s => s.value === chipValue?.value),
      );

      newFieldValues = fieldValues.filter(item => item.value !== chipValue?.value);
      if (compactOptIndex !== -1) newFieldValues.splice(compactOptIndex, 1);

      setValue(field, newFieldValues);
    }

    setValue(field, newFieldValues, { shouldDirty: true });

    if (chip?.isSelected) {
      const newSelections = getNewSelection(field, chip);
      props.onApplyFilter(
        { ...getValues(), [field]: Array.isArray(fieldValues) ? [...(newFieldValues || [])] : null },
        newSelections,
      );
    } else hiddenSubmitButton.current?.click();
  };

  /** Restricted to call onApplyfilter directly -
   * observes not to remove existing filter and chips */
  const handleChipClick = (key, chip) => {
    const fieldValues = getValues(key);
    /**Check to avoid click on single valued fields/chips */
    if (!Array.isArray(fieldValues)) {
      if (!instanceOfAggregateCandidate(fieldValues) && !instanceOfCandidateSkillSetFilter(fieldValues)) return;
      else if (chip?.value?.key && !Array.isArray(fieldValues[chip.value.key])) return;
    }
    /**
     * Below functionalty are limited to type ISelection
     * Need to be updated for dates and single selection
     */
    const newChipSelection = getNewSelection(key, chip);
    props.onApplyFilter(getValues(), newChipSelection);
  };
  useEffect(() => {
    if (gridState.gridTag === 'CandidateOrderMatch') {
      appliedFilter?.filters &&
        dispatch(
          getCandidateOrderMatchActions.setChips({
            ...chips,
            ...createFilterChips(appliedFilter?.filters, appliedFilter?.selectedChipFilters, t),
          }),
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilter?.filters]);

  useEffect(() => {
    const chipsData = Object.keys(chips).some(key => {
      const value = chips[key];
      if (Array.isArray(value) && value.length > 0) {
        return true;
      }
      if (typeof value === 'object' && value !== null && Object.keys(value).length > 0) {
        return true;
      }
      return false;
    });
    setShowChipsCollapseBtn(chipsData);
  }, [chips]);

  return (
    <>
      {showChipsCollapseBtn ? (
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Collapse in={checked} collapsedSize={38}>
              <div ref={divRef}>
                <ChipsContainer
                  chips={chips}
                  onClick={handleChipClick}
                  onDelete={handleChipDelete}
                  isCollapse={!checked}
                />
              </div>
              <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
            </Collapse>
          </Grid>
          <Grid item xs={1} className={classes.fillterCollapse}>
            {(divRef?.current?.clientHeight ?? 0) > 38 && (
              <SearchPageIcons KeyboardArrowIcon onAdvancedChipsClick={() => handleChange()} checked={checked} />
            )}
          </Grid>
        </Grid>
      ) : (
        <>
          <ChipsContainer chips={chips} onClick={handleChipClick} onDelete={handleChipDelete} />
          <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
        </>
      )}
    </>
  );
};

export default OrderMatchChips;
