import { PlacementSearchFilterName } from './SearchUtils';

const defaultFilterValues = {
  [PlacementSearchFilterName.recruiter]: [],
  [PlacementSearchFilterName.accountManager]: [],
  [PlacementSearchFilterName.credentialingAnalyst]: [],
  [PlacementSearchFilterName.placementClinicalContractSpecialist]: [],
  [PlacementSearchFilterName.placementClinicalManager]: [],
  [PlacementSearchFilterName.archiveStatus]: null,
  [PlacementSearchFilterName.amnDivision]: [],
  [PlacementSearchFilterName.brand]: [],
  [PlacementSearchFilterName.candidate]: [],
  [PlacementSearchFilterName.cid]: [],
  [PlacementSearchFilterName.placementTags]: [],
  [PlacementSearchFilterName.oid]: [],
  [PlacementSearchFilterName.pid]: [],
  [PlacementSearchFilterName.facility]: [],
  [PlacementSearchFilterName.facilityID]: [],
  [PlacementSearchFilterName.unit]: [],
  [PlacementSearchFilterName.contractAffiliate]: [],
  [PlacementSearchFilterName.orderType]: [],
  [PlacementSearchFilterName.city]: '',
  [PlacementSearchFilterName.state]: '',
  [PlacementSearchFilterName.mspClient]: [],
  [PlacementSearchFilterName.discipline]: [],
  [PlacementSearchFilterName.specialty]: [],
  [PlacementSearchFilterName.status]: [],
  [PlacementSearchFilterName.skillSet]: [],
  [PlacementSearchFilterName.submission]: [],
  [PlacementSearchFilterName.extensionStatus]: [],
  [PlacementSearchFilterName.availableStartDate]: null,
  [PlacementSearchFilterName.startDate]: null,
  [PlacementSearchFilterName.endDate]: null,
  [PlacementSearchFilterName.placementCuedTo]: [],
  [PlacementSearchFilterName.screen]: [],
  [PlacementSearchFilterName.contractAffiliateIds]: [],
  [PlacementSearchFilterName.staffingVendor]: [],
  [PlacementSearchFilterName.region]: [],
  [PlacementSearchFilterName.arbitrationStatus]: [],
  [PlacementSearchFilterName.recruitmentLeaders]: [],
  [PlacementSearchFilterName.nonContacted]: null,
};

export const resetFilters = setValue => {
  Object.keys(defaultFilterValues).forEach(filterName => {
    setValue(filterName, defaultFilterValues[filterName]);
  });
};
