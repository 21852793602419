import { GridCellParams } from '@mui/x-data-grid';
import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Typography } from 'amn-ui-core';
import { theme } from 'styles/global-styles';
import {
  clientContactActions,
  clientContactReducer,
  clientContactSliceKey,
} from '../../CreateClientContact/store/ClientContact.redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { clientContactSaga } from '../../CreateClientContact/store/ClientContact.saga';

interface GridCellProps {
  [key: string]: any;
}

const useStyles = makeStyles()({
  truncation: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  link: {
    textDecoration: 'underline',
    color: theme.palette.system.primary,
    fontSize: '14px',
    cursor: 'pointer',
  },
});

const ClientContactModalRenderer = React.memo(function ClientContactModalRenderer(cellParams: GridCellProps) {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  useInjectReducer({ key: clientContactSliceKey, reducer: clientContactReducer });
  useInjectSaga({ key: clientContactSliceKey, saga: clientContactSaga });

  return (
    <React.Fragment>
      <span
        className={classes.truncation}
        onClick={() => {
          dispatch(clientContactActions.getClientContactById(cellParams?.row?.contactId));
          dispatch(clientContactActions.setIsClientContactEditModelOpen(true));
        }}
      >
        <Typography className={classes.link}>{`${cellParams?.row[cellParams?.field]}`}</Typography>
      </span>
    </React.Fragment>
  );
});

const InitiateEditModal = (params: GridCellParams) => {
  return <ClientContactModalRenderer {...params} />;
};

export default InitiateEditModal;
