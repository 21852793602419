export enum SupervisorStatus {
  APPROVED = 'Approved',
  DECLINED = 'Declined',
  PENDING = 'Pending',
}
export const skillsetStatusOptions = [
  {
    ID: 1,
    Description: SupervisorStatus.APPROVED,
  },
  {
    ID: 2,
    Description: SupervisorStatus.DECLINED,
  },
];

export enum ChipColors {
  GREEN = '#13A024',
  GREEN_BACKGROUND = 'rgba(19, 160, 36, 0.1)',
  YELLOW = '#ED812C',
  YELLOW_BACKGROUND = 'rgba(237, 129, 44, 0.1)',
  RED = '#CC0000',
  RED_BACKGROUND = 'rgba(204, 0, 0, 0.1)',
}
