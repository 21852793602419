import IconButton from '@mui/material/IconButton';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import moment from 'moment';
import { selectUser } from 'oidc/user.selectors';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ResetIcon from '../../../../assets/images/Notification/ResetIcon.png';
import { candidateDetailActions, loadUserLogs } from '../Profile/CandidateDetails.redux';
import { candidateDetailsSelection, selectChannelValues } from '../Profile/CandidateDetails.selector';

interface dated {
  key: string;
  value: string;
}

export const DATED: dated[] = [
  { key: 'd', value: 'Today' },
  { key: 'w', value: 'Last 7 days' },
  { key: 'm', value: 'This Month' },
  { key: 'y', value: 'This Year' },
];

const useStyles = makeStyles<{ width: number }>()((theme, { width }) => ({
  type: {
    width: !!width ? width : 81,
    height: 47,
    borderRadius: '5px',
    background: '#FFFFFF',
    marginRight: '9px',
    paddingTop: '18px',
  },
  initiatedBy: {
    height: 45,
    width: 123,
    borderRadius: '5px',
    background: '#FFFFFF',
    '&:hover': {
      background: '#FFFFFF',
    },
  },
  selection: {
    background: '#FFFFFF',
    marginRight: '9px',
    borderRadius: '5px',
    border: '1px solid #B9BBBC',
    '&:hover': {
      border: '1px solid #000',
    },
  },
  date: {
    height: 45,
    width: 85,
    borderRadius: '5px',
    background: '#FFFFFF',
    '&:hover': {
      background: '#FFFFFF',
    },
  },
  reset: {
    padding: '6px',
    marginLeft: '2px',
    border: '1px solid #B9BBBC',
    borderRadius: '5px',
    background: '#FFFFFF',
    '&:hover': {
      background: '#FFFFFF',
    },
  },
  inputLabel: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#55555',
    '&.Mui-focused': {
      color: '#55555',
    },
    '&:focus': {
      color: '#55555 !important',
    },
  },
  select: {
    borderRadius: '4px',
  },
}));

const CommunicationLogsFilter = props => {
  const { isCommunicationHistoryGrid, candidateData } = props;
  const width = isCommunicationHistoryGrid ? 156 : 81;
  const { classes } = useStyles({ width: width });
  const dispatch = useDispatch();
  const [dropdown, setDropdown] = useState<any>();
  // TODO - @TEAM - unused usestate variable
  const userInfo = useSelector(selectUser);
  const notificationData = useSelector(notificationSelection);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const filters = notificationData.logFilters;
  const channels = candidateDetails.channels || ['SMS', 'Email', 'Call'];
  const gridLogFilters = notificationData.gridLogFilters;
  const channelValues = useSelector(selectChannelValues);

  const getPayload = (filters: any) => {
    const dated = DATED?.find(date => date.value === filters?.date)?.key;
    const channels = filters.channel.length > 0 ? [...filters.channel] : ['Conversation', 'Email', 'Voice'];
    const VoiceIndex = channels.indexOf('Call');
    if (VoiceIndex !== -1) {
      channels[VoiceIndex] = 'Voice';
    }
    const SMSIndex = channels.indexOf('SMS');
    if (SMSIndex !== -1) {
      channels[SMSIndex] = 'Conversation';
    }
    return {
      brandId: candidateData?.brandId?.toString(),
      contactId: candidateData?.travelerId?.toString(),
      pageNumber: 1,
      channel: channels,
      userRole: filters?.userRole,
      periodType: dated,
      startDate: filters?.startDate,
      endDate: filters?.endDate,
      initiatedBy: filters?.initiatedBy === 'Me' ? userInfo?.userInfo?.email : filters?.initiatedBy,
    };
  };

  const resetFilter = () => {
    const payload = {
      brandId: candidateData?.brandId?.toString(),
      contactId: candidateData?.travelerId?.toString(),
      channel: ['Conversation', 'Email', 'Voice'],
    };
    dispatch(notificationDataActions.resetFilters(''));
    dispatch(notificationDataActions.resetChips());
    dispatch(loadUserLogs({ payload }));
    candidateData &&
      dispatch(
        candidateDetailActions.getAvailableChannelFilters({
          contactId: candidateData?.travelerId?.toString(),
          brandId: candidateData?.brandId?.toString(),
        }),
      );
  };

  const handleFilterChange = (event, field) => {
    const filtersTemp = JSON.parse(JSON.stringify(filters));
    if (field === 'date') {
      const periodType = event.target.value as string;
      const currentDate = moment.utc().startOf('day');

      switch (periodType) {
        case 'Today':
          filtersTemp['periodType'] = 'Today';
          filtersTemp['startDate'] = currentDate;
          filtersTemp['endDate'] = currentDate;
          break;
        case 'Last 7 days':
          filtersTemp['periodType'] = 'Last 7 days';

          filtersTemp['startDate'] = moment.utc().startOf('day').add(-7, 'd');
          filtersTemp['endDate'] = currentDate;
          break;
        case 'This Month':
          filtersTemp['periodType'] = 'This Month';
          filtersTemp['startDate'] = moment.utc().startOf('day').add(-1, 'M');
          filtersTemp['endDate'] = currentDate;
          break;
        case 'This Year':
          filtersTemp['periodType'] = 'This Year';
          filtersTemp['startDate'] = moment.utc().startOf('day').add(-1, 'y');
          filtersTemp['endDate'] = currentDate;
          break;
      }
    }
    if (field === 'type') {
      filtersTemp['channel'] = event.target.value;
    } else {
      filtersTemp[field] = event.target.value as string;
    }
    dispatch(notificationDataActions.setFilters(filtersTemp));
    const payload = getPayload(filtersTemp);
    dispatch(loadUserLogs({ payload }));
  };

  return (
    <div>
      <Box marginBottom={'10px'} display={'flex'} alignItems={'center'}>
        <FormControl size="small" variant="filled">
          <InputLabel className={classes.inputLabel} id="demo-customized-select-label">
            {isCommunicationHistoryGrid ? `Communication Type` : `Type`}
          </InputLabel>
          <Select
            open={dropdown?.type}
            onClose={() => setDropdown({ type: false })}
            onOpen={() => setDropdown({ type: true })}
            multiple
            className={classes.type}
            value={filters?.channel}
            onChange={e => handleFilterChange(e, 'type')}
            IconComponent={ExpandMoreOutlinedIcon}
            data-testid="Communication-Type-filter"
            input={<OutlinedInput />}
            renderValue={value =>
              filters?.channel
                ?.map(item => item)
                .join(', ')
                .toString()
            }
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
          >
            {channelValues?.map(channel => (
              <MenuItem key={channel} value={channel} data-testid={`Communication-Type-option-${channel}`}>
                <Checkbox checked={filters && filters?.channel?.indexOf(channel) > -1} />
                <ListItemText primary={channel} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="small" variant="filled" className={classes.selection}>
          <InputLabel className={classes.inputLabel} id="demo-customized-select-label">{`Initiated By`}</InputLabel>
          <Select
            open={dropdown?.initiatedBy}
            onClose={() => setDropdown({ initiatedBy: false })}
            onOpen={() => setDropdown({ initiatedBy: true })}
            className={classes.initiatedBy}
            value={isCommunicationHistoryGrid ? gridLogFilters?.initiatedBy : filters?.initiatedBy}
            onChange={e => handleFilterChange(e, 'initiatedBy')}
            IconComponent={ExpandMoreOutlinedIcon}
            data-testid="Initiated-By-filter"
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
            disableUnderline
          >
            <MenuItem value={`All`} data-testid="Initiated-By-option-All">{`All`}</MenuItem>
            <MenuItem value={`Me`} data-testid="Initiated-By-option-Me">{`Me`}</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" variant="filled" className={classes.selection}>
          <InputLabel className={classes.inputLabel} id="demo-customized-select-label">{`Date`}</InputLabel>
          <Select
            open={dropdown?.date}
            onClose={() => setDropdown({ date: false })}
            onOpen={() => setDropdown({ date: true })}
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={filters?.periodType}
            className={classes.date}
            data-testid="Date-filter"
            onChange={e => handleFilterChange(e, 'date')}
            IconComponent={ExpandMoreOutlinedIcon}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
            disableUnderline
          >
            {DATED.map(date => (
              <MenuItem key={date.value} value={date.value} data-testid={`Date-option-${date.value}`}>
                {date.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton
          color="primary"
          disableRipple
          className={classes.reset}
          onClick={() => {
            resetFilter();
          }}
          data-testid="reset-button"
          size="small"
        >
          <img alt={`Reset Icon`} height={'33px'} src={ResetIcon} />
        </IconButton>
      </Box>
    </div>
  );
};

export default CommunicationLogsFilter;
