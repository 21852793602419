import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TransportationRow } from './TransportationTable';
import {
  selectEditableRequirementTypes,
  selectPicklists,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { InlineAutoComplete } from 'app/components/Common/EditPage/InlineAutoComplete';

export const DepartureAirport = () => {
  const { t } = useTranslation();
  const { departureAirport } = useSelector(selectEditableRequirementTypes);

  const { airportCodes } = useSelector(selectPicklists);

  return (
    <TransportationRow
      id="placement-offer-transportation-departure-airport"
      label={t('placement.profile.summary.offer.transportation.departureAirport')}
      requirement={departureAirport?.requirement}
      controlComponent={
        <Controller
          name="departureAirport"
          render={({ ref, ...rest }) => (
            <InlineAutoComplete
              {...rest}
              /** Input value is used to override textfield incase DOM is rerendered */
              inputValue={rest.value?.label}
              options={airportCodes}
              style={{ maxWidth: '110px' }}
            />
          )}
        />
      }
    />
  );
};
