import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { serviceActions } from './async-actions';
import { initialState } from './initialState';
import { IActivePlacementCounts, IExistingOrder, IOrderAutomationPlacement, ISubmissionPreferences } from './types';

const slice = createSlice({
  name: 'unitPreferences',
  initialState,
  reducers: {
    setPreferences(state, action: PayloadAction<ISubmissionPreferences>) {
      return { ...state,  
        submissionPreferences: {
        ...state.submissionPreferences,
        ...action.payload,
      }, };
    },
    setActivePlacementCounts(state, action: PayloadAction<{ rfo: number; sent: number; others: number }>) {
      return {
        ...state,
        submissionPreferences: {
          ...state.submissionPreferences,
          submissionLimits: { activePlacementCounts: { ...action.payload } },
        },
      };
    },
    setFacilityUnitClientContacts(state, action: PayloadAction<any[]>) {
      return { ...state, facilityUnitClientContacts: action.payload };
    },
    setIsPreferenceChaged(state, action: PayloadAction<boolean>) {
      return { ...state, isPreferenceChanged: action.payload };
    },
    setConfirmationDialog(state, action: PayloadAction<boolean>) {
      return { ...state, confirmationDialog: action.payload };
    },
    setTabValue(state, action: PayloadAction<number>) {
      return { ...state, tabValue: action.payload };
    },
    setMiscellaneousData(
      state,
      action: PayloadAction<{ key: 'disableEmailContainer' | 'disablePacketContainer'; value: boolean }>,
    ) {
      return { ...state, miscellaneous: { ...state.miscellaneous, [action.payload.key]: action.payload.value } };
    },
    setExsistingOrders(state, action: PayloadAction<IExistingOrder[]>) {
      return { ...state, existingOrders: action.payload };
    },
    setOrderAutomationPlacements(state, action: PayloadAction<IOrderAutomationPlacement[]>) {
      return { ...state, existingPlacements: action.payload };
    },
    setOrderActivePlacementCounts(state, action: PayloadAction<IActivePlacementCounts>) {
      return { ...state, orderActivePlacementCounts: action.payload };
    },
    setReadyToSubmitRfoPlacementIds(state, action: PayloadAction<number[]>) {
      return { ...state, readyToSubmitPlacementIds: action.payload };
    },
    setPreferenceSelectionModal(state, action: PayloadAction<number[]>) {
      return { ...state, preferenceSelectionModal: action.payload };
    },
    setPreferenceUpdateDialog(state, action: PayloadAction<boolean>) {
      return { ...state, updateDialogOpen: action.payload };
    },
    setUpdateAutomationApiProgressStatus(state, action: PayloadAction<boolean>) {
      return { ...state, updateInprogres: action.payload };
    },
  },
});

export const unitPreferenceActions = {
  ...slice.actions,
  ...serviceActions,
};

export const { name: unitPreferenceSliceKey, reducer: unitPreferenceReducer } = slice;
