import { TableRow, TableCell } from 'amn-ui-core';
import { ICustomHeader } from 'app/models/Grid';
import React from 'react';
import HoverView from './HoverView';

interface ExpandedGridTableRowProps {
  [key: string]: any;
  row: any;
  rowKey: number;
  columns: any;
  hasEditMenu: boolean;
  fullWidth: boolean;
  editingIndex: number | null;
  centerHoverView;
  showEditControl;
  stopEditing;
  _onValueChanged;
  didEdit;
  disableRowColors;
  _onGridClose;
  hasExpandableRow;
  isLastRow;
  classes;
}

export const ExceptionWaiveGridTableRow = ({
  row,
  rowKey,
  columns,
  hasEditMenu,
  fullWidth,
  editingIndex,
  classes,
  centerHoverView,
  showEditControl,
  stopEditing,
  _onValueChanged,
  didEdit,
  disableRowColors,
  _onGridClose,
  hasExpandableRow,
  isLastRow,
  searchHighlight,
  searchFoundIndex,
  onStateNavigation,
  ...rest
}: ExpandedGridTableRowProps) => {
  return (
    <>
      {hasExpandableRow ? (
        <TableRow
          data-rowid={row.rowID}
          data-editingrow={row?.isEditing && rowKey === editingIndex}
          className={`${classes.table}`}
        >
          {columns.map((header: ICustomHeader, key) => (
            <React.Fragment key={key}>
              <TableCell
                className={`${key === 0 ? classes.staticRowCell : ''} ${classes.tablecell} ${classes.subGridRowCell}`}
                colSpan={header?.colSpan || 0}
              >
                {header.hasHoverView ? (
                  <HoverView
                    text={row[header.title]}
                    tooltipAttributes={row[header.tooltipAttributes as unknown as string]}
                    header={header}
                    data={row}
                    searchInitiation={searchHighlight && searchFoundIndex === row.rowID}
                    centerHoverView={centerHoverView}
                    onStateNavigation={onStateNavigation}
                    filterOptionsOverride={row[`${header.title}FilterOptions`]}
                    disableRowColors={disableRowColors}
                    edit={{
                      rowKey: rowKey,
                      key: header.title,
                      isEditing: row?.isEditing,
                      onValueChanged: _onValueChanged,
                    }}
                  />
                ) : (
                  row[header.title]
                )}
              </TableCell>
            </React.Fragment>
          ))}
        </TableRow>
      ) : null}
    </>
  );
};
