import { CircularProgress, Grid, IconButton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips/CustomTooltip';
import CopyOutlined from '../../../../assets/images/ClientConfirmation/CopyOutlined.svg';
import CopyOutlinedBlack from '../../../../assets/images/ClientConfirmation/CopyOutlinedBlack.svg';

const useStyles = makeStyles()({
  button: {
    '&:hover': {
      cursor: 'pointer',
    },
    '&.dissabled:hover': {
      cursor: 'not-allowed',
    }
  },
  disabled: {
    cursor: 'not-allowed',
    '& *': {
      cursor: 'not-allowed',
    },
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
});

export const CopyAction = ({
  previewMode,
  onCopy,
  hasDraft,
  previewIsloading,
  disablePreview = false,
  selectedDoc,
  tooltopMessage,
  shouldDissable,
  ...props
}) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  const handleCopy = React.useCallback(() => {
    onCopy(selectedDoc?.documentId, hasDraft);
  }, [onCopy]);

  return (
    <>
      <Grid item>
        {previewIsloading ? (
          <CircularProgress sx={{ color: theme => theme.palette.system.navyBlue, marginRight: 1 }} size={14} />
        ) : (
          <div className={cx({[classes.disabled]: shouldDissable && tooltopMessage})}>
            <CustomTooltip
             disabled={!tooltopMessage} 
             tooltipContent={ tooltopMessage }>
              <IconButton
                color="primary"
                size="small"
                disableFocusRipple
                disableTouchRipple
                disableRipple
                disabled={shouldDissable}
                sx={{ padding: 0 }}
              >
                <img
                  src={shouldDissable ? CopyOutlinedBlack : CopyOutlined}
                  className={cx(classes.button, {
                    [classes.disabled]: shouldDissable,
                  })}
                  onClick={handleCopy}
                  alt="Create a Copy"
                />
                
              </IconButton>
            </CustomTooltip>
          </div>
        )}
      </Grid>
    </>
  );
};
