import { XGrid } from '@AMIEWEB/Common';
import { GridTag } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { materialUiXGrid } from 'amn-ui-core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { orderUnitColumnsConfig } from './utils';
import { missingField } from 'app/constants';
import { DataGridProProps, GridRowScrollEndParams, GridSortModel } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { getAge } from '@AMIEWEB/Order/OrderDetails/helper';
import { getOrderDataForUnitsInFacility } from 'app/services/OrderServices/OrderServices';
import { httpSuccess } from 'app/services/serviceHelpers';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { useTranslation } from 'react-i18next';

export interface IOrderUnitFacility {
  items: IOrderUnitDetail[];
  paging: IGridPaging;
}

export interface ISelectedSubCert {
  skillSetIndex: number;
  subCerts: number[];
}

interface IGridPaging {
  pageSize: number;
  pageNumber: number;
  total: number;
  positions: number;
}

interface IOrderUnitDetail {
  id: number;
  orderId: number;
  shiftNotNegotiable: boolean;
  verified: string;
  dateEntered: string;
  maxFilesSent: boolean;
  maxFilesLastUpdated: string;
  noOfSubmissions: number;
  orderAge: number;
  noOfWeeks: number;
  yoe: number;
  positions: number;
  notes: string;
}

export interface IPostOrderUnitDetail {
  unitId: number;
  pageSize: number;
  pageNumber: number;
}

/**
 * Component to render order units grid for facility
 */
const FacilityOrderUnitGrid = ({ unitId }: { unitId: number }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const apiRef = materialUiXGrid.useGridApiRef();
  const [totalCount, setTotalCount] = useState<number>(0);
  const defaultSortModel: any[] = [{ field: 'orderId', sort: 'desc' }];
  const [sortModel, setSortModel] = React.useState<any>([{ field: 'orderId', sort: 'desc' }]);
  const [orderUnitColumns, setOrderUnitColumns] = useState<any>(orderUnitColumnsConfig());
  const [orderUnitRows, setOrderUnitRows] = useState([]);
  const [orderUnitData, setOrderUnitData] = useState([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  /**
   * Method to trigger on sort model change
   * @param page
   */
  const onSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
  };

  /**
   * Method to set skill set field value
   * @param skillSet
   */
  const setSkillSetFieldValue = (skillSet: [{ disciplineAbbr: string; specialty: string }]) => {
    if (skillSet?.length) {
      return skillSet.map(
        (skillSetItem, index) =>
          `${skillSetItem.disciplineAbbr} - ${skillSetItem.specialty} ${index === skillSet?.length - 1 ? '' : ','}`,
      );
    } else {
      return missingField;
    }
  };

  /**
   *
   * @param value
   */
  const validateYesOrNoForBoolean = (value: boolean) => {
    if (value === false) {
      return 'No';
    } else if (value === true) {
      return 'Yes';
    } else {
      return missingField;
    }
  };

  const validateShiftNotNegotiable = (value: number) => {
    if (value === 0) {
      return 'No';
    } else if (value === 1) {
      return 'Yes';
    } else {
      return missingField;
    }
  };

  /**
   * Method to set grid data for order unit grid
   */
  const setOrderUnitFacilityGridData = () => {
    const formattedRows = orderUnitData?.map((item, index: number) => ({
      ...item,
      id: index,
      orderId: item.orderId ?? missingField,
      shiftNotNegotiable: validateShiftNotNegotiable(item.shiftNotNegotiable),
      verified: item.dateVerified ? moment(item.dateVerified).format('MM/DD/YYYY hh:mm:ss A') : missingField,
      dateEntered: item.dateEntered ? moment(item.dateEntered).format('MM/DD/YYYY') : missingField,
      maxFilesSent: validateYesOrNoForBoolean(item.maxFilesSent),
      maxFilesLastUpdated: item.lastUpdatedMaxFilesSent ?? missingField,
      noOfSubmissions: item.numOfSubmissions ?? missingField,
      orderAge: item?.dateEntered ? getAge(item.dateEntered) : missingField,
      noOfWeeks: item.numOfWeeks ?? missingField,
      yoe: item.yearsOfExperience ?? missingField,
      positions: item.positions ?? missingField,
      notes: item.notes ?? missingField,
      allowScreen: item.allowScreen ?? missingField,
      skillSet: setSkillSetFieldValue(item?.skillsets),
    }));
    setOrderUnitRows(formattedRows ?? []);
  };

  /**
   * Fetching Order Unit data in the component and setting to local state for each grid row
   * @param payload
   */
  const fetchDataForOrderUnitGrid = async (payload: IPostOrderUnitDetail) => {
    setLoading(true);
    await getOrderDataForUnitsInFacility(payload)
      .then(result => {
        if (httpSuccess(result.status)) {
          setOrderUnitData(orderUnitData.concat(result?.data.items));
          setTotalCount(result?.data?.paging.total);
        } else {
          dispatch(
            globalActions.setSnackBar({
              message: t('unitTab.unitGrid.order.error', { unitId: payload.unitId }),
              severity: 'error',
            }),
          );
        }
      })
      .catch(err =>
        dispatch(
          globalActions.setSnackBar({
            message: t('unitTab.unitGrid.order.error', { unitId: payload.unitId }),
            severity: 'error',
          }),
        ),
      );

    setLoading(false);
  };

  /**
   * Method fired on initial detail panel load and last detail panel scroll position
   */
  const handleOnRowsScrollEnd: DataGridProProps['onRowsScrollEnd'] = (params: GridRowScrollEndParams) => {
    if (params.virtualRowsCount % 20 === 0) {
      const request = {
        unitId: unitId,
        pageSize: 20,
        pageNumber: Math.floor(params.virtualRowsCount / 20) + 1,
      };
      fetchDataForOrderUnitGrid(request);
    }
  };

  useEffect(() => {
    if (orderUnitData?.length) setOrderUnitFacilityGridData();
  }, [orderUnitData]);

  return (
    <XGrid
      sx={{ p: '24px' }}
      apiRef={apiRef}
      gridTag={GridTag.FacilityOrderUnitsGrid}
      loading={isLoading}
      title="Orders"
      columns={orderUnitColumns}
      rows={orderUnitRows}
      autoHeight={true}
      disableSelectionOnClick
      pagination={false}
      rowCount={totalCount}
      hideFooter={true}
      defaultSortModel={defaultSortModel}
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      toolbarProps={{
        refreshButton: true,
        resetButton: true,
        refreshGrid: false,
        resetColumns: () => setOrderUnitColumns(orderUnitColumnsConfig()),
      }}
      components={{
        NoRowsOverlay: () => {
          return (
            <div className="center" style={{ height: '100%' }}>
              {t('global.xgrid.noRecordsFound')}
            </div>
          );
        },
      }}
      onRowsScrollEnd={handleOnRowsScrollEnd}
    />
  );
};

export default FacilityOrderUnitGrid;
