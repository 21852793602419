import { missingField } from 'app/constants';
import { GetMomentOfDate } from 'utils/dates/moment';

export const processPassportPreCheckPreferences = (data, disciplines = [], specialities = []) => {
  const autoSubmissions = data?.autoSubmitPreference;
  const optIn = autoSubmissions?.optIn;

  const skillsets = mapSkillsetsToString(autoSubmissions?.disciplinesAndSpecialties, disciplines, specialities);
  const earliestStartDate = GetMomentOfDate(autoSubmissions?.earliestStartDate);
  const lastUpdateDate = GetMomentOfDate(data?.updateDate);

  const locations = mapLocationsToString(autoSubmissions?.locations);
  const surroundingAreas = !!autoSubmissions?.locationRadiusOptIn ? 'Yes' : 'No';
  const minimumPay = autoSubmissions?.minPayRate ? `$${autoSubmissions?.minPayRate}` : missingField;
  const earliestAvailabiltyDate = earliestStartDate.isValid()
    ? earliestStartDate.format('MM/DD/YYYY')?.toString()
    : missingField;
  const lastUpdatedDate = lastUpdateDate.isValid()
    ? lastUpdateDate?.format('MM/DD/YYYY hh:mm:ss a')?.toString()
    : missingField;
  const shiftTime = mapShiftsToString(autoSubmissions?.shifts?.slice(0, 3));
  const shiftLength = mapShiftsToString(autoSubmissions?.shifts?.slice(3));
  const processedData: IPassportPreCheckPreferenceData[] = optIn
    ? [
        {
          id: 1,
          skillsets: skillsets?.length > 0 ? skillsets : missingField,
          locations: locations?.length > 0 ? locations : missingField,
          surroundingAreas: surroundingAreas,
          minimumPay: minimumPay,
          earliestAvailabilty: earliestAvailabiltyDate,
          shiftTime: shiftTime?.length > 0 ? shiftTime : missingField,
          shiftLength: shiftLength?.length > 0 ? shiftLength : missingField,
          lastUpdated: lastUpdatedDate,
        },
      ]
    : [];
  return processedData;
};

const mapLocationsToString = (locs = []): string => {
  const stringsArray = locs?.map(loc => {
    let city = '';
    let state = '';

    if (loc?.cityInfo) {
      city = loc.cityInfo?.city || '';
      state = loc.cityInfo?.state || '';
    } else if (loc?.stateInfo) {
      state = loc.stateInfo?.state || '';
    }

    return city?.length > 0 ? `${city}, ${state}` : state;
  });

  return stringsArray.join(' / ');
};

const mapShiftsToString = (shifts = []): string => {
  let mapped = '';
  const anyTrue = shifts?.some(shift => shift?.isActive);
  if (!anyTrue) {
    mapped = shifts?.map(shift => shift?.type).join(' / ');
  } else {
    mapped = shifts
      ?.map(shift => (shift?.isActive ? shift?.type : ''))
      ?.filter(type => type !== '')
      ?.join(' / ');
  }
  return mapped;
};

const mapSkillsetsToString = (skillsets = [], disciplines = [], specialities = []): string => {
  const skillsetsArray = skillsets?.map(skillset => {
    const discId = skillset?.disciplineId;
    const specId = skillset?.specialtyId;
    const disciplineAbbr = disciplines?.find(discipline => discipline?.value == discId)?.name;
    const specialityAbbr = specialities?.find(speciality => speciality?.value == specId)?.name;

    return `${disciplineAbbr} - ${specialityAbbr}`;
  });

  return skillsetsArray.join(' / ');
};

export interface IPassportPreCheckPreferenceData {
  id: number;
  skillsets: string;
  locations: string;
  surroundingAreas: string;
  minimumPay: string;
  earliestAvailabilty: string;
  shiftTime: string;
  shiftLength: string;
  lastUpdated: string;
}
