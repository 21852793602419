import {
  Autocomplete,
  AutocompleteInputChangeReason,
  Box,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from 'amn-ui-core';
import React, { useMemo, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { makeStyles } from 'tss-react/mui';
import { ISelection } from 'app/ComponentLibrary/Filter/TypeAheadDropdown/CustomTypeAhead';
import { colors } from 'styles/styleVariables';
import { CustomTooltip } from '../Tooltips';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { throttle } from 'lodash';

const useStyles = makeStyles<{ removeGutters: boolean; hasHelperText: boolean; isContactSidePanel: boolean }>()(
  (theme, { removeGutters, hasHelperText, isContactSidePanel }) => ({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: 2,
    },
    autoComplete: {
      minWidth: isContactSidePanel ? 148 : 180,
    },
    popupIndicator: {
      background: 'none',
      marginRight: removeGutters ? -12 : undefined,
      '&:hover, &:focus': {
        background: 'none',
        color: `${colors.amnLightBlue} !important`,
      },
    },
    filled: {
      paddingLeft: removeGutters ? '0 !important' : undefined,
      backgroundColor: isContactSidePanel ? theme.palette.framework.system.backgroundLightGrey : theme.palette.framework.system.white,
      '&:hover, &.Mui-disabled': {
        backgroundColor: isContactSidePanel ? theme.palette.framework.system.backgroundLightGrey : theme.palette.framework.system.white,
      },
      '&.Mui-focused': {
        backgroundColor: isContactSidePanel ? theme.palette.framework.system.backgroundLightGrey : theme.palette.framework.system.white,
        '&:after': {
          borderBottom: `2px solid ${colors.amnLightBlue} !important`,
        },
      },
    },
    input: {
      padding: '0 !important',
      '&::placeholder': {
        fontStyle: 'italic',
      },
    },
    smallTextfield: {
      paddingTop: '0 !important',
    },
    helperText: {
      margin: 0,
    },
    btn: {
      padding: 0,
      paddingBottom: hasHelperText ? 24 : 4,
      '&:hover': {
        color: colors.amnLightBlue,
      },
    },
    tooltip: {
      whiteSpace: 'pre',
    },
    lockIcon: {
      color: colors.amnGrey,
    },
  }),
);

export const InlineDropdown = ({ ...props }) => {
  const [options, setOptions] = useState<ISelection[]>([]);
  const [trackerArea] = useState(`trackerArea-${props.name}`);

  const { promiseInProgress: loadingOptions } = usePromiseTracker({ area: trackerArea, delay: 0 });

  const fetchOptions = async request => {
    const response = await props.fetchOptions(request);
    setOptions(
      (response || []).map(opt => ({
        id: opt.value,
        name: opt.name,
        value: opt.value,
      })),
    );
  };

  const throttleService = useMemo(
    () => throttle(request => trackPromise(fetchOptions(request), trackerArea), 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleChange = (event, choice: ISelection | null) => props.onChange(choice);

  const onInputChange = (event, newInputValue: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'input' && props.fetchOptions) {
      setOptions([]);
      throttleService(newInputValue);
    }
  };

  const onOpen = () => {
    if (props.fetchOptions) throttleService('');
    else setOptions(props.options);
  };

  const helperText = useMemo(
    () =>
      props.isSaving ? (
        <CircularProgress size={15} style={{ marginTop: 4 }} />
      ) : props.error ? (
        props.error?.message
      ) : props.helperText === 'Saved' ? (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'row',
            gap: 4,
          }}
        >
          <CheckIcon fontSize="small" />
          <Typography color="success" component="span" style={{ position: 'relative', top: '2px' }}>
            {props.helperText}
          </Typography>
        </Box>
      ) : (
        props.helperText ?? null
      ),
    [props.isSaving, props.error, props.helperText],
  );

  const { cx, classes } = useStyles({
    removeGutters: props.removeGutters,
    hasHelperText: !!helperText,
    isContactSidePanel: props.isContactSidePanel,
  });

  return (
    <Box className={classes.root}>
      <Autocomplete
        popupIcon={<ExpandMoreOutlinedIcon />}
        clearIcon={null}
        disableClearable
        value={props.value}
        options={options}
        loading={loadingOptions}
        onChange={handleChange}
        disabled={props.disabled || props.disableForDataLoad}
        filterOptions={props.filterOptions ? props.filterOptions : props.fetchOptions && (opt => opt)}
        renderInput={params => (
          <TextField
            {...params}
            variant="filled"
            placeholder={props.placeholder}
            InputProps={{
              ...params.InputProps,
              classes: {
                root: cx(classes.filled, { ...props.inputRootClass }),
                sizeSmall: classes.smallTextfield,
                input: classes.input,
                ...props.inputClass,
              },
            }}
            size={props.size ?? 'small'}
            helperText={helperText}
            FormHelperTextProps={{ classes: { root: classes.helperText } }}
            error={!!props.error}
          />
        )}
        getOptionLabel={p => p.name}
        classes={{ root: classes.autoComplete, popupIndicator: classes.popupIndicator, option: props.noneOption }}
        onInputChange={onInputChange}
        onOpen={onOpen}
      />
      {props.disabled ? (
        <CustomTooltip
          tooltipContent={props.lockReason}
          placement="bottom-end"
          standardMargin
          isStandardContent
          classes={{ tooltip: classes.tooltip }}
        >
          <LockIcon classes={{ root: classes.lockIcon }} />
        </CustomTooltip>
      ) : props.isDirty ? (
        <>
          <IconButton
            color="primary"
            size="small"
            onClick={props.onApply}
            disableTouchRipple
            classes={{ root: classes.btn }}
          >
            <CheckIcon fontSize="small" />
          </IconButton>
          <IconButton
            color="primary"
            size="small"
            onClick={props.onCancel}
            disableTouchRipple
            classes={{ root: classes.btn }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      ) : null}
    </Box>
  );
};
