import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { ExceptionType } from 'app/enums/Common';
import { INotificationLog } from 'app/models/ActivityFeed/IActivityLogs';
import { IGetActivityFeedQuery } from 'app/models/ActivityFeed/IGetActivityFeed';
import { IUpdateActivityFeedReadStatusCommand } from 'app/models/ActivityFeed/IUpdateActivityFeedReadStatus';
import {
  ICueListForPlacement,
  ICueNote,
  IEditSubTask,
  IExternalTask,
  IGroupedList,
  ITask,
  ITaskGridBody,
  StatusUpdateModalVersion,
  TaskEntity,
  TaskStatusReason,
} from 'app/models/Tasks/Tasks';
import { httpSuccess } from 'app/services/serviceHelpers';
import {
  editTaskMetaData,
  getCategoryList,
  getComments,
  getTaskData,
  getTaskDetails,
  getTaskDetailsByCandidateId,
  getTaskDetailsByFacilityId,
  getTaskDetailsByOrderId,
  getTaskDetailsByPlacementId,
  getTaskGroupedList,
  saveComment,
  saveTaskMetaData,
  postNotification,
  updateTaskStatus,
  DeleteAttachments,
  saveExternalTaskMetaData,
  getExternalCategoryList,
  GetExternalTaskList,
  UpdateExternalTaskStatus,
  getExternalTaskDetails,
  getCueNoteRecipient,
  getCueNotesByPlacementId,
  createCueNote,
  updateExternalTask,
  getExternalTaskSubCategory,
  saveTaskComment,
} from 'app/services/TaskServices/TaskServices';
import { selectUser } from 'oidc/user.selectors';
import { trackPromise } from 'react-promise-tracker';
import { call, cancelled, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { globalSearchActions, totalCountErrorThreshold } from 'store/redux-store/global-search/slice';
import {
  GenerateAllInternalTaskDataObject,
  GenerateExternalTaskData,
  GenerateExternalTaskDataObject,
  GenerateInternalTaskDataObject,
} from '../CreateTask/FormatData';
import {
  formatEditExternalTask,
  formatExternalSubCategory,
  formatExternalTaskDetails,
  formatUpdateExternalTaskStatus,
} from '../SagaTransformers/formatExternalTaskData';
import {
  formatCommentList,
  formatSubTaskStatusArray,
  formatEditInternalTask,
  formatInternalGridData,
  formatInternalTaskDetails,
  formatSaveComment,
  formatSaveCurrentComment,
  formatTaskData,
  formatUpdateInternalStatus,
  formatCommentToTask,
  formatCurrentUserSubTaskStatusDetails,
  getTaskDetailCommentForPost,
} from '../SagaTransformers/formatInternalTaskData';
import { taskDetailsActions } from './Tasks.redux';
import {
  selectCueOptionsforPlacement,
  selectCurrentExternalTask,
  selectNewTaskAttachments,
  SelectedContainer,
  selectExternalTaskDetailsData,
  selectStatusAppliedAll,
  selectInternalTaskListData,
  selectInternalTaskDetailsData,
  selectRequestedAlertCategory,
} from './Tasks.selectors';
import _isEqual from 'lodash/isEqual';
import { selectFailedServices, selectGlobalBanner } from 'app/ApplicationRoot/Global.selectors';
import { checkRequiredFilters } from 'app/components/Tasks/TaskManagement/Transformers/helper';
import { selectGridState } from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import axios from 'axios';
import { selectSearchResults } from 'store/redux-store/global-search/selectors';
import { FailedServices } from 'app/ApplicationRoot/types';
import i18next from 'i18next';
import { handleBulkMarkRead, initNotifications, updateNotificationReadStatusSaga } from './business_saga';
import {
  findNotificationCategory,
  formatNotificationResponseWithUnreadStatus,
} from '@AMIEWEB/Notification/Tasks/Common_v2/utils';
import { NotificationService } from 'app/services/NotificationServices/notification-service';

//#region TrackPromise
export const TrackGetTaskDetails = (fn, ...args) => trackPromise(fn(...args), 'get-task-details');
const TrackGetComments = (fn, ...args) => trackPromise(fn(...args), 'get-task-comments');
const TrackSaveComment = (fn, ...args) => trackPromise(fn(...args), 'save-task-comment');
const TrackEditComment = (fn, ...args) => trackPromise(fn(...args), 'edit-task-comment');
export const TrackTaskSubmit = (fn, ...args) => trackPromise(fn(...args), 'new-task-submit');
const TrackExternalTaskSubmit = (fn, ...args) => trackPromise(fn(...args), 'new-external-task-submit');
const TrackEditSubmit = (fn, ...args) => trackPromise(fn(...args), 'edit-task-submit');
export const TrackTaskList = (fn, ...args) => trackPromise(fn(...args), 'get-task-list');
const TrackTaskGroupedList = (fn, ...args) => trackPromise(fn(...args), 'get-task-grouped-list');
const TrackCategoryList = (fn, ...args) => trackPromise(fn(...args), 'get-category-list');
const TrackExternalCategoryList = (fn, ...args) => trackPromise(fn(...args), 'get-external-category-list');
export const TrackUpdateTaskStatus = (fn, ...args) => trackPromise(fn(...args), 'update-task-status');
const TrackCreatedTaskNotification = (fn, ...args) => trackPromise(fn(...args), 'create-task-notification');
const TrackSubTaskCreationNotification = (fn, ...args) => trackPromise(fn(...args), 'create-subtask-notification');
const TrackTaskUpdateNotification = (fn, ...args) => trackPromise(fn(...args), 'update-task-notification');
const TrackCueNoteNotification = (fn, ...args) => trackPromise(fn(...args), 'create-Cue-Note-notification');
const TracktNotificationReadStatus = (fn, ...args) => trackPromise(fn(...args), 'send-notification-read');
const TracktGetActitvityFeedData = (fn, ...args) => trackPromise(fn(...args), 'get-activity-feed');
const TrackGetActitvityUnreadCount = (fn, ...args) => trackPromise(fn(...args), 'get-activity-feed-unread-count');
const TrackDeleteAttachments = (fn, ...args) => trackPromise(fn(...args), 'get-attachment-delete');
const TrackExternalTaskData = (fn, ...args) => trackPromise(fn(...args), 'get-external-task');
const TrackCueRecipientList = (fn, ...args) => trackPromise(fn(...args), 'get-cue-recipient-list');
const TrackCueNotesList = (fn, ...args) => trackPromise(fn(...args), 'get-cue-notes-list');
const TrackCueNotesListAlert = (fn, ...args) => trackPromise(fn(...args), 'get-cue-notes-alert');
const TrackCueNoteSubmit = (fn, ...args) => trackPromise(fn(...args), 'new-cue-note-submit');
const TrackExtenalEditSubmit = (fn, ...args) => trackPromise(fn(...args), 'edit-external-task-submit');
const TrackExtenalSubCategory = (fn, ...args) => trackPromise(fn(...args), 'get-external-task-sub-category');
const TrackSaveTaskComment = (fn, ...args) => trackPromise(fn(...args), 'save-task-detail-comment');

//#endregion

//#region InternalTask
export function* newTaskData(action: PayloadAction<ITask>) {
  try {
    const currentContainer = yield select(SelectedContainer);
    const tempTask = formatTaskData(action.payload);
    const attachments = yield select(selectNewTaskAttachments);
    const createTask = new FormData();
    createTask.append('createTask', JSON.stringify(tempTask));
    attachments && attachments.map(attachment => createTask.append('attachments', attachment ?? null));
    const response = yield call(TrackTaskSubmit, saveTaskMetaData, createTask);
    yield put(taskDetailsActions.setCreatedTaskData(action.payload));
    yield put(taskDetailsActions.setTaskDataId(response.data));
    yield put(taskDetailsActions.setCurrentContainer(currentContainer));
    if (response && httpSuccess(response.status)) {
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('notification.createTask.taskCreationSuccess'),
          severity: 'success',
        }),
      );
    } else {
      yield put(
        globalActions.setSnackBar({
          message: 'ERROR: Task is not created',
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'newTaskData',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
  yield put(taskDetailsActions.setTaskAttachments([]));
}

export function* requestInternalTaskDetails(action: PayloadAction<number>) {
  try {
    const taskCall = yield call(TrackGetTaskDetails, getTaskDetails, action.payload);
    // check for multiple assigned tos
    if (taskCall && httpSuccess(taskCall?.status)) {
      const task = taskCall.data;
      yield put(taskDetailsActions.setExistingTaskAttachments(task?.attachments));
      yield put(taskDetailsActions.setErrorObject({ GET_TASK_DETAILS: false }));
      const tempDetails = formatInternalTaskDetails(task);
      yield put(taskDetailsActions.setInternalTaskDetailsModal(tempDetails));
    } else {
      yield put(taskDetailsActions.setErrorObject({ GET_TASK_DETAILS: true }));
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('common.failedLoad'),
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestTaskDetails',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

export function* requestCommentList(action: PayloadAction<number>) {
  try {
    const commentsCall = yield call(TrackGetComments, getComments, action.payload);
    const userInfo = yield select(selectUser);
    if (commentsCall && httpSuccess(commentsCall?.status)) {
      const comments = commentsCall.data;
      yield put(taskDetailsActions.setErrorObject({ GET_TASK_COMMENTS: false }));
      const subTaskStatusList = formatSubTaskStatusArray(comments);
      const currentUserSubTaskStatusDetails = formatCurrentUserSubTaskStatusDetails(comments, userInfo?.userInfo);
      const tempComments = formatCommentList(comments, subTaskStatusList, currentUserSubTaskStatusDetails);
      yield put(taskDetailsActions.setTaskComments(tempComments));
    } else {
      yield put(taskDetailsActions.setErrorObject({ GET_TASK_COMMENTS: true }));
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('common.failedLoad'),
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestCommentList',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

export function* saveSubTask(action: PayloadAction<any>) {
  try {
    const newAttachments = yield select(selectNewTaskAttachments);
    const internalTaskList = yield select(selectInternalTaskListData);
    const comment = formatSaveComment(action.payload, newAttachments);
    const taskComment = new FormData();
    taskComment.append('createCommentRequest', JSON.stringify(comment));
    newAttachments?.map(attachment => taskComment.append('attachments', attachment ?? null));
    const commentsCall = yield call(TrackSaveComment, saveComment, taskComment);
    if (commentsCall && httpSuccess(commentsCall?.status)) {
      yield put(taskDetailsActions.setCommentsCreated(true));
      if (internalTaskList && internalTaskList?.length > 0) {
        yield put(taskDetailsActions.setTaskCount(comment?.originalTaskId));
      }
      const commentId = commentsCall.data;
      yield put(taskDetailsActions.setErrorObject({ SAVE_TASK_COMMENT: false }));
      const newComment = formatSaveCurrentComment(comment, commentId, action.payload);
      yield put(taskDetailsActions.setCurrentComments(newComment));
      yield put(taskDetailsActions.prependNewComments(newComment));
      yield put(taskDetailsActions.setTaskUpdatedId(newComment?.taskId));
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('notification.createTask.subTaskCreationSuccess'),
          severity: 'success',
        }),
      );
    } else {
      yield put(taskDetailsActions.setErrorObject({ SAVE_TASK_COMMENT: true }));
      yield put(
        globalActions.setSnackBar({
          message: 'ERROR: Failed to create subtask',
          severity: 'error',
        }),
      );
    }
    yield put(taskDetailsActions.setTaskAttachments([]));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'saveSubTask',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

export function* editSubTask(action: PayloadAction<IEditSubTask>) {
  try {
    const updatedSubTask = action.payload?.updatedSubTask;
    const initialSubTask = action.payload?.initialSubTask;
    const userInfo = yield select(selectUser);
    updatedSubTask.comment = updatedSubTask?.comment?.replace(/\r?\n/g, '\r\n');
    const commentsCall = yield call(TrackGetComments, getComments, updatedSubTask?.rootTaskId);
    const comments = commentsCall.data;
    if (comments) {
      const subTaskStatusList = formatSubTaskStatusArray(comments);
      const currentUserSubTaskStatusDetails = formatCurrentUserSubTaskStatusDetails(comments, userInfo?.userInfo);
      const availableComments = formatCommentList(comments, subTaskStatusList, currentUserSubTaskStatusDetails);
      const availableSubtask = availableComments?.find(comment => comment.taskId === updatedSubTask?.taskId);
      if (_isEqual(initialSubTask, availableSubtask)) {
        yield put(taskDetailsActions.setInternalTaskChangedFlag(undefined));
        const updateSubTask = formatCommentToTask(updatedSubTask);
        const response = yield call(TrackEditComment, editTaskMetaData, updateSubTask);
        if (response && httpSuccess(response?.status)) {
          yield put(taskDetailsActions.setTaskComments(availableComments));
          yield put(taskDetailsActions.setTaskUpdatedId(response?.data));
          yield put(
            globalActions.setSnackBar({
              message: i18next.t('notification.createTask.subTaskEditSuccess'),
              severity: 'success',
            }),
          );
        } else {
          yield put(taskDetailsActions.setTaskUpdatedId(-1));
          yield put(
            globalActions.setSnackBar({
              message: 'ERROR: Failed to update subtask',
              severity: 'error',
            }),
          );
        }
      }
    } else {
      yield put(taskDetailsActions.setTaskUpdatedId(-1));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'editSubTask',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

export function* editTaskAction(action: PayloadAction<any>) {
  try {
    const updateTask = action.payload?.updateTask;
    const initialTask = action.payload?.initialTask;
    updateTask.description = updateTask?.description?.replace(/\r?\n/g, '\r\n');
    const taskResponse = yield call(TrackGetTaskDetails, getTaskDetails, updateTask?.taskId);
    const latestData = GenerateInternalTaskDataObject(taskResponse?.data);
    const checkInitialTask = formatEditInternalTask(latestData, initialTask);
    if (checkInitialTask) {
      yield put(taskDetailsActions.setInternalTaskChangedFlag(undefined));
      const response = yield call(TrackEditSubmit, editTaskMetaData, updateTask);
      if (response && httpSuccess(response?.status)) {
        yield put(taskDetailsActions.setTaskUpdatedId(response?.data));
      } else {
        yield put(taskDetailsActions.setTaskUpdatedId(-1));
      }
    } else {
      yield put(taskDetailsActions.setInternalTaskChangedFlag(latestData));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'editTaskAction',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

export function* updateTaskStatusAction(action: PayloadAction<any>) {
  try {
    const updateStatus = action.payload?.update;
    const userInfo = yield select(selectUser);
    const taskResponse = yield call(TrackUpdateTaskStatus, getTaskDetails, updateStatus?.taskId);
    yield put(taskDetailsActions.setTaskStatusUpdateFlag(undefined));
    if (taskResponse && !httpSuccess(taskResponse?.status) && !taskResponse?.data) {
      yield put(taskDetailsActions.setTaskStatusUpdateFlag('ERROR'));
    } else {
      const data = taskResponse?.data;
      const coverageData = action.payload.coverageData;
      if (coverageData && coverageData?.decision) {
        formatUpdateInternalStatus(updateStatus, data, userInfo, coverageData);
      } else {
        formatUpdateInternalStatus(updateStatus, data, userInfo);
      }
      const statusAppliedAll = yield select(selectStatusAppliedAll);
      const updateStatusValue = { ...updateStatus };
      if (statusAppliedAll) {
        updateStatusValue.taskId = 0;
      }
      if (updateStatus?.statusUpdateReasonId === TaskStatusReason.ERROR) {
        updateStatus.taskId = 0;
      }
      const response = yield call(
        TrackUpdateTaskStatus,
        updateTaskStatus,
        statusAppliedAll ? updateStatusValue : updateStatus,
      );
      if (response && httpSuccess(response?.status)) {
        if (updateStatus?.statusId === 3 || updateStatus?.statusId === 4)
          yield put(taskDetailsActions.setVersionUpdated(StatusUpdateModalVersion.PARENT_TASK));
        yield put(
          taskDetailsActions.setTaskStatusData({
            ...data,
            statusId: updateStatus?.statusId,
            statusUpdatedByName: updateStatus?.statusUpdatedByName,
            statusUpdatedDate: updateStatus.statusUpdatedDate,
          }),
        );
        yield put(taskDetailsActions.setTaskStatusUpdateModalId(undefined));
        yield put(taskDetailsActions.setShowTaskStatusModal(undefined));
        yield put(taskDetailsActions.setTaskStatusUpdateFlag('SUCCESS'));
        yield put(taskDetailsActions.setStatusAppliedAll(false));
      } else {
        yield put(taskDetailsActions.setTaskStatusUpdateFlag('ERROR'));
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'updateTaskStatusAction',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

export function* updateSubtaskStatusAction(action: PayloadAction<any>) {
  try {
    const updateStatus = action.payload?.update;
    const userInfo = yield select(selectUser);
    const taskResponse = yield call(TrackUpdateTaskStatus, getTaskDetails, updateStatus?.taskId);
    yield put(taskDetailsActions.setTaskStatusUpdateFlag(undefined));
    if (taskResponse && httpSuccess(taskResponse?.status) && !taskResponse?.data) {
      yield put(taskDetailsActions.setTaskStatusUpdateFlag('ERROR'));
    } else {
      const data = taskResponse?.data;
      const coverageData = action.payload.coverageData;
      if (coverageData && coverageData?.decision) {
        formatUpdateInternalStatus(updateStatus, data, userInfo, coverageData);
      } else {
        formatUpdateInternalStatus(updateStatus, data, userInfo);
      }
      const statusAppliedAll = yield select(selectStatusAppliedAll);
      const updateStatusValue = { ...updateStatus };
      if (statusAppliedAll) {
        updateStatusValue.taskId = 0;
      }
      const response = yield call(
        TrackUpdateTaskStatus,
        updateTaskStatus,
        statusAppliedAll ? updateStatusValue : updateStatus,
      );
      if (response && httpSuccess(response?.status)) {
        if (updateStatus?.statusId === 3 || updateStatus?.statusId === 4)
          yield put(taskDetailsActions.setVersionUpdated(StatusUpdateModalVersion.SUBTASK));
        yield put(
          taskDetailsActions.setTaskStatusData({
            ...data,
            statusId: updateStatus?.statusId,
            statusUpdatedByName: updateStatus?.statusUpdatedByName,
            statusUpdatedDate: updateStatus.statusUpdatedDate,
          }),
        );
        yield put(taskDetailsActions.setTaskStatusUpdateModalId(undefined));
        yield put(taskDetailsActions.setShowTaskStatusModal(undefined));
        yield put(taskDetailsActions.setTaskStatusUpdateFlag('SUCCESS'));
        yield put(taskDetailsActions.setStatusAppliedAll(false));
      } else {
        yield put(taskDetailsActions.setTaskStatusUpdateFlag('ERROR'));
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'updateSubtaskStatusAction',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

export function* requestInternalTaskList(action: PayloadAction<any>) {
  try {
    const currentContainer = yield select(SelectedContainer);
    switch (currentContainer) {
      case TaskEntity.PLACEMENTS:
        const plData = yield call(TrackTaskList, getTaskDetailsByPlacementId, action.payload);
        const placementData = GenerateAllInternalTaskDataObject(plData.data);
        yield put(taskDetailsActions.setInternalTaskListData(placementData));
        break;
      case TaskEntity.ORDERS:
        const orData = yield call(TrackTaskList, getTaskDetailsByOrderId, action.payload);
        const orderData = GenerateAllInternalTaskDataObject(orData.data);
        yield put(taskDetailsActions.setInternalTaskListData(orderData));
        break;
      case TaskEntity.FACILITIES:
        const fcData = yield call(TrackTaskList, getTaskDetailsByFacilityId, action.payload);
        const facilityData = GenerateAllInternalTaskDataObject(fcData.data);
        yield put(taskDetailsActions.setInternalTaskListData(facilityData));
        break;
      case TaskEntity.CANDIDATES:
        const cdData = yield call(TrackTaskList, getTaskDetailsByCandidateId, action.payload);
        const candidateData = GenerateAllInternalTaskDataObject(cdData.data);
        yield put(taskDetailsActions.setInternalTaskListData(candidateData));
        break;
      default:
        break;
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestInternalTaskList',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

export function* deleteAttachment(action: PayloadAction<any>) {
  try {
    const response = yield call(TrackDeleteAttachments, DeleteAttachments, action.payload);
    yield put(taskDetailsActions.setAttachmentDeleted(response));
    if (response && httpSuccess(response?.status)) {
      if (response.data) {
        yield put(
          globalActions.setSnackBar({
            message: 'Attachment deleted successfully',
            severity: 'info',
          }),
        );
      } else {
        yield put(
          globalActions.setSnackBar({
            message: 'File Does not Exists',
            severity: 'error',
          }),
        );
      }
    } else {
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('common.failedLoad'),
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'deleteAttachment',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

export function* getCategoryListAction() {
  try {
    const response = yield call(TrackCategoryList, getCategoryList);
    yield put(taskDetailsActions.setCategorizedList(response));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getCategoryListAction',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}
//#endregion

//#region ExternalTask
export function* newExternalTaskData(action: PayloadAction<IExternalTask>) {
  try {
    const tempTask = {
      ...action.payload,
    };
    const response = yield call(TrackExternalTaskSubmit, saveExternalTaskMetaData, tempTask);
    if (response && httpSuccess(response?.status)) {
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('notification.createTask.taskCreationSuccess'),
          severity: 'success',
        }),
      );
      yield put(taskDetailsActions.setCreatedExternalTaskData(action.payload));
      yield put(taskDetailsActions.setExternalTaskDataId(response?.data));
    } else {
      yield put(
        globalActions.setSnackBar({
          message: 'The task already exists',
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'newExternalTaskData',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

export function* getExternalTaskList(action: PayloadAction<any>) {
  try {
    const response = yield call(TrackExternalTaskData, GetExternalTaskList, action.payload);
    const externalData = GenerateExternalTaskDataObject(response.data);
    yield put(taskDetailsActions.setExternalTaskList(externalData));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getExternalTaskList',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

export function* updateExternalTaskStatusAction(action: PayloadAction<any>) {
  try {
    const updateStatus = action.payload?.update;
    const initialStatus = action.payload?.initialStatus;
    const detailsBody = yield select(selectCurrentExternalTask);
    const taskResponse = yield call(TrackUpdateTaskStatus, getExternalTaskDetails, detailsBody);
    yield put(taskDetailsActions.setTaskStatusUpdateFlag(undefined));
    if (taskResponse && !httpSuccess(taskResponse?.status) && !taskResponse?.data) {
      yield put(taskDetailsActions.setTaskStatusUpdateFlag('ERROR'));
    } else {
      const data = taskResponse?.data;
      const userInfo = yield select(selectUser);
      if (data?.statusId === initialStatus) {
        formatUpdateExternalTaskStatus(updateStatus, detailsBody, userInfo);
        const response = yield call(TrackUpdateTaskStatus, UpdateExternalTaskStatus, updateStatus);
        if (response && httpSuccess(response?.status)) {
          yield put(
            taskDetailsActions.setTaskStatusData({
              ...data,
              statusId: updateStatus?.statusId,
              statusUpdatedByName: updateStatus?.statusUpdatedByName,
              statusUpdatedDate: updateStatus.statusUpdatedDate,
            }),
          );
          yield put(taskDetailsActions.setTaskStatusUpdateModalId(undefined));
          yield put(taskDetailsActions.setShowTaskStatusModal(undefined));
          yield put(taskDetailsActions.setTaskStatusUpdateFlag('SUCCESS'));
          const taskDetails = yield select(selectExternalTaskDetailsData);
          if (taskDetails) {
            yield put(
              taskDetailsActions.setExternalTaskDetailsModal({
                ...taskDetails,
                statusId: updateStatus?.statusId,
                taskCategory: updateStatus?.statusUpdateReason + ' • ' + taskDetails?.taskCategory,
                statusUpdatedByName: updateStatus?.statusUpdatedByName,
                statusUpdatedDate: updateStatus.statusUpdatedDate,
              }),
            );
          }
        } else {
          yield put(taskDetailsActions.setTaskStatusUpdateFlag('ERROR'));
        }
      } else {
        yield put(taskDetailsActions.setTaskStatusUpdateFlag('SHOWMODAL'));
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'updateExternalTaskStatusAction',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

export function* editExternalTaskAction(action: PayloadAction<any>) {
  try {
    const updateTask = action.payload?.updateTask;
    const initialTask = action.payload?.initialTask;
    const detailsBody = yield select(selectCurrentExternalTask);

    const taskResponse = yield call(TrackExtenalEditSubmit, getExternalTaskDetails, detailsBody);
    const latestData = GenerateExternalTaskData(taskResponse?.data);
    const checkInitialTaskData = formatEditExternalTask(latestData, initialTask);
    if (checkInitialTaskData) {
      yield put(taskDetailsActions.setExternalTaskChangedFlag(undefined));
      const response = yield call(TrackExtenalEditSubmit, updateExternalTask, updateTask);
      if (response && httpSuccess(response?.status)) {
        yield put(taskDetailsActions.setIsExternalTaskUpdated(true));
        yield put(taskDetailsActions.setTaskUpdatedId(response?.data));
        yield put(taskDetailsActions.setIsUpdatingTaskFlag(false));
      } else {
        yield put(taskDetailsActions.setIsExternalTaskUpdated(false));
        yield put(taskDetailsActions.setTaskUpdatedId(-1));
      }
    } else {
      yield put(taskDetailsActions.setExternalTaskChangedFlag(latestData));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'editExternalTaskAction',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

export function* requestExternalTaskDetails(action: PayloadAction<any>) {
  const failedService = yield select(selectFailedServices);
  yield put(
    globalActions.setFailedServices({
      failedServices: failedService.filter(f => f.type != FailedServices.ExternalTask),
    }),
  );
  try {
    const categoryList = yield call(TrackExternalCategoryList, getExternalCategoryList);
    const taskCall = yield call(TrackGetTaskDetails, getExternalTaskDetails, action.payload);

    // check for multiple assigned tos
    if (taskCall && httpSuccess(taskCall?.status)) {
      const task = taskCall.data;
      yield put(taskDetailsActions.setExistingTaskAttachments(task?.attachments));
      yield put(taskDetailsActions.setErrorObject({ GET_TASK_DETAILS: false }));
      const taskCategory = categoryList.data.find(c => {
        if (c.taskId === task.taskNameId && c.categoryId === task.taskCategoryId) {
          return c.title;
        } else {
          return '';
        }
      });

      const tempDetails = formatExternalTaskDetails(task, taskCategory);
      yield put(taskDetailsActions.setExternalTaskDetailsModal(tempDetails));
    } else {
      yield put(taskDetailsActions.setErrorObject({ GET_TASK_DETAILS: true }));
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('common.failedLoad'),
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    yield put(
      globalActions.setFailedServices({
        failedServices: [...failedService, { type: FailedServices.ExternalTask, payload: action.payload }],
      }),
    );
    yield put(globalActions.setCatastrophicDialog());
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestExternalTaskDetails',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

export function* getExternalCategoryListAction() {
  try {
    const response = yield call(TrackExternalCategoryList, getExternalCategoryList);
    yield put(taskDetailsActions.setExternalCategorizedList(response.data));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getExternalCategoryListAction',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

export function* getExternalTaskSubCategoryList(action: PayloadAction<number>) {
  try {
    const response = yield call(TrackExtenalSubCategory, getExternalTaskSubCategory, action.payload);
    const { subCategory1, subCategory2 } = formatExternalSubCategory(response);
    yield put(taskDetailsActions.setExternalTaskSubCategory1(subCategory1));
    yield put(taskDetailsActions.setExternalTaskSubCategory2(subCategory2));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getExternalTaskSubCategoryList',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

//#endregion

//#region Notification
export function* sendTaskCreatedNotification(action: PayloadAction<ITask>) {
  try {
    yield call(TrackCreatedTaskNotification, postNotification, action.payload);
    yield put(taskDetailsActions.setCreatedTaskData(undefined));
    yield put(taskDetailsActions.setTaskStatusData(undefined));
    yield put(taskDetailsActions.setTaskNotificationContainer(undefined));
    yield put(taskDetailsActions.setNotificationPrimaryContext(undefined));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'sendTaskCreatedNotification',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

export function* sendSubTaskCreatedNotification(action: PayloadAction<any>) {
  try {
    yield call(TrackSubTaskCreationNotification, postNotification, action.payload);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'sendSubTaskCreatedNotification',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

export function* sendTaskUpdatedNotification(action: PayloadAction<any>) {
  try {
    yield call(TrackTaskUpdateNotification, postNotification, action.payload);
    yield put(taskDetailsActions.setTaskStatusData(undefined));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'sendTaskUpdatedNotification',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

export function* sendCueNoteCreatedNotification(action: PayloadAction<any>) {
  try {
    yield call(TrackCueNoteNotification, postNotification, action.payload);
    yield put(taskDetailsActions.setTaskStatusData(undefined));
    yield put(taskDetailsActions.setIsCueNoteCreated(false));
    yield put(taskDetailsActions.setCueNoteCreatedData(undefined));
    yield put(taskDetailsActions.setCueNoteDataId(undefined));
    yield put(taskDetailsActions.setCueNoteReplyParent(undefined));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'sendCueNoteCreatedNotification',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

export function* sendNotificationReadStatus(action: PayloadAction<IUpdateActivityFeedReadStatusCommand>) {
  const notificationService: NotificationService = yield getContext('notificationService');

  try {
    yield call(TracktNotificationReadStatus, notificationService.UpdateNotificationReadStatus, action.payload);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'sendNotificationReadStatus',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

export function* getUnReadNotifications(action: PayloadAction<IGetActivityFeedQuery>) {
  const notificationService: NotificationService = yield getContext('notificationService');

  const user = yield select(selectUser);
  const failedService = yield select(selectFailedServices);
  yield put(
    globalActions.setFailedServices({
      failedServices: failedService.filter(f => f.type != FailedServices.ActivityFeed),
    }),
  );
  try {
    const response = yield call(TracktGetActitvityFeedData, notificationService.GetActivityFeed, action.payload);
    yield put(taskDetailsActions.setUnreadNotificationLog(response.data));

    if (action.payload?.setNotificationLogData) {
      //TODO: Need to add below logic in api response
      const formattedResponse = formatNotificationResponseWithUnreadStatus(response.data, user?.userInfo);
      yield put(taskDetailsActions.setNotificationLogData(formattedResponse as INotificationLog));
    }
  } catch (error) {
    yield put(
      globalActions.setFailedServices({
        failedServices: [...failedService, { type: FailedServices.ActivityFeed, payload: action.payload }],
      }),
    );
    yield put(globalActions.setCatastrophicDialog());
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getUnReadNotifications',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}
export function* getUnReadNotificationsCounts(action: PayloadAction<IGetActivityFeedQuery>) {
  const notificationService: NotificationService = yield getContext('notificationService');

  const failedService = yield select(selectFailedServices);
  yield put(
    globalActions.setFailedServices({
      failedServices: failedService.filter(f => f.type != FailedServices.ActivityFeedCount),
    }),
  );
  try {
    const response = yield call(TrackGetActitvityUnreadCount, notificationService.GetActivityFeedUnreadCounts, action.payload);
    if (!action.payload?.ignoreCountUpdation) {
      yield put(
        taskDetailsActions.setUnreadNotificationCount({
          unreadAlertNotifications:
            (response.data?.cueNoteCount || 0) + (response.data?.emailCount || 0),
          unreadOrderMatchNotifications: response.data?.orderMatchCount || 0,
          unreadTaskNotifications: response.data?.taskCount || 0,
        }),
      );
    }
  } catch (error) {
    yield put(
      globalActions.setFailedServices({
        failedServices: [...failedService, { type: FailedServices.ActivityFeedCount, payload: action.payload }],
      }),
    );
    yield put(globalActions.setCatastrophicDialog());
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getUnReadNotificationsCounts',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}
export function* getNotificationLogData(action: PayloadAction<IGetActivityFeedQuery>) {
  const notificationService: NotificationService = yield getContext('notificationService');

  const user = yield select(selectUser);
  const requestedAlertCategory = yield select(selectRequestedAlertCategory);
  const failedService = yield select(selectFailedServices);
  yield put(
    globalActions.setFailedServices({
      failedServices: failedService.filter(f => f.type != FailedServices.NotificationLog),
    }),
  );
  try {
    const response = yield call(TracktGetActitvityFeedData, notificationService.GetActivityFeed, action.payload);
    //TODO: Need to add below logic in api response
    const formattedResponse = formatNotificationResponseWithUnreadStatus(response.data, user?.userInfo);
    if (requestedAlertCategory) {
      //Ensure that only notification data corresponding to opened feed is added in redux
      const responseCategory = findNotificationCategory({
        requestPayload: action.payload,
      });
      if (responseCategory === requestedAlertCategory) {
        yield put(taskDetailsActions.setNotificationLogData(formattedResponse as INotificationLog));
      }
    } else {
      yield put(taskDetailsActions.setNotificationLogData(formattedResponse as INotificationLog));
    }
  } catch (error) {
    yield put(
      globalActions.setFailedServices({
        failedServices: [...failedService, { type: FailedServices.NotificationLog, payload: action.payload }],
      }),
    );
    yield put(globalActions.setCatastrophicDialog());
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getNotificationLogData',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

//#endregion

//#region CueNotes
export function* getCueNotesListByPlacementId(action: PayloadAction<ICueListForPlacement>) {
  try {
    const cueNoteList = yield call(
      action.payload?.alertUser ? TrackCueNotesListAlert : TrackCueNotesList,
      getCueNotesByPlacementId,
      action.payload,
    );
    yield put(taskDetailsActions.setCueNotesList(cueNoteList.data));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getCueNotesListByPlacementId',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

export function* getCueRecipientList(action: PayloadAction<any>) {
  try {
    const response = yield call(TrackCueRecipientList, getCueNoteRecipient, action.payload);
    if (response && httpSuccess(response?.status)) {
      yield put(taskDetailsActions.setCueRecipientList(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getCueRecipientList',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

export function* newCueNoteData(action: PayloadAction<ICueNote>) {
  try {
    const currentContainer = yield select(SelectedContainer);
    const tempCueNote = {
      ...action.payload,
      text: action.payload.text?.replace(/\r?\n/g, '\r\n'),
    };
    yield put(taskDetailsActions.setCreatedCueNoteData(action.payload));
    const placementCueOptions = yield select(selectCueOptionsforPlacement);
    const data = yield call(TrackCueNoteSubmit, createCueNote, tempCueNote);
    yield put(taskDetailsActions.setCueNoteDataId(data));
    yield put(taskDetailsActions.setCurrentContainer(currentContainer));
    if (data && data > 0) {
      yield put(taskDetailsActions.setIsCueNoteCreated(true));
      yield put(taskDetailsActions.getCueNotesForPlacements(placementCueOptions));
      yield put(globalSearchActions.setDrawerData({ open: false, data: undefined }));
    } else {
      yield put(
        globalActions.setSnackBar({
          message: 'CueNote Creation Failed',
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'newCueNoteData',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

//#endregion

//#region TaskManagement
export function* requestTaskGridList(action: PayloadAction<ITaskGridBody>) {
  const cancelTokenSource = axios.CancelToken.source();
  const failedService = yield select(selectFailedServices);
  yield put(
    globalActions.setFailedServices({ failedServices: failedService.filter(f => f.type != FailedServices.TaskGrid) }),
  );
  try {
    const globalBanner = yield select(selectGlobalBanner);
    const response = yield call(TrackTaskList, getTaskData, action.payload, cancelTokenSource.token);
    if (response) {
      const gridState = yield select(selectGridState);
      const { filterAttributes } = gridState;
      if (filterAttributes?.selectedChipFilters) {
        const requiredFiltersErrors = checkRequiredFilters(filterAttributes.selectedChipFilters);
        if (!requiredFiltersErrors) {
          const itemResponse = formatInternalGridData(response?.data?.items);
          response.data.items = itemResponse;
          if (response.data.paging.total > totalCountErrorThreshold) {
            yield put(
              globalActions.setBanner({
                message: action.payload.translation('search.globalSearch.exceedLimitBanner'),
                severity: 'warning',
                justify: 'center',
              }),
            );
          } else if (globalBanner.message === action.payload.translation('search.globalSearch.exceedLimitBanner')) {
            yield put(globalActions.closeBanner());
          }
          yield put(taskDetailsActions.setTaskGridData(response.data));
        } else {
          yield put(taskDetailsActions.setTaskGridData(null));
          yield put(
            globalActions.setBanner({
              ...requiredFiltersErrors,
              justify: 'start',
            }),
          );
        }
      }
    }
  } catch (error) {
    yield put(
      globalActions.setFailedServices({
        failedServices: [...failedService, { type: FailedServices.TaskGrid, payload: action.payload }],
      }),
    );
    yield put(globalActions.setCatastrophicDialog());
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestTaskGridList',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
  } finally {
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

export function* requestTaskGroupedList(action: PayloadAction<IGroupedList>) {
  try {
    const response = yield call(TrackTaskGroupedList, getTaskGroupedList, action.payload);
    if (response && httpSuccess(response?.status)) {
      const tempResponse = { ...response?.data, items: response?.data?.items?.map((d, idx) => ({ ...d, id: idx })) };
      yield put(taskDetailsActions.setTaskGroupedList(tempResponse));
    } else {
      yield put(
        taskDetailsActions.setTaskGroupedList({
          paging: undefined,
          items: [],
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestTaskGroupedList',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}

export function* saveTaskDetailComment(action: PayloadAction<string>) {
  try {
    const userInfo = yield select(selectUser);
    const { drawerData } = yield select(selectSearchResults);
    let taskId: number = drawerData?.data?.selectedorder?.row?.taskId || 0;
    let rootTaskId: number = drawerData?.data?.selectedorder?.row?.rootTaskId || 0;
    const taskDetails = yield select(selectInternalTaskDetailsData);
    if (taskDetails) {
      taskId = taskDetails?.taskId;
      rootTaskId = taskDetails.rootTaskId;
    }
    const commentPayload = getTaskDetailCommentForPost(userInfo.userInfo, taskId, rootTaskId, action.payload);
    const commentsCall = yield call(TrackSaveTaskComment, saveTaskComment, commentPayload);
    if (commentsCall && httpSuccess(commentsCall?.status)) {
      yield put(taskDetailsActions.setErrorObject({ SAVE_TASK_DETAIL_COMMENT: false }));

      if (drawerData?.data?.selectedorder?.row?.taskId === commentPayload.taskId)
        yield put(taskDetailsActions.setTaskUpdatedId(commentPayload?.taskId));
      else yield put(taskDetailsActions.setTaskCommentCreated(commentPayload));
    } else {
      yield put(taskDetailsActions.setErrorObject({ SAVE_TASK_DETAIL_COMMENT: true }));
      yield put(
        globalActions.setSnackBar({
          message: 'ERROR: Failed to add comment',
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    yield put(taskDetailsActions.setTaskUpdatedId(-1));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'saveTaskDetailComment',
        area: 'src/app/components/Tasks/store/Tasks.saga.ts',
      },
    });
    throw new Error('API Issue');
  }
}
//#endregion

export function* taskDetailsSaga() {
  yield takeLatest(taskDetailsActions.saveTaskAction.type, newTaskData);
  yield takeLatest(taskDetailsActions.saveExternalTaskAction.type, newExternalTaskData);
  yield takeLatest(taskDetailsActions.getTaskAction.type, requestInternalTaskDetails);
  yield takeLatest(taskDetailsActions.getTaskCommentsAction.type, requestCommentList);
  yield takeLatest(taskDetailsActions.saveSubTaskAction.type, saveSubTask);
  yield takeLatest(taskDetailsActions.editSubTaskAction.type, editSubTask);
  yield takeLatest(taskDetailsActions.editTaskAction.type, editTaskAction);
  yield takeLatest(taskDetailsActions.saveStatusTaskAction.type, updateTaskStatusAction);
  yield takeLatest(taskDetailsActions.getInternalTaskListAction.type, requestInternalTaskList);
  yield takeLatest(taskDetailsActions.updateSubtaskStatusAction.type, updateSubtaskStatusAction);
  yield takeLatest(taskDetailsActions.getTaskGroupedListAction.type, requestTaskGroupedList);
  yield takeLatest(taskDetailsActions.taskGridDataAction.type, requestTaskGridList);
  yield takeLatest(taskDetailsActions.getCategoryListAction.type, getCategoryListAction);
  yield takeLatest(taskDetailsActions.getExternalCategoryListAction.type, getExternalCategoryListAction);
  yield takeLatest(taskDetailsActions.sendTaskCreatedNotification.type, sendTaskCreatedNotification);
  yield takeLatest(taskDetailsActions.sendSubTaskCreatedNotification.type, sendSubTaskCreatedNotification);
  yield takeLatest(taskDetailsActions.sendTaskUpdatedNotification.type, sendTaskUpdatedNotification);
  yield takeLatest(taskDetailsActions.sendCueNoteCreatedNotification.type, sendCueNoteCreatedNotification);
  yield takeLatest(taskDetailsActions.sendNotificationReadStatus.type, sendNotificationReadStatus);
  yield takeLatest(taskDetailsActions.getUnReadNotifications.type, getUnReadNotifications);
  yield takeLatest(taskDetailsActions.getUnReadNotificationsCounts.type, getUnReadNotificationsCounts);
  yield takeLatest(taskDetailsActions.deleteAttachments.type, deleteAttachment);
  yield takeLatest(taskDetailsActions.getExternalTaskList.type, getExternalTaskList);
  yield takeLatest(taskDetailsActions.updateExternalTaskStatus.type, updateExternalTaskStatusAction);
  yield takeLatest(taskDetailsActions.getExternalTaskDetails.type, requestExternalTaskDetails);
  yield takeLatest(taskDetailsActions.getCueNoteRecipient.type, getCueRecipientList);
  yield takeLatest(taskDetailsActions.getCueNotesForPlacements.type, getCueNotesListByPlacementId);
  yield takeLatest(taskDetailsActions.saveCueNoteAction.type, newCueNoteData);
  yield takeLatest(taskDetailsActions.sendUpdateExternalTask.type, editExternalTaskAction);
  yield takeLatest(taskDetailsActions.getNotificationLogData.type, getNotificationLogData);
  yield takeLatest(taskDetailsActions.getExternalSubCategoryList.type, getExternalTaskSubCategoryList);
  yield takeLatest(taskDetailsActions.saveTaskCommentAction.type, saveTaskDetailComment);
  //#region Business Actions
  yield takeLatest(taskDetailsActions.businessInitNotifications.type, initNotifications);
  yield takeLatest(taskDetailsActions.businessUpdateNotificationReadStatusSaga.type, updateNotificationReadStatusSaga);
  yield takeLatest(taskDetailsActions.businessHandleBulkMarkRead.type, handleBulkMarkRead);
  //#endregion
}
