/* eslint-disable i18next/no-literal-string */
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePromiseTracker } from 'react-promise-tracker';
import { Expandable } from 'app/components/Common/Expandable/Expandable';
import { StatusTitle } from '../Titles/StatusTitle';
import { OrderInfoRow, OrderInfoTable } from './OrderInfoTable';
import { AddOnContext } from 'app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/PlacementSummaryTab';
import { selectPlacementSummary, selectServiceFailures } from 'store/redux-store/placement-summary/selectors';
import { TableCell as OrderInfoCell } from 'app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/CommonTableComponents/CommonTableComponents';
import { ErrorAction } from 'app/components/Common/ErrorPage/ErrorAction';
import { placementSummaryActions } from 'store/redux-store/placement-summary/slice';

import { OrderInfoDateItem } from '../OrderInformation/OrderInfoDateItem';
import { OrderInfoTypeItem } from '../OrderInformation/OrderInfoTypeItem';
import { OrderInfoRateItem } from '../OrderInformation/OrderInfoRateItem';
import { OrderInfoHoursItem } from '../OrderInformation/OrderInfoHoursItem';
import { OrderInfoScheduleItem } from '../OrderInformation/OrderInfoScheduleItem';
import { OrderInfoPayItem } from '../OrderInformation/OrderInfoPayItem';
import { OrderInfoPrioritiesItem } from '../OrderInformation/OrderInfoPrioritiesItem';
import { OrderInfoRequiredQualifications } from './OrderInfoRequiredQualifications';
import { OrderInfoPreferredQualifications } from './OrderInfoPreferredQualifications';
import { OrderInfoInternalNotes } from './OrderInfoInternalNotes';
import { useTranslation } from 'react-i18next';

export const OrderInformation = ({ ...props }) => {
  const { t } = useTranslation();
  const { placementId } = useContext(AddOnContext);
  const { orderInformation } = useSelector(selectPlacementSummary);
  const { orderInformationRequirement: serviceFailed } = useSelector(selectServiceFailures);
  const { promiseInProgress } = usePromiseTracker({ area: 'placement-order-info-requirement', delay: 0 });
  const dispatch = useDispatch();

    // Function to handle fetching data
  const handleExpand = React.useCallback((isExpanding) => {
      if (isExpanding && (orderInformation?.orderId ?? 0) <= 0) {
        dispatch(
          placementSummaryActions.getOrderInformationRequirement({ placementId, isRetryingService: true }),
        )
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dispatch, orderInformation]);

  return (
    <Expandable
      loading={promiseInProgress}
      titleComponent={<StatusTitle title={t('placement.profile.orderInformation.orderInformation.title')} />}
      onChange={handleExpand}
    >
      <OrderInfoTable>
        {serviceFailed && !promiseInProgress ? (
          <tr>
            <OrderInfoCell colSpan="2">
              <ErrorAction
                onTryAgain={() =>
                  dispatch(
                    placementSummaryActions.getOrderInformationRequirement({ placementId, isRetryingService: true }),
                  )
                }
              />
            </OrderInfoCell>
          </tr>
        ) : (
          <>
            <OrderInfoRow
              title={t('placement.profile.orderInformation.orderInformation.orderInfoRow.dates')}
              orderCell={<OrderInfoDateItem orderInformation={orderInformation} />}
            />
            <OrderInfoRow
              title={t('placement.profile.orderInformation.orderInformation.orderInfoRow.type')}
              orderCell={<OrderInfoTypeItem orderInformation={orderInformation} />}
            />
            <OrderInfoRow
              title={t('placement.profile.orderInformation.orderInformation.orderInfoRow.rate')}
              orderCell={<OrderInfoRateItem orderInformation={orderInformation} />}
            />
            <OrderInfoRow
              title={t('placement.profile.orderInformation.orderInformation.orderInfoRow.hours')}
              orderCell={<OrderInfoHoursItem orderInformation={orderInformation} />}
            />
            <OrderInfoRow
              title={t('placement.profile.orderInformation.orderInformation.orderInfoRow.schedule')}
              orderCell={<OrderInfoScheduleItem orderInformation={orderInformation} />}
            />
            <OrderInfoRow
              title={t('placement.profile.orderInformation.orderInformation.orderInfoRow.pay')}
              orderCell={<OrderInfoPayItem orderInformation={orderInformation} />}
            />
            <OrderInfoRow
              title={t('placement.profile.orderInformation.orderInformation.orderInfoRow.orderPriorities')}
              orderCell={<OrderInfoPrioritiesItem orderInformation={orderInformation} />}
            />
            <OrderInfoRow
              title={t('placement.profile.orderInformation.orderInformation.orderInfoRow.requiredQualifications')}
              orderCell={<OrderInfoRequiredQualifications orderInformation={orderInformation} />}
            />
            <OrderInfoRow
              title={t('placement.profile.orderInformation.orderInformation.orderInfoRow.preferredQualifications')}
              orderCell={<OrderInfoPreferredQualifications orderInformation={orderInformation} />}
            />
            <OrderInfoRow
              title={t('placement.profile.orderInformation.orderInformation.orderInfoRow.internalNotes')}
              orderCell={<OrderInfoInternalNotes orderInformation={orderInformation} />}
            />
          </>
        )}
      </OrderInfoTable>
    </Expandable>
  );
};
