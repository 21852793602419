import { formatCredentialCertificates } from '@AMIEWEB/Placement/PlacementDetails/helper';
import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { requirementType } from 'app/models/Placement/PlacementSummary';
import { CredentialingService } from 'app/services/CredentialingServices/credentialing-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { fork, getContext, put, call, select } from 'redux-saga/effects';
import { SetPlacementFailRiskRequirementMessage } from 'store/redux-store/new-placement/saga';
import InitPlacementSummary, { IConsolidatedRequirementResponse } from '../init.types';
import { expiringinMonthCount } from '../sagaHelper';
import { IPlacementSummaryRequest, placementSummaryActions } from '../slice';
import { trackPromise } from 'react-promise-tracker';
import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import { resetFailureStatus } from '../saga';

const TrackCandidateRecordsRightWrapper = (fn, ...args) => trackPromise(fn(...args), 'candidate-records-right');
const TrackCertificationOrderReqWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-certification-order-requirement');

export function* requestCertificationOrderRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('certificationOrderRequirement'));
    const placementOrderType = yield select(selectPlacementOrderType);
    const placementService: PlacementService = yield getContext('placementService');

    const response = yield call(
      TrackCertificationOrderReqWrapper,
      placementService.getPlacementSummaryCertification_OrderReq,
      action.payload.placementId,
      placementOrderType,
    );

    if (httpSuccess(response?.status)) {
      const { data } = response;
      const faildCertificationsCount = data.orderRequirement.data?.filter(x => x.type === requirementType.fail).length;

      const riskCertificationsCount =
        expiringinMonthCount(data.candidateRequirement.data.candidateCertResponse) +
        expiringinMonthCount(data.candidateRequirement.data.candidateCredCertListResponse);
      const credentialingService: CredentialingService = yield getContext('credentialingService');

      const dataLookups = yield call(
        TrackCandidateRecordsRightWrapper,
        credentialingService.getCredentialLookups,
        'all',
      );

      if (data.candidateRequirement.data.candidateCredCertListResponse && dataLookups?.data) {
        const formatedCredCerti = formatCredentialCertificates(
          data.candidateRequirement.data.candidateCredCertListResponse,
          dataLookups.data,
        );
        data.candidateRequirement.data.candidateCredCertListResponse = formatedCredCerti;
      }

      /** Logic to determine risk icons  */
      const isCandidateCertsExpiring = (data.candidateRequirement.data.candidateCertResponse || []).some(
        item => item.isExpired === false,
      );
      const isCredentialCertsExpiring = (data.candidateRequirement.data.candidateCredCertListResponse || []).some(
        item => item.isExpired === false,
      );

      const countOfCredentialsWithRisk = (data.candidateRequirement.data.candidateCredCertListResponse || []).filter(
        item => item.isExpired === false,
      ).count;

      yield put(
        placementSummaryActions.setSubmissionInfoByKey({
          key: 'certifications',
          isOrderReq: true,
          ...data,
          candidateRequirement: {
            ...data.candidateRequirement,
            data: {
              ...data.candidateRequirement.data,
              isCandidateCertsExpiring,
              isCredentialCertsExpiring,
              countOfCredentialsWithRisk,
            },
          },
        }),
      );

      if (!action.payload.isOrderGeneralRequirement) {
        //pass/fail
        if (faildCertificationsCount > 0) {
          yield fork(SetPlacementFailRiskRequirementMessage, {
            payload: {
              messageId: data?.candidateRequirement?.messageId,
              version: data?.candidateRequirement?.messageVersion,
              passFailType: requirementType.fail,
              data: faildCertificationsCount,
              messageTypeId: IPlacementRequirementKey.orderCertifications,
              tooltipTypeParam: undefined,
              nextStatusRequirementParam: data.nextStatusRequirement,
            },
            type: placementSummaryActions.setFailedRequirement.type,
          });
        }

        //risk
        yield fork(SetPlacementFailRiskRequirementMessage, {
          payload: {
            messageId: data?.candidateRequirement?.messageId,
            version: data?.candidateRequirement?.messageVersion,
            passFailType: data.candidateRequirement.data.type,
            data: riskCertificationsCount,
            messageTypeId: IPlacementRequirementKey.orderCertifications,
            tooltipTypeParam: undefined,
            nextStatusRequirementParam: data.nextStatusRequirement,
          },
          type: placementSummaryActions.setFailedRequirement.type,
        });
      }
    } else
      yield put(
        placementSummaryActions.setServiceFailureByKey({ key: 'certificationOrderRequirement', failureStatus: true }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestCertificationOrderRequirement',
        area: 'src/store/redux-store/placement-summary/common-saga/certificationOrderRequirement.ts',
      },
    });
  }
}

export function* processCertificationOrderRequirement(
  response: IConsolidatedRequirementResponse<any>,
  context: InitPlacementSummary,
) {
  try {
    if (!httpSuccess(response?.status)) {
      context.setServiceFailureByKey({ key: 'certificationOrderRequirement', failureStatus: true });
      return;
    }

    const { data } = response;
    const faildCertificationsCount = data.orderRequirement.data?.filter(x => x.type === requirementType.fail).length;

    const riskCertificationsCount =
      expiringinMonthCount(data.candidateRequirement.data.candidateCertResponse) +
      expiringinMonthCount(data.candidateRequirement.data.candidateCredCertListResponse);

    if (data.candidateRequirement.data.candidateCredCertListResponse && context.basePayload.credentialLookup) {
      const formatedCredCerti = formatCredentialCertificates(
        data.candidateRequirement.data.candidateCredCertListResponse,
        context.basePayload.credentialLookup,
      );
      data.candidateRequirement.data.candidateCredCertListResponse = formatedCredCerti;
    }

    /** Logic to determine risk icons  */
    const isCandidateCertsExpiring = (data.candidateRequirement.data.candidateCertResponse || []).some(
      item => item.isExpired === false,
    );
    const isCredentialCertsExpiring = (data.candidateRequirement.data.candidateCredCertListResponse || []).some(
      item => item.isExpired === false,
    );

    const countOfCredentialsWithRisk = (data.candidateRequirement.data.candidateCredCertListResponse || []).filter(
      item => item.isExpired === false,
    ).count;

    context.setSubmissionInfoByKey({
      key: 'certifications',
      isOrderReq: true,
      ...data,
      candidateRequirement: {
        ...data.candidateRequirement,
        data: {
          ...data.candidateRequirement.data,
          isCandidateCertsExpiring,
          isCredentialCertsExpiring,
          countOfCredentialsWithRisk,
        },
      },
    });

    //pass/fail
    if (faildCertificationsCount > 0) {
      yield fork(SetPlacementFailRiskRequirementMessage, {
        payload: {
          messageId: data?.candidateRequirement?.messageId,
          version: data?.candidateRequirement?.messageVersion,
          passFailType: requirementType.fail,
          data: faildCertificationsCount,
          messageTypeId: IPlacementRequirementKey.orderCertifications,
          tooltipTypeParam: undefined,
          nextStatusRequirementParam: data.nextStatusRequirement,
        },
        type: placementSummaryActions.setFailedRequirement.type,
      });
    }

    //risk
    yield fork(SetPlacementFailRiskRequirementMessage, {
      payload: {
        messageId: data?.candidateRequirement?.messageId,
        version: data?.candidateRequirement?.messageVersion,
        passFailType: data.candidateRequirement.data.type,
        data: riskCertificationsCount,
        messageTypeId: IPlacementRequirementKey.orderCertifications,
        tooltipTypeParam: undefined,
        nextStatusRequirementParam: data.nextStatusRequirement,
      },
      type: placementSummaryActions.setFailedRequirement.type,
    });
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'processCertificationOrderRequirement',
        area: 'src/store/redux-store/placement-summary/common-saga/certificationOrderRequirement.ts',
      },
    });
  }
}
