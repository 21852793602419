import { FilterCompTypes } from "app/ComponentLibrary/Filter/utils";

enum AutoTaskFilterNames {
    Status = "status"
}

const statusFilterOptions = [
    {
        id: 0,
        name: 'Active',
        value: 'Active'
    },
    {
        id: 0,
        name: 'Inactive',
        value: 'Inactive'
    }
]

export const GetAutoTaskFilters = (t: any) => [
    {
        filterName: t('Status'),
        name: AutoTaskFilterNames.Status,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        options: statusFilterOptions
    },
]

export const defaultAutoTaskFilters = {
    [AutoTaskFilterNames.Status]: statusFilterOptions,
}