import { GRID_DETAIL_PANEL_TOGGLE_FIELD, GridColDef } from '@mui/x-data-grid-pro';
import { VersionGridCell } from '../Common/GridCells/VersionIcon';
import statesJSON from 'assets/jsons/State.json';
import React from 'react';
import { DocumentGridCell } from '../Common/GridCells/DocumentGridCell';
import moment from 'moment';
import { Checkbox } from 'amn-ui-core';
import { CustomDetailPanelToggle } from '../Common/GridCells/CustomDetailToggle';

export const getMainTableColumns = (
    sortable: boolean = true,
    viewingDuplicates: boolean = false,
    historical: boolean = false,
    setClearChildMessages,
    setShowAttachmentPreview,
    setVersionOpen,
) => {

    // const getStateDescriptionFromId = (id: number) => {
    //   if (typeof id == 'number') {
    //     return statesJSON.states.find(e => e.StateNumber === id).Description;
    //   } else {
    //     return id;
    //   }
    // };

    const headers: GridColDef[] = [
        {
            field: 'detailPanelToggle', //GRID_DETAIL_PANEL_TOGGLE_FIELD,
            headerName: '',
            renderCell: (params) => (
                <CustomDetailPanelToggle
                    param={params}
                    setVersionOpen={setVersionOpen}
                />),
            flex: 20,
            align: 'center',
        },
        {
            field: 'credentialNameNoFormat',
            headerName: 'Credential Name',
            sortable: sortable,
            flex: 250,
        },
        {
            field: 'dateCompleted',
            headerName: 'Date Completed',
            sortable: sortable,
            valueFormatter: param => (param.value ? moment(param.value).format('MM/DD/YYYY') : 'N/A'),
            flex: 100,
        },
        {
            field: 'lastUpdate',
            headerName: 'Updated Date',
            sortable: sortable,
            flex: 100,
        },
        {
            field: 'versioning',
            headerName: 'Versions',
            renderCell: params =>
                <VersionGridCell
                    param={params}
                    setVersionOpen={setVersionOpen}
                />,
            align: 'center',
        },
        {
            field: 'documentIconHoverViewComponent',
            headerName: 'Document',
            flex: 100,
            renderCell: param => (
                <DocumentGridCell
                    param={param}
                    setClearChildMessages={setClearChildMessages}
                    setShowAttachmentPreview={setShowAttachmentPreview}
                    isSkillsChecklist={true}
                />
            ),
        },
        {
            field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
            hide: true,
        },
    ];
    return headers;
};

export const getExpandedGridTableColumns = () => {
    const headers: GridColDef[] = [
        {
            field: 'expirationDate',
            flex: 1,
            headerName: 'Expiration Date',
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: 'insertDate',
            flex: 1,
            headerName: 'Insert Date',
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: 'printed',
            flex: 1,
            headerName: 'Printed',
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: 'updatedBy',
            flex: 1,
            headerName: 'Updated By',
            sortable: false,
            disableColumnMenu: true
        },
    ];
    return headers;
};

export const getExpandedVersionColumns = (
    setClearChildMessages,
    setShowAttachmentPreview,
) => {
    const headers: GridColDef<any>[] = [
        {
            field: 'credentialNameNoFormat',
            headerName: 'Credential Name',
            sortable: false,
            disableColumnMenu: true,
            width: 600,
        },
        {
            field: 'dateCompleted',
            headerName: 'Date Completed',
            sortable: false,
            disableColumnMenu: true,
            valueFormatter: param => (param.value ? moment(param.value).format('MM/DD/YYYY') : 'N/A'),
            width: 120,
        },
        {
            field: 'lastUpdate',
            headerName: 'Updated Date',
            sortable: false,
            disableColumnMenu: true,
            width: 120,
        },
        {
            field: 'type',
            headerName: 'Type',
            sortable: false,
            disableColumnMenu: true,
            width: 100,
        },
        {
            field: 'expirationDate',
            headerName: 'Expiration Date',
            sortable: false,
            disableColumnMenu: true,
            width: 120,
        },
        {
            field: 'printed',
            headerName: 'Printed',
            sortable: false,
            disableColumnMenu: true,
            width: 100,
        },
        {
            field: 'updatedBy',
            headerName: 'Updated By',
            sortable: false,
            disableColumnMenu: true,
            width: 120,
        },
        {
            field: 'insertDate',
            headerName: 'Insert Date',
            sortable: false,
            disableColumnMenu: true,
            width: 120,
        },
        {
            field: 'documentIconHoverViewComponent',
            headerName: 'Document',
            disableColumnMenu: true,
            sortable: true,
            width: 120,
            renderCell: param => (
                <DocumentGridCell
                    param={param}
                    setClearChildMessages={setClearChildMessages}
                    setShowAttachmentPreview={setShowAttachmentPreview}
                    disableEdit={true}
                    isSkillsChecklist={true}
                />
            ),
        },
    ];
    return headers;
};
