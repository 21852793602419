import React from 'react';
import {
  TextField,
  Checkbox,
  ThemeProvider,
  StyledEngineProvider,
  Autocomplete,
  AutocompleteChangeReason,
  useTheme
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { Control, Controller } from 'react-hook-form';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ITypeAheadOption } from 'app/models/Orders/OrderDetails';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

interface IProps {
  name: string;
  label: string;
  register: any;
  control: Control<Record<string, any>>;
  options: ITypeAheadOption[];
  isError?: boolean;
  helperText?: string;
  onChange: (data?) => void;
  onFocus?: (data?) => void;
  defaultValue: ITypeAheadOption[];
  selectedvalue: ITypeAheadOption[];
  disabledValue?: string[];
  isdisabled?: boolean;
  id?: string;
}
const useStyles: any = makeStyles()((theme) => ({
  textareastyle: {
    backgroundColor: '#fff',
    border: 'thin #e7e2e2 solid',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
    },
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const ControlledTypeAheadWithCheckBox: React.FC<IProps> = props => {
  const { name, label, control, options, defaultValue, selectedvalue, disabledValue, isdisabled, id } = props;
  const { classes } = useStyles();
  const theme= useTheme();

  const handleChange = (event, choice, reason: AutocompleteChangeReason, details) => {
    if (selectedvalue && selectedvalue?.length > 0) {
      let found = false;
      const options = selectedvalue.reduce((store, x) => {
        if (x.label == details.option.label) found = true;
        else store.push(x.label);
        return store;
      }, []);
      if (!found) options.push(details.option.label);
      props.onChange(options);
    } else {
      props.onChange([details.option.label]);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ ref, value, onChange, ...rest }) => (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Autocomplete
              disablePortal
              multiple
              fullWidth
              options={options as any[]}
              disableCloseOnSelect
              getOptionLabel={option => option.label}
              defaultValue={defaultValue}
              disabled={isdisabled}
              value={selectedvalue}
              id={id}
              renderOption={(p, option, { selected }) => (
                <li id={option.label} {...p}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    color="primary"
                    checked={selectedvalue.filter(x => x.label == option.label)?.length > 0}
                    disabled={disabledValue && disabledValue.includes(option.label)}
                  />
                  {option.label}
                </li>
              )}
              popupIcon={<ExpandMoreOutlinedIcon />}
              renderTags={() => null}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="filled"
                  label={label}
                  disabled={isdisabled}
                  InputProps={{
                    ...params.InputProps,
                    className: classes.textareastyle,
                  }}
                  type="text"
                />
              )}
              onChange={handleChange}
              {...rest}
            />
          </ThemeProvider>
        </StyledEngineProvider>
      )}
    />
  );
};
