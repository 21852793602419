import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  IContractDetails,
  IBillRates,
  ICreateOrderOcbr,
  IOcbr,
  IOffContractRateElements,
  IRateDocument,
} from './types';
import { serviceActions } from './async-actions';
import { businessActions } from './business-actions';
import moment from 'moment';

export const initialState: ICreateOrderOcbr = {
  rateCardRows: [],
  docContainer: [],
  contractDetails: null,
  ocbrDetails: {
    effectiveDates: { startDate: moment().format(), endDate: moment().add(365, 'days').format() },
    billRates: [],
    isFlatRate: null,
  },
  ocbrDetailsSource: null,
  offContractRateElements: null,
  featureAttributes: {
    showLocalBillRate: false,
  },
  featureProps: { facilityId: 0, shifts: [], uniqueShifts: [], skillsets: [], isBeingEdited: false },
  miscellaneous: {
    disciplineOpts: [],
    specialtyOpts: [],
    subSpecialtyOpts: [],
    divisionDisciplineOpts: [],
  },
  rateOpts: {
    billRatesTable: [],
    gwwTable: [],
    mileageTable: [],
  },
  rateSelections: {},
  rateCardValidationError: false,
  ocbrSavedDetails: {
    effectiveDates: { startDate: moment().format(), endDate: moment().add(365, 'days').format() },
    billRates: [],
    isFlatRate: null,
  },
  orderDetailsUpdated: false,
};

const createOrderOcbrSlice = createSlice({
  name: 'createOrderOcbr',
  initialState,
  reducers: {
    setFeatureProps(
      state,
      action: PayloadAction<{
        facilityId: number;
        shifts: any[];
        uniqueShifts: any[];
        skillsets: any[];
        isBeingEdited: boolean;
      }>,
    ) {
      return { ...state, featureProps: { ...action.payload } };
    },
    setRateCardTableRows(state, action: PayloadAction<{ data: any[] }>) {
      return {
        ...state,
        rateCardRows: action.payload.data,
      };
    },
    setContractDetails(state, action: PayloadAction<IContractDetails>) {
      return {
        ...state,
        contractDetails: { ...action.payload },
      };
    },
    setOffContractRateElements(state, action: PayloadAction<IOffContractRateElements[]>) {
      return {
        ...state,
        offContractRateElements: [...action.payload],
      };
    },
    setDocContainer(state, action: PayloadAction<IRateDocument[]>) {
      return {
        ...state,
        docContainer: action.payload,
      };
    },
    resetDocContainer(state) {
      return { ...state, docContainer: [] };
    },
    resetRateCard(state) {
      return {
        ...state,
        rateCardRows: [],
        docContainer: [],
      };
    },
    softReset(state) {
      return { ...initialState, miscellaneous: { ...state.miscellaneous } };
    },
    reset() {
      return { ...initialState };
    },
    setOcbrDetailsSource(state, action: PayloadAction<IOcbr | null>) {
      return {
        ...state,
        ocbrDetailsSource: action.payload,
      };
    },
    setOcberBillRates(state, action: PayloadAction<IBillRates[]>) {
      state.ocbrDetails.billRates = action.payload;
    },
    setOcbrEffectiveDate(state, action: PayloadAction<{ startDate; endDate } | null>) {
      state.ocbrDetails.effectiveDates = action.payload;
    },
    setOcbrFlateRate(state, action: PayloadAction<boolean | null>) {
      state.ocbrDetails.isFlatRate = action.payload;
    },
    resetOcbrDetails(state) {
      state.ocbrDetails = {
        effectiveDates: null,
        billRates: [],
        isFlatRate: null,
      };
    },
    resetOcbrSavedDetails(state) {
      state.ocbrSavedDetails = {
        effectiveDates: null,
        billRates: [],
        isFlatRate: null,
      };
    },
    resetRateElements(state) {
      state.ocbrDetails.billRates = [];
    },
    resetSavedRateElements(state) {
      state.ocbrSavedDetails.billRates = [];
    },
    setFeatureAttributes(state, action: PayloadAction<{ key: 'showLocalBillRate'; data: any }>) {
      return {
        ...state,
        featureAttributes: { ...state.featureAttributes, [action.payload.key]: action.payload.data },
      };
    },
    setMiscellaneousData(
      state,
      action: PayloadAction<
        {
          key:
            | 'disciplineOpts'
            | 'specialtyOpts'
            | 'subSpecialtyOpts'
            | 'divisionDisciplineOpts'
            | 'skillSet'
            | 'shifts';
          data;
        }[]
      >,
    ) {
      const miscellaneous = action.payload.reduce((results, { key, data }) => ({ ...results, [key]: data }), {});
      return {
        ...state,
        miscellaneous: {
          ...state.miscellaneous,
          ...miscellaneous,
        },
      };
    },
    setBillRatesOpts(state, action) {
      return { ...state, rateOpts: { ...state.rateOpts, billRatesTable: action.payload } };
    },
    setGwwTableOpts(state, action) {
      return { ...state, rateOpts: { ...state.rateOpts, gwwTable: action.payload } };
    },
    setMileageTableOpts(state, action) {
      return { ...state, rateOpts: { ...state.rateOpts, mileageTable: action.payload } };
    },
    setBillRateSelection(state, action: PayloadAction<any>) {
      return {
        ...state,
        rateSelections: {
          ...state.rateSelections,
          ...action.payload,
        },
      };
    },
    resetBillRateSelection(state){
      state.rateSelections=initialState.rateSelections
    },
    setRateCardValidationError(state, action: PayloadAction<boolean>) {
      state.rateCardValidationError = action.payload;
    },
    setOcbrSavedBillRates(state, action: PayloadAction<IBillRates[]>) {
      state.ocbrSavedDetails.billRates = action.payload;
    },
    setOcbrSavedEffectiveDate(state, action: PayloadAction<{ startDate; endDate } | null>) {
      state.ocbrSavedDetails.effectiveDates = action.payload;
    },
    setOcbrSavedFlateRate(state, action: PayloadAction<boolean | null>) {
      state.ocbrSavedDetails.isFlatRate = action.payload;
    },
    resetSavedOcbrDetails(state) {
      state.ocbrSavedDetails = {
        effectiveDates: null,
        billRates: [],
        isFlatRate: null,
      };
    },
    setOrderDetailsUpdatedFlag(state, action: PayloadAction<boolean>){
      state.orderDetailsUpdated = action.payload;
    }
  },
});

export const { name: createOrderOcbrSliceKey, reducer: createOrderOcbrReducer } = createOrderOcbrSlice;

export const createOrderOcbrActions = { ...createOrderOcbrSlice.actions, ...serviceActions, ...businessActions };
