/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui';
import { CustomFilter } from 'app/ComponentLibrary/Filter';
import {
  pipelineChoiceKeys,
  pipelineChoiceActions,
} from 'app/components/Common/WorkDesk/PipelineChoice/pipelineChoice.redux';
import React, { useState, useEffect, createRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { defaultFilterChipSelected, expiringCredentialsSubStatus } from '../Models/ExpiringDocumentInterfaces';
import { credentialingDashboardExpiringActions } from '../Store/ExpiringCredential.redux';
import { selectFilterSpecs } from '../Store/ExpiringCredential.selector';

import { defaultFilterValues, getFilterSpecs } from './ExpiringFilterConfig';

const useStyles = makeStyles()({
  placementIdField: {
    minWidth: 150,
  },
  candidateNameField: {
    minWidth: 140,
  },
  credentialField: {
    minWidth: 134,
  },
  credentialGroupField: {
    minWidth: 150,
  },
  clientNameField: {
    minWidth: 140,
  },
  credentialCategoryField: {
    minWidth: 165,
  },
  candidateIdField: {
    minWidth: 140,
  },
});

export const ExpiringFilter = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const filterSpecs = useSelector(selectFilterSpecs);
  const [fields, setFields] = useState<any>(undefined);
  const dispatch = useDispatch();
  const hiddenSubmitButton = createRef<HTMLButtonElement>();
  const {
    setValue,
    formState: { dirtyFields },
  } = useFormContext();

  const resetFilter = () => {
    Object.entries(defaultFilterValues).map(item => setValue(item[0], item[1]));
    dispatch(credentialingDashboardExpiringActions.setFilterChipSelected(defaultFilterChipSelected));

    dispatch(
      pipelineChoiceActions.setPipelineChoice({
        key: pipelineChoiceKeys.CRED_EXPIRING_DAYS,
        selection: [{ category: 5, subcategory: null, value: expiringCredentialsSubStatus.allExpiring }],
      }),
    );

    props.onApplyFilter(defaultFilterValues, defaultFilterChipSelected);
  };

  useEffect(() => {
    if (filterSpecs) {
      setFields(getFilterSpecs(filterSpecs, t, classes));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSpecs]);

  const onFilterChangeValues = (fieldName, newValue) => {
    if (['expirationDate', 'daysUntilExpiration'].includes(fieldName)) {
      hiddenSubmitButton.current?.click();
    }
  };

  return (
    <>
      {filterSpecs && fields && (
        <CustomFilter
          fields={fields}
          onResetForm={() => resetFilter()}
          justifyContent={'flex-start'}
          version2
          falseProp={dirtyFields}
          onChangeValues={onFilterChangeValues}
          analytics={{
            caller: 'Credentialing WorkDesk Filter',
            onApply: true,
            properties: {
              category: 'Expiring Credentials',
            },
          }}
        />
      )}
      <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
    </>
  );
};
