import { ClickAwayListener, Grid, IconButton, InputAdornment, TextField, Autocomplete } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LockIcon from '@mui/icons-material/Lock';
import { UserRoleSearchResultItem } from 'app/components/Order/OrderDetails/OrderHeader/OrderHeader';
import { DisableAppScroll, EnableAppScroll } from 'app/layout/Layout';
import CloseIcon from '@mui/icons-material/Close';
import { DateRangePicker as DateRangeNew } from 'app/ComponentLibrary/DateRangeNew';
import { addDays } from 'date-fns';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { CustomTooltip } from 'app/components/Common/Tooltips';

const useStyles = makeStyles()(theme => ({
  autocomplete: {
    transition: 'opacity 0.25s',
    '&:hover': {
      '& [data-id="edit-icon"]': {
        opacity: 1,
      },
      transition: 'opacity 0.25s',
    },
  },
  inlineEditMode: {
    cursor: 'default',
    '&.editEnabled': {
      'border-bottom': '1px solid #000',
    },
    '& .MuiAutocomplete-input': {
      cursor: 'default',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: '0px',
    },
    '& .MuiOutlinedInput-root': {
      height: '28px',
      width: '139px',
      padding: '0px',
    },
    '& .MuiAutocomplete-input:first-child': {
      width: '70%',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#1a1919',
      backgroundColor: '#fff !important',
      padding: '0px',
    },
  },
  editItem: {
    opacity: 0.75,
    transition: 'opacity 0.15s',
    color: '#8d8686',
    cursor: 'pointer',
    position: 'relative',
    top: '1px',
    '&.enabled': {
      opacity: 1,
    },
    '& svg ': {
      height: '17px',
      width: '17px',
    },
  },
  textareastyle: {
    backgroundColor: '#fff !important',
    border: 'thin #ececec solid',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
    },
  },
  mticonstyle: {
    width: '17px',
    height: '17px',
    color: '#8d8686 !important',
  },
  root: {
    height: '28px',
    minWidth: '6.5rem',
    overflow: 'hidden',
    fontSize: 14,
    paddingRight: '0px !important',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      '&$popupIndicator': {
        opacity: 1,
        transition: 'opacity 0.25s',
      },
    },
    '&$focused': {
      backgroundColor: '#fff',
    },
  },
}));
export interface dependentPropsInterface {
  candidateDetails: any;
  fieldValue: any;
  enableEdit: boolean;
  fieldName: string;
  editableType: string;
  recuiterOptions: string[];
  autocompleteListLoadding: boolean;
  recuiterSearchList: UserRoleSearchResultItem[];
  enableInlineEditMode: (property: string) => void;
  handleChangeValue: (property: string, value: any) => void;
  handleOrderPropertyUpdate: (property: string, value: any) => void;
  handleClickAway?: () => void;
  onApplyDate?: (property: string, value: any) => void;
  isRecruiter?: boolean;
  disabled?: boolean;
}

export const CustomEditable = (props: dependentPropsInterface) => {
  const {
    fieldValue,
    enableEdit,
    fieldName,
    editableType,
    recuiterOptions,
    autocompleteListLoadding,
    recuiterSearchList,
    handleOrderPropertyUpdate,
    enableInlineEditMode,
    handleChangeValue,
    onApplyDate,
    isRecruiter,
    disabled,
  } = props;
  const { classes } = useStyles();
  const date = new Date();
  const { t } = useTranslation();

  const handleClose = event => {
    if (props.handleClickAway) {
      props.handleClickAway();
    }
  };

  const handleClick = event => {
    return null;
  };

  const renderSingleSelectFilter = editableType => {
    return (
      <>
        {editableType === 'date' ? (
          <DateRangeNew
            onChange={event => {
              enableInlineEditMode(fieldName);
              handleChangeValue(fieldName, event.startDate ? event.startDate : fieldValue);
              handleOrderPropertyUpdate(fieldName, event.startDate ? event.startDate : fieldValue);
              onApplyDate?.(fieldName, event.startDate ? moment(event.startDate).format('yyyy-MM-DD') : fieldValue);
            }}
            open={false}
            variant="outlined"
            size="inline"
            single={true}
            actionBar={false}
            clickAway
            // According to existing logic, this date picker should always be enabled unless user is read-only
            // TODO: Aston Martin Team - Review this logic if "enableEdit" should always be true ==> 2022-10-26.
            // If it's role-dependent, remove isDisabled prop and replace with enableEdit prop with "readOnly" as a condition
            // Look at "enableavailableOnStartEdit" and "enablecallbackToStartEdit" var in CandidateHeader.tsx
            //-------------------------------------------------------------------------------------------
            enableEdit
            isDisabled={disabled}
            //-------------------------------------------------------------------------------------------
            showIcon={false}
            isDateIconReq={true}
            maxWidth={140}
            initialDateRange={
              fieldValue
                ? {
                    startDate: new Date(fieldValue),
                    endDate: new Date(fieldValue),
                    label: moment(fieldValue).format('MM/DD/YYYY'),
                    useLabelAsValue: fieldValue || false,
                  }
                : undefined
            }
            definedRanges={[
              {
                label: '1 Day',
                startDate: addDays(date, -1),
                endDate: addDays(date, -1),
                useLabelAsValue: false,
              },
              {
                label: '2 Days',
                startDate: addDays(date, -2),
                endDate: addDays(date, -2),
                useLabelAsValue: false,
              },
              {
                label: '7 Days',
                startDate: addDays(date, -7),
                endDate: addDays(date, -7),
                useLabelAsValue: false,
              },
              {
                label: '20 Days',
                startDate: addDays(date, -20),
                endDate: addDays(date, -20),
                useLabelAsValue: false,
              },
              {
                label: '30 Days',
                startDate: addDays(date, -30),
                endDate: addDays(date, -30),
                useLabelAsValue: false,
              },
              {
                label: '60 Days',
                startDate: addDays(date, -60),
                endDate: addDays(date, -60),
                useLabelAsValue: false,
              },
            ]}
          />
        ) : editableType === 'dropDown' ? (
          <Autocomplete
            style={{ transform: 'translate(0px, -2px' }}
            fullWidth
            blurOnSelect
            clearOnBlur
            disablePortal
            disableClearable
            value={fieldName == 'recuiter' && !enableEdit ? fieldValue !== '' ? fieldValue : '--' : fieldValue}
            disabled={!enableEdit}
            popupIcon={null}
            options={recuiterOptions}
            loading={autocompleteListLoadding}
            loadingText={`Fetching Data`}
            id={`Order${fieldName}Lbl`}
            renderInput={params => (
              <TextField
                hiddenLabel
                variant="standard"
                error={fieldValue === ''}
                value={fieldValue}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  classes: classes,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        color="secondary"
                        onClick={() => {
                          enableInlineEditMode(fieldName);
                        }}
                        edge="end"
                        size="medium"
                      >
                        {!enableEdit &&
                          (isRecruiter ? (
                            <EditOutlinedIcon />
                          ) : (
                            <CustomTooltip tooltipContent={t('Cannot be edited')}>
                              <LockIcon className={classes.mticonstyle} />
                            </CustomTooltip>
                          ))}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={event => {
                  handleChangeValue(
                    fieldName,
                    fieldName == 'recuiter'
                      ? event.target.value
                      : event.target.value !== ''
                      ? event.target.value
                      : fieldValue,
                  );
                }}
              />
            )}
            onChange={(event, data) => {
              handleChangeValue(fieldName, data);
              const selectedUser = recuiterSearchList.filter(x => x.name === data)[0];
              handleOrderPropertyUpdate(fieldName, selectedUser);
            }}
            onOpen={() => {
              DisableAppScroll();
            }}
            onClose={EnableAppScroll}
          />
        ) : (
          <TextField
            fullWidth
            variant="filled"
            InputProps={{
              className: classes.textareastyle,
              disableUnderline: true,
              classes: classes,
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      enableInlineEditMode(fieldName);
                    }}
                    edge="end"
                    size="small"
                  >
                    {enableEdit ? <CloseIcon /> : <EditOutlinedIcon color="primary" />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              className: ``,
            }}
            value={fieldValue}
            defaultValue={''}
            hiddenLabel
            onChange={event => {
              handleChangeValue(fieldName, event.target.value);
              handleOrderPropertyUpdate(fieldName, event.target.value);
            }}
            error={fieldValue === ''}
            type={'string'}
            {...props}
            disabled={!enableEdit}
          />
        )}
      </>
    );
  };

  return (
    <>
      <ClickAwayListener onClickAway={enableEdit ? handleClose : handleClick}>
        <Grid
          container
          direction="row"
          className={classes.autocomplete}
          style={{ borderBottom: `${fieldValue === '' && !(fieldName === 'recuiter') ? '2px solid red' : ''}` }}
        >
          <Grid item xs={11} className={`${classes.inlineEditMode} ${enableEdit ? 'editEnabled' : ''}`}>
            {renderSingleSelectFilter(editableType)}
          </Grid>
        </Grid>
      </ClickAwayListener>
    </>
  );
};
