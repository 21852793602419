import { Box, Divider, Grid, Skeleton } from 'amn-ui-core';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React from 'react';
import { Title } from '../../../ProfileTab/Cards/styles';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips';
import { RowSection } from '../../Common/RowSection';
import { usePromiseTracker } from 'react-promise-tracker';
import { CandidateNavigation } from '../../CardNavigation';
import { useTranslation } from 'react-i18next';
import { useStyles } from './utils';
import { theme } from 'styles/global-styles';

function VirtualInterviewUI({ skillsetsList, handleNavigationToVirtualInterview, checkAccessPermission, navigate }) {
  const { promiseInProgress } = usePromiseTracker({ area: 'get-candidate-summary-tab-details', delay: 0 });
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <>
      <LayoutGridItem style={{ width: '100%' }}>
        <Box className={classes.container}>
          <Box className={classes.children}>
            <LayoutGrid direction="column" spacing={1}>
              <Title className={classes.title} style={{ width: '100%' }}>
                {t('candidate.virtualInterviewCard.title')}
                <span
                  className={skillsetsList?.length > 0 ? classes.profileInlineLink : classes.profileInlineLinkDisabled}
                  onClick={handleNavigationToVirtualInterview}
                >
                  {t('candidate.virtualInterviewCard.all')}
                </span>
              </Title>
              <span className={classes.sectionDivider}>&nbsp;</span>
              <Divider style={{ width: '100%' }} />
              <LayoutGrid
                direction="row"
                spacing={1}
                justifyContent="space-between"
                wrap="nowrap"
                className={classes.content}
              >
                {promiseInProgress ? (
                  <LayoutGridItem mr={2}>
                    <Skeleton />
                    <Skeleton />
                  </LayoutGridItem>
                ) : (
                  <LayoutGridItem style={{ paddingTop: '8px' }}>
                    <LayoutGrid direction="column" spacing={1} className={classes.rowItem}>
                      {skillsetsList?.length > 0 ? (
                        skillsetsList?.map((item, index) => {
                          return (
                            <LayoutGridItem key={index}>
                              <CustomTooltip
                                style={{ color: theme.palette.framework.system.tertiaryGrey }}
                                interactive={true}
                                tooltipContent={item?.toolTipMessage}
                                key={index}
                              >
                                <RowSection
                                  header={item.skillset}
                                  data={''}
                                  preData={item.interviewStatus}
                                  color={theme.palette.system.darkGreenForIcon}
                                  showAlert={false}
                                  showHalfwayProgress={item.interviewStatus ? false : true}
                                  showHalfwayProgressSecondaryIcon={item.interviewStatus ? false : true}
                                />
                              </CustomTooltip>
                            </LayoutGridItem>
                          );
                        })
                      ) : (
                        <RowSection
                          header={
                            <Grid container direction="column">
                              <Grid item>{t('candidate.virtualInterviewCard.interviewUnavailableLine1')}</Grid>
                              <Grid item>
                                <span>{t('candidate.virtualInterviewCard.interviewUnavailableLine2')}</span>
                                {checkAccessPermission() && <span>{t('candidate.virtualInterviewCard.youCan')}</span>}
                              </Grid>
                              {checkAccessPermission() && (
                                <Grid item>
                                  <span
                                    className={classes.inlineLink}
                                    onClick={() => navigate(CandidateNavigation.VirtualInterview)}
                                  >
                                    {t('candidate.virtualInterviewCard.addInterview')}
                                  </span>
                                </Grid>
                              )}
                            </Grid>
                          }
                          data={''}
                          preData={''}
                          color={theme.palette.framework.system.navyBlue}
                          showHalfwayProgress={true}
                          showHalfwayProgressSecondaryIcon={true}
                          showAlert={false}
                        />
                      )}
                    </LayoutGrid>
                  </LayoutGridItem>
                )}
              </LayoutGrid>
            </LayoutGrid>
          </Box>
        </Box>
      </LayoutGridItem>
    </>
  );
}

export default VirtualInterviewUI;
