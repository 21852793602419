import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState, initialSubmissionPrefState } from './utils';

const selectDomain = (state: RootState) => state.orderPreferences || initialState;

export const selectSubmissionPreferences = createSelector(
  [selectDomain],
  preferences => preferences.submissionPreferences || initialSubmissionPrefState,
);

export const selectSubmissionDistributionListLength = createSelector(
  [selectDomain],
  preferences => preferences.submissionPreferences?.distributionList?.length,
);

export const selectAutomationStatus = createSelector(
  [selectDomain],
  preferences => preferences.submissionPreferences.rfoToSent,
);

export const selectUpdateStatus = createSelector([selectDomain], preferences => preferences.updateInprogress);

export const selectOrderPreferenceAlerts = createSelector([selectDomain], data => data.alert);

export const selectAutomationStatusInProgress = createSelector([selectDomain], data => data.automationStatusInProgress);

export const selectOrderDistributionList = createSelector(
  [selectDomain],
  data => data.submissionPreferences.distributionList,
);

export const selectClientContacts = createSelector(
  [selectDomain],
  data => data.clientContacts,
)

export const selectMiscellaneousDate = createSelector(
  [selectDomain],
  data => data.miscellaneous,
)

export const selectPreviewTemplate = createSelector(
  [selectDomain],
  data => data.isPreview,
)
