import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';

export enum ChipStyle {
  Green = 'green',
  Blue = 'blue',
  Red = 'red',
  Grey = 'grey',
  Yellow = 'yellow',
  Null = 'null',
}

export const getChipStyle = customSwitch({
  [ChipStyle.Green]: { backgroundColor: '#E9F9EA', color: '#5FB661' },
  [ChipStyle.Blue]: { backgroundColor: '#E5E2F0', color: '#52429A' },
  [ChipStyle.Red]: { backgroundColor: '#FAE5E7', color: '#CC0000' },
  [ChipStyle.Grey]: { backgroundColor: '#F0F0F0', color: '#666666' },
  [ChipStyle.Yellow]: { backgroundColor: '#FFF4E5', color: '#FC9508' },
})({});

const useStyles = makeStyles<{ 
    backgroundColor: string; color: string 
}>()((theme, { backgroundColor, color }) => ({
    chip: {
      backgroundColor,
      color,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'fit-content',
      padding: '6px 12px',
      whiteSpace: 'pre',
      fontWeight: 500,
      fontSize: 12,
      borderRadius: 7,
      opacity: 1,
    },
  }));

export const StatusChip = ({ status, chipStyle }) => {
  const { classes } = useStyles({ ...chipStyle });

  return (
    <div className={classes.chip}>
      {status}
    </div>
  );
}