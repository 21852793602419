import React from 'react';
import {  mockCounts,  } from './mock';
import data from 'app/services/PlacementServices/__mocks__/PlacementSkillsetRequirement.json';
import { deriveNodeContainer } from './utils';
import { RequirementsContainer } from '../Statuses/RequirementsContainer';
import { IRequirementTreeFilter, RequirementFilterKey, requirementType } from 'app/models/Placement/PlacementSummary';
import { IOperationContainer, instanceOfIOperationContainer } from './types';
import { deriveFilter } from '../Helpers/requirementFilter';

const submissionSelections: IRequirementTreeFilter = {
  key: 'submission',
  filter: [],
};
//
export const deriveInitFilter = (
  initFilter: IRequirementTreeFilter,
  state: IOperationContainer,
): IRequirementTreeFilter => ({
  ...initFilter,
  children: [
    ...(initFilter.children ?? []),
    ...state.nodes.reduce((results, x) => {
      if (x.excludeFromFilter) return results;
      const initFilterNode = { key: x.key, filter: [] };
      const filterNode = instanceOfIOperationContainer(x) ? deriveInitFilter(initFilterNode, x) : initFilterNode;
      return [...results, filterNode];
    }, []),
  ],
});

export const TreeContainer = () => {
  /** API response */
  const [containerState] = React.useState(data.containers);

  const [requirementFilter, setFilter] = React.useState(() => deriveInitFilter(submissionSelections, containerState));

  console.log('@test - requirementFilter', requirementFilter);

  const handleFilterChange = (key: RequirementFilterKey, state: requirementType[]) => {
    if (requirementFilter) {
      var filterTree = deriveFilter(key, requirementFilter, state);
      // cleanChildFilterByKey('submission-amn', filterTree, amnCounts);
      // cleanChildFilterByKey('submission-order', filterTree, orderCounts);
      setFilter(filterTree);
    }
  };

  return (
    <RequirementsContainer
      title={'Submission'}
      counts={mockCounts}
      filter={requirementFilter.filter}
      handleFilterChange={newFilter => handleFilterChange('submission', newFilter)}
    >
      {deriveNodeContainer(containerState, requirementFilter, handleFilterChange)}
    </RequirementsContainer>
  );
};

export const SkillsetRequirements = ({ skillContainers, requirementFilter, handleFilterChange }) => {
  return skillContainers === null ? null : deriveNodeContainer(skillContainers, requirementFilter, handleFilterChange);
};
