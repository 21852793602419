import React, { useEffect, useRef, useState } from "react";
import { DateRangePicker } from 'app/ComponentLibrary/DateRangeNew';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from "react-i18next";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FormInputDropdown } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/PersonalInfoTab/Form/Controls/FormInputDropdown';
import { useReadOnly } from "oidc/userRoles";
import { Button, Checkbox, FormControlLabel, Grid, TextField } from "amn-ui-core";
import _cloneDeep from 'lodash/cloneDeep';
import { selectUser } from "oidc/user.selectors";
import { useDispatch, useSelector } from "react-redux";
import { ICoverageRequest } from "app/models/Notification/Notification";
import { coverageActions } from "store/redux-store/user-coverage/async-actions";

enum RangePickerAction {
    onChange,
    apply,
    cancel,
}

const useStyles = makeStyles()(theme => ({
    subContainer: {

    },
    field: {
        marginBottom: '12px',
        display: 'grid',
    },
    dateRangePicker: {
        width: '100%',
        maxWidth: '400px',
        display: 'flex',
        height: '50px',
    },
    buttonGroup: {
        display: 'block',
    },
    buttons: {
        justifyContent: 'flex-end',
        fontSize: '12px',
    },
    cancelBtn: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        color: '#333333',
        '&:hover': {
            backgroundColor: '#ededed',
            boxShadow: 'none',
        },
    },
    submitBtn: {
        boxShadow: 'none',
        '& :disabled': {

        },
        '&:hover': {
            boxShadow: 'none',
        },
    }
}));
export const RequestCoverage = ({ expanded }: { expanded: boolean }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { readOnly } = useReadOnly();
    const { userInfo } = useSelector(selectUser);
    const { classes } = useStyles();
    const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<{ startDate: Date; endDate: Date }>({ startDate: null, endDate: null });
    const dateRangePreviousRef = useRef(dateRange);
    const [urgentChecked, setUrgentChecked] = useState<boolean>(false);
    const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
    const [initialRange, setInitialRange] = useState<any>({ startDate: null, endDate: null });
    const [notes, setNotes] = useState<string>();
    const requestCoverageVar = {
        dateRange: 'dateRange',
        coveragePreferences: 'coveragePreferences',
        notes: 'notes',
        urgent: 'urgent',
    };
    const formMethods = useForm({
        mode: 'onSubmit',
    });
    const {
        handleSubmit,
        trigger,
        setValue,
        setError,
        control,
        clearErrors,
        register,
        formState: { isDirty, errors },
    } = formMethods;

    const handleDateRange = (selectedRange) => {
        //Hold Preious date range picker value
        dateRangePreviousRef.current = { startDate: dateRange.startDate, endDate: dateRange.endDate };

        if (selectedRange?.startDate && selectedRange?.endDate) {
            setDateRange({ startDate: selectedRange?.startDate, endDate: selectedRange?.endDate });
            clearErrors(requestCoverageVar.dateRange);
            setDisableSubmit(false);
        } else {
            setDateRange({ startDate: null, endDate: null });
            setError(requestCoverageVar.dateRange, { type: 'required' });
            setDisableSubmit(true);
        }
        setOpenDatePicker(false);
    }

    const handleUrgentToggle = () => {
        setUrgentChecked(prev => !prev)
    };

    const handleCancel = () => {
        clearErrors();
        setDateRange({ startDate: undefined, endDate: undefined });
        setDisableSubmit(true);
        setInitialRange({ startDate: undefined, endDate: undefined });
        setUrgentChecked(false);
    };

    const saveRequest = (formData) => {
        const coverageRequest = _cloneDeep(formData) as ICoverageRequest;
        coverageRequest.requestedBy = {
            firstName: userInfo.firstName, lastName: userInfo.lastName, id: userInfo.employeeId
        }
        coverageRequest.preferences = 3;
        dispatch(coverageActions.saveCoverageRequestAction(coverageRequest));
    };

    useEffect(() => {
        if (dateRange) {
            setValue(requestCoverageVar.dateRange, { startDate: dateRange.startDate, endDate: dateRange.endDate });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange]);

    useEffect(() => {
        !expanded && handleCancel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded]);

    return (
        <div className={classes.subContainer}>
            {expanded && (
                <FormProvider {...formMethods}>
                    <div className={classes.field}>
                        <Controller
                            control={control}
                            name={'dateRange'}
                            rules={{ required: true }}
                            render={({ ref, onChange, ...rest }) => (
                                <DateRangePicker
                                    className={classes.dateRangePicker}
                                    isDisabled={readOnly}
                                    actionBar={false}
                                    open={openDatePicker}
                                    onChange={val => handleDateRange(val)}
                                    value={dateRange ?? ''}
                                    variant="filled"
                                    required={true}
                                    placeholder={t('notification.profileDrawer.requestCoverage.datePicker')}
                                    clickAway={true}
                                    keepClickAwayVal={true}
                                    initialDateRange={initialRange}
                                    maxWidth={400}
                                    fullWidth
                                    trailingIconShow={true}
                                    quickSelect={false}
                                    error={errors.dateRange}
                                    helperText={isDirty && errors?.dateRange}
                                />
                            )}
                        />
                    </div>
                    <div className={classes.field}>
                        <Controller
                            control={control}
                            name={'preferences'}
                            render={({ ref, onChange, ...rest }) => (
                                <FormInputDropdown
                                    readOnly={readOnly}
                                    disabled={false}
                                    name={'preferences'}
                                    label={t('notification.profileDrawer.requestCoverage.preferences')}
                                    options={null}
                                    handleChange={() => { }}
                                    inputValue={null}
                                    standard={true}
                                    // clearButton={selection !== undefined}
                                    isCheckBox={false}
                                    isError={false}
                                />
                            )}
                        />
                    </div>
                    <div className={classes.field}>
                        <Controller
                            control={control}
                            name={'notes'}
                            render={({ ref, onChange, ...rest }) => (
                                <TextField
                                    variant="filled"
                                    color="primary"
                                    autoComplete="off"
                                    disabled={readOnly}
                                    label={t('notification.profileDrawer.requestCoverage.notes')}
                                    value={notes}
                                    inputProps={{ maxLength: 4000 }}
                                    required={false}
                                    // error={errors?.firstName ? true : false || (isDirty && errors?.firstName) ? true : false}
                                    {...rest}
                                    onChange={e => {
                                        if (e.target.value?.length > 4000) {
                                            setError(requestCoverageVar.notes, {
                                                type: 'maxLength',
                                                message: t('notification.createTask.maxLimitLabel'),
                                            });
                                        } else if (e.target.value?.length <= 4000) {
                                            clearErrors(requestCoverageVar.notes);
                                        }
                                        onChange(e);
                                        setNotes(e.target.value);
                                    }}
                                    helperText={errors?.firstName?.message}
                                // {register(requestCoverageVar.notes, {
                                //     required: t('required').toString(),
                                // })}
                                />
                            )}
                        />
                    </div>
                    <div className={classes.field}>
                        <Controller
                            control={control}
                            name={'urgent'}
                            render={({ ref, onChange, ...rest }) => (
                                <FormControlLabel
                                    style={{ width: 'fit-content' }}
                                    control={
                                        <Checkbox
                                            id="urgent"
                                            checked={urgentChecked}
                                            color="primary"
                                            onChange={(e) => {
                                                handleUrgentToggle();
                                                onChange(e.target.checked);
                                            }}
                                            name="urgent"
                                        />
                                    }
                                    label={t('notification.profileDrawer.requestCoverage.urgent')}
                                />
                            )}
                        />
                    </div>
                    <Grid justifyContent="flex-start" className={classes.buttonGroup} container>
                        <Grid className={classes.buttons} spacing={1} direction="row" style={{ padding: '1% 0' }} container>
                            <Grid item style={{ marginRight: '10px' }}>
                                <Button variant="contained" className={classes.cancelBtn} onClick={() => handleCancel()}>{`Cancel`}</Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    className={classes.submitBtn}
                                    disabled={disableSubmit}
                                    onClick={async () => {
                                        if (!readOnly) {
                                            const validRequest = await trigger();
                                            if (validRequest) {
                                                handleSubmit(saveRequest)().catch(err => {

                                                })
                                            }
                                        }
                                    }}
                                    variant="contained"
                                    color="primary"
                                >
                                    {`Submit`}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </FormProvider>
            )}
        </div>
    );
};