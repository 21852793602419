import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';

import { pipelineChoiceKeys, nullSelectionObject } from './pipelineChoice.redux';

const selectDomain = (state: RootState) => state?.pipelineChoice || nullSelectionObject;

export const selectRecPlacementsGrid = createSelector(
  [selectDomain],
  grid => grid[pipelineChoiceKeys.REC_WORK_DESK_GRID].selection,
);

export const selectAmPlacementsGrid = createSelector(
  [selectDomain],
  grid => grid[pipelineChoiceKeys.AM_WORK_DESK_GRID].selection,
);

export const selectCredPlacementsStatusGrid = createSelector(
  [selectDomain],
  grid => grid[pipelineChoiceKeys.CRED_PLACEMENT_STATUS_WDG].selection,
);

export const selectCredPlacementsReminderGrid = createSelector(
  [selectDomain],
  grid => grid[pipelineChoiceKeys.CRED_PLACEMENT_REMINDERS_WDG].selection,
);

export const selectCredExpiringDaysGrid = createSelector(
  [selectDomain],
  grid => grid[pipelineChoiceKeys.CRED_EXPIRING_DAYS].selection,
);
