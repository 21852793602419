import { Divider, Grid, Typography } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FundamentalInfo } from './Info/FundamentalInfo';
import { PrimaryEditableFields } from './Edit';
import { Skillset } from './Info/Skillset';
import { SellingPoints } from './Edit/SellingPoints';
import { selectNewPlacementChoice } from 'store/redux-store/new-placement/selectors';
import { useSelector } from 'react-redux';
import { CreateTimeOffRequest } from './Edit/TimeOffRequest';

export const CandidateSection = ({ expanded }: { expanded: boolean }) => {
  const { t } = useTranslation();
  const { candidate } = useSelector(selectNewPlacementChoice);

  return (
    <Grid container flexDirection="column" spacing={2}>
      <Grid item>
        <Typography variant="subtitle2" color="charcoal">
          {t('Candidate Information')}
        </Typography>
      </Grid>
      <Grid item>
        <FundamentalInfo {...candidate} />
      </Grid>
      <Grid item sx={{ maxHeight: expanded ? '70vh' : '500px', overflowY: 'scroll', overflowX: 'hidden' }}>
        <Grid container flexDirection="column" spacing={2}>
          <Grid item>
            <PrimaryEditableFields brand={candidate?.brand} />
          </Grid>
          <Grid item>
            <Divider variant="wideMargin" orientation="horizontal" />
          </Grid>
          <Grid item>
            <Skillset skills={candidate?.groupedSkillsets} />
          </Grid>
          <Grid item>
            <Divider variant="wideMargin" orientation="horizontal" />
          </Grid>
          <Grid item maxWidth={500}>
            <CreateTimeOffRequest />
          </Grid>
          <Grid item>
            <Divider variant="wideMargin" orientation="horizontal" />
          </Grid>
          <Grid item>
            <SellingPoints />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
