import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserInfo } from 'oidc/user.redux';
import { IRoles, userRoles, allAccessRoles } from 'oidc/userRoles';
import _ from 'lodash';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';

export type NavigationTypes =
  | 'home'
  | 'facility'
  | 'order'
  | 'candidate'
  | 'placement'
  | 'credential'
  | 'report'
  | 'client'
  | 'recentHistory'
  | 'settings';

export type NavigationHomeTypes =
  | 'credentialingDashboard'
  | 'recruiterWorkDesk'
  | 'accountManagementWorkDesk'
  | 'credentialingWorkDesk'
  | 'pricingWorkDesk'
  | 'coverage';
export type NavigationRecentTypes = 'recentCandidate' | 'recentPlacement' | 'recentOrder' | 'recentFacility';
export type NavigationSettingTypes = 'userManagement' | 'templateEditor' | 'autoTaskManagement';
export type NavigationTemplateEditorTypes = 'templateEditor.clientConfirmation' | 'templateEditor.candidateContracts';
export type NavigationAction = 'candidateDrawer' | 'facilityDrawer' | 'orderDrawer' | 'placementDrawer';
export type NavigationReportTypes = 'brain' | 'communicationAndTasks' | 'recruiterSales'| 'r108';
export type NavigationSubTypes =
  | NavigationHomeTypes
  | NavigationRecentTypes
  | NavigationSettingTypes
  | NavigationTemplateEditorTypes | NavigationReportTypes;
export type INavigation = NavigationMenu<NavigationTypes, NavigationSubTypes, string>;

export interface NavigationMenu<T, P, C = {}> extends NavigationOption<T> {
  tag: string;
  /** icon to use */
  icon: number;
  /** Sub menu options */
  subMenu?: NavigationOption<P, C>[];
}

// new navigation structure
export interface NavigationOption<T, P = {}> {
  /** key for translation and selection */
  key: T;
  /** label name */
  name?: string;
  /** nav path */
  path?: string;
  /** state variables for navigation */
  pushState?: string;
  /** access roles */
  roles?: (keyof IRoles)[];
  /** disable menu option */
  disabled?: boolean;
  /** link only active when on the given route */
  enableOnlyForRoute?: string;
  /** Sub menu options */
  subMenu?: NavigationOption<P>[];
  /** Limit to one submenu opened at a time */
  singleSubMenuExpandable?: boolean;
  /** option is not disabled, but there is no action when clicking on the menu. Default is true */
  clickable?: boolean;
  /** custom action to perform instead of navigation */
  action?: {
    type: NavigationAction;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
  };
  /** Adds Overflow tooltip for text */
  removeTextOverflow?: boolean;
  // title attribute for the element
  title?: string;
}

export interface INavigationStick {
  navigationMenu: INavigation[];
  selectedMenu: INavigation | undefined;
  selectedSubMenu?: NavigationOption<NavigationSubTypes> | NavigationOption<string>;
  navigationExpanded?: boolean;
}

export enum NavigationStick {
  home = 'home',
  coverage = 'coverage'
}

const getHomeMenuOptions = () => {
  const homeMenuOption: INavigation = {
    key: 'home',
    icon: 0,
    name: 'navStick.home',
    tag: 'home',
    path: '/',
    roles: [userRoles.all],
    subMenu: [
      {
        key: 'accountManagementWorkDesk',
        name: 'navStick.homeOverlay.accountManagement',
        path: '/',
        roles: [
          userRoles.candidate_Contracts,
          userRoles.candidate_Contracts_Leadership,
          userRoles.candidate_Contracts_TeamMember,
          userRoles.accountManagement,
          userRoles.accountManagement_Leadership,
          userRoles.accountManagement_TeamMember,
          userRoles.clientContract_Admin,
          userRoles.clientContract_Admin_Leadership,
          userRoles.clientContract_Admin_TeamMember,
          ...allAccessRoles,
        ],
      },
      // {
      //   key: 'credentialingDashboard',
      //   name: 'navStick.homeOverlay.credentialing',
      //   path: '/',
      //   roles: [
      //     userRoles.supportAdmin,
      //     userRoles.credentialing,
      //     userRoles.credentialing_Leadership,
      //     userRoles.credentialing_TeamMember,
      //   ],
      // },
      {
        key: 'credentialingWorkDesk',
        name: 'navStick.homeOverlay.credentialingWD',
        path: '/',
        roles: [
          userRoles.candidate_Contracts,
          userRoles.candidate_Contracts_Leadership,
          userRoles.candidate_Contracts_TeamMember,
          userRoles.credentialing,
          userRoles.credentialing_Leadership,
          userRoles.credentialing_TeamMember,
          userRoles.clientContract_Admin,
          userRoles.clientContract_Admin_Leadership,
          userRoles.clientContract_Admin_TeamMember,
          ...allAccessRoles,
        ],
      },
      {
        key: 'recruiterWorkDesk',
        name: 'navStick.homeOverlay.recruiter',
        path: '/',
        roles: [
          userRoles.candidate_Contracts,
          userRoles.candidate_Contracts_Leadership,
          userRoles.candidate_Contracts_TeamMember,
          userRoles.recruitment,
          userRoles.recruitment_Leadership,
          userRoles.recruitment_TeamMember,
          userRoles.clientContract_Admin,
          userRoles.clientContract_Admin_Leadership,
          userRoles.clientContract_Admin_TeamMember,
          ...allAccessRoles,
        ],
      },
      {
        key: 'pricingWorkDesk',
        name: 'navStick.homeOverlay.pricing',
        path: '/',
        roles: [userRoles.all],
      },
    ],
  };
  return homeMenuOption;
};
export const homeMenuOption: INavigation = {
  ...getHomeMenuOptions(),
};

const ordersMenu: INavigation = {
  key: 'order',
  icon: 2,
  name: 'navStick.order',
  tag: 'order',
  path: '/search/order',
  roles: [userRoles.all],
};

const placementMenu: INavigation = {
  key: 'placement',
  icon: 3,
  name: 'navStick.placement',
  tag: 'placement',
  path: '/search/placement',
  roles: [userRoles.all],
};

const facilityMenu: INavigation = {
  key: 'facility',
  icon: 1,
  name: 'navStick.facility',
  tag: 'facility',
  path: '/search/facility',
  roles: [userRoles.all],
};

const candidateMenu: INavigation = {
  key: 'candidate',
  icon: 4,
  name: 'navStick.candidate',
  tag: 'candidate',
  path: '/search/candidate',
  roles: [userRoles.all],
};

const credentialMenu: INavigation = {
  key: 'credential',
  icon: 5,
  name: 'navStick.credential',
  tag: 'credential',
  enableOnlyForRoute: '/credential',
  roles: [userRoles.all],
  disabled: true,
};

export const reportMenu: INavigation = {
  key: 'report',
  icon: 6,
  name: 'navStick.report',
  tag: 'report',
  clickable: true,
  subMenu: [
    {
      key: 'brain',
      name: 'navStick.reports.brain',
    },  
    {
      key: 'communicationAndTasks',
      name: 'navStick.reports.communicationAndTasks',
      path: '/reports/communicationAndTasks',
      removeTextOverflow:true
    },
    {
      key: 'recruiterSales',
      name: 'navStick.reports.recruiterSales',
      path:'/reports/recruiterSales',
      removeTextOverflow:true
    },      
    {
      key: 'r108',
      name: 'navStick.reports.r108',
    },
  ],
  roles: [userRoles.all],
};

const clientMenu: INavigation = {
  key: 'client',
  icon: 7,
  name: 'navStick.clientContact',
  tag: 'clientContact',
  path: '/clientContact',
  disabled: true,
  roles: [userRoles.all],
};

const recentHistory: INavigation = {
  key: 'recentHistory',
  icon: 8,
  name: 'navStick.recentHistory',
  tag: 'recentHistory',
  clickable: true,
  singleSubMenuExpandable: true,
  subMenu: [
    {
      key: 'recentCandidate',
      name: 'navStick.recentCandidate',
      clickable: false,
      subMenu: [
        {
          key: 'noRecordCandidate',
          name: 'navStick.noRecords',
          clickable: false,
          roles: [userRoles.all],
        },
      ],
      roles: [userRoles.all],
    },
    {
      key: 'recentPlacement',
      name: 'navStick.recentPlacement',
      clickable: false,
      subMenu: [
        {
          key: 'noRecordPlacement',
          name: 'navStick.noRecords',
          clickable: false,
          roles: [userRoles.all],
        },
      ],
      roles: [userRoles.all],
    },
    {
      key: 'recentOrder',
      name: 'navStick.recentOrder',
      clickable: false,
      subMenu: [
        {
          key: 'noRecordOrder',
          name: 'navStick.noRecords',
          clickable: false,
          roles: [userRoles.all],
        },
      ],
      roles: [userRoles.all],
    },
    {
      key: 'recentFacility',
      name: 'navStick.recentFacility',
      clickable: false,
      subMenu: [
        {
          key: 'noRecordFacility',
          name: 'navStick.noRecords',
          clickable: false,
          roles: [userRoles.all],
        },
      ],
      roles: [userRoles.all],
    },
  ],
  roles: [userRoles.all],
};

export const settingMenu: INavigation = {
  key: 'settings',
  icon: 9,
  name: 'navStick.settings',
  tag: 'settings',
  clickable: true,
  subMenu: [
    {
      key: 'userManagement',
      name: 'navStick.setting.userManagement',
      path: '/settings/user-management',
    },
    {
      key: 'templateEditor',
      name: 'navStick.setting.templateEditor',
      clickable: false,
      subMenu: [
        {
          key: 'templateEditor.clientConfirmation',
          name: 'navStick.setting.template.clientConfirmation',
          path: '/settings/template-editor/client-confirmations',
          removeTextOverflow: true,
        },
        {
          key: 'templateEditor.candidateContracts',
          name: 'navStick.setting.template.candidateContracts',
          path: '/settings/template-editor/candidate-contracts',
          removeTextOverflow: true,
        },
        // For Future
        // {
        //   key: 'templateEditor.submissionPackets',
        //   name: 'navStick.setting.template.submissionPackets',
        //   path: '/settings/template-editor/submission-packets',
        //   removeTextOverflow: true,
        // },
      ],
    },
    {
      key: 'autoTaskManagement',
      name: 'navStick.setting.autoTaskManagement',
      path: '/settings/auto-task-management',
      removeTextOverflow:true
    },
  ],
};

export const initialState: INavigationStick = {
  navigationMenu: [
    homeMenuOption,
    ordersMenu,
    placementMenu,
    candidateMenu,
    facilityMenu,
    clientMenu,
    credentialMenu,
    reportMenu,
    recentHistory,
    settingMenu,
  ],
  selectedMenu: homeMenuOption,
  navigationExpanded: undefined,
};

const navigationStickSlice = createSlice({
  name: 'navigationStick',
  initialState,
  reducers: {
    // new navigation strucutre
    expandNavigation(state, action: PayloadAction<boolean>) {
      return { ...state, navigationExpanded: action.payload };
    },
    setSelectedMenu(state, action: PayloadAction<INavigation | undefined>) {
      return { ...state, selectedMenu: action.payload };
    },
    setSelectedSubMenu(
      state,
      action: PayloadAction<NavigationOption<NavigationSubTypes> | NavigationOption<string> | undefined>,
    ) {
      return { ...state, selectedSubMenu: action.payload };
    },
    setDefaultMenuOptions(state, action: PayloadAction<IUserInfo | undefined>) {
      // to be used for any defaults
      return state;
    },
    setNavigationOptions(state, action: PayloadAction<INavigation[]>) {
      return {
        ...state,
        navigationMenu: [...state.navigationMenu, ...action.payload],
      };
    },
    setNavigationNewOptions(state, action: PayloadAction<INavigation[]>) {
      return {
        ...state,
        navigationMenu: [...action.payload],
      };
    },
    setSelectedMenuByKey(state, action: PayloadAction<NavigationTypes>) {
      return {
        ...state,
        selectedMenu: state.navigationMenu.find(item => item.key === action.payload) || homeMenuOption,
      };
    },
    removeMenuByKey(state, action: PayloadAction<{ key: NavigationTypes } | undefined>) {
      const menu: INavigation[] = _.cloneDeep(state.navigationMenu);
      if (menu && menu.length > 0 && action) {
        menu.map(item => {
          if (item.key === action?.payload?.key) menu.splice(menu.indexOf(item), 1);
        });
      }
      return {
        ...state,
        navigationMenu: menu,
      };
    },
    setSubMenuDisabledByKey(state, action: PayloadAction<{ key: NavigationSubTypes; value: boolean }>) {
      const menu: INavigation[] = _.cloneDeep(state.navigationMenu);
      if (menu && menu.length > 0) {
        for (let i = 0; i < menu.length; i++) {
          const foundItem = menu[i].subMenu?.map(item => item.key).indexOf(action.payload.key);
          if (foundItem && foundItem !== -1 && menu[i].subMenu) {
            menu[i].subMenu![foundItem] = {
              ...menu[i].subMenu![foundItem],
              disabled: action.payload.value,
            };
            break;
          }
        }
      }
      return {
        ...state,
        navigationMenu: menu,
      };
    },
    upsertSubMenuByKey(
      state,
      action: PayloadAction<{
        key: NavigationSubTypes | string;
        menu: NavigationOption<NavigationSubTypes | string, string>;
        // index to insert the menu in the array
        index?: number;
        lengthLimit?: number;
      }>,
    ) {
      const menu: INavigation[] = _.cloneDeep(state.navigationMenu);
      const TraverseNodes = (m: INavigation[] | NavigationOption<NavigationSubTypes, string>[]) => {
        m?.forEach(item => {
          if (item.key === action.payload.key) {
            // find if submenu item already exists in this node. If it does, splice it and add it by index
            const foundIndex = (item.subMenu || []).findIndex(i => i.key === action.payload.menu.key);
            if (foundIndex !== -1) {
              // splice it out and add it at index
              item.subMenu.splice(foundIndex, 1);
              if (!isNullOrUndefined(action.payload.index))
                item.subMenu.splice(action.payload.index, 0, action.payload.menu);
              else item.subMenu.push(action.payload.menu);
            } else {
              if (Array.isArray(item.subMenu)) {
                // add at index
                const foundNoItemsIndex = item.subMenu.findIndex(i => i.key.startsWith('noRecord'));
                if (foundNoItemsIndex !== -1) {
                  item.subMenu.splice(foundNoItemsIndex, 1);
                }
                if (!isNullOrUndefined(action.payload.index))
                  item.subMenu.splice(action.payload.index, 0, action.payload.menu);
                else item.subMenu.push(action.payload.menu);
                if (action.payload.lengthLimit && item.subMenu.length > action.payload.lengthLimit) {
                  item.subMenu.pop();
                }
              } else {
                // insert in new array
                item.subMenu = [];
                item.subMenu.push(action.payload.menu);
              }
            }
          } else if (item.subMenu) {
            TraverseNodes(item.subMenu);
          }
        });
      };
      TraverseNodes(menu);
      return { ...state, navigationMenu: menu };
    },
  },
});

export const {
  actions: navigationStickActions,
  name: navigationStickSliceKey,
  reducer: navigationStickReducers,
} = navigationStickSlice;
