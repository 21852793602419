import React, { ReactNode, useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonProps,
  Grid,
  IconButton,
  IconButtonProps,
  Typography,
  TypographyProps,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { CustomTooltip } from '@AMIEWEB/Common';
import { CustomTooltipProps } from '@AMIEWEB/Common/Tooltips/CustomTooltip';

interface ICustomActionProps {
  type?: 'text' | 'icon' | 'component' | null;
  text?: string;
  hidden?: boolean;
  textProps?: TypographyProps;
  icon?: any;
  iconProps?: IconButtonProps;
  tooltipProps?: CustomTooltipProps;
  renderComponent?: any;
}

interface IActionButton extends ButtonProps {
  text?: string;
  hidden?: boolean;
  useDialogOnCloseEvent?: boolean;
  tooltipProps?: CustomTooltipProps;
}

const useStyles = makeStyles<{ isDisabled: boolean; isExpanded?: boolean }>()(
  (theme, { isDisabled, isExpanded = false }) => ({
    accordionWrapper: {
      boxShadow: 'none',
      '&.Mui-expanded': {
        boxShadow:
          '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      },
    },
    summary: {
      backgroundColor: isDisabled
        ? theme.palette.components.accordion.secondary.backgroundColor
        : theme.palette.system.skyBlue,
      cursor: 'default',
      minHeight: '48px !important',
      maxHeight: '48px !important',
      border: `1px solid ${theme.palette.system.lightGrey}`,
      borderRadius: isExpanded ? '5px 5px 0px 0px' : '5px',
    },
    actionbuttonContainer: {
      justifyContent: 'flex-end',
      marginTop: theme.spacing(3),
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
      background: 'rgb(245, 245, 245)',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px -1px 3px',
    },
    filledTertiaryButton: {
      marginLeft: '16px',
    },
    nonTertiaryButton: {
      marginLeft: '16px',
    },
    transparentButton: {
      marginRight: '-6px',
      color: theme.palette.components.button.dialog.color,
      '&:hover': {
        color: theme.palette.components.button.dialog.color,
      },
    },
  }),
);

const ActionButton = ({
  text,
  variant = 'text',
  color = 'primary',
  size = 'large',
  disableElevation = true,
  tooltipProps,
  ...props
}: IActionButton) => {
  const { classes, cx } = useStyles({ isDisabled: false });

  return (
    <CustomTooltip
      standardMargin
      {...tooltipProps}
      disabled={!tooltipProps || tooltipProps?.disabled}
      tooltipContent={tooltipProps?.tooltipContent ?? <div />}
    >
      <Button
        disableRipple
        disableFocusRipple
        color={color}
        className={cx('', {
          [classes.transparentButton]: variant === 'text',
          [classes.filledTertiaryButton]: variant === 'contained' && color === 'tertiary',
          [classes.nonTertiaryButton]: variant === 'contained' && color !== 'tertiary',
        })}
        size={size}
        variant={variant}
        disableElevation={disableElevation}
        data-testid="generic-dialog-button-id"
        {...props}
      >
        {text}
      </Button>
    </CustomTooltip>
  );
};

const CustomActions = ({
  type,
  hidden,
  text,
  textProps,
  icon,
  iconProps,
  tooltipProps,
  renderComponent,
}: ICustomActionProps) => {
  return (
    <>
      {!hidden && (
        <Grid item mx={2}>
          <CustomTooltip
            standardMargin
            {...tooltipProps}
            disabled={!tooltipProps || tooltipProps?.disabled}
            tooltipContent={tooltipProps?.tooltipContent ?? <div />}
          >
            {type === 'component' && renderComponent ? (
              renderComponent
            ) : type === 'icon' && icon ? (
              <IconButton
                {...iconProps}
                sx={theme => ({
                  paddingX: 0,
                  '&:hover': {
                    color: theme.palette.system.main,
                  },
                })}
              >
                {icon}
              </IconButton>
            ) : (
              text && <Typography {...textProps}>{text}</Typography>
            )}
          </CustomTooltip>
        </Grid>
      )}
    </>
  );
};

const CustomCollapsible = ({
  title,
  showExpandIcon = true,
  defaultExpanded = false,
  isExpanded = false,
  setExpanded,
  isDisabled = false,
  children,
  actionButtons,
  leftActions,
  rightActions,
}: {
  title: string;
  defaultExpanded?: boolean;
  isExpanded?: boolean;
  setExpanded?: (boolean) => void;
  showExpandIcon?: boolean;
  isDisabled?: boolean;
  children: ReactNode;
  actionButtons?: IActionButton[];
  leftActions?: ICustomActionProps[];
  rightActions?: ICustomActionProps[];
}) => {
  const { classes } = useStyles({ isDisabled, isExpanded });

  const onExpandClick = () => {
    setExpanded?.(!isExpanded);
  };

  return (
    <Accordion expanded={isExpanded} defaultExpanded={defaultExpanded} className={classes.accordionWrapper}>
      <AccordionSummary
        expandIcon={
          showExpandIcon ? (
            <IconButton color="primary" size="small" onClick={onExpandClick}>
              <ExpandMoreIcon />
            </IconButton>
          ) : (
            <></>
          )
        }
        aria-controls="panel1a-content"
        id="custom-collapsible"
        className={classes.summary}
      >
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item display={'flex'} alignItems={'center'}>
            <Grid item pl={2}>
              <Typography
                component={'div'}
                sx={{ fontSize: '16px !important', color: theme => theme.palette.system.black, fontWeight: '500' }}
              >
                {title}
              </Typography>
            </Grid>
            {leftActions?.length && leftActions.map(action => <CustomActions {...action} />)}
          </Grid>
          <Grid item display={'flex'} alignItems={'center'} mr={3}>
            {rightActions?.length && rightActions.map(action => <CustomActions {...action} />)}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0, margin: 0 }}>
        {children}

        {actionButtons?.length && (
          <Grid container className={classes.actionbuttonContainer}>
            {actionButtons.map((button, index) =>
              !button.hidden ? (
                <Grid item key={`custom-collapsible-action-button-${index}`}>
                  <ActionButton {...button} />
                </Grid>
              ) : null,
            )}
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomCollapsible;
