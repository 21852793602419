import { GridSortItem, GridColumns } from '@mui/x-data-grid-pro';
import _ from 'lodash';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGridState } from '../../Grid/GridStateManagement/GridState.redux';
import { selectGridState } from '../../Grid/GridStateManagement/GridState.selectors';

const extractColumnPreferences = (columns: GridColumns = [], columnPreferences: GridColumns = []) => {
  /**
   * Code: to derive columns w.r.t user preferences
   * hide, width etc.
   */
  const newColumns = columns.reduce((results, item) => {
    const preference = columnPreferences.find(col => col.field === item.field);
    return preference ? [...results, { ...item, hide: preference.hide, width: preference.width }] : [...results, item];
  }, [] as GridColumns);
  /** Code: sorting performed to preserve user-preferred column order (via XGrid drag and drop) */
  return _.sortBy(newColumns, item => columnPreferences.findIndex(pref => pref.field === item.field));
};

const extractGridPreferences = (preference, gridTag) =>
  preference.userGridPreferences.find(item => gridTag === item.id)?.value;


export const useGridPreferences = ({
  gridTag,
  /** 'gridColumns' to be mapped with absolute columns in case of call backs */
  gridColumns = [] as GridColumns,
  defaultSort = [] as GridSortItem[],
}) => {
  const [columns, setColumns] = useState(gridColumns);
  const [sortModel, setSortModel] = useState(defaultSort);
  const [forceDefaultView, setDefaultView] = useState(false);

  const gridState = useSelector(selectGridState);
  const userPreference = useSelector(selectUserPreference);

  const gridStateRef = useRef<IGridState>(gridState);
  gridStateRef.current = gridState;

  const dispatch = useDispatch();

  useEffect(() => {
    const isCurrentlyInDefaultState =
      _.isEqual(gridStateRef.current.columns, gridColumns) && _.isEqual(gridStateRef.current.sortedColumn, defaultSort);
    if (forceDefaultView && !isCurrentlyInDefaultState) {
      setSortModel(defaultSort);
      setColumns(gridColumns);
    } else {
      setDefaultView(false);
      const preference = extractGridPreferences(userPreference, gridTag);
      setSortModel(prevData =>
        preference?.sortedColumn
          ? [{ field: preference.sortedColumn.column, sort: preference.sortedColumn.direction }]
          : prevData,
      );
      setColumns(preference?.columns ? extractColumnPreferences(gridColumns, preference.columns) : gridColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceDefaultView, gridColumns, gridTag, userPreference]);

  useEffect(() => {
    /** TODO: Provision for screen refresh */
    
    /** Code: to save gridState on component destruction */
    return () => {
      const preferenceData = { id: gridTag, value: gridStateRef.current };
      const defaultGridState = extractGridPreferences(userPreference, gridTag);
      if (
        !_.isEqual(
          JSON.stringify(gridStateRef.current?.sortedColumn),
          JSON.stringify(defaultGridState?.sortedColumn),
        ) ||
        !_.isEqual(JSON.stringify(gridStateRef.current?.columns), JSON.stringify(defaultGridState?.columns))
      ) {
        dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { columns, setColumns, sortModel, setSortModel, forceDefaultView, setDefaultView };
};
