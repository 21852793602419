import _ from "lodash";
import { Moment } from "moment";

export const getPlacementUpdates = (
  {
    requestedShift,
    placementShift,
    bestTimeToCall,
    travelsWithPartner,
    travelPartner,
    placementDate,
    placementStartDate,
    placementEndDate,
    availabilityDate,
    sellingPoints,
    requestedTimeOff,
    vmsReqNo,
    contractHours,
    numberOfWeeks
  },
  dirtyFields,
) => ({
  requestedShift: dirtyFields.requestedShift ? requestedShift ?? '' : undefined,
  bestTimeToCall: dirtyFields.bestTimeToCall ? bestTimeToCall ?? '' : undefined,
  travelsWithPartner: dirtyFields.travelsWithPartner ? travelsWithPartner : undefined,
  travelPartner: dirtyFields.travelPartner ? travelPartner ?? '' : undefined,
  placementDate:
    dirtyFields?.placementStartDate || dirtyFields?.placementEndDate || dirtyFields?.numberOfWeeks
      ? {
          startDate: placementStartDate,
          endDate: placementEndDate,
        }
      : undefined,
  numberOfWeeks : dirtyFields?.numberOfWeeks ? numberOfWeeks : null,
  availabilityDate: dirtyFields.availabilityDate ? availabilityDate : undefined,
  sellingPoints: dirtyFields.sellingPoints ? sellingPoints ?? '' : undefined,
  shiftId: dirtyFields.placementShift ? placementShift.object.id ?? null : undefined,
  shiftDescription: dirtyFields.placementShift ? placementShift?.label ?? null : undefined,
  ...(dirtyFields.requestedTimeOff ? (requestedTimeOff || {}) : {}),
  vmsReqNo: dirtyFields.vmsReqNo && vmsReqNo?.trim() ? vmsReqNo.trim() : null,
  contractHours: dirtyFields.contractHours ? contractHours?.trim() || null : undefined,
});

export const getStrikePlacementUpdates = (
  {
    transportationChosen,
    travelTimePreferenceChosen,
    gender,
    departureAirport,
    datesConfirmed,
    dnsCleared,
    travelConfirmed,
    arrivalDate,
    arrivalTime,
  },
  dirtyFields,
) => ({
  ...(dirtyFields.transportationChosen && transportationChosen ? transportationChosen : {}),
  ...(dirtyFields.travelTimePreferenceChosen && travelTimePreferenceChosen ? travelTimePreferenceChosen.object : {}),
  gender: dirtyFields.gender && gender ? gender.object : undefined,
  departureAirport: dirtyFields.departureAirport && departureAirport ? departureAirport.object : undefined,
  datesConfirmed: dirtyFields.datesConfirmed ? datesConfirmed : undefined,
  dnsCleared: dirtyFields.dnsCleared ? dnsCleared : undefined,
  travelConfirmed: dirtyFields.travelConfirmed ? travelConfirmed : undefined,
  arrivalDate: dirtyFields.arrivalDate ? arrivalDate : undefined,
  arrivalTime: dirtyFields.arrivalTime ? arrivalTime : undefined,
});

export const getExtensionEndDate = (startDateMoment: Moment, duration: number): Moment => {
  const vbThursday = 4; // DayOfWeek.Thursday is 4 in JavaScript (0-based index for Sunday)
  if (duration > 0) {
      if (startDateMoment.day() > vbThursday) {
          startDateMoment = startDateMoment.add(7 - startDateMoment.day(), 'days');
      } else {
          startDateMoment = startDateMoment.subtract(startDateMoment.day(), 'days');
      }
      const calcEndDate = startDateMoment.clone().add((duration * 7) - 1, 'days');
      return calcEndDate;
  } else {
      return startDateMoment;
  }
};
