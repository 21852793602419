import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { LayoutGrid, LayoutGridItem } from '../LayoutGrid';
import { CustomBackdrop } from 'app/components/Common/Backdrop/CustomBackdrop';

const useStyles = makeStyles()((theme) => ({
    root: {
        minHeight: '100%',
        paddingTop: 10,
        paddingLeft: 10,
        paddingBottom: 0,
    },
    mainPanelRoot: {
        width:  '100%',
    },
    container: {
        flexWrap: 'nowrap',
    },
}));

const SearchGridWrapper =
    ({
        filters,
        chips,
        table,
        loading,
        tableMinHeight,
    }
        :
        {
            filters?: React.ReactNode;
            chips?: React.ReactNode;
            table?: React.ReactNode;
            loading?: boolean;
            tableMinHeight?: number;
        }) => {
        const { classes } = useStyles();
        return (
            <div id="search-grid-wrapper-paper" className={classes.root}>
                <LayoutGrid container direction="row" justifyContent="space-between" classes={{ container: classes.container }}>
                    <LayoutGridItem
                        container
                        direction="column"
                        spacing={2}
                        classes={{ root: classes.mainPanelRoot, container: classes.container }}
                    >
                        {filters && (
                            <LayoutGridItem id="search-grid-filters" xs>
                                {filters}
                            </LayoutGridItem>
                        )}
                        {chips && (
                            <LayoutGridItem id="search-grid-chips" xs>
                                {chips}
                            </LayoutGridItem>
                        )}
                        {table && (
                            <LayoutGridItem id="search-grid-table" xs style={ tableMinHeight &&{minHeight: tableMinHeight}}>
                                {table}
                            </LayoutGridItem>
                        )}
                    </LayoutGridItem>
                </LayoutGrid>
                <CustomBackdrop open={loading} />
            </div>
        )
    }

export default SearchGridWrapper
