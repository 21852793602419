import { Grid, Paper } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { validDecimal } from 'app/helpers/numberHelper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PreviewContentTitle } from '../../../OrderCreationForm/DefineUnit/UnitPreview/UnitPreviewDesign';
import LaunchIcon from '@mui/icons-material/Launch';

interface Props {
  disableEdit?: boolean;
  data: {
    vmsBillRate: any;
    vmsOrderNumber: any;
    weblink: any;
    technologyVendor: any;
    staffingVendor: any;
    fyre: any;
  };
  onChange: (key: string, value: any) => void;
}

const useStyles = makeStyles()(theme => ({
  TechtextFiled: {
    background: '#E8E8E8',
    border: 'none',
    padding: '0.3rem 0.2rem',
    width: '100%',
    '&:focus': {
      outline: 'none',
      outlineWidth: 0,
    },
  },
  inlineIconRow: {
    width: '90%',
  },
  inlineIcon: {
    padding: '0 0.2rem',
    color: '#555555',
    '&.clickable': {
      cursor: 'pointer',
    },
  },
  innerTextField: {
    background: '#E8E8E8',
    border: 'none',
    padding: '0.3rem 0.2rem',
    flexGrow: 1,
    '&:focus': {
      outline: 'none',
      outlineWidth: 0,
    },
  },
  Clicklinktext: {
    color: '#006FB9',
    textDecoration: 'underline',
  },
}));

export const OrderDetailsTechnologyInformation = ({ data, disableEdit, onChange }: Props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [validUrl, setValidUrl] = React.useState<boolean>(false);

  React.useEffect(() => {
    setValidUrl(validateUrl(data.weblink));
  }, [data.weblink]);

  const clickOnlink = (target: string) => {
    if (target && !target.startsWith('http')) {
      target = `http://${target}`;
    }
    if (target && validateUrl(target)) {
      const linkSource = `${target}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.target = '_blank';
      downloadLink.click();
    }
  };

  const validateUrl = value => {
    return /^(?:(?:(?:http?|https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value,
    );
  };

  const _onChange = event => {
    if (event.target.getAttribute('id') === 'vmsBillRate') {
      if (validDecimal(event.target.value, { numberLength: 6, decimalLength: 4 })) {
        onChange(event.target.getAttribute('id'), event.target.value || null);
      }
    } else if (event.target.getAttribute('id') === 'vmsOrderNumber') {
      if (event.target.value.length <= 255) {
        onChange(event.target.getAttribute('id'), event.target.value);
      }
    } else {
      onChange(event.target.getAttribute('id'), event.target.value);
    }
  };

  return (
    <Grid item xs={12}>
      <Paper className="layout" variant="outlined" elevation={0}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <PreviewContentTitle>{t('order.createUnit.technologyInformation.title')}</PreviewContentTitle>
          </Grid>
          <Grid item xs={5}>
            {t('order.createUnit.technologyInformation.staffingVendorDescription')}
          </Grid>
          <Grid item xs={7}>
            <Grid id={'OrderStaffingVendorLbl'}>{data.staffingVendor}</Grid>
          </Grid>
          <Grid item xs={5}>
            {t('order.createUnit.technologyInformation.techVendorDescription')}
          </Grid>
          <Grid item xs={7}>
            <Grid id={'OrderTechnologyVendorLbl'}>{data.technologyVendor}</Grid>
          </Grid>
          <Grid item xs={5}>
            {t('order.createUnit.technologyInformation.vmsReqDescription')}
          </Grid>
          <Grid item xs={7}>
            <Grid>
              <input
                id="vmsOrderNumber"
                className={classes.TechtextFiled}
                value={data.vmsOrderNumber}
                onChange={_onChange}
                disabled={disableEdit}
              />
            </Grid>
          </Grid>
          <Grid item xs={5}>
            {t('order.createUnit.technologyInformation.vmsWebDescription')}
          </Grid>
          <Grid item xs={7}>
            <Grid>
              <input
                id="webLink"
                className={`${classes.TechtextFiled} ${classes.inlineIconRow} ${validUrl ? classes.Clicklinktext : ''}`}
                value={data.weblink}
                //onClick={clickOnlink}
                onChange={_onChange}
                disabled={disableEdit}
              />
              <LaunchIcon
                className={`${classes.inlineIcon} ${validUrl ? 'clickable' : ''} `}
                onClick={() => {
                  clickOnlink(data.weblink);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={5}>
            {t('order.createUnit.technologyInformation.vmsBillDescription')}
          </Grid>
          <Grid item xs={7}>
            <Grid>
              <div className={classes.TechtextFiled} style={{ display: 'flex', padding: '0px' }}>
                <div style={{ alignSelf: 'center', paddingLeft: '3px' }}>{'$'}</div>
                <input
                  id="vmsBillRate"
                  className={classes.innerTextField}
                  type="text"
                  value={data.vmsBillRate || ''}
                  onChange={_onChange}
                  disabled={true}
                />
              </div>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            {t('order.createUnit.technologyInformation.fyreDescription')}
          </Grid>
          <Grid item xs={7}>
            <Grid id={'OrderFyreIntLbl'}>{data.fyre ? `${`Yes`}` : `${`No`}`}</Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
