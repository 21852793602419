import { IChip } from 'app/ComponentLibrary/Filter/ChipsContainer';
import { ISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import { ExpiringDocumentsFilterName } from '../Filters/ExpiringFilterConfig';

export interface IFilterChipSelected {
  tiersSelected: [];
  [ExpiringDocumentsFilterName.placementId]: any[] | null;
  [ExpiringDocumentsFilterName.candidateName]: any[] | null;
  [ExpiringDocumentsFilterName.credential]: any[] | null;
  [ExpiringDocumentsFilterName.daysUntilExpiration]: any[] | null;
  [ExpiringDocumentsFilterName.expirationDate]: any[] | null;
  [ExpiringDocumentsFilterName.facilityName]: any[] | null;
  [ExpiringDocumentsFilterName.credentialCategory]: any[] | null;
  [ExpiringDocumentsFilterName.credentialGroup]: any[] | null;
  [ExpiringDocumentsFilterName.candidateId]: any[] | null;
  [ExpiringDocumentsFilterName.tier]: any[] | null;
}

export interface IFilterSpecs {
  placementIDOpts: ISelection[];
  candidateNameOpts: ISelection[];
  credentialsOpts: ISelection[];
  clientNameOpts: ISelection[];
  credentialCategoryOpts: ISelection[];
  credentialGroupOpts: ISelection[];
  candidateIdOpts: ISelection[];
  tierOpts: ISelection[];
}

export const defaultFilterChipSelected = {
  [ExpiringDocumentsFilterName.placementId]: null,
  [ExpiringDocumentsFilterName.candidateName]: null,
  [ExpiringDocumentsFilterName.credential]: null,
  [ExpiringDocumentsFilterName.daysUntilExpiration]: null,

  [ExpiringDocumentsFilterName.facilityName]: null,
  [ExpiringDocumentsFilterName.credentialCategory]: null,
  [ExpiringDocumentsFilterName.credentialGroup]: null,
  [ExpiringDocumentsFilterName.candidateId]: null,
  [ExpiringDocumentsFilterName.tier]: null,
} as IFilterChipSelected;

export interface IExpiringFilterChips {
  tiersSelected: IChip[];
  [ExpiringDocumentsFilterName.placementId]: IChip[];
  [ExpiringDocumentsFilterName.candidateName]: IChip[];
  [ExpiringDocumentsFilterName.credential]: IChip[];
  [ExpiringDocumentsFilterName.daysUntilExpiration]: string | null;

  [ExpiringDocumentsFilterName.facilityName]: IChip[];
  [ExpiringDocumentsFilterName.credentialCategory]: IChip[];
  [ExpiringDocumentsFilterName.credentialGroup]: IChip[];
  [ExpiringDocumentsFilterName.candidateId]: IChip[];
  [ExpiringDocumentsFilterName.tier]: IChip[];
}

export const defaultChips: IExpiringFilterChips = {
  tiersSelected: [],
  [ExpiringDocumentsFilterName.placementId]: [],
  [ExpiringDocumentsFilterName.candidateName]: [],
  [ExpiringDocumentsFilterName.credential]: [],
  [ExpiringDocumentsFilterName.daysUntilExpiration]: null,

  [ExpiringDocumentsFilterName.facilityName]: [],
  [ExpiringDocumentsFilterName.credentialCategory]: [],
  [ExpiringDocumentsFilterName.credentialGroup]: [],
  [ExpiringDocumentsFilterName.candidateId]: [],
  [ExpiringDocumentsFilterName.tier]: [],
};
export interface IExpiringFilter {
  placementId: number | 0;
  candidateName: string[];
  credential: string[];
  daysUntilExpiration: number | 0;
  expirationDate: string;
  facilityName: string[];
  credentialCategory: string[];
  credentialGroup: string[];
  candidateId: number | 0;
  tier: number | 0;
}

export interface CredentialingExpiringDetails {
  candidateId: number;
  candidateName: string;
  credential: string;
  clinicianLastName?: string;
  placementId?: number;
  expiringCredentials?: string;
  brandId: string;
  expirationDate?: string;
  expirationDateNoFormat?: string;
  daysUntilExpiration?: number;
  facilityId: number;
  facilityName: string;
  facilityNameLower: string;
  credentialCategory: string;
  credentialGroup: string;
  statusAbbr?: string | null;
  tier?: number;
  requirementId?: number;
}

export enum expiringCredentialsSubStatus {
  today = 'Today',
  tomorrow = 'Tomorrow',
  next7Days = 'Next 7 Days',
  next14Days = 'Next 14 Days',
  next30Days = 'Next 30 Days',
  allExpiring = 'All',
  onAssignment = 'On Assignment',
}
