import React from 'react';
import { Grid, createSvgIcon } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CustomSelect } from '@AMIEWEB/Placement/CreatePlacement/CustomComponents/CustomSelect';

const Tungsten = createSvgIcon(
  <svg
    id="Group_21296"
    data-name="Group 21296"
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="21.552"
    viewBox="0 0 22 21.552"
  >
    <path
      id="emoji_objects_24dp_FILL0_wght400_GRAD0_opsz24"
      d="M196-863.448a1.865,1.865,0,0,1-.972-.259,1.881,1.881,0,0,1-.683-.693,1.594,1.594,0,0,1-1.169-.486,1.594,1.594,0,0,1-.486-1.169v-2.938a6,6,0,0,1-1.955-2.131A5.832,5.832,0,0,1,190-874a5.787,5.787,0,0,1,1.748-4.252A5.787,5.787,0,0,1,196-880a5.787,5.787,0,0,1,4.252,1.748A5.787,5.787,0,0,1,202-874a5.8,5.8,0,0,1-.734,2.9,6.048,6.048,0,0,1-1.955,2.11v2.938a1.594,1.594,0,0,1-.486,1.169,1.594,1.594,0,0,1-1.169.486,1.881,1.881,0,0,1-.683.693A1.865,1.865,0,0,1,196-863.448Zm-1.655-2.607h3.31v-.745h-3.31Zm0-1.572h3.31v-.786h-3.31Zm-.166-2.441h1.2V-872.3l-1.821-1.821.869-.869L196-873.421l1.572-1.572.869.869-1.821,1.821v2.234h1.2a4.531,4.531,0,0,0,1.821-1.583,4.11,4.11,0,0,0,.7-2.348,4.194,4.194,0,0,0-1.262-3.083A4.194,4.194,0,0,0,196-878.345a4.194,4.194,0,0,0-3.083,1.262A4.194,4.194,0,0,0,191.655-874a4.11,4.11,0,0,0,.7,2.348A4.531,4.531,0,0,0,194.179-870.069ZM196-873.421ZM196-874.207Z"
      transform="translate(-185.5 885)"
      fill="#69c"
    />
    <rect id="Rectangle_8719" data-name="Rectangle 8719" width="2" height="3" transform="translate(9.5)" fill="#69c" />
    <rect
      id="Rectangle_8720"
      data-name="Rectangle 8720"
      width="2"
      height="3"
      transform="translate(22 9.5) rotate(90)"
      fill="#69c"
    />
    <rect
      id="Rectangle_8721"
      data-name="Rectangle 8721"
      width="2"
      height="3"
      transform="translate(3 9.5) rotate(90)"
      fill="#69c"
    />
    <rect
      id="Rectangle_8722"
      data-name="Rectangle 8722"
      width="2"
      height="3"
      transform="matrix(-0.695, 0.719, -0.719, -0.695, 6.273, 4.822)"
      fill="#69c"
    />
    <rect
      id="Rectangle_8723"
      data-name="Rectangle 8723"
      width="2"
      height="3"
      transform="matrix(0.695, 0.719, -0.719, 0.695, 17.884, 2.738)"
      fill="#69c"
    />
  </svg>,
  'Tungsten',
);

const useStyles = makeStyles()({
  infoTip: {
    display: 'flex',
    flexDirection: 'row',
    gap: 6,
  },
  infoIcon: {
    display: 'block',
    alignContent: 'center',
  },
  tipMessage: {
    color: '#333333',
    fontSize: 12,
  },
  rightClipper: {
    marginRight: '24px',
  },
});

export const SelectSkillset = ({ options = [] }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { classes } = useStyles();

  return (
    <Grid container flexDirection="column" spacing={1}>
      <Grid item className={classes.infoTip}>
        <span className={classes.infoIcon}>
          <Tungsten fontSize="small" />
        </span>
        <span className={classes.tipMessage}>
          {t('placement.create.popup.skillSetTip1')}
          <br />
          <span>{t('placement.create.popup.skillSetTip2')}</span>
        </span>
      </Grid>
      <Grid item classes={{ root: classes.rightClipper }}>
        <Controller
          control={control}
          name="skillset"
          render={({ ref, ...rest }) => (
            <CustomSelect options={options} label={t('placement.create.popup.skillSet')} {...rest} />
          )}
        />
      </Grid>
    </Grid>
  );
};
