import { makeStyles, withStyles } from 'tss-react/mui';
import React from 'react';
import CustomTableCell from '../TableCells/TableCell';
import { IColumnProps } from '../types';
import ExpandMoreCell from '../TableCells/ExpandMoreCell';
import { useSelector } from 'react-redux';
import { selectExpandableCells } from '../store/comparisionTable.selectors';
import { includes } from 'lodash';

const useStyles = makeStyles<{
  detailsCell: boolean;
  referenceCell: boolean;
  comparisionCell: boolean;
  width?: number;
}>()((detailsCell: any, referenceCell: any, comparisionCell: any) => ({
  tableCells: {
    '&td:nth-child(even)': {
      backgroundColor:
        (detailsCell && '#EAEBED') || (referenceCell && '#F5F5F5') || (comparisionCell && '#FFFFFF') || '#FFFFFF',
    },
    width: '100%',
    minWidth: '180px',
    '&td:nth-child(odd)': {
      backgroundColor:
        (detailsCell && '#DCDDDF') || (referenceCell && '#EBECEE') || (comparisionCell && '#F6F6F6') || '#F6F6F6',
    },
  },
  cellsText: {
    marginLeft: 14,
  },
  autoCellHeight: {
    height: '380px',
    maxheight: 'auto',
  },
  fixheightCell: {
    height: 'auto',
    maxheight: 'auto',
  },
  expandButton: {
    fontSize: '13px',
    padding: '0px 14px',
    paddingBottom: '12px',
    '& .MuiButtonBase-root': {
      textTransform: 'none',
      fontSize: 13,
    },
    '& .MuiButton-root': {
      textTransform: 'none',
      fontSize: 13,
    },
  },
  skillsetCell: {
    height: '250px',
    maxheight: 'auto',
  },
}));

const Column = props => {
  const { classes } = useStyles({
    detailsCell: props?.detailsCell || false,
    referenceCell: props?.referenceCell || false,
    comparisionCell: props.comparisionCell || false,
  });
  const contentRefs = React.useRef([]);
  const [expandableStates, setExpandableStates] = React.useState({});
  const [lastExpandedRow, setLastExpandedRow] = React.useState(undefined);
  const [lastExpandedCellIndex, setLastExpandedCellIndex] = React.useState<any>();
  const cellRefs = React.useRef([]);
  const [expand, setExpand] = React.useState(false);
  const [lastExpandedCell, setLastExpandedCell] = React.useState();
  const expandable = useSelector(selectExpandableCells);
  const [collapse, setCollapse] = React.useState({});
  const [showExpandButton, setShowExpandButton] = React.useState({});

  cellRefs.current = Array(props.tableRows.length)
    .fill(null)
    .map((_, i) => cellRefs.current[i] || React.createRef());
  contentRefs.current = Array(props.tableRows.length)
    .fill(null)
    .map((_, i) => contentRefs.current[i] || React.createRef());

  React.useLayoutEffect(() => {
    let object = {};
    props?.tableRows &&
      props?.tableRows?.map((item, index) => {
        if (item?.isExpandable) {
          const ele = contentRefs.current[index]?.current;
          if (ele) {
           if(ele.scrollHeight > item?.height){
              object = {
                ...object,
                [item?.name] : true,
              }
           }
          }
          setShowExpandButton(object);
        }
      });
  }, []);

  React.useEffect(() => {
    props.tableRows?.forEach(item => {
      item?.isExpandable &&
        setExpandableStates({
          ...expandableStates,
          [item.name]: {
            expanded: false,
            maxheight: item?.height,
            cellClicked: 0,
          },
        });
    });
  }, []);
  React.useEffect(() => {
    const rowData = props?.tableRows?.[lastExpandedCellIndex];
    if (props?.rowAttributes?.[rowData?.name]?.expanded == false) {
      cellRefs.current[lastExpandedCellIndex].current.style.height = `${rowData?.height}px`;
      setExpandableStates({
        ...expandableStates,
        [lastExpandedRow]: {
          expanded: false,
          maxheight: rowData?.height,
          cellClicked: undefined,
        },
      });
      setLastExpandedCellIndex(undefined);
    } else if (lastExpandedCellIndex) {
      cellRefs.current[lastExpandedCellIndex].current.style.height = `${
        props?.rowAttributes?.[rowData?.name]?.maxheight
      }px`;
    }
  }, [props?.rowAttributes, lastExpandedCellIndex]);

  React.useEffect(() => {
    props?.tableRows &&
      props?.tableRows?.map((item, index) => {
        if (item?.isExpandable) {
          const height = props?.rowAttributes?.[item?.name]?.maxheight || item?.height;
          const ele = cellRefs.current[index]?.current;
          if (ele) {
            cellRefs.current[index].current.style.height = `${height}px`;
          }
        }
      });
  }, [props?.rowAttributes]);

  React.useEffect(() => {
    onExpandCell();
  }, [lastExpandedRow, expand, lastExpandedCell]);


  const onExpandCell = () => {
    const ele = cellRefs.current?.[lastExpandedCellIndex]?.current;
    if (ele) {
      ele.style.height = 'auto';
    }
    const height = cellRefs.current?.[lastExpandedCellIndex]?.current?.offsetHeight;
    const rowData = props?.tableRows?.[lastExpandedCellIndex];
    const maxHeight =
      rowData?.height > props?.rowAttributes?.[rowData?.name]?.maxheight
        ? rowData?.height
        : props?.rowAttributes?.[rowData?.name]?.maxheight;
    if (ele) {
      if (expand === false && props?.rowAttributes?.[rowData?.name]?.cellClicked == 1) {
        cellRefs.current[lastExpandedCellIndex].current.style.height = `${rowData?.height}px`;
        props?.setRowHeights(lastExpandedRow, rowData?.height, expand, lastExpandedCell, expandable);
      } else if (height > maxHeight) {
        cellRefs.current[lastExpandedCellIndex].current.style.height = `${height}px`;
        props?.setRowHeights(lastExpandedRow, height, expand, lastExpandedCell, expandable);
      } else {
        cellRefs.current[lastExpandedCellIndex].current.style.height = `${maxHeight}px`;
        props?.setRowHeights(lastExpandedRow, maxHeight, expand, lastExpandedCell, expandable);
      }
    }
  };

  //TODO - Abhishek Wilson - Infer the types of cells and column by only one prop and remove un necessary codes
  return (
    <div className={classes.tableCells} style={props?.width && { width: `${props?.width}px`, minWidth: 180 }}>
      {props?.tableRows &&
        props?.tableRows.map((item, index) => {
          return (
            <CustomTableCell
              detailsCell={props.detailsCell || false}
              referenceCell={props.referenceCell || false}
              comparisionCell={props.comparisionCell || false}
              index={index}
              height={
                props?.rowAttributes?.[item?.name]?.expanded
                  ? props?.rowAttributes?.[item?.name]?.maxheight
                  : item?.height
              }
              className={
                expandableStates?.[item?.name]?.expanded &&
                expandableStates?.[item?.name]?.cellClicked == props.columnData[item.name]
                  ? classes.fixheightCell
                  : ''
              }
              ref={cellRefs.current[index]}
              maxHeight={item?.isExpandable ? props?.rowAttributes?.[item?.name]?.maxheight : item?.height}
              data-testId={`Table-Cell-${item?.name}-${index}`}
            >
              <div
                style={{
                  height:
                    expandableStates?.[item?.name]?.expanded &&
                    expandableStates?.[item?.name]?.cellClicked === props.columnData[item.name]
                      ? '100%'
                      : item?.height,
                }}
              >
                <div
                  style={{
                    height: item?.isExpandable
                      ? props?.rowAttributes?.[item?.name]?.expanded &&
                        expandableStates?.[item?.name]?.expanded &&
                        expandableStates?.[item?.name]?.cellClicked === props.columnData[item.name]
                        ? 'auto'
                        : showExpandButton?.[item.name] == true ? 'calc(100% - 35px)' : '100%'
                      : '100%',
                    overflow: 'hidden',
                  }}
                  ref={contentRefs.current[index]}
                >
                  {/* rendering according to the property name of the table rows nullifies the scenario of data mismatch */}
                  {!props.detailsCell && item?.renderCell ? (
                    item.renderCell({ data: props.columnData[item.name] })
                  ) : (
                    <p
                      className={classes.cellsText}
                      style={{ fontWeight: props.detailsCell || index === 0 ? 600 : 'normal' }}
                    >
                      {props.detailsCell ? `${item.displayText}` : `${props.columnData[item.name]}`}
                    </p>
                  )}
                </div>
                {item?.expandColumnDataType == 'Array' && item?.isExpandable && showExpandButton?.[item.name] == true ? (
                  <div>
                    <ExpandMoreCell
                      onExpand={exp => {
                        setExpandableStates({
                          ...expandableStates,
                          [item.name]: {
                            expanded: exp,
                            maxheight: item.height,
                            cellClicked: exp ? props.columnData[item.name] : undefined,
                          },
                        });
                        setExpand(exp);
                        setLastExpandedCell(props.columnData[item.name]);
                        setLastExpandedCellIndex(index);
                        props?.onExpandClick(item, exp, props.columnData);
                        const expanded = exp
                          ? true
                          : props?.rowAttributes?.[item.name]?.cellClicked === props?.columnData[item.name]
                          ? false
                          : true;
                        const col = {
                          ...collapse,
                          [item.name]: expanded,
                        };

                        setCollapse(col);
                        setLastExpandedRow(item.name);
                      }}
                      rowName={item?.name}
                      collapseAll={props?.rowAttributes?.[item?.name]?.expanded === false}
                      className={classes.expandButton}
                    />
                  </div>
                ) : (
                  item?.expandColumn === undefined &&
                  item?.isExpandable &&
                  !props.detailsCell &&
                  showExpandButton?.[item.name] == true && (
                    <div>
                      <ExpandMoreCell
                        onExpand={exp => {
                          setExpandableStates({
                            ...expandableStates,
                            [item.name]: {
                              expanded: exp,
                              maxheight: item.height,
                              cellClicked: exp ? props.columnData[item.name] : undefined,
                            },
                          });
                          setExpand(exp);
                          setLastExpandedCell(props.columnData[item.name]);
                          setLastExpandedCellIndex(index);
                          props?.onExpandClick(item, exp, props.columnData);
                          const expanded = exp
                            ? true
                            : expandable?.[item.name]?.cellClicked === props?.columnData[item.name]
                            ? false
                            : true;
                          const col = {
                            ...collapse,
                            [item.name]: expanded,
                          };

                          setCollapse(col);
                          setLastExpandedRow(item.name);
                        }}
                        rowName={item?.name}
                        collapseAll={props?.rowAttributes?.[item?.name]?.expanded === false}
                        className={classes.expandButton}
                      />
                    </div>
                  )
                )}
              </div>
            </CustomTableCell>
          );
        })}
    </div>
  );
};

export default Column;
