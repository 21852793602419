export const tabs = [
    {
      index: 0,
      id: 'preferenceDetail',
      label: 'Preference Detail',
    },
    {
      index: 1,
      id: 'skillSets',
      label: 'Skill Sets',
    },
    {
      index: 2,
      id: 'location',
      label: 'Location',
    },
    {
      index: 5,
      id: 'shifts',
      label: 'Shifts',
    },
    {
      index: 6,
      id: 'orderStartDateRange',
      label: 'Order Start Date Range',
    },
    {
      index: 7,
      id: 'education',
      label: 'Education',
    },
    {
      index: 8,
      id: 'notes',
      label: 'Notes',
    },
  ];

  export const educationOptions = [
    {
      id: 0,
      name: 'Clinical Fellowship',
      value: 'clinicalFellowshipYear',
    },
    {
      id: 1,
      name: 'New Grad',
      value: 'newGrad',
    },
  ];

  export enum JobMatchFormFields {
    preferenceSetName = 'preferenceSetName',
    discipline = 'discipline',
    speciality = 'speciality',
    state = 'state',
    city = 'city',
    zip = 'zip',
    radius = 'radius',
    billrate = 'billRate',
    placementLength = 'placementLength',
    day = 'day',
    evening = 'evening',
    night = 'night',
    startDate = 'startDate',
    education = 'education',
    notes = 'notes',
    orderStartDateRange = 'orderStartDateRange',
    locations = 'locations',
    skillSets = 'skillSets',
    skillSetsArray = 'skillSetsArray',
    shifts = 'shifts'
  }