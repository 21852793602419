import React from 'react';

const HeaderCell = props => {
  const displayData = props.data;
  function isNumeric(text) {
    return /^\d+$/.test(text);
  }
  return (
    <div>
      <p style={{ fontWeight: 500,  marginLeft: 12 }}>
        {isNumeric(displayData) ? (
          <>
            {`OID: `}
            <span style={{ color: '#0D76BC' }}>{displayData}</span>
          </>
        ) : (
          <>{displayData}</>
        )}
      </p>
    </div>
  );
};

export default HeaderCell;
