import { makeStyles } from 'tss-react/mui';
import { ChannelType, ContactType } from 'app/components/Notification/Constants';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MediaQuery } from './MediaQuery';
import { useEnableEmailCommunication, useEnableVoiceCommunication } from 'oidc/CommunicationEnabler';

const useStyles = makeStyles()({
  headerRow: {
    verticalAlign: 'top',
    width: 'fit-content',
    '& [data-id="content"]': {
      whiteSpace: 'nowrap',
      display: 'inline-block',
    },
  },
  contentRow: {
    width: '100%',
    '& [data-id="content"]': {
      whiteSpace: 'break-spaces',
    },
  },
});

export const ResponsiveColumnContainer = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const notificationData = useSelector(notificationSelection);
  const enableEmailCommunication = useEnableEmailCommunication();
  const enableVoiceCommunication = useEnableVoiceCommunication();

  return (
    <>
      <MediaQuery minWidth={props.minWidth} maxWidth={props.maxWidth} exact={props.exact}>
        <tr
          className={!props.customAlign ? `${props.classes.alignContent}` : `${props.customAlign}`}
          data-testid="table-test-id"
          id={props.id}
        >
          <td
            colSpan={props.headColSpan || undefined}
            className={`${classes.headerRow} ${!props.customAlign ? props.classes.defaultAlign : props.customAlign}`}
          >
            <div
              data-id="content"
              className={
                !props.customRowHeadClass
                  ? `${props.classes.rowHead} ${props.classes.rowHeadDefaultColor}`
                  : `${props.classes.rowHead} ${props.customRowHeadClass}`
              }
            >
              {props.rowHead}
            </div>
          </td>
          {!props.headColSpan && (
            <td
              className={`${classes.contentRow} ${!props.customAlign ? props.classes.defaultAlign : props.customAlign}`}
            >
              {props.rowHead === t('placement.profile.header.email') &&
              props.handleNotificationData &&
              props.rowContent ? (
                enableEmailCommunication ? (
                  <div
                    onClick={() =>
                      !notificationData?.email?.open &&
                      props.handleNotificationData(ChannelType.email, ContactType.primary)
                    }
                    onKeyPress={() =>
                      !notificationData?.email?.open &&
                      props.handleNotificationData(ChannelType.email, ContactType.primary)
                    }
                    role="button"
                    tabIndex={-1}
                    data-id="content"
                    style={{ whiteSpace: !props.disableContentWrap ? 'break-spaces' : 'nowrap' }}
                    className={
                      !props.customRowContentClass
                        ? `${props.classes.rowContent} ${props.classes.rowContentDefaultColor}`
                        : `${props.classes.rowContent} ${props.customRowContentClass}`
                    }
                  >
                    {props.rowContent}
                  </div>
                ) : (
                  <div
                    tabIndex={-1}
                    data-id="content"
                    style={{
                      whiteSpace: !props.disableContentWrap ? 'break-spaces' : 'nowrap',
                      opacity: 0.5,
                      cursor: 'default',
                    }}
                    className={
                      !props.customRowContentClass
                        ? `${props.classes.rowContent} ${props.classes.rowContentDefaultColor}`
                        : `${props.classes.rowContent} ${props.customRowContentClass}`
                    }
                  >
                    {props.rowContent}
                  </div>
                )
              ) : props.rowHead === t('placement.profile.header.phoneNumber') &&
                props.handleNotificationData &&
                props.rowContent ? (
                enableVoiceCommunication ? (
                  <div
                    onClick={() =>
                      !notificationData?.voice?.open &&
                      props.handleNotificationData(ChannelType.voice, ContactType.primary)
                    }
                    onKeyPress={() =>
                      !notificationData?.voice?.open &&
                      props.handleNotificationData(ChannelType.voice, ContactType.primary)
                    }
                    role="button"
                    tabIndex={-1}
                    data-id="content"
                    style={{ whiteSpace: !props.disableContentWrap ? 'break-spaces' : 'nowrap' }}
                    className={
                      !props.customRowContentClass
                        ? `${props.classes.rowContent} ${props.classes.rowContentDefaultColor}`
                        : `${props.classes.rowContent} ${props.customRowContentClass}`
                    }
                  >
                    {props.rowContent}
                  </div>
                ) : (
                  <div
                    tabIndex={-1}
                    data-id="content"
                    style={{
                      whiteSpace: !props.disableContentWrap ? 'break-spaces' : 'nowrap',
                      opacity: 0.5,
                      cursor: 'default',
                    }}
                    className={
                      !props.customRowContentClass
                        ? `${props.classes.rowContent} ${props.classes.rowContentDefaultColor}`
                        : `${props.classes.rowContent} ${props.customRowContentClass}`
                    }
                  >
                    {props.rowContent}
                  </div>
                )
              ) : (
                <div
                  data-id="content"
                  style={{ whiteSpace: !props.disableContentWrap ? 'break-spaces' : 'nowrap' }}
                  className={
                    !props.customRowContentClass
                      ? `${props.classes.rowContent} ${props.classes.rowContentDefaultColor}`
                      : `${props.classes.rowContent} ${props.customRowContentClass}`
                  }
                >
                  {props.rowContent}
                </div>
              )}
            </td>
          )}
        </tr>
      </MediaQuery>
    </>
  );
};
