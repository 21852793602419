import React, { useEffect, useState } from 'react';
import { Typography, Select, Button, Container, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';

const useRowStyles = makeStyles()(theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
    fontFamily: 'Roboto',
    color: '#333333',
    fontSize: '12px',
  },
  gridContainer: {
    maxWidth: '1800px',
  },
  filter: {
    display: 'inline-block',
  },
  filterTitle: {
    textAlign: 'left',
    marginBottom: '20px',
  },
  filterLabel: {
    marginLeft: '15px',
  },
  select: {
    width: '112px',
    height: '39px',
    margin: '10px 10px 10px 10px',
    backgroundColor: '#F7F7F7',
  },
  button: {
    margin: '5px 5px 10px 10px',
    width: '90px',
    height: '42px',
    fontSize: '12px',
  },
  dropdownStyle: {
    border: '1px solid #D1D1D1',
    boxShadow: 'none',
    borderRadius: 3,
    marginTop: '2.9em',
    minWidth: 100,
    maxHeight: 300,
  },
}));

export const CustomFilter = props => {
  const { t } = useTranslation();
  const { classes } = useRowStyles();
  const { columns, rows, onChange } = props;
  const [columnData] = useState(columns);
  const [filters, setFilters] = useState({});
  const [filterData, setFilterData] = useState({});
  const handleFilter = event => {
    const data = { ...filterData };
    data[event.target.name] = event.target.value;
    if (event.target.value === 'All') {
      delete data[event.target.name];
    }
    setFilterData(data);
  };

  const filterRows = () => {
    let finalData: string[] = [...rows];
    for (var filterKey in filterData) {
      finalData = finalData.filter(data => data[filterKey] !== null);
      finalData = finalData.filter(function (data) {
        return data[filterKey].includes(filterData[filterKey]);
      });
    }
    return finalData;
  };

  const handleFilterData = () => {
    let checkForAll = false;
    for (const value of Object.values(filterData)) {
      if (value === 'All') {
        checkForAll = true;
      }
    }
    const filteredArray = checkForAll ? rows : filterRows();
    onChange(filteredArray);
  };

  const handleClearFilter = () => {
    setFilterData({});
    document.querySelectorAll('.filteredSelect div').forEach(ctrl => (ctrl.textContent = 'All'));
    onChange(rows, true);
  };
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const filterCreation = () => {
    var options: string[] = [];
    const checkFilters = {};
    const addNewDataToCollection = (collection: string[], item: string) => {
      if (!collection.includes(item)) {
        collection.push(item);
      }
    };
    options.push('All');
    columns.forEach(column => {
      const columnName = column.title;
      if (column.filterable) {
        rows.forEach(row => {
          if (row[columnName] != null) {
            if (column.multiLine) {
              row[columnName].map(val => {
                addNewDataToCollection(options, val);
                return null;
              });
            } else {
              addNewDataToCollection(options, row[columnName]);
            }
          }
        });
      }
      checkFilters[columnName] = options;
      options = [];
      options.push('All');
    });
    setFilters(checkFilters);
  };

  useEffect(() => {
    filterCreation();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);
  return (
    <div className={classes.root}>
      <Container className={classes.gridContainer}>
        <span>
          {
            <div>
              <Typography variant={'subtitle1'} className={classes.filterTitle}>
                {t('Filters')}
              </Typography>
              <div>
                <Grid container spacing={2} justifyContent={'center'} alignContent={'center'}>
                  <span>
                    {columnData.map(
                      (header, index) =>
                        header['filterable'] && (
                          <span className={classes.filter} key={index}>
                            <div className={classes.filterLabel}>
                              <Typography variant="body1">{header.value}</Typography>
                            </div>
                            <Select
                              color="primary"
                              variant="outlined"
                              id="default"
                              labelId="default-label"
                              defaultValue={'All'}
                              onChange={handleFilter}
                              className={`${classes.select} filteredSelect`}
                              name={header.title}
                              MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                            >
                              {filters[header.title] &&
                                filters[header.title].map((data, key) => (
                                  <option value={data} key={key}>
                                    {data}
                                  </option>
                                ))}
                            </Select>
                          </span>
                        ),
                    )}
                    <Button variant="contained" color="primary" onClick={handleFilterData} className={classes.button}>
                      {t('Apply')}
                    </Button>
                    <Button variant="contained" onClick={handleClearFilter} className={classes.button}>
                      {t('Clear')}
                    </Button>
                  </span>
                </Grid>
              </div>
            </div>
          }
        </span>
      </Container>
    </div>
  );
};
