import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid } from 'amn-ui-core';
import { useDispatch, useSelector } from 'react-redux';
import { ClientContactWrapper } from './ClientContactWrapper';
import { selectFacilityDetails } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { FacilityContactsActions } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.redux';

export const ClientContactsContainer = () => {
  const { control, getValues } = useFormContext();
  const facilityDetails = useSelector(selectFacilityDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    if (facilityDetails.facilityId !== undefined) {
      dispatch(
        FacilityContactsActions.requestFacilityClientContactsListForUnitEdit({
          id: facilityDetails.facilityId,
          unitContacts: getValues('unitContacts'),
        }),
      );
    }
  }, []);

  return (
    <Grid>
      <Controller
        control={control}
        name={'unitContacts'}
        render={({ ref, onChange, ...rest }) => <ClientContactWrapper onChange={onChange} value={rest.value} />}
      />
    </Grid>
  );
};
