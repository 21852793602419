export enum RangePickerAction {
  change,
  apply,
  cancel,
}

export enum R225ResponseFields {
  RecruiterName = 'recruiterName',
  BrandName = 'brandName',
  ChangeWeeksBooked = 'changeWeeksBooked',
  PlacementId = 'placementId',
}

export enum ReportTypes {
  Recruiters = 'Recruiters',
  Directors = 'Directors',
  Brand = 'Brands',
}

export enum LinkButtons {
  clearAll = 'clear All',
  selectAll = 'select All',
  save = 'save',
  reset = 'reset',
}

export enum WeekType {
  WeeksBooked = 'WeeksBooked',
  Extension = 'Extension',
  VoidedTimeOff = 'VoidedTimeOff',
  CxlByTraveler = 'CancelledByTraveler',
  CxlByFacility = 'CancelledByFacility',
  CxlByAMH = 'CancelledByAMH',
  SrdAssignment = 'ShortenedAssignment',
  ApprovedTimeOff = 'ApprovedTimeOff',
}

export enum WeeksCategory {
  Rebook = 'Rebook',
  Card = 'Card',
  Pending = 'Pending',
}

export enum SummaryTitle {
  Bookings = 'Bookings',
  Extensions = 'Extensions',
  VoidedTimeOff = 'Voided Time Off',
  CxlByTraveler = 'Cancelled By Traveler',
  CxlByFacility = 'Cancelled By Facility',
  CxlByAMH = 'Cancelled By AMH',
  SrdAssignment = 'Shortened Assignment',
  ApprovedTimeOff = 'Approved Time Off',
  NetWeeksBooked = 'Net Weeks Booked',
}
export interface ISummary {
  title: string;
  candidateTotal: string | number;
  candidatesPercentage: string | number;
  pendingTotal: string | number;
  pendingPercentage: string | number;
  rebookTotal: string | number;
  rebookPercentage: string | number;
  TotalWeeks: string | number;
}
