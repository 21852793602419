export interface PlacementDocuments {
  documents?: PlacementDocument[];
  submitPackets: IPlacementSubmitPacket[];
  selectedPacket?: IPlacementSubmitPacket;
  clientConfirmationDocs: IPlacementClientConfirmationDoc[];
  selectedConfirmationDoc?: IPlacementClientConfirmationDoc;
  miscellaneous: {
    currentSubmitPacketStep: SubmitPacketSteps;
    currentClientConfirmationStep: ClientConfirmationSteps;
    errorProps?: {
      error: boolean;
      severity: 'error' | 'warning';
      message: string;
    };
  };
  logId?: string;
  emailLogs?: any[];
  openEmailDeliveryStatus?: boolean;
}

export interface PlacementDocument {
  document: string;
  id: number;
  version: string;
  status: string;
  sent: Date;
  signed: Date;
  template: string;
  source: string;
  lastUpdated: Date;
  documentImageId: number | string;
}

export interface IPlacementSubmitPacket {
  documentId: string;
  template: string;
  version: number;
  status: string;
  createdDate: string;
  createdByUserId: number;
  createdBy: string;
  lastUpdatedDate: string;
  lastUpdatedByUserId: number;
  lastUpdatedBy: string;
  sentDate: string;
  sentByUserId: number;
  sentByUser: string;
}

export interface IPlacementClientConfirmationDoc {
  documentId: string;
  documentImageId: string;
  templateName: string;
  templateId: number;
  version: string;
  source: 'Classic' | 'Web';
  status: DocStatus;
  createdBy: number;
  createdByName: string;
  createdOn: string;
  updatedBy: number;
  updatedByName: string;
  updatedOn: string;
  sentBy: number;
  sentByName: string;
  sentDate: string;
}

export enum DocStatus {
  Draft = 'Draft',
  NotSent = 'Not Sent',
  Sent = 'Sent',
}

export enum SubmitPacketSteps {
  initial = 'submit_packet_initial',
  markAsSent = 'submit_packet_markAsSent',
  download = 'submit_packet_download',
  sendNow = 'submit_packet_sendNow',
  setToSendPostDownload = 'submit_packet_setToSendPostDownload',
}

export enum ClientConfirmationSteps {
  initial = 'client_confirmation_initial',
  download = 'client_confirmation_download',
  sendNow = 'client_confirmation_sendNow',
}

export enum PlacementDocumentType {
  candidatePacket = 'candidate packet',
  contract = 'candidate contract',
  clientConfirmation = 'client confirmation',
  credentialingPacket = 'credentialing packet',
  arbitrationAgreement = 'arbitration agreement',
}

export enum CandidatePacketStatus {
  sent = 'Sent',
  notSent = 'Not Sent',
  okToSend = 'OK to Send',
}

export enum ClientConfirmationStatus {
  sent = 'Sent',
  okToSend = 'OK to Send',
  pending = 'Pending',
  notSent = 'Not Sent',
}

export enum PlacementDocumentStatus {
  sent = 'Sent',
  //sentByTCC = 'Sent by TCC',
  //pending = 'Pending',
  notSent = 'Not Sent',
  signed = 'Signed',
}

export enum PlacementDocumentSource {
  classic = 'Classic',
  web = 'Web',
  amieWeb = 'AMIE Web',
}

export enum ConfirmationLetterTemplates {
  FEMA = 'FEMA Confirmation Letter',
  Standard = 'Standard Confirmation Letter',
}

export enum ConfirmationLetterSubjectPrefix {
  FEMA = 'FEMA Region # -',
  Standard = 'AMN Healthcare Confirmation for -',
}

export enum KeyContactsDescription {
  SendConfirmationTo = 'Send Confirmation To',
}
export interface ISelectedPacketData {
  blobUrl: string | null;
  fileName: string | null;
  fileType: string | null;
}

export enum PlacementDocumentFileNamePrefix {
  submission = 'AMN_Submission_',
  confirmation = 'AMN_Confirmation_',
}
