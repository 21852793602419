/* eslint-disable i18next/no-literal-string */
import React, { useContext } from 'react';
import { Expandable } from 'app/components/Common/Expandable/Expandable';
import { IconTitle } from './Titles/IconTitle';
import StarIcon from 'app/assets/images/IconImages/StarIcon.svg';
import { CandidateSellingPoints } from 'app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/CandidateSellingPoints';
import { useDispatch, useSelector } from 'react-redux';
import { selectPlacementCandidateRecords } from 'store/redux-store/placement-details/selectors';
import { AddOnContext } from './PlacementSummaryTab';
import { placementDetailsAction } from 'store/redux-store/placement-details/slice';
import { usePromiseTracker } from 'react-promise-tracker';

export const SellingPoints = ({ ...props }) => {

  const { placementId } = useContext(AddOnContext);
  const dispatch = useDispatch();
  const { promiseInProgress } = usePromiseTracker({ area: 'placement-selling-points-details', delay: 0 });
  const { 
    isCandidateSellingPointCountsLoaded 
  } = useSelector(selectPlacementCandidateRecords);

    // Function to handle fetching data
    const handleExpand = React.useCallback((isExpanding) => {
      if (isExpanding && !(isCandidateSellingPointCountsLoaded ?? false)) {
        dispatch(
          placementDetailsAction.getPlacementSellingPointsDetailsAction({ 
            placementId, 
            isInitLoad: false  
          })
        )
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dispatch, isCandidateSellingPointCountsLoaded]);
  

  return (
    <Expandable
      id="placement-summary-selling-points"
      expanded={false}
      loading={promiseInProgress}
      titleComponent={<IconTitle title="Selling Points" icon={<img src={StarIcon} alt="Star" />} />}
      onChange={handleExpand}
    >

      <CandidateSellingPoints />
    </Expandable>
  );
};
