/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable i18next/no-literal-string */
import { ErrorMessage } from '@hookform/error-message';
import { Grid, IconButton, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { GetMomentOfDate } from 'utils/dates/moment';
import { FormInputCheckbox } from './Controls/FormInputCheckbox';
import { FormInputDatePicker } from './Controls/FormInputDatePicker';
import { dates } from './PersonalInfoHelper';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(theme => ({
  sectionWrapper: {
    paddingLeft: 6,
  },
  sectionTitle: {
    paddingTop: 12,
    paddingLeft: 7,
  },
  checkBoxGroup: {
    paddingLeft: 52,
    paddingTop: 5,
    width: 368,
  },
  startDate: {
    maxWidth: 205,
    paddingTop: 8,
    paddingBottom: 20,
  },
  endDate: {
    maxWidth: 205,
  },
  checkBox: {
    fontSize: 14,
    paddingLeft: 8,
    color: '#333333',
    paddingBottom: 15,
  },
  divider: {
    margin: '10px 0px 0px 0px',
  },
  icon: {
    color: '#888888',
    cursor: 'pointer',
    '&:hover': {
      // cursor: 'not-allowed',
    },
  },
  iconButton: {
    background: 'none !important',
  },
}));

export const AddRoleTypeSection = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { errors, getValues, setValue, setError, clearErrors, control, formState } = useFormContext();
  const [fromDate, setFromDate] = useState<any>(props.data?.fromDate);
  const [toDate, setToDate] = useState<any>(props.data?.toDate);

  useEffect(() => {
    setValue(`Start${props.index}`, props.data?.fromDate);
    if (props.data?.toDate) {
      setValue(`End${props.index}`, props.data?.toDate);
    }
  }, []);

  const handleStartDate = async event => {
    props.setIndexValue(props.index);
    if (event && event !== '') {
      setFromDate(GetMomentOfDate(event).format('MM/DD/YYYY'));
      const currentDate = GetMomentOfDate(new Date()).format('MM/DD/YYYY');
      const startDate = GetMomentOfDate(event).format('MM/DD/YYYY');
      const moveInDate = GetMomentOfDate(props.moveInDate ?? '').format('MM/DD/YYYY');
      const endDate = GetMomentOfDate(getValues(`End${props.index}`) ?? '').format('MM/DD/YYYY');
      const moveOutDate = GetMomentOfDate(props.moveOutDate ?? '').format('MM/DD/YYYY');

      const res = dates.gt(startDate, currentDate);
      const gt = dates.gt(startDate, moveInDate);
      const eq = dates.eq(startDate, moveInDate);

      setValue(`Start${props.index}`, startDate);
      //Start Date Errors

      if (!res) {
        setError(`Start${props.index}`, { type: 'manual', message: t('candidate.profileInformation.startDateError') });
      } else if (!(gt || eq)) {
        setError(`Start${props.index}`, {
          type: 'manual',
          message: t('candidate.profileInformation.StartDateError'),
        });
      } else {
        if ((gt || eq) && res) clearErrors(`Start${props.index}`);
      }

      //End Date Errors
      if (endDate && dates.gt(startDate, endDate)) {
        setError(`End${props.index}`, { type: 'manual', message: t('candidate.profileInformation.EndDateError') });
      } else if (endDate && dates.lt(startDate, endDate) && dates.lt(endDate, moveOutDate)) {
        clearErrors(`End${props.index}`);
      }
    }
  };

  const handleEndDate = async event => {
    props.setIndexValue(props.index);
    if (event && event !== '') {
      setToDate(GetMomentOfDate(event).format('MM/DD/YYYY'));
      const startDate = GetMomentOfDate(getValues(`Start${props.index}`)).format('MM/DD/YYYY');
      const endDate = GetMomentOfDate(event).format('MM/DD/YYYY');
      const currentDate = GetMomentOfDate(new Date()).format('MM/DD/YYYY');

      const res = dates.gt(endDate, startDate);
      const validateEndDate = dates.lt(endDate, currentDate);

      setValue(`End${props.index}`, endDate);

      if (validateEndDate) {
        setError(`End${props.index}`, { type: 'manual', message: t('candidate.profileInformation.startDateError') });
      } else {
        if (!res)
          setError(`End${props.index}`, { type: 'manual', message: t('candidate.profileInformation.EndDateError') });
      }

      if (props.moveOutDate) {
        const moveOutDate = GetMomentOfDate(props.moveOutDate ?? '').format('MM/DD/YYYY');
        const gt = dates.gt(endDate, moveOutDate);
        if (gt)
          setError(`End${props.index}`, {
            type: 'manual',
            message: t('candidate.profileInformation.EndDateError2'),
          });
        if ((!gt || dates.eq(endDate, moveOutDate)) && res) clearErrors(`End${props.index}`);
      } else {
        if (res) clearErrors(`End${props.index}`);
      }
    }
  };

  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Typography variant="subtitle2" gutterBottom component="div" className={classes.sectionTitle}>
            {props.title}
          </Typography>
        </Grid>
        <Grid item style={{ padding: '10px' }} onClick={() => props.onDelete?.(props.newRoleID)}>
          <IconButton color="primary" disabled={!props.isNewRole} className={classes.iconButton}>
            <DeleteIcon className={classes.icon} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container className={classes.sectionWrapper}>
        <Grid item>
          <div className={classes.startDate}>
            <FormInputDatePicker
              name={`Start${props.index}`}
              label="Start Date*"
              handleChange={handleStartDate}
              require={true}
              inputValue={fromDate}
              readOnly={props.isDisabled || !props.roleTypeAccess?.startDate}
            ></FormInputDatePicker>
          </div>
          <div className={classes.endDate}>
            <FormInputDatePicker
              name={`End${props.index}`}
              label={props?.require ? 'End Date *' : 'End Date'}
              handleChange={handleEndDate}
              require={props?.require}
              inputValue={toDate}
              readOnly={props.isDisabled || !props.roleTypeAccess?.endDate}
            ></FormInputDatePicker>
          </div>
        </Grid>
        <Grid item>
          <Grid container className={classes.checkBoxGroup}>
            <Grid item xs={5}>
              <FormInputCheckbox
                name={`W2${props.index}`}
                isChecked={props.data?.addressRoles[5].selected}
                handleChecked={props.handleCheck}
                checkboxLabel={`W2`}
                isDisabled={props.isDisabled || !props.roleTypeAccess?.w2}
                labelStyle={classes.checkBox}
                checkboxErrorName={`CheckboxError${props.index}`}
              ></FormInputCheckbox>
              <FormInputCheckbox
                name={`Paycheck${props.index}`}
                isChecked={props.data?.addressRoles[3].selected}
                handleChecked={props.handleCheck}
                checkboxLabel={`Paycheck`}
                labelStyle={classes.checkBox}
                isDisabled={props.isDisabled || !props.roleTypeAccess?.paycheck}
                checkboxErrorName={`CheckboxError${props.index}`}
              ></FormInputCheckbox>
              <FormInputCheckbox
                name={`Subsidy${props.index}`}
                isChecked={props.data?.addressRoles[4].selected}
                handleChecked={props.handleCheck}
                checkboxLabel={`Subsidy`}
                isDisabled={props.isDisabled || !props.roleTypeAccess?.subsidy}
                labelStyle={classes.checkBox}
                checkboxErrorName={`CheckboxError${props.index}`}
              ></FormInputCheckbox>
            </Grid>
            <Grid item xs={6}>
              <FormInputCheckbox
                name={`Mailers${props.index}`}
                isChecked={props.data?.addressRoles[2].selected}
                handleChecked={props.handleCheck}
                checkboxLabel={`Mailers`}
                isDisabled={props.isDisabled || !props.roleTypeAccess?.mailers}
                labelStyle={classes.checkBox}
                checkboxErrorName={`CheckboxError${props.index}`}
              ></FormInputCheckbox>
              <FormInputCheckbox
                name={`Cobra${props.index}`}
                isChecked={props.data?.addressRoles[0].selected}
                handleChecked={props.handleCheck}
                checkboxLabel={`Cobra`}
                isDisabled={props.isDisabled || !props.roleTypeAccess?.cobra}
                labelStyle={classes.checkBox}
                checkboxErrorName={`CheckboxError${props.index}`}
              ></FormInputCheckbox>
              <FormInputCheckbox
                name={`DependentCobra${props.index}`}
                isChecked={props.data?.addressRoles[1].selected}
                handleChecked={props.handleCheck}
                checkboxLabel={`Dependent Cobra`}
                labelStyle={classes.checkBox}
                isDisabled={props.isDisabled || !props.roleTypeAccess?.dependentCobra}
                checkboxErrorName={`CheckboxError${props.index}`}
              ></FormInputCheckbox>
            </Grid>
            <ErrorMessage
              errors={errors}
              name={`CheckboxError${props.index}`}
              render={({ message }) => (
                <span style={{ color: '#f44336', fontSize: '0.75rem', marginLeft: '0px', marginTop: '-8px' }}>
                  {message}
                </span>
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
