import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import styledComponent from 'styled-components';

export const Map = props => {
  const [isMapLoaded, setMapLoaded] = useState<boolean>(false);

  const getMapBounds = (map, maps, locations) => {
    const bounds = new maps.LatLngBounds();
    // ternary operator chooses locations data from app
    // if arg locations returns undefined
    locations = locations ? locations : props.locations;
    locations.forEach(location => {
      bounds.extend(new maps.LatLng(location.latitude, location.longitude));
    });
    return bounds;
  };

  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  };

  const apiIsLoaded = (map, maps, locations) => {
    if (map) {
      props.onMapIsLoaded && props.onMapIsLoaded({ map, maps });
      setMapLoaded(true);
      const bounds = getMapBounds(map, maps, locations);
      map.fitBounds(bounds);
      bindResizeListener(map, maps, bounds);
    }
  };

  return (
    <Container>
      <GoogleMapReact
        bootstrapURLKeys={{ key: globalThis?.app?.env?.REACT_APP_GOOGLE_API_KEY as string }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, props.locations)}
        {...props}
      >
        {isMapLoaded && props.children}
      </GoogleMapReact>
    </Container>
  );
};

const Container = styledComponent.div`
  height: 700px;
`;
