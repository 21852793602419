import { UnitCard } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/CustomComponents/UnitCard';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from 'amn-ui-core';
import { isAuthorizedForAddEdit, useSelectedUnitSkillSetGroups, useUnitStatusInActive } from './unitHelper';
import SkillSetTitle from './Experience/SkillSetTitle';
import { missingField } from 'app/constants';
import SkillSetQualifications from './Experience/SkillSetQualifications';
import { AndOrOperatorSymbolToText } from 'app/enums/Common';
import { IGroup } from '@AMIEWEB/Facility/FacilityStore/Facility.model';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { usePromiseTracker } from 'react-promise-tracker';
import { ExperienceSkeleton } from './ExerienceSkeletonComponent';
import { useStylesExperience } from './Unit.styles';

const Experiences = () => {
  const { t } = useTranslation();
  const { classes } = useStylesExperience();
  const userData = useSelector(selectUser);
  const isAuthorized = isAuthorizedForAddEdit(userData);
  const skillSetGroups: IGroup[] = useSelectedUnitSkillSetGroups();
  const isUnitInActive = useUnitStatusInActive();
  const { promiseInProgress: isSkillSetDataLoading } = usePromiseTracker({
    area: 'get-skillset-for-unit',
    delay: 0,
  });
  return (
    <UnitCard
      title={t('facility.units.experience.title', { expCount: skillSetGroups[0]?.skillsets?.length ?? 0 })}
      rightIcon={true}
      showEditIcon={isAuthorized && !isUnitInActive}
      showTitleBarBorder={false}
      skeletonWidth={104}
      isDataLoading={isSkillSetDataLoading}
      children={
        <>
          {isSkillSetDataLoading ? (
            <ExperienceSkeleton />
          ) : (
            <>
              {skillSetGroups?.map((group, index) =>
                group?.skillsets?.length ? (
                  group?.skillsets?.map((skillSetData, skillSetIndex) => (
                    <>
                      <Grid
                        className={classes.boxBorder}
                        key={index}
                        style={{
                          marginBottom: group?.skillsets?.length - 1 === skillSetIndex ? '0.75rem' : '0.2rem',
                        }}
                      >
                        <Grid
                          className={classes.titleBox}
                          p={2}
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          item
                        >
                          {skillSetData?.skillSet ? (
                            <SkillSetTitle skillSetData={skillSetData?.skillSet} isPrimary={skillSetData?.isPrimary} />
                          ) : (
                            <Box key={skillSetIndex}>{missingField}</Box>
                          )}
                        </Grid>
                        <SkillSetQualifications skillSetData={skillSetData} />
                      </Grid>
                      {group?.skillsets.length - 1 !== skillSetIndex && group?.operation && (
                        <Typography className={classes.operationText}>
                          {AndOrOperatorSymbolToText[group?.operation]}
                        </Typography>
                      )}
                    </>
                  ))
                ) : (
                  <Box key={index}>{missingField}</Box>
                ),
              )}
            </>
          )}
        </>
      }
    />
  );
};

export default Experiences;
