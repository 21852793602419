import React, { useRef } from 'react';
import { Grid, IconButton, InputAdornment, TextField, Typography } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';
import { AddDateTimeIcon } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/SummaryTab/Audit/Helpers/AddDateTimeButton';
import moment from 'moment';
import { CustomTooltip } from '@AMIEWEB/Common';
import { useUnitDetailsStyles } from './Styles';

export const UnitDetailsTextArea = ({
  id,
  fieldName,
  fieldLabel,
  onChange,
  isDisabled,
  inputValue,
  defaultInputValue,
  customHelperText = <></>,
  showMaxCount = false,
  maxCharacters,
  addTimeStamp = false,
}: {
  id: string;
  fieldName: string;
  fieldLabel: string;
  onChange: (e) => void;
  isDisabled: boolean;
  inputValue: string;
  defaultInputValue: string;
  customHelperText?: React.ReactElement;
  showMaxCount?: boolean;
  maxCharacters?: number;
  addTimeStamp?: boolean;
}) => {
  const { classes } = useUnitDetailsStyles({ isError: inputValue?.length === maxCharacters });
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const handleAddTimeStamp = event => {
    if (inputRef && inputRef.current) {
      const value = inputRef.current.value;
      const [start, end] = [inputRef.current.selectionStart, inputRef.current.selectionEnd];
      const timeStamp = moment().format('MM/DD/YYYY hh:mm:ss A');
      inputRef.current.value = null;

      const newValue = value.substring(0, start) + timeStamp + value.substring(end, value.length);
      inputRef.current.value = newValue;
      onChange?.(newValue);
      event.preventDefault();
    }
  };
  return (
    <div style={{ height: '170px' }}>
      <TextField
        className={classes.textField}
        name={fieldName}
        multiline
        fullWidth={true}
        rows={5}
        id={id}
        defaultValue={defaultInputValue}
        variant="filled"
        color="primary"
        autoComplete="off"
        label={fieldLabel}
        onChange={e => {
          onChange(e.target.value);
        }}
        disabled={isDisabled}
        value={inputValue}
        inputProps={{ maxLength: maxCharacters }}
        inputRef={inputRef}
        InputProps={{
          endAdornment: addTimeStamp && (
            <InputAdornment position="end" onClick={handleAddTimeStamp}>
              <IconButton
                color="primary"
                sx={{ position: 'absolute', top: 0, right: 0 }}
                type="button"
                className={`${classes.addDateTimeBtn} ${classes.transparentButton}`}
              >
                <CustomTooltip tooltipContent="Add Date & Time">
                  <AddDateTimeIcon viewBox="0 0 30 30" />
                </CustomTooltip>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {customHelperText && (
        <Grid container item className={classes.helperTextSection}>
          <Grid item xs={4} className={classes.leftHelperArea}>
            {showMaxCount && inputValue?.length === maxCharacters && (
              <Typography variant="body2" color={theme => theme.palette.system.guardsmanRed} component="span">
                {t(`global.textValidations.maxCharacterLimit`)}
              </Typography>
            )}
            {customHelperText}
          </Grid>
          {showMaxCount && (
            <Typography component="span" className={classes.maxCount} variant="body2">{`${
              inputValue?.length ?? 0
            }/${maxCharacters}`}</Typography>
          )}
        </Grid>
      )}
    </div>
  );
};
