import { FilterType } from 'app/enums/Common';
import moment from 'moment';
import { deepEqual } from 'utils/common/comparison';

/**
 * @param {any[]} arr
 * @param {key} keys
 */
export const getUnique = (arr, index) => {
  const unique = arr
    .map(e => e[index])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter(e => arr[e])
    .map(e => arr[e]);
  return unique;
};

/**
 * @param {any[]} arr
 * @param {T[]} keys
 */
export const groupBy = <T>(arr: any[], keys: T[], sortBy?: { item: T; type: 'shortDate' | 'longDate' | 'any' }[]) => {
  return (
    arr?.reduce((storage, item) => {
      const objKey = keys.map(key => `${item[key]}`).join(':'); //should be some unique delimiter that wont appear in your keys
      if (storage[objKey]) {
        storage[objKey].push(item);
        if (sortBy !== undefined)
          storage[objKey] = storage[objKey].sort((a, b) => {
            for (const sort of sortBy) {
              switch (sort.type) {
                case 'shortDate':
                  if (
                    moment(a[sort.item] || '1970-01-01').format('yyyy-MM-DD') <
                    moment(b[sort.item] || '1970-01-01').format('yyyy-MM-DD')
                  )
                    return 1;
                  if (
                    moment(a[sort.item] || '1970-01-01').format('yyyy-MM-DD') >
                    moment(b[sort.item] || '1970-01-01').format('yyyy-MM-DD')
                  )
                    return -1;
                  break;
                case 'longDate':
                  if (moment(a[sort.item]) < moment(b[sort.item])) return 1;
                  if (moment(a[sort.item]) > moment(b[sort.item])) return -1;
                  break;
                case 'any':
                  if (a[sort.item] < b[sort.item]) return 1;
                  if (a[sort.item] > b[sort.item]) return -1;
                  break;
                default:
                  break;
              }
            }
            return 0;
          });
      } else {
        storage[objKey] = [item];
      }
      return storage;
    }, {}) ?? []
  );
};

/**
 * @param {any[]} arr
 * @param {T[]} keys
 */
export const credentialingGroupBy = <T>(arr: any[], keys: any[], sortBy?: T[], keyCreator?: Function) => {
  return arr.reduce((storage, item) => {
    const objKey = (keyCreator ? keyCreator(keys, item) : keys.map(key => `${item[key.apiKey]}`)).join(':'); //should be some unique delimiter that wont appear in your keys
    if (storage[objKey]) {
      storage[objKey].push(item);
      if (sortBy !== undefined)
        storage[objKey] = storage[objKey].sort((a, b) => {
          for (const sort of sortBy) {
            if (a[sort] < b[sort]) return 1;
            if (a[sort] > b[sort]) return -1;
          }
          return 0;
        });
    } else {
      storage[objKey] = [item];
    }
    return storage;
  }, {});
};

export const simpleGroupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {},
  );

export const nestedChildren = grouped => {
  const items = Object.values<any[]>(grouped);
  const reduced = (items as any[]).reduce((storage, item) => {
    const newItem = { ...item[0] };
    if (item.length >= 1) {
      newItem['children'] = item.slice(1, item.length);
    }
    storage.push(newItem);
    return storage;
  }, []);
  return reduced;
};

export const nestedChildrenSorting = <T>(
  arr: any[],
  sortBy?: { item: T; type: 'shortDate' | 'longDate' | 'any' }[],
) => {
  return arr.reduce((storage, item) => {
    if (sortBy !== undefined)
      storage = arr.sort((a, b) => {
        for (const sort of sortBy) {
          switch (sort.type) {
            case 'shortDate':
              if (
                moment(a[sort.item] || '1970-01-01').format('yyyy-MM-DD') <
                moment(b[sort.item] || '1970-01-01').format('yyyy-MM-DD')
              )
                return 1;
              if (
                moment(a[sort.item] || '1970-01-01').format('yyyy-MM-DD') >
                moment(b[sort.item] || '1970-01-01').format('yyyy-MM-DD')
              )
                return -1;
              break;
            case 'longDate':
              if (
                moment(a[sort.item] || '1970-01-01 00:00:00:00').format('yyyy-MM-DD HH:mm:ss a') <
                moment(b[sort.item] || '1970-01-01 00:00:00:00').format('yyyy-MM-DD HH:mm:ss a')
              )
                return 1;
              if (
                moment(a[sort.item] || '1970-01-01 00:00:00:00').format('yyyy-MM-DD HH:mm:ss a') >
                moment(b[sort.item] || '1970-01-01 00:00:00:00').format('yyyy-MM-DD HH:mm:ss a')
              )
                return -1;
              break;
            case 'any':
              if (a[sort.item] < b[sort.item]) return 1;
              if (a[sort.item] > b[sort.item]) return -1;
              break;
            default:
              break;
          }
        }
        return 0;
      });
    return storage;
  }, []);
};

/**
 * Insert a value into the given array, where only 1 of that object will exist.
 *
 * Uses deepEqual for all child values within the object
 * @param array Array to insert the value in
 * @param value Value to insert in the array
 * @returns New array with only 1 unique entry of the given value
 */
export const insertUniqueValueInArray = (array, value): any[] => {
  const currentValue = [...(array || [])];
  let foundIndex;
  do {
    foundIndex = -1;
    // eslint-disable-next-line no-loop-func
    currentValue.forEach((item, index) => {
      if (deepEqual(item, value)) foundIndex = index;
    });
    if (foundIndex !== -1) currentValue.splice(foundIndex, 1);
  } while (foundIndex !== -1);
  return [value, ...currentValue];
};

export function removeDuplicates(array1: any[], array2: any[]) {
  var newArray = array1.concat(array2);

  for (var i = 0; i < newArray.length; ++i) {
    for (var j = i + 1; j < newArray.length; ++j) {
      if (newArray[i] === newArray[j]) newArray.splice(j--, 1);
    }
  }

  return newArray;
}

export const getSortedData = (data: any[], choice: FilterType) => {
  const array = data;
  switch (choice) {
    case FilterType.string:
      array.sort(function (a, b) {
        var itemA = a.name.toLowerCase(),
          itemB = b.name.toLowerCase();
        //sort string ascending
        if (itemA < itemB) return -1;
        if (itemA > itemB) return 1;
        return 0; //default return value (no sorting)
      });
      return array;

    case FilterType.numeric:
      array.sort(function (a, b) {
        var itemA = parseInt(a.name),
          itemB = parseInt(b.name);
        //sort string ascending
        if (itemA < itemB) return -1;
        if (itemA > itemB) return 1;
        return 0; //default return value (no sorting)
      });
      return array;
  }
};

export const getDistinctArray = (array1: any[], array2: any[]) => {
  const arr = [...array1, ...array2];
  const array = [];
  for (let i = 0; i < arr?.length; i++) {
    if (!array.includes(arr[i])) {
      array.push(arr[i]);
    }
  }
  array.sort();
  return array;
};

export const getDynamicSortedData = (data: any[], sortBy: string) => {
  const array = data;
  array.sort(function (a, b) {
    var itemA = a[sortBy].toLowerCase(),
      itemB = b[sortBy].toLowerCase();
    //sort string ascending
    if (itemA < itemB) return -1;
    if (itemA > itemB) return 1;
    return 0; //default return value (no sorting)
  });
  return array;
};

export function generateExpandCollapseArray(inputData) {
  const result = [];

  function traverse(nodes, parentPath = []) {
    nodes.forEach(node => {
      const currentPath = [...parentPath, { value: node.value, name: node.name }];

      if (node.contain) {
        // Add all parent nodes' keys to the result
        currentPath.slice(0, -1).forEach(parentNode => {
          const key = `${parentNode.value}-${parentNode.name}`;
          if (!result.includes(key)) {
            result.push(key);
          }
        });
      }

      if (Array.isArray(node.children) && node.children.length > 0) {
        traverse(node.children, currentPath);
      }
    });
  }

  traverse(inputData);
  return result;
}

export function addRandomSuffixToValues(data) {
  const traverseAndModify = nodes => {
    nodes.forEach(node => {
      node.value = `${node.value}-${crypto.randomUUID().slice(0, 8)}`;
      if (node.children) {
        traverseAndModify(node.children);
      }
    });
  };
  traverseAndModify(data);
  return data;
}

export function addContainFlag(data, searchKey) {
  const searchKeyLower = searchKey.toLowerCase();

  const traverse = nodes => {
    nodes.forEach(node => {
      if (node.name.toLowerCase().includes(searchKeyLower)) {
        node.contain = true;
      } else {
        node.contain = false;
      }

      if (Array.isArray(node.children) && node.children.length > 0) {
        traverse(node.children);
      }
    });
  };

  traverse(data);
  return data;
}