import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { extensionTimeOffCheck } from './EditablePassFailLogic';
import React from 'react';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import { useSelector } from 'react-redux';
import { useEditableExtensionTimeOffRequirement } from './EditExtensionTimeOffRequirementsHelper';

export const useEditableRequirementExtensionTimeOff = (timeOffRequestReason, extensionNumber) => {
  const placementOrderType = useSelector(selectPlacementOrderType);
  const secondaryData = React.useMemo(() => ({ placementOrderType: placementOrderType }), [placementOrderType]);

  useEditableExtensionTimeOffRequirement({
    data: timeOffRequestReason,
    secondaryData: secondaryData,
    requirementId: IPlacementRequirementKey.extensionTimeOff,
    requirementKey: 'extensions',
    requirementCheck: extensionTimeOffCheck,

    riskRequirement: {
      id: IPlacementRequirementKey.extensionTimeOff,
      message: `Extension ${extensionNumber + 1}/Time Off: To Be Determined.`,
      tooltip: {
        message: 'Please add the Time Off dates or indicate that Time Off is Not Required.',
      },
    },
    extensionId: extensionNumber,
  });
};
