/* eslint-disable tss-unused-classes/unused-classes */
import React from 'react';
import styledComponent from 'styled-components';
import { makeStyles } from 'tss-react/mui';

export const RowContainer = props => (
  <table style={{ width: '100%' }}>
    <tbody style={{ width: '100%' }}>{props.children}</tbody>
  </table>
);

export const Row = styledComponent.tr`
  display: flex;
  justify-content: space-between;
`;

export const RowHead = styledComponent.th`
  color: #333333;
  font-size: 14px;
  text-align: left;
  font-weight: 400;
`;

export const RowContent = styledComponent.td`
  color: #333333;
  font-size: 14px;
  text-align: left;
  font-weight: 600;
`;

export const RowData = styledComponent.th`
  color: #333333;
  font-size: 12px;
  text-align: left;
  font-weight: 400;
  @media (min-width: 1370px) {
    font-size: 14px;
    white-space: nowrap;
  }
  @media (min-width: 1260px) and (max-width: 1370px) {
      font-size: 12px;
      white-space: nowrap;
  }
  @media (min-width: 1170px) and (max-width: 1260px) {
    font-size:11px;
  }
  @media (min-width: 800px) and (max-width: 1170px) { 
    font-size: 8.5px;
  }
  @media (max-width: 800px) { 
    font-size: 8.5px;
  }
`;

export const useStyles = makeStyles<{ expanded: boolean }>()((theme, { expanded }) => ({
  dialog: {
    minWidth: '960px',
    '& .MuiDialog-paperWidthSm': {
      minWidth: '900px',
      maxWidth: '100%',
    },
  },
  content: {
    height: '453px',
    overflowX: 'hidden',
    width: '100%',
  },
  indicator: {
    width: '5px',
    left: '0px',
    background: theme.palette.framework.system.violet,
  },
  filterTabs: {
    background: theme.palette.framework.system.whisper,
    height: !expanded ? '100%' : '453px',
    width: '225px',
    minWidth: '225px',
  },
  tab: {
    marginLeft: '10px',
    textTransform: 'none',
    color: theme.palette.framework.system.doveGray,
    alignItems: 'flex-start !important',
    justifyContent: 'flex-start',
    '& .MuiTab-wrapper': {
      alignItems: 'flex-start !important',
      justifyContent: 'flex-start',
    },
    '&.Mui-selected': {
      color: theme.palette.framework.system.violet,
    },
    textAlign: 'start',
    textWrap: 'wrap',
    height: 'auto',
  },
  container: {
    height: !expanded ? '100%' : '453px',
    width: '100%',
    display: 'flex',
  },
  form: {
    padding: '24px 24px 0px 24px',
  },
  dialogueContent: {
    padding: 0,
    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .dialogContentRoot': {
      padding: 0,
    },
    overflow: 'hidden',
  },
  fullScreenContainer: {
    height: '100%',
    overflow: 'hidden',
    overflowY: 'scroll',
    width: '100%',
  },
  filterTabsExpanded: {
    background: theme.palette.framework.system.whisper,
    width: '225px',
    minWidth: '225px',
  },
  notes: {
    backgroundColor: theme.palette.system.white,
    width: '100%',
    '& .MuiInputBase-root': {
      width: '100%',
      height: 'auto',
      display: 'inline-flex',
      flexDirection: 'column',
      margin: '2px',
      paddingTop: '16px',
    },
    '& .MuiOutlinedInput-root': {
      margin: '2px',
      position: 'initial',
    },
    '& label': {
      '&.Mui-focused': {
        color: theme.palette.system.dimGrey2,
      },
    },
  },
}));
