import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state?.reportsView || initialState;

export const selectInputDetails = createSelector([selectDomain], reports => reports.inputDetails);
export const selectR225ReportDetails = createSelector([selectDomain], reports => reports.r225Report);
export const selectR448ReportDetails = createSelector([selectDomain], reports => reports.r448Report);


