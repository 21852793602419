import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import {
  AppBar,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Toolbar,
  Typography,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { CustomGrid } from 'app/components/Common/Grid/Grid';
import { tooltipType } from 'app/components/Common/Tooltips';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { missingDate, missingField } from 'app/constants';
import { PlacementInfo } from 'app/models/Candidate/CandidateProfile';
import { IUpdateConversationBrandPayload } from 'app/models/Notification/Notification';
import { PagingData } from 'app/models/PagingData';
import {
  GetUserLogs,
  UpdateConversationBrand,
  handleSkipBrandSelection,
} from 'app/services/NotificationServices/NotificationService';
import { getActivePlacementsByCandidate } from 'app/services/PlacementServices/PlacementServices';
import { getAMIECategorySearch } from 'app/services/SharedServices/SharedServices';
import _ from 'lodash';
import moment from 'moment';
import { selectUser } from 'oidc/user.selectors';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import styledComponent from 'styled-components';
import { Concatenate } from 'utils/string/string';
import { useDecision } from '@optimizely/react-sdk';
import { ff_notifications_enable_shareddesk_communication } from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';
import { selectCoveredInbox } from 'store/redux-store/user-coverage/selectors';
import { ff_notifications_coverage_sms } from 'app/constants/FeatureFlags/EmployeeCoverage/keys';

const useStyles = makeStyles()(theme => ({
  root: { margin: '1%' },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toolbar: {
    paddingLeft: '2px',
    paddingBottom: '2%',
    minHeight: '80px !important',
    alignItems: 'center',
    paddingTop: theme.spacing(1.5),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: '1200px',
    outline: 'none',
    borderRadius: '5px',
    position: 'absolute',
  },

  content: {
    padding: '20px',
  },
  dropDownStyle: {
    minWidth: 300,
    height: 50,
    borderRadius: '5px 5px 0 0',
  },
  navigationButton: {
    position: 'relative',
    float: 'right',
    padding: '25px',
  },
  cancel: {
    marginRight: '20px',
  },
  brandHeader: {
    marginTop: '25px',
  },
  fieldHeader: {
    marginLeft: '25px',
    fontWeight: 500,
    fontSize: '14px',
  },
  gridHeader: {
    marginBottom: '-15px',
  },
  select: {
    padding: '15px',
  },
  promiseLoader: {
    position: 'absolute',
    zIndex: 999,
    left: 'calc(50% - 20px)',
    top: '50%',
  },
}));

export const CustomGridColumn = t => [
  {
    value: t('PID'),
    title: 'placementId',
    sortable: true,
  },

  {
    value: t('Status'),
    title: 'placementType',
    sortable: true,

    columnHeaderHoverText: `Only placements with a status greater than 'Traveler Accepts' are displayed`,
    headerIcon: { infoIcon: true },
  },
  {
    value: t('Brand'),
    title: 'brand',
    sortable: true,
  },
  {
    value: t('Assigned CA'),
    title: 'assignedCA',
    sortable: true,
  },
  {
    value: t('Days to Start'),
    title: 'startDate',
    sortable: true,
    isNumber: true,
  },
  {
    value: t('Facility'),
    title: 'facilityName',
    sortable: true,
  },
];

export const XGridColumn = t => [
  {
    headerName: t('PID'),
    field: 'placementId',
    sortable: true,
    width: 75,
  },

  {
    headerName: t('Status'),
    field: 'status',
    sortable: true,
    width: 150,

    tooltip: tooltipType.IterableKeyTooltip,
    tooltipAttributes: 'clinicalManagerAttributes',
  },
  {
    headerName: t('Brand'),
    field: 'brand',
    sortable: true,
    width: 225,
  },
  {
    headerName: t('Assigned CA'),
    field: 'assignedCA',
    sortable: true,
    width: 150,
  },
  {
    headerName: t('Days to Start'),
    field: 'daysToStart',
    sortable: true,
    isNumber: true,
    width: 150,
  },
  {
    headerName: t('Facility'),
    field: 'facility',
    sortable: true,
    width: 250,
  },
];

const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_PAGE_SIZE = 50;

interface brand {
  name: string;
  id: string;
}
interface candidateBrand {
  brands: brand[];
  selectedBrand: string;
}

export const ModalAppBar = styledComponent(AppBar)`
  width: 100%;
  border-radius: 5px 5px 0 0;
  background: #009ad9;
  padding-left: 20px;
  height: 65px;
`;

export const ModalTitle = styledComponent(Typography)`
  flex-grow: 1;
  padding: 1%;
  color: #ffffff;
`;

export const ModalClose = styledComponent(IconButton)`
  margin-right: 1%;
  color: #ffffff;
`;

export const BrandSelection = ({ openModal, travelerId, handleBrandSelection, handleSkip, onClose }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sharedDeskFlag] = useDecision(ff_notifications_enable_shareddesk_communication);
  const user = useSelector(selectUser);
  const notificationData = useSelector(notificationSelection);
  const coveredInbox = useSelector(selectCoveredInbox);

  const [open, setOpen] = useState<boolean>(openModal);
  const [activePlacements, setActivePlacements] = useState<any>([]);
  const [candidateBrands, setCandidateBrands] = useState<candidateBrand>({
    brands: [],
    selectedBrand: '0',
  });
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [smsCoverageFlag] = useDecision(ff_notifications_coverage_sms);
  const isLoggedUserInActiveInbox = coveredInbox?.activeInbox?.coveredUserId === String(user?.userInfo?.employeeId);
  const hasActiveInbox =
    (smsCoverageFlag?.enabled || sharedDeskFlag?.enabled) && !isLoggedUserInActiveInbox && !!coveredInbox?.activeInbox;
  const hasActiveSharedInbox =
    sharedDeskFlag?.enabled &&
    !!coveredInbox?.activeInbox &&
    coveredInbox?.activeInbox?.coveredUserId == user.userInfo?.sharedProfile?.employeeId;

  const fetchData = async () => {
    const candidateInfoPayload = {
      searchType: 'candidate',
      pageNumber: 1,
      pageSize: 20,
      candidateIds: [travelerId],
    };
    // Get candidate Info
    var candidateInfo = await getAMIECategorySearch(candidateInfoPayload);

    const brands = candidateInfo.items.map(
      (item: any) =>
        ({
          name: item.brand,
          id: item.brandId,
        } || {}),
    );

    setCandidateBrands({
      brands,
      selectedBrand: brands.length === 1 ? brands[0].id : '0',
    });

    await getActivePlacementsByCandidate(travelerId)
      .then(activePlacementsRes => {
        const pagingData: PagingData<PlacementInfo> = activePlacementsRes;
        setActivePlacements(pagingData.items);
      })
      .catch(err => setActivePlacements([]));
  };

  useEffect(
    () => {
      trackPromise(fetchData(), 'candidate-brand-selection');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    const rowData: any = [];
    activePlacements.map((item: any, index) => {
      const row = {
        id: index,
        placementId: item?.placementId,
        status: item?.placementStatus,
        brand: item?.brand,
        assignedCA:
          Concatenate([item?.credentialAnalystInfo.firstName, item?.credentialAnalystInfo.lastName], ' ') ||
          missingField,
        daysToStart:
          moment(new Date()).diff(moment(item?.startDate), 'days') > 0
            ? `${moment(new Date()).diff(moment(item?.startDate), 'days')} days`
            : missingDate,
        facility: item?.facilityName,
      };
      rowData.push(row);
    });
    setTableRows(rowData);
  }, [activePlacements]);

  const updateBrand = async () => {
    setIsSubmitting(true);
    let payload: IUpdateConversationBrandPayload = {
      brand: candidateBrands.brands.find(x => x.id === candidateBrands.selectedBrand)?.name as string,
      userId: user?.userInfo?.employeeId?.toString() || '',
      userPhone: notificationData.sms.data.sender.phoneNumber,
      brandId: candidateBrands.selectedBrand.toString(),
      contactPhone: notificationData.sms.data.tos.find(Boolean).phoneNumber,
      publishedDate: notificationData.sms.chatHistory?.chats?.results?.find(Boolean)?.publishedOn ?? '',
      associatedRecords: [{ name: 'brandId', value: candidateBrands.selectedBrand.toString() }],
    };

    //Add covering employee details || shared user details in associated records
    if (hasActiveInbox) {
      if (hasActiveSharedInbox) {
        payload = {
          ...payload,
          associatedRecords: [
            ...payload.associatedRecords,
            { name: 'sharedUserId', value: `${user.userInfo?.sharedProfile?.employeeId}` },
          ],
        };
      } else {
        payload = {
          ...payload,
          associatedRecords: [
            ...payload.associatedRecords,
            { name: 'coveredEmployeeId', value: `${coveredInbox?.activeInbox?.coveredUserId}` },
          ],
        };
      }
    }

    // Update conversation brand
    try {
      const response = await UpdateConversationBrand(payload);
      if (!response.data.saved) throw new Error();

      // Get conversation logs
      const logsPayload = {
        pageNumber: 1,
        pageSize: 30,
        contactId: notificationData.sms.data.tos[0]?.contactId,
        channel: ['Conversation'],
      };
      const conversationLogs = await GetUserLogs(logsPayload);
      const sortedLogs = conversationLogs?.results?.sort((a, b) => {
        const dateA = new Date(a.publishedOn);
        const dateB = new Date(b.publishedOn);
        return dateB?.getTime() - dateA?.getTime();
      });

      // Update SkipBrandSelection flag to false after assigning brand
      sortedLogs &&
        sortedLogs?.[0]?.id &&
        notificationData?.sms?.data?.tos[0]?.contactId &&
        (await handleSkipBrandSelection(sortedLogs?.[0]?.id, notificationData.sms.data.tos[0]?.contactId, false));

      // Update store with updated brand
      const updateConversation =
        notificationData.sms.chatHistory && _.cloneDeep(notificationData.sms.chatHistory!.chats!.results);
      if (!updateConversation) return;
      updateConversation.find(Boolean)!.brand = candidateBrands?.brands?.find(
        x => x.id === candidateBrands?.selectedBrand,
      )?.name!;
      updateConversation
        .find(Boolean)!
        .associatedRecords.push({ name: 'brandId', value: candidateBrands?.selectedBrand?.toString() });

      const result = _.cloneDeep(notificationData.inbox);
      const selectedInbox = result?.find(x => x.log.id === updateConversation[0]?.id);
      selectedInbox?.log?.associatedRecords?.push({
        name: 'brandId',
        value: candidateBrands?.selectedBrand?.toString(),
      });
      if (selectedInbox) {
        selectedInbox.brand = candidateBrands?.brands?.find(x => x.id === candidateBrands?.selectedBrand)
          ?.name as string;
      }

      dispatch(
        notificationDataActions.setSmsData({
          ...notificationData.sms,
          data: {
            ...notificationData.sms.data,
            associatedRecords: notificationData.sms?.data?.associatedRecords?.map(entry =>
              entry?.name === 'brandId' ? { name: 'brandId', value: candidateBrands?.selectedBrand } : entry,
            ),
            brandId: candidateBrands?.selectedBrand,
            brand: candidateBrands?.brands?.find?.(item => item?.id == candidateBrands?.selectedBrand)?.name || '',
            tos: [
              {
                ...notificationData.sms.data.tos[0],
                brandId: candidateBrands?.selectedBrand,
                brand: candidateBrands?.brands?.find?.(item => item?.id == candidateBrands?.selectedBrand)?.name || '',
              },
            ],
          },
          enableBrandSelection: false,
          isBrandSelectionSkipped: false,
          chatHistory: {
            ...notificationData.sms.chatHistory,
            chats: {
              ...notificationData.sms.chatHistory!.chats,
              results: updateConversation,
            },
          },
        }),
      );
      setIsSubmitting(false);
      dispatch(notificationDataActions.updateInbox(result));
      dispatch(
        globalActions.setSnackBar({
          message: t('Updated brand for SMS Conversation'),
          severity: 'success',
        }),
      );
      handleBrandSelection();
    } catch {
      setIsSubmitting(false);
      dispatch(
        globalActions.setSnackBar({
          message: t('Failed to update candidate brand for conversation'),
          severity: 'error',
        }),
      );
    }
  };

  const handleClose = () => {
    dispatch(
      notificationDataActions.setSmsInteraction({
        open: false,
        minimized: false,
        readonly: false,
      }),
    );
    onClose();
  };

  return (
    <Modal open={open} className={classes.modal} closeAfterTransition>
      <div className={classes.paper}>
        <ModalAppBar elevation={0} id="draggableArea" position="static">
          <Toolbar disableGutters className={classes.toolbar}>
            <ModalTitle variant="h6">{t('notification.sms.assignBrand')}</ModalTitle>
            <ModalClose color="default" aria-label="upload picture">
              <CloseIcon onClick={handleClose} />
            </ModalClose>
          </Toolbar>
        </ModalAppBar>
        <Typography variant="body1" className={`${classes.fieldHeader} ${classes.brandHeader}`}>
          {t('Select Brand')}
        </Typography>
        <div className={classes.content}>
          {isSubmitting && <CircularProgress className={classes.promiseLoader} />}
          <FormControl variant="standard" className={classes.dropDownStyle}>
            <Select
              labelId="domain-select-label"
              id="domain-select"
              placeholder={`Select candidate brand`}
              IconComponent={ExpandMoreOutlinedIcon}
              disabled={candidateBrands.brands.length === 1}
              classes={{
                select: classes.select,
              }}
              value={candidateBrands.selectedBrand}
              defaultValue={candidateBrands.selectedBrand}
              displayEmpty={false}
              onChange={e =>
                setCandidateBrands(prevState => ({ ...prevState, selectedBrand: e.target.value as string }))
              }
              data-testid={'brand-select'}
            >
              <MenuItem value="0" disabled>
                {t('Please select a brand')}
              </MenuItem>
              {candidateBrands &&
                candidateBrands.brands.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <Typography variant="body1" className={`${classes.fieldHeader} ${classes.gridHeader}`}>
          {t('Placements')}
        </Typography>
        <CustomGrid
          columns={CustomGridColumn(t)}
          rows={tableRows}
          component={'Placements'}
          showCount={false}
          disableGutters
          showPagination={false}
          currentPageNumber={DEFAULT_PAGE_NUMBER}
          fixedItemsPerPage={DEFAULT_PAGE_SIZE}
          defaultPageView={DEFAULT_PAGE_SIZE.toString()}
          gridName={'Placements'}
          totalRecordsCount={tableRows.length}
          pageOptions={[5, 10, 15]}
          minCellWidth={'100px'}
          stickyTable
          maxHeight={'250px'}
        />

        <div className={classes.navigationButton}>
          <Button variant="text" className={classes.cancel} onClick={handleClose}>
            {t('global.button.cancel')}
          </Button>
          <Button variant="text" className={classes.cancel} onClick={() => handleSkip()}>
            {t('global.button.skip')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={updateBrand}
            disabled={candidateBrands.selectedBrand === '0'}
          >
            {t('global.button.confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
