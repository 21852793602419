import { lazyLoad } from 'utils/loadable';

export const ReferenceContactsLoadable = lazyLoad(
  () => import('./ReferenceContacts'),
  module => module.default,
);

export const ReferenceDetailsLoadable = lazyLoad(
  () => import('./ReferenceDetails'),
  module => module.default,
);

export const ReferenceAdditionalInfoLoadable = lazyLoad(
  () => import('./ReferenceAdditionalInfo'),
  module => module.default,
);
