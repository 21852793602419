import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { useEditableRequirement } from './EditRequirementsHelper';
import { ShiftOfferedCheck } from './EditablePassFailLogic';
import { useSelector } from 'react-redux';
import { selectSubmissionInfo } from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';

export const useEditableRequirementShiftOffered = () => {
  // SHIFT OFFERED
  const {
    orderReq: {
      order: { schedule: orderReq },
    },
  } = useSelector(selectSubmissionInfo);
  const placementOrderType = useSelector(selectPlacementOrderType);

  useEditableRequirement({
    data: orderReq?.data?.shiftOffered?.type,
    secondaryData: placementOrderType,
    requirementId: IPlacementRequirementKey.orderShiftOffered,
    requirementKey: 'shiftOffered',
    requirementCheck: ShiftOfferedCheck,
    failRequirement: {
      id: IPlacementRequirementKey.orderShiftOffered,
      message: 'Shift Offered: Missing',
      tooltip: {
        message: 'Shift Offered is required (Must update in AMIE Classic)',
      },
    },
  });
};
