import React from 'react';
import { Grid } from '@mui/material';
import { ITaskComments } from 'app/models/Tasks/Tasks';
import { SubTaskItem } from './SubTaskItem';

export const SubTask = ({
  getCommentsLoading,
  commentList,
  setReplyRecipients,
  setSubtaskModalOpen,
  setComment,
  setIsPopperOpen,
  setSubTaskName,
  setEnableTaskName,
  setTask
}: {
  getCommentsLoading: boolean;
  commentList: ITaskComments[];
  setReplyRecipients: (e) => void;
  setSubtaskModalOpen: (e) => void;
  setComment: (e) => void;
  setIsPopperOpen?: (e) => void;
  setSubTaskName?: (e) => void;
  setEnableTaskName?: (e) => void
  setTask?: (e) => void

}) => {
  return (
    <React.Fragment>
      {!getCommentsLoading && (commentList || []).length > 0 && (
        <Grid item xs={12} style={{ marginTop: '3px' }}>
          {(commentList || []).map((comment, idx) => (
            <SubTaskItem
              key={idx}
              comment={comment}
              setReplyRecipients={setReplyRecipients}
              setSubtaskModalOpen={setSubtaskModalOpen}
              setComment={setComment}
              setIsPopperOpen={setIsPopperOpen}
              setSubTaskName={setSubTaskName}
              setEnableTaskName = {setEnableTaskName}
              setTask = {setTask}
            />
          ))}
        </Grid>
      )}
    </React.Fragment>
  );
};
