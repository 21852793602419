import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import React from 'react';
import { CreatePlacement } from './CreatePlacement';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { newPlacementActions } from 'store/redux-store/new-placement/slice';
import {
  orderPreferenceSliceKey,
  orderPreferenceReducer,
} from '@AMIEWEB/Order/OrderDetails/OrderPreferences/store/OrderPreference.redux';
import { orderPreferenceSaga } from '@AMIEWEB/Order/OrderDetails/OrderPreferences/store/OrderPreference.saga';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import useDocumentTitle from 'utils/customHooks/useDocumentTitle';
import { pageNames } from 'app/constants/PageNames';
import { trackPageView } from 'app-insights/appInsightsTracking';
import { placementStatusAction } from 'store/redux-store/placement-status/slice';
import { DefaultCurrentPlacementStatus } from '../NextPlacementStatus/StatusDefaults';
import { lookupActions } from 'store/redux-store/lookup/lookup.slice';
import { Cancel as DiscardDialog, CustomTooltip } from '@AMIEWEB/Common';
import WhiteAuto from 'app/assets/images/Order/WhiteAuto.svg';
import { selectAutomationStatus } from '@AMIEWEB/Order/OrderDetails/OrderPreferences/store/OrderPreference.selector';
import { selectScreenSelectionEnabled } from 'store/redux-store/new-placement/selectors';
import { FormProvider, useForm } from 'react-hook-form';
import { CreatePlacementContext } from '../CreatePlacement/CreatePlacementWrapper';
import { useConfirmExit } from 'utils/customHooks/useConfirmExit';
import { TimeOffMode } from '@AMIEWEB/Common/TimeOffRequest';

const useStyles = makeStyles()({
  dialog: {
    minWidth: '1310px',
    '@media (max-width: 1310px)': {
      minWidth: '1280px',
    },
    '@media (max-width: 1280px)': {
      minWidth: '1200px',
    },
    '@media (max-width: 1200px)': {
      minWidth: '1060px',
    },
    '@media (max-width: 1060px)': {
      minWidth: '960px',
    },
  },
  dialogContent: {
    padding: 0,
  },
});

export const CreatePlacementWrapper = ({ open, initialArgs = {}, ...props }) => {
  const { t } = useTranslation();
  useInjectReducer({ key: orderPreferenceSliceKey, reducer: orderPreferenceReducer });
  useInjectSaga({ key: orderPreferenceSliceKey, saga: orderPreferenceSaga });
  useDocumentTitle({ title: pageNames.createPlacement, revertToPreviousTitle: true });

  const isSubmissionAutomationEnabled = useSelector(selectAutomationStatus);
  const createScreenEnabled = useSelector(selectScreenSelectionEnabled);
  const [expanded, setExpanded] = React.useState<boolean>(true);
  const [discardDialogOpen, setDiscardDialogOpen] = React.useState<boolean>(false);

  const { classes } = useStyles();
  const dispatch = useDispatch();

  const { blockExit, allowExit } = useConfirmExit();

  const formMethods = useForm({
    defaultValues: {
      skillset: null,
      candidate: null,
      order: null,
      availabilityDate: null,
      bestTimeToCall: null,
      sellingPoints: null,
      requestedTimeOff: {
        requestedTimeOffMode: TimeOffMode.AddTimeOff,
        requestedTimeOff: [],
        isRequestedTimeOffMakeUp: false,
        requestedTimeOffNote: null,
      },
      requestedShift: null,
      recruiter: null,
      screenOption: null,
      travel: null,
    },
    shouldUnregister: false,
  });

  const closeModal = React.useCallback(() => {
    if (formMethods.formState.isDirty) setDiscardDialogOpen(true);
    else props.handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMethods.formState.isDirty, props.handleClose]);

  const onDiscardChanges = () => {
    setDiscardDialogOpen(false);
    props.handleClose();
  };

  React.useEffect(() => {
    trackPageView({ name: pageNames.createPlacement });
    dispatch(lookupActions.getAllLookup());
    dispatch(newPlacementActions.useNewCreatePlacementModalFunctions(true));
    dispatch(placementStatusAction.setCurrentPlacementStatus(DefaultCurrentPlacementStatus));
    return () => {
      dispatch(placementStatusAction.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (open) dispatch(newPlacementActions.onInitAction({ ...initialArgs }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  React.useEffect(() => {
    if (formMethods.formState.isDirty) {
      blockExit();
    } else {
      allowExit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMethods.formState.isDirty]);

  const handleExpanded = () => {
    setExpanded(oldVal => !oldVal);
  };

  return (
    <>
      <GenericDialog
        open={open}
        fullWidth
        maxWidth="md"
        variant="blue"
        classes={{
          paperFullWidth: classes.dialog,
        }}
        onClose={closeModal}
        dialogTitleProps={{
          leadingIcon: isSubmissionAutomationEnabled ? (
            <CustomTooltip tooltipContent={`Automation is On`}>
              <img src={WhiteAuto} alt="automation" style={{ width: '24px' }} />
            </CustomTooltip>
          ) : undefined,
          text: createScreenEnabled ? t('placement.create.popup.screenTitle') : t('placement.create.popup.title'),
          closeButton: true,
          expandable: true,
          onExpandChange: handleExpanded,
        }}
        dialogContentProps={{ classes: { root: classes.dialogContent } }}
      >
        <CreatePlacementContext.Provider
          value={{
            setCurrentFocusId: () => {},
            isDefaultCandidate: props.isDefaultCandidate,
            isDefaultOrder: props.isDefaultOrder,
            closeCreateModal: closeModal,
            useNewCreatePlacementModalFunctions: true,
          }}
        >
          <FormProvider {...formMethods}>
            <form style={{ height: '100%' }}>
              <CreatePlacement expanded={expanded} />
            </form>
          </FormProvider>
        </CreatePlacementContext.Provider>
      </GenericDialog>
      {discardDialogOpen ? (
        <DiscardDialog
          openDialog
          handleCancelAction={() => setDiscardDialogOpen(false)}
          handleConfirmAction={onDiscardChanges}
          // cancelButtonId={createPlacementTabIDs.createPlacementConfirmCancelButton}
          // confirmButtonId={createPlacementTabIDs.createPlacementConfirmDiscardButton}
        />
      ) : null}
    </>
  );
};
