/* eslint-disable @typescript-eslint/no-explicit-any */
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { XGridChipCell } from 'app/components/Common/XGrid/cells/XGridChipCell';
import { XGridDefaultColumn } from 'app/components/Common/XGrid/cells/XGridDefaults';
import { RateElementTableCell } from './RateElements';
import { Status } from 'app/enums/Facility';
import { IRateElementShift } from '@AMIEWEB/Facility/FacilityStore/Facility.model';

export const getColor = (status: any) => {
  switch (status) {
    case 'Void':
      return '#CB0813';
    case 'Expired':
      return '#CB0813';
    case 'Active':
      return '#5FB661';
    case 'Pending':
      return '#FC9508';
    case 'Pending Approval':
      return '#FC9508';
    default:
      return '#000000';
  }
};
export const getColorById = (status: any) => {
  switch (status) {
    case Status.Void:
      return '#CB0813';
    case Status.Expired:
      return '#CB0813';
    case Status.Approved:
      return '#5FB661';
    case Status.Pending:
      return '#FC9508';
    case Status.PendingApproval:
      return '#FC9508';
    case Status.Rejected:
      return '#CB0813';
    default:
      return '#000000';
  }
};
export const getStatus = (status: any) => {
  switch (status) {
    case 'Void':
      return 'Expired';
    case 'Expired':
      return 'Expired';
    case 'Active':
      return 'Approved';
    case 'Pending':
      return 'Pending';
    case 'Pending Approval':
      return 'Pending Approval';
    default:
      return '--';
  }
};

const getRateElementsColumns: GridColDef[] = [
  {
    ...XGridDefaultColumn,
    field: 'RateElement',
    headerName: 'Rate Elements',
    hideable: false,
    pinnable: true,
    renderCell: params => {
      return RateElementTableCell(params.value);
    },
  },
  {
    ...XGridDefaultColumn,
    field: 'status',
    headerName: 'Status',
    hideable: false,
    pinnable: true,
    renderCell: XGridChipCell({
      size: 'small',
      dynamicColor: (params: GridCellParams) => {
        return getColor(params.value);
      },
      renderValue: (params: GridCellParams) => {
        return getStatus(params.value);
      },
    }),
  },
];

const getDataSet = (data: IRateElementShift[]) => {
  if (data.length === 2 && data[0]?.shiftId === data[1]?.shiftId) {
    return data.splice(1,1);
  }
  return [...new Set(data)];
};

export const setRateElementColumns = (data: IRateElementShift[]) => {
  const columnArray: GridColDef[] = [];
  getRateElementsColumns.map(item => columnArray.push(item));
  const shifts = getDataSet([...data]);
  shifts?.forEach(item => {
    const column: GridColDef = {
      ...XGridDefaultColumn,
      field: item.shiftValue,
      headerName: item.shiftDisplayValue,
      hideable: false,
      pinnable: false,
    };
    columnArray.push(column);
  });
  return columnArray;
};
