import React, { useState } from 'react';
import { PreferencesEditModal } from './PreferencesEditModal';
import { ff_candidate_container_ui_v2 } from 'app/constants/FeatureFlags/Candidate/Keys';
import { useDecision } from '@optimizely/react-sdk';
import PreferencesCardUIV2 from '../CardDesigns/PreferencesCardUIV2';
import PreferencesCardUI from '../CardDesigns/PreferencesCardUI';
import { SupervisorStatus } from '../Supervisor/constants';

export const skillsetStatusOptions = [
  {
    ID: 1,
    Description: SupervisorStatus.APPROVED,
  },
  {
    ID: 2,
    Description: SupervisorStatus.DECLINED,
  },
];

const PreferencesCard = () => {
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [candidateContainerUIV2] = useDecision(ff_candidate_container_ui_v2);

  const handleCloseEdit = () => {
    setEditOpen(false);
  };

  return (
    <>
      {candidateContainerUIV2?.enabled ? (
        <PreferencesCardUIV2 setEditOpen={setEditOpen} />
      ) : (
        <PreferencesCardUI setEditOpen={setEditOpen} />
      )}
      <PreferencesEditModal open={editOpen} handleClose={handleCloseEdit} />
    </>
  );
};
export default PreferencesCard;
