import { placementStage } from '@AMIEWEB/Order/OrderDetails/OrderPreferences/store/OrderPreference.redux';
import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { ExceptionType } from 'app/enums/Common';
import { FacilityService } from 'app/services/FacilityServices/facility-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { trackPromise } from 'react-promise-tracker';
import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import { facilityPreferenceActions } from './slice';
import { TemplateService } from 'app/services/TemplateService/template-service';
import { ICopyPreferencesRequest, IFacilityPreferenceRequest, IUpdateUnitPreferences } from './types';

const TrackFacilityPreferencesWrapper = (fn, ...args) =>
  trackPromise(
    fn(...args),
    !!args[args.length - 1] ? `get-facility-preferences-${args[args.length - 1]}` : 'get-facility-preferences',
  );
const TrackUpdateFacilityPreferencesWrapper = (fn, ...args) =>
  trackPromise(fn(...args), `save-facility-preferences-${args[args.length - 1]}`);
const TrackUpdateAutomationStatusWrapper = (fn, ...args) =>
  trackPromise(fn(...args), `update-facility-preferences-automation-${args[args.length - 1]}`);
const TrackConfirmationTemplatesWrapper = (fn, ...args) =>
  trackPromise(fn(...args), `get-confirmation-templates-${args[args.length - 1]}`);
const TrackFacilityTemplateWrapper = (fn, ...args) =>
  trackPromise(fn(...args), `get-facility-template--${args[args.length - 1]}`);
const TrackUpdateFacilityTemplateWrapper = (fn, ...args) =>
  trackPromise(fn(...args), `save-facility-template-${args[args.length - 1]}`);
const TrackActivePlacementCounts = (fn, ...args) =>
  trackPromise(fn(...args), 'facility-preference-active-placementcounts');
const TrackUpdateFacilityPreferencesToUnits = (fn, ...args) =>
  trackPromise(fn(...args), 'update-facility-preferences-to-unit');

function* requestFacilityPreferences(
  action: PayloadAction<{
    facilityId: number;
    stage: placementStage | null;
  }>,
) {
  yield put(facilityPreferenceActions.setUpdateAutomationApiProgressStatus({ status: true }));
  try {
    const facilityService: FacilityService = yield getContext('facilityService');
    const response = yield call(
      TrackFacilityPreferencesWrapper,
      facilityService.getFacilityPreferences,
      action.payload.facilityId,
      action.payload.stage,
    );
    if (httpSuccess(response.status)) {
      yield put(facilityPreferenceActions.setPreferences({ ...response.data }));
    }
    yield put(facilityPreferenceActions.setUpdateAutomationApiProgressStatus({ status: false }));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestFacilityPreferences',
        area: 'facility-preference/saga',
      },
    });
    yield put(facilityPreferenceActions.setUpdateAutomationApiProgressStatus({ status: false }));
  }
}

function* requestConfirmationTemplates() {
  try {
    const templateService: TemplateService = yield getContext('templateService');
    const response = yield call(TrackConfirmationTemplatesWrapper, templateService.getTemplates, false, 'Confirmation');
    if (httpSuccess(response.status)) {
      const templateArray = response.data.map(({ id, templateName }) => ({ value: id, name: templateName }));
      templateArray.sort((a, b) => a.name.localeCompare(b.name));
      yield put(facilityPreferenceActions.setConfirmationTemplates(templateArray));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestConfirmationTemplates',
        area: 'facility-preference/saga',
      },
    });
  }
}

function* requestFacilityTemplate(
  action: PayloadAction<{
    facilityId: number;
  }>,
) {
  yield put(facilityPreferenceActions.setUpdateFacilityTemplateProgressStatus({ status: true }));
  try {
    const facilityService: FacilityService = yield getContext('facilityService');
    const response = yield call(
      TrackFacilityTemplateWrapper,
      facilityService.getFacilityTemplate,
      action.payload.facilityId,
    );
    if (httpSuccess(response.status)) {
      yield put(facilityPreferenceActions.setFacilityTemplate(response.data));
    }
    yield put(facilityPreferenceActions.setUpdateFacilityTemplateProgressStatus({ status: false }));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestFacilityTemplate',
        area: 'facility-preference/saga',
      },
    });
    yield put(facilityPreferenceActions.setUpdateFacilityTemplateProgressStatus({ status: false }));
  }
}

function* saveFacilityPreferences(
  action: PayloadAction<{
    data: IUpdateUnitPreferences;
    isCopyingPreferences: boolean;
  }>,
) {
  yield put(facilityPreferenceActions.setUpdateAutomationApiProgressStatus({ status: true }));
  try {
    const facilityService: FacilityService = yield getContext('facilityService');
    const response = yield call(
      TrackUpdateFacilityPreferencesWrapper,
      facilityService.updateFacilityPreferences,
      { ...action.payload.data },
      /** Placed here to refer the track fn */
      action.payload.data.stage,
    );
    if (httpSuccess(response.status)) {
      yield call(requestFacilityPreferences, {
        payload: { facilityId: action.payload.data.facilityId, stage: action.payload.data.stage },
        type: facilityPreferenceActions.getFacilityPreferences.type,
      });
      yield put(
        globalActions.setSnackBar({
          severity: 'success',
          message: 'Preferences were saved successfully.',
        }),
      );
      if (action.payload.isCopyingPreferences) {
        yield put(facilityPreferenceActions.setPreferenceUpdateDialog(true));
      }
    } else
      yield put(
        globalActions.setSnackBar({
          severity: 'error',
          message: 'Error in saving Preferences. Please try again.',
        }),
      );
    yield put(facilityPreferenceActions.setUpdateAutomationApiProgressStatus({ status: false }));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'saveFacilityPreferences',
        area: 'facility-preference/saga',
      },
    });
    yield put(facilityPreferenceActions.setUpdateAutomationApiProgressStatus({ status: false }));
  }
}

function* saveFacilityTemplate(
  action: PayloadAction<{
    facilityId: number;
    confirmationTemplateId?: number;
    updatedBy?: number;
    confirmationTemplateName?: string;
    facilityForm?: boolean;
  }>,
) {
  yield put(facilityPreferenceActions.setUpdateFacilityTemplateProgressStatus({ status: true }));
  try {
    const facilityService: FacilityService = yield getContext('facilityService');
    const response = yield call(TrackUpdateFacilityTemplateWrapper, facilityService.updateFacilityTemplate, {
      facilityId: action.payload.facilityId,
      confirmationTemplateId: action.payload.confirmationTemplateId,
      updatedBy: action.payload.updatedBy,
    });

    if (httpSuccess(response.status)) {
      yield call(requestFacilityTemplate, {
        payload: { facilityId: action.payload.facilityId },
        type: facilityPreferenceActions.getFacilityTemplate.type,
      });
      if (!action.payload.facilityForm) {
        yield put(
          globalActions.setSnackBar({
            severity: 'success',
            message: 'Automation setting was saved successfully.',
          }),
        );
      }
    } else
      yield put(
        globalActions.setSnackBar({
          severity: 'error',
          message: 'Error in saving Automation setting. Please try again.',
        }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'saveFacilityTemplate',
        area: 'facility-preference/saga',
      },
    });
    yield put(facilityPreferenceActions.setUpdateFacilityTemplateProgressStatus({ status: false }));
  }
}
function* saveAutomationStatus(
  action: PayloadAction<{
    id?: string;
    facilityId: number;
    submission: {
      automation: boolean;
      changedByUserId: number;
      changedByUser: string;
      modifiedDate: string;
    };
    stage: placementStage;
  }>,
) {
  yield put(facilityPreferenceActions.setUpdateAutomationApiProgressStatus({ status: true }));
  try {
    const facilityService: FacilityService = yield getContext('facilityService');
    const response = yield call(
      TrackUpdateAutomationStatusWrapper,
      facilityService.updateFacilityPreferences,
      action.payload,
    );
    if (httpSuccess(response.status)) {
      yield call(requestFacilityPreferences, {
        payload: {
          facilityId: action.payload.facilityId,
          stage: action.payload.stage,
        },
        type: facilityPreferenceActions.getFacilityPreferences.type,
      });
      yield put(
        globalActions.setSnackBar({
          severity: 'success',
          message: 'Automation setting was saved successfully.',
        }),
      );
    } else
      yield put(
        globalActions.setSnackBar({
          severity: 'error',
          message: 'Error in saving Automation setting. Please try again.',
        }),
      );

    yield put(facilityPreferenceActions.setUpdateAutomationApiProgressStatus({ status: false }));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'saveAutomationStatus',
        area: 'facility-preference/saga',
      },
    });
  }
  yield put(facilityPreferenceActions.setUpdateAutomationApiProgressStatus({ status: false }));
}

function* requestActivePlacementCounts(action: PayloadAction<IFacilityPreferenceRequest>) {
  try {
    const facilityService: FacilityService = yield getContext('facilityService');
    yield put(facilityPreferenceActions.setUpdateAutomationApiProgressStatus({ status: true }));
    const response = yield call(TrackActivePlacementCounts, 
      facilityService.getActivePlacementCounts, action.payload.facilityId);
    if (httpSuccess(response.status)) {
      yield put(facilityPreferenceActions.setActivePlacementCounts({ ...response.data }));
    }
    yield put(facilityPreferenceActions.setUpdateAutomationApiProgressStatus({ status: false }));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestActivePlacementCounts',
        area: 'facility-preference/saga',
      },
    });
    yield put(facilityPreferenceActions.setUpdateAutomationApiProgressStatus({ status: false }));
  }
}
function* requestFacilityUnits(
  action: PayloadAction<{
    facilityId: number;
  }>,
) {
  try {
    const facilityService: FacilityService = yield getContext('facilityService');
    const response = yield call(
      TrackFacilityTemplateWrapper,
      facilityService.getFacilityIdUnit,
      action.payload.facilityId,
    );
    if (httpSuccess(response.status)) {
      yield put(facilityPreferenceActions.setExsistingUnits(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestFacilityUnits',
        area: 'facility-preference/saga',
      },
    });
  }
}

function* updateUnitPreferencesToFacility(
  action: PayloadAction<{
    data: ICopyPreferencesRequest;
    facilityId: number;
  }>,
) {
  try {
    const facilityService: FacilityService = yield getContext('facilityService');
    const response = yield call(
      TrackUpdateFacilityPreferencesToUnits,
      facilityService.updateFacilityPreferencesToUnits,
      action.payload.data,
    );
    if (httpSuccess(response.status)) {
      yield call(requestFacilityUnits, {
        payload: { facilityId: action.payload.facilityId },
        type: facilityPreferenceActions.getExistingUnitPreferences.type,
      });
      yield put(
        globalActions.setSnackBar({
          severity: 'success',
          message: 'Preferences successfully saved to selected Units.',
        }),
      );
    } else
      yield put(
        globalActions.setSnackBar({
          severity: 'error',
          message: 'Error in saving Preferences to existing Facility. Please try again.',
        }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'updateUnitPreferencesToFacility',
        area: 'unit-preference/saga',
      },
    });
  }
}
export function* facilityPreferenceSaga() {
  yield takeLatest(facilityPreferenceActions.getFacilityPreferences.type, requestFacilityPreferences);
  yield takeLatest(facilityPreferenceActions.updateFacilityPreferences.type, saveFacilityPreferences);
  yield takeLatest(facilityPreferenceActions.updateAutomation.type, saveAutomationStatus);
  yield takeLatest(facilityPreferenceActions.getConfirmationTemplates.type, requestConfirmationTemplates);
  yield takeLatest(facilityPreferenceActions.getFacilityTemplate.type, requestFacilityTemplate);
  yield takeLatest(facilityPreferenceActions.updateFacilityTemplate.type, saveFacilityTemplate);
  yield takeLatest(facilityPreferenceActions.getActivePlacementCounts.type, requestActivePlacementCounts);
  yield takeLatest(facilityPreferenceActions.getExistingUnitPreferences.type, requestFacilityUnits);
  yield takeLatest(facilityPreferenceActions.updatePrefrencesToFacility.type, updateUnitPreferencesToFacility);

}
