import { Typography, Grid } from 'amn-ui-core';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { IPlacementStatusRequirementTooltip } from 'app/models/Placement/PlacementDetails';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useListStyles } from './styles';

export const NextPlacementStatusMessageTooltip = ({
  tooltip,
  ...props
}: {
  tooltip?: IPlacementStatusRequirementTooltip;
  [key: string]: any;
}) => {
  const { classes } = useListStyles({});
  return tooltip ? (
    <CustomTooltip
      // open={tooltip.message.includes('Candidate Status')}
      hideToolTip={!tooltip?.message}
      standardMargin
      placement={'bottom'}
      tooltipContent={<TooltipListContent tooltip={tooltip} />}
      classes={{tooltip : classes.toolTip}}
    >
      {props.children}
    </CustomTooltip>
  ) : (
    props.children
  );
};

export const TooltipListContent = ({ tooltip }: { tooltip: IPlacementStatusRequirementTooltip }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">{t(tooltip.message)}</Typography>
        </Grid>
        {tooltip.supportingList && (
          <Grid item xs={12}>
            <ListContent list={tooltip.supportingList} />
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

const ListContent = ({ list }) => {
  const { t } = useTranslation();
  return (
    <ul className="unordered-list unordered-list-item">
      {list.map(item =>
        Array.isArray(item) ? (
          <ListContent list={item} />
        ) : (
          <li>
            <Typography variant="body1">{t(item)}</Typography>
          </li>
        ),
      )}
    </ul>
  );
};
