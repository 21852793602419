import { IDirection } from 'app/components/Notification/Constants';
import _ from 'lodash';

export const getBulkInboxDataToUpdate = (
  tos: any[],
  messageBody: string | undefined,
  attachments: any,
  sender: {
    name: string;
    email: string | undefined;
    senderId: number | undefined;
    userId: number | undefined;
    NtUserName: string | undefined;
    deviceName: string | undefined;
    phoneNumber: string | undefined;
  },
  inboxData: any[],
) => {
  if (inboxData === undefined) return;
  var updatedInboxResult = _.cloneDeep([...inboxData]);
  tos?.forEach(
    (candidate: {
      contactId: { toString: () => any };
      phoneNumber: { toString: () => any };
      recruiter: any;
      recruiterId: any;
      brandId: any;
      name: any;
      email: any;
    }) => {
      const date = new Date();
      const existingMessage = updatedInboxResult.filter(item =>
        item.travelerId !== ''
          ? item.travelerId === candidate?.contactId?.toString()
          : item.name === candidate?.phoneNumber?.toString(),
      )[0];
      var updatedExistingLog = { ...existingMessage?.log };
      var updatedExistingMessage = { ...existingMessage?.log?.message };
      if (existingMessage) {
        // Removing the message from the inbox
        updatedInboxResult = updatedInboxResult.filter(item => item !== existingMessage);
        updatedExistingLog.publishedOn = date;
        updatedExistingMessage.body = messageBody;
        updatedExistingMessage.attachmentUrls = attachments;
        updatedExistingMessage.direction = IDirection.Outbound;
        updatedExistingLog.associatedRecords = [
          {
            name: 'travelerId',
            value: candidate?.contactId,
          },
          {
            name: 'recruiter',
            value: candidate?.recruiter,
          },
          {
            name: 'recruiterId',
            value: candidate?.recruiterId,
          },
          {
            name: 'brandId',
            value: candidate?.brandId,
          },
        ];
        const log = { ...updatedExistingLog, message: updatedExistingMessage };
        var updatedMessage = { ...existingMessage, log: log };
        updatedInboxResult.unshift(updatedMessage);
      } else {
        const associatedRecords = [
          {
            name: 'travelerId',
            value: candidate?.contactId,
          },
          {
            name: 'recruiter',
            value: candidate?.recruiter,
          },
          {
            name: 'recruiterId',
            value: candidate?.recruiterId,
          },
          {
            name: 'brandId',
            value: candidate?.brandId,
          },
        ];
        const tos = {
          readByRecipient: null,
          subject: null,
          brand: null,
          contactId: candidate?.contactId,
          deliveryStatus: 'delivered',
          name: candidate?.name,
          email: candidate?.email,
          phoneNumber: candidate?.phoneNumber,
        };
        const logMessage = {
          sender: sender,
          tos: tos,
          body: messageBody,
          direction: IDirection.Outbound,
        };
        const newLog = {
          name: candidate?.name,
          travelerId: candidate?.contactId,
          log: { associatedRecords: associatedRecords, message: logMessage, publishedOn: new Date() },
          unreadLogs: 0,
          publishedOn: date,
        };
        updatedInboxResult.unshift(newLog);
      }
    },
  );
  return updatedInboxResult;
};
