import { Grid, TextField, Autocomplete } from 'amn-ui-core';
import { CategorizedAutoComplete } from 'app/components/Common/Search/CategorizedAutoComplete';
import { TaskCategory, ISubCategoryDependentData, IExternalTaskSubType, TaskEntity } from 'app/models/Tasks/Tasks';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { taskDetailsActions } from '../store/Tasks.redux';
import { selectSubCategory1, selectSubCategory2 } from '../store/Tasks.selectors';

export const TaskCategoryInputs = ({
  externalTask = false,
  createTaskVar,
  formMethods,
  options,
  isCallingAPI = false,
  classes,
  setShowField,
  setTaskCategoryValue,
  showField = false,
  container
}: {
  externalTask: boolean;
  createTaskVar: any;
  formMethods: any;
  options: any;
  isCallingAPI: boolean;
  classes: any;
  setShowField: (e) => void;
  setTaskCategoryValue: (e) => void;
  showField: boolean;
  container: number
}) => {
  const {
    control,
    register,
    setError,
    clearErrors,
    setValue,
    formState: { isDirty, errors },
  } = formMethods;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const subCategory1List = useSelector(selectSubCategory1);
  const subCategory2List = useSelector(selectSubCategory2);
  const [showSubCategoriesField, setSubCategoriesField] = useState<boolean>(false);
  const [subCategory1Label, setSubCategory1Label] = useState<string>();
  const [subCategory2Label, setSubCategory2Label] = useState<string>();
  const [subCategory1OptionsList, setSubCategory1OptionsList] = useState<IExternalTaskSubType[]>([]);
  const [subCategory2OptionsList, setSubCategory2OptionsList] = useState<IExternalTaskSubType[]>([]);
  const initialSubNames: IExternalTaskSubType[] = [];

  const CategoryVar = {
    categorySubType1: 'categorySubType1',
    categorySubType2: 'categorySubType2',
  };

  const categorySelection = value => {
    if (externalTask && (value?.taskId === 1 || value?.taskId === 5)) {
      dispatch(taskDetailsActions.getExternalSubCategoryList(value?.taskId));
      if (value?.taskId === 1) {
        setSubCategory1Label(t('notification.createTask.certification'));
        setSubCategory2Label(t('notification.createTask.issuingBody'));
      }
      if (value?.taskId === 5) {
        setSubCategory1Label(t('notification.createTask.license'));
        setSubCategory2Label(t('notification.createTask.state'));
      }
      setSubCategoriesField(true);
    } else {
      setSubCategoriesField(false);
    }
  };

  const handleInputChange = (options, key) => {
    const filteredList = options?.filter(
      opt =>
        opt.label?.toLowerCase().includes(key.toLowerCase()),
    );
    return filteredList;
  };

  const handleCategory1Change = (event, v) => {
    const categoryList1 = handleInputChange(subCategory1List, v);
    setSubCategory1OptionsList(categoryList1);
  };

  const handleCategory2Change = (event, v) => {
    const categoryList2 = handleInputChange(subCategory2List, v);
    setSubCategory2OptionsList(categoryList2);
  };

  const formValueAddition = (value, category) => {
    if (!value) {
      setValue(category, null);
    } else {
      setValue(category, {
        subTypeId: value.subTypeId,
        subTypeText: (category === CategoryVar.categorySubType1 ? subCategory1Label : subCategory2Label),
        subTypeDataId: value.id,
        subTypeDataText: value.label
      } as ISubCategoryDependentData);
    }
  };

  const sortCategorySelection = value => {
    categorySelection(value);
    clearErrors(createTaskVar.task);
    clearErrors(createTaskVar.taskCategory);
    setShowField(false);
    setTaskCategoryValue(value);
    if (!value) return;
    switch (value?.categoryId) {
      case TaskCategory.OTHERS:
        setShowField(true);
        setTimeout(() => {
          setValue(createTaskVar.taskCategory, value?.categoryId);
          setValue(createTaskVar.task, undefined);
        }, 0);
        break;
      case TaskCategory.MISSINGRFOREQS:
      case TaskCategory.CREDENTIALING:
        setTimeout(() => {
          setValue(createTaskVar.task, value?.taskName);
        }, 0);
        break;
    }
  };

  useEffect(() => {
    sortCategorySelection(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalTask]);

  useEffect(() => {
    setSubCategory1OptionsList(subCategory1List);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategory1List]);

  useEffect(() => {
    setSubCategory2OptionsList(subCategory2List);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategory2List]);

  return (
    <React.Fragment>
      <Grid item xs={12} data-testid="task-type-input">
        {externalTask && (
          <Controller
            control={control}
            name={showField ? createTaskVar.taskCategory : createTaskVar.task}
            render={({ ref, onChange, ...rest }) => (
              <CategorizedAutoComplete
                optionsList={options}
                color="primary"
                disabled={isCallingAPI}
                error={
                  externalTask && (showField ? errors?.taskCategory : errors?.task)
                    ? true
                    : false || (isDirty && (showField ? errors?.taskCategory : errors?.task))
                      ? true
                      : false
                }
                className={classes.inputs}
                classes={{ paper: classes.paper }}
                labelClass={classes.fieldLabel}
                id={showField ? createTaskVar.taskCategory : createTaskVar.task}
                label={t('notification.createTask.taskLabel')}
                groupBy={option => (option.groupBy === 'Others' ? undefined : option.groupBy)}
                onChange={value => {
                  sortCategorySelection(value);
                  onChange(value?.id);
                }}
                noOptionsText={t('notification.createTask.noMatchesFound')}
                helperText={
                  (externalTask && (showField ? errors?.taskCategory : errors?.task) && t('required')) ||
                  (isDirty && (showField ? errors?.taskCategory : errors?.task) && t('required'))
                }
                {...register(showField ? createTaskVar.taskCategory : createTaskVar.task, { required: true })}
              />
            )}
          />
        )}
        {!externalTask && (
          <Controller
            control={control}
            name={showField ? createTaskVar.taskCategory : createTaskVar.task}
            render={({ ref, onChange, ...rest }) => (
              <CategorizedAutoComplete
                optionsList={options}
                color="primary"
                disabled={isCallingAPI}
                error={isDirty && (showField ? errors?.taskCategory : errors?.task) ? true : false}
                className={classes.inputs}
                classes={{ paper: classes.paper }}
                labelClass={classes.fieldLabel}
                id={showField ? createTaskVar.taskCategory : createTaskVar.task}
                label={t('notification.createTask.taskLabel')}
                groupBy={option => (option.groupBy === 'Others' ? undefined : option.groupBy)}
                onChange={value => {
                  sortCategorySelection(value);
                  onChange(value?.id);
                }}
                noOptionsText={t('notification.createTask.noMatchesFound')}
                helperText={isDirty && (showField ? errors?.taskCategory : errors?.task) && t('required')}
                {...register(showField ? createTaskVar.taskCategory : createTaskVar.task, { required: true })}
              />
            )}
          />
        )}
      </Grid>
      {showField && (
        <Grid item xs={12} data-testid="task-other-type-input">
          <Controller
            control={control}
            name={'task'}
            render={({ ref, onChange, value, ...rest }) => (
              <TextField
                required
                variant="filled"
                color="primary"
                className={classes.inputs}
                error={externalTask && errors?.task ? true : false || (isDirty && errors?.task) ? true : false}
                disabled={isCallingAPI}
                InputLabelProps={{
                  className: classes.fieldLabel,
                }}
                id="task"
                label={t('notification.createTask.otherTaskLabel')}
                onChange={e => {
                  if (e.target.value?.length > 50) {
                    setError(createTaskVar.task, {
                      type: 'maxLength',
                      message: t('notification.createTask.maxLimitLabel'),
                    });
                  } else if (e.target.value?.length <= 50) {
                    clearErrors(createTaskVar.task);
                  }
                  onChange(e);
                }}
                helperText={
                  (externalTask && errors?.task && errors?.task?.message) ||
                  (isDirty && errors?.task && errors?.task?.message)
                }
                {...register(createTaskVar.task, {
                  required: t('required').toString(),
                  maxLength: { value: 50, message: t('notification.createTask.maxLimitLabel') },
                })}
              />
            )}
          />
        </Grid>
      )}
      {showSubCategoriesField && (
        <React.Fragment>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={CategoryVar.categorySubType1}
              render={({ ref, onChange, value, ...rest }) => (
                <Autocomplete
                  filterSelectedOptions
                  disabled={isCallingAPI}
                  onInputChange={handleCategory1Change}
                  options={subCategory1OptionsList || initialSubNames}
                  filterOptions={option => option}
                  getOptionLabel={(option: IExternalTaskSubType) => option?.label}
                  noOptionsText={t('notification.createTask.noResultLabel')}
                  onChange={(e, value) => {
                    formValueAddition(value, CategoryVar.categorySubType1);
                    clearErrors(CategoryVar.categorySubType1);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={subCategory1Label}
                      variant="filled"
                      className={classes.inputs}
                      error={externalTask && Object.keys(errors).includes(CategoryVar.categorySubType1)}
                      helperText={
                        (externalTask && Object.keys(errors).includes(CategoryVar.categorySubType1) && t('required'))
                      }
                      {...register(CategoryVar.categorySubType1, { required: true })}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={CategoryVar.categorySubType2}
              render={({ ref, onChange, value, ...rest }) => (
                <Autocomplete
                  blurOnSelect
                  filterSelectedOptions
                  disabled={isCallingAPI}
                  options={subCategory2OptionsList || initialSubNames}
                  onInputChange={handleCategory2Change}
                  filterOptions={option => option}
                  getOptionLabel={(option: IExternalTaskSubType) => option?.label}
                  noOptionsText={t('notification.createTask.noResultLabel')}
                  onChange={(e, value) => {
                    formValueAddition(value, CategoryVar.categorySubType2);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={subCategory2Label}
                      variant="filled"
                      className={classes.inputs}
                      {...register(CategoryVar.categorySubType2, { required: false })}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
