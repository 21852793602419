import React from 'react';
import { MapPopover } from '.';

export const getPopovers = (entities, freezePopover, closePopover) =>
  entities.map((item, index) => {
    return (
      <MapPopover
        key={index.toString()}
        lat={item.locationCoordinates.latitude}
        lng={item.locationCoordinates.longitude}
        id={`popover-${item.entityId}`}
        entityId={item.entityId}
        onClose={() => closePopover()}
        isClosable={freezePopover}
        avatarText={item.avatarText}
        title={item.title}
        data={item.keyValuePairs}
      />
    );
  });

export const getAggregatePopovers = (clusters, supercluster, entities) =>
  clusters.reduce((resultArray: any[], cluster) => {
    const { cluster: isCluster, point_count: pointCount } = cluster.properties;
    if (isCluster) {
      const clusterChildren = supercluster.getLeaves(cluster.id);

      if (determineIsChildrenAtSameCoordinates(clusterChildren)) {
        const preferredEntity = entities.find(entity => entity.entityId === clusterChildren[0].properties.entityId);
        const popover = (
          <MapPopover
            key={preferredEntity.entityId.toString()}
            lat={preferredEntity.locationCoordinates.latitude}
            lng={preferredEntity.locationCoordinates.longitude}
            id={`popover-${preferredEntity.entityId}`}
            entityId={preferredEntity.entityId}
            isClosable={false}
            avatarText={preferredEntity.avatarText}
            title={preferredEntity.title}
            data={preferredEntity.keyValuePairs}
            isAnAggregate={true}
            tail={<div style={{ textAlign: 'center', color: '#333333', fontSize: 12 }}>{`1 of ${pointCount}`}</div>}
          />
        );
        resultArray.push(popover);
      }
    }
    return resultArray;
  }, []);

export const getCarouselCards = (entities, setPopOverEntityId) =>
  entities.map(entity => {
    const keyValuePairs = entity.keyValuePairs.filter(item => item.mapOverlayCardProperty);

    return (
      <MapPopover
        key={entity.entityId.toString()}
        entityId={entity.entityId}
        avatarText={entity.avatarText}
        title={entity.title}
        enableEllipsis
        data={keyValuePairs}
        onClick={() => setPopOverEntityId(entity.entityId)}
        isACarouselCard
        cursor={'pointer'}
      />
    );
  });

// To identify if the cluster holds same coordinates
// check if any of them are any with unequal values as
// array.prototype.some() returns faster result ( v/s array.prototype.every())
export const determineIsChildrenAtSameCoordinates = children =>
  !children.some(
    (child, index, array) =>
      child.geometry.coordinates[0] !== array[0].geometry.coordinates[0] ||
      child.geometry.coordinates[1] !== array[0].geometry.coordinates[1],
  );
