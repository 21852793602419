import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { FormControl, InputLabel, MenuItem, Select, Typography } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { Controller, useFormContext } from 'react-hook-form';
import OutlinedFlagRoundedIcon from '@mui/icons-material/OutlinedFlagRounded';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import CheckIcon from '@mui/icons-material/Check';
import { TaskPriority, TaskPriorityList } from 'app/models/Tasks/Tasks';

const useStyles = makeStyles()(theme => ({
  inputLabel: {
    fontSize: '12px',
    color: theme.palette.framework.system.doveGray,
    '&.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '&:focus': {
      color: `${theme.palette.framework.system.doveGray} !important`,
    },
  },
  checkmark: {
    marginLeft: 'auto',
    width: '18px',
    color: theme.palette.primary.main,
  },
}));

export interface SelectWithIconProps {
  name: string;
  label: string;
  options: { name: string; value: string }[];
  handleChange?: (item: { name: string; value: string }) => void;
  value?: string;
  disabled?: boolean;
}

export const SelectWithIcon = (props: SelectWithIconProps) => {
  const { name, label, options, value, handleChange, disabled } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { setValue, control, errors, clearErrors } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={'2'}
      render={({ ref, onChange, ...rest }) => (
        <FormControl size="small" sx={{ minWidth: '186px' }}>
          <InputLabel id="priority-select-label" className={classes.inputLabel} error={errors[name]}>
            {label}
          </InputLabel>
          <Select
            value={value}
            variant="outlined"
            color="primary"
            IconComponent={ExpandMoreOutlinedIcon}
            onChange={e => {
              setValue(name, e.target.value, { shouldDirty: true });
              clearErrors(name);
            }}
            renderValue={selected => {
              const selectedItem = options.find(item => item.value === selected);
              return selectedItem.name;
            }}
            labelId="priority-select-label"
            label={t('notification.createTask.priority')}
            MenuProps={{
              sx: { maxHeight: '300px' },
            }}
            error={errors?.[name]}
            disabled={disabled ?? false}
          >
            {TaskPriorityList?.map((item, index) => {
              return (
                <MenuItem
                  sx={{ maxWidth: '200px' }}
                  disableTouchRipple
                  key={index}
                  value={item.value}
                  aria-labelledby={item.name}
                  onClick={() => handleChange(item)}
                >
                  {
                    <>
                      {item.value === TaskPriority.High.toString() ? (
                        <FlagRoundedIcon
                          sx={{
                            color: theme => theme.palette.system.darkRed,
                          }}
                        />
                      ) : (
                        <OutlinedFlagRoundedIcon
                          sx={{
                            color: theme =>
                              item.value === TaskPriority.Normal.toString()
                                ? theme.palette.system.neutralGray
                                : theme.palette.system.main,
                          }}
                        />
                      )}
                      <Typography variant="body1" sx={{ paddingLeft: '14px' }}>
                        {item.name}
                      </Typography>
                      {value === item.value && <CheckIcon className={classes.checkmark} />}
                    </>
                  }
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    />
  );
};
