import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import EMRList from 'app/assets/jsons/EMR.json';
import reasonForMaxFilesSent from 'app/assets/jsons/OrderCreation/ReasonForMaxFilesSent.json';
import orderReason from 'app/assets/jsons/OrderCreation/OrderReason.json';
import { IOrderInitialState, initialState } from './OrderCreation.redux';
import { formatTypeAheadEMR } from 'app/helpers/typeAheadHelper';
import { formatShiftInputData } from '../OrderCreationForm/helper';

const selectDomain = (state: RootState) => state.orderCreation || initialState;

export const selectCreatedOrderId = createSelector([selectDomain], (createOrderDataState: IOrderInitialState) =>
  createOrderDataState.stage === 'orderPreview' && createOrderDataState.orderId > 0
    ? createOrderDataState.orderId
    : null,
);
export const selectCreatedUnitId = createSelector(
  [selectDomain],
  (createOrderDataState: IOrderInitialState) => createOrderDataState.steps?.selectUnit?.createdUnitId ?? undefined,
);
export const selectEditedUnitId = createSelector(
  [selectDomain],
  (createOrderDataState: IOrderInitialState) => createOrderDataState.steps?.selectUnit?.editUnitId ?? undefined,
);
export const selectProceedToUnitCreation = createSelector(
  [selectDomain],
  (createOrderDataState: IOrderInitialState) => createOrderDataState.steps?.selectUnit?.proceedToUnitCreation ?? false,
);
export const selectStage = createSelector(
  [selectDomain],
  (createOrderDataState: IOrderInitialState) => createOrderDataState.stage,
);
export const selectFinalStage = createSelector(
  [selectDomain],
  (createOrderDataState: IOrderInitialState) => createOrderDataState.finalStage,
);
export const selectStep = createSelector([selectDomain], createOrderDataState => createOrderDataState.steps);
export const selectFilterCertificationsDecision = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.certificationsFlagDecision,
);
export const selectCurrentStep = createSelector([selectDomain], createOrderDataState => {
  switch (createOrderDataState.stage) {
    case 'selectUnit': {
      return createOrderDataState.steps?.selectUnit;
    }
    case 'editOrderRequrement': {
      return createOrderDataState.steps?.editOrderRequrement;
    }
    case 'editOrderDetails': {
      return createOrderDataState.steps?.editOrderDetails;
    }
    case 'orderInformation': {
      return createOrderDataState.steps?.orderInformation;
    }
    case 'orderBillRate': {
      return createOrderDataState.steps?.orderBillRate;
    }
    case 'orderPreview': {
      return createOrderDataState.steps?.orderPreview;
    }
    default: {
      return createOrderDataState.steps?.selectUnit;
    }
  }
});
export const selectPreviousStep = createSelector([selectDomain], createOrderDataState => {
  switch (createOrderDataState.stage) {
    case 'editOrderRequrement': {
      return createOrderDataState.steps?.selectUnit;
    }
    case 'orderInformation': {
      if (
        createOrderDataState.steps?.editOrderRequrement?.editMode === undefined ||
        createOrderDataState.steps?.editOrderRequrement?.editMode === null
      ) {
        return createOrderDataState.steps?.selectUnit;
      } else {
        return createOrderDataState.steps?.editOrderRequrement;
      }
    }
    case 'editOrderDetails': {
      return createOrderDataState.steps?.editOrderDetails;
    }
    case 'orderBillRate': {
      return createOrderDataState.steps?.orderInformation;
    }
    case 'orderPreview': {
      return createOrderDataState.steps?.orderInformation;
    }
    default: {
      return createOrderDataState.steps?.selectUnit;
    }
  }
});
export const selectPreviousStepName = createSelector([selectDomain], createOrderDataState => {
  switch (createOrderDataState.stage) {
    case 'editOrderRequrement': {
      return 'selectUnit';
    }
    case 'orderInformation': {
      if (
        createOrderDataState.steps?.editOrderRequrement?.editMode === undefined ||
        createOrderDataState.steps?.editOrderRequrement?.editMode === null
      ) {
        return 'selectUnit';
      } else {
        return 'editOrderRequrement';
      }
    }
    case 'editOrderDetails': {
      return 'editOrderDetails';
    }
    case 'orderBillRate': {
      return 'orderInformation';
    }
    case 'orderPreview': {
      return 'orderBillRate';
    }
    default: {
      return 'selectUnit';
    }
  }
});
export const selectPreviousStepNameWithoutRateCard = createSelector([selectDomain], createOrderDataState => {
  switch (createOrderDataState.stage) {
    case 'editOrderRequrement': {
      return 'selectUnit';
    }
    case 'orderInformation': {
      if (
        createOrderDataState.steps?.editOrderRequrement?.editMode === undefined ||
        createOrderDataState.steps?.editOrderRequrement?.editMode === null
      ) {
        return 'selectUnit';
      } else {
        return 'editOrderRequrement';
      }
    }
    case 'editOrderDetails': {
      return 'editOrderDetails';
    }
    case 'orderPreview': {
      return 'orderInformation';
    }
    default: {
      return 'selectUnit';
    }
  }
});
export const selectCurrentStepCount = createSelector([selectDomain], createOrderDataState => {
  switch (createOrderDataState.stage) {
    case 'selectUnit': {
      return 0;
    }
    case 'editOrderRequrement': {
      return 0;
    }
    case 'orderInformation': {
      return 1;
    }
    case 'orderBillRate': {
      return 2;
    }
    case 'orderPreview': {
      return 3;
    }
    default: {
      return 0;
    }
  }
});
export const selectCurrentStepCountWithoutRateCard = createSelector([selectDomain], createOrderDataState => {
  switch (createOrderDataState.stage) {
    case 'selectUnit': {
      return 0;
    }
    case 'editOrderRequrement': {
      return 0;
    }
    case 'orderInformation': {
      return 1;
    }
    case 'orderPreview': {
      return 2;
    }
    default: {
      return 0;
    }
  }
});
export const selectCurrentStepDisplayTitle = createSelector([selectDomain], createOrderDataState => {
  switch (createOrderDataState.stage) {
    case 'selectUnit': {
      return 'Define Unit';
    }
    case 'editOrderRequrement': {
      return 'Define Unit';
    }
    case 'orderInformation': {
      return 'Order Details';
    }
    case 'orderBillRate': {
      return 'Rate Card';
    }
    case 'orderPreview': {
      return 'Preview & Submit';
    }
    default: {
      return 'Define Unit';
    }
  }
});
export const selectStepsDisplayTitles = createSelector([selectDomain], createOrderDataState => [
  'Define Unit',
  'Order Details',
  'Rate Card',
  'Preview & Submit',
]);
export const selectStepsDisplayTitlesWithoutRateCard = createSelector([selectDomain], createOrderDataState => [
  'Define Unit',
  'Order Details',
  'Preview & Submit',
]);
export const selectMessage = createSelector([selectDomain], createOrderDataState => createOrderDataState.message);
export const selectStatus = createSelector([selectDomain], createOrderDataState => createOrderDataState.status);
export const selectError = createSelector([selectDomain], createOrderDataState => ({
  error: createOrderDataState.error,
  isError: createOrderDataState.isError,
}));
export const selectActionButtons = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.actionButtons,
);
export const selectNewOrder = createSelector([selectDomain], createOrderDataState => createOrderDataState.newOrder);
export const selectOrderDetails = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.orderDetails,
);
export const selectNewUnit = createSelector([selectDomain], createOrderDataState => createOrderDataState.newUnit);
export const selectEditUnit = createSelector([selectDomain], createOrderDataState => createOrderDataState.editUnit);
export const selectOrderType = createSelector([selectDomain], createOrderDataState =>
  createOrderDataState.newOrder ? createOrderDataState.newOrder.type : 'Travel',
);
export const selectFacilityDetails = createSelector([selectDomain], createOrderDataState => ({
  facilityId: createOrderDataState.facilityId,
  facilityData: createOrderDataState.facilityData,
}));
export const selectShowEditModeDialog = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.selectUnit.showEditModeDialog,
);
export const selectOrderRequirementTypes = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.editOrderRequrement.requirementTypes,
);
export const selectOrderRequirementLookUps = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.editOrderRequrement.lookUps,
);
export const selectOrderRequirementMandatoryFieldsStatus = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.editOrderRequrement.isMandatoryFieldsFilled,
);
export const selectOrderInformationMandatoryFieldsStatus = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.orderInformation.isMandatoryFieldsFilled,
);
export const selectMandatoryFieldsStatus = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.isMandatoryFieldsFilled,
);
export const selectIncompleteReqFields = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.incompleteReqFields,
);
export const selectOrderRequirementEditOrderRequrementMode = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.editOrderRequrement.editMode,
);
export const selectHasNotNow = createSelector(
  [selectDomain],
  createOrderDataState =>
    createOrderDataState.steps.editOrderRequrement.editMode &&
    createOrderDataState.steps.editOrderRequrement.editMode === 'notNow',
);
export const selectHasStandardize = createSelector(
  [selectDomain],
  createOrderDataState =>
    createOrderDataState.steps.editOrderRequrement.editMode &&
    createOrderDataState.steps.editOrderRequrement.editMode === 'standardize',
);
export const selectDisciplineOpts = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.editOrderRequrement.disciplineOpts,
);
export const selectSpecialtyOpts = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.editOrderRequrement.specialtyOpts,
);
export const selectSubSpecialtyOpts = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.editOrderRequrement.subSpecialtyOpts,
);
export const selectDivisionDisciplineOpts = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.editOrderRequrement.divisionDisciplineOpts,
);
export const selectSkillSet = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.editOrderRequrement.skillSet,
);
export const selectShifts = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.editOrderRequrement.shifts,
);

// License
export const selectLicenseSearchQuery = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.editOrderRequrement.licenseSearchQuery,
);
export const selectLoadingLicenseRequirements = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.editOrderRequrement.loadingLicenseRequirements,
);
export const selectSavedLicenseRequirements = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.orderDetails.unit.qualification.licenses,
);

//Certification
export const selectCertificationRequrements = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.editOrderRequrement.certificationRequrements,
);
export const selectCertificationRequrementsMaster = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.editOrderRequrement.certificationRequrementsMaster,
);
export const selectCertificationSearchQuery = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.editOrderRequrement.certificationSearchQuery,
);
export const selectLoadingCertificationRequrements = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.editOrderRequrement.loadingCertificationRequrements,
);

//SkillCheckList
export const selectSkillCheckListRequrements = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.editOrderRequrement.skillCheckListRequrements,
);
export const selectSkillCheckListRequrementsMaster = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.editOrderRequrement.skillCheckListRequrementsMaster,
);
export const selectSkillCheckListRequrementsChips = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.editOrderRequrement.skillCheckListRequrementsChips,
);
export const selectSkillCheckListSearchQuery = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.editOrderRequrement.skillCheckListSearchQuery,
);
export const selectLoadingSkillCheckListRequrements = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.editOrderRequrement.loadingSkillCheckListRequrements,
);

//emr
export const selectEmrYesNoOptionSelected = createSelector([selectDomain], createOrderDataState =>
  createOrderDataState.newOrder?.qualification?.emr &&
  createOrderDataState.newOrder?.qualification?.emr.values &&
  (createOrderDataState.newOrder?.qualification?.emr.values.length > 0 ||
    (createOrderDataState.newOrder?.qualification?.emr.isRequired !== null &&
      createOrderDataState.newOrder?.qualification?.emr.isRequired !== undefined))
    ? true
    : false,
);
export const selectEmrOptionsList = createSelector([selectDomain], createOrderDataState => formatTypeAheadEMR(EMRList));

//TravelExperience
export const selectTravelExperienceYesNoOptionSelected = createSelector([selectDomain], createOrderDataState =>
  createOrderDataState.newOrder?.travelExperience ? true : false,
);

//const defaultTypeValue = orderTypes.find(order => facilityDivision === HSG ? order.Description.toUpperCase() === STRIKE : order[0]  );
//const defaultRegionValue = regions.find(region => facilityDivision === HSG ?  region.Description.toUpperCase() === 'UNASSIGNED' : null );

//order information

export const selectFormatShiftInputData = createSelector(
  [selectDomain],
  createOrderDataState =>
    (createOrderDataState.newOrder && createOrderDataState.newOrder.shifts.map(item => formatShiftInputData(item))) ||
    [],
);
export const selectReasonForMaxFilesSent = createSelector(
  [selectDomain],
  createOrderDataState => reasonForMaxFilesSent,
);
export const selectOrderReason = createSelector([selectDomain], createOrderDataState => orderReason);

export const selectIsUnitNameError = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.createUnitDetails.isUnitNameError ?? false,
);
export const selectIsEditUnitNameError = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.steps.editUnitDetails.isUnitNameError ?? false,
);

export const selectValidPlacementDate = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.isValidPlacementDate,
);

export const selectOrderPriorities = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.orderPriorities,
);

export const selectBestBets = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.bestBets,
);

export const selectTravelExperiences = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.travelExperiences,
);

export const selectInternalNotesFlag = createSelector(
  [selectDomain],
  createOrderDataState => createOrderDataState.internalNotesFlag,
);
