import { Expandable } from '@AMIEWEB/Common';
import { ActiveSubmissions } from '@AMIEWEB/Common/SubmissionPreference/SubmissionLimit/ActiveSubmissions';
import { AutomatePlacement } from '@AMIEWEB/Common/SubmissionPreference/SubmissionLimit/SendPacketAutomation';
import { Title } from '@AMIEWEB/Order/OrderDetails/OrderPreferences/customComponents/OrderPreferencesDesign';
import { Box, Grid, Typography } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BoxContainer } from '@AMIEWEB/Common/SubmissionPreference/Common/BoxContainer';
import HdrAutoOutlinedIcon from '@mui/icons-material/HdrAutoOutlined';
import { useDecision } from '@optimizely/react-sdk';
import { FormActions } from '@AMIEWEB/Order/OrderDetails/OrderPreferences/customComponents';
import { makeStyles } from 'tss-react/mui';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';

const useStyles = makeStyles()(theme => ({
  btn: {
    padding: '6px 16px',
    marginTop: '10px',
  },
  titleBox: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
}));

interface ISubmissionLimitsProps {
  clientContacts: any[];
  flag?: string;
  isStrike: boolean;
  activePlacementCounts: {
    rfo: number;
    sent: number;
    others: number;
  } | null;
  isFetchingPlacementCounts: boolean;
  updateContainerState: Function;
  submissionEmailSetup: string;
  submissionPacketSetup: string;
  isDirty?: boolean;
  isSaving?: boolean;
  showActionItems?: boolean;
  isFetchingPreferences?: boolean;
  isActivePlacementDisabled?: boolean;
  resetForm?: () => void;
}

export const SubmissionLimitsWrapper = ({
  clientContacts,
  flag,
  isStrike,
  activePlacementCounts,
  isFetchingPlacementCounts,
  updateContainerState,
  submissionEmailSetup,
  submissionPacketSetup,
  isDirty,
  isSaving,
  showActionItems,
  isFetchingPreferences,
  isActivePlacementDisabled,
  resetForm,
}: ISubmissionLimitsProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [rfoFlag] = useDecision(flag);

  return (
    <Expandable expanded titleComponent={<Title>{t('Submission Limits')}</Title>} unmountOnExit={false}>
      <Grid container height={360} spacing={2}>
        <Grid item xs={4}>
          <BoxContainer
            title={
              <Box sx={{ display: 'flex', gap: '10px' }}>
                <HdrAutoOutlinedIcon />
                <Typography>
                  {t('order.orderDetails.preferences.submission.submissionLimits.automatePlacement')}
                </Typography>
              </Box>
            }
          >
            <AutomatePlacement
              isFetchingPreferences={isFetchingPreferences}
              rfoFlag={!isNullOrUndefined(flag) ? rfoFlag : undefined}
              isStrike={isStrike}
              updateConatinerState={updateContainerState}
              clientContacts={clientContacts}
              submissionPacketSetup={submissionPacketSetup}
              submissionEmailSetup={submissionEmailSetup}
            />
          </BoxContainer>
        </Grid>
        <Grid item xs={8}>
          <ActiveSubmissions
            activePlacementCounts={activePlacementCounts}
            promiseInProgress={isFetchingPlacementCounts}
            disabled={isActivePlacementDisabled}
          />
        </Grid>
      </Grid>
      {!rfoFlag.enabled && showActionItems && (
        <Grid item>
          <FormActions
            isSaving={isSaving}
            btnRootClass={classes.btn}
            onCancel={resetForm}
            disableSubmit={!isDirty}
            disableCancel={!isDirty}
          />
        </Grid>
      )}
    </Expandable>
  );
};
