import React from 'react';
import { TreeItem } from '@mui/lab';
import { useTreeItemStyles } from './StyledTreeItem.styles';
import { Typography, Checkbox } from 'amn-ui-core';
import Highlighter from 'react-highlight-words';

export const StyledTreeItem = props => {
  const { classes } = useTreeItemStyles();
  const {
    labelText,
    parent,
    data,
    checked,
    disabled,
    nodeType,
    updateAssociatedNodes = (data: any) => {},
    isTreeFiltered,
    searchText,
    ...other
  } = props;
  const [isChecked, setChecked] = React.useState(checked);

  const toggleChecked = data => {
    setChecked(prev => !prev);
    updateAssociatedNodes(data, !checked);
  };

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          {!parent && (
            <div className={nodeType == 3 ? classes.subSpecialty : ''}>
              <Typography
                variant="caption"
                color="inherit"
                style={{ padding: 0 }}
                sx={{ cursor: disabled ? 'default' : 'pointer' }}
              >
                <Checkbox checked={isChecked} disabled={disabled} onClick={() => toggleChecked(data)} />
              </Typography>
            </div>
          )}
          {!isTreeFiltered ? (
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
          ) : (
            <div className={classes.labelText}>
              <Highlighter
                highlightClassName={classes.labelText}
                searchWords={[`${searchText}`]}
                autoEscape={true}
                textToHighlight={labelText}
              />
            </div>
          )}
        </div>
      }
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
};
