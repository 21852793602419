import { put, call, getContext, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { trackPromise } from 'react-promise-tracker';
import { httpSuccess } from 'app/services/serviceHelpers';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { placementModsAction } from './slice';
import { PlacementModsService } from 'app/services/PlacementModsServices/placementmods-service';
import {
  getFormattedStartDate,
  getFormattedEndDate,
  getFormattedMinReqHours,
  getFomattedPayRate,
  getFormattedLodging,
  getFormattedInsurance,
  getFomattedBillRate,
  getFormattedPto,
  getFormattedOgpInternationalLoan,
  getFormattedReimbursement,
  getFomattedTimeOff,
  getFormattedBonus,
  getFormattedEmiLmo,
  getFormattedHousing,
  getFomattedFurniture,
  getFormattedPreferences,
  getFormatteTravelAllowance,
  getFormattedShift,
  getFormattedPayrollShiftDifferential,
  getFormattedBillingShiftDifferential,
} from './helper';
import { SharedService } from 'app/services/SharedServices/shared-service';
import {
  PLACEMENT_MODS_CONSTANTS,
  TemplateTypes,
} from '@AMIEWEB/Placement/PlacementDetails/PlacementTabPanel/ModificationsTab/CommonComponents/Utils';
import { selectPayRateGww, selectPricingReason } from './selectors';

const TrackModDetailsAPI = (fn, ...args) => trackPromise(fn(...args), 'mod-details-api');
const TrackPlacementModsList = (fn, ...args) => trackPromise(fn(...args), 'placement-mods-list');
const TrackRefreshPlacementModList = (fn, ...args) => trackPromise(fn(...args), 'refresh-placement-mods-list');
const PayRateGwwAPI = (fn, ...args) => trackPromise(fn(...args), 'pay-rate-gww');
const TrackTimeOffGrid = (fn, ...args) => trackPromise(fn(...args), 'timeoff-details-api');
const TrackFurnitureGrid = (fn, ...args) => trackPromise(fn(...args), 'furniture-details-api');
const TrackPreferencesAPI = (fn, ...args) => trackPromise(fn(...args), 'preferences-details-api');
const TrackPayrollShiftDifferentialGrid = (fn, ...args) => trackPromise(fn(...args), 'payroll-shift-differential-api');
const TrackBillingShiftDifferentialGrid = (fn, ...args) => trackPromise(fn(...args), 'billing-shift-differential-api');
const TrackModifications = (fn, ...args) => trackPromise(fn(...args), 'get-placement-modification');

export function* requestModifications(
  action: PayloadAction<{
    placementId: number;
    pageNumber: number;
    pageSize: number;
    gridType: string;
    sortColumn: string;
    sortOrder: string;
  }>,
) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(TrackModifications, placementService.getPlacementModifications, action.payload);
    if (httpSuccess(response?.status)) {
      const orderBonuses = response.data;
      if (!action.payload.gridType || action.payload.gridType === PLACEMENT_MODS_CONSTANTS.CURRENT) {
        yield put(placementModsAction.setModificationHistory(orderBonuses));
      } else {
        yield put(placementModsAction.setPendingMods(orderBonuses));
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getModifications',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestPlacementModsList(action: PayloadAction<any>) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(
      TrackPlacementModsList,
      placementService.getPlacementModsList,
      action.payload.placementId,
    );
    if (httpSuccess(response.status) && response.data) {
      yield put(placementModsAction.setPlacementModsList(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestPlacementModsList',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestRefreshPlacementModList(action: PayloadAction<{ placementId }>) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(
      TrackRefreshPlacementModList,
      placementService.getRefreshPlacementModList,
      action.payload.placementId,
    );
    if (httpSuccess(response.status) && response.data) {
      yield put(placementModsAction.setRefreshPlacementModList(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestRefreshPlacementModsList',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestStartDateModDetails(action: PayloadAction<{ placementId; travelerOptionId; newMod }>) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(
      TrackModDetailsAPI,
      action.payload.newMod ? placementService.getStartDateModDetailsNew : placementService.getStartDateModDetails,
      action.payload.placementId,
      action.payload.travelerOptionId,
    );
    if (httpSuccess(response.status) && response.data) {
      const getFormattedData = getFormattedStartDate(response.data);
      yield put(placementModsAction.setDefaultModDetails(getFormattedData));
      yield put(placementModsAction.setCurrentModsDetails(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestStartDateModDetails',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestInsuranceModDetails(action: PayloadAction<{ placementId; travelerOptionId; newMod }>) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(
      TrackModDetailsAPI,
      action.payload.newMod ? placementService.getInsuranceModDetailsNew : placementService.getInsuranceModDetails,
      action.payload.placementId,
      action.payload.travelerOptionId,
    );
    if (httpSuccess(response.status) && response.data) {
      const getFormattedData = getFormattedInsurance(response.data);
      yield put(placementModsAction.setDefaultModDetails(getFormattedData));
      yield put(placementModsAction.setCurrentModsDetails(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestInsuranceDateModDetails',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestStartDateReasonCodes() {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(TrackModDetailsAPI, placementService.getStartDateReasonCode);
    if (response && httpSuccess(response.status) && response.data) {
      const reasonCodes = response.data?.map(code => ({
        ID: code.key,
        Description: code.value,
      }));
      yield put(placementModsAction.setStartDateReasonCode(reasonCodes));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestStartDateReasonCodes',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestEndDateModDetails(action: PayloadAction<{ placementId; travelerOptionId; newMod }>) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(
      TrackModDetailsAPI,
      action.payload.newMod ? placementService.getEndDateModDetailsNew : placementService.getEndDateModDetails,
      action.payload.placementId,
      action.payload.travelerOptionId,
    );
    if (httpSuccess(response.status) && response.data) {
      const getFormattedData = getFormattedEndDate(response.data);
      yield put(placementModsAction.setDefaultModDetails(getFormattedData));
      yield put(placementModsAction.setCurrentModsDetails(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestEndDateModDetails',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestMinReqHoursModDetails(action: PayloadAction<{ placementId; travelerOptionId; newMod }>) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(
      TrackModDetailsAPI,
      action.payload.newMod ? placementService.getMinReqHoursModDetailsNew : placementService.getMinReqHoursModDetails,
      action.payload.placementId,
      action.payload.travelerOptionId,
    );
    if (httpSuccess(response.status) && response.data) {
      const getFormattedData = getFormattedMinReqHours(response.data);
      yield put(placementModsAction.setDefaultModDetails(getFormattedData));
      yield put(placementModsAction.setCurrentModsDetails(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestMinReqHoursModDetails',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestTravelAllowanceModDetails(
  action: PayloadAction<{ placementId: number; travelerOptionId: number; modEnum: number; newMod: boolean }>,
) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(
      TrackModDetailsAPI,
      action.payload.newMod
        ? placementService.getNewEmptyTravelAllowanceModDetail
        : placementService.getTravelAllowanceModDetail,
      action.payload.placementId,
      action.payload.travelerOptionId,
      action.payload.modEnum,
    );
    if (httpSuccess(response.status) && response.data) {
      const fomattedData = getFormatteTravelAllowance(response.data, action.payload.newMod);
      yield put(placementModsAction.setDefaultModDetails(fomattedData));
      yield put(placementModsAction.setCurrentModsDetails(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestTravelAllowanceModDetails',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestShiftModDetails(
  action: PayloadAction<{ placementId: number; travelerOptionId: number; newMod: boolean }>,
) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(
      TrackModDetailsAPI,
      action.payload.newMod ? placementService.geShiftModDetailsNew : placementService.getShiftModDetail,
      action.payload.placementId,
      action.payload.travelerOptionId,
    );
    if (httpSuccess(response.status) && response.data) {
      const fomattedData = getFormattedShift(response.data);
      yield put(placementModsAction.setDefaultModDetails(fomattedData));
      yield put(placementModsAction.setCurrentModsDetails(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestShiftModDetails',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestPayRateModDetails(action: PayloadAction<{ placementId; travelerOptionId; newMod }>) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(
      TrackModDetailsAPI,
      action.payload.newMod ? placementService?.getPayRateModDetailsNew : placementService?.getPayRateModDetails,
      action.payload.placementId,
      action.payload.travelerOptionId,
    );
    if (httpSuccess(response.status) && response.data) {
      const getGwwPayRate = yield select(selectPayRateGww);
      const getFormattedData = getFomattedPayRate(response.data, getGwwPayRate);
      yield put(placementModsAction.setDefaultModDetails(getFormattedData));
      yield put(placementModsAction.setCurrentModsDetails(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestPayRateModDetails',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestAdditionalPremiumPayRate() {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(TrackModDetailsAPI, placementService.getAdditionalPremiumPayRate);
    if (httpSuccess(response.status) && response.data) {
      const reasonCodes = response.data?.map(code => ({
        Id: code.key,
        Description: parseFloat(code.value),
      }));
      yield put(placementModsAction.setAdditionalPremiumPay(reasonCodes));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestAdditionalPremiumPayRate',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestLodgingModDetails(action: PayloadAction<{ placementId; travelerOptionId; newMod }>) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(
      TrackModDetailsAPI,

      action.payload.newMod ? placementService.getLodgingModDetailsNew : placementService.getLodgingModDetails,
      action.payload.placementId,
      action.payload.travelerOptionId,
    );
    if (httpSuccess(response.status) && response.data) {
      const getFormattedData = getFormattedLodging(response.data);
      yield put(placementModsAction.setDefaultModDetails(getFormattedData));
      yield put(placementModsAction.setCurrentModsDetails(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getLodgingModDetails',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}
export function* requestExceptionsPayRateGww(action: PayloadAction<{ excludeOther }>) {
  try {
    const sharedService: SharedService = yield getContext('sharedService');
    const response = yield call(PayRateGwwAPI, sharedService.getExceptionsPayRateGww, action.payload.excludeOther);
    if (httpSuccess(response.status) && response.data) {
      yield put(placementModsAction.setExceptionPayrateGww(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestExceptionsPayRateGww',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestPtoModDetails(
  action: PayloadAction<{ placementId: number; travelerOptionId: number; newMod?: boolean }>,
) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(
      TrackModDetailsAPI,
      action.payload.newMod ? placementService.getPtoModDetailNew : placementService.getPtoModDetail,
      action.payload.placementId,
      action.payload.travelerOptionId,
    );
    if (httpSuccess(response.status) && response.data) {
      const fomattedData = getFormattedPto(response.data);
      yield put(placementModsAction.setDefaultModDetails(fomattedData));
      yield put(placementModsAction.setCurrentModsDetails(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestPtoModDetails',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestEffectiveDateTypes() {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(TrackModDetailsAPI, placementService.getEffectiveDateTypes);
    if (httpSuccess(response.status) && response.data) {
      const effectiveTypes = response.data?.map(code => ({
        ID: code.key,
        Description: code.value,
      }));
      yield put(placementModsAction.setEffectiveDateTypes(effectiveTypes));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestEffectiveDateTypes',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestInsuranceTypes() {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(TrackModDetailsAPI, placementService.getInsuranceTypes);
    if (httpSuccess(response.status) && response.data) {
      const insuranceTypes = response.data?.map(code => ({
        ID: code.key,
        Description: code.value,
      }));
      yield put(placementModsAction.setInsuranceType(insuranceTypes));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestInsuranceTypes',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestBillRateModDetails(action: PayloadAction<{ placementId; travelerOptionId; newMod }>) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(
      TrackModDetailsAPI,
      action.payload.newMod ? placementService?.getBillRateModDetailsNew : placementService?.getBillRateModDetails,
      action.payload.placementId,
      action.payload.travelerOptionId,
    );
    if (httpSuccess(response.status) && response.data) {
      const gwwExceptions = yield select(selectPayRateGww);
      const pricingReasons = yield select(selectPricingReason);
      const getFormattedData = getFomattedBillRate(response.data, gwwExceptions, pricingReasons);
      yield put(placementModsAction.setDefaultModDetails(getFormattedData));
      yield put(placementModsAction.setCurrentModsDetails(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestPayRateModDetails',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestPricingReason() {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(TrackModDetailsAPI, placementService.getPricingReason);
    if (httpSuccess(response.status) && response.data) {
      const reasonCodes = response.data?.map(code => ({
        Id: code.key,
        Description: code.value,
      }));
      yield put(placementModsAction.setPricingReasons(reasonCodes));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestPricingReason',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestRateShiftList() {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(TrackModDetailsAPI, placementService.getRateShiftList);
    if (httpSuccess(response.status) && response.data) {
      const rateShiftNullIdDescription = {
        ID: null,
        Description: '',
      };
      const rateShiftList = response.data?.map(code => ({
        ID: code.id,
        Description: code.description,
      }));
      yield put(placementModsAction.setRateShiftList([rateShiftNullIdDescription, ...rateShiftList]));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestRateShiftList',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestCancellationHoursFrequencyList() {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(TrackModDetailsAPI, placementService.getCancellationHoursFrequencyList);
    if (httpSuccess(response.status) && response.data) {
      const cancellationHoursNullIdDescription = {
        ID: null,
        Description: '',
      };
      const cancellationHoursFrequencyList = response.data?.map(code => ({
        ID: code.id,
        Description: code.description,
      }));
      yield put(
        placementModsAction.setCancellationHoursFrequencyList([
          cancellationHoursNullIdDescription,
          ...cancellationHoursFrequencyList,
        ]),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestCancellationHoursFrequencyList',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestOgpInternationalLoanModDetails(
  action: PayloadAction<{ placementId: number; travelerOptionId: number; newMod?: boolean }>,
) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(
      TrackModDetailsAPI,
      action.payload.newMod
        ? placementService.getOgpInternationalLoanModDetailNew
        : placementService.getOgpInternationalLoanModDetail,
      action.payload.placementId,
      action.payload.travelerOptionId,
    );
    if (httpSuccess(response.status) && response.data) {
      const fomattedData = getFormattedOgpInternationalLoan(response.data);
      yield put(placementModsAction.setDefaultModDetails(fomattedData));
      yield put(placementModsAction.setCurrentModsDetails(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestOgpInternationalLoadModDetails',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestReimbursement(action: PayloadAction<{ placementId; travelerOptionId; newMod }>) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(
      TrackModDetailsAPI,
      action.payload.newMod ? placementService.getReimbursementNew : placementService.getReimbursement,
      action.payload.placementId,
      action.payload.travelerOptionId,
    );
    if (httpSuccess(response.status) && response.data) {
      const getFormattedData = getFormattedReimbursement(response.data);
      yield put(placementModsAction.setDefaultModDetails(getFormattedData));
      yield put(placementModsAction.setCurrentModsDetails(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestReimbursement',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestReimbursementCategoryAndFrequency() {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(TrackModDetailsAPI, placementService.getReimbursementCategoryAndFrequency);
    if (response && httpSuccess(response.status) && response.data) {
      const categories = response.data.map(code => ({
        ID: code.key,
        Description: code.value,
        Active: code.active,
        descriptions: code.descriptions.map(c => ({
          ID: c.key,
          Description: c.value === 'Mileage' ? 'Mileage (Retired)' : c.value,
          CategoryDescriptionId: c.categoryDescriptionId,
          Active: c.active,
        })),
        frequencies: code.frequencies.map(c => ({ ID: c.key, Description: c.value })),
      }));
      yield put(placementModsAction.setReimbursementCategoryAndFrequency(categories));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestReimbursementCategoryAndFrequency',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestPayeeList() {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(TrackModDetailsAPI, placementService.getPayeeList);
    if (response && httpSuccess(response.status) && response.data) {
      const payeeList = response.data.map(code => ({
        ID: code.key,
        Description: code.value,
      }));
      yield put(placementModsAction.setPayeeList(payeeList));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestPayeeList',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestTimeOff(action: PayloadAction<{ placementId; travelerOptionId; newMod }>) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(
      TrackModDetailsAPI,
      TrackTimeOffGrid,
      action.payload.newMod ? placementService.getTimeOffModDetailsNew : placementService.getTimeOffMod,
      action.payload.placementId,
      action.payload.travelerOptionId,
    );
    if (httpSuccess(response.status) && response.data) {
      const getFormattedData = getFomattedTimeOff(response.data);
      yield put(placementModsAction.setDefaultModDetails(getFormattedData));
      yield put(placementModsAction.setCurrentModsDetails(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestTimeOff',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestFurniture(action: PayloadAction<{ placementId; travelerOptionId; newMod }>) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(
      TrackModDetailsAPI,
      TrackFurnitureGrid,
      action.payload.newMod ? placementService.getFurnitureModDetailsNew : placementService.getFurnitureMod,
      action.payload.placementId,
      action.payload.travelerOptionId,
    );
    if (httpSuccess(response.status) && response.data) {
      const getFormattedData = getFomattedFurniture(response.data);
      yield put(placementModsAction.setDefaultModDetails(getFormattedData));
      yield put(placementModsAction.setCurrentModsDetails(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestFurniture',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestBonusModDetails(
  action: PayloadAction<{ placementId: number; travelerOptionId: number; newMod: boolean }>,
) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(
      TrackModDetailsAPI,
      action.payload.newMod ? placementService.getBonusModDetailsNew : placementService.getBonusModDetail,
      action.payload.placementId,
      action.payload.travelerOptionId,
    );
    if (httpSuccess(response.status) && response.data) {
      const fomattedData = getFormattedBonus(response.data, action.payload.newMod);
      yield put(placementModsAction.setDefaultModDetails(fomattedData));
      yield put(placementModsAction.setCurrentModsDetails(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestBonusModDetails',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestEmiLmoModDetails(
  action: PayloadAction<{ placementId: number; travelerOptionId: number; newMod: boolean }>,
) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(
      TrackModDetailsAPI,
      action.payload.newMod ? placementService.getEmiLmoModDetailNew : placementService.getEmiLmoModDetail,
      action.payload.placementId,
      action.payload.travelerOptionId,
    );
    if (httpSuccess(response.status) && response.data) {
      const fomattedData = getFormattedEmiLmo(response.data);
      yield put(placementModsAction.setDefaultModDetails(fomattedData));
      yield put(placementModsAction.setCurrentModsDetails(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestEmiLmoModDetails',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestHousingModDetails(
  action: PayloadAction<{ placementId: number; travelerOptionId: number; newMod: boolean }>,
) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(
      TrackModDetailsAPI,
      action.payload.newMod ? placementService.getHousingModDetailsNew : placementService.getHousingModDetail,
      action.payload.placementId,
      action.payload.travelerOptionId,
    );
    if (httpSuccess(response.status) && response.data) {
      const fomattedData = getFormattedHousing(response.data, action.payload.newMod);
      yield put(placementModsAction.setDefaultModDetails(fomattedData));
      yield put(placementModsAction.setCurrentModsDetails(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestHousingModDetails',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestPreferencesModDetails(
  action: PayloadAction<{ placementId: number; travelerOptionId: number; newMod: boolean }>,
) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(
      TrackModDetailsAPI,
      TrackPreferencesAPI,
      action.payload.newMod ? placementService.getPreferencesModDetailNew : placementService.getPreferencesModDetail,
      action.payload.placementId,
      action.payload.travelerOptionId,
    );
    if (httpSuccess(response.status) && response.data) {
      const fomattedData = getFormattedPreferences(response.data);
      yield put(placementModsAction.setDefaultModDetails(fomattedData));
      yield put(placementModsAction.setCurrentModsDetails(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestPreferencesModDetails',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestInitiators(action: PayloadAction<any>) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(TrackModDetailsAPI, placementService.getInitiators, action.payload.placementId);
    if (response && httpSuccess(response.status) && response.data) {
      const getInitiators = response.data?.map(code => ({
        ID: code.employeeId,
        FirstName: code.firstName,
        LastName: code.lastName,
        Description: code.firstName + ' ' + code.lastName,
      }));
      yield put(placementModsAction.setInitiators(getInitiators));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestInitiateBy',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestPayrollShiftDifferential(action: PayloadAction<{ placementId; travelerOptionId; newMod }>) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(
      TrackModDetailsAPI,
      TrackPayrollShiftDifferentialGrid,
      action.payload.newMod
        ? placementService.getPayrollShiftDifferentialModDetailNew
        : placementService.getPayrollShiftDifferentialModDetail,
      action.payload.placementId,
      action.payload.travelerOptionId,
    );
    if (httpSuccess(response.status) && response.data) {
      response.data?.shiftDifferentials?.map(
        row =>
          (row.selectAll =
            row.monday && row.tuesday && row.wednesday && row.thursday && row.friday && row.saturday && row.sunday
              ? 'Yes'
              : 'No'),
      );
      const getFormattedData = getFormattedPayrollShiftDifferential(response.data);
      yield put(
        placementModsAction.setCustomTemplateType(
          response.data.shiftDifferentialTemplateTypeId?.toString() === TemplateTypes.Custom.toString(),
        ),
      );
      yield put(placementModsAction.setDefaultModDetails(getFormattedData));
      yield put(placementModsAction.setCurrentModsDetails(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestPayrollShiftDifferential',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestShiftDifferentialTypes() {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(TrackModDetailsAPI, placementService.getShiftDifferentialTypes);
    if (response && httpSuccess(response.status) && response.data) {
      const types = response.data.map(code => ({
        ID: code.key,
        Description: code.value,
      }));
      yield put(placementModsAction.setShiftDifferentialTypes(types));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestShiftDifferentialTypes',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestShiftDifferentialDescriptionTypes() {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(TrackModDetailsAPI, placementService.getShiftDifferentialDescriptionTypes);
    if (response && httpSuccess(response.status) && response.data) {
      const descriptionTypes = response.data.map(code => ({
        ID: code.key,
        Description: code.value,
      }));
      yield put(placementModsAction.setShiftDifferentialDescriptionTypes(descriptionTypes));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestshiftDifferentialDescriptionTypes',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestShiftDifferentialTemplateTypes() {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(TrackModDetailsAPI, placementService.getShiftDifferentialTemplateTypes);
    if (response && httpSuccess(response.status) && response.data) {
      const templateTypes = response.data.map(code => ({
        ID: code.key,
        Description: code.value,
      }));
      yield put(placementModsAction.setShiftDifferentialTemplateTypes(templateTypes));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestShiftDifferentialTemplateTypes',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestBillingShiftDifferential(action: PayloadAction<{ placementId; travelerOptionId; newMod }>) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(
      TrackModDetailsAPI,
      TrackBillingShiftDifferentialGrid,
      action.payload.newMod
        ? placementService.getBillingShiftDifferentialModDetailNew
        : placementService.getBillingShiftDifferentialModDetail,
      action.payload.placementId,
      action.payload.travelerOptionId,
    );
    if (httpSuccess(response.status) && response.data) {
      response.data?.shiftDifferentials?.map(
        row =>
          (row.selectAll =
            row.monday && row.tuesday && row.wednesday && row.thursday && row.friday && row.saturday && row.sunday
              ? 'Yes'
              : 'No'),
      );
      const getFormattedData = getFormattedBillingShiftDifferential(response.data);
      yield put(
        placementModsAction.setCustomTemplateType(
          response.data.shiftDifferentialTemplateTypeId?.toString() === TemplateTypes.Custom.toString(),
        ),
      );
      yield put(placementModsAction.setDefaultModDetails(getFormattedData));
      yield put(placementModsAction.setCurrentModsDetails(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestBillingShiftDifferential',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}

export function* requestShiftDifferentialTemplateItems(action: PayloadAction<{ shiftDifferentialTemplateTypeId }>) {
  try {
    const placementService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(
      TrackModDetailsAPI,
      placementService.getShiftDifferentialTemplateItems,
      action.payload.shiftDifferentialTemplateTypeId,
    );
    if (httpSuccess(response.status) && response.data) {
      response.data?.map(
        row =>
          (row.selectAll =
            row.monday && row.tuesday && row.wednesday && row.thursday && row.friday && row.saturday && row.sunday
              ? 'Yes'
              : 'No'),
      );
      yield put(placementModsAction.setShiftDifferentialTemplateItems(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestShiftDifferentialTemplateItems',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}
export function* requestBookingApprovedCheckboxSpecs(action: PayloadAction<{ placementId: number }>) {
  try {
    const modsService: PlacementModsService = yield getContext('placementModsService');
    const response = yield call(modsService.getBookingApproveCheckboxStatus, action.payload.placementId);

    if (httpSuccess(response.status)) yield put(placementModsAction.setBookingApprovedCheckboxSpec(response.data));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestShiftDifferentialTemplateItems',
        area: 'src/store/redux-store/placement-mods/request-saga.ts',
      },
    });
  }
}
