import { put, call, takeLatest, getContext, select } from 'redux-saga/effects';
import { trackPromise } from 'react-promise-tracker';
import { globalActions } from './Global.redux';
import { SharedService } from 'app/services/SharedServices/shared-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import {
  SessionKey,
  StorageType,
  getSessionValue,
  setSessionValue,
} from 'utils/customHooks/sessionStorage/sessionHelpers';
import moment from 'moment';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { PayloadAction } from '@reduxjs/toolkit';
import { trackEvent } from 'app-insights/appInsightsTracking';
import { selectNewAppVersionAvailable } from './Global.selectors';

const trackNewAppVersion = (fn, ...args) => trackPromise(fn(...args), 'new-app-version');

export const versionCheckSecondsInterval = 60;

export function updateAppVersion(BUILD_VERSION?: string) {
  const newVersion = isNullOrUndefined(BUILD_VERSION)
    ? { LAST_VERIFIED: moment().toISOString() }
    : {
        BUILD_VERSION: BUILD_VERSION,
        LAST_VERIFIED: moment().toISOString(),
      };
  setSessionValue(SessionKey.appVersion, newVersion, StorageType.sessionStorage);
}

export function* validateAppVersion(
  action: PayloadAction<
    { interval?: number; forceValidation?: boolean; updateVersionNumberWithoutNotice?: boolean } | undefined
  >,
) {
  try {
    const localVersion: { BUILD_VERSION: string; LAST_VERIFIED: string } | undefined | null = getSessionValue(
      SessionKey.appVersion,
      StorageType.sessionStorage,
    );
    if (!isNullOrUndefined(localVersion?.BUILD_VERSION) && !isNullOrUndefined(localVersion?.LAST_VERIFIED)) {
      const lastVerified = moment(localVersion.LAST_VERIFIED);
      let localBuildVersion: number = parseFloat(`${localVersion.BUILD_VERSION}`.replaceAll('.', ''));
      if (
        lastVerified.isValid() &&
        (action.payload?.forceValidation ||
          lastVerified
            .add(
              !isNullOrUndefined(action.payload?.interval) && !isNaN(action.payload.interval)
                ? action.payload.interval
                : versionCheckSecondsInterval,
              'seconds',
            )
            .isSameOrBefore(moment()))
      ) {
        // It's past the defined interval to check the version. Get the latest version file and validate the version numbers.
        const sharedService: SharedService = yield getContext('sharedService');
        const response = yield call(trackNewAppVersion, sharedService.getAppVersion);

        if (httpSuccess(response?.status)) {
          const serverBuildVersion: number = parseFloat(`${response.data.BUILD_VERSION}`.replaceAll('.', ''));
          if (localBuildVersion < serverBuildVersion) {
            if (action.payload?.updateVersionNumberWithoutNotice) {
              // Version is outdated, but update session storage with new build version without notifying the user.
              // This is used when the page is loaded for the first time or a new window is opened and the latest JS is already available.
              updateAppVersion(`${response.data.BUILD_VERSION}`);
              return;
            } else {
              // Version is outdated. Set redux value that a new version is available
              const newVersionNoticeDelivered = yield select(selectNewAppVersionAvailable);
              if (!newVersionNoticeDelivered) {
                trackEvent({
                  type: 'event',
                  name: 'New version available notice',
                  properties: { localVersion: localVersion.BUILD_VERSION, serverVersion: response.data.BUILD_VERSION },
                });
                yield put(globalActions.setNewAppVersionAvailable(true));
              }
            }
          }
          // Set a new timestamp in session storage for when the version was last checked successfully
          updateAppVersion(localVersion.BUILD_VERSION);
        }
      }
    } else {
      // New tab. Get current version and set it in session storage
      const sharedService: SharedService = yield getContext('sharedService');
      const response = yield call(trackNewAppVersion, sharedService.getAppVersion);
      updateAppVersion(response.data.BUILD_VERSION);
    }
  } catch (error) {
    console.error('ERROR: validateAppVersion', error);
  }
}

export function* globalSaga() {
  yield takeLatest(globalActions.validateAppVersion.type, validateAppVersion);
}
