import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from 'tss-react/mui';
import styledComponent from 'styled-components';

interface Props {
  value: string[];
  showCount?: boolean;
  count?: number;
}

const useStyles = makeStyles()({
  customTooltip: {
    backgroundColor: '#ffffff',
    color: '#0D2A3B',
    opacity: 1,
    textAlign: 'left',
    font: 'normal normal normal 12px/20px IBM Plex Sans',
    letterSpacing: '0px',
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #8C8C8C',
  },
  customArrow: {
    color: '#ffffff',
    opacity: 1,
    '&::before': {
      boxShadow: '0px 3px 6px #00000029',
      border: '1px solid #8C8C8C',
    },
  },
  tooltipPlacementBottom: {
    margin: 0,
  },
  count: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    color: '#003e68',
    fontWeight: 'bold',
    fontSize: '12px',
  },
  wrap: {
    lineHeight: '16px',
  },
});

export const CountToolTip = (props: Props) => {
  const { classes } = useStyles();
  const { value: items } = props;
  return (
    <Tooltip
      title={items.map(item => (
        <div>{item}</div>
      ))}
      arrow
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.customArrow,
        tooltipPlacementBottom: classes.tooltipPlacementBottom,
      }}
    >
      {props.showCount ? (
        <CellContainer>
          <span className={classes.wrap}>
            {items.slice(0, 3).map((item, key) => (
              <span key={key}>
                <Content>{item}</Content>
              </span>
            ))}
          </span>
          {props.showCount && items[3] && (
            <span className={classes.count}>
              <div>{'+' + props.count}</div>
            </span>
          )}
        </CellContainer>
      ) : (
        <CellContainer>
          <Content>{items[0]}</Content>
        </CellContainer>
      )}
    </Tooltip>
  );
};

const CellContainer = styledComponent.div`
  display: flex;
`;
const Content = styledComponent.div`
  text-align: start;
  color: #003e68;
  font-size: 12px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
`;
