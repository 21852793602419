/* eslint-disable @typescript-eslint/naming-convention */
import { createAction } from '@reduxjs/toolkit';

export interface IWritePlacementMode {
  selectedRow;
  placementId;
  formData;
  selectedButton;
  modDetails;
  timeOffEditRequests;
  furnitureEditRequests;
  utilityEditRequests;
  payrollShiftEditRequests;
  reimbursementCategoryAndFrequency;
  preferenceModEditRequests;
  billingShiftEditRequests;
  gwwExceptionsTypes;
  pricingReasons;
}

export interface IUpdateModStatusRequest {
  placementId: number;
  travelerOptionId: number;
  travelerOptionTimestamp: string;
  modTitle: string;
}

const business_setEditPreferenceRows = createAction<any>('SET_EDIT_PREFERENCE_ROWS');
const business_createPlacementMod = createAction<IWritePlacementMode>('CREATE_PLACEMENT_MOD');
const business_updatePlacementMod = createAction<IWritePlacementMode>('UPDATE_PLACEMENT_MOD');
const business_deletePlacementMod = createAction<IUpdateModStatusRequest>('DELETE_PLACEMENT_MOD');
const business_voidPlacementMod = createAction<IUpdateModStatusRequest>('VOID_PLACEMENT_MOD');

export const businessActions = {
  business_setEditPreferenceRows,
  business_createPlacementMod,
  business_updatePlacementMod,
  business_deletePlacementMod,
  business_voidPlacementMod,
};
