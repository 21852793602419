/* eslint-disable @typescript-eslint/naming-convention */
import { OptimizelyDecision, useDecision } from '@optimizely/react-sdk';
import { selectAutomationStatus } from 'app/components/Order/OrderDetails/OrderPreferences/store/OrderPreference.selector';
import { ff_placementStatusChangeKey } from 'app/constants/FeatureFlags/Placement/Keys';
import {
  EPLacementStatusRequirementTooltipType,
  IPlacementRequirementKey,
  IPlacementStatus,
  IPlacementStatusRequirement,
  IPlacementStatusRequirementMessage,
} from 'app/models/Placement/PlacementDetails';
import { selectUser } from 'oidc/user.selectors';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectMiscellaneousData } from 'store/redux-store/new-placement/selectors';
import { selectSubmissionInfo } from 'store/redux-store/placement-summary/selectors';
import { Concatenate } from 'utils/string/string';
import { selectPlacementStatus } from '../../../../store/redux-store/placement-status/selectors';
import { TooltipListContent } from './NextPlacementStatusTooltip';
import {
  isRFO,
  isArchivePlacement,
  isDeclinedByFacility,
  isDeclinedByTraveler,
  isGoodToGo,
  isSubmitPacket,
  isTA,
  isWaitingGoodToGo,
  isOfferDeclinedByFacility,
  isOfferDeclinedByTraveler,
  isOfferBookedByTraveler,
  isOfferByFacility,
  isWFC,
  placementStatusOptions,
  isBooked,
  isBookingApproved,
} from './StatusDefaults';

/**
 * Checks if the next placement status should be disabled from an Optimizely Feature Flag
 * @param decision Optimizely Decision
 * @param status Placement status to check against
 * @returns boolean if the placement should be disabled or not
 */
export const ff_isPlacementStatusDisabled = (
  decision: OptimizelyDecision,
  status: IPlacementStatus,
  currentPlacementStatus: IPlacementStatus,
): boolean => {
  return (
    !decision.enabled ||
    (Object.keys((decision?.variables?.['allowed_status'] ?? {}) as any).length > 0 &&
      !(decision?.variables?.['allowed_status'] as any)?.[
        `${currentPlacementStatus?.activityTypeId}:${currentPlacementStatus?.activityStatusId}`
      ]?.[`${status.activityTypeId}:${status.activityStatusId}`] &&
      !(currentPlacementStatus?.activityTypeId === -1))
  );
};

interface IUsePlacementStatusDisableProps {
  // Status to check against
  status: IPlacementStatus;
  // Whether the placement has failing requirements or not
  hasFailedRequirements: boolean;
  // Whether prios placement status has failing requirements
  hasPriorFailedRequirements?: boolean;
  // Can manually override the status despite failing requirements
  manualAllowed: boolean;
  // Is a strike placement
  isStrike?: boolean;
  // Is this requirement for create placement or not
  isCreatePlacement?: boolean;

  // Failed requirements to grab messages
  failedRequirements?: IPlacementStatusRequirementMessage[];
  createScreenEnabled?: boolean;
  allFailedRequirements?: IPlacementStatusRequirement;
  isRequestedTimeOffDisabled?: boolean;
}

/**
 * Determine if the next placement status selection should be disabled or not
 */
export const usePlacementStatusDisable = ({
  status,
  hasFailedRequirements,
  hasPriorFailedRequirements,
  manualAllowed,
  isStrike,
  isCreatePlacement,
  createScreenEnabled,
  failedRequirements,
  allFailedRequirements,
  isRequestedTimeOffDisabled,
}: IUsePlacementStatusDisableProps) => {
  const [decision] = useDecision(ff_placementStatusChangeKey, { autoUpdate: true });
  //const { submission } = useSelector(selectAutomationStatus);
  const isSubmissionAutomationEnabled = useSelector(selectAutomationStatus);
  const { currentPlacementStatus } = useSelector(selectPlacementStatus);

  // To be used on PlacementDetails screen
  const {
    amnReq: {
      candidate: { audit: pDetailsAudit },
    },
    orderReq: {
      order: { status: mfs },
    },
  } = useSelector(selectSubmissionInfo);

  // To be used on Create Placement screen
  const { isAuditValid } = useSelector(selectMiscellaneousData);
  const { userInfo } = useSelector(selectUser);
  const { t } = useTranslation();

  return React.useMemo(() => {
    let _disabled = false;
    let _tooltipContent: string | React.ReactNode = '';
    const msgList: string[] = [];
    const filteredWFCFailedRequirements = (failedRequirements: IPlacementStatusRequirementMessage[]) => {
      return failedRequirements?.filter(
        item =>
          t(item.message) !== t('placement.profile.reqMessages.1015.2.v1.message') &&
          t(item.message) !== t('placement.profile.reqMessages.1012.2.v2.message'),
      );
    };

    if (isRequestedTimeOffDisabled) {
      _disabled = true;
      msgList.push(t('placement.placementStatusPickList.timeOffDatesNotSelected'));
    }
    
    if(isCreatePlacement && !isStrike && isRFO(status) && failedRequirements?.some(requirement => requirement.message.includes("Available Start Date:"))){
      msgList.push(t('placement.placementStatusPickList.avilableStartDateDateMissing'));
    }
    
    if (ff_isPlacementStatusDisabled(decision, status, currentPlacementStatus)) {
      /** Feature flag to disable or enable statuses */
      _disabled = true;
      if (
        status.activityTypeId === 101 &&
        [
          placementStatusOptions.clientConfirmation.activityStatusId,
          placementStatusOptions.candidateContract.activityStatusId,
          placementStatusOptions.requestContract.activityStatusId,
        ].includes(status.activityStatusId)
      ) {
        msgList.push(t('placement.profile.clientConfirmation.featureDisabled'));
      } else {
        msgList.push(t('placement.placementStatusPickList.transitionIsDisabled'));
      }
    } else if (
      (hasFailedRequirements && !manualAllowed) ||
      (isSubmissionAutomationEnabled && !manualAllowed) ||
      (isTA(currentPlacementStatus) && isWaitingGoodToGo(status) && !manualAllowed)
    ) {
      /** Placement has failed requirements and moving the status manually is not allowed or order has submission automation and moving the status manually is not allowed  */
      if (!isSubmitPacket(status)) {
        /** Not moving status to Submit Packet */
        if (isOfferBookedByTraveler(status) || isOfferByFacility(status)) {
          /** if moving to status to Offer Booked by Traveler or Offer by Facility */
          failedRequirements?.forEach(obj => {
            if (obj.id === '1009') {
              msgList.push(t('placement.placementStatusPickList.facilityStatus'));
            }
            if (obj.id === '1024') {
              msgList.push(t('placement.placementStatusPickList.placementDate'));
            }
          });

          if (msgList.length > 0) _disabled = true;
        } else if (isTA(status)) {
          /** if moving status to Traveler Accepts */
          if (isOfferByFacility(currentPlacementStatus)) {
            /** If moving status to Traveler Accepts and the current status is Offer by Facility */
            failedRequirements?.forEach(obj => {
              if (obj.id === '1009') {
                msgList.push(t('placement.placementStatusPickList.facilityStatus'));
              }
              if (obj.id === '1024') {
                msgList.push(t('placement.placementStatusPickList.placementDate'));
              }
            });
          } else {
            /** If moving status to Traveler Accepts and the current status is NOT Offer by Facility */
            failedRequirements?.forEach(obj => {
              if (obj.message === 'Requirements: Waiting for Clearance') {
                msgList.push(t('placement.placementStatusPickList.RFORequirements'));
              }
            });
            isSubmissionAutomationEnabled && msgList.push(t('placement.placementStatusPickList.automationOn'));

            failedRequirements?.forEach(obj => {
              if (t(obj.tooltip?.message) === t('placement.profile.reqMessages.1024.2.v1.tooltip.message')) {
                msgList.push(t('placement.placementStatusPickList.placementDate'));
              }
              if (obj.id === '1024') {
                msgList.push(t('placement.placementStatusPickList.placementDate'));
              }
            });
          }

          if (msgList.length > 0) _disabled = true;
        } else if (isWFC(status)) {
          /** If moving status to Waiting for Clearance */
          if (failedRequirements?.length > 0) {
            if (!createScreenEnabled) {
              msgList.push(t('placement.placementStatusPickList.WFCRequirements'));
            } else if (createScreenEnabled) {
              const filteredFailedRequirements = filteredWFCFailedRequirements(failedRequirements);

              if (filteredFailedRequirements.length > 0) {
                msgList.push(t('placement.placementStatusPickList.WFCRequirements'));
              }
            }
          }

          if (msgList.length > 0) _disabled = true;
        } else if (isWaitingGoodToGo(currentPlacementStatus) && isGoodToGo(status)) {
          /**If current status is Waiting Good to Go and moving to Good to Go status */
          failedRequirements?.forEach(obj => {
            if (obj.id === '2035') {
              msgList.push(t('placement.placementStatusPickList.goodToGoNotSelected'));
            }
            if (obj.id === '2036') {
              msgList.push(t('placement.placementStatusPickList.QSNotSelected'));
            }
          });

          if (msgList.length > 0) _disabled = true;
        } else if (isWaitingGoodToGo(status) && isStrike) {
          /** If status is moving to Waiting Good to Go and is a Strike order */
          msgList.push(t('placement.placementStatusPickList.automationOnly'));

          if (msgList.length > 0) _disabled = true;
        } else if (isRFO(status)) {
          /** If moving status to Request File Out */
          const { externalSystemDetails } = userInfo;
          let hasMFS = false;

          failedRequirements?.forEach(obj => {
            if (t(obj.message) === t('placement.profile.reqMessages.1012.2.v1.message')) {
              hasMFS = true;
            }
          });

          const isAuditConditionFailing =
            // check if audit condition fails
            (
              isCreatePlacement
                ? !isAuditValid // @ validation service -> var isValid = (sourceData.HoldFileOut > 0 && !sourceData.IgnoreHoldFileOut) ? false : true;
                : pDetailsAudit?.data?.holdFileOut > 0 && !pDetailsAudit?.data?.ignoreHoldFileOut
            )
              ? // check cfo only if audit condition fails
                !externalSystemDetails.cfo
              : false;

          // only add MFS as a hard stop if createScreenEnabled is false
          if (!createScreenEnabled) {
            (mfs?.data === 'Yes' || hasMFS) && msgList.push(t('placement.placementStatusPickList.maxFilesSent'));
            const newPlacementCondition = isCreatePlacement && hasPriorFailedRequirements;
            newPlacementCondition && msgList.push(t('placement.placementStatusPickList.RFORequirements'));
          } else if (createScreenEnabled) {
            const filteredFailedRequirements = filteredWFCFailedRequirements(allFailedRequirements[`1:1`]);

            if (filteredFailedRequirements?.length > 0) {
              msgList.push(t('placement.placementStatusPickList.RFORequirements'));
            }
          }
          isAuditConditionFailing && msgList.push(t('placement.placementStatusPickList.holdFileOut'));

          if(!isStrike && isWFC(currentPlacementStatus) && isRFO(status) && failedRequirements?.filter(x=>x.id === '1002')?.length > 0){
            msgList.push(t('placement.placementStatusPickList.avilableStartDateDateMissing'));
          }

          if (msgList.length > 0) _disabled = true;
        } else if (isBooked(status)) {
          failedRequirements?.forEach(obj => {
            if (obj.id === IPlacementRequirementKey.bookingClientConfirmation)
              msgList.push(t('placement.placementStatusPickList.confirmationNotSent'));
            if (obj.id === IPlacementRequirementKey.bookingCandidateContract)
              msgList.push(t('placement.placementStatusPickList.contractNotRequested'));
          });
          if (msgList.length > 0) _disabled = true;
        } else if (isBookingApproved(status)) {
          failedRequirements?.forEach(obj => {
            if (
              [
                IPlacementRequirementKey.userRole,
                IPlacementRequirementKey.pendingModification,
                IPlacementRequirementKey.inBookedStatus,
              ].includes(obj.id)
            )
              msgList.push(t(obj.message));
            if (msgList.length > 0) _disabled = true;
          });
        } else if (isWaitingGoodToGo(status)) {
          failedRequirements?.forEach(obj => {
            if (obj.id === IPlacementRequirementKey.wgtgUserRole) msgList.push(t(obj.message));
            else if (obj.id === IPlacementRequirementKey.wgtgClientConfirmation)
              msgList.push(t('placement.placementStatusPickList.confirmationNotSent'));
            else if (obj.id === IPlacementRequirementKey.wgtgCandidateContract)
              msgList.push(t('placement.placementStatusPickList.wgtgContractNotCreated'));
          });
          if (msgList.length > 0) _disabled = true;
        }
      }
    } else {
      /**_disabled = true
       * Custom logic per individual status
       * Include any status specific disable from here down
       */
      if (isArchivePlacement(status)) {
        if (
          (!Authorized(
            [userRoles.recruitment, userRoles.recruitment_Leadership, userRoles.recruitment_TeamMember],
            userInfo,
          ) &&
            (isDeclinedByFacility(currentPlacementStatus) || isOfferDeclinedByFacility(currentPlacementStatus))) ||
          (!Authorized(
            [
              userRoles.accountManagement,
              userRoles.accountManagement_Leadership,
              userRoles.accountManagement_TeamMember,
            ],
            userInfo,
          ) &&
            (isDeclinedByTraveler(currentPlacementStatus) || isOfferDeclinedByTraveler(currentPlacementStatus)))
        ) {
          /**
           * User is either not a recuiter and placement is currently Declined by Facility
           * Or
           * User is not an account manager and placement is currently Declined by Traveler
           */
          _disabled = true;
        }
      }

    }
    _tooltipContent =
      msgList.length > 1 ? (
        <TooltipListContent
          tooltip={{
            message: t('placement.placementStatusPickList.disabledDueTo'),
            supportingList: msgList,
            tooltipType: EPLacementStatusRequirementTooltipType.messageWithSupportList,
          }}
        />
      ) : msgList.length === 1 ? (
        Concatenate([t('placement.placementStatusPickList.disabledDueTo'), msgList[0]], ' ')
      ) : (
        ''
      );

    return { disabled: _disabled, toolTipContent: _tooltipContent };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    decision,
    status,
    currentPlacementStatus,
    hasFailedRequirements,
    manualAllowed,
    isSubmissionAutomationEnabled,
    isStrike,
    failedRequirements,
    createScreenEnabled,
    userInfo,
    isCreatePlacement,
    isAuditValid,
    pDetailsAudit?.data?.holdFileOut,
    pDetailsAudit?.data?.ignoreHoldFileOut,
    mfs?.data,
    hasPriorFailedRequirements,
    allFailedRequirements,
    isRequestedTimeOffDisabled,
  ]);
};
