import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { ChipStyle, getChipStyle } from '../../CustomComponents/StatusChip';

export const getExtensionChip = customSwitch({
  // Declined by Facility
  1: getChipStyle(ChipStyle.Red),
  // Declined by Traveler
  2: getChipStyle(ChipStyle.Red),
  // Facility Offered
  3: getChipStyle(ChipStyle.Green),
  // Traveler Accepts
  4: getChipStyle(ChipStyle.Green),
  // Traveler Requested
  5: getChipStyle(ChipStyle.Blue),
  // Booked by Traveler
  6: getChipStyle(ChipStyle.Blue),
  // Extension Approved
  7: getChipStyle(ChipStyle.Green),
  // On Extension
  8: getChipStyle(ChipStyle.Green),
  // Facility Confirms
  9: getChipStyle(ChipStyle.Blue),
  // Canceled by Facility
  10: getChipStyle(ChipStyle.Red),
  // Canceled by Traveler
  11: getChipStyle(ChipStyle.Red),
  // Not available for Extension
  12: getChipStyle(ChipStyle.Grey),
  // Extension Complete --> To be verified in future
  13: getChipStyle(ChipStyle.Green),
  //  Assignment Complete
  14: getChipStyle(ChipStyle.Grey),
})(getChipStyle(ChipStyle.Null));

export const getPlacementStatusChip = customSwitch({
  // Waiting for Clearance
  1: getChipStyle(ChipStyle.Blue),
  // Request File Out
  2: getChipStyle(ChipStyle.Blue),
  // Sent
  3: getChipStyle(ChipStyle.Blue),
  // Declined by Traveler
  4: getChipStyle(ChipStyle.Red),
  // Declined by Facility
  5: getChipStyle(ChipStyle.Red),
  // Interviewed
  6: getChipStyle(ChipStyle.Blue),
  // Request Cancelled
  7: getChipStyle(ChipStyle.Grey),
  // OK to Send
  8: getChipStyle(ChipStyle.Blue),
  // Offer by Facility
  9: getChipStyle(ChipStyle.Blue),
  // Offer Booked by Traveler
  10: getChipStyle(ChipStyle.Blue),
  // Traveler Accepts
  11: getChipStyle(ChipStyle.Blue),
  // Facility Confirms
  12: getChipStyle(ChipStyle.Blue),
  // Booked
  13: getChipStyle(ChipStyle.Blue),
  // Booking Approved
  14: getChipStyle(ChipStyle.Blue),
  // Waiting Good To Go
  15: getChipStyle(ChipStyle.Blue),
  // Interview Scheduled
  16: getChipStyle(ChipStyle.Blue),
  // Postponement Warning
  17: getChipStyle(ChipStyle.Yellow),
  // Good To Go
  18: getChipStyle(ChipStyle.Blue),
  // Canceled By Traveler
  20: getChipStyle(ChipStyle.Red),
  // Canceled By Facility
  21: getChipStyle(ChipStyle.Red),
  // Canceled by AMH
  22: getChipStyle(ChipStyle.Red),
  // On Assignment
  23: getChipStyle(ChipStyle.Green),
  // Assignment Complete
  24: getChipStyle(ChipStyle.Grey),
  // Canceled By Traveler
  25: getChipStyle(ChipStyle.Red),
  // Canceled By Facility
  26: getChipStyle(ChipStyle.Red),
  // Canceled by AMH
  27: getChipStyle(ChipStyle.Red),
  // Canceled by Settlement
  28: getChipStyle(ChipStyle.Red),
  // Canceled by Settlement
  29: getChipStyle(ChipStyle.Red),
})(getChipStyle(ChipStyle.Null));
