/* eslint-disable tss-unused-classes/unused-classes */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, CircularProgress, Divider, Grid, IconButton, Tooltip } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from 'app/assets/images/close-24px.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Avatar, Paper, Tab, Tabs } from 'amn-ui-core';
import { container } from 'styles/styleVariables';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import { Concatenate } from 'utils/string/string';
import { atAGlanceSectionPreview1, missingField } from 'app/constants';
import { getAge, getEmptyOrderDetails, getName, toolTipCheck } from 'app/components/Order/OrderDetails/helper';
import { IOrderDetails, updateLastVerifiedData } from 'app/models/Orders/OrderDetails';
import { GetMomentOfDate } from 'utils/dates/moment';
import { GlanceStatus } from 'app/components/Common/GlanceTile/NewGlanceTile';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import { colors } from 'styles/styleVariables';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AcUnitOutlinedIcon from '@mui/icons-material/AcUnitOutlined';
import { gridStateActions } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import { getNavData } from 'app/components/Candidate/CandidateProfile/CandidateJobMatchGrid/gridUtils';

import { getOrderDetailsById, updateLastVerified } from 'app/services/OrderServices/OrderServices';
import HospitalIcon from 'app/assets/images/hospital.svg';
import { CreatePlacementWrapper } from '../../Placement/CreatePlacement';
import { selectOrderDetails } from 'app/components/Order/Store/Order.selectors';
import { orderDataActions } from 'app/components/Order/Store/Order.redux';
import {
  selectInterestDrawerData,
  selectJobInterestsGridData,
} from 'app/components/Candidate/CandidateProfile/CandidateJobInterestGrid/JobInterest.selector';
import {
  selectJobMatchesGridData,
  selectMatchDrawerData,
} from 'app/components/Candidate/CandidateProfile/CandidateJobMatchGrid/JobMatch.selectors';
import { jobInterestActions } from 'app/components/Candidate/CandidateProfile/CandidateJobInterestGrid/JobInterest.redux';
import { jobMatchActions } from 'app/components/Candidate/CandidateProfile/CandidateJobMatchGrid/JobMatch.redux';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { convertToFormat, convertToPST } from 'app/helpers/dateHelper';
import { selectUser } from 'oidc/user.selectors';
import { candidateDetailsSelection } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { breadcrumbActions } from 'app/components/Common/BreadcrumbNavigation/BreadcrumbNavigation.redux';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { withReadOnly } from 'oidc/withReadOnly';
import { globalSearchActions } from 'store/redux-store/global-search/slice';
import { selectSearchResults } from 'store/redux-store/global-search/selectors';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { isStrikOrder } from 'utils/common/Placements/Automation';
import { navigationProfilesAction } from '../store/NavigationProfiles.redux';
import { newPlacementActions } from 'store/redux-store/new-placement/slice';
import { CreatePlacementBtn } from '@AMIEWEB/Order/OrderDetails/CreatePlacementBtn';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';

const useStyles = makeStyles()(theme => ({
  title: {
    color: '#52429A',
    fontSize: 20,
    fontWeight: 600,
  },
  divider: {
    margin: '0 0 12px 0',
    height: 2,
  },
  textContent: {
    textAlign: 'start',
    alignSelf: 'column',
    color: '#003e68',
    fontSize: 12,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
  },

  avatarProfile: {
    height: 75,
    width: 75,
    marginRight: 10,
    fontSize: 30,
    backgroundColor: '#a3a1fb',
    fontWeight: 500,
    textTransform: 'uppercase',
    position: 'inherit',
  },

  tabContainer: {
    borderBottom: 'none !important',
    padding: '0px !important',
    width: 'auto',
    maxWidth: '95%',
    borderRadius: `${container.borderRadius} ${container.borderRadius} 0px 0px !important`,
  },
  drawerRoot: {
    flexGrow: 1,
    width: 'inherit',
  },
  profileSegment: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '20px 20px',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: '-16%',
  },
  profileInfo: {
    padding: '0',
  },
  details: {
    wordBreak: 'keep-all',
    fontSize: '14px',

    padding: '4px 0px 4px 0px',

    display: 'flex',
  },
  detailsText: {
    paddingLeft: '6px',
    alignSelf: 'center',
    color: '#006fb9',
    fontWeight: 600,
    width: '68%',
    wordBreak: 'break-all',
  },

  detailsTextAction: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  tabsSelection: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
  },
  closeIconPad: {
    // '&:hover': {
    //   backgroundColor: 'white',
    // },
    fontSize: '1.5rem',
    color: '#888888',
  },
  closeIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  profileInfoName: {
    fontSize: '18px',
    color: '#333333',
    fontWeight: 600,
  },
  profileInfoID: {
    fontSize: '14px',
    color: '#333333',
    fontWeight: 600,
  },
  colorOveride: {
    color: '#555555 !important',
  },
  header: {
    width: 'inherit',
    backgroundColor: '#fff',
    position: 'fixed',
    zIndex: 2000,
  },
  bodyCandidateProfileDrawer: {
    paddingTop: '4rem',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '100vh',
  },
  profileTab: {
    position: 'relative',
    marginTop: '-2%',
    '& .MuiTabScrollButton-root.Mui-disabled': {
      opacity: '0.6',
    },
  },
  profileTabMaster: {
    marginTop: '0.5rem',
  },
  tabRoot: {
    [theme.breakpoints.up(380)]: {
      minWidth: '125px !important',
    },
  },
  profileDisplay: {
    display: 'flex',
  },
  orderBackground: {
    backgroundColor: '#fff !important',
  },
  avatarOrderProfile: {
    fontSize: '14px !important',
    textAlign: 'center',
    position: 'relative',
    'margin-top': '5px',
  },
  avatarOrderProfileIcon: {
    'font-size': '3rem',
  },
  link: {
    color: colors.amnBlue,
    textDecoration: 'underline',
    cursor: 'default',
    fontWeight: 450,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  headerLinkContent: {
    fontSize: 16,
    fontWeight: 450,
    margin: '0 5%',
    color: '#888888',
  },
  itemList: {
    opacity: 1,
    paddingBottom: '2%',
  },
  primaryBtn: {
    color: '#FFFFFF',
    backgroundColor: '#006FB9',
    'font-size': '0.7rem',
    padding: '0.3rem 1rem',
    '&:hover': {
      backgroundColor: '#006FB9',
    },
  },
  verifyButton: {
    fontSize: 12,
    marginLeft: '10px',
  },
  drawerOrderIdItem: {
    'font-size': '0.9rem',
  },
  drawerLeftArraow: {
    position: 'relative',
    padding: '0',
    margin: '0',
    'margin-right': '0',
    'margin-left': '0.2rem',
    'font-size': '1.2rem',
    top: '-1px',
    cursor: 'pointer',
  },
  drawerRightArraow: {
    position: 'relative',
    padding: '0',
    margin: '0',
    'font-size': '1.2rem',
    'margin-left': '0.3rem',
    'margin-right': '1rem',
    top: '-1px',
    cursor: 'pointer',
  },
  divisionBusinessRelationshipLabel: {
    'font-weight': '500',
    color: '#1e1e1e',
    position: 'relative',
    top: '2px',
  },
  drawerFacilityContactNameSection: {
    color: '#8e8e8e',
  },
  drawerFacilityContactNameSectionicon: {
    'font-size': '21px',
    'padding-left': '2px',
  },
  drawerFacilityContactNameSectionUserName: {
    color: '#1e1e1e',
    'font-weight': '500',
    position: 'relative',
    top: '1px',
  },
  drawerFacilityAddressSectionContainer: {
    padding: '0',
  },
  drawerFacilityAddressSection: {
    'word-break': 'break-all',
    color: '#858585',
    'font-weight': '200',
    'padding-left': '0',
    'font-size': '13px',
  },
  boxWidget: {
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'center',
    'flex-direction': 'column ',
    padding: '0.5rem 0.2rem',
    margin: '0.5rem',
    'background-color': '#e8e8ea',
    'border-radius': '5px',
    width: '100%',
  },
  boxWidgetTopContent: {
    color: '#333333',
    'font-size': '17px',
    'font-weight': '600',
  },
  boxWidgetBottomContent: {
    'font-size': '10px',
    color: '#858585',
    'font-weight': '500',
  },
  orderDetailsContainer: {
    padding: '0 1rem',
  },
  unitSkillListContainer: {
    padding: '0rem 0.5rem',
  },
  unitSkillListContainerValue: {
    'font-weight': '600',
  },
  unitSkillListContainerDivider: {
    width: '100%',
    margin: '1rem 0',
  },
  orderSubsectionDetailsTitle: {
    'font-size': '14px',
    color: '#333333',
    'font-weight': '600',
    'text-align': 'start',
  },
  orderSubsectionDetailsContent: {
    'font-size': '14px',
    color: '#333333',
    'text-align': 'start',
    'white-space': 'pre-wrap',
  },
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50vh',
  },
  tooltip: {
    display: 'grid',
  },
  tooltipArrow: {
    color: '#fff',
    '&::before': {
      border: '1px solid #555555',
    },
  },
  tooltipBackground: {
    backgroundColor: '#fff',
    color: '#555555',
    border: '1px solid #555555',
    fontSize: '12px',
  },
  viewOrderLink: {
    'font-size': '14px',
  },
  commingSoonContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 0 10px 20px',
  },
  commingSoonText: {
    color: '#555555',
    'align-self': 'center',
    'font-weight': '500',
    'padding-top': '65px',
  },
  dialog: {
    maxWidth: '700px',
    overflowY: 'hidden',
  },
  customUILink: {
    'font-weight': '450',
    color: '#006FB9',
    cursor: 'pointer',
    'text-decoration': 'underline',
  },
}));

export const OrderPreviewDrawerViewer = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = useSelector(selectUser);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const history = useHistory();
  const [tabSelectedValue, setTabSelectedValue] = useState<number>(0);
  const [lastVerifiedUpdateStatus, setLastVerifiedUpdateStatus] = useState<boolean>(false);
  const [orderDetails, setOrderDetails] = useState<IOrderDetails>(getEmptyOrderDetails);
  const { items, drawerData: orderDrawerData } = useSelector(selectSearchResults);
  const jobInterestflattenedData = useSelector(selectJobInterestsGridData);
  const jobMatchesflattenedData = useSelector(selectJobMatchesGridData);
  const [lastVerifiedDate, setLastVerifiedDate] = useState<string>('');
  const [lastVerfiredBy, setLastVerfiredBy] = useState<string>('');
  const userInfo = {
    userId: userData.userInfo?.employeeId,
    userName: userData.userInfo?.firstName + ' ' + userData.userInfo?.lastName,
  };
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  let value: any;

  if (props.type === 1) {
    value = jobInterestflattenedData;
  } else if (props.type === 2) {
    value = jobMatchesflattenedData;
  } else {
    value = items;
  }
  const [orderSearchResults, setOrderSearchResults] = useState<any>(value);
  const [openCreatePlacementForm, setOpenCreatePlacementForm] = useState<boolean>(false);
  const jobInterestDrawerData = useSelector(selectInterestDrawerData);
  const jobMatchDrawerData = useSelector(selectMatchDrawerData);
  const candidateDetails = useSelector(candidateDetailsSelection);

  const getDrawerData = customSwitch({
    1: jobInterestDrawerData,
    2: jobMatchDrawerData,
  })(orderDrawerData);

  const drawerData = getDrawerData(props.type);

  const details = useSelector(selectOrderDetails);

  useEffect(() => {
    setOrderSearchResults(value);
  }, [value]);

  const highlightRow = rowId => {
    dispatch(gridStateActions.setHighlightedRow(rowId));
  };

  const fetchOrderDetails = (oid: string) => {
    getOrderDetailsById(oid)
      .then(response => {
        dispatch(
          orderDataActions.setDetails({
            orderId: response.orderId,
            facility: response.facility,
            location: response.location,
            groupedSkillsets: response.groupedSkillsets,
            shifts: response.shifts,
            startDate: response.startDate,
            endDate: response.endDate,
            placementLength: response.numOfWeeks,
          }),
        );
        setOrderDetails(response);
        setIsLoad(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (props?.orderId) {
      setIsLoad(true);
      fetchOrderDetails(props?.orderId);
    }

    if (drawerData?.data?.displayValue) {
      setIsLoad(true);
      fetchOrderDetails(drawerData?.data?.displayValue);
      const selIndex = orderSearchResults
        .map(function (e) {
          return props.type === 1 || props.type === 2 ? e.orderId : drawerData?.data?.displayValue;
        })
        .indexOf(drawerData?.data?.displayValue);

      setSelectedIndex(selIndex);

      if (drawerData?.data?.selectedorder?.row?.previewDrawerViewerId) {
        highlightRow(
          `${drawerData?.data?.selectedorder?.row?.previewDrawerViewerId}-${drawerData?.data?.selectedorder?.row?.indexCounter}`,
        );
        setSelectedIndex(drawerData?.data?.selectedorder?.row?.indexCounter);
      }

      dispatch(
        globalSearchActions.setDrawerData({ ...drawerData, data: { ...drawerData.data, selectedIndex: selIndex } }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerData?.data?.displayValue, props?.orderId]);

  const NavigateFacilityItem = async direction => {
    if (isLoad || props?.orderId) {
      return;
    }

    let index = selectedIndex;
    if (direction) {
      if (index <= orderSearchResults.length - 2) {
        index = index + 1;
      } else {
        index = 0;
      }
    } else {
      if (index > 0) {
        index = index - 1;
      } else {
        index = orderSearchResults.length - 1;
      }
    }

    setSelectedIndex(index);
    dispatch(globalSearchActions.setDrawerData({ ...drawerData, data: { ...drawerData.data, selectedIndex: index } }));
    const selIndex = index;

    /*const selIndexIn = orderSearchResults
      .map(function (e) {
        return props.type === 1 || props.type === 2 ? e.orderId : drawerData?.data?.displayValue;
      })
      .indexOf(orderDetails.orderId);
    setSelectedIndex(selIndexIn);
    dispatch(
      globalSearchActions.setDrawerData({ ...drawerData, data: { ...drawerData.data, selectedIndex: selIndexIn } }),
    );

    let selIndex = selectedIndex;
    if (selectedIndex > 0) {
      selIndex = selectedIndex - 1;
      setSelectedIndex(selIndex);
      dispatch(
        globalSearchActions.setDrawerData({ ...drawerData, data: { ...drawerData.data, selectedIndex: selIndex } }),
      );
    } else {
      selIndex = orderSearchResults.length - 1;
      setSelectedIndex(selIndex);
      dispatch(
        globalSearchActions.setDrawerData({ ...drawerData, data: { ...drawerData.data, selectedIndex: selIndex } }),
      );
    }*/

    const orderId =
      props.type === 1 || props.type === 2
        ? orderSearchResults[selIndex].orderId
        : orderSearchResults[selIndex].orderId;

    if (drawerData?.data?.selectedorder?.row?.id) {
      highlightRow(`${orderId}-${selIndex}`);
    }

    setIsLoad(true);

    getOrderDetailsById(orderId)
      .then(response => {
        dispatch(
          orderDataActions.setDetails({
            orderId: response.orderId,
            facility: response.facility,
            location: response.location,
            groupedSkillsets: response.groupedSkillsets,
            shifts: response.shifts,
            startDate: response.startDate,
            endDate: response.endDate,
            placementLength: response.numOfWeeks,
          }),
        );
        setOrderDetails(response);
        setIsLoad(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleClose = e => {
    highlightRow('test');
    if (props.type === 1) {
      dispatch(jobInterestActions.setDrawerData({ open: false, data: undefined }));
    } else if (props.type === 2) {
      dispatch(jobMatchActions.setDrawerData({ open: false, data: undefined }));
    } else {
      dispatch(globalSearchActions.setDrawerData({ open: false, data: undefined }));
    }

    if (props?.orderId) {
      dispatch(globalActions.resetOrderDrawerData());
    }
  };

  /* Navigate to Order Page
   * @param OrderId
   */
  const navigate = () => {
    if (props.type === 2 && candidateDetails && candidateDetails?.travelerId && candidateDetails?.brandId) {
      const orderNavData = getNavData('Order Details', candidateDetails?.travelerId, candidateDetails?.brandId);
      dispatch(breadcrumbActions.setNavigationData(orderNavData));
    } else {
      dispatch(breadcrumbActions.setNavigationData({ backButtonURL: null, breadcrumbHierarchy: null }));
    }
    history.push({
      pathname: `/order/${orderDetails.orderId}`,
      state: { navigateBack: true },
    });
    if (!props?.orderId) {
      dispatch(navigationProfilesAction.setNavigationFlag({ navigationFromGrid: true }));
      dispatch(navigationProfilesAction.setCurrentRowId({ rowId: drawerData?.data?.selectedorder?.row?.id }));
    }
    if (props?.orderId) {
      dispatch(globalActions.resetOrderDrawerData());
    }
    dispatch(globalSearchActions.reset());
  };

  const navigateToFacilityDetails = () => {
    const facilityId = orderDetails.facility.facilityId;
    if (props.type === 2 && candidateDetails && candidateDetails?.travelerId && candidateDetails?.brandId) {
      const facilityNavData = getNavData('Facility Details', candidateDetails?.travelerId, candidateDetails?.brandId);
      dispatch(breadcrumbActions.setNavigationData(facilityNavData));
    } else {
      dispatch(breadcrumbActions.setNavigationData({ backButtonURL: null, breadcrumbHierarchy: null }));
    }
    history.push({
      pathname: `/facility/${facilityId}`,
      state: { navigateBack: true },
    });
  };

  const handleProfileDrawerTabChange = (event, newValue) => {
    if (newValue !== tabSelectedValue) {
      setTabSelectedValue(newValue);
    }
  };

  const getGlanceKPIValues = (title: string, values: any) => {
    if (title === 'POSITIONS') {
      return values ? (values > 999 ? '999+' : values) : '0';
    } else if (title === 'FILES OUT') {
      return values ? (values > 999 ? '999+' : values) : '0';
    } else if (title === 'WK/GROSS') {
      return values ? (values > 99999.99 ? `$9999+` : `$${values}`) : 'N/A';
    } else if (title === 'ACTIVE BONUS') {
      return values ? `${t('order.facilityDetails.orderCreationNavConfirmationOption.yes')}` : `${t('order.facilityDetails.orderCreationNavConfirmationOption.no')}`;
    } else if (title === 'PLACEMENTS') {
      return values ? (values > 999 ? '999+' : values) : '0';
    }
  };

  const getdiscribedValuesSec = (title: string) => {
    if (title === 'Assignment') {
      const startDate = GetMomentOfDate(orderDetails.startDate + '');
      const endDate = GetMomentOfDate(orderDetails.endDate + '');
      return `${startDate.isValid() ? startDate.format('MM/DD/YYYY') : '-'} - ${
        endDate.isValid() ? endDate.format('MM/DD/YYYY') : '-'
      }`;
    } else if (title === 'Flexible Dates') {
      return orderDetails.flexibleDates ? 'YES' : 'NO';
    } else if (title === 'ASAP Start Date') {
      return orderDetails.asap ? 'YES' : 'NO';
    } else if (title === 'MFS') {
      return !orderDetails.maxFilesSent ? 'NO' : 'YES';
    } else if (title === 'Order Age') {
      return `${getAge(orderDetails.dateCreated)}`;
    } else if (title === 'Last Verified Date') {
      return lastVerifiedDate;
    } else if (title === 'Last Verified By') {
      return lastVerfiredBy;
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (lastVerifiedUpdateStatus) {
      const verifiedDate = convertToPST(new Date());
      // setInProgress(false);
      setLastVerifiedDate(verifiedDate ? verifiedDate : '');
      setLastVerfiredBy(userInfo.userName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastVerifiedUpdateStatus]);

  useEffect(() => {
    setLastVerifiedDate(convertToPST(orderDetails.dateVerified !== '' ? orderDetails.dateVerified : ''));
    setLastVerfiredBy(orderDetails.contacts.verifiedBy ? getName(orderDetails.contacts.verifiedBy) : 'N/A');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetails]);

  const handleLastVerified = async () => {
    const verifiedDateTime = convertToFormat(new Date(), '');
    const lastVerifiedData: updateLastVerifiedData = {
      orderId: parseInt(orderDetails.orderId),
      DateVerified: verifiedDateTime ? verifiedDateTime : '',
      VerifiedByUserId: userInfo.userId ? userInfo.userId : 0,
    };
    const response = await updateLastVerified(lastVerifiedData);
    if (response) {
      setLastVerifiedUpdateStatus(false);
      dispatch(
        globalActions.setSnackBar({
          message: t(`The order ${orderDetails.orderId} is verified successfully`),
          severity: 'success',
        }),
      );
    } else {
      dispatch(
        globalActions.setSnackBar({
          message: t(`The order ${orderDetails.orderId} is verified failed`),
          severity: 'error',
        }),
      );
    }
    setLastVerifiedUpdateStatus(true);
  };

  const glanceTileArray = [
    {
      title: t('order.orderDetails.kpiPreview.positionsAvailable'),
      status: {
        data: orderDetails.positionsAvailable,
        colorCode: GlanceStatus.neutral,
      },
    },
    props.type === 1 || props.type === 2
      ? {
          title: t('order.orderDetails.kpiPreview.placements'),
          status: {
            data: orderDetails.orderCurrentPlacements.totalPlacements,
            colorCode: GlanceStatus.neutral,
          },
        }
      : {
          title: t('order.orderDetails.kpiPreview.filesOut'),
          status: {
            data: orderSearchResults[selectedIndex]?.filesOut && orderSearchResults[selectedIndex]?.filesOut !== missingField ? orderSearchResults[selectedIndex]?.filesOut : 0,
            colorCode: GlanceStatus.neutral,
          },
        },
    {
      title: t('order.orderDetails.kpiPreview.weeklyGrossPay'),
      status: {
        data: orderDetails.weeklyGrossPay,
        colorCode: GlanceStatus.neutral,
      },
    },
    {
      title: t('order.orderDetails.kpiPreview.activeBonus'),
      status: {
        data: orderDetails.activeBonus,
        colorCode: orderDetails.activeBonus
          ? GlanceStatus.green
          : !orderDetails.activeBonus
          ? GlanceStatus.red
          : GlanceStatus.neutral,
      },
    },
  ];

  const TabPanel = ({ children, value, index, classes, ...other }) => {
    return (
      <div
        className="tab"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box className={`paper ${classes.tabContainer}`}>{children}</Box>}
      </div>
    );
  };

  return (
    <>
      {isLoad || ((!value || value.length <= 0) && !props?.orderId) ? (
        <CircularProgress className={classes.MuiCircularProgressRoot} />
      ) : (
        <div className={classes.drawerRoot}>
          <LayoutGridItem container xs={12} lg={12} sm={12} md={12} direction="row" spacing={2}>
            <LayoutGridItem container xs={10} lg={10} sm={10} md={10}>
              <span className={`${classes.closeIcon} ${classes.headerLinkContent}`}>
                <span className={classes.drawerOrderIdItem}>{`Order Preview - ID ${orderDetails.orderId}`}</span>
                <span
                  className={classes.drawerLeftArraow}
                  onClick={() => {
                    NavigateFacilityItem(false);
                  }}
                  onKeyDown={() => {
                    NavigateFacilityItem(false);
                  }}
                  aria-hidden="true"
                >
                  <KeyboardArrowLeftIcon />
                </span>
                <span
                  className={classes.drawerRightArraow}
                  onClick={() => {
                    NavigateFacilityItem(true);
                  }}
                  onKeyDown={() => {
                    NavigateFacilityItem(true);
                  }}
                  aria-hidden="true"
                >
                  <KeyboardArrowRightIcon />
                </span>
                <Box component="span" className={`${classes.link} ${classes.viewOrderLink}`} onClick={navigate}>
                  {t('search.globalSearch.order.viewOrder')}
                </Box>
              </span>
            </LayoutGridItem>
            <LayoutGridItem container xs={2} lg={2} sm={2} md={2}>
              <IconButton
                color="primary"
                onClick={handleClose}
                className={classes.closeIconPad}
                data-testid="dt-id-close-drawer"
                size="small"
              >
                <img src={CloseIcon} alt="Close" />
              </IconButton>
            </LayoutGridItem>
          </LayoutGridItem>

          <Divider orientation="horizontal" classes={{ root: classes.divider }} />

          <Grid item xs={12} className={classes.bodyCandidateProfileDrawer} id="body-contact">
            <Grid item xs={12} className={classes.profileSegment}>
              <Grid item xs={3}>
                <Avatar className={`${classes.avatarProfile} ${classes.avatarOrderProfile}`}>
                  <img src={HospitalIcon} alt="Hospital" className={classes.avatarOrderProfileIcon} />
                </Avatar>
              </Grid>
              <Grid item xs={9} className={classes.profileDisplay} justifyContent="space-between">
                <Grid item xs={12}>
                  <div className={classes.profileInfo}>
                    <Grid item className={classes.profileInfoName}>
                      <div
                        className={classes.customUILink}
                        role="button"
                        tabIndex={0}
                        onClick={navigateToFacilityDetails}
                        onKeyDown={navigateToFacilityDetails}
                      >
                        {orderDetails.facility.name}
                      </div>
                    </Grid>
                    <Grid item className={classes.details}>
                      <Grid item xs={6} className={classes.profileDisplay} justifyContent="space-between" container>
                        <Tooltip
                          title={
                            <span className={classes.tooltip}>
                              <span>{`${orderDetails.division}, ${orderDetails.facility.businessRelationship}`}</span>
                            </span>
                          }
                          disableHoverListener={
                            `${orderDetails.division}, ${orderDetails.facility.businessRelationship}`.length <= 20
                          }
                          arrow
                          placement="bottom-end"
                          classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
                        >
                          <span className={classes.divisionBusinessRelationshipLabel}>{`${toolTipCheck(
                            `${orderDetails.division}, ${orderDetails.facility.businessRelationship}` || '',
                            20,
                          )}`}</span>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={6} className={classes.profileDisplay} justifyContent="space-between">
                        <span className={classes.drawerFacilityContactNameSection}>
                          <PersonIcon className={classes.drawerFacilityContactNameSectionicon} />
                          <Tooltip
                            title={
                              <span className={classes.tooltip}>
                                <span>{`${orderDetails.contacts.accountManager.firstName} ${orderDetails.contacts.accountManager.lastName}`}</span>
                              </span>
                            }
                            disableHoverListener={
                              `${orderDetails.contacts.accountManager.firstName} ${orderDetails.contacts.accountManager.lastName}`
                                .length <= 12
                            }
                            arrow
                            placement="bottom-end"
                            classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
                          >
                            <span className={classes.drawerFacilityContactNameSectionUserName}>{`${toolTipCheck(
                              `${orderDetails.contacts.accountManager.firstName} ${orderDetails.contacts.accountManager.lastName}` ||
                                '',
                              12,
                            )}`}</span>
                          </Tooltip>
                        </span>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={`${classes.details} ${classes.drawerFacilityAddressSectionContainer}`}
                    >
                      <span className={`${classes.drawerFacilityAddressSection}`}>
                        {' '}
                        {Concatenate(
                          [
                            orderDetails.location.city || '',
                            orderDetails.location.state || '',
                            orderDetails.location.zipCode || '',
                          ],
                          ', ',
                        ) || 'N/A'}
                      </span>
                    </Grid>
                    <Grid item xs={12} className={`${classes.details}`}>
                      <CreatePlacementBtn
                        division={orderDetails.division}
                        orderType={orderDetails.orderType}
                        maxFilesSent={orderDetails.maxFilesSent}
                        positionsAvailable={orderDetails.positionsAvailable}
                        onClick={() => setOpenCreatePlacementForm(true)}
                      />
                      <CustomTooltip
                        disabled={Authorized(
                          [
                            userRoles.accountManagement,
                            userRoles.accountManagement_Leadership,
                            userRoles.accountManagement_TeamMember,
                            userRoles.supportAdmin,
                          ],
                          userData.userInfo,
                        )}
                        standardMargin
                        isStandardContent
                        tooltipContent={t('global.accessDisabled')}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="medium"
                          classes={{ containedPrimary: `${classes.verifyButton}` }}
                          onClick={handleLastVerified}
                          children={t('search.globalSearch.order.verifyOrder')}
                          disabled={
                            !Authorized(
                              [
                                userRoles.accountManagement,
                                userRoles.accountManagement_Leadership,
                                userRoles.accountManagement_TeamMember,
                                userRoles.supportAdmin,
                              ],
                              userData.userInfo,
                            )
                          }
                        />
                      </CustomTooltip>
                    </Grid>
                  </div>
                </Grid>
                <Grid item style={{ color: '#888888' }}>
                  <MoreVertIcon />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <div className={`${classes.profileTab} ${classes.profileTabMaster}`}>
                <Paper elevation={0} className={`paper ${classes.tabContainer} `}>
                  <Tabs
                    value={tabSelectedValue}
                    classes={{ indicator: 'tab-indicator profileTab' }}
                    onChange={handleProfileDrawerTabChange}
                    variant="scrollable"
                    scrollButtons
                    aria-label="full width tabs"
                    allowScrollButtonsMobile
                  >
                    <Tab
                      className={tabSelectedValue === 0 ? 'active-tab-profile' : 'default-tab profileTab'}
                      label={'Summary'}
                      classes={{ root: classes.tabRoot }}
                    />
                    <Tab
                      className={tabSelectedValue === 1 ? 'active-tab-profile' : 'default-tab profileTab'}
                      label={'Contacts'}
                      classes={{ root: classes.tabRoot }}
                    />
                    <Tab
                      className={tabSelectedValue === 2 ? 'active-tab-profile' : 'default-tab profileTab'}
                      label={'Placements'}
                      classes={{ root: classes.tabRoot }}
                    />
                    <Tab
                      className={tabSelectedValue === 3 ? 'active-tab-profile' : 'default-tab profileTab'}
                      label={'Rates'}
                      classes={{ root: classes.tabRoot }}
                    />
                  </Tabs>
                </Paper>
                <TabPanel componet={'div'} value={tabSelectedValue} index={0} classes={classes}>
                  <Grid item xs={12} className={`${classes.details}`}>
                    <Grid item xs={12} container direction="row" className={`${classes.details}`}>
                      {glanceTileArray.map(tile => (
                        <Grid item xs={3} className={`${classes.details}`}>
                          <div className={classes.boxWidget}>
                            <span className={classes.boxWidgetTopContent}>
                              {getGlanceKPIValues(tile.title, tile.status.data)}
                            </span>
                            <span className={classes.boxWidgetBottomContent}>{tile.title}</span>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    className={`${classes.details} ${classes.orderDetailsContainer}`}
                  >
                    <Grid item xs={12} className={`${classes.details} ${classes.unitSkillListContainer}`}>
                      <Grid item xs={5} className={`${classes.details}`}>
                        {`Unit:`}
                      </Grid>
                      <Grid item xs={7} className={`${classes.details} ${classes.unitSkillListContainerValue}`}>
                        {orderDetails.unit?.name}
                      </Grid>
                    </Grid>

                    <Grid item xs={12} className={`${classes.details} ${classes.unitSkillListContainer}`}>
                      <Grid item xs={5} className={`${classes.details}`}>
                        {`Skillsets:`}
                      </Grid>
                      <Grid item xs={7} className={`${classes.details} ${classes.unitSkillListContainerValue}`}>
                        {orderDetails?.skillsets !== null
                          ? orderDetails?.skillsets
                              ?.map(item => {
                                const subSpec = item.subSpecialty ? ' - ' + item.subSpecialty : '';
                                return item.disciplineAbbr + ' - ' + item.specialtyAbbr + subSpec;
                              })
                              .join(', ')
                          : '-'}
                      </Grid>
                    </Grid>

                    <Grid item xs={12} className={`${classes.details} ${classes.unitSkillListContainer}`}>
                      <Grid item xs={5} className={`${classes.details}`}>
                        {`Shifts:`}
                      </Grid>
                      <Grid item xs={7} className={`${classes.details} ${classes.unitSkillListContainerValue}`}>
                        {orderDetails?.shifts
                          ?.map(item => {
                            return item.description;
                          })
                          .join(', ')}
                      </Grid>
                    </Grid>

                    <Grid item xs={12} className={`${classes.details} ${classes.unitSkillListContainer}`}>
                      <Divider
                        orientation="horizontal"
                        className={`${classes.divider} ${classes.unitSkillListContainerDivider} `}
                      />
                    </Grid>

                    {atAGlanceSectionPreview1.map((glanceValues, key) => (
                      <Grid item xs={12} className={`${classes.details} ${classes.unitSkillListContainer}`}>
                        <Grid item xs={5} className={`${classes.details}`}>
                          {`${glanceValues.title}:`}
                        </Grid>
                        <Grid item xs={7} className={`${classes.details} ${classes.unitSkillListContainerValue}`}>
                          {getdiscribedValuesSec(glanceValues.title)}
                        </Grid>
                      </Grid>
                    ))}
                    <Grid item xs={12} className={`${classes.details} ${classes.unitSkillListContainer}`}>
                      <Divider
                        orientation="horizontal"
                        className={`${classes.divider} ${classes.unitSkillListContainerDivider} `}
                      />
                    </Grid>
                    <Grid item xs={12} className={`${classes.details} ${classes.unitSkillListContainer}`}>
                      <div
                        className={`${classes.details} ${classes.orderSubsectionDetailsTitle}`}
                      >{`Internal Notes`}</div>
                    </Grid>

                    <Grid item xs={12} className={`${classes.details} ${classes.unitSkillListContainer}`}>
                      <div className={`${classes.details} ${classes.orderSubsectionDetailsContent}`}>
                        {orderDetails.internalNotes}
                      </div>
                    </Grid>
                    <Grid item xs={12} className={`${classes.details} ${classes.unitSkillListContainer}`}>
                      <Divider
                        orientation="horizontal"
                        className={`${classes.divider} ${classes.unitSkillListContainerDivider} `}
                      />
                    </Grid>
                    <Grid item xs={12} className={`${classes.details} ${classes.unitSkillListContainer}`}>
                      <div
                        className={`${classes.details} ${classes.orderSubsectionDetailsTitle}`}
                      >{`Required Qualifications`}</div>
                    </Grid>

                    <Grid item xs={12} className={`${classes.details} ${classes.unitSkillListContainer}`}>
                      <div className={`${classes.details} ${classes.orderSubsectionDetailsContent}`}>
                        {orderDetails.submissionRequirements}
                      </div>
                    </Grid>

                    <Grid item xs={12} className={`${classes.details} ${classes.unitSkillListContainer}`}>
                      <div
                        className={`${classes.details} ${classes.orderSubsectionDetailsTitle}`}
                      >{`Preferred Qualifications`}</div>
                    </Grid>

                    <Grid item xs={12} className={`${classes.details} ${classes.unitSkillListContainer}`}>
                      <div className={`${classes.details} ${classes.orderSubsectionDetailsContent}`}>
                        {orderDetails.preferredQualifications}
                      </div>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={tabSelectedValue} index={1} classes={classes}>
                  <div className={classes.commingSoonContainer}>
                    <div className={classes.commingSoonText}>
                      {<AcUnitOutlinedIcon />}
                      {` Coming Soon`}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={tabSelectedValue} index={2} classes={classes}>
                  <div className={classes.commingSoonContainer}>
                    <div className={classes.SoonText}>
                      {<AcUnitOutlinedIcon />}
                      {` Coming Soon`}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={tabSelectedValue} index={3} classes={classes}>
                  <div className={classes.commingSoonContainer}>
                    <div className={classes.commingSoonText}>
                      {<AcUnitOutlinedIcon />}
                      {` Coming Soon`}
                    </div>
                  </div>
                </TabPanel>
              </div>
            </Grid>
          </Grid>
          {openCreatePlacementForm && (
            <CreatePlacementWrapper
              isDefaultOrder
              open
              handleClose={() => setOpenCreatePlacementForm(false)}
              initialArgs={{ orderId: details.orderId }}
            />
          )}
        </div>
      )}
    </>
  );
};
