import { Badge, Typography } from 'amn-ui-core';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { globalSearchActions } from 'store/redux-store/global-search/slice';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { selectUser } from 'oidc/user.selectors';
import { useTranslation } from 'react-i18next';
import { selectUnreadOrderIds } from '../AlertStore/OrderMatchAlert.selector';
import { candidateDetailsSelection } from '../../../Profile/CandidateDetails.selector';
import { getOrderMatchAlertActions } from '../AlertStore/OrderMatchAlert.redux';
import { updateOrderMatchReadStatus } from 'app/services/CandidateServices/CandidateServices';
import { selectCandidateOrderMatchId } from '../store/candidateOrderMatch.selector';
import { selectPreferenceId } from '../../JobPreferencesTab/store/JobPreferences.selectors';
import { jobPreferncesActions } from '../../JobPreferencesTab/store/JobPreferences.redux';
import { getEmployeeId } from '@AMIEWEB/Notification/Tasks/Common_v2/utils';

export const OrderDispatchComponent = ({ displayValue, selectedorder, ...props }) => {
  const dispatch = useDispatch();
  let candidateDetails = useSelector(candidateDetailsSelection);
  const { userInfo } = useSelector(selectUser);
  const newUnradOrders = useSelector(selectUnreadOrderIds);
  const { t } = useTranslation();
  const assignedRecruiter = candidateDetails.recruiter.recruiterId;
  const checkAssignedRecruiter = parseInt(getEmployeeId(userInfo)) == assignedRecruiter;
  const prefId = useSelector(selectCandidateOrderMatchId);
  const orderPreferenceId = useSelector(selectPreferenceId);
  let orderPrefId = prefId ?? orderPreferenceId;

  const updateReadStatus = () => {
    const orderId = selectedorder.row.orderId;

    const readStatusPayload = {
      jobPreferenceId: !!prefId ? `${prefId}` : orderPrefId,
      orderIds: [orderId],
    };

    updateOrderMatchReadStatus(readStatusPayload)
      .then(response => {
        dispatch(
          jobPreferncesActions.getJobPreferencesAction({
            travelerId: candidateDetails.travelerId,
            brandId: candidateDetails.brandId,
          }),
        );
      })
      .catch(err =>
        dispatch(
          globalActions.setSnackBar({
            message: t('Failed to update order read status'),
            severity: 'error',
          }),
        ),
      );
  };
  const stopImmediatePropagation = event => {
    if (checkAssignedRecruiter) {
      const orderIds = newUnradOrders.filter(ele => ele !== selectedorder.row.orderId);
      dispatch(getOrderMatchAlertActions.setUnreadOrderIds(orderIds));
      updateReadStatus();
    }
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <div>
      {newUnradOrders.includes(selectedorder.row.orderId) && checkAssignedRecruiter ? (
        <Badge color="error" variant="dot" style={{ margin: '6px 6px 0px 0px ' }}>
          <Typography
            onClick={e => {
              stopImmediatePropagation(e);
              dispatch(globalActions.resetAllRecentHistoryDrawers());
              dispatch(globalSearchActions.setDrawerData({ open: true, data: { displayValue, ...props } }));
            }}
            style={{ fontSize: 'inherit', padding: '0px 5px' }}
          >
            {displayValue}
          </Typography>
        </Badge>
      ) : (
        <Typography
          onClick={e => {
            stopImmediatePropagation(e);
            dispatch(globalActions.resetAllRecentHistoryDrawers());
            dispatch(globalSearchActions.setDrawerData({ open: true, data: { displayValue, ...props } }));
          }}
          style={{ fontSize: 'inherit', padding: '0px 5px' }}
        >
          {displayValue}
        </Typography>
      )}
    </div>
  );
};

export function processJobPreferencesData(jobPreferencesData, prefId) {
  const data = jobPreferencesData?.filter(item => item?.id === prefId)?.[0];

  const cityOptns: any[] = [];
  const uniqueCities = new Set();

  (data?.locations || []).forEach(location => {
    if (location.city !== null && location.city !== undefined) {
      const cityName = location.city;
      if (!uniqueCities.has(cityName)) {
        cityOptns.push({
          id: cityName,
          name: cityName,
          value: cityName,
        });
        uniqueCities.add(cityName);
      }
    }
  });

  const stateOptns = (data?.locations || [])
    .filter(location => location.state !== null)
    .reduce((stateOptions, location) => {
      const value = location.state;
      if (value && !stateOptions.some(opt => opt.value === value)) {
        stateOptions.push({ value });
      }
      return stateOptions;
    }, []);

  const locations = data?.locations;
  let zipOptn: any = null;

  if (locations && locations.length > 0) {
    for (const location of locations) {
      if (location.zip && location.radius && isValidZip(location.zip)) {
        zipOptn = {
          id: location.zip,
          name: location.zip,
          value: location.zip,
        };
        break;
      } else if (location.zip && isValidZip(location.zip)) {
        zipOptn = {
          id: location.zip,
          name: location.zip,
          value: location.zip,
        };
      }
    }
  }

  function isValidZip(zip) {
    return zip !== null && zip !== undefined && zip !== '' && zip !== '0';
  }

  const locationWithZipAndRadius = data?.locations.find(location => location.zip && location.radius);
  let validRadius = locationWithZipAndRadius?.radius || 0;
  if (validRadius === 0) {
    validRadius = data?.locations.find(location => location.radius > 0)?.radius || null;
  }
  const radiusValue = validRadius ? validRadius.toString() : null;

  const educationOptions: any[] = data?.education
    ? data.education
        .map(eduItem => ({
          labelPrefix: 'Education',
          id: eduItem.id,
          name: eduItem.name,
          value: eduItem.value,
        }))
        .filter(option => option.id !== null && option.name !== null && option.value !== null)
    : [];

  const result = {
    newDisciplineSkill: [],
    newSpecialtySkill: [],
    city: cityOptns,
    state: stateOptns,
    radius: radiusValue,
    zip: zipOptn,
    orderStartDateRange: data?.orderStartDateRange,
    billRate: data?.billRate,
    education: educationOptions,
    shiftOpts: data?.shiftIds,
  };

  for (let i = 0; i < data?.skillSets.length; i++) {
    const skillSet = data?.skillSets[i];
    const skillSetArray = data?.skillSetsArray[i];

    const newDisciplineSkill = {
      id: Number(skillSet?.disciplineId),
      name: skillSetArray?.discipline,
      value: skillSet?.disciplineId,
    };

    const isDisciplineDuplicate = result.newDisciplineSkill.some(skill => skill.id === newDisciplineSkill.id);

    if (!isDisciplineDuplicate) {
      result.newDisciplineSkill.push(newDisciplineSkill);
    }

    const newSpecialtySkill = {
      id: Number(skillSet?.specialtyId),
      name: skillSetArray?.speciality,
      value: skillSet?.specialtyId,
    };

    if (newSpecialtySkill.id && newSpecialtySkill.id !== 0) {
      const isSpecialtyDuplicate = result.newSpecialtySkill.some(skill => skill.id === newSpecialtySkill.id);

      if (!isSpecialtyDuplicate) {
        result.newSpecialtySkill.push(newSpecialtySkill);
      }
    }
  }

  return result;
}

export function getMatchingShiftOptions(shiftOpts: any[], shiftOptsLookups: any[]): any[] {
  if (!shiftOpts) return [];

  const matchingShiftOpts: any[] = [];

  shiftOpts?.forEach(shiftOptValue => {
    const matchingShiftOpt = shiftOptsLookups?.find(shiftOpt => shiftOpt?.value.toString() === shiftOptValue);

    if (matchingShiftOpt) {
      if (!matchingShiftOpts?.some(opt => opt.value === matchingShiftOpt.value)) {
        matchingShiftOpts.push(matchingShiftOpt);
      }
    }
  });

  return matchingShiftOpts;
}

export function getMatchingStateOptions(stateOpts: any[], stateOptsLookups: any[]): any[] {
  if (!stateOpts) return [];

  const matchingStateOpts: any[] = [];

  stateOpts?.forEach(stateOpt => {
    const value = stateOpt?.value;
    if (value) {
      const matchingStateOpt = stateOptsLookups?.find(stateOptLookup => {
        if (Array.isArray(stateOptLookup.value)) {
          return stateOptLookup.value.some(lookupOpt => lookupOpt.value === value);
        }
        return stateOptLookup.value === value;
      });

      if (matchingStateOpt) {
        if (!matchingStateOpts.some(opt => opt.value === matchingStateOpt.value)) {
          matchingStateOpts.push(matchingStateOpt);
        }
      }
    }
  });

  return matchingStateOpts;
}
