import { FormControlLabel, Switch, Tooltip } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectEditStatus, selectPageAttributes } from '../store/placementPageAttributes.selector';
import { pageAttributesActions } from '../store/placementPageAttributes.redux';
import { editableFields } from '../../editUtils';
import { selectEditableData } from '../../../../../../store/redux-store/placement-details/selectors';
import { ActionAccessorHook } from './ActionAccessorHook';

const useStyles = makeStyles<{ travelsWithPartner: boolean }>()((theme, props) => ({
  label: {
    margin: 0,
  },
  customTooltip: {
    backgroundColor: '#ffffff',
    color: '#0D2A3B',
    opacity: 1,
    textAlign: 'center',
    fontFamily: 'IBM Plex Sans, sans-serif',
    font: 'normal normal normal 12px/20px IBM Plex Sans',
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #8C8C8C',
    marginTop: 5,
  },
  customArrow: {
    color: '#ffffff',
    opacity: 1,
    marginRight: 15,
    '&::before': {
      boxShadow: '0px 3px 6px #00000029',
      border: '1px solid #8C8C8C',
    },
  },
  disabled: {
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  switchBase: {
    '&:focus-within .MuiSwitch-thumb': {
      boxShadow: !props.travelsWithPartner
        ? '0px 0px 1px 4px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
        : undefined,
    },
  },
}));

export const TravelsWithPartnerStatus = ({ id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { control, watch } = useFormContext();
  const travelsWithPartner = watch('travelsWithPartner');
  const { classes } = useStyles({ travelsWithPartner });
  const { travelsWithPartner: status } = useSelector(selectEditStatus);
  const { editOrderStack } = useSelector(selectPageAttributes);
  const { travelsWithPartner: defaultValue } = useSelector(selectEditableData);

  const undoChecker = () => !editOrderStack.some(key => key === 'travelsWithPartner');

  return (
    <Controller
      control={control}
      name="travelsWithPartner"
      render={({ ref, onChange, ...rest }) => (
        <>
          {/** Component is used to access inaccessible methods from react-hook-form Controller
           * to invoke actions - say undo action */}
          <ActionAccessorHook
            actionChecker={undoChecker}
            accessAction={() => onChange(defaultValue)}
            hookDependancies={[editOrderStack]}
          />
          <FormControlLabel
            control={
              status.disabled ? (
                <Tooltip
                  //@ts-ignore
                  title={t('placement.profile.edit.disableText')}
                  arrow
                  classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }}
                  placement={'bottom'}
                >
                  <div>
                    <Switch id={id} color="primary" size="small" checked={travelsWithPartner} {...rest} disabled />
                  </div>
                </Tooltip>
              ) : (
                <Switch
                  id={id}
                  color="primary"
                  size="small"
                  checked={travelsWithPartner}
                  // onKeyDown is written to invoke change on enter or click on Space bar
                  onKeyDown={event => {
                    if (event.keyCode === 13 || event.keyCode === 32) {
                      //@ts-ignore
                      onChange(!event.target.checked);
                      dispatch(pageAttributesActions.pushToEditOrderStack(editableFields.travelsWithPartner));
                    }
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  onChange={(e, checked) => {
                    onChange(checked);
                    dispatch(pageAttributesActions.pushToEditOrderStack(editableFields.travelsWithPartner));
                  }}
                  onClick={() => dispatch(pageAttributesActions.setCurrentFocus(id))}
                  classes={{ switchBase: classes.switchBase }}
                  {...rest}
                />
              )
            }
            disabled={status.disabled}
            label={travelsWithPartner ? t('global.Yes') : t('global.No')}
            classes={{ root: classes.label, disabled: classes.disabled }}
          />
        </>
      )}
    />
  );
};
