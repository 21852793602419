import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { missingField } from 'app/constants';
import { ExceptionType } from 'app/enums/Common';
import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { requirementType } from 'app/models/Placement/PlacementSummary';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { put, select, getContext, fork, call } from 'redux-saga/effects';
import { SetPlacementFailRiskRequirementMessage } from 'store/redux-store/new-placement/saga';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import { placementStatusAction } from 'store/redux-store/placement-status/slice';
import { resetFailureStatus } from '../saga';
import { IPlacementSummaryRequest, placementSummaryActions } from '../slice';
import { trackPromise } from 'react-promise-tracker';
import InitPlacementSummary, { IConsolidatedRequirementResponse } from '../init.types';
import { selectPlacementConsolidatedDetails } from '../consolidatedStoreSelectors';

const TrackCovidOrderReqWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-covid-order-requirement');

export function* requestCovidOrderRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('covidOrderRequirement'));
    const placementOrderType = yield select(selectPlacementOrderType);
    const placementService: PlacementService = yield getContext('placementService');

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if(action.payload.isInitLoad && consolidatedDetails){
      response = {
        status : consolidatedDetails.summary?.submission?.orderSubmissionBusinessRequrement?.covid?.status,
        data : consolidatedDetails.summary?.submission?.orderSubmissionBusinessRequrement?.covid?.data
      }
    }

    if(!response?.status || !response?.data){
      response = yield call(
        TrackCovidOrderReqWrapper,
        placementService.getPlacementSummaryCovid_OrderReq,
        action.payload.placementId,
        placementOrderType,
      );
    }

    if (httpSuccess(response?.status)) {
      const { data } = response;

      yield put(placementSummaryActions.setSubmissionInfoByKey({ key: 'covid', isOrderReq: true, ...data }));

      if (data.orderRequirement.type === requirementType.risk) {
        yield put(
          placementStatusAction.setWarningRequirement({
            requirement: {
              id: IPlacementRequirementKey.orderCovid,
              message: `COVID Status: ${data?.candidateRequirement?.data?.vaccinationStatus ?? missingField}`,
              tooltip: {
                message: `Candidate's current COVID Status does not match the Order's Requirements.`,
              },
            },
          }),
        );
      }

      yield fork(SetPlacementFailRiskRequirementMessage, {
        payload: {
          messageId: data?.orderRequirement?.messageId,
          version: data?.orderRequirement?.messageVersion,
          passFailType: data.orderRequirement.type,
          data: data?.candidateRequirement?.data?.vaccinationStatus,
          messageTypeId: IPlacementRequirementKey.orderCovid,
          tooltipTypeParam: undefined,
          nextStatusRequirementParam: data.nextStatusRequirement,
        },
        type: placementSummaryActions.setFailedRequirement.type,
      });
    } else
      yield put(placementSummaryActions.setServiceFailureByKey({ key: 'covidOrderRequirement', failureStatus: true }));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestCovidOrderRequirement',
        area: 'src/store/redux-store/placement-summary/common-saga/covidOrderRequirement.ts',
      },
    });
  }
}

export function* processCovidOrderRequirement(
  response: IConsolidatedRequirementResponse<any>,
  context: InitPlacementSummary,
) {
  try {
    if (!httpSuccess(response?.status)) {
      context.setServiceFailureByKey({ key: 'covidOrderRequirement', failureStatus: true });
      return;
    }

    context.setSubmissionInfoByKey({ key: 'covid', isOrderReq: true, ...response.data });
    yield fork(common, response.data);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'processCovidOrderRequirement',
        area: 'src/store/redux-store/placement-summary/common-saga/covidOrderRequirement.ts',
      },
    });
  }
}

function* common(data) {
  if (data.orderRequirement.type === requirementType.risk) {
    yield put(
      placementStatusAction.setWarningRequirement({
        requirement: {
          id: IPlacementRequirementKey.orderCovid,
          message: `COVID Status: ${data?.candidateRequirement?.data?.vaccinationStatus ?? missingField}`,
          tooltip: {
            message: `Candidate's current COVID Status does not match the Order's Requirements.`,
          },
        },
      }),
    );
  }

  yield fork(SetPlacementFailRiskRequirementMessage, {
    payload: {
      messageId: data?.orderRequirement?.messageId,
      version: data?.orderRequirement?.messageVersion,
      passFailType: data.orderRequirement.type,
      data: data?.candidateRequirement?.data?.vaccinationStatus,
      messageTypeId: IPlacementRequirementKey.orderCovid,
      tooltipTypeParam: undefined,
      nextStatusRequirementParam: data.nextStatusRequirement,
    },
    type: placementSummaryActions.setFailedRequirement.type,
  });
}
