import { GridCellParams } from '@mui/x-data-grid-pro';
import { materialUiXGrid } from 'amn-ui-core';
import { Box, ClickAwayListener, MenuItem, Select } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import ArrowIcon from 'app/assets/images/Credentialing/PersonalInfo/arrow.svg';
import { CustomTooltip } from 'app/components/Common/Tooltips';

const useStyles = makeStyles()({
    hidden: {
        display: 'none',
    },
    input: {
        width: '100%',
        height: '30px',
        border: 'none',
        '&:focus': {
            border: '1px dotted gray',
            outline: 'none',
        },
    },
    inputInvalid: {
        color: 'red',
        padding: '10px'
    },
    inputValid: {
        color: '#a3a2a2',
    },
    dropdown: {
        paddingTop: 9,
        height: 36,
        marginTop: 7,
        width: 115,
        fontSize: '14px',
    },
    dropdownStyle: {
        background: '#ebf4fa',
        maxHeight: '350px',
    },
});

interface GridDropdownCellProps {
    onEndEdit: ({ value, params }: { value: any; params: GridCellParams }) => void;
    validator?: ({ value, params }: { value: any; params: GridCellParams }) => boolean;
    placeholder?: string;
    mandatory?: boolean;
    editOnSingleClick?: boolean;
    Input?: any;
    dropdownOptions?: any;
    disableCellEdit?: boolean;
    maritialOptions?: any[];
}
export const exemptOptions = [{ id: 'Yes', description: 'Yes' }, { id: 'No', description: 'No' }];

export const GridDropdownCell =
    ({ ...props }: GridDropdownCellProps) =>
        (params: GridCellParams) => {
            return <XGridEditableCellContent params={params} props={props} />;
        };

const XGridEditableCellContent = ({ props, params }: { props: GridDropdownCellProps; params: GridCellParams }) => {
    const { classes, cx } = useStyles();
    const api = materialUiXGrid.useGridApiContext();
    const { onEndEdit, validator, placeholder, mandatory, editOnSingleClick = false, disableCellEdit = false } = props;
    const [firstInteraction, setFirstInteraction] = React.useState<boolean>(true);
    const [editing, setEditing] = React.useState<boolean>(false);
    const [editValue, setEditValue] = React.useState<any>(params.value);
    const [valid, setValid] = React.useState<boolean>(true);
    const [options, setOptions] = React.useState<any[]>([]);

    React.useEffect(() => {
        setEditValue(params.value);
        if (validator && !firstInteraction) {
            setValid(validator({ value: editValue, params: params }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.value, validator]);

    React.useEffect(() => {
        if (validator && !firstInteraction) {
            setValid(validator({ value: editValue, params: params }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, params.value, validator]);

    const initializeData = () => {
        switch (params.colDef.field) {
            case 'maritialStatus':
                if(props.maritialOptions.findIndex(x=>x.description === '')){
                  props.maritialOptions.unshift({id:null, description: ''});
                }
                setOptions(props.maritialOptions);
                break;
            case 'exemptStatus':
                setOptions(exemptOptions);
                break;
        }
    };

    const startEditing = React.useCallback(() => {
        initializeData();
        setFirstInteraction(false);
        if (!editOnSingleClick) setEditing(true);
        setTimeout(() => {
            if (editOnSingleClick) setEditing(true);
            document.getElementById(`grid-cell-input-${params.row.id}-${params.colDef.field}`)?.focus();
        }, 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editOnSingleClick, params.colDef.field, params.row.id]);

    const _onEndEditing = () => {
        if (editing) setEditing(false);
    };

    const onKeyUp = React.useCallback(
        event => {
            if (
                event.keyCode === 13 &&
                document.activeElement?.getAttribute('data-field') === params.field &&
                document.activeElement?.getAttribute('data-rowindex') ===
                `${api?.current?.getRowIndexRelativeToVisibleRows(params.id)}`
            ) {
                startEditing();
            }
        },
        [api, params.field, params.id, startEditing],
    );

    React.useEffect(() => {
        document.addEventListener('keyup', onKeyUp);
        return () => {
            document.removeEventListener('keyup', onKeyUp);
        };
    }, [onKeyUp, params]);

    const onInputChanged = value => {
        setEditValue(value);
    };

    const handleSelect = value => {
        setEditValue(value);
        if (editing) {
            setEditing(false);
            api?.current?.updateRows([{ ...params.row, [params.colDef.field]: value }]);
            onEndEdit({ value: value, params: params });
            if (validator) {
                setValid(validator({ value: value, params: params }));
            }
        }
        setOptions([]);
    };

    
  return (
    <React.Fragment>
      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={_onEndEditing}>
        <div style={{ width: '100%' }} className={cx({ [classes.hidden]: !editing })}>         
            <>
              {options?.length > 0 && !disableCellEdit ? (
                <Select
                  disableUnderline
                  className={classes.dropdownStyle}
                  labelId="select-filled-label"
                  value={editValue}
                  onChange={event => handleSelect(event.target.value)}
                  disabled={disableCellEdit}
                  IconComponent={props => (
                    <img
                      src={ArrowIcon}
                      alt="arrow"
                      style={{ marginRight: '10px', marginLeft: '10px', marginTop: '8px' }}
                      {...props}
                    />
                  )}
                  inputProps={{ className: classes.dropdown }}
                >
                  {(params.colDef.field === 'exemptStatus' ||
                    params.colDef.field === 'maritialStatus') &&
                    options?.map((x: any, idx: any) => {
                      return (
                        <MenuItem key={idx} value={x?.description}>
                          {x?.description ?? ''}
                        </MenuItem>
                      );
                    })}
                </Select>
              ): <>{editing ? editValue : ''}</>}
            </>          
        </div>
      </ClickAwayListener>

      <Box
        onClick={editOnSingleClick ? startEditing : undefined}
        onDoubleClick={!editOnSingleClick ? startEditing : undefined}
        className={cx({
          [classes.hidden]: editing,
          [classes.inputInvalid]: !valid || params.row[`${params.field}_error`] === true,
        })}
        style={{ width: '100%', minHeight: '20px' }}
      >
        {editValue ? (
          <span style={{padding: '10px', fontSize: '14px'}}> {editValue} </span>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {mandatory && (!validator?.({ value: params.value, params }) || false) && (
              <span style={{ padding: '0px 5px 2px 0px' }}>
                <CustomTooltip tooltipContent={'This field is required'}>
                  <WarningIcon style={{ color: 'red', height: '14px', width: '14px' }} />
                </CustomTooltip>
              </span>
            )}
            <span
              className={cx({
                [classes.inputInvalid]: !valid || params.row[`${params.field}_error`] === true,
                [classes.inputValid]: valid && !params.row[`${params.field}_error`],
              })}
            >{`${placeholder || params.colDef.headerName}`}</span>
          </div>
        )}
      </Box>
    </React.Fragment>
  );
};