import { ChipsContainer } from 'app/ComponentLibrary/Filter/ChipsContainer';
import React, { createRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectFilters } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';
import { determineChipSelected } from '../PlacementsDesk/filterHelper';
import { generateChips } from '@AMIEWEB/GlobalSearch/helper';
import { gridStateActions } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { isEqual } from 'lodash';

export const CoverageResultschip = props => {
  const { category } = props;
  const dispatch = useDispatch();
  const [chips, setChips] = useState({});
  const hiddenSubmitButton = createRef<HTMLButtonElement>();
  const { setValue, getValues } = useFormContext();
  const { t } = useTranslation();
  const filterObject = useSelector(selectFilters);

  const getNewSelection = (key, chip) => ({
    ...filterObject?.selectedChipFilters,
    [key]: determineChipSelected(
      chip,
      filterObject?.selectedChipFilters ? filterObject?.selectedChipFilters[key] : undefined,
    ),
  });

  const handleChipDelete = (key, chip) => {
    const chosenValue = getValues(key);

    if (chosenValue && chosenValue.length > 0 && Array.isArray(chosenValue)) {
      const newValues = [...chosenValue];
      newValues.splice(
        newValues.findIndex(opt => isEqual(opt, chip.value)),
        1,
      );
      setValue(key, newValues);
    } else {
      if (key === 'dateRange') {
        setValue(key, null);
      }
    }
    dispatch(
      gridStateActions.setFilterAttributes({
        filterObject: Object.entries(getValues()),
      }),
    );
  };

  /** Restricted to call onApplyfilter directly -
   * observes not to remove existing filter and chips */
  const handleChipClick = (key, chip) => {
    /**Check to avoid click on single valued fields/chips */
    if (!Array.isArray(getValues(key))) return;
    /**
     * Below functionalty are limited to type ISelection
     * Need to be updated for dates and single selection
     */
    const newSelections = getNewSelection(key, chip);
    setChips(generateChips({ ...getValues() }, t, category, false, newSelections));
    props?.onApplyFilter(getValues(), newSelections);
  };

  useEffect(() => {
    setChips(
      filterObject?.filters
        ? generateChips(filterObject?.filters, t, category, false, filterObject?.selectedChipFilters)
        : {},
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterObject]);

  return (
    <>
      <ChipsContainer
        chips={chips}
        onDelete={(field, chip, index) => handleChipDelete(field, chip)}
        onClick={(field, chip, index) => handleChipClick(field, chip)}
      />
      <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
    </>
  );
};
