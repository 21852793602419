import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import baseAxiosInstance from 'utils/BaseApi';
import { httpSuccess } from '../serviceHelpers';
import { formatEmployeeList } from './helper';
import { IDenyCoverageRequest } from 'app/models/UserCoverage/UserCoverage';

const taskBase = globalThis?.app?.env?.REACT_APP_TASKS_SERVICE_BASE_URL;
const sharedBase = globalThis?.app?.env?.REACT_APP_TRACK_CHANGE_SERVICE_BASE_URL;

export async function assignCoverageRequest(body) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/coverage/create`, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'assignCoverageRequest',
        area: 'src/app/services/CoverageServices/CoverageServices.ts',
      },
    });
    return error;
  }
}

export async function updateAssignedCoverage(body) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/coverage/update`, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateAssignedCoverage',
        area: 'src/app/services/CoverageServices/CoverageServices.ts',
      },
    });
    return error;
  }
}
export async function getCoverageRequestList(body) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/coverage/list`, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCoverageRequestList',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return error;
  }
}

export async function denyCoverageRequest(body : IDenyCoverageRequest){
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/coverage/update-status`, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'denyCoverageRequest',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return error;
  }
}

export async function getCoverageSearchLookups(keyword) {
  try {
    const url = `${sharedBase}/search/active-employees/${keyword ? keyword : 'amie'}`;
    const response = await baseAxiosInstance.get(url);
    if (!httpSuccess(response?.status)) {
      return [];
    } else {
      if (response?.data?.length > 0) return formatEmployeeList(response?.data);
      else return [];
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getSearchLookups',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
    return [];
  }
}
