import { put, call, takeLatest, select, cancelled } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { trackPromise } from 'react-promise-tracker';
import { getAMIECategorySearch } from '../../../app/services/SharedServices/SharedServices';
import { SearchType } from '../../../app/models/GlobalSearch/GlobalSearch';
import { candidateSearchFlattenData } from '../../../app/components/GlobalSearch/Candidate/SearchHelper';
import { placementFlattenData } from '../../../app/components/GlobalSearch/Placement/SearchHelper';
import { orderFlattenData } from '../../../app/components/GlobalSearch/Order/SearchHelper';
import { facilityFlattenData } from '../../../app/components/GlobalSearch/Facility/SearchHelper';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { TFunction } from 'i18next';
import { gridSelectionActions } from 'app/components/Common/Grid/GridSelection/GridSelection.redux';
import { selectUser } from 'oidc/user.selectors';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { httpSuccess } from 'app/services/serviceHelpers';
import { updatePlacementArchiveStatus } from 'app/services/PlacementServices/PlacementServices';
import { selectGridSelection } from 'app/components/Common/Grid/GridSelection/GridSelection.selector';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { globalSearchActions, totalCountErrorThreshold } from 'store/redux-store/global-search/slice';
import { selectSearchResults } from 'store/redux-store/global-search/selectors';
import axios from 'axios';
import { navigationProfilesAction } from '@AMIEWEB/GlobalSearch/store/NavigationProfiles.redux';
import { bulkVerifyOrderService } from 'app/services/OrderServices/OrderServices';
import i18next from 'i18next';
import { SortColumn } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
const TrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'global-search-call');
const BulkVerifyOrdersTrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'bulk-verify-orders-call');

export function* requestGlobalSearchResults(
  action: PayloadAction<{
    searchType: SearchType;
    keyword: string;
    filter?: any;
    candidateQuery: string;
    pageNumber: number;
    pageSize: number;
    sortedColumn?: { column: string; direction: 'asc' | 'desc' } | undefined | null;
    multiSortedColumns?: SortColumn[] | undefined | null;
    translation: TFunction;
    limit: { key: string; value: string };
    navigationDataLoad?: boolean;
  }>,
) {
  const cancelTokenSource = axios.CancelToken.source();
  try {
    yield put(globalActions.closeBanner());
    yield put(globalSearchActions.clearSearchResults());
    const requestSearch = {
      searchType: action.payload.searchType,
      keyword: action.payload.keyword,
      pageNumber: action.payload.pageNumber,
      pageSize: action.payload.pageSize,
      sortedColumn:
        (action.payload.searchType === 'candidate' && action.payload.sortedColumn?.column === 'radius'
          ? {
              column: 'distance',
              direction: action.payload.sortedColumn?.direction,
            }
          : action.payload.sortedColumn) || undefined,
      latitude: action.payload.filter.zipCode?.latitude || undefined,
      longitude: action.payload.filter.zipCode?.longitude || undefined,
      ...action.payload.filter,
      radius: action.payload.filter.radius ? parseInt(action.payload.filter.radius) : 0,
      multiSortedColumns: action.payload.multiSortedColumns,
    };
    const response = yield call(TrackWrapper, getAMIECategorySearch, requestSearch, cancelTokenSource.token);
    if (response?.paging?.total > totalCountErrorThreshold) {
      yield put(
        globalActions.setBanner({
          message: action.payload.translation('search.globalSearch.exceedLimitBanner'),
          severity: 'warning',
          justify: 'center',
        }),
      );
    } else {
      yield put(globalActions.closeBanner());
    }
    response
      ? yield put(
          globalSearchActions.setSearchResults({
            items:
              action.payload.searchType === SearchType.candidate
                ? candidateSearchFlattenData(response.items)
                : action.payload.searchType === SearchType.placement
                ? placementFlattenData(response.items)
                : action.payload.searchType === SearchType.order
                ? orderFlattenData(response.items)
                : action.payload.searchType === SearchType.facility
                ? facilityFlattenData(response.items)
                : [],
            count: response?.paging?.total,
            miscellaneous: {
              positionCount:
                action.payload.searchType === SearchType.order ? response.facets?.positionsAvailable[0]?.count : 0,
              jobMatchHealth: response.jobMatchHealth,
              jobInterestHealth: response.jobInterestHealth,
            },
          }),
        )
      : yield put(globalSearchActions.reset());
    // reset selected rows in use with X-Grid
    response
      ? !!action.payload?.navigationDataLoad &&
        (yield put(
          navigationProfilesAction.setNavigationData({
            items:
              action.payload.searchType === SearchType.candidate
                ? candidateSearchFlattenData(response.items)
                : action.payload.searchType === SearchType.placement
                ? placementFlattenData(response.items)
                : action.payload.searchType === SearchType.order
                ? orderFlattenData(response.items)
                : action.payload.searchType === SearchType.facility
                ? facilityFlattenData(response.items)
                : [],
          }),
        ))
      : yield put(navigationProfilesAction.reset());
    yield put(
      gridSelectionActions.setGridSelections({
        selectedData: [],
      }),
    );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestGlobalSearchResults',
        area: 'src/app/components/GlobalSearch/store/GlobalSearch.saga.ts',
      },
    });

    //Bug 100100: UI:Api error is not handled properly in order and facility search grid. even after getting 400/500 error api grid shows old data
    yield put(globalSearchActions.reset());
  } finally {
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

function* archivePlacements() {
  const { userInfo } = yield select(selectUser);
  const { selectedData } = yield select(selectGridSelection);
  try {
    // reset success before attempting to archive
    yield put(
      globalSearchActions.setBulkArchivePlacements({
        success: null,
      }),
    );

    const placements = yield call(getArchivablePlacements, {
      payload: {},
      type: globalSearchActions.getArchivablePlacements.type,
    });

    // Set loading to true
    yield put(
      globalSearchActions.setBulkArchivePlacements({
        loading: true,
      }),
    );

    const response = yield call(updatePlacementArchiveStatus, { Placements: placements, userId: userInfo.employeeId });
    if (httpSuccess(response?.status)) {
      // When finished, close dialog
      yield put(
        globalSearchActions.setBulkArchivePlacements({
          loading: false,
          dialogOpen: false,
          success: true,
        }),
      );
      // show snackbar message
      const snackMessage = `${response?.data?.placementsUpdated} of ${selectedData.length} ${i18next.t(
        'placement.archivePlacement.apiResponse',
      )}`;
      yield put(globalActions.setSnackBar({ message: snackMessage, severity: 'success' }));
    } else {
      // Error occurred, close the dialog and show error snackbar
      yield put(
        globalSearchActions.setBulkArchivePlacements({
          loading: false,
          success: false,
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'archivePlacements',
        area: 'src/app/components/GlobalSearch/store/GlobalSearch.saga.ts',
      },
    });
  }
}

const PlacementArchiveUserRules = [
  { name: 'Recruiter', AllowedStatus: ['Declined by Facility', 'Request Cancelled'] },
  { name: 'Account Manager', AllowedStatus: ['Declined by Traveler', 'Request Cancelled'] },
  { name: 'All', AllowedStatus: ['Request Cancelled'] },
];

function* getArchivablePlacements(action: PayloadAction<{ result?: (rows: any[]) => void }>) {
  try {
    const { selectedData } = yield select(selectGridSelection);
    const { items } = yield select(selectSearchResults);

    // Example of determining if a user is authorized
    const PlacementArchiveUniqStatus = yield call(getPlacementStatusPerRole);

    // Returns an array of placementIDs
    const archivableRows: any[] = items
      .filter(item => {
        return selectedData.includes(item.id) && PlacementArchiveUniqStatus.indexOf(item.status) > -1;
      })
      .map(item => {
        return { placementId: item.placementId, activityStatus: item.status };
      });

    action.payload?.result?.(archivableRows);
    return archivableRows;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getArchivablePlacements',
        area: 'src/app/components/GlobalSearch/store/GlobalSearch.saga.ts',
      },
    });
    return [];
  }
}

function* getPlacementStatusPerRole() {
  try {
    const { userInfo } = yield select(selectUser);
    const isRecruiter: boolean =
      Authorized(
        [userRoles.recruitment, userRoles.recruitment_Leadership, userRoles.recruitment_TeamMember],
        userInfo,
      ) ?? false;

    const isAccountManager: boolean =
      Authorized(
        [userRoles.accountManagement, userRoles.accountManagement_Leadership, userRoles.accountManagement_TeamMember],
        userInfo,
      ) ?? false;

    const PlacementArchiveRule = (
      isRecruiter && isAccountManager
        ? PlacementArchiveUserRules.filter(x => x.name === 'Recruiter' || x.name === 'Account Manager')
        : isRecruiter
        ? PlacementArchiveUserRules.filter(x => x.name === 'Recruiter')
        : isAccountManager
        ? PlacementArchiveUserRules.filter(x => x.name === 'Account Manager')
        : PlacementArchiveUserRules.filter(x => x.name === 'All')
    ).map(x => x.AllowedStatus);

    const PlacementArchiveStatus = PlacementArchiveRule.concat.apply([], PlacementArchiveRule);
    const PlacementArchiveUniqStatus = PlacementArchiveStatus.filter((c, index) => {
      return PlacementArchiveStatus.indexOf(c) === index;
    });
    PlacementArchiveUniqStatus.sort();
    yield put(
      globalSearchActions.setBulkArchivePlacements({
        placementStatusesAllowed: PlacementArchiveUniqStatus as unknown as string[],
      }),
    );
    return PlacementArchiveUniqStatus;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getPlacementStatusPerRole',
        area: 'src/app/components/GlobalSearch/store/GlobalSearch.saga.ts',
      },
    });
    return [[]];
  }
}
export function* bulkVerifyOrders(
  action: PayloadAction<{
    orderIds: any[];
    DateVerified: string;
    VerifiedByUserId: number;
  }>,
) {
  const cancelTokenSource = axios.CancelToken.source();
  try {
    const requestSearch = {
      orderIds: action.payload.orderIds,
      DateVerified: action.payload.DateVerified,
      VerifiedByUserId: action.payload.VerifiedByUserId,
    };
    const response = yield call(
      BulkVerifyOrdersTrackWrapper,
      bulkVerifyOrderService,
      requestSearch,
      cancelTokenSource.token,
    );

    if (response) {
      yield put(globalActions.setSnackBar({ message: response?.data?.message, severity: 'success' }));
      yield put(globalSearchActions.setBulkOrders({ bulkVerifyOrdersFlag: true }));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'bulkVerifyOrders',
        area: 'src/app/components/GlobalSearch/store/GlobalSearch.saga.ts',
      },
    });
  }
}

export function* globalSearchSaga() {
  yield takeLatest(globalSearchActions.getSearchResults.type, requestGlobalSearchResults);
  yield takeLatest(globalSearchActions.archivePlacements.type, archivePlacements);
  yield takeLatest(globalSearchActions.getArchivablePlacements.type, getArchivablePlacements);
  yield takeLatest(globalSearchActions.bulkVerifyOrders.type, bulkVerifyOrders);
}
