import { Grid } from 'amn-ui-core';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { GenericTabs } from '@AMIEWEB/Common/Tabs/GenericTabs';
import { TemplateList } from './TemplatesList';
import { AddTemplateModal } from './AddTemplateModal';

const useStyles = makeStyles<{ disabled: boolean }>()((theme, { disabled = false }) => ({
  tabsLayout: {
    height: '100%',
    '& .MuiTabs-indicator': {
      backgroundColor: disabled ? theme.palette.system.blurGrey : theme.palette.system.violet,
    },
  },
}));

interface IEmailTemplate {
  value: string;
  cards: any[];
  facilityCards: any[];
  disabled: boolean;
  onChange: Function;
  showAddEditTemplate: boolean;
  setShowAddEditTemplate: Function;
  setTabValue: Function;
  tabValue: number;
  facilityId: number;
}

export const EmailTemplates = (props: IEmailTemplate) => {
  const {
    value,
    cards = [],
    facilityCards = [],
    disabled,
    onChange,
    showAddEditTemplate,
    setShowAddEditTemplate,
    tabValue,
    setTabValue,
    facilityId,
  } = props;
  const { classes } = useStyles({ disabled });
  const { t } = useTranslation();

  const customHandleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const tabs = React.useMemo(
    () => [
      {
        label: t('Public'),
        component: (
          <TemplateList
            value={value}
            cards={cards?.filter(c => !c?.isPrivate)}
            disabled={disabled}
            onChange={onChange}
          />
        ),
        disabled: disabled,
      },
      {
        label: t('Facility'),
        component: (
          <TemplateList
            value={value}
            cards={facilityCards}
            disabled={disabled}
            onChange={onChange}
            editIcon
            setShowAddEditTemplate={setShowAddEditTemplate}
          />
        ),
        disabled: disabled,
      },
    ],
    [t, value, cards, facilityCards, disabled, onchange],
  );

  return (
    <Grid container direction="row">
      <GenericTabs
        tabs={tabs}
        // modifyUrl
        value={tabValue}
        customHandleChange={customHandleChange}
        analytics={{ name: 'preference tab' }}
        className={classes.tabsLayout}
      />
      <AddTemplateModal
        open={showAddEditTemplate}
        onCancel={() => setShowAddEditTemplate(false)}
        facilityId={facilityId}
      />
    </Grid>
  );
};
