import { RatingValue } from './enums';
import { ISkillChecklistSection } from './model';

/**
 * Method to find the section data using the section id
 */
export const getSectionData = (section: ISkillChecklistSection[], sectionItem: ISkillChecklistSection) => {
  if (sectionItem) {
    return section.filter(item => item.section === sectionItem.section);
  }
};

/**
 * Method to split array into two for displaying skill checklist as two sections
 */
export const splitArrayByLength = array => {
  let firstHalf = [];
  let secondHalf = [];
  if (array?.length > 0) {
    const middleIndex = Math.ceil(array / 2);
    firstHalf = array.slice(0, middleIndex);
    secondHalf = array.slice(-middleIndex);
  }
  return { firstHalf, secondHalf };
};

/**
 * Method to get rating title
 * @param ratingId
 * @param showDescription
 * @returns
 */
export const getRatingTitle = (ratingId: number, showDescription: boolean) => {
  switch (ratingId) {
    case RatingValue.No_Experience:
      return `No experience${showDescription ? '; requires education, training and supervision' : ''}`;
    case RatingValue.Intermittent_Experience:
      return `Intermittent experience${showDescription ? '; may need support or supervision' : ''}`;
    case RatingValue.Proficient:
      return `Proficient${showDescription ? '; consistent experience, independent' : ''}`;
    case RatingValue.Expert_Level:
      return `Expert level${showDescription ? '; can teach/supervise others' : ''}`;
  }
};
