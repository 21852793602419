import React from 'react';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from 'amn-ui-core';

export const TaxInfoDeleteDialog = props => {
  const { name, openDeleteDialog, onDeleteDialogClose, deleteRecords } = props;
  const { t } = useTranslation();

  return (
    <GenericDialog
      open={openDeleteDialog}
      variant={'white'}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          event.preventDefault();
          event.stopProgagation();
          return;
        }
      }}
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
      dialogTitleProps={{
        text: t('candidate.payRollAccounting.dialogTitle'),
        closeButton: false,
      }}
      dialogActions={[
        {
          color: 'tertiary',
          text: t('candidate.payRollAccounting.cancel'),
          onClick: () => onDeleteDialogClose(),
        },
        {
          text: t('candidate.payRollAccounting.delete'),
          variant: 'contained',
          onClick: () => deleteRecords(),
        },
      ]}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1">{t('candidate.payRollAccounting.dialogContent')}</Typography>
        </Grid>
      </Grid>
    </GenericDialog>
  );
};
