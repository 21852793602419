import { InputAdornment, TextField, useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { SnackbarConfirmation } from 'app/components/Notification/Common/CloseDialog';
import { SaveCallLog, SaveConversationNotes } from 'app/services/NotificationServices/NotificationService';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styledComponent from 'styled-components';
import { Concatenate } from 'utils/string/string';
import { candidateDetailActions } from '../../Profile/CandidateDetails.redux';
import { candidateDetailsSelection } from '../../Profile/CandidateDetails.selector';
import { COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT } from '@AMIEWEB/Notification/Constants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { ISaveCallLogPayload } from 'app/models/Notification/Notification';
import { selectFacilityCommunicationLog } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/CommunicationHistory/store/FacilityCommunicationHistory.selector';
import { facilityCommunicationHistoryActions } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/CommunicationHistory/store/FacilityCommunicationHistory.redux';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';

const useStyles = makeStyles()(theme => ({
  notesRoot: {
    '& .MuiInputBase-input.Mui-disabled': {
      color: theme.palette.framework.system.charcoal,
    },
  },
  headerIcon: {
    fontSize: '40px',
  },
  notes: {
    minHeight: '4vh',
  },
  modalContent: {
    margin: '25px',
    color: theme.palette.framework.system.platinum,
  },
  modalContainer: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '620px',
      overflow: 'hidden',
      maxHeight: '400px',
    },
  },
  errorText: {
    fontSize: '12px',
    justifyContent: 'center',
    color: theme.palette.framework.system.errorRed,
  },
  characterCount: {
    fontSize: '12px',
    justifyContent: 'center',
    color: theme.palette.framework.system.tertiaryGrey,
  },
  errorInfoIcon: {
    width: '16px',
    height: '16px',
  },
  dialogContent: {
    padding: 0,
  },
  notesAdornment: {
    position: 'absolute',
    top: '0',
    right: '0',
    color: theme.palette.framework.system.errorRed,
  },
  countContainer: {
    width: '100%',
    marginLeft: '0px',
  },
}));

const Notes = styledComponent(TextField)`
  margin-top: 1%;
  width: 100%;
`;
interface INotesModalProps {
  open: boolean;
  log: any;
  onClose: () => void;
  isPreview?: boolean;
  rowId: number;
  isCandiDateProfile?: boolean;
}
export const NotesModal = (props: INotesModalProps) => {
  const { open, log, onClose, isPreview = false, rowId, isCandiDateProfile = true } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [notes, setNotes] = useState<string>(log?.response?.notes || '');
  const contactName = `${log?.message.tos?.find(Boolean)?.name}` || '';
  const [closeModal, setCloseModal] = useState(false);
  const { classes } = useStyles();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const facilityCommunicationLog = useSelector(selectFacilityCommunicationLog);
  const [previewOnly, setPreviewOnly] = useState<boolean>(isPreview);
  const [focused, setFocused] = useState(false);

  const updateGrid = () => {
    setPreviewOnly(false);
    onClose();
    if (isCandiDateProfile) {
      const candiadteGridLog = _.cloneDeep(candidateDetails.gridLogsData);
      if (candiadteGridLog?.results) {
        candiadteGridLog.results[rowId]?.response
          ? (candiadteGridLog.results[rowId].response.notes = notes)
          : (candiadteGridLog.results[rowId] = { ...candiadteGridLog.results[rowId], response: { notes: notes } });
      }
      dispatch(candidateDetailActions.updateGridLogsData(candiadteGridLog));
    } else {
      const facilityGridLog = _.cloneDeep(facilityCommunicationLog);
      if (facilityGridLog?.results) {
        facilityGridLog.results[rowId]?.response
          ? (facilityGridLog.results[rowId].response.notes = notes)
          : (facilityGridLog.results[rowId] = { ...facilityGridLog.results[rowId], response: { notes: notes } });
      }
      dispatch(facilityCommunicationHistoryActions.updateFacilityCommunicationLogs(facilityGridLog));
    }

    dispatch(
      globalActions.setSnackBar({
        message: log?.response?.notes ? 'Note has been updated successfully!' : `Note has been added successfully!`,
        severity: 'success',
      }),
    );
  };

  const handleSubmit = async () => {
    if (log.channel === 'Conversation') {
      const addNotesData = {
        conversationId: log.id,
        brand: log?.brand,
        brandId: log?.associatedRecords?.find(x => x.name.toLowerCase() === 'brandid')?.value,
        sender: log?.message.sender,
        tos: log?.message.tos[0],
        notes: notes,
        publishedOn: new Date(),
      };
      setPreviewOnly(true);
      try {
        const response = await SaveConversationNotes(addNotesData);
        if (response.saved === true) updateGrid();
      } catch (error) {
        setPreviewOnly(false);
        dispatch(
          globalActions.setSnackBar({
            message: `Failed to save notes ${error}`,
            severity: 'error',
          }),
        );
      }
    }
    if (log.channel === 'Voice') {
      const addNotesData: ISaveCallLogPayload = {
        id: log.id,
        CTIActivityHistoryId: log.response?.messageId,
        manualLog: false,
        channel: log?.channel,
        direction: log?.message.direction,
        disposition: log?.response?.disposition,
        notes: notes,
        associatedRecords: log?.associatedRecords,
        sender: log?.message.sender,
        tos: log?.message.tos,
        useType: log?.useType,
        formName: log?.message.formName,
        brand: log?.brand,
        brandId: log?.brandId,
        userRole: Concatenate(log.userRoles, ','),
        initiatedOn: new Date(),
      };
      setPreviewOnly(true);
      try {
        const response = await SaveCallLog(addNotesData);
        if (response.data.saved === true) updateGrid();
      } catch (error) {
        setPreviewOnly(false);
        dispatch(
          globalActions.setSnackBar({
            message: `Failed to save notes ${error}`,
            severity: 'error',
          }),
        );
      }
    }
  };

  const handleCloseAction = () => {
    if ((log.response?.notes && log.response?.notes !== notes) || (!log.response?.notes && notes)) {
      setCloseModal(true);
    } else {
      onClose();
    }
  };

  return (
    <React.Fragment>
      <GenericDialog
        open={open}
        draggable
        disablePortal
        disableEscapeKeyDown
        dialogTitleProps={{
          text: `${
            !log?.response?.notes ? t('facility.notesModal.addNote') : t('facility.notesModal.editNote')
          } ${contactName} ${isCandiDateProfile ? `| CID ${log?.message.tos?.find(Boolean)?.contactId}` : ''}`,
          expandable: true,
          closeButton: true,
        }}
        dialogContentProps={{
          classes: { root: classes.dialogContent },
        }}
        dialogActions={[
          {
            text: t('facility.notesModal.cancel'),
            variant: 'contained',
            color: 'tertiary',
            onClick: () => handleCloseAction(),
          },
          {
            text: t('facility.notesModal.save'),
            variant: 'contained',
            color: 'primary',
            onClick: () => handleSubmit(),
            disabled: !notes || notes === log?.response?.notes || previewOnly,
          },
        ]}
        fullWidth={true}
        className={classes.modalContainer}
        variant={'blue'}
        onClose={() => handleCloseAction()}
      >
        <div className={classes.modalContent}>
          <Notes
            label={t('notification.voice.enterNotes')}
            className={classes.notesRoot}
            disabled={previewOnly}
            value={notes}
            onChange={event => !previewOnly && setNotes(event.target.value)}
            multiline
            inputProps={{
              maxLength: COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT,
            }}
            InputProps={{
              classes: { input: classes.notes },
              onFocus: () => setFocused(true),
              onBlur: () => setFocused(false),
              startAdornment:
                focused && notes?.length >= COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT ? (
                  <InputAdornment position="start" className={classes.notesAdornment}>
                    <InfoOutlinedIcon className={classes.errorInfoIcon} />
                  </InputAdornment>
                ) : (
                  <></>
                ),
            }}
            variant="filled"
            error={focused && notes?.length === COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT}
          />
          <LayoutGrid container justifyContent="space-between" className={classes.countContainer} xs={12}>
            <LayoutGridItem style={{ paddingLeft: '12px' }}>
              <label className={classes.errorText}>{`${
                focused && notes?.length === COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT
                  ? t('notification.voice.maxCharLimit')
                  : ' '
              }`}</label>
            </LayoutGridItem>
            <LayoutGridItem>
              <label
                className={
                  focused && notes?.length === COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT
                    ? classes.errorText
                    : classes.characterCount
                }
              >{`${notes?.length}/${COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT}`}</label>
            </LayoutGridItem>
          </LayoutGrid>
        </div>
        {closeModal && <SnackbarConfirmation yesAction={() => onClose()} noAction={() => setCloseModal(false)} />}
      </GenericDialog>
    </React.Fragment>
  );
};
