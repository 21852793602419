import React, { useMemo } from 'react';
import { Box, Grid, Skeleton } from 'amn-ui-core';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import { useEducationalHistoryStyles } from './utils';
import { Timeline } from '@mui/lab';
import { TimelineSection } from './TimeLineSection';
import { Concatenate } from 'utils/string/string';
import { getDateRange, getExperienceData } from './helper';
import { CandidateContainer } from '../../../SummaryTab/Common/CandidateContainer';
import EmploymentHistoryIcon from 'app/assets/images/ProfessionalProfileCandidate/EmploymentHistoryIcon.svg';
import AddIcon from '@mui/icons-material/Add';
import EditIconV2 from '@mui/icons-material/EditOutlined';

function EmployeementCardUIV2({ employmentHistories, handleClick, editWorkHistory, setOpenAddPop, addModalAccess }) {
  const { t } = useTranslation();
  const { classes } = useEducationalHistoryStyles();
  const { promiseInProgress } = usePromiseTracker({ area: 'get-candidate-details', delay: 0 });
  const timelineSections = useMemo(() => {
    return employmentHistories?.map((history, key) => (
      <TimelineSection
        key={key}
        facilityName={history.facilityName ? history.facilityName : ' '}
        discipline={history.disciplineAbbr ? history.disciplineAbbr : ' '}
        specialty={history.specialtyAbbr ? history.specialtyAbbr : ' '}
        dateRange={getDateRange(history.startedOn, history.endedOn)}
        place={Concatenate([history.location.city, history.location.state, history.location.country], ', ')}
        experience={getExperienceData(history.yearsOfExperience, history.monthsOfExperience)}
        approvedReferenceCount={history.approvedReferenceCount > 0 ? history.approvedReferenceCount : ' '}
      />
    ));
  }, [employmentHistories]);
  return (
    <CandidateContainer
      title={t('candidate.employmentHistoryCard.title')}
      icon={<img src={EmploymentHistoryIcon} alt="EmploymentHistoryIcon" />}
      rightIcon={<AddIcon color={addModalAccess ? 'inherit' : 'disabled'} />}
      iconToolTip={addModalAccess ? 'Add' : t('candidate.workExperienceView.permissionDisabled')}
      onClick={() => (addModalAccess ? setOpenAddPop(true) : null)}
      enableLink={true}
      onClickAll={handleClick}
      editIconToolTip={addModalAccess ? 'Edit' : t('candidate.workExperienceView.permissionDisabled')}
      rightEditIcon={<EditIconV2 color={addModalAccess ? 'inherit' : 'disabled'} />}
      onClickEditIcon={e => (addModalAccess ? editWorkHistory(e) : null)}
    >
      {promiseInProgress ? (
        <Grid item mr={2}>
          <Skeleton height={60} />
          <Skeleton height={60} />
          <Skeleton height={60} />
        </Grid>
      ) : (
        <Box paddingRight={'12px'}>
          <Timeline classes={{ root: classes.timeLineRoot }} position="left">
            {timelineSections}
          </Timeline>
        </Box>
      )}
    </CandidateContainer>
  );
}

export default EmployeementCardUIV2;
