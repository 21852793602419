import {
  IInternalTaskModalDetails,
  IStatusChip,
  ITaskComments,
  StatusUpdateModalVersion,
  TaskCoverageActionType,
  TaskStatus,
  TaskStatusReason,
} from 'app/models/Tasks/Tasks';
import { IUserInfo } from 'oidc/user.redux';
import { IUser } from 'app/models/Tasks/Tasks';
import { FormatDate } from '@AMIEWEB/Notification/Tasks/Common/FormatData';
import { ICoverageDetails } from 'app/models/UserCoverage/UserCoverage';

export const isSubTaskUserRecipient = (subTask, userInfo) => {
  let recipientDetails = subTask?.tos?.find(user => user.id === userInfo?.employeeId);
  if (!recipientDetails) {
    recipientDetails = subTask?.ccs?.find(user => user.id === userInfo?.employeeId);
  }
  return recipientDetails;
};

/**
 * Checks that the user is an assignee (assignedTo or CC) of a task/subtask and gets their data
 * @returns the first element where the user is an assignee, otherwise returns null
 */
export const getAssignee = (details: any, user: IUserInfo) => {
  const data = {
    ...details,
    assignedTo: details.assignedTo ?? details.tos ?? [],
    cc: details.ccValues ?? details.ccs ?? details.cc ?? [],
  };
  if (data?.assignedTo?.length) {
    const assignee = data?.assignedTo.find(asg=> asg.id === user?.employeeId);
    if (assignee) {
      return assignee;
    }
  }
  if (data?.cc?.length) {
    const assignee = data?.cc.find(asg => asg.id === user?.employeeId);
    if (assignee) {
      return assignee;
    }
  }
  return null;
};

/**
 * Checks that the user is an affiliate (assignedTo, CC, or creator) of a task/subtask and gets their data
 * @returns the first element where the user is an assignee, otherwise returns null
 */
export const checkUserEligible = (details: any, user: IUserInfo) => {
  const data = {
    ...details,
    assignedTo: details?.assignedTo ?? details?.tos ?? [],
    cc: details?.ccValues ?? details?.ccs ?? details?.cc ?? [],
  };
  if (data?.assignedTo?.length) {
    const assignee = data?.assignedTo.find(asg => asg.id === user?.employeeId);
    if (assignee) {
      return assignee;
    }
  }
  if (data?.cc?.length) {
    const assignee = data?.cc.find(asg => asg.id === user?.employeeId);
    if (assignee) {
      return assignee;
    }
  }
  if (data?.createdBy?.id === user?.employeeId) {
    return data?.createdBy;
  }
  return null;
};

/**
 * Checks that the user is an assignee (assignedTo or CC) of a task and gets their statusId
 * @returns the statusArray of type IStatusChip[]
 */

export const getUserTaskStatus = (taskDetails: IInternalTaskModalDetails) => {
  const UserStatusData: IStatusChip[] = [];
  const newUserStatus: IStatusChip = {
    statusId: 1,
    statusCount: 0,
    users: [],
  };
  const pendingUserStatus: IStatusChip = {
    statusId: 2,
    statusCount: 0,
    users: [],
  };
  const completedUserStatus: IStatusChip = {
    statusId: 3,
    statusCount: 0,
    users: [],
  };
  const cancelledUserStatus: IStatusChip = {
    statusId: 4,
    statusCount: 0,
    users: [],
  };
  taskDetails?.assignedTo?.forEach(assigned => {
    if (assigned?.statusId === TaskStatus.New) {
      newUserStatus.statusCount = newUserStatus.statusCount + 1;
      newUserStatus.users.push(assigned);
    }
    if (assigned?.statusId === TaskStatus.Pending) {
      pendingUserStatus.statusCount = pendingUserStatus.statusCount + 1;
      pendingUserStatus.users.push(assigned);
    }
    if (assigned?.statusId === TaskStatus.Completed) {
      completedUserStatus.statusCount = completedUserStatus.statusCount + 1;
      completedUserStatus.users.push(assigned);
    }
    if (assigned?.statusId === TaskStatus.Cancelled) {
      cancelledUserStatus.statusCount = cancelledUserStatus.statusCount + 1;
      cancelledUserStatus.users.push(assigned);
    }
  });
  taskDetails?.cc?.forEach(ccUser => {
    if (ccUser?.statusId === TaskStatus.New) {
      newUserStatus.statusCount = newUserStatus.statusCount + 1;
      newUserStatus.users.push(ccUser);
    }
    if (ccUser?.statusId === TaskStatus.Pending) {
      pendingUserStatus.statusCount = pendingUserStatus.statusCount + 1;
      pendingUserStatus.users.push(ccUser);
    }
    if (ccUser?.statusId === TaskStatus.Completed) {
      completedUserStatus.statusCount = completedUserStatus.statusCount + 1;
      completedUserStatus.users.push(ccUser);
    }
    if (ccUser?.statusId === TaskStatus.Cancelled) {
      cancelledUserStatus.statusCount = cancelledUserStatus.statusCount + 1;
      cancelledUserStatus.users.push(ccUser);
    }
  });
  if (newUserStatus.statusCount > 0) {
    UserStatusData.push(newUserStatus);
  }
  if (pendingUserStatus.statusCount > 0) {
    UserStatusData.push(pendingUserStatus);
  }
  if (completedUserStatus.statusCount > 0) {
    UserStatusData.push(completedUserStatus);
  }
  if (cancelledUserStatus.statusCount > 0) {
    UserStatusData.push(cancelledUserStatus);
  }
  return UserStatusData;
};

/**
 * Checks that the user is an assignee (assignedTo or CC) of a subTask and gets their statusId
 * @returns the statusArray of type IStatusChip[]
 */

export const getUserSubTaskStatus = (taskDetails: ITaskComments) => {
  const UserStatusData: IStatusChip[] = [];
  const newUserStatus: IStatusChip = {
    statusId: 1,
    statusCount: 0,
    users: [],
  };
  const pendingUserStatus: IStatusChip = {
    statusId: 2,
    statusCount: 0,
    users: [],
  };
  const completedUserStatus: IStatusChip = {
    statusId: 3,
    statusCount: 0,
    users: [],
  };
  const cancelledUserStatus: IStatusChip = {
    statusId: 4,
    statusCount: 0,
    users: [],
  };
  taskDetails?.tos?.forEach(assigned => {
    if (assigned?.statusId === TaskStatus.New) {
      newUserStatus.statusCount = newUserStatus.statusCount + 1;
      newUserStatus.users.push(assigned);
    }
    if (assigned?.statusId === TaskStatus.Pending) {
      pendingUserStatus.statusCount = pendingUserStatus.statusCount + 1;
      pendingUserStatus.users.push(assigned);
    }
    if (assigned?.statusId === TaskStatus.Completed) {
      completedUserStatus.statusCount = completedUserStatus.statusCount + 1;
      completedUserStatus.users.push(assigned);
    }
    if (assigned?.statusId === TaskStatus.Cancelled) {
      cancelledUserStatus.statusCount = cancelledUserStatus.statusCount + 1;
      cancelledUserStatus.users.push(assigned);
    }
  });
  taskDetails?.ccs?.forEach(ccUser => {
    if (ccUser?.statusId === TaskStatus.New) {
      newUserStatus.statusCount = newUserStatus.statusCount + 1;
      newUserStatus.users.push(ccUser);
    }
    if (ccUser?.statusId === TaskStatus.Pending) {
      pendingUserStatus.statusCount = pendingUserStatus.statusCount + 1;
      pendingUserStatus.users.push(ccUser);
    }
    if (ccUser?.statusId === TaskStatus.Completed) {
      completedUserStatus.statusCount = completedUserStatus.statusCount + 1;
      completedUserStatus.users.push(ccUser);
    }
    if (ccUser?.statusId === TaskStatus.Cancelled) {
      cancelledUserStatus.statusCount = cancelledUserStatus.statusCount + 1;
      cancelledUserStatus.users.push(ccUser);
    }
  });
  if (newUserStatus.statusCount > 0) {
    UserStatusData.push(newUserStatus);
  }
  if (pendingUserStatus.statusCount > 0) {
    UserStatusData.push(pendingUserStatus);
  }
  if (completedUserStatus.statusCount > 0) {
    UserStatusData.push(completedUserStatus);
  }
  if (cancelledUserStatus.statusCount > 0) {
    UserStatusData.push(cancelledUserStatus);
  }
  return UserStatusData;
};

/**
 * Formats the lists of recipients to be auto-populated in a task/subtask reply
 * @param createdBy Extends {@link IUser IUser}
 * @param assignedTos An array of users with unique IDs
 * @param ccs An array of users with unique IDs
 * @param user Extends {@link IUserInfo IUserInfo}
 * @returns an object consisting of the reply recipients
 */
export const generateReplyRecipients = (
  createdBy: IUser,
  assignedTos: any[],
  ccs: any[],
  user: IUserInfo,
  coverageProviders?: IUser[],
) => {
  if (user) {
    let creatorIsAlsoAssignedTo = false;
    let coverageProviderIsAlsoAssignedTo = false;
    const recipients = {
      assignedTo: assignedTos?.filter(emp => {
        if (emp.id === createdBy?.id) {
          creatorIsAlsoAssignedTo = true;
        }
        if (coverageProviders?.length > 0 && coverageProviders.find(e => e.id === emp.id)) {
          coverageProviderIsAlsoAssignedTo = true;
        }
        return (
          emp.id !== user?.employeeId ||
          (emp.id === user?.employeeId && creatorIsAlsoAssignedTo) ||
          (emp.id === user?.employeeId && coverageProviderIsAlsoAssignedTo)
        );
      }) as IUser[],
      cc: ccs?.filter(emp => emp.id !== user?.employeeId && emp.id !== createdBy?.id) as IUser[],
    };

    if (!creatorIsAlsoAssignedTo) {
      recipients?.assignedTo?.push(createdBy);
    }
    if (!coverageProviderIsAlsoAssignedTo) {
      coverageProviders?.forEach(coverageProvider => {
        recipients?.assignedTo?.push(coverageProvider);
      });
    }
    return recipients;
  }
};

export const getSubTaskRecipientList = (comment, user) => {
  const recipientsList = [...comment?.tos, ...comment?.ccs];
  if (recipientsList?.length > 0) {
    const assignee = recipientsList.find(asg => asg.id === user?.employeeId);
    if (assignee) {
      return assignee;
    }
  }
};

export const getCoverageSubTaskRecipientList = (details, coverageDetails) => {
  const recipientsList = [...details?.tos, ...details?.ccs];
  const availableRecipients = recipientsList.filter(obj1 =>
    coverageDetails.some(obj2 => obj2.coveredEmployeeId === obj1.id),
  );
  if (availableRecipients?.length > 0) {
    return availableRecipients[0];
  }
  return null;
};

export const getCoverageAssignee = (details, coverageDetails) => {
  const recipientsList = [...details?.assignedTo, ...details?.cc];
  const availableRecipients = recipientsList.filter(obj1 =>
    coverageDetails.some(obj2 => obj2.coveredEmployeeId === obj1.id),
  );
  if (availableRecipients?.length > 0) {
    return availableRecipients[0];
  }
  return null;
};

export const checkCoverageCreatedByEligibility = details => {
  const assigneeDetails = details?.assignedTo ?? details?.tos ?? [];
  const ccDetails = details?.ccValues ?? details?.ccs ?? details?.cc ?? [];
  const recipients = [...assigneeDetails, ...ccDetails];
  return recipients?.some(data => data?.statusId === TaskStatus.New || data?.statusId === TaskStatus.Pending);
};

export const getStatusUpdateReason = (taskDetails, version, user) => {
  const usersList =
    version === StatusUpdateModalVersion.PARENT_TASK
      ? [...taskDetails?.assignedTo, ...taskDetails?.cc]
      : [...taskDetails?.tos, ...taskDetails?.ccs];
  const isUserAffiliated =
    version === StatusUpdateModalVersion.PARENT_TASK
      ? getAssignee(taskDetails, user)
      : isSubTaskUserRecipient(taskDetails, user);

  const cancelledUserDetails = taskDetails?.taskStatusDetails?.find(
    record => record?.statusId === TaskStatus.Cancelled,
  );
  const completedUserDetails = taskDetails?.taskStatusDetails?.find(
    record => record?.statusId === TaskStatus.Completed,
  );
  if (isUserAffiliated) {
    if (isUserAffiliated?.statusId === TaskStatus.New || isUserAffiliated?.statusId === TaskStatus.Pending) {
      const data = {
        statusId: isUserAffiliated?.statusId,
        reason: taskDetails?.taskCategory,
      };
      return data;
    }
    const userStatusUpdateDetails = taskDetails?.taskStatusDetails?.find(
      record => record?.recipientId === user?.employeeId,
    );
    if (userStatusUpdateDetails?.statusId === TaskStatus.Completed) {
      const data = {
        statusId: userStatusUpdateDetails?.statusId,
        reason: `Completed Successfully - ${taskDetails?.taskCategory}`,
      };
      return data;
    }
    if (userStatusUpdateDetails?.statusId === TaskStatus.Cancelled) {
      const data = {
        statusId: userStatusUpdateDetails?.statusId,
        reason: `${userStatusUpdateDetails?.statusUpdateReason} - ${taskDetails?.taskCategory}`,
      };
      return data;
    }
  } else {
    const newPendingUser = usersList?.find(
      record => record?.statusId === TaskStatus.New || record?.statusId === TaskStatus.Pending,
    );
    if (newPendingUser && taskDetails?.createdBy?.id === user?.employeeId) {
      const data = {
        statusId: newPendingUser?.statusId,
        reason: taskDetails?.taskCategory,
      };
      return data;
    } else {
      if (newPendingUser) {
        const data = {
          statusId: newPendingUser?.statusId,
          reason: taskDetails?.taskCategory,
        };
        return data;
      }
      if (cancelledUserDetails) {
        const data = {
          statusId: cancelledUserDetails?.statusId,
          reason: `${cancelledUserDetails?.statusUpdateReason} - ${taskDetails?.taskCategory}`,
        };
        return data;
      }
      if (completedUserDetails) {
        const data = {
          statusId: completedUserDetails?.statusId,
          reason: `${completedUserDetails?.statusUpdateReason} - ${taskDetails?.taskCategory}`,
        };
        return data;
      }
    }
  }
};

/**
 * Gets a formatted list of task or subtask recipients for grouped Avatars
 * @returns a list of recipients with statusIds
 * @param taskData a task or subtask object
 */
export const getRecipientsForAvatars = taskData => {
  const data = {
    ...taskData,
    assignedTo: taskData.assignedTo ?? taskData.tos ?? [],
    cc: taskData.ccValues ?? taskData.ccs ?? taskData.cc ?? [],
  };
  return data?.assignedTo?.concat(data?.cc?.map(c => ({ ...c, isCC: 'Y' }))).map(recipient => ({
    ...recipient,
    assignedToFirstName: recipient.firstName,
    assignedToLastName: recipient.lastName,
  }));
};

export const getExternalRecipientsForAvatars = taskData => {
  const data = {
    ...taskData,
    assignedTo: [taskData.assignedTo],
    cc: taskData.cc ?? taskData?.ccValues ?? [],
  };
  return data?.assignedTo?.concat(data?.cc?.map(c => ({ ...c, isCC: 'Y' }))).map(recipient => ({
    ...recipient,
    statusId: 5,
    assignedToFirstName: recipient.firstName,
    assignedToLastName: recipient.lastName,
  }));
};

export const getCreatedByCoveredEmployee = (taskDetails: IInternalTaskModalDetails) => {
  const tempUser = taskDetails?.taskCoverageActions?.find(i => i.actionType === TaskCoverageActionType.CREATE_TASK);
  if (tempUser) {
    return {
      id: tempUser.coveredByEmployeeId,
      firstName: tempUser.coveredByEmployeeFirstName,
      lastName: tempUser.coveredByEmployeeLastName,
    } as IUser;
  } else return null;
};

export const getTaskAssigneeCoveredEmployee = (details: IInternalTaskModalDetails) => {
  const tempAssigneeUsers = details?.taskCoverageActions?.filter(
    i => i.actionType === TaskCoverageActionType.UPDATE_TASK_STATUS,
  );

  const formattedCoverageAssigneeDetails = tempAssigneeUsers?.map(user => {
    const recipients = [...details?.assignedTo, ...details?.cc];
    const userDetails = recipients?.find(userData => userData?.id === user?.coveredForEmployeeId);
    return {
      id: user.coveredByEmployeeId,
      statusId: userDetails?.statusId,
      firstName: user.coveredByEmployeeFirstName,
      lastName: user.coveredByEmployeeLastName,
      coveredForUserId: user.coveredForEmployeeId,
      actionDate: FormatDate(user.actionDate, 'L hh:mm A'),
      createdByError: details?.taskStatusDetails?.some(
        data =>
          data?.recipientId === user.coveredForEmployeeId && data?.statusUpdateReasonId === TaskStatusReason.ERROR,
      ),
    };
  });
  return formattedCoverageAssigneeDetails;
};

export const getCommentCoveredByEmployee = (commentDetails: ITaskComments) => {
  const tempUser =
    commentDetails?.taskCoverageAction?.length > 0
      ? commentDetails?.taskCoverageAction?.find(i => i.actionType === TaskCoverageActionType.CREATE_TASK)
      : null;
  if (tempUser) {
    return {
      id: tempUser.coveredByEmployeeId,
      firstName: tempUser.coveredByEmployeeFirstName,
      lastName: tempUser.coveredByEmployeeLastName,
    } as IUser;
  } else return null;
};

export const getCommentAssigneeCoveredEmployee = (details: ITaskComments) => {
  const tempAssigneeUsers = details?.taskCoverageAction?.filter(
    i => i.actionType === TaskCoverageActionType.UPDATE_TASK_STATUS,
  );
  const formattedCoverageAssigneeDetails = tempAssigneeUsers?.map(user => {
    const recipients = [...details?.tos, ...details?.ccs];
    const userDetails = recipients?.find(userData => userData?.id === user?.coveredForEmployeeId);
    return {
      id: user.coveredByEmployeeId,
      statusId: userDetails?.statusId,
      firstName: user.coveredByEmployeeFirstName,
      lastName: user.coveredByEmployeeLastName,
      coveredForUserId: user.coveredForEmployeeId,
      actionDate: FormatDate(user.actionDate, 'L hh:mm A'),
      createdByError: details?.taskStatusDetails?.some(
        data =>
          data?.recipientId === user.coveredForEmployeeId && data?.statusUpdateReasonId === TaskStatusReason.ERROR,
      ),
    };
  });
  return formattedCoverageAssigneeDetails;
};

export const formatCoveredByUsers = (coveredByUsers: any[]) => {
  return coveredByUsers?.map(
    coveredBy =>
      ({
        id: coveredBy?.coveredByEmployeeId,
        firstName: coveredBy?.coveredByEmployeeFirstName,
        lastName: coveredBy?.coveredByEmployeeLastName,
        statusId: coveredBy?.statusId ?? null,
      } as IUser),
  );
};

export const checkValidUser = (
  details: any,
  isSubtask: boolean,
  coverageDetails: ICoverageDetails[],
  currentUserId: number,
) => {
  const taskRecipients = isSubtask ? [...details?.tos, ...details?.ccs] : [...details?.assignedTo, ...details?.cc];
  taskRecipients?.push(details?.createdBy);
  return taskRecipients?.some(
    obj1 => coverageDetails?.some(obj2 => obj2.coveredEmployeeId === obj1.id) || obj1.id === currentUserId,
  );
};

export const checkUserEditStatus = (
  details: any,
  isSubtask: boolean,
  coverageDetails: ICoverageDetails[],
  currentUserId: number,
) => {
  const isCreatedByEditable = coverageDetails?.some(obj1 => obj1.coveredEmployeeId === details?.createdBy?.id);
  if (isCreatedByEditable) {
    return true;
  } else {
    const validUser = checkValidUser(details, isSubtask, coverageDetails, currentUserId);
    if (validUser) {
      const taskRecipients = isSubtask ? [...details?.tos, ...details?.ccs] : [...details?.assignedTo, ...details?.cc];
      const checkValidUserStatus = taskRecipients?.filter(obj1 => {
        const validRecipient = coverageDetails?.find(
          obj2 =>
            obj1?.id === obj2.coveredEmployeeId &&
            obj1?.statusId !== TaskStatus.Completed &&
            obj1?.statusId !== TaskStatus.Cancelled,
        );
        return validRecipient;
      });
      return checkValidUserStatus?.length > 0;
    } else {
      return false;
    }
  }
};

export const checkUserStatus = (details, isSubtask, coverageDetails, requiredStatusId) => {
  const taskRecipients = isSubtask ? [...details?.tos, ...details?.ccs] : [...details?.assignedTo, ...details?.cc];
  return taskRecipients.some(
    obj1 => coverageDetails.some(obj2 => obj2.coveredEmployeeId === obj1.id) && obj1.statusId === requiredStatusId,
  );
};

export const checkRecipientsStatus = (details, isSubtask, activeUserId, requiredStatusId) => {
  const taskRecipients = isSubtask ? [...details?.tos, ...details?.ccs] : [...details?.assignedTo, ...details?.cc];
  return taskRecipients.some(obj1 => obj1?.id === activeUserId && obj1?.statusId === requiredStatusId);
};

export const checkUserEditInTaskTile = (details: any, coverageDetails: ICoverageDetails[], currentUserId: number) => {
  const isCreatedByEditable =
    coverageDetails?.some(obj1 => obj1.coveredEmployeeId === details?.createdBy?.id) ||
    details?.createdBy?.id === currentUserId;
  if (isCreatedByEditable) {
    return true;
  } else {
    const taskRecipients = [...details?.assignedTo, ...details?.ccValues];
    const validUser = taskRecipients?.some(
      obj1 => coverageDetails?.some(obj2 => obj2.coveredEmployeeId === obj1.id) || obj1.id === currentUserId,
    );
    if (validUser) {
      const checkValidUserStatus = taskRecipients?.filter(obj1 => {
        const validRecipient = coverageDetails?.find(
          obj2 =>
            (obj1?.id === obj2.coveredEmployeeId || obj1.id === currentUserId) &&
            obj1?.statusId !== TaskStatus.Completed &&
            obj1?.statusId !== TaskStatus.Cancelled,
        );
        return validRecipient;
      });
      return checkValidUserStatus?.length > 0;
    } else {
      return false;
    }
  }
};

export const getFormattedTaskComments = taskComments => {
  const comments = taskComments?.length > 0 ? taskComments?.slice().reverse() : [];
  return comments;
};

export const fetchTaskIdforStatusUpdate = (taskData, commentList, userId) => {
  const selectedTaskIds: number[] = [];
  const excludeTaskIds: number[] = [];
  if (taskData?.assignedTo?.find(assignee => assignee?.id === userId && assignee?.statusId === TaskStatus.New)) {
    selectedTaskIds.push(taskData?.taskId);
  } else if (taskData?.cc?.find(cc => cc?.id === userId && cc?.statusId === TaskStatus.New)) {
    selectedTaskIds.push(taskData?.taskId);
  } else {
    excludeTaskIds.push(taskData?.taskId);
  }
  if (commentList?.length > 0) {
    commentList?.forEach(comment => {
      if (comment?.tos?.find(assignee => assignee?.id === userId && assignee?.statusId === TaskStatus.New)) {
        selectedTaskIds.push(comment?.taskId);
      } else if (comment?.ccs?.find(cc => cc?.id === userId && cc?.statusId === TaskStatus.New)) {
        selectedTaskIds.push(comment?.taskId);
      } else {
        excludeTaskIds.push(comment?.taskId);
      }
    });
  }
  const uniqueTaskIds = new Set(selectedTaskIds);
  const uniqueExcludeIds = new Set(excludeTaskIds);
  const excludedTaskIds = Array.from(uniqueExcludeIds);
  const selectedTaskIdsforUpdate = Array.from(uniqueTaskIds);
  return { excludedTaskIds, selectedTaskIdsforUpdate };
};

export const checkOboMarkedError = (userDetails, taskDetails) => {
  if (userDetails?.statusUpdatedById === taskDetails?.createdBy?.id) {
    return null;
  } else {
    const createdByOboRecord = taskDetails?.taskCoverageActions?.find(
      data => data?.coveredForEmployeeId === taskDetails?.createdBy?.id,
    );
    if (createdByOboRecord) {
      return createdByOboRecord;
    } else {
      return null;
    }
  }
};
