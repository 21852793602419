import { Grid, Table, Typography } from 'amn-ui-core';
import { ErrorAction } from 'app/components/Common/ErrorPage/ErrorAction';
import { missingField } from 'app/constants';
import { requirementType } from 'app/models/Placement/PlacementSummary';
import React from 'react';
import { GetMomentOfDate } from 'utils/dates/moment';
import { Loading, TableCell, ColumnHeader } from '../../../CommonTableComponents/CommonTableComponents';
import { getRequirementCheck } from '../../../helper';

export const ConfirmationsTable = ({
  cellTwoTitle = 'Last Updated By',
  cellThreeTitle = 'Last Updated Date',
  id,
  ...props
}) => {
  return (
    <Table id={id}>
      <thead style={{ width: '100%' }}>
        <TableCell />
        <TableCell>
          <ColumnHeader>{cellTwoTitle}</ColumnHeader>
        </TableCell>
        <TableCell>
          <ColumnHeader>{cellThreeTitle}</ColumnHeader>
        </TableCell>
      </thead>
      <tbody>{props.children}</tbody>
    </Table>
  );
};

interface ConfirmationsRowProps {
  id: string;
  controlComponent?: JSX.Element;
  lastUpdatedBy?: string;
  lastUpdatedDate?: string;
  loading?: boolean;
  hasError?: boolean;
  onTryAgain?: Function;
  requirement?: requirementType;
  requiredDependendy?: boolean;
  // there are other types in the table, in order to maintain proper spacing. Default to true
  hasOtherRequirements?: boolean;
  [key: string]: any;
}
export const ConfirmationsRow = ({
  id,
  controlComponent,
  lastUpdatedBy,
  lastUpdatedDate,
  loading,
  transparentTop = false,
  transparentBottom = false,
  hasError = false,
  onTryAgain,
  requirement,
  hasOtherRequirements,
  ...props
}: ConfirmationsRowProps) => {
  const cellStyle = {
    borderTop: transparentTop ? 'transparent' : undefined,
    borderBottom: transparentBottom ? 'transparent' : undefined,
    paddingTop: transparentTop ? 0 : undefined,
    paddingBottom: transparentBottom ? 0 : undefined,
  };

  const lastUpdatedMoment = GetMomentOfDate(lastUpdatedDate || '');
  return (
    <tr id={id} {...props}>
      {hasError && !loading ? (
        <TableCell colSpan="3">
          <ErrorAction onTryAgain={onTryAgain} />
        </TableCell>
      ) : (
        <>
          <TableCell style={{ width: '33%', ...cellStyle }}>
            {loading ? (
              <Loading />
            ) : (
              <Grid container spacing={2} wrap="nowrap" alignItems="center">
                {hasOtherRequirements && (
                  <Grid style={{ width: '32px', minWidth: '32px' }} item>
                    {getRequirementCheck(requirement)}
                  </Grid>
                )}
                <Grid item>{controlComponent}</Grid>
              </Grid>
            )}
          </TableCell>
          <TableCell style={{ width: '33%', ...cellStyle }}>
            {loading ? <Loading /> : <Typography variant="body1">{lastUpdatedBy || missingField}</Typography>}
          </TableCell>
          <TableCell style={{ width: '33%', ...cellStyle }}>
            {loading ? (
              <Loading />
            ) : (
              <Typography variant="body1">
                {lastUpdatedMoment.isValid() ? lastUpdatedMoment.format('MM/DD/YYYY h:mm:ss A') : missingField}
              </Typography>
            )}
          </TableCell>
        </>
      )}
    </tr>
  );
};
