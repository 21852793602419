import React from 'react';
import { Divider, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { Row, QuestionRowContent, RowHead, TextContent } from './styles';
import { candidateDetailsSelection } from '../../../Profile/CandidateDetails.selector';
import { useSelector } from 'react-redux';
import {  StyledEngineProvider } from 'amn-ui-core';
import { missingField } from 'app/constants';
import { YesNoValues, convertInvestigationAndRightToWork } from '../Form/PersonalInfoHelper';
import { RowContainer } from '@AMIEWEB/Candidate/Common/RowContainer';

export const APPLICATIONQUESTIONCONSTANT = {
  PASS: 'Pass',
  FAIL: 'Fail',
};
interface IApplicationQuestionRowProps {
  header: string;
  data: string;
  status: string;
  explainationHeader: string;
  explaination: string;
}

const useStyles = makeStyles()(() => ({
  divider: {
    width: '100%',
    margin: '12px 0px 12px 0px',
  },
  row: {
    marginBottom: '6px',
  },
}));

export const ApplicationQuestionsCard = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const investigationAns = convertInvestigationAndRightToWork(
    candidateDetails.investigationSuspension,
    candidateDetails.investigationSuspensionExplanation,
  );
  const rightToWorkAns = convertInvestigationAndRightToWork(
    candidateDetails.rightToWorkVerification,
    candidateDetails.visaExplaination,
  );
  const questions = [
    {
      question: t('candidate.personalInfoTabLabels.investigationQn'),
      answer: investigationAns,
      ...(investigationAns === YesNoValues.YES
        ? { status: APPLICATIONQUESTIONCONSTANT.FAIL }
        : { status: APPLICATIONQUESTIONCONSTANT.PASS }),
      explainationHeader: t('candidate.personalInfoTabLabels.explanation'),
      explaination: candidateDetails.investigationSuspensionExplanation ?? '--',
    },
    {
      question: t('candidate.personalInfoTabLabels.workPermitQn'),
      answer: rightToWorkAns,
      ...(rightToWorkAns === YesNoValues.YES
        ? { status: APPLICATIONQUESTIONCONSTANT.PASS }
        : { status: APPLICATIONQUESTIONCONSTANT.FAIL }),
      explainationHeader: t('candidate.personalInfoTabLabels.visaType'),
      explaination: candidateDetails.visaExplaination ?? '--',
    },
  ];

  return (
    <>
      {questions.map((qn, index) => (
        <Grid key={index.toString()} container direction="column" justifyContent="space-between" spacing={2}>
          <Grid item>
            <RowSection
              header={qn.question}
              data={qn.answer}
              status={qn.status}
              explainationHeader={qn.explainationHeader}
              explaination={qn.explaination}
            />
            {questions.length !== index + 1 && <Divider className={classes.divider}></Divider>}
          </Grid>
        </Grid>
      ))}
    </>
  );
};

const RowSection: React.FC<IApplicationQuestionRowProps> = props => {
  const { classes } = useStyles();
  return (
    <StyledEngineProvider injectFirst>
      <RowContainer>
          <Row className={classes.row}>
            <RowHead>{props.header}</RowHead>
          </Row>
          {props.data !== missingField ? (
            <>
              <Row className={classes.row}>
                <QuestionRowContent status={props.status}>{props.data}</QuestionRowContent>
              </Row>
              <Row className={classes.row}>
                <RowHead>{props.explainationHeader}</RowHead>
              </Row>
              <Row>
                <TextContent>{props.explaination}</TextContent>
              </Row>
            </>
          ) : (
            <Row>
              <TextContent>{missingField}</TextContent>
            </Row>
          )}
        </RowContainer>
    </StyledEngineProvider>
  );
};
