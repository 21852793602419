import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';
import { TaskTooltip } from 'app/components/Common/Tooltips/TaskTooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()({
  recipientLabel: {
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Roboto',
    letterSpacing: '0px',
    color: '#333333',
    opacity: '1',
  },
  cueToLabel: {
    margin: '10px 5px 10px 12px',
  },
  recipientNameLabel: {
    margin: '10px 10px 10px 0px',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  headerContainer: {
    display: 'inline-flex',
    width: 'inherit',
    whiteSpace: 'nowrap',
    alignItems: 'center',
  },
});

export const LastRecipientTab = ({
  lastRecipient,
}: {
  lastRecipient;
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {lastRecipient && (
        <Grid container xs={12}>
          <div className={classes.headerContainer}>
            <div className={`${classes.recipientLabel} ${classes.cueToLabel}`}>
              {t('notification.taskSidePanel.cueNotes.cuedTo')}
            </div>
            {lastRecipient && (
              <div
                className={`${classes.recipientLabel} ${classes.recipientNameLabel}`}
                style={{ margin: '10px 10px 10px 0px !important' }}
              >
                <TaskTooltip
                  placement={'top'}
                  tooltipContent={lastRecipient?.firstName + ' ' + lastRecipient?.lastName}
                >
                  <span>{lastRecipient?.firstName + ' ' + lastRecipient?.lastName}</span>
                </TaskTooltip>
              </div>
            )}
          </div>
        </Grid>
      )}
    </React.Fragment>
  );
};
