import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { FlatRateItem } from './FlatBillRate';
import { BillRateShift } from './BillRateShift';
import { useSelector } from 'react-redux';
import { selectCreateOrderAttributes } from 'store/redux-store/create-order-ocbr/selector';

const useStyles = makeStyles()(theme => ({
  container: {
    overflowY: 'hidden',
    overflowX: 'auto',
    display: 'block',
    marginLeft: '14em',
    paddingBottom: '5px',
  },
  title: {
    width: '15%',
    fontSize: '14px',
    textAlign: 'start',
    minWidth: 150,
    maxWidth: '14em',
    wordBreak: 'break-word',
    position: 'absolute',
    left: 0,
    paddingTop: '21px',
    fontWeight: 500,
  },
  title2: {
    width: '15%',
    fontSize: '14px',
    textAlign: 'start',
    minWidth: 150,
    maxWidth: '14em',
    wordBreak: 'break-word',
    position: 'absolute',
    left: 0,
    paddingTop: '56px',
    fontWeight: 500,
  },
  divider: {
    borderRight: '1px solid #e4d9d9',
    height: '110%',
  },
}));

export const TableBillRate = ({ billRatesArray, isFlatRateToggle, shiftsArray = [] }) => {
  const { classes } = useStyles();
  const { showLocalBillRate } = useSelector(selectCreateOrderAttributes);

  const displayFlatRate = React.useMemo(() => !shiftsArray.length || isFlatRateToggle, [isFlatRateToggle, shiftsArray]);

  return (
    <table className={classes.container}>
      {!displayFlatRate ? (
        <thead>
          <tr>
            <th />
            {shiftsArray.map((shift, indx) => (
              <th
                key={indx.toString()}
                className={indx < shiftsArray.length - 1 ? classes.divider : undefined}
                style={{ fontWeight: 500 }}
              >{`${shift} Hour Shift`}</th>
            ))}
          </tr>
        </thead>
      ) : (
        <tr />
      )}
      <tbody>
        {billRatesArray.map((item, index) => {
          return (
            <tr key={index.toString()}>
              <th className={!showLocalBillRate ? classes.title : classes.title2}>{item.skillSet}</th>
              {displayFlatRate ? (
                <td>
                  <FlatRateItem skillsetShiftInfo={item} />
                </td>
              ) : (
                item.shiftDetails.map((shiftItem, indx) => (
                  <td key={indx.toString()} className={indx < shiftsArray.length - 1 ? classes.divider : undefined}>
                    <BillRateShift shiftName={shiftItem.shiftName} shifDetails={shiftItem} skillsetShiftInfo={item} />
                  </td>
                ))
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
