import { missingField } from 'app/constants';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RequirementsRow } from '../../TableComponents/TableComponents';
import { usePromiseTracker } from 'react-promise-tracker';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import { TableSection } from '../../../customComponents/TableSection';
import { getRequirementCheck } from '../../../helper';
import { Grid, MenuItem, MenuList, Typography } from 'amn-ui-core';
import { ff_placement_ui_requirement_check } from 'app/constants/FeatureFlags/Placement/Keys';
import { useDecision } from '@optimizely/react-sdk';
import {
  selectInterviewInfo,
  selectServiceFailures,
  selectSubmissionInfo,
} from 'store/redux-store/placement-summary/selectors';
import { makeStyles } from 'tss-react/mui';
import { VirtualInterviewHistory } from '../../InterviewStatus/VirtualInterview/VirtualInterviewHistory';
import { IVICardView } from '../../InterviewStatus/VirtualInterview/InterviewSpecs';
import { StatusChip } from '../../CustomComponents/StatusChip';
import { RowContainer } from '@AMIEWEB/Candidate/Common/RowContainer';
import { CustomTooltip } from '@AMIEWEB/Common';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { theme } from 'styles/global-styles';

const useStyles = makeStyles()({
  title: {
    fontWeight: 500,
  },
  header: {
    fontSize: '12px',
    fontWeight: 500,
    paddingRight: '5px',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  titleCell: {
    textAlign: 'start',
    fontWeight: 500,
  },
  actionColumn: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    height: 25,
    gap: 12,
  },
  headCell: {
    fontSize: 12,
    fontWeight: 450,
  },
  cardHeadCell: {
    minWidth: 100,
  },
  valueCell: {
    fontSize: 14,
  },
  tooltip: {
    padding: '12px 0',
  },
  iconColor: {
    color: theme.palette.system.black,
  },
});

const defaultHistoryProps = {
  rows: [],
  open: false,
  inviteId: undefined,
  interviewDate: undefined,
};

export const VirtualInterview = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [requirementCheckFlag] = useDecision(ff_placement_ui_requirement_check);
  const {
    virtualInterview: {
      order: {
        type,
        data,
        view: { rating, vaAutoOffer, skillset, matchingSkillset },
      },
      candidate: interviews,
    },
  } = useSelector(selectInterviewInfo);
  const { requirementFilter } = useSelector(selectSubmissionInfo);
  const [historyModalProps, setHistoryModalProps] = useState(defaultHistoryProps);

  const { promiseInProgress: promiseInProgressVirtualInterview } = usePromiseTracker({
    area: 'placement-interview-info-vi-requirement',
    delay: 0,
  });
  const { promiseInProgress } = usePromiseTracker({
    area: 'pplacement-submission-mice-details-order-requirement',
    delay: 0,
  });
  const { virtualInterviewRequirement: serviceFailed } = useSelector(selectServiceFailures);
  const { placementId } = useContext(AddOnContext);
  const passFailType = requirementCheckFlag.enabled && data?.virtualRatingSubmissionRequirement ? type : null;
  const { display } = useRequirementFilter('submission-order', requirementFilter, passFailType);

  return display ? (
    <RequirementsRow
      id="placement-submission-order-virtual-interview"
      title={t('placement.profile.summary.interview.virtualInterviewContainer.title')}
      requirementCell={
        requirementCheckFlag.enabled && data?.virtualRatingSubmissionRequirement ? (
          <RowKey
            data={data}
            virtualInterviewRating={rating}
            virtualInterviewAutoOffer={vaAutoOffer}
            virtualInterviewSkillset={skillset}
            virtualInterviewMatchingSkillset={matchingSkillset}
            virtualInterviewType={type}
            classes={classes}
            t={t}
          />
        ) : (
          <Typography variant="body1">{missingField}</Typography>
        )
      }
      loading={requirementCheckFlag.enabled ? promiseInProgressVirtualInterview : promiseInProgress}
      hasError={serviceFailed}
      candidateCell={
        <RowValue
          interviews={interviews}
          historyModalProps={historyModalProps}
          setHistoryModalProps={setHistoryModalProps}
          t={t}
        />
      }
      onTryAgain={() =>
        requirementCheckFlag.enabled
          ? dispatch(placementSummaryActions.getVirtualInterviewRequirement({ placementId, isRetryingService: true }))
          : dispatch(placementSummaryActions.getMiscDetailsOrderRequirement({ placementId, isRetryingService: true }))
      }
    />
  ) : null;
};

const RowKey = ({
  data,
  virtualInterviewRating,
  virtualInterviewAutoOffer,
  virtualInterviewSkillset,
  virtualInterviewMatchingSkillset,
  virtualInterviewType,
  classes,
  t,
}) => {
  const items = React.useMemo(() => {
    const virtualInterviewItems = [
      {
        primaryContent:
          data && Object.keys(data).length > 0 ? (
            <Grid direction="column" spacing={4}>
              <Grid item className={classes.title}>
                {t('placement.profile.summary.interview.virtualInterviewContainer.title')}
              </Grid>
              <Grid item>
                <span className={classes.header}>
                  {t('placement.profile.summary.interview.virtualInterviewContainer.orderSpecs.rating')}
                </span>
                <span>{virtualInterviewRating}</span>
              </Grid>
              <Grid item>
                <span className={classes.header}>
                  {t('placement.profile.summary.interview.virtualInterviewContainer.orderSpecs.vaAutoOffer')}
                </span>
                <span>{virtualInterviewAutoOffer}</span>
              </Grid>
              <Grid item>
                <span className={classes.header}>
                  {t('placement.profile.summary.interview.virtualInterviewContainer.orderSpecs.skillset')}
                </span>
                <span>{virtualInterviewSkillset}</span>
              </Grid>
              <Grid item>
                <span className={classes.header}>
                  {t('placement.profile.summary.interview.virtualInterviewContainer.orderSpecs.matchingSkillset')}
                </span>
                <span>{virtualInterviewMatchingSkillset}</span>
              </Grid>
            </Grid>
          ) : (
            missingField
          ),
        icon: virtualInterviewType ? getRequirementCheck(virtualInterviewType) : null,
      },
    ] as {
      icon?: any;
      primaryContent?: any;
      secondaryContent?: any;
    }[];
    return virtualInterviewItems;
  }, [
    virtualInterviewRating,
    virtualInterviewAutoOffer,
    virtualInterviewSkillset,
    virtualInterviewMatchingSkillset,
    virtualInterviewType,
    classes,
    t,
  ]);
  return <TableSection items={items} />;
};

const ScheduleItem: React.FC<IVICardView & { setHistoryModalProps: Function }> = ({
  skillset: title,
  rating,
  status,
  inviteId,
  reason,
  interviewDateValue,
  sentDateValue,
  lastUpdatedValue,
  history,
  setHistoryModalProps,
  chipStyle,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { tooltipContent } = React.useMemo(() => {
    return {
      tooltipContent: (
        <RowContainer>
          <tr>
            <td className={classes.headCell}>
              {t('placement.profile.summary.interview.virtualInterviewContainer.interviewSpecs.reason')}
            </td>
            <td className={classes.valueCell}>{reason}</td>
          </tr>
          <tr>
            <td className={classes.headCell}>
              {t('placement.profile.summary.interview.virtualInterviewContainer.interviewSpecs.interviewDate')}
            </td>
            <td className={classes.valueCell}>{interviewDateValue}</td>
          </tr>
          <tr>
            <td className={classes.headCell}>
              {t('placement.profile.summary.interview.virtualInterviewContainer.interviewSpecs.sentDate')}
            </td>
            <td className={classes.valueCell}>{sentDateValue}</td>
          </tr>
          <tr>
            <td className={classes.headCell}>
              {t('placement.profile.summary.interview.virtualInterviewContainer.interviewSpecs.inviteId')}
            </td>
            <td className={classes.valueCell}>{inviteId}</td>
          </tr>
        </RowContainer>
      ),
    };
  }, [reason, interviewDateValue, sentDateValue, lastUpdatedValue]);

  return (
    <div className={classes.container}>
      <CustomTooltip isStandardContent standardMargin tooltipContent={tooltipContent} placement="bottom">
        <table>
          <tbody>
            <tr>
              <td colSpan={2} className={classes.titleCell}>
                {title}
              </td>
            </tr>
            <tr>
              <td>{status === missingField ? missingField : <StatusChip status={status} chipStyle={chipStyle} />}</td>
            </tr>
            <tr>
              <td className={`${classes.headCell} ${classes.cardHeadCell}`}>
                {t('placement.profile.summary.interview.virtualInterviewContainer.interviewSpecs.lastUpdated')}
              </td>
              <td className={classes.valueCell} style={{ whiteSpace: 'nowrap' }}>{lastUpdatedValue}</td>
            </tr>
            <tr>
              <td className={`${classes.headCell} ${classes.cardHeadCell}`}>
                {t('placement.profile.summary.interview.virtualInterviewContainer.interviewSpecs.rating')}
              </td>
              <td className={classes.valueCell} style={{ whiteSpace: 'nowrap' }}>{rating}</td>
            </tr>
          </tbody>
        </table>
      </CustomTooltip>
      <div className={classes.actionColumn}>
        <CustomTooltip
          standardMargin
          classes={{ tooltip: classes.tooltip }}
          contentStyle={{ alignSelf: 'center' }}
          tooltipContent={
            <TooltipContent
              onClick={() =>
                setHistoryModalProps({ open: true, rows: history, inviteId, interviewDate: interviewDateValue })
              }
            >
              {t('placement.profile.summary.interview.virtualInterviewContainer.interviewSpecs.history.viewHistory')}
            </TooltipContent>
          }
          placement={'bottom'}
        >
          <MoreVertIcon className={classes.iconColor} />
        </CustomTooltip>
      </div>
    </div >
  );
};

const TooltipContent = ({ onClick, ...props }) => {
  return (
    <MenuList disablePadding>
      <MenuItem dense onClick={onClick}>
        {props.children}
      </MenuItem>
    </MenuList>
  );
};

const RowValue = ({ interviews, historyModalProps, setHistoryModalProps, t }) => {
  const items = React.useMemo(() => {
    return [
      {
        primaryContent: !interviews.length ? (
          <Typography variant="body1">
            {t('placement.profile.summary.interview.virtualInterviewContainer.interviewSpecs.skillNotMatching')}
          </Typography>
        ) : (
          <Grid container spacing={2}>
            {interviews.map(({ cardView }, index) => (
              <Grid item key={index.toString()} xs={12}>
                <ScheduleItem {...cardView} setHistoryModalProps={setHistoryModalProps} />
              </Grid>
            ))}
            <VirtualInterviewHistory {...historyModalProps} onClose={() => setHistoryModalProps(defaultHistoryProps)} />
          </Grid>
        ),
      },
    ];
  }, [interviews, historyModalProps, setHistoryModalProps, t]);
  return <TableSection items={items} />;
};
