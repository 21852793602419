import { Grid } from 'amn-ui-core';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { ActionModal, GenerateHeader } from '../customComponents';
import { useTranslation } from 'react-i18next';
import { SearchField } from '@AMIEWEB/Common/Search/SearchField';
import TemplateNotFound from 'app/assets/images/Template/TemplateNotFound.svg';
import TemplateSearchNotFound from 'app/assets/images/Template/TemplateSearchNotFound.svg';
import { IContractDoc } from 'store/redux-store/placement-candidate-contract-doc-editor/types';
import { useDispatch, useSelector } from 'react-redux';
import { selectDocStatusExists } from 'store/redux-store/placement-candidate-contract-doc-editor/selector';
import { pContractDocEditorActions } from 'store/redux-store/placement-candidate-contract-doc-editor/slice';
import { useParams } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';
import { ContractDocCard } from './ContractDocCard';

const useStyles = makeStyles()(theme => ({
  textField: {
    height: 40,
    width: '100%',
  },
  content: {
    height: 'fit-content',
    width: '100%',
    backgroundColor: theme.palette.components.grid.content.backgroundColor,
    alignSelf: 'center',
  },
  templatesContainer: {
    height: '800px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: 'calc(100vh - 208px)',
    flexWrap: 'nowrap',
  },
  searchBarContainer: {
    position: 'sticky',
    top: '0px',
    zIndex: 2,
    backgroundColor: theme.palette.system.grayChip,
  },
  messageBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'inherit',
    paddingLeft: '20px',
  },
  message: {
    fontSize: '14px',
    color: theme.palette.system.darkGray,
    fontStyle: 'italic',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    ' span': {
      padding: '1rem 2rem 1rem 2rem',
    },
  },
}));

export const EngineTemplates: React.FC<{
  templates: IContractDoc[];
  selectedTemplate: IContractDoc | null;
  onTemplateClick: (item: IContractDoc) => void;
}> = ({ templates, selectedTemplate, onTemplateClick }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { placementId } = useParams<{ placementId: string }>();

  const [searchQuery, setSearchQuery] = React.useState('');
  // @ts-ignore
  const [modalProps, setModalProps] = React.useState<IActionModalProps>({ open: false });
  // @ts-ignore
  const closeModal = React.useCallback(() => setModalProps({ open: false }), []);
  const { amieWebDraftExists, classicDraftExists } = useSelector(selectDocStatusExists);

  const dispatch = useDispatch();

  const { promiseInProgress: isGenerating } = usePromiseTracker({
    area: 'placement-candidate-contract-templates-generate-draft',
  });

  const generateContract = React.useCallback(() => {
    dispatch(pContractDocEditorActions.generateDraft({ placementId: parseInt(placementId) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placementId]);

  const handleGenerateContract = React.useCallback(() => {
    if (amieWebDraftExists) {
      setModalProps({
        open: true,
        title: classicDraftExists
          ? t('templateEditor.modal.title.overrideAmieWebAndClassicDraft')
          : t('templateEditor.modal.title.overrideAmieWebDraft'),
        message: classicDraftExists
          ? t('templateEditor.modal.message.overrideAmieWebAndClassicDraft')
          : t('templateEditor.modal.message.overrideAmieWebDraft'),
        primaryBtns: [
          {
            text: t('cancelModal.confirmation.yes'),
            onClick: () => {
              generateContract();
              closeModal();
            },
          },
        ],
        secondaryBtns: [
          {
            text: t('cancelModal.confirmation.cancel'),
            onClick: closeModal,
          },
        ],
      });
      return;
    }
    if (classicDraftExists) {
      setModalProps({
        open: true,
        title: t('templateEditor.modal.title.overrideClassicDraft'),
        message: t('templateEditor.modal.message.overrideClassicDraft'),
        primaryBtns: [
          {
            text: t('cancelModal.confirmation.yes'),
            onClick: () => {
              generateContract();
              closeModal();
            },
          },
        ],
        secondaryBtns: [
          {
            text: t('cancelModal.confirmation.cancel'),
            onClick: closeModal,
          },
        ],
      });
      return;
    }
    generateContract();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amieWebDraftExists, generateContract, classicDraftExists]);

  const filterTemplates = React.useCallback(
    (list: IContractDoc[]) =>
      list.filter(x => (!!searchQuery ? x.version.toLowerCase().includes(searchQuery.toLowerCase()) : x)),
    [searchQuery],
  );

  const { hasSearchResults, _templateList, _draftList, _noTemplates, hasDraftDoc, hasOnlyDraftDoc } =
    React.useMemo(() => {
      const { draftList, templateList } = templates.reduce(
        (results, item) => {
          if (item.status === 'Draft') results.draftList.push(item);
          else results.templateList.push(item);
          return results;
        },
        { draftList: [], templateList: [] },
      );
      const filteredTemplateList = filterTemplates(templateList);
      const filteredDraftList = filterTemplates(draftList);
      // TODO: Simplify filtering and message determination
      return {
        hasSearchResults: filteredTemplateList.length > 0 || filteredDraftList.length > 0,
        _templateList: filteredTemplateList,
        _draftList: filteredDraftList,
        _noTemplates: templateList.length === 0,
        hasDraftDoc: draftList.length > 0,
        hasOnlyDraftDoc: draftList.length > 0 && templateList.length === 0,
      };
    }, [filterTemplates, templates]);

  const noTemplateMessage = React.useMemo(() => {
    if (hasOnlyDraftDoc)
      return (
        <>
          <img src={TemplateNotFound} alt="No templates available" />
          <span>{t('templateEditor.templateName.search.noContractTemplateCreated')}</span>
        </>
      );
    if (_noTemplates)
      return (
        <>
          <img src={TemplateNotFound} alt="No templates available" />
          <span>{t('templateEditor.templateName.search.noContractTemplateAvailable')}</span>
        </>
      );
    if (!hasSearchResults) {
      return (
        <>
          <img src={TemplateSearchNotFound} alt="No results found" />
          <span>{t('templateEditor.templateName.search.noResults')}</span>
        </>
      );
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasOnlyDraftDoc, hasSearchResults, _noTemplates]);

  return (
    <Grid container className={classes.templatesContainer} direction="column" spacing={2}>
      <Grid className={classes.searchBarContainer} container item>
        <Grid item classes={{ root: classes.content }}>
          <SearchField
            autoFocus
            inheritWidth
            name={'search-template-tokens'}
            placeholder={t('appHeader.searchPlaceHolder')}
            adornAtStart
            onChange={e => setSearchQuery(e.target.value)}
            searchFieldClasses={{ textField: classes.textField }}
          />
        </Grid>
      </Grid>

      {hasDraftDoc ? (
        <>
          <Grid item xs={12} sx={{ maxHeight: 30 }}>
            <GenerateHeader generate onClick={handleGenerateContract} title={t('Draft')} isGenerating={isGenerating} />
          </Grid>
          <TemplateList
            templateList={_draftList}
            selectedTemplate={selectedTemplate}
            onTemplateClick={onTemplateClick}
          />
        </>
      ) : null}
      <Grid item xs={12} sx={{ maxHeight: 30 }}>
        <GenerateHeader
          generate={!hasDraftDoc}
          onClick={handleGenerateContract}
          title={t('Documents')}
          isGenerating={isGenerating}
        />
      </Grid>
      {!!noTemplateMessage ? (
        <Grid item className={classes.messageBox}>
          <div className={classes.message}>{noTemplateMessage}</div>
        </Grid>
      ) : (
        <TemplateList
          templateList={_templateList}
          selectedTemplate={selectedTemplate}
          onTemplateClick={onTemplateClick}
        />
      )}
      <ActionModal {...modalProps} />
    </Grid>
  );
};

export const TemplateList = ({ templateList, selectedTemplate, onTemplateClick }) =>
  templateList.map(template => {
    const selected = selectedTemplate?.documentId === template.documentId;
    return (
      <Grid key={template.documentId} item style={{ position: 'relative', zIndex: 1 }}>
        <ContractDocCard
          card={template}
          isSelected={selected}
          onClick={() => onTemplateClick(selected ? null : template)}
        />
      </Grid>
    );
  });
