/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const useStyles = makeStyles()({
  root: {
    backgroundColor: '#FFF6EE',
    border: '1px solid #FF0000',
    width: '100%',
    padding: '12px 6px',
  },
  icon: {
    marginRight: 12,
    color: '#FF0000',
    fontSize: 24,
  },
  text: {
    width: '205px',
    height: '80px',
    textAlign: 'left',
    font: 'normal normal normal 14px / 20px Roboto',
    letterSpacing: '0px',
    color: '#611A16',
    opacity: '1',
  },
  button: {
    width: '96px',
    height: '40px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #C3C8D1',
    borderRadius: '4px',
    opacity: 1,
  },
});

export const EditTaskFormBanner = props => {
  const { message, onClick } = props;
  const { classes } = useStyles();
  const refresh = 'Refresh';

  return (
    <Grid container justifyContent="center" direction={'row'} alignContent="center" className={classes.root} wrap="nowrap">
      <ErrorOutlineIcon className={classes.icon} />
      <Grid item className={classes.text}>
        {message}
      </Grid>
      <button className={classes.button} onClick={onClick}>
        {refresh}
      </button>
    </Grid>
  );
};
