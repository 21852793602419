import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => state?.globalSearch || initialState;

export const selectSearchAttributes = createSelector([selectDomain], state => state.searchAttributes);

export const selectArchivePlacements = createSelector(
  [selectDomain],
  state =>
    state.searchAttributes.archivePlacement ?? {
      placementStatusesAllowed: [],
      dialogOpen: false,
      loading: false,
      success: undefined,
    },
);

export const selectSearchResults = createSelector([selectDomain], state => state.searchResults);

export const selectMiscellaneous = createSelector([selectDomain], results => results.searchResults.miscellaneous);

export const selectDrawerData = createSelector([selectDomain], state => state.searchResults.drawerData.data);

export const selectAdditioalData = createSelector([selectDomain], state => state.additionalData);

export const selectBulkVerifyOrdersFlag = createSelector([selectDomain], 
                                          state => state.searchAttributes.bulkOrders?.bulkVerifyOrdersFlag);
