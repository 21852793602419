import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import moment from 'moment';
import { useWatch } from 'react-hook-form';
import { useEditableRequirement } from './EditRequirementsHelper';
import { availableStartDateCheck } from './EditablePassFailLogic';
import React from 'react';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import { useSelector } from 'react-redux';

export const useEditableRequirementAvailableStartDate = () => {
  const availableStartDate: string | undefined = useWatch({ name: 'availabilityDate' });
  const availableMoment = moment(availableStartDate);
  const placementOrderType = useSelector(selectPlacementOrderType);
  const secondaryData = React.useMemo(() => ({ placementOrderType: placementOrderType }), [placementOrderType]);

  useEditableRequirement({
    data: availableStartDate,
    secondaryData: secondaryData,
    requirementId: IPlacementRequirementKey.availableStartDate,
    requirementKey: 'availabilityDate',
    requirementCheck: availableStartDateCheck,
    failRequirement: {
      id: IPlacementRequirementKey.availableStartDate,
      message: `Available Start Date: ${availableMoment.isValid() ? availableMoment.format('MM/DD/YYYY') : 'Missing'}`,
      tooltip: {
        message: `Candidate must have an Available Start Date greater than today's date.`,
      },
    },
    riskRequirement: {
      id: IPlacementRequirementKey.availableStartDate,
      message: `Available Start Date: ${availableMoment.isValid() ? availableMoment.format('MM/DD/YYYY') : 'Missing'}`,
      tooltip: {
        message: 'Available Start Date is within 14 days from today.',
      },
    },
  });
};
