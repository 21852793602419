export interface ITooltipField {
  expirationDate?: Date;
  manufacturer?: string | null;
  vaccinationDate?: Date;
}

export interface IFluTooltipField {
  type?: string | null;
  dateCompleted?: Date;
  expirationDate?: Date;
  tsceFormID?: string | null;
}

export const initialTooltipFields: ITooltipField = {
  expirationDate: undefined,
  manufacturer: undefined,
  vaccinationDate: undefined,
};

export const initialFluTooltipFields: IFluTooltipField = {
  type: undefined,
  dateCompleted: undefined,
  expirationDate: undefined,
  tsceFormID: undefined,
};

export enum FluVaccinationStatus {
  Vaccinated = 'Vaccinated',
  VaccinationExpired = 'Vaccination Expired',
  Unvaccinated = 'Unvaccinated',
  VaccinationExpiring = 'Vaccination Expiring',
}