/* eslint-disable @typescript-eslint/naming-convention */
import { createAction } from '@reduxjs/toolkit';
import { EditorType, IUserPreferenceState, IEditorPreference } from './userPreference.redux';

const business_saveConfirmationEditorPreference = createAction<{
  editor: EditorType;
  preference: IUserPreferenceState<IEditorPreference>;
}>('SAVE_CONFIRMATION_DOC_TEAMPLATE_EDITOR_PREFERENCES');

const business_saveEditorWidthPreference = createAction<{
  editor: EditorType;
  width: number;
}>('SAVE_EDITOR_WIDTH_PREFERENCES');

export const businessActions = { business_saveConfirmationEditorPreference, business_saveEditorWidthPreference };
