/* eslint-disable i18next/no-literal-string */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AttachmentAction } from '../AttachmentHoverView';
import { AttachmentPreviewContent } from './AttachmentPreviewContent';
import NotesIcon from '@mui/icons-material/Notes';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  AttachmentsSelection,
  isPreviewSelection,
  newAttachmentsSelection,
  selectViewSkillsChecklistPassport,
} from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { candidateDetailActions } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import _, { isEmpty } from 'lodash';
import { IAttachments } from 'app/models/Credentialing/Credentials';
import { getAttachments } from 'app/services/CredentialingServices';
import {
  findCredentialByRequirementID,
  onAddAttachment,
  onDeleteAttachment,
} from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/CredentialsTab/CredentialsHelpers';
import { candidateDetailsSelection } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { downloadFile, downloadZip, getFileNameFormat } from 'app/components/Common/DocViewer/Helpers/DocHelpers';
import { useTranslation } from 'react-i18next';
import { CustomBackdrop } from 'app/components/Common/Backdrop/CustomBackdrop';
import { LoadingGrid } from 'app/components/Common/Loader/LoadingGrid';
import { DocumentDropzoneWrapper } from '../DocumentDropzone/DocumentDropzoneWrapper';
import { selectUser } from 'oidc/user.selectors';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { isPositiveNumber } from 'app/helpers/componentHelpers';
import { excludeHTMLFile } from '@AMIEWEB/Credentialing/CredentialingAttachmentsViewer/AttachmentsViewer/ViewSkillCheckList/Config/constants';

const useStyles = makeStyles()({
  link: {
    color: '#006FB9',
    cursor: 'pointer',
    fontWeight: 500,
  },
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50vh',
  },
});

export enum AttachmentItemState {
  default,
  loading,
  success,
  error,
}

export const AttachmentPreviewDialog = ({
  data,
  onClose,
  clearChildMessages,
  setClearChildMessages,
  existingAttachmentRes,
  onSubmitAttachments,
}: {
  data: {
    open: boolean;
    action: AttachmentAction;
    requirementID: number;
    requirementTypeID: number;
    travelerId: number;
    brandId: number;
  };
  onClose: () => void;
  clearChildMessages: boolean;
  setClearChildMessages(value: boolean): void;
  existingAttachmentRes?: IAttachments[];
  onSubmitAttachments?: () => void;
}) => {
  const { classes } = useStyles();
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [addFormOpen, setAddFormOpen] = useState<boolean>(false);
  const [simpleDialogText, setSimpleDialogText] = useState<{ title: string; actionOne: string; actionTwo: string }>({
    title: 'View',
    actionOne: 'View All',
    actionTwo: 'View Selected',
  });

  const [previewDialogText, setPreviewDialogText] = useState<{ title: string }>({
    title: 'View Attachment(s)',
  });

  const [previewDownloadText, setPreviewDownloadText] = useState<{ title: string }>({
    title: 'Download Selected',
  });

  const [previewDeleteText, setPreviewDeleteText] = useState<{ title: string }>({
    title: 'Delete Selected',
  });

  const dispatch = useDispatch();
  const attachments = useSelector(AttachmentsSelection);
  const [selectedAttachments, setSelectedAttachments] = useState<IAttachments[]>([]);
  const [allCheckbox, setAllCheckbox] = useState(false);
  const [download, setDownload] = useState(false);
  const history = useHistory();
  const [credential, setCredential] = useState<any>(null);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const newAttachments = useSelector(newAttachmentsSelection);
  const isPreviewAdd = useSelector(isPreviewSelection);
  const [failedAttachments, setFailedAttachments] = useState<any[]>([]);
  const [disableAction, setDisableAction] = useState<boolean>(false);
  const user = useSelector(selectUser);

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isDeletingProcessActive, setIsDeletingProcessActive] = useState<boolean>(false);
  const { t } = useTranslation();
  const [isShowLoader, setIsShowLoader] = useState<boolean>(false);
  const [attachmentRes, setAttachmentRes] = useState<IAttachments[]>();
  const [hasDownloadButton, setHasDownloadButton] = useState<boolean>(false);
  const [hasDeleteButton, setHasDeleteButton] = useState<boolean>(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openDeleteSingleConfirmation, setOpenDeleteSingleConfirmation] = useState(false);
  const [inlineDel, setInlineDel] = useState(false);
  const [inlineAttachments, setInlineAttachments] = useState<IAttachments[]>([]);
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [isAttachmentSelected, setIsAttachmentSelected] = useState<boolean>(false);
  const [del, setDel] = useState<boolean>(false);
  const isSkillChecklistDocFromPassport = useRef<boolean>(false);
  const viewSkillsChecklistPassport = useSelector(selectViewSkillsChecklistPassport);

  useEffect(() => {
    if (clearChildMessages) {
      setClearChildMessages(false);
    }
  }, [clearChildMessages, setClearChildMessages]);

  const fetchData = async () => {
    if (isPositiveNumber(data?.travelerId) && isPositiveNumber(data?.requirementID)) {
      await getAttachments(data?.travelerId, data?.requirementID)
        .then(response => {
          let res = response as unknown as IAttachments[];
          if (res) {
            res = !isEmpty(viewSkillsChecklistPassport) ? mergeAttachmentsAndPassportData(res) : res;
            res = res
              ?.filter(attachment => attachment.document.fileType !== excludeHTMLFile)
              .map(item => ({ ...item, checkBox: false }));
            setAttachmentRes(res as IAttachments[]);
            dispatch(candidateDetailActions.setAttachments(res as IAttachments[]));
            dispatch(candidateDetailActions.setExistingAttachments(res as IAttachments[]));
            if (!res?.length)
              dispatch(
                globalActions.setSnackBar({
                  message: t('search.grid.download.noDataMessage'),
                  severity: 'error',
                }),
              );
            if (!(res && res.length)) {
              setFormOpen(false);
              onClose();
            }
            setFormOpen(false);
            setIsShowLoader(false);
            setDel(false);
          } else {
            setFormOpen(false);
            setIsShowLoader(false);
            setDel(false);
            onClose();
          }
        })
        .catch(error => {
          setFormOpen(false);
          setIsShowLoader(false);
          setDel(false);
          onClose();
        });
    } else {
      dispatch(
        globalActions.setSnackBar({
          message: t('common.failedLoad'),
          severity: 'error',
        }),
      );
      setIsShowLoader(false);
    }
  };

  useEffect(() => {
    setAttachmentRes(attachments);
  }, [attachments]);

  useEffect(() => {
    if (data?.open) {
      if (existingAttachmentRes) {
        const existingAttachments: IAttachments[] = _.cloneDeep(existingAttachmentRes);
        dispatch(candidateDetailActions.setAttachments(existingAttachments));
        dispatch(candidateDetailActions.setExistingAttachments(existingAttachments));
        setAttachmentRes(existingAttachments);
        if (!existingAttachments?.length)
          dispatch(
            globalActions.setSnackBar({
              message: t('search.grid.download.noDataMessage'),
              severity: 'error',
            }),
          );
        setFormOpen(false);
        onAttachmentAction();
      } else {
        setAttachmentRes([]);
        setIsShowLoader(true);
        fetchData().then(() => {
          onAttachmentAction();
        });
      }
    }
    //Saga To Make API Call - Refer : Rijash - Mock data in redux with saga
    //dispatch(candidateDetailActions.setAttachments(attachments));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.action, data.open]);

  useEffect(() => {
    setCredential(findCredentialByRequirementID(data.requirementID, candidateDetails.credentials));
  }, [candidateDetails.credentials, data.action, data.requirementID]);

  /**
   *  Method to merge attachment response and skill checklist data
   * @param attachmentResponse
   */
  const mergeAttachmentsAndPassportData = attachmentResponse => {
    let concatArrays = undefined;
    concatArrays = [].concat(attachmentResponse, [
      {
        isSkillChecklist: true,
        checkBox: false,
        comments: null,
        document: {
          uri: '',
          fileType: 'application/pdf',
          fileData: '',
        },
        lastUpdated: null,
        lastUpdatedBy: null,
        sortOrder: attachmentResponse?.length - 1,
        status: 'Not complete',
        title: null,
        travelerRequirementImageID: 3,
      },
    ]);
    return concatArrays;
  };

  const onFormClose = () => {
    setFormOpen(false);
    setAddFormOpen(false);
    setAttachmentRes([]);
    setSelectedAttachments([]); //To Clear Checkboxes
    dispatch(candidateDetailActions.setSelPreviewAttachments([] as IAttachments[]));
    dispatch(candidateDetailActions.setHistoryAttachments([] as IAttachments[]));
    onClose();
  };

  const onAddFormClose = useCallback(() => {
    dispatch(candidateDetailActions.setNewAttachments([]));
    setAddFormOpen(false);
  }, [dispatch]);

  const onAddDocumentClick = () => {
    setFormOpen(false);
    setAddFormOpen(true);
    setIsPreview(true);
  };

  const loadPreview = () => {
    setFormOpen(true);
    setPreviewDialogText({ title: 'View Attachment(s)' });
    setHasDownloadButton(false);
    setHasDeleteButton(false);
    setDownload(false);
    setAddFormOpen(false);
    setIsPreview(false);
  };

  const onAttachmentAction = () => {
    switch (data.action) {
      case AttachmentAction.view:
        setAddFormOpen(false);
        setSimpleDialogText({
          title: 'View',
          actionOne: 'View All',
          actionTwo: 'View Selected',
        });
        break;
      case AttachmentAction.add:
        if (!addFormOpen) setAddFormOpen(true);
        setFormOpen(false);

        onClose();
        break;
      case AttachmentAction.download:
        setAddFormOpen(false);
        setSimpleDialogText({
          title: 'Download',
          actionOne: 'Download All',
          actionTwo: 'Download Selected',
        });
        break;
      case AttachmentAction.delete:
        setAddFormOpen(false);
        setSimpleDialogText({
          title: 'Delete',
          actionOne: 'Delete All',
          actionTwo: 'Delete Selected',
        });
        break;
      default:
        break;
    }
  };

  /**
   * Perform action on the selected Attachments
   */
  const onSelectedAttachmentAction = () => {
    onClose();
    switch (data.action) {
      case AttachmentAction.view:
        setFormOpen(true);
        setPreviewDialogText({ title: 'View Attachment(s)' });
        setHasDownloadButton(false);
        setHasDeleteButton(false);

        setDownload(false);
        break;
      case AttachmentAction.add:
        break;
      case AttachmentAction.delete:
        setFormOpen(true);
        setPreviewDialogText({ title: 'View Attachment(s)' });
        setPreviewDeleteText({ title: 'Delete Selected' });
        setHasDownloadButton(false);
        setHasDeleteButton(true);

        setDownload(false);
        break;
      case AttachmentAction.download:
        setFormOpen(true);
        setPreviewDialogText({ title: 'View Attachment(s)' });
        setPreviewDownloadText({ title: 'Download Selected' });
        setHasDownloadButton(true);
        setHasDeleteButton(false);

        setDownload(false);
        break;
    }
  };

  /**
   * Perform action on all attachments
   */
  const onAllAttatachmentAction = async () => {
    switch (data.action) {
      case AttachmentAction.view:
        const attachmentsIds = attachments?.map(a => a.sortOrder);
        dispatch(candidateDetailActions.setSelPreviewAttachments(attachments as IAttachments[]));
        dispatch(candidateDetailActions.setHistoryAttachments(attachments as IAttachments[]));
        navigate(attachmentsIds);
        break;
      case AttachmentAction.add:
        break;
      case AttachmentAction.delete:
        setSelectedAttachments(attachments as unknown as IAttachments[]);
        dispatch(candidateDetailActions.setSelPreviewAttachments(attachments as unknown as IAttachments[]));
        dispatch(candidateDetailActions.setHistoryAttachments(attachments as IAttachments[]));
        setInlineDel(false);
        setOpenDeleteConfirmation(true);
        setFormOpen(false);
        break;
      case AttachmentAction.download:
        onClose(); //close dialog window
        setFormOpen(false);
        dispatch(
          globalActions.setSnackBar({
            message: t('search.grid.download.loadingMessage'),
            severity: 'error',
            iconColor: 'blue',
          }),
        );
        await downloadZip(credential?.credential?.requirementType, attachments as unknown as IAttachments[]);
        dispatch(
          globalActions.setSnackBar({
            message: t('search.grid.download.successMessage'),
            severity: 'success',
          }),
        );
        break;
      default:
        break;
    }
  };

  /**
   * Navigate to full attachment viewer with selected attachment IDs
   */
  const onViewOrDownloadSelectedAttachments = async () => {
    //Todo: Re-Check This logic
    switch (data.action) {
      case AttachmentAction.add:
      case AttachmentAction.delete:
      case AttachmentAction.view:
      case AttachmentAction.download:
        const showSkillChecklistDoc = selectedAttachments?.find(item => item.isSkillChecklist);
        if (showSkillChecklistDoc) {
          dispatch(candidateDetailActions.openSkillChecklistDoc(true));
        }
        const attachmentsIds = selectedAttachments?.map(a => a.sortOrder);
        navigate(attachmentsIds);
        break;
      default:
        break;
    }
  };

  /**
   * Navigate to full attachment viewer
   * @param attachmentIds Selected attachment IDs
   */
  const navigate = (attachmentIds?) => {
    history.push({
      pathname: `/credentialing/${data.travelerId}/${data.brandId}/attachements/${data.requirementID}/${
        data.requirementTypeID
      }${attachmentIds !== undefined ? `?at=${attachmentIds}` : ''}`,
      state: { navigateBack: true },
    });
  };

  const onAttachmentSelected = (
    selectedItems: IAttachments,
    booleanSelection: boolean,
    booleanAllSelection: boolean,
  ) => {
    if (del) setDel(false);
    const res = attachments?.map(item => ({ ...item, checkBox: booleanAllSelection }));
    if (booleanAllSelection && selectedItems === null) {
      setAttachmentRes(res);
      setSelectedAttachments(res as IAttachments[]);
      dispatch(candidateDetailActions.setSelPreviewAttachments(res as IAttachments[]));
      dispatch(candidateDetailActions.setHistoryAttachments(res as IAttachments[]));
    } else if (del || (selectedItems === null && !booleanSelection && !booleanAllSelection)) {
      setAttachmentRes(res);
      setSelectedAttachments([] as IAttachments[]);
      dispatch(candidateDetailActions.setSelPreviewAttachments([] as IAttachments[]));
      dispatch(candidateDetailActions.setHistoryAttachments([] as IAttachments[]));
    } else {
      let currentSelection: IAttachments[] = _.cloneDeep(selectedAttachments);

      if (booleanSelection) {
        currentSelection.push(selectedItems);
      } else {
        currentSelection = currentSelection?.filter(item => item.sortOrder !== selectedItems.sortOrder);
      }
      const sortedAttach = attachments?.map(item => {
        const idx = currentSelection.filter(i => i.travelerRequirementImageID === item.travelerRequirementImageID);
        return idx.length > 0 ? { ...item, checkBox: true } : item;
      });
      setAttachmentRes(sortedAttach);
      currentSelection = currentSelection.map(item => ({ ...item, checkBox: true }));
      setSelectedAttachments(currentSelection);
      dispatch(candidateDetailActions.setSelPreviewAttachments(currentSelection as IAttachments[]));
      dispatch(candidateDetailActions.setHistoryAttachments(currentSelection as IAttachments[]));
    }
  };

  const onDeleteSelected = (item, inlineDelete = false) => {
    setInlineDel(inlineDelete);

    if (inlineDelete) {
      setInlineAttachments([item]);
      setOpenDeleteSingleConfirmation(true);
    } else {
      setOpenDeleteConfirmation(true);
    }
  };

  const onDownloadSelected = data => {
    selectedAttachments.map(item => {
      downloadFile(
        item?.document?.uri,
        item?.document?.fileData,
        getFileNameFormat(
          credential?.credential?.requirementType,
          `Attachment ${item.sortOrder + 1}`,
          item.lastUpdated,
        ),
        item?.document?.fileType,
      );
    });
    dispatch(
      globalActions.setSnackBar({
        message: t('search.grid.download.successMessage'),
        severity: 'success',
      }),
    );
  };

  const handleDeleteConfirmClose = (modalState: boolean, clearValues: boolean) => {
    setOpenDeleteConfirmation(modalState);
    setOpenDeleteSingleConfirmation(modalState);
    if (clearValues) {
      setIsDeletingProcessActive(true);
    }
  };

  useEffect(() => {
    let disabled = false;
    if (newAttachments == undefined || newAttachments.length == 0) {
      setIsAttachmentSelected(false);
    } else {
      setIsAttachmentSelected(true);
    }
    if (newAttachments && newAttachments.length > 0) {
      let success = true;
      for (const item of newAttachments) {
        if (item.status === AttachmentItemState.default) {
          success = false;
        }
        if (item.status === AttachmentItemState.error || item.status === AttachmentItemState.loading) {
          disabled = true;
          success = false;
        }
      }
      if (success) {
        onAddFormClose();
        if (onSubmitAttachments) {
          onSubmitAttachments();
        } else {
          if (!isPreviewAdd) {
            setIsShowLoader(true);
            fetchData().then(() => {
              setFormOpen(true);
              setDisableAction(false);
              dispatch(
                globalActions.setSnackBar({
                  message: t('search.grid.add.successUploadMessage'),
                  severity: 'success',
                }),
              );
              setSelectedAttachments([] as IAttachments[]);
            });
          }
        }
      }
    }
    setDisableAction(disabled);
  }, [newAttachments, onAddFormClose]);

  useEffect(() => {
    if (isDeletingProcessActive) {
      if (!isDeleting) {
        setIsDeleting(true);
        dispatch(
          globalActions.setSnackBar({
            message: t('search.grid.download.deleteMessage'),
            severity: 'error',
            iconColor: 'blue',
          }),
        );
      }
      processDelete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleting, isDeletingProcessActive]);

  const DeleteAttchmentItem = async (item: IAttachments) => {
    let processSucess = true;
    await onDeleteAttachment(data.travelerId, data.requirementID, item, attachments, dispatch)
      .then(res => {})
      .catch(err => {
        processSucess = false;
      })
      .finally(() => {});
    return processSucess;
  };

  const processDelete = async () => {
    if (!isDeletingProcessActive) return;
    let processSucess = true;
    //Here we handled logic for inline Del and Delete Selected
    const attachmentList = inlineDel ? inlineAttachments : selectedAttachments;
    const deletedAttchements: IAttachments[] = [];

    for (let i = 0; i < attachmentList.length; i++) {
      const item = attachmentList[i];
      if (item.travelerRequirementImageID) {
        await DeleteAttchmentItem(item).then(result => {
          if (!result) {
            processSucess = false;
          } else {
            deletedAttchements.push(item);
          }
        });
      }
    }
    if (isDeletingProcessActive) {
      if (processSucess) {
        dispatch(
          globalActions.setSnackBar({
            message: t('search.grid.delete.snackbarSuccess'),
            severity: 'success',
          }),
        );
        setIsDeleting(false);
      } else {
        dispatch(
          globalActions.setSnackBar({
            message: t('search.grid.delete.snackbarError'),
            severity: 'error',
          }),
        );
        setIsDeleting(false);
      }
      setIsDeletingProcessActive(false);
      const remaingAttchements: IAttachments[] = [];
      const remaingSelectedAttchements: IAttachments[] = [];
      attachments?.map(item => {
        const attachmentItems = deletedAttchements?.filter(
          t => t.travelerRequirementImageID === item.travelerRequirementImageID,
        );
        if (!attachmentItems || attachmentItems.length <= 0) {
          remaingAttchements.push(item);
          const attachmentItemsSelected = selectedAttachments?.filter(
            t => t.travelerRequirementImageID === item.travelerRequirementImageID,
          );
          if (attachmentItemsSelected && attachmentItemsSelected.length > 0) {
            remaingSelectedAttchements.push(item);
          }
        }
      });
      dispatch(
        candidateDetailActions.setAttachments(
          remaingAttchements.sort(function (a, b) {
            return a.sortOrder > b.sortOrder ? 1 : b.sortOrder > a.sortOrder ? -1 : 0;
          }),
        ),
      );
      setSelectedAttachments(remaingSelectedAttchements);
      dispatch(candidateDetailActions.setSelPreviewAttachments(remaingSelectedAttchements as IAttachments[]));
      dispatch(candidateDetailActions.setHistoryAttachments(remaingSelectedAttchements as IAttachments[]));
    }
    setDel(true);
  };

  useEffect(() => {
    if (del) {
      setAllCheckbox(false);
    } else if (attachments?.length === selectedAttachments?.length) {
      setAllCheckbox(true);
    } else {
      setAllCheckbox(false);
    }
  }, [attachments, selectedAttachments, allCheckbox]);

  const submitFailedAttachment = async attachment => {
    let newAttachmentItems = _.cloneDeep(newAttachments) || [];
    const files: any[] = [];
    newAttachmentItems.map(item => {
      if (item.data === attachment.data) {
        const file = {
          ...item,
          status: AttachmentItemState.loading,
        };
        files.push(file);
      }
    });
    dispatch(candidateDetailActions.setNewAttachments(files));

    await onAddAttachment(data.travelerId, data.requirementID, attachments, attachment, user, dispatch)
      // eslint-disable-next-line no-loop-func
      .then(() => {
        const files: any[] = [];
        newAttachmentItems.map(item => {
          let file = item;
          if (_.isEqual(item, attachment)) {
            file = {
              ...item,
              status: AttachmentItemState.success,
            };
          }
          files.push(file);
        });
        newAttachmentItems = files;
        dispatch(candidateDetailActions.setNewAttachments(files));
      })
      // eslint-disable-next-line no-loop-func
      .catch(e => {
        const failedFiles = failedAttachments;
        const files: any[] = [];
        newAttachmentItems.map(item => {
          let file = item;
          if (item === attachment) {
            file = {
              ...item,
              status: AttachmentItemState.error,
            };
            failedFiles.push(file);
          }
          files.push(file);
        });
        newAttachmentItems = files;
        setFailedAttachments(failedFiles);
        dispatch(candidateDetailActions.setNewAttachments(files));
      });
  };

  const submitNewAttachments = async () => {
    setDisableAction(true);
    let processSuccess = true;

    let newAttachmentItems = _.cloneDeep(newAttachments) || [];
    const files: any[] = [];
    newAttachmentItems.map(item => {
      const file = {
        ...item,
        status: AttachmentItemState.loading,
      };
      files.push(file);
    });
    newAttachmentItems = files;
    dispatch(candidateDetailActions.setNewAttachments(files));

    if (newAttachments) {
      for (const attachment of newAttachmentItems) {
        await onAddAttachment(data.travelerId, data.requirementID, attachments, attachment, user, dispatch)
          // eslint-disable-next-line no-loop-func
          .then(() => {
            const files: any[] = [];
            newAttachmentItems.map(item => {
              let file = item;
              if (_.isEqual(item, attachment)) {
                file = {
                  ...item,
                  status: AttachmentItemState.success,
                };
              }
              files.push(file);
            });
            newAttachmentItems = files;
            dispatch(candidateDetailActions.setNewAttachments(files));
          })
          // eslint-disable-next-line no-loop-func
          .catch(e => {
            processSuccess = false;
            const failedFiles = failedAttachments;
            const files: any[] = [];
            newAttachmentItems.map(item => {
              let file = item;
              if (item === attachment) {
                file = {
                  ...item,
                  status: AttachmentItemState.error,
                };
                failedFiles.push(file);
              }
              files.push(file);
            });
            newAttachmentItems = files;
            setFailedAttachments(failedFiles);
            dispatch(candidateDetailActions.setNewAttachments(files));
          });
      }
    }
    if (processSuccess) {
      onAddFormClose();
      if (onSubmitAttachments) {
        onSubmitAttachments();
      } else {
        setIsShowLoader(true);
        fetchData().then(() => {
          setFormOpen(true);
          setDisableAction(false);
          //setAddSuccessful(true);
        });
      }
    }
  };

  const showViewPopup = () => {
    if (attachmentRes?.length && !addFormOpen) {
      return (
        <React.Fragment>
          <GenericDialog
            open={formOpen}
            disableEscapeKeyDown
            variant="blue"
            fullWidth
            maxWidth="lg"
            onClose={onFormClose}
            dialogTitleProps={{
              text: 'Attachment(s) Preview',
              leadingIcon: <NotesIcon />,
              closeButton: true,
              size: 'small',
            }}
            dialogActions={[
              {
                text: 'Cancel',
                variant: 'contained',
                color: 'tertiary',
                onClick: onFormClose,
                'data-testid': 'cancel-button-id',
              },
              {
                text: previewDeleteText.title,
                variant: 'contained',
                onClick: onDeleteSelected,
                disabled: !(selectedAttachments.length > 0),
                hidden: !hasDeleteButton,
              },
              {
                text: previewDownloadText.title,
                variant: 'contained',
                onClick: onDownloadSelected,
                disabled: !(selectedAttachments.length > 0),
                hidden: !hasDownloadButton,
              },
              {
                text: previewDialogText.title,
                variant: 'contained',
                onClick: onViewOrDownloadSelectedAttachments,
                disabled: !(selectedAttachments.length > 0),
              },
            ]}
          >
            <React.Fragment>
              {/* TODO: @ABISHEK KADAM - Replace with proper error component: Due Date: 15th Oct 2022 */}
              {attachments === null && <div>{t('search.grid.download.noDataMessage')}</div>}
              {attachments === undefined && <LoadingGrid />}
              {attachments !== undefined && attachments !== null && attachments.length <= 0 && (
                <div>{t('search.grid.download.noDataMessage')}</div>
              )}
              {attachments !== undefined && attachments !== null && attachments.length > 0 && (
                <AttachmentPreviewContent
                  attachments={attachmentRes}
                  onAttachmentSelected={onAttachmentSelected}
                  allCheckboxToggle={allCheckbox}
                  credential={credential}
                  isDownload={download}
                  handleDelete={onDeleteSelected}
                  selectedAttachments={selectedAttachments}
                  onAddDocumentClick={onAddDocumentClick}
                />
              )}
            </React.Fragment>
          </GenericDialog>
          <GenericDialog
            open={data.open}
            onClose={onClose}
            dialogTitleProps={{
              text: simpleDialogText.title,
            }}
            dialogContentProps={{
              variant: 'text',
            }}
          >
            <Box>
              <Box component="span">{'Do you want to '}</Box>
              <Box component="span" onClick={onAllAttatachmentAction} className={classes.link}>
                {simpleDialogText.actionOne}
              </Box>
              <Box component="span">{' (or) '}</Box>
              <Box
                component="span"
                onClick={onSelectedAttachmentAction}
                className={classes.link}
                id="attachment-preview"
              >
                {simpleDialogText.actionTwo}
              </Box>
            </Box>
          </GenericDialog>
          <GenericDialog
            open={openDeleteConfirmation || openDeleteSingleConfirmation}
            maxWidth="sm"
            disableEscapeKeyDown
            fullWidth
            dialogTitleProps={{
              text: t('search.grid.delete.dialogSureTitle'),
            }}
            dialogContentProps={{ variant: 'text' }}
            dialogActions={[
              {
                text: t('search.grid.delete.dialogCancelButton'),
                variant: 'contained',
                color: 'tertiary',
                onClick: () => handleDeleteConfirmClose(false, false),
              },
              {
                text: openDeleteConfirmation
                  ? t('search.grid.delete.dialogYesButton')
                  : openDeleteSingleConfirmation
                  ? 'Ok'
                  : 'Ok',
                variant: 'contained',
                color: 'primary',
                onClick: () => handleDeleteConfirmClose(false, true),
              },
            ]}
          >
            {openDeleteConfirmation
              ? t('search.grid.delete.dialogSelectedContent')
              : openDeleteSingleConfirmation
              ? t('search.grid.delete.dialogDeleteContent')
              : ''}
          </GenericDialog>
        </React.Fragment>
      );
    }
    if (addFormOpen) {
      return (
        <GenericDialog
          open={addFormOpen}
          disableEscapeKeyDown
          fullWidth
          maxWidth="md"
          variant="blue"
          onClose={isPreview ? loadPreview : onAddFormClose}
          dialogTitleProps={{
            text: 'Add Attachments',
            closeButton: true,
            size: 'small',
          }}
          dialogActions={[
            {
              text: 'Cancel',
              variant: 'contained',
              color: 'tertiary',
              onClick: isPreview ? loadPreview : onAddFormClose,
            },
            {
              text: 'Add',
              variant: 'contained',
              color: 'primary',
              onClick: submitNewAttachments,
              disabled: isAttachmentSelected ? disableAction : true,
            },
          ]}
        >
          <DocumentDropzoneWrapper
            compressed={false}
            existingAttachments={attachments}
            data={data}
            submitFailedAttachment={submitFailedAttachment}
            dissableUpload={disableAction}
          />
        </GenericDialog>
      );
    }
  };

  return (
    <>
      {isDeleting && <CircularProgress className={classes.MuiCircularProgressRoot} />}
      <CustomBackdrop open={isShowLoader} />
      {showViewPopup()}
    </>
  );
};
