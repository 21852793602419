import { Card, CardContent, Grid, Typography } from 'amn-ui-core';
import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import { PreviewTemplateModal } from './PreviewTemplateModal';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips/CustomTooltip';
import { useTranslation } from 'react-i18next';
import TemplateNotFound from 'app/assets/images/Template/TemplateNotFound.svg';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { useDispatch, useSelector } from 'react-redux';
import { TemplateCategory } from './AddTemplateModal';
import { ITemplateType } from 'app/models/Notification/Notification';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { selectUser } from 'oidc/user.selectors';

const useStyles = makeStyles<{ disabled: boolean }>()((theme, { disabled = false }) => ({
  cardContainer: {
    maxHeight: '350px',
    overflowY: 'auto',
    padding: '12px',
    justifyContent: 'center',
  },
  _card: {
    // margin: '15px 15px 15px 20px',
    width: 'inherit',
    maxHeight: '99px',
    transition: 'background-color 0.3s, border-color 0.3s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${theme.palette.system.cardBackground}`,
    },
    pointerEvents: disabled ? 'none' : 'auto',
  },
  cardClicked: {
    backgroundColor: `${theme.palette.system.cardBackground}`,
    border: `3px solid ${theme.palette.system.navyBlue}`,
  },
  titleClicked: {
    fontWeight: 500,
    fontSize: 16,
    color: `${theme.palette.system.navyBlue}`,
  },
  title: {
    fontWeight: disabled ? '' : 400,
    fontSize: 16,
    color: disabled ? theme.palette.system.lightGrey : theme.palette.framework.system.charcoal,
  },
  description: {
    color: disabled ? theme.palette.system.lightGrey : theme.palette.framework.system.charcoal,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tooltipPlacementBottom: {
    margin: 0,
    marginTop: 0,
  },
  messageBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'inherit',
    padding: '60px 20px 20px 20px',
  },
  message: {
    fontSize: '14px',
    color: theme.palette.components.typography.color,
    fontStyle: 'italic',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    ' span': {
      padding: 0,
      margin: 0,
    },
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    marginRight: '-15px',
    marginBottom: '10px',
  },
  editIcon: {
    marginRight: '10px',
    height: '20px',
    width: '20px',
  },
}));

interface ITemplateList {
  value: string;
  cards: any[];
  disabled: boolean;
  onChange: Function;
  editIcon?: boolean;
  setShowAddEditTemplate?: Function;
}

export const getFacilityEmailTemplateById = (data: any): ITemplateType => {
  const facilityEmailTemplateData: ITemplateType = {
    title: data.title || '',
    id: data.id || '',
    body: data.body || '',
    description: data.description || '',
    category: data.category || TemplateCategory.PacketSubmission,
    isPrivate: data.isPrivate || false,
    substitutions: [],
    subject: data.subject || '',
  };

  return facilityEmailTemplateData;
};

export const TemplateList = (props: ITemplateList) => {
  const { value, cards = [], disabled, onChange, editIcon = false, setShowAddEditTemplate } = props;
  const { cx, classes } = useStyles({ disabled });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [previewOpen, setPreviewOpen] = React.useState<boolean>(false);
  const [previewCard, setPreviewCard] = React.useState(null);
  const { userInfo } = useSelector(selectUser);

  const authorized = Authorized(
    [userRoles.accountManagement_Leadership, userRoles.accountManagement, userRoles.accountManagement_TeamMember],
    userInfo,
  );

  /**
   *No templates available message box.
   */
  const MessageBox = () => {
    return (
      <Grid className={classes.messageBox}>
        <div className={classes.message}>
          <img src={TemplateNotFound} alt="No templates available" />
          <span style={{ marginTop: 10 }}>{t('Preferences.noTemplateMessage.1')}</span>
          <span>{t('Preferences.noTemplateMessage.2')}</span>
          <span>{t('Preferences.noTemplateMessage.3')}</span>
        </div>
      </Grid>
    );
  };

  const onPreview = (data: any) => {
    setPreviewCard(data);
    setPreviewOpen(true);
  };

  const handleEdit = (data: any) => {
    const templateData = getFacilityEmailTemplateById(data);
    dispatch(notificationDataActions.setFacilityTemplatesById(templateData));
    dispatch(notificationDataActions.setIsEdit(true));
    setShowAddEditTemplate(true);
  };

  return (
    <Grid container className={classes.cardContainer} direction="row" spacing={2}>
      {cards.length > 0 ? (
        cards.map((item, index) => (
          <Grid item key={index} xs={12}>
            <Card
              classes={{ root: cx(classes._card, { [classes.cardClicked]: value === item?.id && !disabled }) }}
              onClick={() => onChange(item?.id)}
              data-testid={'email-tempalte-Card'}
            >
              <CardContent data-testid={'email-tempalte-Card-Content'}>
                <Grid item alignItems="center" className={classes.container}>
                  <Grid item>
                    <Typography
                      className={`${value === item?.id && !disabled ? classes.titleClicked : classes.title}`}
                      gutterBottom
                    >
                      {item?.title}
                    </Typography>
                  </Grid>

                  <Grid item className={classes.icons}>
                    <CustomTooltip
                      standardMargin
                      tooltipContent={t('Preferences.editTemplateTooltip')}
                      tooltipPlacementBottom={classes.tooltipPlacementBottom}
                    >
                      {editIcon && authorized ? (
                        <Grid item>
                          <CreateOutlinedIcon
                            color={disabled ? 'disabled' : 'primary'}
                            className={classes.editIcon}
                            onClick={() => handleEdit(item)}
                          />
                        </Grid>
                      ) : (
                        ''
                      )}
                    </CustomTooltip>

                    <Grid item>
                      <CustomTooltip
                        standardMargin
                        tooltipContent={t('Preferences.previewTemplateTooltip')}
                        tooltipPlacementBottom={classes.tooltipPlacementBottom}
                      >
                        <PageviewOutlinedIcon
                          color={disabled ? 'disabled' : 'primary'}
                          data-testid={'preview-template-icon'}
                          className={classes.editIcon}
                          onClick={() => onPreview(item)}
                        />
                      </CustomTooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Typography variant="body2" className={classes.description}>
                  {item?.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))
      ) : (
        <MessageBox />
      )}
      <PreviewTemplateModal
        open={previewOpen}
        onCancel={() => setPreviewOpen(false)}
        dialogTitleText={previewCard?.title}
        templateHtmlContent={previewCard?.body}
      />
    </Grid>
  );
};
