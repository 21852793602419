// import "i18next";
import { FormatDate } from '@AMIEWEB/Notification/Tasks/Common/FormatData';
import { IRequestCoverage, IUpdateRequestCoverage } from 'app/models/UserCoverage/UserCoverage';
import { addDays, addYears, isBefore, isValid, isWithinInterval, parse, parseISO, subYears } from 'date-fns';
import { enGB } from 'date-fns/locale';
import i18next from 'i18next';
import _cloneDeep from 'lodash/cloneDeep';
import moment, { Moment } from 'moment';
import { IUser } from 'oidc/user.redux';

export const enum Views {
  Add,
  Edit,
}

const commonValues = {
  coverageFor: i18next.t('notification.coverage.assignModal.coverageFor'),
  allDay: i18next.t('notification.coverage.assignModal.allday'),
  startDate: i18next.t('notification.coverage.assignModal.startDate'),
  endDate: i18next.t('notification.coverage.assignModal.endDate'),
  startTime: i18next.t('notification.coverage.assignModal.startTime'),
  endTime: i18next.t('notification.coverage.assignModal.endTime'),
  notes: i18next.t('notification.coverage.assignModal.notes'),
  coveredBy: i18next.t('notification.coverage.assignModal.coveredBy'),
  cancel: i18next.t('notification.coverage.assignModal.cancel'),
};
export const mapLabelsWithView = {
  [Views.Add]: {
    headerTitle: i18next.t('notification.coverage.assignModal.headerTitle'),
    ...commonValues,
    save: i18next.t('notification.coverage.assignModal.save'),
  },
  [Views.Edit]: {
    headerTitle: i18next.t('notification.coverage.assignModal.editHeaderTitle'),
    ...commonValues,
    save: i18next.t('notification.coverage.assignModal.confirm'),
  },
};

export const CHARACTER_LIMIT = 1000;
export interface IDropdownOption {
  id: string;
  label: string;
}
export const defaultValues: IRequestCoverage = {
  coveredEmployeeId: '',
  coveredEmployeeFirstName: '',
  coveredEmployeeLastName: '',
  coveringEmployeeId: '',
  coveringEmployeeFirstName: '',
  coveringEmployeeLastName: '',
  startDate: '',
  endDate: '',
  startTime: '',
  endTime: '',
  notes: '',
  createByEmployeeId: 0,
  createByFirstName: '',
  createByLastName: '',
  createDate: '',
  statusId: 4,
  coverageDateRange: { startDate: null, endDate: null },
};

export const assignCoverageVar = {
  coveredEmployeeId: 'coveredEmployeeId',
  coveredEmployeeFirstName: 'coveredEmployeeFirstName',
  coveredEmployeeLastName: 'coveredEmployeeLastName',
  coveringEmployeeId: 'coveringEmployeeId',
  coveringEmployeeFirstName: 'coveringEmployeeFirstName',
  coveringEmployeeLastName: 'coveringEmployeeLastName',
  startDate: 'startDate',
  endDate: 'endDate',
  startTime: 'startTime',
  endTime: 'endTime',
  notes: 'notes',
  createByEmployeeId: 'createByEmployeeId',
  createByFirstName: 'createByFirstName',
  createByLastName: 'createByLastName',
  createDate: 'createDate',
  coverageDateRange: 'coverageDateRange',
};

export const getTodaysFormattedMomentDate = () => {
  return moment(new Date(), 'MM/DD/YYYY');
};

export const setPstTimeOffset = (date: Moment) => {
  return date.tz('America/Los_Angeles', true).format();
};

export const postFormatTime = (dateValue, timeValue) => {
  const date = moment(dateValue, 'MM/DD/YYYY');
  // Parse the time using Moment.js
  const timeData = new Date(timeValue);

  // Combine date and time
  const combinedDateTime = date
    .clone()
    .set({
      hour: timeData.getHours(),
      minute: timeData.getMinutes(),
      second: timeData.getSeconds(),
    })
    .format();

  const pstDateValue = FormatDate(new Date());
  const pstOffset = pstDateValue.slice(-6);
  const formattedDate = combinedDateTime.slice(0, -6) + pstOffset;
  return formattedDate;
};

export const formatAllDayValue = (dateValue, startTime) => {
  const date = moment(dateValue, 'MM/DD/YYYY');
  // Parse the time using Moment.js
  const timeValue = startTime
    ? {
        hour: 0,
        minute: 0,
        second: 0,
      }
    : {
        hour: 23,
        minute: 59,
        second: 59,
      };
  // Combine date and time
  const combinedDateTime = date
    .clone()
    .set({
      hour: timeValue?.hour,
      minute: timeValue?.minute,
      second: timeValue?.second,
    })
    .format();

  const pstDateValue = FormatDate(new Date());
  const pstOffset = pstDateValue.slice(-6);
  const formattedDate = combinedDateTime.slice(0, -6) + pstOffset;
  return formattedDate;
};

export const getPostBody = (formData: IRequestCoverage, isAllDay: boolean, userInfo: IUser) => {
  let data: IRequestCoverage = _cloneDeep(formData);
  data.createByFirstName = userInfo?.userInfo?.firstName;
  data.createByLastName = userInfo?.userInfo?.lastName;
  data.createByEmployeeId = userInfo?.userInfo?.employeeId;
  data.createDate = setPstTimeOffset(getTodaysFormattedMomentDate());
  data.startDate = moment(data.startDate, 'MM/DD/YYYY').format();
  data.endDate = moment(data.endDate, 'MM/DD/YYYY').format();
  if (!isAllDay) {
    data.startDate = postFormatTime(formData.startDate, formData.startTime);
    data.endDate = postFormatTime(formData.endDate, formData.endTime);
  } else {
    data.startDate = formatAllDayValue(formData.startDate, true);
    data.endDate = formatAllDayValue(formData.endDate, false);
  }
  return data;
};

export const getEditPostBody = (formData: any, userInfo: IUser) => {
  let data: IUpdateRequestCoverage = {
    startDate: !formData?.allDay
      ? postFormatTime(formData.startDate, formData.startTime)
      : formatAllDayValue(formData.startDate, true),
    notes: formData?.notes,
    endDate: !formData?.allDay
      ? postFormatTime(formData.endDate, formData.endTime)
      : formatAllDayValue(formData.endDate, false),
    lastUpdateByFirstName: userInfo?.userInfo?.firstName,
    lastUpdateByLastName: userInfo?.userInfo?.lastName,
    lastUpdateByEmployeeId: userInfo?.userInfo?.employeeId,
    lastUpdateDate: setPstTimeOffset(getTodaysFormattedMomentDate()),
    id: formData?.id,
    coveringEmployeeFirstName: formData?.coveringEmployeeFirstName,
    coveringEmployeeLastName: formData?.coveringEmployeeLastName,
    coveringEmployeeId: formData?.coveringEmployeeId,
  };
  return data;
};

export const checkDateValidation = (value, checkIfEndDate?, startDateValue?, isRequired = true) => {
  if (isRequired && (!value || value.length === 0)) {
    return i18next.t('required');
  } else if (!isRequired && (!value || value === '')) {
    return null;
  } else if (value.includes('_') || value.includes(' ')) {
    return i18next.t('invalidFormat');
  } else {
    const customDateFormat = parseDate(value);
    const isDateValid = isValid(customDateFormat);
    const today = addDays(subYears(new Date(), 2), -1);
    const isoDate = parseISO(value);
    if (checkIfEndDate && startDateValue && isDateValid && isBefore(new Date(value), new Date(startDateValue))) {
      return i18next.t('endDateError');
    }
    if (
      isDateValid &&
      isWithinInterval(new Date(value), {
        start: startDateValue ? new Date(startDateValue) : today,
        end: addYears(today, 10),
      })
    ) {
      return null;
    }
    if (isValid(isoDate)) {
      return null;
    } else {
      return i18next.t('invalidDate');
    }
  }
};
export const checkTimeValidation = (value, isSameDay, checkIfEndTime?, startTimeValue?) => {
  const isoDate = parseISO(value);
  if (isValid(isoDate)) {
    return null;
  }
  if (value.length === 0) {
    return i18next.t('required');
  } else if (!isValid(value)) {
    return i18next.t('invalidFormat');
  } else {
    if (checkIfEndTime && startTimeValue && isValid(value) && (isSameDay ? isBefore(value, startTimeValue) : false)) {
      return i18next.t('endTimeError');
    }
    return null;
  }
};

export const parseDate = value => {
  return new Date(Date.parse(value));
  // return parse(value, 'MM/dd/yyyy', new Date(), { locale: enGB });
};

export const compareEditCoverageDates = (startDate, today) => {
  const momentDate1 = moment(startDate).format('YYYY-MM-DD');
  const momentDate2 = moment(today).format('YYYY-MM-DD');
  if (momentDate1 < momentDate2) {
    return true;
  }
  return false;
};

export const compareEditCoverageTime = (startDate, today) => {
  const momentDate1 = moment(startDate);
  const momentDate2 = moment(today);

  const formattedMomentDate1 = moment(startDate).format('YYYY-MM-DD');
  const formattedMomentDate2 = moment(today).format('YYYY-MM-DD');

  // Extract the time components and format them
  const time1 = momentDate1.format('HH:mm:ss');
  const time2 = momentDate2.format('HH:mm:ss');
  if (formattedMomentDate1 === formattedMomentDate2) {
    if (time1 < time2) {
      return true;
    }
  }
  return false;
};
