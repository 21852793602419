/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, Grid, IconButton, MenuItem, Popper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CalendarToday from '@mui/icons-material/CalendarToday';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { pageAttributesActions } from 'app/components/Placement/PlacementDetails/Edit/store/placementPageAttributes.redux';
import { TaskStatus, SubTypeId } from 'app/models/Tasks/Tasks';
import _concat from 'lodash/concat';
import moment from 'moment';
import { selectUser } from 'oidc/user.selectors';
import { EditTaskModal } from '../EditTask/EditTaskModal';
import { taskDetailsActions } from '../store/Tasks.redux';
import { WhiteTooltip } from 'app/components/Common/Tooltips/WhiteTooltip';
import { PassportBadge } from '../CustomComponents/PassportBadge';
import { EditExternalTaskModal } from '../EditTask/EditExternalTaskModal';
import FolderIcon from 'app/assets/images/Tasks/FolderIcon.svg';
import SubTaskIcon from 'app/assets/images/Tasks/SubTaskIcon.svg';
import { TaskRecipientAvatars } from '../TaskManagement/RecipientAvatars';
import { checkUserEditInTaskTile, getAssignee } from '../TaskDetails/helper';
import { selectActiveCoverage } from 'store/redux-store/user-coverage/selectors';
import { isAssigneeStatusCompletedCancelled } from '../TaskDetails/newTaskStatusHelper';
import { getEmployeeId } from '@AMIEWEB/Notification/Tasks/Common_v2/utils';

const useStyles = makeStyles<{ externalTask: boolean }>()((theme, props) => ({
  root: {
    height: 100,
    border: ' 1px solid #ECECEC',
    boxShadow: '0px 3px 6px #00000029',
    borderColor: '#c5c4c4',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    flexWrap: 'nowrap',
    borderRadius: '4px',
    '&:hover': {
      background: theme.palette.framework.system.whisper,
    },
  },
  cardFooterDivision: {
    padding: '6px 0px 10px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: 'rgb(247,247,247)',
  },
  cardContent: {
    padding: '0px',
    width: '272px',
    ':last-child': {
      padding: '0px',
    },
  },
  cardBody: {
    display: 'block',
    padding: '0px 9px 0px 12px',
  },
  truncation: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  taskTitle: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '14px',
    color: '#333333',
    opacity: 1,
    paddingTop: '1.5px',
    paddingLeft: !props.externalTask && '6px',
    height: '24px',
    fontWeight: 'bold',
    display: 'inline-grid',
    alignItems: 'center',
  },
  categoryLabel: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '12px',
    color: '#888888',
    opacity: 1,
    height: '16px',
    overflow: 'hidden',
    display: 'flex',
    paddingLeft: '2px',
  },
  subData: {
    maxHeight: '60px',
    padding: '6px 1rem 0px 2px',
    display: 'flex',
  },
  subLabelsColumn: {
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
  },
  subValuesColumn: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'grid',
    flexDirection: 'column',
  },
  subCategoryLabel: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '12px',
    color: '#000',
    opacity: 1,
    overflow: 'hidden',
    fontWeight: 'bold',
    letterSpacing: '0.02rem',
  },
  subCategoryValue: {
    textAlign: 'left',
    paddingLeft: '10px',
    fontFamily: 'Roboto',
    fontSize: '12px',
    color: '#000',
    opacity: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dueDate: {
    color: '#555555',
    font: 'normal normal normal 12px/17px Roboto',
    opacity: 1,
    margin: 'auto 0px',
    display: 'flex',
    justifyContent: 'end',
    marginLeft: 'auto',
  },
  moreIcon: {
    padding: '0px !important',
    marginLeft: '3px',
  },
  avatarDisplayContainer: {
    display: 'inline-flex',
    paddingLeft: '9px',
    '& .MuiAvatarGroup-avatar': {
      fontSize: '8px',
    },
    '& > *': {
      margin: theme.spacing(0.3),
      width: theme.spacing(3.5),
      height: theme.spacing(3.5),
    },
  },
  flexContainer: {
    width: 'inherit',
    display: 'flex',
    alignItems: 'center',
  },
  gridContainers: {
    maxHeight: 'calc(100vh - 128px)',
    margin: '8px 0px',
  },
  statusIcon: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  floatRight: {
    flex: 1,
    textAlign: 'right',
    display: 'flex',
  },
  menu: {
    width: '80px',
    height: '40px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 8px #0000004D',
    borderRadius: '4px',
    opacity: '1',
  },
  menuItem: {
    width: '80px',
    height: '40px',
    background: '#EFEFEF 0% 0% no-repeat padding-box',
    opacity: '1',
  },
  subtaskCount: {
    font: '14px/20px Roboto',
    fontWeight: 500,
    paddingLeft: '8px',
  },
  groupLeftContainer: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  taskDescription: {
    font: 'normal 12px/16px Roboto',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    marginRight: '6px',
  },
}));

export const TaskItem = ({
  task,
  setTaskID,
  container,
  cardClickFxn,
  isExternal = false,
}: {
  task: any;
  setTaskID: (id) => void;
  container?: number;
  cardClickFxn: (e) => void;
  isExternal?: boolean;
}) => {
  const dispatch = useDispatch();
  const { classes } = useStyles({ externalTask: isExternal });
  const { t } = useTranslation();
  const [isPastDueDate, setIsPastDueDate] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(true);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [anchorEl2, setAnchorEl2] = React.useState<HTMLElement | null>(null);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const userInfo = useSelector(selectUser);
  const user = userInfo?.userInfo;
  const [editExternalOpen, setEditExternalOpen] = useState(false);
  const [hasSubType1, setHasSubType1] = useState<boolean>(false);
  const [hasSubType2, setHasSubType2] = useState<boolean>(false);
  const activeCoverage = useSelector(selectActiveCoverage);

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setAnchorEl2(null);
  };

  const handleClick = e => {
    if (!anchorEl2) {
      setAnchorEl2(e.currentTarget);
      e.stopPropagation();
    } else {
      setAnchorEl2(null);
      e.stopPropagation();
    }
  };

  const getSubtaskLabel = taskCount => {
    const commentCount = taskCount?.subTaskCount || 0;
    const label = commentCount === 1 ? ' subtask' : ' subtasks';
    return `${commentCount} ${label}`;
  };

  const setExternalTaskDetails = () => {
    dispatch(
      taskDetailsActions.setCurrentExternalTask({
        id: task?.taskId,
        placementId: task?.placementId,
        candidateId: task?.candidateId,
        taskNameId: task?.taskNameId,
        categorySubType1: task?.categorySubType1,
        categorySubType2: task?.categorySubType2,
        taskCategoryId: task?.taskCategoryId,
      }),
    );
  };

  const eligibleExternalEdit = () => {
    if (task?.isAMIEWebRequest) {
      const isEligible = task?.createdBy?.id === user?.employeeId;
      setDisabled(!isEligible);
    } else {
      const isRecruiter = user?.roles?.includes('Recruitment');
      if (isRecruiter) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  };

  const checkForSubCategories = () => {
    if (
      task?.categorySubType1?.subTypeId === SubTypeId.License ||
      task?.categorySubType1?.subTypeId === SubTypeId.Certification
    ) {
      setHasSubType1(true);
    } else {
      setHasSubType1(false);
    }
    if (task?.categorySubType2) {
      if (
        task?.categorySubType2?.subTypeId === SubTypeId.State ||
        task?.categorySubType2?.subTypeId === SubTypeId.IssuingBody
      ) {
        setHasSubType2(true);
      }
    } else {
      setHasSubType2(false);
    }
  };

  useEffect(() => {
    if (isExternal && task) {
      checkForSubCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, isExternal, container]);

  useEffect(() => {
    const hsgId = 6000;
    const hsgRecipient = getAssignee(task, { employeeId: hsgId });
    const editUsers = _concat(task.assignedTo, task.createdBy);
    const editUserId = editUsers.map(a => a?.id);
    const isEditUser = editUserId?.includes(parseInt(getEmployeeId(userInfo?.userInfo)));
    if (!isExternal) {
      if (isAssigneeStatusCompletedCancelled(task, parseInt(getEmployeeId(userInfo?.userInfo)))) {
        setDisabled(true);
      }
      else if (activeCoverage?.length > 0) {
        setDisabled(!checkUserEditInTaskTile(task, activeCoverage, userInfo?.userInfo?.employeeId));
      } else setDisabled(!isEditUser && !hsgRecipient);
    } else {
      eligibleExternalEdit();
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, user, isExternal]);

  useEffect(() => {
    if (isExternal) {
      moment().isAfter(moment(task?.dueDate), 'day') && task?.statusId === TaskStatus.New
        ? setIsPastDueDate(true)
        : setIsPastDueDate(false);
    } else {
      moment().isAfter(moment(task?.dueDate), 'day') &&
      (task?.statusId === TaskStatus.Pending || task?.statusId === TaskStatus.New)
        ? setIsPastDueDate(true)
        : setIsPastDueDate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExternal, task]);

  const getRecipientList = () => {
    if (isExternal) {
      const assignedTo = [
        {
          ...task?.assignedTo,
        },
      ];
      return assignedTo?.concat(task?.ccValues?.map(cc => ({ ...cc, isCC: 'Y' }))).map(recipient => ({
        ...recipient,
        assignedToFirstName: recipient.firstName,
        assignedToLastName: recipient.lastName,
        statusId: recipient.statusId || 5,
      }));
    } else {
      return task?.assignedTo?.concat(task?.ccValues?.map(cc => ({ ...cc, isCC: 'Y' }))).map(recipient => ({
        ...recipient,
        assignedToFirstName: recipient.firstName,
        assignedToLastName: recipient.lastName,
      }));
    }
  };

  const subCategories = React.useMemo(() => {
    return (
      <>
        {hasSubType1 && (
          <div className={classes.subData}>
            <div className={classes.subLabelsColumn}>
              <div className={classes.subCategoryLabel} data-testid="task-item-task-subcategory1-label">
                {`${
                  task?.categorySubType1?.subTypeId === SubTypeId.License
                    ? t('notification.taskDetails.license')
                    : t('notification.taskDetails.certification')
                }:`}
              </div>
              {hasSubType2 && (
                <div className={classes.subCategoryLabel} data-testid="task-item-task-subcategory2-label">
                  {`${
                    task?.categorySubType2?.subTypeId === SubTypeId.State
                      ? t('notification.taskDetails.state')
                      : t('notification.taskDetails.issuingBody')
                  }:`}
                </div>
              )}
            </div>
            <div className={classes.subValuesColumn}>
              <div className={classes.subCategoryValue} data-testid="task-item-task-subcategory1-value">
                {task?.categorySubType1?.subTypeId === SubTypeId.License
                  ? task?.categorySubType1?.subTypeDataText?.split('(')[1]?.split(')')[0]
                  : task?.categorySubType1?.subTypeDataText}
              </div>
              {hasSubType2 && (
                <div className={classes.subCategoryValue} data-testid="task-item-task-subcategory2-value">
                  {task?.categorySubType2?.subTypeDataText}
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  }, [hasSubType1, hasSubType2, task]);

  return (
    <Grid data-testid="task-item-container">
      <Card
        className={classes.root}
        data-testid="task-item"
        style={{ marginBottom: '12px', cursor: 'pointer' }}
        onClick={e => {
          dispatch(taskDetailsActions.setInternalTaskDetailsModal(undefined));
          setTaskID(task?.taskId);
          cardClickFxn(e);
          setAnchorEl2(null);
        }}
      >
        <CardContent className={classes.cardContent}>
          <div className={classes.gridContainers} style={{ padding: '0px 2px 0px 12px' }}>
            <div className={classes.flexContainer}>
              {!isExternal && (
                <div className={classes.statusIcon} data-testid="task-file-icon">
                  <img src={FolderIcon} alt="Parent Task" />
                </div>
              )}
              <div style={{ marginLeft: '2px' }}>
                <div className={classes.taskTitle} data-testid="task-item-title">
                  <div className={classes.truncation}>{task?.task}</div>
                </div>
              </div>
              <div className={classes.groupLeftContainer}>
                {isExternal && (
                  <div className={classes.floatRight} style={{ marginLeft: '3px' }} data-testid="task-status-badge">
                    {isExternal && <PassportBadge />}
                  </div>
                )}
                <IconButton color="primary" className={classes.moreIcon} onClick={handleClick} size="small">
                  <MoreVertIcon />
                </IconButton>
                <Popper
                  open={Boolean(anchorEl2)}
                  anchorEl={anchorEl2}
                  role={undefined}
                  disablePortal
                  style={{ zIndex: '999' }}
                  placement={'bottom-end'}
                  className={classes.menu}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuItem
                      className={classes.menuItem}
                      disabled={disabled}
                      onClick={e => {
                        dispatch(taskDetailsActions.setInternalTaskDetailsModal(undefined));
                        isExternal ? setEditExternalOpen(true) : setCreateModalOpen(true);
                        if (isExternal) {
                          setExternalTaskDetails();
                        }else{
                          dispatch(taskDetailsActions.getTaskAction(task?.taskId));
                        }
                        setAnchorEl2(null);
                        e.stopPropagation();
                      }}
                    >
                      {t('search.grid.editMenu.edit')}
                    </MenuItem>
                  </ClickAwayListener>
                </Popper>
              </div>
            </div>
          </div>
          <Grid container xs={12} className={classes.cardBody}>
            <div className={classes.gridContainers} style={{ paddingRight: '1px' }}>
              <div className={isExternal ? classes.categoryLabel : classes.flexContainer}>
                {!isExternal && (
                  <>
                    <div className={classes.statusIcon}>
                      <img src={SubTaskIcon} alt="Subtask" />
                    </div>
                    <span className={classes.subtaskCount} data-testid="task-comment-count">
                      {getSubtaskLabel(task)}
                    </span>
                  </>
                )}
                {isExternal && (
                  <div
                    className="makeStyles-tooltip-1829 makeStyles-taskDescription-1827"
                    style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                    data-testid="task-item-task-category"
                  >
                    {(task?.statusId !== 3 && task?.statusId !== 4) || isExternal
                      ? task?.taskCategory
                      : task?.statusUpdateReason === null
                      ? t('notification.taskDetails.completedText')
                      : task?.statusUpdateReason}
                  </div>
                )}
                <WhiteTooltip title={isPastDueDate ? 'Past Due' : ''}>
                  <Grid item xs={5} className={classes.dueDate}>
                    <CalendarToday
                      style={{ height: '14px', color: isPastDueDate ? '#CC0000' : '#888888' }}
                      data-testid="task-due-date"
                    />
                    <Grid style={{ height: '14px', color: isPastDueDate ? '#AB0000' : '#888888' }}>
                      {task?.dueDate ? moment(task?.dueDate)?.format('MM/DD/YYYY') : '--'}
                    </Grid>
                  </Grid>
                </WhiteTooltip>
              </div>
              {isExternal && subCategories}
            </div>
            <Grid className={`${classes.taskDescription} ${classes.gridContainers}`} style={{ marginLeft: '3px' }}>
              {!isExternal && task?.description}
            </Grid>
          </Grid>
          <Grid container xs={12} direction="column" className={classes.cardFooterDivision}>
            <Grid item>
              <div className={classes.avatarDisplayContainer}>
                {TaskRecipientAvatars(null, false, getRecipientList(), task, 2, task?.createdBy)}
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {createModalOpen && (
        <EditTaskModal
          open={createModalOpen}
          handleClose={() => {
            dispatch(pageAttributesActions.setPopperOpen(false));
            setCreateModalOpen(false);
          }}
          editTaskData={task}
          container={container}
          taskDetail={false}
        />
      )}
      {editExternalOpen && (
        <EditExternalTaskModal
          open={editExternalOpen}
          handleClose={() => {
            dispatch(pageAttributesActions.setPopperOpen(false));
            setEditExternalOpen(false);
          }}
          editTaskData={task}
          container={container}
          taskDetail={false}
        />
      )}
    </Grid>
  );
};
