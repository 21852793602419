/* eslint-disable @typescript-eslint/dot-notation */
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ff_global_new_app_version } from 'app/constants/FeatureFlags/Global/keys';
import { useDecision } from '@optimizely/react-sdk';

export const useApplicationVersionChecker = () => {
  const dispatch = useDispatch();
  const [newAppVersionCheckFlag] = useDecision(ff_global_new_app_version, { autoUpdate: true });

  React.useEffect(() => {
    // Page is first loaded, update the version number in session storage since the latest JS was just validated.
    if (newAppVersionCheckFlag.enabled) {
      dispatch(globalActions.validateAppVersion({ forceValidation: true, updateVersionNumberWithoutNotice: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onVisibilityChanged = React.useCallback(() => {
    if (newAppVersionCheckFlag.enabled && document.visibilityState === 'visible') {
      // Window became active, force validation of the app version
      dispatch(
        globalActions.validateAppVersion({
          interval: parseInt(newAppVersionCheckFlag.variables.validationSettings?.['intervalInSeconds']),
          forceValidation: true,
        }),
      );
    }
  }, [dispatch, newAppVersionCheckFlag.enabled, newAppVersionCheckFlag.variables.validationSettings]);

  React.useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChanged);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChanged);
    };
  }, [dispatch, onVisibilityChanged]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (newAppVersionCheckFlag.enabled) {
        dispatch(
          globalActions.validateAppVersion({
            interval: parseInt(newAppVersionCheckFlag.variables.validationSettings?.['intervalInSeconds']),
          }),
        );
      }
    }, 15000);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
};
