import { Box, Radio } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { Expandable } from 'app/components/Common/Expandable/Expandable';
import { Title } from 'app/components/Order/OrderDetails/OrderPreferences/customComponents/OrderPreferencesDesign';
import { useTranslation } from 'react-i18next';
import { XGridDefaultColumn } from 'app/components/Common/XGrid/cells/XGridDefaults';
import { XGrid } from 'app/components/Common/XGrid/XGrid';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDisciplineSpecialityRates,
  selectSelectedDiscipline,
} from 'app/components/Facility/FacilityStore/Facility.selector';
import { facilityActions } from 'app/components/Facility/FacilityStore/Facility.redux';
import { usePromiseTracker } from 'react-promise-tracker';
import {
  GridSelectionModel,
  GridColumns,
  GridCallbackDetails,
  MuiEvent,
  GridRowScrollEndParams,
} from '@mui/x-data-grid-pro';
import { missingField } from 'app/constants';
import { IGetRateElement } from '@AMIEWEB/Facility/FacilityStore/Facility.model';
import { useParams } from 'react-router-dom';
const useStyles = makeStyles()({
  box: {
    '@media (min-width: 1423px)': {
      height: 'inherit',
    },
  },
  grid: {
    fontSize: '14px',
    border: 'none',
    height: '15.4rem',
    '@media (min-width: 768px) and (max-width: 1024px)': {
      fontSize: '12px',
    },
    '& .MuiDataGrid-colCellWrapper': { backgroundColor: '#F5F5F5' },
    '&.Mui-selected': { backgroundColor: '#D3E3FF' },
  },
  rateRange: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F5F5F5',
  },
  selectedRateRange: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#D3E3FF',
  },
});

export interface GridRow {
  isRadioButtonSelected: boolean;
  id: number;
}

export const DisciplineSpecialtyRates = () => {
  const pathParams = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { promiseInProgress: isCallingAPI } = usePromiseTracker({ area: 'get-discipline-specialty-rates', delay: 0 });
  const [sortModel, setSortModel] = React.useState<any>([
    {
      field: 'specialty',
      sort: 'asc',
    },
  ]);
  const specialityRates = useSelector(selectDisciplineSpecialityRates);
  const discipline = useSelector(selectSelectedDiscipline);
  const [selectedSpecialtyRateId, setSelectedSpecialtyRateId] = useState<number>(0);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [rows, setRows] = useState([]);
  let counter = 0;
  const onSortModelChange = model => {
    if (model.length <= 0) {
      return;
    }
    setSelectedSpecialtyRateId(1);
    setSortModel(model);
    setRows([]);
    if (Number(pathParams?.id) > 0 && discipline) {
      const request: any = {
        facilityId: Number(pathParams?.id),
        disciplineId: discipline?.disciplineId,
        pageSize: 10,
        pageNumber: 1,
        sortColumn: model[0].field,
        sortOrder: model[0].sort,
      };
      dispatch(facilityActions.getDisciplineSpecialityRatesAction(request));
    }
  };

  const getDisciplineSpecialityRateColumns: GridColumns = [
    {
      ...XGridDefaultColumn,
      field: 'view',
      headerName: 'View',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 60,
      flex: 0,
      renderCell: params => {
        return <Radio checked={params.row.id === selectedSpecialtyRateId} name="pid-radio" />;
      },
    },
    {
      ...XGridDefaultColumn,
      field: 'disciplineAbbreviation',
      headerName: 'Discipline',
      sortable: false,
      hideable: false,
      width: 110,
      flex: 0,
    },
    {
      ...XGridDefaultColumn,
      field: 'specialty',
      headerName: 'Specialty',
      hideable: false,
      width: 110,
      flex: 0,
    },
    {
      ...XGridDefaultColumn,
      field: 'subSpecialty',
      headerName: 'Sub Specialty',
      hideable: false,
      width: 140,
      flex: 0,
    },
    {
      ...XGridDefaultColumn,
      field: 'rateRange',
      headerName: 'Rate Ranges',
      hideable: false,
      align: 'center',
      headerAlign: 'center',
      cellClassName: params => {
        return params.row.id === selectedSpecialtyRateId ? classes.selectedRateRange : classes.rateRange;
      },
    },
  ];

  const getRowId = () => {
    counter += 1;
    return counter;
  };
  const onRowSelect = params => {
    if (params) {
      setSelectedSpecialtyRateId(+params?.id);
      dispatch(
        facilityActions.setSelectedSpecialtySubSpecialty({
          disciplineAbbreviation: params?.disciplineAbbreviation,
          specailty: params?.specialty,
          subSpecialty: params?.subSpecialty,
        }),
      );
      const payload: IGetRateElement = {
        disciplineId: params?.disciplineId || discipline?.disciplineId,
        specialtyId: params?.specialtyId,
        subSpecialtyId: params?.subspecialtyId,
        facilityId: Number(pathParams?.id),
      };
      dispatch(facilityActions.getRateElementsAction(payload));
    }
  };
  const mappedSpecialtyRateData = (rows: any) => {
    let mappedData = rows.map((item: any, index: number) => ({
      id: item.id ? item.id : index + 1,
      disciplineAbbreviation: item.disciplineAbbreviation ? item.disciplineAbbreviation : missingField,
      specialty: item.specialty ? item.specialty : missingField,
      subSpecialty: item.subSpecialty ? item.subSpecialty : missingField,
      rateRange: item.rateRange ? getRateRange(item) : missingField,
      discipline: item.discipline ? item.discipline : missingField,
      disciplineId: item.disciplineId ? item.disciplineId : missingField,
      isOCBR: item.isOCBR,
      minRateRange: item.minRateRange,
      specialtyId: item.specialtyId,
      subspecialtyId: item.subspecialtyId,
    }));
    return mappedData;
  };

  const getRateRange = row => {
    let value = '--';

    if (row?.rateRange === '--') {
      return value;
    }

    if (row?.minRateRange) {
      if (row?.rateRange) {
        if (row?.minRateRange < row?.rateRange) {
          value = `$ ${row.minRateRange?.toFixed(2)} - $ ${row.rateRange?.toFixed(2)}`;
        } else {
          value = `$ ${row?.rateRange?.toFixed(2)}`;
        }
      } else {
        value = `$ ${row?.minRateRange?.toFixed(2)}`;
      }
    } else if (row?.rateRange) {
      value = `$ ${row?.rateRange?.toFixed(2)}`;
    }
    return value;
  };

  useEffect(() => {
    setRows([]);
    if (Number(pathParams?.id) > 0 && discipline) {
      setSelectedSpecialtyRateId(1);
      const request: any = {
        facilityId: Number(pathParams?.id),
        disciplineId: discipline?.disciplineId,
        pageSize: 10,
        pageNumber: 1,
        sortColumn: sortModel[0].field,
        sortOrder: sortModel[0].sort,
      };
      dispatch(facilityActions.getDisciplineSpecialityRatesAction(request));
    }
  }, [pathParams?.id, discipline]);

  useEffect(() => {
    if (specialityRates) {
      const data = [...rows, ...mappedSpecialtyRateData(specialityRates)];
      setRows(data);
      setSelectionModel([selectedSpecialtyRateId]);
      onRowSelect(data[selectedSpecialtyRateId - 1]);
    }
  }, [specialityRates]);

  const scrolledToEnd = (gridParams: GridRowScrollEndParams, event: MuiEvent<{}>, details: GridCallbackDetails) => {
    if (Number(pathParams?.id) > 0 && discipline && gridParams.virtualRowsCount % 10 === 0) {
      console.log('Virtual Rows Count', gridParams.virtualRowsCount);
      const request: any = {
        facilityId: Number(pathParams?.id),
        disciplineId: discipline?.disciplineId,
        pageSize: 10,
        pageNumber: Math.floor(gridParams.virtualRowsCount / 10) + 1,
        sortColumn: sortModel[0].field,
        sortOrder: sortModel[0].sort,
      };
      dispatch(facilityActions.getDisciplineSpecialityRatesAction(request));
    }
  };

  return (
    <React.Fragment>
      <Expandable
        expanded
        titleComponent={
          <Title>
            {t('order.facility.rateCard.specialityRates.title')}
            {specialityRates ? (specialityRates[0] ? ` - ${specialityRates[0].disciplineAbbreviation}` : null) : null}
          </Title>
        }
        unmountOnExit={false}
      >
        <Box className={classes.box}>
          <XGrid
            columns={getDisciplineSpecialityRateColumns}
            rows={rows}
            getRowId={getRowId}
            className={classes.grid}
            headerHeight={60}
            loading={isCallingAPI && rows.length < 1}
            disableTitleCount={true}
            disableColumnSelector
            onSelectionModelChange={(model: GridSelectionModel) => {
              setSelectionModel(model);
              let data = rows && model.length > 0 ? rows[parseInt(model[0]?.toString()) - 1] : null;
              onRowSelect(data);
            }}
            selectionModel={selectionModel}
            onSortModelChange={onSortModelChange}
            disableMultipleSelection={true}
            title={''}
            onRowsScrollEnd={scrolledToEnd}
            pagination={false}
            toolbar={false}
            hideFooter={true}
            height={250}
          />
        </Box>
      </Expandable>
    </React.Fragment>
  );
};
