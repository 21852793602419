import { IRecentMessages } from 'app/models/Notification/Notification';

export const getInboxLogIds = (messageData: any) => {
  return messageData?.map(item => item?.log?.id);
};

export const handleUnpinMessage = (messageData: any, item: any, pinnedMessages: any[]): IRecentMessages => {
  var recentMessages: IRecentMessages = {
    pinnedMessages: [],
    messages: undefined,
  };

  var messages = messageData;
  if (item) {
    const pins: any[] = [];
    pinnedMessages.forEach(element => {
      if (element?.log?.id !== item) {
        pins.push(element);
      }
    });

    recentMessages.pinnedMessages = pins;

    pins?.forEach(data => {
      const recents: any[] = [];
      messages?.map((element, index) => {
        if (data?.log?.id !== element?.log?.id) {
          recents.push(element);
        }
      });
      messages = recents;
    });
    recentMessages.messages = messages;
  }

  return recentMessages;
};

export const handlePinMessage = (
  messageData: any,
  item: any,
  pinnedMessages: any,
  recentMsgs: any,
): IRecentMessages => {
  var recentMessages: IRecentMessages = {
    pinnedMessages: [],
    messages: undefined,
  };

  const pinned: any[] = [];
  messageData?.forEach(element => {
    if (element?.log?.id === item) {
      pinned.push(element);
    }
  });
  if (pinnedMessages?.length < 5 || !pinnedMessages) {
    recentMessages.pinnedMessage = pinned;
    const pinnedMsgs: any[] = [];
    recentMsgs.forEach(element => {
      if (element?.log?.id !== item) {
        pinnedMsgs.push(element);
      }
    });
    recentMessages.messages = pinnedMsgs;
  } else {
    recentMessages.pinnedLimitReached = true;
  }
  return recentMessages;
};
