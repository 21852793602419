import { Concatenate } from 'utils/string/string';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { Education } from 'app/models/Candidate/CandidateProfile';
import { SupervisorStatus } from '../constants';
import { userRoles } from 'oidc/userRoles';

export const skills = approvedSkillsets => {
  let skillSetValue: string = '';
  approvedSkillsets?.map((item, i) => {
    skillSetValue = Concatenate([skillSetValue, Concatenate([item.discipline, item.specialty], '-')], ', ');
  });
  return skillSetValue;
};

export const getVariant = (status: string) => {
  switch (status) {
    case SupervisorStatus.APPROVED:
      return 'approved';
    case SupervisorStatus.DECLINED:
      return 'failed';
    case SupervisorStatus.PENDING:
      return 'pending';
    default:
      return 'outlined';
  }
};

export const getOptionDisabled = (api: any, params: GridCellParams, option: { ID: number; Description: string }) => {
  const rowData = api?.current.getVisibleRowModels();
  const discipline = params.row.discipline;
  let isDisabled = false;
  rowData?.forEach((value, key) => {
    if (value.discipline === discipline && option.Description === value.specialty) {
      isDisabled = true;
    }
  });
  return isDisabled;
};

export const useCustomOptions = (api: any, params: GridCellParams, lookups) => {
  const data = api?.current?.state?.rows?.idRowsLookup[params.row.rowID];
  if (data) {
    const discipline = lookups?.disciplines?.find(d => d.object.Abbreviation === data.discipline);
    if (discipline) {
      const specData: any[] = [];
      const temp = lookups?.discSpecMapping?.filter(x => x.disciplineId === discipline?.object?.ID);
      temp?.map(item => {
        specData.push({ ID: item.specialtyId, Description: item.description });
      });
      return specData;
    }
  }
  return [];
};

export function getFirstFour(array: Education[]) {
  if (array) return array.length > 4 ? array.slice(0, 4) : array;
  return [];
}

export const formatSsn = (ssn: any) => {
  //Code Updated by ->Abhishek Wilson : ssn is not recieved as string
  //Navigate to sms modal and associate unkown number to a candidate ...ssn is recieved as a object
  //---------------------///
  let _ssn = ssn;
  if (typeof ssn === 'object') {
    _ssn = ssn.ssn;
  }
  const formattedSsn = _ssn?.replace(/-/g, ' ');
  return formattedSsn;
};

export const ssnViewableByUserRoles = [
  userRoles.accountManagement_Leadership,
  userRoles.accountManagement_TeamMember,
  userRoles.recruitment_TeamMember,
  userRoles.recruitment_Leadership,
  userRoles.credentialing_Leadership,
  userRoles.credentialing_TeamMember,
  userRoles.clinical_Director,
  userRoles.clinical_Manager,
  userRoles.clinical_QualificationsSpecialist,
  userRoles.clinical_ReviewAnalyst,
  userRoles.payrollSpecialist,
  userRoles.housingAccountExecutive,
  userRoles.timeProcessingSpecialist,
  userRoles.workersCompCoordinator,
  userRoles.riskManagement,
  userRoles.complianceAuditSpecialist,
  userRoles.benefitsCoordinator,
  userRoles.customerSupport_Leadership,
  userRoles.customerSupport_TeamMember,
];

export const calenderViewableByUserRoles = [
  userRoles.accountManagement_Leadership,
  userRoles.accountManagement_TeamMember,
  userRoles.recruitment_TeamMember,
  userRoles.recruitment_Leadership,
  userRoles.credentialing_Leadership,
  userRoles.credentialing_TeamMember,
  userRoles.clinical_Director,
  userRoles.clinical_Manager,
  userRoles.clinical_QualificationsSpecialist,
  userRoles.clinical_ReviewAnalyst,
  userRoles.payrollSpecialist,
  userRoles.customerSupport_Leadership,
  userRoles.customerSupport_TeamMember,
  userRoles.housingAccountExecutive,
  userRoles.timeProcessingSpecialist,
  userRoles.workersCompCoordinator,
  userRoles.riskManagement,
  userRoles.complianceAuditSpecialist,
  userRoles.benefitsCoordinator,
];

export const lastFourViewableByUserRoles = [
  userRoles.accountManagement_Leadership,
  userRoles.accountManagement_TeamMember,
  userRoles.recruitment_TeamMember,
  userRoles.recruitment_Leadership,
  userRoles.credentialing_Leadership,
  userRoles.credentialing_TeamMember,
  userRoles.clinical_Director,
  userRoles.clinical_Manager,
  userRoles.clinical_QualificationsSpecialist,
  userRoles.clinical_ReviewAnalyst,
  userRoles.payrollSpecialist,
  userRoles.customerSupport_Leadership,
  userRoles.customerSupport_TeamMember,
];

export const getGridRows = ({ skillsets = [], setSkillsetRows }) => {
  const tempRows = [];
  skillsets?.map((item, index) => {
    const row = {
      rowID: item.travelerSupervisorSkillsetID || index,
      discipline: item.discipline,
      specialty: item.specialty,
      status: item.status,
      rejectionReason: item.rejectionReason,
      existingSkillset: item.travelerSupervisorSkillsetID ? true : false,
      travelerSupervisorSkillsetID: item.travelerSupervisorSkillsetID,
    };
    tempRows.push(row);
  });
  setSkillsetRows(tempRows);
};

export const enableConatiner = ({ key, selectedData }) => {
  switch (key) {
    case 'deleteSkillsets':
      return selectedData.length === 0;
    default:
      return false;
  }
};
