import {
  addIndexToItems,
  convertToAbbreviation,
  getcombinedOptionsWithDefault,
  getFacilityRateElements,
  getFacilityRateSkillsMatched,
  getRatesWithinDateRanges,
  getRatesWithShiftMatch,
  getRatesWithValidRanges,
  getShiftId,
  getShiftName,
  groupAndSortOptions,
  RateElemenetSource,
  RateElements,
  refactorFacilityRateValue,
  refactorValue,
} from '../../helper';
import { IOffContractRateElements } from 'store/redux-store/create-order-ocbr/types';

// REFACTOR RESPONSE FOR DROPDOWN
/**
 * Created dropdown options from Facility data
 * @param skillset
 * @param shiftID
 */
const getFacilityRatesArray = (skillset, shiftID, dates, version, rateType, facilityOffcontractRates) => {
  const facilityBillRates: IOffContractRateElements[] = getFacilityRateElements(rateType, facilityOffcontractRates);
  const facilityBillRateSkillsMatched = getFacilityRateSkillsMatched(skillset, facilityBillRates);
  const facilityRatesWithinDateRange = getRatesWithinDateRanges(dates, facilityBillRateSkillsMatched);
  const facilityRatesWithValidStatus = getRatesWithValidRanges(facilityRatesWithinDateRange);
  let dropDownOptions = [];
  if (!version) {
    dropDownOptions = facilityRatesWithValidStatus.map((facilityRate, index) => {
      return facilityRate.shiftId
        ? {
            value: refactorFacilityRateValue(facilityRate.value, facilityRate.valueMax),
            group: `${getShiftName(facilityRate.shiftId)} Hour Shift`,
            groupShiftId: facilityRate.shiftId,
            index: index,
            disciplineId: skillset?.disciplineId,
            specialtyId: skillset?.specialtyId,
            subSpecialtyId: skillset?.subSpecialtyId,
            statusId: facilityRate.statusId,
            source: RateElemenetSource.Facility,
            createdAt: facilityRate?.createdAt,
            effectiveStartDate: facilityRate?.effectiveStartDate,
            effectiveEndDate: facilityRate?.effectiveEndDate,
            rateElementId: facilityRate?.rateElementId,
            offContractId: facilityRate?.offContractId,
            reason:facilityRate?.reason,
            documentIds: facilityRate?.documentIds,
            createdBy:facilityRate?.createdBy,
            lastUpdatedBy:facilityRate?.lastUpdatedBy,
            dateLastUpdated:facilityRate?.dateLastUpdated,
            dateCreated:facilityRate?.dateCreated,
            

          }
        : {
            value: refactorFacilityRateValue(facilityRate.value, facilityRate.valueMax),
            group: `All Shifts`,
            groupShiftId: null,
            index: index,
            disciplineId: skillset?.disciplineId,
            specialtyId: skillset?.specialtyId,
            subSpecialtyId: skillset?.subSpecialtyId,
            statusId: facilityRate.statusId,
            source: RateElemenetSource.Facility,
            createdAt: facilityRate?.createdAt,
            effectiveStartDate: facilityRate?.effectiveStartDate,
            effectiveEndDate: facilityRate?.effectiveEndDate,
            rateElementId: facilityRate?.rateElementId,
            offContractId: facilityRate?.offContractId,
            reason:facilityRate?.reason,
            documentIds: facilityRate?.documentIds,
            createdBy:facilityRate?.createdBy,
            lastUpdatedBy:facilityRate?.lastUpdatedBy,
            dateLastUpdated:facilityRate?.dateLastUpdated,
            dateCreated:facilityRate?.dateCreated,
          };
    });
  } else if (version === 'v2') {
    const facilityRatesWithShiftMatch = getRatesWithShiftMatch(shiftID, facilityRatesWithValidStatus);
    const facilityRatesWithNullShiftMatch = getRatesWithShiftMatch(null, facilityRatesWithValidStatus);
    const combinedFacilityRates = facilityRatesWithNullShiftMatch.concat(facilityRatesWithShiftMatch);

    dropDownOptions = combinedFacilityRates.map((facilityRate, index) => {
      return facilityRate.shiftId
        ? {
            value: facilityRate?.value ? refactorFacilityRateValue(facilityRate?.value, facilityRate?.valueMax) : null,
            group: `${getShiftName(facilityRate.shiftId)} Hour Shift`,
            groupShiftId: facilityRate.shiftId,
            index: index,
            disciplineId: skillset?.disciplineId,
            specialtyId: skillset?.specialtyId,
            subSpecialtyId: skillset?.subSpecialtyId,
            shift: getShiftName(shiftID),
            shiftID: shiftID,
            statusId: facilityRate.statusId,
            source: RateElemenetSource.Facility,
            createdAt: facilityRate?.createdAt,
            effectiveStartDate: facilityRate?.effectiveStartDate,
            effectiveEndDate: facilityRate?.effectiveEndDate,
            rateElementId: facilityRate?.rateElementId,
            offContractId: facilityRate?.offContractId,
            reason:facilityRate?.reason,
            documentIds: facilityRate?.documentIds,
            createdBy:facilityRate?.createdBy,
            lastUpdatedBy:facilityRate?.lastUpdatedBy,
            dateLastUpdated:facilityRate?.dateLastUpdated,
            dateCreated:facilityRate?.dateCreated,
          }
        : {
            value: facilityRate?.value ? refactorFacilityRateValue(facilityRate?.value, facilityRate?.valueMax) : null,
            group: `All Shifts`,
            groupShiftId: null,
            index: index,
            disciplineId: skillset?.disciplineId,
            specialtyId: skillset?.specialtyId,
            subSpecialtyId: skillset?.subSpecialtyId,
            shift: null,
            shiftID: null,
            statusId: facilityRate.statusId,
            source: RateElemenetSource.Facility,
            createdAt: facilityRate?.createdAt,
            effectiveStartDate: facilityRate?.effectiveStartDate,
            effectiveEndDate: facilityRate?.effectiveEndDate,
            rateElementId: facilityRate?.rateElementId,
            offContractId: facilityRate?.offContractId,
            reason:facilityRate?.reason,
            documentIds: facilityRate?.documentIds,
            createdBy:facilityRate?.createdBy,
            lastUpdatedBy:facilityRate?.lastUpdatedBy,
            dateLastUpdated:facilityRate?.dateLastUpdated,
            dateCreated:facilityRate?.dateCreated,
          };
    });
  }
  return dropDownOptions;
};

//Creates array of values based on shift
const getDropdownObject = (
  contractBillRates,
  skillset,
  uniqueShifts,
  effectiveDates,
  facilityOffcontractRates,
  isFlatRate,
) => {
  //No shift at Order Level
  if (uniqueShifts.length === 0) {
    const facilityRatesTravel = effectiveDates?.startDate
      ? getFacilityRatesArray(
          skillset,
          null,
          effectiveDates,
          null,
          RateElements.TravelBillRate,
          facilityOffcontractRates,
        )
      : [];
    const facilityRatesLocal = effectiveDates?.startDate
      ? getFacilityRatesArray(
          skillset,
          null,
          effectiveDates,
          null,
          RateElements.LocalBillRate,
          facilityOffcontractRates,
        )
      : [];
    const combinedOptions = contractBillRates.concat(facilityRatesTravel);
    const sortedCombinedOptions = groupAndSortOptions(combinedOptions);
    const combinedOptionsWithDefault = getcombinedOptionsWithDefault(sortedCombinedOptions, skillset, null);
    const sortedLocalFacilityRate = groupAndSortOptions(facilityRatesLocal);
    const localBillRateWithDefaults = getcombinedOptionsWithDefault(sortedLocalFacilityRate, skillset, null);
    return [
      {
        shiftName: null,
        shiftRatesArray: addIndexToItems(
          combinedOptionsWithDefault.map(obj => ({ ...obj, shift: null, shiftID: null })),
        ),
        localBillRateOptions: addIndexToItems(
          localBillRateWithDefaults.map(obj => ({ ...obj, shift: null, shiftID: null })),
        ),
      },
    ];
  }
  //Flate rate is on
  else if (uniqueShifts.length > 0 && isFlatRate) {
    const facilityRatesTravel = effectiveDates?.startDate
      ? getFacilityRatesArray(
          skillset,
          null,
          effectiveDates,
          null,
          RateElements.TravelBillRate,
          facilityOffcontractRates,
        )
      : [];
    const facilityRatesLocal = effectiveDates?.startDate
      ? getFacilityRatesArray(
          skillset,
          null,
          effectiveDates,
          null,
          RateElements.LocalBillRate,
          facilityOffcontractRates,
        )
      : [];
    const combinedOptions = contractBillRates.concat(facilityRatesTravel);
    const sortedCombinedOptions = groupAndSortOptions(combinedOptions);
    const combinedOptionsWithDefault = getcombinedOptionsWithDefault(sortedCombinedOptions, skillset, null);
    const sortedLocalFacilityRate = groupAndSortOptions(facilityRatesLocal);
    const localBillRateWithDefaults = getcombinedOptionsWithDefault(sortedLocalFacilityRate, skillset, null);
    return [
      {
        shiftName: null,
        shiftRatesArray: addIndexToItems(
          combinedOptionsWithDefault.map(obj => ({ ...obj, shift: null, shiftID: null })),
        ),
        localBillRateOptions: addIndexToItems(
          localBillRateWithDefaults.map(obj => ({ ...obj, shift: null, shiftID: null })),
        ),
      },
    ];
  } else {
    const shiftDetailsArr = uniqueShifts.map(item => {
      const facilityRatesTravel = effectiveDates?.startDate
        ? getFacilityRatesArray(
            skillset,
            getShiftId(item),
            effectiveDates,
            'v2',
            RateElements.TravelBillRate,
            facilityOffcontractRates,
          )
        : [];
      const facilityRatesLocal = effectiveDates?.startDate
        ? getFacilityRatesArray(
            skillset,
            getShiftId(item),
            effectiveDates,
            'v2',
            RateElements.LocalBillRate,
            facilityOffcontractRates,
          )
        : [];
      const combinedOptions = contractBillRates.concat(facilityRatesTravel);
      const sortedCombinedOptions = groupAndSortOptions(combinedOptions);
      const combinedOptionsWithDefault = getcombinedOptionsWithDefault(sortedCombinedOptions, skillset, null);
      const sortedLocalFacilityRate = groupAndSortOptions(facilityRatesLocal);
      const localBillRateWithDefaults = getcombinedOptionsWithDefault(sortedLocalFacilityRate, skillset, item);
      return {
        shiftName: item,
        shiftRatesArray: addIndexToItems(
          combinedOptionsWithDefault.map(obj => ({ ...obj, shift: item, shiftID: getShiftId(item) })),
        ),
        localBillRateOptions: addIndexToItems(
          localBillRateWithDefaults.map(obj => ({ ...obj, shift: item, shiftID: getShiftId(item) })),
        ),
      };
    });
    return shiftDetailsArr;
  }
};

const getDropdownObjectV2 = (
  contractBillRates,
  skillset,
  uniqueShifts,
  effectiveDates,
  facilityOffcontractRates,
  isFlatRate,
) => {
  //No shift in UI but contract has shifts OR  Shifts more than one and flat rate is on
  if (uniqueShifts.length === 0 || (isFlatRate && uniqueShifts.length > 1)) {
    const contractBillRatesCopy = [...contractBillRates];
    const contractBillRatesWithNullShift = contractBillRatesCopy.filter(item => item?.shiftId === null);
    const ContractBillRatesWithValidShift = contractBillRatesCopy.filter(item => item?.shiftId !== null);
    ContractBillRatesWithValidShift.sort((a, b) => parseFloat(a.shiftDescription) - parseFloat(b.shiftDescription));
    const contractBillRatesCombined = contractBillRatesWithNullShift.concat(ContractBillRatesWithValidShift);
    const contractBillRateOptions = contractBillRatesCombined.map((element, index) => {
      return element.shiftId
        ? {
            value: refactorValue(element.regularRate, 2),
            group: `${element.shiftDescription} Hour Shift`,
            groupShiftId: element.shiftId,
            index: index,
            disciplineId: skillset?.disciplineId,
            specialtyId: skillset?.specialtyId,
            subSpecialtyId: skillset?.subSpecialtyId,
            shift: null,
            shiftID: null,
            source: RateElemenetSource.Contract,
            rateElementId:element.rateElementId
          }
        : {
            value: refactorValue(element.regularRate, 2),
            group: `All Shifts`,
            groupShiftId: null,
            index: index,
            disciplineId: skillset?.disciplineId,
            specialtyId: skillset?.specialtyId,
            subSpecialtyId: skillset?.subSpecialtyId,
            shift: null,
            shiftID: null,
            source: RateElemenetSource.Contract,
            rateElementId:element.rateElementId
          };
    });
    const facilityRatesWithoutShiftTravel = effectiveDates?.startDate
      ? getFacilityRatesArray(
          skillset,
          null,
          effectiveDates,
          null,
          RateElements.TravelBillRate,
          facilityOffcontractRates,
        )
      : [];
    const facilityRatesWithoutShiftLocal = effectiveDates?.startDate
      ? getFacilityRatesArray(
          skillset,
          null,
          effectiveDates,
          null,
          RateElements.LocalBillRate,
          facilityOffcontractRates,
        )
      : [];
    //attach sift details to facility billrates
    const facilityRatesOptionsTravel = facilityRatesWithoutShiftTravel.map(obj => ({
      ...obj,
      shift: null,
      shiftID: null,
    }));
    const facilityRatesOptionsLocal = facilityRatesWithoutShiftLocal.map(obj => ({
      ...obj,
      shift: null,
      shiftID: null,
    }));
    const combinedOptions: any = contractBillRateOptions.concat(facilityRatesOptionsTravel as any);
    const sortedCombinedOptions = groupAndSortOptions(combinedOptions);
    const combinedOptionsWithDefaults = getcombinedOptionsWithDefault(sortedCombinedOptions, skillset, null);
    const sortedLocalFacilityRate = groupAndSortOptions(facilityRatesOptionsLocal);
    const facilityRatesOptionsWithDefault = getcombinedOptionsWithDefault(sortedLocalFacilityRate, skillset, null);
    return [
      {
        shiftName: null,
        shiftRatesArray: addIndexToItems(combinedOptionsWithDefaults),
        localBillRateOptions: addIndexToItems(facilityRatesOptionsWithDefault),
      },
    ];
  }
  //Some shifts are matching
  else {
    const shiftDetailsArr = uniqueShifts.map(shift => {
      const foundContractShiftMatch = contractBillRates.find(item => item.shiftDescription === shift);
      if (foundContractShiftMatch) {
        const contractDropdownOptions = [
          {
            value: refactorValue(foundContractShiftMatch?.regularRate, 2),
            index: 0,
            disciplineId: skillset?.disciplineId,
            specialtyId: skillset?.specialtyId,
            subSpecialtyId: skillset?.subSpecialtyId,
            shift: shift,
            shiftID: getShiftId(shift),
            group: `${shift} Hour Shift`,
            groupShiftId: foundContractShiftMatch?.shiftId,
            source: RateElemenetSource.Contract,
            rateElementId:foundContractShiftMatch.rateElementId
          },
        ];
        //call Facility dropdown
        const facilityDropdownOptionsTravel = effectiveDates?.startDate
          ? getFacilityRatesArray(
              skillset,
              getShiftId(shift),
              effectiveDates,
              'v2',
              RateElements.TravelBillRate,
              facilityOffcontractRates,
            )
          : [];
        const facilityDropdownOptionsLocal = effectiveDates?.startDate
          ? getFacilityRatesArray(
              skillset,
              getShiftId(shift),
              effectiveDates,
              'v2',
              RateElements.LocalBillRate,
              facilityOffcontractRates,
            )
          : [];

        const combinedDropdown = contractDropdownOptions.concat(facilityDropdownOptionsTravel as any);
        const sortedCombinedOptions = groupAndSortOptions(combinedDropdown);
        const combinedOptionsWithDefault = getcombinedOptionsWithDefault(sortedCombinedOptions, skillset, shift);
        const sortedLocalFacilityRate = groupAndSortOptions(facilityDropdownOptionsLocal);
        const facilityRatesOptionsWithDefault = getcombinedOptionsWithDefault(sortedLocalFacilityRate, skillset, shift);
        const singleShiftDetails = {
          shiftName: shift,
          shiftRatesArray: addIndexToItems(combinedOptionsWithDefault),
          localBillRateOptions: addIndexToItems(facilityRatesOptionsWithDefault),
        };
        return singleShiftDetails;
      } else {
        //call Facility dropdown
        const facilityDropdownOptionsTravel = effectiveDates?.startDate
          ? getFacilityRatesArray(
              skillset,
              getShiftId(shift),
              effectiveDates,
              'v2',
              RateElements.TravelBillRate,
              facilityOffcontractRates,
            )
          : [];
        const facilityDropdownOptionsLocal = effectiveDates?.startDate
          ? getFacilityRatesArray(
              skillset,
              getShiftId(shift),
              effectiveDates,
              'v2',
              RateElements.LocalBillRate,
              facilityOffcontractRates,
            )
          : [];
        const sortedLocalFacilityRate = groupAndSortOptions(facilityDropdownOptionsTravel);
        const sortedLocalFacilityRateLocal = groupAndSortOptions(facilityDropdownOptionsLocal);
        const combinedOptionsWithDefault = getcombinedOptionsWithDefault(sortedLocalFacilityRate, skillset, shift);
        const facilityRatesOptionsWithDefault = getcombinedOptionsWithDefault(
          sortedLocalFacilityRateLocal,
          skillset,
          shift,
        );

        const singleShiftDetails = {
          shiftName: shift,
          shiftRatesArray: addIndexToItems(combinedOptionsWithDefault),
          localBillRateOptions: addIndexToItems(facilityRatesOptionsWithDefault),
        };

        return singleShiftDetails;
      }
    });

    return shiftDetailsArr;
  }
};

const getBillRateForEachShift = (
  billRate,
  skillset,
  uniqueShifts,
  effectiveDates,
  facilityOffcontractRates,
  isFlatRate,
) => {
  //Case 1- Skill set not matching dropdown values not given
  if (!billRate) {
    return getDropdownObject([], skillset, uniqueShifts, effectiveDates, facilityOffcontractRates, isFlatRate);
  }

  //Case 2 No shift in Contract
  else if (billRate.shifts.length === 1 && billRate.shifts[0].shiftId === null) {
    return getDropdownObject(
      [
        {
          value: refactorValue(billRate.shifts[0].regularRate, 2),
          index: 0,
          disciplineId: skillset?.disciplineId,
          specialtyId: skillset?.specialtyId,
          subSpecialtyId: skillset?.subSpecialtyId,
          group: `All Shifts`,
          groupShiftId: null,
          source: RateElemenetSource.Contract,
          rateElementId:billRate.shifts[0].rateElementId
        },
      ],
      skillset,
      uniqueShifts,
      effectiveDates,
      facilityOffcontractRates,
      isFlatRate,
    );
  }
  //case 3 Multiple shift in the contract
  else {
    return getDropdownObjectV2(
      billRate.shifts,
      skillset,
      uniqueShifts,
      effectiveDates,
      facilityOffcontractRates,
      isFlatRate,
    );
  }
};
//Skillset matching
export const getShiftTableDetails = ({
  skillset,
  masterBillRates,
  uniqueShifts,
  effectiveDates,
  facilityOffcontractRates,
  isFlatRate,
}) => {
  const matchedSkillset = (masterBillRates || []).find(
    item =>
      item?.disciplineId === skillset?.disciplineId &&
      item?.specialtyId === skillset?.specialtyId &&
      item?.subspecialtyId === skillset?.subSpecialtyId,
  );

  return getBillRateForEachShift(
    matchedSkillset ?? null,
    skillset,
    uniqueShifts,
    effectiveDates,
    facilityOffcontractRates,
    isFlatRate,
  );
};

export const deriveShiftsTable = ({
  skillsets,
  disciplineOpts,
  masterBillRates,
  uniqueShifts,
  effectiveDates,
  facilityOffcontractRates,
  isFlatRate,
}) => {
  const disciplineArray = convertToAbbreviation(skillsets, disciplineOpts);

  return disciplineArray.map(skillsetItem => ({
    skillSet: skillsetItem.skillSetCombined,
    disciplineId: skillsetItem.disciplineId,
    specialtyId: skillsetItem.specialtyId,
    subSpecialtyId: skillsetItem.subSpecialtyId,
    disciplineAbbreviation: skillsetItem.disciplineAbbreviation,
    specialtyName: skillsetItem.specialtyName,
    subSpecialtyName: skillsetItem.subSpecialtyName,
    shiftDetails: getShiftTableDetails({
      skillset: skillsetItem,
      masterBillRates,
      uniqueShifts,
      effectiveDates,
      facilityOffcontractRates,
      isFlatRate,
    }),
  }));
};
