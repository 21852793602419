import React from 'react';
import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { OverflowTooltip } from 'app/components/Common/Header/Tooltip/OverflowTooltip';

// // TODO - @PIYAL CHATTERJEE - renamed interface to NameValueDisplay to INameValueDisplay. please check if this interface matches the props interface
// interface INameValueDisplay {
//   name: string;
//   value: string;
//   styles?: {
//     name?: {
//       fontSize?: string;
//       color?: string;
//       paddingBottom?: string;
//       padding?: string;
//     };
//     value?: {
//       fontSize?: string;
//       color?: string;
//       paddingBottom?: string;
//       fontWeight?: string;
//       padding?: string;
//       paddingRight?: string;
//     };
//   };
//   gridSize?: number;
//   enableToolTip: boolean;
// }

const useStyles = makeStyles()(theme => ({
  name: {
    fontSize: 12,
    color: '#333333',
    paddingBottom: '2%',
  },
  value: {
    fontSize: 12,
    color: '#003E68',
    paddingBottom: '2%',
    fontWeight: 500,
    padding: '2%',
    paddingRight: '10%',
  },
  container: {
    maxHeight: '30px',
  },
  maxWidthOrderCreate: {
    maxWidth: 120,
  },
}));
export const NameValueDisplay = props => {
  const { name, value, styles, gridSize, overFlowStyle, maxWidthFixed } = props;
  const { classes } = useStyles();
  const maxWidth = maxWidthFixed ? classes.maxWidthOrderCreate : '';
  const nameStyle = `${styles && styles.name}  ${classes.name}`;
  const gridSizeValue = gridSize || 5;
  const valueStyle = `${styles && styles.value} ${classes.value}`;
  const useTooltip = props.enableToolTip ? props.enableToolTip : false;

  return (
    <Grid container className={classes.container}>
      <Grid data-testid="test-name" className={maxWidth} item md={gridSizeValue}>
        <div className={nameStyle}>{name}</div>
      </Grid>
      <Grid data-testid="test-value" item md={6}>
        {useTooltip ? (
          <OverflowTooltip styles={overFlowStyle} value={value}></OverflowTooltip>
        ) : (
          <div className={valueStyle}>{value}</div>
        )}
      </Grid>
    </Grid>
  );
};
