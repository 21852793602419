import { FormControl, FormControlLabel, IconButton, Radio, RadioGroup, Grid, Box } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { ICueRecipient } from 'app/models/Tasks/Tasks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCueRecipient } from '../store/Tasks.selectors';
import CloseIcon from '@mui/icons-material/Close';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { useTheme } from 'amn-ui-core';
import moment from 'moment-timezone';
import { CueNoteTooltip } from 'app/components/Common/Tooltips/CueNoteTooltip';
import { selectUser } from 'oidc/user.selectors';
import { hasSharedProfile } from '../EditTask/helper';

const useStyles = makeStyles()(theme => ({
  form: {
    background: '#F5F5F5',
    width: '100%',
    padding: '0px 15px 5px 10px',
    overflow: 'hidden',
  },
  closeBtn: {
    display: 'flex',
    position: 'absolute',
    right: 5,
    padding: '5px',
  },
  recipientList: {
    maxHeight: '120px',
    display: 'inline-grid',
    width: '100%',
  },
  customScroll: {
    overflow: 'auto',
    padding: '0px 12px 0px 0px',
    //@ts-ignore
    overflowX: 'clip',
    justifyContent: 'flex-start',
  },
  radioBtnLbl: {
    '& .MuiFormControlLabel-root': {
      marginLeft: '0px',
    },
  },
  overflowText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '220px',
  },
}));

export default function CueRecipientList({
  setShowRecipientList,
  setSelectedRecipient,
  input,
  setInput,
  clearCueField,
  setRecipientTab,
  setCharacterCount,
  lastRecipient,
  clearErrors,
  selectedRecipient,
  setSelectedNewRecipient,
}: {
  setShowRecipientList: (e) => void;
  setSelectedRecipient: (e) => void;
  input?: string;
  setInput: (e) => void;
  clearCueField?: boolean;
  setRecipientTab: (e) => void;
  setCharacterCount: (e) => void;
  lastRecipient: any;
  clearErrors: (e) => void;
  selectedRecipient?: string;
  setSelectedNewRecipient?: (e) => void;
}) {
  const cueRecipients = useSelector(selectCueRecipient);
  const [openDialog, setOpenDialog] = useState(false);
  const { t } = useTranslation();
  const { classes } = useStyles();
  const theme = useTheme();
  const NoCue = t('notification.taskSidePanel.cueNotes.noCue');
  const userInfo = useSelector(selectUser);

  const sharedProfile = hasSharedProfile(userInfo);

  const handleClose = () => {
    if (input) {
      setOpenDialog(true);
    } else {
      setShowRecipientList(false);
      setSelectedRecipient(undefined);
      if (lastRecipient?.length > 0) {
        setRecipientTab(true);
      }
    }
  };

  useEffect(() => {
    if (clearCueField) {
      handleDialogYes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearCueField]);

  const handleDialogYes = () => {
    setShowRecipientList(false);
    if (lastRecipient?.length > 0) {
      setRecipientTab(true);
    } else {
      setRecipientTab(false);
    }
    setInput('');
    setCharacterCount(0);
    clearErrors('text');
    setSelectedNewRecipient(null);
    if (selectedRecipient === NoCue || lastRecipient?.length === 0 || !lastRecipient) {
      setSelectedRecipient(undefined);
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const getLabel = (recipient: ICueRecipient) => {
    return (
      <Box sx={{ display: 'flex' }}>
        <div className={classes.overflowText}>
          {recipient?.firstName + ' ' + recipient?.lastName}
          {recipient?.coverageDetails && (
            <span style={{ color: theme.palette.system.violet, whiteSpace: 'pre' }}>{` ${t(
              'notification.taskSidePanel.cueNotes.outOfOffice',
            )}`}</span>
          )}
        </div>
      </Box>
    );
  };

  useEffect(() => {
    return () => {
      if (lastRecipient?.length === 0 || !lastRecipient) {
        setSelectedRecipient(undefined);
      }
    };
  }, [lastRecipient, setSelectedRecipient]);

  return (
    <React.Fragment>
      <GenericDialog
        open={openDialog}
        onClose={(e, reason) => {
          if (reason === 'backdropClick') {
            e.preventDefault();
            e.stopPropagation();
            return;
          }
        }}
        fullWidth
        dialogTitleProps={{
          text: 'Add Cue Note',
        }}
        dialogContentProps={{ variant: 'text' }}
        dialogActions={[
          {
            text: t('notification.taskSidePanel.cueNotes.alertBtnCancel'),
            color: 'tertiary',
            onClick: handleDialogClose,
          },
          {
            text: t('notification.taskSidePanel.cueNotes.alertBtnYes'),
            variant: 'contained',
            color: 'primary',
            onClick: handleDialogYes,
          },
        ]}
      >
        {t('notification.taskSidePanel.cueNotes.alertContent')}
      </GenericDialog>
      <FormControl className={classes.form}>
        <IconButton color="primary" className={classes.closeBtn} onClick={handleClose} size="small">
          <CloseIcon />
        </IconButton>
        <RadioGroup
          className={classes.radioBtnLbl}
          onChange={recipient => {
            setSelectedRecipient(recipient.target.value);
            setSelectedNewRecipient(recipient.target.value);
          }}
        >
          <FormControlLabel
            value={t('notification.taskSidePanel.cueNotes.noCue')}
            control={<Radio disableRipple />}
            label="No Cue"
          />
          <Grid className={`${classes.recipientList} ${cueRecipients.length > 3 && classes.customScroll}`}>
            {cueRecipients.length > 0 &&
              cueRecipients.map((recipient: ICueRecipient, idx) =>
                recipient?.coverageDetails && !sharedProfile ? (
                  <CueNoteTooltip
                    key={idx}
                    placement="bottom"
                    tooltipContent={`${moment
                      .tz(recipient?.coverageDetails[0]?.startDate, 'America/Los_Angeles')
                      .format('L')} - ${moment
                      .tz(recipient?.coverageDetails[0]?.endDate, 'America/Los_Angeles')
                      .format('L')} Covered by: ${recipient?.coverageDetails[0]?.coveringEmployeeFirstName} ${
                      recipient?.coverageDetails[0]?.coveringEmployeeLastName
                    }`}
                  >
                    <FormControlLabel
                      key={recipient.employeeId}
                      value={recipient.employeeId.toString()}
                      control={<Radio disableRipple />}
                      label={getLabel(recipient)}
                      style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        textAlign: 'left',
                      }}
                    />
                  </CueNoteTooltip>
                ) : (
                  <FormControlLabel
                    key={recipient.employeeId}
                    value={recipient.employeeId.toString()}
                    control={<Radio disableRipple />}
                    label={recipient?.firstName + ' ' + recipient?.lastName}
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      textAlign: 'left',
                    }}
                  />
                ),
              )}
          </Grid>
        </RadioGroup>
      </FormControl>
    </React.Fragment>
  );
}
