import React from 'react';
import { StepIconProps } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';

const useCustomStepIconStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },

  circle: {
    width: 28,
    height: 28,
    borderRadius: '50%',
    border: '1px solid #000',
    textAlign: 'center',
    font: '14px',
    fontWeight: 'bold',
    borderColor: '#006FB9',
  },
  circleCommon: {
    background: 'white',
    color: '#999999',
    borderColor: '#999999',
  },
  circleCompleted: {
    background: '#006FB9',
    color: 'white',
    borderColor: '#006FB9',
  },
  circleActive: {
    background: 'white',
    color: '#006FB9',
    borderColor: '#006FB9',
  },
}));

export default function CustomStepIcon(props: StepIconProps) {
  const { classes } = useCustomStepIconStyles();
  const { completed, icon, active } = props;

  return (
    <div className={classes.root}>
      <div
        className={
          completed
            ? `${classes.circle} ${classes.circleCompleted}`
            : active
            ? `${classes.circle} ${classes.circleActive}`
            : `${classes.circle} ${classes.circleCommon}`
        }
      >
        {icon}
      </div>
    </div>
  );
}
