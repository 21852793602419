import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { serviceActions } from './async-actions';
import { IReportsView } from './types';
import { IR225InputDetails, IR225ReportResponse } from 'app/models/ReportPages/IR225Report';


export const initialState: IReportsView = {
  r225Report: { response: [], inputDetails: null },
  r448Report: { response: [] },
  inputDetails: null,
};

const reportsViewSlice = createSlice({
  name: 'reportsView',
  initialState,
  reducers: {
    setR225ReportDetails(state, action: PayloadAction<IR225ReportResponse>) {
      return { ...state, r225Report: { response: action.payload.response, inputDetails: action.payload.inputDetails } };
    },
    setR448ReportDetails(state, action: PayloadAction<any>) {
      return { ...state, r448Report: { response: action.payload.response } };
    },
    setInputDetails(state, action: PayloadAction<IR225InputDetails>) {
      state.inputDetails = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const { name: reportsViewSliceKey, reducer: reportDetailsReducer } = reportsViewSlice;

export const reportsViewActions = { ...reportsViewSlice.actions, ...serviceActions };
