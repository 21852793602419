/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { TextField, Grid, CircularProgress } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { Row } from '../../ProfileTab/Cards/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { RowContainer } from '@AMIEWEB/Candidate/Common/RowContainer';

const useStyles = makeStyles()(theme => ({
  textareastyle: {
    padding: '0px',
    overflowWrap: 'break-word',
    backgroundColor: '#fff !important',
    '&:focus-visible': {
      outline: 'none',
      background: '#f2f2f2',
    },
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    },
  },
  inlineBoxEditItemPadding: {
    padding: '0 0.4rem',
  },
  inlineBoxEditMainAcceptItem: {
    'padding-right': '0.2rem',
    '& svg': {
      color: '#006FB9',
      width: '20px',
      height: '20px',
      cursor: 'pointer',
    },
  },
  inlineBoxEditMainRejectItem: {
    'padding-left': '0.2rem',
    '& svg': {
      color: '#8f9a8e',
      width: '20px',
      height: '20px !important',
      cursor: 'pointer',
    },
  },
  inlineBoxEditInputItem: {
    width: 220,
    'border-top': '0',
    'border-left': '0',
    'border-right': '0',
    'border-bottom-color': '#bababa',
    '&:focus-visible': {
      outline: 'none',
      background: '#f2f2f2',
    },
  },
  saveSuccessText: {
    fontSize: '11px',
    paddingLeft: '4px',
    justifyContent: 'center',
    color: '#008000',
  },
  saveFailedText: {
    fontSize: '11px',
    justifyContent: 'center',
    color: '#ff0000',
  },
}));

export const SellingNotes = props => {
  const { classes } = useStyles();
  const [textAreaContent, setTextAreaContent] = useState<string>(props.data);

  useEffect(() => {
    setTextAreaContent(props.data);
  }, []);

  const changeValue = value => {
    setTextAreaContent(value);
    props.handleChangeValue(value);
  };
  const handlesave = () => {
    props.handleSave(props.data);
  };
  const close = () => {
    props.setIsHandleClick(false);
    props.resetValue();
  };

  const TextArea = React.useMemo(() => {
    return (
      <>
        <TextField
          variant="filled"
          fullWidth
          multiline
          ref={ref => ref && ref.focus()}
          onFocus={e => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
          InputProps={{
            className: classes.textareastyle,
            disableUnderline: !props.isHandleClick,
            readOnly: !props.isEditable,
          }}
          className={classes.inlineBoxEditInputItem}
          InputLabelProps={{
            className: ``,
          }}
          defaultValue={props.data}
          hiddenLabel
          onChange={event => {
            changeValue(event.target.value);
          }}
          error={props.validationErrorText}
          type={'string'}
          inputRef={props.textInput}
          onMouseDown={event => {
            if (!props.isHandleClick) event.preventDefault();
          }}
          {...props}
        />
      </>
    );
  }, [props.isHandleClick, props.isEditable, textAreaContent, props.data]);
  return (
    <Grid container>
      <Grid item style={{ paddingTop: 2 }}>
        <>
          <RowContainer>
            <Row>
              <div>{TextArea}</div>
            </Row>
            {/* Save status Icons-Progrss,Saved and Failed */}
            <Row style={{ width: 'fit-content' }}>
              {props.saveStatus == 1 && <CircularProgress style={{ width: '12px', height: '12px' }} />}
              {props.saveStatus == 2 && (
                <>
                  <CheckIcon color="primary" style={{ width: '15px', height: '15px', color: '#008000' }} />
                  <label className={classes.saveSuccessText}>{`Saved`}</label>
                </>
              )}
              {props.saveStatus == 0 && props.saveStatus !== '' && (
                <>
                  <CloseIcon color="primary" style={{ width: '15px', height: '15px', color: '#ff0000' }} />
                  <label className={classes.saveFailedText}>{`Failed to update`}</label>
                </>
              )}
              {props.validationErrorText && (
                <>
                  <label className={classes.saveFailedText}>{`Character Limit Reached`}</label>
                </>
              )}
            </Row>
          </RowContainer>
        </>
      </Grid>
      <Grid item>
        {/* Render only if clicked inside Text field */}
        {props.isHandleClick && (
          <span>
            {/* save Icon */}
            <span
              className={`${classes.inlineBoxEditItemPadding} ${classes.inlineBoxEditMainAcceptItem}`}
              onClick={() => {
                handlesave();
              }}
              aria-hidden="true"
            >
              <CheckIcon color="primary" data-testid="saveIcon" />
            </span>
            {/* close Icon */}
            <span
              className={`${classes.inlineBoxEditItemPadding} ${classes.inlineBoxEditMainRejectItem}`}
              onClick={() => {
                close();
              }}
              onKeyDown={() => {
                close();
              }}
              aria-hidden="true"
            >
              <CloseIcon data-testid="closeIcon" />
            </span>
          </span>
        )}
      </Grid>
    </Grid>
  );
};
