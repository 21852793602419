import React, { useEffect, useState } from 'react';
import { Grid, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { XGrid } from '@AMIEWEB/Common/XGrid/XGrid';
import { extractRows, getExistngRfoColumns } from './UnitUtil';
import { ExistingUnitPlacementGlance } from './ExistingUnitPlacementGlance';
import {
  selectExistingPlacements,
  selectReadyToSubmitPlacementIds,
} from 'store/redux-store/unit-preferences/selectors';
import { useSelector } from 'react-redux';

const useStyles = makeStyles()({
  confirmationText: { fontWeight: 450 },
});

export const ExistingUnitRFOs = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const readyToSubmitPlacementIds = useSelector(selectReadyToSubmitPlacementIds);
  const existingPlacements = useSelector(selectExistingPlacements);

  const [rows, setRows] = useState<any[]>([]);

  const [sortModel] = React.useState<any>([
    {
      field: 'statusUpdatedDate',
      sort: 'asc',
    },
  ]);

  useEffect(
    () => setRows(extractRows(existingPlacements, readyToSubmitPlacementIds)),
    [existingPlacements, readyToSubmitPlacementIds],
  );

  return (
    <Grid container spacing={2} style={{ marginBottom: '-3px' }}>
      <Grid item xs={12} style={{ marginTop: existingPlacements?.length ? '10px' : 0 }}>
        <Typography variant="body1">
          {existingPlacements?.length
            ? t('facility.unitPreference.existingPlacementsFoundMaxFile')
            : t('facility.unitPreference.existingPlacementsFoundMaxFile')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" classes={{ body1: classes.confirmationText }}>
          {t('facility.unitPreference.existingPlacementsFoundconfirmText')}
        </Typography>
      </Grid>
      {existingPlacements?.length ? (
        <Grid container item xs={12}>
          <ExistingUnitPlacementGlance />
        </Grid>
      ) : null}
      {existingPlacements?.length ? (
        <Grid item xs={12}>
          <XGrid
            rows={rows ?? []}
            columns={getExistngRfoColumns(t, readyToSubmitPlacementIds)}
            title={'Placements'}
            rowCount={existingPlacements?.length}
            disableSelectionOnClick
            pagination={false}
            hideFooter
            autoHeight
            sortModel={sortModel}
            inDialog
            toolbarProps={{
              refreshButton: false,
              resetButton: false,
            }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};
