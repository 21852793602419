import { createAction } from '@reduxjs/toolkit';

const getWorkExperienceData = createAction<{ candidateId: number; brandId: number }>('GET_CANDIDATE_WORK_EXPERIENCE');
const updateWorkExperience = createAction<any>('UPDATE_CANDIDATE_WORK_EXPERIENCE');
const updateWorkExperienceBulk = createAction<any>('UPDATE_CANDIDATE_WORK_EXPERIENCE_BULK');

export const serviceActions = {
  getWorkExperienceData: getWorkExperienceData,
  updateWorkExperience: updateWorkExperience,
  updateWorkExperienceBulk: updateWorkExperienceBulk,
};
