/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui';
import { Tooltip } from 'amn-ui-core';
import React from 'react';

const useStyles = makeStyles()({
  arrow: {
    color: '#FFFFFF',
  },
  tooltip: {
    backgroundColor: '#FFFFFF',
    fontSize: '12px',
    border: '1px solid #E3DFDF',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '2px',
    padding: '8px 10px',
    margin: '0px',
    color: '#333333',
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

interface TaskTooltipProps {
  tooltipContent?: any;
  children?: any;
  placement?: 'top' | 'right' | 'bottom' | 'left';
}
export function TaskTooltip(props: TaskTooltipProps) {
  const { classes } = useStyles();

  return (
    <Tooltip
      children={props.children}
      title={props.tooltipContent}
      arrow
      classes={classes}
      enterDelay={1000}
      placement={props.placement}
    />
  );
}
