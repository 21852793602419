import { missingField } from 'app/constants';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RequirementsRow } from '../../TableComponents/TableComponents';
import {
  selectEditableRequirementTypes,
  selectServiceFailures,
  selectSubmissionInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { usePromiseTracker } from 'react-promise-tracker';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { DateOfBirth as DOB } from 'app/components/Placement/PlacementDetails/Edit/Components/DateOfBirth';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import { TableSection } from '../../../customComponents/TableSection';
import { getRequirementCheck } from '../../../helper';
import { Typography } from 'amn-ui-core';
import { useDecision } from '@optimizely/react-sdk';
import { ff_placement_ui_requirement_check } from 'app/constants/FeatureFlags/Placement/Keys';

export const DateOfBirth = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [requirementCheckFlag] = useDecision(ff_placement_ui_requirement_check);

  const { promiseInProgress: promiseInProgressOrderDob } = usePromiseTracker({
    area: 'placement-submission-DOB-order-requirement',
    delay: 0,
  });
  const { promiseInProgress } = usePromiseTracker({
    area: 'pplacement-submission-mice-details-order-requirement',
    delay: 0,
  });
  const {
    orderReq: {
      order: { orderDob: orderDOB },
    },
    requirementFilter,
  } = useSelector(selectSubmissionInfo);
  const { orderDob } = useSelector(selectEditableRequirementTypes);
  const { dobStatusOrderRequirement: serviceFailed } = useSelector(selectServiceFailures);
  const { placementId } = useContext(AddOnContext);
  const { orderDob: orderDOBValue } = useSelector(selectEditableRequirementTypes);

  const { display } = useRequirementFilter(
    'submission-order',
    requirementFilter,
    requirementCheckFlag.enabled && orderDOB?.type ? orderDob?.requirement : null,
  );

  return (
    <RequirementsRow
      id="placement-submission-order-dob"
      title={t('placement.profile.summary.submission.order.dateOfBirth.title')}
      requirementCell={
        orderDOB?.type && requirementCheckFlag.enabled ? (
          <RowKey orderDOBValue={orderDOBValue?.requirement ?? -1} t={t} />
        ) : (
          <Typography variant="body1">{missingField}</Typography>
        )
      }
      loading={requirementCheckFlag.enabled ? promiseInProgressOrderDob : promiseInProgress}
      hasError={serviceFailed}
      candidateCell={<DOB />}
      onTryAgain={() =>
        requirementCheckFlag.enabled
          ? dispatch(placementSummaryActions.getDOBStatusOrderRequirement({ placementId, isRetryingService: true }))
          : dispatch(placementSummaryActions.getMiscDetailsOrderRequirement({ placementId, isRetryingService: true }))
      }
      display={display}
    />
  );
};

const RowKey = ({ orderDOBValue, t }) => (
  <TableSection
    items={[
      {
        icon: !!orderDOBValue ? getRequirementCheck(orderDOBValue) : null,
        primaryContent: !!orderDOBValue
          ? t('placement.profile.summary.submission.order.dateOfBirth.line1')
          : missingField,
      },
    ]}
  />
);
