import { Autocomplete, Checkbox, Chip, TextField, Theme } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { colors } from 'styles/styleVariables';
import { makeStyles } from 'tss-react/mui';
import { selectOrderBonusTab } from '@AMIEWEB/Order/Store/Order.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { orderDataActions } from '@AMIEWEB/Order/Store/Order.redux';

const useStyles = makeStyles<{ multiple: string }>()((theme: Theme, { multiple }) => ({
  textField: {
    '& .MuiInputBase-root': {
      minHeight: multiple ? 130 : 'auto',
      alignItems: multiple ? 'flex-start' : 'center',
    },
    '& .MuiFormLabel-root-MuiInputLabel-root': {
      color: theme.palette.system.black,
    },
    '& .MuiFormControl-root-MuiTextField-root': {
      backgroundColor: theme.palette.system.lightGrayFaded,
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: theme.palette.system.lightGrayFaded,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: theme.palette.system.black,
    },
    '& .MuiFilledInput-input.Mui-disabled': {
      WebkitTextFillColor: `${colors.black}`,
    },
    '& .MuiIconButton-root:hover': { color: `${colors.amnLightBlue}`, background: 'none' },
    '& .MuiAutocomplete-endAdornment': { right: '1.5% !important' },
    '& .MuiTouchRipple-root:focus': {
      color: theme.palette.system.navyBlue,
      background: 'none',
    },
  },
  chipsContainer: { overflowY: 'auto', maxHeight: 76, minWidth: 300 },

  chip: {
    background: `${colors.white}`,
    opacity: '1 !important',

    border: `1px solid ${colors.amnLightGrey}`,
    margin: '4px',
    '& .MuiChip-deleteIcon': {
      color: `${colors.amnDarkGrey}`,
    },
    '&:hover .MuiChip-deleteIcon': {
      color: `${colors.amnDarkGrey}`,
    },
  },
}));

export const OrderBonusAutoComplete = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { control, setValue } = useFormContext();
  const { name, options, multiple, disabled } = props;
  const { classes } = useStyles({ multiple });
  const orderBonusShifts = useSelector(selectOrderBonusTab).orderBonusShiftList;

  useEffect(() => {
    if (orderBonusShifts?.length > 0 && multiple) {
      setValue(name, orderBonusShifts);
      dispatch(orderDataActions.setUpdateOrderBonusShifts(false));
    } else if (multiple) {
      setValue(name, []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBonusShifts, multiple]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ ref, onChange, value }) => (
        <Autocomplete
          forcePopupIcon={!multiple}
          popupIcon={!multiple ? <ExpandMoreIcon sx={{ width: '30px', height: '30px' }} /> : null}
          options={options || []}
          multiple={multiple}
          disabled={disabled}
          renderInput={params => (
            <TextField
              {...params}
              className={classes.textField}
              variant="filled"
              label={t(`order.orderBonusTab.formFields.${name}`)}
            />
          )}
          disableClearable
          disableCloseOnSelect={multiple ? true : false}
          value={value}
          getOptionLabel={(option: { ID: number; Description: string }) => option?.Description}
          onChange={(e, newValue) => {
            if (multiple) {
              onChange(newValue);
              dispatch(orderDataActions.setUpdateOrderBonusShifts(true));
            } else {
              onChange(newValue);
              props?.onChange(newValue);
            }
          }}
          renderTags={(values, getTagProps) =>
            multiple ? (
              <div className={classes.chipsContainer}>
                {values?.map((option, index) => (
                  <Chip
                    key={index}
                    {...getTagProps({ index })}
                    label={option.Description}
                    className={classes.chip}
                    deleteIcon={<CloseIcon sx={{ width: '15px', height: '15px' }} />}
                    onDelete={() => {
                      onChange(value?.filter(entry => entry !== option));
                      dispatch(orderDataActions.setUpdateOrderBonusShifts(true));
                    }}
                  />
                ))}
              </div>
            ) : null
          }
          renderOption={(params, option) =>
            multiple ? (
              <li {...params} id={option.ID}>
                <Checkbox size="small" checked={value?.filter(x => x.Description === option.Description)?.length > 0} />
                {option.Description}
              </li>
            ) : (
              <li {...params} id={option.ID}>
                {option.Description}
              </li>
            )
          }
        />
      )}
    />
  );
};
