import { Box, Grid} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { candidateDetailsSelection } from '../../Profile/CandidateDetails.selector';
import { convertToPST } from 'app/helpers/dateHelper';

const useStyles = makeStyles()(theme => ({
  container: {
    paddingBottom: '64px',
    paddingTop: '8px',
    display: 'flex',
    flexDirection: 'row',
    height: '48px',
    gap: '10px',
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    height: '48px',
    maxHeight: '48px',
    border: '1px solid #d0d0d0',
    borderRadius: '5px',
    paddingRight: '5px',
  },
  value: {
    background: theme.palette.framework.system.whisper,
    color: '#333333',
    borderRadius: '5px 0 0 5px',
    fontWeight: '500',
    fontSize: '17px',
    padding: '10px',
    overflow: 'hidden'
  },
  name: {
    fontSize: '12px',
    overflow: 'hidden',
    maxWidth:'98px',
    paddingLeft: '11px',
    paddingRight: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  lastUpdated: {
    width: '200px',
  },
  calls: {
    width: '110px',
  },
  spoke: {
    width: '150px',
  },
  email: {
    width: '105px',
  },
  openRate: {
    width: '160px',
  },
}));

const Kpis = () => {
  const { classes } = useStyles();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const kpis = candidateDetails.kpis;
  const [fields, setFields] = useState<any>([]);

  useEffect(() => {
    setFields([
      {
        name: 'LAST CONTACTED',
        value: kpis?.lastContacted ? convertToPST(kpis?.lastContacted, 'MM/DD/YYYY') : '-',
        class: classes.lastUpdated,
      },
      {
        name: 'CALLS',
        value: kpis?.numberOfCalls,
        class: classes.calls,
      },
      {
        name: 'SPOKE W/ CANDIDATE %',
        value: kpis?.callSpokenRate ? `${kpis?.callSpokenRate}%` : '-',
        class: classes.spoke,
      },
      {
        name: 'EMAILS',
        value: kpis?.numberOfEmails,
        class: classes.email,
      },
      {
        name: 'OPEN RATE %',
        value: kpis?.emailOpenRate ? `${kpis?.emailOpenRate}%` : '-',
        class: classes.openRate,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpis]);

  return (
    <Grid  spacing={2} className={classes.container} wrap="nowrap"  sx={{ overflow: 'hidden' }}>
      {fields.length > 0 &&
        fields.map(field => (
          <Grid item className={classes.box}>
            <Box className={classes.value}>
              {field.value}
            </Box>
            <Box className={classes.name}>
              {field.name}
            </Box>
          </Grid>
        ))}
    </Grid>
  );
};

export default Kpis;
