import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TaskClose from 'app/assets/images/Tasks/icon-task-close.svg';
import TaskOpen from 'app/assets/images/Tasks/icon-task-open.svg';
import { TaskStatus } from 'app/models/Tasks/Tasks';
import { WhiteTooltip } from 'app/components/Common/Tooltips/WhiteTooltip';

export const StatusIcon = ({
  statusId,
  isStatusRevertible = false,
}: {
  statusId?: number;
  isStatusRevertible?: boolean;
}) => {
  const { t } = useTranslation();
  const displayStatusIcon = useMemo(() => {
    switch (statusId) {
      case TaskStatus.Completed:
        return (
          <React.Fragment>
            {isStatusRevertible ? (
              <img src={TaskOpen} style={{ height: '24px', width: '24px' }} alt ='icon' />
            ) : (
              <WhiteTooltip arrow title={t('notification.taskStatuses.completedStatus') || TaskStatus[3]}>
                <CheckCircleIcon style={{ fill: '#0198D7', height: '24px', width: '24px' }} />
              </WhiteTooltip>
            )}
          </React.Fragment>
        );
      case TaskStatus.Cancelled:
        return (
          <React.Fragment>
            {isStatusRevertible ? (
              <img src={TaskOpen} style={{ height: '24px', width: '24px' }} alt ='icon'/>
            ) : (
              <WhiteTooltip arrow title={t('notification.taskStatuses.cancelledStatus') || TaskStatus[4]}>
                <img src={TaskClose} style={{ height: '24px', width: '24px' }} alt ='icon' />
              </WhiteTooltip>
            )}
          </React.Fragment>
        );
      default:
        return <img src={TaskOpen} style={{ height: '24px', width: '24px' }} />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusId, isStatusRevertible]);

  return displayStatusIcon;
};
