import { useDecision } from '@optimizely/react-sdk';
import { ff_maintaince_key } from 'app/constants/FeatureFlags/Global/keys';
import React, { createContext, useState, useEffect, ReactNode, FC } from 'react';

// Define the shape of your context's value
interface VisibilityContextType {
  isVisible: boolean;
}

// Create the context with a default value of `undefined`
const VisibilityContext = createContext<VisibilityContextType | undefined>(undefined);

interface VisibilityProviderProps {
  children: ReactNode;
}

export const VisibilityProvider: FC<VisibilityProviderProps> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(!document.hidden);
  const [inMaintainance] = useDecision(ff_maintaince_key);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [document.hidden]);

  return (
    <VisibilityContext.Provider value={{ isVisible: !inMaintainance.enabled && isVisible }}>
      {children}
    </VisibilityContext.Provider>
  );
};

export default VisibilityContext;
