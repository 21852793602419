import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { Typography } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  dialog: {
    top: 0,
    marginBottom: '20px',
    '& .MuiDialog-paperWidthSm': {
      width: '390px',
    },
  },
  dialogueContent: {
    padding: 0,
    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .dialogContentRoot': {
      padding: 0,
    },
  },
  text: {
    fontSize: '14px',
    color: theme.palette.components.typography.color,
    padding: '0px 30px',
  },
}));
export interface IConfirmationDialogProps {
  dialogeOpen: boolean;
  keepChanges?: () => void;
  handleDiscard?: () => void;
  handleSave?: () => void;
  dialogeText?: string;
  dialogTitle?: string;
  dialogActions?: any;
}
export const ConfirmationDialog = ({
  dialogeOpen,
  keepChanges,
  handleDiscard,
  dialogeText = '',
  dialogTitle = '',
  dialogActions,
}: IConfirmationDialogProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <GenericDialog
        open={dialogeOpen}
        className={classes.dialog}
        dialogTitleProps={{
          text: dialogTitle ? dialogTitle :  t(`autoTaskManagement.dialogModal.discardChanges`),
        }}
        dialogContentProps={{
          classes: {
            root: classes.dialogueContent,
          },
        }}
        dialogActions={
          dialogActions ?? [
            {
              text: t(`autoTaskManagement.button.cancel`),
              onClick: keepChanges,
            },
            {
              text:t(`autoTaskManagement.button.yes`),
              variant: 'contained',
              color: 'primary',
              onClick: handleDiscard,
            },
          ]
        }
      >
        <Typography className={classes.text}>
          {dialogeText ? dialogeText : t('userManagement.discardChangesMessage')}
        </Typography>
      </GenericDialog>
    </>
  );
};
