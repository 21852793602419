import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Grid, IconButton, TextField, Typography, useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { ChipsContainer } from 'app/ComponentLibrary/Filter';
import { useDispatch, useSelector } from 'react-redux';
import { getUserManagementActions } from '../store/userManagement.redux';
import {
  selectAssociationsDiffer,
  selectChildAssociationsChips,
  selectEmpCardInfo,
  selectEmpInfo,
  selectDialogeOpen,
  selectedTabValue,
  selectInitialChildAssociationsChips,
  selectInitialParentAssociationChips,
  selectParentAssociationChips,
  selectedEmployeeAssociationTypeOptions,
  selectedEmployeeAssociations,
} from '../store/userManagement.selector';
import { IAssociationChip } from 'app/models/Notification/Notification';
import { getSearchLookups } from 'app/services/SharedServices/SharedServices';
import { IAssociationFields, doAssociationsDiffer } from '../Helper';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { selectUser } from 'oidc/user.selectors';
import { useTranslation } from 'react-i18next';
import { CustomTooltip, TypeAhead } from '@AMIEWEB/Common';
import { DiscardChangesDialog } from '../DiscardChangesDialog/DiscardChangesDialog';
import { theme } from 'styles/global-styles';

const useStyles = makeStyles()(theme => ({
  associationContainer: {
    height: 'calc(100vh - 465px)',
    '@media (max-width: 980px)': {
      height: 'calc(100vh - 505px)',
    },
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '0px 10px 0px 5px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  validationLoader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: '50%',
  },
  buttonIcon: {
    fontSize: 44,
  },
  iconButton: {
    color: theme.palette.system.navyBlue,
    height: 49,
    margin: '10px 0px 0px 0px',
    padding: 0,
    width: '40px',
  },
  typeAheadCellWrapper: {
    '& .MuiFilledInput-root': {
      height: '48px',
      backgroundColor: `${theme.palette.framework.system.whisper} !important`,
    },
  },
  customTextField: {
    '& .MuiFilledInput-root': {
      height: '48px',
      backgroundColor: `${theme.palette.framework.system.whisper} !important`,
    },
  },
  dialogueActions: {
    backgroundColor: theme.palette.framework.system.whisper,
  },
  dialogueContent: {
    padding: 0,
    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .dialogContentRoot': {
      padding: 0,
    },
  },
  labelText: {
    marginLeft: '12px',
    fontSize: 'normal normal medium 14px/26px Roboto',
    fontWeight: 700,
  },
  relationship: {
    width: '65%',
    padding: '10px 10px 0 0px',
  },
  userName: {
    width: '35%',
    padding: '10px 10px 0 0px',
  },
  flex: {
    display: 'flex',
    marginLeft: '12px',
    height: '48px',
  },
  chips: {
    marginTop: '20px !important',
    margin: '12px',
    width: '98%',
    display: 'flex',
  },
  chipsDisable: {
    margin: '12px',
    width: '98%',
    display: 'flex',
    pointerEvents: 'none',
  },
  loader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  loaderText: {
    marginTop: '10px',
    fontStyle: 'italic',
    fontSize: '14px',
    fontWeight: 400,
    color: '#333333',
  },
  cancelButton: {
    '&:hover': {
      backgroundColor: ' #707070',
    },
    color: '#fff',
    padding: '4px 9px',
    fontSize: '0.775rem',
    backgroundColor: '#8E9195',
  },
  actionButton: {
    '&:hover': {
      backgroundColor: '#CCCCCC',
    },
    color: '#fff',
    padding: '4px 9px',
    fontSize: '0.775rem',
    backgroundColor: '#CCCCCC',
    pointerEvents: 'none',
  },
  saveButton: {
    color: '#fff',
    padding: '4px 9px',
    fontSize: '0.775rem',
    marginRight: '8px',
  },
  button: {
    margin: '0px 10px 4px 0px',
  },
  saveDisableButton: {
    '&:hover': {
      backgroundColor: '#CCCCCC',
    },
    color: '#fff',
    padding: '4px 9px',
    fontSize: '0.775rem',
    backgroundColor: '#CCCCCC',
    marginRight: '8px',
    pointerEvents: 'none',
  },
}));

interface IselectedData {
  isValid: boolean;
  newLabel: string;
  associationTypeId: number;
  associationWithEmployeeId: number;
  employeeId: number;
}

const Associations = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const formMethods = useForm<IAssociationFields>({
    mode: 'onSubmit',
  });

  const {
    control,
    getValues,
    setValue,
    formState: { isDirty },
  } = formMethods;

  const employeeAssociationTypeOptions = useSelector(selectedEmployeeAssociationTypeOptions);
  const empCardInfo = useSelector(selectEmpCardInfo);
  const employeeAssociations = useSelector(selectedEmployeeAssociations);
  const empInfo = useSelector(selectEmpInfo);
  const parentAssociationChips = useSelector(selectParentAssociationChips);
  const childAssociationsChips = useSelector(selectChildAssociationsChips);
  const initialParentAssociationChips = useSelector(selectInitialParentAssociationChips);
  const associationsDiffer = useSelector(selectAssociationsDiffer);
  const initialChildAssociationsChips = useSelector(selectInitialChildAssociationsChips);
  const { userInfo } = useSelector(selectUser);
  const dialogeOpen = useSelector(selectDialogeOpen);
  const tabValue = useSelector(selectedTabValue);
  const [isEnabled, setIsEnabled] = useState<any>({ parent: false, child: false });
  const [associations, setAssociations] = useState<any>({
    ParentRelationship: null,
    ParentUserName: null,
    ChildRelationship: null,
    ChildUserName: null,
  });
  const authorized = Authorized(
    [
      userRoles.credentialing_Leadership,
      userRoles.accountManagement_Leadership,
      userRoles.recruitment_Leadership,
      userRoles.customerSupport_Leadership,
      // userRoles.customerSupport_TeamMember, We have removed this role as part of userStory : 348138
      userRoles.supportAdmin,
    ],
    userInfo,
  );
  const [parentUsernames, setParentUsernames] = useState<any[]>([]);
  const [childUsernames, setChildUsernames] = useState<any[]>([]);

  const fetchFilterSpecs = async (inputValue, setUsernames) => {
    const usernamesWithId = inputValue
      ? await getSearchLookups('usernameswithid', inputValue)
      : await getSearchLookups('usernameswithid', '');

    const associationTypeOptions: IAssociationChip[] =
      usernamesWithId && Array.isArray(usernamesWithId)
        ? usernamesWithId.map(option => ({
            label: option.name,
            value: option.value,
          }))
        : [];

    setUsernames(associationTypeOptions);
  };

  useEffect(() => {
    fetchFilterSpecs('', setParentUsernames);
    return () => {
      dispatch(getUserManagementActions.setTabValue(undefined));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchFilterSpecs('', setChildUsernames);
  }, []);

  useEffect(() => {
    const parentAssociations = employeeAssociations?.parentAssociations || [];
    const parentChips = parentAssociations.map(option => ({
      label: `${option.description} - ${option.username}`,
      enableDelete: true,
      associationTypeId: option.associationTypeId,
      employeeId: option.employeeId,
      associationWithEmployeeId: option.associationWithEmployeeId,
    }));

    dispatch(getUserManagementActions.setParentAssociationChips(parentChips));
    dispatch(getUserManagementActions.setInitialParentAssociationChips(parentChips));
    const childAssociations = employeeAssociations?.childAssociations;
    const childChips: IAssociationChip[] =
      childAssociations && Array.isArray(childAssociations)
        ? childAssociations.map(option => ({
            label: `${option.description} - ${option.username}`,
            enableDelete: true,
            associationTypeId: option.associationTypeId,
            employeeId: option.employeeId,
            associationWithEmployeeId: option.associationWithEmployeeId,
          }))
        : [];

    dispatch(getUserManagementActions.setChildAssociationsChips(childChips));
    dispatch(getUserManagementActions.setInitialChildAssociationsChips(childChips));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeAssociations]);

  const handleParentUsernamesChange = (inputValue: string | undefined) => {
    if (inputValue !== undefined && inputValue.length >= 3) {
      fetchFilterSpecs(inputValue, setParentUsernames);
    } else if (inputValue === '') {
      fetchFilterSpecs('', setParentUsernames);
    }
  };

  const handleChildUsernamesChange = (inputValue: string | undefined) => {
    if (inputValue !== undefined && inputValue.length >= 3) {
      fetchFilterSpecs(inputValue, setChildUsernames);
    } else if (inputValue === '') {
      fetchFilterSpecs('', setChildUsernames);
    }
  };

  useEffect(() => {
    dispatch(getUserManagementActions.employeeAssociationLookups());
  }, [dispatch, empCardInfo]);

  const checkDuplicateChip = () => {
    const { parentData, childData } = getSelectedData();
    const isParentDuplicate =
      !!(parentAssociationChips || [])?.find(item => item?.label === parentData.newLabel) ||
      !!(childAssociationsChips || [])?.find(item => item?.label === parentData.newLabel);
    const isChildDuplicate =
      !!(childAssociationsChips || [])?.find(item => item?.label === childData.newLabel) ||
      !!(parentAssociationChips || [])?.find(item => item?.label === childData.newLabel);
    setIsEnabled({ ...isEnabled, child: isChildDuplicate, parent: isParentDuplicate });
  };

  const getSelectedData = () => {
    let data = {
      parentData: {} as IselectedData,
      childData: {} as IselectedData,
      isParentValid: false,
      isChildValid: false,
    };
    if (typeof associations === 'object' && associations !== null) {
      const [parentRelationship, parentUserName, childRelationship, childUserName] = [
        associations.ParentRelationship,
        associations.ParentUserName,
        associations.ChildRelationship,
        associations.ChildUserName,
      ];
      const isParentValid = !!(parentRelationship && parentUserName);
      const isChildValid = !!(childRelationship && childUserName);
      data = {
        ...data,
        parentData: {
          ...data.parentData,
          isValid: isParentValid,
          newLabel: isParentValid ? `${parentRelationship.name} - ${parentUserName.label}` : '',
          associationTypeId: isParentValid ? parseInt(parentRelationship.value) : null,
          associationWithEmployeeId: isParentValid ? parseInt(parentUserName.value) : null,
          employeeId: isParentValid ? empInfo?.employeeId : null,
        },
        childData: {
          ...data.childData,
          isValid: isChildValid,
          newLabel: isChildValid ? `${childRelationship.name} - ${childUserName.label}` : '',
          associationTypeId: isChildValid ? parseInt(childRelationship.value) : null,
          associationWithEmployeeId: isChildValid ? empInfo?.employeeId : null,
          employeeId: isChildValid ? parseInt(childUserName.value) : null,
        },
      };
    }
    return data;
  };

  const handleAddChips = (fieldName: string) => {
    if (isDirty) {
      const { parentData, childData } = getSelectedData();
      const selectedData = fieldName === 'parentAssociation' ? parentData : childData;
      const newChips = {
        label: selectedData?.newLabel,
        enableDelete: true,
        associationTypeId: selectedData?.associationTypeId,
        employeeId: selectedData?.employeeId,
        associationWithEmployeeId: selectedData?.associationWithEmployeeId,
      };
      if (fieldName === 'parentAssociation' && parentData.isValid) {
        dispatch(getUserManagementActions.setParentAssociationChips([...parentAssociationChips, newChips]));
        setAssociations({ ...associations, ParentRelationship: null, ParentUserName: null });
        setValue('ParentRelationship', null);
      } else if (fieldName === 'childAssociation' && childData.isValid) {
        dispatch(getUserManagementActions.setChildAssociationsChips([...childAssociationsChips, newChips]));
        setAssociations({ ...associations, ChildRelationship: null, ChildUserName: null });
        setValue('ChildRelationship', null);
      }
    }
  };

  const handleDelete = (field: 'parentAssociation' | 'childAssociation', { label: chipLabel, isSelected }: any) => {
    if (!authorized) {
      return;
    }
    if (field === 'parentAssociation') {
      const filteredParentChips = parentAssociationChips?.filter(item => item?.label !== chipLabel);
      dispatch(getUserManagementActions.setParentAssociationChips(filteredParentChips));
    } else if (field === 'childAssociation') {
      const filteredChildChips = childAssociationsChips?.filter(item => item?.label !== chipLabel);
      dispatch(getUserManagementActions.setChildAssociationsChips(filteredChildChips));
    }
  };

  const handleCancel = () => {
    const payload = { employeeId: empInfo?.employeeId };
    dispatch(getUserManagementActions.employeeAssociations(payload));
  };

  const handleChanges = () => {
    dispatch(getUserManagementActions.setIsDataChanged(false));
    dispatch(getUserManagementActions.setCustomTabValue(tabValue));
    dispatch(getUserManagementActions.setSelectedCardId(empCardInfo?.employeeId));
    dispatch(getUserManagementActions.setDialoge(false));
    dispatch(getUserManagementActions.setEmpInfo(empCardInfo));
    const payload = { employeeId: empCardInfo?.employeeId };
    dispatch(getUserManagementActions.getEmpRoles(payload));
    dispatch(getUserManagementActions.employeeAssociations(payload));
    dispatch(getUserManagementActions.setParentAssociationChips([]));
    dispatch(getUserManagementActions.setChildAssociationsChips([]));
    dispatch(getUserManagementActions.setAssociationsDiffer(false));
  };

  const keepChanges = () => {
    dispatch(getUserManagementActions.setDialoge(false));
  };

  const handleSave = () => {
    const transformAssociations = (parentAssociations, childAssociations) => {
      const transformSingleAssociation = item => {
        return {
          associationTypeId: item.associationTypeId,
          employeeId: item.employeeId,
          associationWithEmployeeId: item.associationWithEmployeeId,
        };
      };

      const transformedParentAssociations = parentAssociations.map(transformSingleAssociation);
      const transformedChildAssociations = childAssociations.map(transformSingleAssociation);

      const associations = [...transformedParentAssociations, ...transformedChildAssociations];

      const uniqueAssociations = [...new Set(associations)];

      return uniqueAssociations;
    };

    const associations = transformAssociations(parentAssociationChips, childAssociationsChips);

    const payload = {
      employeeId: empInfo?.employeeId,
      associations: associations,
    };
    dispatch(getUserManagementActions.updateEmployeeAssociation(payload));
    dispatch(getUserManagementActions.setParentAssociationChips([]));
    dispatch(getUserManagementActions.setChildAssociationsChips([]));
  };

  useEffect(() => {
    if (
      doAssociationsDiffer(parentAssociationChips, initialParentAssociationChips) ||
      doAssociationsDiffer(childAssociationsChips, initialChildAssociationsChips)
    ) {
      dispatch(getUserManagementActions.setAssociationsDiffer(true));
      dispatch(getUserManagementActions.setIsDataChanged(true));
    } else {
      dispatch(getUserManagementActions.setAssociationsDiffer(false));
      dispatch(getUserManagementActions.setIsDataChanged(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentAssociationChips, childAssociationsChips]);

  useEffect(() => {
    if (
      (associations.ParentRelationship && associations.ParentUserName) ||
      (associations.ChildRelationship && associations.ChildUserName)
    ) {
      checkDuplicateChip();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associations]);

  useEffect(() => {
    setAssociations({ ParentRelationship: null, ParentUserName: null, ChildRelationship: null, ChildUserName: null });
  }, [empInfo]);

  return (
    <>
      <FormProvider {...formMethods}>
        <Grid container spacing={2} data-testid="association-container" id="association-container">
          <Grid item xs={12} className={classes.associationContainer}>
            <Box data-testid="parent-association" id="parent-association">
              <Typography className={classes.labelText}>{'Parent Association'}</Typography>
              <Box className={classes.flex}>
                <Box className={classes.relationship}>
                  <Controller
                    control={control}
                    name={'ParentRelationship'}
                    render={({ ref, onChange, ...rest }) => (
                      <TypeAhead
                        label="Relationship"
                        options={employeeAssociationTypeOptions || []}
                        getOptionsLabel={option => option?.name}
                        inputValue={associations?.ParentRelationship}
                        isDisabled={!empInfo?.employeeId || !authorized}
                        onChange={value => {
                          onChange(value);
                          setAssociations({ ...associations, ParentRelationship: value });
                          setValue('ParentRelationship', associations.ParentRelationship);
                        }}
                        customClasses={{
                          root: classes.typeAheadCellWrapper,
                        }}
                        {...rest}
                      />
                    )}
                  />
                </Box>
                <Box className={classes.userName}>
                  <Controller
                    control={control}
                    name={'ParentUserName'}
                    render={({ ref, onChange, value, ...rest }) => (
                      <Autocomplete
                        className={classes.customTextField}
                        disabled={!empInfo?.employeeId || !authorized}
                        filterSelectedOptions
                        value={associations.ParentUserName}
                        options={parentUsernames || []}
                        filterOptions={option => option}
                        getOptionLabel={option => option?.label}
                        onInputChange={(e, newInputValue) => {
                          handleParentUsernamesChange(newInputValue);
                          if (!newInputValue) {
                            fetchFilterSpecs('', setParentUsernames);
                          }
                        }}
                        onChange={(e, value) => {
                          onChange(value);
                          setAssociations({ ...associations, ParentUserName: value });
                          setValue('ParentUserName', associations.ParentUserName);
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label={'User Name'}
                            InputLabelProps={{ style: { color: theme.palette.system.blurGrey } }}
                            variant="filled"
                          />
                        )}
                      />
                    )}
                  />
                </Box>
                <CustomTooltip
                  standardMargin
                  tooltipContent={t(
                    !associations.ParentUserName && associations.ParentRelationship
                      ? 'userManagement.userNameTooltip'
                      : '',
                  )}
                  disabled={false}
                >
                  <IconButton
                    disabled={!associations.ParentUserName || !associations.ParentRelationship || isEnabled.parent}
                    color="primary"
                    className={classes.iconButton}
                    size="small"
                    data-testid="add-button"
                    id="add-button"
                    onClick={() => handleAddChips('parentAssociation')}
                  >
                    <AddBoxIcon className={classes.buttonIcon} />
                  </IconButton>
                </CustomTooltip>
              </Box>
              <div className={`${authorized ? classes.chips : classes.chipsDisable}`}>
                {empCardInfo ? (
                  parentAssociationChips.length > 0 ? (
                    <ChipsContainer
                      maxHeight={'99%'}
                      chips={parentAssociationChips}
                      onClick={() => {}}
                      onDelete={(field, chip) => handleDelete('parentAssociation', chip)}
                    />
                  ) : (
                    <div className={classes.loader}>
                      <Typography className={classes.loaderText}>No association added</Typography>
                    </div>
                  )
                ) : null}
              </div>
            </Box>
            <Box data-testid="child-association" id="child-association">
              <Typography className={classes.labelText}>{'Child Association'}</Typography>
              <Box className={classes.flex}>
                <Box className={classes.relationship}>
                  <Controller
                    control={control}
                    name={'ChildRelationship'}
                    render={({ ref, onChange, ...rest }) => (
                      <TypeAhead
                        label="Relationship"
                        options={employeeAssociationTypeOptions || []}
                        getOptionsLabel={option => option?.name}
                        inputValue={associations?.ChildRelationship}
                        isDisabled={!empInfo?.employeeId || !authorized}
                        onChange={value => {
                          onChange(value);
                          setAssociations({ ...associations, ChildRelationship: value });
                          setValue('ChildRelationship', associations.ChildRelationship);
                        }}
                        customClasses={{
                          root: classes.typeAheadCellWrapper,
                        }}
                        {...rest}
                      />
                    )}
                  />
                </Box>
                <Box className={classes.userName}>
                  <Controller
                    control={control}
                    name={'ChildUserName'}
                    render={({ ref, onChange, value, ...rest }) => (
                      <Autocomplete
                        className={classes.customTextField}
                        disabled={!empInfo?.employeeId || !authorized}
                        filterSelectedOptions
                        options={childUsernames || []}
                        filterOptions={option => option}
                        value={associations.ChildUserName}
                        getOptionLabel={option => option?.label}
                        onInputChange={(e, newInputValue) => {
                          handleChildUsernamesChange(newInputValue);
                          if (!newInputValue) {
                            fetchFilterSpecs('', setChildUsernames);
                          }
                        }}
                        onChange={(e, value) => {
                          setAssociations({ ...associations, ChildUserName: value });
                          setValue('ChildUserName', associations.ChildUserName);
                          onChange(e);
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label={'User Name'}
                            InputLabelProps={{ style: { color: theme.palette.system.blurGrey } }}
                            variant="filled"
                          />
                        )}
                      />
                    )}
                  />
                </Box>
                <CustomTooltip
                  standardMargin
                  tooltipContent={t(
                    !associations.ChildUserName && associations.ChildRelationship
                      ? 'userManagement.userNameTooltip'
                      : '',
                  )}
                  disabled={associations.ChildUserName && associations.ChildRelationship}
                >
                  <IconButton
                    disabled={!associations.ChildUserName || !associations.ChildRelationship || isEnabled.child}
                    color="primary"
                    className={classes.iconButton}
                    size="small"
                    data-testid="add-button"
                    id="add-button"
                    onClick={() => handleAddChips('childAssociation')}
                  >
                    <AddBoxIcon className={classes.buttonIcon} />
                  </IconButton>
                </CustomTooltip>
              </Box>
              <div className={`${authorized ? classes.chips : classes.chipsDisable}`}>
                {empCardInfo ? (
                  childAssociationsChips?.length > 0 ? (
                    <ChipsContainer
                      maxHeight={'99%'}
                      chips={empCardInfo ? childAssociationsChips : []}
                      onClick={() => {}}
                      onDelete={(field, chip) => handleDelete('childAssociation', chip)}
                    />
                  ) : (
                    <div className={classes.loader}>
                      <Typography className={classes.loaderText}>No association added</Typography>
                    </div>
                  )
                ) : null}
              </div>
            </Box>
          </Grid>
          <Grid container spacing={2} justifyContent="flex-end" className={classes.button} data-testid="button">
            <Grid item>
              <Button
                className={`${associationsDiffer ? classes.cancelButton : classes.actionButton}`}
                variant="contained"
                onClick={handleCancel}
              >
                {`Cancel`}
              </Button>
            </Grid>
            <Grid item>
              <CustomTooltip
                standardMargin
                tooltipContent={t(associationsDiffer && authorized ? '' : 'userManagement.SaveButtonTooltip')}
                disabled={associations.ChildUserName && associations.ChildRelationship}
              >
                <Button
                  className={`${associationsDiffer ? classes.saveButton : classes.saveDisableButton}`}
                  variant="contained"
                  onClick={handleSave}
                >
                  {`Save`}
                </Button>
              </CustomTooltip>
            </Grid>
          </Grid>
          <DiscardChangesDialog dialogeOpen={dialogeOpen} handleChanges={handleChanges} keepChanges={keepChanges} />
        </Grid>
      </FormProvider>
    </>
  );
};

export default Associations;
