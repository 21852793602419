import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const useStyles = makeStyles()({
  root: {
    backgroundColor: '#FFF6EE',
    border: '1px solid #FF0000',
    width: '100%',
    padding: '12px 6px',
  },
  icon: {
    marginRight: 12,
    color: '#FF0000',
    fontSize: 24,
  },
  text: {
    fontSize: 14,
    alignSelf: 'center',
  },
});

export const TaskFormBanner = ({ message }) => {
  const { classes } = useStyles();

  return (
    <Grid container justifyContent="center" direction={'row'} alignContent="center" className={classes.root} wrap="nowrap">
      <ErrorOutlineIcon className={classes.icon} />
      <Grid item className={classes.text}>
        {message}
      </Grid>
    </Grid>
  );
};
