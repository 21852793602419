import React from 'react';
import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import CandidatesIcon from '../../../assets/images/IconImages/CandidatesOutline.svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectWorkDeskCounts } from './RecruiterPlacements.selector';

const useStyles = makeStyles()({
  icon: {
    display: 'flex',
    justifyContent: 'center',
  },
  textContent: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    fontWeight: 450,
    lineHeight: '32px',
  },
  title: {
    fontSize: 14,
    color: '#666666',
  },
  count: {
    color: '#555555',
    fontSize: 36,
  },
});

export const Aggregate = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { candidates } = useSelector(selectWorkDeskCounts);
  return (
    <Grid container direction="column">
      <Grid item classes={{ root: classes.icon }}>
        <img src={CandidatesIcon} alt="Candidates" />
      </Grid>
      <Grid item classes={{ root: `${classes.title} ${classes.textContent}` }}>
        {t('Candidates')}
      </Grid>
      <Grid item classes={{ root: `${classes.count} ${classes.textContent}` }} aria-label="aggregate-count">
        {candidates.candidateCount}
      </Grid>
    </Grid>
  );
};
