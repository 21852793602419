import i18next from 'i18next';
/**
 * Data is derived anonymously from a doc maintained at AMN
 * https://amnhealthcare.sharepoint.com/:x:/r/teams/AMNOne-tm/_layouts/15/Doc.aspx?action=edit&sourcedoc=%7Bf4625c08-74c6-45c1-a681-e5548a3d9a43%7D&wdOrigin=TEAMS-WEB.teamsSdk.openFilePreview&wdExp=TEAMS-CONTROL&web=1&ovuser=687f51c3-0c5d-4905-84f8-97c683a5b9d1%2CSevak.Singh%40neudesic.com&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yMzA5MjkxMjMxMCIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D
 */

export const deriveTokensObj = data => ({
  benefits: {
    label: i18next.t('templateEditor.sidebar.tokenLabel.benefits'),
    tokens: data?.benefits ?? [],
  },
  bonus: {
    label: i18next.t('templateEditor.sidebar.tokenLabel.bonus'),
    tokens: data?.bonus ?? [],
  },
  compensation: {
    label: i18next.t('templateEditor.sidebar.tokenLabel.compensation'),
    tokens: data?.compensation ?? [],
  },
  dates: {
    label: i18next.t('templateEditor.sidebar.tokenLabel.dates'),
    tokens: data?.dates ?? [],
  },
  facility: {
    label: i18next.t('templateEditor.sidebar.tokenLabel.facility'),
    tokens: data?.facility ?? [],
  },
  housing: {
    label: i18next.t('templateEditor.sidebar.tokenLabel.housing'),
    tokens: data?.housing ?? [],
  },
  other: {
    label: i18next.t('templateEditor.sidebar.tokenLabel.other'),
    tokens: data?.other ?? [],
  },
  payRate: {
    label: i18next.t('templateEditor.sidebar.tokenLabel.payRate'),
    tokens: data?.['pay Rate'] ?? [],
  },
  perDiem: {
    label: i18next.t('templateEditor.sidebar.tokenLabel.perDiem'),
    tokens: data?.['per Diem'] ?? [],
  },
  schedule: {
    label: i18next.t('templateEditor.sidebar.tokenLabel.schedule'),
    tokens: data?.schedule ?? [],
  },
  shift: {
    label: i18next.t('templateEditor.sidebar.tokenLabel.shift'),
    tokens: data?.shift ?? [],
  },
  stipend: {
    label: i18next.t('templateEditor.sidebar.tokenLabel.stipend'),
    tokens: data?.stipend ?? [],
  },
  timeOff: {
    label: i18next.t('templateEditor.sidebar.tokenLabel.timeOff'),
    tokens: data?.['time Off'] ?? [],
  },
  travel: {
    label: i18next.t('templateEditor.sidebar.tokenLabel.travel'),
    tokens: data?.travel ?? [],
  },
});
