import React from 'react';
import { Box, Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';

const useMaxLineStyles = makeStyles<{ minLines: number; maxLines: number }>()(
  (theme: Theme, { minLines, maxLines }) => ({
    content: {
      paddingBottom: theme.spacing(2),
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      lineHeight: '21px',
      maxHeight: `${minLines * 21}px`,
      WebkitLineClamp: 2,
    },
    fullHeight: {
      maxHeight: `${maxLines * 21}px !important`,
      overflowY: 'auto',
    },
    more: {
      color: '#006FB9',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  }),
);

/**
 *
 * @param param0 minLines is the minimum number of lines before the MORE button shows
 *
 * maxLines is the maximum number of lines before an inner scroll
 * @returns
 */
export const MaxLines = ({ text, minLines, maxLines }) => {
  const { classes, cx } = useMaxLineStyles({ maxLines: maxLines, minLines: minLines });
  const [txt, setTxt] = React.useState<string>('');
  const [tempTxt, setTempTxt] = React.useState<string>('');
  const [initialOverflow, setInitialOverflow] = React.useState<boolean | null>(null);
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const ref = React.useRef<any>(null);

  React.useEffect(() => {
    setTempTxt(text);
    const newText = tempTxt?.replace(/(\r\n|\n|\r)/gm, '\n') || '';
    setTxt(newText);
    setInitialOverflow(null);
  }, [tempTxt, text]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    if (ref !== null && ref.current !== null) {
      const hasOverflowingChildren =
        ref.current.offsetHeight < ref.current.scrollHeight || ref.current.offsetWidth < ref.current.scrollWidth;
      if (!initialOverflow) {
        setInitialOverflow(hasOverflowingChildren);
      }
    }
  });

  const expand = () => {
    setExpanded(prevState => !prevState);
    setTempTxt('');
  };

  return (
    <React.Fragment>
      <div
        ref={ref}
        className={cx({
          [classes.content]: true,
          [classes.fullHeight]: initialOverflow && expanded,
        })}
      >
        {txt}
      </div>
      {initialOverflow && (
        <Box className={classes.more} onClick={expand}>
          {!expanded ? 'more' : 'less'}
        </Box>
      )}
    </React.Fragment>
  );
};
