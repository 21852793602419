import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './FacilityContacts.redux';

const selectDomain = (state: RootState) => state?.facilityClientContacts || initialState;

export const selectContactsGridData = createSelector([selectDomain], data => data.filteredData || data.flattenedData);
export const selectedContactsData = createSelector([selectDomain], data => data.selectedContacts);
export const selectedEmailType = createSelector([selectDomain], data => data.selectedEmailType);

export const selectContactsSourceData = createSelector([selectDomain], data => data.flattenedData);
export const selectFilterSpecs = createSelector([selectDomain], data => data.filterSpecs);
export const selectFilterChipSelected = createSelector([selectDomain], data => data.filterChipSelected);
export const selectContactDeskChips = createSelector([selectDomain], data => data.chips);
export const selectState = createSelector([selectDomain], data => data.state);
export const selectContactsLoading = createSelector([selectDomain], credentialingState => credentialingState.loading);
export const selectSelectedData = createSelector([selectDomain], data => data.flattenedData.filter(x => x.selected));
export const selectPrimaryData = createSelector([selectDomain], data => data.flattenedData.filter(x => x.isPrimary));
export const selectClientContractDrawer = createSelector([selectDomain], data => data.clientContractDrawer);
