import React from 'react';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import PdfIcon from 'app/assets/images/Credentialing/pdf.svg';
import Docx from 'app/assets/images/Credentialing/docx.svg';
import Image from 'app/assets/images/Credentialing/png-jpg.svg';
import Txt from 'app/assets/images/Credentialing/txt.svg';
import Xlsx from 'app/assets/images/Credentialing/xlsx.svg';
import Pptx from 'app/assets/images/Credentialing/pptx.svg';

export enum iconType {
  png = 'image/png',
  jpeg = 'image/jpeg',
  jpg = 'image/jpg',
  gif = 'image/gif',
  pdf = 'application/pdf',
  tiff = 'image/tiff',
  bmp = 'image/bmp',
  text = 'text/plain',
  html = 'text/html',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  doc = 'application/msword',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xls = 'application/vnd.ms-excel',
  ppt = 'application/vnd.ms-powerpoint',
  pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  common = 'application/unknown',
}

export const getIcon = customSwitch({
  [iconType.png]: <img src={Image} alt="PNG" style={{ width: '20px', height: '20px' }} />,
  [iconType.jpeg]: <img src={Image} alt="JPEG" style={{ width: '20px', height: '20px' }} />,
  [iconType.jpg]: <img src={Image} alt="JPG" style={{ width: '20px', height: '20px' }} />,
  [iconType.gif]: <img src={Image} alt="GIF" style={{ width: '20px', height: '20px' }} />,
  [iconType.tiff]: <img src={Image} alt="TIFF" style={{ width: '20px', height: '20px' }} />,
  [iconType.bmp]: <img src={Image} alt="BMP" style={{ width: '20px', height: '20px' }} />,
  [iconType.text]: <img src={Txt} alt="Txt" style={{ width: '20px', height: '20px' }} />,
  [iconType.html]: <img src={Txt} alt="Txt" style={{ width: '20px', height: '20px' }} />,
  [iconType.common]: <img src={Txt} alt="Txt" style={{ width: '20px', height: '20px' }} />,
  [iconType.doc]: <img src={Docx} alt="Docx" style={{ width: '20px', height: '20px' }} />,
  [iconType.docx]: <img src={Docx} alt="Docx" style={{ width: '20px', height: '20px' }} />,
  [iconType.xlsx]: <img src={Xlsx} alt="Xlsx" style={{ width: '20px', height: '20px' }} />,
  [iconType.xls]: <img src={Xlsx} alt="Xlsx" style={{ width: '20px', height: '20px' }} />,
  [iconType.ppt]: <img src={Pptx} alt="Pptx" style={{ width: '20px', height: '20px' }} />,
  [iconType.pptx]: <img src={Pptx} alt="Pptx" style={{ width: '20px', height: '20px' }} />,
  [iconType.pdf]: <img src={PdfIcon} alt="PDF" style={{ width: '20px', height: '20px' }} />,
})(null);
