/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable i18next/no-literal-string */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Theme, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { FormInputText } from './Controls/FormInputText';
import { FormInputDatePicker } from './Controls/FormInputDatePicker';
import { selectUser } from 'oidc/user.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { candidateDetailsSelection, selectPersonalInfoError } from '../../../Profile/CandidateDetails.selector';
import { FormInputDropdown } from './Controls/FormInputDropdown';
import { useFormContext } from 'react-hook-form';
import { useDecision } from '@optimizely/react-sdk';
import { ff_candidate_ui_manage_NUID } from 'app/constants/FeatureFlags/Candidate/Keys';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: '0px 10px 0px 5px',
  },
  textColor: {
    color: '#0D6FB9',
    textAlign: 'left',
    font: 'normal normal medium 14px / 26px Roboto',
    fontWeight: 500,
    paddingLeft: 16,
    paddingTop: 8,
  },
  dobAdjust: {
    paddingBottom: '0px !important',
    paddingTop: '8px !important',
  },
  dobFieldLabel: {
    paddingTop: 4,
  },
}));

const GenderOptionsTypeAhead: any = [
  {
    ID: 0,
    Description: '*****',
  },
  {
    ID: 1,
    Description: '',
  },
  {
    ID: 2,
    Description: 'Male',
  },
  {
    ID: 3,
    Description: 'Female',
  },
  {
    ID: 4,
    Description: 'Non-Binary',
  },
  {
    ID: 5,
    Description: 'Decline To State',
  },
];

export const PersonalInfoSection = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const candidateDetailsSel = useSelector(candidateDetailsSelection);
  const { getValues, setValue, watch, errors, setError, clearErrors } = useFormContext();
  const [ssnText, setSsnText] = React.useState<string>('');
  const [manageNUID] = useDecision(ff_candidate_ui_manage_NUID);
  const dispatch = useDispatch();
  const personalInfoError = useSelector(selectPersonalInfoError);
  const onChange = e => {
    //Todo: Here we can handle logic for onChange
    // switch (e.target.name) {
    //   case 'piFirstName': //Control base logic
    //     break;
    // }
  };

  const genderoptions = props.isGenderEditableByUser
    ? props.canSelectDeclineToState
      ? GenderOptionsTypeAhead.filter(item => item.ID > 1)
      : GenderOptionsTypeAhead.filter(item => item.ID > 1 && item.ID < 5)
    : GenderOptionsTypeAhead;

  const genderValue = props.isGenderViewableByUser
    ? getValues('piGender')
      ? GenderOptionsTypeAhead.find(item => {
          if (item.Description.replace(/-|\s/g, '') === getValues('piGender')) {
            setValue('piGender', item.Description);
            return true;
          }
        })
      : GenderOptionsTypeAhead.find(item => item.ID === 1).Description
    : GenderOptionsTypeAhead.find(item => item.ID === 0).Description;

  React.useEffect(() => {
    setSsnText(candidateDetailsSel.socialSecurityNumber);
    // setSsnText(
    //   props.isViewableByUser
    //     ? `*****${candidateDetailsSel.socialSecurityNumber?.replace(/-/g, '').toString().slice(-4)}`
    //     : '*********',
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isSSNEditableByUser, props.isSSNViewableByUser]);

  const onGenderChange = event => {
    setValue('piGender', event?.target?.value);
  };

  const onNuidChange = event => {
    setValue('piNUID', event?.target?.value);
  };
  const handleSsnView = () => {
    // if (props.isViewableByUser) setSsnText(candidateDetailsSel.socialSecurityNumber?.replace(/-/g, ''));
  };

  const handleSsnChange = e => {
    setValue('piSSN', e.target.value);
  };
  const handleSSNValueChange = value => {
    if (getValues('piSSN').replace(/-/g, '') !== value && personalInfoError.length > 0) {
      const updatedPersonalInfoError = personalInfoError.filter(error => error.errorField !== 'piSSN');
      clearErrors('piSSN');
      dispatch(candidateDetailActions.setPersonalInfoError(updatedPersonalInfoError));
    }
  };

  return (
    <LayoutGrid container direction="column" className={classes.root}>
      <Typography className={classes.textColor} variant="subtitle1" gutterBottom component="div">
        {`Personal Information`}
      </Typography>
      <LayoutGridItem item>
        <LayoutGrid
          container
          classes={{ root: classes.root }}
          direction="row"
          data-testid="container"
          justifyContent="flex-start"
          paddingLeft="100px"
          spacing={4}
        >
          <LayoutGridItem item xs={4} paddingLeft="100px">
            <FormInputText
              name="piFirstName"
              label={t('candidate.profileInformation.firstName')}
              require={true}
              handleChange={onChange}
              readOnly={!props.isNameEditableByUser}
              isCheckBox={true}
              message={errors['piFirstName']?.message || ''}
            />
          </LayoutGridItem>
          <LayoutGridItem item xs={4}>
            <FormInputText
              name="piMidName"
              label={t('candidate.profileInformation.midName')}
              require={false}
              readOnly={!props.isNameEditableByUser}
              isCheckBox={true}
              message={errors['piMidName']?.message || ''}
            />
          </LayoutGridItem>
          <LayoutGridItem item xs={4}>
            <FormInputText
              name="piLastName"
              label={t('candidate.profileInformation.lastName')}
              require={true}
              readOnly={!props.isNameEditableByUser}
              isCheckBox={true}
              message={errors['piLastName']?.message || ''}
            />
          </LayoutGridItem>
          <LayoutGridItem item xs={4}>
            <FormInputText
              name="piGoesBy"
              label={t('candidate.profileInformation.goesBy')}
              require={false}
              isCheckBox={candidateDetailsSel.goesBy ? true : false}
            />
          </LayoutGridItem>
          <LayoutGridItem item xs={4}>
            <div onClick={handleSsnView}>
              <FormInputText
                name="piSSN"
                label={t('candidate.profileInformation.socialSecurityNum')}
                require={false}
                inputValue={ssnText}
                readOnly={!props.isSSNEditableByUser}
                isCheckBox={true}
                inputComponent
                handleChange={handleSsnChange}
                onInputChange={handleSSNValueChange}
              />
            </div>
          </LayoutGridItem>
          <LayoutGridItem item xs={4}>
            <FormInputDatePicker
              name="piDOB"
              label={t('candidate.profileInformation.dateOfBirth')}
              require={false}
              readOnly={!props.isDOBEditableByUser}
              inputValue={props.isDOBViewableByUser ? watch('piDOB') : null}
              customClass={classes.dobAdjust}
              labelClass={classes.dobFieldLabel}
              nativeError={true}
              customErrorMessage={t('candidate.profileInformation.errorMessage')}
              onDateError={value => (value ? setError('piDOB', value) : clearErrors('piDOB'))}
              isOnErrorRequired={true}
              maxDate={new Date()}
            ></FormInputDatePicker>
          </LayoutGridItem>
          {/* <LayoutGridItem item xs={4}>
            <RaceEthnicityControl
              name="raceEthnicity"
              require={false}
              isViewableByUser={props.isGenderViewableByUser}
              isEditableByUser={!props.isGenderEditableByUser}
            />
          </LayoutGridItem> */}
          <LayoutGridItem item xs={4}>
            <FormInputDropdown
              name="piGender"
              label={t('candidate.personalInfoCard.gender')}
              options={genderoptions}
              handleChange={onGenderChange}
              disabled={!props.isGenderEditableByUser}
            />
          </LayoutGridItem>
          {manageNUID.enabled && (
            <LayoutGridItem item xs={4}>
              <FormInputText
                name="piNUID"
                label={t('candidate.profileInformation.nuid')}
                readOnly={candidateDetailsSel.nuid ? true : !props.isNUIDEditableByUser ? true : false}
                isCheckBox={candidateDetailsSel.nuid ? true : false}
                handleChange={onNuidChange}
                message={errors['piNUID']?.message || ''}
              />
            </LayoutGridItem>
          )}
        </LayoutGrid>
      </LayoutGridItem>
    </LayoutGrid>
  );
};
