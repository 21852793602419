import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { makeStyles } from 'tss-react/mui';
import { XGrid } from 'app/components/Common/XGrid/XGrid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDeliveryStatusModalColumns } from './DeliveryStatusModalHelpers';
import { selectGridSelection } from 'app/components/Common/Grid/GridSelection/GridSelection.selector';
import { useDispatch, useSelector } from 'react-redux';
import { SendType, UseSubType } from '../Constants';
import { EmailDefaultValues } from 'app/models/Email/IEmail';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { selectUser } from 'oidc/user.selectors';
import { Concatenate } from 'utils/string/string';
import { ContactProps, IToken } from 'app/models/Notification/Notification';
import { gridSelectionActions } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.redux';
import { taskDetailsActions } from '@AMIEWEB/Tasks/store/Tasks.redux';
import { placementDocumentsActions } from 'store/redux-store/placement-documents/slice';
import { DeliveryAttachments } from './EmailDeliveryStatusAttachments';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Theme } from 'amn-ui-core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CustomBackdrop } from '@AMIEWEB/Common';
import { GetUserLogs } from 'app/services/NotificationServices/NotificationService';
import { INotificationLog } from 'app/models/ActivityFeed/IActivityLogs';
import { selectNotificationLog } from '@AMIEWEB/Tasks/store/Tasks.selectors';
import { RootState } from 'types';
import { checkPlacementuseType } from './helper';

const useStyles = makeStyles<{
  expanded: boolean;
}>()((theme: Theme, { expanded }) => ({
  dialog: {
    minWidth: '900px',
    '& .MuiDialog-paperWidthSm': {
      minWidth: '900px',
      maxWidth: '100%',
      minHeight: '500px',
    },
  },
  dialogContent: {
    padding: '0px',
    overflow: 'hidden',
  },
  attachment: {
    paddingTop: 20,
  },
  root: {
    marginTop: '-4px',
    fontSize: '12px',
    color: theme.palette.system.textBlue,
    '@media (min-width: 1200px)': {
      fontSize: '12px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.system.textBlue} !important`,
      borderWidth: expanded ? '' : '2px',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.system.textBlue} !important`,
      borderWidth: expanded ? '' : '2px',
    },
    '& .MuiOutlinedInput-root-MuiSelect-root': {
      fontSize: '5px',
    },
  },
  '.MuiSelect-root:hover': {
    borderColor: `${theme.palette.system.textBlue} !important`,
  },
  icon: {
    color: `${theme.palette.system.textBlue} !important`,
    height: '22px',
    width: '22px',
  },
  label: {
    color: `${theme.palette.system.textBlue} !important`,
  },
  dropdownStyle: {
    maxHeight: '190px',
  },
  sent: { width: '40%', display: 'inline-block', textAlign: 'right' },
  sub: { width: '60%', display: 'inline-block', textAlign: 'left', textOverflow: 'ellipsis' },
  subsent: { width: '100%', paddingBottom: '14px' },
}));

export enum ReciepientType {
  Tos = 'To',
  Ccs = 'CC',
  Bccs = 'BCC',
}
export enum DeliveryStatus {
  Pending = 'Pending',
  Delivered = 'Delivered',
  Bounce = 'Bounce',
  Blocked = 'Blocked',
  SpamReport = 'Spam Report',
  Unsubscribed = 'Unsubscribed',
  Dropped = 'Dropped',
}

export interface IDeliveryStatusGridData {
  id: string;
  name: string;
  reciepientType: ReciepientType;
  email: string;
  deliveryStatus: DeliveryStatus;
  error: number;
}

export interface ISentDate {
  date?: string;
  logid?: string;
}

export interface IDeliveryStatusProps {
  id?: any;
  open: boolean;
  tableData: IDeliveryStatusGridData[];
  subject: string;
  sentDate: ISentDate[];
  selectedSentDate?: ISentDate;
  body: string;
  closeModal: (event?: any, reason?: string) => void;
  attachmentUrls?: string[];
  loader?: boolean;
  isDocumentsTab?: boolean;
}

export const formatName = str => {
  const strParts = str.split('_');
  const capitalizedParts = strParts.map(part => part.charAt(0).toUpperCase() + part.slice(1));
  return capitalizedParts.join(' ');
};

const EmailDeliveryStatus = ({
  id,
  body,
  open,
  sentDate,
  selectedSentDate,
  subject,
  tableData,
  closeModal,
  attachmentUrls,
  loader = false,
  isDocumentsTab = false,
}: IDeliveryStatusProps) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector(selectUser);
  const [expanded, setExpanded] = useState<boolean>(false);
  const { classes } = useStyles({ expanded: expanded });
  const { t } = useTranslation();
  const [tableColumns, setTableColumns] = React.useState<any[]>(getDeliveryStatusModalColumns(t, true));
  const { selectedData } = useSelector(selectGridSelection);
  const defaultSort: any = [{ field: 'error', sort: 'desc' }];
  const [sub, setSub] = React.useState(subject);
  const [result, setResult] = React.useState<any>();
  const [urlAttachments, setUrlAttachments] = useState<File[]>([]);
  const NotificationLogData = useSelector<RootState, INotificationLog>(selectNotificationLog);
  let isPlacementEmail = false;

  const getForwardAttachments = () => {
    if (attachmentUrls && attachmentUrls?.length > 0) {
      const files = [];
      attachmentUrls?.forEach(attachment => {
        const splitted = attachment.split('/');
        const lastSplitted = splitted?.[splitted?.length - 1] ?? '';
        const file = new File([], lastSplitted);
        files.push(file);
      });
      setUrlAttachments(files);
    }
  };

  useEffect(() => {
    if (attachmentUrls) {
      getForwardAttachments();
    }
    return () => {
      setUrlAttachments([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentUrls]);

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(placementDocumentsActions.setLogId(event.target.value));
  };

  const handleOpen = () => {
    setExpanded(true);
  };

  const handleClose = () => {
    setExpanded(false);
  };

  const SentDateArray = () => {
    return (
      <div>
        <FormControl
          sx={{
            m: 1,
            minWidth: 120,
          }}
          size="small"
        >
          <InputLabel id="select-sent-label" className={classes.label}>
            Sent
          </InputLabel>
          <Select
            open={expanded}
            labelId="select-sent-label"
            id="select-sent"
            value={selectedSentDate?.logid}
            label="Sent"
            onChange={handleChange}
            onOpen={handleOpen}
            onClose={handleClose}
            IconComponent={ExpandMoreIcon}
            className={classes.root}
            classes={{
              icon: classes.icon,
            }}
            MenuProps={{
              classes: { paper: classes.dropdownStyle },
            }}
          >
            {sentDate?.map(dt => (
              <MenuItem value={dt.logid}>{dt?.date}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  React.useEffect(() => {
    const notificationLogData = NotificationLogData?.results?.map(obj => ({
      useSubType: obj?.useSubType,
      useType: obj?.useType,
      id: obj?.id,
    }));

    isPlacementEmail = checkPlacementuseType(id, notificationLogData);

    if (id) {
      const payload = {
        ids: [id],
      };
      if (isPlacementEmail || isDocumentsTab) payload['isCandidateCommunication'] = false;
      GetUserLogs(payload)
        .then(response => {
          setResult(response?.results?.[0]);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [id, NotificationLogData]);

  React.useEffect(() => {
    setTableColumns(getDeliveryStatusModalColumns(t, true));

    return () => {
      dispatch(taskDetailsActions.setEmailDeliveryAssociateRecords([]));
      dispatch(placementDocumentsActions.setLogId(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    handleExpanded(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, subject]);

  const handleResendEmail = () => {
    const tos = [],
      ccs = [],
      bccs = [];
    const data = tableData.filter(row => selectedData.includes(row.id));

    for (let d of data) {
      if (d.reciepientType === 'To') {
        const toRec = result?.message?.tos?.filter(to => to?.email === d.email)?.[0];
        if (toRec) {
          const newToRec: ContactProps = {
            name: toRec?.name,
            email: toRec?.email,
            phoneNumber: toRec?.phoneNumber,
            contactId: toRec?.contactId,
            contactType: toRec?.contactType,
            brand: toRec?.brand,
            brandId: toRec?.brandId,
          };
          tos.push(newToRec);
        }
      }
      if (d.reciepientType === 'CC') {
        const ccRec = result?.message?.ccs?.filter(to => to?.email === d.email)?.[0];
        if (ccRec) {
          const newCcRec: ContactProps = {
            name: ccRec?.name,
            email: ccRec?.email,
            phoneNumber: ccRec?.phoneNumber,
            contactId: ccRec?.contactId,
            contactType: ccRec?.contactType,
            brand: ccRec?.brand,
            brandId: ccRec?.brandId,
          };
          ccs.push(newCcRec);
        }
      }
      if (d.reciepientType === 'BCC') {
        const bccRec = result?.message?.bccs?.filter(to => to?.email === d.email)?.[0];
        if (bccRec) {
          const newBccRec: ContactProps = {
            name: bccRec?.name,
            email: bccRec?.email,
            phoneNumber: bccRec?.phoneNumber,
            contactId: bccRec?.contactId,
            contactType: bccRec?.contactType,
            brand: bccRec?.brand,
            brandId: bccRec?.brandId,
          };
          bccs.push(newBccRec);
        }
      }
    }

    const subject = result?.message?.subject ?? '';
    const body = result?.message?.body ?? '';
    const useType = result?.useType;
    const useSubType = result?.useSubType;
    const sendType = result?.sendType === 'Bulk' ? SendType.bulk : SendType.one_to_one;
    const emailType = result?.sendType === 'Bulk' ? SendType.bulk : SendType.one_to_one;
    const associatedRecords = (result?.associatedRecords || []).filter(rec => rec?.name !== 'ForwardedTo');
    const facilityOneToOne =
      associatedRecords?.find(rec => rec?.name === 'initiatedFrom')?.value === 'FacilityGridEmailLink';
    const sharedUserId = associatedRecords?.find(rec => rec?.name === 'sharedUserId')?.value;

    const sender = {
      name: Concatenate([userInfo?.firstName, userInfo?.lastName], ' '),
      email: userInfo?.email,
      userId: `${userInfo?.employeeId}`,
      NtUserName: userInfo?.ntUserName,
      senderId: '',
    };
    let substitutions: IToken[] = [];
    const subs = result?.message?.tos?.[0]?.substitutions || {};

    Object.entries(subs)?.forEach(sub => {
      substitutions.push({
        name: formatName(sub?.[0]),
        input: `{{${sub?.[0]}}}`,
        value: sub?.[1],
      });
    });

    substitutions.unshift({
      name: 'Signature',
      input: '{{signature}}',
    });

    const forwardAttachmentUrls: string[] = result?.message?.attachmentUrls ?? [];
    const alertPartitionKey = sharedUserId ?? result?.message?.sender?.userId;

    const emailData = {
      channel: 'email',
      subject: subject,
      body: body,
      useType: useType,
      useSubType: useSubType,
      sendType: sendType,
      emailType: emailType,
      associatedRecords: associatedRecords,
      sender: sender,
      tos: tos,
      ccs: ccs,
      bccs: bccs,
      substitutions: substitutions,
      alertId: !isDocumentsTab ? id : '',
      alertPartitionKey: !isDocumentsTab ? alertPartitionKey : '',
      packetSubmission: useSubType === UseSubType.PacketSubmission,
      confirmationLetter: useSubType === UseSubType.ConfirmationLetter,
      facilityOneToOne: !!facilityOneToOne,
    };

    dispatch(
      notificationDataActions.setEmailData({
        data: {
          ...EmailDefaultValues(),
          ...emailData,
        },
        open: true,
        minimized: false,
        forwardAttachmentUrls: forwardAttachmentUrls,
      }),
    );
    closeModal();
  };

  const handleExpanded = expanded => {
    if (subject?.length > 50) {
      if (!expanded) {
        setSub(subject.substring(0, 47) + '...');
      } else {
        setSub(subject);
      }
    } else {
      setSub(subject);
    }
  };

  return (
    <GenericDialog
      open={open}
      onClose={(e, reason) => {
        if (reason === 'backdropClick') return;
        closeModal();
      }}
      variant="blue"
      className={classes.dialog}
      dialogTitleProps={{
        text: 'Email Delivery Status',
        closeButton: true,
        expandable: true,
        onExpandChange: handleExpanded,
      }}
      dialogContentProps={{
        className: classes.dialogContent,
      }}
      dialogActions={[
        {
          text: 'Cancel',
          variant: 'contained',
          color: 'tertiary',
          useDialogOnCloseEvent: true,
        },
        {
          text: 'Preview',
          variant: 'contained',
          color: 'tertiary',
        },
        {
          text: 'Forward',
          variant: 'contained',
          color: 'primary',
          onClick: handleResendEmail,
        },
      ]}
    >
      {loader ? (
        <CustomBackdrop open />
      ) : (
        <div style={{ padding: '5px 20px' }}>
          <div>
            <div className={classes.subsent}>
              <p className={classes.sub}>
                <span style={{ fontWeight: 'bold' }}>{t('Subject: ')}</span>
                {sub}
              </p>
              {sentDate?.length > 1 ? (
                <div className={classes.sent}>{<SentDateArray />}</div>
              ) : (
                <p className={classes.sent}>
                  <span style={{ fontWeight: 'bold' }}>{t('Sent: ')}</span>
                  {sentDate?.[0]?.date ?? ''}
                </p>
              )}
            </div>
            <XGrid
              disableColumnFilter
              disableTitleCount
              disableColumnReorder
              disableColumnResize
              disableMultipleColumnsFiltering
              disableColumnMenu
              disableColumnSelector
              disableDensitySelector
              disableExtendRowFullWidth
              pagination={false}
              toolbar={false}
              defaultSortModel={defaultSort}
              sortModel={defaultSort}
              title={''}
              rows={tableData || []}
              columns={tableColumns}
              autoHeight={false}
              height={300}
              disableSelectionOnClick
              rowCount={(tableData || []).length}
              checkboxSelection
              hideFooter={true}
            />
          </div>
          <div className={classes.attachment}>
            {urlAttachments && urlAttachments?.length > 0 && (
              <DeliveryAttachments dataUrlAttachments={urlAttachments} />
            )}
          </div>
        </div>
      )}
    </GenericDialog>
  );
};

export default EmailDeliveryStatus;
