import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { XGrid } from 'app/components/Common/XGrid/XGrid';
import { Chip, Divider, Grid } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { FormInputDatePicker } from '../../../PersonalInfoTab/Form/Controls/FormInputDatePicker';
import { FormInputText } from '../../../PersonalInfoTab/Form/Controls/FormInputText';
import { FormInputCheckbox } from '../../../PersonalInfoTab/Form/Controls/FormInputCheckbox';
import { getEditSuperviorColumns } from './helpers/EditSupervisorHelper';
import { IXGridBulkActionButton, XGridToolbar } from '@AMIEWEB/Common/XGrid/Toolbar/XGridToolbar';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { lookupSelector } from 'store/redux-store/lookup/lookup.selectors';
import { selectGridSelection } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.selector';
import { SupervisorDeleteDialog } from './SupervisorDeleteDialog';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { IDeleteSupervisorSkillsetData } from 'app/models/Candidate/CandidateProfile';
import { gridSelectionActions } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.redux';
import { candidateDetailsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { selectUser } from 'oidc/user.selectors';
import { ChipColors, SupervisorStatus, skillsetStatusOptions } from './constants';
import { enableConatiner, getOptionDisabled, useCustomOptions } from './helpers/helper';
import { useStyles } from '../styles';

export const EditSupervisor = props => {
  const { t } = useTranslation();
  const { data } = props;
  const { getValues, setValue, clearErrors, watch } = useFormContext();
  const { classes } = useStyles();
  const [rows, setRows] = useState<any[]>(props.skillsetRows);
  const [dropdownOptions, setDropdownOptions] = useState<any>();
  const [dataRows, setDataRows] = useState<any[]>([]);
  const lookups = useSelector(lookupSelector);
  const { selectedData } = useSelector(selectGridSelection);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const dispatch = useDispatch();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const user = useSelector(selectUser);

  useEffect(() => {
    setRows(props.skillsetRows);
  }, [props.skillsetRows]);

  useEffect(() => {
    getDropdownOptions();
  }, [data, lookups]);
  useEffect(() => {
    const dataRows = rows.filter(row => {
      return !(
        row['discipline'] === null &&
        row['existingSkillset'] === false &&
        row['rejectionReason'] === null &&
        row['specialty'] === null &&
        row['status'] === null
      );
    });
    setDataRows(dataRows);
  }, [rows]);
  useEffect(() => {
    dispatch(gridSelectionActions.reset());
    props.setSaveDisabled(true);
  }, []);

  const getDropdownOptions = () => {
    const disciplineOptions = lookups?.disciplines?.map(item => {
      return {
        ID: item.object?.ID,
        Description: item.object?.Abbreviation,
      };
    });
    const rejectionReasonOptions = lookups?.skillsetRejectionReason?.map(item => {
      return {
        ID: item.value,
        Description: item.name,
      };
    });
    setDropdownOptions({
      discipline: disciplineOptions,
      specialty: [], // determined after discipline is chosen
      status: skillsetStatusOptions,
      rejectionReason: rejectionReasonOptions,
    });
  };

  const handleAvailableDateChange = event => {
    setValue('availabilityDate', event, { shouldDirty: true });
    clearErrors('availabilityDate');
    props.setSaveDisabled(false);
  };
  const handleMaxNumberChange = event => {
    setValue('maxNumber', event.target.value, { shouldDirty: true });
    props.setSaveDisabled(false);
  };
  const handleNotInterested = () => {
    const interestedValue = getValues('interested');
    props.setSaveDisabled(false);
    if (interestedValue === false) {
      setValue('maxNumber', null);
      setValue('available', null);
      setValue('availabilityDate', null);
    }
  };

  const renderChip = (value: string, option: any[]) => {
    const status = option.find(item => item.Description === value)?.Description;
    switch (status) {
      case SupervisorStatus.APPROVED:
        return (
          <Chip
            style={{
              color: ChipColors.GREEN,
              borderColor: ChipColors.GREEN,
              background: ChipColors.GREEN_BACKGROUND,
            }}
            variant="outlined"
            size="medium"
            label={status}
          />
        );
      case SupervisorStatus.DECLINED:
        return (
          <Chip
            style={{
              color: ChipColors.RED,
              borderColor: ChipColors.RED,
              background: ChipColors.RED_BACKGROUND,
            }}
            variant="outlined"
            size="medium"
            label={status}
          />
        );
    }
  };

  const getGridColumns = () =>
    getEditSuperviorColumns(
      dropdownOptions,
      renderChip,
      useCustomOptions,
      props.handleSkillsetChange,
      getOptionDisabled,
    );

  const enable = useCallback(
    key => {
      enableConatiner({ key, selectedData });
    },
    [selectedData.length],
  );

  const deleteSkillSets = async () => {
    if (selectedData && selectedData.length) {
      setOpenDeleteDialog(false);
      let skillsets = rows;

      const nonExistingRowIds = rows
        .filter(x => selectedData.includes(x.rowID) && x.existingSkillset === false)
        .map(x => x.rowID);
      if (nonExistingRowIds?.length) {
        skillsets = rows.filter(x => !nonExistingRowIds.includes(x.rowID));
      }

      const existingRowIds = rows
        .filter(x => selectedData.includes(x.rowID) && x.existingSkillset === true)
        .map(x => x.rowID);
      if (existingRowIds?.length) {
        const payload: IDeleteSupervisorSkillsetData = {
          candidateId: candidateDetails.travelerId,
          travelerSupervisorSkillsetIds: existingRowIds,
          skillsets: skillsets,
          currentEmployeeId: user?.userInfo?.employeeId || 0,
          currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
        };

        dispatch(candidateDetailActions.deleteSupervisorSkillsets(payload));
      }

      if (!existingRowIds?.length) {
        props.handleSkillsetChange(skillsets);
        dispatch(
          globalActions.setSnackBar({
            message: t('candidate.supervisorCard.deleteSuccessSupervisorSkillset'),
            severity: 'success',
          }),
        );
      }

      dispatch(
        gridSelectionActions.setGridSelections({
          selectedData: [],
          isCompareGridOpen: false,
        }),
      );
    }
  };

  const toobarRightActions = useMemo<IXGridBulkActionButton[]>(() => {
    return [
      {
        key: 'deleteSkillsets',
        Icon: DeleteOutlinedIcon,
        disabled: enable('deleteSkillsets'),
        onClick: () => setOpenDeleteDialog(true),
        tooltipProps: {
          tooltipContent: 'Delete Skill Set(s)',
        },
      },
      {
        key: 'addSkillsets',
        Icon: AddIcon,
        disabled: false,
        onClick: () => {
          props.setSaveDisabled(false);
          setRows([
            ...rows,
            {
              rowID: rows.length + 1,
              discipline: null,
              specialty: null,
              status: null,
              rejectionReason: null,
              existingSkillset: false,
            },
          ]);
        },
        tooltipProps: {
          tooltipContent: 'Add Skill Set(s)',
        },
      },
    ] as IXGridBulkActionButton[];
  }, [enable, rows]);

  return (
    <>
      <Grid container direction="column" spacing={4} mt="10px" ml="-28px">
        <Grid item className={classes.item}>
          <FormInputCheckbox
            name={'interested'}
            handleChecked={() => handleNotInterested()}
            checkboxLabel={`Interested`}
            isChecked={watch('interested')}
          ></FormInputCheckbox>
        </Grid>
        <Grid item xs={6} width="50%" className={classes.item}>
          <FormInputDatePicker
            name={'availabilityDate'}
            label={t('candidate.supervisorCard.availableDate')}
            trailingIconVisible
            handleChange={handleAvailableDateChange}
            inputValue={watch('availabilityDate')}
            customClass={classes.datePickerInput}
          ></FormInputDatePicker>
        </Grid>
        <Grid item className={classes.item}>
          <Grid container direction="row" data-testid="container" justifyContent="flex-start" spacing={4}>
            <Grid item xs={6} width="50%">
              <FormInputText
                name="maxNumber"
                label={t('candidate.supervisorCard.desiredPlacements')}
                handleChange={handleMaxNumberChange}
                inputValue={getValues('maxNumber')}
                isCheckBox={getValues('maxNumber') ? true : false}
              />
            </Grid>
            <Grid item xs={6} width="40%">
              <FormInputText
                name="available"
                label={t('candidate.supervisorCard.availablePlacements')}
                inputValue={getValues('available')}
                readOnly
                isCheckBox={getValues('available') ? true : false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pt="10px !important ">
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <XGrid
            title="Skill Set"
            rows={rows}
            columns={getGridColumns()}
            experimentalFeatures={{ columnGrouping: true, newEditingApi: true }}
            getRowId={model => model.rowID}
            checkboxSelection
            rowHeight={60}
            headerHeight={70}
            dataRowCountDisplay={dataRows?.length}
            hideFooter={true}
            autoHeight={true}
            disableSelectionOnClick={true}
            components={{
              Toolbar: XGridToolbar,
            }}
            className={classes.grid}
            toolbarProps={{
              rightActions: toobarRightActions,
              refreshButton: true,
              resetButton: true,
              refreshGrid: () => {
                // handleRefreshGrid();
              },
            }}
          />
          <SupervisorDeleteDialog
            openDeleteDialog={openDeleteDialog}
            onDeleteDialogClose={() => setOpenDeleteDialog(false)}
            deleteSkillSets={() => deleteSkillSets()}
          />
        </Grid>
      </Grid>
    </>
  );
};
