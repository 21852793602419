import { GetMomentOfDate } from 'utils/dates/moment';
import { Concatenate } from 'utils/string/string';
import Call from '../../../assets/images/WorkDesk/Call.png';
import email from '../../../assets/images/WorkDesk/email.png';
import { missingDate, missingField } from 'app/constants';
import { getStatusAge } from 'utils/common/statusAge';
import placementTagsData from 'app/assets/jsons/PlacementTagsV2.json';
import { checkCandidatesConsents } from '@AMIEWEB/Candidate/CandidateProfile/Profile/helper';

export const placementFlattenData = items =>
  items.map((item, index) => {
    const statusChangedDateMoment = GetMomentOfDate(item.statusChangeDate);
    const statusAgeDetails = item.statusChangeDate ? getStatusAge(item.statusChangeDate) : null;
    const placementCreatedDateMoment = GetMomentOfDate(item.placementCreated);
    const fileSentDateMoment = GetMomentOfDate(item.fileSentDate);
    const availabileStartDateMoment = GetMomentOfDate(item.availableStartDate);
    const startDateMoment = GetMomentOfDate(item.startDate);
    const endDateMoment = GetMomentOfDate(item.endDate);
    const arbitrationSentDateMoment = GetMomentOfDate(item.arbitrationSentDate);
    const arbitrationSignedDateMoment = GetMomentOfDate(item.arbitrationSignedDate);
    const extStatusAgeDetails = GetMomentOfDate(item.extensionStatusLastUpdateDate).isValid()
      ? getStatusAge(item.extensionStatusLastUpdateDate)
      : null;
    const lastContactedOnDateMoment = GetMomentOfDate(item.lastContactedOnDate);

    const skillSets =
      item.skillsets && item.skillsets?.length
        ? item.skillsets
            ?.map(item => {
              const subSpec = item.subSpecialty && item.subSpecialty !== null ? '-' + item.subSpecialty : '';
              const spec = item.specialtyAbbr && item.specialtyAbbr !== null ? '-' + item.specialtyAbbr : '';
              return item.disciplineAbbr + spec + subSpec;
            })
            .join(', ')
        : missingField;

    const accountManagerAttributes = [
      { key: Call, value: item.accountManager?.phoneNumber },
      { key: email, value: item.accountManager?.email },
    ];
    const recruiterAttributes = [
      { key: Call, value: item.recruiter?.phoneNumber },
      { key: email, value: item.recruiter?.email },
    ];
    const credentialingAnalystAttributes = [
      { key: Call, value: item.credentialingAnalyst?.phoneNumber },
      { key: email, value: item.credentialingAnalyst?.email },
    ];
    const clinicalManagerAttributes = [
      { key: Call, value: item.clinicalManager?.phoneNumber },
      { key: email, value: item.clinicalManager?.email },
    ];
    const clientContractSpecialistAttributes = [
      { key: Call, value: item.clientContractSpecialist?.phoneNumber },
      { key: email, value: item.clientContractSpecialist?.email },
    ];

    const tags = item.tags
      ?.map(tag => {
        const matchingPTag = placementTagsData.find(PTag => PTag.value === tag.label);
        return matchingPTag ? matchingPTag.name : tag.label;
      })
      .map(item => {
        return { title: item };
      })
      ?.sort((a, b) =>
        a?.title?.toLowerCase() < b?.title?.toLowerCase()
          ? -1
          : a?.title?.toLowerCase() > b?.title?.toLowerCase()
          ? 1
          : 0,
      );

    const submissionInfo = {
      submissionAutomated: item.submissionAutomated,
      submissionError: item.submissionError,
      submissionSuccessful: item.submissionSuccessful,
      placementStatus: item.placementStatus,
      placementStatusId: item.placementStatusId,
      placementTypeId: item.placementTypeId,
    };

    const data = {
      id: `${item.placementId}-${index}`,
      indexCounter: index,
      previewDrawerViewerId: item.candidateId,
      previewDrawerViewerType: 1,
      placementId: item.placementId,
      orderId: item.orderId,
      PlacementTag: tags,
      submission: submissionInfo,
      status: item.placementStatus || missingField,
      statusAge: statusAgeDetails ? statusAgeDetails.message : missingField,
      statusAgeValue: statusAgeDetails ? statusAgeDetails.totalMinutes : Number.MIN_VALUE,
      statusChangeDate: statusChangedDateMoment.isValid() ? statusChangedDateMoment.format('MM/DD/YYYY') : missingField,
      statusChangeNoFormat: item.statusChangeDate || missingDate,
      division: item.division || missingField,
      brandId: item.brandId,
      brand: item.brand,
      candidateId: item.candidateId,
      firstName: item.candidate.firstName || missingField,
      lastName: item.candidate.lastName || missingField,
      goesBy: item.candidate.goesBy || missingField,
      otherName: item.candidate.otherName || missingField,
      emails: { primaryEmail: item.candidatePrimaryEmail, secondaryEmail: item.candidateSecondaryEmail },
      phones: item.candidatePhoneNumbers || missingField,
      candidate: Concatenate([item.candidate.firstName, item.candidate.lastName], ' ') || missingField,
      facilityId: item.facilityId,
      facility: item.facility || missingField,
      unit: item.unit || missingField,
      skillsets: skillSets.length ? skillSets : missingField,
      placementCreated: placementCreatedDateMoment.isValid()
        ? placementCreatedDateMoment.format('MM/DD/YYYY')
        : missingField,
      screen: item.screen || missingField,
      placementCreatedNoFormat: item.placementCreated || missingDate,
      fileSentDate: fileSentDateMoment.isValid() ? fileSentDateMoment.format('MM/DD/YYYY') : missingField,
      fileSentDateNoFormat: item.fileSentDate || missingDate,
      availableStartDate: availabileStartDateMoment.isValid()
        ? availabileStartDateMoment.format('MM/DD/YYYY')
        : missingField,
      availableStartDateDateNoFormat: item.availableStartDate || missingDate,
      startDate: startDateMoment.isValid() ? startDateMoment.format('MM/DD/YYYY') : missingField,
      startDateNoFormat: item.startDate || missingDate,
      endDate: endDateMoment.isValid() ? endDateMoment.format('MM/DD/YYYY') : missingField,
      endDateNoFormat: item.endDate || missingDate,
      extensionStatus: item.extensionStatus || missingField,
      extensionStatusLastUpdateDate: extStatusAgeDetails ? extStatusAgeDetails.message : missingField,
      extensionStatusLastUpdateDateValue: extStatusAgeDetails ? extStatusAgeDetails.totalMinutes : Number.MIN_VALUE,
      contractAffiliateId: item.contractAffiliateId || missingField,
      contractAffiliate: item.contractAffiliate || missingField,
      accountManager: Concatenate([item.accountManager?.firstName, item.accountManager?.lastName], ' ') || missingField,
      accountManagerAttributes,
      recruiter: Concatenate([item.recruiter?.firstName, item.recruiter?.lastName], ' ') || missingField,
      recruiterAttributes,
      placementCuedTo:
        Concatenate([item.placementCuedTo?.firstName, item.placementCuedTo?.lastName], ' ') || missingField,
      credentialingAnalyst:
        Concatenate([item.credentialingAnalyst?.firstName, item.credentialingAnalyst?.lastName], ' ') || missingField,
      credentialingAnalystAttributes,
      cueNote: item.placementCueNoteText || missingField,
      clinicalManager:
        Concatenate([item.clinicalManager?.firstName, item.clinicalManager?.lastName], ' ') || missingField,
      clinicalManagerAttributes,
      clientContractSpecialist:
        Concatenate([item.clientContractSpecialist?.firstName, item.clientContractSpecialist?.lastName], ' ') ||
        missingField,
      clientContractSpecialistAttributes,
      staffingVendor: item.staffingVendor || missingField,
      arbitrationStatus: item.arbitrationStatus || missingField,
      arbitrationSignedDate:
        arbitrationSignedDateMoment.isValid() && arbitrationSignedDateMoment.year() > 0
          ? arbitrationSignedDateMoment.format('L hh:mm A [PST]')
          : missingField,
      arbitrationSentDate:
        arbitrationSentDateMoment.isValid() && arbitrationSentDateMoment.year() > 0
          ? arbitrationSentDateMoment.format('L hh:mm A [PST]')
          : missingField,
      consents: checkCandidatesConsents(item?.consents, item?.brandId, item?.candidateId),
      region: item.region || missingField,
      lastContactedOnDate: lastContactedOnDateMoment.isValid() ? lastContactedOnDateMoment.format('MM/DD/YYYY') : missingField,
    };
    return data;
  });
