import {
  IFormatedInternalTaskData,
  ITask,
  ITaskComments,
  IUser,
  IGridData,
  IInternalTaskModalDetails,
  ICoverageData,
  StatusUpdateModalVersion,
} from 'app/models/Tasks/Tasks';
import moment from 'moment-timezone';
import { checkIsLatestAssignedUsersChanged, checkIsLatestCCChanged } from '../CreateTask/FormatData';
import { getStatusUpdateReason, getUserSubTaskStatus, getUserTaskStatus } from '../TaskDetails/helper';
import {
  getTodaysFormattedMomentDate,
  setPstTimeOffset,
} from '@AMIEWEB/AmWorkDesk/CoverageDesk/CoverageModal/assignHelper';
import { ITaskCommentPayload } from 'app/models/Tasks/Comments';
import { IUserInfo } from 'oidc/user.redux';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { getEmployeeId } from '@AMIEWEB/Notification/Tasks/Common_v2/utils';

export const formatTaskData = (payload: ITask) => {
  return {
    ...payload,
    description: payload.description?.replace(/\r?\n/g, '\r\n'),
    assignedTo: Array.isArray(payload.assignedTo)
      ? payload.assignedTo?.map(emp => emp?.empId)
      : payload.assignedTo.empId,
    cc: payload.cc && payload.cc.length > 0 ? payload.cc?.map(c => c.empId) : [],
  };
};

export const formatInternalTaskDetails = (task: any) => {
  const latestUpdateId = task?.taskStatusDetails?.length - 1;
  task.assignedTo = task.assignedTo?.map(
    t => new IUser(t.assignedToFirstName, t.assignedToLastName, t.assignedToId, t.statusId),
  );
  task.cc = task.cc?.map(t => new IUser(t.ccFirstName, t.ccLastName, t.ccId, t.statusId));
  const statusArray = getUserTaskStatus(task) ?? [];
  return {
    rootTaskId: task.rootTaskId,
    placementId: task.placementId,
    taskId: task.taskId,
    assignedTo: task.assignedTo,
    createdBy: new IUser(task.createdByFirstName, task.createdByLastName, task.createdById),
    candidate: new IUser(task.candidateFirstName, task.candidateLastName, task.candidateId),
    task: task.task,
    taskName: task.task,
    taskNameId: task.taskNameId,
    companyId: task.companyId,
    companyBrandName: task.companyBrandName,
    orderId: task.orderId,
    facilityId: task.facilityId,
    facilityName: task.facilityName,
    description: task.description,
    dueDate: task.dueDate,
    taskCategory: task.taskCategory,
    taskCategoryId: task.taskCategoryId,
    statusId: task.statusId,
    createdDate: task.sentDate,
    cc: task?.cc,
    statusUpdateReason: task?.taskStatusDetails?.[latestUpdateId]?.statusUpdateReason ?? task?.statusUpdateReason,
    statusUpdatedByName: task?.taskStatusDetails?.[latestUpdateId]?.statusUpdatedByName ?? task?.statusUpdatedByName,
    statusUpdatedDate: task?.taskStatusDetails?.[latestUpdateId]?.statusUpdatedDate ?? task?.statusUpdatedDate,
    statusUpdatedById: task?.taskStatusDetails?.[latestUpdateId]?.statusUpdatedById ?? task?.statusUpdatedById,
    statusUpdateReasonId: task?.taskStatusDetails?.[latestUpdateId]?.statusUpdateReasonId ?? task?.statusUpdateReasonId,
    role: task?.role,
    placementStatus: task?.placementStatus,
    placementStatusId: task?.placementStatusId,
    taskSource: task?.taskSource,
    attachments: task?.attachments,
    subCategory1: task?.subCategory1,
    subCategory2: task?.subCategory2,
    taskStatusDetails: task?.taskStatusDetails,
    statusArray: statusArray,
    taskCoverageActions: task?.taskCoverageActions,
    comments: task?.comments ?? [],
    taskPriorityId: task?.taskPriorityId,
    unitId: task?.unitId,
    sharedDeskAssociatedUsers: task?.sharedDeskAssociatedUsers,
  };
};

export const CheckIdsForCreatedInError = (task: IInternalTaskModalDetails, comments: ITaskComments[]) => {
  const selectedArray: number[] = [];
  const CreatedByErrorTaskId = task?.taskStatusDetails?.find(element => element?.statusUpdateReasonId === 5);
  if (CreatedByErrorTaskId) {
    selectedArray.push(task?.taskId);
  }
  comments?.forEach(subTask => {
    if (subTask?.taskStatusDetails?.find(element => element?.statusUpdateReasonId === 5)) {
      const existingId = selectedArray?.find(id => id === subTask?.taskId);
      if (!existingId) selectedArray.push(subTask?.taskId);
    }
  });
  return selectedArray;
};

export const formatSubTaskStatusArray = (comments: any) => {
  const statusArray: any[] = [];
  comments?.map(comment => {
    const userStatus = getUserSubTaskStatus(comment);
    statusArray.push(userStatus);
  });
  return statusArray;
};

export const formatCurrentUserSubTaskStatusDetails = (subtasks, userInfo) => {
  const subTaskStatusDetails: any[] = [];
  subtasks?.map(subTask => {
    const statusDetails = getStatusUpdateReason(subTask, StatusUpdateModalVersion.SUBTASK, userInfo);
    subTaskStatusDetails.push(statusDetails);
  });
  return subTaskStatusDetails;
};

export const formatCommentList = (comments: any, subTaskStatusList: any[], currentUserSubTaskStatusDetails: any[]) => {
  return comments?.map((comment, idx) => ({
    taskId: comment?.taskId,
    subject: comment?.subject,
    comment: comment?.text,
    createdBy: new IUser(comment?.senderFirstName, comment?.senderLastName, comment?.senderId),
    sentDate: comment?.sentDate,
    webTask: comment?.webTask,
    attachments: comment?.attachments,
    tos: comment?.tos,
    ccs: comment?.ccs,
    dueDate: comment?.dueDate,
    taskStatusDetails: comment?.taskStatusDetails,
    statusArray: subTaskStatusList[idx],
    currentUserStatusDetails: currentUserSubTaskStatusDetails[idx],
    taskCoverageAction: comment?.taskCoverageActions ?? [],
    taskPriorityId: comment?.taskPriorityId,
    sharedDeskAssociatedUsers: comment?.sharedDeskAssociatedUsers,
  }));
};

export const formatSaveCurrentComment = (comment: any, commentId: any, payload: any) => {
  return {
    taskId: payload.originalTaskId,
    comment: comment.commentText,
    createdBy: payload.createdBy,
    sentDate: comment.commentCreatedDate,
    dueDate: payload?.dueDate,
    webTask: true,
    tos: payload.assignedTo,
    ccs: payload.ccUsers,
    taskCoverageAction: payload?.taskCoverageAction ? [payload?.taskCoverageAction] : [],
    sharedDeskAssociatedUser: payload?.sharedDeskAssociatedUser,
  } as ITaskComments;
};

export const formatSaveComment = (payload: any, attachmentAttributes: any) => {
  const defaultComment = 'Attachment Added';
  return {
    originalTaskId: payload?.originalTaskId,
    subject: payload?.subject,
    commentCreatedBy: payload?.commentCreatedBy,
    commentCreatedDate: payload?.commentCreatedDate,
    commentText: payload.commentText ? payload?.commentText?.replace(/\r?\n/g, '\r\n') : defaultComment,
    ccEmployees: payload?.ccEmployees,
    existingAttachments: attachmentAttributes ? attachmentAttributes : [],
    assignedToEmployees: payload?.assignedToEmployees,
    dueDate: payload?.dueDate,
    taskCoverageAction: payload?.taskCoverageAction,
    taskPriorityId: payload?.taskPriorityId,
    sharedDeskAssociatedUser: payload?.sharedDeskAssociatedUser,
  };
};

export const formatEditInternalTask = (latestData: IFormatedInternalTaskData, initialTask) => {
  return (
    latestData?.task === initialTask?.task &&
    moment(latestData.dueDate)?.format('MM/DD/YYY') === moment(initialTask?.dueDate)?.format('MM/DD/YYY') &&
    latestData?.description === initialTask?.description &&
    checkIsLatestAssignedUsersChanged(latestData?.assignedTo, initialTask) &&
    checkIsLatestCCChanged(latestData.ccValues, initialTask)
  );
};

export const formatUpdateInternalStatus = (
  updateStatus: any,
  data: any,
  userInfo: any,
  coverageData?: ICoverageData,
) => {
  const user = userInfo?.userInfo;
  const sharedDeskUser = userInfo?.userInfo?.sharedProfile;
  updateStatus.rootTaskId = data?.rootTaskId;
  updateStatus.recipientId = coverageData
    ? coverageData?.coveredUser?.id
    : updateStatus?.taskCoverageAction
    ? updateStatus?.taskCoverageAction?.coveredForEmployeeId
    : parseInt(getEmployeeId(userInfo?.userInfo));
  updateStatus.statusUpdatedDate = moment.tz(new Date(), 'America/Los_Angeles').format();
  if (updateStatus?.taskCoverageAction) {
    updateStatus.statusUpdatedByName =
      updateStatus?.taskCoverageAction?.coveredByEmployeeFirstName +
      ' ' +
      updateStatus?.taskCoverageAction?.coveredByEmployeeLastName;
    updateStatus.statusUpdatedById = updateStatus?.taskCoverageAction?.coveredByEmployeeId;
  } else {
    updateStatus.statusUpdatedByName = !isNullOrUndefined(userInfo?.userInfo?.sharedProfile)
      ? sharedDeskUser?.firstName + ' ' + sharedDeskUser?.lastName
      : user?.firstName + ' ' + user?.lastName;
    updateStatus.statusUpdatedById = parseInt(getEmployeeId(userInfo?.userInfo));
  }
};

export const formatInternalGridData = data => {
  const formattedData: IGridData[] = data?.map(item => ({
    taskId: item?.taskId,
    taskName: item?.taskName,
    taskCategory: item?.taskCategory,
    createdByFirstName: item?.createdByFirstName,
    createdByLastName: item?.createdByLastName,
    dueDate: item?.dueDate,
    placementId: item?.placementId,
    orderId: item?.orderId,
    candidateFirstName: item?.candidateFirstName,
    candidateLastName: item?.candidateLastName,
    candidateId: item?.candidateId,
    facilityName: item?.facilityName,
    facilityId: item?.facilityId,
    companyId: item?.companyId,
    companyBrandName: item?.companyBrandName,
    taskStatusId: item?.assignedToList[0]?.statusId,
    assignedToList: item?.assignedToList?.map(i => `${i?.assignedToFirstName} ${i?.assignedToLastName}`),
    originalTaskId: item?.originalTaskId,
    rootTaskId: item?.rootTaskId,
    taskDescription: item?.taskDescription?.replace(/\r?\n/g, '\r\n'),
    assignedTo: item?.assignedToList,
    createdDate: item?.sentDate,
    comments: item?.comments,
  }));
  return formattedData;
};

export const formatCommentToTask = (comment: ITaskComments) => {
  return {
    assignedToIds: comment.tos,
    ccIds: comment.ccs,
    companyId: comment.companyId,
    description: comment.comment,
    dueDate: comment.dueDate,
    task: comment.subject,
    taskCategory: comment.taskCategory,
    taskCategoryText: comment.taskCategoryText,
    taskId: comment.taskId,
    taskNameId: comment.taskNameId,
    sentByUser: comment.createdBy,
    legacyParentTaskId: comment.originalTaskId,
    taskCoverageAction: Array.isArray(comment.taskCoverageAction)
      ? comment.taskCoverageAction[0]
      : comment.taskCoverageAction,
    taskPriorityId: comment.taskPriorityId,
    sharedDeskAssociatedUser: comment?.sharedDeskAssociatedUser,
  };
};

export const getTaskDetailCommentForPost = (userInfo: IUserInfo, taskId: number, rootTaskId: number, text: string) => {
  const createdDate = setPstTimeOffset(getTodaysFormattedMomentDate());
  const commentPayload: ITaskCommentPayload = {
    createByEmployeeId: userInfo.employeeId,
    createByFirstName: userInfo.firstName,
    createByLastName: userInfo.lastName,
    createDate: createdDate,
    text: text,
    taskId: taskId,
    rootTaskId: rootTaskId,
  };
  return commentPayload;
};
