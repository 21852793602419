import { isAccountManagement, isCredentialing, isRecruitment, isSupportAdmin } from '../../../oidc/userRoles';

export const DEFAULT_HOME_PAGE_LOCAL_STORAGE_KEY = 'default_home_page';

export const getAuthorizedHomeOverlay = (roles: string[]) => {
  if ((isRecruitment(roles) && (isAccountManagement(roles) || isCredentialing(roles))) || isSupportAdmin(roles))
    return [
      { tag: 'homeOverlay.credentialing', disabled: false, path: '/' },
      { tag: 'homeOverlay.recruiter', disabled: false, path: '/' },
    ];
  return null;
};

export const authorizeHomePage = (roles: string[]) => {
  if (isSupportAdmin(roles)) return { credential: true, recruiter: true };
  else if (isAccountManagement(roles) || isCredentialing(roles)) return { credential: true };
  else if (isRecruitment(roles)) return { recruiter: true };
};
