import { useDecision } from '@optimizely/react-sdk';
import { ff_global_caching_static_api_response } from 'app/constants/FeatureFlags/Global/keys';
import React from 'react';
import { SessionKey, removeSessionValue, setSessionValue } from './sessionStorage/sessionHelpers';
import { CacheType } from 'utils/networkCache';

/**
 * Retrieve the cache buster value from the feature flag and store it in local storage.
 * This value is used to bust the cache on the static API response.
 */
export const useApiCacheBuster = () => {
  const [cacheBuster] = useDecision(ff_global_caching_static_api_response);
  const enabled = cacheBuster.enabled;
  React.useEffect(() => {
    if (cacheBuster.enabled) {
      setSessionValue(SessionKey.cacheBuster, cacheBuster.variables?.cache_store);
    } else {
      removeSessionValue(SessionKey.cacheBuster);
      Object.values(CacheType).forEach(item => {
        caches.delete(item);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);
};
