import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Popper,
  Radio,
  TextField,
  Typography,
} from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getDateRangeLabelWithNull } from 'utils/dates/dateExtraction';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { makeStyles } from 'tss-react/mui';
import { CustomTooltip } from '@AMIEWEB/Common';
import { TextFieldCalendar } from './TextFieldCalendar';

export enum TimeOffMode {
  ToBeDetermined = 0,
  AddTimeOff = 1,
  NotRequired = 2,
}

export interface ITimeOff {
  requestedTimeOff: {
    startDate: string;
    endDate: string;
  }[];
  requestedTimeOffMode: TimeOffMode;
  isRequestedTimeOffMakeUp: boolean;
  requestedTimeOffNote: string;
}

type Orientation = 'horizontal' | 'vertical';

const useStyles = makeStyles<{ orientation: Orientation }>()((theme, { orientation }) => ({
  timeOffOptionRoot: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  disabled: {
    color: '#75787B',
    opacity: 1,
    pointerEvents: 'none',
  },
}));

export const TimeOffRequest: React.FC<{
  value: ITimeOff;
  onChange: (newValue: ITimeOff) => void;
  orientation?: Orientation;
  calendarControlProps?: any;
  disabledContent?: boolean;
}> = ({ value, onChange, orientation = 'horizontal', calendarControlProps = {}, disabledContent }) => {
  const { t } = useTranslation();
  const { cx, classes } = useStyles({ orientation });
  const isVertical = orientation === 'vertical';

  const handleChange = (
    key: 'requestedTimeOff' | 'requestedTimeOffMode' | 'isRequestedTimeOffMakeUp' | 'requestedTimeOffNote',
    state,
  ) => {
    onChange({ ...value, [key]: state });
  };

  const handleDelete = (index: number) => {
    const existingValues = [...value.requestedTimeOff];
    existingValues.splice(index, 1);
    handleChange('requestedTimeOff', existingValues);
  };

  const selectedTimeOff = React.useMemo(
    () => (
      <Grid container direction="column" spacing={1} sx={{ marginLeft: '24px' }}>
        <Grid item>
          <Grid container spacing={1}>
            {value?.requestedTimeOffMode === TimeOffMode.AddTimeOff
              ? value?.requestedTimeOff?.map((timeOff, index) => {
                  const label = getDateRangeLabelWithNull(timeOff?.startDate || '', timeOff?.endDate || '');
                  return (
                    <Grid key={label} item>
                      <Chip
                        variant="outlined"
                        label={label}
                        onDelete={() => handleDelete(index)}
                        deleteIcon={<CloseIcon />}
                        disabled={disabledContent}
                        className={disabledContent ? classes.disabled : ''}
                      />
                    </Grid>
                  );
                })
              : null}
          </Grid>
        </Grid>

        {value?.requestedTimeOffMode === TimeOffMode.AddTimeOff && value?.requestedTimeOff?.length > 0 ? (
          <Grid item>
            <FormControlLabel
              label={
                <div style={{ display: 'flex', flexDirection: 'row', gap: '6px' }}>
                  <span>{t('placement.create.requestedTimeOff.makeUpTimeOff')}</span>
                  <CustomTooltip placement={'top'} tooltipContent={t('placement.create.requestedTimeOff.timeOffInfo')}>
                    <InfoOutlinedIcon
                      sx={{ fontSize: '12px', alignSelf: 'center' }}
                      color={!disabledContent ? 'primary' : 'disabled'}
                    />
                  </CustomTooltip>
                </div>
              }
              control={
                <Checkbox size="small" disabled={disabledContent} className={disabledContent ? classes.disabled : ''} />
              }
              checked={value.isRequestedTimeOffMakeUp}
              onChange={(e, state) => handleChange('isRequestedTimeOffMakeUp', state)}
            />
          </Grid>
        ) : null}
      </Grid>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleChange, value.isRequestedTimeOffMakeUp, value.requestedTimeOff, value.requestedTimeOffMode],
  );

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography
          variant="body1"
          className={disabledContent ? classes.disabled : ''}
          color={!disabledContent && 'charcoal'}
          fontWeight={500}
        >
          {t('placement.create.requestedTimeOff.title')}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={isVertical ? 0 : 4} direction={isVertical ? 'column' : 'row'}>
          <Grid item>
            <Box className={cx({ [classes.timeOffOptionRoot]: isVertical })}>
              <RadioBtn
                label={t('placement.create.requestedTimeOff.addTimeOff')}
                value={TimeOffMode.AddTimeOff}
                checked={value.requestedTimeOffMode === TimeOffMode.AddTimeOff}
                onChange={state => handleChange('requestedTimeOffMode', state)}
                disabled={disabledContent}
                className={disabledContent ? classes.disabled : ''}
              />
              <Selector
                orientation={orientation}
                value={value.requestedTimeOff || []}
                onChange={state => handleChange('requestedTimeOff', state)}
                calendarControlProps={calendarControlProps}
                disabled={value.requestedTimeOffMode !== TimeOffMode.AddTimeOff || disabledContent}
                className={disabledContent ? classes.disabled : ''}
              />
            </Box>
          </Grid>
          {isVertical ? <Grid item>{selectedTimeOff}</Grid> : null}
          <Grid item>
            <RadioBtn
              label={t('placement.create.requestedTimeOff.toBeDetermined')}
              value={TimeOffMode.ToBeDetermined}
              checked={value.requestedTimeOffMode === TimeOffMode.ToBeDetermined}
              onChange={state => handleChange('requestedTimeOffMode', state)}
              disabled={disabledContent}
              className={disabledContent ? classes.disabled : ''}
            />
          </Grid>
          <Grid item>
            <RadioBtn
              label={t('placement.create.requestedTimeOff.notRequired')}
              value={TimeOffMode.NotRequired}
              checked={value.requestedTimeOffMode === TimeOffMode.NotRequired}
              onChange={state => handleChange('requestedTimeOffMode', state)}
              disabled={disabledContent}
              className={disabledContent ? classes.disabled : ''}
            />
          </Grid>
        </Grid>
      </Grid>
      {!isVertical ? <Grid item>{selectedTimeOff}</Grid> : null}
      <Grid item>
        <TextField
          multiline
          fullWidth
          variant="filled"
          color="primary"
          value={value.requestedTimeOffNote ?? ''}
          maxRows={5}
          inputProps={{
            maxLength: 500,
          }}
          label={t('placement.create.requestedTimeOff.timeOffNotes')}
          onChange={event => handleChange('requestedTimeOffNote', event?.target?.value)}
          disabled={disabledContent}
          className={disabledContent ? classes.disabled : ''}
        />
      </Grid>
    </Grid>
  );
};

const RadioBtn: React.FC<{
  label: string;
  value: TimeOffMode;
  checked?: boolean;
  onChange: (state: TimeOffMode) => void;
  disabled?: boolean;
  className?: string;
  [key: string]: any;
}> = ({ label, value, checked = false, onChange, disabled, className, ...props }) => (
  <FormControlLabel
    control={<Radio />}
    color="primary"
    value={value}
    label={label}
    checked={checked}
    onChange={() => onChange(value)}
    disabled={disabled}
    className={className}
    sx={{ marginRight: '8px' }}
    {...props}
  />
);

const useTempStyles = makeStyles()(theme => ({
  popper: {
    zIndex: 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
}));

const Selector: React.FC<{
  value;
  onChange;
  orientation?: Orientation;
  calendarControlProps;
  disabled;
  className?: string;
}> = ({ value, onChange, orientation = 'horizontal', calendarControlProps, disabled, className }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = !disabled && Boolean(anchorEl);
  const { classes } = useTempStyles();
  const [arrowRef, setArrowRef] = React.useState(null);
  const closePicker = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton
        color="primary"
        size="small"
        onClick={e => setAnchorEl(prev => (!prev ? e.target : null))}
        disabled={disabled}
        className={className}
      >
        <CalendarTodayIcon sx={{ fontSize: '18px', alignSelf: orientation === 'vertical' ? 'center' : undefined }} />
      </IconButton>
      {false ? <span className={classes.arrow} ref={setArrowRef} /> : null}
      <Popper
        open={open}
        anchorEl={anchorEl}
        className={classes.popper}
        disablePortal
        placement="top-start"
        modifiers={[
          {
            name: 'flip',
            enabled: true,
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              boundary: 'viewport',
            },
          },
          {
            name: 'arrow',
            enabled: true,
          },
        ]}
        sx={{ zIndex: '9000 !important', mb: '6px !important' }}
      >
        <Paper sx={{ paddingTop: '12px' }}>
          <TextFieldCalendar
            closePicker={closePicker}
            value={value}
            open={open}
            onChange={onChange}
            {...calendarControlProps}
          />
        </Paper>
      </Popper>
    </>
  );
};
