import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { useWatch } from 'react-hook-form';
import { useEditableRequirement } from './EditRequirementsHelper';
import { DOBRequirementCheck } from './EditablePassFailLogic';

export const useEditableRequirementDOB = () => {
  // DOB
  const dob: string | undefined = useWatch({ name: 'dob' });
  const transportation: any = useWatch({ name: 'transportationChosen' });

  useEditableRequirement({
    data: dob,
    secondaryData: transportation?.transportation,
    requirementId: IPlacementRequirementKey.dob,
    requirementKey: 'dob',
    requirementCheck: DOBRequirementCheck,
    shouldAddFailedRequirement: transportation?.transportation !== 'Unknown',
    failRequirement: {
      id: IPlacementRequirementKey.dob,
      message: 'DOB: Missing',
      tooltip: {
        message: 'Date of Birth is required',
      },
    },
  });
};
