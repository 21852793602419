import { Grid, Typography, materialUiXGrid } from 'amn-ui-core';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { XGrid } from 'app/components/Common/XGrid/XGrid';
import { getOffContractGridColumns } from './utils';
import { UploadDocumentWrapper } from './UploadDocumentWrapper';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { selectGridSelection } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.selector';
import { selectCreateOrderMiscellaneousData, selectCreateOrderOcbr, selectOcbrDetails } from 'store/redux-store/create-order-ocbr/selector';
import { convertNeSwToNwSe } from 'google-map-react';

export const RateCardTable = () => {
  const gridApi = materialUiXGrid.useGridApiRef();
  const { rateCardRows: rows } = useSelector(selectCreateOrderOcbr);
  const [columns, setColumns] = useState<any>([]);
  const [sortModel, setSortModel] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const typeAheadOptions = useSelector(selectCreateOrderMiscellaneousData);
  const history = useHistory();
  const [addDocsModalOpen, setAddDocsModalOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { selectedData: gridSelections } = useSelector(selectGridSelection);

  const onSortModelChange = model => {
    setSortModel(model);
  };

  const handleResetColumns = () => {
    setColumns(getOffContractGridColumns(history));
  };

  const onPageChange = (page: number) => {
    setPageNumber(page);
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };

  useEffect(() => {
    setColumns(getOffContractGridColumns(history));
  }, []);

  const grid = useMemo(() => {
    return (
      <React.Fragment>
        <XGrid
          apiRef={gridApi}
          page={pageNumber}
          pageSize={pageSize}
          paginationMode={'client'}
          sortingOrder={['desc', 'asc']}
          title=""
          columns={columns}
          rows={rows || []}
          rowsPerPageOptions={[20, 50, 100]}
          headerHeight={60}
          disableTitleCount={true}
          checkboxSelection
          hideFooter={!rows || rows?.length < 1}
          defaultRowsPerPage={20}
          autoHeight={true}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          selectionModel={gridSelections}
          toolbarProps={{
            refreshButton: true,
            resetButton: true,
            resetColumns: () => {
              handleResetColumns();
            },
            leftActions: [
              {
                title: 'Approval Documents',
                Icon: CloudUploadOutlinedIcon,
                disabled: !gridSelections.length,
                onClick: () => setAddDocsModalOpen(true),
              },
            ],
          }}
        />
      </React.Fragment>
    );
  }, [columns, rows, typeAheadOptions, onPageChange, onPageSizeChange, pageSize, pageNumber]);

  return (
    <React.Fragment>
      <Grid container spacing={2} justifyContent="flex-start" xs={12}>
        <Grid item xs={12}>
          <Typography className="page-title">{`Off Contract Rate Elements`}</Typography>
        </Grid>
        <Grid item xs={12}>
          {grid}
        </Grid>
      </Grid>
      <UploadDocumentWrapper
        addDocsModalOpen={addDocsModalOpen}
        setAddDocsModalOpen={setAddDocsModalOpen}
        gridSpecs={{ gridSelections, rows }}
      />
    </React.Fragment>
  );
};
