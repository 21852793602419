import { AvailableStartDate } from 'app/components/Placement/PlacementDetails/Edit/Components/AvailableStartDate';
import { editableFields, editAccessor } from 'app/components/Placement/PlacementDetails/editUtils';
import { missingField } from 'app/constants';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { getDateRangeLabel } from 'utils/dates/dateExtraction';
import { SummaryRequestedTimeOff } from '../../../RequirementsAndQualifications/SummaryRequestedTimeOff';
import { TableSection } from '../../../customComponents/TableSection';
import { getRequirementCheck } from '../../../helper';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import {
  selectEditableRequirementTypes,
  selectServiceFailures,
  selectSubmissionInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { RequirementsRow } from '../../TableComponents/TableComponents';
import { Typography, Tooltip, Box } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { requirementType } from 'app/models/Placement/PlacementSummary';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import { PlacementStartDate } from 'app/components/Placement/PlacementDetails/Edit/Components/PlacementStartDate';
import { PlacementEndDate } from 'app/components/Placement/PlacementDetails/Edit/Components/PlacementEndDate';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import { PlacementOrderType } from 'app/models/Placement/PlacementDetails';
import { useDecision } from '@optimizely/react-sdk';
import { ff_new_create_placement_modal_layout } from 'app/constants/FeatureFlags/Placement/Keys';
import { SummaryTimeOffRequest } from '@AMIEWEB/Placement/CreateNewPlacement/Candidate/Edit/SummaryTimeOffRequest';

const useStyles = makeStyles()({
  tooltipArrow: {
    color: '#fff',
    '&::before': {
      border: '1px solid #555555',
    },
  },
  tooltipBackground: {
    backgroundColor: '#fff',
    color: '#555555',
    border: '1px solid #555555',
    fontSize: '12px',
  },
  candRiskIcon: {
    fontSize: 12,
    margin: '0 0.2rem 0.5rem',
  },
  candAvailableStartDateLbl: {
    fontSize: 14,
    margin: '0 0.5rem 0 0.2rem',
  },
  placementDateRoot: {
    display: 'column',
  },
});

export const Dates = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const {
    orderReq: {
      order: { dates: orderReq },
    },
    requirementFilter,
  } = useSelector(selectSubmissionInfo);
  const orderType = useSelector(selectPlacementOrderType);
  const { availabilityDate: availabilityDateReqType, placementDate: placementDateReqType } =
    useSelector(selectEditableRequirementTypes);
  const { promiseInProgress } = usePromiseTracker({
    area: 'placement-submission-dates-order-requirement',
    delay: 0,
  });
  const { datesOrderRequirement: serviceFailed } = useSelector(selectServiceFailures);
  const { placementId } = useContext(AddOnContext);
  const dispatch = useDispatch();
  const { display: displayAvDate } = useRequirementFilter(
    'submission-order',
    requirementFilter,
    availabilityDateReqType?.requirement,
  );
  const { display: displayPlacementDate } = useRequirementFilter(
    'submission-order',
    requirementFilter,
    placementDateReqType?.requirement,
  );
  const [newPlacementLayoutFlag] = useDecision(ff_new_create_placement_modal_layout);

  return displayAvDate || displayPlacementDate ? (
    <RequirementsRow
      id="placement-submission-order-dates"
      title={t('placement.profile.summary.submission.order.dates.title')}
      requirementCell={
        orderReq ? (
          <RowKey
            classes={classes}
            data={orderReq.data}
            availabilityType={availabilityDateReqType?.requirement ?? -1}
            placementDateType={placementDateReqType?.requirement ?? -1}
            displayAvDate={displayAvDate}
            displayPlacementDate={displayPlacementDate}
            t={t}
            orderType={orderType}
          />
        ) : (
          missingField
        )
      }
      candidateCell={<RowValue classes={classes} type={availabilityDateReqType?.requirement} t={t} flag={newPlacementLayoutFlag?.enabled} />}
      loading={promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() =>
        dispatch(placementSummaryActions.getDatesOrderRequirement({ placementId, isRetryingService: true }))
      }
    />
  ) : null;
};

const RowKey = ({
  data = { orderStartDate: null, orderEndDate: null, numOfWeeks: null, placementDateType: null },
  availabilityType,
  placementDateType,
  displayAvDate,
  displayPlacementDate,
  classes,
  t,
  orderType,
}: {
  data?: { orderStartDate: any; orderEndDate: any; numOfWeeks: any; placementDateType: requirementType | null };
  availabilityType: requirementType;
  placementDateType: requirementType;
  displayAvDate: boolean;
  displayPlacementDate: boolean;
  t: any;
  classes: any;
  orderType: PlacementOrderType;
}) => {
  const items = React.useMemo(() => {
    const items = [] as {
      icon?: any;
      primaryContent?: any;
      secondaryContent?: any;
    }[];
    if (displayAvDate)
      items.push({
        primaryContent: `${t('placement.profile.summary.submission.order.dates.line1')}\n${getDateRangeLabel(
          data.orderStartDate,
          data.orderEndDate,
        )}\n${data.numOfWeeks ? `${data.numOfWeeks} ${t('Weeks')}` : missingField}`,
        icon: availabilityType !== requirementType.risk ? getRequirementCheck(availabilityType) : null,
      });
    if (displayPlacementDate)
      items.push({
        primaryContent: (
          <div>
            <Box className={classes.placementDateRoot}>
              <Box>{t('placement.profile.header.placementStartDate')}</Box>
              <PlacementStartDate id={`${editAccessor}-${editableFields.placementStartDate}`} maxWidth={215} />
            </Box>
            <Box className={classes.placementDateRoot}>
              <Box>{t('placement.profile.header.placementEndDate')}</Box>
              <PlacementEndDate id={`${editAccessor}-${editableFields.placementEndDate}`} maxWidth={215} />
            </Box>
          </div>
        ),
        icon:
          !!placementDateType && orderType === PlacementOrderType.StrikeOrderInHsg
            ? getRequirementCheck(placementDateType)
            : null,
      });
    return items;
  }, [data, availabilityType, placementDateType, displayAvDate, displayPlacementDate]);

  return <TableSection items={items} />;
};

const RowValue = ({ type, t, classes, flag }: { type?: requirementType; t: any; classes: any; flag: boolean }) => (
  <div>
    <div style={{ paddingBottom: '12px' }}>
      <div style={{ paddingBottom: '5px' }}>
        {type === 3 ? (
          <Tooltip
            title={t('placement.profile.summary.submission.order.dates.line3')}
            arrow
            placement="bottom"
            classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
          >
            <span className={classes.candRiskIcon}> {getRequirementCheck(3)}</span>
          </Tooltip>
        ) : (
          <></>
        )}
        <span className={classes.candAvailableStartDateLbl}>
          {t('placement.profile.summary.submission.order.dates.line4')}
        </span>
      </div>
      <div style={{ paddingLeft: type === 3 ? '30px' : '0px' }}>
        <AvailableStartDate id={`${editAccessor}-${editableFields.availabilityDate}`} />
      </div>
    </div>
    {!flag ? (
    <>
      <SummaryRequestedTimeOff
        id={`${editAccessor}-${editableFields.requestedTimeOff}`}
        labelStyle={{ fontSize: 14 }}
        labelRenderer={
          <Typography variant="body1">{t('placement.profile.summary.submission.order.dates.line5')}</Typography>
        }
      />
    </>
     ) : ( 
    <>
      <SummaryTimeOffRequest />
    </>
     )} 
  </div>
);
