import { createAction } from "@reduxjs/toolkit";
import { IPlacementSummaryRequest } from "./slice";

const getPlacemnetConsolidatedDetails = createAction<IPlacementSummaryRequest>('GET_PLACEMENT_SUMMARY_CONSOLIDATED_DETAILS');
const getPlacemnetConsolidatedInitialDetails = createAction<IPlacementSummaryRequest>('GET_PLACEMENT_SUMMARY_CONSOLIDATED_INITIAL_DETAILS');
const getPlacemnetConsolidatedInitialBulkDetails = createAction<IPlacementSummaryRequest>('GET_PLACEMENT_SUMMARY_CONSOLIDATED_INITIAL_BULK_DETAILS');
const getPlacemnetConsolidatedDetailsSuccess = createAction('GET_PLACEMENT_SUMMARY_CONSOLIDATED_DETAILS_SUCCESS');
const getPlacemnetConsolidatedInitialDetailsSuccess = createAction('GET_PLACEMENT_SUMMARY_CONSOLIDATED_INITIAL_DETAILS_SUCCESS');
const getPlacemnetConsolidatedInitialBulkDetailsSuccess = createAction('GET_PLACEMENT_SUMMARY_CONSOLIDATED_INITIAL_BULK_DETAILS_SUCCESS');

export const consolidatedServiceActions = {
    getPlacemnetConsolidatedDetails,
    getPlacemnetConsolidatedInitialDetails,
    getPlacemnetConsolidatedInitialBulkDetails,
    getPlacemnetConsolidatedDetailsSuccess,
    getPlacemnetConsolidatedInitialDetailsSuccess,
    getPlacemnetConsolidatedInitialBulkDetailsSuccess
}