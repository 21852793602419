import { RowContent, RowHead } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/ProfileTab/Cards/styles';
import { Row, RowContainer } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/CustomComponents/styles';
import { Checkbox, Typography } from 'amn-ui-core';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import HalfwayIcon from 'app/assets/images/CandidateProfile/Halfway.svg';
import ErrorOutline from 'app/assets/images/CandidateProfile/error_outline-24px.svg';
import UnverifiedIcon from 'app/assets/images/CandidateHeader/HearingBack.svg';
import { getWarningColor } from '../../helper';
import { useStyles } from '../utils';
import { theme } from 'styles/global-styles';
import { Props } from './types';

function RowSection(props: Props) {
  const { classes } = useStyles();
  return (
    <LayoutGridItem item style={{ width: '100%' }}>
      <RowContainer>
        <Row>
          <RowHead style={{ wordBreak: 'keep-all', whiteSpace: 'normal' }}>
            <div className={classes.normalText}>
              <LayoutGrid direction="row" wrap="nowrap" spacing={0} alignItems="flex-start">
                <LayoutGridItem>
                  {!props.showHalfwayProgress ? (
                    <Checkbox
                      disableFocusRipple
                      disableRipple
                      disabled={true}
                      className={`${classes.inlineBoxEditItemPadding} ${classes.inlineBoxEditMainCheckItem}`}
                      icon={
                        !props.showAlert ? (
                          props.preData !== '' ? (
                            <CheckCircleIcon style={{ color: props.color }} />
                          ) : (
                            <CircleUnchecked />
                          )
                        ) : (
                          <ErrorOutlinedIcon style={{ color: theme.palette.system.crimson }} />
                        )
                      }
                      checkedIcon={<CheckCircleIcon />}
                    />
                  ) : (
                    <Checkbox
                      disableFocusRipple
                      disableRipple
                      disabled={true}
                      className={`${classes.inlineBoxEditItemPadding} ${classes.inlineBoxEditMainCheckItem}`}
                      icon={
                        !props.showHalfwayProgressSecondaryIcon ? (
                          <img src={HalfwayIcon} alt="Progress" style={{ paddingLeft: 1 }} />
                        ) : (
                          <img src={ErrorOutline} alt="Error" className={classes.yellowAlert} />
                        )
                      }
                      checkedIcon={<CheckCircleIcon />}
                    />
                  )}
                </LayoutGridItem>
                <LayoutGridItem>
                  <div className={`${classes.inlineBoxEditMainLblItem}`}>
                    <span>{props.header}</span>
                    {props.compact && (
                      <>
                        <Typography gutterBottom component="span" style={{ padding: '0 5px' }}>
                          {`|`}
                        </Typography>
                        <Typography gutterBottom component="span">
                          {`Compact`}
                        </Typography>
                      </>
                    )}
                    {props.warningMessage && (
                      <>
                        <Typography gutterBottom component="span" style={{ padding: '0 5px' }}>
                          {`•`}
                        </Typography>
                        <Typography
                          gutterBottom
                          component="span"
                          style={{ color: getWarningColor(props.warningMessage) }}
                        >
                          {props.warningMessage}
                        </Typography>
                      </>
                    )}
                    {props.unverifiedIcon && (
                      <>
                        <Typography gutterBottom component="span" style={{ padding: '0 5px' }}>
                          {`•`}
                        </Typography>
                        <img src={UnverifiedIcon} alt="UnVerified" />
                      </>
                    )}
                  </div>
                </LayoutGridItem>
              </LayoutGrid>
            </div>
          </RowHead>
          {props.editIcon && <RowContent className={classes.editLink}>{props.editData}</RowContent>}
        </Row>
      </RowContainer>
      {props.children}
    </LayoutGridItem>
  );
}

export default RowSection;
