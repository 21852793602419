import React, { useState } from 'react';
import { GetMomentOfDate } from 'utils/dates/moment';
import { Box, Divider, Grid, IconButton, Typography } from 'amn-ui-core';
import { missingField } from 'app/constants';
import { useSelector } from 'react-redux';
import { selectFacility, selectIsFacilityByIdRequestSuccess } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { useTranslation } from 'react-i18next';
import { BoxContainer } from '../../CustomComponents/BoxContainer';
import { FacilityDialog } from '@AMIEWEB/Facility/FacilityDetails/FacilityDialog';
import { RowSection, RowSubSection, TruncatedText, AtAGlanceSkeleton } from '../Utils';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { makeStyles } from 'tss-react/mui';
import ManageBestBetsForFacility from './ManageBestBetsForFacility';

const useStyles = makeStyles()(() => ({
  boxInlineHeadLabelTxt: {
    'font-weight': '600',
    'margin-top': '0.3rem',
  },
}));

export const AtAGlance = ({ isCreditHold }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const facility = useSelector(selectFacility);
  const facilityDetailsResponse = facility.facilityResponse;
  const [isFacilityActivityNotesOpen, setFacilityActivityNotesOpen] = useState<boolean>(false);
  const [isFacilityCreditNotesOpen, setFacilityCreditNotesOpen] = useState<boolean>(false);
  const [fixedRowHeaderWidth, setFixedRowHeaderWidth] = React.useState(0);
  const facilityLoading = useSelector(selectIsFacilityByIdRequestSuccess);

  const handleMeasureWidth = (width: number) => {
    setFixedRowHeaderWidth(oldWidth => Math.max(oldWidth, width));
  };

  const getDate = (date: string) => {
    const moment = GetMomentOfDate(date);
    if (moment.isValid()) return moment.format('MM/DD/YYYY');
    return '';
  };

  const boolToYesNo = (value: boolean) => {
    return value === undefined || value === null ? missingField : value ? 'Yes' : 'No';
  };

  const NotesIcon = () => (
    <IconButton
      color="secondary"
      size="medium"
      sx={theme => ({
        padding: 0,
        height: 32,
        width: 32,
      })}
    >
      <OpenInNewIcon />
    </IconButton>
  );

  return (
    <>
      <BoxContainer title={t('facility.atAGlance.title')} icon={<SummarizeOutlinedIcon sx={{ width: '19px' }} />}>
        {facilityLoading ? (
          <AtAGlanceSkeleton/>
        ) : (
          <Grid container direction="column">
            <RowSection
              header={t('facility.atAGlance.type')}
              data={facilityDetailsResponse?.facilityType || missingField}
              handleMeasureWidth={handleMeasureWidth}
              fixedHeaderWidth={fixedRowHeaderWidth}
            />
            <RowSection
              header={t('facility.atAGlance.description')}
              data={facilityDetailsResponse?.facilityDescription || missingField}
              handleMeasureWidth={handleMeasureWidth}
              fixedHeaderWidth={fixedRowHeaderWidth}
            />
            <RowSection
              header={t('facility.atAGlance.settings')}
              data={facilityDetailsResponse?.setting || missingField}
              handleMeasureWidth={handleMeasureWidth}
              fixedHeaderWidth={fixedRowHeaderWidth}
            />
            <RowSection
              header={t('facility.atAGlance.numberOfBeds')}
              data={facilityDetailsResponse?.bedSize || 0}
              handleMeasureWidth={handleMeasureWidth}
              fixedHeaderWidth={fixedRowHeaderWidth}
            />

            <Grid container pb="12px" alignItems="center" display="flex">
              <Box sx={{ width: '173px' }}>
                <Typography component="span" sx={{ fontSize: '12px !important', fontWeight: '600' }}>
                  {t('facility.atAGlance.bestBets')}
                </Typography>
              </Box>
              <ManageBestBetsForFacility />
            </Grid>

            <RowSection
              header={t('facility.atAGlance.travelersOnAssignment')}
              data={facilityDetailsResponse?.currentOA || 0}
              handleMeasureWidth={handleMeasureWidth}
              fixedHeaderWidth={fixedRowHeaderWidth}
            />
            <RowSection
              header={t('facility.atAGlance.teachingHospital')}
              data={boolToYesNo(facilityDetailsResponse?.isTeaching)}
              handleMeasureWidth={handleMeasureWidth}
              fixedHeaderWidth={fixedRowHeaderWidth}
            />
            <RowSection
              header={t('facility.atAGlance.magnetStatus')}
              data={boolToYesNo(facilityDetailsResponse?.isMagnetStatus)}
              handleMeasureWidth={handleMeasureWidth}
              fixedHeaderWidth={fixedRowHeaderWidth}
            />
            <RowSection
              header={t('facility.atAGlance.traumaCenter')}
              data={boolToYesNo(facilityDetailsResponse?.isTraumaCenter)}
              handleMeasureWidth={handleMeasureWidth}
              fixedHeaderWidth={fixedRowHeaderWidth}
            />
            <RowSection
              header={t('facility.atAGlance.specialProjects')}
              data={facilityDetailsResponse?.specialProjects || missingField}
              handleMeasureWidth={handleMeasureWidth}
              fixedHeaderWidth={fixedRowHeaderWidth}
            />


            <Divider style={{ marginBottom: '12px' }} />

            <RowSection
              header={<div className={classes.boxInlineHeadLabelTxt}>{`Credit`}</div>}
              data={``}
              handleMeasureWidth={handleMeasureWidth}
              fixedHeaderWidth={fixedRowHeaderWidth}
            />
            <RowSection
              header={t('facility.atAGlance.creditStatus')}
              data={facilityDetailsResponse?.creditStatus || missingField}
              handleMeasureWidth={handleMeasureWidth}
              fixedHeaderWidth={fixedRowHeaderWidth}
            />
            <RowSection
              header={t('facility.atAGlance.creditCheck')}
              data={isCreditHold ? 'Yes' : 'No'}
              handleMeasureWidth={handleMeasureWidth}
              fixedHeaderWidth={fixedRowHeaderWidth}
            />
            <RowSection
              header={t('facility.atAGlance.bookingLimit')}
              data={facilityDetailsResponse?.bookingLimit || 0}
              handleMeasureWidth={handleMeasureWidth}
              fixedHeaderWidth={fixedRowHeaderWidth}
            />
            <RowSection
              header={t('facility.facilityHeader.lastUpdated')}
              data={
                facilityDetailsResponse?.creditLastUpdated
                  ? getDate(facilityDetailsResponse?.creditLastUpdated)
                  : missingField
              }
              handleMeasureWidth={handleMeasureWidth}
              fixedHeaderWidth={fixedRowHeaderWidth}
            />
            <RowSection
              header={t('facility.facilityHeader.lastUpdatedBy')}
              data={
                facilityDetailsResponse?.creditLastUpdatedBy?.firstName &&
                  facilityDetailsResponse?.creditLastUpdatedBy?.lastName
                  ? facilityDetailsResponse?.creditLastUpdatedBy?.firstName +
                  ' ' +
                  facilityDetailsResponse?.creditLastUpdatedBy?.lastName
                  : missingField
              }
              handleMeasureWidth={handleMeasureWidth}
              fixedHeaderWidth={fixedRowHeaderWidth}
            />
            {(facility.facilityResponse?.creditNotes && (
              <RowSubSection
                header={t('facility.atAGlance.creditServiceNotes')}
                actionIcon={<NotesIcon />}
                actionHandler={() => setFacilityCreditNotesOpen(true)}
                actionIcomTooltip={t('facility.atAGlance.viewFullDetails')}
                data={<TruncatedText text={facility.facilityResponse.creditNotes || ' '} maxLength={185} />}
              />
            )) || (
                <RowSection
                  header={t('facility.atAGlance.creditServiceNotes')}
                  data={missingField}
                  handleMeasureWidth={handleMeasureWidth}
                  fixedHeaderWidth={fixedRowHeaderWidth}
                />
              )}
            <Divider style={{ marginBottom: '12px' }} />
            {(facility.facilityResponse?.notes && (
              <RowSubSection
                header={t('facility.facilityActivityNotes.title')}
                actionIcon={<NotesIcon />}
                actionHandler={() => setFacilityActivityNotesOpen(true)}
                actionIcomTooltip={t('facility.atAGlance.viewFullDetails')}
                data={<TruncatedText text={facility.facilityResponse.notes || ' '} maxLength={185} />}
              />
            )) || (
                <RowSection
                  header={t('facility.facilityActivityNotes.title')}
                  data={missingField}
                  handleMeasureWidth={handleMeasureWidth}
                  fixedHeaderWidth={fixedRowHeaderWidth}
                />
              )}
          </Grid>
        )}
      </BoxContainer>
      {isFacilityCreditNotesOpen && (
        <FacilityDialog
          open={isFacilityCreditNotesOpen}
          children={<FacilityCreditNotes />}
          handleClose={() => setFacilityCreditNotesOpen(false)}
          headerTitle={t('facility.facilityCreditNotes.title')}
        />
      )}
      {isFacilityActivityNotesOpen && (
        <FacilityDialog
          open={isFacilityActivityNotesOpen}
          children={<FacilityActivityNotes />}
          handleClose={() => setFacilityActivityNotesOpen(false)}
          headerTitle={t('facility.facilityActivityNotes.title')}
        />
      )}
    </>
  );
};

/**
 *  Component to render facility activity notes
 */
const FacilityActivityNotes = () => {
  const facility = useSelector(selectFacility);
  return (
    <Box p={6} sx={{ height: '100%', maxHeight: '80vh', overflowY: 'scroll', whiteSpace: 'pre-line' }}>
      {facility.facilityResponse.notes}
    </Box>
  );
};

/**
 *  Component to render facility credit notes
 */
const FacilityCreditNotes = () => {
  const facility = useSelector(selectFacility);
  return (
    <Box p={6} sx={{ height: '100%', maxHeight: '80vh', overflowY: 'scroll', whiteSpace: 'pre-line' }}>
      {facility.facilityResponse.creditNotes}
    </Box>
  );
};
