import React, { useState } from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { selectOrderLicenses } from '@AMIEWEB/Order/Store/Order.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { CompactStatus, IOrderLicense, orderDataActions } from '@AMIEWEB/Order/Store/Order.redux';
import { selectOrderRequirementTypes } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { OrderLicenseTypeAheadDropdown } from './OrderLicenseTypeAheadDropdown';

interface GridCellProps {
  [key: string]: any;
}

const useStyles = makeStyles()({
  selectField: {
    minWidth: 450,
    zIndex: 1000,
  },
});

const LicenseStateRenderer = React.memo(function LicenseStateRenderer(cellParams: GridCellProps) {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const orderLicenses = useSelector(selectOrderLicenses);
  const [selectedValues, setSelectedValues] = useState<any[]>([]);
  const { states } = useSelector(selectOrderRequirementTypes);

  const onApplyFilter = data => {
    const orderLicensesDetails: IOrderLicense[] = [...orderLicenses];
    if (orderLicenses && orderLicenses?.length > 0) {
      const licenseDetailsIndex = orderLicensesDetails?.findIndex(data => data?.licenseId === cellParams.id);
      const selectedLicenseOptionDetails = orderLicenses?.find(data => data?.licenseId === cellParams.id);
      if (licenseDetailsIndex !== -1) {
        const selectedLicenseDetail: IOrderLicense = {
          ...selectedLicenseOptionDetails,
          licenseStateDetails: data,
          isError: { ...selectedLicenseOptionDetails?.isError, state: data?.length > 0 ? false : true },
        };
        orderLicensesDetails[licenseDetailsIndex] = { ...selectedLicenseDetail };
      } else {
        if (selectedLicenseOptionDetails) {
          const selectedLicenseDetail: IOrderLicense = {
            ...selectedLicenseOptionDetails,
            licenseStateDetails: data,
            isError: { ...selectedLicenseOptionDetails?.isError, state: data?.length > 0 ? false : true },
          };
          orderLicensesDetails.push(selectedLicenseDetail);
        }
      }
    }
    dispatch(orderDataActions.setOrderLicenseDetails(orderLicensesDetails));
  };

  React.useEffect(() => {
    if (cellParams?.id && orderLicenses?.find(data => data?.licenseId === cellParams.id)) {
      const stateArray: any[] = [];
      orderLicenses?.map(data => {
        if (data?.licenseId === cellParams.id && data?.compactStatus === CompactStatus.single) {
          const defaultStateValue = data?.licenseStateDetails;
          defaultStateValue?.map(stateDetails => {
            const selectedStatesValue = {
              state: stateDetails?.state,
              isRequired: stateDetails?.isRequired,
              documentAttachmentRequired: stateDetails?.documentAttachmentRequired,
            };
            stateArray.push(selectedStatesValue);
          });
        }
      });
      setSelectedValues(stateArray);
    }
  }, [orderLicenses]);

  return (
    <>
      {!cellParams?.hideField && (
        <OrderLicenseTypeAheadDropdown
          filterName={t('order.license.stateDropdown')}
          options={states}
          isMultiSelect
          value={selectedValues}
          minWidth={'120px'}
          apply={onApplyFilter}
          customClass={classes.selectField}
          hasControlError={cellParams?.isError}
          controlErrorText={'required'}
          isDisabled={cellParams?.isDisabled}
          hideApply={cellParams?.hideApply}
          isNewOrderCreation={cellParams?.isNewOrderCretion}
        />
      )}
    </>
  );
});

export const LicenseStateRenderCell = (props: {
  param: GridCellParams;
  isDisabled: boolean;
  hideApply?: boolean;
  isNewOrderCretion?: boolean;
}) => {
  const [hideField, setHideField] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [isError, setIsError] = useState<boolean>(false);
  const orderLicenses = useSelector(selectOrderLicenses);

  React.useEffect(() => {
    if (
      props?.param?.id &&
      orderLicenses?.find(data => data.licenseId === props?.param?.id && data.compactStatus === CompactStatus.single)
    ) {
      orderLicenses?.map(data => {
        if (data?.licenseId === props?.param.id) {
          setIsError(data?.isError?.state);
        }
      });
      setHideField(false);
    } else {
      setSelectedValues([]);
      setHideField(true);
    }
  }, [orderLicenses]);
  return !hideField ? (
    <LicenseStateRenderer
      {...props?.param}
      isDisabled={props?.isDisabled}
      hideField={hideField}
      selectedValues={selectedValues}
      isError={isError}
      hideApply={props.hideApply}
      isNewOrderCretion={props?.isNewOrderCretion}
    />
  ) : (
    <></>
  );
};
