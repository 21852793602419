import { FilterCompTypes } from 'app/ComponentLibrary/Filter/utils';

export enum RemindersFilters {
  placementId = 'placementId',
  candidateName = 'candidateName',
  placementStartDate = 'startDate',
  placementStatus = 'placementStatus',
  clientName = 'clientName',
  candidateId = 'candidateId',
  startDate = 'startDate',
  daysBeforeStart = 'daysBeforeStart',
  reminderDate = 'reminderDate',
  reminderType = 'reminderType',
  reminderStatus = 'reminderStatus',
}

export const defaultFilterValues = {
  [RemindersFilters.placementId]: [],
  [RemindersFilters.candidateName]: [],
  [RemindersFilters.placementStatus]: [],
  [RemindersFilters.clientName]: [],
  [RemindersFilters.startDate]: null,
  [RemindersFilters.reminderDate]: null,
  [RemindersFilters.daysBeforeStart]: null,
  [RemindersFilters.reminderType]: [],
  [RemindersFilters.reminderStatus]: [],
  [RemindersFilters.candidateId]: [],
};

export const getFilterSpecs = (filterSpecs, t, styleClasses) => [
  {
    filterName: t('home.credentialingWorkDesk.placementFilter.placementId'),
    name: RemindersFilters.placementId,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.placementIDOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.placementIdField,
  },
  {
    filterName: t('home.credentialingWorkDesk.placementFilter.candidateName'),
    name: RemindersFilters.candidateName,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.candidateNameOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.candidateNameField,
  },
  {
    filterName: t('home.credentialingWorkDesk.placementFilter.placementStatus'),
    name: RemindersFilters.placementStatus,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.placementStatusOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    returnsObjectAsValue: true,
    selectFieldStyle: styleClasses.placementStatusField,
  },
  {
    filterName: t('Start Date'),
    name: RemindersFilters.startDate,
    placeholder: 'Start Date',
    type: FilterCompTypes.DATERANGEV2,
    variant: 'outlined',
    clickAway: true,
    selectFieldStyle: styleClasses.startDateField,
  },
  {
    filterName: t('Day Before Start'),
    name: RemindersFilters.daysBeforeStart,
    placeholder: 'Day Before Start',
    type: FilterCompTypes.TEXTFIELDNUMBER,
    selectFieldStyle: styleClasses.dayBeforeStartField,
  },
  {
    filterName: t('Reminder Date'),
    name: RemindersFilters.reminderDate,
    placeholder: 'Reminder Date',
    type: FilterCompTypes.DATERANGEV2,
    variant: 'outlined',
    clickAway: true,
    selectFieldStyle: styleClasses.reminderDateField,
  },
  {
    filterName: t('Reminder Type'),
    name: RemindersFilters.reminderType,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.reminderTypeOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.reminderTypeField,
  },
  {
    filterName: t('Reminder Status'),
    name: RemindersFilters.reminderStatus,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.reminderStatusOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.reminderStatusField,
  },
  {
    filterName: t('home.credentialingWorkDesk.placementFilter.clientName'),
    name: RemindersFilters.clientName,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.clientNameOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.clientNameField,
    returnsObjectAsValue: true,
  },
  {
    filterName: t('home.credentialingWorkDesk.placementFilter.candidateId'),
    name: RemindersFilters.candidateId,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.candidateIdOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.candidateIdField,
  },
];
