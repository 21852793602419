import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Switch,
  Typography,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { selectNotificationPreference } from 'app/ApplicationRoot/Global.selectors';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectUser } from 'oidc/user.selectors';

const useStyles = makeStyles()({
  accordion: {
    '&.MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '0px',
    paddingRight: '22px',
  },
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  accordionSummary: {
    paddingLeft: '0px',
  },
  enabled: {
    opacity: 1,
  },
  disabled: {
    opacity: 0.5,
  },
  title: {
    paddingTop: 10,
    fontWeight: 500,
    fontSize: '14px',
    color: '#333333',
  },
  toggleLabel: {
    fontWeight: 400,
    paddingTop: '12px',
    paddingLeft: '10px',
  },
});

const NotificationPreference = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notificationPreference = useSelector(selectNotificationPreference);
  const [preferences, setPreferences] = useState<any>([]);
  const { userInfo } = useSelector(selectUser);

  const renderOrderMatches = userInfo.roles?.includes('Recruitment')
    || userInfo.roles?.includes('Recruitment.Leadership')
    || userInfo.roles?.includes('Recruitment.TeamMember');

  useEffect(() => {
    setPreferences(notificationPreference);
  }, [notificationPreference]);

  const handleOnChange = (value, name) => {
    let temp: any = [];
    temp = preferences.length ? preferences.filter(value => value?.name !== name) : [];
    temp.push({
      name: name,
      enabled: value,
    });
    if (name === 'Cue Note Notification') {
      temp = temp.length ? temp.filter(value => value?.name !== 'Cued To Me') : [];
      temp.push({
        name: 'Cued To Me',
        enabled: value,
      });
      temp = temp.length ? temp.filter(value => value?.name !== 'Cue Note Replies') : [];
      temp.push({
        name: 'Cue Note Replies',
        enabled: value,
      });
    }
    if (name === 'Cued To Me' || name === 'Cue Note Replies') {
      temp = temp.length ? temp.filter(value => value?.name !== 'Cue Note Notification') : [];
      temp.push({
        name: 'Cue Note Notification',
        enabled:
          temp.filter(val => val.name === 'Cued To Me')[0]?.enabled ||
          temp.filter(val => val.name === 'Cue Note Replies')[0]?.enabled,
      });
    }
    setPreferences(temp);
    dispatch(globalActions.setNotificationPreference(temp));

  };
  return (
    <div>
      {preferences.length > 0 && (
        <>
          <>
            <Accordion className={classes.accordion}>
              <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon style={{ marginLeft: '-1px', marginRight: '-12px' }} />}
              >
                <Box className={classes.container} onClick={event => event.stopPropagation()}>
                  <Typography
                    className={`${classes.title}
              ${preferences.filter(val => val.name === 'Cue Note Notification')[0]?.enabled
                        ? classes.enabled
                        : classes.disabled
                      }`}
                  >
                    {t('notification.profileDrawer.preferences.CueNoteNotification')}
                  </Typography>
                  <Switch
                    checked={preferences.filter(val => val.name === 'Cue Note Notification')[0]?.enabled}
                    disableRipple
                    defaultChecked={preferences.filter(val => val.name === 'Cue Note Notification')[0]?.enabled}
                    onChange={e => handleOnChange(e.target.checked, 'Cue Note Notification')}
                  />
                </Box>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Box className={classes.container}>
                  <Typography
                    className={`${classes.toggleLabel}
                ${preferences.filter(val => val.name === 'Cued To Me')[0]?.enabled ? classes.enabled : classes.disabled
                      }`}
                  >
                    {t('notification.profileDrawer.preferences.CuedToMe')}
                  </Typography>
                  <Switch
                    checked={preferences.filter(val => val.name === 'Cued To Me')[0]?.enabled}
                    disableRipple
                    defaultChecked={preferences.filter(val => val.name === 'Cued To Me')[0]?.enabled}
                    onChange={e => handleOnChange(e.target.checked, 'Cued To Me')}
                  />
                </Box>
                <Box className={classes.container}>
                  <Typography
                    className={`${classes.toggleLabel}
                ${preferences.filter(val => val.name === 'Cue Note Replies')[0]?.enabled
                        ? classes.enabled
                        : classes.disabled
                      }`}
                  >
                    {t('notification.profileDrawer.preferences.CueNoteReplies')}
                  </Typography>
                  <Switch
                    checked={preferences.filter(val => val.name === 'Cue Note Replies')[0]?.enabled}
                    disableRipple
                    defaultChecked={preferences.filter(val => val.name === 'Cue Note Replies')[0]?.enabled}
                    onChange={e => handleOnChange(e.target.checked, 'Cue Note Replies')}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
            <Divider />
          </>
          {renderOrderMatches && (
            <>
              <Box className={classes.container} style={{ padding: '9px 25px 12px 0px' }}>
                <Typography
                  className={`${classes.title}
                ${preferences.filter(val => val.name === 'Order Matches')[0]?.enabled
                      ? classes.enabled
                      : classes.disabled
                    }`}
                >
                  {t('notification.profileDrawer.preferences.OrderMatches')}
                </Typography>
                <Switch
                  checked={preferences.filter(val => val.name === 'Order Matches')[0]?.enabled}
                  disableRipple
                  defaultChecked={preferences.filter(val => val.name === 'Order Matches')[0]?.enabled}
                  onChange={e => handleOnChange(e.target.checked, 'Order Matches')}
                />
              </Box>
              <Divider />
            </>
          )
          }
          <>
            <Box className={classes.container} style={{ paddingRight: '25px', paddingTop: '9px' }}>
              <Typography
                className={`${classes.title}
                ${preferences.filter(val => val.name === 'SMS Notification')[0]?.enabled
                    ? classes.enabled
                    : classes.disabled
                  }`}
              >
                {t('notification.profileDrawer.preferences.SMSNotification')}
              </Typography>
              <Switch
                checked={preferences.filter(val => val.name === 'SMS Notification')[0]?.enabled}
                disableRipple
                defaultChecked={preferences.filter(val => val.name === 'SMS Notification')[0]?.enabled}
                onChange={e => handleOnChange(e.target.checked, 'SMS Notification')}
              />
            </Box>
          </>
        </>
      )}
    </div>
  );
};

export default NotificationPreference;
