import { IUpdateAutoTaskManagementPayload } from 'app/models/AutoTaskManagement/AutoTaskManagement';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import baseAxiosInstance from 'utils/BaseApi';

export class PlacementRequestService {
  private sharedBase: string;

  private cancellationToken = axios.CancelToken.source();

  private static currentInstance: PlacementRequestService;

  config?: AxiosRequestConfig = {};

  private constructor() {
    this.sharedBase = globalThis?.app?.env?.REACT_APP_TRACK_CHANGE_SERVICE_BASE_URL;
  }

  static createInstance(): PlacementRequestService {
    const activeInstance = new PlacementRequestService();
    return activeInstance;
  }

  static getInstance(): PlacementRequestService {
    if (!this.currentInstance) this.currentInstance = this.createInstance();
    return this.currentInstance;
  }

  static cancelActiveRequests() {
    this.currentInstance.cancellationToken.cancel('RequestCancellation');
    // this.currentInstance = null;
  }

  cancelRequests() {
    this.cancellationToken.cancel('RequestCancellation');
    return PlacementRequestService.getInstance();
  }

  cancelAndRevokeInstance() {
    this.cancellationToken.cancel('RequestCancellation');
    this.cancellationToken = axios.CancelToken.source();
    if (this.config) this.config.cancelToken = this.cancellationToken.token;
  }

  /**Service calls START */
  getAutoTaskCategories = () =>
    baseAxiosInstance
      .get(`${this.sharedBase}/auto-task-management/auto-task-categories`)
      .catch((error: AxiosError) => error.response);

  getAutoTaskEmployeeRoles = () =>
    baseAxiosInstance
      .get(`${this.sharedBase}/auto-task-management/employee-roles`)
      .catch((error: AxiosError) => error.response);

  updateAutoTaskManagement = (body:IUpdateAutoTaskManagementPayload) =>
    baseAxiosInstance
      .post(`${this.sharedBase}/auto-task-management/auto-task-category/update`,body)
      .catch((error: AxiosError) => error.response);
  /**Service calls END */
}
