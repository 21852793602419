/* eslint-disable @typescript-eslint/dot-notation */
import { AccountInfo } from '@azure/msal-browser';
import { PayloadAction } from '@reduxjs/toolkit';
import { trackEvent, trackException } from 'app-insights/appInsightsTracking';
import {
  GetDevices,
  changeUserEmailPreference,
  getUserTwilioLongCode,
} from 'app/services/NotificationServices/NotificationService';
import { httpSuccess } from 'app/services/serviceHelpers';
import { getUserInfo, getUserPreferences } from 'app/services/SharedServices/SharedServices';
import jwtDecode from 'jwt-decode';
import { all, call, fork, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { setAxiosEmployeeID } from 'utils/BaseApi';
import { setupSignalRConnection } from './SignalRConnectionSetup';
import { IUserInfo, IUserSmsForwardPreference, userActions } from './user.redux';
import {
  initialState as initialUserPreference,
  userDevicePreferenceActions,
} from './UserDevicePreference/userPreference.redux';
import { getOverrideNetwork, getOverrideSignalRLogLevel, getOverrideUserID, getOverrideUserRoles } from './userHelper';
import { formatPhoneNumber } from 'app/helpers/phoneHelpers';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import {
  PreferenceCosmosKeys,
  initialEditorPreference,
  initialEmailSignature,
  initialInboxFilters,
  initialNotificationPreference,
  initialPinnedMessages,
  initialRecentHistory,
} from './UserDevicePreference/utils';
import { isMultiRoled } from './userRoles';
import { SessionKey } from 'utils/customHooks/sessionStorage/sessionHelpers';
import _ from 'lodash';
import { chooseDefaultFilterValues } from 'app/components/GlobalSearch/SearchResultsWrapper';
import { defaultFilterName, SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import { selectUser } from './user.selectors';
import { SharedService } from 'app/services/SharedServices/shared-service';
import { trackPromise } from 'react-promise-tracker';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';

const TrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'User-Preferences-Call');
/**
 * Placement status totals
 * @param action
 */
export function* requestUserInfo(action: PayloadAction<{ user: AccountInfo; accessToken: string }>) {
  try {
    const sharedService: SharedService = yield getContext('sharedService');
    const results = yield call(async () => {
      let response = await getUserInfo();
      const accessToken = jwtDecode(action?.payload?.accessToken || '') as any;
      const roles = getOverrideUserRoles() || accessToken.roles || [];
      const signalRLogLevel = getOverrideSignalRLogLevel() || null;
      const overrideNetwork = getOverrideNetwork();

      const samAccountName = action.payload.user?.idTokenClaims?.['samAccountName'];

      if (overrideNetwork && getOverrideUserID()) {
        response = {
          statusText: '',
          headers: {},
          config: null,
          status: 200,
          data: {
            employeeId: getOverrideUserID() || 0,
            phoneNumber: '5555555555',
            companyId: 1,
            companyBrandName: 'AMH',
          },
        };
      }

      if (response && httpSuccess(response.status)) {
        const userLongCode = await getUserTwilioLongCode(response.data.employeeId);

        let sharedProfile = response?.data?.sharedProfile;
        if (!isNullOrUndefined(sharedProfile?.employeeId)) {
          const sharedLongCode = await getUserTwilioLongCode(sharedProfile?.employeeId);
          sharedProfile = {
            ...sharedProfile,
            phoneNumber: sharedLongCode?.number || null,
          };
        }

        const employeeId = getOverrideUserID() || response.data.employeeId || 0;
        const HubConnection = setupSignalRConnection(
          employeeId,
          response?.data?.sharedProfile?.employeeId,
          action?.payload?.accessToken,
          signalRLogLevel,
        );
        const extSysResponse = await sharedService.getEmployeeExternalSystemDetails(employeeId);

        setAxiosEmployeeID(employeeId);
        trackEvent({
          type: 'event',
          name: 'Get UserInfo Successful',
          properties: { user: action.payload.user, employeeId: employeeId, roles: accessToken?.roles },
        });
        return {
          userInfo: {
            employeeId: employeeId,
            firstName: response.data.firstName || accessToken.given_name,
            lastName: response.data.lastName || accessToken.family_name,
            email: response.data.email || action?.payload?.user?.username,
            roles: roles,
            multiroled: isMultiRoled(roles),
            phoneNumber: userLongCode?.number || null,
            directNumber: response.data.phoneNumber ? formatPhoneNumber(response.data.phoneNumber) : undefined,
            ntUserName: samAccountName,
            companyId: response.data.companyId,
            companyBrandName: response.data.companyBrandName,
            hubConnection: HubConnection,
            externalSystemDetails: httpSuccess(extSysResponse.status) ? extSysResponse.data : undefined,
            forwardInboundSmsToEmail: userLongCode?.assignedTo?.[0]?.preferences?.forwardInboundSmsToEmail || false,
            sharedProfile: sharedProfile || null,
          },

          error: false,
        };
      } else {
        trackEvent({
          type: 'event',
          name: 'Get UserInfo Failed',
          properties: { user: action.payload.user, employeeId: undefined },
        });
        return {
          userInfo: {
            employeeId: undefined,
            firstName: accessToken.given_name,
            lastName: accessToken.family_name,
            email: action?.payload?.user?.username,
            roles: roles,
            multiroled: isMultiRoled(roles),
            phoneNumber: undefined,
            directNumber: null,
            ntUserName: samAccountName,
            hubConnection: undefined,
          },

          error: true,
        };
      }
    });
    yield put(userActions.setUser(results));
  } catch (error) {
    trackEvent({
      type: 'event',
      name: 'Get UserInfo Failed',
      properties: { user: action.payload.user, employeeId: undefined },
    });
  } finally {
    yield fork(requestUserDevicePreference, {
      payload: { user: action.payload.user },
      type: userActions.requestUserDevicePreference.type,
    });
  }
}

/**
 * User grid and device preferences
 * @param action
 */
export function* requestUserDevicePreference(action: PayloadAction<{ user: AccountInfo }>) {
  try {
    let UserPreference = initialUserPreference;
    const { userInfo } = yield select(selectUser);
    const samAccountName = action.payload.user?.idTokenClaims?.['samAccountName'];

    const [devicePreference, preferencesReponse = {}] = yield all([
      call(TrackWrapper, GetDevices, samAccountName),
      call(TrackWrapper, getUserPreferences),
    ]);

    const masterPreferences = preferencesReponse?.data || [];
    if (httpSuccess(preferencesReponse?.status)) {
      const devicePref = masterPreferences.filter(values => values.id === 'DevicePreference');
      UserPreference = {
        existing: {
          device: devicePref?.[0]?.value?.deviceName ?? null,
          line: devicePref?.[0]?.value?.lineNumber ?? null,
        },
        current: {
          device: devicePref?.[0]?.value?.deviceName ?? null,
          line: devicePref?.[0]?.value?.lineNumber ?? null,
        },
        deviceList: devicePreference?.profiles,

        /*Getting device line for matching cosmos device name
        if cosmos value doesn't exists then it will be pick the first profile lines */
        deviceLine: devicePref?.length
          ? devicePreference?.profiles?.find(element => element?.deviceName === devicePref[0].value.deviceName)
              ?.lines ?? []
          : devicePreference?.profiles?.find(Boolean)?.lines ?? [],

        default: false,
        userGridPreferences: !masterPreferences
          ? []
          : masterPreferences.filter(values => values.id !== PreferenceCosmosKeys.signaturePreference),
        emailSignature: !masterPreferences
          ? { id: PreferenceCosmosKeys.signaturePreference, value: [] }
          : masterPreferences.filter(values => values.id === PreferenceCosmosKeys.signaturePreference)[0] ||
            initialEmailSignature,
        notificationPreference: !masterPreferences
          ? { id: 'notification-preference', value: [] }
          : masterPreferences.filter(values => values.id === 'notification-preference')[0] ||
            initialNotificationPreference,
        globalSearchDropDown: masterPreferences.filter(
          values => values.id === PreferenceCosmosKeys.globalSearchDropdown,
        )[0],
        pinnedMessages: !masterPreferences
          ? { id: PreferenceCosmosKeys.pinnedMessages, value: '' }
          : masterPreferences.filter(values => values.id === PreferenceCosmosKeys.pinnedMessages)[0] ||
            initialPinnedMessages,
        recentHistory: !masterPreferences
          ? { id: PreferenceCosmosKeys.recentHistory, value: undefined }
          : masterPreferences.filter(values => values.id === PreferenceCosmosKeys.recentHistory)[0] ||
            initialRecentHistory,
        inboxFilter: !masterPreferences
          ? { id: PreferenceCosmosKeys.inboxFilters, value: [] }
          : masterPreferences.filter(values => values.id === PreferenceCosmosKeys.inboxFilters)?.[0] ||
            initialInboxFilters,
        editors: deriveEditorPreferences(masterPreferences),
      };
      checkDefaultGlobalSearchFilters(masterPreferences, userInfo);
    } else {
      checkDefaultGlobalSearchFilters(masterPreferences, userInfo);
      UserPreference = {
        ...UserPreference,
        userGridPreferences: masterPreferences
      }
      yield put(globalActions.setSnackBar({ message: 'Preferences failed to load', severity: 'error' }));
    }
    UserPreference.loaded = true;

    yield put(userDevicePreferenceActions.setUserPreference(UserPreference));
    yield put(
      globalActions.setEmailSignature(
        UserPreference.emailSignature ? UserPreference.emailSignature.value : initialEmailSignature.value,
      ),
    );
    yield put(
      globalActions.setNotificationPreference(
        UserPreference.notificationPreference
          ? UserPreference.notificationPreference.value
          : initialNotificationPreference.value,
      ),
    );
  } catch (error) {
    trackException({ exception: error });
  }
}

const deriveEditorPreferences = preferences => ({
  settingsConfirmationTemplateEditor: preferences.find(
    x => x.id === PreferenceCosmosKeys.settingsConfirmationTemplateEditor,
  ) ?? {
    id: PreferenceCosmosKeys.settingsConfirmationTemplateEditor,
    value: initialEditorPreference,
  },
  settingsContractTemplateEditor: preferences.find(
    x => x.id === PreferenceCosmosKeys.settingsContractTemplateEditor,
  ) ?? {
    id: PreferenceCosmosKeys.settingsContractTemplateEditor,
    value: initialEditorPreference,
  },
  placementConfirmationDocEditor: preferences.find(
    x => x.id === PreferenceCosmosKeys.placementConfirmationDocEditor,
  ) ?? {
    id: PreferenceCosmosKeys.placementConfirmationDocEditor,
    value: initialEditorPreference,
  },
  placementContractDocEditor: preferences.find(x => x.id === PreferenceCosmosKeys.placementContractDocEditor) ?? {
    id: PreferenceCosmosKeys.placementContractDocEditor,
    value: initialEditorPreference,
  },
});

const checkDefaultGlobalSearchFilters = (GridPreferences: any, userInfo: IUserInfo) => {
  const placement = updateDefaultFilter(SessionKey['GlobalSearchGrid-placement'], userInfo, GridPreferences);
  const order = updateDefaultFilter(SessionKey['GlobalSearchGrid-order'], userInfo, GridPreferences);
  const facility = updateDefaultFilter(SessionKey['GlobalSearchGrid-facility'], userInfo, GridPreferences);
  const candidate = updateDefaultFilter(SessionKey['GlobalSearchGrid-candidate'], userInfo, GridPreferences);
  return placement || order || facility || candidate;
};

const keyToSearchType = key => {
  switch (key) {
    case SessionKey['GlobalSearchGrid-placement']:
      return SearchType.placement;
    case SessionKey['GlobalSearchGrid-candidate']:
      return SearchType.candidate;
    case SessionKey['GlobalSearchGrid-facility']:
      return SearchType.facility;
    case SessionKey['GlobalSearchGrid-order']:
      return SearchType.order;
    case SessionKey['GlobalSearchGrid-tasks']:
      return SearchType.tasks;
    default:
      return null;
  }
};

const updateDefaultFilter = (key: string, userInfo: IUserInfo, GridPreferences: any = []) => {
  const gridIndex = GridPreferences.findIndex(values => values.id === key);
  const defaultFilter = JSON.parse(
    JSON.stringify({
      name: defaultFilterName,
      value: Object.entries(chooseDefaultFilterValues(keyToSearchType(key), undefined, userInfo)),
      isDefault: true,
    }),
  );

  if (gridIndex !== -1) {
    const filterPrefClone = _.cloneDeep(GridPreferences[gridIndex]);

    if ((filterPrefClone?.value?.filterAttributes?.filterSet || []).length === 0) {
      // No filters set, add default filter
      filterPrefClone.value.filterAttributes = {
        ...filterPrefClone.value.filterAttributes,
        filterSet: [defaultFilter],
      };
      if (!filterPrefClone.value.selectedFilter || Object.keys(filterPrefClone.value.selectedFilter).length === 0) {
        filterPrefClone.value.selectedFilter = defaultFilter;
      }
      GridPreferences[gridIndex] = filterPrefClone;
    } else {
      const defaultFilterIndex = (GridPreferences[gridIndex].value?.filterAttributes?.filterSet || [])?.findIndex(
        item => item.name === defaultFilterName && !item.isDefault,
      );
      if (defaultFilterIndex !== -1)
        // has default, but isDefault is not set. set isDefault as true to account for changing the default filter name
        filterPrefClone.value.filterAttributes.filterSet[defaultFilterIndex] = {
          ...filterPrefClone.value.filterAttributes.filterSet[defaultFilterIndex],
          isDefault: true,
        };
      GridPreferences[gridIndex] = filterPrefClone;
      return true;
    }
  } else {
    // Set default filter if filter doesn't exist
    GridPreferences.push({
      id: key,
      value: {
        filterAttributes: {
          filterSet: [defaultFilter],
        },
        selectedFilter: {
          name: defaultFilterName,
          value: Object.entries(chooseDefaultFilterValues(keyToSearchType(key), undefined, userInfo)),
          isDefault: true,
        },
      },
    });
    return true;
  }
  return false;
};

export function* changeUserPreference(action: PayloadAction<IUserSmsForwardPreference>) {
  try {
    let response;
    yield call(async () => {
      response = await changeUserEmailPreference(action.payload);
    });
    if (httpSuccess(response.status) && response.data == true) {
      yield put(userActions.setForwardSmsToEmail(action.payload.preferences.forwardInboundSmsToEmail));
    }
  } catch (error) {
    trackException({ exception: error });
  }
}

export function* userSaga() {
  yield takeLatest(userActions.requestUserInfo.type, requestUserInfo);
  yield takeLatest(userActions.requestUserDevicePreference.type, requestUserDevicePreference);
  yield takeLatest(userActions.changeUserPreference.type, changeUserPreference);
}
