import { IUserRoles } from './componenets/UserRolesSection/UserRolesSection';

export const getSelectedRoles = (ids: number[], rolesList: IUserRoles[]) => {
  let selectedRoles: IUserRoles[] = [];
  ids.forEach(id => {
    const selectedRole = rolesList.find(role => role.id === id + '');
    selectedRole && selectedRoles.push(selectedRole);
  });
  return selectedRoles;
};
