import React from 'react';
import ActiveNotesIcon from 'app/assets/images/Tasks/notes_blue.svg';
import { Badge } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import { selectCurrentUserCuedTo } from '../../store/Tasks.selectors';

const useStyles = makeStyles()(() => ({
  countBadge: {
    '& .MuiBadge-colorError': {
      backgroundColor: '#CC0000',
    },
    '& .MuiBadge-badge ': {
      height: '0.3rem',
      minWidth: '4px',
    },
  },
}));
export const ActivePanelNotes = () => {
  const { classes } = useStyles();
  const isUserCued = useSelector(selectCurrentUserCuedTo);
  return (
    <React.Fragment>
      {isUserCued ? (
        <Badge color={'error'} variant="dot" className={classes.countBadge}>
          <img src={ActiveNotesIcon} alt="Active Notes" />
        </Badge>
      ) : (
        <img src={ActiveNotesIcon} alt="Active Notes" />
      )}
    </React.Fragment>
  );
};
