import { GridCellParams, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Radio, materialUiXGrid } from 'amn-ui-core';
import React from 'react';

export const XGridRadioRenderer = React.memo(function XGridRadioRenderer(params: GridCellParams) {
  const api = materialUiXGrid.useGridApiContext();
  const checked = api.current.isRowSelected(params.id);
  const onSelect = e => {
    e.stopPropagation();
    api.current.selectRow(params.id, !checked, !checked);
  };
  return <Radio checked={checked} onChange={onSelect} size={'small'} sx={{ width: '20px', height: '20px' }} />;
});

export const XGridRadioCell = (params: GridRenderCellParams) => {
  return <XGridRadioRenderer {...params} />;
};
