import { Grid, Switch, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shiftOptions } from '../../helper';
import { RateTable } from './RateTable/RateTable';

const useStyles = makeStyles()((theme) =>
  ({
    gwwSwitch: {
      paddingLeft: '26px',
    },
  }));

export const GuaranteedWorkRate = props => {
  const { t } = useTranslation();
  const [openGrid, setOpenGrid] = useState(false);
  const [switchState, setSwitchState] = useState<boolean>(false);
  const formMethods = useForm({ mode: 'all' });
  const { classes } = useStyles();

  const rows = [
    {
      id: 5, 
      attr: 'Guaranteed Work Week', 
      name: 'Guaranteed Work Week', 
      value: 0, 
      hasRange: true, 
      title: 'Guaranteed Work Week',
    }
  ];

  return (
    <FormProvider {...formMethods}>
      <Grid container style={{ padding: '12px 0px' }}>
        <Grid container style={{ display: 'flex', alignItems: 'center' }}>
          <Grid>
            <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
              {t('order.offContractModel.guaranteedWorkWeek')}
            </Typography>
          </Grid>
          <Grid className={classes.gwwSwitch}>
            <Switch
              checked={openGrid}
              disableRipple
              onChange={e => {
                setOpenGrid(e.target.checked);
                setSwitchState(!switchState);
              }}
              size="small"
            />
          </Grid>
          <Grid>
            <Typography variant="body1" style={{ padding: '5px' }}>
              {switchState ? 'Yes' : 'No'}
            </Typography>
          </Grid>
        </Grid>
        {openGrid && (
          <RateTable
            getTableData={props.setGwwTableData}
            tableTitle={t('order.offContractModel.rateElements')}
            columnOptions={shiftOptions}
            rowOptions={rows}
            disableRowMenu={true}
          />
        )}
      </Grid>
    </FormProvider>
  );
};
