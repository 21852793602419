import React, { useState } from 'react';
import { IconButton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import ArrowRightIcon from '../../../assets/images/ArrowRightIcon.png';
import ArrowLeftIcon from '../../../assets/images/ArrowLeftIcon.png';

const useStyles = makeStyles()({
  root: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
    alignItems: 'center',
    transform: 'translate(-45%, 70%)',
  },
  slide: {
    width: 'max-content',
    height: 'max-content',
    transition: '0.8s',
    marginLeft: 20,
    padding: 0,
    borderRadius: 4,
  },
  navButtons: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    background: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftNav: {
    left: 8,
  },
  rightNav: {
    right: '11%',
  },
});

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function getPositionAtCenter(element) {
  const { top, left, width, height } = element.getBoundingClientRect();
  return {
    x: left + width / 2,
    y: top + height / 2,
  };
}

export const Carousel = props => {
  const { items } = props;
  const { classes } = useStyles();
  const [carouselViewCount, setCarouselViewCount] = useState<number>(1);
  const [translationIndex, setTranslationIndex] = useState<number>(0);
  const [itemIndex, setItemIndex] = useState<number>(0);
  const { width } = useWindowDimensions();

  const DetermineCarouselViewCount = () => {
    const leftBtn = document.getElementById('carousel-left-btn');
    const rightBtn = document.getElementById('carousel-right-btn');
    const carouselItemWidth = document.getElementById('carousel-item')?.offsetWidth;
    if (leftBtn && rightBtn && carouselItemWidth) {
      const leftBtnWidth = getPositionAtCenter(leftBtn);
      const rightBtnWidth = getPositionAtCenter(rightBtn);
      const carouselWidth = Math.sqrt(
        Math.pow(leftBtnWidth.x - rightBtnWidth.x, 2) + Math.pow(leftBtnWidth.y - rightBtnWidth.y, 2),
      );
      setCarouselViewCount(carouselWidth / carouselItemWidth);
      return;
    }
    setCarouselViewCount(1);
  };

  return (
    <div className={classes.root} style={{ transform: `translate(-${width * 0.49 + width * -0.01 - 15}px, 70%)` }}>
      {items.map((item, index) => (
        <div
          key={index}
          id={'carousel-item'}
          className={`${classes.slide} carousel-item`}
          onClick={() => setItemIndex(index)}
          onKeyDown={() => setItemIndex(index)}
          role={'button'}
          tabIndex={-1}
          style={{
            transform: `translate(${translationIndex}%)`,
            border: index === itemIndex ? '3px solid #006FB9' : '3px solid transparent',
          }}
        >
          {item}
        </div>
      ))}
      {translationIndex !== 0 && (
        <IconButton
          color="primary"
          id={'carousel-left-btn'}
          disableFocusRipple
          disableTouchRipple
          disableRipple
          className={`${classes.navButtons} ${classes.leftNav}`}
          style={props.navButtonStyle}
          onClick={() => {
            translationIndex >= -100 * (items.length - 1) &&
              translationIndex < 0 &&
              setTranslationIndex(prev => prev + 100);
          }}
          size="small">
          <img src={ArrowLeftIcon} alt="" className={'carousel-nav-button'} />
        </IconButton>
      )}
      {items.length > 3 && Math.abs(translationIndex / 100) < items.length - (items.length % carouselViewCount) - 3 && (
        <IconButton
          color="primary"
          id={'carousel-right-btn'}
          disableFocusRipple
          disableTouchRipple
          disableRipple
          className={`${classes.navButtons} ${classes.rightNav}`}
          style={props.navButtonStyle}
          onClick={() => {
            // translationIndex > -100 * (items.length - 1) &&
            //   translationIndex <= 0 &&
            setTranslationIndex(prev => prev - 100);
            DetermineCarouselViewCount();
          }}
          size="small">
          <img src={ArrowRightIcon} alt="" className={'carousel-nav-button'} />
        </IconButton>
      )}
    </div>
  );
};
