import { Grid, Typography, ToggleButtonGroup, ToggleButton } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styledComponent from 'styled-components';
import clsx from 'clsx';
import { convertToPST } from 'app/helpers/dateHelper';

export const WiselySwitch = ({ disabled, value, ...props }) => {
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center" wrap="wrap" spacing={2}>
      <Grid item>
        <Grid container>
          <Grid item style={{ marginRight: '3px' }}>
            <Typography variant="body1" className={clsx({ disabled: disabled })}>
              {t('placement.profile.summary.booking.confirmations.wisely1')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" className={clsx({ disabled: disabled })}>
              {t('placement.profile.summary.booking.confirmations.wisely2')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <ToggleButtonGroupStyled
          size="small"
          value={value?.checked}
          exclusive
          onChange={(event, checked) => {
            props.onChange({ checked, lastUpdatedAt: convertToPST(new Date(), 'yyyy-MM-DDThh:mm:ss') });
          }}
          className={clsx('input', { disabled: disabled })}
          {...props}
        >
          <ToggleButtonStyled value={true} disabled={disabled}>
            {t('placement.profile.summary.booking.confirmations.optIn')}
          </ToggleButtonStyled>
          <ToggleButtonStyled value={false} disabled={disabled}>
            {t('placement.profile.summary.booking.confirmations.optOut')}
          </ToggleButtonStyled>
        </ToggleButtonGroupStyled>
      </Grid>
    </Grid>
  );
};

const ToggleButtonGroupStyled = styledComponent(ToggleButtonGroup)`
  height: 28px;
  margin: -4px 0px;
`;

const ToggleButtonStyled = styledComponent(ToggleButton)`
  font-size: 11px;
  white-space: nowrap;
`;
