import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getColumns } from './utils';
import { AMNModalGrid } from 'app/components/Common/AMNModalGrid';
import { selectUser } from 'oidc/user.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { IDeleteAudit } from 'app/models/Candidate/CandidateProfile';
import { candidateDetailsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { GridType } from '@AMIEWEB/Common/types';

export const AuditModal = ({
  auditDetails: rows,
  forceClose,
  onHandleAdd = undefined,
  filterModel,
  onFilterModelChange,
  refreshData,
  serviceTrackerId = '',
  onHandleEdit = undefined,
}) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const dispatch = useDispatch();
  const [auditId, setAuditId] = useState<number>(null);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [auditRows, setAuditRows] = useState(rows);
  const [enableDelete, setEnableDelete] = useState<boolean>(true);
  const [auditType, setAuditType] = useState<string>('');

  const checkBoxChange = (rowID, isSelected, params) => {
    const newRows = [];
    setAuditId(rowID);
    setIsChecked(isSelected);
    params.api.getRowModels()?.forEach((value, key) => {
      const row = {
        ...value,
        isSelected: value.auditId === rowID ? isSelected : false,
      };
      newRows.push(row);
    });
    rows = newRows;
    setAuditRows(newRows);
  };

  const columns = getColumns(t, forceClose, user, onHandleEdit, setEnableDelete, setAuditType, checkBoxChange);

  const onHandleDelete = () => {
    const payload: IDeleteAudit = {
      travelerId: candidateDetails.travelerId,
      auditId: auditId,
      currentEmployeeId: user?.userInfo?.employeeId || 0,
      currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
    };
    dispatch(candidateDetailActions.deleteCandidateAudit(payload));
    setIsChecked(false);
    setEnableDelete(true);
  };

  return (
    <AMNModalGrid
      rows={isChecked ? auditRows : rows}
      filterModel={filterModel}
      onFilterModelChange={onFilterModelChange}
      refreshData={refreshData}
      onHandleAdd={onHandleAdd}
      onHandleDelete={onHandleDelete}
      enableDelete={enableDelete}
      auditType={auditType}
      serviceTrackerId={serviceTrackerId}
      gridHeight={0}
      columnsParams={columns}
      translationKey={'placement.profile.tabs.auditsGrid'}
      defaultSortColumn={'lastUpdated'}
      XGRID_COLUMN_SORT_DIRECTION={'desc'}
      marginBottom={'-3px'}
      gridLabel={true}
      numberOfMonth={0}
      gridType={GridType.audit}
    />
  );
};
