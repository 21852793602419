import moment from 'moment';
import {
  RateElemenetSource,
  getShiftId,
  isNewRateElementRequired,
  isRangeValue,
  refactorInputValue,
  refactorValue,
} from '../helper';
import uuid from 'react-uuid';
import { Status } from 'app/enums/Facility';
import { getStatus } from '../OffContractRateElementTable/helper';
import { missingField } from 'app/constants';
import { useSelector } from 'react-redux';
import { selectCreateOrderMiscellaneousData } from 'store/redux-store/create-order-ocbr/selector';
import { getDisciplineName } from 'store/redux-store/create-order-ocbr/utils';

export const extractBillRates = (
  newVal,
  existingBillRates = [],
  shiftsArray = [],
  shiftRate,
  isOcbr,
  isFlatRate,
  rateType,
  effectiveDates,
  billRatesDetails,
  user,
  shiftRatesArray,
  avoidFlatRate = false,
  disciplineName,
  shiftName,
) => {
  const billRates = existingBillRates.reduce((results, item) => {
    const isSameSkillset =
      item.disciplineId === shiftRate.disciplineId &&
      item.specialtyId === shiftRate.specialtyId &&
      item.subSpecialtyId === shiftRate.subSpecialtyId;
    const isSameRateType = item.type === rateType;
    const hasNoFlatRate =
      shiftsArray.length > 1
        ? !(isSameSkillset && item.shiftId === getShiftId(shiftName) && isSameRateType)
        : !(isSameSkillset && item.shiftId === null && isSameRateType);
    const hasFlatRate = !(
      isFlatRate &&
      shiftsArray.length > 1 &&
      shiftsArray.some(
        shiftItem =>
          isSameSkillset && (item.shiftId === getShiftId(shiftItem) || item.shiftId == null) && isSameRateType,
      )
    );
    return hasNoFlatRate && hasFlatRate ? [...results, item] : results;
  }, []);

  if (newVal === null) return billRates;

  const orderEndDate = effectiveDates?.endDate ? moment(effectiveDates.endDate).format() : null;
  const orderStartDate = moment(newVal.effectiveEndDate).add(1, 'days').format();

  const isExtraRateEleRequired = isNewRateElementRequired(effectiveDates?.endDate, newVal.effectiveEndDate);
  const temp = {
    offContractId: newVal.offContractId ? newVal.offContractId : null,
    rateElementId: newVal.rateElementId ? newVal.rateElementId : uuid(),
    disciplineAbbreviation: getDisciplineName(shiftRate.disciplineId, disciplineName.disciplineOpts),
    disciplineId: shiftRate.disciplineId,
    specialtyId: shiftRate.specialtyId,
    specialtyName: billRatesDetails?.skillSets[0]?.specialty?.replace(/\s/g, ''),
    subSpecialtyId: shiftRate.subSpecialtyId,
    subSpecialtyName: billRatesDetails?.skillSets[0]?.subspeciality?.replace(/\s/g, ''),
    shiftId: shiftName == null ? null : getShiftId(shiftName),
    shift: shiftName,
    type: rateType,
    billRate: refactorInputValue(newVal),
    isOcbr: isOcbr,
    statusId: isOcbr ? newVal?.statusId ?? Status.Unsaved : null,
    createdAt: newVal?.createdAt,
    createdBy: user,
    reason: newVal.reason ? newVal.reason : missingField,
    effectiveStartDate:
      newVal?.source === RateElemenetSource.Facility ? newVal.effectiveStartDate : effectiveDates?.startDate,
    effectiveEndDate:
      newVal?.source === RateElemenetSource.Facility ? newVal.effectiveEndDate : effectiveDates?.endDate,
    lastUpdatedBy: user,
    dateLastUpdated: moment().format(),
    dateCreated: moment().format(),
    source: isRangeValue(newVal) ? RateElemenetSource.Order : newVal?.source,
    documentIds: newVal?.documentIds,
  };

  billRates.push(temp);
  if (isExtraRateEleRequired)
    billRates.push({
      ...temp,
      offContractId: null,
      rateElementId: uuid(),
      statusId: Status.Unsaved,
      effectiveStartDate: orderStartDate,
      effectiveEndDate: orderEndDate,
      source: RateElemenetSource.Order,
      createdAt: null,
      documentIds: [],
      reason: missingField,
      isExtra: true,
    });

  if (isFlatRate && shiftsArray.length > 1) {
    shiftsArray?.forEach(shiftItem => {
      const matchedRateFromDropdown = getDropdownMatchedRate(newVal, shiftItem, shiftRatesArray);
      const checkOcbr = isBillRateOcbr(newVal, shiftRate, getShiftId(shiftItem), billRatesDetails?.billRates);
      const updatedTemp = {
        ...temp,
        offContractId:
          isPresent(newVal, shiftItem, shiftRatesArray) === Status.Unsaved ? null : matchedRateFromDropdown?.contractId,
        rateElementId:
          isPresent(newVal, shiftItem, shiftRatesArray) === Status.Unsaved
            ? uuid()
            : matchedRateFromDropdown?.rateElementId,
        shiftId: getShiftId(shiftItem),
        shift: shiftItem,
        isOcbr: matchedRateFromDropdown.source == RateElemenetSource.Contract ? false : true,
        statusId: matchedRateFromDropdown?.statusId,
        createdAt:
          isPresent(newVal, shiftItem, shiftRatesArray) != Status.Unsaved ? matchedRateFromDropdown?.createdAt : null,
        effectiveStartDate:
          newVal?.source === RateElemenetSource.Facility
            ? isPresent(newVal, shiftItem, shiftRatesArray) === Status.Unsaved
              ? effectiveDates?.startDate
              : matchedRateFromDropdown?.effectiveStartDate
            : effectiveDates?.startDate,
        effectiveEndDate:
          newVal?.source === RateElemenetSource.Facility
            ? isPresent(newVal, shiftItem, shiftRatesArray) === Status.Unsaved
              ? effectiveDates?.endDate
              : matchedRateFromDropdown?.effectiveEndDate
            : effectiveDates?.endDate,
        source:
          isPresent(newVal, shiftItem, shiftRatesArray) === Status.Unsaved ? RateElemenetSource.Order : newVal.source,

        documentIds:
          isPresent(newVal, shiftItem, shiftRatesArray) != Status.Unsaved
            ? getDocNos(newVal, shiftItem, shiftRatesArray)
            : [],
        reason:
          isPresent(newVal, shiftItem, shiftRatesArray) != Status.Unsaved
            ? getRejectionReason(newVal, shiftItem, shiftRatesArray)
            : missingField,
      };
      billRates.push(updatedTemp);

      const isExtraRateElementRequired = isNewRateElementRequired(
        effectiveDates?.endDate,
        updatedTemp?.effectiveEndDate,
      );
      const orderEffectiveEndDate = effectiveDates?.endDate ? moment(effectiveDates.endDate).format() : null;
      const orderEffectiveStartDate = moment(updatedTemp?.effectiveEndDate).add(1, 'days').format();

      if (isExtraRateElementRequired && updatedTemp.statusId != Status.Unsaved)
        billRates.push({
          ...updatedTemp,
          offContractId: null,
          rateElementId: uuid(),
          shiftId: getShiftId(shiftItem),
          shift: shiftItem,
          isOcbr: checkOcbr,
          statusId: Status.Unsaved,
          effectiveStartDate: orderEffectiveStartDate,
          effectiveEndDate: orderEffectiveEndDate,
          source: RateElemenetSource.Order,
          createdAt: null,
          documentIds: [],
          reason: missingField,
          isExtra: true,
        });
    });
  }
  return billRates;
};

export const extractSavedBillRates = (
  newVal,
  existingBillRates = [],
  shiftsArray = [],
  shiftRate,
  isOcbr,
  isFlatRate,
  rateType,
  effectiveDates,
  billRatesDetails,
  user,
  shiftRatesArray,
  avoidFlatRate = false,
  disciplineName,
  shiftName,
) => {
  const billRates = existingBillRates.reduce((results, item) => {
    const isSameSkillset =
      item.disciplineId === shiftRate.disciplineId &&
      item.specialtyId === shiftRate.specialtyId &&
      item.subSpecialtyId === shiftRate.subSpecialtyId;
    const isSameRateType = item.type === rateType;
    const hasNoFlatRate =
      shiftsArray.length > 1
        ? !(isSameSkillset && item.shiftId === getShiftId(shiftName) && isSameRateType)
        : !(isSameSkillset && item.shiftId === null && isSameRateType);
    const hasFlatRate = !(
      isFlatRate &&
      shiftsArray.length > 1 &&
      shiftsArray.some(
        shiftItem =>
          isSameSkillset && (item.shiftId === getShiftId(shiftItem) || item.shiftId == null) && isSameRateType,
      )
    );
    return hasNoFlatRate && hasFlatRate ? [...results, item] : results;
  }, []);

  if (newVal === null) return billRates;

  const temp = {
    offContractId: newVal.offContractId ? newVal.offContractId : null,
    rateElementId: newVal.rateElementId ? newVal.rateElementId : uuid(),
    disciplineAbbreviation: getDisciplineName(shiftRate.disciplineId, disciplineName.disciplineOpts),
    disciplineId: shiftRate.disciplineId,
    specialtyId: shiftRate.specialtyId,
    specialtyName: billRatesDetails?.skillSets[0]?.specialty?.replace(/\s/g, ''),
    subSpecialtyId: shiftRate.subSpecialtyId,
    subSpecialtyName: billRatesDetails?.skillSets[0]?.subspeciality?.replace(/\s/g, ''),
    shiftId: shiftName == null ? null : getShiftId(shiftName),
    shift: shiftName,
    type: rateType,
    billRate: refactorInputValue(newVal),
    isOcbr: isOcbr,
    statusId: isOcbr ? newVal?.statusId ?? Status.Unsaved : null,
    createdAt: newVal?.createdAt,
    createdBy: user,
    reason: newVal.reason ? newVal.reason : missingField,
    effectiveStartDate:
      newVal?.source === RateElemenetSource.Facility ? newVal.effectiveStartDate : effectiveDates?.startDate,
    effectiveEndDate:
      newVal?.source === RateElemenetSource.Facility ? newVal.effectiveEndDate : effectiveDates?.endDate,
    lastUpdatedBy: user,
    dateLastUpdated: moment().format(),
    dateCreated: moment().format(),
    source: isRangeValue(newVal) ? RateElemenetSource.Order : newVal?.source,
    documentIds: newVal?.documentIds,
  };

  billRates.push(temp);

  if (isFlatRate && shiftsArray.length > 1) {
    shiftsArray?.forEach(shiftItem => {
      const checkOcbr = isBillRateOcbr(newVal, shiftRate, getShiftId(shiftItem), billRatesDetails?.billRates);
      const matchedRateFromDropdown = getDropdownMatchedRate(newVal, shiftItem, shiftRatesArray);

      const updatedTemp = {
        ...temp,
        offContractId:
          isPresent(newVal, shiftItem, shiftRatesArray) === Status.Unsaved ? null : matchedRateFromDropdown?.contractId,
        rateElementId:
          isPresent(newVal, shiftItem, shiftRatesArray) === Status.Unsaved
            ? uuid()
            : matchedRateFromDropdown?.rateElementId,
        shiftId: getShiftId(shiftItem),
        shift: shiftItem,
        isOcbr: matchedRateFromDropdown.source == RateElemenetSource.Contract ? false : true,
        statusId: matchedRateFromDropdown?.statusId,
        createdAt:
          isPresent(newVal, shiftItem, shiftRatesArray) != Status.Unsaved ? matchedRateFromDropdown?.createdAt : null,
        effectiveStartDate:
          newVal?.source === RateElemenetSource.Facility
            ? isPresent(newVal, shiftItem, shiftRatesArray) === Status.Unsaved
              ? effectiveDates?.startDate
              : matchedRateFromDropdown?.effectiveStartDate
            : effectiveDates?.startDate,
        effectiveEndDate:
          newVal?.source === RateElemenetSource.Facility
            ? isPresent(newVal, shiftItem, shiftRatesArray) === Status.Unsaved
              ? effectiveDates?.endDate
              : matchedRateFromDropdown?.effectiveEndDate
            : effectiveDates?.endDate,
        source:
          isPresent(newVal, shiftItem, shiftRatesArray) === Status.Unsaved ? RateElemenetSource.Order : newVal.source,

        documentIds:
          isPresent(newVal, shiftItem, shiftRatesArray) != Status.Unsaved
            ? getDocNos(newVal, shiftItem, shiftRatesArray)
            : [],
        reason:
          isPresent(newVal, shiftItem, shiftRatesArray) != Status.Unsaved
            ? getRejectionReason(newVal, shiftItem, shiftRatesArray)
            : missingField,
      };
      billRates.push(updatedTemp);
    });
  }
  return billRates;
};

const isBillRateOcbr = (valueObj, skillSetDetails, shiftID, billRates) => {
  if (valueObj?.isRange) {
    return true;
  } else {
    const matchedSkillset = (billRates || []).find(
      item =>
        item.disciplineId === skillSetDetails.disciplineId &&
        item.specialtyId === skillSetDetails.specialtyId &&
        item.subspecialtyId === skillSetDetails.subSpecialtyId,
    );
    if (!matchedSkillset) return true;
    else {
      const foundMatch = matchedSkillset.shifts.find(item => item.shiftId === shiftID || item.shiftId === null);
      const cotractValue = foundMatch?.regularRate ? `${refactorValue(foundMatch?.regularRate, 2)}` : null;
      return cotractValue !== valueObj?.value?.toString();
    }
  }
};
export const isValueMatch = (itemA, itemB) => {
  if (itemA.value?.minRange && itemA.value?.maxRange) {
    return itemA.value?.minRange === itemB.value?.minRange + '' && itemA.value?.maxRange === itemB.value?.maxRange + '';
  } else {
    return itemA.value === itemB?.value + '';
  }
};

export const isPresent = (newVal, shiftItem, shiftRates) => {
  const getAllDropDownValue = (shiftRates || []).filter(
    item => item?.source === RateElemenetSource.Facility || item?.source === RateElemenetSource.Contract,
  );
  let fromFacility = false;
  let fromContract = false;
  let status = undefined;
  getAllDropDownValue.forEach(option => {
    if ((option.group === shiftItem + ' Hour Shift' || option.groupShiftId === null) && isValueMatch(option, newVal)) {
      if (option.source === 2) {
        fromFacility = true;
        status = option.statusId;
        return;
      } else {
        fromContract = true;
        return;
      }
    }
  });
  if (fromFacility) return getStatus(status);
  if (!fromContract) return Status.Unsaved;
};

export const getDocNos = (newVal, shiftItem, shiftRates) => {
  let docNos = [];
  const getAllDropDownValue = (shiftRates || []).filter(
    item => item?.source === RateElemenetSource.Facility || item?.source === RateElemenetSource.Contract,
  );

  getAllDropDownValue.forEach(option => {
    if ((option.group === shiftItem + ' Hour Shift' || option.groupShiftId === null) && isValueMatch(option, newVal)) {
      if (option.source === 2) {
        docNos = option.documentIds;
        return;
      }
    }
  });
  return docNos;
};

export const getRejectionReason = (newVal, shiftItem, shiftRates) => {
  let reason = missingField;

  const getAllDropDownValue = (shiftRates || []).filter(
    item => item?.source === RateElemenetSource.Facility || item?.source === RateElemenetSource.Contract,
  );

  getAllDropDownValue.forEach(option => {
    if ((option.group === shiftItem + ' Hour Shift' || option.groupShiftId === null) && isValueMatch(option, newVal)) {
      if (option.source === 2) {
        reason = option.reason ? option.reason : missingField;
        return;
      }
    }
  });

  return reason;
};
export const getRateFromDropdown = (newVal, shiftItem, shiftRates) => {
  const getAllDropDownValue = (shiftRates || []).filter(item => item?.source === RateElemenetSource.Facility);
  const dropdownOption = getAllDropDownValue.find(option => {
    if (
      (option.groupShiftId === getShiftId(shiftItem) || option.groupShiftId === null) &&
      isValueMatch(option, newVal)
    ) {
      return option;
    } else return null;
  });
  return dropdownOption;
};

export interface Dates {
  startDate: string;
  endDate: string;
}

export const getContractStartDate = (newVal, shiftItem, shiftRates) => {
  const getAllDropDownValue = (shiftRates || []).filter(
    item => item?.source === RateElemenetSource.Facility || item?.source === RateElemenetSource.Contract,
  );

  const dates: Dates = {
    startDate: undefined,
    endDate: undefined,
  };
  const foundDates = getAllDropDownValue.find(option => {
    return (
      (option.group === shiftItem + ' Hour Shift' || option.groupShiftId === null) &&
      isValueMatch(option, newVal) &&
      option.source === 2
    );
  });
  if (foundDates) {
    dates.startDate = foundDates.effectiveStartDate;
    return dates.startDate;
  } else {
    return undefined;
  }
};

export const getRateElementId = (newVal, shiftItem, shiftRates) => {
  const getAllDropDownValue = (shiftRates || []).filter(
    item => item?.source === RateElemenetSource.Facility || item?.source === RateElemenetSource.Contract,
  );

  const foundDates = getAllDropDownValue.find(option => {
    return (
      (option.group === shiftItem + ' Hour Shift' || option.groupShiftId === null) &&
      isValueMatch(option, newVal) &&
      option.source === 2
    );
  });
  if (foundDates) {
    return foundDates.rateElementId;
  } else {
    return undefined;
  }
};

export const getOffContractId = (newVal, shiftItem, shiftRates) => {
  const getAllDropDownValue = (shiftRates || []).filter(
    item => item?.source === RateElemenetSource.Facility || item?.source === RateElemenetSource.Contract,
  );

  const foundDates = getAllDropDownValue.find(option => {
    return (
      (option.group === shiftItem + ' Hour Shift' || option.groupShiftId === null) &&
      isValueMatch(option, newVal) &&
      option.source === 2
    );
  });
  if (foundDates) {
    return foundDates.offContractId;
  } else {
    return undefined;
  }
};

export const getContractEndDate = (newVal, shiftItem, shiftRates) => {
  const getAllDropDownValue = (shiftRates || []).filter(
    item => item?.source === RateElemenetSource.Facility || item?.source === RateElemenetSource.Contract,
  );

  const dates: Dates = {
    startDate: undefined,
    endDate: undefined,
  };
  const foundRates = getAllDropDownValue.find(option => {
    return (
      (option.group === shiftItem + ' Hour Shift' || option.groupShiftId === null) &&
      isValueMatch(option, newVal) &&
      option.source === 2
    );
  });
  if (foundRates) {
    dates.endDate = foundRates.effectiveEndDate;
    return dates.endDate;
  } else {
    return undefined;
  }
};
export const getDropdownMatchedRate = (newVal, shiftItem, shiftRates) => {
  //STEP 1 -First check match for contract values
  const getContractDropDownValue = (shiftRates || []).filter(item => item?.source === RateElemenetSource.Contract);
  const foundMatchedContractValue = getContractDropDownValue.find(option => {
    return (option.group === shiftItem + ' Hour Shift' || option.groupShiftId === null) && isSameRate(option, newVal);
  });

  if (foundMatchedContractValue) return foundMatchedContractValue;

  //STEP 2 -No matched contract value then check for match in facility offcontract
  const getFacilityOffContractDropDownValues = (shiftRates || []).filter(
    item => item?.source === RateElemenetSource.Facility,
  );
  const foundMatchedFacilityOffContractValue = getFacilityOffContractDropDownValues.find(option => {
    return option.group === shiftItem + ' Hour Shift' && isSameRate(option, newVal);
  });
  if (foundMatchedFacilityOffContractValue) return foundMatchedFacilityOffContractValue;
  else {
    const foundMatchedFacilityOffContractValueAllShifts = getFacilityOffContractDropDownValues.find(option => {
      return option.groupShiftId === null && isSameRate(option, newVal);
    });
    if (foundMatchedFacilityOffContractValueAllShifts) return foundMatchedFacilityOffContractValueAllShifts;
  }
  return null;
};

export const isSameRate = (option, newVal) => {
  if (!newVal?.isRange && !option.value?.minRange && !option.value?.maxRange) {
    return option.value === newVal?.value + '';
  }
  return false;
};
