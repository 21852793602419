import React from 'react';
import { Grid, GridSize, Icon } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { IconLabel } from '../UnitPreviewDesign';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { useTranslation } from 'react-i18next';

interface Props {
  gridSize?: boolean | GridSize | undefined;
  character?: string;
  contentValue?: string;
  styles?: string;
  isRequired?: boolean;
  isDocumentRequired?: boolean;
  isChip?: boolean;
  id?: string;
}

const useStyles = makeStyles()(theme => ({
  root: {
    paddingRight: 10,
    [theme.breakpoints.down('md')]: {
      fontSize: '12px !important',
    },
  },
  icon: {
    width: 20,
    height: 20,
    borderRadius: 10,
    fontSize: 14,
    color: '#FFFFFF',
    backgroundColor: '#006FB9',
    //paddingLeft: 5,
    textAlign: 'center',
  },
  preferredIcon: {
    color: '#FFFFFF',
    backgroundColor: `${theme.palette.components.icon.green.backgroundColor}!important`,
  },
  documentIcon: {
    fill: `${theme.palette.components.icons.secondary.backgroundColor} !important`,
    fontSize: 'large',
    marginLeft: 10,
    marginTop: 2,
  },
}));

export const CharacterIconLabel = (props: Props) => {
  const { classes } = useStyles();
  const { gridSize, character, styles, contentValue } = props;
  const iconStyle = `${styles} ${classes.icon}`;
  return (
    <Grid item xs={gridSize}>
      <Grid container direction="row" spacing={1}>
        <Icon className={iconStyle}>
          <span>{character}</span>
        </Icon>
        {contentValue && <IconLabel>{contentValue}</IconLabel>}
      </Grid>
    </Grid>
  );
};

export const InnerIconSection = (props: Props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { gridSize, isRequired, styles, isDocumentRequired, contentValue, id } = props;
  const iconStyle = isRequired ? `${styles} ${classes.icon}` : `${classes.preferredIcon} ${classes.icon}`;
  return (
    <Grid item xs={gridSize} id={id}>
      <Grid container direction="row" spacing={1}>
        {contentValue && <IconLabel className={classes.root}>{contentValue}</IconLabel>}
        <Icon color={isRequired ?'green':undefined} className={iconStyle}>
          <span>{isRequired ? 'R' : 'P'}</span>
        </Icon>
        {isRequired ? (
          <IconLabel className={classes.root}>{t('order.createUnit.required')}</IconLabel>
        ) : (
          <IconLabel className={classes.root}>{t('order.createUnit.preferred')}</IconLabel>
        )}
        {isDocumentRequired && <DescriptionOutlinedIcon className={classes.documentIcon} />}
      </Grid>
    </Grid>
  );
};
