import { IPlacementStatus } from 'app/models/Placement/PlacementDetails';

export const placementStatusOptions = {
  waitingForClearance: {
    activityTypeId: 1,
    activityStatusId: 1,
    activityStatus: 'Waiting for Clearance',
  },
  requestFileOut: {
    activityTypeId: 1,
    activityStatusId: 2,
    activityStatus: 'Request File Out',
  },
  sent: {
    activityTypeId: 1,
    activityStatusId: 3,
    activityStatus: 'Sent',
    activityStatusAlt: 'Mark as Sent',
  },
  interviewed: {
    activityTypeId: 1,
    activityStatusId: 6,
    activityStatus: 'Interviewed',
  },
  okToSend: {
    activityTypeId: 1,
    activityStatusId: 8,
    activityStatus: 'OK to Send',
  },
  interviewScheduled: {
    activityTypeId: 1,
    activityStatusId: 16,
    activityStatus: 'Interview Scheduled',
  },
  declinedByTraveler: {
    activityTypeId: 1,
    activityStatusId: 4,
    activityStatus: 'Declined by Traveler',
  },
  declinedByFacility: {
    activityTypeId: 1,
    activityStatusId: 5,
    activityStatus: 'Declined by Facility',
  },
  requestCancelled: {
    activityTypeId: 1,
    activityStatusId: 7,
    activityStatus: 'Request Cancelled',
  },
  offerDeclinedByTraveler: {
    activityTypeId: 3,
    activityStatusId: 4,
    activityStatus: 'Declined by Traveler',
  },
  offerDeclinedByFacility: {
    activityTypeId: 3,
    activityStatusId: 5,
    activityStatus: 'Declined by Facility',
  },
  offerRequestCancelled: {
    activityTypeId: 3,
    activityStatusId: 7,
    activityStatus: 'Request Cancelled',
  },
  offerByFacility: {
    activityTypeId: 3,
    activityStatusId: 9,
    activityStatus: 'Offer by Facility',
  },
  offerBookedByTraveler: {
    activityTypeId: 3,
    activityStatusId: 10,
    activityStatus: 'Offer Booked by Traveler',
  },
  travelerAccepts: {
    activityTypeId: 3,
    activityStatusId: 11,
    activityStatus: 'Traveler Accepts',
  },
  facilityConfirms: {
    activityTypeId: 3,
    activityStatusId: 12,
    activityStatus: 'Facility Confirms',
  },
  booked: {
    activityTypeId: 4,
    activityStatusId: 13,
    activityStatus: 'Booked',
  },
  bookingApproved: {
    activityTypeId: 4,
    activityStatusId: 14,
    activityStatus: 'Booking Approved',
  },
  waitingGoodToGo: {
    activityTypeId: 4,
    activityStatusId: 15,
    activityStatus: 'Waiting Good to Go',
  },
  postponementWarning: {
    activityTypeId: 4,
    activityStatusId: 17,
    activityStatus: 'Postponement Warning',
  },
  goodToGo: {
    activityTypeId: 4,
    activityStatusId: 18,
    activityStatus: 'Good to Go',
  },
  submitPacket: {
    activityTypeId: 101,
    activityStatusId: 101,
    activityStatus: 'Submit Packet',
  },
  archivePlacement: {
    activityTypeId: 101,
    activityStatusId: 102,
    activityStatus: 'Archive Placement',
  },
  clientConfirmation: {
    activityTypeId: 101,
    activityStatusId: 103,
    activityStatus: 'Create Client Confirmation',
  },
  candidateContract: {
    activityTypeId: 101,
    activityStatusId: 104,
    activityStatus: 'Create Candidate Contract',
  },
  requestContract: {
    activityTypeId: 101,
    activityStatusId: 105,
    activityStatus: 'Request Contract',
  },
  onAssignment: {
    activityTypeId: 5,
    activityStatusId: 23,
    activityStatus: 'On Assignment',
  },
  assignmentComplete: {
    activityTypeId: 5,
    activityStatusId: 24,
    activityStatus: 'Assignment Complete',
  },
};

export const DefaultAvailableStatusList = [
  placementStatusOptions.waitingForClearance,
  placementStatusOptions.requestFileOut,
  placementStatusOptions.declinedByTraveler,
  placementStatusOptions.declinedByFacility,
  placementStatusOptions.requestCancelled,
];

export const DefaultCurrentPlacementStatus = {
  activityTypeId: -1,
  activityStatusId: -1,
  activityStatus: 'Create Placement',
};

export const isWFC = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === placementStatusOptions.waitingForClearance.activityTypeId &&
    status?.activityStatusId === placementStatusOptions.waitingForClearance.activityStatusId
  );
};

export const isRFO = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === placementStatusOptions.requestFileOut.activityTypeId &&
    status?.activityStatusId === placementStatusOptions.requestFileOut.activityStatusId
  );
};

export const isTA = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === placementStatusOptions.travelerAccepts.activityTypeId &&
    status?.activityStatusId === placementStatusOptions.travelerAccepts.activityStatusId
  );
};

export const isFC = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === placementStatusOptions.facilityConfirms.activityTypeId &&
    status?.activityStatusId === placementStatusOptions.facilityConfirms.activityStatusId
  );
};

export const isBookingApproved = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === placementStatusOptions.bookingApproved.activityTypeId &&
    status?.activityStatusId === placementStatusOptions.bookingApproved.activityStatusId
  );
};

export const isBooked = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === placementStatusOptions.booked.activityTypeId &&
    status?.activityStatusId === placementStatusOptions.booked.activityStatusId
  );
};

export const isWaitingGoodToGo = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === placementStatusOptions.waitingGoodToGo.activityTypeId &&
    status?.activityStatusId === placementStatusOptions.waitingGoodToGo.activityStatusId
  );
};

export const isPostponementWarning = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === placementStatusOptions.postponementWarning.activityTypeId &&
    status?.activityStatusId === placementStatusOptions.postponementWarning.activityStatusId
  );
};

export const isGoodToGo = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === placementStatusOptions.goodToGo.activityTypeId &&
    status?.activityStatusId === placementStatusOptions.goodToGo.activityStatusId
  );
};

export const isInitialStatus = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === DefaultCurrentPlacementStatus.activityTypeId &&
    status?.activityStatusId === DefaultCurrentPlacementStatus.activityStatusId
  );
};

export const isSubmitPacket = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === placementStatusOptions.submitPacket.activityTypeId &&
    status?.activityStatusId === placementStatusOptions.submitPacket.activityStatusId
  );
};

export const isClientConfirmation = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === placementStatusOptions.clientConfirmation.activityTypeId &&
    status?.activityStatusId === placementStatusOptions.clientConfirmation.activityStatusId
  );
};

export const isCandidateContract = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === placementStatusOptions.candidateContract.activityTypeId &&
    status?.activityStatusId === placementStatusOptions.candidateContract.activityStatusId
  );
};

export const isRequestContract = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === placementStatusOptions.requestContract.activityTypeId &&
    status?.activityStatusId === placementStatusOptions.requestContract.activityStatusId
  );
};

export const isArchivePlacement = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === placementStatusOptions.archivePlacement.activityTypeId &&
    status?.activityStatusId === placementStatusOptions.archivePlacement.activityStatusId
  );
};

export const isDeclinedByFacility = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === placementStatusOptions.declinedByFacility.activityTypeId &&
    status?.activityStatusId === placementStatusOptions.declinedByFacility.activityStatusId
  );
};

export const isOfferDeclinedByFacility = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === placementStatusOptions.offerDeclinedByFacility.activityTypeId &&
    status?.activityStatusId === placementStatusOptions.offerDeclinedByFacility.activityStatusId
  );
};

export const isOfferByFacility = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === placementStatusOptions.offerByFacility.activityTypeId &&
    status?.activityStatusId === placementStatusOptions.offerByFacility.activityStatusId
  );
};

export const isOfferBookedByTraveler = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === placementStatusOptions.offerBookedByTraveler.activityTypeId &&
    status?.activityStatusId === placementStatusOptions.offerBookedByTraveler.activityStatusId
  );
};

export const isDeclinedByTraveler = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === placementStatusOptions.declinedByTraveler.activityTypeId &&
    status?.activityStatusId === placementStatusOptions.declinedByTraveler.activityStatusId
  );
};
export const isOfferDeclinedByTraveler = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === placementStatusOptions.offerDeclinedByTraveler.activityTypeId &&
    status?.activityStatusId === placementStatusOptions.offerDeclinedByTraveler.activityStatusId
  );
};

export const isRequestCancelled = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === placementStatusOptions.requestCancelled.activityTypeId &&
    status?.activityStatusId === placementStatusOptions.requestCancelled.activityStatusId
  );
};

export const isOfferRequestCancelled = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === placementStatusOptions.offerRequestCancelled.activityTypeId &&
    status?.activityStatusId === placementStatusOptions.offerRequestCancelled.activityStatusId
  );
};

export const isOnAssignment = (status?: IPlacementStatus) =>
  status?.activityTypeId === placementStatusOptions.onAssignment.activityTypeId &&
  status?.activityStatusId === placementStatusOptions.onAssignment.activityStatusId;

export const isAssignmentComplete = (status?: IPlacementStatus) =>
  status?.activityTypeId === placementStatusOptions.assignmentComplete.activityTypeId &&
  status?.activityStatusId === placementStatusOptions.assignmentComplete.activityStatusId;

export const isOkToSend = (status?: IPlacementStatus) => {
  return (
    status?.activityTypeId === placementStatusOptions.okToSend.activityTypeId &&
    status?.activityStatusId === placementStatusOptions.okToSend.activityStatusId
  );
};

export const isSent = (status?: IPlacementStatus) =>
  status?.activityTypeId === placementStatusOptions.sent.activityTypeId &&
  status?.activityStatusId === placementStatusOptions.sent.activityStatusId;

export const isInterviewed = (status?: IPlacementStatus) =>
  status?.activityTypeId === placementStatusOptions.interviewed.activityTypeId &&
  status?.activityStatusId === placementStatusOptions.interviewed.activityStatusId;

export const isInterviewScheduled = (status?: IPlacementStatus) =>
  status?.activityTypeId === placementStatusOptions.interviewScheduled.activityTypeId &&
  status?.activityStatusId === placementStatusOptions.interviewScheduled.activityStatusId;

export const isSentOrBeyond = (status?: IPlacementStatus) =>
  ![
    placementStatusOptions.waitingForClearance,
    placementStatusOptions.requestFileOut,
    placementStatusOptions.okToSend,
  ].some(
    option => option.activityStatusId === status?.activityStatusId && option.activityTypeId === status?.activityTypeId,
  );

export const isSubmissionNegativeStatus = (status?: IPlacementStatus) =>
  [
    placementStatusOptions.declinedByTraveler,
    placementStatusOptions.declinedByFacility,
    placementStatusOptions.requestCancelled,
  ].some(
    option => option.activityStatusId === status?.activityStatusId && option.activityTypeId === status?.activityTypeId,
  );

export const isNegativeStatus = (status?: IPlacementStatus) => {
  if (status) {
    switch (status.activityTypeId) {
      case 1:
        switch (status.activityStatusId) {
          case 4:
          case 5:
          case 7:
            return true;
          default:
            return false;
        }
      case 3:
        switch (status.activityStatusId) {
          case 4:
          case 5:
          case 7:
            return true;
          default:
            return false;
        }
      case 4:
        switch (status.activityStatusId) {
          case 20:
          case 21:
          case 22:
          case 28:
            return true;
          default:
            return false;
        }
      case 5:
        switch (status.activityStatusId) {
          case 25:
          case 26:
          case 27:
          case 29:
            return true;
          default:
            return false;
        }
      default:
        return false;
    }
  }
};

//"Canceled by Traveler"/"Canceled by Facility"/"Canceled by AMH"/"Canceled by Settlement"
export const isCancellationStatus = (status?: IPlacementStatus) => {
  if (status) {
    switch (status.activityTypeId) {
      // 4	20	Canceled By Traveler
      // 4	21	Canceled By Facility
      // 4	22	Canceled by AMH
      // 4	28	Canceled by Settlement
      case 4:
        return [20, 21, 22, 28].includes(status.activityStatusId);
      // 5	25	Canceled By Traveler
      // 5	26	Canceled By Facility
      // 5	27	Canceled by AMH
      // 5	29	Canceled by Settlement
      case 5:
        return [25, 26, 27, 29].includes(status.activityStatusId);
      default:
        return false;
    }
  }
};

export const activityTypeOptions = {
  fileOut: {
    activityTypeId: 1,
    activityType: 'File Out',
  },
  offer: {
    activityTypeId: 3,
    activityType: 'Offer',
  },
  booking: {
    activityTypeId: 4,
    activityType: 'Booking',
  },
  assignment: {
    activityTypeId: 5,
    activityType: 'Assignment',
  },
};

export const placementRegionUnlocked = (status?: IPlacementStatus) => {
  if (status) {
    switch (status.activityTypeId) {
      // 1 1 Waiting for Clearance
      // 1 2 Request File Out
      // 1 3 Sent
      // 1 4 Interview Scheduled
      // 1 6 Interviewed
      case 1:
        return [1, 2, 3, 16, 6].includes(status.activityStatusId);

      // 3 9 Offer by Facility
      // 3 19 Offer Booked by Traveler
      // 3 11 Traveler Accepts
      // 3 12 Facility Confirms
      case 3:
        return [9, 10, 11, 12].includes(status.activityStatusId);

      // 4 13 Booked
      // 4 14 Booking Approved
      // 4 15 Waiting Good to Go
      // 4 17 Postponement Warning
      // 4 18 Good to Go
      case 4:
        return [13, 14, 15, 17, 18].includes(status.activityStatusId);
      default:
        return false;
    }
  }
};

export enum activityTypeIds {
  fileOut = 1,
  offer = 3,
  booking = 4,
  assignment = 5,
}

export enum assignmentActivityStatusIds {
  onAssignment = 23,
  assignmentComplete = 24,
  canceledByTraveler = 25,
  canceledByFacility = 26,
  canceledByAMH = 27,
  canceledBySettlement = 29,
}
