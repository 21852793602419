/* eslint-disable tss-unused-classes/unused-classes */
/* eslint-disable react-hooks/exhaustive-deps */
import { Cancel } from '@AMIEWEB/Common/CancelModal/Cancel';
import { CoverageConfirmationModal } from '@AMIEWEB/Common/CoverageConfirmation/CoverageConfirmation';
import { FormatDate } from '@AMIEWEB/Notification/Tasks/Common/FormatData';
import { Button, CircularProgress, Grid, TextField } from 'amn-ui-core';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { ITypeAheadOption } from 'app/models/Orders/OrderDetails';
import {
  ActionType,
  CoverageAction,
  ICoverageActionDetails,
  ICoverageData,
  ITaskComments,
  IUser,
  TaskCoverageActionType,
  TaskCoverageDescription,
  TaskDescription,
  TaskPriority,
  TaskPriorityList,
} from 'app/models/Tasks/Tasks';
import { ICoverageDetails } from 'app/models/UserCoverage/UserCoverage';
import { addDays, addYears, isBefore, isValid, isWithinInterval, parse, parseISO } from 'date-fns';
import { enGB } from 'date-fns/locale';
import _cloneDeep from 'lodash/cloneDeep';
import { default as moment, default as momentTz } from 'moment-timezone';
import { selectUser } from 'oidc/user.selectors';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveCoverage } from 'store/redux-store/user-coverage/selectors';
import { makeStyles } from 'tss-react/mui';
import { DateRangePicker } from '../../../../app/ComponentLibrary/DateRangeNew';
import { AssignedToValue } from '../CreateTask/AssignedToEmployeeV2';
import { CCEmployees } from '../CreateTask/CCEmployees';
import { convertMultipleAssignedUserData, convertUserData } from '../CreateTask/FormatData';
import { SelectWithAvatar } from '../CreateTask/SelectWithAvatar';
import { checkButtonDisableStatusByCoverage } from '../StatusUpdate/helper';
import { updateSubject } from '../helper';
import { taskDetailsActions } from '../store/Tasks.redux';
import { selectInternalTaskDetailsData, selectSubTaskName, selectedUpdatedId } from '../store/Tasks.selectors';
import { CommentsAttachment } from './CommentsAttachments';
import { checkUserEditStatus, checkUserEligible, getAssignee } from './helper';
import { SelectWithIcon } from '../CreateTask/SelectWithIcon';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { getSharedDeskAssociatedUser } from '../EditTask/helper';
import { getEmployeeId } from '@AMIEWEB/Notification/Tasks/Common_v2/utils';

export const createTaskModalStyles = makeStyles()(theme => ({
  // Modal styles
  modalContainer: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '450px',
      overflow: 'visible',
    },
  },
  dialogContent: {
    paddingTop: '12px !important',
    padding: '0px 18px 18px 24px',
    width: '100%',
  },
  errorText: {
    '& .MuiFilledInput-underline:before': {
      left: '0',
      right: '0',
      bottom: '0',
      position: 'absolute',
      transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      borderBottom: '1px solid #FF0000',
      pointerEvents: 'none',
    },
    width: '100%',
  },
  firstGridItem: {
    marginTop: '20px',
  },
  // Modal Body Styles
  inputs: {
    width: '100%',
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    '&:hover': {
      '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
        color: '#006FB9',
      },
    },
  },
  dueDateContainer: {
    '& .MuiInputLabel-filled.MuiInputLabel-marginDense': {
      transform: 'translate(12px, 22px) scale(1)',
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '14px',
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: 'translate(12px, 10px) scale(0.75)',
    },
  },
  dueDateInput: {
    '& .MuiFilledInput-input': {
      height: '1.3736em',
      padding: '27px 12px 10px',
      fontfamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif',
      fontSize: '12px',
    },
  },
  dueDateField: {
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Roboto',
    color: theme.palette.framework.system.charcoal,
    letterSpacing: '0px',
    opacity: '1',
    marginTop: '4px',
  },
  fieldLabel: {
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Roboto',
    color: theme.palette.framework.system.charcoal,
    letterSpacing: '0px',
    opacity: '1',
  },
  ccBtn: {
    padding: '0',
    width: '36px',
    height: '36px',
    minWidth: '0',
    background: '#F7F7F7 0% 0% no-repeat padding-box',
    border: `1px solid ${theme.palette.system.fadedGrey}`,
    borderRadius: '5px',
    opacity: '1',
    boxShadow: 'none',
    font: 'normal normal normal 14px/20px Roboto',
    letterSpacing: '0',
    color: theme.palette.framework.system.charcoal,
    '&:hover': {
      backgroundColor: '#F7F7F7',
    },
  },
  banner: {
    paddingBottom: '1px !important',
  },
  validationLoader: {
    position: 'absolute',
    zIndex: 999,
    left: 'calc(50% - 20px)',
    top: '50%',
  },
  paper: {
    '& .MuiAutocomplete-listbox > li:last-child': {
      borderTop: '1px solid #c7c7c7',
      marginBottom: '-8px',
    },
    '& .MuiAutocomplete-listbox > li:first-child': {
      borderTop: 'none',
      marginBottom: '0',
    },
  },
  fileTypeText: {
    textTransform: 'none',
  },
  snackBar: {
    position: 'absolute',
    zIndex: 2,
  },
  fullWidth: {
    width: '100%',
    justifyContent: 'unset',
  },
  flexContainer: {
    width: '16rem',
    display: 'flex',
    alignItems: 'flex-start',
  },
  checkBox: {
    '& .MuiCheckbox-root': {
      padding: '0px',
    },
    marginTop: 'auto',
    padding: '0px',
  },
  CheckBoxLabel: {
    marginTop: '12px',
    textAlign: 'left',
    font: 'normal normal normal 14px/20px Roboto',
    letterSpacing: '0px',
    color: theme.palette.framework.system.charcoal,
    opacity: 1,
    paddingLeft: '9px',
  },
}));

export const TaskCommentModal = ({
  openCommentModal,
  externalTask,
  container,
  parentTaskDetails,
  replyRecipients,
  placementEmployees,
  comment = null,
  setSubtaskModalOpen,
  setComment,
  subtask,
  setTask,
  enableTaskName,
  task = null,
}: {
  openCommentModal: boolean;
  externalTask: boolean;
  container: number;
  parentTaskDetails: any;
  replyRecipients?: any;
  placementEmployees?: any;
  comment?: ITaskComments;
  setComment?: (e) => void;
  setTask?: (e) => void;
  setSubtaskModalOpen: (e) => void;
  subtask?: any;
  enableTaskName?: any;
  task?: ITaskComments;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { classes } = createTaskModalStyles();
  const { promiseInProgress: isCallingAddAPI } = usePromiseTracker({ area: 'save-task-comment', delay: 0 });
  const { promiseInProgress: isCallingGetCommentsAPI } = usePromiseTracker({ area: 'get-task-comments', delay: 0 });
  const { promiseInProgress: isCallingEditCommentAPI } = usePromiseTracker({ area: 'edit-task-comment', delay: 0 });
  const date = new Date();
  const taskDetails = useSelector(selectInternalTaskDetailsData);
  const taskUpdatedId = useSelector(selectedUpdatedId);
  const userInfo = useSelector(selectUser);
  const subTaskName = useSelector(selectSubTaskName);
  const [subtaskValue, setSubtaskValue] = useState<any>('');
  const [descValue, setDescValue] = useState<any>('');
  const [ccEmpValues, setCCEmpvalues] = useState<ITypeAheadOption[]>([]);
  const [showccField, setShowccField] = useState(false);
  const [dueDateValue, setDueDateValue] = useState<any>();
  const [attachments, setAttachments] = useState<any>([]);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [assignedToValue, setAssignedValue] = useState<ITypeAheadOption[]>([]);
  const [initialSubtask, setInitialSubtask] = useState<ITaskComments>({});
  const [isProvidingCoverage, setIsProvidingCoverage] = useState<boolean>(null);
  const [userCanEdit, setUserCanEdit] = useState<boolean>(false);
  const [selectedCoverageId, setSelectedCoverageId] = useState<number>(0);
  const [coverageData, setCoverageData] = useState<ICoverageData>({ openDialog: false });
  const isCallingAPI = isCallingAddAPI || isCallingGetCommentsAPI || isCallingEditCommentAPI;
  const minDueDate =
    comment && isBefore(new Date(comment?.dueDate as string), date)
      ? addDays(new Date(comment?.dueDate as string), -1)
      : addDays(date, -1);
  const [selectedCoverageOption, setSelectedCoverageOption] = useState<any>(null);
  const [coverageActionDetails, setCoverageActionDetails] = useState<any>(null);
  const activeCoverage = useSelector(selectActiveCoverage);
  const [taskPriority, setTaskPriority] = useState<string>(
    comment?.taskPriorityId?.toString() ?? TaskPriority.Normal.toString(),
  );

  const createSubTaskVar = {
    subTask: 'subTask',
    cc: 'cc',
    dueDate: 'dueDate',
    assignedTo: 'assignedTo',
    description: 'description',
    taskPriorityId: 'taskPriorityId',
  };

  const formMethods = useForm({
    mode: 'onSubmit',
  });

  const {
    handleSubmit,
    control,
    trigger,
    setError,
    clearErrors,
    getValues,
    setValue,
    formState: { isDirty, errors },
  } = formMethods;

  const onCloseCall = () => {
    setComment(null);
    setCancelModalOpen(false);
    clearTaskUpdatedId();
    setSubtaskModalOpen(false);
    setCoverageActionDetails(null);
  };

  const onCloseModal = () => {
    if (isDirty) {
      setCancelModalOpen(true);
    } else {
      onCloseCall();
    }
  };

  const getChildComponentsValue = () => {
    const ccValues = ccEmpValues?.map((a: ITypeAheadOption) => a?.object).map(d => d?.empId);
    const assignedTo = assignedToValue?.map((a: ITypeAheadOption) => a?.object).map(d => d?.empId);
    setValue(createSubTaskVar.assignedTo, assignedTo);
    setValue(createSubTaskVar.cc, ccValues);
    if (assignedTo) {
      clearErrors(createSubTaskVar.assignedTo);
    }
  };

  const handleCoverageSelection = (data: ICoverageDetails) => {
    setSelectedCoverageOption(data.id);
    setSelectedCoverageId(data?.coveredEmployeeId ?? 0);
    if (data.id.toString() !== userInfo?.userInfo.employeeId.toString()) {
      setCoverageActionDetails({
        coveredByEmployeeId: data?.coveringEmployeeId,
        coveredByEmployeeFirstName: data?.coveringEmployeeFirstName,
        coveredByEmployeeLastName: data?.coveringEmployeeLastName,
        coveredForEmployeeId: data?.coveredEmployeeId,
        coveredForEmployeeFirstName: data?.coveredEmployeeFirstName,
        coveredForEmployeeLastName: data?.coveredEmployeeLastName,
        actionById: userInfo?.userInfo?.employeeId,
        actionDate: moment.tz(new Date(), 'America/Los_Angeles').format(),
        actionType: comment ? TaskCoverageActionType.EDIT_TASK : TaskCoverageActionType.CREATE_TASK,
        actionTypeDescription: comment ? TaskCoverageDescription.EDIT_TASK : TaskCoverageDescription.CREATE_TASK,
      } as ICoverageActionDetails);
    } else {
      setCoverageActionDetails(null);
    }
  };

  const checkDateValidation = value => {
    const isoDate = parseISO(value);
    if (isValid(isoDate)) {
      return null;
    }
    if (value.length === 0) {
      return t('required');
    } else if (value.includes('_') || value.includes(' ')) {
      return t('invalidFormat');
    } else {
      // Try parsing the date in 'mm/dd/yyyy' format
      const customDateFormat = parse(value, 'MM/dd/yyyy', new Date(), { locale: enGB });
      const isDateValid = isValid(customDateFormat);
      if (isDateValid && isWithinInterval(customDateFormat, { start: minDueDate, end: addYears(date, 10) })) {
        clearErrors(createSubTaskVar.dueDate);
        const formattedDate = customDateFormat.setHours(23, 59, 59);
        const dueDate = FormatDate(formattedDate);
        setDueDateValue(dueDate);
        return null;
      } else {
        return t('invalidDate');
      }
    }
  };

  const setDefaultValues = () => {
    const { assignedTo, cc } = replyRecipients;
    const uniqueAssigneeArray = Array.from(new Map(assignedTo?.map(item => [item?.id, item])).values());
    const convertedAssignedTo = convertMultipleAssignedUserData(uniqueAssigneeArray);
    const assignedToUsers = convertedAssignedTo?.map((a: ITypeAheadOption) => a?.object).map(d => d?.empId);
    const convertedCC = convertUserData(cc);
    const ccUsers = convertedCC?.map((a: ITypeAheadOption) => a?.object).map(d => d?.empId);
    if (ccUsers?.length > 0) {
      setShowccField(true);
    } else {
      setShowccField(false);
    }
    clearErrors('subTask');
    setAssignedValue(convertedAssignedTo);
    setCCEmpvalues(convertedCC);
    getChildComponentsValue();
    setTimeout(() => {
      setValue(createSubTaskVar.subTask, updateSubject(subtask));
      setValue(createSubTaskVar.cc, ccUsers);
      setValue(createSubTaskVar.assignedTo, assignedToUsers);
    }, 0);
  };

  const submitTask = formData => {
    const assignedTo = assignedToValue?.map((a: ITypeAheadOption) => a.object)?.map(d => d.empId);
    const cc = ccEmpValues?.map((a: ITypeAheadOption) => a.object)?.map(d => d.empId);
    if (!comment) {
      if (parentTaskDetails?.rootTaskId) {
        const commentData = {
          originalTaskId: taskDetails?.taskId,
          subject: `${taskDetails?.taskCategory ?? ''} - ${
            formData?.subTask === '' ? parentTaskDetails?.task : formData?.subTask
          }`,
          commentCreatedBy: coverageActionDetails
            ? coverageActionDetails?.coveredForEmployeeId
            : !isNullOrUndefined(userInfo?.userInfo?.sharedProfile)
            ? parseInt(userInfo?.userInfo?.sharedProfile?.employeeId)
            : userInfo?.userInfo?.employeeId,
          commentCreatedDate: momentTz.tz(new Date(), 'America/Los_Angeles').format(),
          commentText: formData?.description,
          ccEmployees: cc,
          assignedToEmployees: assignedTo,
          dueDate: formData?.dueDate,
          assignedTo: assignedToValue,
          ccUsers: ccEmpValues,
          taskCoverageAction: coverageActionDetails,
          createdBy:  !isNullOrUndefined(userInfo?.userInfo?.sharedProfile) ? new IUser(
            userInfo?.userInfo?.sharedProfile?.firstName || '',
            userInfo?.userInfo?.sharedProfile?.lastName || '',
            parseInt(userInfo?.userInfo?.sharedProfile?.employeeId),
          ): new IUser(
            userInfo?.userInfo?.firstName || '',
            userInfo?.userInfo?.lastName || '',
            userInfo?.userInfo?.employeeId,
          ),
          taskPriorityId: +taskPriority,
          sharedDeskAssociatedUser: isNullOrUndefined(userInfo?.userInfo?.sharedProfile)
            ? undefined
            : getSharedDeskAssociatedUser(userInfo?.userInfo, ActionType.CreateTask, TaskDescription.CreateTask),
        };
        dispatch(taskDetailsActions.saveSubTaskAction(commentData));
      }
    } else {
      const updatedComment = _cloneDeep(comment);
      updatedComment.tos = assignedTo;
      updatedComment.ccs = cc;
      updatedComment.companyId = parentTaskDetails?.companyId;
      updatedComment.comment = formData?.description;
      updatedComment.dueDate = formData?.dueDate;
      updatedComment.subject = formData?.subTask;
      updatedComment.taskCategory = parentTaskDetails?.taskCategoryId;
      updatedComment.taskCategoryText = parentTaskDetails?.taskCategory;
      updatedComment.originalTaskId = parentTaskDetails?.taskId;
      updatedComment.rootTaskId = parentTaskDetails?.rootTaskId;
      updatedComment.taskCoverageAction = coverageActionDetails;
      updatedComment.taskPriorityId = +taskPriority;
      updatedComment.sharedDeskAssociatedUser = isNullOrUndefined(userInfo?.userInfo?.sharedProfile)
        ? undefined
        : getSharedDeskAssociatedUser(userInfo?.userInfo, ActionType.EditTask, TaskDescription.EditTask);

      dispatch(
        taskDetailsActions.editSubTaskAction({ updatedSubTask: updatedComment, initialSubTask: initialSubtask }),
      );
    }
  };

  const setDefaultDate = () => {
    const dueDate = getDueDate();
    if (date) {
      setDueDateValue(dueDate);
    }
  };

  const getDueDate = () => {
    let dueDate = null;
    let formattedDate = null;
    if (comment?.dueDate) {
      dueDate = comment?.dueDate;
      if (!moment.isMoment(dueDate)) {
        dueDate = new Date(dueDate);
        dueDate.setHours(23, 59, 59, 999);
        dueDate = moment.tz(dueDate, 'America/Los_Angeles').format();
      }
      formattedDate = dueDate;
    } else {
      dueDate = new Date();
      dueDate.setHours(23, 59, 59, 999);
      formattedDate = moment.tz(dueDate, 'America/Los_Angeles').format();
    }
    return formattedDate;
  };

  const handleDateChange = dueDate => {
    setValue(createSubTaskVar.dueDate, dueDate);
    if (dueDate) {
      clearErrors(createSubTaskVar.dueDate);
    }
  };

  const clearTaskUpdatedId = () => {
    dispatch(taskDetailsActions.setTaskUpdatedId(null));
  };

  const checkCoverage = () => {
    const hsgId = 6000;
    const hsgAsRecipient = getAssignee(comment, { employeeId: hsgId });
    const currentUserAsAffiliate = checkUserEligible(comment, userInfo.userInfo);
    if (hsgAsRecipient && !currentUserAsAffiliate) {
      setCoverageData({
        openDialog: true,
        action: CoverageAction.EDIT_TASK,
        coveredUser: hsgAsRecipient,
      });
      return true;
    }
    return false;
  };

  const handleConfirmClick = async () => {
    const validTask = await trigger();
    const assignToCheck = getValues(createSubTaskVar.assignedTo);
    if (!externalTask && assignToCheck?.length <= 0) {
      setError(createSubTaskVar.assignedTo, {
        type: 'required',
      });
    } else {
      if (validTask) {
        handleSubmit(submitTask)().catch(err => {});
      }
    }
  };

  const checkCoverageActionDisable = () => {
    if (activeCoverage?.length > 0) {
      return checkButtonDisableStatusByCoverage(comment, activeCoverage, selectedCoverageId, userInfo, true);
    } else {
      return false;
    }
  };

  useEffect(() => {
    setDefaultDate();
    if (comment) {
      setUserCanEdit(!!checkUserEditStatus(comment, true, activeCoverage, parseInt(getEmployeeId(userInfo?.userInfo))));
      setDescValue(comment?.comment);
      clearErrors('description');
      setTimeout(() => {
        setValue(createSubTaskVar.description, comment?.comment);
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment]);

  useEffect(() => {
    if (taskDetails && !comment) {
      const subTaskDetails = _cloneDeep(taskDetails) as ITaskComments;
      const assignedTo = assignedToValue?.map((a: ITypeAheadOption) => a.object)?.map(d => d.empId);
      const cc = ccEmpValues?.map((a: ITypeAheadOption) => a.object)?.map(d => d.empId);
      subTaskDetails.tos = assignedTo;
      subTaskDetails.ccs = cc;
      setUserCanEdit(!!checkUserEditStatus(subTaskDetails, true, activeCoverage, parseInt(getEmployeeId(userInfo?.userInfo))));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskDetails]);

  useEffect(() => {
    setDefaultValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replyRecipients]);

  useEffect(() => {
    if (taskUpdatedId > 0) {
      onCloseCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskUpdatedId]);

  useEffect(() => {
    if (!subTaskName) {
      setSubtaskValue(parentTaskDetails?.task);
    }
    if (comment) {
      setInitialSubtask(comment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentTaskDetails?.task, subTaskName]);

  useEffect(() => {
    if (subTaskName) {
      setSubtaskValue(subtask);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTaskName]);

  useEffect(() => {
    if (assignedToValue?.length > 0) {
      clearErrors(createSubTaskVar.assignedTo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedToValue]);

  useEffect(() => {
    handleDateChange(dueDateValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dueDateValue]);

  useEffect(() => {
    if (isProvidingCoverage !== null) {
      setCoverageData({ ...coverageData, openDialog: false, decision: isProvidingCoverage });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProvidingCoverage]);

  useEffect(() => {
    if (coverageData?.decision && coverageData?.openDialog === false) {
      handleConfirmClick();
    } else if (coverageData?.decision === false && coverageData?.openDialog === false) {
      setIsProvidingCoverage(null);
      onCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverageData]);
  return (
    <FormProvider {...formMethods}>
      <GenericDialog
        open={openCommentModal}
        fullWidth
        className={classes.modalContainer}
        onClose={onCloseModal}
        variant="blue"
        dialogTitleProps={{
          text: comment ? t('notification.subTask.editTitle') : t('notification.subTask.modalTitle'),
          closeButton: true,
        }}
        dialogContentProps={{
          classes: { root: classes.dialogContent },
        }}
        dialogActions={[
          {
            text: t('notification.createTask.cancelBtn'),
            variant: 'contained',
            color: 'tertiary',
            onClick: () => onCloseModal(),
          },
          {
            text: comment ? t('notification.emails.save') : t('notification.subTask.create'),
            variant: 'contained',
            disabled: !isDirty || (comment && activeCoverage?.length > 0 && checkCoverageActionDisable()),
            onClick: async e => {
              if (comment) {
                const coverage = checkCoverage();
                if (!coverage) {
                  handleConfirmClick();
                }
              } else {
                handleConfirmClick();
              }
            },
          },
        ]}
      >
        <Grid container spacing={4}>
          {isCallingAPI && <CircularProgress className={classes.validationLoader} />}
          <Grid container spacing={4} item xs={12} sx={{ marginTop: '12px' }} className={classes.banner}>
            {(activeCoverage?.length > 0 || (comment && userCanEdit)) && (
              <Grid item xs={6}>
                <SelectWithAvatar
                  selectedCoverageOption={selectedCoverageOption}
                  handleCoverageSelection={handleCoverageSelection}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <SelectWithIcon
                name={createSubTaskVar.taskPriorityId}
                label={t('notification.createTask.priority')}
                options={TaskPriorityList}
                value={taskPriority}
                handleChange={item => {
                  setTaskPriority(item.value);
                }}
                disabled={isCallingAPI}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: comment ? '12px' : '0px' }}>
            <Controller
              control={control}
              name={createSubTaskVar.subTask}
              render={({ ref, onChange, ...rest }) => (
                <TextField
                  multiline
                  variant="filled"
                  className={classes.inputs}
                  InputLabelProps={{
                    className: classes.fieldLabel,
                  }}
                  color="primary"
                  value={updateSubject(subtaskValue)}
                  error={externalTask && errors?.subTask ? true : false || (isDirty && errors?.subTask) ? true : false}
                  disabled={enableTaskName}
                  id="subTask"
                  label={t('notification.subTask.subTaskName')}
                  onChange={e => {
                    if (e.target.value.length <= 500) {
                      onChange(e);
                      setSubtaskValue(e.target.value);
                    }
                  }}
                  helperText={
                    (externalTask && errors?.subTask && errors?.subTask?.message) ||
                    (isDirty && errors?.subTask && errors?.subTask?.message)
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={createSubTaskVar.description}
              rules={{
                maxLength: { value: 4000, message: t('notification.createTask.maxLimitLabel') },
                required: true,
              }}
              render={({ ref, onChange, ...rest }) => (
                <TextField
                  multiline
                  variant="filled"
                  className={classes.inputs}
                  color="primary"
                  value={descValue}
                  maxRows={3}
                  error={
                    externalTask && errors?.description
                      ? true
                      : false || (isDirty && errors?.description)
                      ? true
                      : false
                  }
                  InputLabelProps={{
                    className: classes.fieldLabel,
                  }}
                  disabled={isCallingAPI}
                  id="description"
                  label={comment ? t('notification.subTask.details') : t('notification.subTask.description')}
                  onChange={e => {
                    if (e.target.value?.length > 4000) {
                      setError(createSubTaskVar.description, {
                        type: 'maxLength',
                        message: t('notification.createTask.maxLimitLabel'),
                      });
                    } else if (e.target.value?.length <= 4000) {
                      clearErrors(createSubTaskVar.description);
                    }
                    onChange(e);
                    setDescValue(e.target.value);
                  }}
                  helperText={
                    (externalTask && errors?.description && errors?.description?.message) ||
                    (isDirty && errors?.description && t('required'))
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={5} className={classes.dueDateContainer}>
            <Controller
              control={control}
              name={createSubTaskVar.dueDate}
              rules={{ validate: checkDateValidation }}
              render={({ ref, onChange, value, ...rest }) => (
                <DateRangePicker
                  variant="filled"
                  placeholder={t('notification.createTask.dueDateLabel')}
                  className={classes.dueDateInput}
                  useMaxWidth={true}
                  single={true}
                  value={dueDateValue}
                  initialDateRange={dueDateValue}
                  labelClass={dueDateValue ? classes.fieldLabel : classes.dueDateField}
                  trailingIconShow={true}
                  minDate={minDueDate}
                  maxWidth={150}
                  isDisabled={isCallingAPI}
                  disablePortal={true}
                  actionBar={false}
                  clickAway={true}
                  keepClickAwayVal={true}
                  fallbackPlacements={['bottom-start']}
                  onInlineEdit={() => {
                    clearErrors(createSubTaskVar.dueDate);
                  }}
                  onBlurText={dateValue => {
                    setDueDateValue(dateValue);
                  }}
                  onChange={e => {
                    const dueDate = e?.endDate ? FormatDate(e?.endDate) : '';
                    onChange(dueDate);
                    setDueDateValue(dueDate);
                  }}
                  error={externalTask && errors?.dueDate ? true : false || (isDirty && errors?.dueDate) ? true : false}
                  helperText={
                    externalTask && errors?.dueDate
                      ? errors?.dueDate?.message
                      : '' || (isDirty && errors?.dueDate)
                      ? errors?.dueDate?.message
                      : ''
                  }
                  definedRanges={[
                    {
                      label: t('notification.createTask.dueDateLabels.today'),
                      startDate: date,
                      endDate: date,
                      useLabelAsValue: false,
                    },
                    {
                      label: t('notification.createTask.dueDateLabels.tomorrow'),
                      startDate: addDays(date, 1),
                      endDate: addDays(date, 1),
                      useLabelAsValue: false,
                    },
                    {
                      label: t('notification.createTask.dueDateLabels.2Days'),
                      startDate: addDays(date, 2),
                      endDate: addDays(date, 2),
                      useLabelAsValue: false,
                    },
                    {
                      label: t('notification.createTask.dueDateLabels.7Days'),
                      startDate: addDays(date, 7),
                      endDate: addDays(date, 7),
                      useLabelAsValue: false,
                    },
                    {
                      label: t('notification.createTask.dueDateLabels.30Days'),
                      startDate: addDays(date, 30),
                      endDate: addDays(date, 30),
                      useLabelAsValue: false,
                    },
                  ]}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={createSubTaskVar.assignedTo}
              rules={{ required: true }}
              render={({ ref, onChange, value, ...rest }) => (
                <AssignedToValue
                  formMethods={formMethods}
                  taskVar={createSubTaskVar}
                  placementEmployees={placementEmployees}
                  assignedToValue={assignedToValue}
                  setAssignedValue={data => {
                    setAssignedValue(data);
                    onChange(data);
                  }}
                  ccValues={ccEmpValues}
                  isDisabled={isCallingAPI}
                  error={isDirty && errors?.assignedTo ? true : false}
                  isTouched={isDirty}
                  helperText={isDirty && errors?.assignedTo && t('required')}
                  container={0}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            {!showccField && (
              <Button
                className={classes.ccBtn}
                disabled={isCallingAPI}
                onClick={() => {
                  setShowccField(true);
                }}
                variant="contained"
              >
                {t('notification.createTask.ccBtn')}
              </Button>
            )}
            {showccField && !externalTask && (
              <Controller
                control={control}
                name={createSubTaskVar.cc}
                render={({ ref, onChange, value, ...rest }) => (
                  <CCEmployees
                    placementEmployees={placementEmployees}
                    ccEmpValues={ccEmpValues}
                    setCCEmpvalues={data => {
                      setCCEmpvalues(data);
                      onChange(data);
                    }}
                    assignedToValue={assignedToValue}
                    isDisabled={isCallingAPI}
                    formMethods={formMethods}
                    container={0}
                  />
                )}
              />
            )}
          </Grid>
          {!comment && (
            <Grid item xs={12}>
              <CommentsAttachment setAttachments={setAttachments} attachments={attachments} />
            </Grid>
          )}
        </Grid>
      </GenericDialog>
      <CoverageConfirmationModal
        openDialog={coverageData?.openDialog}
        handleDecision={setIsProvidingCoverage}
        action={coverageData?.action}
        coveredUser={coverageData?.coveredUser}
      />
      <Cancel
        openDialog={cancelModalOpen}
        handleConfirmAction={() => onCloseCall()}
        handleCancelAction={() => {
          setCancelModalOpen(false);
        }}
      />
    </FormProvider>
  );
};
