import { isNullOrUndefined } from '@microsoft/applicationinsights-core-js';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import baseAxiosInstance from 'utils/BaseApi';
import { httpSuccess } from '../serviceHelpers';
import { CacheType, cacheResponse, getCachedResponse } from 'utils/networkCache';

export class CredentialingService {
  private credentialingBase: string;
  private credentialingAttachmentBase: string;

  private cancellationToken = axios.CancelToken.source();

  private static currentInstance: CredentialingService;
  config?: AxiosRequestConfig = {};

  private constructor() {
    this.credentialingBase = globalThis?.app?.env?.REACT_APP_CREDENTIALING_SERVICE_BASE_URL;
    this.credentialingAttachmentBase = globalThis?.app?.env?.REACT_APP_CREDENTIALING_ATTACHMENT_SERVICE_BASE_URL;
  }

  static createInstance(): CredentialingService {
    const activeInstance = new CredentialingService();
    // activeInstance.cancellationToken = axios.CancelToken.source();
    // if (activeInstance.config) activeInstance.config.cancelToken = activeInstance.cancellationToken.token;
    return activeInstance;
  }

  static getInstance(): CredentialingService {
    if (!this.currentInstance) this.currentInstance = this.createInstance();
    return this.currentInstance;
  }

  static cancelActiveRequests() {
    this.currentInstance.cancellationToken.cancel('RequestCancellation');
    // this.currentInstance = null;
  }

  cancelRequests() {
    this.cancellationToken.cancel('RequestCancellation');
    return CredentialingService.getInstance();
  }

  cancelAndRevokeInstance() {
    this.cancellationToken.cancel('RequestCancellation');
    this.cancellationToken = axios.CancelToken.source();
    if (this.config) this.config.cancelToken = this.cancellationToken.token;
  }

  getCredentialLookups = (category?: string) =>
    baseAxiosInstance.get(`${this.credentialingBase}/lookups${category ? `/${category}` : ''}`);

  getPassportChecklistByPid = (placementId: number) =>
    baseAxiosInstance.get(`${this.credentialingBase}/${`compliance/placement`}/${placementId}/${`level3`}`);

  getCandidateCredentials = (travelerId: number) => 
    baseAxiosInstance
      .get(`${this.credentialingBase}/bycandidateid/${travelerId}`)
      .catch((error: AxiosError) => error.response);
      
  getRequirementTypes = async (cacheControl: boolean = true) => {
    try {
      const url = `${this.credentialingBase}/requirementtypes`;
      if (cacheControl) {
        const cachedData = await getCachedResponse(CacheType.searchOptions, url, {
          returnDataOnly: true,
        });
        if (!isNullOrUndefined(cachedData)) {
          return cachedData;
        }
      }
      const response = await baseAxiosInstance.get(url);
      if (cacheControl && httpSuccess(response.status)) {
        cacheResponse(CacheType.searchOptions, url, response);
      }
      return response;
    } catch (error) {
      return err => err.response;
    }
  };      
  
  getCredentialLookupTypes = async (category?: string, cacheControl: boolean = true) => {
    try {
      const url = `${this.credentialingBase}/lookups${category ? `/${category}` : ''}`;
      if (cacheControl) {
        const cachedData = await getCachedResponse(CacheType.searchOptions, url, {
          returnDataOnly: true,
        });
        if (!isNullOrUndefined(cachedData)) {
          return cachedData;
        }
      }
      const response = await baseAxiosInstance.get(url);
      if (cacheControl && httpSuccess(response.status)) {
        cacheResponse(CacheType.searchOptions, url, response);
      }
      return response;
    } catch (error) {
      return err => err.response;
    }
  };      
}
