/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { globalSliceKey, globalReducer, globalActions } from './Global.redux';
import {
  selectCatastrophicDialog,
  selectDetailsPageDrawer,
  selectFailedServices,
  selectNewAppVersionAvailable,
} from './Global.selectors';
import { useSessionStorage } from 'utils/customHooks/sessionStorage/useSessionStorage';
import { confettiDetailsReducer, confettiDetailsSliceKey } from 'app/components/Confetti/store/Confetti.redux';
import { confettiDetailsSaga } from 'app/components/Confetti/store/Confetti.saga';
import { ConfettiView } from 'app/components/Confetti/ConfettiView';
import { userSliceKey, userReducer } from 'oidc/user.redux';
import { userSaga } from 'oidc/user.saga';
import { userDevicePreferenceKey, userDevicePreferenceReducer } from 'oidc/UserDevicePreference/userPreference.redux';
import { userDevicePreferenceSaga } from 'oidc/UserDevicePreference/userPreference.saga';
import { useTranslationOverride } from 'locales/useTranslationOverride';
import { globalSaga } from './Global.saga';
import { useApplicationVersionChecker } from 'utils/customHooks/useApplicationVersionChecker';
import { GenericDialog, IGenericDialogButton } from '@AMIEWEB/Alerts/GenericDialog';
import { useTranslation } from 'react-i18next';
import { OptimizelyContext, useDecision } from '@optimizely/react-sdk';
import { ff_global_new_app_version, ff_task_api_getcoveragelist } from 'app/constants/FeatureFlags/Global/keys';
import { useEmployeeNameTracking } from 'app-insights/appInsightsTracking';
import { useApiCacheBuster } from 'utils/customHooks/useApiCacheBuster';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { selectUser } from 'oidc/user.selectors';
import { useAppUsageTracker } from 'utils/customHooks/useAppUsageTracker';
import { makeStyles } from 'tss-react/mui';
import { coverageActions } from 'store/redux-store/user-coverage/async-actions';
import { coverageReducer, coverageSliceKey } from 'store/redux-store/user-coverage/slice';
import { coverageSaga } from 'store/redux-store/user-coverage/saga';
import { ff_notifications_coverage_sms } from 'app/constants/FeatureFlags/EmployeeCoverage/keys';
import {
  userManagementSliceKey,
  userManagementReducer,
} from '@AMIEWEB/Settings/UserManagement/store/userManagement.redux';
import { userSearchListSaga } from '@AMIEWEB/Settings/UserManagement/store/userManagement.saga';
import { taskDetailsActions } from '@AMIEWEB/Tasks/store/Tasks.redux';
import { useLocation } from 'react-router-dom';
import { FailedServices } from './types';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import GlobalSnackBar from '@AMIEWEB/Common/Snackbar/GlobalSnackBar';
import { GenericSnackbar } from '@AMIEWEB/Common/Snackbar/GenericSnackbar';
import { ff_notifications_enable_shareddesk_communication } from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';

const useStyles = makeStyles()(theme => ({
  snackbarContent: {
    fontSize: '14px',
  },
  snackbarAction: {
    fontWeight: 'bold',
    color: theme.palette.system.primary,
    cursor: 'pointer',
  },
}));

export const GlobalDisplay = props => {
  useTranslationOverride();
  const { classes } = useStyles();
  const { t } = useTranslation();
  useInjectReducer({ key: globalSliceKey, reducer: globalReducer });
  useInjectSaga({ key: globalSliceKey, saga: globalSaga });
  const dispatch = useDispatch();
  const location = useLocation();
  const detailsPageDrawer = useSelector(selectDetailsPageDrawer);
  const openProfileDrawer = detailsPageDrawer?.open;
  const [checkCoverage] = useDecision(ff_notifications_coverage_sms);

  useSessionStorage();
  useInjectReducer({ key: confettiDetailsSliceKey, reducer: confettiDetailsReducer });
  useInjectSaga({ key: confettiDetailsSliceKey, saga: confettiDetailsSaga });
  useInjectReducer({ key: userSliceKey, reducer: userReducer });
  useInjectSaga({ key: userSliceKey, saga: userSaga });
  useInjectReducer({ key: userDevicePreferenceKey, reducer: userDevicePreferenceReducer });
  useInjectSaga({ key: userDevicePreferenceKey, saga: userDevicePreferenceSaga });
  useInjectReducer({ key: coverageSliceKey, reducer: coverageReducer });
  useInjectSaga({ key: coverageSliceKey, saga: coverageSaga });
  useInjectReducer({ key: userManagementSliceKey, reducer: userManagementReducer });
  useInjectSaga({ key: userManagementSliceKey, saga: userSearchListSaga });

  // Set employee name in session storage for AI tracking
  useEmployeeNameTracking();
  //AppUsage time tracker
  useAppUsageTracker();
  // API Cache Buster for responses stored in Cache Store
  useApiCacheBuster();
  // Application Version Checker
  useApplicationVersionChecker();

  const newAppVersionAvailable = useSelector(selectNewAppVersionAvailable);
  const catastrophicDialog = useSelector(selectCatastrophicDialog);
  const [newAppVersionCheckFlag] = useDecision(ff_global_new_app_version, { autoUpdate: true });
  const [getCoverageListFlag] = useDecision(ff_task_api_getcoveragelist);
  const failedServices = useSelector(selectFailedServices);
  const [sharedDeskFlag] = useDecision(ff_notifications_enable_shareddesk_communication);

  const onRefresh = () => {
    // calling failed services, i.e. on refresh
    dispatch(globalActions.closeCatastrophicDialog());
    failedServices?.map(service => {
      switch (service.type) {
        case FailedServices.SMSInbox:
          dispatch(notificationDataActions.getInboxMessages(service.payload));
          break;
        case FailedServices.CoverageInbox:
          dispatch(coverageActions.fetchCoveredInbox(service.payload));
          break;
        case FailedServices.TaskGrid:
          dispatch(taskDetailsActions.taskGridDataAction(service.payload));
          break;
        case FailedServices.NotificationLog:
          dispatch(taskDetailsActions.getNotificationLogData(service.payload));
          break;
        case FailedServices.ActivityFeed:
          dispatch(taskDetailsActions.getUnReadNotificationsCounts(service.payload));
          break;
        case FailedServices.ActivityFeed:
          dispatch(taskDetailsActions.getUnReadNotifications(service.payload));
          break;
        case FailedServices.ExternalTask:
          dispatch(taskDetailsActions.getExternalTaskDetails(service.payload));
          break;
      }
    });
  };

  React.useEffect(() => {
    // closing snackbar, i.e. route, change
    dispatch(globalActions.closeCatastrophicDialog());
    dispatch(globalActions.resetFailedServices());
  }, [location]);

  /** End snackbar */

  /** Optimizely set user */
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  const { userInfo } = useSelector(selectUser);
  const { optimizely } = React.useContext(OptimizelyContext);
  const dialogActions = [
    {
      text: 'Refresh',
      variant: 'contained',
      onClick: () => {
        window.location.reload();
      },
    },
  ] as IGenericDialogButton[];

  React.useEffect(() => {
    if (isAuthenticated && accounts?.length > 0 && userInfo && optimizely.isReady()) {
      optimizely.setUser({
        id: accounts[0]?.localAccountId,
        attributes: {
          name: accounts[0].name,
          email: accounts[0].username,
          employeeid: userInfo.employeeId,
          ...Object.entries(userInfo.roles ?? []).reduce((store, item) => {
            store[item[1].replace('.', '_').toLowerCase()] = true;
            return store;
          }, {}),
        },
      });
      if (userInfo.employeeId > 0 && getCoverageListFlag.enabled) {
        dispatch(coverageActions.getActiveCoverageProvidedByUser());
      }
    }
  }, [isAuthenticated, accounts, userInfo, optimizely]);

  React.useEffect(() => {
    const isFlagEnabled = checkCoverage.enabled || sharedDeskFlag.enabled;
    if (isFlagEnabled && userInfo.employeeId > 0) {
      dispatch(
        coverageActions.fetchCoveredInbox({
          coveringEmployeeIds: [userInfo.employeeId],
          startDate: new Date(),
          endDate: new Date(),
          pageNumber: 1,
          pageSize: 50,
          statusIds: [4],
          sharedDeskFlagEnabled: sharedDeskFlag.enabled,
          coverageFlagEnabled: checkCoverage.enabled,
        }),
      );
    }
  }, [openProfileDrawer, userInfo]);

  return (
    <>
      {props.children}
      <GenericDialog
        open={newAppVersionAvailable}
        dialogTitleProps={{
          text: t('global.newAppVersion.title'),
          closeButton: newAppVersionCheckFlag.variables.validationSettings?.['discretionaryRefresh'],
        }}
        onClose={() => {
          if (newAppVersionCheckFlag.variables.validationSettings?.['discretionaryRefresh']) {
            dispatch(globalActions.setNewAppVersionAvailable(false));
          }
        }}
        dialogContentProps={{
          content: t('global.newAppVersion.content'),
        }}
        dialogActions={dialogActions}
      />
      <GlobalSnackBar />
      <GenericSnackbar
        id={'globalDisplay'}
        snackOpen={catastrophicDialog.open ?? false}
        className={classes.snackbarContent}
        message={t('global.errorBoundary.dialogContent')}
        clickAwayProps={{ onClickAway: () => null }}
        variant={'dark'}
        severity={'error'}
        actions={[
          {
            action: onRefresh,
            className: classes.snackbarAction,
            text: t('REFRESH'),
            closeSnackbar: true,
          },
        ]}
      />
      <ConfettiView />
    </>
  );
};
