import React from 'react';
import { InlineEditableField } from 'app/components/Common/EditPage/InlineEditableField';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomTextField } from '../CustomComponents/CustomTextField';
import { useDispatch, useSelector } from 'react-redux';
import { selectEditStatus, selectPageAttributes } from '../store/placementPageAttributes.selector';
import { useTranslation } from 'react-i18next';
import { pageAttributesActions } from '../store/placementPageAttributes.redux';
import { editableFields } from '../../editUtils';
import { selectEditableData } from '../../../../../../store/redux-store/placement-details/selectors';
import { ActionAccessorHook } from './ActionAccessorHook';

export const TravelPartner = ({ id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { control, watch } = useFormContext();
  const travelPartner = watch('travelPartner');
  const { travelPartner: status } = useSelector(selectEditStatus);
  const { editOrderStack } = useSelector(selectPageAttributes);
  const { travelPartner: defaultValue } = useSelector(selectEditableData);

  const undoChecker = () => !editOrderStack.some(key => key === 'travelPartner');

  return (
    <Controller
      control={control}
      name="travelPartner"
      render={({ ref, onChange, ...rest }) => (
        <>
          {/** Component is used to access inaccessible methods from react-hook-form Controller
           * to invoke actions - say undo action */}
          <ActionAccessorHook
            actionChecker={undoChecker}
            accessAction={() => onChange(defaultValue)}
            hookDependancies={[editOrderStack]}
          />
          <InlineEditableField
            {...rest}
            id={id}
            label={travelPartner}
            inputProps={{ value: rest.value, minWidth: 80, renderedFromInlineEditor: true }}
            Control={CustomTextField}
            onChange={newValue => {
              onChange(newValue);
              dispatch(pageAttributesActions.pushToEditOrderStack(editableFields.travelPartner));
            }}
            fullWidth={false}
            tooltipText={status.disabled ? t('placement.profile.edit.disableText') : ''}
            setCurrentFocus={state => dispatch(pageAttributesActions.setCurrentFocus(state !== null ? id : null))}
            {...status}
          />
        </>
      )}
    />
  );
};
