/* eslint-disable i18next/no-literal-string */
import React, { useCallback, useEffect, useState } from 'react';
import { Divider, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { convertToFormat } from 'app/helpers/dateHelper';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ActivePlacementSelection } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { PlacementStatus } from 'app/models/Credentialing/CredentialingPlacement';
import moment from 'moment';
import { MyPlacementsGrid } from './MyPlacementsGrid';
import { ICustomHeader } from 'app/models/Grid';
import { PlacementContactsDrawer } from '../../../../../../Placement/PlacementDetails/PlacementTabPanel/CredentialingTab/PlacementContacts/PlacementContactsDrawer';
import { createPlacementContacts } from '../../../../../../Placement/PlacementDetails/PlacementTabPanel/CredentialingTab/PlacementContacts/placementContactsHelper';

const useStyles = makeStyles()(theme => ({
  grid: {
    paddingTop: '20px',
    paddingBottom: '10px',
  },
  hoverHeader: {
    fontWeight: 'bold',
    color: '#52429A',
    paddingBottom: '3px',
  },
  details: {
    color: '#52429A',
    fontWeight: 'bold',
  },
}));

const formatDate = (date: string | null, format?: string) => {
  let defaultFormat = 'MM/DD/yyyy';
  if (format === 'timeStamp') defaultFormat = 'MM/DD/yyyy h:mm:ss a';
  const formattedDate = convertToFormat(date, defaultFormat);
  return formattedDate === null ? '' : formattedDate;
};

interface MyPlacementContainerProps {
  searchString?: any;
  onPlacementSelected: (id) => void;
}

export const MyPlacementContainer: React.FC<MyPlacementContainerProps> = props => {
  const { onPlacementSelected } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const activePlacements = useSelector(ActivePlacementSelection);
  const [open, setOpen] = useState(false);
  const [currentPlacements, setCurrentPlacements] = useState<any[]>([]);
  const [futurePlacements, setFuturePlacements] = useState<any[]>([]);
  const [pcData, setPCData] = useState<any[]>([]);

  const onCurrentProfileClick = rowKey => {
    setOpen(true);
    setPCData(
      createPlacementContacts(
        currentPlacements.find(item => parseInt(item.rowID) === parseInt(rowKey)),
        t,
      ),
    );
  };
  const onFutureProfileClick = rowKey => {
    setOpen(true);
    setPCData(
      createPlacementContacts(
        futurePlacements.find(item => item.rowID === rowKey),
        t,
      ),
    );
  };

  const onClose = () => {
    setOpen(false);
  };

  const columnsData: ICustomHeader[] = [
    {
      title: 'placementStatus',
      value: 'Placement Status',
    },
    {
      title: 'placementID',
      value: 'Placement ID',
      clickable: true,
      navigationIds: ['placementID'],
      navigationExp: '/placement/{0}',
    },
    { title: 'skillSet', value: 'Discipline/Speciality' },
    { title: 'facilityName', value: 'Client Name' },
    {
      title: 'facilityID',
      value: 'Client ID ',
      hasHoverView: true,
    },
    { title: 'startDate', value: 'Start Date', sortByField: 'startDateNoFormat', sortable: true, defaultSort: true },
    { title: 'endDate', value: 'End Date', sortByField: 'endDateNoFormat', sortable: true },
    { title: 'brand', value: 'Brand' },
    { title: 'vms', value: 'VMS' },
  ];

  const getCurrentPlacements = useCallback(() => {
    const rows: any[] = [];
    if (activePlacements) {
      const currentFound = activePlacements
        ?.filter(item => item.placementStatus.toLowerCase() === PlacementStatus.OnAssignment.toLowerCase())
        .sort((a, b) => (moment.utc(a.startDate) as any) - (moment.utc(b.startDate) as any))
        .slice(0, 1);

      if (currentFound.length > 0) {
        setCurrentPlacements(commonResponseMapper(currentFound, rows));
      } else {
        setCurrentPlacements([]);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePlacements]);

  const getFuturePlacements = useCallback(() => {
    const rows: any[] = [];
    if (activePlacements) {
      const futureFound = activePlacements
        ?.filter(
          item =>
            item.placementStatus.toLowerCase() === PlacementStatus.TravelerAccepts.toLowerCase() ||
            item.placementStatus.toLowerCase() === PlacementStatus.FacilityConfirms.toLowerCase() ||
            item.placementStatus.toLowerCase() === PlacementStatus.Booked.toLowerCase() ||
            item.placementStatus.toLowerCase() === PlacementStatus.BookingApproved.toLowerCase() ||
            item.placementStatus.toLowerCase() === PlacementStatus.WaitingGoodToGo.toLowerCase() ||
            item.placementStatus.toLowerCase() === PlacementStatus.PostponementWarning.toLowerCase() ||
            item.placementStatus.toLowerCase() === PlacementStatus.GoodToGo.toLowerCase(),
        )
        .sort((a, b) => (moment.utc(a.startDate) as any) - (moment.utc(b.startDate) as any))
        .slice(0, 3);

      if (futureFound.length > 0) {
        setFuturePlacements(commonResponseMapper(futureFound, rows));
      } else {
        setFuturePlacements([]);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePlacements]);

  const hoverData = item => {
    return [
      <Grid container direction="column" justifyContent="flex-end">
        <Grid item className={classes.hoverHeader}>
          {'Client Details '}
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        ,
        <Grid item>
          {`Client ID :`} <span className={classes.details}>{parseInt(item.facilityId)}</span>
        </Grid>
        ,
        <Grid item>
          {`Client Name :`} <span className={classes.details}>{item.facilityName}</span>
        </Grid>
        ,
        <Grid item>
          {`Client Address :`}
          <span className={classes.details}>
            {item.facilityAddress.address1 || ''} {item.facilityAddress.address2 || ''}
            {item.facilityAddress.city || ''} {item.facilityAddress.country || ''}
            {item.facilityAddress.stateProvinceId || ''} {item.facilityAddress.zip || ''}
          </span>
        </Grid>
        ,
      </Grid>,
    ];
  };

  const commonResponseMapper = (response, arrayStorage) => {
    // eslint-disable-next-line array-callback-return
    response.map(item => {
      const checkData = {
        rowID: item.placementId,
        placementStatus: item.placementStatus || 'N/A',
        placementID: parseInt(item.placementId) || 'N/A',
        facilityName: item.facilityName || 'N/A',
        facilityID: parseInt(item.facilityId) || 'N/A',
        facilityIDHoverViewData: hoverData(item),
        skillSet: `${item.discipline || 'N/A'} / ${item.specialty || 'N/A'}`,
        startDate: `${formatDate(item.startDate)}` || 'N/A',
        startDateNoFormat: `${item.startDate}`,
        endDate: `${formatDate(item.endDate)}` || 'N/A',
        endDateNoFormat: `${item.endDate}`,
        brand: item.brand || 'N/A',
        vms: item.vmsName || 'N/A',
        credentialAnalystInfo: item.credentialAnalystInfo || null,
        recruiterInfo: item.recruiterInfo || null,
        accountManagerInfo: item.accountManagerInfo || null,
        ccsInfo: item.ccsInfo || null,
        clientManagerInfo: item.clientManagerInfo || null,
      };
      arrayStorage.push(checkData);
    });
    return arrayStorage;
  };

  const _onPlacementSelected = data => {
    onPlacementSelected(data.data.placementID);
  };

  useEffect(() => {
    getCurrentPlacements();
    getFuturePlacements();
  }, [getCurrentPlacements, getFuturePlacements, activePlacements]);

  return (
    <React.Fragment>
      {currentPlacements && futurePlacements && (
        <Grid container direction="column" justifyContent="flex-end">
          <Grid item xs={12} className={classes.grid}>
            <MyPlacementsGrid
              columns={columnsData}
              rows={currentPlacements}
              title={t('CURRENT PLACEMENT')}
              onProfileClick={onCurrentProfileClick}
              onPlacementSelected={_onPlacementSelected}
              hideRowCount
            />
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            <MyPlacementsGrid
              columns={columnsData}
              rows={futurePlacements}
              title={t('FUTURE PLACEMENTS')}
              onProfileClick={onFutureProfileClick}
              onPlacementSelected={_onPlacementSelected}
            />
          </Grid>
        </Grid>
      )}
      {(!currentPlacements || !futurePlacements) && <div className="center">{t('global.genericError')}</div>}
      <PlacementContactsDrawer open={open} onCloseDrawer={onClose} contacts={pcData}></PlacementContactsDrawer>
    </React.Fragment>
  );
};
