import { Box } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { trackEvent } from 'app-insights/appInsightsTracking';
import React, { useEffect, useState } from 'react';
import { CustomTooltip } from '../Tooltips';

interface Props {
  tileIcon: React.ReactNode | null;
  title: string | any;
  data: any;
  dataUnit?: string | null;
  clickAble?: boolean;
  OnClick?: ((data?) => void) | null;
  showTooltip?: boolean | null;
  tooltipText?: string | JSX.Element | null;
  showLoader?: boolean | null;
  marginRight?: string | null;
  width?: string | null;
  showError?: boolean;
  analytics?: {
    groupTitle: string;
  };
  background?: any;
  fontColor?: string;
}

export const GlanceTile = (props: Props) => {
  const tooltipText = props.tooltipText || ' ';

  return (
    <>
      {props.showTooltip && tooltipText ? (
        <CustomTooltip arrow standardMargin placement={'bottom'} tooltipContent={tooltipText} isStandardContent={true}>
          <Tile {...props} />
        </CustomTooltip>
      ) : (
        <Tile {...props} />
      )}
    </>
  );
};

const useStyles = makeStyles<{ data: string }>()((theme, props) => ({
  dataMeasure: {
    //can take a maximum of 11 at 24px and 13 at 20px
    //will be modified to ... in future for any further increase in text length
    fontSize: props.data.length < 11 ? 24 : 20,
    textAlign: 'left',
  },
  dataUnit: {
    fontSize: 14,
    textAlign: 'left',
    paddingLeft: 5,
  },
  errorText: {
    color: '#CC0000',
    fontSize: 16,
    fontWeight: 500,
  },
}));

const Tile = props => {
  const { background, fontColor } = props;
  const { classes } = useStyles({
    data: props.data,
  });
  const [data, setData] = useState(props.data);
  const [dataUnit, setDataUnit] = useState(props.dataUnit);
  const handleClick = () => {
    if (props.clickAble && props.OnClick) {
      props.OnClick(props.data);
      if (props.analytics)
        trackEvent({ type: 'click', name: `${props.analytics.groupTitle} KPI`, properties: { kpi: props.title } });
    }
  };

  useEffect(() => {
    setData(props.data);
    setDataUnit(props.dataUnit);
  }, [props]);

  return (
    <Box
      onClick={handleClick}
      key={props.key}
      sx={theme => ({
        display: 'flex',
        border: `1px solid ${theme.palette.framework.system.lightGray}`,
        borderRadius: '3px',
        width: '100%',
        cursor: props.clickAble ? 'pointer' : 'auto',
        height: '56px',
      })}
    >
      <Box
        sx={theme => ({
          background: background ? background : theme.palette.framework.system.whisper,
          fontSize: '17px',
          color: fontColor ?? theme.palette.framework.system.charcoal,
          fontWeight: 500,
          padding: '12px 10px',
          display: 'flex',
          alignItems: 'center',
        })}
      >
        {`${data} ${dataUnit ?? ''}`}
      </Box>
      <Box
        sx={{
          fontSize: '12px !important',
          padding: '9px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {props.title}
      </Box>
    </Box>
  );
};
