import { DrawerFitted } from 'app/components/Common/Drawer/Drawer';
import { CandidateDrawerPreviewer } from 'app/components/GlobalSearch/Candidate/CandidateDrawerPreviewer';
import { FacilityPreviewDrawerViewer } from 'app/components/GlobalSearch/Facility/FacilityPreviewDrawerViewer';
import { OrderPreviewDrawerViewer } from 'app/components/GlobalSearch/Order/OrderPreviewDrawerViewer';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { globalActions } from './Global.redux';
import { selectCandidateDrawer, selectFacilityDrawer, selectOrderDrawer } from './Global.selectors';
import { useTheme } from 'amn-ui-core';

interface RecentHistoryProps {
  children?: any;
}

export const RecentHistory: React.FC<RecentHistoryProps> = props => {
  const dispatch = useDispatch();
  const theme= useTheme();
  const candidateDrawer = useSelector(selectCandidateDrawer);
  const facilityDrawer = useSelector(selectFacilityDrawer);
  const orderDrawer = useSelector(selectOrderDrawer);
  return (
    <div>
      {props.children}
      {(orderDrawer?.open || candidateDrawer?.open || facilityDrawer?.open) && (
        <DrawerFitted 
          onDrawerClose={() => {
            dispatch(globalActions.resetCandidateDrawerData());
            dispatch(globalActions.resetOrderDrawerData());
            dispatch(globalActions.resetFacilityDrawerData());
          }}
          
          width={orderDrawer?.open ? 450 : 400}
          top={0}
          backgroundColor={theme.palette.framework.system.whisper}
          open={orderDrawer?.open || candidateDrawer?.open || facilityDrawer?.open || false}
        >
          {orderDrawer?.open && <OrderPreviewDrawerViewer orderId={orderDrawer.order.orderId} />}
          {facilityDrawer?.open && <FacilityPreviewDrawerViewer facilityId={facilityDrawer.facility.facilityId} />}
          {candidateDrawer?.open && (
            <CandidateDrawerPreviewer
              travelerId={candidateDrawer.candidate.candidateId}
              brandId={candidateDrawer.candidate.brandId}
              isSearchGrid={false}
              isWorkDesk={false}
            />
          )}
        </DrawerFitted>
      )}
    </div>
  );
};
