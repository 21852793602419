import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './PlacementReminders.redux';

const nullCount = {
  all: 0,
  scheduled: 0,
  today: 0,
};

const selectDomain = (state: RootState) => state?.placementReminderTotals || initialState;

export const selectCounts = createSelector([selectDomain], data => data.counts || nullCount);

export const selectRemindersGridData = createSelector([selectDomain], data => data.filteredData || data.flattenedData);

export const selectRemindersSourceData = createSelector([selectDomain], data => data.flattenedData);

export const selectFilterSpecs = createSelector([selectDomain], data => data.filterSpecs);
export const selectFilterChipSelected = createSelector([selectDomain], data => data.filterChipSelected);
export const selectPaging = createSelector([selectDomain], data => data.paging);
export const selectPlacementDeskChips = createSelector([selectDomain], data => data.chips);
export const selectStateID = createSelector([selectDomain], data => data.stateID);

export const selectRemindersLoading = createSelector([selectDomain], credentialingState => credentialingState.loading);
