/* eslint-disable i18next/no-literal-string */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Typography,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { globalActions } from 'app/ApplicationRoot/Global.redux';
import {
  emailSignatureExpanded,
  selectEmailSignature,
  selectNotificationPreference,
} from 'app/ApplicationRoot/Global.selectors';
import LogoutIcon from 'app/assets/images/Logout-24px.svg';
import { DrawerFitted } from 'app/components/Common/Drawer/Drawer';
import EmailSignature from 'app/components/Notification/MultiChannel/Email/EmailSignature';
import UserDeviceSelection from 'app/components/Notification/MultiChannel/Voice/UserDeviceSelection';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { selectUser } from 'oidc/user.selectors';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import { removeOverridenUserID, userInitials } from 'oidc/userHelper';
import { msalInstance } from 'oidc/userManager';
import { userRolesDisplayValue } from 'oidc/userRoles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import Switch from '@mui/material/Switch';
import _ from 'lodash';
import NotificationPreference from 'app/components/Notification/Common/NotificationPreference';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { userActions } from 'oidc/user.redux';
import { ActiveCoverage } from '@AMIEWEB/Notification/CoveragePreferences/ActiveCoverage';
import { RequestCoverage } from '@AMIEWEB/Notification/CoveragePreferences/RequestCoverage';
import { RequestedCoverage } from '@AMIEWEB/Notification/CoveragePreferences/RequestedCoverage';
import LaunchIcon from '@mui/icons-material/Launch';
import { ff_task_ui_coverageaccountsettings } from 'app/constants/FeatureFlags/Global/keys';
import { useDecision } from '@optimizely/react-sdk';
import { selectActiveCoverage } from 'store/redux-store/user-coverage/selectors';
import { PreferenceCosmosKeys } from 'oidc/UserDevicePreference/utils';
import { SnackbarConfirmation } from '@AMIEWEB/Notification/Common/CloseDialog';

const useStyles = makeStyles()(theme => ({
  avatar: {
    color: 'white',
    backgroundColor: '#52429A',
    textTransform: 'uppercase',
    height: '60px',
    width: '60px',
    fontSize: '24px',
    margin: '0px auto',
  },
  drawerInner: {
    padding: '30px 10px',
    width: '100%',
    margin: '0px',
  },
  profileName: {
    textAlign: 'center',
    fontSize: '16px',
  },
  profileRoles: {
    fontSize: '12px',
    color: '#888888',
  },
  profileSegment: {
    width: '100%',
    textAlign: 'center',
  },
  profileAction: {
    position: 'fixed',
    bottom: '0',
    marginBottom: '1%',
  },
  buttons: {
    bottom: '60px',
    marginLeft: '95px',
  },
  logout: {
    cursor: 'pointer',
    margin: '2% 0 2% 5%',
  },
  logoutText: {
    color: '#52429A',
    marginLeft: '5px',
    fontSize: '14px',
  },
  profile: {
    overflowX: 'hidden',
    margin: '10px 5px 5px 5px',
    height: '53vh',
  },
  separator: {
    width: '290px',
  },
  title: {
    fontWeight: 500,
    fontSize: '16px',
  },
  accordion: {
    '&.MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  accordionSummary: {
    padding: '0px',
    minHeight: 50,
    '& .MuiAccordionSummary-content': {
      margin: '0px',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0px',
    },
    '& .MuiAccordionSummary-root': {
      minHeight: 50,
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 50,
    },
    '&.Mui-expanded': {
      minHeight: 50,
    },
    '& .MuiButtonBase-root': {
      minHeight: 50,
    },
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
    margin: '0px',
  },
  closeDrawer: {
    position: 'fixed',
    right: '5px',
  },
  container: {
    margin: '2%',
  },
  content: {
    justify: 'center',
    color: '#fff',
    paddingLeft: '1%',
    fontWeight: 'lighter',
  },
  toggleTitle: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#333333',
    marginTop: 7,
  },
  toggleContainer: {
    display: 'flex',
    width: '100%',
    height: 47,
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  enabled: {
    opacity: 1,
    marginTop: 7,
  },
  disabled: {
    opacity: 0.5,
    marginTop: 7,
  },
  spanTitle: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#333333',
  },
  launchIcon: {
    '&:hover': {
      color: '#41A2DF',
    },
  },
}));

export const ProfileDrawer = props => {
  const { classes } = useStyles();
  const userInfo = useSelector(selectUser);
  const { t } = useTranslation();
  const { open } = props;
  const notificationData = useSelector(notificationSelection);
  const [isSnackBarOpen, setSnackbarOpen] = useState(false);
  const dispatch = useDispatch();
  const userPreference = useSelector(selectUserPreference);
  const emailSignature = useSelector(selectEmailSignature);
  const isEmailSignatureExpanded = useSelector(emailSignatureExpanded);
  const notificationPreference = useSelector(selectNotificationPreference);
  const userDevicePreference = useSelector(selectUserPreference);
  const [expanded, setExpanded] = useState<any>(false);
  const [emailExpanded, setEmailExpanded] = useState<boolean>(false);
  const [coverageSubMenu, setCoverageSubMenu] = useState<string>(null);
  const [disableSave, setDisableSave] = useState(false);
  const forwardSmsFlag = userInfo.userInfo.forwardInboundSmsToEmail || false;
  const [checked, setChecked] = useState(forwardSmsFlag);
  const [coverageFlag] = useDecision(ff_task_ui_coverageaccountsettings);
  const activeCoverage = useSelector(selectActiveCoverage);

  const handleToggleForwardSms = () => {
    setChecked(!checked);
  };
  const handleToggleEmailSignature = e => {
    setEmailExpanded(!emailExpanded);
  };
  const toggleCoverageSubContainers = selectedContainer => {
    coverageSubMenu !== selectedContainer ? setCoverageSubMenu(selectedContainer) : setCoverageSubMenu(null);
  };
  const isEqual = (current, existing) => {
    const currentValues = _.cloneDeep(current).sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0,
    );
    const existingValues = _.cloneDeep(existing).sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0,
    );
    return _.isEqual(existingValues, currentValues);
  };

  const handleYesAction = () => {
    setSnackbarOpen(false);
    dispatch(globalActions.setProfileOpen(false));
    dispatch(globalActions.setEmailSignatureExpanded(false));
    setEmailExpanded(false);
    setChecked(forwardSmsFlag);
    setExpanded(false);
    dispatch(
      globalActions.setEmailSignature(userPreference?.emailSignature ? userPreference?.emailSignature?.value : []),
    );
    dispatch(
      globalActions.setNotificationPreference(
        userPreference?.notificationPreference ? userPreference?.notificationPreference?.value : [],
      ),
    );
    if (!notificationData.email.open && notificationData.email.minimized) {
      dispatch(
        notificationDataActions.setEmailInteraction({
          open: true,
          minimized: false,
        }),
      );
    }
  };

  useEffect(
    () => {
      setDisableSave(
        !userDevicePreference?.current?.device ||
          !userDevicePreference?.current?.line ||
          (userDevicePreference?.current?.device === userDevicePreference?.existing?.device &&
            userDevicePreference?.current?.line === userDevicePreference?.existing?.line),
      );
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [userDevicePreference],
  );

  useEffect(() => {
    const disable =
      isEqual(userPreference?.emailSignature?.value, emailSignature) &&
      isEqual(userPreference?.notificationPreference?.value, notificationPreference) &&
      forwardSmsFlag === checked;
    setDisableSave(disable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailSignature, notificationPreference, checked]);

  const logout = () => {
    removeOverridenUserID();
    msalInstance.logoutRedirect();
  };

  /* Need to be added post release 10
 
 const handleUserPreference = () => {
    dispatch(
      userDevicePreferenceActions.setUserPreference({
        ...userDevicePreference,
        default: true,
        existing: userDevicePreference.current,
      }),
    );

    const preferredUser = {
      user: {
        name: userInfo?.userInfo?.firstName + ' ' + userInfo?.userInfo?.lastName,
        email: userInfo?.userInfo?.email,
        NtUserName: userInfo?.userInfo?.ntUserName,
        userId: userInfo?.userInfo?.employeeId?.toString(),
        deviceName: userDevicePreference?.current?.device,
        lineNumber: userDevicePreference?.current?.line,
      },
    };
    saveUserDevicePreference(preferredUser)
      .then(response => handleGlobalError('Updated user preference successfully', 'success'))
      .catch(err => handleGlobalError('Failed to updated user preference', 'error'));
  }; */

  const handleSave = () => {
    dispatch(globalActions.setProfileOpen(false));
    dispatch(globalActions.setEmailSignatureExpanded(false));
    setEmailExpanded(false);
    setExpanded(false);
    dispatch(
      userDevicePreferenceActions.saveEmailSignaturePreferenceAction({
        id: PreferenceCosmosKeys.signaturePreference,
        value: emailSignature,
      }),
    );
    const preferredUser = {
      userId: userInfo?.userInfo?.employeeId?.toString(),
      deviceName: userPreference?.current?.device,
      lineNumber: userPreference?.current?.line,
    };
    const preferenceData = {
      id: 'DevicePreference',
      value: preferredUser,
    };
    dispatch(userDevicePreferenceActions.saveUserDevicePreferenceAction(preferenceData));
    if (!notificationData?.email.open && notificationData?.email.minimized) {
      dispatch(
        notificationDataActions.setEmailInteraction({
          open: true,
          minimized: false,
        }),
      );
    }
    dispatch(
      userDevicePreferenceActions.saveNotificationPreferenceAction({
        id: PreferenceCosmosKeys.notificationPreference,
        value: notificationPreference,
      }),
    );
    dispatch(
      userActions.changeUserPreference({
        userId: `${userInfo.userInfo.employeeId}`,
        preferences: {
          forwardInboundSmsToEmail: checked,
        },
      }),
    );
    dispatch(
      globalActions.setSnackBar({
        message: t('notification.profileDrawer.success'),
        severity: 'success',
      }),
    );
    setDisableSave(true);
  };

  const checkCloseChanges = event => {
    event.preventDefault();
    if (
      isEqual(userPreference?.emailSignature?.value, emailSignature) &&
      isEqual(userPreference?.notificationPreference?.value, notificationPreference) &&
      forwardSmsFlag == checked
    ) {
      props.closeDrawer(event);
      dispatch(globalActions.setProfileOpen(false));
      dispatch(globalActions.setEmailSignatureExpanded(false));
      setSnackbarOpen(false);
      setEmailExpanded(false);
      setExpanded(false);
      setCoverageSubMenu(null);
      if (!notificationData.email.open && notificationData.email.minimized) {
        dispatch(
          notificationDataActions.setEmailInteraction({
            open: true,
            minimized: false,
          }),
        );
      }
    } else {
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    setEmailExpanded(isEmailSignatureExpanded);
    isEmailSignatureExpanded && setExpanded('emailSettings');
  }, [isEmailSignatureExpanded]);

  const handleChange = (name: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    if (name === 'emailSettings') {
      !isExpanded && setEmailExpanded(isExpanded);
    }
    setExpanded(isExpanded ? name : false);
  };

  return (
    <DrawerFitted open={open} onEdited={open && checkCloseChanges} onDrawerClose={props.closeDrawer} width={440}>
      <IconButton
        color="primary"
        className={classes.closeDrawer}
        disableRipple
        onClick={open ? checkCloseChanges : undefined}
        size="small"
      >
        <CloseIcon />
      </IconButton>
      <Grid className={classes.drawerInner} spacing={1} direction="column" alignContent="center" container>
        <Grid item className={classes.profileSegment}>
          <Avatar className={classes.avatar}>{userInitials(userInfo)}</Avatar>
        </Grid>
        <Grid item className={`${classes.profileSegment} ${classes.profileName}`}>
          {`${userInfo?.userInfo?.firstName || ''} ${userInfo?.userInfo?.lastName || ''}`}
        </Grid>
        <Grid item className={`${classes.profileSegment} ${classes.profileRoles}`}>
          {`${userInfo?.userInfo?.roles?.map(role => userRolesDisplayValue(role))?.join(', ') || ''}`}
        </Grid>

        <div className={classes.profile}>
          <div>
            {/* ****************************** Coverage settings *******************************/}
            {coverageFlag.enabled && (
              <>
                <Accordion
                  className={classes.accordion}
                  expanded={expanded === 'coverage'}
                  onChange={handleChange('coverage')}
                  id={`coverage`}
                >
                  <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
                    <Typography color="primary" className={classes.title}>
                      {t('notification.profileDrawer.coverage')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    {/* ****************************** Request Coverage *******************************/}
                    <Accordion
                      className={classes.accordion}
                      expanded={coverageSubMenu === 'requestCoverage'}
                      onChange={() => toggleCoverageSubContainers('requestCoverage')}
                    >
                      <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
                        <Typography color="primary" className={classes.toggleTitle}>
                          {t('notification.profileDrawer.requestCoverage.header')}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <RequestCoverage expanded={coverageSubMenu === 'requestCoverage'} />
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                    {/* ****************************** Requested Coverage ****************************** */}
                    <Accordion
                      className={classes.accordion}
                      expanded={coverageSubMenu === 'requestedCoverage'}
                      // onChange={() => toggleCoverageSubContainers('requestedCoverage')}
                    >
                      <AccordionSummary
                        className={classes.accordionSummary}
                        expandIcon={<LaunchIcon className={classes.launchIcon} onClick={() => {}} />}
                      >
                        <Typography color="primary" className={classes.toggleTitle}>
                          {`${t('notification.profileDrawer.requestedCoverage.header')} (0)`}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <RequestedCoverage />
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                    {/* ****************************** Active Coverage ****************************** */}
                    <Accordion
                      className={classes.accordion}
                      expanded={coverageSubMenu === 'activeCoverage'}
                      onChange={() => toggleCoverageSubContainers('activeCoverage')}
                    >
                      <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
                        <Typography color="primary" className={classes.toggleTitle}>
                          {`${t('notification.profileDrawer.activeCoverage.header')} (${activeCoverage?.length})`}
                        </Typography>
                      </AccordionSummary>
                      {coverageSubMenu === 'activeCoverage' && (
                        <AccordionDetails>
                          <ActiveCoverage />
                        </AccordionDetails>
                      )}
                    </Accordion>
                  </AccordionDetails>
                </Accordion>
                <Divider />
              </>
            )}
            {/* ****************************** Banner notification settings *******************************/}
            <Accordion
              className={classes.accordion}
              expanded={expanded === 'notificationPreference'}
              onChange={handleChange('notificationPreference')}
            >
              <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
                <CustomTooltip tooltipContent={t('notification.profileDrawer.tooltip')} isInlineContent={true}>
                  <Typography color="primary" className={classes.title}>
                    {t('notification.profileDrawer.notificationPreference')}
                  </Typography>
                </CustomTooltip>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <NotificationPreference />
              </AccordionDetails>
            </Accordion>
            <Divider />
          </div>
          <div>
            {/* ****************************** Email settings *******************************/}
            <Accordion
              className={classes.accordion}
              expanded={expanded === 'emailSettings'}
              onChange={handleChange('emailSettings')}
              id={`emailSettings`}
            >
              <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
                <Typography color="primary" className={classes.title}>
                  {t('notification.profileDrawer.emailSettings')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                {/* ****************************** Email signature *******************************/}
                <Accordion className={classes.accordion} expanded={emailExpanded} onChange={handleToggleEmailSignature}>
                  <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
                    <Typography color="primary" className={classes.toggleTitle}>
                      {t('notification.profileDrawer.emailSignature')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <EmailSignature />
                  </AccordionDetails>
                </Accordion>
                {userInfo.userInfo.phoneNumber && userInfo.userInfo.phoneNumber !== '' && (
                  <>
                    {/* ****************************** SMS Forwarding *******************************/}
                    <Divider />
                    <Box className={classes.toggleContainer}>
                      <Typography
                        className={`${classes.toggleTitle}
                      ${checked ? classes.enabled : classes.disabled}`}
                      >
                        <CustomTooltip tooltipContent={'Forward inbound SMS to your email'} placement={'bottom-start'}>
                          <span className={classes.spanTitle}>{t('notification.profileDrawer.smsForwarding')}</span>
                        </CustomTooltip>
                      </Typography>
                      <Switch checked={checked} onChange={handleToggleForwardSms} id={'forwardSmsToggle'} />
                    </Box>
                  </>
                )}
              </AccordionDetails>
            </Accordion>
            <Divider />
          </div>
          <div>
            <Accordion
              className={classes.accordion}
              expanded={expanded === 'userSelection'}
              onChange={handleChange('userSelection')}
            >
              <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
                <Typography color="primary" className={classes.title}>
                  {t('notification.profileDrawer.userSelection')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <UserDeviceSelection />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>

        <Grid justifyContent="flex-start" className={classes.profileAction} container>
          <Grid className={classes.buttons} spacing={1} direction="row" style={{ padding: '1% 0' }} container>
            <Grid item style={{ marginRight: '10px' }}>
              <Button variant="contained" onClick={checkCloseChanges}>{`Cancel`}</Button>
            </Grid>
            <Grid item>
              <Button
                disabled={disableSave}
                onClick={() => handleSave()}
                variant="contained"
                color="primary"
              >{`Save`}</Button>
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <Divider className={classes.separator} />
            <Box className={classes.logout} onClick={logout}>
              <img src={LogoutIcon} alt="Logout" />
              <span className={classes.logoutText}>Log out</span>
            </Box>
          </Grid>
        </Grid>

        {isSnackBarOpen && <SnackbarConfirmation yesAction={handleYesAction} noAction={() => setSnackbarOpen(false)}/>}
      </Grid>
    </DrawerFitted>
  );
};
