import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Box, SvgIcon, SvgIconProps, Checkbox } from 'amn-ui-core';
import _ from 'lodash';
import { deepEqual } from 'utils/common/comparison';
import { injectItem } from 'app/components/Common/TreeView/TreeViewHelpers';
import { ExpandLessIcon } from 'app/components/Common/Expandable/Expandable';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import { makeStyles } from 'tss-react/mui';

export function instanceOfTreeView(object: any): object is ITreeView {
  return object && typeof object === 'object' && 'name' in object && 'children' in object;
}

function CloseSquare(props: SvgIconProps) {
  return (
    <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

const useStyles = makeStyles()(theme => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
  },
  border: {
    borderLeft: `1px dashed rgba(0, 0, 0, 0.4)`,
  },
  checkbox: {
    padding: '0px 5px 0px 0px',
  },
  indeterminate: {
    color: theme.palette.secondary.main,
  },
  treeItemSize: {
    textAlign:'left',
    margin: '5px 0px',
  },
  disableCheck: {
    pointerEvents: 'none',
    cursor: 'default',
  },
  content: {
    flexDirection: 'row-reverse',
  },
}));

export interface ITreeView {
  name: string;
  value: any;
  checked?: boolean;
  children?: ITreeView[];
}

export const TaskTreeView = ({
  options,
  defaultEndIcon = 'default',
  renderLabel,
  valueKey = 'value',
  nameKey = 'name',
  levelTrace = false,
  checkbox = true,
  size = 'small',
  value = [],
  onChange,
  defaultExpanded = ['root'],
  isAlternateDisabled = false,
}: {
  options?: ITreeView[];
  defaultEndIcon?: React.ReactNode | 'default' | 'close';
  renderLabel?: (node: any) => React.ReactNode | undefined;
  /** the object key of the value for each element */
  valueKey?: 'value' | string;
  /** the object key of the name for each element */
  nameKey?: 'name' | string;
  /** add border to tree level to easily follow which level you're looking at */
  levelTrace?: boolean;
  /** adds a checkbox in front of the label */
  checkbox?: boolean;
  size?: 'small' | 'large';
  value?: { [key: string]: any }[];
  onChange?: (newValue: any) => void;
  /** nodes that are expanded by default. NodeId is "valueKey-nameKey" */
  defaultExpanded?: string[];
  isAlternateDisabled?: boolean;
}) => {
  const { classes, cx } = useStyles();
  const [sel, setSel] = React.useState<any>(value);
  const [checkedItems, setCheckedItems] = React.useState<{ [key: string]: any }[]>([]);
  const [expandedNodes, setExpandedNodes] = React.useState<any[]>([]);
  const [disableParent, setDisableParent] = React.useState<number>(0);

  React.useEffect(() => {
    if (!deepEqual(value, sel)) {
      setSel(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  React.useEffect(() => {
    const selectedParent = value?.map(a => a.value)[0];
    if (selectedParent && isAlternateDisabled) {
      if (selectedParent === 1) {
        setDisableParent(2);
      } else if (selectedParent === 2) {
        setDisableParent(1);
      }
    } else {
      setDisableParent(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  React.useEffect(() => {
    setCheckedItems(sel);
  }, [sel]);

  type childrenChecked = 'full' | 'partial' | undefined;

  const defaultRenderLabel = (node: any) => (
    <Grid container spacing={1}>
      <Grid item>{node[nameKey]}</Grid>
    </Grid>
  );

  const checkItemHandler = (node, parentIdList: any[], checked: boolean) => event => {
    event.target.checked = !checked;
    const injectitemInList = injectItem(
      _.cloneDeep(checkedItems),
      [...parentIdList, node],
      node,
      checked,
      valueKey,
      nameKey,
    );
    setCheckedItems(injectitemInList);
    onChange?.(injectitemInList);
  };

  const areChildrenChecked = (childrenChecked: childrenChecked, node, path): childrenChecked => {
    if (Array.isArray(path.children) && path.children.length > 0) {
      let count = 0;
      path.children.forEach(item => {
        const nodeIndex = node.children.map(n => n[valueKey]).indexOf(item[valueKey]) ?? -1;
        let tmpChildrenChecked: childrenChecked = undefined;
        if (nodeIndex !== -1) {
          tmpChildrenChecked = areChildrenChecked(childrenChecked, node.children[nodeIndex], item);
          if (childrenChecked === 'partial' || tmpChildrenChecked === 'partial') childrenChecked = 'partial';
          else childrenChecked = tmpChildrenChecked;
        }
        if (tmpChildrenChecked === 'full') count++;
      });
      if (count === (node.children?.length || 0) && (!childrenChecked || childrenChecked === 'full'))
        childrenChecked = 'full';
      else if (count > 0) childrenChecked = 'partial';
    } else if (node[valueKey] === path[valueKey]) childrenChecked = 'full';
    return childrenChecked;
  };

  const findPath = (path, parentList, node) => {
    if (parentList[valueKey] === node[valueKey] && parentList[nameKey] === node[nameKey]) path = parentList;
    else if (Array.isArray(parentList.children) && parentList.children.length > 0) {
      parentList.children.forEach(item => {
        path = findPath(path, item, node);
      });
    }
    return path;
  };

  const isChecked = (node, parentIdList, indeterminate): boolean => {
    let foundNode: any = undefined;
    if (parentIdList.length === 0) {
      const foundIndex = checkedItems.map(item => item[valueKey]).indexOf(node[valueKey]);
      if (foundIndex !== -1) foundNode = checkedItems[foundIndex];
    } else {
      const foundIndex2 = checkedItems.map(item => item[valueKey]).indexOf(parentIdList[0][valueKey]);
      if (foundIndex2 !== -1) foundNode = checkedItems[foundIndex2];
    }

    if (foundNode) {
      const p = findPath(null, foundNode, node);
      if (p) {
        const childrenCk = areChildrenChecked(undefined, node, p);
        return indeterminate ? childrenCk === 'partial' : childrenCk === 'full';
      }
    }
    return false;
  };

  const _renderLabel = (node, parentIdList: any[]) => {
    const isDisabled =
      node?.value === disableParent || (parentIdList?.length > 0 && parentIdList[0]?.value === disableParent);
    return (
      <Box
        className={cx({
          [classes.treeItemSize]: size === 'large',
          [classes.disableCheck]: isDisabled,
        })}
        onClick={checkbox ? checkItemHandler(node, parentIdList, isChecked(node, parentIdList, false)) : undefined}
      >
        <Grid wrap="nowrap" container>
          {checkbox && (
            <Grid item alignItems="center" alignContent="center">
              <Checkbox
                key={`${node[valueKey]}-${Math.random()}`}
                checked={isChecked(node, parentIdList, false)}
                indeterminate={isChecked(node, parentIdList, true)}
                classes={{ root: classes.checkbox, indeterminate: classes.indeterminate }}
                size="small"
                disabled={isDisabled}
              />
            </Grid>
          )}
          <Grid item>{renderLabel ? renderLabel(node) : defaultRenderLabel(node)}</Grid>
        </Grid>
      </Box>
    );
  };

  const renderTree = (nodes: any, parentIdList: any[]) => (
    <TreeItem
      classes={{
        content: classes.content,
        group: cx(classes.group, { [classes.border]: levelTrace }),
        iconContainer: classes.iconContainer,
      }}
      key={nodes[valueKey]}
      nodeId={`${nodes[valueKey]}-${nodes[nameKey]}`}
      label={_renderLabel(nodes, parentIdList)}
      // onFocus={onLabelClick(nodes)}
    >
      {Array.isArray(nodes.children) ? nodes.children.map(node => renderTree(node, [...parentIdList, nodes])) : null}
    </TreeItem>
  );

  const onNodeSelect = (event, nodeIds) => {};

  const onNodeToggle = (event, nodeIds) => {
    setExpandedNodes(nodeIds);
  };

  return (
    <React.Fragment>
      <TreeView
        // className={classes.root}
        defaultExpanded={defaultExpanded ? [...defaultExpanded, 'root'] : defaultExpanded}
        defaultCollapseIcon={<ExpandLessIcon />}
        defaultExpandIcon={<ExpandMoreIcon />}
        defaultEndIcon={
          defaultEndIcon === 'close' ? <CloseSquare /> : defaultEndIcon === 'default' ? undefined : defaultEndIcon
        }
        onNodeSelect={onNodeSelect}
        onNodeToggle={onNodeToggle}
        disableSelection
      >
        {Array.isArray(options) ? options.map(node => renderTree(node, [])) : null}
      </TreeView>
    </React.Fragment>
  );
};
