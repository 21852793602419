import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutline from 'app/assets/images/CandidateProfile/error_outline-24px.svg';
import { Row, RowHead, useRowSectionStyles } from './RowSectionV2.styles';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { RowContainer } from '@AMIEWEB/Candidate/Common/RowContainer';

export enum IconType {
  GreenChecked,
  OrangeAlert,
  BlueTick,
  RedError,
}

const getIcon = (type: IconType) => {
  switch (type) {
    case IconType.GreenChecked:
      return <CheckCircleIcon style={{ color: 'Green' }} />;
    case IconType.OrangeAlert:
      return <img src={ErrorOutline} alt="Error" />;
    default:
      return <></>;
  }
};

export interface IRowSectionV2Props {
  header: string;
  iconType: IconType;
}
export const RowSectionV2: React.FC<IRowSectionV2Props> = props => {
  const { classes } = useRowSectionStyles();
  return (
    <LayoutGridItem style={{ width: '100%' }} sx={{ paddingLeft: '3px' }}>
      <RowContainer>
        <Row>
          <div className={classes.normalText}>
            <RowHead style={{ wordBreak: 'keep-all', whiteSpace: 'normal' }}>
              <LayoutGrid direction="row" wrap="nowrap" spacing={0} alignItems="center">
                <LayoutGridItem className={`${classes.inlineBoxEditMainCheckItem}`}>
                  {getIcon(props.iconType)}
                </LayoutGridItem>
                <LayoutGridItem>
                  <div className={classes.inlineBoxEditMainLblItem}>
                    <span>{props.header}</span>
                  </div>
                </LayoutGridItem>
              </LayoutGrid>
            </RowHead>
          </div>
        </Row>
      </RowContainer>
      {props.children}
    </LayoutGridItem>
  );
};
