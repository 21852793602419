/* eslint-disable tss-unused-classes/unused-classes */
import { Divider, Grid, Paper, FormControlLabel, IconButton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import styledComponent from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from 'amn-ui-core';

export const EditText: any = makeStyles()(() => ({
  textSize: {
    fontSize: 12,
    backgroundColor: '#f5f5f5',
    padding: '0px !important',
  },
  editBackground: {
    '& .MuiInputBase-multiline': {
      padding: 0,
    },
  },
}));

export const OrderBody = styledComponent.div`
  background-color: #f4f5f7;
  color: #333333;
`;
export const OrderTitle = styledComponent.div`
  padding: 10px 0px;
  font-size: 20px;
  color: #555555;
  font-family; Roboto,Regular;
`;

export const OrderScrollable = styledComponent.div`
  height: 93vh;
  overflow-y: auto;
  overflow-x: hidden;
`;
export const CustomPaper = styledComponent(Paper)`
  min-height: 100%;
  padding: 10px;
  border: 1px solid #ececec;
  border-radius: 3px;
  height: 100%;
`;
export const CustomPaperMid = styledComponent(CustomPaper)`
  padding-bottom: 15px;
  min-height: 100%;
`;
export const CustomPaperCommon = styledComponent(CustomPaper)`
  max-height: 243px;
  min-height: 220px;
`;
export const CustomPaperLastSection = styledComponent(CustomPaper)`
  height: 243px;
`;
export const CustomPaperGlance = styledComponent(CustomPaper)`
  min-height: fit-content;
`;

export const JobCard = styledComponent.div`
  height: 293;
`;

export const MidCardHeader = styledComponent.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  font-size: 20px;
  color: #52429a;
  padding-bottom: 1%;
`;

export const MidBody = styledComponent.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  font-size: 14px;
  color: #333333;
  width: 100%;
  max-height: 180px;
  overflow-y: auto;
`;

export const MidBodyEdit = styledComponent(MidBody)`
  background-color: #f5f5f5;
`;

export const MidBodyOrderPriorities = styledComponent(MidBody)`
  max-height: 100%;
`;
export const GlanceFieldTitle = styledComponent.div`
  padding-bottom: 15px;
  padding-left: 15px;
  color: #555555;
  display: block;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  justify-content: flex-start;
  ${props =>
    props.title &&
    props.title === 'hidden' &&
    `
  display: none`}
`;

export const GlanceFieldValue = styled(GlanceFieldTitle)(({ theme, ...props }) => ({
  backgroundColor:
    props.title === 'Approved'
      ? theme.palette.components.chips.primary.color
      : theme.palette.components.chips.secondary.color,
  fontWeight: '400 !important',
  textAlign: 'left',
  color:
    (props.title && props.title === 'Guaranteed Work Week' && props.slot && props.slot === 'NO') ||
    (props.title === 'Max Files Sent?' && props.slot && props.slot === 'YES')
      ? '#CC0000'
      : (props.title && props.title === 'Guaranteed Work Week' && props.slot && props.slot === 'YES') ||
        (props.title === 'Max Files Sent?' && props.slot && props.slot === 'NO')
      ? theme.palette.components.typography.status.available.color
      : '#333333 !important',
  display: props.title && props.title === 'hidden' ? 'none' : undefined,
}));

export const ExperienceHeader = styledComponent.div`
  font-size: 14px;
`;

export const ExperienceValue = styledComponent.div`
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  color: #003e68;
  background-color: #f5f5f5;
`;

export const Checkboxbody = styledComponent.div`
  padding: 11px;
`;

export const Header = styledComponent(Grid)`
  background: #f4f5f7;
  opacity: 1;
  display: flex;
  flex-direction: row;
  padding: 5px 0px;
`;

export const HeaderSection = styledComponent.div`
  display: flex;
  flex-direction: column;
  width: inherit;
`;

export const OrderDetails = styledComponent.span`
  display: flex;
  flex-direction: column;
  justify-content: left;
  color: #333333;
  padding-bottom: 2%;
  padding-right: 1%;
`;

export const OrderID = styledComponent.span`
  font-size: 30px
  font-weight: bold;
  color: #52429a;


`;

export const FacilityNameCard = styledComponent.span`
  width: 80px;
  height: 80px;
  background: #34b233;
  border-radius: 4px;
  padding: 5%;
  font-size: 42px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  margin-right: 15px;
`;

export const FacilityName = styledComponent.span`
  font-size: 14px;
  color: #333333;
  padding-bottom: 2%;
`;

export const OrderData = styledComponent.span`
  font-size: 14px;
  padding-bottom: 3%;
  margin-bottom: 8px;
`;

export const OrderInnerData = styledComponent.span`
  font-size: 14px;
  color: #003e68;
  padding-bottom: 9px;
  font-weight: bold;
  margin-top: 1px;
`;

export const CustomDivider = styledComponent(Divider)`
  opacity: 1;
  border: 1px solid #d1d1d1;
`;

export const CheckboxText = styledComponent.span`
  font-size: 14px;
  margin-right: 30px;
  margin-bottom: 30px;
`;

export const QuickGlanceBar = styledComponent(Grid)`
  background: transparent;
  opacity: 1;
  display: flex;
  flex-direction: row;
  padding: 5px;
`;

export const AtAGlanceDivider = styledComponent(Divider)`
  width: 100%;
  margin-bottom: 5%;
`;

export const AtAGlanceWhiteSpace = styledComponent.div`
  padding-bottom: 5%;
`;

export const AtAGlancePartner = styledComponent.div`
  padding-top: 10px;
  color: #003e68;
  font-size: 16px;
  font-weight: bold;
`;

export const AtAGlanceRole = styledComponent.div`
  font-size: 14px;
  color: #333333;
  padding-bottom: 10px;
  font-weight: bold;
`;

export const CustomCircularProgress = styledComponent(CircularProgress)`
  left: 50%;
  position: absolute;
  top: 50vh;
`;

export const ShiftLabel = styledComponent(FormControlLabel)`
  min-width: 120px;
`;

export const ProfHeading = styledComponent.span`
  color: #333333;
  padding-right: 2px;
`;

export const ProfSubHeading = styledComponent.span`
  color: #189ad9;
`;

export const Title = styledComponent.div`
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  padding: 6px 16px 0 0;
  white-wrap: nowrap !important;
`;

export const ArrowButton = styledComponent(IconButton)`
  display: flex;
  font-size: 24;
`;

export const ButtonLabel = styledComponent.div`
  font-size: 14px;
  color: #333333;
  padding-left: 5px;
`;
