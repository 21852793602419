import React, { useEffect, useState } from 'react';
import { AccordionCard, AccordContent, Title } from '../styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSummary, Divider, Grid, IconButton, Typography } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { RequestTimeOffDialog } from './RequestTimeOffDialog';
import { RequestTimeOffWrapper } from './RequestTimeOffWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { FormProvider, useForm } from 'react-hook-form';
import { selectUser } from 'oidc/user.selectors';
import { candidateDetailsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { usePromiseTracker } from 'react-promise-tracker';
import { isPositiveNumber } from 'app/helpers/componentHelpers';
import styledComponent from 'styled-components';
import { ff_candidate_container_ui_v2 } from 'app/constants/FeatureFlags/Candidate/Keys';
import { useDecision } from '@optimizely/react-sdk';
import RequestTimeOffIcon from 'app/assets/images/ProfessionalProfileCandidate/RequestTimeOffIcon.svg';
import { CustomTooltip } from '@AMIEWEB/Common';

/**
 * Component to render request time off card on professional profile
 */
const RequestTimeOffCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const [candidateContainerUIV2] = useDecision(ff_candidate_container_ui_v2);
  const user = useSelector(selectUser);
  const requestedTimeOff = candidateDetails.requestTimeOff;
  const [updatedReqList, setUpdatedReqList] = useState([]);
  const { promiseInProgress: postProfileTimeOffRequests } = usePromiseTracker({
    area: 'profile-time-off-request',
    delay: 0,
  });
  const [isHovered, setIsHovered] = React.useState(false);

  const formMethods = useForm({
    mode: 'all',
  });
  const { handleSubmit } = formMethods;

  /**
   * Method to handle add icon click
   */
  const handleAddIconClick = event => {
    setPopupOpen(true);
    event.stopPropagation();
  };

  const mapRequestTimeOffList = () => {
    return updatedReqList?.map(item => ({ startDate: item.startDate, endDate: item.endDate }));
  };

  const onSubmit = () => {
    const postRequestPayload = {
      candidateId: candidateDetails?.travelerId,
      brandId: candidateDetails?.brandId,
      brandName: candidateDetails?.brand,
      currentEmployeeId: user.userInfo?.employeeId || 0,
      currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
      requestedTimeOff: mapRequestTimeOffList(),
    };
    dispatch(candidateDetailActions.postRequestTimeOff(postRequestPayload));
  };

  useEffect(() => {
    if (isPositiveNumber(candidateDetails.travelerId) && isPositiveNumber(candidateDetails.brandId))
      dispatch(candidateDetailActions.requestTimeOffData());
  }, [candidateDetails?.travelerId, candidateDetails.brandId]);

  useEffect(() => {
    if (postProfileTimeOffRequests) setPopupOpen(false);
  }, [postProfileTimeOffRequests]);

  return (
    <>
      <AccordionCard elevation={0} data-testid="request-time-off-personal-profile">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="expand-time-off-personal-profile"
          sx={theme => ({
            backgroundColor: candidateContainerUIV2.enabled
              ? theme.palette.system.offWhite
              : theme.palette.framework.system.white,
            '.MuiAccordionSummary-content': { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
          })}
        >
          {candidateContainerUIV2 ? (
            <Grid
              container
              direction="row"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Grid item xs={10}>
                <Grid container direction="row" spacing={1} margin="0">
                  <img src={RequestTimeOffIcon} alt="Request Time Off Icon" />
                  <TitleV2>{t('placement.profile.requirementsAndQualifications.timeOffRequest.title')}</TitleV2>
                </Grid>
              </Grid>
              <Grid item mr="6px">
                <CustomTooltip tooltipContent={'Add'}>
                  <IconButton color="secondary" size="medium">
                    <AddIcon
                      onClick={event => handleAddIconClick(event)}
                      id="add-request-time-off-personal-profile"
                    />
                  </IconButton>
                </CustomTooltip>
              </Grid>
            </Grid>
          ) : (
            <>
              <Title>{t('placement.profile.requirementsAndQualifications.timeOffRequest.title')}</Title>
              <AddIcon
                onClick={event => handleAddIconClick(event)}
                id="add-request-time-off-personal-profile"
                sx={{ mr: '12px', color: 'grey' }}
              />
            </>
          )}
        </AccordionSummary>

        <AccordionDetails>
          <AccordContent>
            <Divider sx={{ position: 'relative', mt: '-17px', mb: '10px' }} />
            <Grid container direction="column" justifyContent="space-between" spacing={1}>
              <Grid item>
                {requestedTimeOff?.length
                  ? requestedTimeOff.map((timeOffItem, index: number) => (
                      <Typography key={index} component="div" pb={'6px'}>
                        {timeOffItem?.key}
                      </Typography>
                    ))
                  : '--'}
              </Grid>
            </Grid>
          </AccordContent>
        </AccordionDetails>
      </AccordionCard>
      {isPopupOpen && (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <RequestTimeOffDialog
              open={isPopupOpen}
              children={
                <RequestTimeOffWrapper
                  requestedTimeOff={requestedTimeOff}
                  updateReqList={data => setUpdatedReqList(data)}
                />
              }
              setPopupOpen={() => setPopupOpen(false)}
            />
          </form>
        </FormProvider>
      )}
    </>
  );
};

const TitleV2 = styledComponent.div`
color: #333333;
font-size: 16px;
padding: 0px 12px 0px 12px;
font-weight: 500;
`;
export default RequestTimeOffCard;
