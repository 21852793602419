import { ISaveTemplate, IUpdateTemplate } from 'app/models/Notification/Notification';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import baseAxiosInstance from 'utils/BaseApi';

export class TemplateService {
  private templateBase: string;

  private cancellationToken = axios.CancelToken.source();

  private static currentInstance: TemplateService;

  config?: AxiosRequestConfig = {};

  private constructor() {
    this.templateBase = globalThis?.app?.env?.REACT_APP_TEMPLATE_SERVICE_BASE_URL;
  }

  static createInstance(): TemplateService {
    const activeInstance = new TemplateService();
    // activeInstance.cancellationToken = axios.CancelToken.source();
    // if (activeInstance.config) activeInstance.config.cancelToken = activeInstance.cancellationToken.token;
    return activeInstance;
  }

  static getInstance(): TemplateService {
    if (!this.currentInstance) this.currentInstance = this.createInstance();
    return this.currentInstance;
  }

  static cancelActiveRequests() {
    this.currentInstance.cancellationToken.cancel('RequestCancellation');
    // this.currentInstance = null;
  }

  cancelRequests() {
    this.cancellationToken.cancel('RequestCancellation');
    return TemplateService.getInstance();
  }

  cancelAndRevokeInstance() {
    this.cancellationToken.cancel('RequestCancellation');
    this.cancellationToken = axios.CancelToken.source();
    if (this.config) this.config.cancelToken = this.cancellationToken.token;
  }

  getTemplates = (isTemplateText: boolean, category: 'Confirmation' | 'Contract') =>
    baseAxiosInstance
      .get(`${this.templateBase}/document-template/get?isTemplateText=${isTemplateText}&category=${category}`)
      .catch((error: AxiosError) => error.response);

  getMergeTokensList = (type: 'Confirmation' | 'Contract') =>
    baseAxiosInstance
      .get(`${this.templateBase}/document-template/merge-field?templateMergeType=${type}`)
      .catch((error: AxiosError) => error.response);

  publishTemplate = (body: {
    id: number;
    templateName: string;
    templateText: string;
    updatedBy: number;
    updatedByName: string;
    category: string;
  }) =>
    baseAxiosInstance
      .post(`${this.templateBase}/document-template/publish`, body)
      .catch((error: AxiosError) => error.response);

  saveDraftTemplate = (body: {
    id: number;
    templateName: string;
    templateText: string;
    updatedBy: number;
    updatedByName: string;
  }) =>
    baseAxiosInstance
      .post(`${this.templateBase}/document-template/draft`, body)
      .catch((error: AxiosError) => error.response);

  deleteDraftTemplate = (templateId: number) =>
    baseAxiosInstance
      .delete(`${this.templateBase}/document-template/${templateId}/draft`)
      .catch((error: AxiosError) => error.response);

  updateFacilityEmailTemplate = (data: IUpdateTemplate) =>
    baseAxiosInstance.put(`${this.templateBase}/Templates`, data).catch((error: AxiosError) => error.response);

  saveFacilityEmailTemplate = (data: ISaveTemplate) =>
    baseAxiosInstance
      .post(`${this.templateBase}/Templates/createTemplate`, data)
      .catch((error: AxiosError) => error.response);

  getFacilityTemplatesByCategory = (category: string, userType: number, userId: number) =>
    baseAxiosInstance
      .get(`${this.templateBase}/Templates/${category}/${userType}/${userId}`)
      .catch((error: AxiosError) => error.response);
  getBaseTemplate = (userType: number) =>
    baseAxiosInstance
      .get(`${this.templateBase}/Templates/base-template/${userType}`)
      .catch((error: AxiosError) => error.response);

  deletePersonalTemplate = (templateId: string, category: string) =>
    baseAxiosInstance
      .delete(`${this.templateBase}/Templates/${templateId}/${category}`)
      .catch((error: AxiosError) => error.response);

  GetTemplatesByCategory = (category: string, channel: string) =>
    baseAxiosInstance
      .post(`${this.templateBase}/Templates/search/category`, {
        category: category,
        channel: channel,
      })
      .catch((error: AxiosError) => error.response);
}
