import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './slice';
import _ from 'lodash';

const selectDomain = (state: RootState) => state?.placementMods || initialState;

export const selectPlacementModsList = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.placementModsList,
);

export const selectRefreshPlacementModsList = createSelector(
  [selectDomain],
  placmenModsData => placmenModsData.refreshPlacementModsList,
);

export const selectRefreshPlacementMods = createSelector([selectDomain], placementModsData => ({
  openRefreshDialog: placementModsData.openRefreshDialog,
  refreshModMessage: placementModsData.refreshModMessage,
}));

export const selectCurrentModDetails = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.currentModDetails,
);

export const selectDefaultModDetails = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.defaultModDetails,
);

export const selectStartDateReasonCodes = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.startDateReasonCodes,
);

export const selectInitiators = createSelector([selectDomain], placemenModsData => placemenModsData.initiators);

export const selectAdditionalPremiumPay = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.additionalPremiumPay,
);

export const selectbillRateGwwShift = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.billRateGwwShiftList,
);

export const selectCancelledHoursFrequency = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.cancelledHoursFrequencyList,
);

export const selectExpandedModsDialog = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.expandedModsDialgog,
);

export const selectPayRateGww = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.exceptionPayRateGww,
);

export const selectEffectiveDateTypes = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.effectiveDateTypes,
);

export const selectInsuranceTypes = createSelector([selectDomain], placemenModsData => placemenModsData.insuranceTypes);

export const selectPricingReason = createSelector([selectDomain], placemenModsData => placemenModsData.pricingReasons);

export const selectPayeeList = createSelector([selectDomain], placemenModsData => placemenModsData.payeeList);

export const selectReimbursementCategoryAndFrequency = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.reimbursementCategoryAndFrequency,
);

export const selectIsOpenModForAdding = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.isOpenModForAdding,
);

export const selectIsEditMod = createSelector([selectDomain], placemenModsData => placemenModsData.isEditMod);

export const selectModSavedStatus = createSelector([selectDomain], placemenModsData => placemenModsData.modSavedStatus);

export const selectIsUndoModal = createSelector([selectDomain], placemenModsData => placemenModsData.isUndoModal);

export const selectEffectiveTypeValue = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.insuranceEffectiveType,
);

export const selectIsEditExistingMod = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.isEditExistingMod,
);

export const selectChangeToPending = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.changeToPending,
);

export const selectEditTimeOffRows = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.editTimeOffRows,
);

export const selectEditPayrollShiftDifferentialRows = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.editPayrollShiftDiffRows,
);

export const selectEditFurnitureRows = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.editFurnitureRows,
);

export const selectEditUtilitiesRows = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.editUtilitiesRows,
);

export const selectIsGridModAltered = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.isGridModAltered,
);
export const selectOriginCustomTemplateChanged = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.originCustomTemplateChanged,
);

export const selectIsCustomTemplateType = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.isCustomTemplateType,
);
export const selectTimeOffHoursError = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.timeOffHoursErrors,
);

export const selectTimeOffStartDateError = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.timeOffStartDateErrors,
);

export const selectFurnitureQuantityError = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.furnitureQuantityErrors,
);

export const selectFurnitureCostToTravelerError = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.furnitureCostToTravelerErrors,
);

export const selectTimeOffEndDateError = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.timeOffEndDateErrors,
);

export const selectShiftDifferentialStartTimeError = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.shiftDifferentialStartTimeError,
);

export const selectShiftDifferentialEndTimeError = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.shiftDifferentialEndTimeError,
);

export const selectShiftDifferentialAmountError = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.shiftDifferentialAmountError,
);

export const selectShiftDifferentialTypeError = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.shiftDifferentialTypeError,
);

export const selectShiftDifferentialDescriptionTypeError = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.shiftDifferentialDescriptionTypeError,
);

export const selectIsGridModError = createSelector([selectDomain], placemenModsData => placemenModsData.isGridModError);

export const selectShiftDifferentialTypes = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.shiftDifferentialTypes,
);

export const selectShiftDifferentialDescriptionTypes = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.shiftDifferentialDescriptionTypes,
);

export const selectShiftDifferentialTemplateTypes = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.shiftDifferentialTemplateTypes,
);

export const selectedModForTask = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.selectedTaskForMod,
);
//*START - SELECTORS FOR EDIT PREFERENCE

//-- START ADULT GRID --
export const selectEditPreferenceAdultRows = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.preferenceMod.adult.editAdultRows,
);
export const selectIsAdultGridmodAltered = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.preferenceMod.adult.isAdultGridmodAltered,
);
export const selectIsAdultGridmoderror = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.preferenceMod.adult.isAdultGridmoderror,
);
export const selectPreferenceAdultNameError = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.preferenceMod.adult.adultNameError,
);
export const selectPreferenceAdultRelationshipError = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.preferenceMod.adult.adultRelationshipError,
);
//-- END ADULT GRID --
//-- START CHILDREN GRID --
export const selectEditPreferenceChildrenRows = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.preferenceMod.children.editChildrenRows,
);
export const selectIsChildrenGridmodAltered = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.preferenceMod.children.isChildrenGridModAltered,
);
export const selectIsChildrenGridmodError = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.preferenceMod.children.isChildrenGridModError,
);
export const selectPreferenceChildrenGenderError = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.preferenceMod.children.childrenGenderError,
);
export const selectPreferenceChildrenAgeError = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.preferenceMod.children.childrenAgeError,
);
//-- END CHILDREN GRID --
//-- START PETS  GRID --
export const selectEditPreferencePetsRows = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.preferenceMod.pets.editPetsRows,
);
export const selectIsPetsGridmodAltered = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.preferenceMod.pets.isPetsGridModAltered,
);
export const selectIsPetsGridModError = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.preferenceMod.pets.isPetsGridModError,
);
export const selectPreferencePetsTypeError = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.preferenceMod.pets.petsTypeError,
);
//-- END PETS  GRID --
//Combined data for preference mod
export const selectPreferenceGridDetails = createSelector([selectDomain], placemenModsData => ({
  adultsDetails: placemenModsData.preferenceMod.adult.editAdultRows,
  childrenDetails: placemenModsData.preferenceMod.children.editChildrenRows,
  petsDetails: placemenModsData.preferenceMod.pets.editPetsRows,
}));
//no preferences grid has data
export const selectPreferenceGridEmptyError = createSelector(
  [selectDomain],
  placemenModsData =>
    _.isEmpty(placemenModsData.preferenceMod.adult.editAdultRows) &&
    _.isEmpty(placemenModsData.preferenceMod.children.editChildrenRows) &&
    _.isEmpty(placemenModsData.preferenceMod.pets.editPetsRows),
);

//*END - SELECTORS FOR EDIT PREFERENCE

export const selectShiftDifferentialTemplateItems = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.shiftDifferentialTemplateItems,
);

export const selectOpenTaskGridFromHistory = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.openTaskGridFromHistory,
);

export const selectStandard = createSelector([selectDomain], placemenModsData => placemenModsData.standard);
export const selectDoesHousingBannerDisplay = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.doesHousingBannerDisplay,
);
export const selectholdBannerShownState = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.holdBannerShownState,
);
export const selectApprovalStatusSelected = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.isApprovalStatusSelected,
);

export const selectIsTemplateChangedInShift = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.isTemplateChangedInShift,
);

export const selectEditBillingShiftDifferentialRows = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.editBillingShiftDiffRows,
);

export const selectIsEmiLmoDisabled = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.isEmiLmoDisabled,
);

export const selectIsEmiLmoUndo = createSelector(
  [selectDomain],
  placemenModsData => placemenModsData.isEmiLmoUndo,
);

export const selectPendingModifications = createSelector([selectDomain], data => data.pendingMods);

export const selectPlacementModificationHistory = createSelector([selectDomain], data => data.placementModHistory);

export const selectBookingApprovedCheckboxSpecs = createSelector(
  [selectDomain],
  state => state.bookingApprovedCheckboxSpecs,
);
