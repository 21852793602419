import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { Chip, IconButton, Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getAttachmentIcon } from '../../Common/AttachmentFileTypes';
import { selectActiveStep, selectDeletedAttachments } from 'app/ApplicationRoot/Global.selectors';

const useStyles = makeStyles()((theme: Theme) => ({
  box: {
    borderBottom: '1px solid #B9BBBC',
    float: 'right',
    width: '100%',
    marginBottom: '1%',
    background: '#FBFBFB',
    height: '55px',
  },
  Chip: {
    margin: '1%',
    borderRadius: '5px',
  },
  uploadIcon: {
    float: 'right',
    marginTop: '15px'
  },
  placeholder: {
    border: '0px solid #B9BBBC',
    paddingTop: '1.5%',
    fontSize: '1rem',
    color: '#555555',
  },
}));

export const Attachments = props => {
  const { data, readonly, getInputProps, dataUrlAttachments } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [attachments, setAttachments] = useState<any>(data ?? []);
  const [urlAttachments, setUrlAttachments] = useState<any>(dataUrlAttachments ?? []);
  const validFileFormats = /(\.jpg|\.jpeg|\.pdf|\.xlsx|\.xlsm|\.doc|\.docx|\.png)$/i;
  const allowedFileSize = 5;
  const dispatch = useDispatch();
  const fileUploadRef = useRef<HTMLInputElement | null>(null);
  const activeStep = useSelector(selectActiveStep);
  const deletedAttachments = useSelector(selectDeletedAttachments);
  
  useEffect(() => {
    setAttachments(data);
  }, [data]);

  useEffect(() => {
    const mappedData: any[] = dataUrlAttachments?.map(item => ({
      name: item?.name?.split('?')?.[0],
    }));
    setUrlAttachments(mappedData);
  }, [dataUrlAttachments]);

  const handleGlobalError = (message: string, severity) => {
    dispatch(
      globalActions.setSnackBar({
        message: message,
        severity: severity,
      }),
    );
  };

  const handleFileAttachments = e => {
    e.preventDefault();
    const newAttachment = [...attachments, ...Object.values(e.target.files).slice(0, 5 - attachments.length)];
    let invalidFileFormat = 0;
    let invalidFileSize = 0;
    newAttachment?.forEach((item, index) => {
      if (!validFileFormats.exec(item.name.toLowerCase())) {
        invalidFileFormat++;
      }
      if (item.size / (1024 * 1024) > allowedFileSize) {
        invalidFileSize++;
      }
    });
    if (e.target.files.length > 5 || e.target.files.length + attachments.length > 5) {
      handleGlobalError('Please limit file selections to 5 attachments', 'error');
    }
    if (invalidFileFormat > 0) {
      handleGlobalError('Please choose valid file format (.jpeg, .png, .docx, .xls, .pdf)', 'error');
    }
    if (invalidFileSize > 0) {
      handleGlobalError('The individual file size should not exceed 5MB', 'error');
    } else {
      setAttachments(
        newAttachment.filter(
          item => !(!validFileFormats.exec(item.name.toLowerCase()) || item.size / (1024 * 1024) > allowedFileSize),
        ),
      );

      props.uploadFiles(
        newAttachment.filter(
          (item, index) =>
            !(!validFileFormats.exec(item.name.toLowerCase()) || item.size / (1024 * 1024) > allowedFileSize),
        ),
      );
    }
  };

  const handleDelete = (index: number) => {
    const newAttachments = _.cloneDeep(attachments).filter((item, itemIndex) => itemIndex !== index);
    setAttachments(newAttachments);
    dispatch(globalActions.setDeletedAttachments([...deletedAttachments, attachments[index]?.name]));
    props.uploadFiles(newAttachments);
    fileUploadRef.current.value=null;
  };

  const handleDeleteUrlAttachment = (index: number) => {
    const newUrlAttachments = _.cloneDeep(urlAttachments).filter((item, itemIndex) => itemIndex !== index);
    setUrlAttachments(newUrlAttachments);
    props.handleDataUrlAttachments(newUrlAttachments);
  }

  return (
    <div className={!readonly ? classes.box : undefined}>
      {!readonly && (
        <label htmlFor="email-btn-upload">
          <input
            {...getInputProps()}
            multiple
            style={{ display: 'none' }}
            id="email-btn-upload"
            name="email-btn-upload"
            type="file"
            onChange={e => handleFileAttachments(e)}
            ref={fileUploadRef}
          />
          <IconButton
            color="primary"
            className={classes.uploadIcon}
            disabled={attachments.length > 4}
            aria-label="upload picture"
            component="span"
            size="small">
            <AttachFileIcon />
          </IconButton>
        </label>
      )}

      {attachments.length === 0 && urlAttachments.length === 0  && !readonly && (
        <Chip
          className={classes.placeholder}
          variant="outlined"
          size="medium"
          label={t('Attach files (.jpeg, .png, .docx, .xls, .pdf)')}
        />
      )}
      {urlAttachments.map(
        (e, i) =>
          urlAttachments &&
          urlAttachments.length > 0 &&
          urlAttachments[i] && (
            <Chip
              className={classes.Chip}
              variant="outlined"
              size="medium"
              icon={
                <img src={getAttachmentIcon(urlAttachments[i].name.split('.').pop().toLowerCase())} alt={t('fileicon')} />
              }
              label={
                urlAttachments[i].name?.length > 15 ? urlAttachments[i].name?.substring(0, 15) + ' ...' : urlAttachments[i].name
              }
              onDelete={() => handleDeleteUrlAttachment(i)}
              deleteIcon={<CloseIcon />}
            />
          ),
      )}
      {attachments.map(
        (e, i) =>
          attachments &&
          attachments.length > 0 &&
          attachments[i] && (
            <Chip
              className={classes.Chip}
              variant="outlined"
              size="medium"
              icon={
                <img src={getAttachmentIcon(attachments[i].name.split('.').pop().toLowerCase())} alt={t('fileicon')} />
              }
              label={
                attachments[i].name?.length > 15 ? attachments[i].name?.substring(0, 15) + ' ...' : attachments[i].name
              }
              onDelete={() => activeStep === 1 && handleDelete(i)}
              deleteIcon={<CloseIcon />}
            />
          ),
      )}
    </div>
  );
};
