import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Backdrop, CircularProgress, Alert } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { Add } from '@mui/icons-material';
import { Credentials } from 'app/models/Credentialing/Credentials';
import { CredentialsSearch } from '../Search/CredentialsSearch';
import _ from 'lodash';

import { CredentialsGrid, CredentialsGridType } from '../CredentialsGrid';
import { CredentialingLookup } from 'app/models/Credentialing/CredentialingLookups';
import { AccordionMenu } from 'app/components/Common/DropDown/AccordionMenu';
import { getDropDownDescription, getDropDownValue, getLookups } from '../TableData/tableHelpers';
import { getColumnsAndRows, onAddAttachment, onNewSave } from '../CredentialsHelpers';
import { CustomTooltip } from 'app/components/Common/Tooltips/CustomTooltip';
import { AddCredentialForm } from './AddCredentialForm';
import { startTimedEvent, trackEvent } from 'app-insights/appInsightsTracking';
import { isCredentialing } from 'oidc/userRoles';
import { selectUser } from 'oidc/user.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { gridTypeToCategoryString } from '../Search/searchHelpers';
import {
  AttachmentsSelection,
  newAttachmentsSelection,
  selectAddUnverifiedCredType,
} from '../../../Profile/CandidateDetails.selector';
import { AttachmentItemState } from '../Attachments/AttachmentPreview/AttachmentPreviewDialog';
import { candidateDetailActions } from '../../../Profile/CandidateDetails.redux';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { CredentialsSearchByCategory } from '../../SummaryTab/CredentialsSearchByCategory';
import { globalActions } from 'app/ApplicationRoot/Global.redux';

const useStyles = makeStyles()(theme => ({
  addButton: {
    width: '38px',
    height: '38px',
    borderRadius: '4px',
    color: '#fff',
    marginTop: '2px',
  },
  disabledButton: {
    backgroundColor: theme.palette.framework.system.lightGray,
    '&:hover': {
      backgroundColor: theme.palette.framework.system.lightGray,
    },
    width: '38px',
    height: '38px',
    borderRadius: '4px',
    color: '#fff',
    cursor: 'not-allowed',
    marginTop: '2px',
  },
  paper: {
    minWidth: '80%',
    [theme.breakpoints.up('lg')]: {
      minWidth: '60%',
    },
    overflow: 'hidden',
    maxWidth: 'max-content',
    minheight: '70vh',
  },
  alert: {
    marginTop: '16px',
  },

  headerBody: {
    display: 'flex',
  },
  searchTitle: {
    justifyContent: 'flex-start',
    fontSize: '12px',
    height: '25px',
  },
  backdrop: {
    zIndex: 1500,
  },
}));

export enum CertificationSubCategoryGridTypes {
  Certifications = 6,
  OtherCertifications = 7,
  LifeSupportCertification = 8,
}

export enum CertificationType {
  ACLS = 46,
  BLS = 47,
  NRP = 50,
  PALS = 51,
  Other = 122,
}

export enum CertificationSubCategory {
  Certifications = 'Certifications',
  OtherCertifications = 'Other Certifications',
  LifeSupportCertification = 'Life Support Certification',
}

interface CredentialsAddProps {
  openAdd: boolean;
  handlePopupOpen: (...rest) => void;
  handlePopupClose: (...rest) => void;
  credentialsGridType: CredentialsGridType;
  credentialingLookups: CredentialingLookup[];
  credentialingAllLookups: CredentialingLookup[];
  credentials: Credentials | null;
  onAddCredential: (...rest) => void;
  isAddLicense?: boolean;
  selectedLicenseMetaData?: any;
}
export const getCredentialOrTypeName = (
  selectedOption,
  warningMessageFirst?,
  warningMessageSecond?,
  numberOfDuplicates?,
  addingCredential?,
) => {
  let name;

  if (!!selectedOption?.type && selectedOption.type.value !== 'N/A') {
    name = `The ${selectedOption.requirementTypeID === 222 ? 'requirement type ' : 'type'} ${
      selectedOption?.type?.description
    }`;
  } else {
    name = `The credential name ${selectedOption?.credentialName}`;
  }
  return !addingCredential
    ? `${name} (${numberOfDuplicates} ${warningMessageFirst}${
        numberOfDuplicates > 1 ? 's' : ''
      }) ${warningMessageSecond}`
    : ` ${name} ${warningMessageFirst}`;
};

export const CredentialsAdd = (props: CredentialsAddProps) => {
  const {
    openAdd,
    handlePopupOpen,
    handlePopupClose,
    credentials,
    credentialingLookups,
    credentialingAllLookups,
    onAddCredential,
    isAddLicense,
    selectedLicenseMetaData,
  } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showRows, setShowRows] = useState<Boolean>(false);
  const [showDuplicateWarning, setShowDuplicateWarning] = useState<boolean>(false);
  const [containsDuplicates, setContainsDuplicates] = useState<boolean>(false);
  const [typeDropDownOptions, setTypeDropDownOptions] = useState<any[]>([]);
  const [fieldLookups, setFieldLookups] = useState<[]>([]);
  const [typeValue, setTypeValue] = useState<any>(null);
  const [showTypeDropDown, setShowTypeDropDown] = useState<Boolean>(false);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [addingNewCredential, setAddingNewCredential] = useState<boolean>(false);
  const [newCredentials, setNewCredentials] = useState<any>(null);
  const [metadata, setMetadata] = useState<CredentialingLookup[]>([]);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [addPopUpEditIcon, setAddPopUpEditIcon] = useState<boolean>(true);
  const [numberOfDuplicates, setNumberOfDuplicates] = useState<number>(0);
  const [postingNewCredential, setPostingNewCredential] = useState<boolean>(false);
  const [createSuccessful, setCreateSuccessful] = useState<boolean>(false);
  const [isAttachmentUploaded, setIsAttachmentUploaded] = useState<number>(0);
  const [createFailed, setCreateFailed] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [reloadGrid, setReloadGrid] = useState<boolean>(false);
  const [typeError, setTypeError] = useState<boolean>(false);
  const tooltipTitle: String = t('search.grid.add.addHoverTitle');
  const [searchDialogOpen, setSearchDialogOpen] = useState<boolean>(openAdd);
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
  const [resettingSearch, setResettingSearch] = useState<boolean>(false);
  const user = useSelector(selectUser);
  const hasAccess = isCredentialing(user?.userInfo?.roles || []);
  const attachments = useSelector(AttachmentsSelection);
  const newAttachments = useSelector(newAttachmentsSelection);
  const addUnverifiedCredType = useSelector(selectAddUnverifiedCredType);
  const [failedAttachments, setFailedAttachments] = useState<any[]>([]);
  const [requirementId, setRequirementId] = useState<any>(0);
  const [isAttachmentSelected, setIsAttachmentSelected] = useState<boolean>(false);
  const [licenseMetaData, setLicenseMetaData] = useState<any>(null);

  useEffect(() => {
    setSearchDialogOpen(openAdd);
    if (openAdd) {
      setRequirementId(0);
      setIsAttachmentUploaded(0);
      setIsAttachmentSelected(false);
    }
  }, [openAdd]);

  /**
   * Get the blank model for credentials that will be added
   * @param e event
   */
  const handleAddCredentials = () => {
    setAddingNewCredential(true);
    blockFromAddIfTypeEmpty();
    setShowDuplicateWarning(false);
    setSearchDialogOpen(false);
    setAddDialogOpen(true);
  };

  const handleClickOpen = e => {
    trackEvent({ type: 'click', name: 'Add Credential Button' });
    startTimedEvent('addCredential');
    handlePopupOpen(e);
  };

  const handleDropDownPopulation = (fieldLookups, data) => {
    const currentFieldLookups = fieldLookups?.filter(lookup => lookup.requirementTypeID === data.requirementTypeID);
    const dropDownMenu = getLookups(
      currentFieldLookups,
      data.requirementTypeID === 222 ? 'ReqTypeID' : 'TypeID',
      data?.type?.value,
    );
    const dropDownValue = getDropDownValue(
      currentFieldLookups,
      data.requirementTypeID === 222 ? 'ReqTypeID' : 'TypeID',
      data?.type?.value,
    );
    if (dropDownMenu !== undefined) {
      setShowTypeDropDown(considerTypeValue(data));
      setFieldLookups(_.cloneDeep(currentFieldLookups));
      setTypeValue(_.cloneDeep(dropDownValue));
      const tempDropDown = [{ value: 0, description: 'All' }, ...dropDownMenu];

      setTypeDropDownOptions(_.cloneDeep(tempDropDown));
    } else {
      setShowTypeDropDown(false);
      setTypeDropDownOptions([]);
    }
  };

  const blockFromAddIfTypeEmpty: any = () => {
    if (
      selectedOption.gridType === CredentialsGridType.miscellaneousPendingMoleculeDevelopment ||
      selectedOption.gridType === CredentialsGridType.certificationsOtherCertifications ||
      selectedOption.gridType === CredentialsGridType.educationAndOnlineForms
    ) {
      if (selectedOption.type === undefined) {
        setTypeError(true);
      } else setTypeError(false);
    } else setTypeError(false);
  };

  const considerTypeValue = option => {
    switch (option.gridType) {
      case CredentialsGridType.educationAndOnlineForms:
        return true;
      case CredentialsGridType.certificationsOtherCertifications:
        return true;
      case CredentialsGridType.miscellaneousPendingMoleculeDevelopment:
        return true;

      default:
        return false;
    }
  };

  const getData = (option, creds, filter?, editMode = false) => {
    setShowRows(true);
    setShowTypeDropDown(considerTypeValue(option));
    const cat = gridTypeToCategoryString(option.gridType, false);
    const lookups = credentialingAllLookups
      ?.filter(item => item.category === cat)
      .sort((a, b) => (a.sequence < b.sequence ? 1 : -1));
    setMetadata(lookups);
    handleDropDownPopulation(lookups, option);
  };

  const duplicateRecordsFound = count => {
    setNumberOfDuplicates(count);
    setShowDuplicateWarning(true);
    setResettingSearch(false);
  };

  const willAddNewCredential = () => {
    if (!resettingSearch) {
      if (!addingNewCredential) {
        setShowDuplicateWarning(false);
        setContainsDuplicates(false);
        setNumberOfDuplicates(0);
        handleAddCredentials();
      }
    } else {
      // Going back to search with no duplicates found
      setShowTypeDropDown(false);
      setTypeValue('N/A');
      setSearchInput('');
      setSelectedOption(null);
      setShowRows(false);
    }
    setResettingSearch(false);
  };

  const onSelect = option => {
    setAddPopUpEditIcon(true);
    setSelectedOption(option);
    setSearchInput(option.selectedTitle);
    getData(option, credentials, item => item.requirementTypeID === option.requirementTypeID);
    setContainsDuplicates(false);
  };

  const onTypeDropDownChange = event => {
    const tempPayload = _.cloneDeep(selectedOption);
    if (event.target.value === 0) {
      let tempAllData: any = null;
      tempAllData = { ...tempPayload, type: null, title: tempPayload.selectedTitle };
      setSelectedOption(tempAllData);
    } else {
      let tempData: any = null;
      const description = getDropDownDescription(
        fieldLookups,
        tempPayload.requirementTypeID === 222 ? 'ReqTypeID' : 'TypeID',
        event.target.value,
      );

      tempData = {
        ...tempPayload,

        type: {
          value: event.target.value,
          description: description,
        },
        title: description,
      };

      setSelectedOption(tempData);
    }
    setTypeValue(event.target.value);
  };

  const onBack = () => {
    setResettingSearch(true);
    onTypeDropDownChange({
      target: {
        value: typeValue,
      },
    });
    setShowDuplicateWarning(containsDuplicates);
    setEditingIndex(null);
    setAddingNewCredential(false);
    setNewCredentials(credentials);
    setAddPopUpEditIcon(true);
    setContainsDuplicates(false);
    setCreateSuccessful(false);
    setCreateFailed(false);
    setSearchDialogOpen(true);
    setAddDialogOpen(false);
    dispatch(candidateDetailActions.setNewAttachments([]));
  };

  const logCredential = (row, isSaveActive = false) => {
    const promise = new Promise((resolve, reject) => {
      setPostingNewCredential(true);
      onNewSave(row, credentials?.candidateId)
        .then(response => {
          if (response !== null && response !== undefined && response !== '') {
            onAddCredential(response, selectedOption);
            setRequirementId(response.requirementID);
          }
          if (newAttachments === undefined || newAttachments?.length === 0) {
            setIsAttachmentSelected(false);
          }
          setPostingNewCredential(false);
          setCreateSuccessful(true);
          resolve(response);
          closeDialog(undefined, isSaveActive);
          dispatch(candidateDetailActions.setIsPreview(true));
        })
        .catch(error => {
          if (error.status !== 401) setCreateFailed(true);
          setPostingNewCredential(false);
          reject('error');
        });
    });
    return promise;
  };

  const submitFailedAttachment = async attachment => {
    let newAttachmentItems = _.cloneDeep(newAttachments) || [];
    const files: any[] = [];
    newAttachmentItems.map(item => {
      if (item.data === attachment.data) {
        const file = {
          ...item,
          status: AttachmentItemState.loading,
        };
        files.push(file);
      }
    });
    dispatch(candidateDetailActions.setNewAttachments(files));

    await onAddAttachment(
      credentials?.candidateId as any,
      requirementId as any,
      attachments,
      attachment,
      user,
      dispatch,
    )
      // eslint-disable-next-line no-loop-func
      .then(() => {
        const files: any[] = [];
        newAttachmentItems.map(item => {
          let file = item;
          if (_.isEqual(item, attachment)) {
            file = {
              ...item,
              status: AttachmentItemState.success,
            };
          }
          files.push(file);
        });
        newAttachmentItems = files;
        dispatch(candidateDetailActions.setNewAttachments(files));
      })
      // eslint-disable-next-line no-loop-func
      .catch(e => {
        const failedFiles = failedAttachments;
        const files: any[] = [];
        newAttachmentItems.map(item => {
          let file = item;
          if (item === attachment) {
            file = {
              ...item,
              status: AttachmentItemState.error,
            };
            failedFiles.push(file);
          }
          files.push(file);
        });
        newAttachmentItems = files;
        setFailedAttachments(failedFiles);
        dispatch(candidateDetailActions.setNewAttachments(files));
      });
  };

  useEffect(() => {
    if (requirementId > 0 && newAttachments) {
      setIsAttachmentSelected(true);
      submitNewAttachments(credentials?.candidateId, requirementId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requirementId]);

  const submitNewAttachments = async (candidateId: any, requirementId: any) => {
    let processSuccess = true;

    let newAttachmentItems = _.cloneDeep(newAttachments) || [];
    const files: any[] = [];
    newAttachmentItems.map(item => {
      const file = {
        ...item,
        status: AttachmentItemState.loading,
      };
      files.push(file);
    });
    newAttachmentItems = files;
    dispatch(candidateDetailActions.setNewAttachments(files));

    if (newAttachments) {
      for (const attachment of newAttachmentItems) {
        await onAddAttachment(candidateId, requirementId, attachments, attachment, user, dispatch)
          //eslint-disable-next-line no-loop-func
          .then(() => {
            const files: any[] = [];
            newAttachmentItems.map(item => {
              let file = item;
              if (_.isEqual(item, attachment)) {
                file = {
                  ...item,
                  status: AttachmentItemState.success,
                };
              }
              files.push(file);
            });
            newAttachmentItems = files;
            dispatch(candidateDetailActions.setNewAttachments(files));
            setIsAttachmentUploaded(1);
          })
          // eslint-disable-next-line no-loop-func
          .catch(e => {
            processSuccess = false;
            const failedFiles = failedAttachments;
            const files: any[] = [];
            newAttachmentItems.map(item => {
              let file = item;
              if (item === attachment) {
                file = {
                  ...item,
                  status: AttachmentItemState.error,
                };
                failedFiles.push(file);
              }
              files.push(file);
            });
            newAttachmentItems = files;
            setFailedAttachments(failedFiles);
            setIsAttachmentUploaded(2);
            dispatch(candidateDetailActions.setNewAttachments(files));
          });
      }
      if (processSuccess) {
        closeDialog();
      }
    }
  };

  useEffect(() => {
    if (createSuccessful && (isAttachmentUploaded === 1 || !isAttachmentSelected)) {
      dispatch(
        globalActions.setSnackBar({
          message: t('search.grid.add.successMessage'),
          severity: 'success',
          onSnackClose: () => onSnackBarClose(),
        }),
      );
    } else if (createSuccessful && isAttachmentUploaded === 2) {
      dispatch(
        globalActions.setSnackBar({
          message: t('The credential has been added successfully but the selected documents failed to attach.'),
          severity: 'success',
          onSnackClose: () => onSnackBarClose(),
        }),
      );
    } else if (createFailed) {
      dispatch(
        globalActions.setSnackBar({
          message: !typeError ? t('search.grid.add.errorMessage') : t('search.grid.add.typeErrorMessage'),
          severity: 'error',
          onSnackClose: () => onSnackBarClose(),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createFailed, createSuccessful, isAttachmentSelected, isAttachmentUploaded]);

  const closeDialogAndSearch = rowID => {
    const search: any = {
      object: {
        requirementTypeID: selectedOption.requirementTypeID,

        isEditing: true,
      },
      requirementID: rowID,
      gridType: selectedOption.gridType,
      parentGridType: selectedOption.parentGridType,
    };
    closeDialog(search);
  };

  const closeDialog = (search?, isSaveActive = false) => {
    setSelectedOption(null);
    setShowDuplicateWarning(false);
    setAddingNewCredential(false);
    setTypeDropDownOptions([]);
    setShowRows(false);
    setShowTypeDropDown(false);
    setMetadata([]);
    setEditingIndex(null);
    setContainsDuplicates(false);
    handlePopupClose?.(search, isSaveActive);
    setCreateSuccessful(false);
    setCreateFailed(false);
    setSearchInput('');

    setSearchDialogOpen(false);
    setAddDialogOpen(false);
    dispatch(candidateDetailActions.setNewAttachments([]));
    if (isAddLicense) handlePopupClose?.(null, true);
  };

  const onSnackBarClose = () => {
    setCreateSuccessful(false);
    setCreateFailed(false);
  };

  const onSearchInputChange = value => {
    setSearchInput(value);
  };
  const initiateTypeDropDownChange = value => {
    onTypeDropDownChange({
      target: {
        value: value,
      },
    });
  };

  useEffect(() => {
    if (
      selectedOption?.gridType === CredentialsGridType.miscellaneousPendingMoleculeDevelopment ||
      selectedOption?.gridType === CredentialsGridType.certificationsOtherCertifications ||
      selectedOption?.gridType === CredentialsGridType.educationAndOnlineForms
    ) {
      findDuplicationExistOnTypeChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeValue]);

  const findDuplicationExistOnTypeChange = () => {
    const filterRequirementTypeID = item => item.requirementTypeID === selectedOption.requirementTypeID;
    const filterType = item =>
      (item.requirementTypeID === 222 ? item.reqTypeID : item.typeID) === selectedOption.type.value;

    const { rows } = getColumnsAndRows(
      credentials,
      selectedOption?.gridType,
      metadata,
      selectedOption
        ? !!selectedOption.type && selectedOption?.type?.value !== 'N/A'
          ? [filterRequirementTypeID, filterType]
          : [filterRequirementTypeID]
        : [],
      true,
      () => {},
    );

    if (rows.length > 0) {
      onBack();
    } else setShowDuplicateWarning(false);
  };

  useEffect(() => {
    if (selectedLicenseMetaData) onChange(selectedLicenseMetaData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLicenseMetaData]);

  const getGridType = credId => {
    if (
      credId === CertificationType.ACLS ||
      credId === CertificationType.BLS ||
      credId === CertificationType.NRP ||
      credId === CertificationType.PALS
    )
      return CertificationSubCategoryGridTypes.LifeSupportCertification;
    if (credId === CertificationType.Other) return CertificationSubCategoryGridTypes.OtherCertifications;
    else return CertificationSubCategoryGridTypes.Certifications;
  };

  const getCredSubCategory = credId => {
    if (
      credId === CertificationType.ACLS ||
      credId === CertificationType.BLS ||
      credId === CertificationType.NRP ||
      credId === CertificationType.PALS
    )
      return CertificationSubCategory.LifeSupportCertification;
    if (credId === CertificationType.Other) return CertificationSubCategory.OtherCertifications;
    else return CertificationSubCategory.Certifications;
  };

  const onChange = newInputValue => {
    if (newInputValue) {
      setLicenseMetaData(newInputValue);
      const temp = {
        abbreviation: newInputValue?.abbr,
        category: `${addUnverifiedCredType}`,
        credentialName: newInputValue?.credName,
        gridType: addUnverifiedCredType === 'Licenses' ? 0 : getGridType(newInputValue?.id),
        parentGridType: addUnverifiedCredType === 'Licenses' ? 0 : 5,
        requirementTypeID: newInputValue?.id,
        selectable: true,
        selectedTitle: newInputValue?.label,
        subCategory: getCredSubCategory(newInputValue?.id),
        title: newInputValue?.label,
      };
      onSelect(temp);
    }
  };

  const dialogContentRender = () => {
    return (
      <React.Fragment>
        <div className={classes.headerBody}>
          <Grid spacing={2} container>
            <Grid item xs={showTypeDropDown || !!selectedOption ? 6 : 12}>
              {!isAddLicense && (
                <div className={classes.searchTitle}>
                  {t(
                    addDialogOpen
                      ? t('search.grid.add.credName')
                      : selectedOption
                      ? t('search.grid.add.enterCredName')
                      : '',
                  )}
                </div>
              )}
              {!isAddLicense && (
                <CredentialsSearch
                  onAddSearch={true}
                  searchInput={searchInput}
                  onSelect={onSelect}
                  onSearchInputChange={onSearchInputChange}
                />
              )}
              {isAddLicense && (
                <CredentialsSearchByCategory
                  onChange={onChange}
                  selectedLicense={licenseMetaData ? licenseMetaData?.label : selectedLicenseMetaData?.label}
                />
              )}
            </Grid>
            {showTypeDropDown && (
              <Grid item xs={6}>
                <div className={classes.searchTitle}>
                  {t(selectedOption?.requirementTypeID === 222 ? 'grid.add.searchTitlePMD' : 'grid.add.searchTitle')}
                </div>
                <AccordionMenu
                  filterOptions={typeDropDownOptions}
                  handleChange={onTypeDropDownChange}
                  large={false}
                  value={typeValue === 'N/A' ? 0 : typeValue}
                  color={undefined}
                />
              </Grid>
            )}
          </Grid>
        </div>
        {showDuplicateWarning && (
          <Alert severity="warning" className={classes.alert}>
            <div>
              {`${getCredentialOrTypeName(
                selectedOption,
                t('search.grid.add.record'),
                t('search.grid.add.recordExists'),
                numberOfDuplicates,
                false,
              )}`}{' '}
              <br /> {t('search.grid.add.warningEdit')}
              <br />
              {t('search.grid.add.warningAdd')}
            </div>
          </Alert>
        )}

        {credentials && credentialingLookups && showRows ? (
          <Grid container direction="column">
            <Grid item xs={12}>
              <CredentialsGrid
                credentials={addingNewCredential ? newCredentials : credentials}
                credentialsGridType={selectedOption?.gridType}
                credentialingLookups={metadata}
                selectedOption={selectedOption}
                addPopUpEditIcon={addPopUpEditIcon && !isAddLicense}
                addPopupClose={closeDialogAndSearch}
                initialEditingIndex={editingIndex}
                showEditControl={false}
                duplicateRecordsFound={!addingNewCredential ? duplicateRecordsFound : undefined}
                willAddNewCredential={willAddNewCredential}
                onSaveNew={addingNewCredential ? logCredential : handleAddCredentials}
                addingCredential={addingNewCredential}
                numberOfItemsPerPage={4}
                reloadGrid={reloadGrid}
                isAddLicense={isAddLicense}
              />
            </Grid>
          </Grid>
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <div data-testid="credentials-Add">
      <CustomTooltip
        tooltipContent={tooltipTitle}
        placement={'bottom'}
        value={
          <IconButton
            color={hasAccess ? 'primary' : 'disabled'}
            onClick={e => {
              return hasAccess ? handleClickOpen(e) : null;
            }}
            aria-label="add-button"
            className={hasAccess ? classes.addButton : classes.disabledButton}
            size="large"
          >
            <Add />
          </IconButton>
        }
      ></CustomTooltip>
      <GenericDialog
        open={searchDialogOpen}
        fullWidth
        variant="blue"
        classes={{ paper: classes.paper }}
        dialogTitleProps={{
          text: isAddLicense
            ? addUnverifiedCredType == 'Licenses'
              ? t('search.grid.add.logLicenseText')
              : t('search.grid.add.logCertificationText')
            : t('search.grid.add.logButtonText'),
          closeButton: isAddLicense ? true : undefined,
        }}
        onClose={closeDialog}
        dialogActions={[
          {
            text: t('search.grid.add.cancelButtonText'),
            variant: 'contained',
            color: 'tertiary',
            onClick: closeDialog,
          },
          {
            text: isAddLicense ? (addUnverifiedCredType == 'Licenses' ? 'NEXT' : 'NEXT') : 'NEXT',
            variant: 'contained',
            onClick: () => {
              setSearchDialogOpen(false);
              setAddDialogOpen(true);
            },
            hidden: !selectedOption,
          },
        ]}
      >
        {dialogContentRender()}
      </GenericDialog>
      <AddCredentialForm
        credential={selectedOption}
        handleClose={closeDialog}
        handleBack={onBack}
        typeChanged={event => {
          setTypeValue(event.target.value);
          initiateTypeDropDownChange(event.target.value);
        }}
        open={addDialogOpen}
        logCredential={logCredential}
        duplicateExist={showDuplicateWarning}
        isCredentialCreated={requirementId > 0 ? true : false}
        submitFailedAttachments={submitFailedAttachment}
        isAddLicense={isAddLicense}
      />
      <Backdrop className={classes.backdrop} open={postingNewCredential}>
        <CircularProgress />
      </Backdrop>
    </div>
  );
};
