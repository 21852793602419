import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { InnerValueSection } from 'app/components/Order/OrderCreationForm/DefineUnit/UnitPreview/Common/InnerValueSection';
import {
  PreviewContent,
  PreviewContentTitle,
} from 'app/components/Order/OrderCreationForm/DefineUnit/UnitPreview/UnitPreviewDesign';
import { OrderPrioritiesData } from 'app/models/Orders/OrderDetails';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  data: {
    orderPriorities: OrderPrioritiesData[];
  };
}

const useStyles = makeStyles()(theme => ({
  list: {
    paddingLeft: theme.spacing(3),
    marginTop: 0,
  },
  root: {
    maxHeight: 200,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));

export const OrderDetailsOrderPriorities = (props: Props) => {
  const { orderPriorities } = props.data;

  const { classes } = useStyles();
  const { t } = useTranslation();

  const getUnique = (arr, index) => {
    const unique = arr
      .map(e => e[index])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e])
      .map(e => arr[e]);
    return unique;
  };

  const orderPriorityList = (priorities: OrderPrioritiesData[]) => {
    return (
      <>
        {getUnique(priorities, 'description').map(item => {
          return (
            <ul className={classes.list}>
              <li>{item.description}</li>
            </ul>
          );
        })}
      </>
    );
  };
  return (
    <>
      <Grid item xs={12}>
        <PreviewContent variant="outlined" elevation={0}>
          <Grid container direction="row" spacing={3} justifyContent="flex-start" alignContent="flex-start">
            <Grid item xs={12}>
              <PreviewContentTitle>{t('order.createUnit.orderPriorities')}</PreviewContentTitle>
            </Grid>
            <Grid item xs={12} className={classes.root}>
              {orderPriorities && (
                <InnerValueSection
                  id={'OrderDetailsOrderPrioritiesLbl'}
                  gridSize={12}
                  component={orderPriorityList(orderPriorities)}
                />
              )}
            </Grid>
          </Grid>
        </PreviewContent>
      </Grid>
    </>
  );
};
