import { PayloadAction } from '@reduxjs/toolkit';
import { taskDetailsActions } from './Tasks.redux';
import { call, put, select } from 'redux-saga/effects';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { IGetActivityFeedQuery } from 'app/models/ActivityFeed/IGetActivityFeed';
import { selectUser } from 'oidc/user.selectors';
import {
  bulkMarkNotificationAsReadPayload,
  getEmployeeId,
  getUnreadNotificationPayLoad,
  markNotificationAsReadPayload,
} from '@AMIEWEB/Notification/Tasks/Common_v2/utils';
import { getNotificationLogData, getUnReadNotifications, getUnReadNotificationsCounts, sendNotificationReadStatus } from './Tasks.saga';
import {
  IHandleBulkMarkReadAction,
  IUpdateNotificationReadStatusAction,
  NotificationCategory,
} from 'app/models/Tasks/Tasks';
import {
  filterPreferencesId,
  getSelectedFiltersBasedOnTab,
  payloadWithFilters,
} from '@AMIEWEB/Notification/Tasks/utils';
import { RootState } from 'types';
import { manuallyDecrementPromiseCounter, manuallyIncrementPromiseCounter, manuallyResetPromiseCounter } from 'react-promise-tracker';

//* This function is used get Unread Notification for all icons initially.
export function* initNotifications(action: PayloadAction<number | null>) {
  try {
    //#region Delay
    //Delay is added since in some cases notifications take time to update
    const delay = action.payload || 0;
    yield new Promise(resolve => setTimeout(resolve, delay));
    //#endregion
    const userInfo = yield select(selectUser);
    //#region Get Unread Notifications
    const UnreadPayload: IGetActivityFeedQuery = getUnreadNotificationPayLoad(
      { useTypes: [], useSubTypes: [], emailUseTypes: ['dropped', 'blocked', 'bounce', 'spamreport', 'unsubscribe'] },
      userInfo,
    );
    //#endregion

    yield call(getUnReadNotificationsCounts, {
      type: taskDetailsActions.getUnReadNotificationsCounts.type,
      payload: UnreadPayload,
    });
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'initNotifications',
        area: 'src/app/components/Tasks/store/Tasks.business_saga.ts',
      },
    });
    throw new Error('Error in - getUnReadNotificationsCounts');
  }
}

export function* updateNotificationReadStatusSaga(action: PayloadAction<IUpdateNotificationReadStatusAction>) {
  try {
    //Note : activityFeedRefactoring(featureFlag) is taken as true for helper functions to get the respective results
    const activityFeedRefactoring = true;
    const { idArr, currentPage, notificationCategory, disableGetActivityFetch = false } = action.payload;
    const filterPreference = yield select((state: RootState) => {
      return state.userPreferenceData.userGridPreferences.find(item => item.id === filterPreferencesId);
    });
    const user = yield select(selectUser);

    const appliedFilters = filterPreference?.value.filtersApplied;
    const availableFilters = getSelectedFiltersBasedOnTab(
      notificationCategory,
      appliedFilters,
    );

    const unreadFilterExist = availableFilters?.find(
      item => item?.name === NotificationCategory?.Unread && item?.id === `${notificationCategory}-id`,
    );
    const updateReadStatusBody = markNotificationAsReadPayload(idArr, getEmployeeId(user?.userInfo));
    yield call(sendNotificationReadStatus, {
      type: taskDetailsActions.sendNotificationReadStatus.type,
      payload: updateReadStatusBody,
    });

    /**
     * Decrement count 1 in redux store
     * CASE 1
     *   - Filter-Unread =  CHECKED
     *   - Get current filters
     *   - call GetActivityFeed (readstatus == false && current filters)
     *       - Unread counts updated in redux after this call ??
     *   - Update the store wrt to notificationType
     *
     * CASE 2
     *   - FIlter=Unread = UNCHECKED
     *   - Update the read status of item in redux store
     */

    //CASE 1
    if (unreadFilterExist && unreadFilterExist?.value && !disableGetActivityFetch) {
      const payload = payloadWithFilters(
        availableFilters,
        notificationCategory,
        user,
        currentPage,
      );
      payload.setNotificationLogData = true; //*set the latest response back to redux
      payload.ignoreCountUpdation = true; //*set the latest response back to redux
      yield call(getUnReadNotifications, { type: taskDetailsActions.getNotificationLogData.type, payload });
    }
    //CASE 2
    else {
      yield put(
        taskDetailsActions.setNotificationUnreadStatus(idArr?.map(item => ({ id: item, isNotificationUnread: false }))),
      );
    }
    //*Decrement the unread count  in redux store
    yield put(
      taskDetailsActions.decrementNotificationCount({
        notificationType: notificationCategory,
      }),
    );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'updateNotificationReadStatusSaga',
        area: 'src/app/components/Tasks/store/Tasks.business_saga.ts',
      },
    });
    throw new Error('Error in updateNotificationReadStatusSaga ');
  }
}

//* This function is used get Unread Notification for all icons initially.
export function* handleBulkMarkRead(action: PayloadAction<IHandleBulkMarkReadAction>) {
  try {
    manuallyIncrementPromiseCounter('notification-bulk-read');
    const { idArr, notificationCategory } = action.payload;
    //Note : activityFeedRefactoring(featureFlag) is taken as true for helper functions to get the respective results
    const activityFeedRefactoring = true;

    const filterPreference = yield select((state: RootState) => {
      return state.userPreferenceData.userGridPreferences.find(item => item.id === filterPreferencesId);
    });
    const appliedFilters = filterPreference?.value.filtersApplied;
    const availableFilters = getSelectedFiltersBasedOnTab(
      notificationCategory,
      appliedFilters,
    );

    const userInfo = yield select(selectUser);

    const payload = payloadWithFilters(
      availableFilters,
      notificationCategory,
      userInfo,
      1,
    );
    const updateReadStatusBody = bulkMarkNotificationAsReadPayload(idArr, getEmployeeId(userInfo?.userInfo), payload);
    yield call(sendNotificationReadStatus, {
      type: taskDetailsActions.sendNotificationReadStatus.type,
      payload: updateReadStatusBody,
    });
    //Update the count in header again
    yield call(initNotifications, { type: taskDetailsActions.businessInitNotifications.type, payload: null });
    //Update the Data in Notification Again
    yield call(getNotificationLogData, { type: taskDetailsActions.getNotificationLogData.type, payload: payload })
    manuallyDecrementPromiseCounter('notification-bulk-read');
  } catch (error) {
    manuallyResetPromiseCounter('notification-bulk-read');
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'handleBulkMarkRead',
        area: 'src/app/components/Tasks/store/Tasks.business_saga.ts',
      },
    });
    throw new Error('Error in - handleBulkMarkRead');
  }
}
