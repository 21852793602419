import { TimeOffRequest } from '@AMIEWEB/Common/TimeOffRequest';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export const CreateTimeOffRequest = () => {
  const { control } = useFormContext();
  return (
    <Controller
      name="requestedTimeOff"
      control={control}
      rules={{ required: 'Required' }}
      render={({ ref, ...rest }) => <TimeOffRequest {...rest} orientation="horizontal" />}
    />
  );
};
