import React from 'react';
import styledComponent from 'styled-components';

export const RowContainer = props => (
  <table style={{ width: '100%' }}>
    <tbody style={{ width: '100%' }}>{props.children}</tbody>
  </table>
);

export const Row = styledComponent.tr`
  display: flex;
  justify-content: space-between;
`;

export const RowHead = styledComponent.th`
  color: #333333;
  font-size: 14px;
  text-align: left;
  font-weight: 400;
`;

export const RowContent = styledComponent.td`
  color: #333333;
  font-size: 14px;
  text-align: end;
  font-weight: 600;
`;
