/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { TextField, Grid, CircularProgress, ClickAwayListener } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { EDateRangePickTabbingIDs } from 'app/ComponentLibrary/DateRangeNew/types';
import LockIcon from '@mui/icons-material/Lock';
import { CustomTooltip } from '@AMIEWEB/Common';
import { useTranslation } from 'react-i18next';
import { missingField } from 'app/constants';

const useStyles = makeStyles()(theme => ({
  textareastyle: {
    padding: '0px',
    overflowWrap: 'break-word',
    backgroundColor: 'transparent !important',
  },
  inlineBoxEditItemPadding: {
    //padding: '0 0.4rem',
  },
  inlineBoxEditMainAcceptItem: {
    paddingRight: '0.2rem',
    '& svg': {
      color: '#006FB9',
      width: '20px',
      height: '20px',
      cursor: 'pointer',
    },
  },
  inlineBoxEditMainRejectItem: {
    'padding-left': '0.2rem',
    '& svg': {
      color: '#8f9a8e',
      width: '20px',
      height: '20px !important',
      cursor: 'pointer',
    },
  },
  inlineBoxEditMainDisabledItem: {
    'padding-left': '0.2rem',
    '& svg': {
      color: '#8f9a8e',
      width: '20px',
      height: '20px !important',
    },
  },
  inlineBoxEditInputItem: {
    width: '90px',
    'border-top': '0',
    'border-left': '0',
    'border-right': '0',
    'border-bottom-color': '#bababa',
    '&:focus-visible': {
      outline: 'none',
      background: '#f2f2f2',
    },
  },
  inlineBoxEditInputText: {
    width: '120px',
    'border-top': '0',
    'border-left': '0',
    'border-right': '0',
    'border-bottom-color': '#bababa',
    '&:focus-visible': {
      outline: 'none',
      background: '#f2f2f2',
    },
  },
  saveSuccessText: {
    fontSize: '11px',
    paddingLeft: '4px',
    justifyContent: 'center',
    color: '#008000',
  },
  saveFailedText: {
    fontSize: '11px',
    justifyContent: 'center',
    color: '#ff0000',
  },
  tooltip: {
    maxWidth: 'fit-content',
    padding: '0px 0px',
    backgroundColor: 'white',
    borderRadius: '5%',
  },
  popper: {
    zIndex: 9999,
  },
  dateRangeContainer: {
    transform: 'translate(-5px, 0)',
    borderBottom: '1px solid transparent',
    '&:hover': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    },
  },
  disabledContainer: {
    '& #disableIcon': {
      display: 'none',
    },
    '&:hover': {
      '& #disableIcon': {
        display: 'block',
      },
      '& #disabledText': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
      },
    },
  },
  disabledText: {
    width: '100px',
    color: '#333333',
    fontSize: '14px',
    borderBottom: '1px solid transparent',
  },
}));

interface InlineEditTextProps {
  data: any;
  name: any;
  saveStatus: any;
  textInput?: React.Ref<any>;
  type?: string;
  isEditable?: boolean;
  validationErrorText?: boolean;
  isHandleClick?: boolean;
  disabled?: boolean;
  setIsHandleClick?: (value: boolean) => void;
  handleChangeValue?: (property: string, value: any) => void;
  handleSave?: (property?: string) => void;
  reset?: () => void;
  customClasses?: any;
}

enum FieldTypes {
  text = 'text',
  date = 'date',
}

export const InlineTextInput = (props: InlineEditTextProps) => {
  const {
    data,
    name,
    saveStatus,
    type,
    isEditable,
    validationErrorText,
    isHandleClick,
    disabled,
    setIsHandleClick,
    handleChangeValue,
    handleSave,
    reset,
  } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [error, setError] = useState<boolean>(false);
  const uniqueIdRef = React.useRef(Math.random());
  const textFieldRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>();

  const save = () => {
    if (type === FieldTypes.text) {
      handleChangeValue(name, textFieldRef?.current.value || '');
      handleSave(textFieldRef?.current.value || '');
    } else {
      handleSave();
    }
  };

  const close = () => {
    if (isHandleClick) {
      textFieldRef.current.value = data;
      reset?.();
    }
  };

  const handleClick = value => {
    setIsHandleClick(value);
  };

  const TextArea = React.useMemo(() => {
    return (
      <>
        <TextField
          variant="filled"
          multiline
          fullWidth
          id={`${EDateRangePickTabbingIDs.textfield}-${uniqueIdRef?.current}`}
          inputRef={textFieldRef}
          InputProps={{
            className: classes.textareastyle,
            disableUnderline: !isHandleClick,
            readOnly: !isEditable,
          }}
          className={props.customClasses ? props.customClasses : classes.inlineBoxEditInputText}
          InputLabelProps={{
            className: ``,
          }}
          defaultValue={props.data}
          hiddenLabel
          error={validationErrorText}
          type={'string'}
          {...props}
        />
      </>
    );
  }, [isHandleClick, isEditable, data]);

  return (
    <>
      <ClickAwayListener onClickAway={() => close()}>
        <Grid container direction="column" spacing={0}>
          <Grid item>
            <Grid container style={{ justifyContent: type === FieldTypes.text && 'center' }}>
              {disabled ? (
                <Grid item className={classes.disabledContainer}>
                  <Grid container spacing={1}>
                    <Grid item id={'disabledText'} className={classes.disabledText}>
                      {data ? data : missingField}
                    </Grid>
                    <Grid item id={'disableIcon'}>
                      <CustomTooltip tooltipContent={t('alerts.cannotEdit')}>
                        <span
                          className={`${classes.inlineBoxEditItemPadding} ${classes.inlineBoxEditMainDisabledItem}`}
                          aria-hidden="true"
                        >
                          <LockIcon data-testid="lockIcon" />
                        </span>
                      </CustomTooltip>
                    </Grid>
                  </Grid>
                </Grid>
              ) : !data && !isHandleClick ? (
                <Grid item id={'disabledText'} className={classes.disabledText} onClick={() => handleClick(true)}>
                  {missingField}
                </Grid>
              ) : (
                <Grid item onClick={() => handleClick(true)}>
                  <div>{TextArea}</div>
                </Grid>
              )}
              {/* Render only if clicked inside Text field */}
              <Grid item>
                {isHandleClick && saveStatus !== 2 && (
                  <span>
                    {!error && (
                      <span
                        className={`${classes.inlineBoxEditItemPadding} ${classes.inlineBoxEditMainRejectItem}`}
                        onClick={() => {
                          save();
                        }}
                        aria-hidden="true"
                      >
                        <CheckIcon data-testid="saveIcon" />
                      </span>
                    )}
                    <span
                      className={`${classes.inlineBoxEditItemPadding} ${classes.inlineBoxEditMainRejectItem}`}
                      onClick={() => {
                        close();
                      }}
                      onKeyDown={() => {
                        close();
                      }}
                      aria-hidden="true"
                    >
                      <CloseIcon data-testid="closeIcon" />
                    </span>
                  </span>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ paddingLeft: type === FieldTypes.text && '10px' }}>
            {isHandleClick && saveStatus && (
              <>
                {saveStatus === 1 && <CircularProgress style={{ width: '12px', height: '12px' }} />}
                {saveStatus === 2 && (
                  <>
                    <CheckIcon style={{ width: '15px', height: '15px', color: '#008000' }} />
                    <label className={classes.saveSuccessText}>{t('alerts.saved')}</label>
                  </>
                )}
                {saveStatus === 3 && (
                  <>
                    <CloseIcon style={{ width: '15px', height: '15px', color: '#ff0000' }} />
                    <label className={classes.saveFailedText}>{t('alerts.failed')}</label>
                  </>
                )}
                {validationErrorText && (
                  <>
                    <label className={classes.saveFailedText}>{`Character Limit Reached`}</label>
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </ClickAwayListener>
    </>
  );
};
