import { Chip, Grid, Tooltip } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { dates } from '../PersonalInfoHelper';

const useStyles = makeStyles()(theme => ({
  chip: {
    background: '#f7f4f4',
    margin: '5px 5px 5px 5px',
  },
  tooltipArrow: {
    color: '#fff',
    '&::before': {
      border: '1px solid #555555',
    },
  },
  tooltipBackground: {
    backgroundColor: '#fff',
    color: '#555555',
    border: '1px solid #555555',
    fontSize: '12px',
    maxWidth: '150px',
  },
  tooltipLabel: {
    color: '#0F6FB9',
    fontWeight: 500,
  },
}));
export const getCurrent = (fromDate, toDate, moveIn, moveOut) => {
  var todayDate = moment(new Date()).format('MM/DD/YYYY');
  var startDate = fromDate ? moment(fromDate).format('MM/DD/YYYY') : 'N/A';
  var endDate = toDate ? moment(toDate).format('MM/DD/YYYY') : 'N/A';
  return (fromDate === null || dates.lt(startDate, todayDate) || dates.eq(startDate, todayDate)) &&
    (toDate === null || dates.gt(endDate, todayDate))
    ? true
    : false;
};

export const getPrevious = (fromDate, toDate, moveIn, moveOut) => {
  var todayDate = moment(new Date()).format('MM/DD/YYYY');
  var endDate = toDate ? moment(toDate).format('MM/DD/YYYY') : 'N/A';
  return toDate !== null && (dates.lt(endDate, todayDate) || dates.eq(endDate, todayDate)) ? true : false;
};

export const getFuture = (fromDate, toDate, moveIn, moveOut) => {
  var todayDate = moment(new Date()).format('MM/DD/YYYY');
  var startDate = fromDate ? moment(fromDate).format('MM/DD/YYYY') : 'N/A';
  return fromDate !== null && dates.gt(startDate, todayDate) ? true : false;
};

export const BuildRoleTypeChips = props => {
  const { roleTypeItems, fromDate, toDate, moveIn, moveOut } = props;
  const [roleTypeSlice, setRoleTypeSlice] = useState<string>('');
  const [selectedRoles, setSelectedRoles] = useState<any[]>(roleTypeItems);
  const [current, setCurrent] = useState<boolean>(getCurrent(fromDate, toDate, moveIn, moveOut));
  const { classes } = useStyles();

  useEffect(() => {
    setCurrent(getCurrent(fromDate, toDate, moveIn, moveOut));
    if (roleTypeItems) {
      const selected = roleTypeItems.filter(item => item.selected);
      const filteredData = selected.filter((i, idx) => idx === 0 || idx === 1);
      setSelectedRoles(selected);
      setRoleTypeSlice(formatData(filteredData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleTypeItems]);

  const formatData = data => {
    return data.map(x => {
      return `
        ${roleTypeSlice ? `${roleTypeSlice}, ${x.description}` : x.description}`;
    });
  };

  const addToolTip = () => {
    if (selectedRoles) {
      if (selectedRoles.length > 2) {
        const remainingRoles = selectedRoles.filter((i, idx) => idx !== 0 && idx !== 1);
        return (
          <Tooltip
            title={
              <>
                <Grid container direction="row" spacing={2}>
                  {remainingRoles.map((item,idx) => {
                    return (
                      <Grid item key={idx}>
                        <span>{item.description}</span>
                      </Grid>
                    );
                  })}
                </Grid>
              </>
            }
            arrow
            placement="bottom"
            classes={{
              tooltip: classes.tooltipBackground,
              arrow: classes.tooltipArrow,
            }}
          >
            <span className={classes.tooltipLabel}>{`+${selectedRoles.length - 2}`}</span>
          </Tooltip>
        );
      }
    }
    return '';
  };
  return (
    <Grid container spacing={2}>
      {current ? (
        <Chip
          className={classes.chip}
          style={{
            borderColor: '#199AD91A',
            background: '#199AD91A',
          }}
          variant={'outlined'}
          label={
            <span>
              {`${roleTypeSlice} `}
              {addToolTip()}
              {` | ${fromDate ? moment(new Date(fromDate)).format('MM/DD/YYYY') : 'N/A'} - ${
                toDate ? moment(new Date(toDate)).format('MM/DD/YYYY') : 'N/A'
              }`}
            </span>
          }
        />
      ) : (
        <Chip
          className={classes.chip}
          variant={'outlined'}
          label={
            <span>
              {`${roleTypeSlice} `}
              {addToolTip()}
              {` | ${fromDate ? moment(new Date(fromDate)).format('MM/DD/YYYY') : 'N/A'} - ${
                toDate ? moment(new Date(toDate)).format('MM/DD/YYYY') : 'N/A'
              }`}
            </span>
          }
        />
      )}
    </Grid>
  );
};
