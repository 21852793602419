import { Grid, Typography, Skeleton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styledComponent from 'styled-components';
import { PacketCard } from './Cards/PacketCard';
import { CreatePacketBtn } from '@AMIEWEB/Placement/CreatePacket';
import { IPlacementSubmitPacket } from 'store/redux-store/placement-documents/types';
import NoDocumentsToView from 'app/assets/images/IconImages/NoDocumentsToView.svg';

const useStyles = makeStyles()({
  container: {
    paddingTop: 24,
  },
  loadingSkeleton: {
    height: '82px',
    transform: 'unset',
  },
  noContent: {
    padding: 24,
  },
  noContentMsg: {
    textAlign: 'center',
    fontStyle: 'italic',
    marginTop: 18,
  },
});

interface SubmitPacketDialogContentProps {
  packets: IPlacementSubmitPacket[];
  selectedPacket?: IPlacementSubmitPacket;
  onPacketSelected?: (packet: IPlacementSubmitPacket) => void;
  inlinePacketDownload?: (packet: IPlacementSubmitPacket, isDoubleClick: boolean) => void;
  loading?: boolean;
  packetGenLinkProps: { candidateId: number; placementId: number; queryString: { [key: string]: string } };
}

export const SubmitPacketDialogContent = ({
  packets = [],
  selectedPacket,
  onPacketSelected,
  inlinePacketDownload,
  loading,
  packetGenLinkProps,
  ...props
}: SubmitPacketDialogContentProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const packetsContainer = React.useMemo(
    () =>
      !!packets.length ? (
        packets.map((packet, i) => (
          <Grid item xs={6} key={packet.documentId}>
            <PacketCard
              selected={packet.documentId === selectedPacket?.documentId}
              packet={packet}
              onClick={onPacketSelected}
              inlineDownload={inlinePacketDownload}
            />
          </Grid>
        ))
      ) : (
        <Grid container direction="column" classes={{ root: classes.noContent }} spacing={2}>
          <img src={NoDocumentsToView} alt="NoDocumentsToView" />
          <Typography variant="body1" classes={{ root: classes.noContentMsg }}>
            {t('placement.profile.submitPacket.dialog.content.noPacketsText')}
          </Typography>
        </Grid>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inlinePacketDownload, onPacketSelected, packets, selectedPacket?.documentId, t],
  );

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Title variant="body1">{t('placement.profile.submitPacket.dialog.content.initialInstructions')}</Title>
          </Grid>
          <Grid item>
            <CreatePacketBtn linkProps={packetGenLinkProps} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {loading ? (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Skeleton className={classes.loadingSkeleton} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton className={classes.loadingSkeleton} />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            {packetsContainer}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const Title = styledComponent(Typography)`
  color: #676767;
`;
