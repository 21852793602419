import { GridCellParams } from '@mui/x-data-grid-pro';
import { TextField, materialUiXGrid } from 'amn-ui-core';
import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  textField: {
    fontSize: 12,
    width: '100%',
    '& input::placeholder': {
      fontSize: 12,
    },
  },
});

interface EditGridTextProps {
  type: string;
}

export const RenderEditTextCell =
  ({ ...props }: EditGridTextProps) =>
  (params: GridCellParams) => {
    return <EditGritTextCell params={params} props={props} />;
  };

const EditGritTextCell = ({ props, params }: { props: EditGridTextProps; params: GridCellParams }) => {
  const { classes } = useStyles();

  const api = materialUiXGrid.useGridApiContext();
  const [selected, setSelected] = useState<string>(params.value);

  useEffect(() => {
    setSelected(params.value);
  }, [params]);

  const handleEditChange = async event => {
    await api?.current.setEditCellValue({ id: params.id, field: params.field, value: event.target?.value });
    setSelected(event.target?.value);
  };

  return (
    <div style={{ width: '100%' }}>
      <TextField
        variant={'outlined'}
        size={'small'}
        type={props.type}
        className={classes.textField}
        onChange={event => {
          handleEditChange(event);
        }}
        value={selected === 'N/A' ? '' : selected}
      />
    </div>
  );
};
