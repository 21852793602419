import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import styledComponent from 'styled-components';
import { useFormContext, Controller } from 'react-hook-form';
import { GetClientSettingOptions } from './FormatData';
import { MultiSelectTypeAhead } from '../../../../Common/TypeAheads/MultiSelectTypeAhead';
import { useSelector } from 'react-redux';
import { selectClientSettingsChosen } from './PreferenceForm/Preference.selectors';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
    marginRight: 0,
    marginLeft: 0,
  },
}));

export const ClientSettings = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { control, watch } = useFormContext();

  const options = GetClientSettingOptions();
  const [label, setLabel] = useState<string>(t('candidate.preferenceForm.clientSettingsLabel1'));

  const value = watch('clientSettings');
  const selectedData = useSelector(selectClientSettingsChosen);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => props.onValueChange(), [value]);

  useEffect(() => {
    props.setFieldValue('clientSettings', selectedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData]);

  return (
    <ContainerDiv className={classes.root} data-testid="container">
      <Title>{t('candidate.preferenceForm.clientSettingsTitle')}</Title>
      <Controller
        render={({ ref, ...rest }) => <MultiSelectTypeAhead options={options} label={label} {...rest} />}
        name="clientSettings"
        defaultValue={selectedData}
        control={control}
        onFocus={() => setLabel(t('candidate.preferenceForm.clientSettingsLabel2'))}
        onBlur={() => setLabel(t('candidate.preferenceForm.clientSettingsLabel1'))}
      />
    </ContainerDiv>
  );
};

const Title = styledComponent(Typography)`
  font-size: 16px !important;
  color: #52429a;
  padding-bottom: 1%;
`;
const ContainerDiv = styledComponent.div`
  display: flex;
  flex-direction: column;
`;
