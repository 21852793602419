import React from 'react';
import { Grid, Skeleton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  avatar: {
    width: '50px',
    height: '50px',
    margin: '10px',
  },
  skeletonHead: { width: '95%', height: '15px', marginTop: '12px', marginBottom: '6px' },
  skeleton: { width: '95%', height: '5px', marginTop: '2px' },
}));

const MessageCardSkeleton = () => {
  const { classes } = useStyles();

  return (
    <Grid container spacing={1} display="flex" flexDirection={'row'} item>
      <Grid item xs={2}>
        <Skeleton variant={'circular'} className={classes.avatar} />
      </Grid>
      <Grid item xs={10}>
        <Skeleton variant={'rectangular'} className={classes.skeletonHead} />
        <Skeleton variant={'rectangular'} className={classes.skeleton} />
        <Skeleton variant={'rectangular'} className={classes.skeleton} />
        <Skeleton variant={'rectangular'} className={classes.skeleton} />
      </Grid>
    </Grid>
  );
};

export default MessageCardSkeleton;
