import { takeLatest, put, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { credentialingDashboardExpiringActions } from './ExpiringCredential.redux';
import { expiringDocumentsByTitle } from '../../../../services/CredentialingServices/CredentialingServices';
import { getMultiSelectOptions } from 'app/ComponentLibrary/Filter/helper';
import { ExpiringFlattenData } from '../Helpers/ExpiringTableHelper';
import { getExpiringPipelineCounts } from '../Helpers/ExpiringCredentialHelpers';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';

export function* requestCredentialingWorkDeskExpiringCredentials(
  action: PayloadAction<{
    userId: number;
    filter?: {
      values: any;
      setValue: (
        name: string,
        value: unknown,
        options?:
          | Partial<{
              shouldValidate: boolean;
              shouldDirty: boolean;
            }>
          | undefined,
      ) => void;
    };
  }>,
) {
  try {
    yield put(credentialingDashboardExpiringActions.setLoading(true));
    const results = yield call(async () => {
      const r = await expiringDocumentsByTitle(
        {
          expiringInDays: -2,
        },
        1,
        1000,
        ['-startDate'],
      );
      return {
        placementByStatusData: r,
      };
    });

    //const responseData = yield call(expiringMockData);

    if (results && results.placementByStatusData) {
      const responseData = results?.placementByStatusData;
      const flattenedData = ExpiringFlattenData(responseData.expiringCredentials);
      //Todo : Need to re-write the logic for getExpiringPipelineCounts
      const counts = getExpiringPipelineCounts(flattenedData);
      const filterSpecs = {
        placementIDOpts: getMultiSelectOptions(responseData.filterSpecs.placementIDOpts),
        candidateNameOpts: getMultiSelectOptions(responseData.filterSpecs.candidateNameOpts),
        credentialsOpts: getMultiSelectOptions(responseData.filterSpecs.credentialsOpts),
        clientNameOpts: getMultiSelectOptions(responseData.filterSpecs.clientNameOpts),
        credentialCategoryOpts: getMultiSelectOptions(responseData.filterSpecs.credentialCategoryOpts),
        credentialGroupOpts: getMultiSelectOptions(responseData.filterSpecs.credentialGroupOpts),
        candidateIdOpts: getMultiSelectOptions(responseData.filterSpecs.candidateIDOpts),
        tierOpts: getMultiSelectOptions([1, 2, 3]),
      };
      yield put(credentialingDashboardExpiringActions.setCounts(counts));
      yield put(credentialingDashboardExpiringActions.setFlattenedData(flattenedData));
      yield put(credentialingDashboardExpiringActions.setFilterSpecs(filterSpecs));
      yield put(credentialingDashboardExpiringActions.setLoading(false));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestCredentialingWorkDeskExpiringCredentials',
        area: 'src/app/components/CredentialingWorkDesk/ExpiringCredentials/Store/ExpiringCredential.saga.ts',
      },
    });
    yield put(credentialingDashboardExpiringActions.setLoading(false));
  }
}

export function* credentialingDashboardExpiringDocumentSaga() {
  yield takeLatest(
    credentialingDashboardExpiringActions.credentialingWorkDeskExpiringData.type,
    requestCredentialingWorkDeskExpiringCredentials,
  );
}
