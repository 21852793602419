/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextField, Box, debounce, Typography, RadioGroup, Grid, FormControlLabel, Radio } from 'amn-ui-core';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectOrderRequirementTypes } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { Controller, useFormContext } from 'react-hook-form';
import { AndOrOperator, AndOrText, CollectionType } from 'app/enums/Common';
import UnitCollapsible from '@AMIEWEB/Unit/Common/UnitCollapsible';
import { UnitLicensesGrid } from './UnitLicensesGrid';
import { getSeparatedLicenseDataByStates, transformExistingLicenseData } from '../LicenseGridHelper';
import { selectLicenseData } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';
import { useUnitGridLicenseWrapperStyles } from './Experience/Experience.styles';
import { IUnitGridLicenseWrapperProps } from '../UnitDetails.types';

export const UnitGridLicensesWrapper = (props: IUnitGridLicenseWrapperProps) => {
  const { skillSetIndex, groupIndex, value: skillSetValue } = props;
  const { t } = useTranslation();
  const { classes } = useUnitGridLicenseWrapperStyles();
  const { states } = useSelector(selectOrderRequirementTypes);
  const licenseData = useSelector(selectLicenseData);
  const { control, setValue, watch } = useFormContext();
  const [licenseRequirements, setLicenseRequirements] = useState<any[] | null>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const existingSkillsetLicenseData = skillSetValue?.collections[CollectionType.LicensesIndex]?.requirements ?? [];

  const skillsetLicenseData = watch(
    `skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].collections[${CollectionType.LicensesIndex}].requirements`,
  );

  const transformedLicenses = useMemo(() => {
    let resultData = [];

    if (skillsetLicenseData?.length) {
      if (
        skillsetLicenseData?.find(license => license.isError?.compactStatus === true || license.isError?.state === true)
      ) {
        setIsError(true);
      } else {
        setIsError(false);
      }
      resultData = getSeparatedLicenseDataByStates(skillsetLicenseData);
    }
    return resultData;
  }, [skillsetLicenseData]);

  useEffect(() => {
    if (licenseData?.length) {
      setLicenseRequirements(getSelectionInfo(''));
    }

    if (existingSkillsetLicenseData && skillSetValue) {
      loadSavedLicenses();
    }
  }, [licenseData]);

  const getSelectionInfo = searchQuery => {
    if (!Array.isArray(licenseData)) return [];
    const searchResult =
      searchQuery && searchQuery.length >= 2
        ? licenseData?.filter(item =>
            `${item?.abbreviation} - ${item?.credentialName}`
              .toLowerCase()
              .replace(/\s|\.|\(|\)/g, '')
              .includes(searchQuery.toLowerCase().replace(/\s|\.|\(|\)/g, '')),
          )
        : licenseData;

    return searchResult.map(item => ({
      id: item.requirementTypeID ?? item?.requirementTypeId,
      abbreviation: item.abbreviation,
      credentialName: item.credentialName,
      isSelected: false,
      requirementTypeId: item.requirementTypeID,
      compactStates: item.compactStates,
      compact: item.compact,
    }));
  };

  const handleSearch = event => {
    (async () => {
      if (Array.isArray(licenseData) && licenseData.length > 0) {
        setLicenseRequirements(getSelectionInfo(event.target.value));
      }
    })();
  };

  const loadSavedLicenses = useCallback(() => {
    const formattedLicenses = transformExistingLicenseData(
      skillSetValue?.isDuplicate,
      existingSkillsetLicenseData,
      states,
      true,
    );

    setValue(
      `skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].collections[${CollectionType.LicensesIndex}].requirements`,
      formattedLicenses,
    );
  }, [skillSetValue?.isDuplicate, existingSkillsetLicenseData]);

  return (
    <>
      <Grid xs={12}>
        <UnitCollapsible
          title={`Licenses: ${transformedLicenses?.length}`}
          showIcon={false}
          children={
            <>
              {transformedLicenses?.length > 0 && (
                <Controller
                  name={`skillSetGroup.groups[${groupIndex}].skillsets.${skillSetIndex}.collections[${CollectionType.LicensesIndex}].operation`}
                  control={control}
                  defaultValue={
                    skillSetValue.collections[CollectionType.LicensesIndex].operation
                      ? skillSetValue.collections[CollectionType.LicensesIndex].operation
                      : AndOrOperator.And
                  }
                  rules={{
                    validate: () => (isError ? 'Required' : undefined),
                  }}
                  render={({ ref, onChange, ...rest }) => (
                    <RadioGroup value={rest.value} aria-label="Required" name={'required'} onChange={onChange} row>
                      <FormControlLabel
                        value={AndOrOperator.And}
                        control={<Radio className={classes.radioStyles} disableRipple disableFocusRipple />}
                        label={AndOrText.And}
                      />
                      <FormControlLabel
                        value={AndOrOperator.Or}
                        control={<Radio className={classes.radioStyles} disableRipple disableFocusRipple />}
                        label={AndOrText.Or}
                      />
                    </RadioGroup>
                  )}
                />
              )}
              <Box className={classes.wrapper}>
                <TextField
                  variant="outlined"
                  color="primary"
                  placeholder={t('order.createUnit.qualifications.placeHolder')}
                  id="txtLicense"
                  autoComplete="on"
                  onChange={debounce(handleSearch, 300)}
                  fullWidth
                  classes={{ root: classes.searchField }}
                  InputProps={{
                    startAdornment: <SearchIcon style={{ opacity: 0.6 }} />,
                    classes: { notchedOutline: classes.noBorder },
                  }}
                />
                <UnitLicensesGrid
                  filteredLicenses={licenseRequirements}
                  skillSetIndex={skillSetIndex}
                  groupIndex={groupIndex}
                />
              </Box>
              {isError && (
                <Typography style={{ color: '#D90000', margin: '5px' }}>{t('order.requiredMessage')}</Typography>
              )}
            </>
          }
        />
      </Grid>
    </>
  );
};
