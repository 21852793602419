import { IGetOptimizelyFlagsAction, IGetOptimizelyFlagVariationAction } from 'app/models/Optimizely/Optimizely';
import axios, { AxiosRequestConfig, CancelToken } from 'axios';
import { baseAxiosInstance } from 'utils/BaseApi';
import { cacheResponse, CacheType, getCachedResponse } from 'utils/networkCache';
import { httpSuccess } from '../serviceHelpers';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { ICreateUserPayload } from '@AMIEWEB/Settings/UserManagement/store/userManagement.redux';

export class SharedService {
  private sharedBase: string;

  private cancellationToken = axios.CancelToken.source();

  private static currentInstance: SharedService;

  config?: AxiosRequestConfig = {};

  private constructor() {
    this.sharedBase = globalThis?.app?.env?.REACT_APP_TRACK_CHANGE_SERVICE_BASE_URL;
  }

  static createInstance(): SharedService {
    const activeInstance = new SharedService();
    // activeInstance.cancellationToken = axios.CancelToken.source();
    // if (activeInstance.config) activeInstance.config.cancelToken = activeInstance.cancellationToken.token;
    return activeInstance;
  }

  static getInstance(): SharedService {
    if (!this.currentInstance) this.currentInstance = this.createInstance();
    return this.currentInstance;
  }

  static cancelActiveRequests() {
    this.currentInstance.cancellationToken.cancel('RequestCancellation');
    // this.currentInstance = null;
  }

  cancelRequests() {
    this.cancellationToken.cancel('RequestCancellation');
    return SharedService.getInstance();
  }

  cancelAndRevokeInstance() {
    this.cancellationToken.cancel('RequestCancellation');
    this.cancellationToken = axios.CancelToken.source();
    if (this.config) this.config.cancelToken = this.cancellationToken.token;
  }

  getOptimizelyFlags = ({ perPage, archived }: IGetOptimizelyFlagsAction) =>
    baseAxiosInstance
      .get(`${this.sharedBase}/optimizely/flags?perPage=${perPage}&archived=${archived}`)
      .then(response => response)
      .catch(error => error.response);

  getOptimizelyFlagVariant = ({ flagKey, perPage, archived }: IGetOptimizelyFlagVariationAction) =>
    baseAxiosInstance
      .get(`${this.sharedBase}/optimizely/flags/${flagKey}/variations?perPage=${perPage}&archived=${archived}`)
      .then(response => response)
      .catch(error => error.response);

  getEmployeeExternalSystemDetails = (employeeId: number) =>
    baseAxiosInstance
      .get(`${this.sharedBase}/users/external-systems/${employeeId}`)
      .then(response => response)
      .catch(err => err.response);

  getPlacementDocument = (docId: string) =>
    baseAxiosInstance.post(`${this.sharedBase}/placement/document/download`, { docId }).catch(err => err.response);

  getPlacementClassicDocument = (documentType: string, documentId: number) =>
    baseAxiosInstance
      .get(`${this.sharedBase}/document/documentType/${documentType}/${documentId}`)
      .catch(err => err.response);

  getDisciplineSpecialtyData = () =>
    baseAxiosInstance
      .get(`${this.sharedBase}/ConfigInfo/disciplinesSpecialtiesSubSpecialtiesList`)
      .catch(err => err.response);

  getAppVersion = () =>
    axios.get(`${globalThis?.app?.env?.REACT_APP_BASE_URL}/appVersion.json`).catch(err => err.response);

  getExceptionsPayRateGww = (excludeOther: boolean) =>
    baseAxiosInstance.get(`${this.sharedBase}/pick-list/gww-exceptions?excludeOther=${excludeOther}`);

  getScreenOptions = async (cacheControl: boolean = true) => {
    try {
      const url = `${this.sharedBase}/search/lookups/screen`;
      if (cacheControl) {
        const cachedData = await getCachedResponse(CacheType.searchOptions, url);
        if (!isNullOrUndefined(cachedData)) {
          return cachedData;
        }
      }
      const response = await baseAxiosInstance.get(url);
      if (cacheControl && httpSuccess(response.status)) {
        cacheResponse(CacheType.searchOptions, url, response);
      }
      return response;
    } catch (error) {
      return err => err.response;
    }
  };

  getUserLists = async data => {
    try {
      const response = await baseAxiosInstance.post(`${this.sharedBase}/users/search`, data);
      return response;
    } catch (error) {
      return (err: { response: any }) => err.response;
    }
  };

  getUserDetails = async data => {
    try {
      const response = await baseAxiosInstance.post(`${this.sharedBase}/users/employee-details-query`, data);
      return response;
    } catch (error) {
      return (err: { response: any }) => err.response;
    }
  };

  getEmployeeRoles = async employeeId => {
    try {
      const response = await baseAxiosInstance.get(`${this.sharedBase}/users/${employeeId}/roles`);
      return response;
    } catch (error) {
      return (err: { response: any }) => err.response;
    }
  };

  getEmployeeAssociations = async employeeId => {
    try {
      const response = await baseAxiosInstance.get(`${this.sharedBase}/users/${employeeId}/associations`);
      return response;
    } catch (error) {
      return (err: { response: any }) => err.response;
    }
  };

  getEmployeeAssociationTypeOptions = async () => {
    try {
      const response = await baseAxiosInstance.get(`${this.sharedBase}/search/lookups/employeeassociationtype`);
      return response.data;
    } catch (error) {
      return (err: { response: any }) => err.response;
    }
  };

  updateUserInformation = async data => {
    try {
      const response = await baseAxiosInstance.post(`${this.sharedBase}/users/employee-details-update`, data);
      return response;
    } catch (error) {
      return (err: { response: any }) => err.response;
    }
  };

  updateUserRoles = async data => {
    try {
      const response = await baseAxiosInstance.post(`${this.sharedBase}/users/roles/update`, data);
      return response;
    } catch (error) {
      return (err: { response: any }) => err.response;
    }
  };

  createNewUser = async (data: ICreateUserPayload) => {
    try {
      const response = await baseAxiosInstance.post(`${this.sharedBase}/users/create-user`, data);
      return response;
    } catch (error) {
      return (err: { response: any }) => err.response;
    }
  };

  updateAssociations = async data => {
    try {
      const response = await baseAxiosInstance.post(`${this.sharedBase}/users/associations/update`, data);
      return response;
    } catch (error) {
      return (err: { response: any }) => err.response;
    }
  };

  getEmployeeInfo = async data => {
    try {
      const response = await baseAxiosInstance.post(`${this.sharedBase}/users/info/slim`, data);
      return response;
    } catch (error) {
      return (err: { response: any }) => err.response;
    }
  };

  getFacilityDrugScreenPolicyOptions = async () => {
    try {
      const response = await baseAxiosInstance.get(`${this.sharedBase}/search/lookups/drugPolicyOptions`);
      return response.data;
    } catch (error) {
      return (err: { response: any }) => err.response;
    }
  };

  getUserPreferences = async () => {
    try {
      const response = await baseAxiosInstance.get(`${this.sharedBase}/users/preferences`);
      if (httpSuccess(response.status))
        response.data = response.data.map(({ value, ...rest }) => ({ ...rest, value: JSON.parse(value) }));
      return response;
    } catch (error) {
      return (err: { response: any }) => err.response;
    }
  };

  saveUserPreferences = async (data, cancelToken: CancelToken) => {
    try {
      const response = await baseAxiosInstance.post(
        `${this.sharedBase}/users/preferences`,
        data.map(({ value, ...rest }) => ({ ...rest, value: JSON.stringify(value) })),
        { cancelToken },
      );
      if (httpSuccess(response.status))
        response.data = response.data.map(({ value, ...rest }) => ({ ...rest, value: JSON.parse(value) }));
      return response;
    } catch (error) {
      return (err: { response: any }) => err.response;
    }
  };

  getR225Report = async data => {
    try {
      const response = await baseAxiosInstance.post(`${this.sharedBase}/recruiter/sales-report`, data);
      return response;
    } catch (error) {
      return error;
    }
  };
  
  getR448Report = async data => {
    try {
      const response = await baseAxiosInstance.post(`${this.sharedBase}/booking/details-report`, data);
      return response;
    } catch (error) {
      return error;
    }
  };
}
