import { Button, ClickAwayListener, Tooltip } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { missingField } from 'app/constants';
import DisabledEditIcon from 'app/assets/images/IconImages/DisabledEditIcon.svg';
import { OverflowTooltip } from '../Tooltips/OverflowTooltip';

const useInlineEditStyles = makeStyles<{
  disabled: boolean;
  alignStart: boolean;
  maxWidth: number;
  isFieldError: boolean;
}>()((theme, { disabled, alignStart, maxWidth, isFieldError }) => ({
  labelBox: {
    border: disabled ? '1px solid #E2E2E2' : isFieldError ? '2px solid #D90000' : '1px solid #E2E2E2',
    padding: '0px 5px',
    minHeight: '25px',
    borderRadius: '4px',
    display: 'flex',
  },
  labelBoxHover: {
    '&:hover': {
      border: isFieldError ? '2px solid #D90000' : '1px solid #006FB9',
      background: '#fff',
      '& [data-inline-icon]': {
        color: isFieldError ? '#D90000' : '#006FB9',
      },
    },
    '&:focus': {
      border: isFieldError ? '2px solid #D90000' : '1px solid #006FB9',
      '& [data-inline-icon]': {
        color: isFieldError ? '#D90000' : '#006FB9',
      },
    },
  },
  btnRoot: {
    fontSize: '14px',
    lineHeight: 'inherit',
    fontWeight: 'inherit',
    letterSpacing: 'inherit',
    textTransform: 'none',
    color: disabled ? '#C4C4C4' : isFieldError ? '#D90000' : '#555555',
    minWidth: '20px',
    flexGrow: 1,
    cursor: disabled ? 'not-allowed' : 'pointer',
    textAlign: 'left',
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: alignStart ? 'start' : 'inherit',
  },
  endIcon: {
    marginRight: 0,
    marginLeft: 6,
  },
  labelBoxFitContent: {
    width: 'fit-content',
  },
  editIcon: {
    height: '15px',
    width: '15px',
  },
  maxWidth: {
    maxWidth: !!maxWidth ? `${maxWidth}px` : undefined,
  },
  overflowTooltipHover: {
    '&.Mui-disabled': {
      pointerEvents: 'all',
      backgroundColor: theme.palette.system.white,
    },
  },
  tooltipContentClass: {
    // Above 1280px
    '@media (min-width: 1280px)': {
      maxWidth: 160,
    },
    // Between 1280px and 1060px
    '@media (max-width: 1280px)': {
      maxWidth: 50,
    },
    //Below 1060px
    '@media (max-width: 1060px)': {
      maxWidth: 50,
    },
  },
}));

const InlineEditor = ({
  label,
  Control,
  onChange,
  onCancel,
  inputProps,
  maxWidth,
  editing,
  isFieldError = false,
  fullWidth = true,
  disabled = false,
  alignStart = false,
  setCurrentFocus,
  openOnChange = true,
  overflowTooltip = false,
  ...props
}: {
  label: string;
  Control: any;
  onChange?: (newValue) => void;
  onCancel?: () => void;
  inputProps: { [key: string]: any };
  maxWidth?: number;
  isFieldError?: boolean;
  editing?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  setCurrentFocus?: (state?: null) => void;
  alignStart?: boolean;
  [key: string]: any;
  openOnChange?: boolean;
  overflowTooltip?: boolean;
}) => {
  const { classes, cx } = useInlineEditStyles({ maxWidth, disabled, alignStart, isFieldError });
  const [_editing, _setEditing] = React.useState<boolean | undefined>(false);

  React.useEffect(() => {
    _setEditing(editing);
  }, [editing]);

  const onEdit = () => {
    !disabled && _setEditing(true);
  };

  const _onChange = newValue => {
    onChange?.(newValue);
    _setEditing(openOnChange);
  };

  const _onCancel = () => {
    onCancel?.();
    _setEditing(false);
  };

  return (
    <div
      className={cx({
        [classes.maxWidth]: maxWidth !== undefined,
      })}
    >
      {_editing ? (
        <ClickAwayListener onClickAway={_onCancel}>
          <div>
            <Control {...props} {...inputProps} open={_editing} onChange={_onChange} onCancel={_onCancel} />
          </div>
        </ClickAwayListener>
      ) : (
        <Button
          className={cx(classes.labelBox, {
            [classes.labelBoxFitContent]: !fullWidth,
            [classes.labelBoxHover]: !disabled,
            [classes.overflowTooltipHover]: overflowTooltip,
          })}
          classes={{ root: classes.btnRoot, label: classes.label, endIcon: classes.endIcon }}
          onClick={onEdit}
          onMouseDown={() => setCurrentFocus?.()}
          disableFocusRipple
          disableTouchRipple
          disableRipple
          id={props.id}
          variant="outlined"
          endIcon={
            disabled ? (
              <img src={DisabledEditIcon} alt="Disable Edit" />
            ) : (
              <EditIcon data-inline-icon className={classes.editIcon} color="primary" />
            )
          }
          disabled={disabled}
        >
          {!overflowTooltip ? (
            <>{label !== null && label !== undefined && label !== '' ? label : missingField}</>
          ) : (
            <OverflowTooltip value={label} tooltipContent={label} contentClass={classes.tooltipContentClass} />
          )}
        </Button>
      )}
    </div>
  );
};

const usetooltipStyles = makeStyles()({
  customTooltip: {
    backgroundColor: '#ffffff',
    color: '#0D2A3B',
    opacity: 1,
    textAlign: 'center',
    fontFamily: 'IBM Plex Sans, sans-serif',
    font: 'normal normal normal 12px/20px IBM Plex Sans',
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #8C8C8C',
    marginTop: 5,
  },
  customArrow: {
    color: '#ffffff',
    opacity: 1,
    marginRight: 15,
    '&::before': {
      boxShadow: '0px 3px 6px #00000029',
      border: '1px solid #8C8C8C',
    },
  },
});

export const InlineEditableField = (props: {
  label: string;
  Control: any;
  onChange?: (newValue) => void;
  onCancel?: () => void;
  inputProps: { [key: string]: any };
  maxWidth?: number;
  editing?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  tooltipText?: string;
  setCurrentFocus?: (state?: null) => void;
  openOnChange?: boolean;
  [key: string]: any;
  overflowTooltip?: boolean;
}) => {
  const { disabled = false, tooltipText = '', overflowTooltip = false } = props;
  const { classes } = usetooltipStyles();

  return disabled && !overflowTooltip ? (
    <Tooltip
      title={tooltipText}
      arrow
      classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }}
      placement={'bottom'}
    >
      <div>
        <InlineEditor {...props} />
      </div>
    </Tooltip>
  ) : (
    <InlineEditor {...props} />
  );
};
