import React from 'react';

export const calculateComponentRemainingHeight = (
  wrapperId: string,
  remainingComponentHeight: () => number = () => 0,
  offset: number = 0,
): number | undefined => {
  const a = document.getElementById(wrapperId)?.offsetHeight;
  const b = remainingComponentHeight();
  if (a && b) {
    // subtract offset to account for anything not within the grid wrapper
    const result = a - b - offset;
    return result < 250 ? 250 : result;
  }
  return undefined;
};

/**
 * Custom hook for calculating the height of a child component dynamically.
 * This does not take into account sibling elements changing size, only the screen size
 * @param rowCount count of number of items in row, in order to update height if rows change
 * @param wrapperId DOM Id of component that wrapps the grid (typically home-page-paper or details-page-paper)
 * @param remainingComponentHeight function that calculates the height of any supporting components within the wrapper
 * @param offset offset to subtract from the grid height. Accounts for items outside of the wrapper, like titles/actions
 * @returns JSX.Element
 */
export const useGridHeight = (
  wrapperId: string,
  remainingComponentHeight: () => number = () => 0,
  offset: number = 100,
  rowCount: number = 0,
) => {
  const [gridHeight, setGridHeight] = React.useState<number | undefined>(window.innerHeight / 1.9);

  const updateGridHeight = React.useCallback(() => {
    setGridHeight(calculateComponentRemainingHeight(wrapperId, remainingComponentHeight, offset));
  }, [offset, remainingComponentHeight, wrapperId]);

  const resizeGrid = React.useCallback(
    event => {
      updateGridHeight();
    },
    [updateGridHeight],
  );

  React.useEffect(() => {
    updateGridHeight();
    window.addEventListener('resize', resizeGrid, true);

    return () => {
      window.removeEventListener('resize', resizeGrid);
    };
  }, [rowCount, resizeGrid, updateGridHeight]);

  return { gridHeight };
};
