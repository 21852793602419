import { IRequirementCounts } from 'app/models/Placement/PlacementSummary';

/** Leaf container/nodes  */
export interface IFundamentalContainer {
  label: string;
  /** Prop - used as unique identifier for container */
  key: string;
  counts: IRequirementCounts;
  /** Prop - to access data from requirement container registry */
  containerRegistryKey?: string;
  /** Prop - To identify which should be highlighted as primary container */
  isPrimary?: boolean;
  /** Prop - To decide if filter need to be considered or not */
  excludeFromFilter?: boolean;
}

/** Parent/Brach container/nodes  */
export interface IOperationContainer extends Omit<IFundamentalContainer, 'containerRegistryKey'> {
  operation: Operation;
  nodes: (IOperationContainer | IFundamentalContainer)[];
}

export function instanceOfIOperationContainer(obj: any): obj is IOperationContainer {
  return obj && typeof obj === 'object' && 'operation' in obj && 'nodes' in obj;
}

export enum Operation {
  AND = "&",
  OR = "|",
};