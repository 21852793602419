import { CustomTooltip } from '@AMIEWEB/Common';
import { Grid } from 'amn-ui-core';
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles()(theme => ({
  infoIconStyle: {
    color: theme.palette.components.icons.secondary.backgroundColor,
    height: '12px',
    width: '12px',
  },
  toolTip: {
    maxWidth: '250px',
  },
}));

export const InfoIconToolTip = ({ toolTipContent }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <Grid item sx={{ width: '10px' }}>
      <CustomTooltip
        classes={{ tooltip: classes.toolTip }}
        placement={'top'}
        tooltipContent={t(`${toolTipContent ? toolTipContent : ''}`)}
      >
        <InfoOutlinedIcon classes={{ root: classes.infoIconStyle }} />
      </CustomTooltip>
    </Grid>
  );
};
