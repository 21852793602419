import { DateRangePicker } from 'app/ComponentLibrary/DateRangeNew';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import moment from 'moment';
import { editableFields } from '../../editUtils';
import { useSelector } from 'react-redux';
import { selectEditStatus } from '../store/placementPageAttributes.selector';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';

export const ArrivalDate = () => {
  const { control } = useFormContext();
  const { arrivalDate: status } = useSelector(selectEditStatus);
  const { clearErrors, errors } = useFormContext();

  const value: any = useWatch({ name: 'arrivalDate' });

  return (
    <Controller
      control={control}
      name={editableFields.arrivalDate}
      render={({ ref, onChange, ...rest }) => {
        const arrivalDateValue = moment(value ?? null);
        return (
          <React.Fragment>
            <DateRangePicker
              onChange={newValue => {
                if (newValue.startDate !== undefined) {
                  const newDate = moment(newValue.startDate);
                  const setTo = newDate.isValid() ? moment(newDate).format('YYYY-MM-DDTHH:mm:ss') : null;
                  clearErrors('arrivalDate');
                  onChange(setTo);
                } else {
                  onChange(null);
                }
              }}
              value={value}
              onCancel={() => {}}
              actionBar={false}
              clear
              open={false}
              variant="standard"
              size="inline"
              single
              clickAway
              isDisabled={status?.disabled}
              maxWidth={110}
              showIcon={true}
              isDateIconReq={true}
              quickSelect={false}
              initialDateRange={{
                startDate: arrivalDateValue.isValid()
                  ? new Date(arrivalDateValue.format('YYYY-MM-DDTHH:mm:ss'))
                  : undefined,
                endDate: arrivalDateValue.isValid()
                  ? new Date(arrivalDateValue.format('YYYY-MM-DDTHH:mm:ss'))
                  : undefined,
                useLabelAsValue: true,
              }}
              error={!isNullOrUndefined(errors?.arrivalDate)}
              helperText={!isNullOrUndefined(errors?.arrivalDate) ? errors?.arrivalDate?.message : undefined}
            />
          </React.Fragment>
        );
      }}
    />
  );
};
