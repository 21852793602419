import React, { useMemo, useState, useEffect, useContext } from 'react';
import { CustomTypeAhead, ICustomOption } from '../CustomComponents/CustomTypeAhead';
import { Controller, useFormContext } from 'react-hook-form';
import throttle from 'lodash/throttle';
import { getCandidateOptions } from '../../../../services/CandidateServices/CandidateServices';
import { Concatenate } from 'utils/string/string';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { newPlacementActions } from '../../../../../store/redux-store/new-placement/slice';
import { getSessionValue, SessionKey } from 'utils/customHooks/sessionStorage/sessionHelpers';
import { selectCreatedPlacement } from '../../../../../store/redux-store/new-placement/selectors';
import { createPlacementTabIDs } from '../helper';
import { CreatePlacementContext } from '../CreatePlacementWrapper';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';

export const SearchField = ({
  isDefaultCandidate,
  isDefaultOrder,
  isCandidateRefreshRequest,
  sessionKey,
  ...props
}) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const newPlacementId = useSelector(selectCreatedPlacement);
  const [options, setOptions] = useState<ICustomOption[]>([]);
  const { promiseInProgress } = usePromiseTracker({ area: 'candidate-throttle-area', delay: 0 });
  const [showHistory, setShowHistory] = useState<boolean>(true);
  const dispatch = useDispatch();

  const { setCurrentFocusId } = useContext(CreatePlacementContext);

  const fetchOptions = async request => {
    setShowHistory(false);
    setOptions([]);
    await getCandidateOptions(request)
      .then(res =>
        setOptions(
          res.map(
            opt =>
              ({
                object: opt,
                primaryLabel: `${opt.name} (${opt.candidateId})`,
                secondaryLabel: Concatenate([opt.brand, opt.recruiter, opt.disciplines], ' - '),
              } as ICustomOption),
          ),
        ),
      )
      .catch(error => {
        setOptions([]);
        trackException({
          exception: error,
          properties: {
            name: ExceptionType.APIResponseError,
            functionName: 'fetchOptions',
            area: 'src/app/components/Placement/CreatePlacement/Candidate/CandidateSearch.tsx',
          },
        });
      });
  };

  const throttleService = useMemo(
    () => throttle(request => trackPromise(fetchOptions(request), 'candidate-throttle-area'), 2000),
    [],
  );

  const setSessionOptions = () => {
    setShowHistory(true);
    const recentCandidates = getSessionValue(SessionKey.recentCandidates) || [];
    const newOpts = recentCandidates.map(
      opt =>
        ({
          object: opt,
          primaryLabel: `${opt.name} (${opt.candidateId})`,
          secondaryLabel: Concatenate([opt.brand, opt.recruiter, opt.disciplines?.toString() || ''], ' - '),
        } as ICustomOption),
    );
    setOptions(newOpts);
    return newOpts;
  };

  const autoPopulate = (removeCandidateSelection = false) => {
    const newOpts = setSessionOptions();
    if (newOpts?.length && !removeCandidateSelection) {
      setValue('candidate', newOpts[0], { shouldDirty: true });
      dispatch(
        newPlacementActions.getCandidateAction({
          ...newOpts[0].object,
          useNewCreatePlacementModalFunctions: props.useNewCreatePlacementModalFunctions,
        }),
      );
    } else removeCandidateSelection && dispatch(newPlacementActions.setCandidate(null));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => isDefaultOrder && !isDefaultCandidate && autoPopulate(), []);
  return (
    <Controller
      control={control}
      name="candidate"
      render={({ ref, onChange, ...rest }) => (
        <CustomTypeAhead
          id={createPlacementTabIDs.createPlacementCandidateSearch}
          onInputChange={throttleService}
          options={options}
          loading={promiseInProgress}
          searchOnCharacterCount={3}
          onChange={(event, newValue) => {
            newValue ? dispatch(newPlacementActions.getCandidateAction({ ...newValue.object })) : autoPopulate(true);
            onChange(newValue);
          }}
          label={props.label ?? t('placement.create.popup.selectCandidate')}
          {...rest}
          showHistory={showHistory}
          historyTitle={t('placement.create.dropdown.recentCandidates')}
          resetHistory={() => setSessionOptions()}
          disabled={!isCandidateRefreshRequest && (isDefaultCandidate || newPlacementId !== null)}
          onClick={() => setCurrentFocusId(createPlacementTabIDs.createPlacementCandidateSearch)}
          variant={props.variant}
          size={props.size}
        />
      )}
    />
  );
};
