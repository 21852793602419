import { GridCellParams } from '@mui/x-data-grid-pro';
import React from 'react';
import { Link } from 'amn-ui-core';

interface ICellProps {
  onClick: Function;
  id: string;
}

export const XGridUnitClickCell = (props: ICellProps) => (params: GridCellParams) => {
  const { onClick, id } = props;

  return (
    <Link onClick={() => onClick(params?.row)} id={id}>
      {params?.value}
    </Link>
  );
};
