/* eslint-disable tss-unused-classes/unused-classes */
import { Divider, Grid, Paper } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import styledComponent from 'styled-components';

export const UnitBodyPaperStyle = makeStyles()(() => ({
  paperBackground: {
    background: '#F5F5F5',
  },
}));

export const ReqPrefRadio = makeStyles()(() => ({
  checkboxPadding: {
    padding: 0,
    marginRight: 8,
  },
  checkboxLabel: {
    fontSize: 14,
    paddingLeft: 8,
    paddingRight: 23,
    color: '#333333',
  },
  wrapper: {
    padding: '12px 0px',
  },
}));

export const UnitForm = styledComponent.div`
  padding-bottom: 2%;
`;
export const UnitSection = styledComponent(Paper)`
  border: 1.75px solid #cccccc;
  background: #fcfcfd;
`;

export const UnitBody = styledComponent(Grid)`
  padding-top: 2%;
  padding-bottom: 1%;
  padding-right: 1%;
  padding-left: 1%;
`;

export const UnitDetailsBody = styledComponent(Paper)`
  padding: 12px;
`;

export const UnitCardsTitle = styledComponent.div`
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  padding-right: 23px;
`;

export const UnitCardsSubTitle = styledComponent.div`
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  padding-top: 2px;
`;

export const ValidationErrorMessage = styledComponent.div`
  color: #d90000;
  font-size: 12px;
  padding-left: 1%;
  width: max-content;
`;
export const InstructionText = styledComponent.span`
  color: #75787b;
  font-size: 12px;
  font-style: italic;
`;

export const InstructionTextWarning = styledComponent.span`
  color: red;
  font-size: 12px;
  font-style: italic;
`;

export const CustomDivider = styledComponent(Divider)`
  opacity: 1;
  border: 1px solid #d1d1d1;
`;

export const UnitTitle = styledComponent.div`
  color: #555555;
  height: 26px;
  font-size: 20px;
  margin-left: 0;
  margin-bottom: 14px;
`;
