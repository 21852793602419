import { Grid } from 'amn-ui-core';
import { ShiftResponse } from 'app/models/Orders/OrderDetails';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderDetailsCard } from './OrderDetailsCard';

import { makeStyles } from 'tss-react/mui';
import { getOrderDetailsShiftData } from '../helper';
import { ShiftsAvailableContainer } from 'app/components/Order/OrderCreationForm/Dates/ShiftsAvailableContainer';
import { formatShiftInputData } from 'app/components/Order/OrderCreationForm/helper';
import { ff_order_ui_rate_card } from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { getUniqueShiftDetailsId } from '@AMIEWEB/Order/OrderCreationForm/RateCard/helper';
import { selectUser } from 'oidc/user.selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
  createOrderOcbrActions,
  createOrderOcbrReducer,
  createOrderOcbrSliceKey,
} from 'store/redux-store/create-order-ocbr/slice';
import { createOrderOcbrSaga } from 'store/redux-store/create-order-ocbr/saga';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';

type FreeTextData = {
  data: { shifts: ShiftResponse[] };
  disableEdit?: boolean;
  onUpdate: (itemKey, value) => void;
  facilityId?: number;
  orderId?: number;
};

const useStyles = makeStyles()({
  dialog: {
    maxWidth: '700px',
  },
});

export const OrderDetailsShifts = ({ data, onUpdate, disableEdit, facilityId, orderId, ...props }: FreeTextData) => {
  useInjectReducer({ key: createOrderOcbrSliceKey, reducer: createOrderOcbrReducer });
  useInjectSaga({ key: createOrderOcbrSliceKey, saga: createOrderOcbrSaga });
  const { classes, cx } = useStyles();
  const { shifts } = data;
  const { t } = useTranslation();
  const [value, setValue] = useState<any>(data);
  const [openModel, setOpenModal] = useState<boolean>(false);
  const [rateCardFlag] = useDecision(ff_order_ui_rate_card);
  const userData = useSelector(selectUser);
  const dispatch = useDispatch();
  const userInfo = {
    userId: userData.userInfo?.employeeId,
    userName: userData.userInfo?.firstName + ' ' + userData.userInfo?.lastName,
  };

  const onOrderPropertyChange = (property: string, value: any) => {
    setValue({ ...data, [property]: value });
  };

  const onEditClick = () => {
    setOpenModal(true);
  };

  const onCancel = () => {
    setOpenModal(false);
    setValue(data);
  };

  const _onUpdate = () => {
    onUpdate?.('shifts', value.shifts || []);

    const updatedShifts = value.shifts;
    if (rateCardFlag.enabled) {
      const payload = {
        orderId: orderId,
        shiftDetails: updatedShifts,
        uniqueShiftDetails: getUniqueShiftDetailsId(updatedShifts),
        currentUserId: userInfo.userId,
        currentUserName: userInfo.userName,
      };

      dispatch(createOrderOcbrActions.updateOrderShifts(payload));
    }

    setOpenModal(false);
  };

  return (
    <Grid item xs={12}>
      <OrderDetailsCard
        title={t('order.orderDetails.sections.shifts.title')}
        edit={{ disableEdit: disableEdit, onEditClick: onEditClick }}
        id={'OrderShiftDetailsLbl'}
      >
        {getOrderDetailsShiftData(shifts)}
      </OrderDetailsCard>
      <GenericDialog
            open={openModel}
            disableEscapeKeyDown
            maxWidth="sm"
            fullWidth
            dialogTitleProps={{
              text: t('order.orderDetails.sections.shifts.editTitle'),
            }}
            dialogActions={[
              {
                text: t('order.orderDetails.freeText.dialogCancelButton'),
                variant: 'text',
                color: 'tertiary',
                onClick: onCancel,
              },
              {
                text: rateCardFlag.enabled
                ? t('order.orderDetails.freeText.dialogSaveButton')
                : t('order.orderDetails.freeText.dialogUpdateButton'),
                variant: 'contained',
                color: 'primary',
                onClick: _onUpdate,
              },
            ]}
          >
             <ShiftsAvailableContainer
                listWidth={12}
                chipLocation="bottom"
                checkCreateOrder={false}
                defaultInputShiftList={(data && data.shifts.map(item => formatShiftInputData(item.description))) || []}
                onOrderPropertyChange={onOrderPropertyChange}
                disableEdit={false}
              />
         </GenericDialog>
    </Grid>
  );
};
