import { Button } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles/styleVariables';
import { isOverflown } from 'app/helpers/componentHelpers';
const useStyles = makeStyles<{ maxHeight: string | number }>()((theme, { maxHeight }) => ({
  content: { overflow: 'hidden', textOverflow: 'ellipsis', maxHeight: maxHeight },
  contentTransition: { transition: 'max-height 4s' },
  button: {
    fontSize: 12,
    color: colors.amnBlue,
    textTransform: 'none',
    '&:hover, &:focus': { background: 'none' },
    padding: '6px 0',
  },
  paper: { padding: 12, fontSize: 12 },
}));

interface ITextOverFlowProps {
  value: any;
  maxHeight: number | undefined;
}
export const TextOverFlow: React.FC<ITextOverFlowProps> = ({ value, maxHeight }) => {
  const { t } = useTranslation();
  const anchorElRef = React.useRef<any>(null);
  const [open, setOpen] = useState(false);
  const { classes } = useStyles({ maxHeight });
  const [overflown, setOverflown] = React.useState<boolean | null>(false);
  const [showOverflow, setShowOverflow] = React.useState<boolean>(false);
  const validateOverflow = React.useCallback(() => {
    setOverflown(anchorElRef?.current && isOverflown(anchorElRef.current));
  }, []);
  const handleViewBtnClick = () => {
    setShowOverflow(prev => !prev);
  };
  React.useEffect(() => {
    validateOverflow();
    window.addEventListener('resize', validateOverflow);
    return () => {
      window.removeEventListener('resize', validateOverflow);
    };
  }, [validateOverflow, value]);

  return (
    <>
      <div
        ref={anchorElRef}
        className={!showOverflow ? `${classes.content} ${classes.contentTransition}` : classes.contentTransition}
        onMouseEnter={() => {
          setOpen(true);
        }}
      >
        {value}
      </div>
      {overflown ? (
        <Button
          variant="text"
          disableRipple
          disableFocusRipple
          disableTouchRipple
          classes={{ text: classes.button }}
          onClick={handleViewBtnClick}
        >
          {!showOverflow ? t('common.viewMore') : t('common.viewLess')}
        </Button>
      ) : null}
    </>
  );
};
