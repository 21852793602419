/* eslint-disable @typescript-eslint/no-explicit-any */
import { insertUniqueValueInArray } from 'app/helpers/arrayHelpers';
import _ from 'lodash';
import { AES, enc } from 'crypto-js';

export enum SessionKey {
  appVersion = 'appVersion',
  userDetails = 'userDetails',
  recentCandidates = 'recentCandidates',
  recentOrders = 'recentOrders',
  recentFacilities = 'recentFacilities',
  'GlobalSearchGrid-placement' = 'GlobalSearchGrid-placement',
  'GlobalSearchGrid-candidate' = 'GlobalSearchGrid-candidate',
  'GlobalSearchGrid-facility' = 'GlobalSearchGrid-facility',
  'GlobalSearchGrid-order' = 'GlobalSearchGrid-order',
  'GlobalSearchGrid-tasks' = 'GlobalSearchGrid-tasks',
  'GlobalSearchGrid-placementSave' = 'placementStickyFiltersSave',
  'GlobalSearchGrid-candidateSave' = 'candidateStickyFiltersSave',
  'GlobalSearchGrid-facilitySave' = 'facilityStickyFiltersSave',
  'GlobalSearchGrid-orderSave' = 'orderStickyFiltersSave',
  searchString = 'searchString',
  createPlacementMFS = 'createPlacementMFS',
  createPlacementNotifications = 'createPlacementNotifications',
  taskSidePanel = 'taskSidePanel',
  globalSearchReset = 'globalSearchReset',
  tempColumnOrder = 'tempColumnOrder',
  cacheBuster = 'cacheBuster',
  newPlacementData = 'newPlacementData',
  pageLoadTime = 'pageLoadTime',
}

export interface Session {
  count: number;
  content: { [key: string]: any };
}
export enum StorageType {
  localStorage,
  sessionStorage,
}

const defaultSession: Session = { count: 0, content: {} };
const storageKey = 'amie-session';
const secretKey = 'amie-session-encrypt';

/**
 * Retrieve the entire session object along with how many tabs/windows are open
 * @param storageType the type of storage to put the value in. sessionStorage or localStorage. Defaults to localStorage
 * @returns Session object with session count and content
 */
export const getSession = (storageType: StorageType = StorageType.localStorage): Session => {
  let session = defaultSession;
  const a = (storageType === StorageType.sessionStorage ? sessionStorage : localStorage).getItem(storageKey);
  if (a?.startsWith('U2F')) {
    let bytes = AES.decrypt(a, secretKey);
    session = JSON.parse(bytes.toString(enc.Utf8));
  } else if(a){
    session = JSON.parse(a);
  }
  return _.cloneDeep(session);
};

export const resetSession = (storageType: StorageType = StorageType.localStorage) => {
  let encrypted = AES.encrypt(JSON.stringify(defaultSession), secretKey).toString();
  (storageType === StorageType.sessionStorage ? sessionStorage : localStorage).setItem(
    storageKey,
    encrypted,
  );
};

/**
 * Retrieve session value for given key
 * @param key key to retrieve from session storage
 * @param storageType the type of storage to put the value in. sessionStorage or localStorage. Defaults to localStorage
 * @returns Object for given key
 */
export const getSessionValue = (
  key: SessionKey,
  storageType: StorageType = StorageType.localStorage,
): any | undefined | null => {
  const session = getSession(storageType);
  return session.content[key];
};

/**
 * Internal use only
 */
export const incrementSession = (storageType: StorageType = StorageType.localStorage) => {
  const session = getSession(storageType);
  session.count += 1;
  let encrypted = AES.encrypt(JSON.stringify(session), secretKey).toString();
  (storageType === StorageType.sessionStorage ? sessionStorage : localStorage).setItem(
    storageKey,
    encrypted,
  );
};

/**
 * Internal use only
 */
export const decrementSession = (reloaded = false, storageType: StorageType = StorageType.localStorage) => {
  const session = getSession(storageType);
  if (session.count <= 1) session.count = 0;
  else session.count -= 1;
  if (session.count === 0 && !reloaded) session.content = defaultSession.content;
  let encrypted = AES.encrypt(JSON.stringify(session), secretKey).toString();
  (storageType === StorageType.sessionStorage ? sessionStorage : localStorage).setItem(
    storageKey,
    encrypted,
  );
};

/**
 * Set a session variable under the given key
 * @param key unique key to use for the session variable
 * @param object session object. This is the full object so any modification must be done prior to calling this function
 * @param storageType the type of storage to put the value in. sessionStorage or localStorage. Defaults to localStorage
 */
export const setSessionValue = (key: SessionKey, object: any, storageType: StorageType = StorageType.localStorage) => {
  if (object) {
    const currentSession = getSession(storageType);
    currentSession.content[key] = object;
    let encrypted = AES.encrypt(JSON.stringify(currentSession), secretKey).toString();
    (storageType === StorageType.sessionStorage ? sessionStorage : localStorage).setItem(storageKey, encrypted);
  }
};

/**
 * Remove a session variable under the given key
 * @param key unique key to use for the session variable
 * @param storageType the type of storage to put the value in. sessionStorage or localStorage. Defaults to localStorage
 */
export const removeSessionValue = (key: SessionKey, storageType: StorageType = StorageType.localStorage) => {
  const currentSession = getSession(storageType);
  delete currentSession.content[key];
  let encrypted = AES.encrypt(JSON.stringify(currentSession), secretKey).toString();
  (storageType === StorageType.sessionStorage ? sessionStorage : localStorage).setItem(
    storageKey,
    encrypted,
  );
};

/**
 * Set a session variable under the given key, where the value is an Array
 * @param key unique key to use for the session variable
 * @param value session value to be stored
 * @param unique only keep unique values in the array (not implemented)
 * @param limit limit the array storage to the given length
 * @param storageType the type of storage to put the value in. sessionStorage or localStorage. Defaults to localStorage
 */
export const appendSessionValue = (
  key: SessionKey,
  value: any,
  unique?: boolean,
  limit?: number,
  storageType?: StorageType,
) => {
  try {
    if (value) {
      const currentSession = getSession(storageType);
      let currentValue = insertUniqueValueInArray(currentSession.content[key], value);
      if (limit) currentValue = currentValue.slice(0, limit);
      currentSession.content[key] = currentValue;
      let encrypted = AES.encrypt(JSON.stringify(currentSession), secretKey).toString();
      (storageType === StorageType.sessionStorage ? sessionStorage : localStorage).setItem(
        storageKey,
        encrypted,
      );
    }
  } catch (error) {}
};
