import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';

export interface INavigationProfiles {
  items: any[];
  navigationFromGrid: boolean;
  rowId: string | undefined;
}

export const initialState: INavigationProfiles = {
  items: [],
  navigationFromGrid: false,
  rowId: undefined,
};

const navigationProfilesSlice = createSlice({
  name: 'navigationProfiles',
  initialState,
  reducers: {
    setNavigationData(state, action: PayloadAction<{ items: any[] }>) {
      state.items = action.payload.items;
    },
    setNavigationFlag(state, action: PayloadAction<{ navigationFromGrid: boolean }>) {
      state.navigationFromGrid = action.payload.navigationFromGrid;
    },
    setCurrentRowId(state, action: PayloadAction<{ rowId: string | undefined }>) {
      state.rowId = action.payload.rowId;
    },
    reset() {
      return { ...initialState };
    },
  },
});

const { actions } = navigationProfilesSlice;

export const { name: navigationProfilesSliceKey, reducer: navigationProfilesReducer } = navigationProfilesSlice;

export const navigationProfilesAction = { ...actions };
