import React, { useState } from 'react';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips';
import { Box } from 'amn-ui-core';
import { useDispatch } from 'react-redux';
import { markBulkNotificationAsRead } from './utils';
import NotificationMarkAsRead from 'app/assets/images/Tasks/NotificationMarkAsRead.svg';
import { useTranslation } from 'react-i18next';
interface MarkAsReadButtonProps {
  selectedCards?: string[];
  selectedCardsFiltered?: string[];
  notificationCategory: string;
  unReadCount: boolean;
  setselectedCards: (e) => void;
}

export const MarkAsReadButton = ({
  selectedCards,
  selectedCardsFiltered,
  setselectedCards,
  notificationCategory,
  unReadCount,
}: MarkAsReadButtonProps) => {
  const dispatch = useDispatch();
  const [BulkReadActive, setBulkReadActive] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleClick = () => {
    if (!!selectedCards?.length && !selectedCardsFiltered.length) {
      return;
    }
    setselectedCards([]);
    markBulkNotificationAsRead({
      idArr: !!selectedCardsFiltered.length ? selectedCardsFiltered : [],
      notificationCategory,
      dispatch,
    });
  };

  return (
    <CustomTooltip
      interactive={true}
      tooltipContent={
        unReadCount
          ? !!selectedCards?.length
            ? t('notification.taskNotification.MarkRead')
            : t('notification.taskNotification.MarkAllRead')
          : ''
      }
    >
      <Box onClick={unReadCount && handleClick}>
        {!unReadCount ? (
          <DraftsOutlinedIcon sx={{ color: 'gray', marginRight: '3px' }} />
        ) : BulkReadActive ? (
          <img
            src={NotificationMarkAsRead}
            style={{ scale: '1.2' }}
            onMouseLeave={() => {
              setBulkReadActive(false);
            }}
          />
        ) : (
          <DraftsOutlinedIcon
            sx={{ color: 'gray', marginRight: '3px' }}
            onMouseEnter={() => {
              setBulkReadActive(true);
            }}
          />
        )}
      </Box>
    </CustomTooltip>
  );
};
