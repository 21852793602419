import { CredentialsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import React from 'react';
import { useSelector } from 'react-redux';
import { TooltipMessage, getCompletionMessage, getCredentialType, getExpirationMessage } from '../../helper';
import { Grid } from 'amn-ui-core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function GetCertificatonTooltip({ certificate }) {
  const credentials = useSelector(CredentialsSelection);
  const { t } = useTranslation();
  const typeId = credentials.certifications.find(x => x.requirementID == certificate.requirementID)?.typeID;
  const type = getCredentialType(typeId);
  const expirationMessage = certificate.expirationDate
    ? getExpirationMessage(certificate, 'cert')
    : t('candidate.qualificationsCard.certificationExpirationMessage');
  const completedMessage = certificate.dateCompleted
    ? getCompletionMessage(certificate, 'cert')
    : t('candidate.qualificationsCard.certificationCompletedMessage');

  return (
    <>
      <Grid container direction="column">
        {(expirationMessage || completedMessage) && (
          <Grid item>
            <Grid container direction="column" style={{ paddingBottom: '10px' }}>
              {expirationMessage && <Grid item>{expirationMessage}</Grid>}
              {completedMessage && expirationMessage !== TooltipMessage.CertificationExpired && (
                <Grid item>{completedMessage}</Grid>
              )}
            </Grid>
          </Grid>
        )}
        <Grid item>
          <span style={{ fontWeight: 'bold' }}>
            {expirationMessage == TooltipMessage.CertificationExpired
              ? t('candidate.qualificationsCard.expiredOn')
              : t('candidate.qualificationsCard.expiresOn')}
          </span>
          {certificate.expirationDate ? moment(certificate.expirationDate).format('MM/DD/YYYY') : `--`}
        </Grid>
        <Grid item>
          <span style={{ fontWeight: 'bold' }}>{t('candidate.qualificationsCard.completedOn')}</span>
          {certificate.dateCompleted ? moment(certificate.dateCompleted).format('MM/DD/YYYY') : `--`}
        </Grid>
        <Grid item>
          <span style={{ fontWeight: 'bold' }}>{'Type: '}</span>
          {type ? type : `--`}
        </Grid>
        <Grid item>
          <span style={{ fontWeight: 'bold' }}>{t('candidate.qualificationsCard.certificateNumber')}</span>
          {certificate.certificationRegistrationNum ? certificate.certificationRegistrationNum : `--`}
        </Grid>
      </Grid>
    </>
  );
}

export default GetCertificatonTooltip;
