import {Paper, Popper, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { XGridDefaultHoverView } from './XGridDefaultHoverView';

interface GridCellExpandProps {
  width: number;
  fullWidth: boolean;
  hoverRenderer?: (value) => JSX.Element;
  forceHoverView?: boolean;
  cellRenderer?: (value) => JSX.Element;
  [key: string]: any;
}

function isOverflown(element: Element): boolean {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

const useStyles = makeStyles()({
  scroll: {
    color: '#555555',
    fontSize: '1rem',
    overflowX: 'hidden',
    overflowY: 'auto',
    maxHeight: '390px',
  },
});

const GridCellExpand = React.memo(function GridCellExpand(props: GridCellExpandProps) {
  const { width, fullWidth } = props;
  const { classes, cx } = useStyles();
  const wrapper = React.useRef<HTMLDivElement | null>(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown =
      isOverflown(cellValue.current!) || Array.isArray(props.value) || (props.forceHoverView ?? false);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent: KeyboardEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  const customHoverRenderer = React.useMemo(() => props.hoverRenderer?.(props) || props.cellRenderer?.(props), [props]);
  const customCellRenderer = React.useMemo(() => props.cellRenderer?.(props), [props]);

  return (
    <div
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={cx({ 'x-grid-centered-cell': props.colDef.align === 'center' })}
      style={{
        width: fullWidth ? '100%' : 'unset',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        position: 'relative',
      }}
    >
      <div
        ref={cellDiv}
        style={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
      <div ref={cellValue} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {customCellRenderer ? customCellRenderer : Array.isArray(props.value) ? props.value.join(', ') : props.value}
      </div>
      {showPopper && (
        <Popper
          placement="right-start"
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          className={classes.scroll}
          style={{
            marginTop: '30px',
            marginLeft: -7,
            maxWidth: '400px',
            overflow: 'auto',
            maxHeight: '363px',
            /** Updated ZIndex for displaying poppers within Generic dialog */
            zIndex: 1150,
          }}
        >
          <Paper
            elevation={1}
            style={{
              minHeight: wrapper.current!.offsetHeight - 3,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {customHoverRenderer || (
              <React.Fragment>
                {Array.isArray(props.value) ? (
                  <ul style={{ paddingRight: 24 }}>
                    {props.value.map((item,idx) => (
                      <li key={idx}>
                        <Typography variant="body2" style={{ padding: 0 }}>
                          {item}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                ) : (
                  XGridDefaultHoverView(props)
                )}
              </React.Fragment>
            )}
          </Paper>
        </Popper>
      )}
    </div>
  );
});

export const TaskXGridOverflowCell = (hoverRenderer?, forceHoverView = false, fullWidth = false) => (
  cellRenderer?,
) => params => {
  return (
    <GridCellExpand
      hoverRenderer={hoverRenderer}
      cellRenderer={cellRenderer}
      width={params.colDef.computedWidth}
      forceHoverView={forceHoverView}
      fullWidth={fullWidth}
      {...params}
    />
  );
};
