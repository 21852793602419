import React from 'react';
import { useStylesWorkHistory } from './Unit.styles';
import { Box, Grid, Skeleton, Typography } from 'amn-ui-core';

const WorkHistoryGapGridSection = ({
  label,
  value,
  isDataLoading,
}: {
  label: string;
  value: string;
  isDataLoading: boolean;
}) => {
  const { classes } = useStylesWorkHistory();

  return (
    <>
      <Grid container item>
        <Box sx={{ width: '150px' }}>
          {isDataLoading ? (
            <Skeleton variant="rectangular" width={131} height={13} />
          ) : (
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              {label}
            </Typography>
          )}
        </Box>
        <Box sx={{ width: '250px' }}>
          {isDataLoading ? (
            <Skeleton variant="rectangular" width={37} height={13} />
          ) : (
            <Typography variant="body1" mt={'-2px'} className={classes.paragraph}>
              {value}
            </Typography>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default WorkHistoryGapGridSection;
