import { ITypeAheadOption } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/PreferencesTab/CustomComponents/ControlledTypeAhead';
import { GridColDef } from '@mui/x-data-grid-pro';
import { IAdult, IChild, IPet } from 'app/models/Candidate/CandidateProfile';
import { TFunction } from 'i18next';
import moment from 'moment';

export interface IPreferenceAdultsRow {
  rowID: number | null;
  name: string | null;
  relationship: string | null;
  isSelected: boolean;
};

export interface IPreferenceChildrenRow {
  rowID: number | null;
  gender: string | null;
  age: number | string | null;
  isSelected: boolean;
};

export interface IPreferencePetsRow {
  rowID: number | null;
  type: string | null;
  breed: string | null;
  weight: number | string | null;
  details: string | null;
  isSelected: boolean;
};

export interface IPreferenceGridRow {
  adults: IPreferenceAdultsRow[],
  children: IPreferenceChildrenRow[],
  pets: IPreferencePetsRow[];
}

export const defaultValues = {
  housingType: null,
  furnitureExtras: [],
  typeOfBed: [],
  keyFacts: [],
};

export enum FormValues {
  housingType = 'housingType',
  furnitureExtras = 'furnitureExtras',
  typeOfBed = 'typeOfBed',
  keyFacts = 'keyFacts',
}

export enum GridType {
  adults = 'adults',
  children = 'children',
  pets = 'pets',
}

enum ColumnFields {
  name = 'name',
  relationship = 'relationship',
  gender = 'gender',
  age = 'age',
  type = 'type',
  breed = 'breed',
  weight = 'weight',
  details = 'details',
  fill = 'fill',
};

enum GridMessages {
  no_records = 'No Records Found',
  verified_no_adults = 'Verified No Adults',
  verified_no_children = 'Verified No Children',
  verified_no_pets = 'Verified No Pets',
};

enum Gender {
  male = 'Male',
  female = 'Female',
}

export const dropdownOptionFormat = (options: { value: string; name: string }[]) => {
  if (options) {
    const newOptions = options.map(item => {
      return {
        label: item.name,
        object: {
          ID: item.value,
          Description: item.name,
        },
      };
    });
    return newOptions;
  }
  return [];
};

export const flattenAdultsData = (data: IAdult[], isVerifiedNoAdults: boolean) => {
  if (data?.length > 0 && !isVerifiedNoAdults) {
    return data.map(item => {
      return {
        rowID: item.id,
        name: item.name,
        relationship: item.relationship,
        isSelected: false,
      }
    })
  } else {
    return [{
      rowID: 0,
      name: GridMessages.verified_no_adults,
      relationship: '',
      isSelected: isVerifiedNoAdults,
    }]
  }
};

export const flattenChildrenData = (data: IChild[], isVerifiedNoChildren: boolean) => {
  if (data?.length > 0 && !isVerifiedNoChildren) {
    return data.map(item => {
      return {
        rowID: item.id,
        gender: item.gender,
        age: item.birthYear ? (moment().year() - parseInt(item.birthYear.toString())) : null,
        isSelected: false,
      }
    })
  } else {
    return [{
      rowID: 0,
      gender: GridMessages.verified_no_children,
      age: '',
      isSelected: isVerifiedNoChildren,
    }]
  }
};

export const flattenPetsData = (data: IPet[], isVerifiedNoPets: boolean) => {
  if (data?.length > 0 && !isVerifiedNoPets) {
    return data.map(item => {
      return {
        rowID: item.id,
        type: item.type,
        breed: item.breed,
        weight: item.weight,
        details: item.details,
        isSelected: false,
      }
    })
  } else {
    return [{
      rowID: 0,
      type: GridMessages.verified_no_pets,
      breed: '',
      weight: '',
      details: '',
      isSelected: isVerifiedNoPets,
    }]
  }
};

export const formatTypeAhead = (data: ITypeAheadOption[]) => {
  return data?.map(item => {
    return {
      id: item.object.ID,
      description: item.object.Description,
      isUIDeleted: false,
    };
  })
};

export const formatAdultsData = (data: IPreferenceAdultsRow[], existingValues: IAdult[]) => {
  const deletedItems = existingValues.filter(item => !data.find(x => x.rowID === item.id)).map(item => {
    return {
      ...item,
      isUIDeleted: true,
    }
  });
  const saveItems = data.filter(
    item => item.name !== null && item.relationship !== null && item.rowID > 0).map(item => {
    return {
      id: existingValues.find(x => x.id === item.rowID) ? item.rowID : 0,
      name: item.name?.slice(0, 255),
      relationship: item.relationship?.slice(0, 255),
      isUIDeleted: false,
    }
  });
  return [...saveItems, ...deletedItems];
};

export const formatChildrenData = (data: IPreferenceChildrenRow[], existingValues: IChild[]) => {
  const deletedItems = existingValues.filter(item => !data.find(x => x.rowID === item.id)).map(item => {
    return {
      ...item,
      isUIDeleted: true,
    }
  });
  const saveItems = data.filter(
    item => item.gender !== null && parseInt(item.age?.toString()) >= 0 && item.rowID > 0)?.map(item => {
    return {
      id: existingValues.find(x => x.id === item.rowID) ? item.rowID : 0,
      gender: item.gender,
      birthYear: (moment().subtract({ years: parseInt(item.age.toString()) }).year()),
      isUIDeleted: false,
    }
  });
  return [...saveItems, ...deletedItems];
};

export const formatPetsData = (data: IPreferencePetsRow[], existingValues: IPet[]) => {
  const deletedItems = existingValues.filter(item => !data.find(x => x.rowID === item.id)).map(item => {
    return {
      ...item,
      isUIDeleted: true,
    }
  });
  const saveItems = data.filter(item => item.type !== null && item.rowID > 0)?.map(item => {
    return {
      id: existingValues.find(x => x.id === item.rowID) ? item.rowID : 0,
      type: item.type?.slice(0, 255),
      breed: item.breed?.slice(0, 255),
      weight: item.weight,
      details: item.details?.slice(0, 255),
      isUIDeleted: false,
    }
  });
  return [...saveItems, ...deletedItems];
};

export const getAdultsColumns = (t: TFunction) => {
  const headers: GridColDef[] = [
    {
      field: ColumnFields.name,
      headerName: t('candidate.preferenceCard.name'),
      sortable: false,
      valueFormatter: param => param.value ?? '--',
      flex: 100,
      editable: true,
    },
    {
      field: ColumnFields.relationship,
      headerName: t('candidate.preferenceCard.relationship'),
      sortable: false,
      valueFormatter: param => param.value ?? '--',
      flex: 100,
      editable: true,
    },
    {
      field: ColumnFields.fill,
      headerName: '',
      sortable: false,
      valueFormatter: param => '',
      flex: 300,
    },
  ];
  return headers;
};

export const getChildrenColumns = (t: TFunction) => {
  const headers: GridColDef[] = [
    {
      field: ColumnFields.gender,
      headerName: t('candidate.preferenceCard.gender'),
      sortable: false,
      valueFormatter: param => param.value ?? '--',
      flex: 100,
      editable: true,
      type: "singleSelect",
      valueOptions: [Gender.male, Gender.female],
    },
    {
      field: ColumnFields.age,
      headerName: t('candidate.preferenceCard.age'),
      sortable: false,
      valueFormatter: param => param.value ?? '--',
      flex: 100,
      editable: true,
      type: 'number',
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: ColumnFields.fill,
      headerName: '',
      sortable: false,
      valueFormatter: param => '',
      flex: 300,
    },
  ];
  return headers;
};

export const getPetsColumns = (t: TFunction) => {
  const headers: GridColDef[] = [
    {
      field: ColumnFields.type,
      headerName: t('candidate.preferenceCard.type'),
      sortable: false,
      valueFormatter: param => param.value ?? '--',
      flex: 125,
      editable: true,
    },
    {
      field: ColumnFields.breed,
      headerName: t('candidate.preferenceCard.breed'),
      sortable: false,
      valueFormatter: param => param.value ?? '--',
      flex: 125,
      editable: true,
    },
    {
      field: ColumnFields.weight,
      headerName: t('candidate.preferenceCard.weight'),
      sortable: false,
      valueFormatter: param => param.value ?? '--',
      flex: 125,
      editable: true,
      type: 'number',
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: ColumnFields.details,
      headerName: t('candidate.preferenceCard.details'),
      sortable: false,
      valueFormatter: param => param.value ?? '--',
      flex: 125,
      editable: true,
    },
    {
      field: ColumnFields.fill,
      headerName: '',
      sortable: false,
      valueFormatter: param => '',
      flex: 200,
    },
  ];
  return headers;
};
