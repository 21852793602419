import { requirementType } from 'app/models/Placement/PlacementSummary';
import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import GreenCheck from 'app/assets/images/IconImages/GreenCheckCircleIcon.svg';
import OrangeWarning from 'app/assets/images/IconImages/OrangeWarningIcon.svg';
import RedWarning from 'app/assets/images/IconImages/RedWarningIcon.svg';
import { CustomTooltip } from '@AMIEWEB/Common';

export function isInstanceOfExtensionStatus(obj: any): obj is { extensionStatusId: number; extensionStatus: string } {
  return obj && typeof obj === 'object' && 'extensionStatusId' in obj && 'extensionStatus' in obj;
}

export const getRequirementCheck = customSwitch({
  [requirementType.pass]: <img src={GreenCheck} alt="Pass" />,
  [requirementType.fail]: <img src={RedWarning} alt="Fail" />,
  [requirementType.risk]: <img src={OrangeWarning} alt="Risk" />,
})(null);

export const getRequirementTooltip = (img: JSX.Element, content: string) => (
  <CustomTooltip isInlineContent tooltipPlacementBottom={true} tooltipContent={content}>
    {img}
  </CustomTooltip>
);

export const useRequirementPromiseTracker = () => {
  const { promiseInProgress: skillset } = usePromiseTracker({
    area: 'placement-submission-skillset-requirement',
    delay: 0,
  });
  const { promiseInProgress: status } = usePromiseTracker({
    area: 'placement-submission-status-amn-requirement',
    delay: 0,
  });
  const { promiseInProgress: audit } = usePromiseTracker({
    area: 'placement-submission-audit-amn-requirement',
    delay: 0,
  });
  const { promiseInProgress: reference } = usePromiseTracker({
    area: 'placement-submission-reference-amn-requirement',
    delay: 0,
  });
  const { promiseInProgress: application } = usePromiseTracker({
    area: 'placement-submission-application-amn-requirement',
    delay: 0,
  });
  const { promiseInProgress: orderStatus } = usePromiseTracker({
    area: 'placement-submission-status-order-requirement',
    delay: 0,
  });
  const { promiseInProgress: orderDates } = usePromiseTracker({
    area: 'placement-submission-dates-order-requirement',
    delay: 0,
  });
  const { promiseInProgress: orderLicense } = usePromiseTracker({
    area: 'placement-submission-license-order-requirement',
    delay: 0,
  });
  const { promiseInProgress: orderCertification } = usePromiseTracker({
    area: 'placement-submission-certification-order-requirement',
    delay: 0,
  });
  const { promiseInProgress: orderRadius } = usePromiseTracker({
    area: 'placement-submission-miles-radius-order-requirement',
    delay: 0,
  });
  const { promiseInProgress: orderCovid } = usePromiseTracker({
    area: 'placement-submission-covid-order-requirement',
    delay: 0,
  });
  const { promiseInProgress: skillsChecklist } = usePromiseTracker({
    area: 'placement-submission-skills-checklist-requirement',
    delay: 0,
  });
  const { promiseInProgress: orderDob } = usePromiseTracker({
    area: 'placement-submission-DOB-order-requirement',
    delay: 0,
  });
  const { promiseInProgress: orderSsn } = usePromiseTracker({
    area: 'placement-submission-Ssn-order-requirement',
    delay: 0,
  });
  const { promiseInProgress: workHistory } = usePromiseTracker({
    area: 'placement-submission-work-history-submission-requirement',
    delay: 0,
  });
  const { promiseInProgress: arbitrationAgreement } = usePromiseTracker({
    area: 'arbitartion-agreement-requirement',
    delay: 0,
  });

  return (
    skillset ||
    status ||
    audit ||
    reference ||
    application ||
    orderStatus ||
    orderDates ||
    orderLicense ||
    orderCertification ||
    orderRadius ||
    orderCovid ||
    skillsChecklist ||
    orderDob ||
    orderSsn ||
    workHistory || arbitrationAgreement
  );
};
