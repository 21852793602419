/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { TextField, Grid, CircularProgress, ClickAwayListener } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { DateRangePicker as DateRangeNew } from 'app/ComponentLibrary/DateRangeNew';
import moment from 'moment';
import { EDateRangePickTabbingIDs } from 'app/ComponentLibrary/DateRangeNew/types';
import LockIcon from '@mui/icons-material/Lock';
import { CustomTooltip } from '../Tooltips/CustomTooltip';
import { useTranslation } from 'react-i18next';
import { missingField } from '../../Notification/MultiChannel/Sms/CredentialingAnalyst/PlacementDisplay';
import { DefinedRange } from 'app/ComponentLibrary/DateRange/types';
import { defaultDateRange } from './Defaults';

const useStyles = makeStyles()(theme => ({
  saveIcon: {
    '&:hover': {
      color: 'green',
    },
  },
  closeIcon: {
    '&:hover': {
      color: 'red',
    },
  },
  textareastyle: {
    padding: '0px',
    overflowWrap: 'break-word',
    backgroundColor: 'transparent !important',
  },
  inlineBoxEditItemPadding: {
    padding: '0 1px',
  },
  inlineBoxEditMainAcceptItem: {
    'padding-right': '0.2rem',
    '& svg': {
      color: '#006FB9',
      width: '20px',
      height: '20px',
      cursor: 'pointer',
    },
  },
  inlineBoxEditMainRejectItem: {
    'padding-left': '0.2rem',
    '& svg': {
      color: '#8f9a8e',
      width: '20px',
      height: '20px !important',
      cursor: 'pointer',
    },
  },
  inlineBoxEditMainDisabledItem: {
    'padding-left': '0.2rem',
    '& svg': {
      color: '#8f9a8e',
      width: '20px',
      height: '20px !important',
    },
  },
  inlineBoxEditInputItem: {
    width: '90px',
    'border-top': '0',
    'border-left': '0',
    'border-right': '0',
    'border-bottom-color': '#bababa',
    '&:focus-visible': {
      outline: 'none',
      background: '#f2f2f2',
    },
  },
  inlineBoxEditInputText: {
    width: '320px',
    'border-top': '0',
    'border-left': '0',
    'border-right': '0',
    'border-bottom-color': '#bababa',
    '&:focus-visible': {
      outline: 'none',
      background: '#f2f2f2',
    },
  },
  saveSuccessText: {
    fontSize: '11px',
    paddingLeft: '4px',
    justifyContent: 'center',
    color: '#008000',
  },
  saveFailedText: {
    fontSize: '11px',
    justifyContent: 'center',
    color: '#ff0000',
  },
  validationError: {
    paddingLeft: '20px',
  },
  tooltip: {
    maxWidth: 'fit-content',
    padding: '0px 0px',
    backgroundColor: 'white',
    borderRadius: '5%',
  },
  popper: {
    zIndex: 9999,
  },
  dateRangeContainer: {
    transform: 'translate(-5px, 0)',
    borderBottom: '1px solid #D1D1D1',
    '&:hover': {
      borderBottom: '1px solid #B9BBBC',
    },
  },
  disabledContainer: {
    '& #disableIcon': {
      display: 'none',
    },
    '&:hover': {
      '& #disableIcon': {
        display: 'block',
      },
    },
  },
  disabledText: {
    width: '100px',
    color: '#333333',
    fontSize: '14px',
  },
  hiddenText: {
    width: '100px',
    color: '#333333',
    fontSize: '14px',
    borderBottom: '1px solid #D1D1D1',
    '&:hover': {
      borderBottom: '1px solid #B9BBBC',
      cursor: 'text',
    },
  },
}));

interface InlineEditTextProps {
  data: any;
  name: any;
  saveStatus: any;
  textInput?: React.Ref<any>;
  type?: string;
  isEditable?: boolean;
  validationErrorText?: boolean;
  isHandleClick?: boolean;
  disabled?: boolean;
  isHidden?: boolean;
  setIsHandleClick?: (value: boolean) => void;
  handleChangeValue?: (property: string, value: any) => void;
  handleSave?: (property?: string) => void;
  reset?: () => void;
  setIsHidden?: (value: boolean) => void;
  customClasses?: any;
  customDateRangePresets?: (date) => DefinedRange[];
  alignCenter?: boolean;
}

enum FieldTypes {
  text = 'text',
  date = 'date',
}

export const InlineEditText = (props: InlineEditTextProps) => {
  const {
    data,
    name,
    saveStatus,
    type,
    isEditable,
    isHandleClick,
    disabled,
    isHidden,
    setIsHidden,
    setIsHandleClick,
    handleChangeValue,
    handleSave,
    reset,
    alignCenter = true
  } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [error, setError] = useState<boolean>(false);
  const uniqueIdRef = React.useRef(Math.random());
  const textFieldRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>();

  const save = () => {
    if (type === FieldTypes.text) {
      handleChangeValue(name, textFieldRef?.current?.value || '');
      handleSave(textFieldRef?.current?.value || '');
    } else {
      handleSave();
    }
  };

  const close = () => {
    setIsHidden?.(true);
    if (isHandleClick) {
      if (type === FieldTypes.text && textFieldRef?.current) textFieldRef.current.value = data;
      reset?.();
    }
  };

  const onCancel = () => {
    if (moment(data).format('MM/DD/YYYY') === 'Invalid date') {
      handleChangeValue?.(name, new Date());
      setIsHandleClick(true);
    }
  };

  const TextArea = React.useMemo(() => {
    return (
      <>
        <TextField
          variant="filled"
          multiline
          fullWidth
          id={`${EDateRangePickTabbingIDs.textfield}-${uniqueIdRef.current}`}
          inputRef={textFieldRef}
          InputProps={{
            className: classes.textareastyle,
            disableUnderline: !isHandleClick,
            readOnly: !isEditable,
          }}
          className={props.customClasses ? props.customClasses : classes.inlineBoxEditInputText}
          InputLabelProps={{
            className: ``,
          }}
          defaultValue={data}
          hiddenLabel
          type={'string'}
          {...props}
        />
      </>
    );
  }, [isHandleClick, isEditable, data]);

  const DateRange = React.useMemo(() => {
    const date = new Date();
    return (
      <DateRangeNew
        placeholder="MM/DD/YYYY"
        onChange={event => {
          handleChangeValue?.(name, event.startDate ? event.startDate : null);
          setIsHandleClick(true);
        }}
        onError={value => {
          setError(value);
        }}
        //onCancel={onCancel}
        value={data ? moment(data).format('MM/DD/YYYY') : null}
        open={!data && !isHandleClick && !saveStatus}
        variant="standard"
        size="inline"
        actionBar={false}
        single
        clickAway
        clear
        trailingIconShow={isHandleClick}
        disableUnderline={!isHandleClick}
        showIcon={false}
        showPlaceholder={!data}
        isDateIconReq={true}
        maxWidth={140}
        helperText={t('alerts.invalid')}
        helperBackgroundColor={'transparent'}
        wrapperClass={props.customClasses ? props.customClasses : ''}
        initialDateRange={
          moment(data).format('MM/DD/YYYY') !== 'Invalid date'
            ? {
              startDate: new Date(data),
              endDate: new Date(data),
              label: moment(data).format('MM/DD/YYYY'),
              useLabelAsValue: false,
            }
            : {
              startDate: new Date(),
              endDate: new Date(),
              label: '',
              useLabelAsValue: true,
            }
        }
        definedRanges={props.customDateRangePresets ? props.customDateRangePresets(date) : defaultDateRange(date)}
      />
    );
  }, [saveStatus, isHandleClick, data]);

  return (
    <>
      <ClickAwayListener onClickAway={close}>
        <Grid container direction="column" spacing={0}>
          <Grid item>
            <Grid container style={{ justifyContent: type === FieldTypes.text && alignCenter && saveStatus !== 2 && 'center' }}>
              {disabled ? (
                <Grid item className={classes.disabledContainer}>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      id={'disabledText'}
                      className={classes.disabledText}
                      onClick={() => setIsHandleClick(true)}
                    >
                      {type === FieldTypes.date && moment(data).format('MM/DD/YYYY') !== 'Invalid date'
                        ? moment(data).format('MM/DD/YYYY')
                        : missingField}
                    </Grid>
                    <Grid item id={'disableIcon'}>
                      <CustomTooltip tooltipContent={t('alerts.cannotEdit')}>
                        <span
                          className={`${classes.inlineBoxEditItemPadding} ${classes.inlineBoxEditMainDisabledItem}`}
                          aria-hidden="true"
                        >
                          <LockIcon data-testid="lockIcon" />
                        </span>
                      </CustomTooltip>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <>
                  {type === FieldTypes.date ? (
                    !data && !isHandleClick && isHidden ? (
                      <Grid item className={classes.disabledContainer} onClick={() => setIsHidden?.(false)}>
                        <div id={'disabledText'} className={classes.hiddenText}>
                          {missingField}
                        </div>
                      </Grid>
                    ) : (
                      <Grid item className={classes.dateRangeContainer}>
                        <div>{DateRange}</div>
                      </Grid>
                    )
                  ) : (
                    <Grid item style={{ paddingLeft: saveStatus === 2 && '10px' }}>
                      <div>{TextArea}</div>
                    </Grid>
                  )}
                </>
              )}
              {/* Render only if clicked inside Text field */}
              <Grid item>
                {isHandleClick && saveStatus !== 2 && (
                  <span>
                    {!error && (
                      <span
                        className={`${classes.inlineBoxEditItemPadding} ${classes.inlineBoxEditMainRejectItem}`}
                        onClick={() => {
                          save();
                        }}
                        aria-hidden="true"
                      >
                        <CheckIcon className={classes.saveIcon} data-testid="saveIcon" />
                      </span>
                    )}
                    <span
                      className={`${classes.inlineBoxEditItemPadding} ${classes.inlineBoxEditMainRejectItem}`}
                      onClick={() => {
                        close();
                      }}
                      onKeyDown={() => {
                        close();
                      }}
                      aria-hidden="true"
                    >
                      <CloseIcon className={classes.closeIcon} data-testid="closeIcon" />
                    </span>
                  </span>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ paddingLeft: type === FieldTypes.text && '10px' }}>
            {isHandleClick && saveStatus && (
              <>
                {saveStatus === 1 && <CircularProgress style={{ width: '12px', height: '12px' }} />}
                {saveStatus === 2 && (
                  <>
                    <CheckIcon style={{ width: '15px', height: '15px', color: '#008000' }} />
                    <label className={classes.saveSuccessText}>{t('alerts.saved')}</label>
                  </>
                )}
                {saveStatus === 3 && (
                  <>
                    <CloseIcon style={{ width: '15px', height: '15px', color: '#ff0000' }} />
                    <label className={classes.saveFailedText}>{t('alerts.failed')}</label>
                  </>
                )}
                {saveStatus === 4 && (
                  <>
                    <CloseIcon style={{ width: '15px', height: '15px', color: '#ff0000' }} />
                    <label className={classes.saveFailedText}>{t('alerts.maximumCharacterReached')}</label>
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </ClickAwayListener>
    </>
  );
};
