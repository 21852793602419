import { SidePanel } from '@AMIEWEB/Common/TemplateEditor';
import { ITemplateEditor } from '../confirmation-template-editor/types';
import { DocSource } from '../placement-candidate-contract-doc-editor/types';

export interface IPlacementClientConfirmationDocEditor extends ITemplateEditor {
  documents: IConfirmationDoc[];
  facilityDefaultTemplate: number | null;
  contacts: IClientConfirmationContacts[];
  editableContacts: IClientConfirmationContacts[];
  isContactsDirty?: boolean;
  document?: { data: string; version: number; fileName?: string; documentId?: string };
  renderPreviewScreen?: boolean;
  renderPdfView?: boolean;
  hasDraftTemplate: boolean;
  pageAttributes: {
    sidePanel: SidePanel;
    userHasOptedToDeleteExistingDraft?: boolean;
    forceEditorDirtyState?: boolean;
  };
}

export enum ConfirmationStatus {
  draft = 'Draft',
  notSent = 'Not Sent',
  sent = 'Sent',
  pending = 'Pending',
  okToSend = 'OK to Send',
  preDraft = "Pre-draft",
}

export const ConfirmationDocumentType:string = 'client-confirmation';

export interface IConfirmationDoc {
  id: string;
  documentId: string;
  documentImageId: string;
  source: DocSource;
  version: string;
  fileName: string;
  status: ConfirmationStatus;
  isDraft: boolean;

  templateId: number;
  templateText: string;

  confirmationName: string;
  markedForDelete: boolean;

  createdBy: number;
  createdByName: string;
  createdOn: string;

  /** Account for all changes */
  updatedBy: number;
  updatedByName: string;
  updatedOn: string;

  /** Doc sent */
  sentBy: number;
  sentByName: string;
  sentDate: string;

  /** Doc deletion */
  deletedBy: string;
  deletedByName: string;
  deletedDate: string;

  templateName: string;
  view: {
    draftGeneratedDate: string;
    docCreatedDate: string;
    cardUpdateDateString: string;
    updateDate: string;
    sentDate: string;
  };
}

export interface IClientConfirmationContacts {
  contactId: number;
  title: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  faxNumber: string;
  isSelected?: boolean;
}
