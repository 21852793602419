
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getSearchLookups } from 'app/services/SharedServices/SharedServices';
import { useDispatch, useSelector } from 'react-redux';
import { placementDetailsAction } from 'store/redux-store/placement-details/slice';
import { makeStyles } from 'tss-react/mui';
import { Autocomplete, TextField } from 'amn-ui-core';
import { selectPlacementLink } from 'store/redux-store/placement-details/selectors';


const useStyles: any = makeStyles()({
  autocomplete: {
    width: '40vw',
    height: '7vh',
    marginBottom: '25px',
    color: 'red'
  },
  errorBottom: {
    marginBottom: '40px',
  },
  errorText: {
    color: 'red',
    marginLeft:'-1px'
  },
  redTextInput: {
    '& input': {
      color: 'red',
    },
  },
});


export const SearchPlacement = (props) => {
  const { classes } = useStyles();
  const { control } = useFormContext();
  const placementLinks = useSelector(selectPlacementLink);
  const responseCode = placementLinks?.responseCode;
  const message = placementLinks?.message;
  const [options, setOptions] = useState<any[]>([]);
  const dispatch = useDispatch();
  const [textFieldValue, setTextFieldValue] = useState<number>();

  const fetchOptions = async (field, key) => await getSearchLookups(field, key);

  const handleInputChange = async (inputValue) => {
    const options = await fetchOptions('PID', inputValue);
    setOptions(options);
  };

  const handleChange = (supervisionPid) => {
    props.setFieldValue(supervisionPid);
    if (supervisionPid) {
      dispatch(placementDetailsAction.getPlacementSupervisorDetailsAction({ placementId: parseInt(supervisionPid) }));
    }
  };

  return (
    <Controller
      control={control}
      name="placement-supervisor"
      render={({ ref, onChange, ...rest }) => (
        <Autocomplete
          className={`${classes.autocomplete} ${responseCode > 0 ? classes.errorBottom : ''}`}
          id="autocomplete"
          options={options}
          getOptionLabel={(option) => option?.name}
          onChange={(event, value) => {
            if (value) {
              handleChange(value.value);
              setTextFieldValue(value?.value);
            } else {
              handleChange('');
              dispatch(placementDetailsAction.setPlacementLinkDetails([]));
              dispatch(placementDetailsAction.setPlacementLinkId([]));
              setOptions([]);
            }
          }}
          onInputChange={(event, newInputValue) => {
            if (newInputValue?.length >= 3) {
              handleInputChange(newInputValue);
            }
          }}
          value={options?.find((option) => option?.value === textFieldValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label="PID"
              className={`${responseCode > 0 ? classes.redTextInput : ''}`}
              InputLabelProps={{
                style: {
                  color: responseCode > 0 ? 'grey' : '',
                },
              }}
              error={responseCode > 0 && props.fieldValue}
              helperText={responseCode > 0 && props.fieldValue ? (
                <span className={classes.errorText}>{message}</span>
              ) : null}
            />
          )}
        />

      )}
    />
  );
};
