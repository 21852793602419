import { Grid } from 'amn-ui-core';
import React from 'react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { EffectiveDates } from './EffectiveDates';
import { useTranslation } from 'react-i18next';
export const EffectiveDateWrapper = () => {
  const { t } = useTranslation();
  return (
    <>
      <Grid direction="column" container>
        <Grid item xs={12} sx={{ display:'grid' }} style={{ position: 'relative' }}>
          <EffectiveDates />
        </Grid>
        <Grid item container alignItems="center">
          <Grid>
            <AddBoxIcon style={{ fontSize: 50, fill: '#D6D7D9', paddingRight: 12, marginLeft: -4 }} />
          </Grid>
          <Grid> {t('order.newOrder.rateCard.addAdditionalEffectiveDates')}</Grid>
        </Grid>
      </Grid>
    </>
  );
};
