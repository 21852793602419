import {
  Row,
  RowContainer,
  RowContent,
  RowHead,
} from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/CustomComponents/styles';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import React from 'react';
import { useStyles } from '../utils';

function RowSectionTitle(props: { header: any; data: any; isNormalRow?: boolean; children?: any }) {
  const { classes } = useStyles();
  return (
    <LayoutGridItem item style={{ width: '100%' }}>
      <RowContainer>
        <Row>
          <RowHead style={{ wordBreak: 'keep-all' }}>{props?.header}</RowHead>
          <RowContent className={classes.addLink}>{props?.data}</RowContent>
        </Row>
      </RowContainer>
      {props?.children}
    </LayoutGridItem>
  );
}

export default RowSectionTitle;
