import { useDecision } from '@optimizely/react-sdk';
import { missingField } from 'app/constants';
import { ff_ReadOnly } from 'app/constants/FeatureFlags/Global/keys';
import { useSelector } from 'react-redux';
import { selectUser } from './user.selectors';

export interface IRoles {
  clientContract_Admin;
  clientContract_Admin_Leadership;
  clientContract_Admin_TeamMember;
  candidate_Contracts;
  candidate_Contracts_Leadership;
  candidate_Contracts_TeamMember;
  recruitment;
  recruitment_Leadership;
  recruitment_TeamMember;
  accountManagement;
  accountManagement_Leadership;
  accountManagement_TeamMember;
  credentialing;
  credentialing_Leadership;
  credentialing_TeamMember;
  clinical_Director;
  clinical_Manager;
  clinical_QualificationsSpecialist;
  clinical_ReviewAnalyst;
  clinical_ClientContractSpecialist;
  customerSupport_Leadership;
  customerSupport_TeamMember;
  pricingAnalyst;
  payrollSpecialist;
  accountsPayableSpecialist;
  benefitsCoordinator;
  billingSpecialist;
  clientAccountingRepresentative;
  housingAccountExecutive;
  regionalDirector;
  timeProcessingSpecialist;
  workersCompCoordinator;
  clientSupportSpecialist;
  complianceAuditSpecialist;
  riskManagement;
  immigrationTeam;
  supportAdmin;
  readOnly;
  all;
}

export const userRoles: IRoles = {
  clientContract_Admin: 'ClientContractAdmin',
  clientContract_Admin_Leadership: 'ClientContractAdmin.Leadership',
  clientContract_Admin_TeamMember: 'ClientContractAdmin.TeamMember',
  candidate_Contracts: 'CandidateContracts',
  candidate_Contracts_Leadership: 'CandidateContracts.Leadership',
  candidate_Contracts_TeamMember: 'CandidateContracts.TeamMember',
  recruitment: 'Recruitment',
  recruitment_Leadership: 'Recruitment.Leadership',
  recruitment_TeamMember: 'Recruitment.TeamMember',
  accountManagement: 'AccountManagement',
  accountManagement_Leadership: 'AccountManagement.Leadership',
  accountManagement_TeamMember: 'AccountManagement.TeamMember',
  credentialing: 'Credentialing',
  credentialing_Leadership: 'Credentialing.Leadership',
  credentialing_TeamMember: 'Credentialing.TeamMember',
  clinical_Director: 'Clinical.Director',
  clinical_Manager: 'Clinical.Manager',
  clinical_QualificationsSpecialist: 'Clinical.QualificationsSpecialist',
  clinical_ReviewAnalyst: 'Clinical.ReviewAnalyst',
  clinical_ClientContractSpecialist: 'Clinical.ClientContractSpecialist',
  customerSupport_Leadership: 'CustomerSupport.Leadership',
  customerSupport_TeamMember: 'CustomerSupport.TeamMember',
  pricingAnalyst: 'PricingAnalyst',
  payrollSpecialist: 'PayrollSpecialist',
  accountsPayableSpecialist: 'AccountsPayableSpecialist',
  benefitsCoordinator: 'BenefitsCoordinator',
  billingSpecialist: 'BillingSpecialist',
  clientAccountingRepresentative: 'ClientAccountingRepresentative',
  housingAccountExecutive: 'HousingAccountExecutive',
  regionalDirector: 'RegionalDirector',
  timeProcessingSpecialist: 'TimeProcessingSpecialist',
  workersCompCoordinator: 'WorkersCompCoordinator',
  clientSupportSpecialist: 'ClientSupportSpecialist',
  complianceAuditSpecialist: 'ComplianceAuditSpecialist',
  riskManagement: 'RiskManagement',
  immigrationTeam: 'ImmigrationTeam',
  supportAdmin: 'SupportAdmin',
  readOnly: 'ReadOnly',
  all: 'All',
};

export const isRecruitment = (roles: string[]) => {
  return (
    roles.includes(userRoles.recruitment) ||
    roles.includes(userRoles.recruitment_Leadership) ||
    roles.includes(userRoles.recruitment_TeamMember) ||
    roles.includes(userRoles.clientContract_Admin) ||
    roles.includes(userRoles.clientContract_Admin_Leadership) ||
    roles.includes(userRoles.clientContract_Admin_TeamMember) ||
    roles.includes(userRoles.candidate_Contracts) ||
    roles.includes(userRoles.candidate_Contracts_Leadership) ||
    roles.includes(userRoles.candidate_Contracts_TeamMember)
  );
};

export const isClientContractAdminUser = (roles: string[]) => {
  return (
    roles.includes(userRoles.clientContract_Admin) ||
    roles.includes(userRoles.clientContract_Admin_Leadership) ||
    roles.includes(userRoles.clientContract_Admin_TeamMember)
  );
};

export const isRecruitmentUser = (roles: string[]) => {
  return (
    roles.includes(userRoles.recruitment) ||
    roles.includes(userRoles.recruitment_Leadership) ||
    roles.includes(userRoles.recruitment_TeamMember)
  );
};

export const isCandidateContractUser = (roles: string[]) =>
  roles.includes(userRoles.candidate_Contracts) ||
  roles.includes(userRoles.candidate_Contracts_Leadership) ||
  roles.includes(userRoles.candidate_Contracts_TeamMember);
  
export const isCandidateContractLeadUser = (roles: string[]) => {
  return (
    roles.includes(userRoles.candidate_Contracts_Leadership) || roles.includes(userRoles.candidate_Contracts_TeamMember)
  );
};

export const isAccountManagement = (roles: string[]) => {
  return (
    roles.includes(userRoles.accountManagement) ||
    roles.includes(userRoles.accountManagement_Leadership) ||
    roles.includes(userRoles.accountManagement_TeamMember) ||
    roles.includes(userRoles.clientContract_Admin) ||
    roles.includes(userRoles.clientContract_Admin_Leadership) ||
    roles.includes(userRoles.clientContract_Admin_TeamMember)
  );
};

export const isCredentialing = (roles: string[]) => {
  return (
    roles.includes(userRoles.credentialing) ||
    roles.includes(userRoles.credentialing_Leadership) ||
    roles.includes(userRoles.credentialing_TeamMember) ||
    roles.includes(userRoles.clientContract_Admin) ||
    roles.includes(userRoles.clientContract_Admin_Leadership) ||
    roles.includes(userRoles.clientContract_Admin_TeamMember) ||
    roles.includes(userRoles.candidate_Contracts) ||
    roles.includes(userRoles.candidate_Contracts_Leadership) ||
    roles.includes(userRoles.candidate_Contracts_TeamMember)
  );
};

export const isClinical = (roles: string[]) => {
  return (
    roles.includes(userRoles.clinical_Director) ||
    roles.includes(userRoles.clinical_ClientContractSpecialist) ||
    roles.includes(userRoles.clinical_Manager) ||
    roles.includes(userRoles.clinical_QualificationsSpecialist) ||
    roles.includes(userRoles.clinical_ReviewAnalyst)
  );
};

export const isCustomerSupport = (roles: string[]) => {
  return roles.includes(userRoles.customerSupport_Leadership) || roles.includes(userRoles.customerSupport_TeamMember);
};

export const isSupportAdmin = (roles: string[]) => {
  return roles.includes(userRoles.supportAdmin);
};

export const isReadOnly = (roles: string[]) => {
  return roles.includes(userRoles.readOnly);
};

const isPricingAnalyst = (roles: string[]) => {
  return roles.includes(userRoles.pricingAnalyst);
};

export const isPayrollSpecialist = (roles: string[]) => {
  return roles.includes(userRoles.payrollSpecialist);
};

const isAccountsPayableSpecialist = (roles: string[]) => {
  return roles.includes(userRoles.accountsPayableSpecialist);
};

const isBenefitsCoordinator = (roles: string[]) => {
  return roles.includes(userRoles.benefitsCoordinator);
};

const isBillingSpecialist = (roles: string[]) => {
  return roles.includes(userRoles.billingSpecialist);
};

const isClientAccountingRepresentative = (roles: string[]) => {
  return roles.includes(userRoles.clientAccountingRepresentative);
};

const isHousingAccountExecutive = (roles: string[]) => {
  return roles.includes(userRoles.housingAccountExecutive);
};

const isRegionalDirector = (roles: string[]) => {
  return roles.includes(userRoles.regionalDirector);
};

const isTimeProcessingSpecialist = (roles: string[]) => {
  return roles.includes(userRoles.timeProcessingSpecialist);
};

const isWorkersCompCoordinator = (roles: string[]) => {
  return roles.includes(userRoles.workersCompCoordinator);
};

const isClientSupportSpecialist = (roles: string[]) => {
  return roles.includes(userRoles.clientSupportSpecialist);
};

const isComplianceAuditSpecialist = (roles: string[]) => {
  return roles.includes(userRoles.complianceAuditSpecialist);
};

const isRiskManagement = (roles: string[]) => {
  return roles.includes(userRoles.riskManagement);
};

/**
 * Custom hook to determine if the user is in a read-only role and component should be disabled
 * @returns `readOnly: boolean, showTooltip: boolean`. ReadOnly is true if user is in the read-only role. showTooltip is true if a tooltip should be shown on the control
 */
export const useReadOnly = (): { readOnly: boolean; showTooltip: boolean } => {
  const { userInfo } = useSelector(selectUser);
  const [decision] = useDecision(ff_ReadOnly);
  return {
    readOnly:
      decision?.enabled &&
      isReadOnly(userInfo?.roles ?? []) &&
      !((decision?.variables?.excludedUsers || []) as string[])
        .map(item => item.toLowerCase())
        .includes(userInfo?.email?.toLowerCase() ?? missingField),
    showTooltip: (decision?.variables?.showTooltip ?? false) as boolean,
  };
};

export const isMultiRoled = (roles?: (keyof IRoles)[] | string[]) => {
  let count = 0;
  if (roles) {
    if (isRecruitment(roles || [])) count++;
    if (isCredentialing(roles || [])) count++;
    if (isAccountManagement(roles || [])) count++;
    if (isSupportAdmin(roles || [])) count = 2;
    if (isPricingAnalyst(roles || [])) count = 2;
    if (isPayrollSpecialist(roles || [])) count = 2;
    if (isAccountsPayableSpecialist(roles || [])) count = 2;
    if (isBenefitsCoordinator(roles || [])) count = 2;
    if (isBillingSpecialist(roles || [])) count = 2;
    if (isClientAccountingRepresentative(roles || [])) count = 2;
    if (isHousingAccountExecutive(roles || [])) count = 2;
    if (isRegionalDirector(roles || [])) count = 2;
    if (isTimeProcessingSpecialist(roles || [])) count = 2;
    if (isWorkersCompCoordinator(roles || [])) count = 2;
    if (isClientSupportSpecialist(roles || [])) count = 2;
    if (isComplianceAuditSpecialist(roles || [])) count = 2;
    if (isRiskManagement(roles || [])) count = 2;
  }
  return count >= 2;
};

export const isGrantedAllDashboards = (roles: string[]) => {
  return isSupportAdmin(roles) || isClinical(roles) || isCustomerSupport(roles) || isReadOnly(roles);
};

export const allAccessRoles = [
  userRoles.supportAdmin,
  userRoles.customerSupport_Leadership,
  userRoles.customerSupport_TeamMember,
  userRoles.clinical_ClientContractSpecialist,
  userRoles.clinical_Director,
  userRoles.clinical_Manager,
  userRoles.clinical_QualificationsSpecialist,
  userRoles.clinical_ReviewAnalyst,
  userRoles.readOnly,
];

/**
 * Get the display name for the given AAD role
 * @param role The role (string) defined in AAD
 */
export const userRolesDisplayValue = (role: string): string => {
  const foundRole = Object.entries(userRoles).find(item => item[1] === role);
  if (foundRole === undefined) return role;
  switch (foundRole[0]) {
    case 'clientContract_Admin':
    case 'clientContract_Admin_Leadership':
    case 'clientContract_Admin_TeamMember':
      return 'Client Contract';
    case 'candidate_Contracts':
    case 'candidate_Contracts_Leadership':
    case 'candidate_Contracts_TeamMember':
      return 'Candidate Contract';
    case 'recruitment':
    case 'recruitment_Leadership':
    case 'recruitment_TeamMember':
      return 'Recruitment';
    case 'accountManagement':
    case 'accountManagement_Leadership':
    case 'accountManagement_TeamMember':
      return 'Account Management';
    case 'credentialing':
    case 'credentialing_Leadership':
    case 'credentialing_TeamMember':
      return 'Credentialing';
    case 'supportAdmin':
      return 'Support Admin';
    case 'clinical_Director':
      return 'Clinical Director';
    case 'clinical_Manager':
      return 'Clinical Manager';
    case 'clinical_QualificationsSpecialist':
      return 'Clinical Qualification Specialist';
    case 'clinical_ReviewAnalyst':
      return 'Clinical Review Analyst';
    case 'clinical_ClientContractSpecialist':
      return 'Clinical Client Contract Specialist';
    case 'customerSupport_Leadership':
    case 'customerSupport_TeamMember':
      return 'Customer Support';
    case 'pricingAnalyst':
      return 'Pricing Analyst';
    case 'payrollSpecialist':
      return 'Payroll Specialist';
    case 'accountsPayableSpecialist':
      return 'Accounts Payable Specialist';
    case 'benefitsCoordinator':
      return 'Benefits Coordinator';
    case 'billingSpecialist':
      return 'Billing Specialist';
    case 'clientAccountingRepresentative':
      return 'Client Accounting Representative';
    case 'housingAccountExecutive':
      return 'Housing Account Executive';
    case 'regionalDirector':
      return 'Regional Director';
    case 'timeProcessingSpecialist':
      return 'Time Processing Specialist';
    case 'workersCompCoordinator':
      return 'Workers Comp Coordinator';
    case 'clientSupportSpecialist':
      return 'Client Support Specialist';
    case 'complianceAuditSpecialist':
      return 'Compliance Audit Specialist';
    case 'riskManagement':
      return 'Risk Management';
    case 'immigrationTeam':
      return 'Immigration Team';
    case 'readOnly':
      return 'Read Only';
    default:
      return '';
  }
};

export const userRoleList = Object.values(userRoles);
