/* eslint-disable i18next/no-literal-string */
import React from 'react';
import { Theme,  Grid, Avatar } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) =>
  ({
    root: {
      width: '100%',
    },
  }));

export const VerticalLinearStepper = ({ steps, isAllActive }) => {
  const { classes } = useStyles();

  // const handleClick = stepClicked => stepClicked !== activeStep && handleStepChange(stepClicked);

  return (
    <div className={classes.root} style={{ position: 'relative' }}>
      <div
        style={{
          width: '1px',
          height: `calc(100% - 50px)`,
          position: 'absolute',
          left: '10px',
          backgroundColor: 'grey',
          margin: '25px 0px',
        }}
      />
      <Grid container spacing={3}>
        {steps.map((item, index) => {
          return (
            <Grid item xs={12} key={index.toString()}>
              <Grid container wrap="nowrap" alignItems="flex-start">
                <Grid item style={{ width: '30px' }}>
                  <Avatar
                    style={{
                      marginTop: '14px',
                      width: '20px',
                      height: '20px',
                      fontSize: '12px',
                      backgroundColor: 'white',
                      color: 'grey',
                      border: '1px solid grey',
                    }}
                  >
                    {index + 1}
                  </Avatar>
                </Grid>
                <Grid item xs={12}>
                  {item.stepContent}
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};
