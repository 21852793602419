/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable i18next/no-literal-string */
import React, { useEffect, useState } from 'react';
import { LayoutGrid } from 'app/layout/LayoutGrid';
import { useSelector } from 'react-redux';
import { candidateDetailsSelection } from '../../../../Profile/CandidateDetails.selector';
import _ from 'lodash';
import { useDecision } from '@optimizely/react-sdk';
import { ff_viewReferences, ff_candidate_container_ui_v2 } from 'app/constants/FeatureFlags/Candidate/Keys';
import { useWorkExperienceStyles } from './WorkExperienceCard.style';
import { AddReferenceModal } from '../../../Common/AddReferenceModal';
import { Authorized } from 'oidc/userHelper';
import { selectUser } from 'oidc/user.selectors';
import ExperienceUI from '../Card Design/ExperienceUI';
import ExperienceUIV2 from '../Card Design/ExperienceUIV2';
import { workExperianceUserRoles } from '../helper';

export const WorkExperienceCard = props => {
  const candidateDetails = useSelector(candidateDetailsSelection);
  const [candidateContainerUIV2] = useDecision(ff_candidate_container_ui_v2);
  const { classes } = useWorkExperienceStyles();
  const [referenceChecks, setReferenceChecks] = useState<number>(0);
  const { userInfo } = useSelector(selectUser);
  const [referenceDecision] = useDecision(ff_viewReferences);
  const [openAddPopup, setOpenAddPopup] = useState<boolean>(false);
  const [addReferenceAuthorized, setAddReferenceAuthorized] = useState<boolean>(false);

  useEffect(() => {
    if (candidateDetails?.experience?.totalApprovedReferenceCount >= 2) {
      setReferenceChecks(referenceChecks + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateDetails.experience]);

  useEffect(() => {
    if (Authorized(workExperianceUserRoles, userInfo)) {
      setAddReferenceAuthorized(true);
    }
  }, [userInfo]);

  const handleNavigation = () => {
    setOpenAddPopup(true);
  };

  const handleClose = () => {
    setOpenAddPopup(false);
  };

  return (
    <LayoutGrid className={classes.grid}>
      {candidateContainerUIV2?.enabled ? (
        <ExperienceUIV2
          referenceDecision={referenceDecision}
          showAllWorkExperience={props.showAllWorkExperience}
          showAllReferences={props.showAllReferences}
          filterReferenceBy={props.filterReferenceBy}
          addReferenceAuthorized={addReferenceAuthorized}
          handleNavigation={handleNavigation}
        />
      ) : (
        <ExperienceUI
          referenceDecision={referenceDecision}
          showAllWorkExperience={props.showAllWorkExperience}
          showAllReferences={props.showAllReferences}
          filterReferenceBy={props.filterReferenceBy}
          addReferenceAuthorized={addReferenceAuthorized}
          handleNavigation={handleNavigation}
        />
      )}
      {openAddPopup && <AddReferenceModal open={openAddPopup} handleClose={handleClose} />}
    </LayoutGrid>
  );
};
