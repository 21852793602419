import { missingDate, missingField } from 'app/constants';
import { AmPlacement } from 'app/models/WorkDesk/AccountManagementPlacementDetails';
import { getStatusAge } from 'utils/common/statusAge';
import { GetMomentOfDate } from 'utils/dates/moment';
import { Concatenate } from 'utils/string/string';
import Call from '../../../../app/assets/images/WorkDesk/Call.png';
import email from '../../../../app/assets/images/WorkDesk/email.png';

export enum amPlacementsGridKeys {
  placementAccountManager='placementAccountManager',
  candidate = 'candidate',
  recruiter = 'recruiter',
  mspClientId = 'mspClientId',
  contractAffiliateId = 'contractAffiliateId',
  facilityName = 'facilityName',
  facilityCity = 'facilityCity',
  facilityState = 'facilityState',
  skillSet = 'skillSet',
  staffingVendorId = 'staffingVendorId',
  technologyVendorId = 'technologyVendorId',
  orderPriorityIds = 'orderPriorityIds',
  isActiveCue = 'isActiveCue',
  lastCueDateOnNoFormat = 'lastCueDateOnNoFormat',
}

export const amPlacementFlattenData = (PlacementInfo: AmPlacement[]) =>
  PlacementInfo.map((info, i) => {
    const availableToStartDateMoment = GetMomentOfDate(info.availableStartDate);
    const startDateMoment = GetMomentOfDate(info.startDate);
    const endDateMoment = GetMomentOfDate(info.endDate);
    const lastCueDateMoment = GetMomentOfDate(info.cue.lastCueDate);
    const statusAgeDetails = getStatusAge(info.statusChangeDate);
    const extStatusAgeDetails = GetMomentOfDate(info.extStatusUpdateDate).isValid()
      ? getStatusAge(info.extStatusUpdateDate)
      : null;
    var daysToEndDate =
      info.statusAbbr === 'OA' ? endDateMoment.diff(GetMomentOfDate(new Date()).startOf('day'), 'days') : null;

    const credentialingAnalystAttributes = [
      { key: Call, value: info.credentialingAnalyst.phoneNumber || missingField },
      { key: email, value: info.credentialingAnalyst.email || missingField },
    ];

    const recruiterAttributes = [
      { key: Call, value: info.recruiter.phoneNumber || missingField },
      { key: email, value: info.recruiter.email || missingField },
    ];

    const cuedFromAttributes = [
      { key: Call, value: info.cue.cuePhone || missingField },
      { key: email, value: info.cue.cueEmail || missingField },
    ];
    const clinicalManagerAttributes = [
      { key: Call, value: info.clinicalManager.phoneNumber || missingField },
      { key: email, value: info.clinicalManager.email || missingField },
    ];

    return {
      id: `${info.placementId}-${i}`,
      indexCounter: i,
      previewDrawerViewerId: info.candidate.candidateId,
      previewDrawerViewerType: 1,
      placementId: info.placementId,
      candidateId: info.candidate.candidateId,
      placementType: info.placementType,
      statusAbbr: info.statusAbbr,
      brand: info.brandName,
      brandId: info.brandId,
      [amPlacementsGridKeys.placementAccountManager]: Concatenate([info.accountManager.firstName, info.accountManager.lastName], ' ') || missingField,
      [amPlacementsGridKeys.candidate]: Concatenate([info.candidate.first, info.candidate.last], ' ') || missingField,
      orderId: info.orderId,
      startDate: startDateMoment.isValid() ? startDateMoment.format('MM/DD/YYYY') : missingField,
      endDate: endDateMoment.isValid() ? endDateMoment.format('MM/DD/YYYY') : missingField,
      [amPlacementsGridKeys.facilityName]: info.facility.name || missingField,
      facilityId: info.facility.facilityId,
      [amPlacementsGridKeys.facilityCity]: info.facility.city || missingField,
      [amPlacementsGridKeys.facilityState]: info.facility.state || missingField,
      unit: info.unit || missingField,
      [amPlacementsGridKeys.skillSet]:
        Concatenate([info.skillset.disciplineAbbr, info.skillset.specialtyAbbr], '-') || missingField,
      vmsReqNo: info.vmsReqNo || missingField,
      lastContacted: info.lastContactedOnDate || missingField,
      status: info.status || missingField,
      statusAge: statusAgeDetails ? statusAgeDetails.message : missingField,
      statusAgeValue: statusAgeDetails ? statusAgeDetails.totalMinutes : Number.MIN_VALUE,
      daysLeft: `${daysToEndDate != null && daysToEndDate > 0 ? daysToEndDate : 0} D`,
      daysLeftValue: daysToEndDate != null && daysToEndDate > 0 ? daysToEndDate : 0,
      availableStartDate: availableToStartDateMoment.isValid()
        ? availableToStartDateMoment.format('MM/DD/YYYY')
        : missingField,
      extStatus: info.extStatus || missingField,
      extStatusAge: extStatusAgeDetails ? extStatusAgeDetails.message : missingField,
      extStatusAgeValue: extStatusAgeDetails ? extStatusAgeDetails.totalMinutes : Number.MIN_VALUE,
      credentialingRating: info.credentialingRating || missingField,
      credentialingAnalyst:
        Concatenate([info.credentialingAnalyst.firstName, info.credentialingAnalyst.lastName], ' ') || missingField,
      credentialingAnalystAttributes,
      accountManager:
        Concatenate([info.credentialingAnalyst.firstName, info.credentialingAnalyst.lastName], ' ') || missingField,
      clinicalManager:
        Concatenate([info.clinicalManager.firstName, info.clinicalManager.lastName], ' ') || missingField,
      clinicalManagerAttributes,
      [amPlacementsGridKeys.recruiter]:
        Concatenate([info.recruiter.firstName, info.recruiter.lastName], ' ') || missingField,
      recruiterAttributes,
      recruiterData: info.recruiter,
      candidatePhone: info.candidate.phoneNumber,
      candidateEmail: info.candidate.email,
      orderShift: info.orderShift || missingField,
      shiftRequested: info.shiftRequested || missingField,
      length: info.length || missingField,
      lastCueDate: lastCueDateMoment.isValid() ? lastCueDateMoment.format('MM/DD/yyyy hh:mm A') : missingField,
      [amPlacementsGridKeys.lastCueDateOnNoFormat]: info.cue.lastCueDate || missingDate, // needed for filters
      lastCueNote: info.cue.cueText || missingField,
      cuedFrom: Concatenate([info.cue.cueFirstName, info.cue.cueLastName], ' ') || missingField,
      cuedFromAttributes,
      [amPlacementsGridKeys.mspClientId]: info.mspClientId,
      [amPlacementsGridKeys.contractAffiliateId]: info.affiliationId,
      [amPlacementsGridKeys.staffingVendorId]: info.staffingVendorId,
      [amPlacementsGridKeys.technologyVendorId]: info.technologyVendorId,
      [amPlacementsGridKeys.orderPriorityIds]: info.ordePrioritiesData.map(priority => priority.orderPriorityTypeId),
      [amPlacementsGridKeys.isActiveCue]: info.cue.isActiveCue,
    };
  });

/**
 * @deprecated Use amPlacementFlattedData
 */
export const amPlacementFlattenData_OLD = (PlacementInfo: AmPlacement[]) =>
  PlacementInfo.map((info, i) => {
    const availabilityDateMoment = GetMomentOfDate(info.availableStartDate);
    const cueNoteDate = GetMomentOfDate(info.cue.lastCueDate);
    const startDateMoment = GetMomentOfDate(info.startDate);
    const endDateMoment = GetMomentOfDate(info.endDate);
    const recruiterValue = Concatenate([info.recruiter.firstName, info.recruiter.lastName], ' ') || missingField;
    const cuedFromValue = Concatenate([info.cue.cueFirstName, info.cue.cueLastName], ' ') || missingField;
    var daysToEndDate =
      info.statusAbbr === 'OA' ? endDateMoment.diff(GetMomentOfDate(new Date()).startOf('day'), 'days') : null;
    const daysLeft = daysToEndDate != null && daysToEndDate > 0 ? daysToEndDate : 0;
    const statusAgeDetails = getStatusAge(info.statusChangeDate);
    const extStatusAgeDetails = GetMomentOfDate(info.extStatusUpdateDate).isValid()
      ? getStatusAge(info.extStatusUpdateDate)
      : null;
    const recruiterAttributes = [
      { key: Call, value: info.recruiter.phoneNumber || missingField },
      { key: email, value: info.recruiter.email || missingField },
    ];
    const credentialingAnalystAttributes = [
      { key: Call, value: info.credentialingAnalyst.phoneNumber || missingField },
      { key: email, value: info.credentialingAnalyst.email || missingField },
    ];
    const clinicalManagerAttributes = [
      { key: Call, value: info.clinicalManager.phoneNumber || missingField },
      { key: email, value: info.clinicalManager.email || missingField },
    ];
    const cuedFromAttributes = [
      { key: Call, value: info.cue.cuePhone || missingField },
      { key: email, value: info.cue.cueEmail || missingField },
    ];

    return {
      placementId: info.placementId,
      placementType: info.placementType,
      orderId: info.orderId,
      facilityId: info.facility.facilityId,
      [amPlacementsGridKeys.facilityName]: info.facility.name || missingField,
      [amPlacementsGridKeys.facilityCity]: info.facility.city || missingField,
      [amPlacementsGridKeys.facilityState]: info.facility.state || missingField,
      unit: info.unit || missingField,
      [amPlacementsGridKeys.skillSet]:
        Concatenate([info.skillset.disciplineAbbr, info.skillset.specialtyAbbr], ', ') || missingField,
      vmsReqNo: info.vmsReqNo || missingField,
      [amPlacementsGridKeys.candidate]: Concatenate([info.candidate.first, info.candidate.last], ' ') || missingField,
      candidateId: info.candidate.candidateId,
      brandId: info.brandId,
      status: info.status || missingField,
      statusAbbr: info.statusAbbr,
      statusAge: statusAgeDetails ? statusAgeDetails.message : missingField,
      statusAgeValue: statusAgeDetails ? statusAgeDetails.totalMinutes : Number.MIN_VALUE,
      availableStartDate: availabilityDateMoment.isValid() ? availabilityDateMoment.format('MM/DD/YYYY') : missingField,
      availableStartDateNoFormat: info.availableStartDate || missingDate,
      orderShift: info.orderShift || missingField,
      shiftRequested: info.shiftRequested || missingField,
      [amPlacementsGridKeys.recruiter]: recruiterValue,
      recruiterAttributes,
      lastCueDate: cueNoteDate.isValid() ? cueNoteDate.format('MM/DD/YYYY hh:mm A') : missingField,
      [amPlacementsGridKeys.lastCueDateOnNoFormat]: info.cue.lastCueDate || missingDate,
      startDate: startDateMoment.isValid() ? startDateMoment.format('MM/DD/YYYY') : missingField,
      startDateNoFormat: info.startDate || missingDate,
      endDate: endDateMoment.isValid() ? endDateMoment.format('MM/DD/YYYY') : missingField,
      endDateNoFormat: info.endDate || missingDate,
      length: info.length || missingField,
      credentialingRating: info.credentialingRating || missingField,
      credentialingAnalyst:
        Concatenate([info.credentialingAnalyst.firstName, info.credentialingAnalyst.lastName], ' ') || missingField,
      credentialingAnalystAttributes,
      extStatus: info.extStatus || missingField,
      extStatusAge: extStatusAgeDetails ? extStatusAgeDetails.message : missingField,
      extStatusAgeValue: extStatusAgeDetails ? extStatusAgeDetails.totalMinutes : Number.MIN_VALUE,
      daysLeft: daysLeft + ' D',
      daysLeftValue: daysLeft,
      clinicalManager:
        Concatenate([info.clinicalManager.firstName, info.clinicalManager.lastName], ' ') || missingField,
      clinicalManagerAttributes,
      [amPlacementsGridKeys.mspClientId]: info.mspClientId,
      [amPlacementsGridKeys.contractAffiliateId]: info.affiliationId,
      [amPlacementsGridKeys.staffingVendorId]: info.staffingVendorId,
      [amPlacementsGridKeys.technologyVendorId]: info.technologyVendorId,
      [amPlacementsGridKeys.orderPriorityIds]: info.ordePrioritiesData.map(priority => priority.orderPriorityTypeId),
      lastCueNote: info.cue.cueText || missingField,
      cuedFrom: cuedFromValue,
      cuedFromAttributes,
      [amPlacementsGridKeys.isActiveCue]: info.cue.isActiveCue,
    };
  });
