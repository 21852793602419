import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { InnerValueSection } from 'app/components/Order/OrderCreationForm/DefineUnit/UnitPreview/Common/InnerValueSection';
// import { ExperienceType, SkillSetType } from 'app/models/Unit/CreateUnit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InnerIconValueSection } from '../../../OrderCreationForm/DefineUnit/UnitPreview/Common/InnerIconValueSection';
import { ValueContainer } from '../../../OrderCreationForm/DefineUnit/UnitPreview/Common/ValueContainer';
import EditIcon from '@mui/icons-material/Edit';
import {
  PreviewContent,
  PreviewContentTitle,
  SkillSetDivider,
} from '../../../OrderCreationForm/DefineUnit/UnitPreview/UnitPreviewDesign';
import { SkillSet } from 'app/models/Orders/OrderDetails';
interface Props {
  skillsets: SkillSet[];
  newGrad: boolean | null;
  disableEdit?: boolean;
  handleEditOrder?: () => void;
}

const useStyles = makeStyles()({
  container: {
    '&:hover': {
      '& [data-id="edit-icon"]': {
        opacity: 1,
        transition: 'opacity 0.25s',
      },
    },
  },
  editItem: {
    opacity: 0,
    transition: 'opacity 0.25s',
  },
  editIcon: {
    height: '20px',
    width: '20px',
    cursor: 'pointer',
    color: '#8E9195',
  },
});

export const OrderDetailsExperience = (props: Props) => {
  const { skillsets, newGrad, disableEdit, handleEditOrder } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <PreviewContent variant="outlined" elevation={0}>
          <Grid container direction="column" spacing={3} className={classes.container}>
            <Grid item>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <PreviewContentTitle>{t('order.createUnit.experience.title')}</PreviewContentTitle>
                </Grid>
                <Grid item className={classes.editItem} data-id="edit-icon">
                  {!disableEdit ? (
                    <EditIcon
                      className={classes.editIcon}
                      id={'OrderDetailsExperienceEditTrigger'}
                      onClick={handleEditOrder}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {newGrad && (
              <Grid item>
                <InnerValueSection
                  contentValue={t('order.createUnit.experience.newGrad')}
                  id={'OrderExperienceNewGradLbl'}
                />
              </Grid>
            )}
            {skillsets &&
              skillsets.length > 0 &&
              skillsets.map((item, index) => (
                <>
                  <Grid item>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <InnerIconValueSection
                          isRequired={item.isRequired ? true : false}
                          id={'OrderExperienceIsRequiredLbl_' + index}
                        />
                      </Grid>
                      <Grid item>
                        <ValueContainer
                          labelContent={[
                            { label: t('order.createUnit.experience.disciplineTitle'), size: 3 },
                            { label: t('order.createUnit.experience.specialtyTitle'), size: 3 },
                            { label: t('order.createUnit.experience.subSpecialty'), size: 3 },
                            { label: t('order.createUnit.experience.yearsOfExperienceTitle'), size: 3 },
                          ]}
                          valueContent={[
                            { value: item.discipline, size: 3 },
                            { value: item.specialty, size: 3 },
                            { value: item.subSpecialty ? item.subSpecialty : '-', size: 3 },
                            {
                              value: item.yearsOfExperience ? item.yearsOfExperience.toString() : '-',
                              size: 3,
                            },
                          ]}
                          id={'OrderExperienceSkillsSets_' + index}
                        />
                      </Grid>
                    </Grid>
                    {index !== skillsets.length - 1 && <SkillSetDivider />}
                  </Grid>
                </>
              ))}
          </Grid>
        </PreviewContent>
      </Grid>
    </>
  );
};
