import { Typography, Skeleton, Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';

const useKeyValueStyle = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    gap: '6px',
  },

  value: {
    color: theme.palette.system.primary,
    fontWeight: 450,
  },
  skeleton: {
    borderRadius: 4,
  },
}));

export const KeyValuePair = ({ keyOfValue, value, isLoading = false }) => {
  const { classes } = useKeyValueStyle();
  return (
    <div className={classes.container} data-testid="key-value-container">
      <Typography variant="body1" color='charcoal' >
        {keyOfValue}
      </Typography>
      {isLoading ? (
        <Skeleton variant="rectangular" width={'100%'} classes={{ rectangular: classes.skeleton }} />
      ) : (
        <Typography variant="body1" classes={{ root: classes.value }}>
          {value}
        </Typography>
      )}
    </div>
  );
};
