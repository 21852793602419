import { Typography } from 'amn-ui-core';
import { missingField } from 'app/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { GetMomentOfDate } from 'utils/dates/moment';
import { Concatenate } from 'utils/string/string';
import { getExtensionChip } from '../Assignment/Details/helper';
import { StatusChip } from '../CustomComponents/StatusChip';
import { KeyValueRow } from '../TableComponents/KeyValueTable/KeyValueRow';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 4,
  },
  text: {
    alignSelf: 'flex-end',
  },
}));

export const ExtStatusRow: React.FC<{
  extensionStatusId: number; 
  extensionStatus: string; 
  updatedBy: string;
  updatedAt: string ;
  displayUpdateSpecs: boolean;
}> = ({ 
  extensionStatusId, 
  extensionStatus, 
  updatedBy,
  updatedAt,
  displayUpdateSpecs = false, 
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const statusChip = React.useMemo(() =>  {
    const updatedAtMoment = GetMomentOfDate(updatedAt);
    return (
      <div className={classes.container}>
        {
          extensionStatusId 
          ? (<StatusChip 
            status={extensionStatus} 
            chipStyle={getExtensionChip(extensionStatusId)} 
          />) 
          : missingField
        }
        {
          displayUpdateSpecs && updatedAtMoment.isValid()
          ? (
            <Typography variant="body1" classes={{ body1: classes.text }}>
              {
                Concatenate([
                  updatedAtMoment.format('MM/DD/YYYY hh:mm A'), 
                  'by', 
                  updatedBy
                ], ' ')
              }
            </Typography>
          ) : null
        }
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayUpdateSpecs, extensionStatusId, updatedAt]);

  return (
    <KeyValueRow
      id="placement-ext-status-row"
      rowTitle={t('placement.profile.summary.extension.status')}
      loading={false}
    >
      {statusChip}
    </KeyValueRow>
  );
}