import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IGridSelection {
  selectedData: any[];
  isCompareGridOpen?: boolean;
}

export const initialState: IGridSelection = {
  selectedData: [],
  isCompareGridOpen: false,
};

const gridSelectionSlice = createSlice({
  name: 'selectedData',
  initialState,
  reducers: {
    setGridSelections(state, action: PayloadAction<IGridSelection>) {
      return {
        ...action.payload,
      };
    },
    reset() {
      return initialState;
    },
  },
});

const { actions } = gridSelectionSlice;
export const { name: gridSelectionSliceKey, reducer: gridSelectionReducer } = gridSelectionSlice;
export const gridSelectionActions = {
  ...actions,
};
