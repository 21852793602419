import React, { useEffect, useRef, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import styledComponent from 'styled-components';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  customTooltip: {
    backgroundColor: '#ffffff',
    color: '#0D2A3B',
    opacity: 1,
    textAlign: 'left',
    font: 'normal normal normal 12px/20px IBM Plex Sans',
    letterSpacing: '0px',
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #8C8C8C',
  },
  customArrow: {
    color: '#ffffff',
    opacity: 1,
    '&::before': {
      boxShadow: '0px 3px 6px #00000029',
      border: '1px solid #8C8C8C',
    },
  },
  tooltipPlacementBottom: {
    margin: 0,
  },
});

export const OverflowTooltip = props => {
  const { classes } = useStyles();
  const textElementRef = useRef<HTMLTableDataCellElement>(null);
  const valueStyle = props.styles;
  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);

  const compareSize = () => {
    if (textElementRef.current) setHover(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
  };

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  }, []);

  // remove resize listener again on "componentWillUnmount"
  useEffect(
    () => () => {
      window.removeEventListener('resize', compareSize);
    },
    [],
  );

  return (
    <Tooltip
      title={props.title ? props.title : props.value}
      arrow
      interactive
      disableHoverListener={!hoverStatus}
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.customArrow,
        tooltipPlacementBottom: classes.tooltipPlacementBottom,
      }}
      style={props.contentStyle}
    >
      {props.styles ? (
        <Content className={valueStyle} ref={textElementRef}>
          {props.value}
        </Content>
      ) : (
        <Content ref={textElementRef}>{props.value}</Content>
      )}
    </Tooltip>
  );
};

const Content = styledComponent.td`
  text-align: start;
  color: #003e68;
  font-size: 12px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100px;
`;
