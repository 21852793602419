import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { makeStyles } from 'tss-react/mui';
import { Grid, Skeleton } from '@mui/material';
import YouAreAllDoneImg from 'app/assets/images/img-great-job-youre-all-caught-up.svg';
import NoTaskLeftImg from 'app/assets/images/img-impressive-no-tasks-left-for-you.svg';
import NothingToSeeHereImg from 'app/assets/images/img-nothing-to-see-here.svg';
import { selectUser } from 'oidc/user.selectors';
import { CueNoteItem } from './CueNoteItem';
import { groupByDate } from './Helpers/MessageGrouper';
import DividerWithText from 'app/components/Notification/MultiChannel/Sms/Helpers/DividerWithText';
import { selectCueNotesList } from '../store/Tasks.selectors';
import { useSelector } from 'react-redux';
import { getEmployeeId } from '@AMIEWEB/Notification/Tasks/Common_v2/utils';

const useStyles = makeStyles()({
  noTaskIconPlacement: {
    height: '120px',
    paddingLeft: '2.8rem',
  },
  noTaskIconMessage: {
    font: 'italic normal normal 14px/20px Roboto',
    textAlign: 'center',
    color: '#333333',
    opacity: '1',
    height: '19px',
  },
});

export const CueNoteItemsList = ({
  expandLastCueNote = false,
  lastCueNoteId,
  cueNotesDrawer = false,
}: {
  expandLastCueNote?: boolean;
  lastCueNoteId?: number | null;
  cueNotesDrawer?: boolean;
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const userInfo = useSelector(selectUser);
  const cueNotesList = useSelector(selectCueNotesList);
  const { promiseInProgress: isCallingAPI } = usePromiseTracker({ area: 'get-cue-notes-list', delay: 0 });
  const userId = parseInt(getEmployeeId(userInfo?.userInfo));
  const randomIndex = Math.floor(Math.random() * 3);
  const [chats, setChats] = useState<any[]>([]);

  const socialIcons: { icon; message: string }[] = [
    {
      icon: <img src={NothingToSeeHereImg} />,
      message: t('notification.taskSidePanel.nothingToSeeHere'),
    },
    {
      icon: <img src={NoTaskLeftImg} />,
      message: t('notification.taskSidePanel.noCueLeft'),
    },
    {
      icon: <img src={YouAreAllDoneImg} />,
      message: t('notification.taskSidePanel.greatJobYouAreAllDone'),
    },
  ];

  useEffect(() => {
    setChats(groupByDate(cueNotesList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cueNotesList]);

  return (
    <React.Fragment>
      <div data-testid="task-items-list">
        {isCallingAPI ? (
          <>
            <Grid item xs={12} pb={2} pt={2}>
              <Skeleton variant={'rectangular'} width="95%" height="5px" />
            </Grid>
            <Grid container spacing={1} display="flex" flexDirection={'row'} item>
              <Grid item xs={1}>
                <Skeleton variant={'circular'} width="15px" height="15px" />
                <Skeleton variant={'circular'} width="15px" height="15px" sx={{ marginTop: '6px' }} />
              </Grid>
              <Grid item xs={11}>
                <Skeleton variant={'rectangular'} width="95%" height="70px" />
                <Skeleton variant={'rectangular'} width="95%" height="70px" />
                <Skeleton variant={'rectangular'} width="95%" height="70px" />
              </Grid>
            </Grid>
          </>
        ) : (
          <span>
            {chats?.length > 0 ? (
              <span>
                {chats &&
                  chats?.map((cue, index) => {
                    return (
                      <React.Fragment>
                        <DividerWithText cueNote={true}>{cue?.period}</DividerWithText>
                        {cue.chats.map(item => (
                          <>
                            <CueNoteItem
                              cueNote={item}
                              isCurrentUser={parseInt(item?.senderId) === userId ? true : false}
                              showFullText={
                                cueNotesList?.length > 0 &&
                                cueNotesList[cueNotesList?.length - 1] === item &&
                                expandLastCueNote
                              }
                            />
                            {!cueNotesDrawer &&
                              item?.taskId === lastCueNoteId &&
                              cueNotesList?.length > 0 &&
                              cueNotesList[cueNotesList?.length - 1] !== item && (
                                <DividerWithText>{t('notification.taskSidePanel.cueNotes.lastRead')}</DividerWithText>
                              )}
                          </>
                        ))}
                      </React.Fragment>
                    );
                  })}
              </span>
            ) : (
              <div data-testid="task-items-list-no-tasks">
                <div
                  className={classes.noTaskIconPlacement}
                  style={{ justifyItems: 'center', paddingTop: '9rem', paddingBottom: '30px', marginRight: '3rem' }}
                >
                  {socialIcons[randomIndex].icon}
                </div>
                <div
                  className={classes.noTaskIconMessage}
                  style={{ justifyItems: 'center', paddingTop: '9rem', paddingBottom: '30px' }}
                >
                  {socialIcons[randomIndex].message}
                </div>
              </div>
            )}
          </span>
        )}
      </div>
    </React.Fragment>
  );
};
