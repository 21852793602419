import { Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';

export const useUnitDetailsStyles = makeStyles<{ isError: boolean }>()((theme: Theme, { isError }) => ({
  textField: {
    '& .MuiFilledInput-root': {
      backgroundColor: theme.palette.system.grayChip,
    },
    '&:hover .MuiInputLabel-root': {
      color: theme.palette.system.primary,
    },
  },
  helperTextSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftHelperArea: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '5px',
  },
  maxCount: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: isError ? theme.palette.system.guardsmanRed : theme.palette.framework.system.coolGray,
  },
  addDateTimeBtn: {
    '& svg': {
      fill: `${theme.palette.system.neutralGray} !important`,
      '&:hover': {
        fill: `${theme.palette.system.main} !important`,
      },
    },
  },
  transparentButton: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));
