import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChip } from 'app/ComponentLibrary/Filter/ChipsContainer';
import { ISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import {
  Certification,
  ICompactStatusLookup,
  ILicenseLookup,
  ITransformedIssuedBy,
  NewUnit,
} from 'app/models/Unit/CreateUnit';
import { UnitFilterName } from '../Filter/UnitFilterConfig';
import { TFunction } from 'i18next';
import { ISkillSetGroup } from '@AMIEWEB/Facility/FacilityStore/Facility.model';
import { IUnitGridFields } from '../Grid/FacilityUnitsList';
import { IOrderUnitFacility, ISelectedSubCert } from '../Grid/FacilityOrderUnitGrid';
import { IFetchOrderUnitDetail } from '../../OrdersTab/UnitOrderWrapper';
import { IPostUnitDetailViewPageFields } from '../../CustomComponents/types';

export interface IFilterChipSelected {
  [UnitFilterName.Units]: any[] | null;
  [UnitFilterName.Status]: any[] | null;
  [UnitFilterName.Discipline]: any[] | null;
  [UnitFilterName.Specialty]: any[] | null;
  [UnitFilterName.SubSpecialty]: any[] | null;
  [UnitFilterName.LastUpdatedBy]: any[] | null;
  [UnitFilterName.FyreIntegration]: any[] | null;
  [UnitFilterName.CostCenter]: any[] | null;
  [UnitFilterName.LastUpdatedDate]: any[] | null;
}

interface IUnitSearchResults {
  positions: number;
  orders: number;
}
export interface IFilterSpecs {
  unitIdOpts: ISelection[];
  statusOpts: ISelection[];
  disciplineOpts: ISelection[];
  specialtyOpts: ISelection[];
  subSpecialtyOpts: ISelection[];
  lastUpdatedByOpts: ISelection[];
  fyreIntegrationOpts: ISelection[];
  costCenterOpts: ISelection[];
}

export const defaultFilterChipSelected = {
  [UnitFilterName.Units]: null,
  [UnitFilterName.Status]: null,
  [UnitFilterName.Discipline]: null,
  [UnitFilterName.Specialty]: null,
  [UnitFilterName.SubSpecialty]: null,
  [UnitFilterName.LastUpdatedBy]: null,
  [UnitFilterName.FyreIntegration]: null,
  [UnitFilterName.CostCenter]: null,
  [UnitFilterName.LastUpdatedDate]: null,
} as IFilterChipSelected;

export interface IUnitFilterChips {
  pipelineSelected: IChip[];
  [UnitFilterName.Units]: IChip[];
  [UnitFilterName.Status]: IChip[];
  [UnitFilterName.Discipline]: IChip[];
  [UnitFilterName.Specialty]: IChip[];
  [UnitFilterName.SubSpecialty]: IChip[];
  [UnitFilterName.LastUpdatedBy]: IChip[];
  [UnitFilterName.FyreIntegration]: IChip[];
  [UnitFilterName.CostCenter]: IChip[];
  [UnitFilterName.LastUpdatedDate]: IChip[];
}

export const defaultChips: IUnitFilterChips = {
  pipelineSelected: [],
  [UnitFilterName.Units]: [],
  [UnitFilterName.Status]: [],
  [UnitFilterName.Discipline]: [],
  [UnitFilterName.Specialty]: [],
  [UnitFilterName.SubSpecialty]: [],
  [UnitFilterName.LastUpdatedBy]: [],
  [UnitFilterName.FyreIntegration]: [],
  [UnitFilterName.CostCenter]: [],
  [UnitFilterName.LastUpdatedDate]: [],
};

export interface IFacilityUnitPage {
  unitsSourceData: NewUnit[];
  flattenedData: any[];
  filteredData?: any[];
  filterSpecs?: IFilterSpecs;
  filterChipSelected: IFilterChipSelected;
  chips: IUnitFilterChips;
  searchResults: IUnitSearchResults;
  loading: boolean;
  isUnitSelected: boolean;
  editMode: string;
  selectedUnit: NewUnit | null;
  certificatesFullName: Certification[];
  unitsClone: NewUnit | null;
  isStandardize: boolean | null;
  validationAlert: boolean;
  openCancelModel?: boolean;
  navToEdit?: { callingComponent: string };
  skillsSets?: ISkillSetGroup;
  transformedIssuedBy?: ITransformedIssuedBy[];
  licenseData?: ILicenseLookup[];
  compactStatus?: ICompactStatusLookup[];
  isCreateNewUnitFromOrder?: boolean | null;
  isUnitFormDirty?: boolean;
  isCreateNewUnit?: boolean;
  ordersUnit?: IOrderUnitFacility;
  selectedSubCert?: ISelectedSubCert[];
}

export const initialState: IFacilityUnitPage = {
  unitsSourceData: [],
  flattenedData: [],
  filteredData: undefined,
  filterSpecs: undefined,
  filterChipSelected: defaultFilterChipSelected,
  chips: defaultChips,
  searchResults: {
    positions: 0,
    orders: 0,
  },
  loading: false,
  isUnitSelected: false,
  editMode: '',
  selectedUnit: null,
  certificatesFullName: [],
  unitsClone: null,
  isStandardize: null,
  validationAlert: false,
  skillsSets: {
    operation: null,
    groups: [],
  },
  transformedIssuedBy: [],
  licenseData: [],
  compactStatus: [],
  isCreateNewUnitFromOrder: true,
  isUnitFormDirty: false,
  isCreateNewUnit: false,
  openCancelModel: false,
  ordersUnit: {
    paging: {
      pageNumber: 0,
      pageSize: 0,
      total: 0,
      positions: 0,
    },
    items: [],
  },
  selectedSubCert: [],
};

//Actions
const requestFacilityUnitsList = createAction<{
  id: number;
}>('GET_FACILITY_UNIT_DATA');

const requestFacilityUnitDetails = createAction<{
  id: number;
}>('GET_FACILITY_UNIT_DETAILS');

const deactivateFacilityUnit = createAction<{
  facilityId: number;
  unitId: number;
  isDisableContactsRequired: boolean;
  currentEmployeeId: number;
  currentEmployeeName: string;
  t: TFunction;
}>('DEACTIVATE_FACILITY_UNIT');

const updateFacilityUnitNotesStatus = createAction<{ notes?: string; status?: string }>(
  'GET_FACILITY_UNIT_NOTES_STATUS',
);

const requestFacilityUnitsCertificatesFullName = createAction<{
  requirementTypeIds: number[];
}>('GET_FACILITY_UNIT_CERTIFICATES_FULL_NAME');

const getSkillSetsForUnits = createAction<{
  unitId: number;
}>('GET_SKILL_SETS_FOR_FACILITY_UNITS');

const getIssuedBy = createAction('GET_ISSUED_BY');

const getLicenses = createAction<{
  id: number;
}>('GET_LICENSES');

const getCompactStatus = createAction('GET_COMPACT_STATUS');

const requestSaveUnitDetails = createAction<{
  payloadForSave: any;
}>('SAVE_FACILITY_UNITS');

const requestUpdateUnitViewPageFields = createAction<IPostUnitDetailViewPageFields>(
  'SAVE_FACILITY_UNITS_VIEW_PAGE_FIELDS',
);

const getOrdersByUnitId = createAction<IFetchOrderUnitDetail>('GET_ORDERS_UNIT');

export const FacilityUnit = createSlice({
  name: 'facilityUnits',
  initialState: initialState,
  reducers: {
    setFlattenedData(state, action: PayloadAction<IUnitGridFields[]>) {
      state.flattenedData = action.payload;
    },
    setUnitsSourceData(state, action: PayloadAction<NewUnit[]>) {
      state.unitsSourceData = action.payload;
    },
    setFilterSpecs(state, action: PayloadAction<IFilterSpecs>) {
      state.filterSpecs = action.payload;
    },
    setFilterChipSelected(state, action: PayloadAction<IFilterChipSelected>) {
      state.filterChipSelected = action.payload;
    },
    setFilterChips(state, action: PayloadAction<IUnitFilterChips>) {
      state.chips = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setFilteredData(state, action: PayloadAction<any[] | undefined>) {
      state.filteredData = action.payload;
    },
    setSearchResults(state, action: PayloadAction<IUnitSearchResults>) {
      state.searchResults = action.payload;
    },
    setIsUnitSelected(state, action: PayloadAction<boolean>) {
      state.isUnitSelected = action.payload;
    },
    setEditMode(state, action: PayloadAction<string>) {
      state.editMode = action.payload;
    },
    setSelectedUnit(state, action: PayloadAction<NewUnit>) {
      state.selectedUnit = action.payload;
    },
    setCertificatesFullName(state, action: PayloadAction<Certification[]>) {
      state.certificatesFullName = action.payload;
    },
    setUnitsClone(state, action: PayloadAction<NewUnit>) {
      state.unitsClone = action.payload;
    },
    setIsStandardize(state, action: PayloadAction<boolean | null>) {
      state.isStandardize = action.payload;
    },
    setValidationAlert(state, action: PayloadAction<boolean>) {
      state.validationAlert = action.payload;
    },
    setNavToEdit(state, action: PayloadAction<{ callingComponent: string }>) {
      state.navToEdit = action.payload;
    },
    reset() {
      return { ...initialState };
    },
    setSkillSetsForUnits(state, action: PayloadAction<any>) {
      state.skillsSets = action.payload;
    },
    setTransformedIssuedByLookupValue(state, action: PayloadAction<ITransformedIssuedBy[]>) {
      state.transformedIssuedBy = action.payload;
    },
    setLicenseData(state, action: PayloadAction<ILicenseLookup[]>) {
      state.licenseData = action.payload;
    },
    setCompactSatus(state, action: PayloadAction<ICompactStatusLookup[]>) {
      state.compactStatus = action.payload;
    },
    setCreateNewUnitFromOrder(state, action: PayloadAction<boolean>) {
      state.isCreateNewUnitFromOrder = action.payload;
    },
    setCreateNewUnit(state, action: PayloadAction<boolean>) {
      state.isCreateNewUnit = action.payload;
    },
    setUnitFormValidity(state, action: PayloadAction<boolean>) {
      state.isUnitFormDirty = action.payload;
    },
    setOrdersByUnitId(state, action: PayloadAction<IOrderUnitFacility>) {
      state.ordersUnit = action.payload;
    },
    setSelectedSubCert(state, action) {
      state.selectedSubCert = action.payload;
    },
    setOpenCancelModel(state, action: PayloadAction<{ openCancelModel: boolean }>) {
      state.openCancelModel = action.payload.openCancelModel;
    },
  },
});

const { actions } = FacilityUnit;

export const { reducer: FacilityUnitReducer, name: FacilityUnitKey } = FacilityUnit;
export const FacilityUnitActions = {
  ...actions,
  requestFacilityUnitsList,
  requestFacilityUnitDetails,
  deactivateFacilityUnit,
  updateFacilityUnitNotesStatus,
  requestFacilityUnitsCertificatesFullName,
  getSkillSetsForUnits,
  getIssuedBy,
  getLicenses,
  getCompactStatus,
  requestSaveUnitDetails,
  getOrdersByUnitId,
  requestUpdateUnitViewPageFields,
};
