import { Editor } from '@tinymce/tinymce-react';
import { Grid, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { IToken } from 'app/models/Notification/Notification';
import { applyDataToTemplate } from 'app/services/NotificationServices/TemplateService';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { ChannelType } from '../../Constants';
import { Attachments } from './EmailAttachment';
import PreviewAttachmentsWithUrl from './PreviewAttachmentsWithUrls';
const useStyles = makeStyles()(theme => ({
  paper: {
    border: '1px solid #000',
  },
  expandedPaper: {
    border: '1px solid #000',
    height: '80vh',
  },
  expandedPaperWarning: {
    border: '1px solid #000',
    height: '74vh',
  },
  attachment: {
    paddingLeft: '1%',
  },
  recipientGrid: {
    margin: '2%',
  },
  key: {
    color: '#B9BBBC',
    fontSize: '1rem',
    paddingBottom: '2%',
  },
  value: {
    color: '#555555',
    paddingRight: '2%',
    fontSize: '1rem',
    paddingBottom: '2%',
  },
  subject: {
    paddingTop: '1%',
  },
}));

interface ILoader {
  backdropLoader: boolean;
  templateSelectionGridLoader: boolean;
}
interface IPreviewProps {
  data: any;
  attachments: File[] | null;
  handleAttachments: (file: File[]) => void;
  urlAttachments: File[] | null;
  handleUrlAttachments: (file: File[]) => void;
  signatureSubstitution: IToken[];
  expanded: boolean;
  setLoader: (loader: ILoader) => void;
  loader: ILoader;
}

const joinedValue = (data, channel) => {
  if (channel === ChannelType.email) {
    const uniqueEmails = new Set(
      data.map(function (item) {
        return item?.name || item?.email;
      }),
    );

    return Array.from(uniqueEmails).join(' ; ');
  }

  if (channel === ChannelType.sms) {
    const uniquePhoneNumbers = new Set(
      data.map(function (item) {
        return item?.phoneNumber;
      }),
    );

    return Array.from(uniquePhoneNumbers).join(' ; ');
  }
};

const API_KEY = globalThis?.app?.env?.REACT_APP_TINYMCE_API_KEY;

export function Preview(props: IPreviewProps) {
  const {
    data,
    attachments,
    handleAttachments,
    expanded,
    signatureSubstitution = [],
    urlAttachments,
    handleUrlAttachments,
    setLoader,
    loader,
  } = props;
  const { classes } = useStyles();
  const globalData = useSelector(notificationSelection);

  const [body, setBody] = useState(data?.body);
  var signature = {};
  var substitution = {};
  useEffect(() => {
    (async () => {
      if (data?.body && data?.substitutions) {
        if (data?.body?.indexOf('{{') !== -1) {
          signatureSubstitution?.map((item: IToken) => {
            if (item && item.input) {
              signature[item?.input?.replace('{{', '').replace('}}', '')] = item?.value;
            }
          });

          data?.substitutions?.map((item: IToken) => {
            if (item && item.input) {
              substitution[item?.input?.replace('{{', '').replace('}}', '')] = item?.value;
            }
          });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const tableData = data?.tableData;
      const body = data?.body?.replaceAll('&gt;', '>');
      const previewRequestData = {
        body: body,
        brand: data?.brand,
        signatureSubstitutions: signature,
        substitutions: substitution,
        tableData: tableData,
        id: null,
      };
      applyDataToTemplate(previewRequestData).then(response => {
        setBody(response);
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoader({ ...loader, backdropLoader: false });
  }, []);

  return (
    <div className={expanded ? (data?.subject ? classes.expandedPaper : classes.expandedPaperWarning) : classes.paper}>
      <Grid className={classes.recipientGrid}>
        <Typography>
          {data?.tos && <span className={classes.key}>{`To: `}</span>}
          {data?.tos && <span className={classes.value}>{joinedValue(data?.tos, data.channel)}</span>}
          {data?.ccs && data?.ccs?.length > 0 && <span className={classes.key}>{`CC: `}</span>}
          {data?.ccs?.length > 0 && data?.ccs && (
            <span className={classes.value}>{joinedValue(data?.ccs, data.channel)}</span>
          )}
          {data?.bccs?.length > 0 && data?.bccs && <span className={classes.key}>{`BCC: `}</span>}
          {data?.bccs?.length > 0 && data?.bccs && (
            <span className={classes.value}>{joinedValue(data?.bccs, data.channel)}</span>
          )}
        </Typography>
        {data?.subject && (
          <Typography className={classes.subject}>
            {data?.tos && <span className={classes.value}>{data?.subject}</span>}
          </Typography>
        )}
      </Grid>

      {data?.body && (
        <Editor
          disabled={true}
          value={body}
          apiKey={API_KEY}
          init={{
            menubar: false,
            statusbar: false,
            toolbar: false,
            readonly: true,
            max_height: 500,
            min_height: 360, //Added min height to fix flickering issue while opening Preview modal (with signature)
            plugins: 'noneditable autoresize',
            branding: false,
            elementpath: false,
            block_formats: 'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3',
            forced_root_block: '',
            force_br_newlines: false,
            force_p_newlines: false,
            inline_boundaries: false,
          }}
        />
      )}

      {
        <div className={classes.attachment}>
          {!!globalData.email.data!.attachmentCannotBeRemoved ? (
            <PreviewAttachmentsWithUrl attachmentUrls={globalData.email.data!.attachmentUrls!} />
          ) : (
            <Attachments
              data={attachments}
              readonly
              uploadFiles={handleAttachments}
              dataUrlAttachments={urlAttachments}
              handleDataUrlAttachments={handleUrlAttachments}
            />
          )}
        </div>
      }
    </div>
  );
}
