import { CustomTooltip } from '@AMIEWEB/Common';
import React from 'react';
import { isStrikOrder } from 'utils/common/Placements/Automation';
import { Button } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';

export const CreatePlacementBtn = ({
  editStarted = false,
  division,
  orderType,
  positionsAvailable,
  maxFilesSent,
  ...props
}) => {
  const { t } = useTranslation();

  const { disableBtn, strikeOrder, createScreenEnabled } = React.useMemo(() => {
    const isStrike = isStrikOrder({
      division,
      orderType,
    });
    const isCreateScreenEnabled = Number(positionsAvailable) === 0;
    return {
      strikeOrder: isStrike,
      createScreenEnabled: isCreateScreenEnabled,
      disableBtn: (isStrike && (isCreateScreenEnabled || maxFilesSent)) || editStarted,
    };
  }, [editStarted, positionsAvailable, division, orderType, maxFilesSent]);

  return (
    <CustomTooltip
      tooltipContent={
        !editStarted
          ? 'Max Files Sent'
          : !strikeOrder && createScreenEnabled
          ? 'Save or Clear Changes to Enable Create Screen'
          : 'Save or Clear Changes to Enable Create Placement'
      }
      disabled={!disableBtn}
    >
      <Button variant="contained" color="primary" size="medium" onClick={props.onClick} disabled={disableBtn}>
        {!strikeOrder && createScreenEnabled ? t('placement.create.createScreen') : t('placement.create.create')}
      </Button>
    </CustomTooltip>
  );
};
