import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'redux-injectors';
import { makeStyles } from 'tss-react/mui';
import Column from '../Columns/Column';
import {
  comparisionTableActions,
  comparisionTableReducer,
  comparisionTableSliceKey,
} from '../store/comparisiontable.redux';
import { selectExpandableCells } from '../store/comparisionTable.selectors';
import { ComparisionTableColumnTypes, IComparisionTableProps } from '../types';

const useStyles = makeStyles<any>()(() => ({
  container: {
    width: '100%',
  },
  details: {
    width: '430px',
    overflowX: 'scroll',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
  },
  data: {
    width: 'calc(100% - 224px)',
    overflowX: 'scroll',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '14px',
      height: '14px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent !important',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D2D2D2',
      borderRadius: '10px',
    },
  },
}));


// TODO - @Abhishek Wilson - Configure this component to use the redux states and remove local useStates , Create a custom hook to expose additional functionalities for this component 
const ComparisionTable = (props: IComparisionTableProps) => {
  useInjectReducer({ key: comparisionTableSliceKey, reducer: comparisionTableReducer });
  const wrapperRef = React.useRef(null);
  const detailsRef = React.useRef(null);
  const tableDataRRef = React.useRef(null);
  const { classes } = useStyles({});
  const remainingWidth = props?.width ? props.width - 396 : 100;
  const dispatch = useDispatch();
  const expandableStates = useSelector(selectExpandableCells);
  const [expandableCells, setExpandableCells] = React.useState<any>({});
  const [temp , setTemp] = React.useState<any>({});
  const [wrapperWidth, setWrapperWidth] = React.useState<number>(null);

  React.useEffect(() => {
    let expandableCells = {};
    props.tableRows?.forEach(item => {
      if (item?.isExpandable) {
        expandableCells = {
          ...expandableCells,
          [item.name]: {
            expanded: false,
            maxheight: item?.height,
            cellClicked: 0,
          },
        };
      }
    });
    setExpandableCells(expandableCells);
    setTemp(expandableCells)
    dispatch(comparisionTableActions.setExpandableCells(expandableCells));
   
  }, []);

  React.useEffect(() => {
    const width =tableDataRRef.current && tableDataRRef.current.offsetWidth;
    setWrapperWidth(width);
  },[props?.expanded])

  React.useEffect(() => {}, [expandableCells]);

  React.useEffect(() => {
    const div1 = detailsRef.current;
    const div2 = tableDataRRef.current;

    const handleScrollA = () => {
      div2.scrollTop = div1.scrollTop;
    };
    const handleScrollB = () => {
      div1.scrollTop = div2.scrollTop;
    };

    div1.addEventListener('scroll', handleScrollA);
    div2.addEventListener('scroll', handleScrollB);

    return () => {
      div1.removeEventListener('scroll', handleScrollA);
      div2.removeEventListener('scroll', handleScrollB);
    };
  }, []);

  const onExpandClick = (item, exp, columnData) => {
    const expanded = exp ? true : expandableStates?.[item.name]?.cellClicked === columnData[item.name] ? false : true;
    const lastCell = !expanded ? columnData[item.name] : expandableStates?.[item.name]?.cellClicked;
    const maxheight = expandableStates?.[item.name]?.maxheight
      ? expandableStates?.[item.name]?.maxheight < item?.height
        ? item?.height
        : expandableStates?.[item.name]?.maxheight
      : item?.height;
    const expand = {
      ...expandableStates,
      [item.name]: {
        expanded: expanded,
        maxheight: maxheight,
        cellClicked: lastCell,
      },
    };
    setExpandableCells(expand);
    let cellClicked, expand1, maxHeight;
    if (exp) {
      cellClicked = temp?.[item.name]?.cellClicked + 1;
      expand1 = true;
      maxHeight = temp?.[item.name]?.maxheight
        ? temp?.[item.name]?.maxheight < item?.height
          ? item?.height
          : temp?.[item.name]?.maxheight
        : item?.height;
    } else {
      cellClicked = temp?.[item.name]?.cellClicked - 1;
      expand1 = cellClicked > 0;
      maxHeight = temp?.[item.name]?.maxheight
        ? temp?.[item.name]?.maxheight < item?.height
          ? item?.height
          : temp?.[item.name]?.maxheight
        : item?.height;
    }
    const exxp = {
      ...temp,
      [item.name]: {
        expanded: expand1,
        maxheight: maxHeight,
        cellClicked: cellClicked,
    }
  }
    setTemp(exxp)
    dispatch(comparisionTableActions.setExpandableCells(exxp));
  };

  const setRowHeights = (itemName, expanded, cell, height = undefined, expandedCellsInfo) => {
    const exp = expanded ? true : expandableStates?.[itemName]?.cellClicked === cell ? false : true;
    const lastCell = exp ? cell : expandableStates?.[itemName]?.cellClicked;
    const maxheight = height
      ? expandableStates?.[itemName]?.maxheight < height
        ? height
        : expandableStates?.[itemName]?.maxheight
      : expandableStates?.[itemName]?.maxheight;
    const expand = {
      ...expandableStates,
      [itemName]: {
        cellClicked: lastCell,
        expanded: exp,
        maxheight: maxheight,
      },
    };
    setExpandableCells(expand);
    const cellClicked = expanded ? expandedCellsInfo?.[itemName]?.cellClicked + 1 : expandedCellsInfo?.[itemName]?.cellClicked - 1;
    const expand1 = expanded ? true : cellClicked > 0;
    const maxHeight = expand1 ? expandableStates?.[itemName]?.maxheight < height ? height : expandableStates?.[itemName]?.maxheight : props.tableRows?.find(item => item?.name === itemName)?.height;
    const newExpandableCells = {
      ...expandableStates,
      [itemName]: {
        cellClicked: cellClicked,
        expanded: expand1,
        maxheight: maxHeight,
      }
    }
    setTemp(newExpandableCells)
    const item = expandableStates?.[itemName];
    const newItem = {
      ...expandableStates,
      [itemName]: {
        ...item,
        maxheight: maxHeight,
      }
    }
    dispatch(comparisionTableActions.setExpandableCells(newExpandableCells));
  };

  return (
    <div ref={wrapperRef} style={{ display: 'flex', height: props?.height || '100%' }} data-testid='Comparision-Table'>
      <div ref={detailsRef} className={classes.details} style={{ display: 'flex' }} data-testid='Reference-Table'>
        {/* populate the columns name and reference data here */}
        <Column
          columnData={{}}
          key={''}
          detailsCell
          tableRows={props.tableRows}
          rowAttributes={temp}
          setRowHeights={(itemName, height, exp, cell, expandedCellsInfo) => setRowHeights(itemName, exp, cell, height, expandedCellsInfo)}
          data-testid='Details-column'
        />
        {props.referenceData !== undefined ? (
          <Column
            columnData={props.referenceData}
            referenceCell
            tableRows={props.tableRows}
            columnType={ComparisionTableColumnTypes.referenceCell}
            onExpandClick={(item, exp, columnData) => onExpandClick(item, exp, columnData)}
            setRowHeights={(itemName, height, exp, cell, expandedCellsInfo) => setRowHeights(itemName, exp, cell, height, expandedCellsInfo)}
            rowAttributes={temp}
            columnIndex={'A'}
            data-testid='Reference-Column'
          />
        ) : null}
      </div>
      <div ref={tableDataRRef} className={classes.data} style={{ display: 'flex' }}>
        {/* populate the table data here */}
        {props.tableData &&
          props.tableData.map((item, index) => {
            return (
              <div>
                <Column
                  columnData={item}
                  comparisionCell
                  tableRows={props.tableRows}
                  width={wrapperWidth ? wrapperWidth / props.tableData?.length: null}
                  columnType={ComparisionTableColumnTypes.comparisionCell}
                  onExpandClick={(item, exp, columnData) => onExpandClick(item, exp, columnData)}
                  setRowHeights={(itemName, height, exp, cell, expandedCellsInfo) => setRowHeights(itemName, exp, cell, height, expandedCellsInfo)}
                  rowAttributes={temp}
                  index={index}
                  tableData={props?.tableData}
                  columnIndex={`${index}`}
                  data-testid={`Comparision-Column-${index}`}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ComparisionTable;
