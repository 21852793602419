import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { QualificationType } from 'app/models/Unit/CreateUnit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import {
  PreviewContent,
  PreviewContentTitle,
} from '../../../OrderCreationForm/DefineUnit/UnitPreview/UnitPreviewDesign';
import { RequirementLabel } from '@AMIEWEB/Order/OrderCreationForm/DefineUnit/UnitPreview/Common/RequirementLabel';

interface Props {
  data: {
    state: string | null;
    qualification: QualificationType | null;
  };
  disableEdit?: boolean;
  handleEditOrder?: () => void;
}

const useStyles = makeStyles()({
  container: {
    '&:hover': {
      '& [data-id="edit-icon"]': {
        opacity: 1,
        transition: 'opacity 0.25s',
      },
    },
  },
  editItem: {
    opacity: 0,
    transition: 'opacity 0.25s',
  },
  editIcon: {
    height: '20px',
    width: '20px',
    cursor: 'pointer',
    color: '#8E9195',
  },
});

export const OrderDetailsSkills = (props: Props) => {
  const { qualification } = props.data;
  const { disableEdit, handleEditOrder } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <PreviewContent variant="outlined" elevation={0}>
          <Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <PreviewContentTitle>{t('order.createUnit.qualifications.skills')}</PreviewContentTitle>
                </Grid>
                <Grid item className={classes.editItem} data-id="edit-icon">
                  {!disableEdit ? (
                    <EditIcon
                      id={'OrderDetailsSkillsEditTrigger'}
                      className={classes.editIcon}
                      onClick={handleEditOrder}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {qualification?.skillChecklists?.length > 0 && (
              <Grid container style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                {qualification.skillChecklists.map((skill, key) => {
                  return (
                    <Grid item direction="column" key={key}>
                      <RequirementLabel isRequired={skill.isRequired} contentValue={{ section1: `${skill.text}` }} />
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Grid>
        </PreviewContent>
      </Grid>
    </>
  );
};
