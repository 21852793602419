import { Box, Divider, Grid, Skeleton } from 'amn-ui-core';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React from 'react';
import { Title } from '../../../ProfileTab/Cards/styles';
import { useTranslation } from 'react-i18next';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips';
import { RowSection } from '../../Common/RowSection';
import { useDecision } from '@optimizely/react-sdk';
import { ff_summaryTabIconsUpdate } from 'app/constants/FeatureFlags/Candidate/Keys';
import { AddEducationDialog } from '../../../Common/AddEducationDialog';
import { useStyles } from './utils';
import { usePromiseTracker } from 'react-promise-tracker';
import { theme } from 'styles/global-styles';

function EducationalSummaryUI({
  open,
  groupedSkillSetsList,
  skillsetsList,
  handleClose,
  handleNavigate,
  showAllEducation,
}) {
  const { t } = useTranslation();
  const [summaryTabIconsUpdateDecision] = useDecision(ff_summaryTabIconsUpdate);
  const { classes } = useStyles();
  const { promiseInProgress } = usePromiseTracker({ area: 'get-candidate-details', delay: 0 });

  return (
    <>
      <LayoutGridItem>
        <Box className={classes.container}>
          <Box className={classes.children}>
            <LayoutGrid direction="column" spacing={1}>
              <Title className={classes.title}>
                {t('candidate.educationCard.title')}
                <span
                  className={
                    skillsetsList?.length > 0 || groupedSkillSetsList?.length > 0
                      ? classes.profileInlineLink
                      : classes.profileInlineLinkDisabled
                  }
                  onClick={showAllEducation}
                >
                  {t('candidate.virtualInterviewCard.all')}
                </span>
              </Title>
              <span className={classes.sectionDivider}>&nbsp;</span>
              <Divider />
              <LayoutGrid
                direction="row"
                spacing={1}
                justifyContent="space-between"
                wrap="nowrap"
                className={classes.content}
              >
                {promiseInProgress ? (
                  <LayoutGridItem xs={12}>
                    <Skeleton />
                    <Skeleton />
                  </LayoutGridItem>
                ) : (
                  <LayoutGridItem style={{ paddingTop: '25px' }}>
                    <LayoutGrid direction="column" spacing={1} className={classes.rowItem}>
                      {skillsetsList?.length ? (
                        skillsetsList?.map((item, index) => {
                          if (item.skillset !== '' && item.skillset !== null) {
                            return (
                              <LayoutGridItem key={index}>
                                <div style={{ marginTop: 1 }}>
                                  <CustomTooltip standardMargin tooltipContent={item.tooltipMessage}>
                                    <RowSection
                                      header={item.skillset}
                                      data={''}
                                      preData={item.interviewStatus === 2}
                                      color={theme.palette.system.darkGreenForIcon}
                                    />
                                  </CustomTooltip>
                                </div>
                              </LayoutGridItem>
                            );
                          }
                        })
                      ) : (
                        <RowSection
                          header={
                            <Grid container direction="column">
                              <Grid item>{t('candidate.educationCard.educationUnavailableLine1')}</Grid>
                              <Grid item>{t('candidate.educationCard.educationUnavailableLine2')}</Grid>
                            </Grid>
                          }
                          data={''}
                          preData={''}
                          color={''}
                          showAlert={true}
                          showHalfwayProgress={true}
                          showHalfwayProgressSecondaryIcon={true}
                        />
                      )}
                      {summaryTabIconsUpdateDecision.enabled && groupedSkillSetsList?.length > 0
                        ? groupedSkillSetsList?.map((item, index) => {
                            return (
                              <LayoutGridItem key={index}>
                                <div className={classes.rowSectionMargin}>
                                  <CustomTooltip standardMargin tooltipContent={item.tooltipMessage}>
                                    <RowSection
                                      header={item.skillset}
                                      data={''}
                                      preData={item.interviewStatus === 2}
                                      color={theme.palette.system.darkGreenForIcon}
                                      showHalfwayProgress={item.showIncompleteAlert}
                                      showHalfwayProgressSecondaryIcon={true}
                                      showAlert={item.showAlert}
                                    />
                                  </CustomTooltip>
                                </div>
                              </LayoutGridItem>
                            );
                          })
                        : skillsetsList.length === 0 && (
                            <RowSection
                              header={t('candidate.educationCard.noSkillsets')}
                              data={''}
                              preData={''}
                              color={''}
                              showAlert={true}
                            />
                          )}
                    </LayoutGrid>
                  </LayoutGridItem>
                )}
                <LayoutGridItem onClick={handleNavigate} style={{ paddingTop: 2 }}>
                  <span className={classes.profileInlineLink}>{t('candidate.profileSummaryCard.add')}</span>
                </LayoutGridItem>
              </LayoutGrid>
            </LayoutGrid>
            {open && <AddEducationDialog open={open} handleClose={handleClose} />}
          </Box>
        </Box>
      </LayoutGridItem>
    </>
  );
}

export default EducationalSummaryUI;
