import { FormActions } from '@AMIEWEB/Order/OrderDetails/OrderPreferences/customComponents';
import { placementStage } from '@AMIEWEB/Order/OrderDetails/OrderPreferences/store/OrderPreference.redux';
import { Box, Grid, Skeleton, Typography } from 'amn-ui-core';
import React, { useContext, useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectBookingFacilityReset,
  selectFacilityPreferences,
  selectFacilityTemplateStatus,
} from 'store/redux-store/facility-preferences/selectors';
import { facilityPreferenceActions } from 'store/redux-store/facility-preferences/slice';
import { makeStyles } from 'tss-react/mui';
import { AddOnContext } from '../FacilityPreferences';
import { CustomSelect } from '@AMIEWEB/Placement/CreatePlacement/CustomComponents/CustomSelect';
import { selectUser } from 'oidc/user.selectors';
import { getVal } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/ProfileTab/Education/EducationHelper';
import { useDecision } from '@optimizely/react-sdk';
import { ff_facility_ui_newpreference_rfo_to_sent } from 'app/constants/FeatureFlags/Facility/Keys';

const useStyles = makeStyles<{ isFetchingPreferences: boolean }>()((theme, { isFetchingPreferences }) => ({
  btn: {
    padding: '6px 16px',
  },
  checkboxContainer: {
    marginLeft: theme.spacing(2),
  },
  wfcToRfoContainer: {
    paddingTop: '4px !important',
  },
  candidateTaskContainer: {
    paddingTop: `${isFetchingPreferences ? 6 : 0}px !important`,
  },
  subTitle: {
    fontWeight: 450,
  },
}));

export const BookingContent = () => {
  const { t } = useTranslation();
  const { userInfo } = useSelector(selectUser);
  const [rfoFlag] = useDecision(ff_facility_ui_newpreference_rfo_to_sent);
  const { confirmationTemplates, facilityTemplate } = useSelector(selectFacilityPreferences);
  const isLoading = useSelector(selectFacilityTemplateStatus);
  const isReset = useSelector(selectBookingFacilityReset);

  const { promiseInProgress: isFetchingConfirmationTemplates } = usePromiseTracker({
    area: `get-confirmation-templates-${placementStage.submission}`,
    delay: 0,
  });

  const { promiseInProgress: isFetchingFacilityTemplate } = usePromiseTracker({
    area: `get-facility-template-${placementStage.submission}`,
    delay: 0,
  });

  const isFetchingPreferences = useMemo(
    () => isFetchingConfirmationTemplates || isFetchingFacilityTemplate || isLoading,
    [isFetchingConfirmationTemplates, isFetchingFacilityTemplate, isLoading],
  );

  const { classes } = useStyles({ isFetchingPreferences });

  const formMethods = useForm({
    defaultValues: { confirmationTemplates: { value: '', name: '' } },
  });

  const { facilityId } = useContext(AddOnContext);
  const dispatch = useDispatch();

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
    setValue,
  } = formMethods;

  const defaultFacilityTemplate = useMemo(() => {
    if (facilityTemplate && confirmationTemplates) {
      return confirmationTemplates.find(template => template.value === String(facilityTemplate.confirmationTemplateId));
    }
  }, [facilityTemplate, confirmationTemplates]);

  useEffect(() => {
    if (facilityTemplate && confirmationTemplates && defaultFacilityTemplate) {
      setValue('confirmationTemplates', defaultFacilityTemplate, { shouldDirty: false });
    }
  }, [defaultFacilityTemplate]);

  const onSubmit = data => {
    dispatch(
      facilityPreferenceActions.updateFacilityTemplate({
        facilityId: facilityId,
        confirmationTemplateId: Number(data.confirmationTemplates.value),
        updatedBy: userInfo.employeeId,
        confirmationTemplateName: data.confirmationTemplates.name,
        facilityForm: false,
      }),
    );
  };

  const handleSelectChange = (booking) => {
    const bookingFacilityTemplate = {
      value: booking.value,
      name: booking.name,
    };
    dispatch(facilityPreferenceActions.setFacilityBookingTemplates(bookingFacilityTemplate));
    dispatch(facilityPreferenceActions.setIBookingFacilityDirty(true));
  };

  const resetForm = () => {
    reset({ confirmationTemplates: defaultFacilityTemplate });
    dispatch(facilityPreferenceActions.setBookingFacilityReset(false));
    dispatch(facilityPreferenceActions.setIBookingFacilityDirty(false));
  };

  useEffect(() => {
    if (isReset) {
      resetForm();
    }
  }, [isReset]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="body1" classes={{ root: classes.subTitle }}>
              {t(`Client Confirmation Template *`)}
            </Typography>
          </Grid>
          <Grid item marginLeft={0}>
            <Controller
              control={control}
              name="confirmationTemplates"
              render={({ ref, value, ...rest }) =>
                isFetchingPreferences ? (
                  <Skeleton variant="rectangular" />
                ) : (
                  <CustomSelect value={value} options={confirmationTemplates} label={t('Template')} onSelectedValue={handleSelectChange} {...rest} />
                )
              }
            />
          </Grid>
          {!rfoFlag.enabled &&
            <Grid item>
              <FormActions
                isSaving={isLoading}
                btnRootClass={classes.btn}
                onCancel={resetForm}
                disableSubmit={!isDirty}
                disableCancel={!isDirty}
              />
            </Grid>}
        </Grid>
      </form>
    </FormProvider>
  );
};
