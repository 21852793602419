import { FormatDate } from 'app/components/Notification/Tasks/Common/FormatData';
import { ITypeAheadOption } from 'app/models/Orders/OrderDetails';
import {
  IAllEmployees,
  IFormatedExternalTaskData,
  IFormatedInternalTaskData,
  IPlacementEmployees,
  IUser,
} from 'app/models/Tasks/Tasks';
import { NotificationSender } from 'app/models/Tasks/Tasks';
import { getPlacementEmployees } from 'app/services/TaskServices/TaskServices';
import moment from 'moment-timezone';

export const GeneratePlacementEmpObject = (placement: IPlacementEmployees[]) =>
  !!placement && placement.length > 0
    ? placement?.map(emp => {
        const parsedOption = {
          empId: emp.employeeId,
          label: emp.firstName + ' ' + emp.lastName,
          role: emp.roleDescription,
        };
        const option: ITypeAheadOption = {
          object: parsedOption,
          label: '',
        };
        return option;
      })
    : [];

export const GenerateAllEmployeeObject = (employees: IAllEmployees[]) =>
  !!employees && employees.length > 0
    ? employees?.map(emp => {
        const parsedOption = {
          empId: emp.employeeId,
          label: emp.employeeFirstName + ' ' + emp.employeeLastName,
          role: emp.employeeRoleName,
        };
        const option: ITypeAheadOption = {
          object: parsedOption,
          label: '',
        };
        return option;
      })
    : [];

export const GenerateAllEmployeeObjectInitated = (employees: IAllEmployees[]) =>
  !!employees && employees.length > 0
    ? employees?.map(emp => {
        const parsedOption = {
          id: emp.employeeId,
          label: emp.employeeFirstName + ' ' + emp.employeeLastName,
        };

        return parsedOption;
      })
    : [];

export const GenerateAllInternalTaskDataObject = (taskData: any[]) =>
  !!taskData && taskData.length > 0
    ? taskData?.map(data => {
        const createdByUser = new IUser(data.senderFirstName, data.senderLastName, data.senderId);

        const TaskParsedData: IFormatedInternalTaskData = {
          rootTaskId: data.rootTaskId,
          placementId: data.placementId,
          assignedToFirstName: data.assignedToFirstName,
          assignedToLastName: data.assignedToLastName,
          taskId: data.taskId,
          statusId: data.statusId,
          companyId: data.companyId,
          rootTaskCategoryId: data.rootTaskCategoryId,
          userRoleId: data.userRoleId,
          assignedToId: data.assignedToId,
          task: data.task,
          description: data.description,
          taskName: data.taskName,
          taskCategoryId: data.taskCategoryId,
          taskCategory: data.taskCategory,
          userRole: data.userRole,
          dueDate: data.dueDate,
          senderFirstName: data.senderFirstName,
          senderLastName: data.senderLastName,
          ccValues: data.ccs?.map(t => new IUser(t.firstName, t.lastName, t.id, t.statusId)),
          createdBy: createdByUser,
          assignedTo: data.tos?.map(t => new IUser(t.firstName, t.lastName, t.id, t.statusId)),
          statusUpdateReason: data?.statusUpdateReason,
          statusUpdatedByName: data?.statusUpdatedByName,
          statusUpdatedById: data?.statusUpdatedById,
          statusUpdatedDate: data?.statusUpdatedDate,
          isAMIEWebRequest: data?.isAMIEWebRequest,
          subTaskCount: data?.subTaskCount,
          taskPriorityId: data?.taskPriorityId
        };
        return TaskParsedData;
      })
    : [];

export const convertAssignedUserData = (emp: IUser) => {
  const parsedOption = {
    empId: emp?.id,
    label: emp?.firstName + ' ' + emp?.lastName,
    role: '',
  };
  const option: ITypeAheadOption = {
    object: parsedOption,
    label: '',
  };
  return option;
};

export const convertMultipleAssignedUserData = (emp: IUser[]) => {
  const employees: ITypeAheadOption[] = [];
  emp?.map(e => {
    const parsedOption = {
      empId: e?.id,
      label: e?.firstName + ' ' + e?.lastName,
      role: '',
    };
    const option: ITypeAheadOption = {
      object: parsedOption,
      label: '',
    };
    employees.push(option);
  });

  return employees;
};

export const convertUserData = (employees: IUser[]) =>
  !!employees && employees.length > 0
    ? employees?.map(emp => {
        const parsedOption = {
          empId: emp?.id,
          label: emp?.firstName + ' ' + emp?.lastName,
          role: '',
        };
        const option: ITypeAheadOption = {
          object: parsedOption,
          label: '',
        };
        return option;
      })
    : [];

export const convertAssignedToUserData = (emp: any[]) => {
  const employees: ITypeAheadOption[] = [];
  emp?.map(e => {
    const parsedOption = {
      empId: e?.assignedToId,
      label: e?.assignedToFirstName + ' ' + e?.assignedToLastName,
      role: '',
    };
    const option: ITypeAheadOption = {
      object: parsedOption,
      label: '',
    };
    employees.push(option);
  });

  return employees;
};

export const GenerateInternalTaskDataObject = (data: any) => {
  const createdByUser = new IUser(data.createdByFirstName, data.createdByLastName, data.createdById);

  const TaskParsedData: IFormatedInternalTaskData = {
    rootTaskId: data.rootTaskId,
    placementId: data.placementId,
    assignedToFirstName: data.assignedTo[0]?.assignedToFirstName,
    assignedToLastName: data.assignedTo[0]?.assignedToLastName,
    taskId: data.taskId,
    statusId: data.statusId,
    rootTaskCategoryId: data.rootTaskCategoryID,
    assignedToId: data.assignedTo[0]?.assignedToId,
    task: data.task,
    description: data.description,
    taskCategory: data.taskCategory,
    taskCategoryId: data.taskCategoryId,
    taskName: data.task,
    dueDate: data.dueDate,
    senderFirstName: data.createdByFirstName,
    senderLastName: data.createdByLastName,
    ccValues: data.cc?.map(t => new IUser(t.ccFirstName, t.ccLastName, t.ccId, t.statusId)),
    createdBy: createdByUser,
    assignedTo: convertAssignedToUserData(data.assignedTo),
    companyId: data.companyId,
    isAMIEWebRequest: data?.isAMIEWebRequest,
  };
  return TaskParsedData;
};

export const checkIsLatestCCChanged = (data, initialState) => {
  const intCC = convertUserData(data);
  const latestCC = intCC
    ?.map((a: ITypeAheadOption) => a?.object)
    .map(d => d?.empId)
    .sort((a, b) => a - b);
  const intArr = initialState?.cc?.sort((a, b) => a - b);
  const result = JSON.stringify(intArr) === JSON.stringify(latestCC);
  return result;
};

export const checkIsLatestAssignedUsersChanged = (data, initialState) => {
  const latestAssignedUsers = data
    ?.map((a: ITypeAheadOption) => a?.object)
    .map(d => d?.empId)
    .sort((a, b) => a - b);
  const intArr = initialState?.assignedTo
    ?.map((a: ITypeAheadOption) => a?.object)
    .map(d => d?.empId)
    .sort((a, b) => a - b);
  const result = JSON.stringify(intArr) === JSON.stringify(latestAssignedUsers);
  return result;
};

export const formatTaskCategoryList = list => {
  return !!list && list.length > 0
    ? list?.map(l => ({ ...l, id: `${l.categoryId}-${l.taskId}`, groupBy: l.title, title: l.taskName }))
    : [];
};

export const updateReadNotification = (logId, employeeId) => {
  return {
    sender: NotificationSender,
    contactId: employeeId.toString(),
    isRead: true,
    channel: 'Alert',
    readOn: moment.tz(new Date(), 'America/Los_Angeles').format(),
    notificationLogIds: [logId],
  };
};

export const getFileName = (url: string) => {
  var fileName = url ?? '';
  if (fileName.indexOf('?') > 0 && fileName.indexOf('/') > 0) {
    const decodedURI = decodeURIComponent(url);
    const URIWithoutToken = decodedURI.substring(0, decodedURI.lastIndexOf('?'));
    fileName = URIWithoutToken.substring(URIWithoutToken.lastIndexOf('/') + 1);
  }
  if (fileName.indexOf('/') > 0) {
    fileName = fileName.substring(fileName.lastIndexOf('/') + 1);
  }
  return fileName?.length > 15 ? fileName?.substring(0, 15) + ' ...' : fileName;
};

export const GenerateExternalTaskDataObject = (taskData: any[]) =>
  !!taskData && taskData.length > 0
    ? taskData?.map(data => {
        const createdByUser = new IUser(data.senderFirstName, data.senderLastName, data.senderId);
        const assignedTo = new IUser(data?.assignedToFirstName, data.assignedToLastName, data?.candidateId);
        const TaskParsedData: IFormatedExternalTaskData = {
          rootTaskId: data.rootTaskId,
          placementId: data.placementId,
          assignedToFirstName: data.assignedToFirstName,
          assignedToLastName: data.assignedToLastName,
          taskId: data.id,
          statusId: data.statusId,
          companyId: data.companyId,
          rootTaskCategoryId: data.rootTaskCategoryId,
          userRoleId: data.userRoleId,
          assignedToId: data.assignedToId,
          task: data.taskName,
          description: data.description,
          taskName: data.taskName,
          taskCategoryId: data.taskCategoryId,
          taskCategory: data.taskCategory,
          userRole: data.userRole,
          dueDate: data.dueDate,
          senderFirstName: data.senderFirstName,
          senderLastName: data.senderLastName,
          ccValues: data.cc?.map(t => new IUser(t.ccFirstName, t.ccLastName, t.ccId)),
          createdBy: createdByUser,
          assignedTo: assignedTo,
          statusUpdateReason: data?.statusUpdateReason,
          statusUpdatedByName: data?.statusUpdatedByName,
          statusUpdatedById: data?.statusUpdatedById,
          statusUpdatedDate: data?.statusUpdatedDate,
          candidateId: data?.candidateId,
          taskNameId: data?.taskNameId,
          isAMIEWebRequest: data?.isAMIEWebRequest,
          categorySubType1: data?.categorySubType1,
          categorySubType2: data?.categorySubType2,
        };
        return TaskParsedData;
      })
    : [];

export const GenerateExternalTaskData = (data: any) => {
  const createdByUser = new IUser(
    data?.senderFirstName ?? data?.createdByFirstName,
    data?.senderLastName ?? data?.createdByLastName,
    data?.senderId ?? data?.createdById,
  );
  const assignedTo = new IUser(
    data?.assignedToFirstName ?? data?.assignedToCandidateFirstName,
    data?.assignedToLastName ?? data?.assignedToCandidateLastName,
    data?.candidateId,
  );
  const TaskParsedData: IFormatedExternalTaskData = {
    rootTaskId: data?.rootTaskId ?? null,
    placementId: data?.placementId,
    assignedToFirstName: data?.assignedToFirstName ?? assignedTo?.firstName,
    assignedToLastName: data?.assignedToLastName ?? assignedTo?.lastName,
    taskId: data?.id,
    statusId: data?.statusId,
    companyId: data?.companyId,
    rootTaskCategoryId: data?.rootTaskCategoryId ?? null,
    userRoleId: data?.userRoleId,
    assignedToId: data?.assignedToId ?? assignedTo?.id,
    task: data?.taskName,
    description: data?.description,
    taskName: data?.taskName,
    taskCategoryId: data?.taskCategoryId,
    taskCategory: data?.taskCategory,
    userRole: data?.userRole,
    dueDate: data?.dueDate,
    senderFirstName: data?.senderFirstName,
    senderLastName: data?.senderLastName,
    ccValues: data?.cc?.map(t => new IUser(t.ccFirstName, t.ccLastName, t.ccId)),
    createdBy: createdByUser,
    assignedTo: assignedTo,
    statusUpdateReason: data?.statusUpdateReason,
    statusUpdatedByName: data?.statusUpdatedByName,
    statusUpdatedById: data?.statusUpdatedById,
    statusUpdatedDate: data?.statusUpdatedDate,
    candidateId: data?.candidateId,
    taskNameId: data?.taskNameId,
    isAMIEWebRequest: data?.isAMIEWebRequest,
  };
  return TaskParsedData;
};

export const fetchplacementEmpData = async (foundPlacement, setPlacementEmployees) => {
  if (foundPlacement?.header?.placementId > 0) {
    await getPlacementEmployees(foundPlacement?.header?.placementId?.toString()).then(data => {
      const formatData = GeneratePlacementEmpObject(data || []);
      setPlacementEmployees(formatData);
    });
  }
};

export const displaySentDate = cueNote => {
  const formattedDate = moment.tz(cueNote?.sentDate, 'America/Los_Angeles');
  if (formattedDate.diff(moment(), 'days') <= -2) {
    return FormatDate(cueNote?.sentDate, 'L hh:mm A');
  } else {
    return FormatDate(cueNote?.sentDate, 'hh:mm A');
  }
};

export const checkIsCCChanged = (initialState, ccEmpValues) => {
  const intArr = initialState?.cc?.sort((a, b) => a - b);
  const filteredArr = ccEmpValues
    ?.map((a: ITypeAheadOption) => a?.object)
    .map(d => d?.empId)
    .sort((a, b) => a - b);
  const result = JSON.stringify(intArr) !== JSON.stringify(filteredArr);
  return result;
};

export const checkIsAssignedToChanged = (initialState, assignedToEmpValues) => {
  const intArr = initialState?.assignedTo?.sort((a, b) => a - b);
  const filteredArr = assignedToEmpValues
    ?.map((a: ITypeAheadOption) => a?.object)
    ?.map(d => d?.empId)
    ?.sort((a, b) => a - b);
  const result = JSON.stringify(intArr) !== JSON.stringify(filteredArr);
  return result;
};
export const getInitials = (fullname: string) => {
  if (fullname) {
    const fullName = fullname.split(' ');
    if (fullName?.length > 1) {
      const value = fullName?.shift()?.charAt(0) + fullName?.pop()?.charAt(0);
      return value;
    } else {
      const initial = fullName?.shift()?.charAt(0);
      return initial;
    }
  }
};
export const formatEmployeeName = (firstName: string, lastName: string) => {
  const name = (firstName?.length ? firstName.charAt(0).toUpperCase() + firstName.slice(1) : '').concat(
    ' ',
    lastName?.length ? lastName.charAt(0).toUpperCase() + lastName.slice(1) : '',
  );
  return name;
};
