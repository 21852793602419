import React, { useMemo } from 'react';
import Auto from 'app/assets/images/Order/Auto.svg';
import { Divider, Grid, List, ListItem, Typography } from 'amn-ui-core';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { selectAutomationStatus } from 'app/components/Order/OrderDetails/OrderPreferences/store/OrderPreference.selector';
import {
  IPlacementRequirementKey,
  IPlacementStatus,
  IPlacementStatusRequirement,
  IPlacementStatusRequirementMessage,
  PlacementOrderType,
  WhatsNextIcon,
} from 'app/models/Placement/PlacementDetails';
import { requirementType } from 'app/models/Placement/PlacementSummary';
import { useReadOnly } from 'oidc/userRoles';
import { withReadOnly } from 'oidc/withReadOnly';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import { getRequirementCheck } from '../PlacementDetails/PlacementTabPanel/PlacementSummaryTab/helper';
import { usePlacementStatusDisable } from './Helper';
import { NextPlacementStatusMessageTooltip } from './NextPlacementStatusTooltip';
import { isRFO } from './StatusDefaults';
import {
  FailIcon,
  PassIcon,
  StatusTitle,
  FailedCountBox,
  MoveNextIcon,
  FailedRequirementTitle,
  useListStyles,
  ListItemIcon,
} from './styles';
import clsx from 'clsx';
import { selectPlacementSummary } from 'store/redux-store/placement-summary/selectors';
import { selectCreatePlacementOrderType, selectNewPlacementChoice } from 'store/redux-store/new-placement/selectors';
import { PendingModsMessage } from './FailureMessages';
import { IconButton } from 'amn-ui-core';
import { useFormContext } from 'react-hook-form';
import { TimeOffMode } from '@AMIEWEB/Common/TimeOffRequest';
import { ff_new_create_placement_modal_layout } from 'app/constants/FeatureFlags/Placement/Keys';
import { useDecision } from '@optimizely/react-sdk';

interface PlacementStatusProps {
  statusList: IPlacementStatus[];
  failedRequirements?: IPlacementStatusRequirement;
  onStatusSelected?: (status: IPlacementStatus) => void;
  isCreatePlacement?: boolean;
  displayTopDivider?: boolean;
  inlineDense?: boolean;
}

export const checkIfPriorStatusRequirementsFail = (failedRequirements, priorStatus) =>
  !!(failedRequirements[`${priorStatus?.activityTypeId}:${priorStatus?.activityStatusId}`] ?? []).length;

export const PlacementStatus = ({
  statusList,
  failedRequirements,
  onStatusSelected,
  isCreatePlacement,
  displayTopDivider,
  inlineDense = false,
}: PlacementStatusProps) => {
  const { classes } = useListStyles({ inlineDense });
  return (
    <List disablePadding>
      {statusList.map((status, indx) => (
        <>
          {(indx !== 0 || displayTopDivider) && <Divider className={classes.divider} />}
          <StatusItem
            status={status}
            failedRequirements={failedRequirements[`${status.activityTypeId}:${status.activityStatusId}`]}
            hasPriorFailedRequirements={checkIfPriorStatusRequirementsFail(failedRequirements, statusList[indx - 1])}
            classes={classes}
            onStatusSelected={onStatusSelected}
            lastItem={indx === statusList.length - 1}
            isCreatePlacement={isCreatePlacement}
            allFailedRequirements={failedRequirements}
          />
        </>
      ))}
    </List>
  );
};

const StatusItem = ({
  status,
  hasPriorFailedRequirements,
  failedRequirements,
  onStatusSelected,
  classes,
  isCreatePlacement,
  lastItem,
  allFailedRequirements,
  ...props
}: {
  status: IPlacementStatus;
  hasPriorFailedRequirements: boolean;
  failedRequirements?: IPlacementStatusRequirementMessage[];
  onStatusSelected?: (status: IPlacementStatus) => void;
  classes?: any;
  isCreatePlacement?: boolean;
  lastItem;
  [key: string]: any;
}) => {
  const isSubmissionAutomationEnabled = useSelector(selectAutomationStatus);
  const placementOrderType = useSelector(selectPlacementOrderType);
  const orderType = useSelector(selectCreatePlacementOrderType);

  const { createScreenEnabled } = useSelector(selectNewPlacementChoice);
  const {
    orderInformation: { positionsAvailable },
  } = useSelector(selectPlacementSummary);

  const createScreenEnabledMemo = useMemo(() => {
    if (createScreenEnabled) {
      return createScreenEnabled;
    } else if (positionsAvailable !== null) {
      return positionsAvailable === 0;
    } else {
      return false;
    }
  }, [createScreenEnabled, positionsAvailable]);

  const { t } = useTranslation();
  const { watch } = useFormContext();
  const getRequestedTimeOffData = watch('requestedTimeOff');
  const [{ enabled: newLayoutEnabled }] = useDecision(ff_new_create_placement_modal_layout);
  //usePlacementStatusDisable used in whats next

  const { disabled, toolTipContent } = usePlacementStatusDisable({
    status,
    hasFailedRequirements: (failedRequirements?.length || 0) > 0,
    hasPriorFailedRequirements,
    manualAllowed: false,
    isStrike: placementOrderType === PlacementOrderType.StrikeOrderInHsg,
    isCreatePlacement: isCreatePlacement,
    failedRequirements: failedRequirements,
    createScreenEnabled: orderType !== PlacementOrderType.StrikeOrderInHsg ? createScreenEnabledMemo : false,
    allFailedRequirements: allFailedRequirements,
    isRequestedTimeOffDisabled:
      getRequestedTimeOffData?.requestedTimeOffMode === TimeOffMode.AddTimeOff &&
      !getRequestedTimeOffData?.requestedTimeOff?.length &&
      isCreatePlacement &&
      newLayoutEnabled,
  });

  const { readOnly } = useReadOnly();

  const _onStatusSelected = () => !disabled && !readOnly && onStatusSelected?.(status);

  return (
    <React.Fragment>
      <ListItem disableGutters className={classes.listItem} data-parent={true}>
        <ListItemIcon>
          {(failedRequirements?.length || 0) > 0 ? <FailIcon style={{ color: '#ab0012' }} /> : <PassIcon />}
        </ListItemIcon>
        <Grid container spacing={2} alignContent="center" alignItems="center">
          <Grid item xs={12}>
            <Grid container spacing={2} wrap="nowrap">
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <StatusTitle>{status.activityStatusAlt ?? status.activityStatus}</StatusTitle>
              </Grid>
              {failedRequirements && (failedRequirements?.length || 0) > 0 && (
                <Grid item>
                  <FailedCountBox>{failedRequirements?.length ?? 0}</FailedCountBox>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <CustomTooltip tooltipContent={toolTipContent} disabled={!disabled} placement={'bottom'} isStandardContent>
          <IconButton color="primary" size="small">
            {withReadOnly(MoveNextIcon)({
              disabled,
              onClick: status.whatsNextIcon === WhatsNextIcon.GreenChecked ? undefined : _onStatusSelected,
              icon: status.whatsNextIcon,
            })}
          </IconButton>
        </CustomTooltip>
      </ListItem>
      {failedRequirements &&
        failedRequirements.map(
          (item, i) =>
            item &&
            item?.message && (
              <ListItem
                key={`next-status-failed-req-${i}`}
                disableGutters
                className={clsx(classes.listItem, classes.failedItem)}
              >
                <Grid container spacing={1} alignItems="flex-start" wrap="nowrap">
                  <Grid item>
                    <NextPlacementStatusMessageTooltip tooltip={item.tooltip}>
                      {getRequirementCheck(requirementType.fail)}
                    </NextPlacementStatusMessageTooltip>
                  </Grid>
                  <Grid item>
                    {item.id === IPlacementRequirementKey.pendingModification ? (
                      <PendingModsMessage message={t(item?.message)} />
                    ) : (
                      <FailedRequirementTitle>{t(item?.message)}</FailedRequirementTitle>
                    )}
                  </Grid>
                </Grid>
              </ListItem>
            ),
        )}
      {/* TODO: Review styling @Rijash */}
      {isRFO(status) && isSubmissionAutomationEnabled && (
        <ListItem key={`Submission`} disableGutters className={classes.listItem}>
          <Grid container spacing={1} alignItems="flex-start" wrap="nowrap" style={{ paddingLeft: '9px' }}>
            <Grid item style={{ paddingTop: '8px' }}>
              <CustomTooltip
                tooltipContent={`Automation is On`}
                tooltipPlacementBottom={classes.tooltipPlacementBottom}
              >
                <img src={Auto} alt="Auto" style={{ width: '19px' }} />
              </CustomTooltip>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  <Typography variant="body1" style={{ fontWeight: 500, fontSize: '16px' }}>
                    {t('Submission')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    {t('placement.profile.placementStatusUpdate.automationInlineStatusMessage', {
                      activityStatus: status.activityStatus,
                    })}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
      )}
    </React.Fragment>
  );
};
