import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { candidateDetailActions } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { useTranslation } from 'react-i18next';
import { selectUser } from 'oidc/user.selectors';
import { useReadOnly } from 'oidc/userRoles';
import { candidateDetailsSelection, selectSkillSetTree } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { ISkillsetTreeDiscipline, IUpdateSkillsetTreeData } from 'app/models/Candidate/CandidateProfile';
import { IReason, ISkillsetGroup, SkillsetDetails, SkillsetType, getDeleteSkillsetItem } from './SkillsetHelper';

interface SkillsetDeleteConfirmationProps {
  open: boolean;
  setDialogOpen: (open: boolean) => void; 
  skillset: SkillsetDetails;
  columns: ISkillsetGroup;
}

export const SkillsetDeleteConfirmation = (props: SkillsetDeleteConfirmationProps) => {
  const { open, setDialogOpen, skillset, columns } = props;
  const candidateDetails = useSelector(candidateDetailsSelection);
  const candidateSkillSetTree = useSelector(selectSkillSetTree);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const { readOnly } = useReadOnly();

  const onRemoveSkillset = async () => {
    const deletedSkillset: ISkillsetTreeDiscipline[] = getDeleteSkillsetItem(skillset, candidateSkillSetTree, columns);
    var payload: IUpdateSkillsetTreeData = {
      candidateId: candidateDetails.travelerId,
      skillsetTree: { 
        ...candidateSkillSetTree,
        candidateSkillSets: deletedSkillset,
      },
      currentEmployeeId: user.userInfo?.employeeId || 0,
      currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
    };
    dispatch(candidateDetailActions.updateCandidateSkillsetTree({ data: payload, customSaveMessage: t('candidate.skillsets.deleteMessage') }))
    setDialogOpen(false);
  };

  return (
    <GenericDialog
      open={open}
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
      onClose={(e, reason) => {
        if (reason === IReason.backdropClick) return;
        setDialogOpen(false);
      }}
      dialogTitleProps={{
        style: { paddingTop: '20px' },
        text: skillset.type === SkillsetType.unverified 
          ? t('candidate.skillsets.removeUnverifiedSkillSet') 
          : t('candidate.skillsets.removeVerifiedSkillSet'),
        closeButton: false,
      }}
      dialogActions={[
        {
          text: t('candidate.skillsets.cancel'),
          variant: 'contained',
          color: 'inherit',
          onClick: () => setDialogOpen(false),
        },
        {
          text: t('candidate.skillsets.delete'),
          variant: 'contained',
          color: 'primary',
          onClick: () => onRemoveSkillset(),
          disabled: readOnly,
          tooltipProps: {
            tooltipContent: t('global.readOnlyTooltip'),
            disabled: !readOnly,
          },
        },
      ]}
    >
      <span>
        {`${t('candidate.skillsets.removeDescription')} ${skillset.label} ${t('candidate.skillsets.skillSet')}?`}
      </span>
    </GenericDialog>
  );
};
