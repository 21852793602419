import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import moment from 'moment';
import { editableFields } from '../../editUtils';
import { useSelector } from 'react-redux';
import { selectEditStatus } from '../store/placementPageAttributes.selector';
import { TextField, TimePicker } from 'amn-ui-core';
import { LocalizationProvider } from 'amn-ui-core';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  picker: {
    backgroundColor: 'transparent !important',
    border: 'none',
    width: '115px',
    '& *': {
      backgroundColor: 'transparent !important',
    },
    input: {
      padding: '0',
      background: '#fff',
      borderBottom: 'solid #9d9898 1px',
    },
  },
  typeRadio: {
    color: '#333',
    fontWeight: 500,
  },
}));

export const ArrivalTime = () => {
  const { control } = useFormContext();
  //@Team @Monin - remove unused variables
  const { arrivalTime: status } = useSelector(selectEditStatus);
  const { clearErrors } = useFormContext();
  const { classes } = useStyles();

  const value: any = useWatch({ name: 'arrivalTime' });

  return (
    <Controller
      control={control}
      name={editableFields.arrivalTime}
      render={({ ref, onChange, ...rest }) => {
        return (
          <React.Fragment>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                label={undefined}
                value={value}
                className={classes.picker}
                //inputFormat="HH:mm a"
                disableOpenPicker
                ampm={true}
                onChange={time => {
                  if (time !== undefined) {
                    const newDate = moment(time);
                    const setTo = newDate.isValid() ? moment(newDate).format('YYYY-MM-DDTHH:mm:ss') : null;
                    clearErrors('arrivalTime');
                    onChange(setTo);
                  } else {
                    onChange(null);
                  }
                }}
                renderInput={p => (
                  <TextField
                    {...p}
                    id="timeSelector"
                    aria-label="change time"
                    variant="filled"
                    InputProps={{
                      ...p.InputProps,
                      disableUnderline: true,
                      autoComplete: 'off',
                      placeholder: 'hh:mm a',
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </React.Fragment>
        );
      }}
    />
  );
};
