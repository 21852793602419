import React, { useState } from 'react';
import { Map, LocationPin } from '.';
import { Paper } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import styledComponent from 'styled-components';
import { K_HOVER_CIRCLE_SIZE, K_PIN_STICK_SIZE, K_AGG_PIN_SIZE } from './mapUtils';
import useSupercluster from 'use-supercluster';
import { Carousel } from '../Carousels/Carousel';
import { getPopovers, getAggregatePopovers, getCarouselCards, determineIsChildrenAtSameCoordinates } from './helper';

const Marker = props => props.children;

export enum MarkerType {
  pin = 'PIN',
  cluster = 'CLUSTER',
  clusterPin = 'CLUSTER_PIN',
}
const useStyles = makeStyles()({
  cluster: {
    width: 'max-content',
    border: '1px solid #FFFFFF',
    borderRadius: 4,
    boxShadow: '0px 3px 6px #00000029',
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    '&::after': {
      content: 'close-quote',
      position: 'fixed',
      borderRight: '3px solid transparent',
      borderLeft: '3px solid transparent',
      transform: 'translateY(13px)',
    },
  },
  clusterMarker: {
    zIndex: 1,
    color: '#fff',
    background: '#0098DB 0% 0% no-repeat padding-box',
    transform: 'translate(-50%, -116%)',
    '&::after': {
      borderTop: '4px solid #0098DB',
    },
  },
  aggregateMarker: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    transform: 'translate(-50%, -250%)',
    '&::after': {
      borderTop: '4px solid #FFFFFF',
    },
  },
});

export const AmnMapView = props => {
  const { classes } = useStyles();
  const { locationCoordinates, isPopoversEnabled = true, entities } = props;

  //Default center is marked at AMN Healthcare, San Deago.
  const defaultCenter = { lat: 26.97917, lng: -80.12422 };
  const [mapObject, setMapObject] = useState<any>(null);

  //Cluster management variables
  const [zoom, setZoom] = useState<number>(5);
  const [bounds, setBounds] = useState<any>(null);

  //Popover state management
  const [popOverEntityId, setPopOverEntityId] = useState<number | null>(null);
  const [isHoveringOnAggPin, setIsHoveringOnAggPin] = useState(false);
  const [freezePopover, setFreezePopover] = useState<boolean>(false);
  const [showCarousel, setShowCarousel] = useState<boolean>(false);
  const [carouselCards, setCarouselCards] = useState<JSX.Element[]>([]);

  const closePopover = () => {
    setPopOverEntityId(null);
    setFreezePopover(false);
    setShowCarousel(false);
    if (carouselCards.length) {
      setCarouselCards([]);
      mapObject.map.setOptions({ zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM } });
    }
  };

  const popovers = isPopoversEnabled ? getPopovers(entities, freezePopover, closePopover) : [];

  //Clusters using supercluster algorithm
  const points = entities.map((item, index) => ({
    type: 'Feature',
    properties: { cluster: false, index: index, entityId: item.entityId },
    geometry: { type: 'Point', coordinates: [item.locationCoordinates.longitude, item.locationCoordinates.latitude] },
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
  });

  const aggregatePopovers = isPopoversEnabled ? getAggregatePopovers(clusters, supercluster, entities) : [];

  const onMapIsLoaded = ({ map, maps }) => {
    setMapObject({ map, maps });
  };

  //reconfiguration of map zoom and map bounds for cluster management with onChange
  const reconfigure = (zoom, bounds) => {
    setZoom(zoom);
    setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
  };

  const onClick = e => {
    if (!e.event.target.className) {
      closePopover();
    }
  };

  const onChildClick = (key, e) => {
    if (key.includes('cluster-pin')) {
      setShowCarousel(false);
      const clusterEntities = props.SortClusterEntities
        ? props.SortClusterEntities(e.clusterChildren.map(item => item.properties.entityId))
        : entities.filter(entity => e.clusterChildren.some(item => item.properties.entityId === entity.entityId)).map;

      // logic to drag the map such that
      // the pin clicked is positioned at top centre
      var overlay = new google.maps.OverlayView();
      overlay.draw = function () {};
      overlay.setMap(mapObject.map);
      var projection = overlay.getProjection();
      var latlng = new google.maps.LatLng(e.lat, e.lng);
      var pixelpoint = projection.fromLatLngToContainerPixel(latlng);
      pixelpoint.y = pixelpoint.y + 240;
      mapObject.map.panTo(projection.fromContainerPixelToLatLng(pixelpoint));
      mapObject.map.setOptions({ zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_CENTER } });
      setPopOverEntityId(clusterEntities[0].entityId);

      setTimeout(() => {
        const cards = getCarouselCards(clusterEntities, id => setPopOverEntityId(id));
        setCarouselCards(cards);
        setShowCarousel(true);
        setIsHoveringOnAggPin(false);
        setFreezePopover(true);
      }, 500);
    }
    if (key.includes('pin')) {
      setPopOverEntityId(e.entityId);
      setFreezePopover(true);
      setCarouselCards([]);
    }
  };

  const onChildMouseEnter = (key, e) => {
    if (!freezePopover) {
      if (key.includes('cluster-pin')) {
        setIsHoveringOnAggPin(e.isHoverable);
      } else setIsHoveringOnAggPin(false);
      e.isHoverable && setPopOverEntityId(e.entityId);
    }
  };

  const onChildMouseLeave = (key, e) => {
    if (!freezePopover) {
      setIsHoveringOnAggPin(false);
      setPopOverEntityId(null);
      setCarouselCards([]);
    }
  };

  const HandleClusterClick = (e, cluster) => {
    const expansionZoom = supercluster.getClusterExpansionZoom(cluster.id);
    mapObject.map.setZoom(expansionZoom);
    mapObject.map.panTo({ lng: cluster.geometry.coordinates[0], lat: cluster.geometry.coordinates[1] });
  };

  //Function accounts for heat region around the location pin (while hovering) w.r.t hoverDistance
  const distanceToMouse = (markerPos, mousePos, markerProps) => {
    const hoverStick = markerProps.type === MarkerType.clusterPin ? K_AGG_PIN_SIZE : K_PIN_STICK_SIZE;

    const x = markerPos.x;
    // Since marker is non symmetric,
    // we transform it central point to measure distance from marker circle center
    const y = markerPos.y - hoverStick - K_HOVER_CIRCLE_SIZE / 2;

    return Math.sqrt((x - mousePos.x) * (x - mousePos.x) + (y - mousePos.y) * (y - mousePos.y));
  };

  return (
    <PaperCard>
      <Map
        defaultCenter={defaultCenter}
        defaultZoom={5}
        locations={locationCoordinates}
        onClick={onClick}
        onChildClick={onChildClick}
        onChildMouseEnter={onChildMouseEnter}
        onChildMouseLeave={onChildMouseLeave}
        onMapIsLoaded={onMapIsLoaded}
        onChange={({ zoom, bounds }) => reconfigure(zoom, bounds)}
        hoverDistance={K_HOVER_CIRCLE_SIZE / 2}
        distanceToMouse={distanceToMouse}
        onDrag={map => carouselCards.length !== 0 && setShowCarousel(false)}
        onDragEnd={map => carouselCards.length !== 0 && setShowCarousel(true)}
        options={{
          draggableCursor: 'default',
          // Bug 71077: disabling full screen until solution is found for hover over
          fullscreenControl: false,
        }}
      >
        {/*Clustering start*/}
        {clusters.map(cluster => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } = cluster.properties;
          if (isCluster) {
            const clusterChildren = supercluster.getLeaves(cluster.id);

            if (determineIsChildrenAtSameCoordinates(clusterChildren)) {
              return (
                <Marker
                  key={`cluster-pin-${cluster.id}`}
                  type={MarkerType.clusterPin}
                  isHoverable={true}
                  entityId={clusterChildren[0].properties.entityId}
                  clusterChildren={clusterChildren}
                  lat={latitude}
                  lng={longitude}
                >
                  <LocationPin />
                  {!clusterChildren.some(item => item.properties.entityId === popOverEntityId) && (
                    <div
                      className={`${classes.cluster} ${classes.aggregateMarker}`}
                      style={{
                        // width: `${10 + (pointCount / points.length) * 20}px`,
                        height: `${10 + (pointCount / points.length) * 20}px`,
                      }}
                    >
                      {pointCount}
                    </div>
                  )}
                </Marker>
              );
            }
            return (
              //cluster.id gets generated only for clusters and not a pin
              <Marker
                key={`cluster-${cluster.id}`}
                type={MarkerType.cluster}
                isHoverable={false}
                lat={latitude}
                lng={longitude}
              >
                <div
                  className={`${classes.cluster} ${classes.clusterMarker}`}
                  style={{
                    height: `${10 + (pointCount / points.length) * 20}px`,
                  }}
                  tabIndex={-1}
                  onClick={e => HandleClusterClick(e, cluster)}
                  onKeyDown={e => HandleClusterClick(e, cluster)}
                  role="button"
                >
                  {pointCount}
                </div>
              </Marker>
            );
          }
          return (
            <LocationPin
              key={`pin-${cluster.properties.index}`}
              type={MarkerType.pin}
              isHoverable={true}
              entityId={cluster.properties.entityId}
              lat={latitude}
              lng={longitude}
            />
          );
        })}
        {/*Clustering end*/}
        {isHoveringOnAggPin
          ? isPopoversEnabled &&
            popOverEntityId &&
            aggregatePopovers &&
            aggregatePopovers.find(item => item.props.entityId === popOverEntityId)
          : isPopoversEnabled && popOverEntityId && popovers.find(item => item.props.entityId === popOverEntityId)}
        {showCarousel && carouselCards.length > 0 && (
          <Carousel
            items={carouselCards}
            navButtonStyle={{
              background: '#fff',
              borderRadius: 0,
              padding: 'initial',
            }}
          />
        )}
      </Map>
    </PaperCard>
  );
};

const PaperCard = styledComponent(Paper)`
  width: 100%;
  border: 1px solid #cccccc;
  box-shadow: 0px 1px 1px #00000029;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
`;
