import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { IElementAccessor } from 'app/components/Common/EditPage/PageActions';
import {
  IEditableStatus,
  IPlacementPageAttributes,
  MiscPlacementDialogType,
} from 'app/models/Placement/PlacementDetails';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { editableFields } from '../../editUtils';
import { ExtEditTarget } from '../../PlacementTabPanel/PlacementSummaryTab/Statuses/Extensions/ExtensionEditIcon';

export const initialState: IPlacementPageAttributes = {
  editableFieldStatus: {
    requestedShift: { disabled: true },
    bestTimeToCall: { disabled: true },
    vmsReqNumber: { disabled: true },
    contractHours: { disabled: true },
    travelsWithPartner: { disabled: true },
    travelPartner: { disabled: true },
    placementDate: { disabled: true },
    availabilityDate: { disabled: true },
    sellingPoints: { disabled: true },
    requestedTimeOff: { disabled: true },
    ssn: { disabled: true },
    dob: { disabled: true },
    arrivalDate: { disabled: false },
    arrivalTime: { disabled: false },
    placementShift: { disabled: true },
  },
  editOrderStack: [],
  accessibleItems: [],
  glanceDrawerState: false,
  currentFocus: null,
  popperOpen: false,
  extensionModalProps: {
    open: false,
    title: null,
    extensionOverride: 0,
  },
  preExtensionModalProps: {
    open: false,
    title: null,
  },
};

const getEditableStatusesAction = createAction<{ placementId: number, isInitLoad?: boolean  | false }>('GET_EDITABLE_STATUS');

const pageAttributeSlice = createSlice({
  name: 'placementPageAttrubutes',
  initialState,
  reducers: {
    setEditableStatuses(
      state,
      action: PayloadAction<{
        requestedShift: IEditableStatus;
        bestTimeToCall: IEditableStatus;
        travelsWithPartner: IEditableStatus;
        travelPartner: IEditableStatus;
        placementDate: IEditableStatus;
        availabilityDate: IEditableStatus;
        sellingPoints: IEditableStatus;
        requestedTimeOff: IEditableStatus;
        ssn: IEditableStatus;
        dob: IEditableStatus;
        arrivalDate: IEditableStatus;
        arrivalTime: IEditableStatus;
        placementShift: IEditableStatus;
        vmsReqNumber: IEditableStatus;
        contractHours: IEditableStatus;
      }>,
    ) {
      state.editableFieldStatus = action.payload;
    },
    setEditableStatusByKey(state, action: PayloadAction<{ key: string; status: IEditableStatus }>) {
      state.editableFieldStatus[action.payload.key] = action.payload.status;
    },
    setEditableIds(state, action: PayloadAction<IElementAccessor[]>) {
      state.accessibleItems = action.payload;
    },
    setGlanceDrawerState(state, action: PayloadAction<boolean>) {
      state.glanceDrawerState = action.payload;
    },
    setCurrentFocus(state, action: PayloadAction<string | null>) {
      state.currentFocus = action.payload;
    },
    setEditOrderStack(state, action: PayloadAction<editableFields[]>) {
      state.editOrderStack = [...action.payload];
    },
    pushToEditOrderStack(state, action: PayloadAction<editableFields>) {
      const currentStack = [...state.editOrderStack].filter(key => key !== action.payload);
      currentStack.push(action.payload);
      state.editOrderStack = currentStack;
    },
    popFromEditOrderStack(state) {
      const currentStack = [...state.editOrderStack];
      currentStack.pop();
      state.editOrderStack = [...currentStack];
    },
    setPopperOpen(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        popperOpen: action.payload,
      };
    },
    setExtensionModalProps(
      state,
      action: PayloadAction<{
        open: boolean;
        extensionStatusId?: number;
        title?: string;
        isNewExtension?: boolean;
        extensionOverride: number | 0;
        extContainerIndex?: number;
        currentTargetField?: ExtEditTarget | null;
      }>,
    ) {
      return {
        ...state,
        extensionModalProps: { ...state.extensionModalProps, ...action.payload },
      };
    },
    setExtensionOverride(state, action: PayloadAction<number>) {
      return {
        ...state,
        extensionModalProps: {
          ...state.extensionModalProps,
          extensionOverride: action.payload,
        },
      };
    },
    closeExtensionModal(state) {
      return {
        ...state,
        extensionModalProps: { open: false, extensionOverride: 0 },
      };
    },
    setPreExtensionModalProps(
      state,
      action: PayloadAction<{
        open: boolean;
        title?: string;
        extensionStatusId?: number;
        extensionStatus?: string;
        isNewExtension?: boolean;
        [key: string]: any;
      }>,
    ) {
      return {
        ...state,
        preExtensionModalProps: {
          ...action.payload,
        },
      };
    },
    setMiscDialog(
      state,
      action: PayloadAction<{
        key: MiscPlacementDialogType;
        data?: any;
      }>,
    ) {
      return {
        ...state,
        miscDialogProps: {
          ...action.payload,
        },
      };
    },
    closeMiscDialogue(state) {
      return {
        ...state,
        miscDialogProps: undefined,
      };
    },
    reset() {
      return { ...initialState };
    },

  },
});

const { actions } = pageAttributeSlice;
export const { name: pageAttributeSliceKey, reducer: pageAttributesReducer } = pageAttributeSlice;

export const pageAttributesActions = { ...actions, getEditableStatusesAction };
