import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { ISelection } from 'app/ComponentLibrary/Filter/TypeAheadDropdown/CustomTypeAhead';
import SearchIcon from '@mui/icons-material/Search';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  TextField,
  Button,
  Paper,
  Popper,
  Checkbox,
  Autocomplete,
  AutocompleteInputChangeReason,
  ButtonGroup,
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from 'amn-ui-core';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import { useDispatch, useSelector } from 'react-redux';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { Controller, useFormContext } from 'react-hook-form';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { RootState } from 'types';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import _ from 'lodash';
import { theme } from 'styles/global-styles';
import { convertToFormat } from 'app/helpers/dateHelper';
import { IR225ReportRequest } from 'store/redux-store/report-details/types';
import { reportsViewActions } from 'store/redux-store/report-details/slice';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { getAmnEndOfWeek, getAmnStartOfWeek } from 'utils/dates/dateExtraction';
import { LinkButtons, ReportTypes } from './SalesReportTypes';
import { GenericTooltip } from '@AMIEWEB/Common/genericTooltip/GenericTooltip';

const useStyles = makeStyles<{ screenHeight: string }>()((theme, { screenHeight }) => ({
  headerContainer: {
    minWidth: 250,
    padding: '6px 20px',
  },

  selectedButton: {
    background: theme.palette.components.button.secondary.backgroundColor,
    color: theme.palette.common.white,
    textTransform: 'none',
    '&:hover': {
      background: theme.palette.components.button.secondary.backgroundColor,
      color: theme.palette.common.white,
    },
    minHeight: 40,
    padding: 0,
  },
  unSelectedButton: {
    background: theme.palette.system.white,
    color: theme.palette.common.black,
    textTransform: 'none',
    '&:hover': {
      background: theme.palette.system.paleBlue,
      color: theme.palette.common.black,
    },
    minHeight: 40,
    padding: 0,
  },
  countTag: {
    color: theme.palette.common.black,
    fontSize: 14,
    fontWeight: 600,
  },
  popperDisablePortal: {
    position: 'relative',
    width: '-webkit-fill-available !important',
    backgroundColor: theme.palette.framework.system.backgroundGrey,
  },
  paper: {
    margin: 0,
    backgroundColor: theme.palette.framework.system.backgroundGrey,
  },

  listBox: {
    maxHeight: screenHeight,
    minHeight: screenHeight,
    backgroundColor: theme.palette.framework.system.backgroundGrey,
  },
  option: {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    maxHeight: '40px !important',
  },
  icon: {
    '&:hover': {
      fill: theme.palette.framework.system.skyBlue,
    },
  },
  checked: {
    color: `${theme.palette.framework.system.skyBlue} !important`,
  },
}));

const smallTextFieldTheme = createTheme(theme, {
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
          '&:hover,&:focus': { backgroundColor: theme.palette.common.white },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
          '&:hover,&:focus': { backgroundColor: theme.palette.common.white },
        },
      },
    },
  },
});

const getObjectValue = obj => (obj ? (typeof obj.value === 'object' ? obj.id : obj.value) : obj);

export const SalesReportUserSearch = props => {
  const { optionsList, workDesk } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { control, setValue, watch } = useFormContext();
  const selectedOptionWatcher = watch('userSearch');
  const [selectedCountTag, setSelectedCountTag] = useState<number>(0);
  const [textFieldValue, setTextFieldValue] = useState<any>('');
  const [selectedReport, setSelectedReport] = useState<ReportTypes>(ReportTypes.Recruiters);
  const [savePreferences, setSavePreferences] = useState<boolean>(false);
  const { innerHeight } = window;
  const customHeight = `${_.round(_.divide(innerHeight, 100) * (innerHeight > 720 && workDesk ? 56 : innerHeight > 720 ? 60 : workDesk ? 44 : 48))}px` ;
  const { classes } = useStyles({ screenHeight: customHeight });
  const r225PreferencesId = 'report-preferences';
  const r225SavedPreferences = useSelector((state: RootState) => {
    return state.userPreferenceData.userGridPreferences.find(item => item.id === r225PreferencesId)?.value?.r225;
  });
  const { loaded: preferencesLoaded } = useSelector(selectUserPreference);

  const options = useMemo(() => {
    return selectedReport === ReportTypes.Recruiters
      ? optionsList?.recruiters
      : selectedReport === ReportTypes.Directors
      ? optionsList?.directors
      : selectedReport === ReportTypes.Brand
      ? optionsList?.brands
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport]);

  const disableActions = useMemo(() => {
    return {
      saveDisabled: _.isEqual(
        _.sortBy(
          selectedReport === ReportTypes.Recruiters && r225SavedPreferences?.recruiters
            ? options?.filter(x => r225SavedPreferences?.recruiters.includes(x.value)).map(x => x.value)
            : selectedReport === ReportTypes.Directors && r225SavedPreferences?.directors
            ? options?.filter(x => r225SavedPreferences?.directors.includes(x.value)).map(x => x.value)
            : selectedReport === ReportTypes.Brand && r225SavedPreferences?.brands
            ? options?.filter(x => r225SavedPreferences?.brands.includes(x.value)).map(x => x.value)
            : 0,
        ) ,
        _.sortBy(selectedOptionWatcher?.map(i => i.value)),
      )  || selectedOptionWatcher?.length > 100 ,
      resetDisabled: _.isEqual(
        selectedReport === ReportTypes.Recruiters && r225SavedPreferences?.recruiters
          ? r225SavedPreferences?.recruiters.length
          : selectedReport === ReportTypes.Directors && r225SavedPreferences?.directors
          ? r225SavedPreferences?.directors.length
          : selectedReport === ReportTypes.Brand && r225SavedPreferences?.brands
          ? r225SavedPreferences?.brands.length
          : 0,
        0,
      ),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptionWatcher,r225SavedPreferences]);

  useEffect(() => {
    if (preferencesLoaded && options) {
      setValue(
        'userSearch',
        selectedReport === ReportTypes.Recruiters && r225SavedPreferences?.recruiters
          ? options?.filter(x => r225SavedPreferences?.recruiters.includes(x.value))
          : selectedReport === ReportTypes.Directors && r225SavedPreferences?.directors
          ? options?.filter(x => r225SavedPreferences?.directors.includes(x.value))
          : selectedReport === ReportTypes.Brand && r225SavedPreferences?.brands
          ? options?.filter(x => r225SavedPreferences?.brands.includes(x.value))
          : [],
      );
      setSelectedCountTag(
        selectedReport === ReportTypes.Recruiters && r225SavedPreferences?.recruiters
          ? r225SavedPreferences?.recruiters?.length
          : selectedReport === ReportTypes.Directors && r225SavedPreferences?.directors
          ? r225SavedPreferences?.directors?.length
          : selectedReport === ReportTypes.Brand && r225SavedPreferences?.brands
          ? r225SavedPreferences?.brands?.length
          : 0,
      );
    }
  }, [r225SavedPreferences, preferencesLoaded, options, selectedReport, dispatch, setValue]);

  useEffect(() => {
    const body: IR225ReportRequest = {
      request: {
        fromDate: convertToFormat(getAmnStartOfWeek(), 'yyyy-MM-DD'),
        toDate: convertToFormat(getAmnEndOfWeek(), 'yyyy-MM-DD'),
        bookingCompanyIds: null,
        recruiterIds: null,
        directorIds: r225SavedPreferences?.recruiters?.length > 0 ? r225SavedPreferences?.recruiters + ',' : null,
      },
      inputDetails: {
        title: selectReportTitle(ReportTypes.Recruiters),
        selected: ReportTypes.Recruiters,
        selectedOptions: r225SavedPreferences?.recruiters
          ? optionsList?.recruiters?.filter(x => r225SavedPreferences?.recruiters.includes(x.value))
          : [],
        snackbarErrorMessage: t('reportPages.r225.snackbarErrorMessage'),
      },
    };
    if (body?.request.bookingCompanyIds || body?.request.directorIds || body?.request.recruiterIds) {
      dispatch(reportsViewActions.getR225ReportDetails(body));
    } else {
      dispatch(reportsViewActions.setR225ReportDetails({ response: [], inputDetails: null }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const preferenceSaveCheck =
      selectedReport === ReportTypes.Recruiters
        ? r225SavedPreferences?.recruiters?.length === selectedOptionWatcher?.length
        : selectedReport === ReportTypes.Directors
        ? r225SavedPreferences?.directors?.length === selectedOptionWatcher?.length
        : selectedReport === ReportTypes.Brand
        ? r225SavedPreferences?.brands?.length === selectedOptionWatcher?.length
        : false;
    if (savePreferences && preferencesLoaded && preferenceSaveCheck) {
      dispatch(
        globalActions.setSnackBar({
          message: t('reportPages.r225.preferencesSaveSuccessMessage', { reportType: `${selectedReport}` }),
          severity: 'success',
        }),
      );
    } else if (savePreferences && !preferencesLoaded && !preferenceSaveCheck) {
      dispatch(
        globalActions.setSnackBar({
          message: t('reportPages.r225.preferencesFailedMessage', { reportType: `${selectedReport}` }),
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [r225SavedPreferences, savePreferences]);

  const preferencesReset = () => {
    const preferenceData = {
      id: r225PreferencesId,
      value: {
        r225: {
          recruiters: selectedReport === ReportTypes.Recruiters ? [] : r225SavedPreferences?.recruiters,
          directors: selectedReport === ReportTypes.Directors ? [] : r225SavedPreferences?.directors,
          brands: selectedReport === ReportTypes.Brand ? [] : r225SavedPreferences?.brands,
        },
      },
    };
    setSavePreferences(true);
    dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
  };

  const preferencesSave = () => {
    const preferenceData = {
      id: r225PreferencesId,
      value: {
        r225: {
          recruiters:
            selectedReport === ReportTypes.Recruiters
              ? selectedOptionWatcher?.map(x => x.value)
              : r225SavedPreferences?.recruiters,
          directors:
            selectedReport === ReportTypes.Directors
              ? selectedOptionWatcher?.map(x => x.value)
              : r225SavedPreferences?.directors,
          brands:
            selectedReport === ReportTypes.Brand
              ? selectedOptionWatcher?.map(x => x.value)
              : r225SavedPreferences?.brands,
        },
      },
    };
    setSavePreferences(true);
    dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
  };

  const clearAll = () => {
    setValue('userSearch', []);
    setSelectedCountTag(0);
  };

  const handleSelectAll = () => {
    const filteredOptions = options?.filter(x => _.lowerCase(x.name).includes(textFieldValue));
    setValue('userSearch',filteredOptions );
    setSelectedCountTag(filteredOptions?.length);
  };

  const selectReportTitle = selection => {
    return selection === ReportTypes.Recruiters
      ? t('reportPages.r225.recruiterSales')
      : selection === ReportTypes.Directors
      ? t('reportPages.r225.directorSales')
      : selection === ReportTypes.Brand
      ? t('reportPages.r225.brandSales')
      : '';
  };

  const onClickHandle = selectionType => {
    setSelectedReport(selectionType);
    const body: IR225ReportRequest = {
      request: {
        fromDate: convertToFormat(getAmnStartOfWeek(), 'yyyy-MM-DD'),
        toDate: convertToFormat(getAmnEndOfWeek(), 'yyyy-MM-DD'),
        bookingCompanyIds:
          selectionType === ReportTypes.Brand && r225SavedPreferences?.brands?.length > 0
            ? r225SavedPreferences?.brands + ','
            : null,
        recruiterIds:
          selectionType === ReportTypes.Recruiters && r225SavedPreferences?.recruiters?.length > 0
            ? r225SavedPreferences?.recruiters + ','
            : null,
        directorIds:
          selectionType === ReportTypes.Directors && r225SavedPreferences?.directors?.length > 0
            ? r225SavedPreferences?.directors + ','
            : null,
      },
      inputDetails: {
        title: selectReportTitle(selectionType),
        selected: selectionType,
        selectedOptions:
          selectionType === ReportTypes.Recruiters && r225SavedPreferences?.recruiters
            ? optionsList?.recruiters?.filter(x => r225SavedPreferences?.recruiters.includes(x.value))
            : selectionType === ReportTypes.Directors && r225SavedPreferences?.directors
            ? optionsList?.directors?.filter(x => r225SavedPreferences?.directors.includes(x.value))
            : selectionType === ReportTypes.Brand && r225SavedPreferences?.brands
            ? optionsList?.brands?.filter(x => r225SavedPreferences?.brands.includes(x.value))
            : [],
        tabSelected: selectionType,
        snackbarErrorMessage: t('reportPages.r225.snackbarErrorMessage'),
      },
    };
    dispatch(
      reportsViewActions.setInputDetails({
        title: null,
        selected: null,
        selectedOptions: null,
        tabSelected: selectionType,
      }),
    );
    if (body?.request.bookingCompanyIds || body?.request.directorIds || body?.request.recruiterIds) {
      dispatch(reportsViewActions.getR225ReportDetails(body));
    } else {
      dispatch(reportsViewActions.setR225ReportDetails({ response: [], inputDetails: null }));
    }
  };

  const onInputChange = (event, newInputValue: string, reason: AutocompleteInputChangeReason) => {
    setTextFieldValue(prevValue => (reason === 'input' ? newInputValue : prevValue));
  };

  const headerButton = selectionType => {
    return (
      <Button
        disableRipple
        className={selectedReport === selectionType ? classes.selectedButton : classes.unSelectedButton}
        onClick={() => {
          onClickHandle(selectionType);
        }}
      >
        {selectionType}
      </Button>
    );
  };

  const linkButton = buttonType => {
    return (
      <Button
        variant="text"
        color="primary"
        disableRipple
        sx={{
          ...(buttonType === LinkButtons.clearAll && { padding: 0, float: 'inline-end' }),
          ...(buttonType === LinkButtons.selectAll && { paddingLeft: 0 }),
          ...((buttonType === LinkButtons.reset || buttonType === LinkButtons.save) && {
            '& .MuiButton-startIcon': {
              marginRight: '0px !important',
            },
            padding: '0px 8px',
            marginLeft: '-8% !important',
          }),
        }}
        startIcon={
          buttonType === LinkButtons.reset ? (
            <ReplayIcon fontSize="small" />
          ) : buttonType === 'save' ? (
            <PushPinOutlinedIcon fontSize="small" />
          ) : null
        }
        onClick={() =>
          buttonType === LinkButtons.clearAll
            ? clearAll()
            : buttonType === LinkButtons.selectAll
            ? handleSelectAll()
            : buttonType === LinkButtons.reset
            ? preferencesReset()
            : buttonType === LinkButtons.save
            ? preferencesSave()
            : {}
        }
        disabled={
          buttonType === LinkButtons.clearAll
            ? selectedCountTag === 0
            : buttonType === LinkButtons.selectAll
            ? selectedCountTag === options?.length
            : buttonType === LinkButtons.reset
            ? disableActions.resetDisabled
            : buttonType === LinkButtons.save
            ? disableActions.saveDisabled
            : false
        }
      >
        {buttonType}
      </Button>
    );
  };

  const handleApply = () => {
    const body: IR225ReportRequest = {
      request: {
        fromDate: convertToFormat(watch('dateRange')?.startDate, 'yyyy-MM-DD'),
        toDate: convertToFormat(watch('dateRange')?.endDate, 'yyyy-MM-DD'),
        bookingCompanyIds:
          selectedReport === ReportTypes.Brand ? selectedOptionWatcher?.map(i => i.value).join(',') + ',' : null,
        recruiterIds:
          selectedReport === ReportTypes.Recruiters ? selectedOptionWatcher?.map(i => i.value).join(',') + ',' : null,
        directorIds:
          selectedReport === ReportTypes.Directors ? selectedOptionWatcher?.map(i => i.value).join(',') + ',' : null,
      },
      inputDetails: {
        title: selectReportTitle(selectedReport),
        selected: selectedReport,
        selectedOptions: selectedOptionWatcher,
        snackbarErrorMessage: t('reportPages.r225.snackbarErrorMessage'),
      },
    };
    dispatch(reportsViewActions.getR225ReportDetails(body));
  };
  return (
    <LayoutGrid container direction={'column'} classes={{ root: classes.headerContainer }}>
      <LayoutGridItem md={12} xs={12}>
        <ButtonGroup variant="outlined" fullWidth>
          {headerButton(ReportTypes.Recruiters)}
          {headerButton(ReportTypes.Directors)}
          {headerButton(ReportTypes.Brand)}
        </ButtonGroup>
      </LayoutGridItem>

      <LayoutGridItem md={12} xs={12}>
        <LayoutGrid container direction={'row'}>
          <LayoutGridItem md={6} xs={6} className={classes.countTag}>
            {`${selectedCountTag}`} {t('search.filter.Selected')}
          </LayoutGridItem>
          <LayoutGridItem md={6} xs={6}>
            {linkButton(LinkButtons.clearAll)}
          </LayoutGridItem>
        </LayoutGrid>
      </LayoutGridItem>

      <LayoutGridItem md={12} xs={12} sx={{ paddingTop: 0 }}>
        <Controller
          name={'userSearch'}
          control={control}
          render={({ ref, onChange, value }) => (
            <Autocomplete
              open
              multiple
              clearOnBlur
              disableClearable
              fullWidth
              noOptionsText={'no Options' || undefined}
              value={value ?? []}
              options={options ?? []}
              disableCloseOnSelect
              disablePortal
              isOptionEqualToValue={(option, optionValue) => getObjectValue(option) === getObjectValue(optionValue)}
              getOptionLabel={(option: ISelection) => option.name}
              popupIcon={null}
              forcePopupIcon={true}
              renderTags={() => null}
              classes={{
                popperDisablePortal: classes.popperDisablePortal,
                paper: classes.paper,
                listbox: classes.listBox,
                option: classes.option,
              }}
              onInputChange={onInputChange}
              inputValue={textFieldValue}
              renderInput={params => (
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={smallTextFieldTheme}>
                    <LayoutGrid container>
                      <LayoutGridItem md={12} xs={12}>
                        <TextField
                          autoFocus
                          fullWidth
                          sx={{
                            '& .MuiFilledInput-root': {
                              height: '40px !important',
                              paddingTop: '0px !important',
                              backgroundColor: 'white',
                            },
                          }}
                          size={'medium'}
                          placeholder={t('appHeader.searchPlaceHolder')}
                          {...params}
                          variant={'filled'}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: <SearchIcon sx={{ opacity: 0.6 }} />,
                            'aria-label': 'filter-type-ahead',
                          }}
                          onKeyDown={(event: any) => {
                            if ((event.key === 'Backspace' && !textFieldValue) || event.key === 'Enter') {
                              event.stopPropagation();
                            }
                          }}
                        />
                      </LayoutGridItem>
                      <LayoutGridItem md={12} xs={12}>
                        {linkButton(LinkButtons.selectAll)}
                      </LayoutGridItem>
                    </LayoutGrid>
                  </ThemeProvider>
                </StyledEngineProvider>
              )}
              onChange={(e, newValue) => {
                setSelectedCountTag(newValue?.length);
                onChange(newValue);
              }}
              renderOption={(p, option, { selected }) => (
                <li id={option?.value} {...p} key={option?.value}>
                  <Checkbox
                    size="small"
                    classes={{ checked: classes.checked }}
                    icon={<CheckBoxOutlineBlankIcon classes={{ root: classes.icon }} />}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              PopperComponent={PopperComponent}
              PaperComponent={PaperComponent}
              aria-label={`custom-user-search`}
            />
          )}
        />
      </LayoutGridItem>
      <LayoutGridItem md={12} xs={12} sx={{ padding: '10px 0px !important' }}>
        <ButtonGroup variant="outlined" fullWidth>
          {linkButton(LinkButtons.reset)}
          <GenericTooltip disableHoverListener ={selectedOptionWatcher?.length < 100}  title={selectedOptionWatcher?.length > 100 ? t('reportPages.r225.tooltip.pleaseSelectLessthan100RecordstoSave') : undefined}>
            <span style={{ width: '100%' }}>{linkButton(LinkButtons.save)}</span>
          </GenericTooltip>
          <GenericTooltip disableHoverListener={selectedOptionWatcher?.length < 100}  title={selectedOptionWatcher?.length > 100 ? t('reportPages.r225.tooltip.pleaseSelectLessthan100RecordstoViewReportData') : undefined}>
            <span style={{ width: '100%' }}>
              <Button
                variant="contained"
                disableRipple
                disabled={selectedOptionWatcher?.length === 0 || selectedOptionWatcher?.length > 100}
                sx={{
                  padding: '0px 8px',
                }}
                onClick={() => {
                  handleApply();
                }}
              >
                {t('reportPages.r225.apply')}
              </Button>
            </span>
          </GenericTooltip>
        </ButtonGroup>
      </LayoutGridItem>
    </LayoutGrid>
  );
};

const PaperComponent = props => (
  <Paper {...props} elevation={0}>
    {props.children}
  </Paper>
);

const PopperComponent = props => (
  <Popper
    {...props}
    sx={{ width: 'fit-content', position: 'relative !important', transform: 'unset !important' }}
    placement="bottom-start"
  />
);
