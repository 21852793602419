import { CreatePacketXGridBtn } from '@AMIEWEB/Placement/CreatePacket/CreatePacketBtn';
import { XGrid } from 'app/components/Common/XGrid/XGrid';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { getDetailsPageSupportComponentHeight } from 'app/layout/pages/DetailsPage';
import { selectUser } from 'oidc/user.selectors';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { candidateDocumentSaga } from './store/candidateDocument.saga';
import {
  candidateDocumentActions,
  candidateDocumentReducer,
  candidateDocumentSliceKey,
} from './store/candidateDocuments.redux';
import { selectedCandidateDocumentsFromPassport } from './store/candidateDocuments.selector';
import { getDocumentColumns } from './utils';

export const DocumentsTab = () => {
  useInjectReducer({ key: candidateDocumentSliceKey, reducer: candidateDocumentReducer });
  useInjectSaga({ key: candidateDocumentSliceKey, saga: candidateDocumentSaga });
  const { t } = useTranslation();
  const passportDocuments = useSelector(selectedCandidateDocumentsFromPassport);
  const { travelerId: candidateId } = useParams<{ travelerId: string }>();
  const userInfo = useSelector(selectUser);
  const dispatch = useDispatch();
  const [sortModel, setSortModel] = React.useState<any>([
    {
      field: 'submitDate',
      sort: 'desc',
    },
  ]);
  const [gridHeight, setGridHeight] = useState<number | undefined>(window.innerHeight / 2.0);
  const resizeGrid = useCallback(event => {
    updateGridHeight();
  }, []);
  const updateGridHeight = () => {
    const a = document.getElementById('details-page-paper')?.offsetHeight;
    const b = getDetailsPageSupportComponentHeight();
    if (a && b) {
      // -100 to account for title
      setGridHeight(a - b - 140);
    }
  };

  useEffect(() => {
    if (candidateId) {
      dispatch(candidateDocumentActions.getCandidateDocumentsFromPassport(candidateId));
    }
  }, [candidateId]);

  useEffect(() => {
    setTimeout(() => {
      updateGridHeight();
    }, 250);
    window.addEventListener('resize', resizeGrid, true);

    return () => {
      window.removeEventListener('resize', resizeGrid);
    };
  }, [passportDocuments?.items?.length, resizeGrid]);

  return (
    <LayoutGrid>
      <LayoutGridItem xs={12}>
        <XGrid
          columns={getDocumentColumns(t, userInfo)}
          rows={passportDocuments?.items || []}
          sortModel={sortModel}
          title={t('Documents')}
          height={gridHeight}
          disableSelectionOnClick
          onSortModelChange={model => setSortModel(model)}
          toolbarProps={{
            leftActions: CreatePacketXGridBtn({
              disabled: true,
              hidden: true,
              linkProps: { candidateId: null, placementId: null },
            }),
          }}
        />
      </LayoutGridItem>
    </LayoutGrid>
  );
};
