import { createAction } from '@reduxjs/toolkit';
import {
  IPostEndDate,
  IPostLodging,
  IPostMinReqHrs,
  IPostPayRate,
  IPostStartDate,
  IPostTravelAllowanceMod,
  IPostReimbursement,
  IUpdateShift,
  IPostTimeOff,
  IPostEmiLmoMod,
  IPostFurniture,
  iPostInsuranceMod,
  IPostPayrollShiftDifferential,
  IPostBonusMod,
  IPostPreferenceMod,
  IPostPTOMod,
  IUpdateOGP,
  IPostHousingMod,
  IPostBillingShiftDifferential,
  IPlacementHistoryRequest,
} from 'app/models/Placement/PlacementMods';
import { IUpdateModStatusRequest } from './business-action';

const getPlacementModsList = createAction<{
  placementId: number;
}>('GET_PLACEMENT_MODS_LIST');

const getRefreshPlacementModsList = createAction<{
  placementId: number;
}>('GET_REFRESH_PLACEMENT_MODS_LIST');

const getStartDateModDetails = createAction<{
  placementId: string;
  travelerOptionId: number;
  newMod?: boolean;
}>('GET_START_DATE_MOD_DETAILS');

const getStartDateReasonCodes = createAction<{
  placementId: string;
  travelerOptionId: number;
}>('GET_START_DATE_REASON_CODES');

const getInitiators = createAction<{
  placementId: string;
}>('GET_START_DATE_INITIATORS');

const getMinReqHoursModDetails = createAction<{
  placementId: string;
  travelerOptionId: number;
  newMod?: boolean;
}>('GET_MIN_REQ_HOURS_MOD_DETAILS');

const getEndDateModDetails = createAction<{
  placementId: string;
  travelerOptionId: number;
  newMod?: boolean;
}>('GET_END_DATE_MOD_DETAILS');

const getPayRateModDetails = createAction<{
  placementId: number;
  travelerOptionId: number;
  newMod?: boolean;
}>('GET_PAY_RATE_DETAIL');

const refreshModDetails = createAction<{
  placementId: number;
  travelerOptionTypeId: number;
}>('GET_PAY_RATE_REFRESH_DETAIL');

const getShiftModDetailAction = createAction<{
  placementId: number;
  travelerOptionId: number;
  newMod?: boolean;
}>('GET_SHIFT_DETAIL');

const getAdditionalPremiumPayRate = createAction<{
  placementId: number;
  travelerOptionId: number;
}>('GET_ADDITIONAL_PREMIUM_PAY');

const getLodgingModDetails = createAction<{
  placementId: number;
  travelerOptionId: number;
  newMod?: boolean;
}>('GET_LODGING_MOD_DETAIL');

const getExceptionsPayRateGww = createAction<{
  excludeOther: boolean;
}>('GET_EXCEPTIONS_PAY_RATE_GWW');

const getInsuranceModDetails = createAction<{
  placementId: number;
  travelerOptionId: number;
  newMod?: boolean;
}>('GET_INSURANCE_MOD_DETAIL');

const getEffectiveDateTypes = createAction<{
  placementId: string;
  travelerOptionId: number;
}>('GET_EFFECTIVE_DATE_TYPES');

const getInsuranceTypes = createAction<{
  payload: any;
}>('GET_INSURANCE_TYPES');

const getBillRateModDetails = createAction<{
  placementId: number;
  travelerOptionId: number;
  newMod?: boolean;
}>('GET_BILL_RATE_DETAIL');

const getPricingReason = createAction<{
  placementId: number;
  travelerOptionId: number;
}>('GET_PRICING_REASON');

const getPtoModDetailAction = createAction<{
  placementId: number;
  travelerOptionId: number;
  newMod?: boolean;
}>('GET_PTO_DETAIL');

const getRateShiftList = createAction<{
  payload: any;
}>('GET_RATE_SHIFT_LIST');

const getCancellationHoursFrequencyList = createAction<{
  payload: any;
}>('GET_CANCELLATION_HOURS_FREQUENCY_LIST');

const getOgpInternationalLoanModDetailAction = createAction<{
  placementId: number;
  travelerOptionId: number;
  newMod?: boolean;
}>('GET_OGP_DETAIL');

const getReimbursement = createAction<{
  placementId: number;
  travelerOptionId: number;
  newMod?: boolean;
}>('GET_REIMBURSEMENT');

const getReimbursementCategoryAndFrequency = createAction<{
  placementId: number;
  travelerOptionId: number;
}>('GET_REIMBURSEMENT_CATEGORY_AND_FREQUENCY');

const getPayeeList = createAction<{
  payload: any;
}>('GET_PAYEE_LIST');

const getBonusModDetailAction = createAction<{
  placementId: number;
  travelerOptionId: number;
  newMod?: boolean;
}>('GET_BONUS_DETAIL');

const updateBonusModDetailsAction = createAction<IPostBonusMod>('UPDATE_BONUS_MOD');
const addBonusModDetailsAction = createAction<IPostBonusMod>('ADD_BONUS_MOD');

const getTimeOffModDetails = createAction<{
  placementId: number;
  travelerOptionId: number;
  newMod?: boolean;
}>('GET_TIME_OFF_DETAIL');

const getFurnitureModDetails = createAction<{
  placementId: number;
  travelerOptionId: number;
  newMod?: boolean;
}>('GET_FURNITURE_DETAIL');

const deleteModAction = createAction<IUpdateModStatusRequest>('DELETE_MOD');

const voidModAction = createAction<IUpdateModStatusRequest>('VOID_MOD');

const updateLinkModTask = createAction<{
  placementId: number;
  assignTaskId?: number;
  assignRootTaskId?: number;
  travelerOptionIds: number[];
}>('UPDATE_LINK_MOD');

const updateMinReqHours = createAction<IPostMinReqHrs>('UPDATE_MIN_REQ_HRS');
const addMinReqHours = createAction<IPostMinReqHrs>('ADD_MIN_REQ_HRS');

const updatePayRate = createAction<IPostPayRate>('UPDATE_PAY_RATE');
const addPayRate = createAction<IPostPayRate>('ADD_PAY_RATE');

const updateShiftModDetails = createAction<IUpdateShift>('UPDATE_SHIFT');
const addShiftModDetails = createAction<IUpdateShift>('ADD_SHIFT_MOD');

const getEmiLmoModDetailAction = createAction<{
  placementId: number;
  travelerOptionId: number;
  newMod?: boolean;
}>('GET_EMI_LMO_DETAIL');

const getHousingModDetailAction = createAction<{
  placementId: number;
  travelerOptionId: number;
  newMod?: boolean;
}>('GET_HOUSING_DETAIL');
const updateLodgingModDetails = createAction<IPostLodging>('UPDATE_LODGING_MOD');
const addLodgingModDetails = createAction<IPostLodging>('ADD_LODGING_MOD');

const updateStartDateModDetails = createAction<IPostStartDate>('UPDATE_START_DATE_MOD');
const addStartDateModDetails = createAction<IPostStartDate>('ADD_START_DATE_MOD');

const updateEndDateModDetails = createAction<IPostEndDate>('UPDATE_END_DATE_MOD');
const addEndDateModDetails = createAction<IPostEndDate>('ADD_END_DATE_MOD');

const updateInsuranceModDetails = createAction<iPostInsuranceMod>('UPDATE_INSURANCE_MOD');
const addInsuranceModDetails = createAction<iPostInsuranceMod>('ADD_INSURANCE_MOD');
const updateReimbursementModDetails = createAction<IPostReimbursement>('UPDATE_REIMBURSEMENT_MOD');
const addReimbursementModDetails = createAction<IPostReimbursement>('ADD_REIMBURSEMENT_MOD');

const getPreferencesModDetailAction = createAction<{
  placementId: number;
  travelerOptionId: number;
  newMod?: boolean;
}>('GET_PREFERENCES_DETAIL');

const updateEmiLmoModDetailsAction = createAction<IPostEmiLmoMod>('UPDATE_EMI_LMO_MOD');
const addEmiLmoModDetailsAction = createAction<IPostEmiLmoMod>('ADD_EMI_LMO_MOD');

const updateTimeOffModDetails = createAction<IPostTimeOff>('UPDATE_TIMEOFF_MOD');
const addTimeOffModDetails = createAction<IPostTimeOff>('ADD_OFF_MOD');
const updateFurnitureModDetails = createAction<IPostFurniture>('UPDATE_FURNITURE_MOD');
const addFurnitureModDetails = createAction<IPostFurniture>('ADD_FURNITURE_MOD');
const getTravelAllowanceModDetailAction = createAction<{
  placementId: number;
  travelerOptionId: number;
  modEnum: number;
  newMod?: boolean;
}>('GET_TRAVEL_ALLOWANCE');

const addTravelAllowanceModDetailsAction = createAction<IPostTravelAllowanceMod>('ADD_TRAVEL_ALLOWANCE');
const updateTravelAllowanceModDetailsAction = createAction<IPostTravelAllowanceMod>('UPDATE_TRAVEL_ALLOWANCE');

const getPayrollShiftDifferential = createAction<{
  placementId: number;
  travelerOptionId: number;
  newMod?: boolean;
}>('GET_PAYROLL_SHIFT_DIFFERENTIAL');

const updatePayrollShiftDifferentialModDetails = createAction<IPostPayrollShiftDifferential>(
  'UPDATE_PAYROLL_SHIFT_DIFFERENTIAL_MOD',
);
const addPayrollShiftDifferentialModDetails = createAction<IPostPayrollShiftDifferential>(
  'ADD_PAYROLL_SHIFT_DIFFERENTIAL_MOD',
);

const getShiftDifferentialTypes = createAction<{
  placementId: number;
  travelerOptionId: number;
}>('GET_SHIFT_DIFFERENTIAL_TYPES');

const getShiftDifferentialDescriptionTypes = createAction<{
  placementId: number;
  travelerOptionId: number;
}>('GET_SHIFT_DIFFERENTIAL_DESCRIPTION_TYPES');

const getShiftDifferentialTemplateTypes = createAction<{
  placementId: number;
  travelerOptionId: number;
}>('GET_SHIFT_DIFFERENTIAL__TEMPLATE_TYPES');

const updateBillRateModDetails = createAction<any>('UPDATE_BILLRATE_MOD');
const addBillRateModDetails = createAction<any>('ADD_BILLRATE_MOD');

const getBillingShiftDifferential = createAction<{
  placementId: number;
  travelerOptionId: number;
  newMod?: boolean;
}>('GET_BILLING_SHIFT_DIFFERENTIAL');

const updateBillingShiftDifferentialModDetails = createAction<IPostBillingShiftDifferential>(
  'UPDATE_BILLING_SHIFT_DIFFERENTIAL_MOD',
);

const addBillingShiftDifferentialModDetails = createAction<IPostBillingShiftDifferential>(
  'ADD_BILLING_SHIFT_DIFFERENTIAL_MOD',
);

const getShiftDifferentialTemplateItems = createAction<{
  shiftDifferentialTemplateTypeId: number;
}>('GET_SHIFT_DIFFERENTIAL__TEMPLATE_ITEMS');

const updatePreferenceModDetails = createAction<IPostPreferenceMod>('UPDATE_PREFERENCE_MOD');
const addPreferenceModDetails = createAction<IPostPreferenceMod>('ADD_PREFERENCE_MOD');

const updatePTOModDetails = createAction<IPostPTOMod>('UPDATE_PTO_MOD');
const addPTOModDetails = createAction<IPostPTOMod>('ADD_PTO_MOD');

const updateOGPModDetails = createAction<IUpdateOGP>('UPDATE_OGP_MOD');
const addOGPModDetails = createAction<IUpdateOGP>('Add_OGP_MOD');
const updateHousingModDetailsAction = createAction<IPostHousingMod>('UPDATE_HOUSING_MOD');
const addHousingModDetailsAction = createAction<IPostHousingMod>('ADD_HOUSING_MOD');
const getModificationAction = createAction<IPlacementHistoryRequest>('GET_PLACEMENT_MODIFICATIONS');
const getBookingApprovedCheckboxSpecs = createAction<IPlacementHistoryRequest>(
  'GET_BOOKING_APPROVED_CHECKBOX_MODS_SPECS',
);

export const serviceActions = {
  getPlacementModsList,
  getRefreshPlacementModsList,
  getStartDateModDetails,
  getStartDateReasonCodes,
  getMinReqHoursModDetails,
  getEndDateModDetails,
  getPayRateModDetails,
  refreshModDetails,
  getTravelAllowanceModDetailAction,
  getShiftModDetailAction,
  getAdditionalPremiumPayRate,
  getLodgingModDetails,
  getExceptionsPayRateGww,
  getInsuranceModDetails,
  getEffectiveDateTypes,
  getInsuranceTypes,
  getPtoModDetailAction,
  getBillRateModDetails,
  getPricingReason,
  getRateShiftList,
  getCancellationHoursFrequencyList,
  getOgpInternationalLoanModDetailAction,
  getBonusModDetailAction,
  getReimbursement,
  getReimbursementCategoryAndFrequency,
  getPayeeList,
  getTimeOffModDetails,
  addMinReqHours,
  addPayRate,
  updatePayRate,
  updateMinReqHours,
  getEmiLmoModDetailAction,
  getHousingModDetailAction,
  getFurnitureModDetails,
  addLodgingModDetails,
  updateLodgingModDetails,
  updateStartDateModDetails,
  addStartDateModDetails,
  updateEndDateModDetails,
  addEndDateModDetails,
  getPreferencesModDetailAction,
  voidModAction,
  deleteModAction,
  getInitiators,
  updateTravelAllowanceModDetailsAction,
  updateShiftModDetails,
  addTimeOffModDetails,
  updateTimeOffModDetails,
  addFurnitureModDetails,
  updateFurnitureModDetails,
  addTravelAllowanceModDetailsAction,
  addShiftModDetails,
  updateEmiLmoModDetailsAction,
  addEmiLmoModDetailsAction,
  updateInsuranceModDetails,
  addInsuranceModDetails,
  updateReimbursementModDetails,
  addReimbursementModDetails,
  updateBillRateModDetails,
  addBillRateModDetails,
  getPayrollShiftDifferential,
  addPayrollShiftDifferentialModDetails,
  updatePayrollShiftDifferentialModDetails,
  getShiftDifferentialTypes,
  getShiftDifferentialDescriptionTypes,
  getShiftDifferentialTemplateTypes,
  updateLinkModTask,
  updateBonusModDetailsAction,
  getBillingShiftDifferential,
  addBillingShiftDifferentialModDetails,
  updateBillingShiftDifferentialModDetails,
  getShiftDifferentialTemplateItems,
  updatePreferenceModDetails,
  addPreferenceModDetails,
  updatePTOModDetails,
  updateOGPModDetails,
  addOGPModDetails,
  addBonusModDetailsAction,
  addPTOModDetails,
  updateHousingModDetailsAction,
  addHousingModDetailsAction,
  getModificationAction,
  getBookingApprovedCheckboxSpecs,
};
