import React from 'react';

export const useWindowResize = (callback, onMountEvent?) => {
  React.useEffect(() => {
    onMountEvent?.();
    window.addEventListener('resize', callback, true);

    return () => {
      window.removeEventListener('resize', callback);
    };
  }, [callback, onMountEvent]);
};
