import { ILookupState } from './../../../../../../store/redux-store/lookup/initialState';
import {
  Education,
  ICandidateDetails,
  Employment,
  VerifiedSkill,
  UnVerifiedSkill,
} from 'app/models/Candidate/CandidateProfile';
import { orderBy, groupBy, forEach } from 'lodash';
import moment, { Moment } from 'moment';
import { IUser } from 'oidc/user.redux';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { AMNDivisionType } from 'app/models/enums/AMNDivisionType';

export const constants = {
  rn: 'RN',
  active: 'Active',
  activeWithAudit: 'Active with Audit',
  ongoing: 'Ongoing',
};

interface IDivisionDisciplineConfiguration {
  disciplineId: number;
  specialtyId: number;
  divisionId: number;
}

const allowedNewGraduateDisciplineAbbr = [
  'PT',
  'OT',
  'PTA',
  'COTA',
  'SONO',
  'PST',
  'CTTECH',
  'MAM',
  'MRI',
  'NMT',
  'SLP',
];

const speechLanguagePathologist = 'SLP';

export const checkAccessPermission = (user: IUser): boolean => {
  return Authorized(
    [
      userRoles.clinical_ClientContractSpecialist,
      userRoles.clinical_Director,
      userRoles.clinical_Manager,
      userRoles.clinical_QualificationsSpecialist,
      userRoles.clinical_ReviewAnalyst,
      userRoles.recruitment,
      userRoles.recruitment_Leadership,
      userRoles.recruitment_TeamMember,
    ],
    user.userInfo,
  );
};

export const isDisciplineAllowedForNewGraduate = (disciplineAbbr: string): boolean => {
  return allowedNewGraduateDisciplineAbbr.includes(disciplineAbbr);
};

export const checkAuditStatus = auditData => {
  let isValid = true;
  auditData?.items?.forEach(x => {
    if (x.status === constants.ongoing && x.holdFileOut) isValid = false;
  });
  return isValid;
};

export const checkSkillsChecklistValidity = (candidateSkillsChecklist: any) => {
  let isValid = false;

  if (candidateSkillsChecklist && candidateSkillsChecklist?.skillsCheckList?.length > 0) {
    candidateSkillsChecklist?.skillsCheckList?.forEach(x => {
      x.matchingSkillsChecklist?.forEach(y => {
        const date = new Date(y.dateCompleted);
        date.setDate(date.getDate() + 1095);
        const current = new Date();
        if (date.getTime() > current.getTime()) {
          isValid = true;
        }
      });
    });

    candidateSkillsChecklist?.unMatchedCheckList?.forEach(x => {
      const date = new Date(x.dateCompleted);
      date.setDate(date.getDate() + 1095);
      const current = new Date();
      if (date.getTime() > current.getTime()) {
        isValid = true;
      }
    });
  }
  return isValid;
};

const lastGraduatedOn = (educations: Education[]) => {
  const orderedEducation = orderBy(
    educations,
    e => [moment('01/' + e?.graduatedOn, 'DD/MM/YYYY').format('YYYY/MM/DD')],
    'desc',
  );
  const latestEducation = orderedEducation[0];
  return moment('01/' + latestEducation?.graduatedOn, 'DD/MM/YYYY');
};

const isEducationWithinRange = (educations: Education[], start: Moment, end: Moment): boolean => {
  const graduatedOn = lastGraduatedOn(educations);
  return graduatedOn.isBetween(start, end);
};

const isGraduationDateBefore = (educations: Education[], beforeDate: Moment) => {
  const graduatedOn = lastGraduatedOn(educations);
  return graduatedOn.isBefore(beforeDate);
};

const getDivisionDiscipline = (lookup: ILookupState, config: IDivisionDisciplineConfiguration) => {
  const result = lookup.divisionDisciplines.find(
    e =>
      e.amnDivisionId === config.divisionId &&
      e.disciplineId === config.disciplineId &&
      e.specialtyId === config.specialtyId,
  );
  return result;
};

const getExperienceInDays = (e: Employment): number => {
  const experienceInDaysFromYearAndMonth = e.yearsOfExperience * 12 * 30 + e.monthsOfExperience * 30;
  const experienceInDaysFromStartAndEndDate = moment(e.endedOn ? moment(e.endedOn) : moment()).diff(
    moment(e.startedOn),
    'day',
  );
  return experienceInDaysFromYearAndMonth > experienceInDaysFromStartAndEndDate
    ? experienceInDaysFromYearAndMonth
    : experienceInDaysFromStartAndEndDate;
};

export const checkWorkExperienceValidity = (candidateDetails: ICandidateDetails, lookup: ILookupState) => {
  let isValid = false;
  let isExperienced = false;
  const today = moment();
  let totalExperienceText = '';
  candidateDetails?.experience?.education?.forEach(y => {
    // appending 01/ since we are only getting month/year in graduation date
    const graduatedOn = moment('01/' + y?.graduatedOn, 'DD/MM/YYYY');
    if (today.diff(graduatedOn, 'month') > 20) {
      isExperienced = true;
    }
  });
  candidateDetails?.experience?.skillsets?.forEach(x => {
    let totalExperience = 0;
    if (x.disciplineAbbr === constants.rn) {
      if (!isExperienced && (x.yearsOfExperience > 0 || x.monthsOfExperience > 9)) isValid = true;
      else {
        candidateDetails.experience?.employment?.forEach(exp => {
          const startedOnDate = moment(new Date(exp.startedOn));
          const endedOnDate = moment(new Date(exp.endedOn));
          const threeYearsAgo = moment().add(-3, 'years');

          if (exp.disciplineAbbr === constants.rn) {
            if (exp.currentlyEmployed) {
              if (exp.yearsOfExperience > 0 || exp.monthsOfExperience > 11) isValid = true;
              else totalExperience = totalExperience + exp.monthsOfExperience;
            }
            // not currently employed and started within past 3 years
            if (!exp.currentlyEmployed && startedOnDate.diff(today, 'year', true) < 3) {
              totalExperience = totalExperience + (exp.yearsOfExperience * 12 + exp.monthsOfExperience);
            }
            if (
              !exp.currentlyEmployed &&
              startedOnDate.diff(today, 'year', true) > 3 && // should have started more than 3 years ago
              endedOnDate.diff(today, 'year', true) < 3 // should have ended after 3 years
            ) {
              totalExperience += threeYearsAgo.diff(endedOnDate, 'months');
            }
          }
        });
        if (totalExperience > 11) isValid = true;
      }
    } else {
      candidateDetails.experience?.employment?.forEach(y => {
        const startedOnDate = moment(new Date(y.startedOn));
        const endedOnDate = moment(new Date(y.endedOn));
        const threeYearsAgo = moment().add(-3, 'years');
        if (y.disciplineAbbr !== constants.rn) {
          if (y.currentlyEmployed && (y.yearsOfExperience > 0 || y.monthsOfExperience > 9)) {
            if (y.yearsOfExperience > 0 || y.monthsOfExperience > 11) isValid = true;
            else totalExperience = totalExperience + y.monthsOfExperience;
          }
          if (!y.currentlyEmployed && startedOnDate.diff(today, 'year', true) < 3) {
            totalExperience = totalExperience + (y.yearsOfExperience * 12 + y.monthsOfExperience);
          }
          if (
            !y.currentlyEmployed &&
            startedOnDate.diff(today, 'year', true) > 3 && // should have started more than 3 years ago
            endedOnDate.diff(today, 'year', true) < 3 // should have ended after 3 years
          ) {
            totalExperience += threeYearsAgo.diff(endedOnDate, 'months');
          }
        }
      });
    }
    if (!isValid) totalExperienceText = `${totalExperience} ${totalExperience > 1 ? 'months' : 'month'}`;
  });

  // rule for division discipline
  if (candidateDetails?.experience.employment.length > 0 && lookup) {
    const groupedEmployment = groupBy(
      candidateDetails?.experience.employment.filter(
        e => e.disciplineAbbr !== constants.rn && !isDisciplineAllowedForNewGraduate(e.disciplineAbbr),
      ),
      e => [e.disciplineId, e.specialtyId],
    );

    forEach(groupedEmployment, (employments, key) => {
      const keyValue = key.split(',').map(e => Number.parseInt(e));
      const config: IDivisionDisciplineConfiguration = {
        disciplineId: keyValue[0],
        specialtyId: keyValue[1],
        divisionId: candidateDetails?.divisionType
          ? AMNDivisionType[candidateDetails?.divisionType]
          : AMNDivisionType.unknown,
      };

      const divisionDiscipline = getDivisionDiscipline(lookup, config);
      if (!divisionDiscipline && keyValue[0] && keyValue[1]) isValid = true;
      if (divisionDiscipline) {
        let experienceInDays = 0;
        const minimumExperienceInDaysRequired = divisionDiscipline.experienceDays;

        if (divisionDiscipline.calculationDescription === 'Individual Experience') {
          employments?.forEach(e => {
            experienceInDays = getExperienceInDays(e);
          });
        }
        if (divisionDiscipline.calculationDescription === 'Combined Experience') {
          employments?.forEach(e => {
            experienceInDays += getExperienceInDays(e);
          });
        }
        // 2 months are eased off as of now as per business logic
        if (experienceInDays > minimumExperienceInDaysRequired - 60) isValid = true;
      }
    });
  }

  if (
    doesAllowedDisciplineExistInVerifiedAndUnverifiedSkillSet(
      candidateDetails?.verifiedSkills,
      candidateDetails?.unVerifiedSkills,
    )
  ) {
    isValid = true;
  }
  return { isValid, totalExperienceText };
};

export const getEducationValidity = (educations: Education[], candidateDetails: ICandidateDetails) => {
  const orderedEducation = orderBy(educations, e => e?.graduatedOn, 'desc');
  const latestEducation = orderedEducation?.length > 0 ? orderedEducation[0] : null;
  let educationMessage = latestEducation
    ? latestEducation.graduatedOnMonth
      ? latestEducation.graduatedOnYear
        ? null
        : 'candidate.virtualInterviewCard.missingGraduationYear'
      : 'candidate.virtualInterviewCard.missingGraduationMonth'
    : 'candidate.virtualInterviewCard.noGraduationDate';

  const { educationMessage: message, isValid: isNewGradValid } = applyNewGradsLogic(candidateDetails);

  return { educationValidationError: educationMessage ?? isNewGradValid ? null : message };
};

export const getValidationReasons = (
  workExperience: boolean,
  skillSet: boolean,
  status: boolean,
  education: boolean,
) => {
  let reasons: string[] = [];

  if (status) reasons.push('Candidate Status');
  if (workExperience) reasons.push('Work History');
  if (education) reasons.push('Education');
  if (skillSet) reasons.push('Skill Sets');

  let validationMessage = '';
  reasons?.forEach((value, index) => {
    validationMessage += value;
    if (index + 1 === reasons.length) validationMessage += '.';
    else if (index > 0 && index + 2 === reasons.length) validationMessage += ' and ';
    else validationMessage += ', ';
  });

  return 'Disabled due to ' + validationMessage;
};

export const applyNewGradsLogic = (candidateDetails: ICandidateDetails) => {
  let isValid = false;
  let educationMessage = '';
  candidateDetails?.verifiedSkills?.forEach(v => {
    if (isDisciplineAllowedForNewGraduate(v.disciplineAbbr)) {
      const isNewGrad: boolean =
        v.disciplineAbbr === speechLanguagePathologist
          ? isEducationWithinRange(
              candidateDetails.experience.education,
              moment().add(-2, 'year'),
              moment().add(90, 'day'),
            ) && candidateDetails?.experience?.maxEmploymentGap < 120
          : isEducationWithinRange(
              candidateDetails.experience.education,
              moment().add(-2, 'year'),
              moment().add(30, 'day'),
            );

      if (isNewGrad) isValid = true;
      if (!isValid) {
        const isOlderThan2Years = isGraduationDateBefore(
          candidateDetails.experience.education,
          moment().add(-2, 'year'),
        );
        if (isOlderThan2Years) educationMessage = `Graduation Date > 2 years in the past`;
        else {
          v.disciplineAbbr === speechLanguagePathologist
            ? (educationMessage = `Graduation Date > 90 days`)
            : (educationMessage = `Graduation Date > 30 days`);
        }
      }
    }
  });

  candidateDetails?.unVerifiedSkills?.forEach(v => {
    if (isDisciplineAllowedForNewGraduate(v.disciplineAbbr)) {
      const isNewGrad: boolean =
        v.disciplineAbbr === speechLanguagePathologist
          ? isEducationWithinRange(
              candidateDetails.experience.education,
              moment().add(-2, 'year'),
              moment().add(90, 'day'),
            ) && candidateDetails?.experience?.maxEmploymentGap < 120
          : isEducationWithinRange(
              candidateDetails.experience.education,
              moment().add(-2, 'year'),
              moment().add(30, 'day'),
            );

      if (isNewGrad) isValid = true;
      if (!isValid) {
        const isOlderThan2Years = isGraduationDateBefore(
          candidateDetails.experience.education,
          moment().add(-2, 'year'),
        );
        if (isOlderThan2Years) educationMessage = `Graduation Date > 2 years in the past`;
        else {
          v.disciplineAbbr === speechLanguagePathologist
            ? (educationMessage = `Graduation Date > 90 days`)
            : (educationMessage = `Graduation Date > 30 days`);
        }
      }
    }
  });

  return { isValid, educationMessage };
};

export const doesAllowedDisciplineExistInVerifiedAndUnverifiedSkillSet = (
  verifiedSkillSets: VerifiedSkill[],
  unVerifiedSkillSets: UnVerifiedSkill[],
) => {
  return (
    verifiedSkillSets.some(e => allowedNewGraduateDisciplineAbbr.includes(e.disciplineAbbr)) ||
    unVerifiedSkillSets.some(e => allowedNewGraduateDisciplineAbbr.includes(e.disciplineAbbr))
  );
};
