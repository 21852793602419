import { InlineAutoComplete } from 'app/components/Common/EditPage/InlineAutoComplete';
import { ITypeAheadOption } from 'app/models/Orders/OrderDetails';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectEditableRequirementTypes } from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { TransportationRow } from './TransportationTable';

export const Gender = () => {
  const { t } = useTranslation();
  const { gender } = useSelector(selectEditableRequirementTypes);
  const [options] = useState<ITypeAheadOption[]>([
    { object: 'F', label: 'Female' },
    { object: 'M', label: 'Male' },
  ]);

  return (
    <TransportationRow
      id="placement-offer-transportation-gender"
      label={t('placement.profile.summary.offer.transportation.gender')}
      requirement={gender?.requirement}
      controlComponent={
        <Controller
          name="gender"
          render={({ ref, ...rest }) => (
            <InlineAutoComplete
              {...rest}
              /** Input value is used to override textfield incase DOM is rerendered */ inputValue={rest.value?.label}
              options={options}
              style={{ maxWidth: '110px' }}
            />
          )}
        />
      }
    />
  );
};
