import { Grid, IconButton, MenuItem, Select, SelectChangeEvent } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { getMonth, getYear, setMonth, setYear } from 'date-fns';
import React from 'react';
import { Theme } from '@emotion/react';

interface HeaderProps {
  date: Date;
  minDate?: Date;
  maxDate?: Date;
  setDate: (date: Date) => void;
  nextDisabled: boolean;
  prevDisabled: boolean;
  onClickNext: () => void;
  onClickPrevious: () => void;
  firstSet: boolean;
  secondSet: boolean;
  monthDropDownOpen: boolean;
  yearDropDownOpen: boolean;
  setMonthDropDownOpen: (open, firstSet) => void;
  setYearDropDownOpen: (open, firstSet) => void;
  quickSelect?: boolean;
  single?: boolean;
}

const useStyles = makeStyles<{ quickSelect: boolean; single: boolean }>()((theme: Theme, { quickSelect, single }) => ({
  iconContainer: {
    padding: 5,
  },
  icon: {
    padding: 10,
    '&:hover': {
      background: 'none',
    },
  },
  selectMonthWrapper: {
    zIndex: 2000,
    width: '85px',
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  selectYearWrapper: {
    zIndex: 2000,
    width: '85px',
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  select: {
    paddingLeft: '5px',
  },
  selectMonth: {
    left: 'unset !important',
    right: single ? '147px !important' : '438px !important',
    top: '47px !important',
  },
  selectYear: {
    left: 'unset !important',
    right: single ? '58px !important' : '349px !important',
    top: '47px !important',
  },
}));

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

const generateYears = (relativeTo: Date, count: number) => {
  const half = Math.floor(count / 2);
  return Array(count)
    .fill(0)
    .map((y, i) => relativeTo.getFullYear() - half + i);
};

const generateConstrainedYears = (minDate: Date, maxDate: Date) => {
  const minDateYear = minDate.getFullYear();
  const count = maxDate.getFullYear() - minDate.getFullYear() + 1;
  return Array(count)
    .fill(0)
    .map((y, i) => minDateYear + i);
};

const switchYears = (autoYears, constrainedYears) => (minDate, maxDate, date, constant) => {
  if (minDate && maxDate) return constrainedYears(minDate, maxDate);
  else return autoYears(date, constant);
};

const Header: React.FunctionComponent<HeaderProps> = ({
  date,
  minDate,
  maxDate,
  setDate,
  nextDisabled,
  prevDisabled,
  onClickNext,
  onClickPrevious,
  firstSet,
  secondSet,
  monthDropDownOpen,
  yearDropDownOpen,
  setMonthDropDownOpen,
  setYearDropDownOpen,
  quickSelect,
  single,
}) => {
  const { classes } = useStyles({ quickSelect, single });
  const handleMonthChange = (event: SelectChangeEvent) => {
    setDate(setMonth(date, parseInt(event.target.value as string)));
  };

  const handleYearChange = (event: SelectChangeEvent) => {
    setDate(setYear(date, parseInt(event.target.value as string)));
  };

  const selectMonthRef = React.useRef<any>();
  const selectYearRef = React.useRef<any>();

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item className={classes.iconContainer}>
        <IconButton color="primary" className={classes.icon} disabled={prevDisabled} onClick={onClickPrevious} size="small">
          <ChevronLeft color={prevDisabled ? 'disabled' : 'action'} />
        </IconButton>
      </Grid>
      <Grid item>
        <Select
          inputRef={selectMonthRef}
          className={classes.selectMonthWrapper}
          open={monthDropDownOpen}
          size="small"
          onOpen={event => {
            setMonthDropDownOpen(true, firstSet);
            setYearDropDownOpen(false, firstSet);
          }}
          onClose={event => {
            setMonthDropDownOpen(false, firstSet);
            setYearDropDownOpen(false, firstSet);
          }}
          value={`${getMonth(date)}`}
          onChange={handleMonthChange}
          classes={{
            select: classes.select,
          }}
          MenuProps={{
            disablePortal: true,
            anchorEl: element => element,
            // HACK: Anchor not positioning correctly while inside Popper
            PaperProps: {
              className: classes.selectMonth,
            },
          }}
        >
          {MONTHS.map((month, idx) => (
            <MenuItem key={month} value={idx}>
              {month}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item>
        <Select
          ref={selectYearRef}
          className={classes.selectYearWrapper}
          value={`${getYear(date)}`}
          onChange={handleYearChange}
          size="small"
          classes={{
            select: classes.select,
          }}
          open={yearDropDownOpen}
          onOpen={event => {
            setMonthDropDownOpen(false, firstSet);
            setYearDropDownOpen(true, firstSet);
          }}
          onClose={event => {
            setMonthDropDownOpen(false, firstSet);
            setYearDropDownOpen(false, firstSet);
          }}
          MenuProps={{
            disablePortal: true,
            anchorEl: element => element,
            PaperProps: {
              // HACK: Anchor not positioning correctly while inside Popper
              className: classes.selectYear,
            },
          }}
        >
          {switchYears(generateYears, generateConstrainedYears)(minDate, maxDate, date, 30).map(year => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item className={classes.iconContainer}>
        <IconButton color="primary" className={classes.icon} disabled={nextDisabled} onClick={onClickNext} size="small">
          <ChevronRight color={nextDisabled ? 'disabled' : 'action'} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Header;
