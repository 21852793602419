import React, { useMemo } from 'react';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { SubmissionEmailWrapper } from '@AMIEWEB/Common/SubmissionPreference/SubmissionEmail/SubmissionEmailWrapper';
import { SubmissionPacketWrapper } from '@AMIEWEB/Common/SubmissionPreference/SubmissionPacket/SubmissionPacketWrapper';
import { SubmissionLimitsWrapper } from '@AMIEWEB/Common/SubmissionPreference/SubmissionLimit/SubmissionLimitsWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { selectFacilityPreferences, selectMiscellaneousDate } from 'store/redux-store/facility-preferences/selectors';
import { usePromiseTracker } from 'react-promise-tracker';
import { placementStage } from '@AMIEWEB/Order/OrderDetails/OrderPreferences/store/OrderPreference.redux';
import { ff_facility_ui_newpreference_rfo_to_sent } from 'app/constants/FeatureFlags/Facility/Keys';
import { selectFacilityContacts } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { facilityPreferenceActions } from 'store/redux-store/facility-preferences/slice';
import { useParams } from 'react-router-dom';

export const SubmissionPreferences = props => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const facilityId = parseInt(id);
  const { isStrike = false, resetForm, isDirty } = props;
  const { disableEmailContainer, disablePacketContainer } = useSelector(selectMiscellaneousDate);
  const clientContacts = useSelector(selectFacilityContacts);

  const { submissionPacketSetup, submissionEmailSetup } = useSelector(selectFacilityPreferences);

  const { promiseInProgress: isFetchingSubmissionPref } = usePromiseTracker({
    area: `facility-preference-get-automation-status-by-stage-${placementStage.submission}`,
    delay: 0,
  });

  const { promiseInProgress: isFetchingPref } = usePromiseTracker({
    area: 'facility-preference-get-automation-status-by-stage',
    delay: 0,
  });

  const { promiseInProgress: isSaving } = usePromiseTracker({
    area: `update-facility-preferences-${placementStage.submission}`,
    delay: 0,
  });

  const { promiseInProgress: isFetchingPlacementCounts } = usePromiseTracker({
    area: 'facility-preference-active-placementcounts',
    delay: 0,
  });

  const isFetchingPreferences = useMemo(
    () => isFetchingSubmissionPref || isFetchingPref,
    [isFetchingSubmissionPref, isFetchingPref],
  );

  const {
    submissionLimits: { activePlacementCounts },
  } = useSelector(selectFacilityPreferences);

  const updateContainerState = (
    isEnabled: boolean,
    containerName: 'disableEmailContainer' | 'disablePacketContainer',
  ) => {
    dispatch(
      facilityPreferenceActions.setMiscellaneousData({
        key: containerName,
        value: isEnabled,
      }),
    );
  };

  return (
    <LayoutGrid xs={13} direction="column" data-testid="submission-container" spacing={2}>
      <LayoutGridItem>
        <SubmissionLimitsWrapper
          showActionItems={true}
          isSaving={isSaving}
          isFetchingPlacementCounts={isFetchingPlacementCounts}
          activePlacementCounts={activePlacementCounts}
          updateContainerState={updateContainerState}
          isStrike={isStrike}
          resetForm={resetForm}
          submissionPacketSetup={submissionPacketSetup}
          submissionEmailSetup={submissionEmailSetup}
          isDirty={isDirty}
          flag={ff_facility_ui_newpreference_rfo_to_sent}
          clientContacts={clientContacts}
          isFetchingPreferences={isFetchingPreferences}
        />
      </LayoutGridItem>
      <LayoutGridItem>
        <SubmissionPacketWrapper disabled={disablePacketContainer} />
      </LayoutGridItem>
      <LayoutGridItem>
        <SubmissionEmailWrapper disabled={disableEmailContainer} facilityId={facilityId}/>
      </LayoutGridItem>
    </LayoutGrid>
  );
};
