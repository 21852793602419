/* eslint-disable i18next/no-literal-string */
import React, { useEffect, useState } from 'react';
import { Divider, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import styledComponent from 'styled-components';
import { useTranslation } from 'react-i18next';
import { gridControlType } from 'app/models/Grid';
import moment from 'moment';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

const useStyles = makeStyles()(theme => ({
  paperContainer: {
    overflow: 'hidden',
    minWidth: '300px',
    overflowY: 'auto',
    paddingBottom: '6px',
  },
  content: {
    padding: '12px 12px',
  },
  title: {
    cursor: 'pointer',
  },
}));

const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

const VersionDetailsHoverView = props => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { width } = useWindowSize();
  const [open, setOpen] = useState<boolean>(false);

  const [detailRows, setDetailRows] = useState<any>({ rows: [], columns: [] });

  useEffect(() => {
    setDetailRows(props.credential);
  }, [props.credential]);

  const getControl = (col: any, rows: any) => {
    const value = rows[col.title];
    switch (col.control?.type) {
      case gridControlType.dropDown:
        return rows[`${col.title}FilterOptions`].find(item => item.value === rows[col.title])?.description || 'N/A';
      case gridControlType.date:
      case gridControlType.dateLabel:
        return value && value !== 'N/A' ? moment(value).format('MM/DD/yyyy') : 'N/A';
      case gridControlType.checkbox:
        return value ? 'Yes' : 'No';
      default:
        return value || 'N/A';
    }
  };

  const getRow = (col: any, rows: any) => {
    return (
      <Grid item>
        <Row>
          <RowHead>{col.value}</RowHead>
          <RowContent>{getControl(col, rows)}</RowContent>
        </Row>
      </Grid>
    );
  };

  const CardContent = () => {
    return (
      <>
        <Grid container spacing={2} direction="column" className={classes.content}>
          <Grid item>
            <Title style={{ color: '#333333', fontSize: '16px', fontWeight: 'bold' }}>
              {t('candidate.credentials.attachments.credentialDetails.versionTitle')}
            </Title>
          </Grid>
          <CustomDivider flexItem />
          <Grid item>{props.titleBold}</Grid>
          <Grid item container spacing={1} direction="column" justifyContent="space-between">
            {detailRows?.map((mainRow, mainRowIndex) => {
              const { columns, rows } = mainRow;
              return columns?.map((col, rowIndex) => {
                return getRow(col, rows);
              });
            })}
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {popupState => (
          <div style={{ overflow: 'auto' }}>
            <Typography
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              {...bindTrigger(popupState)}
              className={classes.title}
            >
              {props.title}
            </Typography>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              anchorReference="anchorPosition"
              anchorPosition={{ top: 600, left: width }}
              classes={{ paper: classes.paperContainer }}
            >
              <CardContent />
            </Popover>
          </div>
        )}
      </PopupState>
    </>
  );
};

export default VersionDetailsHoverView;

const Row = styledComponent.tr`
  display: flex;
  justify-content: space-between;
  padding: 5px;
`;

const RowHead = styledComponent.th`
  font-size: 12px;
  text-align: left;
  max-width: 148px;
`;

const RowContent = styledComponent.td`
  color: #003e68;
  font-size: 12px;
  text-align: end;
  font-weight: 600;
  max-width: 148px;
`;

const Title = styledComponent.div`
  font: normal normal normal 16px/21px Roboto;
  color: #7d7b7b;
  opacity: 1;
  text-align: left;
  font-weight: 500;
  letter-spacing: 0px;
`;

export const CustomDivider = styledComponent(Divider)`
  opacity: 1;
  border: 1px solid #d1d1d1;
`;
