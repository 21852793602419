export enum FilterCompTypes {
  SELECT = 'SELECT',
  CALENDAR = 'CALENDAR',
  DATERANGE = 'DATERANGE',
  DATERANGEV2 = 'DATERANGEV2',
  TYPEAHEAD = 'TYPEAHEAD',
  ASYNCTYPEAHEAD = 'ASYNCTYPEAHEAD',
  TYPEAHEADDROPDOWN = 'TYPEAHEADDROPDOWN',
  CUSTOMDROPDOWN = 'CUSTOMDROPDOWN',
  ADVANCEDROPDOWN = 'ADVANCEDROPDOWN',
  TEXTFIELDNUMBER = 'TEXTFIELDNUMBER',
  NUMBERRANGE = 'NUMBERRANGE',
  TREE = 'TREE',
  NESTEDFILTER='NESTEDFILTER',
  COMPACTSTATES = 'COMPACTSTATES',
  CUSTOMCHECKBOX = 'CUSTOMCHECKBOX',
  CANDIDATESEARCH = 'CANDIDATESEARCH',
  CANDIDATESKILLSET = 'CANDIDATESKILLSET',
}

export enum FilterLayoutTypes {
  OR = 'OR',
  SINGLE = 'SINGLE',
}
