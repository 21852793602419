import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IOrderMatchAlertData {
  items: any[];
  unreadOrderIds: any[];
}
export interface IAssociatedRecord {
  name: string;
  value: string;
}
export const initialState: IOrderMatchAlertData = {
  items: [],
  unreadOrderIds: [],
};

const getOrderMatchAlertAction = createAction<{
  associatedRecords: IAssociatedRecord[];
  readStatus: boolean;
  pageSize: number;
  pageNumber: number;
}>('GET_ORDERMATCH_ALERT');

const reduxSlice = createSlice({
  name: 'orderMatchAlert',
  initialState,
  reducers: {
    setData(state, action: PayloadAction<any[]>) {
      state.items = action.payload;
    },
    reset() {
      return { ...initialState };
    },
    setUnreadOrderIds(state, action: PayloadAction<any[]>) {
      state.unreadOrderIds = action.payload;
    },
  },
});

export const getOrderMatchAlertActions = { ...reduxSlice.actions, getOrderMatchAlertAction };
export const { name: orderMatchAlertSliceKey, reducer: orderMatchAlertReducer } = reduxSlice;
