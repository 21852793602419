import React from 'react';
import { Box } from 'amn-ui-core';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';

export const viewSSN = (ssnValue: string, user: any) => {
  const partiallyHidden = '*** ** ';
  let ssnPartialView = `${partiallyHidden}${ssnValue?.toString().slice(-4)}`;
  if (
    Authorized(
      [
        userRoles.accountManagement_Leadership,
        userRoles.accountManagement_TeamMember,
        userRoles.recruitment_TeamMember,
        userRoles.recruitment_Leadership,
        userRoles.credentialing_Leadership,
        userRoles.credentialing_TeamMember,
        userRoles.clinical_Director,
        userRoles.clinical_Manager,
        userRoles.clinical_QualificationsSpecialist,
        userRoles.clinical_ReviewAnalyst,
        userRoles.payrollSpecialist,
        userRoles.housingAccountExecutive,
        userRoles.timeProcessingSpecialist,
        userRoles.workersCompCoordinator,
        userRoles.riskManagement,
        userRoles.complianceAuditSpecialist,
        userRoles.benefitsCoordinator,
        userRoles.customerSupport_Leadership,
        userRoles.customerSupport_TeamMember,
      ],
      user.userInfo,
    )
  ) {
    return (
      <Box>
        <span>{ssnPartialView}</span>
      </Box>
    );
  } else {
    return <span>{`*** ** ****`}</span>;
  }
};

export const ssnOnClickAccess = user => {
  if (
    Authorized(
      [
        userRoles.accountManagement_Leadership,
        userRoles.accountManagement_TeamMember,
        userRoles.recruitment_TeamMember,
        userRoles.recruitment_Leadership,
        userRoles.credentialing_Leadership,
        userRoles.credentialing_TeamMember,
        userRoles.clinical_Director,
        userRoles.clinical_Manager,
        userRoles.clinical_QualificationsSpecialist,
        userRoles.clinical_ReviewAnalyst,
        userRoles.payrollSpecialist,
        userRoles.housingAccountExecutive,
        userRoles.timeProcessingSpecialist,
        userRoles.workersCompCoordinator,
        userRoles.riskManagement,
        userRoles.complianceAuditSpecialist,
        userRoles.benefitsCoordinator,
        userRoles.customerSupport_Leadership,
        userRoles.customerSupport_TeamMember,
      ],
      user.userInfo,
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const viewDOB = (dob: string, user: any) => {
  if (
    Authorized(
      [
        userRoles.accountManagement_Leadership,
        userRoles.accountManagement_TeamMember,
        userRoles.recruitment_TeamMember,
        userRoles.recruitment_Leadership,
        userRoles.credentialing_Leadership,
        userRoles.credentialing_TeamMember,
        userRoles.clinical_Director,
        userRoles.clinical_Manager,
        userRoles.clinical_QualificationsSpecialist,
        userRoles.clinical_ReviewAnalyst,
        userRoles.payrollSpecialist,
        userRoles.housingAccountExecutive,
        userRoles.timeProcessingSpecialist,
        userRoles.workersCompCoordinator,
        userRoles.riskManagement,
        userRoles.complianceAuditSpecialist,
        userRoles.benefitsCoordinator,
        userRoles.customerSupport_Leadership,
        userRoles.customerSupport_TeamMember,
      ],
      user.userInfo,
    )
  ) {
    return (
      <Box>
        <span>{dob}</span>
      </Box>
    );
  } else {
    return <span>{`**/**/****`}</span>;
  }
};
