import { createAction } from '@reduxjs/toolkit';
import { IActiveCountRequest, ICopyPreferencesRequest, IUnitOrderPreference, IUpdateUnitPreferences } from './types';

const getActivePlacementCounts = createAction<{
  unitId: number;
}>('GET_ACTIVE_PLACEMENT_COUNT_UNIT');

const getFacilityUnitClientContacts = createAction<{
  facilityId: number;
  unitId: number;
}>('GET_FACILTY_UNIT_CLIENT_CONTACTS');

const updateUnitPreferences = createAction<{
  data: IUpdateUnitPreferences;
  isCopyingPreferences: boolean;
}>('UPDATE_UNIT_PREFERENCES');

const getUnitPreferences = createAction<{
  unitId: number;
}>('GET_UNIT_PREFERENCES');

const getExistingOrders = createAction<{
  unitId: number;
  facilityId: number;
}>('GET_UNIT_EXISTING_ORDERS_LIST');

const updatePrefrencesToOrders = createAction<{
  data: ICopyPreferencesRequest;
  facilityId: number;
}>('UPDATE_UNIT_PREFERENCES_TO_ORDERS');

const requestExistingPlacements = createAction<{
  data: IUnitOrderPreference;
}>('GET_EXISTING_PLACEMENT');

const requestOrderActivePlacementCounts = createAction<{
  data: IActiveCountRequest;
}>('GET_ORDER_PLACEMNT_ACTIVE_COUNT');

const requestReadyToSubmitRfoPlacementIds = createAction<{
  data: IUnitOrderPreference;
}>('GET_READY_TO_SUBMIT_RFO_PLACEMENT');

export const serviceActions = {
  getActivePlacementCounts,
  getFacilityUnitClientContacts,
  updateUnitPreferences,
  getUnitPreferences,
  getExistingOrders,
  updatePrefrencesToOrders,
  requestExistingPlacements,
  requestOrderActivePlacementCounts,
  requestReadyToSubmitRfoPlacementIds
};
