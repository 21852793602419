/**Add the service call actions here */

import { createAction } from '@reduxjs/toolkit';

const getAutoTaskGridData = createAction('GET_AUTO_TASK_GRID_DATA');
const getAutoTaskEmployeeRoles = createAction('GET_AUTO_TASK_EMPLOYEE_ROLES');
const getAutoTaskManagementStatusLookUp = createAction('GET_AUTO_TASK_MANAGEMENT_STATUS_LOOKUP');
const updateAutoTaskManagement = createAction('UPDATE_AUTO_TASK_MANAGEMENT');
export const serviceActions = {
  getAutoTaskGridData,
  getAutoTaskEmployeeRoles,
  updateAutoTaskManagement,
  getAutoTaskManagementStatusLookUp
};
