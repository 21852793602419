import { GridCellParams } from '@mui/x-data-grid-pro';
import React from 'react';
import styledComponent from 'styled-components';
import { colors } from 'styles/styleVariables';

/**
 * Cell renderer to display the text as blue `(colors.amnBlue)`
 * @param params X-Grid Params: `GridCellParams`
 * @returns JSXElement
 */
export const XGridBlueCell = (params: GridCellParams) => {
  return <BlueName>{params.value}</BlueName>;
};

const BlueName = styledComponent.span`
  color: ${colors.amnBlue};
`;