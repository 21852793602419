import { Theme, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReplyIcon from '@mui/icons-material/Reply';
import AttachmentDetailsModal, { GridRow } from './AttachmentDetailsModal';
import { useDispatch, useSelector } from 'react-redux';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { selectUser } from 'oidc/user.selectors';
import { kofaxNotificationData } from '../../../../GlobalSearch/helper';
import { PlacementInfo } from 'app/models/Candidate/CandidateProfile';
import { IUser } from 'oidc/user.redux';
import { EmailDefaultValues, IEmailAttachment, IEmailData } from 'app/models/Email/IEmail';
import { ContactProps } from 'app/models/Notification/Notification';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    background: '#E8F4FD',
    borderBottom: '1px solid #ccc',
    borderTop: '1px solid #ccc',
    padding: '1rem 2rem',
    display: 'flex',
    width: '103%',
    justifyContent: 'space-between',
    '&>p': {
      fontSize: '1rem',
    },
    '&>svg': {
      color: 'blue',
      transform: 'rotateY(180deg)',
    },
  },
  header: {
    margin: '1% 0',
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Roboto',
    letterSpacing: '0px',
    color: '#333333',
    opacity: 1,
  },
}));

interface IKofaxProps {
  attachmentUrls: [string, string, string][];
  travelerId: string;
  name: string;
}
const EmailData = (
  name: string,
  cid: string,
  user: IUser,
  attachmentUrls: [string, string, string][],
  selectedRow: (GridRow & PlacementInfo) | undefined,
  isUrgent: boolean,
  ccs: ContactProps[],
): IEmailData => {
  const userData = kofaxNotificationData(user);
  const dns = (globalThis?.app?.env?.REACT_APP_BASE_URL as string).split('//')[1];
  const isProd = !(dns.includes('dev') || dns.includes('localhost') || dns.includes('qa') || dns.includes('int'));
  const substitutions = [
    {
      name: 'Signature',
      input: '{{signature}}',
    },
  ];
  const recipient = [
    {
      name: 'Kofax',
      email: !isProd ? 'Kofax.Credentialing.QA2@amnhealthcare.com' : 'Kofax.Credentialing@amnhealthcare.com',
      contactId: '',
      phoneNumber: '',
      contactType: '',
      brand: '',
      fixed: true,
    },
  ];
  userData.tos = recipient;
  userData.ccs = ccs;
  let subject = isUrgent ? '**URGENT** ' : '';

  let commonText = '';
  commonText += !!selectedRow ? `PID:${selectedRow!.placementId} ` : '';
  commonText += `Traveler: ${name} (${cid}) `;
  commonText += !!selectedRow && !!selectedRow!.facilityId ? `FID: ${selectedRow!.facilityId!}` : '';

  subject += commonText;
  userData.body = commonText + userData.body;

  const emailAttachmentUrls: IEmailAttachment[] = attachmentUrls.map(url => ({
    fileName: url[0],
    url: url[1],
    signedUrl: url[2],
  }));

  return {
    ...EmailDefaultValues(),
    substitutions: substitutions,
    ...userData,
    subject: subject,
    attachmentUrls: emailAttachmentUrls,
    attachmentCannotBeRemoved: true,
  };
};
const SendKofaxEmail = ({ attachmentUrls, travelerId, name }: IKofaxProps) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [openAttachmentModal, setOpenAttachmentModalState] = useState(false);
  const user = useSelector(selectUser);

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>{t('notification.sms.sendKofax')}</Typography>
      <ReplyIcon style={{ cursor: 'pointer' }} onClick={() => setOpenAttachmentModalState(true)} />
      {/* Portal Components */}
      {openAttachmentModal && (
        <AttachmentDetailsModal
          attachmentUrls={attachmentUrls}
          travelerId={travelerId}
          handleClose={() => {
            setOpenAttachmentModalState(false);
          }}
          handleNext={(selectedRow, isUrgent, ccs) => {
            dispatch(
              notificationDataActions.setEmailData({
                data: EmailData(name, travelerId, user, attachmentUrls, selectedRow, isUrgent, ccs),
                open: true,
                minimized: false,
              }),
            );
            setOpenAttachmentModalState(false);
          }}
        />
      )}
    </div>
  );
};

export default SendKofaxEmail;
