import { CircularProgress, Alert } from 'amn-ui-core';
import React, { useEffect, useState, useCallback } from 'react';
import { deepEqualSoftValue } from 'utils/common/comparison';
import { CredentialsGridType } from '../CredentialsGrid';
import { findMyComparingCredentialDate, formatEntry, getCredentialModel } from '../CredentialsHelpers';
import { detailColumnsAndRows } from '../TableData/tableHelpers';
import { AddCredentialBuilder } from './AddCredentialBuilder';
import { getLookups } from '../TableData/tableHelpers';
import moment from 'moment';
import { selectUser } from 'oidc/user.selectors';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { ICredentials } from 'app/models/Credentialing/Credentials';
import { gridControlType } from 'app/models/Grid';
import { trackEvent } from 'app-insights/appInsightsTracking';
import { getCredentialOrTypeName } from './CredentialsAdd';
import { gridTypeToCategoryString } from '../Search/searchHelpers';
import { CredentialingLookups, selectAddUnverifiedCredType } from '../../../Profile/CandidateDetails.selector';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { useReadOnly } from 'oidc/userRoles';
interface AddCredentialFormProps {
  credential: any;
  typeChanged: (_) => void;
  open: boolean;
  handleClose: () => void;
  handleBack: () => void;
  logCredential: (_, isSaveActive) => void;
  duplicateExist?: boolean;
  isAddLicense?: boolean;
  isCredentialCreated?: boolean;
  submitFailedAttachments?: (_) => void;
}

enum AddAlertType {
  none,
  sessionTimeout,
  cancel,
  back,
  expirationDate,
  expirationDateLesserDateCompleted,
  dateCompleted,
  expirationDateLesserDateCompletedSubmission,
  typeChangeOnLog,
}

enum CredentialSkillChecklist {
  printed = 'printed',
  insertDate = 'insertDate',
}

export const AddCredentialForm = (props: AddCredentialFormProps) => {
  const { credential, typeChanged, open, handleClose, handleBack, logCredential, duplicateExist, isAddLicense } = props;
  const user = useSelector(selectUser);
  const [model, setModel] = useState<any>(null);
  const [originalModel, setOriginalModel] = useState<any>({});
  const [columns, setColumns] = useState<any>(null);
  const [rows, setRows] = useState<any>(null);
  const [didEdit, setDidEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [typeDropDownValues, setTypeDropDownValues] = useState<any>(null);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const lookupselection = useSelector(CredentialingLookups);
  const addUnverifiedCredType = useSelector(selectAddUnverifiedCredType);
  const [alertData, setAlertData] = useState<{
    type: AddAlertType;
    title: string;
    content: string;
    positiveButton: string;
    negativeButton: string;
  }>({ type: AddAlertType.none, title: '', content: '', positiveButton: '', negativeButton: '' });
  const { t } = useTranslation();
  const [sessionTimer, setSessionTimer] = useState<boolean>(false);
  const [typePreSelected, setTypePreSelected] = useState<boolean>(false);
  const [tempType, setTempType] = useState<any>();
  const [isCredCreated, setIsCredCreated] = useState<boolean>(false);
  const { readOnly } = useReadOnly();

  useEffect(() => {
    if (!!credential) {
      setLoading(true);
      getCredentialModel(credential?.gridType)
        .then(response => {
          response.requirementTypeID = credential.requirementTypeID;
          response.category = gridTypeToCategoryString(credential.gridType);
          setDidEdit(false);
          setTypePreSelected(false);
          // set typeID/reqTypeID if pre-selected
          if (
            credential?.type?.value !== null &&
            credential?.type?.value !== undefined &&
            credential?.type?.value !== 'N/A'
          ) {
            if (credential?.gridType === CredentialsGridType.miscellaneousPendingMoleculeDevelopment) {
              response['reqTypeID'] = credential.type.value;
              setTypePreSelected(true);
            } else if (
              credential?.gridType === CredentialsGridType.certificationsOtherCertifications ||
              credential.gridType === CredentialsGridType.educationAndOnlineForms
            ) {
              response['typeID'] = credential.type.value;
              setTypePreSelected(true);
            }
          }
          credentialsToActivate(credential.requirementTypeID);
          setModel(response);
          setOriginalModel(response);
          if (credential?.gridType !== null && credential?.gridType !== undefined) {
            const lookups = lookupselection
              ?.filter(item => item.requirementTypeID === credential.requirementTypeID)
              .sort((a, b) => (a.sequence > b.sequence ? 1 : -1));
            const details = detailColumnsAndRows(
              response,
              lookups,
              [
                'LastUpdatedBy',
                'LastUpdate',
                credential.gridType === CredentialsGridType.miscellaneousPendingMoleculeDevelopment
                  ? 'ReqTypeID'
                  : credential.gridType === CredentialsGridType.certificationsOtherCertifications ||
                    credential.gridType === CredentialsGridType.educationAndOnlineForms
                  ? 'TypeID'
                  : '',
              ],
              true,
            );
            if (isAddLicense) {
              var tempColumns: any[] = [];
              details[0].columns.map(x => {
                if (x.title !== 'verifiedID' && x.title !== 'dateVerified' && x.title !== 'verifiedByName')
                  tempColumns.push(x);
              });
              setColumns(tempColumns);
            } else {
              // hide printed and insert date in add skill checklist under add credential modal
              const mappedDetails = details[0].columns.filter(
                item =>
                  item.title !== CredentialSkillChecklist.insertDate && item.title !== CredentialSkillChecklist.printed,
              );
              setColumns(mappedDetails);
            }
            setRows(details[0].rows);

            if (
              credential.gridType === CredentialsGridType.miscellaneousPendingMoleculeDevelopment ||
              credential.gridType === CredentialsGridType.certificationsOtherCertifications ||
              credential.gridType === CredentialsGridType.educationAndOnlineForms
            ) {
              setTypeDropDownValues(
                getLookups(
                  lookups,
                  credential?.requirementTypeID === 222 ? 'ReqTypeID' : 'TypeID',
                  credential?.type?.value,
                ),
              );
            } else {
              setTypeDropDownValues(null);
            }
          }
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          setModel({});
          setColumns(null);
          setRows(null);
          setTypeDropDownValues(null);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credential]);

  useEffect(() => {
    if (props.isCredentialCreated) {
      setDidEdit(false);
      setIsCredCreated(false);
    }
  }, [props.isCredentialCreated]);

  const credentialsToActivate = requirementTypeID => {
    if (requirementTypeID === 218 || requirementTypeID === 372) {
      setTypePreSelected(true);
    }
  };

  const Loading = () => (
    <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
      <CircularProgress />
    </div>
  );

  const onChange = (key: keyof ICredentials, value) => {
    const data = valueModified({ ...model }, key, value);
    if (deepEqualSoftValue(originalModel, data)) setDidEdit(false);
    else setDidEdit(true);
    setModel(data);
  };

  const valueModified = (data, key: keyof ICredentials, value) => {
    data[key] = value;
    const updateBooleanFieldsTrackEvent = 'Add Credential:Credential Verified';
    switch (key) {
      case 'verifiedID':
        // change date and name here
        switch (credential.gridType) {
          case CredentialsGridType.licensure:
          case CredentialsGridType.certificationsOtherCertifications:
          case CredentialsGridType.certifications:
          case CredentialsGridType.certificationsLifeSupportCertifications:
          case CredentialsGridType.humanResourcesBackgroundChecks:
          case CredentialsGridType.humanResourcesIdentifications:
            data = updateBooleanFields(data, value, 'verifiedByName', 'dateVerified', 'verifiedByID');
            if (value) trackEvent({ type: 'click', name: updateBooleanFieldsTrackEvent });
            break;
          default:
            break;
        }
        break;
      case 'onFileID':
        switch (credential.gridType) {
          case CredentialsGridType.licensure:
          case CredentialsGridType.certifications:
          case CredentialsGridType.humanResourcesBackgroundChecks:
          case CredentialsGridType.humanResourcesIdentifications:
            data = updateBooleanFields(data, value, 'onFileBy', 'onFileDate', 'onFileByID');
            break;
          default:
            break;
        }
        break;
      case 'paperlessID':
        switch (credential.gridType) {
          case CredentialsGridType.licensure:
            data = updateBooleanFields(data, value, 'paperlessByName', 'paperlessDate', 'paperlessBy');
            break;
          default:
            break;
        }
        break;
      case 'expirationDate':
        if (value !== null && value !== undefined && value !== '') {
          if (
            moment(value).isBefore(moment(`${moment().format('yyyy-MM-DD')}${moment().format('THH:mm:ss')}`)) &&
            moment(moment(value).format('yyyy-MM-DD')).isBefore(findMyComparingCredentialDate(data, 'dateCompleted'))
          ) {
            showAlert(AddAlertType.expirationDateLesserDateCompletedSubmission);
          } else if (
            moment(value).isBefore(moment(`${moment().format('yyyy-MM-DD')}${moment().format('THH:mm:ss')}`))
          ) {
            showAlert(AddAlertType.expirationDate);
          } else if (
            moment(moment(value).format('yyyy-MM-DD')).isBefore(findMyComparingCredentialDate(data, 'dateCompleted'))
          ) {
            showAlert(AddAlertType.expirationDateLesserDateCompleted);
          }
        }
        break;
      case 'dateCompleted':
        if (
          value !== null &&
          value !== undefined &&
          value !== '' &&
          moment(moment(value).format('yyyy-MM-DD')).isAfter(findMyComparingCredentialDate(data, 'expirationDate'))
        ) {
          showAlert(AddAlertType.dateCompleted);
        }
        break;
      default:
        break;
    }

    // set boolean back to null if unchecked
    if (columns.find(column => column.title === key)?.control?.type === gridControlType.checkbox && value === false) {
      data[key] = null;
    }
    return data;
  };

  const updateBooleanFields = (data, boolValue, nameField, dateField, IDField) => {
    if (boolValue) {
      data[nameField] = `${user.userInfo?.firstName} ${user.userInfo?.lastName}`;
      data[dateField] = moment().format('yyyy-MM-DDTHH:mm:ss');
      data[IDField] = null;
    } else {
      data[nameField] = null;
      data[dateField] = null;
      data[IDField] = null;
    }
    return data;
  };

  const _logCredential = (isSaveActive = false) => {
    const m = _.cloneDeep(model);
    columns.forEach(column => {
      const newVal = formatEntry(column.title, m[column.title], column, null, {
        first: user.userInfo?.firstName,
        last: user.userInfo?.lastName,
      });
      m[column.title] = newVal;
    });
    logCredential(m, isSaveActive);
  };

  const showAlert = useCallback(
    (alertType: AddAlertType) => {
      let title = '';
      let content = '';
      let positiveButton = '';
      let negativeButton = '';
      switch (alertType) {
        case AddAlertType.sessionTimeout:
          title = t('search.grid.edit.dialogTitleSession');
          content = didEdit
            ? t('search.grid.add.dialogContentSession')
            : t('search.grid.add.dialogContentWithoutEditSession');
          positiveButton = t('search.grid.add.submitTextSession');
          negativeButton = didEdit
            ? t('search.grid.edit.cancelTextSession')
            : t('search.grid.edit.cancelTextWithoutEditSession');
          break;
        case AddAlertType.cancel:
          title = t('cancelModal.undoConfirmation');
          content = t('cancelModal.saveConfirmation');
          negativeButton = t('cancelModal.confirmation.cancel');
          positiveButton = t('cancelModal.confirmation.yes');
          break;
        case AddAlertType.back:
          title = t('cancelModal.undoConfirmation');
          content = t('cancelModal.saveConfirmation');
          negativeButton = t('cancelModal.confirmation.cancel');
          positiveButton = t('cancelModal.confirmation.yes');
          break;
        case AddAlertType.expirationDate:
          title = t('candidate.credentials.removeRecordExpirationTitle');
          content = t('candidate.credentials.removeRecordExpirationContent');
          positiveButton = t('search.grid.edit.genericAlertOkButton');
          break;
        case AddAlertType.expirationDateLesserDateCompleted:
          title = t('candidate.credentials.expirationLesserDateCompletedWarningTitle');
          content = t('candidate.credentials.expirationLesserDateCompletedWarningContent');
          positiveButton = t('search.grid.edit.genericAlertOkButton');
          break;
        case AddAlertType.dateCompleted:
          title = t('candidate.credentials.dateCompletedWarningTitle');
          content = t('candidate.credentials.dateCompletedWarningContent');
          positiveButton = t('search.grid.edit.genericAlertOkButton');
          break;
        case AddAlertType.expirationDateLesserDateCompletedSubmission:
          title = t('candidate.credentials.removeRecordExpirationLesserDateCompletedWarningTitle');
          content = t('candidate.credentials.removeRecordExpirationLesserDateCompletedWarningContent');
          positiveButton = t('search.grid.edit.genericAlertOkButton');

          break;
        case AddAlertType.typeChangeOnLog:
          title = t('search.grid.edit.dialogTitleSave');
          content = t('search.grid.edit.dialogContentSave');
          negativeButton = t('search.grid.edit.cancelTextSave');
          positiveButton = t('search.grid.edit.submitTextSave');
          break;

        default:
          break;
      }
      setAlertData({
        type: alertType,
        title: title,
        content: content,
        positiveButton: positiveButton,
        negativeButton: negativeButton,
      });
      setAlertOpen(true);
    },
    [didEdit, t],
  );

  const handleAlertNegativeAction = () => {
    switch (alertData.type) {
      case AddAlertType.sessionTimeout:
        handleClose();
        break;
      case AddAlertType.cancel:
        handleClose();
        break;
      case AddAlertType.back:
        handleBack();

        break;
      case AddAlertType.expirationDate:
        break;
      case AddAlertType.typeChangeOnLog:
        typeChanged(tempType);
        break;

      default:
        break;
    }
    setAlertOpen(false);
  };

  const handleAlertPositiveAction = () => {
    switch (alertData.type) {
      case AddAlertType.sessionTimeout:
        setSessionTimer(!sessionTimer);
        // close dialog
        break;
      case AddAlertType.cancel:
        // submit
        _logCredential();
        break;
      case AddAlertType.back:
        // submit
        _logCredential();
        break;
      case AddAlertType.expirationDate:
        // close dialog
        break;
      case AddAlertType.expirationDateLesserDateCompletedSubmission:
        //close dialog
        break;
      case AddAlertType.typeChangeOnLog:
        _logCredential();
        break;
      default:
        break;
    }
    setAlertOpen(false);
  };

  useEffect(() => {
    let interval;
    if (open) {
      interval = setTimeout(() => {
        showAlert(AddAlertType.sessionTimeout);
      }, 600000);
    }
    return () => clearTimeout(interval);
  }, [open, sessionTimer, showAlert]);

  return (
    <React.Fragment>
      <GenericDialog
        open={open}
        maxWidth="md"
        fullWidth
        variant="blue"
        dialogTitleProps={{
          text: isAddLicense
            ? addUnverifiedCredType === 'Licenses'
              ? t('search.grid.add.logLicenseText')
              : t('search.grid.add.logCertificationText')
            : t('search.grid.add.dialogTitle'),
          closeButton: isAddLicense ? true : undefined,
        }}
        onClose={() => (didEdit ? showAlert(AddAlertType.cancel) : handleClose())}
        dialogActions={[
          {
            text: t('search.grid.add.cancelButtonText'),
            variant: 'contained',
            color: 'tertiary',
            onClick: () => {
              if (didEdit) showAlert(AddAlertType.cancel);
              else handleClose();
            },
          },
          {
            text: t('search.grid.add.backButtonText'),
            variant: 'contained',
            color: 'primary',
            onClick: () => {
              if (didEdit) showAlert(AddAlertType.back);
              else handleBack();
            },
          },
          {
            text: isAddLicense ? t('SAVE') : t('search.grid.add.addButtonText'),
            variant: 'contained',
            color: 'primary',
            onClick: () => _logCredential(true),
            disabled: (!didEdit && !typePreSelected) || readOnly,
            tooltipProps: readOnly
              ? {
                  tooltipContent: t('global.readOnlyTooltip'),
                }
              : undefined,
          },
        ]}
      >
        <React.Fragment>
          {duplicateExist === false && (
            <Alert severity="warning" sx={{ marginTop: '12px' }}>
              <div>
                {`${getCredentialOrTypeName(credential, t('search.grid.add.noRecordExists'), null, null, true)}`} <br />{' '}
                {t('search.grid.add.warningNewAdd')}
              </div>
            </Alert>
          )}
          {loading ? (
            <Loading />
          ) : (
            <AddCredentialBuilder
              model={model}
              credential={credential}
              typeChanged={event => {
                if (didEdit) {
                  showAlert(AddAlertType.typeChangeOnLog);
                  setTempType(event);
                } else typeChanged(event);
              }}
              onChange={onChange}
              columns={columns}
              rows={rows}
              typeDropDownValues={typeDropDownValues}
              submitFailedAttachments={props.submitFailedAttachments}
            />
          )}
        </React.Fragment>
      </GenericDialog>
      <GenericDialog
        open={alertOpen && !isCredCreated}
        disableEscapeKeyDown
        maxWidth="sm"
        dialogTitleProps={{
          text: alertData.title,
          style: { fontSize: '20px' },
        }}
        dialogContentProps={{
          style: { fontSize: '14px' },
        }}
        dialogActions={[
          {
            text: alertData.negativeButton,
            variant: 'text',
            color: 'tertiary',
            onClick: () => setAlertOpen(false),
            hidden:
              (alertData.negativeButton === '' &&
                alertData.type === AddAlertType.expirationDateLesserDateCompletedSubmission) ||
              alertData.type === AddAlertType.expirationDate ||
              alertData.type === AddAlertType.dateCompleted ||
              alertData.type === AddAlertType.expirationDateLesserDateCompleted,
          },
          {
            text: alertData.positiveButton,
            variant: 'contained',
            color: 'primary',
            onClick: handleAlertNegativeAction,
            disabled:
              readOnly &&
              (alertData.type === AddAlertType.cancel ||
                alertData.type === AddAlertType.back ||
                alertData.type === AddAlertType.typeChangeOnLog),
            tooltipProps:
              readOnly &&
              (alertData.type === AddAlertType.cancel ||
                alertData.type === AddAlertType.back ||
                alertData.type === AddAlertType.typeChangeOnLog)
                ? {
                    tooltipContent: t('global.readOnlyTooltip'),
                  }
                : undefined,
          },
        ]}
      >
        {alertData.content}
      </GenericDialog>
    </React.Fragment>
  );
};
