import { FilterCompTypes } from 'app/ComponentLibrary/Filter/utils';

export enum ExpiringDocumentsFilterName {
  placementId = 'placementId',
  candidateName = 'candidateName',
  credential = 'credential',
  daysUntilExpiration = 'daysUntilExpiration',
  facilityName = 'facilityName',
  credentialCategory = 'credentialCategory',
  credentialGroup = 'credentialGroup',
  candidateId = 'candidateId',
  expirationDate = 'expirationDate',
  tier = 'tier',
}

export const defaultFilterValues = {
  [ExpiringDocumentsFilterName.placementId]: [],
  [ExpiringDocumentsFilterName.candidateName]: [],
  [ExpiringDocumentsFilterName.credential]: [],
  [ExpiringDocumentsFilterName.daysUntilExpiration]: null,
  [ExpiringDocumentsFilterName.expirationDate]: null,

  [ExpiringDocumentsFilterName.facilityName]: [],
  [ExpiringDocumentsFilterName.credentialCategory]: [],
  [ExpiringDocumentsFilterName.credentialGroup]: [],
  [ExpiringDocumentsFilterName.candidateId]: [],
  [ExpiringDocumentsFilterName.tier]: [],
};

export const getFilterSpecs = (filterSpecs, t, styleClasses) => [
  {
    filterName: t('Placement ID (PID)'),
    name: ExpiringDocumentsFilterName.placementId,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.placementIDOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.placementIdField,
  },
  {
    filterName: t('Candidate Name'),
    name: ExpiringDocumentsFilterName.candidateName,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.candidateNameOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.candidateNameField,
  },
  {
    filterName: t('Credential(s)'),
    name: ExpiringDocumentsFilterName.credential,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.credentialsOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.credentialField,
  },
  // {
  //   filterName: t('Tier'),
  //   name: ExpiringDocumentsFilterName.tier,
  //   type: FilterCompTypes.SELECT,
  //   isMultiSelect: true,
  //   options: filterSpecs.tierOpts,
  //   placeholder: 'Select',
  //   selectAllValue: 'All',
  //   version2: true,
  //   selectFieldStyle: styleClasses.credentialField,
  // },
  {
    filterName: t('Days Until Expiration'),
    name: ExpiringDocumentsFilterName.daysUntilExpiration,
    placeholder: 'Days Until Expiring',
    type: FilterCompTypes.TEXTFIELDNUMBER,
  },
  {
    filterName: t('Expiration Date'),
    name: ExpiringDocumentsFilterName.expirationDate,
    placeholder: 'Expiration Date',
    type: FilterCompTypes.DATERANGEV2,
    variant: 'outlined',
    clickAway: true,
  },
  {
    filterName: t('Client Name'),
    name: ExpiringDocumentsFilterName.facilityName,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.clientNameOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.clientNameField,
  },
  {
    filterName: t('Credential(s) Category'),
    name: ExpiringDocumentsFilterName.credentialCategory,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.credentialCategoryOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.credentialCategoryField,
  },
  {
    filterName: t('Credential(s) Group'),
    name: ExpiringDocumentsFilterName.credentialGroup,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.credentialGroupOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.credentialGroupField,
  },
  {
    filterName: t('Candidate (CID)'),
    name: ExpiringDocumentsFilterName.candidateId,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.candidateIdOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.candidateIdField,
  },
];
