import { createAction } from '@reduxjs/toolkit';
import { coverageSlice } from './slice';
import { IDenyCoverageRequest, IRequestCoverageProp } from 'app/models/UserCoverage/UserCoverage';

const saveCoverageRequestAction = createAction<any>('SAVE_COVERAGE_REQUEST');
const getCoverageRequestList = createAction<IRequestCoverageProp>('GET_COVERAGE_REQUEST_LIST');
const updateCoverageRequestAction = createAction<any>('UPDATE_COVERAGE_REQUEST');
const getActiveCoverageProvidedByUser = createAction('GET_ACTIVE_COVERAGE_PROVIDED_BY_USER');
const denyCoverageRequestAction = createAction<IDenyCoverageRequest>('DENY_COVERAGE_REQUEST');
const fetchCoveredInbox = createAction<IRequestCoverageProp>('FETCH_COVERED_INBOXES')


const { actions } = coverageSlice;

export const coverageActions = {
  ...actions,
  saveCoverageRequestAction,
  updateCoverageRequestAction,
  getCoverageRequestList,
  getActiveCoverageProvidedByUser,
  denyCoverageRequestAction,
  fetchCoveredInbox
};
