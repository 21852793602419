import { createAction } from '@reduxjs/toolkit';
import { IContractDetailsRequest, IBillRates } from './types';

const business_initiateRateCard = createAction<IContractDetailsRequest & { uniqueShifts }>(
  'BUSINESS_INITIATE_RATE_CARD',
);
const business_initiateEditRateCard = createAction<{ orderId: number; facilityId: number; uniqueShifts }>(
  'BUSINESS_INITIATE_EDIT_RATE_CARD',
);
const business_setDefaultTravelBillRate = createAction('BUSINESS_SET_DEFAULT_TRAVEL_RATE');
const business_setDefaultGwwMileageRate = createAction('BUSINESS_SET_DEFAULT_GWW_MILEAGE_RATE');
const business_updateBillRates = createAction<{ billRates: IBillRates[] }>('BUSINESS_UPDATE_BILLRATES');
const business_updateSavedBillRates = createAction<{ billRates: IBillRates[] }>('BUSINESS_UPDATE_SAVED_BILLRATES');

const business_updateTableRows = createAction<{ tableRows: any[] }>('BUSINESS_UPDATE_TABLEROWS');
const business_updateEffectiveDates = createAction<{ startDate; endDate }>('BUSINESS_UPDATE_EFFECTIVE_DATES');
const business_resetDocuments = createAction('BUSINESS_RESET_DOC_CONTAINER');

const business_deriveShiftTableDetails = createAction('DERIVE_BILLRATES_TABLE');
const business_deriveRateSelections = createAction<{ isFlatRate: boolean; billRates: any[] }>('DERIVE_RATE_SELECTIONS');

export const businessActions = {
  business_initiateRateCard,
  business_initiateEditRateCard,
  business_updateBillRates,
  business_updateTableRows,
  business_setDefaultTravelBillRate,
  business_setDefaultGwwMileageRate,
  business_updateEffectiveDates,
  business_resetDocuments,
  business_deriveShiftTableDetails,
  business_deriveRateSelections,
  business_updateSavedBillRates,
};
