import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import React from 'react';
import { AttachmentAction, CredentialAttachmentToolTip } from '../../../Attachments/AttachmentHoverView';
import { useDispatch, useSelector } from 'react-redux';
import { breadcrumbActions } from 'app/components/Common/BreadcrumbNavigation/BreadcrumbNavigation.redux';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { findCredentialByRequirementID } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/CredentialsTab/CredentialsHelpers';
import {
  candidateDetailsSelection,
  selectAllPassportSkillChecklist,
} from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { isPositiveNumber } from 'app/helpers/componentHelpers';
import { getSkillChecklistById } from 'app/services/CandidateServices/CandidateServices';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { useHistory } from 'react-router-dom';
import { IAttachments } from 'app/models/Credentialing/Credentials';

export const DocumentGridCell = (props: { param: GridRenderCellParams; setClearChildMessages; setShowAttachmentPreview, disableEdit?: boolean, isSkillsChecklist?: boolean }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const passportSkillChecklists = useSelector(selectAllPassportSkillChecklist);
  const candidateDetails = useSelector(candidateDetailsSelection);

  const onAttachmentAction = async (
    action: AttachmentAction,
    requirementID: number,
    requirementTypeID: number,
    travelerId: number,
    brandId: number,
  ) => {
    dispatch(candidateDetailActions.setCandidateSkillChecklistFromPassport(undefined));
    getSkillChecklistAndAttachments(requirementID, travelerId, action, requirementTypeID, brandId);
  };

  const getNavData = (lastBreadcrumb: string, travelerId: number, brandId: number) => ({
    backButtonURL: null,
    breadcrumbHierarchy: [
      {
        title: 'Summary',
        order: 1,
        returnURL: `candidate/${travelerId}/${brandId}`,
        clickable: true,
        onClick: e => {
          history.push({ pathname: `/candidate/${travelerId}/${brandId}`, state: { navigateBack: true } })
        }
      },
      {
        title: 'Credential',
        order: 2,
        returnURL: `candidate/${travelerId}/${brandId}?tab=2`,
        clickable: true,
        onClick: e => {
          history.goBack();
        }
      },
      {
        title: lastBreadcrumb,
        order: 3,
        returnURL: '',
        clickable: false,
      },
    ],
    isOwnPageBreadcrumb: true,
  });

  const navigate = (
    name: string,
    travelerId: number,
    requirementID: number,
    requirementTypeID: number,
    brandId: number,
  ) => {
    if (travelerId) {
      const orderNavData = getNavData(name, candidateDetails?.travelerId, candidateDetails?.brandId);
      dispatch(breadcrumbActions.setNavigationData(orderNavData));
    } else {
      dispatch(breadcrumbActions.setNavigationData({ backButtonURL: null, breadcrumbHierarchy: null }));
    }
    history.push({
      pathname: `/credentialing/${travelerId}/${brandId}/attachements/${requirementID}/${
        requirementTypeID
      }${`?at=0`}`,
      state: { navigateBack: true },
    });
  };

  const getCredentialName = (abbreviation, name) => `${abbreviation} - ${name?.replace(/\\\//g, '/')}` || 'Document';

  /**
   * Method to find skill checklist id from passport skill checklist by requirement id and open attachments section
   * @param requirementID
   */
  const getSkillChecklistAndAttachments = async (
    requirementID: number,
    travelerId,
    action,
    requirementTypeID,
    brandId,
  ) => {
    const currentSkillChecklist = passportSkillChecklists?.skillsChecklists.find(
      item => item.requirementId === requirementID,
    );
    if (isPositiveNumber(candidateDetails?.travelerId)) {
      if (currentSkillChecklist?.id) {
        await getSkillChecklistById(travelerId, currentSkillChecklist?.id).then(response => {
          if (response?.data)
            dispatch(
              candidateDetailActions.setCandidateSkillChecklistFromPassport(response?.data?.skillsChecklists[0]),
            );
            const skillsChecklistAttachments = [{
              isSkillChecklist: true,
              checkBox: false,
              comments: null,
              document: {
                uri: '',
                fileType: 'application/pdf',
                fileData: '',
              },
              lastUpdated: null,
              lastUpdatedBy: null,
              sortOrder: 0,
              status: 'Not complete',
              title: null,
              travelerRequirementImageID: 3,
            }];
            const credential = findCredentialByRequirementID(
              currentSkillChecklist.requirementId, 
              candidateDetails.credentials
            );
            dispatch(candidateDetailActions.setSelPreviewAttachments(skillsChecklistAttachments as IAttachments[]));
            dispatch(candidateDetailActions.setHistoryAttachments(skillsChecklistAttachments as IAttachments[]));
            navigate(
              getCredentialName(credential?.credential?.requirementType, credential?.credential?.credentialName), 
              travelerId, 
              requirementID, 
              requirementTypeID, 
              brandId
            );
        });
      } else {
        props.setShowAttachmentPreview({
          open: true,
          action: action,
          requirementID: requirementID,
          requirementTypeID: requirementTypeID,
          travelerId: travelerId,
          brandId: brandId,
        });
      }
      props.setClearChildMessages(true);
      dispatch(candidateDetailActions.setIsPreview(false));
    }
  };

  const openSkillsChecklistDocument = () => {
    onAttachmentAction(
      AttachmentAction.view, 
      props.param.row.rowID, 
      0,
      candidateDetails?.travelerId, 
      candidateDetails?.brandId
    );
  }

  return props.isSkillsChecklist ? 
    (passportSkillChecklists?.skillsChecklists.find(item => item.requirementId === props.param.row.rowID) ? (
      <span onClick={() => openSkillsChecklistDocument()}>
        <DescriptionOutlinedIcon style={{ color: '#333' }} />
      </span>
    ) : (
      <span>
        <DescriptionOutlinedIcon style={{ color: '#b7b7b7' }} />
      </span>
    )) : (
    <CredentialAttachmentToolTip
      onSelect={onAttachmentAction}
      requirementID={props.param.row.rowID}
      requirementTypeID={0}
      travelerId={0}
      brandId={0}
      disableEdit={props.disableEdit}
      useNewIcon={true}
    />
  );
};
