import Excel from 'app/assets/images/Notification/excel.png';
import Jpg from 'app/assets/images/Notification/jpg.png';
import Pdf from 'app/assets/images/Notification/pdf.png';
import Png from 'app/assets/images/Notification/png.png';
import Doc from 'app/assets/images/Notification/word.png';
import Outlook from 'app/assets/images/Notification/outlookFileIcon.png';
import React from 'react';
import styledComponent from 'styled-components';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';

export const getAttachmentIcon = customSwitch({
  xlsx: Excel,
  xls: Excel,
  xlsm: Excel,
  csv: Excel,
  jpg: Jpg,
  jpeg: Jpg,
  pdf: Pdf,
  png: Png,
  doc: Doc,
  docx: Doc,
  msg: Outlook,
})(undefined);

const AttachmentName = styledComponent.span`
  text-align: left;
  font-size: 12px;
  margin-left: 2px;
`;

const AttachmentBlock = styledComponent.div`
  display: block;
`;

export const getFileName = (url: string) => {
  var fileName = url ?? '';
  if (fileName.indexOf('?') > 0 && fileName.indexOf('/') > 0) {
    const decodedURI = decodeURIComponent(url);
    const URIWithoutToken = decodedURI.substring(0, decodedURI.lastIndexOf('?'));
    fileName = URIWithoutToken.substring(URIWithoutToken.lastIndexOf('/') + 1);
  }
  if (fileName.indexOf('/') > 0) {
    fileName = fileName.substring(fileName.lastIndexOf('/') + 1);
  }
  return fileName;
};

export function getAttachmentFileExtension(url: string) {
  var fileName = getFileName(url);

  return (
    fileName && (
      <AttachmentBlock>
        <img src={getAttachmentIcon(fileName && fileName?.split('.')?.pop()?.toLowerCase())} alt={`FileIcon`} />
        <AttachmentName>{fileName}</AttachmentName>
      </AttachmentBlock>
    )
  );
}
