import { useTranslation } from 'react-i18next';
import {
  ContentContainer,
  CountDiv,
  FacilityGrid,
  HeadDiv,
  JobGrid,
  TextGrid,
  useEducationalHistoryStyles,
} from './utils';
import { TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import { Divider, Grid, Typography } from 'amn-ui-core';
import React from 'react';

export const TimelineSection = props => {
  const { t } = useTranslation();
  const { classes } = useEducationalHistoryStyles();

  return (
    <TimelineItem classes={{ missingOppositeContent: classes.removeOppoContent }}>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot variant="outlined" classes={{ root: classes.dotRoot }} />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <ContentContainer container direction="column" justifyContent="space-around">
          <Grid item container direction="column">
            <Grid item container direction="row" justifyContent="space-between">
              <JobGrid item container direction="column">
                <FacilityGrid item>{props.facilityName}</FacilityGrid>
              </JobGrid>
              <TextGrid item>{props.dateRange}</TextGrid>
            </Grid>
            <Grid item container direction="row" justifyContent="space-between">
              <TextGrid item>{props.discipline}</TextGrid>
              <TextGrid item>
                <HeadDiv>{t('candidate.employmentHistoryCard.reference') + ': '}</HeadDiv>
                <Typography color="status.available" component="strong">
                  {t('Approved')}
                </Typography>
                <CountDiv>{` (${props.approvedReferenceCount})`}</CountDiv>
              </TextGrid>
            </Grid>
            <Grid item container direction="column" justifyContent="space-between">
              <TextGrid item>{props.specialty}</TextGrid>
              <TextGrid item>{props.place}</TextGrid>
              <TextGrid item>{t('candidate.employmentHistoryCard.yearsOfExperience') + props.experience}</TextGrid>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
        </ContentContainer>
      </TimelineContent>
    </TimelineItem>
  );
};
