import React, { useEffect } from 'react';
import { DefaultHomeSwitch } from '../AppHome/DefaultHomeSwitch';
import { makeStyles } from 'tss-react/mui';
import { HomePage } from 'app/components/AppHome/AppHome';
import { PlacementStatusWrapper } from './PlacementStatus/PlacementStatusWrapper';
import { ExpiringCredentialWrapper } from './ExpiringCredentials/ExpiringCredentialWrapper';
import { PlacementRemindersWrapper } from './PlacementReminders/PlacementRemindersWrapper';
import { pageNames } from 'app/constants/PageNames';
import useDocumentTitle from 'utils/customHooks/useDocumentTitle';
import { trackPageView } from 'app-insights/appInsightsTracking';
import { GenericTabs } from '@AMIEWEB/Common/Tabs/GenericTabs';

const useStyles = makeStyles()({
  root: {
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: '#F5F5F5',
    flexWrap: 'nowrap',
  },
});

const CredentialingWorkDesk = () => {
  const { classes } = useStyles();
  useDocumentTitle({ title: pageNames.workdeskCredentialing });

  useEffect(() => {
    trackPageView({ name: pageNames.workdeskCredentialing });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs = React.useMemo(
    () => [
      { label: 'Placement Statuses', component: <PlacementStatusWrapper /> },
      { label: 'Placement Reminders', component: <PlacementRemindersWrapper /> },
      { label: 'Expiring Credentials', component: <ExpiringCredentialWrapper /> },
    ],
    [],
  );

  return (
    <div className={classes.root}>
      <GenericTabs
        tabs={tabs}
        modifyUrl
        dense
        addOnComponent={<DefaultHomeSwitch page={HomePage.CREDENTIALING_WORK_DESK} />}
      />
    </div>
  );
};

export default CredentialingWorkDesk;
