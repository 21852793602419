import React, { useState } from 'react';
import { Button, ButtonGroup, Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { taskDetailsActions } from '../store/Tasks.redux';
import { useDispatch } from 'react-redux';

const PlacementContainerStyles = makeStyles()((theme: Theme) => ({
  buttonTitle: {
    textAlign: 'left',
    font: 'normal normal normal 13px/20px Roboto',
    color: '#555555',
    opacity: '1',
    width: '100%',
    letterSpacing: '0',
    textTransform: 'none',
  },
  buttonTitleOnClick: {
    textAlign: 'left',
    font: 'normal normal normal 13px/20px Roboto',
    color: '#FFFFFF',
    opacity: '1',
    width: '100%',
    fontWeight: 'inherit',
    letterSpacing: '0',
    textTransform: 'none',
  },
  internalButton: {
    width: '60px',
    height: '36px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    opacity: '1',
    padding: '10px 5px',
  },
  internalButtonOnClick: {
    width: '60px',
    height: '36px',
    background: `${theme.palette.components.button.secondary.backgroundColor} 0% 0% no-repeat padding-box`,
    opacity: '1',
    padding: '10px 5px',
    '&:hover': {
      background: theme.palette.components.button.secondary.backgroundColor,
    },
  },
  externalButton: {
    width: '62px',
    height: '36px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    opacity: '1',
    padding: '10px 5px',
  },
  externalButtonOnClick: {
    width: '62px',
    height: '36px',
    background: `${theme.palette.components.button.secondary.backgroundColor} 0% 0% no-repeat padding-box`,
    opacity: '1',
    padding: '10px 5px',
    '&:hover': {
      background: theme.palette.components.button.secondary.backgroundColor,
    },
  },
  //
  myTaskButton: {
    width: '69px',
    height: '36px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '4px 0px 0px 4px',
    opacity: '1',
    padding: '10px 5px',
  },
  myTaskButtonOnClick: {
    width: '69px',
    height: '36px',
    background: `${theme.palette.components.button.secondary.backgroundColor} 0% 0% no-repeat padding-box`,
    borderRadius: '4px 0px 0px 4px',
    opacity: '1',
    padding: '10px 5px',
    '&:hover': {
      background:theme.palette.components.button.secondary.backgroundColor,
    },
  },
}));

export const PlacementButtonGroup = props => {
  const MyTask = 'MyTask';
  const Internal = 'Internal';
  const External = 'External';
  const { setShowMyTasks, setExternal } = props;
  const [currentTab, setCurrentTab] = useState(MyTask);
  const { classes, cx } = PlacementContainerStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const changeStyle = tab => {
    setCurrentTab(tab);
    if (tab === External) {
      setExternal(true);
      dispatch(taskDetailsActions.setIsExternalTask(true));
    } else {
      setExternal(false);
      dispatch(taskDetailsActions.setIsExternalTask(false));
    }
    if (tab === Internal) {
      setShowMyTasks(false);
    } else if (tab === MyTask) {
      setShowMyTasks(true);
    }
  };

  return (
    <ButtonGroup>
      <Button
        onClick={() => {
          return changeStyle(MyTask);
        }}
        className={cx({
          [classes.myTaskButtonOnClick]: currentTab === MyTask,
          [classes.myTaskButton]: currentTab !== MyTask,
        })}
      >
        <div
          className={cx({
            [classes.buttonTitleOnClick]: currentTab === MyTask,
            [classes.buttonTitle]: currentTab !== MyTask,
          })}
        >
          {t('notification.taskSidePanel.displayMyTasks')}
        </div>
      </Button>
      <Button
        onClick={() => {
          return changeStyle(Internal);
        }}
        className={cx({
          [classes.internalButtonOnClick]: currentTab === Internal,
          [classes.internalButton]: currentTab !== Internal,
        })}
      >
        <div
          className={cx({
            [classes.buttonTitleOnClick]: currentTab === Internal,
            [classes.buttonTitle]: currentTab !== Internal,
          })}
        >
          {t('notification.taskSidePanel.displayInternal')}
        </div>
      </Button>
      <Button
        onClick={() => {
          return changeStyle(External);
        }}
        className={cx({
          [classes.externalButtonOnClick]: currentTab === External,
          [classes.externalButton]: currentTab !== External,
        })}
      >
        <div
          className={cx({
            [classes.buttonTitleOnClick]: currentTab === External,
            [classes.buttonTitle]: currentTab !== External,
          })}
        >
          {t('notification.taskSidePanel.displayExternal')}
        </div>
      </Button>
      
    </ButtonGroup>
  );
};
