import React from 'react';
import { GraphicStepper } from 'app/ComponentLibrary/GraphicStepper/GraphicStepper';
import { Grid, useMediaQuery } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';
import { pipelineChoiceActions } from 'app/components/Common/WorkDesk/PipelineChoice/pipelineChoice.redux';
import { ExpiringCredentialTiers } from '../ExpiringCredentials/DataVisualization/ExpiringCredentialTiers';

const useStyles = makeStyles()({
  container: {
    flexWrap: 'nowrap',
    background: '#FCFCFD',
    border: '1px solid #F5F5F5',
    padding: '1%',
  },
  placementTotal: {
    padding: '0 2% 0 1%',
    maxWidth: 300,
  },
});

export const WorkDeskPipeline = props => {
  const { pipelineTotal, data, selection, noValuePipelineText, gridSelectionKey, boxStepper = false } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const matches1100px = useMediaQuery('(max-width:1023px)');

  const handleClick = (category, subcategory, value, event) => {
    selection.some(item => item.category === category && item.subcategory === subcategory)
      ? dispatch(
          pipelineChoiceActions.setPipelineChoice({
            key: gridSelectionKey,
            selection: [...selection].filter(item => !(item.category === category && item.subcategory === subcategory)),
          }),
        )
      : event.ctrlKey
      ? // checks if user trying to combine the category to its same subcategories
        selection.some(item => item.category === category)
        ? subcategory === null
          ? //click on category ==> remove all subcategories within that category
            dispatch(
              pipelineChoiceActions.setPipelineChoice({
                key: gridSelectionKey,
                selection: [...selection.filter(item => item.category !== category), { category, subcategory, value }],
              }),
            )
          : //click on subcategory ==> remove the category
            dispatch(
              pipelineChoiceActions.setPipelineChoice({
                key: gridSelectionKey,
                selection: [
                  ...selection.filter(item => !(item.category === category && item.subcategory === null)),
                  { category, subcategory, value },
                ],
              }),
            )
        : dispatch(
            pipelineChoiceActions.setPipelineChoice({
              key: gridSelectionKey,
              selection: [...selection, { category, subcategory, value }],
            }),
          )
      : dispatch(
          pipelineChoiceActions.setPipelineChoice({
            key: gridSelectionKey,
            selection: [{ category, subcategory, value }],
          }),
        );
  };

  return (
    <React.Fragment>
      {!boxStepper && (
        <Grid item container direction="row" classes={{ container: classes.container }}>
          {!matches1100px && (
            <Grid item container alignItems="center" classes={{ root: classes.placementTotal }}>
              {pipelineTotal}
            </Grid>
          )}
          <Grid item xs={12}>
            <GraphicStepper
              items={data}
              handleItemClick={handleClick}
              selected={selection}
              noValueText={noValuePipelineText}
              isCredentialing
            />
          </Grid>
        </Grid>
      )}
      {boxStepper && <ExpiringCredentialTiers tiers={data} handleItemClick={handleClick} selected={selection} />}
    </React.Fragment>
  );
};
