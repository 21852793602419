import React from 'react';
import { ICustomHeader, gridControlType } from 'app/models/Grid';
import NoteIcon from 'app/assets/images/Notes.png';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HistoryIcon from '@mui/icons-material/History';
import { CredentialingLookup } from 'app/models/Credentialing/CredentialingLookups';
import {
  getDate,
  getCredentialName,
  getDateNoFormat,
  getDateText,
  getCredentialNameNoFormat,
  detailColumnsAndRows,
  getMetadataForReqID,
  getCredentialNameBold,
} from '../tableHelpers';
import { ICredentials } from 'app/models/Credentialing/Credentials';
import { groupBy, nestedChildren } from 'app/helpers/arrayHelpers';
import { AttachmentAction, CredentialAttachmentToolTip } from '../../Attachments/AttachmentHoverView';
import VersionDetailsHoverView from '../VersionDetailsHoverView';
import { childSorting } from '../HumanResources/HumanResourcesGroupingHelper';
import { cloneDeep } from 'lodash';

const getMainTableColumns = (
  sortable: boolean = true,
  viewingDuplicates: boolean = false,
  historical: boolean = false,
) => {
  const headers: ICustomHeader[] = [
    {
      title: 'credentialName',
      value: 'Credential Name',
      sortable: sortable,
      sortByField: 'credentialNameNoFormat',
    },
    {
      title: 'dateCompleted',
      value: 'Date Completed',
      sortable: sortable,
      sortByField: 'dateCompletedNoFormat',
      defaultSort: !viewingDuplicates,
      control: {
        type: gridControlType.date,
      },
    },
    {
      title: 'lastUpdate',
      value: 'Updated Date',
      sortable: sortable,
      defaultSort: viewingDuplicates,
      sortByField: 'lastUpdateNoFormat',
      control: {
        type: gridControlType.label,
      },
    },
    {
      title: 'versioning',
      value: 'Versions',
      icon: true,
      versioning: !historical,
    },
    {
      title: 'documentIcon',
      value: 'Document',
      // clickable: true,
      navigationIds: [''],
      navigationExp: '/',
      hasHoverView: true,
      customHoverView: true,
    },
  ];
  return headers;
};

export const groupedSkillsCheck = list => {
  return groupBy<keyof ICredentials>(
    list,
    ['credentialName'],
    [
      { item: 'dateCompleted', type: 'shortDate' },
      { item: 'lastUpdate', type: 'longDate' },
    ],
  );
};

const flattenData = (
  clinicalQualifications: ICredentials[],
  lookups: CredentialingLookup[],
  viewingDuplicates = false,
  onAttachmentAction: (
    action: AttachmentAction,
    reqID: number,
    requirementTypeID: number,
    travelerId: number,
    brandId: number,
  ) => void,
  level: number = 0,
  expandable: boolean = true,
) => {
  let nestedItems = cloneDeep(clinicalQualifications);

  if (level === 0) {
    const grouped = groupedSkillsCheck(clinicalQualifications);
    nestedItems = nestedChildren(grouped);
  }
  if (level > 0) {
    nestedItems = childSorting(nestedItems, lookups);
  }
  const credentials: any[] = [];

  /**
   * Method to update printed field values to yes and no
   */
  const updatePrintedFieldValues = item => {
    const skillChecklistSubCategory = 'Skills Checklist';
    if (item?.subCategory === skillChecklistSubCategory && item?.printed) {
      if (item?.printed === 'N') {
        item.printed = 'No';
      } else if (item?.printed === 'Y') {
        item.printed = 'Yes';
      }
    }
    if (item?.subCategory === skillChecklistSubCategory) {
      const date = new Date(item.dateCompleted);
      date.setDate(date.getDate() + 1095);
      item.expirationDate = date?.toString();
    }
  };
  nestedItems?.forEach(item => {
    updatePrintedFieldValues(item);
    const fieldLookups = getMetadataForReqID(lookups, item);

    const l: any = {
      rowID: item.requirementID,
      credentialName:
        level === 0 ? (
          getCredentialName(item.requirementType, item.credentialName)
        ) : (
          <VersionDetailsHoverView
            credential={[...detailColumnsAndRows(item, fieldLookups, ['DateCompleted', 'LastUpdate'], true)]}
            fieldLookups={fieldLookups}
            title={getCredentialName(item.requirementType, item.credentialName)}
            titleBold={getCredentialNameBold(item.requirementType, item.credentialName)}
          />
        ),
      credentialNameNoFormat: getCredentialNameNoFormat(item.requirementType, item.credentialName),
      dateCompleted: getDate(fieldLookups, 'DateCompleted', item.dateCompleted),
      dateCompletedNoFormat: getDateNoFormat(fieldLookups, 'DateCompleted', item.dateCompleted),
      lastUpdate: getDateText(fieldLookups, 'LastUpdate', item.lastUpdate),
      lastUpdateNoFormat: getDateNoFormat(fieldLookups, 'LastUpdate', item.lastUpdate),
      versioning: level === 0 ? <HistoryIcon /> : null,
      documentIcon: NoteIcon,
      moreIcon: <MoreVertIcon />,
      expandable: expandable,
      detailRows: [[...detailColumnsAndRows(item, fieldLookups, ['DateCompleted', 'LastUpdate'])]],
      documentIconHoverViewComponent: (
        <CredentialAttachmentToolTip
          onSelect={onAttachmentAction}
          requirementID={item.requirementID}
          requirementTypeID={item.requirementTypeID}
          travelerId={0}
          brandId={0}
          disableEdit
        />
      ),
      versionRows:
        level === 0 && item['children'] !== undefined
          ? flattenData(item['children'], lookups, false, onAttachmentAction, level + 1, false)
          : null,
      versionColumns: getMainTableColumns(true, false, true),
      showVersionsPanel: false,
      expirationDate: getDateText(fieldLookups, 'ExpirationDate', item.expirationDate),
      insertDate: getDateText(fieldLookups, 'InsertDate', item.insertDate),
      printed: item.printed ? item.printed === 'N' ? 'No' : 'Yes' : null,
      updatedBy: item.lastUpdatedByName ? `${item.lastUpdatedByName.first} ${item.lastUpdatedByName.last}`: null,
      type: item.typeID ? item.typeID === 131 ? 'On-file' : 'Verbal' : null,
    };
    credentials.push(l);
  });
  if (level === 0) {
    return credentials.sort(
      viewingDuplicates
        ? (a: any, b: any) => (a.lastUpdateNoFormat < b.lastUpdateNoFormat ? 1 : -1)
        : (a: any, b: any) => (a.dateCompletedNoFormat > b.dateCompletedNoFormat ? 1 : -1),
    );
  } else return credentials;
};

export const clinicalQualificationsSkillsChecklistsData = {
  getTableColumns: getMainTableColumns,
  getTableRows: flattenData,
};
