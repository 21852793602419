import { ISkillCheckListDetails } from 'app/models/Candidate/SkillSet';
import { getVerifiedNonVerifiedSkillSets } from './Cards/helper';

export interface IDropdownOptions {
  ID: number;
  Description: string;
}

export const filterOptions = (options, state) => {
  const newOptions: any = [];
  options.forEach(element => {
    if (element.toLowerCase().includes(state.inputValue?.toLowerCase())) newOptions.push(element);
  });
  return newOptions;
};

export const getIssuedByTypes = (requirementTypeID, lookups, credentialingAllLookups, setIssuedByOptions) => {
  let options: any = [];
  if (!requirementTypeID) options = [];
  else {
    const certValue = lookups?.hearsayCertifications.find(x => x.value === requirementTypeID);

    if (certValue && certValue.name && certValue.name.indexOf('-') > 0) {
      const lookupData: any = credentialingAllLookups?.filter(
        item => item['category'] === 'Certifications' && item['abbreviation'] == certValue.name.split(' - ')[0].trim(),
      );
      let lookupItems = lookupData?.find(
        item => item.controlType == 'Drop Down List' && item.lookUpField == 'IssuedBy',
      )?.lookups;

      if (!lookupItems || lookupItems.length <= 0) {
        lookupItems = lookupData?.find(
          item => item.controlType == 'Drop Down List' && item.lookUpField == 'TypeID',
        )?.lookups;
      }

      lookupItems?.forEach(item => {
        options.push(item);
      });
    }
  }
  setIssuedByOptions(options);
  return options;
};

export const findHoveredContent = (selectedItem: ISkillCheckListDetails, totalChecklist) => {
  const currentRequirements = totalChecklist.filter(item => item.requirementID === selectedItem?.requirementID);
  if (currentRequirements) {
    const element = {
      expiredOn: selectedItem?.formattedDate,
      verifiedSkillSets: getVerifiedNonVerifiedSkillSets(currentRequirements, 1) ?? '',
      unverifiedSkillSets: getVerifiedNonVerifiedSkillSets(currentRequirements, 0) ?? '',
      unlistedSkillSets: '',
    };
    return element;
  }
};

export const referenceTypeOptions = () => {
  return [
    {
      ID: 4,
      Description: 'Allied / In Demand',
    },
    {
      ID: 3,
      Description: 'Placement',
    },
    {
      ID: 1,
      Description: 'Employment Verification',
    },
    {
      ID: 5,
      Description: 'Update References',
    },
  ];
};

export const getAllowedStatuses = (treeData) => {
  const onAssignment =
    treeData
      ?.find(item => item.name === 'On Assignment')
      ?.children.filter(x => x.value === '23')
      .map(item => item.name) || [];

  const booked =
    treeData
      ?.find(item => item.name === 'Booked')
      ?.children.filter(x => !['20', '21', '22'].includes(x.value))
      .map(item => item.name) || [];

  const inSubmission =
    treeData
      ?.find(item => item.name === 'In Submission')
      ?.children.filter(x => !['4', '5', '7'].includes(x.value))
      .map(item => item.name) || [];

  const offer =
    treeData
      ?.find(item => item.name === 'Offer')
      ?.children.filter(x => !['4', '5', '7'].includes(x.value))
      .map(item => item.name) || [];

  const statuses = [...booked, ...offer, ...inSubmission, ...onAssignment];
  return statuses;
};