/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { RadioGroup, FormControlLabel, Radio, TextField, Grid, Typography, Box } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';

const Styles = makeStyles()(theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
    color: '#333333',
    fontSize: 12,
  },
  searchBox: {
    padding: 10,
  },
  outerWrapper: {
    width: '100%',
    minHeight: '50px',
    maxHeight: '463px',
    border: '1px solid #ECECEC',
    borderRadius: 4,
  },
  listItem: {
    '&:hover': {
      backgroundColor: '#ECECEC',
    },
    fontSize: '14px !important',
    letterSpacing: '0px',
    textAlign: 'left',
    opacity: '1',
    marginRight: '0 !important',
    marginTop: '2px',
    marginBottom: '2px',
  },
  formStyle: {
    fontSize: '14px !important',
  },
  radioGroup: {
    marginLeft: '15px',
    '& .Mui-checked': {
      color: theme.palette.framework.system.skyBlue,
      
    },
  },
  searchField: {
    height: '1%',
    width: '100%',
    border: '1px solid #CCCCCC',
    borderRadius: 4,
  },
  noBorder: {
    border: 'hidden',
  },
  searchIcon: {
    opacity: '0.6',
  },
  wrapper: {
    padding: '13px 13px 13px',
  },
  listWrapper: {
    overflow: 'auto',
    // width: '312px',
    width: '100%',
    minHeight: '50px',
    maxHeight: '390px',
  },
}));
const SearchableList = props => {
  const { classes } = Styles();
  const [searchValue, setSearchValue] = useState('');
  const [newData, setNewData] = useState(props.data);
  const [value, setValue] = useState('');
  const { t } = useTranslation();
  const Search = (e, text) => {
    e.preventDefault();
    setSearchValue(text);
  };

  const handleToggle = event => {
    const { value: selectedValue, id: selectedId } = event.target;
    setValue(selectedValue);
    setSearchValue(selectedValue);
    props.onSelect(selectedId);
  };

  const handleHover = value => {
    props.onHover(value);
  };

  const handleMouseOut = () => {
    props.onHover(0);
  };
  //eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (searchValue !== '')
      setNewData(props.data.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase())));
    else setNewData(props.data);
  }, [props.data, searchValue]);

  useEffect(() => {
    if (props.selectedValue) {
      const [unit] = props.data.filter(item => item.unitId == props.selectedValue);
      if (unit) {
        setSearchValue(unit.name);
      }
    }
  }, [props.data, props.selectedValue]);

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" className={classes.root}>
      <Grid item className={classes.outerWrapper}>
        <Box className={classes.wrapper}>
          <TextField
            id="search-unit"
            variant="outlined"
            value={searchValue}
            onChange={e => Search(e, e.target.value)}
            placeholder={t('order.createUnit.search')}
            classes={{ root: classes.searchField }}
            InputProps={{
              startAdornment: <SearchIcon className={classes.searchIcon} />,
              classes: { notchedOutline: classes.noBorder, input: classes.searchBox },
            }}
          />
        </Box>
        <Box className={classes.listWrapper}>
          <RadioGroup
            aria-label="list"
            name="list"
            value={value}
            onChange={handleToggle}
            className={classes.radioGroup}
            
            id={props.id}
          >
            {newData.map(item => {
              return (
                <FormControlLabel
                  key={item.unitId}
                  control={<Radio id={item.unitId.toString()} value={item.name} color="primary" />}
                  onMouseEnter={() => handleHover(item.unitId)}
                  onMouseLeave={handleMouseOut}
                  label={<Typography className={classes.formStyle}>{item.name}</Typography>}
                  checked={item.unitId == props.selectedValue}
                  className={classes.listItem}
                />
              );
            })}
          </RadioGroup>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SearchableList;
