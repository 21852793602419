import { useTranslation } from 'react-i18next';
import { useEmailStyles } from './Email.styles';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Skeleton } from 'amn-ui-core';
import React from 'react';
import { ITemplate, TemplateType } from 'app/models/Notification/Notification';
import { TemplateTile } from './TemplateTile';
import { GetTemplatesByCategory } from 'app/services/NotificationServices/TemplateService';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TemplateNotFound from 'app/assets/images/Template/TemplateNotFound.svg';

export const EmailTemplateList = props => {
  const { t } = useTranslation();
  const { classes } = useEmailStyles();
  const dispatch = useDispatch();
  const globalData = useSelector(notificationSelection);
  const {
    type,
    action,
    loader,
    emailTemplates,
    setEmailTemplates,
    setLoader,
    templateChangeTracker,
    handleEmailPropertyChange,
    handleAttachments,
    setAction,
    setTemplateChangeTracker,
    handleTemplateDelete,
    handleSelectedTemplate,
  } = props;

  const messageBox = () => {
    return (
      <Grid className={classes.messageBox}>
        <div className={classes.message}>
          <img src={TemplateNotFound} alt="No templates available" />
          <span>{t('notification.emails.noTemplatesAvailable')}</span>
        </div>
      </Grid>
    );
  };

  const onTemplateCategoryChange = async (event: SelectChangeEvent, selectedTemplateType: TemplateType) => {
    event.stopPropagation();
    setEmailTemplates(prevState =>
      selectedTemplateType === TemplateType.Personal
        ? {
            ...prevState,
            selectedPersonalCategory: event.target.value,
          }
        : {
            ...prevState,
            selectedPublicCategory: event.target.value,
          },
    );

    if (event.target.value !== '') {
      setLoader(prevState => ({ ...prevState, templateSelectionGridLoader: true }));
      await GetTemplatesByCategory(event.target.value, 'email').then(response => {
        setLoader(prevState => ({ ...prevState, templateSelectionGridLoader: false }));
        setEmailTemplates(prevState =>
          selectedTemplateType === TemplateType.Personal
            ? {
                ...prevState,
                personalTemplates: response.filter((item: { isPrivate: any }) => {
                  return item.isPrivate;
                }),
              }
            : {
                ...prevState,
                publicTemplates: response.filter((item: { isPrivate: any }) => {
                  return !item.isPrivate;
                }),
              },
        );
      });
    }
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      <FormControl fullWidth variant="filled" className={classes.categorySelection}>
        {(type === TemplateType.Personal
          ? emailTemplates.selectedPersonalCategory === ''
          : emailTemplates.selectedPublicCategory === '') && (
          <InputLabel className={classes.inputLabel}>{t(`notification.templateCategorySelector`)}</InputLabel>
        )}
        <Select
          variant="outlined"
          onChange={event => onTemplateCategoryChange(event, type)}
          open={type === TemplateType.Personal ? globalData.dropdown?.personal : globalData.dropdown?.public}
          onClose={() =>
            dispatch(
              notificationDataActions.setDropdown(
                type === TemplateType.Personal ? { personal: false } : { public: false },
              ),
            )
          }
          onOpen={() =>
            dispatch(
              notificationDataActions.setDropdown(
                type === TemplateType.Personal ? { personal: true } : { public: true },
              ),
            )
          }
          disableUnderline
          name="category"
          value={
            type === TemplateType.Personal
              ? emailTemplates.selectedPersonalCategory
              : emailTemplates.selectedPublicCategory
          }
          classes={{ select: classes.select }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          IconComponent={KeyboardArrowDownIcon}
        >
          {emailTemplates.templateTab === TemplateType.Personal
            ? emailTemplates.personalCategory.map((listItem, index) => (
                <MenuItem key={index} value={listItem}>
                  {listItem}
                </MenuItem>
              ))
            : emailTemplates.publicCategory.map((listItem, index) => (
                <MenuItem key={index} value={listItem}>
                  {listItem}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
      <div>
        {loader.templateSelectionGridLoader ? (
          <Grid item xs={12}>
            <Skeleton variant="rounded" className={classes.skeleton} />
            <Skeleton variant="rounded" className={classes.skeleton} />
          </Grid>
        ) : type === TemplateType.Personal ? (
          (emailTemplates.personalTemplates as Array<ITemplate>).length < 1 ? (
            <>{messageBox()}</>
          ) : (
            (emailTemplates.personalTemplates as Array<ITemplate>).map((item, index) => (
              <div className={classes.templateSnap} key={index}>
                <TemplateTile
                  changeTracker={templateChangeTracker}
                  data={item}
                  html={item?.body}
                  handlers={{
                    handleEmailPropertyChange,
                    handleAttachments,
                    setAction,
                    setLoader,
                    setTemplateChangeTracker,
                    handleTemplateDelete,
                  }}
                  action={action}
                  handleSelected={handleSelectedTemplate}
                  selected={emailTemplates?.selectedTemplate && emailTemplates?.selectedTemplate?.id === item.id}
                />
              </div>
            ))
          )
        ) : (emailTemplates?.publicTemplates as Array<ITemplate>).length < 1 ? (
          <>{messageBox()}</>
        ) : (
          (emailTemplates?.publicTemplates as Array<ITemplate>).map((item, index) => (
            <div className={classes.templateSnap} key={index}>
              <TemplateTile
                changeTracker={templateChangeTracker}
                data={item}
                html={item?.body}
                handlers={{
                  handleEmailPropertyChange,
                  handleAttachments,
                  setAction,
                  setLoader,
                  setTemplateChangeTracker,
                }}
                action={action}
                handleSelected={handleSelectedTemplate}
                selected={emailTemplates?.selectedTemplate && emailTemplates?.selectedTemplate?.title === item.title}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};
