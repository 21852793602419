import React, { ChangeEvent, createContext, useContext, useState } from 'react';
import {
  TextField,
  Paper,
  Button,
  Grid,
  Checkbox,
  Popper,
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  AutocompleteInputChangeReason,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';
import { DisableAppScroll, EnableAppScroll } from 'app/layout/Layout';
import { useTranslation } from 'react-i18next';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { theme } from 'styles/global-styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
const useStyles = makeStyles()(theme => ({
  actionContainer: {
    backgroundColor: '#ffffff',
    '&:hover, &:focus': {
      backgroundColor: '#ffffff',
    },
    position: 'sticky',
    bottom: -8,
    width: '100%',
    borderTop: '1px solid #cccccc',
    padding: 5,
    zIndex: 10,
  },
  button: {
    fontSize: 14,
    textTransform: 'none',
    '&:hover, &:focus': {
      background: 'none',
    },
  },

  cancelButton: {
    color: '#888888',
  },
  checked: {
    color: `${theme.palette.framework.system.skyBlue} !important`,
  },
  icon: {
    '&:hover': {
      fill: theme.palette.framework.system.skyBlue,
    },
  },
  countTag: {
    color: '#555555',
    fontSize: 14,
    fontWeight: 600,
    paddingLeft: 10,
  },
  textField: {
    fontSize: 12,
    color: '#52429A',
    '&::placeholder': {
      opacity: 1,
      fontSize: 12,
      color: '#C0C0C0',
    },
  },
}));

export interface ITypeAheadOption {
  object: any;
  label: any;
  value: any;
}
const smallTextFieldTheme = createTheme(theme, {
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          height: '37.625px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: { height: '37.625px' },
      },
    },
  },
});

const DropDownContext = createContext<{
  selected: ITypeAheadOption[];
  isMultiSelect: boolean;
  onApplyEvent: Function;
  cancelChanges: Function;
  clearAll: Function;
}>({
  selected: [],
  isMultiSelect: false,
  onApplyEvent: () => {},
  cancelChanges: () => {},
  clearAll: () => {},
});

export const FilterTypeAhead = props => {
  const {
    label,
    options,
    isError,
    isDisabled,
    helperText,
    placeholder,
    isOptionsLoading,
    customClasses,
    noOptionsText,
    searchOnCharacterCount = 0,
    variant,
    size,
    startAdornment,
    enableCustomScroll,
    removeCloseIcon,
    isMultiSelect,
    filterName,
  } = props;
  const { classes } = useStyles();

  const [isNewSelectionApplied, setIsNewSelectionApplied] = useState<boolean>(false);
  const [prevState, setPrevState] = useState<any>(props.value);
  const [open, setOpen] = useState<boolean>(false);
  const [textFieldValue, setTextFieldValue] = useState<any>(null);
  const autocompleteRef: React.RefObject<any> = React.useRef();
  const inputRef: React.RefObject<any> = React.useRef();

  const onChange = (
    event: ChangeEvent<{}>,
    choice: ITypeAheadOption[] | ITypeAheadOption | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ITypeAheadOption> | undefined,
  ) => {
    setIsNewSelectionApplied(false);
    props.onChange(choice);
  };

  const onInputChange = (event, newInputValue: string, reason: AutocompleteInputChangeReason) => {
    if (newInputValue) setOpen(searchOnCharacterCount > (newInputValue || '').length ? false : true);
    if (event) {
      /** caching of tagName in order to avoid  event.currentTarget turn nullable from synthetic event change*/
      const { tagName } = event.currentTarget;
      setTextFieldValue(prevValue => (tagName === 'LI' ? prevValue : newInputValue));
    }
    if (props.onInputChange && searchOnCharacterCount <= newInputValue.length) props.onInputChange(newInputValue);
  };

  const onFocus = event => {
    if (typeof props.onFocus === 'function') props.onFocus(event);
  };

  const onApplyEvent = () => {
    setIsNewSelectionApplied(true);
    props.apply();
    setOpen(false);
  };

  const handleBlur = event => {
    /**event.relatedTarget - differentiate b/w click outside and within autocomplete */
    if (!event.relatedTarget) {
      !isNewSelectionApplied && props.onChange(prevState);
      setOpen(false);
    }
    // setTextFieldValue(`(${props.value.length}) ${props.filterName}`);
    props.onBlur(event);
  };

  const cancelChanges = () => {
    setOpen(false);
    props.onChange(prevState);
  };

  const clearAll = () => props.onChange(isMultiSelect ? [] : null);

  return (
    <DropDownContext.Provider
      value={{
        selected: props.value,
        isMultiSelect,
        onApplyEvent,
        cancelChanges,
        clearAll,
      }}
    >
      <Autocomplete
        ref={autocompleteRef}
        multiple={isMultiSelect}
        clearOnBlur
        fullWidth
        inputValue={textFieldValue || ''}
        noOptionsText={noOptionsText || undefined}
        value={props.value}
        options={options}
        disabled={isDisabled}
        isOptionEqualToValue={(option, value) => option.object.value === value.object.value}
        getOptionLabel={(option: ITypeAheadOption) => option.label}
        popupIcon={props.popupIcon ? props.popupIcon : null}
        forcePopupIcon={true}
        renderTags={() => null}
        loading={isOptionsLoading}
        classes={{ ...customClasses } || undefined}
        clearIcon={removeCloseIcon ? null : <CloseIcon fontSize="small" />}
        open={open}
        onOpen={() => {
          setPrevState(props.value);
          options.length && DisableAppScroll();
        }}
        onClose={() => {
          EnableAppScroll();
        }}
        onBlur={handleBlur}
        renderInput={params => (
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={smallTextFieldTheme}>
              <TextField
                ref={inputRef}
                error={isError}
                onFocus={onFocus}
                helperText={helperText}
                label={label}
                placeholder={placeholder}
                {...params}
                variant={variant || 'filled'}
                size={size || undefined}
                InputProps={{
                  ...params.InputProps,
                  classes: { input: classes.textField },
                  startAdornment: startAdornment || undefined,
                  'aria-label': 'filter-type-ahead',
                }}
              />
            </ThemeProvider>
          </StyledEngineProvider>
        )}
        onChange={onChange}
        onInputChange={onInputChange}
        renderOption={(p, option, { selected }) => (
          <>
            <Checkbox
              size="small"
              classes={{ checked: classes.checked }}
              icon={
                props.isMultiSelect ? (
                  <CheckBoxOutlineBlankIcon classes={{ root: classes.icon }} />
                ) : (
                  <RadioButtonUncheckedIcon />
                )
              }
              checkedIcon={isMultiSelect ? undefined : <RadioButtonCheckedIcon color="secondary" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </>
        )}
        PopperComponent={PopperComponent}
        PaperComponent={PaperDropdown}
        aria-label={`custom-typeAhead-${filterName}`}
      />
    </DropDownContext.Provider>
  );
};

const PopperComponent = props => <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-start" />;

const PaperDropdown = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { selected, isMultiSelect, onApplyEvent, cancelChanges, clearAll } = useContext(DropDownContext);

  return (
    <Paper {...props}>
      <Grid
        item
        container
        justifyContent={isMultiSelect ? 'space-between' : 'flex-end'}
        alignItems="center"
        style={{ padding: 6, minWidth: 250 }}
      >
        {isMultiSelect ? (
          <div className={classes.countTag}>{`${selected.length} ${t('search.filter.Selected')}`}</div>
        ) : null}
        <Button
          className="clear-all-button"
          aria-label="clear-all-button"
          variant="text"
          color="primary"
          disableRipple
          disableFocusRipple
          disableTouchRipple
          onClick={() => clearAll()}
          disabled={!isMultiSelect}
          classes={{ text: ` ${classes.button}` }}
        >
          {t('search.filter.clearAll')}
        </Button>
      </Grid>
      {props.children}
      <div className={classes.actionContainer}>
        <Grid item container justifyContent="flex-end" alignItems="center">
          <Button
            className="clear-all-button"
            aria-label="apply-button"
            variant="text"
            color="primary"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() => onApplyEvent()}
            classes={{ text: ` ${classes.button}` }}
          >
            {t('search.filter.apply')}
          </Button>
          <Button
            className="clear-all-button"
            aria-label="cancel-button"
            variant="text"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() => cancelChanges()}
            classes={{ text: ` ${classes.button} ${classes.cancelButton}` }}
          >
            {t('search.filter.cancel')}
          </Button>
        </Grid>
      </div>
    </Paper>
  );
};
