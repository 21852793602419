import React from 'react';
import { Grid, Link } from 'amn-ui-core';
import { TaskEntity } from 'app/models/Tasks/Tasks';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { WhiteTooltip } from 'app/components/Common/Tooltips/WhiteTooltip';
import { truncateString } from 'utils/string/string';
import { PassportBadge } from '../CustomComponents/PassportBadge';
import FacilityIcon from 'app/assets/images/Tasks/icon-facility.svg';
import OrderIcon from 'app/assets/images/Tasks/icon-jobs.svg';
import PlacementsPersonIcon from 'app/assets/images/Tasks/PlacementsPersonIcon.svg';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

export const TaskDetailsLeftpanel = ({
  classes,
  taskDetails,
  isExternal = false,
  currentContainer,
  expanded = false,
}: {
  classes: any;
  taskDetails: any;
  isExternal?: boolean;
  currentContainer?: TaskEntity;
  expanded: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Grid
      style={{ backgroundColor: '#F0F0F0', minWidth: expanded ? '306px' : '240px', height: 'inherit' }}
      data-testid="task-details-left-content"
    >
      <div className={classes.gridContainers}>
        {(taskDetails?.candidate?.id || 0) > 0 && (
          <div className={classes.flexCenterContainer}>
            <div className={classes.userIcon} style={{ marginRight: '8px' }}>
              {isExternal ? (
                <PassportBadge />
              ) : (
                <AccountCircleOutlinedIcon style={{ color: '#CCCCCC', fontSize: '29px' }} />
              )}
            </div>
            {(taskDetails?.candidate?.firstName + ' ' + taskDetails?.candidate?.lastName).length >= 30 ? (
              <WhiteTooltip arrow title={taskDetails?.candidate?.firstName + ' ' + taskDetails?.candidate?.lastName}>
                <React.Fragment>
                  {taskDetails?.companyId !== 0 && taskDetails?.companyId !== null ? (
                    <Link
                      data-testid="task-details-candidate-container"
                      href={`/candidate/${taskDetails?.candidate?.id}/${taskDetails?.companyId}`}
                      className={classes.taskLinks}
                    >
                      {truncateString(taskDetails?.candidate?.firstName + ' ' + taskDetails?.candidate?.lastName, 30) +
                        ' '}
                      {taskDetails?.candidate?.id}
                    </Link>
                  ) : (
                    taskDetails?.candidate?.firstName + ' ' + taskDetails?.candidate?.lastName
                  )}
                </React.Fragment>
              </WhiteTooltip>
            ) : taskDetails?.companyId !== 0 && taskDetails?.companyId !== null ? (
              <Link
                data-testid="task-details-candidate-container"
                href={`/candidate/${taskDetails?.candidate?.id}/${taskDetails?.companyId}`}
                className={classes.taskLinks}
              >
                {`${taskDetails?.candidate?.firstName} ${taskDetails?.candidate?.lastName} (${taskDetails?.candidate?.id})`}
              </Link>
            ) : (
              taskDetails?.candidate?.firstName + ' ' + taskDetails?.candidate?.lastName
            )}
          </div>
        )}
        {(taskDetails?.placementId || 0) > 0 && (
          <div style={{ marginTop: '24px' }} className={classes.flexCenterContainer}>
            <div className={classes.userIcon} style={{ marginRight: '8px' }}>
              <img
                src={PlacementsPersonIcon}
                alt="PlacementsPersonIcon"
                style={{ color: '#CCCCCC', height: '24px', width: '24px' }}
              />
            </div>
            <div>
              <Link
                data-testid="task-details-placement-container"
                href={`/placement/${taskDetails?.placementId}`}
                className={classes.taskLinks}
              >
                {t('notification.taskDetails.placementIDTitle') + ' ' + taskDetails?.placementId}
              </Link>
              {taskDetails?.placementStatus && taskDetails?.taskSource === 1 && !isExternal && (
                <div style={{ minWidth: '7.5rem' }}>
                  <span
                    className={classes.statusBadge}
                    style={{ backgroundColor: '#0198D7' }}
                    data-testid="task-status-badge"
                  >
                    {taskDetails?.placementStatus}
                  </span>
                </div>
              )}
              {taskDetails?.placementStatus && isExternal && currentContainer === TaskEntity.PLACEMENTS && (
                <div>
                  <span
                    className={classes.statusBadge}
                    style={{ backgroundColor: '#0198D7' }}
                    data-testid="task-status-badge"
                  >
                    {taskDetails?.placementStatus}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
        {(taskDetails?.facilityId || 0) > 0 && (
          <div style={{ marginTop: '24px' }} className={classes.flexCenterContainer}>
            <div className={classes.userIcon} style={{ marginRight: '8px' }}>
              <img src={FacilityIcon} alt="FacilityIcon" style={{ color: '#CCCCCC', height: '24px', width: '24px' }} />
            </div>
            {(taskDetails?.facilityName || '').length >= 30 ? (
              <WhiteTooltip arrow title={taskDetails?.facilityName || ''}>
                <Link
                  data-testid="task-details-facility-container"
                  href={`/facility/${taskDetails?.facilityId}`}
                  className={classes.taskLinks}
                >
                  {`${truncateString(taskDetails?.facilityName || '', 30)} (${taskDetails?.facilityId})`}
                </Link>
              </WhiteTooltip>
            ) : (
              <Link
                data-testid="task-details-facility-container"
                href={`/facility/${taskDetails?.facilityId}`}
                className={classes.taskLinks}
              >
                {taskDetails?.facilityName
                  ? taskDetails?.facilityName + ' '
                  : t('notification.taskDetails.facilityIDTitle')}
                {`(${taskDetails?.facilityId})`}
              </Link>
            )}
          </div>
        )}
        {(taskDetails?.orderId || 0) > 0 && (
          <div style={{ marginTop: '24px' }} className={classes.flexCenterContainer}>
            <div className={classes.userIcon} style={{ marginRight: '8px' }}>
              <img src={OrderIcon} alt="OrderIcon" style={{ color: '#CCCCCC', height: '24px', width: '24px' }} />
            </div>
            <Link
              data-testid="task-details-order-container"
              href={`/order/${taskDetails?.orderId}`}
              className={classes.taskLinks}
            >
              {t('notification.taskDetails.orderIDTitle') + ' ' + taskDetails?.orderId}
            </Link>
          </div>
        )}
        {isExternal && (
          <>
            <div className={classes.createdBy}>
              <div className={classes.textItems} style={{ marginTop: '24px', marginBottom: '5px' }}>
                {t('notification.taskDetails.createdByTitle')}
              </div>
              <div className={classes.creatorDetails}>
                <div>
                  <div
                    data-testid="task-details-created-by"
                    className={classes.textItems + ' ' + classes.truncateText}
                    style={{ fontWeight: 500 }}
                  >
                    {taskDetails?.createdBy?.firstName + ' ' + taskDetails?.createdBy?.lastName}
                  </div>
                  <div
                    data-testid="task-details-created-date"
                    className={classes.textItems + ' ' + classes.truncateText}
                    style={{ fontWeight: 500 }}
                  >
                    {moment.tz(taskDetails?.createdDate, 'America/Los_Angeles').format('L hh:mm A [PST]')}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.textItems} style={{ marginTop: '24px' }}>
              {taskDetails?.statusId === 3 ? t('notification.taskDetails.completedByTitle') : null}
              {taskDetails?.statusId === 4 ? t('notification.taskDetails.cancelledByTitle') : null}
            </div>
            <WhiteTooltip title={taskDetails?.role}>
              <div className={classes.textItems + ' ' + classes.truncateText} style={{ fontWeight: 500 }}>
                {taskDetails?.statusId === 3 || taskDetails?.statusId === 4 ? taskDetails?.statusUpdatedByName : null}
              </div>
            </WhiteTooltip>
            <div className={classes.textItems + ' ' + classes.truncateText} style={{ fontWeight: 500 }}>
              {taskDetails?.statusId === 4 || taskDetails?.statusId === 3
                ? moment.tz(taskDetails?.statusUpdatedDate, 'America/Los_Angeles').format('L hh:mm [PST]')
                : null}
            </div>
          </>
        )}
      </div>
    </Grid>
  );
};
