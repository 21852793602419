import React from 'react';
import { Box, Chip, Grid, Theme } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';
import { ITravelExperience } from '@AMIEWEB/Facility/FacilityStore/Facility.model';
import StatusTile, { StatusTileProps } from '@AMIEWEB/Unit/Common/StatusTile';
import { missingField } from 'app/constants';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { transformUnitExperienceForTravelTile } from '../Qualifications/helper';

const useStyles = makeStyles()((theme: Theme) => ({
  subContainer: {
    marginTop: '10px',
  },
  chipClass: {
    marginLeft: '5px',
  },
}));

const Travel = ({ data }: { data: ITravelExperience }) => {
  const { t: translation } = useTranslation();
  const { classes } = useStyles();
  const transformedTravelData: StatusTileProps = transformUnitExperienceForTravelTile(data);

  return transformedTravelData ? (
    <Grid container alignItems="center">
      {transformedTravelData && <StatusTile tileData={transformedTravelData} />}
      <Grid item className={classes.subContainer}>
        {data?.withinTwoYears && <Chip label={translation('facility.units.travelExp.withinTwoYears')} />}
        {data?.isAMNTravel && (
          <Chip className={classes.chipClass} label={translation('facility.units.travelExp.aMNTravel')} />
        )}
      </Grid>
    </Grid>
  ) : (
    <Box>{missingField}</Box>
  );
};

export default Travel;
