import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IConfetti, IConfettiDetails } from '../ConfettiModel';

export const initialState = {
  confettiDetails: {
    show: false,
    timeout: 3,
    message: '',
  } as IConfetti,
} as IConfettiDetails;

const GetConfettiDetailsAction = createAction<{
  confettiDetails: IConfetti;
}>('GET_CONFETTI_DETAILS');

const ConfettiDetailSlice = createSlice({
  name: 'confettiDetails',
  initialState,
  reducers: {
    setConfettiDetails(state, action: PayloadAction<IConfetti>) {
      state.confettiDetails = action.payload;
    },
  },
});

const { actions } = ConfettiDetailSlice;
export const { reducer: confettiDetailsReducer, name: confettiDetailsSliceKey } = ConfettiDetailSlice;
export const confettiDetailsAction = {
  ...actions,
  GetConfettiDetailsAction,
};
