import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NewUnit } from 'app/models/Unit/CreateUnit';
import { OrderFacilityDetails } from 'app/models/Facility/FacilityDetails';
import { UnitResponse } from 'app/models/Facility/Unit';
import {
  OrderHeaderSection,
  IAtAGlance,
  QuickGlance,
  OrderResponse,
  NewOrder,
  ITypeAheadOption,
  IOrderReportDetails,
} from 'app/models/Orders/OrderDetails';
import { IOrderDetails } from 'app/models/Orders/OrderDetails';
import { FacilityResponse } from 'app/models/Facility/FacilityDetails';
import { serviceActions } from './async-actions';
import { IOrderRateElement } from './types';
import { GridColumns } from '@mui/x-data-grid-pro';
import { IFacilityDetails } from '@AMIEWEB/Facility/FacilityStore/Facility.model';
import { ISelectedSubCert } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Grid/FacilityOrderUnitGrid';

export type LevelTypes = 'Standardize' | 'Classic' | 'None';

export interface LevelRules {
  fieldName: string;
  isRequired: boolean;
  disabled: boolean;
  level: LevelTypes;
}

export interface OrdersPage {
  orders: OrderResponse[] | null;
  totalCount: number;
  pageSize: number;
}

export interface IRateElementShiftOcbr {
  shiftId: number;
  shiftValue: string;
  shiftDisplayValue: string;
}

export enum Qualification {
  license = 'license',
  certification = 'certification',
}

export enum CompactStatus {
  single = 'Single State',
  compact = 'Compact',
}

export interface ILicenseStateData {
  state?: any[];
  isRequired?: boolean;
  documentAttachmentRequired?: boolean;
}

export interface ILicenseErrorDetails {
  compactStatus?: boolean;
  state?: boolean;
}

export interface IOrderLicense {
  licenseDescription?: string;
  licenseId?: number;
  licenseAbbreviation?: string;
  compactStatus?: CompactStatus;
  abbreviation?: string;
  licenseStateDetails?: ILicenseStateData[];
  isError?: ILicenseErrorDetails;
  isSelected?: boolean;
}

export interface IOrderCertification {
  abbreviation?: string;
  isRequired?: boolean;
  documentAttachmentRequired?: boolean;
  requirementTypeId?: number;
  isError?: boolean;
  isSubCert?: boolean;
}

export interface IOrderLicenseState {
  state: any;
  isRequired?: boolean;
  documentAttachmentRequired?: boolean;
}

export interface IOrderBonusTab {
  orderBonusList: any[];
  orderBonusShiftList: any[];
  OrderBonusCalculatedAmount: { type: string; rate: number; returnValue: number; error: string };
  isOrderBonusCreated: any;
  isOrderBonusDeleted: boolean;
  isOrderBonusCopied: boolean;
  editBonusModal: { isEdit: boolean; bonusId: number; rowData: any };
  changeHistoryModal: { open: boolean; changeHistoryRows: any };
  updateOrderBonusShift: boolean;
}

export const initialOrderBonusTab: IOrderBonusTab = {
  orderBonusList: [],
  orderBonusShiftList: [],
  OrderBonusCalculatedAmount: null,
  isOrderBonusCreated: false,
  isOrderBonusDeleted: false,
  isOrderBonusCopied: false,
  editBonusModal: { isEdit: false, bonusId: 0, rowData: null },
  changeHistoryModal: { open: false, changeHistoryRows: null },
  updateOrderBonusShift: false,
};
export interface IOrdersDetails {
  details: any;
  newOrder: NewOrder | null;
  newUnit: NewUnit | null;
  level: LevelTypes;
  levelRules: LevelRules[] | null;
  orders: OrdersPage | null;
  loading: boolean;
  isEditUnit: boolean;
  currentUnitId: any | null;
  activeStepCount: any;
  reload: boolean;
  orderCreationProcessStep: IOrderCreationProcessStep | null;
  editUnitProcessStep: IUnitEditProcessStep | null;
  orderDetailsView: IOrderDetailsView | null;
  orderUnitDetails: Number;
  isOrderFormValid: boolean | false;
  typeAheadOptions: ITypeAheadOptions | null;
  overrideCreditHold: boolean | false;
  orderStrikeDetails: IStrikeDetailsRequest | null;
  isStrikeDataUpdated: boolean;
  rateElements: IOrderRateElement[];
  rateCardView: {
    rates: any[];
    rateColumns: GridColumns[];
    skillsets: any[];
    skillsetColumns: GridColumns[];
  };
  orderBonuses: IOrderBonus[];
  orderLicenses: IOrderLicense[];
  orderCertification: IOrderCertification[];
  subCertificationList: any[];
  orderTypes: Array<{
    ID: number;
    Description: string;
  }>;
  regions: { ID: number; Description: string }[];
  isPreferenceChanged: boolean;
  confirmationDialog: IConfirmationDialog;
  tabValue: number;
  orderBonusTab: IOrderBonusTab;
  selectedSubCert: ISelectedSubCert[];
}

export interface IConfirmationDialog {
  open: boolean;
  tab: number;
}

export const initialState: IOrdersDetails = {
  details: null,
  newOrder: null,
  newUnit: null,
  level: 'None',
  levelRules: null,
  orders: null,
  loading: false,
  isEditUnit: false,
  currentUnitId: 0,
  activeStepCount: 0,
  reload: false,
  orderCreationProcessStep: null,
  editUnitProcessStep: null,
  orderDetailsView: null,
  orderUnitDetails: 0,
  isOrderFormValid: false,
  typeAheadOptions: null,
  overrideCreditHold: false,
  orderStrikeDetails: null,
  isStrikeDataUpdated: false,
  rateElements: [],
  rateCardView: {
    rates: [],
    rateColumns: [],
    skillsets: [],
    skillsetColumns: [],
  },
  orderBonuses: [],
  orderLicenses: [],
  orderCertification: [],
  subCertificationList: [],
  orderTypes: [],
  regions: [],
  isPreferenceChanged: false,
  confirmationDialog: { open: false, tab: 0 },
  tabValue: 0,
  orderBonusTab: initialOrderBonusTab,
  selectedSubCert: [],
};

export interface IOrderCreationProcessStep {
  facilityId: number;
  facilityData: OrderFacilityDetails;
  selectedNewUnitId: number | -1;
  selectedNewUnit: NewUnit | null;
  unitsData: UnitResponse[] | null;
  newOrder: NewOrder | null;
  isMandatoryFieldsFilled: boolean | false;
  isMandatorySecondaryFieldsFilled: boolean | false;
  showMandetryStyle: boolean | false;
  isUnitSelected: boolean | false;
  isRequirementEdited: boolean | false;
  isDetailsUpdated: boolean | false;
  editMode: string | 'standardize';
  currentStep: string;
  requestedBack: boolean | false;
  activeStep: number | 0;
  isEditStarted: boolean | false;
}

export interface IUnitEditProcessStep {
  facilityId: number;
  facilityResponse: FacilityResponse | null;
  facilityDataForUnit: any | null;
  selectedNewUnitId: number | -1;
  selectedNewUnit: NewUnit | null;
  unitsData: UnitResponse[] | null;
  selectedUnitData: UnitResponse | null;
  readyToEdit: boolean | false;
  editStarted: boolean | false;
  isRememebrCheckboxSelected: boolean | false;
  editMode: string;
  callingComponent?: string;
}

export interface ITypeAheadOptions {
  disciplines: ITypeAheadOption[];
  specialties: ITypeAheadOption[];
  subSpecialties: ITypeAheadOption[];
  divisionDisciplines?: any[];
}

export interface IOrderDetailsView {
  orderResponse: OrderResponse;
  orderDetails: IOrderDetails;
  orderDetailsOrginal: IOrderDetails;
  facilityId: number;
  headerDetails: OrderHeaderSection;
  atAGlance: IAtAGlance;
  quickGlanceDetails: QuickGlance;
  facilityDetails: IFacilityDetails;
  selectedNewUnitId: number | -1;
  selectedNewUnit: NewUnit | null;
  unitsData: UnitResponse[] | null;
  isMandatoryFieldsFilled: boolean | false;
  isEditStarted: boolean | false;
  editMode: string | 'inline';
  currentStep: string;
  newOrder: NewOrder | null;
  creditStatus: boolean | false;
  pendingStatus: boolean | false;
  orderReportDetails: IOrderReportDetails | null;
}

export interface IStrikeDetailsRequest {
  facilityId: number;
  orderId: number;
  oKtoTravel: boolean;
  orderStrikeNoticeId: number;
  strikeNoticeDate: string;
  effectiveDate: string;
  endDate: string;
  travelDate1: string;
  travelDate2: string;
  travelDate3: string;
  travelDate4: string;
  travelDate5: string;
  lastUpdatedbyId: number;
}

export interface IOrderBonus {
  division: string;
  clientType: string;
  mspClient: string;
  facilityId: number | string;
  facilityStatusId: number | string;
  facilityStatus: string;
  fullDescFacilityStatus: string;
  facilityName: string;
  unitName: string;
  orderId: number | string;
  orderPriority: string;
  skillSets: string;
  shifts: string;
  orderBillRate: number | string | null;
  maxFilesSent: string;
  positionsAvailable: number | string;
  pricingStatus: string;
  bonusAmount: number | string;
  bonusStartDate: Date | string;
  bonusEndDate: Date | string;
  bonusId: number | string;
  statusId: number | string;
  bonusStatus: string;
  bonusType: string;
  orderCreationDate: Date | string;
  statusChangedDate: Date | string;
  statusChangedByUserId: number | string;
  changedByFirstName: string;
  changedByLastName: string;
  totalCount: number;
}

export interface IOrderBonusCommand {
  pageNumber: number;
  statusIds: number[];
  bonusTypeIds: number[];
  facilityIds: number[];
  facilityStatusIds: number[];
  orderIds: number[];
  mspClientIds: number[];
  orderBillRate: any;
  maxFilesSentId: number;
  positions: any;
  sortColumn: string;
  sortOrder: string;
  pageSize?: number;
  divisionIds: number[];
  disciplineIds: number[];
  specialtyIds: number[];
  subSpecialtyIds: number[];
  orderShiftIds: number[];
  orderPriorityIds: number[];
}

export interface IOrderBonusSave {
  orderId: number;
  bonusId: number;
  statusId: number;
  bonusAmount: number;
  bonusStartDate: string;
  bonusEndDate: string;
  totalCount: number;
  paymentTypeId: number;
  bonusTypeId: number;
  payerId: number;
  billingTypeId: number;
  billingAmount: number;
  approvedBy: string;
  approvalTypeID: number;
  approvalDate: string;
  startDate: string;
  endDate: string;
  bonusShortHoursTypeId: number;
  shortAssignmentBonusId: number;
  notes: string;
  minimumWeeksWorked: number;
  bonusBillingAmount: number;
  bonusTemplateId: number;
  userId: number;
  shiftIds?: number[];
  timeStamp?: string;
  deletedOrderBonusShifts?: number[];
}

const orderDataSlice = createSlice({
  name: 'orderData',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setDetails(state, action: PayloadAction<any>) {
      state.details = action.payload;
    },
    setNewOrder(state, action: PayloadAction<NewOrder | null>) {
      state.newOrder = action.payload;
    },
    setNewUnit(state, action: PayloadAction<NewUnit | null>) {
      state.newUnit = action.payload;
    },
    setIsEditUnit(state, action: PayloadAction<boolean>) {
      state.isEditUnit = action.payload;
    },
    setLevel(state, action: PayloadAction<LevelTypes>) {
      state.level = action.payload;
    },
    setLevelRules(state, action: PayloadAction<LevelRules[]>) {
      state.levelRules = action.payload;
    },
    setOrders(state, action: PayloadAction<OrdersPage>) {
      state.orders = action.payload;
    },
    setCurrentUnitId(state, action: PayloadAction<any>) {
      state.currentUnitId = action.payload;
    },
    setActiveStepCount(state, action: PayloadAction<any>) {
      state.activeStepCount = action.payload;
    },
    setReload(state, action: PayloadAction<boolean>) {
      state.reload = action.payload;
    },
    setOrderCreationProcessStep(state, action: PayloadAction<IOrderCreationProcessStep | null>) {
      state.orderCreationProcessStep = action.payload;
    },
    setDetailsCurrentStep(state, action: PayloadAction<string>) {
      if (state.orderDetailsView) state.orderDetailsView.currentStep = action.payload;
    },
    setUnitEditProcessStep(state, action: PayloadAction<IUnitEditProcessStep | null>) {
      state.editUnitProcessStep = action.payload;
    },
    setOrderDetailsView(state, action: PayloadAction<IOrderDetailsView | null>) {
      state.orderDetailsView = action.payload;
    },
    setFormValid(state, action: PayloadAction<boolean>) {
      state.isOrderFormValid = action.payload;
    },
    setTypeAheadOptions(state, action: PayloadAction<ITypeAheadOptions>) {
      state.typeAheadOptions = action.payload;
    },
    setOverrideCreditHold(state, action: PayloadAction<boolean>) {
      state.overrideCreditHold = action.payload;
    },
    setOrderStrikeDetails(state, action: PayloadAction<IStrikeDetailsRequest | null>) {
      state.orderStrikeDetails = action.payload;
    },
    setStrikeUpdateStatus(state, action: PayloadAction<boolean>) {
      state.isStrikeDataUpdated = action.payload;
    },
    setOrderLicenseDetails(state, action: PayloadAction<IOrderLicense[]>) {
      state.orderLicenses = action.payload;
    },
    setsubCertificationList(state, action: PayloadAction<any[]>) {
      state.subCertificationList = action.payload;
    },
    setOrderCertificationDetails(state, action: PayloadAction<IOrderCertification[]>) {
      state.orderCertification = action.payload;
    },
    /**Code Refactor - begin */
    setOrderRateElements(
      state,
      action: PayloadAction<{
        rateElements: IOrderRateElement[];
        rates: any[];
        rateColumns: GridColumns[];
        skillsets: any[];
        skillsetColumns: GridColumns[];
      }>,
    ) {
      const rateCardView = {
        rates: action.payload.rates,
        rateColumns: action.payload.rateColumns,
        skillsets: action.payload.skillsets,
        skillsetColumns: action.payload.skillsetColumns,
      };
      return { ...state, rateElements: action.payload.rateElements, rateCardView };
    },
    resetRateElements(state) {
      return { ...state, rateElements: [], rateCardView: initialState.rateCardView };
    },
    setOrderBonuses(state, action: PayloadAction<IOrderBonus[]>) {
      state.orderBonuses = action.payload;
    },
    setOrderTypes(state, action: PayloadAction<Array<{ ID: number; Description: string }>>) {
      state.orderTypes = action.payload;
    },
    setRegions(state, action: PayloadAction<{ ID: number; Description: string }[]>) {
      state.regions = action.payload;
    },
    setIsPreferenceChaged(state, action: PayloadAction<boolean>) {
      state.isPreferenceChanged = action.payload;
    },
    setConfirmationDialog(state, action: PayloadAction<IConfirmationDialog>) {
      state.confirmationDialog = action.payload;
    },
    setTabValue(state, action: PayloadAction<number>) {
      state.tabValue = action.payload;
    },
    setOrderBonusList(state, action: PayloadAction<any[]>) {
      state.orderBonusTab.orderBonusList = action.payload;
    },
    setOrderBonusShifts(state, action: PayloadAction<any[]>) {
      state.orderBonusTab.orderBonusShiftList = action.payload;
    },
    SetOrderUnitDetails(state, action: PayloadAction<number>) {
      state.orderUnitDetails = action.payload;
    },
    setUpdateOrderBonusShifts(state, action: PayloadAction<boolean>) {
      state.orderBonusTab.updateOrderBonusShift = action.payload;
    },
    setOrderBonusCalculatedAmount(
      state,
      action: PayloadAction<{ type: string; rate: number; returnValue: number; error: string }>,
    ) {
      state.orderBonusTab.OrderBonusCalculatedAmount = action.payload;
    },
    setEditOrderBonusModal(state, action: PayloadAction<{ isEdit: boolean; bonusId: number; rowData: any }>) {
      state.orderBonusTab.editBonusModal = action.payload;
    },
    setOrderBonusCreated(state, action: PayloadAction<boolean>) {
      state.orderBonusTab.isOrderBonusCreated = action.payload;
    },
    setCopyOrderBonus(state, action: PayloadAction<boolean>) {
      state.orderBonusTab.isOrderBonusCopied = action.payload;
    },
    setDeleteOrderBonus(state, action: PayloadAction<boolean>) {
      state.orderBonusTab.isOrderBonusDeleted = action.payload;
    },
    setOrderBonusChangeHistory(
      state,
      action: PayloadAction<{
        open: boolean;
        changeHistoryRows: any;
      }>,
    ) {
      state.orderBonusTab.changeHistoryModal = action.payload;
    },
    setSelectedSubCert(state, action) {
      state.selectedSubCert = action.payload;
    },
  },
});

export const { reducer: orderDataReducer, name: orderDataSliceKey } = orderDataSlice;

export const orderDataActions = {
  ...orderDataSlice.actions,
  ...serviceActions,
};
