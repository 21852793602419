import { amnMUITheme, Button, Container, Grid } from 'amn-ui-core';
import { trackEvent } from 'app-insights/appInsightsTracking';
import ErrorGremlin from 'app/assets/images/error-gremlin.jpg';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'amn-ui-core';
import styledComponent from 'styled-components';

const ContentContainer = styledComponent(Container)`
  align-items: center;
  background: white;
  display: flex;
  flex-direction: column;
  font-family: ${amnMUITheme.typography.fontFamily};
  margin-top: 5px;
`;

const StyledGrid = styledComponent(Grid)`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto !important;
  text-align: center;
`;

const StyledButton = styledComponent(Button)`
  max-width: 200px;
`;

const Gremlin = styledComponent.img`
  max-width: 100%;
`;

interface Props {
  onReturnToHomeClicked: () => void;
}

const ErrorBoundary: React.FC<Props> = props => {
  const { onReturnToHomeClicked } = props;
  const { t } = useTranslation();

  //App-Insight Tracking.
  React.useEffect(() => {
    trackEvent({ type: 'error', name: 'Gremlin', properties: { url: window.location.href } });
  }, []);

  return (
    <div data-testid="error-page">
      <ContentContainer maxWidth="xl">
        <Grid container>
          <StyledGrid item xs={12} lg={8}>
            <h2>{t('global.errorBoundary.header')}</h2>
            <p>{t('global.errorBoundary.support')}</p>
            <Link href="/" onClick={onReturnToHomeClicked}>
              <StyledButton variant="contained" color="secondary">
                {t('global.errorBoundary.button')}
              </StyledButton>
            </Link>
          </StyledGrid>
          <Grid item xs={12} lg={4}>
            <Gremlin src={ErrorGremlin} />
          </Grid>
        </Grid>
      </ContentContainer>
    </div>
  );
};

export default ErrorBoundary;
