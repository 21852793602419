import IconButton from '@mui/material/IconButton';
import { makeStyles } from 'tss-react/mui';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Badge, Grid, Typography, Theme } from 'amn-ui-core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styledComponent from 'styled-components';
import CallIcon from '../../../../app/assets/images/Notification/MinimizedModal/Call.png';
import EmailIcon from '../../../../app/assets/images/Notification/MinimizedModal/Email.png';
import SmsIcon from '../../../../app/assets/images/Notification/MinimizedModal/Sms.png';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { ChannelType } from '../Constants';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { SmsType } from 'app/enums/Sms';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    flexGrow: 1,
    cursor: 'all-scroll',
    padding: '4%',
  },
  badge: {
    position: 'fixed',
    color: theme.palette.framework.system.red,
    width: '33px',
  },
  unreadCount: {
    position: 'fixed',
    color: theme.palette.framework.system.red,
  },
  avatar: {
    margin: '2% 2% 2% 0',
    height: theme.spacing(8),
    width: theme.spacing(8),
    color: theme.palette.framework.system.white,
    fontSize: '30px',
    cursor: 'pointer',
  },
  actionIcon: {
    cursor: 'pointer',
    padding: '25px !important',
  },
}));

const Header = styledComponent(Typography)`
  text-align: left;
  font: normal normal medium Roboto;
  letter-spacing: 0px;
  color: #34b233;
  opacity: 1;
  color: #6638b7;
  font-size: 1.2rem;
  font-weight: 700;
  margin-left: 1%;
  margin-top: 1.75%;
  padding-top: 5%;
  text-overflow: ellipsis;
  font-size: 1rem;
  cursor: pointer;
`;

export function ToasterContent(props) {
  const { classes } = useStyles();
  const globalData = useSelector(notificationSelection);
  const dispatch = useDispatch();
  const { id, info, header, onClose, channel, isManualLog, type } = props;

  const handleMaximize = () => {
    switch (channel) {
      case ChannelType.email:
        dispatch(notificationDataActions.setEmailInteraction({ open: true, minimized: false }));
        break;
      case ChannelType.sms:
        if (type === SmsType.bulk) {
          dispatch(notificationDataActions.setSmsInteraction({ open: true, minimized: false, readonly: false }));
        } else {
          dispatch(notificationDataActions.setSmsInteraction({ open: true, minimized: false, readonly: false }));
          dispatch(notificationDataActions.updateUnreadCount(0));
        }
        break;
      case ChannelType.voice:
        isManualLog
          ? dispatch(notificationDataActions.setLogInteraction({ open: true, minimized: false }))
          : dispatch(
              notificationDataActions.setVoiceInteraction({ open: true, minimized: false, showDisposition: true }),
            );
        break;
    }
    toast.dismiss(id);
    dispatch(notificationDataActions.decrementActiveToaster());
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={2}
          lg={2}
          md={2}
          role="button"
          tabIndex={-1}
          onKeyPress={handleMaximize}
          onClick={handleMaximize}
        >
          <Avatar
            style={{
              background:
                info?.channel === ChannelType.voice && !info?.manualLog
                  ? '#6638b7'
                  : info?.channel === ChannelType.sms
                  ? '#34B233'
                  : info?.channel === ChannelType.email
                  ? '#006FB9'
                  : info?.channel === ChannelType.voice && info?.manualLog
                  ? '#008b95'
                  : '#34B233',
            }}
            className={classes.avatar}
          >
            {info?.channel === ChannelType.voice && !info?.manualLog ? (
              <img src={CallIcon} alt={`call`} />
            ) : info?.channel === ChannelType.sms ? (
              <Badge
                className={
                  (globalData?.sms?.activeUnreadCount !== undefined && globalData.sms.activeUnreadCount) > 99
                    ? classes.badge
                    : classes.unreadCount
                }
                variant="standard"
                invisible={globalData.sms.activeUnreadCount === 0 || globalData?.sms?.activeUnreadCount === undefined}
                max={99}
                badgeContent={globalData.sms.activeUnreadCount}
                color={'error'}
              >
                <img src={SmsIcon} alt={`sms`} />
              </Badge>
            ) : info?.channel === ChannelType.email ? (
              <img src={EmailIcon} alt={`email`} />
            ) : info?.channel === ChannelType.voice && info?.manualLog ? (
              <AddIcon fontSize="large" />
            ) : (
              info?.tos
                .find(Boolean)
                ?.name.split(' ')
                .map(n => n[0])
                .join('')
                .substring(0, 2)
                .replace(/[^a-zA-Z]/g, '')
            )}
          </Avatar>
        </Grid>
        <Grid item xs={8} style={{ textOverflow: 'ellipsis' }}>
          <Header
            role="button"
            tabIndex={-1}
            onKeyPress={handleMaximize}
            onClick={handleMaximize}
            variant="h6"
            style={{
              color:
                info?.channel === ChannelType.voice && !info?.manualLog
                  ? '#6638b7'
                  : info?.channel === ChannelType.sms
                  ? '#34B233'
                  : info?.channel === ChannelType.email
                  ? '#006FB9'
                  : info?.channel === ChannelType.voice && info?.manualLog
                  ? '#008b95'
                  : '#34B233',
            }}
          >
            {header}
          </Header>
        </Grid>
        <Grid item xs={2}>
          <IconButton aria-label="close" color="primary" className={classes.actionIcon} size="small">
            {info?.channel === ChannelType.voice && !info?.manualLog ? (
              <AspectRatioIcon onClick={handleMaximize} />
            ) : (
              <CloseIcon
                onClick={() => {
                  if (
                    info?.channel === ChannelType.sms &&
                    globalData.sms.data?.body?.length === 0 &&
                    globalData.sms.attachments === undefined &&
                    (globalData.sms.newNotes === undefined ||
                      globalData.sms.newNotes === '' ||
                      globalData.sms.notes === globalData.sms.newNotes)
                  ) {
                    dispatch(
                      notificationDataActions.setSmsInteraction({ open: false, minimized: false, readonly: false }),
                    );
                    toast.dismiss(id);
                  } else {
                    onClose(info?.channel, info?.manualLog);
                    handleMaximize();
                  }
                }}
              />
            )}
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
}
