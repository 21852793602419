import { TaskStatus, TaskStatusReason } from 'app/models/Tasks/Tasks';
import { ICoverageDetails } from 'app/models/UserCoverage/UserCoverage';

export const getRecipients = (details: any) => {
  const assigneeDetails = details?.assignedTo ?? details?.tos ?? [];
  const ccDetails = details?.ccValues ?? details?.ccs ?? details?.cc ?? [];
  const recipients = [...assigneeDetails, ...ccDetails];
  return recipients;
};

export const fetchRecipientStatus = (
  coverageList: ICoverageDetails[],
  coveredByRecipientUsers,
  activeUserId,
  status,
) => {
  return coverageList?.find(
    user =>
      user?.coveringEmployeeId === activeUserId &&
      user?.coveredEmployeeId === coveredByRecipientUsers?.id &&
      coveredByRecipientUsers?.statusId === status,
  );
};

export const checkIfActiveUserRecipient = (details: any, activeUserId: number) => {
  const recipients = getRecipients(details);
  return recipients?.some(user => user?.id === activeUserId);
};

export const isActiveUserCoveringCreatedBy = (activeCoverageList: ICoverageDetails[], createdByUserId) => {
  return activeCoverageList?.some(user => user.coveredEmployeeId === createdByUserId);
};

export const isActiveUserCoveringRecipients = (
  details: any,
  activeCoverageList: ICoverageDetails[],
  activeUserId: number,
) => {
  const recipients = getRecipients(details);
  if (activeCoverageList?.length > 0) {
    const coveredByRecipientUsers = activeCoverageList?.filter(coverage =>
      recipients.some(user => user.id === coverage.coveredEmployeeId),
    );
    if (coveredByRecipientUsers?.length > 0) {
      return coveredByRecipientUsers?.some(user => user?.coveringEmployeeId === activeUserId);
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const isRecipientHsg = (details: any, hsgId: number) => {
  const recipients = getRecipients(details);
  return recipients?.some(user => user?.id === hsgId);
};

export const fetchRecipientDetails = (details, userId) => {
  const recipients = getRecipients(details);
  const isuserRecipient = recipients?.some(recipient => recipient?.id === userId);
  if (isuserRecipient) {
    return recipients?.find(recipient => recipient?.id === userId);
  }
  if (details?.createdby?.id === userId) {
    return {
      firstName: details?.createdBy?.firstname,
      id: userId,
      lastName: details?.createdby?.lastname,
      statusId: 2,
    };
  }
};

export const isUserOnlyRecipientNotCovering = (
  details: any,
  coverageList: ICoverageDetails[],
  activeUserId: number,
) => {
  const recipients = getRecipients(details);
  const hasCurrentUser = recipients.some(employee => employee.id === activeUserId);
  const noneMatched = !coverageList?.some(
    user =>
      user?.coveringEmployeeId === activeUserId && recipients.some(employee => employee.id === user.coveredEmployeeId),
  );
  return hasCurrentUser && noneMatched;
};

export const fetchCoverageRecipientUserDetails = (
  details: any,
  activeCoverageList: ICoverageDetails[],
  activeUserId: number,
) => {
  const recipients = getRecipients(details);
  const sortedRecipientsArray = recipients.slice().sort((a, b) => a?.statusId - b?.statusId);

  const coveredByRecipientUsers = sortedRecipientsArray?.find(user =>
    activeCoverageList.some(coverage => user.id === coverage.coveredEmployeeId),
  );
  const activeRecipientUser = sortedRecipientsArray?.find(user => user?.id === activeUserId);
  const coverForCreatedbyUser =
    activeCoverageList.find(coverage => details?.createdBy.id === coverage.coveredEmployeeId) ||
    details?.createdBy.id === activeUserId;
  if (coveredByRecipientUsers) {
    const newStatusRecipientUser = fetchRecipientStatus(
      activeCoverageList,
      coveredByRecipientUsers,
      activeUserId,
      TaskStatus.New,
    );
    const pendingStatusRecipientUser = fetchRecipientStatus(
      activeCoverageList,
      coveredByRecipientUsers,
      activeUserId,
      TaskStatus.Pending,
    );
    const completedStatusRecipientUser = fetchRecipientStatus(
      activeCoverageList,
      coveredByRecipientUsers,
      activeUserId,
      TaskStatus.Completed,
    );
    const cancelledStatusRecipientUser = fetchRecipientStatus(
      activeCoverageList,
      coveredByRecipientUsers,
      activeUserId,
      TaskStatus.Cancelled,
    );
    if (newStatusRecipientUser) {
      const newStatusUser = recipients?.find(user => user?.id === newStatusRecipientUser?.coveredEmployeeId);
      return activeRecipientUser && activeRecipientUser?.statusId < newStatusUser?.statusId
        ? activeRecipientUser
        : newStatusUser;
    } else if (pendingStatusRecipientUser) {
      const pendingStatusUser = recipients?.find(user => user?.id === pendingStatusRecipientUser?.coveredEmployeeId);
      return activeRecipientUser && activeRecipientUser?.statusId < pendingStatusUser?.statusId
        ? activeRecipientUser
        : pendingStatusUser;
    } else if (completedStatusRecipientUser) {
      const completedStatusUser = recipients?.find(
        user => user?.id === completedStatusRecipientUser?.coveredEmployeeId,
      );
      return activeRecipientUser && activeRecipientUser?.statusId < completedStatusUser?.statusId
        ? activeRecipientUser
        : completedStatusUser;
    } else if (cancelledStatusRecipientUser) {
      const cancelledStatusUser = recipients?.find(
        user => user?.id === cancelledStatusRecipientUser?.coveredEmployeeId,
      );
      return activeRecipientUser && activeRecipientUser?.statusId < cancelledStatusUser?.statusId
        ? activeRecipientUser
        : cancelledStatusUser;
    } else {
      return null;
    }
  } else if (coverForCreatedbyUser) {
    const isCreatedInError = details?.taskStatusDetails?.some(
      data => data?.statusUpdateReasonId === TaskStatusReason.ERROR,
    );
    const allAssignedToIdsPresent = recipients.every(assignedToItem =>
      details?.taskStatusDetails?.some(recipientItem => recipientItem.recipientId === assignedToItem.assignedToId),
    );
    let statusId = 2;
    if (allAssignedToIdsPresent) {
      if (details?.taskStatusDetails?.some(data => data?.statusId === TaskStatus.Cancelled)) {
        statusId = 4;
      } else if (details?.taskStatusDetails?.some(data => data?.statusId === TaskStatus.Completed)) {
        statusId = 3;
      } else {
        statusId = 2;
      }
    }
    return {
      firstName: details?.createdBy?.firstname,
      id: activeUserId,
      lastName: details?.createdby?.lastname,
      statusId: isCreatedInError ? 4 : statusId,
    };
  } else {
    return null;
  }
};

export const FetchStatusReason = (details: any, activeCoverageList: ICoverageDetails[], activeUserId: number) => {
  const recipients = getRecipients(details);
  const sortedRecipientsArray = recipients.slice().sort((a, b) => a?.statusId - b?.statusId);
  const activeRecipientUser = sortedRecipientsArray?.find(user => user?.id === activeUserId);
  const coveredByRecipientUsers = sortedRecipientsArray?.find(user =>
    activeCoverageList.some(coverage => user.id === coverage.coveredEmployeeId),
  );
  if (coveredByRecipientUsers) {
    const newStatusRecipientUser = fetchRecipientStatus(
      activeCoverageList,
      coveredByRecipientUsers,
      activeUserId,
      TaskStatus.New,
    );
    const pendingStatusRecipientUser = fetchRecipientStatus(
      activeCoverageList,
      coveredByRecipientUsers,
      activeUserId,
      TaskStatus.Pending,
    );
    const completedStatusRecipientUser = fetchRecipientStatus(
      activeCoverageList,
      coveredByRecipientUsers,
      activeUserId,
      TaskStatus.Completed,
    );
    const cancelledStatusRecipientUser = fetchRecipientStatus(
      activeCoverageList,
      coveredByRecipientUsers,
      activeUserId,
      TaskStatus.Cancelled,
    );
    if (newStatusRecipientUser) {
      const newStatusUser = recipients?.find(user => user?.id === newStatusRecipientUser?.coveredEmployeeId);
      const data = {
        statusId:
          activeRecipientUser && activeRecipientUser?.statusId < newStatusUser?.statusId
            ? activeRecipientUser?.statusId
            : newStatusUser?.statusId,
        reason: details?.taskCategory,
      };
      return data;
    } else if (pendingStatusRecipientUser) {
      const pendingStatusUser = recipients?.find(user => user?.id === pendingStatusRecipientUser?.coveredEmployeeId);

      const data = {
        statusId:
          activeRecipientUser && activeRecipientUser?.statusId < pendingStatusUser?.statusId
            ? activeRecipientUser?.statusId
            : pendingStatusUser?.statusId,
        reason: details?.taskCategory,
      };
      return data;
    } else if (completedStatusRecipientUser) {
      const completeStatusUser = recipients?.find(user => user?.id === completedStatusRecipientUser?.coveredEmployeeId);

      const completedUserDetails = details?.taskStatusDetails?.find(
        record => record?.statusId === TaskStatus.Completed && record?.recipientId === completeStatusUser?.id,
      );

      const data =
        activeRecipientUser && activeRecipientUser?.statusId < completedUserDetails?.statusId
          ? {
              statusId: activeRecipientUser?.statusId,
              reason: details?.taskCategory,
            }
          : {
              statusId: completedUserDetails?.statusId,
              reason: `${completedUserDetails?.statusUpdateReason} - ${details?.taskCategory}`,
            };
      return data;
    } else if (cancelledStatusRecipientUser) {
      const cancelledStatusUser = recipients?.find(
        user => user?.id === cancelledStatusRecipientUser?.coveredEmployeeId,
      );

      const cancelledUserDetails = details?.taskStatusDetails?.find(
        record => record?.statusId === TaskStatus.Cancelled && record?.recipientId === cancelledStatusUser?.id,
      );
      if (activeRecipientUser) {
        if (activeRecipientUser?.statusId < cancelledUserDetails?.statusId) {
          if (
            activeRecipientUser?.statusId === TaskStatus.New ||
            activeRecipientUser?.statusId === TaskStatus.Pending
          ) {
            return {
              statusId: activeRecipientUser?.statusId,
              reason: details?.taskCategory,
            };
          } else {
            const completedUserDetails = details?.taskStatusDetails?.find(
              record => record?.statusId === TaskStatus.Completed && record?.recipientId === activeRecipientUser?.id,
            );
            return {
              statusId: completedUserDetails?.statusId,
              reason: `${completedUserDetails?.statusUpdateReason} - ${details?.taskCategory}`,
            };
          }
        }
      } else {
        const data = {
          statusId: cancelledUserDetails?.statusId,
          reason: `${cancelledUserDetails?.statusUpdateReason} - ${details?.taskCategory}`,
        };
        return data;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const CheckCancelledOrCompletedStatus = (recipients: any, details: any) => {
  let data = {
    statusId: TaskStatus.Pending,
    reason: details?.taskCategory,
  };
  const isCreatedInError = details?.taskStatusDetails?.some(
    userData => userData?.statusUpdateReasonId === TaskStatusReason.ERROR,
  );
  const allAssignedToIdsCompletedOrCancelled = recipients.every(
    assignedToItem =>
      assignedToItem?.statusId === TaskStatus.Cancelled || assignedToItem?.statusId === TaskStatus.Completed,
  );
  if (isCreatedInError) {
    const cancelledUserDetails = details?.taskStatusDetails?.find(
      statusData => statusData?.statusUpdateReasonId === TaskStatusReason.ERROR,
    );
    data = {
      statusId: TaskStatus.Cancelled,
      reason: `${cancelledUserDetails?.statusUpdateReason} - ${details?.taskCategory}`,
    };
  } else {
    if (allAssignedToIdsCompletedOrCancelled) {
      if (details?.taskStatusDetails?.some(tsd => tsd?.statusId === TaskStatus.Cancelled)) {
        const cancelledUserDetails = details?.taskStatusDetails?.find(tsd => tsd?.statusId === TaskStatus.Cancelled);
        data = {
          statusId: TaskStatus.Cancelled,
          reason: `${cancelledUserDetails?.statusUpdateReason} - ${details?.taskCategory}`,
        };
      } else if (details?.taskStatusDetails?.some(tsd => tsd?.statusId === TaskStatus.Completed)) {
        const completedUserDetails = details?.taskStatusDetails?.find(tsd => tsd?.statusId === TaskStatus.Completed);
        data = {
          statusId: TaskStatus.Completed,
          reason: `${completedUserDetails?.statusUpdateReason} - ${details?.taskCategory}`,
        };
      } else {
        data = {
          statusId: TaskStatus.Pending,
          reason: details?.taskCategory,
        };
      }
    }
  }
  return data;
};

export const checkCreatedByUserStatusEligibility = (
  details: any,
  activeCoverageList: ICoverageDetails[],
  activeUserId: number,
) => {
  const recipients = getRecipients(details);
  const coverForCreatedbyUser =
    activeCoverageList.find(coverage => details?.createdBy.id === coverage.coveredEmployeeId) ||
    details?.createdBy.id === activeUserId;

  if (coverForCreatedbyUser) {
    return CheckCancelledOrCompletedStatus(recipients, details);
  } else return null;
};

export const checkOtherUserStatus = details => {
  const recipients = getRecipients(details);
  return CheckCancelledOrCompletedStatus(recipients, details);
};

export const isAssigneeStatusCompletedCancelled = (details: any, activeUserId: number) => {
  const recipients = getRecipients(details);
  const matchingUsers = recipients.filter(user => user.id === activeUserId);
  if (matchingUsers.length > 0) {
    return matchingUsers.every(user => user.statusId >= 3);
  }

  return false;
};
