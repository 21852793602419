import { InterestDate } from './utils';
import { getDateValue } from '../../../../../utils/dates/dateExtraction';
const canIncludeDateInPayload = interestDate => {
  if (interestDate === InterestDate[1].value) return false;
  return true;
};

export const getFilter = (formData, dirtyFields, filterSourceData) => {
  if (!formData) return undefined;
  return {
    orderStatus: dirtyFields.orderStatus ? formData.orderStatus : undefined,
    fromDate:
      (dirtyFields.interestDate || dirtyFields.fromDate || dirtyFields.toDate) &&
      canIncludeDateInPayload(formData.interestDate) &&
      formData.fromDate
        ? formData.fromDate
        : undefined,
    toDate:
      (dirtyFields.interestDate || dirtyFields.fromDate || dirtyFields.toDate) &&
      canIncludeDateInPayload(formData.interestDate)
        ? formData.toDate
          ? formData.toDate
          : getDateValue(0, true)
        : undefined,
    states: dirtyFields.states && !formData.states.includes('All') ? formData.states : [],
    skillSets:
      dirtyFields.skillSets && !formData.skillSets.includes('All')
        ? formData.skillSets.reduce((resultArray, skill) => {
            const skillSet = skill.split(' ', 2);
            const skillSetobj = filterSourceData?.skillSets?.find(
              item => skillSet[0] === item.disciplineAbbr && skillSet[1] === item.specialtyAbbr,
            );
            if (skillSetobj) resultArray.push(skillSetobj);
            return resultArray;
          }, [])
        : [],
  };
};
