import {
  deriveGWWDropdownData,
  deriveMileageValues,
} from '@AMIEWEB/Order/OrderCreationForm/RateCard/BillRates/helper/rateElementsTable';
import { deriveShiftsTable } from '@AMIEWEB/Order/OrderCreationForm/RateCard/BillRates/helper/rateShiftsTable';
import { getRefactoredData } from '@AMIEWEB/Order/OrderCreationForm/RateCard/OffContractRateElementTable/helper';
import {
  ContractType,
  RateElemenetSource,
  RateElements,
  deriveFieldName,
  isNewRateElementRequired,
  refactorValue,
  removeRateElements,
} from '@AMIEWEB/Order/OrderCreationForm/RateCard/helper';
import { selectOrderDetailsUIView } from '@AMIEWEB/Order/Store/Order.selectors';
import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import shiftNumberList from 'app/components/Order/OrderCreationForm/RateCard/common/shifts.json';
import { missingField } from 'app/constants';
import { ExceptionType } from 'app/enums/Common';
import { Status } from 'app/enums/Facility';
import { OrderService } from 'app/services/OrderServices/order-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import _ from 'lodash';
import moment from 'moment';
import { selectUser } from 'oidc/user.selectors';
import {
  manuallyDecrementPromiseCounter,
  manuallyIncrementPromiseCounter,
  manuallyResetPromiseCounter,
} from 'react-promise-tracker';
import uuid from 'react-uuid';
import { call, getContext, put, select } from 'redux-saga/effects';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { requestContractDetails, requestOffContractRateElements } from './saga';
import {
  selectContractDetails,
  selectCreateOrderMiscellaneousData,
  selectCreateOrderOcbr,
  selectOcbrDetails,
  selectOcbrSavedDetails,
  selectRateOpts,
} from './selector';
import { createOrderOcbrActions, initialState } from './slice';
import { IBillRates, IContractDetailsRequest } from './types';
import {
  getDecimalValue,
  getDefaultGwwMileageRate,
  getDefaultTravelBillRates,
  getDisciplineName,
  getRateElementName,
  getRateValue,
  getShift,
  getSpecialtyName,
  getStatusName,
  getSubSpecialtyName,
  isOrderEffectiveDatesValid,
} from './utils';

const deriveKey = (shift, isFlatRate, skillSet = null) =>
  customSwitch({
    [RateElements.TravelBillRate]: deriveFieldName([
      skillSet?.disciplineAbbreviation,
      skillSet?.specialtyName,
      skillSet?.subSpecialtyName,
      'travel',
      //isFlatRate ? null : shift,
      shift,
    ]),
    [RateElements.LocalBillRate]: deriveFieldName([
      skillSet?.disciplineAbbreviation === missingField ? '' : skillSet?.disciplineAbbreviation,
      skillSet?.specialtyName === missingField ? '' : skillSet?.specialtyName,
      skillSet?.subSpecialtyName === missingField ? '' : skillSet?.subSpecialtyName,
      'local',
      // isFlatRate ? null : shift,
      shift,
    ]),
    [RateElements.GWW]: deriveFieldName(['gww', shift]),
    [RateElements.ExpectedHours]: deriveFieldName(['expected-hours', shift]),
    [RateElements.MileageReimbursement]: 'mileageReimburesement',
  })('error');

export function* deriveRateSelections(action: PayloadAction<{ isFlatRate: boolean; billRates: any[] }>) {
  try {
    // const filteredBillRates = action.payload.billRates.filter(billRate => billRate.statusId != Status.Unsaved && billRate.source != 3);

    const filteredBillRates = action.payload.billRates.filter(billRate => billRate.isExtra != true);

    const billRateSelections = filteredBillRates.reduce((result, item) => {
      // if(item.statusId != Status.Unsaved)
      // {
      const value = item.billRate.isRange
        ? {
            min: { value: refactorValue(item.billRate.value?.min.toString(), getDecimalValue()(item.type)) },
            max: { value: refactorValue(item.billRate.value?.max.toString(), getDecimalValue()(item.type)) },
            rateElementId: item.rateElementId,
          }
        : {
            value: refactorValue(item.billRate.value.toString(), getDecimalValue()(item.type)),
            rateElementId: item.rateElementId,
          };
      // const ocbr = item.statusId ?? ContractType.OnContract;
      const ocbr = item.statusId ?? ContractType.OnContract;
      const shift = shiftNumberList.find(x => x.shiftId === item.shiftId)?.shiftName;
      if (item.billRate.isRange && value.max.value === 'NaN') {
        value.max.value = '';
      }
      if (item.billRate.isRange && value.min.value === 'NaN') {
        value.min.value = '';
      }
      return {
        ...result,
        [deriveKey(shift, action.payload.isFlatRate, {
          disciplineAbbreviation: item?.disciplineAbbreviation?.trim(),
          specialtyName: item?.specialtyName?.trim(),
          subSpecialtyName: item?.subSpecialtyName?.trim(),
        })(item.type)]: { value, ocbr },
      };
      // }
    }, {});
    //  var BR = billRateSelections.filter(e => e.ocbr != Status.Unsaved);
    yield put(createOrderOcbrActions.setBillRateSelection(billRateSelections));
  } catch {}
}

export function* initiateEditRateCard(action: PayloadAction<{ orderId: number; facilityId: number; uniqueShifts }>) {
  try {
    manuallyIncrementPromiseCounter('rate-card-initiation');
    const orderService: OrderService = yield getContext('orderService');

    // yield call(requestCreateOrderMiscellaneousData, { type: createOrderOcbrActions.getMiscellaneousData.type, payload: {} });
    const response = yield call(orderService.getEditableRateElements, action.payload.orderId);
    if (httpSuccess(response.status)) {
      const {
        isFlatRate,
        rateElements,
        effectiveDates,
        skillSet,
        // shifts,
        groupedRateElements,
      } = response.data;

      yield put(createOrderOcbrActions.setOcbrFlateRate(isFlatRate));
      yield put(createOrderOcbrActions.setOcbrSavedFlateRate(isFlatRate));
      yield put(
        createOrderOcbrActions.setOcbrEffectiveDate({
          startDate: effectiveDates?.orderRateEffectiveStartDate,
          endDate: effectiveDates?.orderRateEffectiveEndDate,
        }),
      );
      yield put(
        createOrderOcbrActions.setOcbrSavedEffectiveDate({
          startDate: effectiveDates?.orderRateEffectiveStartDate,
          endDate: effectiveDates?.orderRateEffectiveEndDate,
        }),
      );

      const billRates = !!rateElements
        ? rateElements.map(item => ({
            ...item,
            type: item.rateElementNameId,
            isOcbr: item.isOffContract,
            billRate: {
              isRange: item.valueMax !== null,
              value:
                item.valueMax !== null
                  ? {
                      min: refactorValue(item.value.toString(), getDecimalValue()(item.rateElementNameId)),
                      max: refactorValue(item.valueMax, getDecimalValue()(item.rateElementNameId)),
                    }
                  : refactorValue(item.value, getDecimalValue()(item.rateElementNameId)),
            },
          }))
        : [];

      // @ts-ignore
      const { orderDetails } = yield select(selectOrderDetailsUIView);

      yield call(requestOffContractRateElements, {
        type: createOrderOcbrActions.getOffContractRateElements.type,
        payload: { facilityId: action.payload.facilityId },
      });
      yield call(requestContractDetails, {
        type: createOrderOcbrActions.getContractDetailsAction.type,
        payload: {
          facilityId: action.payload.facilityId,
          referentialDate: moment().format('yyyy-MM-DDTHH:mm:ss'),
          shifts: !!orderDetails.shifts ? orderDetails.shifts.map(x => x.id) : [],
          skillSets: !!skillSet
            ? skillSet.map(item => ({
                ...item,
                subSpecialtyId: item.subSpecialtyId ?? 0,
              }))
            : [],
        },
      });
      yield put(
        createOrderOcbrActions.setFeatureAttributes({
          key: 'showLocalBillRate',
          data: groupedRateElements?.localBillRate?.length > 0 || false,
        }),
      );

      yield put(createOrderOcbrActions.setOcbrSavedBillRates(billRates));

      yield call(deriveBillRatesTable);
      yield call(deriveGwwRateTable);
      yield call(deriveMileageRateTable);
      yield call(updateBillRates, {
        type: createOrderOcbrActions.business_updateBillRates.type,
        payload: { billRates },
      });

      let billRateEdit = [];
      billRateEdit = [...billRateEdit, ...billRates];
      const { billRatesTable } = yield select(selectRateOpts);

      if (isFlatRate) {
        var travelBillRates = billRates.filter(e => e.type == RateElements.TravelBillRate);

        billRatesTable.forEach(skillset => {
          //Travel Bill Rate
          const highestTravel = travelBillRates.reduce(function (highestBr, br) {
            if (
              (br.disciplineId ?? 0) === (skillset.disciplineId ?? 0) &&
              (br.specialtyId ?? 0) === (skillset.specialtyId ?? 0) &&
              (br.subSpecialtyId ?? 0) === (skillSet.subSpecialtyId ?? 0) &&
              br.value > highestBr.value
            ) {
              return br;
            }
            return highestBr;
          }, travelBillRates[0]);

          if (highestTravel != null) {
            const temp = {
              offContractId: highestTravel.offContractId ? highestTravel.offContractId : null,
              rateElementId: highestTravel.rateElementId ? highestTravel.rateElementId : uuid(),
              disciplineAbbreviation: highestTravel.disciplineAbbreviation,
              disciplineId: highestTravel.disciplineId,
              specialtyId: highestTravel.specialtyId,
              specialtyName: highestTravel.specialtyName,
              subSpecialtyId: highestTravel.subSpecialtyId,
              subSpecialtyName: highestTravel.subSpecialtyName,
              shiftId: null,
              shift: null,
              type: RateElements.TravelBillRate,
              billRate: highestTravel.billRate,
              isOcbr: highestTravel.isOcbr,
              statusId: highestTravel.statusId,
              createdAt: highestTravel.createdAt,
              createdBy: highestTravel.createdBy,
              reason: highestTravel.reason,
              effectiveStartDate: highestTravel?.effectiveStartDate,
              effectiveEndDate: highestTravel?.effectiveEndDate,
              lastUpdatedBy: highestTravel.lastUpdatedBy,
              dateLastUpdated: highestTravel?.dateLastUpdated,
              dateCreated: highestTravel?.dateCreated,
              source: highestTravel?.source,
              documentIds: highestTravel?.documentIds,
            };

            billRateEdit.push(temp);
          }

          //Local Bill Rate

          var localBillRates = billRates.filter(e => e.type == RateElements.LocalBillRate);

          const highestLocal = localBillRates?.reduce(function (highestBr, br) {
            if (
              (br.disciplineId ?? 0) === (skillset.disciplineId ?? 0) &&
              (br.specialtyId ?? 0) === (skillset.specialtyId ?? 0) &&
              (br.subSpecialtyId ?? 0) === (skillSet.subSpecialtyId ?? 0) &&
              br.value > highestBr.value
            ) {
              return br;
            }
            return highestBr;
          }, localBillRates[0]);

          if (highestLocal != null) {
            const tempLocal = {
              offContractId: highestLocal.offContractId ? highestLocal.offContractId : null,
              rateElementId: highestLocal.rateElementId ? highestLocal.rateElementId : uuid(),
              disciplineAbbreviation: highestLocal.disciplineAbbreviation,
              disciplineId: highestLocal.disciplineId,
              specialtyId: highestLocal.specialtyId,
              specialtyName: highestLocal.specialtyName,
              subSpecialtyId: highestLocal.subSpecialtyId,
              subSpecialtyName: highestLocal.subSpecialtyName,
              shiftId: null,
              shift: null,
              type: RateElements.LocalBillRate,
              billRate: highestLocal.billRate,
              isOcbr: highestLocal.isOcbr,
              statusId: highestLocal.statusId,
              createdAt: highestLocal.createdAt,
              createdBy: highestLocal.createdBy,
              reason: highestLocal.reason,
              effectiveStartDate: highestLocal?.effectiveStartDate,
              effectiveEndDate: highestLocal?.effectiveEndDate,
              lastUpdatedBy: highestLocal.lastUpdatedBy,
              dateLastUpdated: highestLocal?.dateLastUpdated,
              dateCreated: highestLocal?.dateCreated,
              source: highestLocal?.source,
              documentIds: highestLocal?.documentIds,
            };

            billRateEdit.push(tempLocal);
          }
        });
      }

      yield call(deriveRateSelections, {
        payload: { billRates: billRateEdit, isFlatRate },
        type: createOrderOcbrActions.business_deriveRateSelections.type,
      });

      /** source data for determing edited changes */
      const source = yield select(selectOcbrDetails);
      yield put(createOrderOcbrActions.setOcbrDetailsSource(source));

      manuallyDecrementPromiseCounter('rate-card-initiation');
    }
  } catch (error) {
    manuallyResetPromiseCounter('rate-card-initiation');
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'initiateEditRateCard',
        area: 'store.redux-store.create-order-ocbr.business-saga.ts',
      },
    });
  }
}

export function* initiateRateCard(action: PayloadAction<IContractDetailsRequest & { uniqueShifts }>) {
  try {
    manuallyIncrementPromiseCounter('rate-card-initiation');
    if (action.payload.uniqueShifts.length > 1) {
      yield put(createOrderOcbrActions.setOcbrFlateRate(true));
      yield put(createOrderOcbrActions.setOcbrSavedFlateRate(true));
    }
    yield call(requestOffContractRateElements, {
      type: createOrderOcbrActions.getOffContractRateElements.type,
      payload: { facilityId: action.payload.facilityId },
    });
    yield call(requestContractDetails, {
      type: createOrderOcbrActions.getContractDetailsAction.type,
      payload: action.payload,
    });
    yield put(
      createOrderOcbrActions.setOcbrEffectiveDate({
        startDate: initialState.ocbrDetails.effectiveDates.startDate,
        endDate: initialState.ocbrDetails.effectiveDates.endDate,
      }),
    );
    yield put(
      createOrderOcbrActions.setOcbrSavedEffectiveDate({
        startDate: initialState.ocbrDetails.effectiveDates.startDate,
        endDate: initialState.ocbrDetails.effectiveDates.endDate,
      }),
    );
    yield call(deriveBillRatesTable);
    yield call(deriveGwwRateTable);
    yield call(deriveMileageRateTable);

    //Setting default values
    yield call(setDefaultTravelRate);
    yield call(setDefaultGwwMileageRate);
    const {
      ocbrDetails: { billRates, isFlatRate },
    } = yield select(selectCreateOrderOcbr);
    yield call(deriveRateSelections, {
      payload: { billRates, isFlatRate },
      type: createOrderOcbrActions.business_deriveRateSelections.type,
    });
    manuallyDecrementPromiseCounter('rate-card-initiation');
  } catch (error) {
    manuallyResetPromiseCounter('rate-card-initiation');
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'initiateRateCard',
        area: 'store.redux-store.create-order-ocbr.business-saga.ts',
      },
    });
  }
}

export function* updateBillRates(action: PayloadAction<{ billRates: IBillRates[] }>) {
  try {
    yield put(createOrderOcbrActions.setOcberBillRates(action.payload.billRates));
    const newBillRates = _.cloneDeep(action.payload.billRates);
    const offContarctDetails = (newBillRates ?? []).filter(
      item =>
        (item?.type === RateElements.TravelBillRate ||
          item?.type === RateElements.LocalBillRate ||
          item?.type === RateElements.GWW ||
          item?.type === RateElements.MileageReimbursement) &&
        item?.isOcbr === true,
    );

    const { isFlatRate } = yield select(selectOcbrDetails);
    const filteredOcbrRateEle =
      isFlatRate !== null
        ? offContarctDetails.filter(rateEle => {
            if (
              (rateEle.type === RateElements.TravelBillRate && rateEle.shiftId !== null) ||
              (rateEle.type === RateElements.LocalBillRate && rateEle.shiftId !== null) ||
              (rateEle.type !== RateElements.TravelBillRate && rateEle.type !== RateElements.LocalBillRate)
            ) {
              return true;
            }
          })
        : isFlatRate === null
        ? offContarctDetails
        : [];
    const ocbrRefactoredData = getRefactoredData(filteredOcbrRateEle);
    const { disciplineOpts, specialtyOpts, subSpecialtyOpts } = yield select(selectCreateOrderMiscellaneousData);

    yield put(
      createOrderOcbrActions.setRateCardTableRows({
        data: ocbrRefactoredData.map((item, index) => ({
          ...item,
          rateElementName: getRateElementName(item.rateElementNameId),
          status: getStatusName(item.statusId),
          discipline: getDisciplineName(item.disciplineId, disciplineOpts),
          specialty: getSpecialtyName(item.specialtyId, specialtyOpts),
          subSpecialty: getSubSpecialtyName(item.subSpecialtyId, subSpecialtyOpts),
          rateValue: getRateValue(item.value, item.valueMax, item.rateElementNameId),
          shift: getShift(item.shiftId),
          facilityId: item.facilityId,
          documentIds: item?.documentIds,
          id: index + 1,
          reason: item.reason ?? missingField,
        })),
      }),
    );

    // var BR = action.payload.billRates.filter(b => b.statusId != Status.Unsaved)
    // yield call(deriveRateSelections, {
    //   payload: { BR, isFlatRate },
    //   type: createOrderOcbrActions.business_deriveRateSelections.type,
    // });
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'updateBillRates',
        area: 'store.redux-store.create-order-ocbr.business-saga.ts',
      },
    });
  }
}

export function* uploadDocuments(action: PayloadAction<{ tableRows: any }>) {
  try {
    yield put(createOrderOcbrActions.setRateCardTableRows({ data: action.payload.tableRows }));
    const { billRates } = yield select(selectOcbrDetails);

    const newBillRates = billRates.map(billRate => {
      const rateCard = action.payload.tableRows.find(rateCard => rateCard.rateElementId === billRate.rateElementId);
      return !!rateCard ? { ...billRate, documentIds: rateCard.documentIds } : billRate;
    });

    yield put(createOrderOcbrActions.setOcberBillRates(newBillRates));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'uploadDocuments',
        area: 'store.redux-store.create-order-ocbr.business-saga.ts',
      },
    });
  }
}

export function* resetDocuments() {
  try {
    yield put(createOrderOcbrActions.resetDocContainer());
    const { billRates } = yield select(selectOcbrDetails);
    const newBillRates = billRates.map(billRate => ({
      ...billRate,
      documentIds: [],
    }));
    yield put(createOrderOcbrActions.setOcberBillRates(newBillRates));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'resetDocuments',
        area: 'store.redux-store.create-order-ocbr.business-saga.ts',
      },
    });
  }
}

export function* setDefaultTravelRate() {
  try {
    const {
      ocbrDetails: { effectiveDates, isFlatRate, billRates },
      featureProps: { uniqueShifts },
    } = yield select(selectCreateOrderOcbr);
    const contractDetails = yield select(selectContractDetails);
    const user = yield select(selectUser);
    const { billRatesTable } = yield select(selectRateOpts);
    const filteredBillRates: IBillRates[] = billRates.filter(
      item => item.type !== RateElements.TravelBillRate && item.type !== RateElements.LocalBillRate,
    );
    // const flatRate = isFlatRate === FlatRateStatus.ON ? true : false;

    const defaultTravelBillRate = getDefaultTravelBillRates(
      billRatesTable,
      uniqueShifts,
      effectiveDates,
      contractDetails,
      user,
      isFlatRate,
    );
    const updatedBillrate = [...filteredBillRates, ...defaultTravelBillRate];
    yield put(createOrderOcbrActions.setOcberBillRates(updatedBillrate));
    //Updates the bill rate table with default values
    yield call(updateBillRates, {
      type: createOrderOcbrActions.business_updateBillRates.type,
      payload: { billRates: updatedBillrate },
    });
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'setDefaultTravelRate',
        area: 'store.redux-store.create-order-ocbr.business-saga.ts',
      },
    });
  }
}

export function* setDefaultGwwMileageRate() {
  try {
    const contractDetails = yield select(selectContractDetails);
    const {
      ocbrDetails: { effectiveDates, billRates },
      featureProps: { skillsets, uniqueShifts },
    } = yield select(selectCreateOrderOcbr);
    const user = yield select(selectUser);
    const filteredBillRates: IBillRates[] = billRates.filter(
      item => item.type !== RateElements.GWW && item.type !== RateElements.MileageReimbursement,
    );

    const defaultGwwMileageRate = getDefaultGwwMileageRate(
      contractDetails,
      uniqueShifts,
      effectiveDates,
      user,
      skillsets,
    );
    const updatedBillrate = [...filteredBillRates, ...defaultGwwMileageRate];
    yield put(createOrderOcbrActions.setOcberBillRates(updatedBillrate));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'setDefaultGwwMileageRate',
        area: 'store.redux-store.create-order-ocbr.business-saga.ts',
      },
    });
  }
}

export function* updateEffectiveDates(action: PayloadAction<{ startDate; endDate }>) {
  try {
    yield put(createOrderOcbrActions.setOcbrEffectiveDate(action.payload));
    const isValidated = isOrderEffectiveDatesValid(action.payload.startDate, action.payload.endDate);
    yield put(createOrderOcbrActions.setRateCardValidationError(isValidated));

    var { billRates: ocbrDetails, effectiveDates: ocbrEffectiveDates, isFlatRate } = yield select(selectOcbrDetails);

    var { billRates: ocbrSavedDetails } = yield select(selectOcbrSavedDetails);

    ocbrDetails = ocbrSavedDetails;

    const billRates = [];
    ocbrDetails.forEach(bR => {
      const isExtraRateEleRequired = isNewRateElementRequired(ocbrEffectiveDates?.endDate, bR.effectiveEndDate);

      const removeRateEle = removeRateElements(ocbrEffectiveDates?.startDate, bR.effectiveStartDate);

      const orderEndDate = ocbrEffectiveDates?.endDate ? moment(ocbrEffectiveDates.endDate).format() : null;
      const orderStartDate = moment(bR.effectiveEndDate).add(1, 'days').format();
      if (removeRateEle) {
        billRates.push({
          ...bR,
          offContractId: null,
          rateElementId: uuid(),
          statusId: Status.Unsaved,
          effectiveStartDate: ocbrEffectiveDates?.startDate,
          effectiveEndDate: ocbrEffectiveDates?.endDate,
          source: RateElemenetSource.Order,
          createdAt: null,
          documentIds: [],
          reason: missingField,
        });
      } else {
        if (isExtraRateEleRequired) {
          billRates.push(bR);

          billRates.push({
            ...bR,
            offContractId: null,
            rateElementId: uuid(),
            statusId: Status.Unsaved,
            effectiveStartDate: orderStartDate,
            effectiveEndDate: orderEndDate,
            source: RateElemenetSource.Order,
            createdAt: null,
            documentIds: [],
            reason: missingField,
          });
        } else {
          billRates.push(bR);
        }
      }
    });

    yield call(updateBillRates, {
      type: createOrderOcbrActions.business_updateBillRates.type,
      payload: { billRates },
    });

    yield call(deriveBillRatesTable);
    yield call(deriveGwwRateTable);
    yield call(deriveMileageRateTable);
    yield call(resetDocuments);
  } catch (err) {}
}

export function* deriveBillRatesTable() {
  try {
    const {
      contractDetails,
      offContractRateElements: facilityOffcontractRates,
      ocbrDetails: { effectiveDates, isFlatRate },
      miscellaneous: { disciplineOpts },
      featureProps: { skillsets, uniqueShifts },
    } = yield select(selectCreateOrderOcbr);

    const data = deriveShiftsTable({
      skillsets,
      uniqueShifts,
      disciplineOpts,
      masterBillRates: contractDetails?.billRates,
      effectiveDates,
      facilityOffcontractRates,
      isFlatRate,
    });

    yield put(createOrderOcbrActions.setBillRatesOpts(data));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'deriveBillRatesTable',
        area: 'store.redux-store.create-order-ocbr.business-saga.ts',
      },
    });
  }
}

export function* deriveGwwRateTable() {
  try {
    const {
      contractDetails,
      offContractRateElements: facilityOffcontractRates,
      ocbrDetails: { effectiveDates },
      featureProps: { uniqueShifts },
    } = yield select(selectCreateOrderOcbr);

    const data = deriveGWWDropdownData({
      uniqueShifts,
      effectiveDates,
      facilityOffcontractRates,
      rateElements: contractDetails?.rateElements,
    });

    yield put(createOrderOcbrActions.setGwwTableOpts(data));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'deriveBillRatesTable',
        area: 'store.redux-store.create-order-ocbr.business-saga.ts',
      },
    });
  }
}

export function* deriveMileageRateTable() {
  try {
    const {
      contractDetails,
      offContractRateElements: facilityOffcontractRates,
      ocbrDetails: { effectiveDates },
      miscellaneous: { disciplineOpts },
      featureProps: { skillsets },
    } = yield select(selectCreateOrderOcbr);

    const data = deriveMileageValues({
      value: contractDetails?.amountPerMile,
      effectiveDates,
      facilityOffcontractRates,
      skillsets,
      disciplineOpts,
      rateElementId: contractDetails?.mileageRateElementId,
    });

    yield put(createOrderOcbrActions.setMileageTableOpts(data));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'deriveBillRatesTable',
        area: 'store.redux-store.create-order-ocbr.business-saga.ts',
      },
    });
  }
}
