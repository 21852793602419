import { IconType } from '../../Common/RowSectionV2/RowSectionV2';
import { ISkillSetData } from 'store/redux-store/candidate-work-experience/types';

export const formatExperienceData = (years, months, disciplineAbbr, specialtyAbbr) => {
  if (years == null && months == null) {
    return 'Years of experience not added';
  } else if (years === 0 && months !== 0) {
    return `${disciplineAbbr}${specialtyAbbr ? `, ${specialtyAbbr}` : ''} - ${months} ${
      months === 1 ? 'Month' : 'Months'
    }`;
  } else if (months === 0 && years !== 0) {
    return `${disciplineAbbr}${specialtyAbbr ? `, ${specialtyAbbr}` : ''} - ${years} ${years === 1 ? 'Year' : 'Years'}`;
  } else {
    return `${disciplineAbbr}${specialtyAbbr ? `, ${specialtyAbbr}` : ''} - ${years} ${
      years === 1 ? 'Year' : 'Years'
    } and ${months} ${months === 1 ? 'Month' : 'Months'}`;
  }
};

export const formatHeaderText = (years: number, months: number, disciplineAbbr: string, specialtyAbbr: string) => {
  if (years == null && months == null) return 'Years of experience not added';

  const firstHeaderText = `${disciplineAbbr}${specialtyAbbr ? `-${specialtyAbbr}` : ''}`;
  const monthText = 'mo';
  const yearText = 'yr';

  if (years === 0 && months !== 0) return `${firstHeaderText}: ${months}${monthText}`;
  else if (months === 0 && years !== 0) return `${firstHeaderText}: ${years}${yearText}`;
  else return `${firstHeaderText}: ${years}${yearText} and ${months}${monthText}`;
};

export const setupExperienceData = (
  e: ISkillSetData,
  isVerifiedSkill: boolean,
  doesSkillSetExists: boolean,
): IExperienceRow => {
  var data = {
    iconType: e.yearsOfExperience >= 2 && isVerifiedSkill ? IconType.GreenChecked : IconType.OrangeAlert,
    header: formatHeaderText(e.yearsOfExperience, e.monthsOfExperience, e.disciplineAbbr, e.specialtyAbbr),
    groupKey: e.disciplineAbbr ? (e.specialtyAbbr ? `${e.disciplineAbbr}-${e.specialtyAbbr}` : e.disciplineAbbr) : '',
    isVerifiedSkill,
    doesSkillSetExists,
  };
  return data;
};

export interface IExperienceRow {
  iconType: IconType;
  header: string;
  groupKey: string;
  isVerifiedSkill: boolean;
  doesSkillSetExists: boolean;
}
