import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Tab, Tabs, Box, Paper, IconButton, Theme, Divider } from 'amn-ui-core';
import queryString from 'query-string';
import styledComponent from 'styled-components';
import AttachmentsTabContent from './AttachmentsTabContent';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, useParams } from 'react-router';
import { Grid } from 'amn-ui-core';
import _ from 'lodash';
import { findCredentialByRequirementID } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/CredentialsTab/CredentialsHelpers';
import {
  candidateDetailsSelection,
  CredentialingLookups,
  previewAttachmentsSelection,
} from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { Add } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { candidateDetailActions } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { getCandidateCredentials } from 'app/services/CredentialingServices';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useDispatch } from 'react-redux';
import { CommonNoDataPage } from 'app/components/Common/NoDataPage/CommonNoDataPage';
import { getCredentialLookups } from 'app/services/CredentialingServices/CredentialingServices';
import { CustomTooltip } from '../../../Common/Tooltips/CustomTooltip';
import { useTranslation } from 'react-i18next';
import { CustomBackdrop } from 'app/components/Common/Backdrop/CustomBackdrop';
import { selectUser } from 'oidc/user.selectors';
import { isCredentialing } from 'oidc/userRoles';
import { isPositiveNumber } from 'app/helpers/componentHelpers';
import { BreadcrumbNavigation } from '@AMIEWEB/Common';
import { selectNavigationData } from '@AMIEWEB/Common/BreadcrumbNavigation/BreadcrumbNavigation.selector';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'inherit',
  },

  panel: {
    backgroundColor: 'inherit',
    color: '#52429A',
  },
  panelText: {
    fontSize: 14,
    textTransform: 'none',
  },
  tabIndicator: {
    height: 4,
    backgroundColor: '#52429A',
  },
  defaultTabStyle: {
    color: '#333333',
    opacity: 1,
  },
  activeTabStyle: { color: '#52429A' },
  navigationArrow: {
    display: 'flex',
    alignItems: 'center',
    '&:visited': {
      color: '#52429A',
    },
    cursor: 'pointer',
    textDecoration: 'none',
    color: '#003E68',
    paddingLeft: '15px',
  },
  inlinesm: {
    'max-width': '3.333333%',
    'flex-basis': '3.333333%',
  },
  inlinebig: {
    'max-width': '93.333334%',
    'flex-basis': '93.333334%',
  },
  addButton: {
    backgroundColor: theme.palette.framework.system.skyBlue,
    '&:hover': {
      backgroundColor: theme.palette.framework.system.skyBlue,
    },
    width: '38px',
    height: '38px',
    borderRadius: '4px',
    color: '#fff',
    marginTop: '2px',
  },
  disableAddButton: {
    backgroundColor: theme.palette.framework.system.lightGray,
    '&:hover': {
      backgroundColor: theme.palette.framework.system.lightGray,
    },
    width: '38px',
    height: '38px',
    borderRadius: '4px',
    color: '#fff',
    marginTop: '2px',
    cursor: 'not-allowed',
  },
  breadcrumNavigation: {
    padding: '20px 0px 0px 20px',
  },
  divider: {
    paddingTop: '10px',
  },
}));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <TabBox>{children}</TabBox>}
    </div>
  );
}

const TabBox = styledComponent(Box)`
  padding: 15px 0 !important;
`;
function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
export const AttachmentsTabPanel = props => {
  const { classes } = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const { reqId, travelerId, brandId } = useParams<any>();
  const [credential, setCredential] = useState<any>(null);
  const [credentialLookUps, setCredentialLookUps] = useState<any>(null);
  const [sortedAttachments, setSortedAttachments] = useState<any>([]);
  const navigationData = useSelector(selectNavigationData);
  const lookupselection = useSelector(CredentialingLookups);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const previewAttachmentsSel = useSelector(previewAttachmentsSelection);
  const user = useSelector(selectUser);
  const { promiseInProgress } = usePromiseTracker({ area: 'credentials', delay: 0 });

  //Set metadata look Ups
  useEffect(() => {
    setCredentialLookUps(lookupselection);
  }, [lookupselection]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!candidateDetails.credentials.reloadData && isPositiveNumber(travelerId)) {
      const fetchData = async () => {
        await getCandidateCredentials(travelerId)
          .then(response => {
            setCredential(findCredentialByRequirementID(parseInt(reqId), response));
            dispatch(candidateDetailActions.setCredentials(response));
          })
          .catch(err => console.log(err));
        await getCredentialLookups('all')
          .then(response => {
            setCredentialLookUps(response);

            dispatch(candidateDetailActions.setCredentialingLookups(response));
          })
          .catch(err => console.log(err));
      };
      trackPromise(fetchData(), 'credentials');
    }
  }, [candidateDetails.credentials.reloadData, dispatch, reqId, travelerId]);

  useEffect(() => {
    if (candidateDetails.credentials.reloadData)
      setCredential(findCredentialByRequirementID(parseInt(reqId), candidateDetails.credentials));
  }, [candidateDetails.credentials, reqId]);

  useEffect(() => {
    const attachmentIds = queryString.parse(window.location.search).at;
    const attachments: any = props.attachments;
    let newAttachments: any = _.cloneDeep(attachments);
    const tempAttachment: any = [];
    if (previewAttachmentsSel) {
      newAttachments = _.cloneDeep(previewAttachmentsSel);
    } else {
      //Filter attachments
      if (attachmentIds !== '' && attachmentIds !== null && attachmentIds !== undefined) {
        attachmentIds.split(',').forEach(id => {
          const attachment = attachments?.find(a => a.sortOrder === parseInt(id));
          tempAttachment.push(attachment);
        });
        newAttachments = _.cloneDeep(tempAttachment);
      }
    }

    //Sort attachments
    const sortedAttachments = _.orderBy(
      newAttachments,
      (o: any) => {
        return o != null ? o.lastUpdated : o;
      },
      ['desc'],
    );

    setSortedAttachments(sortedAttachments.filter(x => x));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (previewAttachmentsSel) {
      //Sort attachments
      const sortedAttachments = _.orderBy(
        previewAttachmentsSel,
        (o: any) => {
          return o != null ? o.lastUpdated : o;
        },
        ['desc'],
      );

      setSortedAttachments(sortedAttachments.filter(x => x));
    }
  }, [previewAttachmentsSel]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const _onBack = event => {
    //Todo: CandidateProfile to get the candidate ID and BrandId using rid.
    history.push({ pathname: `/candidate/${travelerId}/${brandId}`, search: '?tab=2' });
  };
  return (
    <>
      {promiseInProgress && <CustomBackdrop open={promiseInProgress} />}

      {!promiseInProgress && (
        <div className={classes.root}>
          <Grid container xs={12} spacing={1} alignItems="center">
            {navigationData && navigationData.breadcrumbHierarchy ? (
              <Grid item xs={12}>
                <div className={classes.breadcrumNavigation}>
                  <BreadcrumbNavigation {...navigationData} />
                  <Divider className={classes.divider} />
                </div>
              </Grid>
            ) : (
              <>
                <Grid item sm={1} className={classes.inlinesm}>
                  <Box onClick={_onBack} className={classes.navigationArrow}>
                    <ArrowBackIcon />
                  </Box>
                </Grid>
                <Grid item sm={10} className={classes.inlinebig}>
                  <PanelPaper elevation={0} className={classes.panel}>
                    {sortedAttachments && sortedAttachments.length > 0 ? (
                      <Tabs
                        value={value}
                        classes={{ indicator: classes.tabIndicator }}
                        textColor="inherit"
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="Credential Attachments Tabs"
                      >
                        {sortedAttachments
                          ?.filter(x => x)
                          .map((item, index) => (
                            <Tab
                              key={index}
                              classes={{ root: classes.panelText }}
                              label={item.title ? item.title : `Attachments ${item?.sortOrder + 1}`}
                              className={value === index ? classes.activeTabStyle : classes.defaultTabStyle}
                              {...a11yProps(index)}
                            />
                          ))}
                      </Tabs>
                    ) : (
                      <></>
                    )}
                  </PanelPaper>
                </Grid>
                <Grid item sm={1} className={classes.inlinesm}>
                  <CustomTooltip
                    tooltipContent={t('Add Document')}
                    value={
                      <IconButton
                        color="secondary"
                        
                        onClick={isCredentialing(user?.userInfo?.roles || []) && props.onAddDocumentClick}
                        aria-label="add-document-button"
                        className={
                          !(isCredentialing(user?.userInfo?.roles || [])) ? classes.addButton : classes.disableAddButton
                        }
                        size="large"
                      >
                        <Add />
                      </IconButton>
                    }
                  ></CustomTooltip>
                </Grid>
              </>
            )}
          </Grid>
          <TabPanel value={value} index={value}>
            {sortedAttachments && sortedAttachments.length > 0 && credential ? (
              <AttachmentsTabContent
                attachment={sortedAttachments[value]}
                attachmentList={sortedAttachments}
                credentials={credential}
                credentialLookUps={credentialLookUps}
                candidateId={travelerId}
                requirementID={reqId}
                attachmentsNum={`Attachments ${sortedAttachments[value]?.sortOrder + 1}`}
              />
            ) : (
              <CommonNoDataPage message={`Unable to find attachment information..`} />
            )}
          </TabPanel>
        </div>
      )}
    </>
  );
};

const PanelPaper = styledComponent(Paper)`
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #e3dfdf;
`;
