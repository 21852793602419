import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Grid } from 'amn-ui-core';
import { SingleLineInput } from '@AMIEWEB/Unit/Common/SingleLineInput';
import { makeStyles } from 'tss-react/mui';
import { SimpleCheckbox } from '@AMIEWEB/Unit/Common/SimpleCheckbox';
import { userRoles } from 'oidc/userRoles';
import { Authorized } from 'oidc/userHelper';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { CustomTooltip } from '@AMIEWEB/Common';
import { getPositiveIntegerValue, getPositiveIntegerValueExceptZero } from '@AMIEWEB/NewOrder/CreateOrder/helper';
import { isWholeNumber } from '@AMIEWEB/Order/OrderCreationForm/helper';

const useStyles = makeStyles()(theme => ({
  container: {
    gap: '12px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  fieldContainer: {
    display: 'grid',
    gap: '12px',
  },
  checkBoxContainer: {
    display: 'grid',
    alignItems: 'center',
  },
  disabledField: {
    opacity: '0.6',
    pointerEvents: 'none',
  },
  disabledFieldWithHeight: {
    opacity: '0.6',
    pointerEvents: 'none',
    height: '60px',
  },
  inputField: {
    height: '60px',
  },
  fieldStyle: {
    '& .MuiFilledInput-root': {
      backgroundColor: `${theme.palette.system.grayChip} !important`,
    },
    '&:hover .MuiFilledInput-root': {
      backgroundColor: `${theme.palette.system.silver} !important`,
    },
    '&:hover .MuiInputLabel-root': {
      color: `${theme.palette.system.primary} !important`,
    },
  },
  checkbox: {
    '& label': {
      '& .MuiButtonBase-root.MuiCheckbox-root.Mui-checked': {
        color: `${theme.palette.system.main} !important`,
      },
      '&:hover .MuiButtonBase-root.MuiCheckbox-root': {
        color: `${theme.palette.system.main} !important`,
      },
    },
  },
}));

export const ReferencesContainer = () => {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext();
  const { classes } = useStyles();
  const userData = useSelector(selectUser);
  const isClientContractSpecialist = Authorized([userRoles.clinical_ClientContractSpecialist], userData.userInfo);
  const referenceValue = watch('reference');
  const [numberOfMonths, setNumberOfMonths] = useState(referenceValue.withinNumberOfMonths);
  const setTabIndex = isDisabled => (isDisabled ? -1 : 0);
  const [withinNumberOfMonthsFocused, setWithinNumberOfMonthsFocused] = React.useState(false);
  const [withinLastMonthsError, setWithinLastMonthsError] = useState<boolean>();

  const withinNumberOfMonths = useWatch({
    control,
    name: 'reference.withinNumberOfMonths',
  });
  const CONSTANT_MINUES_KEY = '-';
  const CONSTANT_DECIMAL_KEY = '.';

  useEffect(() => {
    const isRequired = referenceValue?.numberOfReferenceToRFO > 0 ? true : false;
    setValue('reference.isRequired', isRequired);
  }, [referenceValue?.numberOfReferenceToRFO]);

  useEffect(() => {
    if (withinLastMonthsError) {
      setValue('reference.withinNumberOfMonths', null);
      setWithinLastMonthsError(false);
    } else {
      setError(referenceValue?.withinNumberOfMonths);
    }
  }, [referenceValue?.entireWorkHistory]);


  const setError = (value: string) => {
    const number = parseInt(value, 10);
    number <= 0 ? setWithinLastMonthsError(true) : setWithinLastMonthsError(false);
  };

  return (
    <Grid container columnSpacing={2} marginTop={1} className={classes.container}>
      <Grid xs={4.5} className={classes.fieldContainer}>
        <CustomTooltip
          disabled={isClientContractSpecialist}
          tooltipContent={t(
            'facility.units.unitDetails.additionalInformation.references.disabledReferenceFieldTooltip',
          )}
        >
          <Grid item className={isClientContractSpecialist ? '' : classes.disabledField}>
            <Controller
              name={'reference.numberOfReferenceRequired'}
              control={control}
              render={({ onChange, ...rest }) => (
                <SingleLineInput
                  id={'numberOfReferences'}
                  inputDefaultValue={referenceValue.isRequired ? 1 : 0}
                  inputLabel={t(
                    'facility.units.unitDetails.additionalInformation.references.numberOfReferencesToStartUnit',
                  )}
                  inputName={'numberOfReferences'}
                  inputValue={rest.value}
                  handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    referenceValue.isRequired && parseInt(event.target.value) === 0
                      ? onChange(1)
                      : onChange(getPositiveIntegerValue(event.target.value));
                  }}
                  inputType={'number'}
                  fieldStyle={classes.fieldStyle}
                  tabIndex={setTabIndex(!isClientContractSpecialist)}
                  onKeyPress={e => {
                    if (e.key === CONSTANT_MINUES_KEY || e.key === CONSTANT_DECIMAL_KEY) {
                      e.preventDefault();
                    }
                  }}
                />
              )}
            />
          </Grid>
        </CustomTooltip>
        <Grid item className={referenceValue.entireWorkHistory ? classes.disabledFieldWithHeight : classes.inputField}>
          <Controller
            name={'reference.withinNumberOfMonths'}
            control={control}
            render={({ onChange, ...rest }) => (
              <SingleLineInput
                tabIndex={referenceValue.entireWorkHistory}
                id={'withinTheLast'}
                inputDefaultValue={referenceValue.entireWorkHistory ? null : referenceValue.withinNumberOfMonths}
                inputLabel={t('facility.units.unitDetails.additionalInformation.references.withinTheLast')}
                inputName={'withinTheLast'}
                minInputValue={1}
                inputValue={referenceValue.entireWorkHistory ? '' : referenceValue.withinNumberOfMonths}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(event.target.value === '' ? null : getPositiveIntegerValueExceptZero(event.target.value));
                  setNumberOfMonths(
                    event.target.value === '' ? null : getPositiveIntegerValueExceptZero(event.target.value),
                  );
                  setError(event.target.value);
                }}
                showEndAdornment={withinNumberOfMonthsFocused || isWholeNumber(withinNumberOfMonths)}
                endAdornmentValue={'months'}
                inputType={'number'}
                fieldStyle={classes.fieldStyle}
                shrinkLabel={
                  withinNumberOfMonthsFocused || withinLastMonthsError || isWholeNumber(withinNumberOfMonths)
                }
                handleWarning={() => setWithinNumberOfMonthsFocused(true)}
                handleBlur={() => {
                  setWithinNumberOfMonthsFocused(false);
                  rest.onBlur();
                }}
                inputError={withinLastMonthsError}
                helperText={withinLastMonthsError ? t('facility.references.withinTheLastMonthError') : ''}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid xs={3.5} className={classes.fieldContainer}>
        <Grid item>
          <Controller
            name={'reference.numberOfReferenceToRFO'}
            control={control}
            render={({ onChange, ...rest }) => (
              <SingleLineInput
                id={'numberOfReferencesToRFO'}
                inputDefaultValue={0}
                inputLabel={t('facility.units.unitDetails.additionalInformation.references.referenceToSubmit')}
                inputName={'numberOfReferencesToRFO'}
                inputValue={rest.value}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(getPositiveIntegerValue(event.target.value));
                }}
                inputType={'number'}
                fieldStyle={classes.fieldStyle}
                onKeyPress={e => {
                  if (e.key === CONSTANT_MINUES_KEY || e.key === CONSTANT_DECIMAL_KEY) {
                    e.preventDefault();
                  }
                }}
              />
            )}
          />
        </Grid>
        <Grid item className={classes.checkbox}>
          <Controller
            name={'reference.entireWorkHistory'}
            control={control}
            render={({ onChange, ...rest }) => (
              <SimpleCheckbox
                isChecked={rest.value || false}
                handleChecked={event => {
                  onChange(event.target.checked);
                  if (event.target.checked) {
                    setValue('reference.withinNumberOfMonths', null);
                  } else {
                    setValue('reference.withinNumberOfMonths', numberOfMonths);
                  }
                }}
                checkboxLabel={t('facility.units.unitDetails.additionalInformation.references.entireTime')}
                checkboxName={'entireWorkHistory'}
                id={'entireWorkHistory'}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={3} className={classes.checkBoxContainer}>
        <Grid item className={''}>
          <Controller
            name={'reference.isRequired'}
            control={control}
            render={({ onChange, ...rest }) => (
              <SimpleCheckbox
                isChecked={referenceValue?.numberOfReferenceToRFO >= 1}
                isdisabled={true}
                handleChecked={event => {
                  onChange(event.target.checked);
                  if (event.target.checked && referenceValue.numberOfReferenceRequired === 0) {
                    setValue('reference.numberOfReferenceRequired', 1);
                  }
                }}
                checkboxLabel={t('facility.units.unitDetails.additionalInformation.references.submissionRequirement')}
                checkboxName={'referenceRequired'}
                id={'referenceRequired'}
              />
            )}
          />
        </Grid>
        <Grid item className={classes.checkbox}>
          <Controller
            name={'reference.referenceFromSupervisor'}
            control={control}
            render={({ onChange, ...rest }) => (
              <SimpleCheckbox
                isChecked={rest.value || false}
                handleChecked={event => {
                  onChange(event.target.checked);
                }}
                checkboxLabel={t('facility.units.unitDetails.additionalInformation.references.supervisor')}
                checkboxName={'supervisorAndAbove'}
                id={'supervisorAndAbove'}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
