/* eslint-disable tss-unused-classes/unused-classes */
import { FormControl, InputAdornment, TextField } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { NewOrder } from 'app/models/Orders/OrderDetails';
import { validDecimal } from 'app/helpers/numberHelper';
import { toFixed } from '../../helper';

const useStyles = makeStyles<void, 'focused'>()((theme, _params, classes) => ({
  element: {
    width: '100%',
    height: '100%',
  },
  root: {
    border: '1px solid #d2d2d1',
    overflow: 'hidden',
    fontSize: 16,
    borderRadius: 4,
    backgroundColor: '#fff',
    '&:hover': {
      borderColor: '#aaa',
      borderRadius: 4,
      backgroundColor: '#fff',
    },
    [`&.${classes.focused}`]: {
      borderColor: '#aaa',
      backgroundColor: '#fff',
    },
  },
  focused: {},
  labelRoot: {
    fontSize: 16,
    '&:focus': {
      color: '#000',
    },
  },
  startAdornment: {
    fontSize: 18,
    color: '#888',
  },
  error: {
    border: '1px solid #ff0000',
    overflow: 'hidden',
    fontSize: 16,
    borderRadius: 4,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
    [`&.${classes.focused}`]: {
      backgroundColor: '#fff',
    },
  },
  reqTextareastyle: {
    border: 'thin #02b9ff solid !important',
  },
}));

export const NumberEntryElement = ({
  order,
  onOrderPropertyChange,
  label,
  name,
  monetary,
  placeholder,
  limitHundred,
  limitThousand,
  required,
  req,
  id,
}: {
  order: NewOrder;
  onOrderPropertyChange: (property: string, value: any) => void;
  label: string;
  name: string;
  monetary?: boolean;
  placeholder?: string;
  limitHundred?: boolean;
  limitThousand?: boolean;
  required?: boolean;
  req?: boolean;
  id?: string;
}) => {
  const { classes } = useStyles();
  const [value, setValue] = useState<any>(order[name]);

  useEffect(() => {
    if (!isNaN(parseInt(value))) {
      if (monetary) {
        onOrderPropertyChange(name, toFixed(value, 2));
      } else {
        onOrderPropertyChange(name, parseInt(value));
      }
    } else {
      onOrderPropertyChange(name, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const validateControl = value => {
    const min = required ? 1 : 0;
    if (limitHundred && !isNaN(parseInt(value)) && !(value <= 99 && value >= min)) {
      return false;
    }
    if (limitThousand && !isNaN(parseInt(value)) && !(value <= 9999 && value >= min)) {
      return false;
    }
    return true;
  };

  const handleChange = event => {
    const input = event.target.value;
    if (name === 'orderBillRate') {
      if (validDecimal(input, { numberLength: 6, decimalLength: 4 })) setValue(input);
    } else {
      if (validateControl(input)) {
        setValue(input);
      } else {
        setValue(value);
      }
    }
  };

  return (
    <FormControl data-testid="test number entry element" variant="filled" className={classes.element}>
      <TextField
        id={id}
        label={label}
        type="number"
        variant="filled"
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        InputProps={{
          classes: classes,
          className: req ? classes.reqTextareastyle : '',
          disableUnderline: true,
          startAdornment: monetary && (
            <InputAdornment position="start" disableTypography className={classes.startAdornment}>
              $
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          classes: { root: classes.labelRoot },
        }}
      />
    </FormControl>
  );
};
