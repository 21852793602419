import React, { useMemo, useState } from 'react';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { makeStyles } from 'tss-react/mui';
import { Button, Chip, Typography } from 'amn-ui-core';
import { Expandable } from '@AMIEWEB/Common';
import _ from 'lodash';
import { CustomGrid, CustomGridFooter } from './SalesReportCustomGrid';
import {
  getSummaryDetails,
  getWeeksType,
  getDetailsGrid,
  getSummaryGrid,
  getTotalCountFooter,
  getGroupingData,
} from './SalesReportUtils';
import { useSelector } from 'react-redux';
import { selectR225ReportDetails } from 'store/redux-store/report-details/selectors';
import { IR225ReportResponseObject } from 'store/redux-store/report-details/types';
import { ReportTypes, SummaryTitle, WeekType, R225ResponseFields } from './SalesReportTypes';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(theme => ({
  root: {
    position: 'relative',
    marginTop: '-7px',
    backgroundColor: theme.palette.common.white,
  },
}));

export const SalesReportResult = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [expand, setExpand] = useState<boolean>(true);

  const r225ReportDetails = useSelector(selectR225ReportDetails);
  const r225Report = r225ReportDetails?.response;
  const inputReportDetails = r225ReportDetails?.inputDetails;

  const summaryData = useMemo(() => {
    return {
      totalSummaryData: r225Report?.length > 0 ? getSummaryDetails(r225Report) : [],
      brandWiseSummaryData:
        r225Report?.length > 0 && inputReportDetails?.selected !== ReportTypes.Recruiters
          ? getGroupingData(r225Report, R225ResponseFields.BrandName)
          : [],
      selectedUsersChips:
        inputReportDetails?.selectedOptions?.length > 0
          ? _.sortBy(
              _.uniqBy(inputReportDetails?.selectedOptions, function (e: { name: string; value: string }) {
                return e.name;
              }).map(x => x.name),
            )
          : [],
      selectedBrandsChips:
        r225Report?.length > 0 && inputReportDetails?.selected !== ReportTypes.Brand
          ? _.sortBy(
              _.uniqBy(r225Report, function (e: IR225ReportResponseObject) {
                return e?.brandName;
              }).map(x => x?.brandName),
            )
          : [],
    };
  }, [inputReportDetails?.selected, inputReportDetails?.selectedOptions, r225Report]);

  const chipCollection = data => {
    return (
      <>
        {data &&
          data.map((chipItem, index) => (
            <Chip
              key={index}
              variant="outlined"
              label={chipItem}
              sx={{
                '&:hover': {
                  backgroundColor: 'white',
                },
                margin:1
              }}
            />
          ))}
      </>
    );
  };

  const summaryGrid = rows => {
    return (
      <>
        {rows.length > 0 &&
          rows.map((row, index) => (
            <React.Fragment key={index}>
              <CustomGrid index={index} isFooterAvailable={true} rows={getSummaryGrid(row)} />
            </React.Fragment>
          ))}
      </>
    );
  };

  const detailsGrid = (title, rows) => {
    return (
      <>
        {rows.length > 0 ? (
          <LayoutGrid container direction="column" className={classes.root}>
            <LayoutGridItem md={12} xs={12}>
              <Typography sx={{ fontWeight: 'bolder' }}>{title}</Typography>
            </LayoutGridItem>
            <LayoutGridItem md={12} xs={12}>
              {rows.map((row, index) => (
                <React.Fragment key={index}>
                  <CustomGrid index={index} rows={getDetailsGrid(row)} />
                </React.Fragment>
              ))}
              {<CustomGridFooter rowsCount={rows.length} gridFooter={getTotalCountFooter(rows)} />}
            </LayoutGridItem>
          </LayoutGrid>
        ) : (
          <></>
        )}
      </>
    );
  };

  const detailsGridWithSUmmary = (title, innerRecord, innerRecordIndex,recordSummary?:any) => {
    return (
      <>
        <Expandable
          key={innerRecordIndex}
          variant="report"
          customPadding={'12px 0px'}
          expanded={expand}
          titleComponent={title}
        >
          {detailsGrid(SummaryTitle.Bookings, getWeeksType(innerRecord?.value, WeekType.WeeksBooked))}
          {detailsGrid(SummaryTitle.Extensions, getWeeksType(innerRecord?.value, WeekType.Extension))}
          {detailsGrid(SummaryTitle.VoidedTimeOff, getWeeksType(innerRecord?.value, WeekType.VoidedTimeOff))}
          {detailsGrid(SummaryTitle.CxlByTraveler, getWeeksType(innerRecord?.value, WeekType.CxlByTraveler))}
          {detailsGrid(SummaryTitle.CxlByFacility, getWeeksType(innerRecord?.value, WeekType.CxlByFacility))}
          {detailsGrid(SummaryTitle.CxlByAMH, getWeeksType(innerRecord?.value, WeekType.CxlByAMH))}
          {detailsGrid(SummaryTitle.SrdAssignment, getWeeksType(innerRecord?.value, WeekType.SrdAssignment))}
          {detailsGrid(SummaryTitle.ApprovedTimeOff, getWeeksType(innerRecord?.value, WeekType.ApprovedTimeOff))}
          {summaryGrid(
            getSummaryDetails(
              (inputReportDetails?.selected === ReportTypes.Recruiters ||
                inputReportDetails?.selected === ReportTypes.Directors) &&
                recordSummary?.length > 0
                ? recordSummary
                : innerRecord?.value,
            ),
          )}
        </Expandable>
      </>
    );
  };
  return (
    <>
      {summaryData?.selectedUsersChips?.length > 0 && r225Report?.length > 0 && (
        <LayoutGridItem md={12} xs={12}>
          {chipCollection(summaryData.selectedUsersChips)}
        </LayoutGridItem>
      )}

      {summaryData?.selectedBrandsChips?.length > 0 && r225Report?.length > 0 && (
        <LayoutGridItem md={12} xs={12} sx={{ paddingTop: 1 }}>
          {chipCollection(summaryData.selectedBrandsChips)}
        </LayoutGridItem>
      )}

      {r225Report?.length > 0 && (
        <LayoutGridItem md={12} xs={12}>
          <Button
            variant="text"
            color="primary"
            disableRipple
            sx={{ float: 'right' }}
            onClick={() => {
              setExpand(!expand);
            }}
          >
            {expand ? t('reportPages.r225.collapseAll') : t('reportPages.r225.expandAll')}
          </Button>
        </LayoutGridItem>
      )}

      <LayoutGridItem md={12} xs={12}>
        {summaryData.totalSummaryData?.length > 0 && r225Report?.length > 0 && (
          <Expandable variant="report" customPadding={'12px 0px'} expanded={expand} titleComponent={t('reportPages.r225.summary')} >
            {summaryGrid(summaryData.totalSummaryData)}
          </Expandable>
        )}

        {summaryData.brandWiseSummaryData?.length > 0 &&
          r225Report?.length > 0 && inputReportDetails?.selected !== ReportTypes.Recruiters && 
          _.sortBy(summaryData.brandWiseSummaryData, 'key')?.map((record, index) => {
            return (
              <>
                {inputReportDetails?.selected === ReportTypes.Brand && (
                  <Expandable
                    key={index}
                    variant="report"
                    customPadding={'12px 0px'}
                    expanded={expand}
                    titleComponent={record?.key}
                  >
                    {summaryGrid(getSummaryDetails(record?.value))}
                  </Expandable>
                )}

                {_.sortBy(getGroupingData(record?.value, R225ResponseFields.RecruiterName), 'key')?.map(
                  (innerRecord, innerRecordIndex) => {
                    return (
                      <>
                        {detailsGridWithSUmmary(
                          innerRecord?.key + ' (' + record?.key + ')',
                          innerRecord,
                          innerRecordIndex,
                          inputReportDetails?.selected === ReportTypes.Directors
                            ? r225Report?.filter(x => x.recruiterName === innerRecord?.key)
                            : [],
                        )}
                      </>
                    );
                  },
                )}
              </>
            );
          })}
        {summaryData.selectedUsersChips &&
          inputReportDetails?.selected === ReportTypes.Recruiters &&
          summaryData.selectedUsersChips?.length > 0 &&
          r225Report?.length > 0 &&
          _.sortBy(_.uniq(_.concat(summaryData.selectedUsersChips, _.uniq(r225Report?.map(x => x.recruiterName))))).map(
            (item, index) => {
              return (
                <>
                  {!r225Report.find(x => x.recruiterName === item) &&
                  r225Report?.length > 0 &&
                  inputReportDetails.selected === ReportTypes.Recruiters ? (
                    <Expandable
                      key={index}
                      variant="report"
                      customPadding={'12px 0px'}
                      expanded={expand}
                      titleComponent={item}
                    >
                      <div style={{ textAlign: 'center' }}>
                        {' '}
                        {t('reportPages.r225.noInformationforUser', { user: `${item}` })}
                      </div>
                    </Expandable>
                  ) : (
                    r225Report?.filter(x => x.recruiterName === item) &&
                    _.sortBy(
                      getGroupingData(
                        getGroupingData(
                          r225Report?.filter(x => x.recruiterName === item),
                          R225ResponseFields.RecruiterName,
                        )[0]?.value,
                        R225ResponseFields.BrandName,
                      ),
                      'key',
                    ).map((brandWiseRecord, brandWiseRecordIndex) => {
                      return (
                        <>
                          {detailsGridWithSUmmary(
                            item + ' (' + brandWiseRecord?.key + ')',
                            brandWiseRecord,
                            brandWiseRecordIndex,
                            r225Report?.filter(x => x.recruiterName === item),
                          )}
                        </>
                      );
                    })
                  )}
                </>
              );
            },
          )}
      </LayoutGridItem>
    </>
  );
};
