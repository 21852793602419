export const sort = (val: any[]) => {
    const sortStatusArray = val.sort((one, two) => (one?.statusId > two?.statusId ? 1 : -1));
    return sortStatusArray;
}

export const multiSort = (val: any[]) => {
    const sortedByDiscipline = val?.sort((a, b) => {
        if (a.discipline !== b.discipline) {
          return a.discipline?.localeCompare(b.discipline);
        } else if (a.specialty !== b.specialty) {
          return a.specialty?.localeCompare(b.specialty);
        } else {
          return a.subSpecialty?.localeCompare(b.subSpecialty);
        }
      }); 
    return sortedByDiscipline;
}