import React from 'react';
import { EPlacementSubmissionPacketType } from 'app/models/Placement/PlacementDetails';
import { makeStyles } from 'tss-react/mui';
import { GridCellParams } from '@mui/x-data-grid-pro';
import {
  isOkToSend,
  isSentOrBeyond,
  isSubmissionNegativeStatus,
} from '@AMIEWEB/Placement/NextPlacementStatus/StatusDefaults';
import { missingField } from 'app/constants';
import { useTranslation } from 'react-i18next';

interface SubmissionInfo {
  submissionAutomated: boolean | null;
  submissionSuccessful: boolean | null;
  submissionError: string | null;
  placementStatus: string | null;
  placementStatusId: number;
  placementTypeId: number;
}

interface Props {
  submissionInfo: SubmissionInfo;
  statusDuration: number;
}

const useStyles = makeStyles()(theme => ({
  rootContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    textAlign: 'center',
    minHeight: '18px',
    minWidth: '18px',
    maxHeight: '18px',
    maxWidth: '18px',
    marginRight: '8px',
    fontWeight: 600,
    fontSize: '12px',
  },
  greyColor: {
    border: `${theme.palette.system.lightGrey} 2px solid`,
    color: theme.palette.system.silver,
  },
  redColor: {
    border: `${theme.palette.system.darkRed} 2px solid`,
    color: theme.palette.system.darkRed,
  },
  blackColor: {
    border: `${theme.palette.system.black} 2px solid`,
    color: theme.palette.system.black,
  },
  errorIcon: {
    border: `${theme.palette.system.white} 2px solid`,
    color: theme.palette.system.white,
    backgroundColor: theme.palette.system.darkRed,
  },
  errorMessage: {
    margin: 0,
    padding: 0,
    border: '0 !important',
    color: theme.palette.system.darkRed,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '&:hover': {
      overflow: 'unset',
      textOverflow: 'unset',
    },
  },
  blackText: {
    color: theme.palette.system.black,
  },
}));

const getSubmissionType = (submissionInfo: SubmissionInfo) => {
  return submissionInfo?.submissionAutomated
    ? EPlacementSubmissionPacketType.Automated
    : submissionInfo?.submissionAutomated === false
    ? EPlacementSubmissionPacketType.Manual
    : null;
};

const getSubmissionInfo = (t, submissionInfo: SubmissionInfo, statusDuration: number, classes) => {
  const currentPlacementStatus = {
    activityStatus: submissionInfo.placementStatus,
    activityStatusId: submissionInfo.placementStatusId,
    activityTypeId: submissionInfo.placementTypeId,
  };
  if (
    (isOkToSend(currentPlacementStatus) || isSubmissionNegativeStatus(currentPlacementStatus)) &&
    submissionInfo.submissionAutomated === null &&
    submissionInfo.submissionError
  ) {
    return {
      errorMessage: submissionInfo.submissionError,
      iconClassName: {
        [classes.errorIcon]: true,
      },
      submissionType: EPlacementSubmissionPacketType.Unknown,
    };
  } else if (isOkToSend(currentPlacementStatus)) {
    if (!submissionInfo.submissionError && statusDuration >= 15) {
      return {
        errorMessage: t('placement.submissionError.unknownError'),
        iconClassName: {
          [classes.errorIcon]: submissionInfo.submissionAutomated === null,
          [classes.redColor]: submissionInfo.submissionAutomated !== null,
        },
        submissionType: submissionInfo.submissionAutomated
          ? EPlacementSubmissionPacketType.Automated
          : submissionInfo.submissionAutomated === false
          ? EPlacementSubmissionPacketType.Manual
          : EPlacementSubmissionPacketType.Unknown,
      };
    } else if (submissionInfo.submissionAutomated !== null) {
      return {
        errorMessage: submissionInfo.submissionError,
        iconClassName: {
          [classes.redColor]: !!submissionInfo.submissionError,
          [classes.greyColor]: !submissionInfo.submissionError,
        },
        submissionType: getSubmissionType(submissionInfo),
      };
    }
  } else {
    if (submissionInfo.submissionAutomated !== null) {
      return {
        errorMessage: null,
        iconClassName: {
          [classes.blackColor]: isSentOrBeyond(currentPlacementStatus),
          [classes.greyColor]: !isSentOrBeyond(currentPlacementStatus),
        },
        submissionType: getSubmissionType(submissionInfo),
      };
    }
  }
  return { errorMessage: missingField, iconClassName: null, submissionType: null };
};

export const SubmissionError = (props: Props) => {
  const { submissionInfo, statusDuration } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const { errorMessage, iconClassName, submissionType } = getSubmissionInfo(t, submissionInfo, statusDuration, classes);
  return (
    <div className={classes.rootContainer}>
      <div>
        {submissionType && (
          <div className={cx(classes.iconContainer, iconClassName)}>
            {submissionType === EPlacementSubmissionPacketType.Unknown ? '!' : submissionType}
          </div>
        )}
      </div>
      <p className={cx(classes.errorMessage, { [classes.blackText]: errorMessage === missingField })}>{errorMessage}</p>
    </div>
  );
};

export const Submission = (params: GridCellParams) => {
  return <SubmissionError submissionInfo={params.value} statusDuration={params.row.statusAgeValue} />;
};
