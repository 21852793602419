import { isEmpty, isNil, orderBy } from 'lodash';
import { IQualificationSubSectionData, Qualifications } from './UnitQualifications';
import { StatusTileDescription, StatusTileProps, StatusTileText } from '@AMIEWEB/Unit/Common/StatusTile';
import { ReactElement } from 'react';
import { Concatenate } from 'utils/string/string';
import { dateAbbreviationText, SkillSetCollections } from 'app/enums/Common';
import { IRequirements, ISkillSetInfo, ITravelExperience } from '@AMIEWEB/Facility/FacilityStore/Facility.model';
import { missingField } from 'app/constants';

/**
 *  Method to map licenses
 * @param data
 */
const mappedLicenses = (data: IQualificationSubSectionData[], excludeStatusTooltip: boolean = false) => {
  const licenses = data.map(licenseItem => ({
    ...licenseItem,
    abbreviation: `${licenseItem.licenseAbbreviation} ${
      !licenseItem.isMultiState
        ? `${licenseItem?.stateAbbreviation ? '- ' + licenseItem?.stateAbbreviation : ''}`
        : '- Compact'
    }`,
    isDocAttachmentRequired: licenseItem.documentAttachmentRequired,
    isRequired: licenseItem.isRequired,
    tooltipDescription: `${!excludeStatusTooltip ? (licenseItem.isRequired ? '(Required) ' : '(Preferred) ') : ''}${
      licenseItem.licenseDescription
    } ${
      !licenseItem.isMultiState
        ? `${licenseItem?.stateAbbreviation ? '- ' + licenseItem?.stateAbbreviation : ''}`
        : '- Compact'
    }`,
  }));
  return orderBy(licenses, ['abbreviation'], ['asc']);
};

/**
 * Method to map certifications
 * @param data
 */
const mappedCertifications = (data: IQualificationSubSectionData[], excludeStatusTooltip: boolean = false) => {
  const certification = data.map(certificationItem => ({
    ...certificationItem,
    abbreviation: certificationItem.abbreviation,
    isDocAttachmentRequired: certificationItem.documentAttachmentRequired,
    isRequired: certificationItem.isRequired,
    tooltipDescription: `${
      !excludeStatusTooltip ? (certificationItem.isRequired ? '(Required) ' : '(Preferred) ') : ''
    }${
      certificationItem?.certificateDescription
        ? certificationItem.certificateDescription
        : certificationItem.abbreviation
    }`,
  }));
  return orderBy(certification, ['abbreviation'], ['asc']);
};

/**
 * Method to mapped skills
 * @param data
 */
const mappedSkills = (data: IQualificationSubSectionData[]) => {
  const skills = data.map(skill => ({
    abbreviation: `${skill.text}, ${skill.yearsOfExperience}y`,
    isDocAttachmentRequired: false,
    isRequired: skill?.isRequired,
    tooltipDescription: `${skill.isRequired ? '(Required)' : '(Preferred)'}  ${skill.text}, ${
      skill.yearsOfExperience
    }y`,
  }));
  return orderBy(skills, ['abbreviation'], ['asc']);
};

/**
 *
 * @param type
 * @param data
 * @returns
 */
const transformUnitList = (type: number, data = [], excludeStatusTooltip: boolean = false) => {
  switch (type) {
    case Qualifications.Licenses:
      return mappedLicenses(data, excludeStatusTooltip);
    case Qualifications.Certifications:
      return mappedCertifications(data, excludeStatusTooltip);
    case Qualifications.skills:
      return mappedSkills(data);
  }
};

const checkQualificationCount = (certifications: number, licenses: number, skillChecklists: number) => {
  const certificationCount = certifications ? certifications : 0;
  const licensesCount = licenses ? licenses : 0;
  const skillChecklistsCount = skillChecklists ? skillChecklists : 0;
  return certificationCount + licensesCount + skillChecklistsCount;
};

const transformSkillsetLicenses = (data: IRequirements[]) => {
  const transformedLicenses = data?.map(item => {
    return {
      ...item,
      [`text`]: `${item.requirementAbbreviation} ${
        item.isMultiState
          ? '- Compact'
          : item.requirementState?.stateAbbreviation
          ? `- ${item.requirementState.stateAbbreviation}`
          : ''
      }`,
      [`tooltipText`]: `${item.requirementDescription} ${
        item.isMultiState ? '- Compact' : item.requirementState?.state ? `- ${item.requirementState.state}` : ''
      }`,
    };
  });

  return transformedLicenses || [];
};

const transformSkillsetCertifications = (data: IRequirements[]) => {
  if (!data?.length) return [];

  const transformedCertifications = data?.map(item => {
    const isCert = item.requirementAbbreviation === 'CERT' && item.subCertificate?.subCertificationId;
    return {
      ...item,
      text: isCert
        ? `${item.requirementAbbreviation} - ${item.subCertificate?.subCertificateAbbreviation || ''}`
        : item.requirementDescription,
      tooltipText: isCert
        ? `${item.requirementAbbreviation} - ${item.subCertificate?.subCertificateName || ''}`
        : item.requirementDescription,
    };
  });

  return transformedCertifications;
};

const transformUnitSkillsetCollections = (
  type: SkillSetCollections,
  data: IRequirements[] = [],
  icon: ReactElement = null,
) => {
  const tranformedData =
    type === SkillSetCollections.Licenses
      ? transformSkillsetLicenses(data)
      : type === SkillSetCollections.Certifications
      ? transformSkillsetCertifications(data)
      : [];

  const transformedSkillsetCollections: Array<StatusTileProps> = tranformedData?.map((item: any) => {
    return {
      id: item.id,
      title: {
        text: item.text ?? item.requirementAbbreviation,
        showTooltip: true,
        tooltipText: item.tooltipText ?? item.requirementDescription,
      },
      status: {
        text: item.isRequired ? StatusTileText.Required : StatusTileText.Preferred,
        showTooltip: true,
        tooltipText: item.isRequired ? StatusTileDescription.Required : StatusTileDescription.Preferred,
      },
      issuedBy: item?.issuedBy,
      showAdditionalInfo: false,
      additionalInfo: null,
      icon: item.documentAttachmentRequired ? icon : null,
      iconTooltip: {
        showTooltip: true,
        message: 'facility.units.experience.docRequired',
      },
    };
  });

  return transformedSkillsetCollections;
};

const transformUnitExperienceForStatusTile = (skillSetsData: ISkillSetInfo) => {
  let resultData: StatusTileProps = null;
  if (!isEmpty(skillSetsData)) {
    return {
      title: {
        text: Concatenate(
          [
            skillSetsData?.disciplineAbbr?.toString() || skillSetsData?.discipline?.toString(),
            skillSetsData?.specialty?.toString(),
            skillSetsData?.subSpecialty?.toString(),
          ],
          ' - ',
        ),
        showTooltip: true,
        tooltipText: Concatenate(
          [
            skillSetsData?.discipline?.toString(),
            skillSetsData?.specialty?.toString(),
            skillSetsData?.subSpecialty?.toString(),
          ],
          ' - ',
        ),
      },
      status: {
        text: skillSetsData.isRequired ? StatusTileText.Required : StatusTileText.Preferred,
        showTooltip: true,
        tooltipText: skillSetsData.isRequired ? StatusTileDescription.Required : StatusTileDescription.Preferred,
      },
      showAdditionalInfo: true,
      additionalInfo:
        skillSetsData?.yearsOfExperience === null && skillSetsData.monthsOfExperience === null
          ? `${missingField}${dateAbbreviationText.Year.toLowerCase()}`
          : `${skillSetsData?.yearsOfExperience ?? 0}${dateAbbreviationText.Year.toLowerCase()} ${
              skillSetsData.monthsOfExperience ?? 0
            }${dateAbbreviationText.Month.toLowerCase()}`,
      showAdditionalInfoTooltip: true,
      showIcon: false,
      icon: null,
    };
  }
  return resultData;
};

const transformUnitExperienceForTravelTile = (travelExpData: ITravelExperience) => {
  let resultData: StatusTileProps = null;
  if (!isNil(travelExpData?.experienceTypeId) && !isNil(travelExpData?.experienceTypeText)) {
    return {
      title: {
        text: travelExpData.experienceTypeText,
        showTooltip: true,
        tooltipText: travelExpData.experienceTypeText,
      },
      status: {
        text: travelExpData.isRequired ? StatusTileText.Required : StatusTileText.Preferred,
        showTooltip: true,
        tooltipText: travelExpData.isRequired ? StatusTileDescription.Required : StatusTileDescription.Preferred,
      },
      showAdditionalInfo: true,
      additionalInfo: !isNil(travelExpData.numberOfAssignments)
        ? `${travelExpData.numberOfAssignments ?? 0} # of asgmt.`
        : travelExpData?.yearsOfExperience === null && travelExpData.monthsOfExperience === null
        ? `${missingField}${dateAbbreviationText.Year.toLowerCase()}`
        : `${travelExpData?.yearsOfExperience ?? 0}${dateAbbreviationText.Year.toLowerCase()} ${
            travelExpData.monthsOfExperience ?? 0
          }${dateAbbreviationText.Month.toLowerCase()}`,
      showAdditionalInfoTooltip: true,
      showIcon: false,
      icon: null,
    };
  }
  return resultData;
};

export {
  mappedLicenses,
  mappedCertifications,
  mappedSkills,
  transformUnitList,
  checkQualificationCount,
  transformUnitExperienceForStatusTile,
  transformUnitSkillsetCollections,
  transformUnitExperienceForTravelTile,
};
