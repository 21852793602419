import { CustomTextField } from '@AMIEWEB/Placement/PlacementDetails/Edit/CustomComponents/CustomTextField';
import { Grid, Typography } from 'amn-ui-core';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const SellingPoints = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  return (
    <Grid container flexDirection="column" spacing={1} sx={{ marginBottom: '24px' }}>
      <Grid item>
        <Typography variant="body1" color="charcoal" fontWeight={500}>
          {t('Selling Points')}
        </Typography>
      </Grid>
      <Grid item>
        <Controller
          name="sellingPoints"
          control={control}
          render={({ ref, ...rest }) => <CustomTextField {...rest} variant="filled" size="medium" multiline />}
        />
      </Grid>
    </Grid>
  );
};
