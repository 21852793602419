import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { XGrid, XGridHistoricalHoverView, xGridDateSort } from '@AMIEWEB/Common';
import { XGridDefaultColumn } from '@AMIEWEB/Common/XGrid/cells/XGridDefaults';
import { XGridOverflowCell } from '@AMIEWEB/Common/XGrid/cells/XGridOverflowCell';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Grid, Typography } from 'amn-ui-core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const VirtualInterviewHistory = ({ rows = [], open, onClose, inviteId, interviewDate }) => {
  const { t } = useTranslation();
  const [columns] = React.useState(() => getColumns());
  const refreshData = () => {};
  const resetColumns = () => {};

  const grid = useMemo(
    () => (
      <XGrid
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        toolbarProps={{
          refreshButton: true,
          resetColumns: resetColumns,
          refreshGrid: refreshData,
        }}
        pagination={false}
        hideFooter
        inDialog
        height={500}
      />
    ),
    [columns, rows],
  );

  return (
    <GenericDialog
      open={open}
      draggable
      disableEnforceFocus
      onClose={onClose}
      dialogTitleProps={{
        text: t(
          'placement.profile.summary.interview.virtualInterviewContainer.interviewSpecs.history.virtualIntHistory',
        ),
        expandable: true,
        closeButton: true,
      }}
      fullWidth
      maxWidth={'md'}
      variant="blue"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SubTitle variant="body1">
            {t('placement.profile.summary.interview.virtualInterviewContainer.interviewSpecs.history.invite')}
            <strong>{` ${!inviteId ? 'N/A' : inviteId}, `}</strong>
            {`${t(
              'placement.profile.summary.interview.virtualInterviewContainer.interviewSpecs.history.reqDate',
            )} ${interviewDate}`}
          </SubTitle>
        </Grid>
        <Grid item xs={12}>
          {grid}
        </Grid>
      </Grid>
    </GenericDialog>
  );
};

const SubTitle = styled(Typography)`
  padding-top: 18px;
`;

const getColumns = (): GridColDef[] => [
  {
    ...XGridDefaultColumn,
    field: 'fieldChanged',
    headerName: 'Field Changed',
  },
  {
    ...XGridDefaultColumn,
    field: 'oldValue',
    headerName: 'Old Value',
    renderCell: XGridOverflowCell(XGridHistoricalHoverView)(),
  },
  {
    ...XGridDefaultColumn,
    field: 'newValue',
    headerName: 'New Value',
    renderCell: XGridOverflowCell(XGridHistoricalHoverView)(),
  },
  {
    ...XGridDefaultColumn,
    field: 'changedBy',
    headerName: 'Changed By',
  },
  {
    ...XGridDefaultColumn,
    field: 'changedDate',
    headerName: 'Date / Time',
    sortComparator: xGridDateSort,
  },
];
