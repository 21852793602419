/* eslint-disable i18next/no-literal-string */
import { Grid, Switch, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from '../DateRangeNew';
import { FilterCompTypes } from './utils';

const useStyles = makeStyles()({
  title: {
    color: '#333333',
    fontSize: 14,
    fontWeight: 500,
    alignSelf: 'center',
  },
});

export const SingleFilter = ({ type, filterName, titleToggle, value, onChange, ...props }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const _onToggle = event => {
    const val = props.onSelectFieldChange?.(event.target.checked);
    onChange?.(event.target.checked ? val : null);
  };

  const _onChange = value => {
    if (type === FilterCompTypes.DATERANGE && !value.startDate) {
      _onToggle({ target: { checked: true } });
    } else onChange?.(value);
  };

  return (
    <Grid container>
      <Grid item container xs={12}>
        <Grid item alignItems="center" classes={{ root: classes.title }}>
          {filterName}
        </Grid>
        {titleToggle && (
          <Grid item justifyContent="flex-end" style={{ display: 'flex', flex: '1 1 auto' }}>
            <Switch color="primary" onChange={_onToggle} checked={!!value} />
          </Grid>
        )}
      </Grid>
      {!titleToggle ||
        (titleToggle && value && (
          <Grid item xs={12}>
            {type === FilterCompTypes.DATERANGE ? (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography className="filter-title">{t('search.filter.orFilter.dateRange')}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <DateRangePicker
                    initialDateRange={{
                      startDate: value.startDate,
                      endDate: value.endDate,
                      label: value.label,
                      useLabelAsValue: value.useLabelAsValue,
                    }}
                    variant="outlined"
                    fullWidth
                    onApply={_onChange}
                    useMaxWidth
                    maxWidth={230}
                    definedRanges={props.definedRanges}
                  />
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        ))}
    </Grid>
  );
};
