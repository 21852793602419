import { Grid, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid } from 'app/layout/LayoutGrid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectPlacementCandidateRecords } from '../../../../../../store/redux-store/placement-details/selectors';
import { selectBookingInfo } from 'store/redux-store/placement-summary/selectors';

const usePlacementStrikeStatus = makeStyles()({
  row: {
    marginLeft: '0px',
    marginTop: '0px',
  },
  header: {
    fontSize: '14px',
    lineHeight: '30px',
    letterSpacing: '0px',
    color: '#555555',
    opacity: '1',
    fontWeight: 500,
  },

  info: {
    fontSize: '14px',
    lineHeight: '30px',
    letterSpacing: '0px',
    color: '#555555',
    opacity: '1',
    fontWeight: 400,
  },
});

export const CandidateStrikeStatus = () => {
  const { t } = useTranslation();
  const { strikeStatus } = useSelector(selectPlacementCandidateRecords);
  const {
    confirmation: { arrived },
  } = useSelector(selectBookingInfo);

  const { classes } = usePlacementStrikeStatus();

  return (
    <LayoutGrid className={classes.row} direction="column">
      <RowSection
        header={t('placement.profile.tabs.candidateStrikeStatus.travelStatus')}
        data={
          strikeStatus?.eventManagementStatus === '' || strikeStatus?.eventManagementStatus === null
            ? '--'
            : strikeStatus?.eventManagementStatus
        }
        id={'placementDetailsTabsCandidateStrikeStatusTravelStatus'}
      />

      <RowSection
        header={t('placement.profile.tabs.candidateStrikeStatus.schedulingStatus')}
        data={
          strikeStatus?.schedulingStatus === '' || strikeStatus?.schedulingStatus === null
            ? '--'
            : strikeStatus?.schedulingStatus
        }
        id={'placementDetailsTabsCandidateStrikeStatusschedulingStatus'}
      ></RowSection>
      <RowSection
        header={t('placement.profile.tabs.candidateStrikeStatus.arrived')}
        data={
          arrived?.data.checked
            ? t('placement.profile.tabs.candidateStrikeStatus.yes')
            : t('placement.profile.tabs.candidateStrikeStatus.no')
        }
        id={'placementDetailsTabsCandidateStrikeStatusArrived'}
      ></RowSection>
    </LayoutGrid>
  );
};

export const RowSection: React.FC<{ header: any; data: any; isNormalRow?: boolean; id?: string }> = props => {
  const { classes } = usePlacementStrikeStatus();
  return (
    <Grid container>
      <Grid item xs={5} className={classes.header}>
        <Typography style={{ wordBreak: 'keep-all' }}>{props.header}</Typography>
      </Grid>
      <Grid item xs={7} className={classes.info}>
        <Typography>{props.data}</Typography>
      </Grid>
    </Grid>
  );
};
