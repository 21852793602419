import { IChat } from 'app/models/Sms/IChat';
import { ISmsResult } from 'app/models/Sms/ISmsResult';

class MessageStack {
  public constructor(public messageStack: IChat | null) {}

  Push = (message: ISmsResult) => {
    this.messageStack!.results.push(message);
  };

  Reset = () => {
    this.messageStack = null;
  };
}

export default new MessageStack(null);
