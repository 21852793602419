/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { Grid, FormControlLabel, RadioGroup, Radio, Button, CircularProgress } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { FormProvider, useForm } from 'react-hook-form';
import { Cancel } from 'app/components/Common/CancelModal/Cancel';
import _cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { StrikeDetailsDatePicker } from './StrikeDetailsDatePicker';
import { IStrikeDetailsRequest, orderDataActions } from 'app/components/Order/Store/Order.redux';
import { selectOrderDetailsUIView, selectStrikeUpdateStatus } from 'app/components/Order/Store/Order.selectors';
import { selectUser } from 'oidc/user.selectors';
import { defaultValues, getSpecificHourDateString } from './Transformers/FormatData';

const editStrikeDetailsModalStyles = makeStyles()(theme => ({
  modalContainer: {
    '& .MuiDialog-paperFullWidth': {
      maxWidth: '444px',
      maxHeight: '704px',
      overflow: 'visible',
      '& .MuiDialogContent-root': {
        padding: '0',
        overflow: 'hidden',
      },
    },
    '& .MuiDialogActions-root': {
      height: '64px',
      backgroundColor: '#FFF',
      padding: '16px 24px',
    },
    '& .MuiTypography-h6': {
      userSelect: 'none',
      msUserSelect: 'none',
      MozUserSelect: 'none',
      WebkitUserSelect: 'none',
    },
  },
  body: {
    padding: '0px 24px',
    flexDirection: 'row',
  },
  section: {
    margin: '20px 0px 0px',
  },
  sectionHeader: {
    padding: '0px 0px 10px',
    display: 'flex',
    minHeight: '42px',
  },
  sectionFields: {
    '& .MuiFormControl-root': {
      width: '100%',
      maxWidth: 'none',
    },
  },
  field: {
    paddingBottom: '20px',
  },
  travelDateFields: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  sectionText: {
    font: 'normal bold 16px Roboto',
    userSelect: 'none',
    msUserSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
  },
  validationLoader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: '50%',
  },
  radioGroup: {
    marginLeft: '30px',
  },
  addBtnContainer: {
    float: 'right',
  },
  addBtn: {
    textTransform: 'none',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
}));

export const OrderDetailsStrikeDetailsEditModal = ({
  open,
  data,
  handleClose,
}: {
  open: boolean;
  data: IStrikeDetailsRequest;
  handleClose: () => void;
}) => {
  const { t } = useTranslation();
  const { classes } = editStrikeDetailsModalStyles();
  const isStrikeUpdated = useSelector(selectStrikeUpdateStatus);
  const selectOrderUiDetails = useSelector(selectOrderDetailsUIView);
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const [okToTravelSelection, setOkToTravelSelection] = useState<boolean>(true);
  const [numTravelDates, setNumTravelDates] = useState<number>(0);
  const [numOrientationDates, setNumOrientationDates] = useState<number>(0);
  const [isFormAltered, setIsFormAltered] = useState<boolean>(false);
  const { promiseInProgress: isCallingAPI } = usePromiseTracker({ area: 'save-client-contact', delay: 0 });
  const dispatch = useDispatch();
  const { userInfo } = useSelector(selectUser);
  const hoursToBeSet = 18;

  const editStrikeDetailsVar = {
    oKtoTravel: 'oKtoTravel',
    strikeNoticeDate: 'strikeNoticeDate',
    effectiveDate: 'effectiveDate',
    endDate: 'endDate',
  };

  const formMethods = useForm({
    mode: 'onSubmit',
    defaultValues: data ? data : defaultValues,
  });

  const {
    handleSubmit,
    watch,
    trigger,
    clearErrors,
    setValue,
    formState: { isDirty, errors },
  } = formMethods;
  const travelStartDate = watch('effectiveDate');
  const travelEndDate = watch('endDate');

  useEffect(() => {
    if (open) {
      setOkToTravelSelection(data?.oKtoTravel);
      countDates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, open]);

  useEffect(() => {
    if (isStrikeUpdated) {
      onCloseCall();
      dispatch(orderDataActions.setStrikeUpdateStatus(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStrikeUpdated]);

  useEffect(() => {
    setValue(editStrikeDetailsVar.oKtoTravel, okToTravelSelection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [okToTravelSelection]);

  const submitFormData = formData => {
    const startDate = getSpecificHourDateString(formData[editStrikeDetailsVar.effectiveDate], hoursToBeSet);
    const endDate = getSpecificHourDateString(formData[editStrikeDetailsVar.endDate], hoursToBeSet);
    const payload = _cloneDeep(formData) as IStrikeDetailsRequest;
    payload.facilityId = !data ? selectOrderUiDetails.facilityId : data?.facilityId;
    payload.orderId = !data ? parseInt(selectOrderUiDetails?.orderResponse?.orderDetails?.orderId) : data?.orderId;
    payload.orderStrikeNoticeId = data?.orderStrikeNoticeId;
    payload.oKtoTravel = okToTravelSelection;
    payload.strikeNoticeDate = getSpecificHourDateString(formData[editStrikeDetailsVar.strikeNoticeDate], hoursToBeSet);
    payload.effectiveDate = startDate;
    payload.endDate = endDate;
    payload.travelDate1 = startDate;
    payload.travelDate2 = !formData.travelDate2 ? null : getSpecificHourDateString(formData.travelDate2, hoursToBeSet);
    payload.travelDate3 = !formData.travelDate3 ? null : getSpecificHourDateString(formData.travelDate3, hoursToBeSet);
    payload.travelDate4 = !formData.travelDate4 ? null : getSpecificHourDateString(formData.travelDate4, hoursToBeSet);
    payload.travelDate5 = endDate;
    payload.lastUpdatedbyId = userInfo?.employeeId;
    dispatch(orderDataActions.updateOrderStrike(payload));
  };

  const onCloseCall = () => {
    setCancelModalOpen(false);
    setIsFormAltered(false);
    clearErrors();
    handleClose();
  };

  const onCloseModal = () => {
    if (!getIsFormAltered()) {
      setCancelModalOpen(true);
    } else {
      onCloseCall();
    }
  };

  const countDates = () => {
    let travelDateCount = 0;
    let orientationDateCount = 0;

    if (data?.travelDate1) {
      travelDateCount++;
    }
    if (data?.travelDate2) {
      travelDateCount++;
      orientationDateCount++;
    }
    if (data?.travelDate3) {
      travelDateCount++;
      orientationDateCount++;
    }
    if (data?.travelDate4) {
      travelDateCount++;
      orientationDateCount++;
    }
    if (data?.travelDate5) {
      travelDateCount++;
    }

    setNumTravelDates(travelDateCount);
    setNumOrientationDates(orientationDateCount);
  };

  const getOrientationDateValue = (i: number) => {
    if (i === 1) {
      return data?.travelDate2 ?? null;
    } else if (i === 2) {
      return data?.travelDate3 ?? null;
    } else if (i === 3) {
      return data?.travelDate4 ?? null;
    } else return null;
  };

  const getOrientationDateFields = () => {
    const orientationDateRows: JSX.Element[] = [];
    if (numOrientationDates > 0) {
      for (let i = 1; i <= numOrientationDates; i++) {
        orientationDateRows?.push(
          <Grid item className={classes.field}>
            <StrikeDetailsDatePicker
              name={'travelDate' + (i + 1).toString()}
              editStrikeDetailsVar={editStrikeDetailsVar}
              dateValue={getOrientationDateValue(i)}
              formMethods={formMethods}
              placeholder={t('order.createUnit.strikeDetails.strikeDetailsEditModal.dateLabel')}
            />
          </Grid>,
        );
      }
    }
    return orientationDateRows;
  };

  const getIsFormAltered = () => {
    if (isDirty || isFormAltered) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <FormProvider {...formMethods}>
      <GenericDialog
        className={classes.modalContainer}
        open={open}
        disableEnforceFocus
        variant="blue"
        onClose={onCloseModal}
        dialogTitleProps={{
          text: 'Edit Strike Details',
          closeButton: true,
        }}
        fullWidth
        maxWidth={'md'}
        dialogActions={[
          {
            text: 'cancel',
            variant: 'contained',
            color: 'tertiary',
            onClick: onCloseModal,
          },
          {
            text: 'save',
            variant: 'contained',
            disabled: getIsFormAltered(),
            onClick: async e => {
              const valid = await trigger();
              if (valid) {
                handleSubmit(submitFormData)().catch(err => {
                  console.log('ERROR: ', err.message | err);
                });
              }
            },
          },
        ]}
      >
        <Grid item className={classes.body}>
          {isCallingAPI && <CircularProgress className={classes.validationLoader} />}
          <Grid item className={classes.section}>
            <Grid item xs={12} className={classes.sectionHeader} style={{ whiteSpace: 'nowrap', width: 'fit-content' }}>
              <Grid item xs={12} className={classes.sectionText} style={{ margin: 'auto' }}>
                {t('order.createUnit.strikeDetails.okToTravelLabel')}
              </Grid>
              <RadioGroup
                name={editStrikeDetailsVar.oKtoTravel}
                row
                style={{ display: 'block' }}
                className={classes.radioGroup}
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={okToTravelSelection === true}
                      onChange={() => {
                        setOkToTravelSelection(true);
                        setIsFormAltered(true);
                      }}
                      required={true}
                    />
                  }
                  label={t('order.createUnit.strikeDetails.strikeDetailsEditModal.okToTravelOptions.yes')}
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={okToTravelSelection === false}
                      onChange={() => {
                        setOkToTravelSelection(false);
                        setIsFormAltered(true);
                      }}
                      required={true}
                    />
                  }
                  label={t('order.createUnit.strikeDetails.strikeDetailsEditModal.okToTravelOptions.no')}
                />
              </RadioGroup>
            </Grid>
            <Grid item className={classes.sectionFields}>
              <StrikeDetailsDatePicker
                name={editStrikeDetailsVar.strikeNoticeDate}
                editStrikeDetailsVar={editStrikeDetailsVar}
                dateValue={data?.strikeNoticeDate ?? null}
                formMethods={formMethods}
                placeholder={t('order.createUnit.strikeDetails.noticeDateLabel')}
              />
            </Grid>
          </Grid>
          <Grid item className={classes.section}>
            <Grid item xs={12} className={classes.sectionHeader} style={{ whiteSpace: 'nowrap', width: 'fit-content' }}>
              <Grid item xs={12} className={classes.sectionText} style={{ margin: 'auto' }}>
                {t('order.createUnit.strikeDetails.travelDatesLabel')}
              </Grid>
            </Grid>
            <Grid item className={classes.travelDateFields}>
              <StrikeDetailsDatePicker
                name={editStrikeDetailsVar.effectiveDate}
                editStrikeDetailsVar={editStrikeDetailsVar}
                DateCheck={travelEndDate}
                dateValue={data?.travelDate1 ?? null}
                formMethods={formMethods}
                placeholder={t('order.createUnit.strikeDetails.strikeDetailsEditModal.startDateLabel')}
              />
              <Grid item sx={{ margin: 'auto', mt: errors[editStrikeDetailsVar.endDate] ? '17px' : 'none' }}>
                {t('order.createUnit.strikeDetails.strikeDetailsEditModal.toLabel')}
              </Grid>
              <StrikeDetailsDatePicker
                name={editStrikeDetailsVar.endDate}
                editStrikeDetailsVar={editStrikeDetailsVar}
                DateCheck={travelStartDate}
                dateValue={data?.travelDate5 ?? null}
                formMethods={formMethods}
                placeholder={t('order.createUnit.strikeDetails.strikeDetailsEditModal.endDateLabel')}
              />
            </Grid>
          </Grid>
          <Grid item className={classes.section}>
            <Grid item xs={12} className={classes.sectionHeader}>
              <Grid item xs={12} className={classes.sectionText} style={{ margin: 'auto' }}>
                {t('order.createUnit.strikeDetails.orientationDatesLabel')}
              </Grid>
              <Grid item className={classes.addBtnContainer}>
                <Button
                  className={classes.addBtn}
                  variant='text'
                  title="Add"
                  size="small"
                  color="primary"
                  style={{ justifyContent: 'right', paddingRight: '0px' }}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  disabled={numOrientationDates > 2}
                  onClick={() => {
                    setNumTravelDates(numTravelDates < 6 ? numTravelDates + 1 : numTravelDates);
                    setNumOrientationDates(numOrientationDates < 4 ? numOrientationDates + 1 : numOrientationDates);
                  }}
                >
                  {t('order.createUnit.strikeDetails.strikeDetailsEditModal.addLabel')}
                </Button>
              </Grid>
            </Grid>
            <Grid item className={classes.sectionFields}>
              {getOrientationDateFields()}
            </Grid>
          </Grid>
        </Grid>
      </GenericDialog>
      <Cancel
        openDialog={cancelModalOpen}
        handleConfirmAction={() => onCloseCall()}
        handleCancelAction={() => {
          setCancelModalOpen(false);
        }}
      />
    </FormProvider>
  );
};
