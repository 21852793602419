import { Avatar, CircularProgress, Divider, Grid, IconButton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import { selectSearchResults } from '../../FacilityStore/Facility.selector';
import { facilityActions } from '../../FacilityStore/Facility.redux';
import { selectContactsGridData } from '../FacilityTabs/ContactTab/Store/FacilityContacts.selector';

const useStyles = makeStyles()(() => ({
  divider: {
    margin: '0 0 12px 0',
    height: 2,
  },
  avatarProfile: {
    height: 75,
    width: 75,
    marginRight: 10,
    fontSize: 30,
    backgroundColor: '#52429A',
    fontWeight: 500,
    textTransform: 'uppercase',
    position: 'inherit',
  },
  drawerRoot: {
    flexGrow: 1,
    width: 'inherit',
  },
  profileSegment: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '20px 20px',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: '-16%',
  },
  profileInfo: {
    padding: '0',
  },
  details: {
    wordBreak: 'keep-all',
    fontSize: '14px',

    padding: '4px 0px 4px 0px',

    display: 'flex',
  },
  closeIconPad: {
    // '&:hover': {
    //   backgroundColor: 'white',
    // },
    fontSize: '1.5rem',
    color: '#888888',
  },
  closeIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  bodyCandidateProfileDrawer: {
    paddingTop: '4rem',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '100vh',
  },
  profileDisplay: {
    display: 'flex',
  },
  headerContent: {
    fontSize: 16,
    fontWeight: 450,
    margin: '0 5%',
    color: '#888888',
  },
  drawerOrderIdItem: {
    'font-size': '0.9rem',
  },
  drawerLeftArraow: {
    position: 'relative',
    padding: '0',
    margin: '0',
    'margin-right': '0',
    'margin-left': '0.2rem',
    'font-size': '1.2rem',
    top: '-1px',
    cursor: 'pointer',
  },
  drawerRightArraow: {
    position: 'relative',
    padding: '0',
    margin: '0',
    'font-size': '1.2rem',
    'margin-left': '0.3rem',
    'margin-right': '1rem',
    top: '-1px',
    cursor: 'pointer',
  },
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50vh',
  },
  headerText: {
    fontSize: 16,
    fontWeight: 450,
    color: '#000',
  },
}));

export const FacilityContactsDrawerViewer = props => {
  const { items, count, positionCount, drawerData } = useSelector(selectSearchResults);
  const [contactDetails, setContactDetails] = useState<any>({});
  const [selectedIndex, setSelectedIndex] = useState<number>(positionCount || 0);
  const gridData = useSelector(selectContactsGridData);
  const { classes } = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const selIndexIn = gridData
      .map(function (e) {
        return e.contactId;
      })
      .indexOf(drawerData?.data?.selected?.contactId);
    setSelectedIndex(selIndexIn);
    setContactDetails(gridData[selIndexIn]);
  }, [drawerData, gridData]);

  const loadPreviousOrderItem = async e => {
    let selIndex = selectedIndex;
    if (selectedIndex > 0) {
      selIndex = selectedIndex - 1;
      setSelectedIndex(selIndex);
    } else {
      selIndex = gridData.length - 1;
      setSelectedIndex(selIndex);
    }

    setContactDetails(gridData[selIndex]);
  };

  const loadNextOrderItem = async e => {
    let selIndex = selectedIndex;
    if (selectedIndex <= gridData.length - 2) {
      selIndex = selectedIndex + 1;
      setSelectedIndex(selIndex);
    } else {
      selIndex = 0;
      setSelectedIndex(selIndex);
    }

    setContactDetails(gridData[selIndex]);
  };

  const handleClose = e => {
    dispatch(
      facilityActions.setSearchResults({
        items,
        count,
        positionCount,
        drawerData: { open: false, data: undefined },
      }),
    );
  };

  const genInitials = (firstName, lastName) => {
    return `${firstName?.substring(0, 1) || ''}${lastName?.substring(0, 1) || 'UNK'}`;
  };

  return (
    <>
      {!gridData || gridData.length <= 0 ? (
        <CircularProgress className={classes.MuiCircularProgressRoot} />
      ) : (
        <div className={classes.drawerRoot}>
          <LayoutGridItem container xs={12} lg={12} sm={12} md={12} direction="row" spacing={2}>
            <LayoutGridItem container xs={10} lg={10} sm={10} md={10}>
              <span className={`${classes.closeIcon} ${classes.headerContent}`}>
                <span
                  className={classes.drawerLeftArraow}
                  onClick={loadPreviousOrderItem}
                  onKeyDown={loadPreviousOrderItem}
                  aria-hidden="true"
                >
                  <KeyboardArrowLeftIcon />
                </span>
                <span
                  className={classes.drawerRightArraow}
                  onClick={loadNextOrderItem}
                  onKeyDown={loadNextOrderItem}
                  aria-hidden="true"
                >
                  <KeyboardArrowRightIcon />
                </span>
                <span className={classes.drawerOrderIdItem}>{`Client Contact Quick View`}</span>
              </span>
            </LayoutGridItem>
            <LayoutGridItem container xs={2} lg={2} sm={2} md={2}>
              <IconButton
                color="primary"
                onClick={handleClose}
                className={classes.closeIconPad}
                data-testid="dt-id-close-drawer"
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </LayoutGridItem>
          </LayoutGridItem>

          <Divider orientation="horizontal" classes={{ root: classes.divider }} />

          <Grid item xs={12} className={classes.bodyCandidateProfileDrawer} id="body-contact">
            <Grid item xs={12} className={classes.profileSegment}>
              <Grid item xs={3}>
                <Avatar data-avatar className={`${classes.avatarProfile}`}>
                  {genInitials(contactDetails?.firstName?.props?.displayValue, contactDetails?.lastName)}
                </Avatar>
              </Grid>
              <Grid item xs={9} className={classes.profileDisplay} justifyContent="space-between">
                <Grid item xs={12}>
                  <div className={classes.profileInfo}>
                    <Grid item className={classes.details}>
                      <Grid item xs={6} className={classes.headerText} justifyContent="space-between" container>
                        {`${contactDetails?.firstName?.props?.displayValue} ${contactDetails?.lastName}`}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Divider orientation="horizontal" classes={{ root: classes.divider }} />
        </div>
      )}
    </>
  );
};
