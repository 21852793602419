import { Grid, GridSize } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { SimpleCheckbox } from '../Common/SimpleCheckbox';
import { SingleLineInput } from '../Common/SingleLineInput';

interface Props {
  isComboChecked: boolean;
  handleComboChecked: (event: React.ChangeEvent<HTMLInputElement>) => void;
  comboDefaultValue: number | null;
  comboInputValue: number | null;
  comboInputName: string;
  comboCheckedLabel: string;
  comboCheckedName: string;
  handleComboChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  comboInputLabel: string;
  gridValue?: GridSize;
  fieldStyle?: string;
  isdisabled?: boolean;
  id?: string;
}

const useStyles = makeStyles()(() => ({
  label: {
    fontSize: 14,
    fontWeight: 600,
    paddingBottom: 15,
  },
}));

export const ComboSection = (props: Props) => {
  const { classes } = useStyles();
  const {
    isComboChecked,
    handleComboChange,
    handleComboChecked,
    comboCheckedLabel,
    comboCheckedName,
    comboDefaultValue,
    comboInputLabel,
    comboInputName,
    comboInputValue,
    gridValue,
    fieldStyle,
    isdisabled,
    id,
  } = props;
  return (
    <>
      <Grid item xs={gridValue ? gridValue : 6} container direction="column">
        <SimpleCheckbox
          id={id}
          isChecked={isComboChecked}
          handleChecked={handleComboChecked}
          checkboxLabel={comboCheckedLabel}
          checkboxName={comboCheckedName}
          labelStyle={classes.label}
          isdisabled={isdisabled}
        />
        {isComboChecked && (
          <SingleLineInput
            id={`${id}Txt`}
            inputName={comboInputName}
            inputDefaultValue={comboDefaultValue}
            inputLabel={comboInputLabel}
            inputValue={comboInputValue}
            handleChange={handleComboChange}
            inputType={'number'}
            fieldStyle={fieldStyle}
            isdisabled={isdisabled}
          />
        )}
      </Grid>
    </>
  );
};
