import React, { useEffect, useState } from 'react';
import { Grid, Typography } from 'amn-ui-core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CircularProgress } from 'amn-ui-core';
import { APIStatus } from 'app/enums/ApiStatus';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { TypeAhead } from 'app/components/Common/TypeAheads/TypeAhead';
import { useStyles } from 'app/ComponentLibrary/Filter/AutoCompleteTextBox/AutoCompleteTextBoxStyles';
import { useDispatch, useSelector } from 'react-redux';
import { orderCreationActions } from '@AMIEWEB/Order/Store/OrderCreation.actions';
import { selectBestBets } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { CustomTooltip } from '@AMIEWEB/Common';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { useParams } from 'react-router-dom';
import { selectFacility, selectHttpStatus } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { missingField } from 'app/constants';
import { httpSuccess } from 'app/services/serviceHelpers';

export interface IBestBets {
  id: string;
  isComingFromFacility: boolean;
  bestBetsId: string;
  bestBetsLabel: string;
  currentEmployeeId?: string;
  currentEmployeeName?: string;
}

export enum BestBetsType {
  FACILITY_BEST_BETS = 'FACILITY_BEST_BETS',
}

const useBestBetsStyle = makeStyles()(theme => ({
  icon: {
    cursor: 'pointer !important',
    width: '28px',
    padding: '1px',
    color: theme.palette.system.neutralGray,
    '&:hover': {
      borderRadius: '50%',
      background: theme.palette.system.lightSteelBlue,
      color: theme.palette.system.navyBlue,
    },
  },
}));

const mapBestBetsData = (bestBets: string, bestBetsId: number) => {
  if (bestBetsId) {
    return { id: bestBetsId.toString(), label: bestBets ?? missingField };
  }
  return { id: '', label: missingField };
};

const ManageBestBetsForFacility = () => {
  const params = useParams<{ id: string }>();
  const { classes: facilityClasses } = useStyles();
  const { classes } = useBestBetsStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const bestBetsDataOptions = useSelector(selectBestBets);
  const selectedFacility = useSelector(selectFacility);
  const httpStatus = useSelector(selectHttpStatus);
  const [open, setOpen] = useState<boolean>(false);
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<{ id: string; label: string }>();
  const [saveStatus, setSaveStatus] = useState<number | string>(APIStatus.isLoading);
  const isNetworkNotAvailable = 500;
  let showTimeout;
  let hideTimeout;

  const handleMouseEnter = () => {
    showTimeout = setTimeout(() => {
      setOpen(true);
    }, 500);
  };

  const handleMouseLeave = () => {
    clearTimeout(showTimeout);
    hideTimeout = setTimeout(() => {
      setOpen(false);
    }, 200);
  };

  useEffect(() => {
    if (isEditMode) dispatch(orderCreationActions.getBestBetsOptions());
  }, [isEditMode]);

  useEffect(() => {
    return () => {
      clearTimeout(showTimeout);
      clearTimeout(hideTimeout);
    };
  }, [showTimeout, hideTimeout]);

  const bestBetsOptions = bestBetsDataOptions?.map(item => {
    return {
      id: item.value,
      label: item.name,
    };
  });

  const handleSave = () => {
    setSaveStatus(APIStatus.isLoading);
    const requestPayload = {
      id: params.id,
      isComingFromFacility: true,
      bestBetsId: selectedValue.id.toString(),
      bestBetsLabel: selectedValue.label.toString(),
    };
    dispatch(facilityActions.updateBestBetsForFacility(requestPayload));
  };

  const handleCancel = () => {
    const savedData = mapBestBetsData(
      selectedFacility.facilityResponse?.bestBets,
      selectedFacility.facilityResponse?.bestBetsId,
    );
    setSelectedValue(savedData);
    setEditMode(false);
    setOpen(false);
  };

  const handleClose = () => {
    setSaveStatus('');
    setOpen(false);
    dispatch(facilityActions.setHttpStatus({ pageName: undefined, statusCode: undefined }));
  };

  const handleSuccess = () => {
    setEditMode(false);
    setSaveStatus(APIStatus.Completed);
    setTimeout(() => {
      handleClose();
    }, 3000);
  };

  useEffect(() => {
    const isAPIFailure = httpSuccess(httpStatus?.statusCode) || httpStatus?.statusCode === isNetworkNotAvailable;
    if (httpSuccess(httpStatus?.statusCode)) {
      handleSuccess();
    } else if (BestBetsType.FACILITY_BEST_BETS === httpStatus?.pageName && isAPIFailure) {
      setSaveStatus(APIStatus.Error);
    }
  }, [httpStatus?.statusCode, httpStatus?.pageName]);

  useEffect(() => {
    const savedData = mapBestBetsData(
      selectedFacility.facilityResponse?.bestBets,
      selectedFacility.facilityResponse?.bestBetsId,
    );
    setSelectedValue(savedData);
  }, [selectedFacility.facilityResponse?.bestBets, selectedFacility.facilityResponse?.bestBetsId]);

  return (
    <>
      <Grid container item xs={6} sx={{ alignItems: 'center' }}>
        <Grid item xs={7}>
          {isEditMode ? (
            <TypeAhead
              variant="standard"
              options={bestBetsOptions}
              popupIcon={<ExpandMoreIcon />}
              onChange={newValue => setSelectedValue(newValue)}
              isOptionsLoading={false}
              enableCustomScroll={true}
              removeCloseIcon
              inputValue={selectedValue?.label}
              value={selectedValue}
              isCustomTheme={true}
            />
          ) : (
            <Typography>{selectedFacility?.facilityResponse?.bestBets}</Typography>
          )}
        </Grid>

        {!isEditMode ? (
          <Grid item xs={3}>
            <CustomTooltip tooltipContent={t('facility.tooltip.edit')} open={open} isInlineContent>
              <CreateOutlinedIcon
                onClick={() => setEditMode(true)}
                className={`${classes.icon}`}
                onMouseEnter={() => handleMouseEnter()}
                onMouseLeave={() => handleMouseLeave()}
              />
            </CustomTooltip>
          </Grid>
        ) : (
          <Grid item xs={4}>
            <span>
              <span
                id="save-best-bets-btn"
                className={`${facilityClasses.inlineBoxEditItemPadding} ${facilityClasses.inlineBoxEditMainAcceptItem}`}
                onClick={handleSave}
                aria-hidden="true"
              >
                <CheckIcon data-testid="saveIcon" id="check-icon-best-bets-icon" />
              </span>

              <span
                id="close-best-bets-btn"
                className={`${facilityClasses.inlineBoxEditItemPadding} ${facilityClasses.inlineBoxEditMainRejectItem}`}
                onClick={() => {
                  handleCancel();
                }}
                onKeyDown={() => {
                  handleCancel();
                }}
                aria-hidden="true"
              >
                <CloseIcon data-testid="closeIcon" id="close-icon-best-bets-icon" />
              </span>
            </span>
          </Grid>
        )}
      </Grid>

      {saveStatus !== APIStatus.isLoading && (
        <Grid container item ml="174px">
          {saveStatus === APIStatus.Completed && (
            <>
              <CheckIcon sx={{ width: '15px', height: '15px', color: theme => theme.palette.system.paleDarkGreen }} />
              <label className={facilityClasses.saveSuccessText}>{`Saved`}</label>
            </>
          )}
          {saveStatus === APIStatus.Error && (
            <>
              <CloseIcon sx={{ width: '15px', height: '15px', color: theme => theme.palette.system.red }} />
              <label className={facilityClasses.saveFailedText}>{`Failed to update`}</label>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default ManageBestBetsForFacility;
