import { Grid, Paper } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentTitle, PreviewContentTitle } from '../../../OrderCreationForm/DefineUnit/UnitPreview/UnitPreviewDesign';
import { useSelector } from 'react-redux';
import { selectOrderStrike } from 'app/components/Order/Store/Order.selectors';
import EditIcon from '@mui/icons-material/Edit';
import { OrderDetailsStrikeDetailsEditModal as EditModal } from './OrderDetailsStrikeDetailsEditModal/OrderDetailsStrikeDetailsEditModal';
import { IStrikeDetailsRequest } from 'app/components/Order/Store/Order.redux';
import { FormatDate } from 'app/components/Notification/Tasks/Common/FormatData';
import { missingField } from 'app/constants';
import { selectUser } from 'oidc/user.selectors';
import { isAccountManagement } from 'oidc/userRoles';

const useStyles = makeStyles()(theme => ({
  strikeContainer: {
    '&:hover': {
      '& [data-id="edit-icon"]': {
        opacity: 1,
        transition: 'opacity 0.25s',
      },
    },
  },
  editItem: {
    opacity: 0,
    transition: 'opacity 0.25s',
  },
  editIcon: {
    height: '20px',
    width: '20px',
    cursor: 'pointer',
    color: '#8E9195',
  },
}));

export const OrderDetailsStrikeDetails = ({ disableEdit }: { disableEdit?: boolean }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { userInfo } = useSelector(selectUser);
  const data: IStrikeDetailsRequest = useSelector(selectOrderStrike);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [sortedOrientationDates, setSortedOrientationDates] = useState<any>([]);

  const onEditClick = () => {
    if(userInfo?.employeeId && isAccountManagement(userInfo.roles || [])){
      setOpenModal(true);
    }
  };

  const sortOrientationDates = () => {
    const Moment = require('moment');
    const orientationDates = [data?.travelDate2, data?.travelDate3, data?.travelDate4];
    const sortedDates = orientationDates.sort(
      (a, b) => new Moment(a).format('YYYYMMDD') - new Moment(b).format('YYYYMMDD'),
    );
    setSortedOrientationDates(sortedDates);
  };

  useEffect(() => {
    sortOrientationDates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Grid
        item
        xs={12}
        container
        className={classes.strikeContainer}
      >
        <Paper className="layout" variant="outlined" elevation={0}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <PreviewContentTitle>{t('order.createUnit.strikeDetails.title')}</PreviewContentTitle>
              {!disableEdit && (userInfo?.employeeId && isAccountManagement(userInfo.roles || [])) && (
                <Grid item className={classes.editItem} data-id="edit-icon">
                  <EditIcon className={classes.editIcon} onClick={onEditClick} />
                </Grid>
              )}
            </Grid>
            <Grid item xs={5}>
              <ContentTitle>{t('order.createUnit.strikeDetails.okToTravelLabel')}</ContentTitle>
            </Grid>
            <Grid item xs={7}>
              <Grid id={'okToTravelLabel'}>
                {data?.oKtoTravel !== null ? (data?.oKtoTravel ? 'Yes' : 'No') : missingField}
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <ContentTitle>{t('order.createUnit.strikeDetails.noticeDateLabel')}</ContentTitle>
            </Grid>
            <Grid item xs={7}>
              <Grid id={'noticeDateLabel'}>
                {data?.strikeNoticeDate ? FormatDate(data?.strikeNoticeDate, 'MM/DD/YYYY') : missingField}
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <ContentTitle>{t('order.createUnit.strikeDetails.travelDatesLabel')}</ContentTitle>
            </Grid>
            <Grid item xs={7}>
              <Grid id={'travelDates'}>
                {data?.travelDate1 && data?.travelDate5
                  ? `${FormatDate(data?.travelDate1, 'MM/DD/YYYY')} - ${FormatDate(data?.travelDate5, 'MM/DD/YYYY')}`
                  : missingField}
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <ContentTitle>{t('order.createUnit.strikeDetails.orientationDatesLabel')}</ContentTitle>
            </Grid>
            {data?.travelDate2 || data?.travelDate3 || data?.travelDate4 ? (
              <Grid item xs={7}>
                <Grid id={'orientationDates1'}>
                  {sortedOrientationDates[0] && FormatDate(sortedOrientationDates[0], 'MM/DD/YYYY')}
                </Grid>
                <Grid id={'orientationDates2'}>
                  {sortedOrientationDates[1] && FormatDate(sortedOrientationDates[1], 'MM/DD/YYYY')}
                </Grid>
                <Grid id={'orientationDates3'}>
                  {sortedOrientationDates[2] && FormatDate(sortedOrientationDates[2], 'MM/DD/YYYY')}
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={7}>
                <Grid id={'orientationDatesEmpty'}>{missingField}</Grid>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
      <EditModal
        open={openModal}
        data={data}
        handleClose={() => {
          setOpenModal(false);
        }}
      />
    </>
  );
};
