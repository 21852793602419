import { IGroupedSkillset } from '@AMIEWEB/Common/SkillsetTree/SkillsetTree';
import { TimeOffMode } from '@AMIEWEB/Common/TimeOffRequest';
import { orderBy } from 'lodash';
import { Concatenate } from 'utils/string/string';

export const deriveOrderSkillsetOpts = (skills: IGroupedSkillset[]) =>
  orderBy(skills, ['disciplineAbbr']).reduce((resultArray, item) => {
    const skillsetInfo = orderBy(item.specialties, ['specialtyAbbr']).map(specItem => ({
      value: Concatenate([item.disciplineAbbr, specItem.specialtyAbbr], ' - '),
      name: Concatenate([item.disciplineAbbr, specItem.specialtyAbbr], ' - '),
      object: {
        disciplineId: item.disciplineId,
        specialtyId: specItem.specialtyId,
        subSpecialtyId: specItem.subSpecialties[0]?.subSpecialtyId,
      },
    }));
    return [...resultArray, ...skillsetInfo];
  }, []);

export const formatFormDataBeforeSave = formData => {
  if (formData?.requestedTimeOff?.requestedTimeOffMode !== TimeOffMode.AddTimeOff) {
    formData.requestedTimeOff.isRequestedTimeOffMakeUp = false;
    formData.requestedTimeOff.requestedTimeOff = [];
  }
  return formData;
};
