import React, { useEffect, useState } from 'react';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { makeStyles } from 'tss-react/mui';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { IUpdateDrugScreenPolicyCommand } from '@AMIEWEB/Facility/FacilityStore/Facility.model';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFacilityDrugScreenPolicies,
  selectFacilityInstructions,
} from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { Cancel } from '@AMIEWEB/Common/CancelModal/Cancel';
import { Undo } from '@AMIEWEB/Common/UndoModal/Undo';
import { selectUser } from 'oidc/user.selectors';
import { TypeAhead } from '@AMIEWEB/Common/TypeAheads/TypeAhead';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(theme => ({
  dialogContainer: {
    minWidth: '600px',
    minHeight: '50px',
  },
  dialogContent: {
    padding: 0,
    overflow: 'hidden',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '14px 110px 24px 14px',
  },
  element: {
    padding: '10px',
    width: '470px',
  },
}));

interface EditDrugScreenPolicyProps {
  open: boolean;
  handleClose: () => void;
}

interface FormData {
  drugScreenPolicy: {
    id: number;
    label: string;
  };
  sendDrugScreen: boolean;
}
export const EditDrugScreenPolicy = ({ open, handleClose }: EditDrugScreenPolicyProps) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const policies = useSelector(selectFacilityDrugScreenPolicies);
  const userDetails = useSelector(selectUser);
  const facilityInstructions = useSelector(selectFacilityInstructions);
  const [undoDialogOpen, setUndoDialogOpen] = useState<boolean>(false);
  const methods = useForm<FormData>({
    defaultValues: {
      drugScreenPolicy:
        facilityInstructions?.marijuanaPolicyID && facilityInstructions?.marijuanaPolicyName
          ? {
              id: facilityInstructions?.marijuanaPolicyID,
              label: facilityInstructions?.marijuanaPolicyName,
            }
          : null,
      sendDrugScreen: facilityInstructions?.isSendDrugScreen,
    },
    mode: 'all',
  });
  const { errors, control, reset, getValues, formState } = methods;
  const { isDirty, isValid } = formState;
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(facilityActions.getFacilityDrugScreenPolicies());
  }, []);

  const _onClose = () => {
    if (isDirty) setConfirmDialogOpen(true);
    else handleClose();
  };

  const handleOnSave = () => {
    const { drugScreenPolicy, sendDrugScreen } = getValues();
    const payload: IUpdateDrugScreenPolicyCommand = {
      facilityId: facilityInstructions.facilityId,
      drugScreenPolicyID: Number(drugScreenPolicy.id),
      drugScreenPolicyName: drugScreenPolicy.label,
      isSendDrugScreen: sendDrugScreen,
      currentEmployeeId: userDetails.userInfo.employeeId,
      currentEmployeeName: userDetails.userInfo?.firstName + ' ' + userDetails.userInfo?.lastName,
    };
    dispatch(
      facilityActions.requestUpdateDrugScreenPolicy({
        updateDrugScreenPolicy: payload,
      }),
    );
    handleClose();
  };
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

  const onDiscardChanges = () => {
    setConfirmDialogOpen(false);
    handleClose();
  };

  const onUndoChanges = () => {
    reset();
    setUndoDialogOpen(false);
  };
  const onUndoClick = () => {
    if (isDirty) setUndoDialogOpen(true);
  };

  return (
    <>
      <GenericDialog
        variant={'blue'}
        classes={{
          paper: classes.dialogContainer,
        }}
        open={open}
        onClose={_onClose}
        disablePortal
        disableEnforceFocus
        disableEscapeKeyDown
        dialogTitleProps={{
          text: t('facility.drugScreenPolicy.editDrugScreenPolicy'),
          closeButton: true,
          expandable: true,
          undoButton: true,
          onUndoClick: onUndoClick,
          disableUndo: !isDirty,
        }}
        dialogContentProps={{
          classes: { root: classes.dialogContent },
        }}
        dialogActions={[
          {
            text: t('global.button.cancel'),
            variant: 'contained',
            color: 'tertiary',
            onClick: _onClose,
          },
          {
            text: t('global.button.save'),
            variant: 'contained',
            color: 'primary',
            type: 'submit',
            disabled: !isDirty || !isValid,
            onClick: () => handleOnSave(),
          },
        ]}
      >
        <form>
          <FormProvider {...methods}>
            <div className={classes.container}>
              <div className={classes.element}>
                <Controller
                  name="drugScreenPolicy"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ ref, onChange, ...rest }) => (
                    <TypeAhead
                      options={policies || []}
                      label="Drug Screen Policy"
                      onChange={onChange}
                      {...rest}
                      isError={errors['drugScreenPolicy']}
                    />
                  )}
                />
              </div>
            </div>
          </FormProvider>
        </form>
      </GenericDialog>
      <Cancel
        openDialog={confirmDialogOpen}
        handleCancelAction={() => {
          setConfirmDialogOpen(false);
        }}
        handleConfirmAction={onDiscardChanges}
        cancelButtonId={'facility-drug-screen-policy-discard-changes-cancel-button'}
        confirmButtonId={'facility-drug-screen-policy-discard-changes-confirm-button'}
      />
      <Undo
        openDialog={undoDialogOpen}
        handleConfirmAction={onUndoChanges}
        handleCancelAction={() => {
          setUndoDialogOpen(false);
        }}
      />
    </>
  );
};
