import { makeStyles } from 'tss-react/mui';

export const useCredentialsAddHearsayStyles: any = makeStyles()({
  textInput: {
    height: 14,
  },
  deleteText: {
    color: '#555555',
  },
  deleteIcon: {
    '&:hover': {
      color: '#F4574A',
      background: 'none',
    },
  },
});

export const useLicenseAddHearsayStyles: any = makeStyles()({
  textInput: {
    height: 14,
  },
  datePickerInput: {
    height: '55px',
  },
  deleteText: {
    color: '#555555',
  },
  deleteIcon: {
    '&:hover': {
      color: '#F4574A',
      background: 'none',
    },
  },
});

export const useRequestReferenceFormFieldStyles: any = makeStyles()({
  textarea: {
    minHeight: 90,
      maxHeight: 90,
      overflow: 'scroll !important',
  },
});

export const useRequestReferenceModalStyles: any = makeStyles()({
  dialogContainer: {
    width: '100%',
    minWidth: '600px',
    minHeight: '420px',
  },
  dialogContent: {
    paddingTop: 0,
    paddingRight: '10px',
    overflow: 'hidden',
    'overflow-y': 'scroll',
  },
  dailogActions: {
    marginBottom: '8px',
    marginLeft: '4px',
  },
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50%',
  },
});
