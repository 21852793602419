import { gridStateActions, GridTag } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import { selectCredExpiringDaysGrid } from 'app/components/Common/WorkDesk/PipelineChoice/pipelineChoice.selectors';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { WorkDeskGrid } from '../../WorkDesk/WorkDeskGrid';

export const ExpiringCredentialsTable = props => {
  const { tableColumns, tableData } = props;
  const gridSelection = useSelector(selectCredExpiringDaysGrid);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      gridStateActions.setGridState({
        gridTag: GridTag.CredentialsExpiringDocumentGrid,
        pageSize: 20,
        pageNumber: 1,
        previewItemId: -1,
        showPreviewDialog: false,
        sortedColumn: { column: 'expirationDate', direction: 'asc' },
        filterAttributes: undefined,
      }),
    );
  }, [dispatch]);

  return (
    <>
      <WorkDeskGrid
        tableColumns={tableColumns}
        tableData={tableData}
        gridName={GridTag.CredentialsExpiringDocumentGrid}
        resultsTitle={t('home.credentialingWorkDesk.gridTitle')}
        gridSelected={gridSelection}
      />
    </>
  );
};
