export enum FacilityMenuItems {
  Overview = 'Overview',
  CreateNewOrder = 'Create New Order',
  CreateNewUnit = 'Create New Unit',
}

export enum FacilityStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Prospect = 'Prospect',
  Delete = 'Delete',
  Closed = 'Closed',
  Pending = 'Pending',
}

export enum Status {
  Pending = 1,
  Rejected = 2,
  PendingApproval = 3,
  Approved = 4,
  Expired = 5,
  Void = 6,
  Unsaved = 7,
}

export enum RateSource {
  Facility = 1,
  Contract = 2,
  default = 3,
}

export enum RequirementType {
  cert = 122,
}

export enum RestrictedDivisions {
  Nursing = 'AMN Nurse',
}
