import moment from 'moment';
import { CredentialingExpiringDetails } from '../Models/ExpiringDocumentInterfaces';

//
// ─── GENERATE THE FLATTEN DATA FOR GRID ─────────────────────────────────────────
//
export const ExpiringFlattenData = (data): CredentialingExpiringDetails[] => {
  const Data: CredentialingExpiringDetails[] = [];
  data.forEach(item => {
    const checkData: CredentialingExpiringDetails = {
      placementId: parseInt(item.placementId),
      candidateName: `${item.candidateName.firstName ?? ''} ${item.candidateName.lastName ?? ''}`,
      credential: item.credential,
      daysUntilExpiration: daysLeftForExpiration(item.expirationDate),
      expirationDate: item.expirationDate ? moment(item.expirationDate).format('MM/DD/yyyy') : '',
      expirationDateNoFormat: item.expirationDate,
      facilityId: item.facilityId,
      facilityName: item.facilityName,
      facilityNameLower: item.facilityName?.toLowerCase(),
      credentialCategory: item.credentialCategory,
      credentialGroup: item.credentialGroup,
      candidateId: parseInt(item.candidateId),
      brandId: item.brandId,
      clinicianLastName: `${item.candidateName.lastName?.toLowerCase() ?? ''}`,
      tier: item.tier,
      statusAbbr: item.statusAbbr,
      requirementId: item.requirementId,
    };
    if (!Data.includes(checkData)) {
      Data.push(checkData);
    }
  });
  return Data.sort(dateSort);
};

//
// ─── COMMON FUNCTIONS ───────────────────────────────────────────────────────────
//
const dateSort = (a: any, b: any) => (moment.utc(a.startDate) as any) - (moment.utc(b.startDate) as any);

const daysLeftForExpiration = a => {
  const date1: any = moment(a).format('MM/DD/YYYY');
  const difference = Math.abs(moment(moment().format('MM/DD/YYYY')).diff(date1, 'days'));
  return difference;
};
