import { Button, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { selectTaskStickChosen } from 'app/components/Tasks/store/Tasks.selectors';
import { withReadOnly } from 'oidc/withReadOnly';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useStyles = makeStyles<{ taskShrink }>()((theme, { taskShrink }) => ({
  container: {
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    height: 60,
    position: 'fixed',
    padding: 12,
    right: 0,
    bottom: 0,
    left: 47,
    width: taskShrink ? 'calc(100vw - 62px)' : 'calc(100vw - 386px)',
  },
  elevateContainer: {
    zIndex: theme.zIndex.drawer - 1,
    backdropFilter: 'blur(2px)',
  },
  lowerContainer: {
    zIndex: 0,
  },
  item: {
    margin: '0px 6px',
  },
}));

const PageSaveBtnAccessor = 'page-save-change-action';
const PageClearBtnAccessor = 'page-clear-change-action';

export interface IElementAccessor {
  id: string;
  action: 'focus' | 'click';
  value: any;
}

const stopImmediatePropogation = event => {
  event.preventDefault();
  event.stopPropagation();
};

export const PageActions = ({
  elevate = true,
  disableClearBtn = false,
  disableSaveBtn = false,
  disableCancelButton = false,
  isPageDirty = false,
  hideActions = false,
  accessibleItems = [] as IElementAccessor[],
  currentFocus = null as string | null,
  keydownListnerDependancies = [] as any[],
  disableAllTabbing = false,
  withoutSubmitBtn = false,
  handleSubmit = undefined,
  ...props
}) => {
  const taskStickChosen = useSelector(selectTaskStickChosen);
  const { t } = useTranslation();
  // const classes = useStyles();
  const { classes } = useStyles({ taskShrink: taskStickChosen.stick === null });

  const disableAllTabbingRef = React.useRef(disableAllTabbing);

  React.useEffect(() => {
    disableAllTabbingRef.current = disableAllTabbing;
  }, [disableAllTabbing]);

  const [clearChangeAction, setClearChangeAction] = useState(false);

  const handleClearChanges = () => {
    setClearChangeAction(false);
    props.onClearChanges();
  };

  /**
   * Keydown handler for custom behaviors
   * Use stopImmediatePropogation only for specific keys required - should not hinder unspecified keys
   * @param event
   * @returns void
   */
  const pageKeyDownHandler = event => {
    // Save action: User clicks (ctrl + S) keys
    if (!disableSaveBtn && event.keyCode === 83 && (event.metaKey || event.ctrlKey)) {
      const saveBtn = document.getElementById(PageSaveBtnAccessor);
      saveBtn?.click();

      stopImmediatePropogation(event);
      return;
    }

    // Tabbing action - User clicks on tab key
    if (!disableAllTabbingRef.current && (event.keyCode || event.which) === 9) {
      const accessors = [...accessibleItems];
      !disableSaveBtn && accessors.push({ id: PageSaveBtnAccessor, value: 'save-action', action: 'focus' });
      !disableClearBtn && accessors.push({ id: PageClearBtnAccessor, value: 'clear-action', action: 'focus' });

      const currentIndex = accessors.findIndex(accessor => accessor?.id === currentFocus);
      const accesssor = accessors[currentIndex !== accessors.length - 1 ? currentIndex + 1 : 0];
      props.setCurrentFocus?.(accesssor?.id);
      const element = document.getElementById(accesssor?.id);

      // focus is necessary to get scrolled to the element for all tabbing, while click is conditional
      element?.focus();
      if (accesssor?.action === 'click') element?.click();

      stopImmediatePropogation(event);
      return;
    }

    // Undo action - User clicks on (ctrl + Z) keys
    if (event.keyCode === 90 && (event.metaKey || event.ctrlKey)) {
      props.undoLastEdit();

      stopImmediatePropogation(event);
      return;
    }
  };

  const confirmExit = event => {
    event.preventDefault();
    return (event.returnValue = 'Are you sure');
  };

  useEffect(() => {
    if (isPageDirty) {
      window.addEventListener('beforeunload', confirmExit);
    } else {
      window.removeEventListener('beforeunload', confirmExit);
    }
    return () => {
      window.removeEventListener('beforeunload', confirmExit);
    };
  }, [isPageDirty]);

  useEffect(() => {
    document.addEventListener?.('keydown', pageKeyDownHandler);
    return () => document.removeEventListener?.('keydown', pageKeyDownHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessibleItems, currentFocus, disableSaveBtn, disableClearBtn, ...keydownListnerDependancies]);

  return (
    <>
      {!hideActions && (
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          classes={{ container: classes.container, root: elevate ? classes.elevateContainer : classes.lowerContainer }}
          onClick={props.onActionClick}
        >
          <Grid item classes={{ item: classes.item }}>
            <Button
              id={PageClearBtnAccessor}
              variant="contained"
              color="tertiary"
              size="large"
              disableTouchRipple
              disableFocusRipple
              disabled={disableClearBtn}
              type="button"
              onClick={() => setClearChangeAction(true)}
            >
              {t('detailsPage.clearChanges')}
            </Button>
          </Grid>
          <Grid item classes={{ item: classes.item }}>
            {withReadOnly(Button)({
              id: PageSaveBtnAccessor,
              type: withoutSubmitBtn ? 'button' : 'submit',
              onClick: handleSubmit,
              variant: 'contained',
              color: 'primary',
              size: 'large',
              disableTouchRipple: true,
              disableFocusRipple: true,
              disabled: disableSaveBtn,
              children: t('detailsPage.saveChanges'),
            })}
          </Grid>
        </Grid>
      )}
      <GenericDialog
        open={clearChangeAction}
        dialogTitleProps={{ text: t('detailsPage.undoConfirmation') }}
        maxWidth="sm"
        dialogActions={[
          {
            text: t('detailsPage.confirmation.no'),
            variant: 'text',
            color: 'tertiary',
            onClick: () => setClearChangeAction(false),
            hidden: !disableCancelButton,
          },
          {
            text: t('detailsPage.confirmation.yes'),
            variant: 'contained',
            onClick: handleClearChanges,
          },
        ]}
      />
    </>
  );
};
