import { CountryPhoneNumber } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/PersonalInfoTab/Form/EmergencyContact/CountryPhoneNumber';
import { Grid, StyledEngineProvider, TextField } from 'amn-ui-core';
import React, {  useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { separateCountryCodeAndNumber } from 'app/helpers/objectHelpers';

const useSubStyles = makeStyles()(theme => ({
  headerContainer: {
    minWidth: 250,
    padding: '0px 14px 5px 14px',
    maxWidth: '541px',
  },
  textField: {
    color: '#52429A',
    width: '120px',
    height: '36px',
  },
  phonelabel: {
    '&.react-tel-input': {
      width: '100%',
    },
    '& .flag-dropdown': {
      borderBottomColor: '#cacaca !important',
    },
    '&.special-label': {
      display: 'inline-flex',
      position: 'absolute',
      top: '5px',
      left: '70px',
      background: 'transparent',
      fontSize: '11px',
      color: theme.palette.system.darkGrey,
    },
  },
  focusedLabel: {
    fontSize: '16px !important', // Font size on focus
  },
  label: {
    left: '-6px !important',
    fontSize: '12px !important',
  },
}));

export interface ICandidateSubSearch {
  firstName: string;
  lastName: string;
  goesBy: string;
  cid: string;
  otherName: string;
  phoneNumber: string;
  email: string;
}

export const CandidateSubSearch: React.FC<{
  value: ICandidateSubSearch;
  onChange: (state: ICandidateSubSearch) => void;
}> = ({ value, onChange }) => {
  const { classes } = useSubStyles();
  const { t } = useTranslation();
  const [allTextSelected, setAllTextSelected] = useState<boolean>(false);
  
  const handleChange = React.useCallback(
    (searchProps: { [key in keyof ICandidateSubSearch]?: string }) => {
      const filterKey = Object.keys(searchProps);

      searchProps[filterKey[0]] = searchProps[filterKey[0]] ? searchProps[filterKey[0]].trim() : null;
      onChange({ ...value, ...searchProps });
    },
    [onChange, value],
  );

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-end"
      spacing={1}
      classes={{
        root: classes.headerContainer,
      }}
    >
      <Grid item>
        <TextField
          value={value?.firstName ?? ''}
          variant="outlined"
          size="small"
          className={classes.textField}
          label={<SearchLabel label={t('search.filter.candidateSearch.firstName')} />}
          inputProps={{ maxLength: 225 }}
          InputLabelProps={{ classes: { root: classes.label, focused: classes.focusedLabel } }}
          onChange={event => handleChange({ firstName: event.target?.value })}
        />
      </Grid>
      <Grid item>
        <TextField
          value={value?.lastName ?? ''}
          variant="outlined"
          size="small"
          className={classes.textField}
          label={<SearchLabel label={t('search.filter.candidateSearch.lastName')} />}
          inputProps={{ maxLength: 225 }}
          InputLabelProps={{ classes: { root: classes.label, focused: classes.focusedLabel } }}
          onChange={event => handleChange({ lastName: event.target?.value })}
        />
      </Grid>
      <Grid item>
        <TextField
          value={value?.goesBy ?? ''}
          variant="outlined"
          size="small"
          className={classes.textField}
          label={<SearchLabel label={t('search.filter.candidateSearch.goesBy')} />}
          inputProps={{ maxLength: 225 }}
          InputLabelProps={{ classes: { root: classes.label, focused: classes.focusedLabel } }}
          onChange={event => handleChange({ goesBy: event.target?.value })}
        />
      </Grid>
      <Grid item>
        <TextField
          value={value?.cid ?? ''}
          variant="outlined"
          size="small"
          className={classes.textField}
          label={<SearchLabel label={t('search.filter.candidateSearch.cid')} />}
          InputLabelProps={{ classes: { root: classes.label, focused: classes.focusedLabel } }}
          onChange={event => handleChange({ cid: event.target?.value })}
        />
      </Grid>
      <Grid item>
        <TextField
          value={value?.otherName ?? ''}
          variant="outlined"
          size="small"
          className={classes.textField}
          label={<SearchLabel label={t('search.filter.candidateSearch.otherName')} />}
          InputLabelProps={{ classes: { root: classes.label, focused: classes.focusedLabel } }}
          onChange={event => handleChange({ otherName: event.target?.value })}
        />
      </Grid>
      <Grid item xs={5.9} sx={{ paddingRight: '-5px !important' }}>
        <StyledEngineProvider injectFirst>
          <CountryPhoneNumber
            className={classes.phonelabel}
            name="PhoneNum"
            isDisabled={false}
            value={value?.phoneNumber ?? '1'}
            label={t('search.filter.candidateSearch.phone')}
            onChange={(value, data, event, formattedValue) => {
              //  Preventing the entry of only the country code.
              if (separateCountryCodeAndNumber(formattedValue) !== null) {
                handleChange({ phoneNumber: formattedValue });
              } else {
                handleChange({ phoneNumber: null });
              }
            }}
            countryCodeEditable={false}
            onKeyDown={event => {
              if ((event.key == 'Backspace' || event.key == 'Delete') && allTextSelected) {
                handleChange({ phoneNumber: null });
                setAllTextSelected(false);
              }
              if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
                setAllTextSelected(true);
              }
            }}
            inlineStyle={{
              height: '36px !important',
              border: '1px solid #cacaca !important',
              background: '#fff !important',
              borderRadius: '4px',
              borderBottomLeftRadius: '4px',
              borderBottomRightRadius: '4px',
              borderBottom: '1px solid #cacaca !important',
            }}
          />
        </StyledEngineProvider>
      </Grid>
      <Grid item>
        <TextField
          value={value?.email ?? ''}
          variant="outlined"
          size="small"
          className={classes.textField}
          label={<SearchLabel label={t('search.filter.candidateSearch.email')} />}
          InputLabelProps={{ classes: { root: classes.label, focused: classes.focusedLabel } }}
          onChange={event => handleChange({ email: event.target?.value })}
        />
      </Grid>
    </Grid>
  );
};

const SearchLabel = ({ label }) => (
  <>
    <SearchIcon style={{ opacity: 0.6 }} /> <span>{label}</span>
  </>
);
