import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { ProgressListItem } from 'app/models/Credentialing/ProgressList';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectStateID } from '../Store/PlacementReminders.selector';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles<{ spacing: number }>()((theme, props) => ({
  handLink: {
    cursor: 'default',
  },
  expiringStatusDays: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: '#333333',
    padding: 15,
    alignItems: 'center',
    '&[data-index="0"]': {
      borderTopLeftRadius: '4px',
    },
    '&[data-index="2"]': {
      borderTopRightRadius: '4px',
    },
  },
  expiringStatusDaysActive: {
    backgroundColor: '#006FB9',
    color: '#fff !important',
  },
  paperLayout: {
    display: 'flex',
  },
  tierLayout: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    borderTop: '1px solid #ECECEC',

    height: 103,
    alignItems: 'center',
  },
  tierLayoutActive: {
    background: 'linear-gradient(0deg, #FFFFFF 0%, #006fb92b 100%)',
  },
  rootStructure: {
    border: '1px solid #ECECEC',
    '&[data-index="0"]': {
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
    },
    '&[data-index="2"]': {
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
    },
  },
  borderLeft: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  borderRight: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  tag: {
    fontWeight: 500,
    fontSize: 12,
    textTransform: 'uppercase',
    width: '100%',
    textAlign: 'center',
  },
  tierValue: {
    fontSize: 16,
    color: '#333333',
    fontWeight: 600,
  },
  clickLink: {
    cursor: 'pointer',
  },
  tooltipPlacementBottom: {
    margin: 0,
    marginTop: 22,
  },
  checkMarkNoVisibility: {
    visibility: 'hidden',
  },
  checkMarkVisibility: {
    color: '#006FB9',
  },
}));

export const ReminderGraphicStepper = ({
  items,
  onItemSelected,
  spacing = 'even',
}: {
  items: ProgressListItem[];
  selectedItem?: any;
  onItemSelected?: (id, count) => void;
  spacing?: number | 'even';
  dividers?: boolean;
  loading?: boolean;
  error?: boolean;
}) => {
  const spacingNumber: number = spacing === 'even' ? 100 / items.length : (spacing as number);
  const { classes } = useStyles({ spacing: spacingNumber });
  const { t } = useTranslation();
  const statusId = useSelector(selectStateID);

  const StatusItem = ({ item, index }: { item: ProgressListItem; index: number }) => {
    return (
      <Grid
        data-index={index}
        container
        direction="column"
        className={` ${item.data?.count === 0 ? classes.borderLeft : ''} ${
          item.data?.count === -1 ? classes.borderRight : ''
        } ${classes.rootStructure}`}
      >
        <Grid
          className={item.data?.count ? classes.clickLink : classes.handLink}
          onClick={() => {
            onItemSelected?.(item.data.id, item.data.count);
          }}
          style={{ textDecoration: 'none', color: '#003E68' }}
        >
          <Grid
            item
            xs={12}
            data-index={index}
            className={
              statusId === item.data.id
                ? `${classes.expiringStatusDays} ${classes.expiringStatusDaysActive}`
                : classes.expiringStatusDays
            }
          >
            <Grid item className={classes.tag}>
              {item.data.title}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className={
              statusId === item.data.id
                ? `${classes.tierLayout} ${classes.tierLayoutActive} ${
                    item.data?.count ? classes.clickLink : classes.handLink
                  }`
                : `${classes.tierLayout} ${item.data?.count ? classes.clickLink : classes.handLink}`
            }
            onClick={() => {
              onItemSelected?.(item.data.id, item.data.count);
            }}
          >
            <Grid item className={classes.tierValue}>
              {item.data?.count ? (
                <div className={classes.tierValue}>{item.data?.count}</div>
              ) : (
                <CustomTooltip
                  tooltipContent={t('home.credentialingWorkDesk.pipeline.noValueText')}
                  tooltipPlacementBottom={classes.tooltipPlacementBottom}
                >
                  <div className={classes.tierValue}>{item.data?.count}</div>
                </CustomTooltip>
              )}
            </Grid>
            <Grid item className={classes.tierValue}>
              <CheckIcon
                fontSize="small"
                className={statusId === item.data.id ? classes.checkMarkVisibility : classes.checkMarkNoVisibility}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container direction="column">
      <Grid container>
        <Grid item xs={12} className={classes.paperLayout}>
          {items.map((item, index) => (
            <StatusItem item={item} index={index} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
