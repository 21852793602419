/* eslint-disable i18next/no-literal-string */
import React from 'react';
import { Checkbox, Grid, Paper } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import styledComponent from 'styled-components';
import {
  detailColumnsAndRows,
  getCredentialName,
} from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/CredentialsTab/TableData/tableHelpers';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { gridControlType } from 'app/models/Grid';
import moment from 'moment';

const useStyles = makeStyles()(theme => ({
  paperOverride: {
    padding: '22px 25px 25px 22px !important',
  },
}));

export const AttachementsCredentialDetails = props => {
  const { t } = useTranslation();
  const credential = props.credentials;
  const { reqTypeId } = useParams<any>();
  let detailRows: any = [];

  const _Lookups = props.lookups
    ?.filter(item => item.requirementTypeID === parseInt(reqTypeId))
    .sort((a, b) => (a.sequence < b.sequence ? 1 : -1));
  const { classes } = useStyles();

  detailRows = detailColumnsAndRows(credential?.credential, _Lookups, [], true);

  const getControl = (col: any, rows: any) => {
    const value = rows[col.title];
    switch (col.control?.type) {
      case gridControlType.dropDown:
        return rows[`${col.title}FilterOptions`].find(item => item.value === rows[col.title])?.description || 'N/A';
      case gridControlType.date:
      case gridControlType.dateLabel:
        return value && value !== 'N/A' ? moment(value).format('MM/DD/yyyy') : 'N/A';
      case gridControlType.checkbox:
        return (
          <Checkbox
            style={value ? { padding: '0px 0px', color: '#2eb82e' } : { padding: '0px 0px' }}
            size="small"
            color="primary"
            checked={value}
            disabled
          />
        );
      default:
        return value || 'N/A';
    }
  };

  const getRow = (col: any, rows: any) => {
    return (
      <Grid item>
        <Row>
          <RowHead>{col.value}</RowHead>
          <RowContent>{getControl(col, rows)}</RowContent>
        </Row>
      </Grid>
    );
  };
  return (
    <>
      <Paper className={`paper ${classes.paperOverride}`} elevation={0}>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Title>{t('candidate.credentials.attachments.credentialDetails.title')}</Title>
          </Grid>
          <Grid item container spacing={1} direction="column" justifyContent="space-between">
            <Grid item>
              <Row>
                <RowHead>{t('candidate.credentials.attachments.credentialDetails.credName')}</RowHead>
                <RowContent>
                  {getCredentialName(credential?.credential?.requirementType, credential?.credential?.credentialName)}
                </RowContent>
              </Row>
            </Grid>
            {detailRows?.map((mainRow, mainRowIndex) => {
              const { columns, rows } = mainRow;
              return columns?.map((col, rowIndex) => {
                return getRow(col, rows);
              });
            })}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default AttachementsCredentialDetails;

const Row = styledComponent.tr`
  display: flex;
  justify-content: space-between;
  padding: 5px;
`;

const RowHead = styledComponent.th`
  color: #333333;
  font-size: 12px;
  text-align: left;
`;

const RowContent = styledComponent.td`
  color: #003e68;
  font-size: 12px;
  text-align: end;
  font-weight: 600;
`;

const Title = styledComponent.div`
  font: normal normal normal 16px/21px Roboto;
  color: #7d7b7b;
  opacity: 1;
  text-align: left;
  font-weight: 500;
  letter-spacing: 0px;
`;
