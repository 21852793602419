/* eslint-disable i18next/no-literal-string */
import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import React from 'react';
import { Document, Page } from 'react-pdf';
import { DocRenderer, IStyledProps } from 'react-doc-viewer';
import { pdfjs } from 'react-pdf';
import styledComponent from 'styled-components';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PDFDocRenderer: DocRenderer = ({ mainState: { currentDocument } }) => {
  if (!currentDocument) return null;

  return (
    <Container id="pdf-preview-renderer">
      <DocumentPDF file={currentDocument.fileData} loading={<span>Loading...</span>}>
        {Array.from(new Array(1), (el, index) => (
          <Page size="A4" key={`page_${index + 1}`} pageNumber={index + 1}></Page>
        ))}
      </DocumentPDF>
    </Container>
  );
};

export default PDFDocRenderer;

PDFDocRenderer.fileTypes = ['pdf', 'application/pdf'];
PDFDocRenderer.weight = 0;

const DocumentPDF = styledComponent(Document)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const Container = styledComponent.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  /* width */
  &::-webkit-scrollbar {
    ${(props: IStyledProps) => {
      return props.theme.disableThemeScrollbar ? '' : 'width: 10px';
    }};
  }
  /* Track */
  &::-webkit-scrollbar-track {
    /* background: ${(props: IStyledProps) => props.theme.secondary}; */
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${(props: IStyledProps) => props.theme.tertiary};
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props: IStyledProps) => props.theme.primary};
  }
`;
