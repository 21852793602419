import moment from 'moment';
import {
  getStatusNumber,
  getReferenceRequestStatusFromId,
} from '../../CandidateTabPanel/SummaryTab/References/ReferenceGridHelper';
import states from 'app/assets/jsons/State.json';
import { missingField } from 'app/constants';
import { IRequestReferences } from 'app/models/Candidate/RequestReferences';

const ReferenceRequestType = [
  {
    ID: 1,
    Description: 'Employment Verification',
  },
  {
    ID: 2,
    Description: 'HAM/Facility Requested',
  },
  {
    ID: 3,
    Description: 'Placement',
  },
  {
    ID: 4,
    Description: 'Allied / In Demand',
  },
  {
    ID: 5,
    Description: 'Update References',
  },
  {
    ID: 6,
    Description: 'Web',
  },
];

const getReferenceRequest = (typeId: number) => {
  return ReferenceRequestType.find(e => e.ID == typeId).Description;
};

export const formatData = (data: any, filter, requestReferencesData: IRequestReferences[]) => {
  const updatedData: any = [];
  data?.items?.forEach((x, idx) => {
    if (x.experienceEmployment && x.experienceEmployment?.length > 0) {
      x.experienceEmployment.forEach(item => {
        updatedData.push({
          id: idx,
          referenceType: x.referenceType || missingField,
          status: x.status || missingField,
          statusSort: getStatusNumber(x.status),
          referenceDate: x.referenceDate ? moment(x.referenceDate).format('MM/DD/YYYY') : missingField,
          facility: item.facilityName || missingField,
          city: item.location?.city || missingField,
          state: states.states.find(state => state.ID === item.location?.state)?.Description || missingField,
          startDate: item.startedOn ? moment(item.startedOn).format('MM/DD/YYYY') : missingField,
          endDate: item.endedOn ? moment(item.endedOn).format('MM/DD/YYYY') : missingField,
          discipline: item.discipline || missingField,
          specialty: item.specialty || missingField,
          supervisor: x.supervisorName || missingField,
          title: x.title || missingField,
          contactInformationComplete: x.phoneNumbers?.length > 1 ? 'Yes' : 'No',
          phoneType1: x.phoneNumbers?.length > 0 ? x.phoneNumbers[0].phoneTypeDescription : missingField,
          phoneType2: x.phoneNumbers?.length > 1 ? x.phoneNumbers[1].phoneTypeDescription : missingField,
          phoneNumber1: x.phoneNumbers?.length > 0 ? x.phoneNumbers[0].phoneNumber : missingField,
          phoneNumber2: x.phoneNumbers?.length > 1 ? x.phoneNumbers[1].phoneNumber : missingField,
          callPreference: x.contactPreferenceType || missingField,
          bestTimeToReach: x.bestTimeToReach || missingField,
          email: x.email || missingField,
          rehire: x.eligibleForRehire ? (x.eligibleForRehire === 'Y' ? 'Yes' : 'No') : missingField,
          explanation: x.explanation || missingField,
          comments: x.comment || missingField,
          evaluation: x.referenceEvalutaion?.length > 0 ? x.referenceEvaluation[0].categoryType : missingField,
          ageCompetency:
            x.ageCompetency?.length > 0
              ? x.ageCompetency.map(age => {
                  return `${age.ageType}`;
                })
              : missingField,
          amnTitle: x.amhTitle || missingField,
          unitFloorDept: x.unitName || missingField,
          unitSpecialty: x.unitSpecialty || missingField,
          positionsHeld: x.positionHeld || missingField,
          numOfBeds: x.numberOfBeds || missingField,
          averagePatCaseload: x.avgPatientCaseLoad || missingField,
          unitDescription: x.unitDescription || missingField,
          shiftWorked: x.shiftWorked || missingField,
          supervisorExperience: x.positionHeld || missingField,
          mayWeShareThisReviewWithTraveler: x.mayShareWithTraveler
            ? x.mayShareWithTraveler === 'Y'
              ? 'Yes'
              : 'No'
            : missingField,
          haveYouSharedThisReviewWithTraveler: x.sharedWithTraveler
            ? x.sharedWithTraveler === 'Y'
              ? 'Yes'
              : 'No'
            : missingField,
          activityHistory: x.yearsOfExperience || missingField,
          notes: missingField,
        });
      });
    }
  });
  const sortedByRefDate = updatedData?.sort(
    (a, b) => new Date(b?.referenceDate || 0).getTime() - new Date(a?.referenceDate || 0).getTime(),
  );

  const sortedData = sortedByRefDate?.sort((a, b) => a.statusSort - b.statusSort);
  const currentLength = sortedData?.length;
  requestReferencesData?.forEach((item: IRequestReferences, index, items) => {
    sortedData?.push({
      id: currentLength + index,
      placementId: item.placementID,
      referenceType: getReferenceRequest(item.referenceRequestTypeID),
      referenceRequestTypeID: item.referenceRequestTypeID,
      referenceRequestStatusID: item.referenceRequestStatusID,
      status: 'New',
      notes: item.notes,
      ageCompetency: [],
      statusSort: item.referenceRequestStatusID,
    });
  });

  return sortedData;
};
