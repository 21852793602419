import { makeStyles } from 'tss-react/mui';
import { Box, TextField, Autocomplete } from 'amn-ui-core';
import React, { useEffect, useState } from 'react';
import { Expandable } from 'app/components/Common/Expandable/Expandable';
import { Title } from 'app/components/Order/OrderDetails/OrderPreferences/customComponents/OrderPreferencesDesign';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { facilityActions } from 'app/components/Facility/FacilityStore/Facility.redux';
import {
  selectFacilityDetail,
  selectFacilityDisipline,
  selectSelectedDiscipline,
} from 'app/components/Facility/FacilityStore/Facility.selector';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useParams } from 'react-router-dom';
import { IDisciplineList } from '@AMIEWEB/Facility/FacilityStore/Facility.model';

const useStyles: any = makeStyles()({
  boxComponent: {
    height: '100%',
  },
  expandableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const DisciplineRateCard = () => {
  const params = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const disciplineList = useSelector(selectFacilityDisipline);
  const selectedDiscipline = useSelector(selectSelectedDiscipline);
  const facilityDetails = useSelector(selectFacilityDetail);
  const formMethods = useForm({
    mode: 'onSubmit',
  });
  const { control, setValue } = formMethods;
  const [options, setOptions] = useState<any[]>([]);
  const setSelectedDiscipline = (value, category) => {
    if (!value) {
      setValue(category, null);
    } else {
      setValue(category, {
        discipline: value.discipline,
        disciplineId: value.disciplineId,
      } as IDisciplineList);
      dispatch(facilityActions.setSelectedDiscipline(value));
    }
  };

  useEffect(() => {
    if (params?.id) dispatch(facilityActions.getFacilityDisciplineAction(Number(params?.id)));
  }, [facilityDetails?.facilityId]);

  useEffect(() => {
    if (disciplineList && disciplineList.length > 0) {
      setOptions(disciplineList);
    }
  }, [disciplineList]);

  return (
    <React.Fragment>
      <Expandable
        expanded
        titleComponent={
          <div className={classes.expandableHeader}>
            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              <Title
                style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
              >{`Discipline Rate Card`}</Title>
            </div>
          </div>
        }
        disabled
        hideOverflowTitle={true}
      >
        <Box className={classes.boxComponent}>
          {options && selectedDiscipline ? (
            <Controller
              control={control}
              name={'Discipline'}
              render={({ ref, onChange, value, ...rest }) => (
                <Autocomplete
                  disableClearable
                  options={options}
                  popupIcon={<ExpandMoreOutlinedIcon />}
                  defaultValue={selectedDiscipline}
                  getOptionLabel={option => option.discipline}
                  noOptionsText={t('notification.createTask.noResultLabel')}
                  onChange={(e, value) => {
                    setSelectedDiscipline(value, 'Discipline');
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={'Discipline'}
                      variant="filled"
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              )}
            />
          ) : (
            <Autocomplete
              options={options}
              disabled
              onChange={(e, value) => {}}
              renderInput={params => (
                <TextField
                  {...params}
                  label={'Discipline'}
                  variant="filled"
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}
            />
          )}
        </Box>
      </Expandable>
    </React.Fragment>
  );
};
