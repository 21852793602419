import { IEditorPreference } from './userPreference.redux';

export enum PreferenceCosmosKeys {
  settingsConfirmationTemplateEditor = 'settings-confirmation-template-editor',
  settingsContractTemplateEditor = 'settings-contract-template-editor',
  placementConfirmationDocEditor = 'placement-confirmation-doc-editor',
  placementContractDocEditor = 'placement-contract-doc-editor',
  signaturePreference = 'signature-preference',
  notificationPreference = 'notification-preference',
  globalSearchDropdown = 'Global Search Dropdown',
  pinnedMessages = 'pinnedMessages',
  recentHistory = 'recent-history',
  inboxFilters = 'inboxFilters',
}

export const initialEmailSignature = {
  id: PreferenceCosmosKeys.signaturePreference,
  value: [
    {
      name: 'Sender Name',
      input: '{{sender_name}}',
      value: '',
    },
    {
      name: 'Title',
      input: '{{title}}',
      value: '',
    },
    {
      name: 'Division',
      input: '{{division}}',
      value: '',
    },
    {
      name: 'Brand',
      input: '{{brand}}',
      value: '',
    },
    {
      name: 'Branded Signature',
      input: '{{branded_signature}}',
      value: null,
    },
    {
      name: 'Phone Number',
      input: '{{phone_number}}',
      value: undefined,
    },
    {
      name: 'Mobile Number',
      input: '{{mobile_number}}',
      value: undefined,
    },
    {
      name: 'Text Number',
      input: '{{text_number}}',
      value: undefined,
    },
    {
      name: 'Passport Link',
      input: '{{passport_link}}',
      value: false,
    },
    {
      name: 'Referral Link',
      input: '{{referral_link}}',
      value: false,
    },
  ],
};

export const initialNotificationPreference = {
  id: PreferenceCosmosKeys.notificationPreference,
  value: [
    {
      name: 'SMS Notification',
      enabled: true,
    },
    {
      name: 'Order Matches',
      enabled: true,
    },
    {
      name: 'Cue Note Notification',
      enabled: true,
    },
    {
      name: 'Cued To Me',
      enabled: true,
    },
    {
      name: 'Cue Note Replies',
      enabled: true,
    },
  ],
};

export const initialGlobalDropDown = {
  id: PreferenceCosmosKeys.globalSearchDropdown,
  value: undefined,
};

export const initialPinnedMessages = {
  id: PreferenceCosmosKeys.pinnedMessages,
  value: undefined,
};

export const initialRecentHistory = {
  id: PreferenceCosmosKeys.recentHistory,
  value: undefined,
};

export const initialRecentHistoryNull = {
  id: PreferenceCosmosKeys.recentHistory,
  value: null,
};

export const initialInboxFilters = {
  id: PreferenceCosmosKeys.inboxFilters,
  value: [],
};

export const initialEditorPreference: IEditorPreference = { pinnedTemplates: [], editorWidth: null };
