import React from 'react';
import { IconButton, Typography, Dialog, DialogContent } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';
import MuiDialogTitle from '@mui/material/DialogTitle';

const useDialogStyles = makeStyles()(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const useStyes = makeStyles<{ size: string }>()((theme, { size }) => ({
  root: {
    width: 'calc(100% + 36px)',
  },
  dialogBox: {
    width: '60%',
    'min-width': '60%',
    'border-radius': '7px',
    [theme.breakpoints.up('lg')]: {
      'min-width': '60%',
    },
    overflow: 'hidden',
    'max-width': 'max-content',
    'min-height': '30vh',
  },
  dialogContentRoot: {
    padding: '1rem',
  },
  dialogTitle: {
    color: '#fff',
    fontSize: '12px',
    backgroundColor: theme.palette.framework.system.skyBlue,
    display: 'flex',
    'justify-content': 'space-between',
    'align-items': 'center',
    '& button': {
      'background-color': 'transparent',
      color: '#fff',
    },
    '& svg': {
      position: 'relative',
      'margin-left': '6px',
      'margin-right': '6px',
      top: '-1px',
    },
  },
}));

interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
  classes: any;
}

const DialogTitle = (props: DialogTitleProps) => {
  const { classes, cx } = useDialogStyles();
  const { children, onClose, classes: customClasses, ...other } = props;
  return (
    <MuiDialogTitle className={cx(classes.root, ...customClasses)} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton color="primary" aria-label="close" className={classes.closeButton} onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

interface IDialogComponent {
  id: string;
  target: string;
  title: React.ReactNode;
  content: React.ReactNode;
  isDialogOpen: boolean;
  setDialogOpen: (openState) => void;
}

export const DialogWidget = (props: IDialogComponent) => {
  const { id, target, title, content, isDialogOpen, setDialogOpen } = props;
  const { classes } = useStyes({ size: undefined });

  return (
    <Dialog
      disableEscapeKeyDown={false}
      open={id === target ? isDialogOpen : false}
      onClose={() => setDialogOpen(false)}
      classes={{ paper: `${classes.dialogBox} ` }}
    >
      <DialogTitle classes={[classes.dialogTitle]} id="customized-dialog-title" onClose={() => setDialogOpen(false)}>
        {title}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>{content}</DialogContent>
    </Dialog>
  );
};
