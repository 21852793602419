import { Grid } from 'amn-ui-core';
import { XGrid } from 'app/components/Common/XGrid/XGrid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  referenceGridLoadingSelector,
  referenceGridSelector,
  selectReferences,
} from '../../../Profile/CandidateDetails.selector';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { RequestReferenceModal } from '../RequestReferenceModal';
import { AddReferenceModal } from '../../Common/AddReferenceModal';
import { useDecision } from '@optimizely/react-sdk';
import { ff_candidate_ui_manage_references } from 'app/constants/FeatureFlags/Candidate/Keys';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { selectUser } from 'oidc/user.selectors';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import _ from 'lodash';
import { getDefaults } from './ReferenceGridHelper';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { gridStateActions } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { workExperienceSliceKey } from 'store/redux-store/candidate-work-experience/slice';
import { workExperienceSaga } from 'store/redux-store/candidate-work-experience/saga';
import { useInjectSaga } from 'redux-injectors';

interface ReferenceGridProps {
  onEdit?: (row) => void;
  onDelete?: (params, event) => void;
  rows: any[];
  handleBack?: (event) => void;
  filter?: string;
}

export const ReferencesGrid = ({ onEdit, onDelete, rows, handleBack, filter }: ReferenceGridProps) => {
  useInjectSaga({ key: workExperienceSliceKey, saga: workExperienceSaga });

  const { t } = useTranslation();
  const { userInfo } = useSelector(selectUser);
  const [showAddReference] = useDecision(ff_candidate_ui_manage_references);
  const [addReferenceAuthorized, setAddReferenceAuthorized] = useState<boolean>(false);
  const [columns, setColumns] = useState<any>([]);
  const [openRequestPop, setOpenRequestPop] = useState<boolean>(false);
  const [openAddPopup, setOpenAddPopup] = useState<boolean>(false);
  const referenceGridData = useSelector(referenceGridSelector);
  const isGridLoading = useSelector(referenceGridLoadingSelector);
  const dispatch = useDispatch();
  const selReferences = useSelector(selectReferences);
  const [hasPermission, setHasPermission] = useState<boolean>(false);
  const [sortModel, setSortModel] = React.useState<any>([
    {
      field: 'startDate',
      sort: 'desc',
    },
  ]);
  const [filterModel] = React.useState<any>(
    filter !== ''
      ? { items: [{ columnField: `status`, operatorValue: `equals`, value: filter }] }
      : {
          items: [],
        },
  );

  React.useEffect(() => {
    setColumns(getDefaults(t));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      Authorized(
        [
          userRoles.clinical_Manager,
          userRoles.clinical_Director,
          userRoles.clinical_ReviewAnalyst,
          userRoles.clinical_QualificationsSpecialist,
          userRoles.recruitment_Leadership,
          userRoles.recruitment_TeamMember,
        ],
        userInfo,
      )
    ) {
      setAddReferenceAuthorized(true);
    }
    setHasPermission(
      Authorized(
        [
          userRoles.recruitment,
          userRoles.recruitment_TeamMember,
          userRoles.recruitment_Leadership,
          userRoles.clinical_Manager,
          userRoles.clinical_Director,
          userRoles.clinical_ReviewAnalyst,
          userRoles.clinical_QualificationsSpecialist,
        ],
        userInfo,
      ),
    );
  }, [userInfo]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetColumns = () => {
    setColumns(getDefaults(t));
  };
  useEffect(() => {
    dispatch(candidateDetailActions.initiateReferenceGridData({ filter }));
  }, [selReferences]);

  const handleNavigation = () => {
    setOpenAddPopup(true);
  };

  const handleClose = () => {
    setOpenAddPopup(false);
  };

  const handleRequestRefNavigation = () => {
    setOpenRequestPop(true);
  };

  const handleRequestRefClose = () => {
    setOpenRequestPop(false);
  };

  const handleBackClick = event => {
    dispatch(gridStateActions.setPageNumber(1));
    handleBack(event);
  };

  React.useEffect(() => {
    setSortModel([
      {
        field: 'startDate',
        sort: 'desc',
      },
    ]);
  }, [referenceGridData]);

  const SearchXGrid = React.useMemo(() => {
    return (
      <XGrid
        toolbarProps={{
          resetColumns: () => resetColumns(),
          leftActions: showAddReference.enabled
            ? [
                {
                  title: t('candidate.referenceGrid.AddReference'),
                  Icon: AddIcon,
                  onClick: handleNavigation,
                  disabled: !hasPermission,
                },
                {
                  title: t('Request'),
                  Icon: PersonOutlineIcon,
                  onClick: handleRequestRefNavigation,
                },
              ]
            : [],
          leftTitleAction: (
            <ArrowBackIcon onClick={handleBackClick} style={{ cursor: 'pointer', width: '20px', height: '20px' }} />
          ),
        }}
        rows={referenceGridData || []}
        columns={columns}
        title={t('candidate.referenceGrid.gridTitle')}
        sortModel={sortModel}
        filterModel={filterModel}
        loading={isGridLoading}
        onSortModelChange={model => setSortModel(model)}
        disableSelectionOnClick
      />
    );
  }, [
    showAddReference.enabled,
    t,
    hasPermission,
    handleBack,
    referenceGridData,
    columns,
    sortModel,
    filterModel,
    isGridLoading,
    resetColumns,
  ]);

  return (
    <Grid container>
      <Grid item xs={12}>
        {SearchXGrid}
      </Grid>
      {openAddPopup && <AddReferenceModal open={openAddPopup} handleClose={handleClose} />}
      {<RequestReferenceModal open={openRequestPop} handleClose={handleRequestRefClose} />}
    </Grid>
  );
};
