import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { useWatch } from 'react-hook-form';
import { useEditableRequirement } from './EditRequirementsHelper';
import { ArrivalTimeCheck } from './EditablePassFailLogic';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPlacementStatus } from 'store/redux-store/placement-status/selectors';
import { placementStatus } from '@AMIEWEB/RecruiterWorkDesk/MyPlacementsDesk/utils';

export const useEditableRequirementArrivalTime = () => {
  // DOB
  const arrivalTime: string | undefined = useWatch({ name: 'arrivalTime' });
  const transportation: any = useWatch({ name: 'transportationChosen' });
  const { currentPlacementStatus } = useSelector(selectPlacementStatus);
  const secondaryData = useMemo(() => ({
    transportation: transportation?.transportation, placementStatus: currentPlacementStatus?.activityStatus
  }),
    [transportation?.transportation, currentPlacementStatus?.activityStatus]);

  useEditableRequirement({
    data: arrivalTime,
    secondaryData: secondaryData,
    requirementId: IPlacementRequirementKey.arrivalTime,
    requirementKey: 'arrivalTime',
    requirementCheck: ArrivalTimeCheck,
    shouldAddFailedRequirement:
      currentPlacementStatus?.activityStatus?.toLowerCase() === placementStatus.travelerAccepts.toLowerCase(),
    failRequirement: {
      id: IPlacementRequirementKey.arrivalTime,
      message: 'Arrival Time: Missing',
      tooltip: {
        message: 'Arrival Time is required.',
      },
    },
  });
};
