import { Typography } from 'amn-ui-core';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React from 'react';
import { DocumentDropzone } from 'app/components/Common/DocumentDropzone/DocumentDropzone';
import { MD5 } from 'crypto-js';
import { ErrorCode } from 'react-dropzone';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { trackPromise } from 'react-promise-tracker';

const getErrorMessage = customSwitch({
  [ErrorCode.FileInvalidType]: 'File type is not supported.',
  [ErrorCode.FileTooLarge]: 'File size exceeds 5MB limit.',
  [ErrorCode.TooManyFiles]: 'Limit of 5 files per upload.',
})(undefined);

function processFile(file: any) {
  return new Promise<{ binary: string; hash: string; size: number | null }>((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      resolve({
        binary: reader.result as string,
        hash: MD5(reader.result as string).toString(),
        size: file?.size ?? null,
      }),
    );
    reader.addEventListener('error', err => reject(err));
    reader.readAsBinaryString(file);
  });
}

export const DragDrop = ({ existingDocs, handleAcceptedDocs, handleRejections = undefined, trackerKey = '' }) => {
  const processDocuments = async (acceptedFiles, rejectedFiles) => {
    const files = await Promise.all(
      acceptedFiles.map(file => {
        const fileExt = file.name.split('.').pop();
        return processFile(file).then(({ binary, hash, size }) => ({
          name: file.name,
          type: fileExt,
          // data: binary,
          hash,
          size,
          file,
        }));
      }),
    );

    // Remove duplicates if uploaded by user
    const accepetedDocs = files.reduce(
      (results, doc) => (!results.some(x => x.hash === doc.hash) ? [...results, doc] : results),
      existingDocs,
    );

    const errorDocs = await Promise.all(
      rejectedFiles.map(({ file, errors }) => {
        return processFile(file).then(({ hash, size }) => ({
          hash,
          name: file.name,
          size: size,
          type: file.name.split('.').pop(),
          errors: errors.reduce(
            (results, x) =>
              x.code !== ErrorCode.TooManyFiles
                ? [
                    ...results,
                    {
                      errorCode: x.code,
                      severity: 'error',
                      message: getErrorMessage(x.code),
                    },
                  ]
                : results,
            [],
          ),
        }));
      }),
    );
    const rejectedDocs = errorDocs.reduce(
      (results, doc) => (!results.some(x => x.hash === doc.hash) ? [...results, doc] : results),
      [],
    );
    handleAcceptedDocs(accepetedDocs, rejectedDocs);
    handleRejections?.(rejectedDocs);
  };

  return (
    <>
      <LayoutGrid container>
        <LayoutGridItem xs={12}>
          <LayoutGrid container>
            <LayoutGridItem xs style={{ paddingBottom: 12 }}>
              <Typography component="span" variant="body2" fontSize="14px" fontWeight="500">
                Upload Documents
              </Typography>
            </LayoutGridItem>
          </LayoutGrid>
          <DocumentDropzone
            onDrop={(acceptedFiles, rejectedFiles) =>
              trackPromise(processDocuments(acceptedFiles, rejectedFiles), trackerKey)
            }
            accept={{
              allowedExtensions: [
                '.jpg',
                '.png',
                '.pdf',
                '.xls',
                '.xlsx',
                '.csv',
                '.jpeg',
                '.pst',
                '.msg',
                '.PNG',
                '.docx',
                '.doc',
                '.eml'
              ],
            }}
            message={
              <span>
                Limit of 5 files per upload, with each file not exceeding 5 MB in size.
                <br />
                File Type supported: jpeg, .jpg, .png, .docx, .doc, .xlsx, .xls, .pdf, .csv, outlook email
              </span>
            }
            maxFiles={null}
          />
        </LayoutGridItem>
      </LayoutGrid>
    </>
  );
};
