import React from 'react';
import { CreatePlacementWrapper as Old } from './CreatePlacementWrapper';
import { CreatePlacementWrapper as New } from '../CreateNewPlacement/CreatePlacementWrapper';
import { useDecision } from '@optimizely/react-sdk';
import { ff_new_create_placement_modal_layout } from 'app/constants/FeatureFlags/Placement/Keys';

export const CreatePlacementWrapper = props => {
  const [{ enabled }] = useDecision(ff_new_create_placement_modal_layout);
  return enabled ? <New {...props} /> : <Old {...props} />;
};
