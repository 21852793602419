import { selectReferenceData } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import React from 'react';
import { useSelector } from 'react-redux';
import { groupBy } from '../../helper';
import _ from 'lodash';
import { Box, Typography } from 'amn-ui-core';
import moment from 'moment';
import { useWorkExperienceStyles } from '../../WorkExperience/WorkExperienceCard.style';
import { missingField } from 'app/constants';

const DisplayToolTip = ({ status }) => {
  const referenceData = useSelector(selectReferenceData);
  const { classes } = useWorkExperienceStyles();

  const filteredData = referenceData?.items?.filter(
    d => d.status.toString().toLowerCase() === status.toString().toLowerCase() && d.referenceTypeID !== 5,
  );

  //Get all experienceEmployment
  let experienceEmployments = filteredData.map(item => {
    return item?.experienceEmployment ? item?.experienceEmployment[0] : {};
  });

  experienceEmployments = _.orderBy(
    experienceEmployments,
    (o: any) => {
      return o != null ? o.startedOn : o;
    },
    ['desc'],
  );

  const groupByWorkHistoryList = groupBy(experienceEmployments);
  const cherryPick = _.uniqBy(experienceEmployments, 'workHistoryId');

  experienceEmployments = cherryPick?.map((data: any) => {
    const currentItem: any = Object.entries(groupByWorkHistoryList).find(
      item => item[0] === data?.workHistoryId?.toString(),
    );

    return {
      count: currentItem ? currentItem[1].length : 0,
      facilityName: data.facilityName,
      startedOn: data.startedOn,
      endedOn: data.endedOn,
    };
  });

  return (
    <div className={classes.customScroll}>
      {experienceEmployments?.map((item, index) => {
        return (
          <Box sx={theme => ({ display: 'flexbox', flexDirection: 'row', padding: theme.spacing(1) })} key={index}>
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                <span>{`${item.count}`}</span>
                <span style={{ paddingLeft: '2px' }}>{`${item?.facilityName}`} </span>
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" gutterBottom>
                {`Start Date: ${item?.startedOn ? moment(item?.startedOn).format('MM/DD/YYYY') : missingField}`}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" gutterBottom>
                {`End Date: ${item?.endedOn ? moment(item?.endedOn).format('MM/DD/YYYY') : missingField}`}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </div>
  );
};

export default DisplayToolTip;
