import { StatusUpdateModalVersion } from 'app/models/Tasks/Tasks';

export const RecipientStatus = (task, version, userInfo) => {
  if (version === StatusUpdateModalVersion.PARENT_TASK) {
    let recipientUser: any = null;
    task?.assignedTo?.map(assignee => {
      if (assignee?.id === userInfo?.employeeId) {
        recipientUser = {
          isRecipientUser: true,
          recipientId: assignee?.id,
          statusId: assignee?.statusId,
        };
      }
    });
    task?.cc?.map(ccUser => {
      if (ccUser?.id === userInfo?.employeeId) {
        recipientUser = {
          isRecipientUser: true,
          recipientId: ccUser?.id,
          statusId: ccUser?.statusId,
        };
      }
    });
    return recipientUser;
  } else if (version === StatusUpdateModalVersion.SUBTASK) {
    let recipientUser: any = null;
    task?.tos?.map(assignee => {
      if (assignee?.id === userInfo?.employeeId) {
        recipientUser = {
          isRecipientUser: true,
          recipientId: assignee?.id,
          statusId: assignee?.statusId,
        };
      }
    });
    task?.ccs?.map(ccUser => {
      if (ccUser?.id === userInfo?.employeeId) {
        recipientUser = {
          isRecipientUser: true,
          recipientId: ccUser?.id,
          statusId: ccUser?.statusId,
        };
      }
    });
    return recipientUser;
  } else {
    return null;
  }
};

export const CoverageRecipientStatus = (task: any, version: StatusUpdateModalVersion, coverageId: number) => {
  if (version === StatusUpdateModalVersion.PARENT_TASK) {
    let recipientUser: any = null;
    task?.assignedTo?.forEach(assignee => {
      if (
        assignee?.id === coverageId
      ) {
        recipientUser = {
          isRecipientUser: true,
          recipientId: assignee?.id,
          statusId: assignee?.statusId,
        };
      }
    });
    task?.cc?.forEach(ccUser => {
      if (
        ccUser?.id === coverageId 
      ) {
        recipientUser = {
          isRecipientUser: true,
          recipientId: ccUser?.id,
          statusId: ccUser?.statusId,
        };
      }
    });
    return recipientUser;
  } else if (version === StatusUpdateModalVersion.SUBTASK) {
    let recipientUser: any = null;
    task?.tos?.forEach(assignee => {
      if (
        assignee?.id === coverageId 
      ) {
        recipientUser = {
          isRecipientUser: true,
          recipientId: assignee?.id,
          statusId: assignee?.statusId,
        };
      }
    });
    task?.ccs?.forEach(ccUser => {
      if (
        ccUser?.id === coverageId
      ) {
        recipientUser = {
          isRecipientUser: true,
          recipientId: ccUser?.id,
          statusId: ccUser?.statusId,
        };
      }
    });
    return recipientUser;
  } else {
    return null;
  }
};

export const CreatorStatus = (task, userInfo) => {
  if (task?.createdBy?.id === userInfo?.employeeId) {
    const createdByUser = {
      isCreatedByUser: true,
      createdById: task?.createdBy?.id,
      statusId: 0,
    };
    return createdByUser;
  } else {
    const createdByUser = {
      isCreatedByUser: false,
      createdById: userInfo?.employeeId,
      statusId: 0,
    };
    return createdByUser;
  }
};

export const CoverageCreatorStatus = (task: any, coverageId: number) => {
  if (task?.createdBy?.id === coverageId) {
    const createdByUser = {
      isCreatedByUser: true,
      createdById: task?.createdBy?.id,
      statusId: 0,
    };
    return createdByUser;
  } else {
    const createdByUser = {
      isCreatedByUser: false,
      createdById: coverageId,
      statusId: 0,
    };
    return createdByUser;
  }
};

export const supportDisableStatusCoverage = (
  task,
  taskRecipients,
  activeCoverage,
  userInfo,
  selectedCoverageOption,
) => {
  taskRecipients?.push(task?.createdBy);
  const coverageRecipients: any[] = [];
  taskRecipients?.map(data => {
    const isValidRecipient =
      activeCoverage?.some(coverageDetails => coverageDetails?.coveredEmployeeId === data?.id) ||
      data?.id === userInfo?.userInfo?.employeeId;
    if (isValidRecipient) {
      coverageRecipients?.push(data);
    }
  });
  return !coverageRecipients?.some(recipient => recipient?.id === selectedCoverageOption);
};
export const checkButtonDisableStatusByCoverage = (
  task,
  activeCoverage,
  selectedCoverageOption,
  userInfo,
  isSubTask,
) => {
  let taskRecipients: any[] = [];
  taskRecipients = isSubTask ? [...task?.tos, ...task?.ccs] : [...task?.assignedTo, ...task?.cc];
  return supportDisableStatusCoverage(task, taskRecipients, activeCoverage, userInfo, selectedCoverageOption);
};
export const checkButtonDisableStatusByCoverageForTaskTile = (
  task,
  activeCoverage,
  selectedCoverageOption,
  userInfo,
) => {
  let taskRecipients: any[] = [];
  taskRecipients = [...task?.assignedTo, ...task?.ccValues];
  return supportDisableStatusCoverage(task, taskRecipients, activeCoverage, userInfo, selectedCoverageOption);
};
