import { Box, IconButton, Link, Skeleton, createSvgIcon } from 'amn-ui-core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPlacementDetails } from 'store/redux-store/placement-details/selectors';
import { selectSubmissionInfo } from 'store/redux-store/placement-summary/selectors';
import { makeStyles } from 'tss-react/mui';
import ReplayIcon from '@mui/icons-material/Replay';
import { convertToFormat } from 'app/helpers/dateHelper';
import { placementSummaryActions } from 'store/redux-store/placement-summary/slice';
import { usePromiseTracker } from 'react-promise-tracker';
import { CustomTooltip } from '@AMIEWEB/Common';
import { useTranslation } from 'react-i18next';
import { ActionModal } from '@AMIEWEB/Common/TemplateEditor/customComponents';
import { missingField } from 'app/constants';
import { theme as globalTheme } from 'styles/global-styles';
import { useDecision } from '@optimizely/react-sdk';
import { ff_placement_ui_refresh_driving_distance } from 'app/constants/FeatureFlags/Placement/Keys';
import { useReadOnly } from 'oidc/userRoles';

const Directions = createSvgIcon(
  <svg
    id="directions_FILL0_wght0_GRAD0_opszNaN"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <style>{`.directions-driving-distance{fill:${globalTheme.palette.framework.system.darkCerulean};}`}</style>
    </defs>
    <path
      id="directions_FILL0_wght0_GRAD0_opszNaN-2"
      data-name="directions_FILL0_wght0_GRAD0_opszNaN"
      className="directions-driving-distance"
      d="M84.236,188.573h1.787V184.7H91.5v2.531l3.454-3.424L91.5,180.355v2.561H85.129a.877.877,0,0,0-.893.893ZM89,197a2.111,2.111,0,0,1-.7-.119,1.508,1.508,0,0,1-.581-.357L77.476,186.28a1.507,1.507,0,0,1-.357-.582,2.111,2.111,0,0,1,0-1.4,1.508,1.508,0,0,1,.357-.581L87.72,173.476a1.507,1.507,0,0,1,.582-.357,2.111,2.111,0,0,1,1.4,0,1.508,1.508,0,0,1,.581.357l10.243,10.243a1.507,1.507,0,0,1,.357.582,2.111,2.111,0,0,1,0,1.4,1.508,1.508,0,0,1-.357.581L90.28,196.524a1.507,1.507,0,0,1-.582.357A2.125,2.125,0,0,1,89,197Zm-5.12-6.878L89,195.243,99.243,185,89,174.757,78.757,185ZM89,185Z"
      transform="translate(-77 -173)"
    />
  </svg>,
  'Directions',
);

const useStyles = makeStyles<{ wrap: boolean }>()((theme, { wrap }) => ({
  root: {
    display: 'flex',
    flexDirection: wrap ? 'column' : 'row',
    gap: wrap ? undefined : '8px',
  },
  rowSect: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
  wrapRefreshSect: {
    marginLeft: '32px',
    marginTop: '-4px',
  },
  iconBtn: {
    padding: 0,
    color: wrap ? theme.palette.framework.system.brightNavyBlue : theme.palette.framework.system.navyBlue,
    '&:hover': {
      color: wrap ? theme.palette.framework.system.brightNavyBlue : theme.palette.framework.system.navyBlue,
    },
  },
  link: {
    color: wrap ? theme.palette.framework.system.brightNavyBlue : theme.palette.framework.system.doveGray,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      color: wrap ? theme.palette.framework.system.brightNavyBlue : theme.palette.framework.system.navyBlue,
      cursor: 'pointer',
    },
  },
  breakContent: {
    whiteSpace: 'break-spaces',
  },
  directionsIcon: {
    '& .directions-driving-distance': {
      fill: wrap ? theme.palette.framework.system.brightNavyBlue : undefined,
    },
    '&:hover': {
      '& .directions-driving-distance': {
        fill: wrap ? theme.palette.framework.system.brightNavyBlue : theme.palette.framework.system.navyBlue,
      },
    },
  },
  disabledDirectionsIcon: {
    color: theme.palette.framework.system.coolGray,
    '& .directions-driving-distance': {
      fill: theme.palette.framework.system.silver,
    },
  },
}));

export const DrivingDistance = ({ wrap = false }) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles({ wrap });

  const { readOnly } = useReadOnly();
  const { drivingDistanceMapLink } = useSelector(selectPlacementDetails);

  const {
    orderReq: {
      candidate: { milesRadius },
    },
  } = useSelector(selectSubmissionInfo);

  const [refreshFeature] = useDecision(ff_placement_ui_refresh_driving_distance);

  // @ts-ignore
  const [modalProps, setModalProps] = React.useState<IActionModalProps>({ open: false });

  const dispatch = useDispatch();

  const { promiseInProgress: refreshinDrivingDistance } = usePromiseTracker({
    area: 'refresh-placement-driving-distance',
  });
  const { promiseInProgress: loadingRequirement } = usePromiseTracker({
    area: 'placement-submission-miles-radius-order-requirement',
  });

  const { distance } = React.useMemo(
    () => ({
      distance: `${milesRadius?.data?.distance ? milesRadius.data.distance.toFixed(2) : 0} miles`,
    }),
    [milesRadius?.data?.distance],
  );

  const distanceUpdatedDate = React.useMemo(
    () => convertToFormat(milesRadius?.data?.distanceLastUpdatedOn, 'MM/DD/YYYY hh:mm A', true),
    [milesRadius?.data?.distanceLastUpdatedOn],
  );

  const openRefreshModal = React.useCallback(
    () =>
      setModalProps({
        open: true,
        title: t('placement.profile.header.drivingDistance.refreshDistance'),
        message: t('placement.profile.header.drivingDistance.refreshDistanceMessage', {
          distanceUpdatedDate,
        }),
        primaryBtns: [
          {
            text: t('placement.profile.header.drivingDistance.refresh'),
            onClick: () => {
              dispatch(placementSummaryActions.refreshDrivingDistance());
              setModalProps({ open: false });
            },
          },
        ],
        secondaryBtns: [
          {
            text: t('placement.profile.header.drivingDistance.cancel'),
            onClick: () => setModalProps({ open: false }),
          },
        ],
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [distanceUpdatedDate],
  );

  const refreshSect = React.useMemo(
    () => (
      <div className={cx(classes.rowSect, { [classes.wrapRefreshSect]: wrap })}>
        <div>{distanceUpdatedDate}</div>
        {refreshFeature.enabled ? (
          <CustomTooltip standardMargin disabled={!readOnly} tooltipContent={t('global.readOnlyTooltip')}>
            <IconButton
              color="primary"
              size="small"
              disabled={readOnly}
              classes={{ root: classes.iconBtn }}
              onClick={() => openRefreshModal()}
            >
              <ReplayIcon />
            </IconButton>
          </CustomTooltip>
        ) : null}
        <ActionModal {...modalProps} />
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      distanceUpdatedDate,
      refreshinDrivingDistance,
      readOnly,
      wrap,
      modalProps,
      openRefreshModal,
      refreshFeature.enabled,
    ],
  );

  return loadingRequirement || refreshinDrivingDistance ? (
    <Skeleton width="100px" />
  ) : (
    <div className={classes.root}>
      <CustomTooltip
        isInlineContent
        contentStyle={{ fontSize: 'inherit', color: 'inherit', width: 'min-content' }}
        classes={{ tooltip: classes.breakContent }}
        tooltipContent={
          !milesRadius?.data?.candidateAddress1
            ? t('placement.profile.header.drivingDistance.candidateAddressMissing')
            : t('placement.profile.header.drivingDistance.shortestDisatnce')
        }
      >
        {!milesRadius?.data?.candidateAddress1 ? (
          <Box className={cx(classes.rowSect, classes.disabledDirectionsIcon)}>
            <Directions />
            <span>{missingField}</span>
          </Box>
        ) : (
          <Link
            aria-disabled={!milesRadius?.data?.candidateAddress1}
            classes={{
              root: cx(classes.rowSect, {
                [classes.directionsIcon]: true,
              }),
            }}
            href={drivingDistanceMapLink}
            target="_blank"
          >
            <Directions />
            <span>{distance}</span>
          </Link>
        )}
      </CustomTooltip>
      {refreshSect}
    </div>
  );
};
