import { IEmployeeSharedProfile, IUserInfo } from 'oidc/user.redux';
import { getRecipients } from '../TaskDetails/newTaskStatusHelper';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { getEmployeeId } from '@AMIEWEB/Notification/Tasks/Common_v2/utils';
import { ISharedDeskUserAction } from 'app/models/Tasks/Tasks';

export const isSelectedCoverageOptionAssignee = (userId, detail) => {
  const recipients = getRecipients(detail);
  return recipients?.some(user => user?.id === userId);
};

export const getSharedDeskAssociatedUser = (userInfo: IUserInfo, actionType: number, description: string) => {
  const sharedProfileDetails: ISharedDeskUserAction = {
    employeeId: userInfo?.employeeId?.toString(),
    firstName: userInfo?.firstName,
    lastName: userInfo?.lastName,
    actionByFirstName: userInfo?.sharedProfile?.firstName,
    actionByLastName: userInfo?.sharedProfile?.lastName,
    actionById: parseInt(userInfo?.sharedProfile?.employeeId),
    actionDate: new Date().toISOString(),
    actionType: actionType,
    actionTypeDescription: description,
  };
  return sharedProfileDetails;
};

export const getEmployeeDetails = (userInfo: IUserInfo): IEmployeeSharedProfile => {
  if (!isNullOrUndefined(userInfo?.sharedProfile)) {
    return {
      firstName: userInfo?.sharedProfile?.firstName,
      lastName: userInfo?.sharedProfile?.lastName,
      employeeId: userInfo?.sharedProfile?.employeeId?.toString(),
    };
  } else {
    return {
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      employeeId: userInfo.employeeId?.toString(),
    };
  }
};

export function hasSharedProfile(userInfo) {
  return (
    userInfo?.userInfo?.sharedProfile &&
    Object.values(userInfo?.userInfo?.sharedProfile).some(value => value !== null && value !== '')
  );
}

export function isCoveredByUser(cueNote, userInfo) {
  const employeeId = parseInt(getEmployeeId(userInfo?.userInfo));
  return cueNote?.coverageDetail?.coveredByEmployeeId === employeeId;
}

export function isCoverageForUser(cueNote, userInfo) {
  const employeeId = parseInt(getEmployeeId(userInfo?.userInfo));
  return cueNote?.coverageDetail && cueNote?.senderId === employeeId;
}

export function isSentBySharedDeskUser(cueNote) {
  return (
    cueNote?.sharedDeskAssociatedUser &&
    Object.values(cueNote.sharedDeskAssociatedUser).some(value => value !== null && value !== '')
  );
}

export function isRecipient(cueNote, userInfo) {
  const employeeId = parseInt(getEmployeeId(userInfo?.userInfo));
  return cueNote?.recipientId === employeeId;
}

export function isSender(cueNote, userInfo) {
  const employeeId = parseInt(getEmployeeId(userInfo?.userInfo));
  return cueNote?.senderId === employeeId;
}

export function findRecipient(cueRecipients, cueNote) {
  return cueRecipients?.find(x => x.employeeId === cueNote?.recipientId);
}

export function findParentCueSender(cueRecipients, cueNote) {
  return cueRecipients?.find(x => x.employeeId === cueNote?.parentCueNote?.sentById);
}

export function hasDuplicateSender(cueNote) {
  const recipientId = cueNote?.recipientId;
  return (
    recipientId !== null &&
    (cueNote?.senderId === recipientId || cueNote?.coverageDetail?.coveredByEmployeeId === recipientId)
  );
}

export function hasParentOrCoverage(cueNote) {
  if (cueNote && (cueNote?.parentCueNote || cueNote?.coverageDetail)) {
    return false;
  }
  return true;
}

export function isThirdPartyView(cueNote, coveredByUser, userInfo) {
  const employeeId = parseInt(getEmployeeId(userInfo?.userInfo));
  return cueNote?.coverageDetail && !coveredByUser && cueNote?.senderId !== employeeId;
}
