import React, { useEffect, useRef } from 'react';
import { Autocomplete, Checkbox, Chip, Grid, TextField, Typography, useTheme } from 'amn-ui-core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { ButtonGrid, ChipsGrid } from 'app/components/Order/OrderDesign';
import { makeStyles } from 'tss-react/mui';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { WhiteTooltip } from 'app/components/Common/Tooltips/WhiteTooltip';
import { ITypeAheadOption } from 'app/models/Orders/OrderDetails';
import { selectFacilityMiscellaneousData } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';

interface SkillSetType {
  disciplineId: number;
  discipline: string;
  specialtyId?: number;
  specialty?: string;
  subSpecialtyId?: number;
  subSpecialty?: string;
}

interface IProps {
  setDisableRE: any;
  chosenSkillSets: any;
  setChosenSkillSets: any;
}

const useStyles: any = makeStyles()(theme => ({
  dropdown: {
    maxHeight: '53px',
    width: '200px',
    overflow: 'hidden',
    paddingRight: '10px',
  },
  container: {
    width: 'auto',
  },
  chip: {
    margin: '0 1% 1% 0',
  },
  chipBlock: {
    width: '230%',
    maxHeight: '100px',
    overflow: 'auto',
    marginTop: '10px',
  },
  addTaskButton: {
    border: '1px solid #C4C6C9',
    borderRadius: '4px',
    background: '#C4C6C9 0% 0% no-repeat padding-box',
    opacity: '1',
    height: '30px',
    width: '30px',
    padding: '0px 0px 0px 0px',
    margin: 'auto',
    font: 'normal normal normal 18px Roboto',
    color: '#FFFFFF',
    cursor: 'pointer',
    '&:hover': {
      background: `${theme.palette.components.button.secondary.backgroundColor} 0% 0% no-repeat padding-box`,
    },
  },
  selectedList: {
    maxWidth: 'calc(100% - 40px)',
    width: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const RateCardSection = ({ setDisableRE, chosenSkillSets, setChosenSkillSets }: IProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const formMethods = useFormContext();
  const { control, register, watch, setError, clearErrors, setValue } = formMethods;
  const [filteredSpecialty, setFilteredSpecialty] = React.useState<any[]>([]);
  const [filteredSubSpecialty, setFilteredSubSpecialty] = React.useState<any[]>([]);
  const [hasDiscipline, setHasDiscipline] = React.useState<boolean>(false);
  const [hasSpecialty, setHasSpecialty] = React.useState<boolean>(false);
  const [uniqueChipChosen, setUniqueChipChosen] = React.useState(true);
  const [showDisciplineTooltip, setShowDisciplineTooltip] = React.useState(false);
  const [showSpecialtyTooltip, setShowSpecialtyTooltip] = React.useState(false);
  const [showSubSpecialtyTooltip, setShowSubSpecialtyTooltip] = React.useState(false);
  const data = useSelector(selectFacilityMiscellaneousData);
  const discipline = watch('discipline');
  const specialty = watch('specialty');
  const subSpecialty = watch('subSpecialty');
  const specialtyRef = useRef(null);
  const subSpecialtyRef = useRef(null);
  useEffect(() => {
    if (chosenSkillSets?.length > 0) {
      setDisableRE(false);
    } else {
      setDisableRE(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenSkillSets]);

  useEffect(() => {
    if (discipline) setShowDisciplineTooltip(discipline.label.length > 20);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discipline]);

  useEffect(() => {
    if (specialty && specialty?.length > 0)
      setShowSpecialtyTooltip(specialtyRef?.current?.scrollWidth > specialtyRef?.current?.clientWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialty]);

  useEffect(() => {
    if (subSpecialty && subSpecialty?.length > 0)
      setShowSubSpecialtyTooltip(subSpecialtyRef?.current?.scrollWidth > subSpecialtyRef?.current?.clientWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subSpecialty]);

  const validateSkill = skill => {
    if (skill === 'discipline') {
      setValue('specialty', []);
      setHasSpecialty(false);
      setValue('subSpecialty', []);
    }
    if (skill === 'specialty') {
      setValue('subSpecialty', []);
    }
  };
  const validateIfDisciplineSelected = () => {
    if (!discipline) {
      setHasDiscipline(false);
      setError('specialty', { message: 'Please choose a discipline' });
      return;
    }
    clearErrors('specialty');
  };

  const validateIfSpecialtySelected = () => {
    if (!specialty) {
      setHasSpecialty(false);
      setError('subSpecialty', { message: 'Please choose a specialty' });
      return;
    }
    clearErrors('subSpecialty');
  };
  const filterSpecialty = (id: number) => {
    const filteredSpecialty = data?.specialtyOpts.filter(data => data.object.DisciplineID === id);
    return filteredSpecialty || [];
  };

  const filterSubspecialty = (id: number) => {
    const filteredSubSpecialty = data?.subSpecialtyOpts.filter(data => data.object.SpecialtyID === id);
    return filteredSubSpecialty || [];
  };
  const getDisabledSubSpecialtyOption = (option: ITypeAheadOption) => {
    let response = false;
    let combinationsCount = 0;
    let combinationsPresent = 0;
    specialty.forEach(spec => {
      let combinations = chosenSkillSets.filter(skill => skill.specialtyId === spec.object.SpecialtyID);
      if (combinations) {
        let subSpecs = filterSubspecialty(spec.object.SpecialtyID);
        if (subSpecs) {
          let op = subSpecs.find(x => x.label === option.label);
          if (op) {
            combinationsCount++;
            let combo = combinations.find(skill => skill.subSpecialty === op.label);
            if (combo) {
              combinationsPresent++;
            }
          }
        }
      }
    });
    if (combinationsCount === combinationsPresent) {
      response = true;
    }
    return response;
  };

  const checkNonDuplicateSpecialty = (specialty, subSpecialty) => {
    let response = true;
    let skillSets = chosenSkillSets.filter(sk => sk.specialty === specialty.label);
    if (skillSets && skillSets.length > 0) {
      skillSets.forEach(skill => {
        if (subSpecialty.object.SubSpecialtyID === skill.subSpecialtyId && skill.subSpecialty === subSpecialty.label) {
          response = false;
        }
      });
    }
    return response;
  };

  const checkUniqueSelection = (category, value) => {
    if (category === 'discipline') {
      const isAlreadyChosen = chosenSkillSets.some(skill => {
        return value.label === skill.discipline && !skill?.specialty && !skill?.subspecialty;
      });
      if (isAlreadyChosen) {
        setUniqueChipChosen(false);
        return;
      } else {
        setUniqueChipChosen(true);
      }
    }
    if (category === 'specialty') {
      const isAlreadyChosen = chosenSkillSets.some(skill => {
        return (
          discipline.label === skill.discipline &&
          skill.specialty &&
          value?.some(spec => spec.label === skill.specialty) &&
          !skill.subSpecialty
        );
      });

      if (isAlreadyChosen) {
        setUniqueChipChosen(false);
        return;
      } else {
        setUniqueChipChosen(true);
      }
    }
    if (category === 'subSpecialty') {
      const isAlreadyChosen = getDisabledSubSpecialtyOption(value[0]);
      if (isAlreadyChosen) {
        setUniqueChipChosen(false);
        return;
      } else {
        setUniqueChipChosen(true);
      }
    }
  };
  const setSelectedValue = async (category, value) => {
    validateSkill(category);
    if (!value) {
      setValue(category, null);
    } else {
      setValue(category, value);
    }
  };
  const onSelectionChange = (skill, choice) => {
    if (choice) {
      validateSkill(skill);
      if (skill === 'discipline') {
        if (choice.label.length <= 0) {
          setHasDiscipline(false);
        } else {
          const filteredData = filterSpecialty(choice.object.ID);
          setHasDiscipline(true);
          setFilteredSpecialty(filteredData);
        }
      }
      if (skill === 'specialty') {
        if (choice.length <= 0) setHasSpecialty(choice.length > 0);
        else {
          const filteredData = [];
          choice?.map(option => {
            const filteredSubSpecialty = filterSubspecialty(option.object.SpecialtyID);
            filteredSubSpecialty.map(subspecialty => {
              if (filteredData.length > 0) {
                const isAlreadyAdded = filteredData.some(obj => {
                  return subspecialty.object.SubSpecialtyID === obj.object.SubSpecialtyID;
                });
                if (!isAlreadyAdded) {
                  filteredData.push(subspecialty);
                }
              } else filteredData.push(subspecialty);
            });
          });
          setHasSpecialty(true);
          setFilteredSubSpecialty(filteredData);
        }
      }
      clearErrors('discipline');
      clearErrors('specialty');
    }
  };
  const formatChipLabel = (label1: string, label2?: string, label3?: any) => {
    return label1 + (label2 ? ' - ' + label2 + (label3 ? ' - ' + label3 : '') : '');
  };
  const handleDelete = skill => {
    const data = chosenSkillSets?.filter(entry => entry !== skill);
    setChosenSkillSets(data);
  };

  const formatData = () => {
    let formatedData = [];
    let chipData: SkillSetType = { disciplineId: 0, discipline: '' };
    specialty && specialty.length > 0
      ? subSpecialty && subSpecialty.length > 0
        ? (formatedData = specialty.reduce(
            (formatedData, specialty) => [
              ...formatedData,
              ...subSpecialty.map(
                subSpec =>
                  data?.subSpecialtyOpts.some(
                    x => x.label === subSpec.label && x.object.SpecialtyID === specialty.object.SpecialtyID,
                  ) &&
                  checkNonDuplicateSpecialty(specialty, subSpec) &&
                  (chipData = {
                    disciplineId: discipline.object.ID,
                    discipline: discipline.label,
                    specialtyId: specialty.object.SpecialtyID,
                    specialty: specialty.label,
                    subSpecialtyId: subSpec ? subSpec.object.SubSpecialtyID : null,
                    subSpecialty: subSpec ? subSpec.label : null,
                  }),
              ),
            ],
            [],
          ))
        : specialty?.map(spec => {
            chipData = {
              disciplineId: discipline.object.ID,
              discipline: discipline.label,
              specialtyId: spec.object.SpecialtyID,
              specialty: spec.label,
            };
            formatedData.push(chipData);
          })
      : (formatedData = [{ disciplineId: discipline.object.ID, discipline: discipline.label }]);
    return formatedData?.filter(value => value !== false);
  };

  const AddSkill = () => {
    if (!discipline) {
      setError('discipline', { message: 'Please choose a discipline' });
      return;
    }
    let filteredData = [];
    if (discipline) {
      if (specialty.length < 1 && subSpecialty.length < 1) {
        filteredData = chosenSkillSets?.filter(skillSet => skillSet.discipline !== discipline.label);
      } else if (specialty.length > 0 && subSpecialty.length < 1) {
        filteredData = chosenSkillSets?.filter(
          skillSet =>
            !(skillSet.discipline === discipline.label) ||
            !(skillSet.specialty ? specialty.some(spec => skillSet.specialty === spec.label) : true),
        );
      } else if (specialty.length > 0 && subSpecialty.length > 0) {
        filteredData = chosenSkillSets;
      }
    }
    const updatedSkillSet = [...filteredData, ...formatData()];
    setChosenSkillSets(updatedSkillSet);
    setSelectedValue('discipline', null);
    setHasDiscipline(false);
    setSelectedValue('specialty', []);
    setHasSpecialty(false);
    setSelectedValue('subSpecialty', []);
  };

  return (
    <Grid>
      <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
        {t('order.facility.rateCard.addOffContract.rateCard.title')}
      </Typography>
      <Grid container direction="row" className={classes.container}>
        <Grid item className={classes.dropdown}>
          <Controller
            control={control}
            register={register}
            name="discipline"
            render={({ ref, onChange, value, ...rest }) =>
              value ? (
                <WhiteTooltip
                  title={discipline?.label}
                  disableHoverListener={!showDisciplineTooltip}
                  disableFocusListener={true}
                >
                  <Autocomplete
                    id="discipline"
                    value={value || null}
                    popupIcon={<ExpandMoreOutlinedIcon />}
                    options={data?.disciplineOpts}
                    onFocus={() => validateIfDisciplineSelected()}
                    noOptionsText={t('notification.createTask.noResultLabel')}
                    getOptionDisabled={option =>
                      chosenSkillSets.some(
                        skill => skill.discipline === option.label && !skill.specialty && !skill.subSpecialty,
                      )
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={'Discipline*'}
                        variant="filled"
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                    onChange={(e, discipline) => {
                      setSelectedValue('discipline', discipline);
                      onSelectionChange('discipline', discipline);
                      checkUniqueSelection('discipline', discipline);
                    }}
                    onInputChange={(e, discipline) => {
                      discipline.length <= 0 && setHasDiscipline(false);
                    }}
                  />
                </WhiteTooltip>
              ) : (
                <Autocomplete
                  id="discipline"
                  value={value || null}
                  popupIcon={<ExpandMoreOutlinedIcon />}
                  options={data?.disciplineOpts}
                  onFocus={() => validateIfDisciplineSelected()}
                  noOptionsText={t('notification.createTask.noResultLabel')}
                  getOptionDisabled={option =>
                    chosenSkillSets.some(
                      skill => skill.discipline === option.label && !skill.specialty && !skill.subSpecialty,
                    )
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={'Discipline*'}
                      variant="filled"
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                  onChange={(e, discipline) => {
                    setSelectedValue('discipline', discipline);
                    onSelectionChange('discipline', discipline);
                    checkUniqueSelection('discipline', discipline);
                  }}
                  onInputChange={(e, discipline) => {
                    discipline.length <= 0 && setHasDiscipline(false);
                  }}
                />
              )
            }
          />
        </Grid>
        <Grid item className={classes.dropdown}>
          <Controller
            control={control}
            register={register}
            name="specialty"
            render={({ ref, onChange, value, ...rest }) => (
              <Autocomplete
                id="specialty"
                multiple={true}
                disableCloseOnSelect
                popupIcon={<ExpandMoreOutlinedIcon />}
                value={value || []}
                options={discipline ? filteredSpecialty : []}
                getOptionDisabled={option =>
                  chosenSkillSets.some(
                    skill =>
                      skill.discipline === discipline.label && skill?.specialty === option.label && !skill.subSpecialty,
                  )
                }
                disabled={!hasDiscipline || false}
                onFocus={() => validateIfDisciplineSelected()}
                noOptionsText={t('notification.createTask.noResultLabel')}
                renderOption={(p, option, { selected }) => (
                  <li {...p}>
                    <Checkbox checked={selected} />
                    {option.label}
                  </li>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={'Specialty'}
                    variant="filled"
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
                renderTags={list => {
                  let displayList = list.map(item => item.label).join(', ');
                  return (
                    <WhiteTooltip title={displayList} disableHoverListener={!showSpecialtyTooltip}>
                      <span ref={specialtyRef} className={classes.selectedList}>
                        {displayList}
                      </span>
                    </WhiteTooltip>
                  );
                }}
                onChange={(e, specailty) => {
                  setSelectedValue('specialty', specailty);
                  onSelectionChange('specialty', specailty);
                  checkUniqueSelection('specialty', specailty);
                }}
              />
            )}
          />
        </Grid>
        <Grid item className={classes.dropdown}>
          <Controller
            control={control}
            register={register}
            name="subSpecialty"
            render={({ ref, onChange, value, ...rest }) => (
              <Autocomplete
                id="subSpecialty"
                disableCloseOnSelect
                value={value || []}
                popupIcon={<ExpandMoreOutlinedIcon />}
                multiple={true}
                disabled={!hasSpecialty || !hasDiscipline || false || filteredSubSpecialty.length === 0}
                onFocus={() => validateIfSpecialtySelected()}
                options={filteredSubSpecialty}
                getOptionDisabled={option => getDisabledSubSpecialtyOption(option)}
                noOptionsText={t('notification.createTask.noResultLabel')}
                renderOption={(p, option, { selected }) => (
                  <li {...p}>
                    <Checkbox checked={selected} />
                    {option.label}
                  </li>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={'Sub Specialty'}
                    variant="filled"
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
                renderTags={list => {
                  let displayList = list.map(item => item.label).join(', ');
                  return (
                    <WhiteTooltip title={displayList} disableHoverListener={!showSubSpecialtyTooltip}>
                      <span ref={subSpecialtyRef} className={classes.selectedList}>
                        {displayList}
                      </span>
                    </WhiteTooltip>
                  );
                }}
                onChange={(e, subSpecialty) => {
                  setSelectedValue('subSpecialty', subSpecialty);
                  onSelectionChange('subSpecialty', subSpecialty);
                  checkUniqueSelection('subSpecialty', subSpecialty);
                }}
              />
            )}
          />
        </Grid>
        <ButtonGrid item className={classes.addButton}>
          <button
            type="button"
            className={classes.addTaskButton}
            disabled={!hasDiscipline || !uniqueChipChosen}
            onClick={() => {
              AddSkill();
            }}
            style={{
              backgroundColor:
                hasDiscipline && uniqueChipChosen && theme.palette.components.button.secondary.backgroundColor,
            }}
          >
            {t('notification.taskSidePanel.addTaskButton')}
          </button>
        </ButtonGrid>
      </Grid>
      {chosenSkillSets.length > 0 && (
        <ChipsGrid container xs={12} className={classes.chipBlock} id={'createUnitSelectedSkillsSetContainer'}>
          {chosenSkillSets.map((skill, index) => (
            <Chip
              disabled={false}
              variant="outlined"
              key={index}
              label={
                <React.Fragment>
                  {formatChipLabel(skill.discipline, skill.specialty, skill.subSpecialty)}
                </React.Fragment>
              }
              className={classes.chip}
              onDelete={() => handleDelete(skill)}
            />
          ))}
        </ChipsGrid>
      )}
    </Grid>
  );
};
