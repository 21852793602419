/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Grid, ThemeProvider, StyledEngineProvider,useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { reqPref } from 'app/enums/Unit';
import { FloatType } from 'app/models/Unit/CreateUnit';
import { useTranslation } from 'react-i18next';
import { ReqPrefSection } from '../Common/ReqPrefSection';
import { SingleLineInput } from '../Common/SingleLineInput';
import { UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { IToggleProperty, ToggleNoSelection } from '../ToggleNoSelection';
import { selectIsEditUnit, selectOrderCreationProcessStep } from 'app/components/Order/Store/Order.selectors';
import { useSelector } from 'react-redux';
import { SimpleCheckbox } from '../Common/SimpleCheckbox';
import { selectIsUnitSelected } from 'app/components/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';

interface Props {
  onUnitPropChange: (property: string, value: any) => void;
  disableEdit?: boolean;
  hasRadio?: boolean;
  floatData?: any | null;
}

const useStyles = makeStyles()((theme) => ({
  topPadding: {
    paddingTop: 12,
  },
}));

export const Float = (props: Props) => {
  const { onUnitPropChange, disableEdit, floatData } = props;
  const [yesNoOptionSelected, setYesNoOptionSelected] = React.useState<boolean>(floatData ? true : false);
  const { t } = useTranslation();
  const { classes } = useStyles();
  const theme= useTheme();
  const initialData = floatData
    ? floatData
    : {
        isRequired: true,
        floatToUnit: '',
        floatToLocation: '',
        floatToMiles: 0,
      };

  const [showPanel, setShowPanel] = React.useState(false);
  const isOrderEditUnit = useSelector(selectIsEditUnit);
  const isUnitEditUnit = useSelector(selectIsUnitSelected);
  const isEditUnit = isUnitEditUnit ? isUnitEditUnit : isOrderEditUnit;
  const [floatValue, setFloatValue] = React.useState<FloatType>(initialData);
  const storeOrderCreationProcessStep = useSelector(selectOrderCreationProcessStep);

  useEffect(() => {
    if ((floatData || initialData) && disableEdit) {
      setFloatValue(floatData || initialData);
    }
    if ((isEditUnit || storeOrderCreationProcessStep || yesNoOptionSelected) && floatData) {
      setShowPanel(true);
    } else {
      setShowPanel(false);
    }
  }, []);

  const toggleData: IToggleProperty = {
    showPanel,
    setShowPanel,
    onUnitPropChange,
    propertyName: 'float',
    propertyValue: floatValue,
  };
  const toggleNoSelection = ToggleNoSelection(toggleData);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.type === 'radio') {
      if (event.target.value === reqPref.req)
        setFloatValue({ ...floatValue, isRequired: event.target.value === reqPref.req });
      else setFloatValue({ ...floatValue, isRequired: false });
    } else if (event.target.name === 'floatToMiles' && parseInt(event.target.value))
      setFloatValue({ ...floatValue, [event.target.name]: getPositiveValue(event.target.value) });
    else setFloatValue({ ...floatValue, [event.target.name]: event.target.value });
  };

  const handleCheckBoxChangeX = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleNoSelection.handleShowPanelX(event.target.checked);
    setYesNoOptionSelected(event.target.checked);
  };

  const getPositiveValue = (value: string) => {
    const numericValue = parseInt(value);
    return numericValue > 0 ? numericValue : 0;
  };

  useEffect(() => {
    toggleNoSelection.onPropChange(showPanel, undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floatValue]);

  return (
    <div>
      {/* TODO: Remove this theme wrapper since it uses the global theme */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UnitDetailsBody variant="outlined">
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={1}>
                  <Grid item>
                    <UnitCardsTitle>{t('order.createUnit.float.title')}</UnitCardsTitle>
                  </Grid>
                  <SimpleCheckbox
                    id={'createUnitFloatEnableCheck'}
                    isChecked={yesNoOptionSelected ? true : false}
                    isdisabled={disableEdit}
                    hasRadio={false}
                    handleChecked={handleCheckBoxChangeX}
                    checkboxLabel={`Yes`}
                    checkboxName={'no-cbx'}
                  />
                </Grid>
                {showPanel && (
                  <>
                    <ReqPrefSection
                      isRequired={floatValue.isRequired}
                      handleReqPref={handleChange}
                      isdisabled={disableEdit}
                      id={'createUnitFloatReqPrefRadio'}
                    />
                    <Grid className={classes.topPadding} container direction="row" spacing={3}>
                      <Grid item xs={4}>
                        <SingleLineInput
                          id={'createUnitFloatFloatToUnitTxt'}
                          inputDefaultValue={floatValue.floatToUnit}
                          inputName={'floatToUnit'}
                          isdisabled={disableEdit}
                          inputLabel={t('order.createUnit.float.floatUnit')}
                          inputValue={floatValue.floatToUnit}
                          handleChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <SingleLineInput
                          id={'createUnitFloatFloatToLocationTxt'}
                          inputDefaultValue={floatValue.floatToLocation}
                          inputName={'floatToLocation'}
                          isdisabled={disableEdit}
                          inputLabel={t('order.createUnit.float.floatLocation')}
                          inputValue={floatValue.floatToLocation}
                          handleChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <SingleLineInput
                          id={'createUnitFloatFloatToMilesTxt'}
                          inputDefaultValue={floatValue.floatToMiles}
                          inputName={'floatToMiles'}
                          isdisabled={disableEdit}
                          inputLabel={t('order.createUnit.float.floatToMiles')}
                          inputValue={floatValue.floatToMiles}
                          handleChange={handleChange}
                          inputType={'number'}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </UnitDetailsBody>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};
