import { missingField } from "app/constants";
import { isStrNull } from "utils/string/string";

export enum HeaderSection {
  unitHeaderStaffingVendor = 'unitHeaderStaffingVendor',
  unitHeaderContractAffiliate = 'unitHeaderContractAffiliate',
  unitHeaderMSPClient = 'unitHeaderMSPClient',
  unitHeaderAMNDivision = 'unitHeaderAMNDivision',
  unitHeaderTechnologyVendor = 'unitHeaderTechnologyVendor',
  unitHeaderHealthcareSystem = 'unitHeaderHealthcareSystem',
  unitHeaderBusinessRelationship = 'unitHeaderBusinessRelationship',
  unitHeaderPhone = 'unitHeaderPhone',
  unitHeaderAccountManager = 'unitHeaderAccountManager',
  unitHeaderRegionalDirector = 'unitHeaderRegionalDirector',
}

export const getSkeletonWidthValue = value => {
  switch (value) {
    case HeaderSection.unitHeaderStaffingVendor:
      return 212;
    case HeaderSection.unitHeaderContractAffiliate:
      return 209;
    case HeaderSection.unitHeaderMSPClient:
      return 68;
    case HeaderSection.unitHeaderAMNDivision:
      return 65;
    case HeaderSection.unitHeaderTechnologyVendor:
      return 35;
    case HeaderSection.unitHeaderHealthcareSystem:
      return 132;
    case HeaderSection.unitHeaderBusinessRelationship:
      return 100;
    case HeaderSection.unitHeaderAccountManager:
      return 68;
    case HeaderSection.unitHeaderRegionalDirector:
      return 36;
    default:
      return 0;
  }
};

export const getSkeletonWidthTitle = value => {
  switch (value) {
    case HeaderSection.unitHeaderStaffingVendor:
      return 65;
    case HeaderSection.unitHeaderContractAffiliate:
      return 65;
    case HeaderSection.unitHeaderMSPClient:
      return 65;
    case HeaderSection.unitHeaderAMNDivision:
      return 21;
    case HeaderSection.unitHeaderTechnologyVendor:
      return 21;
    case HeaderSection.unitHeaderHealthcareSystem:
      return 21;
    case HeaderSection.unitHeaderBusinessRelationship:
      return 21;
    case HeaderSection.unitHeaderAccountManager:
      return 19;
    case HeaderSection.unitHeaderRegionalDirector:
      return 19;
    default:
      return 0;
  }
};

export const getSkeletonVariant = value => {
  switch (value) {
    case HeaderSection.unitHeaderAccountManager:
      return 'circular';
    case HeaderSection.unitHeaderRegionalDirector:
      return 'circular';
    default:
      return 'rectangular';
  }
};

export const getFacilityHeaderAddressTemplate = (location): string => {
  if (location?.address1) {
    const addressComponents = [
      { value: location.address1, appendComma: !isStrNull(location.address2) },
      { value: location.address2, appendComma: true },
      { value: location.city, appendComma: true },
      { value: location.state, appendComma: false },
      { value: location.zipCode, appendComma: false },
    ];
    return addressComponents
      .map(({ value, appendComma }) => {
        const strValue = isStrNull(value);
        return strValue ? `${strValue}${appendComma ? ',' : ''}` : null;
      })
      .filter(Boolean)
      .join(' ');
  }
  return missingField;
};