import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { TextField } from 'amn-ui-core';
import { formInputs } from 'app/models/ClientContact/ClientContact';
import { useSelector } from 'react-redux';
import { selectClientContactDetails } from '../store/ClientContact.selector';

export const CCFirstName = formMethods => {
  const { t } = useTranslation();

  const { formMethods: controls, disabled } = formMethods;
  const selectedClientContactDetails = useSelector(selectClientContactDetails);

  const {
    control,
    setError,
    clearErrors,
    setValue,
    formState: { isDirty, errors },
  } = controls;
  useEffect(() => {
    if (selectedClientContactDetails?.contactId)
      setValue('firstName', selectedClientContactDetails?.firstName, { shouldDirty: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientContactDetails?.contactId]);

  return (
    <Controller
      control={control}
      name={'firstName'}
      rules={{ required: t('required').toString(), maxLength: 50 }}
      render={({ ref, onChange, value, ...rest }) => (
        <TextField
          variant="filled"
          color="primary"
          autoComplete="off"
          disabled={disabled}
          label={t('clientContactModal.labels.firstName')}
          inputProps={{ maxLength: 50 }}
          InputLabelProps={{ shrink: !!value }}
          required={true}
          value={value}
          error={errors?.firstName ? true : false || (isDirty && errors?.firstName) ? true : false}
          {...rest}
          onChange={e => {
            if (e.target.value?.length > 50) {
              setError(formInputs.firstName, {
                type: 'maxLength',
                message: t('clientContactModal.messages.charMax50'),
              });
            } else if (e.target.value === '') {
              setError('firstName', {
                type: 'required',
                message: t('required').toString(),
              });
            } else if (errors?.firstName) {
              e.target.value?.length <= 50 && clearErrors(formInputs.firstName);
            }
            onChange(e);
          }}
          helperText={errors?.firstName?.message}
        />
      )}
    />
  );
};
