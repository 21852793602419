import { IUpdateCreatePlacement } from 'store/redux-store/new-placement/types';
import { ITabbingType } from 'utils/customHooks/useTabbingOrder';
import { ICueToOptions } from '../CreateNewPlacement/CuteNotesConfirmation/model';

export const getSaveRequest = ({
  candidate,
  order,
  skillset,
  availabilityDate,
  sellingPoints,
  requestedTimeOff,
  requestedShift,
  recruiter,
  startDate,
  endDate,
  userId,
  status,
  placementOrderType,
  screenOption,
  travel,
  bestTimeToCall,
  shouldSendCueNote,
  cueNotes,
  cuedTo,
}) => ({
  orderId: order.object.orderId,
  facilityId: order.object.facilityId,
  candidateId: candidate.object.candidateId,
  brandId: candidate.object.brandId,
  recruiterId: parseInt(recruiter.object.value),
  ...skillset?.object,
  userId,
  sellingPoints,
  requestedShift,
  /** Code: The spread op used to differentiate between previous and new modal (prev state was array) */
  ...(Array.isArray(requestedTimeOff) ? { requestedTimeOff } : { ...requestedTimeOff }),
  availabilityDate,
  bestTimeToCall,
  travelsWithPartner: travel?.travelsWithPartner,
  travelPartner: travel?.travelPartner,
  status,
  placementOrderType,
  startDate,
  endDate,
  allowScreenId: parseInt(screenOption?.value),
  shouldSendCueNote,
  cueNotes: shouldSendCueNote ? cueNotes : '',
  cueNoteRecipientId: cuedTo.name !== ICueToOptions.NO_CUE ? cuedTo?.value : 0,
});

export const getUpdateRequest = (
  {
    availabilityDate,
    sellingPoints,
    requestedTimeOff,
    requestedShift,
    bestTimeToCall,
    travel,
    shouldSendCueNote,
    cueNotes,
    cuedTo,
  },
  fields,
  userId,
  placementId,
): IUpdateCreatePlacement => {
  return {
    sellingPoints,
    requestedShift,
    /** Code: The spread op used to differentiate between previous and new modal (prev state was array) */
    ...(Array.isArray(requestedTimeOff) ? { requestedTimeOff } : { ...requestedTimeOff }),
    availabilityDate,
    bestTimeToCall,
    userId,
    fields,
    travelsWithPartner: travel?.travelsWithPartner,
    travelPartner: travel?.travelPartner,
    placementId: placementId,
    shouldSendCueNote,
    cueNotes: shouldSendCueNote ? cueNotes : '',
    cueNoteRecipientId: cuedTo.name !== ICueToOptions.NO_CUE ? cuedTo?.value : '',
  };
};

export enum createPlacementTabIDs {
  createPlacementCandidateSearch = 'create-placement-candidate-search',
  createPlacementOrderSearch = 'create-placement-order-search',
  createPlacementRecruiter = 'create-placement-recruiter',
  createPlacementAvailableStartDate = 'create-placement-available-start-date',
  createPlacementRequestedShifts = 'create-placement-requested-shifts',
  createPlacementRequestedTimeOff = 'create-placement-requested-time-off',
  createPlacementSellingPoints = 'create-placement-selling-points',
  createPlacementCancelButton = 'create-placement-cancel-button',
  createPlacementSubmitButton = 'create-placement-submit-button',
  createPlacementConfirmCancelButton = 'create-placement-confirm-cancel-button',
  createPlacementConfirmDiscardButton = 'create-placement-confirm-discard-button',
}
export const createPlacementTabbingOrder: ITabbingType[] = [
  { id: createPlacementTabIDs.createPlacementCandidateSearch, action: 'focus' },
  { id: createPlacementTabIDs.createPlacementOrderSearch, action: 'focus' },
  { id: createPlacementTabIDs.createPlacementRecruiter, action: 'focus' },
  { id: createPlacementTabIDs.createPlacementAvailableStartDate, action: 'click' },
  { id: createPlacementTabIDs.createPlacementRequestedShifts, action: 'click' },
  { id: createPlacementTabIDs.createPlacementRequestedTimeOff, action: 'focus' },
  { id: createPlacementTabIDs.createPlacementSellingPoints, action: 'click' },
  { id: createPlacementTabIDs.createPlacementSubmitButton, action: 'focus' },
  { id: createPlacementTabIDs.createPlacementCancelButton, action: 'focus' },
];

export const createPlacementConfirmDialogTabbingOrder: ITabbingType[] = [
  { id: createPlacementTabIDs.createPlacementConfirmCancelButton, action: 'focus' },
  { id: createPlacementTabIDs.createPlacementConfirmDiscardButton, action: 'focus' },
];
