import { theme } from 'styles/global-styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  container: {
    border: `1px solid ${theme.palette.system.paleGray}`,
    borderRadius: '4px 4px 4px 4px',
    background: theme.palette.framework.main,
    display: 'flex',
    flexDirection: 'column',
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
  },
  children: {
    padding: 12,
  },
  addLink: {
    paddingTop: 6,
    paddingBottom: 0,
  },
  profileInlineLink: {
    color: theme.palette.framework.system.navyBlue,
    'font-size': '14px',
    'font-weight': '600',
    'padding-left': '0.3rem',
    'text-decoration': 'underline',
    'white-space': 'nowrap',
    cursor: 'pointer',
  },
  profileSummaryInlineLink: {
    color: theme.palette.framework.system.navyBlue,
    'font-weight': '800',
    'padding-left': '0.3rem',
    'text-decoration': 'underline',
    cursor: 'pointer',
  },
  boxInlineHeadLabelTxt: {
    'font-weight': '600',
    'margin-top': '0.3rem',
  },
  profileInlineLinkDisabled: {
    color: theme.palette.framework.system.navyBlue,
    'font-weight': '800',
    'padding-left': '0.3rem',
    'text-decoration': 'underline',
    pointerEvents: 'none',
    opacity: 0.4,
  },
  inlineLink: {
    color: theme.palette.framework.system.navyBlue,
    fontSize: '14px',
    cursor: 'pointer',
  },
  customScroll: {
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  tooltipRowHeader: {
    color: theme.palette.framework.system.charcoal,
    fontSize: 13,
  },
  tooltipRowContent: {
    color: theme.palette.framework.system.doveGray,
    fontSize: 13,
  },
  sectionDivider: {
    height: '12px',
  },
  title: {
    color: `${theme.palette.system.richBlack} !important`,
    fontSize: '16px !important',
    fontWeight: 500,
  },
  content: {
    paddingTop: '5px',
  },
  rowItem: {
    marginLeft: 0,
    paddingTop: 5,
  },
  rowSectionMargin: {
    marginTop: 6,
  },
  button: {
    cursor: 'pointer',
    color: theme.palette.framework.system.navyBlue,
    justifyContent: 'flex-start',
    marginLeft: '3px',
    fontWeight: 450,
    padding: '0px',
    margin: '0px !important',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
  header: {
    '&:hover': {
      color: theme.palette.framework.system.navyBlue,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  digit: {
    color: theme.palette.framework.system.navyBlue,
  },
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50vh',
  },
  tooltipPlacementBottom: {
    backgroundColor: theme.palette.system.mutedAzureBlue,
    margin: 0,
    padding: '5px',
    color: theme.palette.framework.main,
    fontWeight: 100,
    fontSize: '8px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  inlineBoxEditItemPadding: {
    padding: '0 0.4rem',
  },
  inlineBoxEditMainLblItem: {
    paddingLeft: '3px',
  },
  inlineBoxEditMainCheckItem: {
    'padding-left': '0',
    'padding-right': '0.2rem',
    '& svg': {
      width: '25px',
      height: '25px',
    },
  },
  yellowAlert: {
    marginLeft: 1,
  },
  inlineBoxEditInputItem: {
    'border-top': '0',
    'border-left': '0',
    'border-right': '0',
    'border-bottom-color': '#bababa',
    '&:focus-visible': {
      outline: 'none',
      background: theme.palette.system.lightGrayFaded,
    },
  },
  progreesBar: {
    backgroundColor: theme.palette.framework.system.lightGray2,
  },
  ProgreesBarS: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: `${theme.palette.system.darkGreenForIcon} !important`,
    },
  },
  profileInlineLinkQualification: {
    color: theme.palette.framework.system.navyBlue,
    'font-weight': '800',
    'padding-left': '0.3rem',
    'text-decoration': 'underline',
    cursor: 'pointer',
  },
  editLink: {
    padding: '0px !important',
  },
  dialogContainer: {
    height: 220,
    width: '60%',
    maxWidth: '60%',
  },
  dialogContainerHearsay: {
    minHeight: '530px',
    minWidth: '600px',
  },
  dialogContainerGrid: {
    width: '100%',
    padding: '23px 0px 0px 0px !important',
  },
  normalText: {
    'font-weight': 'normal',
    fontSize: '14px',
    width: '100%',
  },
}));

export const useTooltipStyles = makeStyles()(theme => ({
  customScroll: {
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  tooltipRowHeader: {
    color: theme.palette.framework.system.charcoal,
    fontSize: 13,
  },
  tooltipRowContent: {
    color: theme.palette.framework.system.charcoal,
    fontSize: 13,
  },
}));
