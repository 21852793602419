import React from 'react';
import { Button } from 'amn-ui-core';

export const CancelButton = props => {
  const { onClick, children, ...rest } = props;
  return (
    <Button {...rest} color="tertiary" onClick={onClick}>
      {children}
    </Button>
  );
};
