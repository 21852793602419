import React from 'react';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { useTranslation } from 'react-i18next';
import { Chip } from 'amn-ui-core/dist/components/Chip/Chip';
import { missingField } from 'app/constants';
import { useSelector } from 'react-redux';
import { lookupSelector } from 'store/redux-store/lookup/lookup.selectors';
import { Box, Typography } from 'amn-ui-core';

export const TimeOffView = ({ selectedWorkExperience, timeOffStartDate, timeOffEndDate, classes }) => {
  const { t } = useTranslation();
  const lookupValues = useSelector(lookupSelector);
  const getStateFullName = value => {
    return lookupValues?.states?.find(item => item.ID === value)?.Description ?? value;
  };
  return (
    <>
      <LayoutGrid container spacing={2} style={{ minWidth: 1224 }}>
        <LayoutGridItem xs={4}>
          <div className="column">
            <Chip label={t('candidate.workExperienceView.personalTimeOff')} variant="outlined" />
          </div>
        </LayoutGridItem>
      </LayoutGrid>
      <LayoutGrid container spacing={2} marginTop={-1} style={{ minWidth: 1224 }}>
        <LayoutGridItem xs={4}>
          <div className="column">
            <div className={`row ${classes.columnRow}`}>
              {'Date:  '}
              <strong>
                {timeOffStartDate} - {timeOffEndDate}
              </strong>
            </div>
          </div>
        </LayoutGridItem>
        <LayoutGridItem xs={2.5} display={'flex'} justifyContent={'center'}>
          <div className="column">
            <div className={`row ${classes.columnRow}`}>
              {'Ongoing:  '}
              {selectedWorkExperience?.currentlyEmployed ? (
                <strong>Yes</strong>
              ) : selectedWorkExperience?.currentlyEmployed === false ? (
                <strong>No</strong>
              ) : (
                ''
              )}
            </div>
          </div>
        </LayoutGridItem>
        <LayoutGridItem xs={5} display={'flex'} justifyContent={'center'}>
          <div className="column">
            <div className={`row ${classes.columnRow}`}>
              <div className={`${classes.detailsContainer}`}>
                <Typography>
                  {selectedWorkExperience?.location?.city ? selectedWorkExperience.location.city : missingField},
                </Typography>
                <Box mx={0.5} />
                <Typography>
                  {selectedWorkExperience?.location?.state
                    ? getStateFullName(selectedWorkExperience.location.state)
                    : missingField}{' '}
                  {selectedWorkExperience?.location?.zip ? selectedWorkExperience.location.zip : missingField},
                </Typography>
                <Box mx={0.5} />
                <Typography>
                  {selectedWorkExperience?.location?.country ? selectedWorkExperience.location.country : missingField}
                </Typography>
              </div>
            </div>
          </div>
        </LayoutGridItem>
      </LayoutGrid>
    </>
  );
};
