import React, { useEffect, useMemo } from 'react';
import StatusTile from '@AMIEWEB/Unit/Common/StatusTile';
import { Box, Theme, Typography } from 'amn-ui-core';
import { missingField } from 'app/constants';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { makeStyles } from 'tss-react/mui';
import { transformUnitSkillsetCollections } from '../Qualifications/helper';
import { AndOrOperator, AndOrText, SkillSetCollections } from 'app/enums/Common';
import { FacilityUnitActions } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.redux';
import { useDispatch, useSelector } from 'react-redux';
import { selectCertificatesFullName } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';
import { ICollection, IRequirements } from '@AMIEWEB/Facility/FacilityStore/Facility.model';

const useStyles = makeStyles()((theme: Theme) => ({
  docIcon: {
    color: theme.palette.framework.system.coolGray,
    width: '20px',
    height: '20px',
  },
}));

const Collections = ({ data }: { data: ICollection | { type: null; operation: null; requirements: [] } }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { operation }: { operation: AndOrOperator | null } = data;
  const certificatesFullName = useSelector(selectCertificatesFullName);

  useEffect(() => {
    if (data?.type === SkillSetCollections.Certifications && data?.requirements?.length) {
      const allSubCertIds = data.requirements.flatMap((requirement: IRequirements) => {
        if (requirement?.subCertificate?.subCertificationId && requirement.requirementAbbreviation === 'CERT') {
          if (requirement?.subCertificate?.subCertificationId >= 1000) {
            //checking if length is greater than 3
            return requirement.subCertificate.subCertificationId / 1000; // Remove last 3 digits
          }
          return requirement.subCertificate.subCertificationId; // Return unchanged if less than 1000
        }
        return [];
      });

      if (allSubCertIds?.length) {
        const uniqueIds = Array.from(new Set(allSubCertIds));
        let missingIds = true;

        if (certificatesFullName?.length) {
          const certificateTypeIdsSet = new Set(certificatesFullName.map(cert => cert.requirementTypeId));
          missingIds =
            uniqueIds.some(certId => !certificateTypeIdsSet.has(certId)) ||
            certificateTypeIdsSet.size !== uniqueIds.length;
        }
        if (missingIds) {
          dispatch(FacilityUnitActions.requestFacilityUnitsCertificatesFullName({ requirementTypeIds: uniqueIds }));
        }
      }
    }
  }, [data?.requirements]);

  const skillsetCollections = useMemo(() => {
    if (!data?.requirements?.length) return [];

    let requirementsData = data.requirements;

    if (data?.type === SkillSetCollections.Certifications && certificatesFullName?.length) {
      requirementsData = data.requirements.map(req => {
        if (req.requirementAbbreviation === 'CERT' && req?.subCertificate?.subCertificationId) {
          const requirementTypeId =
            req.subCertificate.subCertificationId >= 1000
              ? req.subCertificate.subCertificationId / 1000
              : req.subCertificate.subCertificationId;
          const filteredCertFullNameData = certificatesFullName.filter(
            subItem =>
              subItem.requirementTypeId === requirementTypeId &&
              subItem.description &&
              req?.subCertificate?.subCertificateName &&
              subItem.description.includes(req?.subCertificate?.subCertificateName),
          );

          const updatedSubCertificates =
            filteredCertFullNameData?.length && filteredCertFullNameData[0]?.description
              ? { ...req.subCertificate, subCertificateName: filteredCertFullNameData[0].description }
              : { ...req.subCertificate };
          return { ...req, subCertificate: updatedSubCertificates };
        }
        return req;
      });
    }

    return transformUnitSkillsetCollections(
      data?.type,
      requirementsData as IRequirements[],
      <DescriptionOutlinedIcon className={classes.docIcon} />,
    );
  }, [data?.requirements, certificatesFullName]);

  return (
    <>
      {data?.requirements?.length > 0 ? (
        skillsetCollections.map((tile, index) => [
          <StatusTile
            tileData={tile}
            additionalBorderRadiusData={{
              andOrOperator: operation,
              currentIndex: index,
              finalIndex: skillsetCollections?.length - 1,
            }}
          />,
          operation === AndOrOperator.Or && skillsetCollections?.length - 1 > index && (
            <Typography variant="body2" p={1}>
              {AndOrText.Or}
            </Typography>
          ),
        ])
      ) : (
        <Box>{missingField}</Box>
      )}
    </>
  );
};

export default Collections;
