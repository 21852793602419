import { Link, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { Table, TableCell, ColumnHeader } from '../../../CommonTableComponents/CommonTableComponents';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { missingField } from 'app/constants';
import PopOutIcon from 'app/assets/images/IconImages/PopOutIcon.svg';
import { GetMomentOfDate } from 'utils/dates/moment';
import { useFormContext, useWatch } from 'react-hook-form';
import { IDepartureAirport } from 'app/models/Placement/PlacementSummary';
import { selectOfferInfo } from 'store/redux-store/placement-summary/selectors';

export const TravelDetailsTable = () => {
  const {
    strikeTravelDetails: { travelToStrike, travelFromStrike },
  } = useSelector(selectOfferInfo);
  const { t } = useTranslation();

  const departureDateMoment = GetMomentOfDate(travelFromStrike.departureDate);
  const departureTimeMoment = GetMomentOfDate(travelFromStrike.departureTime);

  const arrivalDateMoment = GetMomentOfDate(travelToStrike.arrivalDate);
  const arrivalTimeMoment = GetMomentOfDate(travelToStrike.arrivalTime);

  const { control } = useFormContext();

  const travelToAirport = useWatch({ name: 'departureAirport', control }) as IDepartureAirport;

  return (
    <Table>
      <thead style={{ width: '100%' }}>
        <TableCell />
        <TableCell>
          <ColumnHeader>
            {t('placement.profile.summary.offer.travelDetails.travelDetailsTable.travelToStrike')}
          </ColumnHeader>
        </TableCell>
        <TableCell>
          <ColumnHeader>
            {t('placement.profile.summary.offer.travelDetails.travelDetailsTable.travelFromStrike')}
          </ColumnHeader>
        </TableCell>
      </thead>
      <tbody>
        <RowSection
          header={t('placement.profile.summary.offer.travelDetails.travelDetailsTable.statusOfTravel')}
          dataSects={[travelToStrike.statusOfTravel, travelFromStrike.statusOfTravel]}
        />
        <RowSection
          header={t('placement.profile.summary.offer.travelDetails.travelDetailsTable.airline')}
          dataSects={[travelToStrike.airline, travelFromStrike.airline]}
        />
        <RowSection
          header={t('placement.profile.summary.offer.travelDetails.travelDetailsTable.flightNumber')}
          dataSects={[travelToStrike.flightNumber, travelFromStrike.flightNumber]}
        />
        <RowSection
          header={t('placement.profile.summary.offer.travelDetails.travelDetailsTable.departureAirport')}
          dataSects={[travelToAirport?.label ?? missingField, travelFromStrike.departureAirport]}
        />
        <RowSection
          header={t('placement.profile.summary.offer.travelDetails.travelDetailsTable.departureDate')}
          dataSects={[null, departureDateMoment.isValid() ? departureDateMoment.format('MM/DD/YYYY') : null]}
        />
        <RowSection
          header={t('placement.profile.summary.offer.travelDetails.travelDetailsTable.departureTime')}
          dataSects={[null, departureTimeMoment.isValid() ? departureTimeMoment.format('hh:mm A') : null]}
        />
        <RowSection
          header={t('placement.profile.summary.offer.travelDetails.travelDetailsTable.arrivalAirport')}
          dataSects={[travelToStrike.arrivalAirport, travelFromStrike.arrivalAirport]}
        />
        <RowSection
          header={t('placement.profile.summary.offer.travelDetails.travelDetailsTable.arrivalDate')}
          dataSects={[arrivalDateMoment.isValid() ? arrivalDateMoment.format('MM/DD/YYYY') : null, null]}
        />
        <RowSection
          header={t('placement.profile.summary.offer.travelDetails.travelDetailsTable.arrivalTime')}
          dataSects={[arrivalTimeMoment.isValid() ? arrivalTimeMoment.format('hh:mm A') : null, null]}
        />
        <RowSection
          header={t('placement.profile.summary.offer.travelDetails.travelDetailsTable.itineraryLink')}
          dataSects={[
            travelToStrike.itineraryLink ? <ItineraryLink t={t} link={travelToStrike.itineraryLink} /> : null,
            travelFromStrike.itineraryLink ? <ItineraryLink t={t} link={travelFromStrike.itineraryLink} /> : null,
          ]}
        />
      </tbody>
    </Table>
  );
};

const useRowStyles = makeStyles()({
  rowTitle: {
    wordBreak: 'keep-all',
    width: '20%',
    fontWeight: 450,
    fontSize: 12,
  },
  rowCell: {
    fontSize: 14,
  },
});

const RowSection: React.FC<{ header: any; dataSects: any }> = props => {
  const { classes } = useRowStyles();
  return (
    <tr>
      <TableCell className={classes.rowTitle}>{props.header}</TableCell>
      {props.dataSects.map((data, index) => (
        <TableCell key={index.toString()} className={classes.rowCell}>
          {data ?? missingField}
        </TableCell>
      ))}
    </tr>
  );
};

const ItineraryLink = ({ link, t }) => (
  <Link href={link} target="_blank">
    <Typography variant="body1">{t('placement.profile.summary.offer.transportation.yourItinerary')}</Typography>
    <img src={PopOutIcon} alt="" style={{ fill: '#8e8e8e' }} />
  </Link>
);
