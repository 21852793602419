import React, { useState } from 'react';
import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { useWatch } from 'react-hook-form';
import { useEditableRequirement } from './EditRequirementsHelper';
import { travelConfirmedCheck } from './EditablePassFailLogic';
import { useSelector } from 'react-redux';
import { selectEditableRequirementTypes } from '../../../../../../../../../store/redux-store/placement-summary/selectors';

export const useEditableRequirementTravelConfirmed = () => {
  // TRAVEL CONFIRMED
  const travelConfirmed: any = useWatch({ name: 'travelConfirmed' });
  const [secondaryData, setSecondaryData] = useState<any>({});
  // Supporting data
  const req = useSelector(selectEditableRequirementTypes);

  React.useEffect(() => {
    setSecondaryData({
      travelTimePreference: req.travelTimePreference,
      transportation: req.transportation,
      dob: req.dob,
      gender: req.gender,
      ssn: req.ssn,
      departureAirport: req.departureAirport,
      datesConfirmed: req.datesConfirmed,
      placementDate: req.placementDate,
      departureItineraryLink: req.departureItineraryLink,
      shiftOffered: req.shiftOffered,
    });
  }, [
    req.travelTimePreference,
    req.transportation,
    req.gender,
    req.ssn,
    req.dob,
    req.departureAirport,
    req.datesConfirmed,
    req.placementDate,
    req.departureItineraryLink,
    req.shiftOffered,
  ]);

  useEditableRequirement<any, any>({
    data: travelConfirmed?.checked,
    secondaryData,
    requirementId: IPlacementRequirementKey.travelConfirmed,
    failRequirement: {
      id: IPlacementRequirementKey.travelConfirmed,
      message: 'Travel Confirmed: Not Selected',
      tooltip: {
        message: 'Travel Confirmed checkbox must be selected',
      },
    },
    requirementKey: 'travelConfirmed',
    requirementCheck: travelConfirmedCheck,
  });
};
