import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  candidateDetailsSelection,
  CredentialingLookups as CredentialingLookupsSelection,
} from '../../../../Profile/CandidateDetails.selector';
import { Grid } from 'amn-ui-core';
import { RowSection } from '../../Common/RowSection';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import moment from 'moment';
import { initialTooltipFields, ITooltipField } from './ITooltipField';
import { useCovidCardStyes } from './styles';
import { LookupFields } from 'app/models/Credentialing/enums/Lookup';
import { CovidVaccinationStatus } from 'app/models/Credentialing/enums/CovidVaccinationStatus';
import { RequirementType } from 'app/models/Credentialing/enums/RequirementType';
import { canShow } from './rules';
import { useDecision } from '@optimizely/react-sdk';
import { ff_summaryTabIconsUpdate } from 'app/constants/FeatureFlags/Candidate/Keys';
import { orderBy } from 'lodash';

const CovidSatusCard = props => {
  const { covidStatusInformation } = props;
  const { t } = useTranslation();
  const { classes } = useCovidCardStyes();
  const [summaryTabIconsUpdateDecision] = useDecision(ff_summaryTabIconsUpdate);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const lookupselection = useSelector(CredentialingLookupsSelection);
  const [showTooltip, setShowTooltip] = useState<boolean>(true);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showHalfwayProgress, setShowHalfwayProgress] = useState<boolean>(false);
  const [showCheckmark, setShowCheckmark] = useState<boolean>(false);
  const [vaccinationChecks, setVaccinationChecks] = useState<number>(0);
  const [tooltipInfo, setTooltipInfo] = useState<ITooltipField>(initialTooltipFields);

  const fluStatusInformation = useMemo(() => {
    if (!candidateDetails.credentials) return [];
    return candidateDetails.credentials.employeeHealth?.filter(
      e => e.requirementTypeID === RequirementType.FLUVACCINATION,
    );
  }, [candidateDetails.credentials?.employeeHealth]);

  const getCovidVaccinationTooltip = () => {
    const data = tooltipInfo;
    return (
      <Grid container direction="column">
        {!candidateDetails?.covidVaccinationstatus && fluStatusInformation?.length > 0 && (
          <>
            <Grid item className={classes.tooltipRowContent}>
              {t('candidate.qualificationsCard.vaccinationUnavailableTooltipLine1')}
            </Grid>
            <Grid item className={classes.tooltipRowContent}>
              {t('candidate.qualificationsCard.vaccinationUnavailableTooltipLine2')}
            </Grid>
          </>
        )}
        {(data.expirationDate || data.manufacturer || data.vaccinationDate) && (
          <Grid item>
            <Grid container direction="column" style={{ paddingBottom: '10px' }}>
              <Grid item className={classes.tooltipRowContent}>
                <span style={{ fontWeight: 'bold' }}>{`Vaccination Date: `}</span>
                {data.vaccinationDate ? moment(data.vaccinationDate).format('MM/DD/YYYY') : `--`}
              </Grid>
              <Grid item className={classes.tooltipRowContent}>
                <span style={{ fontWeight: 'bold' }}>{`Expiration Date: `}</span>
                {data.expirationDate ? moment(data.expirationDate).format('MM/DD/YYYY') : `--`}
              </Grid>
              <Grid item className={classes.tooltipRowContent}>
                <span style={{ fontWeight: 'bold' }}>{`Manufacturer: `}</span>
                {data.manufacturer ? data.manufacturer : `--`}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const processCovidVaccinationStatus = useCallback(() => {
    switch (candidateDetails.covidVaccinationstatus as CovidVaccinationStatus) {
      case CovidVaccinationStatus.BoosterEligible:
      case CovidVaccinationStatus.Booster1Eligible:
      case CovidVaccinationStatus.Unknown:
        setShowAlert(true);
        break;
      case CovidVaccinationStatus.DeclinationPersonal:
      case CovidVaccinationStatus.DeclinationMedical:
      case CovidVaccinationStatus.DeclinationReligious:
      case CovidVaccinationStatus.DeclinationSubmittedPersonal:
      case CovidVaccinationStatus.DeclinationSubmittedMedical:
      case CovidVaccinationStatus.DeclinationSubmittedReligious:
      case CovidVaccinationStatus.PartiallyVaccinated:
        setShowHalfwayProgress(true);
        break;
      case CovidVaccinationStatus.DeclinationApprovedPersonal:
      case CovidVaccinationStatus.DeclinationApprovedMedical:
      case CovidVaccinationStatus.DeclinationApprovedReligious:
      case CovidVaccinationStatus.BoosterUptoDate:
      case CovidVaccinationStatus.Booster2UptoDate:
      case CovidVaccinationStatus.FullyVaccinated:
      case CovidVaccinationStatus.Vaccinated:
      case CovidVaccinationStatus.VaccineInternational:
        setShowCheckmark(true);
        setVaccinationChecks(1);
        break;
    }
  }, [candidateDetails.covidVaccinationstatus]);

  useEffect(() => {
    processCovidVaccinationStatus();
    const canShowTooltip = canShow(candidateDetails.covidVaccinationstatus as CovidVaccinationStatus);
    setShowTooltip(canShowTooltip || (!candidateDetails.covidVaccinationstatus && fluStatusInformation?.length > 0));
    const firstReport = orderBy(
      covidStatusInformation?.filter(e => e.typeID === 22),
      ['dateCompleted'],
      ['desc'],
    )[0];

    const manufacturer = lookupselection
      ?.filter(e => e.lookUpField === LookupFields.ManufacturerID)[0]
      ?.lookups?.find(e => e.value === firstReport?.manufacturerID);

    const data = {
      expirationDate: firstReport?.expirationDate,
      manufacturer: manufacturer?.description,
      vaccinationDate: firstReport?.dateCompleted,
    };
    setTooltipInfo(data);
  }, [candidateDetails.covidVaccinationstatus, covidStatusInformation, lookupselection, processCovidVaccinationStatus]);

  return (
    <Grid item>
      <CustomTooltip
        standardMargin
        tooltipContent={getCovidVaccinationTooltip()}
        placement="bottom-start"
        disabled={summaryTabIconsUpdateDecision && !showTooltip}
      >
        {candidateDetails.covidVaccinationstatus ? (
          <RowSection
            header={t(candidateDetails.covidVaccinationstatus)}
            data={''}
            preData={showCheckmark ? candidateDetails.covidVaccinationstatus : ''}
            showHalfwayProgress={showHalfwayProgress}
            showHalfwayProgressSecondaryIcon={true}
            showAlert={showAlert}
            color={vaccinationChecks ? 'Green' : '#006FB9'}
          />
        ) : (
          !candidateDetails.covidVaccinationstatus &&
          fluStatusInformation.length > 0 && (
            <RowSection
              header={t('COVID')}
              data={''}
              preData={''}
              showHalfwayProgress={true}
              showHalfwayProgressSecondaryIcon={true}
              showAlert={false}
              color={'#006FB9'}
            />
          )
        )}
      </CustomTooltip>
    </Grid>
  );
};

export default CovidSatusCard;
