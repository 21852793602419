import { useDecision } from '@optimizely/react-sdk';
import { Box, CircularProgress } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { base64DocumentToURL } from 'app/helpers/fileHelper';
import { ff_placement_ui_document_test_ids } from 'app/constants/FeatureFlags/Placement/Keys';
import { httpSuccess } from 'app/services/serviceHelpers';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useParams } from 'react-router-dom';
import { SharedService } from 'app/services/SharedServices/shared-service';

const useStyles = makeStyles()({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circularProgress: {
    alignSelf: 'center',
  },
  errorMessage: {
    color: 'red',
    fontSize: 28,
  },
});

const trackerArea = 'track-placement-doc-fetch';

export const PlacementDocViewerClassic = () => {
  const { documentType, documentId } = useParams<{ documentType; documentId }>();
  const [fakePlacementDocuments] = useDecision(ff_placement_ui_document_test_ids);
  const { promiseInProgress } = usePromiseTracker({ area: trackerArea, delay: 0 });

  const { classes } = useStyles();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const [documentProps, setDocumentProps] = useState<{ url: string; dispose: Function } | null>(null);

  const fetchDocument = async () => {
    const sharedService = SharedService.createInstance();
    /**
     * Code: restricted for Dev and QA - since documents are limited on these envs
     * Using fake fake arg values for documentId w.r.t documentType
     */
    const queryDocumentId = fakePlacementDocuments.enabled
      ? ((fakePlacementDocuments.variables['documents'] ?? {}) as any)[documentType.toLowerCase()]
      : documentId;

    const response = await sharedService.getPlacementClassicDocument(documentType, queryDocumentId);

    if (response && httpSuccess(response.status))
      if (!!response.data.data) {
        const docProps = base64DocumentToURL(response.data.data, {
          fileType: 'application/pdf',
          toolbar: true,
          target: '_blank',
          fileName: response.data.fileName,
        });
        setDocumentProps(docProps);
      } else setErrorMessage(response.data.message);
    else setErrorMessage(t('placement.profile.tabs.documentTab.errorInFetchingDocument'));
  };

  useEffect(() => {
    if (documentId && documentType) trackPromise(fetchDocument(), trackerArea);

    /**
     * Refactor: clearing data on umount is unnecessary since documentProps is local variable
     */
    // return () => {
    //   if (documentProps) documentProps.dispose();
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, documentType]);

  const Document = useMemo(() => {
    return (
      <Box aria-label="placement-doc-viewer-root" className={classes.root}>
        {promiseInProgress ? (
          <CircularProgress
            size="2rem"
            classes={{ root: classes.circularProgress }}
            aria-label="placement-doc-loader"
          />
        ) : documentProps?.url ? (
          <object
            aria-label="placement-doc-viewer-url-object"
            title={t('placement.profile.tabs.documentTab.title1')}
            data={documentProps.url}
            height="100%"
            width="100%"
          />
        ) : (
          <Box aria-label="placement-doc-error-message" className={classes.errorMessage}>
            {errorMessage ?? null}
          </Box>
        )}
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentProps, errorMessage, promiseInProgress]);

  return Document;
};
