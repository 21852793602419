import { IRequirementRowPadding } from 'app/models/Placement/PlacementSummary';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import { selectBookingInfo } from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { ConfirmationsRow } from '../../TableComponents/Confirmations/ConfirmationsTable';
import { WiselySwitch } from './WiselySwitch';

export const Wisely = ({ hasRequirements }: IRequirementRowPadding) => {
  const { control } = useFormContext();
  const {
    confirmation: { wisely },
    requirementFilter,
  } = useSelector(selectBookingInfo);

  const { display } = useRequirementFilter('booking-confirmation', requirementFilter, wisely?.type);

  return display ? (
    <Controller
      control={control}
      name="wisely"
      render={({ ref, value, ...rest }) => (
        <ConfirmationsRow
          id="placement-booking-confirmation-wisely"
          lastUpdatedDate={value?.lastUpdatedAt}
          requirement={wisely?.type}
          hasOtherRequirements={hasRequirements}
          /** Below value ensures field is empty */
          lastUpdatedBy=" "
          controlComponent={<WiselySwitch value={value} disabled {...rest} />}
        />
      )}
    />
  ) : null;
};
