import { createTheme, ThemeProvider, StyledEngineProvider } from 'amn-ui-core';
import React from 'react';
import { colors } from 'styles/styleVariables';
import { TypeAhead } from '../TypeAheads/TypeAhead';
import { theme } from 'styles/global-styles';

const subTheme = createTheme(
  theme,
  {
    components: {
      MuiInput: {
        styleOverrides: {
          root: {
            background: '#fff !important',
          },
          underline: {
            '&:hover:not($disabled):not($focused):not($error):before': {
              borderBottom: `1px solid ${colors.amnLightBlue}`,
            },
            '&:after': {
              borderBottom: `2px solid ${colors.amnLightBlue}`,
            },
          },
        },
      },
    },
  },
);


export const InlineAutoComplete = props => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={subTheme}>
        <TypeAhead size="small" variant="standard" {...props} enableCustomScroll removeCloseIcon />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
