import { Grid, Typography } from 'amn-ui-core';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { Concatenate } from 'utils/string/string';
import { TableSection } from '../../../customComponents/TableSection';
import { getRequirementCheck } from '../../../helper';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import {
  selectServiceFailures,
  selectSubmissionInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { RequirementsRow } from '../../TableComponents/TableComponents';
import { ListContent } from 'app/components/Common/List/ListContent';

export const Education = () => {
  const { t } = useTranslation();
  const {
    amnReq: {
      candidate: { education },
      amn: { education: amnEducation },
    },
    requirementFilter,
  } = useSelector(selectSubmissionInfo);

  const placementOrderType = useSelector(selectPlacementOrderType);

  const { educationAmnRequirement: serviceFailed } = useSelector(selectServiceFailures);

  const { placementId } = useContext(AddOnContext);
  const { promiseInProgress } = usePromiseTracker({ area: 'placement-submission-education-amn-requirement', delay: 0 });

  const dispatch = useDispatch();
  const { display } = useRequirementFilter('submission-amn', requirementFilter, amnEducation?.type);

  return display ? (
    <RequirementsRow
      id="placement-submission-amn-education"
      title={t('placement.profile.summary.submission.amn.education.title')}
      requirementCell={<RowKey education={amnEducation} t={t} />}
      candidateCell={<RowValue education={education} />}
      loading={promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() =>
        dispatch(
          placementSummaryActions.getEducationAmnRequirement({
            placementId,
            placementOrderType,
            isRetryingService: true,
          }),
        )
      }
    />
  ) : null;
};

const RowValue = ({ education }) => (
  <TableSection
    items={
      education &&
      education.data.map(({ major, degree, graduationMonth, graduationYear }) => ({
        primaryContent: Concatenate(
          [
            major,
            degree,
            Concatenate([graduationMonth as unknown as string, graduationYear as unknown as string], '/'),
          ],
          ', ',
        ),
      }))
    }
  />
);

const RowKey = ({ education, t }) => (
  <TableSection
    items={[
      {
        icon: education ? getRequirementCheck(education.type) : null,
        primaryContent: (
          <Grid container>
            <Grid item xs={12}>
              <Typography>{t(education?.message?.secondary)}</Typography>
            </Grid>
            <Grid item xs={12}>
              {education?.message?.ternary && <ListContent list={education?.message?.ternary} />}
            </Grid>
          </Grid>
        ),
      },
    ]}
  />
);
