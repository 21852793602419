import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { CustomTabs } from '../Common/Tabs/CustomTabs';
import { trackPageView } from 'app-insights/appInsightsTracking';
import { DefaultHomeSwitch } from '../AppHome/DefaultHomeSwitch';
import { HomePage } from '../AppHome/AppHome';
import { recruiterPlacementActions } from './MyPlacementsDesk/RecruiterPlacements.redux';
import { useDispatch } from 'react-redux';
import { gridStateActions } from '../Common/Grid/GridStateManagement/GridState.redux';
import { MyPlacementsDesk } from './MyPlacementsDesk/MyPlacementsDesk';
import { CustomBackdrop } from '../Common/Backdrop/CustomBackdrop';
import { usePromiseTracker } from 'react-promise-tracker';
import { pageNames } from 'app/constants/PageNames';
import useDocumentTitle from 'utils/customHooks/useDocumentTitle';
import { CoverageDeskWrapper } from '@AMIEWEB/AmWorkDesk/CoverageDesk/CoverageDeskWrapper';
import { useTranslation } from 'react-i18next';
import { useDecision } from '@optimizely/react-sdk';
import { ff_task_ui_createcoveragetab } from 'app/constants/FeatureFlags/EmployeeCoverage/keys';
import { GenericTabs } from '@AMIEWEB/Common/Tabs/GenericTabs';
import { SalesReportWrapper } from '@AMIEWEB/ReportPages/R225/SalesReportWrapper';

const useStyles = makeStyles()({
  root: {
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: '#F5F5F5',
    flexWrap: 'nowrap',
  },
});

export const RecruiterWorkDesk = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { promiseInProgress } = usePromiseTracker({ area: 'Recruiter-Placements-call', delay: 0 });
  const { promiseInProgress: userPrefCall } = usePromiseTracker({ area: 'User-Preferences-Call', delay: 0 });
  const [coverageFlag] = useDecision(ff_task_ui_createcoveragetab);

  useDocumentTitle({ title: pageNames.workdeskRecruitment });

  useEffect(() => {
    trackPageView({ name: pageNames.workdeskRecruitment });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs = React.useMemo(() => {
    const _tabs = [
      { label: 'My Work Desk', component: <MyPlacementsDesk /> },
      {
        label: `${t('home.coverageWorkDesk.coverageTab')}`,
        component: <CoverageDeskWrapper workDeskDomain={HomePage.RECRUITER_WORK_DESK} />,
      },
      {
        label: `${t('home.amWorkDesk.recruiterSales')}`,
        component: <SalesReportWrapper workDesk={true} />,
      },
    ];
    if (!coverageFlag.enabled) _tabs.splice(1, 1);
    return _tabs;
  }, [coverageFlag.enabled, t]);

  useEffect(() => {
    return () => {
      dispatch(recruiterPlacementActions.reset());
      dispatch(gridStateActions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <CustomBackdrop open={promiseInProgress || userPrefCall} />
      <GenericTabs
        tabs={tabs}
        modifyUrl
        dense
        addOnComponent={<DefaultHomeSwitch page={HomePage.RECRUITER_WORK_DESK} />}
      />
    </div>
  );
};
