import { CovidVaccinationStatus } from 'app/models/Credentialing/enums/CovidVaccinationStatus';

export const canShow = (type: CovidVaccinationStatus): boolean => {
  const statusArrayForShowRule = [
    CovidVaccinationStatus.Vaccinated,
    CovidVaccinationStatus.BoosterUptoDate,
    CovidVaccinationStatus.FullyVaccinated,
    CovidVaccinationStatus.VaccineInternational,
    CovidVaccinationStatus.Booster2UptoDate,
  ];
  return statusArrayForShowRule.includes(type);
};
