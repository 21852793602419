import { TextField } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles<{ minWidth: number }>()((theme, props) => ({
  textField: {
    width: '100%',
  },
  smallTextField: {
    minWidth: props.minWidth && `${props.minWidth}px`,
    display: 'flex',
    flex: '1 1 auto',
    padding: '0px',
    '& > input': {
      padding: '3px 6px',
    },
  },
  smallTextFieldHeight: {
    padding: '0px',
    '&.MuiOutlinedInput-multiline': {
      padding: '3px 6px',
    },
  },
  icon: {
    padding: '0px 5px',
    cursor: 'pointer',
    height: '27px',
    width: '27px',
    color: '#8E9195',
  },
}));

export const CustomTextField = ({
  open,
  minWidth = 0,
  value,
  bulletPointOnEnter,
  maxLength,
  size = 'small',
  renderedFromInlineEditor = false,
  ...props
}: {
  /** editing requested from caller */
  open?: boolean;
  minWidth?: number;
  value: string | null;
  bulletPointOnEnter?: boolean;
  maxLength?: number;
  renderedFromInlineEditor?: boolean;
  [key: string]: any;
}) => {
  const textFieldRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>();
  const { classes, cx } = useStyles({ minWidth });

  React.useEffect(() => {
    if (open) textFieldRef?.current?.focus?.();
  }, [open]);

  const onClear = () => {
    props.onChange(null);
  };

  const _onChange = event => {
    const input = event.target;
    let val: string = input.value;
    if (bulletPointOnEnter && window.globalThis.keyEvent !== 8 && window.globalThis.keyEvent !== 46) {
      const caret = input.selectionStart ?? 0;
      let moveCaret = 2;
      if (window.globalThis.keyEvent === 13) {
        val = `${val.slice(0, caret)}• ${val.slice(caret)}`;
        window.globalThis.keyEvent = undefined;
        window.requestAnimationFrame(() => {
          input.selectionStart = caret + moveCaret;
          input.selectionEnd = caret + moveCaret;
        });
      } else if ((value === null || value === '') && !val.startsWith('•')) {
        if (caret === 0) {
          moveCaret = 1;
          val = `•${val}`;
        } else val = `• ${val}`;
        window.requestAnimationFrame(() => {
          input.selectionStart = caret + moveCaret;
          input.selectionEnd = caret + moveCaret;
        });
      } else {
        const first = val.slice(0, caret - 1);
        const second = val.slice(caret);
        if ((first.endsWith('\n') || first.endsWith('\r\n')) && !second.startsWith('•')) {
          val = `${val.slice(0, caret - 1)}• ${val.slice(caret - 1)}`;
          window.requestAnimationFrame(() => {
            input.selectionStart = caret + moveCaret;
            input.selectionEnd = caret + moveCaret;
          });
        }
      }
    }
    props.onChange(val === '' ? null : val);
  };

  const onKeyDown = event => {
    window.globalThis.keyEvent = event.keyCode;
  };

  return (
    <TextField
      inputRef={textFieldRef}
      onKeyDownCapture={onKeyDown}
      size={size}
      variant="outlined"
      className={cx(classes.textField, { [classes.smallTextField]: size === 'small' })}
      InputProps={{
        endAdornment:
          !renderedFromInlineEditor && !value ? undefined : <CloseIcon className={classes.icon} onClick={onClear} />,
        classes: {
          root: cx({ [classes.smallTextField]: size === 'small', [classes.smallTextFieldHeight]: size === 'small' }),
        },
      }}
      inputProps={{
        maxLength: maxLength,
      }}
      value={value ?? ''}
      {...props}
      onChange={_onChange}
    />
  );
};
