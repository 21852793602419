import React from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import { HoverGrid } from './HoverGrid';
import { HoverGlance } from './HoverGlance';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GridControlBuilder } from './GridControlBuilder';
import { HoverGlanceSingle } from './HoverGlanceSingle';
import { HoverPopUp } from 'app/components/ClientContact/HoverPopUp';
import { MultiLineSpan } from '../MultiLineSpan/MultiLineSpan';
import { gridHoverBehaviour } from 'app/models/Grid';
import { CountTooltip, CustomTooltip, IterableKeyTooltip, OverflowTooltip, tooltipType } from '../Tooltips';
import { CandidateNotification } from '../../Notification/CandidateNotifiation/CandidateNotifiationWrapper';

const useStyles = makeStyles()(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    pointerEvents: 'auto',
    padding: theme.spacing(1),
  },
  hoverableText: {
    color: '#006FB9',
  },
  link: {
    color: '#006FB9',
    cursor: 'pointer',
    fontSize: '0.875rem',
    textDecoration: 'underline',
  },
  linkNoColor: {
    cursor: 'pointer',
    fontSize: '0.875rem',
  },
  text: {
    fontSize: '0.875rem',
  },
  hoverViewWrapper: {
    display: 'inline-block',
  },
}));

export const gridHeader = [
  { title: 'Placement Id' },
  { title: 'Candidate Name' },
  { title: 'Recruiter Name' },
  { title: 'Placement Status' },
  { title: 'Sent Date' },
];

export const sampleInfo = [
  ['Phone Number', '123456789'],
  ['Email Address', 'work@amnhealthcare.com'],
];

export default function HoverView(props) {
  const history = useHistory();
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const { filterOptionsOverride, restrictRolePermissions } = props;

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick = event => {
    // call state navigation for state only manipulation of components
    if (props.header.stateNavigation && props.onStateNavigation) {
      props.onStateNavigation(props);
      return;
    }
    //Avoid clicks
    if (!props.link) {
      props.onClick?.();
      return;
    }
    history.push(props.link);
    if (props.onNavigation) {
      props.onNavigation(props.link, props.header);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const Worker_JobMatchGrid = 'JobMatchGrid';
  const Worker_InterestGrid = 'JobInterestGrid';
  const Worker_OrderId = 'orderId';
  const Worker_AccountManager = 'accountManager';
  const Worker_ReasonForLeaving = 'reasonForLeaving';
  const Worker_FacilityName = 'facilityName';
  const Worker_FilesInProgress = 'filesInProgress';

  function renderToolTip(title) {
    if (props.data.reasonForLeavingHoverViewData) {
      if (String(title) === Worker_ReasonForLeaving)
        return (
          <HoverGlanceSingle
            data={props.data.reasonForLeavingHoverViewData}
            headerText={props.data.reasonForLeavingHeader}
          />
        );
    } else if (props.gridName === Worker_JobMatchGrid || props.gridName === Worker_InterestGrid) {
      if (String(title).toLowerCase().includes('id'))
        if (String(title) === Worker_OrderId) {
          return (
            <HoverGlance
              data={props.data[`${title}HoverViewData`]}
              headerText={`Order ID - ${props.text}`}
              component={props.component}
              type="id"
            />
          );
        } else {
          return (
            <HoverGlance
              data={props.data[`${title}HoverViewData`]}
              headerText={`${title} - ${props.text}`}
              component={props.component}
              type="id"
            />
          );
        }

      if (String(title).toLowerCase().includes('name'))
        if (String(title) === Worker_FacilityName) {
          return (
            <HoverGlance
              data={props.data[`${title}HoverViewData`]}
              headerText={`${props.text}`}
              header={props.header}
              type="name"
            />
          );
        } else {
          return (
            <HoverGlance
              data={Object.entries(props.data)}
              link={{ to: props.link, text: props.text }}
              header={props.header}
              type="name"
            />
          );
        }

      if (String(title) === Worker_AccountManager)
        return <HoverGlance data={props.data.accountManagerHoverViewData} headerText={props.data.accountManager} />;

      if (String(title) === Worker_FilesInProgress)
        return <HoverGrid column={gridHeader} row={props.data.filesInProgressHoverViewData} />;
    } else if (props.header.hoverBehaviour === gridHoverBehaviour.popUp) {
      return <HoverPopUp data={props.data[`${title}HoverViewData`]} title={props.title}></HoverPopUp>;
    } else {
      if (props.data[`${title}HoverViewData`]) {
        return <HoverGlanceSingle data={props.data[`${title}HoverViewData`]} />;
      } else if (String(title).toLowerCase().includes('id'))
        return (
          <HoverGlance
            data={props.data[`${title}HoverViewData`]}
            link={{ to: props.link, text: props.text }}
            component={props.component}
            type="id"
          />
        );
      else if (String(title).toLowerCase().includes('name'))
        return (
          <HoverGlance
            data={Object.entries(props.data)}
            link={{ to: props.link, text: props.text }}
            header={props.header}
            type="name"
          />
        );
    }
  }

  return props.header.hasHoverView &&
    props.data[`${props.header.title}HoverViewData`] !== false &&
    props.data[`${props.header.title}HoverViewData`]?.length !== 0 &&
    !props.edit?.isEditing ? (
    <div onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} className={classes.hoverViewWrapper}>
      <Typography
        variant="body1"
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onClick={props.header.clickable ? handleClick : undefined}
        className={
          props.data.expandable && props.header.clickable
            ? classes.linkNoColor
            : props.header.clickable && props.text !== '--'
            ? classes.link
            : props.text !== '--'
            ? classes.hoverableText
            : classes.text
        }
      >
        {props.header.icon ? (
          <img src={props.text} alt={t('img')} />
        ) : props.header.customHoverView && !!props.data[`${props.header.title}HoverViewComponent`] ? (
          <React.Fragment>{props.data[`${props.header.title}HoverViewComponent`]}</React.Fragment>
        ) : props.header.control ? (
          <GridControlBuilder
            control={props.header.control}
            value={props.text}
            filterOptionsOveride={filterOptionsOverride}
            lightTheme={
              props.searchInitiation
                ? props.searchInitiation
                : props.data.expanded && !props.data.viewVersions && !props.data.exceptionWaive
            }
            edit={props.edit}
            restrictRolePermissions={restrictRolePermissions}
          />
        ) : props.header.multiLine ? (
          <MultiLineSpan
            data={props.text}
            showCount={props.data[`${props.header.title}HoverViewData`].length > 3}
            count={props.data[`${props.header.title}HoverViewData`].length - 3}
          ></MultiLineSpan>
        ) : (
          <React.Fragment>{props.text}</React.Fragment>
        )}
      </Typography>
      {(!props.header.multiLine || props.data[`${props.header.title}HoverViewData`]?.length > 3) &&
        !props.header.customHoverView && (
          <CustomTooltip
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            placement={'bottom'}
            onClose={handlePopoverClose}
            disableRestoreFocus
            tooltipContent={renderToolTip(props.header.title)}
          />
        )}
    </div>
  ) : (
    <Typography
      variant="body1"
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onClick={props.header.clickable ? handleClick : undefined}
      className={
        props.data.expandable && props.header.clickable
          ? classes.linkNoColor
          : props.header.clickable && props.text !== '--' && props.text !== '— '
          ? classes.link
          : classes.text
      }
    >
      {props.header.icon ? (
        <img src={props.text} alt={t('img')} />
      ) : props.header.customHoverView && !!props.data[`${props.header.title}HoverViewComponent`] ? (
        <React.Fragment>{props.data[`${props.header.title}HoverViewComponent`]}</React.Fragment>
      ) : props.header.control ? (
        <GridControlBuilder
          control={props.header.control}
          value={props.text}
          filterOptionsOveride={filterOptionsOverride}
          lightTheme={
            props.searchInitiation
              ? props.searchInitiation
              : props.data.expanded && !props.data.viewVersions && !props.data.exceptionWaive
          }
          edit={props.edit}
          restrictRolePermissions={restrictRolePermissions}
        />
      ) : props.header.multiLine ? (
        props.header.tooltip === tooltipType.CountTooltip ? (
          <CountTooltip value={props.text} {...props.tooltipAttributes} />
        ) : (
          <MultiLineSpan data={props.text} />
        )
      ) : props.header.tooltip === tooltipType.CustomTooltip ? (
        !props.tooltipAttributes.disableTooltip ? (
          <CustomTooltip value={props.text} children={props.text} renderedFromGrid {...props.tooltipAttributes} />
        ) : (
          props.text
        )
      ) : /**Below implementaion is limited to REC workdesk */
      props.header.tooltip === tooltipType.CandidateNotifiation ? (
        <CandidateNotification
          placementId={props.data['placementId']}
          channel={props.tooltipAttributes}
          displayText={props.text}
          standardMargin
        />
      ) : props.header.tooltip === tooltipType.IterableKeyTooltip ? (
        <IterableKeyTooltip value={props.text} attributes={props.tooltipAttributes} />
      ) : props.header.tooltip === tooltipType.OverflowTooltip ? (
        <OverflowTooltip
          value={props.text}
          contentStyle={{ fontWeight: 400, fontSize: '0.875rem', color: '#006FB9' }}
        />
      ) : (
        <React.Fragment>{props.text}</React.Fragment>
      )}
    </Typography>
  );
}
