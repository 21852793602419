import { ISaveCallLogPayload } from 'app/models/Notification/Notification';
import React from 'react';

export const formatVoiceData = (data: ISaveCallLogPayload) => {
  if (data?.CTIActivityHistoryId === '') {
    data.CTIActivityHistoryId = undefined;
  }

  if (data?.tos !== undefined && Array.isArray(data?.tos)) {
    const newTos = data?.tos?.map(item => {
      return {
        ...item,
        brandId: !!item?.brandId && typeof item?.brandId !== 'string' ? item?.brandId?.toString() : item?.brandId,
        contactId:
          !!item?.contactId && typeof item?.contactId !== 'string' ? item?.contactId?.toString() : item?.contactId,
      };
    });
    data = { ...data, tos: newTos };
  }
  if (data?.associatedRecords) {
    data.associatedRecords = data?.associatedRecords
      .filter(
        record =>
          record?.name !== undefined && record?.name !== '' && record?.value !== undefined && record?.value !== '',
      )
      .map(record => ({
        name: typeof record?.name === 'string' ? record?.name : String(record?.name),
        value: typeof record?.value === 'string' ? record?.value : String(record?.value),
      }));
  }
  if (!!data?.sender?.userId && typeof data?.sender?.userId !== 'string') {
    data.sender.userId = String(data?.sender.senderId);
  }

  if (!!data?.sender?.senderId && typeof data?.sender?.senderId !== 'string') {
    data.sender.senderId = String(data?.sender.senderId);
  }

  return data;
};
