import { Checkbox, Divider, List, ListItem, ListItemIcon, ListItemText } from 'amn-ui-core';
import { missingField } from 'app/constants';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Concatenate } from 'utils/string/string';
import { SendToButtonGroup } from './SendToButtonGroup';

const useStyles = makeStyles()(theme => ({
  container: {
    maxHeight: '394px',
    overflowY: 'auto'
  },
  primaryText: {
    fontSize: '16px',
    maxWidth: 'calc(100% - 220px)'
  },
  secondaryText: {
    fontSize: '14px',
    maxWidth: 'calc(100% - 220px)'
  },
  divider: {
    margin: '0 18px 0',
  },
  iconRoot: {
    minWidth: '34px',
  },
  checkbox: {
    paddingTop: 0,
    marginTop: '-24px',
  },
  disabled: {
    color: theme.palette.system.lightGrey,
  },
}));

export enum Tabs {
  To = 'To',
  CC = 'CC',
  BCC = 'BCC',
}

export const AttentionToContacts = ({ value: attentionContacts = [], onChange, disabled }) => {
  const { classes, cx } = useStyles();

  const handleChange = index => {
    if (disabled) return;
    const newSelections = [...attentionContacts];
    newSelections[index] = {
      ...newSelections[index],
      isChecked: !newSelections[index].isChecked,
      tabSelected: Tabs.To,
    };
    onChange(newSelections);
    /** ?: Flag Property */
  };

  return (
    <List classes={{ root: classes.container }}>
      {attentionContacts.map((contact, index) => (
        <>
          <ListItem key={index.toString()}>
            <ListItemIcon classes={{ root: classes.iconRoot }} onClick={_ => handleChange(index)}>
              <Checkbox
                edge="start"
                checked={contact.isChecked || false}
                disableRipple
                classes={{ root: classes.checkbox }}
                inputProps={{ 'aria-labelledby': contact.title }}
                disabled={disabled}
              />
            </ListItemIcon>
            <ListItemText
              primary={Concatenate([contact?.firstName, contact?.lastName], ' ')}
              secondary={
                <>
                  <div>{contact?.title || missingField}</div>
                  <div>{contact?.email || missingField}</div>
                </>
              }
              classes={{
                primary: cx(classes.primaryText, { [classes.disabled]: disabled }),
                secondary: cx(classes.secondaryText, { [classes.disabled]: disabled }),
              }}
            />
          </ListItem>
          {index >= attentionContacts.length - 1 ? null : (
            <Divider component="li" classes={{ root: classes.divider }} />
          )}
        </>
      ))}
    </List>
  );
};

export const SendToContacts = ({ value: sendToContacts = [], onChange, disabled }) => {
  const { classes, cx } = useStyles();

  const handleChange = (index, tabSelected = Tabs.To, tabChange = false) => {
    if (disabled) return;
    const newSelections = [...sendToContacts];
    newSelections[index] = {
      ...newSelections[index],
      isChecked: tabChange ? newSelections[index].isChecked : !newSelections[index].isChecked,
      tabSelected: tabSelected,
    };
    onChange(newSelections);
  };

  return (
    <List classes={{ root: classes.container }} >
      {sendToContacts.map((contact, index) => (
        <>
          <ListItem
            key={index.toString()}
            secondaryAction={
              disabled || !contact.isChecked ? undefined : (
                <SendToButtonGroup
                  index={index}
                  handleTabChange={tabValue => handleChange(index, tabValue, true)}
                  selectedTab={contact?.tabSelected}
                />
              )
            }
          >
            <ListItemIcon classes={{ root: classes.iconRoot }} onClick={_ => handleChange(index)}>
              <Checkbox
                edge="start"
                checked={contact.isChecked || false}
                disableRipple
                classes={{ root: classes.checkbox }}
                inputProps={{ 'aria-labelledby': contact.title }}
                disabled={disabled}
              />
            </ListItemIcon>
            <ListItemText
              primary={Concatenate([contact?.firstName, contact?.lastName], ' ')}
              secondary={
                <>
                  <div>{contact?.title || missingField}</div>
                  <div>{contact?.email || missingField}</div>
                </>
              }
              classes={{
                primary: cx(classes.primaryText, { [classes.disabled]: disabled }),
                secondary: cx(classes.secondaryText, { [classes.disabled]: disabled }),
              }}
            />
          </ListItem>
          {index >= sendToContacts.length - 1 ? null : <Divider component="li" classes={{ root: classes.divider }} />}
        </>
      ))}
    </List>
  );
};
