import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from 'react';
import { SplitPaneContext } from './SplitPane';
import { Grid } from 'amn-ui-core';

export const PaneDivider: React.FC<{ Element?; orientation: 'vertical' | 'horizontal'; [key: string]: any }> = ({
  Element = Grid,
  orientation,
  ...props
}) => {
  const { onMouseHoldDown } = useContext(SplitPaneContext);

  return (
    <Element
      {...props}
      onMouseDown={onMouseHoldDown}
      style={{
        width: orientation === 'vertical' ? '2px' : undefined,
        height: orientation === 'horizontal' ? '2px' : undefined,
        ...props.style,
        cursor: orientation === 'vertical' ? 'col-resize' : 'row-resize',
      }}
    />
  );
};

export const SplitPaneTop = ({ Element = Grid, ...props }) => {
  const topRef = useRef<HTMLDivElement>(null);
  const { clientHeight, setClientHeight } = useContext(SplitPaneContext);

  useEffect(() => {
    if (!clientHeight) {
      setClientHeight(topRef.current.clientHeight);
      return;
    }

    topRef.current.style.minHeight = clientHeight + 'px';
    topRef.current.style.maxHeight = clientHeight + 'px';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientHeight]);

  return <Element {...props} className="split-pane-top" style={{ flex: 1, ...props.style }} ref={topRef} />;
};

export const SplitPaneBottom = ({ Element = Grid, ...props }) => (
  <Element {...props} className="split-pane-bottom" style={{ flex: 1, ...props.style }} />
);

export const SplitPaneRight = forwardRef<HTMLDivElement, any>(({ Element = Grid, ...props }, outerRef) => {
  const ref = useRef<HTMLDivElement>(null);

  useImperativeHandle(outerRef, () => ref.current!, []);

  return <Element {...props} className="split-pane-right" style={{ flex: 1, ...props.style }} ref={ref} />;
});

export const SplitPaneLeft = forwardRef<HTMLDivElement, any>(({ Element = Grid, ...props }, outerRef) => {
  const ref = useRef<HTMLDivElement>(null);

  useImperativeHandle(outerRef, () => ref.current!, []);

  return <Element {...props} className="split-pane-left" ref={ref} style={{ flex: 1, ...props.style }} />;
});
