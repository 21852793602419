import React from 'react';
import { FormControlLabel, Radio } from 'amn-ui-core';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { materialUiXGrid } from 'amn-ui-core';
import { useDispatch } from 'react-redux';
import { placementModsAction } from 'store/redux-store/placement-mods/slice';

export const ModsLinkRadioRenderer = React.memo(function ModsLinkRadioRenderer(params: GridRenderCellParams) {
  const api = materialUiXGrid.useGridApiContext();
  const checked = api.current.isRowSelected(params.id);
  const dispatch = useDispatch();
  const onClick = e => {
    const taskDetails = {
      taskId: params?.row?.taskId,
      rootTaskId: params?.row?.rootTaskId,
    };
    dispatch(placementModsAction.setSelectedTaskForMod(!checked ? taskDetails : null));
    e.stopPropagation();
    api.current.selectRow(params.id, !checked, !checked);
  };
  return (
    <>
      <FormControlLabel
        onClick={onClick}
        value={false}
        control={<Radio checked={checked} onClick={onClick} />}
        label=""
        style={{ marginLeft: '0px', marginRight: '0px' }}
      />
    </>
  );
});

export const ModsLinkRadio = (params: GridRenderCellParams) => {
  return <ModsLinkRadioRenderer {...params} />;
};
