import React from 'react';
import { Paper, IconButton, Grid, Avatar } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import styledComponent from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { HorizontalDivider } from '../../Candidate/CandidateProfile/CandidateTabPanel/ProfileTab/Cards/styles';
import { OverflowTooltip } from '../Tooltips/OverflowTooltip';

const useStyles = makeStyles()(() => ({
  root: {
    transform: 'translateY(-30px)',
    marginLeft: 20,
  },
  avatar: {
    height: 44,
    width: 44,
    fontSize: 18,
    fontWeight: 500,
    backgroundColor: '#053E68',
    textTransform: 'uppercase',
  },
  avatarGrid: {
    maxWidth: 60,
  },
  closeButton: {
    borderRadius: 4,
    color: '#fff',
    background: '#006fb9 !important',
    padding: 3,
  },
  closeButtonGrid: {
    marginLeft: 'auto',
    textAlign: 'right',
  },
  container: {
    flexWrap: 'nowrap',
  },
}));

export const MapPopover = props => {
  const { classes } = useStyles();
  const { isAnAggregate = false, isACarouselCard = false } = props;

  return (
    <PaperCard
      id={props.id}
      style={{
        border: props.isHighlighted ? '3px solid #006FB9' : 'none',
        cursor: props.cursor || 'default',
      }}
      classes={{ root: isACarouselCard ? undefined : classes.root }}
      onClick={props.onClick}
    >
      <Grid container direction="column" classes={{ container: classes.container }}>
        <Grid item container spacing={2} direction="row" classes={{ container: classes.container }}>
          <Grid item sm={1} classes={{ root: classes.avatarGrid }}>
            <Avatar variant="rounded" classes={{ root: classes.avatar }}>
              {props.avatarText}
            </Avatar>
          </Grid>
          <Grid item sm={10}>
            <Title>{props.title}</Title>
          </Grid>
          <Grid item sm={1} classes={{ root: classes.closeButtonGrid }}>
            {props.isClosable && (
              <IconButton
                color="primary"
                type="button"
                classes={{ root: classes.closeButton }}
                disableTouchRipple
                disableFocusRipple
                onClick={props.onClose}
                aria-label="popup-close-button"
                size="small">
                <CloseIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <HorizontalDivider />
        <Grid item>
          <RowContainer>
            {props.data.map((item, index) => (
              <tr key={index.toString()}>
                <RowHead>{item.key}</RowHead>
                {props.enableEllipsis ? (
                  <OverflowTooltip
                    value={item.value}
                    contentStyle={{ textAlign: 'end', verticalAlign: 'middle', lineHeight: 2 }}
                  />
                ) : (
                  <RowContent>{item.value}</RowContent>
                )}
              </tr>
            ))}
          </RowContainer>
        </Grid>
        {isAnAggregate && (
          <>
            <HorizontalDivider />
            <Grid item>{props.tail}</Grid>
          </>
        )}
      </Grid>
    </PaperCard>
  );
};

const PaperCard = styledComponent(Paper)`
  position: inherit;
  min-width: max-content;
  display: flex;
  color: #fff;
  border: 1px solid #cccccc;
  box-shadow: 0px 1px 1px #00000029;
  border-radius: 4px;
  padding: 16px;
  white-space: nowrap;
  z-index: 2;
`;

const Title = styledComponent.div`
  color: #52429a;
  font-size: 20px;
  line-height: 44px;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const RowHead = styledComponent.th`
  color: #333333;
  font-size: 14px;
  text-align: left;
  vertical-align: middle;
  font-weight: 500;
  line-height: 2;
`;

const RowContent = styledComponent.td`
  color: #003e68;
  font-size: 14px;
  text-align: end;
  font-weight: 600;
  vertical-align: middle;
  line-height: 2;
`;

const RowContainer = props => (
  <table style={{ width: '100%' }}>
    <tbody style={{ width: '100%' }}>{props.children}</tbody>
  </table>
);
