/* eslint-disable no-const-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable eqeqeq */
/* eslint-disable i18next/no-literal-string */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, useCallback } from 'react';
import { Box, Grid, CircularProgress } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';
import { LayoutGridItem, LayoutGrid } from 'app/layout/LayoutGrid';
import { useDispatch, useSelector } from 'react-redux';
import {
  candidateDetailsSelection,
  CredentialingLookups as CredentialingLookupsSelection,
  CredentialsSelection,
  selectAddUnverifiedCredType,
} from '../../../Profile/CandidateDetails.selector';
import moment from 'moment';
import _ from 'lodash';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { CredentialsAdd } from '../../CredentialsTab/AddCredential/CredentialsAdd';
import { selectUser } from 'oidc/user.selectors';
import { userRoles } from 'oidc/userRoles';
import { candidateDetailActions } from '../../../Profile/CandidateDetails.redux';
import { addCredentialLocal } from '../../CredentialsTab/CredentialsHelpers';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { CredentialsSearchByCategory } from '../CredentialsSearchByCategory';
import { Authorized } from 'oidc/userHelper';
import { CredentialsGridType } from '../../CredentialsTab/CredentialsGrid';
import React from 'react';
import { CredentialsAddHearsay } from '../CredentialsAddHearsay';
import { getSearchFilterOptions } from 'app/services/SharedServices/SharedServices';
import { License } from 'app/models/Candidate/CandidateProfile';
import { LicenseAddHearsay } from '../LicenseAddHearsay';
import { FormProvider, useForm } from 'react-hook-form';
import { ff_candidate_container_ui_v2, ff_WorkExperience_Enhancement } from 'app/constants/FeatureFlags/Candidate/Keys';
import { useDecision } from '@optimizely/react-sdk';
import {
  CredentialName,
  HearsayFields,
  qualificationCardPermissions,
  updateDependency,
  updateLicensesTypeState,
} from './helper';
import QualificationUI from './Card Design/QualificationUI';
import QualificationUIV2 from './Card Design/QualificationUIV2';
import { useStyles } from './Card Design/utils';

const defaultValues = {
  pHearsayLicense: null,
  pState: null,
  pHearsayCertification: null,
  pLicenseNumber: null,
  pExpirationDate: null,
  pIssuedBy: null,
};

export const QualificationsCard = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const licensesOptions = useRef<any[]>();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const lookupselection = useSelector(CredentialingLookupsSelection);
  const credentials = useSelector(CredentialsSelection);
  const addUnverifiedCredType = useSelector(selectAddUnverifiedCredType);
  const { classes } = useStyles();
  const [licenses, setLicenses] = useState<any>([]);
  const [certifications, setCertifications] = useState<any>([]);
  const [addLicense, setAddLicense] = useState<boolean>(false);
  const [addLicenseProcessStep, setAddLicenseProcessStep] = useState<number>(0);
  const [fluStatusData, setFluStatusData] = useState<any>(null);
  const [covidStatusData, setCovidStatusData] = useState<any>(null);
  const [haveAddPermission, setHaveAddPermission] = useState(false);
  const [isCredAnalyst, setisCredAnalyst] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [candidateLicenses, setCandidateLicenses] = useState<License[]>([]);
  const [saveHearsayDisabled, setSaveHearsayDisabled] = useState<boolean>(true);
  const [selectedLicenseMetaData, setSelectedLicenseMetaData] = useState<any>(null);
  const [selectedHearsayCertMetaData, setSelectedHearsayCertMetaData] = useState<any>(null);
  const [candidateContainerUIV2] = useDecision(ff_candidate_container_ui_v2);
  const [hasWorkExperienceTab] = useDecision(ff_WorkExperience_Enhancement);
  const formMethods = useForm({ mode: 'all', defaultValues: defaultValues });
  const {
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { dirtyFields, errors, touched },
  } = formMethods;

  const user = useSelector(selectUser);
  const [isLicenseHovering, setIsLicenseHovering] = useState(null);
  const [isCertificationHovering, setIsCertificationHovering] = useState(null);
  const handleLicenseMouseOver = (event, item: any) => {
    setIsLicenseHovering(item);
  };

  const handleLicenseMouseOut = () => {
    setIsLicenseHovering(null);
  };
  const handleCertificationMouseOver = (event, item: any) => {
    setIsCertificationHovering(item);
  };

  const handleCertificationMouseOut = () => {
    setIsCertificationHovering(null);
  };

  useEffect(() => {
    updateDependency({ candidateDetails, setLicenses, setCertifications, setFluStatusData, setCovidStatusData });
  }, [
    candidateDetails?.credentials?.licenses,
    candidateDetails?.credentials?.certifications,
    candidateDetails?.credentials?.employeeHealth,
    candidateDetails?.covidVaccinationstatus,
  ]);

  const licensTypeCallback = useCallback(
    async (licenseTypes: any[]) => {
      if (
        candidateDetails?.licenses &&
        candidateDetails.licenses?.length > 0 &&
        licenseTypes &&
        licenseTypes.length > 0
      )
        updateLicensesTypeState(licenseTypes, candidateDetails, setCandidateLicenses);
      else {
        licenseTypes = (await getSearchFilterOptions('licenseTypes')) as any[];
        updateLicensesTypeState(licenseTypes, candidateDetails, setCandidateLicenses);
      }
    },
    [candidateDetails?.licenses],
  );

  useEffect(() => {
    licensTypeCallback(licensesOptions.current);
  }, [licensesOptions.current, candidateDetails?.licenses]);

  useEffect(() => {
    setHaveAddPermission(Authorized(qualificationCardPermissions, user.userInfo));
    setisCredAnalyst(
      Authorized(
        [userRoles.credentialing, userRoles.credentialing_Leadership, userRoles.credentialing_TeamMember],
        user.userInfo,
      ),
    );
  }, [user]);

  const changeAddLicenseProcessStep = data => {
    if (data) {
      setAddLicenseProcessStep(1);
      setSelectedLicenseMetaData(data);
    }
  };

  const onChangeHearsayData = (name: string, data: any) => {
    if (addUnverifiedCredType === CredentialName.HearsayCertification) {
      switch (name) {
        case HearsayFields.Certification:
          setValue('pHearsayCertification', data);
          setSaveHearsayDisabled(false);
          return;
        case HearsayFields.IssuedBy:
          setValue('pIssuedBy', data);
          if (getValues('pHearsayCertification')) {
            setSaveHearsayDisabled(false);
          } else setSaveHearsayDisabled(true);
          return;
        case HearsayFields.ExpirationDate:
          setValue('pExpirationDate', data);
          if (getValues('pHearsayCertification')) {
            setSaveHearsayDisabled(false);
          } else setSaveHearsayDisabled(true);
          return;
        default:
          return;
      }
    } else if (addUnverifiedCredType === CredentialName.HearsayLicense) {
      switch (name) {
        case HearsayFields.State:
          setValue('pState', data);
          if (data && getValues('pHearsayLicense')) {
            setSaveHearsayDisabled(false);
          } else setSaveHearsayDisabled(true);
          return;
        case HearsayFields.License:
          setValue('pHearsayLicense', data);
          if (data && getValues('pState')) {
            setSaveHearsayDisabled(false);
          } else setSaveHearsayDisabled(true);
          return;
        case HearsayFields.LicenseNumber:
          setValue('pLicenseNumber', data);
          if (getValues('pState') && getValues('pHearsayLicense')) {
            setSaveHearsayDisabled(false);
          } else setSaveHearsayDisabled(true);
          return;
        case HearsayFields.ExpirationDate:
          setValue('pExpirationDate', data);
          if (getValues('pState') && getValues('pHearsayLicense')) {
            setSaveHearsayDisabled(false);
          } else setSaveHearsayDisabled(true);
          return;
        default:
          return;
      }
    }
  };

  const handleAddPopupClose = (search, isSaveActive = false) => {
    setAddLicense(isSaveActive ? false : true);
    setAddLicenseProcessStep(0);
    setSelectedHearsayCertMetaData(undefined);
    setSelectedLicenseMetaData(undefined);
    setSaveHearsayDisabled(true);
    dispatch(candidateDetailActions.setIsAddLicense(false));
  };

  const handleHearsayDialogClose = () => {
    if (!_.isEmpty(errors) || !_.isEmpty(dirtyFields) || !_.isEmpty(touched)) {
      dispatch(
        globalActions.setSnackBar({
          message: t('candidate.virtualInterviewTabLabels.cancelMessage'),
          severity: 'success',
          actions: [
            {
              action: () => handleAddPopupClose(null, true),
              closeSnackbar: true,
              text: t('Yes'),
            },
            {
              closeSnackbar: true,
              text: t('No'),
            },
          ],
        }),
      );
    } else {
      handleAddPopupClose(null, true);
    }
  };

  const onErrorHearsay = () => {};

  const validateHearsayLicenseForm = data => {
    var isLicenseFormValid = true;
    if (data.pLicenseNumber?.length > 255) {
      setError('pLicenseNumber', {
        type: 'maxLength',
        message: 'Maximum Character Limit Reached 255',
      });
      isLicenseFormValid = false;
    }
    return isLicenseFormValid;
  };

  const handleSaveHearsay = async data => {
    if (addUnverifiedCredType === CredentialName.HearsayCertification) {
      setIsLoading(true);
      const payload = {
        candidateId: candidateDetails.travelerId,
        brandId: candidateDetails.brandId,
        data: [
          {
            value: parseInt(data.pHearsayCertification?.value),
            name: data.pHearsayCertification?.name.split(' - ')?.[0] || '',
            expirationDate: data.pExpirationDate ? moment(data.pExpirationDate).format('yyyy-MM-DD') : null,
            requestOptionTypeId: data.pIssuedBy && data.pIssuedBy.value ? data.pIssuedBy.value : null,
            issuedBy: data.pIssuedBy && data.pIssuedBy.description ? data.pIssuedBy.description : null,
            currentEmployeeId: user.userInfo?.employeeId || 8410,
            currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
            travelerHearsayCertificationId: !selectedHearsayCertMetaData
              ? null
              : selectedHearsayCertMetaData.travelerHearsayCertificationId
              ? selectedHearsayCertMetaData.travelerHearsayCertificationId
              : 0,
          },
        ],
      };
      dispatch(candidateDetailActions.postUnverifiedHearsayCertification(payload));
      handleAddPopupClose(null, true);
      setIsLoading(false);
    } else if (addUnverifiedCredType === CredentialName.HearsayLicense) {
      if (!validateHearsayLicenseForm(data)) return;
      setIsLoading(true);
      const licenseLookups = lookupselection.filter(item => item.category === 'Licenses');
      const payload = {
        travelerId: candidateDetails.travelerId,
        companyId: candidateDetails.brandId,
        requirementTypeID:
          licenseLookups?.find(item => item?.abbreviation === data.pHearsayLicense?.value)?.requirementTypeID || 0,
        stateProvinceID: data.pState?.ID || '',
        stateProvince: data.pState?.Description || '',
        expirationDate: data.pExpirationDate ? moment(data.pExpirationDate).format('yyyy-MM-DD') : null,
        licenseNumber: data.pLicenseNumber ? data.pLicenseNumber : null,
        travelerHearsayLicensureID: !selectedLicenseMetaData
          ? 0
          : selectedLicenseMetaData.travelerHearsayLicensureID
          ? selectedLicenseMetaData.travelerHearsayLicensureID
          : 0,
        currentEmployeeId: user.userInfo?.employeeId || 8410,
        currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
        requirementType: data.pHearsayLicense?.name || '',
      };
      dispatch(candidateDetailActions.postUnverifiedHearsayLicense(payload));
      handleAddPopupClose(null, true);
      setIsLoading(false);
    }
  };

  const onAddCredential = (credential, selectedOption) => {
    const newCredentials = addCredentialLocal(credential, selectedOption.gridType, _.cloneDeep(credentials));
    if (newCredentials !== null) dispatch(candidateDetailActions.setCredentials(newCredentials));
    setAddLicense(false);
    setAddLicenseProcessStep(0);
    dispatch(
      globalActions.setSnackBar({
        message:
          addUnverifiedCredType == CredentialName.Licenses
            ? t('candidate.qualificationsCard.addLicenseSuccess')
            : t('candidate.qualificationsCard.addCertificationSuccess'),
        severity: 'success',
      }),
    );
  };

  const showAllLicenses = () => {
    let optedTab = 2;
    if (hasWorkExperienceTab?.enabled) {
      optedTab = optedTab + 1;
    }
    window.history.pushState(null, '', `${window.location.pathname}?tab=${optedTab}`);
    props.navigate(optedTab, CredentialsGridType?.licensure);
  };

  const showAllCertifications = () => {
    let optedTab = 2;
    if (hasWorkExperienceTab?.enabled) {
      optedTab = optedTab + 1;
    }
    window.history.pushState(null, '', `${window.location.pathname}?tab=2`);
    props.navigate(optedTab, CredentialsGridType?.certificationsParent);
  };

  const deleteHearsayLicense = async () => {
    const payload = {
      travelerId: candidateDetails.travelerId,
      companyId: candidateDetails.brandId,
      travelerHearsayLicensureId: selectedLicenseMetaData.travelerHearsayLicensureID,
      currentEmployeeId: user.userInfo?.employeeId || 0,
      currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
    };
    dispatch(candidateDetailActions.deleteUnverifiedHearsayLicense(payload));
    handleAddPopupClose(null, true);
  };

  const deleteHearsayCertification = async () => {
    const payload = {
      travelerId: candidateDetails.travelerId,
      companyId: candidateDetails.brandId,
      hearsayCertificationId: selectedHearsayCertMetaData.id,
      travelerHearsayCertificationId: selectedHearsayCertMetaData.travelerHearsayCertificationId,
      currentEmployeeId: user.userInfo?.employeeId || 0,
      currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
    };
    dispatch(candidateDetailActions.deleteUnverifiedHearsayCertification(payload));
    handleAddPopupClose(null, true);
  };

  return (
    <LayoutGrid className={classes.grid}>
      {isLoading && <CircularProgress style={{ width: '12px', height: '12px' }} />}
      <LayoutGridItem style={{ width: '100%' }}>
        <Box className={classes.container}>
          {candidateContainerUIV2?.enabled ? (
            <QualificationUIV2
              showAllLicenses={showAllLicenses}
              haveAddPermission={haveAddPermission}
              isCredAnalyst={isCredAnalyst}
              setAddLicense={setAddLicense}
              licenses={licenses}
              candidateLicenses={candidateLicenses}
              isLicenseHovering={isLicenseHovering}
              setSelectedLicenseMetaData={setSelectedLicenseMetaData}
              handleLicenseMouseOut={handleLicenseMouseOut}
              showAllCertifications={showAllCertifications}
              isCertificationHovering={isCertificationHovering}
              setSelectedHearsayCertMetaData={setSelectedHearsayCertMetaData}
              handleCertificationMouseOut={handleCertificationMouseOut}
              covidStatusData={covidStatusData}
              fluStatusData={fluStatusData}
              handleLicenseMouseOver={handleLicenseMouseOver}
              certifications={certifications}
              handleCertificationMouseOver={handleCertificationMouseOver}
            />
          ) : (
            <QualificationUI
              showAllLicenses={showAllLicenses}
              haveAddPermission={haveAddPermission}
              isCredAnalyst={isCredAnalyst}
              setAddLicense={setAddLicense}
              licenses={licenses}
              candidateLicenses={candidateLicenses}
              isLicenseHovering={isLicenseHovering}
              setSelectedLicenseMetaData={setSelectedLicenseMetaData}
              handleLicenseMouseOut={handleLicenseMouseOut}
              showAllCertifications={showAllCertifications}
              isCertificationHovering={isCertificationHovering}
              setSelectedHearsayCertMetaData={setSelectedHearsayCertMetaData}
              handleCertificationMouseOut={handleCertificationMouseOut}
              covidStatusData={covidStatusData}
              fluStatusData={fluStatusData}
              handleLicenseMouseOver={handleLicenseMouseOver}
              certifications={certifications}
              handleCertificationMouseOver={handleCertificationMouseOver}
            />
          )}
        </Box>
      </LayoutGridItem>
      <GenericDialog
        variant={'blue'}
        fullWidth
        classes={{
          paper: classes.dialogContainer,
        }}
        open={
          addLicense &&
          addLicenseProcessStep == 0 &&
          addUnverifiedCredType !== CredentialName.HearsayCertification &&
          addUnverifiedCredType !== CredentialName.HearsayLicense
        }
        disablePortal
        disableEscapeKeyDown
        dialogTitleProps={{
          text:
            addUnverifiedCredType == CredentialName.Licenses
              ? t('candidate.qualificationsCard.addLicenseTitle')
              : t('candidate.qualificationsCard.addCertificationTitle'),
          closeButton: true,
        }}
        onClose={() => handleAddPopupClose(null, true)}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CredentialsSearchByCategory changeAddLicenseProcessStep={changeAddLicenseProcessStep} />
          </Grid>
        </Grid>
      </GenericDialog>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleSaveHearsay, onErrorHearsay)}>
          <GenericDialog
            fullWidth
            variant={'blue'}
            classes={{
              paper: classes.dialogContainerHearsay,
            }}
            open={
              addLicense &&
              (addUnverifiedCredType === CredentialName.HearsayCertification ||
                addUnverifiedCredType === CredentialName.HearsayLicense)
            }
            disablePortal
            disableEscapeKeyDown
            dialogTitleProps={{
              text:
                addUnverifiedCredType === CredentialName.HearsayCertification
                  ? selectedHearsayCertMetaData
                    ? t('candidate.qualificationsCard.editCertificationTitle')
                    : t('candidate.qualificationsCard.addCertificationTitle')
                  : selectedLicenseMetaData
                  ? t('candidate.qualificationsCard.editLicenseTitle')
                  : t('candidate.qualificationsCard.addLicenseTitle'),

              closeButton: true,
              expandable: true,
            }}
            dialogActions={[
              {
                text: 'Cancel',
                variant: 'contained',
                color: 'tertiary',
                onClick: () => handleHearsayDialogClose(),
              },
              {
                text: 'Save',
                variant: 'contained',
                color: 'primary',
                type: 'submit',
                disabled: saveHearsayDisabled,
              },
            ]}
            onClose={() => handleHearsayDialogClose()}
          >
            <Grid container spacing={3} style={{ margin: 0, width: '100%' }}>
              <Grid item xs={12} className={classes.dialogContainerGrid}>
                {addUnverifiedCredType === CredentialName.HearsayCertification ? (
                  <CredentialsAddHearsay
                    onChangeHearsayCertification={onChangeHearsayData}
                    credentialingAllLookups={lookupselection}
                    selectedHearsayCertMetaData={selectedHearsayCertMetaData}
                    deleteHearsayCertification={deleteHearsayCertification}
                  />
                ) : (
                  <LicenseAddHearsay
                    onChangeHearsayLicense={onChangeHearsayData}
                    selectedHearsayLicenseMetaData={selectedLicenseMetaData}
                    deleteHearsayLicense={deleteHearsayLicense}
                  />
                )}
              </Grid>
            </Grid>
          </GenericDialog>
        </form>
      </FormProvider>
      {addLicenseProcessStep == 1 && (
        <CredentialsAdd
          openAdd={true}
          handlePopupOpen={props.handleAddPopupOpen}
          handlePopupClose={handleAddPopupClose}
          credentials={credentials}
          credentialsGridType={0}
          credentialingLookups={[]}
          credentialingAllLookups={lookupselection}
          onAddCredential={onAddCredential}
          isAddLicense={addLicense}
          selectedLicenseMetaData={selectedLicenseMetaData}
        />
      )}
    </LayoutGrid>
  );
};
