import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { QualificationType } from 'app/models/Unit/CreateUnit';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import {
  PreviewContent,
  PreviewContentTitle,
  ContentTitle,
  ContentDivider,
} from '../../../OrderCreationForm/DefineUnit/UnitPreview/UnitPreviewDesign';
import { LicenseRequirementLabel } from '@AMIEWEB/Order/OrderCreationForm/DefineUnit/UnitPreview/Common/LicenseRequirementLabel';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrderRequirementTypes } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { orderCreationActions } from '@AMIEWEB/Order/Store/OrderCreation.actions';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { orderCreationReducer, orderCreationSliceKey } from '@AMIEWEB/Order/Store/OrderCreation.redux';
import { orderCreationSaga } from '@AMIEWEB/Order/Store/OrderCreation.saga';
import { CertificationRequirementLabel } from '@AMIEWEB/Order/OrderCreationForm/DefineUnit/UnitPreview/Common/CertificationRequirementLabel';
import { getDynamicSortedData } from 'app/helpers/arrayHelpers';

interface Props {
  data: {
    state: string | null;
    qualification: QualificationType | null;
  };
  disableEdit?: boolean;
  handleEditOrder?: () => void;
}

const useStyles = makeStyles()({
  container: {
    '&:hover': {
      '& [data-id="edit-icon"]': {
        opacity: 1,
        transition: 'opacity 0.25s',
      },
    },
  },
  editItem: {
    opacity: 0,
    transition: 'opacity 0.25s',
  },
  editIcon: {
    height: '20px',
    width: '20px',
    cursor: 'pointer',
    color: '#8E9195',
  },
  title: {
    paddingTop: '12px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

export const OrderDetailsQualification = (props: Props) => {
  const dispatch = useDispatch();
  useInjectReducer({ key: orderCreationSliceKey, reducer: orderCreationReducer });
  useInjectSaga({ key: orderCreationSliceKey, saga: orderCreationSaga });
  const { qualification, state } = props.data;
  const { disableEdit, handleEditOrder } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { certifications } = useSelector(selectOrderRequirementTypes);
  const [certificationValues, setCertificationValues] = useState<any[]>([]);
  const [subCertificationOptions, subCetCertificationOptions] = useState<any[]>([]);

  const removeStaleCertifications = (list: any[]) => {
    const validCertifications = [];
    list?.forEach(item => {
      const validCert = certifications?.find(x => x.requirementTypeID === item.requirementTypeId);
      if (validCert) {
        validCertifications.push(item);
      }
    });
    const sortCertifications = getDynamicSortedData(validCertifications, 'abbreviation');
    setCertificationValues(sortCertifications);
  };

  const parseCertifications = () => {
    const certs = [];
    const subCerts = [];
    certifications?.map(rt => {
      rt.category ? certs.push(rt) : subCerts.push(rt);
    });
    subCetCertificationOptions(subCerts);
  };

  useEffect(() => {
    if (certifications?.length === 0 || !certifications) {
      dispatch(orderCreationActions.requestOrderCreationRequirementTypesDetailsAction());
    }
  }, []);

  useEffect(() => {
    if (
      Array.isArray(qualification?.certifications) &&
      qualification.certifications.length > 0 &&
      Array.isArray(certifications) &&
      certifications.length > 0
    ) {
      removeStaleCertifications(qualification.certifications);
      parseCertifications();
    }
  }, [certifications, qualification?.certifications]);

  return (
    <>
      <Grid item xs={12}>
        <PreviewContent variant="outlined" elevation={0}>
          <Grid className={classes.container}>
            <Grid item>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <PreviewContentTitle>{t('order.createUnit.qualifications.qualificationsTitle')}</PreviewContentTitle>
                </Grid>
                <Grid item className={classes.editItem} data-id="edit-icon">
                  {!disableEdit ? <EditIcon className={classes.editIcon} onClick={handleEditOrder} /> : <></>}
                </Grid>
              </Grid>
            </Grid>
            {state && qualification?.licenses?.length > 0 && (
              <Grid item>
                <ContentTitle className={classes.title}>
                  {`${t('order.createUnit.qualifications.licenses')} (${qualification.licenses.length})`}
                </ContentTitle>
                <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {qualification.licenses.map((license, key) => {
                    return (
                      <Grid item direction="column" key={key} style={{ width: 'calc(50% - 12px)' }}>
                        <LicenseRequirementLabel
                          isRequired={license.isRequired}
                          isDocumentRequired={
                            license.documentAttachmentRequired ? license.documentAttachmentRequired : false
                          }
                          contentValue={{
                            section1: license.licenseAbbreviation,
                            section2: license.isMultiState ? 'Compact' : license.stateAbbreviation,
                            section3: license.licenseDescription,
                            section4: license.state,
                          }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
            {qualification?.licenses?.length > 0 && certificationValues?.length > 0 && (
              <Grid item style={{ paddingTop: '12px' }}>
                <ContentDivider style={{ width: '100%', padding: 0, margin: 0 }} />
              </Grid>
            )}
            {state && certificationValues?.length > 0 && (
              <Grid item>
                <ContentTitle className={classes.title}>
                  {`${t('order.createUnit.qualifications.certifications')} (${certificationValues.length})`}
                </ContentTitle>
                <Grid container style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                  {certificationValues.map((certificate, key) => {
                    const isSubCert = subCertificationOptions?.some(
                      item => item?.requirementTypeID === certificate?.requirementTypeId,
                    );
                    return (
                      <Grid item direction="column" key={key} style={{ width: 'calc(50% - 12px)' }}>
                        <CertificationRequirementLabel
                          isRequired={certificate?.isRequired}
                          isDocumentRequired={
                            certificate?.documentAttachmentRequired ? certificate?.documentAttachmentRequired : false
                          }
                          contentValue={{
                            section1: certificate?.abbreviation,
                            section2: isSubCert,
                          }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
          </Grid>
        </PreviewContent>
      </Grid>
    </>
  );
};
