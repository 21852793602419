import { Grid, MenuItem, MenuList, Typography } from 'amn-ui-core';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { StatusChip } from '../../CustomComponents/StatusChip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CustomTooltip } from '@AMIEWEB/Common';
import { RowContainer } from '@AMIEWEB/Placement/PlacementDetails/styles';
import { selectInterviewInfo } from 'store/redux-store/placement-summary/selectors';
import { useSelector } from 'react-redux';
import { VirtualInterviewHistory } from './VirtualInterviewHistory';
import { missingField } from 'app/constants';

const defaultHistoryProps = {
  rows: [],
  open: false,
  inviteId: undefined,
  interviewDate: undefined,
};

export interface IVICardView {
  skillset;
  reason;
  rating;
  status;
  interviewDateValue;
  sentDateValue;
  lastUpdatedValue;
  inviteId;
  history;
  chipStyle;
}

export const InterviewSpecs = () => {
  const { t } = useTranslation();
  const {
    virtualInterview: { candidate: interviews },
  } = useSelector(selectInterviewInfo);
  const [historyModalProps, setHistoryModalProps] = useState(defaultHistoryProps);

  return !interviews.length ? (
    <Typography variant="body1">
      {t('placement.profile.summary.interview.virtualInterviewContainer.interviewSpecs.skillNotMatching')}
    </Typography>
  ) : (
    <Grid container spacing={2}>
      {interviews.map(({ cardView }, index) => (
        <Grid item key={index.toString()} xs={12}>
          <ScheduleItem {...cardView} setHistoryModalProps={setHistoryModalProps} />
        </Grid>
      ))}
      <VirtualInterviewHistory {...historyModalProps} onClose={() => setHistoryModalProps(defaultHistoryProps)} />
    </Grid>
  );
};

const useItemStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    gap: 24,
  },
  titleCell: {
    textAlign: 'start',
    fontWeight: 500,
  },
  actionColumn: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    height: 36,
    gap: 12,
  },
  headCell: {
    fontSize: 12,
    fontWeight: 450,
  },
  cardHeadCell: {
    minWidth: 125,
  },
  valueCell: {
    fontSize: 14,
  },
  tooltip: {
    padding: '12px 0',
  },
}));

const ScheduleItem: React.FC<IVICardView & { setHistoryModalProps: Function }> = ({
  skillset: title,
  rating,
  status,
  inviteId,
  reason,
  interviewDateValue,
  sentDateValue,
  lastUpdatedValue,
  history,
  setHistoryModalProps,
  chipStyle,
}) => {
  const { classes } = useItemStyles();
  const { t } = useTranslation();

  const { tooltipContent } = useMemo(() => {
    return {
      tooltipContent: (
        <RowContainer>
          <tr>
            <td className={classes.headCell}>
              {t('placement.profile.summary.interview.virtualInterviewContainer.interviewSpecs.reason')}
            </td>
            <td className={classes.valueCell}>{reason}</td>
          </tr>
          <tr>
            <td className={classes.headCell}>
              {t('placement.profile.summary.interview.virtualInterviewContainer.interviewSpecs.interviewDate')}
            </td>
            <td className={classes.valueCell}>{interviewDateValue}</td>
          </tr>
          <tr>
            <td className={classes.headCell}>
              {t('placement.profile.summary.interview.virtualInterviewContainer.interviewSpecs.sentDate')}
            </td>
            <td className={classes.valueCell}>{sentDateValue}</td>
          </tr>
          <tr>
            <td className={classes.headCell}>
              {t('placement.profile.summary.interview.virtualInterviewContainer.interviewSpecs.inviteId')}
            </td>
            <td className={classes.valueCell}>{inviteId}</td>
          </tr>
        </RowContainer>
      ),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reason, interviewDateValue, sentDateValue, lastUpdatedValue]);

  return (
    <div className={classes.container}>
      <CustomTooltip isStandardContent standardMargin tooltipContent={tooltipContent} placement="bottom">
        <table>
          <tbody>
            <tr>
              <td colSpan={2} className={classes.titleCell}>
                {title}
              </td>
            </tr>
            <tr>
              <td className={`${classes.headCell} ${classes.cardHeadCell}`}>
                {t('placement.profile.summary.interview.virtualInterviewContainer.interviewSpecs.lastUpdated')}
              </td>
              <td className={classes.valueCell}>{lastUpdatedValue}</td>
            </tr>
            <tr>
              <td className={`${classes.headCell} ${classes.cardHeadCell}`}>
                {t('placement.profile.summary.interview.virtualInterviewContainer.interviewSpecs.rating')}
              </td>
              <td className={classes.valueCell}>{rating}</td>
            </tr>
          </tbody>
        </table>
      </CustomTooltip>
      <div className={classes.actionColumn}>
        {status === missingField ? missingField : <StatusChip status={status} chipStyle={chipStyle} />}
        <CustomTooltip
          standardMargin
          classes={{ tooltip: classes.tooltip }}
          contentStyle={{ alignSelf: 'center' }}
          tooltipContent={
            <TooltipContent
              onClick={() =>
                setHistoryModalProps({ open: true, rows: history, inviteId, interviewDate: interviewDateValue })
              }
            >
              {t('placement.profile.summary.interview.virtualInterviewContainer.interviewSpecs.history.viewHistory')}
            </TooltipContent>
          }
          placement={'bottom'}
        >
          <MoreVertIcon />
        </CustomTooltip>
      </div>
    </div>
  );
};

const TooltipContent = ({ onClick, ...props }) => {
  return (
    <MenuList disablePadding>
      <MenuItem dense onClick={onClick}>
        {props.children}
      </MenuItem>
    </MenuList>
  );
};
