import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { Typography } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  dialog: {
    width: '380px',
    height: '192px',
    bgcolor: 'red',
    boxShadow: '20px',
    p: 2,
  },
  dialogContent: {
    padding: '0px',
    overflow: 'hidden',
  },
  modalText: {
    margin: '0px 20px',
    fontSize: '14px',
    textAllign: 'left',
  },
}));
export interface IPinnedLimitDialogProps {
  dialogeOpen: boolean;
  handleClose?: () => void;
}
export const PinnedLimitDialog = ({
  dialogeOpen,
  handleClose,
}: IPinnedLimitDialogProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <GenericDialog
        open={dialogeOpen}
        classes={{ paper: classes.dialog }}
        onClose={handleClose}
        variant="white"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        dialogTitleProps={{
          text: "You've reached the limit",
          closeButton: false,
        }}
        dialogContentProps={{
          className: classes.dialogContent,
        }}
        dialogActions={[
          {
            text: 'Got it',
            variant: 'contained',
            color: 'primary',
            onClick: handleClose,
          },
        ]}
      >
        <Typography className={classes.modalText}>
          {t("To pin more messages, you'll need to unpin one first")}
        </Typography>
      </GenericDialog>
    </>
  );
};
