import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { CircularProgress, Grid, Skeleton } from 'amn-ui-core';
import { TaskFormBanner } from 'app/components/Common/Banner/TasksFormBanner';
import { taskDetailsActions } from '../store/Tasks.redux';
import { useDispatch, useSelector } from 'react-redux';
import { selectTaskComments, selectTaskErrors } from '../store/Tasks.selectors';
import { SubTask } from './SubTask';
import { TaskDetailRightSkeleton } from './TaskDetailsSkeleton';

export const TaskComments = ({
  rootTaskId,
  classes,
  setReplyRecipients,
  setSubtaskModalOpen,
  setComment,
  statusChanged,
  setIsPopperOpen,
  setSubTaskName,
  setEnableTaskName,
  setTask
}: {
  rootTaskId?: number;
  classes: any;
  setReplyRecipients: (e) => void;
  setSubtaskModalOpen: (e) => void;
  setComment: (e) => void;
  statusChanged?: boolean;
  setIsPopperOpen?: (e) => void;
  setSubTaskName?: (e) => void;
  setEnableTaskName?: (e) => void;
  setTask?: (e) => void
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const commentList = useSelector(selectTaskComments);
  const taskErrors = useSelector(selectTaskErrors);
  const [showError, setShowError] = useState(false);

  const { promiseInProgress: getCommentsLoading } = usePromiseTracker({ area: 'get-task-comments', delay: 0 });

  const fetchComments = () => {
    if (rootTaskId) {
      dispatch(taskDetailsActions.getTaskCommentsAction(rootTaskId));
    }
  };

  useEffect(() => {
    fetchComments();
    return () => {
      dispatch(taskDetailsActions.setErrorObject({ GET_TASK_COMMENTS: false }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootTaskId]);


  useEffect(() => {
    setShowError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (taskErrors?.SAVE_TASK_COMMENT) {
      setShowError(true);
    } else {
      setShowError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskErrors]);

  return rootTaskId ? (
    <form data-testid="task-comments-container" className={classes.subtaskContainer} style={{ flexGrow: 1 }}>
      <div className={classes.subtaskList}>
        {showError && (
          <Grid item xs={12} style={{ marginBottom: '12px' }}>
            <TaskFormBanner message={t('notification.taskDetails.apiCallError')} />
          </Grid>
        )}
        <Grid container justifyContent="space-between" style={{ marginBottom: '12px' }}>
          <Grid item xs={10}>
            <div
              style={{
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '16px',
                marginTop: '6px',
                display: 'flex',
                flexWrap: 'nowrap',
              }}
              data-testid="task-comments-label"
            >
              {`${t('notification.taskDetails.subTaskLabel')} ${
                commentList?.length > 0 ? ` (${commentList?.length})` : ''
              }`}
            </div>
          </Grid>
        </Grid>
        {getCommentsLoading && (
         <TaskDetailRightSkeleton isSubTask={true} />
        )}
        <SubTask
          getCommentsLoading={getCommentsLoading}
          commentList={commentList}
          setReplyRecipients={setReplyRecipients}
          setSubtaskModalOpen={setSubtaskModalOpen}
          setComment={setComment}
          setIsPopperOpen={setIsPopperOpen}
          setSubTaskName={setSubTaskName}
          setEnableTaskName={setEnableTaskName}
          setTask = {setTask}
        />
      </div>
    </form>
  ) : null;
};
