import { Typography } from 'amn-ui-core';
import { useDispatch} from 'react-redux';
import React from 'react';
import { globalSearchActions } from 'store/redux-store/global-search/slice';
import { globalActions } from 'app/ApplicationRoot/Global.redux';

export const DispatchComponent = ({ displayValue, ...props }) => {
  const dispatch = useDispatch();
  const stopImmediatePropagation = event => {
    if (event.ctrlKey || event.metaKey) {
      // Allow default behavior (opening in a new tab)
      return;
    }
    dispatch(globalActions.resetAllRecentHistoryDrawers());
    dispatch(globalSearchActions.setDrawerData({ open: true, data: { displayValue, ...props } }));
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <div>
      <Typography
        onClick={e => {  
          stopImmediatePropagation(e);
        }}
        style={{ fontSize: 'inherit' }}
        >
        {displayValue}
      </Typography>
    </div>
  );
};
