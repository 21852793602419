import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { AndOrOperator } from 'app/enums/Common';
import { useFormContext } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ClickAwayListener, Paper, List, ListItemButton, ListItemText } from 'amn-ui-core';
import { CustomTooltip } from '@AMIEWEB/Common';
import { useAndOrButtonStyles } from './Experience.styles';
import RightArrow from 'app/assets/images/IconImages/RightArrow.svg';
import { useDecision } from '@optimizely/react-sdk';
import { ff_unit_ui_duplicate_skill_set } from 'app/constants/FeatureFlags/Facility/Keys';

export const AndOrSwitchButton = ({ value, onChange, isDisabled = false }) => {
  const { t } = useTranslation();
  const { classes } = useAndOrButtonStyles();
  const [anchorEl, setAnchorEl] = useState(null);  
  const isAnd = `${value}` === AndOrOperator.And;
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = operationValue => {
    handleClose();
    onChange(operationValue);
  };

  return (
    <>
      <div className={classes.dividerSwitch} />
      <Button
        aria-describedby={anchorEl ? 'add-experience-menu-popover' : undefined}
        variant="contained"
        onClick={handleClick}
        disabled ={isDisabled}
        className={classes.andOrButton}
      >
        {isAnd
          ? t('facility.units.unitDetails.unitRequirements.and')
          : t('facility.units.unitDetails.unitRequirements.or')}
        <div className={`expandIcon ${classes.expandInitial} ${anchorEl ? classes.expandFinal : ''}`}>
          <ExpandMoreIcon />
        </div>
      </Button>
      <div className={classes.dividerSwitch} />
      <Popover
        id={anchorEl ? 'add-experience-menu-popover' : undefined}
        open={anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={classes.popoverOperation}
      >
        <div>
          <MenuItem
            onClick={() => handleOptionClick(isAnd ? AndOrOperator.Or : AndOrOperator.And)}
            className={classes.menuOption}
          >
            {isAnd
              ? t('facility.units.unitDetails.unitRequirements.or')
              : t('facility.units.unitDetails.unitRequirements.and')}
          </MenuItem>
        </div>
      </Popover>
    </>
  );
};

export const AndOrPopUp = ({
  handleAdd,
  handleDuplicate,
  value,
  groupIndex,
  skillSetIndex,
  numberOfFilteredSpeciality,
}: {
  handleAdd: () => void;
  handleDuplicate: (skillSetId: number, isAnd: boolean) => void;
  value: any;
  groupIndex: number;
  skillSetIndex: number;
  numberOfFilteredSpeciality: number;
}) => {
  const { t } = useTranslation();
  const { classes } = useAndOrButtonStyles();
  const { getValues, setValue, register, watch } = useFormContext();
  const isAnd = `${value}` === AndOrOperator.And;
  const [anchorEl, setAnchorEl] = useState(null);  
  const [andOptionMenuAncorEl, setAndOptionMenuAncorEl] = React.useState<HTMLButtonElement | null>(null);  
  const [orOptionMenuAncorEl, setOrOptionMenuAncorEl] = React.useState<HTMLButtonElement | null>(null);  
  const [popoverHeight, setPopoverHeight] = useState(0);  
  const [enableDuplicateSkillSet] = useDecision(ff_unit_ui_duplicate_skill_set);  
  const isDisabled = !(
    (getValues(`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].skillSet.discipline`) &&
      getValues(`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].skillSet.specialty`)) ||
    (getValues(`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].skillSet.discipline`) &&
      numberOfFilteredSpeciality === 0)
  );
  const skillsets = watch(`skillSetGroup.groups[${groupIndex}].skillsets`);

  const getAvailableHeightForPopOver = React.useCallback((isAndOrOperation) => {
    if (isAndOrOperation) {
      setPopoverHeight(
        window.innerHeight - document.getElementById('andDuplicateSkillSetItem').getBoundingClientRect().top - 50,
      );
    }
    else {
      setPopoverHeight(
        window.innerHeight - document.getElementById('orDuplicateSkillSetItem').getBoundingClientRect().top - 50,
      );
    }
  }, []);

  const handleClick = event => {
    if (!isDisabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClickDuplicate = (operationValue, skillSetId) => {
    const isOperationValue = `${operationValue}` === AndOrOperator.And;  
    handleClose();
    handleDuplicate(skillSetId, isOperationValue);
    if (!value) {
      register(`skillSetGroup.groups[${groupIndex}].operation`, null);
    }
    setValue(`skillSetGroup.groups[${groupIndex}].operation`, operationValue);
  };
  const handleOptionClickAdd = operationValue => {
    handleClose();
    handleAdd();
    if (!value) {
      register(`skillSetGroup.groups[${groupIndex}].operation`, null);
    }
    setValue(`skillSetGroup.groups[${groupIndex}].operation`, operationValue);
  };
  
  const handleAddClose = () => {
    setAndOptionMenuAncorEl(null);
  };

  const handleOrClose = () => {
    setOrOptionMenuAncorEl(null);
  };

  const listItemText = (skillset, index) => {
    return (
      <ListItemText
        primary={`${index + 1}. ${skillset?.skillSet?.discipline?.object?.Abbreviation}-${
          skillset?.skillSet?.specialty?.label
        }${skillset?.skillSet?.subSpecialty ? '-' + skillset?.skillSet?.subSpecialty?.label : ''}`}
      />
    );
  };
  
  const andOptionsList = React.useMemo(
    () => (
      <List>
        {(skillsets || []).map((skillset, index) => (
          <ListItemButton
            key={index}
            className={classes.listItem}
            onClick={() => handleOptionClickDuplicate(AndOrOperator.And, index)}
          >
            {listItemText(skillset, index)}
          </ListItemButton>
        ))}
      </List>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [skillsets],
  );

  const orOptionsList = React.useMemo(
    () => (
      <List>
        {(skillsets || []).map((skillset, index) => (
          <ListItemButton
            key={index}
            className={classes.listItem}
            onClick={() => handleOptionClickDuplicate(AndOrOperator.Or, index)}
          >
            {listItemText(skillset, index)}
          </ListItemButton>
        ))}
      </List>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [skillsets],
  );

  return (
    <>
      <div className={classes.dividerPopover} />
      <ClickAwayListener onClickAway={handleClose}>
        <Paper onMouseEnter={handleClick}>
          <CustomTooltip
            arrow
            standardMargin
            disabled={!isDisabled}
            tooltipContent={isDisabled ? t('facility.units.unitDetails.unitRequirements.disableAndOr') : ''}
            disableMaxWidth={true}
          >
            <Button
              aria-describedby={anchorEl ? 'add-experience-menu-popover' : undefined}
              variant="contained"
              onClick={handleClick}
              className={classes.buttonAdd}
              disabled={isDisabled}
            >
              <AddIcon />
            </Button>
          </CustomTooltip>
          {anchorEl && !isDisabled && (
            <div className={classes.popover}>
              <div className={`${value && !isAnd ? classes.disableOptions : ''}`}>
                <div className={classes.andOr}>{t('facility.units.unitDetails.unitRequirements.and')}</div>
                <MenuItem onClick={() => handleOptionClickAdd(AndOrOperator.And)} className={classes.menuOption}>
                  {t('facility.units.unitDetails.unitRequirements.addNewSkillSet')}
                </MenuItem>
                {enableDuplicateSkillSet.enabled ? (
                  <MenuItem
                    id="andDuplicateSkillSetItem"
                    onMouseEnter={(event: any) => {
                      setAndOptionMenuAncorEl(event.currentTarget);
                      getAvailableHeightForPopOver(true);
                    }}
                    onMouseLeave={handleAddClose}
                    className={classes.menuOption}
                  >
                    <Popover
                      open={Boolean(andOptionMenuAncorEl)}
                      anchorEl={andOptionMenuAncorEl}
                      hideBackdrop={true}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      style={{ zIndex: '9999', marginLeft: '1px', marginTop: '-44px', maxHeight: `${popoverHeight}px`, maxWidth: `500px` }}
                      onClose={handleAddClose}
                    >
                      <ClickAwayListener onClickAway={handleAddClose}>
                        <Paper className={classes.paper}>{andOptionsList}</Paper>
                      </ClickAwayListener>
                    </Popover>
                    {t('facility.units.unitDetails.unitRequirements.duplicateSkillSet')}
                    {(!value || isAnd) && <img src={RightArrow} style={{ marginLeft: '10px' }} alt="Side Menu Arrow" />}
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={() => handleOptionClickDuplicate(AndOrOperator.And, skillsets.length - 1)}
                    className={classes.menuOption}
                  >
                    {t('facility.units.unitDetails.unitRequirements.duplicateSkillSet')}
                  </MenuItem>
                )}
              </div>
              <div className={`${value && isAnd ? classes.disableOptions : ''}`}>
                <div className={classes.andOr}>{t('facility.units.unitDetails.unitRequirements.or')}</div>
                <MenuItem onClick={() => handleOptionClickAdd(AndOrOperator.Or)} className={classes.menuOption}>
                  {t('facility.units.unitDetails.unitRequirements.addNewSkillSet')}
                </MenuItem>
                {enableDuplicateSkillSet.enabled ? (
                  <MenuItem
                    id="orDuplicateSkillSetItem"
                    onMouseEnter={(event: any) => {
                      setOrOptionMenuAncorEl(event.currentTarget);
                      getAvailableHeightForPopOver(false);
                    }}                    
                    onMouseLeave={handleOrClose}
                    className={classes.menuOption}
                  >
                    {t('facility.units.unitDetails.unitRequirements.duplicateSkillSet')}
                    {(!value || !isAnd) && (
                      <img src={RightArrow} style={{ marginLeft: '10px' }} alt="Side Menu Arrow" />
                    )}
                    <Popover
                      open={Boolean(orOptionMenuAncorEl)}
                      anchorEl={orOptionMenuAncorEl}
                      hideBackdrop={true}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      style={{ zIndex: '9999', marginLeft: '1px', marginTop: '-44px', maxHeight: `${popoverHeight}px`, maxWidth: `500px` }}
                      onClose={handleOrClose}
                    >
                      <ClickAwayListener onClickAway={handleOrClose}>
                        <Paper className={classes.paper}>{orOptionsList}</Paper>
                      </ClickAwayListener>
                    </Popover>
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={() => handleOptionClickDuplicate(AndOrOperator.Or, skillsets.length - 1)}
                    className={classes.menuOption}
                  >
                    {t('facility.units.unitDetails.unitRequirements.duplicateSkillSet')}
                  </MenuItem>
                )}
              </div>
            </div>
          )}
        </Paper>
      </ClickAwayListener>
    </>
  );
};
