interface Address {
  address1: string;
  address2: string;
  city: string;
  stateAbbrev: string;
  zipValue: string;
  country: string;
}

export const formatAddress = (address: Address) => {
  const address1 = address.address1 || '';
  const address2 = address.address2 || '';
  const city = address.city || '';
  const stateAbbrev = address.stateAbbrev || '';
  const zipValue = address.zipValue || '';
  const country = address.country || '';

  let line1 = `${address1}, ${address2}`.trim().replace(/,$/, '');
  let line2 = `${city}, ${stateAbbrev} ${zipValue}`;
  let line3 = country;

  if (line1.length > 27) {
    const part1 = line1.slice(0, 27).lastIndexOf(' ');
    const part2 = line1.slice(part1).trim().replace(/,$/, '');
    line1 = line1.slice(0, part1);
    line2 = `${part2}, ${line2}`.trim().replace(/,$/, '');
  }

  if (line2.length > 27) {
    const part1 = line2.slice(0, 27).lastIndexOf(' ');
    const part2 = line2.slice(part1).trim().replace(/,$/, '');
    line2 = line2.slice(0, part1);
    line3 = `${part2}, ${line3}`.trim().replace(/,$/, '');
  }
  const formattedAddressObject = { addressLine1: line1, addressLine2: line2, addressLine3: line3 };
  return formattedAddressObject;
};
