/* eslint-disable i18next/no-literal-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutGrid } from 'app/layout/LayoutGrid';
import { useSelector } from 'react-redux';
import { candidateDetailsSelection } from '../../../Profile/CandidateDetails.selector';
import treeData from 'app/assets/jsons/placementStatusFilter.json';
import moment from 'moment';
import { convertToPST } from 'app/helpers/dateHelper';
import { userRoles } from 'oidc/userRoles';
import { selectUser } from 'oidc/user.selectors';
import { disciplineDataSelector } from 'store/redux-store/lookup/lookup.selectors';
import { CandidateNavigation } from '../CardNavigation';
import { ff_candidate_container_ui_v2, ff_WorkExperience_Enhancement } from 'app/constants/FeatureFlags/Candidate/Keys';
import { useDecision } from '@optimizely/react-sdk';
import {
  CandidateVirtualInterview,
  checkInterviewDateOver,
  checkInterviewDateUpcoming,
  checkScheduledIncompleteInterview,
} from './helper';
import VirtualInterviewUI from './Card Design/VirtualInterviewUI';
import VirtualInterviewUIV2 from './Card Design/VirtualInterviewUIV2';
import { useStyles } from './Card Design/utils';
import { Authorized } from 'oidc/userHelper';

export const VirtualInterviewCard = props => {
  const { t } = useTranslation();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const disciplineData = useSelector(disciplineDataSelector);
  const [skillsetsList, setSkillsetsList] = useState<CandidateVirtualInterview[]>([]);
  const { classes } = useStyles();
  const [totalItems, setTotalItems] = useState<number>(0);
  const user = useSelector(selectUser);
  const [candidateContainerUIV2] = useDecision(ff_candidate_container_ui_v2);
  const [hasWorkExperienceTab] = useDecision(ff_WorkExperience_Enhancement);

  const booked = useMemo(() => {
    return treeData.filter(item => item.name === 'Booked');
  }, [treeData]);

  const allowedStatuses: any[] = [];

  booked[0].children?.forEach(item => {
    allowedStatuses.push(item.name);
  });

  const checkAccessPermission = () => {
    return Authorized(
      [userRoles.recruitment, userRoles.recruitment_Leadership, userRoles.recruitment_TeamMember],
      user.userInfo,
    );
  };
  const checkNoVirtualInterview = item => {
    const status = item.interverStatus !== 'Completed' || item.interverStatus === 'Rejected';
    return status;
  };
  const getToolTipMessage = (
    status,
    interviewDate,
    interviewForFuture,
    scheduledIncompleteInterview,
    noVirtualInterview,
    requestedOn,
  ) => {
    if (status) {
      return (
        <>
          <div className={classes.customScroll}>
            <div style={{ display: 'flexbox', flexDirection: 'row', padding: '5px' }}>
              <b className={classes.tooltipRowHeader}>{`Interview Date - `}</b>
              <span className={classes.tooltipRowContent}>{`${moment(interviewDate).format('MM/DD/YYYY')}`}</span>
              <br></br>
              <b className={classes.tooltipRowHeader}>{`Requested By - `}</b>
              <span className={classes.tooltipRowContent}>{`ES API`}</span>
              <br></br>
              <b className={classes.tooltipRowHeader}>{`Requested On - `}</b>
              <span className={classes.tooltipRowContent}>{`${
                requestedOn === null ? '--' : convertToPST(requestedOn)
              }`}</span>
            </div>
          </div>
        </>
      );
    } else if (interviewForFuture) {
      return (
        <>
          <div className={classes.customScroll}>
            <div style={{ display: 'flexbox', flexDirection: 'row', padding: '5px' }}>
              <b className={classes.tooltipRowHeader}>{`Interview Date - `}</b>
              <span className={classes.tooltipRowContent}>{`${moment(interviewDate).format('MM/DD/YYYY')}`}</span>
              <br></br>
              <b className={classes.tooltipRowHeader}>{`Requested By - `}</b>
              <span className={classes.tooltipRowContent}>{`ES API`}</span>
              <br></br>
              <b className={classes.tooltipRowHeader}>{`Requested On - `}</b>
              <span className={classes.tooltipRowContent}>{`${
                requestedOn === null ? '--' : convertToPST(requestedOn)
              }`}</span>{' '}
            </div>
          </div>
        </>
      );
    } else if (scheduledIncompleteInterview) {
      return (
        <>
          <div className={classes.customScroll}>
            <div style={{ display: 'flexbox', flexDirection: 'row', padding: '5px' }}>
              <div className={classes.tooltipRowContent}>{t('Incomplete Virtual Interview')}</div>
              <b className={classes.tooltipRowHeader}>{`Interview Date - `}</b>
              <span className={classes.tooltipRowContent}>{`${moment(interviewDate).format('MM/DD/YYYY')}`}</span>
              <br></br>
              <b className={classes.tooltipRowHeader}>{`Requested By - `}</b>
              <span className={classes.tooltipRowContent}>{`ES API`}</span>
              <br></br>
              <b className={classes.tooltipRowHeader}>{`Requested On - `}</b>
              <span className={classes.tooltipRowContent}>{`${
                requestedOn === null ? '--' : convertToPST(requestedOn)
              }`}</span>
            </div>
          </div>
        </>
      );
    } else if (noVirtualInterview) {
      return (
        <>
          <div className={classes.customScroll}>
            <div style={{ display: 'flexbox', flexDirection: 'row', padding: '5px' }}>
              <div className={classes.tooltipRowContent}>{t('No Virtual Interview')}</div>
            </div>
          </div>
        </>
      );
    } else return '';
  };
  useEffect(() => {
    if (candidateDetails) {
      const skillsets = [];
      candidateDetails?.verifiedSkills?.map(item => {
        const discipline = disciplineData?.find(disc => disc?.object?.Abbreviation === item?.disciplineAbbr)?.object;
        const skillset = discipline?.Description
          ? item.specialtyAbbr
            ? `${discipline.Description} - ${item.specialtyAbbr}`
            : discipline?.Description
          : item.disciplineAbbr;
        let status = false;
        let interviewForFuture = false;
        let scheduledIncompleteInterview = false;
        let noVirtualInterview = false;

        if (item?.interverStatus && item?.interverStatus === 'Completed') {
          status = checkInterviewDateOver(item?.interviewDate);
        }
        if (checkInterviewDateUpcoming(item?.interviewDate) && !status) {
          interviewForFuture = true;
        }
        if (checkScheduledIncompleteInterview(item) && !status && !interviewForFuture) {
          scheduledIncompleteInterview = true;
        }
        if (checkNoVirtualInterview(item) && !status && !interviewForFuture && !scheduledIncompleteInterview) {
          noVirtualInterview = true;
        }
        if (!skillsets.find(item => item.skillset === skillset)) {
          skillsets.push({
            skillset: skillset,
            interviewStatus: status,
            toolTipMessage: getToolTipMessage(
              status,
              item?.interviewDate,
              interviewForFuture,
              scheduledIncompleteInterview,
              noVirtualInterview,
              item?.requestedOn,
            ),
            interviewForFuture: interviewForFuture,
            scheduledIncompleteInterview: scheduledIncompleteInterview,
            noVirtualInterview: noVirtualInterview,
          });
        }
      });
      setSkillsetsList(skillsets);
      setTotalItems(skillsets.length);
    }
  }, [candidateDetails]);

  useEffect(() => {
    let count = 0;
    // eslint-disable-next-line array-callback-return
    skillsetsList.map(item => {
      if (item.interviewStatus) {
        count++;
      }
    });
  }, [skillsetsList]);

  const handleNavigationToVirtualInterview = () => {
    let optedTab = CandidateNavigation.VirtualInterview;
    if (hasWorkExperienceTab?.enabled) {
      optedTab = optedTab + 1;
    }
    window.history.pushState(null, '', `${window.location.pathname}?tab=${optedTab}`);
    props.navigate(optedTab);
  };

  return (
    <LayoutGrid className={classes.grid}>
      {candidateContainerUIV2?.enabled ? (
        <VirtualInterviewUIV2
          skillsetsList={skillsetsList}
          handleNavigationToVirtualInterview={handleNavigationToVirtualInterview}
          checkAccessPermission={checkAccessPermission}
          navigate={props.navigate}
        />
      ) : (
        <VirtualInterviewUI
          skillsetsList={skillsetsList}
          handleNavigationToVirtualInterview={handleNavigationToVirtualInterview}
          checkAccessPermission={checkAccessPermission}
          navigate={props.navigate}
        />
      )}
    </LayoutGrid>
  );
};
