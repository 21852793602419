import UnitDetailCard from '@AMIEWEB/Order/OrderCreationForm/Common/UnitDetailCard';
import { Grid, Typography } from 'amn-ui-core';
import React from 'react';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { JobDescriptionContainer } from '../UnitDetails/JobDescription';
import { useTranslation } from 'react-i18next';
import InternalNotes from '../UnitDetails/InternalNotes';
import AdditionalRequiredQualifications from '../UnitDetails/AdditionalRequiredQualifications';
import PreferredQualifications from '../UnitDetails/PreferredQualifications';
import TungstenIcon from '@mui/icons-material/Tungsten';
import { theme } from 'styles/global-styles';
import { FormPageType } from '../UnitRequirements/Experience/SubCertificate/SubCertificate.types';

function QualificationAndJobDetailsSection({
  jobDescriptionRef,
  internalNotesRef,
  preferredQualifications,
  additionalRequiredQualifications,
}) {
  const { t } = useTranslation();
  return (
    <>
      <TungstenIcon
        style={{ color: theme.palette.secondary.main, transform: 'rotate(180deg)', marginBottom: '4px', scale: '1.1' }}
      />
      <Typography paddingTop={'3px'}>
        {t('facility.units.unitDetails.qualificationAndJobDetails.requiredQualificationGenerationMessage')}
      </Typography>
      <Grid container direction={'column'} xs={12} rowSpacing={2}>
        <Grid item ref={additionalRequiredQualifications}>
          <UnitDetailCard
            icon={<DescriptionOutlinedIcon />}
            title={t('facility.units.unitDetails.qualificationAndJobDetails.additionalRequiredQualifications')}
            children={<AdditionalRequiredQualifications parentPage={FormPageType.Unit} />}
          />
        </Grid>
        <Grid item ref={preferredQualifications}>
          <UnitDetailCard
            icon={<DescriptionOutlinedIcon />}
            title={t('facility.units.unitDetails.qualificationAndJobDetails.preferredQualifications')}
            children={<PreferredQualifications parentPage={FormPageType.Unit} />}
          />
        </Grid>
        <Grid item ref={internalNotesRef}>
          <UnitDetailCard
            icon={<DescriptionOutlinedIcon />}
            title={t('facility.units.internalJobNotes.title')}
            children={<InternalNotes parentPage={FormPageType.Unit} />}
          />
        </Grid>
        <Grid item ref={jobDescriptionRef}>
          <UnitDetailCard
            icon={<DescriptionOutlinedIcon />}
            title={t('facility.units.unitDetails.unitDetails.jobDescription.title2')}
            children={<JobDescriptionContainer />}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default QualificationAndJobDetailsSection;
