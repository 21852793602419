import { Box, Grid, Menu, MenuItem } from 'amn-ui-core';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { getContractMergeToken, getConfirmationMergeToken } from '../mergeTokens';
import { OverflowTooltip } from '@AMIEWEB/Common/Tooltips';
import { colors } from 'styles/styleVariables';
import { TemplateEditorContext } from '..';

const useStyles = makeStyles<{ appliedCount: number; disableTokenInteractions: boolean }>()(
  (theme, { appliedCount, disableTokenInteractions }) => ({
    container: {
      flexWrap: 'nowrap',
      border: `1px solid ${!appliedCount ? '#d9d9d9' : appliedCount === 1 ? '#34B24D' : '#34B233'}`,
      background: !appliedCount ? '#FFFFFF' : appliedCount === 1 ? '#EDF7ED' : '#DEF4DE',
      color: !appliedCount ? '#333333' : '#555555',
      borderRadius: 6,
      height: 38,
      padding: 6,
      cursor: disableTokenInteractions ? 'default' : 'pointer',
    },
    label: {
      flex: 1,
      fontSize: 14,
      lineHeight: 2.3,
      overflow: 'hidden',
      padding: '0 12px',
    },
    invertColor: {
      filter: !appliedCount
        ? `invert(31%) sepia(70%) saturate(1672%) hue-rotate(181deg) brightness(80%) contrast(80%)`
        : undefined,
    },
  }),
);

export const MergeToken = ({ data = '&lt;&lt;test-value&gt;&gt;', label, ...props }) => {
  const { classes } = useStyles({
    appliedCount: props.count,
    disableTokenInteractions: props.disableTokenInteractions,
  });
  const [isHighlighted, setHighlighted] = React.useState(false);
  const { documentType } = React.useContext(TemplateEditorContext);

  const handleDragStart = event => {
    event.dataTransfer.setData('text/html', data);
  };

  const { icon } = React.useMemo(
    () =>
      documentType === 'client-confirmation'
        ? getConfirmationMergeToken(props.id)
        : documentType === 'candidate-contract'
        ? getContractMergeToken(props.id)
        : { icon: null },
    [documentType, props.id],
  );

  const _onHighlight = e => {
    props.onHighlight?.({ highlight: !isHighlighted, data, ...props });
    setHighlighted(x => !x);
    e.stopPropagation();
  };

  const _onDelete = e => {
    props.onDelete?.({ data, ...props });
    e.stopPropagation();
  };

  const displayValue = props.count > 1 ? `${label} (${props.count})` : label;

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignContent="center"
      classes={{ container: classes.container }}
      component={'div'}
      draggable={props.disableTokenInteractions ? undefined : true}
      onDragStart={props.disableTokenInteractions ? undefined : handleDragStart}
      onClick={props.disableTokenInteractions ? undefined : () => props.onClick(data)}
    >
      <Grid item>
        <img src={icon} alt={props.id} className={classes.invertColor} draggable={false} />
      </Grid>
      <Grid item classes={{ root: classes.label }}>
        <OverflowTooltip defaultRender value={displayValue} />
      </Grid>
      {props.count ? (
        <Grid item>
          <InfoIcon onHighlight={_onHighlight} onDelete={_onDelete} />
        </Grid>
      ) : null}
    </Grid>
  );
};

const InfoIcon = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleItemClick = (event, callbackFn = undefined) => {
    event.preventDefault();
    event.stopPropagation();
    callbackFn?.();
  };

  return (
    <Box>
      <div
        onMouseEnter={e => {
          setAnchorEl(e.currentTarget);
        }}
        onMouseLeave={e => {
          setAnchorEl(null);
        }}
      >
        <InfoOutlinedIcon fontSize="small" style={{ color: '#34B233' }} />
        <Menu
          open={open}
          anchorEl={anchorEl}
          disableAutoFocus
          disableEnforceFocus
          autoFocus={false}
          onClose={() => setAnchorEl(null)}
          style={{ pointerEvents: 'none' }}
        >
          <div style={{ pointerEvents: 'auto' }}>
            <MenuItem onClick={e => handleItemClick(e, props.onHighlight)}>
              <VisibilityOutlinedIcon fontSize="small" style={{ color: colors.amnLightBlue }} />
            </MenuItem>
            <MenuItem onClick={e => handleItemClick(e, props.onDelete)}>
              <DeleteOutlinedIcon fontSize="small" style={{ color: colors.amnLightBlue }} />
            </MenuItem>
          </div>
        </Menu>
      </div>
    </Box>
  );
};
