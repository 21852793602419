import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';

import { initialState } from './AmPlacementsDesk.redux';
const nullCount = {
  placementsCount: 0,
  inSubmission: 0,
  waitingForClearance: 0,
  requestFileOut: 0,
  sent: 0,
  interviewed: 0,
  offer: 0,
  offerByFacility: 0,
  offerBookedByTraveler: 0,
  facilityConfirms: 0,
  travelerAccepts: 0,
  bookedCategory: 0,
  bookedSubCategory: 0,
  bookingApproved: 0,
  waitingGoodToGo: 0,
  postponementWarning: 0,
  goodToGo: 0,
  onAssignment: 0,
};
const selectDomain = (state: RootState) => state.accManagementPlacements || initialState;

export const selectPipelineCounts = createSelector(
  [selectDomain],
  amPlacementData => amPlacementData.counts || { ...nullCount },
);

export const selectAmPlacementGridData = createSelector(
  [selectDomain],
  amPlacementData => amPlacementData.filteredData || amPlacementData.flattenedData,
);

export const selectAmPlacementSourceData = createSelector(
  [selectDomain],
  amPlacementData => amPlacementData.flattenedData,
);

export const selectAmPlacementDataLoaded = createSelector([selectDomain], state => state.dataLoaded);

export const selectAmPlacementDeskChips = createSelector([selectDomain], amPlacementData => amPlacementData.chips);

export const selectActiveAccountManagers = createSelector(
  [selectDomain],
  amPlacementData => amPlacementData.accountManagers,
);

export const selectFilterSpecs = createSelector([selectDomain], amPlacementData => amPlacementData.filterSpecs);

export const selectFilterChipSelected = createSelector(
  [selectDomain],
  amPlacementData => amPlacementData.filterChipSelected,
);
