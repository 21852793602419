import { TravelExperienceSections } from 'app/enums/Common';

export const setIsRequiredValue = (
  experienceTypeChanged,
  getValues,
  setValue,
  groupIndex,
  skillSetIndex,
  defaultTravelExperienceValue,
) => {
  const getValue = filedName => {
    return getValues(`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].travelExperience.${filedName}`);
  };
  const setRequired = value => {
    setValue(`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].travelExperience.isRequired`, value);
  };
  const experienceType = getValue('experienceType');
  const numberOfAssignments = Number(getValue('numberOfAssignments'));
  const yearsOfExperience = getValue('yearsOfExperience');
  const monthsOfExperience = getValue('monthsOfExperience');

  if (experienceTypeChanged) {
    if (experienceType.groupBy === TravelExperienceSections.NoOfAssignments) {
      setRequired(defaultTravelExperienceValue?.numberOfAssignments ? true : false);
    } else {
      setRequired(
        defaultTravelExperienceValue?.yearsOfExperience || defaultTravelExperienceValue?.monthsOfExperience
          ? true
          : false,
      );
    }
  } else if (
    (experienceType && numberOfAssignments >= 1) ||
    (experienceType && (yearsOfExperience >= 1 || monthsOfExperience >= 1))
  ) {
    setRequired(true);
  } else {
    setRequired(false);
  }
};
