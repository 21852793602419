import React from 'react';
import { Grid, Skeleton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  skeletonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '18px 0px',
  },
  snackbarAction: {
    fontWeight: 'bold',
    color: theme.palette.system.primary,
    cursor: 'pointer',
  },
}));

export const TaskDetailRightSkeleton = ({ isSubTask }: { isSubTask: boolean }) => {
  return (
    <>
      <Grid container spacing={2} item xs={12}>
        <Grid xs={12} item display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          <Skeleton variant="circular" width={'24px'} height={'24px'} />
          <Skeleton variant="rectangular" width="250px" sx={{ marginLeft: '-40px' }} />
          <Skeleton variant="rectangular" width="20px" />
          <Skeleton variant="rectangular" width="20px" />
          <Skeleton variant="rectangular" width="20px" />
          <Skeleton variant="rectangular" width="130px" />
        </Grid>
        <Grid xs={12} item ml={5}>
          <Skeleton variant="rectangular" width="150px" />
        </Grid>
        <Grid xs={12} item ml={5}>
          <Skeleton variant="rectangular" width="200px" />
        </Grid>
        <Grid xs={12} item ml={5}>
          <Skeleton variant="rectangular" width="150px" />
        </Grid>
        <Grid xs={2.5} ml={5} item display={'flex'} flexDirection={'row'}>
          <Skeleton variant="circular" width={'24px'} height={'24px'} />
          <Skeleton variant="circular" width={'24px'} height={'24px'} sx={{ marginLeft: '24px' }} />
        </Grid>
        <Grid xs={10} ml={5} item>
          <Skeleton variant="rectangular" width="640px" height="50px" />
        </Grid>
      </Grid>
      {!isSubTask && (
        <Grid item>
          <Skeleton variant="rectangular" width="680px" height="250px" />
        </Grid>
      )}
    </>
  );
};

export const TaskDetailsSkeleton = ({ isSubTask }: { isSubTask: boolean }) => {
  return (
    <>
      <Grid padding={'12px 0px'} item xs={3} sx={{ maxHeight: '300px' }}>
        <Grid display={'flex'} flexDirection={'row'} justifyContent={'center'} sx={{ padding: '18px 0px' }}>
          <Skeleton variant="circular" width={'24px'} height={'24px'} />
          <Skeleton variant="rectangular" width={'100px'} sx={{ marginLeft: '12px' }} />
        </Grid>
        <Grid display={'flex'} flexDirection={'row'} justifyContent={'center'} sx={{ padding: '18px 0px' }}>
          <Skeleton variant="circular" width={'24px'} height={'24px'} />
          <Skeleton variant="rectangular" width={'100px'} sx={{ marginLeft: '12px' }} />
        </Grid>
        <Grid display={'flex'} flexDirection={'row'} justifyContent={'center'} sx={{ padding: '18px 0px' }}>
          <Skeleton variant="circular" width={'24px'} height={'24px'} />
          <Skeleton variant="rectangular" width={'100px'} sx={{ marginLeft: '12px' }} />
        </Grid>
        <Grid display={'flex'} flexDirection={'row'} justifyContent={'center'} sx={{ padding: '18px 0px' }}>
          <Skeleton variant="circular" width={'24px'} height={'24px'} />
          <Skeleton variant="rectangular" width={'100px'} sx={{ marginLeft: '12px' }} />
        </Grid>
      </Grid>
      <Grid container spacing={2} padding={5} item xs={9}>
        <TaskDetailRightSkeleton isSubTask={isSubTask} />
      </Grid>
    </>
  );
};
