import { green } from '@mui/material/colors';
import { breadcrumbActions } from 'app/components/Common/BreadcrumbNavigation/BreadcrumbNavigation.redux';
import { JobMatchData } from 'app/models/Candidate/CandidateJobMatch';
import React from 'react';
import { GetMomentOfDate } from 'utils/dates/moment';
import { concatenate } from '../../helper/gridHelper';
import WorkIcon from '@mui/icons-material/Work';
import styledComponent from 'styled-components';
import { Link } from 'amn-ui-core';
import { OverflowTooltip } from '../../../Common/Tooltips';

export const GenerateMapOrderData = (
  jobMatches: JobMatchData[],
  dispatch,
  t,
  orderNavData,
  facilityNavData,
  forCarouselCards = false,
) =>
  jobMatches.reduce((resultArray: any[], info) => {
    if (info.facilityCoordinates) {
      const verifiedDateMoment = GetMomentOfDate(info.orderData.dateVerified);
      const startDateMoment = GetMomentOfDate(info.orderData.startDate);
      const keyValuePairs = [
        {
          key: t('candidate.jobMatchMap.skillSet'),
          value: info.disciplineSpecialty.map(option => concatenate(option)),
          mapOverlayCardProperty: true,
        },
        {
          key: t('candidate.jobMatchMap.unit'),
          value: info.orderData.unit,
          mapOverlayCardProperty: true,
        },
        {
          key: t('candidate.jobMatchMap.facilityAddress'),
          value: info.orderData.facilityAddress,
        },
        {
          key: t('candidate.jobMatchMap.startDate'),
          value: startDateMoment.isValid() ? startDateMoment.format('MM/DD/YYYY') : 'ASAP',
          mapOverlayCardProperty: true,
        },
        {
          key: t('candidate.jobMatchMap.placementLength'),
          value: info.orderData.placementLength,
        },
        {
          key: t('candidate.jobMatchMap.shifts'),
          value: info.orderData.shifts,
        },
        {
          key: t('candidate.jobMatchMap.gww'),
          value: info.orderData.gww ? <WorkIcon style={{ color: green[500] }} /> : ' ',
        },
        {
          key: t('candidate.jobMatchMap.lastVerifiedDate'),
          value: verifiedDateMoment.isValid() ? verifiedDateMoment?.format('MM/DD/YYYY') : '',
        },
      ];

      const OrderLink = () => (
        <Link
          href={`/order/${info.orderId}`}
          onClick={() => dispatch(breadcrumbActions.setNavigationData(orderNavData))}
        >
          {info.orderId}
        </Link>
      );
      const FacilityLink = () => (
        <Link
          href={`/facility/${info.facilityId}`}
          onClick={() => dispatch(breadcrumbActions.setNavigationData(facilityNavData))}
        >
          {info.facilityName}
        </Link>
      );

      const data = {
        entityId: info.orderId,
        title: (
          <>
            <OrderLink />
            <DividerSpan>{'|'}</DividerSpan>
            {forCarouselCards ? (
              <OverflowTooltip
                contentStyle={{ maxWidth: 200, minWidth: 200 }}
                value={<FacilityLink />}
                normallyRendered
              />
            ) : (
              <OverflowTooltip value={<FacilityLink />} normallyRendered />
            )}
          </>
          // <div>{`${info.orderId} | ${info.facilityName}`}</div>
        ),
        avatarText: info.orderData.positionsAvailable,
        locationCoordinates: info.facilityCoordinates,
        keyValuePairs: keyValuePairs,
      };
      resultArray.push(data);
    }
    return resultArray;
  }, []);

const DividerSpan = styledComponent.span`
  margin: auto 8px;
  color: inherit;
`;

export const SortJobMatches = (orders: JobMatchData[], selectedOrderIds) =>
  orders
    .filter(order => selectedOrderIds.some(id => id === order.orderId))
    .sort(function (orderA, orderB) {
      if (orderA.disciplineSpecialty[0].disciplineAbbr > orderB.disciplineSpecialty[0].disciplineAbbr) return 1;
      else if (orderA.disciplineSpecialty[0].disciplineAbbr < orderB.disciplineSpecialty[0].disciplineAbbr) return -1;
      else {
        if (orderA.disciplineSpecialty[0].specialtyAbbr > orderB.disciplineSpecialty[0].specialtyAbbr) return 1;
        else if (orderA.disciplineSpecialty[0].specialtyAbbr < orderB.disciplineSpecialty[0].specialtyAbbr) return -1;
        else {
          // most recent date gets precedence
          if (orderA.orderData.dateVerified < orderB.orderData.dateVerified) return 1;
          else if (orderA.orderData.dateVerified > orderB.orderData.dateVerified) return -1;
          else {
            // higher order value gets precedence
            if (orderA.orderId < orderB.orderId) return 1;
            else if (orderA.orderId > orderB.orderId) return -1;
            else return 0;
          }
        }
      }
    });
