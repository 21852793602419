import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { serviceActions } from './async-actions';
import { IContractTemplate } from './types';
import { initialState } from './utils';
import { IMergeToken } from '../confirmation-template-editor/types';

const slice = createSlice({
  name: 'contractTemplateEditor',
  initialState,
  reducers: {
    setTemplates(state, action: PayloadAction<IContractTemplate[]>) {
      return { ...state, templates: [...action.payload] };
    },
    setSelectedTemplate(state, action: PayloadAction<IContractTemplate>) {
      return { ...state, selectedTemplate: action.payload };
    },
    setMergeTokenList(state, action: PayloadAction<{ [key: string]: { label: string; tokens: IMergeToken[] } }>) {
      return { ...state, mergeTokenList: action.payload };
    },
    toggleForceModalCloseFlag(state) {
      state.miscellaneous.forceModalCloseFlag = !state.miscellaneous.forceModalCloseFlag;
    },
    reset() {
      return { ...initialState };
    },
  },
});

export const { name: contractTemplateEditorSliceKey, reducer: contractTemplateEditorReducer } = slice;

export const contractTemplateEditorActions = { ...slice.actions, ...serviceActions };
