import React, { useState } from 'react';
import {
  ThemeProvider,
  StyledEngineProvider,
  TextField,
  Checkbox,
  Paper,
  Popper,
  Autocomplete,
  createTheme,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { useDispatch } from 'react-redux';
import { theme } from 'styles/global-styles';

const useStyles: any = makeStyles()((theme) => ({
  icon: {
    padding: 0,
    '&:hover': {
      fill: theme.palette.components.icons.secondary.backgroundColor,
    },
    '&.MuiIconButton-label': {
      marginLeft: '-6px',
    },
  },
  textField: {
    fontSize: 14,
    // color: '#52429A',
    padding: '0px 4px 0px 6px !important',
    '&::placeholder': {
      opacity: 1,
      fontSize: 14,
      color: '#C0C0C0',
      paddingBottom: '12px',
    },
    '& .MuiInputAdornment-positionStart': {
      paddingLeft: '40px',
    },
  },
  popperDisablePortal: {
    position: 'relative',
  },
  fullWidth: {},
  paper: {
    margin: 0,
    minHeight: '150px',
  },
  option: {
    paddingLeft: 0,
    '&.MuiAutocomplete-option[aria-selected="true"]': {
      background: 'none',
    },
    '&.MuiAutocomplete-option[data-focus="true"]': {
      background: 'none',
    },
  },
  listBox: {
    /** Ensure utmost five options are within dropdown
     * and list does not toggle when anchor is at the screen center
     */
    maxHeight: 250,
    minHeight: 55,
    padding: 0,
    margin: 0,
    background: 'white',
    top: 0,
    left: '-16px',
    inset: 'none',
    position: 'absolute',
    width: 265,
    borderRadius: '0px 0px 3px 3px',
    boxShadow: `0px 4px 4px ${theme.palette.system.semiTransparentBlack}`,
    '& .MuiAutocomplete-popper': {
      top: 0,
      left: '-16px',
      inset: 'none',
    },
    '& .MuiPopper-root': {
      width: '265px',
    },
    '& .MuiAutocomplete-popper-popperDisablePortal': {
      width: '265px',
    },
  },
  customTextField: {
    display: 'fit-content',
  },
  inputRoot: {
    backgroundColor: 'white',
    '&[class*="MuiFilledInput-root"]': {
      paddingTop: 0,
      paddingLeft: 0,
      backgroundColor: 'white',
    },
  },
}));

const smallTextFieldTheme = createTheme(
  theme,
  {
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            height: '37.625px',
            paddingTop: 0,
            paddingLeft: 0,
            border: '1px solid grey',
            borderBottom: 'none',
            borderRadius: '3px',
            backgroundColor: 'white',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: { height: '37.625px', paddingTop: 0, paddingLeft: 0, backgroundColor: 'white' },
        },
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            '&&:before': {
              borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            },
            '&&:after': {
              borderBottom: '2px solid rgba(0, 0, 0, 0.42)',
            },
          },
        },
      },
    },
  },
);
export const SelectOptions = props => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    label,
    options,
    isError,
    isDisabled,
    helperText,
    customClasses,
    noOptionsText,
    onChange,
    // variant,
    size,
    enableCustomScroll,
    removeCloseIcon,
    // isMultiSelect,
    filterName,
    // searchOnCharacterCount = 3,
    // selectAllValue = null,
    isLoading = false,
    // nonNullable = false,
    hiddenInput,
    defaultValue,
    // handleOnChange,
  } = props;
  const [selectedValues, setSelectedValues] = useState(
    defaultValue.filter(x => x?.consents === true && x?.selectedValues === true && x?.communicationLimits === false),
  );
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event, newInputValue) => {
    const filtered = options.filter(option =>
      option.label.toLowerCase().includes(newInputValue.toLowerCase())
    );
    setFilteredOptions(filtered);
    setInputValue(newInputValue);
  };

  return (
    <div style={{ padding: '10px 15px', minWidth: '265px' }}>
      <Autocomplete
        open
        clearOnBlur={false}
        multiple
        fullWidth
        defaultValue={selectedValues}
        filterOptions={props.fetchOptions && (opt => opt)}
        noOptionsText={noOptionsText || undefined}
        disabled={isDisabled}
        loading={isLoading}
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) => option?.name === value?.name}
        disablePortal
        getOptionLabel={option => option?.label ?? option.name}
        popupIcon={props.popupIcon ? props.popupIcon : null}
        forcePopupIcon={true}
        renderTags={() => null}
        options={filteredOptions}
        value={selectedValues}
        inputValue={inputValue}
        classes={
          {
            inputRoot: classes.inputRoot,
            listbox: classes.listBox,
            popperDisablePortal: classes.popperDisablePortal,
            paper: classes.paper,
            fullWidth: classes.fullWidth,
            option: classes.option,
            ...customClasses,
          } || undefined
        }
        clearIcon={removeCloseIcon ? null : <CloseIcon fontSize="small" />}
        renderInput={params => (
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={smallTextFieldTheme}>
              <TextField
                error={isError}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                helperText={helperText}
                label={label}
                placeholder={t('appHeader.searchPlaceHolder')}
                {...params}
                className={cx({ [classes.customTextField]: hiddenInput })}
                size={size || undefined}
                InputProps={{
                  ...params.InputProps,
                  classes: { input: classes.textField },
                  startAdornment: <SearchIcon style={{ opacity: 0.6, marginLeft: '5px', fontSize: '1.4rem', padding:'0px' }} />,
                  'aria-label': 'filter-type-ahead',
                  onChange: (event) => handleInputChange(event, event.target.value)
                }}
                onKeyDown={(event: any) => {
                  if (event.key === 'Backspace' && !event.target.value) {
                    event.stopPropagation();
                  }
                }}
              />
            </ThemeProvider>
          </StyledEngineProvider>
        )}
        renderOption={(props, option, { selected }) => {
          const handleCheckboxChange = (id, checked) => {
            const updatedOptions = options.map(x => {
              if (x.id === id) {
                x.selectionStatus = checked;
                return x;
              }
              return x;
            });
            onChange(updatedOptions.filter(x => x?.consents && x?.selectionStatus && x?.phoneNumber && x?.communicationLimits === false));
            dispatch(notificationDataActions.setCandidateData(updatedOptions));
          };

          return (
            <li {...props} style={{ backgroundColor: 'transparent' }}>
              <Checkbox
                size="small"
                classes={{ root: classes.checkboxRoot }}
                icon={<CheckBoxOutlineBlankIcon classes={{ root: classes.icon }} />}
                checkedIcon={undefined}
                style={{ marginRight: 3 }}
                disabled={option?.phoneNumber === undefined || !option?.consents || option?.communicationLimits}
                defaultChecked={option?.selectionStatus && option?.phoneNumber && !option?.communicationLimits}
                onChange={e => handleCheckboxChange(option?.id, e.target.checked)}
              />
              <div>
                <span style={{ color: option?.phoneNumber === undefined ? theme.palette.system.darkRed : '' }}>
                  {option.label ?? option.name}
                </span>
                {(!option?.consents && !option?.communicationLimits) && (
                  <p
                    style={{ color: theme.palette.system.darkRed, fontSize: '12px', margin: '0' }}
                  >{`SMS consent revoked`}</p>
                )}
                {option?.communicationLimits && (
                  <p
                    style={{ color: theme.palette.system.darkRed, fontSize: '12px', margin: '0' }}
                  >{`communication limit reached`}</p>
                )}
              </div>
            </li>
          );
        }}
        PopperComponent={PopperComponent}
        PaperComponent={PaperComponent}
        aria-label={`custom-typeAhead-${filterName}`}
      />
    </div>
  );
};
const PaperComponent = props => (
  <Paper {...props} elevation={0}>
    {props.children}
  </Paper>
);

const PopperComponent = props => <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-start" />;
