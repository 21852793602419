import { Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
export const filtersStyle = makeStyles()((theme: Theme) => ({
    sudoTextBox: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px 6px 5px 12px',
        width: '100%',
        minHeight: '38px',
        maxHeight: '38px',
        borderRadius: '4px',
        '& p': {
          fontSize: '12px',
        },
        border: '1px solid rgba(0, 0, 0, 0.23)',
        '&:hover': {
          borderColor: theme.palette.primary.main,
          '&.empty p': {
            color: theme.palette.primary.main,
          },
    
          '&.empty svg': {
            color: theme.palette.primary.main,
          },
        },
        '&.focused': {
          padding: '4px 5px 4px 11px',
          borderColor: theme.palette.primary.main,
          '&.empty p': {
            color: theme.palette.primary.main,
          },
        },
        '&.empty p': {
          color: '#C0C0C0',
        },
      },

      collapsIcon: {
        height: '21px',
        width: '21px',
        color: 'rgba(0, 0, 0, 0.54)',
        marginBottom: '2px',
        '.empty &': {
          color: '#C0C0C0',
        },
        '&:hover': {
          '.empty &': {
            color: theme.palette.primary.main,
          },
        },
      },
      expandIcon: {
        height: '21px',
        width: '21px',
        color: 'rgba(0, 0, 0, 0.54)',
        marginBottom: '2px',
        '.empty &': {
          color: theme.palette.primary.main,
        },
      },
  
}));
