import { Chip, Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAttachmentIcon } from '../Common/AttachmentFileTypes';

const useStyles = makeStyles()((theme: Theme) => ({
  box: {
    width: '100%',
  },
  Chip: {
    border: 'none',
  },
}));

export const DeliveryAttachments = props => {
  const { dataUrlAttachments } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [urlAttachments, setUrlAttachments] = useState<any[]>(dataUrlAttachments ?? []);

  useEffect(() => {
    const mappedData: any[] = dataUrlAttachments?.map(item => ({
      name: item?.name?.split('?')?.[0],
    }));
    setUrlAttachments(mappedData);
  }, [dataUrlAttachments]);

  return (
    <div className={classes.box}>
      <text style={{ fontWeight: 'bold' }}>Attachment : </text>
      {urlAttachments.map(
        (e, i) =>
          urlAttachments &&
          urlAttachments.length > 0 &&
          urlAttachments[i] && (
            <Chip
              className={classes.Chip}
              variant="outlined"
              size="medium"
              icon={
                <img
                  src={getAttachmentIcon(urlAttachments[i]?.name?.split('.')?.pop()?.toLowerCase())}
                  alt={t('fileicon')}
                />
              }
              label={urlAttachments[i]?.name}
            />
          ),
      )}
    </div>
  );
};
