import React, { useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';

const useStyles = makeStyles()((theme) => ({
  paper: {
    width: '80%',
    maxWidth: '1024px',
    outline: 'none',
  },
  paperMd: {
    width: '100%',
    minWidth: '600px',
    outline: 'none',
  },
  table: {
    minWidth: 650,
    fontSize: 12,
    border: '1px solid rgba(224, 224, 224, 1)',
    '&:hover': {
      backgroundColor: '#ECECEC',
    },
  },
  tableContainer: {
    padding: '24px',
  },
  tableWrap: {
    marginTop: '18px',
    fontSize: 12,
  },
  tableHead: {
    backgroundColor: theme.palette.framework.system.white,
    fontWeight: 'bold',
    //border: '1px solid rgba(224, 224, 224, 1)',
  },
  tableCell: {
    fontSize: 14,
    width: '105px',
    //border: '1px solid rgba(224, 224, 224, 1)',
  },
  tableHeaderCell: {
    fontSize: 12,
    width: '125px',
    letterSpacing: '0px',
    fontWeight: 'bold',
    lineHeight: '15px',
    //border: '1px solid rgba(224, 224, 224, 1)',
  },
  subTitleStyle: {
    paddingBottom: '5px',
  },
}));

export const CustomModal = props => {
  const { classes } = useStyles();
  const [open, setOpen] = React.useState(false);
  const { onChange, columns, rows, title, subTitle } = props;
  const handleClose = () => {
    setOpen(false);
    onChange(false);
  };
  useEffect(() => {
    setOpen(true);
  }, []);
  return (
    <>
      <GenericDialog
        open={open}
        variant="blue"
        classes={{ paper: title === 'Experience' ? classes.paperMd : classes.paper }}
        dialogTitleProps={{ text: title, closeButton: true, expandable: true }}
        onClose={handleClose}
      >
        <TableContainer elevation={0} component={Paper} className={classes.tableWrap}>
          {subTitle && (
            <Grid container className={classes.subTitleStyle}>
              <Grid item xs={12}>
                {subTitle}
              </Grid>
            </Grid>
          )}
          <Table className={classes.tableContainer}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                {columns &&
                  columns.map(header => <TableCell className={classes.tableHeaderCell}>{header.value}</TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows.map(row => (
                  <TableRow className={classes.table}>
                    {columns.map(header => (
                      <TableCell className={classes.tableCell}>{row[header.title]}</TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </GenericDialog>
    </>
  );
};
