import { CustomTooltip } from '@AMIEWEB/Common/Tooltips';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { Box, Skeleton } from 'amn-ui-core';
import { LayoutGrid } from 'app/layout/LayoutGrid';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useStyles } from './UnitCard.styles';
import UnitDetailsEditQualificationModal from './UnitDetailsEditQualificationModal';
import { UnitCardProps } from './types';

export const UnitCard = ({
  title,
  rightIcon,
  children,
  showEditIcon = true,
  showTitleBarBorder = true,
  isCustomEdit,
  onEditClick,
  skeletonWidth,
  isDataLoading,
}: UnitCardProps) => {
  const params = useParams<{ id: string; unitId: string }>();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [isHovered, setIsHovered] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const isEditable = rightIcon && showEditIcon;
  let showTimeout;
  let hideTimeout;

  const onEditFacilityRoles = () => {
    if (title === `${t('facility.units.jobDetails.title')}`) {
      setIsModalOpen(true);
      return;
    }
    history.push(`/facility/${params.id}/unit/${params.unitId}/edit`);
  };

  const handleMouseEnter = () => {
    showTimeout = setTimeout(() => {
      setOpen(true);
    }, 500);
  };

  const handleMouseLeave = () => {
    clearTimeout(showTimeout);
    hideTimeout = setTimeout(() => {
      setOpen(false);
    }, 200);
  };

  useEffect(() => {
    return () => {
      clearTimeout(showTimeout);
      clearTimeout(hideTimeout);
    };
  }, [showTimeout, hideTimeout]);

  const setIsModalOpenCallback = useCallback((isopen: boolean) => {
    setIsModalOpen(isopen);
  }, []);

  const editButton = (
    <CustomTooltip tooltipContent={t('facility.tooltip.edit')} open={open}>
      <CreateOutlinedIcon
        onClick={!isCustomEdit ? onEditFacilityRoles : () => onEditClick()}
        className={`${classes.icon} ${isHovered ? classes.iconHovered : ''}`}
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() => handleMouseLeave()}
      />
    </CustomTooltip>
  );

  const viewButton = (
    <CustomTooltip tooltipContent={t('order.viewFullDescription')} open={open}>
      <LaunchOutlinedIcon
        onClick={() => onEditFacilityRoles()}
        className={`${classes.icon} ${isHovered ? classes.iconHovered : ''}`}
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() => handleMouseLeave()}
      />
    </CustomTooltip>
  );

  return (
    <>
      <LayoutGrid container item direction="column" className={classes.container}>
        <LayoutGrid
          container
          item
          className={`${classes.titleBar} ${showTitleBarBorder && classes.titleBarBorder} ${
            isEditable && classes.onHover
          }`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Box
            className={isEditable && classes.onHoverUnderline}
            onClick={event => (isEditable ? onEditFacilityRoles() : event.stopPropagation())}
          >
            {isDataLoading ? <Skeleton variant="rectangular" width={skeletonWidth} height={18} /> : title}
          </Box>
          <Box>
            {title === `${t('facility.units.jobDetails.title')}`
              ? isEditable
                ? editButton
                : viewButton
              : isEditable && editButton}
          </Box>
        </LayoutGrid>
        {children}
      </LayoutGrid>
      {isModalOpen && (
        <UnitDetailsEditQualificationModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpenCallback}
          isEditable={isEditable}
        />
      )}
    </>
  );
};
