import { XGridDefaultColumn } from '@AMIEWEB/Common/XGrid/cells/XGridDefaults';
import { XGridUnitClickCell } from '@AMIEWEB/Common/XGrid/cells/XGridUnitClickCell';
import { xGridDateSort, xGridNumberSort } from '@AMIEWEB/Common/XGrid/sorting/XGridSorting';
import { GridCellParams, GridColumns } from '@mui/x-data-grid';
import { Link } from 'amn-ui-core';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import React from 'react';
import { useDispatch } from 'react-redux';

const unitColumns = (onClick: Function): GridColumns => [
  {
    ...XGridDefaultColumn,
    field: 'unitId',
    headerName: 'Unit ID',
    flex: 0,
    width: 100,
    // @ts-ignore
    renderCell: XGridUnitClickCell({ onClick, id: 'xGrid-unit-id-link' }),
    sortComparator: xGridNumberSort,
  },
  {
    ...XGridDefaultColumn,
    field: 'unitName',
    headerName: 'Unit Name',
    flex: 1,
    width: 100,
  },
  {
    ...XGridDefaultColumn,
    field: 'orders',
    headerName: 'Orders',
    flex: 0,
    width: 100,
    type: 'number',
  },
  {
    ...XGridDefaultColumn,
    field: 'positionsAvailable',
    headerName: 'Positions',
    flex: 0,
    width: 100,
    type: 'number',
  },
  {
    ...XGridDefaultColumn,
    field: 'skillset',
    headerName: 'Skill Set',
    flex: 1,
    width: 125,
  },
  {
    ...XGridDefaultColumn,
    field: 'yearsOfExperience',
    headerName: 'YOE',
    flex: 0,
    width: 100,
    type: 'number',
  },
  {
    ...XGridDefaultColumn,
    field: 'lastUpdated',
    headerName: 'Last Updated',
    flex: 0,
    width: 250,
    sortComparator: xGridDateSort,
  },
  {
    ...XGridDefaultColumn,
    field: 'lastUpdatedBy',
    headerName: 'Last Updated By',
    flex: 0,
    width: 200,
  },
  {
    ...XGridDefaultColumn,
    field: 'fyreIntegration',
    headerName: 'FYRE Integration',
    flex: 0,
    width: 200,
  },
  {
    ...XGridDefaultColumn,
    field: 'bestBets',
    headerName: 'Best Bets',
    flex: 0,
    width: 120,
  },
  {
    ...XGridDefaultColumn,
    field: 'voiceAdvantage',
    headerName: 'VA',
    flex: 0,
    width: 75,
  },
  {
    ...XGridDefaultColumn,
    field: 'unitSize',
    headerName: 'Unit Size',
    flex: 0,
    width: 75,
    type: 'number',
  },
  {
    ...XGridDefaultColumn,
    field: 'costCenter',
    headerName: 'Cost Center',
    flex: 0,
    width: 150,
  },
  {
    ...XGridDefaultColumn,
    field: 'floor',
    headerName: 'Floor',
    flex: 0,
    width: 110,
    type: 'number',
  },
];

interface GridCellProps {
  [key: string]: any;
}

const OrderLinkSidePanel = React.memo(function OrderLinkSidePanel(cellParams: GridCellProps) {
  const dispatch = useDispatch();
  const handleLink = params => {
    const payload = { open: true, order: { orderId: params?.value } };
    dispatch(globalActions.setFacilityDrawerData({ open: false, facility: undefined }));
    dispatch(globalActions.setOrderDrawerData(payload));
  };
  return (
    cellParams?.value &&
    !isNaN(cellParams?.value) && <Link onClick={() => handleLink(cellParams)}>{cellParams?.value}</Link>
  );
});

export const OrderLink = (params: GridCellParams) => {
  return <OrderLinkSidePanel {...params} />;
};

const orderUnitColumnsConfig = (): GridColumns => [
  {
    ...XGridDefaultColumn,
    field: 'orderId',
    headerName: 'Order ID',
    flex: 1,
    width: 100,
    // @ts-ignore
    renderCell: OrderLink,
  },
  {
    ...XGridDefaultColumn,
    field: 'shiftNotNegotiable',
    headerName: 'Shift Not Negotiable',
    flex: 1,
    width: 600,
  },
  {
    ...XGridDefaultColumn,
    field: 'verified',
    headerName: 'Last Verified',
    flex: 1,
    width: 100,
  },
  {
    ...XGridDefaultColumn,
    field: 'dateEntered',
    headerName: 'Date Entered',
    flex: 1,
    width: 100,
  },
  {
    ...XGridDefaultColumn,
    field: 'maxFilesSent',
    headerName: 'Max Files Sent',
    flex: 1,
    width: 100,
  },
  {
    ...XGridDefaultColumn,
    field: 'maxFilesLastUpdated',
    headerName: 'Max Files Last Updated',
    flex: 2,
    width: 300,
  },
  {
    ...XGridDefaultColumn,
    field: 'noOfSubmissions',
    headerName: 'Number of Submissions',
    flex: 2,
    width: 300,
    headerAlign: 'right',
    align: 'right',
  },
  {
    ...XGridDefaultColumn,
    field: 'orderAge',
    headerName: 'Order Age',
    flex: 1,
    width: 100,
  },
  {
    ...XGridDefaultColumn,
    field: 'noOfWeeks',
    headerName: 'Number of Weeks',
    flex: 2,
    width: 300,
    headerAlign: 'right',
    align: 'right',
  },
  {
    ...XGridDefaultColumn,
    field: 'yoe',
    headerName: 'Years of Experience',
    flex: 1,
    width: 300,
    headerAlign: 'right',
    align: 'right',
  },

  {
    ...XGridDefaultColumn,
    field: 'positions',
    headerName: 'Positions',
    flex: 1,
    width: 100,
    headerAlign: 'right',
    align: 'right',
  },
  {
    ...XGridDefaultColumn,
    field: 'notes',
    headerName: 'Notes',
    flex: 1,
    width: 100,
  },
  {
    ...XGridDefaultColumn,
    field: 'allowScreen',
    headerName: 'Allow Screen',
    flex: 1,
    width: 100,
  },
  {
    ...XGridDefaultColumn,
    field: 'skillSet',
    headerName: 'Skill Set',
    flex: 1,
    width: 100,
  },
];

export { unitColumns, orderUnitColumnsConfig };
