import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { ContractDetails } from './RateCardSections/ContractDetails';
import { DisciplineRateCard } from './RateCardSections/DisciplineRateCard';
import { DisciplineSpecialtyRates } from './RateCardSections/DisciplineSpecialtyRate';
import { RateElements } from './RateCardSections/RateElements';
import { OffContractRateElements } from './RateCardSections/OffContractRateElements';
import { Grid } from 'amn-ui-core';
import { useDispatch } from 'react-redux';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { ContractAffiliates } from './RateCardSections/ContactAffiliates';
import { useTranslation } from 'react-i18next';
import { GenericTabs, Orientations } from '@AMIEWEB/Common/Tabs/GenericTabs';
import { AddOffContractModal } from './RateCardSections/AddOffContract/AddOffContractModal';

const useStyles = makeStyles()({
  dsrContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch',
  },
  dsrElements: {
    '& .MuiContainer-root': {
      height: '100%',
    },
  },
});

export enum RateCardRef {
  CONTRACT_DETAILS,
  DISCIPLINE_SPECIALTY_RATES,
  RATE_ELEMENTS,
  OFF_CONTRACT,
  ADD_OFF_CONTRACT,
}

export const RateCardTab = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(facilityActions.getMiscellaneousData());
  }, []);

  const tabs = [
    {
      id: RateCardRef.CONTRACT_DETAILS,
      label: t('order.facility.rateCard.contractDetails.title'),
      component: (
        <Grid container spacing={2} direction="row" className={classes.dsrContainer}>
          <Grid item xs={6} className={classes.dsrElements}>
            <ContractDetails />
          </Grid>
          <Grid item xs={6} className={classes.dsrElements}>
            <ContractAffiliates />
          </Grid>
        </Grid>
      ),
    },
    {
      id: RateCardRef.DISCIPLINE_SPECIALTY_RATES,
      label: t('order.facility.rateCard.disciplineSpecialtyRates.title'),
      component: (
        <Grid container spacing={2} direction="row" className={classes.dsrContainer}>
          <Grid xs={4} item className={classes.dsrElements} style={{ padding: '12px 0px 0px 12px' }}>
            <DisciplineRateCard />
          </Grid>
          <Grid item xs={8} className={classes.dsrElements}>
            <DisciplineSpecialtyRates />
          </Grid>
        </Grid>
      ),
    },
    {
      id: RateCardRef.RATE_ELEMENTS,
      label: t('order.facility.rateCard.rateElements.title'),
      component: <RateElements />,
    },
    {
      id: RateCardRef.OFF_CONTRACT,
      label: t('order.facility.rateCard.offContractRateElement.title'),
      component: <OffContractRateElements />,
    },
    {
      id: RateCardRef.ADD_OFF_CONTRACT,
      label: t('order.facility.rateCard.addOffContractRate.title'),
      component: <AddOffContractModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />,
      onClick: () => setIsModalOpen(true),
    },
  ];

  return <GenericTabs tabs={tabs} title="Rate Card" orientation={Orientations.verticalList} dense />;
};
