import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { confirmationTemplateEditorActions } from './slice';
import { trackPromise } from 'react-promise-tracker';
import { httpSuccess } from 'app/services/serviceHelpers';
import { TemplateService } from 'app/services/TemplateService/template-service';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { PayloadAction } from '@reduxjs/toolkit';
import { selectUser } from 'oidc/user.selectors';
import { ITemplate } from './types';
import { selectSelectedTemplate } from './selectors';
import { Concatenate } from 'utils/string/string';
import { deriveTokensObj } from './helper';
import { convertToFormat } from 'app/helpers/dateHelper';

const TrackTemplatesWrapper = (fn, ...args) => trackPromise(fn(...args), 'document-templates-editor');
const TrackMergeTokensWrapper = (fn, ...args) => trackPromise(fn(...args), 'document-templates-merge-tokens');
const TrackSaveDraftWrapper = (fn, ...args) => trackPromise(fn(...args), 'document-templates-editor-save-draft');
const TrackPublishTemplateWrapper = (fn, ...args) => trackPromise(fn(...args), 'document-templates-editor-publish');
const TrackDeleteDraftWrapper = (fn, ...args) => trackPromise(fn(...args), 'document-templates-delete-draft');

export function* onInit() {
  yield all([call(requestTemplates), call(requestMergeTokenList)]);
}

export function* requestTemplates() {
  try {
    const templateService: TemplateService = yield getContext('templateService');
    const response = yield call(TrackTemplatesWrapper, templateService.getTemplates, true, 'Confirmation');
    if (httpSuccess(response.status)) {
      const formattedTemplates = response.data.map(x => {
        const updatedOn = convertToFormat(x.updatedOn, 'MM/DD/YYYY h:mm A', true);
        return x.isDraft ? { ...x, id: `${x.id}-draft`, updatedOn } : { ...x, updatedOn };
      });
      yield put(confirmationTemplateEditorActions.setTemplates(formattedTemplates as unknown as any));

      return formattedTemplates;
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestTemplates',
        area: 'src/store/reduc-store/template-editor/saga',
      },
    });
  }
}

export function* publishTemplate(action: PayloadAction<ITemplate>) {
  try {
    const templateService: TemplateService = yield getContext('templateService');
    const {
      userInfo: { employeeId, firstName, lastName },
    } = yield select(selectUser);
    const response = yield call(TrackPublishTemplateWrapper, templateService.publishTemplate, {
      id: action.payload.templateId === Number.MIN_VALUE ? 0 : action.payload.templateId,
      templateName: action.payload.templateName,
      templateText: action.payload.templateText,
      updatedBy: employeeId,
      updatedByName: Concatenate([firstName, lastName], ' '),
      category: 'Confirmation',
    });
    if (httpSuccess(response.status)) {
      yield put(confirmationTemplateEditorActions.toggleForceModalCloseFlag());
      const templates: ITemplate[] = yield call(requestTemplates);
      const currentTemplate = templates.find(x => x.templateId === response.data.templateId);
      /** Use forceReset to reset content in the editor */
      // @ts-ignore
      yield put(confirmationTemplateEditorActions.setSelectedTemplate({ ...currentTemplate, forceReset: true }));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'publishTemplate',
        area: 'src/store/reduc-store/template-editor/saga',
      },
    });
  }
}

export function* saveDraftTemplate(action: PayloadAction<ITemplate>) {
  try {
    const templateService: TemplateService = yield getContext('templateService');
    const {
      userInfo: { employeeId, firstName, lastName },
    } = yield select(selectUser);
    const response = yield call(TrackSaveDraftWrapper, templateService.saveDraftTemplate, {
      templateName: action.payload.templateName,
      templateText: action.payload.templateText,
      id: action.payload.templateId === Number.MIN_VALUE ? 0 : action.payload.templateId,
      updatedBy: employeeId,
      updatedByName: Concatenate([firstName, lastName], ' '),
    });
    if (httpSuccess(response.status)) {
      yield put(confirmationTemplateEditorActions.toggleForceModalCloseFlag());
      const templates: ITemplate[] = yield call(requestTemplates);
      const currentTemplate = templates.find(x => x.templateId === response.data.templateId);
      /** Use forceReset to reset content in the editor */
      // @ts-ignore
      yield put(confirmationTemplateEditorActions.setSelectedTemplate({ ...currentTemplate, forceReset: true }));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'saveDraftTemplate',
        area: 'src/store/reduc-store/template-editor/saga',
      },
    });
  }
}

export function* deleteDraftTemplate(action: PayloadAction<{ templateId: number }>) {
  try {
    const templateService: TemplateService = yield getContext('templateService');
    const response = yield call(
      TrackDeleteDraftWrapper,
      templateService.deleteDraftTemplate,
      action.payload.templateId,
    );
    if (httpSuccess(response.status)) {
      yield call(requestTemplates);
      const selctedTemplate = yield select(selectSelectedTemplate);
      if (selctedTemplate?.templateId === action.payload.templateId)
        yield put(confirmationTemplateEditorActions.setSelectedTemplate(null));

      yield put(confirmationTemplateEditorActions.toggleForceModalCloseFlag());
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'deleteDraftTemplate',
        area: 'src/store/reduc-store/template-editor/saga',
      },
    });
  }
}

export function* requestMergeTokenList() {
  try {
    const templateService: TemplateService = yield getContext('templateService');
    const response = yield call(TrackMergeTokensWrapper, templateService.getMergeTokensList, 'Confirmation');
    if (httpSuccess(response.status)) {
      const tokenObj = deriveTokensObj(response.data);
      yield put(confirmationTemplateEditorActions.setMergeTokenList(tokenObj));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestMergeTokenList',
        area: 'src/store/reduc-store/template-editor/saga',
      },
    });
  }
}

export function* templateEditorSaga() {
  yield takeLatest(confirmationTemplateEditorActions.onInitAction.type, onInit);
  yield takeLatest(confirmationTemplateEditorActions.getTemplates.type, requestTemplates);
  yield takeLatest(confirmationTemplateEditorActions.getMergeTokensList.type, requestMergeTokenList);
  yield takeLatest(confirmationTemplateEditorActions.publishTemplate.type, publishTemplate);
  yield takeLatest(confirmationTemplateEditorActions.saveDraftTemplate.type, saveDraftTemplate);
  yield takeLatest(confirmationTemplateEditorActions.deleteDraftTemplate.type, deleteDraftTemplate);
}
