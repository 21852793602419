import { CreatePlacementContext } from '@AMIEWEB/Placement/CreatePlacement/CreatePlacementWrapper';
import { Button, Grid } from 'amn-ui-core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectNewPlacementChoice } from 'store/redux-store/new-placement/selectors';
import { placementStatusAction } from 'store/redux-store/placement-status/slice';

export const CreateActions = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { newPlacementId } = useSelector(selectNewPlacementChoice);

  const { closeCreateModal } = useContext(CreatePlacementContext);
  const dispatch = useDispatch();

  const validPlacementCreated = React.useMemo(() => newPlacementId !== null && newPlacementId !== -1, [newPlacementId]);

  const navigateToNewPlacement = React.useCallback(() => {
    dispatch(placementStatusAction.reset());
    closeCreateModal();
    history.push({ pathname: `/placement/${newPlacementId}`, state: { navigateBack: true } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPlacementId, closeCreateModal]);

  return (
    <Grid container justifyContent="end" spacing={4}>
      <Grid item>
        <Button variant="contained" color="tertiary" size="large" onClick={closeCreateModal}>
          {validPlacementCreated ? t('global.button.close') : t('global.button.cancel')}
        </Button>
      </Grid>
      {validPlacementCreated ? (
        <Grid item>
          <Button variant="contained" color="primary" size="large" onClick={navigateToNewPlacement}>
            {t('Go to Placement')}
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};
