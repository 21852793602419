import React, { useEffect, useState } from 'react';
import { InputAdornment, TextField, Autocomplete } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import { RequirementTypes, selectAddUnverifiedCredType } from '../../Profile/CandidateDetails.selector';
import SearchIcon from '@mui/icons-material/Search';

interface LicenseData {
  label: string;
  id: string;
}

const useStyles: any = makeStyles()({
  autoComplete: {
    marginTop: 12,
  },
  textInput: {
    marginLeft: 8,
    height: 14,
  },
});

export const CredentialsSearchByCategory = props => {
  const { classes } = useStyles();
  const requirementTypeselection = useSelector(RequirementTypes);
  const addUnverifiedCredType = useSelector(selectAddUnverifiedCredType);
  const [inputValue, setInputValue] = useState<any>(props.selectedLicense);
  const [licenseOptions, setLicenseOptions] = useState<any[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.selectedLicense) setInputValue(props.selectedLicense);
  }, [props.selectedLicense]);

  useEffect(() => {
    const temp: any[] = [];
    var tempData;
    requirementTypeselection?.map(x => {
      if (addUnverifiedCredType == 'Licenses') {
        if (x.abbreviation == 'RN') tempData = x;
        if (x.category == 'Licenses' && x.abbreviation !== 'RN') {
          temp.push({
            id: x.requirementTypeID,
            label: `${x.abbreviation} - ${x.credentialName}`,
            abbr: x.abbreviation,
            credName: x.credentialName,
          });
        }
      }
      if (addUnverifiedCredType == 'Certifications') {
        if (x.category == 'Certifications') {
          temp.push({
            id: x.requirementTypeID,
            label: `${x.abbreviation} - ${x.credentialName}`,
            abbr: x.abbreviation,
            credName: x.credentialName,
          });
        }
      }
    });
    temp.sort((a, b) => a.label.localeCompare(b.label));
    if (addUnverifiedCredType == 'Licenses') {
      if (tempData) {
        temp.unshift({
          id: tempData.requirementTypeID,
          label: `${tempData.abbreviation} - ${tempData.credentialName}`,
          abbr: tempData.abbreviation,
          credName: tempData.credentialName,
        });
      }
    }
    setLicenseOptions(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requirementTypeselection]);

  const onChange = (event, newInputValue) => {
    props.changeAddLicenseProcessStep?.(newInputValue);
    props.onChange?.(newInputValue);
    setOpen(false);
  };

  const onInputChange = (event, newInputValue: string) => {
    if (newInputValue !== '') setOpen(true);
    else setOpen(false);
    setInputValue(newInputValue);
  };

  const filterOptions = (options, state) => {
    const newOptions: any = [];
    options.forEach(element => {
      if (element.label.toLowerCase().includes(state.inputValue.toLowerCase())) newOptions.push(element);
    });
    if (newOptions[0]?.abbr !== 'RN' && addUnverifiedCredType == 'Licenses') {
      newOptions.unshift({
        id: 147,
        label: `RN - Registered Nurse Licensure`,
        abbr: `RN`,
        credName: `RN - Registered Nurse Licensure`,
      });
    }
    return newOptions;
  };

  return (
    <Autocomplete
      open={open}
      className={classes.autoComplete}
      renderInput={params => (
        <TextField
          autoFocus
          placeholder={`Search`}
          {...params}
          InputProps={{
            ...params.InputProps,
            classes: { input: classes.textInput },
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon style={{ color: '#A8A8A8', marginLeft: 29, marginRight: -29 }} />
              </InputAdornment>
            ),
          }}
        />
      )}
      onInputChange={onInputChange}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      getOptionLabel={(option: LicenseData) => option.label}
      options={licenseOptions || []}
      filterOptions={filterOptions}
      value={props.selectedLicense ? { label: inputValue } : null}
      inputValue={inputValue}
    ></Autocomplete>
  );
};
