// FIXME: MOVE THIS FILE OUT OF THE SEARCH DIRECTORY
import { CredentialsGridType } from '../CredentialsGrid';

/**
 * String values for categories and sub categories from the APIs
 */
export enum CredentialsAPICategories {
  licensure = 'Licenses',
  certificationsLifeSupportCertifications = 'Life Support Certification',
  certifications = 'Certifications',
  certificationsOtherCertifications = 'Other Certifications',
  clinicalQualificationsAssessments = 'Assessment',
  clinicalQualifications = 'Clinical Qualifications',
  clinicalQualificationsSkillsChecklists = 'Skills Checklist',
  humanResouces = 'Human Resources',
  humanResourcesBackgroundChecks = 'Background Checks',
  humanResourcesDrugScreens = 'Drug Screens',
  humanResourcesIdentifications = 'Identifications',
  humanResourcesForms = 'Forms',
  educationAndOnlineForms = 'Education & Online Exams',
  employeeHealth = 'Employee Health',
  employeeHealthLabResults = 'Lab Results',
  employeeHealthMedicalRecords = 'Medical Records',
  employeeHealthImmunizations = 'Immunizations & Titers',
  miscellaneous = 'Miscellaneous',
  miscellaneousFacilityTrackingSheet = 'Facility Tracking Sheet',
  miscellaneousClientSpecificContractEnclosures = 'Client Specific Contract Enclosure(s)',
  miscellaneousManagerReviewSensitiveAccount = 'Manager Review-Sensitive Account',
  miscellaneousPendingMoleculeDevelopment = 'Pending Molecule Development',
}

/**
 * Converts grid enum value to display string for titles
 * @param gridType credential grid type
 * @returns display string for grid type
 */
export const gridTypeToDisplayString = (gridType: CredentialsGridType) => {
  switch (gridType) {
    case CredentialsGridType.licensure:
      return 'candidate.credentials.types.licensure';
    case CredentialsGridType.clinicalQualificationsSkillsChecklists:
    case CredentialsGridType.clinicalQualifications:
    case CredentialsGridType.clinicalQualificationsAssessments:
      return 'candidate.credentials.types.clinicalQualifications';
    case CredentialsGridType.certifications:
    case CredentialsGridType.certificationsOtherCertifications:
    case CredentialsGridType.certificationsLifeSupportCertifications:
      return 'candidate.credentials.types.certifications';
    case CredentialsGridType.humanResourcesBackgroundChecks:
    case CredentialsGridType.humanResourcesDrugScreens:
    case CredentialsGridType.humanResourcesIdentifications:
    case CredentialsGridType.humanResourcesForms:
      return 'candidate.credentials.types.humanResources';
    case CredentialsGridType.educationAndOnlineForms:
      return 'candidate.credentials.types.educationAndOnlineForms';
    case CredentialsGridType.employeeHealthImmunizations:
    case CredentialsGridType.employeeHealthLabResults:
    case CredentialsGridType.employeeHealthMedicalRecords:
      return 'candidate.credentials.types.employeeHealth';
    case CredentialsGridType.miscellaneousFacilityTrackingSheet:
    case CredentialsGridType.miscellaneousClientSpecificContractEnclosures:
    case CredentialsGridType.miscellaneousPendingMoleculeDevelopment:
    case CredentialsGridType.miscellaneousManagerReviewSensitiveAccount:
      return 'candidate.credentials.types.miscellaneous';
    default:
      return '';
  }
};

/**
 * Used to convert a grid type to an object the API can understand
 * @param selection selected grid type
 * @param urlEncode whether to URL encode the strong
 * @returns string that the API accepts
 */
export const gridTypeToCategoryString = (selection: CredentialsGridType | null, urlEncode: boolean = false) => {
  switch (selection) {
    case CredentialsGridType.licensure:
      return CredentialsAPICategories.licensure;
    case CredentialsGridType.clinicalQualificationsSkillsChecklists:
    case CredentialsGridType.clinicalQualifications:
    case CredentialsGridType.clinicalQualificationsAssessments:
      return CredentialsAPICategories.clinicalQualifications;
    case CredentialsGridType.certifications:
    case CredentialsGridType.certificationsOtherCertifications:
    case CredentialsGridType.certificationsLifeSupportCertifications:
      return CredentialsAPICategories.certifications;
    case CredentialsGridType.humanResourcesBackgroundChecks:
    case CredentialsGridType.humanResourcesDrugScreens:
    case CredentialsGridType.humanResourcesIdentifications:
    case CredentialsGridType.humanResourcesForms:
      return CredentialsAPICategories.humanResouces;
    case CredentialsGridType.educationAndOnlineForms:
      return !urlEncode
        ? CredentialsAPICategories.educationAndOnlineForms
        : CredentialsAPICategories.educationAndOnlineForms.replace('&', '%26');
    case CredentialsGridType.employeeHealthImmunizations:
    case CredentialsGridType.employeeHealthLabResults:
    case CredentialsGridType.employeeHealthMedicalRecords:
      return CredentialsAPICategories.employeeHealth;
    case CredentialsGridType.miscellaneousClientSpecificContractEnclosures:
    case CredentialsGridType.miscellaneousFacilityTrackingSheet:
    case CredentialsGridType.miscellaneousManagerReviewSensitiveAccount:
    case CredentialsGridType.miscellaneousPendingMoleculeDevelopment:
      return CredentialsAPICategories.miscellaneous;
    default:
      return '';
  }
};

/**
 * Used to convert a grid type to an object the API can understand
 * @param selection selected grid type
 * @param urlEncode whether to URL encode the strong
 * @returns string that the API accepts
 */
export const gridTypeToString = (selection: CredentialsGridType | null, urlEncode: boolean = false) => {
  switch (selection) {
    case CredentialsGridType.licensure:
      return CredentialsAPICategories.licensure;
    case CredentialsGridType.clinicalQualificationsSkillsChecklists:
      return CredentialsAPICategories.clinicalQualificationsSkillsChecklists;
    case CredentialsGridType.clinicalQualifications:
      return CredentialsAPICategories.clinicalQualifications;
    case CredentialsGridType.clinicalQualificationsAssessments:
      return CredentialsAPICategories.clinicalQualificationsAssessments;
    case CredentialsGridType.certifications:
      return CredentialsAPICategories.certifications;
    case CredentialsGridType.certificationsOtherCertifications:
      return CredentialsAPICategories.certificationsOtherCertifications;
    case CredentialsGridType.certificationsLifeSupportCertifications:
      return CredentialsAPICategories.certificationsLifeSupportCertifications;
    case CredentialsGridType.humanResourcesBackgroundChecks:
      return CredentialsAPICategories.humanResourcesBackgroundChecks;
    case CredentialsGridType.humanResourcesDrugScreens:
      return CredentialsAPICategories.humanResourcesDrugScreens;
    case CredentialsGridType.humanResourcesIdentifications:
      return CredentialsAPICategories.humanResourcesIdentifications;
    case CredentialsGridType.humanResourcesForms:
      return CredentialsAPICategories.humanResourcesForms;
    case CredentialsGridType.educationAndOnlineForms:
      return !urlEncode
        ? CredentialsAPICategories.educationAndOnlineForms
        : CredentialsAPICategories.educationAndOnlineForms.replace('&', '%26');
    case CredentialsGridType.employeeHealthImmunizations:
      return CredentialsAPICategories.employeeHealthImmunizations;
    case CredentialsGridType.employeeHealthLabResults:
      return CredentialsAPICategories.employeeHealthLabResults;
    case CredentialsGridType.employeeHealthMedicalRecords:
      return CredentialsAPICategories.employeeHealthMedicalRecords;
    case CredentialsGridType.miscellaneousClientSpecificContractEnclosures:
      return CredentialsAPICategories.miscellaneousClientSpecificContractEnclosures;
    case CredentialsGridType.miscellaneousFacilityTrackingSheet:
      return CredentialsAPICategories.miscellaneousFacilityTrackingSheet;
    case CredentialsGridType.miscellaneousManagerReviewSensitiveAccount:
      return CredentialsAPICategories.miscellaneousManagerReviewSensitiveAccount;
    case CredentialsGridType.miscellaneousPendingMoleculeDevelopment:
      return CredentialsAPICategories.miscellaneousPendingMoleculeDevelopment;
    default:
      return '';
  }
};

/**
 * convert a grid type to the same naming convension as the stored credential object
 * E.g., `credentails[CredentialsGridType.licensure] => 'licenses'`
 * @param gridType
 * @returns string of the same object name stored in redux
 */
export const gridTypeToObject = (gridType: CredentialsGridType) => {
  switch (gridType) {
    case CredentialsGridType.licensure:
      return 'licenses';
    case CredentialsGridType.clinicalQualificationsSkillsChecklists:
    case CredentialsGridType.clinicalQualifications:
    case CredentialsGridType.clinicalQualificationsAssessments:
      return 'clinicalQualifications';
    case CredentialsGridType.certifications:
    case CredentialsGridType.certificationsOtherCertifications:
    case CredentialsGridType.certificationsLifeSupportCertifications:
      return 'certifications';
    case CredentialsGridType.humanResourcesBackgroundChecks:
    case CredentialsGridType.humanResourcesDrugScreens:
    case CredentialsGridType.humanResourcesIdentifications:
    case CredentialsGridType.humanResourcesForms:
      return 'humanResourcesCredentials';
    case CredentialsGridType.educationAndOnlineForms:
      return 'educationOnlineForms';
    case CredentialsGridType.employeeHealthImmunizations:
    case CredentialsGridType.employeeHealthLabResults:
    case CredentialsGridType.employeeHealthMedicalRecords:
      return 'employeeHealth';
    case CredentialsGridType.miscellaneousFacilityTrackingSheet:
    case CredentialsGridType.miscellaneousClientSpecificContractEnclosures:
    case CredentialsGridType.miscellaneousPendingMoleculeDevelopment:
    case CredentialsGridType.miscellaneousManagerReviewSensitiveAccount:
      return 'miscellaneous';
    default:
      return '';
  }
};

/**
 * Convert the API string name to enum value. SubCategory takes priority over Category
 * @param category category string
 * @param subCategory sub category string
 * @returns enum value for category/subcategory
 */
export const stringToGridType = (category, subCategory) => {
  switch (subCategory || category) {
    case CredentialsAPICategories.licensure:
      return CredentialsGridType.licensure;
    case CredentialsAPICategories.certificationsLifeSupportCertifications:
      return CredentialsGridType.certificationsLifeSupportCertifications;
    case CredentialsAPICategories.certifications:
      return CredentialsGridType.certifications;
    case CredentialsAPICategories.certificationsOtherCertifications:
      return CredentialsGridType.certificationsOtherCertifications;
    case CredentialsAPICategories.clinicalQualificationsAssessments:
      return CredentialsGridType.clinicalQualificationsAssessments;
    case CredentialsAPICategories.clinicalQualifications:
      return CredentialsGridType.clinicalQualifications;
    case CredentialsAPICategories.clinicalQualificationsSkillsChecklists:
      return CredentialsGridType.clinicalQualificationsSkillsChecklists;
    case CredentialsAPICategories.humanResourcesBackgroundChecks:
      return CredentialsGridType.humanResourcesBackgroundChecks;
    case CredentialsAPICategories.humanResourcesDrugScreens:
      return CredentialsGridType.humanResourcesDrugScreens;
    case CredentialsAPICategories.humanResourcesIdentifications:
      return CredentialsGridType.humanResourcesIdentifications;
    case CredentialsAPICategories.humanResourcesForms:
      return CredentialsGridType.humanResourcesForms;
    case CredentialsAPICategories.educationAndOnlineForms:
      return CredentialsGridType.educationAndOnlineForms;

    case CredentialsAPICategories.employeeHealthImmunizations:
      return CredentialsGridType.employeeHealthImmunizations;
    case CredentialsAPICategories.employeeHealthLabResults:
      return CredentialsGridType.employeeHealthLabResults;
    case CredentialsAPICategories.employeeHealthMedicalRecords:
      return CredentialsGridType.employeeHealthMedicalRecords;

    case CredentialsAPICategories.miscellaneousFacilityTrackingSheet:
      return CredentialsGridType.miscellaneousFacilityTrackingSheet;
    case CredentialsAPICategories.miscellaneousClientSpecificContractEnclosures:
      return CredentialsGridType.miscellaneousClientSpecificContractEnclosures;
    case CredentialsAPICategories.miscellaneousManagerReviewSensitiveAccount:
      return CredentialsGridType.miscellaneousManagerReviewSensitiveAccount;
    case CredentialsAPICategories.miscellaneousPendingMoleculeDevelopment:
      return CredentialsGridType.miscellaneousPendingMoleculeDevelopment;
    default:
      return -1;
  }
};

/**
 * Convert the API category/subcategory string name to parent enum value. Subcategory takes priority over Category
 * @param category
 * @param subCategory
 * @returns enum value for category/subcategory parent
 */
export const stringToGridTypeParent = (category, subCategory) => {
  switch (subCategory || category) {
    case CredentialsAPICategories.licensure:
      return CredentialsGridType.licensure;
    case CredentialsAPICategories.certificationsLifeSupportCertifications:
    case CredentialsAPICategories.certifications:
    case CredentialsAPICategories.certificationsOtherCertifications:
      return CredentialsGridType.certificationsParent;
    case CredentialsAPICategories.clinicalQualificationsAssessments:
    case CredentialsAPICategories.clinicalQualifications:
    case CredentialsAPICategories.clinicalQualificationsSkillsChecklists:
      return CredentialsGridType.clinicalQualificationsParent;
    case CredentialsAPICategories.humanResourcesBackgroundChecks:
    case CredentialsAPICategories.humanResourcesDrugScreens:
    case CredentialsAPICategories.humanResourcesIdentifications:
    case CredentialsAPICategories.humanResourcesForms:
      return CredentialsGridType.humanResourcesParent;
    case CredentialsAPICategories.educationAndOnlineForms:
      return CredentialsGridType.educationAndOnlineForms;

    case CredentialsAPICategories.employeeHealthImmunizations:
    case CredentialsAPICategories.employeeHealthLabResults:
    case CredentialsAPICategories.employeeHealthMedicalRecords:
      return CredentialsGridType.employeeHealthParent;

    case CredentialsAPICategories.miscellaneousFacilityTrackingSheet:
    case CredentialsAPICategories.miscellaneousClientSpecificContractEnclosures:
    case CredentialsAPICategories.miscellaneousManagerReviewSensitiveAccount:
    case CredentialsAPICategories.miscellaneousPendingMoleculeDevelopment:
      return CredentialsGridType.miscellaneous;
    default:
      return -1;
  }
};

/**
 * Convert API name to display name
 * @param category API category string
 * @returns display string
 */
export const categoryNameSwap = category => {
  switch (category) {
    case CredentialsAPICategories.licensure:
      return 'candidate.credentials.types.licensure';
    case CredentialsAPICategories.educationAndOnlineForms:
      return 'candidate.credentials.types.educationAndOnlineForms';
    case CredentialsAPICategories.clinicalQualificationsAssessments:
      return 'candidate.credentials.types.assessments';
    case CredentialsAPICategories.clinicalQualificationsSkillsChecklists:
      return 'candidate.credentials.types.skillsChecklists';
    case CredentialsAPICategories.certificationsLifeSupportCertifications:
      return 'candidate.credentials.types.lifeSupportCertifications';
    default:
      return category;
  }
};

/**
 * Determines if the given category/subcategory is a subcategory
 * @param category API string value
 * @param subCagetory API string value
 * @returns boolean if the given category/subcategory string is a subcategory
 */
export const isSubCategory = (category: string, subCagetory: string, gridType?: CredentialsGridType) => {
  const type = gridType ?? stringToGridType(category, subCagetory);
  switch (type) {
    case CredentialsGridType.clinicalQualifications:
    case CredentialsGridType.clinicalQualificationsSkillsChecklists:
    case CredentialsGridType.clinicalQualificationsAssessments:
    case CredentialsGridType.certifications:
    case CredentialsGridType.certificationsOtherCertifications:
    case CredentialsGridType.certificationsLifeSupportCertifications:
    case CredentialsGridType.miscellaneousFacilityTrackingSheet:
    case CredentialsGridType.miscellaneousClientSpecificContractEnclosures:
    case CredentialsGridType.miscellaneousManagerReviewSensitiveAccount:
    case CredentialsGridType.miscellaneousPendingMoleculeDevelopment:
    case CredentialsGridType.humanResourcesBackgroundChecks:
    case CredentialsGridType.humanResourcesDrugScreens:
    case CredentialsGridType.humanResourcesForms:
    case CredentialsGridType.humanResourcesIdentifications:
    case CredentialsGridType.employeeHealthImmunizations:
    case CredentialsGridType.employeeHealthLabResults:
    case CredentialsGridType.employeeHealthMedicalRecords:
      return true;
    default:
      return false;
  }
};
