/* eslint-disable i18next/no-literal-string */
import { Select, Input, InputLabel, FormControl } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';

const useStyles = makeStyles()({
  root: {
    maxHeight: '62px',
    paddingRight: '0px !important',
  },
  inputLabel: {
    transform: 'translate(2px, 2px) scale(0.75) !important',
  },
});

/**
 * Multiple Select option for grid column filters
 * @param props FilterInputValueProps
 */
export const XGridFilterSelect = props => {
  const { item, options } = props;
  const { classes } = useStyles();

  const onChange = event => {
    const options: any[] = event.target.options;
    const selectedValues: any[] = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    props.applyValue({ ...item, value: selectedValues });
  };

  return (
    <FormControl size="small" variant={'filled'}>
      {
        <InputLabel
          id="xgrid-filter-multiselect-input-label"
          variant={'filled'}
          classes={{ root: classes.inputLabel }}
          shrink={true}
        >
          {'Value'}
        </InputLabel>
      }
      <Select
        native
        multiple
        classes={{ root: classes.root }}
        value={item?.value ?? []}
        input={<Input id="xgrid-filter-multiselect" />}
        onChange={onChange}
      >
        {options.map(item => (
          <option value={item}>{item}</option>
        ))}
      </Select>
    </FormControl>
  );
};
