/* eslint-disable tss-unused-classes/unused-classes */
import React, { useEffect, useState } from 'react';
import { Grid, Typography, createSvgIcon } from 'amn-ui-core';
import styledComponent from 'styled-components';
import { colors } from 'styles/styleVariables';
import { IRequirementCounts, requirementType } from 'app/models/Placement/PlacementSummary';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

interface StatusTitleProps {
  title: string;
  titleAddOns?: string | JSX.Element;
  counts?: IRequirementCounts;
  filter?: requirementType[];
  handleFilterChange?: Function;
  isPrimary?: boolean;
  excludeFromFilter?: boolean;
}

interface IconSpecs {
  selected: boolean;
  display: boolean;
}

const GreenCheck = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <defs>
      <style>{'.green-check-circle{fill:#13a007;}'}</style>
    </defs>
    <g transform="translate(-2 -2)">
      <path
        className="green-check-circle"
        d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM10,17,5,12l1.41-1.41L10,14.17l7.59-7.59L19,8Z"
      />
    </g>
  </svg>,
  'GreenCheck',
);

const RedWarning = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <defs>
      <style>{'.red-check-a{fill:#fff;}.red-check{fill:#ab0012;}'}</style>
    </defs>
    <g transform="translate(-0.145 -0.168)">
      <g transform="translate(17.903 10.169) rotate(135)">
        <rect className="red-check-a" width="2.189" height="11.167" transform="translate(4.488 0)" />
        <rect className="red-check-a" width="11.167" height="2.189" transform="translate(0 4.488)" />
      </g>
      <path
        className="red-check"
        d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm1,15H11V15h2Zm0-4H11V7h2Z"
        transform="translate(-1.855 -1.832)"
      />
    </g>
  </svg>,
  'RedWarning',
);

const OrangeWarning = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20.001" viewBox="0 0 20 20.001">
    <defs>
      <style>{'.orange-check{fill:#f28d17;}'}</style>
    </defs>
    <path
      className="orange-check"
      d="M-2917-274a10.012,10.012,0,0,1-10-10,10.011,10.011,0,0,1,10-10,10.011,10.011,0,0,1,10,10A10.012,10.012,0,0,1-2917-274Zm0-16.667h0L-2923.333-280h12.667L-2917-290.667Zm4.335,9.544h-8.671l4.335-7.3,4.335,7.3Zm-4.911-1.684v1.123h1.152v-1.123Zm0-3.369h0v2.246h1.152v-2.246Z"
      transform="translate(2927 294)"
    />
  </svg>,
  'OrangeWarning',
);

const useStyles = makeStyles()(theme => ({
  secondatyTextStyle: {
    marginLeft: 'auto',
  },
  icon: {
    width: '20px',
    height: '20px',
  },
  disabled: {
    '& .green-check-circle': {
      fill: '#C1C1C2',
    },
    '& .orange-check': {
      fill: '#C1C1C2',
    },
    '& .red-check': {
      fill: '#C1C1C2',
    },
  },
}));

const defaultIconSpecs = { selected: false, display: false };

export const StatusTitle: React.FC<StatusTitleProps> = ({
  title,
  titleAddOns = null,
  counts,
  filter = [],
  handleFilterChange,
  isPrimary = false,
  excludeFromFilter = false,
}) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();

  const [{ pass, fail, risk }, setIconState] = useState<{
    pass: IconSpecs;
    fail: IconSpecs;
    risk: IconSpecs;
  }>({
    pass: { ...defaultIconSpecs },
    fail: { ...defaultIconSpecs },
    risk: { ...defaultIconSpecs },
  });

  useEffect(() => {
    if (counts) {
      const isPassIconSelected = filter.includes(requirementType.pass);
      const isFailIconSelected = filter.includes(requirementType.fail);
      const isRiskIconSelected = filter.includes(requirementType.risk);

      setIconState({
        pass: {
          selected: isPassIconSelected,
          display: counts.passed > 0, //|| (!counts.passed && isPassIconSelected),
        },
        fail: {
          selected: isFailIconSelected,
          display: counts.failed > 0, //|| (!counts.failed && isFailIconSelected),
        },
        risk: {
          selected: isRiskIconSelected,
          display: (counts.risks || 0) > 0, //|| (!counts.risks && isRiskIconSelected),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filter), JSON.stringify(counts)]);

  const handleClick = (type: requirementType, state: boolean) => {
    if (excludeFromFilter) return;
    const newFilter = [...filter];
    state
      ? newFilter.push(type)
      : newFilter.splice(
          newFilter.findIndex(item => item === type),
          1,
        );
    handleFilterChange?.(newFilter);
  };

  return (
    <Grid container spacing={2} wrap="nowrap" alignItems="center">
      <Grid item>
        <Title variant="h5">
          {title}
          {titleAddOns}
        </Title>
      </Grid>
      {counts && (
        <>
          {pass.display && (
            <CountItem
              icon={<GreenCheck classes={{ root: cx(classes.icon, { [classes.disabled]: excludeFromFilter }) }} />}
              count={`${counts.passed}/${counts.aggregate}`}
              selected={pass.selected}
              onClick={state => handleClick(requirementType.pass, state)}
            />
          )}
          {fail.display && (
            <CountItem
              icon={<RedWarning classes={{ root: cx(classes.icon, { [classes.disabled]: excludeFromFilter }) }} />}
              count={`${counts.failed}/${counts.aggregate}`}
              selected={fail.selected}
              onClick={state => handleClick(requirementType.fail, state)}
            />
          )}
          {risk.display && (
            <CountItem
              icon={<OrangeWarning classes={{ root: cx(classes.icon, { [classes.disabled]: excludeFromFilter }) }} />}
              count={counts.risks}
              selected={risk.selected}
              onClick={state => handleClick(requirementType.risk, state)}
            />
          )}
        </>
      )}
      {isPrimary && (
        <Grid item classes={{ root: classes.secondatyTextStyle }}>
          <SecondaryTitle>{t('Primary Skill Set')}</SecondaryTitle>
        </Grid>
      )}
    </Grid>
  );
};

const CountItem = ({ icon, count, selected = false, onClick = state => {} }) => {
  return (
    <CountItemWrapper
      //@ts-ignore
      selected={selected}
      item
      onClick={event => {
        onClick(!selected);
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      {/* @ts-ignore */}
      <CountItemWrapperItem selected={selected} item container spacing={1} wrap="nowrap" alignItems="center">
        <CounterIconItem item>{icon}</CounterIconItem>
        <CounterItem item>
          <Typography>{count}</Typography>
        </CounterItem>
      </CountItemWrapperItem>
    </CountItemWrapper>
  );
};

const CounterIconItem = styledComponent(Grid)`
  padding-top: 4px !important;
`;

const CounterItem = styledComponent(Grid)`
  padding-top: 3px !important;
`;

const CountItemWrapperItem = styledComponent(Grid)`
  border-radius: 4px;
  border: ${props => (props['selected'] ? '1px solid #0069af !important' : undefined)};
  margin-right: 3px;
  padding-right: 6px;
  padding-bottom: 1px;
`;

const CountItemWrapper = styledComponent(Grid)`
  margin-right: 3px;
`;

const Title = styledComponent(Typography)`
  color: ${colors.text};
  font-size: 16px;
  font-weight: 500;
`;

export const SecondaryTitle = styledComponent(Typography)`
  color: ${colors.text};
  font-size: 14px;
  font-weight: 500;
  padding-right: 10px;
`;

export const OperationTitle = styledComponent(Typography)`
  color: ${colors.text};
  font-size: 12px;
  font-weight: 500;
  padding-top: 5px;
`;
