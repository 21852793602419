import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  candidateDetailsSelection,
  CredentialingLookups as CredentialingLookupsSelection,
} from '../../../../Profile/CandidateDetails.selector';
import { Grid } from 'amn-ui-core';
import { RowSection } from '../../Common/RowSection';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import moment from 'moment';
import { initialFluTooltipFields, IFluTooltipField, FluVaccinationStatus } from './ITooltipField';
import { useCovidCardStyes } from './styles';
import { LookupFields } from 'app/models/Credentialing/enums/Lookup';
import { RequirementType } from 'app/models/Credentialing/enums/RequirementType';
import { orderBy } from 'lodash';

const FluStatusCard = props => {
  const { fluStatusInformation } = props;
  const { t } = useTranslation();
  const { classes } = useCovidCardStyes();
  const [showTooltip, setShowTooltip] = useState<boolean>(true);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const lookupselection = useSelector(CredentialingLookupsSelection);
  const [showHalfwayProgress, setShowHalfwayProgress] = useState<boolean>(false);
  const [showCheckmark, setShowCheckmark] = useState<boolean>(false);
  const [tooltipInfo, setTooltipInfo] = useState<IFluTooltipField>(initialFluTooltipFields);
  const [vaccinationStatus, setVaccinationStatus] = useState<string>('');


  const getFluVaccinationTooltip = () => {
    const data = tooltipInfo;
    return (
      <Grid container direction="column">
        <Grid item>
          <Grid container direction="column" style={{ paddingBottom: '5px' }}>
            {candidateDetails?.covidVaccinationstatus && fluStatusInformation?.length < 1 ? (
              <>
                <Grid item className={classes.tooltipRowContent}>{t('candidate.qualificationsCard.vaccinationUnavailableTooltipLine1')}</Grid>
                <Grid item className={classes.tooltipRowContent}>{t('candidate.qualificationsCard.vaccinationUnavailableTooltipLine2')}</Grid>
              </>
            ) : (
              <Grid item className={classes.tooltipRowContent}>{vaccinationStatus}</Grid>
            )}
            {(vaccinationStatus === FluVaccinationStatus.Vaccinated 
              || vaccinationStatus === FluVaccinationStatus.VaccinationExpired) 
              && (data.expirationDate || data.type || data.dateCompleted || data.tsceFormID) && (
              <>
                <Grid item className={classes.tooltipRowContent}>
                  <span style={{ fontWeight: 'bold' }}>{`Immunization Type: `}</span>
                  {data.type ? data.type : `--`}
                </Grid>
                <Grid item className={classes.tooltipRowContent}>
                  <span style={{ fontWeight: 'bold' }}>{`Completion Date: `}</span>
                  {data.dateCompleted ? moment(data.dateCompleted).format('MM/DD/YYYY') : `--`}
                </Grid>
                <Grid item className={classes.tooltipRowContent}>
                  <span style={{ fontWeight: 'bold' }}>{`Expiration Date: `}</span>
                  {data.expirationDate ? moment(data.expirationDate).format('MM/DD/YYYY') : `--`}
                </Grid>
                <Grid item className={classes.tooltipRowContent}>
                  <span style={{ fontWeight: 'bold' }}>{`EForm ID: `}</span>
                  {data.tsceFormID ? data.tsceFormID : `--`}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    const firstReport = orderBy(
      fluStatusInformation,
      ['dateCompleted'],
      ['desc'],
    )[0];

    if ((fluStatusInformation?.length < 1) && !candidateDetails?.covidVaccinationstatus) {
      setShowTooltip(false);
    }

    const type = lookupselection
      ?.filter(e => e.requirementTypeID === RequirementType.FLUVACCINATION)
      ?.find(item =>  item.lookUpField === LookupFields.TypeID)
      ?.lookups?.find(e => e.value === firstReport?.typeID);

    const expirationDate = getExpirationDate(firstReport?.dateCompleted);

    const data = {
        type: type?.description,
        dateCompleted: firstReport?.dateCompleted,
        expirationDate: expirationDate,
        tsceFormID: firstReport?.tsceFormID,
    };
    setTooltipInfo(data);
    getVaccinationStatus(data);
  }, [candidateDetails?.covidVaccinationstatus, fluStatusInformation, lookupselection]);

  const getVaccinationStatus = (data) => {
    if (data.dateCompleted) {
      const dateCompleted = moment(data.dateCompleted);
      if (moment().isSameOrAfter(moment().set({ 'month': 7, 'date': 1 })) 
      && moment().isSameOrBefore(moment().set({ 'month': 7, 'date': 31 })) 
      && dateCompleted.isSameOrBefore(moment().set({ 'month': 3, 'date': 1 })))
      {
        setVaccinationStatus(FluVaccinationStatus.VaccinationExpiring);
        setShowHalfwayProgress(true);
      } else if (
        (moment().isSameOrAfter(moment().set({ 'month': 8, 'date': 1 })) 
        && moment().isSameOrBefore(moment().set({ 'month': 11, 'date': 31 })) 
        && dateCompleted.isSameOrAfter(moment().set({ 'month': 8, 'date': 1 })))
        || (moment().isSameOrAfter(moment().set({ 'month': 0, 'date': 1 }))
        && moment().isSameOrBefore(moment().set({ 'month': 6, 'date': 31 })) 
        && dateCompleted.isSameOrAfter(moment().set({ 'year': (moment().year() - 1), 'month': 8, 'date': 1 }))
        && dateCompleted.isSameOrBefore(moment().set({ 'month': 3, 'date': 1 })))) 
      {
        setVaccinationStatus(FluVaccinationStatus.Vaccinated);
        setShowCheckmark(true);
      } else if (
        (moment().isSameOrBefore(moment().set({ 'month': 11, 'date': 31 }))
        && moment().isSameOrAfter(moment().set({ 'month': 8, 'date': 1 }))
        && dateCompleted.isSameOrBefore(moment().set({ 'month': 3, 'date': 1 })))
      || (moment().isSameOrAfter(moment().set({ 'month': 0, 'date': 1 }))
        && moment().isSameOrBefore(moment().set({ 'month': 6, 'date': 31 })) 
        && dateCompleted.isSameOrBefore(moment().set({ 'year': (moment().year() - 1), 'month': 7, 'date': 31 }))))
      {
        setVaccinationStatus(FluVaccinationStatus.VaccinationExpired);
        setShowHalfwayProgress(true);
      } else if (dateCompleted.isSameOrAfter(moment().set({ 'month': 3, 'date': 1 }))) {
        setVaccinationStatus(FluVaccinationStatus.Unvaccinated);
        setShowCheckmark(true);
        setShowTooltip(false);
      }
    } else {
      setVaccinationStatus(FluVaccinationStatus.Unvaccinated);
      setShowHalfwayProgress(true);
    }
  };

  const getExpirationDate = date => {
    if (date) {
      const dateCompleted = moment(date);
      if (dateCompleted.isSameOrAfter(moment().set({ 'month': 0, 'date': 1 }))
      && dateCompleted.isSameOrBefore(moment().set({ 'month': 3, 'date': 1 })))
      {
        return dateCompleted.set({ 'month': 8, 'date': 1 }).toDate(); 
      } else {
        return dateCompleted.add(1, 'years').set({ 'month': 8, 'date': 1 }).toDate();
      }
    }
    return null;
  };


  return (
    <Grid item>
      <CustomTooltip
        standardMargin
        tooltipContent={getFluVaccinationTooltip()}
        placement="bottom-start"
        disabled={!showTooltip}
      >
        <>
          {fluStatusInformation?.length > 0 ? (
            <RowSection
              header={t('FLU')}
              data={''}
              preData={showCheckmark ? vaccinationStatus : ''}
              showHalfwayProgress={showHalfwayProgress}
              showHalfwayProgressSecondaryIcon={true}
              showAlert={false}
              color={showCheckmark ? 'Green' : '#006FB9'}
            />
          ) : candidateDetails.covidVaccinationstatus && (
            <RowSection
              header={t('FLU')}
              data={''}
              preData={''}
              showHalfwayProgress={true}
              showHalfwayProgressSecondaryIcon={true}
              showAlert={false}
              color={'#006FB9'}
            />
          )}
        </>
      </CustomTooltip>
    </Grid>
  );
};

export default FluStatusCard;