import React, { useEffect, useState } from 'react';
import { Checkbox, MenuItem, Select, Button, Grid, InputLabel } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { SearchField } from '../../components/Common/Search/SearchField';
import { useTranslation } from 'react-i18next';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

export interface ISelection {
  id: number;
  name: string;
  value: string;
  labelPrefix?: string;
  labelPostfix?: string;
}

export function instanceOfISelection(object: any): object is ISelection {
  return object && typeof object === 'object' && 'id' in object && 'name' in object && 'value' in object;
}

const useStyles = makeStyles()(theme => ({
  selectField: {
    minWidth: 100,
    maxHeight: 400,
  },
  dropdownStyle: {
    border: '1px solid #D1D1D1',
    boxShadow: '0px 2px 8px #0000004D',
    borderRadius: 3,
    minWidth: 100,
  },
  listStyle: {
    maxHeight: 350,
    overflowX: 'hidden',
  },
  menu: {
    color: '#333333',
    fontSize: 14,
  },
  menuHighlight: {
    '&:hover, &:focus': {
      color: 'white',
      backgroundColor: '#006FB9',
    },
    '&.Mui-selected': {
      '&:hover, &:focus': {
        color: 'white',
        backgroundColor: '#006FB9',
      },
    },
  },
  searchContainer: {
    backgroundColor: '#ffffff',
    padding: '0px 8px 16px 8px',
    '&:hover, &:focus': {
      backgroundColor: '#ffffff',
    },
    position: 'sticky',
    top: -10,
    zIndex: 10,
  },
  actionContainer: {
    backgroundColor: '#ffffff',
    '&:hover, &:focus': {
      backgroundColor: '#ffffff',
    },
    marginBottom: '-8px',
    position: 'sticky',
    bottom: -8,
    width: '100%',
    borderTop: '1px solid #cccccc',
    padding: 5,
    zIndex: 10,
  },

  button: {
    fontSize: 14,
    textTransform: 'none',
    '&:hover, &:focus': {
      background: 'none',
    },
  },
  checked: {
    color: `${theme.palette.framework.system.skyBlue} !important`,
  },

  cancelButton: {
    color: '#888888',
  },
  multiSelectedTag: {
    maxWidth: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  countTag: {
    color: '#555555',
    fontSize: 14,
    fontWeight: 600,
    paddingLeft: 10,
  },
  placeholder: {
    marginTop: '2px !important',
    fontSize: '12px !important',
    color: '#C0C0C0',
  },
  focusPlaceholder: {
    color: '#C0C0C0 !important',
  },
  searchGrid: {
    paddingLeft: 8,
  },
}));

const getObjectValue = obj => (obj ? (typeof obj.value === 'object' ? obj.id : obj.value) : obj);

export const CustomSelect = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { options, selectAllValue, value: selected, returnsObjectAsValue, isMultiSelect, autoFocus } = props;
  const [filteredOptions, setFilteredOptions] = useState<any[]>(options);
  const [selectedCount, setSelectedCount] = useState<string>(isMultiSelect ? '' : '1');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [prevState, setPrevState] = useState<any>(selected);
  const [isNewSelectionApplied, setIsNewSelectionApplied] = useState<boolean>(false);

  const getCountTag = (chosen, opts) =>
    chosen
      ? chosen.length !== opts.length - 1 &&
        !(returnsObjectAsValue
          ? chosen.some(option => option.value === selectAllValue)
          : chosen.includes(selectAllValue))
        ? `${chosen.length}`
        : selectAllValue
      : '0';

  const stopImmediatePropagation = event => {
    event.stopPropagation();
    event.preventDefault();
  };

  const determineValue = chosenValue => {
    return returnsObjectAsValue
      ? isMultiSelect
        ? options.filter(option => chosenValue.includes(option.value))
        : filteredOptions?.find(option => option.value === chosenValue)
      : chosenValue;
  };

  const handleChange = event => {
    // Checks if click is on genuine menu item
    if (event.currentTarget?.tagName === 'LI') {
      // initial assignment regardless of props.isMultiSelect
      let newValue =
        props.version2 && !isMultiSelect
          ? event.target.value[event.target.value.length - 1] !== undefined
            ? determineValue(event.target.value[event.target.value.length - 1])
            : selected
          : determineValue(event.target.value);
      if (isMultiSelect) {
        // Checks if the click was on selectAllValue option
        if (event.currentTarget.innerText === selectAllValue) {
          // Checks selection/deselection of selectAllValue option
          if (event.target.value.includes(selectAllValue)) {
            newValue = returnsObjectAsValue ? options : options.map(option => option.value);
          } else newValue = [];
        }
        // Checks if the click was on any option when selectAllValue option is checked
        else if (
          event.currentTarget.innerText !== selectAllValue &&
          (returnsObjectAsValue
            ? event.target.value.some(option => option.value === selectAllValue)
            : event.target.value.includes(selectAllValue))
        ) {
          newValue = options.reduce((resultArray, option) => {
            if (option.value !== selectAllValue && option.value !== event.currentTarget.innerText)
              resultArray.push(returnsObjectAsValue ? option : option.value);
            return resultArray;
          }, []);
        }
      }

      setIsNewSelectionApplied(false);
      props.onChange(newValue);
    }
    !props.version2 && !props.isMultiSelect && setIsOpen(false);
    stopImmediatePropagation(event);
  };

  const handleClearAll = () => {
    setIsNewSelectionApplied(false);
    props.onChange([]);
  };

  const onApplyEvent = () => {
    setIsNewSelectionApplied(true);
    props.apply();
    setFilteredOptions(options);
    setIsOpen(false);
  };

  const cancelChoices = () => {
    props.onChange(prevState);
    setFilteredOptions(options);
    setIsOpen(false);
  };

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    if (isMultiSelect) {
      returnsObjectAsValue
        ? selected.some(options => options.value === selectAllValue) && selected.length !== options.length
          ? props.onChange(selected.filter(options => options.value !== selectAllValue))
          : setSelectedCount(getCountTag(selected, options))
        : selected.includes(selectAllValue) && selected.length !== options.length
        ? props.onChange(selected.filter(val => val !== selectAllValue))
        : setSelectedCount(getCountTag(selected, options));
    }
    // stringification of dependency ensures non react-hook-form change is reflected in useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selected)]);
  return (
    <>
      {props.placeholder && (selected === null || (isMultiSelect && !selected.length)) && (
        <InputLabel
          shrink={false}
          id="select-input-label"
          size="small"
          classes={{ root: classes.placeholder, focused: classes.focusPlaceholder }}
        >
          {props.version2 ? props.filterName : props.placeholder}
        </InputLabel>
      )}
      <Select
        sx={{ '& legend': { maxWidth: '0px !important' } }}
        labelId="select-input-label"
        label={props.filterName}
        variant="outlined"
        color="primary"
        multiple={isMultiSelect || props.version2}
        IconComponent={ExpandMoreOutlinedIcon}
        size="small"
        className={`${classes.selectField} ${props.selectFieldStyle && props.selectFieldStyle}`}
        value={
          props.version2 && !isMultiSelect
            ? selected
              ? returnsObjectAsValue
                ? [selected.value]
                : [selected]
              : []
            : returnsObjectAsValue
            ? selected.map(choice => choice.value)
            : selected
        }
        onChange={handleChange}
        onClose={() => {
          props.version2 && !isNewSelectionApplied && cancelChoices();
          setFilteredOptions(options);
        }}
        onOpen={() => {
          setPrevState(selected);
        }}
        onClick={() => setIsOpen(!isOpen)}
        open={isOpen}
        renderValue={(selectedValue: any) =>
          props.version2 ? (
            `(${selectedCount}) ${props.filterName}`
          ) : isMultiSelect ? (
            selectedValue.length === options.length - 1 || selectedValue.includes(selectAllValue) ? (
              <div>{props.options.find(option => option.value === selectAllValue).name}</div>
            ) : (
              <div className={classes.multiSelectedTag}>
                {props.version0
                  ? selectedValue.map(val => props.options.find(option => option.value === val).name).join(', ')
                  : `(${selectedCount})`}
              </div>
            )
          ) : (
            <div>
              {props.version2
                ? props.options.find(option => option.value === selectedValue[0]).name
                : props.options.find(option => option.value === selectedValue).name}
            </div>
          )
        }
        inputProps={{ 'aria-label': `custom-amn-select-${props.name}` }}
        MenuProps={{
          classes: {
            paper: `${classes.dropdownStyle} ${props.dropdownStyle && props.dropdownStyle}`,
            list: classes.listStyle,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          'aria-label': `custom-amn-select-${props.name}`,
        }}
      >
        {(isMultiSelect || props.version2) && (
          <div className={classes.searchContainer}>
            <Grid container direction="column">
              <Grid
                item
                container
                justifyContent={isMultiSelect && !props.version0 ? 'space-between' : 'flex-end'}
                alignItems="center"
              >
                {isMultiSelect && !props.version0 ? (
                  <div className={classes.countTag}>{`${selectedCount} ${t('search.filter.Selected')}`}</div>
                ) : null}
                <Button
                  className="clear-all-button"
                  aria-label="clear-all-button"
                  variant="text"
                  color="primary"
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  onClick={handleClearAll}
                  disabled={!isMultiSelect}
                  classes={{ text: ` ${classes.button}` }}
                >
                  {t('search.filter.clearAll')}
                </Button>
              </Grid>
              <Grid
                container
                item
                justifyContent="center"
                onClickCapture={stopImmediatePropagation}
                onKeyDown={e => e.stopPropagation()}
                classes={{ root: classes.searchGrid }}
                //To be removed once scrollbar is fixed
                style={{ paddingRight: props.version2 ? 8 : undefined }}
              >
                <SearchField
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus={autoFocus}
                  inheritWidth
                  name={`${props.name}-search`}
                  placeholder={t('appHeader.searchPlaceHolder')}
                  adornAtStart
                  onChange={event =>
                    setFilteredOptions(
                      options.filter(opt => opt.name.toLowerCase().includes(event.target.value.toLowerCase())),
                    )
                  }
                />
              </Grid>
            </Grid>
          </div>
        )}

        {filteredOptions.map((item, index) => (
          <MenuItem
            disableTouchRipple
            key={index}
            value={item.value}
            aria-labelledby={item.name}
            className={`${classes.menu} ${props.menuHighlight && classes.menuHighlight}`}
            classes={{ selected: props.menuHighlight && classes.menuHighlight }}
            selected
          >
            {props.version2 && !isMultiSelect && (
              <Checkbox
                size="small"
                color="secondary"
                classes={{ checked: classes.checked }}
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<RadioButtonCheckedIcon color="secondary" />}
                checked={(returnsObjectAsValue ? getObjectValue(selected) : selected) === getObjectValue(item)}
              />
            )}
            {isMultiSelect && (
              <Checkbox
                size="small"
                icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                checked={
                  returnsObjectAsValue
                    ? selected.map(choice => getObjectValue(choice)).includes(getObjectValue(item))
                    : selected.includes(item.value)
                }
              />
            )}
            {item.name}
          </MenuItem>
        ))}
        {props.version2 && (
          <div className={classes.actionContainer}>
            <Grid item container justifyContent="flex-end" alignItems="center">
              <Button
                className="clear-all-button"
                aria-label="apply-button"
                variant="text"
                color="primary"
                disableRipple
                disableFocusRipple
                disableTouchRipple
                onClick={onApplyEvent}
                classes={{ text: ` ${classes.button}` }}
              >
                {t('search.filter.apply')}
              </Button>
              <Button
                className="clear-all-button"
                aria-label="cancel-button"
                variant="text"
                disableRipple
                disableFocusRipple
                disableTouchRipple
                onClick={cancelChoices}
                classes={{ text: ` ${classes.button} ${classes.cancelButton}` }}
              >
                {t('search.filter.cancel')}
              </Button>
            </Grid>
          </div>
        )}
      </Select>
    </>
  );
};
