import { Button, Grid } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  root: {
    backgroundColor: '#FDEDE9',
    width: '100%',
    padding: '12px 6px',
  },
  icon: {
    marginRight: 12,
    color: '#FF0000',
    fontSize: 24,
  },
  textContainer: {
    display: 'flex',
    fontSize: 14,
    alignSelf: 'center',
    width: 'calc(100% - 120px)',
  },
  text: {
    fontSize: 14,
    alignSelf: 'center',
  },
  submitButton: {
    padding: '12px 9px',
    // width: '140px',
    backgroundColor: '#006FB9',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#006FB9',
    },
    marginRight: '24px',
    marginLeft: '24px',
    fontSize: '12px',
    height: '38px',
    '& .MuiInputBase-root.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
});

export interface IBannerMessageProps {
  message: string;
  refreshButton?: any;
  refreshAction?: any;
  buttonProps?: {
    text?: string;
    variant?: 'text' | 'outlined' | 'contained';
    className?: string;
    icon?: React.ReactNode;
    customButton?: React.ReactNode;
  }; // New property to customize the button
}

export const BannerMessage = ({ message, refreshButton, refreshAction, buttonProps = {} }: IBannerMessageProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const {
    text = t('placement.placementMods.refresh'), // Default text for the button
    variant = 'contained', // Default variant for the button
    className = classes.submitButton, // Default className for styling
    icon, // Optional icon for the button
    customButton, // Option to pass a completely custom button component
  } = buttonProps;

  return (
    <Grid
      container
      justifyContent="space-between"
      direction={'row'}
      alignContent="center"
      className={classes.root}
      wrap="nowrap"
    >
      <Grid item className={refreshButton ? classes.textContainer : classes.text}>
        <ErrorOutlineIcon className={classes.icon} />
        {message}
      </Grid>
      {refreshButton && (
        <Button
          variant={variant}
          onClick={e => {
            refreshAction();
          }}
          className={className}
        >
          {icon && <>{icon}</>} {/* Render icon if provided */}
          {text}
        </Button>
      )}
    </Grid>
  );
};
