import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { contactStatusChoice } from 'app/models/ClientContact/ClientContact';
import { FormInputDropdown } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/PersonalInfoTab/Form/Controls/FormInputDropdown';
import { useSelector } from 'react-redux';
import { selectClientContactDetails } from '../store/ClientContact.selector';

export const ContactStatus = formMethods => {
  const { t } = useTranslation();
  const contactStatusOptions = Object.values(contactStatusChoice);
  const [status, setStatus] = useState<any>(contactStatusOptions[0].Description);
  const { formMethods: controls, disabled } = formMethods;
  const selectedClientContactDetails = useSelector(selectClientContactDetails);

  const { control, setValue } = controls;

  const handleContactStatusChange = event => {
    setStatus(event.target.value);
    setValue('contactStatus', event.target.value, { shouldDirty: true });
    setValue('statusId', contactStatusChoice.find(s => s.Description === event.target.value).Id, { shouldDirty: true });
  };

  useEffect(() => {
    if (selectedClientContactDetails && selectedClientContactDetails?.contactStatusId) {
      const value = contactStatusChoice.find(s => s.Id === selectedClientContactDetails?.contactStatusId)?.Description;
      setStatus(value);
      setValue('contactStatus', value, { shouldDirty: false });
      setValue('statusId', selectedClientContactDetails?.contactStatusId, { shouldDirty: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientContactDetails?.contactId]);

  useEffect(() => {
    if (contactStatusOptions) {
      setValue('contactStatus', contactStatusOptions[0].Description, { shouldDirty: false });
      setValue('statusId', contactStatusOptions[0].Id, { shouldDirty: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Controller
      control={control}
      name={'contactStatus'}
      defaultValue={contactStatusOptions[0].Description}
      render={({ ref, onChange, ...rest }) => (
        <FormInputDropdown
          name={'contactStatus'}
          label={t('clientContactModal.contactStatus')}
          options={contactStatusOptions}
          handleChange={e => handleContactStatusChange(e)}
          inputValue={status}
          standard={true}
          disabled={disabled}
        />
      )}
    />
  );
};
