import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IPassportPreCheckPreferences {
  data: any[];
  count: number;
}
export const initialState: IPassportPreCheckPreferences = {
  data: [],
  count: 0,
};

const getPassportPreCheckPreferencesAction = createAction<{
  travelerId: number;
}>('GET_PASSPORT_PRECHECK_PREFERENCES');

const precheckPreferencesReducer = createSlice({
  name: 'passportPreCheckPreferences',
  initialState,
  reducers: {
    setData(state, action: PayloadAction<any[]>) {
      state.data = action.payload;
      state.count = action.payload?.length;
    },
    reset() {
      return initialState;
    },
  },
});

export const { name: passportPrecheckPreferencesSliceKey, reducer: passportPreCheckPreferenceReducer } =
  precheckPreferencesReducer;
export const passportPreCheckPreferencesActions = {
  ...precheckPreferencesReducer.actions,
  getPassportPreCheckPreferencesAction,
};
