import { Box, Button,  Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { candidateDetailActions } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { SellingNotesDragAndDrop } from './SellingNotesDragAndDrop';

const useStyles = makeStyles()((theme: Theme) =>
  ({
    title: {
      fontWeight: 500,
      fontSize: 16,
      paddingLeft: 10,
      color: '#555555',
    },
    wrapper: {
      height: '100%',
      padding: '20px 10px 0 0',
    },
    sellingNotesContainer: {
      maxHeight: '500px',
      height: '100%',
      overflowY: 'auto',
    },
    button: {
      color: '#2079BD',
      marginRight: '18px',
    },
  }));

export const SellingNotesGenerator = props => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [items, setItems] = useState<any>([]);
  const {
    setValue,
  } = useFormContext();

  const setSellingNotesData = data => {
    dispatch(candidateDetailActions.setSellingNotesItems(data));
    setItems(data);
  };

  /**
   * Method to trigger on clicking generate button
   */
  const handleGenerateButton = () => {
    dispatch(candidateDetailActions.setSellingNotesItems(items));
    props.setGenerateButtonState(true);
  };

  useEffect(() => {
    setValue('isGenerateBtnDisabled', !isChanged);
  }, [isChanged]);

  return (
    <>
      <LayoutGrid container direction="column" spacing={2} className={classes.wrapper}>
        <LayoutGridItem item>
          <Box className={classes.title}>{`Generator`}</Box>
        </LayoutGridItem>
        <LayoutGridItem item style={{ width: '100%', height: '400px' }}>
          <div className={classes.sellingNotesContainer}>
            <SellingNotesDragAndDrop
              setIsChanged={setIsChanged}
              setSellingNotesData={setSellingNotesData}
              setGenerateButtonState={props.setGenerateButtonState}
            />
          </div>
        </LayoutGridItem>
        <LayoutGridItem>
          <LayoutGrid direction="row-reverse">
            <LayoutGridItem>
              <Button
                id="generate-auto-selling-notes-preview-btn"
                className={classes.button}
                onClick={handleGenerateButton}
                disabled={!isChanged}
                children={`Generate Preview`}
              />
            </LayoutGridItem>
          </LayoutGrid>
        </LayoutGridItem>
      </LayoutGrid>
    </>
  );
};
