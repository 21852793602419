import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import { Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useInjectReducer } from 'redux-injectors';
import {
  gridSelectionReducer,
  gridSelectionSliceKey,
  gridSelectionActions,
} from '../Grid/GridSelection/GridSelection.redux';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    width: 1000,
  },
  headerTag: {
    paddingTop: '25px',
    paddingLeft: '25px',
    fontSize: 16,
    color: '#333333',
  },
  listItem: {
    width: '100%',
    background: theme.palette.framework.system.whisper,
    position: 'absolute',
    bottom: '0',
    left: '0',
  },
  data: {
    padding: '1%',
  },
  compareButton: {
    backgroundColor: '#006FB9',
    color: '#FFFFFF !important',
    '&:hover': {
      backgroundColor: '#006FCF',
    },
    width: '75%',
    padding: '5%',
  },
  clearAllButton: {
    color: '#888888',
    '&:hover': {
      color: '#006FCF',
      background: 'none',
    },
    width: '75%',
    padding: '1%',
  },
  text: {
    padding: '10%',
    fontSize: 13,
    color: '#333333',
  },
  drawerPaper: {
    marginTop: 46,
    height: '-webkit-fill-available',
    minWidth: 300,
  },
  listGutters: {
    padding: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  buttonListGutters: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

export default function CompareDrawer(props) {
  const { t } = useTranslation();
  const [data, setData] = useState(props.data);
  const { classes } = useStyles();
  const [state, setState] = React.useState({ right: false });

  useInjectReducer({ key: gridSelectionSliceKey, reducer: gridSelectionReducer });
  const dispatch = useDispatch();

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const toggleDrawer = (anchor, open) => event => {
    if (event.key === 'Tab' || event.key === 'Shift') return;
    setState({ ...state, [anchor]: open });
  };

  const handleCompare = () => {
    const selectedKeys = data.map(chosenItem => {
      return chosenItem[props.itemTag];
    });
    dispatch(gridSelectionActions.setGridSelections({ selectedData: selectedKeys, isCompareGridOpen: true }));
  };

  const list = anchor => (
    <>
      <List>
        <Grid item md={8} className={classes.headerTag}>
          {t('compareDrawer.tag1')}
          {props.data.length}
          {t('compareDrawer.tag2')}
        </Grid>
      </List>
      {data.map((chosenItem, index) => (
        <ListItem key={index} classes={{ gutters: classes.listGutters }}>
          <Grid container className={classes.data}>
            <Grid item md={6}>
              <Typography variant="body2" className={classes.text}>
                {chosenItem[props.itemTag]}
              </Typography>
            </Grid>
            <Grid item md={6} style={{ textAlign: 'right' }}>
              <IconButton
                color="primary"
                data-testid="remove-button"
                disableRipple={true}
                disableFocusRipple
                disableTouchRipple
                style={{ background: 'none' }}
                onClick={() => props.removeData(chosenItem)}
                size="small">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </ListItem>
      ))}
      <List className={classes.listItem}>
        <ListItem classes={{ gutters: classes.buttonListGutters }}>
          <Button
            data-testid="compare-button"
            variant="contained"
            classes={{ root: classes.compareButton }}
            disabled={props.data.length < 2}
            onClick={() => handleCompare()}
          >
            {t('compareDrawer.compareButtonLabel')}
          </Button>
          <Button
            data-testid="clear-all-button"
            disableRipple
            variant="text"
            className={classes.clearAllButton}
            disabled={props.data.length === 0}
            onClick={props.clearAll}
          >
            {t('compareDrawer.clearButtonLabel')}
          </Button>
        </ListItem>
      </List>
    </>
  );

  return (
    <div className={classes.root} data-testid="compare-drawer">
      <Divider orientation="vertical" flexItem />
      <Drawer
        variant="persistent"
        anchor="right"
        open={props.open}
        onClose={toggleDrawer('right', false)}
        classes={{ paper: classes.drawerPaper }}
      >
        {list('right')}
      </Drawer>
    </div>
  );
}
