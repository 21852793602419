import React, { useEffect } from 'react';
import { Checkbox, FormControlLabel } from 'amn-ui-core';
import { Controller, useFormContext } from 'react-hook-form';

const FormCheckbox = ({
  name,
  defaultChecked,
  label,
  disabled,
}: {
  name: string;
  defaultChecked: boolean;
  label: string;
  disabled: boolean;
}) => {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    setValue(name, defaultChecked);
  }, [defaultChecked]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ value, onChange }) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              onChange={event => onChange(event?.target?.checked)}
              disabled={disabled}
              defaultChecked={defaultChecked}
            />
          }
          label={label}
        />
      )}
    />
  );
};

export default FormCheckbox;
