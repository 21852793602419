import { createAction } from '@reduxjs/toolkit';
import { ITemplate } from '../confirmation-template-editor/types';
import { DocSource } from '../placement-candidate-contract-doc-editor/types';
import { IClientConfirmationContacts } from './types';

export interface IConfirmationDocPayload {
  placementId: number;
  template: ITemplate;
}

export interface IPreviewConfirmationDocPayload {
  placementId: number;
  templateText: string;
}

export interface IPublishConfirmationDocTokensPayload {
  placementId: number;
  templateId: number;
  templateName: string;
  templateText: string;
  isAutoSave: boolean;
  userId: number;
  userName: string;
  placementClientConfirmationContacts: IClientConfirmationContacts[];
}


const getTemplates = createAction('GET_PLACEMENT_CONFIRMATION_TEMPLATES');
const getMergeTokensList = createAction('GET_PLACEMENT_CONFIRMATION_MERGE_TOKENS_LIST');

/** Draft templates */
const getDraftTemplate = createAction<{ placementId: number }>('GET_PLACEMENT_CONFIRMATION_TEMPLATE_DRAFT');
const saveDraftTemplate = createAction<IConfirmationDocPayload>('SAVE_PLACEMENT_CONFIRMATION_TEMPLATE_DRAFT');
const deleteDraftTemplate = createAction<{ placementId: number }>('DELETE_PLACEMENT_CONFIRMATION_TEMPLATE_DRAFT');
const copyTemplate = createAction<{ placementId: number, documentId: string }>('COPY_PLACEMENT_CONFIRMATION_TEMPLATE');


const getConfirmationDoc = createAction<{ documentId: string; source: DocSource }>('GET_PLACEMENT_CONFIRMATION_DOC');
const previewConfirmationDoc = createAction<IPreviewConfirmationDocPayload>('PREVIEW_PLACEMENT_CONFIRMATION_DOC');
const createConfirmationDoc = createAction<IConfirmationDocPayload>('CREATE_PLACEMENT_CONFIRMATION_DOC');
const publishConfirmationDocTokens = createAction<any>('PUBLISH_PLACEMENT_CONFIRMATION_DOC_TOKENS');

const updateClientConfirmationDocStatus = createAction<{
  placementId: number;
  documentId: string;
}>('EDITOR_UPDATE_CLIENT_CONFIRMATION_DOC_STATUS');

export const serviceActions = {
  getTemplates,
  getMergeTokensList,
  getDraftTemplate,
  saveDraftTemplate,
  deleteDraftTemplate,
  copyTemplate,
  getConfirmationDoc,
  previewConfirmationDoc,
  publishConfirmationDocTokens,
  createConfirmationDoc,
  updateClientConfirmationDocStatus,
};
