import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest } from 'redux-saga/effects';
import {
  getCandidateDetailsData,
  getCandidatePersonalInfoData,
  getCredentialsTabData,
  getProfessionalProfileTabData,
  getSummaryTabData,
} from './business.saga';
import { candidateProfileDetailsActions } from './slice';

export function* candidateProfileDetailsSaga(action: PayloadAction) {
  yield takeLatest(candidateProfileDetailsActions.getCandidateDetails.type, getCandidateDetailsData);
  yield takeLatest(candidateProfileDetailsActions.getSummaryTabDetails.type, getSummaryTabData);
  yield takeLatest(candidateProfileDetailsActions.getProfessionalProfileTabDetails.type, getProfessionalProfileTabData);
  yield takeLatest(candidateProfileDetailsActions.getCandidateCredentialsTabDetails.type, getCredentialsTabData);
  yield takeLatest(
    candidateProfileDetailsActions.getCandidatePersonalInfoTabDetails.type,
    getCandidatePersonalInfoData,
  );
}
