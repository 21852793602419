import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Backdrop } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
    color: '#222222',
  },
  circularProgress: {
    opacity: 0.5,
  },
}));

interface ICustomBackdropProps {open: boolean}

export const CustomBackdrop = ({open = false}: ICustomBackdropProps) => {
  const { classes } = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress color="inherit" className={classes.circularProgress} />
    </Backdrop>
  );
};
