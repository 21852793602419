import { Button } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()({
  root: {
    whiteSpace: 'pre-wrap',
    fontSize: '14px',
    overflowY: 'scroll',
  },
  more: {
    color: '#006FB9',
    textDecoration: 'none',
    textTransform: 'none',
    marginLeft: 12,
    padding: 0,
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
  MuiDialogPaperWidthSm: {
    width: '645px',
    maxWidth: '645px',
  },
});

export const MoreTextBlock = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  return (
    <div className={classes.root} id={props.id}>
      {props.text?.slice(0, props.charLimit)}
      {props.text?.length > props.charLimit && (
        <Button
          variant="text"
          classes={{ root: classes.more }}
          onClick={() => setOpen(true)}
          style={{ background: 'none !important' }}
          disableTouchRipple
          disableFocusRipple
          aria-label="more-button"
        >
          {t('placement.profile.tabs.moreLink')}
        </Button>
      )}
      <GenericDialog
        open={open}
        variant="blue"
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
        classes={{
          paper: classes.MuiDialogPaperWidthSm,
        }}
        dialogTitleProps={{ text: props.title, closeButton: true }}
        dialogContentProps={{
          classes: { root: classes.root },
          sx: { padding: '24px !important' },
        }}
        dialogActions={[
          {
            text: t('placement.profile.tabs.close'),
            variant: 'contained',
            color: 'tertiary',
            onClick: () => setOpen(false),
          },
        ]}
      >
        {props.text}
      </GenericDialog>
    </div>
  );
};
