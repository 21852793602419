import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import SearchGridWrapper from 'app/layout/pages/SearchGridWrapper';
import UnitOrderTab from './OrderTab';
import { useDispatch, useSelector } from 'react-redux';
import UnitOrderFilter from './UnitOrderFilter';
import UnitOrderChips from './UnitOrderChips';
import { selectFilters, selectGridState } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';
import { RootState } from 'types';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import { gridStateActions } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { globalSearchActions } from 'store/redux-store/global-search/slice';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { gridSelectionActions } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.redux';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { revalidateChipSelection } from '@AMIEWEB/GlobalSearch/helper';
import { useParams } from 'react-router-dom';
import { FacilityUnitActions } from '../UnitTab/Store/FacilityUnit.redux';
import { setOrderUnitsPayload } from './helper';

export interface IFetchOrderUnitDetail {
    unitId: number;
    pageSize: number;
    pageNumber: number;
    orderForUnitFilter?: {
        orderAgeFromDate?: string;
        orderAgeToDate?: string;
        mfsFilter?: boolean;
        fromPositionFilter?: number;
        toPositionFilter?: number;
    }
}

const UnitOrderWrapper = () => {
    const { t } = useTranslation();
    const params = useParams<{ id: string; unitId: string }>();
    const formMethods = useForm({});
    const { handleSubmit, reset } = formMethods;
    const filterPreferencesId = 'unit-order-filter';
    const dispatch = useDispatch();
    const gridState = useSelector(selectGridState);
    const appliedFilterValues = useSelector(selectFilters);
    const [searchFilter, setSearchFilter] = useState<any>({});
    const filterPreference = useSelector((state: RootState) => {
        return state.userPreferenceData.userGridPreferences.find(item => item.id === filterPreferencesId);
    });
    const { loaded: preferencesLoaded } = useSelector(selectUserPreference);
    const hiddenSubmitButton = createRef<HTMLButtonElement>();
    const onApplyFilter = (filterValues, newSelectedChipFilters?) => {
        dispatch(gridStateActions.setPageNumber(1));
        const selectedChipFilters =
            newSelectedChipFilters ||
            revalidateChipSelection(filterValues, gridState.filterAttributes?.selectedChipFilters || []);
        dispatch(
            gridStateActions.setFilterAttributes({
                ...gridState.filterAttributes,
                filterObject: Object.entries(filterValues),
                selectedChipFilters,
            }),
        );
        const request = {
            unitId: Number(params.unitId),
            pageSize: gridState.pageSize !== 0 ? gridState.pageSize : 250,
            pageNumber: gridState.pageNumber !== 0 ? gridState.pageNumber : 1,
        };
        dispatch(FacilityUnitActions.getOrdersByUnitId(setOrderUnitsPayload(request, filterValues)));
    };
    const onApplyFilterandSave = (filterValues, newSelectedChipFilters?) => {
        const preferenceData = {
            id: filterPreferencesId,
            value: {
                filterValues: filterValues,
                newSelectedChipFilters: newSelectedChipFilters ?? appliedFilterValues?.selectedChipFilters,
            },
        };
        dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
    };

    useEffect(() => {
        if (preferencesLoaded) {
            const payload = filterPreference?.value ?? { filterValues: {} };
            reset(payload?.filterValues);
            onApplyFilter(payload?.filterValues, payload?.newSelectedChipFilters);
        }

        return () => {
            dispatch(gridStateActions.reset());
            dispatch(globalSearchActions.reset());
            dispatch(globalActions.closeBanner());
            dispatch(
                gridSelectionActions.setGridSelections({
                    selectedData: [],
                }),
            );
        };
    }, [filterPreference]);
    useEffect(() => {
        if (appliedFilterValues) {
            const selectedFilterValues = {
                filter: {
                    ...appliedFilterValues?.filters,
                },

                selectedChipFilters: { ...appliedFilterValues?.selectedChipFilters },
            };
            setSearchFilter(selectedFilterValues);
        }
    }, [appliedFilterValues]);
    return (
        <React.Fragment>
            <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit(filter => onApplyFilterandSave(filter))}>
                    <SearchGridWrapper
                        filters={<UnitOrderFilter onClickHandler={onApplyFilterandSave} />}
                        table={
                            <UnitOrderTab />
                        }
                        chips={<UnitOrderChips onApplyFilter={onApplyFilterandSave} category={'unit'} />}
                        tableMinHeight={430}
                        loading={false}
                    />
                    <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
                </form>
            </FormProvider>
        </React.Fragment >
    )
}

export default UnitOrderWrapper