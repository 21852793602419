/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { searchSkillsets } from 'app/services/FacilityServices/FacilityServices';
import SearchIcon from '@mui/icons-material/Search';
import EMRList from 'app/assets/jsons/EMR.json';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
  Chip,
  Avatar,
  Box,
  FormControl,
  Tooltip,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { SimpleDropdown } from '../Common/SimpleDropdown';
import { yearsOfExperienceList } from 'app/constants';
import { formatYOEDropdown } from '../helper';
import defaultSkillCheckLists from 'app/assets/jsons/defaultSkillCheckList.json';
import { SkillCheckList, SkillCheckListChip } from 'app/models/Unit/CreateUnit';
import { AMNDivisionType } from 'app/models/enums/AMNDivisionType';

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    height: '15%',
    maxHeight: 300,
    overflow: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
    '& .MuiList-padding': {
      paddingTop: 0,
    },
  },
  searchField: {
    height: '1%',
    width: '100%',
    borderBottom: '1px solid #CCCCCC',
  },
  chip: {
    margin: '0 1% 1% 0',
    maxWidth: '50ch',
  },

  wrapper: {
    backgroundColor: '#FFFFFF !important',
    border: '1px solid #CCCCCC',
    borderRadius: 5,
    minHeight: 300,
  },
  item: {
    borderBottom: '1px solid #EFEFEF',
    borderColor: '#f4f5f738',
  },
  noBorder: {
    border: 'hidden',
  },
  chipWrapper: {
    paddingTop: '0.8%',
  },
  dropDownStyle: {
    paddingLeft: '0px !important',
  },
  listItemContent: {
    display: '-webkit-box',
    maxWidth: '40ch',
    fontSize: 14,
    lineHeight: 1.4,
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#333333',
    opacity: 1,
    textAlign: 'left',
    letterSpacing: '0px',
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #8C8C8C',
  },
  arrow: {
    color: '#ffffff',
    opacity: 1,
    '&::before': {
      boxShadow: '0px 3px 6px #00000029',
      border: '1px solid #8C8C8C',
    },
  },
  placementBottom: {
    marginBottom: 0,
  },
  icon: {
    top: 7,
    right: 5,
  },
  selectMenu: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  chipBlock: {
    display: 'flex',
  },
  chipText: {
    maxWidth: '20ch',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

interface Props {
  division?: number;
  onSkillChecklistPropChange: (property: string, chips: any) => void;
  isdisabled?: boolean;
  unitSkillCheckList?: any | null;
}
const defaultSkillCheckListsUnique = (arr, prop) => {
  const set = new Set();
  return arr.filter(o => !set.has(o[prop]) && set.add(o[prop]));
};
const getDefaultCheckLists = division => {
  if (division === AMNDivisionType.amnNurse) return defaultSkillCheckLists.filter(scl => scl.forTravel);
  if (division === AMNDivisionType.allied) return defaultSkillCheckLists.filter(scl => !scl.forTravel);
  if (division === AMNDivisionType.hsg) return defaultSkillCheckListsUnique(defaultSkillCheckLists, 'text');

  return [];
};

export const UnitSkillCheckList = (props: Props) => {
  /* eslint-disable i18next/no-literal-string */
  const { classes } = useStyles();
  const { division, onSkillChecklistPropChange, isdisabled, unitSkillCheckList } = props;
  const [chips, setChips] = React.useState<SkillCheckListChip[]>([]);
  const [searchResult, setSearchResult] = React.useState<SkillCheckList[]>([]);
  const [selectedSkill, setSelectedSkill] = React.useState<{ id: number; isRequired: boolean | null }[]>([]);

  useEffect(() => {
    const defaultSkills = getDefaultCheckLists(division) as SkillCheckList[];
    let selectionInfo = getSelectionInfo(defaultSkills) as SkillCheckList[];

    if (unitSkillCheckList) {
      selectionInfo = mapData(unitSkillCheckList, selectionInfo);
      const prepareChips: SkillCheckListChip[] = [];
      unitSkillCheckList?.map((item, indx) => {
        prepareChips.push({
          id: item.questionId,
          text: item.text,
          isRequired: item.isRequired,
          yearsOfExperience: item.yearsOfExperience,
        });
      });
      setChips(prepareChips);
    }
    setSearchResult(selectionInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [division]);

  useEffect(() => {
    const prepareChips: SkillCheckListChip[] = [];
    searchResult?.map((item, indx) => {
      if (item.isSelected) {
        prepareChips.push({
          id: item.questionId,
          text: item.text,
          isRequired: item.isRequired ? true : false,
          yearsOfExperience: item.yearsOfExperience,
        });
      }
    });
    setChips(prepareChips);
  }, [searchResult]);

  const handleToggle = (id: number) => () => {
    const modifingItem = searchResult.filter(x => x.questionId === id)[0];
    setSearchResult(
      searchResult.map(x =>
        x.questionId !== id
          ? x
          : { ...x, isSelected: !modifingItem.isSelected, yearsOfExperience: '0', isRequired: true },
      ),
    );
    const selected = selectedSkill.filter(x => x.id !== id);
    setSelectedSkill(selected);
  };

  const handleChange = (event, skillCheckList) => {
    setSearchResult(
      searchResult.map(x =>
        x.questionId !== skillCheckList.questionId ? x : { ...x, isRequired: event.target.value === 'req' },
      ),
    );

    const existingChip = chips.find(x => x.id === skillCheckList.questionId);
    if (!existingChip) {
      const newChip = {
        id: skillCheckList.questionId,
        text: skillCheckList.text,
        isRequired: event.target.value === 'req',
        yearsOfExperience:
          skillCheckList.yearsOfExperience && skillCheckList.yearsOfExperience !== '0'
            ? skillCheckList.yearsOfExperience + ''
            : '0',
      };
      setChips([...chips, newChip]);
    } else {
      setChips(
        chips.map(x => (x.id !== skillCheckList.questionId ? x : { ...x, isRequired: event.target.value === 'req' })),
      );
    }
  };

  const handleSearch = async event => {
    const skillChecklists =
      event.target.value.length >= 3 ? await searchSkillsets(event.target.value) : getDefaultCheckLists(division);
    const updatedResults = getSelectionInfo(skillChecklists) as SkillCheckList[];
    const newResults: SkillCheckList[] = searchResult.filter(x => x.isSelected);
    const existingSearchResult = searchResult;
    updatedResults.map(x => {
      const alreadyExistResultItem = existingSearchResult.filter(y => y.questionId === x.questionId && x.isSelected);
      if (!alreadyExistResultItem || alreadyExistResultItem.length <= 0) {
        newResults.push(x);
      }
    });
    setSearchResult(newResults);
  };

  const mapData = (unitSkillCheckList: any, selectionInfo: SkillCheckList[]) => {
    unitSkillCheckList?.map(item => {
      const newItem = selectionInfo.find(d => d.questionId === item.questionId);
      selectionInfo = selectionInfo.filter(d => d.questionId !== item.questionId) as SkillCheckList[];
      selectionInfo.push({
        shortDesc: newItem ? newItem?.shortDesc : '',
        description: newItem ? newItem?.description : '',
        text: item?.text,
        questionId: item?.questionId,
        isRequired: item?.isRequired,
        yearsOfExperience: item?.yearsOfExperience === '' ? '0' : item?.yearsOfExperience,
        isSelected: true,
        partitionKey: item?.partitionKey,
        id: item?.id,
      });
    });
    return selectionInfo;
  };

  const getSelectionInfo = results =>
    results
      .filter(search => EMRList.findIndex(emr => emr.name == search.text) === -1)
      .map(result => {
        const existingChip = chips.find(x => x.id === result.questionId);
        if (!existingChip) return result;
        result.isRequired = existingChip.isRequired;
        result.yearsOfExperience = existingChip.yearsOfExperience ? existingChip.yearsOfExperience : ['0']; // (existingChip.yearsOfExperience?.match(/(\d+)/) || ['0'])[0];
        result.isSelected = true;
        return result;
      });

  const handleDelete = id => {
    setSearchResult(
      searchResult.map(x =>
        x.questionId !== id ? x : { ...x, isSelected: false, isRequired: null, yearsOfExperience: '0' },
      ),
    );
    setChips(chips.filter(x => x.id != id));
  };

  const handleSelect = (event, id) => {
    setSearchResult(searchResult.map(x => (x.questionId !== id ? x : { ...x, yearsOfExperience: event.target.value })));
    setChips(chips.map(x => (x.id !== id ? x : { ...x, yearsOfExperience: event.target.value + '' })));
  };

  useEffect(() => {
    // if (chips && chips.length > 0) {
    const skillCheckListValue = chips?.map(chip => {
      return {
        questionId: chip.id,
        text: chip.text,
        isRequired: chip.isRequired,
        yearsOfExperience: chip.yearsOfExperience
          ? parseInt(chip.yearsOfExperience.toString().replace('/[^0-9]/g', ''))
          : 0,
      };
    });
    onSkillChecklistPropChange('skillChecklists', skillCheckListValue);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chips]);

  return (
    <>
      <Box className={classes.wrapper}>
        <TextField
          variant="outlined"
          color="primary"
          placeholder="Search"
          id="txtSkillCheckList"
          autoComplete="on"
          onChange={handleSearch}
          fullWidth
          disabled={isdisabled}
          classes={{ root: classes.searchField }}
          InputProps={{
            startAdornment: <SearchIcon style={{ opacity: 0.6 }} />,
            classes: { notchedOutline: classes.noBorder },
          }}
        />
        <List className={classes.root} id={'createUnitQualificationsskillChecklistSearchResultListContainer'}>
          {searchResult
            ?.sort((a, b) => (a.text > b.text ? 1 : a.text === b.text ? (a.questionId > b.questionId ? 1 : -1) : -1))
            .map((value, index) => {
              const labelId = `checkbox-list-label-${value}`;
              return (
                <ListItem
                  key={value.questionId}
                  role={undefined}
                  dense
                  button
                  disabled={isdisabled}
                  onClick={handleToggle(value.questionId)}
                  className={classes.item}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      disabled={isdisabled}
                      checked={value.isSelected === true}
                      tabIndex={-1}
                      inputProps={{ 'aria-labelledby': labelId }}
                      color="primary"
                    />
                  </ListItemIcon>
                  <Tooltip
                    title={`${value.text}`}
                    placement="bottom"
                    arrow
                    classes={{
                      tooltip: classes.tooltip,
                      arrow: classes.arrow,
                      tooltipPlacementBottom: classes.placementBottom,
                    }}
                  >
                    <ListItemText
                      classes={{
                        primary: classes.listItemContent,
                      }}
                      id={labelId}
                      primary={`${value.text}`}
                    />
                  </Tooltip>
                  <ListItemSecondaryAction>
                    {value.isSelected && (
                      <Grid container>
                        <Grid item>
                          <RadioGroup aria-label="Required" name="required" onChange={e => handleChange(e, value)} row>
                            <FormControlLabel
                              disabled={isdisabled}
                              value="req"
                              control={<Radio color="primary" checked={value.isRequired === true} />}
                              label="Req"
                            />
                            <FormControlLabel
                              disabled={isdisabled}
                              value="pref"
                              control={<Radio color="primary" checked={value.isRequired === false} />}
                              label="Pref"
                            />
                          </RadioGroup>
                        </Grid>
                        <Grid item>
                          {
                            <FormControl variant="outlined">
                              <SimpleDropdown
                                onChange={event => {
                                  handleSelect(event, value.questionId);
                                }}
                                defaultInputList={yearsOfExperienceList}
                                defaultInputValue={value.yearsOfExperience || 0}
                                formattingFunc={formatYOEDropdown}
                                disabled={isdisabled}
                                inputName="experienceYearsOfExperience"
                                iconStyle={classes.icon}
                                dropDownStyle={classes.dropDownStyle}
                                inputPaddingLeft={'0px'}
                                bodyMarginTop={'3%'}
                                selectMenuStyle={classes.selectMenu}
                                value={value.yearsOfExperience}
                              />
                            </FormControl>
                          }
                        </Grid>
                      </Grid>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
        </List>
      </Box>
      <div className={classes.chipWrapper} id={'createUnitQualificationsskillChecklistAddedItemContainer'}>
        {chips &&
          chips.map(item => {
            return (
              <Chip
                disabled={isdisabled}
                variant="outlined"
                avatar={<Avatar variant={item.isRequired ? 'primary' : 'green'}>{item.isRequired ? 'R' : 'P'}</Avatar>}
                key={item.id}
                label={
                  <React.Fragment>
                    <div className={classes.chipBlock}>
                      <div className={classes.chipText}>{item.text}</div>
                      {item?.yearsOfExperience?.toString() === '0' || item?.yearsOfExperience?.toString() === '' ? (
                        ''
                      ) : (
                        <span>{` , ${item.yearsOfExperience}y`}</span>
                      )}
                    </div>
                  </React.Fragment>
                }
                className={classes.chip}
                onDelete={() => handleDelete(item.id)}
              />
            );
          })}
      </div>
    </>
  );
};
