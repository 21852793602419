import React, { useEffect, useRef, useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from 'tss-react/mui';
import { Autocomplete, FormControl, IconButton, InputAdornment, Radio, TextField } from 'amn-ui-core';

const useStyles = makeStyles<{ isOpen: boolean }>()((theme, { isOpen }) => ({
  container: {
    width: '90%',
  },
  dropdownTextBox: {
    '& .MuiFilledInput-root': {
      paddingRight: '0 !important',
    },
  },
  dropdownText: {
    backgroundColor: 'none',
  },
  KeyboardArrow: {
    fontSize: '30px',
    marginBottom: '12px',
    color: isOpen ? theme.palette.system.primary : theme.palette.system.darkGrey,
  },
  listItemText: {
    '& .MuiList-root': {
      margin: '0 !important',
      padding: '0 !important',
    },
  },
}));

export const PacketTemplateDropDown = ({ label, name, options, isDisabled, selectedOption, setSelectedOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { classes } = useStyles({ isOpen });
  const dropdownRef = useRef(null);

  const handleAutocompleteChange = (event, value) => {
    setSelectedOption(value);
  };

  const handleSelect = option => {
    setIsOpen(false);
    setSelectedOption(option);
  };

  const handleToggleDropdown = event => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <FormControl
      component="fieldset"
      ref={dropdownRef}
      className={classes.container}
      data-testid="pref-packet-templates"
    >
      <Autocomplete
        limitTags={1}
        disabled={isDisabled}
        disablePortal={true}
        disableClearable
        onChange={handleAutocompleteChange}
        clearOnBlur
        disableListWrap={true}
        open={isOpen}
        id={`${name}-autocomplete`}
        disableCloseOnSelect
        options={options}
        value={selectedOption}
        getOptionLabel={option => option.label}
        renderInput={params => (
          <TextField
            {...params}
            data-testid="-pref-packet-templates-textfield"
            label={label}
            onClick={handleToggleDropdown}
            variant="filled"
            className={classes.dropdownTextBox}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off',
              readOnly: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton color="primary" size="small" onClick={handleToggleDropdown}>
                    {isOpen ? (
                      <KeyboardArrowUpIcon
                        className={classes.KeyboardArrow}
                        data-testid="pref-packet-templates-KeyboardArrowUpIcon"
                      />
                    ) : (
                      <KeyboardArrowDownIcon
                        className={classes.KeyboardArrow}
                        data-testid="pref-packet-templates-KeyboardArrowDownIcon"
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        renderOption={(props, option) => (
          <li id={option?.value} {...props}>
            <Radio
              checked={selectedOption === option}
              defaultChecked={option.id === 1}
              onChange={() => {
                handleSelect(option);
              }}
            />
            {option.label}
          </li>
        )}
      />
    </FormControl>
  );
};
