/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui';
import { Theme } from 'amn-ui-core';

export const useEmailStyles = makeStyles()((theme: Theme, props) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toolbar: {
    paddingLeft: '2px',
    paddingBottom: '2%',
    paddingTop: '0.5%',
  },
  newToolbar: {
    paddingLeft: '2px',
    paddingTop: '0px',
    height: '60px',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: '1200px',
    outline: 'none',
    borderRadius: '5px',
    boxShadow: '0px 0px 2px 6px #8888',
  },
  expandedPaper: {
    backgroundColor: theme.palette.background.paper,
    width: '100vw',
    height: '100vh',
    outline: 'none',
    borderRadius: '2px',
    boxShadow: '0px 0px 2px 6px #8888',
    display: 'flex',
    flexDirection: 'column',
    transform: 'none !important',
  },
  templateExpandedPaper: {
    backgroundColor: theme.palette.background.paper,
    width: '100vw',
    height: '100vh',
    outline: 'none',
    borderRadius: '2px',
    boxShadow: '0px 0px 2px 6px #8888',
    display: 'flex',
    flexDirection: 'column',
    transform: 'none !important',
  },
  changeTemplateGrid: {
    boxShadow: '2px 0px 0px 0px #8888',
    padding: '10px 0px 0px 20px',
  },
  expandedChangeTemplateGrid: {
    boxShadow: '2px 0px 0px 0px #8888',
    height: '102vh',
    padding: '20px 0px 0px 30px !important',
  },
  bodyContainer: {
    padding: '0% 0% 0% 1%',
    marginTop: '12px',
  },
  expandedBodyContainer: {
    padding: '0% 0% 1% 0%',
    height: '80vh',
  },
  templateSelectionTabs: {
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      paddingRight: '25px',
    },
  },
  scroll: {
    marginBottom: '0.2%',
    height: '580px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  templateScroll: {
    marginBottom: '2.2%',
    height: '457px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  expandedScroll: {
    marginBottom: '1%',
    height: '72vh',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  container: {
    marginTop: '5px',
  },
  templateSnap: {
    width: '100%',
    height: '100%',
    marginBottom: '5%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 9,
    background: 'transparent',
  },
  minimizedIcon: {
    cursor: 'pointer',
  },
  categorySelection: {
    marginBottom: '5%',
  },
  navigationSpace: {
    marginTop: '0.5%',
    height: '8vh',
    borderRadius: '0 0 5px 5px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  expandedNavigationSpace: {
    marginTop: 'auto',
    height: '8vh',
    borderRadius: '0 0 5px 5px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputLabel: {
    color: '#55555',
    '&.Mui-focused': {
      color: '#55555',
    },
    '&:focus': {
      color: '#55555 !important',
    },
  },
  select: {
    background: '#F7F7F7',
  },
  tab: {
    minWidth: '100px',
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      paddingRight: '25px',
    },
  },
  closeSelection: {
    padding: 0,
    color: '#ffffff',
    backgroundColor: 'transparent',
    '&:hover': {
      padding: 0,
      color: '#ffffff',
      backgroundColor: 'transparent',
    },
  },
  saveButton: { height: '4.5vh' },
  saveButtonPlacement: { margin: '0 0.5%' },
  saveTemplateGrid: { padding: '2%', marginBottom: '20%' },
  expandedSaveTemplateGrid: { padding: '2%', marginBottom: '51vh' },
  MuiCircularProgressRoot: {
    top: '15vh',
    position: 'relative',
    left: '40%',
  },
  closeDialog: {
    position: 'fixed',
    margin: '-5%',
  },
  expandedCloseDialog: {
    position: 'fixed',
    margin: '80vh 0 0 0',
  },

  messageBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'inherit',
    padding: '60px 20px 20px 20px',
  },
  message: {
    fontSize: '14px',
    color: theme.palette.components.typography.color,
    fontStyle: 'italic',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    ' span': {
      padding: '1rem 2rem 1rem 2rem',
    },
  },
  skeleton: { margin: '5px', padding: '10px', width: '100%', height: '160px' },
  closeSelectionPreview: {
    paddingRight: '8px',
    color: theme.palette.components.button.primary.color,
    backgroundColor: 'transparent',
  },
}));
