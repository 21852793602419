import { FormProvider, useForm } from 'react-hook-form';
import { Typography, Box, TextField, Autocomplete, CircularProgress } from 'amn-ui-core';
import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { GetRejectReasons } from 'utils/common/Ocbr/RejectReason';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { cloneDeep } from 'lodash';
import { usePromiseTracker } from 'react-promise-tracker';

const useStyles = makeStyles<{ changesPending: boolean }>()((theme, props) => ({
  modalContainer: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '350px',
      minHeight: 'auto',
      overflow: 'auto',
      '& .MuiDialogContent-root': {
        overflow: 'auto',
      },
      '&.MuiDialog-paperFullWidth': {
        width: 'calc(50% - 50rem)',
      },
      '&.MuiDialog-paperFullScreen': {
        width: '100%',
        height: '100%',
      },
    },
  },
  validationLoader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: '50%',
    zIndex: 2,
  },
  saveButton: {
    backgroundColor: props.changesPending ? '#006FB9' : '#B8BABC',
    color: '#FCFCFD',
    '&:hover': {
      backgroundColor: '#006FB9',
    },
  },
  cancelButton: {
    color: '#555555',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
    backgroundColor: '#ffffff',
  },
  textareastyle: {
    overflowWrap: 'break-word',
    backgroundColor: '#F7F7F7 !important',
    border: 'thin #ececec solid',
    '&:hover': {
      backgroundColor: '#F7F7F7',
    },
    '&:focus': {
      backgroundColor: '#F7F7F7',
    },
    '&.Mui-focused': {
      backgroundColor: '#F7F7F7',
    },
    textAlign: 'start',
    verticalAlign: 'start',
  },
  inlineBoxEditInputText: {
    width: '300px',
    borderTop: '0',
    borderLeft: '0',
    borderRight: '0',
    borderBottomColor: '#bababa',
    '&:focus-visible': {
      outline: 'none',
      background: '#f2f2f2',
    },
  },
  characterLimit: {
    display: 'flex',
    justifyContent: 'right',
    color: '#999999',
  },
}));

interface IRejectReasonModalProps {
  isModalOpen: boolean;
  facilityId: number;
  setIsModelOpen: any;
  reasonSaved: boolean;
  setReasonSaved: any;
  setReason: any;
}

export const RejectionReasonModal = (props: IRejectReasonModalProps) => {
  const [changesPending, setChangesPending] = useState<boolean>();
  const { classes } = useStyles({ changesPending: changesPending });
  const { t } = useTranslation();
  const formMethods = useForm({
    mode: 'onSubmit',
  });
  const { control } = formMethods;
  const [options, setOptions] = useState<any[]>(GetRejectReasons());
  const [selectedReason, setSelectedReason] = useState<any>();
  const [otherReason, setOtherReason] = useState<string>('');
  const { promiseInProgress: isCallingAPI } = usePromiseTracker({ area: 'update-rate-elements', delay: 0 });
  const onReasonChange = value => {
    setSelectedReason(cloneDeep(value));
    if (value?.reasonId !== '9') {
      setChangesPending(true);
    } else {
      setChangesPending(false);
    }
    if (!value) {
      setChangesPending(false);
    }
  };

  const onOtherReasonChange = e => {
    if (e.target.value?.length >= 150) {
      e.target.value = e.target.value.substring(0, 150);
      e.preventDefault();
      e.stopPropagation();
    }
    setOtherReason(e.target.value);
    if (e.target.value?.length > 0) {
      setChangesPending(true);
    } else {
      setChangesPending(false);
    }
  };

  const cancelReasonDialog = () => {
    props.setIsModelOpen(false);
    setSelectedReason(null);
    setOtherReason('');
    setChangesPending(false);
  };

  const saveRejectReason = () => {
    props.setReasonSaved(true);
    if (otherReason) {
      selectedReason.reason = otherReason;
    }
    props.setReason(selectedReason);
  };

  const onKeyDown = e => {
    const valid =
      /^(Control|Meta|Shift|ArrowLeft|ArrowRight|Backspace|Delete)$/.test(e.key) ||
      ((e.metaKey || e.ctrlKey) && e.keyCode === 65);
    if (!valid && e.target.value.length >= 150) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <FormProvider {...formMethods}>
      <GenericDialog
        open={props.isModalOpen}
        className={classes.modalContainer}
        variant="blue"
        dialogActions={[
          {
            text: 'Cancel',
            variant: 'contained',
            color: 'inherit',
            disabled: isCallingAPI,
            className: classes.cancelButton,
            onClick: cancelReasonDialog,
          },
          {
            text: 'Save',
            variant: 'contained',
            color: 'primary',
            disabled: !changesPending || isCallingAPI,
            className: classes.saveButton,
            onClick: saveRejectReason,
          },
        ]}
      >
        {isCallingAPI && <CircularProgress className={classes.validationLoader} />}
        <Typography variant="subtitle1" style={{ fontWeight: 500, padding: '5px 0px' }}>
          {t('order.rejectReasonModal.title')}
        </Typography>
        <Box>
          <Autocomplete
            options={options}
            popupIcon={<ExpandMoreOutlinedIcon />}
            getOptionLabel={option => option.reason}
            onChange={(e, value) => {
              onReasonChange(value);
            }}
            defaultValue={selectedReason}
            renderInput={params => (
              <TextField
                {...params}
                label={t('order.rejectReasonModal.inputPlaceHolder')}
                variant="filled"
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        </Box>
        {selectedReason && selectedReason.reasonId === '9' && (
          <Box sx={{ display: 'flex', marginTop: '12px', height: 'auto', flex: 1 }}>
            <TextField
              multiline
              label={t('order.rejectReasonModal.otherPlaceHolder')}
              variant="filled"
              InputProps={{
                className: classes.textareastyle,
              }}
              onKeyDown={onKeyDown}
              onChange={e => onOtherReasonChange(e)}
              className={classes.inlineBoxEditInputText}
            />
          </Box>
        )}
        {selectedReason && selectedReason.reasonId === '9' && (
          <div className={classes.characterLimit}>{`${otherReason?.length}/150`}</div>
        )}
      </GenericDialog>
    </FormProvider>
  );
};
