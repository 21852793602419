import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  standardizeButton: {
    fontSize: 12,
    fontWeight: 700,
    margin: '0 1rem',
    'margin-right': '0.2rem',
  },
}));
