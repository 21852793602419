import _ from 'lodash';
import { IR225ReportResponseObject } from 'store/redux-store/report-details/types';
import { missingField } from 'app/constants';
import { convertToFormat } from 'app/helpers/dateHelper';
import i18next from 'i18next';
import { ISummary, R225ResponseFields, SummaryTitle, WeekType, WeeksCategory } from './SalesReportTypes';
import { ICustomGridRows, IFooterRowProps } from './SalesReportCustomGrid';

export const getGroupingData = (data, filterColumn) => {
  const records = [];
  if (data?.length > 0) {
    const groupedItems = _.mapValues(_.groupBy(data, filterColumn));
    for (const item in groupedItems) {
      if (groupedItems.hasOwnProperty(item)) {
        const obj = {
          key: `${[item]}`,
          value: groupedItems[item],
        };
        records.push(obj);
      }
    }
  }
  return records;
};

const getDecimalValue = num => {
  let decimal = num ?? 0;
  return Number(decimal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] as unknown).toFixed(2);
};

const getCountPercentage = (dividend: number, divider: number): string => {
  return (
    (dividend > 0 && divider > 0
      ? getDecimalValue(_.round(_.divide(dividend, divider) * 100, 2))
      : getDecimalValue(0)) + '%'
  );
};

const getWeeksBookedCount = (data: IR225ReportResponseObject[], weeksType: string, weeksCategory?: string) => {
  return weeksCategory
    ? _.sumBy(
        data.filter(x => x.weeksType === weeksType && x.weeksCategory === weeksCategory),
        R225ResponseFields.ChangeWeeksBooked,
      )
    : _.sumBy(
        data.filter(x => x.weeksType === weeksType),
        R225ResponseFields.ChangeWeeksBooked,
      );
};

export const getWeeksType = (records, weeksType) => {
  return _.orderBy(
    records?.filter(x => x.weeksType === weeksType),
    R225ResponseFields.PlacementId,
    'asc',
  );
};

export function getSummaryDetails(data): ISummary[] {
  const convertedData: IR225ReportResponseObject[] = _.map(data, item => {
    let newItem = _.clone(item);
    newItem.changeWeeksBooked = Math.abs(parseInt(newItem?.changeWeeksBooked ?? 0));
    return newItem;
  });

  const WeeksBookedCard = getWeeksBookedCount(convertedData, WeekType.WeeksBooked, WeeksCategory.Card);
  const WeeksBookedPending = getWeeksBookedCount(convertedData, WeekType.WeeksBooked, WeeksCategory.Pending);
  const WeeksBookedRebook = getWeeksBookedCount(convertedData, WeekType.WeeksBooked, WeeksCategory.Rebook);
  const WeeksBookedTotal = getWeeksBookedCount(convertedData, WeekType.WeeksBooked);

  const ExtensionCard = getWeeksBookedCount(convertedData, WeekType.Extension, WeeksCategory.Card);
  const ExtensionPending = getWeeksBookedCount(convertedData, WeekType.Extension, WeeksCategory.Pending);
  const ExtensionRebook = getWeeksBookedCount(convertedData, WeekType.Extension, WeeksCategory.Rebook);
  const ExtensionTotal = getWeeksBookedCount(convertedData, WeekType.Extension);

  const VoidTimeCard = getWeeksBookedCount(convertedData, WeekType.VoidedTimeOff, WeeksCategory.Card);
  const VoidTimePend = getWeeksBookedCount(convertedData, WeekType.VoidedTimeOff, WeeksCategory.Pending);
  const VoidTimeRebook = getWeeksBookedCount(convertedData, WeekType.VoidedTimeOff, WeeksCategory.Rebook);
  const VoidTimeTotal = getWeeksBookedCount(convertedData, WeekType.VoidedTimeOff);

  const cxlByTravelerCard = getWeeksBookedCount(convertedData, WeekType.CxlByTraveler, WeeksCategory.Card);
  const cxlByTravelerPending = getWeeksBookedCount(convertedData, WeekType.CxlByTraveler, WeeksCategory.Pending);
  const cxlByTravelerRebook = getWeeksBookedCount(convertedData, WeekType.CxlByTraveler, WeeksCategory.Rebook);
  const cxlByTravelerTotal = getWeeksBookedCount(convertedData, WeekType.CxlByTraveler);

  const cxlByFacilityCard = getWeeksBookedCount(convertedData, WeekType.CxlByFacility, WeeksCategory.Card);
  const cxlByFacilityPending = getWeeksBookedCount(convertedData, WeekType.CxlByFacility, WeeksCategory.Pending);
  const cxlByFacilityRebook = getWeeksBookedCount(convertedData, WeekType.CxlByFacility, WeeksCategory.Rebook);
  const cxlByFacilityTotal = getWeeksBookedCount(convertedData, WeekType.CxlByFacility);

  const cxlByAMHCard = getWeeksBookedCount(convertedData, WeekType.CxlByAMH, WeeksCategory.Card);
  const cxlByAMHPending = getWeeksBookedCount(convertedData, WeekType.CxlByAMH, WeeksCategory.Pending);
  const cxlByAMHRebook = getWeeksBookedCount(convertedData, WeekType.CxlByAMH, WeeksCategory.Rebook);
  const cxlByAMHTotal = getWeeksBookedCount(convertedData, WeekType.CxlByAMH);

  const SrdAssignmentCard = getWeeksBookedCount(convertedData, WeekType.SrdAssignment, WeeksCategory.Card);
  const SrdAssignmentPending = getWeeksBookedCount(convertedData, WeekType.SrdAssignment, WeeksCategory.Pending);
  const SrdAssignmentRebook = getWeeksBookedCount(convertedData, WeekType.SrdAssignment, WeeksCategory.Rebook);
  const ShortenedAssignmentTotal = getWeeksBookedCount(convertedData, WeekType.SrdAssignment);

  const ApprovedTimeCard = getWeeksBookedCount(convertedData, WeekType.ApprovedTimeOff, WeeksCategory.Card);
  const ApprovedTimePending = getWeeksBookedCount(convertedData, WeekType.ApprovedTimeOff, WeeksCategory.Pending);
  const ApprovedTimeRebook = getWeeksBookedCount(convertedData, WeekType.ApprovedTimeOff, WeeksCategory.Rebook);
  const ApprovedTimeTotal = getWeeksBookedCount(convertedData, WeekType.ApprovedTimeOff);

  const CardTotal = _.subtract(
    _.sum([WeeksBookedCard, VoidTimeCard, ExtensionCard]),
    _.sum([ApprovedTimeCard, cxlByFacilityCard, cxlByTravelerCard, cxlByAMHCard, SrdAssignmentCard]),
  );
  const RebookTotal = _.subtract(
    _.sum([WeeksBookedRebook, VoidTimeRebook, ExtensionRebook]),
    _.sum([ApprovedTimeRebook, cxlByFacilityRebook, cxlByTravelerRebook, cxlByAMHRebook, SrdAssignmentRebook]),
  );
  const PendingTotal = _.subtract(
    _.sum([WeeksBookedPending, VoidTimePend, ExtensionPending]),
    _.sum([ApprovedTimePending, cxlByFacilityPending, cxlByTravelerPending, cxlByAMHPending, SrdAssignmentPending]),
  );
  const Total = _.subtract(
    _.sum([WeeksBookedTotal, VoidTimeTotal, ExtensionTotal]),
    _.sum([ApprovedTimeTotal, cxlByFacilityTotal, cxlByTravelerTotal, cxlByAMHTotal, ShortenedAssignmentTotal]),
  );
  const WeeksBookedCardPercentage = getCountPercentage(WeeksBookedCard, WeeksBookedTotal);
  const WeeksBookedPendPercentage = getCountPercentage(WeeksBookedPending, WeeksBookedTotal);
  const WeeksBookedRebookPercentage = getCountPercentage(WeeksBookedRebook, WeeksBookedTotal);

  const ExtensionCardPercentage = getCountPercentage(ExtensionCard, ExtensionTotal);
  const ExtensionPendPercentage = getCountPercentage(ExtensionPending, ExtensionTotal);
  const ExtensionRebookPercentage = getCountPercentage(ExtensionRebook, ExtensionTotal);

  const VoidTimeCardPercentage = getCountPercentage(VoidTimeCard, VoidTimeTotal);
  const VoidTimePendPercentage = getCountPercentage(VoidTimePend, VoidTimeTotal);
  const VoidTimeRebookPercentage = getCountPercentage(VoidTimeRebook, VoidTimeTotal);

  const cxlByTravelerCardPercentage = getCountPercentage(cxlByTravelerCard, cxlByTravelerTotal);
  const cxlByTravelerPendingPercentage = getCountPercentage(cxlByTravelerPending, cxlByTravelerTotal);
  const cxlByTravelerRebookPercentage = getCountPercentage(cxlByTravelerRebook, cxlByTravelerTotal);

  const cxlByFacilityCardPercentage = getCountPercentage(cxlByFacilityCard, cxlByFacilityTotal);
  const cxlByFacilityPendingPercentage = getCountPercentage(cxlByFacilityPending, cxlByFacilityTotal);
  const cxlByFacilityRebookPercentage = getCountPercentage(cxlByFacilityRebook, cxlByFacilityTotal);

  const cxlByAMHCardPercentage = getCountPercentage(cxlByAMHCard, cxlByAMHTotal);
  const cxlByAMHPendingPercentage = getCountPercentage(cxlByAMHPending, cxlByAMHTotal);
  const cxlByAMHRebookPercentage = getCountPercentage(cxlByAMHRebook, cxlByAMHTotal);

  const ShortenedAssignmentCardPercentage = getCountPercentage(SrdAssignmentCard, ShortenedAssignmentTotal);
  const ShortenedAssignmentPendingPercentage = getCountPercentage(SrdAssignmentPending, ShortenedAssignmentTotal);
  const ShortenedAssignmentRebookPercentage = getCountPercentage(SrdAssignmentRebook, ShortenedAssignmentTotal);

  const ApprovedTimeCardPercentage = getCountPercentage(ApprovedTimeCard, ApprovedTimeTotal);
  const ApprovedTimePendPercentage = getCountPercentage(ApprovedTimePending, ApprovedTimeTotal);
  const ApprovedTimeRebookPercentage = getCountPercentage(ApprovedTimeRebook, ApprovedTimeTotal);

  return [
    {
      title: SummaryTitle.Bookings,
      candidateTotal: WeeksBookedCard,
      candidatesPercentage: WeeksBookedCardPercentage,
      pendingTotal: WeeksBookedPending,
      pendingPercentage: WeeksBookedPendPercentage,
      rebookTotal: WeeksBookedRebook,
      rebookPercentage: WeeksBookedRebookPercentage,
      TotalWeeks: WeeksBookedTotal,
    },
    {
      title: SummaryTitle.Extensions,
      candidateTotal: ExtensionCard,
      candidatesPercentage: ExtensionCardPercentage,
      pendingTotal: ExtensionPending,
      pendingPercentage: ExtensionPendPercentage,
      rebookTotal: ExtensionRebook,
      rebookPercentage: ExtensionRebookPercentage,
      TotalWeeks: ExtensionTotal,
    },
    {
      title: SummaryTitle.VoidedTimeOff,
      candidateTotal: VoidTimeCard,
      candidatesPercentage: VoidTimeCardPercentage,
      pendingTotal: VoidTimePend,
      pendingPercentage: VoidTimePendPercentage,
      rebookTotal: VoidTimeRebook,
      rebookPercentage: VoidTimeRebookPercentage,
      TotalWeeks: VoidTimeTotal,
    },
    {
      title: SummaryTitle.CxlByTraveler,
      candidateTotal: cxlByTravelerCard,
      candidatesPercentage: cxlByTravelerCardPercentage,
      pendingTotal: cxlByTravelerPending,
      pendingPercentage: cxlByTravelerPendingPercentage,
      rebookTotal: cxlByTravelerRebook,
      rebookPercentage: cxlByTravelerRebookPercentage,
      TotalWeeks: cxlByTravelerTotal,
    },
    {
      title: SummaryTitle.CxlByFacility,
      candidateTotal: cxlByFacilityCard,
      candidatesPercentage: cxlByFacilityCardPercentage,
      pendingTotal: cxlByFacilityPending,
      pendingPercentage: cxlByFacilityPendingPercentage,
      rebookTotal: cxlByFacilityRebook,
      rebookPercentage: cxlByFacilityRebookPercentage,
      TotalWeeks: cxlByFacilityTotal,
    },
    {
      title: SummaryTitle.CxlByAMH,
      candidateTotal: cxlByAMHCard,
      candidatesPercentage: cxlByAMHCardPercentage,
      pendingTotal: cxlByAMHPending,
      pendingPercentage: cxlByAMHPendingPercentage,
      rebookTotal: cxlByAMHRebook,
      rebookPercentage: cxlByAMHRebookPercentage,
      TotalWeeks: cxlByAMHTotal,
    },
    {
      title: SummaryTitle.SrdAssignment,
      candidateTotal: SrdAssignmentCard,
      candidatesPercentage: ShortenedAssignmentCardPercentage,
      pendingTotal: SrdAssignmentPending,
      pendingPercentage: ShortenedAssignmentPendingPercentage,
      rebookTotal: SrdAssignmentRebook,
      rebookPercentage: ShortenedAssignmentRebookPercentage,
      TotalWeeks: ShortenedAssignmentTotal,
    },
    {
      title: SummaryTitle.ApprovedTimeOff,
      candidateTotal: ApprovedTimeCard,
      candidatesPercentage: ApprovedTimeCardPercentage,
      pendingTotal: ApprovedTimePending,
      pendingPercentage: ApprovedTimePendPercentage,
      rebookTotal: ApprovedTimeRebook,
      rebookPercentage: ApprovedTimeRebookPercentage,
      TotalWeeks: ApprovedTimeTotal,
    },
    {
      title: SummaryTitle.NetWeeksBooked,
      candidateTotal: CardTotal,
      candidatesPercentage: '',
      pendingTotal: PendingTotal,
      pendingPercentage: '',
      rebookTotal: RebookTotal,
      rebookPercentage: '',
      TotalWeeks: Total,
    },
  ];
}

export function getSummaryGrid(row): ICustomGridRows[] {
  return [
    {
      cellSize: 2,
      labelRowClass: `gridRightAlign`,
      labelRow: i18next.t(''),
      dataRowClass: '',
      dataRow: row?.title ?? missingField,
    },
    {
      cellSize: 1.4,
      labelRowClass: `gridRightAlign`,
      labelRow: i18next.t('reportPages.r225.summaryGridFields.candidates'),
      dataRowClass: `gridRightAlign`,
      dataRow: row?.candidateTotal ?? missingField,
    },
    {
      cellSize: 1.4,
      labelRowClass: `gridRightAlign`,
      labelRow: i18next.t('reportPages.r225.summaryGridFields.candidatesPercentage'),
      dataRowClass: `gridRightAlign`,
      dataRow: row?.candidatesPercentage ?? missingField,
    },
    {
      cellSize: 1.4,
      labelRowClass: `gridRightAlign`,
      labelRow: i18next.t('reportPages.r225.summaryGridFields.pendings'),
      dataRowClass: `gridRightAlign`,
      dataRow: row?.pendingTotal ?? missingField,
    },
    {
      cellSize: 1.4,
      labelRowClass: `gridRightAlign`,
      labelRow: i18next.t('reportPages.r225.summaryGridFields.pendingsPercentage'),
      dataRowClass: `gridRightAlign`,
      dataRow: row?.pendingPercentage ?? missingField,
    },
    {
      cellSize: 1.4,
      labelRowClass: `gridRightAlign`,
      labelRow: i18next.t('reportPages.r225.summaryGridFields.rebooks'),
      dataRowClass: `gridRightAlign`,
      dataRow: row?.rebookTotal ?? missingField,
    },
    {
      cellSize: 1.4,
      labelRowClass: `gridRightAlign`,
      labelRow: i18next.t('reportPages.r225.summaryGridFields.rebooksPercentage'),
      dataRowClass: `gridRightAlign`,
      dataRow: row?.rebookPercentage ?? missingField,
    },
    {
      cellSize: 1.6,
      labelRowClass: `gridRightAlign`,
      labelRow: i18next.t('reportPages.r225.summaryGridFields.totalWeeks'),
      dataRowClass: `gridRightAlign`,
      dataRow: row?.TotalWeeks ?? missingField,
    },
  ];
}

export function getDetailsGrid(row): ICustomGridRows[] {
  return [
    {
      cellSize: 1.5,
      labelRowClass: '',
      labelRow: i18next.t('reportPages.r225.detailsGridFields.candidate'),
      dataRowClass: '',
      dataRow: row?.travelerName + '\n' + row?.travelerId ?? missingField,
    },
    {
      cellSize: 0.5,
      labelRowClass: '',
      labelRow: i18next.t('reportPages.r225.detailsGridFields.type'),
      dataRowClass: '',
      dataRow: row?.type ?? missingField,
    },
    {
      cellSize: 2.3,
      labelRowClass: '',
      labelRow: i18next.t('reportPages.r225.detailsGridFields.facility'),
      dataRowClass: '',
      dataRow: row?.facilityName + '\n' + row?.facilityId ?? missingField,
    },
    {
      cellSize: 1.5,
      labelRowClass: '',
      labelRow: i18next.t('reportPages.r225.detailsGridFields.status'),
      dataRowClass: '',
      dataRow: row?.descActivityStatus ?? missingField,
    },
    {
      cellSize: 0.8,
      labelRowClass: ``,
      labelRow: i18next.t('reportPages.r225.detailsGridFields.pID'),
      dataRowClass: ``,
      dataRow: row?.placementId ?? missingField,
    },
    {
      cellSize: 0.7,
      labelRowClass: 'gridCenterAlign',
      labelRow: i18next.t('reportPages.r225.detailsGridFields.wks'),
      dataRowClass: `gridCenterAlign`,
      dataRow:
        Math.abs(row?.changeWeeksBooked) < 10
          ? '0' + Math.abs(row?.changeWeeksBooked)
          : Math.abs(row?.changeWeeksBooked) ?? missingField,
    },
    {
      cellSize: 1.5,
      labelRowClass: '',
      labelRow: i18next.t('reportPages.r225.detailsGridFields.skillSet'),
      dataRowClass: '',
      dataRow: row?.skillset ?? missingField,
    },
    {
      cellSize: 1,
      labelRowClass: '',
      labelRow: i18next.t('reportPages.r225.detailsGridFields.hAM'),
      dataRowClass: '',
      dataRow: row?.hamName ?? missingField,
    },
    {
      cellSize: 1.1,
      labelRowClass: '',
      labelRow: i18next.t('reportPages.r225.detailsGridFields.start'),
      dataRowClass: '',
      dataRow: row?.startDate ? convertToFormat(row?.startDate, 'MM/DD/yyyy') : missingField,
    },
    {
      cellSize: 1.1,
      labelRowClass: '',
      labelRow: i18next.t('reportPages.r225.detailsGridFields.booked'),
      dataRowClass: '',
      dataRow: row?.bookDate ? convertToFormat(row?.bookDate, 'MM/DD/yyyy') : missingField,
    },
  ];
}

export function getTotalCountFooter(rows): IFooterRowProps {
  const totalCount = _.sumBy(
    _.map(rows, item => {
      let newItem = _.clone(item);
      newItem.changeWeeksBooked = Math.abs(parseInt(newItem.changeWeeksBooked));
      return newItem;
    }),
    R225ResponseFields.ChangeWeeksBooked,
  );
  return {
    labelCellSize: 6.6,
    dataCellSize: 0.7,
    rowData: Math.abs(totalCount) < 10 ? '0' + Math.abs(totalCount) : Math.abs(totalCount) ?? missingField,
  };
}
