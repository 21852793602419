/* eslint-disable i18next/no-literal-string */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
  StyledEngineProvider,
  useTheme
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { SingleLineInput } from '../Common/SingleLineInput';
import { useTranslation } from 'react-i18next';
import { NewUnit } from 'app/models/Unit/CreateUnit';
import { selectIsEditUnit } from 'app/components/Order/Store/Order.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsUnitSelected } from 'app/components/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';
import { orderCreationActions } from '@AMIEWEB/Order/Store/OrderCreation.actions';
import { selectBestBets } from '@AMIEWEB/Order/Store/OrderCreation.selectors';

interface Props {
  unit: NewUnit;
  onUnitPropChange: (property: string, value: any) => void;
  disableEdit?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  selectField: {
    height: 'auto',
    backgroundColor: '#F7F7F7',
  },
  dropdownStyle: {
    border: '1px solid #D1D1D1',
    boxShadow: 'none',
    borderRadius: 3,
    marginTop: '0.5rem',
    width: 100,
    overflowY: 'auto',
    height: '20%',
  },
  menu: {
    color: '#333333',
    fontSize: 12,
    '&:hover, &:focus': {
      backgroundColor: '#F7F7F7',
    },
  },
  textareastyle: {
    backgroundColor: '#fff',
    border: 'thin #e7e2e2 solid',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
    },
  },
}));

export const UnitBestBets = (props: Props) => {
  const { unit, onUnitPropChange, disableEdit } = props;
  const theme= useTheme();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [bestBetId, setBestBetId] = React.useState<string>(unit.bestBetsId.toString());
  const isOrderEditUnit = useSelector(selectIsEditUnit);
  const isUnitEditUnit = useSelector(selectIsUnitSelected);
  const isEditUnit = isUnitEditUnit ? isUnitEditUnit : isOrderEditUnit;
  const handleChange = event => {
    if (event.target.name === 'unitSize') return onUnitPropChange(event.target.name, parseInt(event.target.value));
    onUnitPropChange(event.target.name, event.target.value);
    setBestBetId(event.target.value);
  };

  useEffect(() => {
    dispatch(orderCreationActions.getBestBetsOptions());
  }, []);

  useEffect(() => {
    if (unit && isEditUnit) {
      setBestBetId(unit?.bestBetsId.toString());
    }
  }, [bestBetId, isEditUnit]);

  const bestBets = useSelector(selectBestBets);

  return (
    <div>
      {/* TODO: Remove this theme wrapper since it uses the global theme */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UnitDetailsBody variant="outlined">
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12}>
                <UnitCardsTitle>{t('order.createUnit.bestBetsUnitSize.title')}</UnitCardsTitle>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel>{t('order.createUnit.bestBetsUnitSize.bestBets')}</InputLabel>
                      <Select
                        id={'createUnitBestBetsSizeDropDown'}
                        name={'bestBetsId'}
                        className={classes.selectField}
                        //defaultValue={bestBetId}
                        value={bestBetId}
                        disabled={disableEdit}
                        onChange={handleChange}
                        inputProps={{
                          className: disableEdit ? '' : `${classes.textareastyle}`,
                        }}
                        MenuProps={{
                          classes: { paper: classes.dropdownStyle },
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                        IconComponent={ExpandMoreOutlinedIcon}
                      >
                        {bestBets?.map((item) => {
                          return (
                            <MenuItem key={item.ID} value={item.value} className={classes.menu}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <SingleLineInput
                      id={'createUnitUnitSizeTxt'}
                      inputDefaultValue={0}
                      isdisabled={disableEdit}
                      inputLabel={t('order.createUnit.bestBetsUnitSize.unitSize')}
                      inputName={'unitSize'}
                      inputValue={unit?.unitSize ? unit?.unitSize : ''}
                      handleChange={handleChange}
                      inputType={'number'}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </UnitDetailsBody>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

