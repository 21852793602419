import { GridCellParams } from '@mui/x-data-grid-pro';
import { materialUiXGrid, TextField } from 'amn-ui-core';
import { Box, ClickAwayListener } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import _ from 'lodash';

const useStyles = makeStyles()({
    hidden: {
        display: 'none',
    },
    inputInvalid: {
        color: 'red',
        padding: '10px',        
    },
    inputValid: {
        color: '#a3a2a2',
    },
    textField: {
        fontSize: 12,
        width: '100%',
        '& input::placeholder': {
          fontSize: 12,
        },
      },
});

interface GridTextboxCellProps {
    onEndEdit: ({ value, params }: { value: any; params: GridCellParams }) => void;
    validator?: ({ value, params }: { value: any; params: GridCellParams }) => boolean;
    placeholder?: string;
    mandatory?: ({ value, params }: { value: any; params: GridCellParams }) => boolean;
    editOnSingleClick?: boolean;
    Input?: any;
    dropdownOptions?: any;
    disableCellEdit?: ({ value, params }: { value: any; params: GridCellParams }) => boolean;
    maritialOptions?: any[];
    type: string;
    externalValidator?: ({ value, params }: { value: any; params: GridCellParams }) => any;
}

export const GridTextboxCell =
    ({ ...props }: GridTextboxCellProps) =>
        (params: GridCellParams) => {
            return <XGridEditableCellContent params={params} props={props} />;
        };

const XGridEditableCellContent = ({ props, params }: { props: GridTextboxCellProps; params: GridCellParams }) => {
    const { classes, cx } = useStyles();
    const api = materialUiXGrid.useGridApiContext();
    const { onEndEdit, 
        validator, 
        placeholder, 
        mandatory, 
        editOnSingleClick = false, 
        disableCellEdit = false,
        externalValidator} = props;
    const [firstInteraction, setFirstInteraction] = React.useState<boolean>(true);
    const [editing, setEditing] = React.useState<boolean>(false);
    const [editValue, setEditValue] = React.useState<any>(params.value);
    const [valid, setValid] = React.useState<boolean>(true);

    React.useEffect(() => {
        setEditValue(params.value);
        if (validator && !firstInteraction) {
            setValid(validator({ value: editValue, params: params }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.value, validator]);

    React.useEffect(() => {
        if (validator && !firstInteraction) {
            setValid(validator({ value: editValue, params: params }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, params.value, validator]);

    const onInputChanged = async event =>{        
        setEditValue(event.target?.value);
      };


    React.useEffect(() => {
        if (!_.isString(params.value)) {
            setEditValue('');
        } else {
            setEditValue(params.value);
        }
        if (externalValidator && !firstInteraction && _.isString(params.value)) {
            setValid(externalValidator({ value: editValue, params: params }) ? false : true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.value, externalValidator]);
    
  const _onEndEditing = () => {
    if (editing) {
      setEditing(false);     
      onEndEdit({ value: editValue, params: params });
      if (validator) {
        setValid(validator({ value: editValue, params: params }));
      }
    }
  };
  
  const checkDisabled = () => {
    if (disableCellEdit) {
      return disableCellEdit({ value: editValue, params: params });
    } else return false;
  };
    
    const startEditing = React.useCallback(() => {        
        setFirstInteraction(false);
        if (!editOnSingleClick) setEditing(true);
        setTimeout(() => {
            if (editOnSingleClick) setEditing(true);
            document.getElementById(`grid-cell-input-${params.row.id}-${params.colDef.field}`)?.focus();
        }, 100);
        
    }, [editOnSingleClick, params.colDef.field, params.row.id]);

    return (
        <React.Fragment>
            <ClickAwayListener mouseEvent="onMouseDown" onClickAway={_onEndEditing}>
                {!checkDisabled() ? (
                    <div style={{ width: '100%' }} className={cx({ [classes.hidden]: !editing })}>
                        <TextField
                            variant={'outlined'}
                            size={'small'}
                            type={props.type}
                            disabled={checkDisabled()}
                            className={classes.textField}
                            onSubmit={_onEndEditing}
                            onChange={event => {
                                onInputChanged(event);
                            }}
                            value={editValue === '--' ? '' : editValue}
                        />
                    </div>
                ) : <>{editing ? editValue : ''}</>
                }

            </ClickAwayListener>
            <Box
                onClick={editOnSingleClick ? startEditing : undefined}
                onDoubleClick={!editOnSingleClick ? startEditing : undefined}
                className={cx({
                    [classes.hidden]: editing,
                    [classes.inputInvalid]: !valid || params.row[`${params.field}_error`] === true,
                })}
                style={{ width: '100%', minHeight: '20px' }}
            >
                {editValue ? (
                    externalValidator?.({ value: editValue, params: params }) ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>                            
                            <CustomTooltip tooltipContent={externalValidator?.({ value: editValue, params: params })}>
                                <span
                                    className={cx({
                                        [classes.inputInvalid]: !valid || params.row[`${params.field}_error`] === true,
                                        [classes.inputValid]: valid && !params.row[`${params.field}_error`],
                                    })}
                                >
                                    {editValue}
                                </span>
                            </CustomTooltip>
                        </div>
                    ) : (
                       <span style={{padding: '10px'}}> {editValue} </span>
                    )
                ) : (
                    <div>
                        {mandatory?.({ value: params.value, params }) ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <CustomTooltip tooltipContent={'This field is required'}>
                                    <span
                                        className={cx({
                                            [classes.inputInvalid]: !valid || params.row[`${params.field}_error`] === true,
                                            [classes.inputValid]: valid && !params.row[`${params.field}_error`],
                                        })}
                                    >{`${placeholder || params.colDef.headerName}`}</span>
                                </CustomTooltip>
                            </div>
                        ): <span>{`${placeholder || params.colDef.headerName}`}</span>}
                    </div>
                )}
            </Box>
        </React.Fragment>
    );
};