import { RootState } from 'types';
import { initialState, initialSubmissionPreferences } from './initialState';
import { createSelector } from '@reduxjs/toolkit';

const selectDomain = (state: RootState) => state.createOrderPreferences || initialState;

export const selectCreateOrderPreference = createSelector(
  [selectDomain],
  preferences => preferences.submissionPreferences || initialSubmissionPreferences,
);

export const selectMiscellaneousData = createSelector([selectDomain], data => data.miscellaneous);
export const selectEmail= createSelector([selectDomain], data => data.miscellaneous.disableEmailContainer);
export const selectPacket = createSelector([selectDomain], data => data.miscellaneous.disablePacketContainer);
export const selectIsUnitPreferenceNull = createSelector([selectDomain], data => data?.isUnitPreferenceNull);
export const selectUnitDialoge = createSelector([selectDomain], data => data?.unitDialoge);
export const selectTabValue = createSelector([selectDomain], data => data?.tabValue);
export const selectCustomTabvalue = createSelector([selectDomain], data => data?.customTabvalue);
export const selectUnitInherited = createSelector([selectDomain], data => data?.isUnitInherited);
export const selectFormValues = createSelector([selectDomain], data => data?.formValues);
export const selectFormDirty = createSelector([selectDomain], data => data?.isFormDirty);
