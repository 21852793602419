/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { candidateDetailsSelection, candidateSaveStatusSelector } from '../../../Profile/CandidateDetails.selector';
import ManageGender from '../../ProfileTab/Cards/Gender/ManageGender';
import { RowSection } from '../Common/RowSection';
import { usePromiseTracker } from 'react-promise-tracker';
import { candidateDetailActions } from '../../../Profile/CandidateDetails.redux';
import { ITypeAheadOption } from '../../PreferencesTab/CustomComponents/ControlledTypeAhead';
import { userRoles } from 'oidc/userRoles';
import { Authorized } from 'oidc/userHelper';
import treeData from 'app/assets/jsons/placementStatusFilter.json';
import { selectUser } from 'oidc/user.selectors';
import { IsNameDobEditable } from '../../PersonalInfoTab/Form/helper';

const useStyles = makeStyles()(theme => ({
  content: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingLeft: '5px',
    marginTop: '-2px',
  },
  value: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const GenderOptionsTypeAhead: any = [
  {
    label: 'Male',
    object: {
      ID: 0,
      Description: 'Male',
    },
  },
  {
    label: 'Female',
    object: {
      ID: 1,
      Description: 'Female',
    },
  },
  {
    label: 'Non-Binary',
    object: {
      ID: 2,
      Description: 'Non-Binary',
    },
  },
  {
    label: 'Decline To State',
    object: {
      ID: 3,
      Description: 'Decline To State',
    },
  },
];

export const GenderCard = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUser);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const saveStatus = useSelector(candidateSaveStatusSelector);
  const [gender, setGender] = useState<string>(
    GenderOptionsTypeAhead.find(
      item => item.label === candidateDetails.gender || item.label.replace(/-|\s/g, '') === candidateDetails?.gender,
    )?.label,
  );
  const [open, setOpen] = useState<boolean>(false);
  const [close, setClose] = useState<boolean>(false);
  const [isGenderViewableByUser, setIsGenderViewableByUser] = useState<boolean>(false);
  const [isGenderEditableByUser, setIsGenderEditableByUser] = useState<boolean>(false);
  const [canSelectDeclineToState, setCanSelectDeclineToState] = useState<boolean>(false);
  const genderOptions = canSelectDeclineToState
    ? GenderOptionsTypeAhead
    : GenderOptionsTypeAhead.filter(item => item.object.ID !== 3);
  const { promiseInProgress: genderPostRequestSuccess } = usePromiseTracker({ area: 'gender', delay: 0 });

  const placementStatuses = treeData.filter(item => item.name === 'In Submission' || item.name === 'Offer');
  const allowedStatuses: any[] = [];

  placementStatuses[0].children.map(item => {
    allowedStatuses.push(item.name);
  });
  placementStatuses[1].children.map(item => {
    allowedStatuses.push(item.name);
  });

  useEffect(() => {
    if (genderPostRequestSuccess) {
      setTimeout(() => {
        setOpen(false);
      }, 5000);
    }
  }, [genderPostRequestSuccess]);

  useEffect(() => {
    //Check for View Access
    setIsGenderViewableByUser(!Authorized([userRoles.clinical_ClientContractSpecialist], userInfo.userInfo));

    //Check For Edit Access
    const hasRecruitmentRole = Authorized(
      [userRoles.recruitment_Leadership, userRoles.recruitment_TeamMember],
      userInfo.userInfo,
    );

    const allowEditGender = IsNameDobEditable(
      candidateDetails?.placementStatus || '',
      candidateDetails?.placement?.placementInfo || [],
    );

    if (hasRecruitmentRole && allowEditGender) {
      setIsGenderEditableByUser(true);
    } else {
      setIsGenderEditableByUser(false);
    }

    if (
      Authorized(
        [
          userRoles.credentialing_Leadership,
          userRoles.credentialing_TeamMember,
          userRoles.clinical_QualificationsSpecialist,
        ],
        userInfo.userInfo,
      )
    ) {
      setIsGenderEditableByUser(true);
    }
    if (allowedStatuses.slice(0, allowedStatuses.length - 2).includes(candidateDetails.placementStatus)) {
      setCanSelectDeclineToState(true);
    }
  }, [userInfo.userInfo, candidateDetails?.placementStatus]);

  const handleSave = (selectedValue: ITypeAheadOption) => {
    dispatch(candidateDetailActions.postGender(selectedValue));
    setGender(selectedValue?.object?.Description);
  };

  const handleClose = () => {
    setClose(prevState => !prevState);
  };

  useEffect(() => {
    setOpen(false);
  }, [close]);

  return (
    <Grid
      container
      component="a"
      onClick={() => setOpen(true)}
      direction="row"
      style={{ paddingLeft: '3px', alignItems: 'center' }}
    >
      <Grid item>
        <RowSection
          header={`${t('candidate.profileSummaryCard.gender')}: `}
          data={''}
          preData={gender ? (isGenderViewableByUser ? gender : '*****') : '--'}
          color={props.enableSummaryIconsUpdate?.enabled ? 'Green' : '#006FB9'}
          showHalfwayProgress={
            props.enableSummaryIconsUpdate?.enabled &&
            allowedStatuses
              .slice(0, allowedStatuses.length - 2)
              .find(item => item === candidateDetails.placementStatus) &&
            !candidateDetails.gender
          }
          showHalfwayProgressSecondaryIcon={props.enableSummaryIconsUpdate?.enabled && true}
          showAlert={
            !allowedStatuses
              .slice(0, allowedStatuses.length - 2)
              .find(item => item === candidateDetails.placementStatus) &&
            (!gender || gender === 'DeclineToState' || gender === 'Decline To State')
          }
        />
      </Grid>
      <Grid item className={classes.value}>
        {isGenderEditableByUser ? (
          !open ? (
            <Typography className={classes.content}>
              {gender ? (isGenderViewableByUser ? gender : '*****') : '--'}
            </Typography>
          ) : (
            <Box style={{ marginTop: '-5px' }}>
              <ManageGender
                open={open}
                width={'130px'}
                options={genderOptions}
                handleSave={handleSave}
                saveStatus={saveStatus?.genderSaveStatus}
                handleClose={handleClose}
                selected={gender}
                saveBtnId="save-gender-profile-tab-btn"
                closeBtnId="close-gender-profile-tab-btn"
              />
            </Box>
          )
        ) : (
          <Typography className={classes.content}>
            {gender ? (isGenderViewableByUser ? gender : '*****') : '--'}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
