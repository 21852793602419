import { GridCellParams } from '@mui/x-data-grid-pro';
import React from 'react';
import { DispatchComponent } from 'app/components/GlobalSearch/DrawerHelper';
import { Link } from 'amn-ui-core';

export const XGridSideBarViewCell =
  (path: string, fieldList: string[] = [], isHoverView?: boolean) =>
  (params: GridCellParams) => {
    return <XGridSideBarViewCellContent path={path} fieldList={fieldList} isHoverView={isHoverView} params={params} />;
  };

const XGridSideBarViewCellContent = ({
  path,
  fieldList,
  isHoverView,
  params,
}: {
  path: any;
  fieldList: any;
  isHoverView: any;
  params: GridCellParams;
}) => {
  const [link, setLink] = React.useState<string>('');

  React.useEffect(() => {
    let linkBuild = path;
    fieldList.forEach((id, index) => {
      linkBuild = linkBuild.replace(`{${index}}`, `${params.row[id]}`);
    });
    setLink(linkBuild);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, fieldList]);

  const stopImmediatePropagation = event => {
    if (event.ctrlKey || event.metaKey) {
      // Allow default behavior (opening in a new tab)
      return;
    }
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <Link
      itemID={params.row.previewDrawerViewerId}
      href={link}
      style={{ padding: isHoverView ? '8px 8px' : '0px' }}
      onClick={stopImmediatePropagation}
    >
      <DispatchComponent
        displayValue={params.row.previewDrawerViewerId}
        type={params.row.previewDrawerViewerType}
        selectedIndex={params.row.indexCounter}
        selectedorder={{
          row: params.row,
        }}
      />
    </Link>
  );
};
