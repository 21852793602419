import { missingField } from 'app/constants';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { GetMomentOfDate } from 'utils/dates/moment';
import { ListSection } from '../../../customComponents/ListSection';
import { TableSection } from '../../../customComponents/TableSection';
import { getRequirementCheck } from '../../../helper';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import {
  selectServiceFailures,
  selectSubmissionInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { RequirementsRow } from '../../TableComponents/TableComponents';

export const Application = () => {
  const { t } = useTranslation();
  const {
    amnReq: {
      amn: { application: amnReq },
      candidate: { application: candidateReq },
    },
    requirementFilter,
  } = useSelector(selectSubmissionInfo);
  const { applicationAmnRequirement: serviceFailed } = useSelector(selectServiceFailures);

  const { placementId } = useContext(AddOnContext);

  const { promiseInProgress } = usePromiseTracker({
    area: 'placement-submission-application-amn-requirement',
    delay: 0,
  });

  const dispatch = useDispatch();
  const { display: suspensionDisplay } = useRequirementFilter(
    'submission-amn',
    requirementFilter,
    amnReq?.data.suspensionType,
  );
  const { display: rightToWorkDisplay } = useRequirementFilter(
    'submission-amn',
    requirementFilter,
    amnReq?.data.rightToWorkVerificationType,
  );
  return serviceFailed && !promiseInProgress ? (
    <RequirementsRow
      id="placement-submission-amn-application-failure"
      title={t('placement.profile.summary.submission.amn.application.title')}
      requirementCell=""
      candidateCell=""
      promiseInProgress={false}
      hasError
      onTryAgain={() =>
        dispatch(placementSummaryActions.getApplicationAmnRequirement({ placementId, isRetryingService: true }))
      }
    />
  ) : (
    <>
      {suspensionDisplay ? (
        <RequirementsRow
          id="placement-submission-amn-suspension"
          title={t('placement.profile.summary.submission.amn.application.title')}
          requirementCell={<SuspensionKey application={amnReq} t={t} />}
          candidateCell={<SuspensionValue application={candidateReq} t={t} />}
          loading={promiseInProgress}
          transparentBottom
        />
      ) : null}
      {rightToWorkDisplay ? (
        <RequirementsRow
          id="placement-submission-amn-right-to-work"
          /** Logic to render title when above row is missing */
          title={suspensionDisplay ? '' : t('placement.profile.summary.submission.amn.application.title')}
          requirementCell={<RightToWorkKey application={amnReq} t={t} />}
          candidateCell={<RightToWorkValue application={candidateReq} t={t} />}
          loading={promiseInProgress}
          transparentTop={suspensionDisplay}
        />
      ) : null}
    </>
  );
};

const SuspensionKey = ({ application, t }) => (
  <TableSection
    items={[
      {
        icon: application ? getRequirementCheck(application.data.suspensionType) : null,
        primaryContent: t('placement.profile.summary.submission.amn.application.line1'),
      },
    ]}
  />
);

const RightToWorkKey = ({ application, t }) => (
  <TableSection
    items={[
      {
        icon: application ? getRequirementCheck(application.data.rightToWorkVerificationType) : null,
        primaryContent: t('placement.profile.summary.submission.amn.application.line2'),
      },
    ]}
  />
);

const SuspensionValue = ({ application, t }) => {
  const { suspensionLastUpdated, suspension } = application?.data || {};
  const dateMoment = GetMomentOfDate(suspensionLastUpdated);

  return (
    <ListSection
      items={[
        {
          primaryContent: `${t('placement.profile.summary.submission.amn.application.line3')}: ${
            application ? (suspension ? t('global.Yes') : t('global.No')) : missingField
          }`,
          secondaryContent: `${t('placement.profile.summary.submission.amn.application.line4')}: ${
            application && dateMoment.isValid() ? dateMoment.format('MM/DD/YYYY') : missingField
          }`,
        },
      ]}
    />
  );
};

const RightToWorkValue = ({ application, t }) => {
  const { rightToWorkVerificationUpdated, rightToWorkVerification } = application?.data || {};
  const dateMoment = GetMomentOfDate(rightToWorkVerificationUpdated);
  return (
    <ListSection
      items={[
        {
          primaryContent: `${t('placement.profile.summary.submission.amn.application.line5')}: ${
            application ? (rightToWorkVerification ? t('global.Yes') : t('global.No')) : missingField
          }`,
          secondaryContent: `${t('placement.profile.summary.submission.amn.application.line6')}: ${
            application && dateMoment.isValid() ? dateMoment.format('MM/DD/YYYY') : missingField
          }`,
        },
      ]}
    />
  );
};
