import { GridColumns } from '@mui/x-data-grid-pro';
import { XGridDefaultColumn } from 'app/components/Common/XGrid/cells/XGridDefaults';
import { XGridOverflowCell } from 'app/components/Common/XGrid/cells/XGridOverflowCell';
import { OrderMatchesXGridSideBarViewCell } from './OrderMatchesXGridSideBar';
import { XGridLinkCell } from '@AMIEWEB/Common';

const OrderDefaultCell = {
  ...XGridDefaultColumn,
  flex: 0,
};

export const OrderMatchesViewInfo = (t, exclude = [] as string[]): GridColumns => [
  {
    ...OrderDefaultCell,
    field: 'orderId',
    headerName: t('search.globalSearch.order.gridTags.orderId'),
    width: 150,
    renderCell: XGridOverflowCell(OrderMatchesXGridSideBarViewCell('/order/{0}', ['orderId'], true))(
      OrderMatchesXGridSideBarViewCell('/order/{0}', ['orderId']),
    ),
  },
  {
    ...OrderDefaultCell,
    field: 'vmsReqNo',
    headerName: t('search.globalSearch.order.gridTags.vmsReqNo'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'positionsAvailable',
    headerName: t('search.globalSearch.order.gridTags.positionsAvailable'),
    width: 120,
  },
  {
    ...OrderDefaultCell,
    field: 'maxFilesSent',
    sortable: false,
    headerName: t('search.globalSearch.order.gridTags.maxFilesSent'),
    width: 100,
  },
  {
    ...OrderDefaultCell,
    field: 'filesPending',
    sortable: false,
    headerName: t('search.globalSearch.order.gridTags.filesPending'),
    width: 120,
    description: 'Status includes WFC and RFO',
  },
  {
    ...OrderDefaultCell,
    field: 'filesOut',
    sortable: false,
    headerName: t('search.globalSearch.order.gridTags.filesOut'),
    width: 100,
    description: 'Status includes Sent-Offer',
  },
  {
    ...OrderDefaultCell,
    field: 'mspClient',
    headerName: t('search.globalSearch.order.gridTags.mspClient'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'affiliate',
    headerName: t('search.globalSearch.order.gridTags.affiliate'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'facility',
    headerName: t('search.globalSearch.order.gridTags.facility'),
    width: 280,
    renderCell: XGridOverflowCell(XGridLinkCell('/facility/{0}', ['facilityId'], true))(
      XGridLinkCell('/facility/{0}', ['facilityId']),
    ),
  },
  {
    ...OrderDefaultCell,
    field: 'unit',
    headerName: t('search.globalSearch.order.gridTags.unit'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'skillsets',
    headerName: t('search.globalSearch.order.gridTags.skillSet'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'shifts',
    headerName: t('search.globalSearch.order.gridTags.shifts'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'paysGww',
    sortable: false,
    headerName: t('search.globalSearch.order.gridTags.paysGww'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'guaranteedHours',
    headerName: t('search.globalSearch.order.gridTags.guaranteedHours'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'expectedHours',
    headerName: t('search.globalSearch.order.gridTags.expectedHours'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'reason',
    headerName: t('search.globalSearch.order.gridTags.reason'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'orderBillRate',
    headerName: t('search.globalSearch.order.gridTags.orderBillRate'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'startdate',
    headerName: t('search.globalSearch.order.gridTags.startdate'),
    width: 100,
  },
  {
    ...OrderDefaultCell,
    field: 'endDate',
    headerName: t('search.globalSearch.order.gridTags.orderEndDate'),
    width: 100,
  },
  {
    ...OrderDefaultCell,
    field: 'orderPriority',
    headerName: t('search.globalSearch.order.gridTags.orderPriority'),
    width: 280,
  },
  {
    ...OrderDefaultCell,
    field: 'weeklyGrossPay',
    headerName: t('search.globalSearch.order.gridTags.weeklyGrossPay'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'accountManager',
    headerName: t('search.globalSearch.order.gridTags.accountManager'),
    width: 145,
  },
  {
    ...OrderDefaultCell,
    field: 'city',
    headerName: t('search.globalSearch.order.gridTags.city'),
    width: 130,
  },
  {
    ...OrderDefaultCell,
    field: 'stateName',
    headerName: t('search.globalSearch.order.gridTags.state'),
    width: 90,
  },
  {
    ...OrderDefaultCell,
    field: 'zipCode',
    headerName: t('search.globalSearch.order.gridTags.zip'),
    width: 120,
  },
  {
    ...OrderDefaultCell,
    field: 'division',
    headerName: t('search.globalSearch.order.gridTags.division'),
    width: 90,
  },
  {
    ...OrderDefaultCell,
    field: 'orderType',
    headerName: t('search.globalSearch.order.gridTags.type'),
    width: 90,
  },
  {
    ...OrderDefaultCell,
    field: 'businessRelationship',
    headerName: t('search.globalSearch.order.gridTags.businessRelationship'),
    width: 180,
  },
  {
    ...OrderDefaultCell,
    field: 'facilityStatus',
    headerName: t('search.globalSearch.order.gridTags.facilityStatus'),
    width: 130,
  },
  {
    ...OrderDefaultCell,
    field: 'dateEntered',
    headerName: t('search.globalSearch.order.gridTags.ordersAge'),
    width: 100,
  },
  {
    ...OrderDefaultCell,
    field: 'dateVerified',
    headerName: t('search.globalSearch.order.gridTags.dateVerified'),
    width: 100,
  },
  {
    ...OrderDefaultCell,
    field: 'covidVaccinationRequired',
    sortable: false,
    headerName: t('search.globalSearch.order.gridTags.covidVaccinationRequired'),
    width: 90,
    description: 'COVID Vaccination Required',
  },
  {
    ...OrderDefaultCell,
    field: 'staffingVendor',
    headerName: t('search.globalSearch.order.gridTags.staffingVendor'),
    width: 145,
  },
  {
    ...OrderDefaultCell,
    field: 'techVendor',
    headerName: t('search.globalSearch.order.gridTags.techVendor'),
    width: 145,
  },
  {
    ...OrderDefaultCell,
    field: 'region',
    headerName: t('search.globalSearch.order.gridTags.region'),
    width: 145,
  },
];

export const orderMatchesColumnVisibilityModel = {
  orderId: true,
  vmsReqNo: true,
  positionsAvailable: true,
  maxFilesSent: true,
  filesPending: true,
  filesOut: true,
  mspClient: true,
  affiliate: true,
  facility: true,
  unit: true,
  skillsets: true,
  shifts: true,
  paysGww: true,
  guaranteedHours: true,
  expectedHours: true,
  reason: true,
  orderBillRate: true,
  startdate: true,
  endDate: true,
  orderPriority: true,
  weeklyGrossPay: true,
  accountManager: true,
  city: true,
  state: true,
  zipCode: true,
  division: true,
  orderType: true,
  businessRelationship: true,
  facilityStatus: true,
  dateEntered: true,
  dateVerified: true,
  covidVaccinationRequired: true,
  staffingVendor: true,
  techVendor: true,
  region: false,
};
