import { Button, Chip, Grid, Menu, MenuItem, Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { selectPlacementContactsData } from 'store/redux-store/placement-details/selectors';
import { selectUser } from 'oidc/user.selectors';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { selectCoveredEmails } from 'store/redux-store/user-coverage/selectors';
import { ff_notifications_coverage_email } from 'app/constants/FeatureFlags/EmployeeCoverage/keys';
import { useDecision } from '@optimizely/react-sdk';
import { notificationSelection, selectEmailBCC, selectSender } from 'store/redux-store/notification/notification.selector';
import {
  IUserDetailPayload,
  getUserManagementActions,
} from '@AMIEWEB/Settings/UserManagement/store/userManagement.redux';
import { useTranslation } from 'react-i18next';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { ff_notifications_enable_shareddesk_communication } from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';
import { ISenderMenu } from 'app/models/Notification/Notification';
import { getActualBCCs, getSelectedSender } from './EmailHelper';

const useStyles = makeStyles()((theme: Theme) => ({
  box: {
    borderBottom: '1px solid #B9BBBC',
    borderRadius: '0px',
    width: '100%',
    marginBottom: '0.2%',
    background: '#FBFBFB',
    height: '46px',
    padding: '6px 6px 6px 6px',
  },
  chip: { background: '#fff', color: '#333', margin: '0px 4px ' },
  fromGrid: {
    display: 'flex',
    flexDirection: 'row',
  },
  fromBtn: {
    minWidth: '30px',
    minHeight: '30px',
    padding: '3px 8px',
    '&:hover': {
      backgroundColor: '#FBFBFB !important',
    },
  },
  fromButtonDiv: { color: 'black', fontWeight: 'bold', textTransform: 'capitalize', fontSize: '15px' },
  optionSelected: {
    backgroundColor: '#F5F5F5',
  },
  selectedMenuItem: {
    backgroundColor: '#EFEFEF',
  },
  notSelectedMenuItem: {},
  menuItem: {
    marginTop: '5px',
  },
  menuDiv: {
    minWidth: '200px',
    width: '200px',
    maxHeight: '190px',
    overflowY: 'auto',
  },
}));

export const FromField = props => {
  const { packetSubmission = false, clientConfirmation = false, senderMenuValue, setSenderMenuValue, setDefaultEmailData } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const [coverageFlag] = useDecision(ff_notifications_coverage_email);
  const [sharedDeskFlag] = useDecision(ff_notifications_enable_shareddesk_communication);
  const globalData = useSelector(notificationSelection);
  const user = useSelector(selectUser);
  const senderInfo = useSelector(selectSender);
  const bccs = useSelector(selectEmailBCC);
  const placementContactsData = useSelector(selectPlacementContactsData);
  const coverageInfo = useSelector(selectCoveredEmails);
  const [currentUser, setCurrentUser] = useState<ISenderMenu>(null);
  const [placementAccountManager, setPlacementAccountManager] = useState<ISenderMenu>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fromOpen, setFromOpen] = useState<boolean>(false);
  const [menuItems, setMenuItems] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState<ISenderMenu>(null);
  const [currentChip, setCurrentChip] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const coverageData = coverageInfo?.map(cov => ({
    id: cov?.employeeId,
    name: cov?.firstName + ' ' + cov?.lastName,
    email: cov?.email,
    coveredUser: true,
  }));

  const generateMenuItems = () => {
    let menuList = [];
    if (packetSubmission || clientConfirmation) {
      if (placementAccountManager?.email !== currentUser?.email) {
        menuList = coverageFlag.enabled
          ? [placementAccountManager, currentUser, ...coverageData]
          : [placementAccountManager, currentUser];
      } else {
        menuList = coverageFlag.enabled ? [placementAccountManager, ...coverageData] : [placementAccountManager];
      }
    } else {
      menuList = coverageFlag.enabled ? [currentUser, ...coverageData] : [currentUser];
    }
    setMenuItems(menuList);
    setIsDisabled(menuList?.length < 2);
  };

  useEffect(() => {
    generateMenuItems();
  }, [currentUser, placementAccountManager, packetSubmission, clientConfirmation, coverageFlag.enabled]);

  useEffect(() => {
    if (selectedMenu) {
      const selectedUser = getSelectedSender(selectedMenu, currentUser, packetSubmission, clientConfirmation);
      if (senderInfo?.userId !== selectedUser?.userId) {
        const filteredBCCs = getActualBCCs(selectedUser, selectedMenu, bccs, senderInfo, user?.userInfo);
        dispatch(notificationDataActions.setEmailBCC(filteredBCCs));
      }
      dispatch(notificationDataActions.setEmailSender(selectedUser));
      setCurrentChip(selectedMenu?.name);
      setSenderMenuValue(selectedMenu);
    }
  }, [selectedMenu]);

  // fetching user details for signature substitution
  useEffect(() => {
    if (menuItems) {
      const coverageIds = menuItems?.filter(menu => menu?.id != currentUser?.id).map(user => user?.id);
      if (coverageIds?.length > 0) {
        const payload: IUserDetailPayload = {
          employeeIds: coverageIds,
        };
        dispatch(getUserManagementActions.getUserDetail(payload));
      }
    }
  }, [coverageInfo]);

  useEffect(() => {
    const placementManager: ISenderMenu = {
      name: `${placementContactsData?.accountManager?.firstName} ${placementContactsData.accountManager?.lastName}`,
      email: `${placementContactsData?.accountManager?.email}`,
      phoneNumber: `${placementContactsData?.accountManager?.phoneNumber}`,
      id: `${placementContactsData?.accountManager?.id}`,
    };
    setPlacementAccountManager(placementManager);
    const sharedProfile = user.userInfo?.sharedProfile;
    const currentUsr: ISenderMenu = {
      name:
        sharedDeskFlag.enabled && !isNullOrUndefined(sharedProfile)
          ? `${user.userInfo?.sharedProfile?.firstName} ${user.userInfo?.sharedProfile?.lastName}`
          : `${user.userInfo?.firstName} ${user.userInfo?.lastName}`,
      email:
        sharedDeskFlag.enabled && !isNullOrUndefined(sharedProfile)
          ? `${user.userInfo?.sharedProfile?.email}`
          : `${user.userInfo?.email}`,
      phoneNumber: `${user.userInfo?.phoneNumber}`,
      id: `${user.userInfo?.employeeId}`,
    };
    setCurrentUser(currentUsr);
    const selectedMenuItem: ISenderMenu = senderMenuValue
      ? senderMenuValue
      : packetSubmission || clientConfirmation
      ? placementManager
      : currentUsr;
    setSelectedMenu(selectedMenuItem);
    setCurrentChip(selectedMenuItem.name);

    // setting default email data to handle confirmation popup
    setDefaultSenderEmail(selectedMenuItem, currentUsr);
  }, []);

  const setDefaultSenderEmail = (selectedMenuItem: ISenderMenu, currentUsr: ISenderMenu) => {
    const selectedUser = getSelectedSender(selectedMenuItem, currentUsr, packetSubmission, clientConfirmation);
    setDefaultEmailData({
      ...globalData?.email?.data,
      sender: selectedUser,
    });
  };

  const handleItemClick = (menuItem, index) => {
    setSelectedMenu(menuItem);
    setFromOpen(false);
  };

  const handleIconClick = () => event => {
    setFromOpen(prev => !prev);
    setAnchorEl(event.currentTarget);
  };
  const handleFromMenuClose = () => {
    setFromOpen(false);
    setAnchorEl(null);
  };

  return (
    <div className={classes.box}>
      <Grid className={classes.fromGrid}>
        <div>
          <Button
            onClick={handleIconClick()}
            id="from-button"
            className={classes.fromBtn}
            disabled={isDisabled}
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
            data-testid="from-button"
          >
            <div className={classes.fromButtonDiv} style={{ color: isDisabled ? '#BDBDBD' : 'black' }}>
              {'From '}
            </div>
            {fromOpen ? (
              <KeyboardArrowUp color={isDisabled ? 'disabled' : 'action'} />
            ) : (
              <KeyboardArrowDown color={isDisabled ? 'disabled' : 'action'} />
            )}
          </Button>
          <Menu
            id="from-button-menu"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            open={fromOpen}
            onClose={handleFromMenuClose}
            data-testid="from-button-menu"
          >
            <div className={classes.menuDiv}>
              {menuItems.map((menuItem, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleItemClick(menuItem, index)}
                  className={cx(classes.menuItem, selectedMenu?.id === menuItem?.id && classes.selectedMenuItem)}
                >
                  {menuItem?.name}
                </MenuItem>
              ))}
            </div>
          </Menu>
        </div>
        <Chip variant="outlined" size="medium" label={currentChip} className={classes.chip} />
      </Grid>
    </div>
  );
};
