/* eslint-disable i18next/no-literal-string */
import * as React from 'react';
import { accessTokenRequest, loginTokenRequest, msalInstance } from 'oidc/userManager';
import { CircularProgress } from 'amn-ui-core';
import { selectUser } from 'oidc/user.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from 'oidc/user.redux';
import { Layout } from './layout';
import { Authorized } from 'oidc/userHelper';
import { NetworkError } from './components/Common/NetworkError/NetworkError';
import { Unauthenticated } from 'oidc/Unauthenticated';
import { Unauthorized } from 'oidc/Unauthorized';
import { IRoles, userRoles } from 'oidc/userRoles';
import { setAuthenticatedUserContext } from 'app-insights/AppInsights';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { SignoutCallback } from 'oidc/SignoutCallback';
import { trackEvent } from 'app-insights/appInsightsTracking';
import { WalkMe } from 'utils/walkme/walkme';

export const PrivateRoute = ({
  Component,
  roles = [userRoles.all],
  removeLayout = false,
  ...rest
}: {
  [x: string]: any;
  Component: any;
  roles?: (keyof IRoles)[] | undefined;
  removeLayout?: boolean;
}) => {
  const userInfo = useSelector(selectUser);
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();
  const [authorized, setAuthorized] = React.useState<boolean | undefined>();

  React.useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None && userInfo?.userInfo?.employeeId === undefined) {
      instance
        .acquireTokenSilent(accessTokenRequest(accounts))
        .then(tokenResponse => {
          trackEvent({
            type: 'event',
            name: 'Requesting UserInfo',
            properties: { accounts: accounts },
          });
          setAuthenticatedUserContext(accounts[0].idTokenClaims?.['oid'], accounts[0].username);
          dispatch(
            userActions.requestUserInfo({
              user: accounts[0],
              accessToken: tokenResponse.accessToken,
            }),
          );
        })
        .catch(error => {
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            trackEvent({
              type: 'event',
              name: 'Aquiring Silent Token',
              properties: { accounts: accounts },
            });
            return msalInstance.acquireTokenRedirect(accessTokenRequest(accounts));
          }
        });
    } else if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance
        .loginRedirect(loginTokenRequest)
        .then(value => {
          console.info('login request successful');
        })
        .catch(error => {
          trackEvent({
            type: 'error',
            name: 'Login error',
            properties: {
              error: error,
            },
          });
          console.error('Login request error');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, inProgress]);

  React.useEffect(() => {
    if (userInfo?.userInfo?.employeeId) setAuthorized(Authorized(roles, userInfo?.userInfo));
  }, [roles, userInfo?.userInfo]);

  return (
    <>
      {isAuthenticated && <WalkMe />}
      {inProgress === InteractionStatus.Logout && <SignoutCallback />}
      {inProgress !== InteractionStatus.Logout && (
        <>
          {userInfo.error && <NetworkError />}
          {!userInfo.error && (
            <>
              {userInfo?.userInfo?.employeeId !== undefined && (
                <>
                  {isAuthenticated && authorized && <Layout {...rest} Component={Component} />}
                  {authorized === false && isAuthenticated && <Unauthorized />}
                  {!isAuthenticated && <Unauthenticated />}
                </>
              )}
              {userInfo?.userInfo?.employeeId === undefined && (
                <div className={'center-fullscreen'}>
                  <CircularProgress />
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
