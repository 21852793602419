/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui';
import { ToastContainer } from 'react-toastify';
import styledComponent from 'styled-components';
import { IGetActivityFeedQuery } from 'app/models/ActivityFeed/IGetActivityFeed';
import { theme } from 'styles/global-styles';
import { NotificationCategory } from 'app/models/Tasks/Tasks';
import { getEmployeeId } from './Common_v2/utils';

export const useStyles = makeStyles()(() => ({
  // @ts-ignore
  centerItem: {
    alignSelf: 'center',
    justifyContent: 'center',
    padding: '0 15px',
  },
  closeIcon: {
    display: 'block',
    height: '27px',
    width: '27px',
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.framework.system.lightGray}`,
    borderRadius: '15px',
    padding: '3px',
    boxShadow: `0px 0px 6px ${theme.palette.system.lightBlack}`,
    cursor: 'pointer',
    transition: '0.3s ease',
    position: 'absolute',
    left: '-12px',
    top: '-12px',
  },
  tab: {
    minWidth: 50,
    width: 55,
    height: 10,
    opacity: 1,
    '&.Mui-selected': {
      borderColor: theme.palette.system.red,
    },
    '& .MuiTab-root': {
      color: theme.palette.system.darkSlateBlue,
      opacity: 1,
    },
  },
  badge: {
    '& .MuiBadge-colorError': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.framework.system.red,
    },
    '& .MuiBadge-badge ': {
      minWidth: '20px',
      fontSize: '0.60rem',
      height: '17px',
    },
  },
  countBadge: {
    '& .MuiBadge-colorError': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.framework.system.red,
    },
    '& .MuiBadge-badge ': {
      height: '17px',
      fontSize: '0.6rem',
      minWidth: '30px',
    },
  },
}));

export const StyledToastContainer = styledComponent(ToastContainer)`
  .Toastify__toast-container--top-center {
    transform: translateX(-50%);
  }
  .Toastify__toast {
    top: 2.5rem;
    right: 1.8rem;
    width: 350px;
    min-height: 119px;
    background: ${theme.palette.common.white} 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px ${theme.palette.system.lightBlack};
    border: 1px solid ${theme.palette.framework.system.lightGray};
    border-radius: 5px;
    opacity: 1;
    padding: 0px;
    margin:0px;
    overflow: initial;
    '&:hover': {
      background: ${theme.palette.common.white} 0% 0% no-repeat padding-box;
      box-shadow: 10px 10px 16px ${theme.palette.system.lightBlack};
      border: 1px solid ${theme.palette.framework.system.lightGray};
      border-radius: 5px;
    }
  }
  .Toastify__toast-body {
    padding: 0;
    margin: 0;
  }
`;

export const StyledToastContainerSMS = styledComponent(ToastContainer)`
  .Toastify__toast-container--top-center {
    transform: translateX(-50%);
  }
  .Toastify__toast {
    top: 2.5rem;
    right: 1.8rem;
    width: 330px;
    min-height: 85px;
    background: ${theme.palette.common.white} 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px ${theme.palette.system.lightBlack};
    border: 1px solid ${theme.palette.framework.system.lightGray};
    border-radius: 5px;
    opacity: 1;
    overflow: initial;
    '&:hover': {
      background: ${theme.palette.common.white} 0% 0% no-repeat padding-box;
      box-shadow: 10px 10px 16px ${theme.palette.system.lightBlack};
      border: 1px solid ${theme.palette.framework.system.lightGray};
      border-radius: 5px;
    }
  }
  .Toastify__toast-body {
    padding: 0;
    margin: 0;
  }
`;

export const StyledMultiLineToastContainer = styledComponent(ToastContainer)`
  .Toastify__toast-container--top-center {
    transform: translateX(-50%);
  }
  .Toastify__toast {
    top: 2.5rem;
    right: 1.5rem;
    width: 350px;
    min-height: 119px;
    background: ${theme.palette.common.white} 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px ${theme.palette.system.lightBlack};
    border: 1px solid ${theme.palette.framework.system.lightGray};
    border-radius: 5px;
    opacity: 1;
    padding: 0px;
    margin:0px;
    overflow: initial;
    '&:hover': {
      background: ${theme.palette.common.white} 0% 0% no-repeat padding-box;
      box-shadow: 10px 10px 16px ${theme.palette.system.lightBlack};
      border: 1px solid ${theme.palette.framework.system.lightGray};
      border-radius: 5px;
    }
  }
  .Toastify__toast-body {
    padding: 0;
    margin: 0;
  }
`;

export interface INotificationPopperProps {
  id?: number | null;
  currentPopper?: string;
  openPopper: boolean;
  currentRef?: React.RefObject<HTMLButtonElement>;
  selectedTypes?: {
    type: string[];
    subTypes: string[];
  };
}

export const filterPreferencesId = 'Activity-Feed-Filter';

export const fetchPayloadforEmptyFilters = (currentFeed, LogsPayload, userInfo) => {
  if (currentFeed?.toLowerCase() === NotificationCategory.Alert.toLowerCase()) {
    LogsPayload.Activities = {
      ContactId: getEmployeeId(userInfo?.userInfo),
      UseTypes: ['Cue Note'],
      UseSubTypes: ['6', '7'],
    };
    LogsPayload.EmailDeliveries = {
      UserId: getEmployeeId(userInfo?.userInfo),
      DeliveryStatus: ['dropped', 'blocked', 'bounce', 'spamreport', 'unsubscribe'],
    };
  } else if (currentFeed?.toLowerCase() === NotificationCategory.Task.toLowerCase()) {
    LogsPayload.Activities = {
      ContactId: getEmployeeId(userInfo?.userInfo),
      UseTypes: ['Task'],
      UseSubTypes: ['1', '2', '3', '4', '5'],
    };
  } else if (currentFeed?.toLowerCase() === NotificationCategory.Preferences.toLowerCase()) {
    LogsPayload.Activities = {
      ContactId: getEmployeeId(userInfo?.userInfo),
      UseTypes: ['order match'],
      UseSubTypes: [],
    };
  }
};

export const defaultLogsPayload = (selectedType, currentTab, userInfo, PageNumber) => {
  let payload: IGetActivityFeedQuery = {};
  const EmailDeliveries = {
    UserId: getEmployeeId(userInfo?.userInfo),
    DeliveryStatus: ['dropped', 'blocked', 'bounce', 'spamreport', 'unsubscribe'],
  };

  payload.Activities = {
    UseTypes: selectedType?.type,
    UseSubTypes: selectedType?.subTypes,
    ContactId: getEmployeeId(userInfo?.userInfo),
  };

  if (currentTab === 'Alert') {
    payload.EmailDeliveries = EmailDeliveries;
  }

  if (selectedType?.type?.length === 0 && selectedType?.subTypes?.length === 0) {
    fetchPayloadforEmptyFilters(currentTab, payload, userInfo);
  }
  payload.PageSize = 20;
  payload.PageNumber = PageNumber;
  return payload;
};

export const payloadWithFilters = (appliedFilters, currentFeed, userInfo, PageNumber) => {
  const LogsPayload: IGetActivityFeedQuery = {
    PageSize: 20,
    PageNumber: PageNumber,
  };
  if (appliedFilters?.length > 0) {
    if (
      appliedFilters?.filter(x => x?.name === 'Task' || x?.name === 'Cue Note' || /^order match$/i.test(x?.name))
        .length > 0
    ) {
      const type: any[] = appliedFilters
        ?.filter(x => x?.name === 'Task' || x?.name === 'Cue Note' || /^order match$/i.test(x?.name))
        .map(x => (x?.name === 'Order Match' ? x?.name.toLowerCase() : x.name));
      const subType: any[] = [];
      appliedFilters
        ?.filter(x => x?.name === 'Task' || x?.name === 'Cue Note')
        .map(x => {
          x?.children?.map(y => subType?.push(y?.value));
        });

      LogsPayload.Activities = {
        ContactId: getEmployeeId(userInfo?.userInfo),
        UseTypes: type,
        UseSubTypes: subType,
      };
    }
    if (appliedFilters?.filter(x => x?.name === 'Email Status').length > 0) {
      const deliveryStatus: any[] = [];
      appliedFilters
        ?.filter(x => x?.name === 'Email Status')
        .forEach(x => {
          x?.children?.forEach(y => {
            let name = y?.name.toLowerCase();
            name = name === 'spam report' ? 'spamreport' : name;
            deliveryStatus?.push(name);
          });
        });
      LogsPayload.EmailDeliveries = {
        UserId: getEmployeeId(userInfo?.userInfo),
        DeliveryStatus: deliveryStatus,
      };
    }
  }
  if (
    appliedFilters?.filter(
      x => x?.name === 'Task' || x?.name === 'Cue Note' || /^order match$/i.test(x?.name) || x?.name === 'Email Status',
    )?.length === 0
  ) {
    fetchPayloadforEmptyFilters(currentFeed, LogsPayload, userInfo);
  }
  const unreadFilterExist = appliedFilters?.find(
    item => item?.name === NotificationCategory?.Unread && item?.id === `${currentFeed}-id`,
  );
  if (unreadFilterExist && unreadFilterExist?.value) {
    LogsPayload.ReadStatus = false;
  }
  return LogsPayload;
};

export enum FilterType {
  Task = '1',
  CueNote = '2',
  EmailStatus = '3',
  OrderMatch = '4',
}

export const CheckFilterPrefernceDataRequirement = (item: any, currentPopper: string) => {
  if (currentPopper?.toLowerCase() === NotificationCategory.Alert.toLowerCase()) {
    return (
      (item?.name !== 'Cue Note' && item?.name !== 'Email Status') ||
      (item?.name === 'Unread' && item?.id !== `${currentPopper}-id`)
    );
  } else if (currentPopper?.toLowerCase() === NotificationCategory.Task.toLowerCase()) {
    return item?.name !== 'Task' || (item?.name === 'Unread' && item?.id !== `${currentPopper}-id`);
  } else if (currentPopper?.toLowerCase() === NotificationCategory.Preferences.toLowerCase()) {
    return item?.name !== 'order match' || (item?.name === 'Unread' && item?.id !== `${currentPopper}-id`);
  }
};

export const mergePreferenceFilters = (array1: any[], array2: any[], currentPopper: string): any[] => {
  const newResponse = [];
  array1?.forEach(item => {
    const existingitem = array2?.find(key => key?.name === item?.name && (item?.id ? item?.id === key?.id : true));
    if (existingitem) {
      newResponse?.push(existingitem);
    } else {
      const isDataRequired = CheckFilterPrefernceDataRequirement(item, currentPopper);
      if (isDataRequired) newResponse.push(item);
    }
  });
  array2?.forEach(item => {
    const existingData = newResponse?.find(key => key?.name === item?.name && (item?.id ? item?.id === key?.id : true));
    const existingDataIndex = newResponse?.findIndex(
      key => key?.name === item?.name && (item?.id ? item?.id === key?.id : true),
    );
    if (existingData) {
      newResponse[existingDataIndex] = item;
    } else {
      newResponse.push(item);
    }
  });
  if (currentPopper === NotificationCategory.Preferences) {
    const isOrderMatchFilterExists = array2?.find(item => item?.value === '4');
    if (!isOrderMatchFilterExists) {
      return newResponse?.filter(item => item?.value !== '4');
    }
  }
  return newResponse;
};

export const filterLogic = (filter, currentTab, optionList = false) => {
  let filterData = {};
  switch (currentTab?.toLowerCase()) {
    case NotificationCategory.Alert.toLowerCase():
      filterData = filter.value === FilterType.CueNote || filter.value === FilterType.EmailStatus;
      break;
    case NotificationCategory.Task.toLowerCase():
      filterData = filter.value === FilterType.Task;
      break;
    case NotificationCategory.Preferences.toLowerCase():
      filterData = optionList ? false : filter.value === FilterType.OrderMatch;
      break;
    default:
      return filterData;
  }
  return filterData;
};

export const getSelectedFiltersBasedOnTab = (currentTab, appliedFilters) => {
  const appliedFiltersPerFeed = appliedFilters?.filter(filter => {
    return filterLogic(filter, currentTab, false);
  });
  updateUnreadFilter(appliedFilters, appliedFiltersPerFeed, currentTab);
  return appliedFiltersPerFeed;
};

export const updateUnreadFilterOption = (appliedFiltersPerFeed: any[], currentPopper) => {
  appliedFiltersPerFeed?.push({
    children: [],
    name: NotificationCategory.Unread,
    value: true,
    id: `${currentPopper}-id`,
  });
};

export const updateUnreadFilter = (appliedFilters, appliedFiltersPerFeed, currentPopper) => {
  if (appliedFilters && appliedFilters?.length > 0) {
    const existingUnreadFilter = appliedFilters?.find(
      alert => alert?.name === NotificationCategory.Unread && alert?.id === `${currentPopper}-id`,
    );
    if (existingUnreadFilter) {
      appliedFiltersPerFeed.push(existingUnreadFilter);
      return existingUnreadFilter?.value;
    } else {
      updateUnreadFilterOption(appliedFiltersPerFeed, currentPopper);
      return true;
    }
  } else {
    updateUnreadFilterOption(appliedFiltersPerFeed, currentPopper);
    return true;
  }
};
