import { customSwitch } from "utils/customSwitchCase/customSwitch";

/**
 * Converts a base64 encoded string to Blob
 * @param data base64 encoded string
 * @returns Blob
 */
export const base64DocumentToBlob = (
  data: string,
  fileType: string = 'application/pdf',
): { blob: Blob | null; dispose: () => void } => {
  var base64str = data;
  // decode base64 string, remove space for IE compatibility
  var binary = atob(base64str.replace(/\s/g, ''));
  var len = binary.length;
  var buffer = new ArrayBuffer(len);
  var view = new Uint8Array(buffer);
  for (var i = 0; i < len; i++) {
    /** Can fail in case of large files */
    view[i] = binary.charCodeAt(i);
  }
  // create the blob object with content-type
  var blob: Blob | null = new Blob([view], { type: fileType });
  return {
    blob,
    dispose: () => {
      blob = null;
    },
  };
};
/**
 * Converts a blob to a local URL
 * @param blob: Blob to create a URL from
 */
export const blobToURL = (blob: Blob): string => {
  return URL.createObjectURL(blob);
};
/**
 * Converts base64 encoded document files to a local URL to open in a new tab
 *
 * @param data Document as base64 encoded string
 * @param options options for interacting with the document
 * @param options.fileType - the type of file that will be displayed
 * @param options.autoOpen automatically opens the document in a new tab.
 * @param options.target controls whether the document opens in the same tab or a new tab
 * @param options.toolbar shows or hides the document viewer toolbar in supporting browsers
 *
 * @returns URL string
 */
export const base64DocumentToURL = (
  data: string,
  options?: {
    dispose?: () => void;
    fileType?: string;
    autoOpen?: boolean;
    target?: '_self' | '_blank' | '_top' | '_parent';
    toolbar?: boolean;
    download?: boolean;
    fileName?: string;
  },
): { url: string; dispose: () => void } => {
  const { blob, dispose } = base64DocumentToBlob(data, options?.fileType);
  const url = blob ? blobToURL(blob) : null;
  if (url && options?.autoOpen) {
    window.open(`${url}#toolbar=${options.toolbar ? 1 : 0}`, options?.target ?? '_blank');
  } else if (url && options?.download) {
    const link = document.createElement('a');
    link.href = url;
    link.download = options?.fileName ?? 'file';
    link.click();
  }
  return {
    url: url ?? window.location.href,
    dispose: () => {
      if (url) URL.revokeObjectURL(url);
      dispose();
    },
  };
};

export const extractMimeType = (fileName: string) => {
  const extention = fileName.split('.').pop();
  return getMimeTypeOf(extention)
}

export const getMimeTypeOf = customSwitch({
  ['pdf']: 'application/pdf',
  ['txt']:  'text/plain',
  ['doc']: 'application/msword'
})(undefined);

export const convetBase64FileToFile = (data, fileType, fileName) => {
  const {blob} =base64DocumentToBlob(data, fileType);
  let newFile = new File([blob], `Candidate Packet`, {type: fileType})
  return newFile;
}

