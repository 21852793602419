/* eslint-disable tss-unused-classes/unused-classes */
import React from 'react';
import styledComponent from 'styled-components';
import { colors } from 'styles/styleVariables';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import ForwardOutlinedIcon from '@mui/icons-material/ForwardOutlined';
import SubmitEnabledIcon from 'app/assets/images/IconImages/arrowCircleRightEnabled.svg';
import SubmitDisabledIcon from 'app/assets/images/IconImages/arrowCircleRightDisabled.svg';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { Avatar, Typography, ListItemIcon as AMNListItemIcon } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { WhatsNextIcon } from 'app/models/Placement/PlacementDetails';
import { theme } from 'styles/global-styles';

export const useListStyles = makeStyles<{ inlineDense?: boolean }>()((theme, { inlineDense }) => ({
  failedItem: {
    paddingLeft: `${inlineDense ? 8 : 30}px !important`,
  },
  listItem: {
    padding: '3px 0px 0px 0px',
    '&:not(:last-child)': {
      paddingTop: '3px !important',
      paddingBottom: '3px !important',
    },
    '&:first-child': {
      paddingTop: '0px !important',
      paddingBottom: '3px !important',
    },
    '&:only-child': {
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
    },
  },
  divider: {
    margin: '6px 0px',
  },
  tooltipPlacementBottom: {
    margin: 0,
    marginTop: 10,
    position: 'relative',
    left: '-1px',
  },
  toolTip: {
    whiteSpace: 'pre',
  },
}));

export const MoveNextIcon: React.FC<{ disabled: boolean; icon: WhatsNextIcon; [key: string]: any }> = ({
  disabled,
  icon,
  ...props
}) => {
  return (
    <div style={{ cursor: !disabled && !!props.onClick ? 'pointer' : 'auto' }} {...props}>
      {icon === WhatsNextIcon.GreenChecked ? (
        <CheckCircleOutlinedIcon
          sx={{ color: theme => (disabled ? 'rgba(0, 0, 0, 0.26)' : theme.palette.system.forestGreen) }}
        />
      ) : disabled ? (
        <img src={SubmitDisabledIcon} alt="Submit disabled" data-testid="disabled" />
      ) : (
        <img src={SubmitEnabledIcon} alt="Submit" data-testid="enabled" />
      )}
    </div>
  );
};

export const FailedCountBox = styledComponent(Avatar)`
  height: 25px;
  width: 25px;
  background-color: #e6e6e6;
  color: ${colors.text};
  font-size: 16px;
  font-weight: 600;
`;

export const StatusTitle = styledComponent(Typography)`
  font-size: 14px;
  font-weight: 500;
`;

export const FailedRequirementTitle = styledComponent(Typography)`
  font-size: 14px;
  font-weight: 400;
  margin-top: 0px;
`;

export const ListItemIcon = styledComponent(AMNListItemIcon)`
  min-width: unset;
  margin-right: 12px;
`;

export const FailIcon = styledComponent(PanToolOutlinedIcon)`
  color: #d53943;
  padding: 3px;
`;

export const PassIcon = styledComponent(ForwardOutlinedIcon)`
  color:${theme.palette.success.main}
`;

export const NeutralForwardIcon = styledComponent(ForwardOutlinedIcon)`
  color: #c3c8d1;
`;
