import i18next from 'i18next';
/**
 * Data is derived anonymously from a doc maintained at AMN
 * https://amnhealthcare.sharepoint.com/:x:/r/teams/AMNOne-tm/_layouts/15/Doc.aspx?action=edit&sourcedoc=%7Bf4625c08-74c6-45c1-a681-e5548a3d9a43%7D&wdOrigin=TEAMS-WEB.teamsSdk.openFilePreview&wdExp=TEAMS-CONTROL&web=1&ovuser=687f51c3-0c5d-4905-84f8-97c683a5b9d1%2CSevak.Singh%40neudesic.com&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yMzA5MjkxMjMxMCIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D
 */
export const deriveTokensObj = data => ({
  candidate: {
    label: i18next.t('templateEditor.sidebar.tokenLabel.candidate'),
    tokens: data?.candidate ?? [],
  },
  placements: {
    label: i18next.t('templateEditor.sidebar.tokenLabel.placements'),
    tokens: data?.placement ?? [],
  },
  facility: {
    label: i18next.t('templateEditor.sidebar.tokenLabel.facility'),
    tokens: data?.facility ?? [],
  },
  accountManager: {
    label: i18next.t('templateEditor.sidebar.tokenLabel.accountManager'),
    tokens: data?.['account Manager'] ?? [],
  },
  clientContacts: {
    label: i18next.t('templateEditor.sidebar.tokenLabel.clientContacts'),
    tokens: data?.['client Contacts'] ?? [],
  },
  order: {
    label: i18next.t('templateEditor.sidebar.tokenLabel.order'),
    tokens: data?.order ?? [],
  },
  unit: {
    label: i18next.t('templateEditor.sidebar.tokenLabel.unit'),
    tokens: data?.unit ?? [],
  },
  other: {
    label: i18next.t('templateEditor.sidebar.tokenLabel.other'),
    tokens: data?.other ?? [],
  },
  images: {
    label: i18next.t('templateEditor.sidebar.tokenLabel.images'),
    tokens: data?.images ?? [],
  },
});
