export interface IOption {
  id: number;
  name: string;
  value: number;
  title: string;
  attr?: string;
  hasRange?: boolean;
  shiftId?: number;
}

export const shiftOptions: IOption[] = [
  { id: 1, name: 'All Shifts', value: 0, title: 'All Shifts', shiftId: 0 },
  { id: 2, name: '4 Hour', value: 4, title: '4 Hour Shift', shiftId: 8 },
  { id: 3, name: '5 Hour', value: 5, title: '5 Hour Shift', shiftId: 9 },
  { id: 4, name: '6 Hour', value: 6, title: '6 Hour Shift', shiftId: 10 },
  { id: 5, name: '6.4 Hour', value: 6.4, title: '6.4 Hour Shift', shiftId: 16 },
  { id: 6, name: '6.5 Hour', value: 6.5, title: '6.5 Hour Shift', shiftId: 17 },
  { id: 7, name: '6.8 Hour', value: 6.8, title: '6.8 Hour Shift', shiftId: 18 },
  { id: 8, name: '7 Hour', value: 7, title: '7 Hour Shift', shiftId: 7 },
  { id: 9, name: '7.25 Hour', value: 7.25, title: '7.25 Hour Shift', shiftId: 19 },
  { id: 10, name: '7.4 Hour', value: 7.4, title: '7.4 Hour Shift', shiftId: 20 },
  { id: 11, name: '7.5 Hour', value: 7.5, title: '7.5 Hour Shift', shiftId: 4 },
  { id: 12, name: '8 Hour', value: 8, title: '8 Hour Shift', shiftId: 1 },
  { id: 13, name: '9 Hour', value: 9, title: '9 Hour Shift', shiftId: 11 },
  { id: 14, name: '9.5 Hour', value: 9.5, title: '9.5 Hour Shift', shiftId: 6 },
  { id: 15, name: '10 Hour', value: 10, title: '10 Hour Shift', shiftId: 2 },
  { id: 16, name: '11 Hour', value: 11, title: '11 Hour Shift', shiftId: 12 },
  { id: 17, name: '11.5 Hour', value: 11.5, title: '11.5 Hour Shift', shiftId: 5 },
  { id: 18, name: '12 Hour', value: 12, title: '12 Hour Shift', shiftId: 3 },
  { id: 19, name: '14 Hour', value: 14, title: '14 Hour Shift', shiftId: 13 },
  { id: 20, name: '16 Hour', value: 16, title: '16 Hour Shift', shiftId: 14 },
  { id: 21, name: '18 Hour', value: 18, title: '18 Hour Shift', shiftId: 15 },
];

export const rateElementsOptions: IOption[] = [
  { id:25, name: "Local Bill Rate", value: 1, title: "Local Bill Rate", attr:"Local Bill Rate", hasRange: true },
  { id:26, name: "Travel Bill Rate", value: 2, title: "Travel Bill Rate", attr: "Travel Bill Rate", hasRange: true },
  { id:27, name: "Mileage Reimbursement", value: 3, title: "Mileage Reimbursement", attr: "Mileage Reimbursement", hasRange: false },
];

export const sortOptions = options => {
  const sortedOptions = options?.sort((item1, item2) => (item1.value < item2.value ? -1 : 1));
  return sortedOptions;
};

export const formatGwwData = data => {
  const GwwArray: any[] = [];
  data &&
    data.map(item => {
      const GwwData = {
        GwwHoursArray: [{ value: '36', index: 0 }],
        shiftName: item.value,
        shiftTitle: item.title,
      };
      GwwArray.push(GwwData);
    });
  return GwwArray;
};
