import React, { useEffect } from 'react';
import { Chip, Grid, ThemeProvider, StyledEngineProvider,useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { OrderPriorities } from 'app/components/Order/OrderCreationForm/OrderDetails/OrderPriorities';
import { NewUnit } from 'app/models/Unit/CreateUnit';
import { FormProvider, useForm } from 'react-hook-form';
import { UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { useTranslation } from 'react-i18next';
import { getOrderPriorityValues, getOrderPriorityObject, commonVIPriority, formatChipLabel } from '../helper';
import { orderCreationActions } from '@AMIEWEB/Order/Store/OrderCreation.actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrderPriorities } from '@AMIEWEB/Order/Store/OrderCreation.selectors';

interface Props {
  unit: NewUnit;
  onUnitPropChange: (property: string, value: any) => void;
}
const useStyles = makeStyles()(() => ({
  chip: {
    maxWidth: '50ch',
    margin: '0 5px 10px 0',
  },
}));

export const UnitOrderPriorities = (props: Props) => {
  const { unit, onUnitPropChange } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const orderPriorities = useSelector(selectOrderPriorities);
  const [orderPriorityValue, setOrderPriorityValue] = React.useState<string[]>(
    getOrderPriorityValues(unit?.orderPriorities),
  );
  const [orderDisabledPriorityValue, setOrderDisabledPriorityValue] = React.useState<string[]>();

  const handleOrderPriorityValue = selectedValue => {
    onUnitPropChange('orderPriorities', getEditableOrderPriorities(selectedValue));
  };

  const getSelectedValue = () => {
    return orderPriorities.filter(p => orderPriorityValue.includes(p.label));
  };

  const getDisabledPriorities = () => {
    return unit?.virtualRating && unit?.virtualRating.length > 0 ? [...unit?.virtualRating, commonVIPriority] : [];
  };

  useEffect(() => {
    dispatch(orderCreationActions.getOrderPriorityTypes());
  }, []);

  useEffect(() => {
    setOrderDisabledPriorityValue(getDisabledPriorities());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit?.orderPriorities]);

  const getEditableOrderPriorities = values => {
    const viPriorities = getDisabledPriorities();
    let selectedValues = values.map(value => value.label);
    viPriorities?.forEach(viPriority => {
      if (selectedValues.includes(viPriority) && !orderPriorityValue.includes(viPriority))
        selectedValues = selectedValues.filter(x => x !== viPriority);
      if (!selectedValues.includes(viPriority) && orderPriorityValue.includes(viPriority))
        selectedValues.push(viPriority);
    });

    return getOrderPriorityObject(selectedValues);
  };
  const formMethods = useForm();

  const handleDelete = vr => {
    const updatedValue = unit?.orderPriorities.filter(t => t.description != vr);
    // setOrderPriorityValue(updatedValue);
    onUnitPropChange('orderPriorities', updatedValue);
  };

  useEffect(() => {
    setOrderPriorityValue(getOrderPriorityValues(unit?.orderPriorities));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit?.orderPriorities]);

  return (
    <div>
      {/* TODO: Remove this theme wrapper since it uses the global theme */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <FormProvider {...formMethods}>
            <UnitDetailsBody variant="outlined">
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <UnitCardsTitle>{t('order.createUnit.orderPriorities')}</UnitCardsTitle>
                </Grid>
                <Grid item xs={12}>
                  <OrderPriorities
                    defaultValue={orderPriorityValue}
                    handleOrderPriorities={handleOrderPriorityValue}
                    orderPriorities={orderPriorities}
                    selectedValue={getSelectedValue()}
                    disabledValue={orderDisabledPriorityValue}
                  />
                </Grid>
                <Grid item>
                  <div id={'createUnitOrderPrioritiesSelectedItemContainer'}>
                    {orderPriorityValue &&
                      orderPriorityValue.map(opChip => {
                        return (
                          <>
                            {orderDisabledPriorityValue &&
                            orderDisabledPriorityValue?.filter(t => t == opChip).length <= 0 ? (
                              <Chip
                                variant="outlined"
                                label={
                                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                      {formatChipLabel(opChip)}
                                    </div>
                                  </div>
                                }
                                className={classes.chip}
                                onDelete={() => handleDelete(opChip)}
                              />
                            ) : (
                              <Chip
                                variant="outlined"
                                label={
                                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                      {formatChipLabel(opChip)}
                                    </div>
                                  </div>
                                }
                                className={classes.chip}
                              />
                            )}
                          </>
                        );
                      })}
                  </div>
                </Grid>
              </Grid>
            </UnitDetailsBody>
          </FormProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};
