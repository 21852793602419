import React from 'react';
import { Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { getAvailableToStartDateRange, getCallBackDateRange, getLastApplicationDateRange } from './filterHelper';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectTileCounts } from '../../MyPlacementsDesk/RecruiterPlacements.selector';
import { getDateValue } from 'utils/dates/dateExtraction';
import { recPlacementsFilterName } from './filterUtils';

const useStyles = makeStyles()({
  root: {
    maxWidth: 230,
    paddingTop: 10,
  },
  tableCell: {
    border: 'none',
  },
  btnCell: {
    padding: '12px 0px',
  },
  rowTag: {
    fontSize: 11,
    whiteSpace: 'pre',
    paddingRight: 6,
  },
  tableCellHead: {
    lineHeight: '1rem',
  },
  dynamicBtn: {
    backgroundColor: '#006FB9',
    color: '#fff',
    fontSize: 13,
    '&:hover': {
      backgroundColor: '#006FCF',
    },
  },
  staticBtn: {
    backgroundColor: '#F28D25',
    color: '#fff',
    fontSize: 13,
    '&:hover': {
      backgroundColor: '#F58445',
    },
  },
});

export const PlacementTiles = props => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { getValues, setValue } = useFormContext();

  const tileCounts = useSelector(selectTileCounts);

  const applyTile = (fieldName, value) => {
    setValue(fieldName, value);
    props.onApplyFilter({
      ...getValues(),
      [fieldName]: value,
    });
  };

  const rows = [
    {
      tag: t('home.recruiterWorkDesk.placementTiles.nonContacted30Tag'),
      onClick: () =>
        applyTile(recPlacementsFilterName.nonContactedDate, { date: getDateValue(-30), selectChoice: '30 Days' }),
      staticCount: tileCounts.static.nonContactedDate30,
      dynamicCount: tileCounts.dynamic.nonContactedDate30,
    },
    {
      tag: t('home.recruiterWorkDesk.placementTiles.nonContacted20Tag'),
      onClick: () =>
        applyTile(recPlacementsFilterName.nonContactedDate, { date: getDateValue(-20), selectChoice: '20 Days' }),
      staticCount: tileCounts.static.nonContactedDate20,
      dynamicCount: tileCounts.dynamic.nonContactedDate20,
    },
    {
      tag: t('home.recruiterWorkDesk.placementTiles.availableToStartThisWeekTag'),
      onClick: () =>
        applyTile(recPlacementsFilterName.availableToStartDateRange, {
          ...getAvailableToStartDateRange(0),
          selectChoice: 'This Week',
        }),
      staticCount: tileCounts.static.availableToStartThisWeek,
      dynamicCount: tileCounts.dynamic.availableToStartThisWeek,
    },
    {
      tag: t('home.recruiterWorkDesk.placementTiles.availableToStartNextMonthTag'),
      onClick: () =>
        applyTile(recPlacementsFilterName.availableToStartDateRange, {
          ...getAvailableToStartDateRange(2),
          selectChoice: 'Next Month',
        }),
      staticCount: tileCounts.static.availableToStartNextMonth,
      dynamicCount: tileCounts.dynamic.availableToStartNextMonth,
    },
    {
      tag: t('home.recruiterWorkDesk.placementTiles.lastApplicationTodayTag'),
      onClick: () =>
        applyTile(recPlacementsFilterName.lastApplicationDateRange, {
          ...getLastApplicationDateRange(0),
          selectChoice: 'Today',
        }),
      staticCount: tileCounts.static.lastApplicationToday,
      dynamicCount: tileCounts.dynamic.lastApplicationToday,
    },
    {
      tag: t('home.recruiterWorkDesk.placementTiles.lastApplicationThisWeekTag'),
      onClick: () =>
        applyTile(recPlacementsFilterName.lastApplicationDateRange, {
          ...getLastApplicationDateRange(1),
          selectChoice: 'This Week',
        }),
      staticCount: tileCounts.static.lastApplicationThisWeek,
      dynamicCount: tileCounts.dynamic.lastApplicationThisWeek,
    },
    {
      tag: t('home.recruiterWorkDesk.placementTiles.callBackTodayTag'),
      onClick: () =>
        applyTile(recPlacementsFilterName.callBackDateRange, { ...getCallBackDateRange(0), selectChoice: 'Today' }),
      staticCount: tileCounts.static.callBackToday,
      dynamicCount: tileCounts.dynamic.callBackToday,
    },
    {
      tag: t('home.recruiterWorkDesk.placementTiles.callBackThisWeekTag'),
      onClick: () =>
        applyTile(recPlacementsFilterName.callBackDateRange, { ...getCallBackDateRange(1), selectChoice: 'This Week' }),
      staticCount: tileCounts.static.callBackThisWeek,
      dynamicCount: tileCounts.dynamic.callBackThisWeek,
    },
  ];

  return (
    <TableContainer classes={{ root: classes.root }}>
      <Table aria-label="placement-tiles" padding="none">
        <TableHead>
          <TableRow>
            <TableCell classes={{ root: classes.tableCell, head: classes.tableCellHead }} />
            <TableCell classes={{ root: classes.tableCell, head: classes.tableCellHead }}>
              {t('home.recruiterWorkDesk.placementTiles.dynamicCount')}
            </TableCell>
            <TableCell classes={{ root: classes.tableCell, head: classes.tableCellHead }}>
              {t('home.recruiterWorkDesk.placementTiles.staticCount')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index.toString()} aria-label="rec-tile-section">
              <TableCell classes={{ root: `${classes.tableCell} ${classes.rowTag}` }}>
                <div>{row.tag}</div>
              </TableCell>
              <TableCell classes={{ root: `${classes.tableCell} ${classes.btnCell}` }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  classes={{ contained: classes.dynamicBtn }}
                  onClick={row.onClick}
                  type="button"
                  aria-label="rec-tile-dynamic-btn"
                >
                  {row.dynamicCount}
                </Button>
              </TableCell>
              <TableCell classes={{ root: `${classes.tableCell} ${classes.btnCell}` }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  classes={{ contained: classes.staticBtn }}
                  type="button"
                  aria-label="rec-tile-static-btn"
                >
                  {row.staticCount}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
