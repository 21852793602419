import React, { useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Grid,
  Box,
  TextField,
  ClickAwayListener,
  Tooltip,
  DatePicker,
  LocalizationProvider,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { AccordionMenu } from '../DropDown/AccordionMenu';
import { ICustomHeader, gridFilterOptionType } from 'app/models/Grid';
import { DateRangePicker } from 'app/ComponentLibrary/DateRange/components/DateRangePicker';
import { DateRangePicker as DateRangeNew } from 'app/ComponentLibrary/DateRangeNew';
import moment from 'moment';
import { addDays } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const useRowStyles = makeStyles()(theme => ({
  gridContainer: {
    width: '100%',
  },
  filterTitle: {
    textAlign: 'left',
    marginBottom: '10px',
    marginTop: '15px',
  },
  button: {
    marginTop: '20px',
  },
  dateRange: {
    padding: '12px 10px',
    fontSize: '12px',
  },

  textFieldNumber: {
    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
      display: 'none',
    },
  },
  textFieldInput: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  filterItemTitle: {
    fontWeight: 400,
    marginBottom: '5px',
    minWidth: 'max-content',
  },
  filterGridItem: {
    alignSelf: 'flex-end',
  },
  tooltip: {
    maxWidth: 'fit-content',
    padding: '0px 0px',
    backgroundColor: 'white',
    borderRadius: '5%',
  },
}));

export const BetterFilter = ({
  rows,
  columns,
  onChange,
  onReset,
  className,
  displayLabels = true,
  maxWidth,
  hasTitle = true,
  leadingChildren,
  initialFilter,
}: {
  rows: any;
  columns: ICustomHeader[];
  onChange: (rows) => void;
  onReset?: () => void;
  className?: string;
  displayLabels?: boolean;
  maxWidth?: number;
  hasTitle?: boolean;
  leadingChildren?: JSX.Element;
  initialFilter?: { [key: string]: any };
}) => {
  const { t } = useTranslation();
  const { classes } = useRowStyles();
  const [columnData, setColumnData] = useState(columns);
  const [filters, setFilters] = useState({});
  const [filterData, setFilterData] = useState({});
  const [dateRangeOpen, setDateRangeOpen] = useState<{ [x: string]: boolean }>({});
  const [clearRangeData, setClearRangeData] = useState(false);
  const date = new Date();

  const onFilterOptionSelected = event => {
    const data = { ...filterData };
    data[event.target.name] = event.target.value;

    setFilterData(data);
  };

  const filterRows = () => {
    let finalData = [...rows];
    for (const filterKey in filterData) {
      if (`${filterKey}` === '-1') continue;
      const column = columnData.find(item => item.title === filterKey);
      finalData = finalData.filter(data => data[filterKey] !== null);
      if (column?.filterOptions?.customize?.filter !== undefined) {
        finalData = column.filterOptions.customize.filter(finalData, filterData[filterKey]);
      } else {
        finalData = finalData.filter(data => {
          if (filterData[filterKey] === 'All') return true;
          else {
            switch (column?.filterOptions?.type) {
              case gridFilterOptionType.dropDown:
                if (filterData[filterKey].length > 1) {
                  return filterData[filterKey].map(item => `${item}`).includes(`${data[filterKey]}`);
                }
                return true;
              case gridFilterOptionType.dateRange:
                const startDate = filterData[filterKey].startDate;
                const endDate = filterData[filterKey].endDate;
                return (
                  moment(data[filterKey]).isSameOrAfter(moment(startDate)) &&
                  moment(data[filterKey]).isSameOrBefore(moment(endDate))
                );
              case gridFilterOptionType.dateRangeV2:
                const startDateV2 = filterData[filterKey].startDate;
                const endDateV2 = filterData[filterKey].endDate;
                return (
                  moment(data[filterKey]).isSameOrAfter(moment(startDateV2)) &&
                  moment(data[filterKey]).isSameOrBefore(moment(endDateV2))
                );
              case gridFilterOptionType.textfieldNumber:
                return data[filterKey] <= filterData[filterKey];

              default:
                return data[filterKey]?.includes(filterData[filterKey]);
            }
          }
        });
      }
    }
    return finalData;
  };

  const handleFilterData = () => {
    onChange(filterRows());
  };

  const handleClearFilter = () => {
    setFilterData({});
    setClearRangeData(true);

    document.querySelectorAll('.filteredSelect div').forEach(ctrl => (ctrl.textContent = 'All'));
    onReset?.();
  };
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const filterCreation = cols => {
    var options: string[] = [];
    const checkFilters = {};
    const addNewDataToCollection = (collection: string[], item: string) => {
      if (!collection.includes(item) && `${item}`?.trim() !== '' && item !== 'N/A') {
        collection.push(item);
      }
    };
    cols.forEach(column => {
      const columnName = column.title;
      if (column.filterOptions?.customize?.filterData) {
        column.filterOptions.customize.filterData.forEach(item => {
          addNewDataToCollection(options, item);
        });
        checkFilters[columnName] = options;
      } else {
        if (column.filterable || column.filterOptions) {
          rows?.forEach(row => {
            if (row[columnName] != null && row[columnName] !== '' && row[columnName] !== 'N/A') {
              if (column.multiLine) {
                row[columnName].map(val => {
                  addNewDataToCollection(options, val);
                  return null;
                });
              } else {
                addNewDataToCollection(options, row[columnName]);
              }
            }
          });
        }
        checkFilters[columnName] = options.sort((a, b) => (a > b ? 1 : -1));
        if (!column.filterOptions?.multiselect) options.splice(0, 0, 'All');
      }
      options = [];
    });
    setFilters(checkFilters);
  };

  useEffect(() => {
    // setFilterData({});
    if (initialFilter) setFilterData(initialFilter);
    setColumnData(columns);
    filterCreation(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, columns]);

  const getPlaceholder = header => {
    return { value: header.filterOptions?.multiselect ? [-1] : -1, description: header.value };
  };

  return (
    <Box className={className}>
      {hasTitle && (
        <Typography variant={'subtitle1'} className={classes.filterTitle}>
          {t('Filters')}
        </Typography>
      )}
      <Grid
        container
        spacing={2}
        justifyContent={'flex-start'}
        alignContent={'center'}
        alignItems={'center'}
        className={classes.gridContainer}
      >
        {leadingChildren && (
          <Grid item className={classes.filterGridItem}>
            {leadingChildren}
          </Grid>
        )}
        {columnData.map(
          (header, index) =>
            (header.filterable || header.filterOptions) && (
              <Grid item className={classes.filterGridItem} style={maxWidth ? { maxWidth: `${maxWidth}px` } : {}}>
                {displayLabels && (
                  <Typography className={classes.filterItemTitle} variant="subtitle2">
                    {header.value}
                  </Typography>
                )}
                {header.filterOptions?.type === gridFilterOptionType.dateRange ? (
                  <ClickAwayListener onClickAway={() => setDateRangeOpen({ ...dateRangeOpen, [header.title]: false })}>
                    <Box>
                      <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        placement={'bottom'}
                        open={dateRangeOpen[header.title] || false}
                        title={
                          <DateRangePicker
                            onChange={event => {
                              onFilterOptionSelected({
                                target: {
                                  value: event,
                                  name: header.title,
                                },
                              });
                              setDateRangeOpen({ ...dateRangeOpen, [header.title]: true });
                            }}
                            open={true}
                          />
                        }
                      >
                        <TextField
                          disabled={!rows || rows.length === 0}
                          placeholder={header.value}
                          variant="outlined"
                          size="small"
                          inputProps={{ className: classes.dateRange, readOnly: true }}
                          onFocus={() => {
                            setDateRangeOpen({ ...dateRangeOpen, [header.title]: true });
                            setClearRangeData(false);
                          }}
                          value={
                            filterData[header.title] !== undefined
                              ? `${moment(filterData[header.title].startDate).format('MM/DD/YY')} - ${moment(
                                  filterData[header.title].endDate,
                                ).format('MM/DD/YY')}`
                              : ''
                          }
                        />
                      </Tooltip>
                    </Box>
                  </ClickAwayListener>
                ) : header.filterOptions?.type === gridFilterOptionType.dateRangeV2 ? (
                  <ClickAwayListener onClickAway={() => setDateRangeOpen({ ...dateRangeOpen, [header.title]: false })}>
                    <Box>
                      <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        placement={'bottom'}
                        open={dateRangeOpen[header.title] || false}
                        title={false}
                      >
                        <DateRangeNew
                          onApply={event => {
                            onFilterOptionSelected({
                              target: {
                                value: event,
                                name: header.title,
                              },
                            });
                            setDateRangeOpen({ ...dateRangeOpen, [header.title]: true });
                          }}
                          single={!header.filterOptions?.multiselect}
                          variant="outlined"
                          clickAway
                          maxWidth={124}
                          showIcon={false}
                          isDateIconReq={true}
                          value={filterData[header.title]}
                          definedRanges={
                            !header.filterOptions?.multiselect
                              ? [
                                  {
                                    label: '1 Day',
                                    startDate: addDays(date, -1),
                                    endDate: addDays(date, -1),
                                    useLabelAsValue: false,
                                  },
                                  {
                                    label: '2 Days',
                                    startDate: addDays(date, -2),
                                    endDate: addDays(date, -2),
                                    useLabelAsValue: false,
                                  },
                                  {
                                    label: '7 Days',
                                    startDate: addDays(date, -7),
                                    endDate: addDays(date, -7),
                                    useLabelAsValue: false,
                                  },
                                  {
                                    label: '20 Days',
                                    startDate: addDays(date, -20),
                                    endDate: addDays(date, -20),
                                    useLabelAsValue: false,
                                  },
                                  {
                                    label: '30 Days',
                                    startDate: addDays(date, -30),
                                    endDate: addDays(date, -30),
                                    useLabelAsValue: false,
                                  },
                                  {
                                    label: '60 Days',
                                    startDate: addDays(date, -60),
                                    endDate: addDays(date, -60),
                                    useLabelAsValue: false,
                                  },
                                ]
                              : undefined
                          }
                        />
                      </Tooltip>
                    </Box>
                  </ClickAwayListener>
                ) : header.filterOptions?.type === gridFilterOptionType.datePicker ? (
                  <Box style={{ position: 'relative' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disabled={!rows || rows.length === 0}
                        inputFormat="MM/dd/yyyy"
                        value={filterData[header.title] ? filterData[header.title] : null}
                        onChange={event => {
                          onFilterOptionSelected({
                            target: {
                              value: event,
                              name: header.title,
                            },
                          });
                        }}
                        renderInput={p => (
                          <TextField
                            variant="outlined"
                            aria-label="change date"
                            {...p}
                            InputLabelProps={{
                              ...p.InputLabelProps,
                              shrink: true,
                              color: 'primary',
                            }}
                            className={classes.textFieldInput}
                            inputProps={{ readOnly: true, 'aria-autocomplete': 'none' }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                ) : header.filterOptions?.type === gridFilterOptionType.textfieldNumber ? (
                  <Box style={{ position: 'relative' }}>
                    <TextField
                      disabled={!rows || rows.length === 0}
                      placeholder={header.value}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{ className: classes.dateRange }}
                      className={classes.textFieldNumber}
                      value={filterData[header.title] !== undefined ? filterData[header.title] : ''}
                      onChange={event => {
                        onFilterOptionSelected({
                          target: {
                            value: parseInt(event.target.value),
                            name: header.title,
                          },
                        });
                      }}
                    />
                  </Box>
                ) : (
                  <Box style={{ position: 'relative' }}>
                    <AccordionMenu
                      disabled={!rows || rows.length === 0}
                      maxWidth={maxWidth}
                      name={header.title}
                      large={false}
                      placeholder={getPlaceholder(header)}
                      value={filterData[header.title] || (header.filterOptions?.multiselect ? [-1] : -1)}
                      filterOptions={
                        filters[header.title]?.map((data, key) => ({
                          value: `${data}`,
                          description: `${data}`,
                        })) || []
                      }
                      handleChange={onFilterOptionSelected}
                      filter
                      multiselect={header.filterOptions?.multiselect}
                    />
                  </Box>
                )}
              </Grid>
            ),
        )}
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <Button variant="contained" className={`action-button ${classes.button}`} onClick={handleFilterData}>
                {t('Apply')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" className={classes.button} onClick={handleClearFilter}>
                {t('Clear')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
