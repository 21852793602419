import { Box } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { FormInputText } from './Controls/FormInputText';
import { selectSellingNotesItems } from '../../../../Profile/CandidateDetails.selector';
import { ControlType, SellingNoteFields } from './helpers/SellingNotesEnum';
import { ISellingNoteElements } from 'app/models/Candidate/SellingNotes';
import { Row } from '../styles';
import { RowContainer } from '@AMIEWEB/Candidate/Common/RowContainer';

const useStyles = makeStyles()(theme => ({
  title: {
    fontWeight: 500,
    fontSize: 16,
    paddingLeft: 10,
    color: '#555555',
  },
  wrapper: {
    padding: '20px 0 0 10px',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.framework.system.whisper,
  },
  helperText: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  disabled: {
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: theme.palette.framework.system.whisper,
      color: '#555555',
    },
    '& .MuiFilledInput-multiline': {
      padding: '17px 12px 10px',
    },
  },
  errorText: {
    fontSize: '12px',
    justifyContent: 'center',
    color: '#ff0000',
  },
  characterCount: {
    fontSize: '12px',
    justifyContent: 'center',
    color: '#888888',
  },
  rowContainer: {
    height: '450px',
    overflowY: 'auto',
  },
}));

export const SellingNotesPreview = props => {
  const { classes } = useStyles();
  const { getValues, setValue, setError, clearErrors, errors } = useFormContext();
  const [charCount, setCharCount] = useState<number>(0);
  const selectItemsData = useSelector(selectSellingNotesItems);

  useEffect(() => {
    if (charCount > 1000) {
      setError('preview', {
        type: 'maxLength',
      });
    } else {
      clearErrors('preview');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charCount]);

  /**
   *  Method to patch dropdown data
   * @param data - dropdown list
   * @param item
   */
  const patchDropdownData = (data: string[], item: ISellingNoteElements) => {
    const fieldValue = getValues(item.fieldName);
    let selectedValue;
    if (item.type === ControlType.Dropdown) {
      selectedValue = fieldValue.map(currentItem => currentItem.name).join('\n');
    } else if (item.type === ControlType.SingleSelectDropdown) {
      if (item.label === SellingNoteFields.BEST_CONTACT_NUMBER) {
        selectedValue = `${fieldValue}, ${getValues('preferedTimeToReach')}`;
      } else if (item.label !== SellingNoteFields.PREFERRED_TIME_TO_REACH) {
        selectedValue = fieldValue;
      }
    }
    if (selectedValue) {
      data.push(selectedValue);
    }
  };

  /**
   * Method to set values to preview field from selling notes control
   */
  const initiatePreviewFieldMapping = () => {
    const data: any = [];
    selectItemsData?.map(item => {
      if (getValues(item.fieldName)?.length > 0 && getValues(`${item.fieldName}Checkbox`)) {
        if (item.type === ControlType.Dropdown || item.type === ControlType.SingleSelectDropdown) {
          patchDropdownData(data, item);
        } else if (item.type === ControlType.Text) {
          data.push(getValues(item.fieldName));
        }
      }
    });
    if (data.length > 0) {
      const result = `Candidate Highlights:\n${data.join(`\n`)}`;
      setValue('preview', result);
      setCharCount(result?.length);
    } else {
      setValue('preview', '');
      setCharCount(0);
    }
    props.setGenerateButtonState(false);
  };

  /**
   * useEffect to handle generate preview for selling notes
   */
  useEffect(() => {
    if (props.isGenerateBtnClicked) {
      initiatePreviewFieldMapping();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isGenerateBtnClicked]);

  return (
    <>
      <LayoutGrid container direction="column" spacing={2} className={classes.wrapper}>
        <LayoutGridItem item style={{ width: '100%', paddingRight: '27px' }}>
          <Box className={classes.title}>{`Preview`}</Box>
          <div className={classes.rowContainer}>
            <RowContainer>
              <Row>
                <FormInputText
                  multiline
                  name={'preview'}
                  label={''}
                  require={false}
                  inputValue={getValues('preview')}
                  message={errors['preview']?.message}
                  readOnly={true}
                  formHelperTextProps={classes.helperText}
                  customClass={classes.disabled}
                />
              </Row>
              <Row>
                <LayoutGrid container justifyContent="space-between" style={{ width: '100%' }} xs={12}>
                  <LayoutGridItem>
                    <label className={classes.errorText}>{`${
                      charCount > 1000 ? 'Character Limit Reached' : ' '
                    }`}</label>
                  </LayoutGridItem>
                  <LayoutGridItem>
                    <label
                      className={charCount > 1000 ? classes.errorText : classes.characterCount}
                    >{`${charCount}/1000`}</label>
                  </LayoutGridItem>
                </LayoutGrid>
              </Row>
            </RowContainer>
          </div>
        </LayoutGridItem>
      </LayoutGrid>
    </>
  );
};
