import { GridCellParams } from '@mui/x-data-grid-pro';
import { IconButton } from 'amn-ui-core';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface XGridOptionsCellProps {
  icon: string | JSX.Element;
  alt?: string;
  align?: string;
  onClick?: (params: GridCellParams, event) => void;
  hoverTemplate?: (params: GridCellParams | undefined) => string | JSX.Element | null;
  params?: GridCellParams;
  [key: string]: any;
}

const GridCellExpand = React.memo(function GridCellExpand(props: XGridOptionsCellProps) {
  const { icon, alt, align, onClick, params, hoverTemplate } = props;

  const handleClick = e => {
    if (params) {
      onClick?.(params, e);
    }
  };

  return (
    <React.Fragment>
      {hoverTemplate?.(params) ? (
        <IconButton color="primary" size="small">
          <CustomTooltip
            style={{ color: 'yellow' }}
            tooltipContent={hoverTemplate?.(params) || null}
            interactive
            placement="bottom"
            onOpen={handleClick}
            arrow
          >
            <div style={{ cursor: 'pointer' }}>
              {typeof icon === 'string' ? <img style={{ justifySelf: align }} src={icon} alt={alt} /> : icon}
            </div>
          </CustomTooltip>
        </IconButton>
      ) : (
        <div style={{ cursor: 'not-allowed', padding: '12px' }}>
          {typeof icon === 'string' ? <img style={{ justifySelf: align }} src={icon} alt={alt} /> : icon}
        </div>
      )}
    </React.Fragment>
  );
});

/**
 * Cell renderer to display an popper content on click of menu.
 * @param params X-Grid Params: `GridCellParams`
 * @returns JSXElement
 */
export const PassportXGridOptionsCell = ({
  icon,
  alt = '',
  align = 'center',
  onClick,
  hoverTemplate,
}: XGridOptionsCellProps) => (params: GridCellParams) => {
  return (
    <GridCellExpand
      icon={params?.row?.requirementTypeId ? icon : <MoreVertIcon style={{ color: '#c5c0c0' }} />}
      alt={alt}
      align={align}
      onClick={onClick}
      hoverTemplate={hoverTemplate}
      params={params}
    />
  );
};
