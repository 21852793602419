import { GridColumns as XGridColumns, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { trackException } from 'app-insights/appInsightsTracking';
import _ from 'lodash';

export const defaultFilterName = 'my default';

export enum SearchType {
  candidate = 'candidate',
  placement = 'placement',
  order = 'order',
  facility = 'facility',
  tasks = 'tasks',
  coverage = 'coverage',
  amWorkdesk = 'amWorkdesk',
  orderPlacement = 'orderPlacement',
  unit = 'unit',
  autoTasks='autoTasks'
}

export interface ISearchResults {
  count: number;
  items: any[];
  drawerData?: {
    open: boolean;
    data: any;
  };
  miscellaneous: IMiscellanoues;
}

export interface IMiscellanoues {
  positionCount?: number;
  jobMatchHealth?: boolean;
  jobInterestHealth?: boolean;
}

export enum GlobalSearchGridType {
  standard,
  xGrid,
}

export class GridColumns {
  columns: XGridColumns = [];
  visibilityModel?: GridColumnVisibilityModel;

  constructor(columns: XGridColumns = [], visibilityModel?: GridColumnVisibilityModel) {
    this.columns = columns?.map(item => {
      return {
        field: item.field,
        width: item.width,
        flex: item.flex,
        // filterable: item.filterable,
        // sortable: item.sortable,
        // pinnable: item.pinnable,
        // resizable: item.resizable,
        // editable: item.editable,
      };
    });
    this.visibilityModel = visibilityModel;
  }

  isEqual(gridStateColumns: XGridColumns) {
    const gridStateColumnsClone = _.clone(gridStateColumns);
    if (gridStateColumnsClone?.length > 0 && gridStateColumnsClone[0].field === '__check__') {
      gridStateColumnsClone.shift();
    }
    let equal: boolean = true;
    if (
      gridStateColumnsClone?.length > 0 &&
      this.columns?.length > 0 &&
      gridStateColumnsClone?.length >= this.columns?.length
    ) {
      this.columns?.forEach((item, i) => {
        if (
          item.field !== '__check__' &&
          (item.field !== gridStateColumnsClone[i].field || item.width !== gridStateColumnsClone[i].width)
        ) {
          equal = false;
        }
      });
    } else equal = false;
    return equal;
  }

  isVisibilityModelEqual(gridVisibilityModel: GridColumnVisibilityModel): boolean {
    try {
      const model = _.cloneDeep(gridVisibilityModel);
      return _.isEqual(this.visibilityModel, model);
    } catch (err) {
      trackException({
        exception: err,
        properties: {
          name: 'isVisibilityModelEqual',
          columnsThis: JSON.stringify(this.columns ?? {}),
          visibilityModelThis: JSON.stringify(this.visibilityModel ?? {}),
          incomingVisibilityModel: JSON.stringify(gridVisibilityModel ?? {}),
        },
      });
      return false;
    }
  }
}
