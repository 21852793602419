import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useState } from 'react';
import { Row, RowContainer, RowContent, RowHead } from '../../styles';
import { makeStyles } from 'tss-react/mui';
import { Button, Divider, Grid, Typography } from 'amn-ui-core';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { useHistory } from 'react-router-dom';
import AddPlacementIdModal from './CandidatePlacementSupervisionLinkPID/AddPlacementIdModal';
import { useDispatch, useSelector } from 'react-redux';
import { placementDetailsAction } from 'store/redux-store/placement-details/slice';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { selectUser } from 'oidc/user.selectors';
import { useTranslation } from 'react-i18next';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { missingField } from 'app/constants';

const useStyles = makeStyles()({
  clickable: {
    cursor: 'pointer !important',
    color: '#006FB9 !important',
    fontWeight: 450,
  },
  buttonStyle: {
    width: '30px',
    height: '30px',
    borderRadius: '4px',
    backgroundColor: '#006FB9',
    minWidth: '30px',
  },
  iconStyle: {
    fontSize: '25px',
    color: '#fff',
  },
  row: {
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  divider: {
    padding: '0px 0px 0px 0px',
  },
  grid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  groupedRow: {
    padding: '10px 0px 10px 0px',
  },
  clricon: {
    color: '#8F9296',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '8px',
    cursor: 'pointer'
  },
  dialogueContent: {
    padding: 0,
    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .dialogContentRoot': {
      padding: 0,
    },
  },
  wrapper: {
    position: 'absolute',
    top: 0,
    left: '10vw',
  },
  text: {
    fontSize: '14px',
    color: '#333333',
    padding: '0px 30px',
  },
  underlineText: {
    textDecoration: 'underline'
  }
});

export const CandidatePlacementSupervision = props => {
  const { title = 'Supervisor', containerVals = [] } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userInfo } = useSelector(selectUser);
  const history = useHistory();
  const [deleteSupervison, setDeleteSupervison] = useState(false);
  const [selectedPlacementSupervisingId, setSelectedPlacementSupervisingId] = useState();

  const authorized = Authorized(
    [
      userRoles.accountManagement,
      userRoles.accountManagement_Leadership,
      userRoles.accountManagement_TeamMember,
      userRoles.recruitment_TeamMember,
      userRoles.recruitment_Leadership,
      userRoles.recruitment,
    ],
    userInfo,
  );

  const handleRemoveSupervisor = () => {
    dispatch(placementDetailsAction.deletePlacementSupervision({ placementId: selectedPlacementSupervisingId }));
    setDeleteSupervison(false);
  };

  const SquareButton = ({ onClick }) => {
    return (
      <Button variant="contained" className={classes.buttonStyle} onClick={onClick}>
        <AddIcon className={classes.iconStyle} />
      </Button>
    );
  };

  const placementIdClickAction = (placementId: number) => () => {
    if (isNaN(placementId)) return;
    history.push({
      pathname: `/placement/${placementId}`,
    });
  };

  const RowSection = props => {
    return (
      <LayoutGridItem item id={props.id}>
        <RowContainer>
          <Row>
            <RowHead style={{ wordBreak: 'keep-all' }}>{props.header}</RowHead>
            <RowContent
              onClick={placementIdClickAction(props.data)}
              className={cx({
                [classes.clickable]: typeof props.data === 'number' && props.data,
                [classes.underlineText]: typeof props.data === 'number' && props.data,
              })}
            >
              {props.data}
            </RowContent>
          </Row>
        </RowContainer>
      </LayoutGridItem>
    );
  };

  const GroupedRowSection = props => {
    const { pid, supervision, startDate, endDate } = props;

    return (
      <React.Fragment>
        <LayoutGrid direction="column">
          <RowSection
            header={'PID'}
            data={pid}
            className={cx({
              [classes.clickable]: true,
            })}
            id={'supervision-pid'}
          />
          <RowSection
            header={title ?? ''}
            data={supervision}
            className={cx({
              [classes.clickable]: false,
            })}
            id={'supervision'}
          />
          <RowSection
            header={'Start Date'}
            data={`${startDate}` || missingField}
            className={cx({
              [classes.clickable]: false,
            })}
            id={'supervision-start-date'}
          />
          <RowSection
            header={'End Date'}
            data={`${endDate}` || missingField}
            className={cx({
              [classes.clickable]: false,
            })}
            id={'supervision-end-date'}
          />
        </LayoutGrid>
      </React.Fragment>
    );
  };

  const RemoveSupervisor = props => {
    const handleRemove = () => {
      props.onRemove();
      setDeleteSupervison(false);
    };
    const cancelRemove = () => {
      setDeleteSupervison(false);
    };

    return (
      <div className={classes.wrapper} data-testid="delete-supervisor">
        <GenericDialog
          open={deleteSupervison}
          maxWidth='sm'
          dialogTitleProps={{
            text: 'Remove Linked Placement',
          }}
          dialogContentProps={{
            classes: {
              root: classes.dialogueContent,
            },
          }}
          dialogActions={[
            {
              text: 'Cancel',
              onClick: cancelRemove,
            },
            {
              text: 'REMOVE',
              variant: 'contained',
              color: 'primary',
              onClick: handleRemove,
            },
          ]}
        >
          <Typography className={classes.text}>
            {t('placement.profile.candidatePlacementSupervision.content')}
          </Typography>
        </GenericDialog>
      </div>
    );
  };

  const GroupedSupervision = props => {
    const handleRemoveSection = placementSupervisingID => {
      setDeleteSupervison(true);
      setSelectedPlacementSupervisingId(placementSupervisingID);
    };

    const handleChange = () => {
      dispatch(placementDetailsAction.setPlacementLinkModalOpen(true));
      dispatch(placementDetailsAction.setPlacementLinkId([]));
      dispatch(placementDetailsAction.setPlacementLinkDetails([]));
    };

    const groupedRowSections = containerVals.map((item, index) => (
      <>
        <Grid container className={classes.grid}>
          <Grid item xs={11}>
            <div className={classes.groupedRow}>
              <GroupedRowSection
                key={index}
                pid={item?.placementId}
                supervision={item?.supervision}
                startDate={item?.startDate}
                endDate={item?.endDate}
                placementSupervisingID={item.placementSupervisingID}
              />
            </div>
          </Grid>
          {authorized ? (
            <Grid item xs={1} className={classes.clricon}>
              <ClearIcon onClick={() => handleRemoveSection(item?.placementSupervisingID)} />
            </Grid>
          ) : (
            ''
          )}
        </Grid>
        {containerVals.length > 1 && <Divider className={classes.divider} />}
      </>
    ));

    const emptyRowSection = (
      <div className={classes.groupedRow}>
        <GroupedRowSection pid={'--'} supervision={'--'} startDate={'--'} endDate={'--'} />
        {<Divider className={classes.divider} />}
      </div>
    );

    return (
      <React.Fragment>
        {containerVals.length === 0 ? emptyRowSection : groupedRowSections}
        {title === 'Supervisor' && authorized ? (
          <Grid container style={{ display: 'flex', flexDirection: 'row', marginTop: '5px' }}>
            <Grid item style={{ alignContent: 'flex-start' }}>
              <CustomTooltip standardMargin tooltipContent={'Link PID'}>
                <SquareButton onClick={handleChange} />
              </CustomTooltip>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <GroupedSupervision />
      <AddPlacementIdModal title={title ?? ''} />
      <RemoveSupervisor onRemove={handleRemoveSupervisor} />
    </React.Fragment>
  );
};
