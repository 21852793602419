import { Tooltip } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';

const TooltipStyle = makeStyles()(theme => ({
  tooltipArrow: {
    color: '#fff',
    '&::before': {
      border: '1px solid #E5E5E5',
      boxShadow: '0px 3px 6px #00000029',
    },
  },
  tooltipBackground: {
    backgroundColor: '#fff',
    color: '#555555',
    border: '1px solid #E5E5E5',
    boxShadow: '0px 3px 6px #00000029',
    fontSize: '12px',
  },
}));
export const MessageDisabledTooltip = (props: { children: any }) => {
  const { classes } = TooltipStyle();
  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltipBackground,
        arrow: classes.tooltipArrow,
      }}
      arrow
      title={`Text messaging is currently not enabled for your account`}
    >
      {props.children}
    </Tooltip>
  );
};
