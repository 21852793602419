import { RootState } from 'types';
import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './comparisiontable.redux';

const selectDomain = (state: RootState) => state?.comparisionTable || initialState;

export const selectComparisionTableData = createSelector([selectDomain], data => data);
export const selectTableData = createSelector([selectDomain], data => data?.tableData);
export const selectReferenceData = createSelector([selectDomain], data => data?.referenceData);
export const selectRowData = createSelector([selectDomain], data => data?.tableRows);
export const selectExpandableCells = createSelector([selectDomain], data => data?.expandableCells);
