import { GridTag } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { XGridDefaultColumn } from '@AMIEWEB/Common/XGrid/cells/XGridDefaults';
import { XGridOverflowCell } from '@AMIEWEB/Common/XGrid/cells/XGridOverflowCell';
import { GridColumnVisibilityModel, GridSortModel } from '@mui/x-data-grid-pro';
import { Box, materialUiXGrid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { XGrid, XGridLinkCell } from '@AMIEWEB/Common';
import { useDispatch, useSelector } from 'react-redux';
import { selectFacilitiesForAffiliate } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { usePromiseTracker } from 'react-promise-tracker';
import { PromiseTrackerKeys } from 'app/constants/PromiseTrackerKeys';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles()({
  gridContainer: {
    display: 'block',
    paddingTop: '24px',
    marginRight: '-6px',
  },
  gridCell: {
    outline: 'none !important',
    '& a, & div': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  gridHeader: {
    outline: 'none !important',
  },
});

const affiliateGridColumns = (classes, customOnClickHandler) => [
  {
    ...XGridDefaultColumn,
    field: 'facilityId',
    headerName: i18next.t('order.facility.rateCard.affiliatesModal.id'),
    flex: 0,
    width: 100,
    renderCell: XGridLinkCell('/facility/{0}', ['facilityId'], true, {
      checkIfCurrentPath: true,
      handleFalseClick: customOnClickHandler,
    }),
    cellClassName: classes.gridCell,
    headerClassName: classes.gridHeader,
  },
  {
    ...XGridDefaultColumn,
    field: 'facilityName',
    headerName: i18next.t('order.facility.rateCard.affiliatesModal.name'),
    flex: 1,
    width: 150,
    renderCell: XGridLinkCell('/facility/{0}', ['facilityId'], true, {
      checkIfCurrentPath: true,
      handleFalseClick: customOnClickHandler,
    }),
    cellClassName: classes.gridCell,
    headerClassName: classes.gridHeader,
  },
];

export const AffiliatesXgridModal = ({ affiliationId }: { affiliationId: number }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const apiRef = materialUiXGrid.useGridApiRef();
  const gridData = useSelector(selectFacilitiesForAffiliate);
  const { promiseInProgress: isDataLoading } = usePromiseTracker({
    area: PromiseTrackerKeys.facility.facilitiesForAffiliate,
    delay: 0,
  });
  const defaultSortModel: GridSortModel = [{ field: 'facilityName', sort: 'desc' }];
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel);
  const defaultVisibilityModel = { facilityId: true, facilityName: true };
  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>(defaultVisibilityModel);
  const history = useHistory();

  useEffect(() => {
    if (!gridData && !isDataLoading) {
      dispatch(facilityActions.getFacilitiesForAffiliate(affiliationId));
    }
  }, []);

  const customOnClickHandler = (e, params) => {
    history.push(`/facility/${params.row.facilityId}`);
    dispatch(facilityActions.getFacilityDetailsWithOpenOrderCountAction({ id: parseInt(params.row.facilityId) }));
  };

  return (
    <Box className={classes.gridContainer}>
      <XGrid
        inDialog
        gridTag={GridTag.AffiliatesXgridModal}
        apiRef={apiRef}
        loading={isDataLoading}
        columns={affiliateGridColumns(classes, customOnClickHandler)}
        columnVisibilityModel={columnVisibilityModel}
        rows={gridData || []}
        autoHeight={true}
        pagination={false}
        checkboxSelection={false}
        disableSelectionOnClick
        disablePaggination
        sortModel={sortModel}
        defaultSortModel={defaultSortModel}
        title={t('order.facility.rateCard.affiliatesModal.associatedFacilities')}
        onSortModelChange={model => setSortModel(model)}
        hideFooter={true}
        defaultRowsPerPage={gridData?.length || 0}
        onColumnVisibilityModelChange={model => setColumnVisibilityModel(model)}
        toolbarProps={{
          refreshButton: true,
          resetButton: true,
          refreshGrid: () => {
            dispatch(facilityActions.getFacilitiesForAffiliate(affiliationId));
            setSortModel(defaultSortModel);
          },
          resetColumns: () => {
            setColumnVisibilityModel(defaultVisibilityModel);
            setSortModel(defaultSortModel);
          },
        }}
      />
    </Box>
  );
};
