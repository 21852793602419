import { FormProvider, useForm } from 'react-hook-form';
import { CopyOrderBonusForm } from './CopyOrderBonusForm';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import React, { useState } from 'react';
import { Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { orderDataActions } from '@AMIEWEB/Order/Store/Order.redux';
import { selectUser } from 'oidc/user.selectors';
import { useTranslation } from 'react-i18next';
import { SessionKey, appendSessionValue } from 'utils/customHooks/sessionStorage/sessionHelpers';
import { ICustomOption } from '@AMIEWEB/Placement/CreatePlacement/CustomComponents/CustomTypeAhead';
import _ from 'lodash';
import { UseTemplate } from '../CustomComponents/UseTemplate';

const useStyles = makeStyles<{ searchScreen: boolean }>()((theme: Theme, { searchScreen }) => ({
  dialog: {
    minHeight: searchScreen ? 560 : 250,
    minWidth: searchScreen ? 1250 : 420,
    '& .MuiDialog-paperWidthSm': {
      minWidth: searchScreen ? 1250 : 420,
      maxWidth: '100%',
    },
    padding: 0,
    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .dialogContentRoot': {
      padding: 0,
    },
  },
  dialogActionsRoot: {
    paddingRight: 40,
  },
}));
export const CopyOrderBonusModal = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formMethods = useForm();
  const {
    formState: { dirtyFields, touched },
  } = formMethods;
  const { userInfo } = useSelector(selectUser);
  const { open, onClose, sourceOrderId, bonusTemplateId } = props;
  const [openDiscardDialog, setOpenDiscardDialog] = useState<boolean>(false);
  const [copyOrderBonusProps, setCopyOrderBonusProps] = useState<{
    searchScreen: boolean;
    disableCopyButton: boolean;
    searchResult: { orderId: string; facility: string };
    defaultOption: ICustomOption;
  }>({
    searchScreen: false,
    disableCopyButton: true,
    searchResult: { orderId: null, facility: null },
    defaultOption: null,
  });
  const { classes } = useStyles({ searchScreen: copyOrderBonusProps?.searchScreen });
  const handleCloseModal = reason => {
    if (reason !== 'backdropClick') {
      if (
        (!copyOrderBonusProps?.searchScreen && _.isEmpty(dirtyFields) && _.isEmpty(touched)) ||
        (copyOrderBonusProps?.searchScreen && copyOrderBonusProps?.searchResult === null)
      ) {
        onClose();
      } else {
        setOpenDiscardDialog(true);
      }
    }
  };

  const handleDiscardYesAction = () => {
    setOpenDiscardDialog(false);
    onClose();
  };

  const handleDiscardNoAction = () => {
    setOpenDiscardDialog(false);
  };

  const onCopyClick = () => {
    if (!copyOrderBonusProps?.searchScreen) {
      dispatch(
        orderDataActions.copyOrderBonus({
          sourceOrderId: sourceOrderId,
          destinationOrderId: Number(copyOrderBonusProps?.searchResult?.orderId),
          bonusTemplateId: bonusTemplateId,
          userId: userInfo?.employeeId,
        }),
      );
      setCopyOrderBonusProps({ ...copyOrderBonusProps, disableCopyButton: true });
    } else if (copyOrderBonusProps?.searchResult) {
      appendSessionValue(SessionKey.recentOrders, {
        primaryLabel: copyOrderBonusProps?.searchResult?.orderId.toString(),
        secondaryLabel: copyOrderBonusProps?.searchResult?.facility,
      });
      setCopyOrderBonusProps({
        ...copyOrderBonusProps,
        defaultOption: {
          primaryLabel: copyOrderBonusProps?.searchResult?.orderId.toString(),
          secondaryLabel: copyOrderBonusProps?.searchResult?.facility,
        } as ICustomOption,
        searchScreen: false,
      });
    }
  };

  return (
    <div>
      <GenericDialog
        open={open}
        onClose={(e, reason) => {
          handleCloseModal(reason);
        }}
        variant="blue"
        draggable
        classes={{ paper: classes.dialog }}
        dialogTitleProps={{
          text: copyOrderBonusProps?.searchScreen
            ? t('order.orderBonusTab.modalTitles.searchOrders')
            : t('order.orderBonusTab.modalTitles.copyOrderBonus'),
          closeButton: true,
          expandable: copyOrderBonusProps?.searchScreen ? true : false,
          undoButton: copyOrderBonusProps?.searchScreen ? true : false,
          onUndoClick: () => {
            setCopyOrderBonusProps({ ...copyOrderBonusProps, searchScreen: false });
          },
        }}
        dialogActionsProps={{
          classes: {
            root: classes.dialogActionsRoot,
          },
        }}
        dialogActions={[
          {
            text: t('order.orderBonusTab.confirmationTemplate.cancel'),
            color: 'tertiary',
            variant: 'contained',
            onClick: () => {
              handleCloseModal('cancel');
            },
          },
          {
            text: copyOrderBonusProps?.searchScreen
              ? t('order.orderBonusTab.confirmationTemplate.select')
              : t('order.orderBonusTab.confirmationTemplate.copy'),
            color: 'primary',
            variant: 'contained',
            disabled: copyOrderBonusProps?.disableCopyButton,
            onClick: () => {
              onCopyClick();
            },
          },
        ]}
      >
        <FormProvider {...formMethods}>
          <form>
            <CopyOrderBonusForm
              copyOrderBonusProps={copyOrderBonusProps}
              setCopyOrderBonusProps={setCopyOrderBonusProps}
            />
          </form>
          {openDiscardDialog && (
            <UseTemplate
              openDialog={openDiscardDialog}
              title={t('order.orderBonusTab.confirmationTemplate.discardTitle')}
              content={t('order.orderBonusTab.confirmationTemplate.discardContent')}
              handleYesAction={handleDiscardYesAction}
              handleNoAction={handleDiscardNoAction}
              handleCancelAction={null}
            />
          )}
        </FormProvider>
      </GenericDialog>
    </div>
  );
};
