import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GwwRateField } from './GwwRowItem';
import { deriveFieldName } from '../helper';

const useStyles = makeStyles()(theme => ({
  RateElementsContainer: {
    padding: '6px 6px 0px 6px ',
  },
}));

export const GwwShift = ({ shifDetails }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Grid container direction="column">
        <Grid item classes={{ root: classes.RateElementsContainer }}>
          {' '}
          <GwwRateField
            label={t('GWW')}
            name={deriveFieldName(['gww', shifDetails?.shiftName])}
            shiftHours={shifDetails?.GwwHoursArray}
            shift={shifDetails?.shiftName}
          />
        </Grid>
      </Grid>
    </>
  );
};
