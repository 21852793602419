import { Grid, Skeleton, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styledComponent from 'styled-components';
import { colors } from 'styles/styleVariables';
import { BillRatesWrapper } from './BillRates/BillRatesWrapper';
import { EffectiveDateWrapper } from './EffectiveDateWrapper';
import { getContractDetailsPayload, getUniqueShiftValues } from './helper';
import { GwwWrapper } from './RateElements/GwwWrapper';
import { RateCardTable } from './OffContractRateElementTable/RateCardTable';
import { createOrderOcbrActions } from 'store/redux-store/create-order-ocbr/slice';
import { usePromiseTracker } from 'react-promise-tracker';

const useStyles = makeStyles<{ isBeingEdited: boolean }>()((theme, { isBeingEdited }) => ({
  parentContainer: {
    margin: isBeingEdited ? undefined : 12,
  },
  childContainer: {
    marginBottom: 12,
  },
  actionText: {
    color: colors.amnBlue,
  },
  skeleton: {
    borderRadius: 4,
  },
}));

export const RateCard = ({ facilityId, shifts, skillsets, orderId = 0, isBeingEdited = false }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles({ isBeingEdited });
  const formMethods = useForm({ mode: 'all' });

  const { promiseInProgress } = usePromiseTracker({ area: 'rate-card-initiation', delay: 0 });
  const uniqueShifts = React.useMemo(() => getUniqueShiftValues(shifts), [shifts]);

  useEffect(() => {
    dispatch(createOrderOcbrActions.setFeatureProps({ facilityId, shifts, skillsets, uniqueShifts, isBeingEdited }));
    isBeingEdited
      ? dispatch(createOrderOcbrActions.business_initiateEditRateCard({ orderId, facilityId, uniqueShifts }))
      : dispatch(
        createOrderOcbrActions.business_initiateRateCard({
          ...getContractDetailsPayload(facilityId, shifts, skillsets),
          uniqueShifts,
        }),
      );

    return () => {
      if (isBeingEdited)
        dispatch(createOrderOcbrActions.softReset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <FormProvider {...formMethods}>
      <GridContainer container classes={{ container: classes.parentContainer }}>
        {/* Heading */}
        <Grid container item xs={12} justifyContent="space-between">
          <Typography className="page-title">{`Effective Dates`}</Typography>
          <Typography variant="body1" classes={{ body1: classes.actionText }}>{`View Contract Details`}</Typography>
        </Grid>
        <Grid item xs={12} classes={{ root: classes.childContainer }}>
          {promiseInProgress ? (
            <Skeleton variant="rectangular" classes={{ rectangular: classes.skeleton }} />
          ) : (
            <EffectiveDateWrapper />
          )}
        </Grid>
        <GridContainer item xs={12} classes={{ root: classes.childContainer }}>
          {promiseInProgress ? (
            <Skeleton variant="rectangular" classes={{ rectangular: classes.skeleton }} />
          ) : (
            <BillRatesWrapper />
          )}
        </GridContainer>
        <GridContainer item xs={12} classes={{ root: classes.childContainer }}>
          {promiseInProgress ? (
            <Skeleton variant="rectangular" classes={{ rectangular: classes.skeleton }} />
          ) : (
            <GwwWrapper />
          )}
        </GridContainer>
      </GridContainer>
      <GridContainer container classes={{ container: classes.parentContainer }}>
        {promiseInProgress ? (
          <Skeleton variant="rectangular" classes={{ rectangular: classes.skeleton }} />
        ) : (
          <RateCardTable />
        )}
      </GridContainer>
    </FormProvider>
  );
};

export const GridContainer = styledComponent(Grid)`
  border: 1px solid ${colors.containerBorder};
  border-radius: 10px;
  padding: 12px;
`;
