import { Button, Theme, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    background: '#E8F4FD',
    borderBottom: '1px solid #ccc',
    borderTop: '1px solid #ccc',
    padding: '2% 2% 2% 5%',
    display: 'flex',
    width: '100%',
  },
  header: {
    margin: '1% 0',
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Roboto',
    letterSpacing: '0px',
    color: '#333333',
    opacity: 1,
  },
  addButton: {
    margin: '5px',
    padding: '12px',
  },
}));

const AddBrand = ({ handleBrandSelection }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>{t('notification.sms.addBrand')}</Typography>
      <Button variant="contained" color="primary" className={classes.addButton} onClick={handleBrandSelection}>
        {t('notification.sms.add')}
      </Button>
    </div>
  );
};

export default AddBrand;
