import React from 'react';
import { makeStyles } from 'tss-react/mui';
const passportBadgeStyles = makeStyles()(() => ({
  passportBadge: {
    width: '21px',
    height: '21px',
    background: '#8249EB 0% 0% no-repeat padding-box',
    opacity: 1,
    float: 'right',
    borderRadius: '0px 30% / 30%',
    margin: 'auto 0px auto 6px',
  },
  externalBadgeText: {
    font: 'normal normal normal 14px/19px Roboto',
    letterSpacing: '0px',
    color: '#FFFFFF',
    opacity: 1,
    textAlign: 'center',
  },
}));
export const PassportBadge = () => {
  const { classes } = passportBadgeStyles();
  return (
    <span className={classes.passportBadge} data-testid="external-task-badge">
      <div className={classes.externalBadgeText}>{`P`}</div>
    </span>
  );
};
