/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui';
import styledComponent from 'styled-components';

export const useRowSectionStyles = makeStyles()(theme => ({
  normalText: {
    width: '100%',
  },
  iconStyle: {
    padding: '0 0.4rem',
  },
  inlineBoxEditMainCheckItem: {
    'padding-right': '0.2rem',
    '& img': {
      width: '25px',
      height: '25px',
    },
    '& svg': {
      width: '25px',
      height: '25px',
    },
  },
  inlineBoxEditMainLblItem: {
    paddingLeft: '3px',
    fontWeight: 400,
  },
}));

export const RowHead = styledComponent.th`
  color: #333333;
  font-size: 14px;
  text-align: left;
`;

export const Row = styledComponent.tr`
  display: flex;
  justify-content: space-between;
`;
