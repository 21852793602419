import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IPlacementStatus, PlacementOrderType } from 'app/models/Placement/PlacementDetails';
import { usePromiseTracker } from 'react-promise-tracker';
import { NextPlacementStatus } from 'app/components/Placement/NextPlacementStatus/NextPlacementStatus';
import { placementStatusAction } from '../../../../../../store/redux-store/placement-status/slice';
import { selectPlacementStatus } from '../../../../../../store/redux-store/placement-status/selectors';
import {
  isCandidateContract,
  isClientConfirmation,
  isNegativeStatus,
  isRequestContract,
} from 'app/components/Placement/NextPlacementStatus/StatusDefaults';
import { isInstanceOfExtensionStatus, useRequirementPromiseTracker } from './helper';
import { pageAttributesActions } from '../../Edit/store/placementPageAttributes.redux';
import { useTranslation } from 'react-i18next';
import { selectPlacementHeaderData, selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import { useHistory } from 'react-router-dom';
import { redirectToCandidateContractDocEditor } from '@AMIEWEB/Placement/CreateCandidateContractDoc/utils';
import { redirectToClientConfirmationDocEditor } from '@AMIEWEB/Placement/CreateClientConfirmationDoc/utils';
import { useDecision } from '@optimizely/react-sdk';
import { ff_placement_new_client_confirmation_ui_flow } from 'app/constants/FeatureFlags/Placement/Keys';

export const WhatsNext = ({ ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const placementOrderType = useSelector(selectPlacementOrderType);
  const { availablePlacementStatus, failedRequirements, extensionDetails, currentPlacementStatus } =
    useSelector(selectPlacementStatus);
  const { placementId } = useSelector(selectPlacementHeaderData);
  const [ccEditorViewDecision] = useDecision(ff_placement_new_client_confirmation_ui_flow);

  const { promiseInProgress: loadingStatuses } = usePromiseTracker({ area: 'get-placement-status-details', delay: 0 });
  const { promiseInProgress: updatingPlacementStatus } = usePromiseTracker({
    area: 'update-placement-status',
    delay: 0,
  });

  const { promiseInProgress: updatingExtStatus } = usePromiseTracker({
    area: 'update-extension-status',
    delay: 0,
  });

  // promises for requirement APIs
  const loadingRequirements = useRequirementPromiseTracker();
  const [filteredStatuses, setFilteredStatuses] = React.useState<IPlacementStatus[]>([]);

  const filterStatus = React.useCallback((status: IPlacementStatus) => !isNegativeStatus(status), []);
  React.useEffect(() => {
    const placementStatus = availablePlacementStatus.filter(filterStatus);

    if (placementOrderType === PlacementOrderType.StrikeOrderInHsg) {
      setFilteredStatuses(
        placementStatus.filter(status => status.activityTypeId !== 101 || status.activityStatusId !== 103),
      );
    } else {
      setFilteredStatuses(placementStatus);
    }
  }, [availablePlacementStatus, filterStatus, placementOrderType]);

  const onStatusSelected = React.useCallback(
    (status: IPlacementStatus) => {
      if (ccEditorViewDecision?.enabled && isClientConfirmation(status)) {
        redirectToClientConfirmationDocEditor(history, { defaultSelection: 'draft' });
        return;
      }
      if (isCandidateContract(status)) {
        redirectToCandidateContractDocEditor(history, { defaultSelection: 'draft' });
        return;
      }
      if (isRequestContract(status) && !status.data?.isClientConfirmationSent) {
        dispatch(placementStatusAction.createClassicRequestContract({ placementId }));
        return;
      }
      isInstanceOfExtensionStatus(status) && [5, 6].includes(status.extensionStatusId)
        ? extensionDetails.endDateTravelerOption?.approvalStatusId === 4
          ? dispatch(
              pageAttributesActions.setPreExtensionModalProps({
                open: true,
                isNewExtension: true,
                title: `How would you like to proceed?`,
                extensionStatusId: status.extensionStatusId,
                extensionStatus: status.extensionStatus,
              }),
            )
          : dispatch(
              pageAttributesActions.setExtensionModalProps({
                open: true,
                isNewExtension: true,
                title: `${t('Extension')} - ${status.extensionStatus}`,
                ...status,
                extensionOverride: 0,
              }),
            )
        : dispatch(placementStatusAction.setSelectedStatus({ status, statusChangeSource: 'whatsNext' }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [extensionDetails.endDateTravelerOption?.approvalStatusId, history, placementId],
  );

  return (
    <React.Fragment>
      <NextPlacementStatus
        statusList={filteredStatuses}
        failedRequirements={failedRequirements}
        loading={loadingStatuses || updatingPlacementStatus || updatingExtStatus || loadingRequirements}
        onStatusSelected={onStatusSelected}
        extensionDetails={extensionDetails}
        activityStatusId={currentPlacementStatus?.activityStatusId}
        activityTypeId={currentPlacementStatus?.activityTypeId}
      />
    </React.Fragment>
  );
};
