import React from 'react';
import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';
import { ReminderGraphicStepper } from './ReminderGraphicStepper';
import { placementReminderActions } from '../Store/PlacementReminders.redux';

const useStyles = makeStyles()({
  container: {
    flexWrap: 'nowrap',
    background: '#FCFCFD',
    border: '1px solid #F5F5F5',
    padding: '1%',
  },
  total: {
    padding: '0 2% 0 1%',
    maxWidth: 140,
  },
  placementTotal: {
    maxWidth: 155,
  },
});

export const ReminderWorkDeskPipeline = props => {
  const { pipelineTotal, data, selection, isCredentialing = false } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const onStatusClicked = (id, count) => {
    if (count !== 0) {
      dispatch(placementReminderActions.setStateID(id));
    }
  };

  return (
    <Grid item container direction="row" classes={{ container: classes.container }}>
      <Grid
        item
        container
        alignItems="center"
        classes={{ root: isCredentialing ? classes.placementTotal : classes.total }}
      >
        {pipelineTotal}
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <ReminderGraphicStepper
          items={data}
          spacing={11.11}
          selectedItem={selection}
          onItemSelected={onStatusClicked}
          loading={pipelineTotal === undefined}
          error={false}
        />
      </Grid>
    </Grid>
  );
};
