import React from 'react';
import { Tooltip, TooltipProps, Typography } from 'amn-ui-core';

interface GenericTooltipProps extends TooltipProps {
  variant?: 'tooltip' | 'callout' | 'overflow';
}

/**
 * Do not add custom overrides to this component. This is a standardized component and should be used as it is
 */
export const GenericTooltip = ({ children, title, variant = 'tooltip', ...rest }: GenericTooltipProps) => {
  return (
    <>
      <Tooltip
        arrow={variant !== 'overflow'}
        disableInteractive={variant === 'tooltip'}
        {...rest}
        enterDelay={500}
        leaveDelay={200}
        componentsProps={{
          arrow: {
            'data-variant': 'standard',
          },
          tooltip: {
            'data-variant': 'standard',
            sx: theme => ({
              padding:
                variant === 'tooltip'
                  ? `${theme.spacing()} ${theme.spacing(2)}`
                  : variant === 'callout'
                  ? `${theme.spacing(2)} ${theme.spacing(4)}`
                  : theme.spacing(2),
            }),
          },
        }}
        title={
          <Typography variant={variant === 'tooltip' ? 'body2' : 'body1'} component="div">
            {title}
          </Typography>
        }
      >
        {children}
      </Tooltip>
    </>
  );
};
