import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import { navigationStickActions } from './NavigationStick.redux';
import { userRoles } from 'oidc/userRoles';
import _ from 'lodash';

export const useRecentHistoryNavigation = () => {
  const userPreference = useSelector(selectUserPreference);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const recentHistoryPrefs = userPreference?.recentHistory?.value;
    if (recentHistoryPrefs) {
      // Candidates
      _.cloneDeep(recentHistoryPrefs?.candidate || [])
        .reverse()
        .forEach(candidate => {
          dispatch(
            navigationStickActions.upsertSubMenuByKey({
              key: 'recentCandidate',
              menu: {
                key: `${candidate.candidateId}`,
                name:
                  candidate.candidateName.length > 7
                    ? `${candidate.candidateName.substring(0, 6).trim()}...(${candidate.candidateId})`
                    : `${candidate.candidateName} (${candidate.candidateId})`,
                title:
                  candidate.candidateName.length > 7
                    ? `${candidate.candidateName} (${candidate.candidateId})`
                    : undefined,
                clickable: false,
                roles: [userRoles.all],
                action: {
                  type: 'candidateDrawer',
                  data: {
                    open: true,
                    candidate: {
                      candidateId: candidate.candidateId,
                      brandId: candidate.brandId,
                    },
                  },
                },
              },
              index: 0,
              lengthLimit: 5,
            }),
          );
        });

      // Placements
      _.cloneDeep(recentHistoryPrefs?.placement || [])
        .reverse()
        .forEach(placement => {
          dispatch(
            navigationStickActions.upsertSubMenuByKey({
              key: 'recentPlacement',
              menu: {
                key: `${placement.placementId}`,
                name: `PID ${placement.placementId}`,
                clickable: false,
                roles: [userRoles.all],
                action: {
                  type: 'placementDrawer',
                  data: {
                    placementId: placement.placementId,
                  },
                },
              },
              index: 0,
              lengthLimit: 5,
            }),
          );
        });

      // Orders
      _.cloneDeep(recentHistoryPrefs?.order || [])
        .reverse()
        .forEach(order => {
          dispatch(
            navigationStickActions.upsertSubMenuByKey({
              key: 'recentOrder',
              menu: {
                key: `${order.orderId}`,
                name: `OID ${order.orderId} - ${
                  order?.name?.length > 4 ? `${order?.name?.substring(0, 3)}...` : order?.name
                }`,
                title: order?.name?.length > 4 ? `OID ${order.orderId} - ${order?.name}` : undefined,
                clickable: false,
                roles: [userRoles.all],
                action: {
                  type: 'orderDrawer',
                  data: {
                    open: true,
                    order: {
                      orderId: `${order.orderId}`,
                    },
                  },
                },
              },
              index: 0,
              lengthLimit: 5,
            }),
          );
        });

      // Facilities
      _.cloneDeep(recentHistoryPrefs?.facility || [])
        .reverse()
        .forEach(facility => {
          dispatch(
            navigationStickActions.upsertSubMenuByKey({
              key: 'recentFacility',
              menu: {
                key: `${facility.facilityId}`,
                name: `FID ${facility.facilityId} - ${
                  facility?.name?.length > 4 ? `${facility?.name?.substring(0, 3)}...` : facility?.name
                }`,
                title: facility?.name?.length > 4 ? `FID ${facility.facilityId} - ${facility?.name}` : undefined,
                clickable: false,
                roles: [userRoles.all],
                action: {
                  type: 'facilityDrawer',
                  data: {
                    open: true,
                    facility: {
                      facilityId: facility.facilityId,
                    },
                  },
                },
              },
              index: 0,
              lengthLimit: 5,
            }),
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreference, userPreference?.recentHistory]);
};
