import React from 'react';
import { useTranslation } from 'react-i18next';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';

export const Cancel = ({
  openDialog,
  handleConfirmAction,
  handleCancelAction,
  cancelButtonId,
  confirmButtonId,
}: {
  openDialog: boolean;
  handleConfirmAction: any;
  handleCancelAction: any;
  cancelButtonId?: string;
  confirmButtonId?: string;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <GenericDialog
        open={openDialog}
        maxWidth="sm"
        dialogTitleProps={{ text: t('cancelModal.undoConfirmation') }}
        dialogActions={[
          {
            id: cancelButtonId,
            text: t('cancelModal.confirmation.cancel'),
            onClick: handleCancelAction,
            color: 'tertiary',
          },
          {
            id: confirmButtonId,
            text: t('cancelModal.confirmation.yes'),
            onClick: handleConfirmAction,
            variant: 'contained',
          },
        ]}
      >
        {t('cancelModal.saveConfirmation')}
      </GenericDialog>
    </>
  );
};
