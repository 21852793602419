import React from 'react';
import { useTranslation } from 'react-i18next';
import { UnitDetailsTextArea } from '../../../Common/UnitDetailsCommonTextArea';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid } from 'amn-ui-core';

export const FirstDayInstructionContainer = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Grid>
      <Controller
        control={control}
        name={'firstDayInstructions'}
        render={({ ref, onChange, ...rest }) => (
          <UnitDetailsTextArea
            fieldLabel={t('facility.instructionsTab.firstDayInstructions')}
            fieldName={'firstDayInstructions'}
            id={'firstDayInstructions'}
            onChange={onChange}
            isDisabled={false}
            inputValue={rest.value}
            defaultInputValue={''}
            addTimeStamp
          />
        )}
      />
    </Grid>
  );
};
