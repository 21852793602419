import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { PlacementRequestService } from 'app/services/PlacementRequestService/placementRequestService';
import { getSearchFilterOptions } from 'app/services/SharedServices/SharedServices';
import { trackPromise } from 'react-promise-tracker';
import { call, getContext, put } from 'redux-saga/effects';
import { getFlattenedGridData } from './helper';
import { placementRequestsAction } from './slice';

const TrackAutoTaskGridDataReqWrapper = (fn, ...args) => trackPromise(fn(...args), 'fetch-auto-task-grid-data');
const TrackAutoTaskEmployeeRolesReq = (fn, ...args) => trackPromise(fn(...args), 'fetch-auto-task-employee-roles');
const TrackAutoTaskManagementStatusLookUpReq = (fn, ...args) =>
  trackPromise(fn(...args), 'fetch-auto-task-status-lookup');

export function* getAutoTaskGridData() {
  try {
    const AutoTaskManagementService: PlacementRequestService = yield getContext('placementRequestService');
    const response = yield call(TrackAutoTaskGridDataReqWrapper, AutoTaskManagementService?.getAutoTaskCategories);
    const flattenedData = getFlattenedGridData(response?.data || []);
    yield put(placementRequestsAction.setGridData(flattenedData));
    yield put(placementRequestsAction.setResponseData(flattenedData));
  } catch (err) {
    trackException({
      exception: err,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getAutoTaskGridData',
        area: 'src/store/redux-store/placement-requests/request.saga.ts',
      },
    });
  }
}
export function* getAutoTaskEmployeeRoles() {
  try {
    const AutoTaskManagementService: PlacementRequestService = yield getContext('placementRequestService');
    const response = yield call(TrackAutoTaskEmployeeRolesReq, AutoTaskManagementService?.getAutoTaskEmployeeRoles);
    yield put(placementRequestsAction.setUserRolesOptionsList(response?.data || []));
  } catch (err) {
    trackException({
      exception: err,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getAutoTaskEmployeeRoles',
        area: 'src/store/redux-store/placement-requests/request.saga.ts',
      },
    });
  }
}
export function* getAutoTaskManagementStatusLookUp() {
  try {
    const response = yield call(TrackAutoTaskManagementStatusLookUpReq, getSearchFilterOptions, 'autotaskstatuses ');
    const formattedResponse = response.map(item => {
      return {
        statusId: parseInt(item?.value || 0),
        status: item?.name || '',
      };
    });
    yield put(placementRequestsAction.setAutoTaskManagementStatusLookUp(formattedResponse || []));
  } catch (err) {
    trackException({
      exception: err,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getAutoTaskEmployeeRoles',
        area: 'src/store/redux-store/placement-requests/request.saga.ts',
      },
    });
  }
}
