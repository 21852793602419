/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Grid, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RateElementTable } from './RateElementTable';
import { MileageTable } from './MileageTable';
import {
  selectRateFeatureProps,
  selectRateOpts,
} from 'store/redux-store/create-order-ocbr/selector';

const useStyles = makeStyles()(theme => ({
  headerTextStyle: {
    fontSize: 18,
    fontWeight: 500,
    paddingRight: 12,
  },
  divider: {
    width: '100%',
    margin: '6px',
  },
}));
export const GwwWrapper = () => {
  const { gwwTable, mileageTable } = useSelector(selectRateOpts);
  const { t } = useTranslation();
  const { uniqueShifts } = useSelector(selectRateFeatureProps);

  const { classes } = useStyles();
  return (
    <>
      <Grid container justifyContent="flex-start" xs={12}>
        <Grid item xs={12}>
          <Typography className="page-title">{t('order.newOrder.rateCard.rateElements')}</Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: 'grid' }} style={{ position: 'relative' }}>
          <RateElementTable GwwHoursArray={gwwTable} shiftData={uniqueShifts} />
        </Grid>
        <Divider light classes={{ root: classes.divider }} />
        <Grid item xs={12} sx={{ display: 'grid' }} style={{ position: 'relative' }}>
          <MileageTable
            label={t('order.newOrder.rateCard.mileageReimbursement')}
            name={'mileageReimburesement'}
            shiftRates={mileageTable}
          />
        </Grid>
      </Grid>
    </>
  );
};
