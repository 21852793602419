import { Grid, GridProps } from 'amn-ui-core';
import React from 'react';

/**
 * Custom Grid Item. Breakpoints are overridden to use custom widths
 * @param param0
 * @returns
 */
export const LayoutGridItem = ({ item = true, ...props }: React.ComponentProps<typeof Grid>) => {
  return (
    <Grid item {...props}>
      {props.children}
    </Grid>
  );
};

interface LayoutGridProps extends GridProps {
  padding?: 'all' | 'vertical' | 'horizontal';
}

export const LayoutGrid = ({ padding,...rest  }: LayoutGridProps) => {
  return (
    <Grid
      container
      spacing={2}
      className={`${
        padding === 'all'
          ? 'page-section'
          : padding === 'vertical'
          ? 'page-section-vertical'
          : padding === 'horizontal'
          ? 'page-section-horizontal'
          : ''
      }${rest?.className || null}`}
      {...rest}
    >
      {rest.children}
    </Grid>
  );
};
