import { Box, Grid, Typography } from 'amn-ui-core';
import { TaskNotificationType } from 'app/models/Tasks/Tasks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultIcon from 'app/assets/images/Tasks/default-icon.svg';
import CheckIcon from 'app/assets/images/notification-icon.svg';
import CheckIconDisabled from 'app/assets/images/CheckIconDisabled.svg';
import TaskCreatedIcon from 'app/assets/images/Checkmark-Notification-icon.svg';
import TaskCreatedIconDisabled from 'app/assets/images/Tasks/TaskIconDisabled.svg';
import { TextWithEllipses } from '@AMIEWEB/Notification/Common/StringHelper';


export const TaskPopupContext = ({ NotificationBody, classes, associatedRecords, showActionPanel, isUnread }: { NotificationBody: any; classes: any, associatedRecords: any; showActionPanel: boolean; isUnread: boolean; }) => {
  const { t } = useTranslation();
  let Maxlength
  if (showActionPanel) {
    Maxlength = 12;
  }
  else {
    Maxlength = 25;
  }

  return (
    <React.Fragment>
      <Grid container direction="row">
        <Grid item xs={1}>
          <div className={classes.statusIcon}>
            {parseInt(NotificationBody?.useSubType) === TaskNotificationType.COMPLETED ||
              parseInt(NotificationBody?.useSubType) === TaskNotificationType.CANCELLED ? (
              <img src={isUnread ? CheckIcon : CheckIconDisabled} />
            ) : parseInt(NotificationBody?.useSubType) === TaskNotificationType.TASKCREATED ||
              parseInt(NotificationBody?.useSubType) === TaskNotificationType.TASKEDITED ? (
              <img src={isUnread ? TaskCreatedIcon : TaskCreatedIconDisabled} />
            ) : (
              <img src={DefaultIcon} />
            )}
          </div>
        </Grid>
        <Grid item xs={10} style={{ paddingLeft: '12px', position: 'relative', zIndex: '1' }}>
          <div className={classes.flexContainer}>
            {parseInt(NotificationBody?.useSubType) === TaskNotificationType.TASKCREATED && (
              <Grid item xs={12}>
                <Box display={'flex'}>
                  <Typography className={!isUnread ? classes.mainTitleRead : classes.mainTitle}>
                    {t('notification.taskNotification.newTask')}
                  </Typography>
                </Box>
                <Box display={'flex'}>
                  <Typography className={!isUnread ? classes.secondaryTitleRead : classes.secondaryTitle}>
                    {TextWithEllipses(associatedRecords?.PrimaryContext, Maxlength)}
                    <span className={classes.spanText}>&nbsp;{'(' + associatedRecords?.EntityId + ')'}</span>
                  </Typography>
                </Box>
              </Grid>
            )}
            {parseInt(NotificationBody?.useSubType) === TaskNotificationType.TASKEDITED && (
              <Grid item xs={12}>
                <Box display={'flex'}>
                  <Typography className={!isUnread ? classes.mainTitleRead : classes.mainTitle}>
                    {t('notification.taskNotification.taskUpdated')}
                  </Typography>
                </Box>
                <Box display={'flex'}>
                  <Typography className={!isUnread ? classes.secondaryTitleRead : classes.secondaryTitle}>
                    {TextWithEllipses(associatedRecords?.PrimaryContext, Maxlength)}
                    <span className={classes.spanText}>&nbsp;{'(' + associatedRecords?.EntityId + ')'}</span>
                  </Typography>
                </Box>
              </Grid>
            )}
            {parseInt(NotificationBody?.useSubType) !== TaskNotificationType.TASKCREATED && (
              <div className={classes.notificationTitle}>
                {parseInt(NotificationBody?.useSubType) === TaskNotificationType.COMPLETED && (
                  <Grid item xs={12}>
                    <Box display={'flex'}>
                      <Typography className={!isUnread ? classes.mainTitleRead : classes.mainTitle}>
                        {t('notification.taskNotification.taskCompleted')}
                      </Typography>
                    </Box>
                    <Box display={'flex'}>
                      <Typography className={!isUnread ? classes.secondaryTitleRead : classes.secondaryTitle}>
                        {TextWithEllipses(associatedRecords?.PrimaryContext, Maxlength)}
                        <span className={classes.spanText}>&nbsp;{'(' + associatedRecords?.EntityId + ')'}</span>
                      </Typography>
                    </Box>
                  </Grid>
                )}
                {parseInt(NotificationBody?.useSubType) === TaskNotificationType.CANCELLED && (
                  <Grid item xs={12}>
                    <Box display={'flex'}>
                      <Typography className={!isUnread ? classes.mainTitleRead : classes.mainTitle}>
                        {t('notification.taskNotification.taskCancelled')}
                      </Typography>
                    </Box>
                    <Box display={'flex'}>
                      <Typography className={!isUnread ? classes.secondaryTitleRead : classes.secondaryTitle}>
                        {TextWithEllipses(associatedRecords?.PrimaryContext, Maxlength)}
                        <span className={classes.spanText}>&nbsp;{'(' + associatedRecords?.EntityId + ')'}</span>
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </div>
            )}
          </div>

        </Grid>
        <div className={!isUnread ? classes.taskDescription : classes.taskDescriptionunbold}>
          <div className={classes.truncateDescription}>{associatedRecords?.Description}</div>
        </div>
      </Grid>
    </React.Fragment>
  );
};
