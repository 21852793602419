import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  selectCandidate,
  selectMiscellaneousData,
  selectNewPlacementChoice,
  selectScreenSelectionEnabled,
} from 'store/redux-store/new-placement/selectors';
import { Concatenate } from 'utils/string/string';
import { ICustomOption } from '../CustomComponents/CustomTypeAhead';
import { PlacementOrderType } from 'app/models/Placement/PlacementDetails';
import moment from 'moment';
import { TimeOffMode } from '@AMIEWEB/Common/TimeOffRequest';

export const useSearchSelections = (isDefaultCandidate, isDefaultOrder) => {
  const { candidate, order } = useSelector(selectNewPlacementChoice);
  const { screenOptions, orderSkillOpts } = useSelector(selectMiscellaneousData);
  const screenSelectionEnabled = useSelector(selectScreenSelectionEnabled);
  const candidateStatus = useSelector(selectCandidate);
  const savedTimeOffRequests = candidateStatus?.timeOffRequests;

  const { setValue } = useFormContext();

  const setCandidateFormValues = useCallback(
    ({ candidate: _candidate, availabilityDate, sellingPoints, requestedTimeOff, requestedShift, recruiter }) => {
      /** isDefaultCandidate - Ensure candidate search field is prepopulated w.r.t candidate page reference */
      if (isDefaultCandidate) setValue('candidate', _candidate);
      setValue('availabilityDate', availabilityDate);
      setValue('sellingPoints', sellingPoints);
      setValue('requestedTimeOff', requestedTimeOff);
      setValue('requestedShift', requestedShift);
      setValue('recruiter', recruiter);
    },
    [isDefaultCandidate, setValue, candidateStatus?.timeOffRequests],
  );

  useEffect(
    () => {
      const name = Concatenate([candidate?.firstName || '', candidate?.lastName || ''], ', ');
      const disciplines = Concatenate(
        (candidate?.groupedSkillsets || []).map(item => item.disciplineAbbr),
        ', ',
      );

      const isStrikeOrder = order?.placementOrderType === PlacementOrderType.StrikeOrderInHsg;
      const availabilityDate = isStrikeOrder
        ? moment().add(1, 'year').format('YYYY-MM-DDTHH:mm:ss')
        : candidate?.availableStartDate ?? null;
      const requestedShift = isStrikeOrder ? 'Variable' : candidate?.requestedShifts ?? null;

      setCandidateFormValues({
        candidate:
          isDefaultCandidate && candidate
            ? ({
                object: {
                  name,
                  candidateId: candidate?.candidateId,
                  brandId: candidate?.brandId,
                  brand: candidate?.brand,
                  recruiter: candidate?.recruiter,
                  recruiterId: candidate?.recruiterId,
                  disciplines,
                  email: candidate?.email,
                },
                primaryLabel: `${name} (${candidate?.candidateId})`,
                secondaryLabel: Concatenate([candidate?.brand || '', candidate?.recruiter || '', disciplines], ' - '),
              } as ICustomOption)
            : null,
        availabilityDate: availabilityDate,
        sellingPoints: candidate?.sellingPoints || null,
        requestedTimeOff: {
          requestedTimeOffMode: TimeOffMode.AddTimeOff,
          requestedTimeOff: savedTimeOffRequests ?? [],
          isRequestedTimeOffMakeUp: false,
          requestedTimeOffNote: null,
        },
        requestedShift: requestedShift,
        recruiter:
          candidate && candidate.recruiterId
            ? {
                object: { id: candidate.recruiterId, value: candidate.recruiterId, name: candidate.recruiter },
                label: candidate.recruiter,
              }
            : null,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [candidate, order?.placementOrderType, candidateStatus?.timeOffRequests],
  );

  useEffect(() => {
    /** isDefaultOrder - Ensure order search field is prepopulated w.r.t order page reference */
    if (isDefaultOrder)
      setValue(
        'order',
        order
          ? ({
              object: { orderId: order?.orderId, facilityId: order?.facilityId, facility: order?.facility },
              primaryLabel: order?.orderId.toString(),
              secondaryLabel: order?.facility,
            } as ICustomOption)
          : null,
      );

    setValue('skillset', orderSkillOpts[0] ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderSkillOpts]);

  /** Code: Set screen selection, when order changes */
  useEffect(() => {
    if (screenSelectionEnabled) setValue('screenOption', screenOptions[0] ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, screenOptions, screenSelectionEnabled]);
};
