import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';
import { RootState } from 'types';
import { PlacementOrderType } from 'app/models/Placement/PlacementDetails';

const selectDomain = (state: RootState) => state?.newPlacement || initialState;

export const selectNewPlacementChoice = createSelector([selectDomain], placement => placement);
export const selectNewPlacementId = createSelector([selectDomain], placement => placement.newPlacementId);
export const selectCandidate = createSelector([selectDomain], placement => placement.candidate);
export const selectOrder = createSelector([selectDomain], placement => placement.order);
export const selectValidations = createSelector([selectDomain], placement => placement.validations);
export const selectNewPlacementTimestamp = createSelector([selectDomain], state => state.timestamp);

export const selectCreatedPlacement = createSelector([selectDomain], placement => placement.newPlacementId);
export const selectCreatePlacementOrderType = createSelector(
  [selectDomain],
  placement => placement.order?.placementOrderType,
);

export const selectMiscellaneousData = createSelector([selectDomain], placement => ({
  screenOptions: placement.miscellaneous?.screenOptions ?? [],
  orderSkillOpts: placement.miscellaneous?.orderSkillOpts ?? [],
  isAuditValid: placement.miscellaneous?.isAuditValid,
}));
export const selectErrorMessage = createSelector([selectDomain], state => state.errorMessage);

export const selectCreatePlacementModalFunctions = createSelector(
  [selectDomain],
  state => state.useNewCreatePlacementModalFunctions,
);

export const selectScreenSelectionEnabled = createSelector(
  [selectDomain],
  ({ createScreenEnabled, order }) =>
    createScreenEnabled && order?.placementOrderType !== PlacementOrderType.StrikeOrderInHsg,
);

export const selectCueNoteEmployees = createSelector([selectDomain], state => state.cueNoteEmployees);
