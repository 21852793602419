import { Grid } from 'amn-ui-core';
import { DateRangePicker } from 'app/ComponentLibrary/DateRangeNew';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectOcbrDetails } from 'store/redux-store/create-order-ocbr/selector';
import { createOrderOcbrActions } from 'store/redux-store/create-order-ocbr/slice';
import { makeStyles } from 'tss-react/mui';
import { getDateValidationStatusAndMessage } from './helper';

const useStyles = makeStyles()(theme => ({
  effectiveDateStyle: {
    paddingRight: '48px !important',
    fontWeight: 500,
    marginTop: '12px'
  },
  container: {
    padding: '12px 0px 12px 0px',
  },
  textPadding: {
    padding: '0px 12px 0px 12px !important',
    marginTop: '12px'
  }
}));

export const EffectiveDates = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { effectiveDates } = useSelector(selectOcbrDetails);
  const dispatch = useDispatch();

  const updateEffectiveDate = (key: 'startDate' | 'endDate', dateValue) => {
    const effDate = {
      startDate: key === 'startDate' ? dateValue : effectiveDates?.startDate,
      endDate: key === 'endDate' ? dateValue : effectiveDates?.endDate,
    };
    dispatch(createOrderOcbrActions.business_updateEffectiveDates(effDate));
  };

  return (
    <Grid container justifyContent="flex-start" alignItems="flex-start" classes={{ root: classes.container }}>
      <Grid item classes={{ root: classes.effectiveDateStyle }}>
        {t('order.newOrder.rateCard.effectiveDate')}
      </Grid>
      <Grid item>
        <DateInputWrapper
          placeholder={t('order.newOrder.rateCard.startDate')}
          date={effectiveDates?.startDate}
          setDate={newValue => updateEffectiveDate('startDate', newValue)}
          maxDate={effectiveDates?.endDate}
          dateType="startdate"
        />
      </Grid>

      <Grid item classes={{ root: classes.textPadding }}>{`to`}</Grid>
      <Grid item>
        <DateInputWrapper
          placeholder={t('order.newOrder.rateCard.endDate')}
          date={effectiveDates?.endDate}
          setDate={newValue => updateEffectiveDate('endDate', newValue)}
          minDate={effectiveDates?.startDate}
          dateType="enddate"
        />
      </Grid>
    </Grid>
  );
};

const DateInputWrapper = ({ placeholder, date, setDate, maxDate = undefined, minDate = undefined, dateType = undefined }) => {
  const [error, setError] = useState({ status: false, helperText: '' });
  const dispatch = useDispatch();
  const { effectiveDates } = useSelector(selectOcbrDetails);
  return (
    <DateRangePicker
      onChange={date => {
        setDate(date.endDate ? new Date(date?.endDate) : null);
        date.endDate ? setError({ status: false, helperText: '' }) : setError({ status: true, helperText: 'Required' });
      }}
      value={date}
      actionBar={false}
      single={true}
      variant="filled"
      placeholder={placeholder}
      clickAway={true}
      keepClickAwayVal={true}
      maxWidth={300}
      fullWidth
      trailingIconShow={true}
      quickSelect={false}
      initialDateRange={{ startDate: date, endDate: date, useLabelAsValue: true }}
      minDate={minDate ? new Date(minDate) : null}
      maxDate={maxDate ? new Date(maxDate) : null}
      onBlurText={value => {
        const isValidDate = getDateValidationStatusAndMessage(value, dateType, effectiveDates)
        setError({ status: !isValidDate.valid, helperText: isValidDate.message });
        dispatch(createOrderOcbrActions.setRateCardValidationError(!isValidDate.valid));
      }}
      error={error.status}
      helperText={error.helperText}
    />
  );
};