import { FluVaccinationStatus } from "@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/SummaryTab/Cards/VaccinationCard/ITooltipField";
import moment from "moment";

export const getExpirationDate = date => {
  if (date) {
    const dateCompleted = moment(date);
    if (
      dateCompleted.isSameOrAfter(moment().set({ month: 0, date: 1 })) &&
      dateCompleted.isSameOrBefore(moment().set({ month: 3, date: 1 }))
    ) {
      return dateCompleted.set({ month: 8, date: 1 }).toDate();
    } else {
      return dateCompleted.add(1, 'years').set({ month: 8, date: 1 }).toDate();
    }
  }
  return null;
};

export const getUniqueArray = array => {
  return [...new Set(array)];
};

export const getFluVaccinationStatus = data => {
  if (data.dateCompleted) {
    const dateCompleted = moment(data.dateCompleted);
    if (
      moment().isSameOrAfter(moment().set({ month: 7, date: 1 })) &&
      moment().isSameOrBefore(moment().set({ month: 7, date: 31 })) &&
      dateCompleted.isSameOrBefore(moment().set({ month: 3, date: 1 }))
    ) {
      return FluVaccinationStatus.VaccinationExpiring;
    } else if (
      (moment().isSameOrAfter(moment().set({ month: 8, date: 1 })) &&
        moment().isSameOrBefore(moment().set({ month: 11, date: 31 })) &&
        dateCompleted.isSameOrAfter(moment().set({ month: 8, date: 1 }))) ||
      (moment().isSameOrAfter(moment().set({ month: 0, date: 1 })) &&
        moment().isSameOrBefore(moment().set({ month: 6, date: 31 })) &&
        dateCompleted.isSameOrAfter(moment().set({ year: moment().year() - 1, month: 8, date: 1 })) &&
        dateCompleted.isSameOrBefore(moment().set({ month: 3, date: 1 })))
    ) {
      return FluVaccinationStatus.Vaccinated;
    } else if (
      (moment().isSameOrBefore(moment().set({ month: 11, date: 31 })) &&
        moment().isSameOrAfter(moment().set({ month: 8, date: 1 })) &&
        dateCompleted.isSameOrBefore(moment().set({ month: 3, date: 1 }))) ||
      (moment().isSameOrAfter(moment().set({ month: 0, date: 1 })) &&
        moment().isSameOrBefore(moment().set({ month: 6, date: 31 })) &&
        dateCompleted.isSameOrBefore(moment().set({ year: moment().year() - 1, month: 7, date: 31 })))
    ) {
      return FluVaccinationStatus.VaccinationExpired;
    } else if (dateCompleted.isSameOrAfter(moment().set({ month: 3, date: 1 }))) {
      return FluVaccinationStatus.Unvaccinated;
    }
  } else {
    return FluVaccinationStatus.Unvaccinated;
  }
};