import { Checkbox, FormControlLabel, Typography } from 'amn-ui-core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { missingField } from 'app/constants';
import { BoxContainer } from '../../CustomComponents/BoxContainer';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import { useSelector } from 'react-redux';
import { selectFacilityInstructions, selectIsFacilityByIdRequestSuccess } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { selectUser } from 'oidc/user.selectors';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { CustomTooltip } from '@AMIEWEB/Common';
import { EditDrugScreenPolicy } from './EditDrugScreenPolicy';
import { useUnitDetailStyles } from '@AMIEWEB/Unit/UnitDetails/Containers/UnitDetails';
import { DrugScreenSkeleton } from '../Utils';

export const DrugScreenPolicy = () => {
  const { t } = useTranslation();
  const { classes } = useUnitDetailStyles();
  const facilityInstructions = useSelector(selectFacilityInstructions);
  const userData = useSelector(selectUser);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const facilityLoading = useSelector(selectIsFacilityByIdRequestSuccess);
  const isAuthorized = Authorized([userRoles.clinical_ClientContractSpecialist], userData.userInfo);
  
  return (
    <>
      <BoxContainer
        title={t('facility.drugScreenPolicy.title')}
        icon={<AssignmentOutlinedIcon fontSize="small" />}
        onClick={() => setIsEditOpen(true)}
        rightIcon={isAuthorized ? <CreateOutlinedIcon /> : false}
        iconToolTip="Edit"
      >
        {facilityLoading ? (
          <DrugScreenSkeleton />
        ) : (
          <LayoutGridItem container>
            <LayoutGridItem item>
              <Typography className={classes.textBold} pt={0.5}>
                {t('facility.drugScreenPolicy.title')}
              </Typography>
              <Typography variant="body1" sx={{ color: 'system.black', paddingTop: '12px' }}>
                {facilityInstructions &&
                  <CustomTooltip tooltipContent={facilityInstructions?.isSendDrugScreen ? t('facility.drugScreenPolicy.isSendDrugScreenYes') : t('facility.drugScreenPolicy.isSendDrugScreenNo')}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={'send-drug-screen'}
                          checked={facilityInstructions?.isSendDrugScreen}
                          color="primary"
                          disabled={true}
                          name={'send-drug-screen'}
                        />
                      }
                      label={`FCRA`}
                    />
                  </CustomTooltip>
                }
              </Typography>
            </LayoutGridItem>
            <LayoutGridItem item ml={4}>
              <Typography variant="body1" sx={{ color: 'system.black' }}>
                {facilityInstructions?.marijuanaPolicyName ?? missingField}
              </Typography>

            </LayoutGridItem>
          </LayoutGridItem>
        )}
      </BoxContainer>
      {isEditOpen && <EditDrugScreenPolicy open={isEditOpen} handleClose={() => setIsEditOpen(false)} />}
    </>
  );
};
