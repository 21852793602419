import * as React from 'react';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import ErrorBoundary from 'app/components/ErrorBoundary';
import { reactPlugin } from 'app-insights/AppInsights';
import { useState } from 'react';

interface ApplicationInsightsWrapperProps {
  children?: any;
}

export const ApplicationInsightsWrapper: React.FC<ApplicationInsightsWrapperProps> = props => {
  const [error, setError] = useState(0);

  const returnToHomeClicked = () => {
    setError(prevState => prevState + 1);
  };

  return (
    <div data-testid="ttm-insights-wrapper">
      <AppInsightsErrorBoundary
        key={error}
        appInsights={reactPlugin}
        onError={() => <ErrorBoundary onReturnToHomeClicked={returnToHomeClicked} />}
      >
        <></>
        {props.children}
      </AppInsightsErrorBoundary>
    </div>
  );
};
