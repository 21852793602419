import { PayloadAction } from '@reduxjs/toolkit';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { pdfFileDownloader } from 'app/helpers/pdfFileDownloader';
import {
  fetchPdfFileFromBlobStorage,
} from 'app/services/CandidateServices/CandidateServices';
import { httpSuccess } from 'app/services/serviceHelpers';
import moment from 'moment';
import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import { candidateDocumentActions } from './candidateDocuments.redux';
import i18next from 'i18next';
import { CandidateService } from 'app/services/CandidateServices/candidate-service';
import { trackPromise } from 'react-promise-tracker';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
/**
 * Method to get passport documents from external API
 */

const TrackGetDocumentsFromPassport = (fn, ...args) =>
  trackPromise(fn(...args), 'get-documents-from-passport');

export function* fetchDocumentsFromPassport(action: PayloadAction<string>) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const response = yield call(TrackGetDocumentsFromPassport,
      candidateService.getCandidateDocumentsPassport,
      action.payload);
    const { data } = response;
    if (data) {
      data?.items.forEach(item => (item.submitDate = moment(item.submitDate).format(`MM/DD/YYYY hh:mm a`)));
      yield put(candidateDocumentActions.setCandidateDocuments(data));
    }
  }
  catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'fetchDocumentsFromPassport',
        area: 'src/app/components/Candidate/CandidateProfile/CandidateTabPanel/DocumentsTab/store/candidateDocument.saga.ts',
      },
    });
  }
}

/**
 * Method to download the pdf for selected document
 */
export function* downloadPDfFile(action: PayloadAction<{ url: string; formTitle: string }>) {
  const pdfBlobResponse = yield call(() => fetchPdfFileFromBlobStorage(action.payload?.url));
  const { data } = pdfBlobResponse;
  if (httpSuccess(pdfBlobResponse?.status)) {
    pdfFileDownloader(data, action.payload.formTitle);
  } else {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

export function* candidateDocumentSaga() {
  yield takeLatest(candidateDocumentActions.getCandidateDocumentsFromPassport, fetchDocumentsFromPassport);
  yield takeLatest(candidateDocumentActions.initiatePDFFileDownload, downloadPDfFile);
}
