/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Divider, Grid, Menu, MenuItem } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { materialUiXGrid } from 'amn-ui-core';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import FilterListIcon from '@mui/icons-material/FilterList';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { GridPreferencePanelsValue } from '@mui/x-data-grid';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles/styleVariables';
import { CustomTooltipProps } from '@AMIEWEB/Common/Tooltips/CustomTooltip';
import { useReadOnly } from 'oidc/userRoles';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { XGridColumnSelector, XGridColumnSelectorProps } from './XGridColumnSelector';

const buttonMargin = 6;

const useStyles = makeStyles()(theme => ({
  customIconStandalone: {
    height: '20px',
    width: '20px',
  },
  toolbarButton: {
    cursor: 'pointer',
    padding: '3px 0px',
    margin: `0px ${buttonMargin}px`,
    color: colors.darkText,
    borderRadius: '4px',
    '&:hover': {
      color: '#41A2DF',
    },
    '&[data-open="true"]': {
      color: '#306FB9',
    },
  },
  toolbarButtonCustomRenderer: {
    padding: '3px 0px',
    margin: `0px ${buttonMargin}px`,
    color: colors.darkText,
  },
  bulkActionText: {
    fontSize: '12px',
    fontWeight: 500,
    textTransform: 'uppercase',
    marginLeft: `${buttonMargin}px`,
    paddingTop: '1px',
  },
  menuItemIcon: {
    marginRight: '12px',
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.26)',
    pointerEvents: 'none',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.26)',
    },
    cursor: 'default',
  },
  divider: {
    height: '24px',
    margin: `0px ${buttonMargin}px`,
    backgroundColor: theme.palette.system.lightGrey,
    padding: 0.5,
  },
}));

const ToolbarTooltip = ({ children, ...rest }: CustomTooltipProps) => {
  return (
    <CustomTooltip isStandardContent placement="bottom" arrow={false} standardMargin {...rest}>
      {children}
    </CustomTooltip>
  );
};

export interface IXGridBulkActionButton {
  id?: string;
  KeyId: number | string;
  // MUI Icon
  Icon: any;
  title?: string;
  disabled?: boolean;
  onClick?: (event) => void;
  tooltipProps?: CustomTooltipProps;
  // Add a divider to the left of this action
  leftDivider?: boolean;
  // Add a divider to the right of this action
  rightDivider?: boolean;
  // Automatically determine if the button should be disabled with a tooltip if the user is in the read-only role
  enableReadOnly?: boolean;
  // Custom renderer for an action
  renderer?: (props) => JSX.Element;
  // Ref for the button container. Use for any poppers when the element is clicked
  ref?: React.Ref<any>;
}

const XGridBulkActionButton = (props: IXGridBulkActionButton) => {
  const {
    id,
    KeyId,
    title,
    Icon,
    onClick,
    disabled,
    tooltipProps,
    leftDivider,
    rightDivider,
    enableReadOnly,
    renderer,
    ref,
  } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const { readOnly, showTooltip } = useReadOnly();
  return (
    <div key={KeyId} id={id}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {leftDivider && <Divider orientation="vertical" flexItem className={classes.divider} />}
        <ToolbarTooltip
          {...tooltipProps}
          tooltipContent={enableReadOnly && readOnly ? t('global.readOnlyTooltip') : tooltipProps?.tooltipContent}
          disabled={enableReadOnly && readOnly && showTooltip ? false : tooltipProps ? tooltipProps.disabled : true}
        >
          <Box
            className={cx({
              [classes.toolbarButton]: !renderer,
              [classes.toolbarButtonCustomRenderer]: !!renderer,
              [classes.disabled]: disabled,
            })}
            color="primary"
            onClick={onClick}
            ref={ref}
          >
            {renderer ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>{renderer(props)}</div>
            ) : (
              <Grid container alignItems="center" alignContent="center">
                <Grid item>
                  <Box sx={{ alignItems: 'center', display: 'flex' }}>
                    {<Icon className={classes.customIconStandalone} />}
                  </Box>
                </Grid>
                {title && (
                  <Grid item className={classes.bulkActionText}>
                    {t(title)}
                  </Grid>
                )}
              </Grid>
            )}
          </Box>
        </ToolbarTooltip>
        {rightDivider && <Divider orientation="vertical" flexItem className={classes.divider} />}
      </div>
    </div>
  );
};

export interface IXGridToolbarProps {
  title?: any;
  leftTitleAction?: any;
  resetButton?: boolean;
  refreshButton?: boolean;
  columnSelector?: boolean;
  exportButton?: boolean;
  filterButton?: boolean;
  resetColums?: Function;
  setColumnsButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  panelOpen: GridPreferencePanelsValue;
  leftActions?: IXGridBulkActionButton[];
  rightActions?: IXGridBulkActionButton[];
  columnSelectorProps?: XGridColumnSelectorProps;
  moreOption?: boolean;
  [key: string]: any;
}

export const XGridToolbar = ({
  title,
  leftTitleAction,
  resetButton,
  refreshButton,
  exportButton = true,
  columnSelector = true,
  resetColumns,
  refreshGrid,
  filterButton,
  setColumnsButtonEl,
  setFilterButtonEl,
  panelOpen,
  leftActions,
  rightActions,
  columnSelectorProps,
  moreOption = true,
  ...props
}: IXGridToolbarProps) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const apiRef = materialUiXGrid.useGridApiContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [columnSelectorAnchorEl, setColumnSelectorAnchorEl] = React.useState<null | HTMLElement>(null);
  const densityOpen = Boolean(anchorEl);

  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const subMenuOpen = Boolean(subMenuAnchorEl);

  React.useEffect(() => {
    if (panelOpen === 'columns') {
      setColumnSelectorAnchorEl(document.getElementById('xgrid-toolbar-column-selector'));
    }
  }, [panelOpen]);

  return (
    <materialUiXGrid.GridToolbarContainer {...props}>
      <Box
        sx={{
          display: 'flex',
          float: 'left',
          alignItems: 'center',
          paddingLeft: isNullOrUndefined(title) ? '6px' : '12px',
          fontWeight: 500,
          fontSize: '14px',
        }}
      >
        <Grid container alignItems="center" spacing={1}>
          {leftTitleAction && (
            <Grid item sx={{ display: 'flex' }} alignItems="center">
              {leftTitleAction}
            </Grid>
          )}
          {title && <Grid item>{title}</Grid>}
          <Grid item sx={{ display: 'flex' }}>
            {leftActions?.map((action, index) => (
              <XGridBulkActionButton
                key={`left-action-key-${index}`}
                KeyId={`left-action-key-${index}`}
                {...action}
                leftDivider={action.leftDivider || (index === 0 && !isNullOrUndefined(title))}
              />
            ))}
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', float: 'right', marginLeft: 'auto', alignItems: 'center' }}>
        {rightActions?.map((action, index) => (
          <XGridBulkActionButton
            key={`right-action-key-${index}`}
            KeyId={`left-action-key-${index}`}
            {...action}
            rightDivider={
              !isNullOrUndefined(action.rightDivider) ? action.rightDivider : index === rightActions.length - 1
            }
          />
        ))}
        {/* Refresh button */}
        {refreshButton && (
          <ToolbarTooltip tooltipContent={t('global.xgrid.toolbar.refresh')}>
            <Box className={cx(classes.toolbarButton)} color="primary" onClick={() => refreshGrid?.()}>
              <RefreshIcon className={classes.customIconStandalone} />
            </Box>
          </ToolbarTooltip>
        )}
        {/* Column selector */}
        {columnSelector && (
          <>
            <ToolbarTooltip tooltipContent={columnSelectorAnchorEl === null ? t('global.xgrid.toolbar.columns') : ''}>
              <Box
                id="xgrid-toolbar-column-selector"
                className={cx(classes.toolbarButton)}
                color="primary"
                onClick={event => {
                  if (columnSelectorAnchorEl === null) {
                    setColumnSelectorAnchorEl(event.currentTarget);
                    event.stopPropagation();
                  } else setColumnSelectorAnchorEl(null);
                }}
                data-open={columnSelectorAnchorEl !== null}
              >
                <ViewColumnIcon className={classes.customIconStandalone} />
              </Box>
            </ToolbarTooltip>
            <XGridColumnSelector
              open={columnSelectorAnchorEl !== null}
              anchorEl={columnSelectorAnchorEl}
              onClose={() => {
                setColumnSelectorAnchorEl(null);
              }}
              onResetColumns={resetColumns}
              resetButton={resetButton}
              {...columnSelectorProps}
            />
          </>
        )}
        {/* More options selector */}
        {moreOption && (
          <>
            <Box
              ref={filterButton && panelOpen === GridPreferencePanelsValue.filters ? setFilterButtonEl : undefined}
              className={classes.toolbarButton}
              onClick={event => {
                setAnchorEl(event.currentTarget);
              }}
              data-open={anchorEl !== null}
            >
              <ToolbarTooltip disabled={anchorEl !== null} tooltipContent={t('global.xgrid.toolbar.more.title')}>
                <MoreVertIcon className={classes.customIconStandalone} />
              </ToolbarTooltip>
            </Box>
            <Box>
              {/* More options menu */}
              <Menu
                open={densityOpen}
                anchorEl={anchorEl}
                onClose={() => {
                  setAnchorEl(null);
                  setSubMenuAnchorEl(null);
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {/* Export button */}
                {exportButton && (
                  <div>
                    <MenuItem
                      onClick={() => {
                        apiRef.current?.exportDataAsCsv();
                        setAnchorEl(null);
                        setSubMenuAnchorEl(null);
                      }}
                    >
                      <SaveAltIcon className={classes.menuItemIcon} />
                      {t('global.xgrid.toolbar.more.export')}
                    </MenuItem>
                  </div>
                )}
                {/* Density Selector */}
                <div
                  onMouseEnter={event => {
                    setSubMenuAnchorEl(event.currentTarget);
                  }}
                  onMouseLeave={event => {
                    setSubMenuAnchorEl(null);
                  }}
                >
                  <MenuItem
                    disableRipple
                    onClick={event => {
                      event.preventDefault();
                      event.stopPropagation();
                    }}
                  >
                    <ViewHeadlineIcon className={classes.menuItemIcon} />
                    {t('global.xgrid.toolbar.more.density.title')}
                    <Menu
                      style={{ pointerEvents: 'none' }}
                      autoFocus={false}
                      open={subMenuOpen}
                      anchorEl={subMenuAnchorEl}
                      disableAutoFocus
                      disableEnforceFocus
                      onClose={() => {
                        setSubMenuAnchorEl(null);
                      }}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <div style={{ pointerEvents: 'auto' }}>
                        <MenuItem
                          selected={apiRef.current?.state.density.value === materialUiXGrid.GridDensityTypes.Compact}
                          onClick={() => {
                            apiRef.current?.setDensity(materialUiXGrid.GridDensityTypes.Compact);
                            setSubMenuAnchorEl(null);
                            setAnchorEl(null);
                          }}
                        >
                          <ViewHeadlineIcon className={classes.menuItemIcon} />
                          {t('global.xgrid.toolbar.more.density.compact')}
                        </MenuItem>
                        <MenuItem
                          selected={apiRef.current?.state.density.value === materialUiXGrid.GridDensityTypes.Standard}
                          onClick={() => {
                            apiRef.current?.setDensity(materialUiXGrid.GridDensityTypes.Standard);
                            setSubMenuAnchorEl(null);
                            setAnchorEl(null);
                          }}
                        >
                          <TableRowsIcon className={classes.menuItemIcon} />
                          {t('global.xgrid.toolbar.more.density.standard')}
                        </MenuItem>
                        <MenuItem
                          selected={
                            apiRef.current?.state.density.value === materialUiXGrid.GridDensityTypes.Comfortable
                          }
                          onClick={() => {
                            apiRef.current?.setDensity(materialUiXGrid.GridDensityTypes.Comfortable);
                            setSubMenuAnchorEl(null);
                            setAnchorEl(null);
                          }}
                        >
                          <ViewStreamIcon className={classes.menuItemIcon} />
                          {t('global.xgrid.toolbar.more.density.comfortable')}
                        </MenuItem>
                      </div>
                    </Menu>
                  </MenuItem>
                </div>
                {/* Filter button */}
                {filterButton && (
                  <div>
                    <MenuItem
                      onClick={() => {
                        apiRef.current?.showFilterPanel();
                        setAnchorEl(null);
                      }}
                    >
                      <FilterListIcon className={classes.menuItemIcon} />
                      {t('global.xgrid.toolbar.more.filters')}
                    </MenuItem>
                  </div>
                )}
              </Menu>
            </Box>
          </>
        )}
      </Box>
    </materialUiXGrid.GridToolbarContainer>
  );
};
