import {
  selectCurrentComment,
  selectInternalTaskDetailsData,
  selectNotificationContainer,
  selectNotificationPrimaryContext,
} from '@AMIEWEB/Tasks/store/Tasks.selectors';
import { taskDetailsActions, taskDetailsReducer, taskDetailsSliceKey } from 'app/components/Tasks/store/Tasks.redux';
import { taskDetailsSaga } from 'app/components/Tasks/store/Tasks.saga';
import {
  NotificationBrand,
  NotificationSender,
  StatusUpdateModalVersion,
  Task,
  TaskEntity,
  TaskNotificationType,
} from 'app/models/Tasks/Tasks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { Concatenate } from 'utils/string/string';
import { getTaskEntityValue } from '../Common/FormatData';
import moment from 'moment-timezone';

export const useSubTaskCreationNotification = () => {
  useInjectSaga({ key: taskDetailsSliceKey, saga: taskDetailsSaga });
  useInjectReducer({ key: taskDetailsSliceKey, reducer: taskDetailsReducer });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const taskDetails = useSelector(selectInternalTaskDetailsData);
  const currentComment = useSelector(selectCurrentComment);
  const notificationContainer = useSelector(selectNotificationContainer);
  const notificationContext = useSelector(selectNotificationPrimaryContext);

  const formatUserData = user => {
    const name = user?.object?.label?.split(' ');
    const formatedUser = {
      name: name[0] + ' ' + name[1],
      email:'',
      phoneNumber: '',
      contactId: user?.object?.empId?.toString(),
    };
    return formatedUser;
  };

  const getAssignedToList = () => {
    const assignedToArray: any[] = [];
    currentComment.tos?.forEach(user => {
      if (currentComment.createdBy.id !== user.object.empId) {
        const userData = formatUserData(user);
        assignedToArray.push(userData);
      }
    });

    currentComment.ccs?.forEach(user => {
      if (currentComment.createdBy.id !== user.object.empId) {
        const userData = formatUserData(user);
        assignedToArray.push(userData);
      }
    });

    return assignedToArray;
  };

  const getSubTaskCreatedNotifications = async notificationContain => {
    let entityId: string = '0';
    let container: TaskEntity;
    let primaryText: string = '';
    if (notificationContain) {
      container = notificationContain;
    } else {
      if (notificationContainer) {
        container = notificationContainer;
      }
    }
    if (container === TaskEntity.PLACEMENTS) {
      entityId = taskDetails?.candidate?.id?.toString();
      if (notificationContext && container === notificationContainer) {
        primaryText = notificationContext;
      } else {
        if (taskDetails?.placementId) {
          primaryText = Concatenate([taskDetails?.candidate?.firstName, ' ', taskDetails?.candidate?.lastName], '');
        }
      }
    } else if (container === TaskEntity.FACILITIES) {
      entityId = taskDetails?.facilityId?.toString();
      if (notificationContext && container === notificationContainer) {
        primaryText = notificationContext;
      } else {
        if (taskDetails?.facilityId) {
          primaryText = taskDetails?.facilityName;
        }
      }
    } else if (container === TaskEntity.ORDERS) {
      entityId = taskDetails?.facilityId?.toString();
      if (notificationContext && container === notificationContainer) {
        primaryText = notificationContext;
      } else {
        if (taskDetails?.orderId) {
          primaryText = taskDetails?.facilityName;
        }
      }
    } else if (container === TaskEntity.CANDIDATES) {
      entityId = taskDetails?.candidate?.id?.toString();
      if (notificationContext && container === notificationContainer) {
        primaryText = notificationContext;
      } else {
        if (taskDetails?.candidate?.id && taskDetails?.companyId) {
          primaryText = Concatenate([taskDetails?.candidate?.firstName, ' ', taskDetails?.candidate?.lastName], '');
        }
      }
    } else {
      entityId = '0';
      primaryText = '';
    }

    if (primaryText) {
      const commentNotificationPayload = {
        brand: NotificationBrand,
        useType: Task,
        useSubType: TaskNotificationType.TASKCREATED.toString(),
        sendType: 'Bulk',
        sender: NotificationSender,
        ttlValue: 2,
        ttlPeriod: 'w',
        associatedRecords: [
          {
            name: 'TaskId',
            value: currentComment?.taskId?.toString(),
          },
          {
            name: 'TaskName',
            value: taskDetails?.taskName,
          },
          {
            name: 'DueDate',
            value: currentComment?.dueDate,
          },
          {
            name: 'CurrentContainer',
            value: getTaskEntityValue(container, t),
          },
          {
            name: 'PrimaryContext',
            value: primaryText,
          },
          {
            name: 'PlacementId',
            value: taskDetails?.placementId ? taskDetails?.placementId?.toString() : '',
          },
          {
            name: 'FacilityId',
            value: taskDetails?.facilityId ? taskDetails?.facilityId?.toString() : '',
          },
          {
            name: 'UnitId',
            value: taskDetails?.facilityId ? taskDetails?.facilityId?.toString() : '',
          },
          {
            name: 'CandidateId',
            value: taskDetails?.candidate?.id ? taskDetails?.candidate?.id.toString() : '',
          },
          {
            name: 'OrderId',
            value: taskDetails?.orderId ? taskDetails?.orderId?.toString() : '',
          },
          {
            name: 'TaskCategory',
            value: taskDetails?.taskCategory ? taskDetails?.taskCategory?.toString() : '',
          },
          {
            name: 'Description',
            value: `${currentComment?.createdBy?.firstName} ${currentComment?.createdBy?.lastName} : ${currentComment?.comment}`,
          },
          {
            name: 'EntityId',
            value: entityId,
          },
          {
            name: 'SentDate',
            value: moment.tz(new Date(), 'America/Los_Angeles').format(),
          },
          {
            name: 'Version',
            value: StatusUpdateModalVersion.SUBTASK.toString(),
          },
        ],
        body: '',
        level: 1,
        templateId: '',
        tos: getAssignedToList(),
        ccs: [],
        groupName: '',
      };

      if (commentNotificationPayload?.tos?.length > 0) {
        if (taskDetails?.companyId > 0) {
          commentNotificationPayload?.associatedRecords?.push({
            name: 'BrandId',
            value: taskDetails?.companyId?.toString(),
          });
        }
        dispatch(taskDetailsActions.sendSubTaskCreatedNotification(commentNotificationPayload));
      }
      dispatch(taskDetailsActions.setCurrentComments(undefined));
    }
  };

  useEffect(() => {
    let notificationEntity = null;
    if (taskDetails?.placementId && taskDetails?.facilityId && taskDetails?.orderId && taskDetails?.candidate?.id) {
      notificationEntity = TaskEntity.PLACEMENTS;
    } else if (taskDetails?.orderId && taskDetails?.facilityId) {
      notificationEntity = TaskEntity.ORDERS;
    } else if (taskDetails?.facilityId) {
      notificationEntity = TaskEntity.FACILITIES;
    } else if (taskDetails?.candidate?.id) {
      notificationEntity = TaskEntity.CANDIDATES;
    }
    if (taskDetails && currentComment) {
      getSubTaskCreatedNotifications(notificationEntity);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentComment]);
};
