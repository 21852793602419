import React from 'react';
import { Controller } from 'react-hook-form';
import { DateRangePicker } from 'app/ComponentLibrary/DateRangeNew';
import moment from 'moment';

const extractValue = date => {
  const isValid = moment(date).isValid();

  return {
    startDate: isValid ? new Date(date) : undefined,
    endDate: isValid ? new Date(date) : undefined,
    useLabelAsValue: true,
  };
};

export const AvailableStartDate = () => {
  return (
    <Controller
      name="availabilityDate"
      render={({ ref, onChange, ...rest }) => (
        <DateRangePicker
          {...rest}
          placeholder="Available Start Date"
          quickSelect={false}
          actionBar={false}
          single
          useMaxWidth
          maxWidth={160}
          trailingIconShow
          initialDateRange={extractValue(rest.value)}
          clickAway
          onChange={newValue => {
            if (newValue.startDate !== undefined) {
              const newDate = moment(newValue.startDate);
              const setTo = newDate.isValid() ? moment(newDate).format('YYYY-MM-DDTHH:mm:ss') : null;
              onChange(setTo);
            } else {
              onChange(null);
            }
          }}
        />
      )}
    />
  );
};
