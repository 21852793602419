/* eslint-disable tss-unused-classes/unused-classes */
/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from 'tss-react/mui';
import { CustomFilter } from 'app/ComponentLibrary/Filter';
import React, { useState, useEffect, createRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { defaultFilterChipSelected, FacilityContactsActions } from '../Store/FacilityContacts.redux';
import { selectFilterSpecs } from '../Store/FacilityContacts.selector';
import { defaultFilterValues, getFilterSpecs } from './ClientContactFilterConfig';

const useStyles = makeStyles()({
  contactPreferenceField: {
    minWidth: 145,
  },
  candidateNameField: {
    minWidth: 140,
  },
  firstNameField: {
    minWidth: 135,
  },
  titleField: {
    minWidth: 150,
  },
  actionsField: {
    minWidth: 125,
  },
  unitsField: {
    minWidth: 150,
  },
  statusField: {
    minWidth: 125,
  },
});

export const ClientContactFilter = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const filterSpecs = useSelector(selectFilterSpecs);
  const [fields, setFields] = useState<any>(null);
  const dispatch = useDispatch();
  const hiddenSubmitButton = createRef<HTMLButtonElement>();

  //const dispatch = useDispatch();
  const {
    setValue,
    formState: { dirtyFields },
  } = useFormContext();

  const resetFilter = () => {
    Object.entries(defaultFilterValues).map(item => setValue(item[0], item[1]));
    dispatch(FacilityContactsActions.setFilterChipSelected(defaultFilterChipSelected));
    //Reset
    props.onApplyFilter(defaultFilterValues, defaultFilterChipSelected);
  };

  // useEffect(() => {
  //   Object.entries(defaultFilterValues).map(item => setValue(item[0], item[1]));
  //   dispatch(FacilityContactsActions.setFilterChipSelected(defaultFilterChipSelected));
  //   //Reset
  //   props.onApplyFilter(defaultFilterValues, defaultFilterChipSelected);
  // }, []);

  useEffect(() => {
    if (filterSpecs) {
      setFields(getFilterSpecs(filterSpecs, t, classes));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSpecs]);

  const onFilterChangeValues = (fieldName, newValue) => {
    if (['startDate', 'reminderDate', 'daysBeforeStart'].includes(fieldName)) {
      //CHECK: To Generated Chip after Submit, Comment below Code & Uncomment Above
      hiddenSubmitButton.current?.click();
    }
  };

  // useEffect(() => {
  //   if (flattenedData) {
  //     resetFilter();
  //   }
  // }, [flattenedData]);

  return (
    <>
      {filterSpecs && fields && (
        <CustomFilter
          fields={fields}
          onResetForm={() => resetFilter()}
          justifyContent={'flex-start'}
          version2
          falseProp={dirtyFields}
          onChangeValues={onFilterChangeValues}
          analytics={{
            caller: 'Client Contact Filter',
            onApply: true,
            properties: {
              category: 'Client Contact Grid',
            },
          }}
        />
      )}
      <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
    </>
  );
};
