import { Box, Divider, Skeleton, Typography } from 'amn-ui-core';
import React from 'react';
import { LinearProgressWithLabel } from '../../Common/LinearProgressWithLabel';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { Title } from '../../../ProfileTab/Cards/styles';
import { RowSectionTitle } from '../../Common/RowSectionTitle';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips';
import { RowSection } from '../../Common/RowSection';
import { ISkillCheckListDetails } from 'app/models/Candidate/SkillSet';
import { usePromiseTracker } from 'react-promise-tracker';
import {
  generateHeader,
  getExpirationDateLabel,
  getWarningText,
  validateAlert,
  validateChecked,
  validateIncomplete,
} from '../helper';
import { ISkillSetExpiration } from 'app/enums/SkillSet';
import { useTranslation } from 'react-i18next';
import { useStyles } from './utils';
import { theme } from 'styles/global-styles';

function SkillSetUI({
  showUpdatedSummaryIcons,
  skillChecklistProgress,
  checklistItemCount,
  skillChecklistData,
  handleNavigation,
  verifiedSkillSets,
  allSkillSets,
  combinedCheckList,
  tooltipContent,
}) {
  const { t } = useTranslation();
  const { promiseInProgress } = usePromiseTracker({ area: 'get-candidate-details', delay: 0 });
  const { classes } = useStyles();

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.children}>
          {!showUpdatedSummaryIcons?.enabled && (
            <Box style={{ width: '100%' }}>
              <LinearProgressWithLabel
                value={skillChecklistProgress}
                filledItems={checklistItemCount}
                itemCount={skillChecklistData?.length}
              />
            </Box>
          )}

          <LayoutGrid direction="column" spacing={1}>
            <Title className={classes.title}>
              {t('candidate.virtualInterviewCard.skillsChecklist')}
              <span
                className={
                  skillChecklistData?.length > 0 ? classes.profileInlineLink : classes.profileInlineLinkDisabled
                }
              >
                <Typography component="span" onClick={handleNavigation} id="skillChecklist-all-btn">
                  {t('candidate.virtualInterviewCard.all')}
                </Typography>
              </span>
            </Title>
            <span className={classes.sectionDivider}>&nbsp;</span>
            <Divider />
            <RowSectionTitle
              handleClick={() => {}}
              header={<div className={classes.boxInlineHeadLabelTxt}>{t('')}</div>}
              data={
                showUpdatedSummaryIcons?.enabled ? (
                  ''
                ) : (
                  <span className={classes.profileInlineLink}>{t('candidate.profileSummaryCard.add')}</span>
                )
              }
            />
            {promiseInProgress ? (
              <LayoutGridItem mr={2}>
                <Skeleton />
                <Skeleton />
              </LayoutGridItem>
            ) : (
              <>
                {!showUpdatedSummaryIcons?.enabled ? (
                  skillChecklistData && skillChecklistData.length > 0 ? (
                    skillChecklistData.map((item, index) => {
                      return (
                        <LayoutGridItem key={index}>
                          {item.matchingSkillsChecklist ? (
                            <CustomTooltip
                              style={{ color: theme.palette.framework.system.tertiaryGrey }}
                              tooltipContent={
                                <Typography gutterBottom component="span">
                                  {getExpirationDateLabel(item?.matchingSkillsChecklist?.dateCompleted)}
                                </Typography>
                              }
                              tooltipPlacementBottom={classes.tooltipPlacementBottom}
                              placement="bottom-start"
                            >
                              <RowSection
                                header={generateHeader(item, verifiedSkillSets, allSkillSets)}
                                data={''}
                                preData={
                                  validateChecked(item, verifiedSkillSets)
                                    ? validateChecked(item, verifiedSkillSets)
                                    : ''
                                }
                                showHalfwayProgress={validateIncomplete(item, verifiedSkillSets, allSkillSets)}
                                showAlert={validateAlert(item, verifiedSkillSets, allSkillSets)}
                                warningMessage={
                                  item.disciplineID !== 0
                                    ? getWarningText(item?.matchingSkillsChecklist?.dateCompleted)?.toString()
                                    : getWarningText(item?.matchingSkillsChecklist?.dateCompleted)?.toString()
                                }
                                color={
                                  checklistItemCount === skillChecklistData?.length
                                    ? theme.palette.system.darkGreenForIcon
                                    : theme.palette.framework.system.navyBlue
                                }
                              />
                            </CustomTooltip>
                          ) : (
                            <RowSection
                              header={generateHeader(item, verifiedSkillSets, allSkillSets)}
                              data={''}
                              preData={
                                validateChecked(item, verifiedSkillSets) ? validateChecked(item, verifiedSkillSets) : ''
                              }
                              showHalfwayProgress={validateIncomplete(item, verifiedSkillSets, allSkillSets)}
                              showAlert={validateAlert(item, verifiedSkillSets, allSkillSets)}
                              warningMessage={
                                item.disciplineID !== 0
                                  ? getWarningText(item?.matchingSkillsChecklist?.dateCompleted)?.toString()
                                  : getWarningText(item?.matchingSkillsChecklist?.dateCompleted)?.toString()
                              }
                              color={
                                checklistItemCount === skillChecklistData?.length
                                  ? theme.palette.system.darkGreenForIcon
                                  : theme.palette.framework.system.navyBlue
                              }
                            />
                          )}
                        </LayoutGridItem>
                      );
                    })
                  ) : (
                    <RowSection
                      header={t('candidate.skillsChecklistCard.skillsUnavailable')}
                      data={''}
                      preData={''}
                      color={theme.palette.framework.system.navyBlue}
                      showHalfwayProgress={true}
                      showHalfwayProgressSecondaryIcon={true}
                      showAlert={false}
                    />
                  )
                ) : combinedCheckList.length > 0 ? (
                  combinedCheckList.map((item: ISkillCheckListDetails, index: number) => {
                    return (
                      <LayoutGridItem key={index}>
                        {
                          <CustomTooltip
                            interactive={true}
                            style={{ color: theme.palette.framework.system.tertiaryGrey }}
                            tooltipContent={tooltipContent(item)}
                            placement="bottom-start"
                          >
                            <RowSection
                              header={item?.fullName}
                              data={''}
                              preData={item?.expirationType === ISkillSetExpiration.NOT_EXPIRED}
                              showHalfwayProgress={!(item?.expirationType === ISkillSetExpiration.NOT_EXPIRED)}
                              showHalfwayProgressSecondaryIcon={true}
                              showAlert={false}
                              warningMessage={getWarningText(
                                item?.dateCompleted,
                                item?.matchingSkillsChecklist === null,
                              )?.toString()}
                              color={theme.palette.system.darkGreenForIcon}
                            />
                          </CustomTooltip>
                        }
                      </LayoutGridItem>
                    );
                  })
                ) : (
                  <RowSection
                    header={t('candidate.skillsChecklistCard.skillsUnavailable')}
                    data={''}
                    preData={''}
                    color={theme.palette.framework.system.navyBlue}
                    showHalfwayProgress={true}
                    showHalfwayProgressSecondaryIcon={true}
                    showAlert={false}
                  />
                )}
              </>
            )}
          </LayoutGrid>
        </Box>
      </Box>
    </>
  );
}

export default SkillSetUI;
