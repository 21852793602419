import { Expandable } from 'app/components/Common/Expandable/Expandable';
import React, { useContext } from 'react';
import { IAssignmentInfo } from 'app/models/Placement/PlacementSummary';
import { useTranslation } from 'react-i18next';
import { Grid } from 'amn-ui-core';
import { DetailsRow, DetailsTable } from './DetailsTable';
import { useFormContext } from 'react-hook-form';
import { TimeOffItem } from './TimeOffItem';
import { PlacementStatusChip } from './PlacementStatusChip';
import { DateItem } from './DateItem';
import { DetailsTitle } from '../../../Titles/DetailsTitle';
import { TableCell as DetailsCell } from 'app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/CommonTableComponents/CommonTableComponents';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { usePromiseTracker } from 'react-promise-tracker';
import { selectServiceFailures } from 'store/redux-store/placement-summary/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorAction } from 'app/components/Common/ErrorPage/ErrorAction';
import { placementSummaryActions } from 'store/redux-store/placement-summary/slice';
import { DirectHire } from './DirectHire';

interface IDetailsProps {
  title: string;
  expanded?: boolean;
  unmountOnExit: boolean;
  id?: string;
  assignmentInformation: IAssignmentInfo | null;
}

export const Details = ({ title, expanded, id, assignmentInformation, ...props }: IDetailsProps) => {
  const { watch } = useFormContext();
  const timeOff = watch('requestedTimeOff');
  const { t } = useTranslation();
  const { placementId } = useContext(AddOnContext);
  const { promiseInProgress } = usePromiseTracker({ area: 'placement-assignment-info-requirement', delay: 0 });
  const { assignmentInformationRequirement: serviceFailed } = useSelector(selectServiceFailures);
  const dispatch = useDispatch();

  return (
    <Grid item xs={12}>
      <Expandable
        id={id}
        unmountOnExit={false}
        preventForceClose
        expanded={expanded}
        titleComponent={<DetailsTitle title={title} />}
      >
        <DetailsTable>
          {serviceFailed && !promiseInProgress ? (
            <tr>
              <DetailsCell colSpan="2">
                <ErrorAction
                  onTryAgain={() =>
                    dispatch(
                      placementSummaryActions.getAssignmentInformationRequirement({
                        placementId,
                        isRetryingService: true,
                      }),
                    )
                  }
                />
              </DetailsCell>
            </tr>
          ) : (
            <>
              <DetailsRow
                title={t('placement.profile.summary.assignment.details.rows.status')}
                detailCell={<PlacementStatusChip {...assignmentInformation} />}
              />
              <DetailsRow
                title={t('placement.profile.summary.assignment.details.rows.date')}
                detailCell={<DateItem {...assignmentInformation} />}
              />
              <DetailsRow
                title={t('placement.profile.summary.assignment.details.rows.timeOff')}
                detailCell={<TimeOffItem timeOff={timeOff} />}
              />
            </>
          )}
        </DetailsTable>
      </Expandable>
    </Grid>
  );
};
``