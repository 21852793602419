import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { serviceActions } from './async-actions';
import { IMergeToken, ITemplate } from '../confirmation-template-editor/types';
import { initialState } from './utils';
import { IClientConfirmationContacts, IConfirmationDoc } from './types';
import _ from 'lodash';
import { businessActions } from './business.actions';
import { SidePanel } from '@AMIEWEB/Common/TemplateEditor';

const slice = createSlice({
  name: 'placementClientConfirmation',
  initialState,
  reducers: {
    setTemplates(state, action: PayloadAction<ITemplate[]>) {
      return { ...state, templates: [...action.payload] };
    },
    setConfirmationDocuments(state, action: PayloadAction<IConfirmationDoc[]>) {
      return {
        ...state,
        documents: [...action.payload],
        hasDraftTemplate: action.payload.some(x => x.isDraft || x.status === 'Draft'),
      };
    },
    setSidePanel(state, action: PayloadAction<SidePanel>) {
      return { ...state, pageAttributes: { ...state.pageAttributes, sidePanel: action.payload } };
    },
    setSelectedTemplate(state, action: PayloadAction<ITemplate>) {
      return { ...state, selectedTemplate: action.payload };
    },
    setFacilityDefaultTemplate(state, action: PayloadAction<{ templateId: number }>) {
      return { ...state, facilityDefaultTemplate: action.payload.templateId };
    },
    setMergeTokenList(state, action: PayloadAction<{ [key: string]: { label: string; tokens: IMergeToken[] } }>) {
      return { ...state, mergeTokenList: action.payload };
    },
    toggleForceModalCloseFlag(state) {
      state.miscellaneous.forceModalCloseFlag = !state.miscellaneous.forceModalCloseFlag;
    },
    setContactsFeature(state, action: PayloadAction<IClientConfirmationContacts[]>) {
      return { ...state, contacts: [...action.payload], editableContacts: [...action.payload], isContactsDirty: false };
    },
    setEditableContacts(state, action: PayloadAction<IClientConfirmationContacts[]>) {
      const isContactsDirty = !_.cloneDeep(state.contacts).every(c =>
        action.payload.some(ec => c.contactId === ec.contactId && c.isSelected === ec.isSelected),
      );
      return {
        ...state,
        editableContacts: [...action.payload],
        isContactsDirty,
      };
    },
    markCurrentDraftForHardDelete(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        pageAttributes: { ...state.pageAttributes, userHasOptedToDeleteExistingDraft: action.payload },
      };
    },
    forceEditorDirtyState(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        pageAttributes: { ...state.pageAttributes, forceEditorDirtyState: action.payload },
      };
    },
    resetContacts(state) {
      const editableContacts = _.cloneDeep(state.contacts);
      return { ...state, editableContacts, isContactsDirty: false };
    },
    setPreviewScreen(state, action: PayloadAction<{ data: string; version: number }>) {
      return {
        ...state,
        document: action.payload,
        renderPreviewScreen: true,
        renderPdfView: false,
        pageAttributes: { ...state.pageAttributes, sidePanel: null },
      };
    },
    closePreview(state) {
      return {
        ...state,
        document: undefined,
        renderPreviewScreen: false,
        pageAttributes: { ...state.pageAttributes, sidePanel: 'templates' },
      };
    },
    setPdfView(state, action: PayloadAction<{ data: string; version: number }>) {
      return { ...state, document: action.payload, renderPdfView: true, renderPreviewScreen: false };
    },
    closePdfView(state) {
      return { ...state, document: undefined, renderPdfView: false };
    },
    resetDocView(state) {
      return { ...state, document: undefined, renderPreviewScreen: false, renderPdfView: false };
    },
    reset() {
      return initialState;
    },
  },
});

export const { name: pConfirmationDocEditorSliceKey, reducer: pConfirmationDocEditorReducer } = slice;

export const pConfirmationDocEditorActions = { ...slice.actions, ...businessActions, ...serviceActions };
