import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { GlanceTile, GlanceStatus } from 'app/components/Common/GlanceTile/NewGlanceTile';
import React from 'react';
import { GlanceDataStatus, QuickGlance } from 'app/models/Orders/OrderDetails';
import { useTranslation } from 'react-i18next';
import { useDecision } from '@optimizely/react-sdk';
import { ff_order_ui_job_match } from 'app/constants/FeatureFlags/Order/Keys';
interface QuickGlanceBar {
  quickGlanceDetails: QuickGlance;
  onClick(value: number): void;
}

const useStyles = makeStyles()({
  tile: {
    flex: 1,
  },
});

export const OrderQuickGlance: React.FC<QuickGlanceBar> = props => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [showJobMatches] = useDecision(ff_order_ui_job_match);
  const handleJobMatches = () => {
    if (parseInt(props.quickGlanceDetails.jobMatches) > 0) props.onClick(1);
  };
  const glanceTileArray = [
    {
      title: t('order.orderDetails.kpi.positionsAvailable'),
      status: {
        data: props.quickGlanceDetails.positionsAvailable,
        colorCode: GlanceStatus.neutral,
      },
    },
    {
      title: t('order.orderDetails.kpi.startDate'),
      status: {
        data: props.quickGlanceDetails.startDate,
        colorCode: GlanceStatus.neutral,
      },
    },
    {
      title: t('order.orderDetails.kpi.placementLength'),
      status: {
        data: props.quickGlanceDetails.placementLength,
        colorCode: GlanceStatus.neutral,
      },
    },
    {
      title: t('order.orderDetails.kpi.mfs'),
      status: {
        data: props.quickGlanceDetails.maxFilesSent,
        colorCode:
          props.quickGlanceDetails.maxFilesSent === GlanceDataStatus.yes ? GlanceStatus.red : GlanceStatus.green,
      },
    },
    {
      title: t('order.orderDetails.kpi.gww'),
      status: {
        data: props.quickGlanceDetails.guaranteedWorkWeek,
        colorCode:
          props.quickGlanceDetails.guaranteedWorkWeek === GlanceDataStatus.yes
            ? GlanceStatus.green
            : props.quickGlanceDetails.guaranteedWorkWeek === GlanceDataStatus.no
            ? GlanceStatus.red
            : GlanceStatus.neutral,
      },
    },
    {
      title: t('order.orderDetails.kpi.activeBonus'),
      status: {
        data: props.quickGlanceDetails.activeBonus,
        colorCode:
          props.quickGlanceDetails.activeBonus === GlanceDataStatus.yes
            ? GlanceStatus.green
            : props.quickGlanceDetails.activeBonus === GlanceDataStatus.no
            ? GlanceStatus.red
            : GlanceStatus.neutral,
      },
    },
    {
      title: t('order.orderDetails.kpi.weeklyGrossPay'),
      status: {
        data: props.quickGlanceDetails.weeklyGrossPay,
        colorCode: GlanceStatus.neutral,
      },
    },
    ...(showJobMatches?.enabled
      ? [
          {
            title: t('order.orderDetails.kpi.jobMatches'),
            status: {
              data: props.quickGlanceDetails.jobMatches,
              colorCode: GlanceStatus.neutral,
            },
            clickAble: parseInt(props.quickGlanceDetails.jobMatches) > 0,
            OnClick: handleJobMatches,
          },
        ]
      : []),
    {
      title: t('order.orderDetails.kpi.billRate'),
      status: {
        data: props.quickGlanceDetails.billRate,
        colorCode: GlanceStatus.neutral,
      },
    },
  ];

  //TODO: @KEVIN - Refactor using LayoutGrid from app layout for responsiveness: Due Date: 15th Oct 2022
  return (
    <Grid
      justifyContent="flex-start"
      direction="row"
      alignItems="center"
      container
      spacing={2}
      style={{ flexWrap: 'wrap', paddingRight: '1px' }}
    >
      {glanceTileArray.map(tile => (
        <Grid item className={classes.tile}>
          <GlanceTile {...tile} />
        </Grid>
      ))}
    </Grid>
  );
};
