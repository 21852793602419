import { makeStyles } from 'tss-react/mui';

export const useClinicianToPatientStyles = makeStyles()(theme => ({
  fieldStyle: {
    '& .MuiFilledInput-root': {
      backgroundColor: `${theme.palette.system.grayChip} !important`,
    },
    '&:hover .MuiFilledInput-root': {
      backgroundColor: `${theme.palette.system.silver} !important`,
    },
    '&:hover .MuiInputLabel-root': {
      color: `${theme.palette.system.primary} !important`,
    },
    '& .MuiInputAdornment-positionEnd': {
      color: 'red',
    },
  },
  checkbox: {
    '& label': {
      '& .MuiButtonBase-root.MuiCheckbox-root.Mui-checked': {
        color: `${theme.palette.system.main} !important`,
      },
      '&:hover .MuiButtonBase-root.MuiCheckbox-root': {
        color: `${theme.palette.system.main} !important`,
      },
    },
  },
}));
