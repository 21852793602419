/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, Grid, TextField, Checkbox } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { TaskFormBanner } from 'app/components/Common/Banner/TasksFormBanner';
import { Cancel } from 'app/components/Common/CancelModal/Cancel';
import { selectPlacementDetails } from 'store/redux-store/placement-details/selectors';
import { ITypeAheadOption } from 'app/models/Orders/OrderDetails';
import { IPlacementDetails } from 'app/models/Placement/PlacementDetails';
import {
  ITaskCategorizedList,
  IExternalCC,
  ISubCategoryDependentData,
  IExternalTaskSubType,
} from 'app/models/Tasks/Tasks';
import { addDays, addYears, isBefore, isValid, isWithinInterval, parse } from 'date-fns';
import _cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';
import { selectUser } from 'oidc/user.selectors';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { DateRangePicker } from '../../../../app/ComponentLibrary/DateRangeNew';
import { taskDetailsActions, taskDetailsReducer, taskDetailsSliceKey } from '../store/Tasks.redux';
import {
  selectedIsExternalTaskUpdated,
  selectedUpdatedId,
  selectExternalTaskCategoryList,
  selectExternalTaskChangedFlag,
  selectTaskId,
  // selectTaskId,
} from '../store/Tasks.selectors';
import momentTz from 'moment-timezone';
import { ExternalAssignedToField } from '../CustomComponents/ExternalAssignedToField';
import { CCEmployees } from '../CreateTask/CCEmployees';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import {
  checkIsCCChanged,
  convertAssignedUserData,
  convertUserData,
  fetchplacementEmpData,
  formatTaskCategoryList,
} from '../CreateTask/FormatData';
import { taskDetailsSaga } from '../store/Tasks.saga';
import { EditTaskFormBanner } from 'app/components/Common/Banner/EditTaskFormBanner';
import { useEditTaskNotification } from 'app/components/Notification/Tasks/EditTaskNotificaton';
import { candidateDetailsSelection } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { ExternalTaskCategoryInputs } from './ExternalTaskCatergoryInputs';
import { FormatDate } from '@AMIEWEB/Notification/Tasks/Common/FormatData';
import { enGB } from 'date-fns/locale';

const createTaskModalStyles = makeStyles()(theme => ({
  // Modal styles
  modalContainer: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '450px',
      overflow: 'visible',
    },
  },
  // Modal Body Styles
  inputs: {
    width: '100%',
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    '&:hover': {
      '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
        color: '#006FB9',
      },
    },
  },
  dueDateContainer: {
    '& .MuiInputLabel-filled.MuiInputLabel-marginDense': {
      transform: 'translate(12px, 22px) scale(1)',
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '14px',
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: 'translate(12px, 10px) scale(0.75)',
    },
  },
  dueDateInput: {
    '& .MuiFilledInput-input': {
      height: '1.3736em',
      padding: '27px 12px 10px',
      fontfamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif',
      fontSize: '12px',
    },
  },
  ccBtn: {
    padding: '0',
    width: '36px',
    height: '36px',
    minWidth: '0',
    background: '#F7F7F7 0% 0% no-repeat padding-box',
    border: `1px solid ${theme.palette.system.fadedGrey}`,
    borderRadius: '5px',
    opacity: '1',
    boxShadow: 'none',
    font: 'normal normal normal 14px/20px Roboto',
    letterSpacing: '0',
    color: theme.palette.framework.system.charcoal,
  },
  banner: {
    paddingBottom: '1px !important',
  },
  validationLoader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: '50%',
  },
  dueDateField: {
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Roboto',
    color: theme.palette.framework.system.charcoal,
    letterSpacing: '0px',
    opacity: '1',
    marginTop: '4px',
  },
  fieldLabel: {
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Roboto',
    color: theme.palette.framework.system.charcoal,
    letterSpacing: '0px',
    opacity: '1',
  },
  flexContainer: {
    width: '16rem',
    display: 'flex',
    alignItems: 'flex-start',
  },
  checkBox: {
    '& .MuiCheckbox-root': {
      paddingLeft: '0px',
      paddingBottom: '0px',
    },
  },
  CheckBoxLabel: {
    marginTop: '12px',
    textAlign: 'left',
    font: 'normal normal normal 14px/20px Roboto',
    letterSpacing: '0px',
    color: theme.palette.framework.system.charcoal,
    opacity: 1,
  },
}));

export const EditExternalTaskModal = ({
  open,
  handleClose,
  editTaskData,
  container,
  taskDetail,
}: {
  open: boolean;
  handleClose: () => void;
  editTaskData;
  container?: number;
  taskDetail: boolean;
}) => {
  const { t } = useTranslation();
  const { classes } = createTaskModalStyles();
  const dispatch = useDispatch();
  const foundPlacement: IPlacementDetails = useSelector(selectPlacementDetails);
  const createdId = useSelector(selectTaskId);
  const userInfo = useSelector(selectUser);
  const externalTaskList = useSelector(selectExternalTaskCategoryList);
  const { promiseInProgress: isCallingAPI } = usePromiseTracker({ area: 'edit-external-task-submit', delay: 0 });
  const { promiseInProgress: isCallingAPIforSubCategories } = usePromiseTracker({
    area: 'get-external-task-sub-category',
    delay: 0,
  });
  const updateTaskData = useSelector(selectExternalTaskChangedFlag);
  const updatedId = useSelector(selectedUpdatedId);
  const formMethods = useForm({
    mode: 'onSubmit',
  });
  const {
    handleSubmit,
    control,
    trigger,
    setError,
    clearErrors,
    setValue,
    formState: { isDirty, errors },
  } = formMethods;

  const candidateDetails = useSelector(candidateDetailsSelection);
  const [placementEmployees, setPlacementEmployees] = useState<ITypeAheadOption[]>([]);
  const [assignedToValue, setAssignedValue] = useState<ITypeAheadOption | undefined>();
  const [ccEmpValues, setCCEmpvalues] = useState<ITypeAheadOption[]>([]);
  const [showField, setShowField] = useState<any>(false);
  const [showccField, setShowccField] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [externalTaskOptionsList, setExternalTaskOptionsList] = useState(formatTaskCategoryList(externalTaskList));
  const [showBanner, setShowBanner] = useState(false);
  const [serverErrorBanner, setServerErrorBanner] = useState(false);
  const [taskCategoryValue, setTaskCategoryValue] = useState<ITaskCategorizedList>();
  const date = new Date();
  const [descState, setDescState] = useState('');
  const [taskState, setTaskState] = useState('');
  const [subCategory1, setSubCategory1] = useState<ISubCategoryDependentData>();
  const [subCategory2, setSubCategory2] = useState<ISubCategoryDependentData>();
  const [subCategory1Value, setSubCategory1Value] = useState<IExternalTaskSubType>();
  const [subCategory2Value, setSubCategory2Value] = useState<IExternalTaskSubType>();
  const [showSubCategoriesField, setSubCategoriesField] = useState<boolean>(false);
  const [subCategory1Label, setSubCategory1Label] = useState<string>();
  const [subCategory2Label, setSubCategory2Label] = useState<string>();
  const [isDataChanged, setIsDataChanged] = useState<boolean>(false);
  const [dueDateState, setDuedateState] = useState<any>();
  const [showEditBanner, setShowEditBanner] = useState<boolean>(false);
  const isUpdated = useSelector(selectedIsExternalTaskUpdated);
  const [initialState, setInitialState] = useState<any>({});
  const startDate = isBefore(initialState?.dueDate, date) ? addDays(initialState?.dueDate, -1) : addDays(date, -1);
  useInjectSaga({ key: taskDetailsSliceKey, saga: taskDetailsSaga });
  useInjectReducer({ key: taskDetailsSliceKey, reducer: taskDetailsReducer });

  const getChildComponentsValue = () => {
    const ccValues = ccEmpValues?.map((a: ITypeAheadOption) => a?.object).map(d => d?.empId);
    const assignedTo = assignedToValue?.object?.empId;
    setValue('assignedTo', assignedTo);
    setValue('cc', ccValues);
    if (assignedTo) {
      clearErrors('assignedTo');
    }
  };

  const setDefaultValues = (loadDefault = false) => {
    const defaultTaskData = _cloneDeep(loadDefault ? updateTaskData : editTaskData);
    const intCC = taskDetail ? editTaskData?.cc : editTaskData?.ccValues;
    const convertedAssignedTo =
      editTaskData?.assignedTo?.length > 0
        ? convertAssignedUserData(editTaskData?.assignedTo[0])
        : convertAssignedUserData(editTaskData?.assignedTo);
    const convertedCC = convertUserData(intCC);
    const assignedTo = convertedAssignedTo?.object?.empId;
    const cc = convertedCC?.map((a: ITypeAheadOption) => a?.object).map(d => d?.empId);
    if (cc?.length > 0) {
      setShowccField(true);
    } else {
      setShowccField(false);
    }
    const defaultCategoryValue = externalTaskOptionsList?.find(l => l?.taskName === defaultTaskData?.taskName);
    setTaskCategoryValue(defaultCategoryValue);
    setDescState(defaultTaskData?.description);
    setTaskState(defaultTaskData?.task);
    taskDetail ? setSubCategory1(defaultTaskData?.subCategory1) : setSubCategory1(defaultTaskData?.categorySubType1);
    taskDetail ? setSubCategory2(defaultTaskData?.subCategory2) : setSubCategory2(defaultTaskData?.categorySubType2);
    clearErrors('task');
    setAssignedValue(convertedAssignedTo);
    setCCEmpvalues(convertedCC);
    const dueDate = new Date(defaultTaskData?.dueDate);
    dueDate.setHours(23, 59, 59, 999);
    const dt = FormatDate(dueDate, 'MM/DD/YYYY');
    setDuedateState(dt);
    getChildComponentsValue();
    setTimeout(() => {
      setValue('task', defaultTaskData?.task);
      setValue('dueDate', dt);
      setValue('taskCategory', defaultCategoryValue?.categoryId);
      setValue('description', defaultTaskData?.description);
      setValue('assignedTo', assignedTo);
      setValue('cc', cc);
      setValue('companyId', defaultTaskData?.task);
    }, 0);

    const intialState = {
      assignedTo: assignedTo,
      cc: cc,
      task: defaultTaskData?.task,
      taskName: defaultTaskData?.taskName,
      taskCategory: defaultTaskData?.taskCategory,
      description: defaultTaskData?.description,
      dueDate: dueDate,
      categorySubType1: taskDetail ? defaultTaskData?.subCategory1 : defaultTaskData?.categorySubType1,
      categorySubType2: taskDetail ? defaultTaskData?.subCategory2 : defaultTaskData?.categorySubType2,
    };
    setInitialState(intialState);
  };

  const onCloseModal = () => {
    if (!isDataChanged) {
      setCancelModalOpen(true);
    } else {
      onCloseCall();
    }
  };

  const onCloseCall = () => {
    setCancelModalOpen(false);
    setShowField(false);
    setShowccField(false);
    setShowBanner(false);
    setServerErrorBanner(false);
    setShowEditBanner(false);
    handleClose();
    setCCEmpvalues([]);
    setAssignedValue(undefined);
    dispatch(taskDetailsActions.setExternalTaskDataId(undefined));
    dispatch(taskDetailsActions.setTaskDataId(undefined));
    dispatch(taskDetailsActions.setIsExternalTaskUpdated(false));
  };

  const categorySelection = value => {
    if (value?.taskId === 1 || value?.taskId === 5) {
      dispatch(taskDetailsActions.getExternalSubCategoryList(value?.taskId));
      if (value?.taskId === 1) {
        setSubCategory1Label(t('notification.createTask.certification'));
        setSubCategory2Label(t('notification.createTask.issuingBody'));
      }
      if (value?.taskId === 5) {
        setSubCategory1Label(t('notification.createTask.license'));
        setSubCategory2Label(t('notification.createTask.state'));
      }
      setLoading(true);
      setTimeout(() => {
        setSubCategoriesField(true);
        setLoading(false);
      }, 750);
    } else {
      setSubCategoriesField(false);
    }
  };

  useEditTaskNotification();
  const getCCValues = () => {
    const ccValue: IExternalCC[] = [];
    ccEmpValues?.map(emp => {
      const name: any[] = emp?.object?.label?.split(' ');
      const cc: IExternalCC = {
        ccFirstName: name[0],
        ccLastName: name[1],
        ccId: emp?.object?.empId,
      };
      ccValue.push(cc);
    });
    return ccValue;
  };

  const submitTask = formData => {
    const customDateFormat = parse(formData?.dueDate, 'MM/dd/yyyy', new Date(), { locale: enGB });
    customDateFormat.setHours(23, 59, 59);
    const updateTask = {
      externalTaskId: editTaskData?.placementId + '-' + editTaskData?.candidateId + '-' + editTaskData?.taskNameId,
      id: editTaskData?.taskId,
      description: formData?.description,
      dueDate: FormatDate(customDateFormat),
      lastUpdatedDate: momentTz.tz(new Date(), 'America/Los_Angeles').format(),
      lastUpdatedByName: userInfo?.userInfo?.firstName + ' ' + userInfo?.userInfo?.lastName,
      lastUpdatedById: userInfo?.userInfo?.employeeId,
      ccs: getCCValues(),
      categorySubType1: formData?.categorySubType1,
      categorySubType2: formData?.categorySubType2,
    };
    dispatch(taskDetailsActions.sendUpdateExternalTask({ updateTask, initialTask: initialState }));
  };

  const onRefreshClick = () => {
    // setLoadDefault(true);
    setDefaultValues(true);
    setShowEditBanner(false);
    dispatch(taskDetailsActions.setExternalTaskChangedFlag(undefined));
  };

  const checkDateValidation = value => {
    if (value.length === 0) {
      return t('required');
    } else if (value.includes('_') || value.includes(' ')) {
      return t('invalidFormat');
    } else {
      const customDateFormat = parse(value, 'MM/dd/yyyy', new Date(), { locale: enGB });
      const isDateValid = isValid(customDateFormat);
      if (isDateValid && isWithinInterval(customDateFormat, { start: startDate, end: addYears(date, 10) })) {
        return null;
      } else {
        return t('invalidDate');
      }
    }
  };

  useEffect(() => {
    if (isUpdated && updatedId) {
      setServerErrorBanner(false);
      onCloseCall();
      dispatch(
        globalActions.setSnackBar({
          message: t('notification.createTask.taskEditSuccess'),
          severity: 'success',
        }),
      );
    } else if (updatedId && Number(updatedId) < 0 && !isUpdated) {
      setServerErrorBanner(true);
      dispatch(
        globalActions.setSnackBar({
          message: t('notification.createTask.taskEditFailure'),
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated, updatedId]);

  useEffect(() => {
    if (!updateTaskData) {
      setShowEditBanner(false);
    } else {
      setShowEditBanner(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTaskData]);

  useEffect(() => {
    if (showField === false) {
      if (
        taskState !== initialState?.taskName ||
        dueDateState !== FormatDate(initialState?.dueDate, 'MM/DD/YYYY') ||
        descState !== (initialState?.description === null ? '' || null : initialState?.description) ||
        assignedToValue?.object?.empId !== initialState.assignedTo ||
        checkIsCCChanged(initialState, ccEmpValues) ||
        subCategory1Value?.id !== initialState.categorySubType1?.subTypeDataId ||
        subCategory2Value?.id !== initialState.categorySubType2?.subTypeDataId
      ) {
        setIsDataChanged(false);
      } else {
        setIsDataChanged(true);
      }
    } else {
      if (
        taskState !== initialState?.task ||
        dueDateState !== FormatDate(initialState?.dueDate, 'MM/DD/YYYY') ||
        descState !== (initialState?.description === null ? '' : initialState?.description) ||
        assignedToValue?.object?.empId !== initialState.assignedTo ||
        checkIsCCChanged(initialState, ccEmpValues)
      ) {
        setIsDataChanged(false);
      } else {
        setIsDataChanged(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updateTaskData,
    dueDateState,
    taskCategoryValue,
    showField,
    taskState,
    descState,
    assignedToValue,
    ccEmpValues,
    subCategory1Value,
    subCategory2Value,
  ]);

  useEffect(() => {
    if (foundPlacement?.header) {
      fetchplacementEmpData(foundPlacement, setPlacementEmployees);
      dispatch(taskDetailsActions.setPlacementBrandId(foundPlacement?.header?.brandId));
    }
    if (candidateDetails.brandId) {
      dispatch(taskDetailsActions.setPlacementBrandId(candidateDetails?.brandId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundPlacement?.header?.placementId, candidateDetails]);

  useEffect(() => {
    categorySelection(taskCategoryValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskCategoryValue]);

  useEffect(() => {
    setDefaultValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    getChildComponentsValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ccEmpValues, assignedToValue]);

  useEffect(() => {
    setExternalTaskOptionsList(formatTaskCategoryList(externalTaskList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalTaskList]);

  useEffect(() => {
    if (createdId && Number(createdId) < 0 && !isCallingAPI) {
      setServerErrorBanner(true);
      dispatch(
        globalActions.setSnackBar({
          message: t('notification.createTask.taskCreationFailure'),
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCallingAPI]);

  return (
    <FormProvider {...formMethods}>
      <GenericDialog
        open={open}
        fullWidth
        className={classes.modalContainer}
        onClose={onCloseModal}
        variant="blue"
        dialogTitleProps={{
          text: t('notification.createTask.editExternalTaskModalName'),
          closeButton: true,
        }}
        dialogActions={[
          {
            text: t('notification.createTask.cancelBtn'),
            variant: 'contained',
            color: 'tertiary',
            onClick: onCloseModal,
          },
          {
            text: t('notification.createTask.saveBtn'),
            variant: 'contained',
            disabled: isCallingAPI || isDataChanged,
            onClick: async e => {
              setShowBanner(false);
              if (showField) {
                await trigger('task');
              }
              const validTask = await trigger();
              if (!assignedToValue) {
                setError('assignedTo', {
                  type: 'required',
                });
                setShowBanner(true);
              } else {
                if (validTask) {
                  handleSubmit(submitTask)().catch(err => {
                    setServerErrorBanner(true);
                  });
                } else {
                  setShowBanner(true);
                }
              }
            },
          },
        ]}
      >
        <Grid container spacing={4} data-testid="create-task-modal-container">
          {showBanner && (
            <Grid item xs={12} className={classes.banner}>
              <TaskFormBanner message={t('notification.createTask.validationBannerMessage')} />
            </Grid>
          )}
          {serverErrorBanner && (
            <Grid item xs={12} className={classes.banner}>
              <TaskFormBanner message={t('notification.createTask.APICallEror')} />
            </Grid>
          )}
          {showEditBanner && (
            <Grid item xs={12} className={classes.banner}>
              <EditTaskFormBanner
                message={t('notification.createTask.editTaskValidation')}
                onClick={e => {
                  onRefreshClick();
                }}
              />
            </Grid>
          )}
          {isCallingAPI || isCallingAPIforSubCategories || loading ? (
            <div style={{ height: '5rem' }}>
              <CircularProgress className={classes.validationLoader} />
            </div>
          ) : (
            <>
              <Grid item xs={12}>
                <div className={classes.flexContainer}>
                  <div className={classes.checkBox}>
                    <Checkbox color="primary" defaultChecked={true} disabled={true} />
                  </div>
                  <div className={classes.CheckBoxLabel}>{t('notification.createTask.checkBoxLabel')}</div>
                </div>
              </Grid>
              <ExternalTaskCategoryInputs
                classes={classes}
                isCallingAPI={isCallingAPI}
                formMethods={formMethods}
                externalTaskOptionsList={externalTaskOptionsList}
                taskCategoryValue={taskCategoryValue}
                subCategory1={subCategory1}
                subCategory2={subCategory2}
                setSubCategory1Value={setSubCategory1Value}
                setSubCategory2Value={setSubCategory2Value}
                isEdit={true}
                showSubCategoriesField={showSubCategoriesField}
                subCategory1Label={subCategory1Label}
                subCategory2Label={subCategory2Label}
              />
              <Grid item xs={12} data-testid="task-details-input">
                <Controller
                  control={control}
                  name={'description'}
                  rules={{
                    maxLength: { value: 250, message: t('notification.createTask.maxLimitLabel') },
                  }}
                  render={({ ref, onChange, ...rest }) => (
                    <TextField
                      multiline
                      variant="filled"
                      className={classes.inputs}
                      color="primary"
                      value={descState}
                      InputLabelProps={{
                        className: classes.fieldLabel,
                      }}
                      error={errors?.description ? true : false || (isDirty && errors?.description) ? true : false}
                      disabled={isCallingAPI}
                      id="description"
                      label={t('notification.createTask.detailsLabel')}
                      onChange={e => {
                        if (e.target.value?.length > 250) {
                          setError('description', {
                            type: 'maxLength',
                            message: t('notification.createTask.maxLimitLabel'),
                          });
                        } else if (e.target.value?.length <= 250) {
                          clearErrors('description');
                        }
                        onChange(e);
                        setDescState(e.target?.value);
                      }}
                      helperText={
                        (errors?.description && errors?.description?.message) ||
                        (isDirty && errors?.description && errors?.description?.message)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={7} data-testid="task-assigned-to-input">
                <ExternalAssignedToField value={assignedToValue} />
              </Grid>
              <Grid item xs={5} data-testid="task-due-date-input" className={classes.dueDateContainer}>
                <Controller
                  control={control}
                  name={'dueDate'}
                  defaultValue={dueDateState}
                  rules={{ validate: checkDateValidation }}
                  render={({ ref, onChange, value, ...rest }) => (
                    <DateRangePicker
                      variant="filled"
                      placeholder={t('notification.createTask.dueDateLabel')}
                      className={classes.dueDateInput}
                      initialDateRange={dueDateState}
                      useMaxWidth={true}
                      single={true}
                      minDate={startDate}
                      maxWidth={150}
                      isDisabled={isCallingAPI}
                      labelClass={dueDateState ? classes.fieldLabel : classes.dueDateField}
                      trailingIconShow={true}
                      disablePortal={true}
                      actionBar={false}
                      clickAway={true}
                      keepClickAwayVal={true}
                      value={dueDateState}
                      popperPlacement={'top-start'}
                      fallbackPlacements={['top-start']}
                      onChange={e => {
                        const dueDate = e?.endDate
                          ? moment.tz(e?.endDate, 'America/Los_Angeles').format('MM/DD/YYYY')
                          : '';
                        onChange(dueDate);
                        setDuedateState(dueDate);
                      }}
                      onInlineEdit={() => {
                        clearErrors('dueDate');
                      }}
                      onBlurText={dateValue => {
                        onChange(dateValue);
                        setDuedateState(dateValue);
                      }}
                      error={isDirty && errors?.dueDate ? true : false}
                      helperText={isDirty && errors?.dueDate ? errors?.dueDate?.message : ''}
                      definedRanges={[
                        {
                          label: t('notification.createTask.dueDateLabels.today'),
                          startDate: date,
                          endDate: date,
                          useLabelAsValue: false,
                        },
                        {
                          label: t('notification.createTask.dueDateLabels.tomorrow'),
                          startDate: addDays(date, 1),
                          endDate: addDays(date, 1),
                          useLabelAsValue: false,
                        },
                        {
                          label: t('notification.createTask.dueDateLabels.2Days'),
                          startDate: addDays(date, 2),
                          endDate: addDays(date, 2),
                          useLabelAsValue: false,
                        },
                        {
                          label: t('notification.createTask.dueDateLabels.7Days'),
                          startDate: addDays(date, 7),
                          endDate: addDays(date, 7),
                          useLabelAsValue: false,
                        },
                        {
                          label: t('notification.createTask.dueDateLabels.30Days'),
                          startDate: addDays(date, 30),
                          endDate: addDays(date, 30),
                          useLabelAsValue: false,
                        },
                      ]}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                {!showccField && (
                  <Button
                    data-testid="task-cc-btn"
                    className={classes.ccBtn}
                    disabled={isCallingAPI}
                    onClick={() => {
                      setShowccField(true);
                    }}
                    variant="contained"
                  >
                    {t('notification.createTask.ccBtn')}
                  </Button>
                )}
                {showccField && (
                  <Controller
                    control={control}
                    name={'cc'}
                    render={({ ref, onChange, value, ...rest }) => (
                      <CCEmployees
                        placementEmployees={placementEmployees}
                        ccEmpValues={ccEmpValues}
                        setCCEmpvalues={setCCEmpvalues}
                        assignedToValue={assignedToValue?.object?.empId}
                        isDisabled={isCallingAPI}
                        formMethods={formMethods}
                      />
                    )}
                  />
                )}
              </Grid>
            </>
          )}
        </Grid>
      </GenericDialog>
      <Cancel
        openDialog={cancelModalOpen}
        handleConfirmAction={() => onCloseCall()}
        handleCancelAction={() => {
          setCancelModalOpen(false);
        }}
      />
    </FormProvider>
  );
};
