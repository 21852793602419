import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IExtensionDetails,
  IPlacementRequirementKey,
  IPlacementStatus,
  IPlacementStatusChangeSource,
  IPlacementStatusRequirementMessage,
  IPlacementStatusSuccessType,
  IUpdatePlacementStatusResponse,
  PlacementStatus,
} from 'app/models/Placement/PlacementDetails';
import { serviceActions } from './async-actions';

export const initialState = {
  currentPlacementStatus: undefined,
  availablePlacementStatus: [],
  updatePlacementStatusResponse: null,
  failedRequirements: {},
  warningRequirements: {},
  extensionDetails: { isPlacementExtendable: false },
  isModificationError: false,
} as PlacementStatus;

const PlacementStatusSlice = createSlice({
  name: 'placementStatus',
  initialState,
  reducers: {
    setPlacementStatus(state, action: PayloadAction<PlacementStatus>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setSelectedStatus(
      state,
      action: PayloadAction<{ status?: IPlacementStatus; statusChangeSource?: IPlacementStatusChangeSource }>,
    ) {
      return {
        ...state,
        selectedStatus: action.payload?.status,
        statusChangeSource:
          action.payload?.statusChangeSource !== undefined
            ? action.payload.statusChangeSource
            : state.statusChangeSource,
      };
    },
    setCurrentPlacementStatus(state, action: PayloadAction<IPlacementStatus>) {
      return {
        ...state,
        currentPlacementStatus: action.payload,
      };
    },
    setAvailablePlacementStatus(state, action: PayloadAction<IPlacementStatus[]>) {
      return {
        ...state,
        availablePlacementStatus: action.payload,
      };
    },
    setUpdatePlacementStatusResponse(state, action: PayloadAction<IUpdatePlacementStatusResponse | null>) {
      return {
        ...state,
        updatePlacementStatusResponse: action.payload
          ? {
              ...action.payload,
              successType:
                action.payload?.successType ??
                state.updatePlacementStatusResponse?.successType ??
                IPlacementStatusSuccessType.placementUpdate,
            }
          : null,
      };
    },
    setIsModificationError(state, action: PayloadAction<{ isModificationError: boolean }>) {
      return {
        ...state,
        isModificationError: action.payload?.isModificationError,
      };
    },
    removeFailedRequirement(state, action: PayloadAction<{ requirement: IPlacementStatusRequirementMessage }>) {
      const newFailed: any = {};
      for (const item of Object.entries(state.failedRequirements || [])) {
        const foundIndex = (item[1] as IPlacementStatusRequirementMessage[]).findIndex(
          i =>
            (i.message as string)?.startsWith(action.payload.requirement?.message || '++') ||
            i.id === action.payload.requirement.id,
        );
        const copy = [...(item[1] as IPlacementStatusRequirementMessage[])];
        if (foundIndex > -1) {
          copy.splice(foundIndex, 1);
          item[1] = copy;
        }
        newFailed[item[0]] = copy;
      }
      return {
        ...state,
        failedRequirements: newFailed,
      };
    },
    /**
     * Append a unique requirement for the given status.
     * If the requirement already exists, it will be ignored for that particular status.
     */
    setFailedRequirementForStatus(
      state,
      action: PayloadAction<{ status: IPlacementStatus; requirement: IPlacementStatusRequirementMessage }>,
    ) {
      const key = `${action.payload.status.activityTypeId}:${action.payload.status.activityStatusId}`;
      if (
        state.failedRequirements &&
        (state.failedRequirements[key] || []).findIndex(
          item =>
            (item.id === action.payload.requirement.id && item.id !== IPlacementRequirementKey.createPlacement) ||
            (item.message === action.payload.requirement.message &&
              item.tooltip?.message === action.payload.requirement.tooltip?.message),
        ) !== -1
      ) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        failedRequirements: {
          ...state.failedRequirements,
          [key]: [...((state.failedRequirements ?? {})[key] || []), action.payload.requirement],
        },
      };
    },
    resetFailedRequirementForStatus(state, action: PayloadAction<{ status: IPlacementStatus }>) {
      const key = `${action.payload.status.activityTypeId}:${action.payload.status.activityStatusId}`;
      return {
        ...state,
        failedRequirements: {
          ...state.failedRequirements,
          [key]: [],
        },
      };
    },
    resetFailedRequirements(state) {
      return {
        ...state,
        failedRequirements: {},
      };
    },
    removeWarningRequirement(state, action: PayloadAction<{ requirement: IPlacementStatusRequirementMessage }>) {
      const newWarnings: any = {};
      for (const item of Object.entries(state.warningRequirements || [])) {
        const foundIndex = (item[1] as IPlacementStatusRequirementMessage[]).findIndex(
          i =>
            (i.message as string).startsWith(action.payload.requirement?.message ?? '++') ||
            i.id === action.payload.requirement.id,
        );
        const copy = [...(item[1] as IPlacementStatusRequirementMessage[])];
        if (foundIndex > -1) {
          copy.splice(foundIndex, 1);
          item[1] = copy;
        }
        newWarnings[item[0]] = copy;
      }
      return {
        ...state,
        warningRequirements: newWarnings,
      };
    },
    setWarningRequirement(state, action: PayloadAction<{ requirement: IPlacementStatusRequirementMessage }>) {
      const key = action.payload.requirement.id.toString();
      if (
        state.warningRequirements &&
        (state.warningRequirements[key] || []).findIndex(
          item =>
            (item.id === action.payload.requirement.id && item.id !== IPlacementRequirementKey.createPlacement) ||
            (item.message === action.payload.requirement.message &&
              item.tooltip?.message === action.payload.requirement.tooltip?.message),
        ) !== -1
      ) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        warningRequirements: {
          ...state.warningRequirements,
          [key]: [...((state.warningRequirements ?? {})[key] || []), action.payload.requirement],
        },
      };
    },
    resetWarningRequirements(state) {
      return {
        ...state,
        warningRequirements: {},
      };
    },
    setExtensionDetails(state, action: PayloadAction<IExtensionDetails>) {
      return {
        ...state,
        extensionDetails: { ...state.extensionDetails, ...action.payload },
      };
    },
    reset() {
      return {
        ...initialState,
      };
    },
  },
});

const { actions } = PlacementStatusSlice;
export const { reducer: placementStatusReducer, name: placementStatusSliceKey } = PlacementStatusSlice;
export const placementStatusAction = { ...actions, ...serviceActions };
