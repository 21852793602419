import { Divider, Grid, IconButton, List, ListItem, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { withReadOnly } from 'oidc/withReadOnly';
import React from 'react';
import ExtensionPlacementSuite from 'app/assets/images/IconImages/ExtensionPlacementSuite.svg';
import { ListItemIcon, MoveNextIcon, PassIcon, useListStyles } from './styles';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { selectExtensionInfo } from 'store/redux-store/placement-summary/selectors';
import { convertToFormat } from 'app/helpers/dateHelper';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { colors } from 'styles/styleVariables';
import { placementStatusAction } from 'store/redux-store/placement-status/slice';
import { selectPlacementHeaderData } from 'store/redux-store/placement-details/selectors';
import { CustomTooltip } from '@AMIEWEB/Common';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()({
  titleGrid: {
    gap: 6,
    alignItems: 'self-end',
  },
  title: {
    fontWeight: 450,
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  statusList: {
    padding: '6px 0 0 30px',
  },
  statusItem: {
    padding: '0  0 0 30px',
  },
});

export const ExtensionStatus = ({
  availableExtensionStatus = [],
  onStatusSelected,
  extensionStatusId,
  extensionStatus,
}) => {
  const { classes: listClasses } = useListStyles({});
  const { classes, cx } = useStyles();
  const extensions = useSelector(selectExtensionInfo);
  const { placementId } = useSelector(selectPlacementHeaderData);

  const latestExtension = extensions.length - 1;

  const dispatch = useDispatch();

  const resetExtension = () =>
    dispatch(placementStatusAction.updateExtentionStatus({ placementId, extensionStatusId: null }));

  return (
    <Grid container direction="column">
      <Grid container item justifyContent="flex-start" classes={{ container: classes.titleGrid }}>
        <img src={ExtensionPlacementSuite} alt="Extend Placement" />
        <Typography classes={{ body1: classes.title }} variant="body1">{`Extension`}</Typography>
        {!!extensionStatusId && !!extensionStatus && (
          <>
            <Grid container direction="row" classes={{ root: cx(classes.statusItem, [classes.titleWrapper]) }}>
              <Grid item>
                <Typography style={{ fontSize: '14px' }}>
                  <span className={classes.title}>Status: </span>
                  {extensionStatus}
                </Typography>
              </Grid>
              <Grid item>
                {withReadOnly(ExtensionResetBtn)({
                  disabled: false,
                  onClick: resetExtension,
                })}
              </Grid>
            </Grid>

            {extensions[latestExtension] && extensions[latestExtension]?.startDate ? (
              <Grid container direction="row">
                <Grid item direction="column" classes={{ root: classes.statusItem }}>
                  <Typography style={{ fontSize: '14px' }}>
                    <span className={classes.title}>Date: </span>
                    {`${convertToFormat(extensions[latestExtension]?.startDate, 'MM/DD/yyyy')} - ${convertToFormat(
                      extensions[latestExtension]?.endDate,
                      'MM/DD/yyyy',
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
          </>
        )}
      </Grid>
      <Grid item direction="column" classes={{ root: classes.statusList }}>
        <List disablePadding>
          {availableExtensionStatus?.map(({ extensionStatusId, isPositive, extensionStatus }, indx) => (
            <>
              {availableExtensionStatus?.length > 1 && indx !== 0 && <Divider className={listClasses.divider} />}
              <ListItem disableGutters className={listClasses.listItem} data-parent={true}>
                <ListItemIcon>
                  <PassIcon style={{ color: isPositive ? undefined : '#ab0012' }} />
                </ListItemIcon>
                <Grid container spacing={2} alignContent="center" alignItems="center">
                  <Grid item xs={12}>
                    <Grid container spacing={2} wrap="nowrap">
                      <Grid item alignItems="center">
                        <StatusTitle>{extensionStatus}</StatusTitle>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {withReadOnly(MoveNextIcon)({
                  disabled: false,
                  onClick: () => onStatusSelected({ extensionStatusId, extensionStatus }),
                })}
              </ListItem>
            </>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

const ExtensionResetBtn = props => {
  const { t } = useTranslation();
  return (
    <CustomTooltip standardMargin placement="bottom" tooltipContent={t('Reset Status')}>
      <ResetBtn {...props}>
        <RestartAltIcon />
      </ResetBtn>
    </CustomTooltip>
  );
};

const ResetBtn = styled(IconButton)`
  padding: 2px;
  margin-top: -6px;
  &:hover {
    cursor: pointer;
    color: ${colors.amnLightBlue};
  }
`;
const StatusTitle = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
`;
