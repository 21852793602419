import { GridTag } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { useDecision } from '@optimizely/react-sdk';
import { PlacementSearchFilterName, placementViewInfo } from 'app/components/GlobalSearch/Placement/SearchUtils';
import { AssociatedPlacements } from 'app/components/Placement/AssociatedPlacements/AssociatedPlacements';
import {
  associatedPlacementSliceKey,
  associatedPlacementReducer,
} from 'app/components/Placement/AssociatedPlacements/store/associatedPlacements.redux';
import { associatedPlacementsSaga } from 'app/components/Placement/AssociatedPlacements/store/associatedPlacements.saga';
import { notFacilityColumns } from 'app/components/Placement/AssociatedPlacements/store/utils';
import { ff_placement_region_fields } from 'app/constants/FeatureFlags/Placement/Keys';
import { ff_candidate_communication_grid } from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

export const FacilityPlacementsWapper = () => {
  useInjectReducer({ key: associatedPlacementSliceKey, reducer: associatedPlacementReducer });
  useInjectSaga({ key: associatedPlacementSliceKey, saga: associatedPlacementsSaga });
  const [candidateCommunicationConsentFlag] = useDecision(ff_candidate_communication_grid);
  const { t } = useTranslation();
  const [regionFlag] = useDecision(ff_placement_region_fields);

  const { id: facilityId } = useParams<{ id: string }>();
  const [columns, setColumns] = useState(
    placementViewInfo(t, [], candidateCommunicationConsentFlag.enabled).filter(
      column =>
        !(regionFlag.enabled ? notFacilityColumns : [...notFacilityColumns, 'region']).some(
          notValue => notValue === column.field,
        ),
    ),
  );

  return (
    <AssociatedPlacements
      columns={columns}
      rootFilter={{ [PlacementSearchFilterName.facility]: [facilityId] }}
      setColumns={setColumns}
      gridTag={GridTag.FacilityPlacements}
    />
  );
};
