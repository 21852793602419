import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import { useLocation } from 'react-router-dom';
import { Avatar, List, ListItemButton, ListItemIcon, ListItemText, Popover, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CustomTooltip } from '../Tooltips';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import { useHeaderOptions } from './UseHeaderOptions';
import { setTimeout } from 'timers';
import { PlacementChangeHeaderLink } from '@AMIEWEB/Placement/PlacementChange/PlacementChangeHeaderLink';

const useStyles = makeStyles<{
  headerAbbrBackground: string;
  headerIdBackground: string;
  collapseAbbr: boolean;
  width: number;
}>()((theme, props) => ({
  avatarContainer: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 0,
    '&:hover div:nth-child(2)': {
      //   backgroundColor: '#2e7d32',
      //   transform: 'scaleX(1.4545)',
      //   fontSize: 18,
      //   borderTopLeftRadius: '4px',
      //   borderBottomLeftRadius: '4px',
      //   textAlign: 'center',
    },
    '&:hover div:first-child': {
      backgroundPosition: 'right',
    },
    padding: 0,
    '& .MuiButtonBase-root': {
      padding: 0,
      borderRadius: 0,
    },
    '& .MuiButton-root': {
      padding: 0,
      borderRadius: 0,
    },
    '&:hover': {
      '& .typoGraphy': {
        transform: 'translateX(-10px)',
      },
    },
  },
  avatarName: {
    height: 50,
    width: !props.collapseAbbr ? 51 : 20,
    cursor: 'pointer',
    fontSize: 20,
    fontWeight: 500,
    color: '#fff',
    textAlign: 'center',
    padding: '9px 0',
    backgroundColor: '#34B233',
    background: `linear-gradient(to right, ${props.headerAbbrBackground} 50%, ${props.headerIdBackground} 50%)`,
    backgroundSize: '200% 100%',
    backgroundPosition: 'left',
    textTransform: 'uppercase',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    transition: 'all 0.5s ease',
    transform: 'translateX(1px)',
  },
  textArea: {
    width: props.width ? props.width : '',
    minWidth: 110,
    height: 50,
    backgroundColor: props.headerIdBackground,
    paddingTop: 9,
    transform: 'translateX(0)',
    transformOrigin: 'right',
    transition: 'transform 2s ease',
  },
  typoGraphy: {
    color: '#fff',
    marginLeft: 5,
    textTransform: 'none',
    '&.MuiTypography-root': {
      fontSize: 20,
    },
    '&.MuiTypography-root-typoGraphy': {
      fontSize: 20,
    },
    '&.MuiTypography-body1': {
      fontSize: 20,
    },
  },
  typoGraphyHover: {
    color: '#fff',
    transform: `translateX(${!props.collapseAbbr ? '-14px' : 0})`,
    textTransform: 'none',
    fontSize: 20,
    '&.MuiTypography-root': {
      fontSize: 20,
    },
    '&.MuiTypography-root-typoGraphy': {
      fontSize: 20,
    },
    '&.MuiTypography-body1': {
      fontSize: 20,
    },
  },
  dropDownButton: {
    backgroundColor: props.headerIdBackground,
    height: 50,
    marginRight: 10,
    color: '#fff',
    width: 28,
    marginLeft: 0,
    paddingTop: 12.5,
    cursor: 'pointer',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    textAlign: 'right',
    paddingRight: 3,
  },
  tooltip: {
    backgroundColor: '#333333',
    color: '#fff',
    '& .MuiTooltip-arrow': {
      color: '#333333',
    },
  },
  list: {
    '& .MuiListItemIcon-root': {
      minWidth: 34,
    },
  },
}));

export interface IHeaderButtonProps {
  headerAbbr: string;
  headerId: string;
  headerAbbrBackground: string;
  headerIdBackground: string;
  customSearchType?: string;
  isDisabled?: boolean;
  addOnControls?: {
    openPlacementChangeDialog?: Function;
  };
}

const HeaderButton = ({
  headerAbbr,
  headerId,
  headerAbbrBackground,
  headerIdBackground,
  customSearchType,
  isDisabled = false,
  addOnControls,
}: IHeaderButtonProps) => {
  const history = useLocation();
  const searchType = customSearchType ?? history.pathname.split('/')[1];
  const [width, setWidth] = useState(null);
  const { classes } = useStyles({
    headerAbbrBackground: headerAbbrBackground,
    headerIdBackground: headerIdBackground,
    collapseAbbr: headerAbbr == '',
    width: width,
  });
  const [hover, setHover] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);
  const [showToolTip2, setShowToolTip2] = useState(false);
  const [openMenuList, setOpenMenuList] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { copyIdToClipBoard, copyObjectToClipBoard, sendEmail, copyUrlToClipBoard } = useHeaderOptions(
    customSearchType && searchType,
  );
  const ref = React.useRef(null);

  React.useEffect(() => {
    const divWidth = ref.current.offsetWidth;
    if (divWidth > 110) {
      setWidth(divWidth);
    }
  }, [headerId, headerAbbr]);

  const handleClick = () => {
    setShowToolTip2(false);
    copyIdToClipBoard();
    setShowToolTip(true);
  };

  const OptionsList = () => {
    return (
      <List sx={{ width: 218, maxWidth: 240 }} className={classes.list}>
        <ListItemButton
          id="copy-object-button"
          onClick={() => {
            copyObjectToClipBoard();
            setShowToolTip2(true);
            closeMenu();
            setTimeout(() => {
              setShowToolTip2(false);
            }, 2000);
          }}
        >
          <ListItemIcon>
            <ContentCopyIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={`Copy ${searchType?.charAt(0).toUpperCase() + searchType?.slice(1)} Data`} />
        </ListItemButton>
        <ListItemButton
          id="copy-url-button"
          onClick={() => {
            copyUrlToClipBoard();
            setShowToolTip2(true);
            closeMenu();
            setTimeout(() => {
              setShowToolTip2(false);
            }, 2000);
          }}
        >
          <ListItemIcon>
            <InsertLinkIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={`Copy ${searchType?.charAt(0).toUpperCase() + searchType?.slice(1)} URL`} />
        </ListItemButton>
        <ListItemButton
          id="email-button"
          onClick={() => {
            closeMenu();
            sendEmail();
          }}
        >
          <ListItemIcon>
            <ForwardToInboxIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={`Email ${searchType.charAt(0).toUpperCase() + searchType.slice(1)} Data`} />
        </ListItemButton>
        {searchType === SearchType.placement && addOnControls?.openPlacementChangeDialog && (
          <PlacementChangeHeaderLink
            closeCopyMenu={closeMenu}
            openPlacementChangeDialog={addOnControls?.openPlacementChangeDialog}
          />
        )}
      </List>
    );
  };

  const getHoverText = () => {
    switch (searchType) {
      case SearchType.candidate:
        return `Copy CID`;
      case SearchType.placement:
        return `Copy PID`;
      case SearchType.order:
        return `Copy OID`;
      case SearchType.facility:
        return `Copy FID`;
      case SearchType.unit:
        return `Copy UID`;
      default:
        return '';
    }
  };

  const openMenu = event => {
    setAnchorEl(event.currentTarget);
    setOpenMenuList(true);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setOpenMenuList(false);
  };

  return (
    <div
      onMouseLeave={() => {
        setShowToolTip(false);
        setShowToolTip2(false);
      }}
    >
      <CustomTooltip
        classes={{
          tooltip: classes.tooltip,
        }}
        tooltipContent={'Copied to clipboard'}
        open={showToolTip}
      >
        <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
          <Button
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => {
              setHover(false);
              setShowToolTip(false);
              setShowToolTip2(false);
            }}
            onClick={() => handleClick()}
            className={classes.avatarContainer}
            style={{ paddingRight: 0 }}
            disableRipple
            disabled={isDisabled}
          >
            <div className={classes.avatarName} id={'profileAbbr'}>
              {(!hover && (headerAbbr || '')) || ''}
            </div>
            <div className={classes.textArea} ref={ref}>
              <Typography className={hover ? classes.typoGraphyHover : classes.typoGraphy} id={`profileId`}>
                {!hover ? headerId || '' : getHoverText() || ''}
              </Typography>
            </div>
          </Button>
          <CustomTooltip
            tooltipContent={`Copied to clipboard`}
            classes={{
              tooltip: classes.tooltip,
            }}
            open={showToolTip2}
          >
            <div
              className={classes.dropDownButton}
              onClick={event => (isDisabled ? event.stopPropagation() : openMenu(event))}
            >
              {!isDisabled && <KeyboardArrowDownIcon style={{ color: '#fff' }} />}
            </div>
          </CustomTooltip>
          <Popover
            open={openMenuList}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => closeMenu()}
          >
            <OptionsList />
          </Popover>
        </div>
      </CustomTooltip>
    </div>
  );
};

export default HeaderButton;
