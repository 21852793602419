import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React from 'react';
import { SubmissionPacketWrapper } from '@AMIEWEB/Common/SubmissionPreference/SubmissionPacket/SubmissionPacketWrapper';
import { SubmissionEmailWrapper } from '@AMIEWEB/Common/SubmissionPreference/SubmissionEmail/SubmissionEmailWrapper';
import { createOrderPreferenceActions, createOrderPreferenceReducer, createOrderPreferenceSliceKey } from 'store/redux-store/create-order-preferences/slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCreateOrderPreference,
  selectMiscellaneousData,
} from 'store/redux-store/create-order-preferences/selectors';
import { SubmissionLimitsWrapper } from '@AMIEWEB/Common/SubmissionPreference/SubmissionLimit/SubmissionLimitsWrapper';
import { usePromiseTracker } from 'react-promise-tracker';
import { selectClientContacts, selectedOrderTypeDetails } from '@AMIEWEB/NewOrder/Store/Order.selectors';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { orderCreationNewPreferenceSaga } from 'store/redux-store/create-order-preferences/saga';
import { PlacementOrderType } from 'app/models/Placement/PlacementDetails';
import { useParams } from 'react-router-dom';

export const CreateOrderPreferences = (props) => {
  const dispatch = useDispatch();
  const params = useParams<{ facilityId: string }>();

  useInjectReducer({ key: createOrderPreferenceSliceKey, reducer: createOrderPreferenceReducer });
  useInjectSaga({ key: createOrderPreferenceSliceKey, saga: orderCreationNewPreferenceSaga });
  const { disableEmailContainer, disablePacketContainer } = useSelector(selectMiscellaneousData);
  const clientContacts = useSelector(selectClientContacts);
  const orderDetails = useSelector(selectedOrderTypeDetails);
  const { submissionLimits: { activePlacementCounts }, } = useSelector(selectCreateOrderPreference);

  const isStrike = orderDetails?.orderType === PlacementOrderType.Strike;
  const { promiseInProgress: isFetchingPlacementCounts } = usePromiseTracker({
    area: 'create-order-preference-active-placement-counts',
    delay: 0,
  });
  const updateContainerState = (
    isEnabled: boolean,
    containerName: 'disableEmailContainer' | 'disablePacketContainer',
  ) => {
    dispatch(
      createOrderPreferenceActions.setMiscellaneousData({
        key: containerName,
        value: isEnabled,
      }),
    );
  };

  return (
    <LayoutGrid xs={13} direction="column" data-testid="create-order-submission-container" spacing={2}>
      <LayoutGridItem>
        <SubmissionLimitsWrapper
          activePlacementCounts={activePlacementCounts}
          clientContacts={clientContacts}
          isFetchingPlacementCounts={isFetchingPlacementCounts}
          isStrike={isStrike}
          submissionEmailSetup={props.submissionEmailSetup}
          submissionPacketSetup={props.submissionPacketSetup}
          isFetchingPreferences={false}
          updateContainerState={updateContainerState}
          isActivePlacementDisabled={true}
        />
      </LayoutGridItem>
      <LayoutGridItem>
        <SubmissionPacketWrapper disabled={disablePacketContainer} />
      </LayoutGridItem>
      <LayoutGridItem>
        <SubmissionEmailWrapper disabled={disableEmailContainer} facilityId={parseInt(params.facilityId)}/>
      </LayoutGridItem>
    </LayoutGrid>
  );
};
