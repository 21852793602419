import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import {
  IAssociateCandidatePayload,
  ICommunicationLimitsPayload,
  ISaveCallLogPayload,
  IUpdateConversationBrandPayload,
} from 'app/models/Notification/Notification';
import baseAxiosInstance from 'utils/BaseApi';
import messageStack from 'app/components/Notification/MessageInbox/MessageStack';
import { IChat } from 'app/models/Sms/IChat';
import { CompareAttachmentsInInbox } from 'app/components/GlobalSearch/helper';
import { IUserSmsForwardPreference } from 'oidc/user.redux';

const notificationBaseUrl = globalThis?.app?.env?.REACT_APP_NOTIFICATION_SERVICE_BASE_URL;
const notificationFunctionBaseUrl = globalThis?.app?.env?.REACT_APP_NOTIFICATION_FUNCTION_BASE_URL;

/** LOGS APIS */
/**
 * Fetch user logs .
 * @constructor
 * @param {any} data - Payload for getting candidate logs .
 */
export async function GetUserLogs(data: any) {
  try {
    const url = `${notificationBaseUrl}/logs`;
    const response = await baseAxiosInstance.post(url, data);
    messageStack.Reset();
    const msgStack = CompareAttachmentsInInbox(response.data) as IChat;
    messageStack.messageStack = msgStack;
    return msgStack as any;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'GetUserLogs',
        area: 'src/app/services/NotificationServices/NotificationService.ts',
      },
    });
  }
}

/**
 * Fetch slim logs for candidate communication.
 * @constructor
 * @param {any} data - Payload for getting candidate logs .
 */
export async function GetUserLogsSlim(data: any) {
  const url = `${notificationBaseUrl}/logs/slim`;
  const response = await baseAxiosInstance.post(url, data);
  return response.data;
}

/**
 * Fetch traveller KPI's.
 * @constructor
 * @param {any} payload - Payload for getting candidate kpis .
 */
export const getKpis = async (payload: any) => {
  try {
    const url = `${notificationBaseUrl}/logs/kpis`;
    const response = await baseAxiosInstance.post(url, payload);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getKpis',
        area: 'src/app/services/NotificationServices/NotificationService.ts',
      },
    });
  }
};

export const getUserKpis = async (payload: any) => {
  try {
    const url = `${notificationBaseUrl}/logs/user/kpis`;
    const response = await baseAxiosInstance.post(url, payload);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getUserKpis',
        area: 'src/app/services/NotificationServices/NotificationService.ts',
      },
    });
  }
};

/**
 * Fetch available channels for a traveller.
 * @constructor
 * @param {any} payload - data for fetching traveller.
 */
export const getAvailableChannels = async (payload: any) => {
  try {
    const url = `${notificationBaseUrl}/logs/available/channels`;
    const response = await baseAxiosInstance.post(url, payload);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getAvailableChannels',
        area: 'src/app/services/NotificationServices/NotificationService.ts',
      },
    });
  }
};

/** EMAIL APIS */
/**
 * Send Email to recipient.
 * @constructor
 * @param {any} publishEmail -Payload for sending email.
 */
export async function publishEmail(publishEmail: any) {
  const url = `${notificationBaseUrl}/email/publish`;
  const response = await baseAxiosInstance.post(url, publishEmail);
  return response;
}

/**
 * Save draft Email to be sent to recipient.
 * @constructor
 * @param {any} draftEmail -Payload for saving draft email.
 */
export async function saveDraftEmail(draftEmail: any) {
  const url = `${notificationBaseUrl}/email/drafts`;
  return await baseAxiosInstance.post(url, draftEmail);
}

/**
 * Upload Attachment into storage account
 * @constructor
 * @param {FormData} attachments - Payload for uploading Attachment into storage account.
 */
export async function UploadAttachments(attachments: FormData) {
  try {
    const url = `${notificationBaseUrl}/email/publish`;
    const response = await baseAxiosInstance.post(url, attachments);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'UploadAttachments',
        area: 'src/app/services/NotificationServices/NotificationService.ts',
      },
    });
    return error;
  }
}

/** SMS APIS */
/**
 * Send Sms to recipient.
 * @constructor
 * @param {any} publishSms -Payload for sending sms.
 */
export async function publishSms(publishSms: any) {
  const url = `${notificationBaseUrl}/sms/publish`;
  const response = await baseAxiosInstance.post(url, publishSms);
  return response;
}

/**
 * Update crendiating analyst brand for sms .
 * @constructor
 * @param {IUpdateConversationBrandPayload} - Payload for updating conversation brand.
 */
export async function UpdateConversationBrand(payload: IUpdateConversationBrandPayload) {
  const url = `${notificationBaseUrl}/sms/conversation/update/brand`;
  const response = await baseAxiosInstance.post(url, payload);
  return response;
}

/**
 * Update message read status
 * @constructor
 * @param {any} data - Payload for updating message read status.
 */
export async function UpdateMessageReadStatus(data: any) {
  const url = `${notificationBaseUrl}/Logs/readstatus`;
  const response = await baseAxiosInstance.post(url, data);
  return response.data;
}

export async function UpdateMessageAlertReadStatus(data: any) {
  const url = `${notificationBaseUrl}/Logs/alerts/order-match`;
  const response = await baseAxiosInstance.post(url, data);
  return response.data;
}
/**
 * Save conversation notes
 * @constructor
 * @param {any} data - Payload for saving conversation notes .
 */
export async function SaveConversationNotes(data: any) {
  const url = `${notificationBaseUrl}/Sms/conversation/save/notes`;
  const response = await baseAxiosInstance.post(url, data);
  return response.data;
}

/**
 * Fetch conversation notes
 * @constructor
 * @param {any} data - Payload for fetch conversation notes .
 */
export async function FetchConversationNotes(data: any) {
  const url = `${notificationBaseUrl}/Sms/conversation/search/notes`;
  const response = await baseAxiosInstance.post(url, data);
  return response.data;
}

/**
 * Get recruiter long code.
 * @constructor
 * @param {string} recruiterId - Id for fetching user Twilio longCode .
 */
export async function getUserTwilioLongCode(recruiterId: string | undefined) {
  try {
    const url = `${notificationBaseUrl}/sms/LongCode/search/recruiter/${recruiterId}`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getUserTwilioLongCode',
        area: 'src/app/services/NotificationServices/NotificationService.ts',
      },
    });
  }
}

/**
 * Get recruiter inbound sms forwarding to email preferences
 * @constructor
 * @param {IUserSmsForwardPreference} payload - Payload for Changing the sms forwarding to email preference
 */
export async function changeUserEmailPreference(payload: IUserSmsForwardPreference) {
  try {
    const url = `${notificationBaseUrl}/sms/LongCode/preferences`;
    const response = await baseAxiosInstance.patch(url, payload);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'changeUserPreference',
        area: 'src/app/services/NotificationServices/NotificationService.ts',
      },
    });
  }
}

/**
 * Associate conversation with CID
 * @constructor
 * @param {IAssociateCandidatePayload} payload - Payload for Associating Candidate.
 */
export async function associateConversationWithContactId(payload: IAssociateCandidatePayload) {
  try {
    const url = `${notificationBaseUrl}/sms/conversation/update/contactId`;
    const response = await baseAxiosInstance.post(url, payload);
    return response.data.number;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'associateConversationWithContactId',
        area: 'src/app/services/NotificationServices/NotificationService.ts',
      },
    });
  }
}

/** INBOX APIS */
/**
 * Get inbox messages.
 * @constructor
 * @param {inboxQuery} - query to fetch inbox messages.
 */
export async function getInboxMessages(inboxQuery: any) {
  try {
    const url = `${notificationBaseUrl}/inbox`;
    const response = await baseAxiosInstance.post(url, inboxQuery);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getInboxMessages',
        area: 'src/app/services/NotificationServices/NotificationService.ts',
      },
    });
  }
}

/** VOICE APIS */
/**
 * Initiate a jabber call.
 * @constructor
 * @param {any} data - Payload for initiating a voice call .
 */
export async function InitiateCall(data: any) {
  const url = `${notificationFunctionBaseUrl}/voice/initiateCall`;
  return await baseAxiosInstance.post(url, data);
}

/**
 * Fetch user device and line list.
 * @constructor
 * @param {any} ntUserName - Payload for fetching user devices.
 */
export async function GetDevices(ntUserName: any) {
  try {
    const url = `${notificationFunctionBaseUrl}/voice/list/${ntUserName}`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'GetDevices',
        area: 'src/app/services/NotificationServices/NotificationService.ts',
      },
    });
  }
}
/**
 * Manual save call logs.
 * @constructor
 * @param {any} data - data for manually save.
 */
export async function SaveCallLog(data: ISaveCallLogPayload) {
  const url = `${notificationBaseUrl}/voice/saveCall`;

  if (data?.CTIActivityHistoryId === '') {
    data.CTIActivityHistoryId = undefined;
  }

  if (data?.tos !== undefined && Array.isArray(data?.tos)) {
    const newTos = data?.tos?.map(item => {
      return {
        ...item,
        brandId: !!item?.brandId && typeof item?.brandId !== 'string' ? item?.brandId?.toString() : item?.brandId,
        contactId:
          !!item?.contactId && typeof item?.contactId !== 'string' ? item?.contactId?.toString() : item?.contactId,
      };
    });
    data = { ...data, tos: newTos };
  }
  if (data?.associatedRecords) {
    data.associatedRecords = data?.associatedRecords
      .filter(
        record =>
          record?.name !== undefined && record?.name !== '' && record?.value !== undefined && record?.value !== '',
      )
      .map(record => ({
        name: typeof record?.name === 'string' ? record?.name : String(record?.name),
        value: typeof record?.value === 'string' ? record?.value : String(record?.value),
      }));
  }
  if (!!data?.sender?.userId && typeof data?.sender?.userId !== 'string') {
    data.sender.userId = String(data?.sender.senderId);
  }

  if (!!data?.sender?.senderId && typeof data?.sender?.senderId !== 'string') {
    data.sender.senderId = String(data?.sender.senderId);
  }

  return await baseAxiosInstance.post(url, data);
}

/** INAPP APIS */
/**
 * Publish InApp notification
 * @constructor
 * @param {any} data - Payload for publishInApp API.
 */
export async function publishInApp(publishInApp: any) {
  try {
    const url = `${notificationBaseUrl}/InApp/publish`;
    const response = await baseAxiosInstance.post(url, publishInApp);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'publishInApp',
        area: 'src/app/services/NotificationServices/NotificationService.ts',
      },
    });
    return null;
  }
}

/** NOTIFICATION ALERT APIS */
/**
 * Publish notification alert
 * @constructor
 * @param {any} data - Payload for publishAlert API.
 */

export async function publishAlert(publishAlert: any) {
  try {
    const localUrl = 'https://localhost:44305';
    const url = `${localUrl}/alert/publish`;
    const response = await baseAxiosInstance.post(url, publishAlert);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'publishAlert',
        area: 'src/app/services/NotificationServices/NotificationService.ts',
      },
    });
    return null;
  }
}

/** CAMPAIGN APIS */
/**
 * Get all saved campaign.
 * @constructor
 * @param {inboxQuery} - query to fetch all available campaign.
 */
export async function GetAllCampaign() {
  const campaignData = {
    pageSize: 0,
    pageNumber: 0,
  };
  const url = `${notificationBaseUrl}/campaign/all`;
  return await baseAxiosInstance.post(url, campaignData);
}

export async function getCandidateConsents(candidateIds: Array<string>) {
  const url = `${notificationBaseUrl}/Consents/onetrust-query`;
  try {
    const response = await baseAxiosInstance.post(url, { contactIds: candidateIds });
    return response.data;
  } catch (err) {
    trackException({
      exception: err,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateConsents',
        area: 'src/app/services/NotificationServices/NotificationService.ts',
      },
    });
    return null;
  }
}

export async function handleSkipBrandSelection(conversationId: string, contactId: string, skip: boolean) {
  const url = `${notificationBaseUrl}/sms/conversation/skip-brand-selection`;
  try {
    const response = await baseAxiosInstance.put(url, { id: conversationId, contactId, skipBrandSelection: skip });
    return response.data;
  } catch (err) {
    trackException({
      exception: err,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'handleSkipBrandSelection',
        area: 'src/app/services/NotificationServices/NotificationService.ts',
      },
    });
    return null;
  }
}

export async function getCoveredUserInbox(payload: {
  coveringEmployeeIds: number[];
  startDate?: string;
  endDate?: string;
  pageNumber: number;
  pageSize: number;
  statusIds?: number[];
  coveredEmployeeIds?: number[];
}) {
  try {
    const url = `${notificationBaseUrl}/Inbox/covered-inboxes`;
    const response = await baseAxiosInstance.post(url, payload);
    return response.data;
  } catch (err) {
    trackException({
      exception: err,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCoveredUserInbox',
        area: 'src/app/services/NotificationServices/NotificationService.ts',
      },
    });
  }
}

export async function getUnreadCount(phoneNumbers: string[]) {
  const url = `${notificationBaseUrl}/Inbox/unread-count-query`;
  try {
    const response = await baseAxiosInstance.post(url, { phoneNumbers: phoneNumbers });
    return response.data;
  } catch (err) {
    trackException({
      exception: err,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getUnreadCount',
        area: 'src/app/services/NotificationServices/NotificationService.ts',
      },
    });
    return null;
  }
}

export async function getCommunicationLimitsData(communicationLimitsPayload: ICommunicationLimitsPayload) {
  const url = `${notificationBaseUrl}/Suppressions/suppressions-query`;
  try {
    const response = await baseAxiosInstance.post(url, communicationLimitsPayload);
    return response;
  } catch (err) {
    trackException({
      exception: err,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCommunicationLimitsData',
        area: 'src/app/services/NotificationServices/NotificationService.ts',
      },
    });
    return null;
  }
}
