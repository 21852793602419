import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { getContext, put, fork, call, all, select } from 'redux-saga/effects';
import { SetPlacementFailRiskRequirementMessage } from 'store/redux-store/new-placement/saga';
import { placementStatusAction } from 'store/redux-store/placement-status/slice';
import { IPlacementSummaryRequest, placementSummaryActions } from '../slice';
import InitPlacementSummary, { IConsolidatedRequirementResponse } from '../init.types';
import { selectPlacementConsolidatedDetails } from '../consolidatedStoreSelectors';

export function* processwaitingGoodToGoRequirements(
  response: IConsolidatedRequirementResponse<any>,
  context: InitPlacementSummary,
) {
  try {
    if (!httpSuccess(response?.status)) {
      context.setServiceFailureByKey({ key: 'waitingGoodToGoRequirements', failureStatus: true });
      return;
    }
    yield fork(common, response.data);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'processwaitingGoodToGoRequirements',
        area: 'src/store/redux-store/placement-summary/common-saga/waitingGoodToGoRequirements.ts',
      },
    });
  }
}

export function* requestWaitingGoodToGoRequirements(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if(action.payload.isInitLoad && consolidatedDetails){
      response = {
        status : consolidatedDetails.summary.booking?.waitingGoodToGoStatusDetails?.status,
        data :consolidatedDetails.summary.booking?.waitingGoodToGoStatusDetails?.data
      }
    }

    if(!response?.status || !response?.data){
      response = yield call(
        placementService.getWaitingGoodToGoRequirements,
        action.payload.placementId,
        action.payload.placementOrderType,
      );
    }

    if (httpSuccess(response?.status)) {
      yield fork(common, response.data);
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestWGTGRequirements',
        area: 'src/store/redux-store/placement-summary/common-saga/waitingGoodToGoRequirements.ts',
      },
    });
  }
}

function* common(data) {
  const { clientConfirmation, candidateContract, userRole, nextStatusRequirement = [] } = data ?? {};
  /** Placeholder Store state into redux */

  /** Clear all errors for -  getWaitingGoodToGoRequirements */
  yield all(
    [
      IPlacementRequirementKey.wgtgCandidateContract,
      IPlacementRequirementKey.wgtgClientConfirmation,
      IPlacementRequirementKey.wgtgUserRole,
    ].map(id =>
      put(
        placementStatusAction.removeFailedRequirement({
          requirement: { id },
        }),
      ),
    ),
  );

  if (clientConfirmation) {
    yield fork(SetPlacementFailRiskRequirementMessage, {
      payload: {
        messageId: clientConfirmation.messageId,
        version: clientConfirmation.messageVersion,
        passFailType: clientConfirmation.type,
        data: undefined,
        messageTypeId: IPlacementRequirementKey.wgtgClientConfirmation,
        tooltipTypeParam: undefined,
        nextStatusRequirementParam: nextStatusRequirement,
      },
      type: placementSummaryActions.setFailedRequirement.type,
    });
  }

  if (candidateContract) {
    yield fork(SetPlacementFailRiskRequirementMessage, {
      payload: {
        messageId: candidateContract.messageId,
        version: candidateContract.messageVersion,
        passFailType: candidateContract.type,
        data: undefined,
        messageTypeId: IPlacementRequirementKey.wgtgCandidateContract,
        tooltipTypeParam: undefined,
        nextStatusRequirementParam: nextStatusRequirement,
      },
      type: placementSummaryActions.setFailedRequirement.type,
    });
  }
  if (userRole) {
    yield fork(SetPlacementFailRiskRequirementMessage, {
      payload: {
        messageId: userRole?.messageId,
        version: userRole?.messageVersion,
        passFailType: userRole?.type,
        data: undefined,
        messageTypeId: IPlacementRequirementKey.wgtgUserRole,
        tooltipTypeParam: undefined,
        nextStatusRequirementParam: nextStatusRequirement,
      },
      type: placementSummaryActions.setFailedRequirement.type,
    });
  }
}
