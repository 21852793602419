import { PayloadAction } from "@reduxjs/toolkit";
import { trackException } from "app-insights/appInsightsTracking";
import { ExceptionType } from "app/enums/Common";
import { NotificationService } from "app/services/NotificationServices/notification-service";
import { httpSuccess } from "app/services/serviceHelpers";
import { trackPromise } from "react-promise-tracker";
import { call, getContext, put, takeLatest } from "redux-saga/effects";
import { facilityCommunicationHistoryActions, IFacilityLogs, IFacilityLookups } from "./FacilityCommunicationHistory.redux";
import { FacilityService } from "app/services/FacilityServices/facility-service";
import { getFilterLookups, getFilterClientContactLookups, getFilterUnitLookups } from "../Helpers/helper";

const TrackFacilityLogs = (fn, ...args) => trackPromise(fn(...args), 'facility-logs');
export const TrackFacilitygetUnitsAndClientContactLookups = (fn, ...args) => trackPromise(fn(...args), 'facility-lookups');
const TrackFacilityUserRolesAndInitiatedByLookups
    = (fn, ...args) => trackPromise(fn(...args), 'facility-UserRoles-InitiatedByLookups');

export function* getFacilityLogs(action: PayloadAction<IFacilityLogs>) {
    const notificationService: NotificationService = yield getContext('notificationService');
    try {
        yield put(facilityCommunicationHistoryActions.setGridLoading(true));
        var response = yield call(TrackFacilityLogs, notificationService.getFacilityLogs, action.payload);
        if (httpSuccess(response?.status)) {
            yield put(facilityCommunicationHistoryActions.updateFacilityCommunicationLogs(response?.data));
        }
        yield put(facilityCommunicationHistoryActions.setGridLoading(false));
    } catch (error) {
        yield put(facilityCommunicationHistoryActions.setGridLoading(false));
        trackException({
            exception: error,
            properties: {
                name: ExceptionType.APIRequestError,
                functionName: 'getFacilityLogs',
                area: 'src/app/components/Facility/FacilityDetails/FacilityTabs/CommunicationHistory/store/FacilityCommunicationHistory.saga.ts',
            },
        });
    }
};

export function* getUnitsAndClientContactLookups(action: PayloadAction<IFacilityLookups>) {
    const facilityService: FacilityService = yield getContext('facilityService');
    try {
        var response = yield call(TrackFacilitygetUnitsAndClientContactLookups, facilityService.getUnitsAndClientContactLookups, action.payload);
        if (httpSuccess(response?.status)) {
            const contactNames = getFilterClientContactLookups(response?.data?.clientContactLookups);
            const units = getFilterUnitLookups(response?.data?.unitLookups);
            yield put(facilityCommunicationHistoryActions.setFacilityClientContactLookusps(contactNames));
            yield put(facilityCommunicationHistoryActions.setFacilityUnitsLookups(units));
        }
    } catch (error) {
        trackException({
            exception: error,
            properties: {
                name: ExceptionType.APIRequestError,
                functionName: 'getUnitsAndClientContactLookups',
                area: 'src/app/components/Facility/FacilityDetails/FacilityTabs/CommunicationHistory/store/FacilityCommunicationHistory.saga.ts',
            },
        });
    }
};

export function* getUserRolesAndInitiatedByLookups(action: PayloadAction<IFacilityLookups>) {
    const notificationService: NotificationService = yield getContext('notificationService');
    try {
        var response = yield call(TrackFacilityUserRolesAndInitiatedByLookups, notificationService.getUserRolesAndInitiatedByLookups, action.payload);
        if (httpSuccess(response?.status)) {
            const InitiatedBy = getFilterLookups(response?.data?.senders);
            const userRoles = getFilterLookups(response.data.userRoles);
            const communicationTypeOpts = getFilterLookups(response?.data?.channels);
            yield put(facilityCommunicationHistoryActions.setFacilityInitiatedByLookups(InitiatedBy));
            yield put(facilityCommunicationHistoryActions.setFacilityUserRolesLookups(userRoles));
            yield put(facilityCommunicationHistoryActions.setCommunicationTypeOpts(communicationTypeOpts));
        }
    } catch (error) {
        trackException({
            exception: error,
            properties: {
                name: ExceptionType.APIRequestError,
                functionName: 'getUserRolesAndInitiatedByLookups',
                area: 'src/app/components/Facility/FacilityDetails/FacilityTabs/CommunicationHistory/store/FacilityCommunicationHistory.saga.ts',
            },
        });
    }
};

export function* FacilityCommunicationHistorySaga() {
    yield takeLatest(facilityCommunicationHistoryActions.getFacilityLogs.type, getFacilityLogs);
    yield takeLatest(facilityCommunicationHistoryActions.getUnitsAndClientContactLookups.type, getUnitsAndClientContactLookups);
    yield takeLatest(facilityCommunicationHistoryActions.getUserRolesAndInitiatedByLookups.type, getUserRolesAndInitiatedByLookups);

}