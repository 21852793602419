
import { XGridDefaultColumn } from 'app/components/Common/XGrid/cells/XGridDefaults';
import { xGridDateSort, xGridNumberSort } from 'app/components/Common/XGrid/sorting/XGridSorting';
import Call from 'app/assets/images/WorkDesk/Call.png';
import Email from 'app/assets/images/WorkDesk/email.png';
import GreenCheck from 'app/assets/images/IconImages/GreenCheckCircleIcon.svg';
import { GetMomentOfDate } from 'utils/dates/moment';
import React from 'react';
import { Concatenate } from 'utils/string/string';
import { missingField } from 'app/constants';
import { GlanceStatus } from '../../../../../Common/GlanceTile/NewGlanceTile';

const defaultColumnProps = { ...XGridDefaultColumn, flex: 0 };

export const getExistngRfoColumns = (t, readyToSubmitPlacementIds) => {
  const columns = [
    {
      ...defaultColumnProps,
      headerName: t('OID'),
      field: 'orderId',
      width: 88,
      sortComparator: xGridNumberSort,
    },
    {
      ...defaultColumnProps,
      headerName: t('PID'),
      field: 'placementId',
      width: 88,
      sortComparator: xGridNumberSort,
    },
    {
      ...defaultColumnProps,
      headerName: t('Candidate'),
      field: 'candidate',
      width: 140,
      sortComparator: xGridNumberSort,
    },
    {
      ...defaultColumnProps,
      headerName: t('Available Start Date'),
      field: 'availabilityDate',
      width: 154,
      sortComparator: xGridDateSort,
    },
    { ...defaultColumnProps, headerName: t('Skillset'), field: 'skillset', width: 130 },
    {
      ...defaultColumnProps,
      headerName: t('Recruiter'),
      field: 'recruiter',
      width: 142,
    },
    { ...defaultColumnProps, headerName: t('Brand'), field: 'brand' },
    {
      ...defaultColumnProps,
      headerName: t('RFO Date'),
      field: 'statusUpdatedDate',
      width: 150,
      sortComparator: xGridDateSort,
    },
  ];
  return readyToSubmitPlacementIds.length
    ? [{ ...defaultColumnProps, headerName: ' ', field: 'isReadyToSubmit', width: 45 }, ...columns]
    : columns;
};

export const extractRows = (existingRFOs, readyToSubmitPlacementIds) =>
  existingRFOs?.map(item => {
    const availabilityDateMoment = GetMomentOfDate(item.availabilityDate);
    const statusDataMoment = GetMomentOfDate(item.statusUpdatedDate);
    const recruiterAttributes = [
      { key: Call, value: item.recruiter.phoneNumber || missingField },
      { key: Email, value: item.recruiter.email || missingField },
    ];
    return {
      ...item,
      id: item.placementId,
      orderId:item.orderId,
      candidateId: item.candidate.candidateId,
      isReadyToSubmit: readyToSubmitPlacementIds?.some(pid => pid === item.placementId) ? (
        <img src={GreenCheck} alt="Check" />
      ) : undefined,
      candidate: Concatenate([item.candidate?.firstName ?? '', item.candidate?.lastName] || [''], ' '),
      availabilityDate: availabilityDateMoment.isValid() ? availabilityDateMoment.format('MM/DD/YYYY') : missingField,
      skillset: Concatenate([item.disciplineAbbr, item.specialtyAbbr], '/'),
      recruiter: Concatenate([item.recruiter?.firstName || '', item.recruiter?.lastName] || [''], ' '),
      recruiterAttributes,
      statusUpdatedDate: statusDataMoment.isValid() ? statusDataMoment.format('MM/DD/YYYY hh:mm A') : missingField,
    };
  });

export const extraxtTileArray = (t, activePlacementCounts, readyToSubmitPlacementIds) => [
  {
    title: t('Ready to Submit'),
    status: {
      data: readyToSubmitPlacementIds.length,
      colorCode: readyToSubmitPlacementIds.length ? GlanceStatus.green : undefined,
    },
  },
  { title: t('Total Request File out'), status: { data: activePlacementCounts?.rfo } },
  { title: t('Sent'), status: { data: activePlacementCounts?.sent } },
  { title: t('Other'), status: { data: activePlacementCounts?.others } },
];
