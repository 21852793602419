import { Container } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';

const useStyles = makeStyles()({
  noPadding: {
    padding: '0px !important',
  },
});

export const Page = ({ standardPadding = true, ...props }: { standardPadding?: boolean; [key: string]: any }) => {
  const { classes, cx } = useStyles();

  return (
    <Container
      disableGutters
      maxWidth={false}
      className={cx({
        page: true,
        [classes.noPadding]: !standardPadding,
      })}
    >
      {props.children}
    </Container>
  );
};
