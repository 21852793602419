import { Switch, FormControlLabel } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { isAccountManagement, isCredentialing, isRecruitment, isSupportAdmin } from 'oidc/userRoles';
import { selectUser } from 'oidc/user.selectors';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { HomePage } from './AppHome';
import { DEFAULT_HOME_PAGE_LOCAL_STORAGE_KEY } from './helper';
import { selectNavigationMenu } from '@AMIEWEB/GlobalNavigationMenu/NavigationStick.selectors';
import { useDispatch } from 'react-redux';
import { globalActions } from 'app/ApplicationRoot/Global.redux';

const useStyles = makeStyles()(theme => ({
  labelPlacementStart: {
    margin: 'auto -6px',
    whiteSpace: 'nowrap',
  },
  action: {
    display: 'flex',
    flexDirection: 'row',
  },
  actionBtn: {
    fontSize: 12,
    padding: 0,
    minWidth: 30,
  },
  actionNoBtn: {
    color: '#888888',
  },
}));

export const DefaultHomeSwitch = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const appUser = useSelector(selectUser);
  const navigationMenu = useSelector(selectNavigationMenu);
  const [displaySwitch, setDisplaySwitch] = useState<boolean>(false);
  const [switchState, setSwitchState] = useState<boolean>(false);
  const dispatch = useDispatch();
  const snackbarID = new Date().getTime() + Math.random();

  const pages = Object.values(HomePage);
  const getPageName = customSwitch({
    0: t('appHome.amWorkDesk'),
    1: t('appHome.recWorkDesk'),
    2: t('appHome.credential'),
    3: t('appHome.credentialDesk'),
    4: t('appHome.pricingWorkDesk'),
    5: t('appHome.coverageWorkDesk'),
  })(null);

  const handleChange = state => {
    const pageName = getPageName(pages.findIndex(page => page === props.page));
    dispatch(
      globalActions.setSnackBar({
        id: snackbarID,
        message:
          state && pageName
            ? `${t('defaultPage.addQstTag0')} ${pageName} ${t('defaultPage.addQstTag1')}`
            : `${t('defaultPage.removeQstTag0')} ${pageName} ${t('defaultPage.removeQstTag1')}`,
        severity: 'info',
        disableIcon: true,
        actions: [
          {
            action: () => handleFeedback(true),
            closeSnackbar: true,
            text: t('defaultPage.yes'),
          },
          {
            closeSnackbar: true,
            text: t('defaultPage.no'),
          },
        ],
      }),
    );
  };
  const handleFeedback = choice => {
    const pageName = getPageName(pages.findIndex(page => page === props.page));
    if (choice && pageName) {
      if (switchState) {
        localStorage.removeItem(DEFAULT_HOME_PAGE_LOCAL_STORAGE_KEY);
        dispatch(
          globalActions.setSnackBar({
            message: t('defaultPage.notificationRemoveTag', { pageName }),
            severity: null,
            disableIcon: true,
          }),
        );
      } else {
        localStorage.setItem(DEFAULT_HOME_PAGE_LOCAL_STORAGE_KEY, props.page);
        dispatch(
          globalActions.setSnackBar({
            message: t('defaultPage.notificationAddTag', { pageName }),
            severity: null,
            disableIcon: true,
          }),
        );
      }
      setSwitchState(!switchState);
    } else {
      dispatch(
        globalActions.closeSnackBar({
          id: snackbarID,
          message: '',
          severity: null,
        }),
      );
    }
  };

  const checkDefaultHomePage = () => {
    if (
      appUser &&
      appUser.userInfo &&
      appUser.userInfo.roles &&
      ((isRecruitment(appUser.userInfo.roles) &&
        (isAccountManagement(appUser.userInfo.roles) || isCredentialing(appUser.userInfo.roles))) ||
        isSupportAdmin(appUser.userInfo.roles))
    )
      setDisplaySwitch(true);
    if (localStorage.getItem(DEFAULT_HOME_PAGE_LOCAL_STORAGE_KEY) === props.page) setSwitchState(true);
  };

  useEffect(() => {
    checkDefaultHomePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkDefaultHomePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationMenu]);

  return displaySwitch ? (
    <>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            name="defaultPageSwitch"
            size="small"
            checked={switchState}
            inputProps={{ 'aria-label': 'default-page-switch' }}
            onChange={(event, state) => handleChange(state)}
          />
        }
        label="Set as Default"
        labelPlacement="start"
        classes={{ labelPlacementStart: classes.labelPlacementStart }}
      />
    </>
  ) : (
    <div />
  );
};
