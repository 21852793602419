import { Box, Paper } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import { LayoutGrid, LayoutGridItem } from '../LayoutGrid';
import { SidePanel } from '../../ComponentLibrary/SidePanel/SidePanel';
import { CustomBackdrop } from 'app/components/Common/Backdrop/CustomBackdrop';

const useStyles = makeStyles<{ shrink: boolean, showSidePanel: boolean }>()((theme, props) => ({
  root: {
    minHeight: 'calc(100vh - 105px)',
    padding: !props.showSidePanel? '12px 0px 12px 12px' : '12px',
  },
  mainPanelRoot: {
    width: props.shrink ? 'calc(100% - 32px)' : 'calc(100% - 252px)',
  },
  sidePanel: {
    width: props.shrink ? 40 : 260,
  },
  container: {
    flexWrap: 'nowrap',
  },
}));

export const HomePage = ({
  title,
  titleActions,
  filters,
  dataVisualization,
  chips,
  table,
  stickIconArray,
  loading,
  sidePanelItems,
  showSidePanel= true,
  subMenu,
  ...props
}: {
  [key: string]: any;
  title: string;
  titleActions?: React.ReactNode;
  filters?: React.ReactNode;
  dataVisualization?: React.ReactNode;
  chips?: React.ReactNode;
  table?: React.ReactNode;
  sidePanelItems?: { title: string; component: React.ReactNode }[];
  stickIconArray?: number[];
  loading?: boolean;
  showSidePanel?: boolean;
  subMenu?: React.ReactNode;
}) => {
  const [stickChosen, setStickChosen] = useState<number | null>(null);
  const { classes } = useStyles({ shrink: stickChosen === null , showSidePanel: showSidePanel});
  const handleStickChoice = stick => setStickChosen(stick === stickChosen ? null : stick);

  return (
    <Paper id="home-page-paper" classes={{ root: classes.root }}>
      <LayoutGrid container direction="row" justifyContent="space-between" classes={{ container: classes.container }}>
        <LayoutGridItem
          container
          direction="column"
          spacing={2}
          classes={{ root: showSidePanel && classes.mainPanelRoot, container: classes.container }}
        >
          {subMenu && (
            <LayoutGridItem id="home-page-tabs" xs={12} style={{ paddingTop: '0px' }}>
              {subMenu}
            </LayoutGridItem>
          )}
          <LayoutGridItem id="home-page-title" container direction="row" justifyContent="space-between">
            <LayoutGridItem>
              <Box className="page-title">{title}</Box>
            </LayoutGridItem>
            {titleActions && <LayoutGridItem>{titleActions}</LayoutGridItem>}
          </LayoutGridItem>
          {dataVisualization && (
            <LayoutGridItem id="home-page-data-visualization" xs={12}>
              {dataVisualization}
            </LayoutGridItem>
          )}
          {filters && (
            <LayoutGridItem id="home-page-filters" xs={12}>
              {filters}
            </LayoutGridItem>
          )}
          {chips && (
            <LayoutGridItem id="home-page-chips" xs={12}>
              {chips}
            </LayoutGridItem>
          )}
          {table && (
            <LayoutGridItem id="home-page-table" xs={12}>
              {table}
            </LayoutGridItem>
          )}
        </LayoutGridItem>
        <LayoutGridItem classes={{ root: showSidePanel && classes.sidePanel }}>
          {stickIconArray && sidePanelItems && (
            <SidePanel
              items={sidePanelItems}
              stickChosen={stickChosen}
              handleChoice={handleStickChoice}
              iconArray={stickIconArray}
              enableCloseIcon
              scrollable={false}
            />
          )}
        </LayoutGridItem>
      </LayoutGrid>
      <CustomBackdrop open={loading} />
    </Paper>
  );
};

export const getHomePageSupportComponentHeight = (): number => {
  const a = document.getElementById('home-page-title')?.offsetHeight;
  const b = document.getElementById('home-page-data-visualization')?.offsetHeight;
  const c = document.getElementById('home-page-filters')?.offsetHeight;
  const d = document.getElementById('home-page-chips')?.offsetHeight;
  let spacing = 0;
  if (a) spacing++;
  if (b) spacing++;
  if (c) spacing++;
  if (d) spacing++;
  spacing = spacing >= 2 ? (spacing - 2) * 12 : 12;
  return (a ?? 0) + (b ?? 0) + (c ?? 0) + (d ?? 0) + (spacing || 12);
};
