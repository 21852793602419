import React from 'react';
import { Grid, Avatar, Divider } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import styledComponent from 'styled-components';
import { green } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { Concatenate } from 'utils/string/string';
import { useSelector } from 'react-redux';
import { candidateDetailsSelection } from '../Profile/CandidateDetails.selector';
import { RowContainer } from '@AMIEWEB/Candidate/Common/RowContainer';

const useStyles = makeStyles()({
  root: {
    border: '1px solid #d1d1d1',
    padding: '1%',
  },
  avatar: {
    height: 50,
    width: 50,
    marginRight: 10,
    backgroundColor: green[500],
  },
  container: {
    flexWrap: 'nowrap',
    whiteSace: 'nowrap',
    width: 'auto',
  },
  divider: {
    padding: '0 1%',
  },
  name: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#555555',
  },
  th: {
    textAlign: 'left',
  },
});

export const CandidateDetails = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const candidateDetails = useSelector(candidateDetailsSelection);

  const avatarText = Concatenate([candidateDetails.name?.first?.charAt(0), candidateDetails.name?.last?.charAt(0)], '');

  const candidateName = Concatenate(
    [candidateDetails.name.first, candidateDetails.name.middle || '', candidateDetails.name.last],
    ' ',
  );

  let skills: string = '';
  candidateDetails?.verifiedSkills.map(skill => {
    skills = Concatenate([skills, Concatenate([skill.disciplineAbbr, skill.specialtyAbbr], ', ')], ' | ');
  });

  const address = Concatenate(
    [
      candidateDetails.address?.address1 || '',
      candidateDetails.address?.city || '',
      candidateDetails?.address?.stateProvinceId || '',
      candidateDetails?.address?.zip || '',
      candidateDetails?.address?.country || '',
    ],
    ', ',
  );

  const phone = Concatenate(
    [candidateDetails?.primaryPhoneNumber || '', candidateDetails?.mobilePhoneNumber || ''],
    ' | ',
  );

  return (
    <Grid
      container
      justifyContent="flex-start"
      direction="row"
      classes={{ root: classes.root, container: classes.container }}
      style={{ padding: '1%' }}
    >
      <Grid item>
        <CandidateAvatar variant="rounded" classes={{ root: classes.avatar }}>
          {avatarText}
        </CandidateAvatar>
      </Grid>

      <Grid item container direction="column" justifyContent="space-between" classes={{ container: classes.container }}>
        <Grid item className={classes.name}>
          {candidateName}
        </Grid>
        <Grid item>
          <RowContainer>
            <Row>
              <RowHead className={classes.th}>{t('candidate.jobMatchComparison.skillSet')}</RowHead>
              <RowContent>{skills}</RowContent>
            </Row>
          </RowContainer>
        </Grid>
      </Grid>

      <Grid item classes={{ root: classes.divider }}>
        <Divider orientation="vertical" />
      </Grid>

      <Grid item container direction="column" justifyContent="space-between" classes={{ container: classes.container }}>
        <Grid item>
          <RowContainer>
            <Row>
              <RowHead className={classes.th}>{t('candidate.jobMatchComparison.address')}</RowHead>
              <RowContent>{address}</RowContent>
            </Row>
          </RowContainer>
        </Grid>
        <Grid item>
          <RowContainer>
            <Row>
              <RowHead className={classes.th}>{t('candidate.jobMatchComparison.phone')}</RowHead>
              <RowContent>{phone}</RowContent>
            </Row>
          </RowContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

const CandidateAvatar = styledComponent(Avatar)`
  color: white;
  text-transform: uppercase;
  font-size: 14px;
`;

const Row = styledComponent.tr`
  display: flex;
`;

const RowHead = styledComponent.th`
  color: #333333;
  font-size: 12px;
  padding-right: 12px;
  text-algn: left;
  min-width: 60px;
  white-space: nowrap;
`;

const RowContent = styledComponent.td`
  color: #003e68;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
`;
