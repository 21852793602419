import { GridCellParams } from '@mui/x-data-grid-pro';
import { Box, ClickAwayListener } from 'amn-ui-core';
import React from 'react';
import { ssnOnClickAccess, viewSSN } from './helper';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { selectGridTag } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';
import { GridTag } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';

export const XGridSSNClickableCell = (params: GridCellParams) => {
  return <SsnCheck params={params} />;
};

const SsnCheck = ({ params }: { params: GridCellParams }) => {
  const user = useSelector(selectUser);
  const gridTag = useSelector(selectGridTag);
  const [showFullSsn, setShowFullSsn] = React.useState(false);
  const handleOnClick = event => {
    setShowFullSsn(true);
  };
  return (
    <div
      onClick={event => {
        if (
          ((params.row.fieldChanged?.includes?.('SSN') && params.colDef.headerName == 'New Value') ||
            (params.row.fieldChanged?.includes?.('SSN') && params.colDef.headerName == 'Old Value')) &&
          ssnOnClickAccess(user)
        )
          handleOnClick(event);
        else if (gridTag !== GridTag.TaskXGrid) {
          event.stopPropagation();
        }
      }}
    >
      <div>
        {showFullSsn ? (
          <ClickAwayListener onClickAway={event => setShowFullSsn(false)}>
            <Box> {params.value.replace(/-/g, ' ')}</Box>
          </ClickAwayListener>
        ) : params.row.fieldChanged?.includes?.('SSN') && params.colDef.headerName == 'New Value' ? (
          viewSSN(params.value, user)
        ) : params.row.fieldChanged?.includes?.('SSN') && params.colDef.headerName == 'Old Value' ? (
          viewSSN(params.value, user)
        ) : (
          params.value
        )}
      </div>
    </div>
  );
};
