import { Divider, Grid, IconButton, Skeleton, Typography } from 'amn-ui-core';
import React from 'react';
import { PaperCard, Title } from '../styles';
import { useTranslation } from 'react-i18next';
import { usePreferenceCardStyles } from './utils';
import { usePromiseTracker } from 'react-promise-tracker';
import EditIcon from '@mui/icons-material/EditOutlined';
import { missingField } from 'app/constants';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { candidateDetailsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';

function PreferencesCardUI({ setEditOpen }) {
  const { t } = useTranslation();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const { classes } = usePreferenceCardStyles();
  const { promiseInProgress } = usePromiseTracker({ area: 'get-candidate-professional-profile-tab-details', delay: 0 });
  return (
    <PaperCard elevation={0} className={classes.paperRoot} data-testid="preferences-profile">
      {promiseInProgress ? (
        <Grid item mr={2}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Grid>
      ) : (
        <>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            className={classes.gridContainer}
          >
            <Grid item xs={6} paddingTop="0px !important">
              <Title>{t('candidate.preferenceCard.title')}</Title>
            </Grid>
            <Grid item xs={6} paddingTop="0px !important" className={classes.editIcon}>
              <Grid container justifyContent={'flex-end'} spacing={0}>
                <Grid item>
                  <IconButton
                    color="primary"
                    onClick={e => setEditOpen(true)}
                    id="edit-preference-personal-profile"
                    sx={{ mr: '12px', color: 'grey', marginRight: 0 }}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ mb: '10px' }} />
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            spacing={2}
            className={classes.itemContainer}
          >
            <Grid item>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h6">{t('candidate.preferenceCard.housingInfo')}</Typography>
                </Grid>
                <Grid item alignSelf="center">
                  <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                      <Typography className={classes.lastUpdatedTitle}>
                        {t('candidate.preferenceCard.lastUpdated')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.lastUpdated}>
                        {candidateDetails.preferences?.housingInfoUpdated
                          ? moment(candidateDetails.preferences?.housingInfoUpdated).format('MM/DD/YYYY')
                          : missingField}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container spacing={1} direction="row" xs={12}>
              <Grid item xs={6}>
                <Typography className={classes.title}>{t('candidate.preferenceCard.housingType')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.data}>
                  {candidateDetails.preferences?.housingType ?? missingField}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={1} direction="row" xs={12}>
              <Grid item xs={6}>
                <Typography className={classes.title}>{t('candidate.preferenceCard.furnitureExtras')}</Typography>
              </Grid>
              <Grid item>
                {candidateDetails.preferences?.furnitureExtras?.length > 0 ? (
                  <Grid container direction="column">
                    {candidateDetails.preferences?.furnitureExtras.map((item, index) => (
                      <Grid item key={index}>
                        <Typography className={classes.data}>{item.description}</Typography>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography className={classes.data}>{missingField}</Typography>
                )}
              </Grid>
            </Grid>
            <Grid item container spacing={1} direction="row" xs={12}>
              <Grid item xs={6}>
                <Typography className={classes.title}>{t('candidate.preferenceCard.typeOfBed')}</Typography>
              </Grid>
              <Grid item xs={6}>
                {candidateDetails.preferences?.typeOfBeds?.length > 0 ? (
                  <Grid container direction="column">
                    {candidateDetails.preferences?.typeOfBeds.map((item, index) => (
                      <Grid item key={index}>
                        <Typography className={classes.data}>{item.description}</Typography>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography className={classes.data}>{missingField}</Typography>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">{t('candidate.preferenceCard.candidateInfo')}</Typography>
              </Grid>
              <Grid item alignSelf="center">
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Typography className={classes.lastUpdatedTitle}>
                      {t('candidate.preferenceCard.lastUpdated')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.lastUpdated}>
                      {candidateDetails.preferences?.travelsWithUpdated
                        ? moment(candidateDetails.preferences?.travelsWithUpdated).format('MM/DD/YYYY')
                        : missingField}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container spacing={1} direction="row" xs={12}>
              <Grid item xs={6}>
                <Typography className={classes.title}>{t('candidate.preferenceCard.keyFacts')}</Typography>
              </Grid>
              <Grid item xs={6}>
                {candidateDetails.preferences?.keyFacts?.length > 0 ? (
                  <Grid container direction="column">
                    {candidateDetails.preferences?.keyFacts.map((item, index) => (
                      <Grid item key={index}>
                        <Typography className={classes.data}>{item.description}</Typography>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography className={classes.data}>{missingField}</Typography>
                )}
              </Grid>
            </Grid>
            <Grid item container spacing={1} direction="row" xs={12}>
              <Grid item xs={6}>
                <Typography className={classes.title}>
                  {candidateDetails.preferences?.adults?.length > 0
                    ? `${t('candidate.preferenceCard.adults')} (${candidateDetails.preferences?.adults?.length})`
                    : t('candidate.preferenceCard.adults')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {candidateDetails.preferences?.adults?.length > 0 ? (
                  <Grid container direction="column">
                    {candidateDetails.preferences?.adults.map((item, index) => (
                      <Grid item key={index}>
                        <Typography className={classes.data}>{`${item.name} (${item.relationship})`}</Typography>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography className={classes.data}>{missingField}</Typography>
                )}
              </Grid>
            </Grid>
            <Grid item container spacing={1} direction="row" xs={12}>
              <Grid item xs={6}>
                <Typography className={classes.title}>
                  {candidateDetails.preferences?.children?.length > 0
                    ? `${t('candidate.preferenceCard.children')} (${candidateDetails.preferences?.children?.length})`
                    : t('candidate.preferenceCard.children')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {candidateDetails.preferences?.children?.length > 0 ? (
                  <Grid container direction="column">
                    {candidateDetails.preferences?.children.map((item, index) => (
                      <Grid item key={index}>
                        <Typography className={classes.data}>{`${item.gender} ${
                          item.birthYear ? `(${new Date().getFullYear() - parseInt(item.birthYear.toString())}yrs)` : ''
                        }`}</Typography>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography className={classes.data}>{missingField}</Typography>
                )}
              </Grid>
            </Grid>
            <Grid item container spacing={1} direction="row" xs={12}>
              <Grid item xs={6}>
                <Typography className={classes.title}>
                  {candidateDetails.preferences?.pets?.length > 0
                    ? `${t('candidate.preferenceCard.pets')} (${candidateDetails.preferences?.pets?.length})`
                    : t('candidate.preferenceCard.pets')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {candidateDetails.preferences?.pets?.length > 0 ? (
                  <Grid container direction="column">
                    {candidateDetails.preferences?.pets.map((item, index) => (
                      <Grid item key={index}>
                        <Typography className={classes.data}>
                          {`${item.type}: ${item.breed}${item.weight ? ` (${item.weight}lbs)` : ''}${
                            item.details ? ` - ${item.details}` : ''
                          }`}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography className={classes.data}>{missingField}</Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </PaperCard>
  );
}

export default PreferencesCardUI;
