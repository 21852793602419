import { Tooltip } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { CapitalizeEveryFirstLetter } from 'utils/string/string';

const useStyles = makeStyles()(theme =>
  ({
    tooltipArrow: {
      color: '#fff',
      '&::before': {
        border: '1px solid #555555',
      },
    },
    tooltipBackground: {
      backgroundColor: '#fff',
      color: '#555555',
      border: '1px solid #555555',
      fontSize: '12px',
    },
  }));

const StyledTooltip = ({ title, children, ...props }) => {
  const { classes } = useStyles();
  return (
    <Tooltip
      {...props}
      title={title}
      placement="bottom"
      arrow
      classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
    >
      {children}
    </Tooltip>
  );
};

const MAX_LENGTH = 15;
export function ToasterHeader(name: string, travelerId: string, stringLength = MAX_LENGTH) {
  const concatenatedTitle = `${CapitalizeEveryFirstLetter(name?.toLowerCase())} (${travelerId})`;
  const updatedTitle =
    name?.length < stringLength
      ? concatenatedTitle
      : name
      ? `${CapitalizeEveryFirstLetter(name.toLowerCase()).slice(0, stringLength)}... (${travelerId || ''}) `
      : '';
  return (
    <>
      {name?.length < stringLength ? (
        concatenatedTitle
      ) : (
        <StyledTooltip title={concatenatedTitle}>
          <span>{updatedTitle}</span>
        </StyledTooltip>
      )}
    </>
  );
}
