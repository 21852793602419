import { Employment } from 'app/models/Candidate/CandidateProfile';
import _, { toInteger } from 'lodash';
import { cloneDeep } from 'lodash';
import moment from 'moment';

export const getGender = (gender: string) => {
  switch (gender) {
    case 'NonBinary':
      return 'Non-Binary';
    case 'DeclineToState':
      return 'Decline-To-State';
    default:
      return gender;
  }
};

export enum CandidateConsents {
  Confirmed = 'Confirmed',
  Withdrawn = 'Withdrawn',
  NotGiven = 'NotGiven'
}

export const calculateExperienceOverlap = (employmentData: Employment[], candidateRes) => {
  let tempData = _.cloneDeep(candidateRes);
  let totalOverlapInYears = 0;
  let totalOverlapInMonths = 0;
  let tempEmploymentData = employmentData;
  let indexArr = [];
  tempEmploymentData?.forEach((x, xId) => {
    indexArr.push(xId);
    let tempExp = null;
    tempEmploymentData?.forEach((y, yId) => {
      if (tempExp == null) tempExp = x;
      let startDate = new Date(tempExp.startedOn);
      let endDate = tempExp.endedOn ? new Date(tempExp.endedOn) : new Date();
      if (yId > xId) {
        let overlapStartDate = new Date(y.startedOn);
        let overlapEndDate = y.endedOn ? new Date(y.endedOn) : new Date();
        if (
          overlapStartDate.getTime() >= startDate.getTime() &&
          overlapStartDate.getTime() < endDate.getTime() &&
          overlapEndDate.getTime() <= endDate.getTime() &&
          (y.disciplineId !== x.disciplineId || y.specialtyId !== x.specialtyId) &&
          !indexArr.includes(yId)
        ) {
          tempExp = { ...x, startedOn: startDate, endedOn: endDate };
          indexArr.push(yId);
          totalOverlapInYears += Math.round(
            moment(y.endedOn).diff(moment(y.startedOn), 'year', true) > 1
              ? moment(y.endedOn).diff(moment(y.startedOn), 'year', true)
              : 0,
          );
          totalOverlapInMonths += Math.round(
            moment(y.endedOn || new Date()).diff(moment(y.startedOn), 'month', true) % 12,
          );
          if (totalOverlapInMonths > 11) {
            totalOverlapInYears++;
            totalOverlapInMonths = 0;
          }
          tempEmploymentData.splice(yId, 1);
        }
        if (
          overlapStartDate.getTime() > startDate.getTime() &&
          overlapStartDate.getTime() < endDate.getTime() &&
          overlapEndDate.getTime() > endDate.getTime() &&
          (y.disciplineId !== x.disciplineId || y.specialtyId !== x.specialtyId) &&
          !indexArr.includes(yId)
        ) {
          tempExp = { ...x, startedOn: startDate, endedOn: overlapEndDate };
          indexArr.push(yId);
          totalOverlapInYears += Math.round(
            moment(x.endedOn).diff(moment(y.startedOn), 'year', true) > 1
              ? moment(x.endedOn).diff(moment(y.startedOn), 'year', true)
              : 0,
          );
          totalOverlapInMonths += Math.round(
            moment(x.endedOn || new Date()).diff(moment(y.startedOn), 'month', true) % 12,
          );
          if (totalOverlapInMonths > 11) {
            totalOverlapInYears++;
            totalOverlapInMonths = 0;
          }
          tempEmploymentData.splice(yId, 1);
        }
        if (
          overlapStartDate.getTime() < startDate.getTime() &&
          overlapEndDate.getTime() > startDate.getTime() &&
          overlapEndDate.getTime() < endDate.getTime() &&
          (y.disciplineId !== x.disciplineId || y.specialtyId !== x.specialtyId) &&
          !indexArr.includes(yId)
        ) {
          tempExp = { ...x, startedOn: overlapStartDate, endedOn: endDate };
          indexArr.push(yId);
          totalOverlapInYears += Math.round(
            moment(y.endedOn).diff(moment(x.startedOn), 'year', true) > 1
              ? moment(y.endedOn).diff(moment(x.startedOn), 'year', true)
              : 0,
          );
          totalOverlapInMonths += Math.round(
            moment(y.endedOn || new Date()).diff(moment(x.startedOn), 'month', true) % 12,
          );
          if (totalOverlapInMonths > 11) {
            totalOverlapInYears++;
            totalOverlapInMonths = 0;
          }
          tempEmploymentData.splice(yId, 1);
        }
        if (
          overlapStartDate.getTime() <= startDate.getTime() &&
          overlapEndDate.getTime() >= endDate.getTime() &&
          (y.disciplineId !== x.disciplineId ||
            y.specialtyId !== x.specialtyId) &&
          !indexArr.includes(yId)
        ) {
          tempExp = { ...x, startedOn: overlapStartDate, endedOn: overlapEndDate };
          indexArr.push(yId);
          totalOverlapInYears += Math.round(
            moment(x.endedOn).diff(moment(x.startedOn), 'year', true) > 1
              ? moment(x.endedOn).diff(moment(x.startedOn), 'year', true)
              : 0,
          );
          totalOverlapInMonths += Math.round(
            moment(x.endedOn || new Date()).diff(moment(x.startedOn), 'month', true) % 12,
          );
          if (totalOverlapInMonths > 11) {
            totalOverlapInYears++;
            totalOverlapInMonths = 0;
          }
          tempEmploymentData.splice(yId, 1);
        }
      }
    });
  });
  let tempMonths;
  let tempYears;
  if (totalOverlapInMonths > tempData.experience.totalMonthsOfExperience) {
    tempYears = tempData.experience.totalYearsOfExperience - totalOverlapInYears - 1;
    tempMonths = 12 - totalOverlapInMonths;
  } else {
    tempYears = tempData.experience.totalYearsOfExperience - totalOverlapInYears;
    tempMonths = tempData.experience.totalMonthsOfExperience - totalOverlapInMonths;
  }
  tempData.experience.totalYearsOfExperience = tempYears;
  tempData.experience.totalMonthsOfExperience = tempMonths;
  return tempData;
};

export const checkCandidatesConsents = (data, CandidateBrandId, CandidateId) => {
  const brandConsent = data?.brandConsents?.find(item => item?.brandId == CandidateBrandId);
  const hasEmailConsents = data?.globalConsents?.emails !== CandidateConsents.Withdrawn ? brandConsent?.emailConsents ? brandConsent?.emailConsents?.every(consent => consent?.emails !== 'Withdrawn') : true : false;
  const hasCallConsents = data?.globalConsents?.calls !== CandidateConsents.Withdrawn ? brandConsent?.phoneConsents ? brandConsent?.phoneConsents?.every(consent => consent?.calls !== 'Withdrawn') : true : false;
  const hasTextConcents = data?.globalConsents?.texts !== CandidateConsents.Withdrawn ? brandConsent?.phoneConsents ? brandConsent?.phoneConsents?.every(consent => consent?.texts !== 'Withdrawn') : true : false;
  const withDrawnEmailConsents = brandConsent?.emailConsents?.reduce((accumulator, consent) =>
    consent?.emails === 'Withdrawn' ? [...accumulator, consent?.emailAddress] : accumulator, []) || [];
  const withDrawnCallsConsents = brandConsent?.phoneConsents?.reduce((accumulator, consent) =>
    consent?.calls === 'Withdrawn' ? [...accumulator, consent?.phoneNumber] : accumulator, []) || [];
  const withDrawnTextsConsents = brandConsent?.phoneConsents?.reduce((accumulator, consent) =>
    consent?.texts === 'Withdrawn' ? [...accumulator, consent?.phoneNumber] : accumulator, []) || [];

  // Note : Value true indicates allowed 
  return {
    candidateId: data?.contactId || CandidateId,
    brandId: CandidateBrandId,
    globalConsents: {
      emails: data?.globalConsents?.emails !== CandidateConsents.Withdrawn,
      texts: data?.globalConsents?.texts !== CandidateConsents.Withdrawn,
      calls: data?.globalConsents?.calls !== CandidateConsents.Withdrawn,
    },
    // The below values are calculated on the basis of a single brandId (with the global consents into consideration) 
    hasEmailConsents,
    hasCallConsents,
    hasTextConcents,
    withDrawnEmailConsents,
    withDrawnCallsConsents,
    withDrawnTextsConsents
  }
}
