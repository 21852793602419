import { contactPreferenceChoice, IClientContactDetails } from 'app/models/ClientContact/ClientContact';
import { formatActionValues, formatUnitValues } from './FormatData';

export const formatPhoneNumber = phoneNumber => {
  if (phoneNumber) {
    if (phoneNumber.length !== 10) {
      return phoneNumber;
    }
    // Format the string into (XXX) XXX-XXXX
    const formattedNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;

    return formattedNumber;
  }
};

export const formatUpdateClientContactDetails = (
  clientContactData,
  formData,
  facilityDetails,
  contactStatusChoice,
  actionValues,
  selectedUnits,
  selectedClientContactDetails: IClientContactDetails,
) => {
  const phoneNumberPattern = /^1\(\d{3}\) \d{3}-\d{4}$/;
  const phoneNumber =
    formData?.phoneNumber?.length === 10
      ? formData?.phoneNumber
      : phoneNumberPattern.test(formData?.phoneNumber)
      ? formData?.phoneNumber?.replace(/\D/g, '')
      : null;
  clientContactData.phoneNumbers =
    selectedClientContactDetails?.phoneNumbers?.length > 0 && phoneNumber
      ? [
          {
            countryDialingCode: selectedClientContactDetails?.phoneNumbers[0]?.countryDialingCode,
            extensionNotes: selectedClientContactDetails?.phoneNumbers[0]?.extensionNotes,
            phoneNumber: formatPhoneNumber(phoneNumber?.substring(phoneNumber?.length - 10)),
            phoneTypeId: 6,
            timestamp: selectedClientContactDetails?.phoneNumbers[0]?.timestamp,
          },
        ]
      : [
          {
            countryDialingCode: '1',
            extensionNotes: 'note',
            phoneNumber: formatPhoneNumber(clientContactData?.phoneNumber),
            phoneTypeId: 6,
          },
        ];
  clientContactData.facilityID = facilityDetails.facilityId;
  clientContactData.contactStatusId = contactStatusChoice.find(s => s.Description === formData?.contactStatus).Id;
  clientContactData.doNotCall = clientContactData?.contactPreferenceTypeId === 3;
  clientContactData.contactPreference = contactPreferenceChoice?.find(
    x => x.Id === clientContactData?.contactPreferenceTypeId,
  )?.Description;
  clientContactData.actions = formatActionValues(actionValues);
  clientContactData.units = formatUnitValues(selectedUnits);
  clientContactData.timestamp = selectedClientContactDetails?.timestamp;
};
