import { placementStatusAction } from 'store/redux-store/placement-status/slice';
import { IPlacementRequirementKey, IPlacementStatusRequirementMessage } from 'app/models/Placement/PlacementDetails';
import {
  IPlacementEditableRequirementDeps,
  IPlacementEditableRequirements,
  requirementType,
} from 'app/models/Placement/PlacementSummary';
import React from 'react';
import { useDispatch } from 'react-redux';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import { useEditRequirementsHelper, useStatusForRequirement } from './EditRequirementsHelper';

/**
 * Custom hook to automatically remove and apply pass/fail/risk requirements
 */
export const useEditableExtensionTimeOffRequirement = <T, P>({
  data,
  secondaryData,
  requirementId,
  requirementKey,
  requirementCheck,
  riskRequirement,
  extensionId,
}: {
  data: T;
  secondaryData?: P;
  requirementId: IPlacementRequirementKey;
  dependencyRequirementIds?: IPlacementRequirementKey[];
  requirementKey: keyof IPlacementEditableRequirements;
  riskRequirement?: IPlacementStatusRequirementMessage;
  requirementCheck: (data, secondaryData?) => IPlacementEditableRequirementDeps;
  // Used for situations where the failed icon needs to show, but the message should not show
  extensionId: number;
}) => {
  const dispatch = useDispatch();
  const { removeExistingRequirementById } = useEditRequirementsHelper();
  const { findStatusForRequirement } = useStatusForRequirement();

  React.useEffect(() => {
    removeExistingRequirementById(requirementId);
    const statusList = findStatusForRequirement(IPlacementRequirementKey.extensionTimeOff);
    if (statusList) {
      const calculatedRequirementType = requirementCheck(data, secondaryData);

      if (calculatedRequirementType.requirement === requirementType.risk && riskRequirement) {
        // RISK
        dispatch(
          placementSummaryActions.setExtensionEditableRequirementTypes({
            extensionId: extensionId,
            requirement: { [requirementKey]: calculatedRequirementType },
          }),
        );
        dispatch(
          placementStatusAction.setWarningRequirement({
            requirement: riskRequirement,
          }),
        );
      } else {
        dispatch(
          placementSummaryActions.setExtensionEditableRequirementTypes({
            extensionId: extensionId,
            requirement: { [requirementKey]: undefined },
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, secondaryData]);
};
