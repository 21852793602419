import { ICustomHeader } from 'app/models/Grid';

export interface IGridColumns extends Omit<ICustomHeader, 'title'> {
  title:
    | 'placementId'
    | 'candidateId'
    | 'candidateName'
    | 'credential'
    | 'daysUntilExpiration'
    | 'expirationDate'
    | 'facilityId'
    | 'facilityName'
    | 'credentialCategory'
    | 'credentialGroup'
    | 'brandId'
    | 'tier'
    | 'requirementId';
}

export const ExpiringCredentialColumns: IGridColumns[] = [
  {
    title: 'placementId',
    value: 'Placement ID (PID)',
    sortable: true,
    clickable: true,
    navigationIds: ['placementId'],
    navigationExp: '/placement/{0}',
    isNumber: true,
  },
  {
    title: 'candidateName',
    value: 'Candidate Name',
    sortable: true,
    sortByField: 'clinicianLastName',
    clickable: true,
    navigationIds: ['candidateId', 'brandId'],
    navigationExp: '/candidate/{0}/{1}',
  },

  {
    title: 'credential',
    sortable: true,
    value: 'Credential(s)',
    clickable: true,
    navigationIds: ['candidateId', 'brandId', 'requirementId'],
    navigationExp: '/candidate/{0}/{1}?tab=2&rid={2}',
  },
  {
    title: 'tier',
    sortable: true,
    value: 'Tier',
  },
  {
    title: 'daysUntilExpiration',
    value: 'Days Until Expiring',
    sortable: true,
    isNumber: true,
  },
  {
    title: 'expirationDate',
    value: 'Expiration Date',
    sortable: true,
    defaultSort: true,
    sortByField: 'expirationDateNoFormat',
  },
  {
    title: 'facilityName',
    sortable: true,
    value: 'Client Name',
    sortByField: 'facilityNameLower',
    clickable: true,
    navigationIds: ['facilityId'],
    navigationExp: '/facility/{0}',
  },
  {
    title: 'credentialCategory',
    sortable: true,
    value: 'Credential(s) Category',
  },
  {
    title: 'credentialGroup',
    sortable: true,
    value: 'Credential(s) Group',
  },
  {
    title: 'candidateId',
    value: 'Candidate (CID)',
    sortable: true,
    clickable: true,
    navigationIds: ['candidateId', 'brandId'],
    navigationExp: '/candidate/{0}/{1}',
    isNumber: true,
  },
];
