import { createSelector } from '@reduxjs/toolkit';
import _isArray from 'lodash/isArray';
import { RootState } from 'types';

import { initialState } from './GridState.redux';

const selectDomain = (state: RootState) => state?.gridState || initialState;

export const selectGridState = createSelector([selectDomain], gridState => gridState);
export const selectColumnVisibilityModel = createSelector([selectDomain], state => state.columnVisibilityModel);
export const selectSortedColumn = createSelector([selectDomain], gridState => gridState.sortedColumn);
export const selectFiterAttributes = createSelector([selectDomain], gridState => gridState.filterAttributes);
export const selectFilters = createSelector([selectDomain], gridState => {
  if (gridState?.filterAttributes) {
    return {
      filters: _isArray(gridState.filterAttributes.filters)
        ? Object.fromEntries(gridState.filterAttributes.filters)
        : gridState.filterAttributes.filters,
      filterSet: gridState.filterAttributes.filterSet,
      dirtyFields: gridState.filterAttributes.dirtyFields,
      selectedChipFilters: gridState.filterAttributes?.selectedChipFilters,
    };
  } else return undefined;
});

export const selectFilterChipSelected = createSelector([selectDomain], gridState =>
  gridState.filterAttributes ? gridState.filterAttributes.selectedChipFilters : {},
);

export const selectedFilterSet = createSelector([selectDomain], gridState => gridState.selectedFilter);

export const selectGridTag = createSelector([selectDomain], gridState => gridState.gridTag);

export const selectGridFiltersExpanded = createSelector([selectDomain], gridState => gridState.filtersExpanded);
