import React, { useMemo } from 'react';
import { Grid, Skeleton } from 'amn-ui-core';
import { usePromiseTracker } from 'react-promise-tracker';
import { DegreeSection } from '../EducationCard';
import { useTranslation } from 'react-i18next';
import { CandidateContainer } from '../../../SummaryTab/Common/CandidateContainer';
import EducationIcon from 'app/assets/images/IconImages/EducationIcon.svg';
import EditIconV2 from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';

function EducationCardUIV2({ handleToggle, setOpenAddPop, educationArray }) {
  const { promiseInProgress } = usePromiseTracker({ area: 'get-candidate-details', delay: 0 });
  const { t } = useTranslation();
  const degreeSections = useMemo(() => {
    return educationArray?.map((edu, key) => {
      return (
        <DegreeSection
          degree={edu.degree}
          major={edu?.major}
          school={edu?.school}
          city={edu.location?.city}
          state={edu?.location?.state}
          country={edu.location?.country}
          graduatedOn={edu.graduatedOn}
          index={key}
          key={key}
        />
      );
    });
  }, [educationArray]);
  return (
    <CandidateContainer
      title={t('candidate.educationCard.title')}
      icon={<img src={EducationIcon} alt="EducationIcon" />}
      rightIcon={<AddIcon />}
      iconToolTip="Add"
      onClick={() => {
        setOpenAddPop(true);
      }}
      editIconToolTip="Edit"
      rightEditIcon={<EditIconV2 />}
      onClickEditIcon={e => {
        handleToggle(e);
      }}
    >
      {promiseInProgress ? (
        <Grid item mr={2}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Grid>
      ) : (
        <Grid container>
          <Grid item container spacing={4}>
            {degreeSections}
          </Grid>
        </Grid>
      )}
    </CandidateContainer>
  );
}

export default EducationCardUIV2;
