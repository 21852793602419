import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import baseAxiosInstance from 'utils/BaseApi';

export class OrderService {
  private orderBase: string;

  private cancellationToken = axios.CancelToken.source();

  private static currentInstance: OrderService;
  config?: AxiosRequestConfig = {};

  private constructor() {
    this.orderBase = globalThis?.app?.env?.REACT_APP_ORDERS_SERVICE_BASE_URL;
  }

  static createInstance(): OrderService {
    const activeInstance = new OrderService();
    // activeInstance.cancellationToken = axios.CancelToken.source();
    // if (activeInstance.config) activeInstance.config.cancelToken = activeInstance.cancellationToken.token;
    return activeInstance;
  }

  static getInstance(): OrderService {
    if (!this.currentInstance) this.currentInstance = this.createInstance();
    return this.currentInstance;
  }

  static cancelActiveRequests() {
    this.currentInstance.cancellationToken.cancel('RequestCancellation');
    // this.currentInstance = null;
  }

  cancelRequests() {
    this.cancellationToken.cancel('RequestCancellation');
    return OrderService.getInstance();
  }

  cancelAndRevokeInstance() {
    this.cancellationToken.cancel('RequestCancellation');
    this.cancellationToken = axios.CancelToken.source();
    if (this.config) this.config.cancelToken = this.cancellationToken.token;
  }

  getOrderForPlacementCreation = (orderId: number) =>
    baseAxiosInstance.get(`${this.orderBase}/order/${orderId}/placement-modal`);

  getOrderDetailsById = (orderId: number) => baseAxiosInstance.get(`${this.orderBase}/order/${orderId}`);

  updateOrderAutomationConfig = body =>
    baseAxiosInstance
      .post(`${this.orderBase}/order/placement-automation/update/external-systems`, body)
      .catch((error: AxiosError) => error.response);

  getContractDetails = body =>
    baseAxiosInstance
      .post(`${this.orderBase}/order/facility/bill-rate`, body)
      .catch((error: AxiosError) => error.response);

  getOrderRateElements = (orderId: number) =>
    baseAxiosInstance.get(`${this.orderBase}/order/${orderId}/order-rate-element`);

  getEditableRateElements = (orderId: number) =>
    baseAxiosInstance
      .get(`${this.orderBase}/order/${orderId}/grouped-rate-element`)
      .catch((error: AxiosError) => error.response);

  getOrderBonuses = body =>
    baseAxiosInstance.post(`${this.orderBase}/order/order-bonus`, body).catch((error: AxiosError) => error.response);

  updateOrderSkillsets = body =>
    baseAxiosInstance
      .post(`${this.orderBase}/order/update-skillsets`, body)
      .catch((error: AxiosError) => error.response);

  updateOrderShifts = body =>
    baseAxiosInstance.post(`${this.orderBase}/order/update-shifts`, body).catch((error: AxiosError) => error.response);

  getOpenOrdersCountForFacility = (facilityId: number) =>
    baseAxiosInstance
      .get(`${this.orderBase}/order/facility/${facilityId}/open-order-count`)
      .catch((error: AxiosError) => error.response);

  getExistingOrdersForUnit = (unitId: number, facilityId: number) =>
    baseAxiosInstance
      .get(`${this.orderBase}/order/placement-automation/${facilityId}/${unitId}/order-preferences`)
      .catch((error: AxiosError) => error.response);

  updatePreferencesToExistingOrders = body =>
    baseAxiosInstance
      .post(`${this.orderBase}/order/placement-automation/copy-unit-preferences`, body)
      .catch((error: AxiosError) => error.response);

  getOrderBonusList = (orderId: number) =>
    baseAxiosInstance.get(`${this.orderBase}/order-bonus/${orderId}`).catch((error: AxiosError) => error.response);

  getOrderBonusShiftList = (orderId: number, bonusId: number) =>
    baseAxiosInstance
      .get(`${this.orderBase}/order-bonus/${orderId}/order-bonus-shift/${bonusId}`)
      .catch((error: AxiosError) => error.response);

  getOrderBonusCalculatedAmount = (orderId: number, amount: number, type: string) =>
    baseAxiosInstance
      .get(`${this.orderBase}/order-bonus/${orderId}/billrate?billamount=${amount}&type=${type}`)
      .catch((error: AxiosError) => error.response);

  saveOrderBonus = body =>
    baseAxiosInstance.post(`${this.orderBase}/order-bonus/save`, body).catch((error: AxiosError) => error.response);

  copyOrderBonus = body =>
    baseAxiosInstance.post(`${this.orderBase}/order-bonus/copy`, body).catch((error: AxiosError) => error.response);

  deleteOrderBonus = body =>
    baseAxiosInstance
      .delete(`${this.orderBase}/order-bonus`, { data: body })
      .catch((error: AxiosError) => error.response);

  saveOrderBonusShifts = (orderId: number, bonusId: number, shiftIds: number[]) =>
    baseAxiosInstance
      .post(`${this.orderBase}/order-bonus/save-order-bonus-shift`, { orderId, bonusId, shiftIds })
      .catch((error: AxiosError) => error.response);

  deleteOrderBonusShifts = body =>
    baseAxiosInstance
      .delete(`${this.orderBase}/order-bonus/delete-order-bonus-shift`, { data: body })
      .catch((error: AxiosError) => error.response);

  getOrderBonusChangeHistory = (orderId: string, bonusId: string) =>
    baseAxiosInstance
      .get(`${this.orderBase}/order-bonus/status-change-history/${orderId}/${bonusId}`)
      .catch((error: AxiosError) => error.response);

  getPlacementsForAutomationOfOrders = (orderIds: Array<number>, activityStatus: number) =>
    baseAxiosInstance
      .post(`${this.orderBase}/order/placement-automation/orders-automation-placements`, { orderIds, activityStatus })
      .catch((error: AxiosError) => error.response);

  getActiveUnitPlacementCounts = (orderIds: Array<number>) =>
    baseAxiosInstance
      .post(`${this.orderBase}/order/active-placement-counts`, { orderIds })
      .catch((error: AxiosError) => error.response);
}
