import React, { useState } from 'react';
import { Accordion, Grid, Typography, AccordionSummary, AccordionDetails, Box, CircularProgress } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';
import Comment from './Comment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CommentForm from './CommentForm';
import ChatIcon from '@mui/icons-material/Chat';
import { makeStyles } from 'tss-react/mui';
import { usePromiseTracker } from 'react-promise-tracker';
const useStyles = makeStyles()(theme => ({
  accordionSummary: {
    minHeight: 36,
    '& .MuiAccordionSummary-content': {
      margin: '9px 0',
    },
  },
  validationLoader: {
    position: 'absolute',
    zIndex: 999,
    left: 'calc(50% - 20px)',
    top: '50%',
  },
}));

const Comments = props => {
  const { promiseInProgress: isCallingAPI } = usePromiseTracker({ area: 'save-task-detail-comment', delay: 0 });
  const { taskComments } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(true);

  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <Box sx={{ marginLeft: '36px', marginTop: '16px' }}>
      {isCallingAPI && <CircularProgress className={classes.validationLoader} />}
      <Accordion
        elevation={0}
        expanded={expanded}
        onChange={(e, isExpanded) => handleChange(e, isExpanded)}
        disableGutters
        sx={{
          backgroundColor: theme => theme.palette.components.accordion.secondary.backgroundColor,
        }}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon sx={{ color: theme => theme.palette.system.main }} />}
          aria-controls="task-comments"
          id={`task-comments`}
        >
          <ChatIcon />
          <Typography
            color={theme => theme.palette.components.typography.color}
            fontWeight={'medium'}
            sx={{ paddingLeft: '8px' }}
          >
            {`${t('notification.taskDetails.comments')} (${taskComments?.length ?? 0})`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            xs={12}
            sx={{
              maxHeight: '150px',
              overflowY: 'scroll',
              flex: '1',
            }}
          >
            {taskComments &&
              taskComments?.length > 0 &&
              taskComments?.map((comment, index) => {
                return <Comment key={index} comment={comment} />;
              })}
          </Grid>
          <Grid xs={12}>
            <CommentForm key={taskComments?.length} />
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Comments;
