import React from 'react';
import { useTranslation } from 'react-i18next';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { CoverageAction, IUser } from 'app/models/Tasks/Tasks';

export const CoverageConfirmationModal = ({
    openDialog,
    handleDecision,
    action,
    coveredUser,
  }: {
    openDialog: boolean;
    handleDecision: (e) => void;
    action: CoverageAction;
    coveredUser?: IUser;
}) => {
  const { t } = useTranslation();
  const title = t('coverageConfirmation.hsgTitle');
  
  const generateModalMessage = () => {
    let text = '';
    const _hsgId = 6000;
    if (coveredUser?.id === _hsgId) {
        let status = '';
        if (action === CoverageAction.UPDATE_STATUS_COMPLETE)
            status = 'complete';
        else if (action === CoverageAction.UPDATE_STATUS_CANCEL) 
            status = 'cancel';
        else if (action === CoverageAction.REVERT_STATUS) 
            status = 'revert';
        else
            status = 'edit';
        text = t('coverageConfirmation.hsgPrompt.1') + status + t('coverageConfirmation.hsgPrompt.2');
    } else {
        text = t('coverageConfirmation.prompt.1') + 
            action + 
            t('coverageConfirmation.prompt.2') + 
            coveredUser?.firstName + coveredUser?.lastName + 
            t('coverageConfirmation.prompt.3');
    }
    return text;
  };
  
  return (
    <GenericDialog
      open={openDialog}
      maxWidth="xs"
      dialogTitleProps={{ text: title }}
      dialogContentProps={{
        variant: 'text',
      }}
      dialogActions={[
        {
          text: t('coverageConfirmation.cancel'),
          onClick: () => handleDecision(false),
          color: 'tertiary',
        },
        {
          text: t('coverageConfirmation.confirm'),
          onClick: () => handleDecision(true),
          variant: 'contained',
        },
      ]}
    >
      {generateModalMessage()}
    </GenericDialog>
  );
};