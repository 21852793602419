import { Box, Grid, Typography } from 'amn-ui-core';
import { TaskNotificationType } from 'app/models/Tasks/Tasks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SubTaskIcon from 'app/assets/images/Tasks/subtask-notification.svg';
import TaskCreatedIconDisabled from 'app/assets/images/Tasks/SubTaskDisabled.svg';
import { TextWithEllipses } from '@AMIEWEB/Notification/Common/StringHelper';

export const SubTaskPopupContext = ({
  NotificationBody,
  classes,
  associatedRecords,
  showActionPanel,
  isUnread
}: {
  NotificationBody: any;
  classes: any;
  associatedRecords: any;
  showActionPanel: boolean;
  isUnread: boolean;
}) => {
  const { t } = useTranslation();
  let Maxlength
  if (showActionPanel) {
    Maxlength = 12;
  }
  else {
    Maxlength = 25;
  }
  return (
    <React.Fragment>
      <Grid container direction="row">
        <Grid item xs={1}>
          <div className={classes.statusIcon}>
            <img src={isUnread ? SubTaskIcon : TaskCreatedIconDisabled} />
          </div>
        </Grid>
        <Grid item xs={10} style={{ paddingLeft: '12px', position: 'relative', zIndex: '1' }}>
          <div className={classes.flexContainer}>
            {parseInt(NotificationBody?.useSubType) === TaskNotificationType.TASKCREATED && (
              <Grid item xs={12}>
                <Box display={'flex'}>
                  <Typography className={!isUnread ? classes.mainTitleRead : classes.mainTitle}>{t('notification.taskNotification.newSubTask')}</Typography>
                </Box>
                <Box display={'flex'}>
                  <Typography className={!isUnread ? classes.secondaryTitleRead : classes.secondaryTitle}>
                    {TextWithEllipses(associatedRecords?.PrimaryContext, Maxlength)}
                    <span className={classes.spanText}>&nbsp;{'(' + associatedRecords?.EntityId + ')'}</span>
                  </Typography>
                </Box>
              </Grid>
            )}
            {parseInt(NotificationBody?.useSubType) === TaskNotificationType.COMPLETED && (
              <Grid item xs={12}>
                <Box display={'flex'}>
                  <Typography className={!isUnread ? classes.mainTitleRead : classes.mainTitle}>
                    {t('notification.subTaskNotification.subtaskcompleted')}
                  </Typography>
                </Box>
                <Box display={'flex'}>
                  <Typography className={!isUnread ? classes.secondaryTitleRead : classes.secondaryTitle}>
                    {TextWithEllipses(associatedRecords?.PrimaryContext, Maxlength)}
                    <span className={classes.spanText}>&nbsp;{'(' + associatedRecords?.EntityId + ')'}</span>
                  </Typography>
                </Box>
              </Grid>
            )}
            {parseInt(NotificationBody?.useSubType) === TaskNotificationType.CANCELLED && (
              <Grid item xs={12}>
                <Box display={'flex'}>
                  <Typography className={!isUnread ? classes.mainTitleRead : classes.mainTitle}>
                    {t('notification.subTaskNotification.subtaskcancelled')}
                  </Typography>
                </Box>
                <Box display={'flex'}>
                  <Typography className={!isUnread ? classes.secondaryTitleRead : classes.secondaryTitle}>
                    {TextWithEllipses(associatedRecords?.PrimaryContext, Maxlength)}
                    <span className={classes.spanText}>&nbsp;{'(' + associatedRecords?.EntityId + ')'}</span>
                  </Typography>
                </Box>
              </Grid>
            )}
          </div>
        </Grid>
        <div className={isUnread ? classes.taskDescriptionunbold : classes.taskDescription}>
          <div className={classes.truncateDescription}>{associatedRecords?.Description}</div>
        </div>
      </Grid>
    </React.Fragment>
  );
};
