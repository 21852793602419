import {
  Box,
  Checkbox,
  Grid,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from 'amn-ui-core';
import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import SearchIcon from '@mui/icons-material/Search';
import { ChipsContainer } from 'app/ComponentLibrary/Filter';
import { InfoIconToolTip } from './InfoIconToolTip';
import { Loader } from './Loader';
const useStyles = makeStyles()(theme => ({
  subtitle1: {
    fontSize: '14px',
    fontWeight: 500,
  },
  disabledGrid: {
    pointerEvents: 'none',
    filter: 'blur(0.5px)',
    opacity: 0.7,
  },
  left: {
    width: '100%',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.system.lightGrey}`,
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: 'calc(100% - 37px)',
    marginRight: '4px',
  },
  input: {
    width: '100%',
    padding: '2%',
    backgroundColor: 'white',
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  dropdown: {
    flexWrap: 'nowrap',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiListItemIcon-root': {
      minWidth: 20,
      marginRight: theme.spacing(1),
    },
  },
  inputCheckBox: {
    height: '20px',
    color: theme.palette.system.neutralGray,
  },
  rolesText: {
    fontSize: '14px',
    color: theme.palette.components.typography.color,
  },
  subtitle2: {
    fontSize: '14px',
    fontWeight: 500,
  },
  right: {
    width: '100%',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.system.lightGrey}`,
    height: 'calc(100% - 37px)',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    flexDirection: 'row',
    padding: '8px',
    marginLeft: '4px',
  },
  loader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loaderText: {
    marginTop: '10px',
    fontWeight: 500,
  },
  infoIconContainer: {
    marginTop: '2px',
    marginLeft: '5px',
  },
}));

export const ListSelector = ({
  list,
  selectedValues,
  handleSelection,
  listTitle,
  chipsGroupTitile,
  toolTipContent,
  isDisabled = false,
  helperText = '',
  optionsListLoader = false,
  chipsGroupLoader = false,
  isSelectAllReq = false,
}) => {
  const { classes } = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedVals, setSelectedVals] = useState(selectedValues);
  const [options, setOptions] = useState(list);
  useEffect(() => {
    if (isSelectAllReq && list?.length === selectedValues?.length && !!list.length) {
      setSelectedVals([
        {
          id: '-1',
          description: 'SELECT ALL',
        },
        ...selectedValues,
      ]);
    } else {
      setSelectedVals(selectedValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues]);

  useEffect(() => {
    if (isSelectAllReq)
      setOptions([
        {
          id: '-1',
          description: 'SELECT ALL',
        },
        ...list,
      ]);
    else setOptions(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  /**Checkbox selection/deselection */
  const handleClick = selectedItem => {
    let updatedCheckedItems;
    if (isSelectAllReq && selectedItem.id === '-1') {
      //*check if already all items selected 
      const isRoleChecked = selectedVals?.some(item => item.id === selectedItem.id);

      if (isRoleChecked) {
        //unselect all items
        updatedCheckedItems = [];
      } else {
        updatedCheckedItems = options;
      }
    } else {
      const isRoleChecked = selectedVals?.some(item => item.id === selectedItem.id);
      if (isRoleChecked) {
        updatedCheckedItems = selectedVals.filter(item => item.id !== selectedItem.id);
      } else {
        updatedCheckedItems = [...selectedVals, selectedItem];
      }
      //**remove selectAll if it is there in selected list
      updatedCheckedItems = updatedCheckedItems.filter(item => item.id !== '-1');
    }

    setSelectedVals(updatedCheckedItems);
    handleSelection(isSelectAllReq ? updatedCheckedItems.filter(item => item.id !== '-1') : updatedCheckedItems);
  };

  const handleDelete = item => {
    const updatedCheckedItems = selectedVals.filter(sel => sel.id !== item.value && sel.id !== '-1');
    handleSelection(updatedCheckedItems);
  };
  return (
    <Grid container sx={{ height: '100%', flexWrap: 'wrap' }}>
      <Grid item sx={{ flex: 1, height: '100%' }}>
        <Grid container sx={{ height: '100%' }}>
          <Grid item>
            <Typography className={classes.subtitle1} variant="subtitle1">
              {listTitle}
            </Typography>
          </Grid>
          <Grid item classes={{ root: classes.infoIconContainer }}>
            {toolTipContent?.messageOne && <InfoIconToolTip toolTipContent={toolTipContent.messageOne} />}
          </Grid>
          <Grid container className={classes.left}>
            {optionsListLoader ? (
              <Loader />
            ) : (
              <>
                <Box component="form" noValidate autoComplete="off">
                  <Input
                    id="standard-basic"
                    placeholder="Search"
                    onChange={e => setSearchTerm(e.target.value)}
                    className={classes.input}
                    disabled={isDisabled}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                </Box>
                <Grid item spacing={1} className={`${classes.dropdown}`}>
                  <List dense>
                    {options
                      .filter(role => role.description.toLowerCase().includes(searchTerm.toLowerCase()))
                      .map(role => {
                        return (
                          <ListItem
                            key={role.id}
                            className={classes.listItem}
                            button
                            onClick={() => handleClick(role)}
                            disabled={isDisabled}
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={(selectedVals || [])?.some(sel => sel?.id === role?.id)}
                                className={classes.inputCheckBox}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body1" className={classes.rolesText}>
                                  {role.description}
                                </Typography>
                              }
                            />
                          </ListItem>
                        );
                      })}
                  </List>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ flex: 1, height: '100%' }}>
        <Grid container sx={{ height: '100%' }}>
          <Grid item>
            <Typography className={classes.subtitle2} variant="subtitle2">
              {chipsGroupTitile}
            </Typography>
          </Grid>
          <Grid item classes={{ root: classes.infoIconContainer }}>
            {toolTipContent?.messageTwo && <InfoIconToolTip toolTipContent={toolTipContent.messageTwo} />}
          </Grid>
          <Grid container className={`${classes.right} ${isDisabled ? classes.disabledGrid : ''}`}>
            {chipsGroupLoader ? (
              <Loader />
            ) : selectedVals?.length <= 0 ? (
              <div className={classes.loader}>
                <Typography className={classes.loaderText}>{helperText}</Typography>
              </div>
            ) : (
              <ChipsContainer
                maxHeight={'100%'}
                chips={
                  (isSelectAllReq ? selectedVals.filter(item => item.id !== '-1') || [] : selectedVals || [])?.map(
                    item => {
                      if (item && item.description) {
                        return { label: item.description, value: item.id, enableDelete: true };
                      }
                      return null;
                    },
                  ) ?? []
                }
                onDelete={(key, chipItem) => handleDelete(chipItem)}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
