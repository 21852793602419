export enum ComparisionTableColumnTypes {
    detailsCell = 'detailsCell',
    referenceCell = 'referenceCell',
    comparisionCell = 'comparisionCell',
}

interface IPropertiesTypes {
    name: string;
    displayText: string;
    renderCell?: any;
    renderCellColumn?: ComparisionTableColumnTypes;
    height: string | number;
    isExpandable?: boolean | undefined;
}

interface IColumnExpandProps {
    isExpanded: boolean;
    height: number | undefined;
}

interface IRowAttributes {
    expanded: boolean | undefined;
    maxHeight: number | undefined;
    cellClicked: any | undefined;
}
export interface IComparisionTableProps {
    height?: number;
    tableRows: Array<IPropertiesTypes>;
    referenceData?: Object;
    tableData: Array<any>;
    width?: number;
    expanded?:boolean;
}

export interface IColumnProps {
    columnData : Object;
    tableRows: Array<IPropertiesTypes>; 
    detailsCell?: boolean;
    referenceCell?: boolean;
    comparisionCell?: boolean;
    width?: number;
    columnType?: ComparisionTableColumnTypes;
    columnExpandProps?: IColumnExpandProps;
    onExpandClick?: Function;
    setRowHeights?: Function;
    rowAttributes?: any;
}

