import { tooltipType } from 'app/components/Common/Tooltips';
import { ICustomHeader } from 'app/models/Grid';
import { PanelChoice } from '../PanelOptions/PanelOption.redux';

interface IGridColumns extends Omit<ICustomHeader, 'title'> {
  title:
    | 'orderId'
    | 'facilityName'
    | 'expectedStartDate'
    | 'weeklyTakeHomePay'
    | 'city'
    | 'state'
    | 'shifts'
    | 'placementLength'
    | 'lastContactedOn'
    | 'disciplineSpecialty'
    | 'milesAway'
    | 'filesInProgress'
    | 'candidateInterestRating'
    | 'candidateOfferRating'
    | 'radiusRules'
    | 'accountManager'
    | 'rank'
    | 'summary'
    | 'predictiveGoodToGoScore';
}

export const getJobMatchGridHeaders = t =>
  [
    {
      value: t('candidate.jobMatchHeaders.rank'),
      title: 'rank',
      sortable: true,
      isNumber: true,
    },
    {
      value: t('candidate.jobMatchHeaders.orderId'),
      title: 'orderId',
      clickable: true,
      sortable: true,
      isNumber: true,
    },
    {
      value: t('candidate.jobMatchHeaders.facilityName'),
      title: 'facilityName',
      clickable: true,
      sortable: true,
      navigationIds: ['facilityId'],
      navigationExp: '/facility/{0}',
      hasHoverView: true,
    },
    { value: t('candidate.jobMatchHeaders.city'), title: 'city', sortable: true },
    { value: t('candidate.jobMatchHeaders.state'), title: 'state', sortable: true },
    {
      value: t('candidate.jobMatchHeaders.expectedStartDate'),
      title: 'expectedStartDate',
      sortable: true,
      sortByField: 'expectedStartDateNoFormat',
    },
    { value: t('candidate.jobMatchHeaders.skillset'), title: 'disciplineSpecialty', sortable: true, multiLine: true },
    { value: t('candidate.jobMatchHeaders.shifts'), title: 'shifts', sortable: true },
    { value: t('candidate.jobMatchHeaders.placementLength'), title: 'placementLength', sortable: true, isNumber: true },
    {
      value: t('candidate.jobMatchHeaders.accountManager'),
      title: 'accountManager',
      sortable: true,
      tooltip: tooltipType.IterableKeyTooltip,
      tooltipAttributes: 'accountManagerAttributes',
    },
    {
      value: t('candidate.jobMatchHeaders.weeklyGrossPay'),
      title: 'weeklyTakeHomePay',
      sortable: true,
      sortByField: 'weeklyTakeHomePayValue',
    },
    { value: t('candidate.jobMatchHeaders.milesAway'), title: 'milesAway', sortable: true, isNumber: true },
    {
      value: t('candidate.jobMatchHeaders.filesInProgress'),
      title: 'filesInProgress',
      sortable: true,
      hasHoverView: true,
      isNumber: true,
    },
    {
      value: t('candidate.jobMatchHeaders.candidateOfferRating'),
      title: 'candidateOfferRating',
      sortable: true,
      sortByField: 'candidateOfferRatingValue',
    },
    {
      value: t('candidate.jobMatchHeaders.candidateInterestRating'),
      title: 'candidateInterestRating',
      sortable: true,
      sortByField: 'candidateInterestRatingValue',
    },
    {
      value: t('candidate.jobMatchHeaders.predictiveGTGRating'),
      title: 'predictiveGoodToGoScore',
      sortable: true,
      sortByField: 'predictiveGoodToGoScoreValue',
    },
    { value: t('candidate.jobMatchHeaders.radiusRules'), title: 'radiusRules', sortable: true, isNumber: true },
    { value: t('candidate.jobMatchHeaders.summaryInfo'), icon: true, title: 'summary', sortable: false },
  ] as IGridColumns[];

export const getNavData = (lastBreadcrumb: string, travelerId: number, brandId: number) => ({
  backButtonURL: `candidate/${travelerId}/${brandId}?panel=${PanelChoice.JobMatchGrid.toLowerCase()}`,
  breadcrumbHierarchy: [
    {
      title: 'Professional Profile',
      order: 1,
      returnURL: `candidate/${travelerId}/${brandId}?tab=1`,
      clickable: true,
    },
    {
      title: 'Job Matches',
      order: 2,
      returnURL: `candidate/${travelerId}/${brandId}?panel=${PanelChoice.JobMatchGrid.toLowerCase()}`,
      clickable: true,
    },
    {
      title: lastBreadcrumb,
      order: 3,
      returnURL: '',
      clickable: false,
    },
  ],
});

export const getMapNavData = (lastBreadcrumb: string, travelerId: number, brandId: number) => ({
  backButtonURL: `candidate/${travelerId}/${brandId}?panel=${PanelChoice.JobMatchGrid.toLowerCase()}&view=map`,
  breadcrumbHierarchy: [
    {
      title: 'Professional Profile',
      order: 1,
      returnURL: `candidate/${travelerId}/${brandId}`,
      clickable: true,
    },
    {
      title: 'Job Matches',
      order: 2,
      returnURL: `candidate/${travelerId}/${brandId}?panel=${PanelChoice.JobMatchGrid.toLowerCase()}&view=map`,
      clickable: true,
    },
    {
      title: lastBreadcrumb,
      order: 3,
      returnURL: '',
      clickable: false,
    },
  ],
});
