import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  StyledEngineProvider,
  TextField,
  ThemeProvider,
  debounce,
} from 'amn-ui-core';
import React, { Suspense, useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectSubCert } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';
import { useFormContext } from 'react-hook-form';
import { CollectionType } from 'app/enums/Common';
import { smallTextFieldTheme, useStyles } from './CustomizedUnitSubCertificateTypeAhead.styles';
import { ISelectedSubCert } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Grid/FacilityOrderUnitGrid';

export const CustomizedSubCertificateUnitTypeAhead = ({
  options,
  selectedValues,
  setSelectedSubCertificates,
  requirementIndex,
  groupIndex,
  skillSetIndex,
  selectedSubCerts
}: {
  options: any[];
  selectedValues: any[];
  setSelectedSubCertificates: (e) => void;
  requirementIndex: number;
  groupIndex: number;
  skillSetIndex: number;
  selectedSubCerts: ISelectedSubCert[];
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [subCertificateList, setSubCertificateList] = useState<any[]>([]);
  const { watch } = useFormContext();

  const handleToggle = value => {
    const selectedSubCertificateOptions = selectedValues ? [...selectedValues] : [];
    const duplicateSubCertificate = selectedSubCertificateOptions?.find(item => item?.subCertificate?.id === value?.id);
    if (duplicateSubCertificate) {
      const filteredSubCertificateOptions = selectedSubCertificateOptions?.filter(
        item => item?.subCertificate?.id !== value?.id,
      );
      setSelectedSubCertificates(filteredSubCertificateOptions);
    } else {
      const selectedValue = {
        subCertificate: value,
      };
      selectedSubCertificateOptions.push(selectedValue);
      setSelectedSubCertificates(selectedSubCertificateOptions);
    }
  };

  const handleSearch = event => {
    (() => {
      if (options?.length > 0) {
        const filteredSubCertificates = options?.filter(item =>
          item?.name?.toLowerCase()?.includes(event.target.value?.toLowerCase()),
        );
        setSubCertificateList(filteredSubCertificates);
      }
    })();
  };

  useEffect(() => {
    setSubCertificateList(options);
  }, [options]);

  const OptionRender = React.useMemo(() => {
    return (
      <List className={classes.root} id={'subCertificateCell'}>
        <Suspense fallback={<Skeleton variant="rectangular" />}>
          {(subCertificateList || [])?.map(subCertificateValue => {
            const selectedSubCertificate = selectedValues?.find(
              item => item?.subCertificate?.id === subCertificateValue?.id,
            );
            const isSubcertSelected = selectedSubCerts
              ?.find(each => each?.skillSetIndex === skillSetIndex)
              ?.subCerts?.includes(subCertificateValue?.id);
            const currentRowValue = watch(
              `skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].collections[${CollectionType.CertificationsIndex}].requirements[${requirementIndex}]`,
            );

            return (
              <ListItem key={subCertificateValue?.id} role={undefined} dense className={classes.item}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    checked={
                      currentRowValue?.subCertificate?.[0]?.subCertificate?.id === subCertificateValue?.id || undefined
                        ? !!selectedSubCertificate
                        : isSubcertSelected || !!selectedSubCertificate
                    }
                    tabIndex={-1}
                    inputProps={{ 'aria-labelledby': subCertificateValue?.name }}
                    color="primary"
                    onChange={e => handleToggle(subCertificateValue)}
                    className={classes.checkboxItem}
                    disabled={
                      isSubcertSelected &&
                      currentRowValue?.subCertificate?.[0]?.subCertificate?.id !== subCertificateValue?.id
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.listItemContent,
                  }}
                  id={subCertificateValue?.id}
                  primary={subCertificateValue?.name}
                />
              </ListItem>
            );
          })}
        </Suspense>
      </List>
    );
  }, [selectedValues, subCertificateList, selectedSubCerts]);

  return (
    <>
      <Box className={classes.wrapper}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={smallTextFieldTheme}>
            <TextField
              variant="outlined"
              color="primary"
              placeholder={t('appHeader.searchPlaceHolder')}
              id="txtLicense"
              autoComplete="on"
              onChange={debounce(handleSearch, 300)}
              fullWidth
              sx={{ margin: '0px 15px', width: '92%' }}
              size={'small'}
              InputProps={{
                classes: { input: classes.textField },
                startAdornment: <SearchIcon sx={{ opacity: 0.6 }} />,
                'aria-label': 'filter-type-ahead',
              }}
            />
          </ThemeProvider>
        </StyledEngineProvider>
        {OptionRender}
      </Box>
    </>
  );
};
