import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { expiringCredentialsSubStatus } from 'app/components/CredentialingWorkDesk/ExpiringCredentials/Models/ExpiringDocumentInterfaces';
import { reminderstatus } from 'app/components/CredentialingWorkDesk/PlacementReminders/Store/PlacementReminders.redux';

interface ISelection {
  selection: { category: number; subcategory: number | null; value?: any }[];
}

export enum pipelineChoiceKeys {
  REC_WORK_DESK_GRID = 'recWorkDeskGrid',
  AM_WORK_DESK_GRID = 'amWorkDeskGrid',
  CRED_PLACEMENT_STATUS_WDG = 'credPlacements',
  CRED_PLACEMENT_REMINDERS_WDG = 'credPlacementReminders',
  CRED_EXPIRING_DAYS = 'credExpiring',
}

export interface IPipelineChoice {
  [pipelineChoiceKeys.REC_WORK_DESK_GRID]: ISelection;
  [pipelineChoiceKeys.AM_WORK_DESK_GRID]: ISelection;
  [pipelineChoiceKeys.CRED_PLACEMENT_STATUS_WDG]: ISelection;
  [pipelineChoiceKeys.CRED_PLACEMENT_REMINDERS_WDG]: ISelection;
  [pipelineChoiceKeys.CRED_EXPIRING_DAYS]: ISelection;
}

export const nullSelectionObject: ISelection = {
  selection: [],
};

export const initialState: IPipelineChoice = {
  [pipelineChoiceKeys.REC_WORK_DESK_GRID]: nullSelectionObject,
  [pipelineChoiceKeys.AM_WORK_DESK_GRID]: nullSelectionObject,
  [pipelineChoiceKeys.CRED_PLACEMENT_STATUS_WDG]: nullSelectionObject,
  [pipelineChoiceKeys.CRED_PLACEMENT_REMINDERS_WDG]: {
    selection: [{ category: 0, subcategory: null, value: reminderstatus.all }],
  },
  [pipelineChoiceKeys.CRED_EXPIRING_DAYS]: {
    selection: [{ category: 5, subcategory: null, value: expiringCredentialsSubStatus.allExpiring }],
  },
};

const pipelineChoiceSlice = createSlice({
  name: 'pipelineChoice',
  initialState,
  reducers: {
    setPipelineChoice(
      state,
      action: PayloadAction<{
        key: string;
        selection: { category: number; subcategory: number | null; value?: any }[];
      }>,
    ) {
      state[action.payload.key].selection = [...action.payload.selection];
    },
    resetByKey(state, action: PayloadAction<string>) {
      state[action.payload] = { ...initialState[action.payload] };
    },
  },
});

export const {
  actions: pipelineChoiceActions,
  name: pipelineChoiceSliceKey,
  reducer: pipelineChoiceReducer,
} = pipelineChoiceSlice;
