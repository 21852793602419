import { initialState } from './placementPageAttributes.redux';
import { RootState } from 'types';
import { createSelector } from '@reduxjs/toolkit';

const selectDomain = (state: RootState) => state.placementPageAttrubutes || initialState;

export const selectPageAttributes = createSelector([selectDomain], attributes => attributes);

export const selectGlanceDrawerState = createSelector([selectDomain], attributes => attributes.glanceDrawerState);

export const selectEditStatus = createSelector([selectDomain], attributes => attributes.editableFieldStatus);

export const selectExtensionModalProps = createSelector([selectDomain], attributes => attributes.extensionModalProps);

export const selectPreExtensionModalProps = createSelector(
  [selectDomain],
  attributes => attributes.preExtensionModalProps,
);

export const selectMiscellaneousDialogProps = createSelector([selectDomain], attributes => attributes.miscDialogProps);
