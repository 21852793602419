import { XGridChipCell } from '@AMIEWEB/Common/XGrid/cells/XGridChipCell';
import { XGridDefaultColumn } from '@AMIEWEB/Common/XGrid/cells/XGridDefaults';
import { XGridOverflowCell } from '@AMIEWEB/Common/XGrid/cells/XGridOverflowCell';
import { xGridDateSort } from '@AMIEWEB/Common/XGrid/sorting/XGridSorting';
import { GridCellParams, GridColumns } from '@mui/x-data-grid-pro';
import { TFunction } from 'i18next';

export const getStatusNumber = (status: any) => {
  switch (status) {
    case 'Verified':
      return 1;
    case 'Approved':
      return 2;
    case 'Unapproved':
      return 3;
    case 'Approved not Verified':
      return 4;
    case 'Reference in Progress':
      return 5;
    case 'New':
      return 6;
    case 'Reference Requested':
      return 7;
    case 'Request Closed':
      return 8;
    case 'Supervisor Submitted':
      return 9;
    case 'Invalid Contact':
      return 10;
    default:
      return 11;
  }
};

export const getReferenceRequestStatusFromId = (id: number): string => {
  switch (id) {
    case 1:
      return 'Verified';
    case 2:
      return 'Approved';
    case 3:
      return 'Unapproved';
    case 4:
      return 'Approved not Verified';
    case 5:
      return 'Reference in Progress';
    case 6:
      return 'New';
    case 7:
      return 'Reference Requested';
    case 8:
      return 'Request Closed';
    case 9:
      return 'Supervisor Submitted';
    case 10:
      return 'Invalid Contact';
    default:
      return '';
  }
};

const getColor = (status: string) => {
  switch (status) {
    case 'New':
      return '#189AD9';
    case 'Verified':
      return '#189AD9';
    case 'Approved':
      return '#5FB661';
    case 'Unapproved':
      return '#ED812C';
    case 'Approved not Verified':
      return '#ED812C';
    case 'Reference in Progress':
      return '#ED812C';
    case 'Reference Requested':
      return '#ED812C';
    case 'Request Closed':
      return '#CC0000';
    case 'Supervisor Submitted':
      return '#ED812C';
    case 'Invalid Contact':
      return '#CC0000';
    default:
      return '#000000';
  }
};

export const getDefaults = (t: TFunction): GridColumns => {
  return [
    {
      field: 'referenceType',
      headerName: t('candidate.referenceGrid.refType'),
      width: 200,
      sortable: true,
      hide: false,
    },
    {
      ...XGridDefaultColumn,
      field: 'status',
      headerName: t('candidate.referenceGrid.status'),
      width: 200,
      hide: false,
      flex: 0,
      sortable: true,
      renderCell: XGridChipCell({
        size: 'small',
        dynamicColor: (params: GridCellParams) => {
          return getColor(params.value);
        },
      }),
    },
    {
      field: 'referenceDate',
      hide: false,
      width: 200,
      sortable: true,
      type: 'date',
      sortComparator: xGridDateSort,
      headerName: t('candidate.referenceGrid.refDate'),
    },
    {
      field: 'facility',
      hide: false,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.facilityEmployer'),
      renderCell: XGridOverflowCell()(),
    },
    {
      field: 'city',
      hide: false,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.city'),
    },
    {
      field: 'state',
      hide: false,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.state'),
    },
    {
      field: 'startDate',
      hide: false,
      width: 200,
      sortable: true,
      type: 'date',
      sortComparator: xGridDateSort,
      headerName: t('candidate.referenceGrid.startDate'),
    },
    {
      field: 'endDate',
      hide: false,
      width: 200,
      sortable: true,
      type: 'date',
      sortComparator: xGridDateSort,
      headerName: t('candidate.referenceGrid.endDate'),
    },
    {
      field: 'discipline',
      hide: false,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.discipline'),
    },
    {
      field: 'specialty',
      hide: false,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.specialty'),
    },
    {
      field: 'supervisor',
      hide: false,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.supervisor'),
    },
    {
      field: 'title',
      hide: false,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.title'),
    },
    {
      field: 'contactInformationComplete',
      hide: true,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.contactInformationComplete'),
    },
    {
      field: 'phoneType1',
      hide: false,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.phoneType1'),
    },
    {
      field: 'phoneNumber1',
      hide: false,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.phoneNumber1'),
    },
    {
      field: 'phoneType2',
      hide: false,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.phoneType2'),
    },
    {
      field: 'phoneNumber2',
      hide: false,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.phoneNumber2'),
    },
    {
      field: 'callPreference',
      hide: true,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.callPreference'),
    },
    {
      field: 'bestTimeToReach',
      hide: true,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.bestTimeToReach'),
    },
    {
      field: 'email',
      hide: false,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.email'),
    },
    {
      field: 'rehire',
      hide: false,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.eligibleForRehire'),
    },
    {
      field: 'explanation',
      hide: false,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.explanation'),
      renderCell: XGridOverflowCell()(),
    },
    {
      field: 'comments',
      hide: false,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.comments'),
      renderCell: XGridOverflowCell()(),
    },
    {
      field: 'evaluation',
      hide: false,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.evaluation'),
    },
    {
      field: 'ageCompetency',
      hide: false,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.ageSpecificCompetency'),
      renderCell: XGridOverflowCell()(),
    },
    {
      field: 'amnTitle',
      hide: true,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.amnTitle'),
    },
    {
      field: 'unitFloorDept',
      hide: true,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.unitFloorDept'),
    },
    {
      field: 'unitSpecialty',
      hide: true,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.unitSpecialty'),
    },
    {
      field: 'positionsHeld',
      hide: true,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.positionsHeld'),
    },
    {
      field: 'numOfBeds',
      hide: true,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.numOfBeds'),
    },
    {
      field: 'averagePatCaseload',
      hide: true,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.averagePatCaseload'),
    },
    {
      field: 'unitDescription',
      hide: true,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.unitDescription'),
    },
    {
      field: 'shiftWorked',
      hide: true,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.shiftWorked'),
    },
    {
      field: 'supervisorExperience',
      hide: true,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.supervisorExperience'),
    },
    {
      field: 'mayWeShareThisReviewWithTraveler',
      hide: true,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.mayWeShareThisReviewWithTraveler'),
    },
    {
      field: 'haveYouSharedThisReviewWithTraveler',
      hide: true,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.haveYouSharedThisReviewWithTraveler'),
    },
    {
      field: 'activityHistory',
      hide: true,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.activityHistory'),
    },
    {
      field: 'notes',
      hide: false,
      width: 200,
      sortable: true,
      headerName: t('candidate.referenceGrid.notes'),
    },
  ];
};
