/**
 * Page names for use with App Insights and the Document Title
 */
export const pageNames = {
  createPlacement: 'Create Placement',
  candidateProfile: 'Candidate Profile',
  equipmentsProcedure: 'Equipments/Procedure',
  searchPlacements: 'Search - Placements',
  searchCandidates: 'Search - Candidates',
  searchOrders: 'Search - Orders',
  searchFacilities: 'Search - Facilities',
  globalSearchPlacements: 'Global Search - Placements',
  globalSearchCandidates: 'Global Search - Candidates',
  globalSearchOrders: 'Global Search - Orders',
  globalSearchFacilities: 'Global Search - Facilities',
  reports: 'Reports',
  taskInbox: 'Task Inbox',
  workdeskAccountManagement: 'Workdesk - Account Management',
  workdeskCredentialing: 'Workdesk - Credentialing',
  workdeskRecruitment: 'Workdesk - Recruitment',
  workdeskPricing: 'Workdesk - Pricing',
  workdeskCoverage: 'Workdesk - Coverage',
  clientContacts: 'Client Contacts',
  marginTool: 'Margin Tool',
};
