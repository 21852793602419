import React from 'react';
import { AccordContent, PaperCard, SubTitle, TextContent, Title } from '../styles';
import {
  AccordionDetails,
  AccordionSummary,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from 'amn-ui-core';
import { missingField } from 'app/constants';
import moment from 'moment';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips';
import { useSelector } from 'react-redux';
import { supervisorDataSelector } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import EditIcon from 'app/assets/images/Credentialing/PersonalInfo/editIcon.svg';
import { getVariant, skills } from '../Supervisor/helpers/helper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, useSuperVisorStyles } from './utils';
import { theme } from 'styles/global-styles';
import { SupervisorStatus } from '../Supervisor/constants';

function SupervisorCardUI({ handleEditIconClick, isLoading }) {
  const supervisorData = useSelector(supervisorDataSelector);
  const { promiseInProgress } = usePromiseTracker({ area: 'get-candidate-professional-profile-tab-details', delay: 0 });
  const { t } = useTranslation();
  const { classes } = useSuperVisorStyles();
  let approvedSkillsets = supervisorData.skillsets?.filter(item => item.status === SupervisorStatus.APPROVED);

  return (
    <>
      <PaperCard elevation={0} data-testid="supervisor-profile">
        {promiseInProgress ? (
          <Grid item mr={2}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Grid>
        ) : (
          <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Grid item xs={6} paddingTop="0px !important">
                <Title>{t('candidate.supervisorCard.title')}</Title>
              </Grid>
              <Grid item xs={6} paddingTop="0px !important" className={classes.editIcon} mr="-18px">
                <IconButton color="primary" onClick={event => handleEditIconClick(event)} size="small">
                  <img src={EditIcon} alt="Edit" />
                </IconButton>
              </Grid>
            </Grid>
            <Divider sx={{ mb: '10px' }} />

            {isLoading && <CircularProgress style={{ width: '12px', height: '12px' }} />}

            <Grid container direction="column" justifyContent="space-between" spacing={2}>
              <Grid item container spacing={1} direction="row">
                <Grid item xs={6}>
                  <SubTitle>{t('candidate.supervisorCard.interested')}:</SubTitle>
                </Grid>
                <Grid item>
                  <TextContent>{supervisorData.interested === true ? 'Yes' : missingField}</TextContent>
                </Grid>
              </Grid>
              <Grid item container spacing={1} direction="row">
                <Grid item xs={6}>
                  <SubTitle>{t('candidate.supervisorCard.desiredPlacements')}:</SubTitle>
                </Grid>
                <Grid item>
                  <TextContent>{supervisorData.maxNumber > 0 ? supervisorData.maxNumber : '0'}</TextContent>
                </Grid>
              </Grid>
              <Grid item container spacing={1} direction="row">
                <Grid item xs={6}>
                  <SubTitle>{t('candidate.supervisorCard.availablePlacements')}:</SubTitle>
                </Grid>
                <Grid item>
                  <TextContent>{supervisorData.available > 0 ? supervisorData.available : '0'}</TextContent>
                </Grid>
              </Grid>
              <Grid item container spacing={1} direction="row">
                <Grid item xs={6}>
                  <SubTitle>{t('candidate.supervisorCard.availableDate')}:</SubTitle>
                </Grid>
                <Grid item>
                  <TextContent>
                    {supervisorData.availabilityDate
                      ? moment(supervisorData.availabilityDate).format('MM/DD/YYYY')
                      : `MM/DD/YYYY`}
                  </TextContent>
                </Grid>
              </Grid>
              <Grid item container spacing={1} direction="row">
                <Grid item xs={6}>
                  <SubTitle>{t('candidate.supervisorCard.approvedSkillSet')}:</SubTitle>
                </Grid>

                {approvedSkillsets?.length > 0 ? (
                  skills(approvedSkillsets).length < 35 ? (
                    <Grid item>
                      <Typography fontSize="14px" color={theme.palette.framework.system.doveGray}>
                        {skills(approvedSkillsets)}
                      </Typography>
                    </Grid>
                  ) : (
                    <CustomTooltip tooltipContent={skills(approvedSkillsets)}>
                      <Grid item>
                        <Typography className={classes.skillSetContent} ml="6px" mt="6px">
                          {skills(approvedSkillsets)}
                        </Typography>
                      </Grid>
                    </CustomTooltip>
                  )
                ) : (
                  <Grid item mt="3px">
                    {missingField}
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Accordion
              sx={{ marginTop: '17px' }}
              className={classes.accordion}
              elevation={0}
              data-testid="supervisor-skillSet"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="supervisor-skillSet"
                sx={{
                  '.MuiAccordionSummary-content': {
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  },
                }}
              >
                <Grid direction="row" spacing={1}></Grid>
                <Grid item xs={6}>
                  <SubTitle>{t('candidate.supervisorCard.allSkillSets')}</SubTitle>
                </Grid>
                <Grid item sx={{ ml: '14px' }}>
                  <SubTitle>
                    {supervisorData.skillsets?.length > 0 ? supervisorData.skillsets?.length : missingField}
                  </SubTitle>
                </Grid>
              </AccordionSummary>
              <Divider sx={{ mb: '8px' }} />
              <AccordionDetails>
                <AccordContent>
                  <Grid container direction="column" justifyContent="space-between" spacing={2}>
                    <Grid item container spacing={1} direction="row">
                      <Grid item xs={5}>
                        <SubTitle>{t('candidate.supervisorCard.skillSet')}</SubTitle>
                      </Grid>
                      <Grid item xs={3}>
                        <SubTitle>{t('candidate.supervisorCard.status')}</SubTitle>
                      </Grid>
                      <Grid item>
                        <SubTitle>{t('candidate.supervisorCard.reason')}</SubTitle>
                      </Grid>
                    </Grid>
                    {supervisorData.skillsets?.length > 0 ? (
                      supervisorData.skillsets?.map((item, index) => {
                        return (
                          <Grid item container spacing={1} direction="row" key={index}>
                            <Grid item xs={5}>
                              <TextContent>{`${item.discipline} - ${item.specialty}`}</TextContent>
                            </Grid>
                            <Grid item xs={3}>
                              <Chip
                                variant={getVariant(item.status)}
                                label={item.status ? item.status : SupervisorStatus.PENDING}
                                size={'small'}
                              />
                            </Grid>
                            <Grid item>
                              <TextContent>{item.rejectionReason ? item.rejectionReason : missingField}</TextContent>
                            </Grid>
                          </Grid>
                        );
                      })
                    ) : (
                      <Grid item container spacing={1} direction="row">
                        <Grid item xs={5}>
                          {missingField}
                        </Grid>
                        <Grid item xs={3}>
                          {missingField}
                        </Grid>
                        <Grid item>{missingField}</Grid>
                      </Grid>
                    )}
                  </Grid>
                </AccordContent>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </PaperCard>
    </>
  );
}

export default SupervisorCardUI;
