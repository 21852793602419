import { makeStyles } from 'tss-react/mui';
import { Autocomplete, Grid, TextField, Typography } from 'amn-ui-core';
import { DirectHireDetails, DirectHireOption } from 'app/models/Placement/PlacementDetails';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectDirectHireOption,
    selectMiscellaneousPlacementData,
} from 'store/redux-store/placement-details/selectors';
import { placementDetailsAction } from 'store/redux-store/placement-details/slice';
import { useParams } from 'react-router-dom';
import { selectUser } from 'oidc/user.selectors';
import moment from 'moment';

const useStyles: any = makeStyles()({
    inputField: {
        width: '100%',
    },
    labelField: {
        fontSize: '12px',
        fontWeight: 400
    }
});

function CandidateDirectHire() {
    const { classes } = useStyles();
    const dispatch = useDispatch();
    const { extDirectHireOpts } = useSelector(selectMiscellaneousPlacementData);
    const selectedDirectHireOpt = useSelector(selectDirectHireOption);
    const params = useParams<{ placementId: string }>();
    const userInfo = useSelector(selectUser);
    const formMethods = useForm<DirectHireDetails>({
        mode: 'onSubmit'
    });
    const { setValue, control, getValues, reset } = formMethods;

    useEffect(() => {
        if(!extDirectHireOpts){
            dispatch(placementDetailsAction.getExtDirectHireOpts());
        }
    }, []);

    useEffect(() => {
        reset(selectedDirectHireOpt);
    }, [selectedDirectHireOpt, extDirectHireOpts])

    

    const setSelectedDirectHireOpt = (value: NonNullable<DirectHireOption>) => {
        setValue('directHireOption', value);
        dispatch(placementDetailsAction.updateDirectHire({
            placementId: params.placementId,
            directHireTypeId: value.id,
            directHireLastUpdatedById: userInfo.userInfo.employeeId as number,
            directHireLastUpdatedByUserName: `${userInfo.userInfo.firstName as string} ${userInfo.userInfo.lastName as string}`
        }));
    };

    // const directHireLastUpdated = selectedDirectHireOpt.directHireLastUpdated ? moment(selectedDirectHireOpt.directHireLastUpdated, 'MM/DD/YYYY') : ''

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
                <FormProvider {...formMethods}>
                    <form id="candidate-direct-hire-form" autoComplete="off" noValidate={true}>
                        <Controller
                            name={'directHireOption'}
                            control={control}
                            render={({ ref, onChange, value, ...rest }) => (
                                <Autocomplete
                                    {...rest}
                                    getOptionLabel={(option) => `${option.description}`}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    disableClearable
                                    options={extDirectHireOpts}
                                    defaultValue={selectedDirectHireOpt.directHireOption}
                                    onChange={(event, selectedValue) => setSelectedDirectHireOpt(selectedValue)}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={'Status'}
                                            variant="filled"
                                            placeholder="Select"
                                            className={classes.inputField}
                                        />
                                    )}
                                />
                            )}
                        />
                    </form>
                </FormProvider>
            </Grid>

            <Grid item xs={6} md={6} lg={6}>
                <Typography className={classes.labelField}>Last Updated</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                <Typography className={classes.labelField}>
                    {selectedDirectHireOpt.directHireLastUpdated}
                </Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                <Typography className={classes.labelField}>Updated By</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                <Typography className={classes.labelField}>{selectedDirectHireOpt.directHireLastUpdatedBy}</Typography>
            </Grid>
        </Grid>
    )
}

export default CandidateDirectHire;