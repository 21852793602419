import {
  filterChoiceWithNewOptions,
  isItemIncluded,
  isItemIncludedCommaSaperateValues,
} from 'app/ComponentLibrary/Filter/helper';
import { amClientContactsFilterName } from '../Filters/ClientContactFilterConfig';
import { IFilterChipSelected, IFilterSpecs } from '../Store/FacilityContacts.redux';
import { generateFieldChips } from 'app/components/AmWorkDesk/PlacementsDesk/filterHelper';

export const filterUserChoices = (
  filterSpecs: IFilterSpecs,
  values: any,
  setValue: (
    name: string,
    value: unknown,
    options?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
        }>
      | undefined,
  ) => void,
) => {
  return {
    ...values,
    [amClientContactsFilterName.ContactPreference]: filterChoiceWithNewOptions(
      values[amClientContactsFilterName.ContactPreference],
      filterSpecs.contactPreferenceOpts,
      amClientContactsFilterName.ContactPreference,
      setValue,
    ),
    [amClientContactsFilterName.Status]: filterChoiceWithNewOptions(
      values[amClientContactsFilterName.Status],
      filterSpecs.statusOpts,
      amClientContactsFilterName.Status,
      setValue,
    ),
    [amClientContactsFilterName.Units]: filterChoiceWithNewOptions(
      values[amClientContactsFilterName.Units],
      filterSpecs.unitNameOpts,
      amClientContactsFilterName.Units,
      setValue,
    ),
    [amClientContactsFilterName.Actions]: filterChoiceWithNewOptions(
      values[amClientContactsFilterName.Actions],
      filterSpecs.actionsOpts,
      amClientContactsFilterName.Actions,
      setValue,
    ),
    [amClientContactsFilterName.Name]: filterChoiceWithNewOptions(
      values[amClientContactsFilterName.Name],
      filterSpecs.nameOpts,
      amClientContactsFilterName.Name,
      setValue,
    ),
    [amClientContactsFilterName.Title]: filterChoiceWithNewOptions(
      values[amClientContactsFilterName.Title],
      filterSpecs.titleOpts,
      amClientContactsFilterName.Title,
      setValue,
    ),
  };
};

export const parseData = data => {
  return data?.reduce(function (newArr, item) {
    newArr.push(parseInt(item));
    return newArr;
  }, []);
};

export const getFilteredData = (filter, sourceData, chipSelected?: IFilterChipSelected) =>
  sourceData.reduce(
    (resultArray, item) =>
      isItemIncluded(
        item['contactPreference'],
        chipSelected?.contactPreference || filter[amClientContactsFilterName.ContactPreference],
      ) &&
      isItemIncluded(item['name'], chipSelected?.name || filter[amClientContactsFilterName.Name]) &&
      isItemIncluded(item['title'], chipSelected?.title || filter[amClientContactsFilterName.Title]) &&
      isItemIncluded(item['descStatus'], chipSelected?.status || filter[amClientContactsFilterName.Status]) &&
      isItemIncludedCommaSaperateValues(item['unitsSortField'], filter[amClientContactsFilterName.Units]) &&
      isItemIncludedCommaSaperateValues(item['actionsSortField'], filter[amClientContactsFilterName.Actions])
        ? [...resultArray, item]
        : resultArray,
    [],
  );

export const createFilterChips = (filter, selectedChips?: IFilterChipSelected) => ({
  [amClientContactsFilterName.ContactPreference]: generateFieldChips(
    filter[amClientContactsFilterName.ContactPreference],
    selectedChips?.contactPreference || undefined,
  ),
  [amClientContactsFilterName.Status]: generateFieldChips(
    filter[amClientContactsFilterName.Status],
    selectedChips?.status || undefined,
    false,
    true,
  ),
  [amClientContactsFilterName.Units]: generateFieldChips(
    filter[amClientContactsFilterName.Units],
    selectedChips?.units || undefined,
  ),
  [amClientContactsFilterName.Actions]: generateFieldChips(
    filter[amClientContactsFilterName.Actions],
    selectedChips?.actions || undefined,
  ),
  [amClientContactsFilterName.Name]: generateFieldChips(
    filter[amClientContactsFilterName.Name],
    selectedChips?.name || undefined,
  ),
  [amClientContactsFilterName.Title]: generateFieldChips(
    filter[amClientContactsFilterName.Title],
    selectedChips?.title || undefined,
  ),
});
