import { Expandable } from '@AMIEWEB/Common';
import { Title } from '@AMIEWEB/Order/OrderDetails/OrderPreferences/customComponents/OrderPreferencesDesign';
import { Grid } from 'amn-ui-core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { BoxContainer } from '@AMIEWEB/Common/SubmissionPreference/Common/BoxContainer';
import { AttentionToContacts } from '@AMIEWEB/Common/SubmissionPreference/Common/PreferenceContacts';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { SubmissionPacketSelector } from '@AMIEWEB/Common/SubmissionPreference/SubmissionPacket/SubmissionPacketSelector';
import { NoteToFacilityEditor } from '@AMIEWEB/Common/SubmissionPreference/SubmissionPacket/NoteToFacilityEditor';
import { FormNames } from '@AMIEWEB/Common/SubmissionPreference/Common/helper';

interface ISubmissionPacketProps {
  disabled: boolean;
}

export const SubmissionPacketWrapper = ({ disabled }: ISubmissionPacketProps) => {
  const { t } = useTranslation();

  const { control } = useFormContext();

  return (
    <Expandable
      expanded
      titleComponent={<Title>{t('order.orderDetails.preferences.submission.submissionPacketSetup.title')}</Title>}
      unmountOnExit={false}
    >
      <Grid container height={456} spacing={2}>
        <Grid item xs={2.5}>
          <BoxContainer disabled={disabled} title="Packet Template">
            <Controller
              name={FormNames.PacketTemplates}
              control={control}
              render={({ ref, ...rest }) => <SubmissionPacketSelector {...rest} disabled={disabled} />}
            />
          </BoxContainer>
        </Grid>
        <Grid item xs={4.5}>
          <BoxContainer
            disabled={disabled}
            title="Attention To"
            addOnComponent={<AddIcon sx={{ color: theme => theme.palette.system.neutralGray, fontSize: '21px' }} />}
          >
            <Controller
              name={FormNames.AttentionTo}
              control={control}
              render={({ ref, ...rest }) => <AttentionToContacts {...rest} disabled={disabled} />}
            />
          </BoxContainer>
        </Grid>
        <Grid item xs={5}>
          <BoxContainer disabled={disabled} title="Note to Facility Contact">
            <Controller
              name={FormNames.NoteToFacility}
              control={control}
              render={({ ref, ...rest }) => <NoteToFacilityEditor {...rest} disabled={disabled} />}
            />
          </BoxContainer>
        </Grid>
      </Grid>
    </Expandable>
  );
};
