import React from 'react';
import { FormControlLabel, Switch } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  labelPlacementStart: {
    margin: 'auto 0px',
    whiteSpace: 'nowrap',
    gap: '8px',
  },
  disabledLabel: {
    color: '#333333 !important',
  },
  label: {
    fontSize: '16px',
    fontWeight: 450,
  },
});

const stopImmediatePropagation = event => {
  event.stopPropagation();
  event.preventDefault();
};

export const ControlledSwitch = ({
  label,
  switchState,
  handleChange,
  disabled = false,
  hiddenSwitch = false,
  ...props
}) => {
  const { classes } = useStyles();

  const handleTempChange = event => {
    stopImmediatePropagation(event);
    handleChange(!switchState);
  };

  return (
    <FormControlLabel
      control={
        !hiddenSwitch ? (
          <Switch
            color="primary"
            name={props.name}
            size="small"
            checked={switchState}
            inputProps={{ 'aria-label': props.inputLabel }}
            onClick={handleTempChange}
          />
        ) : (
          <div />
        )
      }
      label={label}
      labelPlacement="start"
      classes={{
        label: classes.label,
        labelPlacementStart: classes.labelPlacementStart,
        disabled: classes.disabledLabel,
      }}
      disabled={disabled}
    />
  );
};
