import { formatUSPhoneNumber } from "app/helpers/objectHelpers";
import { IAssociationChip } from "app/models/Notification/Notification";

export enum userManagementFilterNames {
    eid = 'eid',
    usernames = 'usernames',
    employeenames = 'employeeNames',
    companies = 'companies',
    employeestatus = 'employeeStatus',
    employeerole = 'employeeRole'
}

export enum tabValue {
    details = 0,
    roles = 1,
    associations = 2
}

export enum associationFields {
    parentRelationship = 'ParentRelationship',
    parentUserName = 'ParentUserName',
    childRelationship = 'ChildRelationship',
    childUserName = 'ChildUserName',
}
export interface IAssociationFields {
    ParentRelationship: any;
    ParentUserName: any;
    ChildRelationship: any;
    ChildUserName: any;
}

export interface IUserDetailFields {
    firstName: string;
    lastName: string;
    userName: string;
    faxNumber: string;
    smsNumber: string;
    phoneNumber: string;
    companyId: number | null;
    statusId: number | null;
    employeeId: number | null;
    emailId: string;
    workDayEmployeeId: string;
    departmentHireDate: string | null;
    excludeFromTask: boolean;
    crossCompanyUser: boolean;
}

export interface IUserDetailsUpdatePayload extends Omit<IUserDetailFields, 'workDayEmployeeId' | 'emailId'> {
    workDayEmployeeId: number;
    email: string;
}

export const initialUserDetailFields: IUserDetailFields = {
    firstName: "",
    lastName: "",
    userName: "",
    faxNumber: "",
    smsNumber: "",
    phoneNumber: "",
    companyId: null,
    statusId: null,
    employeeId: null,
    emailId: "",
    workDayEmployeeId: "",
    departmentHireDate: null,
    excludeFromTask: false,
    crossCompanyUser: false

}

export interface IUserFieldValidationError {
    fieldName: string;
    message: string;
}


export const doAssociationsDiffer = (arr1: IAssociationChip[], arr2: IAssociationChip[]): boolean => {
    if (arr1.length !== arr2.length) {
        return true;
    }

    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i].label !== arr2[i].label) {
            return true;
        }
    }

    return false;
};

const errorMessages: { [key: string]: string } = {
    firstName: 'First Name already Exists',
    lastName: 'Last Name already Exists',
    userName: 'User Name already Exists',
    emailId: 'Invalid email address',
};

export const getValidationError = (responseCode: number): IUserFieldValidationError[] => {
    const errorMap: { [key: number]: string[] } = {
        60222: ['userName'],
        60274: ['emailId'],
        61313: ['firstName', 'lastName'],
        60223: ['firstName', 'lastName', 'userName'],
    };

    const fields = errorMap[responseCode] || [];

    return fields.map(field => ({
        fieldName: field,
        message: errorMessages[field] || 'Error message not found'
    }));
};

export const getUserInformationFields = (response) => {
    const userDetailFields: IUserDetailFields = {
        firstName: response.firstName,
        lastName: response.lastName,
        userName: response.userName,
        faxNumber: formatUSPhoneNumber(response.faxNumber),
        smsNumber: response.smsNumber,
        phoneNumber: formatUSPhoneNumber(response.phoneNumber),
        companyId: response.companyId,
        statusId: response.statusId,
        employeeId: response.employeeId,
        emailId: response.email ?? "",
        workDayEmployeeId: (response.workDayEmployeeId && (response.workDayEmployeeId).toString()) ?? "",
        departmentHireDate: response.departmentHireDate,
        excludeFromTask: response.excludeFromTask,
        crossCompanyUser: response.crossCompanyUser,
    }
    return userDetailFields;
}