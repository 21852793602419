import React, { useEffect, useState } from 'react';
import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import CheckIcon from '@mui/icons-material/Check';
import { IGraphicStepper } from 'app/ComponentLibrary/GraphicStepper/GraphicStepper';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()({
  containerWrapper: {
    marginBottom: '30px',
  },
  handLink: {
    cursor: 'default',
  },
  expiringStatusDays: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: '#333333',
    padding: '10px 15px',
    alignItems: 'center',
  },
  expiringStatusDaysActive: {
    backgroundColor: '#006FB9',
    color: '#fff !important',
  },
  tiersLayout: {
    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'flex-start',
  },
  paperLayout: {
    display: 'flex',
    border: '1px solid #ECECEC',
    padding: 10,
  },
  tierLayout: {
    borderTop: '1px solid #ECECEC',
    height: '74px',
    alignItems: 'center',
  },
  tierLayoutActive: {
    background: 'linear-gradient(0deg, #FFFFFF 0%, #006fb92b 100%)',
  },
  rootStructure: {
    border: '1px solid #ECECEC',
  },
  borderLeft: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  borderRight: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  tag: {
    fontWeight: 400,
    fontSize: 12,
    textTransform: 'uppercase',
  },

  data: {
    fontWeight: 600,
    fontSize: 20,
  },
  tierText: {
    fontSize: 12,
    color: '#555555',
  },
  tierValue: {
    fontSize: 16,
    color: '#333333',
    fontWeight: 600,
  },
  clickLink: {
    cursor: 'pointer',
  },
  subcategory: {
    borderRight: '1px solid #ECECEC',
  },
  subCategorySection: {
    height: '20px',
  },
  tooltipPlacementBottom: {
    margin: 0,
    marginTop: 22,
  },
  checkMarkNoVisibility: {
    visibility: 'hidden',
  },
  checkMarkVisibility: {
    color: '#006FB9',
  },
});

interface ExpiringCredentialTiersProps {
  tiers: IGraphicStepper[];
  handleItemClick: (category, subcategory, value?: any, event?) => void;
  selected: { category: number; subcategory: number; value: string | null }[];
}

export const ExpiringCredentialTiers = ({ tiers, handleItemClick, selected }: ExpiringCredentialTiersProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [prevselected, SetPrevselected] = useState<any>(selected.length ? selected[0].value : null);

  useEffect(() => {
    SetPrevselected(selected.length ? selected[0].value : null);
  }, [selected]);
  //
  return (
    <Grid container className={classes.containerWrapper} style={{ overflow: 'hidden' }}>
      <Grid item xs={12} className={classes.paperLayout} style={{ overflow: 'auto' }}>
        <Grid container style={{ minWidth: '1233px' }}>
          {tiers.map((tier, index) => (
            <Grid item style={{ width: `${100 / tiers.length}%` }}>
              <Grid
                container
                direction="column"
                className={` ${index === 0 ? classes.borderLeft : ''} ${
                  index === tiers.length - 1 ? classes.borderRight : ''
                } ${classes.rootStructure}`}
              >
                <Grid
                  item
                  className={tier.category.value ? classes.clickLink : classes.handLink}
                  onClick={e => {
                    !tier.category.showTooltip &&
                      prevselected !== tier.category.value &&
                      handleItemClick(index, null, tier.category.value, e);
                  }}
                  style={{ textDecoration: 'none', color: '#003E68' }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      className={
                        selected.length && selected.some(item => item.category === index)
                          ? `${classes.expiringStatusDays} ${classes.expiringStatusDaysActive}`
                          : classes.expiringStatusDays
                      }
                    >
                      <Grid item className={classes.tag}>
                        {tier.category.tag}
                      </Grid>
                      <Grid item className={classes.data}>
                        {tier.subcategory.map(item => item.data).reduce((prev, next) => prev + next)}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} className={classes.tiersLayout} data-id="sub category parent">
                  <Grid container>
                    {tier.subcategory.map((subcategory, subcategoryIndex) => (
                      <Grid item xs={4}>
                        <Grid
                          container
                          direction="column"
                          wrap="nowrap"
                          onClick={e => {
                            if (!subcategory?.showTooltip) {
                              let totalSubCategoriesSelected = selected
                                .filter(itm => itm.category === index)
                                ?.map(sel => sel.subcategory);
                              const totalOtherSubCategoriesSelected = selected
                                .filter(itm => itm.category !== index)
                                ?.map(sel => sel.subcategory);
                              if (!totalSubCategoriesSelected.includes(subcategoryIndex))
                                totalSubCategoriesSelected = totalSubCategoriesSelected.concat([subcategoryIndex]);
                              else if (
                                totalSubCategoriesSelected.length === 1 &&
                                totalOtherSubCategoriesSelected.length === 0
                              )
                                return;
                              if (totalSubCategoriesSelected.length === tier.subcategory.length)
                                handleItemClick(index, null, tier.category.value, e);
                              else handleItemClick(index, subcategoryIndex, subcategory.value, e);
                            }
                          }}
                          style={{ justifyContent: 'space-evenly', padding: '5px 0px', cursor: 'pointer' }}
                          className={`${subcategoryIndex < tier.subcategory.length - 1 && classes.subcategory} ${
                            (selected.length &&
                              selected.some(
                                item => item.category === index && item.subcategory === subcategoryIndex,
                              )) ||
                            (selected.length &&
                              selected.some(item => item.category === index && item.subcategory === null))
                              ? `${classes.tierLayout} ${classes.tierLayoutActive}`
                              : classes.tierLayout
                          }`}
                        >
                          <Grid item className={`${classes.subCategorySection} ${classes.tierText}`}>
                            <div>{subcategory.tag}</div>
                          </Grid>
                          <Grid item className={`${classes.subCategorySection} ${classes.tierValue}`}>
                            {/* <div>{subcategory.data}</div> */}
                            {subcategory.data > 0 ? (
                              <div className={classes.tierValue}>{subcategory.data}</div>
                            ) : (
                              <CustomTooltip
                                tooltipContent={t('home.credentialingWorkDesk.pipeline.noValueText')}
                                tooltipPlacementBottom={classes.tooltipPlacementBottom}
                              >
                                <div className={classes.tierValue}>{subcategory.data}</div>
                              </CustomTooltip>
                            )}
                          </Grid>
                          <Grid item className={`${classes.subCategorySection} ${classes.tierValue}`}>
                            <div>
                              {(selected.length &&
                                selected.some(
                                  item => item.category === index && item.subcategory === subcategoryIndex,
                                )) ||
                              (selected.length &&
                                selected.some(item => item.category === index && item.subcategory === null)) ? (
                                <CheckIcon
                                  fontSize="small"
                                  className={
                                    subcategory.data > 0 ? classes.checkMarkVisibility : classes.checkMarkNoVisibility
                                  }
                                />
                              ) : null}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
