import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { CircularProgress, Grid } from 'amn-ui-core';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import { AddPermanentTaxForm } from './AddPermanentTaxForm';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { candidateDetailsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import _ from 'lodash';
import { Cancel } from '@AMIEWEB/Common/CancelModal/Cancel';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { selectUser } from 'oidc/user.selectors';
import moment from 'moment';
import { ISelectedTaxLetterInfo } from 'app/models/Candidate/CandidateProfile';

const defaultFormValues = {
  pWithhold: null,
  pEffectiveDate: null,
};

const useStyles = makeStyles()(theme => ({
  addTaxDataContainer: {
    minHeight: '288px',
    minWidth: '444px',
  },
  dialogContainerGrid: {
    width: '100%',
    padding: '25px 0px 0px 0px !important',
  },
  dialogContentRoot: {
    padding: '0px 24px 24px 24px',
  },
}));

export enum YesNoValues {
  YES = 'Yes',
  NO = 'No',
}

export const YesNoFieldOptions = [
  {
    id: 1,
    label: YesNoValues.YES,
  },
  {
    id: 2,
    label: YesNoValues.NO,
  },
];

const PermanentTaxFormDialogue = props => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const user = useSelector(selectUser);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cancel, setCancel] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState<ISelectedTaxLetterInfo | null>(
    props.selectedItem ? props.selectedItem : defaultFormValues,
  );

  const formMethods = useForm({
    mode: 'all',
    defaultValues: selectedItem,
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    formState: { dirtyFields, errors, touched },
  } = formMethods;

  useEffect(() => {
    const selectedWithholdValue = YesNoFieldOptions.find(x => x.label === selectedItem.pWithhold);
    setValue('pWithhold', selectedWithholdValue ? selectedWithholdValue.id : '');
    setValue('pEffectiveDate', selectedItem.pEffectiveDate);
  }, [selectedItem]);

  const disableSave = () => {
    if (_.isEmpty(errors) && getValues('pWithhold') && getValues('pEffectiveDate')) return false;
    else return true;
  };

  const handleCancel = () => {
    if (!_.isEmpty(errors) || !_.isEmpty(dirtyFields) || !_.isEmpty(touched)) {
      setCancel(true);
    } else {
      yesAction();
    }
  };

  const yesAction = () => {
    props.setOpenForm(false);
    setCancel(false);
  };

  const noAction = () => {
    setCancel(false);
  };

  const onError = (data, e) => {};

  const onSubmit = async data => {
    setIsLoading(true);
    const withholdValue = YesNoFieldOptions.find(x => x.id === data.pWithhold);
    const payload = {
      candidateId: candidateDetails.travelerId,
      taxLetterInfoID: props.selectedItem ? props.selectedItem.taxLetterInfoID : null,
      taxLetter: withholdValue?.label === YesNoValues.YES ? 'Y' : 'N',
      updateDate: data?.pEffectiveDate ? moment(data?.pEffectiveDate).format('YYYY-MM-DD') : null,
      currentEmployeeId: user.userInfo?.employeeId || 8410,
      currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
    };
    dispatch(candidateDetailActions.postPermanentTaxData(payload));
    props.setOpenForm(false);
    setIsLoading(false);
  };

  const handleUndo = () => {
    setSelectedItem(props.selectedItem);
    const selectedWithholdValue = YesNoFieldOptions.find(x => x.label === selectedItem.pWithhold);
    setValue('pWithhold', selectedWithholdValue ? selectedWithholdValue.id : '');
    setValue('pEffectiveDate', selectedItem.pEffectiveDate);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <GenericDialog
              fullWidth
              draggable
              variant={'blue'}
              classes={{
                paper: classes.addTaxDataContainer,
              }}
              open={props.isModalOpen}
              disablePortal
              disableEscapeKeyDown
              dialogTitleProps={{
                text: props.selectedItem
                  ? t('candidate.taxInfoCard.editPermanentTaxHome')
                  : t('candidate.taxInfoCard.addPermanentTaxHome'),
                closeButton: true,
                expandable: true,
                undoButton: props.selectedItem ? true : false,
                onUndoClick: () => handleUndo(),
              }}
              dialogContentProps={{ classes: { root: classes.dialogContentRoot } }}
              dialogActions={[
                {
                  text: 'Cancel',
                  variant: 'contained',
                  color: 'tertiary',
                  onClick: () => handleCancel(),
                },
                {
                  text: props.selectedItem ? 'Save' : 'Add',
                  variant: 'contained',
                  color: 'primary',
                  type: 'submit',
                  disabled: disableSave(),
                },
              ]}
              onClose={handleCancel}
            >
              <Grid container spacing={3} style={{ margin: 0, width: '100%' }}>
                <Grid item xs={12} className={classes.dialogContainerGrid}>
                  <AddPermanentTaxForm options={YesNoFieldOptions} />
                </Grid>
              </Grid>
            </GenericDialog>
            {cancel && <Cancel openDialog={cancel} handleConfirmAction={yesAction} handleCancelAction={noAction} />}
          </>
        )}
      </form>
    </FormProvider>
  );
};

export default PermanentTaxFormDialogue;
