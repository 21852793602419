import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { CustomFilter } from '../../../../ComponentLibrary/Filter/CustomFilter';
import { FilterCompTypes } from '../../../../ComponentLibrary/Filter/utils';
import { useDispatch, useSelector } from 'react-redux';
import { selectFilters } from '../../../Common/Grid/GridStateManagement/GridState.selectors';
import { gridStateActions } from '../../../Common/Grid/GridStateManagement/GridState.redux';
import { useTranslation } from 'react-i18next';

const PreferenceMatchFilter = [
  {
    id: 0,
    name: 'Candidate Preference Matches',
    value: 'candidate_pref_flag',
  },
  {
    id: 1,
    name: 'Recruiter Preference Matches',
    value: 'recruiter_pref_flag',
  },
  {
    id: 2,
    name: 'All Preference Matches',
    value: 'any_pref_flag',
  },
  {
    id: 3,
    name: 'Recommended Matches',
    value: 'default',
  },
];

export const getFilter = matchFilter =>
  matchFilter && matchFilter === PreferenceMatchFilter[3].value ? null : matchFilter;

const filterDefaultValues = {
  matchFilter: 'default',
};

export const GridFilter = props => {
  const { t } = useTranslation();
  const appliedFilterValues = useSelector(selectFilters);
  const formMethods = useForm({ defaultValues: appliedFilterValues?.filters || filterDefaultValues });
  const { reset, handleSubmit } = formMethods;
  const dispatch = useDispatch();

  const jobMatchFilterSourceData = [
    {
      filterName: t('candidate.jobMatchFilter.preferenceMatch'),
      name: 'matchFilter',
      type: FilterCompTypes.SELECT,
      options: PreferenceMatchFilter,
    },
  ];

  const onApplyFilter = formData => {
    dispatch(gridStateActions.setFilterAttributes({ filterObject: formData }));
    dispatch(gridStateActions.setPageNumber(1));
    const filter = getFilter(formData.matchFilter);
    props.onApplyFilter(filter);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onApplyFilter)}>
        <CustomFilter
          formMethods={formMethods}
          defaultValues={filterDefaultValues}
          fields={jobMatchFilterSourceData}
          onResetForm={() => reset(filterDefaultValues)}
          justifyContent={'flex-start'}
          version0
          analytics={{
            caller: 'Job Match Filter',
            onApply: true,
          }}
        />
      </form>
    </FormProvider>
  );
};
