/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui';
import { AppBar, Theme } from 'amn-ui-core';
import styledComponent from 'styled-components';

export const useMessagingStyles = makeStyles<{ unknownNum; warningMessage; enableBrandSelection }>()(
  (theme: Theme, { unknownNum, warningMessage, enableBrandSelection }) => ({
    headerNavigation: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: 'auto',
    },
    paper: {
      position: 'fixed',
      bottom: '5px',
      zIndex: -1,
      backgroundColor: theme.palette.background.paper,
      overflowY: 'hidden',
      overflowX: 'hidden',
      width: '420px',
      outline: 'none',
      boxShadow: '0px 0px 2px 6px #8888',
      borderRadius: '6px',
      height: '550px',
    },
    paperRight: {
      right: '35px',
    },
    paperRightWithDrawerOpened: {
      right: '460px',
    },
    scroll: {
      padding: '2%',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    input: {
      fontSize: '16px',
      '& textarea': {
        marginRight: '15px',
      },
    },
    modalToolbar: {
      background: '#88888',
      paddingBottom: '8px',
      paddingLeft: '15px',
    },
    messageBar: {
      borderTop: '1px solid #D6D7D8',
      boxShadow: '0px 1px 2px #0000004D',
      backgroundColor: '#F5F5F5',
      paddingBottom: '4%',
      paddingTop: '3%',
      position: 'absolute',
      bottom: 0,
    },
    messageBox: {
      backgroundColor: '#FFFFFF',
      marginTop: '1%',
    },
    notesModal: {
      margin: '10px',
      width: '94%',
      font: 'normal normal normal 14px/20px Roboto',
      '& textarea': {
        height: unknownNum
          ? '278px !important'
          : warningMessage
          ? '330px !important'
          : enableBrandSelection
          ? '315px !important'
          : '380px !important',
      },
    },
    button: {
      marginLeft: '270px',
    },
    avatar: {
      margin: '2% 2% 0 0',
      background: '#34B233',
      color: '#FFFFFF',
      fontSize: '23px',
      height: theme.spacing(8),
      width: theme.spacing(8),
    },
    disableAvatar: {
      margin: '2% 2% 0 0',
      backgroundColor: '#fae5e5',
      color: '#CC0000',
      fontSize: '23px',
      fontWeight: 500,
      height: theme.spacing(8),
      width: theme.spacing(8),
    },
    modalIcon: {
      color: '#BDBEBF',
      cursor: 'pointer',
      fontSize: '1.5rem',
      margin: '5px',
    },
    MuiCircularProgressRoot: {
      top: '0',
      position: 'relative',
      left: '45%',
    },
    tooltipArrow: {
      color: '#fff',
      '&::before': {
        border: '1px solid #555555',
      },
    },
    tooltipBackground: {
      backgroundColor: '#fff',
      color: '#555555',
      border: '1px solid #555555',
      fontSize: '12px',
    },
    emojiGrid: {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 0px 10px #0000004D',
      pointerEvents: 'auto',
      width: '415px',
      marginLeft: '8px',
      left: -5,
      marginBottom: '0px',
      maxWidth: '600px !important',
    },
    grid: {
      marginTop: '5px',
      marginBottom: '5px',
      marginLeft: '0px',
    },
    emojiTooltipArrow: {
      color: '#fff',
      left: '305px !important',
      fontSize: '18px',
      '&::before': {
        boxShadow: '0px 0px 10px #0000004D',
      },
    },
    emoji: {
      fontSize: '22px',
      cursor: 'pointer',
      width: '20px',
      paddingTop: 6,
      paddingLeft: 0,
      'flex-basis': '12%',
      '&.css-1h7phvb-MuiGrid-root-grid>.MuiGrid-item': {
        paddingTop: 6,
        paddingLeft: 0,
      },
    },
    expandedModeEmoji: {
      paddingRight: '3%',
      position: 'absolute',
      right: '5px',
      bottom: '5px',
      width: 16,
      height: 42,
      // '&:hover': {
      //   backgroundColor: '#fff',
      // },
    },
    sendIcon: {
      position: 'absolute',
      bottom: '14px',
      height: 58,
      width: 58,
    },
    attachFileIcon: {
      bottom: '17px',
      marginRight: '10px',
    },
    attachmentLabel: {
      bottom: '21px',
      position: 'absolute',
    },
    header: {
      textAlign: 'left',
      font: 'normal normal medium Roboto',
      letterSpacing: '0px',
      color: '#0f6fb9',
      opacity: 1,
      fontWeight: 700,
      display: 'flex',
      marginTop: 8,
    },
    candidateHeader: {
      display: 'flex',
      flexDirection: 'column',
    },
    otConsentheader: {
      textAlign: 'left',
      font: 'normal normal medium Roboto',
      letterSpacing: '0px',
      color: '#CC0000',
      opacity: 1,
      fontSize: '12px',
      marginTop: '2%',
      display: 'flex',
      fontWeight: 600,
    },
    headerTooltipArrow: {
      color: '#fff',
      fontSize: '14px',
      '&::before': {
        boxShadow: '0px 0px 10px #0000004D',
      },
    },
    headerTooltip: {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 0px 10px #0000004D',
      pointerEvents: 'auto',
      marginTop: '23px',
    },
    confirmationMessage: {
      position: 'absolute',
      bottom: 0,
    },
    addNotesIcon: {
      border: '5px solid  #fff',
      background: 'transparent',
      color: '#006FB9',
      borderRadius: '50px',
      fontSize: '40px',
      cursor: 'pointer',
      '&:hover': {
        border: '5px solid  #006FB9',
        background: '#006FB9',
        color: '#fff',
      },
    },
    phoneDisabledIcon: {
      marginLeft: '4px',
      marginRight: '4px',
    },
    emailDisabledIcon: {
      marginLeft: '4px',
      marginRight: '3px',
    },
    attachmentChip: {
      margin: '5px 0 10px 0',
      borderRadius: '5px',
      background: '#f7f7f7',
      border: '1px solid #b9bbbc',
    },
    snackBar: {
      position: 'absolute',
      zIndex: 2,
      bottom: 0,
    },
    errorText: {
      fontSize: '12px',
      justifyContent: 'center',
      color: theme.palette.system.errorRed,
    },
    characterCount: {
      fontSize: '12px',
      justifyContent: 'center',
      color: '#888888',
    },
    errorInfoIcon: {
      width: '16px',
      height: '16px',
    },
  }),
);

export const ModalAppBar = styledComponent(AppBar)`
  width: 100%;
  background: #f4f5f7;
  box-shadow: none;
`;
