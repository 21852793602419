/* eslint-disable i18next/no-literal-string */
import { GridColParams } from '@mui/x-data-grid-pro';
import { Tooltip } from 'amn-ui-core';
import { isOverflown } from 'app/helpers/componentHelpers';
import React from 'react';

/**
 * X-Grid header cell with an optional icon and tooltip title
 * @param icon icon to display
 * @param autoHover if set to true, it will auto show tooltip when there is overflow on the header name.
 * Else if set to false, it will always show the tooltip on hover, even if there's no overflow
 * @param hoverTitle custom tool tip title. If undefined, the headerName will be used
 * @returns JSX.Element
 */
export const XGridHeaderWithIconCell = (
  icon?: string | JSX.Element,
  autoHover: boolean = true,
  hoverTitle?: string,
) => (params: GridColParams) => {
  return <XGridHeaderWithIconCellContent icon={icon} hoverTitle={hoverTitle} autoHover={autoHover} {...params} />;
};

interface XGridHeaderWithIconCellProps extends GridColParams {
  icon?: string | JSX.Element;
  hoverTitle?: string;
  autoHover?: boolean;
}

/**
 * Inner content for header cell with icon
 */
export const XGridHeaderWithIconCellContent = (props: XGridHeaderWithIconCellProps) => {
  const cellValue = React.useRef<HTMLDivElement | null>(null);
  const [overflowTitle, setOverflowTitle] = React.useState<string | undefined>();

  const handleMouseEnter = () => {
    if ((props.autoHover && isOverflown(cellValue.current!)) || !props.autoHover) {
      setOverflowTitle(props.hoverTitle || props.colDef.headerName);
    }
  };

  const handleMouseLeave = () => {
    setOverflowTitle(undefined);
  };

  return (
    <Tooltip placement="bottom-start" open={!!overflowTitle} title={<div>{overflowTitle}</div>}>
      <div
        className="MuiDataGrid-colCellTitle"
        title={overflowTitle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div ref={cellValue} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {props.colDef.headerName}
          </div>
          {props.icon && (
            <div style={{ marginLeft: '6px' }}>
              {typeof props.icon === 'string' && <img src={props.icon} alt="" />}
              {typeof props.icon !== 'string' && props.icon}
            </div>
          )}
        </div>
      </div>
    </Tooltip>
  );
};
