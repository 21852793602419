import { UnitCard } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/CustomComponents/UnitCard';
import React, { useEffect, useState } from 'react';
import { LayoutGrid } from 'app/layout/LayoutGrid';
import { missingField } from 'app/constants';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Skeleton, Typography } from 'amn-ui-core';
import { useSelectedUnitDetails, useUnitStatusInActive, isAuthorizedForAddEdit, getBestBets } from './unitHelper';
import { useDispatch, useSelector } from 'react-redux';
import { orderCreationActions } from '@AMIEWEB/Order/Store/OrderCreation.actions';
import { selectBestBets } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { selectUser } from 'oidc/user.selectors';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { Cancel } from '@AMIEWEB/Common/CancelModal/Cancel';
import { Undo } from '@AMIEWEB/Common/UndoModal/Undo';
import { FormProvider, useForm } from 'react-hook-form';
import BestBets from '@AMIEWEB/Order/OrderCreationForm/Components/UnitDetail/AdditionalInformation/BestBets';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { usePromiseTracker } from 'react-promise-tracker';
import { useStylesBestBet } from './Unit.styles';

const ViewBestBets = () => {
  const { t } = useTranslation();
  const userData = useSelector(selectUser);
  const bestBets = useSelector(selectBestBets);
  const isAuthorized = isAuthorizedForAddEdit(userData);
  const selectedUnitDetail = useSelectedUnitDetails();
  const isUnitInActive = useUnitStatusInActive();
  const dispatch = useDispatch();
  const [isEditMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    dispatch(orderCreationActions.getBestBetsOptions());
  }, []);

  const { promiseInProgress: isFacilityWithFilterDetailsLoading } = usePromiseTracker({
    area: 'get-facility-details-with-filters',
    delay: 0,
  });
  return (
    <>
      <UnitCard
        title={t('facility.atAGlance.bestBets')}
        rightIcon={true}
        showEditIcon={isAuthorized && !isUnitInActive}
        onEditClick={() => setEditMode(true)}
        isCustomEdit
        skeletonWidth={69}
        isDataLoading={isFacilityWithFilterDetailsLoading}
      >
        <LayoutGrid container sx={{ p: '12px', m: 0 }} direction="column">
          <>
            <Grid container direction="column" rowSpacing={2}>
              <GridSection
                label={t('facility.atAGlance.bestBets')}
                value={
                  getBestBets(bestBets, selectedUnitDetail?.bestBetsId) ?? selectedUnitDetail?.bestBets ?? missingField
                }
                isDataLoading={isFacilityWithFilterDetailsLoading}
              />
            </Grid>
          </>
        </LayoutGrid>
      </UnitCard>
      {isEditMode && <BestBetsDialog open={isEditMode} setEditMode={setEditMode} />}
    </>
  );
};

export default ViewBestBets;

const GridSection = ({
  label,
  value,
  isDataLoading,
}: {
  label: string;
  value: string | number;
  isDataLoading: boolean;
}) => {
  return (
    <>
      <Grid container item>
        <Box pr={4}>
          {isDataLoading ? (
            <Skeleton variant="rectangular" width={131} height={13} />
          ) : (
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              {label}
            </Typography>
          )}
        </Box>
        <Box>
          {isDataLoading ? (
            <Skeleton variant="rectangular" width={37} height={13} />
          ) : (
            <Typography variant="body1" mt={'-2px'}>
              {value}
            </Typography>
          )}
        </Box>
      </Grid>
    </>
  );
};

const BestBetsDialog = ({
  open,
  setEditMode,
}: {
  open: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const params = useParams<{ id: string; unitId: string }>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { classes } = useStylesBestBet();
  const selectedUnitDetail = useSelectedUnitDetails();
  const bestBetsOption = useSelector(selectBestBets);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [undoDialogOpen, setUndoDialogOpen] = useState<boolean>(false);

  const getCurrentBestBets = (bestBetsId: number) => {
    const currentBets = bestBetsOption.find(x => x.value === bestBetsId?.toString());
    if (currentBets) {
      return { ID: currentBets.value, Description: currentBets.name };
    } else return { ID: '', Description: '' };
  };

  const currentBestBets = getCurrentBestBets(selectedUnitDetail?.bestBetsId);
  const methods = useForm({
    defaultValues: { bestBets: currentBestBets },
    mode: 'onSubmit',
  });

  const {
    handleSubmit,
    watch,
    reset,
    formState: { isDirty, isValid },
  } = methods;

  const bestBetsValue = watch('bestBets');

  const onSubmit = data => {
    const requestPayload = {
      id: params.unitId,
      isComingFromFacility: false,
      bestBetsId: data.bestBets.ID,
      bestBetsLabel: data.bestBets.Description,
    };
    dispatch(facilityActions.updateBestBetsForFacility(requestPayload));
    setEditMode(false);
  };

  const onClose = () => {
    if (isDirty) setConfirmDialogOpen(true);
    else setEditMode(false);
  };

  const onDiscardChanges = () => {
    setConfirmDialogOpen(false);
    setEditMode(false);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <GenericDialog
            fullWidth
            variant={'blue'}
            classes={{
              paper: classes.dialogContainer,
            }}
            dialogContentProps={{
              classes: { root: classes.dialogContent },
            }}
            open={open}
            disablePortal
            disableEscapeKeyDown
            dialogTitleProps={{
              text: t('facility.atAGlance.bestBets'),
              closeButton: true,
              expandable: true,
              undoButton: true,
              onUndoClick: () => setUndoDialogOpen(true),
              disableUndo: !isDirty,
            }}
            dialogActions={[
              {
                text: t('global.button.cancel'),
                variant: 'contained',
                color: 'tertiary',
                onClick: onClose,
              },
              {
                text: t('global.button.save'),
                variant: 'contained',
                color: 'primary',
                type: 'submit',
                disabled: !isDirty || !isValid || isEmpty(bestBetsValue),
              },
            ]}
            onClose={onClose}
          >
            <Grid container>
              <Grid item xs={8}>
                <BestBets />
              </Grid>
            </Grid>
          </GenericDialog>
        </form>
      </FormProvider>
      {confirmDialogOpen && (
        <Cancel
          openDialog={confirmDialogOpen}
          handleCancelAction={() => {
            setConfirmDialogOpen(false);
          }}
          handleConfirmAction={onDiscardChanges}
          cancelButtonId="unit-best-bets-discard-changes-cancel-button"
          confirmButtonId="unit-best-bets-discard-changes-confirm-button"
        />
      )}
      {undoDialogOpen && (
        <Undo
          openDialog={undoDialogOpen}
          handleConfirmAction={() => {
            reset();
            setUndoDialogOpen(false);
          }}
          handleCancelAction={() => {
            setUndoDialogOpen(false);
          }}
        />
      )}
    </>
  );
};
