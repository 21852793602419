/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { lookupActions } from 'store/redux-store/lookup/lookup.slice';
import CandidateProfile from 'app/components/Candidate/CandidateProfile/Profile/CandidateProfile';
import { navigationProfilesAction } from '@AMIEWEB/GlobalSearch/store/NavigationProfiles.redux';

const CandidatePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(lookupActions.getAllLookup());

    return () => {
      dispatch(navigationProfilesAction.reset());
    };
  }, []);

  return <CandidateProfile />;
};

export default CandidatePage;
