import { taskDetailsActions, taskDetailsReducer, taskDetailsSliceKey } from 'app/components/Tasks/store/Tasks.redux';
import { taskDetailsSaga } from 'app/components/Tasks/store/Tasks.saga';
import {
  selectInternalTaskDetailsData,
  selectIsExternal,
  selectNotificationContainer,
  selectNotificationPrimaryContext,
  selectTaskStatusData,
  selectVersionUpdated,
} from 'app/components/Tasks/store/Tasks.selectors';
import { useEffect } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import {
  NotificationBrand,
  NotificationSender,
  StatusUpdateModalVersion,
  Task,
  TaskEntity,
} from 'app/models/Tasks/Tasks';
import { useTranslation } from 'react-i18next';
import { getTaskEntityValue } from './Common/FormatData';
import { selectUser } from 'oidc/user.selectors';
import { Concatenate } from 'utils/string/string';

export const useStatusUpdateNotification = () => {
  const taskDetails = useSelector(selectTaskStatusData);
  const userInfo = useSelector(selectUser);
  const isExternal = useSelector(selectIsExternal);
  const parentTaskDetails = useSelector(selectInternalTaskDetailsData);
  const notificationContainer = useSelector(selectNotificationContainer);
  const notificationContext = useSelector(selectNotificationPrimaryContext);
  const versionUpdated = useSelector(selectVersionUpdated);
  const { t } = useTranslation();
  useInjectSaga({ key: taskDetailsSliceKey, saga: taskDetailsSaga });
  useInjectReducer({ key: taskDetailsSliceKey, reducer: taskDetailsReducer });
  const dispatch = useDispatch();


  const getAssignedToList = () => {
    const assignedToArray: any[] = [];
    taskDetails?.assignedTo?.forEach(assigned => {
      if (
        taskDetails?.createdById !== assigned?.assignedToId &&
        userInfo?.userInfo?.employeeId !== assigned?.assignedToId
      ) {
        const userData = {
          name: assigned?.assignedToFirstName + ' ' + assigned?.assignedToLastName,
          email:'',
          phoneNumber: '',
          contactId: assigned?.assignedToId?.toString(),
        };
        assignedToArray.push(userData);
      }
    });
    taskDetails?.cc?.forEach(ccUser => {
      if (taskDetails?.createdById !== ccUser?.ccId && userInfo?.userInfo?.employeeId !== ccUser?.ccId) {
        const userData = {
          name: ccUser?.ccFirstName + ' ' + ccUser?.ccLastName,
          email:'',
          phoneNumber: '',
          contactId: ccUser?.ccId?.toString(),
        };
        assignedToArray.push(userData);
      }
    });

    if (taskDetails.createdById !== userInfo?.userInfo?.employeeId) {
      const createdByUser = {
        name: taskDetails?.createdByFirstName + ' ' + taskDetails?.createdByLastName,
        email:'',
        phoneNumber: '',
        contactId: taskDetails?.createdById?.toString(),
      };
      assignedToArray.push(createdByUser);
    }
    const key = 'contactId';
    const filteredList = assignedToArray.filter(
      (contact, index, self) => self.findIndex(c => c[key] === contact[key]) === index,
    );
    return filteredList;
  };

  const getTaskStatusNotifications = notificationContain => {
    let entityId: string = '0';
    let container: TaskEntity;
    let primaryText: string = '';

    if (notificationContainer) {
      container = notificationContainer;
    } else {
      if (notificationContain) {
        container = notificationContain;
      }
    }

    if (container === TaskEntity.PLACEMENTS) {
      entityId = taskDetails?.candidateId?.toString();
      if (notificationContext && container === notificationContainer) {
        primaryText = notificationContext;
      } else {
        primaryText = Concatenate([taskDetails?.candidateFirstName, taskDetails?.candidateLastName], ' ');
      }
    } else if (container === TaskEntity.FACILITIES) {
      entityId = taskDetails?.facilityId?.toString();
      if (notificationContext && container === notificationContainer) {
        primaryText = notificationContext;
      } else {
        primaryText = taskDetails?.facilityName;
      }
    } else if (container === TaskEntity.ORDERS) {
      entityId = taskDetails?.facilityId?.toString();
      if (notificationContext && container === notificationContainer) {
        primaryText = notificationContext;
      } else {
        primaryText = taskDetails?.facilityName;
      }
    } else if (container === TaskEntity.CANDIDATES) {
      entityId = taskDetails?.candidateId?.toString();
      if (notificationContext && container === notificationContainer) {
        primaryText = notificationContext;
      } else {
        primaryText = Concatenate([taskDetails?.candidateFirstName, taskDetails?.candidateLastName], ' ');
      }
    } else {
      entityId = '0';
      primaryText = '';
    }

    const statusText = taskDetails?.statusId === 3 ? 'has completed' : 'has cancelled';
    if (primaryText) {
      const taskNotificationPayload = {
        brand: NotificationBrand,
        useType: Task,
        useSubType: taskDetails?.statusId?.toString(),
        sendType: 'Bulk',
        sender: NotificationSender,
        ttlValue: 2,
        ttlPeriod: 'w',
        associatedRecords: [
          {
            name: 'TaskId',
            value:
              versionUpdated === StatusUpdateModalVersion.PARENT_TASK
                ? taskDetails?.taskId?.toString()
                : parentTaskDetails?.taskId?.toString(),
          },
          {
            name: 'TaskName',
            value:
              versionUpdated === StatusUpdateModalVersion.PARENT_TASK
                ? taskDetails?.task
                : taskDetails?.task?.split('-')[1] ?? taskDetails?.task,
          },
          {
            name: 'UpdatedUser',
            value: taskDetails?.statusUpdatedByName,
          },
          {
            name: 'CurrentContainer',
            value: getTaskEntityValue(container, t),
          },
          {
            name: 'PrimaryContext',
            value: primaryText,
          },
          {
            name: 'PlacementId',
            value: taskDetails?.placementId ? taskDetails?.placementId?.toString() : '',
          },
          {
            name: 'FacilityId',
            value: taskDetails?.facilityId ? taskDetails?.facilityId?.toString() : '',
          },
          {
            name: 'UnitId',
            value: taskDetails?.unitId ? taskDetails?.unitId?.toString() : '',
          },
          {
            name: 'CandidateId',
            value: taskDetails?.candidateId ? taskDetails?.candidateId?.toString() : '',
          },
          {
            name: 'OrderId',
            value: taskDetails?.orderId ? taskDetails?.orderId?.toString() : '',
          },
          {
            name: 'Description',
            value: ` ${
              versionUpdated === StatusUpdateModalVersion.PARENT_TASK
                ? taskDetails?.statusUpdatedByName + ' ' + statusText
                : taskDetails?.createdByFirstName +
                  ' ' +
                  taskDetails?.createdByLastName +
                  ' ' +
                  ':' +
                  ' ' +
                  taskDetails?.description
            }`,
          },
          {
            name: 'TaskCategory',
            value: taskDetails?.taskCategory ? taskDetails?.taskCategory : '',
          },
          {
            name: 'DueDate',
            value: taskDetails?.dueDate ? taskDetails?.dueDate : '',
          },
          {
            name: 'EntityId',
            value: entityId,
          },
          {
            name: 'SentDate',
            value: moment.tz(new Date(), 'America/Los_Angeles').format(),
          },
          {
            name: 'Version',
            value: versionUpdated.toString(),
          },
        ],
        body: '',
        level: 1,
        templateId: '',
        tos: getAssignedToList(),
        ccs: [],
        groupName: '',
      };
      if (
        taskNotificationPayload?.tos?.length > 0 &&
        (taskNotificationPayload?.useSubType === '3' || taskNotificationPayload?.useSubType === '4')
      ) {
        if (taskDetails?.companyId > 0) {
          taskNotificationPayload?.associatedRecords?.push({
            name: 'BrandId',
            value: taskDetails?.companyId?.toString(),
          });
        }
        dispatch(taskDetailsActions.sendTaskUpdatedNotification(taskNotificationPayload));
        dispatch(taskDetailsActions.setVersionUpdated(null));
        dispatch(taskDetailsActions.setTaskStatusData(undefined));
      }
    }
  };
  useEffect(() => {
    let notificationEntity;
    if (taskDetails?.placementId) {
      notificationEntity = TaskEntity.PLACEMENTS;
    } else if (taskDetails?.facilityId) {
      notificationEntity = TaskEntity.FACILITIES;
    } else if (taskDetails?.orderId) {
      notificationEntity = TaskEntity.ORDERS;
    } else if (taskDetails?.candidateId) {
      notificationEntity = TaskEntity.CANDIDATES;
    }
    if (taskDetails) {
      if (
        (versionUpdated === StatusUpdateModalVersion.PARENT_TASK ||
          versionUpdated === StatusUpdateModalVersion.SUBTASK) &&
        !isExternal
      ) {
        getTaskStatusNotifications(notificationEntity);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskDetails, versionUpdated]);
};
