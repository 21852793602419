import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IWorkExperienceData, IWorkExperienceView } from './types';
import { businessActions } from './business-actions';
import { serviceActions } from './async-actions';

export const initialState: IWorkExperienceView = {
  workExperienceData: {
    totalYearsOfExperience: 0,
    totalMonthsOfExperience: 0,
    totalApprovedReferenceCount: 0,
    maxEmploymentGap: 0,
    education: [],
    employment: [],
    skillsets: [],
    candidateWorkExperience: {
      totalYearsOfExp: 0,
      totalMonthsOfExp: 0,
      hasGraduationDate: true,
      employmentGapDays: [],
      skillsets: [
        {
          totalYearsOfExperience: 0,
          totalMonthsOfExperience: 0,
          lastThreeYearsYearsOfExperience: 0,
          lastThreeYearsMonthsOfExperience: 0,
          totalYearsOfExperienceText: '',
          totalMonthsOfExperienceText: '',
          lastThreeYearsYearsOfExperienceText: '',
          lastThreeYearsMonthsOfExperienceText: '',
          yearsOfExperience: 0,
          monthsOfExperience: 0,
          disciplineId: 0,
          disciplineAbbr: '',
          specialtyId: 0,
          specialtyAbbr: '',
          subSpecialtyId: null,
          subSpecialtyAbbr: null,
          interviewRejected: null,
          interviewReason: null,
          interverStatus: null,
          interviewRating: null,
          interviewDate: null,
          requestedOn: null,
          matchingDegree: null,
          matchingMajor: null,
          matchingDegreesMajors: null,
          isMissingDisciplineEducation: false,
          isVerified: false,
        },
      ],
    },
  },
  editWorkExperienceAPIStatusCode: null,
  referenceListByWorkExperience: [],
  selectedWorkExperience: null,
};

const slice = createSlice({
  name: 'candidateWorkExperienceView',
  initialState,
  reducers: {
    setWorkExperience(state, action: PayloadAction<IWorkExperienceData>) {
      return { ...state, workExperienceData: action.payload };
    },
    setEditWorkExperienceAPIStatusCode(state, action: PayloadAction<any>) {
      return { ...state, editWorkExperienceAPIStatusCode: action.payload };
    },
    setSelectedWorkExperience(state, action) {
      return { ...state, selectedWorkExperience: action.payload };
    },
  },
});

export const { name: workExperienceSliceKey, reducer: workExperienceReducer } = slice;

export const workExperienceActions = { ...slice.actions, ...businessActions, ...serviceActions };
