import { takeLatest, put, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { placementStatusActions } from './PlacementStatus.redux';
import { PlacementStatusFlattenData } from '../Helpers/PlacementStatusTableHelper';
import { getExpiringTotalCount, getPlacementPipelineCounts } from '../Helpers/PlacementStatusHelpers';
import { getMultiSelectOptions } from 'app/ComponentLibrary/Filter/helper';
import { placementByStatus } from 'app/services/PlacementServices/PlacementServices';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { globalSearchActions } from 'store/redux-store/global-search/slice';

export function* requestCredentialingWorkDeskPlacements(
  action: PayloadAction<{
    userId: number;
    filter?: {
      values: any;
      setValue: (
        name: string,
        value: unknown,
        options?:
          | Partial<{
              shouldValidate: boolean;
              shouldDirty: boolean;
            }>
          | undefined,
      ) => void;
    };
  }>,
) {
  try {
    yield put(placementStatusActions.setLoading(true));
    const results = yield call(async () => {
      const r = await placementByStatus(
        {
          status: [0, 1, 2, 3, 4, 5, 6, 7],
        },
        1,
        1000,
        ['-startDate'],
      );
      return {
        placementByStatusData: r,
      };
    });

    if (results && results?.placementByStatusData) {
      const responseData = results?.placementByStatusData;
      const flattenedData = PlacementStatusFlattenData(responseData.placements);
      if (flattenedData) {
        yield put(
          globalSearchActions.setSearchResults({
            count: flattenedData?.length,
            items: flattenedData || [],
            miscellaneous: { positionCount: 0, jobMatchHealth: false, jobInterestHealth: false },
          }),
        );
      } else yield put(globalSearchActions.reset());
      const counts = getPlacementPipelineCounts(flattenedData);

      const expiringTotalCount = getExpiringTotalCount(flattenedData);
      const filterSpecs = {
        placementIDOpts: getMultiSelectOptions(responseData.filterSpecs.placementIDOpts),
        candidateNameOpts: getMultiSelectOptions(responseData.filterSpecs.candidateNameOpts),
        placementStatusOpts: getMultiSelectOptions(responseData.filterSpecs.placementStatusOpts),
        clientNameOpts: getMultiSelectOptions(responseData.filterSpecs.clientNameOpts),
        disciplineSpecilityOpts: getMultiSelectOptions(responseData.filterSpecs.disciplineSpecilityOpts),
        candidateIdOpts: getMultiSelectOptions(responseData.filterSpecs.candidateIdOpts),
      };
      yield put(placementStatusActions.setCounts(counts));
      yield put(placementStatusActions.setExpiringTotalCount(expiringTotalCount));
      yield put(placementStatusActions.setFlattenedData(flattenedData));
      yield put(placementStatusActions.setDataLoaded(true));
      yield put(placementStatusActions.setFilterSpecs(filterSpecs));
      yield put(placementStatusActions.setLoading(false));
    }
  } catch (error) {
    yield put(placementStatusActions.setLoading(false));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestCredentialingWorkDeskPlacements',
        area: 'src/app/components/CredentialingWorkDesk/PlacementStatus/Store/PlacementStatus.saga.ts',
      },
    });
  }
}

export function* credentialWorkDeskPlacementStatusSaga() {
  yield takeLatest(
    placementStatusActions.credentialingWorkDeskPlacementStatusData.type,
    requestCredentialingWorkDeskPlacements,
  );
}
