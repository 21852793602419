import React from 'react';
import { Typography } from 'amn-ui-core';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import { Icon } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';

const useStyles = makeStyles()(theme => ({
  iconStyle: {
    top: '-4px',
    position: 'relative',
    height: '30px',
  },
}));

export const SnackIconImage = ({ severity, iconColor }) => {
  return severity === 'error' ? (
    <ErrorOutlineIcon sx={theme => ({ color: iconColor ? iconColor : theme.palette.framework.alertColors.failure })} />
  ) : severity === 'success' ? (
    <CheckCircleOutlineIcon
      sx={theme => ({ color: iconColor ? iconColor : theme.palette.framework.alertColors.success })}
    />
  ) : severity === 'info' ? (
    <InfoOutlinedIcon sx={theme => ({ color: iconColor ? iconColor : theme.palette.framework.alertColors.info })} />
  ) : severity === 'warning' ? (
    <WarningIcon sx={theme => ({ color: iconColor ? iconColor : theme.palette.framework.alertColors.warning })} />
  ) : (
    <></>
  );
};

export const getHideDuration = (severity: string) => {
  return severity === 'error' ? 5000 : 3000;
};

export const MessageSection = ({ message, severity, isAutoWidth }) => {
  return (
    <Typography
      data-testid="render-message"
      variant="body1"
      sx={{ pl: '10px', width: isAutoWidth ? 'auto' : isNullOrUndefined(severity) ? '312px' : '295px' }}
      component="span"
    >
      <div dangerouslySetInnerHTML={{ __html: message }} />
    </Typography>
  );
};

export const IconSection = ({ iconColor, iconImage, severity }) => {
  const { classes } = useStyles();
  return (
    <Icon className={classes.iconStyle} data-testid="render-icon">
      {iconImage ? iconImage : <SnackIconImage severity={severity} iconColor={iconColor} />}
    </Icon>
  );
};
