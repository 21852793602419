import { TextField, InputAdornment, Typography } from 'amn-ui-core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { missingField } from 'app/constants';
import { makeStyles } from 'tss-react/mui';
import { validDecimal } from 'app/helpers/numberHelper';
const useStyles = makeStyles()(theme => ({
  rangeEndLabel: {
    margin: '15px 0 0 4px',
    padding: '5px 30px 0 0',
  },
  removeSpinBtn: {
    '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));

export const DefaultCell = () => {
  return <Typography style={{ whiteSpace: 'nowrap' }}>{missingField}</Typography>;
};

export const GWWCell = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { label } = props;
  const textFieldRef = React.useRef<any>();

  const onKeyDown = e => {
    const newVal = textFieldRef.current.value + e.key.replace(/[^0-9]/g, '');
    const valid =
      /^([0-9]|Control|Meta|Shift|ArrowLeft|ArrowRight|Backspace|Delete|Enter)$/.test(e.key) ||
      ((e.metaKey || e.ctrlKey) && e.keyCode === 65);

    if (!valid || (newVal === '' && e.keyCode !== 8) || (e.target.value.length >= 3 && e.keyCode !== 8)) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handleInputChange = value => {
    const updatedShifts = props.rateElement?.shifts?.map(shift => {
      if (shift.id === props.shiftDetails?.id) {
        return {
          ...shift,
          inputValue: value,
        };
      } else {
        return { ...shift };
      }
    });
    const row = {
      ...props.rateElement,
      shifts: updatedShifts,
    };
    props.setRow(row);
  };

  return (
    <TextField
      label={label}
      variant="filled"
      value={props.value}
      inputRef={textFieldRef}
      type="text"
      style={{ width: '130px' }}
      className={classes.removeSpinBtn}
      onKeyDown={onKeyDown}
      onChange={e => handleInputChange(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position={'end'} classes={{ positionEnd: classes.rangeEndLabel }}>
            {t('notification.ocbr.hours')}
          </InputAdornment>
        ),
      }}
    />
  );
};

export const SingleRateCell = props => {
  const { classes } = useStyles();
  const { label } = props;
  const textFieldRef = React.useRef<any>();
  const [error, setError] = useState<string>(null);

  const onKeyDown = e => {
    const newVal = textFieldRef.current.value + e.key.replace(/[^0-9.-]/g, '');
    const valid =
      /^([0-9.]|Control|Meta|Shift|ArrowLeft|ArrowRight|Backspace|Delete|Enter)$/.test(e.key) ||
      ((e.metaKey || e.ctrlKey) && e.keyCode === 65);

    if (!valid || (newVal === '' && e.keyCode !== 8)) {
      e.preventDefault();
      e.stopPropagation();
    } else if (e.keyCode === 13) {
      if (!!textFieldRef.current.value) {
        textFieldRef.current.value = parseFloat(newVal).toFixed(3);
      }
    } else if (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 37 && e.keyCode !== 39) {
      if (!validDecimal(newVal, { numberLength: 1, decimalLength: 3 })) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  };

  const onBlur = e => {
    const newVal = textFieldRef.current.value;
    if (!Number.isNaN(newVal) && newVal?.length > 0) {
      let val = '';
      if (newVal === '.') {
        val = parseFloat('0').toFixed(3);
      } else {
        val = parseFloat(newVal).toFixed(3);
      }
      textFieldRef.current.value = val;
      setError(null);
      handleInputChange(textFieldRef.current.value);
    }
  };

  const handleInputChange = value => {
    const updatedShifts = props.rateElement?.shifts?.map(shift => {
      if (shift.id === props.shiftDetails?.id) {
        return {
          ...shift,
          inputValue: value,
        };
      } else {
        return { ...shift };
      }
    });
    const row = {
      ...props.rateElement,
      shifts: updatedShifts,
    };
    props.setRow(row);
  };

  return (
    <TextField
      label={label}
      variant="filled"
      value={props.value}
      inputRef={textFieldRef}
      type="text"
      style={{ width: '130px' }}
      className={classes.removeSpinBtn}
      helperText={error}
      error={error ? true : false}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      onChange={e => handleInputChange(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position={'start'} classes={{ positionEnd: classes.rangeEndLabel }}>
            {'$'}
          </InputAdornment>
        ),
      }}
    />
  );
};

export const SingleOrRangeRateCell = props => {
  const { setError } = props;
  const { classes } = useStyles();
  const [rangeError, setRangeError] = useState<string>(null);
  const { label } = props;
  const { t } = useTranslation();
  const textFieldRef = React.useRef<any>();

  const onKeyDown = e => {
    const newVal = textFieldRef.current.value + e.key.replace(/[^0-9.-]/g, '');
    const valid =
      /^([0-9.-]|Control|Meta|Shift|ArrowLeft|ArrowRight|Backspace|Delete|Enter)$/.test(e.key) ||
      ((e.metaKey || e.ctrlKey) && e.keyCode === 65);

    if (!valid || (newVal === '' && e.keyCode !== 8)) {
      e.preventDefault();
      e.stopPropagation();
    } else if (e.keyCode === 13) {
      if (!!textFieldRef.current.value) {
        if (!(newVal as string).includes('-')) {
          textFieldRef.current.value = parseFloat(newVal).toFixed(2);
          setError(null);
          setRangeError(null);
        } else {
          const fullValue = newVal.split('-');
          if (fullValue.length === 1) {
            textFieldRef.current.value = parseFloat(fullValue[0]).toFixed(2).toString();
            setError(null);
            setRangeError(null);
          } else {
            setRange(fullValue);
          }
        }
      }
    } else if (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 37 && e.keyCode !== 39) {
      const fullValue = newVal.split('-');
      if (fullValue.length > 0) {
        if (
          !validDecimal(fullValue[0], { numberLength: 3, decimalLength: 2 }) ||
          (fullValue.length > 1 && !validDecimal(fullValue[1], { numberLength: 3, decimalLength: 2 }))
        ) {
          e.preventDefault();
          e.stopPropagation();
        }
      }
    }
  };

  const setRange = (fullValue: string[]) => {
    let val1 = parseFloat(fullValue[0]);
    let val2 = parseFloat(fullValue[1]);
    if (!Number.isNaN(val1) && !Number.isNaN(val2)) {
      validRange(val1, val2);
      textFieldRef.current.value = val1.toFixed(2) + '-' + val2.toFixed(2);
    } else if (!Number.isNaN(val1) && Number.isNaN(val2)) {
      textFieldRef.current.value = val1.toFixed(2);
      setError(null);
      setRangeError(null);
    } else {
      textFieldRef.current.value = '';
      setError(null);
      setRangeError(null);
    }
    handleInputChange(textFieldRef.current.value);
  };

  const validRange = (val1: number, val2: number) => {
    if (val1 > val2) {
      setError(t('order.newOrder.rateCard.minMaxRangeError'));
      setRangeError(t('order.newOrder.rateCard.minMaxRangeError'));
    } else {
      setError(null);
      setRangeError(null);
    }
  };

  const onBlur = () => {
    const newVal = textFieldRef?.current?.value;
    const fullValue = newVal?.split('-');
    if (fullValue?.length === 1) {
      let val = '';
      if (newVal === '.') {
        val = parseFloat('0').toFixed(2);
      } else {
        let val1 = parseFloat(fullValue[0]);
        if (!Number.isNaN(val1)) {
          val = val1?.toFixed(2);
          setError(null);
          setRangeError(null);
          handleInputChange(textFieldRef.current.value);
        }
      }
      textFieldRef.current.value = val;
      handleInputChange(textFieldRef.current.value);
    } else {
      setRange(fullValue);
    }
  };

  const handleInputChange = value => {
    const updatedShifts = props.rateElement?.shifts?.map(shift => {
      if (shift.id === props.shiftDetails?.id) {
        return {
          ...shift,
          inputValue: value,
        };
      } else {
        return { ...shift };
      }
    });
    const row = {
      ...props.rateElement,
      shifts: updatedShifts,
    };
    props.setRow(row);
  };

  return (
    <TextField
      label={label}
      variant="filled"
      value={props.value}
      inputRef={textFieldRef}
      type="text"
      style={{ width: '130px' }}
      className={classes.removeSpinBtn}
      helperText={rangeError}
      error={rangeError ? true : false}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      onChange={e => handleInputChange(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position={'start'} classes={{ positionEnd: classes.rangeEndLabel }}>
            {'$'}
          </InputAdornment>
        ),
      }}
    />
  );
};
