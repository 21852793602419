import { missingField } from 'app/constants';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { Concatenate } from 'utils/string/string';
import { TableSection } from '../../../customComponents/TableSection';
import { getRequirementCheck } from '../../../helper';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import {
  selectServiceFailures,
  selectSubmissionInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { RequirementsRow } from '../../TableComponents/TableComponents';
import { requirementType } from 'app/models/Placement/PlacementSummary';

export const Covid = () => {
  const { t } = useTranslation();

  const {
    orderReq: {
      order: { covid: orderReqItem },
      candidate: { covid: candidateReqItem },
    },
    requirementFilter,
  } = useSelector(selectSubmissionInfo);
  const { covidOrderRequirement: serviceFailed } = useSelector(selectServiceFailures);

  const { promiseInProgress } = usePromiseTracker({
    area: 'placement-submission-covid-order-requirement',
    delay: 0,
  });

  const { placementId } = useContext(AddOnContext);
  const dispatch = useDispatch();
  const { display } = useRequirementFilter('submission-order', requirementFilter, orderReqItem?.type);

  return display ? (
    <RequirementsRow
      id="placement-submission-order-covid"
      title={t('placement.profile.summary.submission.order.covid.title')}
      requirementCell={orderReqItem ? <RowKey covid={orderReqItem} t={t} /> : missingField}
      candidateCell={ candidateReqItem ? 
        <RowValue orderCovid={orderReqItem} candidatecovid={candidateReqItem} t={t} /> : missingField
      }
      loading={promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() =>
        dispatch(placementSummaryActions.getCovidOrderRequirement({ placementId, isRetryingService: true }))
      }
    />
  ) : null;
};

const RowValue= ({ orderCovid, candidatecovid, t }) => {
  return (
    <TableSection
      items={[
        {
          primaryContent: `${t('placement.profile.summary.submission.order.covid.line1')}: ${
            (candidatecovid?.data?.vaccinationStatus) ?? missingField
          }`,
          icon: orderCovid && orderCovid?.type === requirementType.risk ? getRequirementCheck(orderCovid?.type) : null,
        },
      ]}
    />
  );
};

const RowKey = ({ covid, t }) => {
  const declinationType = covid?.data?.declinationTypes?.length
    ? Concatenate(
        covid?.data?.declinationTypes.map(x => x.description),
        ', ',
      )
    : 'None';

  return (
    <TableSection
      items={[
        {
          primaryContent: `${t('placement.profile.summary.submission.order.covid.line2')} ${
            covid?.data?.covidVaxReq ? 'Yes' : 'No'
          }\n\n${t('placement.profile.summary.submission.order.covid.line3')} ${declinationType}`,
          icon: covid.type !== requirementType.risk ? getRequirementCheck(covid.type) : null,
        },
      ]}
    />
  );
};
