import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { FormControl, Grid, MenuItem, Select } from 'amn-ui-core';
import { trackEvent } from 'app-insights/appInsightsTracking';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import { PreferenceCosmosKeys } from 'oidc/UserDevicePreference/utils';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import {
  globalSearchActions,
  globalSearchReducer,
  globalSearchSliceKey,
  initialSearchAttributes,
} from 'store/redux-store/global-search/slice';
import { FilterChoice, ISearchAttributes } from 'store/redux-store/global-search/types';
import { makeStyles } from 'tss-react/mui';
import { isNumeric } from 'utils/string/string';
import { globalSearchSaga } from '../../../store/redux-store/global-search/saga';
import { breadcrumbActions } from '../Common/BreadcrumbNavigation/BreadcrumbNavigation.redux';
import { SearchField } from '../Common/Search/SearchField';
import { navigationStickActions } from '../GlobalNavigationMenu/NavigationStick.redux';
import { navigationProfilesReducer, navigationProfilesSliceKey } from './store/NavigationProfiles.redux';

const useStyles = makeStyles()(theme => ({
  selectField: {
    height: '32px',
    width: '130px',
  },
  dropdownStyle: {
    border: '1px solid #D1D1D1',
    boxShadow: 'none',
    borderRadius: 3,
    marginTop: '6px',
    width: 100,
  },
  selectFocus: {
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: '#ffffff !important',
    color: '#52429A',
    fontSize: 12,
  },
  menu: {
    color: '#999999',
    fontSize: 12,
    '&:hover, &:focus': {
      color: 'white',
      backgroundColor: '#52429A',
    },
  },
}));

export const GlobalSearch: React.FC = () => {
  const userPreference = useSelector(selectUserPreference);
  useInjectReducer({ key: globalSearchSliceKey, reducer: globalSearchReducer });
  useInjectSaga({ key: globalSearchSliceKey, saga: globalSearchSaga });
  useInjectReducer({ key: navigationProfilesSliceKey, reducer: navigationProfilesReducer });
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  //To reset the form default values after the api call is made
  useEffect(() => {
    if (userPreference) {
      reset({
        filterOption: userPreference.globalSearchDropDown?.value || initialSearchAttributes.filterOption,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreference.globalSearchDropDown]);
  
  const { control, watch, handleSubmit, reset } = useForm({
    defaultValues: {
      filterOption:
        userPreference && userPreference.globalSearchDropDown?.value !== undefined
          ? userPreference.globalSearchDropDown.value
          : initialSearchAttributes.filterOption,
      searchString: initialSearchAttributes.searchString,
    },
  });

  const OnSearchEvent = (searchAttributes: ISearchAttributes) => {
    trackEvent({
      type: 'event',
      name: `Global Search Attempt`,
      properties: { ...searchAttributes, isNumeric: isNumeric(searchAttributes.searchString) },
    });
    /**Reset action to remove existing page breadcrumbs */
    dispatch(breadcrumbActions.reset());
    dispatch(globalSearchActions.setSearchOptions(searchAttributes));
    dispatch(globalSearchActions.setShowPreferenceFilterDropdown(false));
    dispatch(navigationStickActions.setSelectedMenuByKey('home'));
    dispatch(navigationStickActions.setSelectedSubMenu(undefined));
    dispatch(
      userDevicePreferenceActions.saveGlobalSearchPreferenceAction({
        id: PreferenceCosmosKeys.globalSearchDropdown,
        value: searchAttributes.filterOption,
      }),
    );

    /* To be updated to single route push statement */
    /* Temporary condition to retain earlier global search implemntation*/
    if (
      [FilterChoice.Candidate, FilterChoice.Placement, FilterChoice.Order, FilterChoice.Facility].includes(
        FilterChoice[searchAttributes.filterOption],
      )
    ) {
      history.push({
        pathname: `/search/${searchAttributes.filterOption.toLowerCase()}${
          !isNullOrUndefined(searchAttributes.searchString) && searchAttributes.searchString.trim() !== ''
            ? `/${searchAttributes.searchString}`
            : ''
        }`,
      });
    } else window.location.pathname !== '/' && history.replace('/');
  };

  const filterOptions = Object.values(FilterChoice);
  const filterSelected = watch('filterOption') || FilterChoice.Candidate;

  return (
    <form onSubmit={handleSubmit(OnSearchEvent)}>
      <Grid container spacing={3}>
        <Grid item>
          <FormControl>
            <Controller
              as={
                <Select
                  variant="outlined"
                  color="primary"
                  className={classes.selectField}
                  inputProps={{ className: classes.selectFocus }}
                  MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                  IconComponent={ExpandMoreOutlinedIcon}
                >
                  {filterOptions.map((item, index) => {
                    //hide selected option out the drop down
                    const hideStatus = filterSelected === item ? 'none' : '';
                    return (
                      <MenuItem
                        disableTouchRipple
                        style={{ display: hideStatus }}
                        className={classes.menu}
                        key={index}
                        value={item}
                        aria-labelledby={item}
                      >
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              }
              name="filterOption"
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Controller
            render={({ ref, ...rest }) => <SearchField placeholder={t('appHeader.searchPlaceHolder')} {...rest} />}
            name="searchString"
            control={control}
          />
        </Grid>
      </Grid>
    </form>
  );
};
