/* eslint-disable tss-unused-classes/unused-classes */
/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from 'tss-react/mui';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { Cancel } from 'app/components/Common/CancelModal/Cancel';
import _cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import {
  CHARACTER_LIMIT,
  IDropdownOption,
  Views,
  assignCoverageVar,
  defaultValues,
  getEditPostBody,
  getPostBody,
  mapLabelsWithView,
} from './assignHelper';
import {
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  FormLabel,
  Autocomplete,
  Chip,
  Avatar,
} from 'amn-ui-core';
import { getAllEmployees } from 'app/services/TaskServices/TaskServices';
import { selectUser } from 'oidc/user.selectors';
import { coverageActions } from 'store/redux-store/user-coverage/async-actions';
import { useTranslation } from 'react-i18next';
import { DateTimePicker } from './DateTimePicker';
import { IRequestCoverage } from 'app/models/UserCoverage/UserCoverage';
import { selectCoverageCreatedId, selectCoverageUpdatedId } from 'store/redux-store/user-coverage/selectors';
import CancelIcon from '@mui/icons-material/Cancel';

export const assignCoverageModalStyles = makeStyles()((theme, props) => ({
  modalContainer: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '450px',
      overflow: 'visible',
    },
  },
  dialogContent: {
    paddingTop: '30px !important',
    margin: 'auto',
    width: '100%',
  },
  inputs: {
    width: '100%',
    overflow: 'auto',
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    '&:hover': {
      '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
        color: theme.palette.primary.main,
      },
    },
  },
  countHelper: {
    '& .MuiFormHelperText-root': {
      color: 'inherit',
      textAlign: 'end',
    },
  },
  banner: {
    paddingBottom: '1px !important',
  },
  validationLoader: {
    position: 'absolute',
    zIndex: 999,
    left: 'calc(50% - 20px)',
    top: '50%',
  },
  fullWidth: {
    width: '100%',
    justifyContent: 'unset',
  },
  picker: {
    backgroundColor: 'transparent !important',
    border: 'none',
    width: '115px',
    '& *': {
      backgroundColor: 'transparent !important',
    },
  },
  allDayLbl: {
    fontSize: '14px',
  },
  avatar: {
    width: '18px !important',
    height: '18px  !important',
    textAlign: 'center',
    font: 'normal normal bold 8px/11px Roboto  !important',
    letterSpacing: '0px',
    color: '#FFFFFF  !important',
    opacity: 1,
    margin: '0px 3px 0 0',
    background: '#888888 !important',
  },
  chipStyle: {
    minWidth: '120px',
    height: '30px',
    margin: '3px auto 3px 0px !important',
    justifyContent: 'unset',
    backgroundColor: '#F0F0F0',
    '& .MuiChip-label': {
      paddingRight: '12px',
      height: '19px',
      color: '#333333',
      font: 'normal normal normal 14px/20px Roboto',
    },
    '& .MuiChip-deleteIcon': {
      color: '#888888  !important',
      opacity: 1,
    },
  },
}));

export const AssignCoverage = ({
  open,
  editCoverageData,
  handleClose,
}: {
  open: boolean;
  editCoverageData: any;
  handleClose: () => void;
}) => {
  const { classes } = assignCoverageModalStyles();
  const dispatch = useDispatch();
  const { promiseInProgress: isCallingAPI } = usePromiseTracker({ area: 'save-coverage-request', delay: 0 });
  const { promiseInProgress: isCallingUpdateAPI } = usePromiseTracker({ area: 'update-coverage-request', delay: 0 });

  const coverageCreatedId = useSelector(selectCoverageCreatedId);
  const coverageUpdatedId = useSelector(selectCoverageUpdatedId);

  const formMethods = useForm<IRequestCoverage>({
    defaultValues: defaultValues,
    shouldUnregister: false,
  });
  const {
    handleSubmit,
    control,
    trigger,
    getValues,
    setValue,
    formState: { isDirty, errors },
    reset,
  } = formMethods;
  const userInfo = useSelector(selectUser);
  const { t } = useTranslation();
  const [view, setView] = useState<Views>(Views.Add);
  const [coverageForEmployees, setCoverageForEmployees] = useState<IDropdownOption[]>([]);
  const [coveredByEmployees, setCoveredByEmployees] = useState<IDropdownOption[]>([]);
  const [defaultCoverageForEmployee, setDefaultCoverageForEmployee] = useState<IDropdownOption | null>(null);
  const [coveredByUserValue, setCoveredByUserValue] = useState(null);
  const [isAllDay, setIsAllDay] = useState<boolean>(true);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [shrink, setShrink] = useState<boolean>(false);

  const onCloseModal = () => {
    if (Object.keys(formMethods.formState.dirtyFields)?.length > 0) {
      setCancelModalOpen(true);
    } else {
      onCloseCall();
    }
  };

  const onCloseCall = () => {
    setIsAllDay(true);
    setShrink(false);
    setCoveredByUserValue(null);
    setCancelModalOpen(false);
    setCoverageForEmployees([]);
    setCoveredByEmployees([]);
    setDefaultCoverageForEmployee(null);
    setView(Views.Add);
    handleClose();
    dispatch(coverageActions.setNewCoverageId(undefined));
    dispatch(coverageActions.setUpdatedCoverageId(undefined));
    reset({
      ...defaultValues,
    });
  };

  const fetchEmployeeDetails = async (newInputValue: string) => {
    var result = await getAllEmployees(newInputValue);
    if (result) {
      const temp: any[] = [];
      result.map((x, idx) => {
        temp.push({ label: `${x.employeeFirstName} ${x.employeeLastName}`, id: x.employeeId });
      });
      return temp;
    }
  };

  const handleSelectChange: {
    [key: string]: (param1: any, param2: string) => unknown;
  } = {
    coverageFor: async (event, newInputValue: string) => {
      setShrink(true);
      if (event && newInputValue?.length > 2) {
        var result = await fetchEmployeeDetails(newInputValue);
        if (result) {
          setCoverageForEmployees(result.filter(item => item.id !== getValues(assignCoverageVar.coveringEmployeeId)));
        }
      } else {
        if (newInputValue?.length === 0) {
          setShrink(false);
        }
      }
    },
    coveredBy: async (event, newInputValue: string) => {
      setShrink(true);
      if (event && newInputValue?.length > 2) {
        var result = await fetchEmployeeDetails(newInputValue);
        if (result) {
          setCoveredByEmployees(result.filter(item => item.id !== getValues(assignCoverageVar.coveredEmployeeId)));
        }
      } else {
        if (newInputValue?.length === 0) {
          setShrink(false);
        }
      }
    },
  };

  const getInitials = fullname => {
    if (fullname) {
      const fullName = fullname.split(' ');
      if (fullName?.length > 1) {
        const value = fullName?.shift()?.charAt(0) + fullName?.pop()?.charAt(0);
        return value;
      } else {
        const initial = fullName?.shift()?.charAt(0);
        return initial;
      }
    }
  };

  const handleSelect: {
    [key: string]: (param1: any, param2: any) => unknown;
  } = {
    coverageFor: async (event, newInputValue: any) => {
      if (newInputValue?.label !== '') {
        const firstSplitIndex = newInputValue?.label?.indexOf(' ');

        if (firstSplitIndex !== -1) {
          const firstName = newInputValue?.label?.slice(0, firstSplitIndex);
          const lastName = newInputValue?.label?.slice(firstSplitIndex + 1);
          const defaultCoverageForEmployeeValue: IDropdownOption = {
            label: newInputValue?.label,
            id: newInputValue?.id,
          };
          setDefaultCoverageForEmployee(defaultCoverageForEmployeeValue);
          setValue(assignCoverageVar.coveredEmployeeId, newInputValue?.id, { shouldDirty: true });
          setValue(assignCoverageVar.coveredEmployeeFirstName, firstName, { shouldDirty: true });
          setValue(assignCoverageVar.coveredEmployeeLastName, lastName, { shouldDirty: true });
        }
      }
    },
    coveredBy: async (event, newInputValue: any) => {
      if (newInputValue?.label !== '') {
        // const name = newInputValue?.label?.split(' ');
        const firstSplitIndex = newInputValue?.label?.indexOf(' ');

        if (firstSplitIndex !== -1) {
          const firstName = newInputValue?.label?.slice(0, firstSplitIndex);
          const lastName = newInputValue?.label?.slice(firstSplitIndex + 1);
          const coveredByUser = newInputValue?.label;
          setCoveredByUserValue(coveredByUser);
          setShrink(true);
          setValue(assignCoverageVar.coveringEmployeeId, newInputValue?.id, { shouldDirty: true });
          setValue(assignCoverageVar.coveringEmployeeFirstName, firstName, { shouldDirty: true });
          setValue(assignCoverageVar.coveringEmployeeLastName, lastName, { shouldDirty: true });
        }
      }
    },
  };

  const handleChipDelete = async () => {
    setValue('coveringEmployeeId', '');
    setValue('coveringEmployeeFirstName', '');
    setValue('coveringEmployeeLastName', '');
    setCoveredByUserValue(null);
    setShrink(false);
    var result = await fetchEmployeeDetails('amie');
    if (result) {
      setCoverageForEmployees(result.filter(item => item.id !== getValues(assignCoverageVar.coveringEmployeeId)));
      setCoveredByEmployees(result.filter(item => item.id !== getValues(assignCoverageVar.coveredEmployeeId)));
    }
  };

  const assignCoverage = formData => {
    if (editCoverageData) {
      const data = getEditPostBody(formData, userInfo);
      dispatch(coverageActions.updateCoverageRequestAction(data));
    } else {
      const data = getPostBody(formData, isAllDay, userInfo);
      dispatch(coverageActions.saveCoverageRequestAction(data));
    }
  };

  const getPresetEmpValues = async (s: string) => {
    var result = await fetchEmployeeDetails(s);
    if (result) {
      setCoverageForEmployees(result.filter(item => item.id !== getValues(assignCoverageVar.coveredEmployeeId)));
      setCoveredByEmployees(result.filter(item => item.id !== getValues(assignCoverageVar.coveredEmployeeId)));
    }
  };

  useEffect(() => {
    if (open) {
      getPresetEmpValues('AMIE');
    }
  }, [open]);

  useEffect(() => {
    if (!isAllDay && editCoverageData) {
      setValue(assignCoverageVar.startTime, editCoverageData?.startTime);
      setValue(assignCoverageVar.endTime, editCoverageData?.endTime);
    }
  }, [isAllDay]);

  useEffect(() => {
    if (coverageCreatedId || coverageUpdatedId) {
      onCloseCall();
    }
  }, [coverageCreatedId, coverageUpdatedId]);

  useEffect(() => {
    if (coveredByUserValue) {
      setShrink(true);
    } else {
      setShrink(false);
    }
  }, [coveredByUserValue]);

  useEffect(() => {
    if (editCoverageData) {
      setView(Views.Edit);
      setIsAllDay(editCoverageData?.allDay);
      const defaultCoverageForEmployeeValue: IDropdownOption = {
        label: `${editCoverageData?.coveredEmployeeFirstName} ${editCoverageData?.coveredEmployeeLastName}`,
        id: editCoverageData?.coveredEmployeeId,
      };
      setCoveredByUserValue(
        `${editCoverageData?.coveringEmployeeFirstName} ${editCoverageData?.coveringEmployeeLastName}`,
      );
      setShrink(true);
      setDefaultCoverageForEmployee(defaultCoverageForEmployeeValue);
      reset({ ...editCoverageData });
    }
  }, [editCoverageData]);

  return (
    <FormProvider {...formMethods}>
      <GenericDialog
        open={open}
        draggable
        fullWidth
        className={classes.modalContainer}
        onClose={(e, reason) => {
          if (reason === 'backdropClick') return;
          onCloseModal();
        }}
        variant="blue"
        dialogTitleProps={{
          text: editCoverageData
            ? t('notification.coverage.assignModal.editHeaderTitle')
            : t('notification.coverage.assignModal.headerTitle'),
          closeButton: true,
          expandable: false,
        }}
        dialogContentProps={{
          classes: { root: classes.dialogContent },
        }}
        dialogActions={[
          {
            text: t('notification.coverage.assignModal.cancel'),
            variant: 'contained',
            color: 'tertiary',
            onClick: onCloseModal,
          },
          {
            text: editCoverageData
              ? t('notification.coverage.assignModal.confirm')
              : t('notification.coverage.assignModal.save'),
            variant: 'contained',
            disabled: Object.keys(formMethods.formState.dirtyFields)?.length <= 0 || isCallingAPI || isCallingUpdateAPI,
            onClick: async e => {
              const validCoverage = await trigger();
              if (validCoverage) {
                handleSubmit(assignCoverage)().catch(err => {});
              }
            },
          },
        ]}
      >
        <Grid container spacing={4} data-testid="assign-coverage-container">
          {(isCallingAPI || isCallingUpdateAPI) && <CircularProgress className={classes.validationLoader} />}
          <Grid item xs={12} data-testid="assign-coverage-for">
            <Controller
              control={control}
              name={assignCoverageVar.coveredEmployeeId}
              rules={{ required: true }}
              render={({ ref, onChange, value, ...rest }) => (
                <Autocomplete
                  blurOnSelect
                  filterSelectedOptions
                  disabled={editCoverageData ? true : isCallingAPI || isCallingUpdateAPI}
                  options={coverageForEmployees}
                  defaultValue={defaultCoverageForEmployee}
                  onInputChange={handleSelectChange['coverageFor']}
                  onChange={handleSelect['coverageFor']}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={t('notification.coverage.assignModal.coverageFor')}
                      variant="filled"
                      className={classes.inputs}
                      InputProps={{ ...params.InputProps, endAdornment: null }}
                      error={errors[assignCoverageVar.coveredEmployeeId]}
                      helperText={isDirty && errors[assignCoverageVar.coveredEmployeeId] && t('required')}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="allDay"
              key={'All Day'}
              control={control}
              render={({ ref, value, onChange, ...rest }) => (
                <FormControlLabel
                  sx={{ marginLeft: '0px' }}
                  classes={{ label: classes.allDayLbl }}
                  control={
                    <Switch
                      sx={{ marginLeft: '26px', padding: '6px', fontSize: '14px' }}
                      color="primary"
                      name="allDay"
                      size="small"
                      checked={isAllDay}
                      inputProps={{ 'aria-label': 'default-page-switch' }}
                      onChange={(event, state) => {
                        onChange(state);
                        setIsAllDay(state);
                        setValue(assignCoverageVar.startTime, '');
                        setValue(assignCoverageVar.endTime, '');
                      }}
                      value={isAllDay}
                    />
                  }
                  label={t('notification.coverage.assignModal.allday')}
                  labelPlacement="start"
                />
              )}
            />
          </Grid>
          <Grid sx={{ paddingTop: '25px' }}>
            <FormLabel
              sx={{ color: '#707070', font: 'normal normal normal 14px/19px Roboto' }}
              component="switch"
              className={classes.allDayLbl}
            >
              {isAllDay ? t('notification.taskDetails.yesBtn') : t('notification.taskDetails.noBtn')}
            </FormLabel>
          </Grid>
          <DateTimePicker
            isAllDay={isAllDay}
            editCoverageData={editCoverageData}
            view={view}
            isCallingAPI={isCallingAPI || isCallingUpdateAPI}
            formMethods={formMethods}
          />
          <Grid item xs={12} data-testid="assign-coverage-notes">
            <Controller
              control={control}
              name={assignCoverageVar.notes}
              rules={{
                maxLength: { value: 4000, message: 'notification.createTask.maxLimitLabel' },
              }}
              render={({ ref, onChange, ...rest }) => (
                <TextField
                  multiline
                  fullWidth
                  variant="filled"
                  className={classes.countHelper}
                  color="primary"
                  value={getValues(assignCoverageVar.notes)}
                  maxRows={3}
                  inputProps={{
                    maxLength: CHARACTER_LIMIT,
                  }}
                  disabled={isCallingAPI || isCallingUpdateAPI}
                  id={assignCoverageVar.notes}
                  label={t('notification.coverage.assignModal.notes')}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  helperText={
                    getValues('notes')?.length > 0 ? `${getValues('notes')?.length}/${CHARACTER_LIMIT}` : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} data-testid="assign-coverage-covered-by">
            <Controller
              control={control}
              name={assignCoverageVar.coveringEmployeeId}
              rules={{ required: true }}
              render={({ ref, onChange, value, ...rest }) => (
                <Autocomplete
                  blurOnSelect
                  disabled={isCallingAPI || isCallingUpdateAPI}
                  value={coveredByUserValue}
                  onFocus={() => setShrink(true)}
                  onBlur={() => {
                    coveredByUserValue ? setShrink(true) : setShrink(false);
                  }}
                  onInputChange={handleSelectChange['coveredBy']}
                  onChange={handleSelect['coveredBy']}
                  options={coveredByEmployees}
                  getOptionLabel={() => ''}
                  filterOptions={option => option}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={t('notification.coverage.assignModal.coveredBy')}
                      variant="filled"
                      className={classes.inputs}
                      InputLabelProps={{ shrink: shrink }}
                      error={errors[assignCoverageVar.coveringEmployeeId]}
                      helperText={isDirty && errors[assignCoverageVar.coveringEmployeeId] && t('required')}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: coveredByUserValue ? (
                          <>
                            <Chip
                              avatar={<Avatar className={classes.avatar}>{getInitials(coveredByUserValue)}</Avatar>}
                              className={classes.chipStyle}
                              label={coveredByUserValue}
                              onDelete={handleChipDelete}
                              variant="filled"
                            />
                          </>
                        ) : null,
                        endAdornment: null,
                      }}
                    />
                  )}
                  renderOption={(p, option, state) => {
                    return (
                      <li {...p}>
                        <div>{option?.label}</div>
                      </li>
                    );
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </GenericDialog>
      <Cancel
        openDialog={cancelModalOpen}
        handleConfirmAction={() => onCloseCall()}
        handleCancelAction={() => {
          setCancelModalOpen(false);
        }}
      />
    </FormProvider>
  );
};
