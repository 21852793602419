import { missingField } from 'app/constants';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { TableSection } from '../../../customComponents/TableSection';
import { getRequirementCheck } from '../../../helper';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import {
  selectServiceFailures,
  selectSubmissionInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { RequirementsRow } from '../../TableComponents/TableComponents';

export const Status = () => {
  const { t } = useTranslation();
  const {
    orderReq: {
      order: { status: mfs },
    },
    requirementFilter,
  } = useSelector(selectSubmissionInfo);
  const { promiseInProgress } = usePromiseTracker({
    area: 'placement-submission-status-order-requirement',
    delay: 0,
  });
  const { statusOrderRequirement: serviceFailed } = useSelector(selectServiceFailures);
  const { placementId } = useContext(AddOnContext);
  const dispatch = useDispatch();
  const { display } = useRequirementFilter('submission-order', requirementFilter, mfs?.type);

  return display ? (
    <RequirementsRow
      id="placement-submission-order-status"
      title={t('placement.profile.summary.submission.order.status.title')}
      requirementCell={<RowKey mfs={mfs} t={t} />}
      candidateCell="--"
      loading={promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() =>
        dispatch(placementSummaryActions.getStatusOrderRequirement({ placementId, isRetryingService: true }))
      }
    />
  ) : null;
};

const RowKey = ({ mfs, t }) => (
  <TableSection
    items={[
      {
        icon: mfs ? getRequirementCheck(mfs.type) : null,
        primaryContent: `${t('placement.profile.summary.submission.order.status.line1')}: ${
          mfs ? mfs.data : missingField
        }`,
      },
    ]}
  />
);
