import React from 'react';
import { Box } from 'amn-ui-core';
import Approve from 'app/assets/images/IconImages/Approve.svg';
import Approved from 'app/assets/images/IconImages/Approved.svg';
import ApproveGreyOutline from 'app/assets/images/IconImages/ApproveGreyOutline.svg';
interface PropType {
  iconStyle?: any;
  toggle?: boolean;
  showGreyOutline?: boolean;
}
export const ApproveButton = ({ iconStyle, toggle, showGreyOutline }: PropType) => {
  return (
    <Box>
      <img
        style={iconStyle ? iconStyle : { height: 36, width: 39 }}
        alt="approve-icon"
        src={toggle ? Approved : (showGreyOutline? ApproveGreyOutline : Approve)}
      />
    </Box>
  );
};
