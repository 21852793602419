import { XGridChipCell } from '@AMIEWEB/Common';
import React, { useEffect, useState, useMemo } from 'react';
import { XGrid, Box, ClickAwayListener, MenuItem, MenuList, Popper } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { GridColumns, GridCellParams } from '@mui/x-data-grid-pro';
import { XGridDefaultColumn } from '@AMIEWEB/Common/XGrid/cells/XGridDefaults';
import { useDispatch, useSelector } from 'react-redux';
import PendingApproval from 'app/assets/images/IconImages/Info.svg';
import Void from 'app/assets/images/IconImages/Void.svg';
import Expired from 'app/assets/images/IconImages/InfoRedOutlined.svg';
import { cloneDeep } from 'lodash';
import { ApproveOrRejectCell } from './ApproveOrRejectCell';
import { ApprovalStatusHeader } from './ApprovalStatusHeader';
import { useTranslation } from 'react-i18next';
import { Status } from '../OffContract';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { selectApprovalDocuments, selectedApproveRejectRows } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';

const useStyles = makeStyles<{ showApprovalStatus: boolean }>()((theme, props) => ({
  grid: {
    width: '32rem',
    border: 'none',
    fontSize:'14px',
    '& .MuiDataGrid-row': {
      borderBottom: '1px solid #D5D5D5',
    },
  },
  approveBtn: {
    marginRight: '36px',
  },
  rejectBtn: {
    marginLeft: '12px',
  },
  btn: {
    height: 30,
    width: 35,
  },
  popper: {
    boxShadow: '0px 2px 8px #0000004D',
    borderRadius: '4px',
    opacity: 1,
    zIndex: 5000,
    backgroundColor: '#FFFFFF',
  },
  icon: {
    height: '15px',
    width: '15px',
    borderRadius: '10px',
    border: 'none',
    marginRight: '10px',
    marginLeft: '2.5px',
    marginBottom: '4px',
  },
  pendingApprovalIcon: {
    height: '20px',
    width: '20px',
    borderRadius: '10px',
    border: 'none',
    marginRight: '10px',
    marginBottom: '2px',
  },
  headerTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 500,
    cursor: 'pointer',
    WebkitTapHighlightColor: 'transparent',
    lineHeight: '48px',
    fontSize: '12px',
    fontFamily: 'inherit',
    color: 'black',
    opacity: 0.87,
    width: '100%',
    marginLeft: props.showApprovalStatus ? '5px' : '0px',
  },
}));

export const RateDetails = props => {
  const [hoverApprove, setHoverApprove] = useState<boolean>(false);
  const [hoverReject, setHoverReject] = useState<boolean>(false);
  const [columns, setColumns] = useState<GridColumns<any>>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [statusChanged, setStatusChanged] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedRows = useSelector(selectedApproveRejectRows);
  const rateDetails = useSelector(selectApprovalDocuments);
  const [sortModel, setSortModel] = React.useState<any>([
    {
      field: 'rateElementName',
      sort: 'desc',
    },
  ]);

  const checkAMAndCCAUserForStatusId = () => {
    if (
      props.ccaOrAMUser &&
      (props.row.statusId === 1 || props.row.statusId === 2 || props.row.statusId === 3 || props.row.statusId === 4)
    ) {
      return true;
    }
    return false;
  };

  const { classes } = useStyles({ showApprovalStatus: props.ccaOrAMUser && !statusChanged });

  useEffect(() => {
    let columns;
    if (statusChanged || props.row.statusId === 4 || !props.ccaUser) {
      columns = approvalDocumentGridColumns(['approveOrReject']);
    } else {
      columns = approvalDocumentGridColumns(['status']);
    }
    setColumns(columns);
  }, [statusChanged]);

  const setStatus = (statusId, index) => {
    let record = cloneDeep(props.rows[0]);
    record.statusId = statusId;
    record.status = Status.find(x => x.id === statusId)?.title;
    props.setStatusId(statusId);
    setRows([record]);
    let records = selectedRows ? cloneDeep(selectedRows) : [];
    let existingRecord = records.find(x => x.rateElementId === record.rateElementId);
    if (existingRecord) {
      existingRecord.statusId = statusId;
      existingRecord.status = Status.find(x => x.id === statusId)?.title;
    } else {
      records.push(record);
    }
    dispatch(facilityActions.setSelectedRejectedRows(records));
    props.setApprovalChangesPending(true);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showActions = () => {
    if (statusChanged || checkAMAndCCAUserForStatusId()) {
      return true;
    }
    return false;
  };

  const approvalDocumentGridColumns = (exclude = [] as string[]): GridColumns<any> =>
    (
      [
        {
          ...XGridDefaultColumn,
          field: 'rateElementName',
          headerName: 'Rate Element',
          headerClassName: 'super-app-theme--header',
          flex: 1,
          resizable: false,
          align: 'left',
          minWidth: 120,
        },
        {
          ...XGridDefaultColumn,
          field: 'rateValue',
          flex: 1,
          minWidth: 150,
          resizable: false,
          align: 'right',
          headerAlign: 'right',
          headerName: 'Rate Value',
          headerClassName: 'super-app-theme--header',
        },
        {
          ...XGridDefaultColumn,
          field: 'shift',
          headerName: 'Shift',
          headerClassName: 'super-app-theme--header',
          align: 'right',
          headerAlign: 'right',
          flex: 1,
          minWidth: 80,
          resizable: false,
        },
        {
          ...XGridDefaultColumn,
          field: 'approveOrReject',
          align: 'left',
          headerAlign: 'left',
          headerName: 'Approval Status',
          hideSortIcons: true,
          flex: 1,
          minWidth: 150,
          resizable: false,
          headerClassName: 'super-app-theme--header',
          sortable: false,
          disableColumnMenu: true,
          renderHeader: params =>
            checkAMAndCCAUserForStatusId() ? (
              <ApprovalStatusHeader params={params} handleClick={handleClick} active={Boolean(anchorEl)} />
            ) : (
              <span className={classes.headerTitle}>{t('notification.facilityDetials.approvalStatus')}</span>
            ),
          renderCell: params => {
            return (
              <ApproveOrRejectCell
                row={params?.row}
                setStatus={setStatus}
                statusId={props.statusId}
                rateDetails={props.changesInRateDetails}
                setRateDetails={props.setChangesInRateDetails}
              />
            );
          },
        },
        {
          ...XGridDefaultColumn,
          field: 'status',
          headerName: 'Status',
          headerClassName: 'super-app-theme--header',
          align: 'left',
          flex: 1,
          resizable: false,
          sortable: showActions() ? false : true,
          disableColumnMenu: showActions() ? true : false,
          hideSortIcons: showActions() ? true : false,
          minWidth: 150,
          headerAlign: 'left',
          renderHeader: params =>
            showActions() ? (
              <ApprovalStatusHeader
                headerName={t('notification.facilityDetials.status')}
                params={params}
                handleClick={handleClick}
                active={Boolean(anchorEl)}
              />
            ) : (
              <span className={classes.headerTitle}>Status</span>
            ),
          renderCell: XGridChipCell({
            size: 'small',
            dynamicColor: (params: GridCellParams) => {
              return props.getColor(params.value);
            },
            renderValue: (params: GridCellParams) => {
              return props.getValue(params.value);
            },
          }),
        },
      ] as GridColumns
    ).filter(item => !exclude.some(field => field === item.field));

  const handleSortModelChange = model => {
    setSortModel(model);
  };

  const handleStatusChange = statusId => {
    setStatusChanged(true);
    setStatus(statusId, 0);
    handleClose();
  };

  const grid = useMemo(() => {
    return (
      <React.Fragment>
        <Box
          sx={{
            width: '100%',
            '& .super-app-theme--header': {
              backgroundColor: '#F5F5F5',
            },
          }}
        >
          <XGrid
            headerHeight={48}
            rowHeight={39}
            className={classes.grid}
            autoHeight={true}
            columns={columns}
            rows={rows || []}
            hideFooter={true}
            loading={props.isCallingAPI}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            initialState={{ pinnedColumns: { right: ['approveOrReject'] ? ['approveOrReject'] : ['status'] } }}
            disableMultipleSelection={true}
          />
        </Box>
      </React.Fragment>
    );
  }, [columns, props.statusId, hoverApprove, statusChanged]);

  useEffect(() => {
    if (props.rows && props.rows.length > 0) {
      props.setStatusId(props.rows[0].statusId);
      let clonedRows = cloneDeep(props.rows);
      setRows(clonedRows);
    }
  }, []);

  useEffect(() => {
    if (props.rows && props.rows.length > 0) {
      if (props.ccaUser && props.row.statusId != 4) {
        let columns = approvalDocumentGridColumns(['status']);
        setColumns(columns);
      } else {
        let columns = approvalDocumentGridColumns(['approveOrReject']);
        setColumns(columns);
      }
    }
  }, [props.rows, props.appUser, props.ccaOrAMUser, props.ccaUser]);

  useEffect(() => {
    let resetRows = cloneDeep(props.rows);
    setRows(resetRows);
    props.setStatusId(props.rows[0].statusId);
    setStatusChanged(false);
    dispatch(facilityActions.setSelectedRejectedRows([]));
  }, [props.cancelled]);

  return (
    <React.Fragment>
      <div className={classes.grid}>{grid}</div>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-end"
        style={{ zIndex: '999' }}
        className={classes.popper}
        disablePortal={false}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList>
            {(props.row.statusId === 1 || props.row.statusId === 3) && (
              <MenuItem
                onClick={() => {
                  handleStatusChange(6);
                }}
              >
                <Box>
                  <img src={Void} alt={t('notification.facilityDetials.void')} className={classes.icon} />
                </Box>
                {t('notification.facilityDetials.void')}
              </MenuItem>
            )}
            {props.row.statusId === 2 && (
              <>
                <MenuItem
                  disabled={rateDetails?.attachments?.length <= 0}
                  onClick={() => {
                    handleStatusChange(3);
                  }}
                >
                  <Box>
                    <img
                      src={PendingApproval}
                      alt={t('notification.facilityDetials.pendingApprovalStatus')}
                      className={classes.pendingApprovalIcon}
                    />
                  </Box>
                  {t('notification.facilityDetials.pendingApprovalStatus')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleStatusChange(6);
                  }}
                >
                  <Box>
                    <img src={Void} alt={t('notification.facilityDetials.void')} className={classes.icon} />
                  </Box>
                  {t('notification.facilityDetials.void')}
                </MenuItem>
              </>
            )}
            {props.row.statusId === 4 && (
              <MenuItem
                onClick={() => {
                  handleStatusChange(5);
                }}
              >
                <Box>
                  <img src={Expired} alt={t('notification.facilityDetials.expired')} className={classes.icon} />
                </Box>
                {t('notification.facilityDetials.expired')}
              </MenuItem>
            )}
          </MenuList>
        </ClickAwayListener>
      </Popper>
    </React.Fragment>
  );
};
