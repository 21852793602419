import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { serviceActions } from './async-actions';
import { initialState } from './utils';
import { IMergeToken } from '../confirmation-template-editor/types';
import { IContractDoc } from './types';
import { businessActions } from './business-actions';

const slice = createSlice({
  name: 'placementCandidateContract',
  initialState,
  reducers: {
    setTemplates(state, action: PayloadAction<IContractDoc[]>) {
      return { ...state, templates: [...action.payload] };
    },
    setSelectedTemplate(state, action: PayloadAction<IContractDoc>) {
      return { ...state, selectedTemplate: action.payload };
    },
    setMergeTokenList(state, action: PayloadAction<{ [key: string]: { label: string; tokens: IMergeToken[] } }>) {
      return { ...state, mergeTokenList: action.payload };
    },
    toggleForceModalCloseFlag(state) {
      state.miscellaneous.forceModalCloseFlag = !state.miscellaneous.forceModalCloseFlag;
    },
    setDocStatusExists(
      state,
      action: PayloadAction<{
        amieWebCreatedExists: boolean;
        amieWebDraftExists: boolean;
        classicDraftExists: boolean;
      }>,
    ) {
      return { ...state, ...action.payload };
    },
    setClassicDraftExists(state, action: PayloadAction<boolean>) {
      return { ...state, classicDraftExists: action.payload };
    },
    setAmieWebDraftExists(state, action: PayloadAction<boolean>) {
      return { ...state, amieWebDraftExists: action.payload };
    },
    reset() {
      return initialState;
    },
    setPreviewScreen(state, action: PayloadAction<{ data: string; version: number }>) {
      return { ...state, document: action.payload, renderPreviewScreen: true, renderPdfView: false };
    },
    closePreview(state) {
      return { ...state, document: undefined, renderPreviewScreen: false };
    },
    setPdfView(state, action: PayloadAction<{ data: string; version: number }>) {
      return { ...state, document: action.payload, renderPdfView: true, renderPreviewScreen: false };
    },
    closePdfView(state) {
      return { ...state, document: undefined, renderPdfView: false };
    },
    resetToDocView(state) {
      return { ...state, document: undefined, renderPreviewScreen: false, renderPdfView: false };
    },
  },
});

export const { name: pContractDocEditorSliceKey, reducer: pContractDocEditorReducer } = slice;

export const pContractDocEditorActions = { ...slice.actions, ...serviceActions, ...businessActions };
