import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { placementFlattenData } from 'app/components/GlobalSearch/Placement/SearchHelper';
import { ExceptionType } from 'app/enums/Common';
import { SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import { getAMIECategorySearch } from 'app/services/SharedServices/SharedServices';
import { TFunction } from 'i18next';
import { trackPromise } from 'react-promise-tracker';
import { call, put, takeLatest } from 'redux-saga/effects';
import { associatedPlacementActions } from './associatedPlacements.redux';
import { globalSearchActions } from 'store/redux-store/global-search/slice';
import { SortColumn } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
const TrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'concerned-placements');

export function* getPlacements(
  action: PayloadAction<{
    filter?: any;
    pageNumber: number;
    pageSize: number;
    sortedColumn?: { column: string; direction: 'asc' | 'desc' } | undefined | null;
    multiSortedColumns?: SortColumn[];
    translation: TFunction;
  }>,
) {
  try {
    const requestBody = {
      searchType: SearchType.placement,
      pageNumber: action.payload.pageNumber,
      pageSize: action.payload.pageSize,
      sortedColumn: action.payload.sortedColumn || undefined,
      multiSortedColumns: action.payload.multiSortedColumns,
      ...action.payload.filter,
    };
    const response = yield call(TrackWrapper, getAMIECategorySearch, requestBody);
    if (response) {
      yield put(
        associatedPlacementActions.setData({
          items: placementFlattenData(response.items),
          count: response.paging.total,
        }),
      );
    }
    response
      ? yield put(
        globalSearchActions.setSearchResults({
          items: placementFlattenData(response.items),
          count: response?.paging?.total,
          miscellaneous: {
            positionCount: 0,
            jobMatchHealth: response?.jobMatchHealth,
            jobInterestHealth: response?.jobInterestHealth,
          },
        }),
      )
      : yield put(globalSearchActions.reset());
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getPlacements',
        area: 'src/app/components/Placement/AssociatedPlacements/store/associatedPlacements.saga.ts',
      },
    });
  }
}

export function* associatedPlacementsSaga() {
  yield takeLatest(associatedPlacementActions.getPlacementsAction.type, getPlacements);
}
