import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { Box, CircularProgress, Grid, Skeleton, Typography } from 'amn-ui-core';
import React, { useCallback, useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { IRateDocument } from 'store/redux-store/create-order-ocbr/types';
import { DocumentCard } from '@AMIEWEB/Common/DocumentDropzone/DocumentCard';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { useTranslation } from 'react-i18next';
import { Cancel } from '@AMIEWEB/Common';
import { gridSelectionActions } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.redux';
import { ErrorCode } from 'react-dropzone';
import { Concatenate } from 'utils/string/string';
import { usePromiseTracker } from 'react-promise-tracker';
import { DragDrop } from '@AMIEWEB/Order/OrderCreationForm/RateCard/BillRates/DragDrop';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { selectFacilityDetail, selectUploadedDocuments } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import _ from 'lodash';
import { Resubmit } from './Resubmit';
import {
  IAttachments,
  ISaveOCBRAttachments,
  ISaveRateElementCommand,
} from '@AMIEWEB/Facility/FacilityStore/Facility.model';

const useStyles = makeStyles<{ modalHasError: boolean }>()((theme, { modalHasError }) => ({
  paper: {
    width: '600px',
    maxWidth: '600px',
    '& .MuiDialogActions-root': {
      padding: '12px 24px',
      boxShadow: '0px -1px 3px #00000029',
    },
  },
  infoSection: {
    padding: modalHasError ? '0 0 12px 0' : '24px 0 12px 0',
  },
  docCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    maxHeight: 400,
    overflowY: 'auto',
  },
  skeleton: {
    height: 20,
    borderRadius: 4,
  },
  validationLoader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: '50%',
  },
}));

interface IErrorDoc {
  errorSeverity: 'error' | 'warning';
  errorMessage: string;
}

const isolateAccepetedDocs = (docs = []) =>
  docs.map((x, index) =>
    index > 4
      ? { ...x, error: true, errorSeverity: 'error', errorMessage: 'Limit of 5 files reached' }
      : { ...x, error: false, errorSeverity: undefined, errorMessage: undefined },
  );

const DOC_PROCESS_TRACKER = 'track-rate-doc-upload';
export const UploadApprovalDocuments = ({
  appUser,
  addDocsModalOpen,
  setAddDocsModalOpen,
  gridSpecs: { gridSelections, rows },
  displayDescription = true,
  existingAttachments,
  isEditing,
  row,
}: {
  appUser: any;
  addDocsModalOpen: boolean;
  setAddDocsModalOpen: (params: boolean) => void;
  gridSpecs?: {
    gridSelections?: any;
    rows?: any;
  };
  existingAttachments?: IAttachments[];
  displayDescription?: boolean;
  isEditing?: boolean;
  row?: any;
}) => {
  const [errorProps, setErrorProps] = useState({
    error: false,
    errorMessage: undefined,
    errorSeverity: undefined,
  });
  const facilityDetailResponse = useSelector(selectFacilityDetail);
  const { classes } = useStyles({ modalHasError: errorProps?.error });
  const { t } = useTranslation();
  const [expectedDocs, setExpectedDocs] = useState([]);
  const [failedDocs, setFailedDocs] = useState<(IRateDocument & IErrorDoc)[]>([]);
  const [affectedRows, setAffectedRows] = useState<any>([]);
  // Code: Refer Status List from './OffContractOrderPage' (Pending = 1, Unsaved = 7)
  const [allowedStatuses] = useState([1, 7]);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openResubmitDialog, setOpenResubmitDialog] = useState(false);
  const [previousAttachments, setPreviousAttachment] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [isResubmit, setIsResubmit] = useState(false);
  const { promiseInProgress: processingDocs } = usePromiseTracker({ area: DOC_PROCESS_TRACKER, delay: 0 });
  const { promiseInProgress: isCallingAPI } = usePromiseTracker({ area: 'save-ocbr-attachments', delay: 0 });
  const { promiseInProgress: updatingRateElement } = usePromiseTracker({ area: 'update-rate-elements', delay: 0 });
  const dispatch = useDispatch();
  const documents = useSelector(selectUploadedDocuments);
  const resetDialogError = () =>
    setErrorProps({
      error: false,
      errorMessage: undefined,
      errorSeverity: undefined,
    });
  const onAddFormClose = useCallback(() => {
    setAddDocsModalOpen(false);
    setOpenCancelDialog(false);
    !isEditing && setExpectedDocs([]);
    setFailedDocs([]);
    resetDialogError();
    setIsChanged(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkChanges = () => {
    if (expectedDocs?.length !== previousAttachments?.length) {
      setIsChanged(true);
    } else {
      expectedDocs?.sort((a, b) => a.documentId - b.documentId);

      previousAttachments?.sort((a, b) => a.documentId - b.documentId);

      let newDocsAvailable = false;
      for (let i = 0; i < expectedDocs?.length; i++) {
        if (expectedDocs[i]?.documentId !== previousAttachments[i]?.documentId) {
          newDocsAvailable = true;

          break;
        }
      }
      setIsChanged(newDocsAvailable);
    }
  };

  const onDocumentUpload = () => {
    const acceptedDocs = expectedDocs?.slice(0, expectedDocs?.length > 5 ? 5 : expectedDocs?.length);
    const newRows = rows?.filter(
      row => gridSelections?.some(selection => selection === row.id) && allowedStatuses?.includes(row.statusId),
    );
    const payload: ISaveOCBRAttachments = {
      attachments: acceptedDocs?.map(doc => doc.file),
      command: {
        currentEmployeeId: appUser.userInfo?.employeeId,
        currentEmployeeName: appUser.userInfo?.firstName + ' ' + appUser.userInfo?.lastName,
        facilityId: facilityDetailResponse?.facilityId,
      },
    };
    setAffectedRows(newRows);
    if (newRows?.length > 0) {
      dispatch(facilityActions.saveOCBRAttachmentsAction({ saveAttachments: payload }));
    } else {
      dispatch(
        globalActions.setSnackBar({
          message:
            !isEditing &&
            `${affectedRows?.length} of ${gridSelections?.length} Off Contract Rate Elements were successfully updated.`,
          severity: 'success',
        }),
      );
      !isEditing && onAddFormClose();
      dispatch(gridSelectionActions.reset());
      dispatch(facilityActions.setApprovalDocument({ attachments: [], rateElementId: '', rateElements: [] }));
    }
  };

  const handleModalErrorProps = (accepetdDocs, rejectedDocs) => {
    const errorCodes: ErrorCode[] = (rejectedDocs ?? []).reduce(
      (results, { errors }) => [...results, ...errors?.map(x => x.errorCode)],
      accepetdDocs?.length > 5 ? [ErrorCode.TooManyFiles] : [],
    );
    let countOfErrorTypesExisting = 0;
    const hasTypeError = errorCodes?.includes(ErrorCode.FileInvalidType) && ++countOfErrorTypesExisting;
    const hasSizeError = errorCodes?.includes(ErrorCode.FileTooLarge) && ++countOfErrorTypesExisting;
    const hasSizeWarning = errorCodes?.includes(ErrorCode.TooManyFiles) && ++countOfErrorTypesExisting;
    if (countOfErrorTypesExisting > 0) {
      if (countOfErrorTypesExisting === 1)
        if (hasSizeWarning)
          setErrorProps({
            error: true,
            errorMessage: t('order.uploadApprovalDocuments.maxFileslimit'),
            errorSeverity: 'warning',
          });
        else
          setErrorProps({
            error: true,
            errorMessage: hasTypeError
              ? t('order.uploadApprovalDocuments.unsupportedFileType')
              : t('order.uploadApprovalDocuments.fileSizeExceeded'),
            errorSeverity: 'error',
          });
      else {
        let errMsg = `${countOfErrorTypesExisting} errors found: `;
        let index = 0;
        const typeErrorMsg =
          hasTypeError && ++index ? `${index}. ${t('order.uploadApprovalDocuments.unsupportedFileType')}` : '';
        const sizeErrorMsg =
          hasSizeError && ++index ? `${index}. ${t('order.uploadApprovalDocuments.fileSizeExceeded')}` : '';
        const fileCountMsg =
          hasSizeWarning && ++index ? `${index}. ${t('order.uploadApprovalDocuments.maxFileslimit')}` : '';
        setErrorProps({
          error: true,
          errorMessage: errMsg + Concatenate([typeErrorMsg, sizeErrorMsg, fileCountMsg], ', '),
          errorSeverity: 'error',
        });
      }
    } else resetDialogError();
  };

  const handleAcceptedDocs = (newDocs, rejectedDocs = undefined) => {
    setExpectedDocs(isolateAccepetedDocs(newDocs));
    if (!!rejectedDocs) handleRejections(rejectedDocs);
  };

  const handleDelete = useCallback(
    hash => {
      handleAcceptedDocs(expectedDocs?.filter(d => d.hash !== hash));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [expectedDocs],
  );

  const handleErrorDocsDelete = useCallback(
    hash => {
      setFailedDocs(prevData => prevData.filter(d => d.hash !== hash));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [failedDocs],
  );

  const handleForceClose = () => {
    if (!expectedDocs?.length) onAddFormClose();
    else setOpenCancelDialog(true);
  };

  const handleRejections = errDocs => {
    const docs = errDocs?.map(x => ({ ...x, errorMessage: x.errors[0].message }));
    setFailedDocs(docs);
  };

  const handleEditClose = () => {
    setOpenCancelDialog(true);
  };

  const handleEditSave = () => {
    const acceptedDocs = expectedDocs?.slice(0, expectedDocs?.length > 5 ? 5 : expectedDocs?.length);
    if (isChanged) {
      const isNewDoc = acceptedDocs.some(element => !element.hasOwnProperty('documentId'));
      if (acceptedDocs?.length > 0 && isNewDoc) {
        setOpenResubmitDialog(true);
      } else {
        if (acceptedDocs?.length > 0) {
          setOpenResubmitDialog(true);
        } else {
          setOpenResubmitDialog(false);
          const command: ISaveRateElementCommand = {
            currentUserId: appUser.userInfo?.employeeId,
            currentUserName: appUser.userInfo?.firstName + ' ' + appUser.userInfo?.lastName,
            facilityId: facilityDetailResponse?.facilityId,
            rateElements: [
              {
                ...row,
                documentIds: [],
              },
            ],
          };
          dispatch(
            facilityActions.updateRateElements({
              saveRateElementCommand: command,
              message: `Rate Element updated successfully`,
            }),
          );
          dispatch(facilityActions.setApprovalDocument({ attachments: [], rateElementId: '', rateElements: [] }));
        }
      }
    }
  };
  const handleNewEditDocs = (resubmit: boolean) => {
    const acceptedDocs = expectedDocs?.slice(0, expectedDocs?.length > 5 ? 5 : expectedDocs?.length);
    const existingDocs = expectedDocs.filter(item => item.hasOwnProperty('documentId'));
    const payload: ISaveOCBRAttachments = {
      attachments: acceptedDocs ? acceptedDocs?.map(doc => doc.file) : [],
      command: {
        currentEmployeeId: appUser.userInfo?.employeeId,
        currentEmployeeName: appUser.userInfo?.firstName + ' ' + appUser.userInfo?.lastName,
        facilityId: facilityDetailResponse?.facilityId,
      },
    };
    const isNewDoc = acceptedDocs.some(element => !element.hasOwnProperty('documentId'));
    if (isChanged) {
      if (isNewDoc) {
        dispatch(facilityActions.saveOCBRAttachmentsAction({ saveAttachments: payload }));
      } else {
        const command: ISaveRateElementCommand = {
          currentUserId: appUser.userInfo?.employeeId,
          currentUserName: appUser.userInfo?.firstName + ' ' + appUser.userInfo?.lastName,
          facilityId: facilityDetailResponse?.facilityId,
          rateElements: [
            {
              ...row,
              documentIds: [...existingDocs?.map(doc => doc?.documentId)],
              statusId: resubmit ? 3 : 2,
            },
          ],
        };
        dispatch(
          facilityActions.updateRateElements({
            saveRateElementCommand: command,
            message: `Rate Element updated successfully`,
          }),
        );
        dispatch(facilityActions.setApprovalDocument({ attachments: [], rateElementId: '', rateElements: [] }));
      }
    }
  };

  const handleResubmit = () => {
    setIsResubmit(true);
    setOpenResubmitDialog(false);
    handleNewEditDocs(true);
  };

  const handleNotNow = () => {
    setIsResubmit(false);
    setOpenResubmitDialog(false);
    handleNewEditDocs(false);
  };

  useEffect(() => {
    if (!isCallingAPI && documents?.length > 0) {
      const existingDocs = expectedDocs.filter(item => item.hasOwnProperty('documentId'));
      const command: ISaveRateElementCommand = {
        currentUserId: appUser.userInfo?.employeeId,
        currentUserName: appUser.userInfo?.firstName + ' ' + appUser.userInfo?.lastName,
        facilityId: facilityDetailResponse?.facilityId,
        rateElements: isEditing
          ? [
              {
                ...row,
                documentIds: [...documents?.map(doc => doc.documentId), ...existingDocs.map(doc => doc?.documentId)],
                statusId: isResubmit ? 3 : 2,
              },
            ]
          : affectedRows?.map(row => ({
              ...row,
              documentIds: documents?.map(doc => doc.documentId),
              statusId: 3,
            })),
      };
      dispatch(
        facilityActions.updateRateElements({
          saveRateElementCommand: command,
          message:
            gridSelections?.length <= 0
              ? `Rate Element updated successfully`
              : `${affectedRows?.length} of ${gridSelections?.length} Off Contract Rate Elements were successfully updated.`,
        }),
      );
      dispatch(facilityActions.setUploadedDocuments([]));
      !isEditing && onAddFormClose();
      !isEditing && setAffectedRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, isResubmit]);

  useEffect(() => {
    dispatch(facilityActions.setUploadDocumentModalOpen(addDocsModalOpen));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDocsModalOpen]);

  useEffect(() => {
    setPreviousAttachment(_.cloneDeep(existingAttachments || []));
    setExpectedDocs(isolateAccepetedDocs(existingAttachments || []));
  }, [existingAttachments, addDocsModalOpen]);

  useEffect(() => {
    checkChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expectedDocs]);

  useEffect(() => {
    if (!isCallingAPI && !updatingRateElement && isChanged) {
      onAddFormClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCallingAPI, updatingRateElement]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleModalErrorProps(expectedDocs, failedDocs), [expectedDocs, failedDocs]);

  return (
    <>
      <GenericDialog
        open={addDocsModalOpen}
        disableEscapeKeyDown
        fullWidth
        variant="blue"
        classes={{ paper: classes.paper }}
        onClose={handleForceClose}
        dialogTitleProps={{
          text: t('order.uploadApprovalDocuments.title'),
          closeButton: true,
          size: 'small',
        }}
        dialogContentProps={{ ...errorProps }}
        dialogActions={[
          {
            text: 'CANCEL',
            variant: 'contained',
            disabled: isCallingAPI,
            color: 'tertiary',
            onClick: isEditing ? handleEditClose : handleForceClose,
          },
          {
            text: 'SAVE',
            variant: 'contained',
            color: 'primary',
            disabled: isEditing ? !isChanged || isCallingAPI : !expectedDocs?.length || isCallingAPI,
            onClick: isEditing ? handleEditSave : onDocumentUpload,
          },
        ]}
      >
        {(isCallingAPI || updatingRateElement) && <CircularProgress className={classes.validationLoader} />}
        {displayDescription ? (
          <Typography variant="body1" classes={{ root: classes.infoSection }}>
            Documents will only be added to those Off-Contract Rate Elements that are in <strong>{` ‘Pending’`}</strong>{' '}
            status.
          </Typography>
        ) : (
          <div style={{ padding: '8px 0px 10px 0px' }}></div>
        )}
        <Grid container direction="row" spacing={3}>
          <Grid item xs={6}>
            <DragDrop
              existingDocs={expectedDocs}
              handleAcceptedDocs={handleAcceptedDocs}
              trackerKey={DOC_PROCESS_TRACKER}
            />
          </Grid>
          <Grid item container direction="column" xs={6} spacing={2}>
            <Grid item>
              <Box fontSize="14px" fontWeight="500">
                {t('notification.uploadApprovalDocuments.sectionHeading.documentsUploaded')}
              </Box>
            </Grid>
            <Grid item classes={{ root: classes.docCardContainer }}>
              {processingDocs && (
                <>
                  <Skeleton variant="rectangular" classes={{ root: classes.skeleton }} />
                  <Skeleton variant="rectangular" classes={{ root: classes.skeleton }} />
                </>
              )}
              {expectedDocs?.map(({ hash, name, type, size, ...rest }) => (
                <DocumentCard
                  hash={hash}
                  fileName={name}
                  fileType={type}
                  fileSize={size}
                  {...rest}
                  handleDelete={handleDelete}
                />
              ))}
              {failedDocs.map(({ hash, name, type, size, ...rest }) => (
                <DocumentCard
                  hash={hash}
                  fileName={name}
                  fileType={type}
                  fileSize={size}
                  {...rest}
                  error
                  handleDelete={handleErrorDocsDelete}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </GenericDialog>
      <Cancel
        openDialog={openCancelDialog}
        handleConfirmAction={onAddFormClose}
        handleCancelAction={() => {
          setOpenCancelDialog(false);
        }}
      />
      <Resubmit
        openDialog={openResubmitDialog}
        handleConfirmAction={handleResubmit}
        handleCancelAction={handleNotNow}
      />
    </>
  );
};
