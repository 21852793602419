import { Divider, Grid } from 'amn-ui-core';
import React, { useContext, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { CandidateSection } from './Candidate/CandidateSection';
import { OrderSection } from './Order/OrderSection';
import { CandidateOrderSelector } from './CandidateOrderSelector';
import { CreatePlacementStatus } from './Status/CreatePlacementStatus';
import { useDispatch, useSelector } from 'react-redux';
import { selectCreatePlacementOrderType, selectNewPlacementId } from 'store/redux-store/new-placement/selectors';
import { useSearchSelections, useValidations } from '../CreatePlacement/hooks';
import { newPlacementActions } from 'store/redux-store/new-placement/slice';
import { selectPlacementStatus } from 'store/redux-store/placement-status/selectors';
import { CreatePlacementContext } from '../CreatePlacement/CreatePlacementWrapper';
import { placementStatusAction } from 'store/redux-store/placement-status/slice';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles()(theme => ({
  root: {
    height: '100%',
  },
  selectionContainer: {
    flex: '1 1 auto',
    padding: '24px 24px 0 24px',
  },
  actionContainer: {
    width: '545px',
    backgroundColor: '#F4F5F7',
    padding: '24px',
  },
  candidateContainer: {
    flex: '1 1 auto',
  },
  orderContainer: {
    minWidth: '330px',
    maxWidth: '340px',
  },
}));

export const CreatePlacement = ({ expanded }: { expanded: boolean }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const newPlacementId = useSelector(selectNewPlacementId);
  const orderType = useSelector(selectCreatePlacementOrderType);
  const { currentPlacementStatus } = useSelector(selectPlacementStatus);
  const { isDefaultCandidate, isDefaultOrder } = useContext(CreatePlacementContext);

  useEffect(() => {
    if (orderType) dispatch(newPlacementActions.calculatePlacementStatus({ status: undefined }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlacementStatus, orderType]);

  useSearchSelections(isDefaultCandidate, isDefaultOrder);
  useValidations();

  const { getValues, reset } = useFormContext();

  useEffect(() => {
    if (newPlacementId) {
      if (newPlacementId === -1) dispatch(newPlacementActions.setPlacementCreated(null));
      reset(getValues(), { isDirty: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPlacementId]);

  useEffect(() => {
    return () => {
      dispatch(newPlacementActions.reset());
      dispatch(placementStatusAction.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container flexDirection="row" flexWrap="nowrap" classes={{ root: classes.root }}>
      <Grid item classes={{ root: classes.selectionContainer }}>
        <Grid container flexDirection="column" spacing={2}>
          <Grid item aria-label="candidate-order-selection">
            <CandidateOrderSelector />
          </Grid>
          <Grid item>
            <Divider variant="wideMargin" orientation="horizontal" />
          </Grid>
          <Grid item aria-label="info-section">
            <Grid container flexDirection="row" flexWrap="nowrap" spacing={2}>
              <Grid item aria-label="candidate-info-section" classes={{ root: classes.candidateContainer }}>
                <CandidateSection expanded={expanded} />
              </Grid>
              <Grid item>
                <Divider variant="wideMargin" orientation="vertical" />
              </Grid>
              <Grid item aria-label="order-info-section" classes={{ root: classes.orderContainer }}>
                <OrderSection />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item classes={{ root: classes.actionContainer }}>
        <CreatePlacementStatus />
      </Grid>
    </Grid>
  );
};
