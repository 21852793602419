import { Grid, IconButton } from 'amn-ui-core';
import React, { useState } from 'react';
import EditIconOutlined from '@mui/icons-material/EditOutlined';
import { useTranslation } from 'react-i18next';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { useDispatch } from 'react-redux';
import { pageAttributesActions } from 'app/components/Placement/PlacementDetails/Edit/store/placementPageAttributes.redux';

export enum ExtEditTarget {
  amnHousing = 'edit-extension-amn-housing',
  date = 'edit-extension-date',
  notes = 'edit-extension-notes',
  timeOff = 'edit-extension-time-off',
}

// Extension Status < Extension Approved
// Facility Offered - 3
// Traveler Accepts - 4
// Traveler Requested - 5
// Booked by Traveler - 6
// Facility Confirms - 9
const EDITABLE_STATUSES = [3, 4, 5, 6, 9];

export const isFieldEditableForExtensionId = extensionStatusId => EDITABLE_STATUSES.includes(extensionStatusId);

/** Fields are editable for above EDITABLE_STATUSES */
const useExtEditableLogic = (extensionStatusId, target: ExtEditTarget) => {
  const [display, setDisplay] = useState(target === null || target === ExtEditTarget.notes);

  React.useEffect(
    () =>
      setDisplay(
        !extensionStatusId ||
          target === null ||
          target === ExtEditTarget.notes ||
          isFieldEditableForExtensionId(extensionStatusId),
      ),
    [extensionStatusId, target],
  );

  return { display };
};

export const ExtensionEditIcon = ({
  extensionStatusId = null,
  extensionStatus = '',
  target = null,
  extContainerIndex,
  isHeader = false,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleEditIconClick = event => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(
      pageAttributesActions.setExtensionModalProps({
        open: true,
        isNewExtension: false,
        extensionStatusId,
        title: `${t('Edit Extension')} ${extContainerIndex + 1}`,
        extensionOverride: 0,
        extContainerIndex,
        currentTargetField: target,
      }),
    );
  };

  const { display } = useExtEditableLogic(extensionStatusId, target);

  return display ? (
    <CustomTooltip
      tooltipContent={t('placement.profile.summary.extension.editDetails')}
      standardMargin={true}
      isInlineContent={true}
    >
      <IconButton
        color="secondary"
        size="medium"
        onClick={handleEditIconClick}
        sx={theme => ({
          marginRight: isHeader ? theme.spacing(2) : theme.spacing(0),
        })}
      >
        <EditIconOutlined sx={{ fontSize: 20 }} />
      </IconButton>
    </CustomTooltip>
  ) : null;
};

export const ExtensionEditWrapper = ({ children, extContainerIndex, ...props }) => (
  <Grid container direction="row" wrap="nowrap" justifyContent="space-between">
    <Grid item>{children}</Grid>
    <Grid item>
      <ExtensionEditIcon extContainerIndex={extContainerIndex} {...props} />
    </Grid>
  </Grid>
);
