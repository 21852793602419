import { Grid, Tooltip, Typography, alpha } from 'amn-ui-core';
import { Chip } from 'amn-ui-core/dist/components/Chip/Chip';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const TravelView = ({ selectedWorkExperience, classes, differenceDate, workStartDate, workEndDate }) => {
  const { t } = useTranslation();
  return (
    <LayoutGrid container spacing={2} style={{ minWidth: 1224 }}>
      <LayoutGridItem xs={4}>
        <div className="column">
          {selectedWorkExperience?.hasEMPVReference && (
            <Chip
              sx={{
                borderColor: theme => theme.palette.framework.system.grassGreen,
                backgroundColor: theme => alpha(theme.palette.framework.system.grassGreen, 0.1),
                color: theme => theme.palette.framework.system.grassGreen,
                fontWeight: 'bold',
              }}
              color="success"
              label={t('candidate.workExperienceView.employmentVerified')}
              variant="outlined"
            />
          )}
          <Chip label={t('candidate.workExperienceView.travel')} variant="outlined" />

          <div className={`row ${classes.facilityName} `}>{selectedWorkExperience?.facilityName || ''}</div>
          <div className={`row ${classes.columnRow}`}>
            {selectedWorkExperience?.location?.city && `${selectedWorkExperience?.location.city},`}{' '}
            {selectedWorkExperience?.location?.state
              ? selectedWorkExperience?.location.state
              : selectedWorkExperience?.location.state}{' '}
            {selectedWorkExperience?.location.zip}
          </div>
        </div>
      </LayoutGridItem>

      <LayoutGridItem xs={4}>
        <div className="column">
          {selectedWorkExperience?.disciplineAbbr || selectedWorkExperience?.specialtyAbbr ? (
            <Chip
              label={`${selectedWorkExperience?.disciplineAbbr || '--'}-${
                selectedWorkExperience?.specialtyAbbr || '--'
              }`}
              variant="outlined"
            />
          ) : (
            <Grid sx={{ height: theme => theme.spacing(6) }} />
          )}
          <div className={`row ${classes.columnRow}`}>
            {t('candidate.workExperienceView.unit')}
            <strong>{selectedWorkExperience?.unit || '--'}</strong>
          </div>
          <div className={`row ${classes.columnRow}`}>
            {t('candidate.workExperienceView.position')}
            <strong> {selectedWorkExperience?.position || '--'}</strong>
          </div>
          <div className={`row ${classes.columnRow} ${classes.equipmentContainer}`}>
            <span className={classes.equipmentLabel}>{t('candidate.workExperienceView.equipment')}</span>
            <Tooltip
              placement="right-end"
              classes={{ tooltip: classes.tooltipBackground }}
              title={selectedWorkExperience?.equipmentProcedures || ''}
            >
              <Typography noWrap className={classes.equipment}>
                <strong>{selectedWorkExperience?.equipmentProcedures || ''}</strong>
              </Typography>
            </Tooltip>
          </div>
        </div>
      </LayoutGridItem>

      <LayoutGridItem xs={4} className={classes.columnMargin}>
        <div className="column">
          <div className={`row ${classes.columnRow}`}>
            {'Date:  '}
            <strong>
              {workStartDate} - {workEndDate}
            </strong>
            {differenceDate && ` • ${differenceDate}`}
          </div>
          <div className={`row ${classes.columnRow}`}>
            {'Current Employer:  '}
            {selectedWorkExperience?.currentlyEmployed ? (
              <strong>{t('candidate.workExperienceView.yes')}</strong>
            ) : selectedWorkExperience?.currentlyEmployed === false ? (
              <strong>{t('candidate.workExperienceView.no')}</strong>
            ) : (
              ''
            )}
          </div>
          <div className={`row ${classes.columnRow}`}>
            {t('candidate.workExperienceView.reasonForLeaving')}
            <strong> {selectedWorkExperience?.reasonForLeaving || '--'}</strong>
          </div>
        </div>
      </LayoutGridItem>
    </LayoutGrid>
  );
};
