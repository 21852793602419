import { Grid, Paper } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { MyPlacementsHeader } from './Header/MyPlacementsHeader';
import { MyPlacementContainer } from './Content/MyPlacementsContainer';

const useStyles = makeStyles()({
  root: {
    padding: '0px 15px',
  },
});


export const MyPlacements = props => {
  const { onPlacementSelected } = props;
  const { classes } = useStyles();
  return (
    <Paper elevation={0} className="paper">
      <Grid container direction="column" className={classes.root}>
        <Grid item xs={12}>
          <MyPlacementsHeader />
        </Grid>
        <Grid item xs={12}>
          <MyPlacementContainer onPlacementSelected={onPlacementSelected} />
        </Grid>
      </Grid>
    </Paper>
  );
};
