import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, Select, Typography } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';

export enum StatusDropDownValues {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum VERSION {
  ONE,
  TWO,
}

const useStatusDropDownStyles = makeStyles<{
  statusType: string;
  isNewStatusIndicator: boolean;
  isOrderCreation: boolean;
}>()((theme, { statusType, isNewStatusIndicator, isOrderCreation }) => ({
  select: {
    width: '150px',
    height: '28px',
    backgroundColor: isNewStatusIndicator
      ? statusType === StatusDropDownValues.ACTIVE
        ? `${theme.palette.components.select.statusDropdown.newStatusIndicator.active.backgroundColor} !important`
        : `${theme.palette.system.grayShaded} !important`
      : statusType === StatusDropDownValues.ACTIVE
      ? `${theme.palette.framework.system.green} !important`
      : `${theme?.palette.system.neutralGray} !important`,
    '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
      paddingTop: '0px',
      paddingBottom: '0px',
      fontWeight: '700',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '&.Mui-disabled': {
      backgroundColor: theme?.palette.system.neutralGray,
      color: theme?.palette.framework.system.white,
      '& .MuiSelect-icon': {
        color: isOrderCreation ? theme.palette.system.lightGrey : theme.palette.framework.system.white,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
    },
  },
  icon: {
    color: isNewStatusIndicator
      ? theme.palette.components.select.statusDropdown.newStatusIndicator.color
      : `${theme?.palette.framework.system.white} !important`,
  },
  menuPaper: {
    marginTop: '5px',
    width: '150px',
  },
  buttonText: {
    color: 'white',
    fontWeight: '500',
    fontSize: '12px',
    '-webkit-text-fill-color': isNewStatusIndicator
      ? statusType === StatusDropDownValues.ACTIVE
        ? theme.palette.framework.system.kellyGreen
        : 'rgba(102, 102, 102, 1)'
      : `${theme?.palette.framework.system.white} !important`,
  },
  checkIcon: {
    height: '20px',
    color: theme.palette.system.navyBlue,
  },
}));
interface IStatusDropdownProps {
  handleStatus?: (options: string) => void;
  isNewStatusIndicator?: boolean;
  version?: VERSION;
  width?: string;
  defaultValue?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isOrderCreation?: boolean;
}

export const StatusDropdown = ({
  handleStatus,
  isNewStatusIndicator = false,
  version = VERSION.ONE,
  width = '',
  defaultValue = StatusDropDownValues.ACTIVE,
  isDisabled = false,
  isReadOnly = false,
  isOrderCreation = false,
}: IStatusDropdownProps) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<string>(defaultValue);
  const { classes } = useStatusDropDownStyles({
    statusType: status,
    isNewStatusIndicator: isNewStatusIndicator,
    isOrderCreation: isOrderCreation,
  });

  useEffect(() => {
    setStatus(defaultValue);
  }, [defaultValue]);

  const handleStatusChange = event => {
    setStatus(event?.target?.value);
    handleStatus(event?.target?.value);
  };

  return (
    <Select
      disabled={isDisabled}
      readOnly={isReadOnly}
      value={status}
      onChange={event => handleStatusChange(event)}
      IconComponent={version === VERSION.TWO || status === StatusDropDownValues.ACTIVE ? ExpandMoreIcon : null}
      className={classes.select}
      classes={{
        icon: classes.icon,
      }}
      sx={{
        width: width ? width : '86px !important',
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        classes: {
          paper: classes.menuPaper,
        },
      }}
      renderValue={value => <div className={classes.buttonText}>{value}</div>}
    >
      <MenuItem value={t('facility.units.statusUpdate.active')}>
        <Grid container direction="row" justifyContent="space-between">
          <Typography>{t('facility.units.statusUpdate.active')}</Typography>
          {status === StatusDropDownValues.ACTIVE && <CheckIcon className={classes.checkIcon} />}
        </Grid>
      </MenuItem>
      <MenuItem value={t('facility.units.statusUpdate.inactive')}>
        <Grid container direction="row" justifyContent="space-between">
          <Typography>{t('facility.units.statusUpdate.inactive')}</Typography>
          {status === StatusDropDownValues.INACTIVE && <CheckIcon className={classes.checkIcon} />}
        </Grid>
      </MenuItem>
    </Select>
  );
};
