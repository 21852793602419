import { Box, Divider, Grid, IconButton, Skeleton, Typography } from 'amn-ui-core';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useMemo } from 'react';
import { Title } from '../../../ProfileTab/Cards/styles';
import RowSectionTitle from './SubComponent/RowSectionTitle';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips';
import GetLicenseToolTip from './SubComponent/GetLicenseToolTip';
import RowSection from './SubComponent/RowSection';
import { CredentialName, DateValue, compareDates, getState, getStateById, getType, getWarningMessage } from '../helper';
import { convertNumberToBoolean } from 'app/helpers/converters';
import GetHearsayLicenseTooltip from './SubComponent/GetHearsayLicenseTooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useDispatch, useSelector } from 'react-redux';
import {
  CredentialsSelection,
  candidateDetailsSelection,
} from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import GetCertificatonTooltip from './SubComponent/GetCertificatonTooltip';
import GetHearsayCertificateTooltip from './SubComponent/GetHearsayCertificateTooltip';
import CovidSatusCard from '../VaccinationCard/CovidStatusCard';
import FluStatusCard from '../VaccinationCard/FluStatusCard';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useStyles } from './utils';
import { theme } from 'styles/global-styles';

function QualificationUI({
  showAllLicenses,
  haveAddPermission,
  isCredAnalyst,
  setAddLicense,
  licenses,
  candidateLicenses,
  isLicenseHovering,
  setSelectedLicenseMetaData,
  handleLicenseMouseOut,
  showAllCertifications,
  isCertificationHovering,
  setSelectedHearsayCertMetaData,
  handleCertificationMouseOut,
  covidStatusData,
  fluStatusData,
  handleLicenseMouseOver,
  certifications,
  handleCertificationMouseOver,
}) {
  const candidateDetails = useSelector(candidateDetailsSelection);
  const credentials = useSelector(CredentialsSelection);
  const { promiseInProgress } = usePromiseTracker({ area: 'get-candidate-summary-tab-details', delay: 0 });
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const CertificationLicense = useMemo(() => {
    return (candidateLicenses ?? []).map((item, index) => (
      <LayoutGridItem
        style={{ width: '100%' }}
        key={index}
        onMouseEnter={e => handleLicenseMouseOver(e, item)}
        onMouseLeave={handleLicenseMouseOut}
      >
        <CustomTooltip
          tooltipContent={
            <Typography gutterBottom component="span">
              <GetHearsayLicenseTooltip license={item} />
            </Typography>
          }
          placement="bottom-start"
          open={
            isLicenseHovering
              ? isLicenseHovering.requirementType === item.requirementType && isLicenseHovering.state === item.state
              : false
          }
        >
          <RowSection
            header={t(`${getStateById(item.state)} ${item.requirementType ? item.requirementType : '--'}`)}
            data={''}
            preData={item.expirationOn ? compareDates(item.expirationOn, DateValue.CURRENT) : ''}
            showHalfwayProgress={
              ((item.dateCompleted !== null || item.expirationOn !== null) &&
                (!compareDates(item.dateCompleted, DateValue.CURRENT) ||
                  compareDates(item.expirationOn, DateValue.CURRENT)) &&
                !(
                  !compareDates(item.dateCompleted, DateValue.CURRENT) &&
                  compareDates(item.expirationOn, DateValue.CURRENT)
                ) &&
                (compareDates(item.expirationOn, DateValue.CURRENT) ||
                  item.expirationOn == null ||
                  (!compareDates(item.expirationOn, DateValue.CURRENT) &&
                    !compareDates(item.dateCompleted, DateValue.CURRENT)))) ||
              (item.expirationOn !== null && compareDates(item.expirationOn, DateValue.SOON)) ||
              !item.verified ||
              !item.dateCompleted ||
              !item.expirationOn
            }
            showHalfwayProgressSecondaryIcon={true}
            warningMessage={getWarningMessage(item.expirationOn, DateValue.CURRENT)}
            color={theme.palette.system.darkGreenForIcon}
            compact={false}
            unverifiedIcon={true}
            editIcon={
              haveAddPermission && isLicenseHovering
                ? isLicenseHovering.type === item.type && isLicenseHovering.state === item.state
                : false
            }
            editData={
              <IconButton
                color="tertiary"
                size="medium"
                className={classes.editLink}
                onClick={() => {
                  setAddLicense(true);
                  setSelectedLicenseMetaData(item);
                  handleLicenseMouseOut();
                  dispatch(
                    candidateDetailActions.setIsAddLicense({
                      isAddLicense: true,
                      type: CredentialName.HearsayLicense,
                    }),
                  );
                }}
              >
                <EditOutlinedIcon />
              </IconButton>
            }
          />
        </CustomTooltip>
      </LayoutGridItem>
    ));
  }, [candidateLicenses, handleLicenseMouseOver, handleLicenseMouseOut, haveAddPermission, isLicenseHovering]);

  return (
    <>
      <Box className={classes.children}>
        <LayoutGrid direction="column" spacing={1}>
          <Title className={classes.title}>{t('Qualifications')}</Title>
          <span className={classes.sectionDivider}>&nbsp;</span>
          <Divider style={{ width: '100%' }} />
          <RowSectionTitle
            header={
              <div className={classes.boxInlineHeadLabelTxt}>
                {t('Licensures')}
                <span
                  className={
                    candidateDetails?.credentials?.licenses?.length > 0
                      ? classes.profileInlineLinkQualification
                      : classes.profileInlineLinkDisabled
                  }
                  onClick={showAllLicenses}
                >
                  {t('candidate.virtualInterviewCard.all')}
                </span>
              </div>
            }
            data={
              (haveAddPermission || isCredAnalyst) && (
                <span
                  onClick={() => {
                    setAddLicense(true);
                    if (isCredAnalyst) {
                      dispatch(
                        candidateDetailActions.setIsAddLicense({
                          isAddLicense: true,
                          type: CredentialName.Licenses,
                        }),
                      );
                    } else {
                      dispatch(
                        candidateDetailActions.setIsAddLicense({
                          isAddLicense: true,
                          type: CredentialName.HearsayLicense,
                        }),
                      );
                    }
                  }}
                  className={classes.profileInlineLink}
                >
                  {t('candidate.profileSummaryCard.add')}
                </span>
              )
            }
          />
          {promiseInProgress ? (
            <>
              <Skeleton />
              <Skeleton />
            </>
          ) : (
            <>
              {candidateDetails?.credentials &&
                candidateDetails?.credentials?.licenses?.length > 0 &&
                licenses.map((item, index) => {
                  const licenseData = _.cloneDeep(credentials?.licenses);
                  const sortedData = licenseData
                    ?.filter(
                      x =>
                        x.licenseNumber == item.licenseNumber &&
                        x.credentialName == item.credentialName &&
                        x.stateID == item.stateID,
                    )
                    ?.sort((a, b) => new Date(b.dateCompleted).getTime() - new Date(a.dateCompleted).getTime());
                  const latestLicense = sortedData && sortedData.length ? sortedData[0] : item;

                  return (
                    <LayoutGridItem style={{ width: '100%' }} key={index}>
                      <CustomTooltip
                        standardMargin
                        tooltipContent={<GetLicenseToolTip license={latestLicense} />}
                        placement="bottom-start"
                      >
                        <RowSection
                          header={t(`${getState(latestLicense.stateID)} ${latestLicense.requirementType}`)}
                          data={''}
                          preData={
                            latestLicense.dateCompleted && latestLicense.expirationDate
                              ? !compareDates(latestLicense.dateCompleted, DateValue.CURRENT) &&
                                compareDates(latestLicense.expirationDate, DateValue.CURRENT)
                              : ''
                          }
                          showHalfwayProgress={
                            ((latestLicense.dateCompleted !== null || latestLicense.expirationDate !== null) &&
                              (!compareDates(latestLicense.dateCompleted, DateValue.CURRENT) ||
                                compareDates(latestLicense.expirationDate, DateValue.CURRENT)) &&
                              !(
                                !compareDates(latestLicense.dateCompleted, DateValue.CURRENT) &&
                                compareDates(latestLicense.expirationDate, DateValue.CURRENT)
                              ) &&
                              (compareDates(latestLicense.expirationDate, DateValue.CURRENT) ||
                                latestLicense.expirationDate == null ||
                                (!compareDates(latestLicense.expirationDate, DateValue.CURRENT) &&
                                  !compareDates(latestLicense.dateCompleted, DateValue.CURRENT)))) ||
                            (latestLicense.expirationDate !== null &&
                              compareDates(latestLicense.expirationDate, DateValue.SOON)) ||
                            !latestLicense.dateVerified ||
                            !latestLicense.dateCompleted ||
                            !latestLicense.expirationDate
                          }
                          showHalfwayProgressSecondaryIcon={true}
                          warningMessage={getWarningMessage(latestLicense.expirationDate, DateValue.CURRENT)}
                          color={theme.palette.system.darkGreenForIcon}
                          compact={convertNumberToBoolean(latestLicense.levelID)}
                        />
                      </CustomTooltip>
                    </LayoutGridItem>
                  );
                })}
              {!(candidateDetails?.credentials?.licenses?.length > 0) && !(candidateDetails?.licenses?.length > 0) && (
                <RowSection
                  header={
                    <Grid container direction="column">
                      <Grid item>{t('candidate.qualificationsCard.licenseUnavailableLine1')}</Grid>
                      <Grid item>
                        <span>{t('candidate.qualificationsCard.licenseUnavailableLine2')}</span>
                        {(haveAddPermission || isCredAnalyst) && (
                          <span>{t('candidate.qualificationsCard.please')}</span>
                        )}
                      </Grid>
                      {(haveAddPermission || isCredAnalyst) && (
                        <Grid item>
                          <span
                            className={classes.inlineLink}
                            onClick={() => {
                              setAddLicense(true);
                              if (isCredAnalyst) {
                                dispatch(
                                  candidateDetailActions.setIsAddLicense({
                                    isAddLicense: true,
                                    type: CredentialName.Licenses,
                                  }),
                                );
                              } else {
                                dispatch(
                                  candidateDetailActions.setIsAddLicense({
                                    isAddLicense: true,
                                    type: CredentialName.HearsayLicense,
                                  }),
                                );
                              }
                            }}
                          >
                            {t('candidate.qualificationsCard.addLicense')}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  }
                  data={''}
                  preData={''}
                  color={theme.palette.framework.system.navyBlue}
                  showHalfwayProgress={true}
                  showHalfwayProgressSecondaryIcon={true}
                  showAlert={false}
                />
              )}
              {candidateDetails?.licenses?.length && candidateLicenses?.length > 0 ? CertificationLicense : <></>}
            </>
          )}
          <span className={classes.sectionDivider}>&nbsp;</span>
          <Divider style={{ width: '100%' }} />
          <span className={classes.sectionDivider}>&nbsp;</span>
          <RowSectionTitle
            header={
              <div className={classes.boxInlineHeadLabelTxt}>
                {t('Certification')}
                <span
                  className={
                    candidateDetails?.credentials?.certifications?.length > 0
                      ? classes.profileInlineLink
                      : classes.profileInlineLinkDisabled
                  }
                  onClick={showAllCertifications}
                >
                  {t('candidate.virtualInterviewCard.all')}
                </span>
              </div>
            }
            data={
              (haveAddPermission || isCredAnalyst) && (
                <span
                  onClick={() => {
                    setAddLicense(true);
                    if (isCredAnalyst) {
                      dispatch(
                        candidateDetailActions.setIsAddLicense({
                          isAddLicense: true,
                          type: CredentialName.Certification,
                        }),
                      );
                    } else {
                      dispatch(
                        candidateDetailActions.setIsAddLicense({
                          isAddLicense: true,
                          type: CredentialName.HearsayCertification,
                        }),
                      );
                    }
                  }}
                  className={classes.profileInlineLink}
                >
                  {t('candidate.profileSummaryCard.add')}
                </span>
              )
            }
          />
          {promiseInProgress ? (
            <>
              <Skeleton />
              <Skeleton />
            </>
          ) : (
            <>
              {candidateDetails?.credentials?.certifications?.length > 0
                ? certifications?.map((item, index) => {
                    return (
                      <LayoutGridItem style={{ width: '100%' }} key={index}>
                        <CustomTooltip
                          tooltipContent={
                            <Typography gutterBottom component="span">
                              <GetCertificatonTooltip certificate={item} />
                            </Typography>
                          }
                          placement="bottom-start"
                        >
                          <RowSection
                            header={t(
                              item.subCategory == 'Other Certifications'
                                ? `${getType(item.typeID)} ${item.requirementType}`
                                : `${item.requirementType}`,
                            )}
                            data={''}
                            preData={
                              item.dateCompleted && item.expirationDate
                                ? !compareDates(item.dateCompleted, DateValue.CURRENT) &&
                                  compareDates(item.expirationDate, DateValue.CURRENT)
                                : ''
                            }
                            showHalfwayProgress={
                              ((item.dateCompleted !== null || item.expirationDate !== null) &&
                                (!compareDates(item.dateCompleted, DateValue.CURRENT) ||
                                  compareDates(item.expirationDate, DateValue.CURRENT)) &&
                                !(
                                  !compareDates(item.dateCompleted, DateValue.CURRENT) &&
                                  compareDates(item.expirationDate, DateValue.CURRENT)
                                ) &&
                                (compareDates(item.expirationDate, DateValue.CURRENT) ||
                                  item.expirationDate == null ||
                                  (!compareDates(item.expirationDate, DateValue.CURRENT) &&
                                    !compareDates(item.dateCompleted, DateValue.CURRENT)))) ||
                              (item.expirationDate !== null && compareDates(item.expirationDate, DateValue.SOON)) ||
                              (item.expirationDate !== null && !compareDates(item.expirationDate, DateValue.CURRENT)) ||
                              !item.dateCompleted ||
                              !item.expirationDate
                            }
                            showHalfwayProgressSecondaryIcon={true}
                            showAlert={false}
                            warningMessage={getWarningMessage(item.expirationDate, DateValue.CURRENT)}
                            color={theme.palette.system.darkGreenForIcon}
                            compact={convertNumberToBoolean(item.levelID)}
                          />
                        </CustomTooltip>
                      </LayoutGridItem>
                    );
                  })
                : candidateDetails.hearsayCertifications?.length === 0 && (
                    <LayoutGridItem style={{ width: '100%' }}>
                      <RowSection
                        header={t('candidate.qualificationsCard.certificationUnavailable')}
                        data={''}
                        preData={''}
                        color={theme.palette.framework.system.navyBlue}
                        showHalfwayProgress={true}
                        showHalfwayProgressSecondaryIcon={true}
                        showAlert={false}
                      />
                    </LayoutGridItem>
                  )}
              {candidateDetails.hearsayCertifications?.length > 0 ? (
                candidateDetails.hearsayCertifications?.map((item, index) => {
                  return (
                    <LayoutGridItem
                      style={{ width: '100%' }}
                      key={index}
                      onMouseEnter={e => handleCertificationMouseOver(e, item)}
                      onMouseLeave={handleCertificationMouseOut}
                    >
                      <CustomTooltip
                        tooltipContent={
                          <Typography gutterBottom component="span">
                            <GetHearsayCertificateTooltip certificate={item} />
                          </Typography>
                        }
                        placement="bottom-start"
                        open={
                          isCertificationHovering
                            ? isCertificationHovering.certificationName === item.certificationName
                            : false
                        }
                      >
                        <RowSection
                          header={t(item.certificationName)}
                          data={''}
                          preData={''}
                          showHalfwayProgress={true}
                          showHalfwayProgressSecondaryIcon={true}
                          color={''}
                          warningMessage={getWarningMessage(item.expirationDate, DateValue.CURRENT)}
                          unverifiedIcon={true}
                          editIcon={
                            haveAddPermission && isCertificationHovering
                              ? isCertificationHovering.certificationName === item.certificationName
                              : false
                          }
                          editData={
                            <IconButton
                              color="tertiary"
                              size="medium"
                              onClick={() => {
                                setAddLicense(true);
                                setSelectedHearsayCertMetaData(item);
                                handleCertificationMouseOut();
                                dispatch(
                                  candidateDetailActions.setIsAddLicense({
                                    isAddLicense: true,
                                    type: CredentialName.HearsayCertification,
                                  }),
                                );
                              }}
                            >
                              <EditOutlinedIcon />
                            </IconButton>
                          }
                        />
                      </CustomTooltip>
                    </LayoutGridItem>
                  );
                })
              ) : (
                <></>
              )}
            </>
          )}
          <span className={classes.sectionDivider}>&nbsp;</span>
          <Divider style={{ width: '100%' }} />
          <span className={classes.sectionDivider}>&nbsp;</span>
          <RowSectionTitle
            header={<div className={classes.boxInlineHeadLabelTxt}>{t('Vaccinations')}</div>}
            data={''}
          />
          {promiseInProgress ? (
            <>
              <Skeleton />
              <Skeleton />
            </>
          ) : (
            <>
              {covidStatusData?.length > 0 || fluStatusData?.length > 0 ? (
                <>
                  <CovidSatusCard covidStatusInformation={covidStatusData} />
                  <FluStatusCard fluStatusInformation={fluStatusData} />
                </>
              ) : (
                <RowSection
                  header={
                    <Grid container direction="column">
                      <Grid item>{t('candidate.qualificationsCard.vaccinationUnavailableLine1')}</Grid>
                      <Grid item>{t('candidate.qualificationsCard.vaccinationUnavailableLine2')}</Grid>
                    </Grid>
                  }
                  data={''}
                  preData={''}
                  color={theme.palette.framework.system.navyBlue}
                  showHalfwayProgress={true}
                  showHalfwayProgressSecondaryIcon={true}
                  showAlert={false}
                />
              )}
            </>
          )}
        </LayoutGrid>
      </Box>
    </>
  );
}

export default QualificationUI;
