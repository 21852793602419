import { CustomTooltip, XGridChipCell } from '@AMIEWEB/Common';
import { XGridDefaultColumn } from '@AMIEWEB/Common/XGrid/cells/XGridDefaults';
import { GridCellParams, GridColumns } from '@mui/x-data-grid-pro';
import { formatDate } from '@AMIEWEB/Order/OrderDetails/helper';
import { missingField } from 'app/constants';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import React from 'react';
import { colors } from 'styles/styleVariables';
import { DescriptionOutlined } from '@mui/icons-material';
import { Link } from 'amn-ui-core';
import { XGridDocCell } from './XGridDocCell';

export const getContractStatusColors = customSwitch({
  ['Rejected']: '#CB0813',
  ['Expired']: '#CB0813',
  ['Void']: '#CB0813',
  ['Approved']: '#5FB661',
  ['Pending']: '#FC9508',
  ['Pending Approval']: '#FC9508',
  ['Unsaved']: '#FC9508',
})('#000000');

export const getContractStatusColor = customSwitch({
  ['Rejected']: '#CB0813',
  ['Expired']: '#CB0813',
  ['Void']: '#CB0813',
  ['Approved']: '#5FB661',
  ['Pending']: '#FC9508',
  ['Pending Approval']: '#FC9508',
  ['Unsaved']: '#FFFFFF',
})('#000000');

const tooltipContent = {
  message: 'Approval Documents',
};

export const getOffContractGridColumns = history => {
  const handleCreateAtLink = (val: string) => {
    const createdAtPage = val.charAt(0);

    const pageId = val.replace(/[^0-9]/g, '');
    if (createdAtPage === 'F') {
      history.push({ pathname: `/facility/${pageId}` });
    } else {
      history.push({ pathname: `/order/${pageId}` });
    }
  };

  const getValue = (val: any) => {
    let value = missingField;
    if (val) {
      value = val;
    }
    return value;
  };

  const getLink = (val: string) => {
    let value = missingField;
    if (val) {
      return (
        <Link onClick={() => handleCreateAtLink(val)}>
          {val}
        </Link>
      );
    } else {
      return value;
    }
  };
  return [
    {
      ...XGridDefaultColumn,
      field: 'rateElementName',
      headerName: 'Rate Element',
      flex: 0,
      width: 120,
    },
    {
      ...XGridDefaultColumn,
      field: 'rateValue',
      headerName: 'Rate Value',
      headerAlign: 'right',
      align: 'right',
      flex: 0,
      width: 150,
    },
    {
      ...XGridDefaultColumn,
      field: 'shift',
      headerName: 'Shift',
      headerAlign: 'right',
      align: 'right',
      flex: 0,
      width: 80,
    },
    {
      ...XGridDefaultColumn,
      field: 'discipline',
      headerName: 'Discipline',
      sortable: true,
      sortingOrder: 'asc',
      flex: 0,
      width: 100,
    },
    {
      ...XGridDefaultColumn,
      field: 'specialty',
      headerName: 'Specialty',
      flex: 0,
      sortable: true,
      sortingOrder: 'asc',
      width: 100,
    },
    {
      ...XGridDefaultColumn,
      field: 'subSpecialty',
      headerName: 'Sub Specialty',
      sortable: true,
      sortingOrder: 'asc',
      flex: 0,
      width: 120,
    },

    {
      ...XGridDefaultColumn,
      field: 'status',
      headerName: 'Status',
      flex: 0,
      width: 140,
      sortable: true,
      renderCell: XGridChipCell({
        size: 'small',
        dynamicColor: (params: GridCellParams) => {
          return getContractStatusColors(params.value);
        },
        color: (params: GridCellParams) => {
          return params.value === 'Unsaved' ? getContractStatusColor(params.value) : undefined;
        },
        showSecondaryColor: (params: GridCellParams) => {
          return params.value === 'Unsaved';
        },
        renderValue: (params: GridCellParams) => {
          return params.value;
        },
      }),
    },
    {
      ...XGridDefaultColumn,
      field: 'document',
      flex: 0,
      width: 31,
      renderHeader: () => (
        <CustomTooltip tooltipContent={tooltipContent.message} arrow={true}>
          <DescriptionOutlined style={{ color: colors.darkText }} />
        </CustomTooltip>
      ),
      renderCell: params => (
        <XGridDocCell {...params} />
      ),
    },
    {
      ...XGridDefaultColumn,
      field: 'reason',
      headerName: 'Reason',
      flex: 0,
      width: 100,

    },

    {
      ...XGridDefaultColumn,
      field: 'effectiveStartDate',
      headerName: 'Start Date',
      flex: 0,
      width: 100,
      renderCell: params => {
        return getValue(formatDate(params?.row?.effectiveStartDate));
      },
    },
    {
      ...XGridDefaultColumn,
      field: 'effectiveEndDate',
      headerName: 'End Date',
      flex: 0,
      width: 100,
      renderCell: params => {
        return getValue(formatDate(params?.row?.effectiveEndDate));
      },
    },
    {
      ...XGridDefaultColumn,
      field: 'createdAt',
      headerName: 'Created At',
      flex: 0,
      width: 100,
      renderCell: params => {
        return getLink(params?.row?.createdAt);
      },
    },
    {
      ...XGridDefaultColumn,
      field: 'dateCreated',
      headerName: 'Created On',
      flex: 0,
      width: 100,
      renderCell: params => {
        return formatDate(params?.row?.dateCreated) ?? missingField;
      },
    },
    {
      ...XGridDefaultColumn,
      field: 'createdBy',
      headerName: 'Created By',
      flex: 0,
      width: 100,
    },
    {
      ...XGridDefaultColumn,
      field: 'dateLastUpdated',
      headerName: 'Last Updated',
      flex: 0,
      width: 120,
      renderCell: params => {
        return formatDate(params?.row?.dateLastUpdated) ?? missingField;
      },
    },
    {
      ...XGridDefaultColumn,
      field: 'lastUpdatedBy',
      headerName: 'Last Updated By',
      flex: 0,
      width: 150,
      renderCell: params => {
        return params?.row?.lastUpdatedBy ?? missingField;
      },
    },
  ] as GridColumns;
};
