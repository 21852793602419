import { PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { serviceActions } from './async-actions';
import { INewPlacement, IPlacementCandidate, IPlacementOrder, IPlacementValidations } from './types';
import { ICueNoteTransformed } from '@AMIEWEB/Placement/CreateNewPlacement/CuteNotesConfirmation/model';

export const initialState: INewPlacement = {
  candidate: null,
  order: null,
  validations: null,
  newPlacementId: null,
  timestamp: undefined,
  miscellaneous: { screenOptions: [], orderSkillOpts: [] },
  createScreenEnabled: false,
  errorMessage: null,
  cueNoteEmployees: [],
};

const newPlacementSlice = createSlice({
  name: 'newPlacement',
  initialState,
  reducers: {
    setCandidate(state, action: PayloadAction<IPlacementCandidate | null>) {
      return { ...state, candidate: action.payload };
    },
    setOrder(state, action: PayloadAction<IPlacementOrder | null>) {
      if (action.payload) {
        return {
          ...state,
          order: state.order
            ? {
                ...state.order,
                ...action.payload,
                startDate: moment(action.payload.startDate).isValid()
                  ? moment(action.payload.startDate).format('YYYY-MM-DDTHH:mm:ss')
                  : undefined,
                endDate: moment(action.payload.endDate).isValid()
                  ? moment(action.payload.endDate).format('YYYY-MM-DDTHH:mm:ss')
                  : undefined,
              }
            : {
                ...action.payload,
                startDate: moment(action.payload.startDate).isValid()
                  ? moment(action.payload.startDate).format('YYYY-MM-DDTHH:mm:ss')
                  : undefined,
                endDate: moment(action.payload.endDate).isValid()
                  ? moment(action.payload.endDate).format('YYYY-MM-DDTHH:mm:ss')
                  : undefined,
              },
        };
      } else {
        return state;
      }
    },
    setTimestamp(state, action: PayloadAction<string | undefined>) {
      return {
        ...state,
        timestamp: action.payload,
      };
    },
    setValidation(state, action: PayloadAction<IPlacementValidations | null>) {
      return {
        ...state,
        validations: action.payload,
      };
    },
    setPlacementCreated(state, action: PayloadAction<number | null>) {
      return {
        ...state,
        newPlacementId: action.payload,
      };
    },
    setMiscellaneousDataByKey(
      state,
      action: PayloadAction<{ key: 'isAuditValid' | 'screenOptions' | 'orderSkillOpts'; data: any }>,
    ) {
      return {
        ...state,
        miscellaneous: {
          ...state.miscellaneous,
          [action.payload.key]: action.payload.data,
        },
      };
    },
    reset() {
      return { ...initialState };
    },
    setCreateScreenEnabled(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        createScreenEnabled: action.payload,
      };
    },
    setErrorMessage(state, action: PayloadAction<string>) {
      return {
        ...state,
        errorMessage: action.payload,
      };
    },
    /** Temporary prop to distinguish between old and new modal functional code */
    useNewCreatePlacementModalFunctions(state, action: PayloadAction<boolean>) {
      return { ...state, useNewCreatePlacementModalFunctions: action.payload };
    },
    setCueNotesEmployee(state, action: PayloadAction<ICueNoteTransformed[]>) {
      state.cueNoteEmployees = action.payload;
    },
  },
});

export const { name: newPlacementSliceKey, reducer: newPlacementReducer } = newPlacementSlice;

export const newPlacementActions = { ...newPlacementSlice.actions, ...serviceActions };
