import React, { useEffect, useState } from 'react';
import AutoCompleteRenderChips from '@AMIEWEB/Order/OrderCreationForm/Common/AutoCompleteRenderChips';
import { orderCreationActions } from '@AMIEWEB/Order/Store/OrderCreation.actions';
import { selectOrderPriorities } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { commonVIPriority } from '@AMIEWEB/Unit/helper';
import { disabledOrderPrioritiesList, virtualRatingList, virtualRatingListWithVoiceAdvantage } from 'app/constants';
import { selectFacility } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { cloneDeep } from 'lodash';
import { getOPObjectbyLabel, updateArrayLabels } from './Helper';

const OrderPriorities = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orderPriorities = useSelector(selectOrderPriorities);
  const [orderDisabledPriorities, setOrderDisabledPriorities] = useState<string[]>([]);

  const { setValue, getValues, watch } = useFormContext();
  const watchValue = !!getValues('orderPriorities')
    ? cloneDeep(updateArrayLabels(getValues('orderPriorities'), orderPriorities))
    : [];
  const watchVirtualRatingValue = !!getValues('virtualRating') ? getValues('virtualRating') : [];

  const facility = useSelector(selectFacility);
  const facilityDetailsResponse = facility.facilityResponse;

  const addToWatchValueIfNotPresent = label => {
    const object = getOPObjectbyLabel(label, orderPriorities);

    if (!watchValue.find(item => item?.object?.name === label) && object) {
      watchValue.unshift({
        object: object,
        label: object.value + '. ' + object.name,
      });
      setValue('orderPriorities', watchValue);
    }
  };

  const removeFromWatchValueIfPresent = label => {
    const index = watchValue.findIndex(item => item?.object?.name === label);
    if (index !== -1) {
      watchValue.splice(index, 1);
      setValue('orderPriorities', watchValue);
    }
  };

  facilityDetailsResponse?.isMagnetStatus
    ? addToWatchValueIfNotPresent(t('facility.units.unitDetails.additionalInformation.magnetStatus'))
    : removeFromWatchValueIfPresent(t('facility.units.unitDetails.additionalInformation.magnetStatus'));

  facilityDetailsResponse?.isTraumaCenter
    ? addToWatchValueIfNotPresent(t('facility.units.unitDetails.additionalInformation.traumaCenter'))
    : removeFromWatchValueIfPresent(t('facility.units.unitDetails.additionalInformation.traumaCenter'));

  facilityDetailsResponse?.isTeaching
    ? addToWatchValueIfNotPresent(t('facility.units.unitDetails.additionalInformation.teachingHospital'))
    : removeFromWatchValueIfPresent(t('facility.units.unitDetails.additionalInformation.teachingHospital'));

  facilityDetailsResponse?.bedSize <= 199 && facilityDetailsResponse?.bedSize > 0
    ? addToWatchValueIfNotPresent(t('facility.units.unitDetails.additionalInformation.bedSize'))
    : removeFromWatchValueIfPresent(t('facility.units.unitDetails.additionalInformation.bedSize'));

  useEffect(() => {
    dispatch(orderCreationActions.getOrderPriorityTypes());
  }, []);

  useEffect(() => {
    let options: string[] = [];
    const mergeList = [...disabledOrderPrioritiesList, commonVIPriority];

    if (orderPriorities && orderPriorities.length > 0) {
      mergeList.forEach(label => {
        const option = orderPriorities.find(x => x.hasOwnProperty('object') && x.object.name === label);
        if (option) {
          options.push(option.label);
        }
      });
    }
    setOrderDisabledPriorities(options);
  }, [orderPriorities]);

  useEffect(() => {
    if (watchVirtualRatingValue?.length > 0) {
      let virtualRatingsToAdd = watchVirtualRatingValue
        .map(virtualRatings => virtualRatings.label)
        .filter(item => !watchValue.map(orderPriorities => orderPriorities?.object?.name).includes(item));
      virtualRatingsToAdd = watchVirtualRatingValue.filter(a => a.label == virtualRatingsToAdd);
      const virtualRatingsToRemove = virtualRatingListWithVoiceAdvantage.filter(
        item => !watchVirtualRatingValue.map(virtualRatings => virtualRatings.label).includes(item),
      );
      const virtualRatingsFilteredData = orderPriorities.filter(item =>
        virtualRatingsToAdd.map(orderPriorities => orderPriorities?.label).includes(item?.object?.name),
      );
      const uniqueWatchValues = [...new Map(watchValue.map(item => [item.label, item])).values()];

      if (!!virtualRatingsFilteredData && virtualRatingsFilteredData.length > 0) {
        setValue('orderPriorities', [...uniqueWatchValues, ...virtualRatingsFilteredData]);
      } else if (!!virtualRatingsToRemove && virtualRatingsToRemove.length > 0) {
        setValue(
          'orderPriorities',
          uniqueWatchValues.filter((item: any) => !virtualRatingsToRemove.includes(item.object.name)),
        );
      }
      const watchOrderPriorities = watch('orderPriorities');
      const hasVirtualRatingList = watchOrderPriorities?.some(item => virtualRatingList.includes(item?.object?.name));
      const commonVIPresent = watchOrderPriorities?.map(item => item?.object?.name).includes(commonVIPriority);
      if (hasVirtualRatingList && !commonVIPresent) {
        setValue('orderPriorities', [
          ...watchOrderPriorities,
          {
            object: { value: 45, name: commonVIPriority },
            label: '45. ' + commonVIPriority,
          },
        ]);
      } else if (!hasVirtualRatingList && commonVIPresent) {
        setValue(
          'orderPriorities',
          watchOrderPriorities?.filter(item => item?.object?.name !== commonVIPriority),
        );
      }
    } else {
      const watchOrderPriorities = watch('orderPriorities');
      const commonVIPresent = watchOrderPriorities?.map(item => item?.object?.name).includes(commonVIPriority);

      if (commonVIPresent) {
        setValue(
          'orderPriorities',
          watchOrderPriorities?.filter(item => item?.object?.name !== commonVIPriority),
        );
      }
    }
  }, [watchVirtualRatingValue?.length]);

  return (
    <AutoCompleteRenderChips
      label={t('facility.units.unitDetails.additionalInformation.orderPriorities')}
      options={orderPriorities}
      id="order-priorities-dropdown"
      name="orderPriorities"
      disabledValue={orderDisabledPriorities}
      getOptionDisabled={option => !!orderDisabledPriorities?.includes(option.label)}
      disabledOptionsTooltipString={t(
        'facility.units.unitDetails.additionalInformation.orderPrioritiesDisabledOptionsTooltip',
      )}
      optionsWithHoverEffect={disabledOrderPrioritiesList}
    />
  );
};

export default OrderPriorities;
