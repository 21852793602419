import React from 'react';
import { LayoutGrid, LayoutGridItem } from '../../../../../layout/LayoutGrid';
import { Box, CircularProgress } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { GridColumns } from '@mui/x-data-grid-pro';
import { XGrid } from 'app/components/Common/XGrid/XGrid';
import { useDispatch, useSelector } from 'react-redux';
import { XGridDefaultColumn } from 'app/components/Common/XGrid/cells/XGridDefaults';
import { xGridDateSort } from 'app/components/Common/XGrid/sorting/XGridSorting';
import { XGridOverflowCell } from 'app/components/Common/XGrid/cells/XGridOverflowCell';
import { XGridHistoricalHoverView } from 'app/components/Common/XGrid/cells/HoverViewRenderers/XGridHistoricalHoverView';
import { candidateDetailsSelection, selectChangeHistory } from '../../Profile/CandidateDetails.selector';
import { candidateDetailActions } from '../../Profile/CandidateDetails.redux';
import { XGridSSNClickableCell } from './ChangeHistoryClickableCell';
import { usePromiseTracker } from 'react-promise-tracker';

const useStyles = makeStyles()({
  container: {
    padding: '24px 0px',
  },
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '60vh',
  },
});

export const ChangeHistoryTab = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const changeHistory = useSelector(selectChangeHistory);

  const [sortModel, setSortModel] = React.useState<any>([
    {
      field: 'created',
      sort: 'desc',
    },
  ]);

  const { promiseInProgress } = usePromiseTracker({ area: 'get-candidate-details', delay: 0 });

  React.useEffect(() => {
    if (candidateDetails && candidateDetails?.travelerId && candidateDetails?.candidateGuid) {
      dispatch(
        candidateDetailActions.getChangeHistory({
          candidateId: candidateDetails.travelerId,
          candidateGuid: candidateDetails.candidateGuid,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateDetails?.travelerId]);

  const columns: GridColumns = [
    {
      ...XGridDefaultColumn,
      field: 'fieldChanged',
      headerName: 'Field',
    },
    {
      ...XGridDefaultColumn,
      field: 'oldValue',
      headerName: 'Old Value',
      renderCell: XGridOverflowCell(XGridHistoricalHoverView)(XGridSSNClickableCell),
    },
    {
      ...XGridDefaultColumn,
      field: 'newValue',
      headerName: 'New Value',
      renderCell: XGridOverflowCell(XGridHistoricalHoverView)(XGridSSNClickableCell),
    },
    {
      ...XGridDefaultColumn,
      field: 'userName',
      headerName: 'Changed By',
    },
    {
      ...XGridDefaultColumn,
      field: 'created',
      headerName: 'Date',
      type: 'date',
      sortComparator: xGridDateSort,
    },
    {
      ...XGridDefaultColumn,
      field: 'externalSystem',
      headerName: 'Source System',
    },
  ];

  const CandidateChangeHistoryGrid = React.useMemo(() => {
    return (
      <XGrid
        loading={promiseInProgress || changeHistory?.loading}
        columns={columns}
        rows={changeHistory?.rows || []}
        headerHeight={45}
        autoHeight
        disableSelectionOnClick
        sortModel={sortModel}
        onSortModelChange={model => setSortModel(model)}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeHistory?.rows, columns]);

  return (
    <LayoutGrid>
      <LayoutGridItem xs={12}>
        <Box className={classes.container}>{CandidateChangeHistoryGrid}</Box>
      </LayoutGridItem>
    </LayoutGrid>
  );
};
