import { Box, Grid, Typography } from 'amn-ui-core';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import useCommunication from './UseCommunication';
import { ChannelType } from '@AMIEWEB/Notification/Constants';
import SmsEnabledIcon from 'app/assets/images/Communications/SMS Enabled.svg';
import SmsDisabledIcon from 'app/assets/images/Communications/SMS Disabled.svg';
import CallEnabledIcon from 'app/assets/images/Communications/Call Enabled.svg';
import CallDisabledIcon from 'app/assets/images/Communications/Call Disabled.svg';
import smswithdrawyellow from 'app/assets/images/sms-revoke-yellow.svg';
import callwithdrawyellow from 'app/assets/images/call-revoke-yellow.svg';
import { CapitalizeEveryFirstLetter } from 'utils/string/string';
import { ICandidateDetails } from 'app/models/Candidate/CandidateProfile';

const useStyles = makeStyles()(() => ({
  phoneType: {
    width: 100,
    paddingLeft: 24,
  },
  phoneNumber: {
    width: 150,
    fontSize: 'small',
    display: 'flex',
    color: '#006FB9',
  },
  phoneNumberDisabled: {
    width: 150,
    fontSize: 'small',
    display: 'flex',
    color: '#333333',
    cursor: 'default',
  },
  phoneNumberHover: {
    width: 150,
    fontSize: 'small',
    textDecoration: 'underline',
    color: '#006FB9',
    display: 'flex',
  },
  phoneDescription: {
    margin: '6px 0px',
    fontWeight: 500,
    fontSize: 12,
  },
  wrapper: {
    marginTop: 10,
    minWidth: 255,
    maxWidth: 300,
  },
  callNumber: {
    margin: '6px 2px',
    fontSize: 14,
    fontWeight: 400,
    textWrap: 'nowrap',
    cursor: 'pointer',
  },
  callNumberDisabled: {
    margin: '6px 2px',
    fontSize: 14,
    fontWeight: 400,
    textWrap: 'nowrap',
    cursor: 'default',
  },
  warningMessage: {
    color: '#CC0000',
    textAlign: 'left',
  },
  warningMessageContainer: {
    margin: '0px 26px',
  },
}));

interface ICommunicationStackProps {
  channelType: ChannelType;
  phoneNumbers: Array<any>;
  placementsCall?: boolean;
  backDrop?: any;
  showWarningMessage?: boolean;
  candidateDetails?: ICandidateDetails;
  communicationConsentFlag?: boolean;
  candidateProfile? :boolean;
}
const CommunicationStack = (props: ICommunicationStackProps) => {
  const {
    channelType,
    phoneNumbers,
    placementsCall,
    backDrop,
    showWarningMessage,
    candidateDetails,
    communicationConsentFlag,
    candidateProfile = false
  } = props;
  const { classes } = useStyles();
  const { initiateCommunication } = useCommunication();
  const warningMessage =
    channelType === ChannelType.voice
      ? 'Candidate has opted out of Call communication.'
      : 'Candidate has opted out of Text Message communication.';

  const CommunicationItems = item => {
    const enabledIcon = channelType === ChannelType.voice ? CallEnabledIcon : SmsEnabledIcon;
    const disabledIcon = channelType === ChannelType.voice ? CallDisabledIcon : SmsDisabledIcon;
    const disableCommunicationIcon = channelType === ChannelType.voice ? callwithdrawyellow : smswithdrawyellow;
    const communicationConsent = communicationConsentFlag ? true : showWarningMessage ? false : true;
    const [hover, setHover] = React.useState<boolean>(false);
    return (
      <Grid direction={'row'} xs={12}>
        <Grid style={{ display: 'flex', justifyContent: 'space-around', margin: '0px 0' }} data-testid="phone-element">
          <Box className={classes.phoneType}>
            <p className={classes.phoneDescription} data-testid="phone-type">
              {CapitalizeEveryFirstLetter(item?.phoneTypeDescription || '')}
            </p>
          </Box>
          <Box
            className={
              !communicationConsent && !candidateProfile
                ? classes.phoneNumberDisabled
                : hover
                ? classes.phoneNumberHover
                : classes.phoneNumber
            }
            onClick={() => {
              if (!communicationConsent && !candidateProfile) return;
              backDrop && backDrop(true);
              initiateCommunication({
                channelType,
                phoneNumber: item?.phoneNumber,
                manualLog: false,
                placementsCall: placementsCall || false,
                candidateDetails: candidateDetails,
              });
              backDrop && backDrop(false);
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            data-testid="communication-handler-wrapper"
          >
            <img
              src={!communicationConsent ? disableCommunicationIcon : hover ? enabledIcon : disabledIcon}
              style={{ width: 25, margin: '5px 8px' }}
            />
            <p
              className={!communicationConsent && !candidateProfile ? classes.callNumberDisabled : classes.callNumber}
              data-testid="phone-number"
            >
              {item?.phoneNumber || ''}
            </p>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box className={classes.wrapper} data-testid="phone-number-wrapper">
      <Grid container spacing={2}>
        {showWarningMessage && (
          <Grid item className={classes.warningMessageContainer}>
            <Typography className={classes.warningMessage}>{warningMessage}</Typography>
          </Grid>
        )}
        {phoneNumbers?.map(ele => (
          <CommunicationItems {...ele} />
        ))}
      </Grid>
    </Box>
  );
};

export default CommunicationStack;
