import { OrderConstants } from 'app/constants/OrderCreationForm';
import OrderShiftList from 'app/assets/jsons/OrderCreation/OrderShifts.json';
import { IOrderDetails, NewUIOrder, ShiftResponse, Certifications } from 'app/models/Orders/OrderDetails';
import { IShiftsAvailable } from 'app/models/Orders/CreateOrder';
import { CompactStatus, IOrderCertification, IOrderLicense, LevelRules } from '../Store/Order.redux';
import { NewOrder } from '../../../models/Orders/OrderDetails';
import { formatAddress, formatDate, getName, getValue } from '../OrderDetails/helper';
import { createOrderContacts } from 'app/components/Candidate/CandidateProfile/CandidateHeader/contactTabHelper';
import { convertToFormat } from 'app/helpers/dateHelper';
import { AMNDivisionType } from 'app/models/enums/AMNDivisionType';
import { isNull } from 'lodash';

const getRegion = facilityData => {
  let region;
  if (facilityData.region === 'AMN Allied') {
    return null;
  } else {
    region = facilityData.region;
  }
  if (facilityData.amnDivisionTypeId === AMNDivisionType.hsg) {
    region = 'Unassigned';
  } else {
    region = facilityData.region;
  }
  return region;
};

export const getEmptyOrder = facilityData => {
  return {
    facility: {
      facilityId: facilityData.facilityId,
      name: facilityData.facilityName,
      facilityBestBetsId: 0,
      creditStatusId: 0,
    },
    unit: {
      unitId: 0,
      name: '',
      unitBestBetsId: 0,
    },
    type: facilityData.amnDivisionTypeId === AMNDivisionType.hsg ? 'Strike' : 'Travel',
    orderTypeId: 1,
    region: getRegion(facilityData),
    skillsets: [],
    orderPriorities: getOrderPriorities({
      bedSize: facilityData.bedSize,
      teaching: facilityData.isTeaching,
      magnetStatus: facilityData.isMagnetStatus,
      traumaCenter: facilityData.isTraumaCenter,
    }),
    orderBillRate: null,
    onCallRate: null,
    callBackMinimum: '',
    completionBonus: '',
    hideFacilityDescription: false,
    suppressExternalPosting: false,
    CF: false,
    newGrad: false,
    maxFilesSent: false,
    reasonForMaxFilesSent: '',
    VMSOrderNumber: '',
    weblink: '',
    yearsOfExperience: 0,
    positionsAvailable: 1,
    requiredQualifications: '',
    preferredQualifications: '',
    jobDescription: '',
    internalNotes: '',
    shifts: [],
    placementLength: 13,
    dates: [],
    startDate: null,
    endDate: null,
    ASAP: false,
    flexibleDates: false,
    expectedHours: null,
    guaranteedHours: null,
    orderReason: null,
    orderReasonId: null,
    addBillRateRange: false,
    negotiableRate: 'No',
    notes: '',
    nonContractedBillRates: [],
  } as NewOrder;
};

export const getEmptyUIOrderObj = facilityData => {
  return {
    facility: {
      facilityId: facilityData.facilityId,
      name: facilityData.facilityName,
      facilityBestBetsId: 0,
      creditStatusId: 0,
    },
    unit: {
      unitId: 0,
      name: '',
      unitBestBetsId: 0,
    },
    type: facilityData.amnDivisionTypeId === AMNDivisionType.hsg ? 'Strike' : 'Travel',
    orderTypeId: 1,
    region: getRegion(facilityData),
    skillsets: [],
    orderPriorities: getOrderPriorities({
      bedSize: facilityData.bedSize,
      teaching: facilityData.isTeaching,
      magnetStatus: facilityData.isMagnetStatus,
      traumaCenter: facilityData.isTraumaCenter,
    }),
    nurseRatio: null,
    unitSize: 0,
    status: '',
    fyreIntegration: null,
    orderBillRate: null,
    onCallRate: null,
    callBackMinimum: '',
    completionBonus: '',
    hideFacilityDescription: false,
    suppressExternalPosting: false,
    CF: false,
    newGrad: false,
    maxFilesSent: false,
    reasonForMaxFilesSent: '',
    VMSOrderNumber: '',
    weblink: '',
    yearsOfExperience: 0,
    positionsAvailable: 1,
    requiredQualifications: '',
    preferredQualifications: '',
    jobDescription: '',
    internalNotes: '',
    shifts: [],
    placementLength: 13,
    dates: [],
    startDate: null,
    endDate: null,
    ASAP: false,
    flexibleDates: false,
    expectedHours: null,
    guaranteedHours: null,
    orderReason: null,
    orderReasonId: null,
    addBillRateRange: false,
    negotiableRate: 'No',
    notes: '',
    nonContractedBillRates: [],
  } as NewUIOrder;
};

export const getOrderPriorities = facilityData => {
  const defaultPriorities: string[] = [];
  for (var property in facilityData) {
    if (facilityData[property]) {
      defaultPriorities.push(OrderConstants.OrderPrioritiesLabel[property]);
    }
  }
  return defaultPriorities;
};

export const formatShiftInputData = (shiftData: string) => {
  const extractedData: IShiftsAvailable = OrderShiftList.filter(item => item.Description === shiftData)[0];
  const formattedData: ShiftResponse = { id: extractedData.Id, description: extractedData.Description };
  return formattedData;
};

export const formatShiftResponse = (shiftResponse: ShiftResponse) => {
  const formattedShift: IShiftsAvailable = {
    Id: shiftResponse.id,
    Description: shiftResponse.description,
  };
  return formattedShift;
};

export const formatShiftSave = (shiftData: IShiftsAvailable) => {
  const formattedData: ShiftResponse = {
    id: shiftData.Id,
    description: shiftData.Description,
  };
  return formattedData;
};

export const getSelectedShiftList = (list: IShiftsAvailable[], value: IShiftsAvailable) => {
  return list.some(item => value.Id === item.Id);
};

export const levelRules: LevelRules[] = [
  { level: 'Standardize', fieldName: 'name', isRequired: false, disabled: true },
  { level: 'Standardize', fieldName: 'costCenter', isRequired: false, disabled: true },
  { level: 'Standardize', fieldName: 'floor', isRequired: false, disabled: true },
  { level: 'Standardize', fieldName: 'virtualRating', isRequired: false, disabled: true },
  { level: 'Standardize', fieldName: 'bestBets', isRequired: false, disabled: true },
  { level: 'Standardize', fieldName: 'unitSize', isRequired: false, disabled: true },
  { level: 'Standardize', fieldName: 'nurse', isRequired: false, disabled: true },
  { level: 'Standardize', fieldName: 'patient', isRequired: false, disabled: true },
  { level: 'Standardize', fieldName: 'nurseRatio', isRequired: false, disabled: true },
  { level: 'Standardize', fieldName: 'fyreIntegration', isRequired: false, disabled: true },
  { level: 'Standardize', fieldName: 'status', isRequired: false, disabled: true },
  { level: 'Standardize', fieldName: 'requiredQualifications', isRequired: false, disabled: true },
  { level: 'Standardize', fieldName: 'preferredQualifications', isRequired: false, disabled: true },

  { level: 'Standardize', fieldName: 'travelExperience', isRequired: false, disabled: false },
  { level: 'Standardize', fieldName: 'division', isRequired: false, disabled: false },
  { level: 'Standardize', fieldName: 'yearsOfExperience', isRequired: false, disabled: false },

  { level: 'Classic', fieldName: 'name', isRequired: false, disabled: true },
  { level: 'Classic', fieldName: 'costCenter', isRequired: false, disabled: true },
  { level: 'Classic', fieldName: 'floor', isRequired: false, disabled: true },
  { level: 'Classic', fieldName: 'requiredQualifications', isRequired: false, disabled: true },
  { level: 'Classic', fieldName: 'preferredQualifications', isRequired: false, disabled: true },
  { level: 'Classic', fieldName: 'travelExperience', isRequired: false, disabled: true },
  { level: 'Classic', fieldName: 'virtualRating', isRequired: false, disabled: true },
  { level: 'Classic', fieldName: 'nurse', isRequired: false, disabled: true },
  { level: 'Classic', fieldName: 'patient', isRequired: false, disabled: true },
  { level: 'Classic', fieldName: 'nurseRatio', isRequired: false, disabled: true },
  { level: 'Classic', fieldName: 'fyreIntegration', isRequired: false, disabled: true },
  { level: 'Classic', fieldName: 'status', isRequired: false, disabled: true },

  { level: 'Classic', fieldName: 'division', isRequired: false, disabled: false },
  { level: 'Classic', fieldName: 'compactLicense', isRequired: false, disabled: false },
  { level: 'Classic', fieldName: 'activeLicense', isRequired: false, disabled: false },

  { level: 'Classic', fieldName: 'skillsets', isRequired: true, disabled: false },
  { level: 'Classic', fieldName: 'yearsOfExperience', isRequired: false, disabled: false },
  { level: 'Classic', fieldName: 'externalJobDescription', isRequired: true, disabled: false },
  { level: 'Classic', fieldName: 'internalNotes', isRequired: false, disabled: false },
  { level: 'Classic', fieldName: 'orderPriorities', isRequired: false, disabled: false },
];

//skillsets & externalJobDescription if both the values are provided then only select NEXT.

export const getElement = (level, title) => {
  return levelRules?.find(ele => {
    return ele.fieldName === title && ele.level === level;
  });
};

export const getInternalNotes = (order: NewOrder) => {
  const billRates = order.nonContractedBillRates.map(billRateItem => {
    const billRateSkillsets = billRateItem.skillsets;

    let rates = '';
    if (billRateItem.startRate !== '' && billRateItem.endRate === '') {
      rates = `$${billRateItem.startRate}`;
    } else if (billRateItem.endRate !== '' && billRateItem.startRate === '') {
      rates = `$${billRateItem.endRate}`;
    } else if (billRateItem.endRate !== '' && billRateItem.startRate !== '') {
      rates = `$${billRateItem.startRate}-$${billRateItem.endRate}`;
    }

    if (billRateSkillsets.length > 0) {
      return billRateSkillsets
        .map(item => {
          if (item !== 'All') {
            let skillset = '';
            if (billRateItem.category === 'CF' || billRateItem.category === 'NG' || billRateItem.category === 'Local') {
              skillset = `${billRateItem.category} Bill Rate ${item}`;
            } else if (
              billRateItem.category.startsWith('Start Date By') ||
              billRateItem.category.startsWith('End Date By')
            ) {
              skillset = `${billRateItem.category} Bill Rate ${item}`;
            } else {
              skillset = `Bill Rate ${item}`;
            }

            if (rates !== '') {
              return `${skillset}: ${rates}`;
            } else {
              return `${skillset}`;
            }
          }
        })
        .join('\n');
    } else {
      if (billRateItem.category !== '' && rates !== '') {
        if (billRateItem.category === 'CF' || billRateItem.category === 'NG') {
          return `${billRateItem.category} Bill Rate: ${rates}`;
        } else if (billRateItem.category === 'Local') {
          return `Local: ${rates}`;
        } else if (
          billRateItem.category.startsWith('Start Date By') ||
          billRateItem.category.startsWith('End Date By')
        ) {
          return `${billRateItem.category} Bill Rate ${rates}`;
        } else {
          return `Bill Rate: ${rates}`;
        }
      } else if (billRateItem.category !== '' && rates === '') {
        return `${billRateItem.category}`;
      }
      return `Bill Rate: ${rates}`;
    }
  });

  return `${order.notes !== '' ? `${order.notes}\n` : ''}Negotiable/Set Rate: ${order.negotiableRate}${
    billRates.length > 0 ? `\n${billRates.join('\n')}` : ''
  }`;
};

export const toHeaderDetails = (order: IOrderDetails) => {
  return {
    orderID: order.orderId,
    facilityName: order.facility.name ? order.facility.name : '-',
    facilityId: order.facility.facilityId,
    unit: order.unit.name,
    location: order.location
      ? formatAddress(order.location)
      : {
          addressLine1: null,
          addressLine2: 'N/A',
          phoneNumber: null,
        },
    skillSet: order.skillsets,
    lastVerified: order.dateVerified ? formatDate(order.dateVerified) : 'N/A',
    verifiedBy: order.contacts?.verifiedBy ? getName(order.contacts.verifiedBy) : 'N/A',
    accountManager: order.contacts ? getName(order.contacts.accountManager) : 'N/A',
    regionalDirector: order.contacts ? getName(order.contacts.regionalDirector) : 'N/A',
    type: order.orderType,
    division: order.division,
    brandName: order.brandinfo ? order.brandinfo[0].brandName : '',
    contacts: createOrderContacts(order?.contacts),
    status: order.status || 'N/A',
  };
};

export function toFixed(num, fixed) {
  var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
  if (parseFloat(num) % 1 === 0) {
    return num.toString().match(re)[0];
  }
  return num.toString().concat('0').match(re)[0];
}

export const toQuickGlance = (order: IOrderDetails) => {
  return {
    orderId: order.orderId,
    positionsAvailable: order.positionsAvailable ? order.positionsAvailable.toString() : '0',
    startDate: convertToFormat(order.startDate || null, 'MM/DD/yyyy') || 'N/A',
    placementLength: order.numOfWeeks ? order.numOfWeeks + ' Weeks' : 'N/A',
    maxFilesSent: getValue(order.maxFilesSent),
    guaranteedWorkWeek: getValue(order.gww),
    activeBonus: getValue(order.activeBonus),
    weeklyGrossPay:
      order.weeklyGrossPay === null ? 'N/A' : order.weeklyGrossPay === 0 ? '0' : '$' + order.weeklyGrossPay,
    billRate: order.orderBillRate ? '$' + toFixed(order.orderBillRate, 2) : 'N/A',
    jobMatches: '0',
  };
};

export const getListsFromReqTypesAndLookups = (requirementTypes, lookUps, filterCertsFlagDecision) => {
  if (filterCertsFlagDecision) {
    requirementTypes = requirementTypes?.filter(req => {
      if (req.category === 'Certifications' && req.unitId === 1) {
        return req;
      } else if ((req.category === 'Licenses' || req.requirementTypeID === 313) && req.requirementTypeID !== 218) {
        return req;
      }
    });
  }
  let qualifications: any[] = [];
  if (requirementTypes.length > 0) {
    requirementTypes?.forEach(item => {
      const req = { ...item };
      const lookupTypes = lookUps?.find(
        lookup =>
          lookup.requirementTypeID === item.requirementTypeID &&
          lookup.lookUpField === 'TypeID' &&
          (item.category === 'Certifications' || item.category === 'Licenses') &&
          lookup.requirementTypeID === Certifications.CERT_CERTIFICATE &&
          lookup.lookups !== undefined,
      );

      if (lookupTypes !== undefined)
        lookupTypes.lookups?.forEach(lookupType => {
          if (
            !qualifications.find(
              x => x.requirementTypeID === lookupType.value * 1000 || x.abbreviation === lookupType.description,
            )
          ) {
            //&& lookupType.value != 243
            qualifications.push({
              requirementTypeID: lookupType.value * 1000,
              abbreviation: lookupType.description,
            });
          }
        });

      const qualification = qualifications.find(x => x.abbreviation === req.abbreviation);
      //Add if not exist else remove lookup and add requirement
      if (!qualification || !qualification.credentialName) {
        qualifications = [...qualifications.filter(x => x.abbreviation !== req.abbreviation), req];
      }
    });
  }
  const licenses: any[] = [];
  const certifications: any[] = [];
  qualifications.map(x => {
    if (
      (x.category === 'Licenses' && x.abbreviation !== 'LICNOTREQ') ||
      x.abbreviation === 'GOVLIC' ||
      x.abbreviation === 'SEA'
    ) {
      licenses.push(x);
    } else {
      certifications.push(x);
    }
  });
  licenses.sort((a, b) => a.abbreviation.toLowerCase().localeCompare(b.abbreviation.toLowerCase()));
  certifications.sort((a, b) => a.abbreviation.toLowerCase().localeCompare(b.abbreviation.toLowerCase()));
  return { certifications, licenses };
};

export const getInvalidLicenses = (licenses: any[] = []) => {
  const invalidLicenses = [];
  licenses?.map(l => {
    if (!l.requirementTypeId || l.isMultiState === null) {
      invalidLicenses.push(l);
    } else if (!l.isMultiState && (!l.state || !l.stateAbbreviation)) {
      invalidLicenses.push(l);
    }
  });
  return invalidLicenses;
};

export const getInvalidCertification = (certifications: IOrderCertification[]) => {
  const invalidCertifications: any[] = [];
  const isParentCertExist = certifications?.some(item => item?.requirementTypeId === Certifications.CERT_CERTIFICATE);
  if (isParentCertExist) {
    const isSubCertExists = certifications?.some(item => item?.isSubCert === true);
    if (isParentCertExist && !isSubCertExists) {
      invalidCertifications.push(
        certifications?.find(item => item?.requirementTypeId === Certifications.CERT_CERTIFICATE),
      );
    }
  }
  return invalidCertifications;
};

export const validateInternalNotes = (internalFlag: boolean, notes: string) => {
  return internalFlag ? notes?.length > 8000 : notes?.length > 1500;
};

export const formatOrderLicenseList = (orderLicenses: IOrderLicense[], dataList: any[]) => {
  const formattedSelectedOrderLicense = [];
  dataList.map(requirementType => {
    // Find a matching license based on requirementTypeID
    const matchingLicense = orderLicenses?.find(
      license =>
        license.licenseId === requirementType.requirementTypeID ||
        license.licenseId === requirementType.requirementTypeId,
    );
    const selectedOrderDetails = matchingLicense
      ? {
          ...matchingLicense,
          ...requirementType,
          licenseStateDetails: matchingLicense?.licenseStateDetails,
          compactStatus: matchingLicense.compactStatus,
          isSelected: true,
          licenseId: matchingLicense.licenseId,
        }
      : {
          ...requirementType,
          licenseId: 0,
          isSelected: false,
        };
    formattedSelectedOrderLicense.push(selectedOrderDetails);
  });
  return formattedSelectedOrderLicense;
};

export const transformLicenseData = (licenseData: IOrderLicense[]) => {
  const transformedLicenses: any[] = [];
  licenseData?.forEach(license => {
    if (license?.compactStatus === CompactStatus.single) {
      if (license?.licenseStateDetails?.length > 0) {
        license?.licenseStateDetails?.map(stateDetails => {
          transformedLicenses.push({
            requirementTypeId: license.licenseId,
            licenseAbbreviation: license.licenseAbbreviation,
            licenseDescription: license.licenseDescription,
            stateAbbreviation: stateDetails?.state?.value,
            state: stateDetails?.state?.name,
            isRequired: stateDetails?.isRequired,
            isMultiState: false,
            documentAttachmentRequired: stateDetails?.documentAttachmentRequired,
            isError: license.isError,
          });
        });
      } else {
        transformedLicenses.push({
          requirementTypeId: license.licenseId,
          licenseAbbreviation: license.licenseAbbreviation,
          licenseDescription: license.licenseDescription,
          stateAbbreviation: null,
          state: null,
          isRequired: false,
          isMultiState: false,
          documentAttachmentRequired: false,
          isError: license.isError,
        });
      }
    } else if (license?.compactStatus === CompactStatus.compact) {
      transformedLicenses.push({
        requirementTypeId: license.licenseId,
        licenseAbbreviation: license.licenseAbbreviation,
        licenseDescription: license.licenseDescription,
        stateAbbreviation: null,
        state: null,
        isRequired: license?.licenseStateDetails[0]?.isRequired,
        isMultiState: true,
        documentAttachmentRequired: license?.licenseStateDetails[0]?.documentAttachmentRequired,
        isError: license.isError,
      });
    } else {
      transformedLicenses.push({
        requirementTypeId: license.licenseId,
        licenseAbbreviation: license.licenseAbbreviation,
        licenseDescription: license.licenseDescription,
        stateAbbreviation: null,
        state: null,
        isRequired: true,
        isMultiState: null,
        documentAttachmentRequired: false,
        isError: license.isError,
      });
    }
  });
  return transformedLicenses;
};

export const getValidCertifications = (savedCertifications: any[], currentCertificationsList: any[]) => {
  const validCertifications = [];
  savedCertifications?.forEach(item => {
    const validCert = currentCertificationsList?.find(x => x.requirementTypeID === item.requirementTypeId);
    if (validCert) {
      validCertifications.push(item);
    }
  });
  return validCertifications ?? [];
};

export const isWholeNumber = value => Number(value) >= 0 && !isNull(value);