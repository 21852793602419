import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import {
  selectPlacementDetails,
  selectPlacementLinkModalOpen,
  selectPlacementSupervisorDetals,
} from 'store/redux-store/placement-details/selectors';
import { placementDetailsAction } from 'store/redux-store/placement-details/slice';
import { FormProvider, useForm } from 'react-hook-form';
import { SearchPlacement } from '../Helpers/SearchPlacement';
import { Card, CardContent, Typography } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()({
  root: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '20px 20px 0px 20px',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  pidText: {
    color: '#333333',
    fontSize: '14px',
    marginBottom: '20px',
    fontWeight: 600,
  },
  errorText: {
    color: 'red',
  },
  card: {
    width: '100%',
    height: '17vh',
    marginBottom: '25px',
    borderRadius: '4px',
    border: '1px solid #CCCCCC',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    height: '100%',
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: '2vh',
  },
  label: {
    color: '#000',
    fontSize: '12px',
    fontWeight: 600,
  },
  dialogueContent: {
    overflow: 'hidden',
    padding: 0,
    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .dialogContentRoot': {
      padding: 0,
    },
  },
  dialog: {
    minWidth: '960px',
    top: 0,
    '& .MuiDialog-paperWidthSm': {
      minWidth: '40vw',
      maxWidth: '100%',
    },
  },
  wrapper: {
    position: 'absolute',
    top: 0,
    left: '10vw',
  },
  expandCard: {
    height: '58vh',
    width: '100%',
    marginBottom: '25px',
    borderRadius: '4px',
    border: '1px solid #CCCCCC',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});

const AddPlacementIdModal = props => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const isModalOpen = useSelector(selectPlacementLinkModalOpen);
  const supervisorDetals = useSelector(selectPlacementSupervisorDetals);

  const {
    header: { placementId },
  } = useSelector(selectPlacementDetails);
  const dispatch = useDispatch();
  const formMethods = useForm({
    defaultValues: {},
  });
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [fieldValue, setFieldValue] = useState('');
  const missingField = '--';

  const handleCancel = () => {
    dispatch(placementDetailsAction.setPlacementLinkDetails([]));
  };
  const handleCloseModal = (e, reason) => {
    if (reason !== 'backdropClick') {
      dispatch(placementDetailsAction.setPlacementLinkModalOpen(false));
    }
  };
  const handleChange = () => {
    const payload = {
      supervisorPlacementId: parseInt(fieldValue),
      superviseePlacementId: placementId,
    };
    dispatch(placementDetailsAction.getPlacementSupervisorLinkAction(payload));
  };

  const handleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={classes.wrapper}>
      <GenericDialog
        open={isModalOpen}
        variant="blue"
        className={classes.dialog}
        onClose={handleCloseModal}
        dialogTitleProps={{
          text: 'Link PID',
          closeButton: true,
          expandable: true,
          onExpandChange: handleExpanded,
        }}
        dialogContentProps={{
          classes: {
            root: classes.dialogueContent,
          },
        }}
        dialogActions={[
          {
            text: 'Cancel',
            variant: 'contained',
            color: 'tertiary',
            useDialogOnCloseEvent: true,
            onClick: handleCancel,
          },
          {
            text: 'SAVE',
            variant: 'contained',
            color: 'primary',
            disabled: !fieldValue,
            onClick: handleChange,
          },
        ]}
      >
        <FormProvider {...formMethods}>
          <form>
            <div className={classes.root} data-testid="add-supervisor">
              <div className={classes.pidText}>{t('placement.profile.candidatePlacementSupervision.pid')}</div>
              <SearchPlacement setFieldValue={setFieldValue} fieldValue={fieldValue} />
              <Card className={`${expanded ? classes.card : classes.expandCard}`}>
                <CardContent className={`${expanded ? classes.cardContent : ''}`}>
                  <div className={classes.labelContainer}>
                    <Typography className={classes.label}>{props?.title}</Typography>
                    <Typography>{!fieldValue ? missingField : null}</Typography>
                    {supervisorDetals.map((item, index) => (
                      <Typography key={index}>{item?.supervisor ? item.supervisor || missingField : null}</Typography>
                    ))}
                  </div>
                  <div className={classes.labelContainer}>
                    <Typography className={classes.label}>
                      {t('placement.profile.candidatePlacementSupervision.sd')}
                    </Typography>
                    <Typography>{!fieldValue ? missingField : null}</Typography>
                    {supervisorDetals.map((item, index) => (
                      <Typography key={index}>{item?.supervisor ? item.startDate || missingField : null}</Typography>
                    ))}
                  </div>
                  <div className={classes.labelContainer}>
                    <Typography className={classes.label}>
                      {t('placement.profile.candidatePlacementSupervision.ed')}
                    </Typography>
                    <Typography>{!fieldValue ? missingField : null}</Typography>
                    {supervisorDetals.map((item, index) => (
                      <Typography key={index}>{item?.supervisor ? item.endDate || missingField : null}</Typography>
                    ))}
                  </div>
                </CardContent>
              </Card>
            </div>
          </form>
        </FormProvider>
      </GenericDialog>
    </div>
  );
};

export default AddPlacementIdModal;
