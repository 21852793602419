import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { trackPromise } from 'react-promise-tracker';
import { put, select, getContext, call } from 'redux-saga/effects';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import { placementDetailsAction } from 'store/redux-store/placement-details/slice';
import { resetFailureStatus } from '../saga';
import { IPlacementSummaryRequest, placementSummaryActions } from '../slice';
import InitPlacementSummary, { IConsolidatedRequirementResponse } from '../init.types';
import { selectPlacementConsolidatedDetails } from '../consolidatedStoreSelectors';

const TrackScheduleOrderReqWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-schedule-order-requirement');

export function* requestScheduleOrderRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('scheduleOrderRequirement'));
    const placementOrderType = yield select(selectPlacementOrderType);
    const placementService: PlacementService = yield getContext('placementService');

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if(action.payload.isInitLoad && consolidatedDetails){
      response = {
        status : consolidatedDetails.summary?.submission?.orderSubmissionBusinessRequrement?.schedule?.status,
        data : consolidatedDetails.summary?.submission?.orderSubmissionBusinessRequrement?.schedule?.data
      }
    }

    if(!response?.status || !response?.data){
      response = yield call(
        TrackScheduleOrderReqWrapper,
        placementService.getPlacementSummarySchedule_OrderReq,
        action.payload.placementId,
        placementOrderType,
      );
    }
    
    if (httpSuccess(response?.status)) {
      const { data } = response;
      yield put(placementSummaryActions.setSubmissionInfoByKey({ key: 'schedule', isOrderReq: true, ...data }));
      yield put(placementDetailsAction.resetForm());
    } else
      yield put(
        placementSummaryActions.setServiceFailureByKey({ key: 'scheduleOrderRequirement', failureStatus: true }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestScheduleOrderRequirement',
        area: 'src/store/redux-store/placement-summary/common-saga/scheduleOrderRequirement.ts',
      },
    });
  }
}

export function processScheduleOrderRequirement(
  response: IConsolidatedRequirementResponse<any>,
  context: InitPlacementSummary,
) {
  try {
    if (!httpSuccess(response?.status)) {
      context.setServiceFailureByKey({ key: 'scheduleOrderRequirement', failureStatus: true });
      return;
    }
    context.setSubmissionInfoByKey({ key: 'schedule', isOrderReq: true, ...response.data });
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'processScheduleOrderRequirement',
        area: 'src/store/redux-store/placement-summary/common-saga/scheduleOrderRequirement.ts',
      },
    });
  }
}
