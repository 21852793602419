import { trackException } from "app-insights/appInsightsTracking";
import { ExceptionType } from "app/enums/Common";
import baseAxiosInstance from "utils/BaseApi";

export function distance(lat1, lon1, lat2, lon2) {
    if (lat1 == undefined || lon1 == undefined || lat2 == undefined || lon2 == undefined) {
        return ('--')
    } else {
        const R = 3958.8; // Earth's radius in miles
        const dLat = toRadians(lat2 - lat1);
        const dLon = toRadians(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadians(lat1)) *
            Math.cos(toRadians(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const dist = R * c;
        const rounded = Math.round(dist * 10) / 10
        return `${rounded} mi`;
    }
};

export function toRadians(degrees) {
    return degrees * (Math.PI / 180);
};

export async function getDistance(payload) {
    try {
        const url = 'shared/v1/distance/geo-distance';
      const response = await baseAxiosInstance.post(url, payload);
      return response.data;
    } catch (error) {
      trackException({
        exception: error,
        properties: {
          name: ExceptionType.APIResponseError,
          functionName: 'getDistance',
        },
      });
    }
  }