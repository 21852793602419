import React from 'react';
import { useTranslation } from 'react-i18next';
import { UnitDetailsTextArea } from '../../../Common/UnitDetailsCommonTextArea';
import { Controller, useFormContext } from 'react-hook-form';
import { FormPageType } from '../UnitRequirements/Experience/SubCertificate/SubCertificate.types';
import { useSelector } from 'react-redux';
import { selectInternalNotes } from '@AMIEWEB/NewOrder/Store/Order.selectors';

export const InternalNotes = ({ parentPage }: { parentPage: FormPageType }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const selectedOrderInternalNotes = useSelector(selectInternalNotes);

  return (
    <Controller
      control={control}
      name={'internalNotes'}
      render={({ ref, onChange, ...rest }) => (
        <UnitDetailsTextArea
          fieldLabel={t('facility.units.internalJobNotes.title')}
          fieldName={'internalNotes'}
          id={'internalNotes'}
          onChange={onChange}
          isDisabled={false}
          inputValue={rest.value}
          defaultInputValue={parentPage === FormPageType.Order ? selectedOrderInternalNotes : ''}
          showMaxCount
          maxCharacters={8000}
        />
      )}
    />
  );
};

export default InternalNotes;
