import { PayloadAction } from '@reduxjs/toolkit';
import { CandidateService } from 'app/services/CandidateServices/candidate-service';
import { put, call, getContext, select, fork } from 'redux-saga/effects';
import { trackPromise } from 'react-promise-tracker';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { httpSuccess } from 'app/services/serviceHelpers';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { candidateDetailsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { selectUser } from 'oidc/user.selectors';
import { workExperienceActions } from './slice';
import { deriveWorkExperienceData } from './business.saga';
import i18next from 'i18next';
import { selectSelectedWorkExperience } from './selector';
import { getJobTypeValue, getWorkExpLastUpdatedText } from './helper';

const TrackUpdateWorkExperience = (fn, ...args) => trackPromise(fn(...args), 'update-work-experience');
const TrackUpdateWorkExperienceBatch = (fn, ...args) => trackPromise(fn(...args), 'update-work-experience-batch');

//Single Work Experience Record Update
export function* updateCandidateWorkExperience(action: PayloadAction<any>) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const candidateDetails = yield select(candidateDetailsSelection);
    const response = yield call(
      TrackUpdateWorkExperience,
      candidateService.updateCandidateWorkExperience,
      action.payload,
    );

    if (response && httpSuccess(response.status)) {
      const existingSelectedWorkExp = yield select(selectSelectedWorkExperience);

      yield put(
        workExperienceActions.setSelectedWorkExperience({
          ...response.data,
          referenceData: existingSelectedWorkExp?.referenceData || [],
          jobType: getJobTypeValue(response.data),
          lastUpdatedText: getWorkExpLastUpdatedText(response.data),
        }),
      );

      yield fork(deriveWorkExperienceData, candidateDetails?.travelerId, candidateDetails?.brandId);

      yield put(
        globalActions.setSnackBar({
          message: i18next.t('candidate.workExperience.snackbar.updateWorkExperience'),
          severity: 'success',
        }),
      );
    } else {
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('candidate.workExperience.snackbar.updateFailWorkExperience'),
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'updateCandidateWorkExperience',
        area: 'src/store/redux-store/candidate-work-experience/update-saga.ts',
      },
    });
  }
}

//Bulk update of Work Experience Records
export function* updateCandidateWorkExperienceBulk(action: PayloadAction<{ workExperienceList: any[] }>) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const candidateDetails = yield select(candidateDetailsSelection);
    const user = yield select(selectUser);
    const payload = {
      candidateId: candidateDetails?.travelerId,
      brandId: candidateDetails?.brandId,
      currentEmployeeId: user.userInfo?.employeeId || 0,
      currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
      workHistoryList: action.payload?.workExperienceList,
    };

    const response = yield call(
      TrackUpdateWorkExperienceBatch,
      candidateService.updateCandidateWorkExperienceBulk,
      payload,
    );

    if (httpSuccess(response.status)) {
      yield fork(deriveWorkExperienceData, candidateDetails?.travelerId, candidateDetails?.brandId);
      const action = {
        payload: { candidateId: candidateDetails?.travelerId, brandId: candidateDetails?.brandId },
        type: '',
      };
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('candidate.workExperience.snackbar.updateWorkExperience'),
          severity: 'success',
        }),
      );
    } else {
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('candidate.workExperience.snackbar.updateFailWorkExperience'),
          severity: 'error',
        }),
      );
    }

    yield put(workExperienceActions.setEditWorkExperienceAPIStatusCode(response?.status));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'updateCandidateWorkExperience',
        area: 'src/store/redux-store/candidate-work-experience/update-saga.ts',
      },
    });
  }
}
