import React, { useEffect, useState } from 'react';
import { Avatar, Grid, Paper, ThemeProvider, StyledEngineProvider, Typography, useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { ContentDivider, ContentTitle, PreviewContent, PreviewContentTitle, UnitTitle } from './UnitPreviewDesign';
import { Chip } from 'amn-ui-core';
import { InnerLabelValueSection } from './Common/InnerLabelValueSection';
import { InnerValueSection } from './Common/InnerValueSection';
import { NewUnit } from 'app/models/Unit/CreateUnit';
import { getOrderPrioritiesValue, getReference, getTravelExperience } from 'app/components/Unit/helper';
import { InnerIconSection } from './Common/CharacterIconLabel';
import { ValueContainer } from './Common/ValueContainer';
import { InnerValueHTMLSection } from './Common/InnerValueHTMLSection';
import { MediaQuery } from 'app/layout/MediaQuery';
import { missingField } from 'app/constants';
import { RequirementLabel } from './Common/RequirementLabel';
import { LicenseRequirementLabel } from './Common/LicenseRequirementLabel';
import { CertificationRequirementLabel } from './Common/CertificationRequirementLabel';
import { useSelector } from 'react-redux';
import { selectOrderRequirementTypes } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { WhiteTooltip } from '@AMIEWEB/Common';

interface Props {
  unitData: NewUnit;
}

const useStyles = makeStyles()(theme => ({
  multiLineWrapper: {
    paddingTop: '0px !important',
    color: '#808080',
    paddingBottom: '0px !important',
  },
  titleWrapper: {
    paddingBottom: '0px !important',
  },

  chip: {
    margin: '0 5px 0 0',
    maxWidth: '50ch',
  },
  chipBlock: {
    display: 'flex',
    maxWidth: '150px',
  },
  truncation: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  chipIcon: {
    fontSize: 'x-large',
    marginLeft: 6,
  },
  paper: {
    padding: theme.spacing(2),
  },
  fontResponsiveHeader: {
    fontSize: '17px !important',
  },
  gridItem: {
    paddingTop: '12px',
  },
  qualificationLabel: {
    width: '50%',
    paddingRight: '12px',
  },
}));

const formatChipLabel = (label1: string, label2: string, label3?: any, label4?: any) => {
  return label1 + ' - ' + label2 + (label3 ? ' - ' + label3 : '') + (label4 ? ', ' + label4 + 'y' : '');
};

export const UnitPreview = (props: Props) => {
  const { unitData } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const { certifications } = useSelector(selectOrderRequirementTypes);
  const [subCertificationOptions, subCetCertificationOptions] = useState<any[]>([]);

  const parseCertifications = () => {
    const certs = [];
    const subCerts = [];
    certifications?.map(rt => {
      rt.category ? certs.push(rt) : subCerts.push(rt);
    });
    subCetCertificationOptions(subCerts);
  };

  useEffect(() => {
    if (Array.isArray(certifications) && certifications.length > 0) {
      parseCertifications();
    }
  }, [certifications]);

  const getChipLabel = (sectionName: string, item: any) => {
    return sectionName === t('order.createUnit.experience.title')
      ? formatChipLabel(item.discipline, item.specialty, item.subSpecialty, item.yearsOfExperience)
      : item.abbreviation;
  };

  const getChipsBySection = (data: any[], sectionName?: string) => {
    return data?.length > 0 && sectionName?.length > 0 ? (
      <Grid id={sectionName}>
        {data?.map((item, key) => {
          return (
            <Chip
              variant="outlined"
              avatar={<Avatar variant={item.isRequired ? 'primary' : 'green'}>{item.isRequired ? 'R' : 'P'}</Avatar>}
              key={key}
              id={item.requirementTypeId}   
              label={
                <React.Fragment>
                  <div className={classes.chipBlock}>
                    <WhiteTooltip title={getChipLabel(sectionName, item)}>
                      <div className={classes.truncation}>{getChipLabel(sectionName, item)}</div>
                    </WhiteTooltip>
                    {item.documentAttachmentRequired && (
                      <DescriptionOutlinedIcon
                        style={{ fill: theme.palette.components.icons.secondary.backgroundColor, fontSize: '18px' }}
                        className={classes.chipIcon}
                      />
                    )}
                  </div>
                </React.Fragment>
              }
              className={classes.chip}
              style={sectionName !== t('order.createUnit.experience.title') ? { marginTop: '12px' } : {}}
            />
          );
        })}
      </Grid>
    ) : (
      <Grid item id={sectionName}>
        {missingField}
      </Grid>
    );
  };

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Typography variant="body1">
            <Paper className={classes.paper} variant="outlined" elevation={0}>
              <Grid direction="column" container spacing={2}>
                <Grid item xs={12}>
                  <UnitTitle>{unitData.name}</UnitTitle>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <PreviewContent variant="outlined" elevation={0}>
                          <Grid container direction="column" spacing={2}>
                            <Grid item>
                              <PreviewContentTitle className={classes.fontResponsiveHeader}>
                                {t('order.createUnit.unitDetails.title')}
                              </PreviewContentTitle>
                            </Grid>
                            <Grid item>
                              <ValueContainer
                                id={'UnitPreviewUnitDetails1'}
                                labelContent={[
                                  { label: t('order.createUnit.unitDetails.unitNameTitle'), size: 4 },
                                  { label: t('order.createUnit.unitDetails.costCenter'), size: 4 },
                                  { label: t('order.createUnit.unitDetails.floor'), size: 4 },
                                ]}
                                valueContent={[
                                  { value: unitData.name, size: 4 },
                                  { value: unitData.costCenter ? unitData.costCenter : '-', size: 4 },
                                  { value: unitData.floor ? unitData.floor : '-', size: 4 },
                                ]}
                              />
                            </Grid>
                          </Grid>
                        </PreviewContent>
                      </Grid>
                      <Grid item xs={12}>
                        <PreviewContent variant="outlined" elevation={0}>
                          <Grid container direction="column" spacing={2}>
                            <Grid item>
                              <PreviewContentTitle className={classes.fontResponsiveHeader}>
                                {t('order.createUnit.experience.title')}
                              </PreviewContentTitle>
                            </Grid>
                            {unitData.experience && unitData.experience.isNewGrad && (
                              <Grid item>
                                <InnerValueSection
                                  id={'UnitPreviewExperienceNewGrad'}
                                  contentValue={t('order.createUnit.experience.newGrad')}
                                />
                              </Grid>
                            )}
                            {unitData.experience && unitData.experience.skillSets.length > 0 && (
                              <Grid item id={'UnitPreviewExperienceItems'}>
                                {getChipsBySection(
                                  unitData.experience.skillSets,
                                  t('order.createUnit.experience.title'),
                                )}
                              </Grid>
                            )}
                          </Grid>
                        </PreviewContent>
                      </Grid>
                      <Grid item xs={12}>
                        <PreviewContent variant="outlined" elevation={0}>
                          <Grid container direction="column" spacing={2}>
                            <Grid item>
                              <PreviewContentTitle className={classes.fontResponsiveHeader}>
                                {t('order.createUnit.travelExperience.title')}
                              </PreviewContentTitle>
                            </Grid>
                            {unitData.travelExperience && (
                              <>
                                {unitData.travelExperience?.isRequired !== null ? (
                                  <InnerIconSection
                                    id={'UnitPreviewTravelExperienceIsRequired'}
                                    isRequired={unitData.travelExperience?.isRequired}
                                  />
                                ) : (
                                  <>-</>
                                )}
                                <Grid item direction="row">
                                  <InnerValueSection
                                    id={'UnitPreviewTravelExperience'}
                                    contentValue={getTravelExperience(unitData.travelExperience)}
                                  />
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </PreviewContent>
                      </Grid>
                      <Grid item xs={12}>
                        <PreviewContent variant="outlined" elevation={0}>
                          {/* <Grid item>
                              <PreviewContentTitle className={classes.fontResponsiveHeader}>
                                {t('order.createUnit.qualifications.title')}
                              </PreviewContentTitle>
                            </Grid>
                            <Grid className={classes.gridItem}>
                              <ContentTitle>{t('order.createUnit.qualifications.licenses')}</ContentTitle>
                            </Grid>
                            {unitData.qualification?.licenses?.length > 0 && (
                              <Grid id={'UnitPreviewQualificationsLicenseItems'}>
                                {getChipsBySection(unitData.qualification?.licenses, t('order.createUnit.qualifications.licenses'))}
                              </Grid>
                            )}
                            <ContentDivider style={{ width: '100%' }} />
                            <Grid className={classes.gridItem}>
                              <ContentTitle>{t('order.createUnit.qualifications.certifications')}</ContentTitle>
                            </Grid>
                            {unitData.qualification?.certifications?.length > 0 && (
                              <Grid id={'UnitPreviewQualificationsCertificationItems'}>
                                {getChipsBySection(unitData.qualification?.certifications, t('order.createUnit.qualifications.certifications'))}
                              </Grid>
                              )} */}
                          <Grid>
                            <Grid item>
                              <Grid container direction="row" justifyContent="space-between">
                                <Grid item>
                                  <PreviewContentTitle>
                                    {t('order.createUnit.qualifications.qualificationsTitle')}
                                  </PreviewContentTitle>
                                </Grid>
                              </Grid>
                            </Grid>
                            {unitData.qualification?.licenses?.length > 0 && (
                              <Grid item>
                                <ContentTitle className={classes.gridItem}>
                                  {`${t('order.createUnit.qualifications.licenses')} (${
                                    unitData.qualification.licenses?.length
                                  })`}
                                </ContentTitle>
                                <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  {unitData.qualification?.licenses?.map((license, key) => {
                                    return (
                                      <Grid
                                        item
                                        direction="column"
                                        key={key}
                                        className={classes.qualificationLabel}
                                        style={{ width: '50%' }}
                                      >
                                        <LicenseRequirementLabel
                                          isRequired={license.isRequired}
                                          isDocumentRequired={
                                            license.documentAttachmentRequired
                                              ? license.documentAttachmentRequired
                                              : false
                                          }
                                          contentValue={{
                                            section1: license.licenseAbbreviation,
                                            section2: license.isMultiState ? 'Compact' : license.stateAbbreviation,
                                            section3: license.licenseDescription,
                                            section4: license.state,
                                          }}
                                        />
                                      </Grid>
                                    );
                                  })}
                                </Grid>
                              </Grid>
                            )}
                            {unitData.qualification?.licenses?.length > 0 &&
                              unitData.qualification?.certifications?.length > 0 && (
                                <Grid item style={{ paddingTop: '12px' }}>
                                  <ContentDivider style={{ width: '100%', padding: 0, margin: 0 }} />
                                </Grid>
                              )}
                            {unitData.qualification?.certifications?.length > 0 && (
                              <Grid item>
                                <ContentTitle className={classes.gridItem}>
                                  {`${t('order.createUnit.qualifications.certifications')} (${
                                    unitData.qualification?.certifications?.length
                                  })`}
                                </ContentTitle>
                                <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  {unitData.qualification.certifications.map((certificate, key) => {
                                    const isSubCert = subCertificationOptions?.some(
                                      item => item?.requirementTypeID === certificate?.requirementTypeId,
                                    );
                                    return (
                                      <Grid
                                        item
                                        direction="column"
                                        key={key}
                                        className={classes.qualificationLabel}
                                        style={{ width: '50%' }}
                                      >
                                        <CertificationRequirementLabel
                                          isRequired={certificate?.isRequired}
                                          isDocumentRequired={
                                            certificate?.documentAttachmentRequired
                                              ? certificate?.documentAttachmentRequired
                                              : false
                                          }
                                          contentValue={{
                                            section1: certificate?.abbreviation,
                                            section2: isSubCert,
                                          }}
                                        />
                                      </Grid>
                                    );
                                  })}
                                </Grid>
                              </Grid>
                            )}
                            {unitData.qualification?.skillChecklists?.length > 0 && (
                              <Grid item>
                                <ContentDivider style={{ width: '100%' }} />
                                <Grid className={classes.gridItem}>
                                  <ContentTitle>{t('order.createUnit.qualifications.skills')}</ContentTitle>
                                </Grid>
                                <Grid id={'UnitPreviewQualificationsSkillsItems'} container direction="row" spacing={3}>
                                  {unitData.qualification?.skillChecklists?.map((skill, key) => {
                                    return (
                                      <Grid item direction="column" key={key}>
                                        <RequirementLabel
                                          isRequired={skill.isRequired}
                                          contentValue={{ section1: `${skill.text}` }}
                                        />
                                      </Grid>
                                    );
                                  })}
                                </Grid>
                              </Grid>
                            )}
                            {unitData.qualification?.emr &&
                              unitData.qualification?.emr.values &&
                              unitData.qualification?.emr.values.length > 0 && (
                                <>
                                  <ContentDivider style={{ width: '100%' }} />
                                  <Grid className={classes.gridItem}>
                                    <ContentTitle>{t('order.createUnit.qualifications.emr')}</ContentTitle>
                                  </Grid>
                                  <InnerIconSection
                                    id={'UnitPreviewQualificationEmrIsRequired'}
                                    isRequired={unitData.qualification.emr?.isRequired}
                                  />
                                  <Grid item>
                                    <InnerValueSection
                                      id={'UnitPreviewQualificationEmr'}
                                      contentValue={
                                        unitData.qualification.emr?.values
                                          ? unitData.qualification.emr.values.join(', ')
                                          : '-'
                                      }
                                    />
                                  </Grid>
                                </>
                              )}
                          </Grid>
                        </PreviewContent>
                      </Grid>
                      <Grid item xs={12}>
                        <PreviewContent variant="outlined" elevation={0}>
                          <Grid container direction="column" spacing={2}>
                            <Grid item>
                              <PreviewContentTitle className={classes.fontResponsiveHeader}>
                                {t('order.createUnit.nursePatientRatio.title')}
                              </PreviewContentTitle>
                            </Grid>
                            <Grid item>
                              <ValueContainer
                                id={'UnitPreviewQualificationNursePatientRatio'}
                                labelContent={[
                                  { label: t('order.createUnit.nursePatientRatio.nurse'), size: 2 },
                                  { label: t('order.createUnit.nursePatientRatio.patient'), size: 3 },
                                  {
                                    label: t('order.createUnit.nursePatientRatio.fyreIntegration'),
                                    size: 4,
                                  },
                                  { label: t('order.createUnit.nursePatientRatio.status'), size: 3 },
                                ]}
                                valueContent={[
                                  {
                                    value: unitData.nurseRatio ? unitData.nurseRatio.split(':')[0] + '%' : `-`,
                                    size: 2,
                                  },
                                  {
                                    value: unitData.nurseRatio ? unitData.nurseRatio.split(':')[1] + '%' : `-`,
                                    size: 3,
                                  },
                                  {
                                    value:
                                      unitData.fyreIntegration === null ? '-' : unitData.fyreIntegration ? 'Yes' : 'No',
                                    size: 4,
                                  },
                                  { value: unitData.status, size: 3 },
                                ]}
                              />
                            </Grid>
                          </Grid>
                        </PreviewContent>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid data-testid="test-job" item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <PreviewContent variant="outlined" elevation={0}>
                          <Grid container direction="column" spacing={2}>
                            <Grid item>
                              <PreviewContentTitle className={classes.fontResponsiveHeader}>
                                {t('order.createUnit.externalJobInternalNotes.title')}
                              </PreviewContentTitle>
                            </Grid>
                            <Grid item className={classes.titleWrapper}>
                              <ContentTitle>
                                {t('order.createUnit.externalJobInternalNotes.externalJobDescription')}
                              </ContentTitle>
                            </Grid>
                            <Grid item className={classes.multiLineWrapper}>
                              <Grid container>
                                <InnerValueHTMLSection
                                  id={'UnitPreviewexternalJobInternalNotesExternalJobDescription'}
                                  contentValue={unitData.externalJobDescription ? unitData.externalJobDescription : '-'}
                                />
                              </Grid>
                            </Grid>
                            <Grid item className={classes.titleWrapper}>
                              <ContentTitle>
                                {t('order.createUnit.externalJobInternalNotes.internalNotes')}
                              </ContentTitle>
                            </Grid>
                            <Grid item className={classes.multiLineWrapper}>
                              <Grid container>
                                <InnerValueHTMLSection
                                  id={'UnitPreviewexternalJobInternalNotesInternalNotes'}
                                  contentValue={unitData.internalNotes ? unitData.internalNotes : '-'}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </PreviewContent>
                      </Grid>
                      <Grid item xs={6}>
                        <PreviewContent variant="outlined" elevation={0} style={{ height: '100%' }}>
                          <Grid container direction="column" spacing={2}>
                            <Grid item>
                              <PreviewContentTitle className={classes.fontResponsiveHeader}>
                                {t('order.createUnit.virtualInterviewRating.title')}
                              </PreviewContentTitle>
                            </Grid>
                            <Grid item>
                              <InnerValueSection
                                id={'UnitPreviewexternalJobVirtualRating'}
                                contentValue={unitData.virtualRating ? unitData.virtualRating.join(', ') : '-'}
                              />
                            </Grid>
                          </Grid>
                        </PreviewContent>
                      </Grid>
                      <Grid item xs={6}>
                        <PreviewContent variant="outlined" elevation={0} style={{ height: '100%' }}>
                          <Grid container direction="column" spacing={2}>
                            <Grid item>
                              <PreviewContentTitle className={classes.fontResponsiveHeader}>
                                {t('order.createUnit.orderPriorities')}
                              </PreviewContentTitle>
                            </Grid>
                            <Grid item>
                              <InnerValueSection
                                id={'UnitPreviewOrderPriorities'}
                                contentValue={
                                  unitData.orderPriorities ? getOrderPrioritiesValue(unitData.orderPriorities) : '-'
                                }
                              />
                            </Grid>
                          </Grid>
                        </PreviewContent>
                      </Grid>
                      <Grid item xs={12}>
                        <PreviewContent variant="outlined" elevation={0}>
                          <Grid container direction="column" spacing={2}>
                            <Grid item>
                              <PreviewContentTitle className={classes.fontResponsiveHeader}>
                                {t('order.createUnit.bestBetsUnitSize.title')}
                              </PreviewContentTitle>
                            </Grid>
                            <Grid item>
                              <ValueContainer
                                id={'UnitPreviewBestBetsUnitSize'}
                                labelContent={[
                                  { label: t('order.createUnit.bestBetsUnitSize.bestBetsTitle'), size: 6 },
                                  { label: t('order.createUnit.bestBetsUnitSize.unitSize'), size: 6 },
                                ]}
                                valueContent={[
                                  { value: unitData.bestBets, size: 6 },
                                  { value: unitData.unitSize ? unitData.unitSize.toString() : '-', size: 6 },
                                ]}
                              />
                            </Grid>
                          </Grid>
                        </PreviewContent>
                      </Grid>
                      <Grid item xs={12}>
                        <PreviewContent variant="outlined" elevation={0}>
                          <Grid container direction="column" spacing={2}>
                            <Grid item>
                              <PreviewContentTitle className={classes.fontResponsiveHeader}>
                                {t('order.createUnit.references.title')}
                              </PreviewContentTitle>
                            </Grid>
                            {unitData.reference && (
                              <>
                                <Grid item xs={12}>
                                  <Grid container direction="column" spacing={2}>
                                    <InnerIconSection
                                      id={'UnitPreviewReferencesIsRequired'}
                                      isRequired={unitData.reference.isRequired}
                                      gridSize={12}
                                    />
                                    {(unitData.reference.withinMonths ||
                                      unitData.reference.referenceFromSupervisor) && (
                                      <Grid item>
                                        <InnerValueSection
                                          id={'UnitPreviewReferences'}
                                          contentValue={getReference(unitData.reference)}
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <ValueContainer
                                    id={'UnitPreviewReferencesNumberOfReferences'}
                                    labelContent={[
                                      {
                                        label: t('order.createUnit.references.numberOfReferencesRequiredTitle'),
                                        size: 6,
                                      },
                                      { label: t('order.createUnit.references.numberOfReferencesToRFOTitle'), size: 6 },
                                    ]}
                                    valueContent={[
                                      { value: unitData.reference.numberOfReferenceRequired.toString(), size: 6 },
                                      { value: unitData.reference.numberOfReferenceToRFO.toString(), size: 6 },
                                    ]}
                                  />
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </PreviewContent>
                      </Grid>
                      <Grid item xs={12}>
                        <PreviewContent variant="outlined" elevation={0}>
                          <Grid container direction="column" spacing={2}>
                            <Grid item>
                              <PreviewContentTitle className={classes.fontResponsiveHeader}>
                                {t('order.createUnit.float.title')}
                              </PreviewContentTitle>
                            </Grid>
                            {unitData.float && (
                              <>
                                <InnerIconSection
                                  id={'UnitPreviewFloatIsRequired'}
                                  isRequired={unitData.float.isRequired}
                                />
                                <Grid item>
                                  <ValueContainer
                                    id={'UnitPreviewFloat'}
                                    labelContent={[
                                      { label: t('order.createUnit.float.floatUnit'), size: 4 },
                                      { label: t('order.createUnit.float.floatLocation'), size: 4 },
                                      { label: t('order.createUnit.float.floatToMiles'), size: 4 },
                                    ]}
                                    valueContent={[
                                      { value: unitData.float.floatToUnit, size: 4 },
                                      { value: unitData.float.floatToLocation, size: 4 },
                                      { value: unitData.float.floatToMiles.toString(), size: 4 },
                                    ]}
                                  />
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </PreviewContent>
                      </Grid>
                      <Grid item xs={12}>
                        <PreviewContent variant="outlined" elevation={0}>
                          <Grid container direction="column" spacing={2}>
                            <Grid item>
                              <PreviewContentTitle className={classes.fontResponsiveHeader}>
                                {t('order.createUnit.flexibleSchedule.title')}
                              </PreviewContentTitle>
                            </Grid>
                            {unitData.flexibleSchedule && (
                              <>
                                <MediaQuery minWidth="lg">
                                  <InnerIconSection
                                    id={'UnitPreviewFlexibleScheduleWorkDuringWeekendIsRequired'}
                                    isRequired={unitData.flexibleSchedule.isRequired}
                                  />
                                  <Grid item style={{ height: '67px' }}>
                                    <Grid container spacing={2} direction="row">
                                      <InnerLabelValueSection
                                        id={'UnitPreviewFlexibleScheduleWorkDuringWeekend'}
                                        gridSize={6}
                                        contentLabel={t('order.createUnit.flexibleSchedule.workDuringWeekend')}
                                        contentValue={
                                          unitData.flexibleSchedule.workDuringWeekend
                                            ? unitData.flexibleSchedule.workDuringWeekend.toString()
                                            : '-'
                                        }
                                      />
                                      {/* <CustomDivider orientation="vertical" flexItem variant="middle" /> */}
                                      <InnerLabelValueSection
                                        gridSize={5}
                                        id={'UnitPreviewFlexibleScheduleWorkDuringHoliday'}
                                        contentLabel={t('order.createUnit.flexibleSchedule.workDuringHoliday')}
                                        contentValue={
                                          unitData.flexibleSchedule.workDuringHoliday
                                            ? unitData.flexibleSchedule.workDuringHoliday.toString()
                                            : '-'
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </MediaQuery>
                                <MediaQuery maxWidth="md">
                                  <InnerIconSection isRequired={unitData.flexibleSchedule.isRequired} />
                                  <Grid item>
                                    <Grid container spacing={2} direction="row">
                                      <InnerLabelValueSection
                                        gridSize={5}
                                        id={'UnitPreviewFlexibleScheduleWorkDuringWeekend'}
                                        contentLabel={t('order.createUnit.flexibleSchedule.workDuringWeekend')}
                                        contentValue={
                                          unitData.flexibleSchedule.workDuringWeekend
                                            ? unitData.flexibleSchedule.workDuringWeekend.toString()
                                            : '-'
                                        }
                                      />
                                      {/* <CustomDivider orientation="vertical" flexItem variant="middle" /> */}
                                      <InnerLabelValueSection
                                        gridSize={5}
                                        id={'UnitPreviewFlexibleScheduleWorkDuringHoliday'}
                                        contentLabel={t('order.createUnit.flexibleSchedule.workDuringHoliday')}
                                        contentValue={
                                          unitData.flexibleSchedule.workDuringHoliday
                                            ? unitData.flexibleSchedule.workDuringHoliday.toString()
                                            : '-'
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </MediaQuery>
                              </>
                            )}
                          </Grid>
                        </PreviewContent>
                      </Grid>
                      <Grid item xs={12}>
                        <PreviewContent variant="outlined" elevation={0}>
                          <Grid container direction="column" spacing={2}>
                            <Grid item>
                              <PreviewContentTitle className={classes.fontResponsiveHeader}>
                                {t('order.createUnit.nonStandardRequirement.title')}
                              </PreviewContentTitle>
                            </Grid>
                            {unitData.nonStandardRequirement &&
                              unitData.nonStandardRequirement.length > 0 &&
                              unitData.nonStandardRequirement.map((item, index) => (
                                <>
                                  <InnerIconSection isRequired={item.isRequired} />
                                  <Grid item>
                                    <ValueContainer
                                      id={'UnitPreviewNonStandardRequirement'}
                                      labelContent={[
                                        { label: t('order.createUnit.nonStandardRequirement.category'), size: 3 },
                                        {
                                          label: t('order.createUnit.nonStandardRequirement.nameOfNonStandardSkill'),
                                          size: 6,
                                        },
                                        {
                                          label: item.yearsOfExperience
                                            ? t('order.createUnit.nonStandardRequirement.yearsOfExperienceTitle')
                                            : '',
                                          size: 3,
                                        },
                                      ]}
                                      valueContent={[
                                        { value: item.category, size: 3 },
                                        { value: item.value, size: 6, docIcon: item.documentAttachmentRequired },
                                        {
                                          value: item.yearsOfExperience ? item.yearsOfExperience.toString() : '',
                                          size: 3,
                                        },
                                      ]}
                                    />
                                  </Grid>
                                  {unitData.nonStandardRequirement &&
                                    index !== unitData.nonStandardRequirement.length - 1 && <ContentDivider />}
                                </>
                              ))}
                          </Grid>
                        </PreviewContent>
                      </Grid>
                      <Grid item xs={12}>
                        <PreviewContent variant="outlined" elevation={0}>
                          <Grid container direction="column" spacing={2}>
                            <Grid item>
                              <PreviewContentTitle className={classes.fontResponsiveHeader}>
                                {t('order.createUnit.classicRequirement.title')}
                              </PreviewContentTitle>
                            </Grid>
                            {(unitData.requiredQualifications || unitData.preferredQualifications) && (
                              <>
                                <Grid item className={classes.titleWrapper}>
                                  <InnerIconSection isRequired={true} />
                                </Grid>
                                <Grid item>
                                  <InnerValueHTMLSection
                                    id={'UnitPreviewClassicRequirementRequiredQualifications'}
                                    contentValue={
                                      unitData.requiredQualifications ? unitData.requiredQualifications : '-'
                                    }
                                  />
                                </Grid>

                                <Grid item className={classes.titleWrapper}>
                                  <InnerIconSection isRequired={false} />
                                </Grid>
                                <Grid item>
                                  <InnerValueHTMLSection
                                    id={'UnitPreviewClassicRequirementPreferredQualifications'}
                                    contentValue={
                                      unitData.preferredQualifications ? unitData.preferredQualifications : '-'
                                    }
                                  />
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </PreviewContent>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Typography>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};
