import React from 'react';
import { BoxContainer } from '../../CustomComponents/BoxContainer';
import { useDispatch, useSelector } from 'react-redux';
import { selectFacility, selectIsFacilityByIdRequestSuccess } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { mapEmployeeRoles } from './helper';
import { useTranslation } from 'react-i18next';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { IFacilitySidePanelTabs } from '@AMIEWEB/Common/Drawer/HeaderContactDrawerContent';
import { useDecision } from '@optimizely/react-sdk';
import { ff_facility_ui_edit_internal_contacts } from 'app/constants/FeatureFlags/Facility/Keys';
import { RowSection, FacilityRolesSkeleton } from '../Utils';
import { Grid } from 'amn-ui-core';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

export const FacilityRoles = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const facilityData = useSelector(selectFacility);
  const roles = mapEmployeeRoles(facilityData.facilityResponse);
  const [showEditInternalContacts] = useDecision(ff_facility_ui_edit_internal_contacts);
  const [fixedRowHeaderWidth, setFixedRowHeaderWidth] = React.useState(0);
  const facilityLoading = useSelector(selectIsFacilityByIdRequestSuccess);

  /**
   * Method to open facility edit facility drawer on a side panel
   */
  const onEditFacilityRoles = () => {
    dispatch(globalActions.resetAllRecentHistoryDrawers());
    dispatch(
      facilityActions.setFacilityDrawerVisibility({ open: true, tabId: IFacilitySidePanelTabs.INTERNAL_CONTACTS }),
    );
    dispatch(globalActions.setDetailsPageDrawer({ open: true }));
  };

  const handleMeasureWidth = (width: number) => {
    setFixedRowHeaderWidth(oldWidth => Math.max(oldWidth, width));
  };
  
  return (
    <BoxContainer
      title={t('facility.internalContacts.title')}
      icon={<PeopleAltIcon fontSize="small" />}
      onClick={onEditFacilityRoles}
      rightIcon={showEditInternalContacts?.enabled ? <CreateOutlinedIcon /> : false}
      iconToolTip="Edit"
    >
      {facilityLoading ? (
        <FacilityRolesSkeleton />
      ) : (
        <Grid container direction="column">
          {roles.map((role, index) => (
            <RowSection
              key={index}
              header={role.name}
              data={role.data}
              handleMeasureWidth={handleMeasureWidth}
              fixedHeaderWidth={fixedRowHeaderWidth}
            />
          ))}
        </Grid>
      )}
    </BoxContainer>
  );
};
