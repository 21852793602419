import React from 'react';
import { Grid, Typography, IconButton,  Divider, Breadcrumbs } from 'amn-ui-core';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import styledComponent from 'styled-components';
import { colors } from 'styles/styleVariables';

const useStyles = makeStyles()(theme =>
  ({
    breadcrumbRoot: {
      fontSize: '16px',
    },
    breadcrumb: {
      color: theme.palette.text.primary,
      fontWeight: 500,
      '&:hover': {
        textDecoration: 'none',
      },
    },
    activeBreadcrumb: {
      color: colors.amnBlue,
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    container: {
      flexWrap: 'nowrap',
    },
  }));

export const BreadcrumbNavigation = props => {
  const { classes } = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const { backButtonURL, breadcrumbHierarchy, isOwnPageBreadcrumb } = props;
  const handleClick = item =>
    item.clickable
      ? isOwnPageBreadcrumb
        ? item.onClick?.()
        : history.push({ pathname: `/${item.returnURL}`, state: { navigateBack: true } })
      : undefined;

  const handleBackButtonClick = () => {
    history.push({ pathname: `/${backButtonURL}`, state: { navigateBack: true } });
  };

  return (
    <Grid container direction="column" classes={{ container: classes.container }}>
      <Grid container justifyContent="space-between" classes={{ container: classes.container }}>
        <Breadcrumbs className={classes.breadcrumbRoot}>
          {breadcrumbHierarchy.map((item, index) => (
            <Typography
              variant="subtitle1"
              key={index.toString()}
              className={`${classes.breadcrumb} ${item.clickable && classes.activeBreadcrumb}`}
              onClick={() => handleClick(item)}
            >
              {item.title}
            </Typography>
          ))}
        </Breadcrumbs>
        {!isOwnPageBreadcrumb && (
          <ArrowButton
            disableTouchRipple
            disableFocusRipple
            style={{ background: 'none', padding: 6 }}
            onClick={handleBackButtonClick}
            data-testid="backButton"
          >
            <ArrowBackIcon />
            <ButtonLabel> {t('candidate.jobMatch.backButtonText')}</ButtonLabel>
          </ArrowButton>
        )}
      </Grid>
      {!isOwnPageBreadcrumb && <Divider />}
    </Grid>
  );
};

const ButtonLabel = styledComponent.div`
  font-size: 19px;
  color: #52429a;
  padding-left: 5px;
`;

const ArrowButton = styledComponent(IconButton)`
  display: flex;
  font-size: 30;
`;
