import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMiscellanoues, ISearchResults } from 'app/models/GlobalSearch/GlobalSearch';
import { serviceActions } from './async-actions';
import { FilterChoice, ISearchAttributes } from './types';

export const totalCountErrorThreshold = 100000;

export const initialSearchAttributes: ISearchAttributes = {
  filterOption: FilterChoice.Candidate,
  searchString: '',
  showPreferenceFilterDropdown: true,
  archivePlacement: undefined,
  bulkOrders: {
    bulkVerifyOrdersFlag : false
  },
};

export const initialSearchResults: ISearchResults = {
  count: 0,
  items: [],
  drawerData: {
    open: false,
    data: undefined,
  },
  miscellaneous: { positionCount: 0 },
};

export const initialState = {
  searchAttributes: initialSearchAttributes,
  searchResults: initialSearchResults,
  additionalData: { orderCompactStates: [] } as IAdditionalSearchData,
}

export interface IAdditionalSearchData {
}

const globalSearchSlice = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {
    /** Search Attributes - Start */
    setSearchOptions(state, action: PayloadAction<ISearchAttributes>) {
      return { ...state, searchAttributes: { ...state.searchAttributes, ...action.payload } };
    },
    setShowPreferenceFilterDropdown(state, action: PayloadAction<boolean>) {
      return { ...state, searchAttributes: { ...state.searchAttributes, showPreferenceFilterDropdown: action.payload } }
    },
    setBulkArchivePlacements(
      state,
      action: PayloadAction<{
        placementStatusesAllowed?: string[];
        dialogOpen?: boolean;
        loading?: boolean;
        success?: boolean | null;
      }>,
    ) {
      return {
        ...state,
        searchAttributes: { 
          ...state.searchAttributes, 
          archivePlacement: {
            placementStatusesAllowed: [
              ...(action.payload.placementStatusesAllowed ?? 
                (state.searchAttributes.archivePlacement?.placementStatusesAllowed || [])
              ),
            ],
            dialogOpen: action.payload.dialogOpen ?? state.searchAttributes.archivePlacement?.dialogOpen ?? false,
            loading: action.payload.loading ?? state.searchAttributes.archivePlacement?.loading ?? false,
            success:
              action.payload.success !== undefined 
                ? action.payload.success : state.searchAttributes.archivePlacement?.success ?? null,
          }, 
        },
      };
    },
    /** Search Attributes - End */

    /** Search Reults - Start */
    setSearchResults(state, action: PayloadAction<{ count: number; items: any[]; miscellaneous: IMiscellanoues }>) {
      return { ...state, searchResults: { ...state.searchResults, ...action.payload } };
    },
    setBulkOrders(state, action: PayloadAction<{ bulkVerifyOrdersFlag : boolean }>) {
      return { ...state, searchAttributes: { ...state.searchAttributes, bulkOrders : action.payload } }
    },
    clearSearchResults(state) {
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          items: [],
        }
      }
    },
    setDrawerData(state, action: PayloadAction<{ open: boolean; data: any }>) {
      return {
        ...state,
        searchResults: { 
          ...state.searchResults, 
          drawerData: {
            open: action.payload.open,
            data: action.payload.data,
          }
        },
      };
    },
    setAdditionalSearchData(state, action: PayloadAction<{ key: keyof IAdditionalSearchData; data: any }>) {
      return { 
        ...state, additionalData: { ...state.additionalData, [action.payload.key]: action.payload.data }
      };
    },
    /** Search Reults - End */
    reset(state) {
      return { ...initialState, additionalData: state.additionalData };
    },
  },
});

export const { name: globalSearchSliceKey, reducer: globalSearchReducer } = globalSearchSlice;

const { actions } = globalSearchSlice;
export const globalSearchActions = {
  ...actions,
  ...serviceActions,
};
