import { ChannelType } from 'app/components/Notification/Constants';
import { missingField } from 'app/constants';
import { RecruiterPlacement } from 'app/models/WorkDesk/RecruiterPlacementDetails';
import { getStatusAge } from 'utils/common/statusAge';
import { GetMomentOfDate } from 'utils/dates/moment';
import { Concatenate } from 'utils/string/string';
import Actions from '../../../../../app/assets/images/WorkDesk/Actions.png';
import Call from '../../../../../app/assets/images/WorkDesk/Call.png';
import email from '../../../../../app/assets/images/WorkDesk/email.png';
import { notSent } from 'app/ComponentLibrary/Filter/helper';

export enum recPlacementsGridKeys {
  placementRecruiter = 'placementRecruiter',
  statusAbbr = 'statusAbbr',
  candidate = 'candidate',
  skillSet = 'skillSet',
  endDate = 'endDate',
  startDate = 'startDate',
  availabilityStartDate = 'availabilityStartDate',
  notContactedSinceDate = 'notContactedSinceDate',
  lastApplicationDate = 'lastApplicationDate',
  availableToStartDate = 'availableToStartDate',
  callBackDate = 'callBackDate',
  facilityName = 'facilityName',
  facilityCity = 'facilityCity',
  facilityState = 'facilityState',
  lastCueDate = 'lastCueDate',
  lastCueNote = 'lastCueNote',
  cuedFrom = 'cuedFrom',
  cueNoteAttributes = 'cueNoteAttributes',
  arbitrationStatus = 'arbitrationStatus',
  arbitrationSentDate = 'arbitrationSentDate',
  arbitrationSignedDate = 'arbitrationSignedDate',
}

export const recruiterPlacementFlattenData = (PlacementInfo: RecruiterPlacement[]) =>
  PlacementInfo.map((info, i) => {
    const availabilityDateMoment = GetMomentOfDate(info.availabilityStartDate);
    const startDateMoment = GetMomentOfDate(info.startDate);
    const endDateMoment = GetMomentOfDate(info.endDate);
    const lastContacted = GetMomentOfDate(info.candidate.lastContactedOnDate);
    const statusChanged = GetMomentOfDate(info.statusChangeDate);
    const statusAgeDetails =
      info.activityType === 'Offer' ? getStatusAge(info.initialOfferDate) : getStatusAge(info.statusChangeDate);
    const accountManagerAttributes = [
      { key: Call, value: info.accountManager.phoneNumber || missingField },
      { key: email, value: info.accountManager.email || missingField },
    ];
    const clinicalManagerAttributes = [
      { key: Call, value: info.clinicalManager.phoneNumber || missingField },
      { key: email, value: info.clinicalManager.email || missingField },
    ];
    const analystAttributes = [
      { key: Call, value: info.credentialingAnalyst.phoneNumber || missingField },
      { key: email, value: info.credentialingAnalyst.email || missingField },
    ];
    const cueDateMoment = info?.lastCueNote ? GetMomentOfDate(info?.lastCueNote?.cueDate) : null;
    const cueNoteAttributes = {
      date: info?.lastCueNote?.cueDate,
      cueNote: info?.lastCueNote?.cueNote,
      cuedFrom: info?.lastCueNote
        ? `${info?.lastCueNote?.cuedFromFirstName} ${info?.lastCueNote?.cuedFromLastName}`
        : missingField,
      cuedTo: info?.lastCueNote?.cuedToId,
    };
    return {
      id: `${info.placementId}-${i}`,
      indexCounter: i,
      previewDrawerViewerId: info.candidate.candidateId,
      previewDrawerViewerType: 1,
      placementId: info.placementId,
      candidateId: info.candidate.candidateId,
      brandId: info.brandId || missingField,
      brand: info.brand,
      [recPlacementsGridKeys.candidate]: Concatenate([info.candidate.first, info.candidate.last], ' ') || missingField,
      activityType: info.activityType,
      status: info.status || missingField,
      [recPlacementsGridKeys.statusAbbr]: info.statusAbbr,
      orderId: info.orderId,
      [recPlacementsGridKeys.skillSet]:
        Concatenate([info.skillset.disciplineAbbr, info.skillset.specialtyAbbr], '-') || missingField,
      [recPlacementsGridKeys.availabilityStartDate]: availabilityDateMoment.isValid()
        ? availabilityDateMoment.format('MM/DD/YYYY')
        : missingField,
      [recPlacementsGridKeys.startDate]: startDateMoment.isValid()
        ? startDateMoment.format('MM/DD/YYYY')
        : missingField,
      [recPlacementsGridKeys.endDate]: endDateMoment.isValid() ? endDateMoment.format('MM/DD/YYYY') : missingField,
      facilityId: info.facility.facilityId,
      [recPlacementsGridKeys.facilityName]: info.facility.name || missingField,
      [recPlacementsGridKeys.facilityCity]: info.facility.city || missingField,
      [recPlacementsGridKeys.facilityState]: info.facility.state || missingField,
      lastContactedOn: lastContacted.isValid() ? lastContacted.format('MM/DD/YYYY') : missingField,
      extensionStatus: info.extensionStatus || missingField,
      accountManager: Concatenate([info.accountManager.firstName, info.accountManager.lastName], ' ') || missingField,
      accountManagerAttributes,
      credentialingAnalyst: Concatenate([info.credentialingAnalyst.firstName, info.credentialingAnalyst.lastName], ' '),
      analystAttributes,
      credentialRating: missingField,
      clinicalManager:
        Concatenate([info.clinicalManager.firstName, info.clinicalManager.lastName], ' ') || missingField,
      clinicalManagerAttributes,
      statusChangedDate: statusChanged.isValid() ? statusChanged.format('MM/DD/YYYY') : missingField,
      statusAge: statusAgeDetails ? statusAgeDetails.message : missingField,
      statusAgeValue: statusAgeDetails ? statusAgeDetails.totalMinutes : Number.MIN_VALUE,
      candidatePhone: info.candidate.phoneNumber || missingField,
      candidatePhoneAttributes: ChannelType.voice,
      candidateEmail: info.candidate.email || missingField,
      candidateEmailAttributes: ChannelType.email,
      [recPlacementsGridKeys.notContactedSinceDate]: info.candidate.notContactedSinceDate,
      [recPlacementsGridKeys.callBackDate]: info.candidate.callBackDate,
      [recPlacementsGridKeys.lastApplicationDate]: info.candidate.lastApplicationDate,
      [recPlacementsGridKeys.availableToStartDate]: info.candidate.availableToStartDate,
      actions: Actions,
      [recPlacementsGridKeys.placementRecruiter]:
        Concatenate([info.recruiter.firstName, info.recruiter.lastName], ' ') || missingField,
      placementRecruiterId: info.recruiter.id,
      lastCueDate: cueDateMoment ? cueDateMoment.format('MM/DD/YYYY') : missingField,
      lastCueNote: info?.lastCueNote ? cueNoteAttributes?.cueNote : missingField,
      cuedFrom: info?.lastCueNote ? cueNoteAttributes?.cuedFrom : missingField,
      cuedTo: info?.lastCueNote ? cueNoteAttributes?.cuedTo : missingField,
      cueNoteAttributes,
      [recPlacementsGridKeys.arbitrationStatus]: info?.arbitrationStatus
        ? info?.arbitrationStatus === notSent
          ? missingField
          : info?.arbitrationStatus
        : missingField,
      [recPlacementsGridKeys.arbitrationSentDate]: info?.arbitrationSentDate
        ? GetMomentOfDate(info?.arbitrationSentDate)?.format('L hh:mm A [PST]')
        : missingField,
      [recPlacementsGridKeys.arbitrationSignedDate]: info?.arbitrationSignedDate
        ? GetMomentOfDate(info?.arbitrationSignedDate)?.format('L hh:mm A [PST]')
        : missingField,
    };
  });

export const isOneRecruiterSelected = (appliedFilterValues: any) => {
  return (
    !appliedFilterValues ||
    (appliedFilterValues['placementRecruiter'] && appliedFilterValues['placementRecruiter']?.length > 0)
  );
};
