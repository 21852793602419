import { Controller, useFormContext } from 'react-hook-form';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { validDecimal, validInteger } from 'app/helpers/numberHelper';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { makeStyles } from 'tss-react/mui';
import { orderDataActions } from '@AMIEWEB/Order/Store/Order.redux';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../helper';

const useStyles = makeStyles()((theme) => ({
  textFiled: {
    '& .MuiFormLabel-root-MuiInputLabel-root, .MuiFormLabel-root.Mui-disabled': {
      color: theme.palette.system.black,
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: theme.palette.system.lightGrayFaded,
    },
    '& .MuiFilledInput-input.Mui-disabled': {
      WebkitTextFillColor: theme.palette.system.black,
    },
    '& .MuiIconButton-root': { left: '12% !important' },
    '& .MuiIconButton-root:hover': {
      color: theme.palette.system.main,
      background: 'none',
      backgroundColor: 'transparent',
    },
  },
  notes: {
    marginBottom: '8px',
    '& .MuiInputBase-root': {
      height: '130px',
      display: 'inline-flex',
      flexDirection: 'column',
      paddingRight: 0,
    },
    '& .MuiFormLabel-root-MuiInputLabel-root, .MuiFormLabel-root.Mui-disabled': {
      color: theme.palette.system.black,
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: theme.palette.system.lightGrayFaded,
    },
    '& .MuiFilledInput-input.Mui-disabled': {
      WebkitTextFillColor: theme.palette.system.black,
    },
    '& .MuiFormHelperText-root': {
      textAlign: 'right',
      marginRight: 0,
    },
  },
  statusUpdatedDate: {
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: theme.palette.framework.system.charcoal,
      color: theme.palette.framework.system.charcoal,
    },
    '& .MuiFormLabel-root-MuiInputLabel-root, .MuiFormLabel-root.Mui-disabled': {
      color: theme.palette.framework.system.charcoal,
    },
    width: '96%',
  },
}));

export const OrderBonusTextField = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { control, setValue, watch } = useFormContext();
  const [characterCount, setCharacterCount] = React.useState<number>(0);
  const {
    name,
    disableControls,
    maxLength,
    numberField,
    decimalField,
    notesField,
    notesValue,
    characterLimit,
    statusUpdateField,
    calculationType,
    bonusBillAmountValue,
    orderId,
    rowData,
  } = props;
  const watchFieldValue = watch(name);
  const handleAmountCalculation = () => {
    if (bonusBillAmountValue && calculationType)
      dispatch(
        orderDataActions.getOrderBonusCalculatedAmount({
          orderId: orderId,
          type: calculationType,
          amount: parseInt(bonusBillAmountValue),
        }),
      );
  };

  useEffect(() => {
    setCharacterCount(notesValue?.length ?? 0);
  }, [notesValue]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ ref, onChange }) => (
        <TextField
          id={name}
          label={t(`order.orderBonusTab.formFields.${name}`)}
          placeholder={t(`order.orderBonusTab.placeHolder.${name}`)}
          variant={statusUpdateField ? 'outlined' : 'filled'}
          fullWidth
          color="secondary"
          autoComplete="off"
          multiline={notesField ? true : false}
          maxRows={notesField ? 5 : null}
          className={notesField ? classes.notes : statusUpdateField ? classes.statusUpdatedDate : classes.textFiled}
          value={
            statusUpdateField
              ? rowData
                ? formatDate(rowData?.statusChangedDate)
                : formatDate(new Date())
              : watchFieldValue ?? ''
          }
          onBlur={() =>
            decimalField &&
            watchFieldValue?.length > 0 &&
            watchFieldValue?.indexOf('.') === -1 &&
            setValue(name, parseFloat(watchFieldValue).toFixed(2))
          }
          onChange={e => {
            if (decimalField && validDecimal(e.target.value, { numberLength: 6, decimalLength: 4 })) {
              onChange(e.target.value);
            } else if (numberField && validInteger(e.target.value, { numberLength: 9 })) {
              onChange(e.target.value);
            } else if (!decimalField && !numberField) {
              onChange(e.target.value);
            }
          }}
          inputProps={{ maxLength: maxLength ?? null }}
          InputProps={{
            startAdornment:
              decimalField && watchFieldValue?.length > 0 ? (
                <InputAdornment position="start">{t('currency.dollar')}</InputAdornment>
              ) : null,
            endAdornment:
              decimalField && calculationType ? (
                <CustomTooltip
                  standardMargin
                  tooltipContent={
                    bonusBillAmountValue?.length > 0
                      ? ''
                      : t('order.orderBonusTab.formFields.bonusAmountDisableIconMessage')
                  }
                >
                  <IconButton
                    edge="end"
                    size="large"
                    disableFocusRipple
                    disableTouchRipple
                    onClick={() => {
                      handleAmountCalculation();
                    }}
                    disabled={disableControls ? true : bonusBillAmountValue?.length > 0 ? false : true}
                  >
                    <RotateLeftIcon />
                  </IconButton>
                </CustomTooltip>
              ) : statusUpdateField ? (
                <InputAdornment position="end">
                  <IconButton style={{ cursor: 'not-allowed' }} edge="end" size="large" disableRipple={true}>
                    <CalendarTodayIcon />
                  </IconButton>
                </InputAdornment>
              ) : null,
          }}
          disabled={statusUpdateField ? true : disableControls}
          helperText={notesField ? `${characterCount} / ${characterLimit}` : ''}
        />
      )}
    />
  );
};
