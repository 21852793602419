import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JobInterestData, SkillSet } from 'app/models/Candidate/CandidateJobInterest';
import { ILocCoordinates } from 'app/models/Candidate/CandidateJobMatch';
import { TFunction } from 'i18next';

export interface IJobInterest {
  data: JobInterestData[];
  count: number;
  filteredCount: number | null;
  flattenedData: any[];
  jobLocations: ILocCoordinates[];
  filterSourceData: { states: string[]; skillSets: SkillSet[] };
  serviceFailure: boolean;
  drawerData?: {
    open: boolean;
    data: any;
  };
}

export const initialState: IJobInterest = {
  data: [],
  count: 0,
  filteredCount: null,
  flattenedData: [],
  jobLocations: [],
  filterSourceData: { states: [], skillSets: [] },
  serviceFailure: false,
  drawerData: {
    open: false,
    data: undefined,
  },
};

const getJobInterestAction = createAction<{
  travelerId: number;
  brandId: number;
  limit: number;
  offset: number;
  filterEnable: boolean;
  orderStatus?: string;
  fromDate?: string;
  toDate?: string;
  states?: string[];
  skillSets?: SkillSet[];
  t: TFunction;
}>('GET_JOB_InTERESTS');

const getJobInterestCountAction = createAction<number>('GET_JOB_InTERESTS_COUNT');

const jobInterestSlice = createSlice({
  name: 'jobInterest',
  initialState,
  reducers: {
    setJobInterests(state, action: PayloadAction<JobInterestData[]>) {
      state.data = action.payload;
    },
    setCount(state, action: PayloadAction<number>) {
      state.count = action.payload;
    },
    setFilteredCount(state, action: PayloadAction<number>) {
      state.filteredCount = action.payload;
    },
    setGridData(state, action: PayloadAction<any[]>) {
      state.flattenedData = action.payload;
    },
    setJobLocations(state, action: PayloadAction<ILocCoordinates[]>) {
      state.jobLocations = action.payload;
    },
    setFilterSourceData(state, action: PayloadAction<{ states: string[]; skillSets: SkillSet[] }>) {
      state.filterSourceData = action.payload;
    },
    setServiceFailure(state, action: PayloadAction<boolean>) {
      state.serviceFailure = action.payload;
    },
    setDrawerData(state, action: PayloadAction<{ open: boolean; data: any }>) {
      return {
        ...state,
        drawerData: {
          open: action.payload.open,
          data: action.payload.data,
        },
      };
    },
    setDrawerOpen(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        drawerData: {
          data: state.drawerData?.data,
          open: false,
        },
      };
    },
  },
});

const { actions } = jobInterestSlice;
export const { reducer: jobInterestReducer, name: jobInterestSliceKey } = jobInterestSlice;
export const jobInterestActions = { ...actions, getJobInterestAction, getJobInterestCountAction };
