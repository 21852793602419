import React from 'react';
import { Box } from 'amn-ui-core';
import OffContract from 'app/assets/images/Order/OffContract.svg';
import OffContractFilled from 'app/assets/images/Order/OffContractFilled.svg';

interface PropType {
  iconStyle?: any;
  hover?: boolean;
}
export const OffContractIcon = ({ iconStyle, hover }: PropType) => {
  return (
    <Box>
      <img
        style={iconStyle ? iconStyle : {height: '24px', width: '24px' }}
        alt="off-contract-icon"
        src={hover ? OffContractFilled : OffContract}
      />
    </Box>
  );
};
