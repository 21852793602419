import { Grid } from 'amn-ui-core';
import { missingField } from 'app/constants';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

function GetHearsayCertificateTooltip({ certificate }) {
  let current = new Date();
  let currentDate = current.getTime();
  let date = new Date(certificate.expirationDate);
  let expirationDate = date.getTime();
  const { t } = useTranslation();
  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <span style={{ fontWeight: 'bold' }}>{t('candidate.qualificationsCard.issuedBy')}</span>
          {certificate.issuedBy ? certificate.issuedBy : missingField}
        </Grid>
        <Grid item>
          <span style={{ fontWeight: 'bold' }}>
            {certificate.expirationDate && expirationDate < currentDate
              ? t('candidate.qualificationsCard.expiredOn')
              : t('candidate.qualificationsCard.expiresOn')}
          </span>
          {certificate.expirationDate ? moment(certificate.expirationDate).format('MM/DD/YYYY') : missingField}
        </Grid>
      </Grid>
    </>
  );
}

export default GetHearsayCertificateTooltip;
