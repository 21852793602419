import { Editor } from '@tinymce/tinymce-react';
import { Box } from 'amn-ui-core';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const API_KEY = globalThis?.app?.env?.REACT_APP_TINYMCE_API_KEY;

const useStyles = makeStyles()(theme => ({
  editorOuter: {
    height: '40vh',
    '& .tox-tinymce': {
      border: 'none',
      borderRadius: 0,
    },
    p: {
      color: theme.palette.system.darkGrey,
      fontSize: 14,
    },
    '& div.tox-toolbar__group:last-child': {
      marginLeft: 'auto',
      borderLeft: 'none',
    },
    '& .tox .tox-tbtn--select': {
      width: '120px !important',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${theme.palette.system.fadedGrey} !important`,
      borderRadius: '0px !important',
      pointerEvents: 'none',
    },
    '& .tox .tox-tbtn__select-label': {
      color: theme.palette.system.dimGrey,
    },
    '& .tox .tox-sidebar-wrap': {
      maxHeight: '34vh'
    },
    '@media (max-width: 1242px)': {
      height: '36vh',
      '& .tox .tox-sidebar-wrap': {
        maxHeight: '30vh'
      },
    }
  },
  containerDisable: {
    pointerEvents: 'none',
    '& .tox .tox-tbtn svg': {
      fill: `${theme.palette.system.fadedGrey} !important`,
    },
    '& .tox .tox-tbtn--select': {
      opacity: 0.5,
    },
    '& .tox .tox-edit-area': {
      opacity: 0.5,
    },
  },
}));

export const NoteToFacilityEditor = ({ value, onChange, disabled }) => {
  const { classes } = useStyles();
  return (
    <Box className={`${classes.editorOuter} ${disabled ? classes.containerDisable : ''}`}>
      <Editor
        apiKey={API_KEY}
        onEditorChange={(newValue, editor) => {
          onChange(newValue);
        }}
        value={value}
        init={{
          menubar: false,
          statusbar: false,
          branding: false,
          paste_block_drop: false,
          paste_data_images: false,
          elementpath: false,
          block_formats: 'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3',
          forced_root_block: '',
          force_br_newlines: false,
          force_p_newlines: false,
          inline_boundaries: false,
          setup: function (editor) {
            editor.ui.registry.addMenuButton('insert', {
              text: 'Insert',
              fetch: function (callback) {
                // callback function to populate dropdown items
              },
            });
          },
          toolbar_mode: 'wrap',
          toolbar: 'bold italic underline strikethrough | alignjustify outdent indent | insert',
          plugins: 'autoresize',
          placeholder: 'Add in your comments to appear on the cover page...',
          content_style:
            'body { overflow: auto !important ; overflow-x:hidden !important;} html {  ::-webkit-scrollbar { opacity: 0; background: transparent; width: 14px; } ::-webkit-scrollbar-button { display: none; } ::-webkit-scrollbar-thumb { border-radius: 9999px; border: 3px solid rgba(0,0,0,0.0); background-color: #C7C7C7; background-clip: padding-box; } ::-webkit-scrollbar-track: { background: transparent; }}',
        }}
      />
    </Box>
  );
};
