import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { createContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CreateOrderPreferences } from './CreateOrderPreferences';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import {
  createOrderPreferenceReducer,
  createOrderPreferenceSliceKey,
} from 'store/redux-store/create-order-preferences/slice';
import { orderCreationNewPreferenceSaga } from 'store/redux-store/create-order-preferences/saga';
import {
  notificationDataKey,
  notificationDataReducer,
} from 'store/redux-store/notification/notification.redux';
import { notificationSaga } from 'store/redux-store/notification/notification.saga';
import { makeStyles } from 'tss-react/mui';
import { getFormattedSubmissionEmail, getFormattedSubmissionPacket } from '@AMIEWEB/Unit/UnitDetails/UnitTabs/Preferences/helper/unitHelper';

const useStyles = makeStyles()(theme => ({
  container: {
    marginTop: '8px',
  },
}));

export const AddOnContext = createContext<{ facilityId: number }>({ facilityId: 0 });
export const generatePayload = data => {
  const payload = {
    rfoToSent: data?.rfoToSent,
    sendPacket: data?.sendPacket?.saveAndSend,
    savePacket: data?.sendPacket?.save || data.sendPacket?.saveAndSend,
    submissionEmailSetup: data?.sendPacket?.saveAndSend ? getFormattedSubmissionEmail(data) : null,
    submissionPacketSetup:
      data?.sendPacket?.save || data?.sendPacket?.saveAndSend ? getFormattedSubmissionPacket(data) : null,
    autoRFO: data?.wfcToRfo,
    candidateTasks: data?.candidateTasks,
    updatedBy: data?.updatedBy,
    orderId: data?.orderId
  };
  return payload;
};


export const PreferencesWrapper = (props) => {
  useInjectReducer({ key: createOrderPreferenceSliceKey, reducer: createOrderPreferenceReducer });
  useInjectSaga({ key: createOrderPreferenceSliceKey, saga: orderCreationNewPreferenceSaga });
  useInjectReducer({ key: notificationDataKey, reducer: notificationDataReducer });
  useInjectSaga({ key: notificationDataKey, saga: notificationSaga });

  const params = useParams<{ facilityId: string }>();
  const { classes } = useStyles();


  return (
    <AddOnContext.Provider value={{ facilityId: parseInt(params.facilityId) }}>
      <LayoutGrid
        direction="column"
        spacing={2}
        data-testid="create-order-preference-layout"
        className={classes.container}
      >
        <LayoutGridItem>
          <CreateOrderPreferences submissionEmailSetup={props.submissionEmailSetup} submissionPacketSetup={props.submissionPacketSetup} />
        </LayoutGridItem>
      </LayoutGrid>
    </AddOnContext.Provider>
  );
};
