import { missingField } from 'app/constants';
import { convertToFormat } from 'app/helpers/dateHelper';
import React from 'react';
import styled from 'styled-components';

export const DateItem = ({ startDate, endDate }) => {
  const dateRenderer = React.useMemo(
    () => (
      <RowDataDiv>
        {!startDate || !endDate
          ? missingField
          : `${convertToFormat(startDate, 'MM/DD/yyyy')} - ${convertToFormat(endDate, 'MM/DD/yyyy')}`}
      </RowDataDiv>
    ),
    [startDate, endDate],
  );

  return <div id="placement-assignment-details-dates">{dateRenderer}</div>;
};

const RowDataDiv = styled.div`
  font-size: 14px;
  white-space: pre-wrap;
`;
