import { Button, Grid, Typography } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { ActionModal } from '../customComponents';
import { CustomTooltip } from '@AMIEWEB/Common';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';

const useStyles = makeStyles()(theme => ({
  container: {
    padding: '6px 12px',
    backgroundColor: theme.palette.system.smokeWhite,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  btnContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'end',
  },
  noTemplateSelected: {
    fontSize: '14px',
    fontWeight: 400,
    fontHeight: '19px',
    letterSpacing: '0px',
    color: theme.palette.system.lightGrey,
    opacity: 1,
    padding: '6px 0',
  },
  secondaryBtn: {
    backgroundColor: theme.palette.system.buttonGray,
    ':hover': {
      backgroundColor: theme.palette.system.buttonDarkGray,
    },
  },
}));

export const TemplateTitle: React.FC<{
  isTemplateSelected: boolean;
  isDirty: boolean;
  templateTitle: string | null;
  onCancel: (callbackFn) => void;
  onClose: () => void;
  onPublish: (templateTitle) => void;
  forceModalCloseFlag?: boolean;
  isPublishing?: boolean;
  userIsReadOnly: boolean;
}> = ({
  isTemplateSelected,
  isDirty,
  templateTitle,
  onCancel = undefined,
  onClose = undefined,
  onPublish = undefined,
  forceModalCloseFlag,
  isPublishing,
  userIsReadOnly,
}) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();

  // @ts-ignore
  const [modalProps, setModalProps] = React.useState<IActionModalProps>({ open: false });

  // @ts-ignore
  const closeModal = React.useCallback(() => setModalProps({ open: false }), []);

  const {
    userInfo: {
      externalSystemDetails: { contractTemplate: userCanPublish },
    },
  } = useSelector(selectUser);

  const titleDisplay = React.useMemo(() => {
    return !isTemplateSelected ? (
      <Typography className={classes.noTemplateSelected}>{t('templateEditor.noSelection')}</Typography>
    ) : (
      <div>{templateTitle}</div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTemplateSelected, templateTitle]);

  React.useEffect(() => {
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceModalCloseFlag]);

  const btnRender = React.useMemo(() => {
    const disablePublish = userIsReadOnly || !userCanPublish ? true : !isDirty;
    const btns: {
      children: string;
      disabled: boolean;
      onClick: () => void;
      startIcon?: JSX.Element;
      classes?: any;
      color?: 'secondary';
      tooltip?: string;
    }[] = [
      {
        children: t('templateEditor.publish'),
        /** Enabled for either a new template or a draft, as well as if dirty otherwise: Disabled if user is readonly  */
        disabled: disablePublish,
        onClick: () => {
          setModalProps({
            open: true,
            title: t('templateEditor.modal.title.publishTemplate'),
            message: t('templateEditor.modal.message.publishTemplate'),
            primaryBtns: [
              {
                text: t('templateEditor.publish'),
                onClick: () => {
                  onPublish(templateTitle);
                },
              },
            ],
            secondaryBtns: [
              {
                text: t('cancelModal.confirmation.cancel'),
                onClick: closeModal,
              },
            ],
          });
        },
        tooltip: disablePublish
          ? !userCanPublish
            ? t('templateEditor.disableToolTip.accessRestriction')
            : undefined
          : undefined,
      },
    ];
    btns.unshift(
      isDirty
        ? {
            children: t('templateEditor.cancel'),
            disabled: !isTemplateSelected,
            classes: { containedSecondary: classes.secondaryBtn },
            color: 'secondary',
            onClick: () =>
              setModalProps({
                open: true,
                title: t('cancelModal.undoConfirmation'),
                message: t('cancelModal.publishConfirmation'),
                primaryBtns: [
                  {
                    text: t('cancelModal.confirmation.yes'),
                    onClick: () => {
                      onCancel(closeModal);
                    },
                  },
                ],
                secondaryBtns: [
                  {
                    text: t('cancelModal.confirmation.cancel'),
                    onClick: closeModal,
                  },
                ],
              }),
          }
        : {
            children: t('templateEditor.close'),
            disabled: !isTemplateSelected,
            classes: { containedSecondary: classes.secondaryBtn },
            color: 'secondary',
            onClick: onClose,
          },
    );
    return btns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    closeModal,
    isDirty,
    isTemplateSelected,
    onCancel,
    onClose,
    onPublish,
    templateTitle,
    userCanPublish,
    userIsReadOnly,
  ]);

  return (
    <Grid container direction="row" justifyContent="space-between" classes={{ container: classes.container }}>
      <Grid item classes={{ root: cx({ [classes.titleContainer]: isTemplateSelected }) }}>
        {titleDisplay}
      </Grid>
      <Grid item classes={{ root: classes.btnContainer }}>
        <Grid container direction="row" gap={2}>
          {btnRender.map((btn, index) => (
            <CustomTooltip key={index.toString()} disabled={!btn.tooltip} tooltipContent={btn.tooltip} standardMargin>
              <Button variant="contained" size="small" type="button" {...btn} />
            </CustomTooltip>
          ))}
        </Grid>
      </Grid>
      <ActionModal {...modalProps} loading={isPublishing} />
    </Grid>
  );
};
