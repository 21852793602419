import { Grid, TextField } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { ContentTitle } from 'app/components/Order/OrderCreationForm/DefineUnit/UnitPreview/UnitPreviewDesign';
import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MaxLines } from 'app/components/Common/Label/MaxLines';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';

const useStyles = makeStyles()({
  freeTextField: {
    '&:hover': {
      '& [data-id="edit-icon"]': {
        opacity: 1,
        transition: 'opacity 0.25s',
      },
    },
  },
  freeTextEdit: {
    opacity: 0,
    transition: 'opacity 0.25s',
  },
  editIcon: {
    height: '20px',
    width: '20px',
    cursor: 'pointer',
    color: '#8E9195',
  },
  dialog: {
    maxWidth: '700px',
  },
});

export const OrderDetailsFreeTextField = ({
  itemKey,
  text,
  title,
  disableEdit,
  onUpdate,
  characterLimit,
  id,
}: {
  itemKey: string;
  text: string;
  title: string;
  disableEdit?: boolean;
  characterLimit?: number;
  onUpdate?: (key, value) => void;
  id?: string;
}) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState<string>(text);
  const [openModel, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    setValue(text);

    return () => {
      setValue(text);
    };
  }, [text]);

  const editValue = event => {
    if (characterLimit && (event?.target?.value?.length || 0) <= characterLimit) setValue(event.target.value);
  };

  const onEditClick = () => {
    setOpenModal(true);
  };

  const onCancel = () => {
    setOpenModal(false);
    setValue(text);
  };

  const _onUpdate = useCallback(() => {
    const newText = value?.replace(/(\r\n|\n|\r)/gm, '\r\n') || '';
    onUpdate?.(itemKey, newText);
    setOpenModal(false);
  }, [itemKey, onUpdate, value]);

  return (
    <React.Fragment>
      <Grid container item className={classes.freeTextField}>
        <Grid item style={{ flexGrow: 1 }}>
          <ContentTitle>{title}</ContentTitle>
        </Grid>
        {!disableEdit && (
          <Grid item className={classes.freeTextEdit} data-id="edit-icon">
            <EditIcon className={classes.editIcon} id={`${id}_EditTrigger`} onClick={onEditClick} />
          </Grid>
        )}
        <Grid item xs={12} id={id}>
          <MaxLines text={text} maxLines={8} minLines={4} />
        </Grid>
      </Grid>
      <GenericDialog
            open={openModel}
            disableEscapeKeyDown
            maxWidth="md"
            fullWidth
            dialogTitleProps={{
              text: t('order.orderDetails.freeText.dialogTitle', { title: title }),
            }}
            dialogActions={[
              {
                text: t('order.orderDetails.freeText.dialogCancelButton'),
                variant: 'text',
                color: 'tertiary',
                onClick: onCancel,
              },
              {
                text: t('order.orderDetails.freeText.dialogUpdateButton'),
                variant: 'contained',
                color: 'primary',
                onClick: _onUpdate,
              },
            ]}
          >
            <TextField
                variant="outlined"
                size="small"
                multiline
                rows={2}
                maxRows={8}
                style={{ width: '100%' }}
                onChange={editValue}
                placeholder={title}
                value={value}
                className={'outlined-textfield'}
                InputProps={{
                  classes: {
                    input: 'outlined-textfield-input',
                  },
                }}
              />
         </GenericDialog>
    </React.Fragment>
  );
};
