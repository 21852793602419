import { takeLatest } from 'redux-saga/effects';
import { getAutoTaskEmployeeRoles, getAutoTaskGridData, getAutoTaskManagementStatusLookUp } from './request.saga';
import { placementRequestsAction } from './slice';
import { handleApplyFilters, handleAutoTaskConfirmDialogAction, handleAutoTaskStatusChange, handleRefreshGrid, handleSaveOnPageChange, initiateAutoTaskManagementPage, setAssignedUserRoles, setSelectedTaskIdAndRow } from './business.saga';
import { updateAutoTaskManagement } from './update.saga';

export function* placementRequestsSaga() {
  yield takeLatest(placementRequestsAction.getAutoTaskGridData, getAutoTaskGridData);
  yield takeLatest(placementRequestsAction.getAutoTaskEmployeeRoles, getAutoTaskEmployeeRoles);
  yield takeLatest(placementRequestsAction.updateAutoTaskManagement, updateAutoTaskManagement);
  yield takeLatest(placementRequestsAction.getAutoTaskManagementStatusLookUp, getAutoTaskManagementStatusLookUp);
  //*Business Actions - START
  yield takeLatest(placementRequestsAction.business_setSelectedTaskIdAndRow, setSelectedTaskIdAndRow);
  yield takeLatest(placementRequestsAction.business_setAssignedUserRoles, setAssignedUserRoles);
  yield takeLatest(
    placementRequestsAction.business_handleAutoTaskConfirmDialogAction,
    handleAutoTaskConfirmDialogAction,
  );
  yield takeLatest(
    placementRequestsAction.business_handleRefreshGrid,
    handleRefreshGrid,
  );
  yield takeLatest(
    placementRequestsAction.business_handleApplyFilters,
    handleApplyFilters,
  );
  yield takeLatest(
    placementRequestsAction.business_handleAutoTaskStatusChange,
    handleAutoTaskStatusChange,
  );
  yield takeLatest(
    placementRequestsAction.business_initiateAutoTaskManagementPage,
    initiateAutoTaskManagementPage,
  );
  yield takeLatest(
    placementRequestsAction.business_handleSaveOnPageChange,
    handleSaveOnPageChange,
  );
  //*Business Actions - END
}
