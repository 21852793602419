import CandidateIcon from 'app/assets/images/NavigationStick/Candidates-Icon.svg';
import CredentialsIcon from 'app/assets/images/NavigationStick/Credentials-Icon.svg';
import FacilityIcon from 'app/assets/images/NavigationStick/Facilities-Icon.svg';
import HomeIcon from 'app/assets/images/NavigationStick/HomeIcon.svg';
import OrdersIcon from 'app/assets/images/NavigationStick/Orders-Icon.svg';
import PlacementIcon from 'app/assets/images/NavigationStick/Placements-Icon.svg';
import ReportIcon from 'app/assets/images/NavigationStick/Reports-Icon.svg';
import ClientContactIcon from 'app/assets/images/ClientContact/contacts_Icon.svg';
import SettingsNavIcon from 'app/assets/images/NavigationStick/Settings-Icon.svg';
import React from 'react';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { History } from '@mui/icons-material';

export const getStickIcons = customSwitch({
  0: <img src={HomeIcon} alt="HomeIcon" />,
  1: <img src={FacilityIcon} alt="FacilityIcon" />,
  2: <img src={OrdersIcon} alt="OrdersIcon" />,
  3: <img src={PlacementIcon} alt="PlacementIcon" />,
  4: <img src={CandidateIcon} alt="CandidateIcon" />,
  5: <img src={CredentialsIcon} alt="CredentialsIcon" />,
  6: <img src={ReportIcon} alt="ReportIcon" />,
  7: <img src={ClientContactIcon} alt="ClientContactIcon" />,
  8: <History color='primary'/>,
  9: <img src={SettingsNavIcon} alt='SettingsNavIcon' />,
})(undefined);
