import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { call, getContext, put, select } from 'redux-saga/effects';
import { placementDetailsAction } from 'store/redux-store/placement-details/slice';
import { TrackOfferConfirmationsWrapper, resetFailureStatus } from '../saga';
import { ServiceConstants } from '../sagaHelper';
import { IPlacementSummaryRequest, placementSummaryActions } from '../slice';
import InitPlacementSummary, { IConsolidatedRequirementResponse } from '../init.types';
import { selectPlacementConsolidatedDetails } from '../consolidatedStoreSelectors';

export function* requestOfferTravelDetailsRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('offerTravelDetailsRequirement'));
    const placementService: PlacementService = yield getContext('placementService');

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if(action.payload.isInitLoad && consolidatedDetails){
      response = {
        status : 200,
        data :{
          offerTravelDetails : {
            travelToStrike : consolidatedDetails.summary.offer.offerTravelDetailsRequrement?.travelToStrike?.data,
            travelFromStrike : consolidatedDetails.summary.offer.offerTravelDetailsRequrement?.travelFromStrike?.data,
          } 
        } 
      }
    }

    if(!response){
      response = yield call(
        TrackOfferConfirmationsWrapper,
        placementService.getStrikeRequirementsByContainer,
        action.payload.placementId,
        ...ServiceConstants.offerTravelDetails,
      );
    }
    
    if (httpSuccess(response?.status)) {
      yield put(placementSummaryActions.setOfferTravelDetailsInfo(response.data.offerTravelDetails));
      yield put(placementDetailsAction.resetForm());
    } else {
      yield put(
        placementSummaryActions.setServiceFailureByKey({ key: 'offerTravelDetailsRequirement', failureStatus: true }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestOfferTravelDetailsRequirement',
        area: 'src/store/redux-store/placement-summary/common-saga/requestOfferTravelDetailsRequirement.ts',
      },
    });
  }
}

export function processOfferTravelDetailsRequirement(
  response: IConsolidatedRequirementResponse<any>,
  context: InitPlacementSummary,
) {
  try {
    if (!httpSuccess(response?.status)) {
      context.setServiceFailureByKey({ key: 'offerTravelDetailsRequirement', failureStatus: true });
      return;
    }
    context.setOfferTravelDetailsInfo(response.data);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'processOfferTravelDetailsRequirement',
        area: 'src/store/redux-store/placement-summary/common-saga/requestOfferTravelDetailsRequirement.ts',
      },
    });
  }
}
