import { Box, Divider, Grid, Theme } from 'amn-ui-core';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  ISkillsetGroup,
  ISkillsetItem,
  SkillsetDetails,
  SkillsetType,
  getSkillsetsByType,
} from './Helpers/SkillsetHelper';
import { SkillsetDraggable } from './SkillsetDraggable';
import { makeStyles } from 'tss-react/mui';
import {
  addedSkillSetTree,
  selectSkillSetTree,
} from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { useSelector } from 'react-redux';
import { SkillsetDeleteConfirmation } from './Helpers/SkillsetDeleteConfirmation';
import { userRoles } from 'oidc/userRoles';
import { Authorized } from 'oidc/userHelper';
import { selectUser } from 'oidc/user.selectors';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    height: '100%',
  },
  divider: {
    width: '50%',
    borderColor: 'rgba(0, 0, 0, 0.25)',
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
    padding: '20px 0px 12px 0px',
  },
}));

interface SkillSetDragAndDropProps {
  columns: ISkillsetGroup;
  setColumns: (data: ISkillsetGroup) => void;
  handleToggle: (value: boolean) => void;
}

export const SkillSetDragAndDrop = (props: SkillSetDragAndDropProps) => {
  const { columns, setColumns, handleToggle } = props;
  const candidateSkillSetTree = useSelector(selectSkillSetTree);
  const isAddedSkillSetTree = useSelector(addedSkillSetTree);
  const [removeDialogOpen, setRemoveDialogOpen] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const user = useSelector(selectUser);
  const [selectedSkillset, setSelectedSkillset] = useState<SkillsetDetails>({
    type: SkillsetType.unverified,
    discipline: null,
    specialty: null,
    subspecialty: null,
    label: '',
  });
  const { classes } = useStyles();

  useEffect(() => {
    setDisabled(
      !Authorized(
        [userRoles.clinical_Manager, userRoles.clinical_ReviewAnalyst, userRoles.clinical_Director],
        user.userInfo,
      ),
    );
  }, [user]);

  useEffect(() => {
    setColumns({
      [SkillsetType.unverified]: {
        ...columns[SkillsetType.unverified],
        items: getSkillsetsByType(SkillsetType.unverified, { ...candidateSkillSetTree, ...isAddedSkillSetTree }),
      },
      [SkillsetType.verified]: {
        ...columns[SkillsetType.verified],
        items: getSkillsetsByType(SkillsetType.verified, { ...candidateSkillSetTree, ...isAddedSkillSetTree }),
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateSkillSetTree, isAddedSkillSetTree]);

  const onDragEnd = result => {
    const origin: string = result.source.droppableId;
    const destination: string = result.destination.droppableId;
    const skillset: ISkillsetItem = columns[origin].items.find(item => item.id === result.draggableId);

    if (skillset && origin !== destination) {
      setColumns({
        ...columns,
        [origin]: {
          ...columns[origin],
          items: columns[origin].items.filter(item => item.id !== skillset.id),
        },
        [destination]: {
          ...columns[destination],
          items: [
            ...columns[destination].items,
            { ...skillset, skillset: { ...skillset.skillset, type: destination } },
          ],
        },
      });
      handleToggle(false);
    }
  };

  const onDelete = (skillset: SkillsetDetails) => {
    setSelectedSkillset(skillset);
    setRemoveDialogOpen(true);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container xs={12} className={classes.container}>
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <>
                <Grid item xs={5} key={index}>
                  <Grid container direction={'column'}>
                    <Grid item>
                      <Box className={classes.title}>{column.title}</Box>
                    </Grid>
                    <Grid item>
                      <Divider />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        height: column?.items?.length > 10 ? '500px' : 'auto',
                        overflow: column?.items?.length > 10 ? 'scroll' : 'hidden',
                      }}
                    >
                      <Droppable key={columnId} droppableId={columnId}>
                        {(provided, snapshot) => (
                          <Grid
                            container
                            direction={'column'}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            sx={{
                              height: column?.items?.length > 10 ? 'auto' : '500px',
                            }}
                          >
                            {column.items
                              .sort((a, b) => a.skillset.label.localeCompare(b.skillset.label))
                              .map((item, i) => (
                                <Grid item key={i}>
                                  <SkillsetDraggable item={item} index={i} disabled={disabled} onDelete={onDelete} />
                                </Grid>
                              ))}
                          </Grid>
                        )}
                      </Droppable>
                    </Grid>
                  </Grid>
                </Grid>
                {index === 0 && (
                  <Grid item xs={1}>
                    <Divider orientation={'vertical'} classes={{ root: classes.divider }}></Divider>
                  </Grid>
                )}
              </>
            );
          })}
        </Grid>
      </DragDropContext>
      <SkillsetDeleteConfirmation
        open={removeDialogOpen}
        setDialogOpen={setRemoveDialogOpen}
        skillset={selectedSkillset}
        columns={columns}
      />
    </>
  );
};
