import React, { useEffect } from 'react';
import { Expandable } from 'app/components/Common/Expandable/Expandable';
import { IconTitle } from './Titles/IconTitle';
import WorkOutline from 'app/assets/images/IconImages/WorkOutline.svg';
import CandidateDirectHire from './CandidateDirectHire';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDirectHireOption,
  selectMiscellaneousPlacementData,
} from 'store/redux-store/placement-details/selectors';
import { placementDetailsAction } from 'store/redux-store/placement-details/slice';

export const DirectHire = ({ ...props }) => {
  const selectedDirectHireOpt = useSelector(selectDirectHireOption);
  const { extDirectHireOpts } = useSelector(selectMiscellaneousPlacementData);
  
  return (
    <Expandable
      id="placement-summary-direct-hire"
      expanded={extDirectHireOpts?.length && selectedDirectHireOpt.directHireOption.id !== ''}
      titleComponent={<IconTitle title="Direct Hire" icon={<img src={WorkOutline} alt="Work" />} />}
    >
      <CandidateDirectHire />
    </Expandable>
  );
};
