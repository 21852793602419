import { Avatar, Badge, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { missingField } from 'app/constants';
import { IUser, TaskStatus, TaskStatusReason } from 'app/models/Tasks/Tasks';
import { useTranslation } from 'react-i18next';
import { FormatDate } from '@AMIEWEB/Notification/Tasks/Common/FormatData';
import { WhiteTooltip } from '@AMIEWEB/Common/Tooltips/WhiteTooltip';
import { checkOboMarkedError } from '../TaskDetails/helper';

interface GridCellProps {
  [key: string]: any;
}

const useStyles = makeStyles<{ coveredEmployee: boolean }>()((theme, { coveredEmployee }) => ({
  newBadge: {
    backgroundColor: theme.palette.system.main,
    border: '1px solid white',
    borderRadius: '12px',
    width: '12px',
    height: '12px',
  },
  pendingBadge: {
    backgroundColor: theme.palette.system.orange,
    border: '1px solid white',
    borderRadius: '12px',
    width: '12px',
    height: '12px',
  },
  defaultBadge: {
    backgroundColor: theme.palette.system.suvaGrey,
    border: '1px solid white',
    borderRadius: '12px',
    width: '12px',
    height: '12px',
  },
  externalBadge: {
    backgroundColor: 'transparent',
  },
  tooltip: {
    wordWrap: 'normal',
    whiteSpace: 'pre-wrap',
    justifyContent: 'center',
  },
  coverageTooltip: {
    fontSize: '12px !important',
    wordWrap: 'normal',
    whiteSpace: 'pre-wrap',
    justifyContent: 'center',
  },
  toolTipText: {
    display: 'flex',
    flexDirection: 'row',
    whiteSpace: 'nowrap',
    justifyContent: 'justify-start',
  },
  avatar: {
    height: '24px',
    width: '24px !important',
    marginRight: '3px',
    color: theme.palette.components.avatar.color,
    fontSize: '11px',
    lineHeight: '12px',
    fontFamily: 'Roboto',
    display: 'flex',
  },
  moreValuesAvatar: {
    border: `1px solid ${theme.palette.framework.system.charcoal}`,
    color: `${theme.palette.framework.system.charcoal} !important`,
  },
  typography1: {
    fontFamily: 'Roboto',
    fontSize: '12px !important',
    lineHeight: '20px',
    color: theme.palette.components.typography.color,
    fontWeight: 'normal',
    paddingRight: '3px',
  },
  typography2: {
    fontFamily: 'Roboto',
    fontSize: '12px !important',
    lineHeight: '20px',
    color: theme.palette.components.typography.color,
    fontWeight: 500,
  },
}));

const TaskRecipientAvatarsRenderer = React.memo(function TaskRecipientAvatarsRenderer(cellParams?: GridCellProps) {
  const { t } = useTranslation();
  const inDetailFlag = cellParams?.inDetail;
  const taskDetails = cellParams?.taskDetails;
  const max = cellParams?.groupMax;
  const creator = cellParams?.creator;
  const createdBycoveredEmployee = cellParams?.createdBycoveredEmployee;
  const assigneeCoveredByDetails = cellParams?.assigneeCoveredByDetails;
  const { classes } = useStyles({
    coveredEmployee: !!createdBycoveredEmployee || !!assigneeCoveredByDetails,
  });
  const [statusGroups, setStatusGroups] = useState<any[]>();
  const taskRecipients = cellParams?.row?.recipientStatus ?? cellParams?.recipientList;
  const createdDate = cellParams?.createdDate;
  const sharedDeskAssociatedUsers = taskDetails?.sharedDeskAssociatedUsers;
  const createdBySharedDesk = sharedDeskAssociatedUsers?.find(shared => shared?.actionType === TaskStatus.New);

  const getSharedDeskDetails = user => {
    return sharedDeskAssociatedUsers?.find(shared => shared.employeeId === user?.id || shared.actionById === user?.id);
  };

  const getStatusColor = user => {
    switch (user?.statusId) {
      case 1:
        return classes.newBadge;
      case 2:
        return classes.pendingBadge;
      case 3:
        return classes.defaultBadge;
      case 4:
        return classes.defaultBadge;
      case 5:
        return classes.externalBadge;
      default:
        return classes.defaultBadge;
    }
  };

  const getTooltipText = user => {
    const coverageDetails = assigneeCoveredByDetails?.find(
      userDetails => userDetails?.coveredForUserId === user?.id && !userDetails?.createdByError,
    );
    const userDetails = taskDetails?.taskStatusDetails?.find(userData => userData?.recipientId === user?.id);
    const coveredByUpdatedTask = userDetails?.recipientId !== userDetails?.statusUpdatedById;
    const sharedDeskUpdatedTsk = sharedDeskAssociatedUsers?.find(shared => shared?.actionType === user?.statusId);
    const sharedDeskDetails = getSharedDeskDetails(user);
    switch (user?.statusId) {
      case 1:
        return (
          <span style={{ display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap' }}>
            <Typography className={`${classes.tooltip} ${classes.typography1}`}>
              {`${user?.assignedToFirstName} ${user?.assignedToLastName} : `}
            </Typography>
            <Typography className={classes.typography2}>{'New'}</Typography>
          </span>
        );
      case 2:
        const pendingCoverageText = coverageDetails
          ? `${t('notification.taskDetails.pendingByTitle')} ${coverageDetails?.firstName} ${
              coverageDetails?.lastName
            } ${t('notification.taskDetails.on')} ${coverageDetails?.actionDate} ${t(
              'notification.taskSidePanel.cueNotes.obo',
            )} ${user?.assignedToFirstName} ${user?.assignedToLastName}`
          : '';
        const pendingSharedProfileText = sharedDeskUpdatedTsk
          ? `${t('notification.taskDetails.pendingByTitle')} ${sharedDeskDetails?.firstName} ${
              sharedDeskDetails?.lastName
            } ${t('notification.taskDetails.on')} ${FormatDate(userDetails?.statusUpdatedDate, 'L hh:mm A')} ${t(
              'notification.taskSidePanel.cueNotes.obo',
            )} ${sharedDeskDetails?.actionByFirstName} ${sharedDeskDetails?.actionByLastName}`
          : '';
        return (
          <>
            {coveredByUpdatedTask && coverageDetails ? (
              <span className={classes.toolTipText}>
                <Typography
                  className={classes.coverageTooltip}
                  sx={{ fontWeight: 'normal !important' }}
                  textAlign={'center'}
                >
                  {pendingCoverageText}
                </Typography>
              </span>
            ) : (
              <>
                {inDetailFlag && userDetails ? (
                  sharedDeskUpdatedTsk && sharedDeskDetails ? (
                    <span className={classes.toolTipText}>
                      <Typography
                        className={classes.coverageTooltip}
                        sx={{ fontWeight: 'normal !important' }}
                        textAlign={'center'}
                      >
                        {pendingSharedProfileText}
                      </Typography>
                    </span>
                  ) : (
                    <span className={classes.toolTipText}>
                      <Typography
                        className={classes.coverageTooltip}
                        sx={{ fontWeight: 'normal !important' }}
                        textAlign={'center'}
                      >
                        {`${t('notification.taskDetails.pendingByTitle')} ${user?.assignedToFirstName} ${
                          user?.assignedToLastName
                        } ${t('notification.taskDetails.on')} ${FormatDate(
                          userDetails?.statusUpdatedDate,
                          'L hh:mm A',
                        )} `}
                      </Typography>
                    </span>
                  )
                ) : (
                  <span style={{ display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap' }}>
                    <Typography className={`${classes.tooltip} ${classes.typography1}`}>
                      {`${user?.assignedToFirstName} ${user?.assignedToLastName} : `}
                    </Typography>
                    <Typography className={classes.typography2}>{'Pending'}</Typography>
                  </span>
                )}
              </>
            )}
          </>
        );

      case 3:
        const completedCoverageText = coverageDetails
          ? `${t('notification.taskDetails.completedByTitle')} ${coverageDetails?.firstName} ${
              coverageDetails?.lastName
            } ${t('notification.taskDetails.on')} ${coverageDetails?.actionDate} ${t(
              'notification.taskSidePanel.cueNotes.obo',
            )} ${user?.assignedToFirstName} ${user?.assignedToLastName}`
          : '';
        const completedsharedEmployeeText = sharedDeskUpdatedTsk
          ? `${t('notification.taskDetails.completedByTitle')} ${sharedDeskDetails?.firstName} ${
              sharedDeskDetails?.lastName
            } ${t('notification.taskDetails.on')} ${FormatDate(userDetails?.statusUpdatedDate, 'L hh:mm A')} ${t(
              'notification.taskSidePanel.cueNotes.obo',
            )} ${sharedDeskDetails?.actionByFirstName} ${sharedDeskDetails?.actionByLastName}`
          : '';
        return (
          <>
            {coveredByUpdatedTask && coverageDetails ? (
              <span className={classes.toolTipText}>
                <Typography
                  className={classes.coverageTooltip}
                  sx={{ fontWeight: 'normal !important' }}
                  textAlign={'center'}
                >
                  {completedCoverageText}
                </Typography>
              </span>
            ) : (
              <>
                {inDetailFlag && userDetails ? (
                  sharedDeskUpdatedTsk && sharedDeskDetails ? (
                    <span className={classes.toolTipText}>
                      <Typography
                        className={classes.coverageTooltip}
                        sx={{ fontWeight: 'normal !important' }}
                        textAlign={'center'}
                      >
                        {completedsharedEmployeeText}
                      </Typography>
                    </span>
                  ) : (
                    <span className={classes.toolTipText}>
                      <Typography
                        className={classes.coverageTooltip}
                        sx={{ fontWeight: 'normal !important' }}
                        textAlign={'center'}
                      >
                        {`${t('notification.taskDetails.completedByTitle')} ${user?.assignedToFirstName} ${
                          user?.assignedToLastName
                        } ${t('notification.taskDetails.on')} ${FormatDate(
                          userDetails?.statusUpdatedDate,
                          'L hh:mm A',
                        )} `}
                      </Typography>
                    </span>
                  )
                ) : (
                  <span style={{ display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap' }}>
                    <Typography className={`${classes.tooltip} ${classes.typography1}`}>
                      {`${user?.assignedToFirstName} ${user?.assignedToLastName} : `}
                    </Typography>
                    <Typography className={classes.typography2}>
                      {t('notification.taskStatusModal.statusLabels.completed')}
                    </Typography>
                  </span>
                )}
              </>
            )}
          </>
        );
      case 4:
        const cancelledCoverageText = coverageDetails
          ? `${t('notification.taskDetails.cancelledByTitle')} ${coverageDetails?.firstName} ${
              coverageDetails?.lastName
            } ${t('notification.taskDetails.on')} ${coverageDetails?.actionDate} ${t(
              'notification.taskSidePanel.cueNotes.obo',
            )} ${user?.assignedToFirstName} ${user?.assignedToLastName}`
          : '';
        const cancelledSharedEmployeeText = sharedDeskUpdatedTsk
          ? `${t('notification.taskDetails.cancelledByTitle')} ${sharedDeskDetails?.firstName} ${
              sharedDeskDetails?.lastName
            } ${t('notification.taskDetails.on')} ${FormatDate(userDetails?.statusUpdatedDate, 'L hh:mm A')} ${t(
              'notification.taskSidePanel.cueNotes.obo',
            )} ${sharedDeskDetails?.actionByFirstName} ${sharedDeskDetails?.actionByLastName}`
          : '';
        const createdByErrorObo = checkOboMarkedError(userDetails, taskDetails);
        return (
          <>
            {coveredByUpdatedTask && coverageDetails ? (
              <span className={classes.toolTipText}>
                <Typography
                  className={classes.coverageTooltip}
                  sx={{ fontWeight: 'normal !important' }}
                  textAlign={'center'}
                >
                  {cancelledCoverageText}
                </Typography>
              </span>
            ) : (
              <>
                {inDetailFlag && userDetails ? (
                  sharedDeskUpdatedTsk && sharedDeskDetails ? (
                    <span className={classes.toolTipText}>
                      <Typography
                        className={classes.coverageTooltip}
                        sx={{ fontWeight: 'normal !important' }}
                        textAlign={'center'}
                      >
                        {cancelledSharedEmployeeText}
                      </Typography>
                    </span>
                  ) : (
                    <span className={classes.toolTipText}>
                      <Typography
                        className={classes.coverageTooltip}
                        sx={{ fontWeight: 'normal !important' }}
                        textAlign={'center'}
                      >
                        {userDetails?.statusUpdateReasonId === TaskStatusReason.ERROR
                          ? createdByErrorObo
                            ? `${t('notification.taskDetails.cancelledByTitle')} ${
                                createdByErrorObo?.coveredByEmployeeFirstName
                              } ${createdByErrorObo?.coveredByEmployeeLastName} ${t(
                                'notification.taskDetails.on',
                              )} ${FormatDate(createdByErrorObo?.actionDate, 'L hh:mm A')} ${t(
                                'notification.taskSidePanel.cueNotes.obo',
                              )} ${createdByErrorObo?.coveredForEmployeeFirstName} ${
                                createdByErrorObo?.coveredForEmployeeLastName
                              }`
                            : `${t('notification.taskDetails.cancelledByTitle')} ${taskDetails?.createdBy?.firstName} ${
                                taskDetails?.createdBy?.lastName
                              } ${t('notification.taskDetails.on')} ${FormatDate(
                                userDetails?.statusUpdatedDate,
                                'L hh:mm A',
                              )} `
                          : `${t('notification.taskDetails.cancelledByTitle')} ${user?.assignedToFirstName} ${
                              user?.assignedToLastName
                            } ${t('notification.taskDetails.on')} ${FormatDate(
                              userDetails?.statusUpdatedDate,
                              'L hh:mm A',
                            )} `}
                      </Typography>
                    </span>
                  )
                ) : (
                  <span style={{ display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap' }}>
                    <Typography className={`${classes.tooltip} ${classes.typography1}`}>
                      {`${user?.assignedToFirstName} ${user?.assignedToLastName} : `}
                    </Typography>
                    <Typography className={classes.typography2}>
                      {t('notification.taskStatusModal.statusLabels.cancelled')}
                    </Typography>
                  </span>
                )}
              </>
            )}
          </>
        );
      case 5:
        return (
          <span style={{ display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap' }}>
            <Typography className={`${classes.tooltip} ${classes.typography1}`}>
              {user?.assignedToFirstName + ' ' + user?.assignedToLastName}
            </Typography>
          </span>
        );
      default:
        const coverageText = createdBycoveredEmployee
          ? `${t('notification.taskDetails.createdBy')} ${createdBycoveredEmployee?.firstName} ${
              createdBycoveredEmployee?.lastName
            } ${t('notification.taskDetails.on')} ${createdDate} ${t('notification.taskSidePanel.cueNotes.obo')} ${
              user?.firstName
            } ${user?.lastName}`
          : '';
        const SharedProfileText = createdBySharedDesk
          ? `${t('notification.taskDetails.createdBy')} ${createdBySharedDesk?.firstName} ${
              createdBySharedDesk?.lastName
            } ${t('notification.taskDetails.on')} ${createdDate} ${t('notification.taskSidePanel.cueNotes.obo')} ${
              createdBySharedDesk?.actionByFirstName
            } ${createdBySharedDesk?.actionByLastName}`
          : '';
        return (
          <>
            {createdBycoveredEmployee ? (
              <span className={classes.toolTipText}>
                <Typography
                  className={classes.coverageTooltip}
                  sx={{ fontWeight: 'normal !important' }}
                  textAlign={'center'}
                >
                  {coverageText}
                </Typography>
              </span>
            ) : (
              <>
                {inDetailFlag ? (
                  createdBySharedDesk && sharedDeskDetails ? (
                    <span className={classes.toolTipText}>
                      <Typography
                        className={classes.coverageTooltip}
                        sx={{ fontWeight: 'normal !important' }}
                        textAlign={'center'}
                      >
                        {SharedProfileText}
                      </Typography>
                    </span>
                  ) : (
                    <span className={classes.toolTipText}>
                      <Typography
                        className={classes.coverageTooltip}
                        sx={{ fontWeight: 'normal !important' }}
                        textAlign={'center'}
                      >
                        {`${t('notification.taskSidePanel.createdByTooltip')} ${user?.firstName} ${user?.lastName} ${t(
                          'notification.taskDetails.on',
                        )} ${createdDate}`}
                      </Typography>
                    </span>
                  )
                ) : (
                  <span style={{ display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap' }}>
                    <Typography className={classes.typography2}>
                      {`${t('notification.taskSidePanel.createdByTooltip')}: `}
                    </Typography>
                    <Typography style={{ paddingLeft: '3px' }} className={`${classes.tooltip} ${classes.typography1}`}>
                      {`${user?.firstName} ${user?.lastName}`}
                    </Typography>
                  </span>
                )}
              </>
            )}
          </>
        );
    }
  };

  const calcGroupWidth = groupLength => {
    let groupWidth = 0;
    if (groupLength <= max) {
      groupWidth = groupLength * 27 - (groupLength - 1) * 8;
    } else if (groupLength > max) {
      groupWidth = max * 27 - (max - 1) * 8;
    }
    return groupWidth;
  };

  React.useEffect(() => {
    const newGroup: any[] = [];
    const pendingGroup: any[] = [];
    const completedGroup: any[] = [];
    const cancelledGroup: any[] = [];
    const externalTaskGroup: any[] = [];

    taskRecipients?.forEach(recipient => {
      if (recipient?.statusId === 1) {
        newGroup.push(recipient);
      } else if (recipient?.statusId === 2) {
        pendingGroup.push(recipient);
      } else if (recipient?.statusId === 3) {
        completedGroup.push(recipient);
      } else if (recipient?.statusId === 4) {
        completedGroup.push(recipient);
      } else if (recipient?.statusId === 5) {
        externalTaskGroup.push(recipient);
      }
    });
    const groups = [];
    if (newGroup.length) {
      groups.push(newGroup);
    }
    if (pendingGroup.length) {
      groups.push(pendingGroup);
    }
    if (completedGroup.length) {
      groups.push(completedGroup);
    }
    if (cancelledGroup.length) {
      groups.push(cancelledGroup);
    }
    if (externalTaskGroup.length) {
      groups.push(externalTaskGroup);
    }
    setStatusGroups(groups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskRecipients]);

  const isUpdatedBySharedDesk = user => {
    return sharedDeskAssociatedUsers?.find(shared => shared.actionType === user?.statusId);
  };

  return (
    <div style={{ display: 'flex' }}>
      {taskRecipients !== missingField && taskRecipients !== null ? (
        <>
          {creator && createdBycoveredEmployee ? (
            <WhiteTooltip id="task" arrow title={getTooltipText(creator)}>
              <div style={{ display: 'flex', whiteSpace: 'nowrap', overflow: 'ellipsis', marginRight: '12px' }}>
                <div style={{ position: 'relative' }}>
                  <Avatar
                    sx={{
                      backgroundColor: theme => theme.palette.secondary.main,
                    }}
                    className={classes.avatar}
                  >
                    {`${createdBycoveredEmployee.firstName?.charAt(0) + createdBycoveredEmployee.lastName?.charAt(0)}`}
                  </Avatar>
                </div>
                <div style={{ position: 'relative', marginLeft: '-8px' }}>
                  <Avatar
                    sx={{
                      backgroundColor: theme => theme.palette.system.primary,
                    }}
                    className={classes.avatar}
                  >
                    {`${creator.firstName?.charAt(0) + creator.lastName?.charAt(0)}`}
                  </Avatar>
                </div>
              </div>
            </WhiteTooltip>
          ) : creator && createdBySharedDesk ? (
            <WhiteTooltip id="task" arrow title={getTooltipText(creator)}>
              <div style={{ display: 'flex', whiteSpace: 'nowrap', overflow: 'ellipsis', marginRight: '12px' }}>
                <div style={{ position: 'relative' }}>
                  <Avatar
                    sx={{
                      backgroundColor: theme => theme.palette.secondary.main,
                    }}
                    className={classes.avatar}
                  >
                    {`${createdBySharedDesk?.firstName?.charAt(0) + createdBySharedDesk?.lastName?.charAt(0)}`}
                  </Avatar>
                </div>
                <div style={{ position: 'relative', marginLeft: '-8px' }}>
                  <Avatar
                    sx={{
                      backgroundColor: theme => theme.palette.system.primary,
                    }}
                    className={classes.avatar}
                  >
                    {`${
                      createdBySharedDesk.actionByFirstName?.charAt(0) + createdBySharedDesk.actionByLastName?.charAt(0)
                    }`}
                  </Avatar>
                </div>
              </div>
            </WhiteTooltip>
          ) : creator ? (
            <div style={{ display: 'flex', whiteSpace: 'nowrap', overflow: 'ellipsis', marginRight: '12px' }}>
              <div style={{ position: 'relative' }}>
                <WhiteTooltip id="task" arrow title={getTooltipText(creator)}>
                  <Avatar
                    sx={{
                      backgroundColor: theme => theme.palette.system.primary,
                    }}
                    className={classes.avatar}
                  >
                    {`${creator.firstName?.charAt(0) + creator.lastName?.charAt(0)}`}
                  </Avatar>
                </WhiteTooltip>
              </div>
            </div>
          ) : null}
          {statusGroups?.map((group, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                whiteSpace: 'nowrap',
                overflow: 'ellipsis',
                marginLeft: index > 0 && '12px',
                width: `${calcGroupWidth(group.length)}px`,
              }}
            >
              {group
                ?.map((user, idx) => (
                  <div key={idx} style={{ position: 'relative', left: idx !== 0 && `${idx * -8}px` }}>
                    <WhiteTooltip id="task" arrow sx={{ width: 'auto', display: 'flex' }} title={getTooltipText(user)}>
                      {group.length <= max && idx === group.length - 1 ? (
                        isUpdatedBySharedDesk(user) && getSharedDeskDetails(user) ? (
                          <div
                            style={{ display: 'flex', whiteSpace: 'nowrap', overflow: 'ellipsis', marginRight: '12px' }}
                          >
                            <div style={{ position: 'relative' }}>
                              <Avatar
                                sx={{
                                  backgroundColor: theme =>
                                    user?.isCC === 'Y'
                                      ? theme.palette.framework.system.doveGray
                                      : theme.palette.system.darkGreen,
                                }}
                                className={classes.avatar}
                              >
                                {`${
                                  getSharedDeskDetails(user)?.firstName?.charAt(0) +
                                  getSharedDeskDetails(user)?.lastName?.charAt(0)
                                }`}
                              </Avatar>
                            </div>
                            <div style={{ position: 'relative', marginLeft: '-8px' }}>
                              <Badge
                                classes={{ badge: getStatusColor(user) }}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                variant="dot"
                                overlap="circular"
                                badgeContent={''}
                              >
                                <Avatar
                                  sx={{
                                    backgroundColor: theme =>
                                      user?.isCC === 'Y'
                                        ? theme.palette.framework.system.doveGray
                                        : theme.palette.system.darkGreen,
                                  }}
                                  className={classes.avatar}
                                >
                                  {`${
                                    getSharedDeskDetails(user)?.actionByFirstName?.charAt(0) +
                                    getSharedDeskDetails(user)?.actionByLastName?.charAt(0)
                                  }`}
                                </Avatar>
                              </Badge>
                            </div>
                          </div>
                        ) : (
                          <Badge
                            classes={{ badge: getStatusColor(user) }}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            variant="dot"
                            overlap="circular"
                            badgeContent={''}
                          >
                            <Avatar
                              sx={{
                                backgroundColor: theme =>
                                  user?.isCC === 'Y'
                                    ? theme.palette.framework.system.doveGray
                                    : theme.palette.system.darkGreen,
                              }}
                              className={classes.avatar}
                            >
                              {`${user?.assignedToFirstName?.charAt(0) + user?.assignedToLastName?.charAt(0)}`}
                            </Avatar>
                          </Badge>
                        )
                      ) : isUpdatedBySharedDesk(user) && getSharedDeskDetails(user) ? (
                        <div style={{ display: 'flex', whiteSpace: 'nowrap', overflow: 'ellipsis' }}>
                          <div style={{ position: 'relative' }}>
                            <Avatar
                              sx={{
                                backgroundColor: theme =>
                                  user?.isCC === 'Y'
                                    ? theme.palette.framework.system.doveGray
                                    : theme.palette.system.darkGreen,
                              }}
                              className={classes.avatar}
                            >
                              {`${
                                getSharedDeskDetails(user)?.firstName?.charAt(0) +
                                getSharedDeskDetails(user)?.lastName?.charAt(0)
                              }`}
                            </Avatar>
                          </div>
                          <div style={{ position: 'relative', marginLeft: '-8px' }}>
                            <Avatar
                              sx={{
                                backgroundColor: theme =>
                                  user?.isCC === 'Y'
                                    ? theme.palette.framework.system.doveGray
                                    : theme.palette.system.darkGreen,
                              }}
                              className={classes.avatar}
                            >
                              {`${
                                getSharedDeskDetails(user)?.actionByFirstName?.charAt(0) +
                                getSharedDeskDetails(user)?.actionByLastName?.charAt(0)
                              }`}
                            </Avatar>
                          </div>
                        </div>
                      ) : (
                        <Avatar
                          sx={{
                            backgroundColor: theme =>
                              user?.isCC === 'Y'
                                ? theme.palette.framework.system.doveGray
                                : theme.palette.system.darkGreen,
                          }}
                          className={classes.avatar}
                        >
                          {`${user?.assignedToFirstName?.charAt(0) + user?.assignedToLastName?.charAt(0)}`}
                        </Avatar>
                      )}
                    </WhiteTooltip>
                  </div>
                ))
                .slice(0, group.length > max ? max - 1 : max)}
              {max && group.length > max && (
                <div style={{ position: 'relative', left: `${(max - 1) * -8}px` }}>
                  <WhiteTooltip
                    id="task"
                    arrow
                    title={
                      <React.Fragment>{(group || []).map(user => getTooltipText(user)).slice(max - 1)}</React.Fragment>
                    }
                  >
                    <Badge
                      classes={{ badge: getStatusColor(group[0]) }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      variant="dot"
                      overlap="circular"
                      badgeContent={''}
                      style={{ visibility: group.length > max ? 'visible' : 'hidden' }}
                    >
                      <Avatar
                        sx={{ backgroundColor: theme => theme.palette.components.avatar.color }}
                        className={`${classes.avatar} ${classes.moreValuesAvatar}`}
                      >{`+${(group || []).length - (max - 1)}`}</Avatar>
                    </Badge>
                  </WhiteTooltip>
                </div>
              )}
            </div>
          ))}
        </>
      ) : (
        missingField
      )}
    </div>
  );
});

export const TaskRecipientAvatars = (
  params?: GridCellParams,
  inDetail: boolean = false,
  recipientList?: any[],
  taskDetails: any = null,
  groupMax: number = 5,
  creator: IUser = null,
  createdBycoveredEmployee?: IUser,
  assigneeCoveredByDetails?: any[],
  createdDate?: string,
) => {
  return (
    <TaskRecipientAvatarsRenderer
      {...params}
      recipientList={recipientList}
      groupMax={groupMax}
      creator={creator}
      createdBycoveredEmployee={createdBycoveredEmployee}
      assigneeCoveredByDetails={assigneeCoveredByDetails}
      createdDate={createdDate}
      inDetail={inDetail}
      taskDetails={taskDetails}
    />
  );
};
