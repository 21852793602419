import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddFolderIcon from '../../../assets/images/IconImages/AddFolderIcon.svg';
import styledComponent from 'styled-components';
import { colors } from 'styles/styleVariables';
import { Box } from 'amn-ui-core';
import { useDecision } from '@optimizely/react-sdk';
import { ff_placement_ui_packet_generation } from 'app/constants/FeatureFlags/Placement/Keys';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import { Concatenate } from 'utils/string/string';

const useCreatePacketButton = ({ candidateId, placementId, ...rest }) => {
  const [enableLink] = useDecision(ff_placement_ui_packet_generation);
  const [link, setLink] = useState<string>(globalThis?.app?.env?.REACT_APP_PACKETGEN_URL);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (!!rest.queryString)
      Object.entries<string>(rest.queryString).forEach(([key, value]) => queryParams.set(key, value));
    const returnUrl = encodeURIComponent(Concatenate([window.location.pathname, queryParams.toString()], '?'));
    const splitUrl = returnUrl.split('placement%2F')[1];
    setLink(
      `${globalThis?.app?.env?.REACT_APP_PACKETGEN_URL}?candidateId=${candidateId}&placementId=${placementId}&r=placement/${splitUrl}`,
    );
  }, [candidateId, placementId, rest.queryString]);

  return { enableLink, link };
};

export const CreatePacketBtn = ({
  onClick = undefined,
  hidden = false,
  disabled = false,
  target = '_self',
  linkProps: { candidateId, placementId, ...rest },
}) => {
  const { t } = useTranslation();
  const { enableLink, link } = useCreatePacketButton({ candidateId, placementId, ...rest });

  return !hidden ? (
    enableLink?.enabled && !disabled ? (
      <Link href={link} onClick={onClick} target={target}>
        <img src={AddFolderIcon} style={{ marginTop: -2 }} />
        <div>{t('placement.profile.createPacket')}</div>
      </Link>
    ) : (
      <DisabledLink>
        <img src={AddFolderIcon} alt="Add folder" style={{ marginTop: -2 }} />
        <div>{t('placement.profile.createPacket')}</div>
      </DisabledLink>
    )
  ) : null;
};

export const CreatePacketXGridBtn = ({
  onClick = undefined,
  hidden = false,
  disabled = false,
  target = '_self',
  linkProps: { candidateId, placementId, ...rest },
}) => {
  const { t } = useTranslation();
  const { enableLink, link } = useCreatePacketButton({ candidateId, placementId, ...rest });

  return hidden
    ? []
    : [
        {
          title: t('placement.profile.viewPackets'),
          Icon: CreateNewFolderOutlinedIcon,
          disabled: !enableLink.enabled || disabled,
          onClick: event => {
            onClick?.(event);
            window.open(link, target);
          },
        },
      ];
};

const Link = styledComponent.a`
  display: flex;
  flex-direction: row;
  gap: 6px;
  color: ${colors.amnBlue};
  text-decoration: none;
  cursor: pointer;
  font-weight: 450;
`;

const DisabledLink = styledComponent(Box)`
  display: flex;
  flex-direction: row;
  gap: 6px;
  color: ${colors.amnBlue};
  cursor: pointer;
  font-weight: 450;
`;
