import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { useWatch } from 'react-hook-form';
import { useEditableRequirement } from './EditRequirementsHelper';
import { DepartureItineraryLinkCheck } from './EditablePassFailLogic';
import { useSelector } from 'react-redux';
import { selectOfferInfo } from '../../../../../../../../../store/redux-store/placement-summary/selectors';

export const useEditableRequirementDepartureItineraryLink = () => {
  // DEPARTURE ITINERARY LINK
  const {
    transportation: { departureItineraryLink },
  } = useSelector(selectOfferInfo);
  const transportation: any = useWatch({ name: 'transportationChosen' });

  useEditableRequirement({
    data: departureItineraryLink?.type,
    secondaryData: transportation?.transportation,
    requirementId: IPlacementRequirementKey.departureItinerary,
    requirementKey: 'departureItineraryLink',
    requirementCheck: DepartureItineraryLinkCheck,
    shouldAddFailedRequirement: transportation?.transportation !== 'Unknown',
    failRequirement: {
      id: IPlacementRequirementKey.departureItinerary,
      message: 'Itinerary: Missing',
      tooltip: {
        message: 'Itinerary link is required',
      },
    },
  });
};
