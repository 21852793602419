import { tooltipType } from 'app/components/Common/Tooltips';
import { ICustomHeader } from 'app/models/Grid';

export const modalColumns: ICustomHeader[] = [
  {
    title: 'candidate',
    value: 'Candidate',
    sortable: true,
    clickable: true,
    navigationIds: ['candidateId', 'brandId'],
    navigationExp: '/candidate/{0}/{1}',
  },
  {
    title: 'pid',
    value: 'PID',
    sortable: true,
    clickable: true,
    navigationIds: ['pid'],
    navigationExp: '/placement/{0}',
  },
  {
    title: 'recruiter',
    value: 'Recruiter',
    sortable: true,
  },
  {
    title: 'company',
    value: 'Company',
    sortable: true,
  },
  {
    title: 'facility',
    value: 'Facility',
    sortable: true,
    clickable: true,
    navigationIds: ['facilityId'],
    navigationExp: '/facility/{0}',
    tooltip: tooltipType.OverflowTooltip,
  },
  {
    title: 'city',
    value: 'City',
    sortable: true,
  },
  {
    title: 'accountManager',
    value: 'Account Manager',
    sortable: true,
  },
  {
    title: 'status',
    value: 'Status',
    sortable: true,
    sortByField: 'status',
    defaultSort: true,
  },
  {
    title: 'statusAge',
    value: 'Status Age',
    sortable: true,
    sortByField: 'statusAgeValue',
  },
];
