import { HomePage } from '../../../layout/pages/HomePage';

import React, { useEffect, useState } from 'react';

import { createRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { credentialingDashboardExpiringDocumentSaga } from '../ExpiringCredentials/Store/ExpiringCredential.saga';
import { ff_WorkExperience_Enhancement } from 'app/constants/FeatureFlags/Candidate/Keys';

import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import {
  credentialingDashboardExpiringReducer,
  credentialingDashboardExpiringSliceKey,
  credentialingDashboardExpiringActions,
} from '../ExpiringCredentials/Store/ExpiringCredential.redux';

import {
  selectExpiringChips,
  selectExpiringGridData,
  selectExpiringLoading,
  selectExpiringSourceData,
} from './Store/ExpiringCredential.selector';
import { selectCredExpiringDaysGrid } from 'app/components/Common/WorkDesk/PipelineChoice/pipelineChoice.selectors';
import { gridStateActions } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import { expiringCredentialsSubStatus, IFilterChipSelected } from './Models/ExpiringDocumentInterfaces';
import { ExpiringFilter } from './Filters/ExpiringFilter';
import { ExpiringDayChips } from './Chips/ExpiringDayChips';
import { defaultFilterValues } from './Filters/ExpiringFilterConfig';
import { createFilterChips, getFilteredData } from './Helpers/tiersFilterHelper';
import { ExpiringCredentialsPipeline } from './DataVisualization/ExpiringCredentialsPipeline';
import {
  pipelineChoiceKeys,
  pipelineChoiceActions,
} from 'app/components/Common/WorkDesk/PipelineChoice/pipelineChoice.redux';
import { ExpiringCredentialColumns } from './Table/ExpiringCredentialHeaders';
import { ExpiringCredentialsTable } from './Table/ExpiringCredentialList';
import { useDecision } from '@optimizely/react-sdk';

export const ExpiringCredentialWrapper = props => {
  const formMethods = useForm({ defaultValues: defaultFilterValues, shouldUnregister: false });
  const hiddenSubmitButton = createRef<HTMLButtonElement>();
  const { t } = useTranslation();
  useInjectReducer({ key: credentialingDashboardExpiringSliceKey, reducer: credentialingDashboardExpiringReducer });
  useInjectSaga({ key: credentialingDashboardExpiringSliceKey, saga: credentialingDashboardExpiringDocumentSaga });

  const dispatch = useDispatch();
  const daySelection = useSelector(selectCredExpiringDaysGrid);
  const flattenedData = useSelector(selectExpiringGridData);
  const chips = useSelector(selectExpiringChips);
  const expiringSourceData = useSelector(selectExpiringSourceData);
  const loading = useSelector(selectExpiringLoading);
  const [showWorkExperienceTab] = useDecision(ff_WorkExperience_Enhancement);

  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);

  const { handleSubmit, reset } = formMethods;
  //EVENTS
  const onApplyFilter = (values, selectedChips?: IFilterChipSelected) => {
    dispatch(gridStateActions.setFilterAttributes({ filterObject: Object.entries(values) }));
    const filteredData = getFilteredData(values, expiringSourceData, selectedChips);
    dispatch(
      credentialingDashboardExpiringActions.setFilterChips({
        ...chips,
        ...createFilterChips(values, !!selectedChips ? selectedChips : undefined),
      }),
    );
    dispatch(credentialingDashboardExpiringActions.setFilteredData(filteredData));
  };

  useEffect(() => {
    const columns = ExpiringCredentialColumns.map(item => {
      if (item.title === 'credential') {
        if (showWorkExperienceTab?.enabled) {
          item.navigationExp = '/candidate/{0}/{1}?tab=3&rid={2}';
        }
      }
      return item;
    });
    setTableColumns(columns.reduce((resultArray, item) => [...resultArray, item], [] as any[]));
    loadGridData(daySelection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daySelection, flattenedData]);

  const categoryToNumbericValue = category => {
    switch (category) {
      case 0:
        return 0;
      case 1:
        return 1;
      case 2:
        return 7;
      case 3:
        return 14;
      case 4:
        return 30;
      case 5:
        return Number.MAX_SAFE_INTEGER;
      case 6:
        return -1;
      default:
        return Number.MAX_SAFE_INTEGER;
    }
  };

  const loadGridData = (selected?: { category: number; subcategory: number | null; value?: any }[]) => {
    if (selected) {
      setTableData([
        ...flattenedData.filter(item => {
          return selected.some(
            sel =>
              ((categoryToNumbericValue(sel.category) === -1 && item.statusAbbr === 'OA') ||
                (sel.category > 1 && item.daysUntilExpiration <= categoryToNumbericValue(sel.category)) ||
                ((sel.category === 0 || sel.category === 1) &&
                  item.daysUntilExpiration === categoryToNumbericValue(sel.category))) &&
              (sel.subcategory === null || (sel.subcategory !== null && sel.subcategory + 1 === item.tier)),
          );
        }),
      ]);
      dispatch(
        credentialingDashboardExpiringActions.setFilterChips({
          ...chips,
          tiersSelected: [
            ...daySelection.map(item => ({
              label: item.value,
              enableDelete: item.value !== 'All' ? true : false,
              value: item,
            })),
          ],
        }),
      );
    } else {
      setTableData([...flattenedData]);
      dispatch(
        credentialingDashboardExpiringActions.setFilterChips({
          ...chips,
          tiersSelected: [],
        }),
      );
    }
  };

  const sidePanelItems = [
    {
      title: 'Quick View',
      component: <div style={{ minWidth: 236 }} />,
    },
    { title: 'Advanced Filter', component: <div /> },
  ];
  useEffect(() => {
    dispatch(credentialingDashboardExpiringActions.credentialingWorkDeskExpiringData({ userId: 0 }));
    reset({
      ...defaultFilterValues,
    });

    //Set back to initial state when component un-mounted.
    return () => {
      dispatch(
        pipelineChoiceActions.setPipelineChoice({
          key: pipelineChoiceKeys.CRED_EXPIRING_DAYS,
          selection: [{ category: 5, subcategory: null, value: expiringCredentialsSubStatus.allExpiring }],
        }),
      );
      dispatch(credentialingDashboardExpiringActions.reset());
      dispatch(gridStateActions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(filter => onApplyFilter(filter))}>
          <HomePage
            loading={loading}
            title={t('Expiring Credentials')}
            stickIconArray={[3, 2]}
            dataVisualization={<ExpiringCredentialsPipeline />}
            filters={<ExpiringFilter onApplyFilter={onApplyFilter} />}
            table={<ExpiringCredentialsTable tableColumns={tableColumns} tableData={tableData} />}
            chips={<ExpiringDayChips chips={chips} onApplyFilter={onApplyFilter} />}
            sidePanelItems={sidePanelItems}
          />
          <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
        </form>
      </FormProvider>
    </>
  );
};
