export const getExpiringPipelineCounts = dataArray => {
  const today = [0, 0, 0];
  const tomorrow = [0, 0, 0];
  const seven = [0, 0, 0];
  const fourteen = [0, 0, 0];
  const thirty = [0, 0, 0];
  const all = [0, 0, 0];
  const onAssignment = [0, 0, 0];

  dataArray.forEach(item => {
    if (item.daysUntilExpiration === 0) today[item.tier - 1]++;
    else if (item.daysUntilExpiration === 1) tomorrow[item.tier - 1]++;
    if (item.daysUntilExpiration <= 7) seven[item.tier - 1]++;
    if (item.daysUntilExpiration <= 14) fourteen[item.tier - 1]++;
    if (item.daysUntilExpiration <= 30) thirty[item.tier - 1]++;
    if (item.statusAbbr === 'OA') onAssignment[item.tier - 1]++;
    all[item.tier - 1]++;
  });

  return {
    today: today,
    tomorrow: tomorrow,
    nextSevenDays: seven,
    nextFourteenDays: fourteen,
    nextThirtyDays: thirty,
    allExpiring: all,
    onAssignment: onAssignment,
  };
};
