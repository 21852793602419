import React, { useEffect } from 'react';
import { DetailsPage } from 'app/layout/pages/DetailsPage';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  FacilityUnitActions,
  FacilityUnitKey,
  FacilityUnitReducer,
} from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.redux';
import {
  selectNavToEdit,
  selectUnit,
} from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';
import { facilityUnitsSaga } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.saga';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { navigationStickActions } from '@AMIEWEB/GlobalNavigationMenu/NavigationStick.redux';
import { UnitDetailsBodyWrapper } from './UnitDetailsBodyWrapper';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { usePromiseTracker } from 'react-promise-tracker';
import { Grid, Skeleton } from 'amn-ui-core';
import { selectFacility } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { selectUser } from 'oidc/user.selectors';
import { setFacilityDetailsForOrder } from '@AMIEWEB/Order/OrderCreationForm/OrderCreationForm';
import { orderDataActions, orderDataReducer, orderDataSliceKey } from '@AMIEWEB/Order/Store/Order.redux';
import { IUnitEditProcessStep } from 'app/components/Order/Store/Order.redux';
import { CommonNoDataPage } from '@AMIEWEB/Common';
import { useTranslation } from 'react-i18next';
import { orderCreationActions } from '@AMIEWEB/Order/Store/OrderCreation.actions';
import { orderCreationReducer, orderCreationSliceKey } from '@AMIEWEB/Order/Store/OrderCreation.redux';
import { orderCreationSaga } from '@AMIEWEB/Order/Store/OrderCreation.saga';
import UnitDetailHeader from '@AMIEWEB/Order/OrderCreationForm/Components/UnitDetail/UnitDetailHeader';
import {
  FacilityContactSliceKey,
  FacilityContactsReducer,
} from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.redux';
import { facilityClientContactsSaga } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.saga';
import { unitPreferenceReducer, unitPreferenceSliceKey } from 'store/redux-store/unit-preferences/slice';
import { unitPreferenceSaga } from 'store/redux-store/unit-preferences/saga';
import { selectPreferenceChanged } from 'store/redux-store/unit-preferences/selectors';
import { EnableAppScroll } from 'app/layout/Layout';
import { Button } from 'amn-ui-core';
import { orderCreationNewActions } from '@AMIEWEB/NewOrder/Store/OrderCreation.actions';
import { useStyles } from './UnitLandingStyle';
import { useSelectedUnitDetails } from './Containers/unitHelper';
import { orderDataSaga } from '@AMIEWEB/Order/Store/Order.saga';
import { selectOrderDetailsOrderType } from '@AMIEWEB/Order/Store/Order.selectors';
import { orderCreationNewReducer, orderCreationNewSliceKey } from '@AMIEWEB/NewOrder/Store/OrderCreation.redux';
import { orderCreationNewSaga } from '@AMIEWEB/NewOrder/Store/OrderCreation.saga';
import { usePageLoadingTracker } from 'app-insights/appInsightsTracking';
import useDocumentTitle from 'utils/customHooks/useDocumentTitle';

export const UnitDetailsLandingPage = () => {
  const params = useParams<{ id: string; unitId: string }>();
  useDocumentTitle({ title: `UID ${params.unitId}` });
  usePageLoadingTracker(); // Must come after the document title is set
  const dispatch = useDispatch();
  useInjectSaga({ key: FacilityUnitKey, saga: facilityUnitsSaga });
  useInjectReducer({ key: FacilityUnitKey, reducer: FacilityUnitReducer });
  useInjectReducer({ key: orderCreationSliceKey, reducer: orderCreationReducer });
  useInjectSaga({ key: orderDataSliceKey, saga: orderDataSaga });
  useInjectReducer({ key: orderDataSliceKey, reducer: orderDataReducer });
  useInjectSaga({ key: orderCreationSliceKey, saga: orderCreationSaga });
  useInjectSaga({ key: FacilityContactSliceKey, saga: facilityClientContactsSaga });
  useInjectReducer({ key: FacilityContactSliceKey, reducer: FacilityContactsReducer });
  useInjectReducer({ key: unitPreferenceSliceKey, reducer: unitPreferenceReducer });
  useInjectSaga({ key: unitPreferenceSliceKey, saga: unitPreferenceSaga });
  useInjectReducer({ key: orderCreationNewSliceKey, reducer: orderCreationNewReducer });
  useInjectSaga({ key: orderCreationNewSliceKey, saga: orderCreationNewSaga });
  const { classes } = useStyles();

  const unitData = useSelector(selectUnit);
  const user = useSelector(selectUser);
  const facilityData = useSelector(selectFacility);
  const navToEdit = useSelector(selectNavToEdit);
  const history = useHistory();
  const preferenceChanged = useSelector(selectPreferenceChanged);
  const [shouldShowError, setShouldShowError] = React.useState(false);
  const { t } = useTranslation();
  const { promiseInProgress: isFacilityUnitDetailsLoading } = usePromiseTracker({
    area: 'get-facility-unit-details',
    delay: 0,
  });
  const { promiseInProgress: isFacilityWithFilterDetailsLoading } = usePromiseTracker({
    area: 'get-facility-details-with-filters',
    delay: 0,
  });
  const { promiseInProgress: isSkillSetDataLoading } = usePromiseTracker({
    area: 'get-skillset-for-unit',
    delay: 0,
  });
  const selectedUnitDetail = useSelectedUnitDetails();
  useEffect(() => {
    dispatch(navigationStickActions.setSelectedMenuByKey('facility'));
    if (parseInt(params?.unitId) !== unitData?.unitId) {
      dispatch(FacilityUnitActions.requestFacilityUnitDetails({ id: parseInt(params.unitId) }));
      dispatch(orderCreationActions.requestOrderCreationRequirementTypesDetailsAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, params.unitId, unitData?.unitId]);

  useEffect(() => {
    dispatch(FacilityUnitActions.getSkillSetsForUnits({ unitId: parseInt(params.unitId) }));
    dispatch(FacilityUnitActions.getLicenses({ id: parseInt(params.id) }));
    EnableAppScroll();
  }, []);
  const getBreadCrumbs = () => {
    return {
      items:
        isFacilityUnitDetailsLoading && isFacilityWithFilterDetailsLoading && isSkillSetDataLoading
          ? []
          : [
              {
                title: `All Facilities`,
                clickable: true,
              },
              {
                title: `FID ${params.id} `,
                clickable: true,
              },
              {
                title: `UID ${params.unitId}`,
                clickable: false,
              },
            ],
      onClick: (event, item) => {
        if (item.title.includes('FID')) {
          if (!preferenceChanged) {
            dispatch(FacilityUnitActions.setSelectedUnit(null));
          }
          history.push(`/facility/${params.id}`);
        } else if (item.title.includes('All')) {
          if (!preferenceChanged) {
            dispatch(FacilityUnitActions.setSelectedUnit(null));
            dispatch(facilityActions.setFacilityData(null));
          }
          history.push(`/search/facility`);
        }
      },
    };
  };
  const changeRoute = () => {
    history.push({
      pathname: `/facility/${unitData?.facilityId}/order/new`,
    });
  };

  const createOrderFromUnit = () => {
    const updatedUnit = selectedUnitDetail ?? unitData;
    dispatch(
      orderCreationNewActions.initOrderFromUnitPage({
        unitId: parseInt(params.unitId),
        updatedUnit: updatedUnit,
        changeRoute: changeRoute,
      }),
    );
  };

  useEffect(() => {
    if (!facilityData?.facilityResponse && navToEdit?.callingComponent) {
      dispatch(facilityActions.getFacilityDetailsWithOpenOrderCountAction({ id: parseInt(params.id) }));
    }
  }, [params.id, navToEdit]);

  useEffect(() => {
    if (
      facilityData?.facilityResponse &&
      facilityData?.facilityResponse?.facilityId === parseInt(params.id) &&
      navToEdit?.callingComponent
    ) {
      const facilityDataForUnit =
        facilityData.facilityResponse &&
        setFacilityDetailsForOrder(
          facilityData.facilityResponse,
          user.userInfo?.employeeId ? user.userInfo.employeeId : 0,
        );

      dispatch(
        orderDataActions.setUnitEditProcessStep({
          facilityId: facilityData.facilityId,
          facilityResponse: facilityData.facilityResponse,
          facilityDataForUnit: facilityDataForUnit,
          selectedNewUnitId: parseInt(params.unitId),
          selectedNewUnit: null,
          unitsData: null,
          readyToEdit: true,
          editMode: 'standardized',
          callingComponent: navToEdit.callingComponent,
        } as IUnitEditProcessStep),
      );
      dispatch(FacilityUnitActions.setNavToEdit(null));
      history.push(`/facility/${params.id}/unit/${params.unitId}/edit?mode=standardized&rember=-1`);
    }
  }, [facilityData, navToEdit]);

  useEffect(() => {
    if (unitData?.facilityId) {
      setShouldShowError(unitData.facilityId.toString() !== params.id);
    }
  }, [unitData?.facilityId]);

  return (
    <>
      {shouldShowError ? (
        <CommonNoDataPage message={t('facility.units.errors.noUnitInfoForFacilityId')} />
      ) : (
        <>
          {isFacilityUnitDetailsLoading && isFacilityWithFilterDetailsLoading && isSkillSetDataLoading && (
            <Grid marginTop={2.5} marginLeft={2} marginBottom={-1.5}>
              <Skeleton variant="rectangular" width={357} height={21} />
            </Grid>
          )}
          <DetailsPage
            head={<UnitDetailHeader />}
            titleActions={
              <Button
                variant="contained"
                color="primary"
                classes={{ containedPrimary: `${classes.standardizeButton} button` }}
                onClick={() => createOrderFromUnit()}
              >
                {`CREATE NEW ORDER`}
              </Button>
            }
            breadcrum={getBreadCrumbs()}
            children={<UnitDetailsBodyWrapper />}
            removeExtraTopSpace
          />
        </>
      )}
    </>
  );
};
