import { IPlacementStatus, IPlacementStatusRequirement } from 'app/models/Placement/PlacementDetails';
import React from 'react';
import { NextPlacementStatus } from '../../NextPlacementStatus/NextPlacementStatus';
import { isNegativeStatus, placementStatusOptions } from '../../NextPlacementStatus/StatusDefaults';
import _ from 'lodash';

interface WhatsNextProps {
  availablePlacementStatus: IPlacementStatus[];
  failedRequirements: IPlacementStatusRequirement | undefined;
  currentPlacementStatus: IPlacementStatus;
  onStatusSelected?: (status: IPlacementStatus) => void;
  loading?: boolean;
  inlineDense?: boolean;
}

export const WhatsNext = ({
  availablePlacementStatus,
  failedRequirements,
  onStatusSelected,
  currentPlacementStatus,
  loading = false,
  ...props
}: WhatsNextProps) => {
  return (
    <React.Fragment>
      <NextPlacementStatus
        loading={loading}
        statusList={availablePlacementStatus.filter(item => !isNegativeStatus(item))}
        failedRequirements={failedRequirements}
        onStatusSelected={onStatusSelected}
        complete={_.isEqual(currentPlacementStatus, placementStatusOptions.requestFileOut)}
        isCreatePlacement
        inlineDense={props.inlineDense}
      />
    </React.Fragment>
  );
};
