import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';

import { initialState, NavigationTypes } from './NavigationStick.redux';

const selectDomain = (state: RootState) => state?.navigationStick || initialState;

export const selectNavigationStick = createSelector([selectDomain], stick => stick);
export const selectNavigation = createSelector([selectDomain], option => option.selectedMenu);
export const selectNavigationSubMenu = createSelector([selectDomain], option => option.selectedSubMenu);
export const selectNavigationMenu = createSelector([selectDomain], option => option.navigationMenu);

export const navigationHelpers = createSelector([selectDomain], option => {
  return {
    getNavigationMenuByKey: (key: NavigationTypes) => option.navigationMenu.find(item => item.key === key),
  };
});
