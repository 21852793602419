import { Grid, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { Banner } from 'app/components/Common/Banner/CustomBanner';
import { IPlacementStatus, IPlacementStatusSuccessType } from 'app/models/Placement/PlacementDetails';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { selectAutomationStatus } from 'app/components/Order/OrderDetails/OrderPreferences/store/OrderPreference.selector';
import { useSelector } from 'react-redux';
import Info from 'app/assets/images/Order/Info.svg';
import {
  isArchivePlacement,
  isRFO,
  placementStatusOptions,
} from 'app/components/Placement/NextPlacementStatus/StatusDefaults';
import { useHistory } from 'react-router';
import { useReadOnly } from 'oidc/userRoles';
import { isInstanceOfExtensionStatus } from '../PlacementDetails/PlacementTabPanel/PlacementSummaryTab/helper';

const useStyles = makeStyles()(theme => ({
  dialogContainer: {
    width: '500px',
    maxWidth: '500px',
  },
}));

interface StatusChangeConfirmDialogProps {
  status: IPlacementStatus;
  open: boolean;
  submitting?: boolean;
  onClose: () => void;
  onSubmit: (status: IPlacementStatus) => void;
  error?: boolean | null;
  titleRenderer?: () => string;
  contentRenderer?: () => JSX.Element;
  type?: IPlacementStatusSuccessType;
  closeButton?: boolean;
  [key: string]: any;
  isModificationError?: boolean;
  errorMessage?: string;
}

export const StatusChangeConfirmDialog = ({
  status,
  open,
  submitting,
  onClose,
  onSubmit,
  error,
  titleRenderer,
  contentRenderer,
  type,
  closeButton = true,
  isModificationError,
  errorMessage = '',
  ...props
}: StatusChangeConfirmDialogProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const isSubmissionAutomationEnabled = useSelector(selectAutomationStatus);
  const history = useHistory();
  const { readOnly } = useReadOnly();

  const refreshPage = () => {
    history.go(0);
  };

  /**
   * Title in the confirmation dialog for changing the status
   * Different messages are displayed for different statuses, like "Mark as Sent"
   */
  const title = React.useMemo(() => {
    if (titleRenderer) return titleRenderer();
    else if (isModificationError) {
      return t('placement.profile.placementStatusUpdate.endDateModError');
    } else {
      let titleText = t('placement.profile.placementStatusUpdate.title', {
        activityStatus: isInstanceOfExtensionStatus(status) ? status.extensionStatus : status.activityStatus,
      });
      switch (status.activityTypeId) {
        case 1:
          switch (status.activityStatusId) {
            case 3:
              titleText = t('placement.profile.placementStatusUpdate.SentNoPacketTitle');
              break;
            default:
              break;
          }
          break;
        case 101:
          switch (status.activityStatusId) {
            case 102:
              titleText = t('placement.profile.placementStatusUpdate.archiveTitle');
              break;
            case 105:
              titleText = t('placement.profile.placementStatusUpdate.setToBookedTitle');
              break;
          }
          break;
        default:
          break;
      }
      return titleText;
    }
  }, [status, t, titleRenderer, type, isModificationError]);

  const dialogButtonText = React.useMemo(() => {
    let text: string = t('placement.profile.placementStatusUpdate.confirmButton');
    if (submitting) {
      if (
        type === IPlacementStatusSuccessType.placementConflict ||
        type === IPlacementStatusSuccessType.placementStatusUpdateStepsError
      )
        text = 'Refreshing';
      else text = t('placement.profile.placementStatusUpdate.inSubmissionButton');
    } else if (isArchivePlacement(status)) text = t('placement.profile.placementStatusUpdate.archiveButton');
    else if (
      type === IPlacementStatusSuccessType.placementConflict ||
      type === IPlacementStatusSuccessType.placementStatusUpdateStepsError
    )
      text = 'Refresh';
    else if (isModificationError) {
      text = 'OK';
    }
    return text;
  }, [status, submitting, t, type, isModificationError]);

  /**
   * Content in the confirmation dialog for changing the status.
   * Different messages are displayed for different statuses, like "Mark as Sent"
   */
  const dialogContent = React.useMemo(() => {
    let confirmationLineOne: string[] = [];
    let confirmationLineTwo: string[] = [];

    if (contentRenderer) return contentRenderer();
    else if (isModificationError) {
      confirmationLineOne =
        type === IPlacementStatusSuccessType.bookingToNegativeStatusError
          ? [t('placement.profile.placementStatusUpdate.bookingToNegativeStatusError')]
          : type === IPlacementStatusSuccessType.onAssignmentToNegativeStatusEndDateError
          ? [t('placement.profile.placementStatusUpdate.onAssignmentToNegativeStatusEndDateError')]
          : [t('placement.profile.placementStatusUpdate.onAssignmentToNegativeStatusStartDateError')];
    } else {
      confirmationLineOne = isInstanceOfExtensionStatus(status)
        ? [
            t('placement.profile.placementStatusUpdate.confirmationExtLineOne', {
              activityStatus: status.extensionStatus,
            }),
          ]
        : [
            t('placement.profile.placementStatusUpdate.confirmationLineOne', {
              activityStatus: status.activityStatus,
            }),
          ];
      confirmationLineTwo = [t('placement.profile.placementStatusUpdate.confirmationLineTwo')];
      switch (status.activityTypeId) {
        case 1:
          switch (status.activityStatusId) {
            case 3:
              confirmationLineOne = [t('placement.profile.placementStatusUpdate.confirmationSentNoPacketLineOne')];
              break;
          }
          break;
        case 101:
          switch (status.activityStatusId) {
            case 102:
              confirmationLineOne = [t('placement.profile.placementStatusUpdate.confirmationArchiveLineOne')];
              confirmationLineTwo = [];
              break;
            case 105:
              confirmationLineOne = [
                t('placement.profile.placementStatusUpdate.confirmationLineOne', {
                  activityStatus: placementStatusOptions.booked.activityStatus,
                }),
              ];
              break;
          }
          break;
        default:
          break;
      }
    }
    return (
      <Grid container spacing={2}>
        {isRFO(status) && isSubmissionAutomationEnabled ? (
          <div style={{ padding: '1rem', background: '#E8F4FD', lineHeight: '1.1', marginTop: '0.5rem' }}>
            <Grid container spacing={1} alignItems="flex-start" wrap="nowrap">
              <Grid item>
                <img src={Info} style={{ width: '24px', margin: '0 0.2rem' }} alt="Info" />
              </Grid>
              <Grid item>
                <span style={{ fontSize: '14px', color: '#0E3C61', fontWeight: 500 }}>
                  {t('placement.profile.placementStatusUpdate.automationStatusMessage', {
                    activityStatus: status.activityStatus,
                  })}
                </span>
              </Grid>
            </Grid>
          </div>
        ) : (
          <></>
        )}
        {confirmationLineOne.map(line => (
          <Grid key={line} item xs={12}>
            <Typography variant="body1">{line}</Typography>
          </Grid>
        ))}
        {confirmationLineTwo.map(line => (
          <Grid key={line} item xs={12} style={{ fontWeight: 500 }}>
            <Typography variant="body1">{line}</Typography>
          </Grid>
        ))}
      </Grid>
    );
  }, [isSubmissionAutomationEnabled, contentRenderer, status, t, type, isModificationError]);

  const bannerMessage = React.useMemo(() => {
    if (type === IPlacementStatusSuccessType.placementConflict) {
      return t('placement.profile.conflict');
    }

    if (type === IPlacementStatusSuccessType.placementStatusUpdateStepsError) {
      return t('placement.profile.placementStatusUpdate.placementStatusUpdateStepsError');
    }

    const errorTypesWithMessage = [
      IPlacementStatusSuccessType.missingStartDateEndDateOrNumberOfWeeks,
      IPlacementStatusSuccessType.numberofWeeksMismatch,
      IPlacementStatusSuccessType.BookedStatusPermOrderError,
      IPlacementStatusSuccessType.shiftError,
      IPlacementStatusSuccessType.placementVerificationScreenError,
      IPlacementStatusSuccessType.confirmationStatusError,
      IPlacementStatusSuccessType.contractStatusError,
      IPlacementStatusSuccessType.payCycleError,
      IPlacementStatusSuccessType.billRateError,
      IPlacementStatusSuccessType.housingTypeError,
      IPlacementStatusSuccessType.payRateError,
      IPlacementStatusSuccessType.missingFurnitureHousingInsurancePayRateBillRateError,
      IPlacementStatusSuccessType.travelAllowanceError,
      IPlacementStatusSuccessType.optionsScreenError,
      IPlacementStatusSuccessType.checklistsError,
      IPlacementStatusSuccessType.onAssignmentStartDateError,
    ];

    return errorTypesWithMessage.includes(type) ? errorMessage : t('placement.profile.placementStatusUpdate.error');
  }, [type, errorMessage, t]);

  return (
    <GenericDialog
      variant="white"
      fullWidth
      classes={{
        paper: classes.dialogContainer,
      }}
      open={open}
      draggable
      disableEscapeKeyDown={submitting}
      dialogTitleProps={{
        text: title,
        closeButton: !submitting && closeButton && !isModificationError,
      }}
      dialogActions={[
        {
          onClick: onClose,
          text: isModificationError ? '' : t('placement.profile.placementStatusUpdate.cancelButton'),
          variant: 'text',
          color: 'tertiary',
          disabled: isModificationError ? true : submitting,
        },
        type === IPlacementStatusSuccessType.placementConflict ||
        type === IPlacementStatusSuccessType.placementStatusUpdateStepsError
          ? {
              onClick: refreshPage,
              text: dialogButtonText,
              variant: 'contained',
              loading: submitting,
              disabled: submitting,
            }
          : isModificationError
          ? {
              // When mod error occurs, close modal
              onClick: onClose,
              text: dialogButtonText,
              variant: 'contained',
              loading: submitting,
              disabled: submitting,
            }
          : {
              // Submit status change
              onClick: () => onSubmit(status),
              text: dialogButtonText,
              variant: 'contained',
              loading: submitting,
              disabled: readOnly || submitting,
              tooltipProps: readOnly
                ? {
                    tooltipContent: t('global.readOnlyTooltip'),
                  }
                : undefined,
            },
      ]}
      onClose={(e, reason) => {
        if (reason === 'backdropClick' && submitting) return;
        onClose?.();
      }}
    >
      <Grid container spacing={3}>
        {error && !isModificationError && (
          <Grid item xs={12}>
            <Banner message={bannerMessage} severity="error" />
          </Grid>
        )}
        <Grid item xs={12}>
          {dialogContent}
        </Grid>
      </Grid>
    </GenericDialog>
  );
};
