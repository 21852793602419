import { Grid } from 'amn-ui-core';
import { XGrid, XGridToolbar } from '@AMIEWEB/Common';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  flattenTravelerPayrollData,
  getTravelerPayrollColumns,
  newTravelerPayrollRow,
  valCharLimit,
  valNumeric,
  valNumericStartsWithSymbol,
} from './TravelerPayrollHelper';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { candidateDetailsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { IXGridBulkActionButton } from '@AMIEWEB/Common/XGrid/Toolbar/XGridToolbar';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { selectGridSelection } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.selector';
import { TaxInfoDeleteDialog } from '../Helper/TaxInfoDeleteDialog';
import { IDeleteTravelerPayroll } from 'app/models/Candidate/CandidateProfile';
import { selectUser } from 'oidc/user.selectors';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { ITaxData } from 'app/models/Candidate/CandidateProfile';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';
import { missingField } from 'app/constants';
import { lookupSelector } from 'store/redux-store/lookup/lookup.selectors';

const useStyles = makeStyles()({
  container: {
    height: '100%',
    width: '100%',
  },
  grid: {
    width: '100%',
    minHeight: '275px',
  },
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
  },
  GridWithBorder: {
    width: '100%',
    border: '1px solid #ececec',
    borderRadius: '4px',
    padding: '10px 25px 25px 22px',
  },
  root: {
    '& .MuiGrid-root': {
      margin: 0,
      width: 'auto',
    },
    '& .MuiGrid-root > .MuiGrid-item': {
      padding: 0,
    },
    '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      border: 'none !important',
      outline: 'solid #006FB9 1px',
      outlineOffset: '-1px',
    },
    '& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      padding: '0px',
    },
    '& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell:hover': {
      backgroundColor: '#fff !important',
    },
    '& .MuiInputBase-root-MuiDataGrid-editInputCell': {
      padding: '1px 0',
    },
    '& .MuiInputBase-input': {
      border: 'none !important',
      outline: 'none !important',
      backgroundColor: '#fff !important',
      borderRadius: 0,
      height: '1.737em',
      fontSize: '14px',
    },
    '& .MuiOutlinedInput-root': {
      border: 'none !important',
      outline: 'none !important',
      backgroundColor: '#fff !important',
      borderRadius: 0,
      padding: '0px',
    },
    '& .MuiDataGrid-cell--editable': {
      backgroundColor: '#fff !important',
      fontSize: '14px',
    },
    '&.MuiBox-root': {
      outline: 'solid red 1px',
    },
  },
});

export const TravelerPayrollXGrid = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [columns, setColumns] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const { selectedData } = useSelector(selectGridSelection);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [candidateProfileDetails, setCandidateProfileDetails] = useState<any>(null);
  const [maritalStatusOptions, setMaritalStatusOptions] = React.useState<any>([]);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const lookups = useSelector(lookupSelector);
  const hasDeleteAccess = useMemo(() => {
    return Authorized(
      [userRoles.payrollSpecialist, userRoles.customerSupport_Leadership, userRoles.customerSupport_TeamMember],
      user.userInfo,
    );
  }, [user.userInfo]);
  const hasAddEditAccess = useMemo(() => {
    return Authorized([userRoles.payrollSpecialist], user.userInfo);
  }, [user.userInfo]);
  const getDropdownLookupOptions = () => {
    const maritalStatusOptions: any[] = lookups?.maritalStatus?.map(item => {
      return {
        id: item.value,
        description: item.name,
      };
    });

    setMaritalStatusOptions(maritalStatusOptions);
  };

  useEffect(() => {
    getDropdownLookupOptions();
  }, [lookups]);

  useEffect(() => {
    setCandidateProfileDetails(candidateDetails);
    setColumns(getTravelerPayrollColumns({ updateRow, hasAddEditAccess, maritalStatusOptions }));
    setRows(flattenTravelerPayrollData(candidateDetails?.taxData));
  }, [candidateDetails, maritalStatusOptions]);

  const enable = useCallback(
    key => {
      switch (key) {
        case 'deletePayroll':
          return selectedData.length === 0;
        default:
          return false;
      }
    },
    [selectedData.length],
  );

  const toobarRightActions = useMemo<IXGridBulkActionButton[]>(() => {
    return [
      {
        key: 'deletePayroll',
        Icon: DeleteOutlinedIcon,
        disabled: hasDeleteAccess ? enable('deletePayroll') : true,
        onClick: () => setOpenDeleteDialog(true),
        tooltipProps: {
          tooltipContent: t('candidate.personalInfoTabLabels.candidatePayrollDeleteToolTip'),
        },
      },
    ];
  }, [enable, rows]);

  const deleteTavelerPayroll = () => {
    if (selectedData && selectedData.length) {
      setOpenDeleteDialog(false);
      let selectedIds = [];
      let removedIds = [];

      selectedData.forEach(rowId => {
        if (rows.findIndex(x => x.rowID === rowId && !x.isNew) > -1) {
          selectedIds.push(rowId);
        } else {
          removedIds.push(rowId);
        }
      });

      if (selectedIds && selectedIds.length > 0) {
        const payload: IDeleteTravelerPayroll = {
          candidateId: candidateProfileDetails.travelerId,
          taxDataIds: selectedIds,
          currentEmployeeId: user?.userInfo?.employeeId || 0,
          currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
        };

        dispatch(candidateDetailActions.deleteTravelerPayroll(payload));
      } else if (removedIds) {
        let gridRows = rows.filter(x => removedIds.indexOf(x.rowID) < 0);
        setRows(gridRows);
      }
    }
  };

  const handleNavigation = () => {
    const newRow: any = newTravelerPayrollRow(rows);
    newRow.isNew = true;
    let gridRows = [...rows];
    gridRows.unshift(newRow);
    setRows(gridRows);
  };

  const updateRow = (rowId: any, row: ITaxData) => {
    const payload = preparePayload(row);

    if (!payload.type || payload.type === missingField || !valCharLimit(payload.type, 255)) {
      return;
    }

    if (payload.allowances && !valNumeric(payload.allowances)) {
      return;
    }

    if (payload.addWithHoldings && !valNumericStartsWithSymbol(payload.addWithHoldings)) {
      return;
    }

    if (payload.otherWithHoldings && !valNumericStartsWithSymbol(payload.otherWithHoldings)) {
      return;
    }

    if (!payload.effectiveDate) {
      return;
    }

    dispatch(candidateDetailActions.updateTaxData(payload));
  };

  const preparePayload = row => {
    const payload: ITaxData = {
      candidateId: candidateProfileDetails.travelerId,
      taxDataID: row.isNew ? null : row.rowID,
      type: row.type,
      allowances: row.allowances === missingField ? null : row.allowances,
      maritalStatusID: !row.maritalStatusId ? null : row.maritalStatusId,
      maritalStatus: row.maritialStatus === missingField ? null : row.maritialStatus,
      exemptStatus: row.exemptStatus === missingField ? null : row.exemptStatus,
      addWithHoldings: row.additionalWithholdings === missingField ? null : row.additionalWithholdings,
      otherWithHoldings: row.otherWithholdings === missingField ? null : row.otherWithholdings,
      effectiveDate:
        !row.effectiveDate || row.effectiveDate === missingField
          ? null
          : moment(row.effectiveDate).format('YYYY-MM-DDTHH:mm:ss'),
      currentEmployeeId: user.userInfo?.employeeId || 8410,
      currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
    };
    return payload;
  };

  const grid = React.useMemo(
    () => (
      <Grid className={classes.root}>
        <XGrid
          title={t('candidate.personalInfoTabLabels.candidatePayroll')}
          rows={rows}
          columns={columns}
          getRowId={model => model.rowID}
          editMode="row"
          experimentalFeatures={{ columnGrouping: true, newEditingApi: true }}
          rowHeight={60}
          headerHeight={70}
          hideFooter={true}
          disableSelectionOnClick={true}
          checkboxSelection
          components={{
            Toolbar: XGridToolbar,
          }}
          className={classes.grid}
          toolbarProps={{
            rightActions: toobarRightActions,
            refreshButton: true,
            resetButton: true,
            resetColumns: () =>
              setColumns(getTravelerPayrollColumns({ updateRow, hasAddEditAccess, maritalStatusOptions })),
            refreshGrid: () => {
              setColumns(getTravelerPayrollColumns({ updateRow, hasAddEditAccess, maritalStatusOptions }));
            },
            leftActions: [
              {
                title: 'Request',
                Icon: AddIcon,
                disabled: !hasAddEditAccess,
                onClick: handleNavigation,
              },
            ],
          }}
          onCellKeyDown={(params, events) => events.stopPropagation()}
          onCellDoubleClick={(params, events) => events.stopPropagation()}
        />
      </Grid>
    ),
    [rows, columns, toobarRightActions],
  );

  return (
    <div className={classes.container}>
      {grid}
      <TaxInfoDeleteDialog
        name={'Payroll'}
        openDeleteDialog={openDeleteDialog}
        onDeleteDialogClose={() => setOpenDeleteDialog(false)}
        deleteRecords={() => deleteTavelerPayroll()}
      />
    </div>
  );
};
