import React from 'react';
import { missingField } from 'app/constants';
import styledComponent from 'styled-components';
import { useTranslation } from 'react-i18next';

export const OrderInfoRateItem = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <div id="placement-order-info-rate">
      <RowDataDiv data-testid="order-info-contract-rate-item">
        {`${t('placement.profile.orderInformation.orderInfoRateItem.contractBillRate')}: `}
        {props.orderInformation?.contractBillRate ? ` $${props.orderInformation.contractBillRate}` : ` ${missingField}`}
      </RowDataDiv>
      <RowDataDiv data-testid="order-info-bill-rate-item">
        {`${t('placement.profile.orderInformation.orderInfoRateItem.orderBillRate')}: `}
        {props.orderInformation?.orderBillRate ? ` $${props.orderInformation.orderBillRate}` : ` ${missingField}`}
      </RowDataDiv>
      <RowDataDiv data-testid="order-info-vms-bill-rate-item">
        {`${t('placement.profile.orderInformation.orderInfoRateItem.vmsBillRate')}: `}
        {props.orderInformation?.vmsBillRate ? ` $${props.orderInformation.vmsBillRate}` : ` ${missingField}`}
      </RowDataDiv>
    </div>
  );
};

const RowDataDiv = styledComponent.div`
  font-size: 14px;
  white-space: pre-wrap;
`;
