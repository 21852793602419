import React, { ReactNode, useEffect, useState } from 'react';
import { Box, IconButton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { CustomTooltip } from '@AMIEWEB/Common';

const useStyles = makeStyles()(theme => ({
  container: {
    border: `1px solid ${theme.palette.system.paleGray}`,
    borderRadius: '4px 4px 4px 4px',
    background: theme.palette.framework.system.white,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  titleBar: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.system.paleGray}`,
    background: theme.palette.system.offWhite,
    color: theme.palette.framework.system.charcoal,
    fontSize: 16,
    padding: '12px 12px 12px 12px',
    fontWeight: 500,
  },
  title: {
    paddingLeft: '12px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  clickable: {
    cursor: 'pointer !important',
    '&:hover': {
      borderRadius: '50%',
    },
  },
  children: {
    padding: '12px 0px 12px 12px',
  },
  icon: {
    position: 'relative',
    display: 'inline-block',
    '& img': {
      transition: 'filter 0.3s ease',
      filter: 'grayscale(100%)',
    },
    '&$iconHovered img': {
      filter: 'none',
    },
  },
  onHover: {
    '&:hover': {
      color: theme.palette.system.navyBlue,
    },
  },
  onHoverUnderline: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.system.navyBlue,
      textDecoration: 'underline',
    },
  },
  titleHovered: {
    backgroundColor: theme.palette.system.dimFadedBlack,
  },
}));

export const CandidateContainer = ({
  icon,
  title,
  rightIcon,
  rightEditIcon,
  iconToolTip,
  editIconToolTip,
  onClick,
  onClickEditIcon,
  onClickAll,
  children,
  enableLink,
}: {
  icon?: ReactNode;
  title: string;
  rightIcon?: ReactNode;
  rightEditIcon?: ReactNode;
  iconToolTip?: string;
  editIconToolTip?: string;
  onClick?: any;
  onClickEditIcon?: any;
  onClickAll?: any;
  enableLink?: boolean;
  children?;
}) => {
  const { classes, cx } = useStyles();
  const [isHovered, setIsHovered] = React.useState(false);

  const handleRightIconClick = () => {
    if (onClick) {
      onClick();
    }
  };
  const handleRightEditIconClick = () => {
    if (onClickEditIcon) {
      onClickEditIcon();
    }
  };

  const handleAllClick = () => {
    if (onClickAll) {
      onClickAll();
    }
  };

  return (
    <Box className={classes.container}>
      <Box
        className={`${classes.titleBar} ${enableLink && isHovered && classes.titleHovered && classes.onHover}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Box className={`${classes.titleContainer} ${enableLink && classes.onHover}`}>
          <Box className={`${classes.icon}`}>{icon}</Box>
          <Box className={`${classes.title} ${enableLink && classes.onHoverUnderline}`} onClick={handleAllClick}>
            {title}
          </Box>
        </Box>
        <Box display="flex" id="right-icon">
          {rightEditIcon && (
            <CustomTooltip placement="bottom" arrow={true} tooltipContent={editIconToolTip}>
              <Box
                onClick={event => (rightEditIcon ? handleRightEditIconClick() : event.stopPropagation())}
                className={cx(classes.clickable, classes.icon, classes.onHover)}
                sx={{ visibility: 'visible' }}
              >
                <IconButton color="secondary" size="medium">
                  {rightEditIcon}
                </IconButton>
              </Box>
            </CustomTooltip>
          )}
          {rightIcon && (
            <CustomTooltip placement="bottom" arrow={true} tooltipContent={iconToolTip}>
              <Box
                onClick={event => (rightIcon ? handleRightIconClick() : event.stopPropagation())}
                className={cx(classes.clickable, classes.icon, classes.onHover)}
                sx={{ visibility: 'visible' }}
                marginLeft="12px"
              >
                <IconButton color="secondary" size="medium">
                  {rightIcon}
                </IconButton>
              </Box>
            </CustomTooltip>
          )}
        </Box>
      </Box>
      <Box className={classes.children}>{children}</Box>
    </Box>
  );
};
