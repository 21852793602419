import React from 'react';
import { Box, Typography } from '@mui/material';
import { Theme, useTheme } from 'amn-ui-core';
import { useDropzone, FileRejection, DropEvent, DropzoneOptions } from 'react-dropzone';
import { makeStyles } from 'tss-react/mui';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { JsxElement } from 'typescript';

export interface IDocumentDropzoneProps extends Omit<DropzoneOptions, 'onDrop' | 'onDropRejected'> {
  compressed?: boolean;
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[], event?: DropEvent) => void;
  onDropRejected?: (fileRejections: FileRejection[], event?: DropEvent) => void;
  message?: string | JSX.Element;
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.grey[50],
    '&:hover': {
      backgroundColor: `${theme.palette.info.light}26`,
    },
  },
  uploadIcon: {
    margin: 'auto',
    height: '64px',
    width: '100%',
    color: theme.palette.secondary.main,
  },
  uploadIconCompressed: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: '26px',
    height: '64px',
    width: 'auto',
    color: theme.palette.secondary.main,
  },
  note: {
    '&.MuiTypography-root': {
      font: 'italic normal 300 12px/11px Roboto',
      textAlign: 'center',
      margin: 'auto',
      marginTop: '14px',
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      '&.MuiTypography-root': {
        display: 'none',
      },
    },
  },
  noteLower: {
    '&.MuiTypography-root': {
      fontSize: '12px',
      margin: 'auto',
      marginTop: '34px',
      width: '100%',
      marginLeft: '9px',
    },
  },
  noteLowerCompressed: {
    '&.MuiTypography-root': {
      font: 'italic normal 300 12px/11px Roboto',
      textAlign: 'center',
      margin: 'auto',
      marginTop: '14px',
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      '&.MuiTypography-root': {
        display: 'none',
      },
    },
  },
  textOne: {
    '&.MuiTypography-root': {
      textAlign: 'center',
      margin: 'auto',
      width: '100%',
      whiteSpace: 'nowrap',
    },
  },
  textOneCompressed: {
    '&.MuiTypography-root': {
      display: 'flex',
      textAlign: 'center',
      margin: 'auto',
      width: '100%',
      whiteSpace: 'nowrap',
    },
  },
  textTwo: {
    '&.MuiTypography-root': {
      whiteSpace: 'nowrap',
      textAlign: 'center',
      margin: 'auto',
      width: '100 %',
      color: theme.palette.secondary.main,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  textTwoCompressed: {
    '&.MuiTypography-root': {
      whiteSpace: 'nowrap',
      textAlign: 'center',
      margin: 'auto',
      width: '100 %',
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('sm')]: {
      '&.MuiTypography-root': {
        marginLeft: '6px',
      },
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export const DocumentDropzone: React.FC<IDocumentDropzoneProps> = ({
  message = 'Note: Maximum file upload size is 5120KB (5MB)',
  onDrop,
  onDropRejected = rejected => {},
  disabled,
  accept,
  maxSize = 5000000,
  minSize = 0,
  maxFiles = 5,
  ...props
}) => {
  const { classes: styles } = useStyles();
  const theme = useTheme();

  const _onDrop = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) =>
    onDrop(acceptedFiles, fileRejections);
  const _onDropRejected = (fileRejections: FileRejection[], event: DropEvent) => onDropRejected(fileRejections);

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop: _onDrop,
    onDropRejected: _onDropRejected,
    maxSize,
    minSize,
    maxFiles,
    disabled,
    ...props,
  });

  return (
    <Box
      border={`1px dashed ${theme.palette.secondary.main}`}
      borderRadius="5px"
      display="flex"
      justifyContent="center"
      width="100%"
      minHeight={props.compressed ? { xs: '120px', sm: '96px' } : '200px'}
      className={styles.container}
      {...getRootProps({
        onClick: event => {
          if (disabled) {
            event.stopPropagation();
          }
        },
      })}
    >
      <input {...getInputProps()} />
      <Box margin="24px" justifyContent="center" display="flex" flexWrap="wrap" style={{ margin: '24px 6px' }}>
        <CloudUploadOutlinedIcon className={props.compressed ? styles.uploadIconCompressed : styles.uploadIcon} />
        <Box marginTop="auto" marginBottom="auto">
          <Box display={props.compressed ? { xs: 'block', sm: 'flex' } : 'block'}>
            <Typography className={props.compressed ? styles.textOneCompressed : styles.textOne}>
              Drop files here to upload or
            </Typography>
            <Typography className={props.compressed ? styles.textTwoCompressed : styles.textTwo}>
              Browse to Upload
            </Typography>
          </Box>
          {props.compressed && <Typography className={styles.note}>{message}</Typography>}
        </Box>
        <Typography className={props.compressed ? styles.noteLowerCompressed : styles.noteLower}>{message}</Typography>
      </Box>
    </Box>
  );
};
