import { ModalAppBar, ModalTitle } from '@AMIEWEB/Common';
import { ModalClose } from '@AMIEWEB/Notification/styles';
import { Box, Divider, Modal, Toolbar, Typography } from 'amn-ui-core';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import NotesIcon from 'app/assets/images/Notification/notes.svg';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles()(theme => ({
  key: {
    color: theme.palette.framework.system.shadeyGrey,
    fontSize: '1rem',
    paddingBottom: '2%',
  },
  value: {
    color: theme.palette.framework.system.doveGray,
    paddingRight: '2%',
    fontSize: '1rem',
    paddingBottom: '2%',
  },
  title: {
    color: theme.palette.framework.system.charcoal,
    fontSize: '16px',
    fontWeight: 600,
    paddingLeft: '23px',
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  toolbar: {
    paddingBottom: '2%',
  },
  notes: {
    width: '200px',
    display: 'flex',
    cursor: 'pointer',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: '750px',
    outline: 'none',
    borderRadius: '5px',
    boxShadow: `0px 0px 2px 2px ${theme.palette.framework.system.tertiaryGrey}`,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  divider: {
    marginTop: '1%',
    width: '94%',
  },
  subject: {
    fontSize: '18px',
    paddingBottom: '5px',
  },
  iframe: {
    maxHeight: '650px',
    minHeight: '260px',
    fontFamily: 'Roboto',
    width: '100%',
    fontSize: '14px',
    color: theme.palette.framework.system.doveGray,
  },
  scroll: {
    color: theme.palette.framework.system.doveGray,
    paddingRight: '2%',
    fontSize: '1rem',
    paddingBottom: '2%',
    overflowY: 'auto',
    overflowX: 'auto',
    width: '95%',
    height: '35vh',
    margin: '2%',
  },
  closeIcon: {
    left: '560px',
  },
  headerTitle: {
    marginTop: '15px',
  },
  notesIcon: {
    marginLeft: '10px',
  },
}));

export const EmailModal = props => {
  const { data, onClose } = props;
  const { classes } = useStyles();
  return (
    <Modal className={classes.modal} open>
      <div className={classes.paper}>
        <ModalAppBar id="draggableArea" position="static">
          <Toolbar disableGutters className={classes.toolbar}>
            <img src={NotesIcon} alt="Notes" className={classes.notesIcon} />
            <ModalTitle variant="h6" className={classes.headerTitle}>
              {data.title}
            </ModalTitle>
            <ModalClose onClick={onClose} color="default" aria-label="upload picture" className={classes.closeIcon}>
              <CloseIcon onClick={onClose} />
            </ModalClose>
          </Toolbar>
        </ModalAppBar>
        <Box m={3}>
          {data.title === 'Email Notes' && (
            <Box marginLeft={'20px'}>
              <Typography className={classes.subject}> {`Re: ${data.subject}`}</Typography>
              <Typography>
                {data?.tos && <span className={classes.key}>{`To: `}</span>}
                {data?.tos && <span className={classes.value}>{data?.tos.map(data => data.email).join(' ; ')}</span>}
              </Typography>
              <Typography>
                {data?.ccs && data?.ccs?.length > 0 && <span className={classes.key}>{`CC: `}</span>}
                {data?.ccs?.length > 0 && data?.ccs && (
                  <span className={classes.value}>{data?.ccs.map(data => data.email).join(' ; ')}</span>
                )}
                {data?.bccs?.length > 0 && data?.bccs && <span className={classes.key}>{`BCC: `}</span>}
                {data?.bccs?.length > 0 && data?.bccs && (
                  <span className={classes.value}>{data?.bccs.map(data => data.email).join(' ; ')}</span>
                )}
              </Typography>
              <Divider className={classes.divider} />
            </Box>
          )}
          <div className={classes.scroll}>
            <iframe title={data.title} className={classes.iframe} srcDoc={data?.notes}></iframe>
          </div>
        </Box>
      </div>
    </Modal>
  );
};
