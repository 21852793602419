import React from 'react';
import { Grid, Typography, Box } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import styledComponent from 'styled-components';
import Attachment from 'app/assets/images/Notification/Inbox/Attachment2x.png';
import MessageIcon from 'app/assets/images/messageIcon.svg';
import { CapitalizeEveryFirstLetter } from 'utils/string/string';
import { TextWithEllipses } from '../../Common/StringHelper';
import { formatPhoneNumber } from 'app/helpers/phoneHelpers';
import { theme } from 'styles/global-styles';

const useStyles = makeStyles()(() => ({
  gridContainer: {
    height: 'auto',
    width: '306px',
    opacity: 1,
  },
  statusIcon: {
    marginTop: '0.5rem',
    opacity: 1,
  },
  flexContainer: {
    width: '250px',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  name: {
    color: theme.palette.framework.system.darkCharcoalGray,
    paddingRight: '5px',
    fontSize: '14px',
    fontWeight: 500,
  },
  id: {
    color:  theme.palette.framework.system.darkCharcoalGray,
    fontSize: '14px',
    fontWeight: 500,
  },
  attachmentIcon: {
    width: '23px',
    height: '23px',
    transform: 'rotate(-45deg)',
  },
  messageIcon: {
    width: '36px',
    height: '36px',
    '& .images-message-a': {
      fill: '#5426df',
    },
  },
}));

export const TextContent = styledComponent.div`
  font-size: 12px;
  color: ${theme.palette.framework.system.charcoal};
  text-align: start;
  overflow: hidden;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

const HeaderIcon = styledComponent.div`
  position: absolute;
  right: 20px;
  top: 5px;
  color: ${theme.palette.framework.system.charcoal};
`;

export const MessagePopup = props => {
  const { name, travelerId, body, attachments } = props;
  const messageBody = body.replaceAll(/\n+/g, ' ');
  const { classes } = useStyles();

  const MAX_NAME_LENGTH = 25;

  const SubHeader = styledComponent(Typography)`
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    color: ${theme.palette.framework.system.doveGray};
    opacity: 1;
    text-overflow: ellipsis;
    opacity: 1;
    margin-top: 3px;
  `;

  return (
    <>
      <div role="button" tabIndex={-3}>
        <Grid container direction="row" className={classes.gridContainer}>
          <Grid item xs={2}>
            <div className={classes.statusIcon}>
              <img src={MessageIcon} className={classes.messageIcon} alt="MessageIcon" />
            </div>
          </Grid>

          <Grid item xs={10} className={classes.flexContainer}>
            <Box display={'flex'}>
              {props.name ? (
                <>
                  <Typography className={classes.name}>
                    {props.name && props.name.length > 25
                      ? `${CapitalizeEveryFirstLetter(name.substring(0, MAX_NAME_LENGTH))}... `
                      : CapitalizeEveryFirstLetter(name)}{' '}
                  </Typography>
                  <Typography className={classes.id}>{`(${travelerId})`}</Typography>
                </>
              ) : (
                <Typography className={classes.name}>{formatPhoneNumber(props.phoneNumber)}</Typography>
              )}

              {
                <HeaderIcon>
                  {attachments && attachments.length > 0 && (
                    <img className={classes.attachmentIcon} src={Attachment} alt={`Attachment`} />
                  )}
                </HeaderIcon>
              }
            </Box>
            <SubHeader>
              <TextContent>{messageBody}</TextContent>
            </SubHeader>
            {messageBody?.length === 0 && attachments && (
              <SubHeader>{`Attachment${attachments.length > 1 ? `s` : ``} Received`}</SubHeader>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};
