import { Grid, Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import FilterIcon from '../../../../assets/images/IconImages/FilterIcon.svg';
import ListIcon from '../../../../assets/images/IconImages/ListOutline.svg';
import FunnelIcon from '../../../../assets/images/IconImages/FunnelOutline.svg';
import { TaskTooltip } from 'app/components/Common/Tooltips/TaskTooltip';
import TaskAltIcon from 'app/assets/images/Tasks/task_alt_black_24dp.svg';
import NotesIcon from 'app/assets/images/Tasks/notes_black_24dp.svg';
import CloseIcon from '@mui/icons-material/Close';
import { taskDetailsActions } from '../../store/Tasks.redux';
import { useDispatch } from 'react-redux';
import { ActivePanelNotes } from './ActivePanelNotes';
export const getStickIcons = customSwitch({
  0: <img src={FilterIcon} alt="Filter" />,
  1: <img src={ListIcon} alt="List" />,
  2: <img src={FunnelIcon} alt="Funnel" />,
  3: <img src={NotesIcon} alt="Notes" />,
  4: <img src={TaskAltIcon} alt="Task" />,
  5: <ActivePanelNotes />,
  6: <CloseIcon/>,
})(undefined);
const useStyles = makeStyles()((theme: Theme) => ({
  item: {
    minWidth: '36px',
    padding: '0px 1px',
    background: '#F5F5F5',
    border: 0,
    cursor: 'pointer',
    color: 'blue',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));
export const TaskPanelStick = ({
  stickChosen,
  items,
  iconArray,
  handleChoice,
}: {
  stickChosen: number | null;
  items: any;
  iconArray: any;
  handleChoice: (e) => void;
}) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  return (
    <Grid container xs={12}>
      <div className={classes.flexContainer}>
        {iconArray.map((icon, index) => (
          <TaskTooltip tooltipContent={items[index].tooltip} key={index} placement='top'>
            <button
              key={index.toString()}
              aria-label="stick-item"
              type="button"
              className={classes.item}
              onClick={() => {
                handleChoice(index);
                dispatch(taskDetailsActions.setSidePanelChosen(false));
              }}
            >
              {stickChosen === null && getStickIcons(icon)}
            </button>
          </TaskTooltip>
        ))}
      </div>
    </Grid>
  );
};
