import { UnitCard } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/CustomComponents/UnitCard';
import { Skeleton, Typography } from 'amn-ui-core';
import { missingField } from 'app/constants';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { selectUser } from 'oidc/user.selectors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { StatusIndicator, isAuthorizedForAddEdit, useSelectedUnitDetails, useUnitStatusInActive } from './unitHelper';

// Shared style also used in other components
export const useUnitDetailStyles = makeStyles()(theme => ({
  textBold: {
    fontSize: '12px !important',
    color: theme.palette.components.typography.color,
    fontWeight: 'bold',
  },
  required: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: '12px',
  },
}));

const UnitDetails = () => {
  // #region Hooks
  const { t } = useTranslation();
  const userData = useSelector(selectUser);
  const isAuthorized = isAuthorizedForAddEdit(userData);
  const { classes } = useUnitDetailStyles();
  const selectedUnitDetail = useSelectedUnitDetails();
  const isUnitInActive = useUnitStatusInActive();
  const { promiseInProgress: isFacilityWithFilterDetailsLoading } = usePromiseTracker({
    area: 'get-facility-details-with-filters',
    delay: 0,
  });
  // #endregion

  // #region Helpers
  const unitDetailsFields = [
    {
      label: t('facility.units.unitDetails.unitSize'),
      value: selectedUnitDetail?.unitSize || missingField,
      skeletonWidthLabel: 57,
      skeletonWidthValue: 37,
    },
    {
      label: t('facility.units.unitDetails.costCenter'),
      value: selectedUnitDetail?.costCenter || missingField,
      skeletonWidthLabel: 57,
      skeletonWidthValue: 37,
    },
    {
      label: t('facility.units.unitDetails.floor'),
      value: selectedUnitDetail?.floor || missingField,
      skeletonWidthLabel: 57,
      skeletonWidthValue: 63,
    },
    {
      label: t('facility.units.unitDetails.fyreIntegration'),
      value: selectedUnitDetail?.fyreIntegration ? 'Yes' : 'No',
      skeletonWidthLabel: 57,
      skeletonWidthValue: 37,
    },
  ];
  // #endregion

  return (
    <UnitCard
      title={t('facility.units.unitDetails.title')}
      rightIcon={true}
      showEditIcon={isAuthorized && !isUnitInActive}
      skeletonWidth={85}
      isDataLoading={isFacilityWithFilterDetailsLoading}
    >
      <LayoutGrid container direction="column" rowSpacing={2} sx={{ p: '12px' }}>

        {/** Unit Details, Cost Center, Floor & FYRE Integration */}
        {unitDetailsFields.map((unitDetail, index) => (
          <LayoutGridItem key={index} container style={{ alignItems: 'center' }}>
            <LayoutGridItem item xs={3}>
              {isFacilityWithFilterDetailsLoading ? (
                <Skeleton variant="rectangular" width={unitDetail.skeletonWidthLabel} height={13} />
              ) : (
                <Typography className={classes.textBold}>{unitDetail.label}</Typography>
              )}
            </LayoutGridItem>
            {isFacilityWithFilterDetailsLoading ? (
              <LayoutGridItem item xs={9}>
                <Skeleton variant="rectangular" width={unitDetail.skeletonWidthValue} height={13} />
              </LayoutGridItem>
            ) : (
              <LayoutGridItem item xs={9}>
                <Typography sx={theme => ({ fontSize: '14px', color: theme.palette.components.typography.color })}>
                  {unitDetail.value}
                </Typography>
              </LayoutGridItem>
            )}
          </LayoutGridItem>
        ))}

        {/** SSN */}
        <LayoutGridItem container style={{ alignItems: 'center' }}>
          <LayoutGridItem item xs={3} display={'flex'} alignSelf={'center'}>
            {isFacilityWithFilterDetailsLoading ? (
              <Skeleton variant="rectangular" width={35} height={13} />
            ) : (
              <Typography className={classes.textBold}>{t('facility.units.unitDetails.ssn')}</Typography>
            )}
          </LayoutGridItem>
          {isFacilityWithFilterDetailsLoading ? (
            <LayoutGridItem item xs={9} display={'flex'} alignItems={'center'} flexDirection={'row'} gap={1}>
              <Skeleton variant="circular" width={28} height={28} />
              <Skeleton variant="rectangular" width={63} height={13} />
            </LayoutGridItem>
          ) : (
            <LayoutGridItem item xs={9}>
              {selectedUnitDetail?.ssn ? (
                <div className={classes.required}>
                  <StatusIndicator isRequired={true} hideToolTip={true} />
                  <Typography sx={theme => ({ fontSize: '14px', color: theme.palette.components.typography.color })}>
                    {t('order.requiredMessage')}
                  </Typography>
                </div>
              ) : (
                <Typography sx={theme => ({ fontSize: '14px', color: theme.palette.components.typography.color })}>
                  {t('order.orderDetails.buttonConfirmation.No')}
                </Typography>
              )}
            </LayoutGridItem>
          )}
        </LayoutGridItem>

        {/** DOB */}
        <LayoutGridItem container style={{ alignItems: 'center' }}>
          <LayoutGridItem item xs={3}>
            {isFacilityWithFilterDetailsLoading ? (
              <Skeleton variant="rectangular" width={35} height={13} />
            ) : (
              <Typography className={classes.textBold}>{t('facility.units.unitDetails.dob')}</Typography>
            )}
          </LayoutGridItem>
          {isFacilityWithFilterDetailsLoading ? (
            <LayoutGridItem item xs={9}>
              <Skeleton variant="rectangular" width={63} height={13} />
            </LayoutGridItem>
          ) : (
            <LayoutGridItem item xs={9}>
              {selectedUnitDetail?.dob ? (
                <div className={classes.required}>
                  <StatusIndicator isRequired={true} hideToolTip={true} />
                  <Typography sx={theme => ({ fontSize: '14px', color: theme.palette.components.typography.color })}>
                    {t('order.requiredMessage')}
                  </Typography>
                </div>
              ) : (
                <Typography sx={theme => ({ fontSize: '14px', color: theme.palette.components.typography.color })}>
                  {t('order.orderDetails.buttonConfirmation.No')}
                </Typography>
              )}
            </LayoutGridItem>
          )}
        </LayoutGridItem>
      </LayoutGrid>
    </UnitCard>
  );
};

export default UnitDetails;
