import { useReadOnly } from './userRoles';
import React from 'react';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { useTranslation } from 'react-i18next';

export const withReadOnly = BaseComponent => (props?: {
  // Override Optimizely showTooltip prop
  disableTooltip?: boolean;
  [key: string]: any;
}) => {
  return <ReadOnly BaseComponent={BaseComponent} {...props} />;
};

const ReadOnly = ({ BaseComponent, ...props }: any) => {
  const { readOnly, showTooltip } = useReadOnly();
  const { children, disableTooltip = false, ...rest } = props;
  const { t } = useTranslation();
  const disabled = rest?.disabled;

  const disabledOrReadOnly = React.useMemo(() => {
    return disabled || readOnly ? true : disabled;
  }, [disabled, readOnly]);

  const Base = ({ children, ...rest }) => {
    return React.createElement(BaseComponent, rest, children);
  };

  return (
    <CustomTooltip
      disabled={!(readOnly && !disabled && showTooltip && !disableTooltip)}
      standardMargin
      isStandardContent
      tooltipContent={t('global.readOnlyTooltip')}
    >
      {typeof BaseComponent === 'string' ? (
        <Base {...rest} disabled={disabledOrReadOnly}>
          {children}
        </Base>
      ) : (
        <BaseComponent {...rest} disabled={disabledOrReadOnly}>
          {children}
        </BaseComponent>
      )}
    </CustomTooltip>
  );
};
