/* eslint-disable i18next/no-literal-string */
import { Expandable, Loading } from 'app/components/Common/Expandable/Expandable';
import React, { useContext } from 'react';
import { StatusTitle } from '../Titles/StatusTitle';
import { Box, Grid } from 'amn-ui-core';
import { RequirementsTable } from './TableComponents/TableComponents';
import { RequirementsContainer } from './RequirementsContainer';
import {
  IRequirementCounts,
  IRequirementTreeFilter,
  RequirementFilterKey,
  requirementType,
} from 'app/models/Placement/PlacementSummary';
import {
  cleanChildFilterByKey,
  cleanChildFiltersRecursively,
  deriveFilter,
  useAccordionState,
  useRequirementFilter,
} from '../Helpers/requirementFilter';
import { useDispatch, useSelector } from 'react-redux';
import { placementSummaryActions } from '../../../../../../../store/redux-store/placement-summary/slice';
import {
  selectSubmissionAmnRequirementTypes,
  selectSubmissionOrderRequirementTypes,
} from '../../../../../../../store/redux-store/placement-details/selectors';
import { useTranslation } from 'react-i18next';
import { SkillsetRequirements } from '../GroupedRequirements';
import { usePromiseTracker } from 'react-promise-tracker';
import { selectServiceFailures } from 'store/redux-store/placement-summary/selectors';
import { ErrorAction } from '@AMIEWEB/Common';
import { AddOnContext } from '../PlacementSummaryTab';

interface IStatusProps {
  title: string;
  candidateName: string;
  amnTableRows?: JSX.Element;
  orderTableRows?: JSX.Element;
  amnCounts: IRequirementCounts;
  orderCounts: IRequirementCounts;
  skillReqCounts: IRequirementCounts;
  expanded?: boolean;
  requirementFilter: IRequirementTreeFilter | null;
  id?: string;
  [key: string]: any;
}

export const Status = ({
  title,
  candidateName,
  amnTableRows,
  orderTableRows,
  amnCounts,
  orderCounts,
  skillReqCounts,
  skillContainers,
  expanded,
  id,
  requirementFilter,
  ...props
}: IStatusProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const amnTypes = useSelector(selectSubmissionAmnRequirementTypes);
  const orderTypes = useSelector(selectSubmissionOrderRequirementTypes);

  const { filter: submissionFilter } = useRequirementFilter('submission', requirementFilter);
  const { filter: amnFilter } = useRequirementFilter('submission-amn', requirementFilter);
  const { filter: orderFilter } = useRequirementFilter('submission-order', requirementFilter);

  const { expand: expandAmnReq } = useAccordionState(amnFilter, amnTypes);
  const { expand: expandOrderReq } = useAccordionState(orderFilter, orderTypes);

  const handleFilterChange = (key: RequirementFilterKey, state: requirementType[]) => {
    if (requirementFilter) {
      var filterTree = deriveFilter(key, requirementFilter, state);
      cleanChildFilterByKey('submission-amn', filterTree, amnCounts);
      cleanChildFilterByKey('submission-order', filterTree, orderCounts);
      /** TODO: @Rijash - Implement recursive cleanup, Due Date: 30th May 2024  */
      if (skillContainers) {
        cleanChildFiltersRecursively(skillContainers, filterTree);
      }
      dispatch(
        placementSummaryActions.setFilterTreeByKey({
          key: 'submissionInfo',
          filterTree,
        }),
      );
    }
  };

  return (
    <Expandable
      id={id}
      unmountOnExit={false}
      preventForceClose
      expanded={expanded || !!submissionFilter.length}
      titleComponent={
        <StatusTitle
          title={title}
          counts={{
            aggregate: amnCounts.aggregate + orderCounts.aggregate + skillReqCounts.aggregate,
            passed: amnCounts.passed + orderCounts.passed + skillReqCounts.passed,
            failed: amnCounts.failed + orderCounts.failed + skillReqCounts.failed,
            risks: amnCounts.risks + orderCounts.risks + skillReqCounts.risks,
          }}
          filter={submissionFilter}
          handleFilterChange={state => handleFilterChange('submission', state)}
        />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RequirementsContainer
            title={`${t('placement.profile.summary.status.amnTitle')} ${t(
              'placement.profile.summary.status.requirements',
            )}`}
            counts={{
              aggregate: amnCounts.aggregate,
              passed: amnCounts.passed,
              failed: amnCounts.failed,
              risks: amnCounts.risks,
            }}
            unmountOnExit={false}
            filter={amnFilter}
            handleFilterChange={state => handleFilterChange('submission-amn', state)}
            expanded={expandAmnReq}
          >
            <RequirementsTable
              requirementTitle={t('placement.profile.summary.status.amnTitle')}
              candidateName={candidateName}
              id="placement-submission-amn-req-table"
            >
              {amnTableRows}
            </RequirementsTable>
          </RequirementsContainer>
        </Grid>
        <Grid item xs={12}>
          <RequirementsContainer
            title={`${
                 t('placement.profile.summary.status.orderGeneralTitle')
            } ${t('placement.profile.summary.status.requirements')}`}
            counts={{
              aggregate: orderCounts.aggregate,
              passed: orderCounts.passed,
              failed: orderCounts.failed,
              risks: orderCounts.risks,
            }}
            unmountOnExit={false}
            filter={orderFilter}
            handleFilterChange={state => handleFilterChange('submission-order', state)}
            expanded={expandOrderReq}
          >
            <RequirementsTable
              requirementTitle={t('placement.profile.summary.status.orderTitle')}
              candidateName={candidateName}
              id="placement-submission-order-req-table"
            >
              {orderTableRows}
            </RequirementsTable>
          </RequirementsContainer>
        </Grid>
        <SkillsetRequirementsWrapper
          skillContainers={skillContainers}
          requirementFilter={requirementFilter}
          handleFilterChange={handleFilterChange}
        />
      </Grid>
    </Expandable>
  );
};

const SkillsetRequirementsWrapper = ({ skillContainers, requirementFilter, handleFilterChange }) => {
  const { groupedSkillsetRequirement: groupedSkillReqServiceFailed } = useSelector(selectServiceFailures);

  const { promiseInProgress: loadingSkillReq } = usePromiseTracker({
    area: 'placement-submission-grouped-skillset-requirement',
  });

  const { placementId } = useContext(AddOnContext);

  const dispatch = useDispatch();

  const onTryAgain = () => {
    dispatch(placementSummaryActions.getGroupedSkillSetRequirements({ placementId, isRetryingService: true }));
  };
  return (
    <>
      {loadingSkillReq ? (
        <Grid item xs={12}>
          <Loading />
        </Grid>
      ) : groupedSkillReqServiceFailed ? (
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              border: theme => `1px solid ${theme.palette.system.borderColor1}`,
              borderTopLeftRadius: '5px',
              borderTopRightRadius: '5px',
              padding: '10px',
            }}
          >
            <ErrorAction message={'Skill requirements failed to load.'} onTryAgain={onTryAgain} />
          </Box>
        </Grid>
      ) : !!skillContainers ? (
        <Grid item xs={12}>
          <SkillsetRequirements
            skillContainers={skillContainers}
            requirementFilter={requirementFilter}
            handleFilterChange={handleFilterChange}
          />
        </Grid>
      ) : null}
    </>
  );
};
