import React from 'react';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { usePromiseTracker } from 'react-promise-tracker';
import { selectFacilityRegionModalState } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';

export const RegionSaveModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  const handleClose = () => {
    dispatch(facilityActions.setFacilityRegionModalState({ open: false, data: undefined }));
  };

  const { promiseInProgress: isSaving } = usePromiseTracker({
    area: 'update-facility-region-area',
    delay: 0,
  });

  const { open, data } = useSelector(selectFacilityRegionModalState);

  const handleYesAction = () => {
    dispatch(
      facilityActions.updateFacilityRegionAction({ ...data.updateCommand, IsPlacementOrderUpdateRequired: true }),
    );
  };
  
  const handleNoAction = () => {
    dispatch(facilityActions.updateFacilityRegionAction(data.updateCommand));
    handleClose();
  };

  return (
    <GenericDialog
      open={open}
      maxWidth="sm"
      dialogTitleProps={{
        text: t('facility.internalContacts.saveModal.applyUpdate'),
      }}
      dialogActions={[
        {
          text: t('detailsPage.confirmation.cancel'),
          onClick: () => handleClose(),
          disabled: isSaving,
        },
        {
          text: t('detailsPage.confirmation.no'),
          onClick: handleNoAction,
          variant: 'contained',
          color: 'tertiary',
          disabled: isSaving,
        },
        {
          text: t('detailsPage.confirmation.yes'),
          onClick: handleYesAction,
          variant: 'contained',
          loading: isSaving,
          disabled: isSaving,
        },
      ]}
    >
      {t('facility.region.saveConfirmation')}
    </GenericDialog>
  );
};
