import React from 'react';
import {
  TableRow,
  TableCell,
  Collapse,
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  Paper,
  Container,
  AppBar,
  Toolbar,
  IconButton,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { materialUiIcons } from 'amn-ui-core';
import { Link } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';

const useRowStyles = makeStyles()({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      { date: '2020-01-05', customerId: '11091700', amount: 3 },
      { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const { t } = useTranslation();
  const { classes } = useRowStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton color="primary" aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <materialUiIcons.KeyboardArrowUp /> : <materialUiIcons.KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.calories}</TableCell>
        <TableCell align="right">{row.fat}</TableCell>
        <TableCell align="right">{row.carbs}</TableCell>
        <TableCell align="right">{row.protein}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                {t('exampleTable.history')}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('exampleTable.headingDate')}</TableCell>
                    <TableCell>{t('exampleTable.headingCustomer')}</TableCell>
                    <TableCell align="right">{t('exampleTable.headingAmount')}</TableCell>
                    <TableCell align="right">{t('exampleTable.headingPrice')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map(historyRow => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">
                        {historyRow.date}
                      </TableCell>
                      <TableCell>{historyRow.customerId}</TableCell>
                      <TableCell align="right">{historyRow.amount}</TableCell>
                      <TableCell align="right">{Math.round(historyRow.amount * row.price * 100) / 100}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
  createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
  createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
  createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
];

export const ExampleTable = () => {
  const { t } = useTranslation();
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            {t('exampleTable.tableHeader')}
          </Typography>
          <Link href="/">
            {t('global.home')}
          </Link>
        </Toolbar>
      </AppBar>
      <Box height="40px" />
      <Container maxWidth="md">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>{t('exampleTable.headingDessert')}</TableCell>
                <TableCell align="right">{t('exampleTable.headingCalories')}</TableCell>
                <TableCell align="right">{t('exampleTable.headingFat')}</TableCell>
                <TableCell align="right">{t('exampleTable.headingCarbs')}</TableCell>
                <TableCell align="right">{t('exampleTable.headingProtein')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <Row key={row.name} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};
