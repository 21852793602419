import { Cancel } from '@AMIEWEB/Common/CancelModal/Cancel';
import React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ICreateUserPayload, getUserManagementActions } from './store/userManagement.redux';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectCreateNewUser, selectCreateNewUserErrorFields } from './store/userManagement.selector';
import { Autocomplete, Grid, TextField, Theme } from 'amn-ui-core';
import { CountryPhoneNumber } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/PersonalInfoTab/Form/EmergencyContact/CountryPhoneNumber';
import { makeStyles } from 'tss-react/mui';
import { getSearchFilterOptions } from 'app/services/SharedServices/SharedServices';
import { separateCountryCodeAndNumber } from 'app/helpers/objectHelpers';
import { StatusOptions, statusOpts } from './RightContainer/Helper/DetailsHelper';


const useStyles = makeStyles()((theme: Theme) => ({
    phonelabel: {
        '&.react-tel-input .special-label': {
            display: 'inline-flex',
            position: 'absolute',
            top: '5px',
            left: '70px',
            background: 'transparent',
            fontSize: '11px',
            color: theme.palette.system.darkGrey,
        },
    },
    gridContentContainer: {
        padding: "24px"
    },
    gridPhoneFieldContianer: {
        paddingTop: "12px"
    },
    emailField: {
        paddingLeft: '0px !important'
    }

}));

const CreateNewUserModal = () => {
    const { classes } = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { openNewUserModal } = useSelector(selectCreateNewUser);
    const errorFields = useSelector(selectCreateNewUserErrorFields);
    const [brandOpts, setBrandOpts] = React.useState<any[]>();
    const defaultCountryCode = '1';

    const defaultValues = {
        statusId: statusOpts[0],
        firstName: '',
        lastName: '',
        userName: '',
        companyId: '',
        phoneNumber: "",
        faxNumber: "",
        emailId: "",
    };

    const formMethods = useForm({
        defaultValues,
        mode: 'onSubmit',
    });
    const {
        handleSubmit,
        control,
        setError,
        clearErrors,
        formState: { isDirty, errors },
    } = formMethods;
    const [cancelModalOpen, setCancelModalOpen] = React.useState<boolean>(false);
    const [disableSubmitButton, setDisableSubmitButton] = React.useState<boolean>(false);

    const fetchBrandOptions = async () => {
        const brandLookups = await getSearchFilterOptions('brands')
        setBrandOpts(brandLookups);
    };

    React.useEffect(() => {
        fetchBrandOptions();
    }, [])

    React.useEffect(() => {
        if (errorFields.length > 0) {
            errorFields.forEach(errorField => {
                setError(errorField.fieldName, { message: errorField.message })
            });
            setDisableSubmitButton(true);
        }
    }, [errorFields])

    const onCloseModal = (e, reason) => {
        if (reason === 'backdropClick') return;
        if (isDirty) {
            setCancelModalOpen(true);
        } else {
            setCancelModalOpen(false);
            dispatch(getUserManagementActions.toggleCreateUserModal(false));
        }
    };


    const filterOptions = (brandOpts, state) => {
        const newOptions: any = [];
        brandOpts.forEach(brandOpt => {
            if (brandOpt.name.toLowerCase().includes(state.inputValue?.toLowerCase())) newOptions.push(brandOpt);
        });
        return newOptions;
    };

    const createNewUser = formData => {
        const newUser: ICreateUserPayload = {
            ...formData,
            statusId: formData.statusId.id,
            companyId: parseInt(formData.companyId.value)
        }
        dispatch(getUserManagementActions.createNewUser(newUser))
    };

    return (
        <FormProvider {...formMethods}>
            <GenericDialog
                open={openNewUserModal}
                fullWidth
                onClose={onCloseModal}
                variant="blue"
                maxWidth='md'
                dialogTitleProps={{
                    text: t('userManagement.addNewUser'),
                    closeButton: true,
                }}
                dialogActions={[
                    {
                        text: t('notification.createTask.cancelBtn'),
                        variant: 'contained',
                        color: 'tertiary',
                        onClick: e => isDirty ? onCloseModal(e, 'cancel')
                            : dispatch(getUserManagementActions.toggleCreateUserModal(false)),
                    },
                    {
                        text: t('userManagement.addNewUser'),
                        variant: 'contained',
                        disabled: !isDirty || disableSubmitButton,
                        onClick: async e => {
                            handleSubmit(createNewUser)().catch(err => { });
                        },
                    },
                ]}
            >
                <Grid container spacing={2} className={classes.gridContentContainer}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name={'firstName'}
                                rules={{
                                    maxLength: { value: 255, message: t('notification.createTask.maxLimitLabel') },
                                    required: true
                                }}
                                render={({ ref, onChange, value, ...rest }) => (
                                    <TextField
                                        variant="filled"
                                        fullWidth
                                        color="primary"
                                        required={true}
                                        error={(isDirty && errors?.firstName) ? true : false}
                                        id="firstName"
                                        label={t('userManagement.profileInformation.firstName')}
                                        onChange={e => {
                                            onChange(e);
                                            clearErrors('firstName');
                                            disableSubmitButton && setDisableSubmitButton(!disableSubmitButton);
                                        }}
                                        helperText={(isDirty && errors?.firstName ? !value ? t('required') : errors?.firstName.message : "")}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name={'lastName'}
                                rules={{
                                    maxLength: { value: 255, message: t('notification.createTask.maxLimitLabel') },
                                    required: true
                                }}
                                render={({ ref, onChange, value, ...rest }) => (
                                    <TextField
                                        variant="filled"
                                        fullWidth
                                        color="primary"
                                        required={true}
                                        id="lastName"
                                        label={t('userManagement.profileInformation.lastName')}
                                        error={(isDirty && errors?.lastName) ? true : false}
                                        onChange={e => {
                                            onChange(e);
                                            clearErrors('lastName');
                                            disableSubmitButton && setDisableSubmitButton(!disableSubmitButton);
                                        }}
                                        helperText={(isDirty && errors?.lastName ? !value ? t('required') : errors?.lastName.message : "")}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name={'userName'}
                                rules={{
                                    maxLength: { value: 255, message: t('notification.createTask.maxLimitLabel') },
                                    required: true
                                }}
                                render={({ ref, onChange, value, ...rest }) => (
                                    <TextField
                                        variant="filled"
                                        fullWidth
                                        color="primary"
                                        required={true}
                                        id="userName"
                                        label={t('userManagement.profileInformation.userName')}
                                        error={(isDirty && errors?.userName) ? true : false}
                                        onChange={e => {
                                            onChange(e);
                                            clearErrors('userName');
                                            disableSubmitButton && setDisableSubmitButton(!disableSubmitButton);
                                        }}
                                        helperText={(isDirty && errors?.userName ? !value ? t('required') : errors?.userName.message : "")}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name={"companyId"}
                                rules={{
                                    required: true
                                }}
                                render={({ ref, onChange, value, ...rest }) => (
                                    <Autocomplete
                                        options={brandOpts}
                                        filterOptions={filterOptions}
                                        getOptionLabel={(option) => option?.name}
                                        noOptionsText={t('notification.createTask.noResultLabel')}
                                        onChange={(event, newValue) => {
                                            onChange(newValue);
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label={t('userManagement.profileInformation.brand')}
                                                variant="filled"
                                                error={Object.keys(errors).includes("companyId")}
                                                helperText={
                                                    (Object.keys(errors).includes("companyId") && t('required'))
                                                }
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name={"statusId"}
                                rules={{
                                    required: true
                                }}
                                render={({ ref, onChange, value, ...rest }) => (
                                    <Autocomplete
                                        options={statusOpts}
                                        getOptionLabel={(option: StatusOptions) => option.name}
                                        noOptionsText={t('notification.createTask.noResultLabel')}
                                        value={value || statusOpts[0]}
                                        onChange={(event, newValue) => {
                                            onChange(newValue);
                                            clearErrors("status");
                                            disableSubmitButton && setDisableSubmitButton(!disableSubmitButton);
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label={t('userManagement.profileInformation.status')}
                                                variant="filled"
                                                // className={classes.inputs}
                                                error={Object.keys(errors).includes("statusId")}
                                                helperText={
                                                    (Object.keys(errors).includes("statusId") && t('required'))
                                                }
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.gridPhoneFieldContianer}>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name={"phoneNumber"}
                                render={({ ref, onChange, value, ...rest }) => (
                                    <CountryPhoneNumber
                                        className={classes.phonelabel}
                                        name="phoneNumber"
                                        value={defaultCountryCode}
                                        countryCodeEditable={true}
                                        label={t('userManagement.profileInformation.phoneNum')}
                                        onChange={(value, data, event, formattedValue) => {
                                            if (separateCountryCodeAndNumber(formattedValue) !== null) {
                                                onChange(formattedValue);
                                            } else {
                                                onChange("");
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name={"faxNumber"}
                                render={({ ref, onChange, value, ...rest }) => (
                                    <CountryPhoneNumber
                                        className={classes.phonelabel}
                                        name="faxNumber"
                                        countryCodeEditable={true}
                                        value={defaultCountryCode}
                                        label={t('userManagement.profileInformation.faxNum')}
                                        onChange={(value, data, event, formattedValue) => {
                                            if (separateCountryCodeAndNumber(formattedValue) !== null) {
                                                onChange(formattedValue);
                                            } else {
                                                onChange("");
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid item className={classes.emailField} xs={12} >
                        <Controller
                            name="emailId"
                            control={control}
                            rules={{
                                required: false,
                                maxLength: { value: 255, message: t('notification.createTask.maxLimitLabel') },
                                pattern: {
                                    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                                    message: t('userManagement.newUserValidationErrors.invalidEmailAddress')
                                }
                            }}
                            render={({ ref, value, onChange, onBlur, ...rest }) => (
                                <TextField
                                    variant="filled"
                                    name="emailId"
                                    type="email"
                                    label={t('userManagement.profileInformation.emailId')}
                                    fullWidth
                                    onChange={e => {
                                        onChange(e);
                                    }}
                                    error={(isDirty && errors?.emailId) ? true : false}
                                    helperText={(isDirty && errors?.emailId && t('userManagement.newUserValidationErrors.invalidEmailAddress'))}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </GenericDialog>
            <Cancel
                openDialog={cancelModalOpen}
                handleConfirmAction={() => {
                    dispatch(getUserManagementActions.toggleCreateUserModal(false))
                    setCancelModalOpen(false);
                }}
                handleCancelAction={() => {
                    setCancelModalOpen(false);
                }}
            />
        </FormProvider>
    )
}

export default CreateNewUserModal