import { CircularProgress, Grid, IconButton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useTranslation } from 'react-i18next';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips/CustomTooltip';
import { colors } from 'styles/styleVariables';

const useStyles = makeStyles()({
  button: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export const PreviewAction = ({
  previewMode,
  onPreview,
  onEdit,
  previewIsloading,
  disablePreview = false,
  ...props
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const handlePreview = React.useCallback(() => {
    onPreview();
  }, [onPreview]);

  return (
    <>
      <Grid item>
        {previewIsloading ? (
          <CircularProgress sx={{ color: theme => theme.palette.system.navyBlue, marginRight: 1 }} size={14} />
        ) : previewMode ? (
          <BorderColorIcon
            sx={{ color: theme => theme.palette.system.navyBlue }}
            className={classes.button}
            onClick={onEdit}
          />
        ) : (
          <>
            <CustomTooltip disabled={disablePreview} tooltipContent={t('templateEditor.preview')}>
              <IconButton
                color="primary"
                size="small"
                disableFocusRipple
                disableTouchRipple
                disableRipple
                disabled={disablePreview}
                sx={{ padding: 0 }}
              >
                <PageviewOutlinedIcon
                  sx={{ color: theme => (disablePreview ? colors.amnLightGrey
                    : theme.palette.system.navyBlue) }}
                  className={classes.button}
                  onClick={handlePreview}
                />
              </IconButton>
            </CustomTooltip>
          </>
        )}
      </Grid>
    </>
  );
};
