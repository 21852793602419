import { CircularProgress, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton, CancelButton } from '@AMIEWEB/Common';
import styledComponent from 'styled-components';

const useStyles = makeStyles()({
  btn: {
    fontSize: 12,
    borderRadius: 4,
    color: '#fff',
    padding: '8px 16px 6px 16px',
  },
  circularProgressLight: {
    color: '#FFF',
  },
});

interface FormActionsProps {
  onCancel?: () => void;
  onSave?: () => void;
  disableCancel?: boolean;
  disableSubmit?: boolean;
  isSaving?: boolean; 
  [key: string]: any;
}

export const FormActions = ({ 
  onCancel, 
  onSave, 
  disableCancel, 
  disableSubmit, 
  isSaving, 
  ...props 
}: FormActionsProps) => {
  const { t } = useTranslation();
  const { cx, classes } = useStyles();

  return (
    <Grid container direction="row" justifyContent="flex-end" spacing={2}>
      <Grid item>
        <CancelButton
          variant="contained"
          type="button"
          onClick={onCancel}
          classes={{ root: props.btnRootClass ?? classes.btn }}
          disabled={disableCancel}
        >
          {t('order.orderDetails.preferences.actions.cancel')}
        </CancelButton>
      </Grid>
      <Grid item>
        <ActionButton
          variant="contained"
          type="submit"
          onClick={onSave}
          classes={{ root: props.btnRootClass ?? classes.btn }}
          disabled={disableSubmit}
        >
          {isSaving ? (
            <Grid container spacing={1} wrap="nowrap" alignItems="center" alignContent="center">
              <Grid item>
                <Progress
                  size="15px"
                  className={cx({ [classes.circularProgressLight]: !props.disabled })}
                />
              </Grid>
              <Grid item>{t('order.orderDetails.preferences.actions.saveChanges')}</Grid>
            </Grid>) : t('order.orderDetails.preferences.actions.saveChanges')}
        </ActionButton>
      </Grid>
    </Grid>
  );
};

const Progress = styledComponent(CircularProgress)`
  margin-top: 2px;
  margin-bottom: -2px;
  padding: 0;
`;