/* eslint-disable tss-unused-classes/unused-classes */
// import AutoComplete, { AutocompleteInputChangeReason } from '@mui/lab/Autocomplete';
import { TextField, Paper, Autocomplete, AutocompleteInputChangeReason } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { createContext, useContext, useState } from 'react';
import styledComponent from 'styled-components';
import { colors } from 'styles/styleVariables';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export interface ICustomOption {
  primaryLabel: string;
  secondaryLabel?: string;
  object: any;
}

const useStyles: any = makeStyles()({
  popper: {
    width: 'fit-content !important',
  },
  secondaryLabel: {
    paddingLeft: 5,
  },
});

const DropDownContext = createContext<{ historyTitle: string }>({ historyTitle: '' });

export const CustomTypeAhead = props => {
  const { isDisabled, searchOnCharacterCount = 0, showHistory = false, historyTitle = '', variant = 'outlined' } = props;
  const { classes } = useStyles();
  const [textFieldValue, setTextFieldValue] = useState<any>(
    props.value ? props.value.primaryLabel || props.value.secondaryLabel : null,
  );

  const onInputChange = (event, newInputValue: string, reason: AutocompleteInputChangeReason) => {
    setTextFieldValue(prevValue => (!newInputValue && !event ? prevValue : newInputValue));
    if (event) {
      /** caching of tagName in order to avoid  event.currentTarget turn nullable from synthetic event change*/
      const { tagName } = event && event.currentTarget;
      /**Below return statement ensures calls are not fired when click on option */
      if (tagName === 'LI') return;
    }
    /** Checks:
     * (i): check on searchOnCharacterCount
     * (ii): check if newInputValue is injected from option selection
     */
    if (
      props.onInputChange &&
      searchOnCharacterCount <= newInputValue.length &&
      (!props.value || newInputValue !== props.value?.primaryLabel)
    )
      props.onInputChange(newInputValue);
    else !newInputValue && event && props.resetHistory();
  };

  return (
    <DropDownContext.Provider value={{ historyTitle }}>
      <Autocomplete
        id={props.id}
        clearOnBlur={false}
        popupIcon={null}
        value={props.value}
        disabled={isDisabled}
        inputValue={textFieldValue || ''}
        getOptionLabel={(opt: ICustomOption) => opt.primaryLabel || opt.secondaryLabel}
        renderOption={(p, opt: ICustomOption) => (
          <CustomOption {...p} {...opt} classes={classes} showHistory={showHistory} />
        )}
        renderTags={() => null}
        renderInput={params => <TextField variant={variant} {...params} label={props.label} />}
        {...props}
        /**Retain onInputChange below ...props - to avoid external assinment(props.onInputChange) */
        onInputChange={onInputChange}
        PaperComponent={showHistory && props.options.length ? HistoryDropdown : undefined}
      />
    </DropDownContext.Provider>
  );
};

const HistoryDropdown = props => {
  const { historyTitle } = useContext(DropDownContext);
  return (
    <Paper {...props}>
      <RecentTag>{historyTitle}</RecentTag>
      {props.children}
    </Paper>
  );
};

export const CustomOption = ({ primaryLabel, secondaryLabel = '', showHistory = false, classes, ...props }) => (
  <Option {...props}>
    <PrimaryLabel>
      {showHistory && <HistoryIcon />}
      {primaryLabel}
    </PrimaryLabel>
    {secondaryLabel && (
      <SecondaryLabel className={showHistory ? classes.secondaryLabel : undefined}>{secondaryLabel}</SecondaryLabel>
    )}
  </Option>
);

const Option = styledComponent.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
`;

const PrimaryLabel = styledComponent.div`
  color: ${colors.darkText};
`;

const SecondaryLabel = styledComponent.div`
  color: ${colors.amnDarkGrey};
  font-size: 12px !important;
`;

const HistoryIcon = styledComponent(AccessTimeIcon)`
  font-size: 14px;
  margin: 0px 2px 2px -11px;
  color: #888888;
`;

const RecentTag = styledComponent.div`
  color: #888888;
  padding: 6px 12px;
  font-weight: 400;
`;
