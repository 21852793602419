/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui';
import { CustomFilter } from 'app/ComponentLibrary/Filter';
import React, { useState, useEffect, createRef, useRef } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { PricingFilter, defaultPricingFilters, defaultValues } from './PricingFilterUtils';
import { gridStateActions } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { selectFilters, selectGridFiltersExpanded } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';
import { SearchPageIcons } from 'app/ComponentLibrary/Filter/SearchPageIcons';
import { Grid } from 'amn-ui-core';
import { getSearchFilterOptions } from 'app/services/SharedServices/SharedServices';
import { getDisciplinesMultiSelectOptions, getMultiSelectOptions } from '@AMIEWEB/GlobalSearch/helper';
import { trackPromise } from 'react-promise-tracker';
import { getSortedData, getUnique } from 'app/helpers/arrayHelpers';
import { FilterType } from 'app/enums/Common';

const useStyles = makeStyles()({
  fillterCollapse: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '3px',
  },
});

export const PricingDeskFilters = props => {
  const { classes } = useStyles();
  const [fields, setFields] = useState(null);
  const { setValue } = useFormContext();
  const filtersExpanded = useSelector(selectGridFiltersExpanded);
  const hiddenSubmitButton = createRef<HTMLButtonElement>();
  const dispatch = useDispatch();
  const gridStateFilters = useSelector(selectFilters);
  const [allSpecialities, setAllSpecialities] = useState<any[]>([]);
  const [allSubSpecialities, setAllSubSpecialities] = useState<any[]>([]);

  const gridSpecs = useRef(
    new PricingFilter(
      {
        mspClientOpts: [],
        facilityStatusesOpts: [],
        divisionOpts: [],
        disciplinesOpts: [],
        specialtiesOpts: [],
        subSpecialtiesOpts: [],
        orderPrioritiesOpts: [],
        shiftOpts: [],
      },
      null,
      gridStateFilters,
    ),
  );

  useEffect(() => {
    findspecialities(gridStateFilters?.filters?.discipline);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridStateFilters?.filters?.discipline]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (gridStateFilters?.filters?.specialty?.length > 0) {
      findSubSpecialities(gridStateFilters?.filters?.specialty);
    } else {
      findspecialities(gridStateFilters?.filters?.discipline);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridStateFilters?.filters?.specialty]);

  const fetchPricingDeskFilterSpecs = async () => {
    const mspClientLookups = await getSearchFilterOptions('mspclients');
    const facilitysStatusesLookups = await getSearchFilterOptions('facilitystatuses', true);

    const mspClientOpts = getMultiSelectOptions(mspClientLookups || []);
    const facilityStatusesOpts = getMultiSelectOptions(facilitysStatusesLookups || []);

    const divisionsLookups = await getSearchFilterOptions('divisions', true);
    const divisionOpts = getMultiSelectOptions(divisionsLookups || []);

    const disciplinesLookups = await getSearchFilterOptions('disciplines', true);
    const disciplinesOpts = getMultiSelectOptions(disciplinesLookups || []);

    const specialtiesLookups = await getSearchFilterOptions('discipline_specialties', true);
    const allSpecialtiesOpts = getMultiSelectOptions(getDisciplinesMultiSelectOptions(specialtiesLookups || []));
    const specialtiesOpts = getUnique(
      allSpecialtiesOpts?.filter(x => x.name),
      'name',
    );
    setAllSpecialities(allSpecialtiesOpts);
    const subSpecialtiesLookups = await getSearchFilterOptions('specialty_subspecialties', true);
    const allSubSpecialtiesOpts = getMultiSelectOptions(getDisciplinesMultiSelectOptions(subSpecialtiesLookups || []));
    const subSpecialtiesOpts = getUnique(
      allSubSpecialtiesOpts?.filter(x => x.name),
      'name',
    );
    setAllSubSpecialities(allSubSpecialtiesOpts);
    const orderPrioritiesLookups = await getSearchFilterOptions('orderpriorities', true);
    const orderPrioritiesOpts = getMultiSelectOptions(orderPrioritiesLookups || []);

    const shiftLookups = await getSearchFilterOptions('shiftlist', true);
    const shiftOpts = getMultiSelectOptions(shiftLookups || []);

    gridSpecs.current.filterSpecs = {
      mspClientOpts: mspClientOpts,
      facilityStatusesOpts: facilityStatusesOpts,
      divisionOpts: divisionOpts,
      disciplinesOpts: disciplinesOpts,
      specialtiesOpts: specialtiesOpts,
      subSpecialtiesOpts: subSpecialtiesOpts,
      orderPrioritiesOpts: orderPrioritiesOpts,
      shiftOpts: shiftOpts,
    };
    const fieldsSchema = gridSpecs.current.getPricingFilters();
    setFields(fieldsSchema);
  };

  const formMethods = useForm({
    mode: 'all',
  });
  const {
    formState: { dirtyFields },
  } = formMethods;

  const resetFilter = () => {
    Object?.entries(defaultPricingFilters)?.map(item => setValue(item[0], item[1]));
    dispatch(
      gridStateActions.setFilterAttributes({
        filterObject: Object.entries(defaultPricingFilters),
        selectedChipFilters: { ...defaultValues },
      }),
    );
    props.setResetGridParams(true);
  };

  useEffect(() => {
    trackPromise(fetchPricingDeskFilterSpecs(), 'global-search-call');
  }, []);

  const findspecialities = disciplines => {
    const specialtiesArray: any[] = [];
    if (disciplines?.length > 0) {
      disciplines?.forEach(item => {
        const specialties = allSpecialities.filter(
          curr => curr?.corelation?.slice(0, curr?.corelation?.indexOf('_')) === item?.value,
        );
        if (specialties) {
          specialtiesArray.push(...specialties);
        }
      });
      const sortedData = getSortedData(specialtiesArray, FilterType.string);
      const uniqueSpecialities = getUnique(
        sortedData?.filter(x => x.name),
        'name',
      );
      if (uniqueSpecialities?.length >= 1) {
        gridSpecs.current.filterSpecs.specialtiesOpts = getMultiSelectOptions(uniqueSpecialities);
        const fieldsSchema = gridSpecs.current.getPricingFilters();
        setFields(fieldsSchema);
        findSubSpecialities(specialtiesArray);
      } else {
        gridSpecs.current.filterSpecs.subSpecialtiesOpts = [];
        gridSpecs.current.filterSpecs.specialtiesOpts = getMultiSelectOptions(uniqueSpecialities);
        const fieldsSchema = gridSpecs.current.getPricingFilters();
        setFields(fieldsSchema);
      }
    } else {
      gridSpecs.current.filterSpecs.specialtiesOpts = getUnique(
        allSpecialities?.filter(x => x.name),
        'name',
      );
      const fieldsSchema = gridSpecs.current.getPricingFilters();
      setFields(fieldsSchema);
      findSubSpecialities([]);
    }
  };

  const findSubSpecialities = specialities => {
    const subSpecialtiesArray: any[] = [];
    if (specialities?.length > 0) {
      specialities?.forEach(item => {
        allSubSpecialities?.forEach(curr => {
          if (
            curr?.corelation?.substr(0, curr?.corelation?.lastIndexOf('_')) ===
            item?.corelation?.slice(item?.corelation?.indexOf('_') + 1)
          ) {
            if (curr?.name !== 'All') {
              subSpecialtiesArray.push(curr);
            }
          }
        });
      });
      const sortedData = getSortedData(subSpecialtiesArray, FilterType.string);
      const uniqueSubSpecialities = getUnique(
        sortedData?.filter(x => x.name),
        'name',
      );
      gridSpecs.current.filterSpecs.subSpecialtiesOpts = getMultiSelectOptions(uniqueSubSpecialities);
      const fieldsSchema = gridSpecs.current.getPricingFilters();
      setFields(fieldsSchema);
    } else {
      gridSpecs.current.filterSpecs.subSpecialtiesOpts = getUnique(
        allSubSpecialities?.filter(x => x.name),
        'name',
      );
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={11}>
        <CustomFilter
          fields={fields}
          justifyContent={'flex-start'}
          version2
          resetBtn
          falseProp={dirtyFields}
          analytics={{
            caller: 'Account Management WorkDesk Filter',
            onApply: true,
            properties: {
              category: 'Placements',
            },
          }}
        />
      </Grid>
      <Grid item xs={1} className={classes.fillterCollapse}>
        <SearchPageIcons resetlbutton checked={filtersExpanded} onResetForm={() => resetFilter()} />
        <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
      </Grid>
    </Grid>
  );
};
