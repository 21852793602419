import { Button } from '@mui/material';
import React from 'react';

const ExpandMoreCell = props => {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const handleClick = () => {
    setExpanded(!expanded);
    props.onExpand(!expanded);
  };
  return (
    <div>
      <Button
        variant="text"
        color="primary"
        onClick={handleClick}
        className={props?.className}
        style={{ textTransform: 'none' }}
      >
        {expanded ? `- View Less` : `+ View More`}
      </Button>
    </div>
  );
};

export default ExpandMoreCell;
