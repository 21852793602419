import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomTextField } from '../../CustomComponents/CustomTextField';

export const BestTimeToCall = () => {
  const { control } = useFormContext();
  return (
    <Controller
      name="bestTimeToCall"
      control={control}
      render={({ ref, ...rest }) => <CustomTextField {...rest} label="Best Time to Call" />}
    />
  );
};
