export enum FilterChoice {
    Candidate = 'Candidate',
    Order = 'Order',
    Placement = 'Placement',
    Facility = 'Facility',
    ClientContact = 'Client Contact',
  }
  
  export interface ISearchAttributes {
    filterOption: string;
    searchString: string;
    showPreferenceFilterDropdown: boolean;
    archivePlacement?: {
      placementStatusesAllowed: string[];
      dialogOpen: boolean;
      loading: boolean;
      success?: boolean | null;
    };
    bulkOrders ?: {
      bulkVerifyOrdersFlag ?: boolean;
    };
  }
  