import { RootState } from 'types';
import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './utils';

const selectDomain = (state: RootState) => state?.placementClientConfirmation || initialState;

export const selectPlacementConfirmationTemplatesEditor = createSelector([selectDomain], state => state);

export const selectTemplates = createSelector([selectDomain], state => state.templates);

export const selectDefaultTemplate = createSelector([selectDomain], state => {
  if (!!state.facilityDefaultTemplate) return state.templates.find(x => x.templateId === state.facilityDefaultTemplate);
});

export const selectDocumentList = createSelector([selectDomain], state => state.documents);

export const selectDraftDocument = createSelector([selectDomain], state =>
  state.hasDraftTemplate ? state.documents.find(x => x.isDraft) : undefined,
);

export const selectDraftStatus = createSelector([selectDomain], state => state.hasDraftTemplate);

export const selectSelectedTemplate = createSelector([selectDomain], state => state.selectedTemplate);

export const selectMergeTokenList = createSelector([selectDomain], state => state.mergeTokenList);

export const selectContacts = createSelector([selectDomain], state => ({
  contacts: state.contacts,
  editableContacts: state.editableContacts,
  isContactsDirty: state.isContactsDirty,
  atleastOneContactIsSelected: state.editableContacts.some(x => x.isSelected),
}));

export const selectDocumentView = createSelector([selectDomain], state => ({
  document: state.document,
  renderPreviewScreen: state.renderPreviewScreen,
  renderPdfView: state.renderPdfView,
}));

export const selectPageAttributes = createSelector([selectDomain], state => state.pageAttributes);
