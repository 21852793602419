import React from 'react';
import { missingField } from 'app/constants';
import styledComponent from 'styled-components';

export const OrderInfoTypeItem = ({ ...props }) => {
  return (
    <div id="placement-order-info-type">
      <RowDataDiv data-testid="order-info-type-item">
        {props.orderInformation?.orderType ? `${props.orderInformation.orderType}` : missingField}
      </RowDataDiv>
    </div>
  );
};

const RowDataDiv = styledComponent.div`
  font-size: 14px;
  white-space: pre-wrap;
`;
