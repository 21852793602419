/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable i18next/no-literal-string */
import { GridCellParams } from '@mui/x-data-grid-pro';
import { materialUiXGrid } from 'amn-ui-core';
import { Box, ClickAwayListener } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { CustomTooltip } from '../../Tooltips';
import { DateRangePicker } from 'app/ComponentLibrary/DateRangeNew';
import moment from 'moment';

const useStyles = makeStyles()({
  hidden: {
    display: 'none',
  },
  inputInvalid: {
    color: 'red',
  },
  inputValid: {
    color: '#a3a2a2',
  },
  datePicker: {
    height: 36,
  },
});

interface XGridDatePickerCellProps {
  onEndEdit: ({ value, params }: { value: any; params: GridCellParams }) => void;
  validator?: ({ value, params }: { value: any; params: GridCellParams }) => boolean;
  disableCellEdit?: ({ value, params }: { value: any; params: GridCellParams }) => boolean;
  placeholder?: string;
  mandatory?: ({ value, params }: { value: any; params: GridCellParams }) => boolean;
  editOnSingleClick?: boolean;
  Input?: any;
  dropdownOptions?: any;
  tooltip?: ({ value, params }: { value: any; params: GridCellParams }) => string;
  externalValidator?: ({ value, params }: { value: any; params: GridCellParams }) => any;
}

export const XGridDatePickerCell =
  ({ ...props }: XGridDatePickerCellProps) =>
  (params: GridCellParams) => {
    return <XGridEditableCellContent params={params} props={props} />;
  };

const XGridEditableCellContent = ({ props, params }: { props: XGridDatePickerCellProps; params: GridCellParams }) => {
  const { classes, cx } = useStyles();
  const api = materialUiXGrid.useGridApiContext();
  const {
    onEndEdit,
    validator,
    placeholder,
    mandatory,
    editOnSingleClick = false,
    Input,
    tooltip,
    externalValidator,
  } = props;

  const [editing, setEditing] = React.useState<boolean>(false);
  const [editValue, setEditValue] = React.useState<any>(params.value);
  const [valid, setValid] = React.useState<boolean>(true);
  const [firstInteraction, setFirstInteraction] = React.useState<boolean>(true);
  const showCellContent = true;
  const date = new Date();

  React.useEffect(() => {
    setEditValue(params.value);
    if (validator && !firstInteraction) {
      setValid(validator({ value: editValue, params: params }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.value, validator]);

  React.useEffect(() => {
    if (validator && !firstInteraction) {
      setValid(validator({ value: editValue, params: params }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, params.value, validator]);

  const startEditing = React.useCallback(() => {
    setFirstInteraction(false);
    if (!editOnSingleClick) setEditing(true);
    setTimeout(() => {
      if (editOnSingleClick) setEditing(true);
      document.getElementById(`grid-cell-input-${params.row.id}-${params.colDef.field}`)?.focus();
    }, 100);
  }, [editOnSingleClick, params.colDef.field, params.row.id]);

  const _onEndEditing = () => {
    if (editing) setEditing(false);
  };

  const onKeyUp = React.useCallback(
    event => {
      if (
        event.keyCode === 13 &&
        document.activeElement?.getAttribute('data-field') === params.field &&
        document.activeElement?.getAttribute('data-rowindex') ===
          `${api?.current?.getRowIndexRelativeToVisibleRows(params.id)}`
      ) {
        startEditing();
      }
    },
    [api, params.field, params.id, startEditing],
  );

  React.useEffect(() => {
    setEditValue(params.value);
    if (externalValidator && !firstInteraction) {
      setValid(externalValidator({ value: editValue, params: params }) ? false : true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.value, externalValidator]);

  React.useEffect(() => {
    document.addEventListener('keyup', onKeyUp);
    return () => {
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [onKeyUp, params]);

  const onInputChanged = value => {
    setEditValue(value);
  };

  const handleChange = value => {
    setEditValue(value);
    if (editing) {
      setEditing(false);
      api?.current?.updateRows([{ ...params.row, [params.colDef.field]: value }]);
      onEndEdit({ value: value, params: params });
      if (validator) {
        setValid(validator({ value: value, params: params }));
      }
    }
  };

  const bindValue = inputValue => {
    if (!inputValue)
      return {
        startDate: new Date(),
        endDate: new Date(),
        label: moment(new Date()).format('MM/DD/YYYY'),
        useLabelAsValue: false,
      };
    else {
      return {
        startDate: new Date(inputValue),
        endDate: new Date(inputValue),
        label: moment(new Date(inputValue)).format('MM/DD/YYYY'),
        useLabelAsValue: inputValue || false,
      };
    }
  };

  const checkDisabled = () => {
    if (props.disableCellEdit?.({ value: editValue, params: params })) {
      return true;
    } else return false;
  };

  return (
    <React.Fragment>
      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={_onEndEditing}>
        <div style={{ width: '100%' }} className={cx({ [classes.hidden]: !editing })}>
          {Input ? (
            <Input params={params} onChange={onInputChanged} onEndEdit={_onEndEditing} />
          ) : (
            showCellContent && (
              <DateRangePicker
                className={classes.datePicker}
                onChange={event => handleChange(event?.startDate?.toString() || '')}
                onCancel={() => {}}
                actionBar={false}
                open={false}
                single
                variant="filled"
                clickAway
                isDisabled={checkDisabled()}
                maxWidth={300}
                fullWidth
                showIcon={true}
                isDateIconReq={false}
                initialDateRange={bindValue(editValue)}
                minDate={new Date('01-01-1940')}
              ></DateRangePicker>
            )
          )}
        </div>
      </ClickAwayListener>
      <Box
        onClick={editOnSingleClick ? startEditing : undefined}
        onDoubleClick={!editOnSingleClick ? startEditing : undefined}
        className={cx({
          [classes.hidden]: editing,
          [classes.inputInvalid]: !valid || params.row[`${params.field}_error`] === true,
        })}
        style={{ width: '100%', minHeight: '20px' }}
      >
        {editValue && showCellContent ? (
          externalValidator?.({ value: editValue, params: params }) ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ padding: '0px 5px 2px 0px' }}>
                <CustomTooltip tooltipContent={externalValidator?.({ value: editValue, params: params })}>
                  <WarningIcon style={{ color: 'red', height: '14px', width: '14px' }} />
                </CustomTooltip>
              </span>
              <span
                className={cx({
                  [classes.inputInvalid]: !valid || params.row[`${params.field}_error`] === true,
                  [classes.inputValid]: valid && !params.row[`${params.field}_error`],
                })}
              >
                {moment(new Date(editValue)).format('MM/DD/YYYY')}
              </span>
            </div>
          ) : (
            moment(new Date(editValue)).format('MM/DD/YYYY')
          )
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {mandatory?.({ value: params.value, params }) &&
              (!validator?.({ value: params.value, params }) || false) && (
                <span style={{ padding: '0px 5px 2px 0px' }}>
                  <CustomTooltip tooltipContent={'This field is required'}>
                    <WarningIcon style={{ color: 'red', height: '14px', width: '14px' }} />
                  </CustomTooltip>
                </span>
              )}
            <CustomTooltip
              disabled={tooltip?.({ value: params.value, params })?.length < 1}
              tooltipContent={tooltip?.({ value: params.value, params })}
            >
              <span
                className={cx({
                  [classes.inputInvalid]: !valid || params.row[`${params.field}_error`] === true,
                  [classes.inputValid]: valid && !params.row[`${params.field}_error`],
                })}
              >{`${placeholder || params.colDef.headerName}`}</span>
            </CustomTooltip>
          </div>
        )}
      </Box>
    </React.Fragment>
  );
};
