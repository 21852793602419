import { missingDate } from 'app/constants';
import { Moment } from 'moment';
import moment from 'moment-timezone';

export const convertTZ = (date, tzString) => {
  return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone: tzString }));
};

export const convertToFormat = (date: Date | string | null | any, format: string, pst?: boolean): string | null => {
  if (!date || date === missingDate) return null;
  const mmnt = moment(date);
  if (!mmnt.isValid()) return null;
  return !pst ? mmnt.format(format) : convertToPST(date, format);
};

export const daysFromToday = (date: Date | Moment, excludeNegativeValue?: boolean) => {
  const days = moment(moment(date).format('MM/DD/YY')).diff(moment().format('MM/DD/YY'), 'days');
  return excludeNegativeValue && days < 0 ? 0 : days;
};

export const dateIsToday = (date: Date | Moment) => {
  return moment().isSame(date, 'd');
};

export const dateIsAfterToday = (date: Moment) => {
  return date.isAfter(moment(moment().format('yyyy-MM-DDT23:59:59')));
};

export const dateIsBeforeToday = (date: Moment) => {
  return date.isBefore(moment(moment().format('yyyy-MM-DDT00:00:00')));
};

export const convertToPST = (date: Date | string, format: string = 'MM/DD/YYYY h:mm:ss A'): string => {
  return moment.tz(date, 'America/Los_Angeles').format(format);
};

export const convertDateToPST = (date: Date | string, format: string = 'MM/DD/YYYY | h:mm A'): string => {
  return moment.tz(date, 'America/Los_Angeles').format(format);
};

export const convertToPSTMoment = (date: Date | string): moment.Moment => {
  return moment.tz(date, 'America/Los_Angeles');
};

export const getSaturdayAndSundayOfGivenDate = (date: Date) => {
  var first = date.getDate() - date.getDay();
  var last = first + 6;

  var sunday = new Date(date.setDate(first));
  var saturday = new Date(date.setDate(last));
  return { sunday, saturday };
};

export const getFirstAndLastDateOfMonth = (year, month) => {
  var firstDay = new Date(year, month, 1);
  var lastDay = new Date(year, month + 1, 0);
  return { firstDay, lastDay };
};

export const getStartOfDate = date => {
  const inputDate = new Date(date);
  const isValidDate = moment(date).isValid();
  if (isValidDate) {
    const month = inputDate.getMonth() + 1 > 9 ? `${inputDate.getMonth() + 1}` : `0${inputDate.getMonth() + 1}`;
    const day = inputDate.getDate() > 9 ? `${inputDate.getDate()}` : `0${inputDate.getDate()}`;
    return `${inputDate.getFullYear()}-${month}-${day}T00:00:00.000Z`;
  } else {
    return null;
  }
};

export const getEndOfDate = date => {
  const inputDate = new Date(date);
  const isValidDate = moment(date).isValid();
  if (isValidDate) {
    const day = inputDate.getDate() > 9 ? `${inputDate.getDate()}` : `0${inputDate.getDate()}`;
    const month = inputDate.getMonth() + 1 > 9 ? `${inputDate.getMonth() + 1}` : `0${inputDate.getMonth() + 1}`;
    return `${inputDate.getFullYear()}-${month}-${day}T23:59:59.999Z`;
  } else {
    return null;
  }
};

export const sortDates = (d1, d2, direction: 'asc' | 'desc' = 'asc') => {
  const m1 = moment(d1 ?? null);
  const m2 = moment(d2 ?? null);
  return direction === 'asc'
    ? m1.isBefore(m2)
      ? -1
      : m1.isAfter(m2)
      ? 1
      : 0
    : m1.isAfter(m2)
    ? -1
    : m1.isBefore(m2)
    ? 1
    : 0;
};

export const formatDateToStartOfDay = (date: Date | string) => moment(date).startOf("day").format("YYYY-MM-DDTHH:mm:ss");