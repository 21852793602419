import React, { useEffect, useState } from 'react';
import { GetMomentOfDate } from 'utils/dates/moment';
import { useSelector, useDispatch } from 'react-redux';
import { candidateDetailsSelection } from '../../../Profile/CandidateDetails.selector';
import { selectUser } from 'oidc/user.selectors';
import { postSellingNotes } from 'app/services/CandidateServices/CandidateServices';
import { useDecision } from '@optimizely/react-sdk';
import { ff_candidate_container_ui_v2 } from 'app/constants/FeatureFlags/Candidate/Keys';
import { candidateDetailActions } from '../../../Profile/CandidateDetails.redux';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import treeData from 'app/assets/jsons/placementStatusFilter.json';
import { isPositiveNumber, decodeBase64String } from 'app/helpers/componentHelpers';
import PersonalInfoCardUI from './CardDesigns/PersonalInfoCardUI';
import PersonalInfoCardUIV2 from './CardDesigns/PersonalInfoCardUIV2';
import {
  calenderViewableByUserRoles,
  formatSsn,
  lastFourViewableByUserRoles,
  ssnViewableByUserRoles,
} from './Supervisor/helpers/helper';
import { Info } from './types';
import { IsNameDobEditable } from '../../PersonalInfoTab/Form/helper';

const defaultValue: Info = {
  dateOfBirth: '',
  socialSecurityNumber: '',
  candidateSellingNotes: '',
  candidateSummary: '',
};

export const PersonalInfoCard = () => {
  const dispatch = useDispatch();
  /*Remove above piece of code during feature flag cleanup */
  const user = useSelector(selectUser);
  const [candidateContainerUIV2] = useDecision(ff_candidate_container_ui_v2);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const [info, setInfo] = useState<Info>(defaultValue);
  const [saveResult, setSaveResult] = useState<any>('');
  const [sellingNoteValue, setSellingNoteValue] = useState<any>(candidateDetails.candidateSellingNotes);
  const [sellingNoteValidation, setSellingNoteValidation] = useState<String | null>(null);
  const [isHandleClick, setIsHandleClick] = useState<boolean>(false);
  const [openTemplate, setOpenTemplate] = React.useState(false);
  const [isCalendarViewableByUser, setIsCalendarViewableByUser] = useState<boolean>(false);
  const [isSSNViewableByUser, setIsSSNViewableByUser] = useState<boolean>(false);
  const [isSSNLastFourViewableByUser, setIsSSNLastFourViewableByUser] = useState<boolean>(false);
  const [isGenderViewableByUser, setIsGenderViewableByUser] = useState<boolean>(false);
  const [isGenderEditableByUser, setIsGenderEditableByUser] = useState<boolean>(false);
  const userInfo = useSelector(selectUser);
  const [openTemplateConfirm, setOpenTemplateConfirm] = React.useState(false);
  const [canSelectDeclineToState, setCanSelectDeclineToState] = useState<boolean>(false);

  const placementStatuses = treeData.filter(item => item.name == 'In Submission' || item.name == 'Offer');
  const allowedStatuses: any[] = [];

  placementStatuses[0].children.map(item => {
    allowedStatuses.push(item.name);
  });
  placementStatuses[1].children.map(item => {
    allowedStatuses.push(item.name);
  });

  useEffect(() => {
    setSellingNoteValue(candidateDetails.candidateSellingNotes);
    if (candidateDetails) {
      const momentOfDOB = GetMomentOfDate(candidateDetails.dateOfBirth);
      const processedData: Info = {
        dateOfBirth: momentOfDOB.isValid() ? momentOfDOB.format('MM/DD/YYYY') : '',
        socialSecurityNumber: candidateDetails.socialSecurityNumber
          ? formatSsn(candidateDetails.socialSecurityNumber)
          : '',
        candidateSellingNotes: candidateDetails.candidateSellingNotes
          ? candidateDetails.candidateSellingNotes.replace(/(?:\\[rn])+/g, `\n`).replace(/(?:\\[r])+/g, `\r`)
          : '',
        candidateSummary: candidateDetails.candidateSummary
          ? decodeBase64String(candidateDetails.candidateSummary)
              .replace(/(?:\\[n])+/g, `\n`)
              .replace(/(?:\\[r])+/g, `\r`)
          : '',
      };
      setInfo(processedData);
    } else {
      setInfo(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    candidateDetails.candidateSellingNotes,
    candidateDetails.socialSecurityNumber,
    candidateDetails.candidateSellingNotes,
    candidateDetails.candidateSummary,
  ]);

  useEffect(() => {
    if (isPositiveNumber(candidateDetails.travelerId) && isPositiveNumber(candidateDetails.brandId)) {
      dispatch(
        candidateDetailActions.getEMRData({
          candidateId: candidateDetails.travelerId,
          brandId: candidateDetails.brandId,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateDetails.travelerId, candidateDetails.brandId]);

  //Selling Notes change Value function
  const handleChangeValue = value => {
    setSellingNoteValue(value);
    value.length > 1000 ? setSellingNoteValidation('Maximum Characters Reached') : setSellingNoteValidation(null);
  };

  //Selling notes template dialog
  const handleClose = () => {
    setOpenTemplate(false);
  };

  const handleOpenSellingNotes = value => {
    setOpenTemplate(value);
    setOpenTemplateConfirm(false);
  };

  //Handle Selling Note Save
  const handleSave = async data => {
    if (!sellingNoteValidation) {
      setSaveResult(1);
      const postBody = {
        candidateId: candidateDetails.travelerId,
        brandId: candidateDetails.brandId,
        currentEmployeeId: user.userInfo?.employeeId || 8410,
        currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
        sellingnotes: data,
      };
      const response = await postSellingNotes(postBody);
      if (response.status === 200) {
        setSaveResult(2);
        setTimeout(() => {
          setSaveResult('');
          setIsHandleClick(false);
        }, 5000);
        const updatedCandidateInfo = {
          ...candidateDetails,
          candidateSellingNotes: data,
        };
        dispatch(candidateDetailActions.setCandidateDetails(updatedCandidateInfo));
      } else setSaveResult(3);
    }
  };

  useEffect(() => {
    //Check for View Access
    setIsSSNViewableByUser(Authorized(ssnViewableByUserRoles, userInfo.userInfo));
    setIsCalendarViewableByUser(Authorized(calenderViewableByUserRoles, userInfo.userInfo));
    setIsSSNLastFourViewableByUser(Authorized(lastFourViewableByUserRoles, userInfo.userInfo));
    setIsGenderViewableByUser(!Authorized([userRoles.clinical_ClientContractSpecialist], userInfo.userInfo));

    const allowEditGender = IsNameDobEditable(
      candidateDetails?.placementStatus || '',
      candidateDetails?.placement?.placementInfo || [],
    );

    const hasRecruitmentRole = Authorized(
      [userRoles.recruitment_Leadership, userRoles.recruitment_TeamMember],
      userInfo.userInfo,
    );

    if (hasRecruitmentRole && allowEditGender) {
      setIsGenderEditableByUser(true);
    } else {
      setIsGenderEditableByUser(false);
    }

    if (
      Authorized(
        [
          userRoles.credentialing_Leadership,
          userRoles.credentialing_TeamMember,
          userRoles.clinical_QualificationsSpecialist,
        ],
        user.userInfo,
      )
    ) {
      setIsGenderEditableByUser(true);
    }
    if (allowedStatuses.slice(0, allowedStatuses.length - 2).includes(candidateDetails.placementStatus)) {
      setCanSelectDeclineToState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.userInfo, candidateDetails?.placementStatus]);

  // Reset Selling note Value
  const resetValue = () => {
    setSellingNoteValue(candidateDetails.candidateSellingNotes);
    setSaveResult('');
    setSellingNoteValidation(null);
  };
  return candidateContainerUIV2?.enabled ? (
    <PersonalInfoCardUIV2
      info={info}
      isCalendarViewableByUser={isCalendarViewableByUser}
      isSSNViewableByUser={isSSNViewableByUser}
      isSSNLastFourViewableByUser={isSSNLastFourViewableByUser}
      isGenderEditableByUser={isGenderEditableByUser}
      canSelectDeclineToState={canSelectDeclineToState}
      candidateDetails={candidateDetails}
      isGenderViewableByUser={isGenderViewableByUser}
      openTemplateConfirm={openTemplateConfirm}
      setOpenTemplateConfirm={setOpenTemplateConfirm}
      handleOpenSellingNotes={handleOpenSellingNotes}
      openTemplate={openTemplate}
      handleClose={handleClose}
      sellingNoteValue={sellingNoteValue}
      setOpenTemplate={setOpenTemplate}
      handleChangeValue={handleChangeValue}
      isHandleClick={isHandleClick}
      handleSave={handleSave}
      resetValue={resetValue}
      saveResult={saveResult}
      sellingNoteValidation={sellingNoteValidation}
      setIsHandleClick={setIsHandleClick}
    />
  ) : (
    <PersonalInfoCardUI
      info={info}
      isCalendarViewableByUser={isCalendarViewableByUser}
      isSSNViewableByUser={isSSNViewableByUser}
      isSSNLastFourViewableByUser={isSSNLastFourViewableByUser}
      isGenderEditableByUser={isGenderEditableByUser}
      canSelectDeclineToState={canSelectDeclineToState}
      candidateDetails={candidateDetails}
      isGenderViewableByUser={isGenderViewableByUser}
      openTemplateConfirm={openTemplateConfirm}
      setOpenTemplateConfirm={setOpenTemplateConfirm}
      handleOpenSellingNotes={handleOpenSellingNotes}
      openTemplate={openTemplate}
      handleClose={handleClose}
      sellingNoteValue={sellingNoteValue}
      setOpenTemplate={setOpenTemplate}
      handleChangeValue={handleChangeValue}
      isHandleClick={isHandleClick}
      handleSave={handleSave}
      resetValue={resetValue}
      saveResult={saveResult}
      sellingNoteValidation={sellingNoteValidation}
      setIsHandleClick={setIsHandleClick}
    />
  );
};
