import { missingField } from 'app/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TransportationRow } from './TransportationTable';
import {
  selectEditableRequirementTypes,
  selectOfferInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { useSelector } from 'react-redux';
import PopOutIcon from 'app/assets/images/IconImages/PopOutIcon.svg';
import { Link, Typography } from 'amn-ui-core';

export const Itinerary = () => {
  const { t } = useTranslation();
  const offerInfo = useSelector(selectOfferInfo);

  
  const pathname = offerInfo?.transportation?.departureItineraryLink?.data?.pathname || '';
  const { departureItineraryLink } = useSelector(selectEditableRequirementTypes);
  return (
    <TransportationRow
      id="placement-offer-transportation-itinerary"
      label={t('placement.profile.summary.offer.transportation.itinerary')}
      requirement={departureItineraryLink?.requirement}
      controlComponent={
        pathname ? (
          <Link href={pathname} target="_blank">
            <Typography variant="body1">{t('placement.profile.summary.offer.transportation.yourItinerary')}</Typography>
            <img src={PopOutIcon} alt="PopOut" fill="#8e8e8e" />
          </Link>
        ) : (
          <div>{missingField}</div>
        )
      }
    />
  );
};
