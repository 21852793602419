import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { defaultPricingFilters, defaultValues } from './PricingFilterUtils';
import { HomePage } from 'app/layout/pages/HomePage';
import { PricingDeskFilters } from './PricingDeskFilters';
import { gridStateActions } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { PricingOrderBonus } from './PricingOrderBonus';
import { selectGridState } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';
import { revalidateChipSelection } from '@AMIEWEB/GlobalSearch/helper';
import { FilterSearchChips } from '@AMIEWEB/GlobalSearch/Common/FilterSearchChips';

export const PricingDesk = () => {
  const dispatch = useDispatch();
  const formMethods = useForm({ defaultValues: defaultPricingFilters });
  const { handleSubmit } = formMethods;
  const { filterAttributes } = useSelector(selectGridState);
  const [resetGridParams, setResetGridParams] = useState<boolean>(false);
  const onApplyFilter = (values, newSelectionChips?) => {
    const selectedChipFilters =
      newSelectionChips || revalidateChipSelection(values, filterAttributes?.selectedChipFilters || []);

    dispatch(
      gridStateActions.setFilterAttributes({
        ...filterAttributes,
        filterObject: Object.entries(values),
        selectedChipFilters,
      }),
    );
  };

  useEffect(() => {
    dispatch(
      gridStateActions.setFilterAttributes({
        filterObject: Object.entries(defaultPricingFilters),
        selectedChipFilters: {
          ...defaultValues,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(filter => {
          onApplyFilter(filter);
        })}
      >
        <HomePage
          showSidePanel={false}
          title=""
          filters={<PricingDeskFilters setResetGridParams={setResetGridParams} />}
          chips={<FilterSearchChips onApplyFilter={onApplyFilter} />}
          table={<PricingOrderBonus resetGridParams={resetGridParams} setResetGridParams={setResetGridParams} />}
        />
      </form>
    </FormProvider>
  );
};
