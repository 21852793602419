import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, Grid, Typography, IconButton, Popper, ClickAwayListener, MenuItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Avatar, Theme } from 'amn-ui-core';
import { ICueNoteResponse } from 'app/models/Tasks/Tasks';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ReplyCard } from './ReplyCard';
import { taskDetailsActions } from '../store/Tasks.redux';
import { TaskTooltip } from 'app/components/Common/Tooltips/TaskTooltip';
import { displaySentDate } from '../CreateTask/FormatData';
import { selectUser } from 'oidc/user.selectors';
import { CueNoteTooltip } from '@AMIEWEB/Common/Tooltips/CueNoteTooltip';
import { WhiteTooltip } from '@AMIEWEB/Common';
import { selectCueRecipient } from '../store/Tasks.selectors';
import {
  findParentCueSender,
  findRecipient,
  hasDuplicateSender,
  hasSharedProfile,
  isCoverageForUser,
  isCoveredByUser,
  isRecipient,
  hasParentOrCoverage,
  isSender,
  isSentBySharedDeskUser,
  isThirdPartyView,
} from '../EditTask/helper';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    border: ' 1px solid #ECECEC',
    borderColor: '#c5c4c4',
    flexWrap: 'nowrap',
    background: '#B3E0F4 0% 0% no-repeat padding-box',
    width: '100%',
    paddingTop: '10px',
    float: 'right',
    marginBottom: '0px !important',
    boxShadow: 'none',
  },
  avatarContainer: {
    display: 'initial',
    maxWidth: 'fit-content',
    margin: '0px 8px 0 0',
  },
  avatar: {
    width: '18px',
    height: '18px',
    textAlign: 'center',
    font: 'normal normal normal 8px/10px Roboto',
    letterSpacing: '0px',
    color: '#FFFFFF',
    opacity: 1,
    whiteSpace: 'nowrap',
  },
  senderAvatar: {
    background: '#006FB9',
    marginBottom: '8px',
  },
  recipientAvatar: {
    background: '#34B233',
    marginBottom: '8px',
  },
  coveredByAvatar: {
    background: theme.palette.secondary.main,
    marginBottom: '-4px',
  },
  header: {
    alignItems: 'center',
  },
  headerLeft: {
    justifyContent: 'space-between',
    display: 'inline-flex',
    width: 'inherit',
  },
  headerRight: {
    marginRight: '5px',
    minWidth: '10px',
    minHeight: '10px',
    position: 'absolute',
    right: '0',
  },
  cardContent: {
    padding: '0',
    paddingTop: '2px',
    paddingBottom: '0px !important',
    width: '15rem',
  },
  sentByName: {
    textAlign: 'left',
    fontSize: '12px',
    fontFamily: 'Roboto',
    lineHeight: '20px',
    letterSpacing: '0px',
    color: '#555555',
    opacity: 1,
    fontWeight: 'bold',
    marginLeft: '10px',
    display: 'inline',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  obo: {
    textAlign: 'left',
    fontSize: '10px',
    color: '#333333',
    marginLeft: '10px',
  },
  timestamp: {
    textAlign: 'left',
    font: 'normal normal 400 10px/20px Roboto',
    letterSpacing: '0px',
    color: '#333333',
    opacity: 1,
    marginLeft: '10px',
    marginRight: '20px',
    whiteSpace: 'nowrap',
  },
  cueContent: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    color: '#333333',
    textAlign: 'left',
    margin: '5px 10px 5px 10px',
    overflow: 'hidden',
  },
  userCard: {
    background: '#e0f5ff 0% 0% no-repeat padding-box',
    border: '0px',
    borderRadius: '10px 10px 0px 10px',
    width: '100%',
    paddingTop: '6px',
    paddingBottom: '3px',
    overflow: 'inherit',
  },
  defaultCard: {
    background: '#F5F5F5 0% 0% no-repeat padding-box',
    border: '0px',
    borderRadius: '10px 10px 10px 0px',
    width: '100%',
    paddingTop: '6px',
    paddingBottom: '3px',
    overflow: 'inherit',
  },
  expandBtn: {
    fontSize: '14px',
    color: '#006fb9',
    cursor: 'pointer',
    width: 'fit-content',
    textDecoration: 'underline',
    fontFamily: 'Roboto',
    margin: '0px 10px 10px 10px',
  },
  cueContainer: {
    display: 'flex',
    float: 'right',
    padding: '10px 0px 10px 0px',
  },
  moreIcon: {
    padding: '0px !important',
    display: 'flex',
  },
  menu: {
    height: '40px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 8px #0000004D',
    borderRadius: '4px',
    opacity: '1',
    position: 'relative',
    marginRight: '3rem',
    marginTop: '-1rem',
    zIndex: 999,
  },
  menuItem: {
    width: '100%',
    height: '40px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    opacity: '1',
    borderRadius: '4px',
    boxShadow: '0px 2px 8px #0000004D',
  },
  singleLineHeight: {
    height: '24px',
  },
  singleLineWithOboHeight: {
    height: '50px',
  },
}));

export const CueNoteItem = ({
  cueNote,
  isCurrentUser,
  showFullText = false,
}: {
  cueNote: ICueNoteResponse;
  isCurrentUser: boolean;
  showFullText?: boolean;
}) => {
  const userInfo = useSelector(selectUser);
  const cueRecipients = useSelector(selectCueRecipient);
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [textHeight, setTextHeight] = useState<any>('auto');
  const [sentDate, setSentDate] = useState('');
  const [showMoreContent, setShowMoreContent] = useState<Boolean>(false);
  const [anchorEl2, setAnchorEl2] = useState<HTMLElement | null>(null);
  const [show, setShow] = useState<Boolean>(false);
  const [displayMenu, setDisplayMenu] = useState<Boolean>(false);
  const ref = React.useRef<any>(null);
  const COLLAPSED_HEIGHT = cueNote?.parentCueNote ? '160px' : '70px';
  const sender = 'sender';
  const recipient = 'recipient';
  const parent = {
    cueNoteLegacyTaskId: cueNote?.taskId,
    sentById: cueNote?.senderId,
    sentByFirstName: cueNote?.sentByFirstName,
    sentByLastName: cueNote?.sentByLastName,
    recipientId: cueNote?.recipientId || 0,
    recipientFirstName: cueNote?.recipientFirstName || '',
    recipientLastName: cueNote?.recipientLastName || '',
    sentDate: cueNote?.sentDate,
    text: cueNote?.text,
    coverageDetail: cueNote?.coverageDetail,
    sharedDeskAssociatedUser: cueNote?.sharedDeskAssociatedUser,
  };

  const coveredByUser = isCoveredByUser(cueNote, userInfo);

  const coverageForUser = isCoverageForUser(cueNote, userInfo);

  const sentBySharedDeskUser = isSentBySharedDeskUser(cueNote);

  const sharedProfile = hasSharedProfile(userInfo);

  const cueNoteRecipient = isRecipient(cueNote, userInfo);

  const cueNoteSender = isSender(cueNote, userInfo);

  const duplicateSender = hasDuplicateSender(cueNote);

  const ParentOrCoverage = hasParentOrCoverage(cueNote);

  const cueNoteRecipients = findRecipient(cueRecipients, cueNote);

  const parentCueSender = findParentCueSender(cueRecipients, cueNote);

  const thirdPartyView = isThirdPartyView(cueNote, coveredByUser, userInfo);

  const handleClick = event => {
    setAnchorEl2(event.currentTarget);
    setDisplayMenu(prev => !prev);
  };

  const handleMenuClose = event => {
    setAnchorEl2(null);
    setDisplayMenu(false);
  };

  //TODO : Will move to Transformers
  const getInitials = fullname => {
    if (fullname) {
      const fullName = fullname.split(' ');
      if (fullName?.length > 1) {
        const value = fullName?.shift()?.charAt(0) + fullName?.pop()?.charAt(0);
        return value;
      } else {
        const initial = fullName?.shift()?.charAt(0);
        return initial;
      }
    }
  };

  const formatTooltipText = (role: string, cueNote: ICueNoteResponse) => {
    const tooltipContent =
      role === sender ? (
        <span>
          <b>{t('notification.taskSidePanel.cueNotes.senderTooltip')}</b>
          {cueNote?.sentByFirstName + ' ' + cueNote?.sentByLastName}
        </span>
      ) : (
        <span>
          <b>{t('notification.taskSidePanel.cueNotes.cuedToTooltip')}</b>
          {cueNote?.recipientId
            ? cueNote?.recipientFirstName + ' ' + cueNote?.recipientLastName
            : cueNote?.parentCueNote?.sentByFirstName + ' ' + cueNote?.parentCueNote?.sentByLastName}
        </span>
      );
    return tooltipContent;
  };

  const getCoveredByName = () => {
    return `${cueNote?.coverageDetail?.coveredByEmployeeFirstName} ${cueNote?.coverageDetail?.coveredByEmployeeLastName}`;
  };
  const getSharedDeskUserByName = () => {
    return `${cueNote?.sharedDeskAssociatedUser?.firstName} ${cueNote?.sharedDeskAssociatedUser?.lastName}`;
  };
  const getSenderName = () => {
    return `${cueNote?.sentByFirstName} ${cueNote?.sentByLastName}`;
  };

  const formatTooltipThirdPartyText = (cueNote: ICueNoteResponse) => {
    const tooltipContent = (
      <>
        <div>{getCoveredByName()} </div>
        <div>
          {`
          ${t('notification.taskSidePanel.cueNotes.obo')}
          ${getSenderName()}`}
        </div>
      </>
    );
    return tooltipContent;
  };

  const formatSharedDeskUserByName = (cueNote: ICueNoteResponse) => {
    const tooltipContent = (
      <>
        <div>{getSharedDeskUserByName()} </div>
        <div>{`${t('notification.taskSidePanel.cueNotes.obo')} ${getSenderName()}`}</div>
      </>
    );
    return tooltipContent;
  };

  useEffect(() => {
    setSentDate(displaySentDate(cueNote));
    if (showFullText) {
      setShowMoreContent(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cueNote]);

  const renderAvatar = (tooltipContent, avatarClass, initials) => {
    if (!initials || !initials.trim()) {
      return null;
    }

    return (
      <TaskTooltip tooltipContent={tooltipContent}>
        <Avatar className={`${classes.avatar} ${avatarClass}`}>{initials}</Avatar>
      </TaskTooltip>
    );
  };

  const getRecipientInitials = () => {
    if (cueNote?.recipientId) {
      return getInitials(`${cueNote?.recipientFirstName} ${cueNote?.recipientLastName}`);
    } else if (cueNote?.parentCueNote) {
      return getInitials(`${cueNote?.parentCueNote?.sentByFirstName} ${cueNote?.parentCueNote?.sentByLastName}`);
    }
    return '';
  };

  const renderAvatarsForCueNote = () => {
    const commonRecipientTooltip = formatTooltipText(recipient, cueNote);
    const commonSenderTooltip = formatTooltipText(sender, cueNote);
    return (
      <>
        {cueNoteSender &&
          (cueNoteRecipients || parentCueSender || !cueNote?.parentCueNote) &&
          !cueNote?.coverageDetail &&
          renderAvatar(commonRecipientTooltip, classes.recipientAvatar, getRecipientInitials())}

        {coveredByUser &&
          cueNote?.coverageDetail &&
          renderAvatar(commonRecipientTooltip, classes.recipientAvatar, getRecipientInitials())}

        {cueNoteRecipient &&
          !sentBySharedDeskUser &&
          !cueNote?.coverageDetail &&
          !cueNoteSender &&
          !cueNote?.parentCueNote && (
            <>
              {renderAvatar(commonSenderTooltip, classes.senderAvatar, getInitials(getSenderName()))}
              {renderAvatar(commonRecipientTooltip, classes.recipientAvatar, getRecipientInitials())}
            </>
          )}

        {cueNoteRecipient &&
          sentBySharedDeskUser &&
          !cueNote?.coverageDetail &&
          !cueNote?.parentCueNote &&
          !duplicateSender && (
            <>
              <CueNoteTooltip placement="bottom" tooltipContent={formatSharedDeskUserByName(cueNote)}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                  {renderAvatar('', classes.coveredByAvatar, getInitials(getSharedDeskUserByName()))}
                  {renderAvatar('', classes.senderAvatar, getInitials(getSenderName()))}
                </div>
              </CueNoteTooltip>
              {renderAvatar(commonRecipientTooltip, classes.recipientAvatar, getRecipientInitials())}
            </>
          )}

        {cueNoteRecipient && cueNote?.coverageDetail && !cueNote?.parentCueNote && !duplicateSender && (
          <>
            <CueNoteTooltip placement="bottom" tooltipContent={formatTooltipThirdPartyText(cueNote)}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                {renderAvatar('', classes.coveredByAvatar, getInitials(getCoveredByName()))}
                {renderAvatar('', classes.senderAvatar, getInitials(getSenderName()))}
              </div>
            </CueNoteTooltip>
            {renderAvatar(commonRecipientTooltip, classes.recipientAvatar, getRecipientInitials())}
          </>
        )}

        {cueNoteRecipient && cueNote?.parentCueNote && !duplicateSender && (
          <>
            {renderAvatar(commonSenderTooltip, classes.senderAvatar, getInitials(getSenderName()))}
            {cueNoteRecipients && renderAvatar(commonRecipientTooltip, classes.recipientAvatar, getRecipientInitials())}
          </>
        )}

        {thirdPartyView && !cueNoteRecipient && (!duplicateSender || !ParentOrCoverage) && (
          <>
            <CueNoteTooltip placement="bottom" tooltipContent={formatTooltipThirdPartyText(cueNote)}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                {renderAvatar('', classes.coveredByAvatar, getInitials(getCoveredByName()))}
                {renderAvatar('', classes.senderAvatar, getInitials(getSenderName()))}
              </div>
            </CueNoteTooltip>
            {(cueNoteRecipients || parentCueSender) &&
              renderAvatar(commonRecipientTooltip, classes.recipientAvatar, getRecipientInitials())}
          </>
        )}

        {!cueNoteRecipient && !cueNoteSender && !cueNote?.coverageDetail && !duplicateSender && (
          <>
            {renderAvatar(commonSenderTooltip, classes.senderAvatar, getInitials(getSenderName()))}
            {(cueNote?.recipientId || cueNote?.parentCueNote) &&
              renderAvatar(
                commonRecipientTooltip,
                classes.recipientAvatar,
                cueNote?.recipientId
                  ? getInitials(cueNote?.recipientFirstName + ' ' + cueNote?.recipientLastName)
                  : getInitials(cueNote?.parentCueNote?.sentByFirstName + ' ' + cueNote?.parentCueNote?.sentByLastName),
              )}
          </>
        )}
      </>
    );
  };

  return (
    <Grid data-testid="task-item-container" className={classes.cueContainer}>
      <Grid container xs={12} className={classes.avatarContainer}>
        {renderAvatarsForCueNote()}
      </Grid>
      <Card
        className={`${classes.root} ${isCurrentUser || coveredByUser ? classes.userCard : classes.defaultCard}`}
        data-testid="task-item"
        style={{ marginBottom: '12px', cursor: 'default', whiteSpace: 'pre-line' }}
        onMouseEnter={async () => {
          cueNote?.text && setShow(true);
        }}
        onMouseLeave={async () => {
          cueNote?.text && setShow(false);
        }}
      >
        <CardContent
          className={`${classes.cardContent} ${
            !cueNote?.text ? (sentBySharedDeskUser ? classes.singleLineWithOboHeight : classes.singleLineHeight) : null
          }`}
        >
          {thirdPartyView ? (
            <>
              <Grid container xs={12} sx={{ justifyContent: 'flex-end' }}>
                <div className={classes.timestamp}>{sentDate}</div>
              </Grid>
              <Grid container xs={12} sx={{ width: '96%' }} className={classes.header}>
                <div className={classes.headerLeft}>
                  <div className={classes.sentByName}>
                    {`${getCoveredByName()} ${t('notification.taskSidePanel.cueNotes.obo')}
                      ${getSenderName()}`}
                  </div>
                </div>
                <div className={classes.headerRight}>
                  {show || displayMenu ? (
                    <>
                      <IconButton color="primary" className={classes.moreIcon} onClick={handleClick} size="small">
                        <MoreVertIcon />
                      </IconButton>
                      <Popper
                        open={Boolean(anchorEl2)}
                        anchorEl={anchorEl2}
                        role={undefined}
                        style={{ zIndex: '999' }}
                        className={classes.menu}
                        placement={'bottom-end'}
                      >
                        <ClickAwayListener onClickAway={handleMenuClose}>
                          <MenuItem
                            className={classes.menuItem}
                            onClick={e => {
                              dispatch(taskDetailsActions.setCueNoteReplyParent(parent));
                              handleMenuClose(e);
                            }}
                          >
                            {t('notification.taskSidePanel.cueNotes.replyCueBtn')}
                          </MenuItem>
                        </ClickAwayListener>
                      </Popper>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </Grid>
            </>
          ) : (
            <Grid container xs={12} className={classes.header}>
              {sentBySharedDeskUser && (cueNoteRecipient || !sharedProfile) && !parentCueSender && !duplicateSender && (
                <Grid container xs={12} sx={{ justifyContent: 'flex-end' }}>
                  <div className={classes.timestamp}>{sentDate}</div>
                </Grid>
              )}
              <div className={classes.headerLeft}>
                {coveredByUser || coverageForUser ? (
                  <WhiteTooltip
                    placement="bottom"
                    arrow
                    title={
                      coveredByUser
                        ? `${t('notification.taskSidePanel.cueNotes.coverageFor')} ${getSenderName()}`
                        : `${t('notification.taskSidePanel.cueNotes.coveredBy')} ${getCoveredByName()}`
                    }
                  >
                    <div className={classes.obo}>{`${t('notification.taskSidePanel.cueNotes.obo')}`}</div>
                  </WhiteTooltip>
                ) : (
                  <>
                    {sentBySharedDeskUser ? (
                      <>
                        {cueNoteSender ? (
                          <WhiteTooltip placement="bottom" arrow title={formatSharedDeskUserByName(cueNote)}>
                            <div className={classes.obo}>{`${t('notification.taskSidePanel.cueNotes.obo')}`}</div>
                          </WhiteTooltip>
                        ) : (
                          <Grid container xs={12} sx={{ width: '96%' }} className={classes.header}>
                            <div className={classes.headerLeft}>
                              <div className={classes.sentByName}>
                                {`${getSharedDeskUserByName()} ${t(
                                  'notification.taskSidePanel.cueNotes.obo',
                                )} ${getSenderName()}`}
                              </div>
                            </div>
                          </Grid>
                        )}
                      </>
                    ) : (
                      !isCurrentUser && (
                        <div
                          className={classes.sentByName}
                        >{`${cueNote?.sentByFirstName} ${cueNote?.sentByLastName}`}</div>
                      )
                    )}
                  </>
                )}
                {!sentBySharedDeskUser ? (
                  <div className={classes.timestamp}>{sentDate}</div>
                ) : (
                  (cueNoteSender || cueNote?.coverageDetail) && <div className={classes.timestamp}>{sentDate}</div>
                )}
              </div>
              <div className={classes.headerRight}>
                {show || displayMenu ? (
                  <>
                    <IconButton color="primary" className={classes.moreIcon} onClick={handleClick} size="small">
                      <MoreVertIcon />
                    </IconButton>
                    <Popper
                      open={Boolean(anchorEl2)}
                      anchorEl={anchorEl2}
                      role={undefined}
                      style={{ zIndex: '999' }}
                      className={classes.menu}
                      placement={'bottom-end'}
                    >
                      <ClickAwayListener onClickAway={handleMenuClose}>
                        <MenuItem
                          className={classes.menuItem}
                          onClick={e => {
                            dispatch(taskDetailsActions.setCueNoteReplyParent(parent));
                            handleMenuClose(e);
                          }}
                        >
                          {t('notification.taskSidePanel.cueNotes.replyCueBtn')}
                        </MenuItem>
                      </ClickAwayListener>
                    </Popper>
                  </>
                ) : (
                  ''
                )}
              </div>
            </Grid>
          )}
          <Grid container xs={12}>
            {cueNote?.text !== null &&
              (!showMoreContent ? (
                <>
                  <Typography
                    id="typography"
                    variant="body1"
                    className={classes.cueContent}
                    style={{
                      lineHeight: '20px',
                      height: cueNote?.text && cueNote?.text?.length <= 120 ? 'auto' : COLLAPSED_HEIGHT,
                      minWidth: '13.5rem',
                    }}
                    ref={ref}
                  >
                    <>
                      {cueNote?.parentCueNote && (
                        <ReplyCard cueNote={cueNote?.parentCueNote} closeButton={false} bgColor={'#FFFFFF'} />
                      )}
                      <span id="cueText">{cueNote?.text}</span>
                    </>
                  </Typography>
                  {cueNote?.text && cueNote?.text.length > 120 && (
                    <div
                      role="button"
                      tabIndex={-1}
                      className={classes.expandBtn}
                      color="primary"
                      onClick={() => {
                        setShowMoreContent(true);
                        setTextHeight('auto');
                      }}
                      onKeyDown={() => {
                        setShowMoreContent(true);
                        setTextHeight('auto');
                      }}
                    >
                      {t('notification.taskSidePanel.cueNotes.showMore')}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <Typography
                    variant="body1"
                    className={classes.cueContent}
                    ref={ref}
                    style={{ lineHeight: '20px', height: textHeight, minWidth: '13.5rem' }}
                  >
                    <>
                      {cueNote?.parentCueNote && (
                        <ReplyCard cueNote={cueNote?.parentCueNote} closeButton={false} bgColor={'#FFFFFF'} />
                      )}
                      <span id="cueText">{cueNote?.text}</span>
                    </>
                  </Typography>
                  {cueNote?.text && cueNote?.text?.length > 120 && (
                    <div
                      role="button"
                      tabIndex={-1}
                      className={classes.expandBtn}
                      color="primary"
                      onClick={() => {
                        setShowMoreContent(false);
                        setTextHeight(COLLAPSED_HEIGHT);
                      }}
                      onKeyDown={() => {
                        setShowMoreContent(false);
                        setTextHeight(COLLAPSED_HEIGHT);
                      }}
                    >
                      {t('notification.taskSidePanel.cueNotes.showLess')}
                    </div>
                  )}
                </>
              ))}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
