import { candidatePreferenceMatch } from 'app/constants';
import bestBets from 'app/assets/jsons/BestBets.json';
import { convertToFormat, convertTZ } from 'app/helpers/dateHelper';
import {
  SkillSet,
  Location,
  FilesAwaitingSubmission,
  PlacementFileSent,
  IOrderDetails,
  editOrder,
} from 'app/models/Orders/OrderDetails';
import shiftIDList from 'app/assets/jsons/OrderCreation/OrderShifts.json';
import { orderBy } from 'lodash';
import { OrderReason } from 'app/constants/OrderCreationForm';
import {
  candidatePreferenceMatchData,
  candidatePreferenceMatchResponse,
} from 'app/models/Candidate/CandidatePreferenceMatch';
import moment from 'moment';
import { IFacilityDetails } from '../../Facility/FacilityStore/Facility.redux';
import { isValid } from 'date-fns';
import { AMNDivision } from 'app/models/enums/AMNDivisionType';
import { IUser } from 'oidc/user.redux';
import { STRING_VALIDATION_MESSAGE, UPDATE_MESSAGE_TEMPLATE } from '@AMIEWEB/NewOrder/constants';

export const getEmptyOrderDetails = (): IOrderDetails => {
  return {
    orderId: '',
    orderTypeId: 0,
    facility: {
      facilityId: 0,
      name: '',
      facilityBestBetsId: 0,
      creditStatusId: 0,
      businessRelationship: '',
    },
    unit: {
      name: '',
      unitId: 0,
      facilityId: 0,
      facilityState: '',
      costCenter: '',
      floor: '',
      experience: {
        isNewGrad: false,
        skillSets: [
          {
            disciplineId: 0,
            discipline: '',
            specialtyId: 0,
            specialty: '',
            isRequired: false,
          },
        ],
      },
      reference: {
        isRequired: false,
        numberOfReferenceRequired: 0,
        numberOfReferenceToRFO: 0,
        withinMonths: false,
        referenceFromSupervisor: false,
      },
      nurse: null,
      nurseRatio: null,
      patient: null,
      status: '',
      bestBets: '',
      bestBetsId: 0,
      unitSize: null,
      externalJobDescription: '',
      internalNotes: '',
      travelExperience: null,
      qualification: {
        activeLicense: false,
        compactLicense: false,
        emr: null,
        licenses: [
          {
            requirementTypeId: 0,
            abbreviation: '',
            isRequired: false,
            documentAttachmentRequired: null,
          },
        ],
        certifications: [
          {
            requirementTypeId: 0,
            abbreviation: '',
            isRequired: false,
            documentAttachmentRequired: null,
          },
        ],
        skillChecklists: [
          {
            questionId: 0,
            text: '',
            isRequired: false,
            yearsOfExperience: 0,
          },
        ],
      },
      virtualRating: [''],
      orderPriorities: [
        {
          id: 0,
          description: '',
        },
      ],
      float: null,
      flexibleSchedule: null,
      nonStandardRequirement: null,
      createdByVersion: '',
      preferredQualifications: '',
      requiredQualifications: '',
      currentUserId: 0,
      fyre: null,
    },
    location: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
      regionId: 0,
      region: '',
      phoneNumber: '',
    },
    numOfJobMatches: 0,
    positionsAvailable: 0,
    numOfFilesWfcRfo: 0,
    numOfFilesSent: 0,
    weeklyGrossPay: 0,
    orderBillRate: 0,
    vmsBillRate: null,
    startDate: null,
    endDate: null,
    numOfWeeks: 0,
    dateCreated: '',
    maxFilesSent: false,
    division: '',
    orderType: '',
    technologyVendor: '',
    staffingVendor: '',
    activeBonus: false,
    payCycle: 0,
    fees: 0,
    radiusRules: 0,
    gww: false,
    contacts: {
      recruiter: { userId: 0, firstName: '', lastName: '', email: '' },
      accountManager: { userId: 0, firstName: '', lastName: '', email: '' },
      regionalDirector: { userId: 0, firstName: '', lastName: '', email: '' },
      credentialingAnalyst: { userId: 0, firstName: '', lastName: '', email: '' },
      clinicalManager: { userId: 0, firstName: '', lastName: '', email: '' },
      verifiedBy: { userId: 0, firstName: '', lastName: '', email: '' },
    },
    dateVerified: '',
    jobDescription: '',
    yearsOfExperience: 0,
    shifts: [{ id: 0, description: '' }],
    submissionRequirements: '',
    preferredQualifications: '',
    internalNotes: '',
    skillsets: [{ disciplineId: 0, discipline: '', specialtyId: 0, specialty: '' }],
    clinicalManager: undefined,
    flexibleDates: false,
    asap: false,
    reasonForMaxFilesSent: '',
    hideFacilityDescription: false,
    suppressExternalPosting: false,
    clinicalFellowship: false,
    newGrad: false,
    expectedHours: null,
    guaranteedHours: null,
    orderReasonId: 0,
    vmsOrderNumber: '',
    webLink: null,
    orderPriorities: [{ orderPriorityTypeId: 0, description: '' }],
    orderScore: 0,
    lastUpdatedMaxFilesSent: '',
    orderCurrentPlacements: {
      totalPlacements: 0,
      booked: 0,
      interviewing: 0,
      offered: 0,
      onAssignment: 0,
      inSubmission: 0,
      lastBookedDate: null,
      placementStartDate: null,
    },
    orderDecrementReasonID: 0,
    declineFiles: false,
    amieOrdersTimestamp: '',
    groupedSkillsets: [],
  };
};

export const formatData = (response: IOrderDetails): IOrderDetails => {
  return {
    orderId: response.orderId,
    orderTypeId: response.orderTypeId,
    facility: {
      facilityId: response.facility.facilityId,
      name: response.facility.name,
      facilityBestBetsId: response.facility.facilityBestBetsId,
      creditStatusId: response.facility.creditStatusId,
      businessRelationship: response.facility.businessRelationship,
    },
    unit: {
      unitId: response.unit.unitId,
      name: response.unit.name,
      costCenter: response.unit.costCenter,
      floor: response.unit.floor,
      experience: response.unit.experience,
      reference: response.unit.reference,
      nurseRatio: response.unit.nurseRatio,
      unitSize: response.unit.unitSize,
      travelExperience: response.unit.travelExperience,
      qualification: response.unit.qualification,
      virtualRating: response.unit.virtualRating,
      float: response.unit.float,
      flexibleSchedule: response.unit.flexibleSchedule,
      nonStandardRequirement: response.unit.nonStandardRequirement,
      createdByVersion: response.unit?.createdByVersion,
      fyre: response.unit?.fyre,
    },
    location: {
      address1: response.location.address1,
      address2: response.location.address2,
      city: response.location.city,
      state: response.location.state,
      region: response.location.region,
      regionId: response.location.regionId,
      zipCode: response.location.zipCode,
      country: response.location.country,
      phoneNumber: response.location.phoneNumber,
    },
    numOfJobMatches: response.numOfJobMatches,
    positionsAvailable: response.positionsAvailable,
    numOfFilesWfcRfo: response.numOfFilesWfcRfo,
    numOfFilesSent: response.numOfFilesSent,
    weeklyGrossPay: response.weeklyGrossPay,
    orderBillRate: response.orderBillRate,
    vmsBillRate: response.vmsBillRate,
    startDate: response.startDate,
    endDate: response.endDate,
    numOfWeeks: response.numOfWeeks,
    dateCreated: response.dateCreated,
    maxFilesSent: response.maxFilesSent ? true : false,
    lastUpdatedMaxFilesSent: response.lastUpdatedMaxFilesSent,
    division: response.division,
    orderType: response.orderType,
    technologyVendor: response.technologyVendor,
    staffingVendor: response.staffingVendor,
    activeBonus: response.activeBonus,
    payCycle: response.payCycle,
    fees: response.fees,
    radiusRules: response.radiusRules,
    gww: response.gww,
    contacts: response.contacts,
    dateVerified: response.dateVerified,
    jobDescription: response.jobDescription,
    yearsOfExperience: response.yearsOfExperience,
    shifts: response.shifts,
    submissionRequirements: response.submissionRequirements,
    preferredQualifications: response.preferredQualifications,
    internalNotes: response.internalNotes,
    skillsets: response.skillsets,
    clinicalManager: response.clinicalManager,
    flexibleDates: response.flexibleDates ? true : false,
    asap: response.asap ? true : false,
    reasonForMaxFilesSent: response.reasonForMaxFilesSent,
    hideFacilityDescription: response.hideFacilityDescription ? true : false,
    suppressExternalPosting: response.suppressExternalPosting ? true : false,
    clinicalFellowship: response.clinicalFellowship ? true : false,
    newGrad: response.newGrad ? true : false,
    expectedHours: response.expectedHours,
    guaranteedHours: response.guaranteedHours,
    orderReasonId: response.orderReasonId,
    vmsOrderNumber: response.vmsOrderNumber,
    webLink: response.webLink,
    orderPriorities: response.orderPriorities,
    orderScore: response.orderScore,
    orderCurrentPlacements: {
      ...(response.orderCurrentPlacements && response.orderCurrentPlacements),
      lastBookedDate: convertToFormat(response.orderCurrentPlacements?.lastBookedDate || null, 'MM/DD/yyyy'),
      placementStartDate: convertToFormat(response.orderCurrentPlacements?.placementStartDate || null, 'MM/DD/yyyy'),
    },
    orderDecrementReasonID: response.orderDecrementReasonID,
    declineFiles: response.declineFiles,
    amieOrdersTimestamp: response.amieOrdersTimestamp,
    groupedSkillsets: response.groupedSkillsets || [],
  };
};

export const getEmptyUnit = () => {
  return {
    name: '',
    unitId: 0,
    facilityId: 0,
    facilityState: '',
    costCenter: '',
    floor: '',
    experience: {
      isNewGrad: false,
      skillSets: [],
    },
    reference: {
      isRequired: false,
      numberOfReferenceRequired: 0,
      numberOfReferenceToRFO: 0,
      withinMonths: false,
      referenceFromSupervisor: false,
    },
    nurseRatio: null,
    fyreIntegration: null,
    status: '',
    bestBets: '',
    bestBetsId: 0,
    unitSize: null,
    externalJobDescription: '',
    internalNotes: '',
    travelExperience: null,
    qualification: null,
    virtualRating: [],
    orderPriorities: [],
    float: null,
    flexibleSchedule: null,
    nonStandardRequirement: null,
    currentUserId: 0,
    yearsOfExperience: null,
  };
};

export const getEmptyFacility = () => {
  return {
    facilityResponse: {
      facilityId: 0,
      facilityName: '',
      healthSystemName: '',
      bestBets: '',
      bestBetsId: 0,
      employeeContacts: {
        accountManager: {
          userId: 0,
          firstName: '',
          lastName: '',
        },
        regionalDirector: {
          userId: 0,
          firstName: '',
          lastName: '',
        },
      },
      location: {
        address1: '',
        address2: '',
        state: '',
        city: '',
        zipCode: '',
        region: '',
      },
      facilityStatus: '',
      division: '',
      bedSize: null,
      isTeaching: false,
      isMagnetStatus: false,
      isTraumaCenter: false,
      bookingLimit: 0,
      creditStatus: '',
      technologyVendor: '',
      numberOfReferenceRequired: 0,
      numberOfReferenceToRFO: 0,
      facilityWebsite: '',
    },
    openOrdersCount: 0,
  } as IFacilityDetails;
};

export const formatDate = (date: string | null | Date, format?: string) => {
  let defaultFormat = 'MM/DD/YYYY';
  if (format === 'timeStamp') defaultFormat = 'MM/DD/YYYY | h:mm:ss';
  if (format === 'lastVerifiedFormat') defaultFormat = 'MM/DD/YYYY h:mm:ss A';
  if (format === 'dateAnd12HourTime') defaultFormat = 'MM/DD/YYYY hh:mm A';
  const formattedDate = convertToFormat(date, defaultFormat);
  return formattedDate === null ? '' : formattedDate;
};

export const getName = (data: { firstName: string; lastName: string }) => {
  if (data.firstName) return data.firstName + ' ' + data.lastName;
  return '-';
};
export const getNameShot = (data: { first: string | null; last: string | null }) => {
  if (data.first) return data.first + ' ' + data.last;
  return '';
};

export const getDefaultValueIfNull = (data: number | string | null) => {
  return data || '-';
};

export const getReplacedIfNull = (data: number | string | null) => {
  return data || 'N/A';
};

export const getValueIfNull = data => {
  return data || '--';
};

export const getValue = (value: boolean | null) => {
  if (value) return 'Yes';
  if (value === false) return 'No';
  return '-';
};

export const formatSkillSet = (skillSets: SkillSet[]) => {
  let option = '';
  const data = skillSets.map(item => {
    if (item.subSpecialty) option = item.discipline + ', ' + item.specialty + ', ' + item.subSpecialty;
    else if (item.specialty) option = item.discipline + ', ' + item.specialty;
    else option = item.discipline;
    return option;
  });
  return data;
};

export const formatAddress = (location: Location) => {
  let data = '';
  data = `${location.address2 ? location.address2 + ',' : ''} ${location.city || ''}, ${location.state || ''}, ${
    location.zipCode || ''
  } ${location.country || ''}`;
  return {
    addressLine1: location.address1,
    addressLine2: data,
    phoneNumber: location.phoneNumber,
  };
};

export const getPlacementLength = (placementLength: number | null) => {
  return placementLength + ' Week(s)' || '-';
};

export const filterResponse = data => {
  const filteredData = data.filter(item => item[candidatePreferenceMatch.filterByField] !== null);
  const sortedData = orderBy(filteredData, candidatePreferenceMatch.filterByField, 'asc').slice(
    0,
    candidatePreferenceMatch.recordCount,
  );
  return sortedData;
};

export const getBestBetValue = (bestBetsId?: number) => {
  const filteredBestBet = bestBetsId ? bestBets.filter(item => item.ID === bestBetsId) : null;
  return filteredBestBet ? filteredBestBet[0].Description : '';
};

export const getOrderReasonValue = (reasonId: number | null) => {
  if (!reasonId) return '-';
  const filteredReason = OrderReason.filter(reason => reason.id === reasonId);
  return filteredReason[0].reason;
};

export const getDistinctCandidate = (response: candidatePreferenceMatchResponse[]) => {
  const distinct: candidatePreferenceMatchResponse[] = [];
  response.map(x => (distinct.some(a => a.candidateId === x.candidateId) ? null : distinct.push(x)));
  return distinct.length;
};

export const flattenPlacementSentData = (response: PlacementFileSent[]) =>
  response.map(item => {
    const checkData: PlacementFileSent = {
      placementId: item.placementId,
      candidateName: item.candidateName,
      sentDate: formatDate(item.sentDate),
    };
    return checkData;
  });

export const flattenPlacementWFCRFOData = response =>
  response.map(item => {
    const checkData: FilesAwaitingSubmission = {
      placementId: item.placementId,
      candidateName: item.candidateName,
      placementStatus: item.placementStatus,
      changedToStatus: formatDate(item.rfoRequestedDate),
      availabilityDate: formatDate(item.availabilityDate),
      predictiveInterviewSuccessScore: 'N/A',
      predictiveInterestScore: 'N/A',
    };
    return checkData;
  });

export const flattenCandidatePreferenceMatch = (response: candidatePreferenceMatchResponse[]) =>
  response.map(item => {
    const data: candidatePreferenceMatchData = {
      candidateId: item.candidateId,
      candidateName: item.candidateFirstName + ' ' + item.candidateLastName,
      brandId: item.companyId,
      brand: item.brand,
      candidateStatus: item.candidateStatus,
      placementId: getValueIfNull(item.placementId),
      placementIdValue: item.placementId,
      placementStatus: getValueIfNull(item.placementStatus),
      requestFileOutDate: getValueIfNull(formatDate(item.requestFileOutDate, 'timeStamp')),
      filesInProgress: getValueIfNull(item.filesInProgress),
      netWeeksBooked: getValueIfNull(item.netWeeksBooked),
      weeksBooked: item.netWeeksBooked ? parseInt(item.netWeeksBooked) : 0,
      recruiter: item.recruiterFirstName ? item.recruiterFirstName + ' ' + item.recruiterLastName : ' -- ',
      predictiveInterestScore: getValueIfNull(item.predictiveInterestScore),
      predictiveOfferScore: getValueIfNull(item.predictiveOfferScore),
      rank: item.rank,
      availabilityDate: getValueIfNull(formatDate(item.availabilityDate)),
      availability: item.availabilityDate ? new Date(item.availabilityDate) : null,
      yearsOfExperience: item.yearsOfExperience,
    };
    return data;
  });

export const getShiftID = (description: string) => {
  const shiftID = shiftIDList.filter(shift => {
    return shift && shift.Description === description;
  });
  return shiftID.map(shift => shift.Id)[0];
};

export const getOrderReasonId = (reason: string) => {
  const filteredOrderReason = OrderReason.filter(item => item.reason === reason);
  if (filteredOrderReason.length > 0) {
    return filteredOrderReason[0].id;
  }
  return null;
};

export const addWeek = (date: Date | null, weeks: number) => {
  if (date) {
    const calculatedDate = new Date(date);
    const weekSubtraction = calculatedDate.getDay() === 6 ? 0 : 1;
    calculatedDate.setDate(calculatedDate.getDate() + (weeks - weekSubtraction) * 7 + (6 - calculatedDate.getDay()));
    if (calculatedDate.getFullYear() <= 9999) {
      return calculatedDate;
    }
  }
  return null;
};

export const customAddWeek = (date: Date | null, weeks: number) => {
  if (date) {
    const calculatedDate = new Date(date);
    const day = calculatedDate.getDay();
    const weekSubtraction = day >= 5 ? 0 : 1;
    calculatedDate.setDate(calculatedDate.getDate() + (weeks - weekSubtraction) * 7 + (6 - day));
    if (calculatedDate.getFullYear() <= 9999) {
      return calculatedDate;
    }
  }
  return null;
};

export const calculatePlacementLength = (startDate: Date, endDate: Date) => {
  return (
    moment(new Date(endDate)?.setDate(endDate.getDate() + 6 - endDate.getDay()))?.diff(
      new Date(startDate)?.setDate(startDate.getDate() + 6 - startDate.getDay()),
      'week',
    ) + (startDate.getDay() === 6 ? 0 : 1)
  );
};

export function toFixed(num, fixed) {
  var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
  if (parseFloat(num) % 1 === 0) {
    return num.toString().match(re)[0];
  }
  return num.toString().concat('0').match(re)[0];
}

export const removeTimestamp = (date: Date | string | null) => {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  const formattedDate = moment(date).format('YYYY-MM-DD');
  if (formattedDate === 'Invalid date' || !formattedDate) return null;
  else return formattedDate.toString();
};

export const validateUrl = (value: string | undefined) => {
  const isValid =
    /^(?:(?:(?:http?|https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value,
    );
  return isValid;
};

export const validateHttpsOrWwwUrl = (value: string | undefined) => {
  const isValid = /^(?:(?:https|ftp):\/\/)?(?:www\.)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))?(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value,
  );
  return isValid;
};

export const editOrderDetails = (order: IOrderDetails, userId: number, isEditUnit: boolean, userState: IUser) => {
  const lastUpdatedBy = `${userState.userInfo.firstName} ${userState.userInfo.lastName}`;
  const updateMessage = UPDATE_MESSAGE_TEMPLATE.replace('[lastupdatedby]', lastUpdatedBy).replace(
    '[lastupdateddatetime]',
    formatDate(new Date()).toLocaleString(),
  );

  let internalNotes = isEditUnit ? order.unit.internalNotes : order.internalNotes;

  if (order.showInheritUnitButton) {
    if (!internalNotes.includes(STRING_VALIDATION_MESSAGE)) {
      internalNotes = `${internalNotes}\n${updateMessage}`;
    }
  }

  const edittedOrder: editOrder = {
    ...order,
    orderId: parseInt(order.orderId),
    orderTypeId: 0,
    // type: order.orderType,
    location: order.location,
    // region: '',
    regionId: 0,
    // division: order.division,
    orderPriorities: order.unit.orderPriorities?.map((item, index) => {
      return {
        orderPriorityTypeId: item.id,
        description: item.description,
      };
    }),
    hideFacilityDescription: order.hideFacilityDescription,
    suppressExternalPosting: order.suppressExternalPosting,
    // CF: order.clinicalFellowship,
    newGrad: order.newGrad ?? false,
    maxFilesSent: order.maxFilesSent,
    reasonForMaxFilesSent: order.reasonForMaxFilesSent,
    maxFilesSentDate: order.lastUpdatedMaxFilesSent,
    vmsOrderNumber: order.vmsOrderNumber,
    webLink: order.webLink,
    yearsOfExperience: order.yearsOfExperience,
    positionsAvailable: order.positionsAvailable,
    requiredQualifications: order.submissionRequirements,
    preferredQualifications: order.preferredQualifications,
    jobDescription: isEditUnit ? order.unit.externalJobDescription : order.jobDescription,
    internalNotes: internalNotes,
    shifts: order.shifts,
    placementLength: order.numOfWeeks,
    NumOfWeeks: order.numOfWeeks,
    startDate: removeTimestamp(order.startDate),
    endDate: removeTimestamp(order.endDate),
    ASAP: order.asap,
    flexibleDates: order.flexibleDates,
    expectedHours: order.expectedHours,
    guaranteedHours: order.guaranteedHours,
    orderReasonId: order.orderReasonId,
    // orderScore: order.orderScore,
    currentUserId: userId,
    vmsBillRate: order.vmsBillRate ? parseFloat(`${order.vmsBillRate}`) : order.vmsBillRate,
    orderBillRate: order.orderBillRate,
    standardizedRequirements: false,
    dateCreated: order.dateCreated,
    dateVerified: order.dateVerified,
    ClinicalFellowship: order.clinicalFellowship,
    VerifiedByUserId: null,
    AutoDecrementDate: null,
    AllowScreenId: null,
    ShiftNotNegotiable: false,
    LastUpdatedMaxFilesSent: order.lastUpdatedMaxFilesSent,
    Conditional: false,
    LastUpdatedByUserIdMaxFilesSent: null,
    contacts: order.contacts,
    skillsets: order.unit.experience?.skillSets,
    facility: order.facility,
    unit: order.unit,
    orderDecrementReasonID: order.orderDecrementReasonID,
    declineFiles: order.declineFiles,
    amieOrdersTimestamp: order.amieOrdersTimestamp,
    dates: [{ startDate: removeTimestamp(order.startDate), endDate: removeTimestamp(order.endDate) }] as any[],
  };
  return edittedOrder;
};

export const sampleOrderPayload = {
  id: '76420850-aa2d-46c6-b11f-41dad2c46f68',
  orderId: '907592',
  facility: {
    facilityId: 291,
    name: 'Sentara Independence',
    facilityBestBetsId: 0,
    amieFacilityTimeStamp: null,
    creditStatusId: 0,
  },
  unit: {
    unitId: 429573,
    facilityId: 291,
    name: 'unit for order',
    costCenter: 'cost center',
    orderPriorities: [''],
    facilityState: '',

    floor: '2',
    experience: {
      isNewGrad: true,
      skillSets: [
        {
          disciplineId: 1,
          discipline: 'Registered Nurse',
          disciplineAbbr: null,
          specialtyId: 1,
          specialty: 'ICU',
          specialtyAbbr: null,
          subSpecialtyId: 4,
          subSpecialty: 'Burn ICU',
          yearsOfExperience: 3,
          isRequired: true,
          isPrimary: true,
        },
      ],
    },
    reference: {
      isRequired: true,
      numberOfReferenceRequired: 2,
      numberOfReferenceToRFO: 1,
      withinMonths: false,
      referenceFromSupervisor: false,
    },
    nurseRatio: '5:100',
    fyreIntegration: true,
    status: 'Active',
    bestBets: 'MSP',
    bestBetsId: 13,
    unitSize: 60,
    externalJobDescription: 'External\nJob\nDescription',
    internalNotes: 'Internal\nNotes',
    travelExperience: {
      isRequired: true,
      numberOfAssignments: 3,
      yearsOfExperience: 0,
      general: true,
      inSpecialty: true,
    },
    qualification: {
      activeLicense: false,
      compactLicense: false,
      certifications: [
        {
          requirementTypeId: 47,
          yearsOfExperience: 0,
          abbreviation: 'BLS',
          documentAttachmentRequired: null,
          isRequired: true,
        },
        {
          requirementTypeId: 51,
          yearsOfExperience: 0,
          abbreviation: 'PALS',
          documentAttachmentRequired: true,
          isRequired: false,
        },
      ],
      skillChecklists: [
        {
          questionId: 16292,
          text: 'Start IVs',
          isRequired: true,
          yearsOfExperience: 3,
        },
      ],
      emr: {
        isRequired: true,
        values: ['EPIC', 'Eclipsys'],
      },
    },
    virtualRating: ['B', 'Voice Advantage (Interview)', 'C'],
    float: {},
    requiredQualifications: null,
    preferredQualifications: null,
    flexibleSchedule: {
      isRequired: true,
      workDuringWeekend: 10,
      workDuringHoliday: 8,
    },
    nonStandardRequirement: [
      {
        isRequired: true,
        category: 'References',
        value: 'non standard reference',
        yearsOfExperience: null,
        documentAttachmentRequired: null,
      },
    ],
    createdByVersion: null,
    currentUserId: 0,
    standardizedRequirements: true,
    id: null,
    partitionKey: null,
    timeStamp: null,
  },
  location: {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    country: null,
    phoneNumber: null,
    regionId: 16,
    region: 'New England',
  },
  disciplines: [],
  specialties: [],
  skillsets: [
    {
      disciplineId: 1,
      discipline: 'Registered Nurse',
      disciplineAbbr: null,
      specialtyId: 1,
      specialty: 'ICU',
      specialtyAbbr: null,
      subSpecialtyId: 4,
      subSpecialty: 'Burn ICU',
      yearsOfExperience: 3,
      isRequired: true,
      isPrimary: true,
    },
  ],
  numOfJobMatches: 0,
  positionsAvailable: 2,
  numOfFilesWfcRfo: 0,
  numOfFilesSent: 0,
  weeklyGrossPay: null,
  rate: null,
  startDate: null,
  endDate: null,
  amieOrdersTimestamp: null,
  numOfWeeks: 13,
  dateCreated: null,
  maxFilesSent: true,
  division: AMNDivision.Travel,
  orderType: 'Travel',
  status: null,
  orderTypeId: 0,
  technologyVendor: null,
  staffingVendor: null,
  activeBonus: false,
  payCycle: null,
  fees: 0,
  radiusRules: null,
  gww: false,
  contacts: {
    accountManager: {
      userId: 9981,
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: null,
    },
    regionalDirector: {
      userId: 7635,
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: null,
    },
    credentialingAnalyst: null,
    clinicalManager: null,
    verifiedBy: null,
  },
  dateVerified: null,
  jobDescription: 'External\r\nJob\r\nDescription',
  yearsOfExperience: 3,
  shifts: [
    {
      id: 0,
      description: '12 D',
    },
    {
      id: 0,
      description: '12 N',
    },
    {
      id: 0,
      description: '12 D/N',
    },
  ],
  submissionRequirements: null,
  preferredQualifications: 'Certifications: PALS, Doc Req.\r\n',
  internalNotes:
    'Float: Yes,Float to Unit:Float unit ex, Float to float location ex, Float: 9 Miles\r\nFlexible Schedule: Work During Weekend:10; Work During Holiday:8; \r\nInternal\nNotes',
  hideFacilityDescription: false,
  suppressExternalPosting: false,
  clinicalFellowship: false,
  newGrad: false,
  vmsOrderNumber: '123',
  webLink: 'www.loremipsum.com',
  verifiedByUserId: null,
  autoDecrementDate: null,
  allowScreenId: null,
  shiftNotNegotiable: null,
  lastUpdatedMaxFilesSent: null,
  conditional: false,
  lastUpdatedByUserIdMaxFilesSent: null,
  expectedHours: 20,
  guaranteedHours: 12,
  orderBillRate: 12,
  vmsBillRate: 2,
  orderReasonId: 2,
  region: null,
  regionId: 0,
  orderPriorities: [
    {
      orderPriorityTypeId: 0,
      description: 'B',
    },
    {
      orderPriorityTypeId: 0,
      description: 'Voice Advantage (Interview)',
    },
    {
      orderPriorityTypeId: 0,
      description: 'C',
    },
  ],
  brandinfo: null,
  onCallRate: null,
  callBackMinimum: null,
  completionBonus: null,
  reasonForMaxFilesSent: 'Maxed by VMS',
  requiredQualifications: null,
  dates: [],
  asap: true,
  flexibleDates: true,
  createdByVersion: null,
  currentUserId: 0,
  standardizedRequirements: true,
};

export const setEndDate = (placementLength: number, startDate: Date | null) => {
  if (startDate) return addWeek(startDate, placementLength);
  return '';
};

export const getPlacementLengthValue = (startDateValue: Date | string | null, endDateValue: Date | string | null) => {
  if (isValid(new Date(endDateValue)) && isValid(new Date(startDateValue))) {
    const startDate = new Date(startDateValue);
    const endDate = new Date(endDateValue);

    const timeDifference = endDate.getTime() - startDate.getTime();

    // Calculate the number of weeks (1 week = 7 days)
    const weeks = Math.floor(timeDifference / (7 * 24 * 60 * 60 * 1000));

    return weeks?.toString();
  } else {
    return '';
  }
};

export const getAge = date => {
  const startDate = convertTZ(new Date(), 'America/Los_Angeles');
  const endDate = new Date(typeof date === 'string' ? date.replace('Z', '') : date);
  let seconds = (startDate.getTime() - endDate.getTime()) / 1000;
  if (seconds <= 0) {
    seconds = seconds * -1;
  }

  let secondsx = seconds;
  const days = Math.floor(parseInt((secondsx / 86400).toFixed(2)));
  secondsx -= days * 3600 * 24;
  const hrs = Math.floor(parseInt((secondsx / 3600).toFixed(2)));
  secondsx -= hrs * 3600;
  const mnts = Math.floor(parseInt((secondsx / 60).toFixed(2)));
  secondsx -= mnts * 60;

  if (days >= 999) {
    //999 Days or Greater
    return '999 D';
  } else if (days >= 3 && days < 999) {
    //3 Days - 998 Days
    return days + ' D';
  } else if (days >= 1 && days < 3) {
    //24 hours - 2 Days 23 hours 59 min
    if (days == 1 && hrs <= 0 && mnts <= 0) {
      return '1 D';
    } else {
      //return days + ' D ' + hrs + ' Hr ' + mnts + ' Min';
      return days + ' D ' + hrs + ' Hr ';
    }
  } else if (hrs >= 1 && hrs < 24) {
    //1 hour - 23 hours 59 minutes
    if (hrs == 1 && mnts <= 0) {
      return '1 Hr';
    } else {
      return hrs + ' Hr ' + mnts + ' Min';
    }
  } else {
    if (Math.floor(seconds) <= 30) {
      return '0 Min';
    } else {
      return mnts + ' Min';
    }
  }
};

export const toolTipCheck = (str: string, limit: number) => {
  return (str.trim().length || 0) > limit ? `${str.trim().slice(0, limit)}...` : `${str.trim() || ''}`;
};
