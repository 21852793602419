import moment from 'moment';
import { ISelection } from 'app/ComponentLibrary/Filter/TypeAheadDropdown/CustomTypeAhead';
import { IEmrList, IPhoneNumber, VerifiedSkill } from 'app/models/Candidate/CandidateProfile';
import { ICredentials } from 'app/models/Credentialing/Credentials';
import { CovidVaccinationStatus } from './SellingNotesEnum';
import { orderBy, cloneDeep, isEqual, uniqWith } from 'lodash';
import states from 'app/assets/jsons/State.json';
import { missingField } from 'app/constants';

/**
 * Method to transform certifications to ISelection dropdown list
 * @param certifications
 */
export const transformCertifications = (certifications: ICredentials[]): ISelection[] => {
  return certifications
    .filter(certificationItem => certificationItem.expirationDate === null || findNonExpiredData(certificationItem))
    .map((certificationItem, index) => ({
      id: index,
      name: `${certificationItem.requirementType}, ${
        certificationItem.expirationDate ? moment(certificationItem.expirationDate).format('MM/DD/YYYY') : missingField
      }`,
      value: `${certificationItem.requirementType},  ${
        certificationItem.expirationDate ? moment(certificationItem.expirationDate).format('MM/DD/YYYY') : missingField
      }`,
    }));
};

/**
 * Method to find non expired dates from the list
 * @param listItem
 */
export const findNonExpiredData = (listItem: ICredentials) => {
  const date = moment(listItem.expirationDate).format('MM/DD/YYYY');
  const nonExpiredDate = moment(date, 'MM/DD/YYYY').isAfter(moment());
  return nonExpiredDate;
};

/**
 * Method to transform licenses to ISelection dropdown list
 * @param licenses
 */
export const transformLicensure = (licenses: ICredentials[]): ISelection[] => {
  const result = licenses
    .filter(licenseItem => licenseItem.expirationDate === null || findNonExpiredData(licenseItem))
    .map((licenseItem, index) => ({
      id: index,
      name: `${licenseItem?.credentialName ?? ''}${
        getStateNameById(licenseItem.stateID) ? `, ${getStateNameById(licenseItem.stateID)}` : ''
      }${licenseItem.licenseNumber ? `, ${licenseItem.licenseNumber}` : ''}${
        licenseItem.expirationDate ? `, ${moment(licenseItem.expirationDate).format('MM/DD/YYYY')}` : ''
      }`,
      value: `${licenseItem?.credentialName ?? ','} ${getStateNameById(licenseItem.stateID) ?? ','} ${
        licenseItem.licenseNumber ?? ''
      },${licenseItem.expirationDate ? moment(licenseItem.expirationDate).format('MM/DD/YYYY') : ''}`,
    }));
  return result;
};

/**
 * Method to get transform skillSet experience
 * @param skillSetExperience
 */
export const transformSkillSetExperience = (skillSetExperience: VerifiedSkill[]): ISelection[] => {
  const sortedSkillSet = orderBy(skillSetExperience, ['yearsOfExperience', 'monthsOfExperience'], ['desc', 'desc']);
  const transformSkillSet = sortedSkillSet
    .filter(skillSetItem => skillSetItem.disciplineAbbr && skillSetItem.specialtyAbbr)
    .map((skillSetItem, index) => ({
      id: index,
      name: `${skillSetItem.disciplineAbbr}, ${skillSetItem.specialtyAbbr} - ${skillSetItem.yearsOfExperience} Y  ${skillSetItem.monthsOfExperience} M`,
      value: `${skillSetItem.disciplineAbbr}, ${skillSetItem.specialtyAbbr} - ${skillSetItem.yearsOfExperience} Y  ${skillSetItem.monthsOfExperience} M`,
    }));
  const unique: string[] = [];
  const uniqueElements = transformSkillSet.filter(element => {
    const isDuplicate = unique.includes(element.value);
    if (!isDuplicate) {
      unique.push(element.value);
      return true;
    }
    return false;
  });

  return uniqueElements;
};

/**
 * Method to transform strong areas to ISelection dropdown list
 * @param strongAreas
 */
export const transformStrongAreas = (strongAreas: IEmrList[]): ISelection[] => {
  return strongAreas.map(item => ({
    id: item.id,
    name: item.name,
    value: item.name,
  }));
};

/**
 * Method to find covid vaccination status
 * @param candidateVaccinationStatus
 */
export const validateCovidVaccinationStatus = (candidateVaccinationStatus: string) =>
  Object.values(CovidVaccinationStatus)
    .filter(vaccinationItem => vaccinationItem === candidateVaccinationStatus)
    .map(item => `COVID ${item}`);

/**
 * Method to find best contact number
 */
export const transformBestContactNumber = (bestContactNumbers: IPhoneNumber[]) => {
  return bestContactNumbers.map(contactItem => ({
    ID: contactItem.phoneTypeId,
    Description: `+${contactItem.countryDialingCode} ${contactItem.phoneNumber} - ${contactItem.phoneTypeDescription}`,
  }));
};

/**
 * Method to transform list to preferred time to reach
 * @param preferredTimeToReachList
 */
export const transformPreferredTimeToReach = preferredTimeToReachList => {
  return preferredTimeToReachList.map(item => ({
    ID: parseInt(item.value),
    Description: item.name,
  }));
};

/**
 * Method to transform references data based on rating 'A'/'B'
 * @param referenceData
 */
export const transformReferences = referenceData => {
  const isSuperiorRef = referenceData.find(dataItem => dataItem.averageRating === 'A');
  const isExceedsRef = referenceData.find(dataItem => dataItem.averageRating === 'B');
  if (isSuperiorRef) {
    return `Superior Ref`;
  } else if (isExceedsRef) {
    return `Exceeds Ref`;
  } else {
    return null;
  }
};

/**
 * Helper to pull latest credentials or licenses
 * @param data
 */
export const setLatestVersionedList = data => {
  const ids: { reqTypeId: number; stateId: number }[] = [];
  let distinctIds: { reqTypeId: number; stateId: number }[] = [];
  const distinctCredentials: any = [];
  const licenseData = cloneDeep(data);
  licenseData
    ?.sort((a, b) => new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime())
    .map(item => {
      const temp = { reqTypeId: item.requirementTypeID, stateId: item.stateID };
      ids.push(temp);
    });
  distinctIds = uniqWith(ids, isEqual);

  distinctIds?.map(item => {
    let latest;
    let position;
    licenseData.map((cred, index) => {
      if (item.reqTypeId == cred.requirementTypeID && item.stateId == cred.stateID) {
        var d1 = moment(new Date());
        var d2 = moment(cred.dateCompleted ? cred.dateCompleted : cred.lastUpdate);
        if (d1.diff(d2, 'days') < latest || latest == undefined) {
          latest = d1.diff(d2, 'days');
          position = index;
        }
      }
      if (index == licenseData.length - 1) {
        distinctCredentials.push(licenseData[position]);
      }
      return null;
    });
    return null;
  });
  return distinctCredentials;
};

/**
 * Method to get state by id
 * @param stateId
 */
export const getStateNameById = (stateId: number) => {
  const stateName = states.states.find(item => item.StateNumber === stateId)?.ID;
  return stateName;
};
