import styledComponent from 'styled-components';
import { Paper, Accordion, Divider, Grid, Button } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { theme } from 'styles/global-styles';

export const PaperCard = styledComponent(Paper)`
  width: 100%;
  border: 1px solid ${theme.palette.system.paleGray};
  border-radius: 4px;
  padding: 10px 25px 25px 22px;
`;

export const AccordionCard = styledComponent(Accordion)`
  border: 1px solid ${theme.palette.system.paleGray};
  border-radius: 4px;
`;

export const AccordContent = styledComponent.div`
  width: 100%;
`;

export const Row = styledComponent.tr`
  display: flex;
  justify-content: space-between;
`;

export const RowHead = styledComponent.th`
  color:${theme.palette.framework.system.charcoal};
  font-size: 14px;
  text-align: left;
`;

export const RowHeadDisabled = styledComponent.th`
  color: ${theme.palette.system.fadedGray};
  font-size: 14px;
  text-align: left;
`;

export const RowContent = styledComponent.td`
  color: ${theme.palette.framework.system.midnightBlue};
  font-size: 14px;
  text-align: end;
  font-weight: 600;
`;

export const RowContentDisabled = styledComponent.td`
  color:${theme.palette.system.fadedGray};
  font-size: 14px;
  text-align: end;
  font-weight: 600;
`;

export const Title = styledComponent.div`
  color: #52429a;
  font-size: 20px;
  text-align: start;
`;

export const SubTitle = styledComponent.div`
  font-size: 14px;
  color: ${theme.palette.framework.system.charcoal};
  font-weight: 600;
  text-align: start;
`;

export const TextContent = styledComponent.div`
  font-size: 14px;
  color: ${theme.palette.framework.system.charcoal};
  text-align: start;
  white-space: pre-wrap;
`;

export const Count = styledComponent(Grid)`
  color:${theme.palette.framework.system.midnightBlue};
  font-size: 14px;
  font-weight: 600;
`;

export const HorizontalDivider = styledComponent(Divider)`
  margin-top: 18px !important;
  margin-bottom: 18px !important;
`;

export const ActionButton = styledComponent(Button)`
  width: 100%;
`;

export const PersonalInfoStyles = makeStyles()(theme => ({
  sellingNotesHeading: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconSize: {
    height: '22px',
    width: '22px',
  },
  editIcon: {
    padding: '4px',
    marginRight: '-14px',
  },
}));

export const useStyles: any = makeStyles()({
  datePickerInput: {
    height: '55px',
  },
  item: {
    paddingTop: '12px !important',
  },
});
