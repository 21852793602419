import { createAction } from '@reduxjs/toolkit';
import { IPostReferenceWorkExpModalPayload } from './types';
import { IReferenceRequest } from 'app/models/Candidate/Tags';

const onInitAction = createAction<{ candidateId: number; brandId: number }>('ON_INIT_WORK_EXPERIENCE_VIEW');

const deleteWorkExperienceBatchAction = createAction<{ workHistoryIds: number[]; workExperienceList: any[] }>(
  'DELETE_WORK_EXPERIENCE_BATCH',
);

const getReferenceByWorkExperienceAction = createAction<{ candidateId: number; workHistoryId: number }>(
  'GET_REFERENCE_BY_WORK_EXPERIENCE',
);

const postReferenceFromWorkExpModalAction = createAction<IPostReferenceWorkExpModalPayload>(
  'POST_REFERENCE_FROM_WORK_EXP_MODAL',
);

const postReferenceRequestFromWorkExpAction = createAction<IReferenceRequest>('POST_REFERENCE_REQUEST_FROM_WORK_EXP');

const fetchWorkExperienceData = createAction<{ candidateId: number; brandId: number }>('FETCH_WORK_EXPERIENCE_DATA');

export const businessActions = {
  onInitAction,
  deleteWorkExperienceBatchAction,
  getReferenceByWorkExperienceAction,
  postReferenceFromWorkExpModalAction,
  postReferenceRequestFromWorkExpAction,
  fetchWorkExperienceData,
};
