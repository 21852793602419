import React, { useEffect, useRef } from 'react';
import { ISelection } from 'app/ComponentLibrary/Filter/TypeAheadDropdown/CustomTypeAhead';
import {
  candidateDetailsSelection,
  getEMRList,
  getPreferredTimeToReach,
  savedEMRList,
  selectReferenceData,
} from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import SellingNotesControls from './SellingNotesControls';
import { ControlType, SellingNoteFields } from './helpers/SellingNotesEnum';
import {
  setLatestVersionedList,
  transformBestContactNumber,
  transformCertifications,
  transformLicensure,
  transformPreferredTimeToReach,
  transformReferences,
  transformSkillSetExperience,
  transformStrongAreas,
  validateCovidVaccinationStatus,
} from './helpers/SellingNotesHelper';
import { candidateDetailActions } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { ISellingNoteElements } from 'app/models/Candidate/SellingNotes';
import { useFormContext } from 'react-hook-form';
import { Box, Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';

const useStyles = makeStyles()((theme: Theme) => ({
  draggableContainer: {
    '& .MuiBox-root': {
      height: 0,
    },
  },
  popperStyle: {
    marginTop: 4,
    zIndex: 5000,
    width: '308px',
  },
}));

export const SellingNotesDragAndDrop = props => {
  const { classes } = useStyles();
  const { setValue, getValues } = useFormContext();
  const { setSellingNotesData, setIsChanged } = props;
  const dispatch = useDispatch();
  const [isCheckboxSelected, setIsCheckboxSelected] = useState<boolean>(true);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const getAllEmrList = useSelector(getEMRList);
  const savedEquipments = useSelector(savedEMRList);
  const defaultStrongAreas =
    savedEquipments || getAllEmrList?.filter(emrItem => emrItem.candidateId && emrItem.brandId);
  const strongAreas = getAllEmrList;

  const preferredTimeToReach = useSelector(getPreferredTimeToReach);
  const references = useSelector(selectReferenceData);
  const licenses = candidateDetails?.credentials?.licenses;
  const certifications = candidateDetails?.credentials?.certifications;
  const covidVaccinationStatus = candidateDetails.covidVaccinationstatus;
  const bestContactNumber = candidateDetails.phoneNumbers;
  const verifiedSkills = candidateDetails.verifiedSkills;
  const bestTimeToContactId = candidateDetails.bestTimeToContactId;

  const mappedPreferredTimeToReach = useRef<[{ ID: number; Description: string }]>();

  const latestVersionLicensure = setLatestVersionedList(licenses);
  const latestVersionCredentials = setLatestVersionedList(certifications);

  const mappedLicenses: ISelection[] = transformLicensure(latestVersionLicensure);
  const mappedCertifications: ISelection[] = transformCertifications(latestVersionCredentials);
  const mappedSkillSetExperience = transformSkillSetExperience(verifiedSkills as any);
  const mappedStrongAreas = transformStrongAreas(strongAreas?.length ? strongAreas : []);
  const mappedPhoneNumbers = transformBestContactNumber(bestContactNumber?.length ? bestContactNumber : []);
  const mappedReferences = transformReferences(references?.items?.length ? references?.items : []);

  const elements: ISellingNoteElements[] = [
    {
      id: '1',
      type: ControlType.Dropdown,
      fieldName: 'licensures',
      label: SellingNoteFields.LICENSURE,
      mappedResult: mappedLicenses,
    },
    {
      id: '2',
      type: ControlType.Dropdown,
      fieldName: 'certifications',
      label: SellingNoteFields.CERTIFICATIONS,
      mappedResult: mappedCertifications,
    },
    {
      id: '3',
      type: ControlType.Dropdown,
      fieldName: 'skillSetExperience',
      label: SellingNoteFields.SKILL_SET_EXPERIENCE,
      mappedResult: mappedSkillSetExperience,
    },
    {
      id: '4',
      type: ControlType.Dropdown,
      fieldName: 'strongAreas',
      label: SellingNoteFields.STRONG_AREAS,
      mappedResult: mappedStrongAreas,
      defaultValue: defaultStrongAreas,
    },
    { id: '5', type: ControlType.Text, label: 'Time off Requests', fieldName: 'timeOffRequests', readOnly: false },
    {
      id: '6',
      type: ControlType.Text,
      label: SellingNoteFields.REFERENCES,
      fieldName: 'references',
      readOnly: true,
      defaultValue: mappedReferences,
    },
    {
      id: '7',
      type: ControlType.SingleSelectDropdown,
      label: SellingNoteFields.BEST_CONTACT_NUMBER,
      fieldName: 'bestContactNumber',
      readOnly: false,
      mappedResult: mappedPhoneNumbers,
    },
    {
      id: '8',
      type: ControlType.SingleSelectDropdown,
      label: SellingNoteFields.PREFERRED_TIME_TO_REACH,
      fieldName: 'preferedTimeToReach',
      readOnly: false,
      mappedResult: mappedPreferredTimeToReach.current || [],
      defaultValue: mappedPreferredTimeToReach.current?.[0].Description || '',
    },
    {
      id: '9',
      type: ControlType.Text,
      label: SellingNoteFields.TRAVEL_EXPERIENCE,
      fieldName: 'travelExperience',
      readOnly: false,
    },
    {
      id: '10',
      type: ControlType.Text,
      label: SellingNoteFields.COVID_VACCINATION_STATUS,
      fieldName: 'covidStatus',
      readOnly: true,
      defaultValue: covidVaccinationStatus ? validateCovidVaccinationStatus(covidVaccinationStatus) : '',
    },
    {
      id: '11',
      type: ControlType.Text,
      label: SellingNoteFields.ADDITIONAL_HIGHLIGHTS,
      fieldName: 'additionalHighlights',
      readOnly: false,
    },
  ];

  const [items, setItems] = useState(elements);

  /**
   * Method to set value for best contact number field on initial load
   */
  const patchBestContactNumberValue = () => {
    if (mappedPhoneNumbers.length) {
      setValue('bestContactNumber', mappedPhoneNumbers[0]?.Description);
    }
  };

  const setChanged = () => setIsChanged(true);

  /**
   *  useEffect to trigger on initial load
   */
  useEffect(() => {
    dispatch(candidateDetailActions.getPreferredTimeToReach());
    patchBestContactNumberValue();
    setSellingNotesData(items);
    props.setGenerateButtonState(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tempElements = _.cloneDeep(elements);
    if (preferredTimeToReach?.length) {
      mappedPreferredTimeToReach.current = transformPreferredTimeToReach(preferredTimeToReach);
      if (mappedPreferredTimeToReach?.current) {
        const preferredFieldIndex = elements.findIndex(
          item => item.label === SellingNoteFields.PREFERRED_TIME_TO_REACH,
        );
        tempElements[preferredFieldIndex].defaultValue = bestTimeToContactId
          ? mappedPreferredTimeToReach?.current.find(item => item.ID === bestTimeToContactId)?.Description
          : mappedPreferredTimeToReach?.current[0]?.Description;
        setValue('preferedTimeToReach', tempElements[preferredFieldIndex].defaultValue);
        tempElements[preferredFieldIndex].mappedResult = mappedPreferredTimeToReach.current;
        setItems(tempElements);
        setSellingNotesData(tempElements);
        props.setGenerateButtonState(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferredTimeToReach]);

  const onDragEnd = result => {
    const newItems = Array.from(items);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setItems(newItems);
    setSellingNotesData(newItems);
    setIsChanged(isCheckboxSelected);
  };

  const handleChecked = e => {
    let allSelected = false;
    items.map(item => {
      if (getValues(`${item.fieldName}Checkbox`)) {
        allSelected = true;
      }
    });
    setIsCheckboxSelected(allSelected);
    setIsChanged(allSelected);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <Box className={classes.draggableContainer}>
                    <SellingNotesControls
                      provided={provided}
                      snapshot={snapshot}
                      item={item}
                      customClass={classes.popperStyle}
                      setChanged={setChanged}
                      handleChecked={handleChecked}
                    />
                  </Box>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
