import React, { useEffect, useState } from 'react';
import { Accordion as MuiAccordion, Grid } from 'amn-ui-core';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  candidateDetailsSelection,
  supervisorDataSelector,
} from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import moment from 'moment';
import { isPositiveNumber } from 'app/helpers/componentHelpers';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { FormProvider, useForm } from 'react-hook-form';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import _ from 'lodash';
import { EditSupervisor } from './EditSupervisor';
import { lookupSelector } from 'store/redux-store/lookup/lookup.selectors';
import { selectUser } from 'oidc/user.selectors';
import { ff_candidate_container_ui_v2 } from 'app/constants/FeatureFlags/Candidate/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { useSuperVisorStyles } from '../CardDesigns/utils';
import SupervisorCardUIV2 from '../CardDesigns/SupervisorCardUIV2';
import SupervisorCardUI from '../CardDesigns/SupervisorCardUI';
import { skillsetStatusOptions } from './constants';
import { getGridRows } from './helpers/helper';

const SupervisorCard = () => {
  const supervisorData = useSelector(supervisorDataSelector);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { classes } = useSuperVisorStyles();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const [candidateContainerUIV2] = useDecision(ff_candidate_container_ui_v2);
  const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [supervisorSkillsets, setSupervisorSkillsets] = useState<any>(supervisorData.skillsets);
  const [skillsetRows, setSkillsetRows] = useState<any>([]);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const lookups = useSelector(lookupSelector);
  const formMethods = useForm({ mode: 'all', defaultValues: supervisorData });
  const {
    handleSubmit,
    setValue,
    reset,
    formState: { dirtyFields, errors, touched },
  } = formMethods;

  useEffect(() => {
    setSupervisorSkillsets(supervisorData.skillsets);
    getGridRows({ skillsets: supervisorData.skillsets, setSkillsetRows });
  }, [supervisorData.skillsets]);

  const handleEditIconClick = (event: any) => {
    setPopupOpen(true);
    event?.stopPropagation();
  };
  const handleSaveSupervisor = async data => {
    setIsLoading(true);
    const skillsets = supervisorSkillsets?.map(skillset => {
      const disciplineID = skillset.discipline
        ? lookups?.disciplines?.find(item => item.object.Abbreviation === skillset.discipline)?.object.ID
        : 0;
      const specialtyList: any[] = [];
      if (disciplineID) {
        const temp = lookups?.discSpecMapping?.filter(x => x.disciplineId === disciplineID);
        temp?.map(item => {
          specialtyList.push({ ID: item.specialtyId, Description: item.description });
        });
        return {
          travelerSupervisorSkillsetId: skillset?.travelerSupervisorSkillsetID
            ? skillset.travelerSupervisorSkillsetID
            : supervisorData.skillsets?.find(item => item.travelerSupervisorSkillsetID === skillset.rowID)
            ? skillset.rowID
            : null,
          disciplineId: disciplineID,
          discipline: skillset.discipline,
          specialtyId: skillset.specialty ? specialtyList.find(item => item.Description === skillset.specialty)?.ID : 0,
          specialty: skillset.specialty,
          statusId: skillset.status
            ? skillsetStatusOptions.find(item => item.Description === skillset.status)?.ID
            : null,
          status: skillset.status,
          reasonId: skillset.rejectionReason
            ? parseInt(lookups?.skillsetRejectionReason?.find(item => item.name === skillset.rejectionReason)?.value)
            : null,
          rejectionReason: skillset.rejectionReason,
        };
      }
    });
    const payload = {
      travelerId: candidateDetails.travelerId,
      interested: data.interested,
      availabilityDate: data.availabilityDate ? moment(data.availabilityDate).format('yyyy-MM-DD') : null,
      maxNumber: data.maxNumber || null,
      currentEmployeeId: user.userInfo.employeeId,
      currentEmployeeName: `${user.userInfo.firstName} ${user.userInfo.lastName}`,
      skillsets: skillsets?.map(item => {
        return {
          travelerSupervisorSkillsetID: item.travelerSupervisorSkillsetId,
          disciplineID: item.disciplineId,
          discipline: item.discipline,
          specialtyID: item.specialtyId,
          specialty: item.specialty,
          statusID: item.statusId,
          status: item.status,
          reasonID: item.reasonId,
          rejectionReason: item.rejectionReason,
        };
      }),
    };
    dispatch(candidateDetailActions.postSupervisorData({ reqBody: payload, skillsets }));
    setPopupOpen(false);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isPositiveNumber(candidateDetails.travelerId) && isPositiveNumber(candidateDetails.brandId))
      dispatch(candidateDetailActions.getSupervisorData());
  }, [candidateDetails?.travelerId, candidateDetails.brandId]);

  const handleEditPopupClose = () => {
    setSaveDisabled(true);
    setPopupOpen(false);
  };

  const handleClose = () => {
    if (!_.isEmpty(errors) || !_.isEmpty(dirtyFields) || !_.isEmpty(touched)) {
      dispatch(
        globalActions.setSnackBar({
          message: t('candidate.virtualInterviewTabLabels.cancelMessage'),
          severity: 'success',
          actions: [
            {
              action: () => handleEditPopupClose(),
              closeSnackbar: true,
              text: t('Yes'),
            },
            {
              closeSnackbar: true,
              text: t('No'),
            },
          ],
        }),
      );
    } else {
      handleEditPopupClose();
    }
  };
  const undoHandle = () => {
    setValue('maxNumber', supervisorData.maxNumber, { shouldDirty: true });
    setValue('availabilityDate', supervisorData.availabilityDate, { shouldDirty: true });
    setValue('interested', supervisorData.interested, { shouldDirty: true });
    getGridRows({ skillsets: supervisorData.skillsets, setSkillsetRows });
  };

  useEffect(() => {
    if (supervisorData) {
      reset(supervisorData);
    }
  }, [supervisorData, reset]);

  const handleSkillsetChange = (data: any) => {
    const skillsets = [];
    data?.forEach((value, key) => {
      skillsets.push(value);
    });
    setSupervisorSkillsets(skillsets);
    setSkillsetRows(skillsets);
    setSaveDisabled(false);
  };

  return (
    <>
      {candidateContainerUIV2?.enabled ? (
        <SupervisorCardUIV2 handleEditIconClick={handleEditIconClick} isLoading={isLoading} />
      ) : (
        <SupervisorCardUI handleEditIconClick={handleEditIconClick} isLoading={isLoading} />
      )}
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleSaveSupervisor)}>
          <GenericDialog
            fullWidth
            variant={'blue'}
            classes={{
              paper: classes.dialogContainerSupervisor,
            }}
            open={isPopupOpen}
            disablePortal
            disableEscapeKeyDown
            dialogTitleProps={{
              text: t('candidate.supervisorCard.editSupervisor'),
              closeButton: true,
              expandable: true,
              undoButton: true,
              onUndoClick: () => undoHandle(),
            }}
            dialogActions={[
              {
                text: 'Cancel',
                variant: 'contained',
                color: 'tertiary',
                onClick: () => handleClose(),
              },
              {
                text: 'Save',
                variant: 'contained',
                color: 'primary',
                type: 'submit',
                disabled: saveDisabled,
              },
            ]}
            onClose={() => handleClose()}
          >
            <Grid container spacing={3} style={{ margin: 0, width: '100%' }}>
              <Grid item xs={12} className={classes.dialogContainerGrid}>
                <EditSupervisor
                  data={supervisorData}
                  handleSkillsetChange={handleSkillsetChange}
                  skillsetRows={skillsetRows}
                  setSaveDisabled={setSaveDisabled}
                />
              </Grid>
            </Grid>
          </GenericDialog>
        </form>
      </FormProvider>
    </>
  );
};
export default SupervisorCard;
