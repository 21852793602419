import { InputAdornment, TextField } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';

interface Props {
  inputName: string;
  inputDefaultValue: string | number | null;
  inputLabel: string;
  inputValue: string | number | null;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleWarning?: () => void;
  handleBlur?: (event?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  id?: string;
  inputError?: boolean;
  InputProps?: {
    classes?: any;
    endAdornment?: JSX.Element;
    startAdornment?: JSX.Element;
  };
  inputType?: string;
  tabIndex?: number;
  inputPlaceholder?: string;
  labelStyle?: string;
  fieldStyle?: string;
  minInputValue?: number;
  maxLength?: number;
  isdisabled?: boolean;
  req?: boolean;
  showEndAdornment?: boolean;
  endAdornmentValue?: string;
  showStartAdornment?: boolean;
  startAdornmentValue?: string;
  shrinkLabel?: boolean | undefined;
  helperText?: string | undefined | JSX.Element;
  min?: number;
  max?: number;
  onKeyPress?: (e: React.KeyboardEvent) => void;
}

const useStyles = makeStyles<{ inputError: boolean }>()((theme, props) => ({
  root: {
    '& .MuiFilledInput-root': {
      background: '#FFF',
    },
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
    },
    '& label + div': {
      backgroundColor: '#fff !important',
    },
  },
  disabled: {
    cursor: 'not-allowed',
  },
  textareastyle: {
    backgroundColor: '#fff',
    border: 'thin #e7e2e2 solid',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
    },
  },
  reqTextareastyle: {
    backgroundColor: '#fff',
    border: props.inputError ? 'thin #D90000 solid' : 'thin #02b9ff solid',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
    },
  },
  endAdornment: {
    alignItems: 'baseline',
    p: {
      fontSize: '12px',
    },
  },
  startAdornment: {
    p: {
      lineHeight: '20px',
    },
  },
}));

export const SingleLineInput = (props: Props) => {
  const {
    inputValue,
    inputName,
    inputDefaultValue,
    tabIndex,
    inputLabel,
    handleChange,
    handleWarning,
    handleBlur,
    id,
    inputError,
    inputType,
    labelStyle,
    fieldStyle,
    inputPlaceholder,
    minInputValue = 0,
    maxLength,
    isdisabled,
    req,
    showEndAdornment = false,
    showStartAdornment = false,
    endAdornmentValue = '',
    startAdornmentValue = '',
    shrinkLabel = undefined,
    helperText = undefined,
  } = props;
  const { classes } = useStyles({ inputError: inputError });

  return (
    <>
      <TextField
        id={id}
        fullWidth
        className={isdisabled ? `${classes.disabled} ${fieldStyle}` : `${fieldStyle} ${classes.root}`}
        variant="filled"
        InputProps={{
          className: isdisabled ? '' : `${req ? classes.reqTextareastyle : classes.textareastyle}`,
          inputProps: {
            min: inputType === 'number' && minInputValue,
            maxLength: maxLength && maxLength,
            tabIndex: tabIndex,
          },
          endAdornment: showEndAdornment && (
            <InputAdornment className={classes.endAdornment} position="end">
              {endAdornmentValue}
            </InputAdornment>
          ),
          startAdornment: showStartAdornment && (
            <InputAdornment className={classes.startAdornment} position="start">
              {startAdornmentValue}
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          className: `${labelStyle && labelStyle}`,
          id,
          shrink: shrinkLabel ?? undefined,
        }}
        autoComplete="off"
        placeholder={inputPlaceholder && inputPlaceholder}
        name={inputName}
        value={inputValue}
        defaultValue={inputDefaultValue}
        disabled={isdisabled}
        label={inputLabel}
        onChange={handleChange}
        onFocus={handleWarning}
        onBlur={handleBlur}
        error={inputError}
        helperText={inputError ? helperText : undefined}
        type={inputType ? inputType : 'string'}
        {...props}
        color="primary"
      />
    </>
  );
};
