import { IAdditionalContractDetails, IContractDetails } from '@AMIEWEB/Facility/FacilityStore/Facility.model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TruncatedText } from '../../../GeneralTab/Utils';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import { missingField } from 'app/constants';
import { Typography } from 'amn-ui-core';

const LocalRadiusRule = ({ contractDetails }: { contractDetails: IAdditionalContractDetails }) => {
  const { t } = useTranslation();

  return (
    <LayoutGridItem container>
      <RowItem
        fieldName={t('order.facility.rateCard.contractDetails.localRadiusRule.radiusRule')}
        data={contractDetails.radiusRule}
      />

      <RowItem
        fieldName={t('order.facility.rateCard.contractDetails.localRadiusRule.permAddressWithin')}
        data={contractDetails.permAddressWithin}
      />

      <RowItem
        fieldName={t('order.facility.rateCard.contractDetails.localRadiusRule.billRateAgreement')}
        data={contractDetails.billRateAgreement}
      />

      <RowItem
        data={contractDetails.localRadiusRuleNotes}
        fieldName={t('order.facility.rateCard.contractDetails.localRadiusRule.notes')}
      />
    </LayoutGridItem>
  );
};

const DirectHire = ({ contractDetails }: { contractDetails: IAdditionalContractDetails }) => {
  const { t } = useTranslation();

  return (
    <LayoutGridItem container>
      <RowItem
        fieldName={`${t('order.facility.rateCard.contractDetails.directHire.title')}:`}
        data={contractDetails.isDirectHire}
      />

      <RowItem
        data={
          <TruncatedText
            text={contractDetails?.directHireDescription ? contractDetails?.directHireDescription : missingField}
            maxLength={contractDetails?.directHireDescription?.length}
          />
        }
        fieldName={t('order.facility.rateCard.contractDetails.directHire.notes')}
      />
    </LayoutGridItem>
  );
};

const FeeAmounts = ({ contractDetails }: { contractDetails: IContractDetails }) => {
  const { t } = useTranslation();
  return (
    <LayoutGridItem container sx={{ maxHeight: 48 }}>
      <RowItem
        fieldName={`${t('order.facility.rateCard.contractDetails.fees.vmFee')}:`}
        data={contractDetails?.vmFeeAmount ? contractDetails.vmFeeAmount + ' %' : missingField}
      />
      <RowItem
        fieldName= {`${t('order.facility.rateCard.contractDetails.fees.otherFees')}:`}
        data={contractDetails?.otherFeesAmount ? contractDetails.otherFeesAmount + ' %' : missingField}
      />
    </LayoutGridItem>
  );
};
const ContractNotes = ({ data }: { data: string }) => {
  return <TruncatedText text={data ? data : missingField} maxLength={data?.length} />;
};

const RowItem = ({ data, fieldName }: { data: string | number | boolean | React.ReactChild; fieldName: string }) => {
  return (
    <LayoutGridItem container item sx={{ display: 'flex', alignItems: 'baseline' }}>
      <LayoutGridItem xs={3}>
        <Typography variant="body2" component="span" sx={{ fontWeight: 'bold' }}>
          {fieldName}
        </Typography>
      </LayoutGridItem>
      <LayoutGridItem xs={9}>
        <Typography sx={{ fontSize: '14px !important' }}>{data}</Typography>
      </LayoutGridItem>
    </LayoutGridItem>
  );
};

export { LocalRadiusRule, DirectHire, FeeAmounts, ContractNotes };
