import React from 'react';
import { missingField } from 'app/constants';
import { getDateRangeLabel } from 'utils/dates/dateExtraction';
import styledComponent from 'styled-components';
import { useTranslation } from 'react-i18next';

export const OrderInfoDateItem = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <div id="placement-order-info-dates">
      <RowDataDiv data-testid="order-info-date-item">
        {getDateRangeLabel(
          props.orderInformation?.startDate as string,
          props.orderInformation?.endDate as string,
          ' ',
          ' ',
          missingField,
        )}
      </RowDataDiv>
      <RowDataDiv data-testid="order-info-number-ofweeks-item">
        {props.orderInformation?.numberOfWeeks
          ? `${props.orderInformation.numberOfWeeks} ${t(
              'placement.profile.orderInformation.orderInfoDateItem.numberOfWeeks',
            )}`
          : missingField}
      </RowDataDiv>
    </div>
  );
};

const RowDataDiv = styledComponent.div`
  font-size: 14px;
  white-space: pre-wrap;
`;
