import { Box, Avatar, Button, CircularProgress, Divider, Grid, IconButton, Tooltip, Link } from 'amn-ui-core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HospitalIcon from 'app/assets/images/hospital.svg';
import { getFacilityDetails } from 'app/services/FacilityServices/FacilityServices';
import { IFacilityDetails } from 'app/components/Facility/FacilityStore/Facility.redux';
import { getEmptyFacility } from '../../Order/OrderDetails/helper';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import { toolTipCheck } from 'app/components/Order/OrderDetails/helper';
import { useHistory } from 'react-router';
import { Concatenate } from 'utils/string/string';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useLocation } from 'react-router-dom';
import { gridStateActions } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import { globalSearchActions } from 'store/redux-store/global-search/slice';
import { selectSearchResults } from 'store/redux-store/global-search/selectors';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { navigationProfilesAction } from '../store/NavigationProfiles.redux';
import { selectFacilityDrawer } from 'app/ApplicationRoot/Global.selectors';
import { selectIsUnitFormDirty } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';
import { FacilityUnitActions } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.redux';
import { useFacilityDrawerStyles } from './Styles';

export const FacilityPreviewDrawerViewer = props => {
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const { drawerData, items } = useSelector(selectSearchResults);
  const history = useHistory();
  const { pathname } = useLocation();
  const [facilityDetails, setFacilityDetails] = useState<IFacilityDetails>(getEmptyFacility);
  const { classes } = useFacilityDrawerStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const isOrder = pathname.includes('order');
  const { facility } = useSelector(selectFacilityDrawer);
  const isDirty = useSelector(selectIsUnitFormDirty);
  const hideNavigation = facility?.hideNavigation;
  const highlightRow = (rowId: string) => {
    dispatch(gridStateActions.setHighlightedRow(rowId));
  };

  const showArrow = hideNavigation ? false : !isOrder;

  const fetchFacilityDeatails = (fid: number) => {
    getFacilityDetails(fid)
      .then(response => {
        const data = {
          ...facilityDetails,
          facilityResponse: {
            ...response,
            employeeContacts: {
              ...response.employeeContacts,
              hospitalAccountManager: {
                ...response.employeeContacts?.accountManager,
              },
            },
          },
        };
        setFacilityDetails(data);
        setIsLoad(false);
      })
      .catch(error => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (props?.facilityId) {
      setIsLoad(true);

      fetchFacilityDeatails(props?.facilityId);
    }
    if (drawerData?.data?.displayValue) {
      setIsLoad(true);

      if (drawerData?.data?.selectedorder?.row?.previewDrawerViewerId) {
        highlightRow(
          `${drawerData?.data?.selectedorder?.row?.previewDrawerViewerId}-${drawerData?.data?.selectedorder?.row?.indexCounter}`,
        );
        setSelectedIndex(drawerData?.data?.selectedorder?.row?.indexCounter);
      }

      fetchFacilityDeatails(drawerData?.data?.displayValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerData?.data?.displayValue, props?.facilityId]);

  const NavigateFacilityItem = async direction => {
    if (isLoad || props?.facilityId) {
      return;
    }

    let index = selectedIndex;
    if (direction) {
      if (index <= items.length - 2) {
        index = index + 1;
      } else {
        index = 0;
      }
    } else {
      if (index > 0) {
        index = index - 1;
      } else {
        index = items.length - 1;
      }
    }

    setSelectedIndex(index);
    const selIndex = index;

    const facilityId = props.type === 1 || props.type === 2 ? items[selIndex].facilityId : items[selIndex].facilityId;

    getFacilityDetails(facilityId)
      .then(response => {
        const data = {
          ...facilityDetails,
          facilityResponse: {
            ...response,
            employeeContacts: {
              ...response.employeeContacts,
              hospitalAccountManager: {
                ...response.employeeContacts?.accountManager,
              },
            },
          },
        };
        setFacilityDetails(data);
        setIsLoad(false);
      })
      .catch(error => {
        console.log(error);
      });

    if (drawerData?.data?.selectedorder?.row?.id) {
      highlightRow(`${facilityId}-${selIndex}`);
    }

    setIsLoad(true);
  };

  const handleClose = e => {
    dispatch(globalSearchActions.setDrawerData({ open: false, data: undefined }));
    if (props?.facilityId) {
      dispatch(globalActions.resetFacilityDrawerData());
    }
  };

  const navigate = () => {
    history.push({
      pathname: `/facility/${facilityDetails.facilityResponse?.facilityId}`,
      state: { navigateBack: true },
    });
    if (!props?.facilityId) {
      dispatch(navigationProfilesAction.setNavigationFlag({ navigationFromGrid: true }));
      dispatch(navigationProfilesAction.setCurrentRowId({ rowId: drawerData?.data?.selectedorder?.row?.id }));
    }
    if (props?.facilityId) {
      dispatch(globalActions.resetFacilityDrawerData());
    }
  };

  const isDirtyPopupCheck = () => {
    if (isDirty) {
      dispatch(FacilityUnitActions.setOpenCancelModel({ openCancelModel: true }));
    } else {
      navigate();
    }
  };

  return (
    <>
      {isLoad || ((!items || items.length <= 0) && !props?.facilityId) ? (
        <CircularProgress className={classes.MuiCircularProgressRoot} />
      ) : (
        <div className={classes.drawerRoot}>
          <LayoutGridItem container xs={12} lg={12} sm={12} md={12} direction="row" spacing={2}>
            <LayoutGridItem container xs={10} lg={10} sm={10} md={10}>
              <span className={`${classes.closeIcon} ${classes.headerLinkContent}`}>
                <span
                  className={classes.drawerOrderIdItem}
                >{`ID ${facilityDetails.facilityResponse?.facilityId}`}</span>
                {showArrow && (
                  <>
                    <span className={classes.drawerLeftArraow} aria-hidden="true">
                      <KeyboardArrowLeftIcon
                        onClick={() => {
                          NavigateFacilityItem(false);
                        }}
                        onKeyDown={() => {
                          NavigateFacilityItem(false);
                        }}
                        aria-hidden="true"
                      />
                    </span>
                    <span className={classes.drawerRightArraow} aria-hidden="true">
                      <KeyboardArrowRightIcon
                        onClick={() => {
                          NavigateFacilityItem(true);
                        }}
                        onKeyDown={() => {
                          NavigateFacilityItem(true);
                        }}
                        aria-hidden="true"
                      />
                    </span>
                  </>
                )}

                <Box
                  component="span"
                  className={`${classes.link} ${classes.viewOrderLink}`}
                  onClick={isDirtyPopupCheck}
                  sx={theme => ({ marginLeft: isOrder || hideNavigation ? theme.spacing(2) : theme.spacing(0) })}
                >
                  {`View Facility`}
                </Box>
              </span>
            </LayoutGridItem>
            <LayoutGridItem container xs={2} lg={2} sm={2} md={2}>
              <IconButton
                color="primary"
                onClick={handleClose}
                className={classes.closeIconPad}
                data-testid="dt-id-close-drawer"
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </LayoutGridItem>
          </LayoutGridItem>

          <Divider orientation="horizontal" classes={{ root: classes.divider }} />

          <Grid item xs={12} className={classes.bodyCandidateProfileDrawer} id="body-contact">
            <Grid item xs={12} className={classes.profileSegment}>
              <Grid item xs={3}>
                <Avatar className={`${classes.avatarProfile} ${classes.avatarOrderProfile}`}>
                  <img src={HospitalIcon} className={classes.avatarOrderProfileIcon} />
                </Avatar>
              </Grid>
              <Grid item xs={9} className={classes.profileDisplay} justifyContent="space-between">
                <Grid item xs={12}>
                  <div className={classes.profileInfo}>
                    <Grid item className={classes.profileInfoName}>
                      <Link onClick={isDirtyPopupCheck}>{facilityDetails.facilityResponse?.facilityName}</Link>
                    </Grid>
                    <Grid item className={classes.details}>
                      <Grid item xs={6} className={classes.profileDisplay} justifyContent="space-between" container>
                        <Tooltip
                          title={
                            <span className={classes.tooltip}>
                              <span>{`${facilityDetails.facilityResponse?.division}`}</span>
                            </span>
                          }
                          disableHoverListener={`${facilityDetails.facilityResponse?.division}`.length <= 20}
                          arrow
                          placement="bottom"
                          classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
                        >
                          <span className={classes.divisionBusinessRelationshipLabel}>{`${toolTipCheck(
                            `${facilityDetails.facilityResponse?.division}` || '',
                            20,
                          )}`}</span>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={6} className={classes.profileDisplay} justifyContent="space-between">
                        <span className={classes.drawerFacilityContactNameSection}>
                          <PersonIcon className={classes.drawerFacilityContactNameSectionicon} />
                        </span>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={`${classes.details} ${classes.drawerFacilityAddressSectionContainer}`}
                    >
                      <span className={`${classes.drawerFacilityAddressSection}`}>
                        {' '}
                        {Concatenate(
                          [
                            facilityDetails.facilityResponse?.location.city || '',
                            facilityDetails.facilityResponse?.location.state || '',
                            facilityDetails.facilityResponse?.location.zipCode || '',
                          ],
                          ', ',
                        ) || 'N/A'}
                      </span>
                    </Grid>
                  </div>
                </Grid>
                <Grid item style={{ color: '#888888' }}>
                  <MoreVertIcon />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};
