import { makeStyles } from 'tss-react/mui';

export const useSkillsChecklistStyles = makeStyles()({
  grid: {
    fontSize: '14px',
    height: '500px',
  },
  expandedGrid: {
    width: '31rem',
    border: 'none',
    fontSize: '14px',
    '& .MuiDataGrid-row': {
      borderBottom: '1px solid #D5D5D5',
    },
  },
});

export const useClinicalQualificationsListStyles = makeStyles()({
  grid: {
    fontSize: '14px',
    height: '500px',
  },
  expandedGrid: {
    width: '31rem',
    border: 'none',
    fontSize: '14px',
    '& .MuiDataGrid-row': {
      borderBottom: '1px solid #D5D5D5',
    },
  },
});
