/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import TopLabelStepper from 'app/components/Common/TopLabelStepper/TopLabelStepper';
import { useTranslation } from 'react-i18next';
import { useDecision } from '@optimizely/react-sdk';
import { ButtonSection, ButtonSpace, MessageHeader } from '../OrderDesign';
import { getUnitsForFacility } from 'app/services/FacilityServices/FacilityServices';
import { DefineUnit } from './DefineUnit/DefineUnit';
import shiftData from 'app/assets/jsons/OrderCreation/OrderShifts.json';
import prioritiesData from 'app/assets/jsons/OrderCreation/OrderPriorities.json';
import { Button, Dialog, DialogActions, DialogTitle, Grid, CircularProgress, Divider, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useHistory } from 'react-router-dom';
import { NewOrderDetails } from './OrderDetails/NewOrderDetails';
import { IOrderDetails, NewOrder, ShiftData, ShiftResponse, SkillSet } from 'app/models/Orders/OrderDetails';
import { OrderConstants } from 'app/constants/OrderCreationForm';
import { createOrder } from 'app/services/OrderServices/OrderServices';
import { useDispatch, useSelector } from 'react-redux';
import { trackEvent, trackException } from 'app-insights/appInsightsTracking';
import {
  orderDataActions,
  IOrderCreationProcessStep,
  orderDataSliceKey,
  orderDataReducer,
} from 'app/components/Order/Store/Order.redux';
import { getEmptyOrder, getInternalNotes, toHeaderDetails, toQuickGlance } from './helper';
import { NewUnit } from 'app/models/Unit/CreateUnit';
import {
  selectNewUnit,
  selectOrderCreationProcessStep,
  selectOrderDetailsOrderType,
  selectOrderRegions,
} from 'app/components/Order/Store/Order.selectors';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { OrderFacilityDetails } from 'app/models/Facility/FacilityDetails';
import { OrderDetailsDataWrapper } from '../OrderDetails/OrderDetailsContent/OrderDetailsDataWrapper';
import { DetailsPage } from 'app/layout/pages/DetailsPage';
import { OrderHeader } from '../OrderDetails/OrderHeader/OrderHeader';
import { OrderQuickGlance } from '../OrderDetails/OrderQuickGlance/OrderQuickGlance';
import { getEmptyOrderDetails, getOrderReasonId } from '../OrderDetails/helper';
import info from 'app/assets/images/info-24px.svg';
import _ from 'lodash';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { ExceptionType } from 'app/enums/Common';
import { withReadOnly } from 'oidc/withReadOnly';
import { RateCard } from './RateCard/RateCard';
import { ff_order_ui_rate_card } from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';
import moment from 'moment';
import { selectUser } from 'oidc/user.selectors';
import { RateElemenetSource, RateElements, getDiciplineName } from './RateCard/helper';
import uuid from 'react-uuid';
import { Status } from 'app/enums/Facility';
import { Concatenate } from 'utils/string/string';
import {
  createOrderOcbrActions,
  createOrderOcbrReducer,
  createOrderOcbrSliceKey,
} from 'store/redux-store/create-order-ocbr/slice';
import { httpSuccess } from 'app/services/serviceHelpers';
import {
  selectCreateOrderMiscellaneousData,
  selectCreateOrderOcbr,
  selectOcbrDetails,
  selectRateCardValidationError,
} from 'store/redux-store/create-order-ocbr/selector';
import {
  IFacilityOffContractRateElements,
  IFacilityRateElementDetailsCommand,
  IOffContractRateElements,
} from 'store/redux-store/create-order-ocbr/types';
import { orderDataSaga } from '../Store/Order.saga';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { createOrderOcbrSaga } from 'store/redux-store/create-order-ocbr/saga';
import {
  getAllRateElements,
  getFacilityRateElementDocumentsDataToSave,
  getOffContractDataToSave,
} from './RateCard/saveHelper';
import { facilitySliceKey } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { AMNDivisionType } from 'app/models/enums/AMNDivisionType';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';

interface Props {
  onTypeChange(value: unknown): void;
  onNext(value: unknown): void;
  facilityData: OrderFacilityDetails;
  onOrderCreationNav(value: boolean): void;
  unitNavigationTarget: string;
  setUnitNavigationTarget(value: String): void;
  createdNewUnitId: number;
  onShowmsg(value: boolean): void;
  showCancelConfirmation: boolean;
  updateShowCancelConfirmation(value: boolean): void;
  setCreatedNewUnitId(value: number): void;
  clickOnBackButton: boolean;
  setClickOnBackButton(value: boolean): void;
}

const useStyles = makeStyles()(theme => ({
  button: {
    width: '100px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#006FB9',
      color: '#ffffff',
    },
    '&[data-type="large"]': {
      width: '250px',
    },
  },
  buttonWidth: {
    marginRight: theme.spacing(1),
  },
  addJustButton: {
    marginBottom: '5px',
  },
  buttonHeight: {
    lineHeight: 2,
  },
  cancelButton: {
    backgroundColor: '#8E9195',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#8E9195',
    },
  },
  tooltipPlacementBottom: {
    margin: 0,
    marginBottom: 22,
  },
  modalButton: {
    width: 100,
  },
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50vh',
  },
  statusButton: {
    fontSize: 12,
  },
  stepperWrapper: {
    display: 'flex',
    justifyContent: 'left',
    padding: 15,
    width: '100%',
    backgroundColor: '#ebf7ff',
  },
  info: {
    height: '19px',
    width: '20px',
    marginRight: '10px',
  },
}));

const getSubmitOrderPriorities = (priorities: string[]) => {
  const orderPriorities = priorities.map(priority => {
    return prioritiesData.filter(item => {
      if (item.Description === priority) {
        return item.ID;
      }
    })[0];
  });
  return orderPriorities;
};

const getSubmitOrderShifts = (shifts: string[]) => {
  const shiftUpdateData = shifts.map(shift => {
    return shiftData.filter((item: ShiftData) => {
      if (item.Description === shift) {
        return item.Id;
      }
    })[0];
  });
  return shiftUpdateData;
};

const getOrderPriorities = facilityData => {
  const defaultPriorities: string[] = [];
  for (var property in facilityData) {
    if (facilityData[property]) {
      defaultPriorities.push(OrderConstants.OrderPrioritiesLabel[property]);
    }
  }
  return defaultPriorities;
};

export const OrderCreationBody = (props: Props) => {
  useInjectReducer({ key: orderDataSliceKey, reducer: orderDataReducer });
  useInjectSaga({ key: orderDataSliceKey, saga: orderDataSaga });
  useInjectReducer({ key: createOrderOcbrSliceKey, reducer: createOrderOcbrReducer });
  useInjectSaga({ key: createOrderOcbrSliceKey, saga: createOrderOcbrSaga });
  const {
    onTypeChange,
    facilityData,
    onOrderCreationNav,
    unitNavigationTarget,
    setUnitNavigationTarget,
    createdNewUnitId,
    //activateStep,
    onNext,
    onShowmsg,
    showCancelConfirmation,
    updateShowCancelConfirmation,
    setCreatedNewUnitId,
    clickOnBackButton,
    setClickOnBackButton,
  } = props;
  const [newOrder, setNewOrder] = React.useState<NewOrder>(getEmptyOrder(facilityData));
  const defaultOrderPriorities = getOrderPriorities({
    bedSize: facilityData.bedSize,
    teaching: facilityData.isTeaching,
    magnetStatus: facilityData.isMagnetStatus,
    traumaCenter: facilityData.isTraumaCenter,
  });
  const selectedNewUnit = useSelector(selectNewUnit);
  const isRateCardValidationError = useSelector(selectRateCardValidationError);
  const [openSubmitConfirmation, setOpenSubmitConfirmation] = React.useState(false);
  const [inProgress, setInProgress] = React.useState<boolean>(true);
  const [openCancelConfirmation, setOpenCancelConfirmation] = React.useState(false);
  //const [activeStep, setActiveStep] = React.useState(activateStep);
  const history = useHistory();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [offContractId] = useState(uuid());
  const [showMandetryCss, setShowMandetryCss] = React.useState<boolean>(false);
  const [processStatus, setProcessStatus] = useState<boolean>(false);
  const [processStarted, setProcessStarted] = useState<boolean>(false);
  const [changeProcessScreenRequest, setChangeProcessScreenRequest] = useState<boolean>(false);
  const storeOrderCreationProcessStep = useSelector(selectOrderCreationProcessStep);
  const { disciplineOpts, specialtyOpts, subSpecialtyOpts } = useSelector(selectCreateOrderMiscellaneousData);
  const regions = useSelector(selectOrderRegions);

  const { billRates: ocbrBillRates, effectiveDates: ocbrEffectiveDates, isFlatRate } = useSelector(selectOcbrDetails);
  const { effectiveDates } = useSelector(selectOcbrDetails);
  const user = useSelector(selectUser);
  const [rateCardFlag] = useDecision(ff_order_ui_rate_card);
  const orderTypes = useSelector(selectOrderDetailsOrderType);

  const getSteps = () => {
    if (rateCardFlag.enabled) return ['Define Unit', 'Order Details', 'Rate Card', 'Preview & Submit'];
    else return ['Define Unit', 'Order Details', 'Preview & Submit'];
  };
  const ocbr = useSelector(selectCreateOrderOcbr);

  const steps = getSteps();

  const defaultOrderProcessData = {
    facilityId: facilityData.facilityId,
    facilityData: facilityData,
    currentStep: 'SelectUnit',
    activeStep: 0,
    requestedBack: false,
    selectedNewUnitId: -1,
    selectedNewUnit: null,
    isDetailsUpdated: false,
    isRequirementEdited: false,
    isUnitSelected: false,
    isMandatoryFieldsFilled: false,
    isMandatorySecondaryFieldsFilled: false,
    showMandetryStyle: false,
    editMode: 'standardize',
    unitsData: null,
    newOrder: null,
  } as IOrderCreationProcessStep;

  const getSubmitOrderTypeId = (type: string) => {
    const orderTypeId = orderTypes.filter(item => item.Description === type);
    return orderTypeId[0]?.ID;
  };

  const getFacilityUnits = async (facilityId: number) => {
    setInProgress(true);
    await getUnitsForFacility(facilityId).then(response => {
      if (response) {
        dispatch(
          orderDataActions.setOrderCreationProcessStep({
            facilityId: facilityData.facilityId,
            facilityData: facilityData,
            currentStep: 'SelectUnit',
            activeStep: 0,
            requestedBack: false,
            selectedNewUnitId: -1,
            selectedNewUnit: null,
            isDetailsUpdated: false,
            isRequirementEdited: false,
            isUnitSelected: false,
            isMandatoryFieldsFilled: false,
            isMandatorySecondaryFieldsFilled: false,
            showMandetryStyle: false,
            editMode: 'standardize',
            unitsData: response,
            newOrder: null,
          } as IOrderCreationProcessStep),
        );
      } else {
        dispatch(
          globalActions.setSnackBar({
            message: t('candidate.credentials.networkError'),
            severity: 'error',
          }),
        );
      }
      setInProgress(false);
    });
  };

  const getFacilityUnitsWithSelect = async (facilityId: number, unitId: number, opTrigger: string) => {
    setInProgress(true);
    await getUnitsForFacility(facilityId).then(response => {
      if (response) {
        dispatch(
          orderDataActions.setOrderCreationProcessStep({
            facilityId: facilityData.facilityId,
            facilityData: facilityData,
            currentStep: opTrigger,
            activeStep: 0,
            requestedBack: false,
            selectedNewUnitId: unitId,
            selectedNewUnit: null,
            isDetailsUpdated: false,
            isRequirementEdited: false,
            isUnitSelected: false,
            isMandatoryFieldsFilled: false,
            isMandatorySecondaryFieldsFilled: false,
            showMandetryStyle: false,
            editMode: 'standardize',
            unitsData: response,
            newOrder: null,
          } as IOrderCreationProcessStep),
        );
      } else {
        dispatch(
          globalActions.setSnackBar({
            message: t('candidate.credentials.networkError'),
            severity: 'error',
          }),
        );
      }
    });
  };

  const loadFacilityData = async () => {
    setInProgress(true);
    await getFacilityUnits(facilityData.facilityId);
  };

  const showMandatoryFormError = (show: boolean) => {
    setShowMandetryCss(show);
    onShowmsg(show);
  };

  useEffect(() => {
    if (unitNavigationTarget !== 'SelectUnit' && unitNavigationTarget !== 'UpdateOrderDetails') {
      showMandatoryFormError(false);
      loadFacilityData();
      dispatch(createOrderOcbrActions.getMiscellaneousData());
      dispatch(createOrderOcbrActions.resetRateCard());
      setUnitNavigationTarget('');
    }
  }, []);

  useEffect(() => {
    if (storeOrderCreationProcessStep) {
      if (storeOrderCreationProcessStep.currentStep === 'SelectUnitTrigger') {
        showMandatoryFormError(false);
        setInProgress(true);
        updateMasterData('select unit', storeOrderCreationProcessStep.selectedNewUnitId);
      } else if (storeOrderCreationProcessStep.currentStep === 'UpdateOrderDetailsTrigger') {
        showMandatoryFormError(false);
        setInProgress(true);
        updateMasterData('proceed to unit details', storeOrderCreationProcessStep.selectedNewUnitId);
      } else {
        onNext(storeOrderCreationProcessStep.activeStep);
      }
      if (changeProcessScreenRequest) {
        setChangeProcessScreenRequest(false);
      }
    }
  }, [storeOrderCreationProcessStep]);

  useEffect(() => {
    if (storeOrderCreationProcessStep && clickOnBackButton && !processStarted) {
      setProcessStarted(true);
      handleBack();
    }
  }, [clickOnBackButton, processStarted]);

  useEffect(() => {
    if (createdNewUnitId && createdNewUnitId > 0) {
      setInProgress(true);
      setProcessStatus(true);
      const createdUnitId = createdNewUnitId;
      if (unitNavigationTarget === 'SelectUnit') {
        getFacilityUnitsWithSelect(facilityData.facilityId, createdUnitId, 'SelectUnitTrigger');
      } else if (unitNavigationTarget === 'UpdateOrderDetails') {
        getFacilityUnitsWithSelect(facilityData.facilityId, createdUnitId, 'UpdateOrderDetailsTrigger');
      }
      setCreatedNewUnitId(-1);
    }
  }, [unitNavigationTarget, createdNewUnitId]);

  useEffect(() => {
    if (storeOrderCreationProcessStep && storeOrderCreationProcessStep.isUnitSelected && selectedNewUnit) {
      const orderCreationProcessStepTemp = _.cloneDeep(storeOrderCreationProcessStep) ?? defaultOrderProcessData;
      orderCreationProcessStepTemp.selectedNewUnit = selectedNewUnit;
      if (orderCreationProcessStepTemp.currentStep === 'SelectUnit') {
        orderCreationProcessStepTemp.newOrder = getNewOrderDetail(selectedNewUnit);
      } else {
        orderCreationProcessStepTemp.newOrder = getNewOrderDetailFromExistingOrderAndUnit(
          selectedNewUnit,
          storeOrderCreationProcessStep.newOrder ?? getEmptyOrder(facilityData),
        );
      }
      orderCreationProcessStepTemp.isMandatoryFieldsFilled = IsMandatoryFieldFilled(
        orderCreationProcessStepTemp.newOrder,
      );
      orderCreationProcessStepTemp.isMandatorySecondaryFieldsFilled =
        IsSecondaryMandatoryFieldFilled(orderCreationProcessStepTemp.newOrder) ?? false;
      orderCreationProcessStepTemp.showMandetryStyle = orderCreationProcessStepTemp.isMandatoryFieldsFilled;
      dispatch(orderDataActions.setOrderCreationProcessStep(orderCreationProcessStepTemp));
    }
  }, [selectedNewUnit]);

  useEffect(() => {
    if (showCancelConfirmation) {
      if (storeOrderCreationProcessStep?.currentStep == 'SubmitOrder') {
        setOpenSubmitConfirmation(true);
      } else {
        setOpenCancelConfirmation(true);
      }
      updateShowCancelConfirmation(false);
    }
  }, [showCancelConfirmation, storeOrderCreationProcessStep]);

  const getUnitFromID = id => {
    return storeOrderCreationProcessStep?.unitsData?.filter(unit => unit.unitId === +id)[0] as NewUnit;
  };

  const getNewOrderDetailFromExistingOrderAndUnit = (unit: NewUnit, newOrderPayload: NewOrder) => {
    const updatedOrder = _.cloneDeep(newOrderPayload);
    updatedOrder.unit.unitId = unit.unitId;
    updatedOrder.unit.name = unit.name;
    if (facilityData.amnDivisionTypeId === AMNDivisionType.localStaffing) {
      //change here
      updatedOrder.type = facilityData.division;
    }
    if (facilityData.amnDivisionTypeId === AMNDivisionType.hsg) {
      updatedOrder.type = 'Strike';
    }

    if (!newOrderPayload) {
      if (facilityData.region !== 'AMN Allied') {
        updatedOrder.region = facilityData.region;
      } else {
        updatedOrder.region = '';
      }
    } else {
      if (facilityData.amnDivisionTypeId === AMNDivisionType.hsg) {
        updatedOrder.region = 'Unassigned';
      } else {
        updatedOrder.region = newOrderPayload.region;
      }
    }

    updatedOrder.yearsOfExperience =
      unit.travelExperience?.yearsOfExperience ??
      unit.experience?.skillSets.find(s => s.isPrimary)?.yearsOfExperience ??
      0;
    updatedOrder.preferredQualifications = unit.preferredQualifications ?? '';
    updatedOrder.requiredQualifications = unit.requiredQualifications ?? '';
    updatedOrder.internalNotes = unit.internalNotes;
    updatedOrder.jobDescription = unit.externalJobDescription;
    updatedOrder.newGrad = (unit.experience && unit.experience.isNewGrad) || false;
    updatedOrder.skillsets = (unit.experience && unit.experience.skillSets) || [];
    updatedOrder.orderPriorities =
      unit.createdByVersion === '2.0' ? unit.orderPriorities.map(x => x.description) : defaultOrderPriorities;
    updatedOrder.virtualRating = unit.virtualRating;
    return updatedOrder;
  };

  const getNewOrderDetail = (unit: NewUnit, newOrderPayload?: NewOrder) => {
    const updatedOrder = newOrderPayload ? _.cloneDeep(newOrderPayload) : _.cloneDeep(newOrder);
    updatedOrder.unit.unitId = unit.unitId;
    updatedOrder.unit.name = unit.name;
    if (facilityData.amnDivisionTypeId === AMNDivisionType.localStaffing) {
      //change here
      updatedOrder.type = facilityData.division;
    }

    if (!newOrderPayload) {
      if (facilityData.region !== 'AMN Allied') {
        updatedOrder.region = facilityData.region;
      } else {
        updatedOrder.region = '';
      }
    } else {
      if (facilityData.amnDivisionTypeId === AMNDivisionType.hsg) {
        updatedOrder.region = 'Unassigned';
      } else {
        updatedOrder.region = newOrderPayload.region;
      }
    }

    updatedOrder.yearsOfExperience =
      unit.travelExperience?.yearsOfExperience ??
      unit.experience?.skillSets.find(s => s.isPrimary)?.yearsOfExperience ??
      0;
    updatedOrder.preferredQualifications = unit.preferredQualifications ?? '';
    updatedOrder.requiredQualifications = unit.requiredQualifications ?? '';
    updatedOrder.internalNotes = unit.internalNotes;
    updatedOrder.jobDescription = unit.externalJobDescription;
    updatedOrder.newGrad = (unit.experience && unit.experience.isNewGrad) || false;
    updatedOrder.skillsets = (unit.experience && unit.experience.skillSets) || [];
    updatedOrder.orderPriorities =
      unit.createdByVersion === '2.0' ? unit.orderPriorities.map(x => x.description) : defaultOrderPriorities;
    updatedOrder.virtualRating = unit.virtualRating;
    return updatedOrder;
  };

  const IsMandatoryFieldFilled = (order: NewOrder) => {
    return (
      order?.jobDescription !== null &&
      order?.jobDescription !== '' &&
      order?.skillsets !== null &&
      //@ts-ignore
      order?.skillsets !== [] &&
      order?.skillsets.length !== 0 &&
      order?.skillsets !== null
    );
  };

  const IsSecondaryMandatoryFieldFilled = (order: NewOrder) => {
    return order?.positionsAvailable && order?.positionsAvailable > 0 && order?.orderTypeId && order?.region
      ? true
      : false;
  };

  const updateMasterData = async (ops: string, value: any) => {
    const orderCreationProcessStepTemp = _.cloneDeep(storeOrderCreationProcessStep) ?? defaultOrderProcessData;
    if (ops === 'select unit') {
      const selectedUnitIdTemp = value as number;
      const selectedUnitTemp = getUnitFromID(selectedUnitIdTemp);
      if (selectedUnitTemp) {
        orderCreationProcessStepTemp.selectedNewUnitId = selectedUnitIdTemp;
        orderCreationProcessStepTemp.selectedNewUnit = selectedUnitTemp;
        orderCreationProcessStepTemp.newOrder = getNewOrderDetail(selectedUnitTemp, getEmptyOrder(facilityData));
        orderCreationProcessStepTemp.isMandatoryFieldsFilled = IsMandatoryFieldFilled(
          orderCreationProcessStepTemp.newOrder,
        );
        orderCreationProcessStepTemp.isUnitSelected = true;
        orderCreationProcessStepTemp.isDetailsUpdated = false;
        orderCreationProcessStepTemp.isRequirementEdited = false;
        orderCreationProcessStepTemp.currentStep = 'SelectUnit';
        orderCreationProcessStepTemp.activeStep = 0;
        orderCreationProcessStepTemp.isEditStarted = false;
        dispatch(orderDataActions.setNewUnit(orderCreationProcessStepTemp.selectedNewUnit));
        dispatch(orderDataActions.setNewOrder(orderCreationProcessStepTemp.newOrder));
        dispatch(orderDataActions.setOrderCreationProcessStep(orderCreationProcessStepTemp));
      }
      setInProgress(false);
      setProcessStatus(false);
    } else if (ops === 'proceed to unit details') {
      const selectedUnitIdTemp = value as number;
      const selectedUnitTemp = getUnitFromID(selectedUnitIdTemp);
      if (selectedUnitTemp) {
        orderCreationProcessStepTemp.selectedNewUnitId = selectedUnitIdTemp;
        orderCreationProcessStepTemp.selectedNewUnit = selectedUnitTemp;
        orderCreationProcessStepTemp.newOrder = getNewOrderDetail(selectedUnitTemp, getEmptyOrder(facilityData));
        orderCreationProcessStepTemp.isMandatoryFieldsFilled = IsMandatoryFieldFilled(
          orderCreationProcessStepTemp.newOrder,
        );
        if (orderCreationProcessStepTemp.isMandatoryFieldsFilled) {
          orderCreationProcessStepTemp.isUnitSelected = true;
          orderCreationProcessStepTemp.isDetailsUpdated = true;
          orderCreationProcessStepTemp.isRequirementEdited = false;
          orderCreationProcessStepTemp.currentStep = 'UpdateOrderDetails';
          orderCreationProcessStepTemp.activeStep = 1;
          orderCreationProcessStepTemp.isEditStarted = true;
        } else {
          orderCreationProcessStepTemp.isUnitSelected = true;
          orderCreationProcessStepTemp.isDetailsUpdated = false;
          orderCreationProcessStepTemp.isRequirementEdited = false;
          orderCreationProcessStepTemp.currentStep = 'SelectUnit';
          orderCreationProcessStepTemp.activeStep = 0;
          orderCreationProcessStepTemp.isEditStarted = false;
        }
        dispatch(orderDataActions.setNewUnit(orderCreationProcessStepTemp.selectedNewUnit));
        dispatch(orderDataActions.setNewOrder(orderCreationProcessStepTemp.newOrder));
        dispatch(orderDataActions.setOrderCreationProcessStep(orderCreationProcessStepTemp));
      }
      setInProgress(false);
      setProcessStatus(false);
    }
  };

  const handleCancelConfirmOpen = () => {
    setOpenCancelConfirmation(true);
  };

  const handleSubmit = () => {
    handleSubmitConfirmOpen();
  };

  const handleSubmitConfirmOpen = () => {
    setOpenSubmitConfirmation(true);
  };

  const handleSubmitConfirmClose = (isConfirm: boolean) => {
    if (storeOrderCreationProcessStep && storeOrderCreationProcessStep.newOrder && isConfirm) {
      handleOrderSubmission(storeOrderCreationProcessStep.newOrder);
    }
    setOpenSubmitConfirmation(false);
  };

  const handleOrderSubmission = async (order: NewOrder) => {
    try {
      const selectedUnitOrderPriorities = selectedNewUnit?.orderPriorities?.map(item => {
        return {
          ID: item.id,
          Description: item.description,
        };
      });

      const newOrderPriorities =
        order?.orderPriorities && order?.orderPriorities.filter(val => !defaultOrderPriorities.includes(val));
      const prioritiesUpdatedX = getSubmitOrderPriorities(newOrderPriorities);
      const prioritiesUpdated = selectedUnitOrderPriorities
        ? selectedUnitOrderPriorities?.concat(prioritiesUpdatedX)
        : prioritiesUpdatedX;
      const regionUpdated = regions.filter(item => item.Description === order.region);
      order.startDate?.setHours(0, 0, 0, 0);
      order.endDate?.setHours(0, 0, 0, 0);
      const rateElements = getAllRateElements({
        isFlatRate,
        ocbrBillRates,
        disciplineOpts,
        specialtyOpts,
        subSpecialtyOpts,
        offContractId,
      });
      const orderToBeSaved = {
        ...order,
        division: facilityData.division,
        bestBets: facilityData.bestBets,
        unit: {
          ...order.unit,
          ...selectedNewUnit,
          fyre: selectedNewUnit?.fyreIntegration,
        },
        shifts: getSubmitOrderShifts(order.shifts),
        orderPriorities:
          prioritiesUpdated &&
          prioritiesUpdated.map(priority => {
            return {
              orderPriorityTypeId: priority.ID,
              description: priority.Description,
            };
          }),
        orderTypeId: getSubmitOrderTypeId(order.type),
        orderReasonId: getOrderReasonId(order.orderReason || ''),
        onCallRate: order.onCallRate ? +order.onCallRate : 0,
        orderBillRate: order.orderBillRate ? parseFloat(order.orderBillRate) : 0,
        internalNotes: `${
          selectedNewUnit
            ? handleInternalNotesStringNullCondition(selectedNewUnit.internalNotes)
            : handleInternalNotesStringNullCondition(order.internalNotes)
        }${getInternalNotes(order)}`,
        requiredQualifications: selectedNewUnit?.requiredQualifications
          ? selectedNewUnit?.requiredQualifications
          : order.requiredQualifications,
        preferredQualifications: selectedNewUnit?.preferredQualifications
          ? selectedNewUnit.preferredQualifications
          : order.preferredQualifications,
        jobDescription: selectedNewUnit?.externalJobDescription ? selectedNewUnit.externalJobDescription : '',
        nonContractedBillRates: order.nonContractedBillRates || [],
        placementLength: +order.placementLength,
        skillsets: selectedNewUnit?.experience?.skillSets
          ? selectedNewUnit?.experience?.skillSets.map(item => {
              return {
                ...item,
                disciplineAbbr: disciplineOpts.find(x => x.object.Description === item.discipline)?.object.Abbreviation,
                specialtyAbbr: item.specialty,
              } as SkillSet;
            })
          : [],
        contacts: {
          recruiter: {
            userId: facilityData.recruiter?.employeeId ?? facilityData.recruiter?.userId ?? null,
            firstName: '',
            lastName: '',
            email: '',
          },
          accountManager: {
            userId:
              facilityData.hospitalAccountManager?.employeeId ?? facilityData.hospitalAccountManager?.userId ?? null,
            firstName: '',
            lastName: '',
            email: '',
          },
          regionalDirector: {
            userId: facilityData.regionalDirector?.employeeId ?? facilityData.regionalDirector?.userId ?? null,
            firstName: '',
            lastName: '',
            email: '',
          },
          credentialingAnalyst: null,
          clinicalManager: null,
          verifiedBy: null,
        },
        currentUserId: facilityData.currentUserId ?? null,
        location: {
          address1: '',
          address2: '',
          city: '',
          state: '',
          zipCode: '',
          country: '',
          region: order.region,
          regionId: regionUpdated[0].ID,
          phoneNumber: '',
        },
        rateElements: rateElements,
        orderRateEffectiveStartDate: ocbrEffectiveDates?.startDate,
        orderRateEffectiveEndDate: ocbrEffectiveDates?.endDate,
        isFlatRate,
      };
      setInProgress(true);
      const response = await createOrder(orderToBeSaved);
      trackEvent({
        type: 'event',
        name: 'Order Created',
        properties: {
          multipleDates: order.dates.length > 1,
          flex: order.flexibleDates,
          asap: order.ASAP,
          MFS: order.maxFilesSent,
        },
        timerKey: 'orderCreationStart',
      });
      if (httpSuccess(response.status)) {
        //SAVE OFF CONTRACT RATES TO COSMOS DB
        const offContractDetails = getOffContractDataToSave(
          response.data,
          ocbrBillRates,
          isFlatRate,
          ocbrEffectiveDates?.startDate,
          ocbrEffectiveDates?.endDate,
          facilityData.facilityId,
          offContractId,
          user,
        );
        offContractDetails && dispatch(createOrderOcbrActions.saveOffcontractDetails(offContractDetails));
        const facilityRateElementDetails = getFacilityRateElementDocumentsDataToSave(
          response.data,
          ocbrBillRates,
          isFlatRate,
          offContractId,
          facilityData.facilityId,
          user,
        );
        facilityRateElementDetails &&
          dispatch(createOrderOcbrActions.saveFacilityOffContractRateElementDocuments(facilityRateElementDetails));
        dispatch(createOrderOcbrActions.saveDocuments(facilityData.facilityId));
      }
      dispatch(createOrderOcbrActions.resetOcbrDetails());
      dispatch(createOrderOcbrActions.resetOcbrSavedDetails());
      dispatch(orderDataActions.setIsEditUnit(false));
      dispatch(orderDataActions.setOrderCreationProcessStep(null));
      dispatch(
        globalActions.setSnackBar({
          message: OrderConstants.FAILURE_MESSAGE,
          severity: 'error',
        }),
      );
      showMandatoryFormError(false);
      setInProgress(false);
      history.replace({
        pathname: `/order/${response.data}`,
        state: 'newOrder',
      });
    } catch (error) {
      setInProgress(false);
      dispatch(
        globalActions.setSnackBar({
          message: 'Something went wrong. Please refresh your page to continue',
          severity: 'error',
        }),
      );
      trackException({
        exception: error,
        properties: {
          name: ExceptionType.CommonUIError,
          functionName: 'handleOrderSubmission',
          area: 'src/app/components/Order/OrderCreationForm/OrderCreationBody.tsx',
        },
      });
    }
  };

  const editOrderRequirements = () => {
    if (
      storeOrderCreationProcessStep &&
      storeOrderCreationProcessStep.isUnitSelected &&
      storeOrderCreationProcessStep.selectedNewUnitId > 0 &&
      storeOrderCreationProcessStep.selectedNewUnit
    ) {
      const orderCreationProcessStepTemp = _.cloneDeep(storeOrderCreationProcessStep);
      if (storeOrderCreationProcessStep.selectedNewUnit.createdByVersion === '2.0') {
        //set edit mode as standardize and open edit order requirement page
        //reset order payload
        orderCreationProcessStepTemp.newOrder = getNewOrderDetail(
          storeOrderCreationProcessStep.selectedNewUnit,
          getEmptyOrder(facilityData),
        );
        orderCreationProcessStepTemp.isEditStarted = true;
        orderCreationProcessStepTemp.currentStep = 'EditOrderRequirement';
        orderCreationProcessStepTemp.isRequirementEdited = true;
        orderCreationProcessStepTemp.isDetailsUpdated = false;
        orderCreationProcessStepTemp.editMode = 'standardize';
        orderCreationProcessStepTemp.activeStep = 0;
        dispatch(orderDataActions.setOrderCreationProcessStep(orderCreationProcessStepTemp));
      } else {
        //select edit mode and proceed
        setAlertOpen(true);
      }
    }
  };

  const handleAlertNotNow = () => {
    if (
      storeOrderCreationProcessStep &&
      storeOrderCreationProcessStep.isUnitSelected &&
      storeOrderCreationProcessStep.selectedNewUnitId > 0 &&
      storeOrderCreationProcessStep.selectedNewUnit
    ) {
      const orderCreationProcessStepTemp = _.cloneDeep(storeOrderCreationProcessStep);
      //reset order payload
      orderCreationProcessStepTemp.newOrder = getNewOrderDetail(
        storeOrderCreationProcessStep.selectedNewUnit,
        getEmptyOrder(facilityData),
      );
      orderCreationProcessStepTemp.isEditStarted = true;
      orderCreationProcessStepTemp.currentStep = 'EditOrderRequirement';
      orderCreationProcessStepTemp.isRequirementEdited = true;
      orderCreationProcessStepTemp.isDetailsUpdated = false;
      orderCreationProcessStepTemp.editMode = 'notnow';
      orderCreationProcessStepTemp.activeStep = 0;
      dispatch(orderDataActions.setOrderCreationProcessStep(orderCreationProcessStepTemp));
      setAlertOpen(false);
    }
  };

  const handleAlertStandardize = () => {
    if (
      storeOrderCreationProcessStep &&
      storeOrderCreationProcessStep.isUnitSelected &&
      storeOrderCreationProcessStep.selectedNewUnitId > 0 &&
      storeOrderCreationProcessStep.selectedNewUnit
    ) {
      const orderCreationProcessStepTemp = _.cloneDeep(storeOrderCreationProcessStep);
      //reset order payload
      orderCreationProcessStepTemp.newOrder = getNewOrderDetail(
        storeOrderCreationProcessStep.selectedNewUnit,
        getEmptyOrder(facilityData),
      );
      orderCreationProcessStepTemp.isEditStarted = true;
      orderCreationProcessStepTemp.currentStep = 'EditOrderRequirement';
      orderCreationProcessStepTemp.isRequirementEdited = true;
      orderCreationProcessStepTemp.isDetailsUpdated = false;
      orderCreationProcessStepTemp.editMode = 'standardize';
      orderCreationProcessStepTemp.activeStep = 0;
      dispatch(orderDataActions.setOrderCreationProcessStep(orderCreationProcessStepTemp));
      setAlertOpen(false);
    }
  };

  //Next Button config
  const handleNext = () => {
    if (
      storeOrderCreationProcessStep &&
      storeOrderCreationProcessStep.isUnitSelected &&
      storeOrderCreationProcessStep.selectedNewUnitId > 0 &&
      storeOrderCreationProcessStep.selectedNewUnit &&
      storeOrderCreationProcessStep.newOrder
    ) {
      const orderCreationProcessStepTemp = _.cloneDeep(storeOrderCreationProcessStep);
      const mandatoryOk = IsMandatoryFieldFilled(storeOrderCreationProcessStep.newOrder);
      if (storeOrderCreationProcessStep.currentStep === 'SelectUnit') {
        if (mandatoryOk) {
          orderCreationProcessStepTemp.currentStep = 'UpdateOrderDetails';
          orderCreationProcessStepTemp.isRequirementEdited = false;
          orderCreationProcessStepTemp.isDetailsUpdated = true;
          orderCreationProcessStepTemp.activeStep = 1;
          orderCreationProcessStepTemp.isEditStarted = true;
          dispatch(orderDataActions.setOrderCreationProcessStep(orderCreationProcessStepTemp));
          showMandatoryFormError(false);
        } else {
          orderCreationProcessStepTemp.activeStep = 0;
          orderCreationProcessStepTemp.isMandatoryFieldsFilled = false;
          dispatch(orderDataActions.setOrderCreationProcessStep(orderCreationProcessStepTemp));
          showMandatoryFormError(true);
        }
      } else if (storeOrderCreationProcessStep.currentStep === 'EditOrderRequirement') {
        if (mandatoryOk) {
          orderCreationProcessStepTemp.currentStep = 'UpdateOrderDetails';
          orderCreationProcessStepTemp.activeStep = 1;
          orderCreationProcessStepTemp.isRequirementEdited = true;
          orderCreationProcessStepTemp.isDetailsUpdated = true;
          orderCreationProcessStepTemp.isEditStarted = true;
          dispatch(orderDataActions.setOrderCreationProcessStep(orderCreationProcessStepTemp));
          showMandatoryFormError(false);
        } else {
          orderCreationProcessStepTemp.activeStep = 0;
          showMandatoryFormError(true);
        }
      } else if (storeOrderCreationProcessStep.currentStep === 'UpdateOrderDetails') {
        //clear rate elements redux state
        dispatch(orderDataActions.resetRateElements());
        dispatch(orderDataActions.resetSavedRateElements());
        const mandatorySecondaryOk = IsSecondaryMandatoryFieldFilled(storeOrderCreationProcessStep.newOrder);
        if (mandatorySecondaryOk) {
          if (rateCardFlag.enabled) {
            orderCreationProcessStepTemp.currentStep = 'RateCard';
            orderCreationProcessStepTemp.activeStep = 2;
            orderCreationProcessStepTemp.isEditStarted = true;
            dispatch(orderDataActions.setOrderCreationProcessStep(orderCreationProcessStepTemp));
            showMandatoryFormError(false);
          } else {
            orderCreationProcessStepTemp.currentStep = 'SubmitOrder';
            orderCreationProcessStepTemp.activeStep = 3;
            orderCreationProcessStepTemp.isEditStarted = true;
            dispatch(orderDataActions.setOrderCreationProcessStep(orderCreationProcessStepTemp));
            showMandatoryFormError(false);
          }
        } else {
          orderCreationProcessStepTemp.activeStep = 1;
          showMandatoryFormError(true);
        }
        //else if for Rate Order here
      } else if (storeOrderCreationProcessStep.currentStep === 'RateCard') {
        //Here add validation fn for Rate card
        const mandatorySecondaryOk = IsSecondaryMandatoryFieldFilled(storeOrderCreationProcessStep.newOrder);
        // const mandatoryOrderEffectiveDates= isOrderEffectiveDatesValid(effectiveDates?.startDate,effectiveDates?.endDate)
        if (mandatorySecondaryOk && !isRateCardValidationError) {
          orderCreationProcessStepTemp.currentStep = 'SubmitOrder';
          orderCreationProcessStepTemp.activeStep = 3;
          orderCreationProcessStepTemp.isEditStarted = true;
          dispatch(orderDataActions.setOrderCreationProcessStep(orderCreationProcessStepTemp));
          showMandatoryFormError(false);
        }
        //add code to change storeOrderCreationProcessStep to Submit order
      } else if (storeOrderCreationProcessStep.currentStep === 'SubmitOrder') {
        const mandatorySecondaryOk = IsSecondaryMandatoryFieldFilled(storeOrderCreationProcessStep.newOrder);
        if (mandatoryOk && mandatorySecondaryOk) {
          handleSubmit();
        } else {
          showMandatoryFormError(true);
          alert('Fill required');
        }
      }
    }
  };

  //Back Button config
  const handleBack = () => {
    showMandatoryFormError(false);
    if (
      storeOrderCreationProcessStep &&
      storeOrderCreationProcessStep.isUnitSelected &&
      storeOrderCreationProcessStep.selectedNewUnitId > 0 &&
      storeOrderCreationProcessStep.selectedNewUnit
    ) {
      const orderCreationProcessStepTemp = _.cloneDeep(storeOrderCreationProcessStep);
      if (storeOrderCreationProcessStep.currentStep === 'EditOrderRequirement') {
        updateMasterData('select unit', storeOrderCreationProcessStep.selectedNewUnitId);
        setProcessStarted(false);
        setClickOnBackButton(false);
      } else if (storeOrderCreationProcessStep.currentStep === 'UpdateOrderDetails') {
        if (storeOrderCreationProcessStep.isRequirementEdited) {
          orderCreationProcessStepTemp.currentStep = 'EditOrderRequirement';
          orderCreationProcessStepTemp.activeStep = 0;
          dispatch(orderDataActions.setOrderCreationProcessStep(orderCreationProcessStepTemp));
        } else {
          updateMasterData('select unit', storeOrderCreationProcessStep.selectedNewUnitId);
        }
        setProcessStarted(false);
        setClickOnBackButton(false);

        //change the back function
      } else if (storeOrderCreationProcessStep.currentStep === 'RateCard') {
        setProcessStarted(false);
        setClickOnBackButton(false);
        orderCreationProcessStepTemp.currentStep = 'UpdateOrderDetails';
        orderCreationProcessStepTemp.activeStep = 1;
        dispatch(orderDataActions.setOrderCreationProcessStep(orderCreationProcessStepTemp));
        dispatch(createOrderOcbrActions.reset());
      } else if (storeOrderCreationProcessStep.currentStep === 'SubmitOrder') {
        if (rateCardFlag.enabled) {
          orderCreationProcessStepTemp.currentStep = 'RateCard';
          orderCreationProcessStepTemp.activeStep = 2;
          dispatch(orderDataActions.setOrderCreationProcessStep(orderCreationProcessStepTemp));
          setProcessStarted(false);
          setClickOnBackButton(false);
        } else {
          setProcessStarted(false);
          setClickOnBackButton(false);
          orderCreationProcessStepTemp.currentStep = 'UpdateOrderDetails';
          orderCreationProcessStepTemp.activeStep = 1;
          dispatch(orderDataActions.setOrderCreationProcessStep(orderCreationProcessStepTemp));
        }
      } else {
        setProcessStarted(false);
        setClickOnBackButton(false);
      }
    }
  };

  const handleOrderDetailsPropertyChange = (property: string, value: any) => {
    if (storeOrderCreationProcessStep && storeOrderCreationProcessStep.currentStep === 'UpdateOrderDetails') {
      const orderCreationProcessStepTemp = _.cloneDeep(storeOrderCreationProcessStep);
      const orderTemp = storeOrderCreationProcessStep.newOrder;
      if (orderTemp) {
        const orderTempx = { ...orderTemp, [property]: value };
        orderCreationProcessStepTemp.newOrder = orderTempx;
        dispatch(orderDataActions.setOrderCreationProcessStep(orderCreationProcessStepTemp));
      }
    }
    if (property === 'type') {
      onTypeChange(value);
    }
  };

  //Cancel Button config
  const handleCancelConfirmClose = (isConfirm: boolean) => {
    if (isConfirm) {
      dispatch(orderDataActions.setNewOrder(null));
      dispatch(orderDataActions.setNewUnit(null));
      dispatch(orderDataActions.setIsEditUnit(false));
      dispatch(orderDataActions.setLevel('None'));
      onOrderCreationNav(false);
      dispatch(orderDataActions.setCurrentUnitId(0));
      dispatch(orderDataActions.setReload(false));
      dispatch(orderDataActions.setOrderCreationProcessStep(null));
      dispatch(
        orderDataActions.setOrderCreationProcessStep({
          facilityId: facilityData.facilityId,
          facilityData: facilityData,
          currentStep: 'SelectUnit',
          activeStep: 0,
          requestedBack: false,
          selectedNewUnitId: -1,
          selectedNewUnit: null,
          isDetailsUpdated: false,
          isRequirementEdited: false,
          isUnitSelected: false,
          newOrder: null,
        } as IOrderCreationProcessStep),
      );
      setInProgress(false);
    }
    setOpenCancelConfirmation(false);
  };

  const handleNewUnitNavigation = (navConfirmation: boolean) => {
    if (navConfirmation) {
      dispatch(orderDataActions.setOrderCreationProcessStep(null));
    }
    onOrderCreationNav(navConfirmation);
  };

  const handleInternalNotesStringNullCondition = str => {
    if (str === null || str === '' || str === undefined) return '';
    return `${str}\n`;
  };

  const toOrderDetails = (order: NewOrder) => {
    const selectedUnitOrderPriorities = selectedNewUnit?.orderPriorities?.map(item => {
      return {
        ID: item.id,
        Description: item.description,
      };
    });
    const newOrderPriorities =
      order?.orderPriorities && order?.orderPriorities.filter(val => !defaultOrderPriorities.includes(val));
    const prioritiesUpdatedX = getSubmitOrderPriorities(newOrderPriorities);
    const prioritiesUpdated = selectedUnitOrderPriorities?.concat(prioritiesUpdatedX);
    const regionUpdated = regions && regions.filter(item => item.Description === order.region);
    const emptyOrder = getEmptyOrderDetails();
    return {
      ...emptyOrder,
      ...order,
      orderId: 'XXXXXX',
      location: {
        ...emptyOrder.location,
        address1: facilityData?.facilityAddress.line1,
        city: facilityData?.facilityAddress.line2.split(',')[0],
        state: facilityData?.facilityAddress.line2.split(',')[1],
        zipCode: facilityData?.facilityAddress.line2.split(',')[2],
        region: order.region,
        regionId: regionUpdated[0]?.ID,
      },
      skillsets: selectedNewUnit?.experience?.skillSets.map(item => {
        return {
          ...item,
          disciplineAbbr: disciplineOpts.find(x => x.object.Description === item.discipline)?.object.Abbreviation,
          specialtyAbbr: item.specialty,
        } as SkillSet;
      }),
      unit: {
        ...order.unit,
        ...selectedNewUnit,
        fyre: selectedNewUnit?.fyreIntegration,
      },
      division: facilityData.division,
      bestBets: facilityData.bestBets,
      clinicalFellowship: order.CF,
      vmsOrderNumber: order.VMSOrderNumber,
      webLink: order.weblink,
      numOfWeeks: order.placementLength,
      shifts: order.shifts.map((item, index) => {
        return {
          id: index,
          description: item,
        } as ShiftResponse;
      }),
      orderPriorities: prioritiesUpdated?.map(priority => {
        return {
          orderPriorityTypeId: priority.ID,
          description: priority.Description,
        };
      }),
      weeklyGrossPay: null,
      orderReasonId: getOrderReasonId(order.orderReason || ''),
      orderTypeId: getSubmitOrderTypeId(order.type),
      orderType: order.type,
      internalNotes: `${
        selectedNewUnit
          ? handleInternalNotesStringNullCondition(selectedNewUnit.internalNotes)
          : handleInternalNotesStringNullCondition(order.internalNotes)
      }${getInternalNotes(order)}`,
      onCallRate: order.onCallRate ? +order.onCallRate : 0,
      orderBillRate: order.orderBillRate ? parseFloat(order.orderBillRate) : 0,
      nonContractedBillRates: order.nonContractedBillRates || [],
      placementLength: +order.placementLength,
      gww: null,
      activeBonus: null,
      contacts: {
        recruiter: null,
        accountManager: {
          userId: facilityData?.hospitalAccountManager?.userId,
          firstName: facilityData?.hospitalAccountManager?.firstName,
          lastName: facilityData?.hospitalAccountManager?.lastName,
          email: '',
        },
        regionalDirector: {
          userId: facilityData.regionalDirector.userId,
          firstName: facilityData.regionalDirector.firstName,
          lastName: facilityData.regionalDirector.lastName,
          email: '',
        },
        credentialingAnalyst: null,
        clinicalManager: null,
        verifiedBy: null,
      },
      currentUserId: facilityData.currentUserId,
      submissionRequirements: selectedNewUnit?.requiredQualifications,
      preferredQualifications: selectedNewUnit?.preferredQualifications,
      jobDescription: selectedNewUnit?.externalJobDescription,
    } as IOrderDetails;
  };

  return (
    <React.Fragment>
      {inProgress ||
        processStatus ||
        !storeOrderCreationProcessStep ||
        (!storeOrderCreationProcessStep.unitsData && <CircularProgress className={classes.MuiCircularProgressRoot} />)}
      <React.Fragment>
        <div style={{ marginBottom: '24px' }}>
          <Grid container>
            {!inProgress &&
              storeOrderCreationProcessStep &&
              storeOrderCreationProcessStep.currentStep !== 'SubmitOrder' && (
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Divider light={true} style={{ marginTop: 5 }}></Divider>
                  </Grid>
                  <Grid item xs={12}>
                    {/* place where progress bar is shown */}
                    <div style={{ width: '600px', margin: '0 auto', marginBottom: '24px' }}>
                      <TopLabelStepper activeStep={storeOrderCreationProcessStep?.activeStep} steps={steps} />
                    </div>
                  </Grid>
                </Grid>
              )}
            {/* Initially defining an order */}
            {!inProgress &&
              (storeOrderCreationProcessStep?.currentStep === 'SelectUnit' ||
                storeOrderCreationProcessStep?.currentStep === 'EditOrderRequirement') && (
                <DefineUnit
                  showMandetryStyle={showMandetryCss}
                  onNewUnitNav={handleNewUnitNavigation}
                  onShowmsg={onShowmsg}
                  isShowMandetryStyle={() => {}}
                  updateDetails={updateMasterData}
                />
              )}
            {!inProgress &&
              storeOrderCreationProcessStep &&
              storeOrderCreationProcessStep.newOrder &&
              storeOrderCreationProcessStep?.currentStep === 'UpdateOrderDetails' && (
                <NewOrderDetails
                  order={storeOrderCreationProcessStep.newOrder}
                  onOrderPropertyChange={handleOrderDetailsPropertyChange}
                  facilityDivision={facilityData.amnDivisionTypeId}
                  showMandetryStyle={showMandetryCss}
                />
              )}
            {/* Rate card component*/}
            {rateCardFlag.enabled &&
              !inProgress &&
              storeOrderCreationProcessStep &&
              storeOrderCreationProcessStep.newOrder &&
              storeOrderCreationProcessStep?.currentStep === 'RateCard' && (
                <RateCard
                  facilityId={storeOrderCreationProcessStep.facilityId}
                  shifts={storeOrderCreationProcessStep.newOrder.shifts}
                  skillsets={storeOrderCreationProcessStep.newOrder.skillsets}
                />
              )}
            {/* Details of the order - before final submission */}
            {!inProgress &&
              storeOrderCreationProcessStep &&
              storeOrderCreationProcessStep.newOrder &&
              storeOrderCreationProcessStep?.currentStep === 'SubmitOrder' && (
                <>
                  <div className={classes.stepperWrapper}>
                    <img src={info} alt={`info`} className={classes.info} />
                    <Typography>{`Please review your order and submit to create`}</Typography>
                  </div>
                  <DetailsPage
                    title={`Order Details`}
                    head={
                      <OrderHeader
                        headerDetails={toHeaderDetails(toOrderDetails(storeOrderCreationProcessStep.newOrder))}
                        handleOrderPropertyUpdate={() => {}}
                      />
                    }
                    statTiles={
                      <OrderQuickGlance
                        quickGlanceDetails={toQuickGlance(toOrderDetails(storeOrderCreationProcessStep.newOrder))}
                        onClick={() => {}}
                      />
                    }
                  >
                    <OrderDetailsDataWrapper
                      orderDetails={toOrderDetails(storeOrderCreationProcessStep.newOrder)}
                      onChange={() => {}}
                      disableEdit={true}
                    />
                  </DetailsPage>
                </>
              )}
            {/* Button Group */}
            {!inProgress && storeOrderCreationProcessStep && (
              <ButtonSection>
                <div>
                  <Button
                    variant="contained"
                    disableElevation
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    className={`${classes.button} ${classes.buttonWidth} ${classes.cancelButton}`}
                    onClick={handleCancelConfirmOpen}
                    data-testid="test cancel button"
                  >
                    {`Cancel`}
                  </Button>
                  {storeOrderCreationProcessStep.currentStep === 'SelectUnit' && (
                    <Button
                      disableElevation
                      disableRipple
                      disableTouchRipple
                      disableFocusRipple
                      variant="contained"
                      color="primary"
                      data-type="large"
                      className={`${classes.button} ${classes.buttonWidth}`}
                      onClick={editOrderRequirements}
                      data-testid="test edit order requirements button"
                      disabled={!storeOrderCreationProcessStep.isUnitSelected}
                    >
                      {`Edit Order Requirements`}
                    </Button>
                  )}
                  {storeOrderCreationProcessStep.currentStep !== 'SelectUnit' && (
                    <Button
                      variant="contained"
                      disableElevation
                      disableRipple
                      disableFocusRipple
                      color="primary"
                      onClick={handleBack}
                      className={`${classes.button} ${classes.buttonWidth}`}
                      data-testid="test back button"
                    >
                      {`Back`}
                    </Button>
                  )}
                  <div style={{ display: 'inline-block' }}>
                    <CustomTooltip
                      tooltipContent={'Please edit order requirements to fill out required fields.'}
                      tooltipPlacementBottom={classes.tooltipPlacementBottom}
                      hideToolTip={
                        !(
                          !storeOrderCreationProcessStep.isMandatoryFieldsFilled &&
                          storeOrderCreationProcessStep.currentStep === 'SelectUnit'
                        )
                      }
                    >
                      {storeOrderCreationProcessStep?.currentStep === 'SubmitOrder' ? (
                        withReadOnly(Button)({
                          disableElevation: true,
                          disableRipple: true,
                          disableTouchRipple: true,
                          disableFocusRipple: true,
                          variant: 'contained',
                          color: 'primary',
                          className: `${classes.button} ${classes.buttonWidth} ${classes.addJustButton}`,
                          onClick: handleNext,
                          form: 'my-form',
                          'data-testid': 'test confirm button',
                          type: 'submit',
                          children: storeOrderCreationProcessStep?.currentStep === 'SubmitOrder' ? 'Submit' : 'Next',
                        })
                      ) : (
                        <Button
                          disableElevation
                          disableRipple
                          disableTouchRipple
                          disableFocusRipple
                          variant="contained"
                          color="primary"
                          className={`${classes.button} ${classes.buttonWidth} ${classes.addJustButton}`}
                          onClick={handleNext}
                          disabled={
                            storeOrderCreationProcessStep.currentStep === 'SelectUnit' &&
                            !storeOrderCreationProcessStep.isMandatoryFieldsFilled
                          }
                          form="my-form"
                          data-testid="test confirm button"
                        >
                          {storeOrderCreationProcessStep?.currentStep === 'SubmitOrder' ? 'Submit' : 'Next'}
                        </Button>
                      )}
                    </CustomTooltip>
                  </div>
                </div>
              </ButtonSection>
            )}
          </Grid>
        </div>
      </React.Fragment>
      {/* dialoges */}
      <Dialog
        open={openCancelConfirmation}
        onClose={() => handleCancelConfirmClose(false)}
        fullWidth
        maxWidth={'sm'}
        aria-labelledby="cancel-confirmation"
        aria-describedby="cancel-confirmation-description"
        disableEscapeKeyDown
      >
        <DialogTitle id="cancel-confirmation-title">
          <MessageHeader>{`Are you sure you want to cancel?`}</MessageHeader>
        </DialogTitle>
        <DialogActions>
          <ButtonSpace>
            <Button
              onClick={() => handleCancelConfirmClose(false)}
              disableElevation
              disableRipple
              disableFocusRipple
              disableTouchRipple
              color="inherit"
              className={`${classes.button} ${classes.buttonWidth} ${classes.buttonHeight} ${classes.modalButton}`}
              data-testid="test dialog confirm button"
            >
              {`No`}
            </Button>
            <Button
              onClick={() => handleCancelConfirmClose(true)}
              disableElevation
              disableRipple
              disableFocusRipple
              disableTouchRipple
              variant="contained"
              color="primary"
              className={`${classes.button} ${classes.buttonWidth} ${classes.buttonHeight} ${classes.modalButton}`}
              data-testid="test dialog cancel button"
            >
              {`Yes`}
            </Button>
          </ButtonSpace>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openSubmitConfirmation}
        onClose={() => handleSubmitConfirmClose(false)}
        fullWidth
        maxWidth={'sm'}
        aria-labelledby="submit-confirmation"
        aria-describedby="submit-confirmation-description"
        disableEscapeKeyDown
      >
        <DialogTitle id="submit-confirmation-title">
          <MessageHeader>{`Are you sure you want to submit?`}</MessageHeader>
        </DialogTitle>
        <DialogActions>
          <ButtonSpace>
            <Button
              onClick={() => handleSubmitConfirmClose(false)}
              disableElevation
              disableRipple
              disableFocusRipple
              disableTouchRipple
              color="inherit"
              className={`${classes.button} ${classes.buttonWidth} ${classes.buttonHeight} ${classes.modalButton}`}
              data-testid="test-dialogSubmitConfirmButton"
            >
              {`No`}
            </Button>
            <Button
              onClick={() => handleSubmitConfirmClose(true)}
              disableElevation
              disableRipple
              disableFocusRipple
              disableTouchRipple
              variant="contained"
              color="primary"
              className={`${classes.button} ${classes.buttonWidth} ${classes.buttonHeight} ${classes.modalButton}`}
              data-testid="test-dialogSubmitCancelButton"
            >
              {`Yes`}
            </Button>
          </ButtonSpace>
        </DialogActions>
      </Dialog>
      <GenericDialog
        open={alertOpen}
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
        dialogTitleProps={{
          text: t('Edit Order Requirements'),
        }}
        dialogActions={[
          {
            text: t('Standardize'),
            variant: 'contained',
            color: 'tertiary',
            onClick: handleAlertStandardize,
          },
          {
            text: t(`Not Now`),
            variant: 'contained',
            color: 'primary',
            onClick: handleAlertNotNow,
          },
        ]}
      >
        <span>
          {t(
            `You are opening an order with a unit that was created in AMIE Classic. Do you want to standardize the order requirements?`,
          )}
        </span>
      </GenericDialog>
    </React.Fragment>
  );
};
