/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable i18next/no-literal-string */
import React, { useEffect, useState } from 'react';
import { Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { candidateDetailsSelection } from '../../../Profile/CandidateDetails.selector';
import { useTranslation } from 'react-i18next';
import { AddressSectionWrapper } from './AddressSectionWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { AddAddressForm } from './AddAddressForm';
import { candidateDetailActions } from '../../../Profile/CandidateDetails.redux';
import { selectUser } from 'oidc/user.selectors';
import { userRoles } from 'oidc/userRoles';
import treeData from 'app/assets/jsons/placementStatusFilter.json';
import { validateAddressAddAccess } from './PersonalInfoHelper';
import { Authorized } from 'oidc/userHelper';

const useStyles = makeStyles()(theme => ({
  inlineLink: {
    'font-size': '14px',
    'font-weight': '600',
    'padding-left': '0.3rem',
    'text-decoration': 'underline',
    'white-space': 'nowrap',
    cursor: 'pointer',
    color: '#0D6FB9',
  },
  textColor: {
    color: '#0D6FB9',
    textAlign: 'left',
    font: 'normal normal medium 14px / 26px Roboto',
    fontWeight: 500,
  },
}));

export const AddressSection = props => {
  const candidateDetails = useSelector(candidateDetailsSelection);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [addAddress, setAddAddress] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectableAddresses, setSelectableAddresses] = useState<any>(null);
  const [candidateAddresses, setCandidateAddresses] = useState<any>(candidateDetails.addressData || []);
  const user = useSelector(selectUser);
  const booked = treeData.filter(item => item.name == 'Booked');
  const onAssignment = treeData
    .filter(item => item.name == 'On Assignment')[0]
    .children.filter(x => x.value === '3' || x.value === '23');
  const inSubmission = treeData
    .filter(item => item.name == 'In Submission')[0]
    .children.filter(x => x.value === '3' || x.value === '2');
  const inSubmissionPrior = treeData
    .filter(item => item.name == 'In Submission')[0]
    .children.filter(x => x.value !== '3' && x.value !== '2');
  const Offer = treeData.filter(item => item.name == 'Offer');
  const allowedStatuses: any[] = [];
  const allowedPriorStatuses: any[] = [];
  const notAllowedAtBookedStatus: any[] = [];

  booked[0].children.map(item => {
    allowedStatuses.push(item.name);
    //For strictly booked status group:
    notAllowedAtBookedStatus.push(item.name);
  });

  Offer[0].children.map(item => {
    allowedPriorStatuses.push(item.name);
  });

  inSubmission.map(item => {
    if (item.value === '3' || item.value === '2') allowedStatuses.push(item.name);
  });
  onAssignment.map(item => {
    notAllowedAtBookedStatus.push(item.name);
  })

  inSubmissionPrior.map(item => {
    allowedPriorStatuses.push(item.name);
  });

  useEffect(() => {
    setCandidateAddresses(candidateDetails.addressData);
  }, [candidateDetails]);

  const toggleAddAddress = () => {
    setAddAddress(false);
    dispatch(candidateDetailActions.setReLoading(true));
  };

  useEffect(() => {
    if (
      (Authorized(
        [
          userRoles.recruitment,
          userRoles.recruitment_Leadership,
          userRoles.recruitment_TeamMember,
          userRoles.clinical_Manager,
          userRoles.clinical_ReviewAnalyst,
          userRoles.clinical_QualificationsSpecialist,
          userRoles.accountManagement,
          userRoles.accountManagement_Leadership,
          userRoles.accountManagement_TeamMember,
          userRoles.accountsPayableSpecialist,
          userRoles.benefitsCoordinator,
          userRoles.billingSpecialist,
          userRoles.candidate_Contracts,
          userRoles.candidate_Contracts_Leadership,
          userRoles.candidate_Contracts_TeamMember,
          userRoles.clientAccountingRepresentative,
          userRoles.clientContract_Admin,
          userRoles.clientContract_Admin_Leadership,
          userRoles.clientContract_Admin_TeamMember,
          userRoles.clientSupportSpecialist,
          userRoles.clinical_ClientContractSpecialist,
          userRoles.clinical_Director,
          userRoles.complianceAuditSpecialist,
          userRoles.credentialing,
          userRoles.credentialing_Leadership,
          userRoles.credentialing_TeamMember,
          userRoles.customerSupport_Leadership,
          userRoles.customerSupport_TeamMember,
          userRoles.housingAccountExecutive,
          userRoles.payrollSpecialist,
          userRoles.pricingAnalyst,
          userRoles.regionalDirector,
          userRoles.riskManagement,
          userRoles.supportAdmin,
          userRoles.timeProcessingSpecialist,
          userRoles.workersCompCoordinator
        ],
        user.userInfo,
      ) &&
        !notAllowedAtBookedStatus.includes(candidateDetails.placementStatus))
    ) {
      setIsDisabled(false);
    }

    if (allowedPriorStatuses.includes(candidateDetails.placementStatus)) {
      setSelectableAddresses(validateAddressAddAccess(user.userInfo, 1));
    }

    if (notAllowedAtBookedStatus.includes(candidateDetails.placementStatus)) {
      setSelectableAddresses(validateAddressAddAccess(user.userInfo, 3));
    } else if (allowedStatuses.includes(candidateDetails.placementStatus)) {
      setSelectableAddresses(validateAddressAddAccess(user.userInfo, 2));
    } else {
      setSelectableAddresses(validateAddressAddAccess(user.userInfo, 4));
    }

    if (
      Authorized(
        [
          userRoles.customerSupport_Leadership,
          userRoles.customerSupport_TeamMember,
          userRoles.payrollSpecialist,
          userRoles.benefitsCoordinator,
          userRoles.supportAdmin,
        ],
        user.userInfo,
      )
    ) {
      setIsDisabled(false);
      if (notAllowedAtBookedStatus.includes(candidateDetails.placementStatus)) {
        setSelectableAddresses(validateAddressAddAccess(user.userInfo, 3));
      } else {
        setSelectableAddresses(validateAddressAddAccess(user.userInfo, 1));
      }
    }
  }, [user.userInfo]);

  return (
    <LayoutGrid container direction="column" style={{ width: '100%' }}>
      <LayoutGridItem>
        <LayoutGrid container direction="row" justifyContent="space-between">
          <LayoutGridItem item style={{ paddingLeft: 20 }}>
            <Typography
              className={classes.textColor}
              variant="subtitle1"
              gutterBottom
              component="div"
              style={{ paddingBottom: 10 }}
            >
              Addresses
            </Typography>
          </LayoutGridItem>
          <LayoutGridItem
            item
            style={{ paddingRight: 10 }}
            onClick={() => {
              setAddAddress(true);
            }}
          >
            <span aria-disabled={isDisabled} className={classes.inlineLink}>
              {t('candidate.profileSummaryCard.add')}
            </span>
          </LayoutGridItem>
        </LayoutGrid>
      </LayoutGridItem>
      {addAddress && !isDisabled && (
        <LayoutGridItem>
          <LayoutGrid>
            <LayoutGridItem item style={{ paddingLeft: 20, paddingBottom: '12px', width: '100%' }}>
              <AddAddressForm toggleAddAddress={toggleAddAddress} selectableAddresses={selectableAddresses} />
            </LayoutGridItem>
          </LayoutGrid>
        </LayoutGridItem>
      )}
      <LayoutGridItem>
        <LayoutGrid>
          {candidateAddresses?.map((item, index) => {
            return (
              <LayoutGridItem item style={{ paddingLeft: 20, paddingTop: 0, width: '100%' }} key={index}>
                <AddressSectionWrapper
                  address={item}
                  state={item.state}
                  index={index}
                  isAddAddress={addAddress}
                  toggleAddAddress={toggleAddAddress}
                  checkRoleTypeEdit={props.checkRoleTypeEdit}
                />
              </LayoutGridItem>
            );
          })}
        </LayoutGrid>
      </LayoutGridItem>
    </LayoutGrid>
  );
};
