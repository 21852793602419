import React, { useState } from 'react';
import { Grid, IconButton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { colors } from 'styles/styleVariables';
import { RemoveIcon } from '../common/RemoveIcon';
import { useDispatch, useSelector } from 'react-redux';
import { selectCreateOrderAttributes } from 'store/redux-store/create-order-ocbr/selector';
import { createOrderOcbrActions } from 'store/redux-store/create-order-ocbr/slice';

const useStyles = makeStyles()(theme => ({
  addButtonRoot: {
    color: colors.amnBlue,
    background: 'none !important',
    padding: '0 !important',
  },
}));

export const LocalBillrateBtn = () => {
  const [hover, setHover] = useState(false);
  const { classes } = useStyles();
  const { showLocalBillRate } = useSelector(selectCreateOrderAttributes);

  const dispatch = useDispatch();

  const onClick = React.useCallback(() => {
    dispatch(createOrderOcbrActions.setFeatureAttributes({ key: 'showLocalBillRate', data: !showLocalBillRate }));
  }, [showLocalBillRate]);

  return (
    <Grid container item direction="row" alignItems="center">
      <Grid item>
        <IconButton
          color="primary"
          type="button"
          classes={{ root: classes.addButtonRoot }}
          disableRipple
          centerRipple
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={onClick}
          size="small"
        >
          {showLocalBillRate ? (
            <RemoveIcon toggle={hover} />
          ) : (
            <AddBoxIcon
              style={{ fontSize: 50, fill: hover ? colors.amnLightBlue : '#D6D7D9', paddingRight: 12, marginLeft: -4 }}
            />
          )}
        </IconButton>
      </Grid>
      <Grid item>{showLocalBillRate ? 'Remove Local Bill Rate' : 'Add Local Bill Rate'}</Grid>
    </Grid>
  );
};
