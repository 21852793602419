import { CustomFilter } from '../../../../ComponentLibrary/Filter';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { getFilterSpecs, defaultValues, recPlacementsFilterName } from './filterUtils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectFalseRecruiters, selectFilterSpecs } from '../RecruiterPlacements.selector';
import { selectUser } from 'oidc/user.selectors';
import { Concatenate } from 'utils/string/string';
import { useDecision } from '@optimizely/react-sdk';
import { ff_placement_ui_arbitration_agreement_grids } from 'app/constants/FeatureFlags/Global/keys';

export const PlacementsFilter = props => {
  const {
    setValue,
    formState: { dirtyFields },
  } = useFormContext();
  const { t } = useTranslation();
  const [arbitrationAgreementFlag] = useDecision(ff_placement_ui_arbitration_agreement_grids);
  const { userInfo } = useSelector(selectUser);
  const filterSpecs = useSelector(selectFilterSpecs);
  const falseRecruiters = useSelector(selectFalseRecruiters);
  const fields = getFilterSpecs(filterSpecs, falseRecruiters, t, arbitrationAgreementFlag.enabled);

  const resetFilter = () => {
    if (userInfo) {
      const label = Concatenate([userInfo.firstName || '', userInfo.lastName || ''], ' ');
      const placementRecruiter = [{
        id: userInfo.employeeId,
        name: label,
        value: userInfo.employeeId,
        object: {
          userId: userInfo.employeeId,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
        },
      }];
      const newFilter = { ...defaultValues, placementRecruiter };
      Object.entries(newFilter).map(item =>
        setValue(item[0], item[1], {
          shouldDirty: item[0] === recPlacementsFilterName.placementRecruiter,
        }),
      );
      props.onApplyFilter(newFilter, undefined, []);
    }
  };

  return (
    <CustomFilter
      fields={fields}
      onResetForm={() => resetFilter()}
      justifyContent={'flex-start'}
      // false Prop added to instantiate dirtyFields from react-hook-form: formState, without consuming
      falseProp={dirtyFields}
      version2
      analytics={{
        caller: 'Recruiter WorkDesk',
        onApply: true,
        properties: {
          category: 'My Placements',
        },
      }}
    />
  );
};
