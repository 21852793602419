import { Box, Grid, Skeleton } from 'amn-ui-core';
import { MediaQuery } from 'app/layout/MediaQuery';
import React, { createContext, useMemo, useState } from 'react';
import { OrderInformation } from './OrderInformation/OrderInformation';
import { Placements } from './Placements';
import { Risks } from './Risks';
import { SellingPoints } from './SellingPoints';
import { Submission } from './Statuses/Submission/Submission';
import { WhatsNext } from './WhatsNext';
import { CustomModal } from 'app/components/Common/CustomModal/CustomModal';
import { useParams } from 'react-router-dom';
import { Offer } from './Statuses/Offer/Offer';
import { Booking } from './Statuses/Booking/Booking';
import { useSelector } from 'react-redux';
import { usePromiseTracker } from 'react-promise-tracker';
import {
  selectPlacementDetails,
  selectPlacementOrderType,
  selectPlacementSupervisee,
  selectPlacementSupervision,
} from '../../../../../../store/redux-store/placement-details/selectors';
import { OrderTypeIds, PlacementOrderType } from 'app/models/Placement/PlacementDetails';
import { useRequirementPromiseTracker } from './helper';
import { PeopleSoft } from './PeopleSoft';
import { StrikeStatus } from './StrikeStatus';
import { Assignment } from '../PlacementSummaryTab/Statuses/Assignment/Assignment';
import {
  ff_placement_ui_peoplesoft_sync,
  ff_placement_check_for_classic_packets_in_ok_to_send_status,
} from 'app/constants/FeatureFlags/Placement/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { ExtensionContainer } from './Statuses/Extensions/ExtensionContainer';
import {
  selectClassicSubmissionPacketInOkToSendExists,
  selectExtensionInfo,
  selectSubmissionPacketDetail,
} from 'store/redux-store/placement-summary/selectors';
import { InterviewStatus } from './Statuses/InterviewStatus/Interview';
import { PlacementSupervision } from './PlacementSupervision';
import { makeStyles } from 'tss-react/mui';
import { SubmissionErrorContainer } from './SubmissionPacketError';
import { ClassicPacketOkToSendWarningContainer } from './EFOWarning';
import { useTranslation } from 'react-i18next';
import { DirectHire } from './DirectHire';

export const AddOnContext = createContext<{
  setModalProps: React.Dispatch<
    React.SetStateAction<{
      onChange: (e: any) => void;
      title: string;
      subTitle: any | undefined;
      columns: any[];
      rows: any[];
    } | null>
  > | null;
  placementId: number;
  candidateId: number;
}>({ setModalProps: null, placementId: 0, candidateId: 0 });

const useStyles = makeStyles()(() => ({
  root: {
    paddingBottom: '36px',
  },
}));

export const PlacementSummaryTab = () => {
  const [modalProps, setModalProps] = useState<{
    onChange: (e: any) => void;
    title: string;
    subTitle: any | undefined;
    columns: any[];
    rows: any[];
  } | null>(null);
  const { t } = useTranslation();
  const placementOrderType = useSelector(selectPlacementOrderType);
  const classicSubmissionPacketInOkToSendExists = useSelector(selectClassicSubmissionPacketInOkToSendExists);
  const loadingRequirements = useRequirementPromiseTracker();
  const { placementId: paramId } = useParams<{ placementId: string }>();
  const { promiseInProgress: loadingOrderDivision } = usePromiseTracker({ area: 'placement-header-call', delay: 0 });
  const { promiseInProgress: loadingInitialDetails } = usePromiseTracker({ area: 'placement-consolidated-initial-section-details-data', delay: 0 });
  const { promiseInProgress: loadingBulkDetails } = usePromiseTracker({ area: 'placement-consolidated-bulk-section-details-data', delay: 0 });
  const [enablePeoplesoft] = useDecision(ff_placement_ui_peoplesoft_sync);
  const [checkClassicPacketOkToSendFlag] = useDecision(ff_placement_check_for_classic_packets_in_ok_to_send_status);
  const submissionPacketDetail = useSelector(selectSubmissionPacketDetail);
  const { classes } = useStyles();

  const {
    header: {
      candidate: { id: candidateId },
      supervisorRequired,
      orderTypeId,
    },
  } = useSelector(selectPlacementDetails);
  const selPlacementSupervision = useSelector(selectPlacementSupervision);
  const selPlacementSupervisee = useSelector(selectPlacementSupervisee);

  const extensions = useSelector(selectExtensionInfo);

  const { isStrikePlacement, isDefaultPlacement } = useMemo(
    () => ({
      isStrikePlacement: placementOrderType === PlacementOrderType.StrikeOrderInHsg,
      isDefaultPlacement: placementOrderType === PlacementOrderType.Default,
    }),
    [placementOrderType],
  );

  const LoadingComponent = ({ sx }) => {
    return (
      <Box sx={sx}>
        <Skeleton sx={{ height: '45px' }} />
      </Box>
    );
  }

  const IsLoadingDetails = useMemo(
    () => (loadingInitialDetails === true || loadingBulkDetails === true),
    [loadingInitialDetails, loadingBulkDetails],
  );

  return (
    <AddOnContext.Provider value={{ setModalProps, placementId: parseInt(paramId), candidateId }}>
      {modalProps && <CustomModal {...modalProps} />}
      <Grid container spacing={2} className={classes.root} alignItems="flex-start">
        <Grid item xs={8} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
            {IsLoadingDetails === true ? <LoadingComponent sx={{ marginY: 1 }}/> :<OrderInformation /> }
            </Grid>
            <Grid item xs={12}>
            {IsLoadingDetails === true ? <LoadingComponent sx={{ marginY: 1 }}/> :<Submission />}
            </Grid>
            {isDefaultPlacement && (
              <Grid item xs={12}>
                {IsLoadingDetails === true ? <LoadingComponent sx={{ marginY: 1 }}/> :<InterviewStatus />}
              </Grid>
            )}
            {isDefaultPlacement && (
              <Grid item xs={12}>
                {IsLoadingDetails === true ? <LoadingComponent sx={{ marginY: 1 }}/> :<Assignment />}
              </Grid>
            )}
            {isDefaultPlacement &&
              extensions.map((extension, indx) => (
                <Grid key={indx.toString()} item xs={12}>
                  <ExtensionContainer
                    id={indx}
                    {...extension}
                    status={{
                      extensionStatusId: extension.extensionStatusId,
                      extensionStatus: extension.extensionStatus,
                      updatedBy: extension.updatedBy,
                      updatedAt: extension.updatedAt,
                      displayUpdateSpecs: indx === extensions.length - 1,
                    }}
                    dates={{
                      duration: extension.duration,
                      startDate: extension.startDate,
                      endDate: extension.endDate,
                    }}
                    timeOff={{
                      request: extension.timeOffRequest?.timeOffRequestDetails ?? [],
                      reason: extension.timeOffRequest?.timeOffRequestReason,
                    }}
                  />
                </Grid>
              ))}
            {/* Code: Only show this container if it's a strike placement for now */}
            {isStrikePlacement && (
              <Grid item xs={12}>
                {IsLoadingDetails === true ? 
                <LoadingComponent sx={{ marginY: 1 }}/> :
                <Offer loading={loadingOrderDivision} />
                }
              </Grid>
            )}
            {/* Code: Only show this container if it's a strike placement for now */}
            {isStrikePlacement && (
              <Grid item xs={12}>
                {IsLoadingDetails === true ? 
                <LoadingComponent sx={{ marginY: 1 }}/> :
                <Booking loading={loadingOrderDivision} />
                }
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={4} lg={3}>
          <Grid container spacing={2}>
            <MediaQuery maxWidth="md">
              {isStrikePlacement && (
                <Grid item xs={12}>
                  {IsLoadingDetails === true ? <LoadingComponent sx={{ marginY: 1 }}/> :<StrikeStatus />}
                </Grid>
              )}
              {checkClassicPacketOkToSendFlag?.enabled && classicSubmissionPacketInOkToSendExists && (
                <ClassicPacketOkToSendWarningContainer
                  title={t('placement.profile.summary.efoWarningContainer.title')}
                  message={t('placement.profile.summary.efoWarningContainer.content')}
                />
              )}
              <Grid item xs={12}>
              {IsLoadingDetails === true ? <Skeleton sx={{ marginY: 1 }} /> :<Risks loading={loadingRequirements} />}
              </Grid>
              <SubmissionErrorContainer
                submissionPacketDetail={submissionPacketDetail}
                loadingRequirements={loadingRequirements}
              />
              <Grid item xs={12}>
              {IsLoadingDetails === true ? 
                <Skeleton sx={{ marginY: 1 }} /> :
                <WhatsNext loadingRequirements={loadingRequirements} />
                }
              </Grid>
            </MediaQuery>

            <Grid item xs={12}>
            {IsLoadingDetails === true ? <LoadingComponent sx={{ marginY: 1 }}/> :<SellingPoints />}
            </Grid>
            <Grid item xs={12}>
            {IsLoadingDetails === true ? <LoadingComponent sx={{ marginY: 1 }}/> :<Placements />}
            </Grid>
            {(selPlacementSupervision?.length > 0 ||
              selPlacementSupervisee?.length > 0 ||
              supervisorRequired ||
              orderTypeId === OrderTypeIds.Supervisor ||
              orderTypeId === OrderTypeIds.Tele_Supervisor) && (
              <Grid item xs={12}>
                <PlacementSupervision
                  title={supervisorRequired || selPlacementSupervision?.length > 0 ? 'Supervisor' : 'Supervisee'}
                />
              </Grid>
            )}
            {enablePeoplesoft?.enabled && (
              <Grid item xs={12}>
                {IsLoadingDetails === true ? <LoadingComponent sx={{ marginY: 1 }}/> :<PeopleSoft />}
              </Grid>
            )}
            <Grid item xs={12}>
            {IsLoadingDetails === true ? <LoadingComponent sx={{ marginY: 1 }}/> :<DirectHire />}
            </Grid>
          </Grid>
        </Grid>

        <MediaQuery minWidth="lg">
          <Grid item lg={3}>
            <Grid container spacing={2}>
              {isStrikePlacement && (
                <Grid item xs={12}>
                  {IsLoadingDetails === true ? <Skeleton sx={{ marginY: 1 }} /> :<StrikeStatus />}
                </Grid>
              )}
              {checkClassicPacketOkToSendFlag?.enabled && classicSubmissionPacketInOkToSendExists && (
                <ClassicPacketOkToSendWarningContainer
                  title={t('placement.profile.summary.efoWarningContainer.title')}
                  message={t('placement.profile.summary.efoWarningContainer.content')}
                />
              )}
              <Grid item xs={12}>
              {IsLoadingDetails === true ? <Skeleton sx={{ marginY: 1 }} /> :<Risks loading={loadingRequirements} />}
              </Grid>
              <SubmissionErrorContainer
                submissionPacketDetail={submissionPacketDetail}
                loadingRequirements={loadingRequirements}
              />
              <Grid item xs={12}>
              {IsLoadingDetails === true ? 
                <Skeleton sx={{ marginY: 1 }} /> :
                <WhatsNext loadingRequirements={loadingRequirements} />
              }
              </Grid>
            </Grid>
          </Grid>
        </MediaQuery>
      </Grid>
    </AddOnContext.Provider>
  );
};
