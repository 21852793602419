import { Divider } from 'amn-ui-core';
import { AccountManagerInfo, FacilityData, JobMatchData, OrderData } from 'app/models/Candidate/CandidateJobMatch';
import { GetMomentOfDate } from 'utils/dates/moment';
import { Concatenate } from 'utils/string/string';
import Summary1 from '../../../assets/images/JobMatchGrid/Summary1.png';
import React from 'react';
import WorkIcon from '@mui/icons-material/Work';
import { green } from '@mui/material/colors';
import Call from '../../../assets/images/WorkDesk/Call.png';
import email from '../../../assets/images/WorkDesk/email.png';
import { JobInterestData } from 'app/models/Candidate/CandidateJobInterest';
import { missingDate, missingField } from 'app/constants';
import { TFunction } from 'i18next';
import { getSearchFilterOptions } from 'app/services/SharedServices/SharedServices';
import { educationOptions } from '../CandidateProfile/CandidateTabPanel/JobPreferencesTab/helper';

export const concatenate = data => {
  return Concatenate([data.disciplineAbbr, data.specialtyAbbr], ', ');
};

export const shiftConcatenate = data => {
  let result = '';
  if (data && data.length > 1) {
    data.forEach((element, index) => {
      if (index !== data.length - 1) {
        result = result + element + ', ';
      } else {
        result = result + element;
      }
    });
  } else if (data.length === 1) {
    return data[0];
  }
  return result;
};

//Start Job Match

export const jobMatchFlattenData = (jobMatchInfo: JobMatchData[], t: TFunction) =>
  jobMatchInfo.map(info => {
    const disciplineSpecialty = info.disciplineSpecialty.map(option => {
      return concatenate(option);
    });
    const startDateMoment = GetMomentOfDate(info.expectedStartDate);
    const shiftsData = shiftConcatenate(info.shifts);
    const placementData = info.placementData?.map(item => {
      const sentDateInfo = GetMomentOfDate(item.sentDate);
      const placement = {
        placementId: item.placementId,
        placementStatus: item.placementStatus,
        candidateName: item.candidateName,
        recruiterName: item.recruiterName,
        sentDate: sentDateInfo.isValid() ? sentDateInfo.format('MM/DD/YYYY') : '',
      };
      return placement;
    });
    const accountManagerAttributes = [
      { key: Call, value: info.orderData?.accountManager?.phoneNumber },
      { key: email, value: info.orderData?.accountManager?.email },
    ];
    const data = {
      orderId: info.orderId,
      facilityName: info.facilityName,
      facilityId: info.facilityId,
      city: info.city || missingField,
      state: info.state || missingField,
      expectedStartDate: startDateMoment.isValid() ? startDateMoment.format('MM/DD/YYYY') : 'ASAP',
      expectedStartDateNoFormat: info.expectedStartDate ? info.expectedStartDate : missingDate,
      disciplineSpecialty: disciplineSpecialty || missingField,
      shifts: shiftsData || missingField,
      placementLength: info.placementLength != null ? info.placementLength : missingField,
      accountManager: info.accountManager || missingField,
      accountManagerAttributes,
      weeklyTakeHomePay: info.weeklyTakeHomePay !== null ? `$${info.weeklyTakeHomePay}` : missingField,
      weeklyTakeHomePayValue: info.weeklyTakeHomePay || '',
      milesAway: info.milesAway != null ? info.milesAway : missingField,
      filesInProgress: info.filesInProgress != null ? info.filesInProgress : missingField,
      candidateOfferRating: info.candidateOfferRating != null ? info.candidateOfferRating + '%' : missingField,
      candidateOfferRatingValue: info.candidateOfferRating || '',
      candidateInterestRating: info.candidateInterestRating != null ? info.candidateInterestRating + '%' : missingField,
      candidateInterestRatingValue: info.candidateInterestRating || '',
      radiusRules: info.radiusRules != null ? info.radiusRules : missingField,
      summary: Summary1,
      orderIdHoverViewData: formatOrderIdHover(info.orderData, t),
      facilityNameHoverViewData: formatFacilityNameHover(info.facilityData, t),
      filesInProgressHoverViewData: placementData,
      rank: info.rank,
      selected: false,
      predictiveGoodToGoScore: info.predictiveGoodToGoScore != null ? info.predictiveGoodToGoScore + '%' : missingField,
      predictiveGoodToGoScoreValue: info.predictiveGoodToGoScore || '',
    };
    return data;
  });

export const formatOrderIdHover = (hoverData: OrderData, t: TFunction) => {
  const formattedHover: any[] = [];
  const disciplineSpecialty = hoverData?.skillSet?.map(option => {
    return concatenate(option);
  });
  const startDateMoment = GetMomentOfDate(hoverData?.startDate);
  const verifiedDateMoment = GetMomentOfDate(hoverData?.dateVerified);
  const skillSet = { key: t('order.orderIdHover.skillSet'), value: disciplineSpecialty };
  const facilityName = { key: t('order.orderIdHover.facilityName'), value: hoverData?.facilityName };
  const facilityAddress = { key: t('order.orderIdHover.facilityAddress'), value: hoverData?.facilityAddress };
  const unit = { key: t('order.orderIdHover.unit'), value: hoverData?.unit };
  const horizontalLineBreak = { value: <Divider style={{ marginBottom: '8px' }}></Divider> };
  const startDate = {
    key: t('order.orderIdHover.startDate'),
    value: startDateMoment.isValid() ? startDateMoment.format('MM/DD/YYYY') : 'ASAP',
  };
  const placementLength = { key: t('order.orderIdHover.placementLenght'), value: hoverData?.placementLength };
  const shifts = { key: t('order.orderIdHover.shifts'), value: hoverData?.shifts };
  const YearsOfExperience = { key: t('order.orderIdHover.yearsOfExperience'), value: hoverData?.yearsOfExperience };
  const submissionRequirements = {
    key: t('order.orderIdHover.submissionRequirements'),
    value: hoverData?.submissionRequirements,
  };
  const gww = {
    key: t('order.orderIdHover.gww'),
    value: hoverData?.gww ? <WorkIcon style={{ color: green[500] }} /> : ' ',
  };
  const lastVerifiedDate = {
    key: t('order.orderIdHover.lastVerified'),
    value: verifiedDateMoment.isValid() ? verifiedDateMoment?.format('MM/DD/YYYY') : null,
  };
  const positionAvailable = { key: t('order.orderIdHover.positionsAvailable'), value: hoverData?.positionsAvailable };
  formattedHover.push(
    skillSet,
    facilityName,
    facilityAddress,
    unit,
    horizontalLineBreak,
    startDate,
    placementLength,
    shifts,
    YearsOfExperience,
    submissionRequirements,
    gww,
    lastVerifiedDate,
    positionAvailable,
  );
  return formattedHover;
};

export const formatFacilityNameHover = (hoverData: FacilityData, t: TFunction) => {
  const formattedHover: any[] = [];
  const facilityAddress: any[] = [
    hoverData?.facilityAddress,
    <br />,
    hoverData?.facilityCity + ', ' + hoverData?.facilityState + ' ' + hoverData?.facilityPostalCode,
  ];
  const horizontalLineBreak = { value: <Divider style={{ marginBottom: '8px' }}></Divider> };
  const description = { key: t('order.facilityNameHover.description'), value: '' };
  const descriptionText = { value: hoverData?.description };
  const address = { key: t('order.facilityNameHover.address'), value: facilityAddress };
  const businessRelationship = {
    key: t('order.facilityNameHover.businessRelationShip'),
    value: hoverData?.businessRelationship,
  };
  const setting = { key: t('order.facilityNameHover.setting'), value: hoverData?.setting };
  const staffingVendor = { key: t('order.facilityNameHover.staffingVendor'), value: hoverData?.staffingVendor };
  const technologyVendor = { key: t('order.facilityNameHover.technologyVendor'), value: hoverData?.technologyVendor };
  const numberOfBeds = { key: t('order.facilityNameHover.numberOfBeds'), value: hoverData?.numberOfBeds };
  const payRollRegion = { key: t('order.facilityNameHover.payrollRegion'), value: hoverData?.payRollRegion };
  const instructions = { key: t('order.facilityNameHover.instructions'), value: '' };
  const instructionsText = { value: hoverData?.firstDayInstructions };
  formattedHover.push(
    description,
    descriptionText,
    horizontalLineBreak,
    address,
    businessRelationship,
    setting,
    staffingVendor,
    technologyVendor,
    numberOfBeds,
    payRollRegion,
    horizontalLineBreak,
    instructions,
    instructionsText,
  );
  return formattedHover;
};

export const formataccountManagerHover = (hoverData: AccountManagerInfo) => {
  const formattedHover: any[] = [];
  const email = { key: 'Work Email', value: hoverData?.email };
  const phoneNumber = { key: 'Work Phone Number', value: hoverData?.phoneNumber };
  formattedHover.push(email, phoneNumber);
  return formattedHover;
};

//End Job Match

//Start Job Interest

export const jobInterestFlattenData = (jobInterestInfo: JobInterestData[], t: TFunction) =>
  jobInterestInfo.map(info => {
    const disciplineSpecialty = info.disciplineSpecialty.map(option => {
      return concatenate(option);
    });
    const startDateMoment = GetMomentOfDate(info.expectedStartDate);
    const interestDateMoment = GetMomentOfDate(info.interestDate);
    const shiftsData = shiftConcatenate(info.shifts);
    const placementData = info.placementData?.map(item => {
      const sentDateInfo = GetMomentOfDate(item.sentDate);
      const placement = {
        placementId: item.placementId,
        placementStatus: item.placementStatus,
        candidateName: item.candidateName,
        recruiterName: item.recruiterName,
        sentDate: sentDateInfo.isValid() ? sentDateInfo.format('MM/DD/YYYY') : '',
      };
      return placement;
    });
    const accountManagerAttributes = [
      { key: Call, value: info.orderData?.accountManager?.phoneNumber },
      { key: email, value: info.orderData?.accountManager?.email },
    ];
    const data = {
      interestDate: interestDateMoment.isValid() ? interestDateMoment.utc().format('MM/DD/YYYY') : missingField,
      orderId: info.orderId,
      facilityName: info.facilityName,
      facilityId: info.facilityId,
      city: info.city || missingField,
      state: info.state || missingField,
      expectedStartDate: startDateMoment.isValid() ? startDateMoment.format('MM/DD/YYYY') : 'ASAP',
      expectedStartDateNoFormat: info.expectedStartDate ? info.expectedStartDate : missingDate,
      disciplineSpecialty: disciplineSpecialty || missingField,
      shifts: shiftsData || missingField,
      placementLength: info.placementLength != null ? info.placementLength : missingField,
      accountManager: info.accountManager || missingField,
      accountManagerAttributes,
      weeklyTakeHomePay: info.weeklyTakeHomePay !== null ? `$${info.weeklyTakeHomePay}` : missingField,
      weeklyTakeHomePayValue: info.weeklyTakeHomePay || '',
      milesAway: info.milesAway !== null ? info.milesAway : missingField,
      filesInProgress: info.filesInProgress !== null ? info.filesInProgress : missingField,
      positionsAvailable: info.positionsAvailable !== null ? info.positionsAvailable : missingField,
      radiusRules: info.radiusRules !== null ? info.radiusRules : missingField,
      summary: Summary1,
      orderIdHoverViewData: formatOrderIdHover(info.orderData, t),
      facilityNameHoverViewData: formatFacilityNameHover(info.facilityData, t),
      filesInProgressHoverViewData: placementData,
    };
    return data;
  });

//End Job Interest

// Job Preferences 

const getLocations = (locations: any[]) => {
  if (locations && locations.length > 0) {
    let result: string = "";

    locations?.forEach((element, index) => {
      let location: string = "";

      if (element.state && !element.city && !element.zip && !element.radius) {
        location = element.state;
      } else if (element.state && element.city && !element.zip && !element.radius) {
        location = element.city + ", " + element.state;
      } else if (element.state && element.city && element.zip && !element.radius) {
        location = element.city + ", " + element.state + " " + element.zip;
      } else if (element.state && element.city && element.zip && element.radius) {
        location = element.zip + " - " + element.radius + "mi radius";
      } else if (!element.state && !element.city && element.zip && !element.radius) {
        location = element.zip;
      } else if (!element.state && !element.city && element.zip && element.radius) {
        location = element.zip + " - " + element.radius + "mi radius";
      } else if (element.state && !element.city && element.zip && element.radius) {
        location = element.zip + " - " + element.radius + "mi radius";;
      }else if (!element.state && element.city && !element.zip && !element.radius) {
        location = "";
      } else if (!element.state && element.city && element.zip && !element.radius) {
        location = "";
      } else if (!element.state && element.city && element.zip && element.radius) {
        location = "";
      }
       else if (!element.state && !element.city && !element.zip && !element.radius) {
        location = missingField;
      }
      if (location !== "") {
        result += location;
        if (index < locations.length - 1) {
          result += " / ";
        }
      }
    });

    if (result === "") {
      return missingField;
    }
    return result;
  }
  return missingField;
};

const getSkillSets = (skillSets: any[]) => {
  if (skillSets && skillSets?.length > 0) {
    let skillSet: string = '';
    skillSets?.forEach(element => {
      skillSet = skillSet + ' ' + (element?.discipline || missingField) + '-' + (element?.speciality || missingField) + ",";
    });
    return skillSet.slice(0, skillSet.length - 1);
  } return missingField
}

const getShifts = (shifts: any[]) => {
  if (shifts && shifts?.length > 0) {
    let shift = '';
    shifts.map(item => {
      if (item === 0) {
        shift = shift + 'Day' + ',';
      } else if (item === 1) {
        shift = shift + 'Evening' + ',';
      } else {
        shift = shift + 'Night' + ',';
      };
    })
    return shift.slice(0, shift.length - 1);
  } else return missingField
}

export const getJobPreferencesFlattenedData = (responseData: any[]) => {
  const jobPreferenceFlattenedData: any[] = [];
  responseData?.map((item, index) => {
    const shifts = (item?.shifts)?.map(ele => ele?.name)?.join(', ');
    const data = {
      id: `${item.id}`,
      preferenceSetName: item.preferenceSetName,
      location: getLocations(item.locations),
      notes: item?.notes || missingField,
      state: getLocations(item.locations),
      shift: shifts !== '' ? shifts : missingField,
      skillSet: getSkillSets(item?.skillSetsArray),
      jobMatches: item?.jobMatches,
      isOff: item?.isOff,
      alertsSent: item?.alertsSent,
      alertsSeen: item?.alertsSeen,
      recruiterId: item?.recruiterId,
    }
    jobPreferenceFlattenedData.push(data)
  })
  return jobPreferenceFlattenedData;
};

const getValues = (disciplines, specialities, skillSets) => {
  const arr: any[] = [];
  skillSets?.map(data => {
    const discipline = disciplines.filter(item => item?.value === data?.disciplineId)?.[0];
    const speciality = specialities.filter(item => item?.value === data?.specialtyId)?.[0];
    arr.push({
      discipline: discipline?.name,
      speciality: speciality?.name
    })
  })
  return arr;
};

const getExperienceValues = (data) => {
  const selections = [];
  data?.forEach(element => {
    const opt = educationOptions.filter(item => element == item.value)?.[0]
    selections.push(opt)
  });
  return selections
}

export const getJobPreferenceData = async (data: any[]) => {
  const disciplines = await getSearchFilterOptions('disciplines');
  const specialities = await getSearchFilterOptions('specialties');
  const shift = await getSearchFilterOptions('shiftlist');
  const arr: any[] = [];
  data?.map(item => {
    const skillSetsArray = item?.skillSets?.length > 0 ? getValues(disciplines, specialities, item?.skillSets) : [];
    const shifts = [];
    item?.shiftIds.map(ele => {
      const data = shift.filter(item => item.value === ele)?.[0];
      data && shifts.push(data);
    })
    const element = {
      ...item,
      skillSetsArray: skillSetsArray,
      experience: getExperienceValues(item?.experience),
      day: item?.shift?.includes(0),
      evening: item?.shift?.includes(1),
      night: item?.shift?.includes(2),
      shifts: shifts,
      education: educationOptions.filter(option => item?.education.includes(option.value)),
    };
    arr.push(element);
  })

  return arr;
}