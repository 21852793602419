import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { UpdateMessageAlertReadStatus } from 'app/services/NotificationServices/NotificationService';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getOrderMatchAlertActions, IAssociatedRecord } from './OrderMatchAlert.redux';

interface IOrderMatchAlert {
  associatedRecords: IAssociatedRecord[];
  readStatus: boolean;
  pageSize: number;
  pageNumber: number;
}

export function* getOrderMatchAlert(action: PayloadAction<IOrderMatchAlert>) {
  try {
    const requestBody = {
      associatedRecords: action.payload.associatedRecords,
      readStatus: action.payload.readStatus,
      pageSize: action.payload.pageSize,
      pageNumber: action.payload.pageNumber,
    };

    const response = yield call(UpdateMessageAlertReadStatus, requestBody);
    if (response) {
      yield put(getOrderMatchAlertActions.setData(response.results));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getOrderMatchAlert',
        area: 'src/app/components/Candidate/CandidateProfile/CandidateTabPanel/OrderMatchTab/store/candidateOrderMatch.saga.ts',
      },
    });
  }
}

export function* orderMatchAlertSaga() {
  yield takeLatest(getOrderMatchAlertActions.getOrderMatchAlertAction.type, getOrderMatchAlert);
}
