import { Divider, Grid, IconButton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import CloseIcon from 'app/assets/images/close-24px.svg';
import CueInput from './CueInput';
import { useDispatch, useSelector } from 'react-redux';
import { CueNoteItemsList } from './CueNotesList';
import { taskDetailsActions } from '../store/Tasks.redux';
import { IPlacementDetails } from 'app/models/Placement/PlacementDetails';
import { selectPlacementDetails } from 'store/redux-store/placement-details/selectors';
import { TaskEntity } from 'app/models/Tasks/Tasks';
import { selectDrawerData } from 'store/redux-store/global-search/selectors';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import { globalSearchActions } from 'store/redux-store/global-search/slice';
import { useTranslation } from 'react-i18next';
import { selectCueNotesList } from '../store/Tasks.selectors';
import { selectIsCueNoteCreated, selectCueNoteCreated } from 'app/components/Tasks/store/Tasks.selectors';
import { selectSearchResults } from 'store/redux-store/global-search/selectors';
import _ from 'lodash';
import { getCueNoteRecipientPayload } from 'store/redux-store/placement-details/helper';

const CueNotesContainerStyles = makeStyles()(theme => ({
  messageBar: {
    bottom: 0,
    position: 'absolute',
    left: '0px',
    right: '0px',
  },
  scroll: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column-reverse',
    padding: '5px 5px 0px 5px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  drawerRoot: {
    height: '3rem',
    width: 'inherit',
    boxShadow: '0px 1px 1px #0000004D',
  },
  closeIconPad: {
    fontSize: '14px',
    lineHeight: '19px',
    color: '#797979',
  },
  title: {
    fontSize: '14px',
    lineHeight: '19px',
    fontWeight: 500,
    color: '#797979',
    fontFamily: 'Roboto',
  },
}));

export const CueNotesDrawerWrapper = ({
  clearCueField,
  container,
  setChangePanel,
}: {
  clearCueField: boolean;
  container: TaskEntity;
  setChangePanel: (e) => void;
}) => {
  const { classes } = CueNotesContainerStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const foundPlacement: IPlacementDetails = useSelector(selectPlacementDetails);
  const drawerData = useSelector(selectDrawerData);
  const cueNotesList = useSelector(selectCueNotesList);
  const isReadOnly = drawerData?.isReadOnly ?? false;
  const [offset, setOffset] = useState<number>();
  const [maxHeight, setMaxHeight] = useState<number>(9);
  const isCueNoteCreated = useSelector(selectIsCueNoteCreated);
  const createdCueNote = useSelector(selectCueNoteCreated);
  const { items } = useSelector(selectSearchResults);

  const getCueNotes = () => {
    dispatch(
      taskDetailsActions.setCueListforPlacementOptions({
        placementId: drawerData?.placementId ?? foundPlacement?.header?.placementId,
      }),
    );
    dispatch(
      taskDetailsActions.getCueNotesForPlacements({
        placementId: drawerData?.placementId ?? foundPlacement?.header?.placementId,
      }),
    );
  };

  const closeCueDrawer = () => {
    dispatch(globalSearchActions.setDrawerData({ open: false, data: undefined }));
    dispatch(taskDetailsActions.setCurrentContainer(TaskEntity.ALL));
  };

  useEffect(() => {
    const placementId = drawerData?.placementId;
    if (placementId > 0) {
      dispatch(taskDetailsActions.getCueNoteRecipient(getCueNoteRecipientPayload(placementId)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerData]);

  useEffect(() => {
    if (drawerData?.placementId > 0 || foundPlacement?.header?.placementId > 0) {
      getCueNotes();
    }
    dispatch(taskDetailsActions.setCurrentContainer(TaskEntity.PLACEMENTS));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const pxToRemHeight = offset! / 16;
    setMaxHeight(pxToRemHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container, cueNotesList, offset]);

  React.useEffect(() => {
    if (isCueNoteCreated && createdCueNote && !isNaN(drawerData?.indexCounter)) {
      const clonedTable = _.cloneDeep(items);
      clonedTable[drawerData.indexCounter].cueNote = createdCueNote?.text;
      dispatch(
        globalSearchActions.setSearchResults({
          items: clonedTable,
          count: clonedTable.length,
          miscellaneous: {},
        }),
      );
    }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCueNoteCreated, createdCueNote]);

  return (
    <React.Fragment>
      <Grid className={classes.drawerRoot}>
        <LayoutGridItem container xs={12} direction="row" spacing={2}>
          <LayoutGridItem style={{ padding: '24px 20px 20px 20px' }} container xs={10} className={classes.title}>
            {t('notification.taskSidePanel.cueSidePanelHeading')}
          </LayoutGridItem>
          <LayoutGridItem container xs={2} lg={2} sm={2} md={2} style={{ paddingLeft: '12px', paddingTop: '6px' }}>
            <IconButton color="primary" onClick={closeCueDrawer} className={classes.closeIconPad} size="small">
              <img src={CloseIcon} alt="Close" />
            </IconButton>
          </LayoutGridItem>
        </LayoutGridItem>
      </Grid>
      <Grid
        className={classes.scroll}
        style={
          !isReadOnly
            ? { maxHeight: `calc(100% - (${maxHeight}rem + 4rem))`, marginRight: '3px', marginTop: '6px' }
            : { maxHeight: `calc(100% - 3rem)`, marginRight: '3px', marginTop: '6px' }
        }
      >
        <CueNoteItemsList expandLastCueNote={isReadOnly} cueNotesDrawer={true} />
      </Grid>
      {!isReadOnly && (
        <Grid className={classes.messageBar}>
          <Divider />
          <CueInput
            setChangePanel={setChangePanel}
            setShowRecipient={() => {}}
            clearCueField={clearCueField}
            setOffset={setOffset}
            cueNotesDrawer={true}
          />
        </Grid>
      )}
    </React.Fragment>
  );
};
