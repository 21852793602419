import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import {
  CustomTypeAhead,
  ICustomOption,
} from 'app/components/Placement/CreatePlacement/CustomComponents/CustomTypeAhead';
import { getCandidateOptions } from 'app/services/CandidateServices/CandidateServices';
import throttle from 'lodash/throttle';
import React, { useContext, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useDispatch } from 'react-redux';
import { getSessionValue, SessionKey } from 'utils/customHooks/sessionStorage/sessionHelpers';
import { Concatenate } from 'utils/string/string';
import { AddonContext } from './AssociateCandidate';

export const SearchCandidate = () => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const [options, setOptions] = useState<ICustomOption[]>([]);
  const { promiseInProgress } = usePromiseTracker({ area: 'candidate-throttle-area', delay: 0 });
  const [showHistory, setShowHistory] = useState<boolean>(true);
  const dispatch = useDispatch();
  const { setCurrentFocusId } = useContext(AddonContext);

  const fetchOptions = async request => {
    setShowHistory(false);
    await getCandidateOptions(request).then(res =>
      setOptions(
        res.map(
          opt =>
            ({
              object: opt,
              primaryLabel: `${opt.name} (${opt.candidateId})`,
              secondaryLabel: Concatenate([opt.brand, opt.recruiter, opt.disciplines], ' - '),
            } as ICustomOption),
        ),
      ),
    );
  };

  const throttleService = useMemo(
    () => throttle(request => trackPromise(fetchOptions(request), 'candidate-throttle-area'), 2000),
    [],
  );

  const setSessionOptions = () => {
    setShowHistory(true);
    const recentCandidates = getSessionValue(SessionKey.recentCandidates) || [];
    const newOpts = recentCandidates.map(
      opt =>
        ({
          object: opt,
          primaryLabel: `${opt.name} (${opt.candidateId})`,
          secondaryLabel: Concatenate([opt.brand, opt.recruiter, opt.disciplines?.toString() || ''], ' - '),
        } as ICustomOption),
    );
    setOptions(newOpts);
    return newOpts;
  };

  const autoPopulate = (removeCandidateSelection = false) => {
    const newOpts = setSessionOptions();
    if (newOpts.length && !removeCandidateSelection) {
      setValue('candidate', newOpts[0], { shouldDirty: true });
      dispatch(notificationDataActions.associateCandidate({ ...newOpts[0].object }));
    } else {
      removeCandidateSelection && dispatch(notificationDataActions.associateCandidate(null));
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <Controller
      control={control}
      name="candidate"
      render={({ ref, onChange, ...rest }) => (
        <CustomTypeAhead
          id={'Candidate-Search'}
          onInputChange={throttleService}
          options={options}
          loading={promiseInProgress}
          searchOnCharacterCount={3}
          onChange={(event, newValue) => {
            newValue
              ? dispatch(notificationDataActions.associateCandidate({ ...newValue.object }))
              : autoPopulate(true);
            onChange(newValue);
          }}
          {...rest}
          showHistory={showHistory}
          historyTitle={t('placement.create.dropdown.recentCandidates')}
          resetHistory={() => setSessionOptions()}
          onClick={() => setCurrentFocusId('Candidate-Search')}
        />
      )}
    />
  );
};
