/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable i18next/no-literal-string */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { FormInputText } from './Controls/FormInputText';
import { useSelector } from 'react-redux';
import { candidateDetailsSelection } from '../../../Profile/CandidateDetails.selector';
import { FormInputDropdown } from './Controls/FormInputDropdown';
import { useFormContext } from 'react-hook-form';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CustomTooltip } from '@AMIEWEB/Common';
import { YesNoFieldOptions, YesNoValues, convertInvestigationAndRightToWork } from './PersonalInfoHelper';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: '0px 10px',
  },
  textColor: {
    color: '#0D6FB9',
    textAlign: 'left',
    font: 'normal normal medium 14px / 26px Roboto',
    fontWeight: 500,
    paddingTop: 8,
  },
  dobAdjust: {
    paddingBottom: '0px !important',
    paddingTop: '8px !important',
  },
  dobFieldLabel: {
    paddingTop: 4,
  },
  subHeader: {
    color: '#333',
    font: 'normal normal medium 16px / 26px Roboto',
    fontWeight: 500,
  },
  infoIcon: {
    color: '#34AADC',
    height: '20px',
    width: '20px',
  },
}));

interface ApplicationQuestionProps {
  isApplicationQuestionsEditable: boolean;
}

export const ApplicationQuestions = (props: ApplicationQuestionProps) => {
  const { isApplicationQuestionsEditable } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const candidateDetailsSel = useSelector(candidateDetailsSelection);
  const [invesitgationSuspension, setInvesitgationSuspension] = useState<string>('');
  const [rightToWork, setRightToWork] = useState<string>('');
  const { getValues, setValue, clearErrors } = useFormContext();

  useEffect(() => {
    const investigation = convertInvestigationAndRightToWork(
      candidateDetailsSel?.investigationSuspension,
      candidateDetailsSel?.investigationSuspensionExplanation,
    );
    const work = convertInvestigationAndRightToWork(
      candidateDetailsSel?.rightToWorkVerification,
      candidateDetailsSel?.visaExplaination,
    );
    setInvesitgationSuspension(investigation);
    setRightToWork(work);
  }, []);

  useEffect(() => {
    if (invesitgationSuspension === YesNoValues.MISSING) {
      clearErrors(['piExplanation']);
      setValue('piExplanation', '');
    } else {
      setValue('piExplanation', candidateDetailsSel?.investigationSuspensionExplanation);
    }
  }, [invesitgationSuspension]);

  useEffect(() => {
    if (rightToWork === YesNoValues.MISSING) {
      clearErrors(['piVisaType']);
      setValue('piVisaType', '');
    } else {
      setValue('piVisaType', candidateDetailsSel?.visaExplaination);
    }
  }, [rightToWork]);

  const handleChange = (event, name) => {
    switch (name) {
      case 'piInvestigationSuspension':
        setInvesitgationSuspension(event.target.value);
        setValue('piInvestigationSuspension', event.target.value);
        break;
      case 'piExplanation':
        setValue('piExplanation', event.target.value);
        break;
      case 'piRightToWork':
        setRightToWork(event.target.value);
        setValue('piRightToWork', event.target.value);
        break;
      case 'piVisaType':
        setValue('piVisaType', event.target.value);
        break;
      default:
        return;
    }
  };

  return (
    <LayoutGrid container direction="column" className={classes.root}>
      <LayoutGridItem item>
        <Typography className={classes.textColor} variant="subtitle1" gutterBottom component="div">
          {t('candidate.applicationQuestions.applicationQuestions')}
        </Typography>
      </LayoutGridItem>
      <LayoutGridItem item>
        <LayoutGrid container direction="column" data-testid="container" spacing={2} xs={12}>
          <LayoutGridItem xs={12}>
            <LayoutGrid>
              <LayoutGridItem>
                <Typography className={classes.subHeader} gutterBottom component="div">
                  {t('candidate.applicationQuestions.LicenseCertification')}
                </Typography>
              </LayoutGridItem>
              <LayoutGridItem>
                <CustomTooltip tooltipContent={t('candidate.applicationQuestions.LicenseCertificationTooltip')}>
                  <ErrorOutlineIcon className={classes.infoIcon} />
                </CustomTooltip>
              </LayoutGridItem>
            </LayoutGrid>
          </LayoutGridItem>
          <LayoutGridItem item xs={12}>
            <LayoutGrid>
              <LayoutGridItem xs={2}>
                <FormInputDropdown
                  name="piInvestigationSuspension"
                  inputValue={invesitgationSuspension}
                  label={''}
                  options={YesNoFieldOptions}
                  handleChange={event => handleChange(event, 'piInvestigationSuspension')}
                  disabled={!isApplicationQuestionsEditable}
                />
              </LayoutGridItem>
              <LayoutGridItem xs={8}>
                <FormInputText
                  name="piExplanation"
                  label={t('candidate.applicationQuestions.Explanation')}
                  inputValue={getValues('piExplanation') || candidateDetailsSel?.investigationSuspensionExplanation}
                  require={false}
                  handleChange={event => handleChange(event, 'piExplanation')}
                  readOnly={invesitgationSuspension === YesNoValues.MISSING || !isApplicationQuestionsEditable}
                  multiline
                  maxLengthError={1000}
                />
              </LayoutGridItem>
            </LayoutGrid>
          </LayoutGridItem>
          <LayoutGridItem xs={12}>
            <LayoutGrid style={{ paddingTop: '20px' }}>
              <LayoutGridItem>
                <Typography className={classes.subHeader} gutterBottom component="div">
                  {t('candidate.applicationQuestions.RightToWork')}
                </Typography>
              </LayoutGridItem>
              <LayoutGridItem>
                <CustomTooltip tooltipContent={t('candidate.applicationQuestions.RightToWorkTooltip')}>
                  <ErrorOutlineIcon className={classes.infoIcon} />
                </CustomTooltip>
              </LayoutGridItem>
            </LayoutGrid>
          </LayoutGridItem>
          <LayoutGridItem item xs={12}>
            <LayoutGrid>
              <LayoutGridItem xs={2}>
                <FormInputDropdown
                  name="piRightToWork"
                  inputValue={rightToWork}
                  label={''}
                  options={YesNoFieldOptions}
                  handleChange={event => handleChange(event, 'piRightToWork')}
                  disabled={!isApplicationQuestionsEditable}
                />
              </LayoutGridItem>
              <LayoutGridItem xs={8}>
                <FormInputText
                  name="piVisaType"
                  label={t('candidate.applicationQuestions.VisaType')}
                  inputValue={getValues('piVisaType') || candidateDetailsSel?.visaExplaination}
                  require={false}
                  handleChange={event => handleChange(event, 'piVisaType')}
                  readOnly={rightToWork === YesNoValues.MISSING || !isApplicationQuestionsEditable}
                  multiline
                  maxLengthError={1000}
                />
              </LayoutGridItem>
            </LayoutGrid>
          </LayoutGridItem>
        </LayoutGrid>
      </LayoutGridItem>
    </LayoutGrid>
  );
};
