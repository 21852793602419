import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  center: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
});

export const Callback: React.FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return <p className={classes.center}>{t('common.redirect')}</p>;
};
