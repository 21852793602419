import { IUserInfo } from 'oidc/user.redux';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';

export enum CandidateStatuses {
  Application = 'Application',
  Active = 'Active',
  ActiveWAudit = 'Active with Audit',
  DuplicateDelete = 'Duplicate Delete',
  Holding = 'Holding',
  Inactive = 'Inactive',
  InactiveWAudit = 'Inactive with Audit',
  IntlApplication = `Int'l Application`,
  Pending = 'Pending',
  PendingWAudit = 'Pending with Audit',
  TermHold = 'Term-Hold',
  Terminate = 'Terminate',
}

export const allCandidateStatuses = [
  { value: 5, name: 'Inactive' },
  { value: 13, name: 'Inactive w/Audit' },
  { value: 9, name: 'Application' },
  { value: 2, name: 'Active' },
  { value: 10, name: 'Active w/Audit' },
  { value: 16, name: 'Duplicate Delete' },
  { value: 3, name: 'Holding' },
  { value: 3, name: `Int'l Application` },
  { value: 1, name: 'Pending' },
  { value: 11, name: 'Pending w/Audit' },
  { value: 12, name: 'Term-Hold' },
  { value: 4, name: 'Terminate' },
];

export enum CandidateStatus {
  PENDING = 1,
  ACTIVE = 2,
  HOLDING = 3,
  TERMINATE = 4,
  INACTIVE = 5,
  APPLICATION = 9,
  ACTIVE_WITH_AUDIT = 10,
  PENDING_WITH_AUDIT = 11,
  TERM_HOLD = 12,
  INACTIVE_WITH_AUDIT = 13,
  DUPLICATE_DELETE = 16,
}

export const statusChangeReasons = [
  { value: 4, name: 'Attending School' },
  { value: 12, name: 'Additional Exp. Needed' },
  { value: 29, name: 'Brand Rationalization' },
  { value: 2, name: 'Career Change' },
  { value: 33, name: 'Covid Vaccination Compliance' },
  { value: 26, name: 'Deceased' },
  { value: 21, name: 'Global Opt Out' },
  { value: 30, name: `Immigration Issue` },
  { value: 31, name: 'Incomplete Application' },
  { value: 6, name: 'Lifestyle Change' },
  { value: 8, name: 'No Valid Contact Information' },
  { value: 0, name: 'Non-Compliance ' },
  { value: 34, name: 'Ongoing Performance Issue' },
  { value: 32, name: 'Ongoing Risk Issue' },
  { value: 10, name: 'Perm Job' },
  { value: 11, name: 'Unresponsive' },
];

export const getCandidateStatuses = (currentUserRoles: IUserInfo | undefined, candidateStatus: any) => {
  /////*** clinical_Manager, clinical_Director ****/////
  if (
    Authorized([userRoles.clinical_Manager, userRoles.clinical_Director], currentUserRoles) &&
    candidateStatus === CandidateStatuses.Application
  ) {
    return allCandidateStatuses.filter(
      x => x.name !== CandidateStatuses.DuplicateDelete && x.name !== CandidateStatuses.Terminate,
    );
  }

  if (
    Authorized([userRoles.clinical_Manager, userRoles.clinical_Director], currentUserRoles) &&
    candidateStatus === CandidateStatuses.IntlApplication
  ) {
    return allCandidateStatuses.filter(
      x =>
        x.name !== CandidateStatuses.Application &&
        x.name !== CandidateStatuses.DuplicateDelete &&
        x.name !== CandidateStatuses.Terminate,
    );
  }

  if (
    Authorized([userRoles.clinical_Manager, userRoles.clinical_Director], currentUserRoles) &&
    candidateStatus === CandidateStatuses.Pending
  ) {
    return allCandidateStatuses.filter(
      x =>
        x.name !== CandidateStatuses.Holding &&
        x.name !== CandidateStatuses.DuplicateDelete &&
        x.name !== CandidateStatuses.IntlApplication &&
        x.name !== CandidateStatuses.Application,
    );
  }
  if (
    Authorized([userRoles.clinical_Manager, userRoles.clinical_Director], currentUserRoles) &&
    candidateStatus === 'Pending w/Audit'
  ) {
    return allCandidateStatuses.filter(
      x =>
        x.name !== CandidateStatuses.Holding &&
        x.name !== CandidateStatuses.DuplicateDelete &&
        x.name !== CandidateStatuses.Pending &&
        x.name !== CandidateStatuses.IntlApplication &&
        x.name !== CandidateStatuses.Application,
    );
  }

  if (
    Authorized([userRoles.clinical_Manager, userRoles.clinical_Director], currentUserRoles) &&
    candidateStatus === CandidateStatuses.Active
  ) {
    return allCandidateStatuses.filter(
      x =>
        x.name !== CandidateStatuses.TermHold &&
        x.name !== CandidateStatuses.DuplicateDelete &&
        x.name !== CandidateStatuses.Pending &&
        x.name !== CandidateStatuses.IntlApplication &&
        x.name !== CandidateStatuses.Application,
    );
  }

  if (
    Authorized([userRoles.clinical_Manager, userRoles.clinical_Director], currentUserRoles) &&
    (candidateStatus === CandidateStatuses.ActiveWAudit || candidateStatus === 'Active w/Audit')
  ) {
    return allCandidateStatuses.filter(
      x =>
        x.name !== CandidateStatuses.TermHold &&
        x.name !== CandidateStatuses.DuplicateDelete &&
        x.name !== CandidateStatuses.Pending &&
        x.name !== CandidateStatuses.IntlApplication &&
        x.name !== CandidateStatuses.Application,
    );
  }
  if (
    Authorized([userRoles.clinical_Manager, userRoles.clinical_Director], currentUserRoles) &&
    candidateStatus === CandidateStatuses.Holding
  ) {
    return allCandidateStatuses.filter(
      x =>
        x.name === 'Pending w/Audit' ||
        x.name === CandidateStatuses.Active ||
        x.name === 'Active w/Audit' ||
        x.name === 'Inactive w/Audit' ||
        x.name === CandidateStatuses.Terminate,
    );
  }

  if (
    Authorized([userRoles.clinical_Manager, userRoles.clinical_Director], currentUserRoles) &&
    candidateStatus === 'Inactive w/Audit'
  ) {
    return allCandidateStatuses.filter(
      x =>
        x.name !== CandidateStatuses.TermHold &&
        x.name !== CandidateStatuses.DuplicateDelete &&
        x.name !== CandidateStatuses.Inactive &&
        x.name !== CandidateStatuses.Pending &&
        x.name !== CandidateStatuses.IntlApplication &&
        x.name !== CandidateStatuses.Application,
    );
  }

  if (
    Authorized([userRoles.clinical_Manager, userRoles.clinical_Director], currentUserRoles) &&
    candidateStatus === CandidateStatuses.Inactive
  ) {
    return allCandidateStatuses.filter(
      x =>
        x.name !== CandidateStatuses.TermHold &&
        x.name !== CandidateStatuses.DuplicateDelete &&
        x.name !== CandidateStatuses.Pending &&
        x.name !== CandidateStatuses.IntlApplication &&
        x.name !== CandidateStatuses.Application,
    );
  }

  /////*** END  clinical_Manager, clinical_Director ****/////

  /////*** clinical_QualificationsSpecialist ****/////
  if (
    Authorized([userRoles.clinical_QualificationsSpecialist], currentUserRoles) &&
    (candidateStatus === CandidateStatuses.Application ||
      candidateStatus === CandidateStatuses.IntlApplication ||
      candidateStatus === CandidateStatuses.Pending ||
      candidateStatus === CandidateStatuses.PendingWAudit ||
      candidateStatus === 'Pending w/Audit' ||
      candidateStatus === CandidateStatuses.Active ||
      candidateStatus === CandidateStatuses.ActiveWAudit ||
      candidateStatus === 'Active w/Audit' ||
      candidateStatus === CandidateStatuses.Inactive ||
      candidateStatus === CandidateStatuses.InactiveWAudit ||
      candidateStatus === 'Inactive w/Audit' ||
      candidateStatus === CandidateStatuses.Holding)
  ) {
    return allCandidateStatuses.filter(x => x.name === CandidateStatuses.DuplicateDelete);
  }

  if (
    Authorized([userRoles.clinical_QualificationsSpecialist], currentUserRoles) &&
    candidateStatus === CandidateStatuses.DuplicateDelete
  ) {
    return allCandidateStatuses.filter(x => x.name === 'Pending w/Audit');
  }

  /////*** END clinical_QualificationsSpecialist ****/////

  /////*** recruitment_Leadership, recruitment_TeamMember  ****/////
  if (
    Authorized([userRoles.recruitment_Leadership, userRoles.recruitment_TeamMember], currentUserRoles) &&
    (candidateStatus === CandidateStatuses.Active ||
      candidateStatus === CandidateStatuses.ActiveWAudit ||
      candidateStatus === 'Active w/Audit')
  ) {
    return allCandidateStatuses.filter(x => x.name === CandidateStatuses.Inactive || x.name === 'Inactive w/Audit');
  }

  if (
    Authorized([userRoles.recruitment_Leadership, userRoles.recruitment_TeamMember], currentUserRoles) &&
    candidateStatus === CandidateStatuses.IntlApplication
  ) {
    return allCandidateStatuses.filter(
      x =>
        x.name === CandidateStatuses.Inactive ||
        x.name === 'Inactive w/Audit' ||
        x.name === CandidateStatuses.Application,
    );
  }

  if (
    Authorized([userRoles.recruitment_Leadership], currentUserRoles) &&
    (candidateStatus === CandidateStatuses.Pending || candidateStatus === 'Pending w/Audit')
  ) {
    return allCandidateStatuses.filter(
      x =>
        x.name === CandidateStatuses.Inactive ||
        x.name === 'Inactive w/Audit' ||
        x.name === CandidateStatuses.Active ||
        x.name === 'Active w/Audit',
    );
  }

  if (
    Authorized([userRoles.recruitment_TeamMember], currentUserRoles) &&
    (candidateStatus === CandidateStatuses.Pending || candidateStatus === 'Pending w/Audit')
  ) {
    return allCandidateStatuses.filter(x => x.name === CandidateStatuses.Inactive || x.name === 'Inactive w/Audit');
  }

  if (
    Authorized([userRoles.recruitment_Leadership], currentUserRoles) &&
    (candidateStatus === CandidateStatuses.Inactive || candidateStatus === 'Inactive w/Audit')
  ) {
    return allCandidateStatuses.filter(x => x.name === CandidateStatuses.Active || x.name === 'Active w/Audit' || x.name === CandidateStatuses.Application);
  }

  if (
    Authorized([userRoles.recruitment_TeamMember], currentUserRoles) &&
    (candidateStatus === CandidateStatuses.Inactive || candidateStatus === 'Inactive w/Audit')
  ) {
    return allCandidateStatuses.filter(x => x.name === CandidateStatuses.Application);
  }

  /////*** END recruitment_Leadership, recruitment_TeamMember ****/////

  /////*** clinical_ReviewAnalyst, clinical_Manager ****/////

  if (
    Authorized([userRoles.clinical_ReviewAnalyst, userRoles.clinical_Manager], currentUserRoles) &&
    candidateStatus === CandidateStatuses.TermHold
  ) {
    return allCandidateStatuses.filter(
      x =>
        x.name !== CandidateStatuses.DuplicateDelete &&
        x.name !== CandidateStatuses.Application &&
        x.name !== CandidateStatuses.Pending &&
        x.name !== CandidateStatuses.IntlApplication &&
        x.name !== CandidateStatuses.Application,
    );
  }
  if (
    Authorized([userRoles.clinical_ReviewAnalyst, userRoles.clinical_Manager], currentUserRoles) &&
    candidateStatus === CandidateStatuses.Terminate
  ) {
    return allCandidateStatuses.filter(
      x =>
        x.name !== CandidateStatuses.Holding &&
        x.name !== CandidateStatuses.DuplicateDelete &&
        x.name !== CandidateStatuses.TermHold &&
        x.name !== CandidateStatuses.Application &&
        x.name !== CandidateStatuses.IntlApplication &&
        x.name !== CandidateStatuses.Pending &&
        x.name !== CandidateStatuses.Application,
    );
  }
  /////*** END clinical_ReviewAnalyst, clinical_Manager ****/////

  /////*** clinical_ReviewAnalyst ****/////

  if (
    Authorized([userRoles.clinical_ReviewAnalyst], currentUserRoles) &&
    candidateStatus === CandidateStatuses.Application
  ) {
    return allCandidateStatuses.filter(x => x.name !== CandidateStatuses.Terminate);
  }

  if (
    Authorized([userRoles.clinical_ReviewAnalyst], currentUserRoles) &&
    candidateStatus === CandidateStatuses.IntlApplication
  ) {
    return allCandidateStatuses.filter(
      x => x.name !== CandidateStatuses.Application && x.name !== CandidateStatuses.Terminate,
    );
  }

  if (
    Authorized([userRoles.clinical_ReviewAnalyst], currentUserRoles) &&
    candidateStatus === CandidateStatuses.Pending
  ) {
    return allCandidateStatuses.filter(
      x =>
        x.name !== CandidateStatuses.Holding &&
        x.name !== CandidateStatuses.IntlApplication &&
        x.name !== CandidateStatuses.Application,
    );
  }

  if (Authorized([userRoles.clinical_ReviewAnalyst], currentUserRoles) && candidateStatus === 'Pending w/Audit') {
    return allCandidateStatuses.filter(
      x =>
        x.name !== CandidateStatuses.Pending &&
        x.name !== CandidateStatuses.Holding &&
        x.name !== CandidateStatuses.IntlApplication &&
        x.name !== CandidateStatuses.Application,
    );
  }

  if (
    Authorized([userRoles.clinical_ReviewAnalyst], currentUserRoles) &&
    (candidateStatus === CandidateStatuses.Active ||
      candidateStatus === 'Active w/Audit' ||
      candidateStatus === CandidateStatuses.Inactive)
  ) {
    return allCandidateStatuses.filter(
      x =>
        x.name !== CandidateStatuses.Pending &&
        x.name !== CandidateStatuses.TermHold &&
        x.name !== CandidateStatuses.IntlApplication &&
        x.name !== CandidateStatuses.Application,
    );
  }

  if (
    Authorized([userRoles.clinical_ReviewAnalyst], currentUserRoles) &&
    (candidateStatus === CandidateStatuses.Holding || candidateStatus === 'Inactive w/Audit')
  ) {
    return allCandidateStatuses.filter(
      x =>
        x.name !== CandidateStatuses.Inactive &&
        x.name !== CandidateStatuses.Pending &&
        x.name !== CandidateStatuses.TermHold &&
        x.name !== CandidateStatuses.IntlApplication &&
        x.name !== CandidateStatuses.Application,
    );
  }

  if (
    Authorized([userRoles.clinical_ReviewAnalyst], currentUserRoles) &&
    candidateStatus === CandidateStatuses.DuplicateDelete
  ) {
    return allCandidateStatuses.filter(
      x => x.name === CandidateStatuses.Active || x.name === 'Pending w/Audit' || x.name === 'Active w/Audit',
    );
  }

  /////*** END clinical_ReviewAnalyst ****/////

  /////*** clinical_QualificationsSpecialist ****/////

  if (
    Authorized([userRoles.clinical_QualificationsSpecialist], currentUserRoles) &&
    (candidateStatus === CandidateStatuses.Active ||
      candidateStatus === CandidateStatuses.IntlApplication ||
      candidateStatus === CandidateStatuses.Pending ||
      candidateStatus === CandidateStatuses.Active ||
      candidateStatus === 'Active w/Audit' ||
      candidateStatus === 'Pending w/Audit' ||
      candidateStatus === CandidateStatuses.Inactive ||
      candidateStatus === 'Inactive w/Audit' ||
      candidateStatus === CandidateStatuses.Holding)
  ) {
    return allCandidateStatuses.filter(x => x.name === CandidateStatuses.DuplicateDelete);
  }
  if (
    Authorized([userRoles.clinical_QualificationsSpecialist], currentUserRoles) &&
    candidateStatus === CandidateStatuses.DuplicateDelete
  ) {
    return allCandidateStatuses.filter(x => x.name === 'Pending w/Audit');
  }
  /////*** END clinical_QualificationsSpecialist ****/////

  /////*** start Risk Management  and Workers Comp  ****/////
  if (
    Authorized([userRoles.riskManagement, userRoles.workersCompCoordinator], currentUserRoles) &&
    (candidateStatus === CandidateStatuses.Application || candidateStatus === CandidateStatuses.IntlApplication
      || candidateStatus === CandidateStatuses.Pending || candidateStatus === 'Pending w/Audit')
  ) {
    return allCandidateStatuses.filter(
      x =>
        x.name === CandidateStatuses.Inactive ||
        x.name === 'Inactive w/Audit' ||
        x.name === CandidateStatuses.Active ||
        x.name === 'Active w/Audit',
    );
  }

  if (
    Authorized([userRoles.riskManagement, userRoles.workersCompCoordinator], currentUserRoles) &&
    (candidateStatus === CandidateStatuses.Active)) {
    return allCandidateStatuses.filter(
      x =>
        x.name === CandidateStatuses.Inactive ||
        x.name === 'Inactive w/Audit' ||
        x.name === 'Active w/Audit',
    );
  }

  if (
    Authorized([userRoles.riskManagement, userRoles.workersCompCoordinator], currentUserRoles) &&
    (candidateStatus === 'Active w/Audit')) {
    return allCandidateStatuses.filter(
      x =>
        x.name === CandidateStatuses.Active ||
        x.name === 'Inactive w/Audit' ||
        x.name === CandidateStatuses.Inactive ,
    );
  }

  if (
    Authorized([userRoles.riskManagement, userRoles.workersCompCoordinator], currentUserRoles) &&
    (candidateStatus === CandidateStatuses.Inactive)) {
    return allCandidateStatuses.filter(
      x =>
      x.name === CandidateStatuses.Active ||
      x.name === 'Inactive w/Audit' ||
      x.name === 'Active w/Audit' ,
    );
  }

  if (
    Authorized([userRoles.riskManagement, userRoles.workersCompCoordinator], currentUserRoles) &&
    (candidateStatus === 'Inactive w/Audit')) {
    return allCandidateStatuses.filter(
      x =>
        x.name === CandidateStatuses.Active ||        
        x.name === 'Active w/Audit' ,
    );
  }

  if (
    Authorized([userRoles.riskManagement, userRoles.workersCompCoordinator], currentUserRoles) &&
    (candidateStatus === CandidateStatuses.Holding)) {
    return allCandidateStatuses.filter(
      x =>
        x.name === CandidateStatuses.Active ||
        x.name === 'Inactive w/Audit' ||
        x.name === 'Active w/Audit' ,
    );
  }
  /////*** END Risk Management  and Workers Comp  ****/////
};
