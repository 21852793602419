/* eslint-disable i18next/no-literal-string */
import { ICustomHeader, gridControlType } from 'app/models/Grid';
import NoteIcon from 'app/assets/images/Notes.png';
import HistoryIcon from '@mui/icons-material/History';
import { CredentialingLookup } from 'app/models/Credentialing/CredentialingLookups';
import {
  getBoolean,
  getBooleanNumber,
  getDate,
  getHoverViewData,
  getDateNoFormat,
  getText,
  getBooleanSort,
  getCredentialName,
  getDateText,
  getCredentialNameNoFormat,
  getStateFilterOptions,
  getStateValue,
  detailColumnsAndRows,
  getStateDescription,
  getMetadataForReqID,
  getCredentialNameBold,
} from './tableHelpers';
import { convertNumberToBoolean } from 'app/helpers/converters';
import { ICredentials } from 'app/models/Credentialing/Credentials';
import React from 'react';
import { CredentialAttachmentToolTip, AttachmentAction } from '../Attachments/AttachmentHoverView';
import { groupBy, nestedChildren } from 'app/helpers/arrayHelpers';
import VersionDetailsHoverView from './VersionDetailsHoverView';
import { childSorting } from './HumanResources/HumanResourcesGroupingHelper';

const getMainTableColumns = (
  sortable: boolean = true,
  viewingDuplicates: boolean = false,
  historical: boolean = false,
) => {
  const headers: ICustomHeader[] = [
    {
      title: 'credentialName',
      value: 'Credential Name',
      sortable: sortable,
      sortByField: 'credentialNameNoFormat',
    },
    {
      title: 'expirationDate',
      value: 'Expiration Date',
      sortable: sortable,
      defaultSort: !viewingDuplicates,
      sortByField: 'expirationDateNoFormat',
      control: {
        type: gridControlType.date,
      },
    },
    {
      title: 'verifiedID',
      value: 'Verified',
      sortable: sortable,
      sortByField: 'verifiedIDControl',
      hasHoverView: true,
      control: {
        type: gridControlType.checkbox,
        color: 'green',
      },
    },

    {
      title: 'stateID',
      value: 'State',
      sortByField: 'stateIDDescription',
      sortable: sortable,
      control: {
        type: gridControlType.dropDown,
      },
    },

    {
      title: 'licenseNumber',
      value: 'License #',
      sortable: sortable,
      control: {
        type: gridControlType.textfield,
      },
    },
    {
      title: 'levelID',
      value: 'Compact',
      hasHoverView: true,
      sortable: sortable,
      sortByField: 'levelIDControl',
      control: {
        type: gridControlType.checkbox,
        color: 'green',
      },
    },
    {
      title: 'lastUpdate',
      value: 'Updated Date',
      sortable: sortable,
      defaultSort: viewingDuplicates,
      sortByField: 'lastUpdateNoFormat',
      control: {
        type: gridControlType.label,
      },
    },
    {
      title: 'paperlessBy',
      value: '',
      hidden: true,
      control: {
        type: gridControlType.nameID,
      },
    },
    {
      title: 'onFileByID',
      value: '',
      hidden: true,
      control: {
        type: gridControlType.nameID,
      },
    },
    {
      title: 'verifiedByID',
      value: '',
      hidden: true,
      control: {
        type: gridControlType.nameID,
      },
    },
    {
      title: 'versioning',
      value: 'Versions',
      icon: true,
      versioning: !historical,
    },
    {
      title: 'documentIcon',
      value: 'Document',
      //clickable: true,
      navigationIds: [''],
      navigationExp: '/',
      hasHoverView: true,
      customHoverView: true,
    },
  ];
  return headers;
};

export const groupedLicensure = list => {
  return groupBy<keyof ICredentials>(
    list,
    ['credentialName', 'stateID', 'licenseNumber'],
    [
      { item: 'expirationDate', type: 'shortDate' },
      { item: 'dateCompleted', type: 'shortDate' },
      { item: 'lastUpdate', type: 'longDate' },
    ],
  );
};

const flattenData = (
  licenses: ICredentials[],
  lookups: CredentialingLookup[],
  viewingDuplicates = false,
  onAttachmentAction: (
    action: AttachmentAction,
    reqID: number,
    requirementTypeID: number,
    travelerId: number,
    brandId: number,
  ) => void,
  level: number = 0,
  expandable: boolean = true,
  setClearChildMessages,
  setShowAttachmentPreview,
) => {
  let nestedItems = licenses;
  if (level === 0) {
    const grouped = groupedLicensure(licenses);
    nestedItems = nestedChildren(grouped);
  }
  if (level > 0) {
    nestedItems = childSorting(nestedItems, lookups);
  }
  const credentials: any[] = [];
  nestedItems?.forEach(item => {
    const fieldLookups = getMetadataForReqID(lookups, item);
    const l: any = {
      rowID: item.requirementID,
      credentialName:
        level === 0 ? (
          getCredentialName(item.requirementType, item.credentialName)
        ) : (
          <VersionDetailsHoverView
            credential={[
              ...detailColumnsAndRows(
                item,
                fieldLookups,
                ['StateID', 'ExpirationDate', 'VerifiedID', 'LevelID', 'LicenseNumber', 'LastUpdate'],
                true,
              ),
            ]}
            fieldLookups={fieldLookups}
            title={getCredentialName(item.requirementType, item.credentialName)}
            titleBold={getCredentialNameBold(item.requirementType, item.credentialName)}
          />
        ),
      credentialNameNoFormat: getCredentialNameNoFormat(item.requirementType, item.credentialName),
      stateID: getStateValue(fieldLookups, 'StateID', item.stateID),
      stateIDFilterOptions: getStateFilterOptions(fieldLookups, 'StateID'),
      stateIDDescription: getStateDescription(fieldLookups, 'StateID', item.stateID),
      expirationDate: getDate(fieldLookups, 'ExpirationDate', item.expirationDate),
      expirationDateNoFormat: getDateNoFormat(fieldLookups, 'ExpirationDate', item.expirationDate),
      verifiedID: getBooleanNumber(fieldLookups, 'VerifiedID', item.verifiedID),
      verifiedIDHoverViewData: getHoverViewData(fieldLookups, 'VerifiedID', convertNumberToBoolean(item.verifiedID), [
        convertNumberToBoolean(item.verifiedID)
          ? 'This credential has been verified'
          : 'This credential has not been verified',
      ]),
      verifiedIDControl: getBooleanSort(fieldLookups, 'VerifiedID', convertNumberToBoolean(item.verifiedID)),
      levelID: getBoolean(fieldLookups, 'LevelID', convertNumberToBoolean(item.levelID)),
      levelIDHoverViewData: getHoverViewData(fieldLookups, 'LevelID', convertNumberToBoolean(item.levelID), [
        convertNumberToBoolean(item.levelID)
          ? 'Compact is available for this credential'
          : 'Compact is not available for this credential',
      ]),
      levelIDControl: getBooleanSort(fieldLookups, 'LevelID', convertNumberToBoolean(item.levelID)),
      licenseNumber: getText(fieldLookups, 'LicenseNumber', item.licenseNumber),
      lastUpdate: getDateText(fieldLookups, 'LastUpdate', item.lastUpdate),
      lastUpdateNoFormat: getDateNoFormat(fieldLookups, 'LastUpdate', item.lastUpdate),
      paperlessBy: item.paperlessBy,
      onFileByID: item.onFileByID,
      versioning: level === 0 ? <HistoryIcon /> : null,
      verifiedByID: item.verifiedByID,
      documentIcon: NoteIcon,
      expandable: expandable,
      detailRows: [
        [
          ...detailColumnsAndRows(item, fieldLookups, [
            'StateID',
            'ExpirationDate',
            'VerifiedID',
            'LevelID',
            'LicenseNumber',
            'LastUpdate',
          ]),
        ],
      ],
      documentIconHoverViewComponent: (
        <CredentialAttachmentToolTip
          onSelect={onAttachmentAction}
          requirementID={item.requirementID}
          requirementTypeID={item.requirementTypeID}
          travelerId={0}
          brandId={0}
        />
      ),
      versionRows:
        level === 0 && item['children'] !== undefined
          ? flattenData(
              item['children'],
              lookups,
              false,
              onAttachmentAction,
              level + 1,
              false,
              setClearChildMessages,
              setShowAttachmentPreview,
            )
          : null,
      versionColumns: getMainTableColumns(true, false, true),
    };
    credentials.push(l);
  });
  if (level === 0) {
    return credentials.sort(
      viewingDuplicates
        ? (a: any, b: any) => (a.lastUpdateNoFormat < b.lastUpdateNoFormat ? 1 : -1)
        : (a: any, b: any) => (a.expirationDateNoFormat > b.expirationDateNoFormat ? 1 : -1),
    );
  } else return credentials;
};

export const licensureData = {
  getTableColumns: getMainTableColumns,
  getTableRows: flattenData,
};
