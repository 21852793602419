import React from 'react';
import UnitDetailCard from '../../../Common/UnitDetailCard';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import { useTranslation } from 'react-i18next';
import { Grid } from 'amn-ui-core';
import OrderPriorities from './OrderPriorities';
import { ReferencesContainer } from './References';
import VirtualRatings from './VirtualRatings';
import BestBets from './BestBets';
import ClinicianToPatientRatio from './ClinicianToPatientRatio';
import WorkHistoryGap from './WorkHistoryGap';

export const AdditionalInformationSection = ({
  virtualInterviewRatingRef,
  orderPrioritiesRef,
  referencesRef,
  workHistoryGapRef,
  clinicianPatientRatioRef,
  bestBetsRef,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container direction="row" columnSpacing={2}>
        <Grid item lg={6} ref={virtualInterviewRatingRef}>
          <UnitDetailCard
            icon={<DescriptionOutlinedIcon />}
            title={t('facility.units.unitDetails.additionalInformation.virtualInterviewRating')}
            children={<VirtualRatings />}
          />
        </Grid>
        <Grid item lg={6} ref={orderPrioritiesRef}>
          <UnitDetailCard
            icon={<DescriptionOutlinedIcon />}
            title={t('facility.units.unitDetails.additionalInformation.orderPriorities')}
            children={<OrderPriorities />}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" columnSpacing={2}>
        <Grid item lg={6} ref={clinicianPatientRatioRef}>
          <UnitDetailCard
            title={t('facility.units.clinicianToPatient.title')}
            children={<ClinicianToPatientRatio nameValuePairs={null} />}
            minHeight="225px"
          />
        </Grid>
        <Grid item lg={6} ref={referencesRef}>
          <UnitDetailCard
            icon={<FolderSharedOutlinedIcon />}
            title={t('facility.units.unitDetails.additionalInformation.references.title')}
            children={<ReferencesContainer />}
            minHeight="225px"
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={2}>
        <Grid item lg={6} ref={workHistoryGapRef}>
          <UnitDetailCard
            title={t('facility.units.unitDetails.additionalInformation.workHistoryGap.title')}
            children={<WorkHistoryGap />}
            minHeight="205px"
          />
        </Grid>
        <Grid item lg={6} ref={bestBetsRef}>
          <UnitDetailCard
            icon={<DescriptionOutlinedIcon />}
            title={t('facility.atAGlance.bestBets')}
            children={<BestBets />}
            minHeight="205px"
          />
        </Grid>
      </Grid>
    </>
  );
};
