import { Grid, Divider, Typography, FormControl } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomDateRangePicker, CustomSelect, CustomCalendar } from '.';
import { FilterCompTypes } from './utils';

const useStyles = makeStyles()({
  title: {
    color: '#333333',
    fontSize: 14,
    fontWeight: 500,
    paddingBottom: 10,
  },
  filterName: {
    color: '#707070',
    paddingBottom: 4,
  },
  selectControl: {
    display: 'flex',
  },
  selectFieldStyle: {
    minWidth: '-webkit-fill-available',
  },
  or: {
    fontSize: 12,
    color: '#C0C0C0',
    marginTop: -8,
    marginBottom: 8,
    paddingLeft: 8,
  },
});
export const OrFilter = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const onSelectFieldChange = selectChoice => {
    const newValue = props.onSelectFieldChange(selectChoice);
    props.type === FilterCompTypes.CALENDAR
      ? props.onChange({ date: newValue, selectChoice })
      : props.onChange({ ...newValue, selectChoice });
  };

  const onDateValueChange = newValue =>
    props.type === FilterCompTypes.CALENDAR
      ? props.onChange({ date: newValue, selectChoice: null })
      : props.onChange({ ...newValue, selectChoice: null });

  return (
    <Grid container direction={props.orientaion || 'column'}>
      <Grid item classes={{ root: classes.title }}>
        {props.filterName}
      </Grid>
      <Grid item>
        <Typography variant="body2" className={classes.filterName}>
          {props.type === FilterCompTypes.CALENDAR
            ? t('search.filter.orFilter.date')
            : t('search.filter.orFilter.dateRange')}
        </Typography>
      </Grid>
      {props.type === FilterCompTypes.CALENDAR && (
        <Grid item xs={12}>
          <CustomCalendar value={props.value?.date || null} onChange={onDateValueChange} fullwidth />
        </Grid>
      )}
      {props.type === FilterCompTypes.DATERANGE && (
        <Grid item>
          <CustomDateRangePicker
            value={props.value && props.value.startDate ? { ...props.value } : null}
            onChange={onDateValueChange}
            allowHelperText
          />
        </Grid>
      )}
      <Grid item container direction="row">
        <Grid item xs={5}>
          <Divider />
        </Grid>
        <Grid item xs={2} className={classes.or}>
          {`(${t('search.filter.orFilter.or')})`}
        </Grid>
        <Grid item xs={5}>
          <Divider />
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="body2" className={classes.filterName}>
          {props.selectLabel || t('search.filter.orFilter.days')}
        </Typography>
      </Grid>
      <Grid item>
        <FormControl classes={{ root: classes.selectControl }}>
          <CustomSelect
            options={props.options}
            value={props.value?.selectChoice || null}
            onChange={onSelectFieldChange}
            selectFieldStyle={classes.selectFieldStyle}
            placeholder={t('search.filter.orFilter.selectPlaceholder')}
            version0
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
