import { Grid } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCreateOrderAttributes } from 'store/redux-store/create-order-ocbr/selector';
import { makeStyles } from 'tss-react/mui';
import { deriveFieldName } from '../helper';
import { BillRateField } from './BillRateRowItem';
import { LocalBillRateField } from './LocalBillRateField';

const useStyles = makeStyles()(theme => ({
  billRateContainer: {
    padding: '6px 6px 0px 6px',
  },
  fadeOut: {
    opacity: 0,
    width: 0,
    height: 0,
    transition: 'width 0.5s linear, height 0.5s linear, opacity 0.5s',
    pointerEvents: 'none',
  },
  fadeIn: {
    opacity: 1,
    width: '100px',
    height: '100px',
    transition: 'width 0.5s, height 0.5s, opacity 0.5s linear',
    marginBottom: '-28px',
  },
  container: {
    paddingBottom: '5px',
  },
}));
export const deriveKeyFromSkill = (skillSet, ...args) => {
  return deriveFieldName([skillSet.disciplineAbbreviation, skillSet.specialtyName, skillSet.subSpecialtyName, ...args]);
};

export const BillRateShift = ({ shifDetails, skillsetShiftInfo, shiftName }) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const { showLocalBillRate } = useSelector(selectCreateOrderAttributes);

  return (
    <>
      <Grid container direction="column" className={classes.container}>
        <Grid item classes={{ root: classes.billRateContainer }}>
          <BillRateField
            label={t('order.newOrder.rateCard.travelBillRate')}
            name={deriveKeyFromSkill(skillsetShiftInfo, 'travel', shifDetails.shiftName)}
            shiftRates={shifDetails.shiftRatesArray}
            shiftName={shiftName}
          />
        </Grid>
        <Grid
          item
          classes={{ item: cx(classes.billRateContainer, showLocalBillRate ? classes.fadeIn : classes.fadeOut) }}
        >
          <LocalBillRateField
            label={t('order.newOrder.rateCard.localBillRate')}
            name={deriveKeyFromSkill(skillsetShiftInfo, 'local', shifDetails.shiftName)}
            shiftRates={shifDetails.localBillRateOptions}
          />
        </Grid>
      </Grid>
    </>
  );
};
