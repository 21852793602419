import React from 'react';
import { Chip, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';
const useStyles = makeStyles<{ maxHeight: number }>()((theme, { maxHeight }) => ({
  root: {
    maxHeight: maxHeight,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  collapse: {
    overflowY: 'hidden',
  },
 
  deselected: {
    opacity: 0.5,
  },
 
}));

export interface IChip {
  label: string;
  value?: any;
  enableDelete?: boolean;
  isSelected?: boolean;
}

const SelectionChip = ({ chip, classes, variant, groupValues, ...props }) =>
  chip.isSelected ? (
    <Chip
      variant={chip.isSelected?'filled' :props.variant || 'outlined'}
      color={chip.isSelected?'secondary':props.color}
      label={chip.label}
      
      clickable
      deleteIcon={<CloseIcon />}
      {...props}
    />
  ) : (
    <Chip
      variant={ chip.isSelected?'filled' :props.variant || 'outlined'}
      color={chip.isSelected?'secondary':props.color}
      label={chip.label}
      classes={{
        root: groupValues.some(item => item.isSelected) ? classes.deselected : undefined,
      }}
      clickable
      deleteIcon={<CloseIcon />}
      {...props}
    />
  );

export const ChipsContainer = props => {
  const { isDisabled, maxHeight = 124 } = props;
  const { classes } = useStyles({ maxHeight });
  const chips = Object.entries(props.chips) as any[][];

  return (
    <Grid
      container
      spacing={1}
      classes={{ root: props.isCollapse ? classes.collapse : classes.root }}
      aria-label="chips-container"
    >
      {chips.map((chipGroup, grpIndex) =>
        Array.isArray(chipGroup[1]) ? (
          chipGroup[1].map((chip, index) => (
            <Grid item key={`${grpIndex} - ${index}`}>
              {chip.enableDelete ? (
                <SelectionChip
                  variant={props.variant}
                  chip={chip}
                  groupValues={chipGroup[1]}
                  classes={classes}
                  onClick={() => props.onClick(chipGroup[0], chip, index)}
                  onDelete={() => props.onDelete(chipGroup[0], chip, index)}
                />
              ) : (
                <SelectionChip
                  variant={props.variant}
                  chip={chip}
                  groupValues={chipGroup[1]}
                  classes={classes}
                  clickable={!!props.onClick}
                  onClick={() => props.onClick && props.onClick(chipGroup[0], chip, index)}
                />
              )}
            </Grid>
          ))
        ) : chipGroup[1] ? (
          <Grid item key={`${grpIndex}`}>
            {chipGroup[1].enableDelete ? (
              <Chip
                disabled={isDisabled}
                variant={chipGroup[1].isSelected?'filled':props.variant || 'outlined'}
                color={chipGroup[1].isSelected?'secondary':props.color}
                label={chipGroup[1].label}
               
                clickable
                onClick={() => props.onClick(chipGroup[0], chipGroup[1])}
                onDelete={() => props.onDelete(chipGroup[0], chipGroup[1])}
                deleteIcon={<CloseIcon />}
              />
            ) : (
              <Chip
                disabled={isDisabled}
                variant={chipGroup[1].isSelected ? 'filled' : props.variant || 'outlined'}
                color={chipGroup[1].isSelected?'secondary':props.color}
                label={chipGroup[1].label}
                clickable={!!props.onClick}
                onClick={() => props.onClick && props.onClick(chipGroup[0])}
              />
            )}
          </Grid>
        ) : null,
      )}
    </Grid>
  );
};
