import { useCallback, useEffect, useRef } from 'react';

export function useUpdateEffect(effect, dependencies = []) {
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      effect();
    }
  }, [effect]);
}


//prevent single click when double clicking on a element
export const useDebouncedClick =(callback, delay = 300) => {
  const timerRef = useRef(null);

  const handleClick = useCallback(
    (event) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
        event.stopPropagation();
      } else {
        timerRef.current = setTimeout(() => {
          timerRef.current = null;
          callback(event);
        }, delay);
      }
    },
    [callback, delay]
  );

  return handleClick;
}