import { ClickAwayListener, TextField, Typography, Skeleton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { missingField } from 'app/constants';

const useStyles = makeStyles()({
  textareastyle: {
    backgroundColor: '#fff !important',
  },
  root: {
    height: '20px',
    width: '110px',
  },
  clickable: {
    cursor: 'pointer',
  },
});
export interface SSNProps {
  id: string;
  ssn?: string;
  mask?: ssnMaskType;
  onEndEdit?: (ssn: string) => void;
  onChange?: (ssn: string) => void;
  readOnly?: boolean;
  error?: boolean;
  helperText?: string;
  variant?: 'filled' | 'outlined' | 'standard';
  size?: 'small' | 'medium';
  label?: string;
  hiddenLabel?: boolean;
  fullWidth?: boolean;
  keepTextFieldOnReadOnly?: boolean;
}

/**
 * full - mask entire SSN
 * partial - mask all but last 4 of SSN
 * nonePartial - mask all but last 4 of SSN, when clicking on SSN, show complete SSN
 * none - don't mask the SSN
 */
export type ssnMaskType = 'full' | 'partial' | 'nonePartial' | 'none';

export const SSNTextField = ({
  id,
  ssn = '',
  mask = 'full',
  readOnly,
  error,
  helperText,
  onChange,
  variant,
  size = 'small',
  hiddenLabel = true,
  keepTextFieldOnReadOnly,
  ...props
}: SSNProps) => {
  const partiallyHidden = '*** ** ';
  const completelyHidden = '*** ** ****';
  const { classes, cx } = useStyles();
  const [_ssn, _setSSN] = React.useState<any>(completelyHidden);
  const [editing, setEditing] = React.useState<boolean>(false);
  const textFieldRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>();
  const [viewFullSSN, setViewFullSSN] = React.useState<boolean>(false);
  const { promiseInProgress } = usePromiseTracker({ area: 'candidate-ssn', delay: 0 });

  interface IMaskSSN {
    maskOverride?: ssnMaskType;
    newSSN?: string;
  }

  const maskSSN = React.useCallback(
    (props?: IMaskSSN) => {
      const formattedSSN = (props?.newSSN || ssn)?.replace(/[^0-9]/g, '') || '';
      if((readOnly && !keepTextFieldOnReadOnly) && ((props?.newSSN || ssn) === undefined || (props?.newSSN || ssn) === null || (props?.newSSN || ssn) === '') && (!props?.maskOverride && (mask === 'full' || mask === 'partial' || mask === 'nonePartial'))){
        _setSSN(missingField);
        return;
      }
      else if((!readOnly) && ((props?.newSSN || ssn) === undefined || (props?.newSSN || ssn) === null || (props?.newSSN || ssn) === '')){
        _setSSN('');
        return;
      }
      switch (props?.maskOverride || mask) {
        case 'full':
          _setSSN(completelyHidden);
          break;
        case 'partial':
        case 'nonePartial':
          if (formattedSSN.length > 5) {
            _setSSN(`${partiallyHidden}${formattedSSN.toString().slice(5, 9)}`);
          } else {
            _setSSN(completelyHidden);
          }
          break;
        default:
          setValForOnTextChange(formattedSSN, 0);
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mask, ssn],
  );

  const endSSNEdit = React.useCallback(() => {
    if (editing) {
      maskSSN({ newSSN: _ssn });
      props.onEndEdit?.(_ssn);
      setEditing(false);
    }
  }, [_ssn, editing, maskSSN, props.onEndEdit]);

  const stopViewingSSN = () => {
    maskSSN();
    setViewFullSSN(false);
  };

  const startSSNEdit = event => {
    if (!editing) {
      maskSSN({ maskOverride: 'none', newSSN: ssn });
      setEditing(true);
    }
  };

  const pageKeyDownHandler = React.useCallback(
    event => {
      if (event.keyCode === 13 && document.getElementById(id) === document.activeElement) {
        event.preventDefault();
        event.stopPropagation();
        endSSNEdit();
        document.getElementById(id)?.blur();
      }
    },
    [endSSNEdit, id],
  );

  React.useEffect(() => {
    window.addEventListener('keydown', pageKeyDownHandler);
    return () => {
      window.removeEventListener('keydown', pageKeyDownHandler);
    };
  }, [pageKeyDownHandler]);

  React.useEffect(() => {
    maskSSN();
    return () => {
      window.globalThis.keyEvent = undefined;
    };
  }, [maskSSN]);

  const onViewOnlySSNClick = () => {
    if (mask === 'nonePartial') {
      if (!viewFullSSN) {
        maskSSN({ maskOverride: 'none' });
        setViewFullSSN(true);
      } else {
        maskSSN({ maskOverride: 'nonePartial' });
        setViewFullSSN(false);
      }
    }
  };

  const onKeyDownCapture = event => {
    window.globalThis.keyEvent = event.keyCode;
    switch (event.keyCode) {
      case 8: // backspace
      case 37: // left arrow
      case 39: // right arrow
        break;
      case 48: // numbers
      case 49:
      case 50:
      case 51:
      case 52:
      case 53:
      case 54:
      case 55:
      case 56:
      case 57:
      case 96:
      case 97:
      case 98:
      case 99:
      case 100:
      case 101:
      case 102:
      case 103:
      case 104:
      case 105:
        // prevent key strokes beyond max length
        if (textFieldRef?.current?.value && textFieldRef?.current?.value.replace(/[^0-9]/g, '').length === 11) {
          event.preventDefault();
          event.stopPropagation();
        }
        break;
      case 13: // enter
        break;
      case 65:
        if (event.metaKey || event.ctrlKey) break;
      // eslint-disable-next-line no-fallthrough
      default:
        event.preventDefault();
        event.stopPropagation();
        break;
    }
  };

  const _onChange = event => {
    const input = event.target;
    const caret = setValForOnTextChange(event.target.value, event.target.selectionStart ?? 0);
    window.requestAnimationFrame(() => {
      input.selectionStart = caret;
      input.selectionEnd = caret;
    });
  };

  const setValForOnTextChange = (input: string, caret: number): number => {
    let inputValue = input;

    // Move caret to correct spot on insert or delete
    if (window.globalThis.keyEvent === 8) {
      // backspace
      switch (caret) {
        case 3:
        case 4:
        case 6:
        case 7:
          inputValue = inputValue.slice(0, caret - 1) + inputValue.slice(caret, inputValue.length);
          caret--;
          break;
        default:
          break;
      }
    } else {
      switch (caret) {
        case 3:
        case 4:
        case 6:
        case 7:
          caret++;
          break;
        default:
          break;
      }
    }

    // Format SSN
    const onlyNums: string = inputValue.replace(/[^0-9]/g, '');
    const splitVals = onlyNums.split('');
    const arr = Array(9)
      .fill('_')
      .map((item, i) => (i < splitVals.length ? splitVals[i] : item));
    const format = `${arr[0]}${arr[1]}${arr[2]}-${arr[3]}${arr[4]}-${arr[5]}${arr[6]}${arr[7]}${arr[8]}`;

    if (!onlyNums) _setSSN('');
    else _setSSN(format);

    // move cursor to index of last numeric value
    const regex = new RegExp(/[0-9]{1,1}/g);
    let found = false;
    let lastIndex = 0;
    do {
      found = regex.test(format);
      if (regex.lastIndex > lastIndex) lastIndex = regex.lastIndex;
    } while (found);
    if (caret > lastIndex) caret = lastIndex;
    onChange?.(onlyNums);

    return caret;
  };

  return (
    <React.Fragment>
      {promiseInProgress ? (
        <Skeleton />
      ) : !readOnly || keepTextFieldOnReadOnly ? (
        <ClickAwayListener onClickAway={endSSNEdit}>
          <TextField
            id={id}
            inputRef={textFieldRef}
            autoComplete="off"
            InputProps={{
              className: cx({ [classes.textareastyle]: size === 'small' }),
              classes: {
                root: cx({ [classes.root]: size === 'small' }),
              },
              disabled: readOnly && keepTextFieldOnReadOnly,
            }}
            value={_ssn}
            hiddenLabel={hiddenLabel}
            onKeyDownCapture={onKeyDownCapture}
            onClick={event => {
              if (!readOnly) startSSNEdit(event);
              else if (readOnly && keepTextFieldOnReadOnly) {
                onViewOnlySSNClick();
              }
            }}
            onChange={event => {
              _onChange(event);
            }}
            error={error && textFieldRef.current !== document.activeElement}
            helperText={error && textFieldRef.current !== document.activeElement ? helperText : undefined}
            type={'string'}
            variant={variant}
            size={size}
            {...props}
          />
        </ClickAwayListener>
      ) : (
        <ClickAwayListener onClickAway={stopViewingSSN}>
          <Typography
            variant="body1"
            className={cx({ [classes.clickable]: mask === 'nonePartial' })}
            onClick={onViewOnlySSNClick}
          >
            {_ssn}
          </Typography>
        </ClickAwayListener>
      )}
    </React.Fragment>
  );
};
