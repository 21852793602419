import { Box, Grid, Theme, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PreferanceMatch from 'app/assets/images/PreferanceMatch.svg';
import PreferanceMatchDisabled from 'app/assets/images/PreferanceMatchDisabled.svg';
import { TextWithEllipses } from '@AMIEWEB/Notification/Common/StringHelper';


const useStyles = makeStyles<{ showActionPanel?: boolean }>()((theme: Theme, { showActionPanel }) => ({
  cardFooterDivision: {
    top: '422px',
    height: '16px',
  },
  cardFooterDivisionMultiLine: {
    top: '422px',
    height: '16px',
  },
  truncation: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  truncateDescription: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  truncateTitle: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: showActionPanel ? '100px' : '190px'
  },
  detials: {
    color: theme.palette.system.darkGray,
    font: 'normal normal normal 12px/18px Roboto',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    height: '40px',
    paddingTop: '4px',
  },
  detailsRead: {
    color: theme.palette.system.darkGray,
    font: 'normal normal normal 12px/18px Roboto',
    fontWeight: 'normal',
    fontFamily: 'Roboto',
    height: '40px',
    paddingTop: '4px',
  },
  notificationTitle: {
    top: '363px',
    left: '209px',
    height: '19px',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: 500,
    lineHeight: '19px',
    letterSpacing: '0px',
    color: theme.palette.system.darkGray,
    opacity: 1,
  },
  newTaskNotificationTitle: {
    top: '363px',
    left: '209px',
    height: '19px',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: 500,
    lineHeight: '19px',
    letterSpacing: '0px',
    color: theme.palette.system.darkGray,
    opacity: 1,
  },
  description: {
    top: '390px',
    left: '149px',
    minHeight: '16px',
    maxHeight: '30px',
    textAlign: 'left',
    font: 'normal normal normal 12px/18px Roboto',
    fontFamily: 'Roboto',
    letterSpacing: '0px',
    color: '#222222',
    opacity: 1,
    paddingTop: '0.15rem',
  },
  dueDate: {
    top: '215px',
    left: '428px',
    height: '16px',
    font: 'normal normal normal 12px/16px Roboto',
    letterSpacing: '0px',
    color: theme.palette.framework.system.tertiaryGrey,
    textAlign: "right",
    marginRight: '12px'
  },
  additionalInfo: {
    top: '410px',
    left: '101px',
    height: '16px',
    textAlign: 'left',
    font: 'normal normal normal 12px/16px Roboto',
    letterSpacing: '0px',
    color: theme.palette.framework.system.tertiaryGrey,
    opacity: 1,
    width: showActionPanel ? '100px' : '180px'
  },
  gridContainers: {
    marginTop: '9px',
    height: 'auto',
    opacity: 1,
  },
  gridContainersMultiLine: {
    marginTop: '12px',
    height: 'auto',
    opacity: 1,
  },
  baseContainer: {
    height: '119px !important'
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  statusIcon: {
    top: '366px',
    left: '180px',
    width: '36px',
    height: '36px',
    opacity: 1,
  },
  notificationTaskId: {
    marginLeft: '3px',
    height: '19px',
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Roboto',
    letterSpacing: '0px',
    color: theme.palette.system.doveGray,
  },
  newTasknotificationTaskId: {
    marginLeft: '3px',
    height: '19px',
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Roboto',
    letterSpacing: '0px',
    color: theme.palette.system.doveGray,
    marginTop: '9px',
  },
  mainContainer: {
    margin: '0px',
    padding: '12px',
    height: '119px',
    width: showActionPanel ? '250px' : '350px',
    transition: 'width 0.3s',
    opacity: 1,
    backgroundColor: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: ` 0px 0px 6px ${theme.palette.system.lightBlack}`,
    border: `1px solid ${theme.palette.framework.system.lightGray}`,
    borderRadius: ' 5px',
  },
  attachmentIcon: {
    width: '18px',
    height: '18px',
    marginLeft: '10px',
    marginBottom: '9px',
  },
  seperatorIcon: {
    fontSize: '6px',
    marginBottom: '2px',
  },
  mainTitle: {
    color: theme.palette.framework.system.violet,
    fontSize: ' 0.8rem !important',
    textAlign: 'left',
    fontWeight: 'bold',
    padding: '0px',
    margin: '0px'
  },
  mainTitleRead: {
    color: theme.palette.system.darkCharcoalGray,
    fontSize: ' 0.8rem !important',
    textAlign: 'left',
    fontWeight: 'normal',
    padding: '0px',
    margin: '0px'
  },
  secondaryTitle: {
    display: 'flex',
    color: theme.palette.system.darkGray,
    fontSize: '0.9rem',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    padding: '0px',
    margin: '0px'
  },
  secondaryTitleRead: {
    display: 'flex',
    color: theme.palette.system.darkGray,
    fontSize: '0.9rem',
    fontWeight: 'normal',
    fontFamily: 'Roboto',
    padding: '0px',
    margin: '0px'
  },
  spanText: {
    fontWeight: 'normal',
    zIndex: "5"
  },
}));
export const OrderMatchNotificationPopup = props => {
  const { NotificationBody, showActionPanel, isUnread } = props;
  const { classes } = useStyles({ showActionPanel });
  const { t } = useTranslation();
  const [associatedRecords, setAssociatedRecords] = useState<any>({});
  const [dueDate, setDueDate] = useState('');
  let Maxlength
  if (showActionPanel) {
    Maxlength = 12;
  }
  else {
    Maxlength = 25;
  }




  const getAssociatedRecords = records => {
    const obj: any = {};
    records?.forEach(el => {
      obj[el.name] = el.value;
    });
    return obj;
  };

  const capitalizeName = (name) => {
    return name?.toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  };

  const formatSkillset = (skillset) => {
    const str = skillset?.replaceAll(',', ', ');
    return str;
  };

  const formatSkillsets = (skillset) => {
    const str = skillset?.replaceAll(',', '-');
    return str;
  };

  useEffect(() => {
    const getRecords = getAssociatedRecords(NotificationBody?.associateRecords);
    setAssociatedRecords(getRecords);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [NotificationBody]);

  useEffect(() => {
    const formattedDate = new Date(associatedRecords.OrderCreatedDate);
    const sentDate = formattedDate.toLocaleDateString();
    setDueDate(sentDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associatedRecords]);

  return (
    <React.Fragment>
      <div className={classes.mainContainer}>
        <Grid container direction="row">
          <Grid item xs={2}>
            <div>
              <img src={isUnread ? PreferanceMatch : PreferanceMatchDisabled} />
            </div>
          </Grid>
          <Grid item xs={10}>
            <div style={{ marginLeft: showActionPanel ? '12px' : '-2px' }}>
              <Box display={'flex'}>
                <Typography className={isUnread ? classes.mainTitle : classes.mainTitleRead}>
                  {t('order.orderMatchNotification.newpreferencematch')}
                </Typography>
              </Box>
              <Box display={'flex'}>
                <Typography className={isUnread ? classes.secondaryTitle : classes.secondaryTitleRead}>
                  {associatedRecords.PrimaryContext && (
                    <>
                      {TextWithEllipses(capitalizeName(associatedRecords?.PrimaryContext), Maxlength)}
                      <span className={classes.spanText}> &nbsp;{'(' + associatedRecords?.CandidateId + ')'}</span>
                    </>)}
                </Typography>
              </Box>
            </div>
          </Grid>
          <Grid xs={12} className={isUnread ? classes.detials : classes.detailsRead}>
            <div className={classes.truncateDescription}>
              {formatSkillsets(associatedRecords?.SkillSet)}{" | "}{associatedRecords?.FacilityName ?? associatedRecords?.Location}{" (OID "}{associatedRecords?.OrderId}{")"}
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          direction="column"
          className={classes.cardFooterDivision}
        >
          <Grid item className={classes.additionalInfo}>
            <div className={classes.truncation}>{associatedRecords?.PreferenceSetName ?? " "}</div>
          </Grid>
          <Grid item className={classes.dueDate}>
            {dueDate}
          </Grid>
        </Grid>
      </div>
    </React.Fragment >
  );
};
