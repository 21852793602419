/* eslint-disable prettier/prettier */
/* eslint-disable i18next/no-literal-string */
import { IconButton, Theme, Button, Grid, Alert } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import { FormInputDropdown } from './FormInputDropdown';
import { contactTypes } from '../PersonalInfoHelper';
import { candidateDetailsSelection } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { candidateDetailActions } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { IPhoneNumber } from 'app/models/Candidate/CandidateProfile';
import InfoIcon from 'app/assets/images/IconImages/BlueInfoIcon.svg';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { useTranslation } from 'react-i18next';
import { defaultCountry } from 'app/constants';
import { useFormContext } from 'react-hook-form';

const enum CountryCode {
  US = 'us',
}

export const useInputCountryDropdownStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: '0px 10px 0px 0px',
  },
  arrowClass: {
    '&.react-tel-input .selected-flag .arrow': {
      background: `url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSI4LjgyOSIgdmlld0JveD0iMCAwIDE2IDguODI5Ij4KICA8cGF0aCBpZD0iQXJyb3ciIGQ9Ik0yNS4xNTgsMzEuNzIxYS43NzQuNzc0LDAsMCwxLS41NDgtLjIyN2wtNy4yNzctNy4yNzZhLjc3Ni43NzYsMCwxLDEsMS4xLTEuMWw2LjcyOCw2LjcyOCw2LjYyMS02LjYyMmEuNzc3Ljc3NywwLDEsMSwxLjEsMS4xbC03LjE3LDcuMTdBLjc3NS43NzUsMCwwLDEsMjUuMTU4LDMxLjcyMVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNy4xMDQgLTIyLjg5MikiIGZpbGw9IiM4ODgiLz4KPC9zdmc+Cg==")`,
      borderLeft: '0px',
      borderRight: '0px',
      borderTop: '0px',
      width: '10px',
      height: '9px',
      paddingLeft: '16px',
      zoom: '0.6',
    },
    '&.react-tel-input .selected-flag': {
      width: '60px',
    },
    '&.react-tel-input .selected-flag .arrow.up': {
      borderBottom: '0px',
    },
    '&.react-tel-input .form-control': {
      paddingLeft: '70px',
    },
    '&.react-tel-input .selected-flag .flag': {
      paddingLeft: '8px',
      zoom: '1.5',
    },
  },
  alert: {
    width: '74.2%',
    padding: '0px 6px 3px 6px',
    marginLeft: '7px',
    '&>div': {
      display: 'flex',
      alignItems: 'center',
      '& div.MuiAlert-icon': {
        flexGrow: 1,
      },
      '& div.MuiAlert-message': {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 30,
        justifyContent: 'space-between',
      },
    },
  },

  deleteIcon: {
    marginTop: '20px',
    marginLeft: '10px',
  },
  errorText: {
    margin: '2px',
    paddingLeft: '12px',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    color: '#f44336',
  },
}));

export const FormInputCountryDropDown = props => {
  const { contactNumber, contactTypeName, options, onChangeType, checkContactEdit, disabled, controllerName } = props;
  const { classes } = useInputCountryDropdownStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { control, register, setError, errors, clearErrors } = useFormContext();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const [contactType, setContactType] = useState<string>(contactTypeName);
  const [contactNumbers, setContactNumbers] = useState<string>(contactNumber);
  const deletedData = useRef<IPhoneNumber | undefined>();
  const isPhoneNumberError = errors?.phoneNumbers?.some(error => error?.ref?.name === controllerName);
  const [valueError, setValueError] = useState(false);
  const [previousCountryCode, setPreviousCountryCode] = useState<string>('');

  const handleChangeContactType = event => {
    checkContactEdit('contact');
    setContactType(event?.target.value);
    onChangeType(contactType, event?.target.value);
    var selectedPhone = candidateDetails.phoneNumbers?.find(x => x.phoneTypeDescription === contactTypeName);
    var remainingPhoneData = candidateDetails.phoneNumbers?.filter(x => x.phoneTypeDescription !== contactTypeName);
    remainingPhoneData?.push({
      phoneTypeDescription: event?.target.value,
      phoneTypeId: contactTypes?.find(x => x.Description == event?.target.value)?.ID || 0,
      countryDialingCode: selectedPhone?.countryDialingCode || '',
      phoneNumber: selectedPhone?.phoneNumber || '',
      timeStamp: selectedPhone?.timeStamp || '',
    });
    dispatch(
      candidateDetailActions.setCandidateDetails({
        ...candidateDetails,
        phoneNumbers: remainingPhoneData,
      }),
    );
  };

  const processPhone = value => {
    let count = 0;
    let index;
    for (let i = 0; i < value.length; i++) {
      if (value[i] == ' ' && count == 0) {
        count = 1;
        index = i;
      }
    }
    return value.substr(index + 1, value.length);
  };

  const dispatchPhoneData = (data, formattedValue) => {
    var selectedPhone = candidateDetails?.phoneNumbers?.find(x => x.phoneTypeDescription === contactTypeName);
    var remainingPhoneData = candidateDetails?.phoneNumbers?.filter(x => x.phoneTypeDescription !== contactTypeName);
    remainingPhoneData?.push({
      phoneTypeDescription: contactType,
      phoneTypeId: contactTypes?.find(x => x.Description == contactType)?.ID || 0,
      countryDialingCode: data?.dialCode,
      phoneNumber: processPhone(formattedValue),
      timeStamp: selectedPhone?.timeStamp || '',
    });
    dispatch(
      candidateDetailActions.setCandidateDetails({
        ...candidateDetails,
        phoneNumbers: remainingPhoneData,
      }),
    );
  };

  const handleChangeContactNumber = (value, data, e, formattedValue) => {
    const contactNumber = processPhone(formattedValue);
    const phoneNumberLength = getPhoneNumberLength(contactNumber);
    setPreviousCountryCode(data?.countryCode);
    if (isPhoneNumberError) {
      if (data?.countryCode === CountryCode.US) {
        phoneNumberLength === 10 && setValueError(false);
      } else {
        phoneNumberLength >= 5 && setValueError(false);
      }
    }
    if (data?.countryCode !== previousCountryCode) {
      setValueError(false);
    }

    let format;
    let len;
    if (data.format !== undefined) {
      format = data.format;
      len = format.length;
    }
    if (len == formattedValue?.length) {
      setContactNumbers(value);
      dispatchPhoneData(data, formattedValue);
    }
    if (
      (data.format == '+.. ... ... ... ... ..' || data.format == '+... ... ... ... ... ..') &&
      formattedValue.length > 14
    ) {
      setContactNumbers(value);
      dispatchPhoneData(data, formattedValue);
    }
    checkContactEdit('contact');
    validatePhoneNumber(data?.countryCode, contactNumber);
  };

  const getPhoneNumberLength = (contactNumber: string): number => {
    const matches = contactNumber?.match(/\d/g);
    return matches ? matches?.length : 0;
  };

  const validatePhoneNumber = (countryCode: string, phoneNumber: string) => {
    const phoneNumberLength = getPhoneNumberLength(phoneNumber);
    if (countryCode === CountryCode.US) {
      if (phoneNumberLength < 10) {
        setError(controllerName, { type: 'manual', message: t('candidate.profileInformation.errorMessage') });
      } else if (phoneNumberLength === 10) {
        {
          clearErrors(controllerName);
        }
      }
    } else {
      if (phoneNumberLength < 5) {
        setError(controllerName, { type: 'manual', message: t('candidate.profileInformation.errorMessage') });
      } else {
        clearErrors(controllerName);
      }
    }
  };

  const validateError = () => {
    const error = isPhoneNumberError ? true : false;
    setValueError(error);
  };

  const handleSoftDelete = e => {
    const index = candidateDetails.phoneNumbers?.findIndex(x => x.phoneTypeDescription === contactTypeName)!;
    deletedData.current = candidateDetails.phoneNumbers ? candidateDetails.phoneNumbers[index] : undefined;
    const remainingPhoneData = candidateDetails.phoneNumbers?.filter(x => x.phoneTypeDescription !== contactTypeName);
    if (deletedData.current) remainingPhoneData?.push({ ...deletedData.current, phoneNumber: '' });
    dispatch(
      candidateDetailActions.setCandidateDetails({
        ...candidateDetails,
        phoneNumbers: remainingPhoneData,
      }),
    );
    props.onSoftDelete();
  };

  const handleUndoDelete = () => {
    const phoneData: IPhoneNumber[] = [];
    if (candidateDetails.phoneNumbers) {
      for (let i = 0; i < candidateDetails.phoneNumbers.length; i++) {
        phoneData.push({ ...candidateDetails.phoneNumbers[i] });
      }
    }
    if (deletedData.current) phoneData.push({ ...deletedData.current });
    dispatch(
      candidateDetailActions.setCandidateDetails({
        ...candidateDetails,
        phoneNumbers: phoneData,
      }),
    );
    deletedData.current = undefined;
    props.undoDelete();
  };

  return (
    <>
      {!props.isSoftDeleted || (
        <div className={classes.alert}>
          <Alert severity="warning">
            This phone number will be deleted on save
            <Button variant="text" onClick={handleUndoDelete}>
              Undo
            </Button>
          </Alert>
        </div>
      )}

      <LayoutGridItem item>
        <LayoutGrid
          container
          classes={{ root: classes.root }}
          direction="row"
          data-testid="container"
          justifyContent="flex-start"
          spacing={4}
        >
          <LayoutGridItem item xs={4} py="12px !important">
            <FormInputDropdown
              name={`ContactType`}
              label={
                <Grid container spacing={2}>
                  <Grid item>Type</Grid>
                  {disabled && (
                    <Grid item style={{ transform: 'translate(0, -4px)' }}>
                      <CustomTooltip standardMargin tooltipContent={t('candidate.personalInfoCard.permanent2')}>
                        <img src={InfoIcon} alt="Error" style={{ width: '20px', height: '20px' }} />
                      </CustomTooltip>
                    </Grid>
                  )}
                </Grid>
              }
              require={false}
              options={options} //removing the filter with ID code as it is throwing error while associating candidates
              handleChange={handleChangeContactType}
              inputValue={contactType}
              readOnly={disabled || props.isSoftDeleted}
              disabled={disabled}
            ></FormInputDropdown>
          </LayoutGridItem>
          <LayoutGridItem item xs={5} py="12px !important" style={{ maxWidth: '40%' }}>
            <>
              <PhoneInput
                containerClass={classes.arrowClass}
                disabled={disabled || props.isSoftDeleted}
                country={defaultCountry}
                placeholder={'(...) ...-....'}
                enableSearch={true}
                disableSearchIcon={true}
                value={contactNumbers ?? ''}
                onChange={handleChangeContactNumber}
                onBlur={() => validateError()}
                searchPlaceholder="Search Country"
                preferredCountries={['us', 'ca', 'mx']}
                buttonStyle={{
                  border: 0,
                  borderBottom: valueError ? '2px solid #f44336' : '2px solid #8e8e8e',
                  borderRight: '1px solid lightgrey',
                  background: disabled ? 'rgba(0,0,0,0.08)' : '#f4f4f4',
                }}
                inputStyle={{
                  height: 54,
                  width: 250,
                  border: 0,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  borderBottom: valueError ? '2px solid #f44336' : '2px solid #8e8e8e',
                  color: disabled ? 'rgba(0,0,0,0.38)' : '#333333',
                  background: disabled ? 'rgba(0,0,0,0.12)' : '#f4f4f4',
                }}
                searchStyle={{
                  width: 'fit-content',
                }}
                dropdownStyle={{}}
              />
              <p className={classes.errorText}>{valueError && t('candidate.profileInformation.errorMessage')}</p>
            </>
          </LayoutGridItem>
          {!disabled && (
            <LayoutGridItem item xs={3} py="12px !important">
              <IconButton color="primary" disabled={props.isSoftDeleted} size="small" className={classes.deleteIcon}>
                <DeleteOutline onClick={handleSoftDelete} />
              </IconButton>
            </LayoutGridItem>
          )}
        </LayoutGrid>
      </LayoutGridItem>
    </>
  );
};
