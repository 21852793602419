import React, { useRef } from 'react';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';
import { cssTransition, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styledComponent from 'styled-components';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { selectSearchResults } from 'store/redux-store/global-search/selectors';
import {
  selectCandidateDrawer,
  selectDetailsPageDrawer,
  selectFacilityDrawer,
  selectOrderDrawer,
  selectSmsIconDrawer,
} from 'app/ApplicationRoot/Global.selectors';
import { XGridOptionsCell } from '@AMIEWEB/Common';

export const Zoom = cssTransition({
  // zoomIn will become zoomIn--top-right or zoomIn--top-left and so on
  enter: 'zoomIn',
  // zoomIn will become zoomOut--top-right or zoomOut--top-left and so on
  exit: 'zoomOut',
  // default to false
  appendPosition: true,
});

const StyledToastContainer = styledComponent(ToastContainer)`
  .Toastify__toast-container--top-center {
    transform: translateX(-50%);
  }

  .Toastify__toast {
    cursor: inherit;
    font-family: 'Circular';
    padding: 0;
    margin-bottom: 1.25em;
    height: 70px;
    width: 380px;
    box-shadow: 0px 0px 2px 6px #b2b2b2;
    background-color: #f4f5f7;
    border-radius: 6px;
  }
  .Toastify__toast-body {
    padding: 0;
    margin: 0;
  }
`;

export const Toast = () => {
  const { drawerData } = useSelector(selectSearchResults);
  const candidateDrawer = useSelector(selectCandidateDrawer);
  const facilityDrawer = useSelector(selectFacilityDrawer);
  const orderDrawer = useSelector(selectOrderDrawer);
  const detailsPageDrawer = useSelector(selectDetailsPageDrawer);
  const smsIconDrawer = useSelector(selectSmsIconDrawer);
  const drawerOpened =
    drawerData.open ||
    candidateDrawer.open ||
    facilityDrawer.open ||
    orderDrawer.open ||
    detailsPageDrawer.open ||
    smsIconDrawer?.open;

  const rightOfToast = useRef(null);

  const getComputedStyle = () => {
    const transform = rightOfToast?.current?.style?.transform;
    var matrix = new WebKitCSSMatrix(transform);
    const xPos = Math.abs(matrix.m41);
    const yPos = Math.abs(matrix.m42);

    if (drawerOpened && xPos < 510) {
      return globalData?.sms?.open ? 940 : 510 - xPos;
    } else {
      return globalData?.sms?.open && xPos < 510 ? 520 - xPos : 85;
    }
  };

  const globalData = useSelector(notificationSelection);
  return (
    <Draggable
      bounds={{
        top:
          globalData.activeToasterCount === 1
            ? -800
            : globalData.activeToasterCount === 2
            ? -750
            : globalData.activeToasterCount === 3
            ? -630
            : globalData.activeToasterCount === 4
            ? -600
            : -500,
        left: -1400,
        right: 0,
        bottom: 0,
      }}
    >
      <div
        ref={rightOfToast}
        style={{
          position: 'fixed',
          bottom: -30,
          right: getComputedStyle(),
        }}
      >
        <StyledToastContainer enableMultiContainer containerId={'channel'} />
      </div>
    </Draggable>
  );
};
