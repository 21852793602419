import {
  Box,
  Button,
  Checkbox,
  Grid,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from 'amn-ui-core';
import { ChipsContainer } from 'app/ComponentLibrary/Filter';
import { getSearchFilterOptions } from 'app/services/SharedServices/SharedServices';
import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCheckedRoles,
  selectDialogeOpen,
  selectEmpInfo,
  selectEmpRoles,
  selectEmpCardInfo,
  selectIsDataChanged,
  selectUserLists,
  selectedTabValue,
} from '../store/userManagement.selector';
import { usePromiseTracker } from 'react-promise-tracker';
import { getUserManagementActions } from '../store/userManagement.redux';
import { selectUser } from 'oidc/user.selectors';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { createSelector } from '@reduxjs/toolkit';
import { DiscardChangesDialog } from '../DiscardChangesDialog/DiscardChangesDialog';

const useStyles = makeStyles()(theme => ({
  availableRoles: {
    paddingLeft: '0 !important',
  },
  rolesContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
    margin: '2px',
    alignContent: 'flex-start',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'flex-start',
    height: '100%',
    justifyContent: 'space-evenly',
    marginRight: '10px',
  },
  button: {
    margin: '0px 10px 4px 0px',
  },
  left: {
    width: '100%',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.framework.system.silver}`,
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: 'calc(100vh - 515px)',
    '@media (max-width: 980px)': {
      height: 'calc(100vh - 555px)',
    },
  },
  disabledGrid: {
    pointerEvents: 'none',
    filter: 'blur(0.5px)',
    opacity: 0.7,
  },
  dropdown: {
    flexWrap: 'nowrap',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  right: {
    width: '104%',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.framework.system.silver}`,
    height: 'calc(100vh - 515px)',
    '@media (max-width: 980px)': {
      height: 'calc(100vh - 555px)',
    },
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    flexDirection: 'row',
    padding: '8px',
  },
  whiteChip: {
    backgroundColor: theme.palette.framework.system.white,
    borderRadius: '4px',
    border: `1px solid ${theme.palette.framework.system.silver}`,
    '& .MuiChip-deleteIcon': {
      color: theme.palette.framework.system.white,
    },
  },
  input: {
    width: '100%',
    padding: '2%',
    backgroundColor: `${theme.palette.framework.system.white} !important`,
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiListItemIcon-root': {
      minWidth: 20,
      marginRight: theme.spacing(1),
    },
  },
  rolesText: {
    fontSize: '14px',
    color: theme.palette.framework.system.charcoal,
  },
  inputCheckBox: {
    height: '20px',
    color: theme.palette.framework.system.grayishBlue,
  },
  actionButton: {
    '&:hover': {
      backgroundColor: theme.palette.framework.system.silver,
    },
    color: theme.palette.framework.system.white,
    padding: '4px 9px',
    fontSize: '0.775rem',
    backgroundColor: theme.palette.framework.system.silver,
    pointerEvents: 'none',
  },
  cancelButton: {
    '&:hover': {
      backgroundColor: theme.palette.framework.system.dimGrey,
    },
    color: theme.palette.framework.system.white,
    padding: '4px 9px',
    fontSize: '0.775rem',
    backgroundColor: theme.palette.framework.system.buttonGray,
  },
  saveButton: {
    color: theme.palette.framework.system.white,
    padding: '4px 9px',
    fontSize: '0.775rem',
    marginRight: '8px',
  },
  saveDisableButton: {
    '&:hover': {
      backgroundColor: theme.palette.framework.system.silver,
    },
    color: theme.palette.framework.system.white,
    padding: '4px 9px',
    fontSize: '0.775rem',
    backgroundColor: theme.palette.framework.system.silver,
    marginRight: '8px',
    pointerEvents: 'none',
  },
  subtitle1: {
    fontSize: '14px',
    fontWeight: 600,
    marginBottom: '12px',
  },
  subtitle2: {
    fontSize: '14px',
    fontWeight: 600,
    marginBottom: '12px',
  },
  wrapper: {
    position: 'absolute',
    top: 0,
    left: '10vw',
  },
  loaderText: {
    marginTop: '10px',
    fontStyle: 'italic',
    fontWeight: 500,
  },
  loader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const selectAllData = createSelector(
  [
    selectEmpInfo,
    selectDialogeOpen,
    selectCheckedRoles,
    selectIsDataChanged,
    selectEmpCardInfo,
    selectEmpRoles,
    selectUserLists,
  ],
  (empInfo, dialogeOpen, checkedRoles, isDataChanged, empCardInfo, empRoles, employeeData) => ({
    empInfo,
    dialogeOpen,
    checkedRoles,
    isDataChanged,
    empCardInfo,
    empRoles,
    employeeData,
  }),
);

const Roles: React.FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { empInfo, dialogeOpen, checkedRoles, isDataChanged, empCardInfo, empRoles, employeeData } =
    useSelector(selectAllData);
  const [roles, setRoles] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [uncheck, setUncheck] = useState(false);
  const { userInfo } = useSelector(selectUser);
  const tabValue = useSelector(selectedTabValue);
  const dispatch = useDispatch();
  const { promiseInProgress } = usePromiseTracker({ area: 'user-search-card', delay: 0 });
  const promise = usePromiseTracker({ area: 'user-get-role', delay: 0 });

  var hasValues = employeeData && employeeData.length > 0;
  const authorized = Authorized([userRoles.supportAdmin], userInfo);

  const fetchFilterSpecs = async () => {
    const [roleLookUps] = await Promise.all([getSearchFilterOptions('employeerole', true)]);
    const roleOpts = roleLookUps.map((role, id) => ({ id: id, ...role }));
    setRoles(roleOpts);
  };

  React.useEffect(() => {
    fetchFilterSpecs();
    const Eroles =
      empRoles && Array.isArray(empRoles)
        ? empRoles.map(empRole => ({
            name: empRole?.description,
            value: empRole?.roleTypeId?.toString(),
          }))
        : [];

    const filteredData = Eroles.reduce((acc, current) => {
      const corrRole = roles.find(role => current.name === role.name);
      if (corrRole != -1) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    dispatch(getUserManagementActions.setCheckedRoles(filteredData));
    setUncheck(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empRoles]);

  useEffect(() => {
    const currentEmpRoles = (checkedRoles || []).map(item => item.value);
    const empRoleValues =
      empRoles && Array.isArray(empRoles) ? empRoles.map(empRole => empRole?.roleTypeId.toString()) : [];
    const isDifferent = !arrayEquals(currentEmpRoles, empRoleValues);
    dispatch(getUserManagementActions.setIsDataChanged(uncheck ? false : isDifferent));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedRoles]);

  const arrayEquals = (arr1: any[], arr2: any[]) => {
    if (arr1.length !== arr2.length) return false;

    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) return false;
    }

    return true;
  };

  const handleCheck = role => {
    if (empInfo?.length === 0 || !authorized) {
      return;
    }
    const isRoleChecked = checkedRoles.some(item => item.value === role.value);
    let updatedCheckedItems;

    if (isRoleChecked) {
      updatedCheckedItems = checkedRoles.filter(item => item.value !== role.value);
    } else {
      updatedCheckedItems = [...checkedRoles, role];
    }
    dispatch(getUserManagementActions.setCheckedRoles(updatedCheckedItems));
    setUncheck(false);
  };

  const handleDelete = (field, { value: chipValue, isSelected }) => {
    if (!authorized) {
      return;
    }
    const updatedCheckedItems = checkedRoles.filter(item => item.value !== chipValue);
    dispatch(getUserManagementActions.setCheckedRoles(updatedCheckedItems));
    setUncheck(false);
  };

  const handleSave = () => {
    const empRoleIds = checkedRoles.map(item => parseInt(item.value));
    const payload = {
      employeeId: empInfo?.employeeId,
      roleIds: empRoleIds,
    };
    dispatch(getUserManagementActions.updateEmployeeRoles(payload));
    dispatch(getUserManagementActions.setIsDataChanged(false));
  };

  const handleCancel = () => {
    const Eroles =
      empRoles && Array.isArray(empRoles)
        ? empRoles.map(empRole => ({
            name: empRole?.description,
            value: empRole?.roleTypeId.toString(),
          }))
        : [];
    dispatch(getUserManagementActions.setCheckedRoles(Eroles));
    setUncheck(false);
  };

  const handleChanges = () => {
    setUncheck(true);
    dispatch(getUserManagementActions.setIsDataChanged(false));
    dispatch(getUserManagementActions.setCustomTabValue(tabValue));
    dispatch(getUserManagementActions.setSelectedCardId(empCardInfo?.employeeId));
    dispatch(getUserManagementActions.setDialoge(false));
    dispatch(getUserManagementActions.setCheckedRoles([]));
    dispatch(getUserManagementActions.setEmpInfo(empCardInfo));
    const payload = { employeeId: empCardInfo?.employeeId };
    dispatch(getUserManagementActions.getEmpRoles(payload));
  };
  const keepChanges = () => {
    dispatch(getUserManagementActions.setDialoge(false));
  };

  return (
    <Grid container spacing={2} className={classes.rolesContainer} data-testid="roles">
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={5} data-testid="availableRoles" className={classes.availableRoles}>
          <Typography className={classes.subtitle1} variant="subtitle1">
            {t('userManagement.availableRole')}
          </Typography>
          <Grid
            container
            className={`${classes.left} ${
              (!hasValues && !promiseInProgress) || empInfo?.length === 0 ? classes.disabledGrid : ''
            } `}
          >
            <Box component="form" noValidate autoComplete="off">
              <Input
                id="standard-basic"
                placeholder="Search"
                onChange={e => setSearchTerm(e.target.value)}
                className={classes.input}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </Box>
            <Grid item spacing={1} className={`${classes.dropdown}`}>
              <List dense>
                {roles
                  .filter(role => role.name.toLowerCase().includes(searchTerm.toLowerCase()))
                  .map(role => {
                    return (
                      <ListItem key={role.id} className={classes.listItem} button onClick={() => handleCheck(role)}>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checkedRoles?.some(item => item?.value === role?.value)}
                            className={classes.inputCheckBox}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body1" className={classes.rolesText}>
                              {role.name}
                            </Typography>
                          }
                        />
                      </ListItem>
                    );
                  })}
              </List>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6} data-testid="AssignedRoles">
          <Typography className={classes.subtitle2} variant="subtitle2">
            {t('userManagement.selectedRloes')}
          </Typography>
          <Grid container className={classes.right}>
            {checkedRoles?.length <= 0 && empInfo?.employeeId && promise ? (
              <div className={classes.loader}>
                <Typography className={classes.loaderText}>{t('No role assigned')}</Typography>
              </div>
            ) : (
              <ChipsContainer
                maxHeight={'100%'}
                chips={
                  checkedRoles?.map(item => {
                    if (item && item.name) {
                      return { label: item.name, value: item.value, enableDelete: true };
                    }
                    return null;
                  }) ?? []
                }
                onDelete={handleDelete}
                onClick={() => {}}
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="flex-end" className={classes.button} data-testid="button">
        <Grid item>
          <Button
            className={`${isDataChanged ? classes.cancelButton : classes.actionButton}`}
            variant="contained"
            onClick={handleCancel}
          >
            {`Cancel`}
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={`${
              empInfo?.employeeId && isDataChanged && authorized ? classes.saveButton : classes.saveDisableButton
            }`}
            variant="contained"
            onClick={handleSave}
          >
            {`Save`}
          </Button>
        </Grid>
      </Grid>
      <DiscardChangesDialog dialogeOpen={dialogeOpen} handleChanges={handleChanges} keepChanges={keepChanges} />
    </Grid>
  );
};

export default Roles;
