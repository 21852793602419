import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { isStrikOrder } from 'utils/common/Placements/Automation';

import { initialState } from '../Store/Order.redux';

const selectDomain = (state: RootState) => state.orderData || initialState;

export const selectOrderDetails = createSelector([selectDomain], orderState => orderState.details);

export const selectNewOrder = createSelector([selectDomain], orderState => orderState.newOrder);

export const selectNewUnit = createSelector([selectDomain], orderState => orderState.newUnit);

export const selectIsEditUnit = createSelector([selectDomain], orderState => orderState.isEditUnit);

export const selectOrders = createSelector([selectDomain], orderState => orderState.orders);

export const selectLevel = createSelector([selectDomain], orderState => orderState.level);

export const selectLevelRules = createSelector([selectDomain], orderState => orderState.levelRules);

export const selectCurrentUnitId = createSelector([selectDomain], orderState => orderState.currentUnitId);

export const selectActiveStepCount = createSelector([selectDomain], orderState => orderState.activeStepCount);

export const selectReload = createSelector([selectDomain], orderState => orderState.reload);

export const selectOrderRateElements = createSelector([selectDomain], data => data.rateElements);

export const selectOrderFormValid = createSelector([selectDomain], orderState => orderState.isOrderFormValid);

export const selectOrderCreationProcessStep = createSelector(
  [selectDomain],
  orderState => orderState.orderCreationProcessStep,
);

export const selectEditUnitProcessStep = createSelector([selectDomain], orderState => orderState.editUnitProcessStep);

export const selectOrderDetailsUIView = createSelector([selectDomain], orderState => orderState.orderDetailsView);

export const selectIsStrikeOrder = createSelector([selectDomain], state =>
  isStrikOrder({
    division: state.orderDetailsView?.orderDetails.division,
    orderType: state.orderDetailsView?.orderDetails.orderType,
  }),
);

export const selectTypeAheadOptions = createSelector([selectDomain], orderState => orderState.typeAheadOptions);

export const selectUnitDetailsId = createSelector([selectDomain], state => state.orderUnitDetails);

export const selectOverrideCreditHold = createSelector([selectDomain], orderState => orderState.overrideCreditHold);

export const selectOrderStrike = createSelector([selectDomain], orderState => orderState.orderStrikeDetails);

export const selectStrikeUpdateStatus = createSelector([selectDomain], orderState => orderState.isStrikeDataUpdated);

export const selectRateCard = createSelector([selectDomain], data => ({
  rates: data.rateElements,
  ...data.rateCardView,
}));

export const selectOrderBonuses = createSelector([selectDomain], data => ({ orderBonuses: data.orderBonuses }));

export const selectOrderLicenses = createSelector([selectDomain], orderState => orderState.orderLicenses);

export const selectOrderSubCertification = createSelector(
  [selectDomain],
  orderState => orderState.subCertificationList,
);

export const selectOrderCertification = createSelector([selectDomain], orderState => orderState.orderCertification);

export const selectOrderDetailsOrderType = createSelector([selectDomain], orderState => orderState.orderTypes);

export const selectOrderRegions = createSelector([selectDomain], orderState => orderState.regions);

export const selectPreferenceChanged = createSelector([selectDomain], orderState => orderState.isPreferenceChanged);

export const selectConfirmationDialog = createSelector([selectDomain], orderState => orderState.confirmationDialog);

export const selectTabValue = createSelector([selectDomain], orderState => orderState.tabValue);

export const selectOrderBonusTab = createSelector([selectDomain], orderState => orderState.orderBonusTab);

export const selectSubCert = createSelector([selectDomain], data => data.selectedSubCert);

