import React from 'react';
import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCounts } from '../Store/PlacementReminders.selector';

const useStyles = makeStyles()({
  root: {
    flexGrow: 1,
  },
  icon: {
    color: '#52429A',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '2rem',
  },
  textContent: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    fontWeight: 450,
    lineHeight: '32px',
  },
  title: {
    fontSize: 14,
    color: '#666666',
  },
  count: {
    color: '#555555',
    fontSize: 36,
  },
  container: {
    flexWrap: 'nowrap',
    width: '100%',
  },
});

export const PlacementRemindersTotalSection = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { all } = useSelector(selectCounts);
  return (
    <>
      <div className={classes.root}>
        <Grid container xs={12} direction="row" classes={{ container: classes.container }}>
          <Grid item direction="column" classes={{ root: classes.container }}>
            <Grid item classes={{ root: classes.icon }}>
              <NotificationsActiveOutlinedIcon />
            </Grid>
            <Grid item classes={{ root: `${classes.title} ${classes.textContent}` }}>
              {t('home.credentialingWorkDesk.reminders')}
            </Grid>
            <Grid item classes={{ root: `${classes.count} ${classes.textContent}` }} aria-label="aggregate-count">
              {all}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
