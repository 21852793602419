import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { InnerValueSection } from 'app/components/Order/OrderCreationForm/DefineUnit/UnitPreview/Common/InnerValueSection';
import {
  PreviewContent,
  PreviewContentTitle,
} from 'app/components/Order/OrderCreationForm/DefineUnit/UnitPreview/UnitPreviewDesign';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';

const useStyles = makeStyles()({
  freeTextField: {
    '&:hover': {
      '& [data-id="edit-icon"]': {
        opacity: 1,
        transition: 'opacity 0.25s',
      },
    },
  },
  freeTextEdit: {
    opacity: 0,
    transition: 'opacity 0.25s',
  },
  editIcon: {
    height: '20px',
    width: '20px',
    cursor: 'pointer',
    color: '#8E9195',
  },
});

/**
 *
 * @param props Includes title and hoverable edit button as props. Takes a child component as props.children
 * @returns
 */
export const OrderDetailsCard = ({
  title,
  edit,
  ...props
}: {
  title: string;
  edit?: {
    disableEdit?: boolean;
    onEditClick: () => void;
  };
  [key: string]: any;
  id?: string;
}) => {
  const { classes } = useStyles();

  return (
    <PreviewContent variant="outlined" elevation={0}>
      <Grid container xs={12} className={classes.freeTextField}>
        <Grid container item>
          <Grid item style={{ flexGrow: 1 }}>
            <PreviewContentTitle>{title}</PreviewContentTitle>
          </Grid>
          {edit && !edit.disableEdit && (
            <Grid item className={classes.freeTextEdit} data-id="edit-icon">
              <EditIcon className={classes.editIcon} onClick={edit.onEditClick} />
            </Grid>
          )}
        </Grid>
        <Grid item>
          <InnerValueSection component={props.children} id={props.id} />
        </Grid>
      </Grid>
    </PreviewContent>
  );
};
