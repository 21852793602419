import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './NavigationProfiles.redux';

const selectDomain = (state: RootState) => state?.navigationProfiles || initialState;

export const selectNavigationProfiles = createSelector([selectDomain], results => results);

export const selectNavigationProfilesItems = createSelector([selectDomain], results => results?.items);
export const selectNavigationProfilesFlag = createSelector([selectDomain], results => results?.navigationFromGrid);
export const selectNavigationRowId = createSelector([selectDomain], results => results?.rowId);
