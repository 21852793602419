import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { all, call, fork, getContext, put, select } from 'redux-saga/effects';
import { SetPlacementFailRiskRequirementMessage } from 'store/redux-store/new-placement/saga';
import { placementStatusAction } from 'store/redux-store/placement-status/slice';
import InitPlacementSummary, { IConsolidatedRequirementResponse } from '../init.types';
import { IPlacementSummaryRequest, placementSummaryActions } from '../slice';
import { selectPlacementConsolidatedDetails } from '../consolidatedStoreSelectors';

export function* processbookingApprovedRequirements(
  response: IConsolidatedRequirementResponse<any>,
  context: InitPlacementSummary,
) {
  try {
    if (!httpSuccess(response?.status)) {
      context.setServiceFailureByKey({ key: 'bookingApprovedRequirements', failureStatus: true });
      return;
    }
    yield fork(common, response.data);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'processbookingApprovedRequirements',
        area: 'src/store/redux-store/placement-summary/common-saga/bookingApprovedRequirements.ts',
      },
    });
  }
}

export function* requestBookingApprovedRequirements(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if(action.payload.isInitLoad && consolidatedDetails){
      response = {
        status : consolidatedDetails.summary.booking?.bookingApprovedStatusDetails?.status,
        data :consolidatedDetails.summary.booking?.bookingApprovedStatusDetails?.data
      }
    }

    if(!response?.status || !response?.data){
      response = yield call(
        placementService.getBookingApprovedRequirements,
        action.payload.placementId,
        action.payload.placementOrderType,
      );
    }
    
    if (response && httpSuccess(response?.status)) {
      yield fork(common, response.data);
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestBookingApprovedRequirements',
        area: 'src/store/redux-store/placement-summary/common-saga/bookingApprovedRequirements.ts',
      },
    });
  }
}

function* common(data) {
  /** Clear all errors for -  getBookingApprovedRequirements */
  yield all(
    [
      IPlacementRequirementKey.inBookedStatus,
      IPlacementRequirementKey.pendingModification,
      IPlacementRequirementKey.userRole,
    ].map(id =>
      put(
        placementStatusAction.removeFailedRequirement({
          requirement: { id },
        }),
      ),
    ),
  );
  const { bookedStatus, pendingModification, userRole, nextStatusRequirement = [] } = data;
  if (bookedStatus)
    yield fork(SetPlacementFailRiskRequirementMessage, {
      payload: {
        messageId: bookedStatus?.messageId,
        version: bookedStatus?.messageVersion,
        passFailType: bookedStatus?.type,
        data: undefined,
        messageTypeId: IPlacementRequirementKey.inBookedStatus,
        tooltipTypeParam: undefined,
        nextStatusRequirementParam: nextStatusRequirement,
      },
      type: placementSummaryActions.setFailedRequirement.type,
    });

  if (pendingModification)
    yield fork(SetPlacementFailRiskRequirementMessage, {
      payload: {
        messageId: pendingModification?.messageId,
        version: pendingModification?.messageVersion,
        passFailType: pendingModification?.type,
        data: pendingModification?.data?.pendingModCount,
        messageTypeId: IPlacementRequirementKey.pendingModification,
        tooltipTypeParam: undefined,
        nextStatusRequirementParam: nextStatusRequirement,
      },
      type: placementSummaryActions.setFailedRequirement.type,
    });

  if (userRole)
    yield fork(SetPlacementFailRiskRequirementMessage, {
      payload: {
        messageId: userRole?.messageId,
        version: userRole?.messageVersion,
        passFailType: userRole?.type,
        data: undefined,
        messageTypeId: IPlacementRequirementKey.userRole,
        tooltipTypeParam: undefined,
        nextStatusRequirementParam: nextStatusRequirement,
      },
      type: placementSummaryActions.setFailedRequirement.type,
    });
}
