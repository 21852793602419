/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Divider, Grid, ThemeProvider, StyledEngineProvider,useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { UnitCardsSubTitle, UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { useTranslation } from 'react-i18next';
import { UnitCertification } from 'app/components/Unit/Qualification/unitCertification';
import { SimpleCheckbox } from '../Common/SimpleCheckbox';
import { UnitSkillCheckList } from 'app/components/Unit/Qualification/unitSkillCheckList';
import { FacilityResponse } from 'app/models/Facility/FacilityDetails';
import { EMR } from '../EMR/EMR';
import { QualificationType } from 'app/models/Unit/CreateUnit';
import { selectIsEditUnit } from 'app/components/Order/Store/Order.selectors';
import { useSelector } from 'react-redux';
import { selectOrderCreationProcessStep } from 'app/components/Order/Store/Order.selectors';
import { selectIsUnitSelected } from 'app/components/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';
import { useDecision } from '@optimizely/react-sdk';
import { ff_order_ui_skillssection } from 'app/constants/FeatureFlags/Order/Keys';
import { AMNDivisionType } from 'app/models/enums/AMNDivisionType';

interface Props {
  facilityState: FacilityResponse | null;
  onUnitPropChange: (property: string, value: any) => void;
  disableEdit?: boolean;
  qualificationData?: QualificationType | null;
}

const useStyles = makeStyles()(theme => ({
  titleRightPadding: {
    paddingRight: 23,
  },
  wrapper: {
    paddingTop: theme.spacing(2),
  },
  divider: {
    marginTop: '1%',
  },
}));

export const Qualifications = (props: Props) => {
  const { facilityState, onUnitPropChange, qualificationData, disableEdit } = props;
  const { classes } = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const initialData = qualificationData
    ? qualificationData
    : {
        activeLicense: false,
        compactLicense: false,
        emr: {
          isRequired: true,
          values: [],
        },
        licenses: [],
        certifications: [],
        skillChecklists: [],
      };

  const [qualification, setQualification] = React.useState<QualificationType>(initialData);
  const isOrderEditUnit = useSelector(selectIsEditUnit);
  const isUnitEditUnit = useSelector(selectIsUnitSelected);
  const isEditUnit = isUnitEditUnit ? isUnitEditUnit : isOrderEditUnit;
  const storeOrderCreationProcessStep = useSelector(selectOrderCreationProcessStep);
  const [skillsSectionDecision] = useDecision(ff_order_ui_skillssection);

  useEffect(() => {
    if (qualificationData) {
      setQualification(qualificationData);
    } else {
      if (!isEditUnit) {
        setQualification({
          activeLicense: false,
          compactLicense: false,
          emr: {
            isRequired: true,
            values: [],
          },
          licenses: [],
          certifications:
            (facilityState?.amnDivisionTypeId === AMNDivisionType.amnNurse ||
              facilityState?.amnDivisionTypeId === AMNDivisionType.allied) &&
            !storeOrderCreationProcessStep
              ? [
                  {
                    requirementTypeId: 47,
                    abbreviation: 'BLS',
                    isRequired: true,
                    documentAttachmentRequired: false,
                  },
                ]
              : [],
          skillChecklists: [],
        });
      }
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQualification({ ...qualification, [event.target.name]: event.target.checked });
    // onUnitPropChange('qualification', qualification);
  };

  const activeLicenseLabel = () => {
    return facilityState?.location.state
      ? 'Active ' + facilityState.location.state + ' License'
      : 'Active State License';
  };

  const onQualificationChange = (property, value) => {
    setQualification({ ...qualification, [property]: value });
  };

  useEffect(() => {
    onUnitPropChange('qualification', qualification);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qualification]);

  return (
    <div>
      {/* TODO: Remove this theme wrapper since it uses the global theme */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UnitDetailsBody variant="outlined">
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={1}>
                  <Grid item>
                    <UnitCardsTitle className={classes.titleRightPadding}>
                      {t('order.createUnit.qualifications.title')}
                    </UnitCardsTitle>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1} className={classes.wrapper}>
                  <Grid item>
                    <UnitCardsSubTitle className={classes.titleRightPadding}>
                      {t('order.createUnit.qualifications.certifications')}
                    </UnitCardsSubTitle>
                  </Grid>
                  <Grid item>
                    <SimpleCheckbox
                      id={'createUnitQualificationsCertificationsActiveLicenseCheck'}
                      isdisabled={disableEdit}
                      isChecked={qualification?.activeLicense}
                      handleChecked={handleChange}
                      checkboxLabel={activeLicenseLabel()}
                      checkboxName={'activeLicense'}
                    />
                  </Grid>
                  <Grid item>
                    <SimpleCheckbox
                      id={'createUnitQualificationsCertificationsCompactLicenseCheck'}
                      isChecked={qualification?.compactLicense}
                      handleChecked={handleChange}
                      isdisabled={disableEdit}
                      checkboxLabel={t('order.createUnit.qualifications.compactLicense')}
                      checkboxName={'compactLicense'}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1} className={classes.wrapper}>
                  <Grid item xs={12}>
                    {qualification && (
                      <UnitCertification
                        onCertificationPropChange={onQualificationChange}
                        division={facilityState?.amnDivisionTypeId}
                        isdisabled={disableEdit}
                        unitCertifications={qualification?.certifications}
                      />
                    )}
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
                {skillsSectionDecision.enabled && (
                  <>
                    <Grid container direction="row" spacing={1} className={classes.wrapper}>
                      <Grid item>
                        <UnitCardsSubTitle className={classes.titleRightPadding}>
                          {t('order.createUnit.qualifications.skills')}
                        </UnitCardsSubTitle>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={1} className={classes.wrapper}>
                      <Grid item xs={12}>
                        {qualification && (
                          <UnitSkillCheckList
                            division={facilityState?.amnDivisionTypeId}
                            onSkillChecklistPropChange={onQualificationChange}
                            isdisabled={disableEdit}
                            unitSkillCheckList={qualification?.skillChecklists}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                  </>
                )}
              </Grid>
              <Grid item xs={12} className={classes.wrapper}>
                {qualification && (
                  <EMR
                    onEmrPropChange={onQualificationChange}
                    isdisabled={disableEdit}
                    hasRadio={true}
                    EMRData={qualification?.emr}
                  />
                )}
              </Grid>
            </Grid>
          </UnitDetailsBody>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};
