import { LayoutGrid, LayoutGridItem } from '../LayoutGrid';
import React from 'react';
import { Divider, Theme, IconButton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { BreadcrumbNavigation } from '../../components/Common/BreadcrumbNavigation/BreadcrumbNavigation';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    flexWrap: 'nowrap',
  },
  iconButton: {
    padding: '0 6px',
    marginTop: 4,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    color: '#333333',
    whiteSpace: 'nowrap',
  },
  arrowButton: {
    display: 'flex',
    fontSize: 30,
  },
  arrowButtonLabel: {
    fontSize: 19,
    color: '#52429a',
    paddingLeft: 5,
  },
}));

export const DetailsPageGrid = ({
  title,
  breadcrumbs,
  iconButtons,
  ...props
}: {
  [key: string]: any;
  title?: string;
  breadcrumbs?: any[];
  iconButtons?: React.ReactNode[];
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <LayoutGrid item direction="column" classes={{ container: classes.container }}>
      <LayoutGridItem xs={12}>
        {breadcrumbs && <BreadcrumbNavigation isOwnPageBreadcrumb breadcrumbHierarchy={breadcrumbs} />}
      </LayoutGridItem>
      <Divider style={{ margin: '0 6px' }} />
      <LayoutGridItem container justifyContent="space-between">
        <LayoutGridItem container sm={3} spacing={1} direction="row" classes={{ container: classes.container }}>
          <LayoutGridItem classes={{ root: classes.title }}>{title}</LayoutGridItem>
          {iconButtons ? iconButtons.map(btn => <LayoutGridItem>{btn}</LayoutGridItem>) : null}
        </LayoutGridItem>
        <IconButton
          color="primary"
          disableTouchRipple
          disableFocusRipple
          style={{ background: 'none' }}
          classes={{ root: `${classes.iconButton} ${classes.arrowButton}` }}
          aria-label="back-button"
          onClick={() => props.handleNavigateBack()}
          size="small">
          <ArrowBackIcon />
          <div className={classes.arrowButtonLabel}> {t('candidate.jobMatch.backButtonText')}</div>
        </IconButton>
      </LayoutGridItem>
      <LayoutGridItem>{props.children}</LayoutGridItem>
    </LayoutGrid>
  );
};
