import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./consolidatedStoreSlice";
import { RootState } from 'types';
import { PlacementOrderType } from "app/models/Placement/PlacementDetails";


const selectDomain = (state: RootState) => state.placementSummaryConsolidatedStore || initialState;

export const selectPlacementConsolidatedSummary = createSelector([selectDomain], summary => summary);

export const selectPlacementConsolidatedPlacementId = createSelector([selectDomain], state => state.placementId);

export const selectPlacementConsolidatedDetails = createSelector([selectDomain], state => state?.consolidatedResult);

export const selectPlacementConsolidatedOrderType = createSelector([selectDomain], state => {
    if(state?.consolidatedResult?.placementOrderType !== null && state?.consolidatedResult?.placementOrderType !== undefined){
        if(state?.consolidatedResult?.placementOrderType === 1){
            return PlacementOrderType.StrikeOrderInHsg
        }
        else{
            return PlacementOrderType.Default
        }
    }
    else{
        return null;
    }
});

export const selectInitialDetailsLoadState = createSelector([selectDomain], state => state?.stage0);

export const selectBulkDetailsLoadState = createSelector([selectDomain], state => state?.stage1);

export const selectLoadingCompletedStatus = createSelector([selectDomain], state => state?.isLoadingCompleted ?? false);
export const selectErrorStatus = createSelector([selectDomain], state => state?.isError ?? false);
export const selectErrorMessage = createSelector([selectDomain], state => state?.message);