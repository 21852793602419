import axios, { AxiosRequestConfig } from 'axios';

export class TaskService {
  private taskBase: string;

  private cancellationToken = axios.CancelToken.source();

  private static currentInstance: TaskService;
  
  config?: AxiosRequestConfig = {};

  private constructor() {
    this.taskBase = globalThis?.app?.env?.REACT_APP_TASKS_SERVICE_BASE_URL;
  }

  static createInstance(): TaskService {
    const activeInstance = new TaskService();
    // activeInstance.cancellationToken = axios.CancelToken.source();
    // if (activeInstance.config) activeInstance.config.cancelToken = activeInstance.cancellationToken.token;
    return activeInstance;
  }

  static getInstance(): TaskService {
    if (!this.currentInstance) this.currentInstance = this.createInstance();
    return this.currentInstance;
  }

  static cancelActiveRequests() {
    this.currentInstance.cancellationToken.cancel('RequestCancellation');
    // this.currentInstance = null;
  }

  cancelRequests() {
    this.cancellationToken.cancel('RequestCancellation');
    return TaskService.getInstance();
  }

  cancelAndRevokeInstance() {
    this.cancellationToken.cancel('RequestCancellation');
    this.cancellationToken = axios.CancelToken.source();
    if (this.config) this.config.cancelToken = this.cancellationToken.token;
  }
}
