import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { useFormContext, useWatch } from 'react-hook-form';
import { useEditableRequirement } from './EditRequirementsHelper';
import { placementDateCheck } from './EditablePassFailLogic';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectPlacementOrderType,
  selectResetForm,
} from 'store/redux-store/placement-details/selectors';
import { selectPlacementStatus } from 'store/redux-store/placement-status/selectors';
import { isTA } from 'app/components/Placement/NextPlacementStatus/StatusDefaults';
export const useEditableRequirementPlacementDate = () => {
  const placementOrderType = useSelector(selectPlacementOrderType);
  const resetForm = useSelector(selectResetForm);

  const { control } = useFormContext();

  const placementStartDate = useWatch({ name: 'placementStartDate', control }) as any;
  const placementEndDate = useWatch({ name: 'placementEndDate', control }) as any;

  const data = React.useMemo(
    () => ({ startDate: placementStartDate, endDate: placementEndDate }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [placementStartDate, placementEndDate, resetForm],
  );
  const secondaryData = React.useMemo(() => ({ placementOrderType: placementOrderType }), [placementOrderType]);
  const { currentPlacementStatus } = useSelector(selectPlacementStatus);
  useEditableRequirement({
    data,
    secondaryData,
    requirementId: IPlacementRequirementKey.placementDate,
    requirementKey: 'placementDate',
    requirementCheck: placementDateCheck,
    formReset: resetForm,
    failRequirement: {
      id: IPlacementRequirementKey.placementDate,
      message: `Placement Date: Missing`,
      tooltip: {
        message: isTA(currentPlacementStatus)
          ? `Placement Date is required (Must update from Options in AMIE Classic).`
          : `Placement Date is required`,
      },
    },
  });
};
