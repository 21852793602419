import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { trackPromise } from 'react-promise-tracker';
import { call, fork, getContext, put, all, select } from 'redux-saga/effects';
import { SetPlacementFailRiskRequirementMessage } from 'store/redux-store/new-placement/saga';
import { placementDetailsAction } from 'store/redux-store/placement-details/slice';
import { placementStatusAction } from 'store/redux-store/placement-status/slice';
import InitPlacementSummary, { IConsolidatedRequirementResponse } from '../init.types';
import { ServiceConstants } from '../sagaHelper';
import { IPlacementSummaryRequest, placementSummaryActions } from '../slice';
import { selectPlacementConsolidatedDetails } from '../consolidatedStoreSelectors';

const TrackBookingCredentialingWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-offer-credentialing-data');

export function* processBookingCredentialingRequirement(
  response: IConsolidatedRequirementResponse<any>,
  context: InitPlacementSummary,
) {
  try {
    if (!httpSuccess(response?.status)) {
      context.setServiceFailureByKey({ key: 'bookingCredentialingRequirement', failureStatus: true });
      return;
    }

    yield fork(common, response.data);

    context.setBookingCredentialingInfo(response.data);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'processBookingCredentialingRequirement',
        area: 'src/store/redux-store/placement-summary/common-saga/bookingCredentialingRequirement.ts',
      },
    });
  }
}

export function* requestBookingCredentialingRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if(action.payload.isInitLoad && consolidatedDetails){
      response = {
        status : 200,
        data :{
          bookingCredentialing : {
            gtgComplete : consolidatedDetails.summary.booking.bookingCredentialingRequrement?.gtgComplete?.data,
            finalizedByQs : consolidatedDetails.summary.booking.bookingCredentialingRequrement?.finalizedByQs?.data,
            nextStatusRequirement : consolidatedDetails.summary.booking.bookingCredentialingRequrement?.nextStatusRequirement,
          } 
        } 
      }
    }

    if(!response){
      response = yield call(
        TrackBookingCredentialingWrapper,
        placementService.getStrikeRequirementsByContainer,
        action.payload.placementId,
        ...ServiceConstants.bookingCredentialing,
      );
    }
    
    if (httpSuccess(response?.status)) {
      yield fork(common, response.data.bookingCredentialing);
      yield put(placementSummaryActions.setBookingCredentialingInfo(response.data.bookingCredentialing));
      yield put(placementDetailsAction.resetForm());
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestBookingCredentialingRequirement',
        area: 'src/store/redux-store/placement-summary/common-saga/bookingCredentialingRequirement.ts',
      },
    });
  }
}

function* common(data) {
  yield all(
    [IPlacementRequirementKey.gtgComplete, IPlacementRequirementKey.finalizedByQS].map(id =>
      put(
        placementStatusAction.removeFailedRequirement({
          requirement: { id },
        }),
      ),
    ),
  );
  // Good to Go Complete
  yield fork(SetPlacementFailRiskRequirementMessage, {
    payload: {
      messageId: 2035,
      version: 'v1',
      passFailType: data.gtgComplete?.type,
      data: data.gtgComplete,
      messageTypeId: IPlacementRequirementKey.gtgComplete,
      tooltipTypeParam: undefined,
      nextStatusRequirementParam: data.nextStatusRequirement,
    },
    type: placementSummaryActions.setFailedRequirement.type,
  });

  // Finalized by QS
  yield fork(SetPlacementFailRiskRequirementMessage, {
    payload: {
      messageId: 2036,
      version: 'v1',
      passFailType: data.finalizedByQs?.type,
      data: data.finalizedByQs,
      messageTypeId: IPlacementRequirementKey.finalizedByQS,
      tooltipTypeParam: undefined,
      nextStatusRequirementParam: data.nextStatusRequirement,
    },
    type: placementSummaryActions.setFailedRequirement.type,
  });
}
