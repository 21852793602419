import React, { ReactElement } from 'react';
import Travel from './Travel';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import Collections from './Collections';
import { SkillSetCollections } from 'app/enums/Common';
import { SkillSetTypes } from '@AMIEWEB/Unit/Common/StatusTile';
import { ISkillSet } from '@AMIEWEB/Facility/FacilityStore/Facility.model';

interface IQualificationSections {
  type: string;
  component: ReactElement;
}

const useStyles = makeStyles()({
  qualificationTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '18px',
    '&.MuiTypography-root': {
      fontSize: '16px',
    },
  },
});

const SkillSetQualifications = ({ skillSetData }: { skillSetData: ISkillSet }) => {
  const { classes } = useStyles();
  const qualificationSections: IQualificationSections[] = [
    skillSetData?.travelExperience?.experienceTypeId
      ? {
          type: SkillSetTypes.Travel,
          component: <Travel data={skillSetData?.travelExperience || {}} />,
        }
      : null,
    {
      type: SkillSetTypes.Licenses,
      component: (
        <Collections
          data={
            skillSetData?.collections?.find(collection => collection.type === SkillSetCollections.Licenses) ?? {
              type: null,
              operation: null,
              requirements: [],
            }
          }
        />
      ),
    },
    {
      type: SkillSetTypes.Certifications,
      component: (
        <Collections
          data={
            skillSetData?.collections?.find(collection => collection.type === SkillSetCollections.Certifications) ?? {
              type: null,
              operation: null,
              requirements: [],
            }
          }
        />
      ),
    },
  ].filter(Boolean);

  return (
    <LayoutGrid container columnSpacing={10} p={2}>
      {qualificationSections.map(section => (
        <LayoutGridItem item xs={12 / qualificationSections?.length} key={section.type}>
          <Typography className={classes.qualificationTitle}>{section.type}</Typography>
          {section.component}
        </LayoutGridItem>
      ))}
    </LayoutGrid>
  );
};

export default SkillSetQualifications;
