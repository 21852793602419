import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { pipelineChoiceKeys } from 'app/components/Common/WorkDesk/PipelineChoice/pipelineChoice.redux';
import { IGraphicStepper } from 'app/ComponentLibrary/GraphicStepper/GraphicStepper';
import { selectCredPlacementsStatusGrid } from 'app/components/Common/WorkDesk/PipelineChoice/pipelineChoice.selectors';
import { placementStatus, placementSubStatuses } from 'app/components/RecruiterWorkDesk/MyPlacementsDesk/utils';
import { useTranslation } from 'react-i18next';
import { PlacementTotalSection } from './PlacementTotalSection';
import { selectPipelineCounts } from '../Store/PlacementStatus.selector';
import { WorkDeskPipeline } from '../../WorkDesk/WorkDeskPipeline';
import { useTheme } from 'amn-ui-core';

export const PlacementPipeline = props => {
  const { t } = useTranslation();
  const [pipelineCounts, setPipelineCounts] = useState<IGraphicStepper[]>([]);
  const counts = useSelector(selectPipelineCounts);
  const pipelineSelection = useSelector(selectCredPlacementsStatusGrid);
  const theme = useTheme();

  const getTotalCount = totalValues => {
    let count = 0;
    if (totalValues.length === 1) {
      return totalValues;
    } else
      totalValues.forEach(value => {
        count = count + value;
      });

    return count;
  };

  useEffect(() => {
    setPipelineCounts([
      {
        category: {
          tag: `${t('home.recruiterWorkDesk.offer')} (${getTotalCount([
            counts.offerByFacility,
            counts.offerBookedByTraveler,
            counts.facilityConfirms,
            counts.travelerAccepts,
          ])})`,
          showTooltip: getTotalCount([
            counts.offerByFacility,
            counts.offerBookedByTraveler,
            counts.facilityConfirms,
            counts.travelerAccepts,
          ])
            ? false
            : true,
          value: placementStatus.offer,
          color: 'rgb(0, 139, 149)',
        },
        subcategory: [
          {
            tag: t('home.recruiterWorkDesk.obf'),
            data: counts.offerByFacility,
            value: placementSubStatuses.obf,
          },
          {
            tag: t('home.recruiterWorkDesk.obbt'),
            data: counts.offerBookedByTraveler,
            value: placementSubStatuses.obbt,
          },
          {
            tag: t('home.recruiterWorkDesk.fc'),
            data: counts.facilityConfirms,
            value: placementSubStatuses.fc,
          },
          {
            tag: t('home.recruiterWorkDesk.ta'),
            data: counts.travelerAccepts,
            value: placementSubStatuses.ta,
          },
        ],
      },
      {
        category: {
          tag: `${t('home.recruiterWorkDesk.booked')} (${getTotalCount([
            counts.bookedSubCategory,
            counts.bookingApproved,
            counts.waitingGoodToGo,
            counts.postponementWarning,
            counts.goodToGo,
          ])})`,
          showTooltip: getTotalCount([
            counts.bookedSubCategory,
            counts.bookingApproved,
            counts.waitingGoodToGo,
            counts.postponementWarning,
            counts.goodToGo,
          ])
            ? false
            : true,
          value: placementStatus.booked,
          color: '#003C69',
        },
        subcategory: [
          {
            tag: t('home.recruiterWorkDesk.booked'),
            data: counts.bookedSubCategory,
            value: placementSubStatuses.booked,
          },
          {
            tag: t('home.recruiterWorkDesk.ba'),
            data: counts.bookingApproved,
            value: placementSubStatuses.ba,
          },
          {
            tag: t('home.recruiterWorkDesk.wgtg'),
            data: counts.waitingGoodToGo,
            value: placementSubStatuses.wgtg,
          },
          {
            tag: t('home.recruiterWorkDesk.ppw'),
            data: counts.postponementWarning,
            value: placementSubStatuses.ppw,
          },
          {
            tag: t('home.recruiterWorkDesk.gtg'),
            data: counts.goodToGo,
            value: placementSubStatuses.gtg,
          },
        ],
      },
      {
        category: {
          tag: `${t('home.recruiterWorkDesk.onAssignment')} (${counts.onAssignment})`,
          showTooltip: counts.onAssignment ? false : true,
          value: placementStatus.onAssignment,
          color: theme.palette.framework.alertColors.success,
        },
        subcategory: [
          {
            tag: t('home.recruiterWorkDesk.oa'),
            data: counts.onAssignment,
            value: placementSubStatuses.oa,
          },
        ],
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counts]);

  return (
    <WorkDeskPipeline
      data={pipelineCounts}
      selection={pipelineSelection}
      gridSelectionKey={pipelineChoiceKeys.CRED_PLACEMENT_STATUS_WDG}
      noValuePipelineText={t('home.credentialingWorkDesk.pipeline.noValueText')}
      pipelineTotal={<PlacementTotalSection />}
      shrink={props.stickChosen !== null}
      isCredentialing={true}
    />
  );
};
