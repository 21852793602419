import React from 'react';
import { Divider, Grid, Typography } from 'amn-ui-core';
import { missingField } from 'app/constants';
import styledComponent from 'styled-components';
import { useTranslation } from 'react-i18next';
import { GridCellParams } from '@mui/x-data-grid-pro';

export const XGridHistoricalHoverView = (params: GridCellParams) => {
  /** Memoization prevents first level component from using hooks */
  return <HoverViewContent params={params} />;
};

const HoverViewContent = ({ params }: { params: GridCellParams }) => {
  const { t } = useTranslation();

  return (
    <Grid container style={{ padding: '12px' }}>
      <Grid item container xs={6} alignContent="flex-start">
        <Grid item xs={12}>
          <HeaderTypography variant="body1">{t('search.xGrid.hoverViewRenderer.historical.oldValue')}</HeaderTypography>
          <HR />
        </Grid>
        <Grid item xs={12}>
          <div style={{ padding: '6px 0' }}>
            <UnorderedList list={params.row.oldValue} />
          </div>
        </Grid>
      </Grid>
      <Grid item container xs={6}>
        <Grid item xs={12}>
          <div style={{ paddingLeft: '6px' }}>
            <HeaderTypography variant="body1">
              {t('search.xGrid.hoverViewRenderer.historical.newValue')}
            </HeaderTypography>
            <HR />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ padding: '6px 0 6px 6px' }}>
            <UnorderedList list={params.row.newValue} />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

const UnorderedList = ({ list }) =>
  Array.isArray(list) && list.length > 0 ? (
    <ul>
      {list.map(item => (
        <li style={{ minWidth: '50px' }}>
          <Typography variant="body2">{item && item !== '' ? item : missingField}</Typography>
        </li>
      ))}
    </ul>
  ) : (
    <Typography variant="body2">{list}</Typography>
  );

const HR = styledComponent(Divider)`
  width: 95%;
`;

const HeaderTypography = styledComponent(Typography)`
  font-weight: 500;
`;
