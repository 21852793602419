import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { trackPromise } from 'react-promise-tracker';
import { put, getContext, fork, call, select } from 'redux-saga/effects';
import { SetPlacementFailRiskRequirementMessage } from 'store/redux-store/new-placement/saga';
import { placementStatusAction } from 'store/redux-store/placement-status/slice';
import { resetFailureStatus } from '../saga';
import { extractVirtualInterviewOrderDisplay, extractVirtualInterviewCandidateDisplay } from '../sagaHelper';
import { IPlacementSummaryRequest, placementSummaryActions } from '../slice';
import InitPlacementSummary, { IConsolidatedRequirementResponse } from '../init.types';
import { selectPlacementConsolidatedDetails } from '../consolidatedStoreSelectors';

const TrackVirtualInterviewInfoWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-interview-info-vi-requirement');

export function* requestVirtualInterviewRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('virtualInterviewRequirement'));
    const placementService: PlacementService = yield getContext('placementService');
    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if(action.payload.isInitLoad && consolidatedDetails){
      response = {
        status : consolidatedDetails.summary.submission?.orderSubmissionBusinessRequrement?.virtualInterview?.status,
        data :consolidatedDetails.summary.submission?.orderSubmissionBusinessRequrement?.virtualInterview?.data
      }
    }

    if(!response?.status || !response?.data){
      response = yield call(
        TrackVirtualInterviewInfoWrapper,
        placementService.getVirtualInterviewRequirement,
        action.payload.placementId,
      );
    }

    if (httpSuccess(response?.status)) {
      const orderView = extractVirtualInterviewOrderDisplay(response.data.orderRequirement.data);
      const candidate = extractVirtualInterviewCandidateDisplay(response.data.candidateRequirement.data);

      yield fork(common, response.data);
      yield put(
        placementSummaryActions.setVirtualInterviewRequirement({
          type: response.data.orderRequirement.type,
          order: { data: { ...response.data.orderRequirement.data }, view: orderView },
          candidate,
        }),
      );
      yield put(
        placementSummaryActions.setSubmissionInfoByKey({
          key: 'virtualInterview',
          amnRequirement: null,
          orderRequirement: { ...response.data.orderRequirement },
          candidateRequirement: null,
          isOrderReq: true,
        }),
      );
    } else
      yield put(
        placementSummaryActions.setServiceFailureByKey({
          key: 'virtualInterviewRequirement',
          failureStatus: true,
        }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestVirtualInterviewRequirement',
        area: 'src/store/redux-store/placement-summary/common-saga/virtualInterviewRequirement.ts',
      },
    });
  }
}

export function* processVirtualInterviewRequirement(
  response: IConsolidatedRequirementResponse<any>,
  context: InitPlacementSummary,
) {
  try {
    if (!httpSuccess(response?.status)) {
      context.setServiceFailureByKey({ key: 'virtualInterviewRequirement', failureStatus: true });
      return;
    }

    const orderView = extractVirtualInterviewOrderDisplay(response.data.orderRequirement.data);
    const candidate = extractVirtualInterviewCandidateDisplay(response.data.candidateRequirement.data);

    context.setVirtualInterviewRequirement({
      type: response.data.orderRequirement.type,
      order: { data: { ...response.data.orderRequirement.data }, view: orderView },
      candidate,
    });
    context.setSubmissionInfoByKey({
      key: 'virtualInterview',
      amnRequirement: null,
      orderRequirement: { ...response.data.orderRequirement },
      candidateRequirement: null,
      isOrderReq: true,
    });
    yield fork(common, response.data);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'processVirtualInterviewRequirement',
        area: 'src/store/redux-store/placement-summary/common-saga/virtualInterviewRequirement.ts',
      },
    });
  }
}

function* common(data) {
  yield put(
    placementStatusAction.removeWarningRequirement({
      requirement: { id: IPlacementRequirementKey.virtualInterview },
    }),
  );
  const statusRequirementDetails = data?.orderRequirement?.data?.virtualRatingSubmissionRequirement
    ? data.nextStatusRequirement
    : data.nextStatusRequirement?.filter(x => x?.activityTypeId !== 101);
  yield fork(SetPlacementFailRiskRequirementMessage, {
    payload: {
      messageId: data?.orderRequirement?.messageId,
      version: data?.orderRequirement?.messageVersion,
      passFailType: data?.orderRequirement?.type,
      data: undefined,
      messageTypeId: IPlacementRequirementKey.virtualInterview,
      tooltipTypeParam: undefined,
      nextStatusRequirementParam: statusRequirementDetails,
    },
    type: placementSummaryActions.setFailedRequirement.type,
  });
}
