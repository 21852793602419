import { MFSOpts, PaysGwwOpts, PositionOpts } from '../../Common/OptionsHelper';
import { dropDownFilterOptions, getFacilitySearchLookups } from '@AMIEWEB/GlobalSearch/helper';
import { getFilteredCities, upgradeBasedOnSearchOption } from 'app/components/GlobalSearch/Candidate/FilterUtils';

import { AutocompleteChangeReason } from 'amn-ui-core';
import { FilterCompTypes } from 'app/ComponentLibrary/Filter/utils';
import { getSearchLookups } from 'app/services/SharedServices/SharedServices';
import i18next from 'i18next';

export enum OrderFilterNames {
  oid = 'orderIds',
  facility = 'facilityIds',
  division = 'divisionIds',
  discipline = 'disciplineIds',
  specialty = 'specialtyIds',
  subSpecialty = 'subSpecialtyIds',
  facilityStatus = 'facilityStatusIds',
  orderType = 'orderTypeIds',
  orderPriority = 'orderPriorityIds',
  startDateRange = 'orderStartDateRange',
  orderAgeDateRange = 'dateEnteredRange',
  orderEndDateRange = 'endDateRange',
  positions = 'positions',
  state = 'states',
  city = 'city',
  paysGww = 'paysGww',
  mfs = 'maxFilesSentId',
  shift = 'shiftIds',
  accountManager = 'amUserIds',
}

export class OrderFilterSpecs {
  constructor(public filterSpecs: any, public gridStateFilters: any) {}

  getOrderFilterSpecs = () => {
    return [
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.orderId'),
        name: OrderFilterNames.oid,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => getSearchLookups('OID', key),
        isMultiSelect: true,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.facility'),
        name: OrderFilterNames.facility,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: getFacilitySearchLookups,
        isMultiSelect: true,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.division'),
        name: OrderFilterNames.division,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        options: this.filterSpecs?.divisionOpts,
        selectAllValue: 'All',
      },
      {
        filterName: i18next.t('search.globalSearch.placement.filterNames.discipline'),
        name: OrderFilterNames.discipline,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        options: this.filterSpecs.disciplineOpts,
        selectAllValue: 'All',
      },
      {
        filterName: i18next.t('search.globalSearch.placement.filterNames.specialty'),
        name: OrderFilterNames.specialty,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        options: this.filterSpecs.specialtiesOpts,
        selectAllValue: 'All',
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.subSpecialty'),
        name: OrderFilterNames.subSpecialty,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        options: this.filterSpecs.subSpecialtiesOpts,
        selectAllValue: 'All',
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.facilityStatus'),
        name: OrderFilterNames.facilityStatus,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        options: this.filterSpecs.facilityStatusesOpts,
        selectAllValue: 'All',
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.type'),
        name: OrderFilterNames.orderType,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        options: this.filterSpecs.orderTypesOpts,
        selectAllValue: 'All',
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.orderPriority'),
        name: OrderFilterNames.orderPriority,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        options: this.filterSpecs.orderPrioritiesOpts,
        selectAllValue: 'All',
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.orderAgeDateRange'),
        name: OrderFilterNames.orderAgeDateRange,
        placeholder: i18next.t('search.globalSearch.order.filterNames.orderAgeDateRange'),
        version3: true,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: i18next.t('search.globalSearch.order.filterNames.orderAgeDateRange'),
        useMaxWidth: true,
        maxWidth: 210,
        clickAway: true,
        disablePortal: true,
        quickSelect: false,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.startDateRange'),
        name: OrderFilterNames.startDateRange,
        placeholder: i18next.t('search.globalSearch.order.filterNames.startDateRange'),
        version3: true,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: i18next.t('search.globalSearch.order.filterNames.startDateRange'),
        useMaxWidth: true,
        maxWidth: 210,
        clickAway: true,
        disablePortal: true,
        quickSelect: false,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.orderEndDateRange'),
        name: OrderFilterNames.orderEndDateRange,
        placeholder: i18next.t('search.globalSearch.order.filterNames.orderEndDateRange'),
        version3: true,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: i18next.t('search.globalSearch.order.filterNames.orderEndDateRange'),
        useMaxWidth: true,
        maxWidth: 210,
        clickAway: true,
        disablePortal: true,
        quickSelect: false,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.positions'),
        name: OrderFilterNames.positions,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        options: PositionOpts,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.state'),
        name: OrderFilterNames.state,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        options: this.filterSpecs.statesOpts,
        selectAllValue: 'All',
        compactValues: this.filterSpecs.compactStates,
        // eslint-disable-next-line @typescript-eslint/default-param-last
        handleChangeCallback: (newValue = [], reason: AutocompleteChangeReason, { option }, onChange) => {
          if (option.value === 'All') {
            onChange(reason === 'selectOption' ? this.filterSpecs.statesOpts : []);
            return;
          }
          const compactValuesSelected = newValue.reduce(
            (results, item) => (item.isCompactOpt ? [...results, ...item.value] : results),
            [],
          );
          let newSelections = this.filterSpecs.statesOpts.reduce((results, item) => {
            let isItemSelected =
              newValue.some(x => x.value === item.value) || compactValuesSelected.some(x => x.value === item.value);

            return isItemSelected ? [...results, item] : results;
          }, []);

          if (reason === 'removeOption' && option.isCompactOpt) {
            newSelections = newSelections.reduce(
              (results, item) => (option.value.some(opt => opt.name === item.name) ? results : [...results, item]),
              [],
            );
            const compactValuesPresent = newSelections.filter(item => item?.isCompactOpt === true);
            compactValuesPresent.forEach(opt => {
              newSelections = [...newSelections, ...opt.value];
            });
            const uniqueValues = newSelections.filter((val, index, self) => {
              return self.findIndex(p => p.value === val.value) === index;
            });
            newSelections = uniqueValues;
          } else if (reason === 'removeOption' && !option.isCompactOpt) {
            const parentIndex = newSelections.findIndex(
              item => item.isCompactOpt && item.value.some(x => x.value === option.value),
            );
            parentIndex !== -1 && newSelections.splice(parentIndex, 1);

            const childIndex = newSelections.findIndex(item => item.value === option.value);
            childIndex !== -1 && newSelections.splice(childIndex, 1);
          }
          onChange(newSelections);
        },
        filterOptions: (options, { inputValue }) => {
          return dropDownFilterOptions(options, inputValue);
        },
      },
      {
        filterName: i18next.t('search.globalSearch.candidate.filterNames.city'),
        name: OrderFilterNames.city,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async (key: string) => {
          if (!key) return getFilteredCities(this.filterSpecs.cityOptions, key);
          const cityOptions = await upgradeBasedOnSearchOption(key, 'c', this.gridStateFilters);
          return cityOptions;
        },
        isMultiSelect: true,
        selectAllValue: 'All',
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.paysGww'),
        name: OrderFilterNames.paysGww,
        placeholder: i18next.t('search.globalSearch.order.filterNames.paysGww'),
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        options: PaysGwwOpts,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.mfs'),
        name: OrderFilterNames.mfs,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        options: MFSOpts,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.shift'),
        name: OrderFilterNames.shift,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        options: this.filterSpecs.shiftOpts,
        selectAllValue: 'All',
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.accountManager'),
        name: OrderFilterNames.accountManager,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        options: this.filterSpecs.accountManagerOpts,
        selectAllValue: 'All',
      },
    ];
  };
}
