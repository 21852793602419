import { ArrivalDate } from 'app/components/Placement/PlacementDetails/Edit/Components/ArrivalDate';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectEditableRequirementTypes } from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { TransportationRow } from './TransportationTable';

export const TransportationArrivalDate = () => {
  const { t } = useTranslation();
  const { arrivalDate } = useSelector(selectEditableRequirementTypes);

  return (
    <TransportationRow
      id="placement-offer-transportation-arrivalDate"
      label={t('placement.profile.summary.offer.transportation.arrivalDate')}
      controlComponent={<ArrivalDate />}
      requirement={arrivalDate?.requirement}
    />
  );
};
