import { Badge, Box, Grid, Tab, Tabs, TabsProps, Theme, Typography } from 'amn-ui-core';
import * as React from 'react';
import { makeStyles } from 'tss-react/mui';
import queryString from 'query-string';
import { trackEvent } from 'app-insights/appInsightsTracking';
import { TabPanelContainer } from './TabPanelContainer';
import { useHistory, useLocation } from 'react-router-dom';

export enum Orientations {
  horizontal = 'horizontal',
  vertical = 'vertical',
  verticalList = 'verticalList',
}

const useStyles = makeStyles<{ orientation: string }>()((theme: Theme, { orientation }) => ({
  root: {
    flex: 1,
  },
  tab: {
    textTransform: 'none',
    minWidth: 'fit-content',
    width: orientation === Orientations.horizontal ? 'fit-content' : '100%',
  },

  verticalTabBorder: {
    border: `1px solid ${theme.palette.components.tabs.border.color}`,
    borderRadius: '4px',
    padding: '24px',
    position: 'sticky',
    marginTop: '0px',
  },
  title: {
    color: theme.palette.components.tabs.title.color,
  },
  addOnGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  horizontalBorderBottom: {
    borderBottom: `1px solid ${theme.palette.components.tabs.border.color}`,
  },
}));

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type TabType = {
  id?: string | number;
  label?: string;
  component?: any;
  description?: string;
  value?: number;
  getSelectedValue?: Function;
  isHover?: boolean;
  options?: any;
  onClick?: any;
  disabled?: boolean;
  notification?: {
    value?: string;
  };
  subTabs?: GenericTabsProps;
};

export interface GenericTabsProps extends Omit<TabsProps, 'orientation'> {
  tabs?: TabType[];
  title?: string;
  orientation?: 'horizontal' | 'vertical' | 'verticalList';
  rowSpacing?: number | string;
  columnSpacing?: number | string;
  modifyUrl?: boolean;
  dense?: boolean;
  analytics?: {
    name: string;
  };
  bottomBorder?: boolean;
  hideBorder?: boolean;
  version1?: boolean;
  hidePanel?: boolean;
  addOnComponent?: JSX.Element;
  customTabPanel?: React.CSSProperties;
  tabPanelContainer?: React.CSSProperties;
  customHandleChange?: (event: React.SyntheticEvent<Element, Event>, val: number) => void;
  onTabChanged?: (val: number) => void;
}

const deriveInitTab = numOfTabs => {
  const tab = parseInt(queryString.parse(window.location.search).tab);
  const isTabSelectionValid = tab !== null && tab !== undefined && !isNaN(tab) && tab >= 0 && tab < numOfTabs;
  return isTabSelectionValid ? tab : 0;
};

export const GenericTabs = ({
  tabs,
  modifyUrl,
  orientation = Orientations.horizontal,
  customTabPanel,
  tabPanelContainer,
  title,
  value,
  addOnComponent,
  version1 = false,
  hidePanel = false,
  bottomBorder = true,
  hideBorder = false,
  analytics,
  customHandleChange,
  ...rest
}: GenericTabsProps) => {
  const [_value, setValue] = React.useState(orientation === Orientations.horizontal ? deriveInitTab(tabs?.length) : 0);
  const { classes, cx } = useStyles({ orientation });
  const panelRefs = React.useRef([]);
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    if (modifyUrl && orientation === Orientations.horizontal) {
      setValue(deriveInitTab(tabs?.length));
    } else {
      if (value) setValue(value);
      else setValue(0);
    }
  }, [history.location.search, modifyUrl, tabs?.length, value]);

  const scrollToSelectedTab = (val: number) => {
    panelRefs?.current[val]?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleChange = React.useCallback(
    (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
      if (modifyUrl && newValue !== _value) {
        if (version1) {
          const searchParam = new URLSearchParams({ tab: newValue.toString() });
          history.push({ pathname: history.location.pathname, search: searchParam.toString(), state: location?.state });
        } else history.push({ pathname: history.location.pathname, search: `tab=${newValue}`, state: location?.state });
      } else setValue(newValue);

      if (analytics && tabs) {
        trackEvent({ type: 'click', name: `tabChange:${analytics.name}`, properties: { tab: tabs[newValue].label } });
      }
      if (orientation === Orientations.verticalList) {
        scrollToSelectedTab(newValue);
      }
      rest?.onTabChanged?.(newValue);
      rest?.onChange?.(event, newValue);
    },
    [analytics, history, location?.state, modifyUrl, orientation, rest, tabs, _value, version1],
  );

  const addPanelRef = element => {
    if (element && !panelRefs.current.includes(element)) {
      panelRefs.current.push(element);
    }
  };

  return (
    <Box className={classes.root}>
      <Grid container xs direction={orientation === Orientations.horizontal ? 'column' : 'row'} columnGap={2}>
        <Grid
          container
          item
          xs={'auto'}
          classes={{
            root: cx({
              [classes.verticalTabBorder]: orientation !== Orientations.horizontal && !hideBorder,
              [classes.horizontalBorderBottom]: orientation === Orientations.horizontal,
            }),
          }}
          direction={orientation === Orientations.horizontal ? 'row' : 'column'}
          style={{ maxHeight: orientation === Orientations.horizontal ? 43 : 'fit-content', maxWidth: 'inherit' }}
        >
          {orientation !== Orientations.horizontal && title && (
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          )}

          <Grid item xs>
            <Tabs
              orientation={orientation === Orientations.horizontal ? Orientations.horizontal : Orientations.vertical}
              aria-label="basic tabs example"
              sx={theme => ({
                marginTop: orientation === Orientations.horizontal ? '0px' : theme.spacing(2),
              })}
              {...rest}
              value={_value}
              onChange={customHandleChange ? customHandleChange : handleChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs.map((tab, i) => (
                <Tab
                  key={tab.label + i}
                  label={tab.label}
                  className={classes.tab}
                  {...a11yProps(i)}
                  disabled={tab.disabled || false}
                  onClick={tab?.onClick}
                  icon={tab?.notification && <Badge variant="notification" badgeContent={tab?.notification?.value} />}
                  iconPosition={'end'}
                />
              ))}
            </Tabs>
          </Grid>
          {addOnComponent && (
            <Grid item classes={{ root: classes.addOnGrid }} xs={1}>
              {addOnComponent}
            </Grid>
          )}
        </Grid>
        {hidePanel ? null : (
          <TabPanelContainer
            tabs={tabs}
            orientation={orientation}
            customTabPanel={customTabPanel}
            tabPanelContainer={tabPanelContainer}
            addOnComponent={addOnComponent}
            value={_value}
            setValue={setValue}
            addPanelRef={addPanelRef}
            panelRefs={panelRefs}
            {...rest}
          />
        )}
      </Grid>
    </Box>
  );
};
