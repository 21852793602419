export const getEmploymentHistoryHeaders = () => [
  {
    value: 'Field Changed',
    title: 'fieldChanged',
  },
  { value: 'Old Value', title: 'oldValue' },
  { value: 'New Value', title: 'newValue' },
  { value: 'Changed By', title: 'changedBy' },
  { value: 'Date / Time', title: 'date' },
];
