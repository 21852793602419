import { Button, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { missingField } from 'app/constants';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { ListSection } from '../../../customComponents/ListSection';
import { TableSection } from '../../../customComponents/TableSection';
import { getRequirementCheck } from '../../../helper';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import {
  selectServiceFailures,
  selectSubmissionInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { RequirementsRow } from '../../TableComponents/TableComponents';

const useStyles = makeStyles()({
  countCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  countTag: {
    marginRight: 8,
  },
  button: {
    cursor: 'pointer',
    color: '#006FB9',
    fontWeight: 450,
    minWidth: 0,
    padding: 0,
    '&:hover': {
      background: 'none',
    },
  },
});

export const References = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const {
    amnReq: {
      candidate: { references },
    },
    requirementFilter,
  } = useSelector(selectSubmissionInfo);

  const { referenceAmnRequirement: serviceFailed } = useSelector(selectServiceFailures);

  const { placementId } = useContext(AddOnContext);
  const { promiseInProgress } = usePromiseTracker({ area: 'placement-submission-reference-amn-requirement', delay: 0 });

  const dispatch = useDispatch();

  const { display } = useRequirementFilter('submission-amn', requirementFilter, references?.type);

  return display ? (
    <RequirementsRow
      id="placement-submission-amn-references"
      title={t('placement.profile.summary.submission.amn.references.title')}
      requirementCell={missingField}
      candidateCell={<RowValue references={references} classes={classes} t={t} />}
      loading={promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() => dispatch(placementSummaryActions.getReferenceAmnRequirement({ placementId }))}
    />
  ) : null;
};

const RowValue = ({ references, classes, t }) => {
  return (references?.data || []).length ? (
    <>
      <ListSection listType={'candidateRreferences'} items={(references?.data || []).slice(0, 2)} />
      <Typography variant="body1" className={classes.countCell}>
        <div className={classes.countTag}>
          {t('placement.profile.summary.submission.amn.references.approvedReferences')}
        </div>
        <Button
          type="button"
          variant="text"
          disableRipple
          disableTouchRipple
          disableFocusRipple
          classes={{ root: classes.button }}
        >
          {references?.data.length}
        </Button>
      </Typography>
    </>
  ) : (
    <TableSection
      items={[
        {
          icon: references ? getRequirementCheck(references.type) : null,
          primaryContent: t('placement.profile.summary.submission.amn.references.noApprovedReferences'),
        },
      ]}
    />
  );
};
