import { Typography } from 'amn-ui-core';
import { missingField } from 'app/constants';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useSelector, useDispatch } from 'react-redux';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import {
  selectServiceFailures,
  selectSubmissionInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { RequirementsRow } from '../../TableComponents/TableComponents';

export const Documents = () => {
  const { t } = useTranslation();
  const { promiseInProgress } = usePromiseTracker({
    area: 'placement-submission-certification-document-requirement',
    delay: 0,
  });
  const { requirementFilter } = useSelector(selectSubmissionInfo);
  const { documentsOrderRequirement: serviceFailed } = useSelector(selectServiceFailures);
  const { placementId } = useContext(AddOnContext);
  const dispatch = useDispatch();

  const { display } = useRequirementFilter('submission-order', requirementFilter);

  return display ? (
    <RequirementsRow
      id="placement-submission-order-documents"
      title={t('placement.profile.summary.submission.order.documents.title')}
      requirementCell={<Typography variant="body1">{missingField}</Typography>}
      candidateCell={<Typography variant="body1">{missingField}</Typography>}
      loading={promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() =>
        dispatch(placementSummaryActions.getDocumentsOrderRequirement({ placementId, isRetryingService: true }))
      }
    />
  ) : null;
};
