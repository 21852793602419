/* eslint-disable jsx-a11y/alt-text */
import { List, ListItem, ListItemIcon, Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import FilterIcon from 'app/assets/images/IconImages/FilterIcon.svg';
import ListIcon from 'app/assets/images/IconImages/ListOutline.svg';
import FilterBlueIcon from 'app/assets/images/IconImages/FilterBlueIcon.svg';
import ListBlueIcon from 'app/assets/images/IconImages/ListBlueOutline.svg';
import FunnelIcon from 'app/assets/images/IconImages/FunnelOutline.svg';
import FunnelBlueIcon from 'app/assets/images/IconImages/FunnelBlueOutline.svg';
import BinocularsIcon from 'app/assets/images/IconImages/BinocularsOutline.svg';
import BinocularsBlueIcon from 'app/assets/images/IconImages/BinocularsBlueOutline.svg';
import { TaskIcon } from 'amn-ui-core/dist/components/Icons';

export const getStickIcons = customSwitch({
  0: <img src={FilterIcon} />,
  1: <img src={ListIcon} />,
  2: <img src={FunnelIcon} />,
  3: <img src={BinocularsIcon} />,
  4: <TaskIcon />,
})(undefined);

export const getSelectedStickIcons = customSwitch({
  0: <img src={FilterBlueIcon} />,
  1: <img src={ListBlueIcon} />,
  2: <img src={FunnelBlueIcon} />,
  3: <img src={BinocularsBlueIcon} />,
  4: <TaskIcon />,
})(undefined);

const useStyles = makeStyles()((theme: Theme) => ({
  item: {
    paddingLeft: theme.spacing(1),
  },
}));

export const PanelStick = props => {
  const { classes } = useStyles();
  const { stickChosen } = props;
  return (
    <List disablePadding>
      {props.iconArray.map((icon, index) => (
        <ListItem
          key={index.toString()}
          aria-label="stick-item"
          disableGutters
          button
          classes={{ root: classes.item }}
          onClick={() => props.handleChoice(index)}
        >
          <ListItemIcon>
            {stickChosen !== null && stickChosen === index ? getSelectedStickIcons(icon) : getStickIcons(icon)}
          </ListItemIcon>
        </ListItem>
      ))}
    </List>
  );
};
