import { Grid } from 'amn-ui-core';
import { missingField } from 'app/constants';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { useStyles } from '../utils';

function GetHearsayLicenseTooltip({ license }) {
  const { t } = useTranslation();
  let current = new Date();
  let currentDate = current.getTime();
  let date = new Date(license.expirationOn);
  let expirationDate = date.getTime();
  const { classes } = useStyles();
  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <span style={{ fontWeight: 'bold' }} className={classes.tooltipRowHeader}>
            {t('candidate.qualificationsCard.licenseNumber')}
          </span>
          <span className={classes.tooltipRowContent}> {license.licenseNumber ? license.licenseNumber : `--`}</span>
        </Grid>
        <Grid item>
          <span style={{ fontWeight: 'bold' }}>
            {license.expirationOn && expirationDate < currentDate
              ? t('candidate.qualificationsCard.expiredOn')
              : t('candidate.qualificationsCard.expiresOn')}
          </span>
          {license.expirationOn ? moment(license.expirationOn).format('MM/DD/YYYY') : missingField}
        </Grid>
      </Grid>
    </>
  );
}

export default GetHearsayLicenseTooltip;
