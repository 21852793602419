import { ContactItem } from '@AMIEWEB/Common';
import { SelectAllCheckbox } from '@AMIEWEB/Common/ContactsDrawer/ContactList';
import { List } from 'amn-ui-core';
import { getSearchLookups } from 'app/services/SharedServices/SharedServices';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectKeyInternalContactsEditor,
  selectPlacementContactsData,
} from 'store/redux-store/placement-details/selectors';
import { placementDetailsAction } from 'store/redux-store/placement-details/slice';
import { makeStyles } from 'tss-react/mui';
import { ContactCheckbox, ContactEditor } from './helper';

const useStyles = makeStyles()({
  root: {
    padding: '0px 5px',
  },
  selectAllText: {
    '& .MuiFormControlLabel-label': {
      fontSize: 16,
    },
  },
});

export const KeyInternalContacts = props => {
  const { classes } = useStyles();
  const { disableEmailVoiceIcon, setScrollPosition } = props;
  const {
    flattenedContacts: {
      keyInternalPartners: {
        recruiter,
        regionalDirector,
        accountManager,
        credentialingAnalyst,
        clinicalManager,
        clientContractSpecialist,
      },
    },
  } = useSelector(selectPlacementContactsData);

  const {
    recruiter: editRecruiter,
    accountManager: editAccountManager,
    credentialingAnalyst: editCredentialingAnalyst,
    clientContractSpecialist: editClientContractSpecialist,
    regionalDirector: editRegionalDirector,
    clinicalManager: editClinicalmanager,
  } = useSelector(selectKeyInternalContactsEditor);
  const editorInternalContacts = useSelector(selectKeyInternalContactsEditor);
  const {
    flattenedContacts: { keyInternalPartners },
  } = useSelector(selectPlacementContactsData);

  const dispatch = useDispatch();
  const handleSaveContact = value => dispatch(placementDetailsAction.updateKeyContact(value));

  /**
   * Method to prevent lookup search call if last character is special character
   * @param searchKey
   */
  const ignoreLookupSearchForSpecialCharacter = (searchKey: string) => {
    const lastCharacter = searchKey.slice(-1);
    const specialCharacterPattern = /[!@#$%^&*()_+[\]{};:'"\\|,.<>/?]/;
    return specialCharacterPattern.test(lastCharacter);
  };

  /**
   * Method which trigger on searching in type ahead dropdown
   * @param field
   * @param key
   */
  const fetchOptions = async (field: string, key: string) => {
    if (ignoreLookupSearchForSpecialCharacter(key)) return;
    return await getSearchLookups(field, key);
  };

  /**
   * Method to reset Key Contact value
   * @param keyContact
   * @returns
   */
  const fetchKeyContactValue = (keyContact: string) => {
    switch (keyContact) {
      case 'recruiter':
        return recruiter;
      case 'accountManager':
        return accountManager;
      case 'credentialingAnalyst':
        return credentialingAnalyst;
      case 'clientContractSpecialist':
        return clientContractSpecialist;
      case 'regionalDirector':
        return regionalDirector;
      case 'clinicalManager':
        return clinicalManager;
    }
  };

  const onValueChange = (key, contact) => {
    const field = fetchKeyContactValue(key);
    const value = contact?.id ? contact : contact?.value;
    setScrollPosition();
    dispatch(
      placementDetailsAction.editKeyInternalContact({
        key,
        data: {
          value,
          error: null,
          isDirty: value?.id !== field?.id,
          checked: contact?.checked,
        },
      }),
    );
  };

  const onCancel = key => {
    const field = fetchKeyContactValue(key);
    dispatch(
      placementDetailsAction.editKeyInternalContact({
        key,
        data: {
          value: field.id ? { name: field.title, value: field.id, id: field.id } : null,
          error: null,
          isDirty: false,
        },
      }),
    );
  };

  const _recruiter = React.useMemo(
    () => (
      <ContactEditor
        {...recruiter}
        {...editRecruiter}
        fetchOptions={key => fetchOptions('recruiters', key)}
        onApply={handleSaveContact}
        onChange={newValue => onValueChange('recruiter', newValue)}
        onCancel={() => onCancel('recruiter')}
      />
    ),
    [recruiter, editRecruiter],
  );

  const _accountManager = React.useMemo(
    () => (
      <ContactEditor
        {...accountManager}
        {...editAccountManager}
        fetchOptions={key => fetchOptions('accountmanagers', key)}
        onApply={handleSaveContact}
        onChange={newValue => onValueChange('accountManager', newValue)}
        onCancel={() => onCancel('accountManager')}
      />
    ),
    [accountManager, editAccountManager],
  );

  const _credentialingAnalyst = React.useMemo(
    () => (
      <ContactEditor
        {...credentialingAnalyst}
        {...editCredentialingAnalyst}
        fetchOptions={key => fetchOptions('amieqsa', key)}
        onApply={handleSaveContact}
        onChange={newValue => onValueChange('credentialingAnalyst', newValue)}
        onCancel={() => onCancel('credentialingAnalyst')}
      />
    ),
    [credentialingAnalyst, editCredentialingAnalyst],
  );

  const _clientContractSpecialist = React.useMemo(
    () => (
      <ContactEditor
        {...clientContractSpecialist}
        {...editClientContractSpecialist}
        fetchOptions={key => fetchOptions('amieccs', key)}
        onApply={handleSaveContact}
        onChange={newValue => onValueChange('clientContractSpecialist', newValue)}
        onCancel={() => onCancel('clientContractSpecialist')}
      />
    ),
    [clientContractSpecialist, editClientContractSpecialist],
  );

  const _regionalDirector = React.useMemo(
    () => (
      <ContactEditor
        {...regionalDirector}
        {...editRegionalDirector}
        fetchOptions={key => fetchOptions('amierd', key)}
        onApply={handleSaveContact}
        onChange={newValue => onValueChange('regionalDirector', newValue)}
        onCancel={() => onCancel('regionalDirector')}
      />
    ),
    [regionalDirector, editRegionalDirector],
  );

  const handleSelectAllContacts = () => {
    setScrollPosition();
    const keyInternalContacts = Object.fromEntries(
      Object.entries(editorInternalContacts)?.map(([key, entry]) => [
        key,
        { ...entry, checked: !entry?.isDirty && keyInternalPartners[key]?.email ? !isAllContactsSelected() : false },
      ]),
    );
    // @ts-ignore: unspecified object type
    dispatch(placementDetailsAction.setInternalKeyContacts(keyInternalContacts));
  };

  const isAllContactsSelected = () => {
    let selectContacts = Object.fromEntries(
      Object.entries(editorInternalContacts)?.filter(([key, value]) => {
        return !value?.isDirty && keyInternalPartners[key].email;
      }),
    );
    return Object.values(selectContacts)?.every(value => value?.checked);
  };

  const selectAll = useMemo(() => isAllContactsSelected(), [editorInternalContacts]);

  return (
    <List className={classes.root}>
      <SelectAllCheckbox checked={selectAll} disabled={false} handleSelectAll={handleSelectAllContacts} />
      <ContactItem
        {...recruiter}
        enableCommunication={true}
        title={_recruiter}
        disableMailIcon={editRecruiter?.isDirty || disableEmailVoiceIcon || false}
        disablePhoneIcon={editRecruiter?.isDirty || disableEmailVoiceIcon || false}
        disableMessageIcon={editRecruiter?.isDirty || false}
        disableAvatar={editRecruiter?.isDirty || !editRecruiter?.value || false}
        showCheckbox
        checkbox={
          <ContactCheckbox
            contacts={recruiter}
            editContacts={editRecruiter}
            onValueChange={onValueChange}
            disabled={editRecruiter?.isDirty || !recruiter?.email || false}
          />
        }
      />
      <ContactItem
        {...accountManager}
        title={_accountManager}
        enableCommunication={true}
        disableMailIcon={editAccountManager?.isDirty || disableEmailVoiceIcon || false}
        disablePhoneIcon={editAccountManager?.isDirty || disableEmailVoiceIcon || false}
        disableMessageIcon={editAccountManager?.isDirty || false}
        disableAvatar={editAccountManager?.isDirty || !editAccountManager?.value || false}
        showCheckbox
        checkbox={
          <ContactCheckbox
            contacts={accountManager}
            editContacts={editAccountManager}
            onValueChange={onValueChange}
            disabled={editAccountManager?.isDirty || !accountManager?.email || false}
          />
        }
      />
      <ContactItem
        {...regionalDirector}
        enableCommunication={true}
        title={_regionalDirector}
        disableMailIcon={editRegionalDirector?.isDirty || disableEmailVoiceIcon || false}
        disablePhoneIcon={editRegionalDirector?.isDirty || disableEmailVoiceIcon || false}
        disableMessageIcon={editRegionalDirector?.isDirty || false}
        disableAvatar={editRegionalDirector?.isDirty || !editRegionalDirector?.value || false}
        showCheckbox
        checkbox={
          <ContactCheckbox
            contacts={regionalDirector}
            editContacts={editRegionalDirector}
            onValueChange={onValueChange}
            disabled={editRegionalDirector?.isDirty || !regionalDirector?.email || false}
          />
        }
      />
      <ContactItem
        {...credentialingAnalyst}
        title={_credentialingAnalyst}
        enableCommunication={true}
        disableMailIcon={editCredentialingAnalyst?.isDirty || disableEmailVoiceIcon || false}
        disablePhoneIcon={editCredentialingAnalyst?.isDirty || disableEmailVoiceIcon || false}
        disableMessageIcon={editCredentialingAnalyst?.isDirty || false}
        disableAvatar={editCredentialingAnalyst?.isDirty || !editCredentialingAnalyst?.value || false}
        showCheckbox
        checkbox={
          <ContactCheckbox
            contacts={credentialingAnalyst}
            editContacts={editCredentialingAnalyst}
            onValueChange={onValueChange}
            disabled={editCredentialingAnalyst?.isDirty || !credentialingAnalyst?.email || false}
          />
        }
      />
      <ContactItem
        {...clientContractSpecialist}
        title={_clientContractSpecialist}
        enableCommunication={true}
        disableMailIcon={editClientContractSpecialist?.isDirty || disableEmailVoiceIcon || false}
        disablePhoneIcon={editClientContractSpecialist?.isDirty || disableEmailVoiceIcon || false}
        disableMessageIcon={editClientContractSpecialist?.isDirty || false}
        disableAvatar={editClientContractSpecialist?.isDirty || !editClientContractSpecialist?.value || false}
        showCheckbox
        checkbox={
          <ContactCheckbox
            contacts={clientContractSpecialist}
            editContacts={editClientContractSpecialist}
            onValueChange={onValueChange}
            disabled={editClientContractSpecialist?.isDirty || !clientContractSpecialist?.email || false}
          />
        }
      />
      <ContactItem
        {...clinicalManager}
        enableCommunication={true}
        disableMailIcon={disableEmailVoiceIcon || false}
        disablePhoneIcon={disableEmailVoiceIcon || false}
        showCheckbox
        checkbox={
          <ContactCheckbox
            contacts={clinicalManager}
            editContacts={editClinicalmanager}
            onValueChange={onValueChange}
            disabled={!clinicalManager?.email || false}
          />
        }
      />
    </List>
  );
};
