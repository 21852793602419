import { makeStyles } from 'tss-react/mui';

const useAddRoleTypeStyle = makeStyles()(theme => ({
  sectionWrapper: {
    paddingLeft: 6,
  },
  sectionTitle: {
    paddingTop: 12,
    paddingLeft: 7,
  },
  checkBoxGroup: {
    paddingLeft: 52,
    paddingTop: 5,
    width: 368,
  },
  startDate: {
    maxWidth: 205,
    paddingTop: 8,
    paddingBottom: 20,
  },
  endDate: {
    maxWidth: 205,
  },
  checkBox: {
    fontSize: 14,
    paddingLeft: 8,
    color: '#333333',
    paddingBottom: 15,
  },
  divider: {
    margin: '10px 0px 0px 0px',
  },
  icon: {
    color: '#888888',
    cursor: 'pointer',
    '&:hover': {
      // cursor: 'not-allowed',
    },
  },
  addRolesText: {
    fontWeight: 500,
    fontSize: '12px !important',
    paddingLeft: '5px',
  },
  addRoleIcon: {
    width: 40,
    height: 40,
  },
  addRoleButton: {
    color: '#C4C6C9',
    background: 'none !important',
    padding: '0 5px 0 0 !important',
    '&:hover': {
      color: '#2870B9',
    },
  },
}));

export default useAddRoleTypeStyle;
