import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { call, getContext, put } from 'redux-saga/effects';
import { placementSummaryActions } from '../slice';
import InitPlacementSummary, { IConsolidatedRequirementResponse } from '../init.types';
import { resetFailureStatus } from '../saga';
import { extractOpts } from '../helper';

export function* requestTransportationPicklist() {
  try {
    yield put(resetFailureStatus('transportationOpts'));

    const placementService: PlacementService = yield getContext('placementService');

    const response = yield call(placementService.getTransportationPicklist);
    if (httpSuccess(response?.status)) {
      yield put(
        placementSummaryActions.setOfferTransportationPickListByKey({ key: 'transportationOpts', data: response.data }),
      );
    } else {
      yield put(placementSummaryActions.setServiceFailureByKey({ key: 'transportationOpts', failureStatus: true }));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestTransportationPicklist',
        area: 'src/store/redux-store/placement-summary/common-saga/requestTransportationPicklist.ts',
      },
    });
  }
}

export function processTransportationPicklist(
  response: IConsolidatedRequirementResponse<any>,
  context: InitPlacementSummary,
) {
  try {
    if (!httpSuccess(response?.status)) {
      context.setServiceFailureByKey({ key: 'transportationOpts', failureStatus: true });
      return;
    }
    const extractedData = extractOpts('transportationOpts', response.data);

    context.setOfferTransportationPickListByKey({ key: 'transportationOpts', data: extractedData });
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'processTransportationPicklist',
        area: 'src/store/redux-store/placement-summary/common-saga/requestTransportationPicklist.ts',
      },
    });
  }
}
