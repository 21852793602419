/* eslint-disable i18next/no-literal-string */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutGrid } from 'app/layout/LayoutGrid';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuditData } from '../../../Profile/CandidateDetails.selector';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { AuditModal } from '../../../../../Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/Statuses/Submission/amnRequirementRows/AuditsGrid/AuditModal';
import { formatData, refactorData } from './helper';
import { ff_candidate_container_ui_v2 } from 'app/constants/FeatureFlags/Candidate/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { formatAuditRecords } from '../../../../../../../store/redux-store/placement-summary/helper';
import { AuditAddModal } from '../Audit/AuditAddModal';
import { auditTypeViewEditPermission } from '../Audit/Helpers/AuditAddHelpers';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { lookupSelector } from 'store/redux-store/lookup/lookup.selectors';
import { getAuditCategory, getAuditSubCategory } from 'app/services/SharedServices/SharedServices';
import { selectUser } from 'oidc/user.selectors';
import AuditSummaryUI from './Card Design/AuditSummaryUI';
import AuditSummaryUIV2 from './Card Design/AuditSummaryUIV2';
import { useStyles } from './Card Design/utils';

export const AuditSummaryCard = props => {
  const { t } = useTranslation();
  const auditData = useSelector(selectAuditData);
  const [auditModalOpen, setAuditModalOpen] = useState<boolean>(false);
  const [addAuditOpen, setAddAuditOpen] = useState<boolean>(false);
  const [isAddAudit, setIsAddAudit] = useState<boolean>(true);
  const [filterModel, setFilterModel] = React.useState<any>([]);
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const lookupSelection = useSelector(lookupSelector);
  const [candidateContainerUIV2] = useDecision(ff_candidate_container_ui_v2);
  const user = useSelector(selectUser);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    return () => {
      dispatch(
        candidateDetailActions.setEditAuditData({
          editAuditDataDetails: undefined,
          rowData: undefined,
        }),
      );
    };
  }, []);

  const handleOnAdd = () => {
    setAuditModalOpen(false);
    setAddAuditOpen(true);
    setIsAddAudit(true);
  };

  const handleOnEdit = data => {
    const permissions = auditTypeViewEditPermission(data.row?.auditType, user.userInfo);
    formatEditAuditData(data.row);
    setIsDisabled(!permissions.canEdit);
  };

  const formatEditAuditData = async (data: any) => {
    const auditCategory = data.auditCategory
      ? await getAuditCategory(data.auditTypeId)
          .then(response => {
            if (response.status === 200) {
              return response.data?.find(item => item.name === data.auditCategory);
            }
          })
          .catch(e => {
            return null;
          })
      : null;

    const auditSubCategory = data.auditSubCategory
      ? await getAuditSubCategory(auditCategory?.value)
          .then(response => {
            if (response.status === 200) {
              return response.data?.find(item => item.name === data.auditSubCategory);
            }
          })
          .catch(e => {
            return null;
          })
      : null;

    const formattedData = formatData(data, lookupSelection, auditSubCategory, auditCategory);

    setAuditModalOpen(false);
    setAddAuditOpen(true);
    setIsAddAudit(false);

    dispatch(
      candidateDetailActions.setEditAuditData({
        editAuditDataDetails: formattedData,
        rowData: data,
      }),
    );
  };

  return (
    <>
      <LayoutGrid className={classes.grid}>
        {candidateContainerUIV2?.enabled ? (
          <AuditSummaryUIV2
            setAuditModalOpen={setAuditModalOpen}
            setAddAuditOpen={setAddAuditOpen}
            setIsAddAudit={setIsAddAudit}
            setFilterModel={setFilterModel}
          />
        ) : (
          <AuditSummaryUI
            setAuditModalOpen={setAuditModalOpen}
            setAddAuditOpen={setAddAuditOpen}
            setIsAddAudit={setIsAddAudit}
            setFilterModel={setFilterModel}
          />
        )}
      </LayoutGrid>

      <GenericDialog
        data-testid="audit-popup-modal"
        open={auditModalOpen}
        onClose={() => setAuditModalOpen(false)}
        fullWidth
        draggable
        maxWidth={'md'}
        disableEnforceFocus
        variant="blue"
        dialogTitleProps={{
          text: t('candidate.auditCard.title'),
          closeButton: true,
          expandable: true,
        }}
      >
        <>
          <AuditModal
            auditDetails={formatAuditRecords(refactorData(auditData?.items))}
            forceClose={() => setAuditModalOpen(false)}
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            refreshData={() => {}}
            onHandleAdd={() => handleOnAdd()}
            onHandleEdit={data => handleOnEdit(data)}
          />
        </>
      </GenericDialog>

      <AuditAddModal
        open={addAuditOpen}
        handleClose={() => setAddAuditOpen(false)}
        isAddAudit={isAddAudit}
        disabled={!isAddAudit && isDisabled}
      />
    </>
  );
};
