import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { useWatch } from 'react-hook-form';
import { useEditableRequirement } from './EditRequirementsHelper';
import { datesConfirmedCheck } from './EditablePassFailLogic';

export const useEditableRequirementDatesConfirmed = () => {
  // DATES CONFIRMED
  const datesConfirmed: any = useWatch({ name: 'datesConfirmed' });

  useEditableRequirement({
    data: datesConfirmed?.checked,
    requirementId: IPlacementRequirementKey.datesConfirmed,
    requirementKey: 'datesConfirmed',
    requirementCheck: datesConfirmedCheck,
    failRequirement: {
      id: IPlacementRequirementKey.datesConfirmed,
      message: 'Dates Confirmed: Not Selected',
      tooltip: {
        message: 'Dates Confirmed checkbox must be selected',
      },
    },
  });
};
