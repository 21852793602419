import { createAction } from '@reduxjs/toolkit';
import { IContractTemplate } from './types';

const onInitAction = createAction('ON_INIT_CONTRACT_TEMPLATE_EDITOR');
const getMergeTokensList = createAction('GET_CONTRACT_MERGE_TOKENS_LIST');
const getTemplates = createAction('GET_CONTRACT_TEMPLATES');
const publishTemplate = createAction<IContractTemplate>('PUBLISH_CONTRACT_TEMPLATE_ACTION');

export const serviceActions = {
  onInitAction,
  getMergeTokensList,
  getTemplates,
  publishTemplate,
};
