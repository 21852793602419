import { Box, Grid, Theme } from 'amn-ui-core';
import * as React from 'react';
import uuid from 'react-uuid';
import { makeStyles } from 'tss-react/mui';
import { GenericTabs, GenericTabsProps, Orientations } from './GenericTabs';

const useStyles = makeStyles<{ orientation: string }>()((theme: Theme, { orientation }) => ({
  tabPanelContentContainer: {
    overflow: 'auto',
    flex: 1,
    minHeight: 'fit-content',
    maxWidth: 'inherit',
  },
  tabPanelContainer: { display: 'flex', margin: '0px', flexGrow: 1, overflow: 'auto' },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  addPanelRef: (element: any) => void;
  customTabPanel?: React.CSSProperties;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, index, addPanelRef, customTabPanel } = props;

  return (
    <Grid
      ref={element => addPanelRef(element)}
      item
      xs={12}
      style={{ float: 'right', ...customTabPanel }}
      role="tabpanel"
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {children}
    </Grid>
  );
};

interface TabPanelContainerProps {
  setValue: (val: number) => void;
  addPanelRef: (elemement: any) => void;
  panelRefs: React.MutableRefObject<any[]>;
}

export const TabPanelContainer = ({
  tabs,
  customTabPanel,
  orientation,
  tabPanelContainer,
  value,
  setValue,
  addPanelRef,
  panelRefs,
  dense = false,
}: GenericTabsProps & TabPanelContainerProps) => {
  const { classes } = useStyles({ orientation });
  const [panelId, setPanelId] = React.useState(uuid());

  React.useEffect(() => {
    setPanelId(uuid());
    const panelContainer = document.getElementById('panel-container-' + panelId);
    const handleScroll = () => {
      if (orientation === Orientations.verticalList) {
        if (panelContainer.scrollTop < 10) {
          setValue(0);
        } else if (panelContainer.scrollTop + panelContainer.clientHeight >= panelContainer.scrollHeight) {
          setValue(panelRefs?.current?.length - 1);
        } else {
          panelRefs.current?.map((tabPanel, index) => {
            if (panelContainer.scrollTop > tabPanel.offsetTop) {
              if (panelRefs?.current?.length - 1 > index) setValue(index + 1);
            }
          });
        }
      }
    };

    document.addEventListener('wheel', handleScroll, true);
    return () => {
      document.removeEventListener('wheel', handleScroll, true);
    };
  }, []);

  return (
    <Box
      className={classes.tabPanelContentContainer}
      id={'panel-container-' + panelId}
      sx={{ maxHeight: orientation === Orientations.horizontal ? 'inherit' : '100vh' }}
    >
      <Grid id="tabPanelcontainer" container item xs className={classes.tabPanelContainer} rowSpacing={dense ? 0 : 2}>
        {tabs.map(
          (tab, i) =>
            (orientation === Orientations.verticalList ||
              ((orientation === Orientations.horizontal || orientation === Orientations.vertical) && i === value)) && (
              <TabPanel key={i + ''} addPanelRef={addPanelRef} index={i} customTabPanel={customTabPanel}>
                {tab?.subTabs ? <GenericTabs {...tab?.subTabs} /> : tab.component ? tab.component : tab.label}
              </TabPanel>
            ),
        )}
      </Grid>
    </Box>
  );
};
