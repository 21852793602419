import { Typography, Skeleton } from 'amn-ui-core';
import React from 'react';
import styledComponent from 'styled-components';
import { colors } from 'styles/styleVariables';

export const TableCell = ({ ...props }) => <TD {...props}>{props.children}</TD>;

export const Loading = () => (
  <div style={{ width: '100%' }}>
      <Skeleton />
  </div>
);

export const RowTitle = styledComponent(Typography)`
  font-size: 12px;
  font-weight: 500;
`;

export const Table = styledComponent.table`
  width: 100%;
  border: 1px solid #d9d9d9;
  border-collapse: collapse;
`;

export const TD = styledComponent.td`
  border: 1px solid #d9d9d9;
  padding: 12px;
  vertical-align: top;
`;

export const ColumnHeader = styledComponent(Typography)`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.text};
`;
