import { ChipsContainer } from 'app/ComponentLibrary/Filter';
import {
  pipelineChoiceActions,
  pipelineChoiceKeys,
} from 'app/components/Common/WorkDesk/PipelineChoice/pipelineChoice.redux';
import { selectCredPlacementsStatusGrid } from 'app/components/Common/WorkDesk/PipelineChoice/pipelineChoice.selectors';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { determineChipSelected } from '../Helpers/PlacementStatusFilterHelper';
import { placementStatusActions } from '../Store/PlacementStatus.redux';
import { selectFilterChipSelected } from '../Store/PlacementStatus.selector';
import { useFormContext } from 'react-hook-form';

export const PlacementStatusChips = props => {
  const dispatch = useDispatch();
  const pipelineSelection = useSelector(selectCredPlacementsStatusGrid);
  const filterChipSelected = useSelector(selectFilterChipSelected);
  // Filter
  const formMethods = useFormContext();
  const { getValues, setValue } = formMethods;

  //EVENTS
  const handleChipDelete = (field, chip) => {
    let newValues: any[] = [];
    if (field === 'pipelineSelected') {
      dispatch(
        pipelineChoiceActions.setPipelineChoice({
          key: pipelineChoiceKeys.CRED_PLACEMENT_STATUS_WDG,
          selection: [
            ...pipelineSelection.filter(
              item => !(item.category === chip.value.category && item.subcategory === chip.value.subcategory),
            ),
          ],
        }),
      );
    } else {
      const chosenValue = getValues(field);
      if (chosenValue && chosenValue.length > 0 && Array.isArray(chosenValue)) {
        newValues = [...chosenValue];
        const filterData = ['placementId', 'disciplineSpecialty', 'candidateId'].includes(field)
          ? newValues.filter(item => {
            if (typeof item === 'object') {
              return item.id !== chip.value.id;
            }
            return item !== chip.value;
          })
          : newValues.filter(item => item.id !== chip.value.id);
        setValue(field, filterData);
      } else {
        setValue(field, null);
      }
      // if (chip.isSelected) {
      const newChipSelection = {
        ...filterChipSelected,
        [field]: null,
      };

      dispatch(placementStatusActions.setFilterChipSelected(newChipSelection));
      props.onApplyFilter(getValues(), newChipSelection);
    }
  };

  const handleChipClick = (key, chip) => {
    //filter-by-chip provision is not implemented for below fields
    if (!['pipelineSelected'].includes(key)) {
      const newChipSelection = {
        ...filterChipSelected,
        [key]: determineChipSelected(chip, filterChipSelected[key]),
      };
      dispatch(placementStatusActions.setFilterChipSelected(newChipSelection));
      props.onApplyFilter(getValues(), newChipSelection);
    }
  };

  return (
    <>
      <ChipsContainer
        chips={props.chips}
        onDelete={(field, chip, index) => handleChipDelete(field, chip)}
        onClick={(field, chip, index) => handleChipClick(field, chip)}
      />
    </>
  );
};
