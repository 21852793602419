import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Autocomplete } from 'amn-ui-core';

/**
 * @param optionsList: Can pass any array of data format
 * !! Make sure your data set includes at least 3 properties on it !!
 * The value/defaultValue is based off of this id prop, so
 * properly pass the id value to the object so it sorts by that value
 * {
 *  (id: <string | number>): id of the list item
 *  (groupBy: string): value to group list items by
 *  (title: string): title of the list item
 * }
 *
 * @param groupBy: Allows for custom dropdown groupBy value
 * This way you can pass your own grouped by value to group by
 * Will default to groupBy value if nothing is passed
 */

export const CategorizedAutoComplete = ({
  optionsList,
  disabled,
  onChange,
  helperText,
  label,
  noOptionsText = 'No Matches Found',
  value,
  id,
  className,
  classes,
  labelClass,
  error,
  tabIndex,
  onInputChange,
  groupBy = option => option.groupBy,
}: {
  optionsList?: any[];
  disabled?: boolean;
  onChange?: any;
  helperText?: React.ReactNode;
  label?: string;
  noOptionsText?: string;
  id?: any;
  value?: string | number;
  className?: string;
  classes?: any;
  labelClass?:any;
  error?: boolean;
  tabIndex?: number;
  onInputChange?: any;
  groupBy?: any;
}) => {
  const [options, setOptions] = useState<any[] | undefined>(optionsList || []);
  const [keyword, setKeyword] = useState<any>();

  useEffect(() => {
    if (keyword) {
      const key = keyword as string;
      const filteredCat = optionsList?.filter(
        opt =>
          opt.groupBy?.toLowerCase().includes(key.toLowerCase()) ||
          opt.title?.toLowerCase().includes(key.toLowerCase()),
      );
      setOptions(filteredCat);
    } else {
      setOptions(optionsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    if (optionsList) {
      setOptions(optionsList);
    }
  }, [optionsList]);

  const handleInputChange = (event, v) => {
    setKeyword(v);
    onInputChange && onInputChange(v);
  };

  return (
    <Autocomplete
      id="grouped-TypeAhead"
      className={className}
      classes={classes}
      options={options || []}
      onInputChange={handleInputChange}
      groupBy={groupBy}
      getOptionLabel={option => option.title}
      filterOptions={option => option}
      defaultValue={options?.find(c => c.id === value)}
      value={options?.find(c => c.id === value)}
      disabled={disabled ? true : false}
      noOptionsText={noOptionsText}
      onChange={(e, value) => {
        onChange(value);
      }}
      renderInput={params => (
        <TextField
          {...params}
          id={id}
          label={label}
          variant="filled"
          error={error}
          tabIndex={tabIndex}
          helperText={helperText}
          color="primary"
          InputLabelProps={{
            className: labelClass,
          }}
        />
      )}
    />
  );
};
