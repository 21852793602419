import React, { useEffect } from 'react';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, Grid, Box, SvgIcon, SvgIconProps, Checkbox, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';
import { deepEqual } from 'utils/common/comparison';
import { injectItem } from './TreeViewHelpers';
import { useTranslation } from 'react-i18next';

export function instanceOfTreeView(object: any): object is ITreeView {
  return object && typeof object === 'object' && 'name' in object && 'children' in object;
}

// function MinusSquare(props: SvgIconProps) {
//   return (
//     <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
//       {/* tslint:disable-next-line: max-line-length */}
//       <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
//     </SvgIcon>
//   );
// }

// function PlusSquare(props: SvgIconProps) {
//   return (
//     <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
//       {/* tslint:disable-next-line: max-line-length */}
//       <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
//     </SvgIcon>
//   );
// }

function CloseSquare(props: SvgIconProps) {
  return (
    <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

const useStyles: any = makeStyles()(theme => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
  },
  border: {
    borderLeft: `1px dashed rgba(0, 0, 0, 0.4)`,
  },
  checkbox: {
    padding: '0px 5px 0px 0px',
  },
  indeterminate: {
    color: theme.palette.secondary.main,
  },
  treeItemSize: {
    margin: '5px 0px',
  },
  button: {
    fontSize: '12px',
    fontWeight: 'bold',
    textTransform: 'none',
    '&:hover, &:focus': {
      background: 'none',
    },
  },
  actionButton: {
    padding: '6px 0px',
  },
  secondaryLabel: {
    fontSize: '12px',
    color: '#989898',
  },
}));

export interface ITreeView {
  name: string;
  value: any;
  fullName?: any;
  checked?: boolean;
  children?: ITreeView[];
  userChecked?: boolean;
  filterName?: string;
}

export const CustomTreeView = ({
  options,
  defaultEndIcon = 'default',
  renderLabel,
  valueKey = 'value',
  nameKey = 'name',
  fullNameKey = 'fullName',
  userCheckedKey = 'userChecked',
  levelTrace = false,
  checkbox = true,
  size = 'small',
  value = [],
  onChange,
  isMultiSelect,
  isSelectAll,
  defaultExpanded = ['root'],
  expandedItems,
  excludeChildren = false,
  setExpandedItems,
}: {
  options?: ITreeView[];
  defaultEndIcon?: React.ReactNode | 'default' | 'close';
  renderLabel?: (node: any) => React.ReactNode | undefined;
  /** the object key of the value for each element */
  valueKey?: 'value' | string;
  /** the object key of the name for each element */
  nameKey?: 'name' | string;
  /** the object key of the fullName for each element */
  fullNameKey?: 'fullName' | string;
  /** the object of this key to indentify which node user clicked */
  userCheckedKey?: 'userChecked' | string;
  /** add border to tree level to easily follow which level you're looking at */
  levelTrace?: boolean;
  /** adds a checkbox in front of the label */
  checkbox?: boolean;
  size?: 'small' | 'large';
  value?: { [key: string]: any }[];
  onChange?: (newValue: any) => void;
  /** nodes that are expanded by default. NodeId is "valueKey-nameKey" */
  isMultiSelect?: boolean;
  isSelectAll?: boolean;
  defaultExpanded?: string[];
  expandedItems?: string[];
  /** excludeChildren it will not checked children if parent node is click */
  excludeChildren?: boolean;
  setExpandedItems?: any;
}) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const [sel, setSel] = React.useState<any>(value);
  const [checkedItems, setCheckedItems] = React.useState<{ [key: string]: any }[]>([]);
  const [expandedNodes, setExpandedNodes] = React.useState<any[]>(expandedItems);

  React.useEffect(() => {
    if (!deepEqual(value, sel)) {
      setSel(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  React.useEffect(() => {
    setCheckedItems(Array.isArray(sel) ? sel : sel ? sel?.skillsets : []);
  }, [sel]);

  type childrenChecked = 'full' | 'partial' | undefined;

  /*const onLabelClick = node => (event: React.ChangeEvent<any>) => {
    if (
      (node.children && node.children?.length > 0 && checkbox && event.target.checked === undefined) ||
      expandedNodes.includes(`${node[valueKey]}-${node[nameKey]}`) ||
      event.target.checked === false
    )
      event.preventDefault();
  };*/

  const defaultRenderLabel = (node: any) => (
    <Grid container direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={1}>
      <Grid item>
        {node[nameKey]}
        {node[fullNameKey] && (
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.secondaryLabel}>
                {node[fullNameKey]}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  const checkItemHandler = (node, parentIdList: any[], checked: boolean) => event => {
    if (excludeChildren) {
      event.stopPropagation();
    }
    node[userCheckedKey] = true;
    event.target.checked = !checked;
    const injectitemInList = injectItem(
      _.cloneDeep(checkedItems),
      [...parentIdList, node],
      node,
      checked,
      valueKey,
      nameKey,
      userCheckedKey,
      excludeChildren,
    );
    setCheckedItems(injectitemInList);
    onChange?.(injectitemInList);
  };

  const areChildrenChecked = (childrenChecked: childrenChecked, node, path): childrenChecked => {
    if (Array.isArray(path.children) && path.children.length > 0) {
      let count = 0;
      path.children.forEach(item => {
        const nodeIndex = node?.children?.map(n => n[valueKey]).indexOf(item[valueKey]) ?? -1;
        let tmpChildrenChecked: childrenChecked = undefined;
        if (nodeIndex !== -1) {
          tmpChildrenChecked = areChildrenChecked(childrenChecked, node.children[nodeIndex], item);
          if (childrenChecked === 'partial' || tmpChildrenChecked === 'partial') childrenChecked = 'partial';
          else childrenChecked = tmpChildrenChecked;
        }
        if (tmpChildrenChecked === 'full') count++;
      });
      if (count === (node.children?.length || 0) && (!childrenChecked || childrenChecked === 'full'))
        childrenChecked = 'full';
      else if (count > 0) childrenChecked = 'partial';
    } else if (node[valueKey] === path[valueKey]) childrenChecked = 'full';
    return childrenChecked;
  };

  const findPath = (path, parentList, node) => {
    if (parentList[valueKey] === node[valueKey] && parentList[nameKey] === node[nameKey]) path = parentList;
    else if (Array.isArray(parentList.children) && parentList.children.length > 0) {
      parentList.children.forEach(item => {
        path = findPath(path, item, node);
      });
    }
    return path;
  };

  const isChecked = (node, parentIdList, indeterminate): boolean => {
    let foundNode: any = undefined;
    if (parentIdList.length === 0) {
      const foundIndex = checkedItems?.map(item => item[valueKey]).indexOf(node[valueKey]);
      if (foundIndex !== -1) foundNode = checkedItems[foundIndex];
    } else {
      const foundIndex2 = checkedItems?.map(item => item[valueKey]).indexOf(parentIdList[0][valueKey]);
      if (foundIndex2 !== -1) foundNode = checkedItems[foundIndex2];
    }

    if (foundNode) {
      const p = findPath(null, foundNode, node);
      if (p) {
        const childrenCk = areChildrenChecked(undefined, node, p);
        return indeterminate ? childrenCk === 'partial' : childrenCk === 'full';
      }
    }
    return false;
  };

  const _renderLabel = (node, parentIdList: any[]) => {
    return (
      <Box
        className={cx({
          [classes.treeItemSize]: size === 'large',
        })}
        onClick={checkbox ? checkItemHandler(node, parentIdList, isChecked(node, parentIdList, false)) : undefined}
      >
        <Grid wrap="nowrap" container direction="row" justifyContent="flex-start" alignItems="flex-start">
          {checkbox && (
            <Grid item alignItems="center" alignContent="center">
              <Checkbox
                key={`${node[valueKey]}-${Math.random()}`}
                checked={isChecked(node, parentIdList, false)}
                indeterminate={isChecked(node, parentIdList, true)}
                classes={{ root: classes.checkbox, indeterminate: classes.indeterminate }}
                size="small"
                onKeyDown={event => {
                  if (event.key === ' ' || event.key === 'Spacebar') {
                    event.preventDefault();
                    if (checkbox) {
                      checkItemHandler(node, parentIdList, isChecked(node, parentIdList, false))(event);
                    }
                  }
                }}
              />
            </Grid>
          )}
          <Grid item>{renderLabel ? renderLabel(node) : defaultRenderLabel(node)}</Grid>
        </Grid>
      </Box>
    );
  };

  const renderTree = (nodes: any, parentIdList: any[]) => (
    <TreeItem
      sx={{
        '& .MuiTreeItem-content': {
          alignItems: 'flex-start !important',
          '& .MuiTreeItem-label': { marginTop: '-5px' },
          '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: '#1976d214',
          },
        },
      }}
      classes={{ group: cx(classes.group, { [classes.border]: levelTrace }), iconContainer: classes.iconContainer }}
      key={nodes[valueKey]}
      nodeId={`${nodes[valueKey]}-${nodes[nameKey]}`}
      label={_renderLabel(nodes, parentIdList)}
      // onLabelClick={onLabelClick(nodes)} // TODO: MUI 5 - @Jake Hamo, REVIEW THIS FOR MUI V5 UPGRADE
    >
      {Array.isArray(nodes.children) ? nodes.children.map(node => renderTree(node, [...parentIdList, nodes])) : null}
    </TreeItem>
  );

  const onNodeSelect = (event, nodeIds) => {};

  const onNodeToggle = (event, nodeIds) => {
    setExpandedNodes(nodeIds);
  };
  useEffect(() => {
    // handle expand all and collaps all of tree item
    setExpandedNodes(expandedItems);
  }, [expandedItems]);

  const handleSelectAll = () => {
    const selectAllOptions = (options: ITreeView[]) => {
      return options.map(option => ({
        ...option,
        checked: true,
        children: option.children ? selectAllOptions(option.children) : undefined,
      }));
    };

    const updatedOptions = selectAllOptions(options);
    setCheckedItems(updatedOptions);
    onChange(updatedOptions);
  };

  return (
    <React.Fragment>
      <div>
        {isMultiSelect && isSelectAll && (
          <Button
            className="select-all-button"
            aria-label="apply-button"
            variant="text"
            color="secondary"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            classes={{ text: `${classes.button} ${classes.actionButton}` }}
            onClick={handleSelectAll}
          >
            {t('search.filter.candidateSearch.selectAll')}
          </Button>
        )}
      </div>
      <TreeView
        // className={classes.root} // TODO: MUI 5 - @Jake Hamo, REVIEW THIS FOR MUI V5 UPGRADE
        defaultExpanded={defaultExpanded ? [...defaultExpanded, 'root'] : defaultExpanded}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultEndIcon={
          defaultEndIcon === 'close' ? <CloseSquare /> : defaultEndIcon === 'default' ? undefined : defaultEndIcon
        }
        onNodeSelect={onNodeSelect}
        onNodeToggle={onNodeToggle}
        disableSelection
        expanded={expandedNodes}
      >
        {Array.isArray(options) ? options.map(node => renderTree(node, [])) : null}
      </TreeView>
    </React.Fragment>
  );
};
