import { GridColDef, GridCellParams } from '@mui/x-data-grid-pro';
import { RenderEditDropdownCell } from './EditGridDropdown';

export const getEditSuperviorColumns = (
  dropdownOptions: any,
  renderChip: (value: string, options: any[]) => JSX.Element,
  useCustomOptions: (api: any, params: GridCellParams, lookups?: any) => { ID: number; Description: string }[],
  handleSkillsetChange: (data: any) => void,
  getOptionDisabled: (api: any, params: GridCellParams, option: { ID: number; Description: string }) => boolean,
) => {
  const headers: GridColDef[] = [
    {
      field: 'discipline',
      headerName: 'Discipline',
      sortable: false,
      valueFormatter: param => param.value ?? '--',
      width: 125,
      editable: true,
      renderEditCell: RenderEditDropdownCell({
        options: dropdownOptions?.discipline || [],
        onChange: handleSkillsetChange,
        isDisabled: ({ value, params }) => (params.row.existingSkillset ? true : false),
      }),
    },
    {
      field: 'specialty',
      headerName: 'Specialty',
      sortable: false,
      valueFormatter: param => param.value ?? '--',
      width: 125,
      editable: true,
      renderEditCell: RenderEditDropdownCell({
        options: [],
        useCustomOptions: useCustomOptions,
        onChange: handleSkillsetChange,
        isDisabled: ({ value, params }) => (params.row.existingSkillset ? true : false),
        getOptionDisabled: getOptionDisabled,
      }),
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      valueFormatter: param => param.value ?? '--',
      width: 125,
      renderCell: RenderEditDropdownCell({
        options: dropdownOptions?.status,
        customWidth: '134px',
        renderValue: renderChip,
        onChange: handleSkillsetChange,
      }),
    },
    {
      field: 'rejectionReason',
      headerName: 'Reason',
      sortable: false,
      valueFormatter: param => param.value ?? '--',
      width: 200,
      editable: true,
      renderEditCell: RenderEditDropdownCell({
        options: dropdownOptions?.rejectionReason,
        onChange: handleSkillsetChange,
      }),
    },
  ];
  return headers;
};
