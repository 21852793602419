import React from 'react';
import { GridColumns } from '@mui/x-data-grid-pro';
import { XGridDefaultColumn, XGRID_COLUMN_TYPE } from 'app/components/Common/XGrid/cells/XGridDefaults';
import { XGridLinkCell } from 'app/components/Common/XGrid/cells/XGridLinkCell';
import { XGridOverflowCell } from 'app/components/Common/XGrid/cells/XGridOverflowCell';
import { xGridDateSort } from 'app/components/Common/XGrid/sorting/XGridSorting';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import GridCellEditAudit from './GridCellEditAudit';
import { Radio } from 'amn-ui-core';

const defaultColumnProps = { ...XGridDefaultColumn, flex: 0, filterable: true };
const DEFAULT_COLUMN_WIDTH = 120;
const auditConstants = {
  amnEvaluation: 'AMN Evaluation',
  applicationRef: 'Application/referencing',
  conviction: 'Conviction',
  dmvHit: 'DMV Hit',
  licenseInv: 'License investigation, suspension or problem',
};

const getColumnDefinition = (t, key) => {
  return {
    ...defaultColumnProps,
    field: key,
    headerName: t(`placement.profile.tabs.auditsGrid.columns.${key}`),
  };
};

const checkCellContentAccess = (rowData, user) => {
  return (
    ((rowData.auditType === auditConstants.amnEvaluation || rowData.auditType === auditConstants.applicationRef) &&
      (rowData.auditCategory === auditConstants.conviction || rowData.auditCategory === auditConstants.dmvHit) &&
      Authorized(
        [
          userRoles.recruitment,
          userRoles.recruitment_Leadership,
          userRoles.recruitment_TeamMember,
          userRoles.accountManagement,
          userRoles.accountManagement_Leadership,
          userRoles.accountManagement_TeamMember,
          userRoles.customerSupport_Leadership,
          userRoles.customerSupport_TeamMember,
          userRoles.credentialing,
          userRoles.credentialing_Leadership,
          userRoles.credentialing_TeamMember,
        ],
        user.userInfo,
      )) ||
    ((rowData.auditType === auditConstants.amnEvaluation || rowData.auditType === auditConstants.applicationRef) &&
      rowData.auditCategory === auditConstants.licenseInv &&
      Authorized([userRoles.customerSupport_Leadership, userRoles.customerSupport_TeamMember], user.userInfo))
  );
};

export const getColumns = (
  t,
  forceClose,
  user,
  onHandleEdit,
  setEnableDelete,
  setAuditType,
  checkBoxChange: (rowID, isSelected, params) => void,
) =>
  [
    {
      field: 'isSelected',
      headerName: '',
      width: 60,
      renderCell: params => (
        <Radio
          checked={params.row?.isSelected}
          onChange={(event, param) => {
            params.row[params.field] = event.target.checked;
            setEnableDelete(!event.target.checked);
            setAuditType(params.row?.auditType);
            checkBoxChange(params.row.auditId, event.target.checked, params);
          }}
        />
      ),
      align: 'center',
    },
    {
      ...getColumnDefinition(t, 'auditType'),
      width: 180,
      renderCell: params => <GridCellEditAudit params={params} onHandleEdit={onHandleEdit} />,
    },
    { ...getColumnDefinition(t, 'status'), width: DEFAULT_COLUMN_WIDTH },
    { ...getColumnDefinition(t, 'holdFileOut'), width: 130 },
    { ...getColumnDefinition(t, 'strikeApproved'), width: 140 },
    { ...getColumnDefinition(t, 'auditCategory'), width: 150 },
    { ...getColumnDefinition(t, 'auditSubCategory'), width: 130 },
    { ...getColumnDefinition(t, 'auditAction'), width: 130 },
    {
      ...getColumnDefinition(t, 'personResponsible'),
      width: 200,
    },
    {
      flex: 0,
      filterable: false,
      field: 'description',
      headerName: t(`placement.profile.tabs.auditsGrid.columns.description`),
      renderCell: XGridOverflowCell(undefined, undefined, undefined, checkCellContentAccess, user)(),
      width: 220,
    },
    {
      ...getColumnDefinition(t, 'placementId'),
      width: 110,
      renderCell: XGridOverflowCell(
        XGridLinkCell('/placement/{0}', ['placementId'], true, {
          checkIfCurrentPath: true,
          handleFalseClick: forceClose,
        }),
      )(
        XGridLinkCell('/placement/{0}', ['placementId'], true, {
          checkIfCurrentPath: true,
          handleFalseClick: forceClose,
        }),
      ),
    },
    {
      ...getColumnDefinition(t, 'insertDate'),
      width: 150,
      type: XGRID_COLUMN_TYPE.DATE,
      sortComparator: xGridDateSort,
    },
    {
      ...defaultColumnProps,
      field: 'insertedByUser',
      headerName: t(`placement.profile.tabs.auditsGrid.columns.enteredBy`),
      width: 130,
    },
    {
      ...getColumnDefinition(t, 'lastUpdated'),
      field: 'lastUpdated',
      width: 150,
      type: XGRID_COLUMN_TYPE.DATE,
      sortComparator: xGridDateSort,
    },
    {
      ...getColumnDefinition(t, 'lastUpdatedByUser'),
      width: DEFAULT_COLUMN_WIDTH,
    },
    { ...getColumnDefinition(t, 'actionable'), width: 130 },
    { ...getColumnDefinition(t, 'group'), width: 130 },
  ] as GridColumns;
