import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActionButtonWrapper } from '@AMIEWEB/Common/SubmissionPreference/ActionButtonWrapper/ActionButtonWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { DevTool } from '@hookform/devtools';
import { validationSchema } from '@AMIEWEB/Common/SubmissionPreference/Common/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  checkSendToError,
  deriveAttentionToOpts,
  deriveSendToOpts,
  FormNames,
  isSendToExisted,
} from '@AMIEWEB/Common/SubmissionPreference/Common/helper';
import {
  ISubmissionEmail,
  ISubmissionPacket,
  getFormattedSubmissionEmail,
  getFormattedSubmissionPacket,
} from './helper/unitHelper';
import { usePromiseTracker } from 'react-promise-tracker';
import { Expandable } from '@AMIEWEB/Common';
import { Title } from '@AMIEWEB/Order/OrderDetails/OrderPreferences/customComponents/OrderPreferencesDesign';
import { ConfirmationDialog } from '@AMIEWEB/Settings/AutoTaskManagement/common/ConfirmationDialog';
import { initialSendPacketState } from '@AMIEWEB/Common/SubmissionPreference/SubmissionLimit/SendPacketAutomation';
import { ff_unit_ui_newpreference_rfo_to_sent } from 'app/constants/FeatureFlags/Facility/Keys';
import { useDecision } from '@optimizely/react-sdk';
import {
  selectConfirmationDialog,
  selectExistingOrdersForUnit,
  selectExistingPlacements,
  selectFacilityUnitClientContacts,
  selectMiscellaneousData,
  selectPreferenceChanged,
  selectPreferenceSelectionModal,
  selectTabValue,
  selectUnitPreference,
  selectUpdateInprogres,
  selectUpdatePreferenceDialog,
} from 'store/redux-store/unit-preferences/selectors';
import { selectPacketCategoryTitles } from 'store/redux-store/notification/notification.selector';
import { unitPreferenceActions } from 'store/redux-store/unit-preferences/slice';
import { initialPacketState } from '@AMIEWEB/Common/SubmissionPreference/SubmissionPacket/SubmissionPacketSelector';
import { FacilityUnitActions } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.redux';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { userRoles } from 'oidc/userRoles';
import { Authorized } from 'oidc/userHelper';
import { UpdatePreferencesModal } from '@AMIEWEB/Common/SubmissionPreference/UpdatePreferenes/UpdatePreferencesModal';
import { extractRows, getExistngOrdersColumns } from '@AMIEWEB/Common/SubmissionPreference/UpdatePreferenes/utils';
import { selectGridSelection } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.selector';
import { isValidJSONString } from 'utils/string/string';
import { selectUser } from 'oidc/user.selectors';
import { selectUnit } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';
import { gridSelectionActions } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.redux';
import { navigationStickActions } from '@AMIEWEB/GlobalNavigationMenu/NavigationStick.redux';
import { facilityMenu } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/PreferencesTab/FacilityPreferences';
import { SubmissionLimitsWrapper } from '@AMIEWEB/Common/SubmissionPreference/SubmissionLimit/SubmissionLimitsWrapper';
import { SubmissionPacketWrapper } from '@AMIEWEB/Common/SubmissionPreference/SubmissionPacket/SubmissionPacketWrapper';
import { SubmissionEmailWrapper } from '@AMIEWEB/Common/SubmissionPreference/SubmissionEmail/SubmissionEmailWrapper';
import { selectFacility } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { AMNDivisionType } from 'app/models/enums/AMNDivisionType';
import { UnitAutomationAction } from './UnitAutomationAction/UnitAutomationAction';

export const AddOnContext = createContext<{ facilityId: number; unitId: number }>({ facilityId: 0, unitId: 0 });

export const UnitPreferences = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams<{ unitId: string; id: string }>();
  const [rfoFlag] = useDecision(ff_unit_ui_newpreference_rfo_to_sent);
  const unitId = parseInt(params.unitId);
  const facilityId = parseInt(params.id);
  const history = useHistory();
  const location = useLocation();

  const clientContacts = useSelector(selectFacilityUnitClientContacts);
  const categoryTitles = useSelector(selectPacketCategoryTitles);
  const { disableEmailContainer, disablePacketContainer } = useSelector(selectMiscellaneousData);
  const dialogOpen = useSelector(selectConfirmationDialog);
  const tabValue = useSelector(selectTabValue);
  const preferenceChanged = useSelector(selectPreferenceChanged);

  const { userInfo } = useSelector(selectUser);
  const {
    rfoToSent,
    wfcToRfo,
    candidateTasks,
    sendPacket: apiSendPacket,
    savePacket,
    submissionEmailSetup,
    submissionPacketSetup,
  } = useSelector(selectUnitPreference);
  const user = useSelector(selectUser);
  const updateDialogOpen = useSelector(selectUpdatePreferenceDialog);
  const existingOrders = useSelector(selectExistingOrdersForUnit);
  const unitDetails = useSelector(selectUnit);
  const inprogres = useSelector(selectUpdateInprogres);
  const selectedData = useSelector(selectPreferenceSelectionModal);
  const { facilityResponse } = useSelector(selectFacility);
  const {
    submissionLimits: { activePlacementCounts },
  } = useSelector(selectUnitPreference);

  const [rows, setRows] = useState<any[]>([...extractRows(existingOrders)]);
  const [columns, setColumns] = useState<any[]>([...getExistngOrdersColumns(t)]);
  const [saveDialogOpen, SetSaveDialogOpen] = useState<boolean>(false);
  const [openClearChangesConfirmation, setOpenClearChangesConfirmation] = useState(false);
  const [openAutomationAction, setOpenAutomationAction] = React.useState<boolean>(false);
  const [triggerExit, setTriggerExit] = useState({
    isExit: false,
    path: '',
  });

  const { promiseInProgress: updatingAutomationStatus } = usePromiseTracker({
    area: 'update-unit-preferences-to-orders',
    delay: 0,
  });
  const { promiseInProgress: isFetchingPlacementCounts } = usePromiseTracker({
    area: 'unit-preference-active-placement-counts',
    delay: 0,
  });

  const { promiseInProgress: isSaving } = usePromiseTracker({
    area: `update-unit-preferences`,
    delay: 0,
  });

  const { promiseInProgress: isFetchingPref } = usePromiseTracker({
    area: 'get-unit-preferences',
    delay: 0,
  });

  const defaultFormValues = {
    maxFilesSent: false,
    reason: null,
    submissionLimit: { noLimit: false, limit: null },
    wfcToRfo: false,
    candidateTasks: false,
    rfoToSent: false,
    sendPacket: { save: false, saveAndSend: false, doNotSave: false },
    packetTemplate: initialPacketState,
    emailSettings: '',
    emailTemplate: null,
    attentionTo: [],
    sendTo: [],
    noteToFacility: '',
  };

  const formMethods = useForm({
    defaultValues: defaultFormValues,
    resolver: !disableEmailContainer ? yupResolver(validationSchema) : null,
    mode: 'onChange',
  });

  const {
    formState: { dirtyFields },
    reset,
    handleSubmit,
    getValues,
    trigger,
    errors,
  } = formMethods;

  const isDirty = disableEmailContainer
  ? Object.keys(dirtyFields)?.filter(key => key !== FormNames.SendTo)?.length !== 0
  : Object.keys(dirtyFields)?.length !== 0;

  const onSubmit = data => {
    if (isDirty) {
      updatePreferences(data);
    }
  };

  const generatePayload = data => {
    const payload = {
      unitId,
      rfoToSent: data?.rfoToSent,
      sendPacket: data.sendPacket.saveAndSend,
      savePacket: data.sendPacket.save || data.sendPacket.saveAndSend,
      submissionEmailSetup: data.sendPacket.saveAndSend ? getFormattedSubmissionEmail(data) : null,
      submissionPacketSetup:
        data.sendPacket.save || data.sendPacket.saveAndSend ? getFormattedSubmissionPacket(data) : null,
      wfcToRfo: data?.wfcToRfo,
      candidateTasks: data?.candidateTasks,
      updatedBy: userInfo.employeeId,
    };
    return payload;
  };

  const updatePreferences = (data, isCopyingPreferences = false) => {
    const payload = generatePayload(data);
    if (!payload.sendPacket || (payload.sendPacket && data?.sendTo?.length > 0)) {
      dispatch(unitPreferenceActions.updateUnitPreferences({ data: payload, isCopyingPreferences }));
    }
  };

  const resetForm = React.useCallback(() => {
    const packetSetup: ISubmissionPacket = isValidJSONString(submissionPacketSetup)
      ? JSON.parse(submissionPacketSetup)
      : null;

    const emailSetup: ISubmissionEmail = isValidJSONString(submissionEmailSetup)
      ? JSON.parse(submissionEmailSetup)
      : null;

    const _sendPacket = rfoToSent
      ? {
          save: savePacket && !apiSendPacket,
          saveAndSend: apiSendPacket && savePacket,
          doNotSave: !savePacket,
        }
      : initialSendPacketState;

    const disablePacketContainer = !((_sendPacket.saveAndSend || _sendPacket.save) && rfoToSent && rfoFlag.enabled);

    dispatch(
      unitPreferenceActions.setMiscellaneousData({
        key: 'disablePacketContainer',
        value: disablePacketContainer,
      }),
    );

    const disableEmailContainer = !(_sendPacket.saveAndSend && rfoToSent && rfoFlag.enabled);

    dispatch(
      unitPreferenceActions.setMiscellaneousData({
        key: 'disableEmailContainer',
        value: disableEmailContainer,
      }),
    );

    const _attentionTo = deriveAttentionToOpts(clientContacts, packetSetup?.attentionTo, disablePacketContainer);

    const _sendTo = deriveSendToOpts(clientContacts, emailSetup?.sendTo, disableEmailContainer);

    if (!disableEmailContainer && !isSendToExisted(_sendTo)) {
      trigger(['sendTo']);
    }

    reset({
      wfcToRfo,
      candidateTasks,
      rfoToSent,
      sendPacket: _sendPacket,
      packetTemplate: disablePacketContainer ? initialPacketState : packetSetup?.packetTemplate,
      emailSettings: disableEmailContainer ? null : emailSetup?.bulkEmailSettings,
      emailTemplate: emailSetup?.emailTemplateId ?? categoryTitles?.[0]?.id ?? null,
      attentionTo: _attentionTo,
      sendTo: _sendTo,
      noteToFacility: disablePacketContainer ? '' : packetSetup?.noteToFacility,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    apiSendPacket,
    candidateTasks,
    categoryTitles,
    clientContacts,
    rfoToSent,
    savePacket,
    submissionEmailSetup,
    submissionPacketSetup,
    wfcToRfo,
    rfoFlag,
    trigger,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    () => resetForm(),
    [
      wfcToRfo,
      candidateTasks,
      rfoToSent,
      savePacket,
      apiSendPacket,
      submissionPacketSetup,
      submissionEmailSetup,
      rfoFlag,
      clientContacts,
    ],
  );

  useEffect(() => {
    if (isDirty !== preferenceChanged) {
      dispatch(unitPreferenceActions.setIsPreferenceChaged(isDirty));
    }
  }, [isDirty]);

  const handleGoToIntendedLocation = useCallback(
    location => {
      if (location === `/facility/${facilityId}`) {
        dispatch(FacilityUnitActions.setSelectedUnit(null));
      } else if (tabValue === 1) {
        dispatch(FacilityUnitActions.setSelectedUnit(null));
        dispatch(facilityActions.setFacilityData(null));
      }
      history.push({ pathname: location, search: tabValue !== 1 ? `tab=${tabValue}` : null });
    },
    [history, tabValue],
  );

  useEffect(() => {
    if (triggerExit.isExit) {
      handleGoToIntendedLocation(triggerExit.path);
    }
    //@ts-ignore
    const unblock = history.block(loc => {
      if (loc.pathname !== location.pathname && isDirty) {
        dispatch(unitPreferenceActions.setConfirmationDialog(true));
      }
      setTriggerExit(obj => ({ ...obj, path: loc.pathname }));
      if (triggerExit.isExit || !isDirty) {
        return true;
      }
      return false;
    });

    return () => {
      unblock();
    };
  }, [handleGoToIntendedLocation, history, triggerExit.isExit, triggerExit.path, isDirty]);

  //handling modal action items::
  const handleKeepChanges = () => {
    dispatch(unitPreferenceActions.setConfirmationDialog(false));
    setTriggerExit(obj => ({
      ...obj,
      path: location.pathname,
      isExit: false,
    }));
    dispatch(navigationStickActions.setSelectedMenu(facilityMenu));
  };

  const discardChanges = () => {
    dispatch(unitPreferenceActions.setConfirmationDialog(false));
    dispatch(unitPreferenceActions.setIsPreferenceChaged(false));
    setTriggerExit(obj => ({
      ...obj,
      isExit: true,
    }));
  };

  const handleClearChangesConfirmClose = (clearValues: boolean) => {
    setOpenClearChangesConfirmation(false);
    if (clearValues) {
      resetForm();
    }
  };

  //eslint-disable-next-line prettier/prettier
  useEffect(() => setRows(extractRows(existingOrders)), [existingOrders]);

  const handleResetColumns = () => {
    setColumns(getExistngOrdersColumns(t));
  };

  const handleGridRefresh = () => {
    dispatch(unitPreferenceActions.getExistingOrders({ unitId, facilityId }));
  };

  const handleDiscardAction = () => {
    resetForm();
    SetSaveDialogOpen(false);
    handleUpdateDialog(true);
  };

  const handleSaveAction = () => {
    if (isDirty) {
      updatePreferences(getValues(), true);
    }
    SetSaveDialogOpen(false);
  };

  const handleUpdateDialog = (isOpen: boolean) => {
    dispatch(unitPreferenceActions.setPreferenceUpdateDialog(isOpen));
  };

  const handleInheritCancel = () => {
    handleUpdateDialog(false);
    dispatch(gridSelectionActions.setGridSelections({ selectedData: [] }));
    dispatch(unitPreferenceActions.setPreferenceSelectionModal([]));
  };
  const saveDialogActions = [
    {
      text: t(`autoTaskManagement.button.cancel`),
      onClick: () => SetSaveDialogOpen(false),
    },
    {
      text: t(`autoTaskManagement.button.discard`),
      onClick: handleDiscardAction,
      color: 'tertiary',
      variant: 'contained',
    },
    {
      text: t(`autoTaskManagement.button.save`),
      variant: 'contained',
      color: 'primary',
      onClick: handleSaveAction,
    },
  ];

  const savePreferencesToOrders = () => {
    dispatch(unitPreferenceActions.requestOrderActivePlacementCounts({ data: { orderIds: selectedData } }));
    dispatch(unitPreferenceActions.requestExistingPlacements({ data: { orderIds: selectedData } }));
    dispatch(unitPreferenceActions.requestReadyToSubmitRfoPlacementIds({ data: { orderIds: selectedData } }));
    setOpenAutomationAction(true);
    handleUpdateDialog(false);
  };

  const confirmEnableAutomation = newAutomation => {
    const payload = {
      orderIds: selectedData,
      unitId,
      updatedBy: user.userInfo.employeeId,
    };
    dispatch(unitPreferenceActions.updatePrefrencesToOrders({ data: payload, facilityId: facilityId }));
    dispatch(gridSelectionActions.setGridSelections({ selectedData: [] }));
    dispatch(unitPreferenceActions.setPreferenceSelectionModal([]));
    setOpenAutomationAction(false);
  };

  const handleCancel = () => {
    setOpenAutomationAction(false);
    handleUpdateDialog(true);
  };

  const updateContainerState = (
    isEnabled: boolean,
    containerName: 'disableEmailContainer' | 'disablePacketContainer',
  ) => {
    dispatch(
      unitPreferenceActions.setMiscellaneousData({
        key: containerName,
        value: isEnabled,
      }),
    );
  };

  const formData = getValues();
  const sendToError = useMemo(() => checkSendToError(formData, errors), [errors, formData]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <React.Fragment>
          <Expandable expanded titleComponent={<Title>{t('Submission')}</Title>} unmountOnExit={false}>
            <LayoutGrid xs={13} direction="column" data-testid="unit-submission-container" spacing={2}>
              <LayoutGridItem>
                <SubmissionLimitsWrapper
                  activePlacementCounts={activePlacementCounts}
                  clientContacts={clientContacts}
                  isFetchingPlacementCounts={isFetchingPlacementCounts}
                  isStrike={facilityResponse?.amnDivisionTypeId === AMNDivisionType.hsg}
                  flag={ff_unit_ui_newpreference_rfo_to_sent}
                  updateContainerState={updateContainerState}
                  submissionEmailSetup={submissionEmailSetup}
                  submissionPacketSetup={submissionPacketSetup}
                  isFetchingPreferences={isFetchingPref}
                />
              </LayoutGridItem>
              <LayoutGridItem>
                <SubmissionPacketWrapper disabled={disablePacketContainer} />
              </LayoutGridItem>
              <LayoutGridItem>
                <SubmissionEmailWrapper disabled={disableEmailContainer} facilityId={facilityId} />
              </LayoutGridItem>
            </LayoutGrid>
          </Expandable>
          <ActionButtonWrapper
            disableApplyButton={isDirty || sendToError}
            disableSubmit={!isDirty || sendToError}
            disableCancel={!isDirty}
            isSaving={isSaving}
            openClearChangesConfirmation={openClearChangesConfirmation}
            showApplyButton={Authorized(
              [
                userRoles.accountManagement,
                userRoles.accountManagement_Leadership,
                userRoles.accountManagement_TeamMember,
              ],
              user.userInfo,
            )}
            ApplyButtonTitle={t('order.orderDetails.buttonApplyToOrders')}
            handleApplyButtonClick={() => (!isDirty ? handleUpdateDialog(true) : SetSaveDialogOpen(true))}
            handleClearChanges={() => setOpenClearChangesConfirmation(true)}
            handleClearChangesConfirmClose={handleClearChangesConfirmClose}
          />
          <ConfirmationDialog
            dialogActions={saveDialogOpen ? saveDialogActions : undefined}
            dialogeOpen={dialogOpen || saveDialogOpen}
            keepChanges={handleKeepChanges}
            handleDiscard={discardChanges}
            dialogTitle={saveDialogOpen && t(`autoTaskManagement.dialogModal.saveChanges`)}
            dialogeText={saveDialogOpen && t(`autoTaskManagement.dialogModal.saveAndExit`)}
          />
          {updateDialogOpen && (
            <UpdatePreferencesModal
              open={updateDialogOpen}
              isExistingItems={existingOrders.length > 0}
              dialogTitleText={t(`Apply to Orders: ${unitDetails?.name} (UID ${unitId}) - ${unitDetails?.facilityName} (FID ${facilityId})`)}
              onCancel={handleInheritCancel}
              onConfirm={savePreferencesToOrders}
              isSubmittingAction={!selectedData.length}
              tableTitle={'Orders'}
              tableData={rows}
              columns={columns}
              onResetColumns={handleResetColumns}
              onGridRefresh={handleGridRefresh}
              selectedData={selectedData}
            />
          )}
          <UnitAutomationAction
            open={openAutomationAction}
            onCancel={handleCancel}
            onConfirm={() => confirmEnableAutomation(true)}
            isSubmittingAction={updatingAutomationStatus || inprogres}
          />
        </React.Fragment>
      </form>
      {/* <DevTool control={formMethods.control} /> */}
    </FormProvider>
  );
};
