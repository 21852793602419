import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import {
  EPLacementStatusRequirementTooltipType,
  IPlacementRequirementKey,
} from 'app/models/Placement/PlacementDetails';
import { requirementType } from 'app/models/Placement/PlacementSummary';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { trackPromise } from 'react-promise-tracker';
import { call, fork, getContext, put, select } from 'redux-saga/effects';
import {
  GetPlacementSummaryMessage,
  SetPlacementFailRiskRequirementMessage,
} from 'store/redux-store/new-placement/saga';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import { resetFailureStatus } from '../saga';
import { IPlacementSummaryRequest, placementSummaryActions } from '../slice';
import InitPlacementSummary, { IConsolidatedRequirementResponse } from '../init.types';
import { selectPlacementConsolidatedDetails } from '../consolidatedStoreSelectors';

const TrackLicenseOrderReqWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-license-order-requirement');

export function* processLicenseOrderRequirement(
  response: IConsolidatedRequirementResponse<any>,
  context: InitPlacementSummary,
) {
  try {
    if (!httpSuccess(response?.status)) {
      context.setServiceFailureByKey({ key: 'licenseOrderRequirement', failureStatus: true });
      return;
    }
    const data = GetPlacementSummaryMessage(response, context.basePayload.placementOrderType);

    context.setSubmissionInfoByKey({ key: 'licenses', ...data });
    if (!context.basePayload.isOrderGeneralRequirement) {
      yield fork(common, data);
      if ((data.candidateRequirement.data || []).some(x => x.type === requirementType.risk)) yield fork(common2, data);
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'processLicenseOrderRequirement',
        area: 'src/store/redux-store/placement-summary/common-saga/licenseOrderRequirement.ts',
      },
    });
  }
}

export function* requestLicenseOrderRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('licenseOrderRequirement'));
    const placementOrderType = yield select(selectPlacementOrderType);
    const placementService: PlacementService = yield getContext('placementService');

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if(action.payload.isInitLoad && consolidatedDetails){
      response = {
        status : consolidatedDetails.summary?.submission?.orderSubmissionBusinessRequrement?.licenses?.status,
        data : consolidatedDetails.summary?.submission?.orderSubmissionBusinessRequrement?.licenses?.data
      }
    }

    if(!response?.status || !response?.data){
      response = yield call(
        TrackLicenseOrderReqWrapper,
        placementService.getPlacementSummaryLicense_OrderReq,
        action.payload.placementId,
        placementOrderType,
      );
    }

    if (httpSuccess(response?.status)) {
      const { data } = response;
      yield put(placementSummaryActions.setSubmissionInfoByKey({ key: 'licenses', isOrderReq: true, ...data }));
      if (!action.payload.isOrderGeneralRequirement) {
        yield fork(common, data);

        if ((data.candidateRequirement.data || []).some(x => x.type === requirementType.risk))
          yield fork(common2, data);
      }
    } else
      yield put(
        placementSummaryActions.setServiceFailureByKey({ key: 'licenseOrderRequirement', failureStatus: true }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestLicenseOrderRequirement',
        area: 'src/store/redux-store/placement-summary/common-saga/licenseOrderRequirement.ts',
      },
    });
  }
}

function* common(data) {
  yield fork(SetPlacementFailRiskRequirementMessage, {
    payload: {
      messageId: data?.orderRequirement?.messageId,
      version: data?.orderRequirement?.messageVersion,
      passFailType: data?.orderRequirement?.type,
      data: data?.orderRequirement?.data,
      messageTypeId: IPlacementRequirementKey.orderLicense,
      tooltipTypeParam: EPLacementStatusRequirementTooltipType.messageWithSupportList,
      nextStatusRequirementParam: data.nextStatusRequirement,
    },
    type: placementSummaryActions.setFailedRequirement.type,
  });
}
function* common2(data) {
  yield fork(SetPlacementFailRiskRequirementMessage, {
    payload: {
      messageId: data?.orderRequirement?.messageId,
      version: 'v1',
      passFailType: requirementType.risk,
      data: data.candidateRequirement.data,
      messageTypeId: IPlacementRequirementKey.orderLicense,
      tooltipTypeParam: EPLacementStatusRequirementTooltipType.messageWithSupportList,
      nextStatusRequirementParam: data.nextStatusRequirement,
    },
    type: placementSummaryActions.setFailedRequirement.type,
  });
}
