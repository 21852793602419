/* eslint-disable i18next/no-literal-string */
import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import React from 'react';
import NoteIcon from 'app/assets/images/Notes.png';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import GetAppIcon from '@mui/icons-material/GetApp';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { isCredentialing } from 'oidc/userRoles';
import { isAddLicenseSelection } from '../../../Profile/CandidateDetails.selector';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

export enum AttachmentAction {
  view,
  add,
  download,
  delete,
}

const useStyles = makeStyles()(theme=>({
  icon: {
    color: '#B9BBBC',
    cursor: 'pointer',
    '&:hover': {
      color: '#006FB9',
    },
  },
  disableIcon: {
    color: theme.palette.framework.system.lightGray,
    cursor: 'not-allowed',
    '&:hover': {
      color: theme.palette.framework.system.lightGray,
    },
  },
})); 

export const CredentialAttachmentToolTip = ({
  onSelect,
  requirementID,
  requirementTypeID,
  disableEdit,
  useNewIcon,
}: {
  onSelect: (
    action: AttachmentAction,
    reqID: number,
    requirementTypeID: number,
    travelerId: number,
    brandId: number,
  ) => void;
  requirementID: number;
  requirementTypeID: number;
  travelerId: number;
  brandId: number;
  disableEdit?: boolean;
  useNewIcon?: boolean;
}) => {
  const { classes } = useStyles();
  const isAddLicense = useSelector(isAddLicenseSelection);
  const { travelerId, brandId } = useParams<any>();
  const user = useSelector(selectUser);
  const Content = () => (
    <Grid container spacing={2}>
      <Grid
        item
        onClick={
          typeof onSelect === 'function'
            ? () => onSelect(AttachmentAction.view, requirementID, requirementTypeID, travelerId, brandId)
            : undefined
        }
        data-testid="attachment-view"
      >
        <VisibilityIcon className={classes.icon} />
      </Grid>
      <Grid
        item
        onClick={() =>
          isCredentialing(user?.userInfo?.roles || []) &&
          !isAddLicense &&
          !disableEdit &&
          onSelect(AttachmentAction.add, requirementID, requirementTypeID, travelerId, brandId)
        }
        aria-label="attachment-add"
      >
        <AddCircleIcon
          className={
            isCredentialing(user?.userInfo?.roles || []) && !isAddLicense && !disableEdit
              ? classes.icon
              : classes.disableIcon
          }
        />
      </Grid>
      <Grid
        item
        onClick={() => onSelect(AttachmentAction.download, requirementID, requirementTypeID, travelerId, brandId)}
        aria-label="attachment-download"
      >
        <GetAppIcon className={classes.icon} />
      </Grid>
      <Grid
        item
        onClick={() =>
          isCredentialing(user?.userInfo?.roles || []) &&
          !isAddLicense &&
          !disableEdit &&
          onSelect(AttachmentAction.delete, requirementID, requirementTypeID, travelerId, brandId)
        }
        aria-label="attachment-delete"
      >
        <DeleteIcon
          className={
            isCredentialing(user?.userInfo?.roles || []) && !isAddLicense && !disableEdit
              ? classes.icon
              : classes.disableIcon
          }
        />
      </Grid>
    </Grid>
  );

  return (
    <div>
      <CustomTooltip
        contentStyle={{ display: 'inline-block' }}
        standardMargin
        inlineBlock
        tooltipContent={<Content />}
        interactive
        placement="bottom"
      >
        {useNewIcon ? (
          <DescriptionOutlinedIcon style={{ color: '#333' }} />
        ) : (
        <div>
          <img src={NoteIcon} alt="Document" data-testid="attachment-tooltip" />
        </div>
        )}
      </CustomTooltip>
    </div>
  );
};

// position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(1601px, 633px, 0px)
