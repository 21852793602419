import React from 'react';
import { CircularProgress, Grid, TextField } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { APIStatus } from 'app/enums/ApiStatus';

const useTextAreaStyles = makeStyles()(theme => ({
  textAreaStyle: {
    padding: '0px',
    overflowWrap: 'break-word',
    backgroundColor: 'transparent !important',
  },
  inlineBoxEditInputText: {
    width: '320px',
    'border-top': '0',
    'border-left': '0',
    'border-right': '0',
    'border-bottom-color': theme.palette.system.darkGrayFaded,
    '&:focus-visible': {
      outline: 'none',
      background: theme.palette.system.lightGrayFaded,
    },
  },
  inlineBoxEditItemPadding: {
    padding: '0 1px',
  },
  inlineBoxEditMainRejectItem: {
    'padding-left': '0.2rem',
    '& svg': {
      color: theme.palette.system.grayishLimeGreen,
      width: '20px',
      height: '20px !important',
      cursor: 'pointer',
    },
  },
  saveIcon: {
    '&:hover': {
      color: theme.palette.components.typography.success.color,
    },
  },
  closeIcon: {
    '&:hover': {
      color: theme.palette.system.red,
    },
  },
  saveSuccessText: {
    fontSize: '11px',
    paddingLeft: '4px',
    justifyContent: 'center',
    color: theme.palette.system.paleDarkGreen,
  },
  saveFailedText: {
    fontSize: '11px',
    justifyContent: 'center',
    color: theme.palette.system.red,
  },
}));

const TextArea = ({ value, onChange, onCheckIconClick, onCloseIconClick, saveStatus }) => {
  const { classes } = useTextAreaStyles();
  const { t } = useTranslation();
  return (
    <>
      <TextField
        variant="filled"
        multiline
        fullWidth
        id="text-area-notes-client-contacts"
        InputProps={{
          className: classes.textAreaStyle,
        }}
        className={classes.inlineBoxEditInputText}
        value={value}
        hiddenLabel
        type="string"
        onChange={event => onChange(event)}
      />
      <Grid item>
        <span>
          <span
            className={`${classes.inlineBoxEditItemPadding} ${classes.inlineBoxEditMainRejectItem}`}
            aria-hidden="true"
          >
            <CheckIcon className={classes.saveIcon} data-testid="saveIcon" onClick={onCheckIconClick} />
          </span>

          <span
            className={`${classes.inlineBoxEditItemPadding} ${classes.inlineBoxEditMainRejectItem}`}
            aria-hidden="true"
          >
            <CloseIcon className={classes.closeIcon} data-testid="closeIcon" onClick={onCloseIconClick} />
          </span>
        </span>
      </Grid>
      <Grid item>
        {saveStatus && (
          <>
            {saveStatus === APIStatus.isLoading && <CircularProgress style={{ width: '12px', height: '12px' }} />}
            {saveStatus === APIStatus.Completed && (
              <>
                <CheckIcon sx={{ width: '15px', height: '15px', color: theme => theme.palette.system.paleDarkGreen }} />
                <label className={classes.saveSuccessText}>{t('alerts.saved')}</label>
              </>
            )}
            {saveStatus === APIStatus.Error && (
              <>
                <CloseIcon sx={{ width: '15px', height: '15px', color: theme => theme.palette.system.red }} />
                <label className={classes.saveFailedText}>{t('alerts.failed')}</label>
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default TextArea;
