import { UnitCard } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/CustomComponents/UnitCard';
import React from 'react';
import { LayoutGrid } from 'app/layout/LayoutGrid';
import { useTranslation } from 'react-i18next';
import { Grid, Skeleton, Typography } from 'amn-ui-core';
import { useSelectedUnitDetails, useUnitStatusInActive, isAuthorizedForAddEdit } from './unitHelper';
import { TFunctionResult } from 'i18next';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { missingField } from 'app/constants';
import { usePromiseTracker } from 'react-promise-tracker';
import { checkRatioString } from 'utils/string/string';

const NurseToPatientRatio = () => {
  const { t } = useTranslation();
  const userData = useSelector(selectUser);
  const isAuthorized = isAuthorizedForAddEdit(userData);
  const selectedUnitDetail = useSelectedUnitDetails();
  const isUnitInActive = useUnitStatusInActive();
  const { promiseInProgress: isFacilityWithFilterDetailsLoading } = usePromiseTracker({
    area: 'get-facility-details-with-filters',
    delay: 0,
  });
  return (
    <>
      <UnitCard
        title={t('facility.units.clinicianToPatient.title')}
        rightIcon={true}
        showEditIcon={isAuthorized && !isUnitInActive}
        skeletonWidth={176}
        isDataLoading={isFacilityWithFilterDetailsLoading}
      >
        <LayoutGrid container sx={{ p: '12px', m: 0 }} direction="column">
          <>
            <Grid container direction="column" gap={1}>
              <GridSection
                label={t('facility.units.clinicianToPatient.title')}
                value={checkRatioString(selectedUnitDetail?.nurseRatio)}
                labelSkeletonWidth={131}
                ValueSkeletonWidth={37}
                isDataLoading={isFacilityWithFilterDetailsLoading}
              />
              <GridSection
                label={t('facility.units.clinicianToPatient.notifyCanditate')}
                value={selectedUnitDetail?.notifyCandidate ? t('facility.yes') : t('facility.no')}
                labelSkeletonWidth={131}
                ValueSkeletonWidth={37}
                isDataLoading={isFacilityWithFilterDetailsLoading}
              />
              <GridSection
                label={t('facility.units.clinicianToPatient.patientsSeen')}
                value={
                  selectedUnitDetail?.rangeOfPatientsSeen
                    ? selectedUnitDetail?.rangeOfPatientsSeen
                    : selectedUnitDetail?.patientsSeen ?? missingField
                }
                labelSkeletonWidth={85}
                ValueSkeletonWidth={59}
                isDataLoading={isFacilityWithFilterDetailsLoading}
              />
              <GridSection
                label={t('facility.units.clinicianToPatient.numberOfVisits')}
                value={
                  selectedUnitDetail?.rangeOfVisits
                    ? selectedUnitDetail?.rangeOfVisits
                    : selectedUnitDetail?.numberOfVisits ?? missingField
                }
                labelSkeletonWidth={120}
                ValueSkeletonWidth={34}
                isDataLoading={isFacilityWithFilterDetailsLoading}
              />
              <GridSection
                label={t('facility.units.clinicianToPatient.productivityPercentage')}
                value={
                  selectedUnitDetail?.productivityPercentage === null
                    ? missingField
                    : `${selectedUnitDetail?.productivityPercentage}%`
                }
                labelSkeletonWidth={75}
                ValueSkeletonWidth={92}
                isDataLoading={isFacilityWithFilterDetailsLoading}
              />
            </Grid>
          </>
        </LayoutGrid>
      </UnitCard>
    </>
  );
};

export default NurseToPatientRatio;

const GridSection = ({
  label,
  value,
  labelSkeletonWidth,
  ValueSkeletonWidth,
  isDataLoading,
}: {
  label: string;
  value: string | number | TFunctionResult;
  labelSkeletonWidth: number;
  ValueSkeletonWidth: number;
  isDataLoading: boolean;
}) => {
  return (
    <Grid container item>
      <Grid xs={isDataLoading ? 3 : 6}>
        {isDataLoading ? (
          <Skeleton variant="rectangular" width={labelSkeletonWidth} height={13} />
        ) : (
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {label}
          </Typography>
        )}
      </Grid>
      <Grid xs={6}>
        {isDataLoading ? (
          <Skeleton variant="rectangular" width={ValueSkeletonWidth} height={13} />
        ) : (
          <Typography variant="body1" mt={'-2px'}>
            {value}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
