import { CustomSelect } from '@AMIEWEB/Placement/CreatePlacement/CustomComponents/CustomSelect';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectMiscellaneousData } from 'store/redux-store/new-placement/selectors';

export const SelectScreen = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const { screenOptions } = useSelector(selectMiscellaneousData);

  return (
    <Controller
      control={control}
      name="screenOption"
      render={({ ref, ...rest }) => <CustomSelect options={screenOptions} label={t('Screen ')} {...rest} />}
    />
  );
};
