import {
  Box,
  Checkbox,
  Chip,
  Grid,
  GridSize,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import OrderShiftList from 'app/assets/jsons/OrderCreation/OrderShifts.json';
import { useTranslation } from 'react-i18next';
import { ShiftResponse } from 'app/models/Orders/OrderDetails';
import { IShiftsAvailable } from 'app/models/Orders/CreateOrder';
import { formatShiftResponse, formatShiftSave, getSelectedShiftList } from '../helper';
import { orderBy } from 'lodash';
interface Props {
  onOrderPropertyChange: (property: string, value: any) => void;
  defaultInputShiftList?: ShiftResponse[];
  checkCreateOrder: boolean;
  listWidth?: GridSize;
  disableEdit: boolean;
  chipLocation: 'top' | 'bottom';
}

const useStyles = makeStyles()(theme => ({
  wrapper: {
    backgroundColor: '#FFFFFF !important',
    border: '1px solid #CCCCCC',
    borderRadius: 5,
    minHeight: 300,
  },
  chipSpacing: {
    paddingRight: theme.spacing(2),
  },
  searchField: {
    borderRadius: '0px 0px 0px 0px',
    border: 'hidden',
  },
  listRoot: {
    borderTop: '1px solid #CCCCCC',
    maxHeight: '259px',
    overflowY: 'auto',
    width: '100%',
    padding: `0px`,
  },
  listItemRoot: {
    borderBottom: '1px solid #CCCCCC',
  },
  chipRoot: {
    marginBottom: theme.spacing(1),
  },
}));

export const ShiftsAvailableContainer = ({ chipLocation, ...props }: Props) => {
  const { onOrderPropertyChange, disableEdit } = props || {};
  const [shiftsAvailable, setShiftsAvailable] = useState<IShiftsAvailable[]>([]);
  const [shiftsSelected, setShiftsSelected] = useState<IShiftsAvailable[]>(
    props.defaultInputShiftList?.map(shift => formatShiftResponse(shift)) || [],
  );
  const [shiftChips, setShiftChips] = useState<IShiftsAvailable[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const { classes } = useStyles();
  const { t } = useTranslation();

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.preventDefault();
    setSearchValue(event.target.value);
  };

  const handleSelect = (shiftSelected: IShiftsAvailable) => () => {
    const isExisting = getSelectedShiftList(shiftsSelected, shiftSelected);

    let shiftSelectedList = [...shiftsSelected];
    if (!isExisting) {
      shiftSelectedList.push(shiftSelected);
    } else {
      shiftSelectedList = shiftSelectedList.filter(shiftsSelected => shiftsSelected.Id !== shiftSelected.Id);
    }
    setShiftsSelected(shiftSelectedList);
    setShiftChips(shiftSelectedList);
    onOrderPropertyChange(
      'shifts',
      props.checkCreateOrder
        ? shiftSelectedList.map(item => item.Description)
        : shiftSelectedList.map(item => formatShiftSave(item)),
    );
  };

  const handleDelete = (id: number) => {
    setShiftChips(shiftChips.filter(entry => entry.Id !== id));
    const selectedShiftItems = shiftsSelected.filter(x => x.Id !== id);
    setShiftsSelected(selectedShiftItems);
    onOrderPropertyChange(
      'shifts',
      props.checkCreateOrder
        ? selectedShiftItems.map(item => item.Description)
        : selectedShiftItems.map(item => formatShiftSave(item)),
    );
  };

  useEffect(() => {
    if (searchValue === '') {
      setShiftsAvailable(orderBy(OrderShiftList, 'Id', 'asc'));
    } else {
      setShiftsAvailable(
        orderBy(
          OrderShiftList.filter(shiftItem => shiftItem.Description.toLowerCase().includes(searchValue.toLowerCase())),
          'Id',
          'asc',
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    setShiftChips(shiftsSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftsSelected]);

  const ChipSection = (chip: IShiftsAvailable) => {
    return (
      <>
          <span className={classes.chipSpacing} data-editingrow="true">
            <Chip
              classes={{ root: classes.chipRoot }}
              variant="outlined"
              key={chip.Id}
              label={<React.Fragment>{chip.Description}</React.Fragment>}
              onDelete={() => handleDelete(chip.Id)}
              disabled={disableEdit}
            />
          </span>
      </>
    );
  };

  return (
    <>
      <Grid container direction="column" spacing={3}>
        {chipLocation === 'top' && (
          <Grid item>
            {shiftChips && shiftChips.map(chip => <ChipSection Id={chip.Id} Description={chip.Description} />)}
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={props.listWidth || 3}>
              <Box className={classes.wrapper}>
                <TextField
                  variant="outlined"
                  disabled={disableEdit}
                  color="primary"
                  placeholder={t('order.facility.orderCreation.shift.searchPlaceholder')}
                  autoComplete="off"
                  id="active"
                  value={searchValue}
                  onChange={event => handleSearch(event)}
                  fullWidth
                  InputProps={{
                    startAdornment: <SearchIcon style={{ opacity: 0.6, marginRight: 12 }} />,
                    classes: { notchedOutline: classes.searchField },
                  }}
                />
                <List
                  classes={{
                    root: classes.listRoot,
                  }}
                  id={'orderCreationshiftSearchItemsContainer'}
                >
                  {shiftsAvailable.map(shiftValue => {
                    const labelId = `checkbox-list-label-${shiftValue}`;
                    return (
                      <ListItem
                        key={shiftValue.Id}
                        role={undefined}
                        classes={{ root: classes.listItemRoot }}
                        dense
                        button
                        onClick={handleSelect(shiftValue)}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            disabled={disableEdit}
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                            value={shiftValue.Description}
                            checked={getSelectedShiftList(shiftsSelected, shiftValue)}
                            color="primary"
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`${shiftValue.Description}`}></ListItemText>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {chipLocation === 'bottom' && (
          <Grid item id={'orderCreationshiftSelectedItemsContainer'}>
            {shiftChips && shiftChips.map(chip => <ChipSection Id={chip.Id} Description={chip.Description} />)}
          </Grid>
        )}
      </Grid>
    </>
  );
};
