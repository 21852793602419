import { XGridOverflowCell } from './XGridOverflowCell';

export const XGridDefaultColumn = {
  flex: 1,
  renderCell: XGridOverflowCell()(),
  filterable: true,
};

export const XGridCellPadding = 8;

export enum XGRID_COLUMN_TYPE {
  DATE = 'date',
  ARRAY = 'array',
}
