import { Divider, Grid, Typography } from 'amn-ui-core';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { ControlledTypeAhead } from '../../../PreferencesTab/CustomComponents/ControlledTypeAhead';
import { useFormContext } from 'react-hook-form';
import { PreferenceGridBuilder } from './Helpers/PreferenceGridBuilder';
import {
  FormValues,
  GridType,
  IPreferenceAdultsRow,
  IPreferenceChildrenRow,
  IPreferenceGridRow,
  IPreferencePetsRow,
  dropdownOptionFormat,
  flattenAdultsData,
  flattenChildrenData,
  flattenPetsData,
  getAdultsColumns,
  getChildrenColumns,
  getPetsColumns,
} from './Helpers/PreferencesHelper';
import { lookupSelector } from 'store/redux-store/lookup/lookup.selectors';
import { useSelector } from 'react-redux';
import { candidateDetailsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';

const useStyles = makeStyles()(() => ({
  wrapper: {
    maxHeight: '600px',
    overflowY: 'scroll',
  },
  container: {
    padding: '25px 12px 25px 25px',
  },
  title: {
    fontSize: '14px !important',
    fontWeight: 600,
  },
  divider: {
    padding: '5px 0px',
  },
  grid: {
    paddingBottom: '12px !important',
  },
}));

interface IPreferenceEditContentProps {
  gridRows: IPreferenceGridRow;
  setGridRows: (value: IPreferenceGridRow) => void;
}

export const PreferencesEditContent = (props: IPreferenceEditContentProps) => {
  const { gridRows, setGridRows } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const lookupSelection = useSelector(lookupSelector);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const { errors, setValue, register, control } = useFormContext();

  useEffect(() => {
    setValue(
      FormValues.housingType,
      dropdownOptionFormat(lookupSelection?.housingType)?.find(
        item => item.label === candidateDetails?.preferences?.housingType,
      ),
    );
    setValue(
      FormValues.furnitureExtras,
      dropdownOptionFormat(lookupSelection?.furnitureExtras)?.filter(
        item =>
          candidateDetails?.preferences?.furnitureExtras?.filter(x => x.id === parseInt(item.object.ID))?.length > 0,
      ),
    );
    setValue(
      FormValues.typeOfBed,
      dropdownOptionFormat(lookupSelection?.typeBed)?.filter(
        item => candidateDetails?.preferences?.typeOfBeds?.filter(x => x.id === parseInt(item.object.ID))?.length > 0,
      ),
    );
    setValue(
      FormValues.keyFacts,
      dropdownOptionFormat(lookupSelection?.keyFacts)?.filter(
        item => candidateDetails?.preferences?.keyFacts?.filter(x => x.id === parseInt(item.object.ID))?.length > 0,
      ),
    );
    setGridRows({
      adults: flattenAdultsData(
        candidateDetails?.preferences?.adults,
        candidateDetails?.preferences?.isVerifiedNoAdults,
      ),
      children: flattenChildrenData(
        candidateDetails?.preferences?.children,
        candidateDetails?.preferences?.isVerifiedNoChildren,
      ),
      pets: flattenPetsData(candidateDetails?.preferences?.pets, candidateDetails?.preferences?.isVerifiedNoPets),
    });
  }, [candidateDetails?.preferences, lookupSelection]);

  const handleChange = (name: string, value: any) => {
    setValue(name, value);
  };

  const handleRowChange = (type: GridType, row: IPreferenceAdultsRow | IPreferenceChildrenRow | IPreferencePetsRow) => {
    setGridRows({
      ...gridRows,
      [type]: gridRows[type].map(item => {
        if (item.rowID === row.rowID) {
          return { ...row };
        }
        return item;
      }),
    });
    return row;
  };

  const handleRowSelection = (type: GridType, ids: any[]) => {
    setGridRows({
      ...gridRows,
      [type]: gridRows[type].map(item => {
        if (ids?.includes(item.rowID)) {
          return {
            ...item,
            isSelected: true,
          };
        } else {
          return {
            ...item,
            isSelected: false,
          };
        }
      }),
    });
  };

  const onAddRow = (gridType: GridType) => {
    switch (gridType) {
      case GridType.adults:
        setGridRows({
          ...gridRows,
          adults: gridRows.adults.find(item => item.rowID === 0)
            ? [
                {
                  rowID: 1,
                  name: null,
                  relationship: null,
                  isSelected: false,
                },
              ]
            : [
                ...gridRows.adults,
                {
                  rowID: gridRows.adults.length + 1,
                  name: null,
                  relationship: null,
                  isSelected: false,
                },
              ],
        });
        return;
      case GridType.children:
        setGridRows({
          ...gridRows,
          children: gridRows.children.find(item => item.rowID === 0)
            ? [
                {
                  rowID: 1,
                  gender: null,
                  age: null,
                  isSelected: false,
                },
              ]
            : [
                ...gridRows.children,
                {
                  rowID: gridRows.children.length + 1,
                  gender: null,
                  age: null,
                  isSelected: false,
                },
              ],
        });
        return;
      case GridType.pets:
        setGridRows({
          ...gridRows,
          pets: gridRows.pets.find(item => item.rowID === 0)
            ? [
                {
                  rowID: 1,
                  type: null,
                  breed: null,
                  weight: null,
                  details: null,
                  isSelected: false,
                },
              ]
            : [
                ...gridRows.pets,
                {
                  rowID: gridRows.pets.length + 1,
                  type: null,
                  breed: null,
                  weight: null,
                  details: null,
                  isSelected: false,
                },
              ],
        });
    }
  };

  const onDeleteRow = (gridType: GridType) => {
    switch (gridType) {
      case GridType.adults:
        const filteredAdults = gridRows.adults.filter(item => !item.isSelected);
        setGridRows({
          ...gridRows,
          adults: filteredAdults.length > 0 ? filteredAdults : flattenAdultsData([], false),
        });
        return;
      case GridType.children:
        const filteredChildren = gridRows.children.filter(item => !item.isSelected);
        setGridRows({
          ...gridRows,
          children: filteredChildren.length > 0 ? filteredChildren : flattenChildrenData([], false),
        });
        return;
      case GridType.pets:
        const filteredPets = gridRows.pets.filter(item => !item.isSelected);
        setGridRows({
          ...gridRows,
          pets: filteredPets.length > 0 ? filteredPets : flattenPetsData([], false),
        });
        return;
    }
  };

  const getRowCount = useCallback(
    (gridType: GridType) => {
      switch (gridType) {
        case GridType.adults:
          const selectedAdults = gridRows.adults.filter(item => item.isSelected === true && item.rowID !== 0);
          return selectedAdults.length > 0
            ? selectedAdults.length
            : gridRows.adults.filter(item => item.rowID !== 0).length;
        case GridType.children:
          const selectedChildren = gridRows.children.filter(item => item.isSelected === true && item.rowID !== 0);
          return selectedChildren.length > 0
            ? selectedChildren.length
            : gridRows.children.filter(item => item.rowID !== 0).length;
        case GridType.pets:
          const selectedPets = gridRows.pets.filter(item => item.isSelected === true && item.rowID !== 0);
          return selectedPets.length > 0 ? selectedPets.length : gridRows.pets.filter(item => item.rowID !== 0).length;
      }
    },
    [gridRows],
  );

  return (
    <div className={classes.wrapper}>
      <Grid container direction="column" spacing={2} className={classes.container} wrap={'nowrap'}>
        <Grid item>
          <Typography className={classes.title}>{t('candidate.preferenceCard.housingInfo')}</Typography>
        </Grid>
        <Grid item>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={4}>
              <ControlledTypeAhead
                name={FormValues.housingType}
                label={t('candidate.preferenceCard.housingType')}
                register={register}
                control={control}
                options={dropdownOptionFormat(lookupSelection?.housingType)}
                onChange={value => handleChange(FormValues.housingType, value)}
                isError={errors?.[FormValues.housingType]}
                showDropdownIcon
                adjustAxis
              />
            </Grid>
            <Grid item xs={4}>
              <ControlledTypeAhead
                name={FormValues.furnitureExtras}
                label={t('candidate.preferenceCard.furnitureExtras')}
                register={register}
                control={control}
                options={dropdownOptionFormat(lookupSelection.furnitureExtras)}
                onChange={value => handleChange(FormValues.furnitureExtras, value)}
                isError={errors?.[FormValues.furnitureExtras]}
                showDropdownIcon
                multiple
              />
            </Grid>
            <Grid item xs={4}>
              <ControlledTypeAhead
                name={FormValues.typeOfBed}
                label={t('candidate.preferenceCard.typeOfBed')}
                options={dropdownOptionFormat(lookupSelection.typeBed)}
                register={register}
                control={control}
                onChange={value => handleChange(FormValues.typeOfBed, value)}
                isError={errors?.[FormValues.typeOfBed]}
                showDropdownIcon
                multiple
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item>
          <Typography className={classes.title}>{t('candidate.preferenceCard.candidateInfo')}</Typography>
        </Grid>
        <Grid item className={classes.grid}>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={4}>
              <ControlledTypeAhead
                name={FormValues.keyFacts}
                label={t('candidate.preferenceCard.keyFacts')}
                register={register}
                control={control}
                options={dropdownOptionFormat(lookupSelection.keyFacts)}
                onChange={value => handleChange(FormValues.keyFacts, value)}
                isError={errors?.[FormValues.keyFacts]}
                showDropdownIcon
                multiple
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.grid} xs={12}>
          <PreferenceGridBuilder
            title={t('candidate.preferenceCard.adults')}
            gridType={GridType.adults}
            rows={gridRows.adults}
            selected={gridRows.adults.filter(item => item.isSelected === true).map(item => item.rowID)}
            columns={getAdultsColumns(t)}
            handleRowChange={handleRowChange}
            handleRowSelection={handleRowSelection}
            onAddRow={onAddRow}
            onDeleteRow={onDeleteRow}
            getRowCount={getRowCount}
          />
        </Grid>
        <Grid item className={classes.grid} xs={12}>
          <PreferenceGridBuilder
            title={t('candidate.preferenceCard.children')}
            gridType={GridType.children}
            rows={gridRows.children}
            selected={gridRows.children.filter(item => item.isSelected === true).map(item => item.rowID)}
            columns={getChildrenColumns(t)}
            handleRowChange={handleRowChange}
            handleRowSelection={handleRowSelection}
            onAddRow={onAddRow}
            onDeleteRow={onDeleteRow}
            getRowCount={getRowCount}
          />
        </Grid>
        <Grid item className={classes.grid} xs={12}>
          <PreferenceGridBuilder
            title={t('candidate.preferenceCard.pets')}
            gridType={GridType.pets}
            rows={gridRows.pets}
            selected={gridRows.pets.filter(item => item.isSelected === true).map(item => item.rowID)}
            columns={getPetsColumns(t)}
            handleRowChange={handleRowChange}
            handleRowSelection={handleRowSelection}
            onAddRow={onAddRow}
            onDeleteRow={onDeleteRow}
            getRowCount={getRowCount}
          />
        </Grid>
      </Grid>
    </div>
  );
};
