import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid, Typography } from 'amn-ui-core';
import AddIcon from '@mui/icons-material/Add';
import { selectSelectedWorkExperience } from 'store/redux-store/candidate-work-experience/selector';
import { getTransformedReferenceList, hasAccess } from './ReferenceUtils';
import AddReferenceModal from './AddReferenceModal';
import { useAddEditReferenceStyle } from './AddEditReference.style';
import { selectUser } from 'oidc/user.selectors';

const ReferenceWrapper = () => {
  const { t } = useTranslation();
  const { classes } = useAddEditReferenceStyle();
  const user = useSelector(selectUser);
  const canAddReference = useMemo(() => hasAccess(user), [user]);
  const [addNewReference, setAddNewReference] = useState<boolean>(false);
  const newReferenceSectionRef = useRef(null);
  const selectedWorkExperience = useSelector(selectSelectedWorkExperience);
  const [workExpReferences, setWorkExpReferences] = useState<any>([]);

  useEffect(() => {
    setAddNewReference(false);
    if (selectedWorkExperience?.referenceData) {
      const transformedData = getTransformedReferenceList(selectedWorkExperience?.referenceData, t);
      setWorkExpReferences(transformedData);
    }
  }, [selectedWorkExperience?.referenceData]);

  const handleAddReferenceClick = () => {
    setAddNewReference(true);
    const defaultValues = {
      isNewReference: true,
      isExpanded: true,
      cardTitle: t('candidate.workExperience.addEditReference.referenceContacts.newReferenceCardTitle'),
      lastUpdatedText: t(
        'candidate.workExperience.addEditReference.referenceContacts.newReferenceLastUpdatedDefaultText',
      ),
      supervisorName: '',
      title: '',
      email: '',
      phoneType1: '',
      phoneNumber1: '',
      phoneType2: '',
      phoneNumber2: '',
      contactMethod: '',
      bestTimeToReach: '',
      okToContact: false,
      status: 'New',
      statusID: 1,
      workHistoryID: selectedWorkExperience?.workHistoryId,
    };

    setWorkExpReferences(prev => [...prev, defaultValues]);

    //Delaying this to make sure tht the New Reference Section is mounted to the DOM first
    setTimeout(() => {
      newReferenceSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  };

  const handleOnCancelAddReference = () => {
    setWorkExpReferences(prev => prev?.filter(x => !x.isNewReference));
    setAddNewReference(false);
  };

  return (
    <>
      <Grid container pt={4} alignItems={'center'}>
        <Typography component="div" mr={2} sx={{ fontSize: '16px !important' }}>
          {t('candidate.workExperience.addEditReference.referenceContacts.references')}
        </Typography>
        <Divider
          orientation="vertical"
          flexItem
          sx={theme => ({ borderColor: theme.palette.system.lightGrey, borderRightWidth: 3 })}
        />
        <Box
          className={!canAddReference || addNewReference ? classes.disabledAddReference : classes.addReference}
          onClick={() => canAddReference && !addNewReference && handleAddReferenceClick()}
          ml={2}
        >
          <AddIcon id="add-reference-icon" />
          <Typography component="span" variant="body2" ml={1.5}>
            {t('candidate.workExperience.addEditReference.referenceContacts.addReference')}
          </Typography>
        </Box>
      </Grid>
      {workExpReferences &&
        workExpReferences.map((reference, index) => (
          <Grid item xs={12} pt={4} key={index} ref={newReferenceSectionRef}>
            <AddReferenceModal
              disabledAccess={!canAddReference}
              referenceDetails={reference}
              handleOnCancel={handleOnCancelAddReference}
            />
          </Grid>
        ))}
    </>
  );
};

export default ReferenceWrapper;
