import { Grid, Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { candidateDetailsSelection } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { Cancel } from 'app/components/Common/CancelModal/Cancel';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Concatenate } from 'utils/string/string';
import { useFormContext } from 'react-hook-form';
import { candidateDetailActions } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContainer: {
    width: '820px',
    maxWidth: '820px',
  },
  dialogContent: {
    padding: 0,
    overflow: 'hidden',
  },
  dialogContent2: {
    fontSize: '14px',
  },
  dialogTitle2: {
    fontSize: '20px',
  },
}));

export const SellingNotesDialog = props => {
  const { open, handleClose, children, showConfirmation } = props;
  const { classes } = useStyles();
  const [cancel, setCancel] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [alertOpen, setAlertOpen] = useState(false);
  const { travelerId: candidateId } = useParams<{ travelerId: string; brandId: string }>();
  const candidateDetails: any = useSelector(candidateDetailsSelection);
  const { watch, reset } = useFormContext();
  const dispatch = useDispatch();
  const watchFields = watch(['preview']);

  useEffect(() => {
    if (candidateDetails)
      setName(
        Concatenate(
          [candidateDetails?.name?.first, candidateDetails?.name?.middle || '', candidateDetails?.name?.last],
          ' ',
        ),
      );
  }, [candidateDetails]);

  const handleCancel = () => {
    if (showConfirmation) {
      handleClose();
      reset();
      dispatch(candidateDetailActions.setSellingNotesItems([]));
    } else {
      setAlertOpen(true);
    }
  };

  const handleYesAction = () => {
    dispatch(candidateDetailActions.setSellingNotesItems([]));
    reset();
    handleClose();
    setAlertOpen(false);
  };

  const yesAction = () => {};
  const noAction = () => {};

  return (
    <>
      <GenericDialog
        variant={'blue'}
        classes={{
          paper: classes.dialogContainer,
        }}
        open={open}
        disablePortal
        disableEnforceFocus
        disableEscapeKeyDown
        dialogTitleProps={{
          text: `Selling Notes Template - ${name} (${candidateId})`,
          closeButton: true,
        }}
        dialogContentProps={{
          classes: { root: classes.dialogContent },
        }}
        dialogActions={[
          {
            text: 'Cancel',
            variant: 'contained',
            onClick: handleCancel,
            color: 'tertiary',
          },
          {
            text: 'Save',
            variant: 'contained',
            color: 'primary',
            type: 'submit',
            disabled: watchFields.preview.length > 1000,
          },
        ]}
        onClose={handleCancel}
      >
        <Grid container>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </GenericDialog>
      {cancel && <Cancel openDialog={cancel} handleConfirmAction={yesAction} handleCancelAction={noAction} />}
      <GenericDialog
        open={alertOpen}
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
        dialogTitleProps={{
          text: 'Discard Changes?',
          classes: { root: classes.dialogTitle2 },
        }}
        dialogContentProps={{
          classes: { root: classes.dialogContent2 },
        }}
        dialogActions={[
          {
            text: 'Cancel',
            variant: 'text',
            onClick: () => setAlertOpen(false),
            color: 'tertiary',
          },
          {
            text: 'Yes',
            variant: 'contained',
            color: 'primary',
            onClick: () => handleYesAction(),
          },
        ]}
        onClose={() => setAlertOpen(false)}
      >
        <span>{`You have made some changes that haven't been saved yet. Are you sure you want to discard these changes?`}</span>
      </GenericDialog>
    </>
  );
};
