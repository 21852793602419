import { Grid, Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusUpdateModalVersion, TaskNotificationType } from 'app/models/Tasks/Tasks';
import { convertToEndOfDay, displaySentDate } from './FormatData';
import { TaskPopupContext } from './TaskPopupContext';
import { SubTaskPopupContext } from './SubTaskPopupContext';
import moment from 'moment';

const useStyles = makeStyles<{ activityFeed?: boolean; showActionPanel?: boolean }>()(
  (theme: Theme, { activityFeed, showActionPanel }) => ({
    cardFooterDivision: {
      top: '422px',
      height: '16px',
      marginTop: '0.35rem',
    },
    cardFooterDivisionMultiLine: {
      top: '422px',
      height: '16px',
      marginTop: '0.7rem',
    },
    truncation: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: showActionPanel ? '125px' : '200px',
      marginRight: showActionPanel && !activityFeed ? '6px' : '0px',
    },
    truncateDescription: {
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    notificationTitle: {
      top: '363px',
      left: '209px',
      height: '19px',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontStyle: 'normal',
      fontVariant: 'normal',
      fontWeight: 500,
      lineHeight: '19px',
      letterSpacing: '0px',
      color: theme.palette.system.darkGray,
      opacity: 1,
    },
    newTaskNotificationTitle: {
      top: '363px',
      left: '209px',
      height: '19px',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontStyle: 'normal',
      fontVariant: 'normal',
      fontWeight: 500,
      lineHeight: '19px',
      letterSpacing: '0px',
      color: theme.palette.system.darkGray,
      opacity: 1,
      marginTop: '9px',
    },
    truncateTitle: {
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '150px',
    },
    taskDescription: {
      top: '390px',
      left: '149px',
      minWidth: '150px',
      minHeight: '16px',
      maxHeight: '30px',
      textAlign: 'left',
      font: 'normal normal normal 12px/18px Roboto',
      fontFamily: 'Roboto',
      letterSpacing: '0px',
      color: theme.palette.system.black,
      opacity: 1,
      paddingTop: '0.15rem',
      fontWeight: 'normal',
    },
    taskDescriptionunbold: {
      top: '390px',
      left: '149px',
      minWidth: '150px',
      minHeight: '16px',
      maxHeight: '30px',
      textAlign: 'left',
      font: 'normal normal normal 12px/18px Roboto',
      fontFamily: 'Roboto',
      letterSpacing: '0px',
      color: theme.palette.system.black,
      opacity: 1,
      paddingTop: '4px',
      fontWeight: 'bold',
    },
    taskDueDate: {
      top: '215px',
      left: '428px',
      height: '16px',
      textAlign: 'right',
      font: 'normal normal normal 12px/16px Roboto',
      letterSpacing: '0px',
      color: theme.palette.framework.system.tertiaryGrey,
      opacity: 1,
      paddingRight: showActionPanel ? '4px' : '9px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    additionalInfo: {
      top: '410px',
      left: '101px',
      height: '16px',
      textAlign: 'left',
      font: 'normal normal normal 12px/16px Roboto',
      letterSpacing: '0px',
      color: theme.palette.framework.system.tertiaryGrey,
      opacity: 1,
      marginBottom: '12px',
    },
    gridContainers: {
      height: '75px',
      opacity: 1,
    },
    gridContainersMultiLine: {
      height: '75px',
      opacity: 1,
    },
    flexContainer: {
      marginLeft: showActionPanel ? '17px' : '12px',
      width: '300px',
      display: 'flex',
      alignItems: 'flex-start',
    },
    statusIcon: {
      top: '366px',
      left: '180px',
      width: '36px',
      height: '36px',
      opacity: 1,
      scale: '110%',
    },
    seperatorIcon: {
      fontSize: '6px',
      marginBottom: '2px',
    },
    baseContainer: {
      margin: '0px',
      height: '119px',
      opacity: 1,
      backgroundColor: `${theme.palette.common.white} 0% 0% no-repeat padding-box`,
      boxShadow: ` 3px 0px 6px ${theme.palette.system.lightBlack}`,
      border: '1px solid #E2DFDF',
      borderRadius: ' 5px',
      padding: '12px',
      width: showActionPanel ? '250px' : '350px',
      transition: 'width 0.3s',
    },
    mainTitle: {
      color: theme.palette.system.darkGray,
      fontSize: ' 0.8rem !important',
      textAlign: 'left',
      fontWeight: 'bold',
      padding: '0px',
      margin: '0px',
    },
    mainTitleRead: {
      color: theme.palette.system.darkGray,
      fontSize: ' 0.8rem !important',
      textAlign: 'left',
      fontWeight: 'normal',
      padding: '0px',
      margin: '0px',
    },
    secondaryTitle: {
      display: 'flex',
      color: theme.palette.system.darkGray,
      paddingRight: '5px',
      fontSize: '0.9rem',
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      padding: '0px',
      margin: '0px',
    },
    secondaryTitleRead: {
      display: 'flex',
      color: theme.palette.system.darkGray,
      paddingRight: '5px',
      fontSize: '0.9rem',
      fontWeight: 'normal',
      fontFamily: 'Roboto',
      padding: '0px',
      margin: '0px',
    },
    spanText: {
      fontWeight: 'normal',
      zIndex: '5',
    },
    footerContainer: {
      marginTop: '80px',
      maringBottom: '12px',
    },
  }),
);
export const TaskNotificationPopup = props => {
  const { NotificationBody, multiLine, activityFeed = false, showActionPanel, isUnread } = props;
  const { classes, cx } = useStyles({ activityFeed, showActionPanel });
  const { t } = useTranslation();
  const [associatedRecords, setAssociatedRecords] = useState<any>({});
  const [dueDate, setdueDate] = useState('');
  const [isSubTaskScenario, setSubTaskScenario] = useState<boolean>(false);
  const dueDateValue = 'DueDate';
  const sendDate = 'SentDate';

  const getAssociatedRecords = records => {
    const obj: any = {};
    records?.forEach(el => {
      if (el.name === 'DueDate' || el.name === 'SentDate') {
        obj[el.name] = convertToEndOfDay(el.value);
      } else {
        obj[el.name] = el.value;
      }
    });
    return obj;
  };

  useEffect(() => {
    displaySentDate(NotificationBody, dueDateValue, activityFeed, setdueDate, sendDate, t);
    const getRecords = getAssociatedRecords(NotificationBody?.associateRecords);
    setAssociatedRecords(getRecords);
    if (parseInt(getRecords?.Version) === StatusUpdateModalVersion.SUBTASK) {
      setSubTaskScenario(true);
    } else {
      setSubTaskScenario(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [NotificationBody]);

  return (
    <React.Fragment>
      <div className={classes.baseContainer}>
        <div
          className={cx({
            [classes.gridContainers]: multiLine === false,
            [classes.gridContainersMultiLine]: multiLine === true,
          })}
        >
          {isSubTaskScenario ? (
            <SubTaskPopupContext
              NotificationBody={NotificationBody}
              classes={classes}
              associatedRecords={associatedRecords}
              showActionPanel={showActionPanel}
              isUnread={isUnread}
            />
          ) : (
            <TaskPopupContext
              NotificationBody={NotificationBody}
              classes={classes}
              associatedRecords={associatedRecords}
              showActionPanel={showActionPanel}
              isUnread={isUnread}
            />
          )}
        </div>
        <div>
          <Grid
            container
            xs={12}
            direction="column"
            className={cx({
              [classes.cardFooterDivision]: multiLine === false,
              [classes.cardFooterDivisionMultiLine]: multiLine === true,
            })}
          >
            <Grid item className={classes.additionalInfo}>
              <div className={classes.truncation}>{associatedRecords?.TaskCategory ?? ' '}</div>
            </Grid>
            <Grid item className={classes.taskDueDate}>
              {activityFeed
                ? dueDate
                : parseInt(NotificationBody?.useSubType) === TaskNotificationType.CANCELLED ||
                  parseInt(NotificationBody?.useSubType) === TaskNotificationType.COMPLETED
                ? moment.tz(associatedRecords?.DueDate, 'America/Los_Angeles').format('L')
                : dueDate}
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
};
