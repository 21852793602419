import { IUserRoles } from '@AMIEWEB/Settings/AutoTaskManagement/componenets/UserRolesSection/UserRolesSection';

export enum gridTags {
  AUTOTASKS = 'Auto-Tasks',
}

export interface IAutotaskGridData {
  autoTaskCategoryId: number;
  description: string;
  eventDescription: string;
  lastUpdateDate: string;
  lastUpdatedBy: string;
  lastUpdatedByUserId: string | number;
  statusId: string | number;
  status: string;
  assignedRoles: number[];
  timestamp: string;
}
export interface IAutotaskGridDataResponse {
  autoTaskCategoryId: number;
  description: string;
  eventDescription: string;
  lastUpdateDate: Date;
  lastUpdatedBy: string;
  lastUpdatedByUserId: number;
  statusId: number;
  status: string;
  timestamp: string;
  assignedRoles: number[];
}

export interface IPlacementRequests {
  gridData: Array<IAutotaskGridData>;
  selectedData: unknown;
  selectedRow: IAutotaskGridData;
  assignedRoles: number[];
  selectedUserRoles: IUserRoles[];
  currentStatus: IAutoTaskTaskStatus;
  userRolesOptionsList: IUserRoles[];
  responseData: Array<IAutotaskGridData>;
  filters: any;
  selectedFilters: any;
  autoTaskUpdateResponse:IUpdateAutoTaskManagementResponse;
  autoTaskManagementStatusLookUp:IAutoTaskTaskStatus[]
}

export interface IAutoTaskTaskStatus{
  status:string,
  statusId:number
}

export interface IUpdateAutoTaskManagementPayload{
  autoTaskCategoryId:number
  userId:number
  assignedRoles:number[]
  statusId:number
  timestamp:string
}

export interface IUpdateAutoTaskManagementResponse {
  autoTaskCategoryId: number;
  statusId: number;
  timestamp: string;
  userId: number;
  assignedRoles: number[];
  message: string | null;
  responseCode: number;
}

export  interface IRefreshActionType{
  softReset?:boolean
}
export  interface ISaveTypeOnPageChange{
  selectedId?:number,
  pageChange?:boolean
}