import { missingDate, missingField } from 'app/constants';
import { GridSortCellParams } from '@mui/x-data-grid-pro';
import moment from 'moment';

export const xGridNumberSortByField =
  field => (v1, v2, cellParams1: GridSortCellParams, cellParams2: GridSortCellParams) => {
    // TODO: MUI 5 - @Ferrari, find a different way to accomplish sorting by a secondary column. API is deprecated in these values
    return xGridNumberSort(
      cellParams1.api.getRow(cellParams1.id)[field],
      cellParams2.api.getRow(cellParams2.id)[field],
    );
  };

export const xGridNumberSort = (v1, v2) => {
  if (v1 === missingField && v2 === 0) return -1;
  if (v2 === missingField && v1 === 0) return 1;
  const p1 = (v1 === missingField ? 0 : v1) as number;
  const p2 = (v2 === missingField ? 0 : v2) as number;
  return p1 - p2;
};

export const xGridDateSort = (v1, v2) => {
  const p1 = moment(v1 === missingField ? missingDate : v1);
  const p2 = moment(v2 === missingField ? missingDate : v2);
  return p1.isBefore(p2) ? -1 : p1.isAfter(p2) ? 1 : 0;
};

export const xgridDateTimeSort =
  field => (v1, v2, cellParams1: GridSortCellParams, cellParams2: GridSortCellParams) => {
    return xGridDateSort(cellParams1.api.getRow(cellParams1.id)[field], cellParams2.api.getRow(cellParams2.id)[field]);
  };
