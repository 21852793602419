import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { ff_skillsChecklistSection, ff_WorkExperience_Enhancement } from 'app/constants/FeatureFlags/Candidate/Keys';
import React, { useEffect, useMemo, useState } from 'react';
import { EducationSummaryCard } from './Cards/EducationSummaryCard';
import { QualificationsCard } from './Cards/QualificationsCard';
import { SkillsChecklistCard } from './Cards/SkillsChecklistCard';
import { VirtualInterviewCard } from './Cards/VirtualInterviewCard';
import { WorkExperienceCard } from './Cards/WorkExperience/WorkExperienceCard';
import { useDecision } from '@optimizely/react-sdk';
import { AuditSummaryCard } from './Cards/AuditSummaryCard';
import { ReferencesGrid } from './References/ReferencesGrid';
import { WorkExperienceGridLoadable } from './WorkExperience/WorkExperienceLoadable';
import { EducationWrapperLoadable } from '../ProfileTab/Education/EducationWrapperLoadable';
import { candidateDetailActions } from '../../Profile/CandidateDetails.redux';
import { useDispatch } from 'react-redux';
import { useTheme } from 'amn-ui-core';
import { candidateProfileDetailsActions } from 'store/redux-store/candidate-details/slice';
import { useParams } from 'react-router-dom';
import { ProfileSummaryCard } from './Cards/ProfileSummaryCard';

export const SummaryTab = props => {
  const { navigate } = props;
  const [skillsChecklistShowDecision] = useDecision(ff_skillsChecklistSection);
  const [showAllWorkExperience, setShowAllWorkExperience] = useState(false);
  const [hasWorkExperienceTab] = useDecision(ff_WorkExperience_Enhancement);
  const [showAllEducation, setShowAllEducation] = useState(false);
  const [showAllReferences, setShowAllReferences] = useState(false);
  const [referenceFilter, setReferenceFilter] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const params = useParams<{
    travelerId: string;
    brandId: string;
  }>();

  useEffect(() => {
    if (params.travelerId && params.brandId) {
      dispatch(
        candidateProfileDetailsActions.getSummaryTabDetails({
          candidateId: parseInt(params.travelerId),
          brandId: parseInt(params.brandId),
        }),
      );
    }
  }, [params.travelerId, params.brandId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClick = React.useCallback(() => {
    dispatch(candidateDetailActions.initiateReferenceGridData({ filter: '' }));
    if (hasWorkExperienceTab?.enabled) {
      window.history.pushState(null, '', `${window.location.pathname}?tab=${1}`);
      navigate(1);
    } else {
      setShowAllWorkExperience(!showAllWorkExperience);
    }
  }, [dispatch, showAllWorkExperience]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleEducationClick = () => {
    setShowAllEducation(!showAllEducation);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleReferenceClick = React.useCallback(() => {
    if (!showAllReferences) {
      dispatch(candidateDetailActions.initiateReferenceGridData({ filter: '' }));
    }
    setShowAllReferences(!showAllReferences);
  }, [dispatch, showAllReferences]);

  const filterReferenceBy = status => {
    setReferenceFilter(status);
  };

  const EduXGrid = useMemo(() => {
    return (
      <>
        <LayoutGrid container direction="column" spacing={2}>
          <LayoutGridItem item xs={12}>
            <EducationWrapperLoadable handleToggle={handleEducationClick} loading={loading} />
          </LayoutGridItem>
        </LayoutGrid>
      </>
    );
  }, [handleEducationClick, loading]);

  const ReferencesXGrid = useMemo(() => {
    return (
      <>
        <ReferencesGrid rows={[]} handleBack={handleReferenceClick} filter={referenceFilter} />
      </>
    );
  }, [handleReferenceClick, referenceFilter]);

  const WorkExperienceXGrid = useMemo(() => {
    return (
      <>
        <WorkExperienceGridLoadable rows={[]} handleBack={handleClick} />
      </>
    );
  }, [handleClick]);

  return (
    <>
      {showAllWorkExperience ? (
        <LayoutGrid container direction="column" spacing={2}>
          <LayoutGridItem item xs={12}>
            {WorkExperienceXGrid}
          </LayoutGridItem>
        </LayoutGrid>
      ) : showAllEducation ? (
        EduXGrid
      ) : showAllReferences ? (
        <LayoutGridItem item xs={12}>
          {ReferencesXGrid}
        </LayoutGridItem>
      ) : (
        <LayoutGrid spacing={1} direction="row" data-testid="summary-container">
          <LayoutGridItem paddingRight={`${theme.spacing()}`} xs={4} lg={3}>
            <AuditSummaryCard />
            <ProfileSummaryCard navigate={navigate} />
          </LayoutGridItem>
          <LayoutGridItem paddingRight={`${theme.spacing(2)}`} xs={4} lg={3}>
            {(!showAllWorkExperience || !showAllReferences) && (
              <WorkExperienceCard
                showAllWorkExperience={handleClick}
                showAllReferences={handleReferenceClick}
                filterReferenceBy={filterReferenceBy}
              />
            )}
            <EducationSummaryCard showAllEducation={handleEducationClick} />
          </LayoutGridItem>
          <LayoutGrid spacing={0} direction="row" xs={4} lg={6}>
            <LayoutGridItem marginTop={`${theme.spacing()}`} sx={{ pr: { lg: theme.spacing(2) } }} xs={12} lg>
              <VirtualInterviewCard navigate={navigate} />
              {skillsChecklistShowDecision.enabled && <SkillsChecklistCard navigate={navigate} />}
            </LayoutGridItem>
            <LayoutGridItem sx={{ mt: { lg: theme.spacing(), md: theme.spacing(2) } }} xs={12} lg>
              <QualificationsCard navigate={navigate} />
            </LayoutGridItem>
          </LayoutGrid>
        </LayoutGrid>
      )}
    </>
  );
};
