import { placementStage } from '@AMIEWEB/Order/OrderDetails/OrderPreferences/store/OrderPreference.redux';
import { createAction } from '@reduxjs/toolkit';
import { ICopyPreferencesRequest, IUpdateUnitPreferences } from './types';

const getFacilityPreferences = createAction<{
  facilityId: number;
  stage: placementStage | null;
}>('GET_FACILITY_PREFERENCES');

const getConfirmationTemplates = createAction('GET_CONFIRMATION_TEMPLATES');
const getFacilityTemplate = createAction<{
  facilityId: number;
}>('GET_FACILITY_TEMPLATE');

const updateFacilityPreferences = createAction<{
  data: IUpdateUnitPreferences;
  isCopyingPreferences: boolean;
}>('UPDATE_FACILITY_PREFERENCES');

const updateFacilityTemplate = createAction<{
  facilityId: number;
  confirmationTemplateId: number;
  updatedBy: number;
  confirmationTemplateName: string;
  facilityForm?:boolean
}>('UPDATE_FACILITY_TEMPLATE');

const updateAutomation = createAction<{
  facilityId: number;
  rfoToSent: boolean;
}>('UPDATE_FACILITY_AUTOMATION');

const getActivePlacementCounts = createAction<{
  facilityId: number;
}>('GET_ACTIVE_PLACEMENT_COUNT_FACILITY');

const getExistingUnitPreferences = createAction<{
  facilityId: number;
}>('GET_FACILITY_UNITS_LIST');

const updatePrefrencesToFacility = createAction<{
  data: ICopyPreferencesRequest;
  facilityId: number;
}>('UPDATE_UNIT_PREFERENCES_TO_FACILITY');

export const serviceActions = {
  getFacilityPreferences,
  updateFacilityPreferences,
  updateAutomation,
  getConfirmationTemplates,
  getFacilityTemplate,
  updateFacilityTemplate,
  getActivePlacementCounts,
  getExistingUnitPreferences,
  updatePrefrencesToFacility
};
