import { trackEvent } from 'app-insights/appInsightsTracking';
import moment from 'moment';
import { selectUser } from 'oidc/user.selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getSessionValue,
  SessionKey,
  setSessionValue,
} from 'utils/customHooks/sessionStorage/sessionHelpers';

export const useAppUsageTracker = () => {
  const { userInfo } = useSelector(selectUser);

  const [timeSpentOnPage, setTimeSpentOnPage] = React.useState<number>(
    parseInt(localStorage.getItem('timeSpent') ?? '0') ?? 0,
  );

  // Candidate most recently visited
  const setNewPlacementData = () => {
    if (
      window.globalThis.activeSession &&
      !!userInfo?.employeeId &&
      userInfo.roles?.length > 0 && 
      window.location.href.includes('/candidate/')
    ) {
      const parsedValues = window.location.href.match(/\/candidate\/(\d+)\/(\d+)/);
      if (parsedValues && parsedValues.length >= 3) {
        const candidateId = parseInt(parsedValues[1]);
        const brandId = parseInt(parsedValues[2]);
        const currNewPlacmentData = getSessionValue(SessionKey.newPlacementData) || {};
        const recentCandidates = getSessionValue(SessionKey.recentCandidates) || [];
        const index = recentCandidates?.findIndex(c => c.candidateId === candidateId && c.brandId === brandId);
        if (index !== -1) {
          const exisitingCandidate = recentCandidates[index];
          recentCandidates.splice(index, 1);
          recentCandidates.unshift(exisitingCandidate);
          setSessionValue(SessionKey.recentCandidates, recentCandidates); 
      }
        setSessionValue(SessionKey.newPlacementData, { ...currNewPlacmentData, candidateId, brandId });     
      }
    } else if (
      window.globalThis.activeSession &&
      !!userInfo?.employeeId &&
      userInfo.roles?.length > 0 && 
      window.location.href.includes('/order/')
    ) {
      const parsedOrderId = window.location.href.match(/\/order\/(\d+)/);
      if (parsedOrderId && parsedOrderId[1]) {
        const orderId = parseInt(parsedOrderId[1]);
        const currNewPlacmentData = getSessionValue(SessionKey.newPlacementData) || {};
        const recentOrders = getSessionValue(SessionKey.recentOrders) || [];
        const index = recentOrders?.findIndex(c => c.orderId === orderId);
        if (index !== -1) {
          const existingOrder = recentOrders[index];
          recentOrders.splice(index, 1);
          recentOrders.unshift(existingOrder);
          setSessionValue(SessionKey.recentOrders, recentOrders); 
      }
        setSessionValue(SessionKey.newPlacementData, { ...currNewPlacmentData, orderId });     
      } 
    }
  };
  
  React.useEffect(() => {
    window.globalThis.activeSession = false;
    setTimeSpentOnPage(parseInt(localStorage.getItem('timeSpent') ?? '0'));
    setNewPlacementData();
  }, []);

  // Listens for all events that would cause a tab to be active or inactive
  React.useEffect(() => {
    // Visibility change event
    const visibilityChange = () => {
      if (document.visibilityState === 'visible' && !document.hidden) {
        window.globalThis.activeSession = true;
        setNewPlacementData();
      } else {
        window.globalThis.activeSession = false;
      }
    };
    document.addEventListener('visibilitychange', visibilityChange);

    const onClick = () => {
      window.globalThis.hasFocus = true;
      window.globalThis.activeSession = true;
      setNewPlacementData();
    };
    document.addEventListener('click', onClick);

    /* Focus and blur events use a different variable because visibility 
    /* values could still be true if the page is physically visible even though it's not active
     */
    // On focus event
    const onFocus = () => {
      window.globalThis.hasFocus = true;
      window.globalThis.activeSession = true;
      setNewPlacementData();
    };
    window.addEventListener('focus', onFocus);

    // On blur event
    const onBlur = () => {
      window.globalThis.hasFocus = false;
      window.globalThis.activeSession = false;
    };
    window.addEventListener('blur', onBlur);

    // Interval when user is active
    const intervalId = setInterval(() => {
      if (
        window.globalThis.activeSession &&
        window.globalThis.hasFocus &&
        !!userInfo?.employeeId &&
        userInfo.roles?.length > 0
      ) {
        setTimeSpentOnPage(prevTime => prevTime + 10000);
      }
    }, 10000);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
      document.removeEventListener('visibilitychange', visibilityChange);
      document.removeEventListener('click', onClick);
      clearInterval(intervalId);
    };
  }, [userInfo]);

  React.useEffect(() => {
    const currDate = moment(new Date()).format('YYYY/MM/DD');
    if (!localStorage.getItem('timeSpent') || !localStorage.getItem('currentDate')) {
      localStorage.setItem('currentDate', currDate.toString());
      localStorage.setItem('timeSpent', '0');
      setTimeSpentOnPage(0);
    } else if (!!userInfo?.employeeId && userInfo.roles?.length > 0) {
      if (
        localStorage.getItem('currentDate') !== currDate.toString() &&
        parseInt(localStorage.getItem('timeSpent') ?? '0') > 0
      ) {
        // appInsights call
        trackEvent({
          type: 'event',
          name: 'AppUsage',
          properties: {
            date: localStorage.getItem('currentDate'),
            personas: userInfo.roles,
            duration: localStorage.getItem('timeSpent'),
          },
        });

        localStorage.setItem('currentDate', currDate.toString());
        localStorage.setItem('timeSpent', '0');
        setTimeSpentOnPage(0);
      } else if (
        (parseInt(localStorage.getItem('timeSpent') ?? '0') < timeSpentOnPage || timeSpentOnPage === 0) &&
        timeSpentOnPage <= 86400000
      ) {
        localStorage.setItem('timeSpent', `${timeSpentOnPage}`);
      }
    }
  }, [timeSpentOnPage, userInfo]);

  React.useEffect(() => {
    window.addEventListener('storage', onStorageUpdate);
    return () => {
      window.removeEventListener('storage', onStorageUpdate);
    };
  }, []);

  const onStorageUpdate = e => {
    const { key, newValue } = e;
    if (key === 'timeSpent') {
      setTimeSpentOnPage(parseInt(newValue));
    }
  };
};
