import { placementStatus, placementSubStatuses } from 'app/components/RecruiterWorkDesk/MyPlacementsDesk/utils';
import { simpleGroupBy } from 'app/helpers/arrayHelpers';

export const getAmPipelineCounts = dataArray => {
  const category = simpleGroupBy(dataArray, 'placementType');
  const subcategory = simpleGroupBy(dataArray, 'statusAbbr');

  return {
    placementsCount: dataArray.length || 0,
    inSubmission: category[placementStatus.inSubmission]?.length || 0,
    waitingForClearance: subcategory[placementSubStatuses.wfc]?.length || 0,
    requestFileOut: subcategory[placementSubStatuses.rfo]?.length || 0,
    sent: subcategory[placementSubStatuses.sent]?.length || 0,
    interviewed: subcategory[placementSubStatuses.int]?.length || 0,
    offer: category[placementStatus.offer]?.length || 0,
    offerByFacility: subcategory[placementSubStatuses.obf]?.length || 0,
    offerBookedByTraveler: subcategory[placementSubStatuses.obbt]?.length || 0,
    facilityConfirms: subcategory[placementSubStatuses.fc]?.length || 0,
    travelerAccepts: subcategory[placementSubStatuses.ta]?.length || 0,
    bookedCategory: category[placementStatus.booked]?.length || 0,
    bookedSubCategory: subcategory[placementSubStatuses.booked]?.length || 0,
    bookingApproved: subcategory[placementSubStatuses.ba]?.length || 0,
    waitingGoodToGo: subcategory[placementSubStatuses.wgtg]?.length || 0,
    postponementWarning: subcategory[placementSubStatuses.ppw]?.length || 0,
    goodToGo: subcategory[placementSubStatuses.gtg]?.length || 0,
    onAssignment: category[placementStatus.onAssignment]?.length || 0,
  };
};
