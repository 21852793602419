import { Grid, List, ListItemButton, ListItemText } from 'amn-ui-core';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { SearchField } from '../../Search/SearchField';
import { useTranslation } from 'react-i18next';
import { IContractTemplate } from 'store/redux-store/contract-template-editor/types';
import TemplateSearchNotFound from 'app/assets/images/Template/TemplateSearchNotFound.svg';
import _ from 'lodash';
import { ActionModal, Expandable } from '../customComponents';
import { simpleGroupBy } from 'app/helpers/arrayHelpers';

const useStyles = makeStyles()(theme => ({
  textField: {
    height: 40,
    width: '100%',
  },
  content: {
    height: 'fit-content',
    width: '100%',
    backgroundColor: theme.palette.components.grid.content.backgroundColor,
    alignSelf: 'center',
  },
  templatesContainer: {
    height: '800px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: 'calc(100vh - 208px)',
    flexWrap: 'nowrap',
  },
  searchBarContainer: {
    position: 'sticky',
    top: '0px',
    zIndex: 2,
    backgroundColor: theme.palette.system.grayChip,
  },
  messageBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'inherit',
    paddingLeft: '20px',
  },
  message: {
    fontSize: '14px',
    color: theme.palette.system.darkGray,
    fontStyle: 'italic',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    ' span': {
      padding: '1rem 2rem 1rem 2rem',
    },
  },
  categoryTitle: {
    padding: '0 0 0 16px',
    fontSize: 14,
  },
  selectedTemplate: {
    backgroundColor: theme.palette.framework.system.white,
    border: `1px solid ${theme.palette.system.borderColor1}`,
    borderRadius: '0px 7px 7px 0px',
    borderLeftStyle: 'none',
    '&:hover': {
      backgroundColor: `${theme.palette.system.hoverGrey} !important`,
    },
  },
  unselectedTemplate: {
    '&:hover': {
      backgroundColor: `${theme.palette.system.hoverGrey} !important`,
      borderRadius: 7,
    },
  },
}));

export const TemplatesWrapper: React.FC<{
  templates: IContractTemplate[];
  selectedTemplate: IContractTemplate | null;
  onTemplateClick: (item: IContractTemplate) => void;
  selectedTemplateIsDirty: boolean;
  onCancel: (callbackFn?) => void;
}> = ({ templates, selectedTemplate, onTemplateClick, onCancel, selectedTemplateIsDirty }) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();

  const [searchQuery, setSearchQuery] = React.useState('');
  // @ts-ignore
  const [modalProps, setModalProps] = React.useState<IActionModalProps>({ open: false });
  // @ts-ignore
  const closeModal = React.useCallback(() => setModalProps({ open: false }), []);

  const handleTemplateClick = React.useCallback(
    (templateSelection: IContractTemplate | null) => {
      if (selectedTemplateIsDirty) {
        setModalProps({
          open: true,
          title: t('cancelModal.undoConfirmation'),
          message: t('cancelModal.publishConfirmation'),
          primaryBtns: [
            {
              text: t('Yes'),
              onClick: () => {
                onCancel(closeModal);
                onTemplateClick(templateSelection);
              },
            },
          ],
          secondaryBtns: [
            {
              text: t('cancelModal.confirmation.cancel'),
              onClick: closeModal,
            },
          ],
        });
      } else onTemplateClick(templateSelection);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTemplateIsDirty, onTemplateClick],
  );

  const { _templateList, hasSearchResults } = React.useMemo(() => {
    const _templates = _.cloneDeep(templates).filter(
      template => template.templateName && template.templateName.toLowerCase().includes(searchQuery.toLowerCase()),
    );

    const templateGoups = simpleGroupBy(_templates, 'webCategory');

    const templateList = Object.entries<IContractTemplate[]>(templateGoups)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(item => (
        <Expandable key={item[0]} expanded title={item[0]} titleClass={classes.categoryTitle}>
          <List disablePadding>
            {item[1].map(template => {
              const selected = selectedTemplate?.templateId === template.templateId;
              return (
                <ListItemButton
                  key={template.templateId}
                  onClick={() => handleTemplateClick(selected ? null : template)}
                  sx={{ p: 0, pl: selected ? 4 : 2, ml: selected ? undefined : 2 }}
                  classes={{
                    root: cx({ [classes.selectedTemplate]: selected, [classes.unselectedTemplate]: !selected }),
                  }}
                >
                  <ListItemText
                    primary={template.templateName}
                    sx={{
                      fontSize: 14,
                      color: theme =>
                        selected ? theme.palette.framework.system.skyBlue : theme.palette.system.darkGray,
                    }}
                  />
                </ListItemButton>
              );
            })}
          </List>
        </Expandable>
      ));

    return { _templateList: templateList, hasSearchResults: _templates.length > 0 };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates, searchQuery, handleTemplateClick, selectedTemplate]);

  const messageContent = React.useMemo(() => {
    if (!hasSearchResults) {
      return (
        <>
          <img src={TemplateSearchNotFound} alt="No results found" />
          <span>{t('templateEditor.templateName.search.noResults')}</span>
        </>
      );
    }
    return '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSearchResults]);

  return (
    <Grid container className={classes.templatesContainer} direction="column" spacing={2}>
      <Grid className={classes.searchBarContainer} container item>
        <Grid item classes={{ root: classes.content }}>
          <SearchField
            autoFocus
            inheritWidth
            name={'search-template-tokens'}
            placeholder={t('appHeader.searchPlaceHolder')}
            adornAtStart
            onChange={e => setSearchQuery(e.target.value)}
            searchFieldClasses={{ textField: classes.textField }}
          />
        </Grid>
      </Grid>

      {!hasSearchResults ? (
        <Grid className={classes.messageBox}>
          <div className={classes.message}>{messageContent}</div>
        </Grid>
      ) : (
        _templateList
      )}
      <ActionModal {...modalProps} />
    </Grid>
  );
};
