import { Box, Switch, TextField, Tooltip, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { getSignaturePreferences } from 'app/services/NotificationServices/TemplateService';
import { selectUser } from 'oidc/user.selectors';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { selectEmailSignature } from 'app/ApplicationRoot/Global.selectors';
import { userRoles } from 'oidc/userRoles';

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 0 10px 0',
  },
  root: {
    '& .MuiFilledInput-root': {
      background: '#fff',
    },
    '& .MuiFormLabel-root': {
      fontSize: '14px !important',
      color: '#75787B !important',
    },
    marginBottom: '15px',
    color: '#333333',
    fontSize: '14px',
    padding: '1px',
    width: '100%',
    border: '1px solid #B9BBBC',
    borderRadius: '5px',
    '& .MuiFilledInput-input': {
      fontSize: '14px',
    },
  },
  tooltipArrow: {
    color: '#fff',
    '&::before': {
      border: '1px solid #555555',
    },
  },
  tooltipBackground: {
    backgroundColor: '#fff',
    color: '#555555',
    border: '1px solid #555555',
    fontSize: '12px',
  },
  toggleLabel: {
    fontWeight: 400,
    paddingTop: '5px',
    paddingLeft: '5px',
  },
});

export enum BrandType {
  amnNurse = 1,
  amnAllied = 10,
  hsg = 14,
};

const EmailSignature = () => {
  const { classes } = useStyles();
  const user = useSelector(selectUser);
  const userPreference = useSelector(selectUserPreference);
  const [emailSignatureValues, setEmailSignatureValues] = useState<any>([]);
  const dispatch = useDispatch();
  const [signature, setSignature] = useState<any>();
  const [brandedSignature, setBrandedSignature] = useState<boolean>(false);
  const [passportLink, setPassportLink] = useState<boolean>(false);
  const [referralLink, setReferralLink] = useState<boolean>(false);
  const emailSignature = useSelector(selectEmailSignature);
  const hsgBrand = user?.userInfo?.companyId === BrandType.hsg;

  useEffect(() => {
    //149760: Email Signature: Branded signature toggle should be turned ON by default for the user with Recruitment user role
    if (
      user?.userInfo?.roles?.findIndex(
        x =>
          x === userRoles.recruitment ||
          x === userRoles.recruitment_Leadership ||
          x === userRoles.recruitment_TeamMember,
      ) !== -1
    ) {
      let res = true;
      if (emailSignatureValues && emailSignatureValues.length) {
        if (emailSignatureValues.filter(val => val.name === 'Branded Signature')[0]?.value === null) res = true;
        else res = emailSignatureValues.filter(val => val.name === 'Branded Signature')[0]?.value;
      }
      setBrandedSignature(res);
    } else {
      setBrandedSignature(
        emailSignatureValues && emailSignatureValues.length > 0
          ? emailSignatureValues.filter(val => val.name === 'Branded Signature')[0]?.value
          : false,
      );
    }

    setPassportLink(
      emailSignatureValues && emailSignatureValues.length > 0
        ? emailSignatureValues.filter(val => val.name === 'Passport Link')[0]?.value
        : false,
    );
    setReferralLink(
      emailSignatureValues && emailSignatureValues.length > 0
        ? emailSignatureValues.filter(val => val.name === 'Referral Link')[0]?.value
        : false,
    );
  }, [emailSignatureValues, user]);

  useEffect(() => {
    setEmailSignatureValues(userPreference?.emailSignature ? userPreference?.emailSignature?.value : []);
    if (
      user?.userInfo?.roles?.findIndex(
        x =>
          x === userRoles.recruitment ||
          x === userRoles.recruitment_Leadership ||
          x === userRoles.recruitment_TeamMember,
      ) === -1
    ) {
      setBrandedSignature(
        userPreference?.emailSignature?.value?.filter(val => val.name === 'Branded Signature')[0]?.value,
      );
    }
    setPassportLink(userPreference?.emailSignature?.value?.filter(val => val.name === 'Passport Link')[0]?.value);
    setReferralLink(userPreference?.emailSignature?.value?.filter(val => val.name === 'Referral Link')[0]?.value);
  }, [user, userPreference, userPreference.emailSignature]);

  useEffect(() => {
    setEmailSignatureValues(emailSignature);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailSignature]);

  useEffect(() => {
    getSignature(user?.userInfo?.companyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSignature = async (brandId?: number) => {
    const res = await getSignaturePreferences(brandId);
    if (res && res?.substitutions) setSignature(res.substitutions);
  };

  const handleOnChange = (e, name, input) => {
    e.preventDefault();
    e.stopPropagation();
    if (name === 'Branded Signature' && e.target.checked) {
      getSignature(user.userInfo?.companyId);
    }
    let emailSignatureTemp: any = [];
    if (
      ((name === 'Sender Name' || name === 'Title' || name === 'Division') && e.target.value.length > 50) ||
      ((name === 'Text Number' || name === 'Phone Number' || name === 'Mobile Number') && e.target.value.includes('_'))
    )
      return;
    else {
      emailSignatureTemp = emailSignatureValues.length
        ? emailSignatureValues.filter(value => value?.name !== name)
        : [];
      emailSignatureTemp.push({
        name: name,
        input: input,
        value:
          name === 'Branded Signature' || name === 'Passport Link' || name === 'Referral Link'
            ? e.target.checked
            : (name === 'Text Number' || name === 'Phone Number' || name === 'Mobile Number') &&
              e.target.value.length === 0
              ? undefined
              : e.target.value,
      });
      setEmailSignatureValues(emailSignatureTemp);
      dispatch(globalActions.setEmailSignature(emailSignatureTemp));
    }
  };

  return (
    <div>
      <TextField
        label="First Name"
        disabled
        className={classes.root}
        onChange={e => handleOnChange(e, 'First Name', '{{first_name}}')}
        value={user?.userInfo?.firstName}
        InputProps={{ disableUnderline: true }}
        InputLabelProps={{ shrink: user?.userInfo?.firstName ? true : false }}
        variant="filled"
      />
      <TextField
        label="Last Name"
        disabled
        onChange={e => handleOnChange(e, 'Last Name', '{{last_name}}')}
        className={classes.root}
        value={user?.userInfo?.lastName}
        variant="filled"
        InputProps={{ disableUnderline: true }}
        InputLabelProps={{
          shrink: user?.userInfo?.lastName ? true : false,
        }}
      />
      <TextField
        label={`Preferred First Name & Last Name`}
        onChange={e => handleOnChange(e, 'Sender Name', '{{sender_name}}')}
        InputProps={{ disableUnderline: true }}
        inputProps={{ maxLength: 50 }}
        className={classes.root}
        value={emailSignatureValues.filter(val => val.name === 'Sender Name')[0]?.value}
        InputLabelProps={{
          shrink: emailSignatureValues.filter(val => val.name === 'Sender Name')[0]?.value ? true : false,
        }}
        variant="filled"
      />
      <TextField
        label="Title"
        onChange={e => handleOnChange(e, 'Title', '{{title}}')}
        InputProps={{ disableUnderline: true }}
        inputProps={{ maxLength: 50 }}
        className={classes.root}
        variant="filled"
        value={emailSignatureValues.filter(val => val.name === 'Title')[0]?.value}
        InputLabelProps={{
          shrink: emailSignatureValues.filter(val => val.name === 'Title')[0]?.value ? true : false,
        }}
      />
      <TextField
        label="Division"
        onChange={e => handleOnChange(e, 'Division', '{{division}}')}
        InputProps={{ disableUnderline: true }}
        inputProps={{ maxLength: 50 }}
        className={classes.root}
        variant="filled"
        value={emailSignatureValues.filter(val => val.name === 'Division')[0]?.value}
        InputLabelProps={{
          shrink: emailSignatureValues.filter(val => val.name === 'Division')[0]?.value ? true : false,
        }}
      />
      <TextField
        label="Brand"
        disabled
        value={user?.userInfo?.companyBrandName}
        InputProps={{ disableUnderline: true }}
        className={classes.root}
        variant="filled"
        InputLabelProps={{
          shrink: user?.userInfo?.companyBrandName ? true : false,
        }}
      />
      <Box className={classes.container}>
        <Typography className={classes.toggleLabel}>{`Branded Signature`}</Typography>

        <Tooltip
          arrow
          classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
          title={`Select this option if your signature needs to include your brand-specific logo & website`}
        >
          <Switch
            checked={brandedSignature}
            disableRipple
            defaultChecked={brandedSignature}
            value={`Branded_Signature`}
            inputProps={{ 'aria-label': 'controlled' }}
            onChange={e => {
              if (!e.target.checked) {
                e.target.click();
              }
              handleOnChange(e, 'Branded Signature', '{{branded_signature}}');
            }}
          />
        </Tooltip>
      </Box>
      <MaskedInput
        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        label="Phone Number"
        onChange={e => handleOnChange(e, 'Phone Number', '{{phone_number}}')}
        InputProps={{ disableUnderline: true }}
        className={classes.root}
        variant="filled"
        value={user?.userInfo?.directNumber}
        InputLabelProps={{
          shrink: user?.userInfo?.directNumber ? true : false,
        }}
        render={(innerRef, props) => <TextField {...props} inputRef={innerRef} />}
        disabled
      />
      <MaskedInput
        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        label="Mobile Number"
        onChange={e => handleOnChange(e, 'Mobile Number', '{{mobile_number}}')}
        InputProps={{ disableUnderline: true }}
        className={classes.root}
        variant="filled"
        InputLabelProps={{
          shrink: emailSignatureValues.filter(val => val.name === 'Mobile Number')[0]?.value ? true : false,
        }}
        value={emailSignatureValues.filter(val => val.name === 'Mobile Number')[0]?.value}
        render={(innerRef, props) => <TextField {...props} inputRef={innerRef} />}
      />
      <MaskedInput
        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        label="Text Number"
        onChange={e => handleOnChange(e, 'Text Number', '{{text_number}}')}
        value={emailSignatureValues.filter(val => val.name === 'Text Number')[0]?.value}
        InputProps={{ disableUnderline: true }}
        className={classes.root}
        variant="filled"
        InputLabelProps={{
          shrink: emailSignatureValues.filter(val => val.name === 'Text Number')[0]?.value ? true : false,
        }}
        render={(innerRef, props) => <TextField {...props} inputRef={innerRef} />}
      />
      <Box className={classes.container}>
        <Typography className={classes.toggleLabel}>{`Download Passport Link`}</Typography>
        <Tooltip
          classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
          arrow
          title={`Select this option if your signature needs to include a link to download the Passport app`}
        >
          <Switch
            checked={passportLink}
            disableRipple
            value={`passport_link`}
            onChange={e => {
              setPassportLink(e.target.checked);
              handleOnChange(e, 'Passport Link', '{{passport_link}}');
            }}
          />
        </Tooltip>
      </Box>
      {signature && (signature.hasOwnProperty('referral_link') && !hsgBrand) && (
        <Box className={classes.container}>
          <Typography className={classes.toggleLabel}>{`Refer a Friend Link`}</Typography>
          <Tooltip
            arrow
            classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
            title={`Select this option if your signature needs to include a link to your brand's referral webpage`}
          >
            <Switch
              checked={referralLink}
              disableRipple
              value={`referral_link`}
              onChange={e => {
                setReferralLink(e.target.checked);
                handleOnChange(e, 'Referral Link', '{{referral_link}}');
              }}
            />
          </Tooltip>
        </Box>
      )}
    </div>
  );
};

export default EmailSignature;
