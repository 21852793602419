import { IUserRoles } from '@AMIEWEB/Settings/AutoTaskManagement/componenets/UserRolesSection/UserRolesSection';
import { missingField } from 'app/constants';
import { convertDateToPST } from 'app/helpers/dateHelper';
import { IAutoTaskTaskStatus, IAutotaskGridDataResponse } from 'app/models/AutoTaskManagement/AutoTaskManagement';

export const getFlattenedGridData = (arr: Array<IAutotaskGridDataResponse>) => {
  return arr
    ?.sort((a, b) => a.description.localeCompare(b.description))
    .map((item, index) => ({
      autoTaskCategoryId: item.autoTaskCategoryId,
      description: item.description ? item.description : missingField,
      eventDescription: item.eventDescription ? item.eventDescription : missingField,
      lastUpdateDate: convertDateToPST(item.lastUpdateDate, 'MM/DD/YYYY h:mm A') ?? missingField,
      lastUpdatedBy: item.lastUpdatedBy ? item.lastUpdatedBy : missingField,
      lastUpdatedByUserId: item.lastUpdatedByUserId ?? missingField,
      statusId: item.statusId ?? missingField,
      status: item.status ?? missingField,
      assignedRoles: item.assignedRoles ?? [],
      timestamp:item.timestamp
    }));
};

export const getAssignedRoleIds = (selectedRoles: IUserRoles[] = []) => selectedRoles.map(role => parseInt(role.id));

/**
 * Checks if two arrays are equal without considering the position of elements
 * @param arrayA
 * @param arrayB
 * @returns
 */
export const isArrayElementsEqual = (arrayA = [], arrayB = []) =>
  arrayA.length === arrayB.length && arrayA.every(value => arrayB.includes(value));

  export const getStatusDetails = (status:string,arr:IAutoTaskTaskStatus[]) =>arr.find(item=>item.status === status)

  export const CONFIRMATION_ACTION ={
    SAVE:'SAVE',
    CANCEL:'CANCEL',
    DISCARD:'DISCARD'
  }
  export enum ResponseCodes {
    RefreshErrorCode = 60000
  }

