import { Divider, Grid, Typography, CircularProgress } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { DateRangePicker } from 'app/ComponentLibrary/DateRangeNew';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { FormatDate } from 'app/components/Notification/Tasks/Common/FormatData';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GuaranteedWorkRate } from './GuaranteedWork';
import { RateElementsSection } from './RateElementsSection';
import { Cancel as CancelDialog } from 'app/components/Common/CancelModal/Cancel';
import { RateCardSection } from './RateCardSection';
import { cloneDeep } from 'lodash';
import { usePromiseTracker } from 'react-promise-tracker';
import { facilityActions } from 'app/components/Facility/FacilityStore/Facility.redux';
import { selectCreatedOffContractId } from 'app/components/Facility/FacilityStore/Facility.selector';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import {
  ISaveOffContract,
  IOffContractRateElement,
  IOffContract,
} from '@AMIEWEB/Facility/FacilityStore/Facility.model';
import { useParams } from 'react-router-dom';
// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
const AddOffContractStyles = makeStyles()({
  modalContainer: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '100%',
      minWidth: '777px',
      minHeight: '650px',
      overflow: 'auto',
      '& .MuiDialogContent-root': {
        overflow: 'auto',
        msOverFlowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
      '& .css-lr56cj-MuiDialogActions-root-dialogActionsRoot,.css-vi1g2q-dialogActionsRoot': {
        backgroundColor: '#F0F0F0',
        boxShadow: '0px -1px 3px #00000029',
        padding: '10px 15px',
      },
      '&.MuiDialog-paperFullWidth': {
        width: 'calc(50% - 50rem)',
      },
      '&.MuiDialog-paperFullScreen': {
        width: '100%',
        height: '100%',
      },
      '&.MuiDialog-paperWidthSm::-webkit-scrollbar': {
        width: '0 !important',
      },
    },
  },

  validationLoader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: '50%',
  },
});

export const InitialOffContractState: ISaveOffContract = {
  offContractId: '',
  docType: 'OffContract',
  facilityId: 0,
  effectiveStartDate: null,
  effectiveEndDate: null,
  ccustomerId: 0,
  orderId: 0,
  placementId: 0,
  approvaltaskId: 0,
  uploadtaskId: 0,
  note: '',
  dateCreated: null,
  createdBy: '',
  dateLastUpdated: null,
  lastUpdatedBy: '',
  createdAt: '',
  rateElements: [],
  currentUserId: 0,
  currentUserName: '',
  createdById: 0,
  lastUpdatedById: 0,
};

export const InitialRateElementState: IOffContractRateElement = {
  offContractId: '',
  rateElementId: '',
  effectiveStartDate: null,
  effectiveEndDate: null,
  rateElementNameId: 0,
  rateElementName: '',
  disciplineId: 0,
  specialtyId: 0,
  subSpecialtyId: 0,
  shiftId: 0,
  value: 0,
  valueMax: 0,
  statusId: 1,
  reason: '',
  dateApproved: null,
  approvedBy: '',
  dateCreated: null,
  createdBy: '',
  dateLastUpdated: null,
  lastUpdatedBy: '',
  approvedById: 0,
  createdById: 0,
  disciplineAbbreviation: '',
  disciplineName: '',
  documentIds: [],
  lastUpdatedById: 0,
  specialtyAbbreviation: '',
  specialtyName: '',
  subSpecialtyAbbreviation: '',
  subSpecialtyName: '',
  reasonId: 0,
};

export const AddOffContractModal = ({
  setIsModalOpen,
  isModalOpen,
}: {
  setIsModalOpen: (e) => void;
  isModalOpen: boolean;
}) => {
  const params = useParams<{ id: string }>();
  const { classes } = AddOffContractStyles();
  const { t } = useTranslation();
  const formMethods = useForm({
    mode: 'onSubmit',
  });
  const { control } = formMethods;
  const [startDateValue, setStartDateValue] = useState<any>();
  const [endDateValue, setEndDateValue] = useState<any>();
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const [disableRE, setDisableRE] = useState<boolean>(true);
  const [chosenSkillSets, setChosenSkillSets] = React.useState<any[]>([]);
  const [offContract, setOffContract] = React.useState<ISaveOffContract>(cloneDeep(InitialOffContractState));
  const [gwwTableData, setGwwTableData] = useState<any[]>([]);
  const [rateElemsTableData, setRateElemsTableData] = useState<any[]>([]);
  const [error, setError] = useState<any>(null);
  const { promiseInProgress: isCallingAPI } = usePromiseTracker({ area: 'save-facility-off-contract', delay: 0 });
  const [validData, setValidData] = useState<boolean>(false);
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUser);
  const createdOffContractId = useSelector(selectCreatedOffContractId);

  const onSubmit = () => {
    setOffContract(cloneDeep(InitialOffContractState));
    if (gwwTableData?.length > 0) {
      formatTableDataAsRateElements(gwwTableData, 'gww');
    }
    if (rateElemsTableData?.length > 0) {
      formatTableDataAsRateElements(rateElemsTableData, 'rateElems');
    }
    if (offContract) {
      dispatch(facilityActions.saveOffContractAction({ offContract }));
    }
  };

  const onClickCancel = () => {
    setCancelModalOpen(true);
  };

  const onClose = (event?: any, reason?: any) => {
    if (reason === 'backdropClick') {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    setIsModalOpen(false);
    setCancelModalOpen(false);
    dispatch(facilityActions.setCreatedOffContractId(null));
  };

  const formatTableDataAsRateElements = (tableData, tableName) => {
    if (tableData.length > 0) {
      tableData.map(row => {
        row.shifts?.forEach(shift => {
          if (shift.inputValue) {
            if (tableName === 'gww') {
              const rateElement = {
                ...cloneDeep(InitialRateElementState),
                lastUpdatedBy: userInfo?.userInfo?.firstName + ' ' + userInfo?.userInfo?.lastName,
                createdBy: userInfo?.userInfo?.firstName + ' ' + userInfo?.userInfo?.lastName,
              };
              rateElement.rateElementNameId = row.id;
              rateElement.shiftId = shift.shiftId;
              if (!shift.inputValue.includes('-')) {
                rateElement.value = Number(shift.inputValue);
              } else {
                let values = shift.inputValue.split('-');
                rateElement.value = Number(values[0]);
                rateElement.valueMax = Number(values[1]);
              }
              offContract.rateElements.push(rateElement);
              setOffContract(offContract);
            } else if (tableName === 'rateElems') {
              chosenSkillSets.map(skill => {
                const rateElement = {
                  ...cloneDeep(InitialRateElementState),
                  lastUpdatedBy: userInfo?.userInfo?.firstName + ' ' + userInfo?.userInfo?.lastName,
                  createdBy: userInfo?.userInfo?.firstName + ' ' + userInfo?.userInfo?.lastName,
                  disciplineId: skill.disciplineId,
                  specialtyId: skill.specialtyId,
                  subSpecialtyId: skill.subSpecialtyId,
                };
                rateElement.rateElementNameId = row.id;
                rateElement.shiftId = shift.shiftId;
                if (!shift.inputValue.includes('-')) {
                  rateElement.value = Number(shift.inputValue);
                } else {
                  let values = shift.inputValue.split('-');
                  rateElement.value = Number(values[0]);
                  rateElement.valueMax = Number(values[1]);
                }
                offContract.rateElements.push(rateElement);
                setOffContract(offContract);
              });
            }
          }
        });
      });
    }
  };

  const addOneYearToDate = inputDate => {
    const date = new Date(inputDate);
    const month = date.getMonth();
    const day = date.getDate();

    date.setMonth(month + 12);
    if (date.getMonth() !== (month + 12) % 12) {
      date.setDate(0); // last day of previous month
    } else if (date.getDate() !== day) {
      date.setDate(0); // last day of current month
    }

    return FormatDate(date);
  };

  useEffect(() => {
    const date = new Date();
    setStartDateValue(FormatDate(date));
    setEndDateValue(addOneYearToDate(date));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      (gwwTableData && gwwTableData.length > 0 && gwwTableData.some(x => x.shifts.some(y => y.inputValue))) ||
      (rateElemsTableData &&
        rateElemsTableData.length > 0 &&
        rateElemsTableData.some(x => x.shifts.some(y => y.inputValue)))
    ) {
      setValidData(true);
    } else {
      setValidData(false);
    }
  }, [gwwTableData, rateElemsTableData]);

  useEffect(() => {
    if (params?.id) {
      setOffContract({
        ...cloneDeep(InitialOffContractState),
        createdBy: userInfo.userInfo?.firstName + ' ' + userInfo?.userInfo?.lastName,
        lastUpdatedBy: userInfo.userInfo?.firstName + ' ' + userInfo.userInfo?.lastName,
        currentUserName: userInfo.userInfo?.firstName + ' ' + userInfo.userInfo?.lastName,
        currentUserId: userInfo.userInfo?.employeeId,
        facilityId: Number(params?.id),
        createdAt: `FID ${params?.id}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, params?.id]);

  useEffect(() => {
    if (startDateValue) {
      offContract.effectiveStartDate = new Date(startDateValue);
      setOffContract(offContract);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateValue]);

  useEffect(() => {
    if (startDateValue) {
      offContract.effectiveEndDate = new Date(endDateValue);
      setOffContract(offContract);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDateValue]);

  useEffect(() => {
    if (!isCallingAPI && createdOffContractId) {
      onClose();
      if (params?.id) {
        const payload: IOffContract = {
          facilityId: Number(params?.id),
          skillsetFilter: [],
          rateElementNameIds: [],
          statusIds: [],
        };
        dispatch(facilityActions.getOffContractRateElements(payload));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCallingAPI, createdOffContractId]);

  return (
    <FormProvider {...formMethods}>
      <GenericDialog
        open={isModalOpen}
        fullWidth
        className={classes.modalContainer}
        onClose={onClose}
        variant="blue"
        dialogTitleProps={{
          text: 'Add Off Contract Rate',
          expandable: true,
          closeButton: true,
        }}
        dialogActions={[
          {
            text: 'Cancel',
            variant: 'contained',
            color: 'tertiary',
            disabled: isCallingAPI,
            onClick: onClickCancel,
          },
          {
            text: 'Submit',
            variant: 'contained',
            color: 'primary',
            disabled: isCallingAPI || !validData || error !== null,
            onClick: onSubmit,
          },
        ]}
      >
        {isCallingAPI && <CircularProgress className={classes.validationLoader} />}
        <Typography variant="subtitle1" style={{ fontWeight: 500, padding: '5px 0px' }}>
          {t('order.offContractModel.effectiveDate')}
        </Typography>
        <Grid style={{ display: '-webkit-box' }}>
          <Controller
            control={control}
            name={'EffectiveDate'}
            rules={{ required: true }}
            render={({ ref, onChange, value, ...rest }) => (
              <DateRangePicker
                variant="filled"
                placeholder={'Start Date*'}
                useMaxWidth={true}
                single={true}
                trailingIconShow={true}
                value={startDateValue}
                initialDateRange={startDateValue}
                maxWidth={180}
                maxDate={new Date(endDateValue)}
                actionBar={false}
                quickSelect={false}
                clickAway={true}
                keepClickAwayVal={true}
                onChange={e => {
                  const startDate = e?.startDate ? FormatDate(e?.startDate) : '';
                  setStartDateValue(startDate);
                }}
              />
            )}
          />
          <div style={{ padding: '1rem' }}>{'to'}</div>
          <Controller
            control={control}
            name={'EffectiveDate'}
            rules={{ required: false }}
            render={({ ref, onChange, value, ...rest }) => (
              <DateRangePicker
                variant="filled"
                placeholder={'End Date*'}
                useMaxWidth={true}
                single={true}
                trailingIconShow={true}
                value={endDateValue}
                maxWidth={180}
                defaultFirstMonth={new Date(endDateValue)}
                initialDateRange={endDateValue}
                minDate={new Date(startDateValue)}
                actionBar={false}
                clickAway={true}
                quickSelect={false}
                keepClickAwayVal={true}
                onChange={e => {
                  const endDate = e?.startDate ? FormatDate(e?.startDate) : '';
                  setEndDateValue(endDate);
                }}
              />
            )}
          />
        </Grid>
        <Divider style={{ margin: '1rem 0rem' }} />
        <GuaranteedWorkRate setGwwTableData={setGwwTableData} />
        <Divider style={{ margin: '0rem 0rem 1rem' }} />
        <RateCardSection
          setDisableRE={setDisableRE}
          chosenSkillSets={chosenSkillSets}
          setChosenSkillSets={setChosenSkillSets}
        />
        <RateElementsSection setRateElemsTableData={setRateElemsTableData} disabled={disableRE} setError={setError} />
      </GenericDialog>
      <CancelDialog
        openDialog={cancelModalOpen}
        handleConfirmAction={onClose}
        handleCancelAction={() => {
          setCancelModalOpen(false);
        }}
      />
    </FormProvider>
  );
};
