import {
  actualHousingOption,
  endingTravelPayableOption,
  findOptionBasedOnId,
  getDepositAmountById,
  getFormattedTwoDigits,
  housingProviderOption,
  isDepositRefundable,
  isNumber,
  ptoOption,
  reasonForChangeOption,
  truncateZeros,
} from '@AMIEWEB/Placement/PlacementDetails/PlacementTabPanel/ModificationsTab/ModificationsUtils';
import {
  IStartDateMod,
  IEndDateMod,
  IShiftMod,
  IPayRateMod,
  IBillRateMod,
  IPtoMod,
  IOgpMod,
  IBonusMod,
  ITimeoff,
  IEmiLmoMod,
  IHousingMod,
  IFurniture,
  IPreferencesMod,
  ITravelAllowanceMod,
  IPayrollShiftDifferential,
  IBillingShiftDifferential,
} from 'app/models/Placement/PlacementMods';

export const getFormattedStartDate = data => {
  const formData: IStartDateMod = {
    placementId: data.placementId,
    travelerOptionId: data.travelerOptionId,
    startDate: data.startDate,
    currentStartDate: data.currentStartDate,
    currentEndDate: data.currentEndDate,
    currentPreEmploymentDate: data.currentPreEmploymentDate,
    initiatedByUserId: data.initiatedByUserId,
    initiatedBy: data.initiatedBy,
    newStartDate: data.startDate,
    newPreEmploymentDate: data.preEmploymentDate,
    keepConstant: data.keepConstant,
    startDateReasonCode: data.reasonCodeId ? data.reasonCodeId : '',
    startDateReasonCodeId: data.reasonCodeId ? data.reasonCodeId : '',
    assignRootTaskId: data.assignRootTaskId,
    assignTaskId: data.assignTaskId,
  };
  return formData;
};

export const getFormattedEndDate = data => {
  const formData: IEndDateMod = {
    placementId: data.placementId,
    newEndDate: data.endDate,
    travelerOptionId: data.travelerOptionId,
    currentStartDate: data.currentStartDate,
    currentEndDate: data.currentEndDate,
    initiatedByUserId: data.initiatedByUserId,
    initiatedBy: data.initiatedBy,
    assignRootTaskId: data.assignRootTaskId,
    assignTaskId: data.assignTaskId,
  };
  return formData;
};

export const getFormattedMinReqHours = data => {
  const formData = {
    placementId: data.placementId,
    travelerOptionId: data.travelerOptionId,
    minReqHours: isNumber(data.minReqHours) ? data.minReqHours : '',
    assignRootTaskId: data.assignRootTaskId,
    assignTaskId: data.assignTaskId,
  };
  return formData;
};

export const getFomattedPayRate = (data, exceptions) => {
  const formData: IPayRateMod = {
    placementId: data.placementId,
    travelerOptionId: data.travelerOptionId,
    regularRate: getFormattedTwoDigits(data.regularRate),
    overtimeFactor: data.overtimeFactor,
    doubleTimeFactor: data.doubleTimeFactor,
    holidayFactor: data.holidayFactor,
    onCallRate: getFormattedTwoDigits(data.onCallRate),
    callBackFactor: data.callBackFactor,
    chargeAmount: data.chargeAmount,
    contractRegularRate: data.contractRegularRate,
    contractShift: data.contractShift,
    contractChargeAmount: data.contractChargeAmount,
    chargePremium: getFormattedTwoDigits(data.chargePremium),
    preceptorRate: getFormattedTwoDigits(data.preceptorRate),
    clientPayRateNotes: data.clientPayRateNotes,
    isModified: data.isModified,
    payRoll: data.payroll,
    timestamp: data.timestamp,
    amountPerMile: getFormattedTwoDigits(data.amountPerMile),
    paysGww: data.paysGww,
    tempRate: data.tempRate,
    additionalPremiumPay: data.additionalPremiumPay,
    placementTimestamp: data.placementTimestamp,
    overtimeTotal: data.overtimeTotal,
    doubletimeTotal: data.doubletimeTotal,
    holidayTotal: data.holidayTotal,
    callbackTotal: data.callbackTotal,
    gwwExceptions: data?.gwwExceptions
      ? exceptions?.filter(item => data.gwwExceptions?.includes(parseInt(item.id)))?.map(item => item.description)
      : [],
    assignRootTaskId: data.assignRootTaskId,
    assignTaskId: data.assignTaskId,
  };
  return formData;
};

export const getFormatteTravelAllowance = (data: any, newMod?: boolean) => {
  let timeOff = data?.placementTimeOffList;
  const formData: ITravelAllowanceMod = {
    placementId: data.placementId,
    travelerOptionId: data.travelerOptionId,
    payerId: data.payerId,
    payeeId: data.payeeId,
    payer: data.descPayer,
    payableTo: data.descPayee,
    amountPerMile: data.amountPerMile === 0 ? '0' : data.amountPerMile,
    allowanceCap: data.allowanceCap === 0 ? '0' : data.allowanceCap,
    billable: data.billable,
    billableToClient: data.billableToClient,
    assignRootTaskId: data.assignRootTaskId,
    // for interim travel
    placementTimeOffList:
      timeOff.length > 0
        ? timeOff.map(item => {
            return { ...item, isModified: false };
          })
        : [],
    totalCount: data?.totalCount,
    assigned: data?.assigned,
  };
  if (newMod) {
    if (data.payeeId === 0 || data.payeeId === 1) {
      formData.payableTo = endingTravelPayableOption[0].Description;
      formData.payeeId = endingTravelPayableOption[0].Id;
    } else {
      formData.payableTo = endingTravelPayableOption[1].Description;
      formData.payeeId = endingTravelPayableOption[1].Id;
    }
    if (data.payeeId === 0) {
      // if payeeId == 0 it mean there is no active approved record
      formData.amountPerMile = null;
      formData.allowanceCap = null;
    } else {
      formData.amountPerMile = data.amountPerMile === 0 ? '0' : data.amountPerMile;
      formData.allowanceCap = data.allowanceCap === 0 ? '0' : data.allowanceCap;
    }
  }
  return formData;
};

export const getFormattedShift = data => {
  const formData: IShiftMod = {
    placementId: data.placementId,
    travelerOptionId: data.travelerOptionId,
    shiftId: data.shiftId,
    shiftDescription: data?.shiftDescription,
    assignRootTaskId: data.assignRootTaskId,
    assignTaskId: data.assignTaskId,
  };
  return formData;
};
export const getFormattedPto = data => {
  const option = findOptionBasedOnId(ptoOption, data.ptoPlanId);
  const formData: IPtoMod = {
    placementId: data.placementId,
    travelerOptionId: data.travelerOptionId,
    ptoPlanId: data.ptoPlanId,
    ptoPlanDescription: option ? option.Description : '',
    ptoPlanFullDescription: option ? option.FullDescription?.replace(/\r?\n/g, '\r\n') : '',
    assignRootTaskId: data.assignRootTaskId,
    assignTaskId: data.assignTaskId,
  };
  return formData;
};

export const getFormattedLodging = data => {
  const formData = {
    placementId: data.placementId,
    travelerOptionId: data.travelerOptionId,
    lodgingPerDiemAdjustment: isNumber(data.uhBenefit) ? data.uhBenefit : '',
    dailyLodging: data.daily,
    assignRootTaskId: data.assignRootTaskId,
    assignTaskId: data.assignTaskId,
  };
  return formData;
};

export const getFormattedInsurance = data => {
  const formData = {
    placementId: data.placementId,
    travelerOptionId: data.travelerOptionId,
    effectiveDateTypeId: data.effectiveDateTypeId ? data.effectiveDateTypeId : null,
    effectiveDateDescription: data.effectiveDateDescription,
    insuranceTypeId: data.placementInsuranceTypeId,
    insuranceTypeDescription: data.insuranceTypeDescription,
    insuranceProviderDescription: data.insuranceProviderDescription,
    insuranceAmount: isNumber(data.amount) ? data.amount : '',
    insuranceCustomDate: data.insuranceEffectiveDate,
    assignRootTaskId: data.assignRootTaskId,
    assignTaskId: data.assignTaskId,
  };
  return formData;
};
export const getFomattedBillRate = (data, exceptions, pricingReasons) => {
  const formData: IBillRateMod = {
    placementId: data.placementId,
    travelerOptionId: data.travelerOptionId,
    regularRate: getFormattedTwoDigits(data.regularRate),
    billRateGwwRate: getFormattedTwoDigits(data.gww),
    overtimeRate: getFormattedTwoDigits(data.overtimeRate),
    doubleTimeRate: getFormattedTwoDigits(data.doubleTimeRate),
    overtimeFactor: getFormattedTwoDigits(data.overtimeFactor),
    doubleTimeFactor: getFormattedTwoDigits(data.doubleTimeFactor),
    holidayFactor: getFormattedTwoDigits(data.holidayFactor),
    onCallRate: getFormattedTwoDigits(data.onCallRate),
    callBackFactor: getFormattedTwoDigits(data.callBackFactor),
    chargePremium: getFormattedTwoDigits(data.chargePremium),
    preceptorRate: getFormattedTwoDigits(data.preceptorRate),
    clientPayRateNotes: data.clientPayRateNotes,
    chargeAmount: data.chargeAmount,
    contractRegularRate: data.contractRegularRate,
    contractChargeAmount: data.contractChargeAmount,
    contractShift: data.contractShift,
    isModified: data.isModified,
    payRoll: data.payroll,
    timestamp: data.timestamp,
    amountPerMile: getFormattedTwoDigits(data.amountPerMile),
    paysGww: data.paysGww,
    tempRate: data.tempRate,
    additionalPremiumPay: getFormattedTwoDigits(data.additionalPremiumPay),
    placementTimestamp: data.placementTimestamp,
    overtimeTotal: getFormattedTwoDigits(data.overtimeTotal),
    doubletimeTotal: getFormattedTwoDigits(data.doubletimeTotal),
    holidayTotal: getFormattedTwoDigits(data.holidayTotal),
    callbackTotal: getFormattedTwoDigits(data.callbackTotal),
    gwwExceptions: data.gwwExceptions
      ? exceptions?.filter(item => data.gwwExceptions?.includes(parseInt(item.id)))?.map(item => item.description)
      : [],
    billRateFlat: getFormattedTwoDigits(data.billRateFlat),
    billRateHourly: getFormattedTwoDigits(data.billRateHourly),
    contractTypeId: data.contractTypeId,
    holidayRate: getFormattedTwoDigits(data.holidayRate),
    callbackRate: getFormattedTwoDigits(data.callbackRate),
    premiumPricingDiff: getFormattedTwoDigits(data.premiumPricingDiff),
    adminFee: getFormattedTwoDigits(data.adminFee),
    orientationRate: getFormattedTwoDigits(data.orientationRate),
    orientationFactor: getFormattedTwoDigits(data.orientationFactor),
    lunchPenalty: getFormattedTwoDigits(data.lunchPenalty),
    shiftId: data.shiftId,
    billRateShift: '',
    cancelledHoursFrequency: '',
    billRateMinReqHours: getFormattedTwoDigits(data.hoursPerPayCycle),
    cancelledHours: getFormattedTwoDigits(data.cancellationHoursAllowed),
    cancellationHoursFrequencyId: data.cancellationHoursFrequencyId,
    gwwExceptionNotes: data.gwwExceptionNotes,
    premiumPricingReasons: data.premiumPricingReasons
      ? pricingReasons
          ?.filter(item => data.premiumPricingReasons?.includes(parseInt(item.Id)))
          ?.map(item => item.Description)
      : [],
    assignRootTaskId: data.assignRootTaskId,
    assignTaskId: data.assignTaskId,
  };
  return formData;
};

export const getFormattedReimbursement = data => {
  const formData = {
    placementId: data.placementId,
    travelerOptionId: data.travelerOptionId,
    description: data.description,
    amount: getFormattedTwoDigits(data.amount),
    requiresReceipts: data.requiresReceipts,
    initialEndDate: data.endDate,
    reimbursementFrequencyTypeId: data.reimbursementFrequencyTypeId,
    reimbursementCategoryDescriptionId: data.reimbursementCategoryDescriptionId,
    billable: data.billable,
    payeeId: data.payeeId,
    amountPerMile: data.amountPerMile,
    reimbursementCategoryId: data.reimbursementCategoryId,
    reimbursementDescriptionId: data.reimbursementCategoryDescriptionId,
    reimbursemetFrequencyTypeDescription: data.reimbursemetFrequencyTypeDescription,
    msaGoLiveDate: data.msaGoLiveDate,
    bookedDate: data.bookedDate,
    activityStatusId: data.activityStatusId,
    acceptanceStatusId: data.acceptanceStatusId,
    approvalStatusId: data.approvalStatusId,
    approvedById: data.approvedById,
    voidStatus: data.voidStatus,
    effectiveDate: data.effectiveDate,
    assignRootTaskId: data.assignRootTaskId,
    assignTaskId: data.assignTaskId,
    acceptanceStatusOptions: data.acceptanceStatusOptions,
    approvalStatusOptions: data.approvalStatusOptions,
    enableVoidOption: data.enableVoidOption,
    isNegativeStatus: data.isNegativeStatus,
  };
  return formData;
};

export const getFormattedPayrollShiftDifferential = data => {
  const formData: IPayrollShiftDifferential = {
    placementId: data.placementId,
    travelerOptionId: data.travelerOptionId,
    shiftDifferentialTemplateTypeId: data.shiftDifferentialTemplateTypeId,
    shiftDifferentials: data.shiftDifferentials,
    assignRootTaskId: data.assignRootTaskId,
  };
  return formData;
};

export const samplePricing = [
  {
    key: 2,
    value: 'Float',
  },
  {
    key: 1,
    value: 'Premium Traveler',
  },
  {
    key: 3,
    value: 'Other',
  },
];

export const getFormattedOgpInternationalLoan = data => {
  const formData: IOgpMod = {
    placementId: data.placementId,
    travelerOptionId: data.travelerOptionId,
    totalAmount: data.displayTotalAmount,
    perCheckAmount: data.displayPerCheckAmount,
    totalAdminFee: data.displayTotalAdminFee,
    perCheckAdminFee: data.displayPerCheckAdminFee,
    startDate: data.startDate,
    feeStartDate: data.feeStartDate,
  };
  return formData;
};

export const getFormattedBonus = (data, newMod: boolean) => {
  const formData: IBonusMod = {
    placementId: data.placementId,
    travelerOptionId: data.travelerOptionId,
    bonusType: data.descBonusType,
    bonusTypeId: data.bonusTypeId,
    bonusAmount: data.displayBonusAmount,
    paymentType: data.descPaymentType,
    paymentTypeId: data.paymentTypeId,
    payer: data.descPayer,
    payerId: data.payerId,
    billingType: data.descBillingType,
    billingTypeId: data.billingTypeId,
    billingAmount: data.displayBillingAmount,
    shortHoursType: data.descShortHoursType,
    bonusShortHoursTypeId: data.bonusShortHoursTypeId,
    dateDue: data.dateDue,
    startDate: data.startDate,
    endDate: data.endDate,
    numberOfWeeks: data.numberOfWeeks ? data.numberOfWeeks : '',
    requiredHours: data.requiredHours ? data.requiredHours : '',
    notes: data.notes ? data.notes : '',
    bonusPaid: data.bonusPaid === 'Y' ? true : false,
    forfeitBonus: data.forfeitBonus,
    approvedBy: data.approvedBy ? data.approvedBy : '',
    approvalMethod: data.descApprovalType,
    approvedById: data.approvedById,
    approvalDate: data.approvalDate,
    approvalStatusId: data.approvalStatusId,
    acceptanceStatusId: data.acceptanceStatusId,
    effectiveDate: data.effectiveDate,
    userId: data.userId,
    assignRootTaskId: data.assignRootTaskId,
  };

  if (newMod) {
    formData.paymentType = 'Completion';
    formData.paymentTypeId = 1;
    formData.payer = 'Company';
    formData.payerId = 1;
  }
  return formData;
};
export const getFomattedTimeOff = data => {
  const formData: ITimeoff = {
    placementId: data.placementId,
    travelerOptionId: data.travelerOptionId,
    timeOffRequests: data.timeOffRequests,
    assignRootTaskId: data.assignRootTaskId,
  };
  return formData;
};

export const getFomattedFurniture = data => {
  const formData: IFurniture = {
    placementId: data.placementId,
    travelerOptionId: data.travelerOptionId,
    furnitureRequests: data.furniture,
    utilitiesRequests: data.utilities,
    furnitureNotes: data.furnitureNotes?.replace(/\r?\n/g, '\r\n'),
    utilityNotes: data.utilityNotes?.replace(/\r?\n/g, '\r\n'),
    assignRootTaskId: data.assignRootTaskId,
  };
  return formData;
};

export const getFormattedEmiLmo = data => {
  const formData: IEmiLmoMod = {
    placementId: data.placementId,
    travelerOptionId: data.travelerOptionId,
    moveInDate: data.moveInDate,
    moveOutDate: data.moveOutDate,
    earlyMoveInDate: data.earlyMoveInDate ? data.earlyMoveInDate : '',
    emiNumberOfDays: data.emiNumberOfDays || data.emiNumberOfDays === 0 ? data.emiNumberOfDays : '',
    emiDaysWaived: data.emiDaysWaived || data.emiDaysWaived === 0 ? data.emiDaysWaived : '',
    emiCostPerDay: data.emiCostPerDay || (data.emiCostPerDay === 0 ? '0' : ''),
    lateMoveOutDate: data.lateMoveOutDate,
    lmoNumberOfDays: data.lmoNumberOfDays || data.lmoNumberOfDays === 0 ? data.lmoNumberOfDays : '',
    lmoDaysWaived: data.lmoDaysWaived || data.lmoDaysWaived === 0 ? data.lmoDaysWaived : '',
    lmoCostPerDay: data.lmoCostPerDay || (data.lmoCostPerDay === 0 ? '0' : ''),
    assignRootTaskId: data.assignRootTaskId,
  };
  return formData;
};

export const getFormattedHousing = (data, newMod: boolean) => {
  const formData: IHousingMod = {
    placementId: data.placementId,
    travelerOptionId: data.travelerOptionId,
    housingProvider: data.housingProvider,
    housingProviderId: data.housingProviderId,
    travelerRequestedHousingType: data.travelerRequestedHousingType,
    travelerRequestedHousingTypeId: data.travelerRequestedHousingTypeId,
    actualHousingType: data.actualHousingType,
    actualHousingTypeId: data.actualHousingTypeId,
    reasonForChange: data.reasonForChange,
    reasonForChangeId: data.reasonForChangeId,
    standardAmountCharged: truncateZeros(data.standardAmountCharged),
    amountToTraveler: truncateZeros(data.amountToTraveler),
    monthlyLodgingAmount: null,
    billableToClient: data.billableToClient === 'No' || data.billableToClient === '0' ? 'No' : 'Yes',
    petRentCharge: data.petRentCharge.toFixed(2),
    parkingGarageCharge: data.parkingGarageCharge.toFixed(2),
    utilityCharge: data.utilityCharge.toFixed(2),

    petDespositAmount: getDepositAmountById(data.deposits, 1),
    isPetDepositRefundable: isDepositRefundable(data.deposits, 1),
    private2BedRoomDepositAmount: getDepositAmountById(data.deposits, 2),
    is2BedRoomDepositRefundable: isDepositRefundable(data.deposits, 2),
    private3BedRoomDepositAmount: getDepositAmountById(data.deposits, 3),
    is3BedRoomDepositRefundable: isDepositRefundable(data.deposits, 3),
    otherDepositDescription: data.otherDepositDescription,
    otherDepositAmount: getDepositAmountById(data.deposits, 4),
    isOtherDepositRefundable: isDepositRefundable(data.deposits, 4),
  };

  if (newMod) {
    if (data.actualHousingTypeId) {
      const ahOption = actualHousingOption.find(item => item.Id === data.actualHousingTypeId);
      formData.actualHousingType = ahOption?.Description;
    }
    if (data.reasonForChangeId) {
      const rcOption = reasonForChangeOption.find(item => item.Id === data.reasonForChangeId);
      formData.reasonForChange = rcOption?.Description;
    }
    formData.housingProvider = housingProviderOption[0].Description;
  }
  return formData;
};

export const getFormattedPreferences = data => {
  const formData: IPreferencesMod = {
    placementId: data.placementId,
    travelerOptionId: data.travelerOptionId,
    housingType: data.housingInfo.housingType,
    furnitureExtras: data.housingInfo.furnitureExtras,
    typeOfBed: data.housingInfo.typeOfBed,
    adults: data.travelerInfo.adults,
    keyFacts: data.travelerInfo.keyFacts,
    children: data.travelerInfo.children,
    pets: data.travelerInfo.pets,
    assignRootTaskId: data.assignRootTaskId,
  };
  return formData;
};

export const getFormattedBillingShiftDifferential = data => {
  const formData: IBillingShiftDifferential = {
    placementId: data.placementId,
    travelerOptionId: data.travelerOptionId,
    shiftDifferentialTemplateTypeId: data.shiftDifferentialTemplateTypeId,
    billingShiftDifferentialRequests: data.shiftDifferentials,
    assignRootTaskId: data.assignRootTaskId,
  };
  return formData;
};
