import { RootState } from 'types';
import { initialState } from './utils';
import { createSelector } from '@reduxjs/toolkit';

const selectDomain = (state: RootState) => state?.contractTemplateEditor || initialState;

export const selectTemplatesEditor = createSelector([selectDomain], state => state);

export const selectTemplates = createSelector([selectDomain], state => state.templates);

export const selectMergeTokenList = createSelector([selectDomain], state => state.mergeTokenList);

export const selectSelectedTemplate = createSelector([selectDomain], state => state.selectedTemplate);
