/* eslint-disable tss-unused-classes/unused-classes */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useDecision } from '@optimizely/react-sdk';
import { ff_candidate_ui_view_Externaltasks } from '../../../constants/FeatureFlags/Candidate/Keys';
import { Button, ButtonGroup } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { CreateTask } from '../CreateTask/CreateTask';
import { useTranslation } from 'react-i18next';
import { pageAttributesActions } from '../../Placement/PlacementDetails/Edit/store/placementPageAttributes.redux';
import { TaskDetails } from '../TaskDetails/TaskDetails';
import { TaskItemsList } from './TaskItemsList';
import treeData from 'app/assets/jsons/placementTasksStatusFilter.json';
import { getMultiSelectOptions } from 'app/components/GlobalSearch/helper';
import { gridStateActions } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import { selectedCategoryList, selectExternalTaskCategoryList } from '../store/Tasks.selectors';
import { selectGridState } from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import { candidateDetailsSelection } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { selectOrderDetailsUIView, selectUnitDetailsId } from 'app/components/Order/Store/Order.selectors';
import { selectFacility } from 'app/components/Facility/FacilityStore/Facility.selector';
import { selectPlacementDetails } from 'store/redux-store/placement-details/selectors';
import { IPlacementDetails } from 'app/models/Placement/PlacementDetails';
import { taskDetailsActions } from '../store/Tasks.redux';
import { TaskEntity, defaultFilterValues, defaultFilterApiValues } from 'app/models/Tasks/Tasks';
import { getCandidateById } from 'app/services/CandidateServices/CandidateServices';
import { PlacementButtonGroup } from './PlacementButtonGroup';
import { isPositiveNumber } from 'app/helpers/componentHelpers';
import { TaskCustomFilter } from '../CustomComponents/TaskCustomFilter';

const TaskSidebarContainerStyles = makeStyles()(theme => ({
  addTaskButton: {
    border: `1px solid ${theme.palette.system.fadedGrey}`,
    borderRadius: '4px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    opacity: '1',
    height: '36px',
    width: '36px',
    padding: '0px 0px 0px 0px',
    font: 'normal normal normal 24px Roboto',
    color: '#000000',
    cursor: 'pointer',
  },
  containerPlacement: {
    width: 'auto',
    zIndex: 1,
    marginRight: '0px',
    margin: '0px',
  },
  buttonContainer: {
    padding: '10px 9px',
    backgroundColor: '#FFF',
    width: '100%',
  },
  addTaskText: {
    color: '#006FB9',
  },
  taskCardDisplay: {
    maxHeight: 'calc(100vh - 11rem)',
    paddingRight: '0px !important',
    paddingLeft: '0px !important',
    paddingTop: '6px',
    paddingBottom: '12px',
    display: 'flex',
    justifyContent: 'center',
  },
  buttonTitle: {
    textAlign: 'center',
    font: 'normal normal normal 14px/20px Roboto',
    color: '#555555',
    opacity: '1',
    width: '100%',
  },
  buttonTitleOnClick: {
    textAlign: 'center',
    font: 'normal normal normal 14px/20px Roboto',
    color: '#FFFFFF',
    opacity: '1',
    width: '100%',
    fontWeight: 'inherit',
  },
  allTaskButton: {
    width: '80px',
    height: '36px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '0px 4px 4px 0px',
    border: '1px solid #C3C8D1',
    opacity: '1',
    padding: '0px',
    textTransform: 'capitalize',
  },
  allTaskButtonOnClick: {
    width: '80px',
    height: '36px',
    background: `${theme.palette.components.button.secondary.backgroundColor} 0% 0% no-repeat padding-box`,
    borderRadius: '0px 4px 4px 0px',
    padding: '0px',
    opacity: '1',
    textTransform: 'capitalize',
    '&:hover': {
      background: theme.palette.components.button.secondary.backgroundColor,
    },
  },
  myTaskButton: {
    width: '80px',
    height: '36px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '4px 0px 0px 4px',
    border: '1px solid #C3C8D1',
    padding: '0px',
    opacity: '1',
    textTransform: 'capitalize',
  },
  myTaskButtonOnClick: {
    width: '80px',
    height: '36px',
    background: `${theme.palette.components.button.secondary.backgroundColor} 0% 0% no-repeat padding-box`,
    borderRadius: '4px 0px 0px 4px',
    padding: '0px',
    opacity: '1',
    textTransform: 'capitalize',
    '&:hover': {
      background: theme.palette.components.button.secondary.backgroundColor,
    },
  },
  GridWrapper: {
    paddingBottom: '0.6rem',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-end',
  },
}));

export const TaskSidebarContainer = props => {
  const { container: currentContainer } = props;
  const dispatch = useDispatch();
  const { classes, cx } = TaskSidebarContainerStyles();
  const { t } = useTranslation();
  const gridState = useSelector(selectGridState);
  const [selectedTaskID, setSelectedTaskID] = useState<number>();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [showMyTasks, setShowMyTasks] = useState(true);
  const [statusData, setStatusData] = useState<any[]>(defaultFilterApiValues);
  const [filterState, setFilterState] = useState<any>(defaultFilterValues);
  const [recruiterDetails, setRecruiterDetails] = useState<any>({});
  const candidateDetails = useSelector(candidateDetailsSelection);
  const candidateId = candidateDetails?.travelerId;
  const selectOrderUiDetails = useSelector(selectOrderDetailsUIView);
  const orderId = selectOrderUiDetails?.headerDetails?.orderID;
  const orderPageFacilityId = selectOrderUiDetails?.headerDetails?.facilityId;
  const facilityData = useSelector(selectFacility);
  const facilityId = facilityData?.facilityResponse?.facilityId;
  const foundPlacement: IPlacementDetails = useSelector(selectPlacementDetails);
  const categories = useSelector(selectedCategoryList);
  const externalCategories = useSelector(selectExternalTaskCategoryList);
  const unitId = useSelector(selectUnitDetailsId);
  const placementId = foundPlacement.header.placementId;
  const [isExternal, setExternal] = useState(false);
  const [externalDetails, setExternalDetails] = useState<any>({});
  const [statusChanged, setStatusChanged] = useState<boolean | null>(null);
  const [optionsSelected, setOptionsSelected] = useState<any[]>([]);
  const [candidateExternalTask] = useDecision(ff_candidate_ui_view_Externaltasks);

  const getIds = {
    placementId: placementId,
    candidateId: candidateId,
    orderId: orderId,
    facilityId: currentContainer === TaskEntity.ORDERS ? orderPageFacilityId : facilityId,
    unitId: currentContainer === TaskEntity.ORDERS ? selectOrderUiDetails?.selectedNewUnitId :
      currentContainer === TaskEntity.PLACEMENTS ? unitId : 0,
    companyId:
      currentContainer === TaskEntity.CANDIDATES
        ? candidateDetails.brandId
        : currentContainer === TaskEntity.PLACEMENTS
          ? foundPlacement.header.brandId
          : 0,
  };
  dispatch(taskDetailsActions.setEntityId(getIds));

  useEffect(() => {
    const header = foundPlacement?.header;
    if (isPositiveNumber(header?.candidate?.id) && isPositiveNumber(header?.brandId)) {
      getCandidateById(foundPlacement?.header?.candidate?.id, foundPlacement?.header?.brandId).then(response => {
        setRecruiterDetails(response?.recruiter);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundPlacement?.header?.candidate?.id, foundPlacement?.header?.brandId]);

  const dispatchFilter = values => {
    var statusSelectedArray: any[] = [];
    values?.map(selectedOption => {
      statusSelectedArray.push(parseInt(selectedOption.value));
      return statusSelectedArray;
    });
    setStatusData(statusSelectedArray);
    dispatch(
      gridStateActions.setFilterAttributes({
        ...gridState.filterAttributes,
        filterObject: Object.entries(values),
      }),
    );

    // filter list by status values passed through prop here
  };

  const changeStyle = () => {
    if (showMyTasks === true) {
      setShowMyTasks(false);
    } else if (showMyTasks === false) {
      setShowMyTasks(true);
    }
  };

  const onStatusChecked = values => {
    setOptionsSelected(values);
    const tempVals = values.filter(f => f.value !== 'All');
    setFilterState(tempVals);
  };

  useEffect(() => {
    if (categories?.length === 0 || externalCategories?.length === 0) {
      dispatch(taskDetailsActions.getCategoryListAction());
      dispatch(taskDetailsActions.getExternalCategoryListAction());
    }
    setOptionsSelected(defaultFilterValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Grid container direction="column" style={{ maxWidth: '18.5rem !important' }}>
        <Grid item style={{ minHeight: '3.6rem' }}>
          <Grid className={classes.containerPlacement}>
            <Typography component="div" className={classes.buttonContainer}>
              <Grid container direction="row" className={classes.GridWrapper}>
                <div>
                  <Grid>
                    <ButtonGroup>
                      {currentContainer === TaskEntity.PLACEMENTS || (currentContainer === TaskEntity.CANDIDATES
                        && candidateExternalTask.enabled) ? (
                        <PlacementButtonGroup
                          clasess={classes}
                          setShowMyTasks={setShowMyTasks}
                          showMyTasks={showMyTasks}
                          setExternal={setExternal}
                        />
                      ) : (
                        <>
                          <Button
                            onClick={() => {
                              return changeStyle();
                            }}
                            className={cx({
                              [classes.myTaskButtonOnClick]: showMyTasks === true,
                              [classes.myTaskButton]: showMyTasks === false,
                            })}
                          >
                            <div
                              className={cx({
                                [classes.buttonTitleOnClick]: showMyTasks === true,
                                [classes.buttonTitle]: showMyTasks === false,
                              })}
                            >
                              {t('notification.taskSidePanel.displayMyTasks')}
                            </div>
                          </Button>
                          <Button
                            onClick={() => {
                              return changeStyle();
                            }}
                            className={cx({
                              [classes.allTaskButtonOnClick]: showMyTasks === false,
                              [classes.allTaskButton]: showMyTasks === true,
                            })}
                          >
                            <div
                              className={cx({
                                [classes.buttonTitleOnClick]: showMyTasks === false,
                                [classes.buttonTitle]: showMyTasks === true,
                              })}
                            >
                              {t('notification.taskSidePanel.internalTasks')}
                            </div>
                          </Button>
                        </>
                      )}
                    </ButtonGroup>
                  </Grid>
                </div>
                <div
                  style={{
                    paddingLeft: (currentContainer === TaskEntity.PLACEMENTS || (currentContainer === TaskEntity.CANDIDATES && candidateExternalTask.enabled)) ? '0px' : '32px',
                    display: 'flex',
                    flexWrap: 'nowrap',
                    flexDirection: 'row',
                  }}
                >
                  <Grid>
                    <TaskCustomFilter
                      enableCustomScroll
                      removeCloseIcon
                      isMultiSelect={true}
                      selectAllValue={'All'}
                      size="small"
                      value={optionsSelected}
                      options={getMultiSelectOptions(treeData || [])}
                      onChange={onStatusChecked}
                      hiddenInput={true}
                      hideApply
                      apply={() => {
                        dispatchFilter(filterState);
                      }}
                    />
                  </Grid>
                  <Grid style={{ paddingLeft: '11px', paddingTop: '0.5px' }}>
                    <button
                      type="button"
                      className={classes.addTaskButton}
                      onClick={() => {
                        dispatch(pageAttributesActions.setPopperOpen(true));
                        setCreateModalOpen(true);
                      }}
                    >
                      {t('notification.taskSidePanel.addTaskButton')}
                    </button>
                  </Grid>
                </div>
              </Grid>
            </Typography>
            <Grid container></Grid>
            {createModalOpen && (
              <CreateTask
                open={createModalOpen}
                recruiterDetails={recruiterDetails}
                handleClose={() => {
                  dispatch(pageAttributesActions.setPopperOpen(false));
                  setCreateModalOpen(false);
                }}
                getAllIds={getIds}
                container={currentContainer}
              />
            )}
            {detailsModalOpen && (
              <TaskDetails
                taskID={selectedTaskID}
                container={currentContainer}
                open={detailsModalOpen}
                setDetailsModalOpen={setDetailsModalOpen}
                externalDetails={externalDetails}
                isExternal={isExternal}
                handleClose={() => {
                  dispatch(pageAttributesActions.setPopperOpen(false));
                  setSelectedTaskID(undefined);
                  setDetailsModalOpen(false);
                }}
                setStatusChanged={setStatusChanged}
                statusChanged={statusChanged}
              />
            )}
          </Grid>
        </Grid>
        <Container className={classes.taskCardDisplay}>
          <TaskItemsList
            setTaskID={id => setSelectedTaskID(id)}
            setModalOpen={openState => {
              dispatch(pageAttributesActions.setPopperOpen(true));
              setTimeout(() => {
                setDetailsModalOpen(openState);
              }, 750);
            }}
            displayMyTasks={showMyTasks}
            filterDataOptions={statusData}
            currentContainer={currentContainer}
            getAllIds={getIds}
            isExternal={isExternal}
            setExternalDetails={setExternalDetails}
            statusChanged={statusChanged}
          />
        </Container>
      </Grid>
    </React.Fragment>
  );
};
