import { Theme, debounce } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useEffect, useState } from 'react';
import statesData from 'app/assets/jsons/State.json';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { getCitiesAndZipsByStates } from 'app/services/SharedServices/SharedServices';
import { TypeAhead } from '@AMIEWEB/Common';
import { IOption } from '../ProfileTab/Education/FormInputDropdown';
import FormInputTextField from '../ProfileTab/Education/FormInputTextField';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    paddingLeft: '10px',
    width: '480px',
    marginTop: '0px !important',
  },
  textColor: {
    paddingTop: 8,
    paddingLeft: 0,
  },
  alingmentField: {
    padding: '0px 6px 0px 6px !important',
  },
}));

export const MONTHS = [
  { Id: 1, Description: 'January' },
  { Id: 2, Description: 'February' },
  { Id: 3, Description: 'March' },
  { Id: 4, Description: 'April' },
  { Id: 5, Description: 'May' },
  { Id: 6, Description: 'June' },
  { Id: 7, Description: 'July' },
  { Id: 8, Description: 'August' },
  { Id: 9, Description: 'September' },
  { Id: 10, Description: 'October' },
  { Id: 11, Description: 'November' },
  { Id: 12, Description: 'December' },
];

enum CountryCodes {
  USA = 1,
  CANADA = 2,
}

export const AddEducation = props => {
  const { classes } = useStyles();
  const [cityOptions, setCityOptions] = useState<IOption[]>([]);
  const [statesOptions, setStatesOptions] = useState<IOption[]>([]);
  const [defaultCities, setDefaultCities] = useState<IOption[]>([]);
  const { errors, getValues, setValue, control, formState, watch } = useFormContext();

  const enableSaveOnChanges = () => {
    const values = getValues();
    const { pCountry, pDegree, pMajor, pMonth, pSchool, pState, pYear, piCity } = values;

    if (!pSchool) {
      props.setSaveDisabled(true);
      return;
    } else if (!pDegree) {
      props.setSaveDisabled(true);
      return;
    } else if (!pMajor) {
      props.setSaveDisabled(true);
      return;
    } else if (!pMonth) {
      props.setSaveDisabled(true);
      return;
    } else if (!pYear) {
      props.setSaveDisabled(true);
      return;
    } else if (!pCountry) {
      props.setSaveDisabled(true);
      return;
    } else if (!pState && (pCountry?.id === CountryCodes.USA || pCountry?.id === CountryCodes.CANADA)) {
      props.setSaveDisabled(true);
      return;
    } else if (!piCity && pCountry?.id === CountryCodes.USA) {
      props.setSaveDisabled(true);
      return;
    } else {
      props.setSaveDisabled(false);
      return;
    }
  };

  useEffect(() => {
    enableSaveOnChanges();
  }, [watch(['pSchool', 'pState'])]);

  useEffect(() => {
    const country = getValues('pCountry');
    if (country?.id === CountryCodes.USA || country?.id === CountryCodes.CANADA) {
      const states = statesData.states
        .filter(e => e.CountryID === country?.id)
        .map(e => ({ id: e.ID, label: e.Description, value: e.StateNumber }));

      setStatesOptions(states);
      setValue('pState', states[0]);
    } else if (formState['dirtyFields']['pCountry'] || formState['touched']['pCountry']) {
      setStatesOptions([]);

      // DO NOT REMOVE SetTimeout otherwise state wont reset once you change state from USA to non-USA.
      setTimeout(() => {
        setValue('pState', '');
      }, 0);
    }
  }, [watch('pCountry')]);

  useEffect(() => {
    if (watch('pCountry')?.id === CountryCodes.USA && watch('pState')) {
      fetchCities();
    } else {
      setCityOptions([]);
    }

    setTimeout(() => {
      setValue('piCity', '');
    }, 0);
  }, [watch('pState')]);

  const fetchCities = async () => {
    const selectedStates = typeof watch('pState') ? [watch('pState')?.label] : [];
    const result = getCitiesAndZipsByStates({
      states: selectedStates,
      cities: [],
    });

    let filteredCities = [];
    result.then(res => {
      filteredCities = res[0]?.cities?.map(item => {
        return { id: item.id, label: item.name };
      });

      setCityOptions(filteredCities);
      setDefaultCities(filteredCities);
    });
  };

  const onTextSearch = async (key: string, searchMode: 'c' | 'z') => {
    const selectedStates = typeof watch('pState') ? [watch('pState')?.label] : [];
    if (searchMode === 'c') {
      const result = getCitiesAndZipsByStates({
        states: selectedStates,
        cities: [],
        searchMode: searchMode,
        searchValue: key,
      });

      let filteredCities = [];
      result.then(res => {
        filteredCities = res[0]?.cities?.map(item => {
          return { id: item.id, label: item.name };
        });

        setCityOptions(filteredCities);
      });
    }
  };

  return (
    <LayoutGrid direction="column" className={classes.root} spacing={4} style={{ marginTop: '10px' }}>
      <LayoutGridItem item>
        <LayoutGrid
          container
          classes={{ root: classes.root }}
          direction="row"
          data-testid="container"
          justifyContent="flex-start"
          spacing={4}
        >
          <LayoutGridItem item xs={6} className={classes.alingmentField}>
            <FormInputTextField name="pSchool" label={'School'} required={true} />
          </LayoutGridItem>
        </LayoutGrid>
      </LayoutGridItem>
      <LayoutGridItem item>
        <LayoutGrid
          container
          classes={{ root: classes.root }}
          direction="row"
          data-testid="container"
          justifyContent="flex-start"
          spacing={4}
        >
          <LayoutGridItem item xs={6} className={classes.alingmentField}>
            <Controller
              name="pDegree"
              defaultValue={null}
              control={control}
              render={({ ref, ...rest }) => (
                <TypeAhead options={props.degreeData || []} label="Degree *" {...rest} isError={errors['pDegree']} />
              )}
            />
          </LayoutGridItem>
          <LayoutGridItem item xs={6} className={classes.alingmentField}>
            <Controller
              name="pMajor"
              defaultValue={null}
              control={control}
              render={({ ref, ...rest }) => (
                <TypeAhead options={props.majorData || []} label="Major *" {...rest} isError={errors['pMajor']} />
              )}
            />
          </LayoutGridItem>
        </LayoutGrid>
      </LayoutGridItem>
      <LayoutGridItem item>
        <LayoutGrid
          container
          classes={{ root: classes.root }}
          direction="row"
          data-testid="container"
          justifyContent="flex-start"
          spacing={4}
        >
          <LayoutGridItem item xs={6} className={classes.alingmentField}>
            <Controller
              name="pMonth"
              defaultValue={null}
              control={control}
              render={({ ref, ...rest }) => (
                <TypeAhead options={props.monthsData || []} label="Month *" {...rest} isError={errors['pMonth']} />
              )}
            />
          </LayoutGridItem>
          <LayoutGridItem item xs={6} className={classes.alingmentField}>
            <Controller
              name="pYear"
              defaultValue={null}
              control={control}
              render={({ ref, ...rest }) => (
                <TypeAhead options={props.yearsData || []} label="Year *" {...rest} isError={errors['pYear']} />
              )}
            />
          </LayoutGridItem>
        </LayoutGrid>
      </LayoutGridItem>
      <LayoutGridItem item>
        <LayoutGrid
          container
          classes={{ root: classes.root }}
          direction="row"
          data-testid="container"
          justifyContent="flex-start"
          spacing={4}
        >
          <LayoutGridItem item xs={6} className={classes.alingmentField}>
            <Controller
              name="pCountry"
              defaultValue={null}
              control={control}
              render={({ ref, ...rest }) => (
                <TypeAhead options={props.countriesData} label="Country *" {...rest} isError={errors['pCountry']} />
              )}
            />
          </LayoutGridItem>
        </LayoutGrid>
      </LayoutGridItem>
      <LayoutGridItem item>
        <LayoutGrid
          container
          classes={{ root: classes.root }}
          direction="row"
          data-testid="container"
          justifyContent="flex-start"
          spacing={4}
        >
          <LayoutGridItem item xs={6} className={classes.alingmentField}>
            {watch('pCountry')?.id === CountryCodes.USA || watch('pCountry')?.id === CountryCodes.CANADA ? (
              <Controller
                name="pState"
                defaultValue={statesOptions[0] || null}
                control={control}
                render={({ ref, ...rest }) => (
                  <TypeAhead options={statesOptions || []} label="State*" {...rest} isError={errors['pState']} />
                )}
              />
            ) : (
              <FormInputTextField name="pState" label={'State'} required={false} />
            )}
          </LayoutGridItem>
          <LayoutGridItem item xs={6} className={classes.alingmentField}>
            {watch('pCountry')?.id === CountryCodes.USA ? (
              <Controller
                name="piCity"
                defaultValue={null}
                control={control}
                render={({ ref, ...rest }) => (
                  <TypeAhead
                    options={cityOptions || []}
                    label="City*"
                    {...rest}
                    onInputChange={(text, reason) =>
                      reason !== 'reset' && debounce(text => onTextSearch(text, 'c'), 1500)(text)
                    }
                    onFocus={() => {
                      const cityOpts =
                        !!rest.value && !defaultCities.some(item => item.id === rest.value.id)
                          ? [rest.value, ...defaultCities]
                          : defaultCities;
                      setCityOptions(cityOpts);
                    }}
                  />
                )}
              />
            ) : (
              <FormInputTextField name="piCity" label={'City'} required={false} />
            )}
          </LayoutGridItem>
        </LayoutGrid>
      </LayoutGridItem>
    </LayoutGrid>
  );
};
