import CheckIcon from '@mui/icons-material/Check';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Checkbox,
  Grid,
  IconButton,
  ThemeProvider,
  StyledEngineProvider,
  Tooltip,
  Typography,
  useTheme,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { formatTime } from 'app/components/Candidate/helper/StringFormatter';
import { convertToPST } from 'app/helpers/dateHelper';
import { IMessageAttachment } from 'app/models/Sms/ISmsResult';
import React, { useState, useEffect } from 'react';
import styledComponent from 'styled-components';
import { getAttachmentFileExtension, getFileName } from '../../Common/AttachmentFileTypes';
import { IDirection } from '../../Constants';
import { Dot } from './Helpers/dots';
import ReplyIcon from '@mui/icons-material/Reply';
import Box from 'app/assets/images/IconImages/Box.svg';
import Boxcheck from 'app/assets/images/IconImages/Boxcheck.svg';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(theme => ({
  status: {
    display: 'flex',
    marginLeft: '3%',
    marginRight: '3%',
    marginBottom: '2%',
    color: '#AFAFAF',
    fontSize: '14px',
  },
  attachmentStyle: {
    textDecoration: 'none',
    color: '#FFF',
  },
  inboundAttachmentStyle: {
    textDecoration: 'none',
    color: '#333333',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '0px',
    flexDirection: 'column',
  },
}));

const Message = styledComponent.div`
  text-align: left;
  margin-right: 11px;
  margin-left: 11px;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0px;
  max-width: 75%;
  min-width: 20%;
  padding: 10px 10px;
  opacity: 1;
  word-break: break-word;
  appearance: textarea;
  white-space: break-spaces;
`;

const MessageTime = styledComponent(Typography)`
  margin-top: 3%;
  font-size: 12px;
  color: #afafaf;
`;

interface ChatProps {
  resetSelected: boolean;
  chatId: any;
  message: any;
  direction: any;
  time: any;
  resendMessage: any;
  removeKofaxLink: (fileName: string) => void;
  addKofaxLink: (fileName: string, url: string, signedUrl: string) => void;
  selectedKofaxCount: number;
  inProgress: boolean;
  error: boolean;
  attachments: IMessageAttachment[] | null;
  errorText?: string;
  allowResend?: boolean;
  hasResponse?: boolean;
}

export const Chat = ({
  resetSelected,
  chatId,
  message,
  direction,
  time,
  resendMessage,
  removeKofaxLink,
  addKofaxLink,
  selectedKofaxCount,
  attachments,
  inProgress = false,
  error = false,
  errorText,
  allowResend,
  hasResponse,
}: ChatProps) => {
  const { classes } = useStyles();
  const allow = hasResponse ? allowResend : false;
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    if (!resetSelected) return;
  }, [resetSelected]);

  const AttachmentJSX = (msgAttachments: IMessageAttachment[]) => {
    const result: JSX.Element[] = [];
    msgAttachments?.forEach((url: IMessageAttachment) => {
      const guid = !!url.contentUrl.split('/sms-attachments/')[1]
        ? url.contentUrl.split('/sms-attachments/')[1].split('/')[0]
        : 'guid-sms-attachment';
      const fileName = getFileName(url.contentUrl);
      const Attachment = (
        <React.Fragment>
          <Checkbox
            name={fileName}
            checkedIcon={<img src={Boxcheck} alt="checkedInIcon" />}
            icon={<img src={Box} alt="CheckboxIcon" />}
            onChange={event => {
              if (event.target.checked) {
                if (selectedKofaxCount < 5) addKofaxLink(fileName, url.contentUrl, url.signedUrl);
                else return;
              } else {
                removeKofaxLink(fileName);
              }
            }}
          />
          <a
            className={
              direction === IDirection.Inbound || direction == 2
                ? classes.inboundAttachmentStyle
                : classes.attachmentStyle
            }
            href={url.signedUrl}
            target="_blank"
            rel="noopener noreferrer"
            download={getFileName(url.contentUrl)}
          >
            {getAttachmentFileExtension(url.contentUrl)}
          </a>
        </React.Fragment>
      );

      const AttachmentWithToolTip = (
        <React.Fragment key={guid}>
          {!!url.dateDeliveredToKofax ? (
            <Tooltip
              title={t('notification.sms.kofax.forwardTooltip', { sentDate: convertToPST(url.dateDeliveredToKofax) })}
              arrow
              placement="bottom-start"
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {Attachment}
                <ReplyIcon
                  style={{
                    color: direction === IDirection.Inbound || direction == 2 ? '#808080b3' : '#ffffffab',
                    transform: 'rotateY(180deg)',
                    alignSelf: 'flex-start',
                  }}
                />
              </div>
            </Tooltip>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>{Attachment}</div>
          )}
        </React.Fragment>
      );
      result.push(AttachmentWithToolTip);
    });
    return <>{result}</>;
  };

  return (
    <Grid
      container
      {...(direction === IDirection.Inbound || direction == 2
        ? { justifyContent: 'flex-start', alignItems: 'flex-start' }
        : { justifyContent: 'flex-end', alignItems: 'flex-end' })}
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Message
            id={chatId}
            className={classes.message}
            style={{
              background:
                direction === IDirection.Inbound || direction == 2
                  ? '#EFF0F2'
                  : inProgress
                  ? '#87B7DC'
                  : error
                  ? '#D58288'
                  : '#0F6FB9',
              borderRadius:
                direction === IDirection.Inbound || direction == 2 ? '12px 12px 12px 0' : '12px 12px 0 12px ',
              color: direction === IDirection.Inbound || direction == 2 ? '#333333' : '#FFFFFF',
            }}
          >
            {!!attachments && attachments.length > 0 && (
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                {AttachmentJSX(attachments)}
              </div>
            )}
            {message && (
              <span
                style={
                  !!attachments && attachments.length > 0
                    ? { alignSelf: 'flex-start', paddingLeft: '0.8rem', fontSize: '0.9rem' }
                    : {}
                }
              >
                {message}
              </span>
            )}
          </Message>

          <Grid
            className={classes.status}
            spacing={1}
            container
            {...(direction === IDirection.Inbound || direction == 2
              ? { justifyContent: 'flex-start', alignItems: 'flex-start' }
              : { justifyContent: 'flex-end', alignItems: 'flex-end' })}
          >
            {inProgress ? (
              <Grid style={{ display: 'contents' }}>
                <MessageTime style={{ marginRight: '5px' }}>{`Sending Message`}</MessageTime>
                {[...Array(3)].map(() => (
                  <Dot>
                    <FiberManualRecordIcon style={{ fontSize: '8px' }} />
                  </Dot>
                ))}
              </Grid>
            ) : error ? (
              <Grid style={{ display: 'contents' }}>
                <MessageTime style={{ color: '#AB0512' }}>{errorText || `Message not delivered, retry `}</MessageTime>
                {/* ****   TODO : Commented this code until we have valid white listed error codes where retry is required **** */}
                {/* {allow && (
                  <IconButton
                    style={{ color: '#AB0512', padding: 0 }}
                    onClick={() => resendMessage(chatId)}
                    size="large"
                  >
                    <RefreshIcon style={{ fontSize: '18px' }}></RefreshIcon>
                  </IconButton>
                )} */}
              </Grid>
            ) : (
              <>
                <Grid item>
                  <MessageTime>{formatTime(convertToPST(time, 'HH:mm'))}</MessageTime>
                </Grid>
                <Grid item>
                  <CheckIcon
                    style={{
                      fontSize: '18px',
                      color: direction === IDirection.Inbound || direction == 2 ? '#AFAFAF' : '#34B233',
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </ThemeProvider>
      </StyledEngineProvider>
    </Grid>
  );
};
