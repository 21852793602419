import { IPlacementCandidateContractDocEditor } from './types';

export const initialState: IPlacementCandidateContractDocEditor = {
  templates: [],
  selectedTemplate: null,
  mergeTokenList: {},
  miscellaneous: {},
  classicDraftExists: false,
  amieWebDraftExists: false,
  amieWebCreatedExists: false,
};
