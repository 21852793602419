/* eslint-disable i18next/no-literal-string */
/**
 * THIS IS THE NEW STANDARD COMPONENT FOR A FILTER OPTION WRAPPER
 */

import React from 'react';
import { Paper, Grid, Button, Theme, Box, Popper, Typography, ClickAwayListener } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export const FilterDropDown = () => {
  return <div />;
};

const useStyles = makeStyles()((theme: Theme) => ({
  actionContainer: {
    backgroundColor: '#ffffff',
    '&:hover, &:focus': {
      backgroundColor: '#ffffff',
    },
    position: 'sticky',
    bottom: -8,
    width: '100%',
    borderTop: '1px solid #cccccc',
    padding: 5,
    zIndex: 10,
  },
  button: {
    fontSize: '12px',
    fontWeight: 'bold',
    textTransform: 'none',
    '&:hover, &:focus': {
      background: 'none',
    },
  },

  cancelButton: {
    color: '#888888',
  },
  countTag: {
    color: '#555555',
    fontSize: 14,
    fontWeight: 600,
    paddingLeft: 10,
  },
  popper: {
    zIndex: 1301,
    boxShadow: '0px 3px 8px 0px rgba(148,148,148,0.75)',
    '-webkit-box-shadow': '0px 3px 8px 0px rgba(148,148,148,0.75)',
    '-moz-box-shadow': '0px 3px 8px 0px rgba(148,148,148,0.75)',
    marginTop: '6px',
  },
  paper: {
    border: `1px solid lightgray`,
  },
  sudoTextBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 6px 5px 12px',
    width: '100%',
    minHeight: '38px',
    maxHeight: '38px',
    borderRadius: '4px',
    '& p': {
      fontSize: '12px',
    },
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },
    '&.focused': {
      padding: '4px 5px 4px 11px',
      color: '#52429A !important',
    },
    '&.empty p': {
      color: '#C0C0C0',
    },
  },
  expandIcon: {
    height: '21px',
    width: '21px',
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: '2px',
    '.empty &': {
      color: '#C0C0C0 !important',
    },
  },
  childWrapper: {
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '5px 12px',
  },
}));

export const PaperDropdown = ({
  isMultiSelect,
  onApplyEvent,
  onChange,
  filterName,
  customSelectedCount,
  value,
  Component,
  applyOnClickAway = false,
  applyOnEnter = false,
  anchorElement = undefined,
  isCustomTree = false,
  id,
  ...props
}) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);
  const boxRef = React.useRef<any>();
  const [originalVal, setOriginalVal] = React.useState(value);

  const toggleDropDown = event => {
    if (!open) setOpen(true);
  };

  const clearAll = () => {
    onChange([]);
  };

  const cancelChanges = () => {
    onChange(originalVal);
    setOpen(false);
    props?.handleCancel();
  };

  const apply = value => {
    props.handleApplyTreeData?.(value);
    onApplyEvent();
    setOpen(false);
  };

  React.useEffect(() => {
    if (open) {
      setOriginalVal(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onClickAway = e => {
    if (applyOnClickAway && originalVal !== value) {
      apply(value);
    } else if (e.target.type !== 'submit') {
      setOpen(false);
      cancelChanges();
    }
  };

  React.useEffect(() => {
    const handleKeyDown = event => {
      if (open && applyOnEnter && event.key === 'Enter' && originalVal !== value) {
        apply(value);
      }
    };

    document.body.addEventListener('keydown', handleKeyDown);

    return () => {
      document.body.removeEventListener('keydown', handleKeyDown);
    };
  }, [apply, applyOnEnter, onApplyEvent, open, originalVal, value]);

  React.useEffect(() => {
    if (isCustomTree && anchorElement) {
      setOpen(true);
    }
  }, [isCustomTree, anchorElement]);

  return (
    <React.Fragment>
      {!isCustomTree && (
        <div ref={boxRef}>
          <Box
            onClick={toggleDropDown}
            className={cx(classes.sudoTextBox, {
              focused: open,
              empty: !(value && ((customSelectedCount && customSelectedCount(value) > 0) || value.length > 0)),
            })}
          >
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Typography>{`${
                  isMultiSelect && value && value.length > 0
                    ? `(${customSelectedCount ? customSelectedCount(value) : value.length})`
                    : ''
                } ${filterName}`}</Typography>
              </Grid>
              <Grid item>
                {!open ? (
                  <ExpandMoreIcon className={classes.expandIcon} />
                ) : (
                  <ExpandLessIcon className={classes.expandIcon} />
                )}
              </Grid>
            </Grid>
          </Box>
        </div>
      )}
      {open && (
        <ClickAwayListener onClickAway={onClickAway}>
          <Popper
            className={classes.popper}
            placement="bottom-start"
            open={open}
            anchorEl={isCustomTree ? anchorElement : boxRef.current}
            id={id}
          >
            <Paper elevation={0} className={classes.paper}>
              <Grid
                item
                container
                justifyContent={isMultiSelect ? 'space-between' : 'flex-end'}
                alignItems="center"
                style={{ padding: 6, minWidth: 250 }}
              >
                {isMultiSelect ? (
                  <div className={classes.countTag}>{`${
                    isMultiSelect && value && value.length > 0
                      ? `${customSelectedCount ? customSelectedCount(value) : value.length}`
                      : 0
                  } ${t('search.filter.Selected')}`}</div>
                ) : null}
                <Button
                  className="clear-all-button"
                  aria-label="clear-all-button"
                  variant="text"
                  color="primary"
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  onClick={clearAll}
                  disabled={!isMultiSelect}
                  classes={{ text: ` ${classes.button} ` }}
                >
                  {t('search.filter.clearAll')}
                </Button>
              </Grid>
              <div className={classes.childWrapper}>
                <Component {...props} isMultiSelect={isMultiSelect} value={value} onChange={onChange} />
              </div>
              <div className={classes.actionContainer}>
                <Grid item container justifyContent="flex-end" alignItems="center">
                  <Button
                    className="clear-all-button"
                    aria-label="cancel-button"
                    variant="text"
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    onClick={() => cancelChanges()}
                    classes={{ text: ` ${classes.button} ${classes.cancelButton}` }}
                  >
                    {t('search.filter.cancel')}
                  </Button>
                  <Button
                    className="clear-all-button"
                    aria-label="apply-button"
                    variant="text"
                    color="primary"
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    onClick={() => apply(value)}
                    classes={{ text: ` ${classes.button} ` }}
                  >
                    {t('search.filter.apply')}
                  </Button>
                </Grid>
              </div>
            </Paper>
          </Popper>
        </ClickAwayListener>
      )}
    </React.Fragment>
  );
};
