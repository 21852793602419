import React, { useEffect } from 'react';
import { Autocomplete, TextField } from 'amn-ui-core';
import { Controller, useFormContext } from 'react-hook-form';

const FormAutoComplete = ({
  name,
  label,
  options,
  defaultValue,
  disabled,
}: {
  name: string;
  label: string;
  options: any;
  defaultValue?: any;
  disabled: boolean;
}) => {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    setValue(name, defaultValue);
  }, [defaultValue]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ ref, onChange, value, ...rest }) => (
        <Autocomplete
          {...rest}
          value={value}
          getOptionLabel={option => option?.name}
          isOptionEqualToValue={(option, currentValue) => option?.name === currentValue?.name}
          disableClearable
          options={options}
          onChange={(event, newData) => onChange(newData)}
          disabled={disabled}
          renderInput={params => <TextField {...params} label={label} variant="filled" placeholder="Select" />}
        />
      )}
    />
  );
};

export default FormAutoComplete;
