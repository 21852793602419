import React from 'react';
import { Grid, Typography } from 'amn-ui-core';

const UnitDetailLayout = ({ title, section }: { title: string; section: React.ReactElement }) => {
  return (
    <Grid item container>
      <Typography sx={{ fontWeight: 400, fontSize: '20px !important', pb: '12px' }}>{title}</Typography>
      <Grid item container rowGap={2} columnGap={2}>
        {section}
      </Grid>
    </Grid>
  );
};

export default UnitDetailLayout;
