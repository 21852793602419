import { Box, Grid, IconButton } from "amn-ui-core";
import React, { useState } from "react";
import { makeStyles } from 'tss-react/mui';
import { FunnelButton } from "./SearchPageIcons/FunnelButton";
import { ResetButton } from "./SearchPageIcons/ResetButton";
import { KeyBoardArrowButton } from "./SearchPageIcons/KeyBoardArrowButton";
import KeyboardControlKeyRoundedIcon from '@mui/icons-material/KeyboardControlKeyRounded';
import FunnelOutlineWithBlue from 'app/assets/images/IconImages/FunnelOutlineWithBlue.svg';

const useStyles = makeStyles()({
    resetButtonVersion2: {
        padding: 0,
    },
    funnelBtn: {
        padding: 0,
        paddingLeft: '4px',
    },
    ArrowBtn: {
        padding: 0,
    },
    KeyRoundedIcon: {
        height: '26px',
        width: '33px',
    },
    box: {
        marginRight: '1px',
        borderRadius: '50%'
    },
    FilterIcon: {
        padding: 0,
        margin: '-7px -7px -12px -8px'
    }
});

export const SearchPageIcons = (props) => {
    const { classes } = useStyles();
    const [hoverFunnel, setHoverFunnel] = useState(false);
    const [hoverReset, setHoverReset] = useState(false);
    const [hoverArrow, setHoverArrowIcon] = useState(false);

    return (
        <Grid>
            {props.resetlbutton && (
                <IconButton
                    color="secondary"
                    type="button"
                    disableTouchRipple
                    disableFocusRipple
                    onMouseEnter={() => setHoverReset(true)}
                    onMouseLeave={() => setHoverReset(false)}
                    classes={{ root: classes.resetButtonVersion2 }}
                    onClick={props.onResetForm}
                    size="medium">
                    <ResetButton toggleReset={hoverReset} />
                </IconButton>
            )}
            {props.funnelButton && (
                <IconButton
                    color="secondary"
                    type="button"
                    disableTouchRipple
                    disableFocusRipple
                    onMouseEnter={() => setHoverFunnel(true)}
                    onMouseLeave={() => setHoverFunnel(false)}
                    onClick={props.onAdvancedFilterClick}
                    classes={{ root: classes.funnelBtn }}
                    size="medium">
                    {props.checked ? (
                        <img src={FunnelOutlineWithBlue} alt="Funnel" className={classes.FilterIcon} />
                    ) : (
                        <FunnelButton toggle={hoverFunnel} />
                    )}
                </IconButton>
            )}
            {props.KeyboardArrowIcon && (
                <IconButton
                    color="secondary"
                    type="button"
                    disableTouchRipple
                    disableFocusRipple
                    onMouseEnter={() => setHoverArrowIcon(true)}
                    onMouseLeave={() => setHoverArrowIcon(false)}
                    onClick={props.onAdvancedChipsClick}
                    classes={{ root: classes.ArrowBtn }}
                    size="medium">
                    {props.checked ? (
                        <Box boxShadow={1} className={classes.box} >
                            <KeyboardControlKeyRoundedIcon color='primary' className={classes.KeyRoundedIcon} />
                        </Box>
                    ) : (
                        <KeyBoardArrowButton toggleArrow={hoverArrow} />
                    )}
                </IconButton>
            )}
        </Grid>
    );
};