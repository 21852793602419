import {
  IFacilityOffContractRateElements,
  IFacilityRateElementDetailsCommand,
  IOffContractRateElements,
} from 'store/redux-store/create-order-ocbr/types';
import { RateElements, getDiciplineName } from './helper';
import { Status } from 'app/enums/Facility';
import { Concatenate } from 'utils/string/string';
import moment from 'moment';

export const getAllRateElements = ({
  isFlatRate,
  ocbrBillRates,
  disciplineOpts,
  specialtyOpts,
  subSpecialtyOpts,
  offContractId,
}) => {
  const filteredOcbrRateEle =
    isFlatRate !== null
      ? ocbrBillRates.filter(rateEle => {
        return (
          (rateEle.type === RateElements.TravelBillRate && rateEle.shiftId !== null) ||
          (rateEle.type === RateElements.LocalBillRate && rateEle.shiftId !== null) ||
          (rateEle.type !== RateElements.TravelBillRate && rateEle.type !== RateElements.LocalBillRate)
        );
      })
      : isFlatRate === null
        ? ocbrBillRates
        : [];

  const rateElements: IOffContractRateElements[] = (filteredOcbrRateEle || []).map(item => {
    const skillSetInfo = getDiciplineName(item?.disciplineId, item?.specialtyId, item?.subSpecialtyId, {
      disciplineOpts,
      specialtyOpts,
      subSpecialtyOpts,
    });
    const temp = {
      offContractId: item.isOcbr ? (item.offContractId ? item.offContractId : offContractId) : null,
      rateElementId: item?.rateElementId,
      rateElementNameId: item?.type,
      disciplineId: item?.disciplineId ?? 0,
      disciplineName: skillSetInfo.disciplineName ?? null,
      disciplineAbbreviation: skillSetInfo.disciplineAbbr ?? null,
      specialtyId: item?.specialtyId ?? 0,
      specialtyName: skillSetInfo.specialityName ?? null,
      subSpecialtyId: item?.subSpecialtyId ?? 0,
      subSpecialtyName: skillSetInfo.subSpecilityName ?? null,
      shiftId: item?.shiftId,
      //@ts-ignore
      value: !item?.billRate?.isRange ? item?.billRate?.value : item?.billRate?.value?.min,
      //@ts-ignore
      valueMax: item?.billRate?.isRange && item?.billRate?.value?.max ? item?.billRate?.value?.max : null,
      statusId:
        item?.statusId === Status.Unsaved || item?.statusId === Status.Pending
          ? !!item?.documentIds?.length
            ? Status.PendingApproval
            : Status.Pending
          : item?.statusId,
      reason: item?.reason,
      dateApproved: null,
      approvedBy: null,
      dateCreated: item?.dateCreated,
      createdBy: item?.createdBy,
      dateLastUpdated: item?.dateLastUpdated,
      lastUpdatedBy: item?.lastUpdatedBy,
      effectiveStartDate: item?.effectiveStartDate,
      effectiveEndDate: item?.effectiveEndDate,
      documentIds: item?.documentIds ?? [],
      source: item?.source,
      isOffContract: item?.isOcbr,
      createdAt: item?.createdAt,
    };
    return temp;
  });
  return rateElements;
};

/**
 * Creates payload for the Save offcontract service call
 * @param orderId,
 * ocbrBillRates,
  isFlatRate,
  startDate,
  endDate,
  facilityId,
  offContractId,
  user,
 * @returns Facility Offcontract details
 */
export const getOffContractDataToSave = (
  orderId,
  ocbrBillRates,
  isFlatRate,
  startDate,
  endDate,
  facilityId,
  offContractId,
  user,
) => {
  const offContarctDetails = ocbrBillRates.filter(
    item =>
      (item?.type === RateElements.TravelBillRate ||
        item?.type === RateElements.LocalBillRate ||
        item?.type === RateElements.GWW ||
        item?.type === RateElements.MileageReimbursement) &&
      item?.isOcbr === true &&
      item?.statusId === Status.Unsaved
  );
  let filteredOcbrRateEle = [];
  if (isFlatRate !== null) {
    filteredOcbrRateEle = offContarctDetails.filter(rateEle => {
      if (
        (rateEle.type === RateElements.TravelBillRate && rateEle.shiftId !== null) ||
        (rateEle.type === RateElements.LocalBillRate && rateEle.shiftId !== null) ||
        (rateEle.type !== RateElements.TravelBillRate && rateEle.type !== RateElements.LocalBillRate)
      ) {
        return true;
      }
    });
  } else {
    filteredOcbrRateEle = offContarctDetails;
  }
  const rateElements: IOffContractRateElements[] = filteredOcbrRateEle?.map(item => {
    const temp = {
      offContractId: offContractId,
      rateElementId: item?.rateElementId,
      rateElementNameId: item?.type,
      disciplineId: item?.disciplineId ?? 0,
      specialtyId: item?.specialtyId ?? 0,
      subSpecialtyId: item?.subSpecialtyId ?? 0,
      shiftId: item?.shiftId,
      //@ts-ignore
      value: !item?.billRate?.isRange ? item?.billRate?.value : item?.billRate?.value?.min,
      //@ts-ignore
      valueMax: item?.billRate?.isRange && item?.billRate?.value?.max ? item?.billRate?.value?.max : null,
      statusId:
        item?.statusId === Status.Unsaved || item?.statusId === Status.Pending
          ? !!item?.documentIds?.length
            ? Status.PendingApproval
            : Status.Pending
          : item?.statusId,
      reason: item?.reason,
      dateApproved: null,
      approvedBy: null,
      dateCreated: item?.dateCreated,
      createdBy: item?.createdBy,
      dateLastUpdated: item?.dateLastUpdated,
      lastUpdatedBy: item?.lastUpdatedBy,
      effectiveStartDate: item?.effectiveStartDate,
      effectiveEndDate: item?.effectiveEndDate,
      documentIds: item?.documentIds,
    };
    return temp;
  });
  const offContractsDetailsToSave = {
    offContractId: offContractId,
    docType: 'OffContract',
    facilityId: facilityId,
    effectiveStartDate: startDate ? moment(startDate).format() : null,
    effectiveEndDate: endDate ? moment(endDate).format() : null,
    cCustomerId: 0,
    orderId: orderId,
    placementId: 0,
    approvaltaskId: 0,
    uploadtaskId: 0,
    note: null,
    dateCreated: moment().format(),
    createdBy: Concatenate([user.userInfo?.firstName, user.userInfo?.lastName], ' '),
    dateLastUpdated: moment().format(),
    lastUpdatedBy: Concatenate([user.userInfo?.firstName, user.userInfo?.lastName], ' '),
    rateElements: rateElements,
    currentUserName: Concatenate([user.userInfo?.firstName, user.userInfo?.lastName], ' '),
    currentUserId: user?.userInfo?.employeeId,
  };
  return rateElements?.length > 0 ? offContractsDetailsToSave : null;
};

/**
 * Creates payload for the Save facility offcontract service call
 * @param oorderId,
  ocbrBillRates,
  isFlatRate,
  offContractId,
  facilityId,
  user
 * @returns Offcontract details
 */
export const getFacilityRateElementDocumentsDataToSave = (
  orderId,
  ocbrBillRates,
  isFlatRate,
  offContractId,
  facilityId,
  user,
) => {
  const offContarctDetails = ocbrBillRates.filter(
    item =>
      (item?.type === RateElements.TravelBillRate ||
        item?.type === RateElements.LocalBillRate ||
        item?.type === RateElements.GWW ||
        item?.type === RateElements.MileageReimbursement) &&
      item?.isOcbr === true &&
      item?.statusId === (Status.Pending || Status.PendingApproval)
  );
  let filteredOcbrRateEle = [];
  if (isFlatRate !== null) {
    filteredOcbrRateEle = offContarctDetails.filter(rateEle => {
      if (
        (rateEle.type === RateElements.TravelBillRate && rateEle.shiftId !== null) ||
        (rateEle.type === RateElements.LocalBillRate && rateEle.shiftId !== null) ||
        (rateEle.type !== RateElements.TravelBillRate && rateEle.type !== RateElements.LocalBillRate)
      ) {
        return true;
      }
    });
  } else if (isFlatRate === null) {
    filteredOcbrRateEle = offContarctDetails;
  }

  const rateElements: IFacilityOffContractRateElements[] = filteredOcbrRateEle
    ?.map(item => {
      const temp = {
        offContractId: item?.offContractId ? item?.offContractId : offContractId,
        rateElementId: item?.rateElementId,
        rateElementNameId: item?.type,
        disciplineId: item?.disciplineId ?? 0,
        specialtyId: item?.specialtyId ?? 0,
        subSpecialtyId: item?.subSpecialtyId ?? 0,
        shiftId: item?.shiftId ?? 0,
        //@ts-ignore
        value: !item?.billRate?.isRange ? item?.billRate?.value : item?.billRate?.value?.min,
        //@ts-ignore
        valueMax: item?.billRate?.isRange && item?.billRate?.value?.max ? item?.billRate?.value?.max : null,
        documentIds: item?.documentIds ?? [],
        statusId:
          item?.statusId === Status.Unsaved || item?.statusId === Status.Pending
            ? !!item?.documentIds?.length
              ? Status.PendingApproval
              : Status.Pending
            : item?.statusId,
      };
      if (!!temp.documentIds?.length) return temp;
      else return null;
    })
    .filter(rateElement => {
      return rateElement != null;
    });

  const facilityRateElementsToSave: IFacilityRateElementDetailsCommand = {
    facilityId: facilityId,
    currentUserName: Concatenate([user.userInfo?.firstName, user.userInfo?.lastName], ' '),
    currentUserId: user?.userInfo?.employeeId,
    rateElements: rateElements,
  };
  return rateElements?.length > 0 ? facilityRateElementsToSave : null;
};
