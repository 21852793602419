import { Grid, IconButton } from 'amn-ui-core';
import React, { useState } from 'react';
import passportIcon from 'app/assets/images/Order/passport.svg';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { candidateDetailsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { selectGridSelection } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.selector';
import { theme } from 'styles/global-styles';

const useStyles = makeStyles()({
  iconButton: {
    padding: 0,
    margin: 0,
  },
  passportIcon: {
    transition: 'filter 0.2s',
  },
  bulkActionText: {
    fontSize: '12px',
    fontWeight: 500,
    textTransform: 'uppercase',
    marginLeft: `6px`,
    paddingTop: '1px',
    marginBottom: '4px',
    fontFamily: 'Roboto',
    color: theme.palette.framework.system.charcoal,
  },
  grayIcon: {
    filter: 'grayscale(100%) brightness(4)',
  },
  active: {
    filter:
      'brightness(0) saturate(100%) invert(64%) sepia(94%) saturate(1214%) hue-rotate(180deg) brightness(95%) contrast(100%) !important',
  },
});

export const PassportIconButton = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  let candidateDetails = useSelector(candidateDetailsSelection);
  const { selectedData } = useSelector(selectGridSelection);

  const [isHovered, setIsHovered] = useState(false);

  const enablePassportPublish =
    candidateDetails?.hasAcceptedMobileTermsAndConditions === 'Y' &&
    selectedData.length > 0 &&
    selectedData.length <= 15;

  return (
    <Grid onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <IconButton className={classes.iconButton} disableRipple>
        <img
          src={passportIcon}
          alt="Passport"
          style={{
            height: '20px',
            marginBottom: '3px',
          }}
          className={`${classes.passportIcon} ${
            isHovered ? classes.active : !enablePassportPublish ? classes.grayIcon : ''
          }`}
        />
        <Grid
          item
          className={`${classes.bulkActionText} ${
            isHovered ? classes.active : !enablePassportPublish ? classes.grayIcon : ''
          }`}
        >
          {t('global.xgrid.toolbar.passport')}
        </Grid>
      </IconButton>
    </Grid>
  );
};
