import React, { useMemo } from 'react';
import { Expandable, IExpandableProps } from 'app/components/Common/Expandable/Expandable';
import { Grid, Skeleton } from 'amn-ui-core';
import {
  IExtensionDetails,
  IPlacementStatus,
  IPlacementStatusRequirementMessage,
} from 'app/models/Placement/PlacementDetails';
import { IconTitle } from '../PlacementDetails/PlacementTabPanel/PlacementSummaryTab/Titles/IconTitle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ExtensionStatus } from './ExtensionStatus';
import { PlacementStatus } from './PlacementStatus';
import { NeutralForwardIcon } from './styles';
import { isCandidateContract, isClientConfirmation } from './StatusDefaults';
interface NextPlacementStatusProps extends Omit<IExpandableProps, 'titleComponent'> {
  statusList: IPlacementStatus[];
  failedRequirements?: {
    // typeStatus is [activityTypeId:activityStatusId]
    [typeStatusID: string]: IPlacementStatusRequirementMessage[];
  };
  onStatusSelected?: (status: IPlacementStatus) => void;
  // shows check icon in title for when there are no more status available
  complete?: boolean;
  // is create placement modal
  isCreatePlacement?: boolean;
  extensionDetails?: IExtensionDetails;
}

export const NextPlacementStatus = ({
  statusList,
  failedRequirements = {},
  loading,
  onStatusSelected,
  complete,
  isCreatePlacement,
  extensionDetails,
  activityStatusId,
  activityTypeId,
  inlineDense = false,
  ...props
}: NextPlacementStatusProps) => {
  /*
     activityStatusId:- current placement status id
     statusList:- current placement available status list
     extensionDetails:- extension details

     logic:-
     if current placement has an extension details; then display in what next container
     else if archive placement is set for 4, 5, 7
     else if current placement has available status and current placement status is not a negative status; then display in what next container
  */

  const { expanded, disabled, displayExtensions } = useMemo(() => {
    const isHavingNegativeStatus = [4, 5, 7, 20, 21, 22, 25, 26, 27].includes(activityStatusId);
    const hasExtStatus =
      !!extensionDetails?.extensionStatusId || extensionDetails?.availableExtensionStatus?.length > 0;
    const isArchivePlacement =
      (statusList &&
        statusList.length > 0 &&
        activityStatusId &&
        activityTypeId &&
        statusList.filter(x => x.activityTypeId === 101 && x.activityStatusId === 102).length > 0 &&
        [4, 5, 7].includes(activityStatusId) === true &&
        [1, 3].includes(activityTypeId) === true) === true;
    const hasValidStatus = (statusList && statusList.length > 0 && !isHavingNegativeStatus) || isArchivePlacement;
    const shouldBeDisplayed = hasExtStatus || hasValidStatus;
    return {
      expanded: shouldBeDisplayed, //if any of condition match display as expanded
      disabled: !shouldBeDisplayed, //if any of condition match display as disabled
      displayExtensions: hasExtStatus, // placement do not have current extensionStatus or availableExtensionStatus
    };
  }, [
    activityStatusId,
    activityTypeId,
    extensionDetails?.availableExtensionStatus?.length,
    extensionDetails?.extensionStatusId,
    statusList,
  ]);

  const _content = React.useMemo(() => {
    const { _statusList, _miscList } = statusList.reduce(
      (result, item) => {
        // CODE: _miscList is used to display create client confirmation and candidate contract opts below extension opts
        (isClientConfirmation(item) || isCandidateContract(item)) && displayExtensions
          ? result._miscList.push(item)
          : result._statusList.push(item);
        return result;
      },
      { _statusList: [], _miscList: [] },
    );

    return (
      <Grid container spacing={2}>
        {_statusList.length > 0 ? (
          <Grid item sx={{ width: 'inherit' }}>
            <PlacementStatus
              statusList={_statusList}
              failedRequirements={failedRequirements}
              onStatusSelected={onStatusSelected}
              isCreatePlacement={isCreatePlacement}
              inlineDense={inlineDense}
            />
          </Grid>
        ) : null}
        {displayExtensions ? (
          <Grid item sx={{ width: 'inherit' }}>
            <ExtensionStatus {...extensionDetails} onStatusSelected={onStatusSelected} />
          </Grid>
        ) : null}
        {_miscList.length > 0 ? (
          <Grid item sx={{ width: 'inherit' }}>
            <PlacementStatus
              statusList={_miscList}
              failedRequirements={failedRequirements}
              onStatusSelected={onStatusSelected}
              isCreatePlacement={isCreatePlacement}
              displayTopDivider
            />
          </Grid>
        ) : null}
      </Grid>
    );
  }, [
    displayExtensions,
    extensionDetails,
    failedRequirements,
    inlineDense,
    isCreatePlacement,
    onStatusSelected,
    statusList,
  ]);

  return (
    <Expandable
      id="placement-whats-next"
      loading={loading}
      titleComponent={
        <IconTitle
          title="What's Next"
          icon={<NeutralForwardIcon />}
          titleSupportComponent={complete && <CheckCircleIcon style={{ color: '#14a008' }} />}
        />
      }
      disabled={disabled}
      loadingRenderer={() => {
        return (
          <Grid container>
            {[1, 2].map((item, index) => (
              <Grid item xs={12} key={index}>
                <Grid container spacing={2}>
                  <Grid item style={{ width: '34px' }}>
                    <Skeleton />
                  </Grid>
                  <Grid item style={{ flex: '1 0' }}>
                    <Skeleton />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        );
      }}
      expanded={expanded}
      variant={inlineDense ? 'inlineDense' : undefined}
    >
      {_content}
    </Expandable>
  );
};
