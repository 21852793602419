import { Chip, ChipProps } from 'amn-ui-core';
import React from 'react';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HearingOutlinedIcon from '@mui/icons-material/HearingOutlined';
import { Concatenate } from 'utils/string/string';
import { IGroupedSkillset } from './SkillsetTree';

interface ISkillProps {
  description: string;
  verified: boolean;
}

export interface IVerifySkillset extends ISkillProps {
  disciplineId: number;
  disciplineAbbr: string;
  specialties: ISpecialty[];
}

export interface ISpecialty extends ISkillProps {
  specialityId: number;
  specialityAbbr: string;
  subSpecialties: ISubSpecility[] | null;
}

export interface ISubSpecility extends ISkillProps {
  subSpecialtyID: number;
  subSpecialityAbbr: string;
}

const getLabel = (discipline, specialty, subSpecialty = null) => {
  let label = Concatenate([discipline, specialty], '-');
  if (subSpecialty) label += `, ${subSpecialty}`;
  return label;
};

export const deriveVerifiedSkillsetChips = (skills: IVerifySkillset[], chipProps: ChipProps = {}) =>
  skills.reduce(
    (results, discipline) => {
      if (discipline.verified) {
        const verfiedSpecilities = discipline.specialties.filter(x => x.verified);
        if (verfiedSpecilities.length === 0)
          results.verifiedChips.push(<VerifySkillsetChip verified label={discipline.disciplineAbbr} {...chipProps} />);
        else
          verfiedSpecilities.forEach(spec => {
            const verifiedSubSpecialties = (spec.subSpecialties || []).filter(x => x.verified);
            if (verifiedSubSpecialties.length === 0)
              results.verifiedChips.push(
                <VerifySkillsetChip
                  verified
                  label={getLabel(discipline.disciplineAbbr, spec.specialityAbbr)}
                  {...chipProps}
                />,
              );
            else
              verifiedSubSpecialties.forEach(subSpec =>
                results.verifiedChips.push(
                  <VerifySkillsetChip
                    verified
                    label={getLabel(discipline.disciplineAbbr, spec.specialityAbbr, subSpec.subSpecialityAbbr)}
                    {...chipProps}
                  />,
                ),
              );
          });
      } else {
        const unverfiedSpecilities = discipline.specialties.filter(x => !x.verified);
        if (unverfiedSpecilities.length === 0)
          results.unverifiedChips.push(<VerifySkillsetChip label={discipline.disciplineAbbr} {...chipProps} />);
        else
          unverfiedSpecilities.forEach(spec => {
            const unverifiedSubSpecialties = (spec.subSpecialties || []).filter(x => !x.verified);
            if (unverifiedSubSpecialties.length === 0)
              results.unverifiedChips.push(
                <VerifySkillsetChip label={getLabel(discipline.disciplineAbbr, spec.specialityAbbr)} {...chipProps} />,
              );
            else
              unverifiedSubSpecialties.forEach(subSpec =>
                results.unverifiedChips.push(
                  <VerifySkillsetChip
                    label={getLabel(discipline.disciplineAbbr, spec.specialityAbbr, subSpec.subSpecialityAbbr)}
                    {...chipProps}
                  />,
                ),
              );
          });
      }
      return results;
    },
    { verifiedChips: [], unverifiedChips: [] },
  );

const classifyChips = ({ results, verified, label, chipProps }) =>
  verified
    ? results.verifiedChips.push(<VerifySkillsetChip verified label={label} {...chipProps} />)
    : results.unverifiedChips.push(<VerifySkillsetChip verified={false} label={label} {...chipProps} />);

/** Code: Used if skills have been declared verified w.r.t entire skill object */
export const deriveVerifiedSkillsetChip2 = (skills: IVerifySkillset[], chipProps: ChipProps = {}) =>
  skills.reduce(
    (results, skill) => {
      if (skill.specialties?.length === 0)
        classifyChips({ results, verified: skill.verified, label: skill.disciplineAbbr, chipProps });
      else
        skill.specialties.forEach(spec => {
          if (spec.subSpecialties?.length === 0)
            classifyChips({
              results,
              verified: skill.verified,
              label: getLabel(skill.disciplineAbbr, spec.specialityAbbr),
              chipProps,
            });
          else
            spec.subSpecialties.forEach(subSpec => {
              classifyChips({
                results,
                verified: skill.verified,
                label: getLabel(skill.disciplineAbbr, spec.specialityAbbr, subSpec.subSpecialityAbbr),
                chipProps,
              });
            });
        });
      return results;
    },
    { verifiedChips: [], unverifiedChips: [] },
  );

export const deriveSkillsetChips = (skills: IGroupedSkillset[], chipProps: ChipProps = {}) =>
  skills.reduce((results, discipline) => {
    if (discipline.specialties.length === 0)
      results.push(<SkillsetChip {...chipProps} label={discipline.disciplineAbbr} />);
    else
      discipline.specialties.forEach(specialty => {
        if (!specialty.subSpecialties?.length)
          results.push(
            <SkillsetChip {...chipProps} label={getLabel(discipline.disciplineAbbr, specialty.specialtyAbbr)} />,
          );
        else
          specialty.subSpecialties.forEach(subSpec =>
            results.push(
              <SkillsetChip
                {...chipProps}
                label={getLabel(discipline.disciplineAbbr, specialty.specialtyAbbr, subSpec.subSpecialty)}
              />,
            ),
          );
      });
    return results;
  }, []);

const VerifySkillsetChip = ({ label, verified = false, ...chipProps }) => (
  <Chip
    icon={verified ? <DoneAllIcon /> : <HearingOutlinedIcon />}
    variant="outlined"
    label={label}
    sx={{
      // '&:hover': {
      //   '& .MuiChip-root': {
      //     backgroundColor: '#ffffff',
      //   },
      // },
      '& .MuiChip-icon': {
        color: theme => (verified ? theme.palette.framework.system.navyBlue : theme.palette.framework.system.silver),
        fontSize: '18px',
        marginLeft: '6px',
      },
    }}
    {...chipProps}
  />
);

const SkillsetChip = ({ label, ...chipProps }) => <Chip variant="outlined" label={label} {...chipProps} />;
