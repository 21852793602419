import { ICustomHeader } from 'app/models/Grid';
import { GridColumns } from '@mui/x-data-grid-pro';
import { XGridContactHoverView } from 'app/components/Common/XGrid/cells/HoverViewRenderers/XGridContactHoverView';
import { XGridBlueCell } from 'app/components/Common/XGrid/cells/XGridBlueCell';
import { XGridDefaultColumn } from 'app/components/Common/XGrid/cells/XGridDefaults';
import { XGridLinkCell } from 'app/components/Common/XGrid/cells/XGridLinkCell';
import { XGridOverflowCell } from 'app/components/Common/XGrid/cells/XGridOverflowCell';
import { xGridDateSort } from 'app/components/Common/XGrid/sorting/XGridSorting';
import { XGridSideBarViewCell } from 'app/components/Common/XGrid/cells/XGridSideBarViewCell';
import { ChannelType } from 'app/components/Notification/Constants';
import { XGridNotificationCell } from 'app/components/RecruiterWorkDesk/MyPlacementsDesk/WorkdeskGrid/NotificationCell';

export interface IGridColumns extends Omit<ICustomHeader, 'title'> {
  title:
    | 'placementId'
    | 'placementStatus'
    | 'candidateId'
    | 'candidateName'
    | 'facilityId'
    | 'facilityName'
    | 'startDate'
    | 'discipline'
    | 'specialty'
    | 'brandId';
}

export enum amCredentialingGridKeys {
  candidate = 'candidateName',
  recruiter = 'recruiter',
  facilityName = 'facilityName',
  skillSet = 'skillSet',
}

export const PlacementStatusColumns: IGridColumns[] = [
  {
    title: 'placementId',
    value: 'Placement ID (PID)',
    sortable: true,
    clickable: true,
    navigationIds: ['placementId'],
    navigationExp: '/placement/{0}',
    isNumber: true,
  },
  {
    title: 'candidateName',
    value: 'Candidate Name',
    clickable: true,
    sortable: true,
    navigationIds: ['candidateId', 'brandId'],
    navigationExp: '/candidate/{0}/{1}',
    sortByField: 'clinicianLastName',
  },
  {
    title: 'startDate',
    value: 'Placement Start Date',
    sortable: true,
    defaultSort: true,
    sortByField: 'startDateNoFormat',
  },
  {
    title: 'placementStatus',
    value: 'Placement Status',
    sortable: true,
  },
  {
    title: 'facilityName',
    value: 'Client Name',
    sortable: true,
    sortByField: 'facilityNameLower',
    clickable: true,
    navigationIds: ['facilityId'],
    navigationExp: '/facility/{0}',
  },

  {
    title: 'discipline',
    value: 'Discipline / Speciality',
    sortable: true,
  },
  {
    title: 'candidateId',
    value: 'Candidate ID (CID)',
    clickable: true,
    sortable: true,
    navigationIds: ['candidateId', 'brandId'],
    navigationExp: '/candidate/{0}/{1}',
    isNumber: true,
  },
];

//columns for the X-grid
export const getPlacementStatusColumns = (t, flexColumns): GridColumns => {
  const defaultCredWorkDeskColumn = {
    ...XGridDefaultColumn,
    // replace this to have flex column layout for default columns
    // flex: flexColumns ? 1 : 0
    flex: 0,
  };
  return [
    {
      ...defaultCredWorkDeskColumn,
      headerName: t('home.amPlacementColumns.placementId'),
      field: 'placementId',
      width: 75,
      renderCell: XGridOverflowCell(XGridLinkCell('/placement/{0}', ['placementId'], true))(
        XGridLinkCell('/placement/{0}', ['placementId']),
      ),
    },
    {
      ...defaultCredWorkDeskColumn,
      headerName: t('home.amPlacementColumns.facility'),
      field: amCredentialingGridKeys.facilityName,
      width: 190,
      renderCell: XGridOverflowCell(XGridLinkCell('/facility/{0}', ['facilityId'], true))(
        XGridLinkCell('/facility/{0}', ['facilityId']),
      ),
    },
    {
      ...defaultCredWorkDeskColumn,
      headerName: t('home.amPlacementColumns.unit'),
      field: 'unit',
      width: 150,
    },
    {
      ...defaultCredWorkDeskColumn,
      headerName: t('home.amPlacementColumns.skillSet'),
      field: amCredentialingGridKeys.skillSet,
      width: 145,
    },
    {
      ...defaultCredWorkDeskColumn,
      field: 'candidateId',
      headerName: t('home.amPlacementColumns.candidateId'),
      width: 100,
      hide: false,
      renderCell: XGridOverflowCell(XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId'], true))(
        XGridSideBarViewCell('/candidate/{0}/{1}', ['candidateId', 'brandId']),
      ),
    },
    {
      ...defaultCredWorkDeskColumn,
      headerName: t('home.amPlacementColumns.candidate'),
      field: 'candidateName',
      width: 140,
      renderCell: XGridOverflowCell(XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId'], true))(
        XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId']),
      ),
    },
    {
      ...defaultCredWorkDeskColumn,
      headerName: t('Placement Status'),
      field: 'placementStatus',
      width: 140,
    },
    {
      ...defaultCredWorkDeskColumn,
      headerName: t('home.amPlacementColumns.startDate'),
      field: 'startDate',
      width: 125,
      type: 'date',
      sortComparator: xGridDateSort,
    },
    {
      ...defaultCredWorkDeskColumn,
      headerName: t('home.recWorkDeskHeaders.endDate'),
      field: 'endDate',
      type: 'date',
      width: 125,
      sortComparator: xGridDateSort,
    },
    {
      ...defaultCredWorkDeskColumn,
      headerName: t('home.recWorkDeskHeaders.lastContacted'),
      field: 'lastContactedOn',
      type: 'date',
      width: 130,
      sortComparator: xGridDateSort,
    },
    {
      ...defaultCredWorkDeskColumn,
      headerName: t('home.amPlacementColumns.credentialingAnalyst'),
      field: 'credentialingAnalystName',
      width: 190,
      renderCell: XGridOverflowCell(XGridContactHoverView('credentialingAnalyst'), true)(XGridBlueCell),
    },
    {
      ...defaultCredWorkDeskColumn,
      headerName: t('home.amPlacementColumns.recruiter'),
      field: 'recruiterName',
      width: 150,
      renderCell: XGridOverflowCell(XGridContactHoverView('recruiter'), true)(XGridBlueCell),
    },
    {
      ...defaultCredWorkDeskColumn,
      headerName: t('home.amPlacementColumns.clinicalManager'),
      field: 'clinicaliManagerName',
      width: 165,
      renderCell: XGridOverflowCell(XGridContactHoverView('clinicalManager'), true)(XGridBlueCell),
    },
    {
      ...defaultCredWorkDeskColumn,
      headerName: t('home.recWorkDeskHeaders.candidatePhone'),
      field: 'candidatePhone',
      width: 140,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: XGridOverflowCell(
        XGridNotificationCell({ channel: ChannelType.voice, displayKey: 'candidatePhone', v2: true, grid: 'credentialing' }),
        true,
      )(XGridNotificationCell({ channel: ChannelType.voice, displayKey: 'candidatePhone', tooltip: false, grid: 'credentialing' })),
    },
    {
      ...defaultCredWorkDeskColumn,
      headerName: t('home.recWorkDeskHeaders.candidateEmail'),
      field: 'candidateEmail',
      width: 135,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: XGridOverflowCell(
        XGridNotificationCell({ channel: ChannelType.email, displayKey: 'candidateEmail', v2: true, grid: 'credentialing' }),
        true,
      )(XGridNotificationCell({ channel: ChannelType.email, displayKey: 'candidateEmail', tooltip: false, grid: 'credentialing' })),
    },
    {
      ...defaultCredWorkDeskColumn,
      headerName: t('home.recWorkDeskHeaders.accountManager'),
      field: 'accountManagerName',
      width: 145,
      renderCell: XGridOverflowCell(XGridContactHoverView('accountManager'), true)(XGridBlueCell),
    },
  ];
};
