import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import { selectSubmissionInfo } from 'store/redux-store/placement-summary/selectors';
import { facilityStatusCheck } from './EditablePassFailLogic';
import { useEditableRequirement } from './EditRequirementsHelper';

export const useEditableRequirementFacilityStatus = () => {
  const {
    orderReq: {
      order: { facilityStatus: data },
    },
  } = useSelector(selectSubmissionInfo);
  const placementOrderType = useSelector(selectPlacementOrderType);
  const secondaryData = React.useMemo(() => ({ placementOrderType: placementOrderType }), [placementOrderType]);

  useEditableRequirement({
    data: data?.data,
    secondaryData: secondaryData,
    requirementId: IPlacementRequirementKey.facilityStatus,
    requirementKey: 'facilityStatus',
    requirementCheck: facilityStatusCheck,
    failRequirement: {
      id: IPlacementRequirementKey.facilityStatus,
      message: `Facility Status: ${data?.data}`,
      tooltip: {
        message: `Facility Status must be Active.`,
      },
    },
  });
};
