import _isArray from 'lodash/isArray';
import { generateFieldChips } from 'app/components/AmWorkDesk/PlacementsDesk/filterHelper';
import { isItemIncluded } from 'app/ComponentLibrary/Filter/helper';
import { IPassportCredSelectedFilters } from './IPassportCredFilter';

export enum passportCredSearchFilterName {
  category = 'categories',
  status = 'status',
}

export const defaultPassportCredFilterValues = {
  [passportCredSearchFilterName.category]: null,
  [passportCredSearchFilterName.status]: null,
};

export const returnFilterBody = (filterName, filter, data, remove = false) => {
  if (!data || !filter) return;
  const temp = { ...data };
  const formattedFilterData = _isArray(filter) ? filter.map(f => f.value) : filter;
  // organizes filter items
  switch (filterName) {
    default:
      if (remove && temp[filterName]) {
        temp[filterName] = temp[filterName]?.filter(t => t !== formattedFilterData?.value) || [];
      } else if (temp[filterName]) {
        temp[filterName] = formattedFilterData;
      }
      break;
  }
  return temp;
};

export const generateChips = (values, selectedChips?) =>
  Object.fromEntries(
    Object.entries(values).map(field => [
      field[0],
      generateFieldChips(
        field[1],
        selectedChips ? selectedChips[field[0]] : undefined,
        field[0] == 'orderBillRate'
          ? 'Order Bill Rate ($)'
          : field[0] === 'effectiveDate'
          ? ''
          : field[0] === 'OID'
          ? 'OID'
          : field[0] === 'startDateRange'
          ? 'Start Date'
          : field[0] === 'endDateRange'
          ? 'End Date'
          : field[0] === 'regionIds'
          ? 'Region'
          : undefined,
      ),
    ]),
  );

export const parseData = data => {
  return data?.reduce(function (newArr, item) {
    newArr.push(parseInt(item.id));
    return newArr;
  }, []);
};

export const parseByName = data => {
  return data?.reduce(function (newArr, item) {
    newArr.push(item.name);
    return newArr;
  }, []);
};

export const getFilteredData = (filter, sourceData, chipSelected?: IPassportCredSelectedFilters) => {
  const data = sourceData.reduce((resultArray, item) => {
    if (
      isItemIncluded(item['status'], parseByName(chipSelected?.status) || parseByName(filter?.status)) &&
      isItemIncluded(item['categoryId'], chipSelected?.categories || parseData(filter?.categories))
    )
      resultArray.push(item);
    return resultArray;
  }, []);

  return data;
};
