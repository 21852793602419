import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { trackPromise } from 'react-promise-tracker';
import { call, getContext, put, select } from 'redux-saga/effects';
import { placementDetailsAction } from 'store/redux-store/placement-details/slice';
import InitPlacementSummary, { IConsolidatedRequirementResponse } from '../init.types';
import { resetFailureStatus } from '../saga';
import { ServiceConstants } from '../sagaHelper';
import { IPlacementSummaryRequest, placementSummaryActions } from '../slice';
import { selectPlacementConsolidatedDetails } from '../consolidatedStoreSelectors';

const TrackTransportationWrapper = (fn, ...args) => trackPromise(fn(...args), 'placement-offer-transportation-data');

export function processtransportationData(
  response: IConsolidatedRequirementResponse<any>,
  context: InitPlacementSummary,
) {
  try {
    if (!httpSuccess(response?.status)) {
      context.setServiceFailureByKey({ key: 'offerTransportation', failureStatus: true });
      return;
    }
    context.setOfferTransportationInfo(response.data);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'processtransportationData',
        area: 'src/store/redux-store/placement-summary/common-saga/transportationData.ts',
      },
    });
  }
}

export function* requestTransportationData(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('offerTransportation'));
    const placementService: PlacementService = yield getContext('placementService');
    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if(action.payload.isInitLoad && consolidatedDetails){
      response = {
        status : 200,
        data :{
          offerTransportation : {
            transportation : consolidatedDetails.summary.offer.offerTransportationRequrement?.transportation?.data,
            travelTimePreference : consolidatedDetails.summary.offer.offerTransportationRequrement?.travelTimePreference?.data,
            dob : consolidatedDetails.summary.offer.offerTransportationRequrement?.dob?.data,
            gender : consolidatedDetails.summary.offer.offerTransportationRequrement?.gender?.data,
            ssn : consolidatedDetails.summary.offer.offerTransportationRequrement?.ssn?.data,
            departureAirport : consolidatedDetails.summary.offer.offerTransportationRequrement?.departureAirport?.data,
            departureItineraryLink : consolidatedDetails.summary.offer.offerTransportationRequrement?.departureItineraryLink?.data,
            arrivalDate : consolidatedDetails.summary.offer.offerTransportationRequrement?.arrivalDate?.data,
            arrivalTime : consolidatedDetails.summary.offer.offerTransportationRequrement?.arrivalTime?.data,
            nextStatusRequirement : consolidatedDetails.summary.offer.offerTransportationRequrement?.nextStatusRequirement,
          } 
        } 
      }
    }

    if(!response || !consolidatedDetails?.summary?.offer?.offerTransportationRequrement?.transportation?.status){
      response = yield call(
        TrackTransportationWrapper,
        placementService.getStrikeRequirementsByContainer,
        action.payload.placementId,
        ...ServiceConstants.offerTransportation,
      );
    }

    if (httpSuccess(response?.status)) {
      yield put(placementSummaryActions.setOfferTransportationInfo(response.data.offerTransportation));
      yield put(placementDetailsAction.resetForm());
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestTransportationData',
        area: 'src/store/redux-store/placement-summary/common-saga/transportationData.ts',
      },
    });
  }
}
