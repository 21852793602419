/* eslint-disable tss-unused-classes/unused-classes */
/* eslint-disable eqeqeq */
/* eslint-disable i18next/no-literal-string */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { LayoutGridItem, LayoutGrid } from 'app/layout/LayoutGrid';
import { useSelector } from 'react-redux';
import { candidateDetailsSelection, selectSkillSetTree } from '../../../Profile/CandidateDetails.selector';
import {
  ff_summaryTabIconsUpdate,
  ff_candidate_container_ui_v2,
  ff_WorkExperience_Enhancement,
} from 'app/constants/FeatureFlags/Candidate/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { ISkillSetExpiration } from 'app/enums/SkillSet';
import { ISkillCheckListDetails } from 'app/models/Candidate/SkillSet';
import { CredentialsGridType } from '../../CredentialsTab/CredentialsGrid';
import { mapAllSkillChecklistElements, setupSkillChecklistElements, validateChecked } from './helper';
import SkillSetUI from './Card Design/SkillSetUI';
import SkillSetUIV2 from './Card Design/SkillSetUIV2';
import { useStyles, useTooltipStyles } from './Card Design/utils';
import { findHoveredContent } from '../helper';

export const SkillsChecklistCard = ({ navigate }: { navigate: Function }) => {
  const { classes: tooltipClasses } = useTooltipStyles();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const candidateSkillSetTree = useSelector(selectSkillSetTree);
  const { classes } = useStyles();
  const [skillChecklistProgress, setSkillChecklistProgress] = useState<number>(0);
  const [checklistItemCount, setChecklistItemCount] = useState<number>(0);
  const [skillChecklistData, setSkillChecklistData] = useState<any>();
  const [verifiedSkillSets, setVerifiedSkillSets] = React.useState<any>();
  const [allSkillSets, setAllSkillSets] = React.useState<any>();
  const [showUpdatedSummaryIcons] = useDecision(ff_summaryTabIconsUpdate);
  let combinedCheckList: ISkillCheckListDetails[] | any = [];
  let totalChecklist: ISkillCheckListDetails[] = [];
  const skillChecklist = candidateDetails?.skillsChecklist?.skillsCheckList;
  const emptySkillChecklist = skillChecklist?.filter(item => item?.matchingSkillsChecklist === null) ?? [];
  const unMatchedCheckList = candidateDetails?.skillsChecklist?.unMatchedCheckList ?? [];
  const [candidateContainerUIV2] = useDecision(ff_candidate_container_ui_v2);
  const [hasWorkExperienceTab] = useDecision(ff_WorkExperience_Enhancement);

  if (skillChecklist?.length && showUpdatedSummaryIcons?.enabled) {
    const selectedMatchingChecklist: ISkillCheckListDetails[] | any = [];
    setupSkillChecklistElements(skillChecklist, selectedMatchingChecklist);
    // sets all nested arrays into single array
    const flattenedChecklist = selectedMatchingChecklist.flat(2);
    totalChecklist = []?.concat(flattenedChecklist, emptySkillChecklist, unMatchedCheckList);
    combinedCheckList = mapAllSkillChecklistElements(
      flattenedChecklist,
      emptySkillChecklist,
      unMatchedCheckList,
      combinedCheckList,
    );
  }

  /**
   *  Method to set tool tip content
   */
  const tooltipContent = (item: ISkillCheckListDetails) => {
    if (item?.matchingSkillsChecklist === null) return '';
    const element = findHoveredContent(item, totalChecklist);
    return (
      <div className={tooltipClasses.customScroll}>
        <div style={{ display: 'flexbox', flexDirection: 'row' }}>
          <b className={tooltipClasses.tooltipRowHeader}>{`${
            item?.expirationType === ISkillSetExpiration.EXPIRED ? `Expired on:` : `Expires on:`
          } `}</b>
          <span className={tooltipClasses.tooltipRowContent}>{element?.expiredOn ? element?.expiredOn : '--'}</span>
          <br></br>
          <b className={tooltipClasses.tooltipRowHeader}>{`Verified Skill Sets: `}</b>
          <span className={tooltipClasses.tooltipRowContent}>
            {element?.verifiedSkillSets ? element?.verifiedSkillSets : '--'}
          </span>
          <br></br>
          <b className={tooltipClasses.tooltipRowHeader}>{`Unverified Skill Sets: `}</b>
          <span className={tooltipClasses.tooltipRowContent}>
            {element?.unverifiedSkillSets ? element?.unverifiedSkillSets : '--'}
          </span>
          <br></br>
          <b className={tooltipClasses.tooltipRowHeader}>{`Unlisted Skill Sets: `}</b>
          <span className={tooltipClasses.tooltipRowContent}>
            {element?.unlistedSkillSets ? element?.unlistedSkillSets : '--'}
          </span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    var checklistItems: any = [];
    candidateDetails?.skillsChecklist?.skillsCheckList?.map(x => {
      if (x.matchingSkillsChecklist == null) {
        var data = {
          verified: x.verified,
          disciplineID: x.disciplineID,
          discipline: x.discipline,
          specialtyId: x.specialtyId,
          specialty: x.specialty,
          subSpecialtyId: x.subSpecialtyId,
          subSpecialty: x.subSpecialty,
          matchingSkillsChecklist: null,
        };
        checklistItems = [...checklistItems, data];
      }
      x.matchingSkillsChecklist?.map((y, i) => {
        var data = {
          verified: x.verified,
          disciplineID: x.disciplineID,
          discipline: x.discipline,
          specialtyId: x.specialtyId,
          specialty: x.specialty,
          subSpecialtyId: x.subSpecialtyId,
          subSpecialty: x.subSpecialty,
          matchingSkillsChecklist: y,
        };
        checklistItems = [...checklistItems, data];
      });
    });

    candidateDetails?.skillsChecklist?.unMatchedCheckList?.map(x => {
      var data = {
        verified: 0,
        disciplineID: 0,
        discipline: '',
        specialtyId: 0,
        specialty: '',
        subSpecialtyId: 0,
        subSpecialty: '',
        matchingSkillsChecklist: x,
      };
      checklistItems = [...checklistItems, data];
    });

    setSkillChecklistData(checklistItems);
  }, [candidateDetails.skillsChecklist]);

  useEffect(() => {
    const allSkills: { disciplineAbbr; specialtyAbbr?; subSpecialtyAbbr? }[] = [];
    const verifiedSkills: { disciplineAbbr; specialtyAbbr?; subSpecialtyAbbr? }[] = [];
    candidateSkillSetTree?.candidateSkillSets?.map(discipline => {
      if (discipline.verified || discipline.unverified)
        if (
          discipline.specialties &&
          (discipline.specialties.filter(item => item.verified).length > 0 ||
            discipline.specialties.filter(item => item.unverified).length > 0)
        ) {
          discipline.specialties.forEach(specialty => {
            if (specialty.verified || specialty.unverified) {
              if (
                specialty.subSpecilities &&
                (specialty.subSpecilities.filter(item => item.verified).length > 0 ||
                  specialty.subSpecilities.filter(item => item.unverified).length > 0)
              ) {
                return specialty.subSpecilities.forEach(subspecialty => {
                  if (subspecialty.verified || subspecialty.unverified) {
                    allSkills.push({
                      disciplineAbbr: discipline.abbreviation,
                      specialtyAbbr: specialty.description,
                      subSpecialtyAbbr: subspecialty.description,
                    });
                  }
                });
              } else {
                allSkills.push({
                  disciplineAbbr: discipline.abbreviation,
                  specialtyAbbr: specialty.description,
                });
              }
            }
          });
        } else {
          allSkills.push({
            disciplineAbbr: discipline.abbreviation,
          });
        }
    });

    candidateSkillSetTree?.candidateSkillSets?.map(discipline => {
      if (discipline.verified)
        if (
          discipline.specialties &&
          (discipline.specialties.filter(item => item.verified).length > 0 ||
            discipline.specialties.filter(item => item.unverified).length > 0)
        ) {
          discipline.specialties.forEach(specialty => {
            if (specialty.verified) {
              if (
                specialty.subSpecilities &&
                (specialty.subSpecilities.filter(item => item.verified).length > 0 ||
                  specialty.subSpecilities.filter(item => item.unverified).length > 0)
              ) {
                return specialty.subSpecilities.forEach(subspecialty => {
                  if (subspecialty.verified) {
                    verifiedSkills.push({
                      disciplineAbbr: discipline.abbreviation,
                      specialtyAbbr: specialty.description,
                      subSpecialtyAbbr: subspecialty.description,
                    });
                  }
                });
              } else {
                verifiedSkills.push({
                  disciplineAbbr: discipline.abbreviation,
                  specialtyAbbr: specialty.description,
                });
              }
            }
          });
        } else {
          verifiedSkills.push({
            disciplineAbbr: discipline.abbreviation,
          });
        }
    });
    setVerifiedSkillSets(verifiedSkills);
    setAllSkillSets(allSkills);
  }, [candidateSkillSetTree]);

  useEffect(() => {
    let count = 0;
    skillChecklistData?.map(item => {
      if (validateChecked(item, verifiedSkillSets)) count++;
      setChecklistItemCount(count);
    });
  }, [skillChecklistData]);

  useEffect(() => {
    setSkillChecklistProgress(checklistItemCount > 0 ? (checklistItemCount / skillChecklistData?.length) * 100 : 0);
  }, [checklistItemCount]);

  /**
   * Method to handle all button click
   */
  const handleNavigation = () => {
    let optedTab = 2;
    if (hasWorkExperienceTab?.enabled) {
      optedTab = optedTab + 1;
    }
    window.history.pushState(null, '', `${window.location.pathname}?tab={optedTab}`);
    navigate(
      optedTab,
      CredentialsGridType?.clinicalQualificationsParent,
      CredentialsGridType?.clinicalQualificationsSkillsChecklists,
    );
  };

  return (
    <LayoutGrid className={classes.grid}>
      <LayoutGridItem>
        {candidateContainerUIV2?.enabled ? (
          <SkillSetUIV2
            showUpdatedSummaryIcons={showUpdatedSummaryIcons}
            skillChecklistProgress={skillChecklistProgress}
            checklistItemCount={checklistItemCount}
            skillChecklistData={skillChecklistData}
            handleNavigation={handleNavigation}
            verifiedSkillSets={verifiedSkillSets}
            allSkillSets={allSkillSets}
            combinedCheckList={combinedCheckList}
            tooltipContent={tooltipContent}
          />
        ) : (
          <SkillSetUI
            showUpdatedSummaryIcons={showUpdatedSummaryIcons}
            skillChecklistProgress={skillChecklistProgress}
            checklistItemCount={checklistItemCount}
            skillChecklistData={skillChecklistData}
            handleNavigation={handleNavigation}
            verifiedSkillSets={verifiedSkillSets}
            allSkillSets={allSkillSets}
            combinedCheckList={combinedCheckList}
            tooltipContent={tooltipContent}
          />
        )}
      </LayoutGridItem>
    </LayoutGrid>
  );
};
