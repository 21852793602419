import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from 'tss-react/mui';
import styledComponent from 'styled-components';

const useStyles = makeStyles()({
  customTooltip: {
    backgroundColor: '#ffffff',
    color: '#0D2A3B',
    opacity: 1,
    textAlign: 'left',
    font: 'normal normal normal 12px/20px IBM Plex Sans',
    letterSpacing: '0px',
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #8C8C8C',
  },
  customArrow: {
    color: '#ffffff',
    opacity: 1,
    '&::before': {
      boxShadow: '0px 3px 6px #00000029',
      border: '1px solid #8C8C8C',
    },
  },
  tooltipPlacementBottom: {
    margin: 0,
  },
});

export const CountTooltip = props => {
  const { classes } = useStyles();
  const { value: items } = props;
  return (
    <CellContainer>
      <Content>{`${items[0]}`}</Content>
      <Tooltip
        title={items.map(item => (
          <div>{item}</div>
        ))}
        arrow
        classes={{
          tooltip: classes.customTooltip,
          arrow: classes.customArrow,
          tooltipPlacementBottom: classes.tooltipPlacementBottom,
        }}
      >
        <Count>{`+${items.length - 1}`}</Count>
      </Tooltip>
    </CellContainer>
  );
};

const CellContainer = styledComponent.td`
  display: flex;
`;
const Content = styledComponent.div`
  text-align: start;
  color: #003e68;
  font-size: 12px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 240px;
`;
const Count = styledComponent.div`
  color: #003e68;
  font-size: 12px;
  font-weight: bolder;
  padding-left: 10px;
`;
