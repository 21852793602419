export enum ClassicTags {
  ForeignTraveler = 'ForeignTraveler',
  ClinicalFellowshipYear = 'ClinicalFellowshipYear',
  NewGradProgram = 'NewGradProgram',
  NewGrad = 'NewGrad',
  VaMilExp = 'VaMilExp',
  KaizerHot = 'KaizerHot',
  KaizerWarm = 'KaizerWarm',
}

export enum ClassicTagValue {
  ForeignTraveler = 'foreignTraveler',
  ClinicalFellowshipYear = 'clinicalFellowshipYear',
  NewGradProgram = 'newGradProgram',
  NewGrad = 'newGrad',
  VaMilExp = 'vaMilExp',
  KaizerHot = 'kaizerHot',
  KaizerWarm = 'kaizerWarm',
}

export const formatClassicTagToTagsLookup = (classicTags: string[]) => {
  let selectedClassicTag = [];
  classicTags?.forEach(selectedTag => {
    switch (selectedTag) {
      case ClassicTagValue.ClinicalFellowshipYear:
        selectedClassicTag.push(ClassicTags.ClinicalFellowshipYear);
        break;
      case ClassicTagValue.ForeignTraveler:
        selectedClassicTag.push(ClassicTags.ForeignTraveler);
        break;
      case ClassicTagValue.NewGradProgram:
        selectedClassicTag.push(ClassicTags.NewGradProgram);
        break;
      case ClassicTagValue.NewGrad:
        selectedClassicTag.push(ClassicTags.NewGrad);
        break;
      case ClassicTagValue.VaMilExp:
        selectedClassicTag.push(ClassicTags.VaMilExp);
        break;
      case ClassicTagValue.KaizerHot:
        selectedClassicTag.push(ClassicTags.KaizerHot);
        break;
      case ClassicTagValue.KaizerWarm:
        selectedClassicTag.push(ClassicTags.KaizerWarm);
        break;
      default:
        break;
    }
  });
  return selectedClassicTag;
};
