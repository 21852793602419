import { CircularProgress, Grid, IconButton, Typography } from 'amn-ui-core';
import { LayoutGrid } from 'app/layout/LayoutGrid';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Cancel } from 'app/components/Common/CancelModal/Cancel';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { workExperienceSaga } from 'store/redux-store/candidate-work-experience/saga';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import {
  workExperienceActions,
  workExperienceReducer,
  workExperienceSliceKey,
} from 'store/redux-store/candidate-work-experience/slice';
import { brandsSelector, disciplineDataSelector, specialtiesSelector } from 'store/redux-store/lookup/lookup.selectors';
import { TravelView } from './TravelView';
import { ITypeAheadOption } from '../../../PreferencesTab/CustomComponents/ControlledTypeAhead';
import { TimeOffView } from './TimeOffView';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips';
import { useReadOnly } from 'oidc/userRoles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { trackException } from 'app-insights/appInsightsTracking';
import { candidateDetailsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import moment from 'moment';
import { selectUser } from 'oidc/user.selectors';
import { EnhancedEditWorkExperience } from '../EditWorkExperience/EnhancedEditWorkExperience';
import _, { isNil } from 'lodash';
import {
  hasEditModalAccess,
  setDefaultValues,
  dateFormatter,
  getDateDifference,
  validateWorkForm,
} from '../EditWorkExperience/WorkExperienceUtils';
import { reasonForLeavingDropDownOptions, getCountryOpts } from '../EditWorkExperience/Constants';
import { WorkExperienceModalStyles } from '../EditWorkExperience/WorkExperienceModal.style';
import { JobTypeIDs } from 'store/redux-store/candidate-work-experience/helper';

export const WorkExperienceModal = ({ selectedWorkExperience, onEditOpen }) => {
  useInjectReducer({ key: workExperienceSliceKey, reducer: workExperienceReducer });
  useInjectSaga({ key: workExperienceSliceKey, saga: workExperienceSaga });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const disciplines = useSelector(disciplineDataSelector);
  const specialtiesLookup = useSelector(specialtiesSelector);
  const travelCompanies = useSelector(brandsSelector);
  const { readOnly } = useReadOnly();
  const [isTravelWorkExp, setIsTravelWorkExp] = useState<boolean>(true);
  const [timeOffStartDate, setTimeOffStartDate] = useState<string>('');
  const [timeOffEndDate, setTimeOffEndDate] = useState<string>('');
  const user = useSelector(selectUser);
  const editModalAccess = useMemo(() => hasEditModalAccess(user), [user]);
  const [differenceDate, setDifferenceDate] = useState<string>('');
  const [typeAheadOptions, setTypeAheadOptions] = useState<{
    reasonForLeaving: ITypeAheadOption[];
    disciplines: ITypeAheadOption[];
    specialties: ITypeAheadOption[];
  }>({ disciplines: [], specialties: [], reasonForLeaving: reasonForLeavingDropDownOptions });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [travelCompanyData, setTravelCompanyData] = useState<any[]>([]);
  const [workStartDate, setWorkStartDate] = useState<string>('');
  const candidateDetails = useSelector(candidateDetailsSelection);
  const formMethods = useForm({
    mode: 'all',
    defaultValues: setDefaultValues(selectedWorkExperience, disciplines, specialtiesLookup, travelCompanies),
  });
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [cancel, setCancel] = useState<boolean>(false);
  const [countryOptions] = useState(getCountryOpts());
  const [workEndDate, setWorkEndDate] = useState<string>('');
  const { classes } = WorkExperienceModalStyles();
  const [open, setOpen] = useState<boolean>(false);

  const {
    reset,
    handleSubmit,
    setError,
    formState: { dirtyFields, errors, touched },
  } = formMethods;

  useEffect(() => {
    fetchTravel();
  }, []);

  useEffect(() => {
    if (selectedWorkExperience) {
      const defaultValues = setDefaultValues(selectedWorkExperience, disciplines, specialtiesLookup, travelCompanies);
      reset(defaultValues);
      if (selectedWorkExperience?.facilityName === 'Personal Time Off') {
        setIsTravelWorkExp(false);
        setTimeOffStartDate(dateFormatter(selectedWorkExperience?.startedOn));
        setTimeOffEndDate(dateFormatter(selectedWorkExperience?.endedOn));
      } else {
        setWorkStartDate(dateFormatter(selectedWorkExperience?.startedOn));
        setWorkEndDate(dateFormatter(selectedWorkExperience?.endedOn));
        if (selectedWorkExperience?.endedOn !== null) {
          setDifferenceDate(getDateDifference(selectedWorkExperience?.startedOn, selectedWorkExperience?.endedOn));
        }
      }
    }
  }, [selectedWorkExperience]);

  const fetchTravel = async () => {
    setIsLoading(true);
    var mapResult = travelCompanies?.map(x => {
      return {
        id: x.value,
        label: x.name,
      };
    });
    const sortedDisciplines = disciplines ? [...disciplines].sort((a, b) => a.label.localeCompare(b.label)) : [];
    setTravelCompanyData(mapResult);
    setTypeAheadOptions({
      disciplines: sortedDisciplines || [],
      specialties: specialtiesLookup || [],
      reasonForLeaving: reasonForLeavingDropDownOptions,
    });
    setIsLoading(false);
  };

  const handleNavigation = () => {
    onEditOpen(true);
    setOpen(true);
  };
  const handleCancel = () => {
    if (!_.isEmpty(errors) || !_.isEmpty(dirtyFields) || !_.isEmpty(touched)) setCancel(true);
    else yesAction();
  };
  const onError = (errors, e) => {
    dispatch(globalActions.setSnackBar({ message: 'An error has occurred. Please try again', severity: 'error' }));
    trackException({ exception: new Error('Error editing work history'), properties: { errors } });
  };
  const onSubmit = async (data, e) => {
    if (!validateWorkForm(data, setError, isTravelWorkExp)) return;
    var reasonForLeaving = data.pReason?.label;

    setIsLoading(true);
    try {
      const payload = {
        workHistoryId: selectedWorkExperience?.workHistoryId,
        startedOn: moment(data?.pStart).format('yyyy-MM-DDT00:00:00'),
        endedOn: data?.pEnd ? moment(data?.pEnd).format('yyyy-MM-DDT00:00:00') : null,
        workGap: 0,
        previousEndedOn: selectedWorkExperience?.previousEndedOn,
        yearsOfExperience: 0,
        monthsOfExperience: 0,
        discipline: data?.piDiscipline?.object?.Description || selectedWorkExperience?.discipline,
        disciplineAbbr: data?.piDiscipline?.object?.Abbreviation || selectedWorkExperience?.disciplineAbbr,
        specialty: data?.piSpecialty?.label || selectedWorkExperience?.specialty,
        specialtyAbbr: data?.piSpecialty?.label || selectedWorkExperience?.specialtyAbbr,
        position: data?.pPosition,
        approvedReferenceCount: selectedWorkExperience?.approvedReferenceCount,
        facilityName: !isTravelWorkExp ? 'Personal Time Off' : data.pFacilityEmployer || '',
        location: {
          address1: selectedWorkExperience?.location?.address1,
          address2: selectedWorkExperience?.location?.address2,
          city: typeof data?.piCity === 'string' ? data?.piCity : data?.piCity?.label,
          stateProvinceID: typeof data.pState === 'string' ? data?.pState : data.pState?.id,
          state: typeof data?.pState === 'string' ? data?.pState : data?.pState?.label,
          country: data?.pCountry ? data?.pCountry?.label : null,
          zip: data?.piZip || null,
          countryId: data?.pCountry ? data?.pCountry?.id : null,
          latitude: null,
          longitude: null,
        },
        unit: data?.pUnitFloorDept || selectedWorkExperience?.unit || null,
        travelCompany: data?.piTravelerCompany?.label || null,
        staffingAgency: data?.pStaffing ? 'Y' : 'N',
        isTeachingHospital: data?.pHospital ? 'Y' : 'N',
        numberOfBedsInFacility: data?.pBeds ? parseInt(data?.pBeds) : null,
        currentlyEmployed: data?.currentlyWorking ? true : !data?.pEnd ? true : false,
        placementID: selectedWorkExperience?.placementId,
        reasonForLeaving: data?.pAddTimeOff ? data?.pPaidTimeOffReason : reasonForLeaving,
        timeStamp: selectedWorkExperience?.timeStamp,
        ref: null,
        travelCompanyId: data?.piTravelerCompany?.id || null,
        specialtyId: data?.piSpecialty?.object?.SpecialtyID || selectedWorkExperience?.specialtyId || null,
        disciplineId: data?.piDiscipline?.object?.ID || selectedWorkExperience?.disciplineId || null,
        otherDiscipline: '',
        otherSpecialty: '',
        equipmentProcedures: data?.pnotes?.replace(/\r?\n/g, '\r\n') || null,
        isSupervisor: data?.pSupervisor || null,
        isTeletherapy: data?.pTeletherapy || null,
        candidateId: candidateDetails?.travelerId,
        brandId: candidateDetails?.brandId,
        currentEmployeeId: user?.userInfo?.employeeId || 8410,
        currentEmployeeName: user?.userInfo?.firstName + ' ' + user?.userInfo?.lastName,
        hasEMPVReference: selectedWorkExperience?.hasEMPVReference,
        jobTypeID: !isNil(selectedWorkExperience?.jobTypeID)
          ? selectedWorkExperience?.jobTypeID
          : !isTravelWorkExp
          ? JobTypeIDs.TimeOff
          : JobTypeIDs.Travel, //To-Do: Modify this when we have more Job Types availale in UI
      };

      dispatch(workExperienceActions.updateWorkExperience(payload));
    } catch (error) {}
    setIsLoading(false);
    handleClose();
  };

  const yesAction = () => {
    handleClose();
    setCancel(false);
  };
  const noAction = () => {
    setCancel(false);
  };
  const handleClose = () => {
    setOpen(false);
    onEditOpen(false);
  };

  return (
    <>
      <div className={classes.root}>
        <Grid container justifyContent={'space-between'} alignItems={'center'} className={classes.heading}>
          <Grid item pl={1} className={classes.internalHeading}>
            {open ? (
              <span>
                <span>{'Work Experience '}</span>
                <span className={classes.requiredField}>{'*Required Fields'}</span>
              </span>
            ) : (
              <span>{isTravelWorkExp ? 'Work Experience' : 'Time Off'}</span>
            )}
          </Grid>
          <Grid item display={'flex'} alignItems={'center'} ml={4}>
            <Grid item mr={open ? 11 : 4}>
              <Typography>{selectedWorkExperience?.lastUpdatedText}</Typography>
            </Grid>
            {!open && (
              <Grid item mr={2}>
                <CustomTooltip
                  tooltipContent={editModalAccess ? t('Edit') : t('candidate.workExperienceView.permissionDisabled')}
                >
                  <IconButton
                    disabled={!editModalAccess}
                    color="secondary"
                    size="medium"
                    className={classes.editHover}
                    onClick={handleNavigation}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </CustomTooltip>
              </Grid>
            )}
          </Grid>
        </Grid>

        {isLoading ? (
          <CircularProgress className={classes.MuiCircularProgressRoot} />
        ) : (
          <>
            {open ? (
              <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                  <EnhancedEditWorkExperience
                    travelCompanyData={travelCompanyData}
                    isTravelWorkExp={isTravelWorkExp}
                    typeAheadOptions={typeAheadOptions}
                    setSaveDisabled={setSaveDisabled}
                    countriesData={countryOptions}
                    dialogActions={[
                      {
                        onClick: handleCancel,
                        text: 'CANCEL',
                        variant: 'contained',
                        color: 'tertiary',
                      },
                      {
                        text: `${t('candidate.dialogActions.UpdateWorkExperience')}`,
                        variant: 'contained',
                        type: 'submit',
                        disabled: saveDisabled || readOnly,
                        tooltipProps: readOnly
                          ? {
                              tooltipContent: t('global.readOnlyTooltip'),
                            }
                          : undefined,
                      },
                    ]}
                  />
                </form>
              </FormProvider>
            ) : isTravelWorkExp ? (
              <LayoutGrid container direction="column" className={classes.container} spacing={4}>
                <TravelView
                  selectedWorkExperience={selectedWorkExperience}
                  classes={classes}
                  differenceDate={differenceDate}
                  workStartDate={workStartDate}
                  workEndDate={workEndDate}
                />
              </LayoutGrid>
            ) : (
              <LayoutGrid container direction="column" className={classes.container} spacing={4}>
                <TimeOffView
                  selectedWorkExperience={selectedWorkExperience}
                  timeOffStartDate={timeOffStartDate}
                  timeOffEndDate={timeOffEndDate}
                  classes={classes}
                />
              </LayoutGrid>
            )}
          </>
        )}
      </div>
      {cancel && <Cancel openDialog={cancel} handleConfirmAction={yesAction} handleCancelAction={noAction} />}
    </>
  );
};
