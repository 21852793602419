import { createAction } from '@reduxjs/toolkit';
import { ITemplate } from './types';

const onInitAction = createAction('ON_INIT_CONFIRMATION_TEMPLATE_EDITOR');
const getMergeTokensList = createAction('GET_CONFIRMATION_MERGE_TOKENS_LIST');
const getTemplates = createAction('GET_CONFIRMATION_TEMPLATES');
const publishTemplate = createAction<ITemplate>('PUBLISH_CONFIRMATION_TEMPLATE_ACTION');
const saveDraftTemplate = createAction<ITemplate>('SAVE_CONFIRMATION_DRAFT_TEMPLATE_ACTION');
const deleteDraftTemplate = createAction<{
  templateId: number;
}>('DELETE_CONFIRMATION_DRAFT_TEMPLATE_ACTION');

export const serviceActions = {
  onInitAction,
  getMergeTokensList,
  getTemplates,
  saveDraftTemplate,
  publishTemplate,
  deleteDraftTemplate,
};
