import { tooltipType } from 'app/components/Common/Tooltips';
import { toolTipCheck } from 'app/components/Order/OrderDetails/helper';
import { missingField } from 'app/constants';
import { formatToAmericanNotaion } from 'app/helpers/numberHelper';
import { ICustomHeader } from 'app/models/Grid';
import { isEmpty } from '../../../../../../../utils/string/string';

interface IGridColumns extends Omit<ICustomHeader, 'title'> {
  title:
    | 'unitId'
    | 'name'
    | 'orders'
    | 'positionsAvailable'
    | 'skillset'
    | 'yearsOfExperience'
    | 'lastUpdated'
    | 'lastUpdatedBy'
    | 'doNotCallFacility'
    | 'fyreIntegration'
    | 'bestBets'
    | 'voiceAdvantage'
    | 'unitSize'
    | 'costCenter'
    | 'floor'
    | 'status'
    | 'fullSkillset';
}

export const facilityUnitsViewInfo: IGridColumns[] = [
  {
    title: 'unitId',
    value: 'Unit ID',
    clickable: true,
    sortable: true,
  },
  {
    title: 'name',
    value: 'Unit Name',
    tooltip: tooltipType.CustomTooltip,
    tooltipAttributes: 'nameAttributes',
    sortable: true,
  },
  { title: 'orders', value: 'Orders', sortable: true },
  { title: 'positionsAvailable', value: 'Positions', sortable: true },
  {
    title: 'skillset',
    value: 'Skillset',
    tooltip: tooltipType.CustomTooltip,
    tooltipAttributes: 'skillsetAttributes',
    sortable: true,
  },
  {
    title: 'yearsOfExperience',
    value: 'YOE',
    sortable: true,
  },
  {
    title: 'lastUpdated',
    value: 'Last Updated',
    defaultSort: true,
    sortable: true,
    sortByField: 'lastUpdatedSortable',
  },
  {
    title: 'lastUpdatedBy',
    value: 'Last Updated By',
    sortable: true,
  },
  { title: 'fyreIntegration', value: 'FYRE Integration', sortable: true },
  {
    title: 'bestBets',
    value: 'Best bets',
    sortable: true,
  },
  {
    title: 'voiceAdvantage',
    value: 'VA',
    sortable: true,
    columnHeaderHoverView: true,
    columnHeaderHoverText: 'Voice Advantage',
  },
  {
    title: 'unitSize',
    value: 'Unit Size',
    sortable: true,
  },
  {
    title: 'costCenter',
    value: 'Cost Center',
    sortable: true,
  },
  {
    title: 'floor',
    value: 'Floor',
    sortable: true,
  },
];

//Tooltip Fix
const tooltipFix = { display: 'inline-block' };

export const formatFacilityUnits = response =>
  response?.map((unit, index) => {
    const skillSets =
      unit?.experience?.skillSets && !!unit?.experience?.skillSets.length && unit?.experience?.skillSets?.length
        ? unit?.experience?.skillSets
            ?.map(item => {
              const discipline = item.disciplineAbbr ? item.disciplineAbbr : item.discipline;
              const specialty = item.specialtyAbbr
                ? '-' + item.specialtyAbbr
                : item.specialty
                ? '-' + item.specialty
                : '';
              const subSpec = item.subSpecialty ? '-' + item.subSpecialty : '';
              return discipline + specialty + subSpec;
            })
            .join(', ')
        : missingField;

    const fullSkillSets =
      unit?.experience?.skillSets && !!unit?.experience?.skillSets.length && unit?.experience?.skillSets?.length
        ? unit?.experience?.skillSets
            ?.map(item => {
              const discipline = item.discipline;
              const specialty = item.specialty;
              const subSpec = item.subSpecialty ? '-' + item.subSpecialty : '';
              return discipline + '-' + specialty + subSpec;
            })
            .join(', ')
        : missingField;

    const skillSetTemp = unit?.experience?.skillSets?.map(item => ({
      discipline: item.discipline,
      disciplineId: item.disciplineId,
      isRequired: item.isRequired,
      specialty: item.specialty,
      specialtyId: item.specialtyId,
      isPrimary: item.isPrimary,
      subSpecialty: item.subSpecialty,
      subSpecialtyId: item.subSpecialtyId,
      yearsOfExperience: item.yearsOfExperience,
    }));

    const data = {
      id: index,
      name: toolTipCheck(isEmpty(unit?.name), 36),
      facilityId: unit.facilityId,
      unitName: unit?.name ?? missingField,
      nameAttributes: {
        tooltipContent: unit?.name,
        contentStyle: tooltipFix,
        disableTooltip: !unit?.name || (unit?.name?.length || 0) < 36,
      },
      status: unit?.status,
      unitId: `${unit?.unitId || missingField}`,
      costCenter: `${unit?.costCenter || missingField}`,
      floor: `${unit?.floor || missingField}`,
      yearsOfExperience: (unit?.travelExperience?.yearsOfExperience || 0) ?? missingField,
      positionsAvailable: (unit?.positionsAvailable || 0) ?? missingField,
      orders: (unit?.orders || 0) ?? missingField,
      ssn: unit?.ssn,
      dob: unit?.dob,
      lastUpdated: `${new Date(unit?.lastUpdated).toLocaleDateString() || missingField}`,
      lastUpdatedSortable: unit?.lastUpdated || missingField,
      lastUpdatedBy:
        unit?.lastUpdatedBy && unit?.lastUpdatedBy.firstName
          ? `${unit.lastUpdatedBy.firstName} ${unit.lastUpdatedBy.lastName}`
          : missingField,
      fyreIntegration: unit?.fyreIntegration ? 'Yes' : 'No',
      bestBets: `${unit?.bestBets || missingField}`,
      bestBetsId: unit?.bestBetsId,
      unitSize: unit?.unitSize || 0,
      skillset: isEmpty(skillSets) ?? missingField,
      fullSkillSets: fullSkillSets,
      skillsetAttributes: {
        tooltipContent: skillSets,
        contentStyle: tooltipFix,
        disableTooltip: !skillSets || (skillSets.length || 0) < 36,
      },
      voiceAdvantage: unit?.virtualRating ? 'Yes' : 'No',
      reference: {
        isRequired: unit?.reference?.isRequired,
        numberOfReferenceRequired: unit?.reference?.numberOfReferenceRequired,
        numberOfReferenceToRFO: unit?.reference?.numberOfReferenceToRFO,
        withinMonths: unit?.reference?.withinMonths,
        referenceFromSupervisor: unit?.reference?.referenceFromSupervisor,
      },
      float: unit?.float,
      requiredQualifications: unit?.requiredQualifications,
      preferredQualifications: unit?.preferredQualifications,
      travelExperience: {
        isRequired: unit?.travelExperience?.isRequired,
        numberOfAssignments: unit?.travelExperience?.numberOfAssignments,
        yearsOfExperience: unit?.travelExperience?.yearsOfExperience,
        general: unit?.travelExperience?.general,
        inSpecialty: unit?.travelExperience?.inSpecialty,
      },
      virtualRating: unit?.virtualRating,
      skillSets: skillSetTemp,
      externalJobDescription: unit?.externalJobDescription,
      internalNotes: unit?.internalNotes,
      qualification: unit?.qualification,
      additionalRequiredQualifications: unit?.additionalRequiredQualifications,
    };
    return data;
  });

export const formatUnitsData = response =>
  response.map(unit => ({
    name: unit?.name,
    ssn: unit?.ssn,
    dob: unit?.dob,
    status: unit?.status,
    unitId: unit?.unitId,
    costCenter: unit?.costCenter,
    floor: unit?.floor,
    yearsOfExperience: unit?.travelExperience?.yearsOfExperience,
    fyreIntegration: unit?.fyreIntegration,
    bestBets: unit?.bestBets,
    bestBetsId: unit?.bestBetsId,
    unitSize: unit?.unitSize,
    float: unit?.float,
    requiredQualifications: unit?.requiredQualifications,
    preferredQualifications: unit?.preferredQualifications,
    additionalRequiredQualifications: unit?.additionalRequiredQualifications,
    virtualRating: unit?.virtualRating,
    submissionRequirement: unit?.submissionRequirement,
    experience: unit?.experience,
    externalJobDescription: unit?.externalJobDescription,
    internalNotes: unit?.internalNotes,
    facilityId: unit?.facilityId,
    facilityState: '',
    reference: unit?.reference,
    nurseRatio: unit?.nurseRatio,
    rangeOfPatientsSeen: unit?.rangeOfPatientsSeen,
    rangeOfVisits: unit?.rangeOfVisits,
    travelExperience: unit?.travelExperience,
    qualification: unit?.qualification || {
      activeLicense: false,
      compactLicense: false,
      emr: null,
      certifications: [],
      skillChecklists: [],
    },
    orderPriorities: unit?.orderPriorities,
    flexibleSchedule: unit?.flexibleSchedule,
    nonStandardRequirement: unit?.nonStandardRequirement,
    currentUserId: 0,
    standardizedRequirements: unit?.standardizedRequirements,
    statusId: 0,
    lastUpdated: unit?.lastUpdated,
    lastUpdatedBy: unit?.lastUpdatedBy,
    unitContacts: unit?.unitContacts,
    createdByVersion: unit?.createdByVersion,
    notifyCandidate: unit?.notifyCandidate,
    patientsSeen: unit?.patientsSeen,
    numberOfVisits: unit?.numberOfVisits,
    productivityPercentage: unit?.productivityPercentage,
    withinTheLastDays: unit?.withinTheLastDays,
    withinTheLastYears: unit?.withinTheLastYears,
    entireWorkHistory: unit?.entireWorkHistory,
  }));

export const getPositionsCount = data => {
  return data && !!data.length ? data.map(item => item.positionsAvailable).reduce((prev, next) => prev + next) : 0;
};

export const getOrdersCount = data => {
  return data && !!data.length ? data.map(item => item.orders).reduce((prev, next) => prev + next) : 0;
};

export const formatPositionsTitle = count => ` / Positions (${formatToAmericanNotaion(count)})`;

export const formatOrdersTitle = count => ` / Orders (${formatToAmericanNotaion(count)})`;
