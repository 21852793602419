import { coverageAvatarColors } from './coverageAvatarColors';

export const getFinalInboxDetails = (coverageResponse, userInfo) => {
  const sortedArr = coverageResponse?.sort((a, b) => a.firstName.localeCompare(b.firstName));
  let totalInboxes = sortedArr;
  if (userInfo?.phoneNumber) {
    totalInboxes = [userInfo, ...sortedArr];
  }
  const finalInbox = totalInboxes?.map((item, index) => {
    return {
      ...item,
      avatarColor: coverageAvatarColors[index % 5],
    };
  });
  return finalInbox;
};
