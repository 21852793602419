import { FilterCompTypes } from 'app/ComponentLibrary/Filter/utils';
import { statusOpts } from '../../UnitTab/Filter/UnitFilterConfig';

export enum amClientContactsFilterName {
  ContactPreference = 'contactPreference',
  Name = 'name',
  Title = 'title',
  Status = 'status',
  Units = 'units',
  Actions = 'actions',
}

export const defaultFilterValues = {
  [amClientContactsFilterName.ContactPreference]: [],
  [amClientContactsFilterName.Name]: [],
  [amClientContactsFilterName.Title]: [],
  [amClientContactsFilterName.Status]: statusOpts[0],
  [amClientContactsFilterName.Units]: [],
  [amClientContactsFilterName.Actions]: [],
};

export const getFilterSpecs = (filterSpecs, t, styleClasses) => [
  {
    filterName: t('Contact Preference'),
    name: amClientContactsFilterName.ContactPreference,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.contactPreferenceOpts || [],
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.contactPreferenceField,
  },
  {
    filterName: t('Contact Status'),
    name: amClientContactsFilterName.Status,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    options: statusOpts,
  },
  {
    filterName: t('Units'),
    name: amClientContactsFilterName.Units,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.unitNameOpts || [],
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    returnsObjectAsValue: true,
    selectFieldStyle: styleClasses.unitsField,
  },
  {
    filterName: t('Actions'),
    name: amClientContactsFilterName.Actions,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.actionsOpts || [],
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    returnsObjectAsValue: true,
    selectFieldStyle: styleClasses.actionsField,
  },
  {
    filterName: t('Name'),
    name: amClientContactsFilterName.Name,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.nameOpts || [],
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.firstNameField,
  },
  {
    filterName: t('Title'),
    name: amClientContactsFilterName.Title,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.titleOpts || [],
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    returnsObjectAsValue: true,
    selectFieldStyle: styleClasses.titleField,
  },
];
