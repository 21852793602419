import React from 'react';
import NoInboxMessage from 'app/assets/images/NoInboxMessage.svg';
import { Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()(theme =>
({
  root: {
    height: '350px',
    padding: '40px',
    paddingTop: '80px',
  },
  image: {
    margin: '0 40px',
    padding: '0 40px',
  },
  textBox: {
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: '13px',
  },
}));

export const NoFilterResult = props => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.image}>
        <img src={NoInboxMessage} alt="No Filter Resultt" />
      </div>
      <div>
        <Typography className={classes.textBox}>{`No unread messages`}</Typography>
      </div>
    </div>
  );
};
