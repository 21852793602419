import moment from 'moment';
import { IGetRequestTimeOff } from './IRequest';
import { convertToFormat } from 'app/helpers/dateHelper';

/**
 * Method to get number of days between start date and end date
 * @param startDate
 * @param endDate
 */
function getNumberOfDays(startDate: Date, endDate: Date) {
  const diffInDays = moment(endDate).diff(moment(startDate), 'day', false);
  return diffInDays + 1;
}

/**
 * Method to convert date to mm/dd/yyyy format
 */
const formatDate = (date: string) => {
  return moment(date).format('MM/DD/YYYY');
};

/**
 *  Method to format requested time off
 * @param requestTimeOffList
 */
const formatRequestedTimeOffList = (requestTimeOffList: IGetRequestTimeOff[]) => {
  return requestTimeOffList.map((requestItem, index: number) => {
    const noOfDays = getNumberOfDays(new Date(requestItem.startDate), new Date(requestItem.endDate));
    return {
      id: index,
      key: `${formatDate(requestItem.startDate)} - ${formatDate(requestItem.endDate)} (${noOfDays} days)`,
      label: `${formatDate(requestItem.startDate)} - ${formatDate(requestItem.endDate)}`,
      startDate: new Date(formatDate(requestItem.startDate)),
      endDate: new Date(formatDate(requestItem.endDate)),
    };
  });
};

/**
 *
 * @param startDate - existing/saved start date
 * @param endDate - existing/saved end date
 * @param newStartDate - new date picker selected start date
 * @param newEndDate - new date picker selected end date
 */
const findDateRangeAlreadyExists = (
  startDate: Date | string,
  endDate: Date | string,
  newStartDate: Date,
  newEndDate: Date,
) => {
  const s = moment(convertToFormat(startDate, 'YYYY-MM-DD'));
  const e = moment(convertToFormat(endDate || '1900-01-01', 'YYYY-MM-DD'));
  const newS = moment(convertToFormat(newStartDate, 'YYYY-MM-DD'));
  const newE = moment(convertToFormat(newEndDate || '1900-01-01', 'YYYY-MM-DD'));
  if (s.isSame(newS, 'D')) return true;
  else if (newS.isBetween(s, e, null, '[]')) {
    return true;
  } else if (newE.isBetween(s, e, null, '[]')) {
    return true;
  } else if (s.isBetween(newS, newE, null, '[]')) {
    return true;
  } else if (e.isBetween(newS, newE, null, '[]')) {
    return true;
  }
  return false;
};

export { formatRequestedTimeOffList, findDateRangeAlreadyExists, getNumberOfDays };
