import { CircularProgress, Grid, Theme } from 'amn-ui-core';
import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { GenericDialog } from '../../../../Alerts/GenericDialog';
import { useReadOnly } from '../../../../../../oidc/userRoles';
import { useDispatch, useSelector } from 'react-redux';
import statesData from 'app/assets/jsons/State.json';
import { candidateDetailsSelection } from '../../Profile/CandidateDetails.selector';
import { Cancel } from 'app/components/Common/CancelModal/Cancel';
import _ from 'lodash';
import { ReferenceFormFields } from './ReferenceFormFields';
import { missingField } from 'app/constants';
import moment from 'moment';
import { selectUser } from 'oidc/user.selectors';
import { IReferenceData } from 'app/models/Candidate/CandidateProfile';
import { candidateDetailActions } from '../../Profile/CandidateDetails.redux';
import bestTimesToReach from 'app/assets/jsons/BestTimeToReach.json';
import referenceTypes from 'app/assets/jsons/ReferenceTypes.json';
import callPreferences from 'app/assets/jsons/ContactPreference.json';
import contactTypes from 'app/assets/jsons/PhoneTypes.json';
import { DropDownItem } from 'app/models/DropDown';
import { usePromiseTracker } from 'react-promise-tracker';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContainer: {
    minWidth: '600px',
  },
  dialogContent: {
    padding: 0,
    overflow: 'hidden',
    'overflow-y': 'scroll',
  },
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50vh',
  },
}));

enum contactTypeIds {
  CELL_PHONE = 6,
  HOME = 5,
  UNIT = 2,
  MAIN_FACILITY = 9,
  OFFICE = 1,
}

const defaultValues = {
  pWorkExperience: null,
  pSupervisorName: '',
  pTitle: '',
  pReferenceType: '',
  pReferenceDate: null,
  pComments: '',
  pEmail: '',
  pPhoneType1: '',
  pPhoneNumber1: '',
  pPhoneType2: '',
  pPhoneNumber2: '',
  pCallPreference: '',
  pBestTimeToReach: '',
};

export const AddReferenceModal = props => {
  const { open, handleClose } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const { promiseInProgress: postProfileSummaryDetails } = usePromiseTracker({
    area: 'profile-summary-details',
    delay: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<boolean>(false);
  const formMethods = useForm({ mode: 'all', defaultValues: defaultValues });
  const [cancel, setCancel] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [workExperienceOptions, setWorkExperienceOptions] = useState<DropDownItem[]>([]);
  const [referenceTypeOptions, setReferenceTypeOptions] = useState<DropDownItem[]>(
    referenceTypes.sort((a, b) => (b.Description > a.Description ? -1 : 1)),
  );
  const [phoneTypeOptions, setPhoneTypeOptions] = useState<DropDownItem[]>(
    contactTypes.sort((a, b) => (b.Description > a.Description ? -1 : 1)),
  );
  const [callPreferenceOptions, setCallPreferenceOptions] = useState<DropDownItem[]>(
    callPreferences.sort((a, b) => (b.Description > a.Description ? -1 : 1)),
  );
  const [bestTimeToReachOptions, setBestTimeToReachOptions] = useState<DropDownItem[]>(bestTimesToReach);
  const { readOnly } = useReadOnly();
  const {
    handleSubmit,
    formState: { dirtyFields, errors, touched },
  } = formMethods;

  useEffect(() => {
    const options = [];
    const sortedExperience = _.cloneDeep(candidateDetails?.experience?.employment).sort((a, b) =>
      moment(b.endedOn).isBefore(a.endedOn) ? -1 : 1,
    );
    sortedExperience.map(item => {
      if (item.facilityName !== 'Personal Time Off') {
        const facility = item.facilityName ? `${item.facilityName.trim()}, ` : '';
        const city = item.location.city ? `${item.location.city}, ` : '';
        const state = item.location.state
          ? item.location.country === 'USA'
            ? `${statesData.states.find(x => x.ID === item.location.state)?.Description}, `
            : `${item.location.state}, `
          : '';
        const startDate = item.startedOn ? moment(item.startedOn).format('MM/DD/YYYY') : null;
        const dateRange = startDate
          ? item.endedOn
            ? `${startDate} - ${moment(item.endedOn).format('MM/DD/YYYY')}, `
            : `${startDate}, `
          : '';
        const discipline = item.disciplineAbbr
          ? item.specialtyAbbr
            ? `${item.disciplineAbbr} - ${item.specialtyAbbr}`
            : `${item.disciplineAbbr}`
          : '';
        const formattedText = facility + city + state + dateRange + discipline;
        options.push({
          ID: item.workHistoryId,
          Description: formattedText,
        });
      }
    });
    const filteredContactTypes = contactTypes.filter(item => Object.values(contactTypeIds).includes(item.ID));

    setWorkExperienceOptions(options);
    setReferenceTypeOptions([{ ID: 0, Description: null }, ...referenceTypes]);
    setCallPreferenceOptions([{ ID: 0, Description: null }, ...callPreferences]);
    setBestTimeToReachOptions([{ ID: 0, Description: null }, ...bestTimesToReach]);
    setPhoneTypeOptions([{ ID: 0, Description: null }, ...filteredContactTypes]);
  }, []);

  useEffect(() => {
    if (postProfileSummaryDetails) {
      setIsLoading(false);
      handleClose();
    }
  }, [postProfileSummaryDetails]);

  const onSubmit = async data => {
    const workHistoryID = workExperienceOptions?.find(item => item.Description === data.pWorkExperience).ID;
    const experience = candidateDetails?.experience?.employment.find(item => item.workHistoryId === workHistoryID);
    const phoneNumbers = [];
    if (data.pPhoneNumber1) {
      phoneNumbers.push({
        phoneTypeDescription: data.pPhoneType1 || null,
        phoneTypeID: phoneTypeOptions.find(item => item.Description === data.pPhoneType1)?.ID || null,
        phoneNumber: data.pPhoneNumber1.replace('+1 ', ''),
        timestamp: null,
      });
    }
    if (data.pPhoneNumber2) {
      phoneNumbers.push({
        phoneTypeDescription: data.pPhoneType2 || null,
        phoneTypeID: phoneTypeOptions.find(item => item.Description === data.pPhoneType2)?.ID || null,
        phoneNumber: data.pPhoneNumber2.replace('+1 ', ''),
        timestamp: null,
      });
    }
    if (!data.pEmail && data.pEmail?.length == 0 && phoneNumbers?.length == 0) {
      setAlertMessage(true);
      return;
    }
    setIsLoading(true);
    const payload: IReferenceData = {
      candidateId: candidateDetails?.travelerId,
      travelerReferenceID: null,
      workHistoryID: workHistoryID,
      facilityName: experience.facilityName,
      startDate: moment(experience?.startedOn).format('yyyy-MM-DDT00:00:00'),
      endDate: experience?.endedOn,
      city: experience?.location.city,
      state: experience?.location.state,
      supervisorName: data.pSupervisorName,
      title: data.pTitle,
      referenceTypeID: referenceTypes.find(item => item.Description === data.pReferenceType)?.ID || null,
      referenceType: data.pReferenceType,
      referenceDate: data.pReferenceDate ? moment(data.pReferenceDate).format('yyyy-MM-DD') : null,
      comments: data.pComments.replace(/\r?\n/g, '\r\n'),
      email: data.pEmail,
      contactPreferenceTypeID: callPreferences.find(item => item.Description === data.pCallPreference)?.ID || null,
      contactPreferenceType: data.pCallPreference,
      bestTimeToReachID: bestTimesToReach.find(item => item.Description === data.pBestTimeToReach)?.ID || null,
      bestTimeToReach: data.pBestTimeToReach,
      currentEmployeeId: user.userInfo?.employeeId || 8410,
      currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
      phoneNumbers: phoneNumbers,
    };
    dispatch(candidateDetailActions.postCandidateReference({ requestBody: payload }));
  };

  const onError = data => {
    setSaveDisabled(true);
  };

  const handleCancel = () => {
    if (!_.isEmpty(errors) || !_.isEmpty(dirtyFields) || !_.isEmpty(touched)) setCancel(true);
    else yesAction();
  };
  const yesAction = () => {
    handleClose?.();
    setCancel(false);
  };
  const noAction = () => {
    setCancel(false);
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          {isLoading ? (
            <CircularProgress className={classes.MuiCircularProgressRoot} />
          ) : (
            <>
              <GenericDialog
                variant={'blue'}
                classes={{ paper: classes.dialogContainer }}
                open={open}
                disablePortal
                disableEscapeKeyDown
                dialogTitleProps={{
                  text: t('candidate.dialogActions.AddReferenceTitle'),
                  closeButton: true,
                  expandable: true,
                }}
                dialogActions={[
                  {
                    onClick: handleCancel,
                    text: 'CANCEL',
                    variant: 'contained',
                    color: 'tertiary',
                  },
                  {
                    text: t('candidate.dialogActions.Add'),
                    variant: 'contained',
                    type: 'submit',
                    disabled: saveDisabled || readOnly,
                    tooltipProps: readOnly
                      ? {
                          tooltipContent: t('global.readOnlyTooltip'),
                        }
                      : undefined,
                  },
                ]}
                dialogContentProps={{
                  classes: { root: classes.dialogContent },
                }}
                onClose={handleCancel}
              >
                <Grid spacing={3}>
                  <Grid item xs={12}>
                    <ReferenceFormFields
                      workExperienceOptions={workExperienceOptions}
                      phoneTypeOptions={phoneTypeOptions}
                      callPreferenceOptions={callPreferenceOptions}
                      bestTimeToReachOptions={bestTimeToReachOptions}
                      referenceTypeOptions={referenceTypeOptions}
                      setSaveDisabled={setSaveDisabled}
                      displayAlert={alertMessage}
                    />
                  </Grid>
                </Grid>
              </GenericDialog>
              {cancel && <Cancel openDialog={cancel} handleConfirmAction={yesAction} handleCancelAction={noAction} />}
            </>
          )}
        </form>
      </FormProvider>
    </>
  );
};
