import { Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { selectUser } from 'oidc/user.selectors';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { SellingNotesDialog } from './SellingNotesDialog';
import { SellingNotesGenerator } from './SellingNotesGenerator';
import { SellingNotesPreview } from './SellingNotesPreview';
import { candidateDetailsSelection } from '../../../../Profile/CandidateDetails.selector';
import { candidateDetailActions } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { usePromiseTracker } from 'react-promise-tracker';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: '#ffff',
    margin: 0,
    padding: 5,
    overflow: 'hidden',
    height: '100%',
  },
  sellingNotes: {
    backgroundColor: '#f5f5f5',
    height: '100%',
  },
}));

export const SellingNotesWrapper = props => {
  const { open, handleClose } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const [preview, setPreview] = useState<any>(null);
  const [isGenerateBtnClicked, setGenerateButtonState] = useState<boolean>();
  const onError = () => {};
  const { promiseInProgress: onSellingNotesTemplateSave } = usePromiseTracker({
    area: 'save-selling-notes-template',
    delay: 0,
  });

  const defaultValues = {
    licensures: null,
    licensuresCheckbox: true,
    certifications: null,
    certificationsCheckbox: true,
    skillSetExperience: null,
    skillSetExperienceCheckbox: true,
    strongAreas: null,
    strongAreasCheckbox: true,
    timeOffRequests: '',
    timeOffRequrestsCheckbox: true,
    references: '',
    referencesCheckbox: true,
    bestContactNumber: '',
    bestContactNumberCheckbox: true,
    preferedTimeToReach: '',
    preferredTimeToReachCheckbox: true,
    travelExperience: '',
    travelExperienceCheckbox: true,
    covidStatus: '',
    covidStatusCheckbox: true,
    additionalHighlights: '',
    additionalHighlightsCheckbox: true,
    preview: '',
    isGenerateBtnDisabled: true,
  };

  const formMethods = useForm({ mode: 'all', defaultValues: defaultValues });
  const { handleSubmit, getValues } = formMethods;

  const setPreviewData = items => {
    setPreview(items);
  };

  const setGenerateButton = (state: boolean) => {
    setGenerateButtonState(state);
  };

  const onSubmit = async data => {
    const postBody = {
      candidateId: candidateDetails.travelerId,
      brandId: candidateDetails.brandId,
      currentEmployeeId: user.userInfo?.employeeId || 8410,
      currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
      sellingnotes: getValues('preview'),
      candidateDetails: candidateDetails,
    };
    dispatch(candidateDetailActions.postSellingNotesTemplate(postBody));
  };

  useEffect(() => {
    if (onSellingNotesTemplateSave) {
      handleClose();
    }
  }, [handleClose, onSellingNotesTemplateSave]);

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <SellingNotesDialog open={open} handleClose={handleClose}>
            <LayoutGrid container className={classes.root}>
              <LayoutGridItem item xs={12} md={6}>
                <LayoutGrid container direction="row">
                  <SellingNotesGenerator setPreviewData={setPreviewData} setGenerateButtonState={setGenerateButton} />
                </LayoutGrid>
              </LayoutGridItem>
              <LayoutGridItem item xs={12} md={6} className={classes.sellingNotes}>
                <LayoutGrid container direction="row">
                  <SellingNotesPreview
                    preview={preview}
                    isGenerateBtnClicked={isGenerateBtnClicked}
                    setGenerateButtonState={setGenerateButton}
                  />
                </LayoutGrid>
              </LayoutGridItem>
            </LayoutGrid>
          </SellingNotesDialog>
        </form>
      </FormProvider>
    </>
  );
};
