/* eslint-disable i18next/no-literal-string */
import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import React from 'react';
import { IGraphicStepper } from './GraphicStepper';

const useStyles = makeStyles<{ height: number }>()((theme, { height }) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    borderRadius: '4px',
    border: '1px solid rgba(224, 224, 224, 1)',
    backgroundColor: 'rgba(224, 224, 224, 1)',
  },
  header: {
    padding: '7px 0px',
    fontSize: '14px',
    marginLeft: '-5%',
    maxHeight: '35px',
    color: '#222222',
    textAlign: 'center',
    maxWidth: '100%',
  },
  headerValue: {
    textAlign: 'center',
    color: '#222222',
    fontSize: '14px',
    fontWeight: 500,
  },
  dataValue: {
    fontSize: '12px',
    '@media (min-width: 1280px)': {
      fontSize: '14px',
    },
  },
  subCategoryParent: {
    // backgroundColor: 'red',// 'rgba(224, 224, 224, 1)',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    // -------------START small container----------------
    '& [data-id]': {
      width: '100%',
    },
    '& [data-id="0"]': {
      width: '100%',
      backgroundColor: 'rgba(224, 224, 224, 1)',
    },
    '& [data-id="0"][data-parent-id="1"]': {
      backgroundColor: 'rgba(224, 224, 224, 1)',
    },
    '& [data-id="1"]': {
      marginLeft: '-8% !important',
      backgroundColor: 'rgba(224, 224, 224, 1)',
      paddingLeft: '3%',
    },
    '& [data-id="2"]': {
      marginLeft: '-8% !important',
      marginRight: '-8% !important',
      backgroundColor: 'rgba(224, 224, 224, 1)',
      paddingLeft: '3%',
    },
    '& [data-id="3"]': {
      backgroundColor: 'rgba(224, 224, 224, 1)',
      paddingLeft: '3%',
    },
    // --------------END small container-----------------
  },
  subCategoryFirst: {
    width: '100%',
    height: `${height}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subCategory: {
    width: '100%',
    height: `${height}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subCategoryLast: {
    width: '100% !important',
    height: `${height}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sectionFirst: {
    clipPath: 'polygon(72% 0, 100% 65.5%, 86.5% 100%, 0% 100%, 0 0)',
    // cursor: 'pointer',
    borderTopLeftRadius: '4px',
  },
  sectionMiddle: {
    clipPath: 'polygon(0 0, 73% 0, 100% 65.5%, 87% 100%, 14% 100%, 27% 65.5%)',
    // cursor: 'pointer',
  },
  sectionEnd: {
    clipPath: 'polygon(0px 0px, 100% 0px, 100% 100%, 20% 100%, 38% 65.5%)',
    // cursor: 'pointer',
  },
  sectionTopLeftRadius: {
    borderTopLeftRadius: '4px',
    overflow: 'hidden',
  },
  sectionTopRightRadius: {
    borderTopRightRadius: '4px',
    overflow: 'hidden',
  },
  sectionBottomRadius: {
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
  tooltipPlacementBottom: {
    margin: 0,
    marginTop: 22,
  },
  content: {
    color: '#222222',
    fontWeight: 'bold',
    fontSize: '18px',
  },
}));

export interface IProps {
  items: IGraphicStepper[];
  handleItemClick: (category, subcategory, value?: any, event?) => void;
  selected: { category: number; subcategory: number | null }[];
  noValueText?: string;
  height?: number;
}

export const GraphicStepperSingle = ({ items, handleItemClick, selected, noValueText, height = 70 }: IProps) => {
  const { classes } = useStyles({ height: height });

  const backgroundColor = (index, length) =>
    index === 0 ? '#FFF' : index === length - 2 ? '#F8F7F7' : index === length - 1 ? '#F0EDED' : '#FEFDFD';

  return (
    <Grid item style={{ width: '100%' }}>
      <Grid container wrap="nowrap" className={classes.container}>
        {items.map((item, a) => {
          const hasSelection = selected && selected.length > 0;
          const isCategorySelected = selected?.find(i => i.category === a) !== undefined;

          return (
            <Grid
              item
              className={
                a === 0 ? classes.sectionTopLeftRadius : a === items.length - 1 ? classes.sectionTopRightRadius : ''
              }
              style={{
                // HACK: Calculating section with and margins
                width: `${a === 0 ? 34 : a === 1 ? 35 : a === 2 ? 35 : 25}%`,
                marginRight: `${a !== items.length - 1 ? -9.1 : 0}%`,
              }}
            >
              {/* ------------------------ */}
              <Grid
                container
                wrap="nowrap"
                direction="column"
                className={
                  a === 0 ? classes.sectionFirst : a === items.length - 1 ? classes.sectionEnd : classes.sectionMiddle
                }
              >
                <Grid
                  item
                  className={classes.header}
                  onClick={e => {
                    !item.category.showTooltip && handleItemClick(a, null, item.category.value, e);
                  }}
                  style={{
                    backgroundColor:
                      !hasSelection || (hasSelection && isCategorySelected) ? item.category.color : '#C0C0C0',
                    marginLeft: a !== items.length - 1 ? '-7%' : '0%',
                  }}
                >
                  {/* HEAD */}
                  {item.category.showTooltip && (
                    <CustomTooltip standardMargin tooltipContent={noValueText}>
                      <div className={classes.headerValue}>{item.category.tag}</div>
                    </CustomTooltip>
                  )}
                  {!item.category.showTooltip && <div className={classes.headerValue}>{item.category.tag}</div>}
                </Grid>
                <Grid item style={{ marginLeft: a > 0 ? '4%' : '0%', maxWidth: '100%' }}>
                  {/* ++++++++++++++++++++++++ */}
                  <Grid
                    container
                    wrap="nowrap"
                    className={`${classes.subCategoryParent} ${
                      a === 0 || a === items.length - 1 ? classes.sectionBottomRadius : ''
                    }`}
                  >
                    {item.subcategory.map((subCategoryItem, subCategoryIndex) => {
                      // If something needs to be selectable in the future
                      // const isSelected =
                      //   selected?.find(i => i.category === a && i.subcategory === subCategoryIndex) !== undefined;

                      const content = (
                        <Grid
                          container
                          spacing={1}
                          direction="column"
                          alignContent="center"
                          style={{ textAlign: 'center' }}
                        >
                          <Grid item className={classes.content}>
                            <>
                              {subCategoryItem.icon ? subCategoryItem.icon : <></>}
                              {subCategoryItem.data}
                            </>
                          </Grid>
                        </Grid>
                      );

                      return (
                        <Grid
                          data-last={a === items.length}
                          data-parent-large={item.subcategory.length > 4}
                          data-parent-id={a}
                          data-id={subCategoryIndex}
                          item
                          onClick={e => {
                            subCategoryItem.data && handleItemClick(a, subCategoryIndex, subCategoryItem.value, e);
                          }}
                          className={`${
                            a === items.length - 1
                              ? classes.subCategoryLast
                              : subCategoryIndex === 0
                              ? classes.subCategoryFirst
                              : classes.subCategory
                          } ${a === 0 || a === items.length - 1 ? classes.sectionBottomRadius : ''}`}
                          style={{
                            backgroundColor: backgroundColor(a, items.length),
                          }}
                        >
                          <div style={{ marginLeft: `${a === items.length - 1 ? 25 : 0}px` }}>
                            {subCategoryItem.data ? (
                              subCategoryItem.showTooltip ? (
                                <CustomTooltip tooltipContent={subCategoryItem.tooltipContent} isInlineContent={true}>
                                  <div className={classes.dataValue}>{content}</div>
                                </CustomTooltip>
                              ) : (
                                <div className={classes.dataValue}>{content}</div>
                              )
                            ) : (
                              <CustomTooltip
                                tooltipContent={noValueText}
                                tooltipPlacementBottom={classes.tooltipPlacementBottom}
                              >
                                <div className={classes.dataValue}>{content}</div>
                              </CustomTooltip>
                            )}
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                  {/* ++++++++++++++++++++++++ */}
                </Grid>
              </Grid>
              {/* ------------------------ */}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
