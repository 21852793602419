import React from 'react';
import { useSelector } from 'react-redux';
import { useEditableRequirementExtensionTimeOff } from './EditableRequirements/TimeOffRequirement';
import { selectEditableRequirementTypes } from '../../../../../../../../store/redux-store/placement-summary/selectors';
import { Requirements } from 'app/models/Placement/PlacementRequirements';

const useExtensionDetailsRequirementCounts = (timeOffRequestReason, extensionNumber) => {
  useEditableRequirementExtensionTimeOff(timeOffRequestReason, extensionNumber);
  const { extensions } = useSelector(selectEditableRequirementTypes);

  const currentExtension = React.useMemo(() => ({ ...extensions[extensionNumber] }), [extensions]);

  const extensionTimeOffRequirementCounts = React.useMemo(() => {
    const counts = new Requirements();
    counts.add(currentExtension[extensionNumber]?.extensionTimeOff?.requirement);
    return counts;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentExtension]);

  return extensionTimeOffRequirementCounts;
};

export const useExtensionRequirementCounts = (timeOffRequestReason, extensionNumber) => {
  const extensionDetailsCounts = useExtensionDetailsRequirementCounts(timeOffRequestReason, extensionNumber);
  return extensionDetailsCounts;
};
