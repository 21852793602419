import React from 'react';
import { ErrorAction } from '../../../../../../Common/ErrorPage/ErrorAction';
import {
  TableCell as RequirementsCell,
  Table,
  RowTitle,
  Loading,
  ColumnHeader,
} from '../../CommonTableComponents/CommonTableComponents';

interface RequirementsTableProps {
  requirementTitle: string;
  candidateName: string;
  id: string;
  [key: string]: any;
}

export const RequirementsTable = ({ requirementTitle, candidateName, id, ...props }: RequirementsTableProps) => {
  return (
    <Table id={id}>
      <thead style={{ width: '100%' }}>
        <RequirementsRow
          id={`${id}-row`}
          style={{ backgroundColor: '#fcfcfd' }}
          title={''}
          requirementCell={<ColumnHeader>{requirementTitle}</ColumnHeader>}
          candidateCell={<ColumnHeader>{candidateName}</ColumnHeader>}
        />
      </thead>
      <tbody>{props.children}</tbody>
    </Table>
  );
};

interface RequirementsRowProps {
  id: string;
  title: string;
  requirementCell: string | JSX.Element;
  candidateCell: string | JSX.Element;
  candidateCellRowSpan?: number;
  loading?: boolean;
  displayProp?: string;
  [key: string]: any;
}
export const RequirementsRow = ({
  id,
  title,
  requirementCell,
  candidateCell,
  candidateCellRowSpan,
  loading,
  transparentTop = false,
  transparentBottom = false,
  hasError = false,
  onTryAgain,
  display = true,
  displayProp= undefined,
  ...props
}: RequirementsRowProps) => {
  const cellStyle = {
    borderTop: transparentTop ? 'transparent' : undefined,
    borderBottom: transparentBottom ? 'transparent' : undefined,
    paddingTop: transparentTop ? 0 : undefined,
    paddingBottom: transparentBottom ? 0 : undefined,
  };

  return (
    <tr id={id} style={{ display: display ? displayProp : 'none' }} {...props}>
      <RequirementsCell style={{ width: '20%', ...cellStyle }}>
        <RowTitle variant="body1">{title}</RowTitle>
      </RequirementsCell>
      {hasError && !loading ? (
        <RequirementsCell colSpan="2">
          <ErrorAction onTryAgain={onTryAgain} />
        </RequirementsCell>
      ) : (
        <>
          <RequirementsCell style={{ width: '40%', ...cellStyle }}>
            {loading ? <Loading /> : requirementCell}
          </RequirementsCell>
          <RequirementsCell rowSpan={candidateCellRowSpan} style={{ width: '40%', ...cellStyle }}>
            {loading ? <Loading /> : candidateCell}
          </RequirementsCell>
        </>
      )}
    </tr>
  );
};
