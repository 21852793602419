import { createSelector } from '@reduxjs/toolkit';
import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => state?.coverage || initialState;

export const selectRequestedCoverage = createSelector([selectDomain], coverage => coverage.requestedCoverage);

export const selectActiveCoverage = createSelector([selectDomain], coverage => coverage.activeCoverage);

export const selectRequestsGridData = createSelector([selectDomain], coverage => coverage.coverageRequestsGridData);

export const selectCoverageDeskChips = createSelector([selectDomain], coverage => coverage.chips);

export const selectCoverageCreatedId = createSelector([selectDomain], coverage => coverage.newCreatedCoverageId);

export const selectCoverageUpdatedId = createSelector([selectDomain], coverage => coverage.updatedCoverageId);

export const selectDenyCoverageId = createSelector([selectDomain], coverage => coverage.denyCoverage);

export const selectCoveredEmails = createSelector([selectDomain], coverage => coverage?.emailCoverage);

export const selectCoveredInbox = createSelector([selectDomain], coverage=> coverage.inboxCoverage);