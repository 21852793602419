import React, { useEffect, useState } from 'react';
import { UnitCard } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/CustomComponents/UnitCard';
import { useTranslation } from 'react-i18next';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { isAuthorizedForAddEdit, useSelectedUnitDetails, useUnitStatusInActive } from './unitHelper';
import { Box, Grid, Skeleton, Typography } from 'amn-ui-core';
import { useUnitDetailStyles } from './UnitDetails';
import { cloneDeep } from 'lodash';
import { missingField } from 'app/constants';
import { makeStyles } from 'tss-react/mui';
import { selectUser } from 'oidc/user.selectors';
import { useSelector } from 'react-redux';
import { usePromiseTracker } from 'react-promise-tracker';

const useVirtualInterviewStyles = makeStyles()(theme => ({
  container: {
    width: '85px',
  },
}));

const VirtualInterviewRating = () => {
  const { t } = useTranslation();
  const { classes: virtualClasses } = useVirtualInterviewStyles();
  const [virtualInterviewRatingCount, setVirtualInterviewRatingCount] = useState<number>(0);
  const selectedUnitDetail = useSelectedUnitDetails();
  const userData = useSelector(selectUser);
  const isAuthorized = isAuthorizedForAddEdit(userData);
  const isUnitInActive = useUnitStatusInActive();
  const virtualOptions = selectedUnitDetail?.virtualRating;
  const submissionRequirement = selectedUnitDetail?.submissionRequirement;
  const { classes } = useUnitDetailStyles();
  let firstHalf = [];
  let secondHalf = [];
  if (virtualOptions?.length > 5) {
    const clonedArray = cloneDeep(virtualOptions);
    firstHalf = clonedArray.splice(0, 5);
    secondHalf = clonedArray;
  } else {
    firstHalf = virtualOptions;
  }
  useEffect(() => {
    setVirtualInterviewRatingCount(virtualOptions?.length ? virtualOptions.length : 0);
  }, [virtualOptions]);

  const { promiseInProgress: isFacilityWithFilterDetailsLoading } = usePromiseTracker({
    area: 'get-facility-details-with-filters',
    delay: 0,
  });

  return (
    <UnitCard
      title={t('facility.units.virtualInterviewRating.title', { count: virtualInterviewRatingCount })}
      rightIcon={true}
      showEditIcon={isAuthorized && !isUnitInActive}
      skeletonWidth={186}
      isDataLoading={isFacilityWithFilterDetailsLoading}
    >
      {isFacilityWithFilterDetailsLoading ? (
        <LayoutGrid p="16px 12px">
          <LayoutGridItem container>
            <Grid display={'flex'} flexDirection={'row'} gap={2}>
              <Skeleton variant="rectangular" width={131} height={13} />
              <Skeleton variant="rectangular" width={37} height={13} />
            </Grid>
          </LayoutGridItem>
          <LayoutGridItem container>
            <Grid display={'flex'} flexDirection={'row'} gap={2}>
              <Skeleton variant="rectangular" width={63} height={13} />
              <Skeleton variant="rectangular" width={37} height={13} />
            </Grid>
          </LayoutGridItem>
        </LayoutGrid>
      ) : (
        <LayoutGrid p="16px 12px">
          <LayoutGridItem container alignItems={virtualOptions?.length ? 'normal' : 'center'}>
            <Typography className={`${classes.textBold} ${virtualClasses.container}`} pr="12px" component="span">
              {t('facility.units.unitDetails.additionalInformation.submissionRequirement')}
            </Typography>
            <Box component="span">
              <LayoutGridItem container item>
                <LayoutGridItem item>
                  <Typography sx={{ color: theme => theme.palette.components.typography.color }}>
                    {submissionRequirement
                      ? t('order.orderDetails.buttonConfirmation.Yes')
                      : t('order.orderDetails.buttonConfirmation.No')}
                  </Typography>
                </LayoutGridItem>
              </LayoutGridItem>
            </Box>
          </LayoutGridItem>
          <LayoutGridItem container alignItems={virtualOptions?.length ? 'normal' : 'center'}>
            <Typography className={`${classes.textBold} ${virtualClasses.container}`} pr="12px" component="span">
              {t('facility.units.virtualInterviewRating.rating')}
            </Typography>
            <Box component="span">
              <LayoutGridItem container item>
                {virtualOptions?.length ? (
                  <>
                    <LayoutGridItem item pr="36px">
                      {firstHalf?.map((virItem, index) => (
                        <Typography key={index} sx={{ color: theme => theme.palette.components.typography.color }}>
                          {virItem}
                        </Typography>
                      ))}
                    </LayoutGridItem>
                    <LayoutGridItem item>
                      {secondHalf?.map((virItem, index) => (
                        <Typography key={index} sx={{ color: theme => theme.palette.components.typography.color }}>
                          {virItem}
                        </Typography>
                      ))}
                    </LayoutGridItem>
                  </>
                ) : (
                  <LayoutGridItem item>{missingField}</LayoutGridItem>
                )}
              </LayoutGridItem>
            </Box>
          </LayoutGridItem>
        </LayoutGrid>
      )}
    </UnitCard>
  );
};

export default VirtualInterviewRating;
