export interface IPlacementMods {
  placementModsList: IModsDetails[] | null;
  refreshPlacementModsList: IModsDetails[] | null;
  openRefreshDialog: boolean;
  refreshModMessage: string;
  currentModDetails: any | null;
  defaultModDetails: any | null;
  startDateReasonCodes: any[] | null;
  additionalPremiumPay: any[] | null;
  expandedModsDialgog: boolean;
  exceptionPayRateGww: any;
  billRateGwwShiftList: any[] | null;
  cancelledHoursFrequencyList: any[] | null;
  effectiveDateTypes: any[] | null;
  insuranceTypes: any[] | null;
  pricingReasons: any;
  payeeList: any[] | null;
  reimbursementCategoryAndFrequency: any[] | null;
  isEditMod: boolean;
  isOpenModForAdding: any;
  modSavedStatus: any;
  isUndoModal: boolean;
  insuranceEffectiveType: any;
  isEditExistingMod: boolean;
  changeToPending: boolean;
  editTimeOffRows: any[] | null;
  editUtilitiesRows: any[] | null;
  editFurnitureRows: any[] | null;
  editPayrollShiftDiffRows: any[] | null;
  initiators: any[] | null;
  isGridModAltered: boolean;
  isCustomTemplateType: boolean;
  originCustomTemplateChanged: boolean;
  timeOffHoursErrors: any;
  timeOffStartDateErrors: any;
  furnitureQuantityErrors: any;
  furnitureCostToTravelerErrors: any;
  timeOffEndDateErrors: any;
  shiftDifferentialStartTimeError: any;
  shiftDifferentialEndTimeError: any;
  shiftDifferentialAmountError: any;
  shiftDifferentialTypeError: any;
  shiftDifferentialDescriptionTypeError: any;
  isGridModError: boolean;
  shiftDifferentialTypes: any[] | null;
  shiftDifferentialDescriptionTypes: any[] | null;
  shiftDifferentialTemplateTypes: any[] | null;
  selectedTaskForMod: any;
  preferenceMod: IPreferenceMod;
  shiftDifferentialTemplateItems: any[] | null;
  standard: boolean | null;
  openTaskGridFromHistory: any;
  isApprovalStatusSelected: boolean | null;
  isTemplateChangedInShift: boolean | null;
  editBillingShiftDiffRows: any[] | null;
  doesHousingBannerDisplay: boolean | null;
  holdBannerShownState: boolean | null;
  isEmiLmoDisabled: boolean | null;
  isEmiLmoUndo: boolean | null;
  pendingMods: IPlacementModification[];
  placementModHistory: IPlacementModification[];
  bookingApprovedCheckboxSpecs: IBookingApprovedCheckboxSpecs;
}

export interface IPlacementModification {
  placementId: number;
  travelerOptionId: number;
  travelerOptionTypeId: number;
  acceptanceStatusId: number;
  standard: string | null;
  approvalStatusId: number;
  approvalDate: string;
  description: string;
  amount: number;
  notes: string;
  voidStatus: string;
  effectiveDate: string;
  insertDate: string;
  assignRootTaskId: number;
  assignTaskId: number;
  dateVoided: number;
  shiftDifferentialTemplateTypeId: number;
  lastUpdated: string;
  recruiterApprovalDate: string;
  recruiterApprovedById: number;
  descTravelerOptionType: string;
  descAcceptanceStatus: string;
  descApprovalStatus: string;
  approvedByName: string;
  recruiterApprovedByName: string;
  hasAssignedTask: string;
  totalCount: number;
}

export interface IPreferenceMod {
  adult: IPreferenceAdult;
  children: IPreferenceChildren;
  pets: IPreferencePets;
}

export interface IPreferenceAdult {
  editAdultRows: IAdults[];
  isAdultGridmodAltered: boolean;
  isAdultGridmoderror: boolean;
  adultNameError: any[];
  adultRelationshipError: any[];
}
export interface IPreferenceChildren {
  editChildrenRows: IChildren[];
  isChildrenGridModAltered: boolean;
  isChildrenGridModError: boolean;
  childrenGenderError: any[];
  childrenAgeError: any[];
}
export interface IPreferencePets {
  editPetsRows: IPets[];
  isPetsGridModAltered: boolean;
  isPetsGridModError: boolean;
  petsTypeError: any[];
}
//initial state for PreferenceMod
export const preferenceModInitialState = {
  adult: {
    editAdultRows: [],
    isAdultGridmodAltered: false,
    isAdultGridmoderror: false,
    adultNameError: [],
    adultRelationshipError: [],
  },
  children: {
    editChildrenRows: [],
    isChildrenGridModAltered: false,
    isChildrenGridModError: false,
    childrenGenderError: [],
    childrenAgeError: [],
  },
  pets: {
    editPetsRows: [],
    isPetsGridModAltered: false,
    isPetsGridModError: false,
    petsTypeError: [],
  },
};

export interface IModsDetails {
  id: number;
  description: string;
}

export const Approved = 'Approved';
export const Pending = 'Pending';
export const Denied = 'Denied';
export const RecruiterApproved = 'Recruiter Approved';
export const Approve = 'Approve';
export const Deny = 'Deny';
export const Accepted = 'Accepted';
export const Declined = 'Declined';
export const Delete = 'Delete';
export const Void = 'Void';
export const EndDate = 'EndDate';
export const MinReqHours = 'MinReqHours';
export const MinReqHoursDescription = 'Minimum Required Hours';
export const StartDate = 'StartDate';
export const RegularRate = 'regularRate';
export const ChargeAmount = 'chargeAmount';
export const ContractRegularRate = 'contractRegularRate';
export const ContractChargeAmount = 'contractChargeAmount';
export const ContractShift = 'contractShift';
export const callBackFactor = 'callBackFactor';
export const doubleTimeFactor = 'doubleTimeFactor';
export const holidayFactor = 'holidayFactor';
export const overtimeFactor = 'overtimeFactor';
export const payrollType = 'payRoll';
export const onCallRate = 'onCallRate';
export const chargePremium = 'chargePremium';
export const amountPerMile = 'amountPerMile';
export const preceptorRate = 'preceptorRate';
export const additionalPremiumPay = 'additionalPremiumPay';
export const insuranceAmount = 'insuranceAmount';
export const contractTypeId = 'contractTypeId';
export const billRateHourly = 'billRateHourly';
export const overtimeRate = 'overtimeRate';
export const doubleTimeRate = 'doubleTimeRate';
export const holidayRate = 'holidayRate';
export const callBackRate = 'callbackRate';
export const orientationRate = 'orientationRate';
export const orientationFactor = 'orientationFactor';
export const billRateGwwRate = 'billRateGwwRate';
export const billExNotes = 'gwwExceptionNotes';
export const cancelledHours = 'cancelledHours';
export const billRateGwwShift = 'billRateGwwShift';
export const billRateMinReqHours = 'billRateMinReqHours';
export const cancelledHoursFrequency = 'cancelledHoursFrequency';
export const premiumPricingDiff = 'premiumPricingDiff';
export const premiumPricingReasons = 'premiumPricingReasons';
export const adminFee = 'adminFee';
export const lunchPenalty = 'lunchPenalty';
export const billRateFlat = 'billRateFlat';
export const ogpIntlLoan = 'OGPIntlLoan';
export const ogpIntlLoanDescription = 'OGP International Loan';
export const addOgpIntlLoanDescription = 'Add OGP International Loan';
export const editOgpIntlLoanDescription = 'Edit OGP International Loan';
export const timeoff = 'Timeoff';
export const timeOffDescription = 'Time Off';
export const requiredReceiptType = 'requiresReceipts';
export const payableType = 'payable';
export const reimbursementCategory = 'reimbursementCategory';
export const reimbursementCategorId = 'reimbursementCategoryId';
export const reimbursementDescription = 'reimbursementDescription';
export const reimbursementDescriptionId = 'reimbursementDescriptionId';
export const reimbursementFrequency = 'reimbursementFrequencyTypeDescription';
export const reimbursementFrequencyId = 'reimbursementFrequencyTypeId';
export const template = 'shiftDifferentialTemplateTypeId';
export const EmiLmo = 'EMILMO';
export const AddMinReqHoursDescription = 'Add Minimum Required Hours';
export const EmiLmoDescription = 'Early Move In / Late Move Out';
export const EditEmiLmoDescription = 'Edit Early Move In / Late Move Out';
export const AddEmiLmoDescription = 'Add Early Move In / Late Move Out';
export const effectiveDateErrorCode = 60028;
export const refreshErrorCode = 60000;
export const badRequestError = 60030;
export const newRecordError = 61326;
export const endDateModError = 60325;
export const PTOMod = 'PTO';

export const interimNoTimeoffAssociated = 60207;
export const housingInfoMsgCode = 20;

export const isErrorCode = code => {
  return (
    code === effectiveDateErrorCode ||
    code === refreshErrorCode ||
    code === badRequestError ||
    code === newRecordError ||
    code === endDateModError
  );
};
export const EditMinReqHoursDescription = 'Edit Minimum Required Hours';
export const timeOffAdd = 'Add Time Off';
export const timeOffEdit = 'Edit Time Off';
export enum ApprovalStatus {
  Pending = 1,
  Approved,
  Denied,
  RecruiterApproved,
}

export enum AcceptenceStatus {
  Accepted = 1,
  Declined,
}

export interface IStartDateMod {
  placementId: number;
  travelerOptionId: number;
  startDate: string;
  newPreEmploymentDate: Date;
  currentStartDate: string;
  currentEndDate: string;
  newStartDate: Date;
  currentPreEmploymentDate: string;
  initiatedBy: string;
  keepConstant: boolean;
  startDateReasonCode: number;
  startDateReasonCodeId: number;
  initiatedByUserId: number;
  assignRootTaskId: number;
  assignTaskId: number;
}

export interface IEndDateMod {
  placementId: number;
  travelerOptionId: number;
  currentStartDate: string;
  currentEndDate: string;
  newEndDate: Date;
  initiatedBy: string;
  initiatedByUserId: number;
  assignRootTaskId: number;
  assignTaskId: number;
}

export interface ITravelAllowanceMod {
  placementId: number;
  travelerOptionId: number;
  payerId: number;
  payeeId: number;
  payer?: string;
  payableTo?: string;
  amountPerMile: number;
  allowanceCap: number;
  billable: number;
  billableToClient?: string;
  //for interim travel
  placementTimeOffList?: ITimeOffRequests[];
  totalCount?: number;
  assigned?: number;
  assignRootTaskId?: number;
}

export interface IPostTravelAllowanceMod extends IUpdatePlacementMod {
  payerId: number;
  payeeId: number;
  amountPerMile: number;
  allowanceCap: number;
  billable: number;
  travelerOptionTypeId: number;
  timeOffRequests?: ITimeOffRequests[];
  selectedModType?: string;
}

export interface IPostEmiLmoMod extends IUpdatePlacementMod {
  emiNumberOfDays: number;
  emiDaysWaived: number;
  emiCostPerDay: number;
  lmoNumberOfDays: number;
  lmoDaysWaived: number;
  lmoCostPerDay: number;
  earlyMoveInDate: Date;
  moveInDate: Date;
  lateMoveOutDate: Date;
  moveOutDate: Date;
  placementStartDate?: Date | null;
  placementEndDate?: Date | null;
}

export interface IPostBonusMod extends IUpdatePlacementMod {
  bonusId: number;
  bonusAmount: number;
  bonusTypeId: number;
  payerId: number;
  paymentTypeId: number;
  billingTypeId: number | null;
  billingAmount: number;
  approvedBy: string;
  approvalTypeId: number | null;
  approvalDate: Date | null;
  bonusShortHoursTypeId: number | null;
  dateDue: Date | null;
  bonusPaid: string | null;
  updateOptionStatus: string | null;
  notes: string | null;
  shortAssignmentBonusId: number;
  startDate: Date | null;
  endDate: Date | null;
  requiredHours: number | null;
  forfeitBonus: boolean | null;
}

export interface ITypeAheadOption {
  id: number;
  Description: string;
}
export interface IShiftMod {
  placementId: number;
  travelerOptionId: number;
  shiftId: number;
  shiftDescription: string;
  assignRootTaskId: number;
  assignTaskId: number;
}

//START PTO MOD
export interface IPtoMod {
  placementId: number;
  travelerOptionId: number;
  ptoPlanId: number;
  ptoPlanDescription: string;
  ptoPlanFullDescription: string;
  assignRootTaskId: number;
  assignTaskId: number;
}
export interface IPostPTOMod extends IUpdatePlacementMod {
  ptoPlanID: number;
}
//END PTO MOD

export interface IPostTimeOff extends IUpdatePlacementMod {
  timeOffRequests: ITimeOffRequests[];
}

export interface IPostFurniture extends IUpdatePlacementMod {
  billable: boolean;
  utilityNotes: string;
  furnitureNotes: string;
  furniture: IFurnitureRequest[];
  utilities: IUtilityRequest[];
}

export const modsEnums = {
  Bonus: 1,
  EMILMO: 2,
  Furniture: 3,
  Housing: 4,
  Insurance: 5,
  PayRate: 6,
  TimeOff: 8,
  WKSPTS: 9,
  StartDate: 10,
  EndDate: 11,
  Reimbursement: 12,
  BillRate: 13,
  Preferences: 14,
  InterimTravel: 15,
  ArrivingTravel: 16,
  EndingTravel: 17,
  PayrollShiftDifferential: 18,
  BillingShiftDifferential: 19,
  LodingPerDiemAdjustment: 20,
  MinReqHours: 21,
  Shift: 22,
  PTO: 23,
  OGPLoan: 24,
};

export interface IPayRateMod {
  placementId: number;
  travelerOptionId: number;
  regularRate: string;
  overtimeFactor: string;
  doubleTimeFactor: string;
  holidayFactor: string;
  onCallRate: string;
  callBackFactor: string;
  chargePremium: string;
  preceptorRate: string;
  chargeAmount: number;
  contractRegularRate: number;
  contractShift: string;
  contractChargeAmount: number;
  clientPayRateNotes: string;
  isModified: boolean;
  payRoll: string;
  timestamp: string;
  amountPerMile: string;
  paysGww: boolean;
  tempRate: boolean;
  additionalPremiumPay: number;
  placementTimestamp: string;
  overtimeTotal: number;
  doubletimeTotal: number;
  holidayTotal: number;
  callbackTotal: number;
  gwwExceptions: string[];
  assignRootTaskId: number;
  assignTaskId: number;
}

export interface IBillRateMod {
  placementId: number;
  travelerOptionId: number;
  regularRate: string;
  overtimeRate: string;
  doubleTimeRate: string;
  overtimeFactor: string;
  doubleTimeFactor: string;
  holidayFactor: string;
  onCallRate: string;
  callBackFactor: string;
  chargePremium: string;
  chargeAmount: number;
  contractChargeAmount: number;
  preceptorRate: string;
  clientPayRateNotes: string;
  isModified: boolean;
  payRoll: string;
  timestamp: string;
  amountPerMile: string;
  paysGww: boolean;
  tempRate: boolean;
  additionalPremiumPay: string;
  placementTimestamp: string;
  overtimeTotal: string;
  doubletimeTotal: string;
  holidayTotal: string;
  callbackTotal: string;
  gwwExceptions: string[];
  billRateFlat: string;
  billRateHourly: string;
  contractTypeId: number;
  holidayRate: string;
  callbackRate: string;
  premiumPricingDiff: string;
  adminFee: string;
  orientationRate: string;
  orientationFactor: string;
  lunchPenalty: string;
  shiftId: number;
  billRateMinReqHours: string;
  cancelledHoursFrequency: string;
  cancellationHoursFrequencyId: number;
  gwwExceptionNotes: string;
  premiumPricingReasons: string[];
  billRateGwwRate: string;
  cancelledHours: string;
  billRateShift: string;
  assignRootTaskId: number;
  assignTaskId: number;
  contractRegularRate: number;
  contractShift: string;
}

export interface IOgpMod {
  placementId: number;
  travelerOptionId: number;
  totalAmount: number;
  perCheckAmount: number;
  totalAdminFee: number | null;
  perCheckAdminFee: number | null;
  startDate: string;
  feeStartDate: string | null;
}

export interface IBonusMod {
  placementId: number;
  approvalStatusId: number | null;
  acceptanceStatusId: number | null;
  effectiveDate: Date | null;
  userId: number | null;
  travelerOptionId: number;
  bonusType: string;
  bonusTypeId: number | null;
  bonusAmount: number;
  paymentType: string;
  paymentTypeId: number | null;
  payer: string;
  payerId: number | null;
  billingTypeId: number | null;
  billingType: string;
  billingAmount: number;
  shortHoursType: string;
  bonusShortHoursTypeId: number | null;
  dateDue: string;
  startDate: string | null;
  endDate: string | null;
  numberOfWeeks: number | null;
  requiredHours: number | null;
  notes: any;
  bonusPaid: boolean;
  forfeitBonus: boolean;
  approvedBy: string | null;
  approvalMethod: string | null;
  approvalDate: string | null;
  approvedById: number | null;
  assignRootTaskId: number;
}
export interface ITimeoff {
  placementId: number;
  travelerOptionId: number;
  timeOffRequests: ITimeOffRequests[];
  assignRootTaskId: number;
}

export interface ITimeOffRequests {
  placementId: number;
  travelerOptionId: number;
  placementTimeOffId: number;
  startDate: Date;
  endDate: Date;
  days: number;
  hours: number;
  cost: number;
  placementTimeOffPayerId: number;
  timestamp: string;
  isModified: boolean;
  facilityApprovedBy: string;
  interimPlacementId: number;
  interimTravelerOptionId: number;
  timeOffPayerDescription: string;
  placementTimeStamp: string;
  approvalStatusId: number;
  voidStatus: string;
  assigned?: boolean;
}
//START PREFERENCE MOD
export interface IPostPreferenceMod extends IUpdatePlacementMod {
  adults: IAdults[];
  children: IChildren[];
  pets: IPets[];
}
//END PREFERENCE MOD

export interface IPayrollShiftDifferential {
  placementId: number;
  travelerOptionId: number;
  shiftDifferentialTemplateTypeId: number;
  shiftDifferentials: IPayrollShiftDifferentialRequests[];
  assignRootTaskId: number;
}

export interface IPayrollShiftDifferentialRequests {
  placementId: number;
  travelerOptionId: number;
  shiftDifferentialId: number;
  shiftDifferentialDescriptionTypeId: number;
  shiftDifferentialTypeId: number;
  amount: number;
  startTime: Date;
  endTime: Date;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  timestamp: string;
  placementTimestamp: string;
}

export interface IFurniture {
  placementId: number;
  travelerOptionId: number;
  furnitureNotes: string;
  furnitureRequests: IFurnitureRequest[];
  utilityNotes: string;
  utilitiesRequests: IUtilityRequest[];
  assignRootTaskId: number;
}

export interface IFurnitureRequest {
  placementId: number;
  travelerOptionId: number;
  placementFurnitureId: number;
  description: string;
  standardCost: number;
  costToTraveler: number;
  furnitureCostId: number;
  quantity: number;
  include: boolean;
  assignRootTaskId: number;
}

export interface IUtilityRequest {
  placementId: number;
  travelerOptionId: number;
  billable: boolean;
  costToTraveler: number;
  description: string;
  include: boolean;
  placementUtilityId: number;
  standardCost: number;
  utilityCostId: number;
}
export enum gridTags {
  TIMEOFFGRIDTAG = 'PlacementMods-TimeOff',
  INTERIMGRIDTAG = 'PlacementMods-Interim-Travel-Assigned-TimeOff',
  FURNITUREGRIDTAG = 'PlacementMods-Furniture',
  UTILITYGRIDTAG = 'PlacementMods-Utility',
  ADULTGRIDTAG = 'PlacementMods-Traveler-Info-Adults',
  CHILDRENGRIDTAG = 'PlacementMods-Traveler-Info-Children',
  PETSGRIDTAG = 'PlacementMods-Traveler-Info-Pets',
  PAYROLLSHIFTDIFFERENTIALGRIDTAG = 'PlacementMods-PAYROLL-SHIFT-DIFFERENTIAL',
  BILLINGSHIFTDIFFERENTIALGRIDTAG = 'PlacementMods-BILLING-SHIFT-DIFFERENTIAL',
}

export interface IPostMinReqHrs extends IUpdatePlacementMod {
  minReqHours: number;
}
export interface IPostPayRate extends IPayRate {
  travelerOptionId: number;
  voidStatus: boolean;
  assignRootTaskId: number;
  assignTaskId: number;
  timestamp: string;
  travelerOptionTimestamp: string;
  placementTimestamp: string;
}

export interface IPayRate {
  placementId: number;
  approvalStatusId: number;
  acceptanceStatusId: number;
  effectiveDate: Date;
  userId: number;
  regularRate: number;
  overtimeFactor: number;
  doubletimeFactor: number;
  holidayFactor: number;
  onCallRate: number;
  callbackFactor: number;
  chargePremium: number;
  preceptorRate: number;
  payRoll: string;
  amountPerMile: number;
  paysGww: boolean;
  tempRate: boolean;
  additionalPremiumPay: number;
  gwwExceptions: number[];
}

export interface IPostLodging extends IUpdatePlacementMod {
  uhBenefit: number;
  daily: boolean;
}

export interface IPostStartDate {
  placementId: number;
  travelerOptionId: number;
  startDate: string;
  keepConstant: boolean;
  initiatedByUserId: number;
  preEmploymentDate: string;
  reasonCodeId: number;
  acceptanceStatusId: number;
  approvalStatusId: number;
  userId: number;
  effectiveDate: string;
  timestamp: string;
  travelerOptionTimestamp: string;
  placementTimestamp: string;
  voidStatus: boolean;
  assignRootTaskId: number;
  assignTaskId: number;
}
export interface IPostEndDate extends IUpdatePlacementMod {
  endDate?: Date | null;
  initiatedByUserId: number;
}

export interface IPostReimbursement extends IUpdatePlacementMod {
  description: string;
  amount: number;
  requiresReceipts: boolean;
  endDate: string;
  reimbursementFrequencyTypeId: number;
  reimbursementCategoryDescriptionId: number;
  billable: boolean;
  reimbursementCategoryId: number;
  reimbursementDescriptionId: number;
  payeeId: number;
  amountPerMile: number;
  effectiveDate: Date;
}

export interface IEmiLmoMod {
  placementId: number;
  travelerOptionId: number;
  moveInDate: string;
  moveOutDate: string;
  earlyMoveInDate: string;
  emiNumberOfDays: number;
  emiDaysWaived: number;
  emiCostPerDay: number;
  lateMoveOutDate: string;
  lmoNumberOfDays: number;
  lmoDaysWaived: number;
  lmoCostPerDay: number;
  assignRootTaskId: number;
}

export interface IPostPayrollShiftDifferential extends IUpdatePlacementMod {
  shiftDifferentialTemplateTypeId: number;
  shiftDifferentials: IPayrollShiftDifferentialRequests[];
}

export interface IHousingMod {
  placementId: number;
  travelerOptionId: number;
  housingProvider: string;
  housingProviderId: number;
  travelerRequestedHousingType: string;
  travelerRequestedHousingTypeId: number;
  actualHousingType: string;
  actualHousingTypeId: number;
  reasonForChange: string;
  reasonForChangeId: number;
  standardAmountCharged: string;
  amountToTraveler: string;
  monthlyLodgingAmount: number;
  billableToClient: string;
  petRentCharge: number;
  parkingGarageCharge: number;
  utilityCharge: number;

  isPetDepositRefundable: string;
  petDespositAmount: string;
  private2BedRoomDepositAmount: string;
  is2BedRoomDepositRefundable: string;
  private3BedRoomDepositAmount: string;
  is3BedRoomDepositRefundable: string;
  otherDepositDescription: string;
  otherDepositAmount: string;
  isOtherDepositRefundable: string;
}

export interface IAdults {
  id: number;
  name: string;
  relationship: string;
}
export interface IAdults {
  id: number;
  name: string;
  relationship: string;
}

export interface IChildren {
  id: number;
  gender: string;
  age: string;
}

export interface IPets {
  id: number;
  type: string;
  breed: string;
  weight: number;
  details: string;
}

export interface IPreferencesMod {
  placementId: number;
  travelerOptionId: number;
  housingType: string;
  furnitureExtras: string;
  typeOfBed: string;
  adults: IAdults[];
  keyFacts: string;
  children: IChildren[];
  pets: IPets[];
  assignRootTaskId: number;
}

export interface IAddPlacementMod {
  assignRootTaskId?: number | null;
  assignTaskId?: number | null;
  voidStatus: boolean;
  timestamp: Uint8Array;
  travelerOptionTimestamp: Uint8Array;
  placementTimestamp: Uint8Array;
}

export interface IUpdatePlacementMod extends IAddPlacementMod {
  travelerOptionId: number;
  placementId: number;
  approvalStatusId: number;
  acceptanceStatusId: number;
  effectiveDate: Date;
  userId: number;
}

export interface IUpdateShift extends IUpdatePlacementMod {
  shiftId: number;
}

export interface iPostInsuranceMod {
  placementId: number;
  travelerOptionId: number;
  effectiveDateTypeId: number;
  placementInsuranceTypeId: number;
  amount: number;
  insuranceEffectiveDate: Date | null;
  acceptanceStatusId: number;
  approvalStatusId: number;
  userId: number;
  effectiveDate: Date | null;
  insuranceProviderId: number;
  timestamp: Uint8Array;
  travelerOptionTimestamp: Uint8Array;
  placementTimestamp: Uint8Array;
  voidStatus: boolean;
  assignRootTaskId: number | null;
  assignTaskId: number | null;
}
export interface IRefreshModDetailsRequest {
  placementId: number;
  travelerOptionTypeId: number;
}
export interface IBillingShiftDifferential {
  placementId: number;
  travelerOptionId: number;
  shiftDifferentialTemplateTypeId: number;
  billingShiftDifferentialRequests: IBillingShiftDifferentialRequests[];
  assignRootTaskId: number;
}

export interface IBillingShiftDifferentialRequests {
  placementId: number;
  travelerOptionId: number;
  shiftDifferentialId: number;
  shiftDifferentialDescriptionTypeId: number;
  shiftDifferentialTypeId: number;
  amount: number;
  startTime: Date;
  endTime: Date;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  timestamp: string;
  placementTimeStamp: string;
}

export interface IPostBillingShiftDifferential extends IUpdatePlacementMod {
  shiftDifferentialTemplateTypeId: number;
  shiftDifferentials: IBillingShiftDifferentialRequests[];
}

export interface IUpdateOGP extends IUpdatePlacementMod {
  totalAmount: number;
  perCheckAmount: number;
  startDate: Date;
  totalAdminFee: number;
  perCheckAdminFee: number;
  feeStartDate: Date;
}

export interface IPostHousingMod extends IUpdatePlacementMod {
  placementId: number;
  travelerOptionId: number;
  housingProvider: string;
  housingProviderId: number;
  travelerRequestedHousingType: string;
  requestedHousingId: number;
  actualHousingType: string;
  housingDescriptionId: number;
  reasonForChange: string;
  privateHousingOptionTypeId: number;
  standardAmount: number;
  amountChargedToTraveler: number;
  monthlyLodgingAmount: number;
  billableToClient: string;
  petRentCharge: number;
  parkingGarageCharge: number;
  utilityCharge: number;
  deposits: any;
  otherDepositNotes: string;
}

export interface IPlacementHistoryRequest {
  placementId?: number;
  pageNumber?: number;
  pageSize?: number;
  gridType?: string;
  modTypeIds?: string;
  modApprovalStatusIds?: string;
  modAcceptanceStatusIds?: string;
  modRecruiterApproved?: string;
  modVoid?: string;
  startDate?: string;
  endDate?: string;
  sortColumn?: string;
  sortOrder?: string;
  offSet?: number;
}

export interface IBookingApprovedCheckboxSpecs {
  isCheckBoxSelected: boolean;
  isCheckBoxDisabled: boolean;
  pendingModCount: number;
  travelerOptionTimestamp: string;
  divisionTypeId: number;
  divisionType: string;
  activityTypeId: number;
  activityStatusId: number;
  approvedBy: string;
  approvedByName: string;
  approvalDate: string;
  disabledReasons: { [key: string]: string };
}
