import React, { useEffect, useState } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { CheckboxContainer } from '../CustomComponents/CheckboxContainer';
import { MiscellaneousFields } from '../MiscellaneousFields';
import { useTranslation } from 'react-i18next';
import { PaperCard } from '../styles';
import { SkillSet } from '../SkillSet';
import TimeOffRequest from 'app/components/TimeOffRequest/TImeOffRequest';
import { Divider, Button, Theme, Paper } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { ClientSettings } from '../ClientSettings';
import { PreferredLocation } from '../PreferredLocation';
import { useDispatch, useSelector } from 'react-redux';
import {
  preferenceActions,
  preferenceReducer,
  preferenceSliceKey,
  initialFormState,
  initialPreferenceState,
  IMiscellaneousFields,
} from './Preference.redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { preferenceSaga } from './Preference.saga';
import {
  selectFacilityTypeCheckboxes,
  selectJobExperienceCheckboxes,
  selectJobTypeCheckboxes,
  selectPlacementLengthCheckboxes,
  selectShiftLengthCheckboxes,
  selectShiftTimeCheckboxes,
  selectTimeOffRequestsChosen,
  selectSaveEvent,
  selectSkillSetsChosen,
  selectClientSettingsChosen,
  selectLocationsChosen,
  selectMiscellaneousFields,
  selectRecruiterEnterdDataExists,
  selectApiResponse,
} from './Preference.selectors';
import _ from 'lodash';
import { candidateDetailsSelection } from '../../../Profile/CandidateDetails.selector';
import { CustomBackdrop } from '../../../../../Common/Backdrop/CustomBackdrop';
import { usePromiseTracker } from 'react-promise-tracker';
import { selectUser } from 'oidc/user.selectors';
import { resetFieldData } from './helper';
import { usePageVisitTimeTracker } from 'app-insights/appInsightsTracking';
import { withReadOnly } from 'oidc/withReadOnly';
import { useDecision } from '@optimizely/react-sdk';
import { ff_candidate_ui_preferences } from 'app/constants/FeatureFlags/Candidate/Keys';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';

const useStyles = makeStyles()((theme: Theme) => ({
  button: {
    // backgroundColor: '#3F51B5',
    backgroundColor: '#006FB9',
    color: '#fff',
    fontSize: 12,
  },
  actionCard: {
    position: 'fixed',
    width: 'calc(100% - 115px)',
    display: 'inline-flex',
    gap: '1%',
    justifyContent: 'flex-end',
    bottom: 0,
    border: '1px solid #ececec',
    borderRadius: 4,
    padding: '0.5% 1%',
    background: '#FCFCFC 0% 0% no-repeat padding-box',
    boxShadow: '0px -2px 6px #00000029',
    zIndex: 1,
  },
  form: {
    paddingBottom: '3%',
  },
}));

const CheckAnyFieldIsNotReadOnly = fields => fields.some(field => !field.isReadOnly);
const CheckAnyCheckBoxesIsNotReadOnly = fields => fields.some(field => !field.isReadOnly && field.checked);

export const PreferenceForm = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  useInjectReducer({ key: preferenceSliceKey, reducer: preferenceReducer });
  useInjectSaga({ key: preferenceSliceKey, saga: preferenceSaga });
  const { promiseInProgress } = usePromiseTracker({ area: 'preference-form', delay: 0 });
  usePageVisitTimeTracker('Candidate Details:Preferences Tab');
  const [enableJobPreferences] = useDecision(ff_candidate_ui_preferences);

  const dispatch = useDispatch();

  const isSuccessDialogOpen = useSelector(selectSaveEvent);
  const appUser = useSelector(selectUser);

  const apiResponse = useSelector(selectApiResponse);
  const skillSet = useSelector(selectSkillSetsChosen);
  const preferredLocations = useSelector(selectLocationsChosen);
  const clientSettings = useSelector(selectClientSettingsChosen);
  const miscellaneousFields = useSelector(selectMiscellaneousFields);
  const placementTypes = useSelector(selectJobTypeCheckboxes);
  const jobExperiences = useSelector(selectJobExperienceCheckboxes);
  const shiftTimes = useSelector(selectShiftTimeCheckboxes);
  const shiftLengths = useSelector(selectShiftLengthCheckboxes);
  const placementLengths = useSelector(selectPlacementLengthCheckboxes);
  const facilityTypes = useSelector(selectFacilityTypeCheckboxes);
  const timeOffRequests = useSelector(selectTimeOffRequestsChosen);

  const candidateDetails = useSelector(candidateDetailsSelection);
  const recruiterEnterdDataExists = useSelector(selectRecruiterEnterdDataExists);
  const [formIsClearing, setFormIsClearing] = useState<boolean>(false);

  const defaultValues = {
    skillSet,
    discipline: null,
    specialty: null,
    preferredLocations,
    location: null,
    radius: null,
    placementTypes,
    jobExperiences,
    clientSettings,
    facilityTypes,
    placementLengths,
    shiftLengths,
    shiftTimes,
    weeklyPayRate: miscellaneousFields.weeklyPayRate,
    preferredStartDate: miscellaneousFields.avStartDate,
    timeOffRequests,
  };

  const formMethods = useForm({
    defaultValues: defaultValues,
  });
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { dirtyFields },
  } = formMethods;

  const successArgs = {
    variant: 'contained',
    buttonText: 'Ok',
    dialogTitle: t('alerts.success'),
    dialogText: t('alerts.successDialogText'),
    open: isSuccessDialogOpen,
    handleSubmit: () => {
      dispatch(preferenceActions.setPreferenceSaveEvent(false));
    },
  };
  const OnSubmit = formData => {
    const userData = {
      updatedBy: appUser.userInfo?.email,
      candidateId: candidateDetails?.travelerId,
      recruiterId: candidateDetails?.recruiter?.recruiterId ? candidateDetails?.recruiter?.recruiterId : null,
      candidateGuid: candidateDetails?.candidateGuid,
      userId: appUser.userInfo?.employeeId,
      userName: `${appUser.userInfo?.firstName} ${appUser.userInfo?.lastName}`,
    };
    dispatch(
      preferenceActions.savePreferencesAction({ formData: formData, dirtyFields: dirtyFields, userData: userData }),
    );
  };

  const resetForm = () => {
    resetFieldData(skillSet, newData => dispatch(preferenceActions.setSkillSetsChosen(newData)));
    resetFieldData(preferredLocations, newData => dispatch(preferenceActions.setLocationsChosen(newData)));
    resetFieldData(getValues('clientSettings'), newData =>
      dispatch(preferenceActions.setClientSettingsChosen(newData)),
    );
    resetFieldData(_.cloneDeep(placementTypes), newData =>
      dispatch(preferenceActions.setPlacementTypeCheckboxes(newData)),
    );
    resetFieldData(_.cloneDeep(jobExperiences), newData =>
      dispatch(preferenceActions.setJobExperiencesCheckboxes(newData)),
    );
    resetFieldData(_.cloneDeep(shiftTimes), newData => dispatch(preferenceActions.setShiftTimeCheckBoxes(newData)));
    resetFieldData(_.cloneDeep(shiftLengths), newData => dispatch(preferenceActions.setShiftLengthCheckBoxes(newData)));
    resetFieldData(_.cloneDeep(placementLengths), newData =>
      dispatch(preferenceActions.setPlacementLengthCheckBoxes(newData)),
    );
    resetFieldData(_.cloneDeep(facilityTypes), newData =>
      dispatch(preferenceActions.setFacilityTypeCheckBoxes(newData)),
    );
    resetFieldData(
      {
        avStartDate: getValues('avStartDate'),
        isStartDateReadOnly: miscellaneousFields.isStartDateReadOnly,
        weeklyPayRate: getValues('weeklyPayRate'),
        isPayRateReadOnly: miscellaneousFields.isPayRateReadOnly,
      } as IMiscellaneousFields,
      newData => dispatch(preferenceActions.setMiscellaneousFields(newData)),
    );
  };

  const clearFields = async () => {
    setFormIsClearing(true);
    resetForm();
    dispatch(preferenceActions.setRecruiterEnterdDataExists(false));
    setFormIsClearing(false);
  };

  const setFieldValue = (fieldName: string, data) => {
    if (formIsClearing) setValue(fieldName, data, { shouldDirty: true });
    else setValue(fieldName, data);
  };

  const checkClearAllButtonStatus = (e?: React.FormEvent<HTMLFormElement>) => {
    if (
      CheckAnyFieldIsNotReadOnly(getValues('skillSet')) ||
      CheckAnyFieldIsNotReadOnly(getValues('preferredLocations')) ||
      CheckAnyFieldIsNotReadOnly(getValues('clientSettings')) ||
      CheckAnyCheckBoxesIsNotReadOnly(getValues('placementTypes')) ||
      CheckAnyCheckBoxesIsNotReadOnly(getValues('jobExperiences')) ||
      CheckAnyCheckBoxesIsNotReadOnly(getValues('shiftTimes') || shiftTimes) ||
      CheckAnyCheckBoxesIsNotReadOnly(getValues('shiftLengths') || shiftLengths) ||
      CheckAnyCheckBoxesIsNotReadOnly(getValues('placementLengths') || placementLengths) ||
      CheckAnyCheckBoxesIsNotReadOnly(getValues('facilityTypes') || facilityTypes) ||
      (getValues('weeklyPayRate') && !miscellaneousFields.isPayRateReadOnly) ||
      (getValues('avStartDate') && !miscellaneousFields.isStartDateReadOnly)
    ) {
      dispatch(preferenceActions.setRecruiterEnterdDataExists(true));
      return;
    } else dispatch(preferenceActions.setRecruiterEnterdDataExists(false));
  };

  useEffect(() => {
    const newDefaultValues = {
      skillSet,
      discipline: null,
      specialty: null,
      preferredLocations,
      location: null,
      radius: null,
      placementTypes,
      jobExperiences,
      clientSettings,
      facilityTypes,
      placementLengths,
      shiftLengths,
      shiftTimes,
      weeklyPayRate: miscellaneousFields.weeklyPayRate,
      preferredStartDate: miscellaneousFields.avStartDate,
      timeOffRequests,
    };
    reset(newDefaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiResponse]);

  useEffect(() => {
    setFieldValue('placementTypes', _.cloneDeep(placementTypes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placementTypes]);

  useEffect(() => {
    setFieldValue('jobExperiences', _.cloneDeep(jobExperiences));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobExperiences]);

  useEffect(() => {
    setFieldValue('facilityTypes', _.cloneDeep(facilityTypes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityTypes]);

  useEffect(() => {
    setFieldValue('placementLengths', _.cloneDeep(placementLengths));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placementLengths]);

  useEffect(() => {
    setFieldValue('shiftLengths', _.cloneDeep(shiftLengths));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftLengths]);

  useEffect(() => {
    setFieldValue('shiftTimes', _.cloneDeep(shiftTimes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftTimes]);

  useEffect(() => {
    if (enableJobPreferences?.enabled && candidateDetails.travelerId) {
      const candidateId = candidateDetails.travelerId;
      const recruiterId = candidateDetails.recruiter?.recruiterId ? candidateDetails.recruiter?.recruiterId : null;
      dispatch(preferenceActions.requestPreferencesAction({ candidateId: candidateId, recruiterId: recruiterId }));
    }

    return () => {
      dispatch(
        preferenceActions.setPreferences({
          preferenceAPIresponse: initialPreferenceState,
          formData: initialFormState,
          preferenceSaveEvent: false,
          recruiterEnterdDataExists,
          miscellaneousSpecs: {
            disciplineOpts: [],
            specialtyOpts: [],
          },
        }),
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(OnSubmit)} className={classes.form} onChange={e => checkClearAllButtonStatus(e)}>
        <GenericDialog
          variant="white"
          open={isSuccessDialogOpen}
          dialogTitleProps={{ text: t('alerts.success') }}
          dialogActions={[
            {
              text: 'Ok',
              onClick: () => {
                dispatch(preferenceActions.setPreferenceSaveEvent(false));
              },
            },
          ]}
        >
          {t('alerts.successDialogText')}
        </GenericDialog>
        <CustomBackdrop open={promiseInProgress} />
        <PaperCard id={'paper-card-container'} data-testid="paper-card-1">
          <SkillSet setFieldValue={setFieldValue} onValueChange={checkClearAllButtonStatus} />
          <Divider />

          <PreferredLocation setFieldValue={setFieldValue} onValueChange={checkClearAllButtonStatus} />
          <Divider />

          <Controller
            name="placementTypes"
            control={control}
            render={({ ref, ...rest }) => (
              <CheckboxContainer {...rest} title={t('candidate.preferenceForm.jobTypeTitle')} multiSelect />
            )}
          />
          <Divider />

          <Controller
            name="jobExperiences"
            control={control}
            render={({ ref, ...rest }) => (
              <CheckboxContainer {...rest} title={t('candidate.preferenceForm.jobExperienceTitle')} multiSelect />
            )}
          />
          <Divider />

          <ClientSettings setFieldValue={setFieldValue} onValueChange={checkClearAllButtonStatus} />
          <Divider />

          <Controller
            name="facilityTypes"
            control={control}
            render={({ ref, ...rest }) => (
              <CheckboxContainer
                {...rest}
                title={t('candidate.preferenceForm.facilityTypeTitle')}
                anyOptionName={'facilityType.any'}
                multiSelect
              />
            )}
          />
          <Divider />

          <Controller
            name="placementLengths"
            control={control}
            render={({ ref, ...rest }) => (
              <CheckboxContainer
                {...rest}
                title={t('candidate.preferenceForm.placementLengthTitle')}
                anyOptionName={'placementLength.any'}
                multiSelect
              />
            )}
          />
          <Divider />

          <Controller
            name="shiftLengths"
            control={control}
            render={({ ref, ...rest }) => (
              <CheckboxContainer
                {...rest}
                title={t('candidate.preferenceForm.shiftLengthTitle')}
                anyOptionName={'shiftLength.any'}
                multiSelect
              />
            )}
          />
          <Divider />

          <Controller
            name="shiftTimes"
            control={control}
            render={({ ref, ...rest }) => (
              <CheckboxContainer
                {...rest}
                title={t('candidate.preferenceForm.shiftTimeTitle')}
                anyOptionName={'shiftTime.any'}
                multiSelect
              />
            )}
          />
          <Divider />

          <MiscellaneousFields setFieldValue={setFieldValue} onValueChange={checkClearAllButtonStatus} />
        </PaperCard>

        <PaperCard>
          <Controller
            name="timeOffRequests"
            control={control}
            defaultValue={timeOffRequests}
            render={({ ref, ...rest }) => <TimeOffRequest {...rest} />}
          />
        </PaperCard>

        <Paper classes={{ root: classes.actionCard }}>
          <Button
            variant="contained"
            color="primary"
            classes={{ contained: classes.button }}
            type="button"
            disabled={!recruiterEnterdDataExists}
            onClick={clearFields}
          >
            {t('candidate.preferenceForm.clearButtonLabel')}
          </Button>
          {withReadOnly(Button)({
            variant: 'contained',
            color: 'primary',
            disabled:
              !enableJobPreferences?.enabled ||
              !(
                dirtyFields.skillSet ||
                dirtyFields.preferredLocations ||
                dirtyFields.placementTypes ||
                dirtyFields.jobExperiences ||
                dirtyFields.clientSettings ||
                dirtyFields.facilityTypes ||
                dirtyFields.placementLengths ||
                dirtyFields.shiftLengths ||
                dirtyFields.shiftTimes ||
                dirtyFields.weeklyPayRate ||
                dirtyFields.avStartDate ||
                dirtyFields.timeOffRequests
              ),
            classes: { contained: classes.button },
            type: 'submit',
            children: t('candidate.preferenceForm.saveButtonLabel'),
          })}
        </Paper>
      </form>
    </FormProvider>
  );
};
