import { Grid, Typography } from 'amn-ui-core';
import { SkillsetTree } from 'app/components/Common/SkillsetTree/SkillsetTree';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { TableSection } from '../../../customComponents/TableSection';
import { getRequirementCheck } from '../../../helper';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import {
  selectServiceFailures,
  selectSubmissionInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { RequirementsRow } from '../../TableComponents/TableComponents';
import { ListContent } from 'app/components/Common/List/ListContent';

export const Skillset = () => {
  const { t } = useTranslation();
  const {
    amnReq: {
      amn: {
        skillset: {
          data: { verifiedByCmType, strikeMatchType },
        },
      },
      candidate: { skillset: candidateReq },
    },
    requirementFilter,
  } = useSelector(selectSubmissionInfo);

  const {
    amnReq: {
      amn: { skillset: amnSkillset },
    },
  } = useSelector(selectSubmissionInfo);

  const {
    skillsetAmnRequirement: skillsetServiceFailed,
    strikeSkillsetAmnRequirement: strikeSkillServiceFailed,
  } = useSelector(selectServiceFailures);

  const { placementId } = useContext(AddOnContext);
  const dispatch = useDispatch();

  const { promiseInProgress: skillsetLoading } = usePromiseTracker({
    area: 'placement-submission-skillset-requirement',
    delay: 0,
  });
  const { promiseInProgress: strikeSkillsetLoading } = usePromiseTracker({
    area: 'placement-submission-skill-strike-amn-requirement',
    delay: 0,
  });

  const { display: displayNonstrike } = useRequirementFilter('submission-amn', requirementFilter, verifiedByCmType);
  const { display: displayStrike } = useRequirementFilter('submission-amn', requirementFilter, strikeMatchType);

  /**
   * Below conditional observes entire row is hidden
   * if applied filter ignores both strike and non-strike
   */
  return displayNonstrike || displayStrike ? (
    <>
      <RequirementsRow
        id="placement-submission-amn-skillset"
        title={t('placement.profile.summary.submission.amn.skillset.title')}
        requirementCell={<RowKey amnSkillset={amnSkillset} t={t} />}
        candidateCell={<RowValue skillset={candidateReq} />}
        hasError={
          skillsetServiceFailed ||
          strikeSkillServiceFailed
        }
        loading={skillsetLoading || strikeSkillsetLoading}
        onTryAgain={() => {
          dispatch(placementSummaryActions.getSkillsetRequirement({ placementId, isRetryingService: true }));
        }}
        transparentBottom={strikeMatchType !== null}
      />
    </>
  ) : null;
};

const RowKey = ({ amnSkillset, t }) => {
  return (
    <TableSection
      items={amnSkillset?.message?.map(x => {
        return {
          icon: x.icon ? getRequirementCheck(x.icon) : null,
          primaryContent: (
            <Grid container>
              <Grid item xs={12}>
                <Typography>{t(x?.message?.secondary)}</Typography>
              </Grid>
              <Grid item xs={12}>
                {x?.message?.ternary && <ListContent list={x?.message?.ternary} />}
              </Grid>
            </Grid>
          ),
        };
      })}
    />
  );
};

const RowValue = ({ skillset }) => (
  <Typography variant="body1">
    <SkillsetTree skillset={skillset ? skillset.data : []} />
  </Typography>
);
