import { Grid, Typography } from 'amn-ui-core';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{
  disabled: boolean;
  error: boolean;
}>()((theme, { disabled = false, error = false }) => ({
  root: {
    border: `1px solid ${theme.palette.system.borderColor1}`,
    background: theme.palette.components.box.container.color,
    width: '100%',
    height: '100%',
  },
  titleBar: {
    borderBottom: `1px solid ${!disabled && error ? theme.palette.system.errorRed : theme.palette.system.borderColor1}`,
    background: theme.palette.system.offWhite,
    color: disabled
      ? theme.palette.system.lightGrey
      : error
      ? theme.palette.system.errorRed
      : theme.palette.components.box.titleBar.color,
    fontSize: 14,
    padding: '12px',
    fontWeight: 500,
  },
  error: {
    color: theme.palette.system.errorRed,
    marginLeft: '10px',
    marginTop: '5px',
  },
}));

export const BoxContainer = ({ disabled = false, error = undefined, addOnComponent = undefined, ...props }) => {
  const { classes } = useStyles({ disabled, error });
  return (
    <Grid container direction="column" flexWrap="nowrap" classes={{ root: classes.root }}>
      <Grid item>
        <Grid container classes={{ root: classes.titleBar }} display="flex" justifyContent="space-between">
          <Grid item>{props.title}</Grid>
          {addOnComponent ? <Grid item>{addOnComponent}</Grid> : null}
        </Grid>
      </Grid>
      {!!error && !disabled ? (
        <Grid item>
          <Typography className={classes.error}>{error.message}</Typography>
        </Grid>
      ) : null}
      <Grid item>{props.children}</Grid>
    </Grid>
  );
};
