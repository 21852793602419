import { createAction } from '@reduxjs/toolkit';
import { IPlacementStatusUpdateRequest } from 'app/models/Placement/PlacementDetails';
import { IExtensionRequest, IExtensionUpdate } from './types';
import { ISubmitPacketCommand } from '../placement-details/types';

const getPlacementStatusDetails = createAction<{
  placementId: number;
  isInitLoad?: boolean  | false
}>('GET_PLACEMENT_STATUS_DETAILS');

const updatePlacementStatusAction = createAction<IPlacementStatusUpdateRequest>('UPDATE_PLACEMENT_STATUS');
const createClassicRequestContract = createAction<{ placementId: number }>('CREATE_CLASSIC_REQUEST_CONTRACT');

const getExtentionStatusDetails = createAction<{
  placementId: number;
  isInitLoad?: boolean  | false;
}>('GET_EXTENSION_STATUS_DETAILS');

const updateExtentionStatusDetails = createAction<IExtensionRequest | IExtensionUpdate>(
  'UPDATE_EXTENSION_STATUS_DETAILS',
);

const updateExtentionStatus = createAction<{
  placementId: number;
  extensionStatusId: number | null;
}>('UPDATE_EXTENSION_STATUS');

const saveSubmissionPacketsToCosmos = createAction<ISubmitPacketCommand>('SAVE_SUBMISSION_PACKETS_TO_COSMOS');

const updateEfoDocToOkToSend = createAction<{
  documentId: string;
  status: string;
}>('UPDATE_PLACEMENT_EFO_DOC_TO_OK_TO_SEND_STATUS');

export const serviceActions = {
  getPlacementStatusDetails,
  getExtentionStatusDetails,
  updatePlacementStatusAction,
  createClassicRequestContract,
  updateExtentionStatusDetails,
  updateExtentionStatus,
  saveSubmissionPacketsToCosmos,
  updateEfoDocToOkToSend,
};
