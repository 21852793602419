import React, { useState, useEffect } from 'react';
import { Box, Checkbox, FormControl, TextField, InputAdornment, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { gridControlType, gridControl } from 'app/models/Grid';
import { AccordionMenu } from '../DropDown/AccordionMenu';
import { FilterOptions } from 'app/models/DropDown';
import moment from 'moment';
import PublishIcon from '@mui/icons-material/Publish';
import { DateRangePicker } from 'app/ComponentLibrary/DateRangeNew';
import { addDays } from 'date-fns';

const useStyles = makeStyles<{ fullWidth: boolean }>()((theme, { fullWidth }) => ({
  //checkbox
  checkboxRoot: {
    '& .MuiCheckbox-colorPrimary.Mui-checked': {
      color: '#555555',
    },
  },
  checkbox: {
    color: '#555555',
  },
  //white checkbox
  lightCheckboxRoot: {
    '& .MuiCheckbox-colorPrimary.Mui-checked': {
      color: '#fff',
    },
    '& .MuiCheckbox-colorPrimary.Mui-disabled': {
      color: '#bdbdbd',
    },
  },
  lightCheckbox: {
    color: '#fff',
  },
  //green checkbox
  greenCheckboxRoot: {
    '& .MuiCheckbox-colorPrimary.Mui-checked': {
      color: '#2eb82e',
    },
  },
  greenCheckbox: {
    color: '#2eb82e',
  },
  // textfield
  textFieldNumber: {
    // '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
    //   display: 'none',
    // },
  },
  textField: {
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  textFieldInput: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    fontSize: '14px',
  },

  containment: {
    width: '10px',
    height: '10px',
    position: 'absolute',
    marginLeft: '-6px',
    top: '-4px',
    paddingLeft: '11px',
    // transform: 'translate(-50%, -50%)',
  },
  containmentOuter: {
    width: '10px',
    height: '10px',
    // margin: '18px 0px',
  },
  containmentSelected: {
    backgroundColor: 'white',
  },
  fullWidth: {
    width: '100%',
  },
  checkBoxLabelStyles: {
    marginBottom: '10px ',
  },
}));

interface GridControlBuilderProps {
  control: gridControl;
  value: any;
  id?: any;
  lightTheme?: boolean;
  filterOptionsOveride?: FilterOptions[];
  edit?: {
    rowKey: number;
    key: string;
    isEditing: boolean;
    onValueChanged: (rowKey, key, value) => void;
  };
  fullWidth?: boolean;
  label?: string;
  textFiledCustomStyle?: string;
  datePickerLabelStyles?: string;
  menuPaperStyle?: string;
  large?: boolean;
  filled?: boolean;
  showDatePickerIcon?: boolean;
  restrictRolePermissions?: (metaData) => boolean;
}
export const GridControlBuilder = (props: GridControlBuilderProps) => {
  const {
    control,
    value,
    lightTheme = false,
    filterOptionsOveride,
    edit,
    label,
    textFiledCustomStyle,
    datePickerLabelStyles,
    menuPaperStyle,
    large,
    filled,
    showDatePickerIcon,
    restrictRolePermissions,
  } = props;
  const { type, filterOptions } = control;
  const [activeValue, setActiveValue] = useState<any>(value);
  const { classes } = useStyles({ fullWidth: props.fullWidth });
  const { t } = useTranslation();
  const date = new Date();

  const handleChange = (prop1?, prop2?) => {
    if (type === gridControlType.textfieldnumber) {
      const onlyNums = prop1.target.value.replace(/[^0-9]/g, '');
      if (String(onlyNums) !== String(activeValue) && onlyNums.length < 10)
        edit?.onValueChanged(edit.rowKey, edit.key, String(onlyNums));
    } else if (type === gridControlType.textarea && prop1.target.value.length < 500) {
      edit?.onValueChanged(edit.rowKey, edit.key, prop1.target.value);
    } else if (type === gridControlType.textfield && prop1.target.value.length < 255) {
      edit?.onValueChanged(edit.rowKey, edit.key, prop1.target.value);
    } else edit?.onValueChanged(edit.rowKey, edit.key, prop1.target.value);
  };

  const handleDateChange = (prop1, prop2) => {
    if (typeof prop1?.preventDefault === 'function') prop1?.preventDefault();
    const v = `${moment(prop2).format('yyyy-MM-DD')}${moment().format('THH:mm:ss')}`;
    setActiveValue(v);
    edit?.onValueChanged(edit.rowKey, edit.key, v);
  };

  const handleCheckboxChange = (prop1, prop2) => {
    setActiveValue(prop2);
    edit?.onValueChanged(edit.rowKey, edit.key, prop2);
  };

  useEffect(() => {
    setActiveValue(value);
  }, [value]);

  return (
    <React.Fragment>
      {type === gridControlType.dropDown ? (
        <AccordionMenu
          filterOptions={filterOptionsOveride || filterOptions || []}
          handleChange={handleChange}
          large={large ? large : false}
          value={value === undefined ? 'N/A' : value}
          color={undefined}
          disabled={!edit?.isEditing || value === undefined}
          inputLabel={label}
          menuPaperStyle={menuPaperStyle ? menuPaperStyle : ''}
        />
      ) : type === gridControlType.fileUpload ? (
        <FormControl variant={filled ? 'filled' : 'outlined'} className={classes.fullWidth}>
          <TextField
            variant={filled ? 'filled' : 'outlined'}
            size="small"
            value={value}
            onChange={handleChange}
            className={classes.textField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <PublishIcon />
                </InputAdornment>
              ),
              classes: {
                input: classes.textFieldInput,
              },
            }}
          />
        </FormControl>
      ) : value === undefined ? (
        <div>{t('search.grid.notAvailable')}</div>
      ) : type === gridControlType.date ? (
        <DateRangePicker
          onChange={event => handleDateChange(event, event.startDate)}
          single={true}
          actionBar={false}
          open={false}
          variant={filled ? 'filled' : 'outlined'}
          isDisabled={edit?.isEditing ? false : true}
          maxWidth={200}
          showIcon={showDatePickerIcon ? true : false}
          isDateIconReq={true}
          clickAway
          placeholder={label}
          labelClass={datePickerLabelStyles ? datePickerLabelStyles : ''}
          trailingIconShow={showDatePickerIcon ? true : false}
          initialDateRange={
            activeValue
              ? {
                  startDate: new Date(activeValue),
                  endDate: new Date(activeValue),
                  label: moment(activeValue).format('MM/DD/YYYY'),
                  useLabelAsValue: activeValue || false,
                }
              : undefined
          }
          minDate={new Date('01-01-1940')}
          definedRanges={[
            {
              label: '1 Day',
              startDate: addDays(date, -1),
              endDate: addDays(date, -1),
              useLabelAsValue: false,
            },
            {
              label: '2 Days',
              startDate: addDays(date, -2),
              endDate: addDays(date, -2),
              useLabelAsValue: false,
            },
            {
              label: '7 Days',
              startDate: addDays(date, -7),
              endDate: addDays(date, -7),
              useLabelAsValue: false,
            },
            {
              label: '20 Days',
              startDate: addDays(date, -20),
              endDate: addDays(date, -20),
              useLabelAsValue: false,
            },
            {
              label: '30 Days',
              startDate: addDays(date, -30),
              endDate: addDays(date, -30),
              useLabelAsValue: false,
            },
            {
              label: '60 Days',
              startDate: addDays(date, -60),
              endDate: addDays(date, -60),
              useLabelAsValue: false,
            },
          ]}
        />
      ) : type === gridControlType.checkbox && ((!edit?.isEditing && value !== null) || edit?.isEditing) ? (
        <>
          <Grid direction="row">
            {label && <Box className={classes.checkBoxLabelStyles}>{label}</Box>}
            <Box style={{ position: 'relative', marginLeft: '2px' }}>
              <Box
                className={`
              ${
                activeValue
                  ? classes.greenCheckboxRoot
                  : (!activeValue && lightTheme) || lightTheme
                  ? classes.lightCheckboxRoot
                  : classes.checkboxRoot
              } ${classes.containmentOuter} ${activeValue ? classes.containmentSelected : ''}`}
              >
                <Checkbox
                  size="small"
                  color="primary"
                  checked={activeValue}
                  disabled={!edit?.isEditing || restrictRolePermissions?.(edit.key)}
                  style={{
                    color:
                      restrictRolePermissions?.(edit?.key) && edit?.isEditing && !activeValue ? '#bdbdbd' : undefined,
                  }}
                  onChange={handleCheckboxChange}
                  className={`
                ${
                  activeValue
                    ? classes.greenCheckbox
                    : (!activeValue && lightTheme) || lightTheme
                    ? classes.lightCheckbox
                    : classes.checkbox
                } ${classes.containment}`}
                />
              </Box>
            </Box>
          </Grid>
        </>
      ) : type === gridControlType.textfield ? (
        <React.Fragment>
          {!edit?.isEditing ? (
            value !== null ? (
              value
            ) : (
              t('search.grid.notAvailable')
            )
          ) : (
            <FormControl variant={filled ? 'filled' : 'outlined'} className={classes.fullWidth}>
              <TextField
                label={label}
                variant={filled ? 'filled' : 'outlined'}
                size="small"
                value={value}
                onChange={handleChange}
                className={classes.textField}
                InputProps={{
                  classes: {
                    input: textFiledCustomStyle ? textFiledCustomStyle : classes.textFieldInput,
                  },
                }}
              />
            </FormControl>
          )}
        </React.Fragment>
      ) : type === gridControlType.textarea ? (
        <React.Fragment>
          {!edit?.isEditing ? (
            value !== null ? (
              value
            ) : (
              t('search.grid.notAvailable')
            )
          ) : (
            <FormControl variant={filled ? 'filled' : 'outlined'} className={classes.fullWidth}>
              <TextField
                label={label}
                variant={filled ? 'filled' : 'outlined'}
                size="small"
                multiline
                value={activeValue}
                onChange={handleChange}
                className={classes.textField}
                InputProps={{
                  classes: {
                    input: textFiledCustomStyle ? textFiledCustomStyle : classes.textFieldInput,
                  },
                }}
              />
            </FormControl>
          )}
        </React.Fragment>
      ) : type === gridControlType.textfieldnumber ? (
        <React.Fragment>
          {!edit?.isEditing ? (
            value !== null ? (
              value
            ) : (
              t('search.grid.notAvailable')
            )
          ) : (
            <FormControl variant={filled ? 'filled' : 'outlined'} className={classes.fullWidth}>
              <TextField
                label={label}
                variant={filled ? 'filled' : 'outlined'}
                size="small"
                value={activeValue}
                onChange={handleChange}
                className={classes.textFieldNumber}
                InputProps={{
                  classes: {
                    input: textFiledCustomStyle ? textFiledCustomStyle : classes.textFieldInput,
                  },
                }}
              />
            </FormControl>
          )}
        </React.Fragment>
      ) : type === gridControlType.dateLabel ? (
        <React.Fragment>
          <Grid direction="row">
            {label && <Box className={classes.checkBoxLabelStyles}>{label}</Box>}
            <Box>
              {value !== null && value !== undefined && value !== 'N/A'
                ? moment(value).format('MM/DD/yyyy')
                : t('search.grid.notAvailable')}
            </Box>
          </Grid>
        </React.Fragment>
      ) : type === gridControlType.label || type === gridControlType.name ? (
        <Grid direction="row">
          {label && <Box className={classes.checkBoxLabelStyles}>{label}</Box>}
          <Box>
            {value !== null && value !== undefined ? (
              <React.Fragment>{value}</React.Fragment>
            ) : (
              <React.Fragment>{t('search.grid.notAvailable')}</React.Fragment>
            )}
          </Box>
        </Grid>
      ) : (
        <div>{t('search.grid.notAvailable')}</div>
      )}
    </React.Fragment>
  );
};
