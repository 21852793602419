import React, { useState } from 'react';
import { FacilityDialog } from '../../../Facility/FacilityDetails/FacilityDialog';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Grid, Skeleton } from 'amn-ui-core';
import { UnitCard } from '../../../Facility/FacilityDetails/FacilityTabs/CustomComponents/UnitCard';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { TruncatedText } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/GeneralTab/Utils';
import { missingField } from 'app/constants';
import LaunchIcon from '@mui/icons-material/Launch';
import { selectUnit } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { ModalDialogBox, isAuthorizedForAddEdit, useUnitStatusInActive } from './unitHelper';
import { selectUser } from 'oidc/user.selectors';
import { usePromiseTracker } from 'react-promise-tracker';
import { useExternalJobQualificationStyles } from './Unit.styles';

const UnitFirstDayInstructions = () => {
  const [isFDIOpen, setIsFDIOpen] = useState(false);
  const { t } = useTranslation();
  const { classes } = useExternalJobQualificationStyles();
  const unitData = useSelector(selectUnit);
  const userData = useSelector(selectUser);
  const isAuthorized = isAuthorizedForAddEdit(userData);
  const isUnitInActive = useUnitStatusInActive();
  const { promiseInProgress: isFacilityUnitDetailsLoading } = usePromiseTracker({
    area: 'get-facility-unit-details',
    delay: 0,
  });
  return (
    <>
      <UnitCard
        title={t('facility.instructionsTab.firstDayInstructions')}
        rightIcon={true}
        showEditIcon={isAuthorized && !isUnitInActive}
        skeletonWidth={153}
        isDataLoading={isFacilityUnitDetailsLoading}
        children={
          <>
            <LayoutGrid direction="row" sx={{ p: '12px' }}>
              {isFacilityUnitDetailsLoading ? (
                <LayoutGridItem xs={11} item>
                  <Grid display={'flex'} flexDirection={'column'} gap={1}>
                    <Skeleton variant="rectangular" width={481} height={13} />
                    <Skeleton variant="rectangular" width={432} height={13} />
                    <Skeleton variant="rectangular" width={456} height={13} />
                    <Skeleton variant="rectangular" width={456} height={13} />
                    <Skeleton variant="rectangular" width={302} height={13} />
                  </Grid>
                </LayoutGridItem>
              ) : (
                <LayoutGridItem xs={11} item>
                  {unitData?.firstDayInstructions ? (
                    <TruncatedText text={unitData?.firstDayInstructions} maxLength={185} />
                  ) : (
                    missingField
                  )}
                </LayoutGridItem>
              )}
              <Box sx={{ mt: '10px', ml: '10px' }}>
                {unitData?.firstDayInstructions && (
                  <CustomTooltip tooltipContent={t('facility.tooltip.viewFullDetails')}>
                    <LaunchIcon onClick={() => setIsFDIOpen(true)} className={`${classes.facilityGeneralLaunchIcon}`} />
                  </CustomTooltip>
                )}
              </Box>
            </LayoutGrid>
          </>
        }
      />

      {isFDIOpen && (
        <FacilityDialog
          open={isFDIOpen}
          children={<FirstDayInstructions />}
          handleClose={() => setIsFDIOpen(false)}
          headerTitle={t('facility.instructionsTab.firstDayInstructions')}
        />
      )}
    </>
  );
};

/**
 *  Component to render First Day Instructions
 */
const FirstDayInstructions = () => {
  const unitData = useSelector(selectUnit);

  return <ModalDialogBox data={unitData?.firstDayInstructions} />;
};

export default UnitFirstDayInstructions;
