import React from 'react';
import { ErrorAction } from 'app/components/Common/ErrorPage/ErrorAction';
import { TableCell as OrderInfoCell, Table, RowTitle, Loading } from '../CommonTableComponents/CommonTableComponents';

export const OrderInfoTable = ({ ...props }) => {
  return (
    <Table>
      <tbody>{props.children}</tbody>
    </Table>
  );
};

interface OrderInfoRowProps {
  title: string;
  orderCell: string | JSX.Element;
  loading?: boolean;
  [key: string]: any;
}
export const OrderInfoRow = ({
  title,
  orderCell,
  loading,
  transparentTop = false,
  transparentBottom = false,
  hasError = false,
  onTryAgain,
  ...props
}: OrderInfoRowProps) => {
  const cellStyle = {
    borderTop: transparentTop ? 'transparent' : undefined,
    borderBottom: transparentBottom ? 'transparent' : undefined,
    paddingTop: transparentTop ? 0 : undefined,
    paddingBottom: transparentBottom ? 0 : undefined,
  };

  return (
    <tr {...props}>
      <OrderInfoCell style={{ width: '20%', ...cellStyle }}>
        <RowTitle variant="body1">{title}</RowTitle>
      </OrderInfoCell>
      {hasError && !loading ? (
        <OrderInfoCell colSpan="2">
          <ErrorAction onTryAgain={onTryAgain} />
        </OrderInfoCell>
      ) : (
        <>
          <OrderInfoCell style={{ width: '60%', ...cellStyle }}>{loading ? <Loading /> : orderCell}</OrderInfoCell>
        </>
      )}
    </tr>
  );
};
