import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { ChannelType } from '@AMIEWEB/Notification/Constants';
import { selectCommunicationLimitsDialog } from 'store/redux-store/notification/notification.selector';

interface CommunicationLimitsDialogProps {
  handleEmailRetry: () => void;
  handleSMSRetry: () => void;
}

const useStyles = makeStyles()({
  dialogContainer: {
    width: '460',
    maxWidth: '460px',
  },
  dialogHeading: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '10px',
    paddingTop: '32px',
  },
  actionStatement: {
    paddingTop: '25px',
  },
});

export const CommunicationLimitsDialog = ({ handleEmailRetry, handleSMSRetry }: CommunicationLimitsDialogProps) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const dialogOpen = useSelector(selectCommunicationLimitsDialog);

  return (
    <GenericDialog
      open={dialogOpen.open}
      classes={{
        paper: classes.dialogContainer,
      }}
      dialogActions={[
        {
          text: 'Cancel',
          variant: 'text',
          onClick: () =>
            dispatch(
              notificationDataActions.setCommunicationLimitsDialogOpen({
                open: false,
                key: '',
              }),
            ),
        },
        {
          text: 'Yes',
          variant: 'contained',
          onClick: () => {
            dialogOpen?.key === ChannelType.email
              ? handleEmailRetry()
              : dialogOpen?.key === ChannelType.sms && handleSMSRetry();
            dispatch(
              notificationDataActions.setCommunicationLimitsDialogOpen({
                open: false,
                key: '',
              }),
            );
          },
        },
      ]}
    >
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.dialogHeading}>{'An error occured'}</div>
          <div>{'The Process to check the candidate communication limits could not be completed.'}</div>
          <div className={classes.actionStatement}>{'Do you want to retry?'}</div>
        </Grid>
      </Grid>
    </GenericDialog>
  );
};
