export interface IOrderRateElement {
  offContractId: null | string;
  rateElementId: string;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  rateElementNameId: number;
  rateElementName: string;
  disciplineId: number;
  disciplineName: null | string;
  disciplineAbbreviation: null | string;
  specialtyId: number;
  specialtyName: null | string;
  specialtyAbbreviation: null;
  subSpecialtyId: number;
  subSpecialtyName: null | string;
  subSpecialtyAbbreviation: null;
  shiftId: number | null;
  value: number;
  valueMax: null;
  statusId: number | null;
  documentIds: any[];
  reasonId: null;
  reason: null;
  dateApproved: null;
  approvedBy: null;
  approvedById: null;
  dateCreated: Date;
  createdBy: string;
  createdById: number;
  createdAt: null;
  dateLastUpdated: Date;
  lastUpdatedBy: string;
  lastUpdatedById: number;
}

export enum ReqOrderFieldNames {
  UNIT_NAME = 'UNIT_NAME',
  DISCIPLINE = 'DISCIPLINE',
  SPECIALTY = 'SPECIALTY',
  EXT_JOB_DESCRIPTION = 'EXT_JOB_DESCRIPTION',
  REGION = 'REGION',
  LICENSE = 'LICENSE',
  CERTIFICATION = 'CERTIFICATION',
  POSITIONS_AVAILABLE = 'POSITIONS_AVAILABLE',
  ORDER_TYPE = 'ORDER_TYPE',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  INTERNAL_NOTES = 'INTERNAL_NOTES',
  VIRTUAL_RATING = 'VIRTUAL_RATING',
}
