import { Editor } from '@tinymce/tinymce-react';
import { Box } from 'amn-ui-core';
import { base64DocumentToURL } from 'app/helpers/fileHelper';
import React, { useEffect } from 'react';

export const PreviewScreen = ({ htmlVersion, ...props }) =>
  htmlVersion ? <PreviewHtmlScreen {...props} /> : <PreviewPdfScreen {...props} />;

const PreviewHtmlScreen = ({ document, ...props }) => (
  <Box sx={{ '& .tox-edit-area__iframe': { pointerEvents: props.disablePointerEvents ? 'none' : undefined } }}>
    <Editor
      apiKey={globalThis?.app?.env?.REACT_APP_TINYMCE_API_KEY}
      disabled
      init={{
        branding: false,
        height: 760,
        menubar: false,
        statusbar: false,
        toolbar: false,
        content_style:
          'body { background-color: rgb(251, 251, 251); border:none; padding: 1rem 1rem 0; margin: 0; min-height: 97.8vh } html { margin: 0px 100px; background-color: lightgrey; ::-webkit-scrollbar { opacity: 0; background: transparent; width: 14px; } ::-webkit-scrollbar-button { display: none; } ::-webkit-scrollbar-thumb { border-radius: 9999px; border: 3px solid rgba(0,0,0,0.0); background-color: #C7C7C7; background-clip: padding-box; } ::-webkit-scrollbar-track: { background: transparent; }  }',
      }}
      value={document}
    />
  </Box>
);

const PreviewPdfScreen = ({ document, ...props }) => {
  const [data, setData] = React.useState(null);

  useEffect(() => {
    const { url, dispose } = base64DocumentToURL(document, {
      fileType: 'application/pdf',
    });
    setData(url);
    return () => {
      dispose();
    };
  }, [document]);

  return data ? (
    <object
      aria-label="placement-client-confirmation-document-object"
      title={'test'}
      data={`${data}#toolbar=0`}
      style={{ pointerEvents: props.disablePointerEvents ? 'none' : undefined }}
    />
  ) : null;
};
