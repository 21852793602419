import { TextOverFlow } from '@AMIEWEB/Common/Textfield/TextOverFlow';
import { missingField } from 'app/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { KeyValueRow } from '../TableComponents/KeyValueTable/KeyValueRow';
import { ExtEditTarget, ExtensionEditWrapper } from './ExtensionEditIcon';

export const ExtNotesRow = ({ extContainerIndex, extensionStatusId, notes }) => {
  const { t } = useTranslation();

  return (
    <KeyValueRow
      id="placement-ext-status-row"
      rowTitle={t('placement.profile.summary.extension.notes')}
      loading={false}
    >
      <ExtensionEditWrapper
        target={ExtEditTarget.notes}
        extContainerIndex={extContainerIndex}
        extensionStatusId={extensionStatusId}
      >
        <TextOverFlow value={notes ? notes : missingField} maxHeight={72} />
      </ExtensionEditWrapper>
    </KeyValueRow>
  );
};
