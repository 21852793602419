import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ICreateUnit,
  IEditUnit,
  IOrderActionButton,
  IOrderCreationStep,
  IRequrementType,
} from './helpers/rateCard/types';
import { OrderFacilityDetails } from 'app/models/Facility/FacilityDetails';
import { NewUnit, QualificationType, SkillCheckList, SkillCheckListChip } from 'app/models/Unit/CreateUnit';
import {
  IBestBets,
  IOrderDetails,
  ITypeAheadOption,
  IValidPlacementDate,
  NewUIOrder,
} from 'app/models/Orders/OrderDetails';
import { ReqOrderFieldNames } from './types';
import { IGroupedTypeAheadOption } from '@AMIEWEB/NewOrder/types';

export interface IOrderInitialState {
  steps: IOrderCreationStep;
  facilityId: number | null | undefined;
  facilityData: OrderFacilityDetails | null | undefined;
  newOrder: NewUIOrder | null | undefined;
  newUnit: ICreateUnit | null | undefined;
  editUnit: IEditUnit | null | undefined;
  orderDetails: IOrderDetails | null | undefined;
  stage: string;
  finalStage: string;
  actionButtons: IOrderActionButton[] | null | undefined;
  status: 'idle' | 'loading' | 'succeeded' | 'failed' | 'proceedToParent';
  error: string | null | undefined;
  message: string | null | undefined;
  isMandatoryFieldsFilled: boolean | true;
  incompleteReqFields: ReqOrderFieldNames[] | null | undefined;
  isError: boolean | false;
  orderId: number | null | undefined;
  certificationsFlagDecision: boolean | null | undefined;
  isValidPlacementDate: IValidPlacementDate;
  orderPriorities: ITypeAheadOption[];
  travelExperiences: IGroupedTypeAheadOption[];
  bestBets: BestBets[];
  internalNotesFlag: boolean;
}

export const initialState: IOrderInitialState = {
  status: 'loading',
  error: null,
  message: null,
  isError: false,
  isMandatoryFieldsFilled: true,
  facilityId: undefined,
  facilityData: null,
  orderId: null,
  steps: {
    selectUnit: {
      availableFacilityUnits: [],
      hoverdFacilityUnit: undefined,
      selectedFacilityUnit: undefined,
      selectedOrderUnit: undefined,
      allowNext: false,
      editMode: null,
      showEditModeDialog: false,
      proceedToUnitCreation: false,
      createdUnitId: undefined,
      editUnitId: undefined,
    },
    editOrderRequrement: {
      editMode: null,
      selectedOrderUnit: undefined,
      isMandatoryFieldsFilled: true,
      allowNext: false,
      requirementTypes: {
        certifications: [],
        licenses: [],
        states: [],
      },
      lookUps: [],
      disciplineOpts: [],
      specialtyOpts: [],
      subSpecialtyOpts: [],
      certificationRequrements: undefined,
      certificationRequrementsMaster: undefined,
      certificationSearchQuery: '',
      licenseSearchQuery: '',
      loadingCertificationRequrements: undefined,
      loadingLicenseRequirements: undefined,
      skillCheckListRequrements: undefined,
      skillCheckListRequrementsMaster: undefined,
      skillCheckListRequrementsChips: [],
      skillCheckListSearchQuery: '',
      loadingSkillCheckListRequrements: undefined,
    },
    editOrderDetails: {
      editMode: null,
      selectedOrderUnit: undefined,
      isMandatoryFieldsFilled: true,
      allowNext: false,
      requirementTypes: {
        certifications: [],
        licenses: [],
        states: [],
      },
      lookUps: [],
      disciplineOpts: [],
      specialtyOpts: [],
      subSpecialtyOpts: [],
      certificationRequrements: undefined,
      certificationRequrementsMaster: undefined,
      certificationSearchQuery: '',
      licenseSearchQuery: '',
      loadingCertificationRequrements: undefined,
      loadingLicenseRequirements: undefined,
      skillCheckListRequrements: undefined,
      skillCheckListRequrementsMaster: undefined,
      skillCheckListRequrementsChips: [],
      skillCheckListSearchQuery: '',
      loadingSkillCheckListRequrements: undefined,
    },
    createUnitDetails: {
      editMode: null,
      selectedOrderUnit: undefined,
      isMandatoryFieldsFilled: true,
      allowNext: false,
      requirementTypes: {
        certifications: [],
        licenses: [],
        states: [],
      },
      lookUps: [],
      disciplineOpts: [],
      specialtyOpts: [],
      subSpecialtyOpts: [],
      certificationRequrements: undefined,
      certificationRequrementsMaster: undefined,
      certificationSearchQuery: '',
      licenseSearchQuery: '',
      loadingCertificationRequrements: undefined,
      loadingLicenseRequirements: undefined,
      skillCheckListRequrements: undefined,
      skillCheckListRequrementsMaster: undefined,
      skillCheckListRequrementsChips: [],
      skillCheckListSearchQuery: '',
      loadingSkillCheckListRequrements: undefined,
      isUnitNameError: false,
    },
    editUnitDetails: {
      editMode: null,
      isRememebrCheckboxSelected: false,
      selectedOrderUnit: undefined,
      isMandatoryFieldsFilled: true,
      allowNext: false,
      requirementTypes: {
        certifications: [],
        licenses: [],
        states: [],
      },
      lookUps: [],
      disciplineOpts: [],
      specialtyOpts: [],
      subSpecialtyOpts: [],
      certificationRequrements: undefined,
      certificationRequrementsMaster: undefined,
      certificationSearchQuery: '',
      licenseSearchQuery: '',
      loadingCertificationRequrements: undefined,
      loadingLicenseRequirements: undefined,
      skillCheckListRequrements: undefined,
      skillCheckListRequrementsMaster: undefined,
      skillCheckListRequrementsChips: [],
      skillCheckListSearchQuery: '',
      loadingSkillCheckListRequrements: undefined,
      isUnitNameError: false,
    },
    orderInformation: {
      selectedOrderUnit: undefined,
      allowNext: false,
      isMandatoryFieldsFilled: true,
    },
    orderBillRate: {
      selectedOrderUnit: undefined,
      allowNext: false,
    },
    orderPreview: {
      selectedOrderUnit: undefined,
      allowNext: false,
    },
  },
  stage: 'selectUnit',
  finalStage: 'selectUnit',
  actionButtons: [],
  newOrder: undefined,
  orderDetails: undefined,
  newUnit: undefined,
  editUnit: undefined,
  certificationsFlagDecision: undefined,
  incompleteReqFields: [],
  isValidPlacementDate: {
    startDate: true,
    endDate: true,
  },
  orderPriorities: [{ value: '', name: '' }],
  bestBets: [{ value: '', name: '' }],
  internalNotesFlag: false,
};

export const orderCreationSlice = createSlice({
  name: 'orderCreation',
  initialState,
  reducers: {
    updateMessage: {
      reducer(state, action: any) {
        state.message = action.payload;
      },
      prepare(message) {
        return {
          payload: message,
        };
      },
    },
    updateStatus: {
      reducer(state, action: any) {
        state.status = action.payload;
      },
      prepare(status) {
        return {
          payload: status,
        };
      },
    },
    updateOrderId: {
      reducer(state, action: any) {
        state.orderId = action.payload;
      },
      prepare(orderId) {
        return {
          payload: orderId,
        };
      },
    },
    updateError: {
      reducer(state, action: any) {
        state.isError = action.payload.isError;
        if (action.payload.isError) {
          state.error = action.payload.error;
        } else {
          state.error = null;
        }
      },
      prepare(isError: boolean | false, error: string | null | undefined) {
        return {
          payload: {
            error: error,
            isError: isError,
          },
        };
      },
    },
    setFacilityId: {
      reducer(state, action: any) {
        state.facilityId = action.payload;
      },
      prepare(facilityId: number | null | undefined) {
        return {
          payload: facilityId,
        };
      },
    },
    setFacilityDetails: {
      reducer(state, action: any) {
        state.facilityData = action.payload;
      },
      prepare(facilityDetails: OrderFacilityDetails | null | undefined) {
        return {
          payload: facilityDetails,
        };
      },
    },
    setFacilityUnits: {
      reducer(state, action: any) {
        state.steps.selectUnit.availableFacilityUnits = action.payload;
      },
      prepare(facilityUnits: NewUnit[] | null | undefined) {
        return {
          payload: facilityUnits,
        };
      },
    },
    setHoverdFacilityUnit: {
      reducer(state, action: any) {
        state.steps.selectUnit.hoverdFacilityUnit = action.payload;
      },
      prepare(facilityUnit: NewUnit | null | undefined) {
        return {
          payload: facilityUnit,
        };
      },
    },
    setSelectedFacilityUnit: {
      reducer(state, action: any) {
        state.steps.selectUnit.selectedFacilityUnit = action.payload;
      },
      prepare(facilityUnit: NewUnit | null | undefined) {
        return {
          payload: facilityUnit,
        };
      },
    },
    setOrderUnit: {
      reducer(state, action: any) {
        state.steps.selectUnit.selectedFacilityUnit = action.payload;
        state.steps.selectUnit.selectedOrderUnit = action.payload;
        state.steps.editOrderRequrement.selectedOrderUnit = action.payload;
        state.steps.editOrderDetails.selectedOrderUnit = action.payload;
        state.steps.createUnitDetails.selectedOrderUnit = action.payload;
        state.steps.editUnitDetails.selectedOrderUnit = action.payload;
        state.steps.orderInformation.selectedOrderUnit = action.payload;
        state.steps.orderBillRate.selectedOrderUnit = action.payload;
        state.steps.orderPreview.selectedOrderUnit = action.payload;
      },
      prepare(orderUnit: NewUnit | null | undefined) {
        return {
          payload: orderUnit,
        };
      },
    },
    setSelectedUnit: {
      reducer(state, action: any) {
        state.steps.selectUnit.selectedOrderUnit = action.payload;
        state.steps.editOrderRequrement.selectedOrderUnit = action.payload;
        state.steps.editOrderDetails.selectedOrderUnit = action.payload;
        state.steps.createUnitDetails.selectedOrderUnit = action.payload;
        state.steps.editUnitDetails.selectedOrderUnit = action.payload;
        state.steps.orderInformation.selectedOrderUnit = action.payload;
        state.steps.orderBillRate.selectedOrderUnit = action.payload;
        state.steps.orderPreview.selectedOrderUnit = action.payload;
      },
      prepare(orderUnit: NewUnit | null | undefined) {
        return {
          payload: orderUnit,
        };
      },
    },
    setOrder: {
      reducer(state, action: any) {
        state.newOrder = action.payload;
      },
      prepare(newOrder: NewUIOrder | null | undefined) {
        return {
          payload: newOrder,
        };
      },
    },
    setOrderDetails: {
      reducer(state, action: any) {
        state.orderDetails = action.payload;
      },
      prepare(newOrder: IOrderDetails | null | undefined) {
        return {
          payload: newOrder,
        };
      },
    },
    setCreateUnitDetails: {
      reducer(state, action: any) {
        state.newUnit = action.payload;
      },
      prepare(newUnit: ICreateUnit | null | undefined) {
        return {
          payload: newUnit,
        };
      },
    },
    setPlacementValidDate: {
      reducer(state, action: any) {
        state.isValidPlacementDate = action.payload;
      },
      prepare(isValidPlacementDate: IValidPlacementDate) {
        return {
          payload: isValidPlacementDate,
        };
      },
    },
    setUpdateUnitDetails: {
      reducer(state, action: any) {
        state.editUnit = action.payload;
      },
      prepare(newUnit: IEditUnit | null | undefined) {
        return {
          payload: newUnit,
        };
      },
    },
    setCreatedUnitId: {
      reducer(state, action: any) {
        if (!!state?.steps?.selectUnit) {
          state.steps.selectUnit.createdUnitId = action.payload;
        }
      },
      prepare(createdUnitId: number | null | undefined) {
        return {
          payload: createdUnitId,
        };
      },
    },
    setEditedUnitId: {
      reducer(state, action: any) {
        if (!!state?.steps?.selectUnit) {
          state.steps.selectUnit.editUnitId = action.payload;
        }
      },
      prepare(editUnitId: number | null | undefined) {
        return {
          payload: editUnitId,
        };
      },
    },
    setProceedToUnitCreation: {
      reducer(state, action: any) {
        if (!!state?.steps?.selectUnit) {
          state.steps.selectUnit.proceedToUnitCreation = action.payload;
        }
      },
      prepare(proceedToUnitCreation: boolean | false) {
        return {
          payload: proceedToUnitCreation,
        };
      },
    },
    setOrderQualification: {
      reducer(state, action: any) {
        state.newOrder.qualification = action.payload;
      },
      prepare(qualification: QualificationType) {
        return {
          payload: qualification,
        };
      },
    },
    setEditOrderRequrementMode: {
      reducer(state, action: any) {
        state.steps.selectUnit.editMode = action.payload;
        state.steps.editOrderRequrement.editMode = action.payload;
        state.steps.editOrderDetails.editMode = action.payload;
        state.steps.createUnitDetails.editMode = action.payload;
        state.steps.editUnitDetails.editMode = action.payload;
      },
      prepare(mode: string) {
        return {
          payload: mode,
        };
      },
    },
    setEditModeDialogMode: {
      reducer(state, action: any) {
        state.steps.selectUnit.showEditModeDialog = action.payload;
      },
      prepare(show: boolean | false) {
        return {
          payload: show,
        };
      },
    },
    setEditUnitEditMode: {
      reducer(state, action: any) {
        state.steps.selectUnit.editMode = action.payload;
        state.steps.editOrderRequrement.editMode = action.payload;
        state.steps.editOrderDetails.editMode = action.payload;
        state.steps.createUnitDetails.editMode = action.payload;
        state.steps.editUnitDetails.editMode = action.payload;
        state.editUnit.standardizedRequirements = action.payload === 'standardize';
        state.editUnit.createdByVersion = action.payload === 'standardize' ? '2.0' : null;
      },
      prepare(mode: string) {
        return {
          payload: mode,
        };
      },
    },
    setOrderRequrementTypes: {
      reducer(state, action: any) {
        state.steps.editOrderRequrement.requirementTypes = action.payload;
        state.steps.editOrderDetails.requirementTypes = action.payload;
        state.steps.createUnitDetails.requirementTypes = action.payload;
        state.steps.editUnitDetails.requirementTypes = action.payload;
      },
      prepare(requirementTypes: any) {
        return {
          payload: requirementTypes,
        };
      },
    },
    setOrderRequrementLookUps: {
      reducer(state, action: any) {
        state.steps.editOrderRequrement.lookUps = action.payload;
        state.steps.editOrderDetails.lookUps = action.payload;
        state.steps.createUnitDetails.lookUps = action.payload;
        state.steps.editUnitDetails.lookUps = action.payload;
      },
      prepare(lookUps: any[]) {
        return {
          payload: lookUps,
        };
      },
    },
    setCertificationRequrements: {
      reducer(state, action: any) {
        state.steps.editOrderRequrement.certificationRequrements = action.payload;
        state.steps.editOrderRequrement.certificationRequrementsMaster = action.payload;

        state.steps.editOrderDetails.certificationRequrements = action.payload;
        state.steps.editOrderDetails.certificationRequrementsMaster = action.payload;

        state.steps.createUnitDetails.certificationRequrements = action.payload;
        state.steps.createUnitDetails.certificationRequrementsMaster = action.payload;

        state.steps.editUnitDetails.certificationRequrements = action.payload;
        state.steps.editUnitDetails.certificationRequrementsMaster = action.payload;
      },
      prepare(certificationRequrements: IRequrementType[] | []) {
        return {
          payload: certificationRequrements,
        };
      },
    },
    setCertificationRequrementsSearchResults: {
      reducer(state, action: any) {
        state.steps.editOrderRequrement.certificationRequrements = action.payload;
        state.steps.editOrderDetails.certificationRequrements = action.payload;
        state.steps.createUnitDetails.certificationRequrements = action.payload;
        state.steps.editUnitDetails.certificationRequrements = action.payload;
      },
      prepare(certificationRequrements: IRequrementType[] | []) {
        return {
          payload: certificationRequrements,
        };
      },
    },
    setLicenseSearchQuery: {
      reducer(state, action: any) {
        state.steps.editOrderRequrement.licenseSearchQuery = action.payload;
        state.steps.editOrderDetails.licenseSearchQuery = action.payload;
        state.steps.createUnitDetails.licenseSearchQuery = action.payload;
        state.steps.editUnitDetails.licenseSearchQuery = action.payload;
      },
      prepare(licenseSearchQuery: string | null | undefined) {
        return {
          payload: licenseSearchQuery,
        };
      },
    },
    setCertificationSearchQuery: {
      reducer(state, action: any) {
        state.steps.editOrderRequrement.certificationSearchQuery = action.payload;
        state.steps.editOrderDetails.certificationSearchQuery = action.payload;
        state.steps.createUnitDetails.certificationSearchQuery = action.payload;
        state.steps.editUnitDetails.certificationSearchQuery = action.payload;
      },
      prepare(certificationSearchQuery: string | null | undefined) {
        return {
          payload: certificationSearchQuery,
        };
      },
    },
    setSkillCheckListSearchQuery: {
      reducer(state, action: any) {
        state.steps.editOrderRequrement.skillCheckListSearchQuery = action.payload;
        state.steps.editOrderDetails.skillCheckListSearchQuery = action.payload;
        state.steps.createUnitDetails.skillCheckListSearchQuery = action.payload;
        state.steps.editUnitDetails.skillCheckListSearchQuery = action.payload;
      },
      prepare(skillCheckListSearchQuery: string | null | undefined) {
        return {
          payload: skillCheckListSearchQuery,
        };
      },
    },
    setLoadingLicenseRequirementsStatus: {
      reducer(state, action: any) {
        state.steps.editOrderRequrement.loadingLicenseRequirements = action.payload;
        state.steps.editOrderDetails.loadingLicenseRequirements = action.payload;
        state.steps.createUnitDetails.loadingLicenseRequirements = action.payload;
        state.steps.editUnitDetails.loadingLicenseRequirements = action.payload;
      },
      prepare(loadingCertificationRequrements: boolean | false | undefined) {
        return {
          payload: loadingCertificationRequrements,
        };
      },
    },
    setLoadingCertificationRequrementsStatus: {
      reducer(state, action: any) {
        state.steps.editOrderRequrement.loadingCertificationRequrements = action.payload;
        state.steps.editOrderDetails.loadingCertificationRequrements = action.payload;
        state.steps.createUnitDetails.loadingCertificationRequrements = action.payload;
        state.steps.editUnitDetails.loadingCertificationRequrements = action.payload;
      },
      prepare(loadingCertificationRequrements: boolean | false | undefined) {
        return {
          payload: loadingCertificationRequrements,
        };
      },
    },
    setLoadingSkillCheckListRequrementsStatus: {
      reducer(state, action: any) {
        state.steps.editOrderRequrement.loadingSkillCheckListRequrements = action.payload;
        state.steps.editOrderDetails.loadingSkillCheckListRequrements = action.payload;
        state.steps.createUnitDetails.loadingSkillCheckListRequrements = action.payload;
        state.steps.editUnitDetails.loadingSkillCheckListRequrements = action.payload;
      },
      prepare(loadingSkillCheckListRequrements: boolean | false | undefined) {
        return {
          payload: loadingSkillCheckListRequrements,
        };
      },
    },
    setSkillCheckListRequrements: {
      reducer(state, action: any) {
        state.steps.editOrderRequrement.skillCheckListRequrements = action.payload;
        state.steps.editOrderRequrement.skillCheckListRequrementsMaster = action.payload;

        state.steps.editOrderDetails.skillCheckListRequrements = action.payload;
        state.steps.editOrderDetails.skillCheckListRequrementsMaster = action.payload;

        state.steps.createUnitDetails.skillCheckListRequrements = action.payload;
        state.steps.createUnitDetails.skillCheckListRequrementsMaster = action.payload;

        state.steps.editUnitDetails.skillCheckListRequrements = action.payload;
        state.steps.editUnitDetails.skillCheckListRequrementsMaster = action.payload;
      },
      prepare(skillCheckListRequrements: SkillCheckList[] | []) {
        return {
          payload: skillCheckListRequrements,
        };
      },
    },
    setSkillCheckListMasterRequrements: {
      reducer(state, action: any) {
        state.steps.editOrderRequrement.skillCheckListRequrementsMaster = action.payload;
        state.steps.editOrderDetails.skillCheckListRequrementsMaster = action.payload;
        state.steps.createUnitDetails.skillCheckListRequrementsMaster = action.payload;
        state.steps.editUnitDetails.skillCheckListRequrementsMaster = action.payload;
      },
      prepare(skillCheckListRequrements: SkillCheckList[] | []) {
        return {
          payload: skillCheckListRequrements,
        };
      },
    },
    setSkillCheckListRequrementsSearchResults: {
      reducer(state, action: any) {
        state.steps.editOrderRequrement.skillCheckListRequrements = action.payload;
        state.steps.editOrderDetails.skillCheckListRequrements = action.payload;
        state.steps.createUnitDetails.skillCheckListRequrements = action.payload;
        state.steps.editUnitDetails.skillCheckListRequrements = action.payload;
      },
      prepare(skillCheckListRequrements: SkillCheckList[] | []) {
        return {
          payload: skillCheckListRequrements,
        };
      },
    },
    setSkillCheckListRequrementsChipsResults: {
      reducer(state, action: any) {
        state.steps.editOrderRequrement.skillCheckListRequrementsChips = action.payload;
        state.steps.editOrderDetails.skillCheckListRequrementsChips = action.payload;
        state.steps.createUnitDetails.skillCheckListRequrementsChips = action.payload;
        state.steps.editUnitDetails.skillCheckListRequrementsChips = action.payload;
      },
      prepare(skillCheckListRequrementsChips: SkillCheckListChip[] | []) {
        return {
          payload: skillCheckListRequrementsChips,
        };
      },
    },
    setEditOrderRequrementMandatoryFieldsStatus: {
      reducer(state, action: any) {
        state.steps.editOrderRequrement.isMandatoryFieldsFilled = action.payload;
        state.steps.editOrderDetails.isMandatoryFieldsFilled = action.payload;
        state.steps.createUnitDetails.isMandatoryFieldsFilled = action.payload;
        state.steps.editUnitDetails.isMandatoryFieldsFilled = action.payload;
        state.isMandatoryFieldsFilled = action.payload;
      },
      prepare(mode: boolean | true) {
        return {
          payload: mode,
        };
      },
    },
    setEditOrderInformationMandatoryFieldsStatus: {
      reducer(state, action: any) {
        state.steps.orderInformation.isMandatoryFieldsFilled = action.payload;
        state.isMandatoryFieldsFilled = action.payload;
      },
      prepare(mode: boolean | true) {
        return {
          payload: mode,
        };
      },
    },
    setMandatoryFieldsStatus: {
      reducer(state, action: any) {
        state.isMandatoryFieldsFilled = action.payload;
      },
      prepare(mode: boolean | true) {
        return {
          payload: mode,
        };
      },
    },
    setUnitNameErrorStatus: {
      reducer(state, action: any) {
        state.steps.createUnitDetails.isUnitNameError = action.payload;
      },
      prepare(status: boolean | true) {
        return {
          payload: status,
        };
      },
    },
    setEditUnitNameErrorStatus: {
      reducer(state, action: any) {
        state.steps.editUnitDetails.isUnitNameError = action.payload;
      },
      prepare(status: boolean | true) {
        return {
          payload: status,
        };
      },
    },
    setActionButtons: {
      reducer(state, action: any) {
        state.actionButtons = action.payload;
      },
      prepare(actionButtons: IOrderActionButton[] | null | undefined) {
        return {
          payload: actionButtons,
        };
      },
    },
    setMiscellaneousData: {
      reducer(state, action: any) {
        state.steps.editOrderRequrement.disciplineOpts = action.payload.disciplineOpts;
        state.steps.editOrderRequrement.specialtyOpts = action.payload.specialtyOpts;
        state.steps.editOrderRequrement.subSpecialtyOpts = action.payload.subSpecialtyOpts;
        state.steps.editOrderRequrement.divisionDisciplineOpts = action.payload.divisionDisciplineOpts;

        state.steps.editOrderDetails.disciplineOpts = action.payload.disciplineOpts;
        state.steps.editOrderDetails.specialtyOpts = action.payload.specialtyOpts;
        state.steps.editOrderDetails.subSpecialtyOpts = action.payload.subSpecialtyOpts;
        state.steps.editOrderDetails.divisionDisciplineOpts = action.payload.divisionDisciplineOpts;

        state.steps.createUnitDetails.disciplineOpts = action.payload.disciplineOpts;
        state.steps.createUnitDetails.specialtyOpts = action.payload.specialtyOpts;
        state.steps.createUnitDetails.subSpecialtyOpts = action.payload.subSpecialtyOpts;
        state.steps.createUnitDetails.divisionDisciplineOpts = action.payload.divisionDisciplineOpts;

        state.steps.editUnitDetails.disciplineOpts = action.payload.disciplineOpts;
        state.steps.editUnitDetails.specialtyOpts = action.payload.specialtyOpts;
        state.steps.editUnitDetails.subSpecialtyOpts = action.payload.subSpecialtyOpts;
        state.steps.editUnitDetails.divisionDisciplineOpts = action.payload.divisionDisciplineOpts;
      },
      prepare(
        disciplineOpts: ITypeAheadOption[],
        specialtyOpts: ITypeAheadOption[],
        subSpecialtyOpts: ITypeAheadOption[],
        divisionDisciplineOpts?: any[],
      ) {
        return {
          payload: {
            disciplineOpts,
            specialtyOpts,
            subSpecialtyOpts,
            divisionDisciplineOpts,
          },
        };
      },
    },
    setCurrentStage: {
      reducer(state, action: any) {
        state.stage = action.payload;
      },
      prepare(activeStage: string) {
        return {
          payload: activeStage,
        };
      },
    },
    setFinalStage: {
      reducer(state, action: any) {
        state.finalStage = action.payload;
      },
      prepare(activeStage: string) {
        return {
          payload: activeStage,
        };
      },
    },
    updateOrderType: {
      reducer(state, action: any) {
        if (state.newOrder) {
          state.newOrder.type = action.payload;
        }
      },
      prepare(orderType: string) {
        return {
          payload: orderType,
        };
      },
    },
    setCertificationsFlagDecision: {
      reducer(state, action: any) {
        state.certificationsFlagDecision = action.payload;
      },
      prepare(certificationsFlagDecision: boolean) {
        return {
          payload: certificationsFlagDecision,
        };
      },
    },
    setIncompleteReqFields: {
      reducer(state, action: any) {
        state.incompleteReqFields = action.payload;
      },
      prepare(incompleteReqFields: ReqOrderFieldNames[] | null | undefined) {
        return {
          payload: incompleteReqFields,
        };
      },
    },
    setInternalNotesFlag(state, action: PayloadAction<boolean>) {
      state.internalNotesFlag = action.payload;
    },
    orderCreationReset() {
      return initialState;
    },
    setOrderPriorityTypes(state, action: PayloadAction<ITypeAheadOption[]>) {
      state.orderPriorities = action.payload;
    },
    setBestBetsOptions(state, action: PayloadAction<IBestBets[]>) {
      state.bestBets = action.payload;
    },
    setTravelExperienceOptions(state, action: PayloadAction<IGroupedTypeAheadOption[]>) {
      state.travelExperiences = action.payload;
    },
  },
});

export const {
  updateMessage,
  updateStatus,
  updateOrderId,
  updateError,
  setFacilityId,
  setFacilityDetails,
  setFacilityUnits,
  setHoverdFacilityUnit,
  setSelectedFacilityUnit,
  setOrderUnit,
  setSelectedUnit,
  setOrder,
  setOrderDetails,
  setCreateUnitDetails,
  setUpdateUnitDetails,
  setCreatedUnitId,
  setEditedUnitId,
  setProceedToUnitCreation,
  setOrderQualification,
  setEditOrderRequrementMode,
  setEditUnitEditMode,
  setEditModeDialogMode,
  setOrderRequrementTypes,
  setOrderRequrementLookUps,
  setCertificationRequrements,
  setCertificationRequrementsSearchResults,
  setCertificationSearchQuery,
  setLicenseSearchQuery,
  setLoadingCertificationRequrementsStatus,
  setSkillCheckListRequrements,
  setSkillCheckListMasterRequrements,
  setSkillCheckListRequrementsSearchResults,
  setSkillCheckListRequrementsChipsResults,
  setSkillCheckListSearchQuery,
  setLoadingSkillCheckListRequrementsStatus,
  setEditOrderRequrementMandatoryFieldsStatus,
  setEditOrderInformationMandatoryFieldsStatus,
  setMandatoryFieldsStatus,
  setUnitNameErrorStatus,
  setEditUnitNameErrorStatus,
  setActionButtons,
  setMiscellaneousData,
  setCurrentStage,
  setFinalStage,
  orderCreationReset,
} = orderCreationSlice.actions;

export const { reducer: orderCreationReducer, name: orderCreationSliceKey } = orderCreationSlice;
