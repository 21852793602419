import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Checkbox, FormControlLabel, Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { CustomNumberField } from '../../customComponents/CustomFormFields';
import styledComponent from 'styled-components';

const useStyles = makeStyles()((theme: Theme) => ({
  styledCheck: {
    zIndex: 999,
    '&:hover': {
      color: theme.palette.system.navyBlue,
    },
  },
  root: {
    paddingLeft: 0,
  },
}));

export const MaxSubmission = () => {
  const { control } = useFormContext();

  return (
    <Controller
      name="submissionLimit"
      control={control}
      render={({ ref, ...rest }) => <CustomRadioGroup {...rest} />}
    />
  );
};

const CustomRadioGroup = ({ value: { noLimit = false, limit = null }, ...props }) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <CustomRadioButton
        label={t('Max Submission')}
        checked={!noLimit}
        onClick={() => props.onChange({ noLimit: false, limit })}
        isInitial
      />
      <CustomNumberField
        label={t('Limit')}
        variant="filled"
        type="number"
        disabled={noLimit}
        onChange={e => props.onChange({ noLimit: false, limit: e.target.value })}
        value={limit}
      />
      <CustomRadioButton
        label={t('No Limit')}
        checked={noLimit}
        onClick={() => props.onChange({ noLimit: true, limit: '' })}
      />
    </div>
  );
};

export const CustomRadioButton = ({ label, checked, onClick, disabled = false, ...props }) => {
  const { classes } = useStyles();

  return (
    <StyledLabel
      label={label}
      control={
        <Checkbox
          className={classes.root}
          checked={checked}
          icon={
            <RadioButtonUncheckedIcon
              className={!disabled && classes.styledCheck}
              color={disabled ? 'disabled' : 'inherit'}
            />
          }
          checkedIcon={<RadioButtonCheckedIcon className={!disabled && classes.styledCheck} color={disabled ? 'disabled' : 'inherit'}/>}
        />
      }
      onClick={onClick}
      {...props}
    />
  );
};

// const StyledLabel = withStyles(FormControlLabel, {
//   root: { margin: props => (props['isInitial'] ? '0px 24px 0px 0px' : '0px 12px') },
// });

interface StyledLabelProps {
  isInitial?: boolean;
}

// TODO: MUI 5 - @Rijash Ali, verify this implementation
const StyledLabel = styledComponent(FormControlLabel)<StyledLabelProps>`
margin: ${props => (props.isInitial ? '0px 24px 0px 0px' : '0px 12px')}
`;
