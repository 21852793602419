import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import { IChip } from 'app/ComponentLibrary/Filter/ChipsContainer';
import { ISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import { IPlacementFilter } from 'app/components/RecruiterWorkDesk/MyPlacementsDesk/WorkdeskFilter/filterUtils';
import { TFunction } from 'i18next';
import { getAmPipelineCounts } from './amCountHelper';
import { determineChipSelected } from './filterHelper';
import { amPlacementsFilterName } from './filterUtils';

export interface ICounts {
  placementsCount: number;
  inSubmission: number;
  waitingForClearance: number;
  requestFileOut: number;
  sent: number;
  interviewed: number;
  offer: number;
  offerByFacility: number;
  offerBookedByTraveler: number;
  facilityConfirms: number;
  travelerAccepts: number;
  bookedCategory: number;
  bookedSubCategory: number;
  bookingApproved: number;
  waitingGoodToGo: number;
  postponementWarning: number;
  goodToGo: number;
  onAssignment: number;
}

export interface IPlacementFilterChips {
  [amPlacementsFilterName.placementAccountManager]: IChip[];
  pipelineSelected: IChip[];
  [amPlacementsFilterName.candidate]: IChip[];
  [amPlacementsFilterName.placementRecruiter]: IChip[];
  [amPlacementsFilterName.mspClient]: IChip[];
  [amPlacementsFilterName.contractAffiliate]: IChip[];
  [amPlacementsFilterName.facility]: IChip[];
  [amPlacementsFilterName.city]: IChip[];
  [amPlacementsFilterName.state]: IChip[];
  [amPlacementsFilterName.discipline]: IChip[];
  [amPlacementsFilterName.specialty]: IChip[];
  [amPlacementsFilterName.staffingVendor]: IChip[];
  [amPlacementsFilterName.technologyVendor]: IChip[];
  [amPlacementsFilterName.orderPriorities]: IChip[];
  [amPlacementsFilterName.activeCueDateRange]: IChip | null;
}

export interface IFilterChipSelected {
  [amPlacementsFilterName.candidate]: any[] | null;
  [amPlacementsFilterName.placementAccountManager]: any[] | null;
  [amPlacementsFilterName.placementRecruiter]: any[] | null;
  [amPlacementsFilterName.mspClient]: any[] | null;
  [amPlacementsFilterName.contractAffiliate]: any[] | null;
  [amPlacementsFilterName.facility]: any[] | null;
  [amPlacementsFilterName.city]: any[] | null;
  [amPlacementsFilterName.state]: any[] | null;
  [amPlacementsFilterName.discipline]: any[] | null;
  [amPlacementsFilterName.specialty]: any[] | null;
  [amPlacementsFilterName.staffingVendor]: any[] | null;
  [amPlacementsFilterName.technologyVendor]: any[] | null;
  [amPlacementsFilterName.orderPriorities]: any[] | null;
}

export interface IFilterSpecs {
  candidateOpts: ISelection[];
  disciplineOpts: ISelection[];
  specialtyOpts: ISelection[];
  facilityOpts: ISelection[];
  cityOpts: ISelection[];
  stateOpts: ISelection[];
  recruiterOpts: ISelection[];
  mspClientOpts: ISelection[];
  affiliateOpts: ISelection[];
  staffingOpts: ISelection[];
  technologyOpts: ISelection[];
  orderPriorityOpts: ISelection[];
}

export interface IAmPlacementsDesk {
  counts: ICounts | null;
  chips: IPlacementFilterChips;
  flattenedData: any[];
  dataLoaded?: boolean;
  filteredData?: any[];
  accountManagers: { id: number; name: string; value: number; object: any }[];
  filterSpecs: IFilterSpecs;
  filterChipSelected: IFilterChipSelected;
}

export const defaultChips: IPlacementFilterChips = {
  [amPlacementsFilterName.placementAccountManager]: [],
  pipelineSelected: [],
  [amPlacementsFilterName.candidate]: [],
  [amPlacementsFilterName.placementRecruiter]: [],
  [amPlacementsFilterName.mspClient]: [],
  [amPlacementsFilterName.contractAffiliate]: [],
  [amPlacementsFilterName.facility]: [],
  [amPlacementsFilterName.city]: [],
  [amPlacementsFilterName.state]: [],
  [amPlacementsFilterName.discipline]: [],
  [amPlacementsFilterName.specialty]: [],
  [amPlacementsFilterName.staffingVendor]: [],
  [amPlacementsFilterName.technologyVendor]: [],
  [amPlacementsFilterName.orderPriorities]: [],
  [amPlacementsFilterName.activeCueDateRange]: null,
};

export const defaultFilterChipSelected = {
  [amPlacementsFilterName.placementAccountManager]: null,
  [amPlacementsFilterName.candidate]: null,
  [amPlacementsFilterName.placementRecruiter]: null,
  [amPlacementsFilterName.mspClient]: null,
  [amPlacementsFilterName.contractAffiliate]: null,
  [amPlacementsFilterName.facility]: null,
  [amPlacementsFilterName.city]: null,
  [amPlacementsFilterName.state]: null,
  [amPlacementsFilterName.discipline]: null,
  [amPlacementsFilterName.specialty]: null,
  [amPlacementsFilterName.staffingVendor]: null,
  [amPlacementsFilterName.technologyVendor]: null,
  [amPlacementsFilterName.orderPriorities]: null,
} as IFilterChipSelected;

export const initialState: IAmPlacementsDesk = {
  counts: null,
  chips: defaultChips,
  flattenedData: [],
  filteredData: undefined,
  accountManagers: [],
  filterSpecs: {
    candidateOpts: [],
    disciplineOpts: [],
    specialtyOpts: [],
    facilityOpts: [],
    cityOpts: [],
    stateOpts: [],
    recruiterOpts: [],
    mspClientOpts: [],
    affiliateOpts: [],
    staffingOpts: [],
    technologyOpts: [],
    orderPriorityOpts: [],
  },
  filterChipSelected: defaultFilterChipSelected,
};

const getAmPlacementActions = createAction<{
  accountManagerId: number[];
  filter?: {
    values: IPlacementFilter;
    setValue: (
      name: string,
      value: unknown,
      options?:
        | Partial<{
            shouldValidate: boolean;
            shouldDirty: boolean;
          }>
        | undefined,
    ) => void;
    t: TFunction;
  };
}>('GET_ACCOUNTMANAGER_PLACEMENTS');

const getActiceAccountManagers = createAction<{
  userId: number;
}>('GET_ACTIVE_ACCOUNT_MANAGERS');

const amPlacementSlice = createSlice({
  name: 'accManagementPlacements',
  initialState,
  reducers: {
    setCounts(state, action: PayloadAction<ICounts>) {
      state.counts = action.payload;
    },
    setFlattenedData(state, action: PayloadAction<any[]>) {
      state.flattenedData = action.payload;
    },
    setFilteredData(state, action: PayloadAction<any[] | undefined>) {
      state.filteredData = action.payload;
      state.counts = getAmPipelineCounts(action.payload || state.flattenedData);
    },
    setDataLoaded(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        dataLoaded: action.payload,
      }
    },
    setFilterSpecs(state, action: PayloadAction<IFilterSpecs>) {
      state.filterSpecs = action.payload;
    },
    setFilterChips(state, action: PayloadAction<IPlacementFilterChips>) {
      state.chips = action.payload;
    },
    setActiveAccountManagers(state, action: PayloadAction<{ id: number; name: string; value: number; object: any }[]>) {
      state.accountManagers = action.payload;
    },
    // Below logic can be altered to accommodate filter by multiple chip logic
    setFilterChipSelected(state, action: PayloadAction<IFilterChipSelected>) {
      state.filterChipSelected = action.payload;
    },
    setFilterChipSelectedByKey(state, action: PayloadAction<{ key: string; selected: any | null }>) {
      state.filterChipSelected = {
        ...state.filterChipSelected,
        [action.payload.key]: determineChipSelected(
          action.payload.selected,
          state.filterChipSelected[action.payload.key],
        ),
      };
    },
    reset() {
      return { ...initialState };
    },
  },
});

const { actions } = amPlacementSlice;

export const { name: amPlacementsSliceKey, reducer: amPlacementsReducer } = amPlacementSlice;

export const amPlacementsAction = { ...actions, getAmPlacementActions, getActiceAccountManagers };
