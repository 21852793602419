import { missingField } from 'app/constants';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { TableSection } from '../../../customComponents/TableSection';
import { getRequirementCheck } from '../../../helper';
import { RequirementsRow } from '../../TableComponents/TableComponents';
import { usePromiseTracker } from 'react-promise-tracker';
import {
  selectSubmissionInfo,
  selectServiceFailures,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import { ColumnTruncator } from '../../InterviewStatus/VirtualInterview/ColumnTruncator';
import { GetMomentOfDate } from 'utils/dates/moment';
import { DrivingDistance } from '@AMIEWEB/Placement/PlacementDetails/PlacementDetailsHeader/FacilityLocation';

export const MilesRadius = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    orderReq: {
      order: { milesRadius: orderReq },
      candidate: { milesRadius: candidateReq },
    },
    requirementFilter,
  } = useSelector(selectSubmissionInfo);
  const { milesRadiusOrderRequirement: serviceFailed } = useSelector(selectServiceFailures);
  const { promiseInProgress } = usePromiseTracker({
    area: 'placement-submission-miles-radius-order-requirement',
    delay: 0,
  });
  const { placementId } = useContext(AddOnContext);
  const { display } = useRequirementFilter('submission-order', requirementFilter, orderReq?.type);

  return display ? (
    <RequirementsRow
      id="placement-submission-order-miles-radius"
      title={t('placement.profile.summary.submission.order.radiusRule.title')}
      requirementCell={orderReq && !isNullOrUndefined(orderReq.data) ? <RowKey miles={orderReq} t={t} /> : missingField}
      candidateCell={
        candidateReq && !isNullOrUndefined(candidateReq.data) ? <RowValue miles={candidateReq} t={t} /> : missingField
      }
      loading={promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() => dispatch(placementSummaryActions.getMilesRadiusOrderRequirement({ placementId }))}
    />
  ) : null;
};

const RowKey = ({ miles, t }) => {
  const [forceTruncation, setForceTruncation] = useState(true);

  return (
    <ColumnTruncator forceTruncation={forceTruncation} setForceTruncation={setForceTruncation} maxHeight={300}>
      <TableSection
        items={[
          {
            icon: getRequirementCheck(miles?.type),
            primaryContent: `${miles?.data.facilityAddress1}\n${miles?.data.facilityCity}, ${miles?.data.facilityState} ${miles?.data.facilityZip}`,
          },
          {
            primaryContent: `${t('placement.profile.summary.submission.order.radiusRule.title')}: ${
              miles?.data.radiusRule
                ? miles?.data.radiusRule
                : t('placement.profile.summary.submission.order.radiusRule.alternateLine1')
            }`,
          },
          {
            primaryContent: miles?.data.radiusRule
              ? miles?.data.addressWithin
                ? `${t('placement.profile.summary.submission.order.radiusRule.line1')}: ${miles?.data.addressWithin}`
                : `${t('placement.profile.summary.submission.order.radiusRule.line1')}: ${missingField}`
              : null,
          },
          {
            primaryContent: miles?.data.radiusRule
              ? miles?.data.billRateAgreement
                ? `${t('placement.profile.summary.submission.order.radiusRule.line2')}: ${
                    miles?.data.billRateAgreement
                  }`
                : `${t('placement.profile.summary.submission.order.radiusRule.line2')}: ${missingField}`
              : null,
          },
          {
            primaryContent: miles?.data.radiusRule
              ? miles?.data.notes
                ? `${t('placement.profile.summary.submission.order.radiusRule.line3')}: ${miles?.data.notes}`
                : `${t('placement.profile.summary.submission.order.radiusRule.line3')}: ${missingField}`
              : null,
          },
        ]}
      />
    </ColumnTruncator>
  );
};

const RowValue = ({ miles, t }) => {
  const lastUpdatedMoment = GetMomentOfDate(miles?.data.candidateAddressLastUpdated);

  return (
    <TableSection
      items={[
        {
          primaryContent: miles?.data.candidateAddress1
            ? `${miles?.data.candidateAddress1}\n${miles?.data.candidateCity ? miles?.data.candidateCity + ', ' : ''}${
                miles?.data.candidateState ? miles?.data.candidateState : ''
              } ${miles?.data.candidateZip ? miles?.data.candidateZip : ''}`
            : missingField,
        },

        {
          primaryContent: `${t('placement.profile.summary.submission.order.radiusRule.line4')}: ${
            miles?.data.addressType ? miles?.data.addressType : missingField
          }`,
        },
        {
          primaryContent: `${t('placement.profile.summary.submission.order.radiusRule.line5')}: ${
            lastUpdatedMoment.isValid() ? lastUpdatedMoment.format('MM/DD/YYYY') : missingField
          }`,
        },
        {
          primaryContent: <DrivingDistance wrap />,
        },
      ]}
    />
  );
};
