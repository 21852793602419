import React from 'react';
import { Avatar, GridSize, Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { Contentlabel, Requirementlabel as Requirementlabel } from '../UnitPreviewDesign';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { CustomTooltip } from '@AMIEWEB/Common';

interface Props {
  gridSize?: boolean | GridSize | undefined;
  isRequired: boolean;
  isDocumentRequired?: boolean;
  contentValue?: {
    section1: string;
    section2?: string;
  };
  id?: string;
  hideReqPrefText?: boolean;
}

const useStyles = makeStyles<{ windowWidth: number }>()((theme: Theme, props) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      fontSize: '14px !important',
    },
  },
  chip: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 12,
  },

  section1: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '50%',
    display: 'block',
  },
  section2: {},
  documentIcon: {
    fill: `${theme.palette.components.icons.secondary.backgroundColor} !important`,
    fontSize: 'large',
    marginLeft: 4,
  },
  green: {
    width: 22,
    height: 22,
    fontSize: 12,
  },
  blue: {
    width: 22,
    height: 22,
    fontSize: 12,
  },
}));

export const RequirementLabel = (props: Props) => {
  const { t } = useTranslation();
  const { innerWidth } = window;
  const { classes } = useStyles({ windowWidth: innerWidth });
  const { isRequired, isDocumentRequired, contentValue } = props;

  return (
    <div id={props.id} className={classes.chip}>
      <CustomTooltip
        tooltipContent={isRequired ? t('order.createUnit.required') : t('order.createUnit.preferred')}
        placement="bottom"
        isStandardContent={true}
      >
        <Avatar variant={isRequired?'primary':'green'} className={`${isRequired ? classes.blue : classes.green}`}>{isRequired ? 'R' : 'P'}</Avatar>
      </CustomTooltip>
      {contentValue?.section1?.length > 0 && (
        <div style={{ display: 'contents' }}>
          <Requirementlabel id={`${props.id}-section1`} className={`${classes.root}${classes.section1}`}>
            {`${contentValue.section1}`}
          </Requirementlabel>
          {contentValue?.section2 && (
            <Contentlabel
              className={`${classes.root}${classes.section2}`}
              style={{ paddingRight: '0px', whiteSpace: 'nowrap' }}
            >
              {` - ${contentValue.section2}`}
            </Contentlabel>
          )}
        </div>
      )}
      {isDocumentRequired && <DescriptionOutlinedIcon className={classes.documentIcon} />}
    </div>
  );
};
