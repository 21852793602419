/* eslint-disable tss-unused-classes/unused-classes */
import React from 'react';
import { Theme, Typography, Grid, Link } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { CandidateSection } from './Candidate/CandidateSection';
import { OrderSection } from './Order/OrderSection';
import { useDispatch, useSelector } from 'react-redux';
import { selectNewPlacementChoice } from '../../../../store/redux-store/new-placement/selectors';
import { Concatenate } from 'utils/string/string';
import { missingField } from 'app/constants';
import { colors } from 'styles/styleVariables';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { newPlacementActions } from '../../../../store/redux-store/new-placement/slice';
import { OverflowTooltip } from 'app/components/Common/Tooltips';
import { useFormContext } from 'react-hook-form';
import { CreatePlacementStatus } from './Status/CreatePlacementStatus';
import { placementStatusAction } from '../../../../store/redux-store/placement-status/slice';
import { IAutomation } from 'app/models/Orders/OrderPreference';
import { useValidations, useSearchSelections } from './hooks';
import {
  getSessionValue,
  removeSessionValue,
  SessionKey,
  StorageType,
} from 'utils/customHooks/sessionStorage/sessionHelpers';
import { getCandidateOptions } from 'app/services/CandidateServices/CandidateServices';
import { ICustomOption } from './CustomComponents/CustomTypeAhead';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    overflow: 'hidden',
    paddingBottom: '10px',
  },
  subtitle: {
    color: colors.darkText,
    fontWeight: 450,
  },
  banner: {
    paddingBottom: '12px !important',
  },
  snackbar: {
    position: 'absolute',
    bottom: 84,
    width: '100%',
  },
}));

export const CreatePlacement = ({
  isDefaultCandidate,
  isDefaultOrder,
  isOrderRefreshRequest,
  isCandidateRefreshRequest,
  newPlacementId,
  automation,
  sessionKey,
  initialArgs,
}: {
  isDefaultCandidate?: boolean;
  isDefaultOrder?: boolean;
  isOrderRefreshRequest?: boolean;
  isCandidateRefreshRequest?: boolean;
  newPlacementId: number | null;
  automation?: IAutomation;
  sessionKey?: string | null;
  initialArgs?: { orderId?: number; candidateId?: number; brandId?: number };
}) => {
  const { classes } = useStyles();
  const boxRef = React.useRef<any>(null);
  useValidations();
  useSearchSelections(isDefaultCandidate, isDefaultOrder);

  const { candidate, order } = useSelector(selectNewPlacementChoice);
  const { reset, getValues } = useFormContext();
  const dispatch = useDispatch();

  useEffect(() => {
    if (newPlacementId) {
      if (newPlacementId === -1) dispatch(newPlacementActions.setPlacementCreated(null));
      reset(getValues(), { isDirty: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPlacementId]);

  useEffect(() => {
    return () => {
      dispatch(newPlacementActions.reset());
      dispatch(placementStatusAction.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [refreshVars, setRefreshVars] = React.useState<any>();
  const [candidateValue, setCandidateValue] = React.useState<ICustomOption | null>(null);

  const fetchNotificationCandidate = async candidateId => {
    await getCandidateOptions(candidateId?.toString())
      .then(res => {
        if (res) {
          const opt = {
            object: res[0],
            primaryLabel: `${res[0]?.name} (${res[0]?.candidateId})`,
            secondaryLabel: Concatenate([res[0]?.brand, res[0]?.recruiter, res[0]?.disciplines], ' - '),
          } as ICustomOption;
          setCandidateValue(opt);
        }
      })
      .catch(error => {
        trackException({
          exception: error,
          properties: {
            name: ExceptionType.APIResponseError,
            functionName: 'fetchOptions',
            area: 'src/app/components/Placement/CreatePlacement/Candidate/CandidateSearch.tsx',
          },
        });
      });
  };

  React.useEffect(() => {
    const newPlacementDataFromLocalStorage = getSessionValue(SessionKey.newPlacementData, StorageType.localStorage);
    if (sessionKey === SessionKey.createPlacementNotifications) {
      setRefreshVars({ ...initialArgs });
      fetchNotificationCandidate(initialArgs?.candidateId);
      removeSessionValue(SessionKey.createPlacementNotifications, StorageType.sessionStorage);
    } else if (initialArgs && ((initialArgs?.candidateId && initialArgs?.brandId) || initialArgs?.orderId)) {
      setRefreshVars({ ...initialArgs });
    } else {
      setRefreshVars(getSessionValue(SessionKey.createPlacementMFS, StorageType.sessionStorage) ?? {});
      removeSessionValue(SessionKey.createPlacementMFS, StorageType.sessionStorage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (candidateValue) {
      reset({ ...getValues, candidate: candidateValue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateValue]);

  return (
    <div id="create-placement-dialog" ref={boxRef}>
      <Grid container spacing={2} style={{ position: 'relative' }} xs={12} classes={{ root: classes.root }}>
        <Grid item>
          <Subtitle order={order} candidate={candidate} classes={classes} />
        </Grid>
        <Grid item xs={12}>
          {refreshVars && (
            <Grid container direction="row" spacing={2} wrap="nowrap">
              <CandidateSection
                isDefaultCandidate={isDefaultCandidate}
                isDefaultOrder={isDefaultOrder}
                isCandidateRefreshRequest={isCandidateRefreshRequest}
                sessionKey={sessionKey}
                {...refreshVars}
              />
              <OrderSection
                isDefaultCandidate={isDefaultCandidate}
                isDefaultOrder={isDefaultOrder}
                isOrderRefreshRequest={isOrderRefreshRequest}
                sessionKey={sessionKey}
                {...refreshVars}
              />
              <CreatePlacementStatus />
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const Subtitle = props => {
  const { t } = useTranslation();
  const { order, candidate, classes } = props;
  return (
    <Typography variant="body1" classes={{ body1: classes.subtitle }}>
      {candidate?.candidateId ? (
        <Link href={`/candidate/${candidate?.candidateId}/${candidate?.brandId}`}>
          {Concatenate([candidate?.firstName, candidate?.lastName, `(${candidate?.candidateId})`], ' ')}
        </Link>
      ) : (
        missingField
      )}
      {t('placement.create.popup.toOID')}
      {order?.orderId ? <Link href={`/order/${order?.orderId}`}>{`(${order?.orderId})`}</Link> : missingField}
      {t('placement.create.popup.at')}
      {order?.facilityId ? (
        <Link href={`/facility/${order?.facilityId}`}>
          <OverflowTooltip
            value={order?.facility}
            contentStyle={{ fontWeight: 450, fontSize: 14, color: colors.amnBlue }}
          />
        </Link>
      ) : (
        missingField
      )}
    </Typography>
  );
};
