import states from 'app/assets/jsons/State.json';
import { missingField } from 'app/constants';
import { convertToFormat } from 'app/helpers/dateHelper';
import { formatToAmericanNotaion } from 'app/helpers/numberHelper';
import { GetMomentOfDate } from 'utils/dates/moment';
import { Concatenate } from 'utils/string/string';
import Call from '../../../assets/images/WorkDesk/Call.png';
import email from '../../../assets/images/WorkDesk/email.png';
import { covidVaxStatus } from './SearchUtils';
import { checkCandidatesConsents} from '@AMIEWEB/Candidate/CandidateProfile/Profile/helper';

export const candidateSearchFlattenData = items =>
  items.map((item, index: number) => {
    const availableToStartDateMoment = GetMomentOfDate(item.availableToStartDate);
    const lastContactedOnDateMoment = GetMomentOfDate(item.lastContactedOnDate);
    const callBackDateMoment = GetMomentOfDate(item.callBackDate);
    const applicationDateMoment = GetMomentOfDate(item.applicationDate);
    const engagementDateMoment = GetMomentOfDate(item.engagementDate);
    const lastPlacementEndDateMoment = GetMomentOfDate(item.lastPlacementEndDate);

    const set = new Set();
    let tempArray = [];

    type Skillset = {
      disciplineAbbr?: string;
      specialtyAbbr?: string;
    };

    const formatSkillset = (skillset: Skillset) => {
      tempArray = [];
      skillset.disciplineAbbr && tempArray.push(skillset.disciplineAbbr);
      skillset.specialtyAbbr && tempArray.push(skillset.specialtyAbbr);
      set.add(tempArray.join('-'));
    };

    item?.skillsets?.forEach((skillset: Skillset) => formatSkillset(skillset));
    const skillSets = set.size > 0 ? Array.from(set).join(', ') : missingField;
    set.clear();

    item?.unverifiedSkillsets?.forEach((skillset: Skillset) => formatSkillset(skillset));
    const unVerifiedSkillsets = set.size > 0 ? Array.from(set).join(', ') : missingField;
    set.clear();

    const tags = item.tags
      ?.map(item => {
        return { title: item.label };
      })
      ?.sort((a, b) =>
        a?.title?.toLowerCase() < b?.title?.toLowerCase()
          ? -1
          : a?.title?.toLowerCase() > b?.title?.toLowerCase()
            ? 1
            : 0,
      );

    const recruiterAttributes = [
      { key: Call, value: item.recruiter?.phoneNumber },
      { key: email, value: item.recruiter?.email },
    ];

    const data = {
      id: `${item.candidateId}-${item.brandId}-${index}`,
      indexCounter: index,
      previewDrawerViewerId: item.candidateId,
      previewDrawerViewerType: 1,
      brandId: item.brandId,
      candidateBrandId: item.candidateBrandId, // currently needed for notifications
      candidateId: item.candidateId, // currently needed for notifications
      candidate: Concatenate([item.candidate.firstName, item.candidate.lastName], ' ') || missingField,
      skillsets: skillSets,
      unverifiedSkillsets: unVerifiedSkillsets,
      brand: item.brand || missingField,
      candidateStatus: item.candidateStatus || missingField,
      recruiterId: item?.recruiter?.employeeId || missingField,
      recruiter: Concatenate([item.recruiter?.firstName, item.recruiter?.lastName], ' ') || missingField,
      recruiterAttributes,
      availableToStartDate: availableToStartDateMoment.isValid()
        ? availableToStartDateMoment.format('MM/DD/YYYY')
        : missingField,
      lastContactedOnDate: lastContactedOnDateMoment.isValid()
        ? convertToFormat(item.lastContactedOnDate, 'MM/DD/YYYY', true)
        : missingField,
      callBackDate: callBackDateMoment.isValid() ? callBackDateMoment.format('MM/DD/YYYY') : missingField,
      applicationDate: applicationDateMoment.isValid() ? applicationDateMoment.format('MM/DD/YYYY') : missingField,
      engagementDate: engagementDateMoment.isValid() ? engagementDateMoment.format('MM/DD/YYYY') : missingField,
      city: item.city || missingField,
      firstName: item.candidate.firstName || missingField,
      lastName: item.candidate.lastName || missingField,
      goesBy: item.candidate.goesBy || missingField,
      otherName: item.candidate.otherName || missingField,
      emails: { primaryEmail: item.primaryEmail, secondaryEmail: item.secondaryEmail },
      zipCode: item.zip || missingField,
      covidVaxStatus: covidVaxStatus[item.covidVaxStatus] || missingField,
      distance: item.distance ? parseFloat(item.distance).toFixed(1) : missingField,
      virtualInterview: item.virtualInterview === null ? missingField : item.virtualInterview ? 'Yes' : 'No',
      lastPlacementEndDate: lastPlacementEndDateMoment.isValid()
        ? lastPlacementEndDateMoment.format('MM/DD/YYYY')
        : missingField,
      state: item.state ? item.state : missingField,
      email: item.primaryEmail ? item.primaryEmail : item.secondaryEmail ? item.secondaryEmail : missingField,
      jobMatchCount: Number.isFinite(item.jobMatchCount) ? formatToAmericanNotaion(item.jobMatchCount) : missingField,
      jobInterestCount: Number.isFinite(item.jobInterestCount)
        ? formatToAmericanNotaion(item.jobInterestCount)
        : missingField,
      phones: item.phoneNumbers || missingField,
      candidateTags: tags,
      placementBeenOnAssignment:
        item.placementBeenOnAssignment !== null ? `${item.placementBeenOnAssignment} Days` : missingField,
      supervisor: item.supervisor ? 'Yes' : 'No',
      consents: checkCandidatesConsents(item?.consents, item?.brandId, item?.candidateId),
      activePlacements: !!(item?.activePlacements && item?.activePlacements.length > 0)
    };
    return data;
  });
