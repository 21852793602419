import { Button, Grid, Menu, MenuItem } from 'amn-ui-core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styledComponent from 'styled-components';
import { useEmailStyles } from './Email.styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveStep } from 'app/ApplicationRoot/Global.selectors';
import { NotificationContext } from '@AMIEWEB/Notification/NotificationWrapper';
import { ChannelType } from '@AMIEWEB/Notification/Constants';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { withReadOnly } from 'oidc/withReadOnly';
import { areAllWhiteSpaces } from 'utils/string/string';
import { canSaveDraft } from './EmailHelper';
import { theme } from 'styles/global-styles';

export const NavigationButtons = styledComponent(Button)`
  margin: 4px;
  height: 4.5vh;
  color: #ffffff;
  background: #006fb9;
  padding-bottom: '1%';
`;

export const ReadOnlyNavigationButton = withReadOnly(NavigationButtons);

export const EmailActionButtons = props => {
  const { t } = useTranslation();
  const { classes } = useEmailStyles();
  const activeStep = useSelector(selectActiveStep);
  const { onClose } = useContext(NotificationContext);
  const dispatch = useDispatch();
  const {
    isExpanded,
    disableSend,
    anchorSaveEl,
    loader,
    saveMenuOpen,
    handleSaveMenuClose,
    CancelTemplateModel,
    handleSaveButtonClick,
    canSaveTemplate,
    templateSaveTitleError,
    templateSaveDescError,
    templateSaveCategoryError,
    handleSaveTemplateMenu,
    handleSaveDraftMenu,
    emailTemplates,
    titlesList,
    handleSaveTemplate,
    notificationData,
    handleSubmit,
    handleModalClose,
  } = props;
  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-end"
        direction="row"
        style={{ padding: isExpanded ? '' : '1%', paddingRight: isExpanded ? '0.5%' : '1%' }}
      >
        {activeStep === 1 && (
          <NavigationButtons
            variant="contained"
            style={{ backgroundColor: theme.palette.framework.system.buttonGray }}
            onClick={handleModalClose}
          >
            {t('notification.emails.cancel')}
          </NavigationButtons>
        )}
        {activeStep === 3 && (
          <NavigationButtons
            variant="contained"
            style={{ backgroundColor: theme.palette.framework.system.buttonGray }}
            onClick={() => CancelTemplateModel()}
          >
            {t('notification.emails.cancel')}
          </NavigationButtons>
        )}
        {activeStep === 1 && (
          <NavigationButtons
            variant="contained"
            color="primary"
            onClick={() => dispatch(globalActions.setActiveStep(2))}
          >
            {t('notification.emails.preview')}
          </NavigationButtons>
        )}
        {activeStep === 1 && (
          <div className={classes.saveButtonPlacement}>
            <Button
              className={classes.saveButton}
              variant="contained"
              color="primary"
              id="save-button"
              aria-controls="save-button-menu"
              aria-haspopup="true"
              aria-expanded={saveMenuOpen ? 'true' : undefined}
              disableElevation
              onClick={handleSaveButtonClick}
              endIcon={saveMenuOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              disabled={!canSaveTemplate() && !canSaveDraft()}
            >
              {t('notification.emails.save')}
            </Button>
            <Menu
              id="save-button-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorSaveEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={saveMenuOpen}
              onClose={handleSaveMenuClose}
            >
              <MenuItem disabled={!canSaveTemplate()} onClick={handleSaveTemplateMenu}>
                {t('notification.emails.saveTemplate')}
              </MenuItem>
              <MenuItem disabled={!canSaveDraft()} onClick={handleSaveDraftMenu}>
                {t('notification.emails.saveDraft')}
              </MenuItem>
            </Menu>
          </div>
        )}
        {activeStep === 3 && (
          <NavigationButtons
            disabled={
              templateSaveTitleError.length > 0 ||
              templateSaveDescError.length > 0 ||
              templateSaveCategoryError.length > 0 ||
              emailTemplates.selectedTemplate?.title === '' ||
              emailTemplates.selectedTemplate?.description === '' ||
              emailTemplates.selectedTemplate?.category === '' ||
              titlesList?.includes(emailTemplates.selectedTemplate?.title?.toLowerCase())
            }
            variant="contained"
            color="primary"
            onClick={handleSaveTemplate}
          >
            {t('notification.emails.save')}
          </NavigationButtons>
        )}
        {activeStep === 2 && (
          <NavigationButtons
            variant="contained"
            color="primary"
            onClick={() => dispatch(globalActions.setActiveStep(1))}
          >
            {t('notification.emails.edit')}
          </NavigationButtons>
        )}
        {activeStep < 3 && (
          <ReadOnlyNavigationButton
            disabled={
              disableSend ||
              !notificationData.subject ||
              areAllWhiteSpaces(notificationData.subject) ||
              loader.backdropLoader ||
              !notificationData.tos ||
              notificationData.tos?.length < 1 ||
              (notificationData.tos?.length === 1 && !notificationData.tos[0].email)
            }
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            {t('notification.emails.send')}
          </ReadOnlyNavigationButton>
        )}
      </Grid>
    </>
  );
};
