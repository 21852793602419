import React, { useEffect } from 'react';
import { Grid } from 'amn-ui-core';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { SimpleCheckbox } from '@AMIEWEB/Unit/Common/SimpleCheckbox';
import { SingleLineInput } from '@AMIEWEB/Unit/Common/SingleLineInput';
import { useTranslation } from 'react-i18next';
import {
  ControlledTypeAhead,
  ITypeAheadOption,
} from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/PreferencesTab/CustomComponents/ControlledTypeAhead';
import { useSelector } from 'react-redux';
import { selectFacility } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { filterDisciplineList } from '@AMIEWEB/Order/OrderCreationForm/OrderDetails/FormatData';
import { selectTypeAheadOptions } from '@AMIEWEB/Order/Store/Order.selectors';
import { validateDuplicateSkillset } from '../../UnitDetailsFormValidator';
import { getPositiveIntegerValue } from '@AMIEWEB/NewOrder/CreateOrder/helper';
import { isWholeNumber } from '@AMIEWEB/Order/OrderCreationForm/helper';
import { useSkillSetSectionStyles } from './Experience.styles';
import { useDecision } from '@optimizely/react-sdk';
import { ff_unit_ui_duplicate_skill_set } from 'app/constants/FeatureFlags/Facility/Keys';

export const SkillSetSection = ({
  skillSetIndex,
  groupIndex,
  skillSetField,
  numberOfSkillSets,
  setNumberOfFilteredSpeciality,
}) => {
  const {
    control,
    register,
    setValue,
    watch,
    trigger,
    errors,
    formState: { isSubmitted },
  } = useFormContext();

  const facilityValue = useSelector(selectFacility);
  const typeAheadOptions = useSelector(selectTypeAheadOptions);
  const [filteredDiscipline, setFilteredDiscipline] = React.useState<ITypeAheadOption[]>([]);
  const [filteredSpecialty, setFilteredSpecialty] = React.useState<ITypeAheadOption[]>([]);
  const [filteredSubSpecialty, setFilteredSubSpecialty] = React.useState<ITypeAheadOption[]>([]);
  const [yearsOfExperienceFocused, setYearsOfExperienceFocused] = React.useState(false);
  const [monthsOfExperienceFocused, setMonthsOfExperienceFocused] = React.useState(false);
  const skillSetValue = watch(`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].skillSet`);
  const { t } = useTranslation();
  const { classes } = useSkillSetSectionStyles();
  const skillSetError = errors?.skillSetGroup?.groups?.[groupIndex]?.skillsets?.[skillSetIndex]?.skillSet;
  const [enableDuplicateSkillSet] = useDecision(ff_unit_ui_duplicate_skill_set);  
  const yearsOfExperience = useWatch({
    control,
    name: `skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].skillSet.yearsOfExperience`,
  });
  const monthsOfExperience = useWatch({
    control,
    name: `skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].skillSet.monthsOfExperience`,
  });

  const filterDiscipline = () => {
    if (facilityValue) {
      let divisionId = facilityValue?.response?.amnDivisionTypeId || facilityValue?.facilityResponse?.amnDivisionTypeId;
      const filteredList = filterDisciplineList(
        divisionId,
        typeAheadOptions?.disciplines,
        typeAheadOptions?.divisionDisciplines,
      );
      setTimeout(() => {
        setFilteredDiscipline(filteredList);
      }, 500);
    } else {
      setFilteredDiscipline(typeAheadOptions?.disciplines);
    }
  };

  useEffect(() => {
    filterDiscipline();
    if (skillSetField?.skillSet?.discipline) {
      setFilteredSpecialty(filterSpecialty(skillSetField.skillSet.discipline?.object?.ID, false));
    }
    if (skillSetField?.skillSet?.specialty) {
      setFilteredSubSpecialty(filterSubspecialty(skillSetField.skillSet.specialty?.object?.SpecialtyID, false));
    }
  }, []);

  useEffect(() => {
    filterSpecialty(skillSetField.skillSet.discipline?.object?.ID, false);
  }, [numberOfSkillSets]);

  const filterSpecialty = (id: number, disableFields) => {
    if (disableFields) {
      setValue(`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].skillSet.specialty`, null);
      setValue(`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].skillSet.subSpecialty`, null);
    }
    const divisionDisciplines = typeAheadOptions?.divisionDisciplines.filter(dd => {
      return (
        (dd.amnDivisionId === facilityValue?.facilityResponse?.amnDivisionTypeId ||
          dd.amnDivisionId === facilityValue?.response?.amnDivisionTypeId) &&
        dd.disciplineId === id
      );
    });
    const filteredSpecialties = divisionDisciplines
      ?.map(dd => {
        return typeAheadOptions?.specialties.find(
          ds => ds.object.DisciplineID === dd.disciplineId && ds.object.SpecialtyID === dd.specialtyId,
        );
      })
      .filter(Boolean);
    setNumberOfFilteredSpeciality(filteredSpecialties ? filteredSpecialties?.length : 0);
    return filteredSpecialties || [];
  };

  const filterSubspecialty = (id: number, disableFields) => {
    if (disableFields) {
      setValue(`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].skillSet.subSpecialty`, null);
    }
    const filteredSubSpecialties = typeAheadOptions?.subSpecialties.filter(data => {
      return data.object.SpecialtyID === id;
    });
    return filteredSubSpecialties || [];
  };
  const filterOptions = (options: ITypeAheadOption[], { inputValue }: { inputValue: string }) => {
    const searchQuery = inputValue?.toLowerCase()?.replace(/\s|\.|\(|\)/g, '');
    return searchQuery?.length >= 2
      ? options?.filter(option =>
          `${option?.object?.Abbreviation} - ${option?.object?.Description}`
            ?.toLowerCase()
            ?.replace(/\s|\.|\(|\)/g, '')
            ?.includes(searchQuery),
        )
      : options;
  };

  return (
    <Grid container gap={2}>
      <Grid item xs={2}>
        <ControlledTypeAhead
          register={register}
          name={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].skillSet.discipline`}
          label={t('facility.units.unitDetails.unitRequirements.discipline')}
          control={control}
          options={filteredDiscipline || []}
          filterOptions={filterOptions}
          onChange={async discipline => {
            await setFilteredSpecialty(filterSpecialty(discipline.object.ID, true));
            setValue(`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].skillSet.isRequired`, false);
            if (isSubmitted) {
              trigger();
            }
          }}
          isError={skillSetError?.discipline?.type === 'required' ? true : false}
          helperText={
            skillSetError?.discipline?.type === 'required'
              ? t('facility.units.unitDetails.unitRequirements.required')
              : ''
          }
          customScroll
          disabled={false}
          id={'unitExperienceDiscipline'}
          showDropdownIcon={true}
          defaultValue={skillSetField.skillSet.discipline}
          validateTypeAhead={() =>
            validateDuplicateSkillset(
              watch(`skillSetGroup.groups[${groupIndex}].skillsets`),
              skillSetIndex,
              watch(`skillSetGroup.groups[${groupIndex}].operation`),
              enableDuplicateSkillSet.enabled,
              t,
            )
          }
          require={true}
          isNestedObject={true}
        />
      </Grid>
      <Grid item xs={2}>
        <ControlledTypeAhead
          mandatory={false}
          filledNormal={false}
          register={register}
          name={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].skillSet.specialty`}
          label={t('facility.units.unitDetails.unitRequirements.specialty')}
          control={control}
          options={filteredSpecialty || []}
          onChange={specialty => {
            setFilteredSubSpecialty(filterSubspecialty(specialty.object.SpecialtyID, true));
            setValue(`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].skillSet.isRequired`, true);
            if (isSubmitted) {
              trigger();
            }
          }}
          isError={skillSetError?.specialty}
          helperText={
            skillSetError?.specialty?.type === 'required'
              ? t('facility.units.unitDetails.unitRequirements.required')
              : skillSetError?.specialty?.message
              ? t('facility.units.unitDetails.unitRequirements.duplicatedSpeciality')
              : ''
          }
          customScroll
          disabled={!Boolean(skillSetValue?.discipline) || !Boolean(filteredSpecialty?.length)}
          id={'unitExperienceSpecialty'}
          showDropdownIcon={true}
          defaultValue={skillSetField.skillSet.specialty}
          validateTypeAhead={() =>
            validateDuplicateSkillset(
              watch(`skillSetGroup.groups[${groupIndex}].skillsets`),
              skillSetIndex,
              watch(`skillSetGroup.groups[${groupIndex}].operation`),
              enableDuplicateSkillSet.enabled,
              t,
            )
          }
          require={Boolean(skillSetValue?.discipline) && !Boolean(filteredSpecialty?.length) ? false : true}
          isNestedObject={true}
        />
      </Grid>
      <Grid item xs={2}>
        <ControlledTypeAhead
          mandatory={false}
          filledNormal={false}
          register={register}
          name={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].skillSet.subSpecialty`}
          label={t('facility.units.unitDetails.unitRequirements.subSpecialty')}
          control={control}
          options={filteredSubSpecialty || []}
          onChange={() => {
            if (isSubmitted) {
              trigger();
            }
          }}
          isError={skillSetValue?.subSpecialty ? skillSetError?.subSpecialty : false}
          helperText={
            skillSetValue?.subSpecialty && skillSetError?.subSpecialty?.message
              ? t('facility.units.unitDetails.unitRequirements.duplicatedSubSpeciality')
              : ''
          }
          customScroll
          disabled={!Boolean(skillSetValue?.specialty) || !Boolean(filteredSubSpecialty?.length)}
          id={'unitExperienceSubSpecialty'}
          showDropdownIcon={true}
          defaultValue={skillSetField.skillSet.subSpecialty}
          validateTypeAhead={() =>
            validateDuplicateSkillset(
              watch(`skillSetGroup.groups[${groupIndex}].skillsets`),
              skillSetIndex,
              watch(`skillSetGroup.groups[${groupIndex}].operation`),
              enableDuplicateSkillSet.enabled,
              t,
            )
          }
          isNestedObject={true}
        />
      </Grid>
      <Grid item xs={1.2}>
        <Controller
          name={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].skillSet.yearsOfExperience`}
          control={control}
          defaultValue={skillSetField.skillSet.yearsOfExperience}
          render={({ onChange, ...rest }) => (
            <SingleLineInput
              id={'unitExperienceInYears'}
              inputDefaultValue={null}
              inputLabel={t('facility.units.unitDetails.unitRequirements.experienceIn')}
              inputName={'ExpYears'}
              inputValue={rest.value}
              handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChange(event.target.value === '' ? null : getPositiveIntegerValue(event.target.value));
              }}
              showEndAdornment={yearsOfExperienceFocused || isWholeNumber(yearsOfExperience)}
              endAdornmentValue={t('facility.units.unitDetails.unitRequirements.years')}
              inputType={'number'}
              fieldStyle={classes.fieldStyle}
              shrinkLabel={yearsOfExperienceFocused || isWholeNumber(yearsOfExperience)}
              handleWarning={() => setYearsOfExperienceFocused(true)}
              handleBlur={() => {
                setYearsOfExperienceFocused(false);
                rest.onBlur();
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={1.2}>
        <Controller
          name={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].skillSet.monthsOfExperience`}
          control={control}
          defaultValue={skillSetField.skillSet.monthsOfExperience}
          render={({ onChange, ...rest }) => (
            <SingleLineInput
              id={'unitExperienceInMonths'}
              inputDefaultValue={null}
              inputLabel={t('facility.units.unitDetails.unitRequirements.experienceIn')}
              inputName={'ExpMonths'}
              inputValue={rest.value}
              handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (parseInt(event.target.value) < 13 || isNaN(parseInt(event.target.value))) {
                  onChange(event.target.value === '' ? null : getPositiveIntegerValue(event.target.value));
                }
              }}
              showEndAdornment={monthsOfExperienceFocused || isWholeNumber(monthsOfExperience)}
              endAdornmentValue={t('facility.units.unitDetails.unitRequirements.months')}
              inputType={'number'}
              fieldStyle={classes.fieldStyle}
              shrinkLabel={monthsOfExperienceFocused || isWholeNumber(monthsOfExperience)}
              handleWarning={() => setMonthsOfExperienceFocused(true)}
              handleBlur={() => {
                setMonthsOfExperienceFocused(false);
                rest.onBlur();
              }}
            />
          )}
        />
      </Grid>
      <Grid item sx={{ alignSelf: 'center' }} className={classes.disabled}>
        <Controller
          name={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].skillSet.isRequired`}
          control={control}
          defaultValue={skillSetField.skillSet.isRequired}
          render={({ onChange, ...rest }) => (
            <SimpleCheckbox
              isChecked={rest.value || false}
              handleChecked={event => {
                onChange(event.target.checked);
              }}
              checkboxLabel={t('facility.units.unitDetails.unitRequirements.required')}
              checkboxName={'experienceSkillRequired'}
              id={'experienceSkillRequired'}
            />
          )}
        />
      </Grid>
      <Grid item sx={{ alignSelf: 'center' }} className={classes.checkbox}>
        <Controller
          name={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].skillSet.isNewGrad`}
          control={control}
          defaultValue={skillSetField.skillSet.isNewGrad}
          render={({ onChange, ...rest }) => (
            <SimpleCheckbox
              isChecked={rest.value || false}
              handleChecked={event => {
                onChange(event.target.checked);
              }}
              checkboxLabel={t('facility.units.unitDetails.unitRequirements.newGrad')}
              checkboxName={'experienceSkillNewGrad'}
              id={'experienceSkillNewGrad'}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
