import { IDocument } from 'react-doc-viewer';
import moment from 'moment';
import { IAttachments } from 'app/models/Credentialing/Credentials';
import base64 from 'base-64';
import utf8 from 'utf8';

//various allowed file types
const fileTypes = {
  png: 'image/png',
  jpeg: 'image/jpeg',
  jpg: 'image/jpg',
  pdf: 'application/pdf',
};

//various allowed base64 prepends, these are prepended to the start of a base64 image string
const base64Pre = {
  png: `data:${fileTypes.png};base64,`,
  jpeg: `data:${fileTypes.jpeg};base64,`,
  jpg: `data:${fileTypes.jpg};base64,`,
  pdf: `data:${fileTypes.pdf};base64,`,
};

export interface IDocuments {
  travelerRequirementImageID: number;
  comments: string;
  lastUpdated: string;
  lastUpdatedby: string;
  Document: IDocument;
}


//Append base64 to based on file types
export const base64Append = (fileType: string, base64Data: string) => {
  if (fileType === '') return null;

  switch (fileType) {
    case fileTypes.png:
      return `${base64Pre.png}+${base64Data}`;
    case fileTypes.jpeg:
      return `${base64Pre.jpeg}+${base64Data}`;
    case fileTypes.jpg:
      return `${base64Pre.jpg}+${base64Data}`;
    case fileTypes.pdf:
      return `${base64Pre.pdf}+${base64Data}`;
  }
};


export const fileTypePre = (fileType: string) => {
  if (fileType === '') return null;

  switch (fileType) {
    case fileTypes.png:
      return `${base64Pre.png}`;
    case fileTypes.jpeg:
      return `${base64Pre.jpeg}`;
    case fileTypes.jpg:
      return `${base64Pre.jpg}`;
    case fileTypes.pdf:
      return `${base64Pre.pdf}`;
  }
};

//Remove if any prefix is of base64Pre.
export const removeFileTypePrefix = (fileType: string, base64Data: string) => {
  //replace('data:application/pdf; base64,', '')
  return base64Data.replace(fileType + ' base64,', '');
};

//Add if any prefix is of base64Pre.
export const addFileTypePrefix = (fileType: string, base64Data: string) => {
  return 'data:' + fileType + '; base64,' + base64Data;
};

//Download all files in ZIP (image,pdf,htmls).
export const downloadZip = (credentialName: string, download: IAttachments[], zipName?: string) => {
  //var zip = new JSZip();
  download.map((data, index) => {
    var uri = data.document.uri as string;
    var fileData = data.document.fileData as string;
    var fileName = getFileNameFormat(credentialName, `Attachment ${data.sortOrder}`, data.lastUpdated);
    var fileExt = data.document.fileType as string;
    downloadFile(uri, fileData, fileName, fileExt);
    /*downloadFile(data.document.fileData,
      getFileNameFormat(
        credential?.credential?.requirementType,
        props.attachmentsNum,
        attachment.lastUpdated,
      ),
      data?.document?.fileType);*/
    /*zip.file(
      getFileNameFormat(credentialName, `Attachment ${data.sortOrder}`, data.lastUpdated) +
        fileExtAppend(data.document.fileType as string),
      removeFileTypePrefix(data.document.fileType as string, data.document.fileData as string),
      { base64: true },
    );*/
  });

  /*zip.generateAsync({ type: 'blob' }).then(function (content) {
    saveAs(content, `${credentialName}.zip`);
  });*/
  return true;
};

//Single file download
export const downloadFile = (
  url: string | undefined,
  fileBase64: string | ArrayBuffer | undefined,
  fileName: string,
  fileType: string | undefined,
) => {
  fileName = fileName === '' || fileName === null || fileName === undefined ? defaultName(fileType) : fileName;
  if (fileBase64 === undefined) return;

  if (fileType == 'text/html') {
    const linkSource = `${url}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.target = '_blank';
    downloadLink.click();
  } else {
    const linkSource = `${fileBase64}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return true;
};

//IF no file name is specified.
const defaultName = (fileType: string | undefined) => {
  var fileNameFormat = new Date().toString().replace(/[^\w]/g, '');
  return fileNameFormat;
};

//Get the file format
export const getFileNameFormat = (
  credentialName: string,
  attachmentNumber: string,
  uploadedDate: string | undefined,
  description?: string,
) => {
  const dateFormat = moment(uploadedDate).format('L');
  return (
    credentialName?.split(' ').join('_') +
    '_' +
    attachmentNumber?.split(' ').join('_') +
    '_' +
    dateFormat?.toString().split('/').join('')
  );
};

//
// ─── DOCUMENT PREVIEW SUPPORT ───────────────────────────────────────────────────
//
export const isPrevSupported = (fileType: string) => {
  if (fileType === '') return false;
  switch (fileType) {
    // case 'text/html':
    //   return true;
    case 'application/pdf':
      return true;
    case 'image/bmp':
      return true;
    case 'image/jpeg':
      return true;
    case 'image/png':
      return true;
    case 'image/tiff':
      return true;
    case 'text/plain':
      return true;
  }
  return false;
};

//
// ─── CHECK FILE TYPE IS HTML ────────────────────────────────────────────────────
//

export const isFileTypeHtml = (fileType: string) => {
  if (fileType === '') return false;

  switch (fileType) {
    case 'htm':
    case 'html':
    case 'text/htm':
    case 'text/html':
      return true;
    default:
      return false;
  }
};

//
// ─── CHECK FILE TYPE IS TXT ────────────────────────────────────────────────────
//

export const isFileTypeText = (fileType: string) => {
  if (fileType === '') return false;

  switch (fileType) {
    case 'txt':
    case 'text/plain':
      return true;
    default:
      return false;
  }
};

//
// ─── CHECK FILE TYPE IS PDF ────────────────────────────────────────────────────
//

export const isFileTypePdf = (fileType: string) => {
  if (fileType === '') return false;
  switch (fileType) {
    case 'pdf':
    case 'application/pdf':
      return true;
    default:
      return false;
  }
};

//
// ─── IF CONTENT NULL THE REPLACE WITH NA ────────────────────────────────────────
//
export const isNullWithDefaultVal = (content: string) => {
  return content ? content : 'N/A';
};

//
// ─── IF CONTENT NULL THE REPLACE WITH Empty ────────────────────────────────────────
//
export const isNullWithEmptyVal = (content: string) => {
  return content ? content : 'N/A';
};

export const deCodeText = (data: any) => {
  return utf8.decode(base64.decode(data.replace('data:text/plain; base64,', '')));
};

export const enCodeText = (data: any) => {
  return base64.encode(data);
};
