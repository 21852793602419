import { DateRangePicker } from 'app/ComponentLibrary/DateRangeNew';
import { addYears } from 'date-fns';
import { InlineEditableField } from 'app/components/Common/EditPage/InlineEditableField';
import moment from 'moment';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import React, { useEffect, useContext } from 'react';
import { missingField } from 'app/constants';
import { DrawerContext } from 'app/components/Common/Drawer/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { editableFields } from '../../editUtils';
import { pageAttributesActions } from '../store/placementPageAttributes.redux';
import { selectEditStatus, selectPageAttributes } from '../store/placementPageAttributes.selector';
import { selectEditableData } from '../../../../../../store/redux-store/placement-details/selectors';
import { ActionAccessorHook } from './ActionAccessorHook';

export const AvailableStartDate = ({ maxWidth, id }: { maxWidth?: number | undefined; id: string }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  /** Code: useWatch has been used to retain value while avoidParentForm is triggered in Placement screen wrapper */
  const value = useWatch<string>({ name: 'availabilityDate' });
  const { setPopperOpen } = useContext(DrawerContext);
  const { availabilityDate: status } = useSelector(selectEditStatus);
  const { editOrderStack } = useSelector(selectPageAttributes);
  const { availabilityDate: defaultValue } = useSelector(selectEditableData);

  const dispatch = useDispatch();
  const undoChecker = () => !editOrderStack.some(key => key === 'availabilityDate');

  useEffect(() => {
    return () => {
      setPopperOpen?.('availableStartDate', false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Controller
      control={control}
      name={editableFields.availabilityDate}
      render={({ ref, onChange, ...rest }) => {
        const availableStart = moment(value);
        return (
          <>
            {/** Component is used to access inaccessible methods from react-hook-form Controller
             * to invoke actions - say undo action */}
            <ActionAccessorHook
              actionChecker={undoChecker}
              accessAction={() => onChange(defaultValue)}
              hookDependancies={[editOrderStack]}
            />
            <InlineEditableField
              {...rest}
              id={id}
              maxWidth={maxWidth}
              inputProps={{
                size: 'inline',
                variant: 'outlined',
                initialDateRange: {
                  startDate: availableStart.isValid() ? new Date(value) : undefined,
                  endDate: availableStart.isValid() ? new Date(value) : undefined,
                  useLabelAsValue: true,
                },
                maxDate: addYears(new Date(), 20),
                quickSelect: false,
                disablePortal: true,
                actionBar: false,
                single: true,
                onOpen: open => setPopperOpen?.('availableStartDate', open),
                useMaxWidth: !!maxWidth,
              }}
              label={availableStart.isValid() ? availableStart.format('MM/DD/YYYY') : missingField}
              Control={DateRangePicker}
              onChange={newValue => {
                if (newValue.startDate !== undefined) {
                  const newDate = moment(newValue.startDate);
                  const setTo = newDate.isValid() ? moment(newDate).format('YYYY-MM-DDTHH:mm:ss') : null;
                  onChange(setTo);
                } else {
                  onChange(null);
                }
                dispatch(pageAttributesActions.pushToEditOrderStack(editableFields.availabilityDate));
                // Uncomment if you want to be able to clear the date
                // else onChange(null);
              }}
              setCurrentFocus={state => dispatch(pageAttributesActions.setCurrentFocus(state !== null ? id : null))}
              tooltipText={status.disabled ? t('placement.profile.edit.disableText') : ''}
              {...status}
            />
          </>
        );
      }}
    />
  );
};
