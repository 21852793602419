import React from 'react';
import { Chip } from 'amn-ui-core';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  deselected: {
    margin: '2px',
  },
  selectedDeleteIcon: {
    color: theme.palette.components.chips.tags.backgroundColor,
  },
}));

export const AuditPlacementFilterChips = props => {
  const { chips, onDeleteChip } = props;
  const { classes } = useStyles();

  return chips.map((item, index) => (
    <Chip
      variant={'outlined'}
      label={item.value}
      key={index}
      classes={{
        root: classes.deselected,
        deleteIcon: classes.selectedDeleteIcon,
      }}
      onDelete={() => onDeleteChip(item)}
      deleteIcon={<HighlightOffIcon />}
    />
  ));
};
