/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Grid, ThemeProvider, StyledEngineProvider,useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { reqPref } from 'app/enums/Unit';
import { ExperienceType, SkillSetType } from 'app/models/Unit/CreateUnit';
import { FormProvider, useForm } from 'react-hook-form';
import { ReqPrefSection } from '../Common/ReqPrefSection';
import { SimpleCheckbox } from '../Common/SimpleCheckbox';
import { UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { ExperienceSkillSet } from './ExperienceSkillSet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectIsEditUnit,
  selectLevel,
  selectOrderCreationProcessStep,
  selectOrderDetailsUIView,
} from 'app/components/Order/Store/Order.selectors';
import { getElement } from 'app/components/Order/OrderCreationForm/helper';
import { IOrderDetails } from 'app/models/Orders/OrderDetails';
import { AMNDivisionType } from 'app/models/enums/AMNDivisionType';

interface Props {
  onUnitPropChange: (property: string, value: any) => void;
  division: number | undefined;
  isEdit?: boolean;
  experienceData?: any | null;
  showMandetryStyle?: boolean;
  orderDetails?: IOrderDetails;
}

const useStyles = makeStyles()((theme) => ({
  addDisciplineSpecialty: {
    fontSize: 14,
    color: '#333333',
    fontWeight: 500,
    paddingRight: 23,
  },
}));

export const Experience = (props: Props) => {
  const { onUnitPropChange, division, isEdit, experienceData, showMandetryStyle, orderDetails } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const theme= useTheme();
  const experienceHeader = 'Experience';
  const addDisciplineSpecialty = 'Add Discipline & Specialty';
  const formMethods = useForm();
  const initialData = experienceData
    ? experienceData
    : {
        isNewGrad: false,
        skillSets: [],
      };
  const [experience, setExperience] = React.useState<ExperienceType>(initialData);
  const [isRequired, setIsRequired] = React.useState<boolean>(true);
  const [isNewGrad, setIsNewGrad] = React.useState<boolean>(initialData?.isNewGrad);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.type === 'checkbox') {
      setExperience({ ...experience, isNewGrad: event.target.checked });
    }
    if (event.target.type === 'radio') setIsRequired(event.target.value === reqPref.req);
  };
  const isEditUnit = useSelector(selectIsEditUnit);
  const level = useSelector(selectLevel);
  const storeOrderCreationProcessStep = useSelector(selectOrderCreationProcessStep);
  const selectOrderUiDetails = useSelector(selectOrderDetailsUIView);

  useEffect(() => {
    if (experienceData) {
      setExperience(experienceData);
      setIsNewGrad(experienceData?.isNewGrad);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSkillSetAddition = (value: SkillSetType[]) => {
    setExperience({ ...experience, skillSets: value });
  };

  useEffect(() => {
    onUnitPropChange('experience', experience);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experience]);

  const getNewGradEnabledStatus = () => {
    let enableNewGrad = true;
    if (isEditUnit) {
      enableNewGrad = true;
    }

    if (enableNewGrad && selectOrderUiDetails) {
      enableNewGrad = true;
    }

    if (enableNewGrad && storeOrderCreationProcessStep) {
      enableNewGrad = storeOrderCreationProcessStep.editMode === 'notnow' ? false : true;
    }

    return enableNewGrad;
  };

  const getNewGradStatus = () => {
    let checkBoxStatus = experience.isNewGrad ?? false;
    if (isEditUnit) {
      checkBoxStatus = isNewGrad;
    }
    if (storeOrderCreationProcessStep) {
      checkBoxStatus = experience.isNewGrad ?? false;
    }
    if (selectOrderUiDetails) {
      checkBoxStatus = experience.isNewGrad ?? false;
    }

    return checkBoxStatus;
  };

  return (
    <div>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <FormProvider {...formMethods}>
            <UnitDetailsBody variant="outlined">
              <Grid container direction="column" spacing={2}>
                <Grid item xs={12}>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <UnitCardsTitle>{experienceHeader}</UnitCardsTitle>
                    </Grid>
                    <Grid item>
                      <SimpleCheckbox
                        isChecked={getNewGradStatus()}
                        handleChecked={handleChange}
                        checkboxLabel={t('order.createUnit.experience.newGrad')}
                        checkboxName={'newGrad'}
                        isdisabled={!getNewGradEnabledStatus()}
                        id={'createUnitNewGradCheck'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <div className={`${classes.addDisciplineSpecialty}`}>
                        {addDisciplineSpecialty}
                        <span style={{ color: '#e90707' }}>{t('*')}</span>
                      </div>
                    </Grid>
                    <ReqPrefSection
                      isRequired={isRequired}
                      handleReqPref={handleChange}
                      id={'createUnitDisciplineSpecialtyReqPrefRadio'}
                    />
                    <Grid item xs={12}>
                      <ExperienceSkillSet
                        onNewSkillAddition={handleSkillSetAddition}
                        isRequired={isRequired}
                        hasMultipleChips={division === AMNDivisionType.allied || division === AMNDivisionType.hsg}
                        hasEdit={isEdit}
                        showMandetryStyle={showMandetryStyle}
                        expSkillSetData={orderDetails ? orderDetails.skillsets : experience?.skillSets}
                        orderDetails={orderDetails}
                        isDisabled={isEditUnit ? getElement(level, 'newGrad')?.disabled : false}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </UnitDetailsBody>
          </FormProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};
