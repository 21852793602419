import { instanceOfISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import { filterChoiceWithNewOptions, isItemIncluded, itemIsInRange } from 'app/ComponentLibrary/Filter/helper';
import { UnitFilterName } from '../Filter/UnitFilterConfig';
import { IFilterChipSelected, IFilterSpecs } from '../Store/FacilityUnit.redux';
import { generateFieldChips } from 'app/components/AmWorkDesk/PlacementsDesk/filterHelper';

export const filterUserChoices = (
  filterSpecs: IFilterSpecs,
  values: any,
  setValue: (
    name: string,
    value: unknown,
    options?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
        }>
      | undefined,
  ) => void,
) => {
  return {
    ...values,
    [UnitFilterName.Units]: filterChoiceWithNewOptions(
      values[UnitFilterName.Units],
      filterSpecs.unitIdOpts,
      UnitFilterName.Units,
      setValue,
    ),
    [UnitFilterName.Status]: filterChoiceWithNewOptions(
      values[UnitFilterName.Status],
      filterSpecs.statusOpts,
      UnitFilterName.Status,
      setValue,
    ),
    [UnitFilterName.Discipline]: filterChoiceWithNewOptions(
      values[UnitFilterName.Discipline],
      filterSpecs.disciplineOpts,
      UnitFilterName.Discipline,
      setValue,
    ),
    [UnitFilterName.Specialty]: filterChoiceWithNewOptions(
      values[UnitFilterName.Specialty],
      filterSpecs.specialtyOpts,
      UnitFilterName.Specialty,
      setValue,
    ),
    [UnitFilterName.SubSpecialty]: filterChoiceWithNewOptions(
      values[UnitFilterName.SubSpecialty],
      filterSpecs.subSpecialtyOpts,
      UnitFilterName.SubSpecialty,
      setValue,
    ),
  };
};

export const parseData = data => {
  return data?.reduce(function (newArr, item) {
    newArr.push(parseInt(item));
    return newArr;
  }, []);
};

export const getFilteredData = (filter, sourceData, chipSelected?: IFilterChipSelected) =>
  sourceData.reduce(
    (resultArray, item) =>
      isItemIncluded(item['unitName'], chipSelected?.unit || filter[UnitFilterName.Units]) &&
      isItemIncluded(item['costCenter'], chipSelected?.costCenter || filter[UnitFilterName.CostCenter]) &&
      isItemIncluded(
        item['fyreIntegration'],
        chipSelected?.fyreIntegration || filter[UnitFilterName.FyreIntegration],
      ) &&
      isItemIncluded(item['lastUpdatedBy'], chipSelected?.lastUpdatedBy || filter[UnitFilterName.LastUpdatedBy]) &&
      itemIsInRange(item['lastUpdated'], chipSelected?.lastUpdatedDate || filter[UnitFilterName.LastUpdatedDate]) &&
      isItemIncluded(item['status'], chipSelected?.status || filter[UnitFilterName.Status]) &&
      isSubstringPresent(item['fullSkillSets'], chipSelected?.discipline || filter[UnitFilterName.Discipline]) &&
      isSubstringPresent(item['fullSkillSets'], chipSelected?.specialty || filter[UnitFilterName.Specialty]) &&
      isSubstringPresent(item['fullSkillSets'], chipSelected?.subSpecialty || filter[UnitFilterName.SubSpecialty])
        ? [...resultArray, item]
        : resultArray,
    [],
  );

export const isSubstringPresent = (value, choice) =>
  choice.length
    ? instanceOfISelection(choice[0])
      ? choice.some(subStr => value.includes(subStr.value))
      : choice.some(ch => value.includes(ch))
    : true;

export const createFilterChips = (filter, selectedChips?: IFilterChipSelected) => ({
  [UnitFilterName.Units]: generateFieldChips(filter[UnitFilterName.Units], selectedChips?.unit || undefined),
  [UnitFilterName.Status]: generateFieldChips(
    filter[UnitFilterName.Status],
    selectedChips?.status || undefined,
    null,
    true,
  ),
  [UnitFilterName.Discipline]: generateFieldChips(
    filter[UnitFilterName.Discipline],
    selectedChips?.discipline || undefined,
  ),
  [UnitFilterName.Specialty]: generateFieldChips(
    filter[UnitFilterName.Specialty],
    selectedChips?.specialty || undefined,
  ),
  [UnitFilterName.SubSpecialty]: generateFieldChips(
    filter[UnitFilterName.SubSpecialty],
    selectedChips?.subSpecialty || undefined,
  ),
  [UnitFilterName.LastUpdatedBy]: generateFieldChips(
    filter[UnitFilterName.LastUpdatedBy],
    selectedChips?.lastUpdatedBy || undefined,
  ),
  [UnitFilterName.FyreIntegration]: generateFieldChips(
    filter[UnitFilterName.FyreIntegration],
    selectedChips?.fyreIntegration || undefined,
  ),
  [UnitFilterName.CostCenter]: generateFieldChips(
    filter[UnitFilterName.CostCenter],
    selectedChips?.costCenter || undefined,
  ),
  [UnitFilterName.LastUpdatedDate]: generateFieldChips(
    filter[UnitFilterName.LastUpdatedDate],
    selectedChips?.lastUpdatedDate || undefined,
    'Date Range',
    false,
  ),
});
