import { PayloadAction } from '@reduxjs/toolkit';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { createOrderOcbrActions } from './slice';
import { FacilityService } from 'app/services/FacilityServices/facility-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { trackPromise } from 'react-promise-tracker';
import { selectDocContainer } from './selector';
import {
  IContractDetailsRequest,
  IFacilityRateElementDetailsCommand,
  IOffContractDetailsCommand,
  IUpdateOrder,
  IUpdateOrderShifts,
} from './types';
import { OrderService } from 'app/services/OrderServices/order-service';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import {
  deriveBillRatesTable,
  deriveRateSelections,
  initiateEditRateCard,
  initiateRateCard,
  resetDocuments,
  setDefaultGwwMileageRate,
  setDefaultTravelRate,
  updateBillRates,
  updateEffectiveDates,
  uploadDocuments,
} from './business-saga';
import { selectUser } from 'oidc/user.selectors';
import { Concatenate } from 'utils/string/string';
import { selectFacility } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { SharedService } from 'app/services/SharedServices/shared-service';
import {
  formatTypeAheadDisciplineOptions,
  formatTypeAheadSpecialtyOptions,
  formatTypeAheadSubSpecialtyOptions,
} from 'app/helpers/typeAheadHelper';
import { orderDataActions } from '@AMIEWEB/Order/Store/Order.redux';
import { requestRateElements } from '@AMIEWEB/Order/Store/Order.saga';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import i18next from 'i18next';

const TrackContractDetailsWrapper = (fn, ...args) => trackPromise(fn(...args), 'track-contract-details-request');
const TrackSaveOffContractDetails = (fn, ...args) => trackPromise(fn(...args), 'save-off-contract-details');
const TrackRequestOffContractRateElements = (fn, ...args) =>
  trackPromise(fn(...args), 'request-off-contract-rate-elements');

export function* saveOcbrDocs(action: PayloadAction<number>) {
  try {
    const facilityService: FacilityService = yield getContext('facilityService');
    const docContainer = yield select(selectDocContainer);

    const { userDocumentIds, attachments } = { userDocumentIds: [], attachments: [] };
    docContainer.forEach(item => {
      userDocumentIds.push({ fileName: item.name, documentId: item.hash });
      attachments.push(item.file);
    });

    const user = yield select(selectUser);
    const currentEmployeeName = Concatenate([user.userInfo?.firstName, user.userInfo?.lastName], ' ');
    const currentEmployeeId = user?.userInfo?.employeeId;

    var facilityId = action.payload;

    if (action.payload === 0) {
      const facility = yield select(selectFacility);
      facilityId = facility.facilityResponse.facilityId;
    }

    const createDocuments = new FormData();
    attachments && attachments.map(attachment => createDocuments.append('attachments', attachment));
    createDocuments.append(
      'command',
      JSON.stringify({ facilityId, userDocumentIds, currentEmployeeId, currentEmployeeName }),
    );
    if (docContainer?.length > 0) yield call(facilityService.saveDocuments, createDocuments);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'saveOcbrDocs',
        area: 'store.redux-store.create-order-ocbr.saga.ts',
      },
    });
  }
}

export function* requestContractDetails(action: PayloadAction<IContractDetailsRequest>) {
  try {
    const orderService: OrderService = yield getContext('orderService');

    const response = yield call(TrackContractDetailsWrapper, orderService.getContractDetails, action.payload);

    if (httpSuccess(response.status)) yield put(createOrderOcbrActions.setContractDetails(response.data));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestBillRateDetailsAction',
        area: 'store.redux-store.create-order-ocbr.saga.ts',
      },
    });
  }
}

export function* requestOffContractRateElements(action: PayloadAction<{ facilityId: number }>) {
  try {
    const facilityService: FacilityService = yield getContext('facilityService');
    const response = yield call(
      TrackRequestOffContractRateElements,
      facilityService.getOffContractRateElements,
      action.payload,
    );

    if (httpSuccess(response.status))
      yield put(createOrderOcbrActions.setOffContractRateElements(response.data?.rateElements));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'saveOffcontractDetails',
        area: 'store.redux-store.create-order-ocbr.saga.ts',
      },
    });
  }
}

export function* saveOffcontractDetails(action: PayloadAction<IOffContractDetailsCommand>) {
  try {
    const facilityService: FacilityService = yield getContext('facilityService');
    yield call(TrackSaveOffContractDetails, facilityService.saveOffContractDetails, action.payload);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'saveOffcontractDetails',
        area: 'store.redux-store.create-order-ocbr.saga.ts',
      },
    });
  }
}

export function* saveFacilityRateElementDocumentDetails(action: PayloadAction<IFacilityRateElementDetailsCommand>) {
  try {
    const facilityService: FacilityService = yield getContext('facilityService');
    yield call(facilityService.saveFacilityOffContractRateElementDocuments, action.payload);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'saveFacilityOffContractRateElementDocuments',
        area: 'store.redux-store.create-order-ocbr.saga.ts',
      },
    });
  }
}

export function* requestCreateOrderMiscellaneousData() {
  try {
    const sharedService: SharedService = yield getContext('sharedService');

    const response = yield call(sharedService.getDisciplineSpecialtyData);
    if (httpSuccess(response.status)) {
      const disciplineOpts = formatTypeAheadDisciplineOptions(response.data.disciplines);
      const specialtyOpts = formatTypeAheadSpecialtyOptions(response.data.disciplineSpecialties);
      const subSpecialtyOpts = formatTypeAheadSubSpecialtyOptions(response.data.subSpecialties);
      const divisionOpts = response.data.divisionDisciplines;

      yield put(
        createOrderOcbrActions.setMiscellaneousData([
          { key: 'disciplineOpts', data: disciplineOpts },
          { key: 'specialtyOpts', data: specialtyOpts },
          { key: 'subSpecialtyOpts', data: subSpecialtyOpts },
          { key: 'divisionDisciplineOpts', data: divisionOpts },
        ]),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getCreateOrderMiscellaneousData',
        area: 'store.redux-store.create-order-ocbr.saga.ts',
      },
    });
  }
}

export function* updateOrderSkillsets(action: PayloadAction<IUpdateOrder>) {
  try {
    const orderService: OrderService = yield getContext('orderService');

    const response = yield call(
      TrackContractDetailsWrapper,
      orderService.updateOrderSkillsets,
      action.payload.updateOrderPayload,
    );

    if (httpSuccess(response.status)) {
      yield call(requestRateElements, {
        payload: action.payload.updateOrderPayload.orderId,
        type: orderDataActions.getRateElementsAction.type,
      });
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestBillRateDetailsAction',
        area: 'store.redux-store.create-order-ocbr.saga.ts',
      },
    });
  }
}

export function* updateOrderShifts(action: PayloadAction<IUpdateOrderShifts>) {
  try {
    const orderService: OrderService = yield getContext('orderService');

    const response = yield call(TrackContractDetailsWrapper, orderService.updateOrderShifts, action.payload);

    if (response.status === 200) {
      yield put(orderDataActions.getRateElementsAction(action.payload.orderId));
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('order.orderDetails.network.success'),
          severity: 'success',
        }),
      );
    }
  } catch (error) {
    yield put(
      globalActions.setSnackBar({
        message: 'Order Updation Failed',
        severity: 'error',
      }),
    );
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestBillRateDetailsAction',
        area: 'store.redux-store.create-order-ocbr.saga.ts',
      },
    });
  }
}

export function* createOrderOcbrSaga() {
  yield takeLatest(createOrderOcbrActions.getMiscellaneousData.type, requestCreateOrderMiscellaneousData);
  yield takeLatest(createOrderOcbrActions.getContractDetailsAction.type, requestContractDetails);
  yield takeLatest(createOrderOcbrActions.getOffContractRateElements.type, requestOffContractRateElements);
  yield takeLatest(createOrderOcbrActions.saveOffcontractDetails.type, saveOffcontractDetails);
  yield takeLatest(
    createOrderOcbrActions.saveFacilityOffContractRateElementDocuments.type,
    saveFacilityRateElementDocumentDetails,
  );
  yield takeLatest(createOrderOcbrActions.updateOrderShifts.type, updateOrderShifts);
  yield takeLatest(createOrderOcbrActions.saveDocuments.type, saveOcbrDocs);
  yield takeLatest(createOrderOcbrActions.updateOrderSkillsets.type, updateOrderSkillsets);

  /** Business saga - start */
  yield takeLatest(createOrderOcbrActions.business_initiateRateCard.type, initiateRateCard);
  yield takeLatest(createOrderOcbrActions.business_initiateEditRateCard.type, initiateEditRateCard);
  yield takeLatest(createOrderOcbrActions.business_updateBillRates.type, updateBillRates);
  yield takeLatest(createOrderOcbrActions.business_updateTableRows.type, uploadDocuments);
  // yield takeLatest(createOrderOcbrActions.setDocContainer.type, saveOcbrDocs);
  yield takeLatest(createOrderOcbrActions.business_setDefaultTravelBillRate.type, setDefaultTravelRate);
  yield takeLatest(createOrderOcbrActions.business_setDefaultGwwMileageRate.type, setDefaultGwwMileageRate);
  yield takeLatest(createOrderOcbrActions.business_updateEffectiveDates.type, updateEffectiveDates);
  yield takeLatest(createOrderOcbrActions.business_resetDocuments.type, resetDocuments);

  yield takeLatest(createOrderOcbrActions.business_deriveShiftTableDetails.type, deriveBillRatesTable);
  yield takeLatest(createOrderOcbrActions.business_deriveRateSelections.type, deriveRateSelections);
  /** Business saga - end */
}
