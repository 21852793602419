import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { GwwShift } from './GwwShift';
import { ExpectedHourShift } from './ExpectedHourShift';
import { GwwRateField } from './GwwRowItem';
import { ExpectedHoursRateField } from './ExpectedHoursRowItem';
import { defaultValues } from '../helper';

const useStyles = makeStyles()(theme => ({
  container: {
    overflowY: 'hidden',
    overflowX: 'auto',
    display: 'block',
    marginLeft: '14em',
  },
  title: {
    width: '15%',
    fontSize: '14px',
    textAlign: 'start',
    minWidth: 150,
    wordBreak: 'keep-all',
    position: 'absolute',
    left: 0,
    paddingTop: '21px',
    fontWeight: 500,
  },

  divider: {
    borderRight: '1px solid #e4d9d9',
    height: '110%',
  },
  itemStyle: {
    padding: '6px',
  },
}));

export const RateElementTable = ({ GwwHoursArray, shiftData }) => {
  const { classes } = useStyles();
  return (
    <table className={classes.container}>
      {shiftData.length ? (
        <thead>
          <tr>
            <th />
            {shiftData.map((shift, indx) => (
              <th
                key={indx.toString()}
                className={indx < shiftData.length - 1 ? classes.divider : undefined}
                style={{ fontWeight: 500 }}
              >{`${shift} Hour Shift`}</th>
            ))}
          </tr>
        </thead>
      ) : (
        <tr />
      )}
      <tbody>
        <tr>
          <th className={classes.title}>{`Guaranteed Work Week`}</th>
          {shiftData?.length === 0 ? (
            <td className={classes.itemStyle}>
              <GwwRateField label={`GWW`} name="gww" shiftHours={GwwHoursArray[0]?.GwwHoursArray} />
            </td>
          ) : (
            GwwHoursArray.map((item, index) => {
              return (
                <td key={index.toString()} className={index < shiftData.length - 1 ? classes.divider : undefined}>
                  <GwwShift shifDetails={item} />
                </td>
              );
            })
          )}
        </tr>
        <tr>
          <th className={classes.title}>{`Expected Hours`}</th>
          {shiftData?.length === 0 ? (
            <td className={classes.itemStyle}>
              <ExpectedHoursRateField
                label={`Expected Hours`}
                name="expected-hours"
                shiftHours={defaultValues}
                shiftDetails={{ shiftName: null }}
              />
            </td>
          ) : (
            GwwHoursArray.map((item, index) => {
              return (
                <td key={index.toString()} className={index < shiftData.length - 1 ? classes.divider : undefined}>
                  <ExpectedHourShift shifDetails={item} />
                </td>
              );
            })
          )}
        </tr>
      </tbody>
    </table>
  );
};
