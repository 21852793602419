import { UnitCard } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/CustomComponents/UnitCard';
import React from 'react';
import { LayoutGrid } from 'app/layout/LayoutGrid';
import { useTranslation } from 'react-i18next';
import { Grid } from 'amn-ui-core';
import {
  useSelectedUnitDetails,
  useUnitStatusInActive,
  isAuthorizedForAddEdit,
  getWorkHistoryGapMessage,
} from './unitHelper';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { usePromiseTracker } from 'react-promise-tracker';
import WorkHistoryGapGridSection from './WorkHistoryGapGridSection';

const WorkHistoryGap = () => {
  const { t } = useTranslation();
  const userData = useSelector(selectUser);
  const isAuthorized = isAuthorizedForAddEdit(userData);
  const selectedUnitDetail = useSelectedUnitDetails();
  const isUnitInActive = useUnitStatusInActive();
  const { promiseInProgress: isFacilityWithFilterDetailsLoading } = usePromiseTracker({
    area: 'get-facility-details-with-filters',
    delay: 0,
  });

  return (
    <>
      <UnitCard
        title={t('facility.units.workHistory.title')}
        rightIcon={true}
        showEditIcon={isAuthorized && !isUnitInActive}
        skeletonWidth={91}
        isDataLoading={isFacilityWithFilterDetailsLoading}
      >
        <LayoutGrid container sx={{ p: '12px', m: 0 }} direction="column">
          <>
            <Grid container direction="column" rowSpacing={2}>
              <WorkHistoryGapGridSection
                label={t('facility.units.workHistory.gapGreaterThan')}
                value={getWorkHistoryGapMessage(selectedUnitDetail, t)}
                isDataLoading={isFacilityWithFilterDetailsLoading}
              />
            </Grid>
          </>
        </LayoutGrid>
      </UnitCard>
    </>
  );
};

export default WorkHistoryGap;
