import { ExperienceEditType, ICandidateDetails, ICandidateSaveStatus, SaveStatus } from "app/models/Candidate/CandidateProfile";

export const initialChangeHistory = {
    rows: undefined,
    loading: false,
  };
  
  export const credentialsInitialState = {
    candidateId: 0,
    licenses: [],
    clinicalQualifications: [],
    certifications: [],
    educationOnlineForms: [],
    humanResourcesCredentials: [],
    employeeHealth: [],
    miscellaneous: [],
    reloadData: false,
    isPreview: false,
    attachments: undefined,
    credentialingLookups: [],
    requirementTypes: [],
    contactDrawerOpened: false,
  };
  
  export const candidateWorkExperienceInitialState = {
    totalYearsOfExp: 0,
    totalMonthsOfExp: 0,
    skillsets: [],
  };
  
  export const IUserCommunicationLogInitialState = {
    pageSize: 1,
    pageNumber: 1,
    totalRecords: 0,
    results: [],
    nextPageExists: false,
  };
  
  const initialSaveStatus: ICandidateSaveStatus = {
    dobSaveStatus: SaveStatus.Undefined,
    ssnSaveStatus: SaveStatus.Undefined,
    genderSaveStatus: SaveStatus.Undefined,
  };
  
  export const initialState: ICandidateDetails = {
    travelerId: 0,
    candidateGuid: '',
    brandId: 0,
    brand: '',
    name: {
      prefix: '',
      first: '',
      middle: null,
      last: '',
    },
    goesBy: '',
    adpId: 0,
    nuid: '',
    other: '',
    primaryPhoneNumber: null,
    mobilePhoneNumber: null,
    primaryEmail: '',
    secondaryEmail: '',
    verifiedSkills: [],
    unVerifiedSkills: [],
    groupedSkillsSets: [],
    address: {
      address1: '',
      city: '',
      stateProvinceId: '',
      state: '',
      country: '',
      zip: '',
    },
    phoneNumbers: [],
    candidateStatusId: 0,
    candidateStatus: '',
    audits: [],
    placementStatus: '',
    recruiter: {
      recruiterId: 0,
      first: '',
      last: '',
      email: '',
    },
    availableOn: '',
    lastContactedOn: '',
    gender: '',
    dateOfBirth: '',
    socialSecurityNumber: '',
    candidateSellingNotes: '',
    candidateSummary: '',
    chatHistory: IUserCommunicationLogInitialState,
    licenses: [],
    experience: {
      totalYearsOfExperience: 0,
      totalMonthsOfExperience: 0,
      totalApprovedReferenceCount: 0,
      education: [],
      employment: [],
      skillsets: [],
      maxEmploymentGap: 0,
      candidateWorkExperience: candidateWorkExperienceInitialState,
    },
    supervisor: {
      interested: false,
      availabilityDate: '',
      maxNumber: 0,
      available: 0,
      skillsets: [],
      caseLoad: 0,
    },
  
    investigationSuspension: false,
    investigationSuspensionExplanation: undefined,
    rightToWorkVerification: false,
    visaExplaination: undefined,
    emergencyContact: {
      name: '',
      phone: '',
      relationship: '',
      extensionNotes: '',
      countryDialingCode: '',
    },
    placement: {
      placementDays: 0,
      weeksBooked: 0,
      fileSent: 0,
      placementInfo: [],
    },
    credentials: credentialsInitialState,
    communicationLog: IUserCommunicationLogInitialState,
    gridLogsData: IUserCommunicationLogInitialState,
    channels: [],
    newAttachments: [],
    existingAttachments: [],
    activePlacements: undefined,
    hasActivePlacements:false,
    loading: false,
    disableLoadMore: false,
    scrolled: false,
    isGridLoading: false,
    isEducationActive: false,
    kpis: {},
    shiftsRequested: [],
    timeOffRequests: [],
    contactDrawerOpened: false,
    primaryEmailLastUpdated: '',
    secondaryEmailLastUpdated: '',
    primaryPhoneNumberLastUpdated: '',
    mobilePhoneNumberLastUpdated: '',
    callBackDate: '',
    covidVaccinationstatus: '',
    inactivityReasonId: 0,
    inactivityReason: '',
    skillSetTree: undefined,
    addSkillSetTree: undefined,
    skillsChecklist: undefined,
    masterCopyDropDowns: {
      states: [],
      countries: [],
      degrees: [],
      graduationMonth: [],
      graduationYear: [],
      majors: [],
      disciplines: [],
      specialties: [],
      brands: [],
      discSpecMapping: [],
    },
    disciplineData: [],
    specialtyData: [],
    isAddLicense: false,
    addUnverifiedCredType: '',
    auditData: null,
    referenceData: null,
    changeHistory: initialChangeHistory,
    references: null,
    interviewReasonList: [],
    emrList: [],
    equipmentsProcedure: [],
    bestTimeToContactId: undefined,
    preferredTimeToReach: [],
    sellingNotesItems: [],
    raceEthnicity: {
      ID: '',
      Description: '',
    },
    raceEthnicityOptions: [],
    httpStatus: {
      statusCode: undefined,
      pageName: undefined,
    },
    genderSelection: {
      ID: '',
      Description: '',
    },
    genderOptions: [],
    viewSkillsChecklistPassport: undefined,
    tagsList: undefined,
    candidateSaveStatus: initialSaveStatus,
    ssnTimestamp: '',
    candidateSSN: undefined,
    candidateDateOfBirth: undefined,
    candidateNUID: undefined,
    placementSkillsetExperience: undefined,
    requestReferenceSaveStatus: undefined,
    requestTimeOff: [],
    passportSkillChecklist: undefined,
    referenceGrid: { isLoading: false, data: [] },
    openSkillChecklistDocument: false,
    contactTabSaveStatus: {
      primaryPhoneNumberSaveResult: '',
      secondaryPhoneNumberSaveResult: '',
      primaryEmailSaveResult: '',
      secondaryEmailSaveResult: '',
      primaryPhoneExtNotesSaveResult: '',
      secondaryPhoneExtNotesSaveResult: '',
    },
    editExperience: {
      isModalOpen: false,
      type: ExperienceEditType.None,
      workExperience: undefined,
      timeOff: undefined,
      rowData: undefined,
    },
    hearsayCertifications: [],
    isFilterApplied: false,
    channelValues: [],
    taxData: [],
    taxLetterInfo: [],
    preferences: null,
    editAuditData: {
      editAuditDataDetails: undefined,
      rowData: undefined,
    },
  };
  
  export interface IAvailableChannelFiltersPayload {
    contactId: string;
    brandId?: string;
  }
  
  export interface IGetRequestTimeOff {
    startDate: string;
    endDate: string;
  }