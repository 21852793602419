import React, { useState, useRef } from 'react';
import { Box, TextField, ClickAwayListener, InputAdornment, Popper, Grid, Button, Paper } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { NumberRange } from '../NumberRange/NumberRange';
import { Concatenate } from 'utils/string/string';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import { DisableAppScroll, EnableAppScroll } from 'app/layout/Layout';

const useStyles = makeStyles()(theme => ({
  textField: {
    fontSize: 12,
    '& input::placeholder': {
      fontSize: 12,
    },
  },
  headerContainer: {
    padding: '12px 20px 6px',
  },
  popper: {
    marginTop: 4,
    zIndex: 5000,
  },
  paperDropdown: {
    boxShadow: '0px 2px 8px #0000004D',
  },
  button: {
    fontSize: '12px',
    fontWeight: 'bold',
    textTransform: 'none',
    '&:hover, &:focus': {
      background: 'none',
    },
    opacity: 1,
  },
  actionContainer: {
    backgroundColor: '#ffffff',
    borderTop: '1px solid #cccccc',
    padding: '0px 8px',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  boxContainer: {
    backgroundColor: '#ffffff',
    padding: '0px 20px',
    height: 70,
  },

  inActive: {
    color: '#888888',
  },
  clearAllButton: {
    padding: 0,
  },
  actionButton: {
    padding: 12,
  },
}));

export const getNumberRange = value =>
  value === null
    ? ''
    : Concatenate(
        [value.min === null ? null : value.min.toString(), value.max === null ? null : value.max.toString()],
        ' - ',
      );

export const CustomNumberRange = props => {
  const { classes } = useStyles();
  const textRef = useRef();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [prevState, setPrevState] = useState<any>(props.value);

  const open = Boolean(anchorEl);

  const onClickAway = e => {
    if (e.target.type !== 'submit') cancelChanges();
  };

  const clearAll = () => {
    props.onChange(null);
  };

  const closeActions = () => {
    setAnchorEl(null);
    EnableAppScroll();
  };

  const cancelChanges = () => {
    props.onChange(prevState);
    closeActions();
  };

  const onApplyEvent = () => {
    props.apply(props.value);
    closeActions();
  };

  const handleOpen = event => {
    if (!open) {
      setAnchorEl(event.currentTarget);
      setPrevState(props.value);
      DisableAppScroll();
    } else cancelChanges();
  };

  return (
    <Box>
      <TextField
        inputRef={textRef}
        value={props.value ? `(1) ${props.filterName}` : ''}
        placeholder={props.placeholder || ''}
        variant="outlined"
        size="small"
        classes={{ root: classes.textField }}
        inputProps={{ 'aria-label': 'number-text-field' }}
        onClick={handleOpen}
        style={{ width: props.value ? 175 : 160 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <KeyboardArrowDownIcon style={{ fontSize: 20, color: '#c1c1c1' }} />
            </InputAdornment>
          ),
          style: {
            color: props.value ? '#555555' : undefined,
            borderRadius: 4,
            width: '100%',
            minHeight: '36.7px',
            justifyContent: 'space-between',
            textTransform: 'none',
            fontSize: 12,
            fontWeight: 400,
          },
          // classes: { underline: classes.underline, focused: classes.focused },
        }}
      />
      <Popper open={open} className={classes.popper} anchorEl={anchorEl} placement={'bottom-start'}>
        <ClickAwayListener onClickAway={onClickAway}>
          <Paper classes={{ root: classes.paperDropdown }}>
            <Grid
              item
              container
              justifyContent={'flex-end'}
              alignItems="center"
              classes={{ root: classes.headerContainer }}
            >
              <Button
                aria-label="clear-all-button"
                variant="text"
                color="primary"
                disableRipple
                disableFocusRipple
                disableTouchRipple
                disabled={props.value ? false : true}
                onClick={() => clearAll()}
                classes={{ text: ` ${classes.button} ${classes.clearAllButton}` }}
              >
                {t('search.filter.clearAll')}
              </Button>
            </Grid>
            <div aria-label="custom-number-range" className={classes.boxContainer}>
              <NumberRange onChange={props.onChange} range={props.value} numberRangeType={props.numberRangeType} />
            </div>
            <div className={classes.actionContainer}>
              <Grid item container justifyContent="flex-end" alignItems="center">
                <Button
                  aria-label="cancel-button"
                  variant="text"
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  onClick={() => cancelChanges()}
                  classes={{ text: `${classes.button} ${classes.actionButton} ${classes.inActive}` }}
                >
                  {t('search.filter.cancel')}
                </Button>
                <Button
                  aria-label="apply-button"
                  variant="text"
                  color="primary"
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  onClick={() => onApplyEvent()}
                  classes={{ text: `${classes.button} ${classes.actionButton}` }}
                >
                  {t('search.filter.apply')}
                </Button>
              </Grid>
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};
