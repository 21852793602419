import { makeStyles } from 'tss-react/mui';

export const useEditReferencesStyles = makeStyles()(theme => ({
  item: {
    paddingTop: '12px !important',
    width: '400px',
  },
  dialogContainer: {
    minWidth: '600px',
  },
  dialogContent: {
    paddingTop: '24px !important',
    paddingBottom: '50px',
  },
  fieldStyle: {
    '& .MuiFilledInput-root': {
      backgroundColor: `${theme.palette.system.grayChip} !important`,
      border: '0px',
      fontWeight: '400',
    },
    '&:hover .MuiFilledInput-root': {
      backgroundColor: `${theme.palette.system.silver} !important`,
    },
    '&:hover .MuiInputLabel-root': {
      color: `${theme.palette.system.primary} !important`,
    },
    '.MuiInputLabel-root': {
      fontWeight: '400 ',
    },
  },
  fieldStyleNonHover: {
    '& .MuiFilledInput-root': {
      backgroundColor: `${theme.palette.system.grayChip} !important`,
      border: '0px',
      fontWeight: '400',
    },
    '.MuiInputLabel-root': {
      fontWeight: '400 ',
    },
  },
}));

export const useReferencesStyles = makeStyles()(theme => ({
  dialogContainer: {
    minHeight: '300px',
    minWidth: '500px',
  },
  dialogContainerGrid: {
    width: '100%',
    padding: '0px 0px 0px 0px !important',
  },
  textBold: {
    fontSize: '12px !important',
    color: theme.palette.components.typography.color,
    fontWeight: 'bold',
  },
  avatar: {
    width: '22px',
    background: theme.palette.primary.main,
    height: '22px',
    fontSize: '14px',
  },
}));
