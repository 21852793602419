import { Employment } from 'app/models/Candidate/CandidateProfile';
import { GetMomentOfDate } from 'utils/dates/moment';

export function getExperienceData(years: number | undefined, months: number | undefined) {
  if (years == null && months == null) return '';
  else if (years == null || years === 0) return `${months}M`;
  else if (months == null || months === 0) return `${years}Y`;
  else return `${years}Y ${months}M`;
}

export function getDateRange(startDate: string, endDate: string) {
  const startMoment = GetMomentOfDate(startDate);
  const endMoment = GetMomentOfDate(endDate);

  if (startMoment.isValid() && endMoment.isValid()) {
    return `${startMoment.format('MM/DD/YYYY')} - ${endMoment.format('MM/DD/YYYY')}`;
  } else if (startMoment.isValid() && !endMoment.isValid()) {
    return `${startMoment.format('MM/DD/YYYY')} - Current`;
  } else if (!startMoment.isValid() && endMoment.isValid()) {
    return `Current - ${endMoment.format('MM/DD/YYYY')}`;
  } else return '';
}

export function getFirstFour(array: Employment[]) {
  if (array) return array.length > 4 ? array.slice(0, 4) : array;
  return [];
}
