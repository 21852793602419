/* eslint-disable @typescript-eslint/naming-convention */
import { Grid, XGrid as AMNXGrid, materialUiXGrid, Link, Skeleton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import _ from 'lodash';
import { XGridPagination } from './pagination';
import {
  DataGridProProps,
  GridSortModel,
  GridColumns,
  GridSelectionModel,
  GridCallbackDetails,
  GridState,
  GridFilterModel,
  GridColumnOrderChangeParams,
  MuiBaseEvent,
  GridColumnVisibilityModel,
  gridVisibleRowCountSelector,
} from '@mui/x-data-grid-pro';
import { XGridPaginationProps } from './pagination/XGridPagination';
import { useDispatch, useSelector } from 'react-redux';
import { gridStateActions } from '../Grid/GridStateManagement/GridState.redux';
import { IXGridToolbarProps, XGridToolbar } from './Toolbar/XGridToolbar';
import { formatToAmericanNotaion } from 'app/helpers/numberHelper';
import {
  gridSelectionActions,
  gridSelectionReducer,
  gridSelectionSliceKey,
} from '../Grid/GridSelection/GridSelection.redux';
import { selectGridSelection } from '../Grid/GridSelection/GridSelection.selector';
import { useInjectReducer } from 'utils/redux-injectors';
import { selectGridState } from '../Grid/GridStateManagement/GridState.selectors';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import debounce from 'lodash.debounce';
import { GridColumns as CGridColumns } from 'app/models/GlobalSearch/GlobalSearch';
import { formatMultiSortColumns } from '@AMIEWEB/GlobalSearch/helper';

export const GridSkeleton = props => {
  return (
    <Grid container rowSpacing={2} xs={12}>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles<{
  height: number;
  hideFooter: boolean;
  autoHeight: boolean;
  pagination: boolean;
  bannerIsVisible: boolean;
}>()((theme, props) => ({
  root: {
    backgroundColor: 'white',
  },
  heightLimit: {
    minHeight: '50px',
    maxHeight: `${props.height ? props.height : window.innerHeight / 1.9}px`,
    overflow: props.autoHeight && 'auto',
  },
  innerHeightLimit: {
    minHeight: '50px',
    maxHeight: `${
      props.bannerIsVisible && props.height ? props.height - 70 : props.height ? props.height : window.innerHeight / 1.9
    }px`,
    fontSize: '14px',
    bottom: props.bannerIsVisible && '3px',
  },
  withRowsHeight: {
    height: `${props.height ? props.height : window.innerHeight / 1.9}px`,
    fontSize: '14px',
  },
  columnHeaders: {
    minHeight: props.pagination === false && props.hideFooter === true ? '76px' : '200px',
    fontSize: '14px',
  },
  header: {
    backgroundColor: theme.palette.framework.system.whisper,
    fontSize: '12px',
  },
  main: {
    minHeight: props.pagination === false && props.hideFooter === true ? '76px' : '150px',
  },
}));
interface AMNXGridProps extends Omit<DataGridProProps, 'onColumnOrderChange' | 'onColumnResize'> {
  disablePaggination?: boolean;
  height?: number;
  paginationProps?: XGridPaginationProps;
  title?: JSX.Element | string;
  titleBreadCrumbs?: JSX.Element | null;
  secondaryTitle?: string | null;
  titleLink?: JSX.Element | null;
  disableTitleCount?: boolean;
  toolbar?: boolean;
  defaultRowsPerPage?: number;
  dataRowCountDisplay?: number;
  rowCountDisplay?: number;
  gridTag?: string;
  actionItems?: unknown[];
  defaultSortModel?: GridSortModel;
  defaultColumns?: GridColumns;
  defaultColumnVisibilityModel?: GridColumnVisibilityModel;
  /** If this grid is in a dialog, set this to true for toolbar controls to display properly */
  inDialog?: boolean;
  bannerIsVisible?: boolean;
  disableStateRowSelection?: boolean;
  toolbarProps?: Omit<IXGridToolbarProps, 'setColumnsButtonEl' | 'setFilterButtonsEl' | 'panelOpen'>;
  onColumnOrderChange?: (
    params: GridColumnOrderChangeParams,
    event: MuiBaseEvent,
    details: GridCallbackDetails,
    columns: GridColumns,
  ) => void;
  onColumnResizeStop?: (columns: GridColumns) => void;
  showSelectedRowCount?: boolean;
  displayCustomCount?: number;
}
/**
 * Material UI X-Grid
 * @param autoHeight grid's height will be auto calculated based on the number of rows. This disables sticky header
 * @param height set a fixed height/maxHeight for the grid. autoHeight will override this property if set to true
 * @returns JSX Element
 */
export const XGrid = ({
  apiRef,
  columns,
  defaultSortModel,
  defaultColumns,
  defaultColumnVisibilityModel,
  rows,
  density = 'compact',
  height,
  autoHeight = false,
  bannerIsVisible = false,
  pagination = true,
  title = 'Showing Results',
  titleBreadCrumbs = null,
  secondaryTitle = null,
  titleLink = null,
  disableTitleCount = false,
  rowsPerPageOptions = [10, 20, 50, 100],
  defaultRowsPerPage = 20,
  hideFooterRowCount = true,
  toolbar = true,
  rowCountDisplay,
  dataRowCountDisplay = -1,
  actionItems,
  gridTag,
  disableMultipleColumnsSorting = true,
  inDialog,
  disableStateRowSelection = false,
  disablePaggination = false,
  onColumnOrderChange,
  onColumnResizeStop,
  components,
  showSelectedRowCount = true,
  displayCustomCount,
  ...rest
}: AMNXGridProps) => {
  const { classes, cx } = useStyles({ bannerIsVisible, height, autoHeight, pagination, hideFooter: rest.hideFooter });
  const _apiRef = materialUiXGrid.useGridApiRef();
  const dispatch = useDispatch();
  useInjectReducer({ key: gridSelectionSliceKey, reducer: gridSelectionReducer });
  const { selectedData } = useSelector(selectGridSelection);
  const { pageSize, pageNumber, highlightedRow } = useSelector(selectGridState);
  const [filteredRowCount, setFilteredRowCount] = React.useState<number | null | undefined>(undefined);
  const [customColumns, setCustomColumns] = React.useState<boolean>(false);
  const [customSort, setCustomSort] = React.useState<boolean | undefined>();
  const [filterButtonEl, setFilterButtonEl] = React.useState<HTMLButtonElement | null>(null);
  const [columnsButtonEl, setColumnsButtonEl] = React.useState<HTMLButtonElement | null>(null);
  const [panelOpen, setPanelOpen] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (!_.isEqual(rest?.sortModel, defaultSortModel)) {
      setCustomSort(true);
    } else {
      setCustomSort(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest?.sortModel, defaultSortModel]);

  React.useEffect(() => {
    dispatch(gridStateActions.setPageAttributes({ pageNumber: 1, pageSize: defaultRowsPerPage }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const body: HTMLCollectionOf<HTMLBodyElement> = document.getElementsByTagName('body');
    if (inDialog && body) body[0].setAttribute('data-dialog', 'true');
    return () => {
      if (inDialog && body) body[0].removeAttribute('data-dialog');
    };
  }, [inDialog]);

  React.useEffect(() => {
    if (_apiRef && _apiRef?.current) {
      if (apiRef) apiRef.current = _apiRef?.current;
      _apiRef?.current?.setPageSize?.(defaultRowsPerPage);
    }
    return () => {
      dispatch(gridStateActions.setHighlightedRow(undefined));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_apiRef]);

  React.useEffect(() => {
    if (filteredRowCount === undefined) {
      if (rest.filterModel?.items) {
        setFilteredRowCount(gridVisibleRowCountSelector(_apiRef));
      } else setFilteredRowCount(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_apiRef, rest.filterModel]);

  const _onPageChange = (page: number, details: GridCallbackDetails) => {
    _apiRef?.current.resize();
    dispatch(gridStateActions.setPageNumber(page + 1));
    dispatch(gridStateActions.setHighlightedRow(undefined));
    dispatch(
      gridSelectionActions.setGridSelections({
        selectedData: [],
      }),
    );
    _apiRef?.current?.setSelectionModel([]);
    rest.onPageChange?.(page, details);
  };

  /**
   * Callback when the XGrid pagination page size changes
   */
  const _onPageSizeChange = (size: number, details: GridCallbackDetails) => {
    dispatch(gridStateActions.setPageAttributes({ pageNumber: 1, pageSize: size }));
    dispatch(gridStateActions.setHighlightedRow(undefined));
    dispatch(
      gridSelectionActions.setGridSelections({
        selectedData: [],
      }),
    );
    _apiRef?.current?.setSelectionModel([]);
    rest.onPageSizeChange?.(size, details);
  };

  /**
   * Callback when the XGrid row selection model changes
   */
  const _onSelectionModelChange = (selectionModel: GridSelectionModel, details: GridCallbackDetails) => {
    if (!disableStateRowSelection) {
      dispatch(gridSelectionActions.setGridSelections({ selectedData: selectionModel }));
    }
    rest.onSelectionModelChange?.(selectionModel, details);
  };

  /**
   * Callback when the XGrid sort model changes
   */
  const _onSortModelChanged = (model: GridSortModel, details: GridCallbackDetails) => {
    dispatch(gridStateActions.setSortedColumn(model[0] ? { column: model[0].field, direction: model[0].sort } : null));

    const multiSortedColumns = model?.length > 0 ? formatMultiSortColumns(model) : undefined;
    dispatch(gridStateActions.setMultiSortedColumn(multiSortedColumns));

    if (!_.isEqual(defaultSortModel, [model[0]])) setCustomSort(true);
    else setCustomSort(false);
    rest.onSortModelChange?.(model, details);
  };

  /**
   * Callback when the XGrid state changes
   * Debounced after 500 milliseconds
   */
  const _onStateChange = (state: GridState, event, details: GridCallbackDetails) => {
    const c = new CGridColumns(defaultColumns, defaultColumnVisibilityModel);

    if (columns?.filter?.(column => column.filterable)?.length > 0) {
      setFilteredRowCount(
        (state.filter.filterModel?.items || []).some(
          filter => !isNullOrUndefined(filter?.value) && filter?.value !== '',
        )
          ? Object.entries(_apiRef.current?.state.filter.filteredRowsLookup ?? {})?.filter(item => item[1]).length
          : null,
      );
    } else {
      setFilteredRowCount(null);
    }

    if (
      !c.isEqual(_apiRef?.current?.getAllColumns()) ||
      (defaultColumnVisibilityModel &&
        rest.columnVisibilityModel &&
        !c.isVisibilityModelEqual(state.columns.columnVisibilityModel))
    ) {
      setCustomColumns(true);
    } else {
      setCustomColumns(false);
    }
    rest.onStateChange?.(state, event, details);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/naming-convention
  const _onStateChangeDebounce = React.useCallback(debounce(_onStateChange, 250), [columns, defaultColumns]);

  /**
   * Callback when a filter is applied to the grid
   * @param model: Model returned from XGrid
   */
  const _onFilterModelChange = (model: GridFilterModel, details: GridCallbackDetails) => {
    rest?.onFilterModelChange?.(model, details);
  };

  React.useEffect(() => {
    removeRowHighlight();
    if (highlightedRow) {
      const docs = document.querySelectorAll(`[data-id="${highlightedRow}"]`);
      docs?.forEach(item => {
        if (!item.classList.contains('xgrid-row-highlight')) {
          item.classList.add('xgrid-row-highlight');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedRow]);
  const removeRowHighlight = () => {
    const prevDocs = document.getElementsByClassName('xgrid-row-highlight');
    for (let i = 0; i < prevDocs.length; i++) {
      prevDocs[i].classList.remove('xgrid-row-highlight');
    }
  };

  const GridSubTitle = React.useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    return displayCustomCount >= 0
      ? `(${displayCustomCount}) ${secondaryTitle ? secondaryTitle : ''}`
      : !disableTitleCount &&
          `(${formatToAmericanNotaion(
            selectedData?.length > 0 && showSelectedRowCount
              ? selectedData.length
              : !isNullOrUndefined(filteredRowCount)
              ? filteredRowCount
              : dataRowCountDisplay > -1
              ? dataRowCountDisplay
              : rowCountDisplay
              ? rowCountDisplay
              : rest.rowCount
              ? rest.rowCount
              : rows.length,
          )}) ${secondaryTitle ? secondaryTitle : ''}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    disableTitleCount,
    secondaryTitle,
    selectedData,
    filteredRowCount,
    rowCountDisplay,
    rest,
    rows,
    displayCustomCount,
  ]);

  const GridTitle = React.useMemo(() => {
    return title ? (
      typeof title === 'string' ? (
        <div style={{ fontSize: '16px', fontWeight: 400 }} id={`xgrid-title-${title ?? gridTag ?? 'component'}`}>
          {!titleLink && `${title} ${GridSubTitle}`}
          {titleLink && `${title} for ${titleLink} ${GridSubTitle}`}
        </div>
      ) : (
        title
      )
    ) : undefined;
  }, [GridSubTitle, title, titleLink]);

  const _onColumnOrderChange = (
    params: GridColumnOrderChangeParams,
    event: MuiBaseEvent,
    details: GridCallbackDetails,
  ) => {
    onColumnOrderChange?.(params, event, details, _apiRef?.current?.getAllColumns());
  };
  React.useEffect(() => {
    return _apiRef?.current?.subscribeEvent('columnResizeStop', params => {
      onColumnResizeStop?.(_apiRef?.current?.getAllColumns());
    });
  }, [_apiRef, onColumnResizeStop]);

  React.useEffect(() => {
    return _apiRef.current.subscribeEvent('preferencePanelClose', params => {
      setPanelOpen(undefined);
    });
  }, [_apiRef, onColumnResizeStop]);

  React.useEffect(() => {
    return _apiRef.current.subscribeEvent('preferencePanelOpen', params => {
      setPanelOpen(params.openedPanelValue);
    });
  }, [_apiRef, onColumnResizeStop]);
  return (
    <div
      className={cx(classes.root, {
        [classes.heightLimit]: !autoHeight,
        [classes.withRowsHeight]: rows.length > 0 && !autoHeight,
      })}
    >
      <Grid container spacing={2}>
        {(actionItems || titleBreadCrumbs) && (
          <Grid id="xgrid-title-container" item xs={12}>
            <Grid container alignItems="flex-end" alignContent="flex-end">
              <Grid item xs={6}>
                {titleBreadCrumbs && titleBreadCrumbs}
              </Grid>
              <Grid item container xs={6} spacing={1} justifyContent="flex-end">
                {actionItems?.map((action, i) => (
                  <Grid key={`global-search-action-${i}`} id={`x-grid-action-item-${i}`} item>
                    {action}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <AMNXGrid
            className={cx(classes.columnHeaders, {
              [classes.innerHeightLimit]: !autoHeight,
              [classes.withRowsHeight]: (components?.NoRowsOverlay || rows.length > 0) && !autoHeight,
            })}
            classes={{
              main: classes.main,
              columnHeader: classes.header,
            }}
            apiRef={_apiRef}
            density={density}
            columns={columns}
            autoHeight={autoHeight}
            components={{
              Toolbar: toolbar ? XGridToolbar : undefined,
              Pagination: props => {
                return !disablePaggination ? (
                  <XGridPagination
                    {...props}
                    paginationProps={rest.paginationProps}
                    defaultRowsPerPage={pageSize || defaultRowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    loading={rest.loading}
                  />
                ) : null;
              },
              LoadingOverlay: props => <GridSkeleton />,
              ...components,
            }}
            page={((pageNumber ?? 0) === 0 ? 1 : pageNumber) - 1}
            pagination={pagination}
            rows={rest.loading ? [] : rows}
            pageSize={pageSize || defaultRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            hideFooterRowCount={hideFooterRowCount}
            disableMultipleColumnsSorting={disableMultipleColumnsSorting}
            {...rest}
            // don't override these handlers
            componentsProps={{
              panel:
                panelOpen === 'columns'
                  ? { anchorEl: columnsButtonEl, placement: 'bottom-end' }
                  : panelOpen === 'filters' && rest.toolbarProps?.filterButton
                  ? { anchorEl: filterButtonEl, placement: 'bottom-end' }
                  : undefined,
              toolbar: {
                setFilterButtonEl,
                setColumnsButtonEl,
                ...rest.toolbarProps,
                title: GridTitle,
                resetColumns: () => {
                  setCustomColumns(false);
                  setCustomSort(false);
                  rest?.toolbarProps?.resetColumns?.();
                },
                resetButton: rest?.toolbarProps?.resetButton ?? (customColumns || customSort),
                panelOpen,
              },
            }}
            onPageChange={_onPageChange}
            onPageSizeChange={_onPageSizeChange}
            onSelectionModelChange={_onSelectionModelChange}
            onSortModelChange={_onSortModelChanged}
            onStateChange={_onStateChangeDebounce}
            onFilterModelChange={_onFilterModelChange}
            onColumnOrderChange={_onColumnOrderChange}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export const CustomTitleLink = props => {
  const { text, subDir1, subDir2, subDir3, count } = props;
  const link = subDir3 !== '' ? `/${subDir1}/${subDir2}/${subDir3}` : `/${subDir1}/${subDir2}`;
  return subDir1 === 'candidate' && !subDir3 ? (
    <>{`Tasks for ${text}`}</>
  ) : (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <>{`Tasks for `}</>
      <Link href={link}>{text}</Link>
      <>{` ( ${count} )`}</>
    </div>
  );
};
