import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import { selectPlacementStatus } from 'store/redux-store/placement-status/selectors';
import { selectSubmissionInfo } from 'store/redux-store/placement-summary/selectors';
import { useEditableRequirement } from './EditRequirementsHelper';
import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { useTranslation } from 'react-i18next';
import { OrderSsnRequirementCheck, checkSsnRequirement } from './EditablePassFailLogic';
import { ff_placement_ui_requirement_check } from 'app/constants/FeatureFlags/Placement/Keys';
import { useDecision } from '@optimizely/react-sdk';

export const useEditableRequirementOrderSsn = () => {
  const [requirementCheckFlag] = useDecision(ff_placement_ui_requirement_check);
  const ssn: string | undefined = useWatch({ name: 'ssn' });
  const { currentPlacementStatus } = useSelector(selectPlacementStatus);
  const placementOrderType = useSelector(selectPlacementOrderType);
  const { t } = useTranslation();
  const {
    orderReq: {
      order: { orderSsn },
    },
  } = useSelector(selectSubmissionInfo);

  useEditableRequirement({
    data: requirementCheckFlag.enabled ? ssn : undefined,
    requirementId: IPlacementRequirementKey.orderSsn,
    requirementKey: 'orderSsn',
    requirementCheck: OrderSsnRequirementCheck,
    shouldAddFailedRequirement: checkSsnRequirement(currentPlacementStatus, placementOrderType, orderSsn?.type, ssn),
    failRequirement: {
      id: IPlacementRequirementKey.orderSsn,
      message: t('placement.profile.summary.submission.order.socialSecurityNum.line3'),
      tooltip: {
        message: t('placement.profile.summary.submission.order.socialSecurityNum.line2'),
      },
    },
  });
};
