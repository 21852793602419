import { Box, Button, CircularProgress } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import AddIcon from '@mui/icons-material/Add';
import { usePromiseTracker } from 'react-promise-tracker';
import { PromiseTrackerKeys } from 'app/constants/PromiseTrackerKeys';
import LoadingButton from '@mui/lab/LoadingButton';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 30,
  },
  title: {
    fontSize: 16,
    fontWeight: 450,
    color: theme.palette.system.darkGray,
  },
  btn: {
    padding: 0,
    marginTop: -6,
    fontSize: 12,
    fontWeight: 450,
    color: theme.palette.system.darkGray,
    '&:hover': {
      background: 'none',
      color: theme.palette.components.button.secondary.backgroundColor,
    },
  },
  startIcon: {
    marginRight: 2,
  },
}));

export const GenerateHeader = ({ title, generate = false, onClick = undefined, isGenerating = false }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { promiseInProgress: isGeneratingDraft } = usePromiseTracker({
    area: PromiseTrackerKeys.placements.generateDraft,
  });
  const { promiseInProgress: isSavingingDraft } = usePromiseTracker({ area: PromiseTrackerKeys.placements.saveDraft });
  const { promiseInProgress: isDeletingDraft } = usePromiseTracker({ area: PromiseTrackerKeys.placements.deleteDraft });
  const { promiseInProgress: isCreatingDoc } = usePromiseTracker({
    area: PromiseTrackerKeys.placements.createDocument,
  });

  const isCallingAPI = React.useMemo(
    () => isGeneratingDraft || isDeletingDraft || isCreatingDoc || isSavingingDraft,
    [isGeneratingDraft, isDeletingDraft, isCreatingDoc, isSavingingDraft],
  );

  return (
    <Box className={classes.container}>
      <div className={classes.title}>{title}</div>
      {generate ? (
        <LoadingButton
          disabled={isCallingAPI}
          loading={isGenerating}
          startIcon={<AddIcon />}
          variant="text"
          onClick={isGenerating ? undefined : onClick}
          classes={{ root: classes.btn, startIcon: classes.startIcon }}
        >
          {t('Generate')}
        </LoadingButton>
      ) : null}
    </Box>
  );
};
