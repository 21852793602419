import { DetailsPage } from 'app/layout/pages/DetailsPage';
import React, { createRef, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserManagementFilter } from './Filter';
import { UserManagementChips } from './Chips';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { getUserManagementActions } from './store/userManagement.redux';
import ParentContainer from './ParentContainer';
import { initialUserDetailFields, tabValue } from './Helper';
import CreateNewUserModal from './CreateNewUserModal';
import { DiscardChangesDialog } from './DiscardChangesDialog/DiscardChangesDialog';
import { selectIsDataChanged } from './store/userManagement.selector';

const useStyles = makeStyles()(() => ({
  header: {
    fontSize: '18px',
    fontWeight: 'bold'
  }
}))
export const UserDetailsPage = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const formMethods = useForm();
  const [dialogeOpen, setDialogeOpen] = useState<boolean>(false);
  const [currentSelectedFilters, setCurrentSelectedFilters] = useState(null);
  const [previousSelectedFilters, setPreviousSelectedFilters] = useState(null);

  const [pageSize, setPageSize] = React.useState<number>(25);
  const {
    handleSubmit,
    setValue
  } = formMethods;
  const dispatch = useDispatch();
  const hiddenSubmitButton = createRef<HTMLButtonElement>();
  const isDataChanged = useSelector(selectIsDataChanged);

  useEffect(() => {
    dispatch(getUserManagementActions.setDialoge(false));
    dispatch(getUserManagementActions.setCheckedRoles([]));
    dispatch(getUserManagementActions.setSelectedCardId(null));
    dispatch(getUserManagementActions.setIsDataChanged(false));
    dispatch(getUserManagementActions.setCustomTabValue(tabValue.details));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applyingFilter = (values: any) => {
    dispatch(getUserManagementActions.setSelectedFilters(values));
    dispatch(getUserManagementActions.setUserDetailsFields(initialUserDetailFields));
    dispatch(getUserManagementActions.setDisableUserFieldEdit(true));
    const requestPayload = {
      firstName: values?.employeeNames?.value?.split('_')?.[0],
      lastName: values?.employeeNames?.value?.split('_')?.[1],
      userName: values?.usernames?.value,
      statusId: Number(values?.employeeStatus?.value) ?? null,
      employeeId: Number(values?.eid?.value) ?? null,
      employeeRoleId: Number(values?.employeeRole?.value) ?? null,
      companyId: Number(values?.companies?.value) ?? null,
      pageSize: 25,
      pageNumber: 1,
    };
    dispatch(getUserManagementActions.getUserSearchLists(requestPayload));
    if (pageSize > 25) {
      dispatch(getUserManagementActions.setSelectedFiltersFlag(true));
    }
    dispatch(getUserManagementActions.setEmpRoles([]));
    dispatch(getUserManagementActions.setEmpInfo([]));
    dispatch(getUserManagementActions.setEmpCardInfo(null));
    dispatch(getUserManagementActions.setemployeeAssociations([]));
    dispatch(getUserManagementActions.setUserDetailsFields([]));
  }

  const onApplyFilter = (values) => {
    setCurrentSelectedFilters(values);
    if (isDataChanged) {
      setDialogeOpen(true);
    } else {
      setPreviousSelectedFilters(values);
      applyingFilter(values);
    }
  };

  const handleChanges = () => {
    setDialogeOpen(false);
    applyingFilter(currentSelectedFilters);
  }

  const keepChanges = () => {
    setDialogeOpen(false);
    dispatch(getUserManagementActions.setSelectedFilters(previousSelectedFilters));
    dispatch(getUserManagementActions.setSelectedFiltersFlag(true));
    setValue('employeeNames', previousSelectedFilters?.employeeNames);
    setValue('eid', previousSelectedFilters?.eid);
    setValue('employeeRole', previousSelectedFilters?.employeeRole);
    setValue('usernames', previousSelectedFilters?.usernames);
    setValue('companies', previousSelectedFilters?.companies);
    setValue('employeeStatus', previousSelectedFilters?.employeeStatus);
  }

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(filter => onApplyFilter(filter))}>
          <DetailsPage
            title={t('userManagement.userManagementTitle')}
            head={<div className={classes.header}>Search User</div>}
            filters={<UserManagementFilter onApplyFilter={onApplyFilter} />}
            chips={<UserManagementChips />}
            fullPage
            fullHeight
          >
            <ParentContainer setPageSize={setPageSize} onApplyFilter={onApplyFilter} />
          </DetailsPage>
          <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
        </form>
      </FormProvider>
      <CreateNewUserModal />
      <DiscardChangesDialog dialogeOpen={dialogeOpen} handleChanges={handleChanges} keepChanges={keepChanges} />
    </>
  );
};


