import { selectPreferredQualificationText } from '@AMIEWEB/NewOrder/Store/Order.selectors';
import { UnitDetailsTextArea } from '@AMIEWEB/Order/OrderCreationForm/Common/UnitDetailsCommonTextArea';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FormPageType } from '../UnitRequirements/Experience/SubCertificate/SubCertificate.types';

function PreferredQualifications({ parentPage }: { parentPage: FormPageType }) {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const selectedOrderPreferredQualificationText = useSelector(selectPreferredQualificationText);

  return (
    <Controller
      control={control}
      name={'preferredQualifications'}
      render={({ ref, onChange, ...rest }) => (
        <UnitDetailsTextArea
          fieldLabel={t('facility.units.unitDetails.qualificationAndJobDetails.preferredQualifications')}
          fieldName={'preferredQualifications'}
          id={'preferredQualifications'}
          onChange={onChange}
          isDisabled={false}
          inputValue={rest.value}
          defaultInputValue={parentPage === FormPageType?.Order ? selectedOrderPreferredQualificationText : ''}
          showMaxCount
          maxCharacters={8000}
        />
      )}
    />
  );
}

export default PreferredQualifications;
