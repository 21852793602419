import { getStatusAge } from 'utils/common/statusAge';
import { GetMomentOfDate } from 'utils/dates/moment';
import { Concatenate } from 'utils/string/string';

export const ModalBindData = categoryData => {
  const dateMoment = GetMomentOfDate(categoryData.statusChangeDate);
  const ageValue = dateMoment.isValid() ? getStatusAge(dateMoment) : null;

  const data = {
    candidateId: categoryData.candidateId,
    candidate: Concatenate([categoryData.candidateFirstName, categoryData.candidateLastName], ' '),
    pid: categoryData.placementId,
    recruiter: Concatenate([categoryData.recruiterFirstName, categoryData.recruiterLastName], ' '),
    company: categoryData.brand,
    brandId: categoryData.brandId,
    facilityId: categoryData.facilityID,
    facility: categoryData.facility,
    city: categoryData.city,
    accountManager: Concatenate([categoryData.accountManagerFirstName, categoryData.accountManagerLastName], ' '),
    status: categoryData.placementStatus,
    // statusAge: getCueTimeValue(categoryData.statusChangeDate),
    statusAge: ageValue?.message,
    statusAgeValue: ageValue?.totalMinutes,
  };
  return data;
};
