import React from 'react';
import {
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  Grid,
  Box,
  TableFooter,
  Button,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { ICustomHeader, gridControlType } from 'app/models/Grid';
import HoverView from './HoverView';

const useSubtableStyles = makeStyles<{ disableRowColors: boolean; width: string | number }>()(
  (theme, { disableRowColors, width }) => ({
    table: {
      tableLayout: 'auto',
    },
    subTableContainer: {
      maxHeight: '360px',
      overflowY: 'auto',
      backgroundColor: disableRowColors ? '#FFF' : '#E3F0F8',
      margin: '0px 0px 0px 0px',
      padding: ' 6px 16px 6px 16px',
      width: '100%',
    },
    subTableContainerLastRow: {
      margin: '0px',
    },

    subTableCell: {
      color: '#333333',
      textAlign: 'left',
      fontSize: '14px',
      borderBottom: 'none',
      padding: '6px 16px',
      width: width,
      minHeight: '50px',
      verticalAlign: 'top',
    },
    subtable: {
      margin: '40px',
      fontSize: '11px',
    },
    editButton: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    subTableHead: {
      backgroundColor: disableRowColors ? '#FFF' : '#E3F0F8',
      fontSize: '13px',
      fontWeight: 'bold',
      wordBreak: 'keep-all',
    },
    subTableHeaderCell: {
      fontWeight: 'bold',
      marginLeft: '10px',
      textAlign: 'left',
      color: '#555555',
      fontSize: '14px',
      borderBottom: 'none',
      padding: '0px 16px',
      width: width,
    },
    footer: {
      height: '50px',
      marginTop: '10px',
    },
    footerNoData: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      padding: '20px',
    },
    expandedTableCell: {
      width: '40px !important',
      paddingBottom: '10px',
    },
    midRow: {
      borderBottom: '1px solid lightgrey',
      paddingBottom: '10px',
      marginBottom: '15px',
      overflow: 'hidden',
      margin: '2px',
    },
    midRowLast: {
      paddingBottom: '10px',
    },
    parentRow: {
      borderBottom: '1px solid grey',
      marginBottom: '15px',
    },
    editFooter: {
      float: 'right',
    },
    editCancel: {
      backgroundColor: '#8E9195',
      marginRight: '10px',

      color: '#fff',
      '&:hover': {
        backgroundColor: '#8E9195',
      },
    },
    editSave: {
      backgroundColor: '#006FB9',
      color: '#fff',
      width: '90px',
      '&:hover': {
        backgroundColor: '#006FB9',
      },
    },
    innerCell: {
      marginTop: '7px',
    },
  }),
);

export const CellsPerRow = 7;

export const SubGrid = props => {
  const colWidth = 100 / CellsPerRow;
  const { t } = useTranslation();
  const {
    row,
    isLastRow,
    centerHoverView,
    showEditControl = false,
    handleEditCancel,
    edit,
    didEdit,
    disableRowColors,
    restrictRolePermissions,
  } = props;
  const { classes } = useSubtableStyles({ width: `${colWidth}%`, disableRowColors: disableRowColors });

  const { detailRows } = row;

  return (
    <React.Fragment>
      {detailRows && detailRows.length > 0 && (
        <div className={`${classes.subTableContainer} ${isLastRow && classes.subTableContainerLastRow}`}>
          {detailRows.map((mainRow, mainRowIndex) => (
            <Box className={mainRowIndex !== detailRows.length - 1 ? classes.parentRow : ''}>
              {/* for avoiding toggling of space when success message is being displayed */}
              {mainRow.map((detailRow, rowIndex) => {
                const { columns, rows } = detailRow;
                return (
                  <>
                    <TableContainer className={rowIndex !== mainRow.length - 1 ? classes.midRow : classes.midRowLast}>
                      <Table className={`${classes.table}`}>
                        <TableHead className={classes.subTableHead}>
                          <TableRow>
                            <TableCell className={`${classes.subTableHeaderCell} ${classes.expandedTableCell}`} />
                            {columns.map((header: ICustomHeader, headerKey) => (
                              <TableCell key={headerKey} className={`${classes.subTableHeaderCell}`}>
                                {header.value}
                              </TableCell>
                            ))}
                            {/* add td to fill remaining space */}
                            {columns.length < CellsPerRow && (
                              <TableCell
                                className={`${classes.subTableHeaderCell}`}
                                style={{ width: `${colWidth * (CellsPerRow - columns.length)}%` }}
                              />
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((r, rIndex) => (
                            <TableRow className={classes.subtable}>
                              <TableCell className={`${classes.subTableCell} ${classes.expandedTableCell}`}></TableCell>
                              {columns.map((header, rowKey) => (
                                <TableCell key={rowKey} className={`${classes.subTableCell}`}>
                                  {header.icon ? (
                                    <img src={r[header.title]} alt={t('icon')}></img>
                                  ) : (
                                    <div
                                      className={
                                        header.control.type === gridControlType.dropDown ||
                                        header.control.type === gridControlType.date ||
                                        ((header.control.type === gridControlType.textfield ||
                                          header.control.type === gridControlType.textfieldnumber ||
                                          header.control.type === gridControlType.textarea) &&
                                          edit?.isEditing)
                                          ? ''
                                          : classes.innerCell
                                      }
                                    >
                                      <HoverView
                                        text={r[header.title]}
                                        header={header}
                                        data={r}
                                        filterOptionsOverride={r[`${header.title}FilterOptions`]}
                                        disableRowColors={disableRowColors}
                                        centerHoverView={centerHoverView}
                                        edit={{
                                          rowKey: edit.rowKey,
                                          key: header.title,
                                          isEditing: row?.isEditing,
                                          onValueChanged: edit.onValueChanged,
                                        }}
                                        restrictRolePermissions={restrictRolePermissions}
                                      />
                                    </div>
                                  )}
                                </TableCell>
                              ))}
                              {/* add td to fill remaining space */}
                              {columns.length < CellsPerRow && (
                                <TableCell
                                  className={`${classes.subTableCell}`}
                                  style={{ width: `${colWidth * (CellsPerRow - columns.length)}%` }}
                                />
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                );
              })}
              {props.children}
              {showEditControl && row?.isEditing ? (
                <TableFooter className={classes.editFooter}>
                  <div className={classes.editButton}>
                    <Button variant="contained" className={classes.editCancel} onClick={handleEditCancel}>
                      {`Cancel`}
                    </Button>
                    <Button disabled={!didEdit} variant="contained" type="submit" className={classes.editSave}>
                      {`Save`}
                    </Button>
                  </div>
                </TableFooter>
              ) : null}
            </Box>
          ))}
        </div>
      )}
      {row?.detailRows?.length === 0 && (
        <Grid container className={classes.footer}>
          <Grid item xs={12} className={classes.footerNoData}>
            {t('search.grid.notAvailable')}
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};
