import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { CustomTooltip } from '.';

const useStyles = makeStyles()({
  root: { display: 'flex', flexDirection: 'column' },
  pair: { display: 'flex', flexDirection: 'row', margin: '2px 0px' },
  value: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    color: '#555555',
    fontSize: 12,
    paddingLeft: 6,
  },
});

export const IterableKeyTooltip = props => {
  const { classes } = useStyles();
  return (
    <CustomTooltip
      value={props.value}
      tooltipContent={
        <div className={classes.root}>
          {props.attributes.map(item => (
            <div className={classes.pair}>
              <img src={item.key} alt="" />
              <div className={item.value ? classes.value : undefined}>{item.value}</div>
            </div>
          ))}
        </div>
      }
      contentStyle={{ fontWeight: 400, fontSize: '0.875rem', color: '#006FB9' }}
    />
  );
};
