import { gridStateActions, GridTag } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import { HomePage } from 'app/layout/pages/HomePage';
import { selectUser } from 'oidc/user.selectors';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import {
  recruiterPlacementActions,
  recruiterPlacementReducer,
  recruiterPlacementSliceKey,
} from './RecruiterPlacements.redux';
import { recruiterPlacementSaga } from './RecruiterPlacements.saga';
import { selectRecWorkdeskDataLoaded, selectWorkDeskGridData } from './RecruiterPlacements.selector';
import { getFilteredData } from '../MyPlacementsDesk/WorkdeskFilter/filterHelper';
import { defaultValues } from './WorkdeskFilter/filterUtils';
import { Pipeline } from './Pipeline';
import { WorkdeskChips } from './WorkdeskChips';
import { PlacementsFilter, AdvancedFilter, PlacementTiles } from './WorkdeskFilter';
import { WorkDeskGrid } from './WorkdeskGrid/WorkDeskGrid';
import { PageActions } from './PageActions';
import { revalidateChipSelection } from 'app/components/GlobalSearch/helper';
import { selectFilters, selectGridState } from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import { isRecruitment } from 'oidc/userRoles';
import { Concatenate } from 'utils/string/string';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { selectRecPlacementsGrid } from '@AMIEWEB/Common/WorkDesk/PipelineChoice/pipelineChoice.selectors';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import {
  candidateDetailReducer,
  candidateDetailSliceKey,
} from '../../../components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { candidateDetailsSaga } from '../../../components/Candidate/CandidateProfile/Profile/CandidateDetails.saga';
import { RootState } from 'types';
import {
  pipelineChoiceActions,
  pipelineChoiceKeys,
} from '@AMIEWEB/Common/WorkDesk/PipelineChoice/pipelineChoice.redux';
import { checkForPipelineEquality } from './utils';
import { useDecision } from '@optimizely/react-sdk';
import { ff_placement_ui_arbitration_agreement_grids } from 'app/constants/FeatureFlags/Global/keys';

interface IFilterValues {
  id: any;
  value: any;
  [key: string]: any;
}

interface DateRangeFilters {
  startDate: string;
  endDate: string;
  [key: string]: any;
}

interface IPipeline {
  category: number;
  subcategory: number | null;
  value?: any;
}
interface IFilters {
  placementRecruiter: Array<IFilterValues>;
  candidateName: Array<IFilterValues>;
  discipline: Array<IFilterValues>;
  specialty: Array<IFilterValues>;
  availabilityStartDateRange: DateRangeFilters;
  startDateRange: DateRangeFilters;
  endDateRange: DateRangeFilters;
  facilityName: Array<IFilterValues>;
  city: Array<IFilterValues>;
  state: Array<IFilterValues>;
  nonContactedDate: any;
  lastApplicationDateRange: DateRangeFilters;
  availableToStartDateRange: DateRangeFilters;
  callBackDateRange: any;
  activeCues: any;
  pipelineSelected: Array<IPipeline>;
}

export const MyPlacementsDesk = () => {
  const { t } = useTranslation();
  useInjectReducer({ key: recruiterPlacementSliceKey, reducer: recruiterPlacementReducer });
  useInjectSaga({ key: recruiterPlacementSliceKey, saga: recruiterPlacementSaga });
  useInjectReducer({ key: candidateDetailSliceKey, reducer: candidateDetailReducer });
  useInjectSaga({ key: candidateDetailSliceKey, saga: candidateDetailsSaga });
  const formMethods = useForm({ defaultValues, shouldUnregister: false });
  const {
    setValue,
    reset,
    handleSubmit,
    formState: { dirtyFields },
  } = formMethods;
  const dispatch = useDispatch();
  const { userInfo } = useSelector(selectUser);
  const flattenedData = useSelector(selectWorkDeskGridData);
  const { filterAttributes } = useSelector(selectGridState);
  const pipelineSelection = useSelector(selectRecPlacementsGrid);
  const appliedFilter = useSelector(selectFilters);
  const dataLoaded = useSelector(selectRecWorkdeskDataLoaded);
  const [arbitrationAgreementFlag] = useDecision(ff_placement_ui_arbitration_agreement_grids);
  const gridTag = 'recruitment-workdesk-filters';
  const gridPreferences = useSelector((state: RootState) => {
    return state.userPreferenceData.userGridPreferences.find(item => item.id === gridTag);
  });
  const pipelineChoices = {
    placements: t('home.recruiterWorkDesk.placements'),
    retention: t('home.recruiterWorkDesk.retention'),
    candidate: t('home.recruiterWorkDesk.candidates'),
  };

  const [pipeline, setPipeline] = useState<string>(pipelineChoices.placements);
  const [preferencesLoaded, setPreferencesLoaded] = React.useState<boolean>(false);
  const pipeLineChoice = React.useRef<any>([]);

  const saveFilterPreferences = (values: IFilters | any, pipeline: Array<IPipeline> | undefined) => {
    const filters = values || appliedFilter?.filters;
    const pipelineSelected = pipeline || pipeLineChoice.current;
    const preferenceData = {
      id: gridTag,
      value: {
        pipelineSelected: pipelineSelected,
        filtersApplied: filters,
      },
    };
    filters && dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
    dispatch(
      pipelineChoiceActions.setPipelineChoice({
        key: pipelineChoiceKeys.REC_WORK_DESK_GRID,
        selection: pipelineSelected,
      }),
    );
  };

  const savePipelineSelection = pipelineSelection => {
    pipeLineChoice.current = pipelineSelection;
    const pipelineChoice = gridPreferences?.value?.pipelineSelected;
    const isPipelineEqual = checkForPipelineEquality(pipelineSelection, pipelineChoice);
    const preferenceData = {
      id: gridTag,
      value: {
        ...gridPreferences?.value,
        pipelineSelected: pipelineSelection,
      },
    };
    !isPipelineEqual && dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
  };

  React.useEffect(() => {
    if (preferencesLoaded) {
      savePipelineSelection(pipelineSelection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipelineSelection]);

  const onApplyFilter = (values, newSelectedChipFilters?, pipelineChoice?) => {
    dispatch(globalActions.closeBanner());
    const selectedChipFilters =
      newSelectedChipFilters || revalidateChipSelection(values, filterAttributes?.selectedChipFilters || {});
    dispatch(gridStateActions.setFilterAttributes({ filterObject: Object.entries(values), selectedChipFilters }));
    dispatch(gridStateActions.setPageNumber(1));
    if (dirtyFields['placementRecruiter']) {
      const recruiterIds: number[] = values['placementRecruiter']?.map(data => data?.object.userId);
      dispatch(
        recruiterPlacementActions.getRecruiterPlacementAction({
          recruiterId: recruiterIds,
          filter: { values, setValue },
          arbitrationAgreement: arbitrationAgreementFlag.enabled
        }),
      );
      if (userInfo?.employeeId && recruiterIds?.includes(userInfo.employeeId)) {
        dispatch(recruiterPlacementActions.getRecStaticCountAction({ userId: userInfo.employeeId }));
      }
      reset({ ...defaultValues, placementRecruiter: values['placementRecruiter'] });
    } else if (values?.placementRecruiter?.length === 0) {
      dispatch(
        recruiterPlacementActions.getRecruiterPlacementAction({
          recruiterId: [],
          filter: { values, setValue },
          arbitrationAgreement: arbitrationAgreementFlag.enabled
        }),
      );
      reset({ ...defaultValues });
      dispatch(
        globalActions.setBanner({
          message: t('home.recruiterWorkDesk.noSelectionMessage'),
          severity: 'warning',
          justify: 'center',
        }),
      );
    } else {
      if (flattenedData.length > 0) {
        const filteredData = getFilteredData(values, flattenedData, selectedChipFilters);
        dispatch(recruiterPlacementActions.setFilteredData(filteredData));
      }
    }
    saveFilterPreferences(values, pipelineChoice);
  };

  const sidePanelItems = [
    {
      title: 'Quick View',
      component: <div style={{ minWidth: 236 }} />,
    },
    {
      title: 'Advanced Filters',
      component: <AdvancedFilter onApplyFilter={onApplyFilter} />,
    },
    { title: 'Tiles', component: <PlacementTiles onApplyFilter={onApplyFilter} /> },
  ];

  useEffect(() => {
    const appliedFilters = gridPreferences?.value?.filtersApplied;
    const pipelineSelected = gridPreferences?.value?.pipelineSelected;
    if ((appliedFilters || pipelineSelected?.length > 0) && dataLoaded) {
      if (!preferencesLoaded) {
        setPreferencesLoaded(true);
        isRecruitment(userInfo.roles || []) &&
          dispatch(recruiterPlacementActions.getFalseRecruitersAction({ userId: userInfo.employeeId }));
        if (appliedFilters) {
          dispatch(gridStateActions.setGrid(GridTag.RecPlacementDesk));
          Object.keys(appliedFilters)?.forEach(key => {
            setValue(key, appliedFilters[key], {
              shouldDirty: appliedFilters?.placementRecruiter?.length > 0 && key === 'placementRecruiter',
            });
          });
          onApplyFilter(appliedFilters, undefined, pipelineSelected);
          dispatch(gridStateActions.setFilterAttributes({ filterObject: Object.entries(appliedFilters) }));
        }
        if (pipelineSelected?.length > 0) {
          pipeLineChoice.current = pipelineSelected;
          dispatch(
            pipelineChoiceActions.setPipelineChoice({
              key: pipelineChoiceKeys.REC_WORK_DESK_GRID,
              selection: pipelineSelected,
            }),
          );
        }
      }
    } else if (userInfo && userInfo.employeeId && !dataLoaded) {
      dispatch(
        recruiterPlacementActions.getRecruiterPlacementAction({
          recruiterId: [userInfo.employeeId],
          arbitrationAgreement: arbitrationAgreementFlag.enabled
        }),
      );
      dispatch(recruiterPlacementActions.getRecStaticCountAction({ userId: userInfo.employeeId }));
      isRecruitment(userInfo.roles || []) &&
        dispatch(recruiterPlacementActions.getFalseRecruitersAction({ userId: userInfo.employeeId }));

      const recruiterName = Concatenate([userInfo.firstName || '', userInfo.lastName || ''], ' ');
      const placementRecruiter = [
        {
          id: userInfo.employeeId,
          name: recruiterName,
          value: userInfo.employeeId,
          object: {
            userId: userInfo.employeeId,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
          },
        },
      ];
      const filter = { ...defaultValues, placementRecruiter };
      dispatch(
        gridStateActions.setGridState({
          gridTag: GridTag.RecPlacementDesk,
          pageSize: 250,
          pageNumber: 1,
          previewItemId: -1,
          showPreviewDialog: false,
          sortedColumn: { column: 'statusAge', direction: 'desc' },
          filterAttributes: { filters: Object.entries(filter), selectedChipFilters: {}, dirtyFields },
        }),
      );
      reset(filter);
    }
    return () => {
      dispatch(globalActions.closeBanner());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridPreferences, preferencesLoaded, dataLoaded]);

  useEffect(() => {
    return () => {
      dispatch(gridStateActions.reset());
      dispatch(recruiterPlacementActions.reset());
      dispatch(globalActions.closeBanner());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(filter => onApplyFilter(filter))}>
        <HomePage
          title={`My Placements`}
          titleActions={
            <div hidden>
              <PageActions pipelineView={pipeline} setPipeline={setPipeline} pipelineChoices={pipelineChoices} />
            </div> // Hidden since not using this filter
          }
          stickIconArray={[3, 2, 1]}
          dataVisualization={<Pipeline pipeline={pipeline} pipelineChoices={pipelineChoices} />}
          filters={<PlacementsFilter onApplyFilter={onApplyFilter} />}
          table={<WorkDeskGrid />}
          chips={<WorkdeskChips onApplyFilter={onApplyFilter} />}
          sidePanelItems={sidePanelItems}
        />
      </form>
    </FormProvider>
  );
};
