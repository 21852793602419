import { CustomTooltip } from '@AMIEWEB/Common';
import { IconButton } from 'amn-ui-core';
import { TaskPriority } from 'app/models/Tasks/Tasks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutlinedFlagRoundedIcon from '@mui/icons-material/OutlinedFlagRounded';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';

export const TaskPriorityIcon = ({ taskPriorityId }: { taskPriorityId?: number }) => {
  const { t } = useTranslation();
  const [highPriorityMouseHover, setHighPriorityMouseHover] = useState(false);
  return (
    <CustomTooltip
      standardMargin
      arrow
      tooltipContent={
        taskPriorityId === TaskPriority.High
          ? t('notification.taskDetails.highPriority')
          : taskPriorityId === TaskPriority.Normal
          ? t('notification.taskDetails.normalPriority')
          : t('notification.taskDetails.lowPriority')
      }
    >
      <IconButton
        size="small"
        disableRipple
        onMouseEnter={() => {
          if (taskPriorityId === TaskPriority.High) setHighPriorityMouseHover(true);
        }}
        onMouseLeave={() => {
          if (taskPriorityId === TaskPriority.High) setHighPriorityMouseHover(false);
        }}
        sx={{
          color: theme1 =>
            taskPriorityId === TaskPriority.High
              ? theme1.palette.system.darkRed
              : taskPriorityId === TaskPriority.Normal
              ? theme1.palette.system.neutralGray
              : theme1.palette.system.main,
          '&:hover': {
            backgroundColor: theme1 =>
              taskPriorityId === TaskPriority.High
                ? theme1.palette.system.paleRed
                : taskPriorityId === TaskPriority.Normal
                ? theme1.palette.system.lightGrey2
                : theme1.palette.system.paleBlue,
          },
        }}
      >
        {taskPriorityId === TaskPriority.High && !highPriorityMouseHover ? (
          <FlagRoundedIcon />
        ) : (
          <OutlinedFlagRoundedIcon />
        )}
      </IconButton>
    </CustomTooltip>
  );
};
