import { NewOrder } from 'app/models/Orders/OrderDetails';
import categories from 'app/assets/jsons/OrderCreation/Category.json';
import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  FormControlLabel,
  Checkbox,
  Typography,
  IconButton,
  Grid,
  Chip,
  DatePicker,
  LocalizationProvider,
  InputAdornment,
  TextField,
} from 'amn-ui-core';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { SkillSetType } from 'app/models/Unit/CreateUnit';
import { ButtonGrid } from 'app/components/Order/OrderDesign';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { INonContractedBillRate } from 'app/models/Orders/CreateOrder';
import { useTranslation } from 'react-i18next';
import { selectNewUnit } from 'app/components/Order/Store/Order.selectors';
import { useSelector } from 'react-redux';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { NumberField } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/PreferencesTab/CustomComponents/Currency';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { selectCreateOrderMiscellaneousData } from 'store/redux-store/create-order-ocbr/selector';

interface ISelection {
  id: number;
  name: string;
  value: string;
}

interface Props {
  order: NewOrder;
  onOrderPropertyChange: (property: string, value: any) => void;
}

const useStyles = makeStyles()(theme => ({
  element: {
    width: '100%',
    height: '100%',
  },
  gridContainer: {
    width: '100%',
    margin: 2.5,
    border: '1px solid #ccc',
    borderRadius: 4,
    padding: 10,
  },
  gridItem: {
    padding: 0,
  },
  checkbox: {
    padding: 0,
    paddingRight: 5,
    paddingLeft: 10,
  },
  addButtonDisabled: {
    color: '#D6D7D9',
    background: 'none !important',
  },
  addButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
  displayFilled: {
    textAlign: 'center',
    padding: 0,
    background: '#f6f6f6',
  },
  displayEmpty: {
    textAlign: 'center',
    padding: 0,
    background: '#fff',
  },
  chipSpacing: {
    paddingRight: 5,
  },
  chipRoot: {
    marginBottom: 2,
  },
  root: {
    width: '100%',
    border: '1px solid #d2d2d1',
    overflow: 'hidden',
    fontSize: 12,
    borderRadius: 4,
    backgroundColor: '#fff',
    '&:hover': {
      borderColor: '#aaa',
      borderRadius: 4,
      backgroundColor: '#fff',
    },
    '&$focused': {
      borderColor: '#aaa',
      backgroundColor: '#fff',
    },
  },
  focused: {},
  labelRoot: {
    fontSize: 0,
    '&:focus': {
      color: '#000',
    },
  },
  error: {
    border: '1px solid #ff0000',
    overflow: 'hidden',
    fontSize: 12,
    borderRadius: 4,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
    },
  },
  datePicker: {
    marginLeft: 30,
  },
  inlineDatePicker: {
    '& .MuiFilledInput-root': {
      paddingRight: 0,
    },
    '& .MuiFilledInput-root button': {
      display: 'none',
    },
  },
}));

export const NonContractedBillRates = (props: Props) => {
  const { order, onOrderPropertyChange } = props;
  const categoryOptions = categories as ISelection[];
  const selectedNewUnit = useSelector(selectNewUnit);
  const [categorySelected, setCategorySelected] = useState<ISelection | null>();
  const [skillsetsSelected, setSkillsetsSelected] = useState<string[]>([]);
  const [startRate, setStartRate] = useState<string>('');
  const [endRate, setEndRate] = useState<string>('');
  const [dateCategoryPicked, setDateCategoryPicked] = useState<ISelection | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const { disciplineOpts} = useSelector(selectCreateOrderMiscellaneousData);
  const [billRatesSelected, setBillRatesSelected] = useState<INonContractedBillRate[]>([
    ...order.nonContractedBillRates,
  ]);
  const [dateOpen, setDateOpen] = useState(false);
  const { classes } = useStyles();
  const { t } = useTranslation();

  const getSkillsetOptions = (skillsets: SkillSetType[]) => {
    const options = skillsets?.map(item => {
      const disciplineItem = disciplineOpts.find(x => x.object.Description === item.discipline);
      return disciplineItem ? `${disciplineItem.object.Abbreviation}-${item.specialty}` : '';
    });
    return options;
  };

  const skillsSet = getSkillsetOptions(selectedNewUnit ? selectedNewUnit?.experience?.skillSets : order.skillsets);
  const skillsetOptions = order.skillsets ? ['All', ...(skillsSet ? skillsSet : [])] : [];

  const handleCategorySelect = event => {
    const selection = event.target.name;
    const item = categoryOptions.find(item => item.name === selection);
    if (item) {
      if (item.name === categorySelected?.name) {
        setCategorySelected(null);
        setDateCategoryPicked(null);
      } else if (item.name === 'startDateBy' || item.name === 'endDateBy') {
        setDateOpen(true);
        setDateCategoryPicked(item);
        if (selectedDate) {
          setCategorySelected({
            ...item,
            value: item?.value + ' ' + selectedDate.toLocaleDateString(),
          } as ISelection);
        } else {
          setCategorySelected(null);
        }
      } else {
        setDateCategoryPicked(null);
        setCategorySelected(item);
      }
    }
  };

  const handleDateChange = event => {
    if (event && event.toLocaleDateString() !== 'Invalid Date') {
      setSelectedDate(event);
      setCategorySelected({
        ...dateCategoryPicked,
        value: dateCategoryPicked?.value + ' ' + event.toLocaleDateString(),
      } as ISelection);
    } else {
      setSelectedDate(null);
    }
  };

  const handleSkillsetSelect = event => {
    const selection = event.target.name;
    if (selection === 'All') {
      skillsetsSelected.some(item => item === selection)
        ? setSkillsetsSelected([])
        : setSkillsetsSelected(skillsetOptions);
    } else {
      const isExisting = skillsetsSelected.some(item => item === selection);
      let selectionList = [...skillsetsSelected.filter(item => item !== 'All')];
      if (!isExisting) {
        const item = skillsetOptions.find(item => item === selection);
        if (item) {
          selectionList.push(item);
        }
      } else {
        selectionList = selectionList.filter(item => item !== selection);
      }
      setSkillsetsSelected(selectionList);
    }
  };

  const handleValueChange = event => {
    var reg = /^\d*(\.\d{1,2})?$/;
    const isValid = reg.test(event.target.value);
    if (isValid || event.target.value === '') {
      if (event.target.name === 'start') setStartRate(event.target.value);
      if (event.target.name === 'end') setEndRate(event.target.value);
    }
  };

  const addBillRate = () => {
    const newBillRate = {
      category: categorySelected?.value || '',
      skillsets: skillsetsSelected.filter(item => item !== 'All'),
      startRate: startRate,
      endRate: endRate,
    };
    setBillRatesSelected([...billRatesSelected, newBillRate]);
    setCategorySelected(null);
    setSkillsetsSelected([]);
    setStartRate('');
    setEndRate('');
    setDateCategoryPicked(null);
    setSelectedDate(null);
    onOrderPropertyChange('nonContractedBillRates', [...billRatesSelected, newBillRate]);
  };

  const handleDelete = (chip: number) => {
    const filteredBillRateData = billRatesSelected.filter((_, index) => index !== chip);
    setBillRatesSelected([...filteredBillRateData]);
    onOrderPropertyChange('nonContractedBillRates', [...filteredBillRateData]);
  };

  const toChipFormat = (billRateItem: INonContractedBillRate) => {
    let skillsets;
    const billRateSkillsets = billRateItem?.skillsets;
    if (billRateSkillsets) {
      skillsets = billRateSkillsets.map((item, index) => {
        if (item !== 'All') {
          if (index === billRateSkillsets.length - 1) {
            return item;
          } else {
            return `${item} /`;
          }
        }
      });
    }
    let firstHalf = '';
    if (billRateItem.category === '' && skillsets?.length > 0) {
      firstHalf = `${skillsets.join(' ')}`;
    } else if (skillsets?.length === 0 && billRateItem.category !== '') {
      firstHalf = `${billRateItem.category}`;
    } else if (billRateItem.category !== '' && skillsets.length > 0) {
      firstHalf = `${billRateItem.category}, ${skillsets.join(' ')}`;
    }

    let secondHalf = '';
    if (billRateItem.startRate !== '' && billRateItem.endRate === '') {
      secondHalf = `$ ${billRateItem.startRate}`;
    } else if (billRateItem.endRate !== '' && billRateItem.startRate === '') {
      secondHalf = `$ ${billRateItem.endRate}`;
    } else if (billRateItem.endRate !== '' && billRateItem.startRate !== '') {
      secondHalf = `$ ${billRateItem.startRate} - ${billRateItem.endRate}`;
    }

    if (
      (billRateItem.category !== '' || skillsets.length > 0) &&
      (billRateItem.startRate !== '' || billRateItem.endRate !== '')
    ) {
      return `${firstHalf} : ${secondHalf}`;
    } else {
      return `${firstHalf}${secondHalf}`;
    }
  };

  const isDisabled = () => {
    return !categorySelected && skillsetsSelected.length === 0 && startRate === '' && endRate === '';
  };

  return (
    <LayoutGrid>
      <LayoutGrid direction="row" spacing={2} className={classes.element}>
        <LayoutGridItem xs={4}>
          <LayoutGrid direction="column" spacing={0} className={classes.gridContainer} style={{ height: '100%' }}>
            <LayoutGridItem>
              <Typography variant="body2">{t('order.newOrder.selectCategory')}</Typography>
            </LayoutGridItem>
            {categoryOptions.map((item, i) => (
              <LayoutGridItem key={i}>
                <FormControlLabel
                  className={classes.gridItem}
                  control={
                    <Checkbox
                      id={'newOrderContractedBillRatesDateByCheck'}
                      className={classes.checkbox}
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      name={item.name}
                      icon={
                        item.name === 'startDateBy' || item.name === 'endDateBy' ? (
                          <CalendarTodayIcon />
                        ) : (
                          <CheckBoxOutlineBlankIcon />
                        )
                      }
                      checkedIcon={
                        item.name === 'startDateBy' || item.name === 'endDateBy' ? (
                          <CalendarTodayIcon />
                        ) : (
                          <CheckBoxIcon />
                        )
                      }
                      checked={
                        categorySelected ? item.name === categorySelected?.name : item.name === dateCategoryPicked?.name
                      }
                      onChange={handleCategorySelect}
                    />
                  }
                  label={item.value}
                />
                {item.name === dateCategoryPicked?.name && (
                  <div className={classes.datePicker}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        data-testId="test start date"
                        inputFormat="MM/dd/yyyy"
                        value={selectedDate}
                        open={dateOpen}
                        onOpen={() => setDateOpen(true)}
                        onClose={() => setDateOpen(false)}
                        onChange={handleDateChange}
                        className={`${classes.element} ${classes.inlineDatePicker}`}
                        renderInput={p => (
                          <TextField
                            {...p}
                            variant="outlined"
                            error={!selectedDate}
                            id="newOrderContractedBillRatesStartDatepicker"
                            aria-label="change date"
                            size="small"
                            placeholder={`MM/DD/YYYY`}
                            InputLabelProps={{
                              ...p.InputLabelProps,
                              shrink: false,
                              classes: { root: classes.labelRoot },
                            }}
                            InputProps={{
                              ...p.InputProps,
                              classes: { root: !selectedDate ? classes.error : classes.root },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                )}
              </LayoutGridItem>
            ))}
          </LayoutGrid>
        </LayoutGridItem>
        <LayoutGridItem xs={4}>
          <LayoutGrid direction="column" spacing={0} className={classes.gridContainer} style={{ height: '100%' }}>
            <LayoutGridItem>
              <Typography variant="body2">{t('order.newOrder.selectSkillset')}</Typography>
            </LayoutGridItem>
            {skillsetOptions.map((item, index) => (
              <LayoutGridItem key={index}>
                <FormControlLabel
                  className={classes.gridItem}
                  control={
                    <Checkbox
                      id={'newOrderContractedBillRatesselectSkillsetCheck'}
                      className={classes.checkbox}
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      name={item}
                      checked={skillsetsSelected.filter(x => x === item).length > 0}
                      onChange={handleSkillsetSelect}
                    />
                  }
                  label={item}
                />
              </LayoutGridItem>
            ))}
          </LayoutGrid>
        </LayoutGridItem>
        <LayoutGridItem xs={3}>
          <LayoutGrid direction="column" spacing={0} className={classes.gridContainer}>
            <LayoutGridItem>
              <Typography variant="body2">{t('order.newOrder.setRange')}</Typography>
            </LayoutGridItem>
            <LayoutGridItem>
              <LayoutGrid direction="row" alignItems="center" spacing={0}>
                <LayoutGridItem className={classes.gridItem}>
                  <Typography variant="body1">{`$`}</Typography>
                </LayoutGridItem>
                <LayoutGridItem className={classes.gridItem} style={{ width: 35 }} data-testid="test start rate">
                  <NumberField
                    id={'newOrderContractedBillRatessetRangeTxt'}
                    value={startRate}
                    type="number"
                    name="start"
                    autoComplete="off"
                    onChange={handleValueChange}
                    InputProps={{
                      classes: { root: startRate ? classes.displayEmpty : classes.displayFilled },
                      disableUnderline: true,
                      endAdornment: <InputAdornment style={{ display: 'none' }} position="end">{``}</InputAdornment>,
                    }}
                    inputProps={{
                      maxLength: 4,
                      min: 0,
                      'aria-label': 'test-textField',
                    }}
                  />
                </LayoutGridItem>
                <LayoutGridItem xs={1}>
                  <Typography variant="h6" style={{ textAlign: 'center' }}>{`-`}</Typography>
                </LayoutGridItem>
                <LayoutGridItem className={classes.gridItem}>
                  <Typography variant="body1" style={{ paddingLeft: 5 }}>{`$`}</Typography>
                </LayoutGridItem>
                <LayoutGridItem style={{ width: 35 }} data-testid="test end rate">
                  <NumberField
                    id={'newOrderContractedBillRatessetRangeEndTxt'}
                    value={endRate}
                    type="number"
                    name="end"
                    autoComplete="off"
                    onChange={handleValueChange}
                    InputProps={{
                      classes: { root: endRate ? classes.displayEmpty : classes.displayFilled },
                      disableUnderline: true,
                      inputProps: { maxLength: 4 },
                    }}
                    inputProps={{
                      min: 0,
                      'aria-label': 'test-textField',
                    }}
                  />
                </LayoutGridItem>
              </LayoutGrid>
            </LayoutGridItem>
          </LayoutGrid>
        </LayoutGridItem>
        <LayoutGridItem xs={1}>
          <ButtonGrid item>
            <IconButton
              id={'newOrderContractedBillItemAddBtn'}
              type="button"
              classes={{ root: classes.addButton, disabled: classes.addButtonDisabled }}
              color="secondary"
              disableTouchRipple
              disableFocusRipple
              disabled={isDisabled()}
              onClick={() => addBillRate()}
              size="medium"
            >
              <AddBoxIcon
                style={{
                  fontSize: 40,
                  fill: isDisabled() ? '#D6D7D9' : '#006FB9',
                }}
              />
            </IconButton>
          </ButtonGrid>
        </LayoutGridItem>
      </LayoutGrid>
      {billRatesSelected && (
        <Grid item id={'newOrderContractedBillAddedItemContainer'}>
          {billRatesSelected.map((chip, index) => (
            <span className={classes.chipSpacing} key={index}>
              <Chip
                classes={{ root: classes.chipRoot }}
                variant="outlined"
                key={index}
                label={<React.Fragment>{toChipFormat(chip)}</React.Fragment>}
                onDelete={() => handleDelete(index)}
              />
            </span>
          ))}
        </Grid>
      )}
    </LayoutGrid>
  );
};
