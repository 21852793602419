import React from 'react';
import { Route } from 'react-router-dom';
import { Header } from 'app/components/Header/Header';
import { SideNavStick } from 'app/components/GlobalNavigationMenu/SideNavStick';
import { Grid, Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { keyframes } from 'tss-react';
import { useInjectReducer } from 'redux-injectors';
import { gridStateSliceKey, gridStateReducer } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import { useSelector } from 'react-redux';
import { selectNavigationStick } from 'app/components/GlobalNavigationMenu/NavigationStick.selectors';
import { CustomGrid } from 'app/components/Common/Grid/Grid';
import { selectGlobalBanner } from 'app/ApplicationRoot/Global.selectors';
import { newPlacementReducer, newPlacementSliceKey } from 'store/redux-store/new-placement/slice';
import { useInjectSaga } from 'utils/redux-injectors';
import { newPlacementSaga } from 'store/redux-store/new-placement/saga';
import { facilitySliceKey, facilityReducer } from 'app/components/Facility/FacilityStore/Facility.redux';
import { facilitySaga } from 'app/components/Facility/FacilityStore/Facility.saga';
import { orderDataSliceKey, orderDataReducer } from 'app/components/Order/Store/Order.redux';
import { placementStatusReducer, placementStatusSliceKey } from 'store/redux-store/placement-status/slice';
import {
  candidateDetailReducer,
  candidateDetailSliceKey,
} from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { taskDetailsReducer, taskDetailsSliceKey } from 'app/components/Tasks/store/Tasks.redux';
import { taskDetailsSaga } from 'app/components/Tasks/store/Tasks.saga';
import { Banner } from 'app/components/Common/Banner/CustomBanner';
import { coverageSaga } from 'store/redux-store/user-coverage/saga';
import { coverageSliceKey, coverageReducer } from 'store/redux-store/user-coverage/slice';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: '#F5F5F5',
    overflow: 'hidden',
  },
  container: {
    minHeight: '100vh',
    flexWrap: 'nowrap',
  },
  layoutStatics: {
    position: 'fixed',
    zIndex: 1200,
  },
  sideNav: {
    zIndex: theme.zIndex.drawer + 2,
  },
  dashboard: {
    marginTop: '50px',
    width: '100%',
    height: '100%',
    // flexWrap: 'nowrap',
  },
  component: {
    width: 'inherit',
    maxHeight: '93vh',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  defaultNavigation: {
    paddingLeft: '0px',
  },
  navigation: {
    paddingLeft: '0px',
    animation: `${keyframes`
      100% {
          padding-left: ${OVERLAY_WIDTH}px
      }
      `} 150ms linear normal forwards`,
  },
  navigationCollapsed: {
    paddingLeft: `${OVERLAY_WIDTH}px`,
    animation: `${keyframes`
      100% {
          padding-left: 0px
      }
      `} 150ms linear normal forwards`,
  },
  banner: {
    margin: '18px 24px 0px 12px',
  },
}));

const STICK_WIDTH = 48;
const OVERLAY_WIDTH = 125;

export const appComponentAccessId = 'app-component-access-id';

export const EnableAppScroll = () => {
  const application = document.getElementById(appComponentAccessId);
  if (application) application.classList.remove('disable-scroll');
};

export const DisableAppScroll = () => {
  const application = document.getElementById(appComponentAccessId);
  if (application) application.classList.add('disable-scroll');
};

export const Layout = props => {
  const { classes, cx } = useStyles();
  useInjectReducer({ key: candidateDetailSliceKey, reducer: candidateDetailReducer });
  useInjectReducer({ key: gridStateSliceKey, reducer: gridStateReducer });
  useInjectReducer({ key: newPlacementSliceKey, reducer: newPlacementReducer });
  useInjectSaga({ key: newPlacementSliceKey, saga: newPlacementSaga });
  useInjectReducer({ key: facilitySliceKey, reducer: facilityReducer });
  useInjectSaga({ key: facilitySliceKey, saga: facilitySaga });
  useInjectReducer({ key: orderDataSliceKey, reducer: orderDataReducer });
  useInjectReducer({ key: placementStatusSliceKey, reducer: placementStatusReducer });
  useInjectSaga({ key: taskDetailsSliceKey, saga: taskDetailsSaga });
  useInjectReducer({ key: taskDetailsSliceKey, reducer: taskDetailsReducer });
  useInjectSaga({ key: coverageSliceKey, saga: coverageSaga });
  useInjectReducer({ key: coverageSliceKey, reducer: coverageReducer });

  const { navigationExpanded } = useSelector(selectNavigationStick);
  const globalBanner = useSelector(selectGlobalBanner);
  const { Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Grid container classes={{ root: classes.root, container: classes.container }} direction="row" spacing={0}>
          <Grid item className={cx(classes.layoutStatics, classes.sideNav)} style={{ width: STICK_WIDTH }}>
            <SideNavStick />
          </Grid>
          <Grid
            item
            container
            direction="column"
            classes={{ container: classes.container }}
            style={{
              paddingLeft: STICK_WIDTH,
            }}
          >
            <Grid item className={classes.layoutStatics} style={{ width: `calc(100% - ${STICK_WIDTH}px)` }}>
              <Header />
            </Grid>
            <Grid
              item
              container
              direction="row"
              classes={{ container: classes.dashboard }}
              className={
                navigationExpanded === undefined
                  ? classes.defaultNavigation
                  : navigationExpanded
                  ? classes.navigation
                  : classes.navigationCollapsed
              }
            >
              {globalBanner.open && (
                <Grid item xs={12} className={classes.banner}>
                  <Banner {...globalBanner} message={globalBanner.message ?? ''} />
                </Grid>
              )}
              <Grid xs={12} item id={appComponentAccessId} classes={{ root: classes.component }}>
                {/* HACK: Temporary hack for first render of grid component to prevent rerender
                Rendering a new grid for the first time within an already rendered page causes the entire page to rerender */}
                <div style={{ display: 'none' }}>
                  <CustomGrid columns={[]} rows={[]} />
                </div>
                <Component {...matchProps}></Component>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    />
  );
};
