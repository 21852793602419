/* eslint-disable @typescript-eslint/naming-convention */
import { createAction } from '@reduxjs/toolkit';
import { IContractDoc } from './types';

const onInitAction = createAction<{ placementId: number }>('ON_INIT_PLACEMENT_CANDIDATE_CONTRACT_EDITOR');
const business_OnContractDocClick = createAction<{ contract: IContractDoc }>(
  'BUSINESS_PLACEMENT_CANDIDATE_CONTRACT_ON_TEMPLATE_CLICK',
);
const business_DownloadSelectedContractDoc = createAction('BUSINESS_PLACEMENT_DOWNLOAD_SELECTED_CANDIDATE_CONTRACT');

export const businessActions = { onInitAction, business_OnContractDocClick, business_DownloadSelectedContractDoc };
