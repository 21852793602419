import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICandidateInterview } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/InterviewsTab/InterviewsTab';
import { initialInterviewsState } from './initialState';

const candidateInterviewSlice = createSlice({
  name: 'candidateInterviews',
  initialState: initialInterviewsState,
  reducers: {
    setCandidateInterviews(state, action: PayloadAction<ICandidateInterview[]>) {
      state.interviews = action.payload;
    },
    getCandidateInterviews() {},
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
  },
});

export const { actions: candidateInterviewActions, reducer: candidateInterviewReducers } = candidateInterviewSlice;
