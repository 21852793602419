/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, CircularProgress, Typography } from 'amn-ui-core';
import React, { useEffect, useMemo, useState } from 'react';
import { usePageVisitTimeTracker } from 'app-insights/appInsightsTracking';
import { InterviewCard } from './InterviewCard/InterviewCard';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import {
  candidateDetailsSelection,
  selectAuditData,
  selectSkillsChecklist,
} from '../../Profile/CandidateDetails.selector';
import { useDispatch, useSelector } from 'react-redux';
import { postInterviewInvite } from 'app/services/CandidateServices/CandidateServices';
import moment from 'moment';
import { CustomGrid } from 'app/components/Common/Grid/Grid';
import { groupBy } from '../../../../../helpers/arrayHelpers';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { ff_interviewViewHistory } from 'app/constants/FeatureFlags/Candidate/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { convertToPST } from 'app/helpers/dateHelper';
import MissingInterviews from 'app/assets/images/CandidateProfile/MissingInterviews.svg';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { FormInputDropdown } from '../PersonalInfoTab/Form/Controls/FormInputDropdown';
import { useReadOnly } from 'oidc/userRoles';
import { selectUser } from 'oidc/user.selectors';
import _ from 'lodash';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import Union from 'app/assets/images/CandidateProfile/Union.svg';
import { ff_ui_Candidate_Summary_send_virtual_interview_invite } from 'app/constants/FeatureFlags/Candidate/Keys';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { Row } from '../SummaryTab/Cards/WorkExperience/ExperienceSection/ExperienceSection.styles';
import { useInterviewTabStyles } from './InterviewTab.styles';
import {
  checkAccessPermission,
  checkAuditStatus,
  checkSkillsChecklistValidity,
  checkWorkExperienceValidity,
  getEducationValidity,
  getValidationReasons,
} from './rules';
import { getEmploymentHistoryHeaders } from './helpers';
import { ICandidateDetails } from 'app/models/Candidate/CandidateProfile';
import { interviewReasonsSelector, lookupSelector } from 'store/redux-store/lookup/lookup.selectors';
import { orderBy } from 'lodash';
import AuditModalDialog from 'app/components/Common/DomainModals/AuditModalDialog';
import { SkillSetWrapper } from '../../CandidateHeader/ManageSkillSet/SkillSetWrapper';
import { WorkExperienceGridLoadable } from '../SummaryTab/WorkExperience/WorkExperienceLoadable';
import { ILookupState } from 'store/redux-store/lookup/initialState';
import { interviewsSelector } from 'store/redux-store/candidate-interviews/interview.selector';
import { candidateInterviewActions } from 'store/redux-store/candidate-interviews/interviews.slice';
import { IInterviewState } from 'store/redux-store/candidate-interviews/initialState';
import { EducationWrapperLoadable } from '../ProfileTab/Education/EducationWrapperLoadable';
import { CandidateNavigation } from '../SummaryTab/CardNavigation';

export interface ICandidateInterview {
  changedDate: string | null;
  disciplineAbbr: string;
  disciplineId: number;
  interviewStatus: string | null;
  interviewDate: string | null;
  interviewRating: string;
  interviewReason: string | null;
  interviewRejected: boolean | null;
  invitationDate: string | null;
  inviteId: number;
  specialtyAbbr: string;
  specialtyId: number;
  statusDate: string | null;
  travelerInterviewRatingId: number;
}

export const InterviewsTab = props => {
  const defaultValues = {
    discipline: '',
    specialty: '',
    reason: '',
  };
  const { t } = useTranslation();
  const { navigate } = props;
  usePageVisitTimeTracker('Candidate Details:Interviews Tab');
  const candidateDetails = useSelector(candidateDetailsSelection) as ICandidateDetails;
  const interviewReasons = useSelector(interviewReasonsSelector);
  const candidateSkillsChecklist = useSelector(selectSkillsChecklist);
  const lookupData = useSelector(lookupSelector) as ILookupState;
  const interviewsState = useSelector(interviewsSelector) as IInterviewState;
  const auditData = useSelector(selectAuditData);
  const dispatch = useDispatch();
  const [interviewData, setInterviewData] = useState<any>(null);
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [inviteDialogOpen, setInviteDialogOpen] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<ICandidateInterview | null>();
  const [filteredSpecialty, setFilteredSpecialty] = React.useState<any[]>([]);
  const formMethods = useForm({ mode: 'all', defaultValues: defaultValues });
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [isInterviewValid, setIsInterviewValid] = useState<boolean>(false);
  const [interviewViewHistory] = useDecision(ff_interviewViewHistory);
  const [inviteInterviewFlag] = useDecision(ff_ui_Candidate_Summary_send_virtual_interview_invite);

  const user = useSelector(selectUser);
  const { readOnly } = useReadOnly();
  const { classes } = useInterviewTabStyles({ size: '' });
  const {
    handleSubmit,
    getValues,
    setValue,
    formState: { dirtyFields, errors, touched },
  } = formMethods;

  // navigation changes
  const [auditModalOpen, setAuditModalOpen] = useState<boolean>(false);
  const [openSkillSet, setOpenSkillSet] = useState<boolean>(false);
  const [showWorkExperience, setShowWorkExperience] = useState<boolean>(false);
  const [showEducationXGrid, setShowEducationXGrid] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  // DO NOT DELETE THIS VARIABLE
  let totalExperienceText = '';

  const constants = {
    rn: 'RN',
    active: 'Active',
    activeWithAudit: 'Active with Audit',
    ongoing: 'Ongoing',
  };

  useEffect(() => {
    dispatch(candidateInterviewActions.getCandidateInterviews());
  }, [candidateDetails?.travelerId]);

  const getRows = data => {
    // eslint-disable-next-line array-callback-return
    const interviewRows: any = [];
    if (dialogData) {
      const interviewsList = interviewData[data.travelerInterviewRatingId];
      let lastChangedItem = interviewsList[0];
      interviewsList.forEach(item => {
        if (item.disciplineAbbr !== lastChangedItem.disciplineAbbr) {
          const row = {
            fieldChanged: (
              <span>
                {`${item?.inviteId ? `${item.inviteId} - ` : ''}`}
                <span style={{ fontWeight: 'bold' }}>{`Skill Set`}</span>
              </span>
            ),
            oldValue: `${item.disciplineAbbr ? `${item.disciplineAbbr} - ${item.specialtyAbbr}` : '-'}`,
            newValue: `${
              lastChangedItem.disciplineAbbr
                ? `${lastChangedItem.disciplineAbbr} - ${lastChangedItem.specialtyAbbr}`
                : '-'
            }`,
            changedBy: `${item.updateByFirstName} ${item.updatedByLastName}`,
            date: `${convertToPST(item.changedDate)}`,
          };
          interviewRows.push(row);
        }
        if (item.interviewRating !== lastChangedItem.interviewRating) {
          const row = {
            fieldChanged: (
              <span>
                {`${item?.inviteId ? `${item.inviteId} - ` : ''}`}
                <span style={{ fontWeight: 'bold' }}>{`Rating`}</span>
              </span>
            ),
            oldValue: `${
              item.interviewRating !== '' ? item.interviewRating.substring(0, item.interviewRating.length - 1) : '-'
            }`,
            newValue: `${
              lastChangedItem.interviewRating !== ''
                ? lastChangedItem.interviewRating.substring(0, lastChangedItem.interviewRating.length - 1)
                : '-'
            }`,
            changedBy: `${item.updateByFirstName} ${item.updatedByLastName}`,
            date: `${convertToPST(item.changedDate)}`,
          };
          interviewRows.push(row);
        }
        if (item.interviewStatus !== lastChangedItem.interviewStatus) {
          const row = {
            fieldChanged: (
              <span>
                {`${item?.inviteId ? `${item.inviteId} - ` : ''}`}
                <span style={{ fontWeight: 'bold' }}>{`Status`}</span>
              </span>
            ),
            oldValue: `${item.interviewStatus ? item.interviewStatus : '-'}`,
            newValue: `${lastChangedItem.interviewStatus ? lastChangedItem.interviewStatus : '-'}`,
            changedBy: `${item.updateByFirstName} ${item.updatedByLastName}`,
            date: `${convertToPST(item.changedDate)}`,
          };
          interviewRows.push(row);
        }
        if (item.interviewReason !== lastChangedItem.interviewReason) {
          const row = {
            fieldChanged: (
              <span>
                {`${item?.inviteId ? `${item.inviteId} - ` : ''}`}
                <span style={{ fontWeight: 'bold' }}>{`Reason`}</span>
              </span>
            ),
            oldValue: `${item.interviewReason ? item.interviewReason : '-'}`,
            newValue: `${lastChangedItem.interviewReason ? lastChangedItem.interviewReason : '-'}`,
            changedBy: `${item.updateByFirstName} ${item.updatedByLastName}`,
            date: `${convertToPST(item.changedDate)}`,
          };
          interviewRows.push(row);
        }
        if (item.interviewRejected !== lastChangedItem.interviewRejected) {
          const row = {
            fieldChanged: (
              <span>
                {`${item?.inviteId ? `${item.inviteId} - ` : ''}`}
                <span style={{ fontWeight: 'bold' }}>{`Rejected`}</span>
              </span>
            ),
            oldValue: `${item.interviewReason ? 'Yes' : 'No'}`,
            newValue: `${lastChangedItem.interviewReason ? 'Yes' : 'No'}`,
            changedBy: `${item.updateByFirstName} ${item.updatedByLastName}`,
            date: `${convertToPST(item.changedDate)}`,
          };
          interviewRows.push(row);
        }
        lastChangedItem = item;
      });
    }
    return interviewRows;
  };

  const handleClick = (data: ICandidateInterview) => {
    setDialogData(data);
    setDialogOpen(true);
  };

  const getInterviewCards = () => {
    const cards: any = [];
    if (interviewData) {
      for (const k in interviewData) {
        cards.push(interviewData[k][0]);
      }
    }
    return cards;
  };

  useEffect(() => {
    const isAuditStatusValid = checkAuditStatus(auditData);
    const isSkillsChecklistValid = checkSkillsChecklistValidity(candidateSkillsChecklist);
    const { isValid: isWorkExperienceValid, totalExperienceText: experienceText } = checkWorkExperienceValidity(
      candidateDetails,
      lookupData,
    );
    const { educationValidationError } = getEducationValidity(candidateDetails?.experience.education, candidateDetails);

    totalExperienceText = experienceText;
    setIsInterviewValid(
      ((candidateDetails.candidateStatus === constants.active ||
        candidateDetails.candidateStatus === constants.activeWithAudit) &&
        isAuditStatusValid &&
        isSkillsChecklistValid &&
        isWorkExperienceValid &&
        !educationValidationError) ||
        false,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditData, candidateDetails]);

  useEffect(() => {
    const sortedData = orderBy(interviewsState.interviews, e => [e.interviewDate], 'desc');
    if (sortedData && sortedData.length > 0) {
      const groupedInterviews = groupBy(
        sortedData,
        ['travelerInterviewRatingId'],
        [
          { item: 'changedDate', type: 'shortDate' },
          { item: 'interviewDate', type: 'shortDate' },
          { item: 'statusDate', type: 'shortDate' },
          { item: 'invitationDate', type: 'shortDate' },
          { item: 'disciplineAbbr', type: 'any' },
          { item: 'interviewRating', type: 'any' },
          { item: 'interviewReason', type: 'any' },
        ],
      );
      if (interviewViewHistory.enabled) {
        setInterviewData(groupedInterviews);
      } else {
        setInterviewData(sortedData);
      }
    } else {
      setInterviewData(null);
    }
  }, [interviewViewHistory.enabled, interviewsState.interviews]);

  const onSubmit = async data => {
    setIsSubmitting(true);
    const discipline = lookupData.disciplines.find(item => item.object.Description === data.discipline);
    const specialty = lookupData.specialties.find(item => item.object.Description === data.specialty);
    const reason = interviewReasons.find(item => item.name === data.reason);
    const payload = {
      candidateId: candidateDetails.travelerId,
      disciplineId: discipline?.object.ID,
      specialtyId: specialty?.object.SpecialtyID,
      reasonId: reason?.value,
      reason: reason?.name,
      candidateName: {
        prefix: candidateDetails.name.prefix,
        first: candidateDetails.name.first,
        middle: candidateDetails.name.middle,
        last: candidateDetails.name.last,
        goesBy: candidateDetails.goesBy,
        other: candidateDetails.other,
      },
      primaryPhoneNumber: candidateDetails.primaryPhoneNumber,
      mobilePhoneNumber: candidateDetails.mobilePhoneNumber,
      primaryEmail: candidateDetails.primaryEmail,
      secondaryEmail: candidateDetails.secondaryEmail,
      recruiter: {
        recruiterId: candidateDetails.recruiter.recruiterId,
        first: candidateDetails.recruiter.first,
        last: candidateDetails.recruiter.last,
        email: candidateDetails.recruiter.email,
      },
      currentEmployeeId: user.userInfo?.employeeId,
      currentEmployeeName: `${user.userInfo?.firstName} ${user.userInfo?.lastName}`,
    };
    const response = await postInterviewInvite(candidateDetails.travelerId, payload);
    if (response?.status === 200) {
      dispatch(
        globalActions.setSnackBar({
          message: t('candidate.virtualInterviewTabLabels.inviteSentSuccess'),
          severity: 'success',
        }),
      );
      onClose();
    } else {
      dispatch(
        globalActions.setSnackBar({
          message: t('common.failedLoad'),
          severity: 'error',
        }),
      );
      onClose();
    }
    setIsSubmitting(false);
  };

  const onError = () => {};

  const onClose = () => {
    setInviteDialogOpen(false);
    setFilteredSpecialty([]);
    setIsSubmitDisabled(true);
  };

  const handleCancel = () => {
    if (!_.isEmpty(errors) || !_.isEmpty(dirtyFields) || !_.isEmpty(touched)) {
      dispatch(
        globalActions.setSnackBar({
          message: t('candidate.virtualInterviewTabLabels.cancelMessage'),
          severity: 'success',
          actions: [
            {
              action: () => onClose(),
              closeSnackbar: true,
              text: t('Yes'),
            },
            {
              closeSnackbar: true,
              text: t('No'),
            },
          ],
        }),
      );
    } else {
      onClose();
    }
  };

  const formatTypeAhead = (options: any[]) => {
    return options.map(item => ({
      ...item,
      ID: item.object.SpecialtyID,
      Abbreviation: item.label,
      Description: item.object.Description,
    }));
  };

  const formatTypeAheadDiscipline = (options: any[]) => {
    return options.map(item => ({
      ...item,
      ID: item.object.id,
      Abbreviation: item.object.Abbreviation,
      Description: item.object.Description,
    }));
  };

  const formatTypeAheadReasons = (options: { value: any; name: string }[]) =>
    options.map(item => ({
      ID: item.value,
      Description: item.name,
    }));

  const onSelectionChange = choice => {
    const specialtyData = formatTypeAhead(lookupData.specialties);
    const discipline = lookupData.disciplines.find(item => item.object.Description === choice);
    const filteredData = specialtyData.filter(data => {
      return data.object.DisciplineID === discipline?.object.ID;
    });
    setFilteredSpecialty(filteredData);
    setValue('discipline', choice, { shouldDirty: true });
    filteredData.length && setValue('specialty', filteredData[0].Description, { shouldDirty: true });
  };

  const onStatusClick = () => {
    const showAuditPopup = auditData.items.some(e => e.status === constants.ongoing && e.holdFileOut);
    if (showAuditPopup) {
      setAuditModalOpen(true);
    } else {
      window.history.pushState(null, '', `${window.location.pathname}?tab=${CandidateNavigation.Summary}`);
      navigate(CandidateNavigation.Summary);
    }
  };

  const getTooltipContent = () => {
    const { educationValidationError } = getEducationValidity(
      candidateDetails?.experience?.education,
      candidateDetails,
    );
    const educationMessage = t(educationValidationError);

    const isAuditStatusValid = checkAuditStatus(auditData);
    const { isValid: isWorkExperienceValid, totalExperienceText: experienceText } = checkWorkExperienceValidity(
      candidateDetails,
      lookupData,
    );

    const auditStatus =
      (isAuditStatusValid && candidateDetails.candidateStatus === constants.active) ||
      candidateDetails.candidateStatus === constants.activeWithAudit
        ? null
        : 'Active';
    const skillsCheckList =
      candidateDetails.verifiedSkills?.length > 0 ? null : t('candidate.virtualInterviewCard.noVerifiedSkillset');
    const workExperienceValid = isWorkExperienceValid
      ? null
      : candidateDetails.experience.employment.length > 0
      ? experienceText
      : 'Missing experience';

    const disabledReason = getValidationReasons(
      !!workExperienceValid && !isWorkExperienceValid,
      !!skillsCheckList,
      !!auditStatus,
      !!educationValidationError,
    );
    return (
      <div>
        <Typography variant="caption">{disabledReason}</Typography>
        {auditStatus && (
          <Row>
            <Typography variant="caption">
              <b>{t('candidate.virtualInterviewCard.status')}</b>
              <span className={classes.linkButton} onClick={e => onStatusClick()}>
                {candidateDetails.candidateStatus}
              </span>
            </Typography>
          </Row>
        )}
        {workExperienceValid && (
          <Row>
            <Typography variant="caption">
              <b>{t('candidate.virtualInterviewCard.workExperience')}</b>
              <span className={classes.linkButton} onClick={e => setShowWorkExperience(true)}>
                {workExperienceValid}
              </span>
            </Typography>
          </Row>
        )}
        {educationMessage && (
          <Row>
            <Typography variant="caption">
              <b>{t('candidate.virtualInterviewCard.education')}</b>
              <span className={classes.linkButton} onClick={e => setShowEducationXGrid(true)}>
                {educationMessage}
              </span>
            </Typography>
          </Row>
        )}
        {skillsCheckList && (
          <Row>
            <Typography variant="caption">
              <b>{t('candidate.virtualInterviewCard.verifiedSkillset')}</b>
              <span className={classes.linkButton} onClick={e => setOpenSkillSet(true)}>
                {skillsCheckList}
              </span>
            </Typography>
          </Row>
        )}
      </div>
    );
  };

  const WorkExperienceXGrid = useMemo(() => {
    return (
      <>
        <WorkExperienceGridLoadable rows={[]} handleBack={e => setShowWorkExperience(false)} />
      </>
    );
  }, [setShowWorkExperience]);

  const EduXGrid = useMemo(() => {
    return (
      <>
        <LayoutGrid container direction="column" spacing={2}>
          <LayoutGridItem item xs={12}>
            <EducationWrapperLoadable handleToggle={() => setShowEducationXGrid(!showEducationXGrid)} loading={false} />
          </LayoutGridItem>
        </LayoutGrid>
      </>
    );
  }, [setShowEducationXGrid, showEducationXGrid]);

  return (
    <>
      {interviewsState.isLoading ? (
        <CircularProgress className={classes.MuiCircularProgressRoot} />
      ) : (
        <>
          {showWorkExperience ? (
            <LayoutGrid container direction="column" spacing={2}>
              <LayoutGridItem item xs={12}>
                {WorkExperienceXGrid}
              </LayoutGridItem>
            </LayoutGrid>
          ) : showEducationXGrid ? (
            EduXGrid
          ) : (
            <>
              <LayoutGrid direction="column">
                <LayoutGridItem style={{ width: '100%' }}>
                  <LayoutGrid direction="row" justifyContent="space-between" style={{ width: '100%' }}>
                    <LayoutGridItem>
                      <Typography className={classes.fontTitle}>{`All Interviews`}</Typography>
                    </LayoutGridItem>
                    {inviteInterviewFlag.enabled && (
                      <LayoutGridItem>
                        <CustomTooltip interactive tooltipContent={getTooltipContent()} disabled={isInterviewValid}>
                          <span style={{ cursor: !isInterviewValid ? 'not-allowed' : 'default' }}>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={!checkAccessPermission(user) || !isInterviewValid}
                              className={classes.button}
                              onClick={() => setInviteDialogOpen(true)}
                            >
                              {t('candidate.virtualInterviewTabLabels.inviteInterview')}
                              <div className={classes.unionIcon}>
                                <img src={Union} alt="Union" />
                              </div>
                            </Button>
                          </span>
                        </CustomTooltip>
                      </LayoutGridItem>
                    )}
                  </LayoutGrid>
                </LayoutGridItem>
                <div className={`${interviewData && Object.keys(interviewData).length > 6 ? classes.bodyScroll : ''}`}>
                  {interviewData ? (
                    <>
                      <LayoutGridItem className={classes.container}>
                        <LayoutGrid container spacing={2} justifyContent="flex-start" direction="row">
                          {interviewViewHistory.enabled
                            ? getInterviewCards().map((item, index) => {
                                return (
                                  <LayoutGridItem xs={4} sm={4} md={4} lg={4} xl={4} key={index}>
                                    <InterviewCard data={item} handleClick={handleClick} />
                                  </LayoutGridItem>
                                );
                              })
                            : interviewData?.map((item, index) => {
                                return (
                                  <LayoutGridItem xs={4} sm={4} md={4} lg={4} xl={4} key={index}>
                                    <InterviewCard data={item} handleClick={handleClick} />
                                  </LayoutGridItem>
                                );
                              })}
                        </LayoutGrid>
                      </LayoutGridItem>
                    </>
                  ) : (
                    <LayoutGridItem className={classes.container}>
                      <LayoutGrid
                        container
                        direction="column"
                        spacing={4}
                        className={classes.missingInterviewsContainer}
                      >
                        <LayoutGridItem>
                          <img src={MissingInterviews} alt="Missing interviews" />
                        </LayoutGridItem>
                        <LayoutGridItem>
                          <span className={classes.missingInterviewsText}>
                            {t('candidate.virtualInterviewTabLabels.missingInterview')}
                          </span>
                        </LayoutGridItem>
                      </LayoutGrid>
                    </LayoutGridItem>
                  )}
                </div>
              </LayoutGrid>
              <GenericDialog
                variant={'blue'}
                fullWidth
                classes={{
                  paper: classes.dialogContainer,
                }}
                open={isDialogOpen}
                disablePortal
                disableEscapeKeyDown={false}
                dialogTitleProps={{
                  text: `Virtual Interview - View History`,
                  closeButton: true,
                }}
                onClose={() => setDialogOpen(false)}
                dialogContentProps={{
                  classes: { root: classes.dialogContentRoot },
                }}
              >
                <>
                  <Box>
                    {`Invite: ${dialogData?.inviteId ? dialogData.inviteId : '-'}`}
                    {' ,  '}
                    {`Date: ${
                      dialogData?.interviewDate ? moment(new Date(dialogData.interviewDate)).format('MM/DD/YYYY') : '--'
                    }`}
                  </Box>
                  <CustomGrid
                    columns={getEmploymentHistoryHeaders()}
                    rows={getRows(dialogData)}
                    gridName={'InterviewHistoryGrid'}
                    defaultPageView={'25'}
                    fixedItemsPerPage={25}
                    pageOptions={[25, 50, 100, 200]}
                    disableGutters
                    noPadding
                    centerHoverView={false}
                    showCount={false}
                    minCellWidth={'100px'}
                  />
                </>
              </GenericDialog>
              <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                  <GenericDialog
                    variant={'blue'}
                    maxWidth="md"
                    open={inviteDialogOpen}
                    disablePortal
                    disableEscapeKeyDown={false}
                    dialogTitleProps={{
                      text: t('candidate.virtualInterviewTabLabels.inviteInterview'),
                      closeButton: true,
                    }}
                    dialogActions={[
                      {
                        onClick: handleCancel,
                        text: 'CANCEL',
                        variant: 'text',
                        classes: { root: classes.actionNoBtn },
                      },
                      {
                        text: 'SEND',
                        variant: 'contained',
                        type: 'submit',
                        disabled: isSubmitDisabled || readOnly,
                        tooltipProps: {
                          tooltipContent: t('global.readOnlyTooltip'),
                          disabled: !readOnly,
                        },
                      },
                    ]}
                    onClose={handleCancel}
                    dialogContentProps={{
                      classes: { root: classes.dialogContentRoot },
                    }}
                  >
                    <>
                      <LayoutGrid direction="column" spacing={4} style={{ width: '500px' }}>
                        <LayoutGridItem className={classes.fieldsMarkedLabel}>
                          {t('candidate.virtualInterviewTabLabels.fieldsMarked')}
                        </LayoutGridItem>
                        <LayoutGridItem>
                          <FormInputDropdown
                            search
                            name={'discipline'}
                            label={`Discipline*`}
                            require={true}
                            options={
                              lookupData.disciplines
                                ? getValues('discipline')
                                  ? formatTypeAheadDiscipline(lookupData.disciplines)
                                  : [{ ID: 0, Description: '' }, ...formatTypeAheadDiscipline(lookupData.disciplines)]
                                : []
                            }
                            handleChange={e => onSelectionChange(e.target.value)}
                            inputValue={getValues('discipline') ?? ''}
                          ></FormInputDropdown>
                        </LayoutGridItem>
                        <LayoutGridItem>
                          <FormInputDropdown
                            search
                            name={'specialty'}
                            label={`Specialty*`}
                            require={true}
                            readOnly={filteredSpecialty.length < 1}
                            options={filteredSpecialty.length ? filteredSpecialty : [{ ID: 0, Description: '' }]}
                            handleChange={e => setValue('specialty', e.target.value)}
                            inputValue={
                              getValues('specialty') || (filteredSpecialty.length > 0 && filteredSpecialty[0]) || ''
                            }
                          ></FormInputDropdown>
                        </LayoutGridItem>
                        <LayoutGridItem>
                          <FormInputDropdown
                            standard
                            name={'reason'}
                            label={`Reason*`}
                            require={true}
                            options={
                              interviewReasons
                                ? getValues('reason')
                                  ? formatTypeAheadReasons(interviewReasons)
                                  : [{ ID: 0, Description: '' }, ...formatTypeAheadReasons(interviewReasons)]
                                : []
                            }
                            handleChange={e => {
                              setValue('reason', e.target.value, { shouldDirty: true });
                              setIsSubmitDisabled(false);
                            }}
                            inputValue={getValues('reason')}
                          ></FormInputDropdown>
                        </LayoutGridItem>
                      </LayoutGrid>
                    </>
                  </GenericDialog>
                </form>
              </FormProvider>
              <AuditModalDialog
                openAuditModal={auditModalOpen}
                setOpenAuditModal={setAuditModalOpen}
                auditData={auditData}
              />
              {openSkillSet && (
                <SkillSetWrapper
                  handleClose={e => setOpenSkillSet(false)}
                  open={openSkillSet}
                  candidateId={candidateDetails.travelerId}
                />
              )}
            </>
          )}
        </>
      )}
      {isSubmitting && <CircularProgress className={classes.MuiCircularProgressRoot} />}
    </>
  );
};
