import React, { ReactNode, useEffect, useState } from 'react';
import { Box, IconButton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { CustomTooltip } from '@AMIEWEB/Common';

const useStyles = makeStyles()(theme => ({
  titleBar: {
    display: 'flex',
    justifyContent: 'space-between',
    background: theme.palette.framework.system.white,
    color: theme.palette.framework.system.charcoal,
    fontSize: 16,
    padding: '0px 12px 12px 0px',
    fontWeight: 500,
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  clickable: {
    cursor: 'pointer !important',
    '&:hover': {
      borderRadius: '50%',
    },
  },
  children: {
    padding: '0px 12px 12px 0px',
  },
  icon: {
    position: 'relative',
    display: 'inline-block',
    '& img': {
      transition: 'filter 0.3s ease',
      filter: 'grayscale(100%)',
    },
    '&$iconHovered img': {
      filter: 'none',
    },
  },
  onHover: {
    '&:hover': {
      color: theme.palette.system.navyBlue,
    },
  },
  onHoverUnderline: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.system.navyBlue,
      textDecoration: 'underline',
    },
  },
  titleHovered: {
    backgroundColor: theme.palette.system.dimFadedBlack,
  },
}));

export const CandidateSubHeader = ({
  title,
  rightIcon,
  iconToolTip,
  onClick,
  onClickAll,
  children,
  totalCount,
  showCount,
}: {
  title: string;
  rightIcon?: ReactNode;
  iconToolTip?: string;
  onClick?: any;
  onClickAll?: any;
  children;
  totalCount?: number;
  showCount?: boolean;
}) => {
  const { classes, cx } = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [isHovered, setIsHovered] = React.useState(false);
  let showTimeout;
  let hideTimeout;

  const handleRightIconClick = () => {
    if (onClick) {
      onClick();
      setOpen(false);
    }
  };

  const handleAllClick = () => {
    if (onClickAll) {
      onClickAll();
      setOpen(false);
    }
  };

  const handleMouseEnter = () => {
    showTimeout = setTimeout(() => {
      setOpen(true);
    }, 500);
  };

  const handleMouseLeave = () => {
    clearTimeout(showTimeout);
    hideTimeout = setTimeout(() => {
      setOpen(false);
    }, 200);
  };

  useEffect(() => {
    return () => {
      clearTimeout(showTimeout);
      clearTimeout(hideTimeout);
    };
  }, [showTimeout, hideTimeout]);

  return (
    <Box>
      <Box
        className={`${classes.titleBar} ${isHovered && classes.titleHovered && showCount && classes.onHover}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Box className={`${classes.titleContainer} ${showCount && classes.onHover}`}>
          <Box className={showCount && classes.onHoverUnderline} onClick={handleAllClick}>
            {title}
            {/* Will implement this in upcoming sprint {showCount && <span className={classes.digit}>{` ${totalCount}`}</span>} */}
          </Box>
        </Box>

        {rightIcon && (
          <CustomTooltip open={open} placement="bottom" arrow={true} tooltipContent={iconToolTip}>
            <Box
              onClick={event => (rightIcon ? handleRightIconClick() : event.stopPropagation())}
              className={cx(classes.clickable, classes.icon)}
              sx={{ visibility: 'visible' }}
              onMouseOver={() => handleMouseEnter()}
              onMouseOut={() => handleMouseLeave()}
            >
              <IconButton
                color="secondary"
                size="medium"
                sx={theme => ({
                  padding: 0,
                })}
              >
                {rightIcon}
              </IconButton>
            </Box>
          </CustomTooltip>
        )}
      </Box>
      <Box className={classes.children}>{children}</Box>
    </Box>
  );
};
