import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { DistributionContact, EAutomationKey, IOrderAutomationPlacement } from 'app/models/Orders/OrderPreference';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { orderPreferenceAlert } from '../OrderPreferenceAlerts';
import { initialState } from './utils';

export enum placementStage {
  submission = 'submission',
  interview = 'interview',
  offer = 'offer',
  booking = 'booking',
  assignment = 'assignment',
}

export interface IOrderPreferenceRequest {
  orderId: number;
}

export interface IOrderAutomationRequest {
  orderId: number;
  placementStage?: placementStage;
}

export interface IAutomationStatusUpdateRequest {
  orderId: number;
  rfoToSent: boolean;
}

export interface IUpdateSubmissionPreferenceRequest {
  orderId: number;
  candidateTasks: boolean;
  sendPacket: boolean;
  savePacket?: boolean;
  submissionEmailSetup?: string;
  submissionPacketSetup?: string;
}

const getActivePlacementCounts = createAction<IOrderPreferenceRequest>('GET_ACTIVE_PLACEMENT_COUNT_ORDER');
const getAutomationStatusByStage = createAction<IOrderAutomationRequest>('GET_AUTOMATION_STATUS_BY_STAGE');
const updateAutomationStatusByStage = createAction<IAutomationStatusUpdateRequest>('UPDATE_AUTOMATION_STATUS_BY_STAGE');
const checkAutomationEditStatusByStage = createAction<IOrderAutomationRequest>('CHECK_AUTOMATION_EDIT_STATUS_BY_STAGE');
const getExistingRFOs = createAction<IOrderPreferenceRequest>('GET_EXISTING_RFOS');
const getReadyToSubmitRfoPlacementIds = createAction<IOrderPreferenceRequest>('GET_READY_TO_SUBMIT_RFO_PLACEMENTIDS');
const saveDistributionList = createAction<{
  orderId: number;
  contacts: DistributionContact[];
}>('SAVE_DISTRIBUTION_LIST');
const updateOrderPreferences = createAction<{
  data: IUpdateSubmissionPreferenceRequest;
}>('UPDATE_ORDER_SUBMISSION_PREFERENCES');
const getClientContacts = createAction<{
  facilityId: number;
  unitId: number;
}>('GET_CLIENT_CONTACTS');

const orderPreferenceSlice = createSlice({
  name: 'orderPreferences',
  initialState,
  reducers: {
    setUpdateAutomationApiProgressStatus(state, action: PayloadAction<{ status: boolean | false }>) {
      return {
        ...state,
        updateInprogress: action.payload.status,
      };
    },
    /** Function - Extract data since get request has pref and automation fields combined */
    setAutomationPreferenceData(
      state,
      action: PayloadAction<{
        submissionPreferences?;
      }>,
    ) {
      const { submissionPreferences = {} } = action.payload;

      return {
        ...state,
        submissionPreferences: {
          ...state.submissionPreferences,
          ...submissionPreferences,
        },
      };
    },
    setActivePlacementCounts(state, action: PayloadAction<{ rfo: number; sent: number; others: number }>) {
      return {
        ...state,
        submissionPreferences: {
          ...state.submissionPreferences,
          submissionLimits: { activePlacementCounts: { ...action.payload } },
        },
      };
    },
    setDistributionList(state, action: PayloadAction<DistributionContact[]>) {
      return {
        ...state,
        submissionPreferences: {
          ...state.submissionPreferences,
          distributionList: action.payload,
        },
      };
    },
    setExistingRFOs(state, action: PayloadAction<IOrderAutomationPlacement[]>) {
      return { ...state, submissionPreferences: { ...state.submissionPreferences, existingRFOs: [...action.payload] } };
    },
    setReadyToSubmitRfoPlacementIds(state, action: PayloadAction<number[]>) {
      return {
        ...state,
        submissionPreferences: { ...state.submissionPreferences, readyToSubmitPlacementIds: [...action.payload] },
      };
    },
    setAlert(state, action: PayloadAction<orderPreferenceAlert | null>) {
      return { ...state, alert: action.payload };
    },
    setAutomationStatusInProgress(state, action: PayloadAction<EAutomationKey | null>) {
      return { ...state, automationStatusInProgress: action.payload };
    },
    setClientContacts(state, action: PayloadAction<any[]>) {
      return { ...state, clientContacts: action.payload };
    },
    setMiscellaneousData(
      state,
      action: PayloadAction<{ key: 'disableEmailContainer' | 'disablePacketContainer'; value: boolean }>,
    ) {
      return { ...state, miscellaneous: { ...state.miscellaneous, [action.payload.key]: action.payload.value } };
    },
  },
});

export const orderPreferenceActions = {
  ...orderPreferenceSlice.actions,
  getActivePlacementCounts,
  getAutomationStatusByStage,
  updateAutomationStatusByStage,
  checkAutomationEditStatusByStage,
  saveDistributionList,
  getExistingRFOs,
  getReadyToSubmitRfoPlacementIds,
  updateOrderPreferences,
  getClientContacts,
};

export const { name: orderPreferenceSliceKey, reducer: orderPreferenceReducer } = orderPreferenceSlice;
