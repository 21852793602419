import React from 'react';
import { Box } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import RemoveButton from 'app/assets/images/IconImages/RemoveButton.svg';
import RemoveButtonFilled from 'app/assets/images/IconImages/RemoveButtonFilled.svg';

const useStyles = makeStyles()(theme => ({
  removeIcon: {
    height: 50,
    width: 50,
    paddingRight: 12,
    marginLeft: -4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
interface PropType {
  rootStyle?: any;
  iconStyle?: any;
  toggle?: boolean;
}
export const RemoveIcon = ({ rootStyle, iconStyle, toggle }: PropType) => {
  const { classes } = useStyles();
  return (
    <Box className={rootStyle ? rootStyle : classes.removeIcon}>
      <img
        style={iconStyle ? iconStyle : { height: 28.5, width: 28.5 }}
        alt="remove-icon"
        src={toggle ? RemoveButtonFilled : RemoveButton}
      />
    </Box>
  );
};
