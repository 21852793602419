import { Paper, TextField, Grid } from 'amn-ui-core';
import styledComponent from 'styled-components';

export const PaperCard = styledComponent(Paper)`
  width: 100%;
  border: 1px solid #ececec;
  border-radius: 4px;
  padding: 10px 25px 25px 22px;
  margin-bottom: 2%;
`;

export const Title = styledComponent.div`
  color: #52429a;
  font-size: 16px !important;
  text-align: start;
  padding-bottom: 1%;
`;

export const CurrencyField = styledComponent(TextField)`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &$cssFocused $notchedOutline: {
    border-color: #009ad9 !important;
    color: #009ad9 !important;
  }
`;

export const ChipsGrid = styledComponent(Grid)`
  margin-bottom: 1% !important;
`;
