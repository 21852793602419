import React, { useEffect } from 'react';
import { Grid, Skeleton } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';
import { UnitDetailsBody } from './UnitDetailsBody';
import { UnitPreferencesWrapper } from './UnitTabs/Preferences/UnitPreferencesWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { unitPreferenceActions } from 'store/redux-store/unit-preferences/slice';
import { selectPreferenceChanged } from 'store/redux-store/unit-preferences/selectors';
import { GenericTabs } from '@AMIEWEB/Common/Tabs/GenericTabs';
import UnitOrderWrapper from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/OrdersTab/UnitOrderWrapper';
import { usePromiseTracker } from 'react-promise-tracker';
import { useStyles } from './UnitDetailsBodyWrapper.styles';


export const UnitDetailsBodyWrapper = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const preferenceChanged = useSelector(selectPreferenceChanged);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { promiseInProgress: isSkillSetDataLoading } = usePromiseTracker({
    area: 'get-skillset-for-unit',
    delay: 0,
  });
  const { promiseInProgress: isFacilityWithFilterDetailsLoading } = usePromiseTracker({
    area: 'get-facility-details-with-filters',
    delay: 0,
  });
  const { promiseInProgress: isFacilityUnitDetailsLoading } = usePromiseTracker({
    area: 'get-facility-unit-details',
    delay: 0,
  });

  const tabs = [
    { label: t('facility.units.unitDetails.tabs.general'), component: <UnitDetailsBody /> },
    { label: t('facility.units.unitDetails.tabs.preferences'), component: <UnitPreferencesWrapper /> },
    { label: t('facility.units.unitDetails.tabs.orders'), component: <UnitOrderWrapper /> },
  ];

  useEffect(() => {
    const tab = parseInt(queryString.parse(history.location.search).tab);
    const isTabSelectionValid = tab !== null && tab !== undefined && !isNaN(tab) && tab >= 0;
    dispatch(unitPreferenceActions.setTabValue(isTabSelectionValid ? tab : 0));
  }, []);

  //handling custom tab change based on preference tab data changes::
  const customTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (preferenceChanged) {
      dispatch(unitPreferenceActions.setConfirmationDialog(true));
      dispatch(unitPreferenceActions.setTabValue(newValue));
    } else {
      history.push({ pathname: history.location.pathname, search: `tab=${newValue}`, state: location?.state });
      dispatch(unitPreferenceActions.setTabValue(newValue));
    }
  };

  return (
    <Grid item xs={12}>
      <div className={classes.profileTab}>
        {isFacilityUnitDetailsLoading && isFacilityWithFilterDetailsLoading && isSkillSetDataLoading ? (
          <Grid display={'flex'} flexDirection={'column'} gap={3}>
            <Grid display={'flex'} flexDirection={'row'} gap={2}>
              <Skeleton variant="rectangular" width={52} height={19} />
              <Skeleton variant="rectangular" width={66} height={19} />
            </Grid>
            <UnitDetailsBody />
          </Grid>
        ) : (
          <GenericTabs
            tabs={tabs}
            modifyUrl
            customHandleChange={customTabChange}
            analytics={{ name: 'Unit Details' }}
          />
        )}
      </div>
    </Grid>
  );
};
