import React from 'react';
import CustomRangeDropdown from '../common/CustomRangeDropdown';
import { numberType } from '../common/CustomRangeDropdown';
import { RateElemenetSource, RateElements, getShiftId, refactorInputSingleValue } from '../helper';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectBillRateSelections,
  selectOcbrDetails,
  selectOcbrSavedDetails,
} from 'store/redux-store/create-order-ocbr/selector';
import { selectUser } from 'oidc/user.selectors';
import { createOrderOcbrActions } from 'store/redux-store/create-order-ocbr/slice';
import moment from 'moment';
import uuid from 'react-uuid';
import _ from 'lodash';

export const ExpectedHoursRateField = ({ label, name, shiftHours, shiftDetails }) => {
  const user = useSelector(selectUser);
  const { billRates: ocbrBillRates, effectiveDates } = useSelector(selectOcbrDetails);
  const { billRates: ocbrSavedBillRates } = useSelector(selectOcbrSavedDetails);
  const dispatch = useDispatch();

  const rateSelections = useSelector(selectBillRateSelections);
  const { value } = React.useMemo(() => {
    const selection = rateSelections[name];
    return !selection ? { value: null } : selection;
  }, [rateSelections]);

  const handleChange = newValue =>
    dispatch(
      createOrderOcbrActions.setBillRateSelection({
        [name]: { value: newValue },
      }),
    );

  // useEffect(() => {
  //   handleChange(null);
  // }, [effectiveDates]);

  const updateReduxValue = newVal => {
    // const isOcbrValue = checkIsBillRateOcbr(newVal);
    if (newVal !== undefined && ocbrBillRates) {
      const billRates = getBillRateData(newVal, ocbrBillRates);
      if (!_.isEqual(billRates, ocbrBillRates)) {
        // dispatch(createOrderOcbrActions.setOcberBillRates(billRates));
        dispatch(createOrderOcbrActions.setOcbrSavedBillRates(billRates));
        dispatch(createOrderOcbrActions.business_updateBillRates({ billRates }));
      }
    } else if (newVal !== undefined && !ocbrBillRates) {
      const billRates = getBillRateData(newVal, []);
      if (!_.isEqual(ocbrBillRates, billRates)) {
        // dispatch(createOrderOcbrActions.setOcberBillRates(billRates));
        dispatch(createOrderOcbrActions.setOcbrSavedBillRates(billRates));
        dispatch(createOrderOcbrActions.business_updateBillRates({ billRates }));
      }
    }
  };
  const updateSavedReduxValue = newVal => {
    //const isOcbr = checkIsBillRateOcbr(newVal);
    if (newVal !== undefined && ocbrSavedBillRates) {
      const billRates = getBillRateData(newVal, ocbrSavedBillRates);
      if (!_.isEqual(billRates, ocbrSavedBillRates)) {
        dispatch(createOrderOcbrActions.setOcbrSavedBillRates(billRates));
      }
    } else if (newVal !== undefined && !ocbrSavedBillRates) {
      const billRates = getBillRateData(newVal, []);
      if (!_.isEqual(ocbrSavedBillRates, billRates)) {
        dispatch(createOrderOcbrActions.setOcbrSavedBillRates(billRates));
      }
    }
  };

  const shiftId = shiftDetails.shiftName === null ? null : getShiftId(shiftDetails.shiftName);

  const getBillRateData = (newVal, billRatesArrOrg) => {
    //delete existing values
    const billRatesArr = (billRatesArrOrg ?? []).reduce((resultArray, item) => {
      if (item.shiftId === shiftId && item.type === RateElements.ExpectedHours)
        return resultArray;
      return [...resultArray, item];
    }, []);
    //Delete from Redux when value removed
    if (newVal === null) {
      return billRatesArr;
    }
    const temp = {
      offContractId: null,
      rateElementId: newVal.rateElementId ? newVal.rateElementId : uuid(),
      disciplineId: null,
      specialtyId: null,
      subSpecialtyId: null,
      shiftId: shiftDetails.shiftName === null ? null : getShiftId(shiftDetails.shiftName),
      shift: shiftDetails.shiftName,
      type: RateElements.ExpectedHours,
      billRate: refactorInputSingleValue(newVal),
      isOcbr: null,
      statusId: null,
      createdAt: null,
      createdBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
      reason: null,
      effectiveStartDate: effectiveDates?.startDate,
      effectiveEndDate: effectiveDates?.endDate,
      lastUpdatedBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
      dateLastUpdated: moment().format(),
      dateCreated: moment().format(),
      source: RateElemenetSource.Order,
      documentIds: newVal?.documentIds,
    };

    billRatesArr.push(temp);
    return billRatesArr || [];
  };
  return (
    <>
      <CustomRangeDropdown
        value={value}
        options={shiftHours}
        onChange={newVal => {
          handleChange(newVal);
        }}
        onBlur={newVal => {
          updateReduxValue(newVal);
          updateSavedReduxValue(newVal);
        }}
        // @ts-ignore
        label={label}
        name={name}
        labelPostfix={'Hours'}
        type={numberType.number}
      />
    </>
  );
};
