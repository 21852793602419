import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { Box, Grid, Skeleton, Typography } from 'amn-ui-core';
import React, { useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { DragDrop } from '../BillRates/DragDrop';
import { useDispatch, useSelector } from 'react-redux';
import { createOrderOcbrActions } from 'store/redux-store/create-order-ocbr/slice';
import { IRateDocument } from 'store/redux-store/create-order-ocbr/types';
import { selectCreateOrderOcbr, selectRateFeatureProps } from 'store/redux-store/create-order-ocbr/selector';
import { DocumentCard } from '@AMIEWEB/Common/DocumentDropzone/DocumentCard';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { useTranslation } from 'react-i18next';
import { Cancel } from '@AMIEWEB/Common';
import { gridSelectionActions } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.redux';
import { ErrorCode } from 'react-dropzone';
import { Concatenate } from 'utils/string/string';
import { usePromiseTracker } from 'react-promise-tracker';

const useStyles = makeStyles<{ modalHasError: boolean }>()((theme, { modalHasError }) => ({
  paper: {
    width: '600px',
    maxWidth: '600px',
  },
  infoSection: {
    padding: modalHasError ? '0 0 12px 0' : '24px 0 12px 0',
  },
  docCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    maxHeight: 400,
    overflowY: 'auto',
  },
  skeleton: {
    height: 20,
    borderRadius: 4,
  },
}));

interface IErrorDoc {
  errorSeverity: 'error' | 'warning';
  errorMessage: string;
}

const isolateAccepetedDocs = (docs = []) =>
  docs.map((x, index) =>
    index > 4
      ? { ...x, error: true, errorSeverity: 'error', errorMessage: 'Limit of 5 files reached' }
      : { ...x, error: false, errorSeverity: undefined, errorMessage: undefined },
  );

const DOC_PROCESS_TRACKER = 'track-rate-doc-upload';
export const UploadDocumentWrapper = ({
  addDocsModalOpen,
  setAddDocsModalOpen,
  gridSpecs: { gridSelections, rows },
  existingAttachments,
  displayDescription,
  isEditing,
}) => {
  const [errorProps, setErrorProps] = useState({
    error: false,
    errorMessage: undefined,
    errorSeverity: undefined,
  });
  const { classes } = useStyles({ modalHasError: errorProps.error });
  const { t } = useTranslation();
  const [expectedDocs, setExpectedDocs] = useState<IRateDocument[]>([]);
  const [failedDocs, setFailedDocs] = useState<(IRateDocument & IErrorDoc)[]>([]);
  const { docContainer } = useSelector(selectCreateOrderOcbr);
  // Code: Refer Status List from './OffContractOrderPage' (Pending = 1, Unsaved = 7)
  const [allowedStatuses] = useState([1, 7]);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const { promiseInProgress: processingDocs } = usePromiseTracker({ area: DOC_PROCESS_TRACKER, delay: 0 });
  const { rateCardRows: totalRows } = useSelector(selectCreateOrderOcbr);
  const { facilityId } = useSelector(selectRateFeatureProps);

  const dispatch = useDispatch();

  const resetDialogError = () =>
    setErrorProps({
      error: false,
      errorMessage: undefined,
      errorSeverity: undefined,
    });

  const onAddFormClose = useCallback(() => {
    setAddDocsModalOpen(false);
    setOpenCancelDialog(false);
    setExpectedDocs([]);
    setFailedDocs([]);
    resetDialogError();
  }, []);

  const onDocumentUpload = () => {
    let uploadedSelections = 0;
    const acceptedDocs = expectedDocs.slice(0, expectedDocs.length > 5 ? 5 : expectedDocs.length);
    var newRows;
    !isEditing
      ? (newRows = rows.map(
          row =>
            // uploadedSelections should only be incremented if prior conditions satisfy, and hence placed last
            gridSelections.some(selection => selection === row.id) &&
            allowedStatuses.includes(row.statusId) &&
            ++uploadedSelections
              ? { ...row, documentIds: acceptedDocs.map(i => i.hash) }
              : row,
          [],
        ))
      : (newRows = totalRows.map(
          row =>
            // uploadedSelections should only be incremented if prior conditions satisfy, and hence placed last
            gridSelections.some(selection => selection === row.id) &&
            allowedStatuses.includes(row.statusId) &&
            ++uploadedSelections
              ? { ...row, documentIds: acceptedDocs.map(i => i.hash) }
              : row,
          [],
        ));

    /** Determine new grid rows */
    dispatch(createOrderOcbrActions.business_updateTableRows({ tableRows: newRows }));

    /** Determinedoc container state */
    const allExistingReferences = newRows.reduce(
      (results, row) => (row.documentIds ? [...results, ...row.documentIds] : results),
      [],
    );
    const newDocs = acceptedDocs.filter(newDoc => !docContainer.some(existingDoc => existingDoc.hash === newDoc.hash));
    /** Code: Remove docs if reference no longer exist among grid data
     * - chances are existing docs get overwritten with new references
     */
    const newDocCOntainer = [...docContainer, ...newDocs].filter(x => allExistingReferences.includes(x.hash));
    dispatch(createOrderOcbrActions.setDocContainer(newDocCOntainer));
    dispatch(createOrderOcbrActions.saveDocuments(facilityId));
    onAddFormClose();
    dispatch(gridSelectionActions.reset());

    dispatch(
      globalActions.setSnackBar({
        message: t('order.facility.rateCard.documentUpload.successMessage', {
          totalSelections: gridSelections.length,
          uploadedSelections,
        }),
        severity: 'success',
      }),
    );
  };

  const handleModalErrorProps = (accepetdDocs, rejectedDocs) => {
    const errorCodes: ErrorCode[] = (rejectedDocs ?? []).reduce(
      (results, { errors }) => [...results, ...errors.map(x => x.errorCode)],
      accepetdDocs.length > 5 ? [ErrorCode.TooManyFiles] : [],
    );
    let countOfErrorTypesExisting = 0;
    const hasTypeError = errorCodes.includes(ErrorCode.FileInvalidType) && ++countOfErrorTypesExisting;
    const hasSizeError = errorCodes.includes(ErrorCode.FileTooLarge) && ++countOfErrorTypesExisting;
    const hasSizeWarning = errorCodes.includes(ErrorCode.TooManyFiles) && ++countOfErrorTypesExisting;
    if (countOfErrorTypesExisting > 0) {
      if (countOfErrorTypesExisting === 1)
        if (hasSizeWarning)
          setErrorProps({
            error: true,
            errorMessage: 'Limit of 5 files per upload.',
            errorSeverity: 'warning',
          });
        else
          setErrorProps({
            error: true,
            errorMessage: hasTypeError ? 'File type is not supported.' : 'File size exceeds 5MB limit.',
            errorSeverity: 'error',
          });
      else {
        let errMsg = `${countOfErrorTypesExisting} errors found: `;
        let index = 0;
        const typeErrorMsg = hasTypeError && ++index ? `${index}. File type is not supported` : '';
        const sizeErrorMsg = hasSizeError && ++index ? `${index}. File size exceeds 5MB limit` : '';
        const fileCountMsg = hasSizeWarning && ++index ? `${index}. Limit of 5 files per upload.` : '';
        setErrorProps({
          error: true,
          errorMessage: errMsg + Concatenate([typeErrorMsg, sizeErrorMsg, fileCountMsg], ', '),
          errorSeverity: 'error',
        });
      }
    } else resetDialogError();
  };

  const handleAcceptedDocs = (newDocs, rejectedDocs = undefined) => {
    setExpectedDocs(isolateAccepetedDocs(newDocs));
    if (!!rejectedDocs) handleRejections(rejectedDocs);
  };

  const handleDelete = useCallback(
    hash => handleAcceptedDocs(expectedDocs.filter(d => d.hash !== hash)),
    [expectedDocs],
  );

  const handleErrorDocsDelete = useCallback(
    hash => setFailedDocs(prevData => prevData.filter(d => d.hash !== hash)),
    [failedDocs],
  );

  const handleForceClose = () => {
    if (!expectedDocs.length) onAddFormClose();
    else setOpenCancelDialog(true);
  };

  const handleRejections = errDocs => {
    const docs = errDocs.map(x => ({ ...x, errorMessage: x.errors[0].message }));
    setFailedDocs(docs);
  };

  React.useEffect(() => handleModalErrorProps(expectedDocs, failedDocs), [expectedDocs, failedDocs]);

  React.useEffect(() => {
    setExpectedDocs(isolateAccepetedDocs(existingAttachments || []));
  }, [existingAttachments]);

  return (
    <>
      <GenericDialog
        open={addDocsModalOpen}
        disableEscapeKeyDown
        fullWidth
        variant="blue"
        classes={{ paper: classes.paper }}
        onClose={handleForceClose}
        dialogTitleProps={{
          text: 'Upload Approval Documents',
          closeButton: true,
          size: 'small',
        }}
        dialogContentProps={{ ...errorProps }}
        dialogActions={[
          {
            text: 'CANCEL',
            variant: 'contained',
            color: 'tertiary',
            onClick: handleForceClose,
          },
          {
            text: 'SAVE',
            variant: 'contained',
            color: 'primary',
            disabled: isEditing ? false : !expectedDocs.length,
            onClick: onDocumentUpload,
          },
        ]}
      >
        {displayDescription ? (
          <Typography variant="body1" classes={{ root: classes.infoSection }}>
            Documents will only be added to those Off-Contract Rate Elements that are in <strong>{`‘Unsaved’`}</strong>{' '}
            or
            <strong>{` ‘Pending’`}</strong> status.
          </Typography>
        ) : (
          <div style={{ padding: '8px 0px 10px 0px' }}></div>
        )}
        <Grid container direction="row" spacing={3}>
          <Grid item xs={6}>
            <DragDrop
              existingDocs={expectedDocs}
              handleAcceptedDocs={handleAcceptedDocs}
              trackerKey={DOC_PROCESS_TRACKER}
            />
          </Grid>
          <Grid item container direction="column" xs={6} spacing={2}>
            <Grid item>
              <Box fontSize="14px" fontWeight="500">
                Documents Uploaded
              </Box>
            </Grid>
            <Grid item classes={{ root: classes.docCardContainer }}>
              {processingDocs && (
                <>
                  <Skeleton variant="rectangular" classes={{ root: classes.skeleton }} />
                  <Skeleton variant="rectangular" classes={{ root: classes.skeleton }} />
                </>
              )}
              {expectedDocs.map(({ hash, name, type, size, ...rest }) => (
                <DocumentCard
                  hash={hash}
                  fileName={name}
                  fileType={type}
                  fileSize={size}
                  {...rest}
                  handleDelete={handleDelete}
                />
              ))}
              {failedDocs.map(({ hash, name, type, size, ...rest }) => (
                <DocumentCard
                  hash={hash}
                  fileName={name}
                  fileType={type}
                  fileSize={size}
                  {...rest}
                  error
                  handleDelete={handleErrorDocsDelete}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </GenericDialog>
      <Cancel
        openDialog={openCancelDialog}
        handleConfirmAction={onAddFormClose}
        handleCancelAction={() => setOpenCancelDialog(false)}
      />
    </>
  );
};
