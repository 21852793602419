import React, { useEffect, useState } from 'react';
import { useDebounce } from 'utils/customHooks/useDebounce';
import { ITypeAheadOption } from '../CustomComponents/ControlledTypeAheadWithAvatar';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { GenerateAllEmployeeObject } from './FormatData';
import { getAllEmployees } from 'app/services/TaskServices/TaskServices';
import { MultiSelectTypeAheadInlineChips } from '../CustomComponents/MultiSelectTypeAheadInlineChips';
import { isArray } from 'lodash';
import { TaskEntity } from 'app/models/Tasks/Tasks';
import { useTranslation } from 'react-i18next';

export const CCEmployees = props => {
  const { placementEmployees, setCCEmpvalues, ccEmpValues, assignedToValue, isDisabled, formMethods, container } =
    props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();
  const [keyword, keywordValue, setKeyword] = useDebounce<string>('', 500);
  const { promiseInProgress } = usePromiseTracker({ area: 'cc-employees', delay: 0 });
  const [employeeData, setEmployeeData] = useState<ITypeAheadOption[]>([]);

  const getFilteredData = employees => {
    const ccValues = ccEmpValues?.map(e => e?.object?.empId);
    if (isArray(assignedToValue)) {
      const assignedEmployeesValues = assignedToValue?.map(e => e?.object?.empId);
      const filteredData = employees
        ?.filter(
          val => !assignedEmployeesValues?.includes(val?.object?.empId) && !ccValues?.includes(val?.object?.empId),
        )
        .map(obj => obj);
      return filteredData;
    } else {
      const filteredData = employees
        ?.filter(a => a?.object?.empId !== assignedToValue && !ccValues?.includes(a?.object?.empId))
        .map(obj => obj);
      return filteredData;
    }
  };

  const fetchEmployeeDetails = async (searchKey: string) => {
    if (searchKey) {
      await getAllEmployees(searchKey).then(data => {
        const formatData = GenerateAllEmployeeObject(data);
        const filteredData = getFilteredData(formatData);
        setEmployeeData(filteredData);
      });
    }
  };

  useEffect(() => {
    if (keyword?.length > 2) {
      trackPromise(fetchEmployeeDetails(keyword), 'cc-employees');
    } else {
      const empData = placementEmployees?.length > 0 ? placementEmployees : [];
      const filterData = empData.length > 0 ? getFilteredData(empData) : [];
      setEmployeeData(filterData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, ccEmpValues]);

  useEffect(
    () => {
      if (placementEmployees?.length > 0) {
        const filteredData = assignedToValue ? getFilteredData(placementEmployees) : placementEmployees;
        setEmployeeData(filteredData);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [placementEmployees, assignedToValue],
  );

  return (
    <React.Fragment>
      <MultiSelectTypeAheadInlineChips
        label={
          ccEmpValues?.length > 0
            ? t('notification.createTask.ccBtnWithCount', {
              ccCount: `(${ccEmpValues?.length})`,
              })
            : t('notification.createTask.ccBtn')
        }
        options={employeeData}
        value={ccEmpValues}
        onChange={setCCEmpvalues}
        setKeyword={setKeyword}
        isLoading={promiseInProgress}
        isDisabled={isDisabled}
        inputValue={keyword}
        isOpenOption={container === TaskEntity.PLACEMENTS ? true : false}
        formMethods={formMethods}
      />
    </React.Fragment>
  );
};
