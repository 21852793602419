import { IRequirementRowPadding } from 'app/models/Placement/PlacementSummary';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import {
  selectEditableFields,
  selectEditableRequirementTypes,
  selectOfferInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { ConfirmationCheckbox } from '../../TableComponents/Confirmations/ConfirmationCheckbox';
import { ConfirmationsRow } from '../../TableComponents/Confirmations/ConfirmationsTable';

export const DnsCleared = ({ hasRequirements }: IRequirementRowPadding) => {
  const { t } = useTranslation();
  const { dnsCleared: initialValue } = useSelector(selectEditableFields);
  const { requirementFilter } = useSelector(selectOfferInfo);
  const { dnsCleared } = useSelector(selectEditableRequirementTypes);
  const { control } = useFormContext();

  const { display } = useRequirementFilter('offer-confirmation', requirementFilter, dnsCleared?.requirement);

  return display ? (
    <Controller
      control={control}
      name="dnsCleared"
      render={({ ref, ...rest }) => (
        <ConfirmationsRow
          id="placement-offer-confirmations-dns-cleared"
          lastUpdatedBy={rest?.value?.lastUpdatedBy}
          lastUpdatedDate={rest?.value?.lastUpdatedAt}
          requirement={dnsCleared?.requirement}
          hasOtherRequirements={hasRequirements}
          controlComponent={
            <ConfirmationCheckbox
              label={t('placement.profile.summary.offer.confirmations.dnsCleared')}
              defaultState={initialValue}
              {...rest}
            />
          }
        />
      )}
    />
  ) : null;
};
