import { PayloadAction } from '@reduxjs/toolkit';
import { manuallyIncrementPromiseCounter, manuallyResetPromiseCounter, trackPromise } from 'react-promise-tracker';
import { all, call, cancelled, getContext, put, PutEffect, select, takeLatest } from 'redux-saga/effects';
import { newPlacementActions } from './slice';
import {
  IPlacementStatus,
  PlacementOrderType,
  getPlacementOrderTypeValue,
  IPlacementRequirementKey,
  IPlacementStatusSuccessType,
  IPlacementStatusSource,
  IPlacementStatusRequirementTooltip,
} from 'app/models/Placement/PlacementDetails';
import { placementStatusAction } from '../placement-status/slice';
import {
  DefaultCurrentPlacementStatus,
  isInitialStatus,
  isRFO,
  isTA,
  isWFC,
  placementStatusOptions,
} from '../../../app/components/Placement/NextPlacementStatus/StatusDefaults';
import { selectPlacementStatus } from '../placement-status/selectors';
import { httpSuccess } from 'app/services/serviceHelpers';
import {
  selectCreatePlacementModalFunctions,
  selectCreatePlacementOrderType,
  selectNewPlacementId,
  selectNewPlacementTimestamp,
} from './selectors';
import { selectUser } from 'oidc/user.selectors';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { checkPlacementTimestamps, getPlacementTimestamp } from '../placement-details/request.saga';
import { placementDetailsAction } from '../placement-details/slice';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { CandidateService } from 'app/services/CandidateServices/candidate-service';
import { OrderService } from 'app/services/OrderServices/order-service';
import { ISavePlacement, IUpdateCreatePlacement } from './types';
import { requirementType } from 'app/models/Placement/PlacementSummary';
import moment from 'moment';
import { missingField } from 'app/constants';
import i18next from 'i18next';
import { setFailedRequirement } from '../placement-summary/saga';
import { placementSummaryActions } from '../placement-summary/slice';
import { SessionKey, setSessionValue, StorageType } from 'utils/customHooks/sessionStorage/sessionHelpers';
import axios from 'axios';
import { SharedService } from 'app/services/SharedServices/shared-service';
import { Concatenate } from 'utils/string/string';
import { getExperienceData } from '@AMIEWEB/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/Statuses/Submission/groupedSkillsetRows/ExperieneRow';
import { deriveOrderSkillsetOpts } from '@AMIEWEB/Placement/CreateNewPlacement/helper';
import { orderPreferenceActions } from '@AMIEWEB/Order/OrderDetails/OrderPreferences/store/OrderPreference.redux';
import { requestAssignmentInformationRequirement } from '../placement-summary/common-saga';
import { formatCueNoteEmployeeList } from '@AMIEWEB/Placement/CreateNewPlacement/CuteNotesConfirmation/helper';
import { IPlacementTimestampResponse } from 'app/models/Placement';
import { IRequestCueNotePayload } from '@AMIEWEB/Placement/CreateNewPlacement/CuteNotesConfirmation/model';

const TrackCandiadteWrapper = (fn, ...args) => trackPromise(fn(...args), 'new-placement-get-candidate');
const TrackOrderWrapper = (fn, ...args) => trackPromise(fn(...args), 'new-placement-get-order');
const TrackValidationWrapper = (fn, ...args) => trackPromise(fn(...args), 'new-placement-get-validations');
const TrackSubmitWrapper = (fn, ...args) => trackPromise(fn(...args), 'new-placement-submit');
const TrackPlacementChangeWrapper = (fn, ...args) => trackPromise(fn(...args), 'placement-change-submit');

export function* onInit(action: PayloadAction<{ candidateId?: number; brandId?: number; orderId?: number }>) {
  if (action.payload.candidateId && action.payload.brandId)
    yield requestCandidateDetails({
      payload: { candidateId: action.payload.candidateId, brandId: action.payload.brandId },
      type: newPlacementActions.getCandidateAction.type,
    });
  if (action.payload.orderId)
    yield requestOrderDetails({
      payload: { orderId: action.payload.orderId },
      type: newPlacementActions.getOrderAction.type,
    });
  yield requestScreenOptions({ type: newPlacementActions.requestScreenOptions.type, payload: undefined });
}

export function* requestScreenOptions(action: PayloadAction) {
  try {
    const sharedService: SharedService = yield getContext('sharedService');
    const response = yield call(TrackValidationWrapper, sharedService.getScreenOptions);
    if (response && httpSuccess(response.status))
      yield put(
        newPlacementActions.setMiscellaneousDataByKey({
          key: 'screenOptions',
          data: response.data.filter(item => item.value !== '9'),
        }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestScreenOptions',
        area: 'src/app/components/Placement/CreatePlacement/store/NewPlacement.saga.ts',
      },
    });
  }
}

export function* requestCandidateDetails(
  action: PayloadAction<{ candidateId: number; brandId: number; useNewCreatePlacementModalFunctions?: boolean }>,
) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const placementService: PlacementService = yield getContext('placementService');
    const useNewModalFunctions = yield select(selectCreatePlacementModalFunctions);

    const [response, skillResponse] = yield all([
      call(
        TrackCandiadteWrapper,
        candidateService.getCandidateForPlacementCreation,
        action.payload.candidateId,
        action.payload.brandId,
      ),
      ...(useNewModalFunctions || action.payload.useNewCreatePlacementModalFunctions
        ? [call(placementService.getCandidateSkillsForPlacementCreation, action.payload.candidateId)]
        : []),
    ]);

    if (response && httpSuccess(response.status)) {
      if (skillResponse && httpSuccess(skillResponse.status)) response.data.groupedSkillsets = skillResponse.data;
      yield put(newPlacementActions.setCandidate(response.data));
    } else {
      yield put(globalActions.setSnackBar({ message: 'A network error has occurred', severity: 'error' }));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestCandidateDetails',
        area: 'src/app/components/Placement/CreatePlacement/store/NewPlacement.saga.ts',
      },
    });
  }
}

export function* requestOrderDetails(action: PayloadAction<{ orderId: number }>) {
  try {
    const orderService: OrderService = yield getContext('orderService');
    yield put(orderPreferenceActions.getAutomationStatusByStage({ orderId: action.payload.orderId }));

    const response = yield call(TrackOrderWrapper, orderService.getOrderForPlacementCreation, action.payload.orderId);
    if (response && httpSuccess(response.status)) {
      yield put(newPlacementActions.setOrder(response.data));
      yield put(newPlacementActions.setCreateScreenEnabled(response.data.positionsAvailable === 0));
      yield put(
        newPlacementActions.setMiscellaneousDataByKey({
          key: 'orderSkillOpts',
          data: deriveOrderSkillsetOpts(response.data.groupedSkillsets || []),
        }),
      );
    } else yield put(globalActions.setSnackBar({ message: 'A network error has occurred', severity: 'error' }));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestOrderDetails',
        area: 'src/app/components/Placement/CreatePlacement/store/NewPlacement.saga.ts',
      },
    });
  }
}

const IsStrike = placementOrderType => {
  return placementOrderType === PlacementOrderType.StrikeOrderInHsg;
};

export const GetPlacementSummaryMessage = (response, placementOrderType) => {
  let { data } = response;

  const messageId = data?.amnRequirement?.messageId;
  const version = data?.amnRequirement?.messageVersion;

  switch (`${messageId}`) {
    case IPlacementRequirementKey.amnEducation: {
      const messageContent = GetPassMessageMessageId(messageId, version, 1, data?.amnRequirement?.data);
      /*data.amnRequirement = {
        ...data.amnRequirement,
        message: {
          primary: messageContent?.message ?? '',
          secondary: messageContent?.tooltip?.message ?? '',
          ternary: messageContent?.tooltip?.supportingList,
        },
      };*/
      const updatedData = {
        ...data,
        amnRequirement: {
          ...data.amnRequirement,
          message: {
            primary: messageContent?.message ?? '',
            secondary: messageContent?.tooltip?.message ?? '',
            ternary: messageContent?.tooltip?.supportingList,
          },
        },
      };
      return updatedData;
    }
    case IPlacementRequirementKey.amnCandidateStatus: {
      const messageContent = GetPassMessageMessageId(messageId, 'v1', 1, data?.amnRequirement?.data);
      /*data.amnRequirement = {
        ...data.amnRequirement,
        message: {
          primary: messageContent?.message ?? '',
          secondary: messageContent?.tooltip?.message ?? '',
          ternary: messageContent?.tooltip?.supportingList,
        },
      };*/
      const updatedData = {
        ...data,
        amnRequirement: {
          ...data.amnRequirement,
          message: {
            primary: messageContent?.message ?? '',
            secondary: messageContent?.tooltip?.message ?? '',
            ternary: messageContent?.tooltip?.supportingList,
          },
        },
      };

      return updatedData;
    }
    case IPlacementRequirementKey.amnAudit: {
      const messageContent = GetPassMessageMessageId(
        messageId,
        IsStrike(placementOrderType) ? 'v1' : 'v2',
        1,
        data?.amnRequirement?.data,
      );
      /*data.amnRequirement = {
        ...data.amnRequirement,
        message: {
          primary: messageContent?.message ?? '',
        },
      };*/
      const updatedData = {
        ...data,
        amnRequirement: {
          ...data.amnRequirement,
          message: {
            primary: messageContent?.message ?? '',
          },
        },
      };

      return updatedData;
    }
    case IPlacementRequirementKey.amnSkillsChecklist: {
      if (data?.amnRequirement?.type !== null) {
        if (data?.amnRequirement.type === requirementType.pass && data?.amnRequirement?.messageVersion === 'v2') {
          const messageContent = GetPassMessageMessageId(
            messageId,
            data?.amnRequirement.messageVersion,
            requirementType.pass,
            data?.amnRequirement?.data,
          );
          /*data.amnRequirement = {
            ...data.amnRequirement,
            messageCategory: 1,
            message: {
              primary: messageContent?.tooltip?.message ?? '',
            },
          };*/
          const updatedData = {
            ...data,
            amnRequirement: {
              ...data.amnRequirement,
              messageCategory: 1,
              message: {
                primary: messageContent?.tooltip?.message ?? '',
              },
            },
          };
          data = updatedData;
        } else if (
          data?.amnRequirement.type === requirementType.pass &&
          data?.amnRequirement?.messageVersion === 'v1'
        ) {
          const messageContent = GetPassMessageMessageId(
            messageId,
            data?.amnRequirement.messageVersion,
            requirementType.pass,
            data?.amnRequirement?.data,
          );
          /*data.amnRequirement = {
            ...data.amnRequirement,
            messageCategory: 2,
            message: {
              primary: messageContent?.tooltip?.message ?? '',
              ternary: data?.amnRequirement?.data.map(skill => skill),
            },
          };*/
          const updatedData = {
            ...data,
            amnRequirement: {
              ...data.amnRequirement,
              messageCategory: 2,
              message: {
                primary: messageContent?.tooltip?.message ?? '',
                ternary: data?.amnRequirement?.data.map(skill => skill),
              },
            },
          };
          data = updatedData;
        } else if (data?.amnRequirement.type === requirementType.fail) {
          const messageContent = GetPassMessageMessageId(
            messageId,
            data?.amnRequirement.messageVersion,
            requirementType.fail,
            data?.amnRequirement?.data,
          );
          /*data.amnRequirement = {
            ...data.amnRequirement,
            messageCategory: 2,
            message: {
              primary: messageContent?.tooltip?.message ?? '',
              ternary: data?.amnRequirement?.data.map(skill => skill),
            },
          };*/

          const updatedData = {
            ...data,
            amnRequirement: {
              ...data.amnRequirement,
              messageCategory: 2,
              message: {
                primary: messageContent?.tooltip?.message ?? '',
                ternary: data?.amnRequirement?.data.map(skill => skill),
              },
            },
          };

          data = updatedData;
        }
      }
      return data;
    }
    case IPlacementRequirementKey.orderSkillset: {
      const messageContent = GetPassMessageMessageId(messageId, 'v2', 1, data?.amnRequirement?.data);
      const messageValue = [
        {
          icon: data?.amnRequirement?.data?.verifiedByCmType,
          message: {
            secondary: messageContent?.message,
          },
        },
      ] as any;
      const messageContentX = GetPassMessageMessageId(messageId, 'v1', 1, data?.amnRequirement?.data);
      messageValue.push({
        icon: data?.amnRequirement?.data?.strikeMatchType,
        message: {
          secondary: messageContentX?.tooltip?.message ?? '',
          ternary: messageContentX?.tooltip?.supportingList,
        },
      });
      /*data.amnRequirement = {
        ...data.amnRequirement,
        message: messageValue,
      };*/

      const updatedData = {
        ...data,
        amnRequirement: {
          ...data.amnRequirement,
          message: messageValue,
        },
      };

      return updatedData;
    }
    case IPlacementRequirementKey.amnExperience: {
      const messageContent = GetPassMessageMessageId(
        messageId,
        version === 'v6' ? 'v7' : version,
        1,
        data?.amnRequirement?.data,
      );
      /*data.amnRequirement = {
        ...data.amnRequirement,
        message: {
          primary: messageContent?.message ?? '',
          secondary: messageContent?.tooltip?.message ?? '',
          ternary: messageContent?.tooltip?.supportingList,
        },
      };*/
      const updatedData = {
        ...data,
        amnRequirement: {
          ...data.amnRequirement,
          message: {
            primary: messageContent?.message ?? '',
            secondary: messageContent?.tooltip?.message ?? '',
            ternary: messageContent?.tooltip?.supportingList,
          },
        },
      };
      return updatedData;
    }
    case IPlacementRequirementKey.amnWorkHistory: {
      const messageContent = GetPassMessageMessageId(messageId, 'v1', 1, data?.amnRequirement?.data);
      /*data.amnRequirement = {
        ...data.amnRequirement,
        message: {
          primary: data?.amnRequirement?.type !== null ? messageContent?.message : missingField,
        },
      };*/
      const updatedData = {
        ...data,
        amnRequirement: {
          ...data.amnRequirement,
          message: {
            primary: data?.amnRequirement?.type !== null ? messageContent?.message : missingField,
          },
        },
      };
      return updatedData;
    }
    case IPlacementRequirementKey.orderWorkHistory: {
      const messageContent = GetPassMessageMessageId(
        messageId,
        data?.orderRequirement?.messageVersion ?? 'v1',
        1,
        data?.orderRequirement?.data,
      );
      /*data.orderRequirement = {
        ...data.orderRequirement,
        message: {
          primary: data?.orderRequirement?.type !== null ? messageContent?.message : missingField,
        },
      };*/
      const updatedData = {
        ...data,
        orderRequirement: {
          ...data.orderRequirement,
          message: {
            primary: data?.orderRequirement?.type !== null ? messageContent?.message : missingField,
          },
        },
      };
      return updatedData;
    }
    default:
      return data;
  }
};

export const GetPassMessageMessageId = (messageId, version, type, data) => {
  const messagePrePath = 'placement.profile.reqMessages';
  switch (`${messageId}`) {
    case IPlacementRequirementKey.amnAudit:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
      };
    case IPlacementRequirementKey.amnWorkHistory:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
      };
    case IPlacementRequirementKey.orderWorkHistory:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
      };
    case IPlacementRequirementKey.amnCandidateStatus:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: i18next.t(
          `${messagePrePath}.${messageId}.${type}.${version}.tooltip`,
        ) as IPlacementStatusRequirementTooltip,
      };
    case IPlacementRequirementKey.orderSkillset:
      return {
        message: i18next.t(`${messagePrePath}.${messageId}.${type}.${version}.message`),
        tooltip: i18next.t(
          `${messagePrePath}.${messageId}.${type}.${version}.tooltip`,
        ) as IPlacementStatusRequirementTooltip,
      };
    case IPlacementRequirementKey.amnExperience:
      return {
        message: i18next.t(`${messagePrePath}.${messageId}.${type}.${version}.message`),
        tooltip: i18next.t(
          `${messagePrePath}.${messageId}.${type}.${version}.tooltip`,
        ) as IPlacementStatusRequirementTooltip,
      };
    case IPlacementRequirementKey.amnEducation:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: i18next.t(
          `${messagePrePath}.${messageId}.${type}.${version}.tooltip`,
        ) as IPlacementStatusRequirementTooltip,
      };
    case IPlacementRequirementKey.amnSkillsChecklist:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: i18next.t(
          `${messagePrePath}.${messageId}.${type}.${version}.tooltip`,
        ) as IPlacementStatusRequirementTooltip,
      };
    default:
      return null;
  }
};

export const GetMessageMessageId = (messageId, version, type, data) => {
  const messagePrePath = 'placement.profile.reqMessages';
  switch (`${messageId}`) {
    case IPlacementRequirementKey.orderDOB:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.orderSsn:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.orderLicense:
      const failRequirementCount = data?.failRequirementCount;
      return {
        message: i18next.t(`${messagePrePath}.${messageId}.${type}.${version}.message`, { failRequirementCount }),
        tooltip: i18next.t(
          `${messagePrePath}.${messageId}.${type}.${version}.tooltip`,
        ) as IPlacementStatusRequirementTooltip,
      };
    case IPlacementRequirementKey.availableStartDate:
      const availableMoment = moment(data);
      return {
        message: `Available Start Date: ${
          availableMoment.isValid() ? availableMoment.format('MM/DD/YYYY') : 'Missing'
        }`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.orderWorkHistory:
      const workHistoryGapInDaysCount = data?.workHistoryGapInDays;
      const workHistoryGapInDays = `${data?.workHistoryGapInDays} days`;
      const workHistoryGapInYears = data?.entireWorkHistory
        ? `entire work history`
        : `${data?.workHistoryGapInYears} years`;
      return {
        message: i18next.t(`${messagePrePath}.${messageId}.${type}.${version}.message`, { workHistoryGapInDaysCount }),
        tooltip: {
          message: i18next.t(`${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`, {
            workHistoryGapInDays,
            workHistoryGapInYears,
          }),
        },
      };
    case IPlacementRequirementKey.orderReferences:
      const numberOfReferences = data?.numberOfReferenceToRFO;
      const referenceMonths =
        (data?.withinMonths === null && data?.withinNumberOfMonths > 0) ||
        (!data?.withinMonths && data?.withinNumberOfMonths && data?.numberOfReferenceToRFO > 0) // withinMonth == IsEntireWorkHistory as per API
          ? i18next.t(`placement.profile.summary.submission.order.reference.timeFrameValueMessage`, {
              orderMonths: data?.withinNumberOfMonths,
            })
          : i18next.t('placement.profile.summary.submission.order.reference.entireWorkhistoryMessage');
      const referenceFromSupervisor = data?.referenceFromSupervisor
        ? i18next.t('placement.profile.summary.submission.order.reference.supervisorType')
        : i18next.t('placement.profile.summary.submission.order.commons.any');
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: {
          message: i18next.t(`${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`, {
            numberOfReferences,
            referenceMonths,
            referenceFromSupervisor,
          }),
        },
      };
    case IPlacementRequirementKey.amnAudit:
      const auditCount = data;
      return {
        message: `Hold File Out audit: ${auditCount ?? missingField}`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.amnCandidateStatus:
      const candidateStatus = data;
      return {
        message: `Candidate Status: ${candidateStatus ?? missingField}`,
        tooltip: i18next.t(
          `${messagePrePath}.${messageId}.${type}.${version}.tooltip`,
        ) as IPlacementStatusRequirementTooltip,
      };
    case IPlacementRequirementKey.orderSkillset:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: i18next.t(
          `${messagePrePath}.${messageId}.${type}.${version}.tooltip`,
        ) as IPlacementStatusRequirementTooltip,
      };
    case IPlacementRequirementKey.amnSuspension:
      const suspension = data;
      return {
        message: `License/Cert/Invest/Susp: ${suspension === true ? 'Yes' : 'No'}`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.amnUsRightToWork:
      const rightToWorkVerification = data;
      return {
        message: `U.S. Right to Work: ${rightToWorkVerification === true ? 'Yes' : 'No'}`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.orderMaxFilesSent:
      if (version === 'v2') {
        return {
          message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
          tooltip: {
            message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
          },
        };
      } else {
        return {
          message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        };
      }
    case IPlacementRequirementKey.orderCertifications:
      const certificationExpiredCount = data;
      return {
        message: `Certifications: ${certificationExpiredCount ?? '0'}`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.orderRadius:
      const billRateAgreement = data;
      return {
        // message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        message: `Radius Rule: ${billRateAgreement?.billRateAgreement ?? missingField}`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.orderCovid:
      const candidateCovidStatus = data;
      return {
        message: `COVID Status: ${candidateCovidStatus ?? missingField}`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.amnEducation:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: i18next.t(
          `${messagePrePath}.${messageId}.${type}.${version}.tooltip`,
        ) as IPlacementStatusRequirementTooltip,
      };
    case IPlacementRequirementKey.amnExperience:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: i18next.t(
          `${messagePrePath}.${messageId}.${type}.${version}.tooltip`,
        ) as IPlacementStatusRequirementTooltip,
      };
    case IPlacementRequirementKey.orderExperience:
      const experienceList = data?.map(x => Concatenate([x?.disciplineAbbr, x?.specialtyAbbr], ` - `))?.join(', ');
      const orderExperienceTooltipList = data?.map(x => {
        const years = x?.yearsOfExperience;
        const months = x?.monthsOfExperience;
        const discipline = x?.disciplineAbbr;
        const specialty = x?.specialtyAbbr;
        const experience = `${getExperienceData(years, months)}`;
        return `${discipline} - ${specialty} (${experience})`;
      });
      return {
        message: i18next.t(`${messagePrePath}.${messageId}.${type}.${version}.message`, { experienceList }),
        tooltip: {
          message: i18next.t(`${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`),
          supportingList: orderExperienceTooltipList ? [...orderExperienceTooltipList] : [],
        },
      };
    case IPlacementRequirementKey.amnSkillsChecklist:
      if (version === 'v1') {
        return {
          message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
          tooltip: {
            message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
            supportingList: data ? [...data] : [],
          },
        };
      } else {
        return {
          message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
          tooltip: i18next.t(
            `${messagePrePath}.${messageId}.${type}.${version}.tooltip`,
          ) as IPlacementStatusRequirementTooltip,
        };
      }
    case IPlacementRequirementKey.amnWorkHistory:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.placementDate:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.orderShiftOffered:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.arrived:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.wisely:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.gtgComplete:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.finalizedByQS:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.virtualInterview:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.facility:
      const placementActivityStatus = data;
      return {
        message: `Already active placement: ${placementActivityStatus ?? missingField}`,
        tooltip: i18next.t(
          `${messagePrePath}.${messageId}.${type}.${version}.tooltip`,
        ) as IPlacementStatusRequirementTooltip,
      };
    case IPlacementRequirementKey.facilityStatus:
      const facilityStatus = data;
      return {
        message: `Facility Status: ${facilityStatus ?? missingField}`,
        tooltip: i18next.t(
          `${messagePrePath}.${messageId}.${type}.${version}.tooltip`,
        ) as IPlacementStatusRequirementTooltip,
      };
    case IPlacementRequirementKey.facilityDivision:
      const division = data;
      return {
        message: `Order Division: ${division ?? missingField}`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.orderType:
      const orderType = data;
      return {
        message: `Order Type: ${orderType ?? missingField}`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.brand:
      const brand = data;
      return {
        message: `Brand: ${brand ?? missingField}`,
        tooltip: i18next.t(
          `${messagePrePath}.${messageId}.${type}.${version}.tooltip`,
        ) as IPlacementStatusRequirementTooltip,
      };
    case IPlacementRequirementKey.pendingModification:
      const pendingModCount = data;
      return {
        message: i18next.t(`${messagePrePath}.${messageId}.${type}.${version}.message`, { pendingModCount }),
        tooltip: i18next.t(
          `${messagePrePath}.${messageId}.${type}.${version}.tooltip`,
        ) as IPlacementStatusRequirementTooltip,
      };
    case IPlacementRequirementKey.arbitrationAgreement:
      const status =
        data?.arbitrationStatusId === null ? 'Not Sent' : data?.arbitrationStatusId === 1 ? 'Sent' : missingField;
      return {
        message: i18next.t(`${messagePrePath}.${messageId}.${type}.${version}.message`, { status }),
        tooltip: i18next.t(
          `${messagePrePath}.${messageId}.${type}.${version}.tooltip`,
        ) as IPlacementStatusRequirementTooltip,
      };
    default:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: i18next.t(
          `${messagePrePath}.${messageId}.${type}.${version}.tooltip`,
        ) as IPlacementStatusRequirementTooltip,
      };
  }
};

export const GetRiskMessageMessageId = (messageId, version, type, data) => {
  const messagePrePath = 'placement.profile.reqMessages';
  switch (`${messageId}`) {
    case IPlacementRequirementKey.orderLicense:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.availableStartDate:
      const availableMoment = moment(data);
      return {
        message: `Available Start Date: ${
          availableMoment.isValid() ? availableMoment.format('MM/DD/YYYY') : 'Missing'
        }`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.orderCertifications:
      const certificationRiskCount = data;
      return {
        message: `Certifications Expiring in 30 Days or Less: ${certificationRiskCount ?? '0'}`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.orderRadius:
      const billRateAgreement = data;
      return {
        // message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        message: `Radius Rule: ${billRateAgreement?.billRateAgreement ?? missingField}`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.orderReferences:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.recruiterAssigned:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.facility:
      const placementActivityStatus = data;
      return {
        message: `Already active placement: ${placementActivityStatus ?? missingField}`,
        tooltip: i18next.t(
          `${messagePrePath}.${messageId}.${type}.${version}.tooltip`,
        ) as IPlacementStatusRequirementTooltip,
      };
    case IPlacementRequirementKey.facilityStatus:
      const facilityStatus = data;
      return {
        message: `Facility Status: ${facilityStatus ?? missingField}`,
        tooltip: i18next.t(
          `${messagePrePath}.${messageId}.${type}.${version}.tooltip`,
        ) as IPlacementStatusRequirementTooltip,
      };
    case IPlacementRequirementKey.facilityDivision:
      const division = data;
      return {
        message: `Order Division: ${division ?? missingField}`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.positions:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.discipline:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.orderType:
      const orderType = data;
      return {
        message: `Order Type: ${orderType ?? missingField}`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.brand:
      const brand = data;
      return {
        message: `Brand: ${brand ?? missingField}`,
        tooltip: i18next.t(
          `${messagePrePath}.${messageId}.${type}.${version}.tooltip`,
        ) as IPlacementStatusRequirementTooltip,
      };
    case IPlacementRequirementKey.virtualInterview:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.orderCovid:
      const candidateCovidStatus = data;
      return {
        message: `COVID Status: ${candidateCovidStatus ?? missingField}`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
    case IPlacementRequirementKey.arbitrationAgreement:
      const status =
        data?.arbitrationStatusId === null ? 'Not Sent' : data?.arbitrationStatusId === 1 ? 'Sent' : missingField;
      return {
        message: i18next.t(`${messagePrePath}.${messageId}.${type}.${version}.message`, { status }),
        tooltip: i18next.t(
          `${messagePrePath}.${messageId}.${type}.${version}.tooltip`,
        ) as IPlacementStatusRequirementTooltip,
      };
    default:
      return {
        message: `${messagePrePath}.${messageId}.${type}.${version}.message`,
        tooltip: {
          message: `${messagePrePath}.${messageId}.${type}.${version}.tooltip.message`,
        },
      };
  }
};

export const GetRequirementByMessageId = (
  messageId,
  version,
  type,
  data,
  messageTypeId,
  tooltipTypeParam,
  requirementTypeParam,
) => {
  if (requirementTypeParam === requirementType.fail) {
    const messageContent = GetMessageMessageId(messageId, version, type, data);
    return {
      id: messageTypeId,
      message: messageContent?.message ?? '',
      tooltip: {
        message: messageContent?.tooltip?.message ?? '',
        supportingList: messageContent?.tooltip?.supportingList,
        tooltipType: tooltipTypeParam,
      },
    };
  } else {
    const messageContent = GetRiskMessageMessageId(messageId, version, type, data);
    return {
      id: messageTypeId,
      message: messageContent?.message ?? '',
      tooltip: {
        message: messageContent?.tooltip?.message ?? '',
        supportingList: messageContent?.tooltip?.supportingList,
        tooltipType: tooltipTypeParam,
      },
    };
  }
};

export function* SetPlacementFailRiskRequirementMessage(
  action: PayloadAction<{
    messageId;
    version;
    passFailType: number | null;
    data;
    messageTypeId;
    tooltipTypeParam;
    nextStatusRequirementParam;
  }>,
) {
  if (action.payload.passFailType === requirementType.fail) {
    yield call(setFailedRequirement, {
      payload: {
        nextStatusRequirement: action.payload.nextStatusRequirementParam,
        requirement: GetRequirementByMessageId(
          action.payload.messageId,
          action.payload.version,
          action.payload.passFailType,
          action.payload.data,
          action.payload.messageTypeId,
          action.payload.tooltipTypeParam,
          action.payload.passFailType,
        ),
      },
      type: placementSummaryActions.setFailedRequirement.type,
    });
  } else if (action.payload.passFailType === requirementType.risk) {
    yield put(
      placementStatusAction.setWarningRequirement({
        requirement: GetRequirementByMessageId(
          action.payload.messageId,
          action.payload.version,
          action.payload.passFailType,
          action.payload.data,
          action.payload.messageTypeId,
          undefined,
          action.payload.passFailType,
        ),
      }),
    );
  }
}

function* validationProessing(params: {
  isRfoValid: boolean;
  isWfcValid: boolean;
  isTAValid: boolean;
  rfoRequirements: { [key: string]: any };
  wfcRequirements: { [key: string]: any };
  taRequirements: { [key: string]: any };
  currentPlacementStatus: IPlacementStatus;
}) {
  try {
    const placementOrderType = yield select(selectCreatePlacementOrderType);
    yield put(placementStatusAction.resetFailedRequirements());
    yield put(placementStatusAction.resetWarningRequirements());
    yield put(
      newPlacementActions.setValidation({
        isRFOValid: params.isRfoValid,
        isWFCValid: params.isWfcValid,
        isTAValid: params.isTAValid,
      }),
    );
    if (params.wfcRequirements && params.currentPlacementStatus === DefaultCurrentPlacementStatus) {
      yield all(
        Object.values<any>(params.wfcRequirements).reduce((storage: PutEffect[], obj: any) => {
          if (obj !== null && !obj.isValidated) {
            storage.push(
              put(
                placementStatusAction.setFailedRequirementForStatus({
                  status:
                    placementOrderType === PlacementOrderType.StrikeOrderInHsg
                      ? placementStatusOptions.travelerAccepts
                      : placementStatusOptions.requestFileOut,
                  requirement: {
                    id: IPlacementRequirementKey.createPlacement,
                    message: 'Requirements: Waiting for Clearance',
                    tooltip: {
                      message: 'Candidate must meet all requirements for Waiting for Clearance.',
                    },
                  },
                }),
              ),
            );
            const messageContent = GetMessageMessageId(obj?.messageId, obj?.messageVersion, obj?.type, obj?.data);
            if (messageContent) {
              storage.push(
                put(
                  placementStatusAction.setFailedRequirementForStatus({
                    status: placementStatusOptions.waitingForClearance,
                    requirement: {
                      id: IPlacementRequirementKey.createPlacement,
                      message: messageContent.message,
                      tooltip: messageContent.tooltip,
                    },
                  }),
                ),
              );
            } else {
              const datax = obj?.message?.ternary ?? [];
              if (datax && obj?.message?.additionalInfo && obj?.message?.additionalInfo.length > 0) {
                datax.push(obj?.message?.additionalInfo);
              }
              storage.push(
                put(
                  placementStatusAction.setFailedRequirementForStatus({
                    status: placementStatusOptions.waitingForClearance,
                    requirement: {
                      id: IPlacementRequirementKey.createPlacement,
                      message: obj.message.primary,
                      tooltip: {
                        message: obj.message.secondary,
                        supportingList: datax,
                      },
                    },
                  }),
                ),
              );
            }
          } else if (obj !== null && obj?.type === requirementType.risk) {
            const messageContent = GetRiskMessageMessageId(obj?.messageId, obj?.messageVersion, obj?.type, obj?.data);
            if (messageContent) {
              storage.push(
                put(
                  placementStatusAction.setWarningRequirement({
                    requirement: {
                      id: IPlacementRequirementKey.createPlacement,
                      message: messageContent.message,
                      tooltip: messageContent.tooltip,
                    },
                  }),
                ),
              );
            } else {
              const datax = obj?.message?.ternary ?? [];
              if (datax && obj?.message?.additionalInfo && obj?.message?.additionalInfo.length > 0) {
                datax.push(obj?.message?.additionalInfo);
              }
              storage.push(
                put(
                  placementStatusAction.setWarningRequirement({
                    requirement: {
                      id: IPlacementRequirementKey.createPlacement,
                      message: obj.message.primary,
                      tooltip: {
                        message: obj.message.secondary,
                        supportingList: datax,
                      },
                    },
                  }),
                ),
              );
            }
          }
          return storage;
        }, []),
      );
    }
    if (params.rfoRequirements) {
      const licenseRiskRequirement = Object.values(params.rfoRequirements).filter(
        obj => obj?.messageId === Number(IPlacementRequirementKey.orderLicense) && obj?.data?.isRisk === true,
      );
      yield all(
        Object.values(licenseRiskRequirement)?.map((obj: any) => {
          const messageContent = GetRiskMessageMessageId(obj?.messageId, 'v1', 3, obj?.data);
          return put(
            placementStatusAction.setWarningRequirement({
              requirement: {
                id: IPlacementRequirementKey.createPlacement,
                message: messageContent.message,
                tooltip: messageContent.tooltip,
              },
            }),
          );
        }),
      );
      yield all(
        // eslint-disable-next-line array-callback-return
        Object.values(params.rfoRequirements).map((obj: any) => {
          if (obj !== null && !obj.isValidated) {
            const messageContent = GetMessageMessageId(obj?.messageId, obj?.messageVersion, obj?.type, obj?.data);
            if (messageContent) {
              return put(
                placementStatusAction.setFailedRequirementForStatus({
                  status: placementStatusOptions.requestFileOut,
                  requirement: {
                    id: IPlacementRequirementKey.createPlacement,
                    message: messageContent.message,
                    tooltip: messageContent.tooltip,
                  },
                }),
              );
            } else {
              const datax = obj?.message?.ternary ?? [];
              if (datax && obj?.message?.additionalInfo && obj?.message?.additionalInfo.length > 0) {
                datax.push(obj?.message?.additionalInfo);
              }
              return put(
                placementStatusAction.setFailedRequirementForStatus({
                  status: placementStatusOptions.requestFileOut,
                  requirement: {
                    id: IPlacementRequirementKey.createPlacement,
                    message: obj?.message?.primary,
                    tooltip: {
                      message: obj?.message?.secondary,
                      supportingList: datax,
                    },
                  },
                }),
              );
            }
          } else if (obj !== null && obj?.type === requirementType.risk) {
            const messageContent = GetRiskMessageMessageId(obj?.messageId, obj?.messageVersion, obj?.type, obj?.data);
            if (messageContent) {
              return put(
                placementStatusAction.setWarningRequirement({
                  requirement: {
                    id: IPlacementRequirementKey.createPlacement,
                    message: messageContent.message,
                    tooltip: messageContent.tooltip,
                  },
                }),
              );
            } else {
              const datax = obj?.message?.ternary ?? [];
              if (datax && obj?.message?.additionalInfo && obj?.message?.additionalInfo.length > 0) {
                datax.push(obj?.message?.additionalInfo);
              }
              return put(
                placementStatusAction.setWarningRequirement({
                  requirement: {
                    id: IPlacementRequirementKey.createPlacement,
                    message: obj.message.primary,
                    tooltip: {
                      message: obj.message.secondary,
                      supportingList: datax,
                    },
                  },
                }),
              );
            }
          }
        }),
      );
    }
    if (params.taRequirements) {
      const licenseRiskRequirement = Object.values(params.taRequirements).filter(
        obj => obj?.messageId === Number(IPlacementRequirementKey.orderLicense) && obj?.data?.isRisk === true,
      );
      yield all(
        Object.values(licenseRiskRequirement)?.map((obj: any) => {
          const messageContent = GetRiskMessageMessageId(obj?.messageId, 'v1', 3, obj?.data);
          return put(
            placementStatusAction.setWarningRequirement({
              requirement: {
                id: IPlacementRequirementKey.createPlacement,
                message: messageContent.message,
                tooltip: messageContent.tooltip,
              },
            }),
          );
        }),
      );
      yield all(
        // eslint-disable-next-line array-callback-return
        Object.values(params.taRequirements).map((obj: any) => {
          if (obj !== null && !obj.isValidated) {
            const messageContent = GetMessageMessageId(obj?.messageId, obj?.messageVersion, obj?.type, obj?.data);
            if (messageContent) {
              return put(
                placementStatusAction.setFailedRequirementForStatus({
                  status: placementStatusOptions.travelerAccepts,
                  requirement: {
                    id: IPlacementRequirementKey.createPlacement,
                    message: messageContent.message,
                    tooltip: messageContent.tooltip,
                  },
                }),
              );
            } else {
              const datax = obj?.message?.ternary ?? [];
              if (datax && obj?.message?.additionalInfo && obj?.message?.additionalInfo.length > 0) {
                datax.push(obj?.message?.additionalInfo);
              }
              return put(
                placementStatusAction.setFailedRequirementForStatus({
                  status: placementStatusOptions.travelerAccepts,
                  requirement: {
                    id: IPlacementRequirementKey.createPlacement,
                    message: obj?.message?.primary,
                    tooltip: {
                      message: obj?.message?.secondary,
                      supportingList: datax,
                    },
                  },
                }),
              );
            }
          } else if (obj !== null && obj?.type === requirementType.risk) {
            const messageContent = GetRiskMessageMessageId(obj?.messageId, obj?.messageVersion, obj?.type, obj?.data);
            if (messageContent) {
              return put(
                placementStatusAction.setWarningRequirement({
                  requirement: {
                    id: IPlacementRequirementKey.createPlacement,
                    message: messageContent.message,
                    tooltip: messageContent.tooltip,
                  },
                }),
              );
            } else {
              const datax = obj?.message?.ternary ?? [];
              if (datax && obj?.message?.additionalInfo && obj?.message?.additionalInfo.length > 0) {
                datax.push(obj?.message?.additionalInfo);
              }
              return put(
                placementStatusAction.setWarningRequirement({
                  requirement: {
                    id: IPlacementRequirementKey.createPlacement,
                    message: obj.message.primary,
                    tooltip: {
                      message: obj.message.secondary,
                      supportingList: datax,
                    },
                  },
                }),
              );
            }
          }
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'validationProessing',
        area: 'src/app/components/Placement/CreatePlacement/store/NewPlacement.saga.ts',
      },
    });
  }
}

export function* requestValidations(
  action: PayloadAction<{
    candidateId: number;
    brandId: number;
    orderId: number;
    facilityId: number;
    recruiterId?: number;
    availabilityDate?: string;
  }>,
) {
  const CancelTokenSource = axios.CancelToken.source();
  try {
    const placementService: PlacementService = yield getContext('placementService');

    const placementOrderType = yield select(selectCreatePlacementOrderType);
    const { currentPlacementStatus } = yield select(selectPlacementStatus);

    const response = yield call(
      TrackValidationWrapper,
      placementService.getNewPlacementValidations,
      {
        ...action.payload,
        isRFO: currentPlacementStatus !== DefaultCurrentPlacementStatus,
        placementOrderType: getPlacementOrderTypeValue(placementOrderType),
      },
      CancelTokenSource.token,
    );
    yield put(placementStatusAction.resetWarningRequirements());
    yield put(placementStatusAction.resetFailedRequirements());
    yield put(newPlacementActions.setValidation(null));
    if (httpSuccess(response.status)) {
      const { data } = response;
      if (!!data.rfoRequirements)
        yield put(
          newPlacementActions.setMiscellaneousDataByKey({
            key: 'isAuditValid',
            data: data.rfoRequirements.audit.isValidated,
          }),
        );
      yield call(validationProessing, {
        ...data,
        currentPlacementStatus: currentPlacementStatus,
      });
    } else {
      yield put(
        placementStatusAction.setFailedRequirementForStatus({
          status: placementStatusOptions.waitingForClearance,
          requirement: {
            id: IPlacementRequirementKey.networkError,
            message: 'Network Error',
            tooltip: {
              message: 'A network error has occurred.',
            },
          },
        }),
      );
      yield put(
        placementStatusAction.setFailedRequirementForStatus({
          status: placementStatusOptions.requestFileOut,
          requirement: {
            id: IPlacementRequirementKey.networkError,
            message: 'Network Error',
            tooltip: {
              message: 'A network error has occurred.',
            },
          },
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestValidations',
        area: 'src/app/components/Placement/CreatePlacement/store/NewPlacement.saga.ts',
      },
    });
  } finally {
    if (yield cancelled()) {
      CancelTokenSource.cancel();
    }
  }
}

export function* calculatePlacementListStatus(action: PayloadAction<{ status: IPlacementStatus | undefined }>) {
  try {
    const { currentPlacementStatus } = yield select(selectPlacementStatus);
    const placementOrderType = yield select(selectCreatePlacementOrderType);
    if (!currentPlacementStatus || isInitialStatus(currentPlacementStatus)) {
      yield put(placementStatusAction.setCurrentPlacementStatus(DefaultCurrentPlacementStatus));
      // DEFAULT
      yield put(
        placementStatusAction.setAvailablePlacementStatus([
          placementStatusOptions.waitingForClearance,
          placementOrderType === PlacementOrderType.StrikeOrderInHsg
            ? placementStatusOptions.travelerAccepts
            : placementStatusOptions.requestFileOut,
        ]),
      );
    } else if (isWFC(currentPlacementStatus)) {
      // WFC
      yield put(
        placementStatusAction.setAvailablePlacementStatus([
          placementOrderType === PlacementOrderType.StrikeOrderInHsg
            ? placementStatusOptions.travelerAccepts
            : placementStatusOptions.requestFileOut,
          placementStatusOptions.declinedByTraveler,
          // Declined By Facility is only included with Strike Orders
          ...(placementOrderType === PlacementOrderType.StrikeOrderInHsg
            ? [placementStatusOptions.declinedByFacility]
            : []),
          placementStatusOptions.requestCancelled,
        ]),
      );
    } else if (isRFO(currentPlacementStatus)) {
      // RFO
      yield put(
        placementStatusAction.setAvailablePlacementStatus([
          placementStatusOptions.declinedByTraveler,
          placementStatusOptions.requestCancelled,
        ]),
      );
    } else if (isTA(currentPlacementStatus)) {
      // TA
      // Won't reach this status in Create Placement unless it's a strike placement
      yield put(
        placementStatusAction.setAvailablePlacementStatus([
          placementStatusOptions.declinedByTraveler,
          placementStatusOptions.declinedByFacility,
          placementStatusOptions.requestCancelled,
        ]),
      );
    } else {
      yield put(placementStatusAction.setAvailablePlacementStatus([]));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'calculatePlacementListStatus',
        area: 'src/app/components/Placement/CreatePlacement/store/NewPlacement.saga.ts',
      },
    });
  }
}

export function* savePlacements(action: PayloadAction<ISavePlacement>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');

    const placementId = yield select(selectNewPlacementId) ?? 0;

    // Check timestamp for placement if it's already created
    manuallyIncrementPromiseCounter('new-placement-submit');
    const timestamp = yield select(selectNewPlacementTimestamp);
    if (
      !isNullOrUndefined(placementId) &&
      placementId !== 0 &&
      !(yield call(checkPlacementTimestamps, {
        payload: {
          source: IPlacementStatusSource.statusConfirmationDialog,
          placement: {
            id: placementId,
            timestamp: timestamp.placementTimestamp,
          },
          candidate: {
            id: 0,
            timestamp: timestamp.candidateTimestamp,
          },
          // placementStrikeDetails: action.payload.request,
          // dirtyFields: action.payload.dirtyFields ?? [],
          fields: ['placement'],
        },
        type: placementDetailsAction.validatePlacementTimestamp.type,
      }))
    ) {
      // Placement has been updated. Return from function before updating placement
      manuallyResetPromiseCounter('new-placement-submit');
      return;
    }

    const user = yield select(selectUser);
    const payload = {
      ...action.payload,
      placementId: placementId ?? 0,
      isRfo: isRFO(action.payload.status),
      isTA: isTA(action.payload.status),
      userName: `${user?.userInfo?.firstName} ${user?.userInfo?.lastName}`,
      placementOrderType: getPlacementOrderTypeValue(action.payload.placementOrderType),
      timestamp,
    };
    delete payload.status;

    yield put(newPlacementActions.setValidation(null));
    const response = yield call(TrackSubmitWrapper, placementService.saveWFCPlacements, payload);
    if (response && httpSuccess(response.status) && !isNaN(response.data.placementId)) {
      yield call(validationProessing, {
        ...response.data,
        currentPlacementStatus: action.payload.status,
      });

      if (action.payload.status) yield put(placementStatusAction.setCurrentPlacementStatus(action.payload.status));
      yield put(newPlacementActions.setPlacementCreated(response.data.placementId));
      yield put(
        placementStatusAction.setUpdatePlacementStatusResponse({
          success: true,
          props: { placementCreated: !placementId || placementId === -1 },
          source: IPlacementStatusSource.statusConfirmationDialog,
          successType: IPlacementStatusSuccessType.placementUpdate,
        }),
      );

      // Get latest timestamp for placement after saving, if it's already created
      if (Number.isInteger(response.data.placementId) && response.data.placementId > 0) {
        const timestampResponse: string | undefined = yield call(getPlacementTimestamp, {
          payload: { placementId: response.data.placementId },
          type: placementDetailsAction.getPlacementTimestamp.type,
        });
        yield put(newPlacementActions.setTimestamp(timestampResponse));
      }
    } else if (response && response?.status === 409) {
      setSessionValue(
        SessionKey.createPlacementMFS,
        {
          orderId: payload.orderId,
          brandId: payload.brandId,
          candidateId: payload.candidateId,
        },
        StorageType.sessionStorage,
      );
      yield put(
        placementStatusAction.setUpdatePlacementStatusResponse({
          success: false,
          props: { placementCreated: false },
          source: IPlacementStatusSource.statusConfirmationDialog,
          successType: IPlacementStatusSuccessType.placementConflict,
        }),
      );
      yield put(newPlacementActions.setPlacementCreated(-1));
    } else {
      yield put(
        placementStatusAction.setUpdatePlacementStatusResponse({
          success: false,
          props: { placementCreated: false },
          source: IPlacementStatusSource.statusConfirmationDialog,
          successType: IPlacementStatusSuccessType.placementUpdate,
        }),
      );
      yield put(newPlacementActions.setPlacementCreated(-1));
    }
    manuallyResetPromiseCounter('new-placement-submit');
  } catch (error) {
    yield put(newPlacementActions.setPlacementCreated(-1));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'savePlacements',
        area: 'src/app/components/Placement/CreatePlacement/store/NewPlacement.saga.ts',
      },
    });
    manuallyResetPromiseCounter('new-placement-submit');
  }
}

export function* declinePlacement(
  action: PayloadAction<{
    placementId: number;
    placementStatus: IPlacementStatus;
    placementOrderType: PlacementOrderType;
    candidateId: number;
    placementUpdate?: {
      updates: IUpdateCreatePlacement | null;
    };
  }>,
) {
  try {
    const placementService: PlacementService = yield getContext('placementService');

    const user = yield select(selectUser);
    // reset success while new request is being made
    yield put(
      placementStatusAction.setUpdatePlacementStatusResponse({
        success: undefined,
        source: IPlacementStatusSource.statusConfirmationDialog,
      }),
    );

    // Check if the placement has been saved by another user first
    let timestamp = yield select(selectNewPlacementTimestamp);
    if (
      !(yield call(checkPlacementTimestamps, {
        payload: {
          source: IPlacementStatusSource.statusConfirmationDialog,
          placement: {
            id: action.payload.placementId,
            timestamp: timestamp.placementTimestamp,
          },
          candidate: {
            id: 0,
            timestamp: timestamp.candidateTimestamp,
          },
          fields: ['placement'],
        },
        type: placementDetailsAction.validatePlacementTimestamp.type,
      }))
    ) {
      // Placement has been updated. Return from function before updating placement
      return;
    }

    if (action.payload.placementUpdate?.updates) {
      const request = {
        ...action.payload.placementUpdate?.updates,
        placementTimestamp: timestamp.placementTimestamp,
      };
      const updateResponse = yield call(TrackSubmitWrapper, placementService.updatePlacementDetails, request);
      if (!httpSuccess(updateResponse?.status)) {
        // placement update failed. Set error and return
        yield put(
          placementStatusAction.setUpdatePlacementStatusResponse({
            success: false,
            source: IPlacementStatusSource.statusConfirmationDialog,
            successType: IPlacementStatusSuccessType.placementUpdate,
          }),
        );
        return;
      }
    }

    const timestampResponse: IPlacementTimestampResponse = yield call(getPlacementTimestamp, {
      payload: { placementId: action.payload.placementId },
      type: placementDetailsAction.getPlacementTimestamp.type,
    });
    if (timestampResponse) {
      timestamp = timestampResponse;
      yield put(
        placementDetailsAction.setTimestamps({
          candidateTimestamp: timestampResponse.candidateTimestamp,
          placementTimestamp: timestampResponse.placementTimestamp,
          travelerOptionTimestamp: timestampResponse.travelerOptionTimestamp,
        }),
      );
    }

    // Call API to update placement status
    const response = yield call(TrackSubmitWrapper, placementService.updateStatus, {
      placementId: action.payload.placementId,
      activityTypeId: action.payload.placementStatus.activityTypeId,
      activityStatusId: action.payload.placementStatus.activityStatusId,
      userId: user.userInfo.employeeId,
      placementOrderType: getPlacementOrderTypeValue(action.payload.placementOrderType),
      candidateId: action.payload.candidateId,
      timestamp: timestamp.placementTimestamp,
    });

    if (httpSuccess(response.status)) {
      yield put(placementStatusAction.setCurrentPlacementStatus(action.payload.placementStatus));

      //update Assignment details
      yield call(requestAssignmentInformationRequirement, {
        payload: { placementId: action.payload.placementId },
        type: placementSummaryActions.getAssignmentInformationRequirement.type,
      });

      // get new available statuses for status drop down
      yield put(placementStatusAction.setAvailablePlacementStatus([]));
      yield put(
        placementStatusAction.setUpdatePlacementStatusResponse({
          success: true,
          supportAPIFailure: undefined,

          source: IPlacementStatusSource.statusConfirmationDialog,
          successType: IPlacementStatusSuccessType.placementUpdate,
        }),
      );
    } else {
      yield put(
        placementStatusAction.setUpdatePlacementStatusResponse({
          success: false,
          source: IPlacementStatusSource.statusConfirmationDialog,
          successType: IPlacementStatusSuccessType.placementUpdate,
        }),
      );
    }
  } catch (error) {
    yield put(
      placementStatusAction.setUpdatePlacementStatusResponse({
        success: false,
        source: IPlacementStatusSource.statusConfirmationDialog,
        successType: IPlacementStatusSuccessType.placementUpdate,
      }),
    );
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'declinePlacement',
        area: 'src/app/components/Placement/CreatePlacement/store/NewPlacement.saga.ts',
      },
    });
  }
}

export function* getPlacementChangeId(
  action: PayloadAction<{
    copyPlacementOption: string;
    startDate: string;
    endDate: string;
    oldPlacementID: number;
    copyPlacementFacilityID: string;
    copyPlacementOrderID: string;
    currentEmployeeId: number;
  }>,
) {
  try {
    yield put(newPlacementActions.setErrorMessage(null));
    yield put(newPlacementActions.setPlacementCreated(null));
    const placementService: PlacementService = yield getContext('placementService');
    const payload = {
      copyPlacementOption:
        action.payload.copyPlacementOption === 'Pay Cycle'
          ? 'PAYCYCLECHANGE'
          : action.payload.copyPlacementOption === 'Pre-Start'
          ? 'PRESTART'
          : 'FIDOIDCHANGE',
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
      oldPlacementID: action.payload.oldPlacementID,
      copyPlacementFacilityID: action.payload.copyPlacementFacilityID,
      copyPlacementOrderID: action.payload.copyPlacementOrderID,
      currentEmployeeId: action.payload.currentEmployeeId,
    };
    const response = yield call(TrackPlacementChangeWrapper, placementService.getPlacemenChangeId, payload);
    if (
      response &&
      httpSuccess(response.status) &&
      response.data.responseCode === 0 &&
      !isNaN(response.data.placementId)
    ) {
      yield put(newPlacementActions.setPlacementCreated(response.data.placementId));
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('placement.placementChange.PlacementChangeSuccessMessage', {
            copyPlacementOption: action.payload.copyPlacementOption,
          }),
          severity: 'success',
        }),
      );
    } else if (response && response?.data?.errorId) {
      yield put(newPlacementActions.setErrorMessage(response?.data?.errorMessage));
    }
  } catch (error) {
    yield put(newPlacementActions.setPlacementCreated(null));
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('placement.placementChange.PlacementChangeErrorMessage'),
        severity: 'error',
      }),
    );
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getPlacementChangeId',
        area: 'src/app/components/Placement/CreatePlacement/store/NewPlacement.saga.ts',
      },
    });
  }
}

export function* requestCueNotesEmployee(action: PayloadAction<IRequestCueNotePayload>) {
  try {
    const requestPayload = action.payload;
    const placementService: PlacementService = yield getContext('placementService');
    const response = yield call(placementService.getCueNotesEmployee, requestPayload);
    if (httpSuccess(response.status)) {
      yield put(newPlacementActions.setCueNotesEmployee(formatCueNoteEmployeeList(response?.data)));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestCueNotesEmployee',
        area: 'src/app/components/Placement/CreatePlacement/store/NewPlacement.saga.ts',
      },
    });
  }
}

export function* newPlacementSaga() {
  yield takeLatest(newPlacementActions.onInitAction.type, onInit);
  yield takeLatest(newPlacementActions.getCandidateAction.type, requestCandidateDetails);
  yield takeLatest(newPlacementActions.getOrderAction.type, requestOrderDetails);
  yield takeLatest(newPlacementActions.getValidationsAction.type, requestValidations);
  yield takeLatest(newPlacementActions.calculatePlacementStatus.type, calculatePlacementListStatus);
  yield takeLatest(newPlacementActions.savePlacementAction.type, savePlacements);
  yield takeLatest(newPlacementActions.declinePlacement.type, declinePlacement);
  yield takeLatest(newPlacementActions.requestScreenOptions.type, requestScreenOptions);
  yield takeLatest(newPlacementActions.getPlacementChangeId.type, getPlacementChangeId);
  yield takeLatest(newPlacementActions.getCueNotesEmployee.type, requestCueNotesEmployee);
}
