import { CustomTooltip } from '@AMIEWEB/Common';
import { StatusDropdown, VERSION } from '@AMIEWEB/Common/StatusDropDown/StatusDropDown';
import { Grid } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentStatus,
  selectSelectedGridRow,
  selectSelectedUserRoles,
} from 'store/redux-store/placement-requests/selectors';
import { placementRequestsAction } from 'store/redux-store/placement-requests/slice';
import { makeStyles } from 'tss-react/mui';
import { Loader } from '../../common/Loader';
import { IAutoTaskTaskStatus } from 'app/models/AutoTaskManagement/AutoTaskManagement';
const useStyles = makeStyles()(theme => ({
  containerStyle: {
    flexDirection: 'column',
  },
  headerStyle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  contentStyle: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  headingStyle: {
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: '12px',
  },
  subHeadingStyle: {
    fontSize: '14px',
    fontWeight: 500,
    width: '100px',
  },
  toolTip: {
    maxWidth: '250px',
    marginTop:'10px !important',
    boxShadow: `0px -3px 6px ${theme.palette.system.darkBlackBlue}`
  },
}));

export const AutoTaskDetails = ({ isDisabled = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const selectedGridRow = useSelector(selectSelectedGridRow);
  const currentStatus:IAutoTaskTaskStatus = useSelector(selectCurrentStatus);
  const selectedUserRoles = useSelector(selectSelectedUserRoles);
  const { promiseInProgress: gridDataLoader } = usePromiseTracker({ area: 'fetch-auto-task-grid-data', delay: 0 });
  const { promiseInProgress: autoTaskStatusLookUpLoader } = usePromiseTracker({ area: 'fetch-auto-task-status-lookup', delay: 0 });
  const { promiseInProgress: autoTaskEmployeeRolesLoader } = usePromiseTracker({ area: 'fetch-auto-task-employee-roles', delay: 0 });

  const handleStatus = status => {
    dispatch(placementRequestsAction.business_handleAutoTaskStatusChange(status));
  };

  const getToolTipContent = () => {
    if (!selectedUserRoles.length) return t(`autoTaskManagement.roleDetails.statusTooltip`);
    else return ``;
  };
  return (
    <Grid container classes={{ root: classes.containerStyle }}>
      <Grid item classes={{ root: classes.headerStyle }}>
        <Grid item classes={{ root: classes.headingStyle }}>
          {gridDataLoader || autoTaskEmployeeRolesLoader ?  <Loader size={18} />  : selectedGridRow?.description}
        </Grid>
        <Grid item>
          {gridDataLoader || autoTaskStatusLookUpLoader || autoTaskEmployeeRolesLoader? (
            <Loader size={18} />
          ) : (
            <CustomTooltip
              classes={{ tooltip: classes.toolTip }}
              placement={'bottom'}
              tooltipContent={ !isDisabled ? getToolTipContent() : ""}
              popperProps={{
                popperOptions: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 10]
                      },
                    },
                  ],
                },
              }}
            >
              <StatusDropdown
                handleStatus={handleStatus}
                version={VERSION.TWO}
                isNewStatusIndicator={false}
                defaultValue={currentStatus?.status }
                width="150px"
                isDisabled={!selectedUserRoles.length || isDisabled}
                isReadOnly={!selectedUserRoles.length || isDisabled}
              />
            </CustomTooltip>
          )}
        </Grid>
      </Grid>
      <Grid item classes={{ root: classes.contentStyle }}>
        <Grid item classes={{ root: classes.subHeadingStyle }}>
          {t(`autoTaskManagement.roleDetails.autoTaskId`)}
        </Grid>
        <Grid item>{gridDataLoader  || autoTaskEmployeeRolesLoader ? <Loader size={18} /> : selectedGridRow?.autoTaskCategoryId}</Grid>
      </Grid>
      <Grid item classes={{ root: classes.contentStyle }}>
        {' '}
        <Grid item classes={{ root: classes.subHeadingStyle }}>
          {t(`autoTaskManagement.roleDetails.lastUpdated`)}
        </Grid>
        <Grid item>
          {gridDataLoader || autoTaskEmployeeRolesLoader ? (
            <Loader size={18} />
          ) : (
            `${selectedGridRow?.lastUpdateDate} by ${selectedGridRow?.lastUpdatedBy}`
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
