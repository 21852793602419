import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { Divider, Typography } from 'amn-ui-core';
import { AccordianComponent } from '@AMIEWEB/NewOrder/CreateOrder/Common/AccordianComponent';
import { KeyInternalContacts } from './KeyInternalContacts';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { ContactList } from '@AMIEWEB/Common/ContactsDrawer/ContactList';
import { EmailDefaultValues } from 'app/models/Email/IEmail';
import _, { isEqual } from 'lodash';
import { searchNotificationData } from '@AMIEWEB/GlobalSearch/helper';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { IEmployee, IToken } from 'app/models/Notification/Notification';
import { IEmailData, SendType, UseSubType, UseType } from '@AMIEWEB/Notification/Constants';
import { Concatenate } from 'utils/string/string';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { selectPlacementOrderUnit } from 'store/redux-store/placement-summary/selectors';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { useHeaderOptions } from '@AMIEWEB/Common/Buttons/UseHeaderOptions';
import {
  selectContactsExpanded,
  selectFocusedHeight,
  selectKeyInternalContactsEditor,
  selectPlacementContactsData,
} from 'store/redux-store/placement-details/selectors';
import { placementDetailsAction } from 'store/redux-store/placement-details/slice';
import { KeyContactType } from './utils';
import { getDefaultSender } from '@AMIEWEB/Candidate/CandidateProfile/CandidateHeader/NotificationHelper';

const useStyles = makeStyles()(theme => ({
  root: {
    padding: 12,
  },
  selectedContact: {
    fontSize: '14px',
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    cursor: 'pointer',
    paddingBottom: '12px',
  },
  bulkEMail: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: theme.palette.system.navyBlue,
    },
  },
  emailText: {
    marginLeft: theme.spacing(0.5),
  },
  contactContainer: {
    width: '100%',
    height: 'calc(63vh + 5px)',
    overflowY: 'auto',
    paddingTop: '0px !important',
  },
  selectedText: {
    fontSize: 14,
  },
  mailIcon: {
    width: '20px',
    height: '20px',
  },
  selectedContainer: {
    width: '100%',
  },
  disabled: {
    color: theme.palette.system.doveGray,
    pointerEvents: 'none',
  },
}));

export const PlacementContactsTab = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getEmailSubject } = useHeaderOptions();
  const { userInfo } = useSelector(selectUser);
  const notificationData = useSelector(notificationSelection);
  const { flattenedContacts: contacts, facility } = useSelector(selectPlacementContactsData);
  const isContactsExpanded = useSelector(selectContactsExpanded);
  const unitId = useSelector(selectPlacementOrderUnit);
  const editorInternalContacts = useSelector(selectKeyInternalContactsEditor);
  const {
    flattenedContacts: { keyInternalPartners },
  } = useSelector(selectPlacementContactsData);
  const focusedHeight = useSelector(selectFocusedHeight);

  const areAllClientContactsChecked = () => {
    const validContacts = contacts?.facilityContacts?.filter(contact => contact?.email && contact?.id);
    return validContacts?.length > 0 && validContacts?.filter(contact => !contact?.checked)?.length === 0;
  };

  const setScrollPosition = () => {
    const element = document.querySelector('.scroll-class');
    if (element) {
      dispatch(placementDetailsAction.setFocusedHeight(element.scrollTop));
    }
  };

  const clientContactClick = (id: number) => {
    setScrollPosition();
    dispatch(placementDetailsAction.setClientContactsCheckbox(id));
  };

  const clientContactSelectAll = () => {
    setScrollPosition();
    dispatch(placementDetailsAction.selectAllClientContacts(!areAllClientContactsChecked()));
  };

  const getSelectedInternalContacts = () => {
    let filteredObj = [];
    Object.entries(editorInternalContacts)?.map(([_, value]) => value?.checked && filteredObj.push(value?.checked));
    return filteredObj;
  };

  const getSelectedClientContacts = () => {
    return contacts?.facilityContacts?.filter(contact => !!contact?.checked);
  };

  const internalContactsLength = useMemo(() => getSelectedInternalContacts().length, [editorInternalContacts]);

  const clientContactsLength = useMemo(() => getSelectedClientContacts()?.length, [contacts?.facilityContacts]);

  const selectedContactsLength = internalContactsLength + clientContactsLength;

  const handleCreateBulkEmail = () => {
    const notificationUser = searchNotificationData({ userInfo });
    const clientContacts = getSelectedClientContacts()?.map(contact => ({
      contactId: contact.id,
      email: contact.email,
      phoneNumber: contact.phoneNumber,
      name: contact.title,
      designation: contact.title,
      isInternalRecipient: false,
      clientContactDetails: {
        statusId: contact?.statusId?.toString(),
        unitIds: [unitId?.toString()],
      },
    }));

    const internalContacts = Object.entries(editorInternalContacts)
      ?.map(
        ([groupKey, items]) =>
          items?.checked && {
            contactId: keyInternalPartners[groupKey]?.id,
            email: keyInternalPartners[groupKey]?.email,
            phoneNumber: keyInternalPartners[groupKey]?.phoneNumber,
            name: keyInternalPartners[groupKey]?.title,
            designation: keyInternalPartners[groupKey]?.designation,
            isInternalRecipient: true,
          },
      )
      .filter(value => !_.isEmpty(value));

    const associatedRecords =
      clientContacts?.length > 0
        ? [
            {
              name: 'facilityId',
              value: facility?.id?.toString(),
            },
          ]
        : [];
    const sender: IEmployee = getDefaultSender({ userInfo })
    const tos = [...clientContacts, ...internalContacts];
    const currentUrl = window.location.href?.split('?')[0];
    const body =
      clientContacts?.length > 0
        ? `<p><br><br><br>{{signature}}</p>`
        : `<p>&nbsp;</p><p>&nbsp;</p><p>Link To Profile : <a href=${currentUrl}>${currentUrl}</a><br><br>{{signature}}</p>`;
    const substitutions: Array<IToken> = [
      {
        name: 'Signature',
        input: '{{signature}}',
      },
    ];
    const emailData: IEmailData = {
      substitutions: substitutions,
      useType: clientContacts?.length > 0 ? UseType.ClientContacts : UseType.Bulk,
      useSubType: UseSubType.KeyContactBulkEmail,
      emailType: SendType.one_to_one,
      body: body,
      associatedRecords: associatedRecords,
      subject: clientContacts?.length > 0 ? '' : getEmailSubject(),
      tos: tos,
      sender: sender,
      userRole: Concatenate(userInfo?.roles || [], ','),
      isInternal: clientContacts?.length === 0,
    };
    if (tos?.length > 0) {
      if (!notificationData.email.open && notificationData.email.minimized) {
        dispatch(
          notificationDataActions.setEmailInteraction({
            open: true,
            minimized: false,
          }),
        );
        if (!isEqual(notificationData.email.data!.tos, [])) {
          dispatch(notificationDataActions.setSnackBarData({ channel: 'email', manual: false, changeWarning: true }));
        }
      } else {
        dispatch(
          notificationDataActions.setEmailData({
            data: {
              ...EmailDefaultValues(),
              ...notificationUser,
              ...emailData,
            },
            open: true,
            minimized: false,
          }),
        );
      }
    }
  };

  useEffect(() => {
    if (focusedHeight > 0) {
      const element = document.querySelector('.scroll-class');
      if (element) {
        element.scrollTop = focusedHeight;
      }
    }
  }, [focusedHeight]);

  const onExpand = (type: 'isInternal' | 'isClient') => {
    dispatch(placementDetailsAction.setContactsExpanded({ type, value: !isContactsExpanded[type] }));
  };

  return (
    <LayoutGrid className={classes.root}>
      <LayoutGridItem className={classes.selectedContainer}>
        <div
          className={`${classes.selectedContact} ${selectedContactsLength > 0 ? '' : classes.disabled}`}
          onClick={handleCreateBulkEmail}
        >
          <Typography className={classes.selectedText}>{`${t('placement.placementDrawerCotactTab.selectedContacts')} ${
            selectedContactsLength > 0 ? '(' + selectedContactsLength + ')' : ''
          }`}</Typography>
          <div className={classes.bulkEMail}>
            <MailOutlineIcon className={classes.mailIcon} />
            <Typography className={classes.emailText}>{t('placement.placementDrawerCotactTab.bulkEmail')}</Typography>
          </div>
        </div>
        <Divider />
      </LayoutGridItem>
      <LayoutGridItem className={`${classes.contactContainer} scroll-class`}>
        <AccordianComponent
          title={`${t('placement.placementDrawerCotactTab.internalContacts')} ${
            internalContactsLength > 0 ? '(' + internalContactsLength + ')' : ''
          }`}
          isTransparent
          isExpanded={isContactsExpanded?.isInternal ?? true}
          setIsExpanded={() => onExpand(KeyContactType.Internal)}
          children={
            <KeyInternalContacts
              disableEmailVoiceIcon={clientContactsLength > 0 || internalContactsLength > 0}
              setScrollPosition={setScrollPosition}
            />
          }
        ></AccordianComponent>
        <Divider />
        <AccordianComponent
          title={`${t('placement.placementDrawerCotactTab.clientContacts')} ${
            clientContactsLength > 0 ? '(' + clientContactsLength + ')' : ''
          }`}
          isTransparent
          isExpanded={isContactsExpanded?.isClient ?? true}
          setIsExpanded={() => onExpand(KeyContactType.Client)}
          children={
            !!contacts ? (
              <ContactList
                enableCommunication
                clientContact
                normalOrder
                showCheckbox
                handleClick={clientContactClick}
                handleSelectAll={clientContactSelectAll}
                areAllClientContactsChecked={areAllClientContactsChecked}
                disableIndividualIcons={internalContactsLength > 0 || clientContactsLength > 0}
              />
            ) : (
              <></>
            )
          }
        ></AccordianComponent>
      </LayoutGridItem>
    </LayoutGrid>
  );
};
