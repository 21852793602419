import React, { useEffect, useState } from 'react';
import { usePageVisitTimeTracker } from 'app-insights/appInsightsTracking';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { educationActiveSelection } from '../../Profile/CandidateDetails.selector';
import { useDispatch, useSelector } from 'react-redux';
import { EducationCard, PersonalInfoCard, EmploymentHistoryCard } from './Cards';
import RequestTimeOffCard from './Cards/RequestTimeOff/RequestTimeOffCard';
import { WorkExperienceGridLoadable } from '../SummaryTab/WorkExperience/WorkExperienceLoadable';
import { EducationWrapperLoadable } from './Education/EducationWrapperLoadable';
import {
  ff_candidate_ui_manage_SupervisorQualifications,
  ff_candidate_ui_manage_time_off,
  ff_WorkExperience_Enhancement,
} from 'app/constants/FeatureFlags/Candidate/Keys';
import { useDecision } from '@optimizely/react-sdk';
import SupervisorCard from './Cards/Supervisor/SupervisorCard';
import PreferencesCard from './Cards/Preferences/PreferencesCard';
import { candidateProfileDetailsActions } from 'store/redux-store/candidate-details/slice';
import { useParams } from 'react-router-dom';

const ProfessionalProfile = props => {
  return (
    <>
      <LayoutGrid spacing={2} direction="row" data-testid="profile-container">
        <LayoutGridItem xs={3}>
          <PersonalInfoCard />
        </LayoutGridItem>

        <LayoutGridItem xs={5}>
          <LayoutGrid container style={{ flexWrap: 'nowrap' }} spacing={2} direction="column">
            {/* Commenting out this as per the US 209155
              <LayoutGridItem>
                <LicensureCard data-testid="education-container" />
              </LayoutGridItem>
              <LayoutGridItem>
                <ReferencesCard />
              </LayoutGridItem>
              <LayoutGridItem>
                <VirtualInterviewCard />
              </LayoutGridItem> */}
            {props.showSupervisorCard.enabled && (
              <LayoutGridItem>
                <SupervisorCard />
              </LayoutGridItem>
            )}

            {props.showTimeOff.enabled && (
              <LayoutGridItem>
                <RequestTimeOffCard />
              </LayoutGridItem>
            )}
            <LayoutGridItem>
              <EducationCard {...props} />
            </LayoutGridItem>
          </LayoutGrid>
        </LayoutGridItem>

        <LayoutGridItem xs={4}>
          <LayoutGrid container style={{ flexWrap: 'nowrap' }} spacing={2} direction="column">
            <LayoutGridItem>
              <EmploymentHistoryCard {...props} />
            </LayoutGridItem>
            <LayoutGridItem>
              <PreferencesCard />
            </LayoutGridItem>
          </LayoutGrid>
        </LayoutGridItem>
      </LayoutGrid>
    </>
  );
};

export const ProfileTab = props => {
  const { toggleEducation } = props;
  const dispatch = useDispatch();
  usePageVisitTimeTracker('Candidate Details:Professional Profile Tab');
  const [toggle, setToggle] = useState(true);
  const [editWorkHistory, setEditWorkHistory] = useState(toggleEducation ?? false);
  const isEduActive = useSelector(educationActiveSelection);
  const [loading, setLoading] = useState<boolean>(false);
  const [showTimeOff] = useDecision(ff_candidate_ui_manage_time_off);
  const [showSupervisorCard] = useDecision(ff_candidate_ui_manage_SupervisorQualifications);
  const [hasWorkExperienceTab] = useDecision(ff_WorkExperience_Enhancement);
  const params = useParams<{
    travelerId: string;
    brandId: string;
  }>();

  useEffect(() => {
    dispatch(
      candidateProfileDetailsActions.getProfessionalProfileTabDetails({
        candidateId: parseInt(params.travelerId),
        brandId: parseInt(params.brandId),
      }),
    );
  }, []);

  useEffect(() => {
    if (isEduActive) {
      setToggle(!toggle);
      window.history.pushState(null, '', `${window.location.pathname}?tab=1`);
      //dispatch(candidateDetailActions.setEducationActive(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEduActive]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleToggle = () => {
    setToggle(!toggle);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleEdit = () => {
    if (hasWorkExperienceTab?.enabled) {
      const optedTab = 1;
      window.history.pushState(null, '', `${window.location.pathname}?tab=${optedTab}`);
      props.navigate(optedTab);
    } else {
      setEditWorkHistory(!editWorkHistory);
    }
  };

  return (
    <>
      {toggle ? (
        editWorkHistory ? (
          <WorkExperienceGridLoadable rows={[]} handleBack={handleEdit} />
        ) : (
          <ProfessionalProfile
            handleToggle={handleToggle}
            editWorkHistory={handleEdit}
            showTimeOff={showTimeOff}
            showSupervisorCard={showSupervisorCard}
          />
        )
      ) : (
        <EducationWrapperLoadable handleToggle={handleToggle} loading={loading} />
      )}
    </>
  );
};
