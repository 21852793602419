/* eslint-disable tss-unused-classes/unused-classes */
/* eslint-disable react-hooks/exhaustive-deps */
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useEffect, useState } from 'react';
import { Checkbox, TextField, Grid, CircularProgress, ClickAwayListener } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { Row, RowContent, RowHead } from '../../ProfileTab/Cards/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MaskedInput from 'react-text-mask';
import { useSelector } from 'react-redux';
import { candidateDetailsSelection } from '../../../Profile/CandidateDetails.selector';
import { withReadOnly } from 'oidc/withReadOnly';
import { useReadOnly } from 'oidc/userRoles';
import ErrorOutline from 'app/assets/images/CandidateProfile/error_outline-24px.svg';
import { RowContainer } from '@AMIEWEB/Candidate/Common/RowContainer';
import { CustomTooltip } from '@AMIEWEB/Common';

const useStyles = makeStyles()(theme => ({
  autocomplete: {
    transition: 'opacity 0.25s',
    '&:hover': {
      '& [data-id="edit-icon"]': {
        opacity: 1,
      },
      transition: 'opacity 0.25s',
    },
  },
  inlineEditMode: {
    cursor: 'default',
    '&.editEnabled': {
      'border-bottom': '1px solid #000',
    },
    '& .MuiAutocomplete-input': {
      cursor: 'default',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: '0px',
    },
    '& .MuiOutlinedInput-root': {
      height: '28px',
      width: '139px',
    },
    '& .MuiAutocomplete-input:first-child': {
      width: '70%',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#1a1919',
      backgroundColor: '#fff !important',
    },
  },
  editItem: {
    opacity: 0.75,
    transition: 'opacity 0.15s',
    color: '#8d8686',
    cursor: 'pointer',
    position: 'relative',
    top: '1px',
    '&.enabled': {
      opacity: 1,
    },
    '& svg ': {
      height: '17px',
      width: '17px',
    },
  },
  textareastyle: {
    backgroundColor: '#fff !important',
    border: 'thin #ececec solid',
    'border-top': '0',
    'border-left': '0',
    'border-right': '0',
    'border-bottom-color': '#bababa',
    '&:focus-visible': {
      outline: 'none',
      background: '#f2f2f2',
    },
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
      'border-color': '#006FB9',
      borderWidth: '2px',
    },
  },
  root: {
    height: '20px',
    width: '110px',
    overflow: 'hidden',
    fontSize: 14,
    paddingRight: '0px !important',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      '&$popupIndicator': {
        opacity: 1,
        transition: 'opacity 0.25s',
      },
    },
    '&$focused': {
      backgroundColor: '#fff',
    },
  },
  normalText: {
    'font-weight': 'normal',
  },
  inlineBoxEditItemPadding: {
    padding: '0 0.4rem',
  },
  inlineBoxEditMainCheckItem: {
    'padding-left': '0',
    'padding-right': '0',
    '& svg': {
      width: '25px',
      height: '25px',
    },
  },
  inlineBoxEditMainLblItem: {
    'padding-right': '0.1rem',
  },
  inlineBoxEditMainAcceptItem: {
    'padding-right': '0.2rem',
    '& svg': {
      color: '#006FB9',
      width: '20px',
      height: '20px',
      cursor: 'pointer',
    },
  },
  disabledItem: {
    '& svg': {
      color: '#8f9a8e',
      width: '20px',
      height: '20px !important',
      cursor: 'default',
    },
  },
  inlineBoxEditMainRejectItem: {
    'padding-left': '0.2rem',
    '& svg': {
      color: '#8f9a8e',
      width: '20px',
      height: '20px !important',
      cursor: 'pointer',
    },
  },
  inlineBoxEditInputItem: {
    'border-top': '0',
    'border-left': '0',
    'border-right': '0',
    'border-bottom-color': '#bababa',
    '&:focus-visible': {
      outline: 'none',
      background: '#f2f2f2',
    },
  },
  saveSuccessText: {
    fontSize: '11px',
    paddingLeft: '4px',
    justifyContent: 'center',
    color: '#008000',
  },
  saveFailedText: {
    fontSize: '11px',
    paddingLeft: '4px',
    justifyContent: 'center',
    color: '#ff0000',
  },
}));

export const EditText = props => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const [isHandleClick, setIsHandleClick] = useState<boolean>(false);
  const [ssn, setSsn] = useState<string>('');
  const [ssnClone, setSsnClone] = useState<string>('');
  const [showFullSsn, setShowFullSsn] = useState<boolean>(false);
  const [showSubmitIcon, setShowSubmitIcon] = useState<boolean>(false);
  const partiallyHidden = '*** ** ';
  const completelyHidden = '*** ** ****';
  const candidateDetails = useSelector(candidateDetailsSelection);
  const { readOnly } = useReadOnly();

  useEffect(() => {
    if (props.isViewable) {
      setSsn(props.data ? `${partiallyHidden}${props.data.toString().slice(-4)}` : '');
      setSsnClone(props.data);
    } else {
      setSsn(props.data ? completelyHidden : '');
    }
  }, [props.isViewable]);

  useEffect(() => {
    if (props.saveStatus == '1') {
      setSsn(`${partiallyHidden}${props.data.toString().slice(-4)}`);
    }
    if (props.saveStatus == '2') {
      setSsn(props.data);
      setSsnClone(props.data);
    }
  }, [props.saveStatus]);

  const enableInlineEditMode = () => {
    if (props.isViewable) {
      setShowFullSsn(true);
      if (props.data == '' && ssnClone != '' && isHandleClick != true) {
        setSsn(ssnClone);
      } else {
        if (props.data !== formatSsn(candidateDetails.socialSecurityNumber)) {
          setSsn(formatSsn(candidateDetails.socialSecurityNumber));
        } else {
          setSsn(props.data);
        }
      }
    }
    if (props.isEditable) {
      setIsHandleClick(true);
    }
  };
  const formatSsn = (ssn: any) => {
    const formattedSsn = ssn?.replace(/-/g, '');
    return formattedSsn || '';
  };

  useEffect(() => {
    if (props.isEditable && showFullSsn) {
      setSsn(props.data);
    }

    const formattedData = props.data?.replace(/\D/g, '');

    if (formattedData?.length === 9) {
      setShowSubmitIcon(true);
    } else {
      setShowSubmitIcon(false);
    }
  }, [props.data]);

  const checkValidation = value => {
    if (value.length < 12) {
      props.handleChangeValue('ssn', value);
    }
  };
  const handlesave = () => {
    setSsn(`${partiallyHidden}${ssn.toString().slice(-4)}`);
    props.handleSave('ssn');
    setShowFullSsn(false);
    setIsHandleClick(false);
  };
  const close = () => {
    setIsHandleClick(false);
    if (props.isViewable) {
      setSsn(ssnClone ? `${partiallyHidden}${ssnClone.toString().slice(-4)}` : '');
      setShowFullSsn(false);
      props.resetSsn();
    }
  };
  function TextMaskCustom(props) {
    const { ...other } = props;
    return (
      <MaskedInput
        {...other}
        // ref={ref => {
        //   inputRef(ref ? ref.inputElement : null);
        // }}
        mask={[/\d*/, /\d*/, /\d*/, ' ', /\d*/, /\d*/, ' ', /\d*/, /\d*/, /\d*/, /\d*/]}
      />
    );
  }

  const NumberMaskText = React.useMemo(() => {
    return (
      <>
        <TextField
          variant="filled"
          InputProps={{
            className: classes.textareastyle,
            disableUnderline: true,
            classes: classes,
            readOnly: !props.isEditable,
            inputComponent: TextMaskCustom,
          }}
          className={classes.inlineBoxEditInputItem}
          InputLabelProps={{
            className: ``,
          }}
          value={ssn}
          hiddenLabel
          onClick={() => enableInlineEditMode()}
          onChange={event => {
            checkValidation(event.target.value);
          }}
          error={props.data === ''}
          type={'string'}
          {...props}
        />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHandleClick, props.isEditable, ssnClone, partiallyHidden, completelyHidden, props.isViewable, showFullSsn]);
  return (
    <LayoutGridItem item>
      <RowContainer>
        <Row style={{ height: '30px' }}>
          <RowHead style={{ wordBreak: 'keep-all' }}>
            <div className={classes.normalText}>
              <Grid container>
                <Grid item>
                  <Checkbox
                    disableFocusRipple
                    disableRipple
                    disabled={true}
                    className={`${classes.inlineBoxEditItemPadding} ${classes.inlineBoxEditMainCheckItem}`}
                    icon={
                      props.data !== '' ? (
                        <CheckCircleIcon style={{ color: props.color }} />
                      ) : (
                        <img src={ErrorOutline} alt="Error" />
                      )
                    }
                    checkedIcon={<CheckCircleIcon />}
                  />
                  <span className={`${classes.inlineBoxEditItemPadding} ${classes.inlineBoxEditMainLblItem}`}>
                    {`${t('candidate.profileSummaryCard.socialSecurityNum')}:`}
                  </span>
                </Grid>
                <Grid item>
                  <span>
                    <Grid container>
                      <CustomTooltip
                        tooltipContent={t('candidate.profileSummaryCard.protectedDataTooltip')}
                        contentStyle={{ alignSelf: 'center' }}
                        disabled={props.isViewable}
                      >
                        <Grid item>
                          <>
                            <RowContainer>
                              <Row>
                                {props.isViewable ? (
                                  <ClickAwayListener onClickAway={close}>
                                    <div>{NumberMaskText}</div>
                                  </ClickAwayListener>
                                ) : props.data ? (
                                  <span style={{ paddingLeft: '6px' }}>{completelyHidden}</span>
                                ) : (
                                  '--'
                                )}
                              </Row>
                              <Row style={{ width: 'fit-content' }}>
                                {props.saveStatus == 1 && (
                                  <CircularProgress style={{ width: '12px', height: '12px' }} />
                                )}
                                {props.saveStatus == 2 && (
                                  <>
                                    <CheckIcon style={{ width: '15px', height: '15px', color: '#008000' }} />
                                    <label className={classes.saveSuccessText}>{`Saved`}</label>
                                  </>
                                )}
                                {props.saveStatus == 0 && props.saveStatus !== '' && (
                                  <>
                                    <CloseIcon style={{ width: '15px', height: '15px', color: '#ff0000' }} />
                                    <label className={classes.saveFailedText}>{`Failed to update`}</label>
                                  </>
                                )}
                              </Row>
                            </RowContainer>
                          </>
                        </Grid>
                      </CustomTooltip>
                      <Grid item style={{ display: 'flex' }}>
                        {isHandleClick && (
                          <>
                            {showSubmitIcon &&
                              withReadOnly('span')({
                                className: cx(classes.inlineBoxEditItemPadding, classes.inlineBoxEditMainAcceptItem, {
                                  [classes.disabledItem]: readOnly,
                                }),
                                onClick: () => {
                                  if (!readOnly) handlesave();
                                },
                                'aria-hidden': 'true',
                                children: <CheckIcon data-testid="saveIcon" />,
                              })}
                            <span
                              className={`${classes.inlineBoxEditItemPadding} ${classes.inlineBoxEditMainRejectItem}`}
                              onClick={() => {
                                close();
                              }}
                              onKeyDown={() => {
                                close();
                              }}
                              aria-hidden="true"
                            >
                              <CloseIcon data-testid="closeIcon" />
                            </span>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </span>
                </Grid>
              </Grid>
            </div>
          </RowHead>
          <RowContent></RowContent>
        </Row>
      </RowContainer>
    </LayoutGridItem>
  );
};
