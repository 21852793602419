import React from 'react';
import { makeStyles } from 'tss-react/mui';
import {  Grid, Theme } from 'amn-ui-core';

const useStyles = makeStyles()((theme: Theme) =>
  ({
    root: {
      height: 'auto',
      width: '10vw',
    },
    header: {
      textAlign: 'left',
      font: 'normal normal normal 12px/30px Roboto',
      color: '#333333',
      opacity: '1',
      fontWeight: 600,
      padding: 0,
      margin: 0,
      minWidth: '65px',
    },
    innerRoot: {
      paddingBottom: '8px',
    },
    value: {
      fontSize: '12px',
      color: '#555555',
      textAlign: 'center',
      fontFamily: 'IBM Plex Sans, sans-serif',
      font: 'normal normal normal 12px/20px IBM Plex Sans',
      opacity: 1,
      padding: 0,
      paddingTop: '8px',
      margin: 0,
      lineHeight: '16px',
    },
  }));

export const HoverGlanceSingle = props => {
  const { classes } = useStyles();

  const header = <div>{props.headerText}</div>;

  const glance = props.data.map(item => {
    return (
      <Grid container key={item} className={classes.innerRoot}>
        <Grid item xs={12} className={classes.value}>
          {item}
        </Grid>
      </Grid>
    );
  });

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.header}>
        {header}
        {glance}
      </Grid>
    </Grid>
  );
};

// HoverGlanceSingle.propTypes = {
//   headerText: string,
//   data: PropTypes.arrayOf(
//     PropTypes.shape({
//       key: PropTypes.string,
//       value: PropTypes.string,
//     }),
//   ).isRequired,
// };
