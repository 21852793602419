import { XGridBlueCell } from 'app/components/Common/XGrid/cells/XGridBlueCell';
import { XGridDefaultColumn } from 'app/components/Common/XGrid/cells/XGridDefaults';
import { xGridDateSort } from 'app/components/Common/XGrid/sorting/XGridSorting';
import { missingField } from 'app/constants';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { GetMomentOfDate } from 'utils/dates/moment';

const defaultColumnProps = { ...XGridDefaultColumn, flex: 0 };

export const getExistngOrdersColumns = t => {
  const columns = [
    {
      ...defaultColumnProps,
      headerName: t('Order ID'),
      field: 'orderId',
      width: 100,
      renderCell: XGridBlueCell,
    },
    {
      ...defaultColumnProps,
      headerName: t('Positions'),
      field: 'positionsAvailable',
      width: 160,
    },
    {
      ...defaultColumnProps,
      headerName: t('Automation Status'),
      field: 'automationStatus',
      width: 220,
    },
    {
      ...defaultColumnProps,
      headerName: t('Last Updated'),
      field: 'lastUpdatedOn',
      width: 160,
      sortComparator: xGridDateSort,
    },
    {
      ...defaultColumnProps,
      headerName: t('Last Updated By'),
      field: 'lastUpdatedBy',
      width: 220,
    },
  ];
  return columns;
};

export const extractRows = existingOrders =>
  existingOrders.map(item => {
    const lastUpdatedDate = GetMomentOfDate(item.lastUpdatedOn);
    return {
      ...item,
      id: item.orderId,
      orderId: item.orderId,
      positionsAvailable: item.positionsAvailable,
      automationStatus: item.automationStatus,
      lastUpdatedOn: lastUpdatedDate.isValid() ? lastUpdatedDate.format('MM/DD/YYYY') : missingField,
      lastUpdatedBy: !isNullOrUndefined(item.lastUpdatedBy) ? item.lastUpdatedBy : missingField,
    };
  });
