import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import { Theme, IconButton, Grid, Chip, debounce } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { TypeAhead } from '../../../../Common/TypeAheads/TypeAhead';
import { getLocationsByKeyword } from '../../../../../services/SharedServices/SharedServices';
import { GenerateLocationOptions, GetRadiiOptions } from './FormatData';
import { ITypeAheadOption } from './CustomComponents/ControlledTypeAhead';
import { Title } from './styles';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Concatenate } from 'utils/string/string';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDebounce } from 'utils/customHooks/useDebounce';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocationsChosen } from './PreferenceForm/Preference.selectors';
import { preferenceActions } from './PreferenceForm/Preference.redux';

const useStyles = makeStyles()((theme: Theme) =>
  ({
    root: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    addButtonRoot: {
      color: '#006FB9',
      background: 'none !important',
      padding: '0 !important',
    },
    titleGridRoot: {
      paddingBottom: theme.spacing(2),
    },
    buttonGridRoot: {
      padding: 0,
    },
    chip: {
      margin: '16px 16px 0 0',
    },
    locationField: {
      width: 300,
    },
    radiusField: {
      width: 150,
    },
  }));

export const PreferredLocation = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const chosenLocations = useSelector(selectLocationsChosen);

  const { control, register, setValue, watch, errors, setError, clearErrors } = useFormContext();

  const maximumChoicesAllowed = 20;
  const { promiseInProgress } = usePromiseTracker({ area: 'pref-location', delay: 0 });

  const [chips, setChips] = useState<JSX.Element[]>([]);
  const [locHelperText, setLocHelperText] = useState<string | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [keyword, keywordValue, setKeyword] = useDebounce<string>('', 500);
  const [locationOptions, setLocationOptions] = useState<ITypeAheadOption[]>([]);
  const radiiOptions: ITypeAheadOption[] = GetRadiiOptions();

  const locationChosen = watch('location');
  const radiusChosen = watch('radius');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchLocationOptions = useCallback(debounce(async (searchKey: string) => {
    if (searchKey && !locationChosen)
      await getLocationsByKeyword(searchKey).then(res => {
        const locOptions = GenerateLocationOptions(res);
        setLocationOptions(prevData => locOptions);
      });
  }, 1500), [locationChosen, getLocationsByKeyword]);

  useMemo(() => {
    trackPromise(fetchLocationOptions(keyword), 'pref-location');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  const CheckIfChosen = (currentChoice: ITypeAheadOption) => {
    const isAlreadyChosen =
      currentChoice &&
      chosenLocations.some(
        chosenOpt =>
          chosenOpt.location.object.stateId === currentChoice.object?.stateId &&
          chosenOpt.location.object.cityId === currentChoice.object?.cityId,
      );
    if (isAlreadyChosen) {
      setError('location', { message: t('candidate.preferenceForm.choiceExist') });
    }
    return isAlreadyChosen;
  };

  const AddPreferredLocation = (loc?: ITypeAheadOption) => {
    setKeyword('');
    clearErrors('location');

    if (loc === null) return;
    if (loc && CheckIfChosen(loc)) return;

    if (loc && loc.object.cityId === null) {
      const data = [...chosenLocations, { location: loc, radius: null, isReadOnly: false }];
      setValue('preferredLocations', data, { shouldDirty: true });
      dispatch(preferenceActions.setLocationsChosen(data));

      setValue('location', null);
      props.onValueChange();
    } else if (!loc && locationChosen && locationChosen.object.cityId) {
      const data = [
        ...chosenLocations,
        { location: locationChosen as ITypeAheadOption, radius: radiusChosen as ITypeAheadOption, isReadOnly: false },
      ];
      setValue('preferredLocations', data, { shouldDirty: true });
      dispatch(preferenceActions.setLocationsChosen(data));

      setValue('location', null);
      setValue('radius', null);
      props.onValueChange();
    }
  };

  useEffect(() => {
    props.setFieldValue('preferredLocations', chosenLocations);
    const chipElements = chosenLocations.map((choice, index) => {
      const locString = Concatenate([choice.location.object.city, choice.location.object.stateProvinceId], ', ');
      const radString = choice.radius ? choice.radius.label : '';
      const label = Concatenate([locString, radString], ' - ');
      if (choice.isReadOnly) return <Chip key={index} label={label} className={classes.chip} />;
      return (
        <Chip
          key={index}
          label={label}
          className={classes.chip}
          onDelete={() => {
            const data = chosenLocations.filter(entry => entry !== choice);
            dispatch(preferenceActions.setLocationsChosen(data));
            setValue('preferredLocations', data, { shouldDirty: true });
            props.onValueChange();
          }}
        />
      );
    });
    setChips(chipElements);
    if (chipElements.length >= maximumChoicesAllowed)
      setLocHelperText(
        `${t('candidate.preferenceForm.locationCountLimitNote1')} ${maximumChoicesAllowed} ${t(
          'candidate.preferenceForm.locationCountLimitNote2',
        )}`,
      );
    else setLocHelperText(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenLocations]);

  return (
    <Grid container classes={{ root: classes.root }} direction="column" data-testid="container">
      <Grid item classes={{ root: classes.titleGridRoot }}>
        <Title>{t('candidate.preferenceForm.locationTitle')}</Title>
        <Controller
          name="preferredLocations"
          control={control}
          inputRef={register}
          render={({ ref, ...rest }) => <input type="hidden" {...rest} />}
        />
      </Grid>
      <Grid item container alignItems="center" spacing={2} direction="row">
        <Grid item className={classes.locationField}>
          <Controller
            name="location"
            defaultValue={null}
            control={control}
            render={({ ref, ...rest }) => (
              <TypeAhead
                options={keyword ? locationOptions : []}
                label={t('candidate.preferenceForm.locationLabel')}
                placeholder={t('candidate.preferenceForm.locationPlaceholder')}
                isError={errors.location ? true : false}
                helperText={errors.location ? errors.location.message : locHelperText}
                isDisabled={chosenLocations.length >= maximumChoicesAllowed}
                isOptionsLoading={promiseInProgress}
                hasAsyncOptions
                onChangeReflection={loc => AddPreferredLocation(loc)}
                onInputChange={key => {
                  setKeyword(key);
                }}
                {...rest}
              />
            )}
          />
        </Grid>
        {!errors.location && locationChosen && locationChosen.object?.cityId && (
          <>
            <Grid item className={classes.radiusField}>
              <Controller
                name="radius"
                defaultValue={null}
                control={control}
                render={({ ref, ...rest }) => (
                  <TypeAhead
                    options={radiiOptions}
                    label={t('candidate.preferenceForm.radiusLabel')}
                    placeholder={t('candidate.preferenceForm.radiusPlaceHolder')}
                    popupIcon={<ExpandMoreIcon />}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item classes={{ root: classes.buttonGridRoot }} justifyContent="center">
              <IconButton
               color="primary"
                type="button"
                classes={{ root: classes.addButtonRoot }}
                disableTouchRipple
                disableFocusRipple
                onClick={() => AddPreferredLocation()}
                size="small">
                <AddBoxIcon style={{ fontSize: 60 }} />
              </IconButton>
            </Grid>
          </>
        )}
      </Grid>
      <Grid item>{chips}</Grid>
    </Grid>
  );
};
