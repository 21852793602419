import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { useWatch } from 'react-hook-form';
import { useEditableRequirement } from './EditRequirementsHelper';
import { OrderDOBRequirementCheck, checkDOBRequirement } from './EditablePassFailLogic';
import { useSelector } from 'react-redux';
import { selectPlacementStatus } from 'store/redux-store/placement-status/selectors';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import { selectSubmissionInfo } from 'store/redux-store/placement-summary/selectors';
import { ff_placement_ui_requirement_check } from 'app/constants/FeatureFlags/Placement/Keys';
import { useDecision } from '@optimizely/react-sdk';

export const useEditableRequirementOrderDOB = () => {
  const [requirementCheckFlag] = useDecision(ff_placement_ui_requirement_check);
  // DOB
  const dob: string | undefined = useWatch({ name: 'dob' });
  const { currentPlacementStatus } = useSelector(selectPlacementStatus);
  const placementOrderType = useSelector(selectPlacementOrderType);
  const {
    orderReq: {
      order: { orderDob: orderDOB },
    },
  } = useSelector(selectSubmissionInfo);

  useEditableRequirement({
    data: requirementCheckFlag.enabled ? dob : undefined,
    requirementId: IPlacementRequirementKey.orderDOB,
    requirementKey: 'orderDob',
    requirementCheck: OrderDOBRequirementCheck,
    shouldAddFailedRequirement: checkDOBRequirement(currentPlacementStatus, placementOrderType, orderDOB,dob),
    failRequirement: {
      id: IPlacementRequirementKey.orderDOB,
      message: 'DOB: Missing',
      tooltip: {
        message: 'Date of Birth is required',
      },
    },
  });
};
