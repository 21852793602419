import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import styledComponent from 'styled-components';

const CustomTableCell = styledComponent(TableCell)<{
  detailsCell?: boolean;
  referenceCell?: boolean;
  comparisionCell?: boolean;
  index?: number;
  expanded?: boolean;
  maxHeight?: number;
  height?: number;
}>`
display: flex;
flex-direction: column;
background-color: ${props =>
  props?.index % 2 == 0
    ? (props?.detailsCell && '#EAEBED') ||
      (props.referenceCell && '#F5F5F5') ||
      (props.comparisionCell && '#FFFFFF') ||
      '#FFFFFF'
    : (props?.detailsCell && '#DCDDDF') ||
      (props?.referenceCell && '#EBECEE') ||
      (props?.comparisionCell && '#F6F6F6') ||
      '#F6F6F6'};
padding: 0px;
min-height: 42px;
width: 100%;
min-width: 100px;
text-overflow: ellipsis;
overflow: hidden; 
border-bottom: 1px solid ${props =>
  props.index === 0
    ? (props?.detailsCell && '#8E9195') ||
      (props.referenceCell && '#CCCCCC') ||
      (props.comparisionCell && '#E5E5E5') ||
      '#E5E5E5'
    : 'none'};
border-right-color:  ${props =>
  (props?.detailsCell && 'none') ||
  (props.referenceCell && '#CCCCCC') ||
  (props.comparisionCell && '#E5E5E5') ||
  ''};
border-right-width: thin;
border-right-style: ${props => !props?.detailsCell ? 'solid' : 'none'};
max-height: ${props => (props?.maxHeight ? props?.maxHeight : '')};
height: ${props => (props?.height ? props?.height : '')};
`;

export default CustomTableCell;
