import { UnitDetailsTextArea } from '@AMIEWEB/Order/OrderCreationForm/Common/UnitDetailsCommonTextArea';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelectedUnitDetails } from '@AMIEWEB/Unit/UnitDetails/Containers/unitHelper';
import { FormPageType } from '../UnitRequirements/Experience/SubCertificate/SubCertificate.types';
import { useSelector } from 'react-redux';
import { selectAdditionalRequiredText } from '@AMIEWEB/NewOrder/Store/Order.selectors';

function AdditionalRequiredQualifications({ parentPage }: { parentPage: FormPageType }) {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const selectedUnitDetail = useSelectedUnitDetails();
  const selectedOrderAdditionalRequirement = useSelector(selectAdditionalRequiredText);

  useEffect(() => {
    setValue('additionalRequiredQualifications', selectedUnitDetail?.additionalRequiredQualifications);
  }, [selectedUnitDetail?.additionalRequiredQualifications]);

  return (
    <Controller
      control={control}
      name={'additionalRequiredQualifications'}
      render={({ ref, onChange, ...rest }) => (
        <UnitDetailsTextArea
          fieldLabel={t('facility.units.unitDetails.qualificationAndJobDetails.additionalRequiredQualifications')}
          fieldName={'additionalRequiredQualifications'}
          id={'additionalRequiredQualifications'}
          onChange={onChange}
          isDisabled={false}
          inputValue={rest.value}
          defaultInputValue={parentPage === FormPageType.Order ? selectedOrderAdditionalRequirement : ''}
          showMaxCount
          maxCharacters={8000}
        />
      )}
    />
  );
}

export default AdditionalRequiredQualifications;
