/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import {
  selectFiterAttributes,
  selectGridState,
} from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import { XGrid } from 'app/components/Common/XGrid/XGrid';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { associatedPlacementActions, IRootFilter } from './store/associatedPlacements.redux';
import {
  GridColumns,
  GridRowsProp,
  GridSortModel,
  GridColumnVisibilityModel,
  GridPinnedColumns,
} from '@mui/x-data-grid-pro';
import { materialUiXGrid } from 'amn-ui-core';
import {
  formatMultiSortColumns,
  formatMultiSortXGridColumns,
  getFilterRequest,
  revalidateChipSelection,
} from '@AMIEWEB/GlobalSearch/helper';
import { useFormContext } from 'react-hook-form';
import { selectApplyFilters } from './store/associatedPlacements.selectors';
import _ from 'lodash';
import { GridColumns as CurrentGridColumns } from 'app/models/GlobalSearch/GlobalSearch';
import { GridColumns as XGridColumns } from '@mui/x-data-grid-pro';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import { RootState } from 'types';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { placementViewInfo } from 'app/components/GlobalSearch/Placement/SearchUtils';
import { notCandidateColumns, notFacilityColumns } from 'app/components/Placement/AssociatedPlacements/store/utils';
import { SortColumn, gridStateActions } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { GridCallbackDetails, GridDensity, GridFilterModel } from '@mui/x-data-grid';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import { globalSearchActions } from 'store/redux-store/global-search/slice';
import { selectGridSelection } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.selector';
import { ArchivePlacementDialog } from '@AMIEWEB/GlobalSearch/Placement/ArchivePlacementDialog';
import { selectArchivePlacements } from 'store/redux-store/global-search/selectors';
import { useDecision } from '@optimizely/react-sdk';
import { ff_candidate_communication_grid } from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';
import { ff_placement_region_fields } from 'app/constants/FeatureFlags/Placement/Keys';
const useStyles = makeStyles()({
  container: {
    // To be refactored w.r.t to grid height
    paddingBottom: 40,
  },
});
export const candidatePlacementMatchVisibiltyModel = {
  orderId: true,
  placementId: true,
  candidateId: false,
  firstName: false,
  lastName: false,
  goesBy: false,
  otherName: false,
  phones: false,
  emails: false,
  PlacementTag: true,
  status: true,
  statusAge: true,
  brand: true,
  division: true,
  candidate: true,
  facility: true,
  unit: true,
  skillsets: true,
  placementCreated: true,
  screen: true,
  fileSentDate: true,
  availableStartDate: true,
  startDate: true,
  endDate: true,
  extensionStatus: true,
  extensionStatusLastUpdateDate: true,
  accountManager: true,
  recruiter: true,
  credentialingAnalyst: true,
  placementCuedTo: true,
  cueNote: true,
  contractAffiliate: true,
  contractAffiliateId: true,
  clientContractSpecialist: true,
  clinicalManager: true,
  facilityId: true,
  staffingVendor: true,
  region: false,
};

export const PlacementsGrid = ({
  columns,
  setColumns,
  rows,
  rootFilter,
  count,
  placementGridTag,
  ...props
}: {
  columns: GridColumns;
  setColumns: any;
  rows: GridRowsProp;
  rootFilter: { [key in keyof IRootFilter]?: string[] };
  count: number;
  placementGridTag: string;
  [key: string]: any;
}) => {
  const { t } = useTranslation();
  const gridTag = placementGridTag;
  const { classes } = useStyles();
  const apiRef = materialUiXGrid.useGridApiRef();
  const gridState = useSelector(selectGridState);
  const { promiseInProgress } = usePromiseTracker({ area: 'concerned-placements' });
  const dispatch = useDispatch();
  const { getValues } = useFormContext();
  const { filterAttributes } = useSelector(selectGridState);
  const defaultColumn = { column: 'placementCreated', direction: 'desc' };
  const defaultSort = [{ field: 'placementCreated', sort: 'desc' }];
  const [xGridSortModel, setXGridSortModel] = React.useState<any>(defaultSort);
  const applyFilters = useSelector(selectApplyFilters);
  const [candidateCommunicationConsentFlag] = useDecision(ff_candidate_communication_grid);
  const [regionFlag] = useDecision(ff_placement_region_fields);
  const [currentColumnVisibilityModel, setCurrentColumnVisibilityModel] = React.useState<any>(
    _.cloneDeep(candidatePlacementMatchVisibiltyModel),
  );
  const userPreference = useSelector(selectUserPreference);
  const gridPreferences = useSelector((state: RootState) => {
    return state.userPreferenceData.userGridPreferences.find(item => item.id === gridTag);
  });
  const [defaultColumns] = React.useState<any>(
    placementViewInfo(t, [], candidateCommunicationConsentFlag.enabled).filter(
      column =>
        !(regionFlag.enabled ? notCandidateColumns : [...notCandidateColumns, 'region']).some(
          notValue => notValue === column.field,
        ),
    ),
  );
  const [showResetButton, setShowResetButton] = React.useState<boolean>(false);
  const [pinnedColumns, setPinnedColumns] = React.useState<GridPinnedColumns>({});
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({ items: [] });
  const [density, setDensity] = React.useState<GridDensity>('compact');
  const [disableArchivePlacements, setDisableArchivePlacements] = React.useState<boolean>(true);
  const { selectedData } = useSelector(selectGridSelection);
  const { success: archivePlacementStatus } = useSelector(selectArchivePlacements);
  const filters = useSelector(selectFiterAttributes);
  const handleFetchData = (
    offset: number,
    page: number,
    sortedColumn?: { column: string; direction: any } | undefined | null,
    filters?: any | null | undefined,
    multiSortedColumns?: SortColumn[],
  ) => {
    dispatch(
      associatedPlacementActions.getPlacementsAction({
        filter: filters ? { ...filters, ...rootFilter } : { ...rootFilter },
        pageNumber: page,
        pageSize: offset,
        sortedColumn: sortedColumn || gridState.sortedColumn,
        multiSortedColumns: multiSortedColumns || gridState.multiSortedColumns,
        translation: t,
      }),
    );
  };

  useEffect(() => {
    const pagination = apiRef?.current?.state?.pagination;
    const selectedChipFilters = revalidateChipSelection(
      getValues(),
      gridState.filterAttributes?.selectedChipFilters || [],
    );
    if (applyFilters) {
      dispatch(associatedPlacementActions.setApplyFilter(false));
    }
    const sorting =
      xGridSortModel.length > 0
        ? { column: xGridSortModel[0]?.field, direction: xGridSortModel[0]?.sort }
        : defaultColumn;
    const multiSortedColumns = xGridSortModel?.length > 0 ? formatMultiSortColumns(xGridSortModel) : undefined;
    handleFetchData(
      pagination.pageSize,
      pagination.page + 1,
      sorting,
      getFilterRequest(getValues(), selectedChipFilters),
      multiSortedColumns,
    );
  }, [applyFilters]);

  const saveSortModel = React.useCallback(
    sortModel => {
      const sortedColumn =
        sortModel?.length > 0
          ? {
              column: sortModel[0].field,
              direction: sortModel[0].sort,
            }
          : undefined;
      const multiSortedColumns = sortModel?.length > 0 ? formatMultiSortColumns(sortModel) : undefined;
      if (
        !_.isEqual(sortedColumn, gridState?.sortedColumn) ||
        !_.isEqual(multiSortedColumns, gridState?.multiSortedColumns)
      ) {
        const preferenceData = {
          id: gridTag,
          value: {
            ...(gridPreferences?.value || {}),
            sortedColumn: sortedColumn,
            multiSortedColumns: multiSortedColumns,
          },
        };
        setShowResetButton(true);
        dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
      }
    },
    [dispatch, gridPreferences?.value, gridState?.sortedColumn, gridState?.multiSortedColumns],
  );
  const handlePinnedColumnsChange = React.useCallback(
    (updatedPinnedColumns: GridPinnedColumns) => {
      setPinnedColumns(updatedPinnedColumns);
      const preferenceData = {
        id: gridTag,
        value: {
          ...(gridPreferences?.value || {}),
          pinnedColumns: updatedPinnedColumns,
        },
      };
      setShowResetButton(true);
      dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
    },
    [pinnedColumns, userPreference.loaded, gridPreferences?.value, dispatch],
  );
  const saveFilterPreference = React.useCallback(
    (updatedFilter: GridFilterModel, details: GridCallbackDetails) => {
      setFilterModel(updatedFilter);
      const preferenceData = {
        id: gridTag,
        value: {
          ...(gridPreferences?.value || {}),
          updatedFilter: updatedFilter,
        },
      };
      dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
    },
    [filterModel, userPreference.loaded, gridPreferences?.value, dispatch],
  );
  const onSortModelChanged = (model: GridSortModel) => {
    const pagination = apiRef?.current?.state?.pagination;
    const selectedChipFilters = revalidateChipSelection(
      getValues(),
      gridState.filterAttributes?.selectedChipFilters || [],
    );
    const multiSortedColumns = model?.length > 0 ? formatMultiSortColumns(model) : undefined;
    if (pagination) {
      handleFetchData(
        pagination.pageSize,
        pagination.page + 1,
        model.length > 0
          ? {
              column: model[0].field,
              direction: model[0].sort,
            }
          : undefined,
        getFilterRequest(getValues(), selectedChipFilters),
        multiSortedColumns,
      );
    }
    if (model?.length > 0) {
      saveSortModel(model);
    }
    setXGridSortModel(model);
  };

  const onPageChange = (page: number) => {
    const pagination = apiRef?.current?.state?.pagination;
    const selectedChipFilters = revalidateChipSelection(
      getValues(),
      gridState.filterAttributes?.selectedChipFilters || [],
    );
    if (pagination)
      handleFetchData(
        pagination.pageSize,
        page + 1,
        gridState.sortedColumn,
        getFilterRequest(getValues(), selectedChipFilters),
        gridState?.multiSortedColumns,
      );
  };

  const onPageSizeChange = (pageSize: number) => {
    const selectedChipFilters = revalidateChipSelection(
      getValues(),
      gridState.filterAttributes?.selectedChipFilters || [],
    );
    if (gridState.pageSize !== pageSize)
      handleFetchData(
        pageSize,
        1,
        gridState.sortedColumn,
        getFilterRequest(getValues(), selectedChipFilters),
        gridState?.multiSortedColumns,
      );
  };
  const handleGridChanges = state => {
    if (state.density.value !== density) {
      setDensity(state.density.value);
      const preferenceData = {
        id: gridTag,
        value: {
          ...(gridPreferences?.value || {}),
          gridDensity: state.density.value,
        },
      };
      dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
    }
    const current = apiRef.current;
    const currentGridColumns = _.cloneDeep(current.getAllColumns());
    if (currentGridColumns && currentGridColumns.length > 0 && currentGridColumns[0].field === '__check__')
      currentGridColumns.shift();
    const fields = _.cloneDeep(current.state.columns.all);
    if (fields.length > 0 && fields[0] === '__check__') fields.shift();
  };
  const savePreferences = React.useCallback(
    ({
      field,
      columns: gridColumns,
      visibilityModel,
    }: {
      field?: string;
      columns: XGridColumns;
      visibilityModel: GridColumnVisibilityModel;
    }) => {
      if (!columns || columns.length === 0 || !userPreference.loaded) return;
      const currentGridColumns = new CurrentGridColumns(gridColumns).columns;

      if (currentGridColumns && currentGridColumns.length > 0 && currentGridColumns[0].field === '__check__')
        currentGridColumns.shift();

      const comparableColumns = new CurrentGridColumns(
        gridPreferences?.value?.columns,
        gridPreferences?.value?.columnVisibilityModel,
      );
      const columnsEqual = comparableColumns.isEqual(currentGridColumns);
      const visibilityEqual = comparableColumns.isVisibilityModelEqual(visibilityModel);

      if (!columnsEqual || !visibilityEqual) {
        const preferenceData = {
          id: gridTag,
          value: {
            ...(gridPreferences?.value || {}),
            columns: currentGridColumns,
            columnVisibilityModel: visibilityModel,
          },
        };
        setShowResetButton(true);
        dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
      }
    },
    [columns, userPreference.loaded, defaultColumns?.length, gridPreferences?.value, dispatch],
  );
  const handleRefreshGrid = () => {
    const selectedChipFilters = revalidateChipSelection(
      getValues(),
      gridState.filterAttributes?.selectedChipFilters || [],
    );
    handleFetchData(
      gridState.pageSize,
      gridState.pageNumber,
      gridState.sortedColumn,
      getFilterRequest(getValues(), selectedChipFilters),
      gridState.multiSortedColumns,
    );
  };
  const handleResetColumns = async () => {
    setPinnedColumns({});
    setDensity('compact');
    setFilterModel({ items: [] });
    setShowResetButton(false);

    setColumns(
      placementViewInfo(t, [], candidateCommunicationConsentFlag.enabled).filter(
        column =>
          !(
            gridTag === 'facilityPlacementMatch'
              ? regionFlag.enabled
                ? notFacilityColumns
                : [...notFacilityColumns, 'region']
              : regionFlag.enabled
              ? notCandidateColumns
              : [...notCandidateColumns, 'region']
          ).some(notValue => notValue === column.field),
      ),
    );
    setCurrentColumnVisibilityModel(candidatePlacementMatchVisibiltyModel);
    dispatch(gridStateActions.setSortedColumn({ column: 'placementCreated', direction: 'desc' }));
    dispatch(gridStateActions.setMultiSortedColumn([{ column: 'placementCreated', direction: 'desc' }]));
    setXGridSortModel(defaultSort);
    const preferenceData = {
      id: gridTag,
      value: {
        ...(gridPreferences?.value || {}),
        columns: defaultColumns,
        columnVisibilityModel: candidatePlacementMatchVisibiltyModel,
        sortedColumn: { column: 'candidateId', direction: 'desc' },
        multiSortedColumns: [{ column: 'candidateId', direction: 'desc' }],
        pinnedColumns: {},
        gridDensity: 'compact',
        updatedFilter: { items: [] },
      },
    };
    const selectedChipFilters = revalidateChipSelection(getValues(), filterAttributes?.selectedChipFilters || []);
    const filters = getFilterRequest(getValues(), selectedChipFilters);
    dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
    handleFetchData(gridState.pageSize, gridState.pageNumber, defaultColumn, { ...filters, ...rootFilter });
  };
  const getColumnPreferences = (cols: any[]) => {
    try {
      const columnPreferences = gridPreferences?.value;
      if (columnPreferences && columnPreferences.columns) {
        const newColumns = cols.map(column => {
          const foundIndex = columnPreferences.columns.findIndex(item => item?.field === column?.field);
          if (foundIndex !== -1) {
            return {
              ...column,
              ...columnPreferences.columns[foundIndex],
            };
          } else {
            return column;
          }
        });
        return newColumns.sort((a, b) => {
          return (
            columnPreferences.columns.findIndex(i => i.field === a.field) -
            columnPreferences.columns.findIndex(i => i.field === b.field)
          );
        });
      }
      return cols;
    } catch (err) {
      return cols;
    }
  };
  const determineGridColumns = cols => {
    const currentGridColumns = getColumnPreferences(cols);
    setColumns(currentGridColumns);
  };
  const getVisibilityPreferences = (cols: any) => {
    const columnPreferences = gridPreferences?.value;
    if (columnPreferences && columnPreferences?.columnVisibilityModel) {
      return _.merge(cols, columnPreferences?.columnVisibilityModel);
    }
    return cols;
  };
  const determineVisibilityModel = () => {
    const currentVisibilityModel = getVisibilityPreferences(candidatePlacementMatchVisibiltyModel);
    setCurrentColumnVisibilityModel(currentVisibilityModel);
    const visibilityModel = placementViewInfo(t, [], candidateCommunicationConsentFlag.enabled).filter(
      column =>
        !(
          gridTag === 'facilityPlacementMatch'
            ? regionFlag.enabled
              ? notFacilityColumns
              : [...notFacilityColumns, 'region']
            : regionFlag.enabled
            ? notCandidateColumns
            : [...notCandidateColumns, 'region']
        ).some(notValue => notValue === column.field),
    );

    determineGridColumns(visibilityModel);
  };

  const getSortModelPreferences = (sortModel: any[]) => {
    const columnPreferences = gridPreferences?.value;
    if (
      columnPreferences &&
      columnPreferences?.multiSortedColumns &&
      Array.isArray(columnPreferences?.multiSortedColumns)
    ) {
      return formatMultiSortXGridColumns(columnPreferences?.multiSortedColumns);
    }
    return sortModel;
  };
  const determineSortModel = () => {
    const currentGridSortModel = getSortModelPreferences(xGridSortModel);
    setXGridSortModel(currentGridSortModel);
    if (
      !gridState?.sortedColumn ||
      (gridState?.sortedColumn &&
        (gridState.sortedColumn.column !== currentGridSortModel[0].field ||
          gridState.sortedColumn.direction !== currentGridSortModel[0].sort))
    )
      dispatch(
        gridStateActions.setSortedColumn({
          column: currentGridSortModel[0].field,
          direction: currentGridSortModel[0].sort,
        }),
      );
    if (gridState?.multiSortedColumns?.length > 0) {
      dispatch(gridStateActions.setMultiSortedColumn(gridState?.multiSortedColumns));
      setShowResetButton(true);
    }
  };

  const determinePinnedColumns = () => {
    if (gridPreferences?.value?.pinnedColumns) {
      setPinnedColumns(gridPreferences?.value?.pinnedColumns);
    }
  };
  const determineFilters = () => {
    if (gridPreferences?.value?.updatedFilter) {
      setFilterModel(gridPreferences?.value?.updatedFilter);
    }
  };
  const determineDensity = () => {
    if (gridPreferences?.value?.gridDensity) {
      setDensity(gridPreferences?.value?.gridDensity);
    }
  };

  React.useEffect(() => {
    if (userPreference?.loaded) {
      dispatch(gridStateActions.setGrid(gridTag));
      determineVisibilityModel();
      determineSortModel();
      determinePinnedColumns();
      determineFilters();
      determineDensity();
      if (gridPreferences?.value?.multiSortedColumns) {
        setShowResetButton(gridPreferences.value.multiSortedColumns.length > 1);
      }
    }
  }, [userPreference?.loaded]);
  React.useEffect(() => {
    dispatch(gridStateActions.setGrid(gridTag));
    return () => {
      dispatch(
        gridStateActions.setSortedColumn({
          column: 'placementCreated',
          direction: 'desc',
        }),
      );
      dispatch(gridStateActions.setMultiSortedColumn(gridState?.multiSortedColumns));
    };
  }, []);
  React.useEffect(() => {
    // Reset grid selection regardless of archive filter
    if (
      archivePlacementStatus &&
      (filters?.filters as any[])?.find(
        item => item[0] === 'archiveStatus' && (item[1]?.name === 'No' || item[1]?.name === 'Yes'),
      )
    ) {
      // Get new data when archive filter is applied
      apiRef?.current?.setSelectionModel([]);
      const selectedChipFilters = revalidateChipSelection(
        getValues(),
        gridState.filterAttributes?.selectedChipFilters || [],
      );
      setTimeout(() => {
        handleFetchData(
          gridState.pageSize,
          gridState.pageNumber,
          gridState.sortedColumn,
          getFilterRequest(getValues(), selectedChipFilters),
          gridState.multiSortedColumns,
        );
      }, 500);

      dispatch(globalSearchActions.setBulkArchivePlacements({ dialogOpen: false, success: null, loading: false }));
    }
  }, [archivePlacementStatus, dispatch, handleFetchData, xGridSortModel]);
  const leftActions = React.useMemo(() => {
    return [
      {
        title: 'global.xgrid.toolbar.archive',
        Icon: ArchiveOutlinedIcon,
        disabled: disableArchivePlacements,
        onClick: () => {
          dispatch(globalSearchActions.setBulkArchivePlacements({ dialogOpen: true }));
        },
        enableReadOnly: true,
      },
    ];
  }, [dispatch, disableArchivePlacements, selectedData.length]);

  /** Enable or disable Archive Placements action button */
  React.useEffect(() => {
    if (selectedData?.length > 0) {
      dispatch(
        globalSearchActions.getArchivablePlacements({
          result: rows => {
            setDisableArchivePlacements(rows?.length === 0);
          },
        }),
      );
    } else {
      setDisableArchivePlacements(true);
    }
  }, [dispatch, selectedData]);

  const GridComponent = React.useMemo(() => {
    return (
      <XGrid
        apiRef={apiRef}
        columns={columns}
        rowsPerPageOptions={[250, 300, 400, 500]}
        defaultRowsPerPage={250}
        defaultColumns={defaultColumns}
        defaultColumnVisibilityModel={candidatePlacementMatchVisibiltyModel}
        columnVisibilityModel={currentColumnVisibilityModel}
        disableMultipleColumnsSorting={false}
        rows={rows || []}
        rowCount={count}
        autoHeight={false}
        loading={promiseInProgress}
        onSortModelChange={onSortModelChanged}
        defaultSortModel={xGridSortModel}
        sortModel={xGridSortModel}
        onStateChange={handleGridChanges}
        gridTag={gridTag}
        paginationMode="server"
        sortingMode="server"
        disableSelectionOnClick
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onColumnResizeStop={_columns => {
          savePreferences({
            field: undefined,
            columns: _columns,
            visibilityModel: _.cloneDeep(currentColumnVisibilityModel ?? candidatePlacementMatchVisibiltyModel),
          });
        }}
        onColumnOrderChange={(params, event, details, _columns) => {
          savePreferences({
            field: params.field,
            columns: _columns,
            visibilityModel: _.cloneDeep(currentColumnVisibilityModel ?? candidatePlacementMatchVisibiltyModel),
          });
        }}
        onColumnVisibilityModelChange={(model, details) => {
          dispatch(gridStateActions.setColumnVisibilityModel(model));
          setCurrentColumnVisibilityModel(_.cloneDeep(model));
          savePreferences({
            field: undefined,
            columns: _.cloneDeep(apiRef?.current?.getAllColumns()),
            visibilityModel: _.cloneDeep(model),
          });
        }}
        toolbarProps={{
          refreshButton: true,
          filterButton: true,
          resetButton: showResetButton,
          leftActions,
          refreshGrid: () => {
            handleRefreshGrid();
          },
          resetColumns: () => {
            handleResetColumns();
            dispatch(
              globalActions.setSnackBar({
                message: 'Columns have been reset.',
                severity: 'success',
              }),
            );
          },
        }}
        filterModel={filterModel}
        onFilterModelChange={saveFilterPreference}
        pinnedColumns={pinnedColumns}
        onPinnedColumnsChange={handlePinnedColumnsChange}
        density={density}
        title={`Placements`}
        checkboxSelection
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    leftActions,
    density,
    filterModel,
    pinnedColumns,
    rows,
    columns,
    xGridSortModel,
    currentColumnVisibilityModel,
    promiseInProgress,
    showResetButton,
  ]);

  return (
    <Box className={classes.container}>
      {GridComponent} <ArchivePlacementDialog />
    </Box>
  );
};
