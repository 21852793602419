import { getAge } from '@AMIEWEB/Order/OrderDetails/helper';
import { missingField } from 'app/constants';
import moment from 'moment';
import { IFetchOrderUnitDetail } from './UnitOrderWrapper';

/**
 * Method to set skill set field value
 * @param skillSet
 */
const setSkillSetFieldValue = (skillSet: [{ disciplineAbbr: string; specialty: string }]) => {
  if (skillSet?.length) {
    return skillSet.map(
      (skillSetItem, index) =>
        `${skillSetItem.disciplineAbbr} - ${skillSetItem.specialty} ${index === skillSet?.length - 1 ? '' : ','}`,
    );
  } else {
    return missingField;
  }
};

/**
 *
 * @param value
 */
const validateYesOrNoForBoolean = (value: boolean) => {
  if (value === false) {
    return 'No';
  } else if (value === true) {
    return 'Yes';
  } else {
    return missingField;
  }
};

const validateShiftNotNegotiable = (value: number) => {
  if (value === 0) {
    return 'No';
  } else if (value === 1) {
    return 'Yes';
  } else {
    return missingField;
  }
};

export const formatOrderUnitFacilityGridData = orderData => {
  let numberOfPositions = 0;
  const formattedRows = orderData?.items?.map((item, index: number) => {
    if (!isNaN(item?.positions)) {
      numberOfPositions = numberOfPositions + item.positions;
    }
    return {
      ...item,
      id: index,
      orderId: item.orderId ?? missingField,
      shiftNotNegotiable: validateShiftNotNegotiable(item.shiftNotNegotiable),
      verified: item.dateVerified ? moment(item.dateVerified).format('MM/DD/YYYY hh:mm:ss A') : missingField,
      dateEntered: item.dateEntered ? moment(item.dateEntered).format('MM/DD/YYYY') : missingField,
      maxFilesSent: validateYesOrNoForBoolean(item.maxFilesSent),
      maxFilesLastUpdated: item.lastUpdatedMaxFilesSent ?? missingField,
      noOfSubmissions: item.numOfSubmissions ?? missingField,
      orderAge: item?.dateEntered ? getAge(item.dateEntered) : missingField,
      noOfWeeks: item.numOfWeeks ?? missingField,
      yoe: item.yearsOfExperience ?? missingField,
      positions: item.positions ?? missingField,
      notes: item.notes ?? missingField,
      allowScreen: item.allowScreen ?? missingField,
      skillSet: setSkillSetFieldValue(item?.skillsets),
    };
  });
  return { items: formattedRows ?? [], paging: { ...orderData.paging, positions: numberOfPositions } };
};

export function setOrderUnitsPayload(fetchRequest, filterValues) {
  let request: IFetchOrderUnitDetail = fetchRequest;

  let orderForUnitFilter = {};

  if (filterValues && filterValues.dateEnteredRange) {
    orderForUnitFilter = {
      ...orderForUnitFilter,
      orderAgeFromDate: filterValues.dateEnteredRange.startDate,
      orderAgeToDate: filterValues.dateEnteredRange.endDate,
    };
  }

  if (filterValues && filterValues.maxFilesSentId) {
    orderForUnitFilter = {
      ...orderForUnitFilter,
      mfsFilter: filterValues.maxFilesSentId.value === 1,
    };
  }

  if (filterValues && filterValues.positions) {
    orderForUnitFilter = {
      ...orderForUnitFilter,
      fromPositionFilter: filterValues.positions.value.min,
      toPositionFilter: filterValues.positions.value.max,
    };
  }

  if (Object.keys(orderForUnitFilter).length > 0) {
    request.orderForUnitFilter = orderForUnitFilter;
  }

  return request;
}

