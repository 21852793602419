import React, { useEffect, useState } from 'react';
import { Divider, Grid, IconButton, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { candidateDetailsSelection } from '../../../Profile/CandidateDetails.selector';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import KeyboardArrowDownwardIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { SubTitle } from './styles';
import { missingField } from 'app/constants';
import _ from 'lodash';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { IDeleteTravelerTaxInfo, ISelectedTaxLetterInfo, ITaxLetterInfo } from 'app/models/Candidate/CandidateProfile';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { selectUser } from 'oidc/user.selectors';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips';
import PermanentTaxFormDialogue from '../Form/PermanentTaxFormDailogue';

export enum TaxLetterValue {
  Yes = 'Y',
  No = 'N',
}

const useStyles = makeStyles<{ deleteEnabled: boolean; editEnabled: boolean }>()(
  (theme, { deleteEnabled, editEnabled }) => ({
    sectionContainer: {
      padding: '10px',
      '& #deleteIcon': {
        display: 'none',
      },
      '& #editIcon': {
        display: 'none',
      },
      '&:hover': {
        backgroundColor: deleteEnabled && editEnabled && 'rgba(0, 0, 0, 0.04)',
        transition: 'background-color 250ms ease-out',
        '& #deleteIcon': {
          display: deleteEnabled && 'block',
          transition: 'display 250ms ease-out',
        },
        '& #editIcon': {
          display: editEnabled && 'block',
          transition: 'display 250ms ease-out',
        },
      },
    },
    editHover: {
      color: `${theme.palette.system.neutralGray} !important`,
      '&:hover': {
        color: `${theme.palette.framework.system.skyBlue} !important`,
      },
    },
  }),
);

export const PermanentTaxHomeCard = () => {
  const { t } = useTranslation();
  const [deleteEnabled, setDeleteEnabled] = useState<boolean>(false);
  const [editEnabled, setEditEnabled] = useState<boolean>(false);
  const { classes } = useStyles({ deleteEnabled, editEnabled });
  const candidateDetails = useSelector(candidateDetailsSelection);
  const user = useSelector(selectUser);
  const taxLetterInfo = candidateDetails.taxLetterInfo;
  const [sortRecords, SetSortRecords] = useState(taxLetterInfo);
  const [expand, setExpand] = useState<boolean>(false);
  const [showDivider, setShowDivider] = useState<boolean>(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [openEditForm, setOpenEditForm] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<ITaxLetterInfo | null>(null);
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState<ISelectedTaxLetterInfo | null>(null);

  useEffect(() => {
    if (taxLetterInfo?.length) {
      SetSortRecords(
        _.orderBy(
          taxLetterInfo?.map((item, idx) => {
            return { ...item, sortOrder: idx };
          }),
          (o: any) => {
            return o != null ? o.updateDate : o;
          },
          ['desc'],
        ),
      );
      if (
        Authorized(
          [userRoles.payrollSpecialist, userRoles.customerSupport_Leadership, userRoles.customerSupport_TeamMember],
          user.userInfo,
        )
      ) {
        setDeleteEnabled(true);
        setEditEnabled(true);
      }
      if (Authorized([userRoles.payrollSpecialist], user.userInfo)) {
        setEditEnabled(true);
      }
    } else {
      SetSortRecords([]);
      setDeleteEnabled(false);
      setEditEnabled(false);
    }
  }, [taxLetterInfo, user.userInfo]);

  const onDelete = (item: ITaxLetterInfo) => {
    setItemToDelete(item);
    setOpenDeleteConfirmation(true);
  };
  const onEdit = item => {
    const formattedData = {
      taxLetterInfoID: item?.taxLetterInfoID,
      pWithhold: item?.taxLetter === 'Y' ? 'Yes' : 'No',
      pEffectiveDate: item?.updateDate,
    };
    setSelectedItem(formattedData);
    setOpenEditForm(true);
  };

  const onCancel = () => {
    setItemToDelete(null);
    setOpenDeleteConfirmation(false);
  };

  const deleteTaxInfo = () => {
    const payload: IDeleteTravelerTaxInfo = {
      candidateId: candidateDetails.travelerId,
      TaxLetterInfoIds: [itemToDelete.taxLetterInfoID],
      currentEmployeeId: user?.userInfo?.employeeId || 0,
      currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
    };
    dispatch(candidateDetailActions.deleteTravelerTaxData(payload));
    setOpenDeleteConfirmation(false);
  };

  const Sections = props => {
    const { item } = props;
    return (
      <Grid item sx={{ width: '100%' }}>
        <Grid container justifyContent="space-between" className={classes.sectionContainer} xs={12}>
          <Grid item xs={10}>
            <Grid
              container
              direction="column"
              spacing={2}
              sx={{ maxHeight: sortRecords?.length < 2 ? '85px' : 'auto' }}
            >
              <Grid item container spacing={1} direction="row">
                <Grid item xs={5}>
                  <SubTitle>{t('candidate.personalInfoTabLabels.withhold')}</SubTitle>
                </Grid>
                <Grid item>
                  <Typography>
                    {item.taxLetter ? (item.taxLetter === TaxLetterValue.Yes ? 'Yes' : 'No') : missingField}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container spacing={1} direction="row">
                <Grid item xs={5}>
                  <SubTitle>{t('candidate.personalInfoTabLabels.effectiveDate')}</SubTitle>
                </Grid>
                <Grid item>
                  <Typography>
                    {item.updateDate ? moment(item.updateDate).format('MM/DD/YYYY') : missingField}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {editEnabled && (
            <Grid item xs={1} alignSelf="center" id={'editIcon'}>
              <CustomTooltip tooltipContent={t('Edit')}>
                <IconButton color="secondary" size="medium" className={classes.editHover} onClick={() => onEdit(item)}>
                  <EditOutlinedIcon />
                </IconButton>
              </CustomTooltip>
            </Grid>
          )}
          {deleteEnabled && (
            <Grid item xs={1} alignSelf="center" id={'deleteIcon'}>
              <CustomTooltip tooltipContent={t('Delete')}>
                <IconButton color="secondary" size="medium" className={classes.editHover} onClick={() => onDelete(item)}>
                  <DeleteOutlinedIcon />
                </IconButton>
              </CustomTooltip>
            </Grid>
          )}
        </Grid>
        {showDivider && taxLetterInfo?.length > 1 && <Divider />}
      </Grid>
    );
  };

  const BuildSections = props => {
    const { expand } = props;
    return (
      <>
        {sortRecords?.map((item, index) => {
          if (!expand) {
            if (index === 0) {
              return (
                <>
                  <Sections item={item} />
                </>
              );
            }
          } else {
            return (
              <>
                <Sections item={item} />
              </>
            );
          }
        })}
      </>
    );
  };

  return (
    <>
      <Grid container direction="column">
        {sortRecords?.length > 1 ? (
          !expand ? (
            <>
              <BuildSections expand={false} />
              <Grid item sx={{ width: '100%' }}>
                <Typography
                  sx={{ cursor: 'pointer', paddingTop: '10px' }}
                  color="primary"
                  onClick={event => {
                    setExpand(true), setShowDivider(true);
                  }}
                >
                  {`Show ${sortRecords.length - 1} Older Permanent Tax Home ${
                    sortRecords.length - 1 > 1 ? 'Records' : 'Record'
                  }`}
                  <KeyboardArrowDownwardIcon color="primary" />
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <BuildSections expand={true} />
              <Grid item sx={{ width: '100%' }}>
                <Typography
                  sx={{ cursor: 'pointer', paddingTop: '10px' }}
                  color="primary"
                  onClick={event => {
                    setExpand(false), setShowDivider(false);
                  }}
                >
                  {`Hide ${sortRecords.length - 1} Older Permanent Tax Home ${
                    sortRecords.length - 1 > 1 ? 'Records' : 'Record'
                  }`}
                  <KeyboardArrowUpIcon color="primary" />
                </Typography>
              </Grid>
            </>
          )
        ) : sortRecords.length > 0 ? (
          <BuildSections expand={true} />
        ) : (
          <Sections item={''} />
        )}
      </Grid>
      <GenericDialog
        open={openDeleteConfirmation}
        variant={'white'}
        onClose={onCancel}
        dialogTitleProps={{
          text: t('candidate.taxInfoCard.dialogTitle'),
          closeButton: false,
        }}
        dialogActions={[
          {
            text: t('candidate.taxInfoCard.cancel'),
            onClick: onCancel,
            color: 'tertiary',
          },
          {
            text: t('candidate.taxInfoCard.delete'),
            variant: 'contained',
            onClick: deleteTaxInfo,
          },
        ]}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body1">{t('candidate.taxInfoCard.dialogContent')}</Typography>
          </Grid>
        </Grid>
      </GenericDialog>
      {openEditForm && (
        <PermanentTaxFormDialogue
          selectedItem={selectedItem}
          setOpenForm={setOpenEditForm}
          isModalOpen={openEditForm}
        />
      )}
    </>
  );
};
