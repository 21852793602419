import { Concatenate } from "utils/string/string";

export const formatEmployeeList = (userList: any[]) => {
  const formattedUserList: any[] = [];
  userList?.map(user => {
    const data = {
      value: user.employeeId,
      name: Concatenate([user.employeeFirstName || '', user.employeeLastName || ''], ' '),
    };
    formattedUserList.push(data);
  });
  return formattedUserList;
};
