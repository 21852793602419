import { takeEvery, takeLatest } from 'redux-saga/effects';
import { fetchCoverageRequests, saveCoverageRequest, updateCoverageRequests, fetchActiveCoverageProvidedByUser , denyCoverageRequests, fetchCoveredInbox } from './request-saga';
import { coverageActions } from './async-actions';

export function* coverageSaga() {
  yield takeEvery(coverageActions.saveCoverageRequestAction.type, saveCoverageRequest);
  yield takeLatest(coverageActions.getCoverageRequestList.type, fetchCoverageRequests);
  yield takeLatest(coverageActions.updateCoverageRequestAction.type , updateCoverageRequests);
  yield takeLatest(coverageActions.getActiveCoverageProvidedByUser.type , fetchActiveCoverageProvidedByUser);
  yield takeLatest(coverageActions.denyCoverageRequestAction.type , denyCoverageRequests );
  yield takeLatest(coverageActions.fetchCoveredInbox.type, fetchCoveredInbox)
}
