import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { selectConfettiDetails } from 'app/components/Confetti/store/Confetti.selector';
import { confettiDetailsAction } from 'app/components/Confetti/store/Confetti.redux';
import { IConfetti } from 'app/components/Confetti/ConfettiModel';
import Confetti from 'react-confetti';

const useStyles = makeStyles()({
  centerContainer: {
    //'background-color': '#ffffffd9',
    width: '100%',
    height: 'auto',
    bottom: '0px',
    top: '0px',
    left: '0',
    position: 'absolute',
    zIndex: '9999',
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 0 10px 20px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  centerText: {
    textAlign: 'center',
    fontSize: '2rem',
    zIndex: '9999',
    maxWidth: '55%',
    padding: '2rem',
    background: '#ffffff',
    boxShadow: '0px 4px 6px -2px rgb(15 255 56 / 47%)',
    WebkitBoxShadow: '0px 4px 6px -2px rgb(15 255 56 / 47%)',
    textShadow: '0px 0px 2px #daff2dbf',
    border: 'thin #000 solid',
    borderRadius: '14px',
    borderColor: '#4b11fa',
    animation: `$myEffect 1s`,
  },
  hide: {
    opacity: '0',
    pointerEvents: 'none',
    animation: `$myEffectHide 1s`,
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  '@keyframes myEffect': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 0.5,
    },
    '100%': {
      opacity: 1,
    },
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  '@keyframes myEffectHide': {
    '0%': {
      opacity: 1,
    },
    '30%': {
      opacity: 0.7,
    },
    '50%': {
      opacity: 0.5,
    },
    '100%': {
      opacity: 0,
    },
  },
});

export const ConfettiView = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const confettiDetails = useSelector(selectConfettiDetails);
  const [openVew, setOpenVew] = useState<boolean>(false);
  const [hideClass, setHideClass] = useState<string>('');
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const [windowHeight, setWindowHeight] = useState<number>(0);

  useEffect(() => {
    const { innerWidth: width, innerHeight: height } = window;
    setWindowWidth(width);
    setWindowHeight(height);
  }, []);

  useEffect(() => {
    if (confettiDetails && confettiDetails.confettiDetails && confettiDetails.confettiDetails.show && !openVew) {
      setOpenVew(true);
      setHideClass('');
      const timeOut = confettiDetails.confettiDetails.timeout * 2 + 1;
      const intervalHide = setInterval(function () {
        setHideClass('hide');
        clearInterval(intervalHide);
        return;
      }, (timeOut - 2) * 1000);

      const interval = setInterval(function () {
        dispatch(
          confettiDetailsAction.setConfettiDetails({
            show: false,
            timeout: confettiDetails.confettiDetails.timeout,
            message: confettiDetails.confettiDetails.message,
          } as IConfetti),
        );
        setOpenVew(false);
        clearInterval(interval);
        return;
      }, timeOut * 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confettiDetails]);

  return (
    <>
      {openVew && (
        <div
          className={`${classes.centerContainer} ${hideClass === 'hide' && classes.hide}`}
          data-testid="confettiDetailsContainer"
        >
          <Confetti width={windowWidth} height={windowHeight} numberOfPieces={1000} run={openVew} opacity={85} />
          <div className={classes.centerText} data-testid="confettiDetailsMessage">
            {confettiDetails.confettiDetails.message}
          </div>
        </div>
      )}
    </>
  );
};
