import React from 'react';
import { SidePanel, TemplateEditorWrapper } from '@AMIEWEB/Common/TemplateEditor';
import { useDispatch, useSelector } from 'react-redux';
import { usePromiseTracker } from 'react-promise-tracker';
import { useReadOnly } from 'oidc/userRoles';
import { Typography } from 'amn-ui-core';
import {
  contractTemplateEditorActions,
  contractTemplateEditorReducer,
  contractTemplateEditorSliceKey,
} from 'store/redux-store/contract-template-editor/slice';
import { IContractTemplate } from 'store/redux-store/contract-template-editor/types';
import { selectTemplatesEditor } from 'store/redux-store/contract-template-editor/selectors';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { templateEditorSaga } from 'store/redux-store/contract-template-editor/saga';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { selectEditorPreferences } from 'oidc/UserDevicePreference/userPreference.selectors';

export const CandidateContract = () => {
  useInjectReducer({ key: contractTemplateEditorSliceKey, reducer: contractTemplateEditorReducer });
  useInjectSaga({ key: contractTemplateEditorSliceKey, saga: templateEditorSaga });
  const [sideView, setSideView] = React.useState<SidePanel>('templates');

  const { t } = useTranslation();
  const history = useHistory();

  const {
    settingsContractTemplateEditor: { value: editorPreference },
    hasLoadedPreferences,
  } = useSelector(selectEditorPreferences);

  const {
    templates,
    selectedTemplate,
    mergeTokenList,
    miscellaneous: { forceModalCloseFlag },
  } = useSelector(selectTemplatesEditor);

  const { readOnly } = useReadOnly();

  const { promiseInProgress: isLoading } = usePromiseTracker({ area: 'document-templates-editor', delay: 0 });
  const { promiseInProgress: isPublishing } = usePromiseTracker({
    area: 'document-templates-editor-publish',
    delay: 0,
  });

  const dispatch = useDispatch();

  const onTemplateClick = template => {
    dispatch(contractTemplateEditorActions.setSelectedTemplate(template));
  };

  const onPublish = (template: IContractTemplate) => {
    dispatch(contractTemplateEditorActions.publishTemplate(template));
  };

  const pageTitle = React.useMemo(
    () => <Typography variant={'h6'}>{t('templateEditor.pageTitle.candidateContract')}</Typography>,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const updateEditorWidthPreference = width => {
    dispatch(
      userDevicePreferenceActions.business_saveEditorWidthPreference({
        editor: 'settingsContractTemplateEditor',
        width,
      }),
    );
  };

  React.useEffect(() => {
    dispatch(contractTemplateEditorActions.onInitAction());

    return () => {
      dispatch(contractTemplateEditorActions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [{ titleIcons }] = React.useState({ titleIcons: ['tokens', 'templates'] });

  return (
    <TemplateEditorWrapper
      //@ts-ignore
      templates={templates}
      //@ts-ignore
      selectedTemplate={selectedTemplate}
      onTemplateClick={onTemplateClick}
      onPublish={onPublish}
      history={history}
      mergeTokenList={mergeTokenList}
      pageTitle={pageTitle}
      isLoading={!hasLoadedPreferences}
      isLoadingSideView={isLoading}
      isPublishing={isPublishing}
      forceModalCloseFlag={forceModalCloseFlag}
      userIsReadOnly={readOnly}
      titleIcons={titleIcons}
      documentType="candidate-contract"
      sideViewProps={[sideView, setSideView]}
      editorPreference={editorPreference}
      onSplitPaneDragEnd={({ clientWidth }) => updateEditorWidthPreference(clientWidth)}
    />
  );
};
