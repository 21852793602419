import { appComponentAccessId } from 'app/layout/Layout';

export const redirectToCandidateContractDocEditor = (history, options?: { defaultSelection: string }) => {
  /** Code: Switch view to candidate-contract-editor */
  const searchParams = new URLSearchParams(history.location.search);
  searchParams.set('view', 'candidate-contract-editor');
  if (options?.defaultSelection) searchParams.set('default_selection', options?.defaultSelection);
  history.push({ ...history.location, search: searchParams.toString() });
  /** Scroll to the top of the screen on redirection */
  document.getElementById(appComponentAccessId)?.scrollTo(0, 0);
};
