import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { selectExistingTaskAttachments } from '../store/Tasks.selectors';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { taskDetailsActions } from '../store/Tasks.redux';
import { Chip, Grid, Tooltip } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { getAttachmentIcon } from '@AMIEWEB/Notification/Common/AttachmentFileTypes';


const CommentsAttachmentStyles = makeStyles()(theme => ({
  attachmentBtn: {
    '&:hover': {
      display: '-webkit-inline-box',
      overflow: 'hidden',
    },
    padding: '0',
    height: '36px',
    minWidth: '36px',
    background: '#F7F7F7 0% 0% no-repeat padding-box',
    border: `1px solid ${theme.palette.system.fadedGrey}`,
    borderRadius: '5px',
    opacity: '1',
    boxShadow: 'none',
    font: 'normal normal normal 11px/20px Roboto',
    letterSpacing: '0',
    color: '#333333',
  },
  attachmentIcon: {
    width: '34px',
  },
  arrow: {
    '&:before': {
      border: '1px solid #E6E8ED',
      boxShadow: '0px 3px 3px #c7c7c7',
    },
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#4A4A4A',
    fontSize: '12px',
    lineHeight: '20px',
    maxWidth: 'none',
    boxShadow: '0px 3px 3px #c7c7c7',
  },
  attachmentChip: {
    margin: '3px 3px 3px 3px',
    borderRadius: '5px',
    background: '#f7f7f7',
    border: `1px solid ${theme.palette.system.fadedGrey}`,
    height: '26px',
    zIndex: 2,
    width: '10rem',
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  chipLabel: {
    width: 'inherit',
  },
  fileIcon: {
    color: '#616161',
    marginRight: '0px',
    marginLeft: '5px',
    width: '16px',
  },
}));

export const CommentsAttachment = props => {
  const { attachments, setAttachments } = props;
  const dispatch = useDispatch();
  const { classes } = CommentsAttachmentStyles();
  const { t } = useTranslation();
  const SIZE_LIMIT_MESSAGE = `Attachment limit Max 35MB per attachment`;
  const validFileFormats = /(\.jpeg|\.jpg|\.png|\.docx|\.doc|\.pdf|\.xlsx|\.xls|\.csv|\.msg)$/i;
  const MAX_FILE_SIZE = 35000;
  const attachmentAttributes = useSelector(selectExistingTaskAttachments);

  const getAttchmentSize = newAttachment => {
    const totalSizeAttach = attachments?.reduce((accumulator, object) => {
      return accumulator + object.size;
    }, 0);
    var initialAttachedFileSize = 0;
    if (attachmentAttributes) {
      attachmentAttributes?.map(attachment => {
        initialAttachedFileSize = initialAttachedFileSize + attachment?.attachmentSize;
      });
    }

    const totalSize = totalSizeAttach + initialAttachedFileSize + newAttachment?.size;

    if (totalSize / 1024 > MAX_FILE_SIZE) {
      return true;
    } else {
      return false;
    }
  };

  const getAttchmentCount = () => {
    if (attachmentAttributes) {
      var count = 0;
      attachmentAttributes?.forEach(attachment => {
        count = count + 1;
      });
      if (attachments?.length + count > 4) {
        return true;
      } else {
        return false;
      }
    } else {
      if (attachments?.length > 4) {
        return true;
      } else {
        return false;
      }
    }
  };

  const existingFileCheck = (attachments, newAttachment) => {
    var fileCheck = false;
    if (attachments?.length > 0) {
      const existingAttachment = attachments?.filter(fileAttachment => fileAttachment?.name === newAttachment?.name);
      if (existingAttachment?.length !== 0) {
        fileCheck = true;
      }
    }
    if (attachmentAttributes && fileCheck === false) {
      for (var attachment of attachmentAttributes) {
        const check = attachment?.attachmentUrl?.includes(newAttachment?.name?.replace(/\s/g, '_'));
        if (check) {
          fileCheck = true;
          break;
        }
      }
    }
    return fileCheck;
  };

  const handleTaskAttachment = event => {
    if (event.target.id === 'attachment-btn-upload') {
      const newAttachment = event.target.files[0];
      if (!validFileFormats.exec(newAttachment.name.toLowerCase())) {
        dispatch(
          globalActions.setSnackBar({
            message: `1 file failed to attach. Please choose valid file format ${t(
              'notification.createTask.attachments.fileTypesLabel',
            )}`,
            severity: 'error',
          }),
        );
      } else if (getAttchmentSize(newAttachment)) {
        dispatch(
          globalActions.setSnackBar({
            message: SIZE_LIMIT_MESSAGE,
            severity: 'error',
          }),
        );
      } else if (getAttchmentCount()) {
        dispatch(
          globalActions.setSnackBar({
            message: 'Attachment could not be added because it exceeds the 5 attachment limit.',
            severity: 'error',
          }),
        );
      } else {
        const getAttachment = [...attachments];
        if (existingFileCheck(getAttachment, newAttachment)) {
          dispatch(
            globalActions.setSnackBar({
              message: 'file already exists',
              severity: 'error',
            }),
          );
        } else {
          getAttachment.push(newAttachment);
          setAttachments(getAttachment);
        }
        dispatch(taskDetailsActions.setTaskAttachments(getAttachment));
      }
    }
  };

  const handleDeleteAttachment = index => {
    const newAttachments = attachments?.filter((item, itemIndex) => itemIndex !== index);
    setAttachments(newAttachments);
    dispatch(taskDetailsActions.setTaskAttachments(newAttachments));
  };
  return (
    <Grid item xs={12}>
      <div className={classes.flexContainer}>
        <Tooltip
          placement="top-start"
          arrow
          classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
          title={`${t('notification.createTask.attachments.attachmentsLabel')} ${t(
            'notification.createTask.attachments.fileTypesLabel',
          )}`}
        >
          <label htmlFor="attachment-btn-upload">
            <input
              value=""
              style={{ display: 'none' }}
              id="attachment-btn-upload"
              name="attachment-btn-upload"
              type="file"
              onChange={event => handleTaskAttachment(event)}
            />
            <IconButton color="primary" centerRipple={false} component="span" className={`${classes.attachmentBtn}`} size="small">
              <AttachFileIcon className={classes.attachmentIcon} />
            </IconButton>
          </label>
        </Tooltip>
        <div style={{ width: '24rem' }}>
          {attachments?.length > 0 &&
            attachments.map((attachment, index) => (
              <Chip
                classes={{ icon: classes.fileIcon, label: classes.chipLabel }}
                className={classes.attachmentChip}
                icon={
                  <div>
                    <img
                      src={getAttachmentIcon(attachment?.name?.split('.')?.pop()?.toLowerCase())}
                      alt={t('FileIcon')}
                    />
                  </div>
                }
                label={attachment?.name?.length > 15 ? attachment?.name.substring(0, 15) + ' ...' : attachment?.name}
                deleteIcon={<CloseIcon />}
                onDelete={() => handleDeleteAttachment(index)}
              />
            ))}
        </div>
      </div>
    </Grid>
  );
};
