import { Box, Grid, Skeleton } from 'amn-ui-core';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips';
import { RowSection } from '../../Common/RowSection';
import { useDecision } from '@optimizely/react-sdk';
import { ff_summaryTabIconsUpdate } from 'app/constants/FeatureFlags/Candidate/Keys';
import { useStyles } from './utils';
import { usePromiseTracker } from 'react-promise-tracker';
import { CandidateContainer } from '../../Common/CandidateContainer';
import EducationIcon from 'app/assets/images/IconImages/EducationIcon.svg';
import AddIcon from '@mui/icons-material/Add';
import { theme } from 'styles/global-styles';

function EducationalSummaryUIV2({ groupedSkillSetsList, skillsetsList, handleNavigate, showAllEducation }) {
  const { t } = useTranslation();
  const [summaryTabIconsUpdateDecision] = useDecision(ff_summaryTabIconsUpdate);
  const { classes } = useStyles();
  const { promiseInProgress } = usePromiseTracker({ area: 'get-candidate-details', delay: 0 });

  return (
    <>
      <LayoutGridItem style={{ width: '100%' }} pb="12px">
        <Box className={classes.container}>
          <CandidateContainer
            title={`${t('candidate.educationCard.title')} (all) `}
            icon={<img src={EducationIcon} alt="EducationIcon" />}
            rightIcon={<AddIcon />}
            iconToolTip="Add Education"
            onClick={() => handleNavigate()}
            enableLink={true}
            onClickAll={showAllEducation}
          >
            {promiseInProgress ? (
              <LayoutGridItem mr={2}>
                <Skeleton />
                <Skeleton />
              </LayoutGridItem>
            ) : (
              <LayoutGrid direction="column" spacing={1} className={classes.rowItem}>
                {skillsetsList?.length ? (
                  skillsetsList?.map((item, index) => {
                    if (item.skillset !== '' && item.skillset !== null) {
                      return (
                        <div style={{ marginTop: 1 }}>
                          <CustomTooltip standardMargin tooltipContent={item.tooltipMessage}>
                            <RowSection
                              header={item.skillset}
                              data={''}
                              preData={item.interviewStatus === 2}
                              color={theme.palette.system.darkGreenForIcon}
                            />
                          </CustomTooltip>
                        </div>
                      );
                    }
                  })
                ) : (
                  <RowSection
                    header={
                      <Grid container direction="column">
                        <Grid item>{t('candidate.educationCard.educationUnavailableLine1')}</Grid>
                        <Grid item>{t('candidate.educationCard.educationUnavailableLine2')}</Grid>
                      </Grid>
                    }
                    data={''}
                    preData={''}
                    color={''}
                    showAlert={true}
                    showHalfwayProgress={true}
                    showHalfwayProgressSecondaryIcon={true}
                  />
                )}
                {summaryTabIconsUpdateDecision.enabled && groupedSkillSetsList?.length > 0
                  ? groupedSkillSetsList?.map((item, index) => {
                      return (
                        <div className={classes.rowSectionMargin}>
                          <CustomTooltip standardMargin tooltipContent={item.tooltipMessage}>
                            <RowSection
                              header={item.skillset}
                              data={''}
                              preData={item.interviewStatus === 2}
                              color={theme.palette.system.darkGreenForIcon}
                              showHalfwayProgress={item.showIncompleteAlert}
                              showHalfwayProgressSecondaryIcon={true}
                              showAlert={item.showAlert}
                            />
                          </CustomTooltip>
                        </div>
                      );
                    })
                  : skillsetsList.length === 0 && (
                      <RowSection
                        header={t('candidate.educationCard.noSkillsets')}
                        data={''}
                        preData={''}
                        color={''}
                        showAlert={true}
                      />
                    )}
              </LayoutGrid>
            )}
          </CandidateContainer>
        </Box>
      </LayoutGridItem>
    </>
  );
}

export default EducationalSummaryUIV2;
