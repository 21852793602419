import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import {
  manuallyDecrementPromiseCounter,
  manuallyIncrementPromiseCounter,
  manuallyResetPromiseCounter,
  trackPromise,
} from 'react-promise-tracker';
import { call, getContext, put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import {
  IUserManagementPayload,
  getUserManagementActions,
  IEmployeeRolesPayload,
  IUpdateRoleLinkPayload,
  IUpdateAssociationkPayload,
  IUserDetailPayload,
  IDetailsOptionsPayload,
  ICreateUserPayload
} from './userManagement.redux';
import { SharedService } from 'app/services/SharedServices/shared-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { selectEmpInfo } from './userManagement.selector';
import { getSearchFilterOptions } from 'app/services/SharedServices/SharedServices';
import { IUserDetailsUpdatePayload, IUserFieldValidationError, getUserInformationFields, getValidationError } from '../Helper';

const TrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'user-search-lists');
const updateTrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'update-user-role-link');
const fetchEmployeeAssociationTypeOptionsTrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'fetch-Employee-AssociationType-Options');
const EmpTrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'emp-search-roles');
const GetEmpTrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'emp-Association');
const updateAssociationTrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'update-user-association');
const GetUserDetailTrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'user-detail-card');
const CreateNewUserTrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'create-user');
const updateUserInformationTrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'update-user-information');

export function* getUserSearchList(action: PayloadAction<IUserManagementPayload>) {
  try {
    manuallyIncrementPromiseCounter('user-search-card');
    const requestBody = {
      firstName: action.payload.firstName,
      lastName: action.payload.lastName,
      userName: action.payload.userName,
      statusId: action.payload.statusId,
      employeeId: action.payload.employeeId,
      employeeRoleId: action.payload.employeeRoleId,
      companyId: action.payload.companyId,
      pageNumber: action.payload.pageNumber,
      pageSize: action.payload.pageSize,
    };

    const sharedService: SharedService = yield getContext('sharedService');
    const response = yield call(TrackWrapper, sharedService.getUserLists, requestBody);
    if (response) {
      yield put(
        getUserManagementActions.setData({
          items: response.data.items,
          count: response.data?.paging.total,
        }),
      );
      manuallyDecrementPromiseCounter('user-search-card');
    }
    manuallyDecrementPromiseCounter('user-search-card');
  } catch (error) {
    manuallyResetPromiseCounter('user-search-card');
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getUserLists',
        area: 'src/app/components/Settings/UserManagement/store/userManagement.saga.ts',
      },
    });
  }
}

export function* getEmpRoles(action: PayloadAction<IUserManagementPayload>) {
  try {
    manuallyIncrementPromiseCounter('user-get-role');

    const employeeId = action.payload.employeeId;

    const sharedService: SharedService = yield getContext('sharedService');

    const response = yield call(EmpTrackWrapper, sharedService.getEmployeeRoles, employeeId);

    if (response) {
      yield put(getUserManagementActions.setEmpRoles(response.data));
      manuallyDecrementPromiseCounter('user-get-role');
    }
    manuallyDecrementPromiseCounter('user-get-role');
  } catch (error) {
    manuallyResetPromiseCounter('user-get-role');
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getEmpRoles',
        area: 'src/app/components/Settings/UserManagement/store/userManagement.saga.ts',
      },
    });
  }
}
export function* updateUserRoleLink(action: PayloadAction<IUpdateRoleLinkPayload>) {
  try {
    manuallyIncrementPromiseCounter('');
    const requestBody = {
      employeeId: action.payload.employeeId,
      roleIds: action.payload.roleIds,
    };
    const sharedService: SharedService = yield getContext('sharedService');
    const response = yield call(updateTrackWrapper, sharedService.updateUserRoles, requestBody);
    if (response && httpSuccess(response.status)) {
      const empInfo = yield select(selectEmpInfo);
      yield put(getUserManagementActions.getEmpRoles({ employeeId: empInfo?.employeeId }));
      yield put(
        globalActions.setSnackBar({
          message: 'User Role has been updated successfully',
          severity: 'success',
        }),
      );
    } else {
      yield put(
        globalActions.setSnackBar({
          message: 'ERROR: could not update User Role',
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'updateUserRoles',
        area: 'src/app/components/Settings/UserManagement/store/userManagement.saga.ts',
      },
    });
  }
};

export function* updateAmieUserInformation(action: PayloadAction<IUserDetailsUpdatePayload>) {
  try {
    const sharedService: SharedService = yield getContext('sharedService');
    const response = yield call(updateUserInformationTrackWrapper, sharedService.updateUserInformation, action.payload);
    if (response.data.success && httpSuccess(response.status)) {
      yield put(
        globalActions.setSnackBar({
          message: 'User Details has been updated Successfully',
          severity: 'success',
        }),
      );
      yield put(getUserManagementActions.getUserDetail({ employeeIds: [response.data.employeeId] }))
    } else {
      const validationErrors: Array<IUserFieldValidationError> = getValidationError(response.data.responseCode);
      yield put(getUserManagementActions.setUpdateUserInfoErrorFields(validationErrors));
      yield put(
        globalActions.setSnackBar({
          message: response.data.message.join(` and `),
          severity: 'error',
        })
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'updateUserInformation',
        area: 'src/app/components/Settings/UserManagement/store/userManagement.saga.ts',
      },
    });
    yield put(
      globalActions.setSnackBar({
        message: `ERROR: One or more validation errors occured.`,
        severity: 'error',
      }),
    );
  }
};

export function* createNewAmieUser(action: PayloadAction<ICreateUserPayload>) {
  try {
    const sharedService: SharedService = yield getContext('sharedService');
    const response = yield call(CreateNewUserTrackWrapper, sharedService.createNewUser, action.payload);
    if (response.data.success && httpSuccess(response.status)) {
      yield put(
        globalActions.setSnackBar({
          message: 'User Created successfully',
          severity: 'success',
        }),
      );
      yield put(getUserManagementActions.toggleCreateUserModal(false));
    } else {
      const validationErrors: Array<IUserFieldValidationError> = getValidationError(response.data.responseCode);
      yield put(getUserManagementActions.setCreateUserErrorFields(validationErrors));
      yield put(
        globalActions.setSnackBar({
          message: response.data.message.join(` and `),
          severity: 'error',
        })
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'createNewAmieUser',
        area: 'src/app/components/Settings/UserManagement/store/userManagement.saga.ts',
      },
    });
    yield put(
      globalActions.setSnackBar({
        message: `ERROR: One or more validation errors occured.`,
        severity: 'error',
      }),
    );
  }
};

export function* fetchEmployeeAssociationTypeOptions() {
  try {
    const sharedService: SharedService = yield getContext('sharedService');
    const data = yield call(fetchEmployeeAssociationTypeOptionsTrackWrapper,
      sharedService.getEmployeeAssociationTypeOptions);
    yield put(getUserManagementActions.setEmployeeAssociationTypeOptions(data));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getEmployeeAssociationType',
        area: 'src/app/components/Settings/UserManagement/store/userManagement.saga.ts',
      },
    });
  }
};

export function* fetchEmpAssociations(action: PayloadAction<IEmployeeRolesPayload>) {
  try {
    const employeeId = action.payload.employeeId;

    const sharedService: SharedService = yield getContext('sharedService');

    const response = yield call(GetEmpTrackWrapper, sharedService.getEmployeeAssociations, employeeId);

    if (response) {
      yield put(getUserManagementActions.setemployeeAssociations(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getEmployeeAssociations',
        area: 'src/app/components/Settings/UserManagement/store/userManagement.saga.ts',
      },
    });
  }
}

export function* updateUserAssociation(action: PayloadAction<IUpdateAssociationkPayload>) {
  try {
    const requestBody = {
      employeeId: action.payload.employeeId,
      associations: action.payload.associations,
    };

    const sharedService: SharedService = yield getContext('sharedService');
    const response = yield call(updateAssociationTrackWrapper, sharedService.updateAssociations, requestBody);
    if (response && httpSuccess(response.status)) {
      const empInfo = yield select(selectEmpInfo);
      yield put(getUserManagementActions.employeeAssociations({ employeeId: empInfo?.employeeId }));
      const res = response.data;
      if (res.success) {
        yield put(
          globalActions.setSnackBar({
            message: res.message,
            severity: 'success',
          }),
        );
      } else {
        yield put(
          globalActions.setSnackBar({
            message: res.message,
            severity: 'error',
          }),
        );
      }
    } else {
      yield put(
        globalActions.setSnackBar({
          message: 'ERROR: could not update Association',
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'updateUserAssociation',
        area: 'src/app/components/Settings/UserManagement/store/userManagement.saga.ts',
      },
    });
  }
}

export function* getUserDetails(action: PayloadAction<IUserDetailPayload>) {
  try {
    const requestBody = {
      employeeIds: action.payload.employeeIds,
    };
    const sharedService: SharedService = yield getContext('sharedService');
    const response = yield call(GetUserDetailTrackWrapper, sharedService.getUserDetails, requestBody);
    if (httpSuccess(response.status)) {
      yield put(getUserManagementActions.setUserDetails(response.data));
      yield put(getUserManagementActions.setUserDetailsFields(getUserInformationFields(response.data[0])));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getUserDetails',
        area: 'src/app/components/Settings/UserManagement/store/userManagement.saga.ts',
      },
    });
  }
}

export function* getUserDetailsOptions(action: PayloadAction<IDetailsOptionsPayload>) {
  try {
    const keyword = action.payload.keyword;
    const response = yield call(getSearchFilterOptions, keyword);
    if (response) {
      if (keyword === 'companies') {
        yield put(getUserManagementActions.setCompanyOptions(response));
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getUserDetailsOptions',
        area: 'src/app/components/Settings/UserManagement/store/userManagement.saga.ts',
      },
    });
  }
}

export function* userSearchListSaga() {
  yield takeLatest(getUserManagementActions.getUserSearchLists.type, getUserSearchList);
  yield takeLatest(getUserManagementActions.getEmpRoles.type, getEmpRoles);
  yield takeLatest(getUserManagementActions.updateEmployeeRoles.type, updateUserRoleLink);
  yield takeLatest(getUserManagementActions.employeeAssociationLookups.type, fetchEmployeeAssociationTypeOptions);
  yield takeLatest(getUserManagementActions.employeeAssociations.type, fetchEmpAssociations);
  yield takeLatest(getUserManagementActions.updateEmployeeAssociation.type, updateUserAssociation);
  yield takeLatest(getUserManagementActions.getUserDetail.type, getUserDetails);
  yield takeLatest(getUserManagementActions.createNewUser.type, createNewAmieUser);
  yield takeLatest(getUserManagementActions.updateAmieUserInfo.type, updateAmieUserInformation);
  yield takeEvery(getUserManagementActions.getDetailsOptions.type, getUserDetailsOptions);
}
