import { Typography } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { missingField } from 'app/constants';
import { BoxContainer } from '../../CustomComponents/BoxContainer';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import LaptopIcon from '@mui/icons-material/Laptop';
import { useUnitDetailStyles } from '@AMIEWEB/Unit/UnitDetails/Containers/UnitDetails';
import { selectFacilityDetail, selectIsFacilityByIdRequestSuccess } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { useSelector } from 'react-redux';
import { TechVendorSkeleton } from '../Utils';

export const TechnologyVendor = () => {
  const { t } = useTranslation();
  const { classes } = useUnitDetailStyles();
  const facilityDetailResponse = useSelector(selectFacilityDetail);
  const facilityLoading = useSelector(selectIsFacilityByIdRequestSuccess);


  return (
    <BoxContainer title={t('facility.technologyVendor')} icon={<LaptopIcon fontSize="small" />} rightIcon={false}>
      {facilityLoading ? (
        <TechVendorSkeleton />
      ) : (
        <LayoutGridItem container direction="column" rowSpacing={2}>
          <LayoutGridItem container>
            <LayoutGridItem item>
              <Typography className={classes.textBold}>{t('facility.vendor')}</Typography>
            </LayoutGridItem>
            <LayoutGridItem item ml="24px">
              <Typography sx={{ fontSize: '14px', color: 'system.black' }}>
                {facilityDetailResponse?.technologyVendor ?? missingField}
              </Typography>
            </LayoutGridItem>
          </LayoutGridItem>
          <LayoutGridItem container>
            <LayoutGridItem item>
              <Typography className={classes.textBold}>{t('facility.usage')}</Typography>
            </LayoutGridItem>
            <LayoutGridItem item ml="28px">
              {facilityDetailResponse?.technologyVendorUsage?.length
                ? facilityDetailResponse?.technologyVendorUsage.map((item, index) => (
                  <Typography sx={{ fontSize: '14px', color: 'system.black', display: 'block' }} key={index}>
                    {item.description}
                  </Typography>
                ))
                : missingField}
            </LayoutGridItem>
          </LayoutGridItem>
        </LayoutGridItem>
      )}
    </BoxContainer>
  );
};
