import { Grid, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';

const useFormStyles = makeStyles()({
  filterName: {
    color: '#707070',
    paddingBottom: 4,
  },
  input: {
    height: '37.625px',
  },
});

export interface IFormInput {
  [key: string]: any;
  label: string;
  customClasses?: any;
}

export const FormInput = ({ label, customClasses, ...props }: IFormInput) => {
  const { classes } = useFormStyles();

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Typography variant="body2" className={customClasses ? customClasses : classes.filterName}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.input}>
        <div>{props.children}</div>
      </Grid>
    </Grid>
  );
};
