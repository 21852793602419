/* eslint-disable jsx-a11y/no-autofocus */
import { IconButton, TextField } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles()(theme => ({
  headerSearchField: {
    height: '32px',
    width: '280px',
    paddingRight: '0px',
  },
  searchField: {
    height: '32px',
    width: 'inherit',
  },
  searchInput: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 12,
    borderColor: '#52429A',
  },
  color: {
    color: '#52429A',
  },
  greyColor: {
    color: '#a0a0a0',
  },
  errorColor: {
    color: '#d32f2f',
  },
}));

export const SearchField = props => {
  const { classes, cx } = useStyles();
  const {
    name,
    placeholder,
    inheritWidth,
    adornAtStart = false,
    autoFocus = undefined,
    error = false,
    value,
    onChange,
    inputRef,
    onKeyPress,
  } = props;

  return (
    <TextField
      onKeyPress={onKeyPress}
      inputRef={inputRef}
      variant="outlined"
      placeholder={placeholder}
      id="active"
      name={name}
      autoFocus={autoFocus}
      autoComplete="off"
      onChange={onChange}
      classes={{ root: classes.searchField }}
      error={error}
      value={value}
      InputProps={{
        className: inheritWidth ? classes.searchField : classes.headerSearchField,
        classes: {
          input: cx(classes.searchInput, {
            colorClass: !error,
            [classes.errorColor]: error,
          }),
        },
        endAdornment: !adornAtStart && (
          <IconButton color="primary" type="submit" disableTouchRipple disableFocusRipple style={{ background: 'none' }} size="small">
            <SearchIcon
              className={cx({
                [classes.errorColor]: error,
              })}
            />
          </IconButton>
        ),
        startAdornment: adornAtStart && <SearchIcon style={{ opacity: 0.6 }} />,
      }}
    />
  );
};
