import React from 'react';
import { Grid, Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { differenceInCalendarMonths } from 'date-fns';
import Month from './Month';
import { DateRange, DefinedRange, Setter, NavigationAction } from '../types';
import { MARKERS } from './DateRangePicker';

const useStyles = makeStyles()((theme: Theme) => ({
  divider: {
    borderLeft: `1px solid #CCCCCC`,
  },
  picker: {
    borderRadius: '5%',
    border: '1px solid #CCCCCC',
    boxShadow: '0px 3px 6px #00000029',
    width: 'fit-Content',
  },
}));

interface MenuProps {
  dateRange: DateRange;
  ranges: DefinedRange[];
  minDate: Date;
  maxDate: Date;
  firstMonth: Date;
  secondMonth: Date;
  setFirstMonth: Setter<Date>;
  setSecondMonth: Setter<Date>;
  setDateRange: Setter<DateRange>;
  helpers: {
    inHoverRange: (day: Date) => boolean;
  };
  handlers: {
    onDayClick: (day: Date) => void;
    onDayHover: (day: Date) => void;
    onMonthNavigate: (marker: symbol, action: NavigationAction) => void;
  };
}

const Menu: React.FunctionComponent<MenuProps> = (props: MenuProps) => {
  const { classes } = useStyles();

  const {
    dateRange,
    minDate,
    maxDate,
    firstMonth,
    setFirstMonth,
    secondMonth,
    setSecondMonth,
    helpers,
    handlers,
  } = props;

  const canNavigateCloser = differenceInCalendarMonths(secondMonth, firstMonth) >= 2;
  const commonProps = {
    dateRange,
    minDate,
    maxDate,
    helpers,
    handlers,
  };
  return (
    <Grid container direction="row" wrap="nowrap" className={classes.picker}>
      <Month
        {...commonProps}
        value={firstMonth}
        setValue={setFirstMonth}
        navState={[true, canNavigateCloser]}
        marker={MARKERS.FIRST_MONTH}
      />
      <div className={classes.divider} />
      <Month
        {...commonProps}
        value={secondMonth}
        setValue={setSecondMonth}
        navState={[canNavigateCloser, true]}
        marker={MARKERS.SECOND_MONTH}
      />
    </Grid>
  );
};

export default Menu;
