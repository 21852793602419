import { CustomTooltip } from '@AMIEWEB/Common/Tooltips/CustomTooltip';
import {
  selectSkillsSetsForUnits,
  selectUnitsData,
} from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';
import { Avatar, Box } from 'amn-ui-core';
import { selectUser } from 'oidc/user.selectors';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { missingField } from 'app/constants';
import { StatusDropDownValues } from '@AMIEWEB/Common/StatusDropDown/StatusDropDown';

export function useIsUserAccountManager() {
  const userData = useSelector(selectUser);

  return Authorized(
    [userRoles.accountManagement, userRoles.accountManagement_Leadership, userRoles.accountManagement_TeamMember],
    userData.userInfo,
  );
}

export const isAuthorizedForAddEdit = userData => {
  return Authorized(authorizedRolesForAddEditUnit, userData.userInfo);
};

export const authorizedRolesForAddEditUnit = [
  userRoles.accountManagement,
  userRoles.accountManagement_TeamMember,
  userRoles.accountManagement_Leadership,
  userRoles.clinical_ClientContractSpecialist,
  userRoles.billingSpecialist,
  userRoles.candidate_Contracts_Leadership,
];

export const ModalDialogBox = ({ data }: { data: string }) => {
  return (
    <Box
      p={6}
      sx={{
        padding: '24px',
        fontSize: '14px',
        height: '100%',
        maxHeight: '80vh',
        overflowY: 'scroll',
        whiteSpace: 'pre-line',
      }}
    >
      {data ?? missingField}
    </Box>
  );
};

export const StatusIndicator = ({
  isRequired,
  hideToolTip = false,
}: {
  isRequired: boolean;
  hideToolTip?: boolean;
}) => {
  return (
    <div style={{ width: '24px' }}>
      <CustomTooltip tooltipContent={isRequired ? 'Required' : 'Preferred'} hideToolTip={hideToolTip}>
        <Avatar
          variant={isRequired ? 'primary' : 'green'}
          sx={theme => ({
            fontWeight: '400',
            width: '24px',
            height: '24px',
            color: theme.palette.components.avatar.color,
            fontSize: '14px !important',
          })}
        >
          {isRequired ? 'R' : 'P'}
        </Avatar>
      </CustomTooltip>
    </div>
  );
};

export function useSelectedUnitDetails() {
  const unitsSourceData = useSelector(selectUnitsData);
  const params = useParams<{ id: string; unitId: string }>();
  return unitsSourceData?.find(unit => unit.unitId === Number(params.unitId) && unit.facilityId === Number(params.id));
}

export function useSelectedUnitQualifications() {
  const selectedUnitDetails = useSelectedUnitDetails();
  return selectedUnitDetails?.qualification;
}

export function useSelectedUnitSkillSetGroups() {
  const skillsetData = useSelector(selectSkillsSetsForUnits);
  return skillsetData?.groups || [];
}

export const getTextForGapGreaterThan = (selectedUnitDetail, t) => {
  const withinTheLastDays = selectedUnitDetail?.withinTheLastDays;
  const withinTheLastYears = selectedUnitDetail?.withinTheLastYears ?? 0;
  if (selectedUnitDetail?.entireWorkHistory) {
    return withinTheLastDays === 1
      ? t('facility.units.workHistory.oneDayWithEntireWorkHistory')
      : t('facility.units.workHistory.entireWorkHistory', { withinTheLastDays: withinTheLastDays });
  } else if (withinTheLastDays === 1) {
    return withinTheLastYears === 1
      ? t('facility.units.workHistory.oneDayWithinTheLastYear')
      : t('facility.units.workHistory.oneDayWithinTheLastYears', { withinTheLastYears: withinTheLastYears });
  } else {
    return withinTheLastYears === 1
      ? t('facility.units.workHistory.withinTheLastYear', {
          withinTheLastDays: withinTheLastDays,
        })
      : t('facility.units.workHistory.withinTheLastYears', {
          withinTheLastDays: withinTheLastDays,
          withinTheLastYears: withinTheLastYears,
        });
  }
};

export const getWorkHistoryGapMessage = (selectedUnitDetail, t) => {
  const withinTheLastDays = selectedUnitDetail?.withinTheLastDays;
  const withinTheLastYears = selectedUnitDetail?.withinTheLastYears;
  const entireWorkHistory = selectedUnitDetail?.entireWorkHistory;
  if (entireWorkHistory) {
    if (withinTheLastDays == null) {
      return (
        t('facility.units.workHistory.gapGreaterThan') +
        ' ' +
        t('facility.units.workHistory.entireWorkHistory', { withinTheLastDays: missingField })
      );
    } else if (withinTheLastDays >= 0) {
      return (
        t('facility.units.workHistory.gapGreaterThan') +
        ' ' +
        t('facility.units.workHistory.entireWorkHistory', { withinTheLastDays })
      );
    }
  } else {
    if (
      (withinTheLastDays == null && withinTheLastYears == null) ||
      (withinTheLastDays >= 0 && withinTheLastYears == null)
    ) {
      return missingField;
    } else if (withinTheLastDays == null && withinTheLastYears > 0) {
      return (
        t('facility.units.workHistory.gapGreaterThan') +
        ' ' +
        t('facility.units.workHistory.withinTheLastYears', {
          withinTheLastDays: missingField,
          withinTheLastYears,
        })
      );
    } else if (withinTheLastYears > 0 && withinTheLastDays >= 0) {
      return (
        t('facility.units.workHistory.gapGreaterThan') +
        ' ' +
        t('facility.units.workHistory.withinTheLastYears', { withinTheLastDays, withinTheLastYears })
      );
    }
  }
};

export const getBestBets = (bestBets, bestBetsId: number) => {
  const bets = bestBets.find(x => x.value === bestBetsId?.toString());
  return bets?.name;
};

export function getResultString(value: boolean | null) {
  switch (value) {
    case true:
      return 'Yes';
    case false:
      return 'No';
    case null:
    case undefined:
      return missingField;
    default:
      return '';
  }
}

export function useUnitStatusInActive() {
  const unitsSourceData = useSelector(selectUnitsData);
  const params = useParams<{ id: string; unitId: string }>();
  const unitStatus = unitsSourceData?.find(
    unit => unit.unitId === Number(params.unitId) && unit.facilityId === Number(params.id),
  )?.status;
  return unitStatus === StatusDropDownValues.INACTIVE;
}
