import { Box, Grid, IconButton, Typography } from 'amn-ui-core';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  container: {
    height: '100%',
    background: '#F7F7F7 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    boxShadow: '0px 3px 6px #00000029',
    padding: 12,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
  },
}));

export const Sidebar = ({ title, ...props }) => {
  const { classes } = useStyles();
  return (
    <Grid container direction="column" classes={{ container: classes.container }}>
      <Grid item>
        <Box className={classes.titleContainer}>
          <Typography variant="h6">{title}</Typography>
          <IconButton
            color="primary"
            size="small"
            sx={{
              padding: '0px',
            }}
            onClick={props.onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Grid>
      <Grid item>{props.children}</Grid>
    </Grid>
  );
};
