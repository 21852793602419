import { Grid } from 'amn-ui-core';
import { convertToFormat } from 'app/helpers/dateHelper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Concatenate } from 'utils/string/string';
import { KeyValueRow } from '../TableComponents/KeyValueTable/KeyValueRow';
import styledComponent from 'styled-components';
import { colors } from 'styles/styleVariables';
import { missingField } from 'app/constants';
import { ExtensionEditWrapper, ExtEditTarget } from './ExtensionEditIcon';
import OrangeWarning from 'app/assets/images/IconImages/OrangeWarningIcon.svg';

export interface IExtTimeOffItem {
  startDate: string;
  endDate: string;
  dailyCost: number;
  placementTimeOffPayerId: number;
  placementTimeOffPayer: string;
  facilityApprovedBy: string;
  days: number;
  hours: number;
}
export const ExtTimeOffRow: React.FC<{
  extContainerIndex: number;
  extensionStatusId: number;
  timeOff: IExtTimeOffItem[];
  timeOffReason: number;
}> = ({ extContainerIndex, extensionStatusId, timeOff, timeOffReason }) => {
  const { t } = useTranslation();

  const content = React.useMemo(
    () =>
      !!timeOff.length ? (
        <Grid container direction="column" spacing={2}>
          {timeOff.map((item, indx) => (
            <Grid key={indx.toString()} item>
              <TimeOffUnit {...item} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container>
          {timeOffReason === 2 ? (
            t('placement.profile.summary.extension.timeOff.notRequired')
          ) : timeOffReason === 3 ? (
            <>
              <Grid item>
                {<img src={OrangeWarning} alt="Warn" style={{ marginRight: '6px', width: '16px', height: '16px' }} />}
              </Grid>
              <span>{t('placement.profile.summary.extension.timeOff.toBeDetermined')}</span>
            </>
          ) : (
            missingField
          )}
        </Grid>
      ),
    [timeOff],
  );

  return (
    <KeyValueRow
      id="placement-ext-status-row"
      rowTitle={t('placement.profile.summary.extension.timeOff.title')}
      loading={false}
    >
      <ExtensionEditWrapper
        target={ExtEditTarget.timeOff}
        extContainerIndex={extContainerIndex}
        extensionStatusId={extensionStatusId}
      >
        {content}
      </ExtensionEditWrapper>
    </KeyValueRow>
  );
};

const TimeOffUnit: React.FC<IExtTimeOffItem> = ({
  startDate,
  endDate,
  days,
  hours,
  dailyCost,
  placementTimeOffPayer,
  facilityApprovedBy,
}) => {
  const { t } = useTranslation();

  const dateString = React.useMemo(() => {
    const postDaysLabel =
      days !== 1 ? t('placement.profile.summary.extension.days') : t('placement.profile.summary.extension.day');
    return Concatenate(
      [
        days.toString(),
        postDaysLabel,
        `(${hours}h):`,
        Concatenate([convertToFormat(startDate, 'MM/DD/yyyy'), convertToFormat(endDate, 'MM/DD/yyyy')], ' - '),
      ],
      ' ',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days, hours, endDate, startDate]);

  return (
    <table style={{ width: '100%' }}>
      <tbody style={{ width: '100%' }}>
        <tr>
          <RowHead>{t('placement.profile.summary.extension.dates')}</RowHead>
          <RowContent>{dateString}</RowContent>
        </tr>
        <tr>
          <RowHead>{t('placement.profile.summary.extension.dailyCost')}</RowHead>
          <RowContent>{dailyCost ? `${t('currency.dollar')}${dailyCost}` : missingField}</RowContent>
        </tr>
        <tr>
          <RowHead>{t('placement.profile.summary.extension.payer')}</RowHead>
          <RowContent>{placementTimeOffPayer ?? missingField}</RowContent>
        </tr>
        <tr>
          <RowHead>{t('placement.profile.summary.extension.facilityApprover')}</RowHead>
          <RowContent>{facilityApprovedBy ?? missingField}</RowContent>
        </tr>
      </tbody>
    </table>
  );
};

export const RowHead = styledComponent.th`
  color: ${colors.darkText};
  font-size: 12px;
  text-align: start;
  width: 25%;
  font-weight: 450;
`;

export const RowContent = styledComponent.td`
  color: ${colors.darkText};
  font-size: 14px;
  text-align: start;
`;
