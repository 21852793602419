import { ICustomHeader } from 'app/models/Grid';

interface IGridColumns extends Omit<ICustomHeader, 'title'> {
  title:
    | 'facilityName'
    | 'city'
    | 'state'
    | 'zip'
    | 'country'
    | 'unit'
    | 'discipline'
    | 'unitSpecialty'
    | 'experience'
    | 'startDate'
    | 'endDate'
    | 'references'
    | 'travelCompany'
    | 'localStaffingAgency'
    | 'teachingHospital'
    | 'numberOfBedsInFacility'
    | 'currentlyEmployed'
    | 'placementID'
    | 'positionHeld'
    | 'reasonForLeaving';
}

export const getEmploymentHistoryHeaders = t =>
  [
    {
      value: t('candidate.employmentHistoryHeaders.facilityName'),
      title: 'facilityName',
      sortable: true,
      sortByField: 'facilityNameSort',
    },
    { value: t('candidate.employmentHistoryHeaders.city'), title: 'city', sortable: true },
    { value: t('candidate.employmentHistoryHeaders.state'), title: 'state', sortable: true },
    { value: t('candidate.employmentHistoryHeaders.zip'), title: 'zip', sortable: true, isNumber: true },
    { value: t('candidate.employmentHistoryHeaders.country'), title: 'country', sortable: true },
    { value: t('candidate.employmentHistoryHeaders.unit'), title: 'unit', sortable: true },
    { value: t('candidate.employmentHistoryHeaders.discipline'), title: 'discipline', sortable: true },
    { value: t('candidate.employmentHistoryHeaders.unitSpecialty'), title: 'unitSpecialty', sortable: true },
    {
      value: t('candidate.employmentHistoryHeaders.experience'),
      title: 'experience',
      sortable: true,
      sortByField: 'totalMonthsOfExperience',
    },
    {
      value: t('candidate.employmentHistoryHeaders.startDate'),
      title: 'startDate',
      sortable: true,
      sortByField: 'startDateNoFormat',
    },
    {
      value: t('candidate.employmentHistoryHeaders.endDate'),
      title: 'endDate',
      sortable: true,
      sortByField: 'endDateNoFormat',
    },
    { value: t('candidate.employmentHistoryHeaders.references'), title: 'references', sortable: true, isNumber: true },
    { value: t('candidate.employmentHistoryHeaders.travelCompany'), title: 'travelCompany', sortable: true },
    {
      value: t('candidate.employmentHistoryHeaders.localStaffingAgency'),
      title: 'localStaffingAgency',
      sortable: true,
    },
    { value: t('candidate.employmentHistoryHeaders.teachingHospital'), title: 'teachingHospital', sortable: true },
    {
      value: t('candidate.employmentHistoryHeaders.numberOfBeds'),
      title: 'numberOfBedsInFacility',
      sortable: true,
      isNumber: true,
    },
    { value: t('candidate.employmentHistoryHeaders.currentlyEmployed'), title: 'currentlyEmployed', sortable: true },
    {
      value: t('candidate.employmentHistoryHeaders.placementID'),
      title: 'placementID',
      sortable: true,
      isNumber: true,
    },
    { value: t('candidate.employmentHistoryHeaders.positionHeld'), title: 'positionHeld', sortable: true },
    {
      value: t('candidate.employmentHistoryHeaders.reasonForLeaving'),
      title: 'reasonForLeaving',
      sortable: false,
      hasHoverView: true,
    },
  ] as IGridColumns[];
