import { Button, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { TaskSidePanel } from 'app/components/Tasks/CustomComponents/TaskSidePanel/TaskSidePanel';
import { taskDetailsActions } from 'app/components/Tasks/store/Tasks.redux';

import { TaskSidebarContainer } from 'app/components/Tasks/TaskSidebar/TaskSidebarContainer';
import { ITaskStickChosen, TaskEntity } from 'app/models/Tasks/Tasks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles<{ taskShrink: boolean }>()((theme, { taskShrink }) => ({
  sidePanel: {
    width: taskShrink ? 36 : '297px',
    '& .MuiPaper-root.MuiPaper-elevation1': {
      top: '60px',
      height: '90%',
      zIndex: 99,
    },
  },
}));

export const CandidateHeaderWrapper = ({
  stickIconArray,
  taskStickChosen,
  setOpenCreatePlacementForm,
}: {
  stickIconArray: number[];
  taskStickChosen?: ITaskStickChosen;
  setOpenCreatePlacementForm: (e) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const taskSidePanelItems = [
    {
      title: t('notification.taskSidePanel.taskSidePanelHeading'),
      tooltip: t('notification.taskSidePanel.taskSidePanelHeading'),
      component: <TaskSidebarContainer container={TaskEntity.CANDIDATES} />,
    },
  ];
  const { classes } = useStyles({ taskShrink: taskStickChosen.stick === null });

  const handleTaskStickChoice = stick => {
    dispatch(
      taskDetailsActions.setTaskStickChosen({
        entity: TaskEntity.CANDIDATES,
        stick: stick === taskStickChosen.stick ? null : stick,
      }),
    );
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} wrap="nowrap" sx={{ alignItems: 'center'}}>
        <Grid item style={{ alignSelf: 'center' }}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => setOpenCreatePlacementForm(true)}
          >
            {t('placement.create.create')}
          </Button>
        </Grid>
        <Grid item>
          <div className={classes.sidePanel}>
            {stickIconArray && taskSidePanelItems && (
              <TaskSidePanel
                items={taskSidePanelItems}
                stickChosen={taskStickChosen}
                handleChoice={handleTaskStickChoice}
                iconArray={stickIconArray}
                scrollable={true}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
