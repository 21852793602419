import React, { useCallback } from 'react';
import { Grid, Avatar, Typography } from 'amn-ui-core';
import { getInitials } from '@AMIEWEB/Tasks/CreateTask/FormatData';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectSearchResults } from 'store/redux-store/global-search/selectors';

const Comment = ({ comment: { text, createByFirstName, createByLastName, createDate } }) => {
  const { t } = useTranslation();
  const { drawerData } = useSelector(selectSearchResults);
  const taskId: number = drawerData?.data?.selectedorder?.row?.taskId || 0;

  const getHourDateText = useCallback(() => {
    const formattedDate = moment.tz(createDate, 'America/Los_Angeles');
    if (formattedDate.diff(moment(), 'days') < 0) {
      return formattedDate.format('L hh:mm A');
    } else if (formattedDate.diff(moment(), 'days') === 0) {
      return formattedDate.fromNow();
    } else if (formattedDate.diff(moment(), 'minute') === 0) {
      return t('notification.taskNotification.aFewSecondsAgo');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createDate]);

  return (
    <Grid sx={{ display: 'flex' }} data-testid="task-comment-item-container">
      <Grid xs={0.5}>
        <Avatar
          sx={{
            height: '24px',
            width: '24px',
            backgroundColor: theme => theme.palette.secondary.main,
            fontSize: '12px',
          }}
        >
          {getInitials(`${createByFirstName} ${createByLastName}`)}
        </Avatar>
      </Grid>
      <Grid xs={11} sx={{ paddingLeft: '7px', marginLeft: taskId !== 0 ? '15px' : 'none' }}>
        <Grid container xs={12}>
          <Typography
            color="primary"
            variant="body2"
            fontWeight={'medium'}
          >{`${createByFirstName} ${createByLastName}`}</Typography>
          <Typography variant="body1" color="inherit" noWrap>
            &nbsp;
          </Typography>
          {
            <Typography variant="body2" fontWeight={'medium'}>
              {getHourDateText()}
            </Typography>
          }
        </Grid>
        <Grid container xs={12}>
          <Typography variant="body1">{text}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Comment;
