import { Chip, Theme } from 'amn-ui-core';
import React, { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { makeStyles } from 'tss-react/mui';
import HearingIcon from '@mui/icons-material/Hearing';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { ISkillsetItem, SkillsetDetails, SkillsetType } from './Helpers/SkillsetHelper';
import CloseIcon from '@mui/icons-material/Close';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { CustomTooltip } from '@AMIEWEB/Common';

const useStyles = makeStyles<{ deleteUnverified: boolean; deleteVerified: boolean; skillsetType: string }>()(
  (theme: Theme, { deleteUnverified, deleteVerified, skillsetType }) => ({
    chip: {
      margin: '12px 12px 0px 0px',
      maxWidth: '250px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      background: '#ffffff',
      fontWeight: '400',
      '& .MuiChip-deleteIcon': {
        display: 'none',
      },
      '& #icon': {
        color: 'gray',
      },
      '&:hover': {
        color:
          skillsetType === SkillsetType.unverified
            ? deleteUnverified && 'white !important'
            : deleteVerified && 'white !important',
        background:
          skillsetType === SkillsetType.unverified
            ? deleteUnverified && `${theme.palette.components.chips.secondary.backgroundColor} !important`
            : deleteVerified && `${theme.palette.components.chips.secondary.backgroundColor} !important`,
        '& .MuiChip-deleteIcon': {
          display: skillsetType === SkillsetType.unverified ? deleteUnverified && 'block' : deleteVerified && 'block',
        },
        '& #icon': {
          color: skillsetType === SkillsetType.unverified ? deleteUnverified && 'white' : deleteVerified && 'white',
        },
      },
    },
    icon: {
      height: '18px !important',
      width: '18px !important',
      textAlign: 'center',
      color: 'gray',
    },
    delete: {
      width: '15px',
      height: '15px',
      color: 'white',
    },
  }),
);

interface SkillsetDraggableProps {
  item: ISkillsetItem;
  index: number;
  disabled: boolean;
  onDelete: (skillset: SkillsetDetails) => void;
}

export const SkillsetDraggable = (props: SkillsetDraggableProps) => {
  const { item, index, disabled, onDelete } = props;
  const [deleteVerified, setDeleteVerified] = useState<boolean>(false);
  const [deleteUnverified, setDeleteUnverified] = useState<boolean>(false);
  const user = useSelector(selectUser);
  useEffect(() => {
    setDeleteVerified(
      Authorized(
        [userRoles.clinical_Manager, userRoles.clinical_ReviewAnalyst, userRoles.clinical_Director],
        user.userInfo,
      ),
    );
    setDeleteUnverified(
      Authorized(
        [
          userRoles.recruitment,
          userRoles.recruitment_TeamMember,
          userRoles.recruitment_Leadership,
          userRoles.clinical_Manager,
          userRoles.clinical_ReviewAnalyst,
          userRoles.clinical_Director,
        ],
        user.userInfo,
      ),
    );
  }, [user]);
  let skillsetType = item.skillset.type;
  const { classes } = useStyles({ deleteVerified, deleteUnverified, skillsetType });
  return item.skillset.label.length > 30 ? (
    <CustomTooltip fontWeight="400" tooltipContent={item.skillset.label}>
      <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={disabled}>
        {provided => (
          <Chip
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            variant={'outlined'}
            label={item.skillset.label}
            className={classes.chip}
            onDelete={() => onDelete(item.skillset)}
            avatar={
              item.skillset.type === SkillsetType.unverified ? (
                <HearingIcon id={'icon'} className={classes.icon} />
              ) : (
                <DoneAllIcon id={'icon'} className={classes.icon} />
              )
            }
            deleteIcon={<CloseIcon id={'icon'} className={classes.delete} />}
          />
        )}
      </Draggable>
    </CustomTooltip>
  ) : (
    <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={disabled}>
      {provided => (
        <Chip
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          variant={'outlined'}
          label={item.skillset.label}
          className={classes.chip}
          onDelete={() => onDelete(item.skillset)}
          avatar={
            item.skillset.type === SkillsetType.unverified ? (
              <HearingIcon id={'icon'} className={classes.icon} />
            ) : (
              <DoneAllIcon id={'icon'} className={classes.icon} />
            )
          }
          deleteIcon={<CloseIcon id={'icon'} className={classes.delete} />}
        />
      )}
    </Draggable>
  );
};
