import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Credentials } from 'app/models/Credentialing/Credentials';
import { categoryNameSwap, stringToGridType, stringToGridTypeParent, isSubCategory } from './searchHelpers';
import { MultiGroupTypeAhead } from 'app/components/Common/TypeAheads/MultiGroupTypeAhead';
import { getDropDownDescription } from '../TableData/tableHelpers';
// import RequirementTypes from 'app/assets/jsons/Credentials/requirementTypes.json';
import { useSelector } from 'react-redux';
import { CredentialingLookups, RequirementTypes } from '../../../Profile/CandidateDetails.selector';
interface CredentialsSearchProps {
  credentials?: Credentials | null;
  searchInput: any;
  onSearchInputChange: Function;
  reloadData?: boolean;
  onSelect: (option: any) => void;
  disabled?: boolean;
  onAddSearch?: boolean;
  /** include TypeID and ReqTypeID in list */
  includeTypes?: boolean;
}

export const CredentialsSearch = ({ includeTypes = true, ...props }: CredentialsSearchProps) => {
  const { credentials, reloadData, onSearchInputChange, searchInput, onSelect, onAddSearch = false } = props;
  const [searchDetails, setSearchDetails] = useState<any[]>([]);
  const [searchData, setSearchData] = useState<any[]>([]);
  const [builtData, setBuiltData] = useState<any>([]);
  const { t } = useTranslation();
  const lookupselection = useSelector(CredentialingLookups);
  const requirementTypeselection = useSelector(RequirementTypes);

  const createSearchObject = useCallback((element, category, subCategory) => {
    const req = {
      requirementID: element.requirementID,
      category: category,
      subCategory: subCategory,
      requirementTypeID: element.requirementTypeID,
      abbreviation: element.requirementType,
      credentialName: element.credentialName,
      types: null as any,
      title: `${element.requirementType} - ${element.credentialName}`,
      selectedTitle: `${element.requirementType} - ${element.credentialName}`,
      selectable: true,
    };

    const lookupTypeValue = element.requirementTypeID === 222 ? element.reqTypeID : element.typeID;
    if (lookupTypeValue !== null) {
      const currentLookUps = lookupselection
        ?.filter(item => item.category === element.category)
        .filter(lookup => lookup.requirementTypeID === element.requirementTypeID)
        .sort((a, b) => (a.sequence < b.sequence ? 1 : -1));
      const lookupTypeDescription = getDropDownDescription(
        currentLookUps,
        element.requirementTypeID === 222 ? 'ReqTypeID' : 'TypeID',
        element.requirementTypeID === 222 ? element.reqTypeID : element.typeID,
        true,
      );
      if (includeTypes) {
        req['types'] = [
          {
            value: lookupTypeValue,
            description: lookupTypeDescription,
          },
        ];
      }
      req['title'] = `${element.requirementType} - ${element?.credentialName} ${
        shouldShowType(element) ? `(${lookupTypeDescription})` : ''
      }`;
    }
    return req;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onAddSearch) {
      const lookupList: any[] = [];
      requirementTypeselection?.forEach(item => {
        const req = { ...item };
        const lookupTypes = lookupselection?.find(
          lookup =>
            lookup.requirementTypeID === item.requirementTypeID &&
            (item.requirementTypeID === 222 ? lookup.lookUpField === 'ReqTypeID' : lookup.lookUpField === 'TypeID') &&
            lookup.lookups !== undefined,
        );

        if (lookupTypes !== undefined && includeTypes) req['types'] = lookupTypes.lookups;
        req['title'] = `${item.abbreviation} - ${item.credentialName}`;
        req['selectedTitle'] = `${item.abbreviation} - ${item.credentialName}`;
        req['selectable'] = true;
        lookupList.push(req);
      });
      setSearchData(lookupList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RequirementTypes]);

  useEffect(() => {
    if (credentials !== null) {
      const tmp: any[] = [];
      (credentials?.licenses || []).forEach(element => {
        tmp.push(createSearchObject(element, element.category, null));
      });
      (credentials?.certifications || []).forEach(element => {
        tmp.push(createSearchObject(element, element.category, element.subCategory));
      });
      (credentials?.clinicalQualifications || []).forEach(element => {
        tmp.push(createSearchObject(element, element.category, element.subCategory));
      });
      (credentials?.humanResourcesCredentials || []).forEach(element => {
        tmp.push(createSearchObject(element, element.category, element.subCategory));
      });
      (credentials?.educationOnlineForms || []).forEach(element => {
        tmp.push(createSearchObject(element, element.category, null));
      });
      (credentials?.employeeHealth || []).forEach(element => {
        tmp.push(createSearchObject(element, element.category, element.subCategory));
      });
      (credentials?.miscellaneous || []).forEach(element => {
        tmp.push(createSearchObject(element, element.category, element.subCategory));
      });
      setSearchDetails(tmp);
    }
  }, [credentials, reloadData, createSearchObject, onSelect]);

  const findOption = (list: any[], gridType, newItem, foundItem) => {
    list?.forEach(item => {
      if (item.option?.gridType !== null && item.option?.gridType !== undefined) {
        // has gridType
        if (item.option.gridType === gridType) foundItem = item;
        else if (item.group.length > 0) {
          foundItem = findOption(item.group, gridType, newItem, foundItem);
        }
      }
    });
    return foundItem;
  };

  const createCategorySubCategoryOption = (title, gridType, item, addCredentials, searchTerm, parentGridType) => {
    return {
      option: {
        title: t(categoryNameSwap(title)),
        gridType: gridType,
        parentGridType: parentGridType,
        requirementID: item?.object?.requirementID,
      },
      group: (addCredentials && [addCredentialOption(gridType, item, searchTerm, parentGridType)]) || [],
    };
  };

  const shouldShowType = item => {
    if (
      item?.subCategory === 'Other Certifications' ||
      item?.subCategory === 'Pending Molecule Development' ||
      item?.category === 'Education & Online Exams'
    ) {
      return true;
    } else return false;
  };

  const addCredentialOption = (gridType, item, searchTerm, parentGridType) => {
    const credentialNameSearched = `${item.abbreviation} - ${item.credentialName}`
      .toLowerCase()
      .replace(/\s|\.|\(|\)/g, '')
      .includes(searchTerm.toLowerCase().replace(/\s|\.|\(|\)/g, ''));
    return {
      option: {
        ...item,
        gridType: gridType,
        parentGridType: parentGridType,
      },
      groupTitle:
        item?.requirementTypeID === 222 ? t('search.grid.add.searchTitlePMD') : t('search.grid.add.searchTitle'),
      group:
        (onAddSearch &&
          item.types &&
          shouldShowType(item) &&
          (credentialNameSearched
            ? item.types
            : item.types.filter(type =>
                type.description
                  .toLowerCase()
                  .replace(/\s|\.|\(|\)/g, '')
                  .includes(searchTerm.toLowerCase().replace(/\s|\.|\(|\)/g, '')),
              )
          ).map(type => {
            return {
              option: {
                ...item,
                title: type.description,
                selectedTitle: `${item?.abbreviation} - ${item?.credentialName}`,
                type: type,
                selectable: true,
                gridType: gridType,
                parentGridType: parentGridType,
              },
              group: [],
            };
          })) ||
        [],
    };
  };

  const buildList = (dataSet, searchTerm) => {
    const list: any[] = [];
    dataSet.forEach((item, j) => {
      const parentCategoryID = stringToGridTypeParent(item.category, item.subCategory);
      const subCategoryID = stringToGridType(item.category, item.subCategory);
      const isASubCategory = isSubCategory(item.category, item.subCategory);

      const FOUND = findOption(list, subCategoryID, item, null);
      if (FOUND) {
        // add item here in existing category / sub category
        FOUND.group.push(addCredentialOption(subCategoryID, item, searchTerm, parentCategoryID));
      } else {
        let category;
        let subCategory;
        if (!isASubCategory) {
          // category without any sub categories
          // category doesn't exist, create it
          category = createCategorySubCategoryOption(
            item.category,
            parentCategoryID,
            item,
            true,
            searchTerm,
            parentCategoryID,
          );
          list.push(category);
        } else {
          // this is a sub category. check if the category already exists for this
          // if it doesn't create
          const foundCategoryIndex = list.map(i => i['option']['gridType']).indexOf(parentCategoryID);
          if (foundCategoryIndex === -1) {
            category = createCategorySubCategoryOption(
              item.category,
              parentCategoryID,
              null,
              false,
              searchTerm,
              parentCategoryID,
            );
            list.push(category);
          } else {
            category = list[foundCategoryIndex];
          }

          // create subCategory, since you didn't find your gridType in your sub-category
          const foundSubCategoryIndex = category.group.map(subC => subC['option']['gridType']).indexOf(subCategoryID);
          if (foundSubCategoryIndex === -1) {
            subCategory = createCategorySubCategoryOption(
              item.subCategory,
              subCategoryID,
              item,
              true,
              searchTerm,
              parentCategoryID,
            );
            category.group.push(subCategory);
          }
        }
      }
    });
    return list;
  };

  const _onSelect = option => {
    onSelect(option);
  };

  const onChange = event => {
    onSearchInputChange?.(event.target.value);
    if (event.target.value.length < 2) return;

    const foundItem = (onAddSearch ? searchData : searchDetails).filter(
      item =>
        `${item.abbreviation} - ${item.credentialName}`
          .toLowerCase()
          .replace(/\s|\.|\(|\)/g, '')
          .includes(event.target.value.toLowerCase().replace(/\s|\.|\(|\)/g, '')) ||
        (!!item.types &&
          shouldShowType(item) &&
          item.types
            ?.map(type =>
              type.description
                ?.toLowerCase()
                .replace(/\s|\.|\(|\)/g, '')
                .includes(event.target.value.toLowerCase().replace(/\s|\.|\(|\)/g, '')),
            )
            .indexOf(true) !== -1),
    );
    setBuiltData(buildList(foundItem, event.target.value));
  };

  return (
    <MultiGroupTypeAhead
      placeholder={t('Search')}
      shouldHaveAutoFocus={onAddSearch}
      options={builtData}
      onChange={onChange}
      onSelect={_onSelect}
      value={searchInput}
      hideUntilTyped={onAddSearch}
      historyKey={!onAddSearch && 'Credentials'}
      searchOnCharacterCount={2}
      isInDialog={onAddSearch}
    />
  );
};
