import { Box, Divider } from 'amn-ui-core';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useCallback } from 'react';
import { LinearProgressWithLabel } from '../../Common/LinearProgressWithLabel';
import { Title } from '../../../ProfileTab/Cards/styles';
import { RowSectionTitle } from '../../Common/RowSectionTitle';
import { EditCalendar } from '../../Common/EditCalendar';
import { EditText } from '../../Common/EditText';
import { GenderCard } from '../GenderCard';
import { RowSection } from '../../Common/RowSection';
import { useDecision } from '@optimizely/react-sdk';
import { ff_WorkExperience_Enhancement, ff_summaryTabIconsUpdate } from 'app/constants/FeatureFlags/Candidate/Keys';
import { useTranslation } from 'react-i18next';
import { CandidateNavigation } from '../../CardNavigation';
import { useDispatch, useSelector } from 'react-redux';
import {
  candidateDetailsSelection,
  candidateSaveStatusSelector,
} from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { Info, handleChangeValue, resetDob, resetSsn } from '../helper';
import { useStyles } from './utils';
import { theme } from 'styles/global-styles';
import { validateEmailPhone } from '../../Common/helper';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import moment from 'moment';
import { ICandidateDateOfBirth, ICandidateSSN } from 'app/models/Candidate/CandidateProfile';
import { selectUser } from 'oidc/user.selectors';

function ProfileSummaryUI({
  progresscount,
  filledItemscount,
  totalItems,
  navigate,
  info,
  setInfo,
  isCalendarEditableByUser,
  isCalendarViewableByUser,
  isSSNEditableByUser,
  isSSNViewableByUser,
}) {
  const { t } = useTranslation();
  const [enableSummaryIconsUpdate] = useDecision(ff_summaryTabIconsUpdate);
  const [hasWorkExperienceTab] = useDecision(ff_WorkExperience_Enhancement);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const saveStatus = useSelector(candidateSaveStatusSelector);
  const { classes } = useStyles();
  const userInfo = useSelector(selectUser);
  const dispatch = useDispatch();

  const handleNavigation = () => {
    let optedTab = CandidateNavigation.PersonalInformation;
    if (hasWorkExperienceTab?.enabled) {
      optedTab = optedTab + 1;
    }
    window.history.pushState(null, '', `${window.location.pathname}?tab=${optedTab}`);
    navigate(optedTab);
  };

  const validatePhoneEmailExists = useCallback(
    field => {
      return validateEmailPhone(enableSummaryIconsUpdate, candidateDetails, field);
    },
    [candidateDetails?.primaryEmail, candidateDetails?.phoneNumbers?.length],
  );

  const handleSave = async (field: any, info: Info) => {
    if (field === 'ssn') {
      //create payload
      const candidateSSN: ICandidateSSN = {
        candidateId: candidateDetails?.travelerId,
        brandId: candidateDetails.brandId,
        currentEmployeeId: userInfo.userInfo?.employeeId || 0,
        currentEmployeeName: userInfo.userInfo?.firstName + ' ' + userInfo.userInfo?.lastName,
        ssn:
          info.socialSecurityNumber.length < 10
            ? `${info.socialSecurityNumber.slice(0, 3)}-${info.socialSecurityNumber.slice(
                3,
                5,
              )}-${info.socialSecurityNumber.slice(5, 9)}`
            : info.socialSecurityNumber.replace(/ /g, '-'),
        timestamp: candidateDetails?.ssnTimestamp,
        ignoreTimestamp: false,
      };
      // call api
      dispatch(candidateDetailActions.updateCandidateSSN(candidateSSN));
    } else {
      const candidateDOB: ICandidateDateOfBirth = {
        candidateId: candidateDetails?.travelerId,
        currentEmployeeId: userInfo.userInfo?.employeeId || 0,
        currentEmployeeName: userInfo.userInfo?.firstName + ' ' + userInfo.userInfo?.lastName,
        dob: info.dateOfBirth ? moment(info.dateOfBirth).format('yyyy-MM-DD') : null,
        timestamp: candidateDetails?.ssnTimestamp,
        ignoreTimestamp: false,
      };
      dispatch(candidateDetailActions.updateCandidateDateOfBirth(candidateDOB));
    }
  };

  return (
    <>
      <LayoutGridItem>
        <Box className={classes.container}>
          <Box className={classes.children}>
            {!enableSummaryIconsUpdate?.enabled && (
              <Box style={{ width: '100%' }}>
                <LinearProgressWithLabel
                  value={progresscount}
                  filledItemscount={filledItemscount}
                  totalItems={totalItems}
                />
              </Box>
            )}

            <LayoutGrid direction="column" spacing={1}>
              <Title className={classes.title}>
                <span> {t('candidate.profileSummaryCard.title')}</span>
                {enableSummaryIconsUpdate.enabled && (
                  <span
                    className={classes.profileSummaryInlineLink}
                    onClick={handleNavigation}
                    role="link"
                    onKeyDown={handleNavigation}
                    tabIndex={0}
                  >
                    {t('candidate.profileSummaryCard.all')}
                  </span>
                )}
              </Title>
              <span className={classes.sectionDivider}>&nbsp;</span>
              <Divider />
              <RowSectionTitle
                handleClick={() => {}}
                header={
                  <div className={classes.boxInlineHeadLabelTxt}>
                    {t('candidate.profileSummaryCard.firstSubTitle')}
                    {!enableSummaryIconsUpdate?.enabled && (
                      <span className={classes.profileInlineLink}>{t('candidate.profileSummaryCard.all')}</span>
                    )}
                  </div>
                }
                data={
                  !enableSummaryIconsUpdate?.enabled ? (
                    <span className={classes.profileInlineLink}>{t('candidate.profileSummaryCard.add')}</span>
                  ) : (
                    ''
                  )
                }
              />
              <EditCalendar
                data={info.dateOfBirth}
                handleChangeValue={(property, value) => {
                  handleChangeValue(property, value, info, setInfo);
                }}
                handleSave={field => handleSave(field, info)}
                isEditable={isCalendarEditableByUser}
                isViewable={isCalendarViewableByUser}
                resetDob={() => resetDob(info, setInfo, candidateDetails)}
                color={
                  enableSummaryIconsUpdate?.enabled
                    ? theme.palette.system.darkGreenForIcon
                    : theme.palette.framework.system.navyBlue
                }
                saveStatus={saveStatus?.dobSaveStatus}
              />
              <EditText
                data={info.socialSecurityNumber}
                handleChangeValue={(property, value) => {
                  handleChangeValue(property, value, info, setInfo);
                }}
                handleSave={field => handleSave(field, info)}
                isEditable={isSSNEditableByUser}
                isViewable={isSSNViewableByUser}
                resetSsn={() => resetSsn(info, setInfo, candidateDetails)}
                color={
                  enableSummaryIconsUpdate?.enabled
                    ? theme.palette.system.darkGreenForIcon
                    : theme.palette.framework.system.navyBlue
                }
                saveStatus={saveStatus?.ssnSaveStatus}
              />
              <GenderCard enableSummaryIconsUpdate={enableSummaryIconsUpdate} />
              <span className={classes.sectionDivider}>&nbsp;</span>
              <Divider />
              <span className={classes.sectionDivider}>&nbsp;</span>
              <RowSectionTitle
                handleClick={() => {}}
                header={
                  <div className={classes.boxInlineHeadLabelTxt}>
                    {t('candidate.profileSummaryCard.secondSubTitle')}
                    {!enableSummaryIconsUpdate.enabled && (
                      <span className={classes.profileInlineLink}>{t('candidate.profileSummaryCard.all')}</span>
                    )}
                  </div>
                }
                data={
                  !enableSummaryIconsUpdate?.enabled ? (
                    <span className={classes.profileInlineLink}>{t('candidate.profileSummaryCard.add')}</span>
                  ) : (
                    ''
                  )
                }
              />
              <RowSection
                header={t('candidate.profileSummaryCard.phone')}
                data={''}
                preData={info.phone}
                color={
                  enableSummaryIconsUpdate.enabled
                    ? theme.palette.system.darkGreenForIcon
                    : theme.palette.framework.system.navyBlue
                }
                showAlert={
                  !enableSummaryIconsUpdate?.enabled
                    ? false
                    : candidateDetails?.primaryEmail || candidateDetails?.phoneNumbers?.length
                    ? false
                    : true
                }
                showHalfwayProgress={validatePhoneEmailExists(candidateDetails?.phoneNumbers?.length)}
                showHalfwayProgressSecondaryIcon={enableSummaryIconsUpdate?.enabled && true}
              />
              <RowSection
                header={t('candidate.profileSummaryCard.email')}
                data={''}
                preData={info.email}
                color={
                  enableSummaryIconsUpdate?.enabled
                    ? theme.palette.system.darkGreenForIcon
                    : theme.palette.framework.system.navyBlue
                }
                showAlert={
                  !enableSummaryIconsUpdate?.enabled
                    ? false
                    : candidateDetails?.primaryEmail || candidateDetails?.phoneNumbers?.length
                    ? false
                    : true
                }
                showHalfwayProgress={validatePhoneEmailExists(candidateDetails?.primaryEmail)}
                showHalfwayProgressSecondaryIcon={enableSummaryIconsUpdate?.enabled && true}
              />
              <RowSection
                header={t('candidate.profileSummaryCard.emergencyContact')}
                data={''}
                preData={info.emergencyContact}
                color={
                  enableSummaryIconsUpdate?.enabled
                    ? theme.palette.system.darkGreenForIcon
                    : theme.palette.framework.system.navyBlue
                }
                showHalfwayProgress={enableSummaryIconsUpdate?.enabled && !candidateDetails?.emergencyContact?.phone}
                showHalfwayProgressSecondaryIcon={enableSummaryIconsUpdate?.enabled && true}
              />
              <RowSection
                header={t('candidate.profileSummaryCard.address')}
                data={''}
                preData={info.address}
                color={
                  enableSummaryIconsUpdate?.enabled
                    ? theme.palette.system.darkGreenForIcon
                    : theme.palette.framework.system.navyBlue
                }
                showAlert={
                  !enableSummaryIconsUpdate?.enabled ? false : candidateDetails?.addressData?.length ? false : true
                }
                showHalfwayProgressSecondaryIcon={enableSummaryIconsUpdate?.enabled && true}
              />
            </LayoutGrid>
          </Box>
        </Box>
      </LayoutGridItem>
    </>
  );
}

export default ProfileSummaryUI;
