import { Divider, Typography, Box, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPlacementCandidateRecords,
  selectPlacementModalCategoryData,
} from '../../../../../../store/redux-store/placement-details/selectors';
import { Row, RowContainer, RowHead, RowContent } from '../../styles';
import { getPlacementListIcon } from '../../helper';
import { GetMomentOfDate } from 'utils/dates/moment';
import { CustomGrid } from 'app/components/Common/Grid/Grid';
import { placementDetailsAction } from '../../../../../../store/redux-store/placement-details/slice';
import { CandidatePlacementCategory } from 'app/models/Placement/PlacementDetails';
import { useParams } from 'react-router-dom';
import { modalColumns } from './Helpers/CandidatePlacementUtils';
import { TravelPartner } from '../../Edit/Components/TravelPartner';
import { TravelsWithPartnerStatus } from '../../Edit/Components/TravelsWithPartnerStatus';
import { useFormContext } from 'react-hook-form';
import { SellingPoints } from '../../Edit/Components/SellingPoints';
import { editAccessor, editableFields } from '../../editUtils';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';

const usePlacementSellingStyles = makeStyles()({
  root: {
    whiteSpace: 'pre-wrap',
  },
  iconBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  warningMessage: {
    display: 'contents',
    color: '#333333',
    fontSize: 14,
    fontWeight: 450,
  },
  warningIcon: {
    paddingRight: 6,
  },
  clickable: {
    cursor: 'pointer !important',
    color: '#006FB9 !important',
    fontWeight: 450,
  },
  headerTitle:{
    paddingBottom: '6px !important'
  }
});

export const CandidateSellingPoints = () => {
  const dispatch = useDispatch();
  const { classes, cx } = usePlacementSellingStyles();
  const { t } = useTranslation();
  const params = useParams<{ placementId: string; categoryId: string }>();
  const { candidateSellingPointCounts } = useSelector(selectPlacementCandidateRecords);
  const [openAmnAssignments, setOpenAmnAssignments] = useState<boolean>(false);
  const returnToMspEndOfAssignment = GetMomentOfDate(candidateSellingPointCounts.returnToMspEndOfAssignment);
  const returnToFacilityEndOfAssignment = GetMomentOfDate(candidateSellingPointCounts.returnToFacilityEndOfAssignment);
  const rows: any = useSelector(selectPlacementModalCategoryData);
  const [categoryId, setCategoryId] = useState<any>();

  const { watch } = useFormContext();

  const travelsWithPartnerStatus = watch('travelsWithPartner');
  const travelsWithPartnerAttributes = [
    {
      key: t('placement.profile.tabs.candidateSellingPoints.travelsWithPartnerName'),
      value: <TravelPartner id={`${editAccessor}-${editableFields.travelPartner}`} />,
      inline: true,
    },
  ];
  const returnToMspAttributes = [
    {
      key: t('placement.profile.tabs.candidateSellingPoints.returnToEndOfAssignment'),
      value: returnToMspEndOfAssignment.isValid() ? returnToMspEndOfAssignment.format('MM/DD/YYYY') : '',
    },
    {
      key: t('placement.profile.tabs.candidateSellingPoints.returnToAssignmentLength'),
      value: candidateSellingPointCounts.returnToMspAssignmentLength,
    },
    {
      key: t('placement.profile.tabs.candidateSellingPoints.returnToNumberOfAssignments'),
      value: candidateSellingPointCounts.returnToMspCount,
    },
  ];
  const returnToFacilityAttributes = [
    {
      key: t('placement.profile.tabs.candidateSellingPoints.returnToEndOfAssignment'),
      value: returnToFacilityEndOfAssignment.isValid() ? returnToFacilityEndOfAssignment.format('MM/DD/YYYY') : '',
    },
    {
      key: t('placement.profile.tabs.candidateSellingPoints.returnToAssignmentLength'),
      value: candidateSellingPointCounts.returnToFacilityAssignmentLength,
    },
    {
      key: t('placement.profile.tabs.candidateSellingPoints.returnToNumberOfAssignments'),
      value: candidateSellingPointCounts.returnToFacilityCount,
    },
  ];

  const displayModal = () => {
    return (
      <React.Fragment>
        {categoryId && rows[categoryId] && (
          <GenericDialog
            open={openAmnAssignments}
            maxWidth="lg"
            fullWidth
            dialogTitleProps={{
              text: 'AMN Assignments Complete',
              closeButton: true,
            }}
            onClose={() => {
              setOpenAmnAssignments(prevState => !prevState);
            }}
            variant="blue"
          >
            <CustomGrid
              columns={modalColumns}
              rows={rows[categoryId] as any[]}
              searchTerm={undefined}
              component={''}
              showCount={false}
              disableGutters
              noPadding={true}
              fullWidth
              showPagination={false}
              showViewOptions={false}
              currentPageNumber={1}
              fixedItemsPerPage={1000}
              disablePaper
              centerHoverView
              isInDialog={false}
              stickyTable
              maxHeight={'360px'}
            />
          </GenericDialog>
        )}
      </React.Fragment>
    );
  };

  const onAmnAssignmentToggle = (placementid: number, categoryId: any, count: number) => () => {
    if (count === 0) return;
    setCategoryId(categoryId);
    if (!rows[categoryId]) {
      dispatch(
        placementDetailsAction.getModalCategoryData({
          placementId: placementid,
          categoryId: categoryId,
        }),
      );
    }
    setOpenAmnAssignments(prevState => !prevState);
  };

  const RowSectionClickable = props => {
    return (
      <LayoutGridItem item id={props.id}>
        <RowContainer>
          <Row>
            <RowHead style={{ wordBreak: 'keep-all' }}>{props.header}</RowHead>
            <RowContent
              onClick={onAmnAssignmentToggle(
                parseInt(params.placementId),
                CandidatePlacementCategory[props.header.toLowerCase().replace(/[^a-zA-Z]+/g, '')],
                props.data,
              )}
              className={cx({
                [classes.clickable]: typeof props.data === 'number' && props.data,
              })}
            >
              {props.data}
            </RowContent>
          </Row>
        </RowContainer>
      </LayoutGridItem>
    );
  };

  return (
    <LayoutGrid direction="column">
      <RowSection
        header={t('placement.profile.tabs.candidateSellingPoints.currentAmnTraveler')}
        data={candidateSellingPointCounts.currentAmnTraveler ? 'Yes' : 'No'}
        id={'placementDetailsTabsCandidateSellingPointsCurrentAmnTraveler'}
      />

      <RowSectionClickable
        header={t('placement.profile.tabs.candidateSellingPoints.amnAssignComplete')}
        data={candidateSellingPointCounts.amnAssignmentsComplete}
        className={cx({
          [classes.clickable]: candidateSellingPointCounts.amnAssignmentsComplete > 0,
        })}
        id={'placementDetailsTabsCandidateSellingPointsAmnAssignComplete'}
      />

      <RowSection
        header={t('placement.profile.tabs.candidateSellingPoints.travelsWithPartner')}
        data={<TravelsWithPartnerStatus id={`${editAccessor}-${editableFields.travelsWithPartner}`} />}
        id={'placementDetailsTabsCandidateSellingPointsTravelsWithPartner'}
      >
        {travelsWithPartnerStatus && <RowSubSection data={travelsWithPartnerAttributes} />}
      </RowSection>

      <RowSection
        header={t('placement.profile.tabs.candidateSellingPoints.extensionsTaken')}
        data={'N/A'}
        id={'placementDetailsTabsCandidateSellingPointsExtensionsTaken'}
      />

      <RowSection
        header={t('placement.profile.tabs.candidateSellingPoints.returnToFacility')}
        data={candidateSellingPointCounts.returnToFacility ? 'Yes' : 'No'}
        id={'placementDetailsTabsCandidateSellingPointsReturnToFacility'}
      >
        {candidateSellingPointCounts.returnToFacility && <RowSubSection data={returnToFacilityAttributes} />}
      </RowSection>

      <RowSection
        header={t('placement.profile.tabs.candidateSellingPoints.returnToMsp')}
        data={
          candidateSellingPointCounts.hasMspClient ? (candidateSellingPointCounts.returnToMsp ? 'Yes' : 'No') : 'N/A'
        }
        id={'placementDetailsTabsCandidateSellingPointsReturnToMsp'}
      >
        {candidateSellingPointCounts.hasMspClient && candidateSellingPointCounts.returnToMsp && (
          <RowSubSection data={returnToMspAttributes} />
        )}
      </RowSection>

      <Divider />
      <PlacementSellingPoints
        header={t('placement.profile.tabs.candidateSellingPoints.placementSellingPoints')}
        data={candidateSellingPointCounts.placementSellingPoints}
        id={'placementDetailsTabsCandidateSellingPointsPlacementSellingPoints'}
      />
      {displayModal()}
    </LayoutGrid>
  );
};

export const RowSection: React.FC<{ header: any; data: any; isNormalRow?: boolean; id?: string }> = props => {
  return (
    <LayoutGridItem item id={props.id}>
      <RowContainer>
        <Row>
          <RowHead style={{ wordBreak: 'keep-all' }}>{props.header}</RowHead>
          <RowContent>{props.data}</RowContent>
        </Row>
      </RowContainer>
      {props.children}
    </LayoutGridItem>
  );
};

const useRowSubSectionStyle = makeStyles()({
  container: {
    paddingLeft: 1,
  },
  key: {
    color: '#555555',
    paddingTop: 6,
  },
  value: {
    color: '#333333',
    fontWeight: 500,
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
  },
  inlineValue: {
    paddingLeft: '12px',
  },
});

const RowSubSection: React.FC<{ data: any }> = props => {
  const { classes, cx } = useRowSubSectionStyle();
  return (
    <div className={classes.container}>
      {props.data.map(item => {
        return (
          <Typography
            variant="body2"
            className={cx({ [classes.inline]: item.inline })}
            classes={{ body2: classes.key }}
          >
            {item.key}
            {': '}
            <b className={cx(classes.value, { [classes.inlineValue]: item.inline })}>{item.value}</b>
          </Typography>
        );
      })}
    </div>
  );
};

const PlacementSellingPoints = props => {
  const { t } = useTranslation();
  const { classes } = usePlacementSellingStyles();

  return (
    <LayoutGridItem>
      {props.data ? (
        <RowContainer>
          <Row>
            <RowContent className={classes.headerTitle}>{props.header}</RowContent>
          </Row>
          <Typography variant="body1" classes={{ body1: classes.root }}>
            <SellingPoints id={`${editAccessor}-${editableFields.sellingPoints}`} />
          </Typography>
        </RowContainer>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className={classes.iconBox}>
              <Box className={classes.warningIcon} aria-label="warning-icon">
                {getPlacementListIcon(-1)}
              </Box>
              <Box className={classes.warningMessage}>
                {t('placement.profile.tabs.candidateSellingPoints.placementSellingPointsNotAvail')}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" classes={{ body1: classes.root }}>
              <SellingPoints id={`${editAccessor}-${editableFields.sellingPoints}`} />
            </Typography>
          </Grid>
        </Grid>
      )}
    </LayoutGridItem>
  );
};
