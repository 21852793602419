import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { getTravelExperience } from 'app/components/Unit/helper';
import { TravelExperienceType } from 'app/models/Unit/CreateUnit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InnerIconValueSection } from '../../../OrderCreationForm/DefineUnit/UnitPreview/Common/InnerIconValueSection';
import { InnerValueSection } from '../../../OrderCreationForm/DefineUnit/UnitPreview/Common/InnerValueSection';
import EditIcon from '@mui/icons-material/Edit';
import {
  PreviewContent,
  PreviewContentTitle,
} from '../../../OrderCreationForm/DefineUnit/UnitPreview/UnitPreviewDesign';

interface Props {
  data: {
    travelExperience: TravelExperienceType | null | undefined;
  };
  disableEdit?: boolean;
  handleEditOrder?: () => void;
}

const useStyles = makeStyles()({
  container: {
    '&:hover': {
      '& [data-id="edit-icon"]': {
        opacity: 1,
        transition: 'opacity 0.25s',
      },
    },
  },
  editItem: {
    opacity: 0,
    transition: 'opacity 0.25s',
  },
  editIcon: {
    height: '20px',
    width: '20px',
    cursor: 'pointer',
    color: '#8E9195',
  },
});

export const OrderDetailsTravel = (props: Props) => {
  const { travelExperience } = props.data;
  const { handleEditOrder, disableEdit } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <PreviewContent variant="outlined" elevation={0}>
          <Grid container direction="column" spacing={3} className={classes.container}>
            <Grid item>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <PreviewContentTitle>{t('order.createUnit.travelExperience.title')}</PreviewContentTitle>
                </Grid>
                <Grid item className={classes.editItem} data-id="edit-icon">
                  {!disableEdit ? (
                    <EditIcon
                      className={classes.editIcon}
                      id={'OrderDetailsTravelExperienceEditTrigger'}
                      onClick={handleEditOrder}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {travelExperience && travelExperience?.isRequired !== null && (
              <>
                <InnerIconValueSection
                  id={'OrderDetailsTravelExperienceIsRequiredLbl'}
                  isRequired={travelExperience.isRequired}
                />
                <Grid item>
                  <InnerValueSection
                    id={'OrderDetailsTravelExperienceSection'}
                    contentValue={getTravelExperience(travelExperience)}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </PreviewContent>
      </Grid>
    </>
  );
};
