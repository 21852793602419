
import { GridColDef } from '@mui/x-data-grid-pro';
import { Radio, Tooltip } from 'amn-ui-core';
import { missingField } from 'app/constants';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import React from 'react';

export const defaultFormValues = {
  pType: null,
  pCategory: null,
  pSubCategory: null,
  pActionable: false,
  pGroup: null,
  pStatus: null,
  pHoldFileOut: false,
  pAction: null,
  pPrimaryResponsibleParty: null,
  pSecondaryResponsibleParty: [],
  pDescription: null,
  isPlacementRequired: true,
  isSubcategoryRequired: false,
  primaryResponsibleChipValues: [],
  pPlacementID: null,
};

export const defaultAuditPlacementFilters = {
  startDate: [],
  endDate: [],
  placementID: [],
  placementStatus: [],
  facilityName: [],
  unit: [],
  assignedClinicalLiasionForFacility: [],
};

export const placementFilterLabels = {
  startDate: 'Start Date',
  startDateFull: 'Start Date',
  endDate: 'End Date',
  endDateFull: 'End Date',
  placementID: 'PID',
  status: 'Placement Status',
  facility: 'Facility',
  unit: 'Unit',
  clinicalManager: 'Placement Clinical Manager',
};

export enum CategoryTypes {
  workplace_accommodation = 'workplace accommodation',
  integration = 'integration',
  ncs = 'ncs (no subcategory required)',
}

export enum StatusTypes {
  ongoing = 'Ongoing',
}

export enum AuditActions {
  need_management_review = 'Need management review',
}

export enum PrimaryResponsibleValues {
  hospital_account_manager = 'Hospital Account Manager',
}

export enum AuditPlacementFilterName {
  startDate = 'startDate',
  startDateFull = 'startDateFull',
  endDate = 'endDate',
  endDateFull = 'endDateFull',
  placementID = 'placementID',
  status = 'status',
  facility = 'facility',
  unit = 'unit',
  clinicalManager = 'clinicalManager',
}

export enum AuditPlacementStatusCategory {
  offer = 'Offer',
  submission = 'Submission',
  booking = 'Booking',
  assignment = 'Assignment',
  onAssignment = 'On Assignment',
  bookingApproved = 'Booking Approved',
  offerByFacility = 'Offer by Facility',
  requestFileOut = 'Request File Out',
}

export enum AuditType {
  amnEvaluation = 'AMN Evaluation',
  applicationReferencing = 'Application/referencing',
  arBalance = 'AR balance',
  canadianTraveler = 'Canadian traveler',
  cancellation = 'Cancellation',
  clinicianExperienceSurveyResponse = 'Clinician Experience Survey Response',
  dns = 'DNS',
  experienceTouchpoint = 'Experience Touchpoint',
  ineligibleForTravel = 'Ineligible for travel',
  integration = 'Integration',
  noncancellationIncident = 'Noncancellation incident',
  prePlacementOGPIUseOnly = 'PrePlacement  - OGPI Use Only',
}

export enum TabValues {
  audit = 0,
  placement = 1,
}

export const getPlacementStatusNumber = (status: string | null) => {
  switch (status) {
    case AuditPlacementStatusCategory.onAssignment:
      return 1;
    case AuditPlacementStatusCategory.bookingApproved:
      return 2;
    case AuditPlacementStatusCategory.offerByFacility:
      return 3;
    case AuditPlacementStatusCategory.requestFileOut:
      return 4;
    default:
      return 5;
  }
};

export const getAuditPlacementColumns = (props: {
  checkBoxChange: (rowID, isSelected, params) => void;
  cancelAuditTypePlacementId?: any[];
  disabled?: boolean;
}) => {
  const headers: GridColDef[] = [
    {
      field: 'isSelected',
      headerName: '',
      width: 60,
      renderCell: params => (
        <Tooltip title="Disabled due to PID being associated to an existing Cancellation Audit" disableHoverListener={!props.cancelAuditTypePlacementId || !props.cancelAuditTypePlacementId.includes(params.row.activeID)}>
          <span>
            <Radio
              disabled={
                (props.cancelAuditTypePlacementId && props.cancelAuditTypePlacementId.includes(params.row.activeID)) ||
                props.disabled
              }
              checked={params.row?.isSelected}
              onChange={(event, param) => {
                params.row[params.field] = event.target.checked;
                props.checkBoxChange(params.row.rowID, event.target.checked, params);
              }}
            />
          </span>
        </Tooltip>
      ),
      align: 'center',
    },
    {
      field: 'activeID',
      headerName: 'Active ID',
      sortable: false,
      valueFormatter: param => param.value ?? missingField,
      width: 100,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      valueFormatter: param => param.value ?? missingField,
      width: 150,
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      sortable: false,
      valueFormatter: param => param.value ?? missingField,
      width: 125,
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      sortable: false,
      valueFormatter: param => param.value ?? missingField,
      width: 125,
    },
    {
      field: 'facility',
      headerName: 'Facility',
      sortable: false,
      valueFormatter: param => param.value ?? missingField,
      width: 310,
    },
    {
      field: 'unit',
      headerName: 'Unit',
      sortable: false,
      valueFormatter: param => param.value ?? missingField,
      width: 125,
    },
    {
      field: 'clinicalManager',
      headerName: 'Clinical Manager',
      sortable: false,
      valueFormatter: param => param.value ?? missingField,
      width: 125,
    },
  ];
  return headers;
};

export const getDropDownOption = option => {
  if (option) {
    return {
      label: option.name,
      object: {
        ID: option.value,
        Description: option.name,
      },
    };
  }

  return null;
};

interface IAuditPermissions {
  canView: boolean;
  canEdit: boolean;
}

export const auditTypeViewEditPermission = (name: string, roles) => {
  const editRolesByAuditType = {
    [AuditType.amnEvaluation]: [
      userRoles.clinical_ReviewAnalyst,
      userRoles.clinical_Manager,
      userRoles.clinical_Director,
    ],
    [AuditType.applicationReferencing]: [
      userRoles.clinical_ReviewAnalyst,
      userRoles.clinical_Manager,
      userRoles.clinical_Director,
      userRoles.riskManagement,
      userRoles.workersCompCoordinator,
    ],
    [AuditType.arBalance]: [],
    [AuditType.canadianTraveler]: [
      userRoles.clinical_ReviewAnalyst,
      userRoles.clinical_Manager,
      userRoles.clinical_Director,
      userRoles.immigrationTeam,
    ],
    [AuditType.cancellation]: [
      userRoles.clinical_ReviewAnalyst,
      userRoles.clinical_Manager,
      userRoles.clinical_Director,
    ],
    [AuditType.clinicianExperienceSurveyResponse]: [
      userRoles.clinical_Manager,
      userRoles.clinical_Director,
    ],
    [AuditType.dns]: [
      userRoles.clinical_ReviewAnalyst,
      userRoles.clinical_Manager,
      userRoles.clinical_Director,
    ],
    [AuditType.experienceTouchpoint]: [],
    [AuditType.ineligibleForTravel]: [
      userRoles.clinical_ReviewAnalyst,
      userRoles.clinical_Manager,
      userRoles.clinical_Director,
      userRoles.immigrationTeam,
      userRoles.riskManagement,
      userRoles.workersCompCoordinator,
    ],
    [AuditType.integration]: [
      userRoles.clinical_ReviewAnalyst,
      userRoles.clinical_Manager,
      userRoles.clinical_Director,
      userRoles.immigrationTeam,
      userRoles.riskManagement,
      userRoles.workersCompCoordinator,
    ],
    [AuditType.noncancellationIncident]: [
      userRoles.clinical_ReviewAnalyst,
      userRoles.clinical_Manager,
      userRoles.clinical_Director,
      userRoles.immigrationTeam,
      userRoles.riskManagement,
      userRoles.workersCompCoordinator,
      userRoles.credentialing_Leadership,
    ],
    [AuditType.prePlacementOGPIUseOnly]: [
      userRoles.clinical_ReviewAnalyst,
      userRoles.clinical_Manager,
      userRoles.clinical_Director,
      userRoles.riskManagement,
      userRoles.workersCompCoordinator,
    ],
  };

  const viewOnlyRoles = [
    userRoles.accountManagement,
    userRoles.accountManagement_TeamMember,
    userRoles.accountManagement_Leadership,
    userRoles.credentialing,
    userRoles.credentialing_TeamMember,
    userRoles.credentialing_Leadership,
    userRoles.customerSupport_Leadership,
    userRoles.customerSupport_TeamMember,
    userRoles.recruitment_Leadership,
    userRoles.recruitment_TeamMember,
    userRoles.timeProcessingSpecialist,
  ];

  if (!name || !roles) {
    return {
      canView: false,
      canEdit: false,
    } as IAuditPermissions;
  }

  const editRoles = editRolesByAuditType[name] || [];

  return {
    canView: Authorized([...editRoles, ...viewOnlyRoles], roles),
    canEdit: Authorized(editRoles, roles),
  } as IAuditPermissions;
};
