import { Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import React from 'react';

const useStyles = makeStyles()({
  alertDesc: {
    color: '#333333',
    fontSize: '14px',
    paddingBottom: '20px',
  },
  hyperText: {
    textDecoration: 'underline',
    color: '#2996CC',
    cursor: 'pointer',
  },
  modalDesc: {
    color: '#333333',
    fontSize: '14px',
  },
});

export const DuplicateFilterAlertModal = ({
  isAlertModalOpen,
  setAlertModalOpen,
  setMangeFilters,
  existingFilterSet,
}) => {
  const { classes } = useStyles();
  return (
    <GenericDialog
      open={isAlertModalOpen}
      onClose={() => setAlertModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      dialogTitleProps={{
        id: 'alert-dialog-title',
        text: 'Unable to Save Filters',
      }}
      dialogActions={[
        {
          text: 'Got It',
          variant: 'contained',
          color: 'primary',
          useDialogOnCloseEvent: true,
        },
      ]}
    >
      <Typography className={classes.alertDesc}>
        {`The current filters you are trying to save already exist in your `}
        <span className={classes.hyperText}>{existingFilterSet}</span>
        {` view.`}
      </Typography>
      <Typography className={classes.modalDesc}>
        {`You can`}
        <span
          role="button"
          tabIndex={0}
          onClick={() => {
            setMangeFilters(true);
            setAlertModalOpen(false);
          }}
          onKeyPress={() => setMangeFilters(true)}
          className={classes.hyperText}
        >
          {` manage your filter sets `}{' '}
        </span>
        {`to make changes to your existing views`}
      </Typography>
    </GenericDialog>
  );
};
