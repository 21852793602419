import { IconButton, useTheme } from 'amn-ui-core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomGrid } from 'app/components/Common/Grid/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from 'tss-react/mui';
import { breadcrumbActions } from '../../../Common/BreadcrumbNavigation/BreadcrumbNavigation.redux';
import { useDispatch } from 'react-redux';
import { JobMatchComparison } from '../JobMatchComparison/JobMatchComparison';
import { useSelector } from 'react-redux';
import { candidateDetailsSelection } from '../Profile/CandidateDetails.selector';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { OrderMapView } from './OrderMapView';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { panelActions, PanelChoice } from '../PanelOptions/PanelOption.redux';
import { gridSelectionActions } from '../../../Common/Grid/GridSelection/GridSelection.redux';
import {
  selectMatchDrawerData,
  selectJobMatchCount,
  selectJobMatchesGridData,
  selectMatchServiceFailure,
  selectJobMatches,
} from './JobMatch.selectors';
import { jobMatchActions } from './JobMatch.redux';
import { usePromiseTracker } from 'react-promise-tracker';
import { getJobMatchGridHeaders, getNavData } from './gridUtils';
import queryString from 'query-string';
import { gridStateActions, GridTag } from '../../../Common/Grid/GridStateManagement/GridState.redux';
import { selectFilters, selectGridState } from '../../../Common/Grid/GridStateManagement/GridState.selectors';
import { GridFilter, getFilter } from './GridFilter';
import { DetailsPageGrid } from 'app/layout/pages/DetailsPageGrid';
import { DrawerFitted } from 'app/components/Common/Drawer/Drawer';
import { OrderPreviewDrawerViewer } from 'app/components/GlobalSearch/Order/OrderPreviewDrawerViewer';
import { useDecision } from '@optimizely/react-sdk';
import { ff_candidate_ui_job_match } from 'app/constants/FeatureFlags/Candidate/Keys';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
const useStyles = makeStyles()(theme => ({
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50vh',
  },
  iconButton: {
    padding: '0 6px',
  },
}));

const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_PAGE_NUMBER = 1;

export const JobMatchGrid = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const candidateDetails = useSelector(candidateDetailsSelection);
  const { totalCount, filteredCount } = useSelector(selectJobMatchCount);
  const apiResponse = useSelector(selectJobMatches);
  const flattenedData = useSelector(selectJobMatchesGridData);
  const gridState = useSelector(selectGridState);
  const appliedFilterValues = useSelector(selectFilters);
  const view = queryString.parse(window.location.search).view;
  const jobMatchServiceFailure = useSelector(selectMatchServiceFailure);
  const drawerData = useSelector(selectMatchDrawerData);
  const [enableJobMatch] = useDecision(ff_candidate_ui_job_match);

  const { promiseInProgress } = usePromiseTracker({ area: 'job-match-call', delay: 0 });

  const [isGridListed, setGridListed] = useState<boolean>(view !== 'map');

  const pageBreadCrumbs = [
    { title: t('candidate.gridTitle'), clickable: true, onClick: () => handleNavigateBack() },
    { title: t('candidate.jobMatchGrid.title') },
  ];

  const orderNavData = getNavData('Order Details', candidateDetails?.travelerId, candidateDetails?.brandId);
  const facilityNavData = getNavData('Facility Details', candidateDetails?.travelerId, candidateDetails?.brandId);

  const handleNavigation = navigateTo => {
    if (navigateTo.includes('order')) {
      dispatch(breadcrumbActions.setNavigationData(orderNavData));
    }
    if (navigateTo.includes('facility')) {
      dispatch(breadcrumbActions.setNavigationData(facilityNavData));
    }
  };

  const handleNavigateBack = () => {
    window.history.pushState(null, '', `${window.location.pathname}?tab=1`);
    //Action dispatched to reset grid state when navigating back
    dispatch(gridStateActions.reset());
    dispatch(panelActions.setPanelOptions({ panelOption: PanelChoice.Tabs }));
  };

  const enableGridView = () => {
    setGridListed(true);
    window.history.pushState(null, '', `${window.location.pathname}?panel=${PanelChoice.JobMatchGrid.toLowerCase()}`);
  };

  const enableMapView = () => {
    setGridListed(false);
    window.history.pushState(
      null,
      '',
      `${window.location.pathname}?panel=${PanelChoice.JobMatchGrid.toLowerCase()}&view=map`,
    );
  };

  const fetchPaginationData = (offset = 20, page: number | null = null, matchFilter?: string) => {
    if (candidateDetails.travelerId && candidateDetails.brandId && enableJobMatch?.enabled) {
      const candidateId = candidateDetails.travelerId;
      const brandId = candidateDetails.brandId;
      const newOffset = ((page || 1) - 1) * offset;

      dispatch(
        jobMatchActions.getJobMatchAction({
          travelerId: candidateId,
          brandId,
          limit: offset,
          offset: newOffset,
          matchFilter,
          t,
        }),
      );
    }
  };

  const handlePagination = (offset, page) => {
    const existingFilter = appliedFilterValues?.filters
      ? getFilter(appliedFilterValues?.filters.matchFilter)
      : undefined;
    fetchPaginationData(offset, page, existingFilter);
  };

  const filterGridData = filters => {
    fetchPaginationData(gridState.pageSize || DEFAULT_PAGE_SIZE, DEFAULT_PAGE_NUMBER, filters);
  };

  const enableDrawer = (rowKey: number, columnKey: string) => {
    const selectedorder = apiResponse[rowKey];
    const displayValue = flattenedData[rowKey][columnKey];
    dispatch(jobMatchActions.setDrawerData({ open: true, data: { displayValue, ...selectedorder } }));
  };

  useEffect(() => {
    if (gridState.gridTag !== GridTag.JobMatchGrid) {
      dispatch(
        gridStateActions.setGridState({
          gridTag: GridTag.JobMatchGrid,
          pageSize: DEFAULT_PAGE_SIZE,
          pageNumber: DEFAULT_PAGE_NUMBER,
          previewItemId: -1,
          showPreviewDialog: false,
          sortedColumn: null,
          filterAttributes: undefined,
        }),
      );
      fetchPaginationData(DEFAULT_PAGE_SIZE, DEFAULT_PAGE_NUMBER);
    } else
      fetchPaginationData(
        gridState.pageSize || DEFAULT_PAGE_SIZE,
        gridState.pageNumber || DEFAULT_PAGE_NUMBER,
        getFilter(appliedFilterValues?.filters.matchFilter),
      );

    return () => {
      dispatch(gridSelectionActions.setGridSelections({ selectedData: [], isCompareGridOpen: false }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (jobMatchServiceFailure) {
      dispatch(
        globalActions.setSnackBar({
          message: t('API Error, Filtering Unsuccessful'),
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobMatchServiceFailure]);

  return (
    <>
      <JobMatchComparison />
      {promiseInProgress ? (
        <CircularProgress className={classes.MuiCircularProgressRoot} />
      ) : (
        <>
          <DetailsPageGrid
            breadcrumbs={pageBreadCrumbs}
            title={`${t('candidate.jobMatch.resultsTitle')} (${filteredCount !== null ? filteredCount : totalCount})`}
            handleNavigateBack={handleNavigateBack}
            iconButtons={[
              <IconButton
                color="primary"
                disableTouchRipple
                disableFocusRipple
                style={{ background: 'none' }}
                classes={{ root: classes.iconButton }}
                aria-label="grid-view-button"
                onClick={() => enableGridView()}
                size="small"
              >
                <FormatListBulletedIcon
                  style={{ color: isGridListed ? '#0098DB' : undefined, opacity: isGridListed ? 1 : 0.6 }}
                />
              </IconButton>,
              <IconButton
                color="primary"
                disableTouchRipple
                disableFocusRipple
                style={{ background: 'none' }}
                classes={{ root: classes.iconButton }}
                aria-label="map-view-button"
                onClick={() => enableMapView()}
                size="small"
              >
                <LocationOnOutlinedIcon
                  style={{ color: isGridListed ? undefined : '#0098DB', opacity: isGridListed ? 0.6 : 1 }}
                />
              </IconButton>,
            ]}
          >
            {isGridListed ? (
              <CustomGrid
                columns={getJobMatchGridHeaders(t)}
                rows={flattenedData}
                currentPageNumber={gridState.pageNumber || DEFAULT_PAGE_NUMBER}
                fixedItemsPerPage={gridState.pageSize || DEFAULT_PAGE_SIZE}
                defaultPageView={
                  gridState.pageSize !== 0 ? gridState.pageSize.toString() : DEFAULT_PAGE_SIZE.toString()
                }
                defaultSortDirection={gridState.sortedColumn?.direction}
                defaultSortedColumn={gridState.sortedColumn?.column}
                pageOptions={[20, 50, 100]}
                showCount={false}
                disableGutters
                noPadding
                gridName={GridTag.JobMatchGrid}
                onNavigation={handleNavigation}
                onPagination={handlePagination}
                onPageSizeChange={pageSize => handlePagination(pageSize, 1)}
                totalRecordsCount={filteredCount || totalCount || 0}
                enableSelectionDrawer
                rowSelectionKey={'orderId'}
                selectionLimit={4}
                minCellWidth={'100px'}
                enableStateManagement
                serverSidePagination
                CustomizedFilter={<GridFilter onApplyFilter={filterGridData} />}
                modifyData={data => dispatch(jobMatchActions.setGridData(data))}
                onElementClick={enableDrawer}
              />
            ) : (
              <OrderMapView />
            )}
          </DetailsPageGrid>
        </>
      )}
      <DrawerFitted
        onDrawerClose={() => {
          dispatch(jobMatchActions.setDrawerData({ open: false, data: undefined }));
        }}
        width={450}
        top={0}
        backgroundColor={theme.palette.framework.system.whisper}
        open={drawerData?.open || false}
      >
        {drawerData?.data ? <OrderPreviewDrawerViewer type={2} /> : null}
      </DrawerFitted>
    </>
  );
};
