import React from 'react';
import { useSelector } from 'react-redux';
import { selectInterestLocations, selectJobInterests } from './JobInterest.selector';
import { AmnMapView } from '../../../Common/Map';

export const OrderMapView = () => {
  const locationCoordinates = useSelector(selectInterestLocations);
  const jobInterests = useSelector(selectJobInterests);

  const entities = jobInterests.reduce((resultArray: any[], info) => {
    if (info.facilityCoordinates) {
      const data = {
        entityId: info.orderId,
        locationCoordinates: info.facilityCoordinates,
      };
      resultArray.push(data);
    }
    return resultArray;
  }, []);
  return <AmnMapView locationCoordinates={locationCoordinates} isPopoversEnabled={false} entities={entities} />;
};
