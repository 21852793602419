import { DateRangePicker } from 'app/ComponentLibrary/DateRangeNew';
import { addYears } from 'date-fns';
import { InlineEditableField } from 'app/components/Common/EditPage/InlineEditableField';
import moment, { Moment } from 'moment';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useEffect, useContext } from 'react';
import { DrawerContext } from 'app/components/Common/Drawer/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { pageAttributesActions } from '../store/placementPageAttributes.redux';
import { selectEditStatus, selectPageAttributes } from '../store/placementPageAttributes.selector';
import { editableFields } from '../../editUtils';
import { ActionAccessorHook } from './ActionAccessorHook';
import { missingField } from 'app/constants';
import { getExtensionDuration } from 'app/helpers/getExtensionDuration';
import { getExtensionEndDate } from '../helper';

export const PlacementStartDate = ({ id, maxWidth }: { id: string; maxWidth?: number | undefined }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { control, setValue, setError, clearErrors, getValues } = useFormContext();

  const value = getValues('placementDate');
  const startDateValue = getValues('placementStartDate');
  const endDateValue = getValues('placementEndDate');
  const placementLength = getValues('numberOfWeeks');

  const { setPopperOpen } = useContext(DrawerContext);
  const { placementDate: status } = useSelector(selectEditStatus);
  const { editOrderStack } = useSelector(selectPageAttributes);
  const undoChecker = () => !editOrderStack.some(key => key === 'placementStartDate');
  const endDateMoment = moment(endDateValue);

  useEffect(() => {
    return () => {
      setPopperOpen?.('placementStartDate', false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateEndDate = (startDate: Moment) => {
    if (placementLength !== null && placementLength >= 0) {
      const endDate = getExtensionEndDate(startDate, placementLength ?? 0);
      setValue('placementEndDate', endDate?.format('YYYY-MM-DDTHH:mm:ss'));
      clearErrors('placementEndDate');
      const placementDateValue = {
        startDate: startDate?.format('YYYY-MM-DDTHH:mm:ss'),
        endDate: endDate?.format('YYYY-MM-DDTHH:mm:ss'),
      };
      setValue('placementDate', placementDateValue);
    } else if (endDateMoment?.isValid()) {
      const placementDateValue = {
        startDate: moment(startDateValue)?.format('YYYY-MM-DDTHH:mm:ss'),
        endDate: endDateMoment?.format('YYYY-MM-DDTHH:mm:ss'),
      };
      setValue('placementDate', placementDateValue);
      const weeksGap = getExtensionDuration(startDate, endDateMoment?.format('YYYY-MM-DDTHH:mm:ss'));
      if (weeksGap >= 0) {
        setValue('numberOfWeeks', weeksGap);
        clearErrors('placementEndDate');
      } else {
        setValue('numberOfWeeks', 0);
        setError('placementEndDate', {
          type: 'validate',
          message: t(''),
        });
      }
    } else {
      if (placementLength === null && endDateValue === null) {
        setValue('numberOfWeeks', 13);
        const endDate = getExtensionEndDate(startDate, 13);
        setValue('placementEndDate', endDate?.format('YYYY-MM-DDTHH:mm:ss'));
        const placementDateValue = {
          startDate: startDate?.format('YYYY-MM-DDTHH:mm:ss'),
          endDate: endDate?.format('YYYY-MM-DDTHH:mm:ss'),
        };
        setValue('placementDate', placementDateValue);
      }
    }
  };

  return (
    <div>
      <Controller
        control={control}
        name={'placementStartDate'}
        render={({ ref, onChange, ...rest }) => {
          const startDate = moment(startDateValue);
          return (
            <>
              {/** Component is used to access inaccessible methods from react-hook-form Controller
               * to invoke actions - say undo action */}
              <ActionAccessorHook
                actionChecker={undoChecker}
                accessAction={() => onChange(startDateValue)}
                hookDependancies={[editOrderStack]}
              />
              <InlineEditableField
                {...rest}
                id={id}
                maxWidth={maxWidth}
                inputProps={{
                  size: 'inline',
                  variant: 'outlined',
                  initialDateRange: {
                    startDate: startDate?.isValid() ? new Date(startDateValue) : undefined,
                    endDate: startDate?.isValid() ? new Date(startDateValue) : undefined,
                    useLabelAsValue: true,
                  },
                  maxDate: addYears(new Date(), 20),
                  quickSelect: false,
                  disablePortal: true,
                  actionBar: false,
                  single: true,
                  onOpen: open => setPopperOpen?.('startDate', open),
                  useMaxWidth: !!maxWidth,
                }}
                label={startDate.isValid() ? startDate.format('MM/DD/YYYY') : missingField}
                Control={DateRangePicker}
                onChange={newValue => {
                  if (newValue.endDate !== undefined) {
                    const newDate = moment(newValue.endDate);
                    const setTo = newDate.isValid() ? moment(newDate).format('YYYY-MM-DDTHH:mm:ss') : null;
                    calculateEndDate(moment(newDate));
                    onChange(setTo);
                  } else {
                    setValue('numberOfWeeks', null);
                    const placementDate = { ...value, startDate: null };
                    setValue('placementDate', placementDate);
                    clearErrors('placementEndDate');
                    onChange(null);
                  }
                  dispatch(pageAttributesActions.pushToEditOrderStack(editableFields.placementStartDate));
                  // Uncomment if you want to be able to clear the date
                  // else onChange(null);
                }}
                setCurrentFocus={state => dispatch(pageAttributesActions.setCurrentFocus(state !== null ? id : null))}
                tooltipText={status.disabled ? t('placement.profile.edit.disableText') : ''}
                {...status}
              />
            </>
          );
        }}
      />
    </div>
  );
};
