import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ICandidateDocumentDetail, ICandidatePassportDocuments } from './candidateDocuments.state';

export const initialState: ICandidateDocumentDetail = {
  candidatePassportDocuments: [],
};

const getCandidateDocumentsFromPassport = createAction<string>('GET_CANDIDATE_DOCUMENTS_PASSPORT');
const initiatePDFFileDownload = createAction<{ url: string; formTitle: string }>('INITIATE_PDF_FILE_DOWNLOAD');

const candidateDocumentSlice = createSlice({
  name: 'candidateDocuments',
  initialState: initialState,
  reducers: {
    setCandidateDocuments(state, action: PayloadAction<ICandidatePassportDocuments[]>) {
      state.candidatePassportDocuments = action.payload;
    },
  },
});

export const { name: candidateDocumentSliceKey, reducer: candidateDocumentReducer } = candidateDocumentSlice;

export const candidateDocumentActions = {
  ...candidateDocumentSlice.actions,
  getCandidateDocumentsFromPassport,
  initiatePDFFileDownload,
};
