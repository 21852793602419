import { Box, Grid } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchField } from '../Search/SearchField';
import { makeStyles } from 'tss-react/mui';
import { Expandable, MergeToken } from './customComponents';
import { IAppliedMergeToken } from 'store/redux-store/confirmation-template-editor/types';
import { Editor as EditorType } from 'tinymce';

const useStyles = makeStyles()(() => ({
  container: {
    height: 768,
    flexWrap: 'nowrap',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  searchBarContainer: {
    position: 'sticky',
    top: 1,
    zIndex: 2,
    backgroundColor: '#F7F7F7',
    width: '-webkit-fill-available',
  },
  textField: {
    height: 40,
    width: '100%',
  },
  content: {
    display: 'contents',
  },
}));

export const TokensWrapper: React.FC<{
  tokensGroup: {
    [k: string]: {
      label: string;
      tokens: IAppliedMergeToken[];
    };
  };
  editorInstance: EditorType;
  [key: string]: any;
  isNewTemplate: boolean;
  disableTokenInteractions?: boolean;
}> = ({ tokensGroup, editorInstance, isNewTemplate, disableTokenInteractions, ...props }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [searchQuery, setSeachQuery] = React.useState(null);

  const handleTokenClick = React.useCallback(
    data => {
      // @ts-ignore
      editorInstance.execCommand('mceInsertContent', false, data);
    },
    [editorInstance],
  );

  const renderExpandables = React.useMemo(
    () =>
      Object.entries<{ label: string; tokens: IAppliedMergeToken[] }>(tokensGroup).reduce((expandables, pair) => {
        const tokens = pair[1].tokens.filter(
          x =>
            !searchQuery ||
            x.mergeFieldDescription.toLowerCase().includes(searchQuery.toLowerCase()) ||
            x.mergeFieldText.toLowerCase().includes(searchQuery.toLowerCase()),
        );
        if (tokens.length > 0)
          expandables.push(
            <Expandable expanded title={pair[1].label}>
              <Grid container spacing={2}>
                {tokens.map(({ count, mergeFieldDescription, mergeFieldText }) => (
                  // Code: xs = 6 The tokens are arranged in two columns
                  <Grid key={mergeFieldText} item xs={6}>
                    <MergeToken
                      id={mergeFieldText}
                      data={`&lt;&lt;${mergeFieldText}&gt;&gt;`}
                      count={count}
                      onClick={handleTokenClick}
                      label={mergeFieldDescription}
                      disableTokenInteractions={disableTokenInteractions}
                    />
                  </Grid>
                ))}
              </Grid>
            </Expandable>,
          );
        return expandables;
      }, []),
    [tokensGroup, searchQuery, handleTokenClick, disableTokenInteractions],
  );

  return (
    <Grid container direction="column" classes={{ container: classes.container }}>
      <Grid item classes={{ root: classes.content }}>
        <Box className={classes.searchBarContainer}>
          <SearchField
            autoFocus={!isNewTemplate}
            inheritWidth
            name={'search-template-tokens'}
            placeholder={t('appHeader.searchPlaceHolder')}
            adornAtStart
            onChange={e => setSeachQuery(e.target.value)}
            searchFieldClasses={{ textField: classes.textField }}
          />
        </Box>
      </Grid>
      <Grid style={{ marginTop: '1rem' }} item>
        {renderExpandables}
      </Grid>
    </Grid>
  );
};
