import React, { useState, useEffect } from 'react';
import { Grid, IconButton } from 'amn-ui-core';
import { AutoCompleteTextBox } from 'app/ComponentLibrary/Filter/AutoCompleteTextBox/AutoCompleteTextBox';
import { ISelection } from 'app/ComponentLibrary/Filter/TypeAheadDropdown/CustomTypeAhead';
import { TypeAheadDropdown } from 'app/ComponentLibrary/Filter/TypeAheadDropdown/TypeAheadDropdown';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Concatenate } from 'utils/string/string';
import { SubTitle } from '../styles';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import EditIcon from 'app/assets/images/Credentialing/PersonalInfo/editIcon.svg';
import { selectUser } from 'oidc/user.selectors';
import { candidateDetailActions } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { APIStatus } from 'app/enums/ApiStatus';
import {
  candidateDetailsSelection,
  getEMRList,
  savedEMRList,
} from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { isRecruitment, useReadOnly } from 'oidc/userRoles';
import { IEmrList } from 'app/models/Candidate/CandidateProfile';
import { withReadOnly } from 'oidc/withReadOnly';
import { httpSuccess } from 'app/services/serviceHelpers';
import { EMRFilterName } from './EMRFilterConfig';

const anchorAccesorid = 'procedure-anchor-accessor';
const hiddenApplyBtn = 'procedure-hidden-btn-accessor';

export const EquipmentProcedure = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const httpStatus = candidateDetails.httpStatus;
  const [value, setValue] = useState<ISelection[]>([]);
  const [renderedText, setRenderedText] = useState('');
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<any>(null);
  const [saveStatus, setSaveStatus] = useState<any>('');
  const emrList = useSelector(getEMRList);
  const savedEquipments = useSelector(savedEMRList);
  const { readOnly } = useReadOnly();
  const isSubmitted = React.useRef<boolean>();
  const isNetworkNotAvailable = 500;

  const handleOpen = () => {
    const btn = document.getElementById(anchorAccesorid);
    /** btn click is required to invoke TypeAheadDropdown opener */
    btn?.click();
    setOpen(true);
    anchorRef.current.focus();
  };

  /**
   * Method to initiate save api
   */
  const handleSave = () => {
    setSaveStatus(APIStatus.isLoading);
    isSubmitted.current = true;
    dispatch(
      candidateDetailActions.postEMRData({
        candidateId: Number(candidateDetails?.travelerId),
        brandId: candidateDetails?.brandId,
        equipmentIds: value.map(item => item.id),
        currentEmployeeId: user?.userInfo?.employeeId || 8410,
        currentEmployeeName: `${user.userInfo?.firstName} ${user.userInfo?.lastName}`,
      }),
    );
  };

  /**
   * Method to reset equipment field to initial state
   */
  const resetState = () => {
    patchEquipmentFieldValue();
    setSaveStatus('');
  };

  /**
   * Method to set value for equipment field when candidate and brand id is not null
   */
  const patchEquipmentFieldValue = () => {
    const patchEquipment = emrList?.filter(emrItem => emrItem.candidateId && emrItem.brandId);
    if (patchEquipment?.length) {
      setValue(patchEquipment as any);
    }
  };

  useEffect(() => {
    setRenderedText(
      Concatenate(
        value.map(item => item.name),
        ', ',
      ),
    );
  }, [value]);

  /**
   *  useEffect to trigger after save (tab switch)/ initial page load
   */
  useEffect(() => {
    if (savedEquipments?.length) {
      setValue(savedEquipments);
    }
  }, [dispatch, emrList, savedEquipments]);

  /**
   *
   */
  const handleSuccess = () => {
    setSaveStatus(APIStatus.Completed);
    dispatch(candidateDetailActions.savedEquipmentsProcedure(value as IEmrList[]));
    setTimeout(() => {
      setSaveStatus('');
      setOpen(false);
      handleClose();
    }, 5000);
  };

  /**
   * Method to call when close button is clicked
   */
  const handleClose = () => {
    isSubmitted.current = false;
    dispatch(candidateDetailActions.setHttpStatus({ pageName: undefined, statusCode: undefined }));
  };

  useEffect(() => {
    if (isSubmitted?.current) {
      const isAPIFailure = httpSuccess(httpStatus?.statusCode) || httpStatus?.statusCode === isNetworkNotAvailable;
      if (httpSuccess(httpStatus?.statusCode)) {
        handleSuccess();
      } else if (EMRFilterName.Procedure === httpStatus?.pageName && isAPIFailure) {
        setSaveStatus(APIStatus.Error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted?.current, httpStatus?.statusCode]);

  return (
    <Grid item container direction="column">
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <SubTitle data-testid="equipments-procedure-title">
            {t('candidate.personalInfoCard.equipmentProcedures')}
          </SubTitle>
        </Grid>
        {!open && (
          <Grid item style={{ width: 32 }}>
            {withReadOnly(IconButton)({
              'data-testid': 'edit-equipments-btn',
              disabled: !isRecruitment(user.userInfo?.roles || []),
              onClick: e => {
                if (!readOnly) {
                  handleOpen();
                  setOpen(true);
                }
              },
              color: 'secondary',
              size: 'medium',
              children: <ModeEditOutlinedIcon />,
            })}
          </Grid>
        )}
      </Grid>
      <Grid item>
        <Grid item>
          <AutoCompleteTextBox
            open={open}
            anchorRef={anchorRef}
            data={renderedText ? renderedText : '--'}
            handleSave={handleSave}
            {...{ isHandleClick: open, setIsHandleClick: setOpen, handleOpen }}
            saveStatus={saveStatus}
            resetValue={resetState}
            handleClose={handleClose}
          />
          <TypeAheadDropdown
            name="procedure"
            value={value}
            anchorRef={anchorRef}
            onChange={newValue => setValue(newValue)}
            options={emrList ?? []}
            isMultiSelect
            anchorAccesorid={open ? anchorAccesorid : undefined}
            hiddenApplyBtn={hiddenApplyBtn}
            removeActionContainer
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
