import React, { useEffect, useState } from 'react';
import { TypeAheadCustomWrapper } from 'app/components/ClientContact/Common/TypeAheadCustomWrapper';
import { useSelector } from 'react-redux';
import { selectClientContactDetails, selectFacilityUnits } from './store/ClientContact.selector';
import { IOptionsList } from 'app/models/Tasks/Tasks';
import { selectAllOption } from './Transformers/FormatData';

export const FacilityUnits = props => {
  const { setSelectedUnits, disabled } = props;
  const facilityList = useSelector(selectFacilityUnits);
  const selectedClientContactDetails = useSelector(selectClientContactDetails);
  const [selectedOptions, setSelectedOptions] = useState<IOptionsList[]>([]);
  const [optionList, setOptionList] = useState([]);

  useEffect(() => {
    if (facilityList?.length > 0) setOptionList(facilityList);
  }, [facilityList]);

  useEffect(() => {
    if (selectedClientContactDetails && selectedClientContactDetails?.units?.length > 0) {
      const unitsList = [
        ...selectedClientContactDetails?.units?.map(opt => ({
          id: opt.unitId?.toString(),
          value: opt.name,
          name: opt.name,
        })),
      ];
      if (facilityList && facilityList?.length - 1 === selectedClientContactDetails?.units?.length) {
        unitsList?.unshift({ id: '0', name: 'SELECT ALL', value: 'SELECT ALL' });
      }
      setSelectedOptions(unitsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientContactDetails?.contactId]);

  return (
    <TypeAheadCustomWrapper
      optionsList={optionList}
      setSelectedOptions={setSelectedUnits}
      selectAllValue={selectAllOption}
      selectedValues={selectedOptions}
      isDisabled={disabled}
    />
  );
};
