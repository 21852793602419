import React from 'react';
import { Chip, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { useTranslation } from 'react-i18next';
import { NewUnit } from 'app/models/Unit/CreateUnit';
import { formatChipLabel } from '../helper';
import { useEffect } from 'react';
import { ControlledTypeAheadWithCheckBox } from 'app/components/Order/OrderCreationForm/OrderDetails/ControlledTypeAheadWithCheckBox';
import { useForm, FormProvider } from 'react-hook-form';
import { GetVirtualRating } from 'app/components/Order/OrderCreationForm/OrderDetails/FormatData';

interface Props {
  unit: NewUnit;
  onUnitPropChange: (property: string, value: any) => void;
  disableEdit?: boolean;
  virtualRating?: any;
}

const useStyles = makeStyles()(() => ({
  chip: {
    maxWidth: '50ch',
    margin: '0 5px 10px 0',
  },
}));

export const VirtualRating = (props: Props) => {
  const { unit, onUnitPropChange, disableEdit, virtualRating } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const formMethods = useForm();
  const virtualRatingOptions = GetVirtualRating();
  const [virtualRatingValue, setVirtualRatingValue] = React.useState<any>(unit?.virtualRating);
  const [virtualRatingChips, setVirtualRatingChips] = React.useState<any>(virtualRating);

  useEffect(() => {
    setVirtualRatingValue(unit?.virtualRating);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit?.virtualRating]);

  const getSelectedValue = () => {
    return virtualRatingOptions?.filter(p => virtualRatingValue?.includes(p.label));
  };

  const handleVirtualRatingInput = values => {
    const selectedValues = values.map(value => value.label);
    onUnitPropChange('virtualRating', selectedValues);
    setVirtualRatingChips(selectedValues);
  };

  const handleDelete = vr => {
    const updatedValue = virtualRatingChips.filter(virtualRatingChip => virtualRatingChip != vr);
    setVirtualRatingChips(updatedValue);
    onUnitPropChange('virtualRating', updatedValue);
  };
  useEffect(() => {
    if (virtualRating) {
      setVirtualRatingChips(virtualRating);
    }
  }, [virtualRating]);

  return (
    <div>
      <FormProvider {...formMethods}>
        <UnitDetailsBody variant="outlined">
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <UnitCardsTitle>{t('order.createUnit.virtualInterviewRating.title')}</UnitCardsTitle>
            </Grid>
            <Grid item>
              <ControlledTypeAheadWithCheckBox
                id={'createUnitVirtualInterviewRatingDropDown'}
                register={formMethods.register}
                name="virtualRating"
                label={t('order.createUnit.virtualInterviewRating.rating')}
                control={formMethods.control}
                options={virtualRatingOptions}
                isdisabled={disableEdit}
                onChange={handleVirtualRatingInput}
                defaultValue={virtualRating}
                selectedvalue={getSelectedValue()}
              />
            </Grid>
            <Grid item>
              <div id={'createUnitVirtualInterviewRatingSelectedItemContainer'}>
                {virtualRatingChips &&
                  virtualRatingChips.map(vrChip => {
                    return (
                      <Chip
                        disabled={disableEdit}
                        variant="outlined"
                        label={
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              {formatChipLabel(vrChip)}
                            </div>
                          </div>
                        }
                        className={classes.chip}
                        onDelete={() => handleDelete(vrChip)}
                      />
                    );
                  })}
              </div>
            </Grid>
          </Grid>
        </UnitDetailsBody>
      </FormProvider>
    </div>
  );
};
