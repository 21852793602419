import React from 'react';
import { Avatar, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';

const useStyles: any = makeStyles()(theme => ({
  textAvatar: {
    width: '21px',
    height: '21px',
    textAlign: 'center',
    font: 'normal normal bold 9px/12px Roboto',
    letterSpacing: '0px',
    color: '#FFFFFF',
    opacity: 1,
    margin: '0px 3px 0 0',
    background: '#708090',
  },
  label: {
    textAlign: 'left',
    font: 'normal normal normal 14px/20px Roboto',
    color: theme.palette.components.typography.color,
  },
  flexContainer: {
    width: '16rem',
    display: 'flex',
    alignItems: 'flex-start',
  },
  fieldLabel: {
    marginBottom: '6px',
    marginTop: '6px',
    font: 'normal normal normal 10px/19px Roboto',
    color: '#333333',
    letterSpacing: '0px',
    opacity: '1',
  },
}));
export const ExternalAssignedToField = props => {
  const { value } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const getInitials = fullname => {
    if (fullname) {
      const fullName = fullname.split(' ');
      if (fullName?.length > 1) {
        const name = fullName?.shift()?.charAt(0) + fullName?.pop()?.charAt(0);
        return name;
      } else {
        const initial = fullName?.shift()?.charAt(0);
        return initial;
      }
    }
  };
  return (
    <Grid container xs={12} style={{ marginLeft: '12px' }}>
      <Grid item xs={12}>
        <div className={classes.fieldLabel}>{t('notification.createTask.assignedToLabel')}</div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.flexContainer}>
          <div style={{ marginTop: '1px' }}>
            {value?.object?.label && (
              <Avatar className={classes.textAvatar}>{getInitials(value?.object?.label)}</Avatar>
            )}
          </div>
          <div className={classes.label}>{value?.object?.label}</div>
        </div>
      </Grid>
    </Grid>
  );
};
