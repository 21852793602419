import { IUnitPreferences, ISubmissionPreferences, IOrderAutomationPlacement, IActivePlacementCounts } from './types';

export const initialSubmissionPreferences: ISubmissionPreferences = {
  submissionLimits: { activePlacementCounts: null },
  wfcToRfo: false,
  candidateTasks: false,
  rfoToSent: false,
  sendPacket: false,
  savePacket: false,
  submissionEmailSetup: null,
  submissionPacketSetup: null,
};

export const initialOrderAutomationPlacement: IOrderAutomationPlacement = {
  orderId: null,
  placementId: null,
  candidate: {
    candidateId: null,
    firstName: null,
    lastName: null,
  },
  availabilityDate: null,
  statusUpdatedDate: null,
  brandId: null,
  brand: null,
  recruiter: {
    userId: null,
    firstName: null,
    lastName: null,
    email: null,
    phoneNumber: null,
  },
  discipline: null,
  disciplineAbbr: null,
  specialty: null,
  specialtyAbbr: null,
}

export const initaiActivePlacementCounts: IActivePlacementCounts = {
  rfo: 0,
  sent: 0,
  others: 0,
};

export const initialState: IUnitPreferences = {
  submissionPreferences: initialSubmissionPreferences,
  facilityUnitClientContacts: [],
  isPreferenceChanged: false,
  confirmationDialog: false,
  tabValue: 0,
  miscellaneous: {
    disableEmailContainer: true,
    disablePacketContainer: true,
  },
  existingOrders: [],
  updateDialogOpen: false,
  existingPlacements: [],
  orderActivePlacementCounts: initaiActivePlacementCounts,
  readyToSubmitPlacementIds: [],
  updateInprogres: false,
  preferenceSelectionModal:[]
};
