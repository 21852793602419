import React from 'react';
import { Grid } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';

export const PdfAction = ({ documentId }) => {
  const { t } = useTranslation();

  return (
    <Grid container sx={{ padding: '9px' }}>
      <Grid item sx={{ color: theme => theme.palette.system.darkGray, fontWeight: 450 }}>
        {t('templateEditor.pdfAction', { documentId })}
      </Grid>
    </Grid>
  );
};
