import { amnCommunicationLogsName } from './utils';

const expandDateRange = (dateRange, isDirty) => (isDirty ? dateRange : { startDate: undefined, endDate: undefined });

export const extractValues = filterValue =>
  Array.isArray(filterValue) ? filterValue.map(val => val?.value) : filterValue?.value;

export const getFilterRequest = (filterValues, dirtyFields) => ({
  [amnCommunicationLogsName.channel]: dirtyFields[amnCommunicationLogsName.channel]
    ? extractValues(filterValues[amnCommunicationLogsName.channel])
    : undefined,
  [amnCommunicationLogsName.initiatedBy]: dirtyFields[amnCommunicationLogsName.initiatedBy]
    ? extractValues(filterValues[amnCommunicationLogsName.initiatedBy])
    : undefined,
  [amnCommunicationLogsName.userRole]: dirtyFields[amnCommunicationLogsName.userRole]
    ? extractValues(filterValues[amnCommunicationLogsName.userRole])
      ? [extractValues(filterValues[amnCommunicationLogsName.userRole])]
      : []
    : undefined,
  ...expandDateRange(
    filterValues[amnCommunicationLogsName.logDateRange],
    dirtyFields[amnCommunicationLogsName.logDateRange],
  ),
});

export const createFilterChips = values => ({
  [amnCommunicationLogsName.channel]: values[amnCommunicationLogsName.channel]?.length
    ? values[amnCommunicationLogsName.channel].map(value =>
        generateFieldChips(
          value.value === 'Voice' ? 'Call' : value.value === 'Conversation' ? 'SMS' : value.value,
          amnCommunicationLogsName.channel,
        ),
      )
    : undefined,
  [amnCommunicationLogsName.initiatedBy]: values[amnCommunicationLogsName.initiatedBy]?.value
    ? generateFieldChips(values[amnCommunicationLogsName.initiatedBy].value, amnCommunicationLogsName.initiatedBy)
    : undefined,
  [amnCommunicationLogsName.logDateRange]: values[amnCommunicationLogsName.logDateRange]?.label
    ? generateFieldChips(values[amnCommunicationLogsName.logDateRange]?.label, amnCommunicationLogsName.logDateRange)
    : undefined,
  [amnCommunicationLogsName.userRole]: values[amnCommunicationLogsName.userRole]?.value
    ? generateFieldChips(values[amnCommunicationLogsName.userRole].value, amnCommunicationLogsName.userRole)
    : undefined,
});

const generateFieldChips = (value, label) => ({
  label: value,
  value: label,
  enableDelete: true,
});
