import { makeStyles } from 'tss-react/mui';
export const useTagStyles = makeStyles()(theme => ({
  chip: {
    margin: '3px',
    color: 'rgba(51, 51, 51, 1)',
    fontSize: '12px',
    background: theme.palette.components.chips.tags.backgroundColor,
    '& .MuiChip-deleteIcon': {
      display: 'none',
    },
    '& .MuiChip-label': {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
    '& #fullText': {
      display: 'block',
    },
    '& #shortenedText': {
      display: 'none',
    },
    '&:hover': {
      color: 'white !important',
      background: theme.palette.components.chips.secondary.backgroundColor,
      '& .MuiChip-deleteIcon': {
        display: 'block',
      },
      '& #fullText': {
        display: 'none',
      },
      '& #shortenedText': {
        display: 'block',
      },
    },
  },

  gridItemStyle: {
    alignSelf: 'center',
  },
  container: {
    width: 'fit-content',
    overflowX: 'hidden',
    overflowY: 'hidden',
    msOverflowStyle: 'none',
  },
  gridTooltip: {
    alignSelf: 'start',
  },
  addIconStyle: {
    marginTop: theme.spacing(2.5),
  },
}));
