import { Grid } from 'amn-ui-core';
import { Expandable } from 'app/components/Common/Expandable/Expandable';
import { convertToFormat } from 'app/helpers/dateHelper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { Concatenate } from 'utils/string/string';
import { Table } from '../../CommonTableComponents/CommonTableComponents';
import { StatusTitle } from '../../Titles/StatusTitle';
import { RequirementsContainer } from '../RequirementsContainer';
import { ExtAmnHousingRow } from './ExtAmnHousingRow';
import { ExtDateRow } from './ExtDateRow';
import { ExtNotesRow } from './ExtNotesRow';
import { ExtStatusRow } from './ExtStatusRow';
import { ExtTimeOffRow, IExtTimeOffItem } from './ExtTimeOffRow';
import { ExtensionEditIcon } from './ExtensionEditIcon';
import { useExtensionRequirementCounts } from '../RequirementCounts/TimeOffRequirementCounts';

interface IProps {
  status: {
    extensionStatusId: number;
    extensionStatus: string;
    updatedBy: string;
    updatedAt: string;
    displayUpdateSpecs: boolean;
  };
  dates: {
    duration: number;
    startDate: string;
    endDate: string;
  };
  timeOff: {
    request: IExtTimeOffItem[];
    reason: number;
  };
  notes: string;
  amnHousing: boolean;
  id;
}

const useStyles = makeStyles()({
  datesTitle: {
    fontWeight: 400,
    '@media (max-width: 980px)': {
      fontSize: 12,
    },
  },
});

export const ExtensionContainer: React.FC<IProps> = ({ status, dates, timeOff, notes, amnHousing, ...props }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const extensionDetailsCounts = useExtensionRequirementCounts(timeOff.reason, props.id);

  const dateString = React.useMemo(() => {
    const str = Concatenate(
      [convertToFormat(dates.startDate, 'MM/DD/yyyy'), convertToFormat(dates.endDate, 'MM/DD/yyyy')],
      ' - ',
    );
    return <span className={classes.datesTitle}>{` - ${str}`}</span>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates.endDate, dates.startDate]);

  return (
    <Expandable
      id={`extensionContainer-${props.id}`}
      unmountOnExit={false}
      preventForceClose
      titleComponent={
        <StatusTitle
          title={`${t('placement.profile.summary.extension.containerTitle')} - ${props.id + 1}`}
          titleAddOns={dateString}
          counts={{
            aggregate: extensionDetailsCounts.aggregate,
            passed: extensionDetailsCounts.passed,
            failed: extensionDetailsCounts.failed,
            risks: extensionDetailsCounts.risks,
          }}
          handleFilterChange={state => {}}
        />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RequirementsContainer
            title={t('placement.profile.summary.extension.detailsContainerTitle')}
            titleAddOns={
              <ExtensionEditIcon
                extContainerIndex={props.id}
                extensionStatusId={status.extensionStatusId}
                isHeader={true}
              />
            }
            counts={{
              aggregate: extensionDetailsCounts.aggregate,
              passed: extensionDetailsCounts.passed,
              failed: extensionDetailsCounts.failed,
              risks: extensionDetailsCounts.risks,
            }}
            handleFilterChange={state => {}}
          >
            <Table id={'id'}>
              <tbody>
                <ExtStatusRow {...status} />
                <ExtDateRow {...dates} extContainerIndex={props.id} extensionStatusId={status.extensionStatusId} />
                <ExtTimeOffRow
                  timeOff={timeOff.request}
                  extContainerIndex={props.id}
                  extensionStatusId={status.extensionStatusId}
                  timeOffReason={timeOff.reason}
                />
                <ExtNotesRow notes={notes} extContainerIndex={props.id} extensionStatusId={status.extensionStatusId} />
                <ExtAmnHousingRow
                  amnHousing={amnHousing}
                  extContainerIndex={props.id}
                  extensionStatusId={status.extensionStatusId}
                />
              </tbody>
            </Table>
          </RequirementsContainer>
        </Grid>
      </Grid>
    </Expandable>
  );
};
