import { IMergeToken } from '../confirmation-template-editor/types';
import { ITemplate } from '../confirmation-template-editor/types';

export enum ContractStatus {
  draft = 'Draft',
  created = 'Created',
  published = 'Published',
  publishedVoid = 'Published - Void',
  signed = 'Signed',
  pending = 'Pending',
  requestContract = 'Request Contract',
  okToSend = 'OK to Send',
  sent = 'Sent',
}

export enum DocSource {
  web = 'Web',
  classic = 'Classic',
}

export interface IContractDoc {
  id: string;
  documentId: string;
  documentImageId: string;
  source: DocSource;
  version: string;
  fileName: string;
  status: ContractStatus;
  templateText: string;
  isDraftManualEdit?: boolean;
  isDraft: boolean;

  /** Draft generated */
  draftCreatedBy: number;
  draftCreatedByName: string;
  draftCreatedOn: string;

  /** Draft updated */
  draftUpdateBy: number;
  draftUpdateByName: string;
  draftUpdateOn: string;

  /** Doc  created */
  docCreatedBy: number | null;
  docCreatedByName: string;
  docCreatedOn: string;

  /** Publish created */
  publishedBy: null;
  publishedByName: string;
  publishedOn: string;

  /** Signed created */
  signedBy: null;
  signedByName: string;
  signedOn: string;

  /** Void created */
  voidedBy: null;
  voidedByName: string;
  voidedOn: string;

  /** Account for all changes */
  updatedBy: number;
  updatedByName: string;
  updatedOn: string;

  /** Doc sent */
  sentBy: number;
  sentByName: string;
  sentDate: string;

  templateName: string;
  view: {
    draftGeneratedDate: string;
    docCreatedDate: string;
    cardUpdateDateString: string;
    updateDate: string;
    publishedDate: string;
    voidedDate: string;
    signedDate: string;
  };
}

export interface IPlacementCandidateContractDocEditor {
  templates: IContractDoc[];
  selectedTemplate: IContractDoc | null;
  mergeTokenList: { [key: string]: { label: string; tokens: IMergeToken[] } };
  miscellaneous: {
    forceModalCloseFlag?: boolean;
  };
  classicDraftExists: boolean;
  amieWebDraftExists: boolean;
  amieWebCreatedExists: boolean;
  /** Doc Specs - start */
  document?: { data: string; version: number; fileName?: string; documentId?: string };
  renderPreviewScreen?: boolean;
  renderPdfView?: boolean;
  /** Doc Specs - end */
}

export interface IContractDocPayload {
  placementId: number;
  template: ITemplate;
}
