import { CustomFilter } from 'app/ComponentLibrary/Filter';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GetAutoTaskFilters, defaultAutoTaskFilters } from './FilterHelpers';
import { IconButton } from 'amn-ui-core';
import { useDispatch } from 'react-redux';
import { SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import { makeStyles } from 'tss-react/mui';
import { ResetButton } from 'app/ComponentLibrary/Filter/SearchPageIcons/ResetButton';
import { usePromiseTracker } from 'react-promise-tracker';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles()({
  resetButtonVersion2: {
    padding: 0,
    height: '34px',
    width: '34px',
  },
  container: {
    width: '93vw',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
const AutoTaskFilters = ({ handleSubmit }: { handleSubmit: (val: any, sub?: any) => any }) => {
  const { t } = useTranslation();
  const { reset } = useFormContext();
  const { classes } = useStyles();
  const [filters, setFilters] = React.useState(GetAutoTaskFilters(t));
  const [hoverReset, setHoverReset] = React.useState(false);
  const [appliedDefaults, setAppliedDefaults] = React.useState(false);
  const { promiseInProgress: isLoading } = usePromiseTracker({ area: 'fetch-auto-task-grid-data', delay: 0 });
  const dispatch = useDispatch();
  const resetFilter = () => {
    reset(defaultAutoTaskFilters);
    handleSubmit(defaultAutoTaskFilters, {});
  };

  React.useEffect(() => {
    if (!isLoading && !appliedDefaults) {
      reset(defaultAutoTaskFilters);
      setAppliedDefaults(true);
      handleSubmit(defaultAutoTaskFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, appliedDefaults]);
  return (
    <div className={classes.container}>
      <div>
        <CustomFilter
          fields={filters}
          justifyContent={'flex-start'}
          version2
          resetBtn
          analytics={{
            caller: t('Auto Tasks'),
            onApply: true,
            properties: {
              category: SearchType.autoTasks,
            },
          }}
        />
      </div>
      <div>
        <IconButton
          color="primary"
          type="button"
          disableTouchRipple
          disableFocusRipple
          onMouseEnter={() => setHoverReset(true)}
          onMouseLeave={() => setHoverReset(false)}
          classes={{ root: classes.resetButtonVersion2 }}
          onClick={() => resetFilter()}
          size="small"
        >
          <ResetButton toggleReset={hoverReset} />
        </IconButton>
      </div>
    </div>
  );
};

export default AutoTaskFilters;
