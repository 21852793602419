import { FormName, SendType, UseSubType, UseType } from 'app/components/Notification/Constants';
import { ContactProps, IEmployee } from '../Notification/Notification';
import { IAssociatedRecord } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/OrderMatchTab/AlertStore/OrderMatchAlert.redux';

export interface IEmailNotDeliveredNotification {
  messageId: string;
  recipient: string;
  sender: string;
  channelType: string;
  event: string;
  subject: string;
  correlationId: string;
  publishedOn: string;
}

export interface IEmailData {
  attachmentData?: any;
  channel: string;
  sender: IEmployee | null;
  tos: ContactProps[];
  ccs: ContactProps[];
  bccs: IEmployee[];
  body: string;
  sendType: SendType | null;
  useType: UseType | null;
  useSubType?: UseSubType;
  userRole: string;
  emailType: string;
  subject: string;
  packetSubmission: boolean;
  confirmationLetter?: boolean;
  substitutions: any;
  selectedTemplate: {
    title: string;
    id: string;
    body: string;
    description: string;
    category: string;
    isPrivate: boolean;
    substitutions: any[];
  } | null;
  associatedRecords: IAssociatedRecord[];
  publicTemplates: any[];
  selectedPublicCategory: string;
  personalTemplates: any[];
  selectedPersonalCategory: string;
  formName: FormName | null;
  attachmentUrls?: IEmailAttachment[];
  attachmentCannotBeRemoved?: boolean;
  alertId?: string | null;
  alertPartitionKey?: string | null;
  tableData: {
    jobs?: {
      partialTemplateName: string;
      partialTemplate: string;
      partialData: any;
    };
    licenses?: {
      partialTemplateName: string;
      partialTemplate: string;
      partialData: any;
    };
    certifications?: {
      partialTemplateName: string;
      partialTemplate: string;
      partialData: any;
    };
  } | null;
  isInternal?: boolean;
  facilityOneToOne?: boolean;
  disableGroupEmailCheckbox?: boolean;
  populateEmpListForMarginTool?: boolean;
  initiationPoint?: string;
}

export interface ICategoryTitle {
  title: string;
  description: string;
  id: string;
}
export interface IEmail {
  data: IEmailData | null;
  open: Boolean;
  minimized: Boolean;
  expanded?: boolean;
  forwardAttachmentUrls?: string[];
  categoryTitles?: string[];
  packetCategoryTitles?: ICategoryTitle[];
  sendWithoutConsent?: boolean;
}

export interface IEmailAttachment {
  fileName: string;
  url: string;
  signedUrl: string;
}

export const EmailDefaultValues = (): IEmailData => {
  const emailData = {
    channel: '',
    sender: null,
    tos: [],
    ccs: [],
    bccs: [],
    body: '',
    sendType: null,
    useType: null,
    userRole: '',
    emailType: '',
    subject: '',
    packetSubmission: false,
    confirmationLetter: false,
    substitutions: '',
    selectedTemplate: null,
    associatedRecords: [],
    publicTemplates: [],
    selectedPublicCategory: '',
    personalTemplates: [],
    selectedPersonalCategory: '',
    formName: null,
    tableData: null,
    attachmentUrls: undefined,
    attachmentCannotbeRemoved: undefined,
    attachmentData: null,
    isInternal: false,
    facilityOneToOne: false,
    disableGroupEmailCheckbox: false,
    populateEmpListForMarginTool: false,
    initiationPoint: '',
  };
  return emailData;
};
