import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.createOrderOcbr || initialState;

export const selectCreateOrderOcbr = createSelector([selectDomain], data => data);
export const selectContractDetails = createSelector([selectDomain], data => data.contractDetails);

export const selectOffContractRateElements = createSelector(
  [selectDomain],
  orderState => orderState.offContractRateElements,
);
export const selectOcbrDetails = createSelector([selectDomain], orderState => orderState.ocbrDetails);
export const selectOcbrSavedDetails = createSelector([selectDomain], orderState => orderState.ocbrSavedDetails);
export const selectDocContainer = createSelector([selectDomain], orderState => orderState.docContainer);

export const selectCreateOrderMiscellaneousData = createSelector([selectDomain], data => data.miscellaneous);

export const selectCreateOrderAttributes = createSelector([selectDomain], data => data.featureAttributes);

export const selectRateOpts = createSelector([selectDomain], data => data.rateOpts);

export const selectRateFeatureProps = createSelector([selectDomain], data => data.featureProps);

export const selectBillRateSelections = createSelector([selectDomain], data => data.rateSelections);

export const selectRateCardValidationError = createSelector([selectDomain], data => data.rateCardValidationError);

export const selectOrderDetailsUpdated = createSelector([selectDomain], data => data.orderDetailsUpdated)
