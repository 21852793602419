import { ICustomHeader } from 'app/models/Grid';

interface IGridColumns extends Omit<ICustomHeader, 'title'> {
  title:
    | 'placementId'
    | 'placementStatus'
    | 'travelerId'
    | 'candidateName'
    | 'facilityId'
    | 'clientName'
    | 'startDate'
    | 'brandId'
    | 'daysBeforeStart'
    | 'followUpDate'
    | 'reminderType'
    | 'reminderStatus';
}

export const PlacementReminderColumns: IGridColumns[] = [
  {
    title: 'placementId',
    value: 'Placement ID (PID)',
    sortable: true,
    clickable: true,
    navigationIds: ['placementId'],
    navigationExp: '/placement/{0}',
    isNumber: true,
  },
  {
    title: 'candidateName',
    value: 'Candidate Name',
    clickable: true,
    sortable: true,
    navigationIds: ['travelerId', 'brandId'],
    navigationExp: '/candidate/{0}/{1}',
    sortByField: 'candidateLastName',
  },
  {
    title: 'placementStatus',
    value: 'Placement Status',
    sortable: true,
    sortByField: 'placementStatus',
  },
  {
    title: 'startDate',
    value: 'Start Date',
    sortable: true,
    defaultSort: true,
    sortByField: 'startDateNoFormat',
  },
  {
    value: 'Days Before Start',
    title: 'daysBeforeStart',
    sortable: true,
    sortByField: 'daysBeforeStart',
    isNumber: true,
  },
  {
    value: 'Reminder Date',
    title: 'followUpDate',
    sortable: true,
    sortByField: 'followUpDateNoFormat',
  },
  {
    value: 'Reminder Type',
    title: 'reminderType',
    sortable: true,
    navigationIds: ['placementId', 'eventTypeId'],
    navigationExp: '/placement/{0}?tab=2&eventType={1}',
    clickable: false,
  },
  {
    value: 'Reminder Status',
    title: 'reminderStatus',
    sortable: true,
  },
  {
    value: 'Client Name',
    title: 'clientName',
    sortable: true,
    clickable: true,
    navigationIds: ['facilityId'],
    navigationExp: '/facility/{0}',
  },
  {
    title: 'travelerId',
    value: 'Candidate (CID)',
    clickable: true,
    sortable: true,
    navigationIds: ['travelerId', 'brandId'],
    navigationExp: '/candidate/{0}/{1}',
    isNumber: true,
  },
];
