import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { getJobPreferenceData, getJobPreferencesFlattenedData } from 'app/components/Candidate/helper/gridHelper';
import { ExceptionType } from 'app/enums/Common';
import { CandidateService } from 'app/services/CandidateServices/candidate-service';
import { trackPromise } from 'react-promise-tracker';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { selectJobPreferencesData } from '../store/JobPreferences.selectors';
import { jobPreferncesActions } from './JobPreferences.redux';

const TrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'job-preferences');
const TrackNotificationToggle = (fn, ...args) => trackPromise(fn(...args), 'job-preference-toggle');

export function* getJobPreferences(
  action: PayloadAction<{
    travelerId: number;
    brandId: number;
  }>,
) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    yield put(jobPreferncesActions.setLoading(true));
    const response = yield call(
      TrackWrapper,
      candidateService.getCandidateJobPreferences,
      action.payload.travelerId,
      action.payload.brandId,
    );

    if (response) {
      const data = yield call(TrackWrapper, getJobPreferenceData, response.data);
      yield put(jobPreferncesActions.setData(data));
      const flattenedData = getJobPreferencesFlattenedData(data);
      yield put(jobPreferncesActions.setFlattenedData(flattenedData));
      yield put(jobPreferncesActions.setLoading(false));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getJobPreferences',
        area: 'src/app/components/Candidate/CandidateProfile/CandidateTabPanel/JobPreferencesTab/store/JobPreferences.saga.ts',
      },
    });
    yield put(jobPreferncesActions.setLoading(false));
  }
}

export function* toggleJobPreferenceNotification(
  action: PayloadAction<{
    candidateId: string;
    brandId: string;
    id: string;
    isOff: boolean;
  }>,
) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');

    yield put(jobPreferncesActions.setLoading(true));

    const response = yield call(
      TrackNotificationToggle,
      candidateService.toggleCandidateJobPreferenceNotification,
      action.payload,
    );

    if (response.status === 200) {
      const jobPreferencesData = yield select(selectJobPreferencesData);
      const newData = jobPreferencesData.jobPreferencesData.map(pref => ({
        ...pref,
        isOff: pref.id === action.payload.id ? action.payload.isOff : pref.isOff,
      }));
      yield put(jobPreferncesActions.setData(newData));
      yield put(
        globalActions.setSnackBar({
          message: response.data.message,
          severity: 'success',
          justify: 'center',
        }),
      );
    } else {
      yield put(
        globalActions.setSnackBar({
          message: 'Failed to save notification preference',
          severity: 'error',
          justify: 'center',
        }),
      );
    }
    yield put(jobPreferncesActions.setLoading(false));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getJobPreferences',
        area: 'src/app/components/Candidate/CandidateProfile/CandidateTabPanel/JobPreferencesTab/store/JobPreferences.saga.ts',
      },
    });
    yield put(jobPreferncesActions.setLoading(false));
  }
}

export function* jobPreferencesSaga() {
  yield takeLatest(jobPreferncesActions.getJobPreferencesAction.type, getJobPreferences);
  yield takeLatest(jobPreferncesActions.toggleJobPreferenceNotificationAction.type, toggleJobPreferenceNotification);
}
