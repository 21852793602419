import { Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';

export const useAddEditReferenceStyle = makeStyles()((theme: Theme) => ({
  addReference: {
    color: theme.palette.system.black,
    '&:hover': {
      color: `${theme.palette.system.main}`,
      cursor: 'pointer',
    },
  },
  disabledAddReference: {
    color: theme.palette.system.lightGrey,
  },
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50%',
  },
  activityAction: {
    color: theme.palette.system.black,
    '&:hover': {
      color: theme.palette.system.main,
      cursor: 'pointer',
    },
  },
  activityActionDisabled: {
    color: theme.palette.system.lightGrey,
  },
  addButtonRoot: {
    color: theme.palette.framework.system.defaultGray,
  },
  phonelabel: {
    '&.react-tel-input .special-label': {
      display: 'inline-flex',
      position: 'absolute',
      top: '5px',
      left: '70px',
      background: 'transparent',
      fontSize: '11px',
      color: theme.palette.system.darkGrey,
    },
    '&.react-tel-input .form-control': {
      paddingTop: theme.spacing(3),
    },
  },
  textAreaInputStyle: {
    '& textarea': {
      minHeight: 90,
      maxHeight: 90,
      overflow: 'scroll !important',
    },
  },
  AdditionalInfoTitle: {
    fontSize: '16px !important',
  },
  datePickerInput: {
    height: '55px',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.37)',
    '&.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.37)',
    },
    '&[class*="MuiInputLabel-outlined"]': {
      marginTop: '1px',
    },
  },
}));
