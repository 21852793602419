import { Collapse, Grid, Theme } from 'amn-ui-core';
import React, { useRef } from 'react';
import { selectFilters, selectGridState } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';
import { useEffect, useState } from 'react';
import { ChipsContainer } from 'app/ComponentLibrary/Filter';
import { GridTag } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { OrderFilterNames } from './OrdersFilterUtils';
import { SearchPageIcons } from 'app/ComponentLibrary/Filter/SearchPageIcons';
import { determineChipSelected } from '@AMIEWEB/AmWorkDesk/PlacementsDesk/filterHelper';
import { generateChips } from '@AMIEWEB/GlobalSearch/helper';
import { instanceOfISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import { makeStyles } from 'tss-react/mui';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()((theme: Theme) => ({
  filterCollapse: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '3px',
  },
}));
export const OrderBonusChips = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { setValue, getValues } = useFormContext();
  const { collapsible = true } = props;
  const [showChipsCollapseBtn, setShowChipsCollapseBtn] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const selectedFilter = useSelector(selectFilters);
  const gridState = useSelector(selectGridState);
  const [chips, setChips] = useState({});

  const handleChange = () => {
    setChecked(prev => !prev);
  };

  const getNewSelection = (key, chip) => ({
    ...selectedFilter?.selectedChipFilters,
    [key]: determineChipSelected(
      chip,
      selectedFilter?.selectedChipFilters ? selectedFilter?.selectedChipFilters[key] : undefined,
    ),
  });

  const handleChipDelete = (field, chip) => {
    const fieldValues = getValues(field) ?? getValues(chip.value);
    let newFieldValues: any = null;
    if (Array.isArray(fieldValues)) {
      newFieldValues = [...fieldValues];
      newFieldValues.splice(
        newFieldValues.findIndex(opt => (instanceOfISelection(chip.Value) ? opt.value : opt) === chip.value),
        1,
      );
      setValue(field, newFieldValues, { shouldDirty: true });
    } else setValue(field, null);
    if (field === OrderFilterNames.state) {
      const compactOptIndex = fieldValues.findIndex(
        item => item.isCompactOpt && item.value.some(s => s.value === chip.value),
      );
      newFieldValues = fieldValues.filter(item => item.value !== chip.value);
      if (compactOptIndex !== -1) newFieldValues.splice(compactOptIndex, 1);
      setValue(field, newFieldValues, { shouldDirty: true });
    }
    if (chip.isSelected) {
      const newSelections = getNewSelection(field, chip);
      props.onApplyFilter(
        { ...getValues(), [field]: Array.isArray(fieldValues) ? [...(newFieldValues || [])] : null },
        newSelections,
      );
    } else props.onApplyFilter(getValues());
  };

  const handleChipClick = (key, chip) => {
    if (!Array.isArray(getValues(key))) return;
    const newChipSelection = getNewSelection(key, chip);
    generateChips({ ...getValues() }, t, props.selectedSearchType, false, newChipSelection);
    props.onApplyFilter(getValues(), newChipSelection);
  };

  useEffect(() => {
    if (gridState.gridTag === GridTag.CopyOrderBonusGrid) {
      selectedFilter?.filters &&
        setChips({
          ...chips,
          ...generateChips(
            selectedFilter?.filters,
            t,
            props.selectedSearchType,
            false,
            selectedFilter?.selectedChipFilters,
          ),
        });
    }
  }, [selectedFilter?.filters]);

  useEffect(() => {
    const chipsData = Object.keys(chips).some(key => {
      const value = chips[key];
      if (Array.isArray(value) && value.length > 0) {
        return true;
      }
      if (typeof value === 'object' && value !== null && Object.keys(value).length > 0) {
        return true;
      }
      return false;
    });
    setShowChipsCollapseBtn(chipsData);
  }, [chips]);

  return (
    <>
      {showChipsCollapseBtn && collapsible ? (
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Collapse in={checked} collapsedSize={38}>
              <div ref={divRef}>
                <ChipsContainer
                  chips={chips}
                  onDelete={handleChipDelete}
                  onClick={handleChipClick}
                  isCollapse={!checked}
                />
              </div>
            </Collapse>
          </Grid>
          <Grid item xs={1} className={classes.filterCollapse}>
            {(divRef?.current?.clientHeight ?? 0) > 38 && (
              <SearchPageIcons KeyboardArrowIcon onAdvancedChipsClick={() => handleChange()} checked={checked} />
            )}
          </Grid>
        </Grid>
      ) : (
        <>
          <ChipsContainer chips={chips} onDelete={handleChipDelete} onClick={handleChipClick} />
        </>
      )}
    </>
  );
};
