import { createAction } from "@reduxjs/toolkit";
import { IHandleBulkMarkReadAction, IUpdateNotificationReadStatusAction } from "app/models/Tasks/Tasks";

const businessUpdateNotificationReadStatusSaga = createAction<IUpdateNotificationReadStatusAction>('BUSINESS_UPDATE_NOTIFICATION_READ_STATUS_SAGA');
const businessInitNotifications = createAction<number | null>('business_inti_Notifications');
const businessHandleBulkMarkRead = createAction<IHandleBulkMarkReadAction>('business_handle_bulk_mark_read_notification');

export const businessActions = {
    businessUpdateNotificationReadStatusSaga,
    businessInitNotifications,
    businessHandleBulkMarkRead
};