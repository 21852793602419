import React, { useState } from 'react';
import { Grid, Theme } from 'amn-ui-core';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { candidateDetailsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { FormInputDatePicker } from './Controls/FormInputDatePicker';
import { makeStyles } from 'tss-react/mui';
import { FormInputDropdown } from './Controls/FormInputDropdownV2';

const useStyles = makeStyles()((theme: Theme) => ({
  datePickerInput: {
    height: '55px',
  },
  label: {
    fontSize: '14px !important',
    color: 'rgba(0, 0, 0, 0.37)',
    '&.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.37)',
    },
    '&[class*="MuiInputLabel-outlined"]': {
      marginTop: '1px',
    },
  },
}));

export const AddPermanentTaxForm = props => {
  const { classes } = useStyles();
  const { getValues, clearErrors, setError } = useFormContext();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const taxLetterInfo = candidateDetails.taxLetterInfo;
  const [showError, setShowError] = useState<string>('');

  const handleEffectiveDateChange = event => {
    const date = moment(event);
    const effectiveDate = date.isValid() ? moment(date).format('YYYY-MM-DDTHH:mm:ss') : null;
    const filteredTaxInfo = taxLetterInfo?.filter(
      item => moment(item.updateDate).format('YYYY-MM-DDTHH:mm:ss') === effectiveDate,
    );
    setShowError(undefined);
    clearErrors('pEffectiveDate');
    setTimeout(() => {
      if (filteredTaxInfo.length > 0) {
        setShowError('Effective date already exists');
        setError('pEffectiveDate', { type: 'custom', message: '' });
      } else {
        setShowError(undefined);
        clearErrors('pEffectiveDate');
      }
    }, 100);
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item width={'100%'}>
        <FormInputDropdown
          name="pWithhold"
          label={'Withhold *'}
          required={false}
          isDisabled={false}
          options={props.options}
        />
      </Grid>
      <Grid item width={'100%'}>
        <FormInputDatePicker
          name={'pEffectiveDate'}
          label={'Effective Date *'}
          handleChange={handleEffectiveDateChange}
          customClass={classes.datePickerInput}
          labelClass={classes.label}
          trailingIconVisible={true}
          quickSelect={false}
          maxWidth={400}
          customErrorMessage={showError}
          inputValue={getValues('pEffectiveDate')}
        ></FormInputDatePicker>
      </Grid>
    </Grid>
  );
};
