import React, { useEffect, useState } from 'react';
import { Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { IOrderDetails } from 'app/models/Orders/OrderDetails';
import { OrderDetailsQualification } from './OrderDetailsDataComponents/OrderDetailsQualification';
import { OrderDetailsEMR } from './OrderDetailsDataComponents/OrderDetailsEMR';
import { OrderDetailsExperience } from './OrderDetailsDataComponents/OrderDetailsExperience';
import { OrderDetailsFlexibleSchedule } from './OrderDetailsDataComponents/OrderDetailsFlexibleSchedule';
import { OrderDetailsFloat } from './OrderDetailsDataComponents/OrderDetailsFloat';
import { OrderDetailsFreeTextFieldContainer } from './OrderDetailsDataComponents/OrderDetailsFreeTextFieldContainer';
import { OrderDetailsNonStandardRequirement } from './OrderDetailsDataComponents/OrderDetailsNonStandardRequirement';
import { OrderDetailsOrderPriorities } from './OrderDetailsDataComponents/OrderDetailsOrderPriorities';
import { OrderDetailsReferences } from './OrderDetailsDataComponents/OrderDetailsReferences';
import { OrderDetailsSkills } from './OrderDetailsDataComponents/OrderDetailsSkills';
import { OrderDetailsTravel } from './OrderDetailsDataComponents/OrderDetailsTravel';
import { OrderDetailsVirtualRating } from './OrderDetailsDataComponents/OrderDetailsVirtualRating';
import { OrderDetailsCurrentPlacements } from './OrderDetailsDataComponents/OrderDetailsCurrentPlacements';
import { MediaQuery } from 'app/layout/MediaQuery';
import { OrderDetailsTechnologyInformation } from './OrderDetailsDataComponents/OrderDetailsTechnologyInformation';
import { OrderDetailsStrikeDetails } from './OrderDetailsDataComponents/OrderDetailsStrikeDetails';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { OrderDetailsShifts } from './OrderDetailsDataComponents/OrderDetailsShifts';
import { useDispatch } from 'react-redux';
import {
  orderPreferenceActions,
  orderPreferenceReducer,
  orderPreferenceSliceKey,
} from 'app/components/Order/OrderDetails/OrderPreferences/store/OrderPreference.redux';
import { orderDataActions, orderDataReducer, orderDataSliceKey } from 'app/components/Order/Store/Order.redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { orderPreferenceSaga } from 'app/components/Order/OrderDetails/OrderPreferences/store/OrderPreference.saga';
import { isNotInteger } from 'app/helpers/objectHelpers';
import { orderDataSaga } from '../../Store/Order.saga';
import { useDecision } from '@optimizely/react-sdk';
import { ff_order_ui_skillssection } from 'app/constants/FeatureFlags/Order/Keys';
import { OrderDetailsRateCard } from './OrderDetailsDataComponents/RateCard/OrderDetailsRateCard';
import { ff_order_ui_rate_card } from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';
import { AMNDivision } from 'app/models/enums/AMNDivisionType';

interface Props {
  orderDetails: IOrderDetails;
  disableEdit?: boolean;
  onChange: (key: string, value: any) => void;
  handleEditOrder?: () => void;
}

const useStyles = makeStyles()(theme => ({
  root: {
    paddingBottom: theme.spacing(15),
  },
}));

export const OrderDetailsDataWrapper = (props: Props) => {
  const { orderDetails, disableEdit, onChange, handleEditOrder } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  useInjectReducer({ key: orderPreferenceSliceKey, reducer: orderPreferenceReducer });
  useInjectSaga({ key: orderPreferenceSliceKey, saga: orderPreferenceSaga });
  useInjectReducer({ key: orderDataSliceKey, reducer: orderDataReducer });
  useInjectSaga({ key: orderDataSliceKey, saga: orderDataSaga });
  const STRIKE = 'STRIKE';
  const [showStrikeDetails, setShowStrikeDetails] = useState<boolean>(false);
  const [skillsSectionDecision] = useDecision(ff_order_ui_skillssection);
  const [rateCardFlag] = useDecision(ff_order_ui_rate_card);

  useEffect(() => {
    const orderId = parseInt(orderDetails.orderId);
    const facilityId = orderDetails?.facility?.facilityId;
    if (!isNotInteger(orderId)) {
      dispatch(orderPreferenceActions.getAutomationStatusByStage({ orderId }));
    }

    if (orderDetails.division.toUpperCase() === AMNDivision.HSG.toUpperCase() && orderDetails.orderType.toUpperCase() === STRIKE) {
      setShowStrikeDetails(true);
    }

    if (orderId && facilityId) {
      const payload = {
        facilityId: facilityId,
        orderId: orderId,
      };
      dispatch(orderDataActions.getOrderStrike(payload));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetails]);

  return (
    <div className={classes.root}>
      <Typography variant="body1">
        <LayoutGrid container direction="row">
          <LayoutGridItem item lg={4} xs={6}>
            <LayoutGrid container>
              <OrderDetailsExperience
                skillsets={orderDetails.skillsets}
                newGrad={orderDetails.newGrad}
                disableEdit={disableEdit}
                handleEditOrder={handleEditOrder}
              />
              <OrderDetailsTravel
                data={{ travelExperience: orderDetails.unit.travelExperience }}
                disableEdit={disableEdit}
                handleEditOrder={handleEditOrder}
              />
              <OrderDetailsShifts
                disableEdit={disableEdit}
                onUpdate={onChange}
                data={{ shifts: orderDetails.shifts }}
                facilityId={orderDetails?.facility?.facilityId}
                orderId={parseInt(orderDetails.orderId)}
              />
              <OrderDetailsEMR
                id={'OrderDetailsEmr'}
                data={{ emr: orderDetails.unit.qualification && orderDetails.unit.qualification.emr }}
              />
              <OrderDetailsQualification
                data={{ qualification: orderDetails.unit.qualification, state: orderDetails.location.state }}
                disableEdit={disableEdit}
                handleEditOrder={handleEditOrder}
              />
              {skillsSectionDecision.enabled && (
                <OrderDetailsSkills
                  data={{ qualification: orderDetails.unit.qualification, state: orderDetails.location.state }}
                  disableEdit={disableEdit}
                  handleEditOrder={handleEditOrder}
                />
              )}
              <OrderDetailsNonStandardRequirement
                data={{ nonStandardReq: orderDetails.unit.nonStandardRequirement }}
                disableEdit={disableEdit}
                handleEditOrder={handleEditOrder}
              />
              <OrderDetailsFloat data={{ float: orderDetails.unit.float }} handleEditOrder={handleEditOrder} />
              <MediaQuery maxWidth="md">
                <OrderDetailsTechnologyInformation
                  disableEdit={disableEdit}
                  data={{
                    vmsBillRate: orderDetails.vmsBillRate,
                    vmsOrderNumber: orderDetails.vmsOrderNumber,
                    weblink: orderDetails.webLink,
                    technologyVendor: orderDetails.technologyVendor,
                    staffingVendor: orderDetails.staffingVendor,
                    fyre: orderDetails.unit.fyre,
                  }}
                  onChange={onChange}
                />
              </MediaQuery>
            </LayoutGrid>
          </LayoutGridItem>
          <LayoutGridItem item lg={8} xs={6}>
            <LayoutGrid container>
              <LayoutGridItem item xs={12} lg={6}>
                <LayoutGrid container>
                  <OrderDetailsFreeTextFieldContainer
                    disableEdit={disableEdit}
                    onUpdate={onChange}
                    data={{
                      internalNotes: orderDetails.internalNotes,
                      externalJobDescription: orderDetails.jobDescription,
                      preferredQualifications: orderDetails.preferredQualifications,
                      requiredQualifications: orderDetails.requiredQualifications,
                    }}
                  />
                  <OrderDetailsOrderPriorities data={{ orderPriorities: orderDetails.orderPriorities }} />
                  <OrderDetailsVirtualRating data={{ VIRating: orderDetails.unit.virtualRating }} />
                  <OrderDetailsReferences data={{ references: orderDetails.unit.reference }} />
                  <OrderDetailsFlexibleSchedule data={{ flexibleSchedule: orderDetails.unit.flexibleSchedule }} />
                </LayoutGrid>
              </LayoutGridItem>
              <LayoutGridItem item xs={12} lg={6}>
                <LayoutGrid container>
                  <MediaQuery minWidth="lg">
                    <OrderDetailsTechnologyInformation
                      disableEdit={disableEdit}
                      data={{
                        vmsBillRate: orderDetails.vmsBillRate,
                        vmsOrderNumber: orderDetails.vmsOrderNumber,
                        weblink: orderDetails.webLink,
                        technologyVendor: orderDetails.technologyVendor,
                        staffingVendor: orderDetails.staffingVendor,
                        fyre: orderDetails.unit.fyre,
                      }}
                      onChange={onChange}
                    />
                  </MediaQuery>
                  {rateCardFlag.enabled &&  <LayoutGridItem item xs={12}>
                    <OrderDetailsRateCard disableEdit={disableEdit} />
                  </LayoutGridItem>}
                  <OrderDetailsCurrentPlacements
                    data={{
                      currentPlacements: orderDetails.orderCurrentPlacements && orderDetails.orderCurrentPlacements,
                    }}
                  />
                  {showStrikeDetails && <OrderDetailsStrikeDetails disableEdit={disableEdit} />}
                </LayoutGrid>
              </LayoutGridItem>
            </LayoutGrid>
          </LayoutGridItem>
        </LayoutGrid>
      </Typography>
    </div>
  );
};
