import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import {
  EPLacementStatusRequirementTooltipType,
  IPlacementRequirementKey,
} from 'app/models/Placement/PlacementDetails';
import { requirementType } from 'app/models/Placement/PlacementSummary';
import { CredentialingService } from 'app/services/CredentialingServices/credentialing-service';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { trackPromise } from 'react-promise-tracker';
import { all, call, fork, getContext, put, select } from 'redux-saga/effects';
import { SetPlacementFailRiskRequirementMessage } from 'store/redux-store/new-placement/saga';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import InitPlacementSummary, { IConsolidatedRequirementResponse } from '../init.types';
import { SkillRequirementMapper } from '../SkillRequirementMapper';
import { IPlacementSummaryRequest, placementSummaryActions } from '../slice';

const TrackGroupedSkillsetReq = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-grouped-skillset-requirement');

const TrackCandidateRecordsRightWrapper = (fn, ...args) => trackPromise(fn(...args), 'candidate-records-right');

export function* processgroupedSkillsetRequirement(
  response: IConsolidatedRequirementResponse<any>,
  context: InitPlacementSummary,
  lookupCert: any,
) {
  try {
    if (!httpSuccess(response.status)) {
      context.setServiceFailureByKey({ key: 'groupedSkillsetRequirement', failureStatus: true });
      return;
    }
    const data = response.data.groups?.[0];
    if (!!data) {
      yield put(
        placementSummaryActions.setSubmissionSkillsetCandidateRequirements(response.data.candidateRequirements),
      );
      const mapper = new SkillRequirementMapper(
        data.operation,
        data.skillsets,
        data.requirements,
        lookupCert.data,
        response.data.candidateRequirements?.certifications,
      );
      const reqData = mapper.getRequirementsData();
      context.setSubmissionSkillsetRequirements(reqData);
      yield call(common, reqData);
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'processgroupedSkillsetRequirement',
        area: 'src/store/redux-store/placement-summary/common-saga/processgroupedSkillsetRequirement.ts',
      },
    });
  }
}

export function* requestGroupedSkillsetRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const credentialingService: CredentialingService = yield getContext('credentialingService');
    const placementOrderType = yield select(selectPlacementOrderType);
    const [response, lookupCert] = yield all([
      call(
        TrackGroupedSkillsetReq,
        placementService.getPlacementSummaryGroupedSkillsetReq,
        action.payload.placementId,
        placementOrderType,
      ),
      call(TrackCandidateRecordsRightWrapper, credentialingService.getCredentialLookups, 'all'),
    ]);

    if (httpSuccess(response?.status)) {
      const data = response.data.groups?.[0];
      if (!!data) {
        yield put(
          placementSummaryActions.setSubmissionSkillsetCandidateRequirements(response.data?.candidateRequirements),
        );
        const mapper = new SkillRequirementMapper(
          data.operation,
          data.skillsets,
          data.requirements,
          lookupCert.data,
          response.data?.candidateRequirements?.certifications,
        );
        const reqData = mapper.getRequirementsData();
        yield put(placementSummaryActions.setSubmissionSkillsetRequirements(reqData));
        yield call(common, reqData);
      }
    } else
      yield put(
        placementSummaryActions.setServiceFailureByKey({
          key: 'groupedSkillsetRequirement',
          failureStatus: true,
        }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestGroupedSkillsetRequirement',
        area: 'src/store/redux-store/placement-summary/common-saga/requestGroupedSkillsetRequirement.ts',
      },
    });
  }
}

function* common(reqData) {
  const { experience, licenses, certifications } = reqData.containerRegistry.primary;

  // #region Set-Fail-Risk-Requirement-LICENSES
  yield fork(SetPlacementFailRiskRequirementMessage, {
    payload: {
      messageId: licenses?.messageId,
      version: licenses?.messageVersion,
      passFailType: licenses?.type,
      data: licenses?.data,
      messageTypeId: IPlacementRequirementKey.orderLicense,
      tooltipTypeParam: EPLacementStatusRequirementTooltipType.messageWithSupportList,
      nextStatusRequirementParam: licenses.nextStatusRequirement,
    },
    type: placementSummaryActions.setFailedRequirement.type,
  });

  if (licenses?.data?.riskRequirementCount > 0)
    yield fork(SetPlacementFailRiskRequirementMessage, {
      payload: {
        messageId: licenses?.messageId,
        version: licenses?.messageVersion,
        passFailType: requirementType.risk,
        data: licenses?.data,
        messageTypeId: IPlacementRequirementKey.orderLicense,
        tooltipTypeParam: EPLacementStatusRequirementTooltipType.messageWithSupportList,
        nextStatusRequirementParam: licenses.nextStatusRequirement,
      },
      type: placementSummaryActions.setFailedRequirement.type,
    });
  // #endregion Set-Fail-Risk-Requirement-LICENSES
  // #region Set-Fail-Risk-Requirement-CERTIFICATIONS
  yield fork(SetPlacementFailRiskRequirementMessage, {
    payload: {
      messageId: certifications?.messageId,
      version: certifications?.messageVersion,
      passFailType: certifications?.type,
      data: certifications?.data?.failRequirementCount,
      messageTypeId: IPlacementRequirementKey.orderCertifications,
      tooltipTypeParam: EPLacementStatusRequirementTooltipType.messageWithSupportList,
      nextStatusRequirementParam: certifications.nextStatusRequirement,
    },
    type: placementSummaryActions.setFailedRequirement.type,
  });

  if (certifications?.data?.riskRequirementCount)
    yield fork(SetPlacementFailRiskRequirementMessage, {
      payload: {
        messageId: certifications?.messageId,
        version: certifications?.messageVersion,
        passFailType: requirementType.risk,
        data: certifications?.data?.riskRequirementCount,
        messageTypeId: IPlacementRequirementKey.orderCertifications,
        tooltipTypeParam: EPLacementStatusRequirementTooltipType.messageWithSupportList,
        nextStatusRequirementParam: certifications.nextStatusRequirement,
      },
      type: placementSummaryActions.setFailedRequirement.type,
    });
  // #endregion Set-Fail-Risk-Requirement-CERTIFICATIONS
  // #region Set-Fail-Risk-Requirement-EXPERIENCE
  yield fork(SetPlacementFailRiskRequirementMessage, {
    payload: {
      messageId: experience?.messageId,
      version: experience?.messageVersion,
      passFailType: experience?.type,
      data: experience?.data,
      messageTypeId: IPlacementRequirementKey.orderExperience,
      tooltipTypeParam: EPLacementStatusRequirementTooltipType.messageWithSupportList,
      nextStatusRequirementParam: experience.nextStatusRequirement,
    },
    type: placementSummaryActions.setFailedRequirement.type,
  });
  // #endregion Set-Fail-Risk-Requirement-EXPERIENCE
}
