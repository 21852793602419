/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui';
import { CustomFilter } from 'app/ComponentLibrary/Filter';
import React, { useState, useEffect, createRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { defaultFilterChipSelected, placementReminderActions } from '../Store/PlacementReminders.redux';
import { selectFilterSpecs } from '../Store/PlacementReminders.selector';
import { defaultFilterValues, getFilterSpecs } from './PlacementRemindersConfig';

const useStyles = makeStyles()({
  placementIdField: {
    minWidth: 125,
  },
  candidateNameField: {
    minWidth: 140,
  },
  placementStartDateField: {
    minWidth: 135,
  },
  placementStatusField: {
    minWidth: 150,
  },
  clientNameField: {
    minWidth: 125,
  },
  disciplineSpecialtyField: {
    minWidth: 150,
  },
  candidateIdField: {
    minWidth: 125,
  },
  reminderStatusField: {
    minWidth: 135,
  },
  reminderTypeField: {
    minWidth: 135,
  },
  reminderDateField: {
    minWidth: 135,
  },
  startDateField: {
    minWidth: 135,
  },
  dayBeforeStartField: {
    minWidth: 135,
  },
});

export const PlacementReminderFilter = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const filterSpecs = useSelector(selectFilterSpecs);
  const [fields, setFields] = useState<any>(undefined);
  const dispatch = useDispatch();
  const hiddenSubmitButton = createRef<HTMLButtonElement>();

  //const dispatch = useDispatch();
  const {
    setValue,
    formState: { dirtyFields },
  } = useFormContext();

  const resetFilter = () => {
    dispatch(placementReminderActions.setStateID('all'));
    Object.entries(defaultFilterValues).map(item => setValue(item[0], item[1]));
    dispatch(placementReminderActions.setFilterChipSelected(defaultFilterChipSelected));
    //Reset
    dispatch(placementReminderActions.setCounts(null));
    props.onApplyFilter(defaultFilterValues, defaultFilterChipSelected);
  };

  useEffect(() => {
    if (filterSpecs) {
      setFields(getFilterSpecs(filterSpecs, t, classes));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSpecs]);

  const onFilterChangeValues = (fieldName, newValue) => {
    if (['startDate', 'reminderDate', 'daysBeforeStart'].includes(fieldName)) {
      //CHECK: To Generated Chip after Submit, Comment below Code & Uncomment Above
      hiddenSubmitButton.current?.click();
    }
  };

  return (
    <>
      {filterSpecs && fields && (
        <CustomFilter
          fields={fields}
          onResetForm={() => resetFilter()}
          justifyContent={'flex-start'}
          version2
          falseProp={dirtyFields}
          onChangeValues={onFilterChangeValues}
          analytics={{
            caller: 'Credentialing WorkDesk Filter',
            onApply: true,
            properties: {
              category: 'Placement Reminders',
            },
          }}
        />
      )}
      <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
    </>
  );
};
