import { CircularProgress, Grid } from 'amn-ui-core';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  loadingGrid: {
    height: '100%',
  },
});
export const Loader = ({ size = 40 }) => {
  const { classes } = useStyles();
  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.loadingGrid}>
      <Grid item>
        <CircularProgress size={size} />
      </Grid>
    </Grid>
  );
};
