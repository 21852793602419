import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { useWatch } from 'react-hook-form';
import { useEditableRequirement } from './EditRequirementsHelper';
import { TravelTimePreferenceCheck } from './EditablePassFailLogic';

export const useEditableRequirementTravelTimePreference = () => {
  // TRAVEL TIME PREFERENCE
  const travelTimePreference: any = useWatch({ name: 'travelTimePreferenceChosen' });
  const transportation: any = useWatch({ name: 'transportationChosen' });

  useEditableRequirement({
    data: travelTimePreference?.object?.travelTimePreference,
    secondaryData: transportation?.transportation,
    requirementId: IPlacementRequirementKey.travelTimePreference,
    requirementKey: 'travelTimePreference',
    requirementCheck: TravelTimePreferenceCheck,
    shouldAddFailedRequirement: transportation?.transportation !== 'Unknown',
    failRequirement: {
      id: IPlacementRequirementKey.travelTimePreference,
      message: 'Travel Time Preference: Missing',
      tooltip: {
        message: 'Travel Time Preference is required',
      },
    },
  });
};
