import { List, ListItem, ListItemAvatar, ListItemText } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { missingField } from 'app/constants';
import React from 'react';

const useStyles = makeStyles<{ avatarMargin?: number }>()((theme, { avatarMargin }) => ({
  root: {
    marginTop: '-3px',
  },
  item: {
    padding: 0,
    fontSize: 16,
    height: 'min-content',
  },
  icon: {
    minWidth: 30,
    flexShrink: 0,
    marginTop: avatarMargin ?? 1,
  },
  primary: {
    whiteSpace: 'pre-wrap',
  },
}));

interface ITableSection {
  items: ({
    icon?: any;
    primaryContent?: any;
    secondaryContent?: any;
  } | null)[];
  avatarMargin?: number;
}

export const TableSection = ({ items, avatarMargin }: ITableSection) => {
  const { classes } = useStyles({ avatarMargin });
  return items ? (
    <List disablePadding classes={{ root: items.findIndex(item => !!item?.icon) === 0 ? classes.root : undefined }}>
      {items.map((item, index) => (
        <React.Fragment key={index.toString()}>
          {item && (
            <ListItem disableGutters classes={{ root: classes.item }} alignItems="flex-start" key={index.toString()}>
              {item.icon && <ListItemAvatar classes={{ root: classes.icon }}>{item.icon}</ListItemAvatar>}
              <ListItemText
                primary={item.primaryContent}
                classes={{ primary: classes.primary }}
                secondary={item.secondaryContent}
              />
            </ListItem>
          )}
        </React.Fragment>
      ))}
    </List>
  ) : (
    <>{missingField}</>
  );
};
