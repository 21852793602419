import { StatusUpdateModalVersion, TaskStatus } from 'app/models/Tasks/Tasks';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StatusIcon } from '../CustomComponents/StatusIcon';
import BlockIcon from '@mui/icons-material/Block';
import { taskDetailsActions } from '../store/Tasks.redux';
import { IconButton } from 'amn-ui-core';
import { getAssignee, getSubTaskRecipientList } from './helper';
import { selectUser } from 'oidc/user.selectors';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { selectActiveCoverage } from 'store/redux-store/user-coverage/selectors';
import { checkIfActiveUserRecipient, isActiveUserCoveringCreatedBy } from './newTaskStatusHelper';
import { getEmployeeId } from '@AMIEWEB/Notification/Tasks/Common_v2/utils';

export const RecipientStatusIcon = ({
  classes,
  details,
  isExternal = false,
  isEligableToUpdate,
  isSubtask = false,
  statusDetails,
  coverageUser,
}: {
  classes: any;
  details: any;
  isExternal?: boolean;
  isEligableToUpdate: boolean;
  isSubtask?: boolean;
  statusDetails: any;
  coverageUser?: any;
}) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUser);
  const user = userInfo?.userInfo;
  const activeCoverage = useSelector(selectActiveCoverage);
  const [isClickable, setClickable] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(true);
  const [isStatusRevertible, setIsStatusRevertible] = useState<boolean>(false);
  const [assigneeData, setAssigneeData] = useState<any>(null);
  const [checkCreatedByEligibility, setCreatedByEligibilty] = useState<boolean>(false);

  const onHover = async () => {
    if (!isExternal) {
      const checkErrorEligibility = details?.taskStatusDetails?.find(
        taskStatusData => taskStatusData?.statusUpdateReasonId === 5,
      );
      if (checkErrorEligibility) {
        setIsStatusRevertible(false);
        setIsEditable(false);
      } else {
        if (coverageUser) {
          if (isActiveUserCoveringCreatedBy(activeCoverage, details?.createdBy?.id) && !checkErrorEligibility) {
            setIsEditable(true);
          }
          if (checkIfActiveUserRecipient(details, coverageUser?.id)) {
            setIsEditable(true);
            if (
              coverageUser?.statusId === TaskStatus.Completed ||
              (coverageUser?.statusId === TaskStatus.Cancelled && isEligableToUpdate)
            ) {
              setIsStatusRevertible(true);
            } else {
              setIsStatusRevertible(false);
            }
          }
        } else if (assigneeData) {
          setIsEditable(true);
          if (
            assigneeData?.statusId === TaskStatus.Completed ||
            (assigneeData?.statusId === TaskStatus.Cancelled && isEligableToUpdate)
          ) {
            setIsStatusRevertible(true);
          }
        } else if (parseInt(getEmployeeId(userInfo?.userInfo)) === details?.createdBy?.id && !checkErrorEligibility && checkCreatedByEligibility) {
          setIsEditable(true);
        } else {
          setIsEditable(false);
        }
      }
    } else {
      const isValidUser = details?.createdBy?.id === parseInt(getEmployeeId(userInfo?.userInfo));
      setIsEditable(isValidUser);
    }
  };

  const onHoverOut = async () => {
    setIsEditable(true);
    setIsStatusRevertible(false);
  };

  const onIconClick = e => {
    e.stopPropagation();
    if (isExternal) {
      e.stopPropagation();
      dispatch(
        taskDetailsActions.setTaskStatusUpdateModalId({
          taskId: details?.taskId,
          statusId: details?.statusId,
        }),
      );
      dispatch(
        taskDetailsActions.setCurrentExternalTask({
          id: details?.taskId,
          placementId: details?.placementId,
          candidateId: details?.candidateId,
          taskNameId: details?.taskNameId,
          categorySubType1: details?.subCategory1,
          categorySubType2: details?.subCategory2,
          taskCategoryId: details?.taskCategoryId,
        }),
      );
      dispatch(taskDetailsActions.setShowTaskStatusModal({ isModalOpen: true, container: 'details' }));
    } else {
      dispatch(
        taskDetailsActions.setTaskStatusUpdateModalId({
          taskId: details?.taskId,
          statusId: statusDetails?.statusId,
        }),
      );

      dispatch(
        taskDetailsActions.setShowTaskStatusModal({
          isModalOpen: true,
          container: isSubtask ? 'subTask' : 'details',
          version: isSubtask ? StatusUpdateModalVersion.SUBTASK : StatusUpdateModalVersion.PARENT_TASK,
          subTaskDetails: details,
        }),
      );
    }
  };

  useEffect(() => {
    if (details && !isExternal) {
      const taskRecipents = !isSubtask ? [...details?.assignedTo, ...details?.cc] : [];
      const subTaskRecipients = isSubtask ? [...details?.tos, ...details?.ccs] : [];
      const checkNewPendingStatus = isSubtask
        ? subTaskRecipients?.find(
            record => record?.statusId === TaskStatus.New || record?.statusId === TaskStatus.Pending,
          )
        : taskRecipents?.find(record => record?.statusId === TaskStatus.New || record?.statusId === TaskStatus.Pending);
      setCreatedByEligibilty(checkNewPendingStatus);
      let recipientDetails = isSubtask ? getSubTaskRecipientList(details, user) : getAssignee(details, user);
      if (!recipientDetails) {
        const hsgId = 6000;
        if (activeCoverage?.length === 0) {
          recipientDetails = isSubtask
            ? getSubTaskRecipientList(details, { employeeId: hsgId })
            : getAssignee(details, { employeeId: hsgId });
        }
      }
      setAssigneeData(recipientDetails ?? null);
      if (!isExternal) {
        if (parseInt(getEmployeeId(userInfo?.userInfo)) === details?.createdBy?.id) {
          setClickable(false);
        } else {
          setClickable(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  useEffect(() => {
    if (isSubtask) {
      dispatch(taskDetailsActions.setStatusNotificationContainer('subTask'));
    } else {
      dispatch(taskDetailsActions.setStatusNotificationContainer('details'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubtask]);

  return (
    <React.Fragment>
      <div
        style={{ marginLeft: isSubtask ? '2px' : '0px', width: '25px' }}
        onMouseEnter={onHover}
        onMouseLeave={onHoverOut}
      >
        {isEditable ? (
          <div>
            {statusDetails?.statusId === TaskStatus.New ? (
              <CircleOutlinedIcon
                color="primary"
                style={{
                  cursor: 'pointer',
                  height: '24px',
                  width: '24px',
                  pointerEvents: isEligableToUpdate ? 'auto' : 'none',
                }}
                onKeyDown={e => {
                  if (e.key === 'Space' || e.keyCode === 32) {
                    e.preventDefault();
                  }
                  if (e.keyCode === 13 && isClickable) {
                    onHover();
                  }
                }}
                onClick={e => {
                  onIconClick(e);
                }}
              />
            ) : (
              <IconButton
                color="primary"
                data-testid="task-details-status-icon-btn"
                className={classes.statusIconBtn}
                disabled={!isEligableToUpdate}
                onKeyDown={e => {
                  if (e.key === 'Space' || e.keyCode === 32) {
                    e.preventDefault();
                  }
                  if (e.keyCode === 13 && isClickable) {
                    onHover();
                  }
                }}
                onClick={e => {
                  onIconClick(e);
                }}
                size="small"
              >
                <StatusIcon
                  statusId={statusDetails?.statusId}
                  isStatusRevertible={isExternal ? false : isStatusRevertible}
                />
              </IconButton>
            )}
          </div>
        ) : (
          <BlockIcon className={classes.statusIconDeniedBtn} />
        )}
      </div>
    </React.Fragment>
  );
};
