import { Grid, Typography, useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { TypeAheadCustomDropdown } from 'app/components/Tasks/CustomComponents/TypeAheadCustomDropdown';
import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatGwwData, sortOptions } from '../../../helper';
import { RateElementsTableRow } from './RateElementsTableRow';
import { RateTableHeader } from './RateTableHeader';

const useStyles = makeStyles<{ alignPopper: boolean; disabled: boolean }>()((theme, props) => ({
  headerTextStyle: {
    fontWeight: 500,
  },
  addTaskButton: {
    border: '1px solid #C4C6C9',
    borderRadius: '4px',
    background: '#C4C6C9 0% 0% no-repeat padding-box',
    opacity: '1',
    height: '30px',
    width: '30px',
    padding: '0px 0px 0px 0px',
    font: 'normal normal normal 18px Roboto',
    color: '#FFFFFF',
    cursor: 'pointer',
    '&:hover': {
      background: props.disabled
        ? '#C4C6C9 0% 0% no-repeat padding-box'
        : `${theme.palette.components.button.secondary.backgroundColor} 0% 0% no-repeat padding-box`,
    },
  },
  table: {
    height: '100%',
    overflowY: 'hidden',
    marginTop: '5px',
    paddingBottom: '5px',
  },
  tableRow: {
    minWidth: 'max-content',
    flexWrap: 'nowrap',
  },
  columnTitle: {
    paddingLeft: '11px',
    alignItems: 'center',
    display: 'flex',
    fontSize: '12px',
  },
  popper: {
    zIndex: 999,
    position: 'absolute',
    left: props.alignPopper ? '40rem' : 'auto',
  },
}));

export const RateTable = ({
  disabled = false,
  tableTitle,
  columnOptions,
  rowOptions,
  disableRowMenu = false,
  getTableData,
  setError,
}: {
  disabled?: boolean;
  tableTitle: string;
  columnOptions: any[];
  rowOptions: any[];
  disableRowMenu?: boolean;
  getTableData?: (e) => void;
  setError?: (e) => void;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [popperOpen, setPopperOpen] = useState<boolean>(false);
  const [rateElementsPopper, showRateElementsPopper] = useState<boolean>(false);
  const [shifts, setShifts] = useState<any[]>([]);
  const [selected, setSelected] = useState<any[]>([]);
  const [rateElements, setRateElements] = useState<any[]>([]);
  const [selectedRateElements, setSelectedRateElements] = useState<any[]>(disableRowMenu ? rowOptions : []);
  const [gwwHoursArray, setGwwHoursArray] = useState<any[]>([]);
  const [deleteColumn, setDeleteColumn] = useState<any>(null);
  const [rateElementDeleted, deleteRateElement] = useState<any>();
  const [highlightedCol, setHighlightedCol] = useState<any>();
  const [highlightRateElementButton, setHighlightRateElementButton] = useState<boolean>(false);
  const [shiftCount, setShiftCount] = useState<number>(0);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const formMethods = useForm({ mode: 'all' });
  const { classes } = useStyles({ alignPopper: shiftCount > 3, disabled });
  const [row, setRow] = useState<any>();
  const [rows, setRows] = useState<any[]>([]);

  const getFormattedRows = () => {
    const rowArr = rateElements?.map(rateElement => {
      const existingRow = rows?.filter(row => row.name === rateElement.name);
      if (existingRow[0]?.id > -1) {
        return { ...existingRow[0] };
      } else {
        // new row with rate element props
        return { ...rateElement };
      }
    });
    return rowArr;
  };

  const mapColumnValuesToRows = tempRows => {
    const updatedRows = tempRows?.map(row => ({
      ...row,
      shifts: shifts?.map(shift => ({
        ...shift,
        inputValue: checkPreexistingShift(row.shifts, shift.id),
      })),
    }));
    setRows(updatedRows);
  };

  const checkPreexistingShift = (rateElementShifts, shiftId) => {
    const existingShift = rateElementShifts?.find(s => s.id === shiftId);
    return existingShift?.inputValue ?? '';
  };

  const handleDeleteColumn = colIndex => {
    const updatedShifts = shifts?.filter((item, index) => index !== deleteColumn);
    setShifts(updatedShifts);
    setSelected(updatedShifts);
    setDeleteColumn(null);
  };

  const handleDeleteRow = () => {
    const tempRows = [...rows];
    const index = rows?.findIndex(r => r.id === rateElementDeleted.id);

    if (disableRowMenu) {
      const updatedRows = tempRows?.map(row => ({
        ...row,
        shifts: shifts?.map(shift => ({
          ...shift,
          inputValue: '',
        })),
      }));
      setRows(updatedRows);
    } else {
      tempRows.splice(index, 1);
      const updatedRateElements = rateElements?.filter(item => item.id !== rateElementDeleted.id);
      setRows(tempRows);
      setRateElements(updatedRateElements);
      setSelectedRateElements(updatedRateElements);
      deleteRateElement(null);
    }
  };

  const handleColumnChange = () => {
    if (shifts) {
      const sortedOptions = sortOptions(shifts);
      const formatedGwwArray = formatGwwData(sortedOptions);
      setGwwHoursArray(formatedGwwArray);
      setShiftCount(shifts.length);
    }

    if (rows?.length > 0) {
      mapColumnValuesToRows(rows);
    }
  };

  useEffect(() => {
    handleColumnChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shifts]);

  useEffect(() => {
    const updatedRows = rows?.map(r => {
      if (r.id === row.id) {
        return { ...row };
      } else {
        return { ...r };
      }
    });
    setRows(updatedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  useEffect(() => {
    const tempRows = getFormattedRows();
    mapColumnValuesToRows(tempRows);

    if (rateElements?.length > 0) {
      if (shifts?.length <= 0) {
        let allShift = columnOptions.filter(x => x.id === 1);
        setShifts(allShift);
        setSelected(allShift);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateElements]);

  useEffect(() => {
    if (shifts?.length > 0 && deleteColumn >= 0) {
      handleDeleteColumn(deleteColumn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteColumn]);

  useEffect(() => {
    if (rateElementDeleted) {
      handleDeleteRow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateElementDeleted]);

  useEffect(() => {
    if (disableRowMenu) {
      setRateElements(rowOptions);
      setSelectedRateElements(rowOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableRowMenu]);

  useEffect(() => {
    if (popperOpen === false) {
      if (shifts.length > selected.length || shifts.length === selected.length) {
        setSelected(shifts);
      }
      if (rateElements.length > selected.length || rateElements.length === selectedRateElements.length) {
        setSelectedRateElements(rateElements);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popperOpen]);

  useEffect(() => {
    getTableData(rows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  useEffect(() => {
    if (disabled) {
      setRows([]);
      setGwwHoursArray([]);
      setSelectedRateElements([]);
      setSelected([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  return (
    <FormProvider {...formMethods}>
      <Grid container className={classes.table}>
        <RateTableHeader
          disabled={disabled}
          tableTitle={tableTitle}
          setDeleteColumn={setDeleteColumn}
          columns={gwwHoursArray}
          highlightedCol={highlightedCol}
          setHighlightedCol={setHighlightedCol}
          columnOptions={columnOptions}
          shifts={shifts}
          setShifts={setShifts}
          selected={selected}
          setSelected={setSelected}
          popperOpen={popperOpen}
          setPopperOpen={setPopperOpen}
          shiftCount={shiftCount}
          setError={setError}
        />
        {rows?.map(rateElement => {
          return (
            <RateElementsTableRow
              key={rateElement.name}
              rateElement={rateElement}
              deleteRateElement={deleteRateElement}
              setDeleteColumn={setDeleteColumn}
              gwwHoursArray={gwwHoursArray}
              selectedRateElements={selectedRateElements}
              shiftCount={shiftCount}
              highlightedCol={highlightedCol}
              setHighlightedCol={setHighlightedCol}
              row={rateElement}
              setRow={setRow}
              rows={rows}
              setError={setError}
            />
          );
        })}
        <Grid container className={classes.tableRow} />
      </Grid>
      {!disableRowMenu && (
        <Grid
          container
          onMouseEnter={() => {
            setHighlightRateElementButton(true);
          }}
          onMouseLeave={() => {
            setHighlightRateElementButton(false);
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: highlightRateElementButton ? '#E9EDF4' : '',
          }}
        >
          <Grid style={{ paddingRight: '3px' }}>
            <Grid item className={classes.columnTitle} style={{ margin: '10px 0 10px' }}>
              <button
                type="button"
                className={classes.addTaskButton}
                onClick={e => {
                  setAnchorEl(e.currentTarget);
                  showRateElementsPopper(prevState => !prevState);
                }}
                style={{
                  backgroundColor: rateElementsPopper && theme.palette.components.button.secondary.backgroundColor,
                }}
                disabled={disabled}
              >
                {t('notification.taskSidePanel.addTaskButton')}
              </button>
              <Typography
                className={classes.columnTitle}
                classes={{ root: classes.headerTextStyle }}
                style={{ whiteSpace: 'nowrap' }}
              >
                {t('notification.taskStatusModal.addRateElements')}
              </Typography>
            </Grid>
            <Grid className={classes.popper}>
              {rateElementsPopper && (
                <TypeAheadCustomDropdown
                  open={rateElementsPopper}
                  apply={setRateElements}
                  selected={rateElements}
                  options={rowOptions}
                  onChange={setSelectedRateElements}
                  isMultiSelect={true}
                  value={selectedRateElements}
                  showPopper={showRateElementsPopper}
                  anchorEl={anchorEl}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </FormProvider>
  );
};
