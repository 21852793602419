import { NewUnit, SkillCheckList } from 'app/models/Unit/CreateUnit';
import { Certification } from 'app/models/Unit/CreateUnit';
import { IOrderCreationProcessStep } from 'app/components/Order/Store/Order.redux';
import { AMNDivisionType } from 'app/models/enums/AMNDivisionType';

export const mapUnitData = (data: NewUnit) => {};

//
// ─── NURSE DATA ─────────────────────────────────────────────────────────────────
//
export const getNurse = (nurseRatio: any | string | null) => {
  return nurseRatio ? nurseRatio.split(':')[0] : null;
};

//
// ─── PATIENT DATA ───────────────────────────────────────────────────────────────
//
export const getPatient = (nurseRatio: any | string | null) => {
  return nurseRatio ? nurseRatio.split(':')[1] : '';
};

//
// ─── CERTIFICATIONS DATA ────────────────────────────────────────────────────────
//
export const getCertifications = (
  certifications: Certification[] | null | undefined,
  isEditUnit: boolean,
  division?: number,
  storeOrderCreationProcessStep?: IOrderCreationProcessStep | null,
) => {
  const extCertifications: any = [];
  if (!isEditUnit) {
    if (certifications?.length) {
      certifications.map((item: Certification) => {
        extCertifications.push({
          id: item?.requirementTypeId,
          isRequired: item?.isRequired,
        });
      });
      return extCertifications;
    }
    return (division === AMNDivisionType.amnNurse || division === AMNDivisionType.allied) && !storeOrderCreationProcessStep
      ? [
          {
            id: 47,
            isRequired: true,
          },
        ]
      : [];
  }
  return extCertifications;
};

//
// ─── SKILLCHECKLISTS CHIPS DATA ─────────────────────────────────────────────────
//
export const getSkillChecklistsChips = (skillChecklists: any | null | undefined) => {
  if (skillChecklists) {
    const extskillChecklistsChips: any = [];
    skillChecklists.map((item: any) => {
      extskillChecklistsChips.push({
        id: item?.questionId,
        text: item?.text,
        isRequired: item?.isRequired,
        yearsOfExperience: item?.yearsOfExperience,
      });
    });
    return extskillChecklistsChips;
  }
  return [];
};

//
// ─── SKILLCHECKLISTS DATA ───────────────────────────────────────────────────────
//
export const getSkillChecklists = (skillChecklists: any | null | undefined) => {
  if (skillChecklists) {
    const extskillChecklists: any = [];
    skillChecklists.map((item: SkillCheckList) => {
      extskillChecklists.push({
        questionId: item?.questionId,
        text: item?.text,
        isRequired: item?.isRequired,
        yearsOfExperience: item?.yearsOfExperience,
        id: item?.id,
        partitionKey: item?.partitionKey,
        isSelected: true,
      });
    });
    return extskillChecklists;
  }
  return [];
};
