import React, { useState } from 'react';
import { SubTitle, TextContent } from './styles';
import { useTranslation } from 'react-i18next';
import { Concatenate } from 'utils/string/string';
import { useSelector } from 'react-redux';
import { candidateEducationSelection } from '../../../Profile/CandidateDetails.selector';
import { AddEducationDialog } from '../../Common/AddEducationDialog';
import { ff_candidate_container_ui_v2 } from 'app/constants/FeatureFlags/Candidate/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { DateDiv, EduGrid, VerticalDivider, useEducationalCardStyles } from './CardDesigns/utils';
import EducationCardUIV2 from './CardDesigns/EducationCardUIV2';
import EducationCardUI from './CardDesigns/EducationCardUI';
import { getFirstFour } from './Supervisor/helpers/helper';
import { DegreeSectionProps } from './types';

export const EducationCard = props => {
  const education = useSelector(candidateEducationSelection);
  const [openAddPop, setOpenAddPop] = useState<boolean>(false);
  const [candidateContainerUIV2] = useDecision(ff_candidate_container_ui_v2);

  //////////////////////////////////
  //US 33255 insists only 4 to be displayed --> remove below code to display all + uncomment line above
  const educationArray = getFirstFour(education || []);
  /////////////////////////////////
  const handleClose = () => {
    setOpenAddPop(false);
  };

  return (
    <>
      {candidateContainerUIV2?.enabled ? (
        <EducationCardUIV2
          handleToggle={props.handleToggle}
          setOpenAddPop={setOpenAddPop}
          educationArray={educationArray}
        />
      ) : (
        <EducationCardUI
          handleToggle={props.handleToggle}
          setOpenAddPop={setOpenAddPop}
          educationArray={educationArray}
        />
      )}
      {openAddPop && <AddEducationDialog open={openAddPop} handleClose={handleClose} />}
    </>
  );
};

export const DegreeSection: React.FC<DegreeSectionProps> = props => {
  const { t } = useTranslation();
  const { classes } = useEducationalCardStyles();
  //////////////////////////////////
  //Not included in US 33255 --> uncomment codes below for using Icons in Education Card
  //const isGraduated = props.graduatedOn < new Date(Date.now());

  return (
    <EduGrid
      item
      container
      classes={{ container: classes.container }}
      xs={6}
      sm={6}
      md={6}
      lg={6}
      xl={6}
      data-testid="education-item"
    >
      {props.index % 2 === 1 && <VerticalDivider orientation="vertical" />}
      <div>
        <SubTitle>{Concatenate([props.degree, props.major], ', ')}</SubTitle>
        <TextContent>{props.school ? props.school : ''}</TextContent>
        <TextContent>{Concatenate([props.city, props.state, props.country], ', ')}</TextContent>
        <DateDiv>{`${t('candidate.educationCard.graduationDate')}: ${
          props.graduatedOn ? props.graduatedOn : ''
        }`}</DateDiv>
      </div>
    </EduGrid>
  );
};
