import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import { selectClientContactDetails } from '../store/ClientContact.selector';

const useStyles = makeStyles()(() => ({
  errorText: {
    margin: '2px',
    paddingLeft: '12px',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    color: '#f44336',
  },
}));

export const CCPhoneNumber = formMethods => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const selectedClientContactDetails = useSelector(selectClientContactDetails);

  const [formatValue, setFormatValue] = useState<string>('');

  const { formMethods: controls, setValidPhoneNumber, isPhoneNumberError, disabled } = formMethods;

  const { control, setValue } = controls;

  const handleChange = (value, data, e, formattedValue) => {
    let format;
    let len;
    if (data.format !== undefined) {
      format = data.format;
      len = format.length;
    }
    if (value.length > 1) {
      const formatedData = value.replace(/\D/g, '').substring(value.length - 10);
      setValidPhoneNumber(false);
      if (len == formattedValue?.length) {
        setFormatValue(value);
        if (formatedData?.length === 10) {
          setValidPhoneNumber(true);
        }
        setValue('phoneNumber', formatedData, { shouldDirty: true });
      }
      if (
        (data.format == '+.. ... ... ... ... ..' || data.format == '+... ... ... ... ... ..') &&
        formattedValue.length > 14
      ) {
        setFormatValue(value);
        if (formatedData?.length === 10) {
          setValidPhoneNumber(true);
        }
        setValue('phoneNumber', formatedData, { shouldDirty: true });
      }
    } else {
      setValidPhoneNumber(true);
      setValue('phoneNumber', '', { shouldDirty: false });
      setFormatValue('1');
    }
  };

  useEffect(() => {
    if (selectedClientContactDetails?.phoneNumbers && selectedClientContactDetails?.phoneNumbers[0]?.phoneNumber) {
      const value =
        selectedClientContactDetails?.phoneNumbers[0]?.countryDialingCode +
        selectedClientContactDetails?.phoneNumbers[0]?.phoneNumber?.replace(/\D/g, '');
      setFormatValue(value);
    } else {
      setFormatValue('1');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientContactDetails?.contactId]);

  return (
    <Controller
      control={control}
      name={'phoneNumber'}
      render={({ ref, onChange, ...rest }) => (
        <div>
          <PhoneInput
            value={formatValue}
            placeholder={'(...) ...-....'}
            enableSearch={true}
            disableSearchIcon={true}
            disabled={disabled}
            country={'us'}
            onlyCountries={['us']}
            onChange={handleChange}
            countryCodeEditable={false}
            buttonStyle={{
              border: 0,
              borderBottom: disabled ? 'none' : '2px solid #8e8e8e',
              borderRight: '1px solid lightgrey',
              background: disabled ? 'rgba(0, 0, 0, 0.12)' : '#f4f4f4',
              userSelect: disabled ? 'none' : 'all',
            }}
            dropdownStyle={{
              border: 0,
              borderBottom: disabled ? 'none' : '2px solid #8e8e8e',
              borderRight: '1px solid lightgrey',
              background: disabled ? 'rgba(0, 0, 0, 0.12)' : '#f4f4f4',
              userSelect: disabled ? 'none' : 'all',
            }}
            inputStyle={{
              height: 54,
              border: 0,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderBottom: disabled ? 'none' : '2px solid #8e8e8e',
              background: disabled ? 'rgba(0, 0, 0, 0.12)' : '#f4f4f4',
            }}
            searchStyle={{
              width: 'fit-content',
            }}
          />
          <p className={classes.errorText}>
            {isPhoneNumberError && t('notification.clientContact.phoneNumberInValid')}
          </p>
        </div>
      )}
    />
  );
};
