import { XGrid, XGridToolbar } from '@AMIEWEB/Common';
import { IXGridBulkActionButton } from '@AMIEWEB/Common/XGrid/Toolbar/XGridToolbar';
import { GridColDef } from '@mui/x-data-grid-pro';
import React, { useCallback, useMemo } from 'react';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import { GridType, IPreferenceAdultsRow, IPreferenceChildrenRow, IPreferencePetsRow } from './PreferencesHelper';
import { ThemeProvider, createTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { theme } from 'styles/global-styles';

interface IPreferenceGridBuilderProps {
  title: string;
  gridType: GridType;
  rows: IPreferenceAdultsRow[] | IPreferenceChildrenRow[] | IPreferencePetsRow[]; 
  columns: GridColDef[];
  selected: any[];
  onAddRow: (type: GridType) => void;
  onDeleteRow: (type: GridType) => void;
  handleRowSelection: (type: GridType, ids: any[]) => void;
  getRowCount: (type: GridType) => number;
  handleRowChange: (
    type: GridType, 
    row?: IPreferenceAdultsRow | IPreferenceChildrenRow | IPreferencePetsRow,
  ) => IPreferenceAdultsRow | IPreferenceChildrenRow | IPreferencePetsRow;
};

enum RightActionKeys {
  ADD = 'add',
  DELETE = 'delete'
}

const useStyles = makeStyles()({
  container: {
    height: '290px',
  },
  grid: {
    width: '100%',
    minHeight: '275px',
  },
});

const gridTheme = createTheme(theme,{
  components: {
    //@ts-ignore
    MuiDataGrid: {
      styleOverrides: {
        row: {
          "&.Mui-selected": {
            backgroundColor: '#ffffff',
          }
        },
      }
    }
  }
});

export const PreferenceGridBuilder = (props: IPreferenceGridBuilderProps) => {
  const { 
    title, 
    gridType, 
    rows, 
    columns, 
    selected, 
    handleRowChange, 
    handleRowSelection, 
    onAddRow, 
    onDeleteRow,
    getRowCount,
  } = props;
  const { classes } = useStyles();

  const toobarRightActions = useMemo<IXGridBulkActionButton[]>(() => {
    return [
      {
        key: RightActionKeys.ADD,
        Icon: AddIcon,
        disabled: rows?.filter(item => item.rowID === 0 && selected.includes(item.rowID))?.length > 0,
        onClick: () => onAddRow(gridType),
      },
      {
        key: RightActionKeys.DELETE,
        Icon: DeleteOutlinedIcon,
        disabled: rows?.filter(item => item.rowID === 0)?.length > 0 || 
          rows?.filter(item => item.rowID !== 0 && item.isSelected)?.length === 0,
        onClick: () => onDeleteRow(gridType),
      },
    ];
  }, [selected, rows, onAddRow, onDeleteRow]);

  const handleProcessRowUpdateError = useCallback((error: Error) => {}, []);

  return (
    <div className={classes.container}>
    <ThemeProvider theme={gridTheme}>
      <XGrid
        title={title}
        rows={rows}
        displayCustomCount={getRowCount(gridType)}
        columns={columns}
        experimentalFeatures={{ columnGrouping: true, newEditingApi: true }}
        getRowId={model => model.rowID}
        checkboxSelection
        inDialog
        rowHeight={60}
        headerHeight={70}
        hideFooter={true}
        selectionModel={selected}
        disableSelectionOnClick={true}
        isCellEditable={(params) => params.row.rowID !== 0}
        processRowUpdate={(updatedRow, originalRow) =>
          handleRowChange(gridType, updatedRow)
        }
        onProcessRowUpdateError={handleProcessRowUpdateError}
        onSelectionModelChange={(newRowSelectionModel) => {
          handleRowSelection(gridType, newRowSelectionModel);
        }}
        components={{
          Toolbar: XGridToolbar,
        }}
        className={classes.grid}
        toolbarProps={{
          rightActions: toobarRightActions,
          //toobarRightActions,
          refreshButton: true,
          resetButton: true,
          refreshGrid: () => {
            // handleRefreshGrid();
          },
        }}
        sx={
          (rows?.filter(item => item.rowID === 0 && selected.includes(item.rowID))?.length > 0) && {
          '& .MuiDataGrid-columnHeader': {
          color: '#cccccc',
            '& .MuiCheckbox-root svg': {
              color: '#cccccc',
          },
          },
        }}
      />
    </ThemeProvider>
    </div>
  )
}