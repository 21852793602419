import { Concatenate } from 'utils/string/string';
import states from 'app/assets/jsons/State.json';
import Call from '../../../assets/images/WorkDesk/Call.png';
import email from '../../../assets/images/WorkDesk/email.png';
import { missingField } from 'app/constants';

export const facilityFlattenData = items =>
  items.map((item, index) => {
    const accountManagerAttributes = [
      { key: Call, value: item.accountManager?.phoneNumber },
      { key: email, value: item.accountManager?.email },
    ];
    const credentialingAnalystAttributes = [
      { key: Call, value: item.credAnalyst?.phoneNumber },
      { key: email, value: item.credAnalyst?.email },
    ];
    const clinicalManagerAttributes = [
      { key: Call, value: item.clinicalManager?.phoneNumber },
      { key: email, value: item.clinicalManager?.email },
    ];
    const regionalDirectorAttributes = [
      { key: Call, value: item.regionalDirector?.phoneNumber },
      { key: email, value: item.regionalDirector?.email },
    ];
    const clientContractAdminAttributes = [
      { key: Call, value: item.clientContractAdmin?.phoneNumber },
      { key: email, value: item.clientContractAdmin?.email },
    ];
    const clientContractSpecialistAttributes = [
      { key: Call, value: item.clientContractSpecialist?.phoneNumber },
      { key: email, value: item.clientContractSpecialist?.email },
    ];
    
    const data = {
      id: `${item.facilityId}-${index}`,
      indexCounter: index,
      facilityId: item.facilityId,
      previewDrawerViewerId: item.facilityId,
      previewDrawerViewerType: 1,
      facility: item.name,
      facilityStatus: item.facilityStatus || missingField,
      orders: item.orders,
      positionsAvailable: item.positionsAvailable,
      filesPending: item.filesPending,
      filesOut: item.filesOut,
      accountManager: Concatenate([item.accountManager?.firstName, item.accountManager?.lastName], ' ') || missingField,
      accountManagerAttributes,
      city: item.city || missingField,
      stateName: item.stateName ? item.stateName : missingField,
      division: item.division || missingField,
      type: item.facilityType || missingField,
      staffingVendor: item.staffingVendor || missingField,
      techVendor: item.technologyVendor || missingField,
      mspClient: item.mspClient || missingField,
      facilityAddress: item.facilityAddress || missingField,
      healthSystem: item.healthSystemName || missingField,
      contractAffiliate: item.affiliate || missingField,
      contractAffiliateId: item.affiliateId || missingField,
      credAnalyst: Concatenate([item.credAnalyst?.firstName, item.credAnalyst?.lastName], ' ') || missingField,
      credentialingAnalystAttributes,
      clinicalManager: Concatenate([item.clinicalManager?.firstName, item.clinicalManager?.lastName], ' ') || missingField,
      clinicalManagerAttributes,
      regionalDirector: Concatenate([item.regionalDirector?.firstName, item.regionalDirector?.lastName], ' ') || missingField,
      regionalDirectorAttributes,
      clientContractAdmin: Concatenate([item.clientContractAdmin?.firstName, item.clientContractAdmin?.lastName], ' ') || missingField,
      clientContractAdminAttributes,
      clientContractSpecialist: Concatenate([item.clientContractSpecialist?.firstName, item.clientContractSpecialist?.lastName], ' ') || missingField,
      clientContractSpecialistAttributes,
      hospitalSetting: item.hospitalSetting || missingField,
      facilityType: item.facilityType || missingField,
      facilityDescription: item.facilityDescription || missingField,
      healthSystemId: item.healthSystemId || missingField,
      requirementTypesUnits: Concatenate(item.requirementTypesUnits.map(item => item.requirementTypeName), ', ',) || missingField,
      requirementTypes: Concatenate(item.requirementTypes.map(item => item.requirementTypeName), ', ',) || missingField,
    };
    return data;
  });
