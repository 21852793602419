import React, { createRef, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { selectFilterChips } from './RecruiterPlacements.selector';
import { ChipsContainer } from '../../../ComponentLibrary/Filter';
import { recruiterPlacementActions } from './RecruiterPlacements.redux';
import { selectFilters, selectGridTag } from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import { createFilterChips } from './WorkdeskFilter/filterHelper';
import { useTranslation } from 'react-i18next';
import { instanceOfISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import { determineChipSelected } from 'app/components/AmWorkDesk/PlacementsDesk/filterHelper';
import { selectRecPlacementsGrid } from 'app/components/Common/WorkDesk/PipelineChoice/pipelineChoice.selectors';
import {
  pipelineChoiceActions,
  pipelineChoiceKeys,
} from 'app/components/Common/WorkDesk/PipelineChoice/pipelineChoice.redux';
import { GridTag } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';

export const WorkdeskChips = props => {
  const { t } = useTranslation();
  const { setValue, getValues } = useFormContext();
  const chips = useSelector(selectFilterChips);
  const pipelineSelection = useSelector(selectRecPlacementsGrid);
  const appliedFilter = useSelector(selectFilters);
  const gridTag = useSelector(selectGridTag);
  const hiddenSubmitButton = createRef<HTMLButtonElement>();
  const dispatch = useDispatch();

  const getNewSelection = (key, chip) => ({
    ...appliedFilter?.selectedChipFilters,
    [key]: determineChipSelected(
      chip,
      appliedFilter?.selectedChipFilters ? appliedFilter?.selectedChipFilters[key] : undefined,
    ),
  });

  const handleChipDelete = (field, { value: chipValue, isSelected }) => {
    if (field === 'pipelineSelected') {
      dispatch(
        pipelineChoiceActions.setPipelineChoice({
          key: pipelineChoiceKeys.REC_WORK_DESK_GRID,
          selection: [
            ...pipelineSelection.filter(
              item => !(item.category === chipValue.category && item.subcategory === chipValue.subcategory),
            ),
          ],
        }),
      );
    }
    {
      const fieldValues = getValues(field);
      let newFieldValues: any = null;
      if (Array.isArray(fieldValues)) {
        newFieldValues = [...fieldValues];
        newFieldValues.splice(
          newFieldValues.findIndex(opt => (instanceOfISelection(chipValue) ? opt.value : opt) === chipValue.value),
          1,
        );
        setValue(field, newFieldValues);
      } else setValue(field, null);
      if (isSelected) {
        const newSelections = getNewSelection(field, { value: chipValue, isSelected });
        props.onApplyFilter(
          { ...getValues(), [field]: Array.isArray(fieldValues) ? [...(newFieldValues || [])] : null },
          newSelections,
        );
      } else hiddenSubmitButton.current?.click();
    }
  };

  /** Restricted to call onApplyfilter directly -
   * observes not to remove existing filter and chips */
  const handleChipClick = (key, chip) => {
    /**Check to avoid click on single valued fields/chips */
    if (!Array.isArray(getValues(key))) return;
    /**
     * Below functionalty are limited to type ISelection
     * Need to be updated for dates and single selection
     */
    const newChipSelection = getNewSelection(key, chip);
    props.onApplyFilter(getValues(), newChipSelection);
  };

  useEffect(() => {
    appliedFilter?.filters &&
      dispatch(
        recruiterPlacementActions.setFilterChips({
          ...chips,
          // Solution: false chips introduction from gridState.redux file
          ...(gridTag === GridTag.RecPlacementDesk
            ? createFilterChips(appliedFilter?.filters, appliedFilter.selectedChipFilters, t)
            : {}),
          pipelineSelected: [
            ...pipelineSelection.map(item => ({ label: item.value, enableDelete: true, value: item })),
          ],
        }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilter?.filters, pipelineSelection]);

  return (
    <>
      <ChipsContainer chips={chips} onClick={handleChipClick} onDelete={handleChipDelete} />
      <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
    </>
  );
};
