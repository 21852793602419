import { Moment } from 'moment';
import { IChip } from 'app/ComponentLibrary/Filter/ChipsContainer';
import { CoverageRequestsGridKeys } from '@AMIEWEB/AmWorkDesk/CoverageDesk/Tabs/Request/gridHelper';

export interface ICoverage {
  chips: ICoverageFilterChips;
  coverageRequest: ICoverageRequest;
  requestedCoverage: any[];
  activeCoverage: ICoverageDetails[];
  coverageRequestsGridData: ICoverageGridData;
  newCreatedCoverageId: string | undefined;
  updatedCoverageId: string | undefined;
  denyCoverage: IDenyCoverageResponse[];
  emailCoverage?: any[];
  inboxCoverage?: ICoveredUserInboxes;
}

export interface ICoverageDetails {
  coverageTypeDescription?: string;
  coverageTypeId?: number;
  coverageTypeSplit?: string;
  coveredEmployeeFirstName?: string;
  coveredEmployeeId: number;
  coveredEmployeeLastName?: string;
  coveringEmployeeFirstName?: string;
  coveringEmployeeId: number;
  coveringEmployeeLastName?: string;
  createByEmployeeId?: number;
  createByFirstName?: string;
  createByLastName?: string;
  createDate?: string;
  endDate?: string;
  id: string;
  lastUpdateByEmployeeId?: number;
  lastUpdateByFirstName?: string;
  lastUpdateByLastName?: string;
  lastUpdateDate?: string;
  notes?: string;
  startDate?: string;
  statusDescription?: string;
  totalDaysOfCoverage?: number;
}

export interface ICoveredUserInbox {
  coveredUserEmail: string;
  coveredUserId: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  avatarColor?: string;
}
export interface ICoveredUserInboxes {
  coveredInboxes: Array<ICoveredUserInbox>;
  activeInbox: ICoveredUserInbox;
}
export interface IRequestCoverageProp {
  coveringEmployeeIds?: number[];
  coveredEmployeeIds?: number[];
  createdByIds?: number[];
  statusIds?: number[];
  startDate?: string;
  endDate?: string;
  pageNumber?: number;
  pageSize?: number;
  sortedColumn?: any;
  sharedDeskFlagEnabled?: boolean;
  coverageFlagEnabled?: boolean;
}

export interface ICoverageRequest {
  requestedBy?: any;
  preferences?: any;
  notes?: string;
  urgent?: boolean;
  coverageProvider?: any;
  toa?: number;
  coverageFor?: number;
  coveredBy?: number;
  dateRange?: Moment | Date | string;
  numDays?: number;
  createdBy?: number;
  createdDate?: Moment | Date | string;
  lastUpdatedBy?: number;
}

export interface ICoverageResponseData {
  id: string;
  coverageFor: string;
  coverageForEmployeeId: number;
  coveredByEmployeeId: number;
  coveredBy: string;
  dateRange: Moment | Date | string;
  numDays: number;
  notes: string;
  createdBy: string;
  createdDate: Moment | Date | string;
  lastUpdatedBy: string;
  startTime?: Moment | Date | string;
  endTime?: Moment | Date | string;
  startTimeValue?: string;
  endTimeValue?: string;
  displayCoverageDuration: string;
  displayCoverageDurationInDays: string;
}

export interface ICoverageGridData {
  pageNumber?: number | null;
  pageSize?: number | null;
  totalCount?: number | null;
  coverageRequests?: ICoverageResponseData[] | null;
}

export interface ICoverageFilterChips {
  [CoverageRequestsGridKeys.coverageFor]: IChip[];
  [CoverageRequestsGridKeys.coveredBy]: IChip[];
  [CoverageRequestsGridKeys.dateRange]: IChip | null;
  [CoverageRequestsGridKeys.createdBy]: any[] | null;
}

export interface IFilterChipSelected {
  [CoverageRequestsGridKeys.coverageFor]: any[] | null;
  [CoverageRequestsGridKeys.coveredBy]: any[] | null;
  [CoverageRequestsGridKeys.dateRange]: any | null;
  [CoverageRequestsGridKeys.createdBy]: any[] | null;
}
export const defaultChips: ICoverageFilterChips = {
  [CoverageRequestsGridKeys.coverageFor]: [],
  [CoverageRequestsGridKeys.coveredBy]: [],
  [CoverageRequestsGridKeys.dateRange]: null,
  [CoverageRequestsGridKeys.createdBy]: [],
};

export const defaultFilterChipSelected = {
  [CoverageRequestsGridKeys.coverageFor]: [],
  [CoverageRequestsGridKeys.coveredBy]: [],
  [CoverageRequestsGridKeys.dateRange]: null,
  [CoverageRequestsGridKeys.createdBy]: [],
} as IFilterChipSelected;

export interface IDenyCoverageResponse {
  id: string;
  success: boolean;
}

export interface IDenyCoverageRequest {
  ids: string[];
  changedByEmployeeId: number;
  changedByFirstName: string;
  changedByLastName: string;
  changedDate: string | Date | Moment;
  statusId: number;
}

export interface IRequestCoverage {
  coveredEmployeeId: number | string;
  coveredEmployeeFirstName: string;
  coveredEmployeeLastName: string;
  coveringEmployeeId: number | string;
  coveringEmployeeFirstName: string;
  coveringEmployeeLastName: string;
  statusId: number;
  startDate: string | Date | Moment;
  endDate: string | Date | Moment;
  startTime: any;
  endTime: any;
  createDate: string | Date | Moment;
  createByEmployeeId: number;
  createByFirstName: string;
  createByLastName: string;
  notes: string;
  coverageDateRange: any;
}

export interface IUpdateRequestCoverage {
  id: string;
  notes: string;
  coveringEmployeeId: number;
  coveringEmployeeFirstName: string;
  coveringEmployeeLastName: string;
  startDate: string | Date | Moment;
  endDate: string | Date | Moment;
  lastUpdateDate: string | Date | Moment;
  lastUpdateByEmployeeId: number;
  lastUpdateByFirstName: string;
  lastUpdateByLastName: string;
}

export enum CoverageRequestStatus {
  Pending = 1,
  Waitlist = 2,
  Declined = 3,
  Approved = 4,
}
