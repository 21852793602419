import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { ExceptionType } from 'app/enums/Common';
import {
  CoverageRequestStatus,
  IDenyCoverageRequest,
  IRequestCoverage,
  IRequestCoverageProp,
} from 'app/models/UserCoverage/UserCoverage';
import { httpSuccess } from 'app/services/serviceHelpers';
import { trackPromise } from 'react-promise-tracker';
import { call, getContext, put, select } from 'redux-saga/effects';
import { coverageActions } from './async-actions';
import {
  assignCoverageRequest,
  denyCoverageRequest,
  getCoverageRequestList,
  updateAssignedCoverage,
} from 'app/services/CoverageServices/CoverageServices';
import { formatCoverageResponse } from '@AMIEWEB/AmWorkDesk/CoverageDesk/Transformer';
import { selectUser } from 'oidc/user.selectors';
import { SharedService } from 'app/services/SharedServices/shared-service';
import { getCoveredUserInbox, getUserTwilioLongCode } from 'app/services/NotificationServices/NotificationService';
import { selectCoveredInbox } from './selectors';
import { getFinalInboxDetails } from './helper';
import { selectFailedServices } from 'app/ApplicationRoot/Global.selectors';
import { FailedServices } from 'app/ApplicationRoot/types';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { IInboxQuery } from 'app/models/Notification/Notification';

const TrackSaveCoverageRequest = (fn, ...args) => trackPromise(fn(...args), 'save-coverage-request');
export const TrackFetchCoverageRequests = (fn, ...args) => trackPromise(fn(...args), 'fetch-coverage-requests');
const TrackUpdateCoverageRequest = (fn, ...args) => trackPromise(fn(...args), 'update-coverage-request');
const TrackFetchActiveCoverageProvidedByUser = (fn, ...args) =>
  trackPromise(fn(...args), 'fetch-active-coverage-provided-by-user');
export const TrackDenyCoverageRequests = (fn, ...args) => trackPromise(fn(...args), 'deny-coverage-requests');
export const TrackInboxCoverageInbox = (fn, ...args) => trackPromise(fn(...args), 'inbox-coverage-requests');
export const TrackLongCodeNumber = (fn, ...args) => trackPromise(fn(...args), 'inbox-shared-long-code-number');

export function* saveCoverageRequest(action: PayloadAction<IRequestCoverage>) {
  try {
    const response = yield call(TrackSaveCoverageRequest, assignCoverageRequest, action.payload);
    if (response && httpSuccess(response?.status)) {
      yield put(coverageActions.setNewCoverageId(response.data));
      yield put(
        globalActions.setSnackBar({
          message: 'Coverage request sent',
          severity: 'success',
        }),
      );
    } else {
      yield put(
        globalActions.setSnackBar({
          message: 'ERROR: could not send coverage request',
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'sendCoverageRequest',
        area: 'src/store/redux-store/user-coverage/request-saga.ts',
      },
    });
  }
}

export function* updateCoverageRequests(action: PayloadAction<any>) {
  try {
    const response = yield call(TrackUpdateCoverageRequest, updateAssignedCoverage, action.payload);
    if (response && httpSuccess(response?.status)) {
      yield put(coverageActions.setUpdatedCoverageId(response.data));
    } else {
      yield put(
        globalActions.setSnackBar({
          message: 'ERROR: could not send coverage request',
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'sendCoverageRequest',
        area: 'src/store/redux-store/user-coverage/request-saga.ts',
      },
    });
  }
}

export function* fetchCoverageRequests(action: PayloadAction<IRequestCoverageProp>) {
  try {
    const response = yield call(TrackFetchCoverageRequests, getCoverageRequestList, action.payload);
    if (httpSuccess(response?.status) && response?.data?.coverageRequests?.length > 0) {
      const coverageResponse = formatCoverageResponse(response?.data?.coverageRequests);
      yield put(coverageActions.setCoverageRequestGridData({ ...response?.data, coverageRequests: coverageResponse }));
    } else {
      yield put(coverageActions.setCoverageRequestGridData({ ...response?.data }));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'fetchCoverageRequests',
        area: 'src/store/redux-store/user-coverage/request-saga.ts',
      },
    });
  }
}

export function* fetchActiveCoverageProvidedByUser() {
  try {
    const sharedService: SharedService = yield getContext('sharedService');
    const now = new Date();
    const { userInfo } = yield select(selectUser);
    const payload = {
      coveringEmployeeIds: [userInfo?.employeeId],
      startDate: now,
      endDate: now,
      pageNumber: 1,
      pageSize: 50,
      statusIds: [CoverageRequestStatus.Approved],
    };
    let userElement = {
      coveredUserEmail: userInfo?.email,
      coveredUserId: `${userInfo?.employeeId}`,
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      unreadLogs: [],
      phoneNumber: userInfo?.phoneNumber,
    };
    const response = yield call(TrackFetchActiveCoverageProvidedByUser, getCoverageRequestList, payload);
    if (httpSuccess(response?.status) && response?.data?.coverageRequests?.length > 0) {
      yield put(coverageActions.setActiveCoverage([...response?.data?.coverageRequests]));
      const employees = response?.data?.coverageRequests?.map(cov => cov?.coveredEmployeeId);
      const emailPayload = {
        employeeIds: employees,
      };
      if (employees?.length > 0) {
        const coveredUserEmailsResponse = yield call(sharedService.getEmployeeInfo, emailPayload);
        if (httpSuccess(coveredUserEmailsResponse?.status) && coveredUserEmailsResponse?.data) {
          yield put(coverageActions.setEmailCoverage(coveredUserEmailsResponse.data ?? []));
        }
      }
    } else {
      yield put(coverageActions.setActiveCoverage([]));
      yield put(coverageActions.setEmailCoverage([]));
      yield put(coverageActions.setInboxCoverage([userElement]));
      if (userElement?.phoneNumber) {
        yield put(coverageActions.setActiveInbox(userElement));
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'fetchActiveCoverageProvidedByUser',
        area: 'src/store/redux-store/user-coverage/request-saga.ts',
      },
    });
  }
}

export function* denyCoverageRequests(action: PayloadAction<IDenyCoverageRequest>) {
  try {
    const response = yield call(TrackDenyCoverageRequests, denyCoverageRequest, action.payload);
    if (response && httpSuccess(response.status)) {
      yield put(coverageActions.setDenyCoverageList(response?.data));
    } else {
      yield put(coverageActions.setDenyCoverageList([]));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'denyCoverageRequests',
        area: 'src/store/redux-store/employee-coverage/saga.ts',
      },
    });
  }
}

export function* fetchCoveredInbox(action: PayloadAction<IRequestCoverageProp>) {
  const failedService = yield select(selectFailedServices);
  yield put(
    globalActions.setFailedServices({
      failedServices: failedService.filter(f => f.type !== FailedServices.CoverageInbox),
    }),
  );
  try {
    const { activeInbox } = yield select(selectCoveredInbox);
    const { userInfo } = yield select(selectUser);
    let userElement = {
      coveredUserEmail: userInfo?.email,
      coveredUserId: `${userInfo?.employeeId}`,
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      unreadLogs: [],
      phoneNumber: userInfo?.phoneNumber,
    };
    let sharedProfilelement = {
      coveredUserEmail: userInfo?.sharedProfile?.email,
      coveredUserId: `${userInfo?.sharedProfile?.employeeId}`,
      firstName: userInfo?.sharedProfile?.firstName,
      lastName: userInfo?.sharedProfile?.lastName,
      unreadLogs: [],
      phoneNumber: userInfo?.sharedProfile?.phoneNumber || null,
    };
    let response;
    if (action.payload?.coverageFlagEnabled) {
      response = yield call(TrackInboxCoverageInbox, getCoveredUserInbox, action.payload);
    }
    if (response && response?.length > 0) {
      const totalInboxes = getFinalInboxDetails(response, userElement);
      yield put(coverageActions.setInboxCoverage(totalInboxes));
      if (!activeInbox?.coveredUserId) {
        yield put(coverageActions.setActiveInbox(totalInboxes?.[0]));
      }
    } else if (action.payload.sharedDeskFlagEnabled && !isNullOrUndefined(sharedProfilelement?.phoneNumber)) {
      if (userElement?.phoneNumber) {
        yield put(coverageActions.setInboxCoverage([sharedProfilelement, userElement]));
      } else {
        yield put(coverageActions.setInboxCoverage([sharedProfilelement]));
      }
      if (!activeInbox?.coveredUserId) {
        yield put(coverageActions.setActiveInbox(sharedProfilelement));
      }
    } else {
      yield put(coverageActions.setInboxCoverage([userElement]));
      if (userElement?.phoneNumber) {
        yield put(coverageActions.setActiveInbox(userElement));
      }
    }
  } catch (error) {
    yield put(
      globalActions.setFailedServices({
        failedServices: [...failedService, { type: FailedServices.CoverageInbox, payload: action.payload }],
      }),
    );
    yield put(globalActions.setCatastrophicDialog());
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'fetchCoveredInbox',
        area: 'src/store/redux-store/employee-coverage/saga.ts',
      },
    });
  }
}
