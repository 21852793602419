import React, { useEffect, useRef, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import styledComponent from 'styled-components';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  customTooltip: {
    backgroundColor: theme.palette.framework.system.white,
    color: theme.palette.framework.system.doveGray,
    opacity: 1,
    textAlign: 'left',
    font: '12px/30px Roboto',
    letterSpacing: '0px',
    boxShadow: `0px 3px 6px ${theme.palette.system.darkBlackBlue}`,
    border: `1px solid ${theme.palette.system.veryLightGray}`,
    lineHeight: '1.5',
  },
  customArrow: {
    color: theme.palette.framework.system.white,
    opacity: 1,
    '&::before': {
      boxShadow: `0px 3px 6px ${theme.palette.system.darkBlackBlue}`,
      border: `1px solid ${theme.palette.system.darkGray}`,
    },
  },
  tooltipPlacementBottom: {
    marginTop: '6px !important',
  },
  multiLine: {
    display: '-webkit-box',
  },
  singleLine: {
    whiteSpace: 'nowrap',
  },
}));

export const OverflowTooltip = props => {
  const { cx, classes } = useStyles({ ...props });
  const textElementRef = useRef(null);

  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);

  const compareSize = React.useCallback(() => {
    if (textElementRef.current)
      props.lineClamp > 1
        ? setHover(textElementRef.current.scrollHeight > textElementRef.current.offsetHeight)
        : setHover(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
  }, [props.lineClamp]);

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);

    return () => {
      window.removeEventListener('resize', compareSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, compareSize, textElementRef.current?.clientWidth]);

  return (
    <Tooltip
      title={props.tooltipContent ?? props.value}
      arrow
      disableHoverListener={!hoverStatus}
      classes={{
        tooltip: cx(
          props?.customTooltipClass ? props?.customTooltipClass : classes.customTooltip,
          props.classes?.tooltip,
        ),
        arrow: classes.customArrow,
        tooltipPlacementBottom: classes.tooltipPlacementBottom,
      }}
      placement={props.placement ?? 'bottom'}
      PopperProps={props.modifyPopper ?? {}}
    >
      {props.defaultRender || props.contentClass ? (
        <CustomContent
          className={`${props.lineClamp > 1 ? classes.multiLine : classes.singleLine} ${props.contentClass}`}
          ref={textElementRef}
          style={props.contentStyle}
        >
          {props.value}
        </CustomContent>
      ) : props.normallyRendered ? (
        <SpanContent style={props.contentStyle} ref={textElementRef}>
          {props.value}
        </SpanContent>
      ) : (
        <TableContent style={props.contentStyle} ref={textElementRef}>
          {props.value}
        </TableContent>
      )}
    </Tooltip>
  );
};

const TableContent = styledComponent.td`
  text-align: start;
  color: #003e68;
  font-size: 12px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 240px;
`;

export const SpanContent = styledComponent.td`
  color: #003e68;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 350px;
  min-width: 350px;
`;

export const CustomContent = styledComponent.div`
  text-overflow: ellipsis;
  overflow: hidden;
`;
