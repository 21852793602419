import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText as AMNListItemText,
  Typography,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { OverflowTooltip } from 'app/components/Common/Tooltips';
import { GetMomentOfDate } from 'utils/dates/moment';
import { useTranslation } from 'react-i18next';
import { Concatenate } from 'utils/string/string';
import styledComponent from 'styled-components';
import { missingField } from 'app/constants';
import { getRequirementCheck } from 'app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/helper';
import { TableSection } from './TableSection';
import { useSelector } from 'react-redux';
import { selectPlacementOrderType } from '../../../../../../../store/redux-store/placement-details/selectors';
import { PlacementOrderType } from 'app/models/Placement/PlacementDetails';

const useStyles = makeStyles()({
  primary: {
    color: '#333333',
    fontWeight: 450,
  },
  secondary: {
    color: '#555555',
  },
  secondaryBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  item: {
    padding: 0,
    margin: 0,
    fontSize: 16,
    '&:not(:last-child)': {
      marginBottom: '12px',
    },
  },
  icon: {
    minWidth: 30,
    flexShrink: 0,
    marginTop: 1,
  },
  dateTag: {
    paddingRight: 6,
  },
  key: {
    whiteSpace: 'nowrap',
  },
  warningColor: {
    color: '#F28D25',
  },
  errorColor: {
    color: '#AB0012',
  },
  dateColor: {
    color: '#333333',
    fontSize: 12,
    '@media (min-width: 1280px)': {
      fontSize: 14,
    },
    fontWeight: 450,
  },
  primaryTooltip: {
    color: '#333333',
    fontWeight: 450,
  },
  button: {
    cursor: 'pointer',
    color: '#006FB9',
    justifyContent: 'flex-start',
    fontWeight: 450,
    padding: '0px  !important',
    margin: '0px !important',
    textDecoration: 'none',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
});

const getListItem = props =>
  customSwitch({
    candidateRreferences: () => <CandidateReferenceItem {...props} />,
    placementCandidateReferences: () => <PlacementCandidateReferenceItem {...props} />,
    skillsChecklist: () => <SkillsChecklistItem {...props} />,
    schedule: () => <Schedule {...props} />,
    orderExperience: () => <OrderExperience {...props} />,
  })(() => <CustomListItem {...props} />);

export const ListSection = ({
  items,
  listType = '',
  openModal,
}: {
  items: any;
  listType?: any;
  openModal?: (sectionName: any, category: any) => void;
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <List disablePadding>
      {items.map(({ primaryContent, secondaryContent, icon, ...rest }) => (
        <ListItem disableGutters alignItems="flex-start" classes={{ root: classes.item }}>
          {icon && <ListItemAvatar classes={{ root: classes.icon }}>{icon}</ListItemAvatar>}
          {getListItem({ primaryContent, secondaryContent, classes, t, openModal, ...rest })(listType)}
        </ListItem>
      ))}
    </List>
  );
};

export const CustomListSection = ({ items, listType = '' }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <List disablePadding>
      {items.map(({ primaryContent, secondaryContent, checklistIcon, ...rest }) => (
        <ListItem disableGutters alignItems="flex-start" classes={{ root: classes.item }}>
          {checklistIcon && (
            <ListItemAvatar classes={{ root: classes.icon }}>{getRequirementCheck(checklistIcon)}</ListItemAvatar>
          )}
          {getListItem({ primaryContent, secondaryContent, classes, t, ...rest })(listType)}
        </ListItem>
      ))}
    </List>
  );
};

const CustomListItem = ({ primaryContent, secondaryContent, classes }) => {
  return (
    <ListItemText
      primary={primaryContent}
      secondary={secondaryContent}
      classes={{ primary: classes.primary, secondary: classes.secondary }}
    />
  );
};

const CandidateReferenceItem = ({ referenceIcon, completedDate, facilityName, evaluatorTitle, t, classes }) => {
  const refCompletionMoment = GetMomentOfDate(completedDate);
  const dateColor = customSwitch({
    2: classes.errorColor,
  })(classes.dateColor);

  return (
    <ListItemText
      style={{ margin: 0 }}
      primary={
        <OverflowTooltip value={facilityName} contentClass={classes.primaryTooltip} contentStyle={{ maxWidth: 400 }} />
      }
      secondary={
        <>
          <Box className={classes.secondaryBox}>
            <Typography variant="body1" classes={{ body1: `${classes.dateTag} ${classes.key}` }}>
              {t('placement.profile.tabs.references.completedDate')}
            </Typography>
            <Typography variant="body1" classes={{ root: dateColor(referenceIcon) }}>
              {refCompletionMoment.isValid() ? refCompletionMoment.format('MM/DD/YYYY') : ''}
            </Typography>
          </Box>
          {evaluatorTitle && (
            <Box className={classes.secondaryBox}>
              <Typography variant="body1" classes={{ body1: `${classes.dateTag} ${classes.key}` }}>
                {t('placement.profile.tabs.references.evaluatorTitle')}
              </Typography>
              <OverflowTooltip
                value={evaluatorTitle}
                contentClass={classes.dateColor}
                contentStyle={{ maxWidth: 300 }}
              />
            </Box>
          )}
        </>
      }
      classes={{ primary: classes.primary, secondary: classes.secondary }}
    />
  );
};

const PlacementCandidateReferenceItem = ({ sectionName, sectionCount, isExpired, details, t, classes, openModal }) => {
  return (
    <ListItemText
      style={{ margin: 0 }}
      primary={
        <>
          <TableSection
            items={[
              {
                primaryContent: (
                  <>
                    {isExpired === false ? (
                      <> {getRequirementCheck(3)} </>
                    ) : isExpired === true ? (
                      <> {getRequirementCheck(2)} </>
                    ) : (
                      <></>
                    )}
                    <span>{sectionName}: </span>
                    {sectionCount > 0 ? (
                      <Button
                        type="button"
                        variant="text"
                        disableRipple
                        disableTouchRipple
                        disableFocusRipple
                        onClick={() => openModal(sectionName, sectionName)}
                        classes={{
                          root: classes.button,
                        }}
                      >
                        {sectionCount}
                      </Button>
                    ) : (
                      <span>{sectionCount}</span>
                    )}
                  </>
                ),
              },
            ]}
          />
        </>
      }
      secondary={
        <>
          {details?.map(detail => {
            return (
              <>
                <Box key={detail.sectionName} className={classes.secondaryBox}>
                  <Typography variant="body1" classes={{ body1: `${classes.dateTag} ${classes.key}` }}>
                    <>
                      {detail.isExpired === false ? (
                        <span style={{ paddingLeft: '4px' }}> {getRequirementCheck(3)} </span>
                      ) : detail.isExpired === true ? (
                        <span style={{ paddingLeft: '4px' }}> {getRequirementCheck(2)} </span>
                      ) : (
                        <></>
                      )}
                      <span>{detail.sectionName}: </span>
                      {detail.sectionCount > 0 ? (
                        <Button
                          type="button"
                          variant="text"
                          disableRipple
                          disableTouchRipple
                          disableFocusRipple
                          onClick={() => openModal(sectionName, detail.sectionName)}
                          classes={{
                            root: classes.button,
                          }}
                        >
                          {detail.sectionCount}
                        </Button>
                      ) : (
                        <span>{detail.sectionCount}</span>
                      )}
                    </>
                  </Typography>
                </Box>
              </>
            );
          })}
        </>
      }
      classes={{ primary: classes.primary, secondary: classes.secondary }}
    />
  );
};

const SkillsChecklistItem = ({ checklistIcon, checklistName, dateCompleted, t, classes }) => {
  const skillCompletionMoment = GetMomentOfDate(dateCompleted || '-1/-1/-1');
  const dateColor = customSwitch({
    2: classes.warningColor,
  })(classes.dateColor);

  return (
    <ListItemText
      primary={checklistName}
      secondary={
        <Box className={classes.secondaryBox}>
          <Typography variant="body1" classes={{ body1: classes.dateTag }}>
            {t('placement.profile.tabs.references.completedDate')}
          </Typography>
          <Typography variant="body1" classes={{ root: dateColor(checklistIcon) }}>
            {skillCompletionMoment.isValid() ? skillCompletionMoment.format('MM/DD/YYYY') : missingField}
          </Typography>
        </Box>
      }
      classes={{ primary: classes.primary, secondary: classes.secondary }}
    />
  );
};

const useScheduleStyle = makeStyles()({
  unitTooltip: { maxWidth: 400 },
  shiftOffered: { display: 'flex', alignItems: 'center' },
});

const Schedule = ({ shifts, float, flexibleSchedule, shiftOffered, t }) => {
  const { classes } = useScheduleStyle();
  const placementOrderType = useSelector(selectPlacementOrderType);
  return (
    <ListItemText
      primary={
        <Box>
          <RowContainer>
            <Tags>{`${t('placement.profile.tabs.references.shifts')}:`}</Tags>
            {shifts?.length
              ? Concatenate(
                  shifts.map(item => item.description),
                  ', ',
                )
              : missingField}
          </RowContainer>
          <br />
          {flexibleSchedule ? (
            <RowContainer>
              <Tags>{`${t('placement.profile.tabs.references.flexibleSchedule')}:`}</Tags>
              <SignificanceIcon significance={flexibleSchedule.isRequired} />
            </RowContainer>
          ) : (
            <RowContainer>
              <Tags>{`${t('placement.profile.tabs.references.flexibleSchedule')}:`}</Tags>
              {missingField}
            </RowContainer>
          )}
          {flexibleSchedule?.workDuringWeekend && (
            <RowContainer>
              <Tags>{`${t('placement.profile.tabs.references.weekendDays')}:`}</Tags>
              {flexibleSchedule.workDuringWeekend}
            </RowContainer>
          )}
          {flexibleSchedule?.workDuringHoliday && (
            <RowContainer>
              <Tags>{`${t('placement.profile.tabs.references.holidays')}:`}</Tags>
              {flexibleSchedule.workDuringHoliday}
            </RowContainer>
          )}
          <br />
          {float ? (
            <RowContainer>
              <Tags>{`${t('placement.profile.tabs.references.float')}:`}</Tags>
              <SignificanceIcon significance={float.isRequired} />
            </RowContainer>
          ) : (
            <RowContainer>
              <Tags>{`${t('placement.profile.tabs.references.float')}:`}</Tags>
              {missingField}
            </RowContainer>
          )}
          {float?.floatToUnit && (
            <RowContainer>
              <Tags>{`${t('placement.profile.tabs.references.unit')}:`}</Tags>
              <OverflowTooltip value={float.floatToUnit} contentClass={classes.unitTooltip} />
            </RowContainer>
          )}
          {float?.floatToLocation && (
            <RowContainer>
              <Tags>{`${t('placement.profile.tabs.references.location')}:`}</Tags>
              {float.floatToLocation}
            </RowContainer>
          )}
          {float?.floatToMiles && (
            <RowContainer>
              <Tags>{`${t('placement.profile.tabs.references.miles')}:`}</Tags>
              {float.floatToMiles}
            </RowContainer>
          )}
          <br />
          {shiftOffered && (
            <RowContainer>
              <Tags className={classes.shiftOffered}>
                {placementOrderType === PlacementOrderType.StrikeOrderInHsg && (
                  <div style={{ marginRight: '12px' }}>{getRequirementCheck(shiftOffered.type)}</div>
                )}
                <span>
                  {' '}
                  {`${t('placement.profile.tabs.references.shiftOffered')}: ${shiftOffered.data ?? missingField}`}{' '}
                </span>
              </Tags>
            </RowContainer>
          )}
        </Box>
      }
    />
  );
};

const OrderExperience = ({ yearsOfExperience, emr, t }) => (
  <ListItemText
    primary={
      <Box>
        <RowContainer>
          <Tags>{`${t('placement.profile.tabs.references.yearsOfExperience')}:`}</Tags>
          {Number.isFinite(yearsOfExperience) ? `${yearsOfExperience}Y` : missingField}
        </RowContainer>
        <br />
        <RowContainer>
          <Tags>{t('placement.profile.tabs.references.emr')}</Tags>
          <SignificanceIcon significance={emr.isRequired} />
        </RowContainer>
        <br />
        <Typography>{Concatenate(emr.values, ', ')}</Typography>
      </Box>
    }
  />
);

const SignificanceIcon = props => <SignificanceAvatar {...props}>{props.significance ? `R` : `P`}</SignificanceAvatar>;

const SignificanceAvatar = styledComponent(Avatar)`
  color: #fff;
  background-color: ${props => (props['significance'] ? '#006FB9' : '#5FB661')};
  width: 22px;
  height: 22px;
  font-size: 12px;
`;

const Tags = styledComponent.div`
  white-space: nowrap;
`;

const RowContainer = styledComponent(Typography)`
  display: flex;
  gap: 6px;
  font-size: 14px;
`;

const ListItemText = styledComponent(AMNListItemText)`
  margin: 0px;
`;
