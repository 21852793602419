import React from 'react';
import { Grid, Typography, Box, Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import styledComponent from 'styled-components';
import { TextWithEllipses } from '../../Common/StringHelper';
import { HubEventType } from '../../Constants';
import { GetMomentOfDate } from 'utils/dates/moment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EmailFailed from 'app/assets/images/Notification/EmailNotification/EmailFailed.svg'
import EmailFailedDisabled from 'app/assets/images/Notification/EmailNotification/EmailFailedDisabled.svg'
import { useTranslation } from 'react-i18next';
import { theme } from 'styles/global-styles';

const useStyles = makeStyles<{ showActionPanel?: boolean }>()((theme: Theme, { showActionPanel }) => ({
  gridContainers: {
    height: 'auto',
    opacity: 1,
  },
  newGridContainer: {
    margin: '0px',
    height: '119px',
    opacity: 1,
    backgroundColor: `${theme.palette.common.white} 0% 0% no-repeat padding-box`,
    boxShadow: ` 0px 0px 6px ${theme.palette.system.lightBlack}`,
    border: `1px solid ${theme.palette.framework.system.lightGray}`,
    borderRadius: ' 5px',
    padding: '12px',
    width: showActionPanel ? '250px' : '350px',
  },
  subject: {
    color: theme.palette.system.charCoalGray,
    paddingRight: '5px',
    fontSize: '0.9rem',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    whiteSpace: 'nowrap'
  },
  subjectRead: {
    color: theme.palette.system.charCoalGray,
    paddingRight: '5px',
    fontSize: '0.9rem',
    fontWeight: 'normal',
    fontFamily: 'Roboto',
    whiteSpace: 'nowrap'
  },
  emailIcon: {
    color: '#d56363',
  },
  sent: {
    marginLeft: 'auto',
    marginRight: '4px',
    font: 'normal normal normal 12px/16px Roboto',
    padding: '5px 5px 0 0',
    color: theme.palette.framework.system.tertiaryGrey,
  },
  attachmentIcon: {
    width: '19px',
    height: '19px',
    marginLeft: 'auto',
  },
  image: {
    margin: '0px',
  },
  emailStatus: {
    color: '#CC0014',
    fontSize: ' 0.75rem !important',
    textAlign: 'left',
    fontWeight: 'bold',
    padding: '0px',
    margin: '0px'
  },
  emailStatusRead: {
    color: theme.palette.system.darkCharcoalGray,
    fontSize: ' 0.75rem !important',
    textAlign: 'left',
    fontWeight: 'normal',
    padding: '0px',
    margin: '0px'
  },
  subHeader: {
    marginLeft: '45px'
  },
  subHeaderNotification: {
    fontWeight: 'bold !important',
    height: '34px',
    fontSize: '12px !important',
    color: `${theme.palette.system.black} ! important`
  },
  subHeaderNotificationRead: {
    fontWeight: 'normal !important',
    height: '34px',
    fontSize: '12px !important',
    color: `${theme.palette.system.black} ! important`
  }
}));

const SubHeader = styledComponent(Typography)`
text-align: left;
font-size: 11px;
font-weight: 500;
letter-spacing: 0px;
color:${theme.palette.framework.system.doveGray};
opacity: 1;
text-overflow: ellipsis;
opacity: 1;
margin-top: 3px;
font-family: 'Roboto';
`;

interface IEmailNotDeliveredPopup {
  subject: string;
  recipientAddr: string;
  status: string;
  publishedOn: string;
  defaultBody?: boolean;
  hasAttachments: boolean;
  isUnread?: boolean;
  showActionPanel: boolean;
}



const failingReason = (status: string, t): string => {
  let failingReason = '';
  switch (status?.toLowerCase()) {
    case HubEventType.EmailBlocked:
      failingReason = t('notification.emailNotification.serverDenied');
      break;
    case HubEventType.EmailBounced:
      failingReason = t('notification.emailNotification.serverDenied');
      break;
    case HubEventType.EmailSpam:
      failingReason = t('notification.emailNotification.spamReported');
      break;
    case HubEventType.EmailUnsubscribed:
      failingReason = t('notification.emailNotification.unSubscribedMessage');
      break;
    default:
      failingReason =
        t('notification.emailNotification.defaultMessage');
      break;
  }
  return failingReason;
};

export const EmailNotDeliveredPopup = ({
  hasAttachments,
  publishedOn,
  subject,
  status,
  recipientAddr,
  defaultBody,
  isUnread,
  showActionPanel
}: IEmailNotDeliveredPopup) => {
  const { classes } = useStyles({ showActionPanel });

  let MAX_SUBJECT_LENGTH;
  let MAX_MESSAGE_LENGTH;
  if (showActionPanel) {
    MAX_SUBJECT_LENGTH = 12;
    MAX_MESSAGE_LENGTH = 65;
  } else {
    MAX_SUBJECT_LENGTH = 25;
    MAX_MESSAGE_LENGTH = 85;
  }


  const { t } = useTranslation()
  const body = `Body: Delivery to ${recipientAddr} failed because ${failingReason(status, t)}`;

  return (
    <>
      <div
        role="button"
        tabIndex={-3}
      >
        <Grid container direction="row" className={classes.newGridContainer}>
          <Grid item xs={2}>
            <img src={isUnread ? EmailFailed : EmailFailedDisabled}
              className={classes.image}
              alt={status} />
          </Grid>
          <Grid item xs={10} style={{ paddingLeft: showActionPanel ? '12px' : '0px' }}>
            <Box display={'flex'}>
              <Typography className={isUnread ? classes.emailStatus : classes.emailStatusRead}>
                {`${t('notification.emailNotification.emailFailed')}`}
              </Typography>
            </Box>
            <Box display={'flex'}>
              <Typography className={isUnread ? classes.subject : classes.subjectRead}>
                Subject: {TextWithEllipses(subject, MAX_SUBJECT_LENGTH)}
              </Typography>
              {hasAttachments ? <AttachFileIcon data-testid="AttachFileIcon" className={classes.attachmentIcon} /> : null}
            </Box>
          </Grid>
          <SubHeader className={isUnread ? classes.subHeaderNotification : classes.subHeaderNotificationRead}>
            {!!defaultBody
              ? t('notification.emailNotification.emailDeliveryFailedMessage')
              : TextWithEllipses(body, MAX_MESSAGE_LENGTH)}
          </SubHeader>
          <Grid className={classes.sent}>
            Sent:{' '}
            {GetMomentOfDate(publishedOn).format('MM/DD/YYYY') +
              ' ' +
              'at' +
              ' ' +
              GetMomentOfDate(publishedOn).format('LT')}
          </Grid>
        </Grid>
      </div>
    </>
  );
};
