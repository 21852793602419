import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography, ToggleButtonGroup, ToggleButton, Alert } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import SearchableList from 'app/components/Common/SearchableList/SearchableList';
import { useTranslation } from 'react-i18next';
import { SnackbarState } from 'app/models/Orders/CreateOrder';
// import { orderBy } from 'lodash';
import { UnitPreview } from './UnitPreview/UnitPreview';
import { NewUnit } from 'app/models/Unit/CreateUnit';
import { colors } from 'styles/styleVariables';
import lightbulb from 'app/assets/images/light-bulb.png';
import { EditUnitForm } from '../EditUnitForm';
import { orderDataActions } from 'app/components/Order/Store/Order.redux';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentUnitId,
  selectReload,
  selectOrderCreationProcessStep,
} from 'app/components/Order/Store/Order.selectors';


interface Props {
  onNewUnitNav(value: boolean): void;
  showMandetryStyle: boolean;
  onShowmsg(value: boolean): void;
  isShowMandetryStyle(value: boolean): void;
  updateDetails(ops: string, value: any): void;
}

const useStyles: any = makeStyles()(theme => ({
  listBox: {
    fontSize: 12,
    color: '#333333',
    fontFamily: 'roboto regular',
    marginLeft: '30%',
    paddingLeft: '5%',
  },
  customAlert: {
    width: '100%',
    '& .MuiAlert-icon': {
      fontSize: 24,
      color: '#694D28',
    },
    alignItems: 'center',
    height: '52px',
  },
  root: {
    padding: theme.spacing(2),
  },
  alertMessage: {
    padding: '13px 0',
  },
  alertColor: {
    backgroundColor: '#E8F4FD',
  },
  alertIcon: {
    padding: '9px 0',
  },

  lightbulb: {
    height: '19px',
    width: '20px',
    marginRight: '10px',
  },
  stepperHelper: {
    textAlign: 'center',
    color: colors.text,
  },
  stepperWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  toggleButtonRoot: {
    textTransform: 'none',
    width: '160px',
    fontWeight: 400,
    color: '#574C4C',
    '&.Mui-selected': {
      fontWeight: 300,
    },
  },
}));

const defaultSelectedUnit: NewUnit = {
  name: '',
  unitId: 0,
  facilityId: 0,
  facilityState: '',
  costCenter: '',
  floor: '',
  experience: {
    isNewGrad: false,
    skillSets: [],
  },
  nurse: null,
  nurseRatio: null,
  patient: null,
  fyreIntegration: null,
  status: 'Active',
  statusId: 0,
  bestBets: '',
  bestBetsId: 0,
  unitSize: null,
  externalJobDescription: '',
  internalNotes: '',
  reference: {
    isRequired: true,
    numberOfReferenceRequired: 0,
    numberOfReferenceToRFO: 0,
    withinMonths: false,
    referenceFromSupervisor: false,
  },
  travelExperience: null,
  qualification: {
    activeLicense: false,
    compactLicense: false,
    emr: null,
    licenses: [],
    certifications: [],
    skillChecklists: [],
  },
  virtualRating: [],
  orderPriorities: [],
  float: null,
  flexibleSchedule: null,
  nonStandardRequirement: [],
  currentUserId: 0,
  yearsOfExperience: null,
  standardizedRequirements: null,
  lastUpdated: null,
  lastUpdatedBy: null,
  unitContacts: null,
  linkedToFacility: '',
  firstDayInstructions: '',
  linkedFacilityDetails: undefined
};

export enum pageParams {
  newUnit = 'newunit',
}

export const DefineUnit = (props: Props) => {
  const { onNewUnitNav, showMandetryStyle, onShowmsg, isShowMandetryStyle, updateDetails } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [unitToDisplay, setUnitToDisplay] = useState<NewUnit>(defaultSelectedUnit);
  const [snackbarState, setSnackbarState] = React.useState<SnackbarState>({
    snackOpen: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const currentUnitId = useSelector(selectCurrentUnitId);
  const reloadState = useSelector(selectReload);
  const storeOrderCreationProcessStep = useSelector(selectOrderCreationProcessStep);

  const dispatch = useDispatch();
  useEffect(() => {
    setUnitDetails(storeOrderCreationProcessStep?.unitsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reloadState && currentUnitId !== 0) {
      const filteredUnit = getUnitFromID(currentUnitId);
      setUnitToDisplay(filteredUnit);
      dispatch(orderDataActions.setNewUnit(filteredUnit));
      dispatch(orderDataActions.setNewOrder(null));
      dispatch(orderDataActions.setReload(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadState]);

  const setUnitDetails = res => {
    // unitDetails = res;
  };

  const getUnitFromID = useCallback(
    id => {
      if (id === 0) return defaultSelectedUnit;
      return storeOrderCreationProcessStep?.unitsData?.filter(unit => unit.unitId === +id)[0] as NewUnit;
    },
    [storeOrderCreationProcessStep],
  );

  const handleSelect = value => {
    updateDetails('select unit', value);
  };

  const handleHover = value => {
    if (value === 0) return;
    const filteredUnit = getUnitFromID(value);
    setUnitToDisplay(filteredUnit);
  };

  useEffect(() => {
    if (storeOrderCreationProcessStep && storeOrderCreationProcessStep?.selectedNewUnitId > 0) {
      setUnitDetails(storeOrderCreationProcessStep?.unitsData);
      const filteredUnit = getUnitFromID(storeOrderCreationProcessStep?.selectedNewUnitId);
      setUnitToDisplay(filteredUnit);
    }
  }, [getUnitFromID, storeOrderCreationProcessStep]);

  const handleOpenCreateUnit = () => {
    onNewUnitNav(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleSnackClose();
    }, 3000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snackbarState]);

  const handleSnackClose = () => {
    setSnackbarState({ ...snackbarState, snackOpen: false });
  };

  const onUnitToggle = (event, value) => {
    setToggleValue(value);
    if (value === 1) {
      dispatch(orderDataActions.setNewUnit(null));
      dispatch(orderDataActions.setIsEditUnit(false));
      dispatch(orderDataActions.setLevel('None'));
      handleOpenCreateUnit();
    }
  };

  const [toggleValue, setToggleValue] = React.useState<any>(0);

  return (
    <>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} justifyContent="center" className={classes.stepperHelper}>
          {storeOrderCreationProcessStep?.currentStep === 'SelectUnit' && (
            <div className={classes.stepperWrapper}>
              <img src={lightbulb} alt={`info`} className={classes.lightbulb} />
              <Typography>{`Select a Unit from the list below or Create a New Unit`}</Typography>
            </div>
          )}
          {storeOrderCreationProcessStep?.currentStep === 'EditOrderRequirement' && (
            <div className={classes.stepperWrapper}>
              <img src={lightbulb} alt={`info`} className={classes.lightbulb} />
              <Typography>{`Fill in the form below to edit order level requirements`}</Typography>
            </div>
          )}
        </Grid>
        {storeOrderCreationProcessStep?.currentStep === 'SelectUnit' && (
          <Grid item xs={12} justifyContent="center" style={{ display: 'flex' }}>
            <ToggleButtonGroup value={toggleValue} exclusive onChange={onUnitToggle}>
              <ToggleButton id={'SelectUnitExistingBtn'} classes={{ root: classes.toggleButtonRoot }} value={0}>
                {`Select Existing Unit`}
              </ToggleButton>
              <ToggleButton id={'SelectUnitCreateBtn'} classes={{ root: classes.toggleButtonRoot }} value={1}>
                {`Create New Unit`}
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        )}
        {storeOrderCreationProcessStep?.currentStep === 'SelectUnit' && (
          <Grid item xs={12} data-testid="render-defineUnitBody">
            <Grid data-testid="render-searchableList" container spacing={2}>
              <Grid item xs={3} data-testid="test-searchableList">
                <SearchableList
                  id={'SelectUnitSearch'}
                  data={storeOrderCreationProcessStep.unitsData}
                  onSelect={handleSelect}
                  onHover={handleHover}
                  className={classes.listBox}
                  selectedValue={storeOrderCreationProcessStep.selectedNewUnit?.unitId}
                ></SearchableList>
              </Grid>
              {unitToDisplay && unitToDisplay.unitId > 0 && (
                <Grid item xs={9}>
                  <Grid data-testid="render-innerPanel" container spacing={2}>
                    {(!unitToDisplay.createdByVersion ||
                      (unitToDisplay.createdByVersion && unitToDisplay.createdByVersion !== '2.0')) && (
                      <Grid item xs={12}>
                        <Alert
                          severity="info"
                          className={classes.customAlert}
                          classes={{
                            message: classes.alertMessage,
                            standardInfo: classes.alertColor,
                            icon: classes.alertIcon,
                          }}
                        >
                          {t('order.createUnit.alertMessage')}
                        </Alert>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <UnitPreview unitData={unitToDisplay}></UnitPreview>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        {storeOrderCreationProcessStep &&
          storeOrderCreationProcessStep.selectedNewUnit &&
          storeOrderCreationProcessStep?.currentStep === 'EditOrderRequirement' &&
          storeOrderCreationProcessStep.isRequirementEdited && (
            <EditUnitForm
              onNewUnitNav={onNewUnitNav}
              unitData={storeOrderCreationProcessStep.selectedNewUnit}
              hasNotNow={storeOrderCreationProcessStep.editMode === 'notnow'}
              hasStandardize={storeOrderCreationProcessStep.editMode === 'standardize'}
              showMandetryStyle={showMandetryStyle}
              onShowmsg={onShowmsg}
              isShowMandetryStyle={isShowMandetryStyle}
            />
          )}
      </Grid>
    </>
  );
};
