/* eslint-disable i18next/no-literal-string */
import React from 'react';
import { Expandable } from 'app/components/Common/Expandable/Expandable';
import { IconTitle } from './Titles/IconTitle';
import OrangeWarning from 'app/assets/images/IconImages/OrangeWarningIcon.svg';
import styledComponent from 'styled-components';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { getPlacementListIcon } from 'app/components/Placement/PlacementDetails/helper';
import { selectPlacementStatus } from '../../../../../../store/redux-store/placement-status/selectors';
import { useSelector } from 'react-redux';
import { colors } from 'styles/styleVariables';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles<{ inlineDense: boolean }>()((theme, { inlineDense }) => ({
  root: {
    maxHeight: 190,
    overflowY: 'scroll',
  },
  primary: {
    color: '#333333',
    fontWeight: 450,
  },
  secondary: {
    color: '#555555',
    marginTop: '1px',
    fontSize: '0.9rem',
  },
  item: {
    padding: 0,
    fontSize: 16,
  },
  icon: {
    minWidth: 30,
    flexShrink: 0,
    marginTop: 4,
  },
  listContainer: {
    paddingLeft: inlineDense ? '8px' : '36px',
  },
}));

export const Risks = ({ expanded = true, loading = false, inlineDense = false, ...props }) => {
  const { t } = useTranslation();
  const [warningRequirementsCount, setWarningRequirementsCount] = React.useState<number>(0);
  const { classes } = useStyles({ inlineDense });
  const { warningRequirements } = useSelector(selectPlacementStatus);

  React.useEffect(() => {
    setWarningRequirementsCount(
      Object.values(warningRequirements || {})?.reduce((storage, item) => {
        return storage + item.length;
      }, 0),
    );
  }, [warningRequirements]);

  return (
    <Expandable
      id="placement-summary-risks"
      titleComponent={
        <IconTitle
          title={'Risks'}
          icon={<img src={OrangeWarning} alt="Risk" style={{ width: '20px', height: '20px' }} />}
          titleSupportComponent={!loading && <RiskCount count={warningRequirementsCount} />}
        />
      }
      disabled={!loading && warningRequirementsCount <= 0}
      expanded={expanded === false ? false : loading || (expanded && warningRequirementsCount > 0)}
      loading={loading}
      variant={inlineDense ? 'inlineDense' : undefined}
    >
      <List
        disablePadding
        classes={{ root: classes.root }}
        data-testid="placementRisk-itemContainer"
        id={'placementSummaryDetailsRiskContainer'}
      >
        {Object.values(warningRequirements || {}).map((riskItems, i1) => {
          return riskItems.map(
            (riskItem, i2) =>
              riskItem?.message && (
                <ListItem
                  classes={{ root: classes.item }}
                  className={classes.listContainer}
                  alignItems="flex-start"
                  aria-label="placementrisk-item"
                  key={`${i1}-${i2}`}
                >
                  <ListItemAvatar classes={{ root: classes.icon }}>
                    {riskItem.tooltip ? (
                      <CustomTooltip
                        standardMargin
                        placement="bottom"
                        tooltipContent={
                          <Typography variant="body1">
                            {riskItem.tooltip?.message == null ? '' : t(riskItem.tooltip?.message)}
                          </Typography>
                        }
                      >
                        {getPlacementListIcon(2)}
                      </CustomTooltip>
                    ) : (
                      getPlacementListIcon(2)
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    secondary={t(riskItem?.message)}
                    classes={{
                      primary: classes.primary,
                      secondary: classes.secondary,
                    }}
                  />
                </ListItem>
              ),
          );
        })}
      </List>
    </Expandable>
  );
};

const RiskCount = ({ count }) => {
  return <RiskBox>{count}</RiskBox>;
};

const RiskBox = styledComponent(Avatar)`
  height: 25px;
  width: 25px;
  background-color: #e6e6e6;
  color: ${colors.text};
  font-size: 16px;
  font-weight: 600;
`;
