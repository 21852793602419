import { IChip } from 'app/ComponentLibrary/Filter/ChipsContainer';

export enum OrderBonusFieldNames {
  bonusType = 'bonusType',
  bonusAmount = 'bonusAmount',
  startDate = 'startDate',
  endDate = 'endDate',
  payer = 'payer',
  paymentType = 'paymentType',
  shortHourType = 'shortHourType',
  lessThan13Weeks = 'lessThan13Weeks',
  billingType = 'billingType',
  bonusBillAmount = 'bonusBillAmount',
  billingAmount = 'billingAmount',
  approvedBy = 'approvedBy',
  approvalType = 'approvalType',
  approvalDate = 'approvalDate',
  minWeeksWorked = 'minWeeksWorked',
  notes = 'notes',
  shiftsApplicable = 'shiftsApplicable',
  statusUpdatedDate = 'statusUpdatedDate',
}
export enum OrderBonusStatus {
  approve = 'Approve',
  approved = 'Approved',
  pending = 'Pending',
  expired = 'Expired',
  void = 'Void',
}

export enum BonusTypes {
  startDate = 'Start Date',
  dateBooked = 'Date Booked',
  additionalPay = 'Additional Pay',
  marketingAdjustment = 'Marketing Adjustment',
}
export enum CalculationTypes {
  bonusRate = 'bonusrate',
  companyBillRate = 'companybillrate',
}

export const OrderBonusFormDefaultValues = {
  bonusType: null,
  bonusAmount: null,
  startDate: null,
  endDate: null,
  payer: null,
  paymentType: null,
  shortHourType: null,
  lessThan13Weeks: null,
  billingType: null,
  bonusBillAmount: null,
  billingAmount: null,
  approvedBy: null,
  approvalType: null,
  approvalDate: null,
  minWeeksWorked: null,
  shiftsApplicable: [],
  notes: null,
  statusUpdatedDate: null
};

export const OrderBonusFormFields = [
  {
    FieldName: OrderBonusFieldNames.bonusType,
    required: true,
  },
  {
    FieldName: OrderBonusFieldNames.bonusAmount,
    required: true,
  },
  {
    FieldName: OrderBonusFieldNames.startDate,
    required: true,
  },
  {
    FieldName: OrderBonusFieldNames.endDate,
    required: true,
  },
  {
    FieldName: OrderBonusFieldNames.payer,
    required: true,
  },
  {
    FieldName: OrderBonusFieldNames.paymentType,
    required: true,
  },
  {
    FieldName: OrderBonusFieldNames.shortHourType,
    required: true,
  },
  {
    FieldName: OrderBonusFieldNames.lessThan13Weeks,
    required: true,
  },
  {
    FieldName: OrderBonusFieldNames.minWeeksWorked,
    required: true,
  },
  {
    FieldName: OrderBonusFieldNames.billingType,
    required: true,
  },
  {
    FieldName: OrderBonusFieldNames.bonusBillAmount,
    required: true,
  },
  {
    FieldName: OrderBonusFieldNames.billingAmount,
    required: true,
  },
  {
    FieldName: OrderBonusFieldNames.approvedBy,
    required: false,
  },
  {
    FieldName: OrderBonusFieldNames.approvalType,
    required: true,
  },
  {
    FieldName: OrderBonusFieldNames.approvalDate,
    required: true,
  },
  {
    FieldName: OrderBonusFieldNames.notes,
    required: false,
  },
  {
    FieldName: OrderBonusFieldNames.shiftsApplicable,
    required: false,
  },
];

export interface IOrderBonusChips {
  orderIds: IChip[];
  facilityIds: IChip[];
  divisionIds: IChip[];
  disciplineIds: IChip[];
  specialtyIds: IChip[];
  subSpecialtyIds: IChip[];
  facilityStatusIds: IChip[];
  orderTypeIds: IChip[];
  orderPriorityIds: IChip[];
  orderStartDateRange: Date;
  dateEnteredRange: any | null;
  endDateRange: any | null;
  positions: any | null;
  states: IChip[];
  city: IChip[];
  paysGww: any | null;
  maxFilesSentId: any | null;
  shiftIds: IChip[];
  amUserIds: IChip[];
}
export const initialFilterOrderChips: IOrderBonusChips = {
  orderIds: [],
  facilityIds: [],
  divisionIds: [],
  disciplineIds: [],
  specialtyIds: [],
  subSpecialtyIds: [],
  facilityStatusIds: [],
  orderTypeIds: [],
  orderPriorityIds: [],
  orderStartDateRange: null,
  dateEnteredRange: null,
  endDateRange: null,
  positions: null,
  states: [],
  city: [],
  paysGww: null,
  maxFilesSentId: null,
  shiftIds: [],
  amUserIds: [],
};
