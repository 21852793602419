import { makeStyles, withStyles } from 'tss-react/mui';
import { Accordion as MuiAccordion, Divider, Grid } from 'amn-ui-core';
import styledComponent from 'styled-components';
import { theme } from 'styles/global-styles';

export const useSuperVisorStyles = makeStyles()(() => ({
  dialogContainerSupervisor: {
    minHeight: '574px',
    minWidth: '600px',
  },
  snackBarActionItems: {
    color: theme.palette.system.darkBlue,
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dialogContainerGrid: {
    width: '100%',
    padding: '0px 0px 0px 0px !important',
  },
  skillSetContent: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    whiteSpace: 'nowrap',
    color: theme.palette.framework.system.doveGray,
    fontSize: '14px',
    fontWeight: '400',
  },
  editIcon: {
    textAlign: 'right',
  },
  accordion: {
    border: `1px solid ${theme.palette.system.paleGray}`,
    borderRadius: '4px',
  },
}));

export const Accordion = withStyles(MuiAccordion, (_theme, _params, classes) => ({
  root: {
    [`&.${classes.root}:before`]: {
      height: 0,
    },
  },
}));

export const useEducationalCardStyles = makeStyles()({
  container: {
    flexWrap: 'nowrap',
  },
  editLink: {
    textAlign: 'right',
    textDecoration: 'none',
  },
});

export const DateDiv = styledComponent.div`
  font-size: 12px;
  color: ${theme.palette.framework.system.charcoal};
  font-weight: 600;
  text-align: start;
  margin-top: 10px;
  display: flex;
`;

export const VerticalDivider = styledComponent(Divider)`
  margin-right: 20px !important;
`;

export const EduGrid = styledComponent(Grid)`
  display: flex;
  flex-direction: row;
`;

export const ContentContainer = styledComponent(Grid)`
  min-height: 100px;
`;
export const JobGrid = styledComponent(Grid)`
  max-width: 70%;
`;

export const TextGrid = styledComponent(Grid)`
  color: ${theme.palette.framework.system.charcoal};
  font-size: 14px;
`;

export const FacilityGrid = styledComponent(Grid)`
  color: ${theme.palette.framework.system.charcoal};
  font-weight: bold;
  font-size: 14px;
  text-align: left;
`;

export const HeadDiv = styledComponent.span`
  color: ${theme.palette.framework.system.charcoal};
  font-size: 14px;
`;

export const CountDiv = styledComponent.strong`
  color: #003e68;
  font-size: 14px;
`;

export const useEducationalHistoryStyles: any = makeStyles()({
  timeLineRoot: {
    padding: 0,
    margin: 0,
  },
  dotRoot: {
    margin: '0px auto',
  },
  removeOppoContent: {
    '&:before': {
      display: 'none',
    },
  },
  viewLink: {
    textAlign: 'right',
    textDecoration: 'none',
  },
  linkButton: {
    textDecoration: 'underline',
    '&:hover': { textDecoration: 'underline' },
    textTransform: 'none',
    color: '#006FB9',
  },
});

export const usePreferenceCardStyles = makeStyles()(() => ({
  paperRoot: {
    padding: '10px 0px !important',
  },
  gridContainer: {
    padding: '12px 5px 12px 20px',
  },
  itemContainer: {
    padding: '0px 16px 12px',
  },
  itemContainerV2: {
    padding: '0px 12px 12px 0px',
  },
  editIcon: {
    textAlign: 'right',
  },
  lastUpdated: {
    color: 'gray',
    fontSize: '14px',
  },
  header: {
    fontSize: '16px',
    fontWeight: 500,
  },
  lastUpdatedTitle: {
    color: 'gray',
    fontSize: '14px',
    fontWeight: 500,
  },
  data: {
    fontSize: '14px !important',
  },
  title: {
    fontSize: '12px !important',
    fontWeight: 600,
  },
}));
