import { Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';

export const addWorkExperienceFormStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: '0px 24px 0px 24px',
  },
  action: {
    display: 'flex',
    flexDirection: 'row',
  },
  actionBtn: {
    fontSize: 12,
    padding: 0,
    minWidth: 30,
  },
  actionNoBtn: {
    color: `${theme.palette.framework.system.tertiaryGrey}`,
  },

  alingmentField: {
    paddingRight: '25px !important',
  },
  currentlyWorking: {
    marginBottom: '-15px',
  },
  datePickerInput: {
    height: '55px',
  },

  contentContainer: {
    padding: '0px',
    border: `1px solid ${theme.palette.system.paleGray}`,
    borderRadius: '4px 4px 4px 4px',
    margin: '24px',
  },
  checkBoxContainer: {
    marginBottom: '-30px',
  },
  checkBoxInput: {
    paddingTop: '24px !important',
  },
  heading: {
    border: `1px solid ${theme.palette.system.lightGrey}`,
    borderRadius: '4px 4px 4px 4px',
    background: `${theme.palette.system.skyBlue}`,
    display: 'flex',
    flexDirection: 'column',
    padding: '6px !important',
  },
  buttonStyle: {
    color: `${theme.palette.system.persianPlum}`,
    fontSize: '14px',
    fontWeight: 500,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  infoIcon: {
    color: `${theme.palette.system.spanishSkyBlue}`,
    height: '20px',
    width: '20px',
  },
  infoIconAlignment: {
    paddingTop: '30px !important',
  },
  label: {
    fontSize: '14px !important',
    color: 'rgba(0, 0, 0, 0.37)',
    '&.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.37)',
    },
    '&[class*="MuiInputLabel-outlined"]': {
      marginTop: '1px',
    },
  },
  textBox: {
    backgroundColor: `${theme.palette.system.white}`,
    marginTop: '1%',
    marginBottom: '8px',
    '& .MuiInputBase-root': {
      height: '135px',
      display: 'inline-flex',
      flexDirection: 'column',
      margin: '2px',
    },
    '& .MuiOutlinedInput-root': {
      margin: '2px',
      position: 'initial',
    },
    '& label': {
      '&.Mui-focused': {
        color: `${theme.palette.system.dimGrey2}`,
      },
    },
  },
  actionButton: {
    backgroundColor: `${theme.palette.system.smokeWhite}`,
    boxShadow: '0px -1px 3px rgba(0, 0, 0, 0.29)',
    borderRadius: '0px 0px 4px 4px',
    opacity: 0.95,
    backdropFilter: 'blur(30px)',
    WebkitBackdropFilter: 'blur(30px)',
    height: theme.spacing(11),
    paddingBottom: theme.spacing(2),
  },
  textFieldError: {
    p: {
      position: 'absolute',
    },
  },
}));
