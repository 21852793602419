import { CustomFilter } from 'app/ComponentLibrary/Filter';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { defaultValues, getFilterSpecs } from './utils';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { candidateDetailsSelection, selectChannelValues } from '../../Profile/CandidateDetails.selector';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { candidateDetailActions, loadGridUserLogs, loadKpis } from '../../Profile/CandidateDetails.redux';
import { useParams } from 'react-router';
import { RouteParams } from '../../Profile/CandidateProfile';
import { selectUser } from 'oidc/user.selectors';
import _ from 'lodash';
import { useDecision } from '@optimizely/react-sdk';
import { ff_notifications_enable_shareddesk_communication } from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';

export const CommunicationLogsFilter = () => {
  const candidateDetails = useSelector(candidateDetailsSelection);
  const [sharedDeskFlag] = useDecision(ff_notifications_enable_shareddesk_communication);
  const channels = useSelector(selectChannelValues);
  const user = useSelector(selectUser);
  const userRoleOpts = ['Account Management', 'Recruitment', 'Credentialing'];
  const defaultOpts = ['All', 'Me'];
  const initiatedByOpts =
    sharedDeskFlag.enabled && !_.isEmpty(user?.userInfo?.sharedProfile) ? [...defaultOpts, 'Shared Desk'] : defaultOpts;
  const dispatch = useDispatch();
  const CandidateLogs = candidateDetails?.gridLogsData?.results?.length;
  const isCandidateLogsEmpty = CandidateLogs === null || CandidateLogs === 0;
  const [fields, setFields] = useState(getFilterSpecs([], userRoleOpts, initiatedByOpts, isCandidateLogsEmpty));
  useEffect(() => {
    setFields(getFilterSpecs(channels || [], userRoleOpts, initiatedByOpts, isCandidateLogsEmpty));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateDetails.channels, channels, candidateDetails]);
  const { reset } = useFormContext();
  const params = useParams<RouteParams>();
  const onResetForm = () => {
    dispatch(
      candidateDetailActions.getAvailableChannelFilters({
        contactId: params.travelerId.toString(),
        brandId: params.brandId.toString(),
      }),
    );

    reset(defaultValues);
    dispatch(
      loadGridUserLogs({
        pageNumber: 1,
        pageSize: 50,
        brandId: params.brandId.toString(),
        contactId: params.travelerId.toString(),
      }),
    );

    dispatch(
      loadKpis({
        pageNumber: 1,
        brandId: params.brandId.toString(),
        channel: ['Email', 'Conversation', 'Voice'],
        contactId: params.travelerId.toString(),
      }),
    );
    dispatch(notificationDataActions.resetChips());
    dispatch(candidateDetailActions.setFilterApplied(false));
  };
  return <CustomFilter fields={fields} justifyContent={'flex-start'} version2 onResetForm={onResetForm} />;
};
