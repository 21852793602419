import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChip } from 'app/ComponentLibrary/Filter/ChipsContainer';
import { ISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import { RemindersFilters } from '../Filters/PlacementRemindersConfig';
import { setPlacementVolumeTotals } from '../Helpers/PlacementRemindersFilterHelper';

export interface IPlacementReminderCount {
  today: any;
  sheduled: any;
  all: any;
}
export enum reminderstatus {
  today = 'Today',
  sheduled = 'Sheduled',
  all = 'All',
}

export interface IFilterChipSelected {
  [RemindersFilters.placementId]: any[] | null;
  [RemindersFilters.candidateName]: any[] | null;
  [RemindersFilters.placementStatus]: any[] | null;
  [RemindersFilters.clientName]: any[] | null;
  [RemindersFilters.candidateId]: any[] | null;
  [RemindersFilters.reminderDate]: any[] | null;
  [RemindersFilters.reminderStatus]: any[] | null;
  [RemindersFilters.reminderType]: any[] | null;
  [RemindersFilters.daysBeforeStart]: any[] | null;
  [RemindersFilters.startDate]: any[] | null;
}

export interface IFilterSpecs {
  placementIDOpts: ISelection[];
  placementStatusOpts: ISelection[];
  candidateIdOpts: ISelection[];
  candidateNameOpts: ISelection[];
  clientNameOpts: ISelection[];
  reminderStatusOpts: ISelection[];
  reminderTypeOpts: ISelection[];
}

export const defaultFilterChipSelected = {
  [RemindersFilters.placementId]: null,
  [RemindersFilters.candidateName]: null,
  [RemindersFilters.placementStatus]: null,
  [RemindersFilters.clientName]: null,
  [RemindersFilters.candidateId]: null,
} as IFilterChipSelected;

export interface IPlacementFilterChips {
  pipelineSelected: IChip[];
  [RemindersFilters.placementId]: IChip[];
  [RemindersFilters.candidateName]: IChip[];
  [RemindersFilters.placementStatus]: IChip[];
  [RemindersFilters.clientName]: IChip[];
  [RemindersFilters.candidateId]: IChip[];
}

export const defaultChips: IPlacementFilterChips = {
  pipelineSelected: [],
  [RemindersFilters.placementId]: [],
  [RemindersFilters.candidateName]: [],
  [RemindersFilters.placementStatus]: [],
  [RemindersFilters.clientName]: [],
  [RemindersFilters.candidateId]: [],
};

export interface IPlacementsReminderPage {
  counts: any | null;
  flattenedData: any[];
  paging: any;
  stateID: any;
  filteredData?: any[];
  filterSpecs?: IFilterSpecs;
  filterChipSelected: IFilterChipSelected;
  chips: IPlacementFilterChips;
  loading: boolean;
}

export const initialState: IPlacementsReminderPage = {
  flattenedData: [],
  filteredData: undefined,
  counts: null,
  paging: null,
  stateID: null,
  filterSpecs: undefined,
  filterChipSelected: defaultFilterChipSelected,
  chips: defaultChips,
  loading: false,
};

const requestCredentialingWorkDeskPlacementReminderList = createAction('credentialingWorkDesk/placementreminders');

export const placementReminder = createSlice({
  name: 'placementReminderTotals',
  initialState: initialState,
  reducers: {
    setCounts(state, action: PayloadAction<any>) {
      state.counts = action.payload;
    },
    setStateID(state, action: PayloadAction<any>) {
      state.stateID = action.payload;
    },
    setFlattenedData(state, action: PayloadAction<any[]>) {
      state.flattenedData = action.payload;
    },
    setPaging(state, action: PayloadAction<any>) {
      state.paging = action.payload;
    },
    setFilterSpecs(state, action: PayloadAction<IFilterSpecs>) {
      state.filterSpecs = action.payload;
    },
    setFilterChipSelected(state, action: PayloadAction<IFilterChipSelected>) {
      state.filterChipSelected = action.payload;
    },
    setFilterChips(state, action: PayloadAction<IPlacementFilterChips>) {
      state.chips = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setFilteredData(state, action: PayloadAction<any[] | undefined>) {
      state.filteredData = action.payload;
      state.counts = setPlacementVolumeTotals(action.payload || state.flattenedData);
    },
    reset() {
      return { ...initialState };
    },
  },
});

const { actions } = placementReminder;

export const { reducer: placementReminderTotalsReducer, name: placementReminderSliceKey } = placementReminder;
export const placementReminderActions = {
  ...actions,
  requestCredentialingWorkDeskPlacementReminderList,
};
