import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GridColumnVisibilityModel } from '@mui/x-data-grid-pro';

export enum GridTag {
  Default = 'Default',
  JobMatchGrid = 'JobMatchGrid',
  JobInterestGrid = 'JobInterestGrid',
  RecPlacementDesk = 'RecPlacementDesk',
  AmPlacementDesk = 'AmPlacementDesk',
  CredentialsGrid = 'CredentialsGrid',
  CommunicationHistoryGrid = 'CommunicationHistoryGrid',
  CredentialsPlacementStatusGrid = 'CredentialsPlacementStatusGrid',
  CredentialsExpiringDocumentGrid = 'CredentialsExpiringDocumentGrid',
  CredentialsPlacementRemindersGrid = 'CredentialsPlacementRemindersGrid',
  /** GlobalSearchGrid: Naming should consider 'category' from url (ref: SearchResultsPage) */
  GlobalSearchCandidateGrid = 'GlobalSearchGrid-candidate',
  GlobalSearchPlacementGrid = 'GlobalSearchGrid-placement',
  FacilityClientContactsGrid = 'FacilityClientContactsGrid',
  UnitSelectClientContactsGrid = 'UnitEditSelectClientContactsGrid',
  FacilityUnitsGrid = 'FacilityUnitsGrid',
  PricingWorkDeskGrid = 'PricingWorkDeskGrid',
  CoverageRequestsGrid = 'CoverageRequestsGrid',
  FacilityOrderUnitsGrid = 'FacilityOrderUnitsGrid',
  AffiliatesXgridModal = 'AffiliatesXgridModal',
  TaskXGrid = 'GlobalSearchGrid-tasks',
  PlacementChangeSearchGrid = 'PlacementChangeSearchGrid',
  CandidatePlacements = 'CandidatePlacements',
  FacilityPlacements = 'FacilityPlacements',
  CopyOrderBonusGrid = 'CopyOrderBonusGrid',
  ChangeOrderIDGrid = 'ChangeOrderIDGrid',
}

export interface IGridState {
  gridTag: string;
  pageSize: number;
  pageNumber: number;
  previewItemId: number;
  selectedFilter?: string;
  showPreviewDialog: boolean;
  columns?: any;
  columnVisibilityModel?: GridColumnVisibilityModel;
  sortedColumn: SortColumn | undefined | null;
  filterAttributes?:
  | { filters: [string, unknown][]; dirtyFields: any | undefined; selectedChipFilters: any; filterSet?: any; }
  | undefined;
  saveAsDefaultView?: boolean;
  highlightedRow?: any;
  fetchSwitch?: boolean;
  filtersExpanded?: boolean;
  multiSortedColumns?: SortColumn[] | undefined | null;
}

export type SortColumn = { column: string; direction: 'asc' | 'desc' };

export const initialState: IGridState = {
  gridTag: GridTag.Default,
  pageSize: 0,
  pageNumber: 0,
  previewItemId: 0,
  columns: undefined,
  columnVisibilityModel: undefined,
  showPreviewDialog: false,
  sortedColumn: undefined,
  filterAttributes: undefined,
  highlightedRow: undefined,
  saveAsDefaultView: false,
  selectedFilter: '',
  fetchSwitch: false,
  filtersExpanded: false,
  multiSortedColumns: undefined,
};

const gridStateSlice = createSlice({
  name: 'gridState',
  initialState,
  reducers: {
    setFetchSwitch(state) {
      return {
        ...state,
        fetchSwitch: !state.fetchSwitch,
      };
    },
    setGridState(state, action: PayloadAction<IGridState>) {
      return { ...action.payload };
    },
    setGridAttributesState(state, action: PayloadAction<IGridState>) {
      return {
        ...state,
        gridTag: action.payload.gridTag,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        showPreviewDialog: action.payload.showPreviewDialog,
        saveAsDefaultView: action.payload.saveAsDefaultView,
      };
    },
    setGrid(state, action: PayloadAction<string>) {
      state.gridTag = action.payload;
    },
    setPageAttributes(state, action: PayloadAction<{ pageSize: number; pageNumber: number }>) {
      state.pageSize = action.payload.pageSize;
      state.pageNumber = action.payload.pageNumber;
    },
    setPageNumber(state, action: PayloadAction<number>) {
      state.pageNumber = action.payload;
    },
    setPreviewItemId(state, action: PayloadAction<number>) {
      state.previewItemId = action.payload;
    },
    setShowPreviewItem(state, action: PayloadAction<boolean>) {
      state.showPreviewDialog = action.payload;
    },
    setColumns(state, action: PayloadAction<any>) {
      return {
        ...state,
        columns: action.payload,
      };
    },
    setColumnVisibilityModel(state, action: PayloadAction<GridColumnVisibilityModel>) {
      return {
        ...state,
        columnVisibilityModel: action.payload,
      };
    },
    setFilterSet(state, action: PayloadAction<any>) {
      if (state.filterAttributes) {
        state.filterAttributes.filterSet = action.payload;
      }
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    setSortedColumn(state, action: PayloadAction<{ column: string; direction: 'asc' | 'desc' } | null>) {
      state.sortedColumn = action.payload;
    },
    setMultiSortedColumn(state, action: PayloadAction<SortColumn[] | null>) {
      state.multiSortedColumns = action.payload;
    },
    setFilterAttributes(
      state,
      action: PayloadAction<{ filterObject: any; dirtyFields?: any; selectedChipFilters?: any; fetchSwitch?: boolean; }>,
    ) {
      return {
        ...state,
        filterAttributes: {
          filters: action.payload.filterObject,
          filterSet: state.filterAttributes?.filterSet,
          dirtyFields: { ...action.payload.dirtyFields },
          selectedChipFilters: action.payload.selectedChipFilters || {}
        },
        fetchSwitch: action.payload.fetchSwitch ? !state.fetchSwitch : state.fetchSwitch,
      };
    },
    setSelectedFilters(state, action: PayloadAction<any>) {
      if (state.filterAttributes) state.filterAttributes.filters = Object.entries(action.payload);
    },
    setSelectedChips(state, action: PayloadAction<any>) {
      if (state.filterAttributes) state.filterAttributes.selectedChipFilters = action.payload;
    },
    setSaveAsDefaultView(state, action: PayloadAction<boolean>) {
      state.saveAsDefaultView = action.payload;
    },
    setHighlightedRow(state, action: PayloadAction<any | undefined>) {
      return {
        ...state,
        highlightedRow: action.payload,
      };
    },
    setFiltersExpanded(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        filtersExpanded: action.payload,
      };
    },
    reset() {
      return { ...initialState };
    },
    setSelectedFilterSet(state, action: PayloadAction<string>) {
      state.selectedFilter = action.payload;
    },
  },
});

export const { actions: gridStateActions, name: gridStateSliceKey, reducer: gridStateReducer } = gridStateSlice;
