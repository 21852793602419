import { Box, Skeleton, Typography } from 'amn-ui-core';
import React from 'react';
import { LinearProgressWithLabel } from '../../Common/LinearProgressWithLabel';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips';
import { RowSection } from '../../Common/RowSection';
import { ISkillCheckListDetails } from 'app/models/Candidate/SkillSet';
import { usePromiseTracker } from 'react-promise-tracker';
import {
  generateHeader,
  getExpirationDateLabel,
  getWarningText,
  validateAlert,
  validateChecked,
  validateIncomplete,
} from '../helper';
import { ISkillSetExpiration } from 'app/enums/SkillSet';
import { useTranslation } from 'react-i18next';
import { useStyles } from './utils';
import { CandidateContainer } from '../../Common/CandidateContainer';
import SkillsChecklistIcon from 'app/assets/images/IconImages/SkillsChecklistIcon.svg';
import { theme } from 'styles/global-styles';

function SkillSetUIV2({
  showUpdatedSummaryIcons,
  skillChecklistProgress,
  checklistItemCount,
  skillChecklistData,
  handleNavigation,
  verifiedSkillSets,
  allSkillSets,
  combinedCheckList,
  tooltipContent,
}) {
  const { t } = useTranslation();
  const { promiseInProgress } = usePromiseTracker({ area: 'get-candidate-details', delay: 0 });
  const { classes } = useStyles();

  return (
    <>
      <Box className={classes.container}>
        {!showUpdatedSummaryIcons?.enabled && (
          <Box style={{ width: '100%' }}>
            <LinearProgressWithLabel
              value={skillChecklistProgress}
              filledItems={checklistItemCount}
              itemCount={skillChecklistData?.length}
            />
          </Box>
        )}

        <CandidateContainer
          title={`${t('candidate.virtualInterviewCard.skillsChecklist')} (all)`}
          icon={<img src={SkillsChecklistIcon} alt="SkillsChecklistIcon" />}
          rightIcon={true}
          enableLink={true}
          onClickAll={handleNavigation}
        >
          {promiseInProgress ? (
            <LayoutGridItem mr={2}>
              <Skeleton />
              <Skeleton />
            </LayoutGridItem>
          ) : (
            <>
              {!showUpdatedSummaryIcons?.enabled ? (
                skillChecklistData && skillChecklistData.length > 0 ? (
                  skillChecklistData.map(item => {
                    return (
                      <>
                        {item.matchingSkillsChecklist ? (
                          <CustomTooltip
                            style={{ color: theme.palette.framework.system.tertiaryGrey }}
                            tooltipContent={
                              <Typography gutterBottom component="span">
                                {getExpirationDateLabel(item?.matchingSkillsChecklist?.dateCompleted)}
                              </Typography>
                            }
                            tooltipPlacementBottom={classes.tooltipPlacementBottom}
                            placement="bottom-start"
                          >
                            <RowSection
                              header={generateHeader(item, verifiedSkillSets, allSkillSets)}
                              data={''}
                              preData={
                                validateChecked(item, verifiedSkillSets) ? validateChecked(item, verifiedSkillSets) : ''
                              }
                              showHalfwayProgress={validateIncomplete(item, verifiedSkillSets, allSkillSets)}
                              showAlert={validateAlert(item, verifiedSkillSets, allSkillSets)}
                              warningMessage={
                                item.disciplineID !== 0
                                  ? getWarningText(item?.matchingSkillsChecklist?.dateCompleted)?.toString()
                                  : getWarningText(item?.matchingSkillsChecklist?.dateCompleted)?.toString()
                              }
                              color={
                                checklistItemCount === skillChecklistData?.length
                                  ? theme.palette.system.darkGreenForIcon
                                  : theme.palette.framework.system.navyBlue
                              }
                            />
                          </CustomTooltip>
                        ) : (
                          <RowSection
                            header={generateHeader(item, verifiedSkillSets, allSkillSets)}
                            data={''}
                            preData={
                              validateChecked(item, verifiedSkillSets) ? validateChecked(item, verifiedSkillSets) : ''
                            }
                            showHalfwayProgress={validateIncomplete(item, verifiedSkillSets, allSkillSets)}
                            showAlert={validateAlert(item, verifiedSkillSets, allSkillSets)}
                            warningMessage={
                              item.disciplineID !== 0
                                ? getWarningText(item?.matchingSkillsChecklist?.dateCompleted)?.toString()
                                : getWarningText(item?.matchingSkillsChecklist?.dateCompleted)?.toString()
                            }
                            color={
                              checklistItemCount === skillChecklistData?.length
                                ? theme.palette.system.darkGreenForIcon
                                : theme.palette.framework.system.navyBlue
                            }
                          />
                        )}
                      </>
                    );
                  })
                ) : (
                  <RowSection
                    header={t('candidate.skillsChecklistCard.skillsUnavailable')}
                    data={''}
                    preData={''}
                    color={theme.palette.framework.system.navyBlue}
                    showHalfwayProgress={true}
                    showHalfwayProgressSecondaryIcon={true}
                    showAlert={false}
                  />
                )
              ) : combinedCheckList.length > 0 ? (
                combinedCheckList.map((item: ISkillCheckListDetails, index: number) => {
                  return (
                    <>
                      {
                        <CustomTooltip
                          interactive={true}
                          style={{ color: theme.palette.framework.system.tertiaryGrey }}
                          tooltipContent={tooltipContent(item)}
                          placement="bottom-start"
                        >
                          <RowSection
                            header={item?.fullName}
                            data={''}
                            preData={item?.expirationType === ISkillSetExpiration.NOT_EXPIRED}
                            showHalfwayProgress={!(item?.expirationType === ISkillSetExpiration.NOT_EXPIRED)}
                            showHalfwayProgressSecondaryIcon={true}
                            showAlert={false}
                            warningMessage={getWarningText(
                              item?.dateCompleted,
                              item?.matchingSkillsChecklist === null,
                            )?.toString()}
                            color={theme.palette.system.darkGreenForIcon}
                          />
                        </CustomTooltip>
                      }
                    </>
                  );
                })
              ) : (
                <RowSection
                  header={t('candidate.skillsChecklistCard.skillsUnavailable')}
                  data={''}
                  preData={''}
                  color={theme.palette.framework.system.navyBlue}
                  showHalfwayProgress={true}
                  showHalfwayProgressSecondaryIcon={true}
                  showAlert={false}
                />
              )}
            </>
          )}
        </CandidateContainer>
      </Box>
    </>
  );
}

export default SkillSetUIV2;
