import { Box, Button } from 'amn-ui-core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { FailedRequirementTitle } from '../styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  container: { display: 'flex', flexDirection: 'row' },
  link: {
    cursor: 'pointer',
    color: theme.palette.system.navyBlue,
    fontWeight: 450,
    padding: 0,
    minWidth: '24px',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
}));

export const PendingModsMessage: React.FC<{ message: string }> = ({ message }) => {
  const history = useHistory();
  const { classes } = useStyles();

  const handleClick = React.useCallback(
    () => history.push({ pathname: history.location.pathname, search: `?tab=3` }),
    [history],
  );
  const _message = React.useMemo(() => {
    const matches = message.match(/\d+/g);
    const count = matches.pop();
    const messagePart = message.replace(count, '');
    return (
      <Box classes={{ root: classes.container }}>
        <span>{messagePart}</span>
        <Button classes={{ root: classes.link }} type="button" variant="text" disableRipple onClick={handleClick}>
          {count}
        </Button>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClick, message]);

  return <FailedRequirementTitle>{_message}</FailedRequirementTitle>;
};
