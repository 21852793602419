import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { TextField } from 'amn-ui-core';
import { formInputs } from 'app/models/ClientContact/ClientContact';
import { useSelector } from 'react-redux';
import { selectClientContactDetails } from '../store/ClientContact.selector';

export const CCLastName = formMethods => {
  const { t } = useTranslation();

  const { formMethods: controls, disabled } = formMethods;
  const selectedClientContactDetails = useSelector(selectClientContactDetails);

  const {
    control,
    setError,
    clearErrors,
    setValue,
    formState: { isDirty, errors },
  } = controls;

  useEffect(() => {
    if (selectedClientContactDetails?.contactId)
      setValue('lastName', selectedClientContactDetails?.lastName, { shouldDirty: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientContactDetails?.contactId]);

  return (
    <Controller
      control={control}
      name={'lastName'}
      rules={{ required: t('required').toString(), maxLength: 50 }}
      render={({ ref, onChange, value, ...rest }) => (
        <TextField
          variant="filled"
          color="primary"
          id="lastName"
          label={t('clientContactModal.labels.lastName')}
          inputProps={{ maxLength: 50 }}
          InputLabelProps={{ shrink: !!value }}
          required={true}
          disabled={disabled}
          autoComplete="off"
          error={errors?.lastName ? true : false || (isDirty && errors?.lastName) ? true : false}
          value={value}
          {...rest}
          onChange={e => {
            if (e.target.value?.length > 50) {
              setError(formInputs.lastName, {
                type: 'maxLength',
                message: t('clientContactModal.messages.charMax50'),
              });
            } else if (e.target.value === '') {
              setError('lastName', {
                type: 'required',
                message: t('required').toString(),
              });
            } else if (errors?.lastName) {
              e.target.value?.length <= 50 && clearErrors(formInputs.lastName);
            }
            onChange(e);
          }}
          helperText={errors?.lastName?.message}
        />
      )}
    />
  );
};
