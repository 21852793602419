/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Paper, Tab, Tabs } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import { container } from 'styles/styleVariables';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectFacility } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import _ from 'lodash';
import { ContactsTab } from './ContactsTab';

const useStyles = makeStyles()(theme => ({
  tabContainer: {
    borderBottom: 'none !important',
    padding: '0px !important',
    width: '100%',
    borderRadius: `${container.borderRadius} ${container.borderRadius} 0px 0px !important`,
    '& .active-tab-profile': {
      width: 'auto',
    },
    backgroundColor: theme.palette.system.lightGray,
  },
  profileTab: {
    position: 'relative',
    marginTop: '-2%',
    '& .MuiTabScrollButton-root.Mui-disabled': {
      opacity: '0.6',
    },
  },
  profileTabMaster: {
    marginTop: '0.5rem',
  },
  tabRoot: {
    [theme.breakpoints.up(380)]: {
      minWidth: '105px !important',
    },
  },
  fullWidth: {
    width: '100% !important',
  },
  tabBackground: {
    backgroundColor: `${theme.palette.framework.system.white} !important`,
  }
}));

export enum IFacilitySidePanelTabs {
  CONTACTS,
}

export const HeaderContactDrawerContent = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const facilityData = useSelector(selectFacility);
  const facilityDrawerState = facilityData?.facilityDrawerState;
  const [tabSelectedValue, setTabSelectedValue] = useState<number>(
    facilityDrawerState?.tabId ? facilityDrawerState?.tabId : IFacilitySidePanelTabs.CONTACTS,
  );

  const TabPanel = ({ children, value, index, classes, ...other }) => {
    return (
      <div
        className="tab"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box className={`paper ${classes.tabContainer}`}>{children}</Box>}
      </div>
    );
  };

  const handleProfileDrawerTabChange = (event, newValue) => {
    if (newValue !== tabSelectedValue) {
      setTabSelectedValue(newValue);
    }
  };

  return (
    <div className={`${classes.profileTab} ${classes.profileTabMaster}  ${classes.fullWidth}`}>
      <Paper elevation={0} className={`paper ${classes.tabContainer} ${classes.tabBackground}`}>
        <Tabs
          value={tabSelectedValue}
          classes={{ indicator: 'tab-indicator profileTab' }}
          onChange={handleProfileDrawerTabChange}
          aria-label="full width tabs"
        >
          <Tab
            className={
              tabSelectedValue === IFacilitySidePanelTabs.CONTACTS ? 'active-tab-profile' : 'default-tab profileTab'
            }
            label={t('facility.contactsDrawer.contactsTab.label')}
            classes={{ root: classes.tabRoot }}
          />
        </Tabs>
      </Paper>
      <TabPanel componet={'div'} value={tabSelectedValue} index={0} classes={classes}>
        <ContactsTab />
      </TabPanel>
    </div>
  );
};
