import { convertToPST } from 'app/helpers/dateHelper';
import { ICueNoteResponse } from 'app/models/Tasks/Tasks';
import _ from 'lodash';
import moment from 'moment-timezone';

export const groupByDate = (logs?: ICueNoteResponse[]) => {
  const timeStampedMessages: any = [];

  const groupedData = _.chain(logs)
    .groupBy(datum =>
      determineDateRange(
        moment(moment.tz(datum?.sentDate, 'America/Los_Angeles').format('MM/DD/YYYY h:mm:ss A')).startOf('day'),
      ),
    )
    .value();
  const keys = Object.keys(groupedData);
  const values = Object.values(groupedData);
  keys.map((period, index) => timeStampedMessages.push({ period: period, chats: values[index] } as any));
  return timeStampedMessages;
};

export const getElapsedTime = value => {
  return duration(value, new Date());
};

export function duration(providedDate, now) {
  now = moment(convertToPST(now));
  providedDate = moment(convertToPST(providedDate));
  const years = moment(now).diff(providedDate, 'years');
  const months = moment(now).diff(providedDate, 'months');
  const weeks = moment(now).diff(providedDate, 'weeks');
  const days = moment(now).diff(providedDate, 'days');
  const hours = moment(now).diff(providedDate, 'hours');
  const minutes = moment(now).diff(providedDate, 'minutes');
  const seconds = moment(now).diff(providedDate, 'seconds');

  if (years > 0) {
    return years + 'y';
  }
  if (months > 0) {
    return months + 'mo';
  }
  if (weeks > 0) {
    return weeks + 'w';
  }
  if (days > 0) {
    return days + 'd';
  }
  if (hours > 0) {
    return hours + 'h';
  }
  if (minutes > 0) {
    return minutes + 'm';
  }
  if (seconds > 0) {
    return seconds + 's';
  }
  return '0s';
}

const determineDateRange = value => {
  const now = moment(convertToPST(new Date())).startOf('day');

  if (value.isSame(now, 'day')) {
    return 'Today';
  }
  if (value.isSame(now.clone().subtract(1, 'days').startOf('day'))) {
    return 'Yesterday';
  }
  return value.format('MM/DD/YYYY');
};
