import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { useWatch } from 'react-hook-form';
import { useEditableRequirement } from './EditRequirementsHelper';
import { SSNRequirementCheck } from './EditablePassFailLogic';

export const useEditableRequirementSSN = () => {
  // SSN
  const ssn: string | undefined = useWatch({ name: 'ssn' });
  const transportation: any = useWatch({ name: 'transportationChosen' });

  useEditableRequirement({
    data: ssn,
    secondaryData: transportation?.transportation,
    requirementId: IPlacementRequirementKey.ssn,
    requirementKey: 'ssn',
    requirementCheck: SSNRequirementCheck,
    shouldAddFailedRequirement: transportation?.transportation !== 'Unknown',
    failRequirement: {
      id: IPlacementRequirementKey.ssn,
      message: 'SSN: Missing',
      tooltip: {
        message: 'SSN is required',
      },
    },
  });
};
