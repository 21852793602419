import { takeLatest, put, call, select, getContext } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  getActiveRecruitersByLoggedInUserId,
  getRecruiterStaticCount,
} from 'app/services/CandidateServices/CandidateServices';
import { trackPromise } from 'react-promise-tracker';
import { IFilterSpecs, recruiterPlacementActions } from './RecruiterPlacements.redux';
import { selectUser } from 'oidc/user.selectors';
import { IPlacementFilter } from '../MyPlacementsDesk/WorkdeskFilter/filterUtils';
import {
  getRecruiterList,
  filterUserChoices,
  getFilteredData,
  getPipelineCounts,
  getTileCounts,
} from '../MyPlacementsDesk/WorkdeskFilter/filterHelper';
import { getMultiSelectOptions } from 'app/ComponentLibrary/Filter/helper';
import { recruiterPlacementFlattenData } from './WorkdeskGrid/helper';
import { gridStateActions } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import { selectFilterChipSelected } from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import { revalidateChipSelection } from 'app/components/GlobalSearch/helper';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { getMultiSelectDefaultOptions } from './utils';


const TrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'Recruiter-Placements-call');

export function* requestRecruiterPlacements(
  action: PayloadAction<{
    recruiterId: number[];
    filter?: {
      values: IPlacementFilter;
      setValue: (
        name: string,
        value: unknown,
        options?:
          | Partial<{
            shouldValidate: boolean;
            shouldDirty: boolean;
          }>
          | undefined,
      ) => void;
    };
    arbitrationAgreement: boolean;
  }>,
) {
  const placementService: PlacementService = yield getContext('placementService');
  try {
    const response = yield call(
      TrackWrapper,
      placementService.getActivePlacementsByRecruiterById,
      action.payload.recruiterId,
    );
    if (httpSuccess(response?.status)) {
      const { data } = response;
      const flattenedData = recruiterPlacementFlattenData(data.placements);
      const filterSpecs: IFilterSpecs = {
        candidateNameOpts: getMultiSelectOptions(data.filterSpecs.candidateNameOpts),
        disciplineOpts: getMultiSelectOptions(data.filterSpecs.disciplineOpts),
        specialtyOpts: getMultiSelectOptions(data.filterSpecs.specialtyOpts),
        facilityNameOpts: getMultiSelectOptions(data.filterSpecs.facilityNameOpts),
        cityOpts: getMultiSelectOptions(data.filterSpecs.cityOpts),
        stateOpts: getMultiSelectOptions(data.filterSpecs.stateOpts),
        arbitrationStatusOpts:
          action.payload.arbitrationAgreement ?
            getMultiSelectDefaultOptions(data.filterSpecs?.arbitrationStatusOpts, true) : [],
      };
      const counts = getPipelineCounts(flattenedData);
      yield put(recruiterPlacementActions.setCounts(counts));
      yield put(recruiterPlacementActions.setFlattenedData(flattenedData));
      yield put(recruiterPlacementActions.setDataLoaded(true));
      yield put(recruiterPlacementActions.setFilterSpecs(filterSpecs));

      //Tiles count will do internally from filter logic else it will do explicitly
      if (action.payload.filter) {
        const updatedFilter = filterUserChoices(
          filterSpecs,
          action.payload.filter.values,
          action.payload.filter.setValue,
        );
        const selectedChips = yield select(selectFilterChipSelected);
        const newSelectedChips = revalidateChipSelection(updatedFilter, selectedChips);
        const filteredData = getFilteredData(updatedFilter, flattenedData, newSelectedChips);
        yield put(
          gridStateActions.setFilterAttributes({
            filterObject: Object.entries(updatedFilter),
            selectedChipFilters: newSelectedChips,
          }),
        );
        yield put(recruiterPlacementActions.setFilteredData(filteredData));
      } else {
        const tilesCounts = getTileCounts(flattenedData);
        yield put(recruiterPlacementActions.setTileDynamicCounts(tilesCounts));
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestRecruiterPlacements',
        area: 'src/app/components/RecruiterWorkDesk/MyPlacementsDesk/RecruiterPlacements.saga.ts',
      },
    });
  }
}

export function* requestFalseRecruitersByUserId(action: PayloadAction<{ userId: number }>) {
  try {
    const response = yield call(TrackWrapper, getActiveRecruitersByLoggedInUserId, action.payload.userId, 'R');
    if (httpSuccess(response.status)) {
      const appUser = yield select(selectUser);
      const recruiters = getRecruiterList(response.data ?? [], appUser.userInfo);
      yield put(recruiterPlacementActions.setFalseRecruiters(recruiters));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestFalseRecruitersByUserId',
        area: 'src/app/components/RecruiterWorkDesk/MyPlacementsDesk/RecruiterPlacements.saga.ts',
      },
    });
  }
}

export function* requestRecStaticCounts(action: PayloadAction<{ userId: number }>) {
  try {
    const response = yield call(TrackWrapper, getRecruiterStaticCount, action.payload.userId);
    const counts = {
      nonContactedDate30: response.nonContacted30Days,
      nonContactedDate20: response.nonContacted20Days,
      callBackToday: response.callBackDateToday,
      callBackThisWeek: response.callBackDateWeek,
      lastApplicationToday: response.applicationDateToday,
      lastApplicationThisWeek: response.applicationDateWeek,
      availableToStartThisWeek: response.availableToStartWeek,
      availableToStartNextMonth: response.availableToStartNextMonth,
    };
    yield put(recruiterPlacementActions.setTileStaticCounts(counts));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestRecStaticCounts',
        area: 'src/app/components/RecruiterWorkDesk/MyPlacementsDesk/RecruiterPlacements.saga.ts',
      },
    });
  }
}

export function* recruiterPlacementSaga() {
  yield takeLatest(recruiterPlacementActions.getRecruiterPlacementAction.type, requestRecruiterPlacements);
  yield takeLatest(recruiterPlacementActions.getFalseRecruitersAction.type, requestFalseRecruitersByUserId);
  yield takeLatest(recruiterPlacementActions.getRecStaticCountAction.type, requestRecStaticCounts);
}
