import { Expandable } from '@AMIEWEB/Common';
import React, { useEffect, useState } from 'react';
import { StatusTitle } from '../../Titles/StatusTitle';
import { useTranslation } from 'react-i18next';
import { Grid } from 'amn-ui-core';
import { InterviewContainer } from './Interview/InterviewContainer';
import { VirtualInterviewContainer } from './VirtualInterview/VirtualInterviewContainer';
import { InterviewScheduledContainer } from './InterviewScheduled/InterviewScheduledContainer';
import { useDispatch, useSelector } from 'react-redux';
import { selectInterviewInfo } from 'store/redux-store/placement-summary/selectors';
import { useInterviewRequirementCounts } from '../RequirementCounts/InterviewRequirementCounts';
import { IRequirementCounts, RequirementFilterKey, requirementType } from 'app/models/Placement/PlacementSummary';
import {
  cleanChildFilterByKey,
  deriveFilter,
  useAccordionState,
  useRequirementFilter,
} from '../../Helpers/requirementFilter';
import { placementSummaryActions } from 'store/redux-store/placement-summary/slice';
import { RequirementsContainer } from '../RequirementsContainer';

const deriveCounts = (x: IRequirementCounts, y: IRequirementCounts, z: IRequirementCounts) => ({
  aggregate: x.aggregate + y.aggregate + z.aggregate,
  passed: x.passed + y.passed + z.passed,
  failed: x.failed + y.failed + z.failed,
  risks: x.risks + y.risks + z.risks,
});

export const InterviewStatus = () => {
  const { t } = useTranslation();
  const {
    virtualInterview: {
      reqCounts: virtualCounts,
      order: { type: viType },
    },
    interview: { reqCounts: interviewCounts },
    interviewScheduled: { reqCounts: scheduledCounts },
    requirementFilter,
  } = useSelector(selectInterviewInfo);
  const { filter: interviewFilter } = useRequirementFilter('interview', requirementFilter);
  const { filter: virtualFilter } = useRequirementFilter('interview-virtual', requirementFilter);

  const { expand: expandVIReq } = useAccordionState(virtualFilter, [viType]);

  useInterviewRequirementCounts();
  const dispatch = useDispatch();

  const [counts, setCounts] = useState(deriveCounts(virtualCounts, interviewCounts, scheduledCounts));

  useEffect(() => {
    setCounts(deriveCounts(virtualCounts, interviewCounts, scheduledCounts));
  }, [virtualCounts, interviewCounts, scheduledCounts]);

  const handleFilterChange = (key: RequirementFilterKey, state: requirementType[]) => {
    if (requirementFilter) {
      const filterTree = deriveFilter(key, requirementFilter, state);
      cleanChildFilterByKey('interview-virtual', filterTree, virtualCounts);
      dispatch(
        placementSummaryActions.setFilterTreeByKey({
          key: 'interviewInfo',
          filterTree,
        }),
      );
    }
  };

  return (
    <Expandable
      id="placement-interview-container"
      unmountOnExit={false}
      preventForceClose
      expanded={!!interviewFilter.length}
      titleComponent={
        <StatusTitle
          title={t('placement.profile.summary.interview.title')}
          counts={counts}
          filter={interviewFilter}
          handleFilterChange={state => handleFilterChange('interview', state)}
        />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RequirementsContainer
            title={t('placement.profile.summary.interview.virtualInterviewContainer.title')}
            counts={virtualCounts}
            unmountOnExit={false}
            expanded={expandVIReq}
            filter={virtualFilter}
            handleFilterChange={state => handleFilterChange('interview-virtual', state)}
          >
            <VirtualInterviewContainer />
          </RequirementsContainer>
        </Grid>
        <Grid item xs={12}>
          <RequirementsContainer
            title={t('placement.profile.summary.interview.interviewContainer.title')}
            counts={interviewCounts}
            unmountOnExit={false}
            handleFilterChange={state => {}}
          >
            <InterviewContainer />
          </RequirementsContainer>
        </Grid>
        <Grid item xs={12}>
          <RequirementsContainer
            title={t('placement.profile.summary.interview.interviewScheduledContainer.title')}
            counts={scheduledCounts}
            unmountOnExit={false}
            handleFilterChange={state => {}}
          >
            <InterviewScheduledContainer />
          </RequirementsContainer>
        </Grid>
      </Grid>
    </Expandable>
  );
};
