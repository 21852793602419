import { Requirements } from 'app/models/Placement/PlacementRequirements';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectInterviewInfo } from 'store/redux-store/placement-summary/selectors';
import { placementSummaryActions } from 'store/redux-store/placement-summary/slice';

export const useVirtualInterviewRequirementCounts = () => {
  const {
    virtualInterview: {
      order: { type: virtualInterviewReq },
    },
  } = useSelector(selectInterviewInfo);

  return React.useMemo(() => {
    const counts = new Requirements();
    counts.add(virtualInterviewReq);
    return counts;
  }, [virtualInterviewReq]);
};
// export const useInterviewScheduledRequirementCounts = () => {};
// export const useInterviewReqCounts = () => {};

export const useInterviewRequirementCounts = () => {
  const dispatch = useDispatch();
  const virtualInterviewReqCounts = useVirtualInterviewRequirementCounts();

  React.useEffect(() => {
    dispatch(
      placementSummaryActions.setRequirementCounts({
        virtualInterviewReqCounts,
      }),
    );
  }, [virtualInterviewReqCounts]);

  return {
    virtualInterviewCounts: virtualInterviewReqCounts,
  };
};
