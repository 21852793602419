import { Divider, Tab, Tabs } from 'amn-ui-core';
import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { AuditAddContent } from './AuditAddContent';
import { AuditHeaderSection } from './AuditHeaderSection';
import { AuditPlacementGrid } from './AuditPlacementGrid';
import { TabValues } from './Helpers/AuditAddHelpers';
import { selectAssociatedPlacements } from '@AMIEWEB/Placement/AssociatedPlacements/store/associatedPlacements.selectors';
import { useSelector } from 'react-redux';

const useStyles = makeStyles()(theme => ({
  tab: {
    padding: '20px 12px 12px 12px',
    marginLeft: '10px',
    textTransform: 'none',
    alignItems: 'flex-start !important',
    color: theme.palette.system.darkGray,
    justifyContent: 'flex-start',
    '& .MuiTab-wrapper': {
      alignItems: 'flex-start !important',
      justifyContent: 'flex-start',
    },
    '&.Mui-selected': {
      color: theme.palette.system.violet,
    },
  },
  indicator: {
    width: '5px',
    height: '3px',
    left: '0px',
    background: theme.palette.system.violet,
  },
  tabsRoot: {
    paddingLeft: '10px',
  },
  contentContainer: {
    maxHeight: 'calc(70vh - 10.5rem)',
    width: '100%',
    overflowY: 'scroll',
  },
  contentContainerExpanded: {
    maxHeight: 'calc(70vh - 6.5rem)',
    width: '100%',
    overflowY: 'scroll',
  },
}));

export const tabs = [
  {
    index: TabValues.audit,
    id: 'auditDetails',
    label: 'Audit Details',
  },
  {
    index: TabValues.placement,
    id: 'placements',
    label: 'Placements',
  },
];

interface AuditAddWrapperProps {
  setFormValues?: (value: any) => void;
  existingFormValues?: any;
  isExpanded?: boolean;
  addMode?: boolean;
  disabled?: boolean;
  getPlacementsFunction?: () => void;
}

export const AuditAddWrapper = (props: AuditAddWrapperProps) => {
  const { setFormValues, existingFormValues, isExpanded, addMode, disabled, getPlacementsFunction } = props;
  const { items: rows, count } = useSelector(selectAssociatedPlacements);
  const { classes } = useStyles();
  const [selectedTab, setSelectedTab] = useState<number>(TabValues.audit);

  useEffect(() => {
    getPlacementsFunction();
  }, []);

  return (
    <>
      <AuditHeaderSection existingFormValues={existingFormValues} />
      <Tabs
        value={selectedTab}
        classes={{
          root: classes.tabsRoot,
          indicator: classes.indicator,
        }}
        textColor="primary"
        orientation="horizontal"
        onChange={(event, newValue) => setSelectedTab(newValue)}
        aria-label="edit product selector"
      >
        {tabs.map((tab, index) => (
          <Tab
            label={
              tab.index === TabValues.placement
                ? existingFormValues?.isPlacementRequired
                  ? `${tab.label} (${count}) *`
                  : `${tab.label} (${count})`
                : tab.label
            }
            className={classes.tab}
            id={tab.id}
            key={index}
          />
        ))}
      </Tabs>
      <Divider />
      {selectedTab === TabValues.audit ? (
        <div className={isExpanded ? classes.contentContainerExpanded : classes.contentContainer}>
          <AuditAddContent
            setFormValues={setFormValues}
            existingFormValues={existingFormValues}
            addMode={addMode}
            disabled={!addMode && disabled}
          />
        </div>
      ) : (
        <div className={isExpanded ? classes.contentContainerExpanded : classes.contentContainer}>
          <AuditPlacementGrid
            setFormValues={setFormValues}
            existingFormValues={existingFormValues}
            isExpanded={isExpanded}
            disabled={!addMode && disabled}
          />
        </div>
      )}
    </>
  );
};
