import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { FormInputProps } from './FormInputProps';

export const FormInputText = ({
  name,
  label,
  require,
  message,
  handleChange,
  inputValue,
  readOnly,
  customClass,
  type,
  isCheckBox,
  disableInputRef,
  multiline,
  helperText,
  formHelperTextProps,
  customInputProps,
}: FormInputProps) => {
  const { control, register, errors } = useFormContext();
  const [labelShrink, setLabelShrink] = useState(false);

  useEffect(() => {
    setLabelShrink(isCheckBox ? true : inputValue ? true : false);
  }, [inputValue, isCheckBox]);

  const TextBox = useMemo(() => {
    return (
      <>
        <Controller
          name={name}
          control={control}
          rules={{
            required: require,
          }}
          render={({ ref, value, onChange, ...rest }) => (
            <TextField
              multiline={multiline}
              autoComplete="off"
              id={`txt${name}`}
              variant="filled"
              type={type || 'text'}
              label={label}
              defaultValue={inputValue}
              value={value || inputValue}
              onChange={e => {
                handleChange?.(e);
                if (e.target.value === '') setLabelShrink(false);
                else setLabelShrink(true);
              }}
              InputLabelProps={{ shrink: labelShrink }}
              className={customClass ?? ''}
              inputRef={!disableInputRef ? register : undefined}
              fullWidth
              {...rest}
              InputProps={{
                readOnly: readOnly,
              }}
              inputProps={customInputProps ?? { maxLength: 500 }}
              error={!!errors[name]}
              helperText={
                errors[name] ? (errors[name]?.type === 'required' ? 'Required' : message) : helperText ? helperText : ''
              }
              disabled={readOnly}
              FormHelperTextProps={{
                className: formHelperTextProps,
              }}
            />
          )}
        />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    control,
    customClass,
    disableInputRef,
    errors,
    handleChange,
    helperText,
    inputValue,
    label,
    labelShrink,
    message,
    multiline,
    name,
    readOnly,
    register,
    require,
    type,
  ]);

  return <>{TextBox}</>;
};
