import { InputAdornment, TextField, Typography } from 'amn-ui-core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from '../../../ComponentLibrary/DateRangeNew';
import { makeStyles } from 'tss-react/mui';
import { addDays, format, isSaturday, isSunday, isWeekend } from 'date-fns';
import { FormatDate } from '@AMIEWEB/Notification/Tasks/Common/FormatData';
import { IDueDateView, TaskStatus, TaskStatusReason } from 'app/models/Tasks/Tasks';
import { useDispatch, useSelector } from 'react-redux';
import { taskDetailsActions } from '../store/Tasks.redux';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { selectDueDateFirstView, selectInternalTaskDetailsData } from '../store/Tasks.selectors';
import { editTaskMetaData, getComments, getTaskDetails } from 'app/services/TaskServices/TaskServices';
import { httpSuccess } from 'app/services/serviceHelpers';
import { formatCommentToTask } from '../SagaTransformers/formatInternalTaskData';
import moment from 'moment';
import { selectActiveCoverage } from 'store/redux-store/user-coverage/selectors';
import { checkUserStatus, checkValidUser } from './helper';
import { selectUser } from 'oidc/user.selectors';
import { formatCommentData, formatTaskDetailUpdateData } from '../TaskSidebar/Transformers';
import { formatSubtaskUpdateDetails, formatTaskUpdateDetails } from '../helper';
import _isEqual from 'lodash/isEqual';
import { getEmployeeId } from '@AMIEWEB/Notification/Tasks/Common_v2/utils';

const useStyles = makeStyles<{ isPastDueDate: boolean }>()((theme, props) => ({
  dueDateInput: {
    '& .MuiOutlinedInput-input': {
      fontSize: '12px',
    },
  },
  dateField: {
    width: '138px',
    '& .MuiOutlinedInput-root': {
      paddingRight: '4px',
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: props.isPastDueDate ? theme.palette.system.errorRed : theme.palette.primary.main,
        borderWidth: '2px',
      },
    },
  },
  disabledDateField: {
    width: '138px',
    '& .MuiOutlinedInput-root': {
      paddingRight: '4px',
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.framework.system.lightGray,
        borderWidth: '2px',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.framework.system.lightGray,
      },
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.system.neutralGray,
      '&.Mui-focused': {
        color: theme.palette.system.neutralGray,
      },
    },
  },

  adornment: {
    backgroundColor: theme.palette.system.paleBlue,
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
  disabledAdornment: {
    backgroundColor: theme.palette.framework.system.whisper,
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    pointerEvents: 'none',
  },

  adornmentText: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    fontWeight: '500',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: 'inherit',
  },

  disabledadornmentText: {
    color: theme.palette.primary.darkGray,
    fontSize: '12px',
    fontWeight: '500',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: 'inherit',
  },
}));

export const TaskDetailsDateRenderer = ({
  details,
  isSubtask,
  userStatusId = 1,
  setIsPopperOpen,
  coverageUser,
}: {
  details: any;
  isSubtask: boolean;
  userStatusId: TaskStatus;
  setIsPopperOpen?: (e: boolean) => void;
  coverageUser: any;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUser = useSelector(selectUser);
  const activeCoverage = useSelector(selectActiveCoverage);
  const parentTaskDetails = useSelector(selectInternalTaskDetailsData);
  const taskDueDateFirstView = useSelector(selectDueDateFirstView);
  const [isDueDateFirstView, setDuedateFirstView] = useState<boolean>(true);
  const [dueDateValue, setDueDateValue] = useState<any>('');
  const [isPastDueDate, setIsPastDueDate] = useState<boolean>(false);
  const { classes } = useStyles({ isPastDueDate });
  const [isHovered, setHovered] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const date = new Date();

  useEffect(() => {
    const presentDueDateDetails = taskDueDateFirstView?.find(task => task?.taskId === details?.taskId);
    setDuedateFirstView(presentDueDateDetails?.isFirstView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskDueDateFirstView]);

  useEffect(() => {
    if (details) {
      const extractedDate = format(new Date(details?.dueDate), 'MM/dd/yyyy');
      setDueDateValue(extractedDate);
    }
    moment().isAfter(moment(details?.dueDate).endOf('day')) &&
    (userStatusId === TaskStatus.Pending ||
      userStatusId === TaskStatus.New ||
      checkUserStatus(details, isSubtask, activeCoverage, TaskStatus.New) ||
      checkUserStatus(details, isSubtask, activeCoverage, TaskStatus.Pending))
      ? setIsPastDueDate(true)
      : setIsPastDueDate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details, userStatusId]);

  const addBusinessDay = (selectedDate: Date) => {
    let newDate = addDays(selectedDate, 1);

    // Check if the new date is a weekend (Saturday or Sunday)
    while (isWeekend(newDate) || isSaturday(newDate) || isSunday(newDate)) {
      newDate = addDays(newDate, 1);
    }
    const formattedDate = new Date(newDate?.setHours(18));
    return formattedDate;
  };

  const failureMessage = () => {
    dispatch(taskDetailsActions.setIsUpdatingTaskFlag(false));
    dispatch(
      globalActions.setSnackBar({
        message: t('notification.createTask.taskEditFailure'),
        severity: 'error',
      }),
    );
  };

  const successMessage = () => {
    dispatch(taskDetailsActions.setIsUpdatingTaskFlag(false));
    dispatch(
      globalActions.setSnackBar({
        message: t('notification.createTask.taskEditSuccess'),
        severity: 'success',
      }),
    );
    dispatch(taskDetailsActions.setTaskUpdatedId(null));
  };

  const updateTask = async (UpdatedDateValue: Date) => {
    if (!isSubtask) {
      const { updatedTaskDetails, initialState } = formatTaskUpdateDetails(
        UpdatedDateValue,
        details,
        activeUser.userInfo,
      );
      const taskResponse = await getTaskDetails(updatedTaskDetails?.taskId);
      if (taskResponse && httpSuccess(taskResponse?.status)) {
        const { latestData, checkInitialTask } = formatTaskDetailUpdateData(taskResponse?.data, initialState);
        if (checkInitialTask) {
          dispatch(taskDetailsActions.setIsUpdatingTaskFlag(true));
          dispatch(taskDetailsActions.setInternalTaskChangedFlag(undefined));
          const UpdatedResponse = await editTaskMetaData(updatedTaskDetails);
          if (UpdatedResponse && httpSuccess(UpdatedResponse?.status)) {
            const updatedDuedateView: IDueDateView = {
              isFirstView: false,
              taskId: details?.taskId,
            };
            const TaskDueDateViewDetails = [...taskDueDateFirstView];
            const taskIndex = TaskDueDateViewDetails?.findIndex(data => data?.taskId === details?.taskId);
            TaskDueDateViewDetails[taskIndex] = updatedDuedateView;
            dispatch(taskDetailsActions.setDuedateFirstView(TaskDueDateViewDetails));
            dispatch(taskDetailsActions.getTaskAction(UpdatedResponse?.data));
            dispatch(taskDetailsActions.setTaskUpdatedId(UpdatedResponse?.data));
            successMessage();
          } else {
            failureMessage();
          }
        } else {
          dispatch(taskDetailsActions.setInternalTaskChangedFlag(latestData));
          dispatch(
            globalActions.setSnackBar({
              message: t('common.failedLoad'),
              severity: 'error',
            }),
          );
        }
      } else {
        dispatch(
          globalActions.setSnackBar({
            message: t('common.failedLoad'),
            severity: 'error',
          }),
        );
      }
    } else {
      const updatedSubTask = formatSubtaskUpdateDetails(
        details,
        parentTaskDetails,
        UpdatedDateValue,
        activeUser.userInfo,
      );
      const commentsCall = await getComments(updatedSubTask?.rootTaskId);
      const comments = commentsCall.data;
      if (comments) {
        const { availableComments, availableSubtask } = formatCommentData(comments, updatedSubTask, activeUser);
        if (_isEqual(details, availableSubtask)) {
          const updatedSubTaskDetails = formatCommentToTask(updatedSubTask);
          dispatch(taskDetailsActions.setIsUpdatingTaskFlag(true));
          const UpdatedResponse = await editTaskMetaData(updatedSubTaskDetails);
          if (UpdatedResponse && httpSuccess(UpdatedResponse?.status)) {
            const updatedDuedateView: IDueDateView = {
              isFirstView: false,
              taskId: details?.taskId,
            };
            const TaskDueDateViewDetails = [...taskDueDateFirstView];
            const taskIndex = TaskDueDateViewDetails?.findIndex(data => data?.taskId === details?.taskId);
            TaskDueDateViewDetails[taskIndex] = updatedDuedateView;
            dispatch(taskDetailsActions.setDuedateFirstView(TaskDueDateViewDetails));
            dispatch(taskDetailsActions.getTaskAction(parentTaskDetails?.taskId));
            dispatch(taskDetailsActions.setTaskUpdatedId(UpdatedResponse?.data));
            successMessage();
          } else {
            failureMessage();
          }
        } else {
          dispatch(taskDetailsActions.setTaskComments(availableComments));
          dispatch(
            globalActions.setSnackBar({
              message: t('common.failedLoad'),
              severity: 'error',
            }),
          );
        }
      } else {
        dispatch(
          globalActions.setSnackBar({
            message: t('common.failedLoad'),
            severity: 'error',
          }),
        );
      }
    }
  };

  const incrementDate = () => {
    const updatedValue = addBusinessDay(new Date(details?.dueDate));
    updateTask(updatedValue);
  };

  const isEditable = (status: TaskStatus) => {
    return status !== TaskStatus.Cancelled;
  };

  const isDisabledCondition =
    userStatusId === TaskStatus.Completed || userStatusId === TaskStatus.Cancelled || coverageUser || isDisabled;

  const adornmentClassName = isDisabledCondition ? classes.disabledAdornment : classes.adornment;

  const adornmentTextClassName = isDisabledCondition ? classes.disabledadornmentText : classes.adornmentText;

  const duedateField = isDisabledCondition ? classes.disabledDateField : classes.dateField;

  useEffect(() => {
    if (details?.statusUpdateReasonId === TaskStatusReason.ERROR) {
      setIsDisabled(!isEditable(TaskStatus.Cancelled));
    }
  }, [details?.currentUserStatusDetails, details?.statusUpdateReasonId]);

  return (
    <div
      style={{
        pointerEvents: !checkValidUser(
          details,
          isSubtask,
          activeCoverage,
          parseInt(getEmployeeId(activeUser?.userInfo)),
        )
          ? 'none'
          : 'auto',
      }}
    >
      {isDueDateFirstView ? (
        <TextField
          variant="outlined"
          size="small"
          error={isPastDueDate}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          className={duedateField}
          value={dueDateValue}
          disabled={isDisabled}
          label={t('notification.createTask.dueDateFieldLabel')}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {isHovered && (
                  <div
                    className={adornmentClassName}
                    onClick={() => {
                      incrementDate();
                    }}
                  >
                    <Typography className={adornmentTextClassName}>{t('notification.createTask.plusOne')}</Typography>
                  </div>
                )}
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <DateRangePicker
          variant="outlined"
          placeholder={t('notification.createTask.dueDateFieldLabel')}
          secondaryLabel={t('notification.createTask.dueDateFieldLabel')}
          className={classes.dueDateInput}
          useMaxWidth={true}
          single={true}
          value={dueDateValue}
          initialDateRange={{
            startDate: dueDateValue ? new Date(dueDateValue) : undefined,
            endDate: dueDateValue ? new Date(dueDateValue) : undefined,
            useLabelAsValue: true,
          }}
          minDate={addDays(date, -1)}
          maxWidth={136}
          isDisabled={isDisabled}
          disableEdit={!isDisabledCondition}
          readOnly={isDisabledCondition}
          helperTextDisabled={true}
          actionBar={false}
          clickAway={true}
          keepClickAwayVal={true}
          trailingIconShow
          popperPlacement={'bottom-end'}
          fallbackPlacements={['top-end']}
          onChange={e => {
            const dueDate = e?.endDate ? FormatDate(e?.endDate?.setHours(18)) : '';
            setDueDateValue(dueDate);
            e?.endDate && updateTask(new Date(e?.endDate?.setHours(18)));
          }}
          onOpen={setIsPopperOpen}
          error={isPastDueDate}
          definedRanges={[
            {
              label: t('notification.createTask.dueDateLabels.today'),
              startDate: date,
              endDate: date,
              useLabelAsValue: false,
            },
            {
              label: t('notification.createTask.dueDateLabels.tomorrow'),
              startDate: addDays(date, 1),
              endDate: addDays(date, 1),
              useLabelAsValue: false,
            },
            {
              label: t('notification.createTask.dueDateLabels.2Days'),
              startDate: addDays(date, 2),
              endDate: addDays(date, 2),
              useLabelAsValue: false,
            },
            {
              label: t('notification.createTask.dueDateLabels.7Days'),
              startDate: addDays(date, 7),
              endDate: addDays(date, 7),
              useLabelAsValue: false,
            },
            {
              label: t('notification.createTask.dueDateLabels.30Days'),
              startDate: addDays(date, 30),
              endDate: addDays(date, 30),
              useLabelAsValue: false,
            },
          ]}
        />
      )}
    </div>
  );
};
