import { IExternalTaskSubType, IFormatedExternalTaskData, IUser } from 'app/models/Tasks/Tasks';
import moment from 'moment-timezone';
import { checkIsLatestCCChanged } from '../CreateTask/FormatData';

export const formatUpdateExternalTaskStatus = (updateStatus: any, detailsBody: any, userInfo: any) => {
  const user = userInfo?.userInfo;
  updateStatus.candidateId = detailsBody?.candidateId;
  updateStatus.placementId = detailsBody?.placementId;
  updateStatus.statusChangeById = user?.employeeId;
  updateStatus.statusChangedByFirstName = user?.firstName;
  updateStatus.statusChangedByLastName = user?.lastName;
  updateStatus.taskNameId = detailsBody?.taskNameId;
  updateStatus.taskCategoryId = detailsBody?.taskCategoryId;
  updateStatus.statusChangeDate = moment.tz(new Date(), 'America/Los_Angeles').format();
};
export const formatEditExternalTask = (latestData: IFormatedExternalTaskData, initialTask) => {
  return (
    latestData?.task === initialTask?.task &&
    moment(latestData.dueDate)?.format('MM/DD/YYY') === moment(initialTask?.dueDate)?.format('MM/DD/YYY') &&
    latestData?.description === initialTask?.description &&
    latestData?.assignedTo?.id === initialTask?.assignedTo &&
    checkIsLatestCCChanged(latestData.ccValues, initialTask)
  );
};

export const formatExternalTaskDetails = (task: any, taskCategory: any) => {
  const assignedto = new IUser(task.assignedToCandidateFirstName, task.assignedToCandidateLastName, task.candidateId);
  return {
    rootTaskId: task.rootTaskId,
    placementId: task.placementId,
    taskId: task.id,
    assignedTo: assignedto,
    createdBy: new IUser(task.createdByFirstName, task.createdByLastName, task.createdById),
    candidate: new IUser(task.assignedToCandidateFirstName, task.assignedToCandidateLastName, task.candidateId),
    candidateId: task.candidateId,
    task: task.taskName,
    taskName: task.taskName,
    taskNameId: task.taskNameId,
    companyId: task.companyId,
    companyBrandName: task.companyBrandName,
    orderId: task.orderId,
    facilityId: task.facilityId,
    facilityName: task.facilityName,
    description: task.description,
    dueDate: task.dueDate,
    taskCategory: taskCategory,
    taskCategoryId: task.taskCategoryId,
    statusId: task.statusId,
    createdDate: task.sentDate,
    cc: task.cc?.map(t => new IUser(t.ccFirstName, t.ccLastName, t.ccId)),
    statusUpdateReason: task?.statusUpdateReason,
    statusUpdatedByName: task?.statusUpdatedByName,
    statusUpdatedDate: task?.statusChangeDate,
    statusUpdatedById: task?.statusUpdatedById,
    statusUpdateReasonId: task?.statusUpdateReasonId,
    role: task?.role,
    placementStatus: task?.placementStatus,
    placementStatusId: task?.placementStatusId,
    taskSource: task?.taskSource,
    attachments: task?.attachments,
    isAMIEWebRequest: task?.isAMIEWebRequest,
    subCategory1:task?.categorySubType1,
    subCategory2:task?.categorySubType2
  };
};

export const formatExternalSubCategory = response => {
  const subCategory1: IExternalTaskSubType[] = [];
  const subCategory2: IExternalTaskSubType[] = [];
  response.subName1.map(sub1 => {
    const data = {
      id: sub1.id,
      label: sub1.name,
      subTypeId: sub1.subTypeId
    };
    subCategory1.push(data);
  });
  response.subName2.map(sub2 => {
    const data = {
      id: sub2.id,
      label: sub2.name,
      subTypeId: sub2.subTypeId
    };
    subCategory2.push(data);
  });
  return { subCategory1, subCategory2 };
};
