import ResponsiveChipCell from '@AMIEWEB/Common/XGrid/cells/ResponsiveGridChips/ResponsiveChipCell';
import { GridColDef, GridColumns } from '@mui/x-data-grid-pro';
import { XGridContactHoverView } from 'app/components/Common/XGrid/cells/HoverViewRenderers/XGridContactHoverView';
import { XGridBlueCell } from 'app/components/Common/XGrid/cells/XGridBlueCell';
import { XGridDefaultColumn } from 'app/components/Common/XGrid/cells/XGridDefaults';
import { XGridLinkCell } from 'app/components/Common/XGrid/cells/XGridLinkCell';
import { XGridOverflowCell } from 'app/components/Common/XGrid/cells/XGridOverflowCell';
import { XGridSideBarViewCell } from 'app/components/Common/XGrid/cells/XGridSideBarViewCell';
import { PlacementSearchCueText } from 'app/components/Tasks/CueNotes/Helpers/PlacementSearchCueText';
import React from 'react';
import { CandidateGridCustomTooltip } from '../Candidate/SearchUtils';
import { Submission } from './SubmissionError';
import { XGridSubmissionHoverView } from '@AMIEWEB/Common/XGrid/cells/HoverViewRenderers/XGridSubmissionHoverView';
import { ChannelType } from '@AMIEWEB/Notification/Constants';

const PlacementDefaultCell = {
  ...XGridDefaultColumn,
  flex: 0,
};

export const placementViewInfo = (t, exclude = [] as string[], candidateCommunicationConsentFlag?): GridColDef[] =>
  (
    [
      {
        ...PlacementDefaultCell,
        field: 'orderId',
        headerName: t('search.globalSearch.placement.gridTags.orderId'),
        width: 75,
        renderCell: XGridOverflowCell(XGridLinkCell('/order/{0}', ['orderId'], true))(
          XGridLinkCell('/order/{0}', ['orderId']),
        ),
      },
      {
        ...PlacementDefaultCell,
        field: 'placementId',
        headerName: t('search.globalSearch.placement.gridTags.placementId'),
        width: 75,
        renderCell: XGridOverflowCell(XGridLinkCell('/placement/{0}', ['placementId'], true))(
          XGridLinkCell('/placement/{0}', ['placementId']),
        ),
      },
      {
        ...PlacementDefaultCell,
        field: 'PlacementTag',
        headerName: t('search.globalSearch.placement.gridTags.tags'),
        width: 300,
        renderCell: ResponsiveChipCell,
        valueFormatter: params => params.value?.map(x => x.title).join(', '),
      },
      {
        ...PlacementDefaultCell,
        field: 'status',
        headerName: t('search.globalSearch.placement.gridTags.status'),
        width: 150,
      },
      {
        ...PlacementDefaultCell,
        field: 'statusAge',
        headerName: t('search.globalSearch.placement.gridTags.statusAge'),
        width: 130,
      },
      {
        ...PlacementDefaultCell,
        field: 'brand',
        headerName: 'Brand',
      },
      {
        ...PlacementDefaultCell,
        field: 'division',
        headerName: 'Division',
        width: 90,
      },
      {
        ...PlacementDefaultCell,
        field: 'candidateId',
        headerName: 'CID',
        width: 200,
        renderCell: XGridOverflowCell(XGridSideBarViewCell('/candidate/{0}/{1}', ['candidateId', 'brandId'], true))(
          XGridSideBarViewCell('/candidate/{0}/{1}', ['candidateId', 'brandId']),
        ),
      },
      {
        ...PlacementDefaultCell,
        field: 'candidate',
        headerName: t('search.globalSearch.placement.gridTags.candidate'),
        width: 120,
        renderCell: XGridOverflowCell(XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId'], true))(
          XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId']),
        ),
      },
      {
        ...PlacementDefaultCell,
        field: 'firstName',
        headerName: 'First Name',
        width: 150,
        renderCell: XGridOverflowCell(XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId'], true))(
          XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId']),
        ),
      },
      {
        ...PlacementDefaultCell,
        field: 'lastName',
        headerName: 'Last Name',
        width: 150,
        renderCell: XGridOverflowCell(XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId'], true))(
          XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId']),
        ),
      },
      {
        ...PlacementDefaultCell,
        field: 'goesBy',
        headerName: 'Goes By',
        width: 160,
      },
      {
        ...PlacementDefaultCell,
        field: 'otherName',
        headerName: 'Other Names',
        width: 160,
      },
      {
        ...PlacementDefaultCell,
        field: 'phones',
        headerName: 'Phone',
        sortable: false,
        width: 160,
        valueFormatter: param =>
          param.value.length === 0
            ? '--'
            : param.value
                .filter(x => x.phoneNumber !== null && x.phoneNumber !== undefined && x.phoneNumber.length > 0)
                .map(obj => `${obj.phoneTypeDescription}: ${obj.phoneNumber}`)
                .join(', '),
        renderCell: params => (
          <div style={{ display: 'flex', gap: '8px' }}>
            <CandidateGridCustomTooltip
              value={params.value}
              type={ChannelType.voice}
              candidate={params.row}
              communicationConsentFlag={candidateCommunicationConsentFlag}
            />
            <CandidateGridCustomTooltip
              value={params.value}
              type={ChannelType.sms}
              candidate={params.row}
              communicationConsentFlag={candidateCommunicationConsentFlag}
            />
          </div>
        ),
      },
      {
        ...PlacementDefaultCell,
        field: 'emails',
        headerName: 'Email',
        sortable: false,
        width: 100,
        valueFormatter: param =>
          param.value.primaryEmail || param.value.secondaryEmail
            ? Object.values(param.value)
                .filter(value => value)
                .join(', ')
            : '--',
        renderCell: params => (
          <CandidateGridCustomTooltip
            value={params.value}
            type={ChannelType.email}
            candidate={params.row}
            communicationConsentFlag={candidateCommunicationConsentFlag}
          />
        ),
      },

      {
        ...PlacementDefaultCell,
        field: 'facility',
        headerName: t('search.globalSearch.placement.gridTags.facility'),
        width: 150,
        renderCell: XGridOverflowCell(XGridLinkCell('/facility/{0}', ['facilityId'], true))(
          XGridLinkCell('/facility/{0}', ['facilityId']),
        ),
      },
      {
        ...PlacementDefaultCell,
        field: 'unit',
        headerName: t('search.globalSearch.placement.gridTags.unit'),
        width: 150,
      },
      {
        ...PlacementDefaultCell,
        field: 'skillsets',
        headerName: t('search.globalSearch.placement.gridTags.skillSet'),
        width: 90,
      },
      {
        ...PlacementDefaultCell,
        field: 'placementCreated',
        headerName: 'Placement Created',
        width: 150,
      },
      {
        ...PlacementDefaultCell,
        field: 'screen',
        headerName: 'Screen',
        width: 150,
      },
      {
        ...PlacementDefaultCell,
        field: 'fileSentDate',
        headerName: 'File Sent',
        width: 95,
      },
      {
        ...PlacementDefaultCell,
        field: 'submission',
        headerName: t('search.globalSearch.placement.gridTags.submission'),
        width: 200,
        description: t('search.globalSearch.placement.descriptions.submission'),
        renderCell: XGridOverflowCell(XGridSubmissionHoverView('submission'))(Submission),
      },
      {
        ...PlacementDefaultCell,
        field: 'availableStartDate',
        headerName: t('search.globalSearch.placement.gridTags.availableStartDate'),
        width: 160,
      },
      {
        ...PlacementDefaultCell,
        field: 'startDate',
        headerName: t('search.globalSearch.placement.gridTags.startDate'),
        width: 100,
      },
      {
        ...PlacementDefaultCell,
        field: 'endDate',
        headerName: t('search.globalSearch.placement.gridTags.endDate'),
        width: 100,
      },
      {
        ...PlacementDefaultCell,
        field: 'extensionStatus',
        headerName: t('search.globalSearch.placement.gridTags.extStatus'),
        width: 100,
      },
      {
        ...PlacementDefaultCell,
        field: 'extensionStatusLastUpdateDate',
        headerName: t('search.globalSearch.placement.gridTags.extStatusAge'),
        width: 125,
      },
      {
        ...PlacementDefaultCell,
        field: 'accountManager',
        headerName: t('search.globalSearch.placement.gridTags.accountManager'),
        width: 145,
        renderCell: XGridOverflowCell(XGridContactHoverView('accountManagerAttributes'), true)(XGridBlueCell),
      },
      {
        ...PlacementDefaultCell,
        field: 'recruiter',
        headerName: t('search.globalSearch.placement.gridTags.recruiter'),
        width: 100,
        renderCell: XGridOverflowCell(XGridContactHoverView('recruiterAttributes'), true)(XGridBlueCell),
      },
      {
        ...PlacementDefaultCell,
        field: 'credentialingAnalyst',
        headerName: t('search.globalSearch.placement.gridTags.credAnalyst'),
        renderCell: XGridOverflowCell(XGridContactHoverView('credentialingAnalystAttributes'), true)(XGridBlueCell),
        width: 165,
      },
      {
        ...PlacementDefaultCell,
        field: 'placementCuedTo',
        headerName: t('search.globalSearch.placement.gridTags.placementCuedTo'),
        width: 165,
      },
      {
        ...PlacementDefaultCell,
        field: 'cueNote',
        headerName: 'Cue Note',
        width: 170,
        renderCell: PlacementSearchCueText,
      },
      {
        ...PlacementDefaultCell,
        field: 'contractAffiliate',
        headerName: 'Contract Affiliate',
        width: 170,
      },
      {
        ...PlacementDefaultCell,
        field: 'contractAffiliateId',
        headerName: 'Contract Affiliate ID',
        width: 170,
      },
      {
        ...PlacementDefaultCell,
        field: 'clientContractSpecialist',
        headerName: 'Client Contract Specialist',
        renderCell: XGridOverflowCell(XGridContactHoverView('clientContractSpecialistAttributes'), true)(XGridBlueCell),
        width: 165,
      },
      {
        ...PlacementDefaultCell,
        field: 'clinicalManager',
        headerName: 'Clinical Manager',
        renderCell: XGridOverflowCell(XGridContactHoverView('clinicalManagerAttributes'), true)(XGridBlueCell),
        width: 165,
      },
      {
        ...PlacementDefaultCell,
        field: 'facilityId',
        headerName: 'Facility ID',
        width: 150,
      },
      {
        ...PlacementDefaultCell,
        field: 'staffingVendor',
        headerName: 'Staffing Vendor',
        width: 150,
      },
      {
        ...PlacementDefaultCell,
        field: 'region',
        headerName: 'Region',
        width: 150,
      },
      {
        ...PlacementDefaultCell,
        field: 'arbitrationStatus',
        headerName: 'Arbitration Status',
        width: 150,
      },
      {
        ...PlacementDefaultCell,
        field: 'arbitrationSentDate',
        headerName: 'Arbitration Sent Date',
        width: 150,
      },
      {
        ...PlacementDefaultCell,
        field: 'arbitrationSignedDate',
        headerName: 'Arbitration Signed Date',
        width: 150,
      },
      {
        ...PlacementDefaultCell,
        field: 'lastContactedOnDate',
        headerName: t('search.globalSearch.placement.gridTags.lastContacted'),
        width: 150,
      },
    ] as GridColumns
  ).filter(item => !exclude.some(field => field === item.field));

export const placementColumnVisibilityModel = {
  orderId: true,
  placementId: true,
  firstName: false,
  lastName: false,
  goesBy: false,
  otherName: false,
  phones: false,
  emails: false,
  candidateId: false,
  tags: true,
  status: true,
  statusAge: true,
  brand: true,
  candidate: true,
  facility: true,
  unit: true,
  skillsets: true,
  placementCreated: true,
  screen: true,
  fileSentDate: true,
  submission: false,
  availableStartDate: true,
  startDate: true,
  endDate: true,
  extensionStatus: true,
  extensionStatusLastUpdateDate: true,
  accountManager: true,
  recruiter: true,
  credentialingAnalyst: true,
  placementCuedTo: true,
  cueNote: true,
  contractAffiliate: true,
  arbitrationStatus: true,
  arbitrationSentDate: true,
  arbitrationSignedDate: true,
  region: false,
  lastContactedOnDate: false,
};

/* enum values are named w.r.t api service call*/
export enum PlacementSearchFilterName {
  recruiter = 'recruiterIds',
  accountManager = 'amUserIds',
  credentialingAnalyst = 'credIds',
  placementClinicalContractSpecialist = 'clientContractSpecialistUserIds',
  placementClinicalManager = 'clinicalManagerUserIds',
  archiveStatus = 'archiveStatus',
  archive = 'archive',
  amnDivision = 'divisionIds',
  brand = 'brandIds',
  candidate = 'candidateIds',
  cid = 'cIds',
  placementTags = 'placementTags',
  oid = 'orderIds',
  pid = 'placementIds',
  facility = 'facilityIds',
  facilityID = 'facilityNameIds',
  unit = 'unitIds',
  contractAffiliate = 'contractAffiliateIds',
  orderType = 'orderTypeIds',
  city = 'city',
  state = 'states',
  mspClient = 'mspClientIds',
  discipline = 'disciplineIds',
  specialty = 'specialtyIds',
  status = 'placementTypeStatuses',
  skillSet = 'skillsetFilter',
  submission = 'submissionPacketError',
  extensionStatus = 'extensionStatusIds',
  availableStartDate = 'availableStartDateRange',
  startDate = 'startDateRange',
  endDate = 'endDateRange',
  placementCuedTo = 'placementCuedToIds',
  cuedTo = 'cuedTo',
  tags = 'tags',
  screen = 'screenIds',
  contractAffiliateIds = 'contractAffiliateNameIds',
  staffingVendor = 'staffingVendorIds',
  region = 'regionIds',
  arbitrationStatus = 'arbitrationStatusIds',
  recruitmentLeaders = 'LeaderRecruiterIds',
  nonContacted = 'nonContactedDate'
}

export enum taskManagement {
  status = 'status',
}
