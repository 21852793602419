/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { SingleLineInput } from '../Common/SingleLineInput';
import { SimpleDropdown } from '../Common/SimpleDropdown';
import {
  ThemeProvider,
  StyledEngineProvider,
  Grid,
  InputAdornment,
  FormControl,
  InputLabel,
  useTheme,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { useTranslation } from 'react-i18next';
import { NewUnit } from 'app/models/Unit/CreateUnit';
import { getNurse, getPatient } from 'app/components/Order/OrderCreationForm/DefineUnit/Helper';

interface Props {
  unit: NewUnit;
  onUnitPropChange: (property: string, value: any) => void;
  disableEdit?: boolean;
}

const useStyles = makeStyles()(theme => ({
  titleRightPadding: {
    paddingRight: 23,
  },
  adornedRoot: {
    backgroundColor: theme.palette.framework.main,
    border: 'thin 2#e7e2e solid',
    '&:hover': {
      backgroundColor: theme.palette.framework.main,
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.framework.main,
    },
  },
  textareastyle: {
    backgroundColor: `${theme.palette.framework.main} !important`,
    '& label + div': {
      backgroundColor: `${theme.palette.framework.main} !important`,
    },
    '&:hover': {
      backgroundColor: theme.palette.framework.main,
    },
    '&:focus': {
      backgroundColor: theme.palette.framework.main,
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.framework.main,
    },
  },
}));

export const NurseToPatientRatio = (props: Props) => {
  const { unit, onUnitPropChange, disableEdit } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'fyreIntegration')
      return onUnitPropChange(event.target.name, event.target.value === 'Yes');
    onUnitPropChange(event.target.name, event.target.value);
  };

  const isFyreIntegration = unit.fyreIntegration === true ? 'Yes' : 'No';

  return (
    <div>
      {/* TODO: Remove this theme wrapper since it uses the global theme */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UnitDetailsBody variant="outlined">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <UnitCardsTitle className={classes.titleRightPadding}>
                  {t('order.createUnit.nursePatientRatio.title')}
                </UnitCardsTitle>
              </Grid>
              <Grid item xs={6}>
                <SingleLineInput
                  id={'createUnitNursePatientRatioNurseTxt'}
                  inputValue={
                    unit?.nurse ? parseInt(unit?.nurse) : unit?.nurseRatio ? parseInt(getNurse(unit?.nurseRatio)) : ''
                  }
                  inputName={'nurse'}
                  inputDefaultValue={''}
                  isdisabled={disableEdit}
                  inputLabel={t('order.createUnit.nursePatientRatio.nurse')}
                  handleChange={handleChange}
                  inputType={'number'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment style={{ backgroundColor: 'red !important' }} position="start">
                        {'%'}
                      </InputAdornment>
                    ),
                  }}
                ></SingleLineInput>
              </Grid>
              <Grid item xs={6} className={disableEdit ? '' : classes.textareastyle}>
                <SingleLineInput
                  id={'createUnitNursePatientRatioPatientTxt'}
                  inputValue={
                    unit?.patient
                      ? parseInt(unit?.patient)
                      : unit?.nurseRatio
                      ? parseInt(getPatient(unit?.nurseRatio))
                      : ''
                  }
                  inputName={'patient'}
                  inputDefaultValue={''}
                  isdisabled={disableEdit}
                  inputLabel={t('order.createUnit.nursePatientRatio.patient')}
                  handleChange={handleChange}
                  inputType={'number'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment className={classes.adornedRoot} position="start">
                        {'%'}
                      </InputAdornment>
                    ),
                  }}
                ></SingleLineInput>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth variant="filled">
                  <InputLabel>{`FYRE integration`}</InputLabel>
                  <SimpleDropdown
                    id={'createUnitNursePatientRatioFyreIntegrationDropDown'}
                    onChange={handleChange}
                    defaultInputList={['Yes', 'No']}
                    disabled={disableEdit}
                    defaultInputValue={isFyreIntegration ?? 'No'}
                    inputName={'fyreIntegration'}
                    inputPaddingLeft="2%"
                    marginLeft={'0px'}
                    minWidth={'330px !important'}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth variant="filled">
                  <InputLabel>{t('order.createUnit.nursePatientRatio.status')}</InputLabel>
                  <SimpleDropdown
                    id={'createUnitNursePatientRatioActiveStatusDropDown'}
                    onChange={() => {}}
                    defaultInputList={['Active']}
                    defaultInputValue={'Active'}
                    inputName={'Status'}
                    inputPaddingLeft="2%"
                    marginLeft={'0px'}
                    disabled={disableEdit}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </UnitDetailsBody>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};
