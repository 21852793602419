import { CustomSideFilter } from 'app/ComponentLibrary/Filter';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getAdvancedFilterSpecs } from './filterUtils';

export const AdvancedFilter = () => {
  const { t } = useTranslation();
  const fields = getAdvancedFilterSpecs(t, new Date());

  return <CustomSideFilter fields={fields} version2 hasApplyButton={false} />;
};
