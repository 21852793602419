import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { globalSearchActions } from 'store/redux-store/global-search/slice';
import { selectArchivePlacements } from 'store/redux-store/global-search/selectors';

interface ArchivePlacementDialogProps {}

const useStyles = makeStyles()({
  dialogContainer: {
    width: '460',
    maxWidth: '460px',
  },
  statusList: {
    paddingLeft: '38px !important',
    paddingTop: '6px !important',
  },
});

export const ArchivePlacementDialog = ({ ...props }: ArchivePlacementDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { classes, cx } = useStyles();

  const {
    dialogOpen: archivePlacementsDialogOpen,
    placementStatusesAllowed,
    loading: archivePlacementSubmitting,
    success,
  } = useSelector(selectArchivePlacements);

  return (
    <GenericDialog
      open={archivePlacementsDialogOpen}
      classes={{
        paper: classes.dialogContainer,
      }}
      dialogTitleProps={{
        text: t(`placement.archivePlacement.dialog.title`),
      }}
      dialogContentProps={{
        error: success === false,
        errorMessage: t(`placement.archivePlacement.dialog.errorMessage`),
        errorSeverity: 'error',
      }}
      dialogActions={[
        {
          text: 'Cancel',
          variant: 'text',
          onClick: () =>
            dispatch(
              globalSearchActions.setBulkArchivePlacements({ dialogOpen: false, success: null, loading: false }),
            ),
          disabled: archivePlacementSubmitting,
        },
        {
          text: 'Archive',
          variant: 'contained',
          onClick: () => dispatch(globalSearchActions.archivePlacements()),
          loading: archivePlacementSubmitting,
          disabled: archivePlacementSubmitting,
        },
      ]}
    >
      <Grid container>
        <Grid item xs={12}>
          {t(`placement.archivePlacement.dialog.dialogMessage`)}
        </Grid>
        <Grid item xs={12}>
          <ul className={cx('unordered-list', classes.statusList)}>
            {placementStatusesAllowed.map(item => (
              <li key={item} className={'unordered-list-item'}>{item}</li>
            ))}
          </ul>
        </Grid>
      </Grid>
    </GenericDialog>
  );
};
