import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { serviceActions } from './async-actions';
import { initialState } from './utils';
import { IMergeToken, ITemplate } from './types';

const slice = createSlice({
  name: 'confirmationTemplateEditor',
  initialState,
  reducers: {
    setTemplates(state, action: PayloadAction<ITemplate[]>) {
      return { ...state, templates: [...action.payload] };
    },
    setSelectedTemplate(state, action: PayloadAction<ITemplate>) {
      return { ...state, selectedTemplate: action.payload };
    },
    setMergeTokenList(state, action: PayloadAction<{ [key: string]: { label: string; tokens: IMergeToken[] } }>) {
      return { ...state, mergeTokenList: action.payload };
    },
    toggleForceModalCloseFlag(state) {
      state.miscellaneous.forceModalCloseFlag = !state.miscellaneous.forceModalCloseFlag;
    },
    reset() {
      return { ...initialState };
    },
  },
});

export const { name: confirmationTemplateEditorSliceKey, reducer: confirmationTemplateEditorReducer } = slice;

export const confirmationTemplateEditorActions = { ...slice.actions, ...serviceActions };
