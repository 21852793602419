import React, { useCallback, useEffect } from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import Grid from '@mui/material/Grid/Grid';
import { Checkbox, FormControlLabel, FormGroup } from 'amn-ui-core';
import { CompactStatus, IOrderLicense } from '@AMIEWEB/Order/Store/Order.redux';
import { useFormContext } from 'react-hook-form';
import { CollectionType } from 'app/enums/Common';

interface GridCellProps {
  [key: string]: any;
}

const LicenseRequirementRenderer = React.memo(function LicenseRequirementRenderer(cellParams: GridCellProps) {
  const { setValue } = useFormContext();

  useEffect(() => {
    //If an exisitng License isRequired is false then make it true
    if (!cellParams?.selectedLicense?.licenseStateDetails[0]?.isRequired) {
      updateLicenseStateDetails(true, 'isRequired');
    }
  }, [cellParams?.skillsetLicenseData, cellParams.param.id]);

  const handleAttachDocumentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    updateLicenseStateDetails(value, 'documentAttachmentRequired');
  };

  const updateLicenseStateDetails = useCallback(
    (value: boolean, fieldName: string) => {
      const orderLicensesDetails: IOrderLicense[] = [...cellParams?.skillsetLicenseData];
      const licenseDetailsIndex = orderLicensesDetails.findIndex(data => data?.licenseId === cellParams?.param?.id);

      if (licenseDetailsIndex !== -1 && cellParams?.selectedLicense) {
        const selectedLicenseDetail: IOrderLicense = {
          ...cellParams?.selectedLicense,
          licenseStateDetails: [
            {
              ...cellParams?.selectedLicense?.licenseStateDetails[0],
              [fieldName]: value,
            },
          ],
        };
        orderLicensesDetails[licenseDetailsIndex] = { ...selectedLicenseDetail };
      }

      setValue(
        `skillSetGroup.groups[${cellParams?.groupIndex}].skillsets[${cellParams?.skillSetIndex}].collections[${CollectionType.LicensesIndex}].requirements`,
        orderLicensesDetails,
      );
    },
    [cellParams?.skillsetLicenseData, cellParams?.param?.id],
  );

  return (
    <Grid container style={{ pointerEvents: 'auto' }}>
      <Grid item>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={true} //"isRequired" always true
                disabled
              />
            }
            label="Required"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={cellParams?.selectedLicense?.licenseStateDetails[0]?.documentAttachmentRequired || false}
                onChange={handleAttachDocumentChange}
              />
            }
            label="Attach Document"
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
});

export const UnitLicenseRequirementRenderCell = (props: {
  param: GridCellParams;
  groupIndex: number;
  skillSetIndex: number;
}) => {
  const { getValues } = useFormContext();

  const skillsetLicenseData = getValues(
    `skillSetGroup.groups[${props.groupIndex}].skillsets[${props.skillSetIndex}].collections[${CollectionType.LicensesIndex}].requirements`,
  );

  const selectedLicense = skillsetLicenseData?.find(data => data?.licenseId === props.param?.id);

  return props.param?.id &&
    skillsetLicenseData?.length &&
    selectedLicense &&
    selectedLicense?.compactStatus === CompactStatus.compact ? (
    <LicenseRequirementRenderer
      {...props}
      skillsetLicenseData={skillsetLicenseData}
      selectedLicense={selectedLicense}
    />
  ) : (
    <></>
  );
};
