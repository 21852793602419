/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Alert } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { selectFacility } from 'app/components/Facility/FacilityStore/Facility.selector';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { OrderCreationBodyWrapper } from './OrderCreationBodyWrapper';
import { OrderCreationHeader } from './OrderCreationHeader';
import { FacilityResponse, OrderFacilityDetails } from 'app/models/Facility/FacilityDetails';
import { selectUser } from 'oidc/user.selectors';
import { DetailsPage } from 'app/layout/pages/DetailsPage';
import { useInjectReducer } from 'utils/redux-injectors';
import { orderDataActions, orderDataReducer, orderDataSliceKey } from '../Store/Order.redux';
import { selectOrderCreationProcessStep } from 'app/components/Order/Store/Order.selectors';
import { useParams } from 'react-router-dom';
import useDocumentTitle from 'utils/customHooks/useDocumentTitle';
import { trackPageView } from 'app-insights/appInsightsTracking';
import { selectRateCardValidationError } from 'store/redux-store/create-order-ocbr/selector';
import { AMNDivisionType } from 'app/models/enums/AMNDivisionType';

export interface Facility {
  currentUserId: number;
  facilityId: number;
  facilityName: string;
  bestBets: string;
  bestBetsId: number;
  bedSize: boolean;
  isTeaching: boolean;
  isMagnetStatus: boolean;
  isTraumaCenter: boolean;
  division: string;
  hospitalAccountManager: {
    userId: number;
    firstName: string;
    lastName: string;
  };
  regionalDirector: {
    userId: number;
    firstName: string;
    lastName: string;
  };
  technologyVendor: string;
  healthSystem: string;
  region: string;
}

const useStyles: any = makeStyles()(theme => ({
  customAlert: {
    width: '100%',
    zIndex: 99,
    position: 'fixed',
    top: '40px',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    '& .MuiAlert-icon': {
      fontSize: 24,
      color: '#694D28',
    },
    alignItems: 'center',
    height: '52px',
  },
  root: {
    padding: theme.spacing(2),
  },
  alertMessage: {
    padding: '13px 0',
  },
  alertColor: {
    backgroundColor: '#E8F4FD',
  },
  alertIcon: {
    padding: '9px 0',
  },
}));

const getAddress = (data: { address1: string; address2: string; city: string; state: string; zipCode: string }) => {
  let value = '';
  if (data.zipCode) value = data.city + ', ' + data.state + ', ' + data.zipCode;
  else value = data.city + ', ' + data.state;

  let addressLine = data.address1 ? data.address1 : '';
  if (data.address2) {
    addressLine = addressLine + ' ' + data.address2;
  }

  return {
    line1: addressLine + ',',
    line2: value,
  };
};

const getRegion = facilityData => {
  let region;
  if (facilityData.location.region === 'AMN Allied') {
    return null;
  } else {
    region = facilityData.location.region;
  }
  if (facilityData.amnDivisionTypeId === AMNDivisionType.hsg) {
    region = 'Unassigned';
  } else {
    region = facilityData.location.region;
  }
  return region;
};

export const setFacilityDetailsForOrder = (response: FacilityResponse, user: number) => {
  return {
    currentUserId: user,
    facilityId: response.facilityId,
    facilityName: response.facilityName,
    facilityAddress: getAddress(response.location),
    hospitalAccountManager: response.employeeContacts?.accountManager ?? '',
    regionalDirector: response.employeeContacts.regionalDirector,
    division: response.division,
    bestBets: response.bestBets,
    bestBetsId: response.bestBetsId,
    bedSize: response.bedSize != null && response.bedSize < 200,
    isTeaching: response.isTeaching,
    isMagnetStatus: response.isMagnetStatus,
    isTraumaCenter: response.isTraumaCenter,
    technologyVendor: response.technologyVendor,
    healthSystem: response.healthSystemName,
    region: getRegion(response),
  };
};

export const OrderCreationForm = props => {
  const params = useParams<{ id: string }>();
  useInjectReducer({ key: orderDataSliceKey, reducer: orderDataReducer });
  useDocumentTitle({ title: `Create Order - FID ${params.id}` });
  const {
    onOrderCreationNav,
    createdNewUnitId,
    unitNavigationTarget,
    setUnitNavigationTarget,
    activateStep,
    setCreatedNewUnitId,
  } = props;
  const facilityValue = useSelector(selectFacility);
  const user = useSelector(selectUser);
  const facilityData =
    facilityValue.facilityResponse &&
    setFacilityDetailsForOrder(
      facilityValue.facilityResponse,
      user.userInfo?.employeeId ? user.userInfo.employeeId : 0,
    );
  //Code: Default is as per COA #US-67798
  const isRateCardValidationError = useSelector(selectRateCardValidationError)
  const [type, setType] = React.useState<string>('Travel');
  const [activeStep, setActiveStep] = React.useState<number>(activateStep);
  const [showCancelConfirmation, setShowCancelConfirmation] = React.useState<boolean>(false);
  const storeOrderCreationProcessStep = useSelector(selectOrderCreationProcessStep);
  const [clickOnBackButton, setClickOnBackButton] = React.useState<boolean>(false);

  useEffect(() => {
    trackPageView({ name: `Create Order - FID ${params.id}` });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTypeChange = (value: string) => {
    setType(value);
  };
  const handleNextButton = (value: number) => {
    setActiveStep(value);
  };
  const handleBackButton = (value: number) => {
    setActiveStep(value);
  };
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isShowmsg, setIsShowmsg] = useState<boolean>(false);

  const handleShow = (value: boolean) => {
    setIsShowmsg(value);
    dispatch(orderDataActions.setFormValid(value));
  };

  const windowClose = useCallback(e => {
    e.preventDefault();
    // Note: most browsers do not allow custom text
    return 'You are currently editing a credential, are you sure you want to exit?';
  }, []);

  const isClickInsideElement = useCallback((obj: Element) => {
    if (typeof obj?.className !== 'object' && obj?.getAttribute?.('data-editingrow') === 'true') return true;
    if (obj.parentNode) return isClickInsideElement(obj.parentNode);
    else return false;
  }, []);

  const didClick = useCallback(
    e => {
      if (!storeOrderCreationProcessStep || !storeOrderCreationProcessStep.isEditStarted) {
        return;
      }
      const clickOutsideElement = !isClickInsideElement(e.target);
      if (clickOutsideElement) {
        if (
          storeOrderCreationProcessStep?.currentStep === 'SelectUnit' ||
          storeOrderCreationProcessStep?.currentStep === 'EditOrderRequirement' ||
          storeOrderCreationProcessStep?.currentStep === 'UpdateOrderDetails' ||
          storeOrderCreationProcessStep?.currentStep === 'SubmitOrder'
        ) {
          e.stopPropagation();
          e.preventDefault();
          setShowCancelConfirmation(true);
        } else {
          setShowCancelConfirmation(false);
        }
      } else {
        setShowCancelConfirmation(false);
      }
    },
    [isClickInsideElement, storeOrderCreationProcessStep],
  );

  useEffect(() => {
    if (storeOrderCreationProcessStep && storeOrderCreationProcessStep.isEditStarted) {
      document.querySelector('#root')?.addEventListener('click', didClick);
      window.onbeforeunload = windowClose;
    }
    return () => {
      window.onbeforeunload = null;
      document.querySelector('#root')?.removeEventListener('click', didClick);
    };
  }, [didClick, storeOrderCreationProcessStep, windowClose]);

  useEffect(() => {
    if (storeOrderCreationProcessStep && storeOrderCreationProcessStep.isEditStarted) {
      window.addEventListener('beforeunload', reloadHandler);
      return () => {
        window.removeEventListener('beforeunload', reloadHandler);
      };
    } else {
      window.removeEventListener('beforeunload', reloadHandler);
    }
  }, [storeOrderCreationProcessStep]);

  const reloadHandler = e => {
    if (storeOrderCreationProcessStep && storeOrderCreationProcessStep.isEditStarted) {
      e.preventDefault();
      e.returnValue = '';
      if (storeOrderCreationProcessStep?.currentStep === 'SubmitOrder') {
        setShowCancelConfirmation(true);
      }
    }
  };

  return (
    <div data-editingrow="true">
      {isShowmsg ? (
        <Grid item xs={12}>
          <Alert
            severity="info"
            className={classes.customAlert}
            classes={{
              message: classes.alertMessage,
              standardInfo: classes.alertColor,
              icon: classes.alertIcon,
            }}
          >
            {t('order.createUnit.warringMessage')}
          </Alert>
        </Grid>
      ) : (
        isRateCardValidationError && (
          <Alert
            severity="error"
         
          >
            {t('order.newOrder.rateCard.warringMessage')}
          </Alert>
        )
      )}
      <DetailsPage
        title={storeOrderCreationProcessStep?.currentStep !== 'SubmitOrder' ? `Create Order` : ''}
        isCreateForm={isShowmsg}
        head={
          storeOrderCreationProcessStep?.currentStep !== 'SubmitOrder' && (
            <OrderCreationHeader
              data-testId="create order header"
              facilityData={facilityData as OrderFacilityDetails}
              type={type}
              activateStep={activeStep}
              onBack={handleBackButton}
              isUnit={false}
              setClickOnBackButton={setClickOnBackButton}
            />
          )
        }
      >
        <OrderCreationBodyWrapper
          onNext={handleNextButton}
          onTypeChange={handleTypeChange}
          onShowmsg={value => handleShow(value)}
          facilityData={facilityData as OrderFacilityDetails}
          onOrderCreationNav={onOrderCreationNav}
          unitNavigationTarget={unitNavigationTarget}
          setUnitNavigationTarget={setUnitNavigationTarget}
          createdNewUnitId={createdNewUnitId}
          activateStep={activeStep}
          setCreatedNewUnitId={setCreatedNewUnitId}
          showCancelConfirmation={showCancelConfirmation}
          setShowCancelConfirmation={setShowCancelConfirmation}
          clickOnBackButton={clickOnBackButton}
          setClickOnBackButton={setClickOnBackButton}
        />
      </DetailsPage>
    </div>
  );
};
