import { Grid, Button, useTheme, ThemeProvider, StyledEngineProvider, Theme, CircularProgress } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styledComponent from 'styled-components';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    height: 60,
    width: 'calc(100% - 92px)',
    position: 'fixed',
    left: 61,
    right: 0,
    bottom: '0px',
    opacity: 0.9,
  },
  elevateContainer: {
    zIndex: 1100,
    backdropFilter: 'blur(2px)',
  },
  facilityButton: {
    marginRight: 10,
    marginTop: 10,
    marginBottom: 6,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  circularProgressLight: {
    color: '#FFF',
  },
}));

interface FormActionsProps {
  disableSubmit: boolean;
  disableCancel: boolean;
  isSaving: boolean;
  openClearChangesConfirmation: boolean;
  disableApplyButton?: boolean;
  ApplyButtonTitle?: string;
  showApplyButton?: boolean;
  handleSaveChanges?: () => Promise<void>;
  handleClearChanges: () => void;
  handleClearChangesConfirmClose: (clearValues: boolean) => void;
  handleApplyButtonClick?: () => void;
}

export const ActionButtonWrapper = ({
  disableSubmit,
  disableCancel,
  isSaving,
  openClearChangesConfirmation,
  disableApplyButton,
  ApplyButtonTitle,
  showApplyButton,
  handleClearChanges,
  handleSaveChanges,
  handleClearChangesConfirmClose,
  handleApplyButtonClick,
  ...props
}: FormActionsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { classes, cx } = useStyles();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(openClearChangesConfirmation);

  const reloadHandler = e => {
    e.preventDefault();
    e.returnValue = '';
    window.removeEventListener('beforeunload', reloadHandler);
    setShowConfirmationDialog(true);
  };

  useEffect(() => {
    setShowConfirmationDialog(openClearChangesConfirmation);
  }, [openClearChangesConfirmation]);

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Grid
            container
            direction="row"
            data-testid="button-wrapper"
            justifyContent="flex-end"
            className={`${classes.container} ${classes.elevateContainer}`}
          >
            <Grid item className={classes.wrapper} xs={5} lg={4}>
              {showApplyButton && (
                <Button
                  variant="contained"
                  data-testid="apply-button"
                  type="button"
                  color="tertiary"
                  size="large"
                  className={`${classes.facilityButton}`}
                  disabled={disableApplyButton}
                  onClick={handleApplyButtonClick}
                >
                  {ApplyButtonTitle}
                </Button>
              )}
              <Button
                variant="contained"
                data-testid="clear-button"
                type="button"
                color="tertiary"
                size="large"
                className={`${classes.facilityButton}`}
                disabled={disableCancel}
                onClick={handleClearChanges}
              >
                {t('order.orderDetails.buttonClearChanges')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                data-testid="save-button"
                className={`${classes.facilityButton}`}
                disabled={disableSubmit}
                onClick={handleSaveChanges}
              >
                {isSaving ? (
                  <Grid
                    container
                    spacing={1}
                    wrap="nowrap"
                    alignItems="center"
                    justifyContent="center"
                    alignContent="center"
                  >
                    <Grid item>
                      <Progress size="15px" className={cx({ [classes.circularProgressLight]: true })} />
                    </Grid>
                    <Grid item>{t('order.orderDetails.preferences.actions.saveChanges')}</Grid>
                  </Grid>
                ) : (
                  t('order.orderDetails.preferences.actions.saveChanges')
                )}
              </Button>
            </Grid>
          </Grid>
         
        <GenericDialog
            open={showConfirmationDialog}
            disableEscapeKeyDown
            maxWidth="sm"
            fullWidth
            dialogTitleProps={{
              text: t(`order.orderDetails.contentConfirmation`),
            }}
            dialogActions={[
              {
                text: t('order.orderDetails.buttonConfirmation.No'),
                variant: 'text',
                color: 'tertiary',
                onClick: ()=> handleClearChangesConfirmClose(false),
              },
              {
                text: t('order.orderDetails.buttonConfirmation.Yes'),
                variant: 'contained',
                color: 'primary',
                onClick: ()=> handleClearChangesConfirmClose(true),
              },
            ]}
          >
      </GenericDialog>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

const Progress = styledComponent(CircularProgress)`
  margin-top: 2px;
  margin-bottom: -2px;
  padding: 0;
`;
