import { FormControl, FormControlLabel, Radio, RadioGroup } from 'amn-ui-core';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ disabled: boolean }>()((theme, { disabled = false }) => ({
  titleBar: {
    borderBottom: `1px solid ${theme.palette.system.borderColor1}`,
    background: theme.palette.system.offWhite,
    color: disabled ? theme.palette.system.lightGrey : theme.palette.components.box.titleBar.color,
    fontSize: 14,
    padding: 12,
    fontWeight: 500,
  },
  radioButtons: {
    margin: '15px',
    pointerEvents: disabled ? 'none' : 'auto',
  },
}));

export enum EmailSettings {
  Group = 'groupEmail',
  Individual = 'individualEmails'
}


export const BulkEmailChoice = ({ disabled, onChange, ...rest }) => {
  const { classes } = useStyles({ disabled });

  return (
      <FormControl className={classes.radioButtons} data-testid="packet-templates-settings" disabled={disabled}>
        <RadioGroup
          {...rest}
          aria-labelledby="radio-buttons-group-label"
          name="radio-buttons-group"
          onChange={e => onChange(e.target.value)}
          defaultValue={disabled ? '' : EmailSettings.Group}
        >
          <FormControlLabel
            value={EmailSettings.Group}
            control={<Radio />}
            label="Group Email"
            data-testid="packet-templates-group-email"
          />
          <FormControlLabel
            value={EmailSettings.Individual}
            control={<Radio />}
            label="Individual Emails"
            data-testid="packet-templates-indivisual-email"
          />
        </RadioGroup>
      </FormControl>
  );
};