import { Box, Grid, Theme, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import CueIconActive from 'app/assets/images/Tasks/CueNoteActive.svg';
import CueIconDisabled from 'app/assets/images/Tasks/CueNoteDisabled.svg';
import ThumbUp from 'app/assets/images/Tasks/thumb-up.svg';
import ThumbUpFilled from 'app/assets/images/Tasks/thumb-up-filled.svg';
import ReplyIcon from 'app/assets/images/Tasks/CueReplyIcon.svg';
import ReplyIconDisabled from 'app/assets/images/Tasks/ReplyIconDisabled.svg';
import passportIcon from 'app/assets/images/Tasks/passport-icon-2.png';
import { useTranslation } from 'react-i18next';
import { displaySentDate } from '../Common/FormatData';
import { ICueNote, ITaskNotificationPopupProps, TaskNotificationType } from 'app/models/Tasks/Tasks';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { taskDetailsActions } from 'app/components/Tasks/store/Tasks.redux';
import { WhiteTooltip } from 'app/components/Common/Tooltips/WhiteTooltip';
import { theme } from 'styles/global-styles';
import { TextWithEllipses } from '@AMIEWEB/Notification/Common/StringHelper';
import { getEmployeeId } from '../Common_v2/utils';


const useStyles = makeStyles<{ showActionPanel?: boolean }>()((theme: Theme, { showActionPanel }) => ({
  cardFooterDivision: {
    top: '422px',
    height: '18px',
    marginTop: '4px',
  },
  cardFooterDivisionMultiLine: {
    top: '422px',
    height: '16px',
    marginTop: '2rem',
  },
  truncation: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  truncateDescription: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre-wrap',
  },
  newTaskNotificationTitle: {
    top: '363px',
    left: '209px',
    height: '19px',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: 500,
    lineHeight: '19px',
    letterSpacing: '0px',
    color: theme.palette.system.darkGray,
    opacity: 1,
    marginTop: '6px',
  },
  taskDescription: {
    top: '390px',
    left: '149px',
    minHeight: '16px',
    maxHeight: '30px',
    textAlign: 'left',
    font: 'normal normal normal 12px/18px Roboto',
    fontFamily: 'Roboto',
    letterSpacing: '0px',
    color: theme.palette.system.black,
    opacity: 1,
    paddingTop: '0.15rem',
  },
  taskDescriptionSplit: {
    top: '390px',
    left: '149px',
    textAlign: 'left',
    font: 'normal normal normal 12px/18px Roboto',
    fontFamily: 'Roboto',
    letterSpacing: '0px',
    color: theme.palette.system.black,
    fontWeight: 'bold !important',
    opacity: 1,
    paddingTop: '0.15rem',
    height: '34px',
    width: showActionPanel ? '200px' : '300px'
  },
  taskDescriptionSplitRead: {
    top: '390px',
    left: '149px',
    textAlign: 'left',
    font: 'normal normal normal 12px/18px Roboto',
    fontFamily: 'Roboto',
    letterSpacing: '0px',
    color: theme.palette.system.black,
    fontWeight: 'normal !important',
    opacity: 1,
    paddingTop: '0.15rem',
    height: '34px',
    width: showActionPanel ? '200px' : '320px'
  },
  taskDueDate: {
    top: '215px',
    left: '428px',
    height: '16px',
    textAlign: 'right',
    font: 'normal normal normal 12px/16px Roboto',
    letterSpacing: '0px',
    color: theme.palette.framework.system.tertiaryGrey,
    opacity: 1,
    paddingRight: '9px',
    marginTop: '3px',
  },
  additionalInfo: {
    top: '410px',
    left: '101px',
    height: '16px',
    textAlign: 'left',
    font: 'normal normal normal 12px/16px Roboto',
    letterSpacing: '0px',
    color: theme.palette.framework.system.tertiaryGrey,
    opacity: 1,
    marginTop: '3px',
  },
  gridContainers: {
    marginTop: '0px',
    height: 'auto',
    opacity: 1,
  },
  gridContainer: {
    marginTop: '0px'
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  statusIcon: {
    marginTop: '0.5rem',
    width: '36px',
    height: '36px',
    opacity: 1,
  },
  statusIconNew: {
    width: '36px',
    height: '36px',
    opacity: 1,
  },
  seperatorIcon: {
    fontSize: '6px',
    marginBottom: '2px',
  },
  spanText: {
    fontWeight: 'normal',
    zIndex: '5'
  },
  truncateTitle: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: showActionPanel ? '100px' : '165px'
  },
  acknowledgeIcon: {
    height: '16px',
    textAlign: 'right',
    font: 'normal normal normal 12px/16px Roboto',
    letterSpacing: '0px',
    color: theme.palette.framework.system.tertiaryGrey,
    opacity: 1,
  },
  mainTitle: {
    color: theme.palette.framework.system.violet,
    fontSize: ' 0.8rem !important',
    textAlign: 'left',
    fontWeight: 'bold',
    padding: '0px',
    margin: '0px'
  },
  mainTitleRead: {
    color: theme.palette.system.darkCharcoalGray,
    fontSize: ' 0.8rem !important',
    textAlign: 'left',
    fontWeight: 'normal',
    padding: '0px',
    margin: '0px'
  },
  secondaryTitle: {
    color: theme.palette.system.darkGray,
    paddingRight: '5px',
    fontSize: '0.9rem',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    padding: '0px',
    margin: '0px',
    display: 'flex'
  },
  secondaryTitleRead: {
    color: theme.palette.system.darkGray,
    paddingRight: '5px',
    fontSize: '0.9rem',
    fontWeight: 'normal',
    fontFamily: 'Roboto',
    padding: '0px',
    margin: '0px',
    display: 'flex'
  },
  baseContainer: {
    margin: '0px',
    height: '119px',
    width: showActionPanel ? '250px' : '350px',
    transition: 'width 0.3s',
    opacity: 1,
    backgroundColor: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: ` 0px 0px 6px ${theme.palette.system.lightBlack}`,
    border: '1px solid #E2DFDF',
    borderRadius: ' 5px',
    padding: '12px',
    marginTop: '0px !important'
  }
}));
export const CueNotesNotificationPopup = ({
  NotificationBody,
  activityFeed = false,
  showActionPanel,
  isUnread,
}: {
  NotificationBody: ITaskNotificationPopupProps;
  activityFeed: boolean;
  showActionPanel?: boolean;
  isUnread?: boolean;
}) => {
  const { classes, } = useStyles({ showActionPanel });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [associatedRecords, setAssociatedRecords] = useState<any>({});
  const [dueDate, setdueDate] = useState('');
  const [acknowledge, setAcknowledged] = useState<boolean>(false);
  const userInfo = useSelector(selectUser);
  const dueDateValue = 'DueDate';
  const sendDate = 'SentDate';
  const isReplyNotification = parseInt(NotificationBody.useSubType) === TaskNotificationType.CUENOTEREPLY;
  const PlacementID = 'PlacementId';

  let Maxlength
  if (showActionPanel) {
    Maxlength = 12;
  }
  else {
    Maxlength = 20;
  }


  const PlacementStatusId_Blue = [1, 2, 3, 16, 6, 8]
  const PlacementStatusId_Teal = [9, 10, 11, 12]
  const PlacementStatusId_DarkBlue = [13, 14, 15, 17, 18]
  const PlacementStatusId_Green = [23]
  const PlacementStatusId_Red = [4, 5, 7, 20, 21, 22, 28, 25, 26, 27, 29]


  const getAssociatedRecords = records => {
    const obj: any = {};
    records?.forEach(el => {
      obj[el.name] = el.value;
    });
    return obj;
  };

  const getPlacementId = () => {
    let PID = 0;
    NotificationBody?.associateRecords?.map(records => {
      if (records?.name === PlacementID) {
        PID = parseInt(records?.value);
      }
    });
    return PID;
  };

  const sendCue = () => {
    if (!acknowledge) {
      setAcknowledged(true);
      const cueData: ICueNote = {
        placementId: getPlacementId(),
        senderId: parseInt(getEmployeeId(userInfo?.userInfo))|| 0,
        statusId: 1,
        text: t('notification.cueNotification.acknowledge'),
        recipientId: 0,
      };
      dispatch(taskDetailsActions.saveCueNoteAction(cueData));
    }
  };

  useEffect(() => {
    displaySentDate(NotificationBody, dueDateValue, activityFeed, setdueDate, sendDate, t);
    const getRecords = getAssociatedRecords(NotificationBody?.associateRecords);
    setAssociatedRecords(getRecords);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [NotificationBody]);

  const getColor = (() => {
    if (isUnread) {
      let PlacementStatusId = +associatedRecords?.PlacementStatusId;
      if (PlacementStatusId_Blue.includes(PlacementStatusId)) {
        return { color: theme.palette.framework.system.navyBlue }
      } else if (PlacementStatusId_Teal.includes(PlacementStatusId)) {
        return { color: theme.palette.framework.system.teal }
      } else if (PlacementStatusId_DarkBlue.includes(PlacementStatusId)) {
        return { color: theme.palette.system.mutedAzureBlue }
      } else if (PlacementStatusId_Green.includes(PlacementStatusId)) {
        return { color: theme.palette.framework.system.green }
      } else if (PlacementStatusId_Red.includes(PlacementStatusId)) {
        return { color: theme.palette.framework.system.red }
      }
      else {
        return { color: theme.palette.system.doveGray }
      }
    }
  })

  return (
    <React.Fragment>
      <div className={classes.baseContainer}>
        <div className={classes.gridContainers}>
          <Grid container direction="row">
            <Grid item xs={1}>
              <div className={classes.statusIconNew}>
                {isReplyNotification ? (
                  <img src={isUnread ? ReplyIcon : ReplyIconDisabled} alt="ReplyIcon" />
                ) : associatedRecords?.isAutoSubmission ? (
                  <img src={passportIcon} alt="passportIcon" />
                ) : (
                  <img src={isUnread ? CueIconActive : CueIconDisabled} alt="CueIcon" />
                )}
              </div>
            </Grid>
            <>
              <Grid item xs={11} style={{ paddingLeft: showActionPanel ? '12px' : '5px' }}>
                <div style={{ marginLeft: showActionPanel ? '12px' : '14px' }}>
                  <Box display={'flex'}>
                    <Typography className={isUnread ? classes.mainTitle : classes.mainTitleRead}>
                      {isReplyNotification
                        ? t('notification.cueNotification.newCueReply')
                        : t('notification.cueNotification.newCueLabel')}
                    </Typography>
                  </Box>
                  <Box display={'flex'}>
                    <Typography className={isUnread ? classes.secondaryTitle : classes.secondaryTitleRead}>
                      {TextWithEllipses(associatedRecords?.PrimaryContext, Maxlength)}
                      <span className={classes.spanText}>&nbsp;{'(' + associatedRecords?.EntityId + ')'}</span>
                    </Typography>
                  </Box>
                </div>
              </Grid>
              <Grid>
                <div className={isUnread ? classes.taskDescriptionSplit : classes.taskDescriptionSplitRead}>
                  <div className={classes.truncateDescription}>
                    {`${associatedRecords?.UserName}:`} {associatedRecords?.Description}
                  </div>
                  {
                    associatedRecords?.PlacementStatus ?
                      <span style={getColor()}>{t('notification.cueNotification.status')} {associatedRecords?.PlacementStatus}</span> : <></>
                  }
                </div>
              </Grid>
            </>
          </Grid>
        </div>
        <Grid container xs={12} direction="column" className={classes.cardFooterDivision}>
          <Grid item className={classes.additionalInfo}>
            <div>
              {' '}
              {t('notification.cueNotification.PID')} {associatedRecords?.PlacementId}
            </div>
          </Grid>
          {activityFeed && (
            <Grid item className={classes.taskDueDate}>
              {dueDate}
            </Grid>
          )}
          
        </Grid>
      </div>
    </React.Fragment >
  );
};
