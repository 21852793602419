import { Grid, Theme, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { ReactElement } from 'react';
import { CustomTooltip } from '@AMIEWEB/Common';
import { AndOrOperator } from 'app/enums/Common';
import { useTranslation } from 'react-i18next';

export enum SkillSetTypes {
  Licenses = 'Licenses',
  Certifications = 'Certifications',
  Travel = 'Travel',
}

export enum StatusTileText {
  Required = 'R',
  Preferred = 'P',
}

export enum StatusTileDescription {
  Required = 'Required',
  Preferred = 'Preferred',
}

export enum StatusTileColorCode {
  violet = 'Violet',
  grey = 'Grey',
}

interface StatusTileContent {
  text: string;
  showTooltip: boolean;
  tooltipText: string;
}

export interface StatusTileProps {
  id?: number | null;
  status: StatusTileContent;
  title: StatusTileContent;
  showAdditionalInfo?: boolean;
  additionalInfo?: string;
  showAdditionalInfoTooltip?: boolean;
  icon?: ReactElement;
  issuedBy?: string;
  iconTooltip?: {
    showTooltip: boolean;
    message: string;
  };
}

interface AdditionalDataForBorderRadius {
  andOrOperator?: AndOrOperator;
  currentIndex?: number;
  finalIndex?: number;
}

interface StatusTilePropsWithAndOrOperator {
  tileData: StatusTileProps;
  additionalBorderRadiusData?: AdditionalDataForBorderRadius;
}

interface IStyleProps {
  issuedBy: string;
  additionalBorderRadiusData: AdditionalDataForBorderRadius;
}

function getBorderRadiusForTile({ andOrOperator, currentIndex, finalIndex }: AdditionalDataForBorderRadius) {
  return andOrOperator !== AndOrOperator.Or && finalIndex !== 0
    ? currentIndex === 0
      ? '4px 4px 0px 0px'
      : currentIndex === finalIndex
      ? '0px 0px 4px 4px'
      : '0px'
    : '4px';
}

const useStyles = makeStyles<IStyleProps>()((theme: Theme, { additionalBorderRadiusData, issuedBy }) => ({
  tileContainer: {
    display: 'grid',
    overflow: 'hidden',
    gridTemplateColumns: '30px auto',
    alignItems: 'center',
    gridAutoFlow: 'column',
    borderRadius: additionalBorderRadiusData ? getBorderRadiusForTile(additionalBorderRadiusData) : '4px',
    border: `1px solid ${theme.palette.framework.system.lightGray}`,
    minHeight: '32px',
    backgroundColor: theme.palette.components.grid.content.backgroundColor,
  },
  issuedBy: {
    fontStyle: 'italic',
    fontSize: '12px !important',
    padding: '0px 12px',
  },
  title: {
    borderRadius: '2px 0px 0px 2px',
    color: theme.palette.system.darkCharcoal,
    fontSize: 14,
    fontWeight: 400,
    padding: issuedBy ? '6px 8px 0px 12px' : '6px 8px 6px 12px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontFamily: 'Roboto',
  },
  statusText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    whiteSpace: 'nowrap',
    fontFamily: 'Roboto',
    height: '100%',
  },
  statusViolet: {
    background: theme.palette.system.violet,
    color: theme.palette.framework.system.white,
  },
  statusGrey: {
    background: theme.palette.system.lightGreyStatus,
    color: theme.palette.system.violet,
  },
  additionalInfo: {
    marginLeft: 'auto',
    justifySelf: 'flex-end',
    color: theme.palette.system.darkCharcoal,
    fontSize: 12,
    fontWeight: 400,
    whiteSpace: 'nowrap',
    paddingLeft: '2px',
    paddingRight: '8px',
  },
  icon: {
    marginLeft: 'auto',
    marginRight: '8px',
    position: 'relative',
    top: issuedBy ? '-6px' : '0px',
  },
  tooltipText: {
    maxWidth: '300px',
  },
}));

const StatusTile = ({ tileData, additionalBorderRadiusData }: StatusTilePropsWithAndOrOperator) => {
  const { t } = useTranslation();
  const { text: statusText = null, showTooltip: showStatusTooltip, tooltipText: statusTooltipText } = tileData.status;
  const { text: titleText = null, showTooltip: showTitleTooltip, tooltipText: titleTooltipText } = tileData.title;
  const { classes } = useStyles({ additionalBorderRadiusData, issuedBy: tileData?.issuedBy });

  return (
    <Grid className={classes.tileContainer}>
      <CustomTooltip
        arrow
        standardMargin
        disabled={!(showStatusTooltip && statusTooltipText)}
        tooltipContent={statusTooltipText}
        classes={{ tooltip: classes.tooltipText }}
        contentStyle={{ height: '100%' }}
        disableMaxWidth={true}
      >
        <Typography
          component={'div'}
          className={`${classes.statusText} ${
            statusText === StatusTileText.Required ? classes.statusViolet : classes.statusGrey
          }`}
        >
          {statusText}
        </Typography>
      </CustomTooltip>
      <CustomTooltip
        arrow
        standardMargin
        disabled={!(showTitleTooltip && titleTooltipText)}
        tooltipContent={titleTooltipText}
        classes={{ tooltip: classes.tooltipText }}
        contentStyle={{ overflow: 'hidden' }}
        disableMaxWidth={true}
      >
        <div>
          <Typography component="div" className={classes.title}>
            {titleText}
          </Typography>
          {tileData?.issuedBy && (
            <Typography component="div" className={`${classes.title} ${classes.issuedBy}`}>
              {t('facility.units.unitDetails.unitRequirements.issuedBy')}: {tileData.issuedBy}
            </Typography>
          )}
        </div>
      </CustomTooltip>
      {tileData.showAdditionalInfo && tileData.additionalInfo && (
        <Typography component="div" className={classes.additionalInfo} px={2}>
          {tileData.additionalInfo}
        </Typography>
      )}
      {tileData.icon ? (
        <div className={classes.icon}>
          <CustomTooltip
            arrow
            standardMargin
            disabled={!tileData.iconTooltip.showTooltip}
            tooltipContent={
              <Typography
                component="div"
                variant="body2"
                sx={{
                  color: theme => theme.palette.framework.doveGray,
                  width: '108px',
                  wordWrap: 'break-word',
                }}
              >
                {t(tileData.iconTooltip.message)}
              </Typography>
            }
            children={tileData.icon}
          />
        </div>
      ) : null}
    </Grid>
  );
};

export default StatusTile;
