import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import React from 'react';
import { CustomTooltip, CustomTooltipProps } from '../../Tooltips/CustomTooltip';

export const XGridToolTipCell =
  ({
    tooltipContent,
    value,
    disabled = () => false,
    tooltipProps,
  }: {
    tooltipContent?: (params: GridRenderCellParams) => JSX.Element | string;
    value?: (params: GridRenderCellParams) => JSX.Element | string;
    disabled?: (params: GridRenderCellParams) => boolean;
    tooltipProps?: CustomTooltipProps;
  }) =>
  params => {
    return (
      <CustomTooltip
        contentStyle={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        {...tooltipProps}
        tooltipContent={tooltipContent?.(params) ?? params.value}
        value={value?.(params) ?? params.value}
        disabled={disabled?.(params)}
      />
    );
  };
