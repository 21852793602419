import React from 'react';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles()(() => ({
  dialogContainer: {
    minWidth: '600px',
    minHeight: '400px',
  },
  dialogContent: {
    padding: 0,
    overflow: 'hidden',
  },
}));

export const RequestTimeOffDialog = ({
  open,
  children,
  setPopupOpen,
}: {
  open: boolean;
  children: JSX.Element;
  setPopupOpen: Function;
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    reset,
    formState: { isDirty, isValid },
  } = useFormContext();

  return (
    <GenericDialog
      variant={'blue'}
      classes={{
        paper: classes.dialogContainer,
      }}
      open={open}
      disablePortal
      disableEnforceFocus
      disableEscapeKeyDown
      dialogTitleProps={{
        text: t('placement.profile.requirementsAndQualifications.timeOffRequest.title'),
        closeButton: true,
        expandable: true,
      }}
      dialogContentProps={{
        classes: { root: classes.dialogContent },
      }}
      dialogActions={[
        {
          text: 'Cancel',
          color: 'tertiary',
          variant: 'contained',
          onClick: () => {
            reset();
            setPopupOpen();
          },
        },
        {
          text: 'Save',
          variant: 'contained',
          color: 'primary',
          type: 'submit',
          disabled: !isDirty || !isValid,
        },
      ]}
      onClose={() => {
        reset();
        setPopupOpen();
      }}
    >
      {children}
    </GenericDialog>
  );
};
