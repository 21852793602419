import React, { useState, useRef } from 'react';
import { DateRangePicker } from '../DateRange/components/DateRangePicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Box, TextField, ClickAwayListener, InputAdornment, Popper } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { getDateRangeLabel } from './helper';

const useStyles = makeStyles()({
  textField: {
    fontSize: 12,
    width: '100%',
    '& input::placeholder': {
      fontSize: 12,
    },
  },
  calendarIcon: {
    opacity: 0.6,
  },
});

export const CustomDateRangePicker = props => {
  const { classes } = useStyles();
  const textRef = useRef();
  const [open, setOpen] = useState(false);
  const [userEnteredValue, setUserEnteredValue] = useState<string | null>(null);
  const [range, setRange] = useState<any>(null);
  const handleChange = range => {
    setRange({ ...range });
    setUserEnteredValue(null);
    props.onChange(range);
    setOpen(false);
  };

  React.useEffect(() => {
    if (!props.value && !userEnteredValue) setRange(null);
    else if (props.value) setRange({ ...props.value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onClickAway = e => {
    if (!e.path?.some(element => element.ariaLabel === 'custom-date-range-picker')) setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box>
        <TextField
          inputRef={textRef}
          placeholder={props.placeholder || 'MM/DD/YYYY - MM/DD/YYYY'}
          variant="outlined"
          size="small"
          classes={{ root: classes.textField }}
          InputProps={{
            id: 'date-range',
            endAdornment: (
              <InputAdornment position="end">
                <CalendarTodayIcon className={classes.calendarIcon} />
              </InputAdornment>
            ),
          }}
          inputProps={{ 'aria-label': 'picker-text-field' }}
          helperText={props.allowHelperText ? (props.errorObject ? props.errorObject.message : ' ') : null}
          value={userEnteredValue ? userEnteredValue : props.value ? getDateRangeLabel(props.value) : ''}
          onClick={() => setOpen(prevState => !prevState)}
        />
        <Popper open={open} anchorEl={textRef.current} placement={'bottom-start'} style={{ zIndex: 10 }}>
          <Box aria-label="custom-date-range-picker">
            <DateRangePicker initialDateRange={range} onChange={val => handleChange(val)} open={open} />
          </Box>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};
