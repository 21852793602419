import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectGridSelection } from 'app/components/Common/Grid/GridSelection/GridSelection.selector';
import { ContactProps, IToken } from 'app/models/Notification/Notification';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { EmailDefaultValues } from 'app/models/Email/IEmail';
import { searchNotificationData } from 'app/components/GlobalSearch/helper';
import { ChannelType, ContactType, SendType, UseType } from 'app/components/Notification/Constants';
import { selectUser } from 'oidc/user.selectors';
import _ from 'lodash';
import { missingField } from 'app/constants';
import { setNotificationData } from 'app/components/Candidate/CandidateProfile/CandidateHeader/NotificationHelper';
import { formatPhoneNumber } from '../MultiChannel/BulkSms/MessageHelpers';
import { selectSearchResults } from 'store/redux-store/global-search/selectors';
import { selectFilters } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';
import { getCandidateById } from 'app/services/CandidateServices/CandidateServices';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';


export const useBulkCommunication = () => {
  const user = useSelector(selectUser);
  const { selectedData } = useSelector(selectGridSelection);
  const notificationData = useSelector(notificationSelection);
  const { items } = useSelector(selectSearchResults);
  const filters = useSelector(selectFilters);
  const [notificationUser] = useState(searchNotificationData(user));
  const dispatch = useDispatch();

  function getValidRowsWithEmail() {
    const validRows = items.filter(row => selectedData.includes(row.id));
    const validEmailRows: ContactProps[] = validRows.reduce((storage: ContactProps[], item) => {
      return [
        ...storage,
        {
          name: item?.candidate || item?.candidateName,
          email: item.candidateEmail,
          contactId: item?.candidateId,
          phoneNumbers: item?.phones,
          contactType: 'candidate',
          brand: item?.brand,
          brandId: item?.brandId,
          placementId: item?.placementId,
          //@ts-ignore
          recruiterId: `${item?.recruiterId || item?.placementRecruiterId || item?.recruiterData?.id || filters.filters?.placementRecruiter?.value}`,
        },
      ];
    }, []);
    return validEmailRows;
  }
  function handleCreateEmail() {
    const recipient: ContactProps[] = getValidRowsWithEmail();
    const substitutions: IToken[] = [];
    substitutions.push({
      name: 'Signature',
      input: '{{signature}}',
    });
    if (!notificationData.email.open && notificationData.email.minimized) {
      dispatch(
        notificationDataActions.setEmailInteraction({
          open: true,
          minimized: false,
        }),
      );
      if (!_.isEqual(notificationData.email.data!.tos, recipient)) {
        dispatch(notificationDataActions.setSnackBarData({ channel: 'email', manual: false, changeWarning: true }));
      }
    } else {
      dispatch(
        notificationDataActions.setEmailData({
          data: {
            ...EmailDefaultValues(),
            ...notificationUser,
            substitutions: substitutions,
            useType: UseType.Bulk,
            emailType: SendType.bulk,
            tos: recipient,
          },
          open: true,
          minimized: false,
        }),
      );
    }
  }

  const formatCandidateData = candidate => {
    return {
      travelerId: candidate?.candidateId,
      name: {
        first: candidate?.candidate?.substr(0, candidate?.candidate?.indexOf(' ')) || candidate?.candidateName?.substr(0, candidate?.candidateName?.indexOf(' ')),
        last: candidate?.candidate?.substr(candidate?.candidate?.indexOf(' ') + 1) || candidate?.candidateName?.substr(candidate?.candidateName?.indexOf(' ') + 1),
      },
      brand: candidate?.brand,
      brandId: candidate?.brandId,
      primaryPhoneNumber: candidate?.candidatePhone,
      primaryEmail: candidate?.candidateEmail,
    };
  };

  const handleSms = async () => {
    const candidate = items.filter(row => selectedData.includes(row.id));
    const validCandidate = candidate.filter(item => item?.candidatePhone !== missingField);
    const candidatDetails = formatCandidateData(validCandidate[0]);
    const CandidateData = await getCandidateById(
      parseInt(candidatDetails.travelerId), parseInt(candidatDetails.brandId))
    dispatch(candidateDetailActions.setCandidateDetails(CandidateData));

    setNotificationData(
      user,
      user.userInfo?.phoneNumber,
      CandidateData,
      dispatch,
      notificationData,
      ChannelType.sms,
      ContactType.primary,
      false,
      () => {
        return;
      },
    );
  };

  const handleSelectedSmsData = () => {
    const validRows = items.filter(row => selectedData.includes(row.id));
    const validSmsRows = validRows.reduce((storage: ContactProps[], item) => {
      if (item?.candidatePhone && item?.candidatePhone !== missingField) {
        return [
          ...storage,
          {
            name: item?.candidate || item?.candidateName,
            email: item?.candidateEmail,
            contactId: `${item?.candidateId}`,
            phoneNumber: formatPhoneNumber(item?.candidatePhone),
            contactType: 'candidate',
            brand: item?.brand,
            brandId: `${item.brandId}`,
            recruiter: item?.recruiter,
            recruiterId: `${item?.recruiterId}`,
          },
        ];
      } else {
        return storage;
      }
    }, []);
    return validSmsRows;
  };

  const handleSelectedSmsDataFilter = () => {
    const validRows = items.filter(row => selectedData.includes(row.id));
    const validMappedRows: ContactProps[] = validRows.map(item => {
      const newItem = {
        name: item?.candidate || item?.candidateName,
        email: item?.candidateEmail,
        contactId: `${item?.candidateId}`,
        phoneNumber: formatPhoneNumber(item?.candidatePhone),
        contactType: 'candidate',
        brand: item?.brand,
        brandId: `${item.brandId}`,
        recruiter: item?.recruiterName || item?.placementRecruiter || item?.recruiter,
        recruiterId: `${item?.recruiterId || item?.placementRecruiterId || item?.recruiterData?.id}`,
      };
      return newItem;
    });

    return validMappedRows;
  };

  const handleSmsSelection = () => {
    const candidateData = handleSelectedSmsDataFilter();
    const contactIds = candidateData.map(item => item.contactId) as string[];

    if (candidateData?.length > 1) {
      dispatch(notificationDataActions.bulkSmsCandidateConsent({ contactIds, candidateData }));
    } else {
      handleSms();
    }
  }
  const enableSmsCreate = () => {
    if (user.userInfo?.phoneNumber !== undefined) {
      return true;
    } else {
      return false;
    }
  };
  return { handleCreateEmail, handleSmsSelection, handleSelectedSmsData, handleSelectedSmsDataFilter, enableSmsCreate };
};
