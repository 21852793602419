import { tooltipType } from 'app/components/Common/Tooltips';
import { FilterOptions } from 'app/models/DropDown';

export interface CustomGridProps {
  /** column headers */
  columns: any[];
  /** row data */
  rows: any[];
  /** what is being searched upon */
  searchTerm?: string;
  /** Analytics to include */
  analytics?: {
    tableName: string;
    columnSort?: boolean;
    navigation?: boolean;
    itemsPerPage?: boolean;
  };
  /** component title */
  component?: string;
  /** limit how many records are shown per page */
  fixedItemsPerPage?: number;
  /** default column to sort upon */
  defaultSortedColumn?: string | null;
  /** default direction to sort upon */
  defaultSortDirection?: 'asc' | 'desc';
  /** disable gutters for grid container */
  disableGutters?: boolean;
  /** Disable padding around the grid */
  noPadding?: boolean;
  /** enable/disable padding for table */
  fullWidth?: boolean;
  /** Have a minimum width of 100% so large tables can still take up full width and allow for expanding beyond bounds */
  minFullWidth?: boolean;
  /** toggle for showing component to control how many items per page show */
  showViewOptions?: boolean;
  /** label for what the result count is */
  resultsTitle?: string;
  /** label for what the any secondary count is */
  secondaryTitle?: string;
  /** label for what the any third count is */
  thirdTitle?: string;
  /** controls whether to show the result count */
  showCount?: boolean;
  /** Only show the title without the row count */
  showTitleWithoutCount?: boolean;
  /** control to show pagination controls */
  showPagination?: boolean;
  /** control to show what active page you are on */
  showActivePage?: boolean;
  /** toggle to control whether the table adheres to the Paper component */
  disablePaper?: boolean;
  /** Pagination custom options apart from default one's */
  pageOptions?: any[];
  /** Default page size for the grid */
  defaultPageView?: string;
  /** Unique identifier for grid*/
  gridName?: string;
  /** Optional callback function on navigation of a link */
  onNavigation?: Function;
  /** places the hoverview underneath and to the start of the table cell */
  centerHoverView?: boolean;
  /** displays morevert icon as last column */
  hasEditMenu?: boolean;
  /** displays row expand icon for first column */
  rowExpand?: boolean;
  /** Sets if the grid should allow for checkbox selections */
  enableSelectionDrawer?: boolean;
  enableTotalRecordsSelection?: boolean;
  rowSelectionKey?: string;
  selectionLimit?: number;
  rowCheckBoxSelection?: boolean;
  /** single row to search for in the grid */
  rowSearch?: any;
  /** callback when search is compelte */
  onSearchComplete?: () => void;
  /** sets minimum width on header cells */
  minCellWidth?: string;
  /** Function to call when a save is performed */
  onEditSave?: (updatedRow: any) => Promise<onEditSaveReturn>;

  onEditSaveComplete?: (rowID) => void;
  /** auto updation of verified field if specific fields are changed */
  onValueChanged?: any;
  /** Determins if a row is editable */
  canEdit?: (row: any) => boolean;
  /** Optional callback function on Pagination of a link */
  onPagination?: Function;
  onPageSizeChange?: Function;
  /** Shows page load circular progress */
  showPageLoad?: boolean;
  /** Set totalcount of records */
  totalRecordsCount?: number;
  /** Override display value for totalRecordsCount */
  totalRecordsCountDisplay?: number;
  /** For assigning pageNumber from parent */
  currentPageNumber?: number;
  /** For displaying edit icon on add pop up */
  hasSingleEditIcon?: boolean;
  /** For closing add pop up */
  onSingleEditIconClick?: any;
  /** set the row that will be editable from the start */
  initialEditingIndex?: number | null;
  /** show parent back/cancel/save buttons */
  showEditControl?: boolean;
  /** have no colors except default grid colors */
  disableRowColors?: boolean;
  /** parent save button event handler */
  onSave?: any | null;
  /** to convert grid table to sticky table */
  stickyTable?: boolean;
  /** Max height for the table when sticky headers are enabled */
  maxHeight?: string;
  /**to set up for the grid pagination in footer requirement*/
  footerVisibility?: boolean;
  /** control for whether the grid is in a dialog or not */
  isInDialog?: boolean;
  /**give line height if necessary */
  colLineHeight?: string;
  /** control the warning message if on add mode */
  addingCredential?: boolean;
  /** Result title style */
  resultTitleClass?: string;
  /**to trigger table state management */
  enableStateManagement?: boolean;
  /**to indicate server side pagination */
  serverSidePagination?: boolean;
  /** Icon with action to present at the end of a row */
  rowIconAction?: {
    icon: any;
    onClick: Function;
    title?: string;
  };
  /** Star Icon with action to present at the end of a row */
  starIconAction?: {
    onClick: Function;
    title?: string;
  };
  /** Actions to delete a row from the table */
  deleteActions?: {
    /** Called before showing the confirmation dialog to pass custom dialong content */
    willDelete: ([key]: any) => { title: string; content: any; canDeleteRecord: boolean };
    /** Called when delete is confirmed so API call can be executed */
    onDelete: ([key]: any) => Promise<boolean>;
  };

  /** State navigation link clicked. State link will only call function and not redirect to a given route */
  onStateNavigation?: (_) => void;
  /** Have the first column remain static with a fixed width */
  staticFirstColumn?: {
    width: number;
  };
  /** Adding custom filter */
  CustomizedFilter?: JSX.Element | null;
  /** Is this grid nested in another grid */
  isNested?: boolean;
  /** called when close button is clicked */
  onGridClose?: () => void;
  /** has close (X) button on top right corner of grid */
  hasCloseButton?: boolean;
  /** force updation event for data (eg., if data is provided from redux) */
  modifyData?: (data) => void;
  /** Retrieve row selection data from grid*/
  handleSelectedRows?: (_) => void;
  /** has permision to add/edit/delete*/
  hasPermisions?: {
    canAdd?: boolean;
    canDelete?: boolean;
    canEdit?: boolean;
  };
  serverSideSorting?: boolean;
  onSorting?: (
    offset: number,
    page: number,
    sortedColumn: { column: string; direction: 'asc' | 'desc' } | undefined | null,
  ) => void;

  /** Action button on top right of grid header **/
  titleActions?: JSX.Element | null;
  onElementClick?: (rowKey: number, columnKey: string, row: any) => void;
  onCheckedRows?: (selectedRows: any, isSelected?: boolean, Id?: any) => void;
  /** selected row highlighted in grid */
  highlightedRow?: number | null;
  createMessageButton?: string;
  createClientContactButtonIcon?: JSX.Element | null;
  handleCreateMessageOnlick?: () => void;
  handleCreateClientContactOnlick?: () => void;
}

export interface ICustomHeader {
  title: string;
  value: string;
  isModalOpen?: boolean;
  clickable?: boolean;
  sortable?: boolean;
  hasHoverView?: boolean;
  filterable?: boolean;
  navigationIds?: string[];
  navigationExp?: string;
  icon?: boolean;
  multiLine?: boolean;
  defaultSort?: boolean;
  sortByField?: string;
  hidden?: boolean;
  materialUIIcon?: boolean;
  control?: gridControl;
  editMenu?: boolean;
  hoverBehaviour?: gridHoverBehaviour;
  iconWithText?: boolean;
  stateNavigation?: boolean;
  cellClass?: string;
  customHoverView?: boolean;
  filterOptions?: {
    multiselect?: boolean;
    type?: gridFilterOptionType;
    customize?: {
      filterData: any[];
      filter?: Function;
    };
  };
  versioning?: boolean;
  isNumber?: boolean;
  tooltip?: tooltipType;
  tooltipAttributes?: string;
  columnHeaderHoverView?: boolean;
  columnHeaderHoverText?: string;
  colSpan?: number;
  headerIcon?: {
    redAlert?: boolean;
  };
}

export enum gridFilterOptionType {
  dropDown,
  textfield,
  textfieldNumber,
  datePicker,
  dateRange,
  dateRangeV2,
}
export interface customRows {
  rows: [];
}

export enum gridControlType {
  dropDown,
  checkbox,
  textfield,
  textfieldnumber,
  textarea,
  date,
  dateLabel,
  label,
  name,
  nameID,
  fileUpload,
}

export interface gridControl {
  type: gridControlType;
  onChange?: (prop1, prop2?, prop3?) => void;
  //drop down options
  filterOptions?: FilterOptions[];
  disableIfEmpty?: boolean;
  disabled?: boolean;
  color?: 'green' | undefined;
}

export enum gridHoverBehaviour {
  popUp,
  grid,
  glanceView,
}

export interface onEditSaveReturn {
  rowID: number;
  data: any;
  continueEditing: boolean;
}
