import { GridCellParams } from '@mui/x-data-grid-pro';
import { materialUiXGrid } from 'amn-ui-core';
import { Box, ClickAwayListener } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { DateRangePicker as DateRangeNew } from 'app/ComponentLibrary/DateRangeNew';
import { defaultDateRange } from '@AMIEWEB/Common/Textfield/Defaults';
import moment from 'moment';
import _ from 'lodash';

const useStyles = makeStyles()({
    hidden: {
        display: 'none',
    },
    input: {
        width: '100%',
        height: '30px',
        border: 'none',
        '&:focus': {
            border: '1px dotted gray',
            outline: 'none',
        },
    },
    inputInvalid: {
        color: 'red',
        padding: '10px'
    },
    inputValid: {
        color: '#a3a2a2',
    },
    textField: {
        fontSize: 12,
        width: '100%',
        '& input::placeholder': {
            fontSize: 12,
        },
    },
});

interface GridDateCellProps {
    onEndEdit: ({ value, params }: { value: any; params: GridCellParams }) => void;
    validator?: ({ value, params }: { value: any; params: GridCellParams }) => boolean;
    placeholder?: string;
    mandatory?: ({ value, params }: { value: any; params: GridCellParams }) => boolean;
    editOnSingleClick?: boolean;
    Input?: any;
    dropdownOptions?: any;
    disableCellEdit?: ({ value, params }: { value: any; params: GridCellParams }) => boolean;
    maritialOptions?: any[];
    externalValidator?: ({ value, params }: { value: any; params: GridCellParams }) => any;
    tooltip?: ({ value, params }: { value: any; params: GridCellParams }) => string;
}

export const GridDateCell =
    ({ ...props }: GridDateCellProps) =>
        (params: GridCellParams) => {
            return <XGridEditableCellContent params={params} props={props} />;
        };

const XGridEditableCellContent = ({ props, params }: { props: GridDateCellProps; params: GridCellParams }) => {
    const { classes, cx } = useStyles();
    const api = materialUiXGrid.useGridApiContext();
    const date = new Date();
    const {
        onEndEdit,
        validator,
        placeholder,
        mandatory,
        editOnSingleClick,
        disableCellEdit,
        externalValidator,
        tooltip
    } = props;
    const [firstInteraction, setFirstInteraction] = React.useState<boolean>(true);
    const [editing, setEditing] = React.useState<boolean>(false);
    const [editValue, setEditValue] = React.useState<any>(params.value);
    const [valid, setValid] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (validator && !firstInteraction) {
            setValid(validator({ value: editValue, params: params }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, params.value, validator]);

    const handleEditChange = value => {
        if (editing) {                       
            setEditValue(value);
            onEndEdit({ value: value, params: params });
            if (validator) {
                setValid(validator({ value: value, params: params }));
            }
        }
    };

    const onKeyUp = React.useCallback(
        event => {
            if (
                event.keyCode === 13 &&
                document.activeElement?.getAttribute('data-field') === params.field &&
                document.activeElement?.getAttribute('data-rowindex') ===
                `${api?.current?.getRowIndexRelativeToVisibleRows(params.id)}`
            ) {
                startEditing();
            }
        },
        [api, params.field, params.id],
    );

    React.useEffect(() => {
        document.addEventListener('keyup', onKeyUp);
        return () => {
            document.removeEventListener('keyup', onKeyUp);
        };
    }, [onKeyUp, params]);

    React.useEffect(() => {
        if (externalValidator && !firstInteraction && _.isString(params.value)) {
            setValid(externalValidator({ value: editValue, params: params }) ? false : true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.value, externalValidator]);

    const _onEndEditing = () => {
        if (editing) setEditing(false);
    };

    const checkDisabled = () => {
        if (disableCellEdit) {
            return disableCellEdit({ value: editValue, params: params });
        } else return false;
    };

    const startEditing = React.useCallback(() => {
        setFirstInteraction(false);
        if (!editOnSingleClick) setEditing(true);
        setTimeout(() => {
            if (editOnSingleClick) setEditing(true);
            document.getElementById(`grid-cell-input-${params.row.id}-${params.colDef.field}`)?.focus();
        }, 100);

    }, [editOnSingleClick, params.colDef.field, params.row.id]);

    return (
        <React.Fragment>
            <ClickAwayListener mouseEvent="onMouseDown" onClickAway={_onEndEditing}>
                {!checkDisabled() ? (
                    <div style={{ width: '100%', backgroundColor: '#fff !important' }} className={cx({ [classes.hidden]: !editing })}>
                        <DateRangeNew
                            placeholder="MM/DD/YYYY"
                            onChange={event => handleEditChange(event?.startDate?.toString() || '')}
                            value={editValue}
                            variant="standard"
                            size="inline"
                            actionBar={false}
                            single
                            clickAway
                            clear
                            trailingIconShow={true}
                            disableUnderline={true}
                            showIcon={true}
                            isDateIconReq={true}
                            isDisabled={checkDisabled()}
                            helperBackgroundColor={'transparent'}
                            initialDateRange={
                                moment(editValue).format('MM/DD/YYYY') !== 'Invalid date'
                                    ? {
                                        startDate: new Date(editValue),
                                        endDate: new Date(editValue),
                                        label: moment(editValue).format('MM/DD/YYYY'),
                                        useLabelAsValue: false,
                                    }
                                    : {
                                        startDate: new Date(),
                                        endDate: new Date(),
                                        label: '',
                                        useLabelAsValue: true,
                                    }
                            }
                            definedRanges={defaultDateRange(date)}
                        />
                    </div>
                ) : <>{editing ? editValue : ''}</>
                }
            </ClickAwayListener>
            <Box
                onClick={editOnSingleClick ? startEditing : undefined}
                className={cx({
                    [classes.hidden]: editing,
                    [classes.inputInvalid]: !valid || params.row[`${params.field}_error`] === true,
                })}
                style={{ width: '100%', minHeight: '20px' }}
            >
                {editValue ? (
                    externalValidator?.({ value: editValue, params: params }) ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>                            
                            <span
                                className={cx({
                                    [classes.inputInvalid]: !valid || params.row[`${params.field}_error`] === true,
                                    [classes.inputValid]: valid && !params.row[`${params.field}_error`],
                                })}
                            >
                                {moment(new Date(editValue)).format('MM/DD/YYYY')}
                            </span>
                        </div>
                    ) : (
                        <span style={{ padding: '10px' }}> {editValue === '--' ? '--' : moment(new Date(editValue)).format('MM/DD/YYYY')} </span>
                    )
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>                        
                        <CustomTooltip
                            disabled={tooltip?.({ value: params.value, params })?.length < 1}
                            tooltipContent={tooltip?.({ value: params.value, params })}
                        >
                            <span
                                className={cx({
                                    [classes.inputInvalid]: !valid || params.row[`${params.field}_error`] === true,
                                    [classes.inputValid]: valid && !params.row[`${params.field}_error`],
                                })}
                            >{`${placeholder || params.colDef.headerName}`}</span>
                        </CustomTooltip>
                    </div>
                )}
            </Box>
        </React.Fragment>
    );
};