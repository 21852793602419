import React, { useEffect, useMemo, useState } from 'react';
import { Box, IconButton, Typography, XGrid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { XGridDefaultColumn } from '@AMIEWEB/Common/XGrid/cells/XGridDefaults';
import { useDispatch, useSelector } from 'react-redux';
import { isUploadDocumentModalOpen, selectApprovalDocuments } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import {
  facilityActions,
  IApprovalDocuments,
  IRequestApprovalDocument,
} from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import FileIcon from 'app/assets/images/description-24px.svg';
import { usePromiseTracker } from 'react-promise-tracker';
import { formatDate } from '@AMIEWEB/Order/OrderDetails/helper';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useTranslation } from 'react-i18next';
import { WhiteTooltip } from '@AMIEWEB/Common';
import { UploadApprovalDocuments } from '../OffContractTable/UploadApprovalDocuments';
import { selectUser } from 'oidc/user.selectors';
import { selectGridSelection } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.selector';
import { isAccountManagement, isClientContractAdminUser, isReadOnly } from 'oidc/userRoles';

const useStyles = makeStyles<{ rows }>()((theme, props) => ({
  grid: {
    width: '31rem',
    border: 'none',
    fontSize: '14px',
    '& .MuiDataGrid-row': {
      borderBottom: '1px solid #D5D5D5',
    },
    '& .MuiDataGrid-virtualScroller': {
      maxHeight: !props.rows || props.rows?.length === 0 ? '39px' : 'auto',
      height: !props.rows || props.rows?.length === 0 ? '39px' : 'auto',
      borderBottom: '1px solid #D5D5D5',
    },
  },
  documentName: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  iconButton: {
    padding: '0px',
    // '&:hover': {
    //   color: '#0098DB',
    // },
  },
}));

export const ApprovalDocuments = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { promiseInProgress: isCallingAPI } = usePromiseTracker({
    area: `get-approval-document-${props?.row?.rateElementId}`,
    delay: 0,
  });
  const documents = useSelector(selectApprovalDocuments);
  const appUser = useSelector(selectUser);
  const { selectedData: gridSelections } = useSelector(selectGridSelection);
  const [allAttachments, setAllAttachment] = useState<IApprovalDocuments[]>([]);
  const [rows, setRows] = useState([]);
  const [uploadDocumentOpen, setUploadDocumentOpen] = useState(false);
  const [sortModel, setSortModel] = React.useState<any>([
    {
      field: 'dateAdded',
      sort: 'desc',
    },
  ]);
  const { classes } = useStyles({ rows: rows });
  const rowHeight = 39;
  const handleSortModelChange = model => {
    setSortModel(model);
  };

  const handleUploadDocumentBtn = () => {
    dispatch(facilityActions.setUploadDocumentModalOpen(!uploadDocumentOpen));
    setUploadDocumentOpen(!uploadDocumentOpen);
  };

  const isValidUserRole = props => {
    const isValid =
      (isAccountManagement(props?.userInfo?.roles) || isClientContractAdminUser(props?.userInfo?.roles)) &&
      !isReadOnly(props?.userInfo?.roles);
    return isValid;
  };

  const approvalDocumentGridColumns: any = [
    {
      ...XGridDefaultColumn,
      field: 'fileName',
      headerName: 'Document Type',
      headerClassName: 'super-app-theme--header',
      renderCell: params => {
        const link = params.row.saasAttachmentUrl;
        return (
          <div>
            <img src={FileIcon} style={{ height: '24px', width: '24px', paddingRight: '5px' }} />
            <a
              href={link}
              className={classes.documentName}
              style={{ color: '#006FB9' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {params.value}
            </a>
          </div>
        );
      },
    },
    {
      ...XGridDefaultColumn,
      field: 'documentRef',
      headerName: 'Document ID',
      headerClassName: 'super-app-theme--header',
      align: 'left',
      headerAlign: 'left',
      flex: 0,
      width: 100,
    },
    {
      ...XGridDefaultColumn,
      field: 'dateAdded',
      headerName: 'Date Added',
      headerClassName: 'super-app-theme--header',
      flex: 0,
      width: 150,
      renderCell: params => {
        const date = new Date(params.value);
        const formattedTime = date.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        });
        return `${formatDate(params.value)} ${formattedTime}`;
      },
    },
  ];

  useEffect(() => {
    setAllAttachment([]);
    setRows([]);
    const payload: IRequestApprovalDocument = {
      facilityId: props.facilityId,
      rateElementId: props?.row?.rateElementId,
    };
    dispatch(facilityActions.getApprovalDocuments(payload));
  }, []);

  useEffect(() => {
    if (documents) {
      if (allAttachments && allAttachments?.length > 0) {
        const rowExist = allAttachments?.find(item => item?.rateElementId === documents?.rateElementId);
        if (rowExist) {
          const index = allAttachments.findIndex(elem => elem?.rateElementId === documents?.rateElementId);
          if (index !== -1) {
            allAttachments[index] = documents;
            setAllAttachment([...allAttachments]);
          }
        } else {
          setAllAttachment([...allAttachments, documents]);
        }
      } else {
        setAllAttachment([documents]);
      }
    }
  }, [documents]);

  useEffect(() => {
    if (allAttachments?.length > 0 && !isCallingAPI) {
      const filteredAttachment = allAttachments?.find(doc => doc?.rateElementId === props?.row?.rateElementId);
      setRows(filteredAttachment?.attachments);
      const moreThanOneElements = filteredAttachment?.attachments
        ? filteredAttachment?.attachments?.length > 1
          ? true
          : false
        : false;
      const gh = rowHeight * (moreThanOneElements ? filteredAttachment?.attachments?.length : 1) + 140;
      props.setApprovalDocumentsHeight(gh);
    }
  }, [allAttachments, isCallingAPI]);

  const uploadDoc = useMemo(() => {
    return (
      <React.Fragment>
        <UploadApprovalDocuments
          appUser={appUser}
          facilityId={props.facilityId}
          addDocsModalOpen={uploadDocumentOpen}
          setAddDocsModalOpen={setUploadDocumentOpen}
          gridSpecs={{ gridSelections, rows }}
          displayDescription={false}
          existingAttachments={
            allAttachments?.find(item => item.rateElementId === props?.row?.rateElementId)?.attachments
          }
          isEditing={true}
          row={props?.row}
        />
      </React.Fragment>
    );
  }, [allAttachments, props?.row, uploadDocumentOpen]);
  return (
    <React.Fragment>
      <div style={{  width: '31rem', display: 'flex', justifyContent: 'space-between' }}>
        <Typography style={{ paddingBottom: '7px', fontSize: '14px', fontWeight: 600 }}>
          {t('notification.facilityDetials.approval')}
        </Typography>
        {props?.row?.statusId === 2 && isValidUserRole(appUser) && (
          <WhiteTooltip title={'Upload Approval Documents'}>
            <IconButton color="secondary" size="small" className={classes.iconButton} onClick={handleUploadDocumentBtn} disabled={isCallingAPI}>
              <CloudUploadOutlinedIcon />
            </IconButton>
          </WhiteTooltip>
        )}
      </div>
      <Box
        sx={{
          width: '100%',
          '& .super-app-theme--header': {
            backgroundColor: '#F5F5F5',
          },
        }}
      >
        <XGrid
          autoHeight={true}
          className={classes.grid}
          columns={approvalDocumentGridColumns}
          rows={rows || []}
          headerHeight={48}
          rowHeight={rowHeight}
          loading={isCallingAPI}
          hideFooter={true}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          disableColumnSelector
          disableMultipleSelection={true}
          disableSelectionOnClick={true}
        />
      </Box>
      {uploadDoc}
    </React.Fragment>
  );
};
