import { Divider } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectMiscellaneousData,
  selectNewPlacementChoice,
} from '../../../../../store/redux-store/new-placement/selectors';
import Tungsten from 'app/assets/images/IconImages/tungsten.svg';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomSelect } from '../CustomComponents/CustomSelect';

const useStyles = makeStyles()({
  infoTip: {
    display: 'flex',
    flexDirection: 'row',
    gap: 6,
  },
  infoIcon: {
    display: 'flex',
  },
  tipMessage: {
    color: '#333333',
    fontSize: 12,
  },
});

export const SelectPlacementSkillsets = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { control } = useFormContext();
  const { candidate: selectedCandidate, order: selectedOrder, newPlacementId } = useSelector(selectNewPlacementChoice);
  const { orderSkillOpts: options } = useSelector(selectMiscellaneousData);

  return selectedOrder && selectedCandidate && options.length > 1 ? (
    <>
      <Divider style={{ margin: '8px 0px' }} />
      <LayoutGridItem container spacing={2} direction="column">
        <LayoutGridItem classes={{ root: classes.infoTip }}>
          <span className={classes.infoIcon}>
            <img src={Tungsten} alt="Tungsten" />
          </span>
          <span className={classes.tipMessage}>
            {t('placement.create.popup.skillSetTip1')}
            <br />
            <span className={classes.tipMessage}>{t('placement.create.popup.skillSetTip2')}</span>
          </span>
        </LayoutGridItem>
        <LayoutGridItem>
          <Controller
            control={control}
            name="skillset"
            render={({ ref, ...rest }) => (
              <CustomSelect
                options={options}
                label={t('placement.create.popup.skillSet')}
                disabled={newPlacementId !== null}
                {...rest}
              />
            )}
          />
        </LayoutGridItem>
      </LayoutGridItem>
    </>
  ) : null;
};
