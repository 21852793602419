/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Grid, ThemeProvider, StyledEngineProvider, useTheme, Chip, Avatar } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { reqPref } from 'app/enums/Unit';
import { FormProvider, useForm } from 'react-hook-form';
import { ReqPrefSection } from '../Common/ReqPrefSection';
import { EMRType } from 'app/models/Unit/CreateUnit';
import { IToggleProperty, ToggleNoSelection } from '../ToggleNoSelection';
import { UnitCardsTitle } from '../UnitDesign';
import { useSelector } from 'react-redux';
import { selectIsEditUnit, selectOrderCreationProcessStep } from 'app/components/Order/Store/Order.selectors';
import { formatChipLabel } from '../helper';
import { ControlledTypeAheadWithCheckBox } from 'app/components/Order/OrderCreationForm/OrderDetails/ControlledTypeAheadWithCheckBox';
import { GetEMRValues } from 'app/components/Order/OrderCreationForm/OrderDetails/FormatData';
import { SimpleCheckbox } from '../Common/SimpleCheckbox';

const useStyles = makeStyles()(theme => ({
  topWrapper: {
    paddingTop: 20,
  },
  emrTitle: {
    marginRight: theme.spacing(3),
  },

  chip: {
    maxWidth: '50ch',
    margin: '0 5px 10px 0',
  },
}));

interface IEmr {
  onEmrPropChange: (property: string, emrList: any) => void;
  isdisabled?: boolean;
  hasRadio?: boolean;
  EMRData?: any | null;
}

export const EMR = (props: IEmr) => {
  const { onEmrPropChange, isdisabled, EMRData } = props;
  const { classes } = useStyles();
  const formMethods = useForm();
  const theme = useTheme();
  const [showPanel, setShowPanel] = React.useState<boolean>(false);
  const [yesNoOptionSelected, setYesNoOptionSelected] = React.useState<boolean>(
    EMRData && EMRData.values && EMRData.values.length > 0 ? true : false,
  );
  const emrOptions = GetEMRValues();
  const initialData = EMRData
    ? EMRData
    : {
        isRequired: true,
        values: [],
      };
  const [emrList, setEmrList] = React.useState<EMRType>(initialData);
  const isEditUnit = useSelector(selectIsEditUnit);
  const storeOrderCreationProcessStep = useSelector(selectOrderCreationProcessStep);

  useEffect(() => {
    if (EMRData) {
      setEmrList(EMRData);
    }
    if (
      yesNoOptionSelected ||
      ((isEditUnit || storeOrderCreationProcessStep) && EMRData && EMRData.values && EMRData.values.length > 0)
    ) {
      setShowPanel(true);
    } else {
      setShowPanel(false);
    }
  }, []);

  const getSelectedValue = () => {
    return emrOptions.filter(p => emrList.values.includes(p.label));
  };

  const handleDelete = emr => {
    const updatedValue = {
      isRequired: emrList.isRequired,
      values: emrList.values.filter(t => t != emr),
    };
    setEmrList(updatedValue);
  };

  const toggleData: IToggleProperty = {
    showPanel,
    setShowPanel,
    onUnitPropChange: onEmrPropChange,
    propertyName: 'emr',
    propertyValue: emrList,
    propDefaultValue: null,
  };
  const toggleNoSelection = ToggleNoSelection(toggleData);

  const handleChange = values => {
    const selectedValues = values.map(value => value.label);
    setEmrList({ ...emrList, values: selectedValues });
  };

  const handleRPChange = event => {
    const v = event.target.value === reqPref.req;
    setEmrList({ ...emrList, isRequired: v });
  };

  const handleCheckBoxChangeX = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleNoSelection.handleShowPanelX(event.target.checked);
    setYesNoOptionSelected(event.target.checked);
  };

  useEffect(() => {
    toggleNoSelection.onPropChange(showPanel, undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emrList]);

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <FormProvider {...formMethods}>
            <Grid container direction="row" className={classes.topWrapper}>
              <Grid item>
                <UnitCardsTitle className={classes.emrTitle}>{`EMR`}</UnitCardsTitle>
              </Grid>
              <SimpleCheckbox
                id={'createUniEMREnableCheck'}
                isChecked={yesNoOptionSelected ? true : false}
                isdisabled={isdisabled}
                hasRadio={false}
                handleChecked={handleCheckBoxChangeX}
                checkboxLabel={`Yes`}
                checkboxName={'no-cbx'}
              />
            </Grid>
            {showPanel && (
              <Grid container item direction="column">
                <ReqPrefSection
                  isRequired={emrList.isRequired}
                  handleReqPref={handleRPChange}
                  isdisabled={isdisabled}
                  id={'createUnitEMRReqPrefRadio'}
                />
                <Grid item xs={3} style={{ paddingBottom: '13px' }}>
                  <ControlledTypeAheadWithCheckBox
                    id={'createUnitEMRSelectCheckbox'}
                    register={formMethods.register}
                    name="emr"
                    label={`EMR`}
                    control={formMethods.control}
                    isdisabled={isdisabled}
                    options={emrOptions}
                    onChange={handleChange}
                    defaultValue={emrList.values as any}
                    selectedvalue={getSelectedValue()}
                  />
                </Grid>
              </Grid>
            )}
          </FormProvider>
        </ThemeProvider>
      </StyledEngineProvider>
      {showPanel &&
        emrList &&
        emrList.values.map((emrChip, index) => {
          return (
            <Chip
              disabled={isdisabled}
              variant="outlined"
              key={index}
              avatar={
                <Avatar variant={emrList.isRequired ? 'primary' : 'green'}>{emrList.isRequired ? 'R' : 'P'}</Avatar>
              }
              label={
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                  <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{formatChipLabel(emrChip)}</div>
                </div>
              }
              className={classes.chip}
              onDelete={() => handleDelete(emrChip)}
            />
          );
        })}
    </>
  );
};
