import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, CircularProgress, ClickAwayListener, Paper, Skeleton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { CredentialsGrid, CredentialsGridType } from './CredentialsGrid';
import { useTranslation } from 'react-i18next';
import { FilterOptions } from 'app/models/DropDown';
import { CredentialingLookup } from 'app/models/Credentialing/CredentialingLookups';
import { CredentialsSearch } from './Search/CredentialsSearch';
import { CredentialsAdd } from './AddCredential/CredentialsAdd';
import { addCredentialLocal, findCredentialByRequirementID } from './CredentialsHelpers';
import _ from 'lodash';
import {
  CredentialingLookups as CredentialingLookupsSelection,
  CredentialsReloadData,
  CredentialsSelection,
  candidateDetailsSelection,
} from '../../Profile/CandidateDetails.selector';
import { candidateDetailActions } from '../../Profile/CandidateDetails.redux';
import { Credentials } from 'app/models/Credentialing/Credentials';
import queryString from 'query-string';
import { nestedChildren } from 'app/helpers/arrayHelpers';
import { groupedLicensure } from './TableData/Licensure';
import { groupedClinicalQualification } from './TableData/ClinicalQualifications/ClinicalQualifications';
import { usePageVisitTimeTracker } from 'app-insights/appInsightsTracking';
import { gridTypeToCategoryString } from './Search/searchHelpers';
import { gridStateActions, GridTag } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import { selectGridState } from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import { groupedHumanResources } from './TableData/HumanResources/HumanResourcesGroupingHelper';
import { groupedCertifications } from './TableData/Certifications/Certfications';
import { groupedLifeCertifications } from './TableData/Certifications/LifeSupportCertifications';
import { groupedOtherCertifications } from './TableData/Certifications/OtherCertifications';
import { groupedEmployeeHealths } from './TableData/EmployeeHealth/EmployeeHealth';
import { groupedEducations } from './TableData/EducationAndOnlineForms';
import { groupedFacilityMiscellaneous } from './TableData/Miscellaneous/FacilityTrackingSheet';
import { groupedClientMiscellaneous } from './TableData/Miscellaneous/ClientSpecificContractEnclosures';
import { groupedManagerMiscellaneous } from './TableData/Miscellaneous/ManagerReviewSensitiveAccount';
import { groupedPendingMiscellaneous } from './TableData/Miscellaneous/PendingMoleculeDevelopment';
import { GenericTabs } from '@AMIEWEB/Common/Tabs/GenericTabs';
import { candidateProfileDetailsActions } from 'store/redux-store/candidate-details/slice';
import { useParams } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    width: '100%',
  },
  tabContainer: {
    width: '250px !important',
    marginRight: '12px',
  },
  tab: {
    '& .MuiButton-label': {
      justifyContent: 'start',
    },
    width: '100%',
    wordBreak: 'keep-all',
    textAlign: 'start',
    margin: '25px 0px',
  },
  tabLabel: {
    padding: '10 0px 5px 0px',
    fontSize: '14px',
  },
  tabRenderContainer: {
    flex: 1,
    width: '100%',
    overflowX: 'hidden',
  },
  selectedTab: {
    borderBottom: '5px solid #52429A',
  },
  unselectedTab: {
    borderBottom: '5px solid transparent',
  },
  selectable: {
    cursor: 'pointer',
  },
  centerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  fullHeight: {
    height: '100%',
  },
  topBar: {
    marginTop: '12px',
    display: 'flex',
    width: '100%',
  },
  searchBar: {
    width: '80%',
    marginRight: '3%',
  },
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50vh',
  },
});

const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_PAGE_NUMBER = 1;

export const CredentialsTab = props => {
  const { classes } = useStyles();
  const [tabs, setTabs] = useState<{ label: string; value: number; subCategories?: FilterOptions[] }[]>([]);
  const [subCategory, setSubCategory] = useState<FilterOptions[]>();
  const [value, setValue] = useState<CredentialsGridType>(CredentialsGridType.licensure);
  const [subCategorySelection, setSubCategorySelection] = useState<any>(null);
  const [credentialingLookups, setCredentialingLookups] = useState<CredentialingLookup[] | null>(null);
  const [loadingLookups, setLoadingLookups] = useState(false);
  const [count, setCount] = useState<any>('');
  const [searchInput, setSearchInput] = useState<string | any>(null);
  const [searchResult, setSearchResult] = useState<any>(null);
  const [popCount, setPopCount] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [addPopUp, setAddPopUp] = useState<boolean>(false);
  const lookupselection = useSelector(CredentialingLookupsSelection);
  const credentials = useSelector(CredentialsSelection);
  const [reloadGrid, setReloadGrid] = useState<boolean>(false);
  const [searchWithRequirementID, setSearchWithRequirementID] = useState<number | null>(null);
  const [valueTabs, setValueTabs] = useState(0);
  usePageVisitTimeTracker('Candidate Details:Credentials Tab');
  const gridState = useSelector(selectGridState);
  const reloadData = useSelector(CredentialsReloadData);
  const candidateDetails = useSelector(candidateDetailsSelection);

  const { promiseInProgress } = usePromiseTracker({ area: 'get-candidate-credentials-tab-details', delay: 0 });
  const { promiseInProgress: lookUpPromiseInProgress } = usePromiseTracker({
    area: 'get-candidate-credentials-lookup-details',
    delay: 0,
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const params = useParams<{
    travelerId: string;
    brandId: string;
  }>();

  useEffect(() => {
    dispatch(
      candidateProfileDetailsActions.getCandidateCredentialsTabDetails({
        candidateId: parseInt(params.travelerId),
        brandId: parseInt(params.brandId),
      }),
    );

    const rid = queryString.parse(window.location.search).rid;

    if (rid !== null && rid !== undefined) {
      setSearchWithRequirementID(parseInt(rid));
    } else setSearchWithRequirementID(null);
    //eslint(react-hooks/exhaustive-deps)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchWithRequirementID) {
      const tempSearchData = findCredentialByRequirementID(searchWithRequirementID, credentials);
      onSearch(tempSearchData);
      window.history.pushState(null, '', `${window.location.pathname}?tab=1`);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWithRequirementID]);

  useEffect(() => {
    if (
      (value !== CredentialsGridType.clinicalQualificationsParent &&
        subCategorySelection === null &&
        value !== CredentialsGridType.certificationsParent &&
        subCategorySelection === null &&
        value !== CredentialsGridType.humanResourcesParent &&
        subCategorySelection === null &&
        value !== CredentialsGridType.employeeHealthParent &&
        subCategorySelection === null &&
        value !== CredentialsGridType.miscellaneous &&
        subCategorySelection === null) ||
      (value === CredentialsGridType.clinicalQualificationsParent && subCategorySelection !== null) ||
      (value === CredentialsGridType.certificationsParent && subCategorySelection !== null) ||
      (value === CredentialsGridType.humanResourcesParent && subCategorySelection !== null) ||
      (value === CredentialsGridType.employeeHealthParent && subCategorySelection !== null) ||
      (value === CredentialsGridType.miscellaneous && subCategorySelection !== null)
    ) {
      setLoadingLookups(true);
      const cat = subCategorySelection
        ? gridTypeToCategoryString(subCategorySelection!, false)
        : gridTypeToCategoryString(value, false);
      const lookups = lookupselection
        ?.filter(item => item.category === cat)
        .sort((a, b) => (a.sequence < b.sequence ? 1 : -1));
      setCredentialingLookups(lookups as CredentialingLookup[]);
      setLoadingLookups(false);
    }
  }, [value, subCategorySelection, credentials, lookupselection]);

  const getTabList = React.useCallback(
    creds => {
      return [
        {
          label: `${t('candidate.credentials.types.licensure')} (${getCountOfCategory(creds?.licenses, 'Licenses')})`,
          value: CredentialsGridType.licensure,
        },
        {
          label: `${t('candidate.credentials.types.clinicalQualifications')} (${getCountOfCategory(
            creds?.clinicalQualifications,
            'Clinical Qualifications',
          )})`,
          value: CredentialsGridType.clinicalQualificationsParent,
          subCategories: [
            {
              description: `${t('candidate.credentials.types.clinicalQualifications')} (${getCountOfSubCategory(
                creds?.clinicalQualifications,
                'Clinical Qualifications',
              )})`,
              value: CredentialsGridType.clinicalQualifications,
            },
            {
              description: `${t('candidate.credentials.types.skillsChecklists')} (${getCountOfSubCategory(
                creds?.clinicalQualifications,
                'Skills Checklist',
              )})`,
              value: CredentialsGridType.clinicalQualificationsSkillsChecklists,
            },

            {
              description: `${t('candidate.credentials.types.assessments')} (${getCountOfSubCategory(
                creds?.clinicalQualifications,
                'Assessment',
              )})`,
              value: CredentialsGridType.clinicalQualificationsAssessments,
            },
          ],
        },
        {
          label: `${t('candidate.credentials.types.certifications')} (${getCountOfCategory(
            creds?.certifications,
            'Certifications',
          )})`,
          value: CredentialsGridType.certificationsParent,
          subCategories: [
            {
              description: `${t('candidate.credentials.types.certifications')} (${getCountOfSubCategory(
                creds?.certifications,
                'Certifications',
              )})`,
              value: CredentialsGridType.certifications,
            },
            {
              description: `${t('candidate.credentials.types.lifeSupportCertifications')} (${getCountOfSubCategory(
                creds?.certifications,
                'Life Support Certification',
              )})`,
              value: CredentialsGridType.certificationsLifeSupportCertifications,
            },
            {
              description: `${t('candidate.credentials.types.otherCertifications')} (${getCountOfSubCategory(
                creds?.certifications,
                'Other Certifications',
              )})`,
              value: CredentialsGridType.certificationsOtherCertifications,
            },
          ],
        },
        {
          label: `${t('candidate.credentials.types.humanResources')} (${getCountOfMultipleSubCategories(
            creds?.humanResourcesCredentials,
            ['Background Checks', 'Drug Screens', 'Identifications', 'Forms'],
            'Human Resources',
          )})`,
          value: CredentialsGridType.humanResourcesParent,
          subCategories: [
            {
              description: `${t('candidate.credentials.types.backgroundChecks')} (${getCountOfSubCategory(
                creds?.humanResourcesCredentials,
                'Background Checks',
              )})`,
              value: CredentialsGridType.humanResourcesBackgroundChecks,
            },
            {
              description: `${t('candidate.credentials.types.drugScreens')} (${getCountOfSubCategory(
                creds?.humanResourcesCredentials,
                'Drug Screens',
              )})`,
              value: CredentialsGridType.humanResourcesDrugScreens,
            },
            {
              description: `${t('candidate.credentials.types.identifications')} (${getCountOfSubCategory(
                creds?.humanResourcesCredentials,
                'Identifications',
              )})`,
              value: CredentialsGridType.humanResourcesIdentifications,
            },
            {
              description: `${t('candidate.credentials.types.forms')} (${getCountOfSubCategory(
                creds?.humanResourcesCredentials,
                'Forms',
              )})`,
              value: CredentialsGridType.humanResourcesForms,
            },
          ],
        },
        {
          label: `${t('candidate.credentials.types.educationAndOnlineForms')} (${getCountOfCategory(
            creds?.educationOnlineForms,
            'Education & Online Exams',
          )})`,
          value: CredentialsGridType.educationAndOnlineForms,
        },
        {
          label: `${t('candidate.credentials.types.employeeHealth')} (${getCountOfCategory(
            creds?.employeeHealth,
            'Employee Health',
          )})`,
          value: CredentialsGridType.employeeHealthParent,
          subCategories: [
            {
              description: `${t('candidate.credentials.types.employeeHealthImmunizations')} (${getCountOfSubCategory(
                creds?.employeeHealth,
                'Immunizations & Titers',
              )})`,
              value: CredentialsGridType.employeeHealthImmunizations,
            },
            {
              description: `${t('candidate.credentials.types.employeeHealthLabResults')} (${getCountOfSubCategory(
                credentials?.employeeHealth,
                'Lab Results',
              )})`,
              value: CredentialsGridType.employeeHealthLabResults,
            },
            {
              description: `${t('candidate.credentials.types.employeeHealthMedicalRecords')} (${getCountOfSubCategory(
                creds?.employeeHealth,
                'Medical Records',
              )})`,
              value: CredentialsGridType.employeeHealthMedicalRecords,
            },
          ],
        },
        {
          label: `${t('candidate.credentials.types.miscellaneous')} (${getCountOfCategory(
            creds?.miscellaneous,

            'Miscellaneous',
          )})`,
          value: CredentialsGridType.miscellaneous,
          subCategories: [
            {
              description: `${t('candidate.credentials.types.pendingMoleculeDevelopment')} (${getCountOfSubCategory(
                creds?.miscellaneous,
                'Pending Molecule Development',
              )})`,
              value: CredentialsGridType.miscellaneousPendingMoleculeDevelopment,
            },

            {
              description: `${t(
                'candidate.credentials.types.clientSpecificContractEnclosures',
              )} (${getCountOfSubCategory(creds?.miscellaneous, 'Client Specific Contract Enclosure(s)')})`,
              value: CredentialsGridType.miscellaneousClientSpecificContractEnclosures,
            },
            {
              description: `${t('candidate.credentials.types.facilityTrackingSheet')} (${getCountOfSubCategory(
                creds?.miscellaneous,
                'Facility Tracking Sheet',
              )})`,
              value: CredentialsGridType.miscellaneousFacilityTrackingSheet,
            },
            {
              description: `${t('candidate.credentials.types.managerReviewSensitiveAccount')} (${getCountOfSubCategory(
                creds?.miscellaneous,
                'Manager Review-Sensitive Account',
              )})`,
              value: CredentialsGridType.miscellaneousManagerReviewSensitiveAccount,
            },
          ],
        },
      ];
    },
    [credentials, t],
  );

  useEffect(() => {
    const currentTabList = getTabList(credentials);
    setTabs(currentTabList);

    const tab = currentTabList.find(item => item.value === value);

    if (tab?.subCategories?.length > 0) {
      setSubCategory(tab.subCategories.map(x => ({ ...x, label: x.description })));
    }
  }, [credentials, count, getTabList, value, reloadData]);

  useEffect(() => {
    if (props.credentialTabSelection) {
      handleTabChange(props.credentialTabSelection, props?.credentialSubCategory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.credentialTabSelection, props?.credentialSubCategory]);

  useEffect(() => {
    if (candidateDetails?.travelerId) {
      dispatch(candidateDetailActions.getAllSkillChecklists());
    }
  }, [candidateDetails?.travelerId]);

  useEffect(() => {
    let columnSort: boolean = false; // To avoid subcategory for issue when no expirationDate Col.
    if (subCategory !== undefined && subCategory?.length > 0 && subCategorySelection === null) {
      setSubCategorySelection(subCategory[0]?.value);
      setValueTabs(0);
    } else if (subCategorySelection !== null) {
      switch (subCategorySelection) {
        case CredentialsGridType?.clinicalQualifications:
          setValueTabs(0);
          break;
        case CredentialsGridType?.clinicalQualificationsSkillsChecklists:
          setValueTabs(1);
          columnSort = true;
          break;
        case CredentialsGridType?.clinicalQualificationsAssessments:
          setValueTabs(2);
          columnSort = true;
          break;
        case CredentialsGridType?.certifications:
          setValueTabs(0);
          break;
        case CredentialsGridType?.certificationsOtherCertifications:
          setValueTabs(2);
          break;
        case CredentialsGridType?.certificationsLifeSupportCertifications:
          setValueTabs(1);
          break;
        case CredentialsGridType?.humanResourcesBackgroundChecks:
          setValueTabs(0);
          break;
        case CredentialsGridType?.humanResourcesDrugScreens:
          setValueTabs(1);
          break;
        case CredentialsGridType?.humanResourcesIdentifications:
          setValueTabs(2);
          break;
        case CredentialsGridType?.humanResourcesForms:
          setValueTabs(3);
          break;
        case CredentialsGridType?.miscellaneousFacilityTrackingSheet:
          setValueTabs(2);
          columnSort = true;
          break;
        case CredentialsGridType?.miscellaneousClientSpecificContractEnclosures:
          setValueTabs(1);
          break;
        case CredentialsGridType?.miscellaneousManagerReviewSensitiveAccount:
          setValueTabs(3);
          columnSort = true;
          break;
        case CredentialsGridType?.miscellaneousPendingMoleculeDevelopment:
          setValueTabs(0);
          break;
        case CredentialsGridType?.employeeHealthLabResults:
          setValueTabs(1);
          columnSort = true;
          break;
        case CredentialsGridType?.employeeHealthMedicalRecords:
          setValueTabs(2);
          columnSort = true;
          break;
        case CredentialsGridType?.employeeHealthImmunizations:
          setValueTabs(0);
          columnSort = true;
          break;
      }
    }

    //Need for each category grid.
    if (gridState.gridTag !== GridTag.CredentialsGrid)
      dispatch(
        gridStateActions.setGridState({
          gridTag: GridTag.CredentialsGrid,
          pageSize: DEFAULT_PAGE_SIZE,
          pageNumber: DEFAULT_PAGE_NUMBER,
          previewItemId: -1,
          showPreviewDialog: false,
          sortedColumn: { column: columnSort ? 'dateCompleted' : 'expirationDate', direction: 'asc' },
          filterAttributes: undefined,
        }),
      );

    return () => {
      //Action dispatched to reset grid state
      dispatch(gridStateActions.reset());
    };

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategory, subCategorySelection]);

  //#region Helper functions
  const getCountOfCategory = (list, countType) => {
    switch (countType) {
      case 'Licenses':
        return Object.entries(groupedLicensure(list)).length;
      case 'Clinical Qualifications':
        return Object.entries(groupedClinicalQualification(list)).length;
      case 'Certifications':
        return Math.floor(
          nestedChildren(groupedCertifications(list.filter(item => item.subCategory === 'Certifications'))).length +
            nestedChildren(
              groupedLifeCertifications(list.filter(item => item.subCategory === 'Life Support Certification')),
            ).length +
            nestedChildren(groupedOtherCertifications(list.filter(item => item.subCategory === 'Other Certifications')))
              .length,
        );
      case 'Education & Online Exams':
        return Object.entries(groupedEducations(list)).length;
      case 'Employee Health':
        return Object.entries(groupedEmployeeHealths(list)).length;
      case 'Miscellaneous':
        return Math.floor(
          nestedChildren(
            groupedPendingMiscellaneous(list.filter(item => item.subCategory === 'Pending Molecule Development')),
          ).length +
            nestedChildren(
              groupedManagerMiscellaneous(list.filter(item => item.subCategory === 'Manager Review-Sensitive Account')),
            ).length +
            nestedChildren(
              groupedClientMiscellaneous(
                list.filter(item => item.subCategory === 'Client Specific Contract Enclosure(s)'),
              ),
            ).length +
            nestedChildren(
              groupedFacilityMiscellaneous(list.filter(item => item.subCategory === 'Facility Tracking Sheet')),
            ).length,
        );

      default:
        return (list || []).filter(item => item.category === countType).length;
    }
  };

  const getCountOfMultipleSubCategories = (list, countType: string[], parentcountType) => {
    switch (parentcountType) {
      case 'Human Resources':
        let total = 0;
        countType?.forEach(type => {
          total += nestedChildren(groupedHumanResources(list, `${type}`)).filter(
            item => item.subCategory === type,
          ).length;
        });
        return total;

      default:
        return (list || []).filter(item => countType.includes(item.subCategory)).length;
    }
  };

  const getCountOfSubCategory = (list, countType) => {
    switch (countType) {
      case 'Clinical Qualifications':
      case 'Skills Checklist':
      case 'Assessment':
        return nestedChildren(groupedClinicalQualification(list.filter(item => item.subCategory === countType))).length;
      case 'Immunizations & Titers':
      case 'Lab Results':
      case 'Medical Records':
        return nestedChildren(groupedEmployeeHealths(list.filter(item => item.subCategory === countType))).length;
      case 'Certifications':
        return nestedChildren(groupedCertifications(list.filter(item => item.subCategory === countType))).length;
      case 'Life Support Certification':
        return nestedChildren(groupedLifeCertifications(list.filter(item => item.subCategory === countType))).length;
      case 'Other Certifications':
        return nestedChildren(groupedOtherCertifications(list.filter(item => item.subCategory === countType))).length;
      case 'Background Checks':
        return nestedChildren(groupedHumanResources(list, 'Background Checks')).filter(
          item => item.subCategory === countType,
        ).length;
      case 'Drug Screens':
        return nestedChildren(groupedHumanResources(list, 'Drug Screens')).filter(
          item => item.subCategory === countType,
        ).length;
      case 'Identifications':
        return nestedChildren(groupedHumanResources(list, 'Identifications')).filter(
          item => item.subCategory === countType,
        ).length;
      case 'Forms':
        return nestedChildren(groupedHumanResources(list, 'Forms')).filter(item => item.subCategory === countType)
          .length;
      case 'Facility Tracking Sheet':
        return nestedChildren(groupedFacilityMiscellaneous(list.filter(item => item.subCategory === countType))).length;
      case 'Client Specific Contract Enclosure(s)':
        return nestedChildren(groupedClientMiscellaneous(list.filter(item => item.subCategory === countType))).length;
      case 'Manager Review-Sensitive Account':
        return nestedChildren(groupedManagerMiscellaneous(list.filter(item => item.subCategory === countType))).length;
      case 'Pending Molecule Development':
        return nestedChildren(groupedPendingMiscellaneous(list.filter(item => item.subCategory === countType))).length;

      default:
        return (list || []).filter(item => item.subCategory === countType).length;
    }
  };

  const tabHasSubcategories = (id?) => {
    return tabs && (tabs.find(item => item.value === (id || value))?.subCategories?.length || 0) > 0;
  };

  const setCountDisplay = tab => {
    if (tab && tab.subCategories) setCount(tab?.subCategories?.length);

    switch (tab?.value) {
      case 1:
        const countData: any = credentials?.licenses?.length;
        return setCount(countData);

      default:
        setCount('');
    }
  };
  //#endregion

  //#region Handlers
  const handleSubcategoryChange = (event, newValue) => {
    if (subCategory?.[newValue].value !== subCategorySelection) {
      setCredentialingLookups(null);
      setSearchResult(null);
      setSearchInput(null);
      setSubCategorySelection(subCategory?.[newValue].value);
      setPopCount(0);
      setValueTabs(newValue);
      dispatch(gridStateActions.setPageNumber(1));
    }
  };

  const handleTabChange = (index, _subCategorySelection = null) => {
    setValue(index);

    if (
      (index === CredentialsGridType.certificationsParent ||
        index === CredentialsGridType.clinicalQualificationsParent ||
        index === CredentialsGridType.humanResourcesParent ||
        index === CredentialsGridType.miscellaneous) &&
      !subCategorySelection
    ) {
      const newPopCount = popCount + 1;
      setPopCount(newPopCount);
      if (newPopCount >= 3) {
        setOpen(true);
      }
    }

    if (index !== value) {
      setCredentialingLookups(null);
      setSubCategorySelection(_subCategorySelection);
      setSearchInput(null);
      setPopCount(1);
      setOpen(false);
      setSearchResult(null);
    }
    getTabsForSubcategories(index, credentials);
  };

  const getTabsForSubcategories = (index, creds) => {
    const tab = getTabList(creds).find(item => item.value === index);
    setCountDisplay(tab);

    if (tab?.subCategories?.length > 0) {
      setSubCategory(tab.subCategories.map(x => ({ ...x, label: x.description })));
    }
  };
  //#endregion

  //#region handlers
  const handleClose = (event: any) => {
    setOpen(false);
  };
  //#endregion

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = (search: any, creds: Credentials | null = null) => {
    if (!search || !credentials) return;
    const allCredentials = Object.entries(creds || credentials!);
    try {
      allCredentials.forEach(credentialsItems => {
        if (typeof credentialsItems[1] === 'object') {
          credentialsItems[1].forEach(credential => {
            if (Number(credential.requirementID) === Number(search.requirementID)) {
              if (search.parentGridType !== null) {
                if (search.parentGridType !== value) {
                  setCredentialingLookups(null);
                }
                setValue(search.parentGridType || 0);

                getTabsForSubcategories(search.parentGridType, credentials);
              }
              if (search.gridType !== null && tabHasSubcategories(search.parentGridType)) {
                setSubCategorySelection(search.gridType || null);
              } else {
                setSubCategorySelection(null);
              }
              const s = {
                key: 'rowID',
                value: credential.requirementID,
                isEditing: search?.object?.isEditing,
              };
              setSearchResult(s);
            }
          });
        }
      });
    } catch (e) {
      console.log('search error', e, JSON.stringify(credentials));
    }
  };

  const onSearchComplete = () => {
    setSearchResult(null);
  };

  const onSearchInputChange = input => {
    setSearchInput(input);
  };
  const handleAddPopupOpen = e => {
    if (e) {
      setAddPopUp(true);
    }
  };
  const handleAddPopupClose = search => {
    onSearch(search);
    setAddPopUp(false);
  };
  const onSelect = Option => {
    onSearch(Option);
  };

  const onAddCredential = (credential, selectedOption) => {
    const search: any = {
      requirementTypeID: selectedOption.requirementTypeID,
      requirementID: credential.requirementID,

      gridType: selectedOption.gridType,
      parentGridType: selectedOption.parentGridType,
    };
    const newCredentials = addCredentialLocal(credential, selectedOption.gridType, _.cloneDeep(credentials));
    if (newCredentials !== null) dispatch(candidateDetailActions.setCredentials(newCredentials));

    setTimeout(() => {
      onSearch(search, newCredentials);
      setReloadGrid(!reloadGrid);
    }, 500);
  };

  return (
    <div className={classes.container} data-testid="credentials-tab">
      <Paper elevation={0} className={`paper ${classes.tabContainer}`}>
        <div className={classes.topBar}>
          <div className={classes.searchBar}>
            <CredentialsSearch
              reloadData={reloadData}
              credentials={credentials || null}
              searchInput={searchInput}
              onSelect={onSelect}
              onSearchInputChange={onSearchInputChange}
            />
          </div>
          <div>
            <CredentialsAdd
              openAdd={addPopUp}
              handlePopupOpen={handleAddPopupOpen}
              handlePopupClose={handleAddPopupClose}
              credentials={credentials || null}
              credentialsGridType={tabHasSubcategories() ? subCategorySelection || 0 : value}
              credentialingLookups={credentialingLookups!}
              credentialingAllLookups={lookupselection}
              onAddCredential={onAddCredential}
            />
          </div>
        </div>
        {lookUpPromiseInProgress || promiseInProgress || !credentialingLookups?.length ? (
          <Grid item>
            <Skeleton sx={{ marginY: 3 }} />
            <Skeleton sx={{ marginY: 3 }} />
            <Skeleton sx={{ marginY: 3 }} />
            <Skeleton sx={{ marginY: 3 }} />
          </Grid>
        ) : (
          <>
            {tabs &&
              tabs.map((tab, index) => {
                const hasSubcategories = tabHasSubcategories();
                const selectionClass = tab.value === value ? classes.selectedTab : classes.unselectedTab;
                return (
                  <Box key={index} className={classes.tab}>
                    <ClickAwayListener
                      mouseEvent={tab.value === value && open ? 'onClick' : false}
                      onClickAway={handleClose}
                    >
                      <Grid container className={hasSubcategories ? selectionClass : ''}>
                        <Grid item className={classes.selectable}>
                          <Box
                            onClick={event => {
                              handleTabChange(tab.value);
                            }}
                            className={`${classes.tabLabel} ${!hasSubcategories ? selectionClass : ''}`}
                          >
                            {tab.label}
                          </Box>
                        </Grid>
                      </Grid>
                    </ClickAwayListener>
                  </Box>
                );
              })}
          </>
        )}
      </Paper>
      <Paper elevation={0} className={`paper ${classes.tabRenderContainer}`} data-testid="credentials-tab-paper">
        {lookUpPromiseInProgress || promiseInProgress || !credentialingLookups?.length ? (
          <Grid item>
            <Skeleton sx={{ marginY: 3 }} />
            <Skeleton sx={{ marginY: 3 }} />
            <Skeleton sx={{ marginY: 3 }} />
            <Skeleton sx={{ marginY: 3 }} />
            <Skeleton sx={{ marginY: 3 }} />
          </Grid>
        ) : (
          <div className={classes.fullHeight}>
            {credentialingLookups?.length &&
              (!tabHasSubcategories() || (tabHasSubcategories() && subCategorySelection)) && (
                <CredentialsGrid
                  credentials={credentials}
                  credentialsGridType={tabHasSubcategories() ? subCategorySelection || 0 : value}
                  credentialingLookups={credentialingLookups!}
                  searchResult={searchResult}
                  onSearchComplete={onSearchComplete}
                  reloadGrid={reloadGrid}
                  menuToRender={
                    <GenericTabs
                      value={valueTabs}
                      tabs={subCategory}
                      customHandleChange={handleSubcategoryChange}
                      hidePanel={true}
                    />
                  }
                  hasSubcategories={tabHasSubcategories()}
                />
                //<LicensureExpandableTab />
              )}
          </div>
        )}
      </Paper>
    </div>
  );
};
