import React from 'react';
import { Grid, Divider, Button } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { Controller, useFormContext } from 'react-hook-form';
import { OrFilter } from '.';
import { useTranslation } from 'react-i18next';
import { FilterLayoutTypes } from './utils';
import { SingleFilter } from './SingleFilter';

const useStyles = makeStyles()({
  root: {
    position: 'relative',
  },
  container: {
    flexWrap: 'nowrap',
  },
  buttonGrid: {
    display: 'flex',
    alignItems: 'baseline',
  },
  button: {
    fontSize: 12,
  },
  submitButton: {
    backgroundColor: '#006FB9',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#006FCF',
    },
  },
  resetButton: {
    color: '#888888',
  },
  filterField: {
    paddingTop: 20,
  },
  filterDivider: {
    marginTop: 22,
  },
  customScroll: {
    height: 'calc(100vh - 150px)',
    overflowY: 'scroll',
    overflowX: 'hidden',
    paddingBottom: '30%',
  },
  btnContainer: {
    flexWrap: 'nowrap',
    position: 'absolute',
    width: '111%',
    right: -10,
    bottom: 0,
    background: '#F7F7F7',
    padding: '5% 3%',
    zIndex: 10,
    borderRadius: 4,
  },
});

export const CustomSideFilter = ({
  fields,
  version2,
  onChangeValues,
  onReset,
  hasApplyButton = true,
}: {
  fields;
  version2?: boolean;
  onChangeValues?: (item, value) => void;
  onReset?: (event) => void;
  hasApplyButton?: boolean;
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { control, errors } = useFormContext();
  const hiddenSubmitButton = React.createRef<HTMLButtonElement>();
  return (
    <Grid container direction="column" classes={{ root: classes.root }}>
      <Grid container direction="column" classes={{ root: classes.customScroll, container: classes.container }}>
        {fields.map((item, index) => (
          <Grid item classes={{ root: classes.filterField }} key={item.name}>
            <Controller
              control={control}
              name={item.name}
              render={({ ref, onChange, ...rest }) =>
                item.componentType === FilterLayoutTypes.SINGLE ? (
                  <SingleFilter
                    onChange={value => {
                      onChange(value);
                      onChangeValues?.(item.name, value);
                      if (!hasApplyButton) {
                        hiddenSubmitButton?.current?.click();
                      }
                    }}
                    {...rest}
                    {...item}
                  />
                ) : (
                  <OrFilter
                    onChange={value => {
                      onChange(value);
                      onChangeValues?.(item.name, value);
                    }}
                    errorObject={errors[item.name]}
                    {...rest}
                    {...item}
                  />
                )
              }
            />
            {index < fields.length - 1 && <Divider classes={{ root: classes.filterDivider }} />}
          </Grid>
        ))}
      </Grid>
      <Grid item container spacing={1} direction="row" justifyContent="flex-end" classes={{ container: classes.btnContainer }}>
        {!version2 && (
          <Grid item classes={{ root: classes.buttonGrid }}>
            <Button
              aria-label="reset-side-filter"
              variant="text"
              size="small"
              className={`${classes.resetButton} ${classes.button}`}
              type="button"
              onClick={onReset}
            >
              {t('search.filter.Reset')}
            </Button>
          </Grid>
        )}
        {hasApplyButton ? (
          <Grid item classes={{ root: classes.buttonGrid }}>
            <Button
              aria-label="submit-side-filter"
              variant="contained"
              size="small"
              className={`${classes.submitButton} ${classes.button}`}
              type="submit"
            >
              {t('search.filter.apply')}
            </Button>
          </Grid>
        ) : (
          <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
        )}
      </Grid>
    </Grid>
  );
};
