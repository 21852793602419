import { XGridChipCell, xGridNumberSort } from "@AMIEWEB/Common";
import { XGridDefaultColumn } from "@AMIEWEB/Common/XGrid/cells/XGridDefaults";
import { GridColumns } from "@mui/x-data-grid-pro";
import { XGridRadioButton } from "./XGridRadioButton";
import { convertDateToPST } from "app/helpers/dateHelper";

export const getAutoTasksGridColumns = (t,selectedAutoTaskCategoryId):GridColumns =>[ {
    ...XGridDefaultColumn,
    field: 'select',
    headerName:t('autoTaskManagement.grid.select'),
    flex:0,
    width: 39,
    hide: false,
    editable: true,
    hideable: false,
    disableExport: true,
    renderCell:(params)=>XGridRadioButton(params.row.autoTaskCategoryId === selectedAutoTaskCategoryId)
  },
  {
    ...XGridDefaultColumn,
    field: 'autoTaskCategoryId',
    headerName: t('autoTaskManagement.grid.id'),
    flex:0,
    width: 33,
    hide: false,
    editable: false,
    hideable: false,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    sortComparator: xGridNumberSort
  },
  {
    ...XGridDefaultColumn,
    field: 'description',
    headerName: t('autoTaskManagement.grid.type'),
    minWidth: 210,
    hide: false,
    editable: false,
    hideable: false,
    align: 'left',
    headerAlign: 'left',
  },
  {
    ...XGridDefaultColumn,
    field: 'status',
    headerName: t('autoTaskManagement.grid.status'),
    minWidth: 100,
    hide: false,
    editable: false,
    hideable: false,
    align: 'left',
    headerAlign: 'left',
    renderCell: XGridChipCell({
        size: 'small',
        dynamicColor: (params) => {
          return getColor(params.value);
        },
        renderValue: (params) => {
          return params.value;
        },
      }),
  },
  {
    ...XGridDefaultColumn,
    field: 'eventDescription',
    headerName: t('autoTaskManagement.grid.eventDescription'),
    minWidth: 260,
    hide: false,
    editable: false,
    hideable: false,
    align: 'left',
    headerAlign: 'left',
  },
  {
    ...XGridDefaultColumn,
    field: 'lastUpdatedBy',
    headerName: t('autoTaskManagement.grid.lastUpdatedBy'),
    minWidth: 150,
    hide: false,
    editable: false,
    hideable: false,
    align: 'left',
    headerAlign: 'left',
  },
  {
    ...XGridDefaultColumn,
    field: 'lastUpdateDate',
    headerName: t('autoTaskManagement.grid.lastUpdated'),
    minWidth: 180,
    hide: false,
    editable: false,
    hideable: false,
    align: 'left',
    headerAlign: 'left',
    valueFormatter:({ value })=>convertDateToPST(value, 'MM/DD/YYYY h:mm A'), 
  },

]

export const getColor = (val: string) => {
    switch (val) {
      case 'Active':
        return '#5FB661';
      case 'Inactive':
        return '#5f5a5a';
      default:
        return '#000000';
    }
  };
