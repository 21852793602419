import React from 'react';
import { missingField } from 'app/constants';
import { MoreTextBlock } from '../../CustomComponents/MoreTextBlock';
import { useTranslation } from 'react-i18next';

export const OrderInfoInternalNotes = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <div id="placement-order-info-internal-notes" data-testid="order-info-internal-note-item">
      <MoreTextBlock
        title={t('placement.profile.orderInformation.orderInformation.orderInfoRow.internalNotes')}
        text={props.orderInformation?.internalNotes ? props.orderInformation?.internalNotes : `${missingField}`}
        charLimit={170}
        id={'placementSummaryDetailsTabsInternalNotesDetailsContainer'}
      />
    </div>
  );
};
