import React from 'react';
import { withStyles } from 'tss-react/mui';
import { Paper, Grid, Divider, Button, Box } from '@mui/material';
import { differenceInCalendarMonths } from 'date-fns';
import Month from './Month';
import DefinedRanges from './DefinedRanges';
import { DateRange, DefinedRange, Setter, NavigationAction, EDateRangePickTabbingIDs } from '../types';
import { MARKERS } from '..';
import { addMonths } from 'date-fns';
import clsx from 'clsx';

const applyButton = 'Apply';
const cancelButton = 'Cancel';

interface MenuProps {
  dateRange: DateRange;
  ranges: DefinedRange[];
  minDate: Date;
  maxDate: Date;
  firstMonth: Date;
  secondMonth: Date;
  setFirstMonth: Setter<Date>;
  setSecondMonth: Setter<Date>;
  setDateRange: Setter<DefinedRange>;
  quickSelect?: boolean;
  helpers: {
    inHoverRange: (day: Date) => boolean;
  };
  handlers: {
    onDayClick: (day: Date) => void;
    onDayHover: (day: Date) => void;
    onMonthNavigate: (marker: symbol, action: NavigationAction) => void;
    _onApply: () => void;
    _onCancel: () => void;
  };
  actionBar: boolean;
  single: boolean;
  valid: boolean;
  elevation: number;
  applyButtonLabel?: string;
  uniqueId: any;
  classes?: any;
}

const Menu: React.FunctionComponent<MenuProps> = props => {
  const {
    classes,
    ranges,
    dateRange,
    minDate,
    maxDate,
    firstMonth,
    setFirstMonth,
    secondMonth,
    setSecondMonth,
    setDateRange,
    quickSelect,
    helpers,
    handlers,
    actionBar,
    single,
    valid,
    elevation,
    applyButtonLabel,
    uniqueId,
  } = props;
  const canNavigateCloser = differenceInCalendarMonths(secondMonth, firstMonth) >= 2;
  const maxForward = maxDate
    ? new Date(addMonths(secondMonth, 1).toDateString()) <= new Date(maxDate.toDateString())
    : true;
  const maxBackward = minDate
    ? new Date(addMonths(firstMonth, -1).toDateString()) >= new Date(minDate.toDateString())
    : true;
  const commonProps = { dateRange, minDate, maxDate, helpers, handlers };

  const [monthOneDropDownOpen, setMonthOneDropDownOpen] = React.useState<boolean>(false);
  const [monthTwoDropDownOpen, setMonthTwoDropDownOpen] = React.useState<boolean>(false);
  const [yearOneDropDownOpen, setYearOneDropDownOpen] = React.useState<boolean>(false);
  const [yearTwoDropDownOpen, setYearTwoDropDownOpen] = React.useState<boolean>(false);

  const setMonthDropDownOpen = (open, firstSet) => {
    if (firstSet) {
      setMonthOneDropDownOpen(open);
      setMonthTwoDropDownOpen(false);
    } else {
      setMonthTwoDropDownOpen(open);
      setMonthOneDropDownOpen(false);
    }
  };

  const setYearDropDownOpen = (open, firstSet) => {
    if (firstSet) {
      setYearOneDropDownOpen(open);
      setYearTwoDropDownOpen(false);
    } else {
      setYearTwoDropDownOpen(open);
      setYearOneDropDownOpen(false);
    }
  };

  return (
    <Paper elevation={elevation} square className={classes.root}>
      <Grid container direction="row" wrap="nowrap">
        {quickSelect && (
          <Grid>
            <DefinedRanges selectedRange={dateRange} ranges={ranges} setRange={setDateRange} />
          </Grid>
        )}
        <div className={classes.divider} />
        <Grid>
          <Grid container className={classes.datepickercontainer} direction="row" justifyContent="center" wrap="nowrap">
            <Month
              {...commonProps}
              value={firstMonth}
              setValue={setFirstMonth}
              navState={[maxBackward, single ? maxForward : canNavigateCloser]}
              marker={MARKERS.FIRST_MONTH}
              firstSet
              monthDropDownOpen={monthOneDropDownOpen}
              yearDropDownOpen={yearOneDropDownOpen}
              setMonthDropDownOpen={setMonthDropDownOpen}
              setYearDropDownOpen={setYearDropDownOpen}
              quickSelect={quickSelect}
              single={single}
            />
            {!single && (
              <React.Fragment>
                <div className={classes.divider} />
                <Month
                  {...commonProps}
                  value={secondMonth}
                  setValue={setSecondMonth}
                  navState={[canNavigateCloser, maxForward]}
                  marker={MARKERS.SECOND_MONTH}
                  secondSet
                  monthDropDownOpen={monthTwoDropDownOpen}
                  yearDropDownOpen={yearTwoDropDownOpen}
                  setMonthDropDownOpen={setMonthDropDownOpen}
                  setYearDropDownOpen={setYearDropDownOpen}
                  quickSelect={quickSelect}
                  single
                />
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </Grid>
      {actionBar && (
        <React.Fragment>
          <Divider style={{ height: '2px' }} />
          <Box className={`${classes.spreadBox} ${classes.box}`}>
            <Button
              id={`${EDateRangePickTabbingIDs.cancelButton}-${uniqueId}`}
              variant="text"
              color="secondary"
              onClick={commonProps.handlers._onCancel}
            >
              <span className={classes.buttonText}>{cancelButton}</span>
            </Button>
            <Button
              id={`${EDateRangePickTabbingIDs.applyButton}-${uniqueId}`}
              disabled={!valid || dateRange.startDate === undefined}
              variant="text"
              color="primary"
              className={clsx({
                [classes.disabledButton]: !valid,
              })}
              onClick={commonProps.handlers._onApply}
            >
              <span className={classes.buttonText}>{applyButtonLabel || applyButton}</span>
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Paper>
  );
};

export default withStyles(Menu, theme => ({
  root: {
    borderRadius: '4px',
    width: 'max-content',
  },
  header: {
    padding: '20px 70px',
  },
  divider: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
    opacity: 1,
  },
  box: {
    display: 'flex',
    padding: '6px 12px',
  },
  spreadBox: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  disabledButton: {
    color: `${theme.palette.action.disabled} !important`,
  },
  datepickercontainer: {
    marginTop: theme.spacing(2),
  },
  buttonText: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
}));
