import { Button, Grid, Tooltip } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { TaskSidePanel } from 'app/components/Tasks/CustomComponents/TaskSidePanel/TaskSidePanel';
import { taskDetailsActions } from 'app/components/Tasks/store/Tasks.redux';
import { TaskSidebarContainer } from 'app/components/Tasks/TaskSidebar/TaskSidebarContainer';
import { ITaskStickChosen, TaskEntity } from 'app/models/Tasks/Tasks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { orderDataActions } from 'app/components/Order/Store/Order.redux';
import { useHistory, useParams } from 'react-router-dom';
import { selectFacility } from '../FacilityStore/Facility.selector';
import { selectUser } from 'oidc/user.selectors';
import { facilityActions } from '../FacilityStore/Facility.redux';
import { FacilityUnitActions } from './FacilityTabs/UnitTab/Store/FacilityUnit.redux';
import { isAuthorizedForAddEdit } from '@AMIEWEB/Unit/UnitDetails/Containers/unitHelper';
import { calculateIsCreditHold, calculateIsCreditPending } from './helper';
import { AMNDivisionType } from 'app/models/enums/AMNDivisionType';

const useStyles = makeStyles<{ taskShrink: boolean }>()((theme, props) => ({
  disabledHover: {
    '&:hover': {
      backgroundColor: '#8f9195',
    },
  },
  tooltip: {
    display: 'grid',
  },
  tooltipCustom: {
    font: 'normal normal normal 14px/20px Roboto',
    '& svg': {
      position: 'relative',
      top: '6px',
      left: '-12px',
      color: '#FFA22E',
      'font-size': '1.7rem',
    },
  },
  container: {
    flexWrap: 'nowrap',
    width: '100%',
  },
  tooltipArrow: {
    color: '#fff',
    '&::before': {
      border: '1px solid #e3e2e2',
      'box-shadow': '1px 1px 5px #888888',
    },
  },
  tooltipBackground: {
    backgroundColor: '#fff',
    color: '#555555',
    border: '1px solid #e3e2e2',
    fontSize: '12px',
    'box-shadow': '1px 1px 5px #888888',
  },
  linkDisabled: {
    fontSize: 12,
    cursor: 'not-allowed',
    backgroundColor: '#8E9195',
  },
  sidePanel: {
    width: props.taskShrink ? 36 : '297px',
    '& .MuiPaper-root.MuiPaper-elevation1': {
      top: '60px',
      height: '90%',
      zIndex: 99,
    },
  },
  statusButton: {
    fontSize: 12,
    fontWeight: 700,
  },
}));

export const FacilityHeaderWrapper = ({
  stickIconArray,
  taskStickChosen,
  handleUnitCreation,
  credHoldMessage,
  isMaxBookingLimit,
  bookingLimitMessage,
  handleOrderCreation,
  setTooltipIsOpen,
  tooltipIsOpen,
  disableOrderBtn,
  ineligibleStatusMessage,
}: {
  stickIconArray: number[];
  taskStickChosen?: ITaskStickChosen;
  handleUnitCreation: (e) => void;
  credHoldMessage: string;
  isMaxBookingLimit: boolean;
  bookingLimitMessage: string;
  handleOrderCreation: (e, i) => void;
  setTooltipIsOpen: (e) => void;
  tooltipIsOpen: boolean;
  disableOrderBtn: boolean;
  ineligibleStatusMessage: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = useSelector(selectUser);
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const history = useHistory();
  const facility = useSelector(selectFacility);
  const { id: facilityId } = useParams<{ id: string }>();
  const isCreditHold = calculateIsCreditHold(facility?.facilityResponse?.creditStatus);
  const isCreditPending = calculateIsCreditPending(facility?.facilityResponse?.creditStatus);
  const isOverrideEligible =
  facility?.facilityResponse?.amnDivisionTypeId === AMNDivisionType.allied ||
  facility?.facilityResponse?.amnDivisionTypeId === AMNDivisionType.hsg;
  const taskSidePanelItems = [
    {
      title: t('notification.taskSidePanel.taskSidePanelHeading'),
      tooltip: t('notification.taskSidePanel.taskSidePanelHeading'),
      component: <TaskSidebarContainer container={TaskEntity.FACILITIES} />,
    },
  ];
  const { classes } = useStyles({ taskShrink: taskStickChosen.stick === null });
  const handleTaskStickChoice = stick => {
    dispatch(
      taskDetailsActions.setTaskStickChosen({
        entity: TaskEntity.FACILITIES,
        stick: stick === taskStickChosen.stick ? null : stick,
      }),
    );
  };

  const handleCreateOrderBtnClick = () => {
    if ((!isCreditHold && !isCreditPending && !disableOrderBtn) || isOverrideEligible) {
      //handleOrderCreation(true, 0);
      history.push(`/facility/${facility.facilityResponse?.facilityId}/order/new`);
    } else if ((isCreditHold || isCreditPending) && !isOverrideEligible) {
      handleOpenDialog();
    } else {
      setTooltipIsOpen(!tooltipIsOpen);
    }
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogYes = () => {
    dispatch(orderDataActions.setOverrideCreditHold(true));
    setDialogOpen(false);
    //handleOrderCreation(true, 0);
    history.push(`/facility/${facility.facilityResponse?.facilityId}/order/new`);
  };

  return (
    <React.Fragment>
      <GenericDialog
        open={isDialogOpen}
        fullWidth
        dialogTitleProps={{
          text: t('order.facility.creditHoldDialog.title'),
        }}
        dialogContentProps={{ variant: 'text' }}
        dialogActions={[
          {
            text: t('order.facility.creditHoldDialog.cancelBtn'),
            color: 'tertiary',
            onClick: handleDialogClose,
          },
          {
            text: t('order.facility.creditHoldDialog.confirmBtn'),
            variant: 'contained',
            color: 'primary',
            onClick: handleDialogYes,
          },
        ]}
      >
        {t('order.facility.creditHoldDialog.prompt')}
      </GenericDialog>
      <Grid container spacing={2} wrap="nowrap" sx={{ alignItems: 'center' }}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => handleUnitCreation(true)}
            id={`createUnitButton`}
            disabled={!isAuthorizedForAddEdit(userData)}
          >
            {`CREATE NEW UNIT`}
          </Button>
        </Grid>
        <Grid item>
          <Tooltip
            title={
              isOverrideEligible ? (
                ''
              ) : (
                <div className={`${classes.tooltip} ${classes.tooltipCustom}`}>
                  <Grid
                    container
                    xs={12}
                    direction="row"
                    classes={{ container: classes.container }}
                    data-testid="table-test-id"
                  >
                    {!disableOrderBtn && (
                      <Grid xs={1} item direction="column">
                        <ReportProblemOutlinedIcon />
                      </Grid>
                    )}
                    <Grid xs={11} item direction="column">
                      <span>
                        {disableOrderBtn
                          ? ineligibleStatusMessage
                          : isCreditHold || isCreditPending
                          ? credHoldMessage
                          : isMaxBookingLimit && !isCreditHold
                          ? bookingLimitMessage
                          : ''}
                      </span>
                    </Grid>
                  </Grid>
                </div>
              )
            }
            disableHoverListener={!isCreditHold && !isCreditPending && !(isMaxBookingLimit && !isCreditHold)}
            arrow
            placement="bottom"
            classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
          >
            <Button
              variant="contained"
              color="primary"
              size="medium"
              disableElevation
              classes={{
                containedPrimary: `${classes.statusButton} ${
                  (isCreditHold || isCreditPending || disableOrderBtn) && !isOverrideEligible
                    ? `${classes.disabledHover} ${classes.linkDisabled}`
                    : ''
                }`,
              }}
              onClick={() => {
                dispatch(FacilityUnitActions.setCreateNewUnitFromOrder(false));
                if (!facility) {
                  dispatch(facilityActions.getFacilityDetailsWithOpenOrderCountAction({ id: parseInt(facilityId) }));
                }
                handleCreateOrderBtnClick();
              }}
              id={`createOrderButton`}
            >
              {`CREATE NEW ORDER`}
            </Button>
          </Tooltip>
        </Grid>
        <Grid item>
          <div className={classes.sidePanel}>
            {stickIconArray && taskSidePanelItems && (
              <TaskSidePanel
                items={taskSidePanelItems}
                stickChosen={taskStickChosen}
                handleChoice={handleTaskStickChoice}
                iconArray={stickIconArray}
                scrollable={true}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
