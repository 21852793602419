/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IJobPreferences {
    data: any[];
    count: number;
    flattenedData: any[];
    loading: boolean;
    id?: string | undefined;
    preferenceId?: string | undefined;
}
export const initialState: IJobPreferences = {
    data: [],
    count: 0,
    flattenedData: [],
    loading: false,
    id: undefined,
    preferenceId: undefined
}

const getJobPreferencesAction = createAction<{
    travelerId: number;
    brandId: number;
}>('GET_JOB_PREFERENCES');

const toggleJobPreferenceNotificationAction = createAction<{
    candidateId: string;
    brandId: string;
    id: string;
    isOff: boolean;
}>('TOGGLE_JOB_PREFERENCE_NOTIFICATION');

const preferencesReducer = createSlice({
    name: 'candidateJobPreferences',
    initialState,
    reducers: {
        setData(state, action: PayloadAction<any[]>) {
            state.data = action.payload;
        },
        updateData(state, action: PayloadAction<any>){
           state.data = [...state.data,action.payload];
        },
        setFlattenedData(state, action: PayloadAction<any[]>) {
            state.flattenedData = action.payload;
        },
        updateFlattenedData(state, action: PayloadAction<any>){
            state.flattenedData = [...state.flattenedData,action.payload]
        },
        setLoading(state, action: PayloadAction<boolean>){
            state.loading= action.payload;
        },
        setModalProps(state, action: PayloadAction<any>){
            state.id = action.payload;
        },
        setPreferenceId(state, action: PayloadAction<any>){
            state.preferenceId = action.payload;
        },
        reset() {
            return initialState;
        },
    }
});

export const { name: candidateJobPreferencesSliceKey, reducer: jobPreferenceReducer } = preferencesReducer;
export const jobPreferncesActions = {
    ...preferencesReducer.actions,
    getJobPreferencesAction,
    toggleJobPreferenceNotificationAction
}