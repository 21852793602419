import { Expandable } from 'app/components/Common/Expandable/Expandable';
import React from 'react';
import { Grid } from 'amn-ui-core';
import { StatusTitle } from '../../Titles/StatusTitle';
import { RequirementsContainer } from '../RequirementsContainer';
import { ConfirmationsTable } from '../TableComponents/Confirmations/ConfirmationsTable';
import { useTranslation } from 'react-i18next';
import { GtgComplete } from './Credentialing/GtgComplete';
import { FinalizedByQS } from './Credentialing/FinalizedByQS';
import { Arrived } from './Confirmations/Arrived';
import { DirectDeposit } from './Confirmations/DirectDeposit';
import { Wisely } from './Confirmations/Wisely';
import { useBookingRequirementCounts } from '../RequirementCounts/BookingRequirementCounts';
import { selectBookingInfo } from '../../../../../../../../store/redux-store/placement-summary/selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
  cleanChildFilterByKey,
  deriveFilter,
  useAccordionState,
  useRequirementFilter,
} from '../../Helpers/requirementFilter';
import { RequirementFilterKey, requirementType } from 'app/models/Placement/PlacementSummary';
import { placementSummaryActions } from '../../../../../../../../store/redux-store/placement-summary/slice';
import {
  selectBookingConfirmationRequirementTypes,
  selectBookingCredentialingRequirementTypes,
} from 'store/redux-store/placement-details/selectors';

export const Booking = ({ loading }) => {
  const { t } = useTranslation();
  useBookingRequirementCounts();
  const {
    confirmation: { reqCounts: confirmationCounts },
    credentialing: { reqCounts: credentialingCounts },
    requirementFilter,
  } = useSelector(selectBookingInfo);

  const confirmationTypes = useSelector(selectBookingConfirmationRequirementTypes);
  const credentialingTypes = useSelector(selectBookingCredentialingRequirementTypes);

  const dispatch = useDispatch();

  const { filter: bookingFilter } = useRequirementFilter('booking', requirementFilter);
  const { filter: confirmationFilter } = useRequirementFilter('booking-confirmation', requirementFilter);
  const { filter: credentialingFilter } = useRequirementFilter('booking-credentialing', requirementFilter);

  const { expand: expandConfirmationReq } = useAccordionState(confirmationFilter, confirmationTypes);
  const { expand: expandCredentialingReq } = useAccordionState(credentialingFilter, credentialingTypes);

  const handleFilterChange = (key: RequirementFilterKey, state: requirementType[]) => {
    if (requirementFilter) {
      const filterTree = deriveFilter(key, requirementFilter, state);
      cleanChildFilterByKey('booking-confirmation', filterTree, confirmationCounts);
      cleanChildFilterByKey('booking-credentialing', filterTree, credentialingCounts);
      dispatch(
        placementSummaryActions.setFilterTreeByKey({
          key: 'bookingInfo',
          filterTree,
        }),
      );
    }
  };

  return (
    <Expandable
      id="placement-booking-container"
      unmountOnExit={false}
      loading={loading}
      preventForceClose
      expanded={!!bookingFilter.length}
      titleComponent={
        <StatusTitle
          title={t('placement.profile.summary.booking.title')}
          counts={{
            aggregate: confirmationCounts.aggregate + credentialingCounts.aggregate,
            passed: (confirmationCounts.passed || 0) + (credentialingCounts.passed || 0),
            failed: (confirmationCounts.failed || 0) + (credentialingCounts.failed || 0),
            risks: (confirmationCounts.risks || 0) + (credentialingCounts.risks || 0),
          }}
          filter={bookingFilter}
          handleFilterChange={state => handleFilterChange('booking', state)}
        />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* CONFIRMATIONS */}
          <RequirementsContainer
            title={t('placement.profile.summary.booking.confirmations.title')}
            counts={confirmationCounts}
            unmountOnExit={false}
            filter={confirmationFilter}
            handleFilterChange={state => handleFilterChange('booking-confirmation', state)}
            expanded={expandConfirmationReq}
          >
            <ConfirmationsTable
              id="placement-booking-confirmations-table"
              cellTwoTitle={t('placement.profile.summary.booking.confirmations.lastUpdatedBy')}
              cellThreeTitle={t('placement.profile.summary.booking.confirmations.lastUpdatedDate')}
            >
              <Arrived hasRequirements={confirmationCounts.aggregate > 0} />
              <DirectDeposit hasRequirements={confirmationCounts.aggregate > 0} />
              <Wisely hasRequirements={confirmationCounts.aggregate > 0} />
            </ConfirmationsTable>
          </RequirementsContainer>
        </Grid>
        <Grid item xs={12}>
          {/* CREDENTIALING */}
          <RequirementsContainer
            title={t('placement.profile.summary.booking.credentialing.title')}
            counts={credentialingCounts}
            unmountOnExit={false}
            filter={credentialingFilter}
            handleFilterChange={state => handleFilterChange('booking-credentialing', state)}
            expanded={expandCredentialingReq}
          >
            <ConfirmationsTable
              id="placement-booking-credentialing-table"
              cellTwoTitle={t('placement.profile.summary.booking.credentialing.lastUpdatedBy')}
              cellThreeTitle={t('placement.profile.summary.booking.credentialing.lastUpdatedDate')}
            >
              <GtgComplete hasRequirements={credentialingCounts.aggregate > 0} />
              <FinalizedByQS hasRequirements={credentialingCounts.aggregate > 0} />
            </ConfirmationsTable>
          </RequirementsContainer>
        </Grid>
      </Grid>
    </Expandable>
  );
};
