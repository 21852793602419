import { GridColumns } from '@mui/x-data-grid-pro';
import { XGridDefaultColumn } from 'app/components/Common/XGrid/cells/XGridDefaults';
import { XGridRadioCell } from '@AMIEWEB/Common/XGrid/cells/XGridRadioCell';

const OrderDefaultCell = {
  ...XGridDefaultColumn,
  flex: 0,
};
export const orderViewInfo = (t): GridColumns => [
  {
    ...OrderDefaultCell,
    field: 'orderIDselect',
    headerName: t('*'),
    align: 'center',
    sortable: false,
    width: 15,
    renderCell: XGridRadioCell,
  },
  {
    ...OrderDefaultCell,
    field: 'orderId',
    headerName: t('search.globalSearch.order.gridTags.orderId'),
    width: 100,
  },
  {
    ...OrderDefaultCell,
    field: 'facility',
    headerName: t('search.globalSearch.order.gridTags.facility'),
    width: 280,
  },
  {
    ...OrderDefaultCell,
    field: 'facilityStatus',
    headerName: t('search.globalSearch.order.gridTags.facilityStatus'),
    width: 130,
  },
  {
    ...OrderDefaultCell,
    field: 'division',
    headerName: t('search.globalSearch.order.gridTags.division'),
    width: 90,
  },
  {
    ...OrderDefaultCell,
    field: 'city',
    headerName: t('search.globalSearch.order.gridTags.city'),
    width: 130,
  },
  {
    ...OrderDefaultCell,
    field: 'state',
    headerName: t('search.globalSearch.order.gridTags.state'),
    width: 90,
  },
  {
    ...OrderDefaultCell,
    field: 'skillSets',
    headerName: t('search.globalSearch.order.gridTags.skillSet'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'orderType',
    headerName: t('search.globalSearch.order.gridTags.type'),
    width: 90,
  },
  {
    ...OrderDefaultCell,
    field: 'orderPriority',
    headerName: t('search.globalSearch.order.gridTags.orderPriority'),
    width: 280,
  },
  {
    ...OrderDefaultCell,
    field: 'vmsReqNo',
    headerName: t('search.globalSearch.order.gridTags.vmsReqNo'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'positionsAvailable',
    headerName: t('search.globalSearch.order.gridTags.positionsAvailable'),
    width: 120,
  },
  {
    ...OrderDefaultCell,
    field: 'maxFilesSent',
    headerName: t('search.globalSearch.order.gridTags.maxFilesSent'),
    width: 100,
  },
  {
    ...OrderDefaultCell,
    field: 'filesPending',
    headerName: t('search.globalSearch.order.gridTags.filesPending'),
    width: 120,
    description: 'Status includes WFC and RFO',
  },
  {
    ...OrderDefaultCell,
    field: 'filesOut',
    headerName: t('search.globalSearch.order.gridTags.filesOut'),
    width: 100,
    description: 'Status includes Sent-Offer',
  },
  {
    ...OrderDefaultCell,
    field: 'mspClient',
    headerName: t('search.globalSearch.order.gridTags.mspClient'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'affiliate',
    headerName: t('search.globalSearch.order.gridTags.affiliate'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'unit',
    headerName: t('search.globalSearch.order.gridTags.unit'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'shifts',
    headerName: t('search.globalSearch.order.gridTags.shifts'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'paysGww',
    headerName: t('search.globalSearch.order.gridTags.paysGww'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'guaranteedHours',
    headerName: t('search.globalSearch.order.gridTags.guaranteedHours'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'expectedHours',
    headerName: t('search.globalSearch.order.gridTags.expectedHours'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'reason',
    headerName: t('search.globalSearch.order.gridTags.reason'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'orderBillRate',
    headerName: t('search.globalSearch.order.gridTags.orderBillRate'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'startDate',
    headerName: t('search.globalSearch.order.gridTags.startdate'),
    width: 100,
  },
  {
    ...OrderDefaultCell,
    field: 'endDate',
    headerName: t('search.globalSearch.order.gridTags.orderEndDate'),
    width: 100,
  },
  {
    ...OrderDefaultCell,
    field: 'weeklyGrossPay',
    headerName: t('search.globalSearch.order.gridTags.weeklyGrossPay'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'accountManager',
    headerName: t('search.globalSearch.order.gridTags.accountManager'),
    width: 145,
  },
  {
    ...OrderDefaultCell,
    field: 'zipCode',
    headerName: t('search.globalSearch.order.gridTags.zip'),
    width: 120,
  },
  {
    ...OrderDefaultCell,
    field: 'businessRelationship',
    headerName: t('search.globalSearch.order.gridTags.businessRelationship'),
    width: 180,
  },
  {
    ...OrderDefaultCell,
    field: 'dateEntered',
    headerName: t('search.globalSearch.order.gridTags.ordersAge'),
    width: 100,
  },
  {
    ...OrderDefaultCell,
    field: 'dateVerified',
    headerName: t('search.globalSearch.order.gridTags.dateVerified'),
    width: 100,
  },
  {
    ...OrderDefaultCell,
    field: 'covidVaccinationRequired',
    headerName: t('search.globalSearch.order.gridTags.covidVaccinationRequired'),
    width: 90,
    description: 'COVID Vaccination Required',
  },
  {
    ...OrderDefaultCell,
    field: 'staffingVendor',
    headerName: t('search.globalSearch.order.gridTags.staffingVendor'),
    width: 145,
  },
  {
    ...OrderDefaultCell,
    field: 'techVendor',
    headerName: t('search.globalSearch.order.gridTags.techVendor'),
    width: 145,
  },
];

export const orderColumnVisibilityModel = {
  orderId: true,
  facility: true,
  facilityStatus: true,
  division: true,
  city: true,
  state: true,
  skillSets: true,
  orderType: true,
  orderPriority: true,
  vmsReqNo: false,
  positionsAvailable: false,
  maxFilesSent: false,
  filesPending: false,
  filesOut: false,
  mspClient: false,
  affiliate: false,
  unit: false,
  shifts: false,
  paysGww: false,
  guaranteedHours: false,
  expectedHours: false,
  reason: false,
  orderBillRate: false,
  startDate: false,
  endDate: false,
  weeklyGrossPay: false,
  accountManager: false,
  zipCode: false,
  businessRelationship: false,
  dateEntered: false,
  dateVerified: false,
  covidVaccinationRequired: false,
  staffingVendor: false,
  techVendor: false,
};
