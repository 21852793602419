import React from 'react';
import moment from 'moment';
import { convertNumberToBoolean } from 'app/helpers/converters';
import Discipline from 'app/assets/jsons/Discipline.json';
import State from 'app/assets/jsons/State.json';
import Country from 'app/assets/jsons/Country.json';
import { ICredentials } from 'app/models/Credentialing/Credentials';
import { ICustomHeader, gridControlType } from 'app/models/Grid';
import { CellsPerRow } from 'app/components/Common/Grid/SubGrid';

export const maxDate = '3000-01-01T00:00:00.000Z';
export const minDate = (date: string) => date.includes('0001-01-01');

export const getMetadataForReqID = (fieldLookups, item) => {
  return (
    fieldLookups
      ?.filter(lookup => lookup.requirementTypeID === item.requirementTypeID)
      ?.sort((a, b) => (a.sequence > b.sequence ? 1 : -1)) || []
  );
};

export const hasName = name => name && (name.first || name.last);

export const hasField = (fieldLookups, field) => {
  return fieldLookups?.find(lookup => lookup.lookUpField === field) !== undefined;
};

export const getCredentialName = (abbreviation, name) => {
  return (
    <>
      <span style={{ fontWeight: 'bold' }}>{abbreviation}</span>
      <span>{` - ${name?.replace(/\\\//g, '/') || 'N/A'}`}</span>
    </>
  );
};

export const getCredentialNameBold = (abbreviation, name) => {
  return (
    <>
      <span style={{ fontWeight: 'bold' }}>{abbreviation}</span>
      <span style={{ fontWeight: 'bold' }}>{` - ${name?.replace(/\\\//g, '/') || 'N/A'}`}</span>
    </>
  );
};

export const getCredentialNameNoFormat = (abbreviation, name) => {
  return `${abbreviation} - ${name?.replace(/\\\//g, '/') || 'N/A'}`;
};

export const getName = (fieldLookups, lookupType, name) => {
  return hasField(fieldLookups, lookupType)
    ? hasName(name)
      ? `${name?.first || ''} ${name?.last || ''}`
      : null
    : undefined;
};

export const getNameValue = name => {
  return hasName(name) ? `${name?.first || ''} ${name?.last || ''}` : null;
};

export const getLookups = (fieldLookups, lookupType, value?) => {
  return fieldLookups?.find(lookup => lookup.lookUpField === lookupType)?.lookups;
};

export const getDate = (fieldLookups, lookupType, value) => {
  return hasField(fieldLookups, lookupType)
    ? value && !minDate(value)
      ? moment(value).format('yyyy-MM-DDTHH:mm:ss')
      : null
    : undefined;
};

export const getDateNoFormat = (fieldLookups, lookupType, value) => {
  return hasField(fieldLookups, lookupType) ? (value && !minDate(value) ? value : maxDate) : 'N/A';
};

export const getHoverViewData = (fieldLookups, lookupType, value, hoverData) => {
  return hasField(fieldLookups, lookupType) && value !== null ? hoverData : false;
};

export const getDropDownValue = (fieldLookups, lookupType, value) => {
  const doesHaveField = fieldLookups?.find(lookup => lookup.lookUpField === lookupType);
  if (doesHaveField?.lookups.find(item => item.value === value) === undefined) return 'N/A';
  return doesHaveField !== undefined
    ? value === null || value === undefined || value === '' || value === ' '
      ? 'N/A'
      : value
    : 'N/A';
};

export const getDropDownDescription = (fieldLookups, lookupType, value, nullReturn = false) => {
  const doesHaveField = fieldLookups?.find(lookup => lookup.lookUpField === lookupType);
  const hasValue = doesHaveField?.lookups.find(item => item.value === value);
  if (hasValue === undefined) return !nullReturn ? 'N/A' : null;
  return doesHaveField !== undefined ? hasValue.description : !nullReturn ? 'N/A' : null;
};

export const getBoolean = (fieldLookups, lookupType, value) => {
  return hasField(fieldLookups, lookupType) ? value : undefined;
};

export const getBooleanNumber = (fieldLookups, lookupType, value) => {
  return hasField(fieldLookups, lookupType) ? (value !== null ? convertNumberToBoolean(value) : false) : undefined;
};

export const getText = (fieldLookups, lookupType, value) => {
  return hasField(fieldLookups, lookupType) ? value : undefined;
};
export const getDateText = (fieldLookups, lookupType, value) => {
  return hasField(fieldLookups, lookupType) && value && !minDate(value) ? moment(value).format('MM/DD/yyyy') : 'N/A';
};

export const getBooleanSort = (fieldLookups, lookupType, value) => {
  return value === null ? 3 : value ? 1 : 2;
};

export const getDisciplineValue = (fieldLookups, lookupType, value) => {
  const doesHaveField = fieldLookups?.find(lookup => lookup.lookUpField === lookupType);

  const hasValue = Discipline.disciplines.find(item => item.ID === value);

  if (hasValue === undefined) return 'N/A';
  return doesHaveField !== undefined ? hasValue?.ID : 'N/A';
};

export const getCountryValue = (fieldLookups, lookupType, value) => {
  const doesHaveField = fieldLookups?.find(lookup => lookup.lookUpField === lookupType);

  const hasValue = Country.countries.find(item => item.ID === value);

  if (hasValue === undefined) return 'N/A';
  return doesHaveField !== undefined ? hasValue?.ID : 'N/A';
};

export const getDisciplineFilterOptions = (fieldLookups, lookupType) => {
  return Discipline.disciplines.map(lookup => {
    return {
      value: lookup?.ID,
      description: lookup?.Description,
    };
  });
};

export const getStateValue = (fieldLookups, lookupType, value) => {
  const doesHaveField = fieldLookups?.find(lookup => lookup.lookUpField === lookupType);

  const hasValue = State.states.find(item => item.StateNumber === value);

  if (hasValue === undefined) return 'N/A';
  return doesHaveField !== undefined ? hasValue?.StateNumber : 'N/A';
};

export const getStateFilterOptions = (fieldLookups, lookupType) => {
  if (!hasField(fieldLookups, lookupType)) return [];
  return State.states.map(lookup => {
    return {
      value: lookup.StateNumber,
      description: lookup.Description,
    };
  });
};
export const getStateDescription = (fieldLookups, lookupType, value) => {
  const doesHaveField = fieldLookups?.find(lookup => lookup.lookUpField === lookupType);
  const hasValue = State.states.find(item => item.StateNumber === value);
  if (hasValue === undefined) return 'N/A';
  return doesHaveField !== undefined ? hasValue.Description : 'N/A';
};
export const getCommonPopUpforWorkAndEducationValue = (fieldLookups, lookupType, value) => {
  const doesHaveField = fieldLookups?.find(lookup => lookup.lookUpField === lookupType);

  const hasValue = Country.countries.find(item => item.ID === value);

  if (hasValue === undefined) return 'N/A';
  return doesHaveField !== undefined ? hasValue?.ID : 'N/A';
};

export const getCountryFilterOptions = (fieldLookups, lookupType) => {
  return Country.countries.map(lookup => {
    return {
      value: lookup?.ID,
      description: lookup?.Description,
    };
  });
};

export const detailValues: {
  // Metadata key
  [key: string]: {
    label: string; // Header label to display
    key: keyof ICredentials; // API key
    type?: 'name' | 'checkbox' | 'textarea' | 'dateLabel' | 'other';
    secondaryKey?: string;
    hoverViewDataTrue?: string; // verbiage for true hover data
    hoverViewDataFalse?: string; // verbiage for false hover data
  };
} = {
  TypeID: {
    label: 'Type',
    key: 'typeID',
  },
  DateVerified: {
    label: 'Verified Date',
    key: 'dateVerified',
    type: 'dateLabel',
  },
  VerifiedBy: {
    label: 'Verified By',
    key: 'verifiedByName',
    type: 'name',
  },
  DisciplinaryActionID: {
    label: 'Disciplinary Action',
    key: 'disciplinaryActionID',
    type: 'checkbox',
    hoverViewDataTrue: 'Disciplinary Action is available for this credential',
    hoverViewDataFalse: 'Disciplinary Action is not available for this credential',
  },
  LastUpdatedBy: {
    label: 'Updated By',
    key: 'lastUpdatedByName',
    type: 'name',
  },
  LastUpdate: {
    label: 'Updated Date',
    key: 'lastUpdate',
    type: 'dateLabel',
  },
  IssuedBy: {
    label: 'Issuing Body',
    key: 'issuedBy',
  },
  DateCompleted: {
    label: 'Date Completed',
    key: 'dateCompleted',
  },
  Contact: {
    label: 'Contact',
    key: 'contact',
  },
  CertificationID: {
    label: 'Certification',
    key: 'certificationID',
  },
  PrescriptiveAuthorityID: {
    label: 'Prescriptive Authority',
    key: 'prescriptiveAuthorityID',
    type: 'checkbox',
    hoverViewDataTrue: 'This credential has prescriptive authority',
    hoverViewDataFalse: 'This credential does not have prescriptive authority',
  },
  TestID: {
    label: 'Test',
    key: 'testID',
  },
  Score: {
    label: 'Score',
    key: 'score',
  },
  FacilityID: {
    label: 'Facility ID',
    key: 'facilityID',
  },
  ExpirationDate: {
    label: 'Expiration Date',
    key: 'expirationDate',
  },
  VerifiedID: {
    label: 'Verified',
    key: 'verifiedID',
    type: 'checkbox',
    hoverViewDataTrue: 'This credential has been verified',
    hoverViewDataFalse: 'This credential has not been verified',
  },
  CertificationRegistrationNum: {
    label: 'Certification/ Registration',
    key: 'certificationRegistrationNum',
  },
  StateID: {
    label: 'State',
    key: 'stateID',
  },
  Hours: {
    label: 'Hours',
    key: 'hours',
  },
  Comments: {
    label: 'Comments',
    key: 'comments',
    type: 'textarea',
  },
  LabResultID: {
    label: 'Results',
    key: 'labResultID',
  },
  ReadInMM: {
    label: 'Read in MM',
    key: 'readInMM',
    type: 'checkbox',
    hoverViewDataTrue: 'Read In MM is avilable for this credential',
    hoverViewDataFalse: 'Read In MM is not available for this credential',
  },
  PreviousTypeID: {
    label: 'Previous Type',
    key: 'previousTypeID',
  },
  PreviousReadInMM: {
    label: 'Previous Read in MM',
    key: 'previousReadInMM',
    type: 'checkbox',
    hoverViewDataTrue: 'Previous Read In MM is avilable for this credential',
    hoverViewDataFalse: 'Previous Read In MM is not available for this credential',
  },
  PreviousDateCompleted: {
    label: 'Previous Date Completed',
    key: 'previousDateCompleted',
  },
  PreviousResultID: {
    label: 'Previous Results',
    key: 'previousResultID',
  },
  TSCEFormID: {
    label: 'EForm ID',
    key: 'tsceFormID',
    type: 'name',
  },
  VisaTypeID: {
    label: 'Visa Type',
    key: 'visaTypeID',
  },
  FiledAsID: {
    label: 'Filed As',
    key: 'filedAsID',
  },
  OnFileID: {
    label: 'On File',
    key: 'onFileID',
    type: 'checkbox',
    hoverViewDataTrue: 'This credential is on file',
    hoverViewDataFalse: 'This credential is not on file',
  },
  OnFileDate: {
    label: 'On-File Date',
    key: 'onFileDate',
  },
  OnFileBy: {
    label: 'On-File By',
    key: 'onFileBy',
    type: 'name',
  },
  TravelerName: {
    label: 'Candidate Name',
    key: 'clinicianName',
  },
  DisciplineID: {
    label: 'Discipline',
    key: 'disciplineID',
  },
  ApplicationCompanyID: {
    label: 'Application Company',
    key: 'applicationCompanyID',
  },
  PanelTypeID: {
    label: 'Panel Type',
    key: 'panelTypeID',
  },
  ResultID: {
    label: 'Lab-Results',
    key: 'resultID',
    secondaryKey: 'labResultID',
  },
  BackOfCardID: {
    label: 'Back of Card',
    key: 'backOfCardID',
    type: 'checkbox',
    hoverViewDataTrue: 'Back of card is avilable for this credential',
    hoverViewDataFalse: 'Back of card is not available for this credential',
  },
  IssuedToID: {
    label: 'Issues To',
    key: 'issuedToID',
  },
  YearsSearched: {
    label: '# of Years Searched',
    key: 'yearsSearched',
  },
  DateIssued: {
    label: 'Issued Date',
    key: 'issuedDate',
  },
  DateReceived: {
    label: 'Date Received',
    key: 'dateReceived',
  },
  StatusID: {
    label: 'Status',
    key: 'statusID',
  },
  DegreeID: {
    label: 'Degree',
    key: 'degreeID',
  },
  School: {
    label: 'School',
    key: 'school',
  },
  CountryID: {
    label: 'Country',
    key: 'countryID',
  },
  CoverageAmountID: {
    label: 'Coverage Amount',
    key: 'coverageAmountID',
  },
  PolicyNumber: {
    label: 'Policy Number',
    key: 'policyNumber',
  },
  LicenseNumber: {
    label: 'License Number',
    key: 'licenseNumber',
  },
  AHAeCode: {
    label: 'AHA eCode',
    key: 'ahAeCode',
  },
  EnclosureItems: {
    label: 'Enclosure Item',
    key: 'enclosureItems',
  },
  DateSent: {
    label: 'Date Sent',
    key: 'dateSent',
  },
  PaperlessID: {
    label: 'Paperless',
    key: 'paperlessID',
    type: 'checkbox',
    hoverViewDataTrue: 'This credential is paperless',
    hoverViewDataFalse: 'This credential is not paperless',
  },
  PaperlessDate: {
    label: 'Paperless Date',
    key: 'paperlessDate',
  },
  PaperlessBy: {
    label: 'Paperless By',
    key: 'paperlessByName',
    type: 'name',
  },
  ReqTypeID: {
    label: 'Requirement Type',
    key: 'reqTypeID',
  },
  LevelID: {
    label: 'Compact',
    key: 'levelID',
    type: 'checkbox',
  },
  ManufacturerID: {
    label: 'Manufacturer',
    key: 'manufacturerID',
  },
  DoseNumber: {
    label: 'Dose Number',
    key: 'doseNumber',
  },
  Printed: {
    label: 'Printed',
    key: 'printed',
  },
  InsertDate: {
    label: 'Insert Date',
    key: 'insertDate',
  },
};

export const detailColumnsAndRows = (
  data: any,
  metadata: any[],
  fieldToIgnore: string[] = [],
  createFlatList: boolean = false,
  fileUpload: boolean = false,
): { columns: any; rows: any }[] => {
  const detailRows: { columns: any; rows: any }[] = [];
  let columns: any[] = [];
  let rows: any = {};
  metadata?.forEach(item => {
    if (!fieldToIgnore.includes(item.lookUpField)) {
      const value = detailValues[item.lookUpField];
      if (value !== undefined) {
        // Set the column header for the field
        const column: ICustomHeader = {
          title: value.key,
          value: value.label,
          control: {
            type:
              value.type === 'name'
                ? gridControlType.name
                : value.type === 'dateLabel'
                  ? gridControlType.dateLabel
                  : value.type === 'textarea'
                    ? gridControlType.textarea
                    : item.controlType === 'Checkbox'
                      ? gridControlType.checkbox
                      : item.controlType === 'Alphanumeric Textbox'
                        ? gridControlType.textfield
                        : item.controlType === 'Number Textbox'
                          ? gridControlType.textfieldnumber
                          : item.controlType === 'Drop Down List'
                            ? gridControlType.dropDown
                            : item.controlType === 'Date'
                              ? gridControlType.date
                              : gridControlType.label,
          },
        };
        if (value.type === 'checkbox') {
          column.hasHoverView = true;
        }

        // Set the value for the field
        switch (value.type) {
          case 'name':
            rows[value.key] = getNameValue(data[value.key]);
            break;
          case 'checkbox':
            rows[value.key] = convertNumberToBoolean(data[value.key]);
            rows[`${value.key}HoverViewData`] =
              data[value.key] !== null && data[value.key] !== undefined
                ? [convertNumberToBoolean(data[value.key]) ? value.hoverViewDataTrue : value.hoverViewDataFalse]
                : false;
            break;
          case 'dateLabel':
            rows[value.key] = getDateText(metadata, item.lookUpField, data[value.key]);
            break;
          default:
            switch (item.controlType) {
              case 'Date':
                rows[value.key] = getDate(metadata, item.lookUpField, data[value.key]);
                break;
              case 'Drop Down List':
                switch (item.lookUpField) {
                  case 'StateID':
                    rows[value.key] = getStateValue(metadata, item.lookUpField, data[value.key]);
                    rows[`${value.key}FilterOptions`] = getStateFilterOptions(metadata, item.lookUpField);
                    break;
                  case 'DisciplineID':
                    rows[value.key] = getDisciplineValue(metadata, item.lookUpField, data[value.key]);
                    rows[`${value.key}FilterOptions`] = getDisciplineFilterOptions(metadata, item.lookUpField);
                    break;
                  case 'CountryID':
                    rows[value.key] = getCountryValue(metadata, item.lookUpField, data[value.key]);
                    rows[`${value.key}FilterOptions`] = getCountryFilterOptions(metadata, item.lookUpField);
                    break;
                  default:
                    rows[value.key] = getDropDownValue(metadata, item.lookUpField, data[value.key]);
                    rows[`${value.key}FilterOptions`] = item.lookups;
                    break;
                }
                break;
              default:
                rows[value.key] = data[value.key];
                break;
            }
            break;
        }
        if (data[value.key] === undefined) rows[value.key] = undefined;

        columns.push(column);

        if (columns.length === CellsPerRow && !createFlatList) {
          detailRows.push({
            columns: columns,
            rows: [rows],
          });
          columns = [];
          rows = {};
        }
      }
    }
  });
  // add remaining data
  if (columns.length > 0 && !createFlatList) {
    detailRows.push({
      columns: columns,
      rows: [rows],
    });
  }

  if (fileUpload) {
    if (!createFlatList) {
      detailRows[detailRows.length - 1].columns.push({
        title: 'fileUpload',
        value: 'File Upload',
        control: {
          type: gridControlType.fileUpload,
        },
      });
      detailRows[detailRows.length - 1].rows[0]['fileUpload'] = null;
    } else {
      columns.push({
        title: 'fileUpload',
        value: 'File Upload',
        control: {
          type: gridControlType.fileUpload,
        },
      });
      rows['fileUpload'] = null;
    }
  }

  if (!createFlatList) return detailRows;
  else {
    return [{ columns: columns, rows: rows }];
  }
};
