import React, { useEffect, useMemo, useState } from 'react';
import { CircularProgress, Grid } from 'amn-ui-core';
import { XGrid } from 'app/components/Common/XGrid/XGrid';
import {
  AuditPlacementFilterName,
  AuditPlacementStatusCategory,
  getAuditPlacementColumns,
  getPlacementStatusNumber,
  placementFilterLabels,
} from './Helpers/AuditAddHelpers';
import { XGridToolbar } from '@AMIEWEB/Common';
import { makeStyles } from 'tss-react/mui';
import { candidateDetailsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { useSelector } from 'react-redux';
import { DetailsPage } from 'app/layout/pages/DetailsPage';
import { AuditPlacementSearchFilters } from './Helpers/AuditPlacementFilters';
import { AuditPlacementFilterChips } from './Helpers/AuditPlacementFilterChips';
import { useFormContext } from 'react-hook-form';
import { getPlacementStatusTreeOptions } from 'app/services/SharedServices/SharedServices';
import axios from 'axios';
import _ from 'lodash';
import { isItemIncluded } from 'app/ComponentLibrary/Filter/helper';
import moment from 'moment';
import { selectAssociatedPlacements } from '@AMIEWEB/Placement/AssociatedPlacements/store/associatedPlacements.selectors';
import { missingField } from 'app/constants';
import { getCandidateAuditDetails } from 'app/services/CandidateServices/CandidateServices';

const useStyles = makeStyles()({
  container: {
    height: '100%',
    '& .page': {
      padding: 0,
    },
  },
  grid: {
    width: '100%',
    minHeight: '275px',
  },
  expandedGrid: {
    width: '100%',
    minHeight: '335px',
  },
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
  },
});

const defaultValues = {
  startDate: [],
  endDate: [],
};

// Note: below values has to be changed when there is change in the service lookup values
const defaultAssignmentStatusOpts = [
  'On Assignment',
  'Assignment Complete',
  'Canceled by AMH',
  'Canceled By Facility',
  'Canceled By Traveler',
];

export const AuditPlacementGrid = props => {
  const { setFormValues, existingFormValues, isExpanded, disabled } = props;
  const { classes } = useStyles();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const [chips, setChips] = useState<any[]>([]);
  const { getValues, setValue } = useFormContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [filterSpecs, setFilterSpecs] = useState<any>();
  const [placementRows, setPlacementRows] = useState<any[]>([]);
  const { items: rows, count } = useSelector(selectAssociatedPlacements);
  const [columns, setColumns] = useState<any[]>([]);
  const [defaultRows, setDefaultRows] = useState<any[]>([]);
  const [defaultFilters, setDefaultFilters] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(50);
  const [cancelAuditTypePlacementId, setCancelAuditTypePlacementId] = useState([]);
  useEffect(() => {
    getPlacementRows();
    setColumns(getAuditPlacementColumns({ checkBoxChange, disabled }));
  }, []);
  useEffect(() => {
    setColumns(getAuditPlacementColumns({ checkBoxChange, cancelAuditTypePlacementId, disabled }));
  }, [cancelAuditTypePlacementId]);

  useEffect(() => {
    if (defaultRows.length > 0 && !defaultRows.find(item => item.isSelected)) {
      setValue(AuditPlacementFilterName.status, defaultFilters);
      onApplyFilter(AuditPlacementFilterName.status, defaultFilters);
    }
  }, [defaultRows, defaultFilters]);

  useMemo(() => {
    const newRows = [];
    placementRows?.forEach((value, key) => {
      const row = {
        ...value,
        isSelected: value.rowID === existingFormValues.pPlacementID,
      };
      newRows.push(row);
    });
    setPlacementRows(newRows);
  }, [existingFormValues.pPlacementID]);

  const fetchPlacementFilterSpecs = async placements => {
    const statusOpts = await getPlacementStatusTreeOptions();
    const placementIDs = placements.map(item => {
      return {
        id: item.placementId,
        name: item.placementId,
        value: item.placementId,
      };
    });
    const facilityNames = placements.map(item => {
      return {
        id: item.facility,
        name: item.facility,
        value: item.facility,
      };
    });
    const units = placements.map(item => {
      return {
        id: item.unit,
        name: item.unit,
        value: item.unit,
      };
    });
    const clinicalManager = placements
      .filter(x => x.clinicalManager !== missingField)
      .map(item => {
        return {
          id: item.clinicalManager,
          name: item.clinicalManager,
          value: item.clinicalManager,
        };
      });
    setFilterSpecs({
      placementId: _.uniqBy(placementIDs, 'id'),
      status: statusOpts,
      facilityName: _.uniqBy(facilityNames, 'id'),
      unit: _.uniqBy(units, 'id'),
      clinicalManager: _.uniqBy(clinicalManager, 'id'),
    });
    setPlacementFilters(statusOpts);
  };

  const getPlacementRows = async () => {
    setLoading(true);
    if (existingFormValues?.pType?.label === 'Cancellation' && candidateDetails?.travelerId > 0) {
      const res = await getCandidateAuditDetails(candidateDetails.travelerId);
      const cancellations = res?.data?.items?.filter(item => item.auditType === 'Cancellation');
      const cancelIds = cancellations?.map(cancel => cancel.placementId);
      setCancelAuditTypePlacementId(prevState => [...prevState, ...cancelIds]);
    }
    if (candidateDetails?.placement?.placementInfo?.length > 0) {
      if (count) {
        await fetchPlacementFilterSpecs(rows);
        const initialRows = rows?.map((item, index) => {
          return {
            rowID: item.placementId || index,
            activeID: item.placementId,
            placementID: item.placementId,
            status: item.status,
            statusSortOrder: getPlacementStatusNumber(item.status),
            startDate: item.startDate,
            startDateFull: item.startDateNoFormat,
            endDate: item.endDate,
            endDateFull: item.endDateNoFormat,
            amnDivision: item.division,
            clinicalManager: item.clinicalManager,
            firstName: candidateDetails.name.first,
            lastName: candidateDetails.name.last,
            facility: item.facility,
            unit: item.unit,
            isSelected: existingFormValues.pPlacementID === item.placementId,
          };
        });
        const placementSort = initialRows.sort((a, b) => b.statusSortOrder - a.statusSortOrder);
        const sortedRows = placementSort.sort(
          (a, b) => new Date(b.startDateFull || 0).getTime() - new Date(a.startDateFull || 0).getTime(),
        );
        setPlacementRows(sortedRows || []);
        setDefaultRows(sortedRows || []);
      }
    } else {
      await fetchPlacementFilterSpecs([]);
      setPlacementRows([]);
      setDefaultRows([]);
    }
    setLoading(false);
  };

  const setPlacementFilters = statusOpts => {
    const defaultFilters = statusOpts
      .filter(item => item.name === AuditPlacementStatusCategory.assignment)
      .map(item => {
        return {
          ...item,
          children: item.children.filter(x => defaultAssignmentStatusOpts.indexOf(x.name) > -1),
        };
      });
    setDefaultFilters(defaultFilters);
  };

  const onDeleteChip = (item: { name: string; label: string; value: any }) => {
    const { name, value } = item;
    if (name === AuditPlacementFilterName.startDate || name === AuditPlacementFilterName.endDate) {
      onApplyFilter(name, null);
      setValue(name, null);
      setChips(chips.filter(x => x.name !== name));
    } else if (name === AuditPlacementFilterName.status) {
      const currentFiltersSelected = getValues(name);
      if (value.includes('- All')) {
        const categoryName = value.split(' - ');
        const updatedValue = currentFiltersSelected?.filter(filterValue => filterValue.name !== categoryName?.[0]);
        setValue(name, updatedValue);
        onApplyFilter(name, updatedValue);
      } else {
        const updatedValue = currentFiltersSelected?.map(filterValue => {
          return {
            ...filterValue,
            children: filterValue.children.filter(x => x.name !== value),
          };
        });
        setValue(
          name,
          updatedValue.filter(filterValue => filterValue.children?.length !== 0),
        );
        onApplyFilter(
          name,
          updatedValue.filter(filterValue => filterValue.children?.length !== 0),
        );
      }
      setChips(
        chips.filter(x => {
          if (x.name === name) {
            return x.value !== value;
          }
          return true;
        }),
      );
    } else {
      const currentFiltersSelected = getValues(name);
      onApplyFilter(
        name,
        currentFiltersSelected?.filter(x => x.value !== value),
      );
      setValue(
        name,
        currentFiltersSelected?.filter(x => x.value !== value),
      );
      setChips(
        chips.filter(x => {
          if (x.name === name) {
            return x.value !== value;
          }
          return true;
        }),
      );
    }
  };

  const checkBoxChange = (rowID, isSelected, params) => {
    const newRows = [];
    params.api.getRowModels()?.forEach((value, key) => {
      const row = {
        ...value,
        isSelected: value.rowID === rowID ? isSelected : false,
      };
      newRows.push(row);
    });
    setPlacementRows(newRows);
    if (isSelected) {
      setFormValues({
        ...existingFormValues,
        pPlacementID: rowID,
      });
    } else {
      setFormValues({
        ...existingFormValues,
        pPlacementID: null,
      });
    }
  };

  const onApplyFilter = (name: string, value: any, isReset: boolean = false) => {
    const filteredRows = defaultRows.reduce((resultArray, item) => {
      if (
        isItemIncluded(
          item[AuditPlacementFilterName.placementID],
          name === AuditPlacementFilterName.placementID ? value : getValues(AuditPlacementFilterName.placementID) || [],
        ) &&
        isStatusIncluded(
          item[AuditPlacementFilterName.status],
          name === AuditPlacementFilterName.status ? value : getValues(AuditPlacementFilterName.status) || [],
        ) &&
        isItemIncluded(
          item[AuditPlacementFilterName.facility],
          name === AuditPlacementFilterName.facility ? value : getValues(AuditPlacementFilterName.facility) || [],
        ) &&
        isItemIncluded(
          item[AuditPlacementFilterName.unit],
          name === AuditPlacementFilterName.unit ? value : getValues(AuditPlacementFilterName.unit) || [],
        ) &&
        isItemIncluded(
          item[AuditPlacementFilterName.clinicalManager],
          name === AuditPlacementFilterName.clinicalManager
            ? value
            : getValues(AuditPlacementFilterName.clinicalManager) || [],
        ) &&
        (isDateIncluded(
          item[AuditPlacementFilterName.startDateFull],
          name === AuditPlacementFilterName.startDate ? value : getValues(AuditPlacementFilterName.startDate),
          name === AuditPlacementFilterName.endDate ? value : getValues(AuditPlacementFilterName.endDate),
        ) ||
          isDateIncluded(
            item[AuditPlacementFilterName.endDateFull],
            name === AuditPlacementFilterName.startDate ? value : getValues(AuditPlacementFilterName.startDate),
            name === AuditPlacementFilterName.endDate ? value : getValues(AuditPlacementFilterName.endDate),
          ))
      ) {
        item.isSelected = existingFormValues.pPlacementID === item.rowID;
        resultArray.push(item);
      }
      return resultArray;
    }, []);

    setPlacementRows(filteredRows);

    let newChips = isReset ? [] : chips.filter(item => item.name !== name);
    if (name === AuditPlacementFilterName.startDate || name === AuditPlacementFilterName.endDate) {
      if (value) {
        setChips([...newChips, { name: name, label: placementFilterLabels[name], value: value.label }]);
      }
    } else if (name === AuditPlacementFilterName.status) {
      value?.map(item => {
        if (isAllSelected(item.name, item.children)) {
          newChips.push({ name: name, label: placementFilterLabels[name], value: `${item.name} - All` });
        } else {
          item.children?.map(x => {
            newChips.push({ name: name, label: placementFilterLabels[name], value: x.name });
          });
        }
      });
      setChips(newChips);
    } else {
      value?.map(item => {
        newChips.push({ name: name, label: placementFilterLabels[name], value: item.name });
      });
      setChips(newChips);
    }
  };

  const onChangeFilter = (name: string, value: any) => {
    if (name === AuditPlacementFilterName.startDate || name === AuditPlacementFilterName.endDate) {
      onApplyFilter(name, value);
    }
  };

  const isStatusIncluded = (status, values) => {
    if (values?.length <= 0) return true;
    let isStatusFound = false;
    values?.map(item => {
      if (item.name === status || item.children?.find(x => x.name === status)) {
        isStatusFound = true;
      }
    });
    return isStatusFound;
  };

  const compareDates = (initialDate, dateStart, dateEnd) => {
    if (dateStart && !dateEnd) {
      if (initialDate) {
        return moment(initialDate).isSame(dateStart);
      } else return false;
    } else if (!dateStart && dateEnd) {
      if (initialDate) {
        return moment(initialDate).isSame(dateEnd);
      } else return false;
    } else if (dateStart && dateEnd) {
      if (initialDate) {
        return moment(initialDate).isSameOrAfter(dateStart) && moment(initialDate).isSameOrBefore(dateEnd);
      } else return false;
    }
    return true;
  };

  const isDateIncluded = (rowItem, startDate, endDate) => {
    return compareDates(rowItem, startDate?.startDate, endDate?.endDate);
  };

  const isAllSelected = (categoryName, children) => {
    if (
      (categoryName === AuditPlacementStatusCategory.offer && children.length === 7) ||
      (categoryName === AuditPlacementStatusCategory.submission && children.length === 9) ||
      (categoryName === AuditPlacementStatusCategory.booking && children.length === 9) ||
      (categoryName === AuditPlacementStatusCategory.assignment && children.length === 6)
    )
      return true;
    return false;
  };

  const resetFilter = () => {
    setValue(AuditPlacementFilterName.startDate, null);
    setValue(AuditPlacementFilterName.endDate, null);
    setValue(AuditPlacementFilterName.placementID, null);
    setValue(AuditPlacementFilterName.facility, null);
    setValue(AuditPlacementFilterName.unit, null);
    setValue(AuditPlacementFilterName.clinicalManager, null);
    setChips([]);
    setValue(AuditPlacementFilterName.status, defaultFilters);
    onApplyFilter(AuditPlacementFilterName.status, defaultFilters, true);
  };

  const onPageChange = (page: number) => {
    setPageNumber(page);
    // const pagination = apiRef?.current?.state?.pagination;
    // if (pagination) handleFetchData(pagination.pageSize, page + 1, gridState.sortedColumn);
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    props.setPageChanged(true);
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <DetailsPage
          enablePageActionSpace
          paddingBottom={isExpanded ? '25px' : '5px'}
          filters={
            <AuditPlacementSearchFilters
              defaultValues={defaultValues}
              filterSpecs={filterSpecs}
              resetFilter={resetFilter}
              onApplyFilter={onApplyFilter}
              onChangeFilter={onChangeFilter}
            />
          }
          chips={<AuditPlacementFilterChips chips={chips} onDeleteChip={onDeleteChip} />}
          stickIconArray={[4]}
          elevation={0}
        >
          {loading ? (
            <div style={{ minHeight: '265px' }}>
              <CircularProgress className={classes.MuiCircularProgressRoot} />
            </div>
          ) : (
            <XGrid
              title="Placements"
              rows={placementRows}
              columns={columns}
              getRowId={model => model.rowID}
              experimentalFeatures={{ columnGrouping: true, newEditingApi: true }}
              rowHeight={60}
              headerHeight={70}
              hideFooter={defaultRows.length < 50}
              height={300}
              paginationMode={'client'}
              defaultRowsPerPage={50}
              page={pageNumber}
              inDialog
              pageSize={pageSize}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              disableSelectionOnClick={true}
              components={{
                Toolbar: XGridToolbar,
              }}
              className={isExpanded ? classes.expandedGrid : classes.grid}
              toolbarProps={{
                refreshButton: true,
                resetButton: true,
                resetColumns: () => setColumns(getAuditPlacementColumns({ checkBoxChange, disabled })),
                refreshGrid: () => {
                  setColumns(getAuditPlacementColumns({ checkBoxChange, disabled }));
                },
              }}
            />
          )}
        </DetailsPage>
      </Grid>
    </Grid>
  );
};
