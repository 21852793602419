/* eslint-disable tss-unused-classes/unused-classes */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { Cancel } from 'app/components/Common/CancelModal/Cancel';
import { pageAttributesActions } from 'app/components/Placement/PlacementDetails/Edit/store/placementPageAttributes.redux';
import {
  ActionType,
  IDueDateView,
  ITaskAttachmentList,
  ITaskComments,
  TaskDescription,
  TaskStatus,
} from 'app/models/Tasks/Tasks';
import { EditTaskModal } from '../EditTask/EditTaskModal';
import { taskDetailsActions } from '../store/Tasks.redux';
import {
  selectAttachmentDeleted,
  selectedCategoryList,
  selectedUpdatedId,
  selectNewCommentCreated,
  selectShowStatusModal,
  selectInternalTaskDetailsData,
  selectTaskStatusUpdateFlag,
  SelectedContainer,
  selectedIsExternalTaskUpdated,
  selectExternalTaskDetailsData,
  selectDueDateFirstView,
  selectIsTaskUpdateFlag,
  selectInternalTaskChangedFlag,
} from '../store/Tasks.selectors';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { Delete } from 'app/components/Common/DeleteModal/Delete';
import { httpSuccess } from 'app/services/serviceHelpers';
import { EditExternalTaskModal } from '../EditTask/EditExternalTaskModal';
import { TaskDetailsLeftpanel } from './TaskDetailsLeftPanel';
import { selectUser } from 'oidc/user.selectors';
import { updateTaskStatus } from 'app/services/TaskServices/TaskServices';
import { selectTaskComments } from '../store/Tasks.selectors';
import { TaskDetailsInternalRightPanel } from './TaskDetailsInternalRightPanel';
import { TaskDetailsExternalRightPanel } from './TaskDetailsExternalRightPanel';
import { useSubTaskCreationNotification } from '@AMIEWEB/Notification/Tasks/SubTasks/SubtaskCreationNotification';
import { StatusUpdateModal } from '../StatusUpdate/StatusUpdateModal';
import { TaskCommentModal } from './TaskCommentsModal';
import { selectPlacementDetails } from 'store/redux-store/placement-details/selectors';
import { IPlacementDetails } from 'app/models/Placement/PlacementDetails';
import { selectActiveCoverage } from 'store/redux-store/user-coverage/selectors';
import { FormatDate } from '@AMIEWEB/Notification/Tasks/Common/FormatData';
import { fetchTaskIdforStatusUpdate } from './helper';
import { ExternalTaskStatusUpdateModal } from './ExternalTaskStatusUpdateModal';
import { TaskDetailsSkeleton } from './TaskDetailsSkeleton';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { getSharedDeskAssociatedUser } from '../EditTask/helper';
import { useStatusUpdateNotification } from '@AMIEWEB/Notification/Tasks/StatusUpdateNotification';

const taskDetailsModalStyles = makeStyles<{ expanded: boolean; isPopperOpen: boolean }>()(
  (theme, { expanded, isPopperOpen }) => ({
    modalContainer: {
      '& .MuiDialog-paper': {
        maxWidth: expanded ? 'none' : '960px',
        height: expanded ? 'none' : '666px',
        color: '#333333',
        '& .MuiDialogContent-root': {
          padding: '0',
          overflow: 'hidden',
          '& .dialogContentRootNoActions': {
            padding: '0px',
          },
        },
      },
    },
    gridContainers: {
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 128px)',
      height: '100%',
      padding: '16px 24px 30px 30px',
    },
    descriptionTitle: {
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0px',
      opacity: 1,
      color: '#333333',
      fontWeight: 500,
      margin: '16px 2px 8px 36px',
      '&.MuiTypography-root': {
        fontSize: '14px',
      },
    },
    gridRightContainer: {
      display: 'flex',
      flexDirection: 'column',
      overflow: isPopperOpen ? 'hidden' : 'hidden auto',
      maxHeight: 'calc(100vh - 64px)',
      height: '100%',
      '&::-webkit-scrollbar': {
        width: '0px',
      },
    },
    detailsRightColumn: {
      flexDirection: 'column',
      display: 'flex',
      height: 'inherit',
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      height: '27px',
    },
    flexCenterContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    validationLoader: {
      left: 'calc(50% - 20px)',
      top: '50%',
      position: 'absolute',
    },
    userIcon: {
      width: '24px',
      height: '24px',
    },
    statusIconBtn: {
      padding: '0',
      marginRight: '2px',
    },
    statusIconDeniedBtn: {
      padding: '0',
      marginRight: '2px',
      color: 'red',
    },
    statusIcon: {
      paddingTop: '0.1rem',
      width: '24px',
      height: '24px',
    },
    taskLinks: {
      fontSize: '14px',
    },
    subtaskContainer: {
      flexGrow: 1,
      display: 'flex',
    },
    subtaskList: {
      backgroundColor: '#F5F5F5',
      padding: '24px',
      borderTop: '2px solid #D0D0D0',
      flexGrow: 1,
    },
    taskTitle: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '20px',
      fontFamily: 'Roboto',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    floatRight: {
      flex: 1,
      textAlign: 'right',
    },
    textItems: {
      color: '#555555',
      fontSize: '14px',
    },
    avatars: {
      height: '18px',
      width: '18px',
      backgroundColor: '#555555',
      color: '#FFFFFF',
      fontSize: '8px',
    },
    truncateText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    truncateLines: {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      overflow: 'hidden',
    },
    moreIcon: {
      padding: '0px !important',
      margin: 'auto 0px',
    },
    menu: {
      width: '80px',
      height: '40px',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 2px 8px #0000004D',
      borderRadius: '4px',
      opacity: '1',
      zIndex: 999,
    },
    menuItem: {
      width: '80px',
      height: '40px',
      background: '#EFEFEF 0% 0% no-repeat padding-box',
      opacity: '1',
    },
    statusBadge: {
      textTransform: 'uppercase',
      borderRadius: '4px',
      fontSize: '9px',
      fontFamily: 'Roboto',
      color: '#FFFFFF',
      padding: '4px 4px',
    },
    attachmentBtn: {
      '&:hover': {
        display: '-webkit-inline-box',
        overflow: 'hidden',
        justifyContent: 'unset',
      },
      padding: '0',
      height: '36px',
      minWidth: '36px',
      background: '#F7F7F7 0% 0% no-repeat padding-box',
      border: `1px solid ${theme.palette.system.fadedGrey}`,
      borderRadius: '5px',
      opacity: '1',
      boxShadow: 'none',
      font: 'normal normal normal 11px/20px Roboto',
      letterSpacing: '0',
      color: '#333333',
    },
    attachmentIcon: {
      width: '34px',
    },
    arrow: {
      '&:before': {
        border: '1px solid #E6E8ED',
        boxShadow: '0px 3px 3px #c7c7c7',
      },
      color: theme.palette.common.white,
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      border: '1px solid #E6E8ED',
      color: '#4A4A4A',
      fontSize: '12px',
      lineHeight: '20px',
      maxWidth: 'none',
      boxShadow: '0px 3px 3px #c7c7c7',
    },
    attachmentChip: {
      margin: '3px 3px 3px 3px',
      borderRadius: '5px',
      background: '#f7f7f7',
      border: `1px solid ${theme.palette.system.fadedGrey}`,
      height: '26px',
      zIndex: 2,
      width: '10rem',
      fontSize: '0.75rem',
      WebkitTouchCallout: 'none',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none',
    },
    leftBlock: {},
    rightBlock: {
      display: 'inline-block',
      margin: '3px',
    },
    attachments: {
      display: 'flex',
    },
    chipLabel: {
      width: 'inherit',
      fontFamily: 'Roboto',
    },
    fileIcon: {
      color: '#616161',
      marginRight: '0px',
      marginLeft: '5px',
      width: '16px',
    },
    noSelect: {
      userSelect: 'none',
      msUserSelect: 'none',
      MozUserSelect: 'none',
      WebkitUserSelect: 'none',
    },
    createdBy: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    createdByRight: {
      marginLeft: '8px',
    },
    createdByAvatar: {
      backgroundColor: '#006FB9',
    },
    creatorDetails: {
      display: 'flex',
    },
    assignedToAvatar: {
      backgroundColor: '#34B233',
    },
    subtypeText: {
      textAlign: 'left',
      color: '#555555',
      opacity: 1,
      fontSize: '12px',
      lineHeight: '20px',
      fontFamily: 'Roboto',
      paddingLeft: '5px',
    },
    subTypeHeading: {
      textAlign: 'left',
      color: theme.palette.components.typography.color,
      lineHeight: '20px',
      opacity: 1,
      fontSize: '12px',
      fontFamily: 'Roboto',
      fontWeight: 500,
      minWidth: '3.3rem',
    },
    replyBtnContainer: {
      margin: 'auto 0px auto 12px',
    },
    replyBtn: {
      padding: '0px',
    },
    replyBtnIcon: {
      '&:hover': {
        color: '#41A2DF',
      },
    },
    parentDivider: {
      marginTop: '12px',
      marginBottom: '12px',
      border: '1px solid #D0D0D0',
    },
    headerRight: {
      marginLeft: 'auto',
      display: 'flex',
    },
  }),
);

export const SubTaskOnContext = createContext<{ expanded: boolean }>({ expanded: false });

export const TaskDetails = ({
  taskID,
  open,
  flag,
  container = 0,
  handleClose,
  setDetailsModalOpen,
  externalDetails,
  isExternal,
  setStatusChanged,
  statusChanged,
}: {
  taskID?: number;
  open: boolean;
  flag?: boolean;
  container?: number;
  handleClose: () => void;
  setDetailsModalOpen: (e) => void;
  externalDetails?: any;
  isExternal?: boolean;
  setStatusChanged?: (e) => void;
  statusChanged?: boolean;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const taskUpdate = useSelector(selectedUpdatedId);
  const userInfo = useSelector(selectUser);
  const taskStatusFlag = useSelector(selectTaskStatusUpdateFlag);
  const updateTaskData = useSelector(selectInternalTaskChangedFlag);
  const internalTaskDetails = useSelector(selectInternalTaskDetailsData);
  const externalTaskDetails = useSelector(selectExternalTaskDetailsData);
  const selectFirstDueDateView = useSelector(selectDueDateFirstView);
  const showStatusModal = useSelector(selectShowStatusModal);
  const currentContainer = useSelector(SelectedContainer);
  const categories = useSelector(selectedCategoryList);
  const activeCoverage = useSelector(selectActiveCoverage);
  const attachmentDeleted = useSelector(selectAttachmentDeleted);
  const commentCreated = useSelector(selectNewCommentCreated);
  const isTaskUpdated = useSelector(selectIsTaskUpdateFlag);
  const { promiseInProgress: isCallingAPI } = usePromiseTracker({ area: 'get-task-details', delay: 0 });
  const [editExternalOpen, setEditExternalOpen] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [subtaskModalOpen, setSubtaskModalOpen] = useState(false);
  const [attachments, setAttachments] = useState<ITaskAttachmentList[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [attachmentIndex, setAttachmentIndex] = useState(0);
  const isUpdated = useSelector(selectedIsExternalTaskUpdated);
  const userId = userInfo?.userInfo?.employeeId;
  const commentList = useSelector(selectTaskComments);
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);
  const { classes } = taskDetailsModalStyles({ expanded, isPopperOpen });
  const [subtaskReplyRecipients, setSubtaskReplyRecipients] = useState<any>();
  const [comment, setComment] = useState<any>();
  const [task, setTask] = useState<any>();
  const [enableTaskName, setEnableTaskName] = useState<boolean>(false);
  const [subtask, setSubTaskName] = useState<any>('');
  const foundPlacement: IPlacementDetails = useSelector(selectPlacementDetails);
  useSubTaskCreationNotification();
  useStatusUpdateNotification();

  const getTaskDetails = () => {
    if (!isExternal && taskID) {
      dispatch(taskDetailsActions.getTaskAction(taskID));
    } else {
      dispatch(taskDetailsActions.getExternalTaskDetails(externalDetails));
    }
  };

  const closeDetails = () => {
    dispatch(taskDetailsActions.setErrorObject({ SAVE_TASK_COMMENT: false }));
    dispatch(taskDetailsActions.setInternalTaskDetailsModal(undefined));
    dispatch(taskDetailsActions.setExternalTaskDetailsModal(undefined));
    dispatch(taskDetailsActions.setTaskStatusUpdateModalId(undefined));
    dispatch(taskDetailsActions.setCurrentComments(undefined));
    dispatch(taskDetailsActions.setTaskNotificationContainer(undefined));
    dispatch(taskDetailsActions.setNotificationPrimaryContext(undefined));
    dispatch(taskDetailsActions.setDuedateFirstView([]));
    handleClose();
    dispatch(taskDetailsActions.setTaskStatusUpdateFlag(undefined));
    dispatch(taskDetailsActions.setExistingTaskAttachments(undefined));
    dispatch(taskDetailsActions.setTaskUpdatedId(null));
  };

  const updateTaskReadStatus = taskData => {
    if (!isExternal) {
      const employeeId = !isNullOrUndefined(userInfo?.userInfo?.sharedProfile)
        ? parseInt(userInfo?.userInfo?.sharedProfile?.employeeId)
        : userId;
      const { excludedTaskIds, selectedTaskIdsforUpdate } = fetchTaskIdforStatusUpdate(
        taskData,
        commentList,
        employeeId,
      );
      if (selectedTaskIdsforUpdate?.length > 0) {
        const statusUpdateBody = {
          statusUpdateReason: 'Update status from New to Pending',
          statusId: TaskStatus.Pending,
          excludeTaskIds: excludedTaskIds ?? [],
          taskId: 0,
          rootTaskId: taskData?.rootTaskId,
          recipientId: employeeId,
          statusUpdatedByName: !isNullOrUndefined(userInfo?.userInfo?.sharedProfile)
            ? `${userInfo?.userInfo?.sharedProfile?.firstName} ${userInfo?.userInfo?.sharedProfile?.lastName}`
            : `${userInfo?.userInfo?.firstName} ${userInfo?.userInfo?.lastName}`,
          statusUpdatedById: employeeId,
          statusUpdatedDate: FormatDate(new Date()),
          sharedDeskAssociatedUser: isNullOrUndefined(userInfo?.userInfo?.sharedProfile)
            ? undefined
            : getSharedDeskAssociatedUser(userInfo?.userInfo, ActionType.EditTask, TaskDescription.StatusUpdate),
        };
        setReadStatus(statusUpdateBody);
      }
    }
  };

  const updateCoverageReadStatus = taskData => {
    const coverageRecipients: any[] = [];
    const taskRecipientsList = [...taskData?.assignedTo, ...taskData?.cc];
    const availableRecipients = taskRecipientsList.filter(obj1 =>
      activeCoverage.some(obj2 => obj2.coveredEmployeeId === obj1.id && obj1?.statusId === TaskStatus.New),
    );

    if (availableRecipients)
      availableRecipients?.map(data => {
        coverageRecipients?.push(data);
        return coverageRecipients;
      });
    if (commentList?.length > 0) {
      commentList?.map(commentData => {
        const commentRecipientsList = [...commentData?.tos, ...commentData?.ccs];
        const availableCommentRecipients = commentRecipientsList.filter(obj1 =>
          activeCoverage.some(obj2 => obj2.coveredEmployeeId === obj1.id && obj1?.statusId === TaskStatus.New),
        );

        if (availableCommentRecipients)
          availableCommentRecipients?.map(data => {
            coverageRecipients?.push(data);
            return availableCommentRecipients;
          });
        return availableCommentRecipients;
      });
    }
    const uniqueDataArray = Array.from(new Map(coverageRecipients.map(item => [item.id, item])).values());
    if (uniqueDataArray?.length > 0) {
      uniqueDataArray?.forEach(recipient => {
        const { excludedTaskIds, selectedTaskIdsforUpdate } = fetchTaskIdforStatusUpdate(
          taskData,
          commentList,
          recipient?.id,
        );
        if (selectedTaskIdsforUpdate?.length > 0) {
          const taskBody = {
            statusUpdateReason: 'Update status from New to Pending',
            statusId: TaskStatus.Pending,
            taskId: 0,
            excludeTaskIds: excludedTaskIds ?? [],
            rootTaskId: taskData?.rootTaskId,
            recipientId: recipient?.id,
            statusUpdatedByName: `${userInfo?.userInfo?.firstName} ${userInfo?.userInfo?.lastName}`,
            statusUpdatedById: userId,
            statusUpdatedDate: FormatDate(new Date()),
            taskCoverageAction: {
              coveredByEmployeeId: userInfo?.userInfo?.employeeId,
              coveredByEmployeeFirstName: userInfo?.userInfo?.firstName,
              coveredByEmployeeLastName: userInfo?.userInfo?.lastName,
              coveredForEmployeeId: recipient?.id,
              coveredForEmployeeFirstName: recipient?.firstName,
              coveredForEmployeeLastName: recipient?.lastName,
              actionById: userId,
              actionDate: FormatDate(new Date()),
              actionType: 3,
            },
          };
          setReadStatus(taskBody);
        }
      });
    }
  };

  const setReadStatus = async statusUpdateBody => {
    if (statusChanged) setStatusChanged(null);
    if (statusUpdateBody?.rootTaskId) {
      await updateTaskStatus(statusUpdateBody).then(data => {
        if (data) {
          setStatusChanged(true);
        }
      });
    } else {
      setStatusChanged(false);
    }
  };

  const handleDeleteAttachment = () => {
    const existingAttachments = [...attachments];
    const currentAttachment = existingAttachments[attachmentIndex];
    const body = {
      attachmentUrl: currentAttachment?.attachmentUrl,
      taskId: currentAttachment?.taskId,
    };
    dispatch(taskDetailsActions.deleteAttachments(body));
    existingAttachments?.splice(attachmentIndex, 1);
    setAttachments(existingAttachments);
    setDeleteModalOpen(false);
    dispatch(taskDetailsActions.setExistingTaskAttachments(existingAttachments));
  };

  useEffect(() => {
    if (attachmentDeleted && !httpSuccess(attachmentDeleted?.status) && taskID) {
      getTaskDetails();
    }
  }, [attachmentDeleted]);

  useEffect(() => {
    if (updateTaskData && taskID) {
      getTaskDetails();
    }
  }, [updateTaskData]);

  useEffect(() => {
    if (taskUpdate && taskID) {
      getTaskDetails();
    }
  }, [taskUpdate]);

  useEffect(() => {
    if (taskID) {
      getTaskDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskID, isUpdated]);

  useEffect(() => {
    if (taskID && statusChanged) {
      getTaskDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusChanged]);

  useEffect(() => {
    if (categories?.length === 0) {
      dispatch(taskDetailsActions.getCategoryListAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (foundPlacement?.header) {
      dispatch(taskDetailsActions.setPlacementBrandId(foundPlacement?.header?.brandId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundPlacement?.header?.placementId]);

  useEffect(() => {
    const dueDateView = [...selectFirstDueDateView];
    if (internalTaskDetails) {
      const taskDueDateView: IDueDateView = {
        isFirstView: true,
        taskId: internalTaskDetails?.taskId,
      };
      dueDateView?.push(taskDueDateView);
      if (commentList?.length > 0) {
        commentList?.map((commentData: ITaskComments) => {
          const commentDueDateView: IDueDateView = {
            isFirstView: true,
            taskId: commentData?.taskId,
          };
          dueDateView?.push(commentDueDateView);
          return [];
        });
      }
    }
    const filteredList = dueDateView.filter(
      (data, index, self) => self.findIndex(c => c['taskId'] === data['taskId']) === index,
    );
    dispatch(taskDetailsActions.setDuedateFirstView(filteredList));
  }, [internalTaskDetails, commentList]);

  useEffect(() => {
    if (taskStatusFlag === 'REFRESH' && taskID) {
      getTaskDetails();
      dispatch(taskDetailsActions.setTaskStatusUpdateModalId(undefined));
    }
    if (taskStatusFlag === 'SUCCESS') {
      dispatch(taskDetailsActions.setTaskStatusUpdateFlag(undefined));
      setDetailsModalOpen(false);
    }
    if (commentCreated && taskID) {
      getTaskDetails();
      dispatch(taskDetailsActions.setCommentsCreated(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskStatusFlag, commentCreated]);

  useEffect(() => {
    const newAttachments = internalTaskDetails?.attachments;
    if (newAttachments) setAttachments(newAttachments);
  }, [internalTaskDetails?.attachments]);

  useEffect(() => {
    if (isExternal) {
      if (externalTaskDetails?.taskId > 0 && open) {
        updateTaskReadStatus(externalTaskDetails);
      }
    } else {
      if (internalTaskDetails?.taskId > 0 && open) {
        updateTaskReadStatus(internalTaskDetails);
        updateCoverageReadStatus(internalTaskDetails);
      }
    }
  }, [commentList, open]);

  return (
    <GenericDialog
      open={open}
      className={classes.modalContainer}
      variant="blue"
      draggable
      disableEnforceFocus
      fullWidth
      onClose={() => {
        closeDetails();
      }}
      dialogTitleProps={
        isExternal
          ? {
              text: t('notification.taskDetails.modalTitleExternal'),
              closeButton: true,
              expandable: true,
              onExpandChange: expand => {
                setExpanded(expand);
              },
            }
          : {
              text: t('notification.taskDetails.modalTitle'),
              closeButton: true,
              expandable: true,
              onExpandChange: expand => {
                setExpanded(expand);
              },
            }
      }
    >
      <Grid
        container
        data-testid="task-details-modal-container"
        style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}
      >
        {isCallingAPI || isTaskUpdated ? (
          <TaskDetailsSkeleton isSubTask={false} />
        ) : (
          <React.Fragment>
            {!isExternal && internalTaskDetails && (
              <>
                <SubTaskOnContext.Provider value={{ expanded: expanded }}>
                  <TaskDetailsLeftpanel
                    classes={classes}
                    taskDetails={internalTaskDetails}
                    isExternal={isExternal}
                    currentContainer={currentContainer}
                    expanded={expanded}
                  />
                  <TaskDetailsInternalRightPanel
                    classes={classes}
                    taskDetails={internalTaskDetails}
                    isExternal={isExternal}
                    setEditExternalOpen={setEditExternalOpen}
                    setCreateModalOpen={setCreateModalOpen}
                    attachments={attachments}
                    setDeleteModalOpen={setDeleteModalOpen}
                    setAttachmentIndex={setAttachmentIndex}
                    setSubtaskReplyRecipients={setSubtaskReplyRecipients}
                    setSubtaskModalOpen={setSubtaskModalOpen}
                    setComment={setComment}
                    setTask={setTask}
                    enableTaskName={enableTaskName}
                    setEnableTaskName={setEnableTaskName}
                    setSubTaskName={setSubTaskName}
                    statusChanged={statusChanged}
                    setIsPopperOpen={setIsPopperOpen}
                  />
                </SubTaskOnContext.Provider>
              </>
            )}
            {isExternal && externalTaskDetails && (
              <>
                <TaskDetailsLeftpanel
                  classes={classes}
                  taskDetails={externalTaskDetails}
                  isExternal={isExternal}
                  currentContainer={currentContainer}
                  expanded={expanded}
                />
                <TaskDetailsExternalRightPanel
                  classes={classes}
                  taskDetails={externalTaskDetails}
                  isExternal={isExternal}
                  setEditExternalOpen={setEditExternalOpen}
                  setCreateModalOpen={setCreateModalOpen}
                  attachments={attachments}
                  setDeleteModalOpen={setDeleteModalOpen}
                  setAttachmentIndex={setAttachmentIndex}
                  setIsPopperOpen={setIsPopperOpen}
                />
              </>
            )}
            {createModalOpen && (
              <EditTaskModal
                open={createModalOpen}
                handleClose={() => {
                  dispatch(pageAttributesActions.setPopperOpen(false));
                  setCreateModalOpen(false);
                }}
                editTaskData={internalTaskDetails}
                placementDataPresent={flag}
                taskDetail={true}
                container={container || currentContainer}
              />
            )}
            <Cancel
              openDialog={showCancelModal}
              handleConfirmAction={() => closeDetails()}
              handleCancelAction={() => {
                setShowCancelModal(false);
              }}
            />
          </React.Fragment>
        )}

        {isExternal && showStatusModal?.isModalOpen && showStatusModal?.container === 'details' && (
          <ExternalTaskStatusUpdateModal open={showStatusModal?.isModalOpen} task={externalTaskDetails} />
        )}

        {!isExternal && showStatusModal?.isModalOpen && showStatusModal?.container === 'details' && (
          <StatusUpdateModal
            open={showStatusModal?.isModalOpen}
            version={showStatusModal?.version}
            task={internalTaskDetails}
          />
        )}
        {showStatusModal?.isModalOpen && showStatusModal?.container === 'subTask' && (
          <StatusUpdateModal
            open={showStatusModal?.isModalOpen}
            version={showStatusModal?.version}
            task={showStatusModal?.subTaskDetails}
          />
        )}
      </Grid>
      <Delete
        openDialog={deleteModalOpen}
        handleDeleteAction={() => handleDeleteAttachment()}
        handleCancelAction={() => {
          setDeleteModalOpen(false);
        }}
      />
      {editExternalOpen && (
        <EditExternalTaskModal
          open={editExternalOpen}
          handleClose={() => {
            dispatch(pageAttributesActions.setPopperOpen(false));
            setEditExternalOpen(false);
          }}
          editTaskData={externalTaskDetails}
          container={container}
          taskDetail={true}
        />
      )}
      {subtaskModalOpen && (
        <TaskCommentModal
          openCommentModal={subtaskModalOpen}
          externalTask={false}
          parentTaskDetails={internalTaskDetails}
          container={container}
          replyRecipients={subtaskReplyRecipients}
          comment={comment}
          subtask={subtask}
          setSubtaskModalOpen={setSubtaskModalOpen}
          setComment={setComment}
          setTask={setTask}
          task={task}
          enableTaskName={enableTaskName}
        />
      )}
    </GenericDialog>
  );
};
