import { StatusDropDownValues } from '@AMIEWEB/Common/StatusDropDown/StatusDropDown';
import { IUserRoles } from '@AMIEWEB/Settings/AutoTaskManagement/componenets/UserRolesSection/UserRolesSection';
import { getSelectedRoles } from '@AMIEWEB/Settings/AutoTaskManagement/helper';
import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { IAutoTaskTaskStatus, IRefreshActionType, ISaveTypeOnPageChange, IUpdateAutoTaskManagementPayload } from 'app/models/AutoTaskManagement/AutoTaskManagement';
import { call, put, select } from 'redux-saga/effects';
import { CONFIRMATION_ACTION, getAssignedRoleIds, getStatusDetails } from './helper';
import { getAutoTaskEmployeeRoles, getAutoTaskGridData } from './request.saga';
import {
  selectAutoTaskGridData,
  selectAutoTaskManagement,
  selectAutoTaskManagementStatusLookUp,
  selectAutoTaskUpdateResponse,
  selectSelectedGridRow,
  selectSelectedRowId,
  selectUserRolesOptionsList,
} from './selectors';
import { placementRequestsAction } from './slice';
import { updateAutoTaskManagement } from './update.saga';

/**Handles row selected in grid
 * @param action contains id of selected row
 */
export function* setSelectedTaskIdAndRow(action: PayloadAction<number>) {
  try {
    //* get roles options list
    const userRolesOptionsList = yield select(selectUserRolesOptionsList);
    //*Get current data in grid
    const gridData = yield select(selectAutoTaskGridData);
    const selectedRow = gridData.find(row => row.autoTaskCategoryId === action.payload);
    //*Set selected row Id
    yield put(placementRequestsAction.setSelectedData(action.payload));
    //*Set selected complete row data
    yield put(placementRequestsAction.setSelectedRow(selectedRow));
    //*Set assigned role ids from selected row
    yield put(placementRequestsAction.setAssignedRoles(selectedRow.assignedRoles || []));
    //*Set  assigned role ids to  selected ids in chips
    yield put(
      placementRequestsAction.setSelectedUserRoles(
        getSelectedRoles(selectedRow.assignedRoles || [], userRolesOptionsList),
      ),
    );
    //*Set the default status
    const defaultStatus: IAutoTaskTaskStatus = { status: selectedRow.status, statusId: selectedRow.statusId };
    yield put(placementRequestsAction.currentStatus(defaultStatus));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'setSelectedTaskIdAndRow',
        area: 'srcstore\redux-storeplacement-requests\business.saga.ts',
      },
    });
  }
}
/**Handles the User role selection in checkbox
 * @param action contains an array of selected userroles
 */

export function* setAssignedUserRoles(action: PayloadAction<IUserRoles[]>) {
  try {
    const autoTaskManagementStatusOptions = yield select(selectAutoTaskManagementStatusLookUp);
    //*Set  assigned role ids to  selected ids in chips
    yield put(placementRequestsAction.setSelectedUserRoles(action.payload || []));
    //*Set assigned role ids from selected roles
    yield put(placementRequestsAction.setAssignedRoles(getAssignedRoleIds(action.payload) || []));
    //*If no user role selected change status to INACTIVE
    if (!action.payload.length)
      yield put(
        placementRequestsAction.currentStatus(
          getStatusDetails(StatusDropDownValues.INACTIVE, autoTaskManagementStatusOptions),
        ),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'setAssignedUserRoles',
        area: 'srcstore\redux-storeplacement-requests\business.saga.ts',
      },
    });
  }
}
/**Handles the Auto Task Confirmation Dailog User Action
 * @param action type of action
 */

export function* handleAutoTaskConfirmDialogAction(action: PayloadAction<string>) {
  try {
    const defaultRowData = yield select(selectSelectedGridRow);
    if (action.payload === CONFIRMATION_ACTION.SAVE) {
      yield call(updateAutoTaskManagement)
      const res = yield select(selectAutoTaskUpdateResponse)
      if (res?.responseCode === 0) {
        yield call(handleRefreshGrid, {
          type: placementRequestsAction.business_handleRefreshGrid.type,
          payload: { softReset: true },
        });
      }
    } else if (action.payload === CONFIRMATION_ACTION.DISCARD) {
      //* get roles options list
      const userRolesOptionsList = yield select(selectUserRolesOptionsList);

      //*Set the default status
      const defaultStatus: IAutoTaskTaskStatus = { status: defaultRowData.status, statusId: defaultRowData.statusId };
      yield put(placementRequestsAction.currentStatus(defaultStatus));
      //*Set assigned role ids from selected row
      yield put(placementRequestsAction.setAssignedRoles(defaultRowData.assignedRoles || []));
      //*Set  assigned role ids to  selected ids in chips
      yield put(
        placementRequestsAction.setSelectedUserRoles(
          getSelectedRoles(defaultRowData.assignedRoles || [], userRolesOptionsList),
        ),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'handleAutoTaskConfirmDialogAction',
        area: 'srcstore\redux-storeplacement-requests\business.saga.ts',
      },
    });
  }
}
export function* handleAutoTaskStatusChange(action: PayloadAction<string>) {
  try {
    const autoTaskManagementStatusOptions = yield select(selectAutoTaskManagementStatusLookUp);
    yield put(placementRequestsAction.currentStatus(getStatusDetails(action.payload, autoTaskManagementStatusOptions)));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'handleAutoTaskStatusChange',
        area: 'srcstore\redux-storeplacement-requests\business.saga.ts',
      },
    });
  }
}
export function* handleRefreshGrid(action:PayloadAction<IRefreshActionType>) {
  try {
    yield call(getAutoTaskGridData) //service call to get data
    if(!action.payload.softReset){
      const response = yield select(selectAutoTaskGridData);
      const  rowId = !!response?.length && response[0].autoTaskCategoryId;
      yield put(placementRequestsAction.business_setSelectedTaskIdAndRow(rowId));

    }else{
      const rowId = yield select(selectSelectedRowId);
      yield put(placementRequestsAction.business_setSelectedTaskIdAndRow(rowId));
    }


  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'handleRefreshGrid',
        area: 'srcstore\redux-storeplacement-requests\business.saga.ts',
      },
    });
  }
}
export function* handleApplyFilters(action : PayloadAction<any>) {
  try {
    const { responseData } = yield  select(selectAutoTaskManagement);
    const { filters , subFilter }  = action.payload
    const filteredData =
    filters?.status?.length > 0
    ? responseData.filter(item => filters?.status?.some(filter => filter?.value === item?.status))
    : responseData;
    const subFilteredData = subFilter?.status
    ? filteredData.filter(item => subFilter?.status?.some(filter => filter?.value === item?.status))
    : filteredData;
    yield put(placementRequestsAction.setFilters(filters));
    yield put(placementRequestsAction.setSelectedFilters(subFilter || {}));
    yield put(placementRequestsAction.setGridData(subFilteredData));
    yield put(placementRequestsAction.business_setSelectedTaskIdAndRow(subFilteredData[0].autoTaskCategoryId))

  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'handleRefreshGrid',
        area: 'srcstore\redux-storeplacement-requests\business.saga.ts',
      },
    });
  }
}
export function* handleSaveActionFromDailog(action : PayloadAction<{id:number}>) {
  try {
   yield put(placementRequestsAction.business_handleAutoTaskConfirmDialogAction(CONFIRMATION_ACTION.SAVE));
   yield put(placementRequestsAction.business_setSelectedTaskIdAndRow(action.payload.id))


  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'handleSaveActionFromDailog',
        area: 'srcstore\redux-storeplacement-requests\business.saga.ts',
      },
    });
  }
}
export function* initiateAutoTaskManagementPage() {
  try {
    yield put(placementRequestsAction.getAutoTaskManagementStatusLookUp());
    yield call(getAutoTaskEmployeeRoles);
    yield call(getAutoTaskGridData) //Call the get api for auto task 

    //*Select the first row
    const response = yield select(selectAutoTaskGridData);
    const  rowId = !!response?.length && response[0].autoTaskCategoryId;
    yield call(setSelectedTaskIdAndRow, {
      type: placementRequestsAction.business_setSelectedTaskIdAndRow.type,
      payload: rowId,
    });

  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'initiateAutoTaskPage',
        area: 'srcstore\redux-storeplacement-requests\business.saga.ts',
      },
    });
  }
}
export function* handleSaveOnPageChange(action:PayloadAction<ISaveTypeOnPageChange>) {
  try {
      if (!action.payload.pageChange) {
        yield call(handleAutoTaskConfirmDialogAction, {
          type: placementRequestsAction.business_handleAutoTaskConfirmDialogAction.type,
          payload: CONFIRMATION_ACTION.SAVE,
        });
        yield call(setSelectedTaskIdAndRow, {
          type: placementRequestsAction.business_setSelectedTaskIdAndRow.type,
          payload: action.payload.selectedId,
        });
      }

  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'initiateAutoTaskPage',
        area: 'srcstore\redux-storeplacement-requests\business.saga.ts',
      },
    });
  }
}



export const getUpdateAutoTaskManagmentPaylod = (
  selectedRow,
  userId,
  currentRoles,
  currentStatusId,
):IUpdateAutoTaskManagementPayload => {
  const payload = {
    autoTaskCategoryId: selectedRow.autoTaskCategoryId,
    userId: userId,
    assignedRoles: currentRoles,
    statusId: currentStatusId,
    timestamp: selectedRow.timestamp,
  };
  return payload;
};
