import { CandidateNotification } from 'app/components/Notification/CandidateNotifiation/CandidateNotifiationWrapper';
import React from 'react';

export const XGridNotificationCell = ({
  channel,
  displayKey,
  v2,
  tooltip,
  grid = 'recruitment',
}: {
  channel: any;
  displayKey: any;
  v2?: boolean;
  tooltip?: boolean;
  grid?: string;
}) => params => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <CandidateNotification
        placementId={params.row.placementId}
        channel={channel}
        displayText={params.row[displayKey]}
        standardMargin
        v2={v2}
        tooltip={tooltip}
        gridKey={grid}
        recruiter={params?.row?.recruiterName}
        recruiterId={params?.row?.recruiterId}
      />
    </div>
  );
};
