import { Expandable } from 'app/components/Common/Expandable/Expandable';
import React from 'react';
import { Grid } from 'amn-ui-core';
import { StatusTitle } from '../../Titles/StatusTitle';
import { RequirementsContainer } from '../RequirementsContainer';
import { ConfirmationsTable } from '../TableComponents/Confirmations/ConfirmationsTable';
import { TransportationWrapper } from './Transportation/TransportationWrapper';
import { useTranslation } from 'react-i18next';
import { DatesConfirmed } from './Confirmations/DatesConfirmed';
import { TravelConfirmed } from './Confirmations/TravelConfirmed';
import { useOfferRequirementCounts } from '../RequirementCounts/OfferRequirementCounts';
import { useDispatch, useSelector } from 'react-redux';
import { selectOfferInfo } from '../../../../../../../../store/redux-store/placement-summary/selectors';
import { useFormContext } from 'react-hook-form';
import { RequirementFilterKey, requirementType } from 'app/models/Placement/PlacementSummary';
import {
  cleanChildFilterByKey,
  deriveFilter,
  useAccordionState,
  useRequirementFilter,
} from '../../Helpers/requirementFilter';
import { placementSummaryActions } from '../../../../../../../../store/redux-store/placement-summary/slice';
import {
  selectOfferConfirmationRequirementTypes,
  selectOfferTransportationRequirementTypes,
} from 'store/redux-store/placement-details/selectors';
import { DnsCleared } from './Confirmations/DNSCleared';
import { TravelDetailsTable } from './TravelDetails/TravelDetailsTable';

export const Offer = ({ loading }) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const transportationChosen = watch('transportationChosen');
  const {
    confirmation: { reqCounts: confirmationCounts },
    transportation: { reqCounts: transportationCounts },
    requirementFilter,
  } = useSelector(selectOfferInfo);

  const confimrationTypes = useSelector(selectOfferConfirmationRequirementTypes);
  const transportationTypes = useSelector(selectOfferTransportationRequirementTypes);

  const dispatch = useDispatch();

  const { filter: offerFilter } = useRequirementFilter('offer', requirementFilter);
  const { filter: confirmationFilter } = useRequirementFilter('offer-confirmation', requirementFilter);
  const { filter: transportationFilter } = useRequirementFilter('offer-transportation', requirementFilter);

  const { expand: expandConfirmationReq } = useAccordionState(confirmationFilter, confimrationTypes);
  const { expand: expandTransportationReq } = useAccordionState(transportationFilter, transportationTypes);

  useOfferRequirementCounts({ transportation: transportationChosen });

  const handleFilterChange = (key: RequirementFilterKey, state: requirementType[]) => {
    if (requirementFilter) {
      const filterTree = deriveFilter(key, requirementFilter, state);
      cleanChildFilterByKey('offer-confirmation', filterTree, confirmationCounts);
      cleanChildFilterByKey('offer-transportation', filterTree, transportationCounts);
      dispatch(
        placementSummaryActions.setFilterTreeByKey({
          key: 'offerInfo',
          filterTree,
        }),
      );
    }
  };

  return (
    <Expandable
      id="placement-offer-container"
      unmountOnExit={false}
      loading={loading}
      preventForceClose
      expanded={!!offerFilter.length}
      titleComponent={
        <StatusTitle
          title={t('placement.profile.summary.offer.title')}
          counts={{
            aggregate: confirmationCounts.aggregate + transportationCounts.aggregate,
            passed: (confirmationCounts.passed || 0) + (transportationCounts.passed || 0),
            failed: (confirmationCounts.failed || 0) + (transportationCounts.failed || 0),
            risks: (confirmationCounts.risks || 0) + (transportationCounts.risks || 0),
          }}
          filter={offerFilter}
          handleFilterChange={state => handleFilterChange('offer', state)}
        />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* CONFIRMATIONS */}
          <RequirementsContainer
            title={t('placement.profile.summary.offer.confirmations.title')}
            counts={confirmationCounts}
            unmountOnExit={false}
            filter={confirmationFilter}
            handleFilterChange={state => handleFilterChange('offer-confirmation', state)}
            expanded={expandConfirmationReq}
          >
            <ConfirmationsTable
              id="placement-offer-confirmations-table"
              cellTwoTitle={t('placement.profile.summary.offer.confirmations.lastUpdatedBy')}
              cellThreeTitle={t('placement.profile.summary.offer.confirmations.lastUpdatedDate')}
            >
              <DnsCleared hasRequirements={confirmationCounts.aggregate > 0} />
              <DatesConfirmed hasRequirements={confirmationCounts.aggregate > 0} />
              <TravelConfirmed hasRequirements={confirmationCounts.aggregate > 0} />
            </ConfirmationsTable>
          </RequirementsContainer>
        </Grid>
        <Grid item xs={12}>
          {/* TRANSPORTATIONS */}
          <RequirementsContainer
            title={t('placement.profile.summary.offer.transportation.title')}
            counts={transportationCounts}
            unmountOnExit={false}
            filter={transportationFilter}
            handleFilterChange={state => handleFilterChange('offer-transportation', state)}
            expanded={expandTransportationReq}
          >
            <TransportationWrapper />
          </RequirementsContainer>
        </Grid>
        {transportationChosen?.transportation !== 'Fly' ? null : (
          <>
            <Grid item xs={12}>
              <Expandable
                id="travel-details-container"
                unmountOnExit={false}
                loading={loading}
                preventForceClose
                expanded={false}
                titleComponent={<StatusTitle title={t('placement.profile.summary.offer.travelDetails.title')} />}
              >
                <TravelDetailsTable />
              </Expandable>
            </Grid>
          </>
        )}
      </Grid>
    </Expandable>
  );
};
