import React from 'react';
import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { FloatType } from 'app/models/Unit/CreateUnit';
import { useTranslation } from 'react-i18next';
import { InnerIconValueSection } from '../../../OrderCreationForm/DefineUnit/UnitPreview/Common/InnerIconValueSection';
import { ValueContainer } from '../../../OrderCreationForm/DefineUnit/UnitPreview/Common/ValueContainer';
import EditIcon from '@mui/icons-material/Edit';
import {
  PreviewContent,
  PreviewContentTitle,
} from '../../../OrderCreationForm/DefineUnit/UnitPreview/UnitPreviewDesign';

interface Props {
  data: {
    float: FloatType | null;
  };
  handleEditOrder?: () => void;
}

const useStyles = makeStyles()({
  container: {
    '&:hover': {
      '& [data-id="edit-icon"]': {
        opacity: 1,
        transition: 'opacity 0.25s',
      },
    },
  },
  editItem: {
    opacity: 0,
    transition: 'opacity 0.25s',
  },
  editIcon: {
    height: '20px',
    width: '20px',
    cursor: 'pointer',
    color: '#8E9195',
  },
});

export const OrderDetailsFloat = (props: Props) => {
  const { float } = props.data;
  const { handleEditOrder } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <PreviewContent variant="outlined" elevation={0}>
          <Grid container direction="column" spacing={3} className={classes.container}>
            <Grid item>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <PreviewContentTitle>{t('order.createUnit.float.title')}</PreviewContentTitle>
                </Grid>
                <Grid item className={classes.editItem} data-id="edit-icon">
                  <EditIcon
                    id={'OrderDetailsFloatEditTrigger'}
                    className={classes.editIcon}
                    onClick={handleEditOrder}
                  />
                </Grid>
              </Grid>
            </Grid>
            {float && (
              <>
                <InnerIconValueSection id={'OrderDetailsFloatIsRequiredLbl'} isRequired={float.isRequired} />
                <Grid item>
                  <ValueContainer
                    labelContent={[
                      { label: t('order.createUnit.float.floatToUnit'), size: 4 },
                      { label: t('order.createUnit.float.floatToLocation'), size: 4 },
                      { label: t('order.createUnit.float.floatToMiles'), size: 4 },
                    ]}
                    valueContent={[
                      { value: float?.floatToUnit || '-', size: 4 },
                      { value: float?.floatToLocation || '-', size: 4 },
                      { value: float?.floatToMiles.toString() || '-', size: 4 },
                    ]}
                    id={'OrderDetailsFloatContainer'}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </PreviewContent>
      </Grid>
    </>
  );
};
