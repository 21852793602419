import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { toast } from 'react-toastify';
import { selectUser } from 'oidc/user.selectors';
import { trackEvent } from 'app-insights/appInsightsTracking';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { taskDetailsActions, taskDetailsReducer, taskDetailsSliceKey } from 'app/components/Tasks/store/Tasks.redux';
import ClearIcon from '@mui/icons-material/Clear';
import { taskDetailsSaga } from 'app/components/Tasks/store/Tasks.saga';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import { useHistory } from 'react-router-dom';
import { jobPreferncesActions as jobPreferencesActions } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/JobPreferencesTab/store/JobPreferences.redux';
import { getCandidateById } from 'app/services/CandidateServices/CandidateServices';
import { useDecision } from '@optimizely/react-sdk';
import { ff_candidate_ui_preferences, ff_WorkExperience_Enhancement } from 'app/constants/FeatureFlags/Candidate/Keys';
import { IGetActivityFeedQuery } from 'app/models/ActivityFeed/IGetActivityFeed';
import { isPositiveNumber } from 'app/helpers/componentHelpers';
import { SignalREventType } from 'app/enums/Common';
import { StyledToastContainer } from '@AMIEWEB/Notification/Tasks/utils';
import { getEmployeeId, getNotificationType } from '@AMIEWEB/Notification/Tasks/Common_v2/utils';
import { AlertFeed } from '@AMIEWEB/Notification/Tasks/Common_v2/Feeds/AlertFeed';
import { INotificationFeedResponse, NotificationCategory } from 'app/models/Tasks/Tasks';
import { CreatePlacementWrapper } from '@AMIEWEB/Placement/CreatePlacement';
import { SessionKey } from 'utils/customHooks/sessionStorage/sessionHelpers';
import { selectDismissNotificationId } from '@AMIEWEB/Tasks/store/Tasks.selectors';

const useStyles = makeStyles()(() => ({
  centerItem: {
    alignSelf: 'center',
    justifyContent: 'center',
    padding: '0 15px',
  },
  closeIcon: {
    display: 'block',
    height: '27px',
    width: '27px',
    background: '#FFFFFF',
    border: '1px solid #E2DFDF',
    borderRadius: '15px',
    padding: '3px',
    boxShadow: '0px 0px 6px #00000040',
    cursor: 'pointer',
    transition: '0.3s ease',
    position: 'absolute',
    left: '-12px',
    top: '-12px',
  },
}));

export const OrderMatchNotification = () => {
  const { classes } = useStyles();
  useInjectReducer({ key: taskDetailsSliceKey, reducer: taskDetailsReducer });
  useInjectSaga({ key: taskDetailsSliceKey, saga: taskDetailsSaga });
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser);
  const userPreference = useSelector(selectUserPreference);
  const dismissedNotificationId = useSelector(selectDismissNotificationId);
  const notificationPreference = userPreference?.notificationPreference?.value;
  const preference = React.useRef(notificationPreference);
  const multiLine = true;
  const [preferenceId, setPreferenceId] = useState<string>(null);
  const [candidateId, setCandidateId] = useState<string>(null);
  const [recordDetails, setRecordDetails] = useState<any>();
  const [brandId, setBrandId] = useState<string>(null);
  const [enableJobPreferences] = useDecision(ff_candidate_ui_preferences);
  const [hasWorkExperienceTab] = useDecision(ff_WorkExperience_Enhancement);

  useEffect(() => {
    if (dismissedNotificationId) {
      toast.dismiss(dismissedNotificationId);
      dispatch(taskDetailsActions.setDismissNotificationId(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dismissedNotificationId]);
  let orderPopUp = true;
  const closeNotification = (e, closeToast) => {
    closeToast();
    e.stopPropagation();
  };

  const CloseButton = ({ closeToast }) => {
    return <ClearIcon className={classes.closeIcon} onClick={e => closeNotification(e, closeToast)} />;
  };

  const handleClick = contentProps => {
    contentProps?.associateRecords?.forEach(record => {
      if (record?.name === 'CandidateId') {
        setCandidateId(record?.value);
      }
      if (record?.name === 'BrandId') {
        setBrandId(record?.value);
      }
      if (record?.name === 'PreferenceId') {
        setPreferenceId(record?.value);
      }
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const navigateToOrderMatchGrid = (candidateId, brandId, preferenceId) => {
    if (isPositiveNumber(candidateId) && isPositiveNumber(brandId)) {
      getCandidateById(candidateId, brandId).then(response => {
        dispatch(
          jobPreferencesActions.getJobPreferencesAction({
            travelerId: parseInt(candidateId),
            brandId: parseInt(brandId),
          }),
        );
      });

      let redirectTabNumber = 5;
      if (enableJobPreferences?.enabled && hasWorkExperienceTab?.enabled) {
        redirectTabNumber = 7;
      } else if (
        (enableJobPreferences?.enabled && !hasWorkExperienceTab?.enabled) ||
        (!enableJobPreferences?.enabled && hasWorkExperienceTab?.enabled)
      ) {
        redirectTabNumber = 6;
      } else if (!enableJobPreferences?.enabled && !hasWorkExperienceTab?.enabled) {
        redirectTabNumber = 5;
      }

      dispatch(jobPreferencesActions.setPreferenceId(preferenceId));
      if (!window.location.href.includes(`/candidate/${candidateId}/${brandId}?tab=${redirectTabNumber}`)) {
        history.push(`/candidate/${candidateId}/${brandId}?tab=${redirectTabNumber}`);
      }
      resetValues();
    }
  };

  const resetValues = () => {
    setCandidateId(null);
    setBrandId(null);
    setPreferenceId(null);
  };

  React.useEffect(() => {
    preference.current = notificationPreference;
  }, [notificationPreference]);

  const getUnreadCounts = () => {
    const UnreadPayload: IGetActivityFeedQuery = {
      ReadStatus: false,
      Activities: {
        ContactId: `${getEmployeeId(user?.userInfo)}`,
        UseSubTypes: [],
        UseTypes: [],
      },
    };
    dispatch(taskDetailsActions.getUnReadNotificationsCounts(UnreadPayload));
  };

  useEffect(
    () => {
      const hubConnection = user?.userInfo?.hubConnection;
      if (hubConnection) {
        hubConnection.on('BroadcastOrderMatch', (connectedUser, _eventName, log) => {
          trackEvent({
            type: 'event',
            name: `SignalR Hub`,
            properties: {
              NotificationType: SignalREventType.OrderMatch,
              User: connectedUser,
              CorrelationId: log?.correlationId,
              Log: log,
              message: `Received order match notification for user: ${connectedUser}`,
            },
          });
          const contentProps = {
            id: log?.id,
            name: log?.message?.tos.find(Boolean).name,
            body: log?.message?.body,
            associateRecords: log?.associatedRecords,
            useSubType: log?.useSubType,
          };
          //*Increment the unread count in redux store
          if (connectedUser === getEmployeeId(user?.userInfo)) {
            dispatch(
              taskDetailsActions.incrementNotificationCount({
                notificationType: getNotificationType({
                  useType: log?.useType,
                  useSubType: log?.useType,
                  channelType: log?.channel,
                }),
              }),
            );
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
          orderPopUp = preference.current.filter(val => val.name === 'Order Matches')[0]?.enabled;
          if (!orderPopUp) {
            getUnreadCounts();
          }
          const notification: INotificationFeedResponse = {
            contentProps: { ...contentProps, useType: SignalREventType.OrderMatch, isNotificationUnread: true },
            channel: NotificationCategory.Alert,
            multiLine: false,
          };
          orderPopUp &&
            toast(
              <AlertFeed
                key={0}
                index={0}
                notifyData={notification}
                setRecordDetails={setRecordDetails}
                pushNotification={true}
              />,
              {
                toastId: log.id,
                containerId: 'orderMatch',
                position: 'top-right',
                closeButton: true,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 5000,
              },
            );
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useEffect(() => {
    if (candidateId !== null && brandId !== null && preferenceId !== null) {
      navigateToOrderMatchGrid(candidateId, brandId, preferenceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateId, brandId, preferenceId]);

  return (
    <>
      <Grid item className={classes.centerItem}></Grid>
      <div style={{ position: 'fixed' }}>
        {multiLine && (
          <StyledToastContainer enableMultiContainer containerId={'orderMatch'} limit={3} closeButton={CloseButton} />
        )}
      </div>
      {recordDetails?.openCreatePlacementForm &&
        recordDetails?.CandidateId &&
        recordDetails?.OrderId &&
        recordDetails?.BrandId && (
          <CreatePlacementWrapper
            isDefaultCandidate
            isDefaultOrder
            open={recordDetails?.openCreatePlacementForm}
            sessionKey={SessionKey.createPlacementNotifications}
            handleClose={() => setRecordDetails(prev => ({ ...prev, openCreatePlacementForm: false }))}
            initialArgs={{
              candidateId: parseInt(recordDetails.CandidateId),
              brandId: parseInt(recordDetails.BrandId),
              orderId: parseInt(recordDetails.OrderId),
            }}
          />
        )}
    </>
  );
};
