import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { IconButton, useTheme } from 'amn-ui-core';
import React from 'react';

export const NavigationProfileCycle = ({
  handleClickUp,
  handleClickDown,
  disabledUp = false,
  disabledDown = false,
}: {
  handleClickUp?: () => void;
  handleClickDown?: () => void;
  disabledUp?: boolean;
  disabledDown?: boolean;
}) => {
  const theme = useTheme();
  return (
    <div style={{ marginLeft: theme.spacing(2) }}>
      <IconButton
        color="tertiary"
        size="small"
        onClick={handleClickUp}
        disabled={disabledUp}
        
      >
        <KeyboardArrowUp />
      </IconButton>
      <IconButton
        color="tertiary"
        size="small"
        onClick={handleClickDown}
        disabled={disabledDown}
      >
        <KeyboardArrowDown />
      </IconButton>
    </div>
  );
};
