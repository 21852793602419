import React, { useState } from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import FormControl from '@mui/material/FormControl';
import { MenuItem, TextField } from 'amn-ui-core';
import { selectOrderLicenses } from '@AMIEWEB/Order/Store/Order.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { IOrderLicense, orderDataActions } from '@AMIEWEB/Order/Store/Order.redux';
import { selectFacility } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { selectOrderRequirementTypes } from '@AMIEWEB/Order/Store/OrderCreation.selectors';

interface GridCellProps {
  [key: string]: any;
}

export const CompactStatusOptions = [
  {
    id: 1,
    name: 'Single State',
    value: 'Single State',
  },
  {
    id: 2,
    name: 'Multi State',
    value: 'Multi State',
  },
];

const useStyles = makeStyles()({
  formControl: {
    minWidth: 180,
  },
});

const CompactStatusRenderer = React.memo(function CompactStatusRenderer(cellParams: GridCellProps) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedValues, setSelectedValues] = useState<any>(cellParams?.selectedValues);
  const orderLicenses = useSelector(selectOrderLicenses);
  const facilityData = useSelector(selectFacility);
  const { states } = useSelector(selectOrderRequirementTypes);

  const onSelectFieldChange = data => {
    const facilityState = states?.find(state => state?.value === facilityData?.facilityResponse?.location?.state);
    setSelectedValues(data?.target?.value);
    const orderLicensesDetails: IOrderLicense[] = [...orderLicenses];
    if (orderLicenses && orderLicenses?.length > 0 && data) {
      const licenseDetailsIndex = orderLicensesDetails?.findIndex(data => data?.licenseId === cellParams.id);
      const selectedLicenseOptionDetails = orderLicenses?.find(data => data?.licenseId === cellParams.id);
      const licenseStateData = [
        {
          state: facilityState,
          isRequired: false,
          documentAttachmentRequired: false,
        },
      ];
      if (licenseDetailsIndex !== -1) {
        let licenseStateDetails;
        if (cellParams?.isNewOrderCretion) {
          licenseStateDetails =
            data?.target?.value === 'Single State'
              ? licenseStateData
              : [{ isRequired: false, documentAttachmentRequired: false }];
        } else {
          licenseStateDetails =
            data?.target?.value === 'Single State'
              ? licenseStateData
              : [{ isRequired: true, documentAttachmentRequired: false }];
        }
        const selectedLicenseDetail: IOrderLicense = {
          ...selectedLicenseOptionDetails,
          compactStatus: data?.target?.value,
          licenseStateDetails: licenseStateDetails,
          isError: { compactStatus: false, state: false },
        };
        orderLicensesDetails[licenseDetailsIndex] = { ...selectedLicenseDetail };
      } else {
        if (selectedLicenseOptionDetails) {
          const selectedLicenseDetail: IOrderLicense = {
            ...selectedLicenseOptionDetails,
            compactStatus: data?.target?.value,
            licenseStateDetails:
              data?.target?.value === 'Single State'
                ? licenseStateData
                : [{ isRequired: true, documentAttachmentRequired: false }],
            isError: { compactStatus: false, state: false },
          };
          orderLicensesDetails.push(selectedLicenseDetail);
        }
      }
    }
    dispatch(orderDataActions.setOrderLicenseDetails(orderLicensesDetails));
  };

  return (
    <>
      <FormControl variant="outlined" className={classes.formControl}>
        <TextField
          required={true}
          select
          variant="outlined"
          size={'small'}
          style={{ width: '165px' }}
          error={cellParams?.isError}
          value={selectedValues}
          onChange={value => onSelectFieldChange(value)}
          disabled={cellParams?.isDisabled}
          label={!selectedValues ? t('order.license.compactStatus') : ''}
          InputLabelProps={{ shrink: false }}
        >
          {CompactStatusOptions?.map(option => {
            return <MenuItem value={option.value}>{option.name}</MenuItem>;
          })}
        </TextField>
      </FormControl>
    </>
  );
});

export const CompactStatusRenderCell = (props: {
  param: GridCellParams;
  isDisabled: boolean;
  isNewOrderCretion?: boolean;
}) => {
  const [isFieldHidden, setFieldHidden] = useState<boolean>(true);
  const orderLicenses = useSelector(selectOrderLicenses);
  const [selectedValues, setSelectedValues] = useState<any>(null);
  const [isError, setIsError] = useState<boolean>(false);

  React.useEffect(() => {
    if (props.param?.id && orderLicenses?.find(data => data?.licenseId === props.param?.id)) {
      setFieldHidden(false);
      orderLicenses?.map(data => {
        if (data?.licenseId === props.param?.id) {
          setIsError(data?.isError?.compactStatus);
          if (data?.compactStatus) {
            setSelectedValues(data?.compactStatus);
          }
        }
      });
    } else {
      setFieldHidden(true);
      setSelectedValues(null);
    }
  }, [orderLicenses]);
  return !isFieldHidden ? (
    <CompactStatusRenderer
      {...props.param}
      isDisabled={props?.isDisabled}
      selectedValues={selectedValues}
      isError={isError}
      isNewOrderCretion={props?.isNewOrderCretion}
    />
  ) : (
    <></>
  );
};
