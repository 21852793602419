import { FilterCompTypes } from 'app/ComponentLibrary/Filter/utils';

export enum UnitFilterName {
  Units = 'unit',
  Status = 'status',
  Discipline = 'discipline',
  Specialty = 'specialty',
  SubSpecialty = 'subSpecialty',
  LastUpdatedBy = 'lastUpdatedBy',
  FyreIntegration = 'fyreIntegration',
  CostCenter = 'costCenter',
  LastUpdatedDate = 'lastUpdatedDate',
}

export const statusOpts = [
  { id: 0, name: 'Active', value: 'Active' },
  { id: 1, name: 'Inactive', value: 'Inactive' },
];

export const defaultFilterValues = {
  [UnitFilterName.Units]: [],
  [UnitFilterName.Status]: statusOpts[0],
  [UnitFilterName.Discipline]: [],
  [UnitFilterName.Specialty]: [],
  [UnitFilterName.SubSpecialty]: [],
  [UnitFilterName.LastUpdatedBy]: [],
  [UnitFilterName.FyreIntegration]: [],
  [UnitFilterName.CostCenter]: [],
};

export const getFilterSpecs = (filterSpecs, t, styleClasses) => [
  {
    filterName: t('Unit'),
    name: UnitFilterName.Units,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.unitIdOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
  },
  {
    filterName: t('Status'),
    name: UnitFilterName.Status,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    options: statusOpts,
    nonNullable: true,
  },
  {
    filterName: t('Discipline'),
    name: UnitFilterName.Discipline,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.disciplineOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
  },
  {
    filterName: t('Specialty'),
    name: UnitFilterName.Specialty,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.specialtyOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
  },
  {
    filterName: t('Sub Specialty'),
    name: UnitFilterName.SubSpecialty,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.subSpecialtyOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.subSpecialty,
  },
  {
    filterName: t('Last Updated Date Range'),
    name: UnitFilterName.LastUpdatedDate,
    type: FilterCompTypes.DATERANGE,
    placeholder: t('Last Updated Date Range'),
    version3: true,
  },
  {
    filterName: t('Last Updated By'),
    name: UnitFilterName.LastUpdatedBy,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.lastUpdatedByOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.lastUpdatedBy,
  },
  {
    filterName: t('FYRE Integration'),
    name: UnitFilterName.FyreIntegration,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.fyreIntegrationOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.fyreIntegration,
  },
  {
    filterName: t('Cost Center'),
    name: UnitFilterName.CostCenter,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.costCenterOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.costCenter,
  },
];

export const fyreOpts = ['Yes', 'No'];
