import { TagValues } from './TagsWrapper';
export const getWindowTagCount = () => {
  let count = 1;
  if (window.innerWidth <= 1515) {
    count = 1;
  } else if (window.innerWidth > 1515 && window.innerWidth <= 1530) {
    count = 2;
  } else if (window.innerWidth > 1530 && window.innerWidth <= 1580) {
    count = 3;
  } else if (window.innerWidth > 1580 && window.innerWidth <= 1660) {
    count = 4;
  } else if (window.innerWidth > 1660 && window.innerWidth <= 1710) {
    count = 6;
  } else if (window.innerWidth > 1710 && window.innerWidth <= 1810) {
    count = 8;
  } else if (window.innerWidth > 1810) {
    count = 10;
  }

  return count;
};

export const shortenText = label => {
  if (!canDelete(label)) return label;
  const MAX_LENGTH = 4;
  if (label.length > MAX_LENGTH) {
    return `${label.slice(0, label.length - 5)}..`;
  } else {
    return label;
  }
};

const canDelete = (label: string) => {
  return label !== TagValues.SUPERVISOR && label !== TagValues.PRECHECK;
};
export const createLabelValue = item => {
  const MAX_LENGTH = 10;
  if (item?.name?.length > MAX_LENGTH) {
    return `${item.name.slice(0, 10)}...`;
  } else {
    return item?.name;
  }
};
