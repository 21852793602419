/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable i18next/no-literal-string */
import { GridCellParams } from '@mui/x-data-grid-pro';
import { materialUiXGrid } from 'amn-ui-core';
import { Box, Checkbox } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { CustomTooltip } from '../../Tooltips';

const useStyles = makeStyles()({
  hidden: {
    display: 'none',
  },
  inputInvalid: {
    color: 'red',
  },
  inputValid: {
    color: '#a3a2a2',
  },
  checkbox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

interface XGridCheckboxCellProps {
  onEndEdit: ({ value, params }: { value: any; params: GridCellParams }) => void;
  validator?: ({ value, params }: { value: any; params: GridCellParams }) => boolean;
  disableCellEdit?: ({ value, params }: { value: any; params: GridCellParams }) => boolean;
  placeholder?: string;
  mandatory?: boolean;
  editOnSingleClick?: boolean;
  Input?: any;
  dropdownOptions?: any;
}

export const XGridCheckboxCell =
  ({ ...props }: XGridCheckboxCellProps) =>
  (params: GridCellParams) => {
    return <XGridEditableCellContent params={params} props={props} />;
  };

const XGridEditableCellContent = ({ props, params }: { props: XGridCheckboxCellProps; params: GridCellParams }) => {
  const { classes, cx } = useStyles();
  const api = materialUiXGrid.useGridApiContext();
  const { onEndEdit, validator, disableCellEdit, placeholder, mandatory, editOnSingleClick = false, Input } = props;

  const [editing, setEditing] = React.useState<boolean>(false);
  const [editValue, setEditValue] = React.useState<any>(params.value);
  const [valid, setValid] = React.useState<boolean>(true);
  const [firstInteraction, setFirstInteraction] = React.useState<boolean>(true);

  React.useEffect(() => {
    setEditValue(params.value);
    if (validator && !firstInteraction) {
      setValid(validator({ value: editValue, params: params }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.value, validator]);

  React.useEffect(() => {
    if (validator && !firstInteraction) {
      setValid(validator({ value: editValue, params: params }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, params.value, validator]);

  const startEditing = React.useCallback(() => {
    setFirstInteraction(false);
    if (!editOnSingleClick) setEditing(true);
    setTimeout(() => {
      if (editOnSingleClick) setEditing(true);
      document.getElementById(`grid-cell-input-${params.row.id}-${params.colDef.field}`)?.focus();
    }, 100);
  }, [editOnSingleClick, params.colDef.field, params.row.id]);

  const _onEndEditing = () => {
    if (editing) setEditing(false);
  };

  const onKeyUp = React.useCallback(
    event => {
      if (
        event.keyCode === 13 &&
        document.activeElement?.getAttribute('data-field') === params.field &&
        document.activeElement?.getAttribute('data-rowindex') === `${api?.current?.getRowIndexRelativeToVisibleRows(params.id)}`
      ) {
        startEditing();
      }
    },
    [api, params.field, params.id, startEditing],
  );

  React.useEffect(() => {
    document.addEventListener('keyup', onKeyUp);
    return () => {
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [onKeyUp, params]);

  const onInputChanged = value => {
    setEditValue(value);
  };

  const handleSelect = () => {
    setEditValue(!editValue);
    if (editing) {
      setEditing(false);
      api?.current?.updateRows([{ ...params.row, [params.colDef.field]: !editValue }]);
      onEndEdit({ value: !editValue, params: params });
      if (validator) {
        setValid(validator({ value: !editValue, params: params }));
      }
    }
    return null;
  };

  return (
    <React.Fragment>
      <div style={{ width: '100%' }} className={cx({ [classes.hidden]: !editing })}>
        {Input ? (
          <Input params={params} onChange={onInputChanged} onEndEdit={_onEndEditing} />
        ) : (
          <div className={classes.checkbox}>
            <Checkbox
              disableFocusRipple
              disableRipple
              disableTouchRipple
              checked={editValue}
              disabled={disableCellEdit?.({ value: editValue, params: params }) ? true : false}
              onClick={() => handleSelect()}
              color="primary"
            />
          </div>
        )}
      </div>

      <Box
        onClick={editOnSingleClick ? startEditing : undefined}
        onDoubleClick={!editOnSingleClick ? startEditing : undefined}
        className={cx({
          [classes.hidden]: editing,
          [classes.inputInvalid]: !valid || params.row[`${params.field}_error`] === true,
        })}
        style={{ width: '100%', minHeight: '20px' }}
      >
        {true ? (
          <div className={classes.checkbox}>
            <Checkbox
              disableFocusRipple
              disableRipple
              disableTouchRipple
              checked={editValue}
              disabled={disableCellEdit?.({ value: editValue, params: params }) ? true : false}
              color="primary"
            />
          </div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {mandatory && (!validator?.({ value: params.value, params }) || false) && (
              <span style={{ padding: '0px 5px 2px 0px' }}>
                <CustomTooltip tooltipContent={'This field is required'}>
                  <WarningIcon style={{ color: 'red', height: '14px', width: '14px' }} />
                </CustomTooltip>
              </span>
            )}
            <span
              className={cx({
                [classes.inputInvalid]: !valid || params.row[`${params.field}_error`] === true,
                [classes.inputValid]: valid && !params.row[`${params.field}_error`],
              })}
            >{`${placeholder || params.colDef.headerName}`}</span>
          </div>
        )}
      </Box>
    </React.Fragment>
  );
};
