import { ICandidateInterview } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/InterviewsTab/InterviewsTab';

export interface IInterviewState {
  interviews: ICandidateInterview[];
  isLoading: boolean;
}

export const initialInterviewsState: IInterviewState = {
  interviews: [],
  isLoading: false,
};
