import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomFilter } from '../../../ComponentLibrary/Filter';
import { Grid } from 'amn-ui-core';
import { UserManagementFilters } from './FilterUtils';
import { useFormContext } from 'react-hook-form';
import { defaultChips, getUserManagementActions } from './store/userManagement.redux';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserManagementFilters } from './store/userManagement.selector';
import { getSearchFilterOptions } from 'app/services/SharedServices/SharedServices';


export const UserManagementFilter = props => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const dispatch = useDispatch();
  const userManagementFilters = useSelector(selectUserManagementFilters);

  const gridSpecs = useRef(
    new UserManagementFilters(
      {
        eidSelected: false,
        companyOpts: [],
        statusOpts: [],
        roleOpts: []
      },
      t,
    ),
  );
  const [fields, setFields] = useState(gridSpecs.current.getUserManagementFilterSpecs());

  const fetchFilterSpecs = async () => {
    const [
      companyLookups,
      statusLookUps,
      roleLookUps
    ] = await Promise.all([
      getSearchFilterOptions('companies', true),
      getSearchFilterOptions('employeestatus', true),
      getSearchFilterOptions('employeerole', true)
    ]);

    gridSpecs.current.filterSpecs = {
      companyOpts: companyLookups.map((company, id) => ({ id: id, ...company })),
      statusOpts: statusLookUps.map((status, id) => ({ id: id, ...status })),
      roleOpts: roleLookUps.map((role, id) => ({ id: id, ...role }))
    };
    const fieldsSchema = gridSpecs.current.getUserManagementFilterSpecs();
    setFields(fieldsSchema);
  };

  const resetFilter = () => {
    setValue('employeeNames', null);
    setValue('eid', null);
    setValue('employeeRole', null);
    setValue('usernames', null);
    setValue('companies', null);
    setValue('employeeStatus', { id: '1', name: 'Active', value: '1' });
    dispatch(getUserManagementActions.setemployeeAssociations([]));
    dispatch(getUserManagementActions.setUserDetailsFields([]));
    dispatch(getUserManagementActions.setEmpCardInfo(null));
    props?.onApplyFilter(defaultChips);
  };

  React.useEffect(() => {
    if (userManagementFilters?.eid) {
      gridSpecs.current.filterSpecs.eidSelected = true;
    }
    else {
      gridSpecs.current.filterSpecs.eidSelected = false;
    }
    const fieldsSchema = gridSpecs.current.getUserManagementFilterSpecs();
    setFields(fieldsSchema);
  }, [userManagementFilters])

  React.useEffect(() => {
    fetchFilterSpecs();
    resetFilter();

    return () => {
      resetFilter();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={11}>
        <CustomFilter
          fields={fields}
          justifyContent={'flex-start'}
          version2
          onResetForm={() => resetFilter()}
          analytics={{
            caller: 'usermanagement',
            onApply: true,
            properties: {},
          }}
        />
      </Grid>
    </Grid>
  );
};
