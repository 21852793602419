import React from 'react';
import { Button, Grid, IconButton, Snackbar, SnackbarContent } from 'amn-ui-core';
import { MessageSection, IconSection } from './snackbarHelpers';
import { getHideDuration } from './snackbarHelpers';
import { useDispatch } from 'react-redux';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { GenericSnackbarActions, ISnackBarSeverity } from 'app/models/Global/Global';
import CloseIcon from '@mui/icons-material/Close';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  actionBtn: {
    color: `${theme.palette.framework.system.white} !important`,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '14px',
    minWidth: theme.spacing(4),
  },
}));

interface GenericSnackbarProps {
  id?: string | number;
  snackOpen?: boolean;
  message: string;
  onSnackClose?: () => void;
  vertical?: 'top' | 'bottom';
  horizontal?: 'left' | 'center' | 'right';
  actions?: GenericSnackbarActions[];
  disableIcon?: boolean;
  className?: string;
  clickAwayProps?: any;
  variant?: 'light' | 'dark';
  severity?: ISnackBarSeverity;
  iconImage?: JSX.Element;
  iconColor?: string;
}

export const GenericSnackbar = (props: GenericSnackbarProps) => {
  const {
    id,
    message,
    actions,
    severity,
    variant,
    onSnackClose,
    clickAwayProps,
    className,
    disableIcon,
    vertical,
    horizontal,
    snackOpen,
    iconImage,
    iconColor,
  } = props;
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const onActionClicked = (action, closeSnackbar) => {
    action?.();
    if (closeSnackbar || isNullOrUndefined(closeSnackbar)) {
      dispatch(
        globalActions.closeSnackBar({
          id: id,
          message: '',
          severity: null,
        }),
      );
    }
  };

  return (
    <Snackbar
      key={`${id}-${message}`}
      data-testid="render-snackBar"
      open={snackOpen || false}
      autoHideDuration={actions?.length ? null : getHideDuration(severity)}
      ClickAwayListenerProps={clickAwayProps}
      style={{ maxWidth: actions?.length ? '100%' : '344px' }}
      className={className}
      anchorOrigin={{ vertical: vertical || 'bottom', horizontal: horizontal || 'center' }}
      color={variant || 'dark'}
      onClose={(event, reason) => {
        if (reason === 'clickaway') {
          return;
        } else {
          onSnackClose();
        }
      }}
    >
      <SnackbarContent
        message={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!disableIcon && <IconSection iconColor={iconColor} iconImage={iconImage} severity={severity} />}
            <MessageSection message={message} severity={severity} isAutoWidth={actions?.length > 1} />
          </div>
        }
        action={
          <Grid container wrap="nowrap" spacing={1}>
            {actions?.map((actionItem, key) => {
              if (actionItem.closeAction)
                return (
                  <Grid item key={key}>
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={() => onActionClicked(actionItem?.action, actionItem?.closeSnackbar)}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                );
              else
                return (
                  <Grid item key={key}>
                    <Button
                      key={actionItem?.text?.toString()}
                      onClick={() => {
                        onActionClicked(actionItem?.action, actionItem?.closeSnackbar);
                      }}
                      className={classes.actionBtn}
                    >
                      {actionItem?.text}
                    </Button>
                  </Grid>
                );
            })}
          </Grid>
        }
        color={variant || 'dark'}
      />
    </Snackbar>
  );
};
