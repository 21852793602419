import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomRangeDropdown from '../common/CustomRangeDropdown';
import { numberType } from '../common/CustomRangeDropdown';
import {
  ContractType,
  getShiftId,
  isNewRateElementRequired,
  isRangeValue,
  RateElemenetSource,
  RateElements,
  refactorInputValue,
} from '../helper';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Status } from 'app/enums/Facility';
import uuid from 'react-uuid';
import { selectUser } from 'oidc/user.selectors';
import moment from 'moment';
import {
  selectBillRateSelections,
  selectCreateOrderMiscellaneousData,
  selectOcbrDetails,
  selectOcbrSavedDetails,
  selectRateFeatureProps,
} from 'store/redux-store/create-order-ocbr/selector';
import { createOrderOcbrActions } from 'store/redux-store/create-order-ocbr/slice';
import { getDocNos, getDropdownMatchedRate, getRejectionReason, isPresent } from './helper';
import { missingField } from 'app/constants';
import { getDisciplineName, getSpecialtyName, getSubSpecialtyName } from 'store/redux-store/create-order-ocbr/utils';

export const LocalBillRateField = ({ label, name, shiftRates = [] }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { billRates: ocbrBillRates, effectiveDates, isFlatRate } = useSelector(selectOcbrDetails);
  const { billRates: ocbrSavedBillRates } = useSelector(selectOcbrSavedDetails);
  const user = useSelector(selectUser);
  const { uniqueShifts } = useSelector(selectRateFeatureProps);

  const rateSelections = useSelector(selectBillRateSelections);
  const { disciplineOpts, specialtyOpts, subSpecialtyOpts } = useSelector(selectCreateOrderMiscellaneousData);

  const { value, ocbr } = React.useMemo(() => {
    const selection = rateSelections[name];
    return !selection ? { value: null, ocbr: ContractType.OnContract } : selection;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateSelections]);

  const handleChange = newValue =>
    dispatch(
      createOrderOcbrActions.setBillRateSelection({
        [name]: { value: newValue, ocbr: determineOcbr(newValue) },
      }),
    );

  const checkIsBillRateOcbr = val => {
    if (val?.statusId) return true;
    else return true;
  };
  const updateReduxValue = newVal => {
    const isOcbr = checkIsBillRateOcbr(newVal);
    if (newVal !== undefined && ocbrBillRates) {
      const billRates = getBillRateData(newVal, ocbrBillRates, isOcbr);
      if (!_.isEqual(billRates, ocbrBillRates)) {
        dispatch(createOrderOcbrActions.business_updateBillRates({ billRates }));
        dispatch(createOrderOcbrActions.business_deriveRateSelections({ isFlatRate, billRates }));
      }
    } else if (newVal !== undefined && !ocbrBillRates) {
      const billRates = getBillRateData(newVal, [], isOcbr);
      if (!_.isEqual(ocbrBillRates, billRates)) {
        dispatch(createOrderOcbrActions.business_updateBillRates({ billRates }));
        dispatch(createOrderOcbrActions.business_deriveRateSelections({ isFlatRate, billRates }));
      }
    }
  };
  const updateSavedReduxValue = newVal => {
    const isOcbr = checkIsBillRateOcbr(newVal);
    if (newVal !== undefined && ocbrSavedBillRates) {
      const billRates = getSavedBillRateData(newVal, ocbrSavedBillRates, isOcbr);
      if (!_.isEqual(billRates, ocbrSavedBillRates)) {
        dispatch(createOrderOcbrActions.setOcbrSavedBillRates(billRates));
      }
    } else if (newVal !== undefined && !ocbrSavedBillRates) {
      const billRates = getSavedBillRateData(newVal, [], isOcbr);
      if (!_.isEqual(ocbrSavedBillRates, billRates)) {
        dispatch(createOrderOcbrActions.setOcbrSavedBillRates(billRates));
      }
    }
  };
  const getBillRateData = (newVal, billRatesArrOrg, isOcbr) => {
    //Remove existing values
    const billRatesArr = (billRatesArrOrg ?? []).reduce((resultArray, item) => {
      if (
        item.disciplineId === shiftRates[0]?.disciplineId &&
        item.specialtyId === shiftRates[0]?.specialtyId &&
        item.subSpecialtyId === shiftRates[0]?.subSpecialtyId &&
        item.shiftId === shiftRates[0]?.shiftID &&
        item.type === RateElements.LocalBillRate
      )
        return resultArray;
      return [...resultArray, item];
    }, []);
    //flate rate is on update shift fields also
    if (isFlatRate && uniqueShifts.length > 1) {
      uniqueShifts?.forEach(shiftItem => {
        const foundIndices = billRatesArr.map((item, index) =>
          item.disciplineId === shiftRates[0]?.disciplineId &&
            item.specialtyId === shiftRates[0]?.specialtyId &&
            item.subSpecialtyId === shiftRates[0]?.subSpecialtyId &&
            item.shiftId === getShiftId(shiftItem) &&
            item.type === RateElements.LocalBillRate
            ? index
            : -1,
        );
        const filteredIndices = foundIndices.filter(index => index !== -1).sort((a, b) => b - a);
        filteredIndices.forEach(index => {
          billRatesArr.splice(index, 1);
        });
      });
    }
    //remove if no value selected
    if (newVal === null) {
      return billRatesArr;
    }
    const orderEndDate = effectiveDates?.endDate ? moment(effectiveDates?.endDate).format() : null;
    const orderStartDate = moment(newVal.effectiveEndDate).add(1, 'days').format();
    const isExtraRateEleRequired = isNewRateElementRequired(effectiveDates?.endDate, newVal.effectiveEndDate);
    const temp = {
      offContractId: null,
      rateElementId: newVal.rateElementId ? newVal.rateElementId : uuid(),
      disciplineId: shiftRates[0]?.disciplineId,
      disciplineAbbreviation: getDisciplineName(shiftRates[0]?.disciplineId, disciplineOpts),
      specialtyId: shiftRates[0]?.specialtyId,
      specialtyName: getSpecialtyName(shiftRates[0]?.specialtyId, specialtyOpts),
      subSpecialtyId: shiftRates[0]?.subSpecialtyId,
      subSpecialtyName: getSubSpecialtyName(shiftRates[0]?.subSpecialtyId, subSpecialtyOpts),
      shiftId: shiftRates[0]?.shiftID,
      shift: shiftRates[0]?.shift,
      type: RateElements.LocalBillRate,
      billRate: refactorInputValue(newVal),
      isOcbr: isOcbr,
      statusId: isOcbr ? newVal?.statusId ?? Status.Unsaved : null,
      createdAt: newVal?.createdAt,
      createdBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
      reason: newVal.reason ? newVal.reason : missingField,
      effectiveStartDate:
        newVal?.source === RateElemenetSource.Facility ? newVal.effectiveStartDate : effectiveDates?.startDate,
      effectiveEndDate:
        newVal?.source === RateElemenetSource.Facility ? newVal.effectiveEndDate : effectiveDates?.endDate,
      lastUpdatedBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
      dateLastUpdated: moment().format(),
      dateCreated: moment().format(),
      source: isRangeValue(newVal) ? RateElemenetSource.Order : newVal?.source,
      documentIds: newVal?.documentIds,
    };

    billRatesArr.push(temp);
    if (isExtraRateEleRequired) {
      const additonalRateElement = {
        ...temp,
        rateElementId: uuid(),
        statusId: Status.Unsaved,
        effectiveStartDate: orderStartDate,
        effectiveEndDate: orderEndDate,
        source: RateElemenetSource.Order,
        createdAt: null,
        documentIds: [],
        reason: missingField,
        isExtra: true,
      };
      billRatesArr.push(additonalRateElement);
    }
    //flate rate is on update shift fields also
    if (isFlatRate && uniqueShifts.length > 1) {
      uniqueShifts?.forEach(shiftItem => {
        const matchedRateFromDropdown = getDropdownMatchedRate(newVal, shiftItem, shiftRates);

        const updatedTemp = {
          ...temp,
          offContractId:
            isPresent(newVal, shiftItem, shiftRates) === Status.Unsaved ? null : matchedRateFromDropdown?.offContractId,
          rateElementId:
            isPresent(newVal, shiftItem, shiftRates) === Status.Unsaved
              ? uuid()
              : matchedRateFromDropdown?.rateElementId,
          shiftId: getShiftId(shiftItem),
          shift: shiftItem,
          statusId: isPresent(newVal, shiftItem, shiftRates),
          createdAt:
            isPresent(newVal, shiftItem, shiftRates) != Status.Unsaved ? matchedRateFromDropdown?.createdAt : null,
          effectiveStartDate:
            newVal?.source === RateElemenetSource.Facility
              ? isPresent(newVal, shiftItem, shiftRates) === Status.Unsaved
                ? effectiveDates?.startDate
                : matchedRateFromDropdown?.effectiveStartDate
              : effectiveDates?.startDate,
          effectiveEndDate:
            newVal?.source === RateElemenetSource.Facility
              ? isPresent(newVal, shiftItem, shiftRates) === Status.Unsaved
                ? effectiveDates?.endDate
                : matchedRateFromDropdown?.effectiveEndDate
              : effectiveDates?.endDate,
          source:
            isPresent(newVal, shiftItem, shiftRates) === Status.Unsaved ? RateElemenetSource.Order : newVal.source,
          documentIds:
            isPresent(newVal, shiftItem, shiftRates) != Status.Unsaved ? getDocNos(newVal, shiftItem, shiftRates) : [],
          reason:
            isPresent(newVal, shiftItem, shiftRates) != Status.Unsaved
              ? getRejectionReason(newVal, shiftItem, shiftRates)
              : missingField,
        };
        billRatesArr.push(updatedTemp);

        const isExtraRateElementRequired = isNewRateElementRequired(
          effectiveDates?.endDate,
          updatedTemp?.effectiveEndDate,
        );
        const orderEffectiveEndDate = effectiveDates?.endDate ? moment(effectiveDates.endDate).format() : null;
        const orderEffectiveStartDate = moment(updatedTemp?.effectiveEndDate).add(1, 'days').format();

        if (isExtraRateElementRequired && updatedTemp.statusId != Status.Unsaved) {
          const additonalRateElement = {
            ...updatedTemp,
            offContractId: null,
            rateElementId: uuid(),
            statusId: Status.Unsaved,
            effectiveStartDate: orderEffectiveStartDate,
            effectiveEndDate: orderEffectiveEndDate,
            source: RateElemenetSource.Order,
            createdAt: null,
            documentIds: [],
            reason: missingField,
            isExtra: true,
          };
          billRatesArr.push(additonalRateElement);
        }
      });
    }

    return billRatesArr || [];
  };

  const getSavedBillRateData = (newVal, billRatesArrOrg, isOcbr) => {
    //Remove existing values
    const billRatesArr = (billRatesArrOrg ?? []).reduce((resultArray, item) => {
      if (
        item.disciplineId === shiftRates[0]?.disciplineId &&
        item.specialtyId === shiftRates[0]?.specialtyId &&
        item.subSpecialtyId === shiftRates[0]?.subSpecialtyId &&
        item.shiftId === shiftRates[0]?.shiftID &&
        item.type === RateElements.LocalBillRate
      )
        return resultArray;
      return [...resultArray, item];
    }, []);
    //flate rate is on update shift fields also
    if (isFlatRate && uniqueShifts.length > 1) {
      uniqueShifts?.forEach(shiftItem => {
        const foundIndices = billRatesArr.map((item, index) =>
          item.disciplineId === shiftRates[0]?.disciplineId &&
            item.specialtyId === shiftRates[0]?.specialtyId &&
            item.subSpecialtyId === shiftRates[0]?.subSpecialtyId &&
            item.shiftId === getShiftId(shiftItem) &&
            item.type === RateElements.LocalBillRate
            ? index
            : -1,
        );
        const filteredIndices = foundIndices.filter(index => index !== -1).sort((a, b) => b - a);
        filteredIndices.forEach(index => {
          billRatesArr.splice(index, 1);
        });
      });
    }
    //remove if no value selected
    if (newVal === null) {
      return billRatesArr;
    }
    const temp = {
      offContractId: null,
      rateElementId: newVal.rateElementId ? newVal.rateElementId : uuid(),
      disciplineId: shiftRates[0]?.disciplineId,
      disciplineAbbreviation: getDisciplineName(shiftRates[0]?.disciplineId, disciplineOpts),
      specialtyId: shiftRates[0]?.specialtyId,
      specialtyName: getSpecialtyName(shiftRates[0]?.specialtyId, specialtyOpts),
      subSpecialtyId: shiftRates[0]?.subSpecialtyId,
      subSpecialtyName: getSubSpecialtyName(shiftRates[0]?.subSpecialtyId, subSpecialtyOpts),
      shiftId: shiftRates[0]?.shiftID,
      shift: shiftRates[0]?.shift,
      type: RateElements.LocalBillRate,
      billRate: refactorInputValue(newVal),
      isOcbr: isOcbr,
      statusId: isOcbr ? newVal?.statusId ?? Status.Unsaved : null,
      createdAt: newVal?.createdAt,
      createdBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
      reason: newVal.reason ? newVal.reason : missingField,
      effectiveStartDate:
        newVal?.source === RateElemenetSource.Facility ? newVal.effectiveStartDate : effectiveDates?.startDate,
      effectiveEndDate:
        newVal?.source === RateElemenetSource.Facility ? newVal.effectiveEndDate : effectiveDates?.endDate,
      lastUpdatedBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
      dateLastUpdated: moment().format(),
      dateCreated: moment().format(),
      source: isRangeValue(newVal) ? RateElemenetSource.Order : newVal?.source,
      documentIds: newVal?.documentIds,
    };

    billRatesArr.push(temp);
    //flate rate is on update shift fields also
    if (isFlatRate && uniqueShifts.length > 1) {
      uniqueShifts?.forEach(shiftItem => {
        const matchedRateFromDropdown = getDropdownMatchedRate(newVal, shiftItem, shiftRates);
        const updatedTemp = {
          ...temp,
          offContractId:
            isPresent(newVal, shiftItem, shiftRates) === Status.Unsaved ? null : matchedRateFromDropdown?.offContractId,
          rateElementId:
            isPresent(newVal, shiftItem, shiftRates) === Status.Unsaved
              ? uuid()
              : matchedRateFromDropdown?.rateElementId,
          shiftId: getShiftId(shiftItem),
          shift: shiftItem,
          statusId: isPresent(newVal, shiftItem, shiftRates),
          createdAt:
            isPresent(newVal, shiftItem, shiftRates) != Status.Unsaved ? matchedRateFromDropdown?.createdAt : null,
          effectiveStartDate:
            newVal?.source === RateElemenetSource.Facility
              ? isPresent(newVal, shiftItem, shiftRates) === Status.Unsaved
                ? effectiveDates?.startDate
                : matchedRateFromDropdown?.effectiveStartDate
              : effectiveDates?.startDate,
          effectiveEndDate:
            newVal?.source === RateElemenetSource.Facility
              ? isPresent(newVal, shiftItem, shiftRates) === Status.Unsaved
                ? effectiveDates?.endDate
                : matchedRateFromDropdown?.effectiveEndDate
              : effectiveDates?.endDate,
          source:
            isPresent(newVal, shiftItem, shiftRates) === Status.Unsaved ? RateElemenetSource.Order : newVal.source,
          documentIds:
            isPresent(newVal, shiftItem, shiftRates) != Status.Unsaved ? getDocNos(newVal, shiftItem, shiftRates) : [],
          reason:
            isPresent(newVal, shiftItem, shiftRates) != Status.Unsaved
              ? getRejectionReason(newVal, shiftItem, shiftRates)
              : missingField,
        };
        billRatesArr.push(updatedTemp);
      });
    }

    return billRatesArr || [];
  };

  return (
    <>
      <CustomRangeDropdown
        defaultValue=""
        value={value}
        options={shiftRates}
        onChange={newVal => {
          handleChange(newVal);
        }}
        onBlur={newVal => {
          updateReduxValue(newVal);
          updateSavedReduxValue(newVal);
        }}
        label={label}
        name={name}
        labelPrefix="$"
        labelPostfix={undefined}
        type={numberType.currency}
        errorMessage={t('order.newOrder.rateCard.minMaxRangeError')}
        isChanged={ocbr}
      />
    </>
  );
};

const determineOcbr = value =>
  value?.statusId ? value?.statusId : value === null ? ContractType.OnContract : ContractType.OffContract;
