import { Button } from 'amn-ui-core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles/styleVariables';
import { makeStyles } from 'tss-react/mui';

interface IColumnTruncator {
  forceTruncation?: boolean;
  setForceTruncation?: Function;
  maxHeight?: string | number;
}

const useStyles = makeStyles<{ maxHeight?: string | number }>()((theme, { maxHeight }) => ({
  content: { overflow: 'hidden', textOverflow: 'ellipsis', maxHeight },
  contentTransition: { transition: 'max-height 4s' },
  button: {
    fontSize: 12,
    color: colors.amnBlue,
    textTransform: 'none',
    '&:hover, &:focus': { background: 'none' },
    padding: '6px 0',
  },
  paper: { padding: 12, fontSize: 12 },
}));

export const ColumnTruncator: React.FC<IColumnTruncator> = ({
  forceTruncation,
  setForceTruncation,
  maxHeight,
  ...props
}) => {
  const { t } = useTranslation();
  const { cx, classes } = useStyles({ maxHeight });
  const anchorElRef = React.useRef<any>(null);
  //@Team: @Rijash - remove these exceedsHeight usestate if its not used
  const [exceedsHeight, setExceedsHeight] = useState(false);
  const [truncateState, setTruncateState] = React.useState<boolean | null>(true);

  const compareSize = React.useCallback(() => {
    if (anchorElRef) setExceedsHeight(anchorElRef.current.scrollHeight > anchorElRef.current.offsetHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorElRef?.current?.scrollHeight, anchorElRef?.current?.offsetHeight]);

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
    return () => {
      window.removeEventListener('resize', compareSize);
    };
  }, [compareSize]);

  React.useEffect(() => {
    if (truncateState !== null) setTruncateState(forceTruncation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceTruncation]);

  useEffect(() => {
    if (
      anchorElRef?.current &&
      anchorElRef?.current.scrollHeight === anchorElRef?.current.offsetHeight &&
      truncateState
    )
      setTruncateState(null);
    else compareSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [truncateState]);

  const handleTruncation = () =>
    setTruncateState(prev => {
      setForceTruncation(!prev);
      return !prev;
    });

  return (
    <>
      <div ref={anchorElRef} className={cx(classes.contentTransition, { [classes.content]: truncateState })}>
        {props.children}
      </div>
      {truncateState !== null ? (
        <Button
          variant="text"
          disableRipple
          disableFocusRipple
          disableTouchRipple
          classes={{ text: classes.button }}
          onClick={handleTruncation}
        >
          {truncateState ? t('common.viewMore') : t('common.viewLess')}
        </Button>
      ) : null}
    </>
  );
};
