import React from "react";
import { InlineDropdown } from "@AMIEWEB/Common/EditPage/InlineDropdown";
import { usePromiseTracker } from "react-promise-tracker";

export const ContactEditor = ({
    value,
    isDirty,
    helperText,
    designation,
    employeeRole,
    applyNoneOptionStyle,
    onChange,
    onCancel,
    onApply,
    classes,
    ...props
  }) => {
    const { promiseInProgress: isSaving } = usePromiseTracker({ area: `save-contact-${props?.keyAttribute}` });
  
    const _onApply = () => onApply({ ...value, key: props?.keyAttribute, employeeRole: employeeRole });
  
    return (
      <InlineDropdown
        {...props}
        placeholder="Select"
        name={designation}
        value={value}
        onChange={onChange}
        onCancel={onCancel}
        onApply={_onApply}
        removeGutters
        isDirty={isDirty}
        isSaving={isSaving}
        helperText={helperText}
        noneOption={applyNoneOptionStyle ?? classes.option}
        inputClass={{ disabled: classes.disabled, sizeSmall: classes.smallTextfield }}
        isContactSidePanel
      />
    );
  };

  export enum KeyContacts {
    AccountManager = 'accountManager',
    RegionalDirector = 'regionalDirector',
    HousingManager = 'housingAccountExecutive',
    BillingSpecialist = 'billingSpecialist',
    ClientAccountRepresentative = 'clientAcctgRep',
    TimeProcessingSpecialist = 'timeProcessingSpecialist',
    PayrollSpecialist = 'payrollSpecialist',
    PayrollRegion = 'payrollRegion',
    ClientContractAdmin = 'clientContractAdmin',
    ClientContractSpecialist = 'clientContractSpecialist',
    QSAnalyst = 'credentialingAnalyst',
    ClinicalLiaison = 'clinicalDirector',
    HousingAccountingSpecialist = 'credentialingSpecialist',
    AccountCoordinator = 'accountCoordinator',
    CustomerSupportSpecialist = 'customerSupportSpecialist',
  }