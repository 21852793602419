import { Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';

export const useStylesExperience = makeStyles()((theme: Theme) => ({
  boxBorder: {
    flexGrow: 1,
    borderColor: theme.palette.framework.system.whisper,
    border: `1px solid ${theme.palette.system.paleGray}`,
    borderRadius: '5px',
    margin: '0.2rem 0.75rem 0.75rem 0.75rem',
  },
  titleBox: {
    backgroundColor: theme.palette.system.lightGreyTileBackground,
    borderBottom: `1px solid ${theme.palette.system.paleGray}`,
  },
  operationText: {
    fontSize: 16,
    fontWeight: 500,
    marginLeft: '0.9rem',
  },
  tileComponent: {
    display: 'flex',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.system.paleGray}`,
  },
  leftSection: {
    height: '32px',
    width: '32px',
    backgroundColor: `${theme.palette.system.gray}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  RightSection: {
    display: 'flex',
    height: '32px',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0px 5px 0px 5px',
  },
  firstColumn: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
  },
}));

export const useStylesWorkHistory = makeStyles()(() => ({
  paragraph: {
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-line',
  },
}));

export const useStylesOrderPriority = makeStyles()(theme => ({
  list: {
    listStyleType: 'disc',
    padding: 0,
  },
  listItemText: {
    display: 'list-item',
    paddingTop: 0,
    margin: 0,
  },
  listItem: {
    padding: '0px 0px 0px 16px',
  },
}));

export const useStylesBestBet = makeStyles()(() => ({
  dialogContainer: {
    minWidth: '600px',
    overflow: 'hidden',
  },
  dialogContent: {
    paddingTop: '24px !important',
    minHeight: '300px',
  },
}));

export const useExternalJobQualificationStyles = makeStyles()(theme => ({
  subSectionHeading: {
    fontSize: '16px !important',
    color: theme.palette.system.black,
    fontWeight: 500,
  },
  facilityGeneralLaunchIcon: {
    cursor: 'pointer !important',
    color: theme.palette.system.neutralGray,
    width: '25px',
    padding: '2px',
    '&:hover': {
      color: theme.palette.system.steelBlue,
      borderRadius: '50%',
      background: theme.palette.system.lightSteelBlue,
    },
  },
}));
