/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CustomFilter } from 'app/ComponentLibrary/Filter';
import { getMultiSelectOptions } from 'app/components/GlobalSearch/helper';
import {
  fetchDisciplineAndSpecialty,
  getCitiesAndZipsByStates,
  getSearchFilterOptions,
} from 'app/services/SharedServices/SharedServices';
import { MFSOpts, OrderMatchFlterName, OrderMatchSearchFilters, PositionOpts, convertToTreeStructure } from './OrderMatchFilterUtils';
import { SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { getCandidateOrderMatchActions, initialFilterChips, IOrderMatchCips } from '../store/candidateOrderMatch.redux';
import { getSortedData, getUnique } from 'app/helpers/arrayHelpers';
import { FilterType } from 'app/enums/Common';
import {
  selectFilters,
  selectGridFiltersExpanded,
} from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { IStateCityZip } from 'app/models/Candidate/IStateCityZip';
import { getAllCitiesAndZips } from 'app/components/GlobalSearch/Candidate/FilterUtils';
import { Collapse, Grid } from 'amn-ui-core';
import { gridStateActions } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { SearchPageIcons } from 'app/ComponentLibrary/Filter/SearchPageIcons';
import { makeStyles } from 'tss-react/mui';
import { candidateDetailsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { selectCandidateLookUpPrefilter, selectCandidateOrderMatchId } from '../store/candidateOrderMatch.selector';
import { selectJobPreferencesData, selectPreferenceId } from '../../JobPreferencesTab/store/JobPreferences.selectors';
import { getMatchingShiftOptions, getMatchingStateOptions, processJobPreferencesData } from './OrderDrawerHelper';
import { updateTreeNodes } from '@AMIEWEB/Common/TreeView/TreeViewHelpers';
import { RootState } from 'types';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles()({
  fillterCollapse: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '3px',
  },
  collapse: {
    paddingTop: '4px',
  },
});
const OrderMatchesFilters = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const dispatch = useDispatch();
  const gridStateFilters = useSelector(selectFilters);
  const allCitiesandZipsData = useRef<IStateCityZip[]>();
  const candidateDetails = useSelector(candidateDetailsSelection);
  let prefId = useSelector(selectCandidateOrderMatchId);
  const orderPreferenceId = useSelector(selectPreferenceId);
  const { jobPreferencesData, jobPreferencesGridData } = useSelector(selectJobPreferencesData);
  const orderPreferenceLookups = useSelector(selectCandidateLookUpPrefilter);
  let orderPrefId = prefId ?? orderPreferenceId;
  const [orderPreferenceResult, setOrderPreferenceResult] = useState<any>(
    processJobPreferencesData(jobPreferencesData, orderPrefId),
  );
  const matchingShiftOpts = getMatchingShiftOptions(
    orderPreferenceResult?.shiftOpts,
    orderPreferenceLookups?.shiftOpts,
  );
  const matchingStateOpts = getMatchingStateOptions(
    orderPreferenceResult?.state || [],
    orderPreferenceLookups?.statesOpts,
  );
  const [skillset, setSkillset] = useState(null);
  const [prefSkillset, setPrefSkillset] = useState(null);
  const { travelerId: candidateId } = useParams<{ travelerId: string; brandId: string }>();
  const [filterKey] = React.useState<any>(`candidate-${candidateId}`);
  const filterPreference = useSelector((state: RootState) => {
    return state.userPreferenceData.userGridPreferences.find(item => item.id === filterKey);
  });
  const gridSpecs = useRef(
    new OrderMatchSearchFilters(
      {
        divisionOpts: [],
        businessRelationshipsOpts: [],
        facilityStatusesOpts: [],
        orderTypesOpts: [],
        disciplineOpts: [],
        specialityOpts: [],
        subSpecialtiesOpts: [],
        statesOpts: [],
        compactStatesOpts: [],
        cityptions: [],
        zipOptions: [],
        facilityOpts: [],
        mspClientOpts: [],
        orderReasonOpts: [],
        accountManagerOpts: [],
        orderPrioritiesOpts: [],
        shiftOpts: [],
        orderIds: [],
        regionOpts: [],
      },
      gridStateFilters,
    ),
  );
  const [allSpecialities, setAllSpecialities] = React.useState<any[]>([]);
  const [allSubSpecialities, setAllSubSpecialities] = React.useState<any[]>([]);
  const [fields, setFields] = React.useState(gridSpecs.current.getOrderMatchFilters());

  const filtersExpanded = useSelector(selectGridFiltersExpanded);
  const handleChange = () => {
    dispatch(gridStateActions.setFiltersExpanded(!filtersExpanded));
  };
  const getDisciplinesMultiSelectOptions = options => {
    const array = options?.reduce(
      (resultArray, item) => [
        ...resultArray,
        {
          value: item?.value?.slice(item?.value?.indexOf('_') + 1),
          name: item.name,
          corelation: item.value,
        },
      ],
      [],
    );
    const sortedData = getSortedData(array || [], FilterType.string);
    return sortedData;
  };

  const findspecialities = disciplines => {
    const specialtiesArray: any[] = [];
    if (disciplines?.length > 0) {
      disciplines?.forEach(item => {
        const specialties = allSpecialities.filter(
          curr => curr?.corelation?.slice(0, curr?.corelation?.indexOf('_')) === item?.value,
        );
        if (specialties) {
          specialtiesArray.push(...specialties);
        }
      });
      const sortedData = getSortedData(specialtiesArray, FilterType.string);
      const uniqueSpecialities = getUnique(
        sortedData?.filter(x => x.name),
        'name',
      );
      if (uniqueSpecialities?.length >= 1) {
        gridSpecs.current.filterSpecs.specialityOpts = getMultiSelectOptions(uniqueSpecialities);
        const fieldsSchema = gridSpecs.current.getOrderMatchFilters();
        setFields(fieldsSchema);
        findSubSpecialities(specialtiesArray);
      } else {
        gridSpecs.current.filterSpecs.subSpecialtiesOpts = [];
        gridSpecs.current.filterSpecs.specialityOpts = getMultiSelectOptions(uniqueSpecialities);
        const fieldsSchema = gridSpecs.current.getOrderMatchFilters();
        setFields(fieldsSchema);
      }
    } else {
      gridSpecs.current.filterSpecs.specialityOpts = getUnique(
        allSpecialities?.filter(x => x.name),
        'name',
      );
      const fieldsSchema = gridSpecs.current.getOrderMatchFilters();
      setFields(fieldsSchema);
      findSubSpecialities([]);
    }
  };

  const findSubSpecialities = specialities => {
    const subSpecialtiesArray: any[] = [];
    if (specialities?.length > 0) {
      specialities?.forEach(item => {
        allSubSpecialities?.forEach(curr => {
          if (
            curr?.corelation?.substr(0, curr?.corelation?.lastIndexOf('_')) ===
            item?.corelation?.slice(item?.corelation?.indexOf('_') + 1)
          ) {
            if (curr?.name !== 'All') {
              subSpecialtiesArray.push(curr);
            }
          }
        });
      });
      const sortedData = getSortedData(subSpecialtiesArray, FilterType.string);
      const uniqueSubSpecialities = getUnique(
        sortedData?.filter(x => x.name),
        'name',
      );
      gridSpecs.current.filterSpecs.subSpecialtiesOpts = getMultiSelectOptions(uniqueSubSpecialities);
      const fieldsSchema = gridSpecs.current.getOrderMatchFilters();
      setFields(fieldsSchema);
    } else {
      gridSpecs.current.filterSpecs.subSpecialtiesOpts = getUnique(
        allSubSpecialities?.filter(x => x.name),
        'name',
      );
    }
  };
  const updateGridState = useCallback(
    async (selectedStates: string[], selectedCities: string[], gridStateFilters: any) => {
      if (!gridStateFilters || !allCitiesandZipsData.current) return;
      let filteredZips: IStateCityZip[];
      let filteredCities: IStateCityZip[];
      if (!selectedStates.length) filteredCities = allCitiesandZipsData.current;
      else {
        filteredCities = (await getCitiesAndZipsByStates({
          states: selectedStates,
          cities: [],
        })) as IStateCityZip[];
      }

      if (!selectedCities.length) filteredZips = filteredCities;
      else {
        filteredZips = (await getCitiesAndZipsByStates({
          states: selectedStates,
          cities: selectedCities,
        })) as IStateCityZip[];
      }
      gridSpecs.current.filterSpecs.cityOptions = getAllCitiesAndZips(filteredCities).cities;
      gridSpecs.current.filterSpecs.zipOptions = getAllCitiesAndZips(filteredZips).zips;
      gridSpecs.current.gridStateFilters = gridStateFilters;
      const fieldsSchema = gridSpecs.current.getOrderMatchFilters();
      setFields(fieldsSchema);
    },
    [],
  );

  const fetchFilterSpecs = async key => {
    const divisionsLookups = await getSearchFilterOptions('divisions');
    const businessRelationshipsLookups = await getSearchFilterOptions('businessrelationships');
    const facilitysStatusesLookups = await getSearchFilterOptions('facilitystatuses');
    const orderTypesLookups = await getSearchFilterOptions('ordertypes');
    const disciplines = await getSearchFilterOptions('disciplines');
    const speciality = await getSearchFilterOptions('discipline_specialties');
    const subSpecialtiesLookups = await getSearchFilterOptions('specialty_subspecialties');
    const statesLookups = await getSearchFilterOptions('states');
    const disciplineOpts = getMultiSelectOptions(disciplines || []);
    const compactStatesOpts = await getSearchFilterOptions('compact_states');

    const states = getMultiSelectOptions(statesLookups || []);
    const compactStates = compactStatesOpts?.map(item => {
      const stateAbbrs = item.name?.split(',');
      return {
        name: item?.value?.replace('?', "'")?.replace('Only ', '')?.replace('LIC', 'Licence'),
        value: states.filter(s => stateAbbrs.includes(s.value)),
        isCompactOpt: true,
      };
    });

    const facilityLookups = await getSearchFilterOptions('facility');
    const mspClientLookups = await getSearchFilterOptions('mspclients');
    const orderReasonLookups = await getSearchFilterOptions('orderreason');
    const accountManagerLookups = await getSearchFilterOptions('accountmanagers');
    const orderPrioritiesLookups = await getSearchFilterOptions('orderpriorities');
    const shiftLookups = await getSearchFilterOptions('shiftlist');
    const regionLookups = await getSearchFilterOptions('orderplacementregions');
    const allSpecialtiesOpts = getMultiSelectOptions(getDisciplinesMultiSelectOptions(speciality || []));
    const specialityOpts = getUnique(
      allSpecialtiesOpts?.filter(x => x.name),
      'name',
    );
    setAllSpecialities(allSpecialtiesOpts);
    const allSubSpecialtiesOpts = getMultiSelectOptions(getDisciplinesMultiSelectOptions(subSpecialtiesLookups || []));
    setAllSubSpecialities(allSubSpecialtiesOpts);
    const subSpecialtiesOpts = getUnique(
      allSubSpecialtiesOpts?.filter(x => x.name),
      'name',
    );
    allCitiesandZipsData.current = (await getCitiesAndZipsByStates({
      states: [statesLookups?.[0]?.name],
      cities: undefined,
    })) as IStateCityZip[];
    const AllCitiesAndZips = getAllCitiesAndZips(allCitiesandZipsData.current);
    const statesOpts = getMultiSelectOptions([...compactStates, ...statesLookups] || []);
    const cityOpts = AllCitiesAndZips?.cities;
    const zipOpts = AllCitiesAndZips?.zips;
    const divisionOpts = getMultiSelectOptions(divisionsLookups || []);
    const businessRelationshipsOpts = getMultiSelectOptions(businessRelationshipsLookups || []);
    const facilityStatusesOpts = getMultiSelectOptions(facilitysStatusesLookups || []);
    const orderTypesOpts = getMultiSelectOptions(orderTypesLookups || []);
    const facilityOpts = getMultiSelectOptions(facilityLookups || []);
    const mspClientOpts = getMultiSelectOptions(mspClientLookups || []);
    const orderReasonOpts = getMultiSelectOptions(orderReasonLookups || []);
    const accountManagerOpts = getMultiSelectOptions(accountManagerLookups || []);
    const orderPrioritiesOpts = getMultiSelectOptions(orderPrioritiesLookups || []);
    const shiftOpts = getMultiSelectOptions(shiftLookups || []);
    const regionOpts = getMultiSelectOptions(regionLookups || [], { withoutAllOption: true });

    gridSpecs.current.filterSpecs = {
      divisionOpts,
      businessRelationshipsOpts,
      facilityStatusesOpts,
      orderTypesOpts,
      disciplineOpts,
      specialityOpts,
      facilityOpts,
      mspClientOpts,
      statesOpts,
      compactStates,
      orderReasonOpts,
      accountManagerOpts,
      orderPrioritiesOpts,
      shiftOpts,
      subSpecialtiesOpts,
      regionOpts,
      cityOptions: cityOpts,
      zipOptions: zipOpts,
    };
    const fieldsSchema = gridSpecs.current.getOrderMatchFilters();
    setFields(fieldsSchema);
  };

  const matchingDivisions = (props.divisions ?? []).filter(
    divisionType => divisionType.name === candidateDetails?.divisionType,
  );

  const getSkillSets = defaultSkillsets => {
    let newSkillSetFilter = convertToTreeStructure(defaultSkillsets);
    if (newSkillSetFilter?.length > 0) {
      newSkillSetFilter = updateTreeNodes(newSkillSetFilter);
      newSkillSetFilter[0].filterName = 'skillsetFilter';
      return {
        allowableMismatch: false,
        isVerifiedOnly: false,
        operation: 'Or',
        skillsets: newSkillSetFilter,
      };
    }
  };

  useEffect(() => {
    const defaultSkillsets = [
      ...(candidateDetails?.verifiedSkills || []),
      ...(candidateDetails?.unVerifiedSkills || []),
    ]
      .filter(item => !!item)
      .reduce((resultArray, item) => {
        if (!item?.disciplineId || !item?.disciplineAbbr) {
          return resultArray;
        }
        const newSkill = {
          disciplineId: item?.disciplineId,
          disciplineAbbr: item?.disciplineAbbr,
          specialtyId: item?.specialtyId,
          specialtyAbbr: item?.specialtyAbbr,
          subSpecialtyId: item?.subSpecialtyId,
          subSpecialtyAbbr: item?.subSpecialtyAbbr,
        };
        resultArray?.push(newSkill);
        return resultArray;
      }, []);

    setSkillset(getSkillSets(defaultSkillsets));
  }, [candidateDetails]);

  const getDisciplineAndSpecialty = async (disciplineIds, specialtyIds) => {
    let newSkillSetFilter = [];

    const abortController = new AbortController();
    const disciplineAndSpecialty = (await fetchDisciplineAndSpecialty('', abortController.signal, true)) as any;

    if (disciplineAndSpecialty?.treeStructure?.length > 0) {
      const skillSetTree = disciplineAndSpecialty?.treeStructure;

      //If Specialty array is not empty, then pick only the valid and active values of Discipline and Specialty combination.
      if (specialtyIds?.length > 0) {
        for (const disciplineId of disciplineIds) {
          let specialtyChildren = [];
          let node = skillSetTree?.find(t => t.value == disciplineId?.value);
          if (node) {
            for (const specialtyId of specialtyIds) {
              const specialtyNode = node?.children?.find(
                child => child?.value == `${node?.value}-${specialtyId?.value}`,
              );

              //If Specialty is not present under Discipline, then don't pick anything at all.
              if (specialtyNode) {
                specialtyNode.userChecked = true;
                specialtyChildren = [...specialtyChildren, { ...specialtyNode, children: [] }];
              }
            }
          }

          if (specialtyChildren?.length > 0) {
            let disciplineNode = {
              name: node?.name,
              value: node?.value,
              userChecked: true,
              children: specialtyChildren,
            };
            newSkillSetFilter = [...newSkillSetFilter, disciplineNode];
          }
        }
      }
      //If Specialty array is empty, then pick only the valid and active values of Discipline.
      else {
        for (const disciplineId of disciplineIds) {
          let node = skillSetTree?.find(t => t.value == disciplineId?.value);
          if (node) {
            node.userChecked = true;
            newSkillSetFilter = [...newSkillSetFilter, { ...node, children: [] }];
          }
        }
      }
    }

    let skillSets = null;
    if (newSkillSetFilter?.length > 0) {
      newSkillSetFilter[0].filterName = 'skillsetFilter';
      skillSets = {
        allowableMismatch: false,
        isVerifiedOnly: false,
        operation: 'Or',
        skillsets: updateTreeNodes(newSkillSetFilter),
      };
    }
    return skillSets;
  };

  useEffect(() => {
    const fetchData = async () => {
      let preferenceValues = null;
      const disciplines = orderPreferenceResult?.newDisciplineSkill;
      const specialties = orderPreferenceResult?.newSpecialtySkill;

      if (disciplines?.length > 0) {
        preferenceValues = await getDisciplineAndSpecialty(disciplines, specialties);
        setPrefSkillset(preferenceValues);
        setValue(OrderMatchFlterName.skillsetFilter, preferenceValues);
      }
    };

    fetchData();
  }, [orderPreferenceResult]);

  useEffect(() => {
    if (orderPreferenceId && jobPreferencesData) {
      setOrderPreferenceResult(processJobPreferencesData(jobPreferencesData, orderPreferenceId));
      Object.entries(orderPreferencePreFilters).map(item => setValue(item[0], item[1]));
      props.onApplyFilter(orderPreferencePreFilters);
    }
  }, [orderPreferenceId, jobPreferencesData]);

  const defaultAppliedFilters: IOrderMatchCips = {
    ...initialFilterChips,
    divisionIds: matchingDivisions,
    specialtyIds: [],
    disciplineIds: [],
    skillsetFilter: skillset,
    maxFilesSentId: MFSOpts[1],
    positions: PositionOpts[1],
  };

  const orderPreferencePreFilters: IOrderMatchCips = {
    ...initialFilterChips,
    divisionIds: matchingDivisions,
    specialtyIds: [],
    disciplineIds: [],
    maxFilesSentId: MFSOpts[1],
    positions: PositionOpts[1],
    states: matchingStateOpts,
    city: orderPreferenceResult?.city,
    zipCode: orderPreferenceResult?.zip,
    orderStartDateRange: orderPreferenceResult?.orderStartDateRange,
    billrate: orderPreferenceResult?.billRate
      ? { min: orderPreferenceResult?.billRate[0], max: orderPreferenceResult?.billRate[1] }
      : null,
    education: orderPreferenceResult?.education,
    shiftIds: matchingShiftOpts,
    radius: orderPreferenceResult?.radius?.toString(),
    skillsetFilter: prefSkillset,
  };

  const resetFilter = () => {
    if (!orderPrefId) {
      Object.entries(defaultAppliedFilters).map(item => setValue(item[0], item[1]));
      props.onApplyFilter(defaultAppliedFilters);
    } else {
      Object.entries(orderPreferencePreFilters).map(item => setValue(item[0], item[1]));
      props.onApplyFilter(orderPreferencePreFilters);
    }
  };

  const resetToDefaultFilter = () => {
    dispatch(getCandidateOrderMatchActions.setId(null));
    Object.entries(defaultAppliedFilters).map(item => setValue(item[0], item[1]));
    props.onApplyFilter(defaultAppliedFilters);
  };

  React.useEffect(() => {
    fetchFilterSpecs('');
    if (skillset && !orderPrefId) {
      if (!filterPreference) {
        resetFilter();
      }
      else{
        setValue(OrderMatchFlterName.skillsetFilter, filterPreference?.value?.filtersApplied?.skillsetFilter)
      }
    } else if (prefSkillset && orderPrefId) resetFilter();
  }, [orderPrefId, skillset, prefSkillset]);

  useEffect(() => {
    if (!gridStateFilters || !allCitiesandZipsData.current) return;
    const selectedStates = !(gridStateFilters?.filters?.states as any[])?.length
      ? []
      : (gridStateFilters?.filters?.states as any[]).reduce(
          (result, item) => (!item?.isCompactOpt ? [...result, item] : result),
          [],
        );

    const selectedCities = (
      !(gridStateFilters?.filters?.city as any[])?.length ? [] : gridStateFilters?.filters?.city
    ) as any[];
    updateGridState(
      selectedStates.map(x => x.name),
      selectedCities.map(x => x.name),
      gridStateFilters,
    );
  }, [gridStateFilters, updateGridState]);

  useEffect(() => {
    findspecialities(gridStateFilters?.filters?.disciplineIds);
  }, [gridStateFilters?.filters?.disciplineIds]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (gridStateFilters?.filters?.specialtyIds?.length > 0) {
      findSubSpecialities(gridStateFilters?.filters?.specialtyIds);
    } else {
      findspecialities(gridStateFilters?.filters?.disciplineIds);
    }
  }, [gridStateFilters?.filters?.specialtyIds]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={11}>
        <Collapse timeout={200} in={filtersExpanded} collapsedSize={45} className={classes.collapse}>
          <div>
            <CustomFilter
              fields={fields}
              justifyContent={'flex-start'}
              version2
              resetBtn
              onResetForm={() => resetFilter()}
              analytics={{
                caller: t('Candidate Order Match'),
                onApply: true,
                properties: {
                  category: SearchType.order,
                },
              }}
            />
          </div>
        </Collapse>
      </Grid>
      <Grid item xs={1} className={classes.fillterCollapse}>
        <SearchPageIcons
          resetlbutton
          funnelButton
          checked={filtersExpanded}
          onResetForm={() => resetToDefaultFilter()}
          onAdvancedFilterClick={() => handleChange()}
        />
      </Grid>
    </Grid>
  );
};

export default OrderMatchesFilters;
