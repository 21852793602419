import React, { useEffect, useState } from 'react';
import { CustomGrid } from '../../../Common/Grid/Grid';
import 'locales/i18n';
import { GetMomentOfDate } from 'utils/dates/moment';
import { useTranslation } from 'react-i18next';
import { Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { panelActions, PanelChoice } from '../PanelOptions/PanelOption.redux';
import ReasonForLeavingIcon from '../../../../assets/images/Notes.png';
import { candidateDetailsSelection } from '../Profile/CandidateDetails.selector';
import { DetailsPageGrid } from 'app/layout/pages/DetailsPageGrid';
import { getEmploymentHistoryHeaders } from './utils';
import { missingDate, missingField } from 'app/constants';

const useStyles = makeStyles()((theme: Theme) => ({
  Link: {
    textDecoration: 'underline',
    color: '#006FB9',
  },
}));

function getExperienceData(years: number | undefined, months: number | undefined) {
  if (years == null && months == null) return missingField;
  else if (years == null || years === 0) return `${months}M`;
  else if (months == null || months === 0) return `${years}Y`;
  else return `${years}Y ${months}M`;
}

function getTotalMonthsOfExperience(years: number | undefined, months: number | undefined) {
  if (years == null && months == null) return 0;
  else if (years == null) return months;
  else if (months == null) return years * 12;
  else return years * 12 + months;
}

export const EmploymentHistoryGrid = () => {
  const { t } = useTranslation();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const [employmentData, setEmploymentHistory] = useState<any[]>([]);
  const { classes } = useStyles();

  const dispatch = useDispatch();

  const handleClick = () => {
    window.history.pushState(null, '', `${window.location.pathname}?tab=1`);
    dispatch(panelActions.setPanelOptions({ panelOption: PanelChoice.Tabs }));
  };

  const pageBreadCrumbs = [
    { title: t('candidate.empHistoryGrid.breadCrumb1'), clickable: true, onClick: () => handleClick() },
    { title: t('candidate.empHistoryGrid.breadCrumb2'), clickable: true, onClick: () => handleClick() },
    { title: t('candidate.empHistoryGrid.title') },
  ];

  const formatData = employmentHistory =>
    employmentHistory.map(details => {
      const facilityName = details.facilityName || missingField;

      const startDate = GetMomentOfDate(details.startedOn);
      const endDate = GetMomentOfDate(details.endedOn);

      return {
        facilityName: <span className={classes.Link}>{facilityName}</span>,
        facilityNameSort: details.facilityName,
        city: details.location?.city || missingField,
        state: details.location?.state || missingField,
        zip: details.location?.zip ? parseInt(details.location.zip) : missingField,
        country: details.location?.country || missingField,
        unit: details.unit || missingField,
        discipline: details.disciplineAbbr || missingField,
        unitSpecialty: details.specialtyAbbr || missingField,
        experience: getExperienceData(details.yearsOfExperience, details.monthsOfExperience),
        totalMonthsOfExperience: getTotalMonthsOfExperience(details.yearsOfExperience, details.monthsOfExperience),
        startDate: startDate.isValid() ? startDate.format('MM/DD/YYYY') : missingField,
        startDateNoFormat: startDate.isValid() ? details.startedOn : missingDate,
        endDate: endDate.isValid() ? endDate.format('MM/DD/YYYY') : missingField,
        endDateNoFormat: endDate.isValid() ? details.endedOn : missingDate,
        references: details.approvedReferenceCount !== null ? details.approvedReferenceCount : missingField,
        travelCompany: details.travelCompany || missingField,
        localStaffingAgency: details.staffingAgency || missingField,
        teachingHospital: details.isTeachingHospital || missingField,
        numberOfBedsInFacility: details.numberOfBedsInFacility !== null ? details.numberOfBedsInFacility : missingField,
        currentlyEmployed: details.currentlyEmployed === null ? missingField : details.currentlyEmployed ? 'Yes' : 'No',
        placementID: details.placementID !== null ? details.placementID : missingField,
        positionHeld: details.position !== null ? details.position : missingField,
        reasonForLeaving: <img src={ReasonForLeavingIcon} alt="" />,
        reasonForLeavingHeader: 'Reason For Leaving',
        reasonForLeavingHoverViewData: [details?.reasonForLeaving || missingField],
      };
    });

  useEffect(() => {
    if (candidateDetails?.experience?.employment) {
      const formattedData = formatData(candidateDetails.experience.employment);
      setEmploymentHistory(formattedData);
    }
    return () => {
      dispatch(panelActions.setPanelOptions({ panelOption: PanelChoice.Tabs }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateDetails?.experience?.employment]);

  return (
    <DetailsPageGrid
      breadcrumbs={pageBreadCrumbs}
      title={t('candidate.empHistoryGrid.title')}
      handleNavigateBack={handleClick}
    >
      <div>
        <CustomGrid
          columns={getEmploymentHistoryHeaders(t)}
          rows={employmentData}
          gridName={'EmploymentHistoryGrid'}
          defaultPageView={'25'}
          fixedItemsPerPage={100}
          pageOptions={[25, 50, 100, 200]}
          disableGutters
          noPadding
          centerHoverView={false}
          showCount={false}
          minCellWidth={'100px'}
        />
      </div>
    </DetailsPageGrid>
  );
};
