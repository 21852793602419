import { IChip } from 'app/ComponentLibrary/Filter/ChipsContainer';
import { ISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import { PlacementsFilterName } from '../Filters/PlacementFilterConfig';

export interface IPlacementStatusCount {
  placementsCount: number;
  expiringCount: number;
  offer: number;
  offerByFacility: number;
  offerBookedByTraveler: number;
  facilityConfirms: number;
  travelerAccepts: number;
  bookedCategory: number;
  bookedSubCategory: number;
  bookingApproved: number;
  waitingGoodToGo: number;
  postponementWarning: number;
  goodToGo: number;
  onAssignment: number;
}

export interface IFilterChipSelected {
  [PlacementsFilterName.placementId]: any[] | null;
  [PlacementsFilterName.candidateName]: any[] | null;
  [PlacementsFilterName.startDate]: any[] | null;
  [PlacementsFilterName.placementStatus]: any[] | null;
  [PlacementsFilterName.clientName]: any[] | null;
  [PlacementsFilterName.disciplineSpecialty]: any[] | null;
  [PlacementsFilterName.candidateId]: any[] | null;
}

export interface IFilterSpecs {
  placementIDOpts: ISelection[];
  candidateNameOpts: ISelection[];
  placementStatusOpts: ISelection[];
  clientNameOpts: ISelection[];
  disciplineSpecilityOpts: ISelection[];
  candidateIdOpts: ISelection[];
}

export const defaultFilterChipSelected = {
  [PlacementsFilterName.placementId]: null,
  [PlacementsFilterName.candidateName]: null,
  [PlacementsFilterName.placementStatus]: null,
  [PlacementsFilterName.clientName]: null,
  [PlacementsFilterName.disciplineSpecialty]: null,
  [PlacementsFilterName.candidateId]: null,
  [PlacementsFilterName.startDate]: null,
} as IFilterChipSelected;

export interface IPlacementFilterChips {
  pipelineSelected: IChip[];
  [PlacementsFilterName.placementId]: IChip[];
  [PlacementsFilterName.candidateName]: IChip[];
  [PlacementsFilterName.placementStatus]: IChip[];
  [PlacementsFilterName.clientName]: IChip[];
  [PlacementsFilterName.disciplineSpecialty]: IChip[];
  [PlacementsFilterName.candidateId]: IChip[];
  [PlacementsFilterName.startDate]: string | null;
}

export const defaultChips: IPlacementFilterChips = {
  pipelineSelected: [],
  [PlacementsFilterName.placementId]: [],
  [PlacementsFilterName.candidateName]: [],
  [PlacementsFilterName.placementStatus]: [],
  [PlacementsFilterName.clientName]: [],
  [PlacementsFilterName.disciplineSpecialty]: [],
  [PlacementsFilterName.candidateId]: [],
  [PlacementsFilterName.startDate]: null,
};

export interface IPlacementsStatusPage {
  counts: any | null;
  expiringTotalCount: number | 0;
  flattenedData: any[];
  dataLoaded?: boolean;
  pipelineData: any[];
  filteredData?: any[];
  filterSpecs?: IFilterSpecs;
  filterChipSelected: IFilterChipSelected;
  chips: IPlacementFilterChips;
  loading: boolean;
}

export interface IPlacementFilter {
  placementId: number | 0;
  candidateName: string[];
  discipline: string[];
  specialty: string[];
  startDate: string | null;
  facilityName: string[];
  expiringCredentialsCount: number | 0;
}
