import { Grid, Paper, Divider, IconButton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { TaskTooltip } from 'app/components/Common/Tooltips/TaskTooltip';
import { ITaskStickChosen, TaskEntity } from 'app/models/Tasks/Tasks';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { taskDetailsActions } from '../../store/Tasks.redux';
import { SelectedContainer } from '../../store/Tasks.selectors';
import { getStickIcons, TaskPanelStick } from './TaskPanelStick';
const useStyles = makeStyles()({
  root: {
    position: 'fixed',
    minWidth: 40,
    width: 'inherit',
    background: '#fff',
  },
  rootIcons: {
    position: 'inherit',
    minWidth: 36,
    width: 'inherit',
    background: '#fff',
  },
  container: {
    height: '100%',
  },
  panelStick: {
    height: '10%',
  },
  choiceContainerX: {
    width: '100%',
  },
  choiceTitle: {
    width: '100%',
    fontSize: 16,
    fontWeight: 500,
    color: '#333333',
    padding: '12px 12px 2% 12px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeButtonRoot: {
    padding: '0px 3px',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  closeIconButton: {
    background: '#D1D1D1',
    borderRadius: '4px',
    cursor: 'pointer',
    padding: '0px 3px',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
});
export const TaskSidePanel = ({
  stickChosen,
  items,
  iconArray,
  handleChoice,
  scrollable = true,
  changePanel = true,
}: {
  stickChosen: ITaskStickChosen;
  items: any;
  iconArray: any[];
  handleChoice: (e) => void;
  scrollable: boolean;
  changePanel?: boolean
}) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const container = useSelector(SelectedContainer);
  const [iconOptions, setIconOptions] = useState<any[]>([]);

  useEffect(() => {
    const panelIconArr = [];
    iconArray.map(icon => {
      panelIconArr.push(icon);
    });
    if (container === 1) {
      panelIconArr.push(6);
    }
    if (stickChosen.stick !== null && stickChosen.entity === TaskEntity.PLACEMENTS) {
      setIconOptions(panelIconArr);
    } else {
      setIconOptions(iconArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconArray]);

  const Panel = React.useMemo(() => {
    return (
      <Paper
        classes={{
          root: stickChosen.stick === null && stickChosen.entity === container ? classes.rootIcons : classes.root,
        }}
        elevation={stickChosen.stick === null && stickChosen.entity === container ? 0 : undefined}
      >
        <Grid container direction="row-reverse" classes={{ container: classes.container }}>
          {stickChosen.stick === null && stickChosen.entity === container && (
            <Grid item classes={{ root: classes.panelStick }}>
              <TaskPanelStick
                iconArray={iconOptions}
                stickChosen={stickChosen.stick}
                handleChoice={handleChoice}
                items={items}
              />
            </Grid>
          )}
          {stickChosen.stick !== null && stickChosen.entity === container && (
            <Grid item container direction="column" className={classes.choiceContainerX}>
              <Grid item className={classes.choiceTitle}>
                {items[stickChosen.stick]?.title}
                <div className={classes.flexContainer}>
                  {iconOptions.map((icon, index) => (
                    <TaskTooltip tooltipContent={items[index]?.tooltip} key={index}>
                      {stickChosen.stick === index ? (
                        <IconButton
                          color="primary"
                          aria-label="close"
                          onClick={() => {
                            handleChoice(index);
                            dispatch(taskDetailsActions.setSidePanelChosen(false));
                          }}
                          classes={{ root: classes.closeIconButton }}
                          size="small">
                          {getStickIcons(icon)}
                        </IconButton>
                      ) : (
                        <IconButton
                          color="primary"
                          aria-label="close"
                          onClick={() => {
                            handleChoice(index);
                            dispatch(taskDetailsActions.setSidePanelChosen(false));
                          }}
                          classes={{ root: classes.closeButtonRoot }}
                          size="small">
                          {getStickIcons(icon)}
                        </IconButton>
                      )}
                    </TaskTooltip>
                  ))}
                </div>
              </Grid>
              <Divider />
              <Grid item style={{ width: 'inherit' }}>
                {items[stickChosen.stick].component}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconArray, iconOptions,changePanel]);

  return <React.Fragment>{Panel}</React.Fragment>;
};
