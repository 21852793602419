import { Grid } from 'amn-ui-core';
import React from 'react';

const Divider = (isLastRead: Boolean) => (
  <div style={{ borderTop: isLastRead ? '1px solid #0F6FB9' : '1px solid #AFAFAF' }} />
);

const DividerWithText = ({ children, ...props }) => (
  <Grid container alignItems="center" spacing={3} {...props}>
    <Grid item xs>
      {Divider(children.toLowerCase() === 'last read')}
    </Grid>
    <Grid
      item
      style={{
        color: children.toLowerCase() === 'last read' ? '#0F6FB9' : '#AFAFAF',
        fontSize: '12px',
        lineHeight: '20px',
      }}
    >
      {children}
    </Grid>
    <Grid item xs>
      {Divider(children.toLowerCase() === 'last read')}
    </Grid>
  </Grid>
);

export default DividerWithText;
