import React, { useEffect, useState } from 'react';
import { AttachmentAction } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/CredentialsTab/Attachments/AttachmentHoverView';
import { DocumentDropzoneWrapper } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/CredentialsTab/Attachments/DocumentDropzone/DocumentDropzoneWrapper';
import { useHistory, useParams } from 'react-router-dom';
import { breadcrumbActions } from 'app/components/Common/BreadcrumbNavigation/BreadcrumbNavigation.redux';
import {
  newAttachmentsSelection,
  previewAttachmentsSelection,
} from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { candidateDetailActions } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { onAddAttachment } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/CredentialsTab/CredentialsHelpers';
import { selectUser } from 'oidc/user.selectors';
import { getAttachments } from 'app/services/CredentialingServices';
import { IAttachments } from 'app/models/Credentialing/Credentials';
import { CustomBackdrop } from 'app/components/Common/Backdrop/CustomBackdrop';
import { useTranslation } from 'react-i18next';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { isPositiveNumber } from 'app/helpers/componentHelpers';

export enum AttachmentItemState {
  default,
  loading,
  success,
  error,
}

export const AttachmentsForm = props => {
  const { attachments, openAddAttachment, closeForm } = props;
  const { reqId, travelerId, brandId } = useParams<any>();
  const [failedAttachments, setFailedAttachments] = useState<any[]>([]);
  const [disableAction, setDisableAction] = useState<boolean>(false);
  const dispatch = useDispatch();
  const newAttachments = useSelector(newAttachmentsSelection);
  const previewAttachmentsSel = useSelector(previewAttachmentsSelection);
  const user = useSelector(selectUser);
  const [isShowLoader, setIsShowLoader] = useState<boolean>(false);
  const { t } = useTranslation();
  const history = useHistory();

  const submitFailedAttachment = async attachment => {
    let newAttachmentItems = _.cloneDeep(newAttachments) || [];
    const files: any[] = [];
    newAttachmentItems.map(item => {
      if (item.data === attachment.data) {
        const file = {
          ...item,
          status: AttachmentItemState.loading,
        };
        files.push(file);
      }
    });
    dispatch(candidateDetailActions.setNewAttachments(files));

    await onAddAttachment(travelerId, reqId, attachments, attachment, user, dispatch)
      // eslint-disable-next-line no-loop-func
      .then(() => {
        const files: any[] = [];
        newAttachmentItems.map(item => {
          let file = item;
          if (_.isEqual(item, attachment)) {
            file = {
              ...item,
              status: AttachmentItemState.success,
            };
          }
          files.push(file);
        });
        newAttachmentItems = files;
        dispatch(candidateDetailActions.setNewAttachments(files));
        closeForm();
      })
      // eslint-disable-next-line no-loop-func
      .catch(e => {
        const failedFiles = failedAttachments;
        const files: any[] = [];
        newAttachmentItems.map(item => {
          let file = item;
          if (item === attachment) {
            file = {
              ...item,
              status: AttachmentItemState.error,
            };
            failedFiles.push(file);
          }
          files.push(file);
        });
        newAttachmentItems = files;
        setFailedAttachments(failedFiles);
        dispatch(candidateDetailActions.setNewAttachments(files));
      });
  };

  const submitNewAttachments = async () => {
    setDisableAction(true);
    let processSuccess = true;

    let newAttachmentItems = _.cloneDeep(newAttachments) || [];
    const files: any[] = [];
    newAttachmentItems.map(item => {
      const file = {
        ...item,
        status: AttachmentItemState.loading,
      };
      files.push(file);
    });
    newAttachmentItems = files;
    dispatch(candidateDetailActions.setNewAttachments(files));

    if (newAttachments) {
      for (const attachment of newAttachmentItems) {
        await onAddAttachment(travelerId, reqId, attachments, attachment, user, dispatch)
          // eslint-disable-next-line no-loop-func
          .then(() => {
            const files: any[] = [];
            newAttachmentItems.map(item => {
              let file = item;
              if (_.isEqual(item, attachment)) {
                file = {
                  ...item,
                  status: AttachmentItemState.success,
                };
              }
              files.push(file);
            });
            newAttachmentItems = files;
            dispatch(candidateDetailActions.setNewAttachments(files));
            dispatch(
              globalActions.setSnackBar({
                message: t('search.grid.add.successUploadMessage'),
                severity: 'success',
              }),
            );
            setIsShowLoader(true);
          })
          // eslint-disable-next-line no-loop-func
          .catch(e => {
            processSuccess = false;
            const failedFiles = failedAttachments;
            const files: any[] = [];
            newAttachmentItems.map(item => {
              let file = item;
              if (item === attachment) {
                file = {
                  ...item,
                  status: AttachmentItemState.error,
                };
                failedFiles.push(file);
              }
              files.push(file);
            });
            newAttachmentItems = files;
            setFailedAttachments(failedFiles);
            dispatch(candidateDetailActions.setNewAttachments(files));
          });
      }
    }
    if (processSuccess) {
      if (isPositiveNumber(travelerId) && isPositiveNumber(reqId)) {
        getAttachments(travelerId, reqId).then(response => {
          const filteredData = response?.filter(
            item => !attachments?.some(it => it.travelerRequirementImageID === item.travelerRequirementImageID),
          );
          const _previewAttachments = _.cloneDeep(previewAttachmentsSel);
          filteredData?.map(item => {
            _previewAttachments?.push(item);
          });

          dispatch(candidateDetailActions.setSelPreviewAttachments(_previewAttachments as unknown as IAttachments[]));
          const sortedAttachments = _.orderBy(
            _previewAttachments?.map((item, idx) => {
              return { ...item, sortOrder: idx };
            }),
            (o: any) => {
              return o != null ? o.lastUpdated : o;
            },
            ['desc'],
          );
          const attachmentsIds = sortedAttachments?.map(a => a.sortOrder);
          navigate(attachmentsIds);
          setDisableAction(false);
        });
        closeForm();
      } else {
        dispatch(
          globalActions.setSnackBar({
            message: t('common.failedLoad'),
            severity: 'error',
          }),
        );
      }
      setIsShowLoader(false);
    }
  };

  useEffect(() => {
    newAttachments && newAttachments.length > 0 ? setDisableAction(false) : setDisableAction(true);
  }, [newAttachments]);
  /**
   * Navigate to full attachment viewer
   * @param attachmentIds Selected attachment IDs
   */
  const navigate = (attachmentIds?) => {
    dispatch(breadcrumbActions.setNavigationData({ backButtonURL: null, breadcrumbHierarchy: null }));
    history.push({
      pathname: `${history.location.pathname.split('?')[0]}${`?at=${attachmentIds}`}`,
      state: { navigateBack: true },
    });
  };

  return (
    <>
      <CustomBackdrop open={isShowLoader} />
      {openAddAttachment ? (
        <GenericDialog
          open={openAddAttachment}
          disableEscapeKeyDown
          variant="blue"
          fullWidth
          maxWidth="md"
          onClose={closeForm}
          dialogTitleProps={{
            text: 'Add Attachments',
            closeButton: true,
            size: 'small',
          }}
          dialogActions={[
            {
              text: 'Cancel',
              variant: 'contained',
              color: 'tertiary',
              onClick: closeForm,
            },
            {
              text: 'Add',
              variant: 'contained',
              color: 'primary',
              onClick: submitNewAttachments,
              disabled: disableAction,
            },
          ]}
        >
          <DocumentDropzoneWrapper
            compressed={false}
            existingAttachments={attachments}
            data={{
              open: true,
              action: AttachmentAction.add,
              requirementID: reqId,
              requirementTypeID: 0,
              travelerId: travelerId,
              brandId: brandId,
            }}
            submitFailedAttachment={submitFailedAttachment}
            dissableUpload={false}
          />
        </GenericDialog>
      ) : null}
    </>
  );
};
