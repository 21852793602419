import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExpectedHoursRateField } from './ExpectedHoursRowItem';
import {  defaultValues, deriveFieldName } from '../helper';

const useStyles = makeStyles()(theme => ({
  rateElementsContainer: {
    padding: '6px',
  },
}));

export const ExpectedHourShift = ({ shifDetails }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Grid container direction="column">
        <Grid item classes={{ root: classes.rateElementsContainer }}>
          {' '}
          <ExpectedHoursRateField
            label={t('Expected Hours')}
            name={deriveFieldName(['expected-hours', shifDetails?.shiftName])}
            shiftHours={defaultValues}
            shiftDetails ={shifDetails}
          />
        </Grid>
      </Grid>
    </>
  );
};
