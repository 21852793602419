export const objectWithoutProperties = (obj, keys) => {
  var target = {};
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
};

export const isNullOrUndefined = (obj: any): boolean => obj === null || obj === undefined;

export const isNotInteger = (obj: any): boolean => !Number.isInteger(obj);

/* The following function is used to add missing properties from object 2 into object 1 */
export const extendObjectProperties = (object1, object2) => {
  for (var key in object2) {
    if (object1[key]) {
      // Check if object1 key matches object2 keys, if it matches do nothing
    } else {
      object1[key] = object2[key]; // If no match, we add object2 key to object1 key
    }
  }
  return object1;
};

export const compareObject = (obj1, obj2) => {
  // const isObject = obj => {
  //   return Object.prototype.toString.call(obj) === '[object Object]';
  // };
  // if (!isObject(obj1) || !isObject(obj2)) {
  //   return false;
  // }
  // let len: number = 0;
  // //check if they're of thesame length
  // if (Object.keys(obj1).length != Object.keys(obj2).length) {
  //   return false;
  // } else {
  //   len = JSON.stringify(obj1).length; //use any length
  // }
  // let match = 0; //store number of matched properties
  // const stringified = JSON.stringify(obj1); //use JSON.stringify to convert to JSON string
  // let iteration = 0;
  // //loop through the variable
  // while (iteration < stringified.length) {
  //   //check if character exists in the other object
  //   if (JSON.stringify(obj2).includes(stringified[iteration])) {
  //     match++; //increment the variable
  //   }
  //   iteration++;
  // }
  // //check if object length equals the number of matched properties
  // if (match === len) {
  //   return true;
  // }
  // return false;
};
export const separateCountryCodeAndNumber = (phoneNumber: string) => {
  // Regular expression to match the country code and phone number
  const regex = /^\+(\d+)\s(.+)$/;
  const matches = phoneNumber.match(regex);

  if (matches && matches.length === 3) {
    // Extract the country code and phone number
    const countryCode = matches[1];
    const number = matches[2];

    return { countryCode, number };
  } else {
    return null;
  }
};

export const formatUSPhoneNumber = (phoneNumber: string | null) => {
  if (phoneNumber) {
    if (phoneNumber.startsWith('(')) {
      const formattedNumber = `+1 ${phoneNumber}`;
      return formattedNumber;
    } else {
      return phoneNumber;
    }
  }
  else
    return "";
};
