import { AccordionDetails, AccordionSummary, Grid, useTheme } from 'amn-ui-core';
import styledComponent from 'styled-components';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { AccordionCard, AccordContent, Row, RowContent, RowHead, Title } from './styles';
import { RowContainer } from '@AMIEWEB/Candidate/Common/RowContainer';

export const VirtualInterviewCard = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const availableStatusText = theme.palette.components.typography.status.available.color;
  return (
    <AccordionCard elevation={0} data-testid="virtualInterview-container">
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Title>{t('Virtual Interview')}</Title>
      </AccordionSummary>
      <AccordionDetails>
        <AccordContent>
          <Grid container direction="column" justifyContent="space-between" spacing={1}>
            <RowSection header={t('List')} data={t('A')} />
            <StatusSection header={t('Status')} data={t('Available')} color={availableStatusText} />
            <RowSection header={t('Date Mapped')} data={t('10/07/2020')} />
            <RowSection header={t('Date Completed')} data={t('10/07/2028')} />
            <RowSection header={t('Discipline')} data={t('PT')} />
            <RowSection header={t('Specialty')} data={t('Physical Therapist')} />
          </Grid>
        </AccordContent>
      </AccordionDetails>
    </AccordionCard>
  );
};

const RowSection: React.FC<{ header: string; data: string }> = props => {
  return (
    <Grid item>
      <RowContainer>
        <Row>
          <RowHead>{props.header}</RowHead>
          <RowContent>{props.data}</RowContent>
        </Row>
      </RowContainer>
    </Grid>
  );
};

const StatusSection: React.FC<{ header: string; data: string; color: string }> = props => {
  const theme = useTheme();
  return (
    <Grid item>
      <RowContainer>
        <Row>
          <RowHead>{props.header}</RowHead>
          <RowStatusContent style={{ color: theme.palette.components.typography.status.available.color }}>
            {props.data}
          </RowStatusContent>
        </Row>
      </RowContainer>
    </Grid>
  );
};

const RowStatusContent = styledComponent.td`
  font-size: 12px;
  text-align: end;
  font-weight: 600;
`;
