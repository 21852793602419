import React from 'react';
import { Button } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  button: {
    backgroundColor: '#006FB9',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#006FB9',
    },
  },
});

export const ActionButton = props => {
  const { classes } = useStyles();
  const { onClick, children, ...rest } = props;
  return (
    <Button {...rest} className={classes.button} onClick={onClick}>
      {children}
    </Button>
  );
};
