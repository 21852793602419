import React, { useState } from 'react';
import { IconButton, Grid, Box } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { ApproveButton } from 'app/ComponentLibrary/Icons/ApproveButtonIcon';
import { RejectButton } from 'app/ComponentLibrary/Icons/RejectButtonIcon';
import Approved from 'app/assets/images/IconImages/Approved.svg';
import Rejected from 'app/assets/images/IconImages/Rejected.svg';
import { cloneDeep } from 'lodash';
import { selectedApproveRejectRows } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { useDispatch, useSelector } from 'react-redux';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
const useStyles = makeStyles()({
  grid: {
    width: '31rem',
    border: 'none',
    '& .MuiDataGrid-columnHeaderRow': {
      backgroundColor: '#F5F5F5',
    },
    '& .MuiDataGrid-row': {
      borderBottom: '1px solid #D5D5D5',
    },
  },
  approveBtn: {
   
    marginLeft:'12px',
    padding: 0,
  },
  rejectBtn: {
    marginRight: '12px',
  
    padding: 0,
  },
  btn: {
    height: 30,
    width: 35,
  },
});

export const ApproveOrRejectCell = props => {
  const { classes } = useStyles();
  const [hoverApprove, setHoverApprove] = useState<boolean>(false);
  const [hoverReject, setHoverReject] = useState<boolean>(false);
  const dispatch = useDispatch();
  const selectedRows = useSelector(selectedApproveRejectRows);
  const {setStatus, row} = props;

  const setSelectedStatus = (statusId: number, index: number) => {
    setStatus(statusId, index);
    let record = cloneDeep(row);
    let records = selectedRows? cloneDeep(selectedRows): [];
    record.statusId = statusId;
    let existing = records?.find(x=>x.rateElementId === record.rateElementId);
    if(!existing && records){
      records.push(record);
    }
    else{
      existing.statusId = statusId;
    }
    dispatch(facilityActions.setSelectedRejectedRows(records));
  };

  return (
    <Grid sx={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
      <IconButton
        color="primary"
        type="button"
        disableTouchRipple
        disableFocusRipple
        onMouseEnter={() => {
          setHoverReject(true);
        }}
        onMouseLeave={() => setHoverReject(false)}
        onClick={() => setSelectedStatus(2, props.row.id)}
        classes={{ root: classes.rejectBtn }}
        size="medium"
      >
        {props.row.statusId === 2 ? (
          <Box>
            <img src={Rejected} alt="Rejected" className={classes.btn} />
          </Box>
        ) : (
          <RejectButton
            toggle={hoverReject}
            iconStyle={{ height: 30, width: 35 }}
            showGreyOutline={props.row.statusId === 4}
          />
        )}
      </IconButton>
      <IconButton
        color="primary"
        type="button"
        disableTouchRipple
        disableFocusRipple
        onMouseEnter={() => {
          setHoverApprove(true);
        }}
        onMouseLeave={() => {
          setHoverApprove(false);
        }}
        onClick={() => setSelectedStatus(4, props.row.id)}
        classes={{ root: classes.approveBtn }}
        size="medium"
      >
        {props.row.statusId === 4 ? (
          <Box>
            <img src={Approved} alt="Approved" className={classes.btn} />
          </Box>
        ) : (
          <ApproveButton
            toggle={hoverApprove}
            iconStyle={{ height: 30, width: 35 }}
            showGreyOutline={props.row.statusId === 2}
          />
        )}
      </IconButton>
    </Grid>
  );
};
