import { RatingValue } from './enums';

const CertificationType = 'CERTIFICATIONS';
const EMRType = 'EMR Conversion';
const Specify = 'specify';
const EmrConversionTypeId = 18;
const HeadingType = 1;
const IgnoreHeadingType = 2;
const ConversionHeading = 2;
const excludeTypeIds = [IgnoreHeadingType, HeadingType, 13, 17, 4];
const excludeHTMLFile = 'text/html';
const RatingConfiguration = [
  {
    id: RatingValue.No_Experience,
    color: ['#4ABAEB', '#E3E3E3', '#E3E3E3', '#E3E3E3'],
  },
  {
    id: RatingValue.Intermittent_Experience,
    color: ['#4ABAEB', '#3F9AD9', '#E3E3E3', '#E3E3E3'],
  },
  {
    id: RatingValue.Proficient,
    color: ['#4ABAEB', '#3F9AD9', '#306FB9', '#E3E3E3'],
  },
  {
    id: RatingValue.Expert_Level,
    color: ['#4ABAEB', '#3F9AD9', '#306FB9', '#1B3F69'],
  },
];

export {
  CertificationType,
  EMRType,
  Specify,
  EmrConversionTypeId,
  HeadingType,
  IgnoreHeadingType,
  excludeTypeIds,
  ConversionHeading,
  RatingConfiguration,
  excludeHTMLFile,
};
