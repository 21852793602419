import countriesData from 'app/assets/jsons/Country.json';
import { ITypeAheadOption } from '../../../PreferencesTab/CustomComponents/ControlledTypeAhead';

export const ExcludedCountries = {
  VIRGIN_ISLANDS: 213,
  PUERTO_RICO: 17,
};

export const reasonForLeavingDropDownOptions: ITypeAheadOption[] = [
  { object: { id: 1 }, label: 'Career Advancement' },
  { object: { id: 2 }, label: 'Career Change' },
  { object: { id: 3 }, label: 'Change in Specialty' },
  { object: { id: 4 }, label: 'Currently Working' },
  { object: { id: 5 }, label: 'End of Assignment' },
  { object: { id: 6 }, label: 'Facility Closure / Merger' },
  { object: { id: 7 }, label: 'Per Diem' },
  { object: { id: 8 }, label: 'Personal Reasons' },
  { object: { id: 9 }, label: 'Relocation' },
  { object: { id: 10 }, label: 'To Travel' },
];

export const getCountryOpts = () =>
  countriesData.countries
    .filter(x => x.ID !== ExcludedCountries.PUERTO_RICO && x.ID !== ExcludedCountries.VIRGIN_ISLANDS)
    .map(e => ({
      label: e.Description,
      value: e.CountryDialingCode,
      id: e.ID,
    }));
