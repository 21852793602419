import { ISelectedSubCert } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Grid/FacilityOrderUnitGrid';
import { ICetificate } from '@AMIEWEB/NewOrder/types';

export interface SubCertificateUnitTypeAheadDropdownProps {
  name: string;
  isMultiSelect: boolean;
  value: any[];
  selectAllValue?: boolean;
  options: any[];
  filterName;
  isDisabled: boolean;
  anchorRef?: any;
  customClass: string;
  hasControlError: boolean;
  fallbackPlacements?: any;
  minWidth: string;
  apply?: (value: any[]) => void;
  hiddenApplyBtn?: string;
  anchorAccesorid?: any;
  hideApply?: boolean;
  controlErrorText?: string;
  removeCertificate?: (number) => void;
  requirementIndex: number;
  appendRequirement?: (value: ICetificate) => void;
  groupIndex: number;
  skillSetIndex: number;
  selectedSubCerts: ISelectedSubCert[];
  parentPage: FormPageType;
}

export enum FormPageType {
  Unit = 0,
  Order,
}
