/* eslint-disable @typescript-eslint/naming-convention */
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { SessionKey, getSessionValue } from './customHooks/sessionStorage/sessionHelpers';

const secondsCached = 43200;
interface ICacheResponseDetails {
  returnDataOnly?: boolean;
  // duration in seconds
  duration?: number;
  unit?: moment.unitOfTime.DurationConstructor;
}

export enum CacheType {
  searchOptions = 'search-options',
}

export const getCachedResponse = async (
  cache: CacheType,
  route: string,
  details: ICacheResponseDetails = {
    returnDataOnly: false,
    duration: secondsCached,
    unit: 'seconds',
  },
) => {
  const cacheBuster = getSessionValue(SessionKey.cacheBuster);
  if (cacheBuster) {
    const newCache = await caches.open(cache);
    const cachedResult = await newCache.match(route);
    const _duration =
      cacheBuster?.[cache]?.routes?.[route] ?? details?.duration ?? cacheBuster?.[cache]?.seconds ?? secondsCached;
    if (!isNullOrUndefined(cachedResult)) {
      const data = await cachedResult.json();
      if (moment(data.time).isAfter(moment().add(_duration * -1, details?.unit ?? 'seconds'))) {
        if (details?.returnDataOnly) {
          return data.data;
        } else {
          return {
            data: data.data,
            status: cachedResult.status,
            statusText: cachedResult.statusText,
          };
        }
      }
    }
  }
};

export const cacheResponse = async (cache: CacheType, route: string, response: AxiosResponse<any>) => {
  if (getSessionValue(SessionKey.cacheBuster)) {
    const newCache = await caches.open(cache);
    newCache.put(
      route,
      new Response(JSON.stringify({ time: new Date(), data: response.data }), {
        status: response.status,
        statusText: response.statusText,
        headers: {
          'Content-Type': response.headers['content-type'] ?? 'application/json',
        },
      }),
    );
  }
};
