import { Grid, Theme, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PhoneNumber } from '@AMIEWEB/Common';
import { formatPhoneNumber } from 'app/helpers/phoneHelpers';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    background: '#E8F4FD',
    borderBottom: `1px solid ${theme.palette.system.lightGrey}`,
    borderTop: `1px solid ${theme.palette.system.lightGrey}`,
    padding: '2% 4% 2% 4%',
  },
  header: {
    margin: '1% 0',
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Roboto',
    letterSpacing: '0px',
    color: theme.palette.system.darkCharcoal,
    opacity: 1,
    fontSize: 14,
  },
  textField: {
    backgroundColor: theme.palette.system.white,
    marginTop: '1%',
    width: '100%',
    padding: '0 !important',
    height: '38px',
  },
  phone: {
    backgroundColor: theme.palette.system.white,
    height: '38px',
  },
}));

interface ImanualEntry {
  phoneNumber: string;
  setPhoneNumber: Function;
  disabled: boolean;
}

const ManualEntryField = (props: ImanualEntry) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { phoneNumber, setPhoneNumber, disabled } = props;

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>{t('notification.sms.addPhoneNumber')}</Typography>
      <Grid container direction="row">
        <Grid item xs={12}>
          <PhoneNumber
            disableUnderline
            fullWidth
            className={classes.textField}
            variant="outlined"
            placeholder="(###) ###-####"
            value={formatPhoneNumber(phoneNumber) || ''}
            onChange={e => setPhoneNumber(e.target.value)}
            InputProps={{ classes: { root: classes.phone }, autoFocus: true }}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ManualEntryField;
