import { setPstTimeOffset } from '@AMIEWEB/AmWorkDesk/CoverageDesk/CoverageModal/assignHelper';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { ComplianceStatusProgressNotesDetails, DirectHireOption } from 'app/models/Placement/PlacementDetails';
import moment from 'moment';
import { Concatenate } from 'utils/string/string';
import { IPlacementMapAddress } from './types';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { put } from 'redux-saga/effects';

const formatAddress = address => {
  if (!address) return '';
  return address.replace(/ /g, '%20').replace(/#/g, '%23').replace(/\./g, '');
};

const formatValue = value => (value ? `%20${value.replace(/\./g, '')}` : '');

export const formatPlacementGoogleMapLink = (candidate: IPlacementMapAddress, facility: IPlacementMapAddress) => {
  const originAddress = formatAddress(candidate.address);
  const destinationAddress = formatAddress(facility.address);

  const domainUrl = globalThis?.app?.env?.REACT_APP_GOOGLE_DIRECTIONS_URL;
  const origin = Concatenate(
    [originAddress, formatValue(candidate.city), formatValue(candidate.state), formatValue(candidate.zip)],
    '%2C',
  );

  const destination = Concatenate(
    [destinationAddress, formatValue(facility.city), formatValue(facility.state), formatValue(facility.zip)],
    '%2C',
  );

  return `${domainUrl}&origin=${origin}&destination=${destination}`;
};

export const getCueNoteRecipientPayload = placementId => {
  const coverageStartDate = setPstTimeOffset(
    moment(new Date()).set({
      hour: 0,
      minute: 0,
      second: 0,
    }),
  );
  const coverageEndDate = setPstTimeOffset(
    moment(new Date()).set({
      hour: 23,
      minute: 59,
      second: 59,
    }),
  );
  return {
    placementId: placementId.toString(),
    coverageStartDate: coverageStartDate,
    coverageEndDate: coverageEndDate,
  };
};

export const getLicensure = groups => {
  const licenses = [];
  if (isNullOrUndefined(groups) || groups?.length === 0) {
    return licenses;
  }
  groups.forEach(group => {
    if (isNullOrUndefined(group.skillsets) || group.skillsets.length === 0) {
      return;
    }
    group.skillsets.forEach(skillset => {
      if (
        isNullOrUndefined(skillset) ||
        isNullOrUndefined(skillset.licenses) ||
        isNullOrUndefined(skillset.licenses.orderRequirement) ||
        isNullOrUndefined(skillset.licenses.orderRequirement.data) ||
        isNullOrUndefined(skillset.licenses.orderRequirement.data.orderLicensesDetails) ||
        skillset.licenses.orderRequirement.data.orderLicensesDetails.length === 0
      ) {
        return;
      }
      skillset.licenses.orderRequirement.data.orderLicensesDetails.forEach(orderLicensesDetail => {
        if (orderLicensesDetail.licenseAbbreviation && orderLicensesDetail.stateAbbreviation) {
          licenses.push(`${orderLicensesDetail.licenseAbbreviation} - ${orderLicensesDetail.stateAbbreviation}`);
        } else if (orderLicensesDetail.licenseAbbreviation && orderLicensesDetail.stateAbbreviation === null) {
          licenses.push(`${orderLicensesDetail.licenseAbbreviation}`);
        } else if (orderLicensesDetail.licenseAbbreviation === null && orderLicensesDetail.stateAbbreviation) {
          licenses.push(`${orderLicensesDetail.stateAbbreviation}`);
        }
      });
    });
  });
  return licenses;
};

export const getCertificates = groups => {
  const certificates = [];
  if (isNullOrUndefined(groups) || groups?.length === 0) {
    return certificates;
  }
  groups.forEach(group => {
    if (isNullOrUndefined(group.skillsets) || group.skillsets.length === 0) {
      return;
    }
    group.skillsets.forEach(skillset => {
      if (
        isNullOrUndefined(skillset) ||
        isNullOrUndefined(skillset.certifications) ||
        isNullOrUndefined(skillset.certifications.orderRequirement) ||
        isNullOrUndefined(skillset.certifications.orderRequirement.data) ||
        isNullOrUndefined(skillset.certifications.orderRequirement.data.certifications) ||
        skillset.certifications.orderRequirement.data.certifications.length === 0
      ) {
        return;
      }
      skillset.certifications.orderRequirement.data.certifications.forEach(certification => {
        if (certification.abbreviation && certification.issuedBy) {
          certificates.push(`${certification.abbreviation} - ${certification.issuedBy}`);
        } else if (certification.abbreviation && certification.issuedBy === null) {
          certificates.push(`${certification.abbreviation}`);
        } else if (certification.abbreviation === null && certification.issuedBy) {
          certificates.push(`${certification.issuedBy}`);
        }
      });
    });
  });
  return certificates;
};

export const searchNoteListByText = (
  searchText: string,
  notesList: Array<ComplianceStatusProgressNotesDetails>,
): Array<ComplianceStatusProgressNotesDetails> => {
  if (searchText === '') {
    return notesList;
  }
  let filteredNoteList = notesList.filter(
    note =>
      note.notes.toLowerCase().includes(searchText.toLowerCase()) ||
      note.userName.toLowerCase().includes(searchText.toLowerCase()),
  );
  return filteredNoteList;
};

export function* handleResponse(response) {
  for (const result of response.data) {
    yield put(
      globalActions.setStackSnackBar({
        message: result.message,
        severity: result.severity,
      }),
    );
  }
}

export const getDirectHireSelectedOption = (
  directHireOptions: DirectHireOption[],
  id: string | null,
  directHireTypeDescription?: string | null,
) => {
  const selectedOption: DirectHireOption = { id: id ?? '', description: directHireTypeDescription ?? '' };
  if (id === null) {
    return selectedOption;
  }
  const optionIndex = directHireOptions?.findIndex(directHireOption => directHireOption.id === id);
  if (optionIndex > -1) {
    return directHireOptions[optionIndex];
  } else {
    return selectedOption;
  }
};
