import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Grid,Typography } from 'amn-ui-core';
import { ApprovalDocuments } from './ApprovalDocuments';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { selectUser } from 'oidc/user.selectors';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import { RateDetails } from './RateDetails';
import { isAccountManagement, isClientContractAdminUser, isReadOnly } from 'oidc/userRoles';
import { facilityActions, ISaveRateElementCommand } from 'app/components/Facility/FacilityStore/Facility.redux';
import { RejectionReasonModal } from './RejectionReasonModal';
import { RelatedRateDetails } from './RelatedRateDetails';
import { selectedApproveRejectRows } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';

const useStyles = makeStyles<{ approvalChangesPending: boolean }>()((theme, props) => ({
 
  grid: {
    border: 'none',
    fontSize: '14px',
    '& .MuiDataGrid-main-main': {
      minHeight: 'auto',
    },
  },
  gridCellData: {
    width: 'inherit',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  saveButton: {
    backgroundColor: props.approvalChangesPending ? '#006FB9' : '#d3d3d3',
    color: '#FCFCFD',
    '&:hover': {
      backgroundColor: '#006FB9',
    },
    marginLeft: '25px',
  },
  cancelButton: {
    color: '#555555',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
}));

export const OffContractDetailsPanel = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const appUser = useSelector(selectUser);
  const { promiseInProgress: isCallingAPI } = usePromiseTracker({ area: 'update-rate-elements', delay: 0 });
  const [approvalChangesPending, setApprovalChangesPending] = useState<boolean>(false);
  const [cancelled, setCancelled] = useState<boolean>(false);
  const { classes } = useStyles({ approvalChangesPending: approvalChangesPending });
  const [statusId, setStatusId] = useState<number>();
  const [isModalOpen, setIsModelOpen] = useState<boolean>(false);
  const [reasonSaved, setReasonSaved] = useState<boolean>(false);
  const [reason, setReason] = useState<any>();
  const [aprovalDocumentsHeight, setApprovalDocumentsHeight] = useState<number>(0);
  const [relatedRateElementsHeight, setRelatedRateElementsHeight] = useState<number>(0);
  const [ccaOrAMUser, setCCAorAMUser] = useState<boolean>(false);
  const [ccaUser, setCCAUser] = useState<boolean>(false);
  const cancelApprovalOrRejection = () => {
    setCancelled(!cancelled);
    setApprovalChangesPending(false);
  };
  const [rateDetailsRows, setRateDetailsRows] = useState<any[]>([cloneDeep(props.row)]);
  const [changesInRateDetails, setChangesInRateDetails] = useState([]);
  const [relatedRateDetailsRows, setRelatedRateDetailsRows] = useState<any[]>([]);
  const changesInRelatedRateDetails = useSelector(selectedApproveRejectRows);

  const saveApprovalOrRejection = () => {
    if (showRejectReasonModal()) {
      setIsModelOpen(true);
    } else {
      setReasonSaved(true);
    }
  };

  const showRejectReasonModal = () => {
    return changesInRateDetails.some(x => x.statusId === 2) || changesInRelatedRateDetails.some(x => x.statusId === 2);
  };

  useEffect(() => {
    if (
      (props.row.statusId === 2 || props.row.statusId === 3 || props.row.statusId === 4) &&
      isClientContractAdminUser(appUser.userInfo.roles) &&
      !isReadOnly(props.appUser?.userInfo?.roles || [])
    ) {
      setCCAUser(true);
    } else {
      setCCAUser(false);
    }

    if (
      (isClientContractAdminUser(appUser.userInfo.roles) ||
      isAccountManagement(appUser.userInfo.roles)) &&
      !isReadOnly(props.appUser?.userInfo?.roles || [])
    ) {
      setCCAorAMUser(true);
    } else {
      setCCAorAMUser(false);
    }
  }, [appUser]);

  useEffect(() => {
    if (reasonSaved) {
      if (changesInRateDetails?.length > 0) {
        changesInRateDetails[0].statusId = statusId;
        if (statusId === 2) {
          changesInRateDetails[0].reasonId = reason?.reasonId;
          changesInRateDetails[0].reason = reason?.reason;
        } else {
          changesInRateDetails[0].reasonId = null;
          changesInRateDetails[0].reason = '';
        }
      }

      let rows = changesInRelatedRateDetails?.map(elem => ({
        ...elem,
        reasonId: elem.statusId === 2 ? reason?.reasonId : elem.statusId === 3 ? elem.reasonId : null,
        reason: elem.statusId === 2 ? reason?.reason : elem.statusId === 3 ? elem.reason : '',
        effectiveEndDate: elem.statusId === 5 ? new Date() : elem.effectiveEndDate,
      }));

      rows = changesInRateDetails.concat(rows);

      let command: ISaveRateElementCommand = {
        currentUserId: appUser.userInfo?.employeeId,
        currentUserName: appUser.userInfo?.firstName + ' ' + appUser.userInfo?.lastName,
        facilityId: props.facilityId,
        rateElements: rows,
      };
      dispatch(
        facilityActions.updateRateElements({
          saveRateElementCommand: command,
          message: rows?.length > 0 ? t('notification.facilityDetials.updateSuccessForSingleElement') : null,
        }),
      );
    }
  }, [reasonSaved]);

  useEffect(() => {
    if (!isCallingAPI) {
      setReasonSaved(false);
      setChangesInRateDetails([]);
      dispatch(facilityActions.setSelectedRejectedRows([]));
    }
  }, [isCallingAPI]);

  useEffect(() => {
    props.setContentDetailHeight(aprovalDocumentsHeight + relatedRateElementsHeight);
  }, [aprovalDocumentsHeight, relatedRateElementsHeight]);

  // Majority of the work will be creating this details view
  return (
    <>
      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid alignItems="center" sx={{ padding: '0px 12px 12px 0px', display: 'grid', justifyContent: 'space-between' }}>
          <Grid item xs={6}>
            <ApprovalDocuments
              facilityId={props.facilityId}
              row={props.row}
              setApprovalDocumentsHeight={setApprovalDocumentsHeight}
            />
        </Grid>
        </Grid>
        <Grid sx={{ padding: '0px 0px 12px 12px', display: 'grid', justifyContent: 'space-between', marginLeft:'50px' }}>
          <Grid item xs={6}>
            <Typography style={{ paddingBottom: '7px', fontSize: '14px', fontWeight: 600 }}>
              {t('notification.facilityDetials.rate')}
            </Typography>
            <RateDetails
              rows={rateDetailsRows}
              row={props.row}
              getColor={props.getColor}
              getValue={props.getValue}
              appUser={appUser}
              setApprovalChangesPending={setApprovalChangesPending}
              cancelled={cancelled}
              isCallingAPI={isCallingAPI}
              setStatusId={setStatusId}
              statusId={statusId}
              setRows={setRateDetailsRows}
              changesInRateDetails={changesInRateDetails}
              setChangesInRateDetails={setChangesInRateDetails}
              ccaOrAMUser={ccaOrAMUser}
              ccaUser={ccaUser}
            />
          </Grid>
      </Grid>
      </Grid>
      <Grid sx={{ display: 'flex', justifyContent: 'space-between', padding: '-4px 0px 0px 0px' }}>
        <Grid item xs={12}>
          <span style={{ display: 'flex' }}>
            <Typography style={{ paddingBottom: '7px', fontSize: '14px', fontWeight: 600, paddingRight: '7px' }}>
              {t('notification.facilityDetials.relatedRate')}
            </Typography>
            <Typography style={{ paddingBottom: '7px', fontSize: '14px', fontWeight: 400 }}>
              {t('notification.facilityDetials.pendingApproval')}
            </Typography>
          </span>
          <RelatedRateDetails
            rows={relatedRateDetailsRows}
            row={props.row}
            getColor={props.getColor}
            getValue={props.getValue}
            appUser={appUser}
            setApprovalChangesPending={setApprovalChangesPending}
            cancelled={cancelled}
            isCallingAPI={isCallingAPI}
            setStatusId={setStatusId}
            statusId={statusId}
            changesInRelatedRateDetails={changesInRelatedRateDetails}
            setRelatedRateDetailsRows={setRelatedRateDetailsRows}
            setRelatedRateElementsHeight={setRelatedRateElementsHeight}
            ccaUser={ccaUser}
            ccaOrAMUser={ccaOrAMUser}
          />
        </Grid>
      </Grid>
      {(ccaUser || ccaOrAMUser && ( props?.row?.statusId === 2  || props?.row?.statusId === 4 || props?.row?.statusId === 1  || props?.row?.statusId === 3)) && (
        <Grid sx={{ width:'100%',  display: 'flex', justifyContent: 'right', marginRight: '35px', marginTop: '9px' }}>
          <Button
            disableRipple
            disableFocusRipple
            className={classes.cancelButton}
            onClick={cancelApprovalOrRejection}
            variant="text"
          >
            {t('global.button.cancel')}
          </Button>
          <Button
            disabled={!approvalChangesPending}
            disableRipple
            disableFocusRipple
            className={classes.saveButton}
            variant="text"
            // color="primary"
            onClick={saveApprovalOrRejection}
          >
            {t('global.button.save')}
          </Button>
        </Grid>
      )}
      <RejectionReasonModal
        facilityId={props.facilityId}
        isModalOpen={isModalOpen}
        setIsModelOpen={setIsModelOpen}
        reasonSaved={reasonSaved}
        setReasonSaved={setReasonSaved}
        setReason={setReason}
      />
    </>
  );
};
