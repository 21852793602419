import React from 'react';

export const useConfirmExit = () => {
  const [block, setBlock] = React.useState<boolean>(false);

  const confirmExit = event => {
    event.preventDefault();
    return (event.returnValue = 'Are you sure');
  };

  React.useEffect(() => {
    if (block) {
      window.addEventListener('beforeunload', confirmExit);
    } else {
      window.removeEventListener('beforeunload', confirmExit);
    }
    return () => {
      window.removeEventListener('beforeunload', confirmExit);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block]);

  const blockExit = () => {
    setBlock(true);
  };

  const allowExit = () => {
    setBlock(false);
  };

  return { blockExit, allowExit };
};
