import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { CustomSelect } from '../../../ComponentLibrary/Filter/CustomSelect';

const useStyles = makeStyles()({
  selectFieldStyle: {
    minWidth: 120,
    height: 30,
  },
  dropdownStyle: {
    marginTop: '0.5em',
  },
});

export const PageActions = props => {
  const { classes } = useStyles();
  const { pipelineChoices, pipelineView } = props;
  const options = Object.values(pipelineChoices).map(value => ({ name: value, value }));

  return (
    <CustomSelect
      selectFieldStyle={classes.selectFieldStyle}
      dropdownStyle={classes.dropdownStyle}
      options={options}
      value={pipelineView}
      name={'pipelineFilter'}
      menuHighlight
      onChange={props.setPipeline}
    />
  );
};
