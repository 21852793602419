import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { useWatch } from 'react-hook-form';
import { useEditableRequirement } from './EditRequirementsHelper';
import { GenderCheck } from './EditablePassFailLogic';

export const useEditableRequirementGender = () => {
  // GENDER
  const gender: any = useWatch({ name: 'gender' });
  const transportation: any = useWatch({ name: 'transportationChosen' });

  useEditableRequirement({
    data: gender?.object,
    secondaryData: transportation?.transportation,
    requirementId: IPlacementRequirementKey.gender,
    requirementKey: 'gender',
    requirementCheck: GenderCheck,
    shouldAddFailedRequirement: transportation?.transportation !== 'Unknown',
    failRequirement: {
      id: IPlacementRequirementKey.gender,
      message: 'Gender: Missing',
      tooltip: {
        message: 'Gender is required',
      },
    },
  });
};
