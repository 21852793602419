import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { trackPromise } from 'react-promise-tracker';
import { httpSuccess } from 'app/services/serviceHelpers';
import { TemplateService } from 'app/services/TemplateService/template-service';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { PayloadAction } from '@reduxjs/toolkit';
import { selectUser } from 'oidc/user.selectors';
import { Concatenate } from 'utils/string/string';
import { contractTemplateEditorActions } from './slice';
import { IContractTemplate } from './types';
import { deriveTokensObj } from './helper';
import { globalActions } from 'app/ApplicationRoot/Global.redux';

const TrackTemplatesWrapper = (fn, ...args) => trackPromise(fn(...args), 'document-templates-editor');
const TrackMergeTokensWrapper = (fn, ...args) => trackPromise(fn(...args), 'document-templates-merge-tokens');
const TrackPublishTemplateWrapper = (fn, ...args) => trackPromise(fn(...args), 'document-templates-editor-publish');

export function* onInit() {
  yield all([call(requestTemplates), call(requestMergeTokenList)]);
}

export function* requestTemplates() {
  try {
    const templateService: TemplateService = yield getContext('templateService');
    const response = yield call(TrackTemplatesWrapper, templateService.getTemplates, true, 'Contract');
    if (httpSuccess(response.status)) {
      yield put(contractTemplateEditorActions.setTemplates(response.data));

      return response.data;
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestTemplates',
        area: 'src/store/reduc-store/template-editor/saga',
      },
    });
  }
}

export function* publishTemplate(action: PayloadAction<IContractTemplate>) {
  try {
    const templateService: TemplateService = yield getContext('templateService');
    const {
      userInfo: { employeeId, firstName, lastName },
    } = yield select(selectUser);
    const response = yield call(TrackPublishTemplateWrapper, templateService.publishTemplate, {
      id: action.payload.templateId,
      templateName: action.payload.templateName,
      templateText: action.payload.templateText,
      updatedBy: employeeId,
      updatedByName: Concatenate([firstName, lastName], ' '),
      category: 'Contract',
    });
    if (httpSuccess(response.status)) {
      yield put(contractTemplateEditorActions.toggleForceModalCloseFlag());
      const templates: IContractTemplate[] = yield call(requestTemplates);
      const currentTemplate = templates.find(x => x.templateId === response.data.templateId);
      /** Use forceReset to reset content in the editor */
      // @ts-ignore
      yield put(contractTemplateEditorActions.setSelectedTemplate({ ...currentTemplate, forceReset: true }));
    } else
      yield put(globalActions.setSnackBar({ message: 'Something went wrong. Please try again.', severity: 'error' }));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'publishTemplate',
        area: 'src/store/reduc-store/template-editor/saga',
      },
    });
  }
}

export function* requestMergeTokenList() {
  try {
    const templateService: TemplateService = yield getContext('templateService');
    const response = yield call(TrackMergeTokensWrapper, templateService.getMergeTokensList, 'Contract');
    if (httpSuccess(response.status)) {
      const tokenObj = deriveTokensObj(response.data);
      yield put(contractTemplateEditorActions.setMergeTokenList(tokenObj));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestMergeTokenList',
        area: 'src/store/reduc-store/template-editor/saga',
      },
    });
  }
}

export function* templateEditorSaga() {
  yield takeLatest(contractTemplateEditorActions.onInitAction.type, onInit);
  yield takeLatest(contractTemplateEditorActions.getTemplates.type, requestTemplates);
  yield takeLatest(contractTemplateEditorActions.getMergeTokensList.type, requestMergeTokenList);
  yield takeLatest(contractTemplateEditorActions.publishTemplate.type, publishTemplate);
}
