import { Grid, Typography, Box, Skeleton, Link } from 'amn-ui-core';
import React from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { IPlacementCandidate } from 'store/redux-store/new-placement/types';
import { Concatenate } from 'utils/string/string';
import { usePromiseTracker } from 'react-promise-tracker';
import { missingField } from 'app/constants';

export const FundamentalInfo: React.FC<IPlacementCandidate> = ({
  firstName,
  lastName,
  candidateId,
  brandId,
  phone,
  email,
}) => {
  const { promiseInProgress } = usePromiseTracker({ area: 'new-placement-get-candidate' });
  const candidate = React.useMemo(() => {
    let name = Concatenate([firstName, lastName, candidateId ? `(${candidateId})` : ''], ' ');
    return name;
  }, [candidateId, firstName, lastName]);

  return promiseInProgress ? (
    <Skeleton width="100%" />
  ) : (
    <Grid container flexDirection="column" spacing={1}>
      <Grid item>
        <Link
          href={`${window.location.origin}/candidate/${candidateId}/${brandId}`}
          variant="body1"
          color="navyBlue"
          target="_blank"
          rel="noopener noreferrer"
          underline="hover"
        >
          {candidate}
        </Link>
      </Grid>
      <Grid item sx={{ display: 'flex', gap: '12px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '6px' }}>
          <EmailOutlinedIcon fontSize="small" color="disabled" />
          <Typography component="span" variant="body1" color="navyBlue">
            {email ?? missingField}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '6px' }}>
          <PhoneOutlinedIcon fontSize="small" color="disabled" />
          <Typography component="span" variant="body1" color="navyBlue">
            {phone ?? missingField}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
