import React, { useState } from 'react';
import UnitDetailCard from '@AMIEWEB/Order/OrderCreationForm/Common/UnitDetailCard';
import { theme } from 'styles/global-styles';
import { SkillSet } from './SkillSet';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Grid } from 'amn-ui-core';

export const SkillSetWrapper = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [experienceCount, setExperienceCount] = useState(0);
  const { fields: groupFields } = useFieldArray({
    control,
    name: 'skillSetGroup.groups',
  });

  return (
    <UnitDetailCard
      title={`${t('facility.units.unitDetails.unitRequirements.experience')}: ${experienceCount}`}
      customBackgroundColor={theme.palette.system.paperBackground}
    >
      {groupFields?.map((groupField, groupIndex) => (
        <Grid key={groupField.id}>
          <SkillSet groupIndex={groupIndex} setExperienceCount={setExperienceCount} />
        </Grid>
      ))}
    </UnitDetailCard>
  );
};
