import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Moment from 'moment';

const useStyles = makeStyles()({
  table: {
    minWidth: 650,
  },
});

export function HoverGrid(props) {
  const { classes } = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {props.column.map((header, index) =>
              index === 0 ? <TableCell>{header.title}</TableCell> : <TableCell>{header.title}</TableCell>,
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.row.slice(0, 5).map(row => (
            <TableRow>
              <TableCell component="th" scope="row">
                {row.placementId}
              </TableCell>
              <TableCell>{row.candidateName}</TableCell>
              <TableCell>{row.recruiterName}</TableCell>
              <TableCell>{row.placementStatus}</TableCell>
              <TableCell>{Moment(row.sentDate).format('MM/DD/YYYY')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
