import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import { FacilityDetailsHeader, FacilityResponse } from 'app/models/Facility/FacilityDetails';
import { BreadcrumbNavigation } from 'app/components/Common/BreadcrumbNavigation/BreadcrumbNavigation';
import { useDecision } from '@optimizely/react-sdk';
import { facilityClientContactsSaga } from '../FacilityTabs/ContactTab/Store/FacilityContacts.saga';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import {
  FacilityContactSliceKey,
  FacilityContactsReducer,
} from '../FacilityTabs/ContactTab/Store/FacilityContacts.redux';
import { FacilityUnitTab } from '../FacilityTabs/UnitTab/FacilityUnitTab';
import { facilityUnitsSaga } from '../FacilityTabs/UnitTab/Store/FacilityUnit.saga';
import { FacilityUnitKey, FacilityUnitReducer } from '../FacilityTabs/UnitTab/Store/FacilityUnit.redux';
import { FacilityGeneralTab } from '../FacilityTabs/GeneralTab/FacilityGeneralTab';
import { FacilityContactTab } from '../FacilityTabs/ContactTab/FacilityContactTab';
import { FacilityPlacementsWapper } from '../../FacilityPlacements/FacilityPlacementsWapper';
import { ChangeHistory } from '../FacilityTabs/ChangeHistoryTab/ChangeHistory';
import { RateCardTab } from '../FacilityTabs/RateCardTab/RateCardTab';
import { ff_facility_ui_ratecardtab } from 'app/constants/FeatureFlags/Facility/Keys';
import { FacilityPreferences } from '../FacilityTabs/PreferencesTab/FacilityPreferences';
import InstructionsTab from '../FacilityTabs/Instructions/InstructionsTab';
import { orderDataReducer, orderDataSliceKey } from '@AMIEWEB/Order/Store/Order.redux';
import { orderDataSaga } from '@AMIEWEB/Order/Store/Order.saga';
import { selectPreferenceChanged } from 'store/redux-store/facility-preferences/selectors';
import { useHistory, useLocation } from 'react-router-dom';
import {
  facilityPreferenceActions,
  facilityPreferenceReducer,
  facilityPreferenceSliceKey,
} from 'store/redux-store/facility-preferences/slice';
import { facilityPreferenceSaga } from 'store/redux-store/facility-preferences/saga';
import { GenericTabs } from '@AMIEWEB/Common/Tabs/GenericTabs';
import { orderCreationReducer, orderCreationSliceKey } from '@AMIEWEB/Order/Store/OrderCreation.redux';
import { orderCreationSaga } from '@AMIEWEB/Order/Store/OrderCreation.saga';

import { selectFacilityDetail } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { FacilityCommunicationHistoryWrapper } from '../FacilityTabs/CommunicationHistory/Filters/FacilityCommunicationHistoryWrapper';

const useStyes = makeStyles()(theme => ({
  profileTab: {
    position: 'relative',
    '& .MuiTabScrollButton-root.Mui-disabled': {
      opacity: '0.6',
    },
  },
  profileTabMaster: {
    marginTop: '0.5rem',
  },
}));

interface Props {
  isCreditHold: boolean;
  isMaxBookingLimit: boolean;
  facilityHeader: FacilityDetailsHeader | null;
  navigationData: any;
}

export enum FacilityTabs {
  General_Tab,
  Instruction_Tab,
  Unit_Tab,
  Placement_Tab,
  Preferences_Tab,
  Rate_Card_Tab,
  Facility_Contact_Tab,
  Change_History_Tab,
}

export const FacilityBody = (props: Props) => {
  useInjectReducer({ key: FacilityContactSliceKey, reducer: FacilityContactsReducer });
  useInjectReducer({ key: FacilityUnitKey, reducer: FacilityUnitReducer });
  useInjectSaga({ key: FacilityContactSliceKey, saga: facilityClientContactsSaga });
  useInjectSaga({ key: FacilityUnitKey, saga: facilityUnitsSaga });
  useInjectReducer({ key: orderDataSliceKey, reducer: orderDataReducer });
  useInjectSaga({ key: orderDataSliceKey, saga: orderDataSaga });
  useInjectReducer({ key: facilityPreferenceSliceKey, reducer: facilityPreferenceReducer });
  useInjectSaga({ key: facilityPreferenceSliceKey, saga: facilityPreferenceSaga });
  useInjectReducer({ key: orderCreationSliceKey, reducer: orderCreationReducer });
  useInjectSaga({ key: orderCreationSliceKey, saga: orderCreationSaga });
  const { isCreditHold, navigationData } = props;
  const { classes } = useStyes();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const preferenceChanged = useSelector(selectPreferenceChanged);
  const [rateCardTabDecision] = useDecision(ff_facility_ui_ratecardtab, { autoUpdate: true });
  const [currentTab, setCurrentTab] = React.useState<number>(0);

  const tabs = () => {
    const tabConfig = [
      {
        label: 'General',
        component: <FacilityGeneralTab isCreditHold={isCreditHold} />,
      },
      {
        label: 'Instructions',
        component: <InstructionsTab />,
      },
      {
        label: 'Units',
        component: <FacilityUnitTab />,
      },
      { label: 'Placements', component: <FacilityPlacementsWapper /> },
      { label: 'Preferences', component: <FacilityPreferences /> },
      { label: 'Rate Card', component: <RateCardTab /> },
      {
        label: 'Contacts',
        component: <FacilityContactTab />,
      },
      {
        label: 'Change History',
        component: <ChangeHistory />,
      },
      {
        label: 'Communication History',
        component: <FacilityCommunicationHistoryWrapper />,
      },
    ];

    return !rateCardTabDecision.enabled ? tabConfig.filter(tab => tab.label !== 'Rate Card') : tabConfig;
  };

  const customTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (preferenceChanged) {
      dispatch(facilityPreferenceActions.setConfirmationDialog(true));
      dispatch(facilityPreferenceActions.setTabValue(newValue));
    } else {
      dispatch(facilityPreferenceActions.setTabValue(newValue));
      history.push({ pathname: history.location.pathname, search: `tab=${newValue}`, state: location?.state });
    }
  };

  return (
    <div>
      {navigationData && navigationData.breadcrumbHierarchy && (
        <LayoutGridItem xs={12}>
          <BreadcrumbNavigation {...navigationData} />
        </LayoutGridItem>
      )}
      <div>
        <Grid item xs={12}>
          <div className={`${classes.profileTab} ${classes.profileTabMaster}`}>
            <GenericTabs
              tabs={tabs()}
              modifyUrl
              value={currentTab}
              onTabChanged={tab => setCurrentTab(tab)}
              customHandleChange={customTabChange}
              analytics={{ name: 'Facility Details' }}
            />
          </div>
        </Grid>
      </div>
    </div>
  );
};
