/* eslint-disable @typescript-eslint/no-explicit-any */

enum LogLevel {
  debug = 1,
}

export class Logger {
  static debug(message: string, ...data) {
    if (globalThis.amieLogLevel === LogLevel.debug) {
      console.info(message, ...data);
    }
  }

  static error(message: string, ...data) {
    if (globalThis.amieLogLevel === LogLevel.debug) {
      console.error(message, ...data);
    }
  }
}