import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import React from 'react';
import { Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogTitle: {
    padding: '30px 30px 20px 30px',
    fontSize: '20px',
  },
  dialogContent: {
    padding: '0px 30px 20px 30px',
    fontSize: '14px',
  },
  dialogActions: {
    padding: '0px 30px 30px 30px',
  },
}));

export const UseTemplate = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    openDialog,
    title,
    content,
    yesActionTitle,
    noActionTitle,
    handleYesAction,
    handleNoAction,
    handleCancelAction,
  } = props;
  return (
    <>
      <GenericDialog
        open={openDialog}
        maxWidth="sm"
        dialogTitleProps={{
          classes: { root: classes.dialogTitle },
          text: title,
        }}
        dialogContentProps={{ classes: { root: classes.dialogContent } }}
        dialogActionsProps={{ classes: { root: classes.dialogActions } }}
        dialogActions={[
          {
            text: t('detailsPage.confirmation.cancel'),
            onClick: handleCancelAction,
            style: { display: handleCancelAction !== null ? 'flex' : 'none',textDecoration:'none' },
          },
          {
            text: noActionTitle ?? t('detailsPage.confirmation.no'),
            onClick: handleNoAction,
            variant: 'contained',
            color: 'tertiary',
          },
          {
            text: yesActionTitle ?? t('detailsPage.confirmation.yes'),
            onClick: handleYesAction,
            variant: 'contained',
          },
        ]}
      >
        {content}
      </GenericDialog>
    </>
  );
};
