import { Grid, Typography, Divider, useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { TypeAheadCustomDropdown } from 'app/components/Tasks/CustomComponents/TypeAheadCustomDropdown';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RateElementsTableCell } from './RateElementsTableCell';

const useStyles = makeStyles<{ alignPopper: boolean; disabled: boolean }>()((theme, props) => ({
  headerTextStyle: {
    fontWeight: 500,
  },
  divider: {
    height: '50%',
    margin: '0 2px',
    width: '2px',
    display: 'flex',
    alignSelf: 'center',
  },
  addTaskButton: {
    border: '1px solid #C4C6C9',
    borderRadius: '4px',
    background: '#C4C6C9 0% 0% no-repeat padding-box',
    opacity: '1',
    height: '30px',
    width: '30px',
    padding: '0px 0px 0px 0px',
    font: 'normal normal normal 18px Roboto',
    color: '#FFFFFF',
    cursor: 'pointer',
    '&:hover': {
      background: props.disabled
        ? '#C4C6C9 0% 0% no-repeat padding-box'
        : `${theme.palette.components.button.secondary.backgroundColor} 0% 0% no-repeat padding-box`,
    },
  },

  tableHeader: {
    backgroundColor: '#F5F5F5',
    height: '50px',
    color: '#333333',
    borderBottom: '2px solid #8E8E8E',
    minWidth: 'max-content',
    flexWrap: 'nowrap',
  },

  shiftColumn: {
    display: 'flex',
    flexDirection: 'row',
    width: '152px',
    minWidth: '130px',
  },
  columnHeader: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    height: '50px',
    width: '100%',
    color: '#333333',
    alignItems: 'center',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
  },
  columnTitle: {
    paddingLeft: '11px',
    alignItems: 'center',
    display: 'flex',
    fontSize: '12px',
  },
  popper: {
    zIndex: 999,
    position: 'absolute',
    left: props.alignPopper ? '40rem' : 'auto',
  },
}));

export const RateTableHeader = props => {
  const {
    disabled,
    setDeleteColumn,
    columns,
    highlightedCol,
    setHighlightedCol,
    columnOptions,
    shifts,
    setShifts,
    selected,
    setSelected,
    popperOpen,
    setPopperOpen,
    shiftCount,
    setError,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const [highlightAddButton, setHighlightAddButton] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const formMethods = useForm({ mode: 'all' });
  const { classes } = useStyles({ alignPopper: shiftCount > 3, disabled });

  return (
    <FormProvider {...formMethods}>
      <Grid container className={classes.tableHeader}>
        {/* Header, First Column */}
        <Grid container item direction="column" className={classes.shiftColumn}>
          <Grid container item className={classes.columnHeader}>
            <Typography
              className={classes.columnTitle}
              classes={{ root: classes.headerTextStyle }}
              style={{ whiteSpace: 'nowrap' }}
            >
              {t('notification.ocbr.rateElements')}
            </Typography>
          </Grid>
        </Grid>
        <Divider classes={{ root: classes.divider }} orientation="vertical" variant="middle" />
        {/* Header, Columns 2:n-1 */}
        {columns?.map(column => {
          return (
            <>
              <RateElementsTableCell
                rateElement={null}
                title={'GWW'}
                shiftDetails={column}
                isDeletable={true}
                setDeleteColumn={setDeleteColumn}
                isColumnHeader={true}
                setHighlightedCol={setHighlightedCol}
                columnIndex={columns.indexOf(column)}
                highlightCol={columns.indexOf(column) === highlightedCol}
                setError={setError}
              />
              <Divider classes={{ root: classes.divider }} orientation="vertical" variant="middle" />
            </>
          );
        })}
        {/* Header, Last Column */}
        <Grid
          item
          direction="column"
          onMouseEnter={() => {
            setHighlightAddButton(true);
          }}
          onMouseLeave={() => {
            setHighlightAddButton(false);
          }}
          className={classes.shiftColumn}
          style={{ width: 'unset', backgroundColor: highlightAddButton ? '#E9EDF4' : '' }}
        >
          <Grid item className={classes.columnHeader}>
            <Grid style={{ paddingRight: '3px' }}>
              <Grid item className={classes.columnTitle}>
                <button
                  type="button"
                  className={classes.addTaskButton}
                  onClick={e => {
                    setAnchorEl(e.currentTarget);
                    setPopperOpen(prevState => !prevState);
                  }}
                  style={{
                    backgroundColor: popperOpen && theme.palette.components.button.secondary.backgroundColor,
                  }}
                  disabled={disabled}
                >
                  {t('notification.taskSidePanel.addTaskButton')}
                </button>
                <Typography
                  className={classes.columnTitle}
                  classes={{ root: classes.headerTextStyle }}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {t('notification.taskStatusModal.addShift')}
                </Typography>
              </Grid>
              <Grid className={classes.popper}>
                {popperOpen && (
                  <TypeAheadCustomDropdown
                    open={popperOpen}
                    apply={setShifts}
                    selected={shifts}
                    options={columnOptions}
                    onChange={setSelected}
                    isMultiSelect={true}
                    value={selected}
                    showPopper={setPopperOpen}
                    anchorEl={anchorEl}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
