import { XGridSideBarViewCell } from './../../Common/XGrid/cells/XGridSideBarViewCell';
import { GridColumns } from '@mui/x-data-grid-pro';
import { XGridContactHoverView } from 'app/components/Common/XGrid/cells/HoverViewRenderers/XGridContactHoverView';
import { XGridBlueCell } from 'app/components/Common/XGrid/cells/XGridBlueCell';
import { XGridDefaultColumn } from 'app/components/Common/XGrid/cells/XGridDefaults';
import { XGridLinkCell } from 'app/components/Common/XGrid/cells/XGridLinkCell';
import { XGridOverflowCell } from 'app/components/Common/XGrid/cells/XGridOverflowCell';
import { xGridDateSort, xGridNumberSortByField } from 'app/components/Common/XGrid/sorting/XGridSorting';
import { ChannelType } from 'app/components/Notification/Constants';
import { XGridNotificationCell } from './WorkdeskGrid/NotificationCell';
import { notSent } from 'app/ComponentLibrary/Filter/helper';
import { missingField } from '@AMIEWEB/Notification/MultiChannel/Sms/CredentialingAnalyst/PlacementDisplay';

export enum placementStatus {
  inSubmission = 'In Submission',
  offer = 'Offer',
  booked = 'Booked',
  onAssignment = 'On Assignment',
  travelerAccepts = 'Traveler Accepts',
  sent = 'Sent',
  waitingForClearance = 'Waiting for Clearance',
  requestFileOut = 'Request File Out',
  interviewed = 'Interviewed',
  interviewScheduled = 'Interview Scheduled',
  offerByFacility = 'Offer By Facility',
  okToSend = 'OK to Send',
}

export enum placementSubStatuses {
  wfc = 'WFC',
  rfo = 'RFO',
  sent = 'Sent',
  intrwd = 'INT',
  int = 'INT',
  obf = 'OBF',
  obbt = 'OBBT',
  fc = 'FC',
  ta = 'TA',
  booked = 'Booked',
  ba = 'BA',
  wgtg = 'WGTG',
  ppw = 'PPW',
  gtg = 'GTG',
  oa = 'OA',
}

export const notDefaultColumns = [
  'lastContactedOn',
  'extensionStatus',
  'credentialingRate',
  'credentialingAnalyst',
  'accountManager',
  'clinicalManager',
  'candidatePhone',
  'candidateEmail',
];

export const notInSubmissionColumns = [
  'startDate',
  'endDate',
  'extensionStatus',
  'credentialingRate',
  'credentialingAnalyst',
  'clinicalManager',
];

export const notOfferColumns = [
  'availabilityStartDate',
  'endDate',
  'extensionStatus',
  'credentialingRate',
  'credentialingAnalyst',
  'clinicalManager',
];

export const notBookedColumns = ['availabilityStartDate', 'endDate', 'extensionStatus', 'clinicalManager'];

export const notOAColumns = ['availabilityStartDate', 'credentialingRate', 'credentialingAnalyst'];

export const getPlacementGridColumns = (t, flexColumns, arbitrationAgreementFlag: boolean): GridColumns => {
  const defaultRecruiterWordDeskColumn = {
    ...XGridDefaultColumn,
    // replace this to have flex column layout for default columns
    // flex: flexColumns ? 1 : 0
    flex: 0,
  };
  const columns: GridColumns = [
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.recWorkDeskHeaders.placementRecruiter'),
      field: 'placementRecruiter',
      width: 150,
      sortable: false,
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.recWorkDeskHeaders.status'),
      field: 'status',
      width: 160,
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.recWorkDeskHeaders.placementId'),
      field: 'placementId',
      width: 90,
      renderCell: XGridOverflowCell(XGridLinkCell('/placement/{0}', ['placementId'], true))(
        XGridLinkCell('/placement/{0}', ['placementId']),
      ),
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.recWorkDeskHeaders.candidateId'),
      field: 'candidateId',
      width: 90,
      renderCell: XGridOverflowCell(XGridSideBarViewCell('/candidate/{0}/{1}', ['candidateId', 'brandId'], true))(
        XGridSideBarViewCell('/candidate/{0}/{1}', ['candidateId', 'brandId']),
      ),
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.recWorkDeskHeaders.candidate'),
      field: 'candidate',
      width: 180,
      renderCell: XGridOverflowCell(XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId'], true))(
        XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId']),
      ),
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.recWorkDeskHeaders.skillSet'),
      field: 'skillSet',
      width: 130,
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.recWorkDeskHeaders.orderId'),
      field: 'orderId',
      width: 90,
      renderCell: XGridOverflowCell(XGridLinkCell('/order/{0}', ['orderId'], true))(
        XGridLinkCell('/order/{0}', ['orderId']),
      ),
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.recWorkDeskHeaders.availableStartDate'),
      field: 'availabilityStartDate',
      type: 'date',
      sortComparator: xGridDateSort,
      width: 160,
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.recWorkDeskHeaders.startDate'),
      field: 'startDate',
      type: 'date',
      width: 100,
      sortComparator: xGridDateSort,
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.recWorkDeskHeaders.endDate'),
      field: 'endDate',
      type: 'date',
      width: 100,
      sortComparator: xGridDateSort,
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.recWorkDeskHeaders.facilityName'),
      field: 'facilityName',
      width: 280,
      renderCell: XGridOverflowCell(XGridLinkCell('/facility/{0}', ['facilityId'], true))(
        XGridLinkCell('/facility/{0}', ['facilityId']),
      ),
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.recWorkDeskHeaders.city'),
      field: 'facilityCity',
      width: 140,
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.recWorkDeskHeaders.state'),
      field: 'facilityState',
      width: 80,
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.recWorkDeskHeaders.lastContacted'),
      field: 'lastContactedOn',
      type: 'date',
      width: 130,
      sortComparator: xGridDateSort,
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.recWorkDeskHeaders.extensionStatus'),
      field: 'extensionStatus',
      width: 130,
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.recWorkDeskHeaders.credentialingRating'),
      field: 'credentialingRate',
      width: 160,
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.recWorkDeskHeaders.credentialingAnalyst'),
      field: 'credentialingAnalyst',
      width: 165,
      renderCell: XGridOverflowCell(XGridContactHoverView('analystAttributes'), true)(XGridBlueCell),
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.recWorkDeskHeaders.statusAge'),
      field: 'statusAge',
      width: 130,
      sortComparator: xGridNumberSortByField('statusAgeValue'),
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.recWorkDeskHeaders.accountManager'),
      field: 'accountManager',
      width: 145,
      renderCell: XGridOverflowCell(XGridContactHoverView('accountManagerAttributes'), true)(XGridBlueCell),
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.recWorkDeskHeaders.clinicalManager'),
      field: 'clinicalManager',
      width: 145,
      renderCell: XGridOverflowCell(XGridContactHoverView('clinicalManagerAttributes'), true)(XGridBlueCell),
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.recWorkDeskHeaders.candidatePhone'),
      field: 'candidatePhone',
      width: 140,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: XGridOverflowCell(
        XGridNotificationCell({ channel: ChannelType.voice, displayKey: 'candidatePhone', v2: true }),
        true,
      )(XGridNotificationCell({ channel: ChannelType.voice, displayKey: 'candidatePhone', tooltip: false })),
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.recWorkDeskHeaders.candidateEmail'),
      field: 'candidateEmail',
      width: 135,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: XGridOverflowCell(
        XGridNotificationCell({ channel: ChannelType.email, displayKey: 'candidateEmail', v2: true }),
        true,
      )(XGridNotificationCell({ channel: ChannelType.email, displayKey: 'candidateEmail', tooltip: false })),
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.amPlacementColumns.lastCueDate'),
      field: 'lastCueDate',
      width: 220,
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.amPlacementColumns.lastCueNote'),
      field: 'lastCueNote',
      width: 220,
    },
    {
      ...defaultRecruiterWordDeskColumn,
      headerName: t('home.amPlacementColumns.cuedFrom'),
      field: 'cuedFrom',
      width: 190,
    },
  ];
  const arbitrationColumns: GridColumns = [
    {
      ...defaultRecruiterWordDeskColumn,
      field: 'arbitrationStatus',
      headerName: 'Arbitration Status',
      width: 150,
      description: t('home.recWorkDeskHeaders.toolTip.arbitrationStatus'),
    },
    {
      ...defaultRecruiterWordDeskColumn,
      field: 'arbitrationSentDate',
      headerName: 'Arbitration Sent Date',
      width: 150,
      description: t('home.recWorkDeskHeaders.toolTip.arbitrationSentDate'),
      sortComparator: xGridDateSort,
    },
    {
      ...defaultRecruiterWordDeskColumn,
      field: 'arbitrationSignedDate',
      headerName: 'Arbitration Signed Date',
      width: 150,
      description: t('home.recWorkDeskHeaders.toolTip.arbitrationSignedDate'),
      sortComparator: xGridDateSort,
    },
  ];
  const viewColumns = arbitrationAgreementFlag ? columns.concat(arbitrationColumns) : columns;
  return viewColumns;
};

export const getPlacementGridColumnVisibilityModel = {
  placementRecruiter: true,
  status: true,
  placementId: true,
  candidateId: true,
  candidate: true,
  skillSet: true,
  orderId: true,
  availabilityStartDate: true,
  startDate: true,
  endDate: true,
  facilityName: true,
  facilityCity: true,
  facilityState: true,
  lastContactedOn: true,
  extensionStatus: true,
  credentialingRate: true,
  credentialingAnalyst: true,
  statusAge: true,
  accountManager: true,
  clinicalManager: true,
  candidatePhone: true,
  candidateEmail: true,
  lastCueDate: true,
  lastCueNote: true,
  cuedFrom: true,
  arbitrationStatus: true,
  arbitrationSentDate: true,
  arbitrationSignedDate: true,
};

export const checkForPipelineEquality = (arrA, arrB) => {
  if (arrA?.length !== arrB?.length) {
    return false;
  }

  return arrA?.every(elementA => {
    const indexB = arrB?.findIndex(elementB => {
      return (
        elementA?.category === elementB?.category &&
        elementA?.subcategory === elementB?.subcategory &&
        elementA?.value === elementB?.value
      );
    });
    return indexB !== -1;
  });
};

export const getMultiSelectDefaultOptions = (options, withoutAllOption?: boolean) => {
  const opts = options.length
    ? [
        ...options.map((opt, index) => ({
          id: index + 1,
          name: opt,
          value: opt === notSent ? missingField : opt,
        })),
      ]
    : [];
  if (!withoutAllOption && opts.length > 0) opts.unshift({ id: 0, name: 'All', value: 'All' });
  return opts;
};
