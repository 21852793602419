import React, { useCallback, useState } from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import { CompactStatus, IOrderLicense } from '@AMIEWEB/Order/Store/Order.redux';
import { selectOrderRequirementTypes } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { useFormContext } from 'react-hook-form';
import { CollectionType } from 'app/enums/Common';
import { OrderLicenseTypeAheadDropdown } from '../../../OrderLicense/OrderLicenseTypeAheadDropdown';

interface GridCellProps {
  [key: string]: any;
}

const useStyles = makeStyles()({
  selectField: {
    minWidth: 450,
    zIndex: 1000,
  },
});

const LicenseStateRenderer = React.memo(function LicenseStateRenderer(cellParams: GridCellProps) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [selectedValues, setSelectedValues] = useState<any[]>([]);
  const { states } = useSelector(selectOrderRequirementTypes);
  const { setValue, getValues } = useFormContext();
  const [hideField, setHideField] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  React.useEffect(() => {
    if (cellParams?.param?.id && cellParams?.selectedLicense) {
      const stateArray: any[] = [];
      let isRequiredAdjustment = false;
      cellParams?.skillsetLicenseData?.forEach(data => {
        if (data?.licenseId === cellParams?.param.id && data?.compactStatus === CompactStatus.single) {
          setIsError(data?.isError?.state);
          const defaultStateValue = data?.licenseStateDetails;
          //check if any existing license state isRequired is false
          isRequiredAdjustment = defaultStateValue?.some(state => state?.isRequired === false);
          defaultStateValue?.map(stateDetails => {
            const selectedStatesValue = {
              state: stateDetails?.state,
              isRequired: stateDetails?.isRequired,
              documentAttachmentRequired: stateDetails?.documentAttachmentRequired,
            };
            stateArray.push(selectedStatesValue);
          });
        }
      });

      if (isRequiredAdjustment) {
        // if any license state isRequired is false then make it true
        const currLicenseDetails = getValues(
          `skillSetGroup.groups[${cellParams?.groupIndex}].skillsets[${cellParams?.skillSetIndex}].collections[${CollectionType.LicensesIndex}].requirements`,
        );
        const updatedLicenseDetails = currLicenseDetails.map((item: any) => {
          if (item.licenseId === cellParams?.param?.id) {
            return {
              ...item,
              licenseStateDetails: item.licenseStateDetails.map((stateDetails: any) => {
                return {
                  ...stateDetails,
                  isRequired: true,
                };
              }),
            };
          } else {
            return item;
          }
        });

        setValue(
          `skillSetGroup.groups[${cellParams?.groupIndex}].skillsets[${cellParams?.skillSetIndex}].collections[${CollectionType.LicensesIndex}].requirements`,
          updatedLicenseDetails,
        );
      }

      setHideField(false);
      setSelectedValues(stateArray);
    }
  }, [cellParams?.skillsetLicenseData]);

  const onApplyFilter = useCallback(
    (data: any) => {
      const updateIsRequired = data?.map((item: any) => ({ ...item, isRequired: true })); // "isRequired" always true
      const orderLicensesDetails: IOrderLicense[] = [...cellParams?.skillsetLicenseData];
      if (cellParams?.skillsetLicenseData?.length > 0) {
        const licenseDetailsIndex = orderLicensesDetails?.findIndex(
          updateIsRequired => updateIsRequired?.licenseId === cellParams?.param?.id,
        );

        if (licenseDetailsIndex !== -1 && cellParams?.selectedLicense) {
          const selectedLicenseDetail: IOrderLicense = {
            ...cellParams?.selectedLicense,
            licenseStateDetails: updateIsRequired,
            isError: { ...cellParams?.selectedLicense?.isError, state: updateIsRequired?.length > 0 ? false : true },
          };
          orderLicensesDetails[licenseDetailsIndex] = { ...selectedLicenseDetail };
        }
      }
      setValue(
        `skillSetGroup.groups[${cellParams?.groupIndex}].skillsets[${cellParams?.skillSetIndex}].collections[${CollectionType.LicensesIndex}].requirements`,
        orderLicensesDetails,
      );
    },
    [cellParams?.skillsetLicenseData, cellParams?.param?.id],
  );

  return (
    <>
      {!hideField && (
        <OrderLicenseTypeAheadDropdown
          filterName={t('order.license.stateDropdown')}
          options={states}
          isMultiSelect
          value={selectedValues}
          minWidth={'120px'}
          apply={onApplyFilter}
          customClass={classes.selectField}
          hasControlError={isError}
          controlErrorText={'required'}
          hideApply={cellParams?.hideApply}
          showDefaultIsRequired={false}
        />
      )}
    </>
  );
});

export const UnitStateRenderCell = (props: {
  param: GridCellParams;
  groupIndex: number;
  skillSetIndex: number;
  hideApply?: boolean;
}) => {
  const { getValues } = useFormContext();

  const skillsetLicenseData = getValues(
    `skillSetGroup.groups[${props.groupIndex}].skillsets[${props.skillSetIndex}].collections[${CollectionType.LicensesIndex}].requirements`,
  );

  const selectedLicense = skillsetLicenseData?.find(
    data => data.licenseId === props?.param?.id && data.compactStatus === CompactStatus.single,
  );

  return props.param?.id && skillsetLicenseData?.length && selectedLicense ? (
    <LicenseStateRenderer {...props} skillsetLicenseData={skillsetLicenseData} selectedLicense={selectedLicense} />
  ) : (
    <></>
  );
};
