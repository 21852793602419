import { useDecision } from '@optimizely/react-sdk';
import i18next from 'i18next';
import React from 'react';

export const useTranslationOverride = () => {
  const [translationOverrideDecision] = useDecision('ff_global_ui_translation_override');
  React.useEffect(() => {
    if (translationOverrideDecision.enabled) {
      const en: any | null | undefined = translationOverrideDecision.variables['en'];
      if (en && typeof en === 'object')
        i18next.addResourceBundle('en', 'translation', translationOverrideDecision.variables['en'], false, true);
    }
  }, [translationOverrideDecision]);
};
