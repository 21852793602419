import {  Divider, Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useState } from 'react';
import { SkillSetDialog } from './Components/SkillSetDialog';
import { SkillSetTree } from './Components/SkillSetTree';
import { SkillSetUnVerified } from './Components/SkillSetUnVerified';
import { SkillSetVerified } from './Components/SkillSetVerified';
import { SkillSetDragAndDrop } from './Components/SkillSetDragAndDrop';
import { ISkillsetGroup, skillsetGroups } from './Components/Helpers/SkillsetHelper';

const useStyles = makeStyles()((theme: Theme) =>
  ({
    root: {
      backgroundColor: '#ffff',
      margin: 0,
      padding: 0,
      width: '100% !important',
      overflow: 'hidden',
      height: 'fit-content',
    },
    skillTree: {
      backgroundColor: '#f5f5f5',
    },
    columnsContainer: {
      paddingLeft: '20px !important',
      paddingTop: '0px !important',
    }
  }));

export const SkillSetWrapper = props => {
  const { open, handleClose } = props;
  const [showConfirmation, setShowConfirmation] = useState<boolean>(true);
  const [columns, setColumns] = useState<ISkillsetGroup>(skillsetGroups);
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const handleToggle = value => {
    setShowConfirmation(value);
  };
  
  const handleExpanded = (expanded = false) => {
    setExpanded(expanded);
  };

  const { classes } = useStyles();
  return (
    <>
      <SkillSetDialog 
        open={open} 
        handleClose={handleClose} 
        showConfirmation={showConfirmation} 
        handleOnExpanded={handleExpanded}
        columns={columns}
      >
        <LayoutGrid container className={classes.root} xs={12}>
          <LayoutGridItem item xs={4}>
            <LayoutGrid container direction="row" className={classes.skillTree}>
              <SkillSetTree 
                handleClose={handleClose} 
                handleToggle={handleToggle} 
                expanded={expanded} 
                columns={columns} />
            </LayoutGrid>
          </LayoutGridItem>
          <LayoutGridItem item xs={8} className={classes.columnsContainer}>
            <SkillSetDragAndDrop columns={columns} setColumns={setColumns} handleToggle={handleToggle} />
          </LayoutGridItem>
        </LayoutGrid>
      </SkillSetDialog>
    </>
  );
};
