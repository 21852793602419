import { createAction } from '@reduxjs/toolkit';
import { DocSource } from './types';

export interface IContractDocWritePayload {
  placementId: number;
  templateText: string;
}

export interface IContractDocPublishPayload {
  placementId: number;
  contractId: string;
}

const getTemplates = createAction('GET_PLACEMENT_CANDIDATE_CONTRACT_TEMPLATES');
const getMergeTokensList = createAction('GET_PLACEMENT_CANDIDATE_CONTRACT_MERGE_TOKENS_LIST');
const generateDraft = createAction<{ placementId: number }>('GENERATE_PLACEMENT_CANDIDATE_CONTRACT_DRAFT');
const deleteContractDraft = createAction<{ placementId: number }>('DELETE_PLACEMENT_CANDIDATE_CONTRACT_DRAFT');
const deleteContractDoc = createAction<{ placementId: number; contractId: string }>(
  'DELETE_PLACEMENT_CANDIDATE_CONTRACT_DOC',
);
const previewContractDoc = createAction<IContractDocWritePayload>('PREVIEW_PLACEMENT_CANDIDATE_CONTRACT_DOC');
const createContractDoc = createAction<IContractDocWritePayload & { isDraftManualEdit: boolean }>(
  'CREATE_PLACEMENT_CANDIDATE_CONTRACT_DOC',
);
const saveDraftTemplate = createAction<IContractDocWritePayload>('SAVE_PLACEMENT_CANDIDATE_CONTRACT_DRAFT');
const publishContractDoc = createAction<IContractDocPublishPayload>('PUBLISH_PLACEMENT_CANDIDATE_CONTRACT_DOC');
const voidContractDoc = createAction<IContractDocPublishPayload>('VOID_PLACEMENT_CANDIDATE_CONTRACT_DOC');
const getPdfDocument = createAction<{ documentId: string; source: DocSource }>('GET_PLACEMENT_CANDIDATE_CONTRACT_PDF_DOC');

export const serviceActions = {
  getTemplates,
  getMergeTokensList,
  generateDraft,
  deleteContractDraft,
  deleteContractDoc,
  previewContractDoc,
  createContractDoc,
  saveDraftTemplate,
  publishContractDoc,
  voidContractDoc,
  getPdfDocument,
};
