import { CompactStatus } from '@AMIEWEB/Order/Store/Order.redux';
import { LastUpdatedBy } from '@AMIEWEB/Order/Store/helpers/rateCard/types';
import { SkillSetCollections } from 'app/enums/Common';
import { IIssuedBy, ITransformedIssuedBy } from 'app/models/Unit/CreateUnit';
import _ from 'lodash';

export const transformDataForPayload = (data, facilityDetails, editUnit, user, isEdit) => {
  const transformedData = {
    additionalRequiredQualifications: data.additionalRequiredQualifications,
    facilityId: facilityDetails.facilityId,
    facilityState: facilityDetails.facilityData.location.state,
    bestBets: data.bestBets.Description,
    bestBetsId: data.bestBets.ID,
    costCenter: data.costCenter,
    costCenterId: isEdit ? editUnit.costCenterId : null,
    createdByVersion: isEdit ? editUnit.createdByVersion : '2.0',
    currentUserId: user.userInfo?.employeeId ?? 0,
    experience: null,
    skillSetGroup: transformSkillSetGroupData(data.skillSetGroup),
    newGrad: data.newGrad,
    skillsets: null,
    externalJobDescription: formatFreeText(data.externalJobDescription),
    flexibleSchedule: data.flexibleSchedule,
    float: data.float,
    floor: data.floor,
    fyreIntegration: data.fyreIntegration,
    internalNotes: formatFreeText(data.internalNotes),
    lastUpdated: null,
    lastUpdatedBy: {
      employeeId: user.userInfo?.employeeId ?? 0,
    } as LastUpdatedBy,
    name: data.name,
    nonStandardRequirement: data.nonStandardRequirement ?? [],
    orders: isEdit ? editUnit.orders : 0,
    orderPriorities: data.orderPriorities,
    positionsAvailable: isEdit ? editUnit.positionsAvailable : 0,
    qualification: null,
    reference: {
      isRequired: data.reference.isRequired,
      numberOfReferenceRequired: data.reference.numberOfReferenceRequired ?? 0,
      referenceFromSupervisor: data.reference.referenceFromSupervisor,
      withinNumberOfMonths: data.reference.entireWorkHistory ? null : data.reference.withinNumberOfMonths,
      numberOfReferenceToRFO: data.reference.numberOfReferenceToRFO ?? 0,
      entireWorkHistory: data.reference.entireWorkHistory,
    },
    isReferenceChanged: isEdit ? checkIfReferenceChanged(data.reference, editUnit.reference) : true,
    emr: data.emr,
    standardizedRequirements: isEdit ? editUnit.standardizedRequirements : true,
    preferredQualifications: formatFreeText(data.preferredQualifications),
    requiredQualifications: data.requiredQualifications,
    status: data.status,
    statusId: data.statusId,
    travelExperience: data.travelExperience,
    unitId: isEdit ? editUnit.unitId : 0,
    unitSize: data.unitSize,
    virtualRating: data.virtualRating,
    submissionRequirement: data.submissionRequirement,
    unitContacts: data.unitContacts,
    linkedToFacility: 'N',
    firstDayInstructions: data.firstDayInstructions,
    dob: data.dob,
    ssn: data.ssn,
    notifyCandidate: data.notifyCandidate,
    rangeOfPatientsSeen: checkIfStringisEmpty(data.patientsSeen),
    rangeOfVisits: checkIfStringisEmpty(data.numberOfVisits),
    productivityPercentage: data.productivityPercentage,
    nurseRatio: transformRatioValue(data.nurse, data.patient),
    withinTheLastDays: data?.workHistoryGap?.withinTheLastDays,
    withinTheLastYears: data?.workHistoryGap?.entireWorkHistory ? null : data?.workHistoryGap?.withinTheLastYears,
    entireWorkHistory: data?.workHistoryGap?.entireWorkHistory,
  };
  return transformedData;
};

const checkIfReferenceChanged = (newReference, oldReference) => {
  const filteredOldReference = _.omit(oldReference, ['lastUpdatedByFirstName', 'lastUpdatedByLastName', 'lastUpdated']);
  return !_.isEqual(newReference, filteredOldReference);
};
const transformRatioValue = (nurse, patient) => {
  if (!nurse && !patient) {
    return null;
  } else {
    return `${nurse}:${checkIfStringisEmpty(patient)}`;
  }
};
const checkIfStringisEmpty = (value: string) => {
  if (value !== null) {
    return value.length === 0 ? null : value;
  }
  return null;
};

export const transformSkillSetGroupData = data => {
  const formattedValue = {
    operation: data?.operation ? data.operation : null,
    groups: data?.groups?.map((group, index) => {
      return {
        id: group?.id ? group?.id : index,
        operation: group?.operation ? group.operation : null,
        skillsets: group?.skillsets?.map((skill, index) => {
          return {
            collections:
              skill?.collections
                ?.map((collection, index) => {
                  return collection?.requirements?.length
                    ? collection.requirements[0]?.hasOwnProperty('certificates')
                      ? collection.requirements[0]?.certificates
                        ? {
                            id: index,
                            operation: collection?.operation ?? null,
                            requirements: getSeperatedCertSubCertItems(collection),
                            type: SkillSetCollections.Certifications,
                          }
                        : null
                      : {
                          id: 0,
                          type: SkillSetCollections.Licenses,
                          operation: collection?.operation ? collection.operation : null,
                          requirements: getTransformedLicenses(collection?.requirements),
                        }
                    : null;
                })
                .filter(Boolean) || [],
            id: skill?.id ? skill?.id : index,
            isPrimary: skill?.isPrimary,
            skillSet: {
              disciplineId: skill?.skillSet?.discipline?.object?.ID,
              discipline: skill?.skillSet?.discipline?.object?.Description,
              disciplineAbbr: skill?.skillSet?.discipline?.object?.Abbreviation,

              specialtyId: skill?.skillSet?.specialty?.object?.SpecialtyID,
              specialty: skill?.skillSet?.specialty?.label,
              specialtyAbbr: skill?.skillSet?.specialty?.object?.Description,

              subSpecialtyId: skill?.skillSet?.subSpecialty?.object?.SubSpecialtyID,
              subSpecialty: skill?.skillSet?.subSpecialty?.object?.Description,
              subSpecialtyAbbr: skill?.skillSet?.subSpecialty?.object?.Description,

              yearsOfExperience: skill?.skillSet?.yearsOfExperience,
              monthsOfExperience: skill?.skillSet?.monthsOfExperience,
              isRequired: skill?.skillSet?.isRequired,
              isNewGrad: skill?.skillSet?.isNewGrad,

              isSkillsCheckList: skill?.skillSet?.isSkillsCheckList ?? false,
              isClinicalFellowship: skill?.skillSet?.isClinicalFellowship ?? false,
            },
            travelExperience: {
              experienceTypeId: skill?.travelExperience?.experienceType?.object?.optionId,
              experienceTypeText: skill?.travelExperience?.experienceType?.object?.title,
              numberOfAssignments: skill?.travelExperience?.numberOfAssignments,
              yearsOfExperience: skill?.travelExperience?.yearsOfExperience,
              monthsOfExperience: skill?.travelExperience?.monthsOfExperience,
              isRequired: skill?.travelExperience?.isRequired,
              withinTwoYears: skill?.travelExperience?.withinTwoYears,
              isAMNTravel: skill?.travelExperience?.isAMNTravel,
            },
          };
        }),
      };
    }),
  };
  return formattedValue;
};

interface ICetificate {
  certificates: ICertificates | null;
  subCertificate: ISubCertificate[] | null;
  isRequired: boolean;
  documentAttachmentRequired: boolean;
  issuedBy: ITransformedIssuedBy[];
}

interface ICertificates {
  label: string;
  object: {
    abbreviation: string;
    category: string;
    certificationName: string;
    credentialName: string;
    id: number;
    requirementTypeID: number;
    subCategory: string;
    unitId: number;
  };
}
interface ISubCertificate {
  id: number;
  name: string;
  value: string;
}

export const addCertificateHelper = (data: ICetificate[], append) => {
  data.map((each, index) => {
    append({
      id: index,
      documentAttachmentRequired: each.documentAttachmentRequired,
      isRequired: each.isRequired,
      certificates: each.certificates,
      subCertificate: each.subCertificate,
      issuedBy: each.issuedBy,
    });
  });
};

export const getSeperatedCertSubCertItems = collection => {
  if (!collection?.requirements?.length) return [];
  const transformedCertifications = collection?.requirements?.reduce((accumulator, requirement, index) => {
    if (!requirement.certificates) {
      return accumulator;
    }

    accumulator.push({
      id: index,
      documentAttachmentRequired: requirement.documentAttachmentRequired,
      isRequired: requirement.isRequired,
      requirementTypeId: requirement.certificates.object?.requirementTypeID,
      requirementAbbreviation: requirement.certificates.object?.abbreviation,
      requirementDescription: requirement.certificates.object?.certificationName,
      issuedBy: requirement.issuedBy?.object?.abbr,
      issuedById: requirement.issuedBy?.object?.id,
      isMultiState: false,
      requirementState: {},
      subCertificate:
        Array.isArray(requirement.subCertificate) && requirement.subCertificate?.length
          ? requirement.subCertificate.map(subCertificate => ({
              subCertificationId: subCertificate.subCertificate.id,
              subCertificateAbbreviation: subCertificate.subCertificate.value,
              subCertificateName: subCertificate.subCertificate.name,
              documentAttachmentRequired: subCertificate.documentAttachmentRequired,
              isRequired: subCertificate.isRequired,
            }))
          : {},
    });

    return accumulator;
  }, []);

  let idCounter = 0;

  const seperatedCertSubcertData = transformedCertifications?.flatMap(requirement => {
    if (requirement?.subCertificate?.length) {
      // Create new requirements for each subCertificate
      return requirement.subCertificate.map(subCertificate => ({
        ...requirement,
        id: idCounter++,
        documentAttachmentRequired: requirement.documentAttachmentRequired,
        isRequired: requirement.isRequired,
        subCertificate: subCertificate,
      }));
    }
    return { ...requirement, id: idCounter++ };
  });

  return seperatedCertSubcertData;
};

export const getTransformedLicenses = (newLicenses: any) => {
  let idCounter = 0;

  const transformedLicenses = newLicenses?.flatMap(license => {
    const licenseItem = {
      requirementTypeId: license?.licenseId,
      requirementAbbreviation: license?.licenseAbbreviation,
      requirementDescription: license?.licenseDescription,
      isMultiState:
        license.compactStatus === CompactStatus.single
          ? false
          : license.compactStatus === CompactStatus.compact
          ? true
          : null,
      requirementState: {
        state: null,
        stateAbbreviation: null,
      },
      documentAttachmentRequired: false,
      isRequired: false,
      subCertificate: {},
    };

    if (license?.licenseStateDetails?.length) {
      // Create new licenses for each state
      return license.licenseStateDetails.map(stateDetails => ({
        ...licenseItem,
        id: idCounter++,
        requirementState: {
          state: stateDetails?.state?.name ?? null,
          stateAbbreviation: stateDetails?.state?.value ?? null,
        },
        documentAttachmentRequired: stateDetails?.documentAttachmentRequired ?? false,
        isRequired: stateDetails?.isRequired ?? false,
      }));
    }
    return { ...licenseItem, id: idCounter++ };
  });

  return transformedLicenses;
};

export const transformIssuedByLookupData = (data: IIssuedBy[]) => {
  const transformedData = data.map(value => {
    return {
      label: value.issuingBodyFullDescription,
      object: {
        id: value.issuingBodyReqOptionTypeId,
        abbr: value.issuingBodyDescription,
        fullDescription: value.issuingBodyFullDescription,
        certificationRequirementTypeId: value.certificationRequirementTypeId,
        certificationRequirementDescription: value.certificationRequirementDescription,
        certificationRequirementFullDescription: value.certificationRequirementFullDescription,
      },
    };
  });
  transformedData.sort((a, b) => a?.label?.localeCompare(b?.label));
  return transformedData;
};

export const transformLicenseData = data => {
  const transformedData = data.map(value => {
    return {
      abbreviation: value.abbreviation,
      credentialName: value.requirementType,
      compactStates: value.compactStates,
      compact: value.compact,
      requirementTypeID: value.requirementTypeId,
    };
  });

  return transformedData;
};

export const formatFreeText = value => {
  return value?.replace(/([^\r])\n/g, '$1\r\n');
};
