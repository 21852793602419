import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialPreferenceState, initialFormState, IPreference } from './Preference.redux';

const selectDomain = (state: RootState) =>
  state?.preferences ||
  ({
    preferenceAPIresponse: initialPreferenceState,
    formData: initialFormState,
    preferenceSaveEvent: false,
    recruiterEnterdDataExists: false,
    miscellaneousSpecs: {
      disciplineOpts: [],
      specialtyOpts: [],
    },
  } as IPreference);

export const selectJobPreferences = createSelector([selectDomain], preferences => preferences);

export const selectApiResponse = createSelector([selectDomain], preferences => preferences.preferenceAPIresponse);
export const selectSkillSetsChosen = createSelector([selectDomain], preference => preference.formData.skillSets);

export const selectClientSettingsChosen = createSelector(
  [selectDomain],
  preference => preference.formData.clientSettings,
);

export const selectLocationsChosen = createSelector([selectDomain], preference => preference.formData.locations);

export const selectJobTypeCheckboxes = createSelector([selectDomain], preference => preference.formData.placementTypes);

export const selectJobExperienceCheckboxes = createSelector(
  [selectDomain],
  preference => preference.formData.jobExperiences,
);

export const selectShiftTimeCheckboxes = createSelector([selectDomain], preferences => preferences.formData.shiftTimes);

export const selectShiftLengthCheckboxes = createSelector(
  [selectDomain],
  preferences => preferences.formData.shiftLengths,
);

export const selectPlacementLengthCheckboxes = createSelector(
  [selectDomain],
  preferences => preferences.formData.placementLengths,
);

export const selectFacilityTypeCheckboxes = createSelector(
  [selectDomain],
  preferences => preferences.formData.facilityTypes,
);

export const selectTimeOffRequestsChosen = createSelector(
  [selectDomain],
  preferences => preferences.formData.timeOffRequests,
);

export const selectMiscellaneousFields = createSelector(
  [selectDomain],
  preferences => preferences.formData.miscellaneousFields,
);

export const selectSaveEvent = createSelector([selectDomain], preferences => preferences.preferenceSaveEvent);
export const selectRecruiterEnterdDataExists = createSelector(
  [selectDomain],
  preferences => preferences.recruiterEnterdDataExists,
);

export const selectMiscellaneousData = createSelector([selectDomain], preference => preference.miscellaneousSpecs);
