import { makeStyles } from 'tss-react/mui';
import {
  selectFilters,
  selectGridFiltersExpanded,
} from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import { defaultFilterName, SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import {
  fetchDisciplineAndSpecialty,
  getCitiesAndZipsByStates,
  getSearchFilterOptions,
} from 'app/services/SharedServices/SharedServices';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { CustomFilter } from '../../../ComponentLibrary/Filter';
import { getMultiSelectOptions } from '../helper';
import { CandidatesFilter, getAllCitiesAndZips, getStatesArray, getTagsOpts } from './FilterUtils';
import { IStateCityZip } from 'app/models/Candidate/IStateCityZip';
import { removeSessionValue, SessionKey, StorageType } from 'utils/customHooks/sessionStorage/sessionHelpers';
import { RootState } from 'types';
import _ from 'lodash';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { Collapse, Grid } from 'amn-ui-core';
import { SearchPageIcons } from 'app/ComponentLibrary/Filter/SearchPageIcons';
import { gridStateActions } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import tagsData from 'app/assets/jsons/Tags.json';
import { ff_candidate_api_candidate_search_index } from 'app/constants/FeatureFlags/Candidate/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { candidateSearchFilterName } from './SearchUtils';
import { initialCandidateAggregateSearch } from 'app/ComponentLibrary/Filter/CandidateSearch';
import { initialCandidateSkillSetFilter } from 'app/ComponentLibrary/Filter/CandidateSkillset';
import { updateTreeNodes } from '@AMIEWEB/Common/TreeView/TreeViewHelpers';

const useStyles = makeStyles()({
  // eslint-disable-next-line tss-unused-classes/unused-classes
  candidateStatus: {
    width: 140,
  },
  fillterCollapse: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '3px',
  },
  collapse: {
    paddingTop: '4px',
  },
});

export const CandidateSearchFilters = ({ defaultFilterValues }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { reset, setValue } = useFormContext();
  const gridStateFilters = useSelector(selectFilters);
  const allCitiesandZipsData = useRef<IStateCityZip[]>();
  const [newSearchIndex] = useDecision(ff_candidate_api_candidate_search_index);
  const gridSpecs = useRef(
    new CandidatesFilter(
      {
        recruiterOpts: [],
        brandOpts: [],
        candidateStatusOpts: [],
        disciplineOpts: [],
        specialtyOpts: [],
        statesOpts: [],
        cityOptions: [],
        zipOptions: [],
        licensesOptions: [],
        certificationOptions: [],
        certificationIssuers: [],
        tagsOpts: [],
      },
      t,
      classes,
      gridStateFilters,
      newSearchIndex.enabled,
    ),
  );
  const [fields, setFields] = useState(gridSpecs.current.getCandidateFilterSpecs());
  const filterPreferences = useSelector((state: RootState) => {
    return state.userPreferenceData.userGridPreferences.find(item => item.id === 'GlobalSearchGrid-candidate');
  });

  const fetchFilterSpecs = async () => {
    getSearchFilterOptions('brands', true).then(res => {
      gridSpecs.current.filterSpecs.brandOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getCandidateFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('candidatestatuses', true).then(res => {
      gridSpecs.current.filterSpecs.candidateStatusOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getCandidateFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('disciplines', true).then(res => {
      gridSpecs.current.filterSpecs.disciplineOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getCandidateFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('specialties', true).then(res => {
      gridSpecs.current.filterSpecs.specialtyOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getCandidateFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('licenseTypes', true).then(res => {
      gridSpecs.current.filterSpecs.licensesOptions = res.map((license, id) => ({ id: id, ...license }));
      const fieldsSchema = gridSpecs.current.getCandidateFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('certificationTypes', true).then(res => {
      gridSpecs.current.filterSpecs.certificationOptions = res.map((certificate, id) => ({ id: id, ...certificate }));
      const fieldsSchema = gridSpecs.current.getCandidateFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('certificationIssuers', true).then(res => {
      gridSpecs.current.filterSpecs.certificationIssuers = res.map((certificateIssuer, id) => ({
        ...certificateIssuer,
        id: id,
        value: certificateIssuer.name,
      }));
      const fieldsSchema = gridSpecs.current.getCandidateFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('states', true).then(async res => {
      gridSpecs.current.filterSpecs.statesOpts = await getMultiSelectOptions(res || [], { withoutAllOption: true });
      const statesArray = getStatesArray(res || []);
      allCitiesandZipsData.current = (await getCitiesAndZipsByStates({
        states: [statesArray?.[0]],
        cities: undefined,
      })) as IStateCityZip[];
      if (!allCitiesandZipsData.current) return;
      const AllCitiesAndZips = getAllCitiesAndZips(allCitiesandZipsData.current);
      gridSpecs.current.filterSpecs.cityOptions = AllCitiesAndZips.cities;
      gridSpecs.current.filterSpecs.zipOptions = AllCitiesAndZips.zips;
      const fieldsSchema = gridSpecs.current.getCandidateFilterSpecs();
      setFields(fieldsSchema);
    });
    gridSpecs.current.filterSpecs.tagsOpts = getTagsOpts(tagsData || []);
    const fieldsSchema = gridSpecs.current.getCandidateFilterSpecs();
    setFields(fieldsSchema);
  };

  const updateGridState = useCallback(
    async (selectedStates: string[], selectedCities: string[], gridStateFilters: any) => {
      if (!gridStateFilters || !allCitiesandZipsData.current) return;
      let filteredZips: IStateCityZip[];
      let filteredCities: IStateCityZip[];
      if (!selectedStates.length) filteredCities = allCitiesandZipsData.current;
      else { 
        filteredCities = await getCitiesAndZipsByStates({
            states: selectedStates,
            cities: [],
          }) as IStateCityZip[];
      }
 
      if (!selectedCities.length) filteredZips = filteredCities;
      else {
        filteredZips = await getCitiesAndZipsByStates({
            states: selectedStates,
            cities: selectedCities,
          }) as IStateCityZip[];
      }
 
      gridSpecs.current.filterSpecs.cityOptions = getAllCitiesAndZips(filteredCities).cities;
      gridSpecs.current.filterSpecs.zipOptions = getAllCitiesAndZips(filteredZips).zips;
      gridSpecs.current.gridStateFilters = gridStateFilters;
      const fieldsSchema = gridSpecs.current.getCandidateFilterSpecs();
      setFields(fieldsSchema);
    },
    [],
  );

  const getDisciplineAndSpecialty = async savedDisciplineIds => {
    let preferenceValues = null;
    let newSkillSetFilter = [];

    const abortController = new AbortController();
    const disciplineAndSpecialty = (await fetchDisciplineAndSpecialty('', abortController.signal, true)) as any;

    if (disciplineAndSpecialty?.treeStructure?.length > 0) {
      const skillSetTree = disciplineAndSpecialty?.treeStructure;
      const savedSpecialtyIds = gridStateFilters?.filters?.specialtyIds as any[];

      //If Specialty array is not empty, then pick only the valid and active values of Discipline and Specialty combination.
      if (savedSpecialtyIds?.length > 0) {
        for (const disciplineId of savedDisciplineIds) {
          let specialtyChildren = [];
          let node = skillSetTree?.find(t => t.value == disciplineId?.value);
          if (node) {
            for (const specialtyId of savedSpecialtyIds) {
              const specialtyNode = node?.children?.find(
                child => child?.value == `${node?.value}-${specialtyId?.value}`,
              );

              //If Specialty is not present under Discipline, then don't pick anything at all.
              if (specialtyNode) {
                specialtyNode.userChecked = true;
                specialtyChildren = [...specialtyChildren, { ...specialtyNode, children: [] }];
              }
            }
          }

          if (specialtyChildren?.length > 0) {
            let disciplineNode = {
              name: node?.name,
              value: node?.value,
              userChecked: true,
              children: specialtyChildren,
            };
            newSkillSetFilter = [...newSkillSetFilter, disciplineNode];
          }
        }
      }
      //If Specialty array is empty, then pick only the valid and active values of Discipline.
      else {
        for (const disciplineId of savedDisciplineIds) {
          let node = skillSetTree?.find(t => t.value == disciplineId?.value);
          if (node) {
            node.userChecked = true;
            newSkillSetFilter = [...newSkillSetFilter, { ...node, children: [] }];
          }
        }
      }
    }

    preferenceValues = _.cloneDeep(filterPreferences)?.value;

    const filterSet = preferenceValues?.filterAttributes?.filterSet;
    const selectedFilter = preferenceValues?.selectedFilter;
    const selectedFilterSet = filterSet.find(filter => filter.name === selectedFilter?.name).value;

    if (newSkillSetFilter?.length > 0) {
      newSkillSetFilter[0].filterName = 'skillsetFilter';
      const skillSets = {
        allowableMismatch: false,
        isVerifiedOnly: false,
        operation: 'Or',
        skillsets: updateTreeNodes(newSkillSetFilter),
      };
      selectedFilterSet.find(arr => arr[0] === 'skillsetFilter')[1] = skillSets;
    }
    selectedFilterSet.find(arr => arr[0] === 'disciplineIds')[1] = [];
    selectedFilterSet.find(arr => arr[0] === 'specialtyIds')[1] = [];

    let pref = preferenceValues?.filterAttributes?.filterSet?.find(filter => filter.name === selectedFilter?.name);
    if (pref?.value) {
      pref = selectedFilterSet;
    }

    preferenceValues.selectedFilter.value = selectedFilterSet;

    return preferenceValues;
  };

  useEffect(() => {
    const fetchData = async () => {
      let preferenceValues = null;

      if (gridStateFilters && gridStateFilters.filters) {
        const savedDisciplineIds = gridStateFilters.filters.disciplineIds as any[];

        if (savedDisciplineIds?.length > 0) {
          preferenceValues = await getDisciplineAndSpecialty(savedDisciplineIds);
        }
      }

      if (preferenceValues !== null && preferenceValues !== undefined && Object.keys(preferenceValues).length !== 0) {
        dispatch(
          userDevicePreferenceActions.saveUserGridPreferenceAction({
            id: 'GlobalSearchGrid-candidate',
            value: preferenceValues,
          }),
        );
      }
    };

    fetchData();
  }, [gridStateFilters]);

  useEffect(() => {
    if (!gridStateFilters || !allCitiesandZipsData.current) return;
    const selectedStates = (
      !(gridStateFilters?.filters?.states as any[])?.length ? [] : gridStateFilters?.filters?.states
    ) as any[];

    const selectedCities = (
      !(gridStateFilters?.filters?.city as any[])?.length ? [] : gridStateFilters?.filters?.city
    ) as any[];
    updateGridState(
      selectedStates.map(x => x.name),
      selectedCities.map(x => x.name),
      gridStateFilters,
    );
  }, [gridStateFilters, updateGridState]);

  const resetFilter = () => {
    const foundDefaultIndex = (filterPreferences.value?.filterAttributes?.filterSet || [])?.find(
      item => item.name === defaultFilterName || item.isDefault,
    );
    const filterPrefClone = _.cloneDeep(filterPreferences);

    if (!foundDefaultIndex) {
      filterPrefClone.value.selectedFilter = null;
    } else {
      // Default filter found, select it
      filterPrefClone.value.selectedFilter = foundDefaultIndex;
    }
    removeSessionValue(SessionKey[`GlobalSearchGrid-candidate`], StorageType.sessionStorage);
    dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(filterPrefClone));
    setValue('license', null);
    setValue('certification', null);
    setValue('workHistory', null);
    setValue('candidateTags', []);
    setValue('skillsetFilter', initialCandidateSkillSetFilter);
    setValue(candidateSearchFilterName.candidateAgggreggate, initialCandidateAggregateSearch);
    reset(!isNullOrUndefined(foundDefaultIndex) ? Object.fromEntries(foundDefaultIndex?.value) : null);

    // NOTE: This code will reset the grid back to what it was when you first arrived on the page,
    //       regardless of which filter or temporary filter is selected
    //
    // const filtersFromFirstLoad = getSessionValue(SessionKey.globalSearchReset, StorageType.sessionStorage);
    // dispatch(
    //   gridStateActions.setFilterAttributes({
    //     ...gridState.filterAttributes,
    //     filterObject: filtersFromFirstLoad,
    //     fetchSwitch: true,
    //   }),
    // );
    // reset(Object.fromEntries(filtersFromFirstLoad));
  };

  const filtersExpanded = useSelector(selectGridFiltersExpanded);
  const handleChange = () => {
    dispatch(gridStateActions.setFiltersExpanded(!filtersExpanded));
  };

  useEffect(() => {
    trackPromise(fetchFilterSpecs(), 'gloabl-search-call');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={11}>
        <Collapse in={filtersExpanded} timeout={200} collapsedSize={45} className={classes.collapse}>
          <CustomFilter
            fields={fields}
            justifyContent={'flex-start'}
            version2
            resetBtn
            analytics={{
              caller: t('search.globalSearch.globalSearchFilter'),
              onApply: true,
              properties: {
                category: SearchType.candidate,
              },
            }}
          />
        </Collapse>
      </Grid>
      <Grid item xs={1} className={classes.fillterCollapse}>
        <SearchPageIcons
          resetlbutton
          funnelButton
          checked={filtersExpanded}
          onResetForm={() => resetFilter()}
          onAdvancedFilterClick={() => handleChange()}
        />
      </Grid>
    </Grid>
  );
};
