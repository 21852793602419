import { FormControl, Grid, InputLabel, MenuItem, Select, Tooltip } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { UserDeviceProps } from 'app/models/Notification/Notification';
import {
  userDevicePreferenceActions,
  userDevicePreferenceKey,
  userDevicePreferenceReducer,
} from 'oidc/UserDevicePreference/userPreference.redux';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/redux-injectors';

const useStyles = makeStyles<void, 'selected'>()((theme, _params, classes) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    overflowY: 'hidden',
    overflowX: 'hidden',
    width: '100%',
    outline: 'none',
    paddingBottom: '1%',
  },
  selection: {
    marginTop: '3%',
    marginBottom: '5%',
    background: '#FFFFFF',
    border: '1px solid #777777',
    borderRadius: '5px',
  },
  inputLabel: {
    color: '#55555',
    '&.Mui-focused': {
      color: '#55555',
    },
    '&:focus': {
      color: '#55555 !important',
    },
  },
  select: {
    background: '#fff',
    borderRadius: '5px',
    [`&.${classes.selected}`]: {
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
    '&:hover': {
      background: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
      borderRadius: '5px',
      color: '#333',
    },
    '&.Mui-focused': {
      color: '#333',
    },
  },
  tooltipArrow: {
    color: '#fff',
    '&::before': {
      border: '1px solid #555555',
    },
  },
  tooltipBackground: {
    backgroundColor: '#fff',
    color: '#555555',
    border: '1px solid #555555',
    fontSize: '12px',
  },
}));

const UserDeviceSelection = () => {
  const { t } = useTranslation();
  const userDevice = useSelector(selectUserPreference);
  useInjectReducer({ key: userDevicePreferenceKey, reducer: userDevicePreferenceReducer });

  const dispatch = useDispatch();
  const { classes } = useStyles();
  const [userDeviceLine, setUserDeviceLine] = useState<UserDeviceProps>({
    selectedDevice: userDevice?.current?.device ?? '',
    deviceList: userDevice?.deviceList ?? [],
    selectedLine: userDevice?.current?.line ?? '',
    deviceLine: userDevice?.deviceLine ?? [],
    isDefaultDeviceAndLine: false,
  });

  useEffect(() => {
    setUserDeviceLine(prevState => ({
      ...prevState,
      selectedDevice: userDevice?.current?.device ?? '',
      deviceList: userDevice?.deviceList ?? [],
      selectedLine: userDevice?.current?.line ?? '',
      deviceLine: userDevice?.deviceLine ?? [],
      isDefaultDeviceAndLine: false,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDevice]);

  const handleUserDeviceLine = (event: React.ChangeEvent<any>, key: string) => {
    if (key === 'device') {
      setUserDeviceLine(prevState => ({ ...prevState, selectedDevice: event?.target?.value }));
      const device = userDeviceLine?.deviceList?.find(element => {
        return element?.deviceName === event?.target?.value;
      });
      dispatch(
        userDevicePreferenceActions.setUserPreference({
          ...userDevice,
          current: {
            device: event?.target?.value,
          },
          deviceLine: device ? device.lines : [],
        }),
      );
      setUserDeviceLine(prevState => ({ ...prevState, deviceLine: device?.lines ?? [] }));
    }
    if (key === 'line') {
      setUserDeviceLine(prevState => ({ ...prevState, selectedLine: event?.target?.value }));
      dispatch(
        userDevicePreferenceActions.setUserPreference({
          ...userDevice,
          current: {
            device: userDevice.current?.device,
            line: event?.target?.value,
          },
        }),
      );
    }
  };

  const DeviceSelection = () => {
    return (
      <div className={classes.paper}>
        <Grid>
          <Grid item className={classes.selection}>
            <FormControl fullWidth variant="filled" disabled={userDeviceLine?.deviceList?.length === 0}>
              <InputLabel className={classes.inputLabel} id="device-label-id">
                {t('notification.selectDevice')}
              </InputLabel>
              <Tooltip
                arrow
                classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
                title={
                  userDeviceLine?.deviceList?.length === 0 || userDeviceLine?.deviceLine?.length === 0
                    ? `No Device found for the user`
                    : ''
                }
              >
                <Select
                  disableUnderline
                  classes={{ root: classes.select }}
                  labelId="device-label-id"
                  disabled={userDeviceLine?.deviceList?.length === 0}
                  label={t('notification.selectDevice')}
                  value={userDeviceLine.selectedDevice}
                  onChange={event => handleUserDeviceLine(event, 'device')}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {userDeviceLine?.deviceList?.map(device => (
                    <MenuItem key={device.deviceName} value={device.deviceName}>
                      {device.deviceName}
                    </MenuItem>
                  ))}
                </Select>
              </Tooltip>
            </FormControl>
          </Grid>
          <Grid item className={classes.selection}>
            <FormControl fullWidth variant="filled">
              <InputLabel className={classes.inputLabel} id="device-line-id">
                {t('notification.selectLine')}
              </InputLabel>
              <Select
                classes={{ root: classes.select }}
                disableUnderline
                disabled={userDeviceLine?.deviceList?.length === 0}
                labelId="device-line-id"
                label={t('notification.selectLine')}
                value={userDeviceLine.selectedLine}
                onChange={event => handleUserDeviceLine(event, 'line')}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {userDeviceLine?.deviceLine?.map(line => (
                  <MenuItem key={line} value={line}>
                    {line}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    );
  };
  return <>{DeviceSelection()}</>;
};

export default UserDeviceSelection;
