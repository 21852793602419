import React, { memo } from 'react';
import { orderDataActions } from '@AMIEWEB/Order/Store/Order.redux';
import { useDispatch } from 'react-redux';
import { GridCellParams } from '@mui/x-data-grid';
import { Link } from 'amn-ui-core';

const OrderBonusIdLinkRenderer = memo(function OrderBonusIdRenderer(props: {
  gridCellParams: GridCellParams;
  isPricingDesk: boolean;
}) {
  const dispatch = useDispatch();
  const { gridCellParams, isPricingDesk } = props;
  const bonusId = gridCellParams?.value;
  const handleLink = () => {
    const orderId = gridCellParams?.row?.orderId;
    if (isPricingDesk) {
      dispatch(orderDataActions.getOrderBonusList(orderId));
      dispatch(
        orderDataActions.setEditOrderBonusModal({
          isEdit: true,
          bonusId: bonusId,
          rowData: null,
        }),
      );
    } else {
      dispatch(
        orderDataActions.setEditOrderBonusModal({
          isEdit: true,
          bonusId: bonusId,
          rowData: gridCellParams?.row,
        }),
      );
    }
  };
  return bonusId && !isNaN(bonusId) && <Link onClick={() => handleLink()}>{bonusId}</Link>;
});

export const XGridBonusIdCell = (props: { gridCellParams: GridCellParams; isPricingDesk: boolean }) => {
  return <OrderBonusIdLinkRenderer {...props} />;
};
