import React from 'react';
import { Divider, Typography } from '@mui/material';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import { HeadingType, IgnoreHeadingType } from '../Config/constants';
import { RatingPanel } from './RatingPanel';

/**
 *  Functional component which renders dynamic field and its values
 */
export const SkillChecklistFields = skillChecklistSection => {
  const skillChecklistFields = skillChecklistSection.skillChecklistFields;
  const heading = skillChecklistFields?.find(item => item.typeId === HeadingType || item.typeId === IgnoreHeadingType);

  // Filter to new array that will have hasAnswer as true
  const skillChecklistCollection = skillChecklistFields?.filter(i => i.hasAnswer);

  // Split the array into two sections by sequence id
  const middleIndex = Math.ceil(skillChecklistCollection.length / 2);
  const firstHalf = skillChecklistCollection.slice(0, middleIndex);
  const secondHalf =
    skillChecklistCollection.length === 1
      ? []
      : skillChecklistCollection.length % 2 === 0
        ? skillChecklistCollection.slice(-middleIndex)
        : skillChecklistCollection.slice(-(middleIndex - 1));

  return (
    <LayoutGridItem container item direction="column">
      <LayoutGridItem item>
        <Typography variant="subtitle1" sx={{ fontWeight: '500 !important', color: '#1B3F69' }}>
          {heading?.text}
        </Typography>
      </LayoutGridItem>

      {/* iterate through sub section fields */}
      {/** remove fixed height */}
      <LayoutGridItem container item direction="row" pb="12px">
        {/* height="102px"> */}
        <LayoutGridItem item xs={5}>
          {firstHalf?.map((currentItem, index: number) => (
            <RatingPanel currentItem={currentItem} key={index} />
          ))}
        </LayoutGridItem>
        <LayoutGridItem item xs={1} mr={'12px'}>
          <Divider orientation="vertical" />
        </LayoutGridItem>
        <LayoutGridItem item xs={5}>
          {secondHalf?.map((currentItem, index: number) => (
            <RatingPanel currentItem={currentItem} key={index} />
          ))}
        </LayoutGridItem>
      </LayoutGridItem>
      <Divider />
    </LayoutGridItem>
  );
};
