import { missingField } from "app/constants";
import { Status } from "app/enums/Facility";
import { customSwitch } from "utils/customSwitchCase/customSwitch";

export const getRefactoredData = (reduxArray = []) => reduxArray.map(item => ({
    createdAt: item?.createdAt,
    facilityId: item?.createdAt ? item?.createdAt.match(/\d+/)[0] : missingField,
    createdBy: item?.createdBy,
    dateCreated: item?.dateCreated,
    dateLastUpdated: item?.dateLastUpdated,
    disciplineId: item?.disciplineId,
    effectiveStartDate: item?.effectiveStartDate,
    effectiveEndDate: item?.effectiveEndDate,
    lastUpdatedBy: item?.lastUpdatedBy,
    offContractId: item?.offContractId,
    rateElementId: item?.rateElementId,
    rateElementNameId: item?.type,
    reason: item?.reason,
    shift: item?.shift,
    shiftId: item?.shiftId,
    specialtyId: item?.specialtyId,
    statusId: item?.statusId,
    subSpecialtyId: item?.subSpecialtyId,
    isOcbr: item?.isOcbr,
    //@ts-ignore
    value: !item?.billRate?.isRange ? item?.billRate?.value : item?.billRate?.value?.min,
    //@ts-ignore
    valueMax: item?.billRate?.isRange && item?.billRate?.value?.max ? item?.billRate?.value?.max : null,
    documentIds: item?.documentIds
  }));



  export const getStatus=customSwitch(
    {
      [1]:Status.Pending,
      [2]:Status.Rejected,
      [3]:Status.PendingApproval,
      [4]:Status.Approved,
      [5]:Status.Expired,
      [6]:Status.Void,
      [7]:Status.Unsaved
  
    }
  )('')