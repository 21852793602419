import { gridPageSelector, useGridSelector, gridPageSizeSelector } from '@mui/x-data-grid-pro';
import { IPaginationProps, Pagination } from 'app/ComponentLibrary/Pagination/Pagination';
import React from 'react';
import { materialUiXGrid } from 'amn-ui-core';

export interface XGridPaginationProps extends Pick<IPaginationProps, 'hideResultsPerPageSelection' | 'hidePageCount'> {
  loading?: boolean;
}

interface _XGridPaginationProps {
  paginationProps?: XGridPaginationProps;
  defaultRowsPerPage?: number;
  rowsPerPageOptions?: number[];
  loading?: boolean;
}

export const XGridPagination = (props: _XGridPaginationProps) => {
  const apiRef = materialUiXGrid.useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

  return (
    <Pagination
      isLoading={props.loading ?? false}
      handleResultsPerPageChange={(value, _page) => {
        apiRef?.current?.setPageSize(value);
        apiRef?.current?.setPage(_page - 1);
      }}
      handlePageChange={(value, _) => {
        apiRef?.current?.setPage(value - 1);
      }}
      pageOptions={props?.rowsPerPageOptions}
      pagination={{
        page: (page || 0) + 1,
        resultsPerPage: pageSize || 1,
        totalResults: apiRef.current?.state?.pagination?.rowCount || 0,
      }}
      {...props.paginationProps}
    />
  );
};
