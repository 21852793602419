import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { useWatch } from 'react-hook-form';
import { useEditableRequirement } from './EditRequirementsHelper';
import { DepartureAirportCheck } from './EditablePassFailLogic';

export const useEditableRequirementDepartureAirport = () => {
  // DEPARTURE AIRPORT
  const departureAirport: any = useWatch({ name: 'departureAirport' });
  const transportation: any = useWatch({ name: 'transportationChosen' });

  useEditableRequirement({
    data: departureAirport?.object,
    secondaryData: transportation?.transportation,
    requirementId: IPlacementRequirementKey.departureAirport,
    requirementKey: 'departureAirport',
    requirementCheck: DepartureAirportCheck,
    shouldAddFailedRequirement: transportation?.transportation !== 'Unknown',
    failRequirement: {
      id: IPlacementRequirementKey.departureAirport,
      message: 'Departure Airport: Missing',
      tooltip: {
        message: 'Departure Airport is required',
      },
    },
  });
};
