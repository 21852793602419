import { Grid, Typography } from 'amn-ui-core';
import { XGrid } from 'app/components/Common/XGrid/XGrid';
import { makeStyles } from 'tss-react/mui';
import React from 'react';

// .react-grid-Cell {
//   border: none;
// }

const expandedDetailPanelStyles = makeStyles()({
  root: {
    padding: '20px',
  },
  gridItem: {
    paddingLeft: '30px',
    paddingBottom: '10px',
    width: '100%',
  },
  expandedGrid: {
    //width: '31rem',
    border: 'none',
    fontSize: '14px',
    overflowX: 'scroll',
    '& .MuiDataGrid-row': {
      borderBottom: '1px solid #D5D5D5',
    },
    '& .MuiDataGrid-main': {
      minHeight: '110px',
    },
  },
});

export const ExpandedDetailPanel = (props) => {
  const { classes } = expandedDetailPanelStyles();
  return (
    <Grid container direction="column" alignItems={'center'} className={classes.root}>
      <Grid item className={classes.gridItem}>
        <XGrid
          title={props.title}
          apiRef={props.apiRef}
          rows={props.rows || []}
          editMode='row'
          columns={props.columns || []}
          getRowId={model => model.rowID || props.rowID}
          experimentalFeatures={{ columnGrouping: true, newEditingApi: true }}
          autoHeight={true}
          rowHeight={60}
          headerHeight={70}
          components={{
            Toolbar: null,
          }}
          className={classes.expandedGrid}
          pagination={false}
          hideFooter={true}
          disableColumnSelector
          disableMultipleSelection={true}
          disableSelectionOnClick={true}
          processRowUpdate={props.processRowUpdate}
          onProcessRowUpdateError={props.handleProcessRowUpdateError}
        />
      </Grid>
      {props.secondGridTitle && (
        <Grid item className={classes.gridItem}>
          <Typography variant="subtitle1" style={{ paddingBottom: '7px', fontWeight: 500 }}>
            {props.secondGridTitle}
          </Typography>
        </Grid>
      )}
      {(props.secondGridTitle && props.secondGridRows.length) ? (
        <Grid item className={classes.gridItem}>
          <XGrid
            title={''}
            rows={props.secondGridRows || []}
            columns={props.secondGridColumns || []}
            getRowId={model => model.rowID}
            autoHeight={true}
            rowHeight={60}
            headerHeight={70}
            components={{
              Toolbar: null,
            }}
            className={classes.expandedGrid}
            hideFooter={true}
            disableColumnSelector
            disableMultipleSelection={true}
            disableSelectionOnClick={true}
          />
        </Grid>
      ) : (
        <Grid item className={classes.gridItem}>
          <Typography variant="subtitle2" style={{ paddingBottom: '7px', fontWeight: 400 }}>
            {props.dataUnavailableMessage}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}