import { Collapse, Box, Container, Grid, Typography } from 'amn-ui-core';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
  },
  title: {
    fontWeight: 500,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    borderBottom: '1.5px solid #d9d9d9',
  },
  titleWrapper: {
    padding: '12px 0',
  },
  collapse: {
    width: '100%',
  },
}));

export const Expandable = ({ title, disabled = false, expanded = false, ...props }) => {
  const { classes, cx } = useStyles();
  const [_expanded, _setExpanded] = React.useState<boolean>(!disabled && expanded);

  React.useEffect(() => {
    _setExpanded(expanded);
  }, [expanded]);

  const titleRender = React.useMemo(
    () => (
      <div className={classes.titleContainer}>
        <div className={classes.title}>{title}</div>
        <div>{!disabled && <Box>{_expanded ? <ExpandLess /> : <ExpandMore />}</Box>}</div>
      </div>
    ),
    [disabled, title, _expanded],
  );

  return (
    <Container disableGutters classes={{ root: classes.container }}>
      <Grid container>
        <Grid
          item
          xs={12}
          onClick={
            !disabled
              ? () => {
                  _setExpanded(prev => !prev);
                }
              : undefined
          }
          classes={{ root: cx(classes.titleWrapper, { [props.titleClass]: !!props.titleClass }) }}
        >
          {titleRender}
        </Grid>
        <Grid item xs={12}>
          <Collapse in={_expanded} timeout="auto" classes={{ root: classes.collapse }}>
            {props.children}
          </Collapse>
        </Grid>
      </Grid>
    </Container>
  );
};
