import { GridCellParams } from '@mui/x-data-grid-pro';
import { Box, ClickAwayListener, Paper, Popper } from 'amn-ui-core';
import React, { useRef, useState } from 'react';

interface XGridOptionsCellProps {
  icon: string | JSX.Element;
  alt?: string;
  align?: string;
  onClick?: (params: GridCellParams, event) => void;
  hoverTemplate: string | JSX.Element;
  [key: string]: any;
}

const GridCellExpand = React.memo(function GridCellExpand(props: XGridOptionsCellProps) {
  const { icon, alt, align, onClick, params, hoverTemplate } = props;
  const cellDiv = useRef(null);
  const [showPopper, setShowPopper] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const wrapper = React.useRef<HTMLDivElement | null>(null);

  const handleClick = e => {
    setShowPopper(true);
    setAnchorEl(cellDiv.current);
    onClick?.(params, e);
  };

  return (
    <React.Fragment>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={e => {
          setShowPopper(false);
          onClick?.(params, e);
        }}
      >
        <div
          ref={wrapper}
          style={{
            width: 'unset',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            position: 'relative',
          }}
        >
          <div
            ref={cellDiv}
            style={{
              height: 1,
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
          <Box onClick={e => handleClick(e)}>
            {typeof icon === 'string' && <img style={{ justifySelf: align }} src={icon} alt={alt} />}
            {typeof icon !== 'string' && icon}
          </Box>
        </div>
      </ClickAwayListener>

      {showPopper && (
        <Popper
          placement="bottom-end"
          open={showPopper && anchorEl !== null}
          anchorEl={anchorEl}
          style={{
            marginLeft: -7,
            maxWidth: '35vw',
            overflow: 'auto',
            /** Updated ZIndex for displaying poppers within Generic dialog */
            zIndex: 1301,
          }}
        >
          <Paper
            elevation={3}
            style={{
              minHeight: wrapper.current!.offsetHeight - 3,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {hoverTemplate}
          </Paper>
        </Popper>
      )}
    </React.Fragment>
  );
});

/**
 * Cell renderer to display an popper content on click of menu.
 * @param params X-Grid Params: `GridCellParams`
 * @returns JSXElement
 */
export const XGridOptionsCell = ({
  icon,
  alt = '',
  align = 'center',
  onClick,
  hoverTemplate,
}: XGridOptionsCellProps) => (params: GridCellParams) => {
  return (
    <GridCellExpand icon={icon} alt={alt} align={align} onClick={onClick} {...params} hoverTemplate={hoverTemplate} />
  );
};
