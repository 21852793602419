import { FilterCompTypes } from 'app/ComponentLibrary/Filter/utils';

export enum EMRFilterName {
  Procedure = 'procedure',
}

export const defaultFilterValues = {
  [EMRFilterName.Procedure]: [],
};

export enum emrFilterModel {
  procedure = 'procedure',
}

export const emrFilter = (filterSpec, t, styleClasses, gridStateFilters) => [
  {
    filterName: t('candidateProfile.personalInfoCard.equipmentProcedures'),
    name: emrFilterModel.procedure,
    type: FilterCompTypes.ADVANCEDROPDOWN,
    isMultiSelect: true,
    options: filterSpec.procedure,
    placeholder: 'Select',
    selectAllValue: 'All',
  },
];
