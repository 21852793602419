import { tooltipType } from 'app/components/Common/Tooltips';
import { ICustomHeader } from 'app/models/Grid';
import { GridColumns } from '@mui/x-data-grid-pro';
import { XGridContactHoverView } from 'app/components/Common/XGrid/cells/HoverViewRenderers/XGridContactHoverView';
import { XGridBlueCell } from 'app/components/Common/XGrid/cells/XGridBlueCell';
import { XGridDefaultColumn } from 'app/components/Common/XGrid/cells/XGridDefaults';
import { XGridLinkCell } from 'app/components/Common/XGrid/cells/XGridLinkCell';
import { XGridOverflowCell } from 'app/components/Common/XGrid/cells/XGridOverflowCell';
import { xGridDateSort, xGridNumberSortByField } from 'app/components/Common/XGrid/sorting/XGridSorting';
import { amPlacementsGridKeys } from './AmGridHelper';
import { XGridSideBarViewCell } from 'app/components/Common/XGrid/cells/XGridSideBarViewCell';
import { XGridAvatarCell } from '@AMIEWEB/Common/XGrid/cells/XGridAvatarCell';

interface IGridColumns extends Omit<ICustomHeader, 'title'> {
  title:
  | 'placementId'
  | 'orderId'
  | 'candidate'
  | 'facilityName'
  | 'facilityCity'
  | 'facilityState'
  | 'unit'
  | 'skillSet'
  | 'vmsReqNo'
  | 'status'
  | 'statusAge'
  | 'recruiter'
  | 'lastCueDate'
  | 'availableStartDate'
  | 'orderShift'
  | 'shiftRequested'
  | 'startDate'
  | 'endDate'
  | 'length'
  | 'credentialingRating'
  | 'credentialingAnalyst'
  | 'extStatus'
  | 'extStatusAge'
  | 'daysLeft'
  | 'clinicalManager'
  | 'lastCueNote'
  | 'cuedFrom';
}

export const notOAColumns = ['availabilityStartDate', 'credentialingRate', 'credentialingAnalyst'];

export const getAmPlacementColumns = (t, flexColumns): GridColumns => {
  const defaultAMWorkDeskColumn = {
    ...XGridDefaultColumn,
    // replace this to have flex column layout for default columns
    // flex: flexColumns ? 1 : 0
    flex: 0,
  };
  return [
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.placementAccountManger'),
      field: 'placementAccountManager',
      width: 150,
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.lastCueDate'),
      field: 'lastCueDate',
      width: 160,
      type: 'date',
      sortComparator: xGridDateSort,
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.placementId'),
      field: 'placementId',
      width: 75,
      renderCell: XGridOverflowCell(XGridLinkCell('/placement/{0}', ['placementId'], true))(
        XGridLinkCell('/placement/{0}', ['placementId']),
      ),
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.status'),
      field: 'status',
      width: 140,
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.candidate'),
      field: amPlacementsGridKeys.candidate,
      width: 140,
      renderCell: XGridOverflowCell(XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId'], true))(
        XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId']),
      ),
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.facility'),
      field: amPlacementsGridKeys.facilityName,
      width: 190,
      renderCell: XGridOverflowCell(XGridLinkCell('/facility/{0}', ['facilityId'], true))(
        XGridLinkCell('/facility/{0}', ['facilityId']),
      ),
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.unit'),
      field: 'unit',
      width: 150,
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.skillSet'),
      field: amPlacementsGridKeys.skillSet,
      width: 145,
    },

    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.orderShift'),
      field: 'orderShift',
      width: 130,
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.shiftsRequested'),
      field: 'shiftRequested',
      width: 160,
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.availableStartDate'),
      field: 'availableStartDate',
      width: 160,
      type: 'date',
      sortComparator: xGridDateSort,
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.orderId'),
      field: 'orderId',
      width: 75,
      renderCell: XGridOverflowCell(XGridLinkCell('/order/{0}', ['orderId'], true))(
        XGridLinkCell('/order/{0}', ['orderId']),
      ),
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.statusAge'),
      field: 'statusAge',
      width: 130,
      sortComparator: xGridNumberSortByField('statusAgeValue'),
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.startDate'),
      field: 'startDate',
      width: 125,
      type: 'date',
      sortComparator: xGridDateSort,
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.endDate'),
      field: 'endDate',
      width: 120,
      type: 'date',
      sortComparator: xGridDateSort,
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.vmsReqNo'),
      field: 'vmsReqNo',
      width: 120,
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.extStatus'),
      field: 'extStatus',
      width: 140,
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.extStatusAge'),
      field: 'extStatusAge',
      width: 130,
      sortComparator: xGridNumberSortByField('extStatusAgeValue'),
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.length'),
      field: 'length',
      width: 110,
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.recruiter'),
      field: amPlacementsGridKeys.recruiter,
      width: 150,
      renderCell: XGridOverflowCell(XGridContactHoverView('recruiterAttributes'), true)(XGridBlueCell),
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.credentialingAnalyst'),
      field: 'credentialingAnalyst',
      width: 190,
      renderCell: XGridOverflowCell(XGridContactHoverView('credentialingAnalystAttributes'), true)(XGridBlueCell),
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.credentialingRating'),
      field: 'credentialingRating',
      width: 160,
    },
    {
      ...defaultAMWorkDeskColumn,
      field: 'candidateId',
      headerName: t('home.amPlacementColumns.candidateId'),
      width: 100,
      hide: false,
      renderCell: XGridOverflowCell(XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId'], true))(
        XGridSideBarViewCell('/candidate/{0}/{1}', ['candidateId', 'brandId']),
      ),
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.city'),
      field: amPlacementsGridKeys.facilityCity,
      width: 120,
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.state'),
      field: amPlacementsGridKeys.facilityState,
      width: 100,
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.clinicalManager'),
      field: 'clinicalManager',
      width: 165,
      renderCell: XGridOverflowCell(XGridContactHoverView('clinicalManagerAttributes'), true)(XGridBlueCell),
    },
    {
      ...defaultAMWorkDeskColumn,
      headerName: t('home.amPlacementColumns.daysLeft'),
      field: 'daysLeft',
      width: 100,
      sortComparator: xGridNumberSortByField('daysLeftValue'),
    },
  ]
};

export const defaultColumnVisiblityModel = {
  placementAccountManager: true,
  placementId: true,
  orderId: true,
  facilityName: true,
  facilityCity: true,
  facilityState: true,
  unit: true,
  skillSet: true,
  vmsReqNo: true,
  clinicalManager: true,
  recruiter: true,
  daysLeft: true,
  candidateId: true,
  candidate: true,
  status: true,
  extStatus: true,
  extStatusAge: true,
  statusAge: true,
  availableStartDate: true,
  startDate: true,
  endDate: true,
  orderShift: true,
  shiftRequested: true,
  credentialingRating: true,
  credentialingAnalyst: true,
  length: true,
  lastCueDate: true,
}

/**
 * @deprecated use `getAmPlacementColumns` instead
 */
export const getAmPlacementColumnsOld = t =>
  [
    {
      value: t('home.amPlacementColumns.placementId'),
      title: 'placementId',
      clickable: true,
      sortable: true,
      navigationIds: ['placementId'],
      navigationExp: '/placement/{0}',
      isNumber: true,
    },
    {
      value: t('home.amPlacementColumns.orderId'),
      title: 'orderId',
      clickable: true,
      sortable: true,
      navigationIds: ['orderId'],
      navigationExp: '/order/{0}',
      isNumber: true,
    },
    {
      value: t('home.amPlacementColumns.facility'),
      title: 'facilityName',
      clickable: true,
      sortable: true,
      navigationIds: ['facilityId'],
      navigationExp: '/facility/{0}',
      tooltip: tooltipType.OverflowTooltip,
    },
    {
      value: t('home.amPlacementColumns.city'),
      title: 'facilityCity',
      sortable: true,
    },
    {
      value: t('home.amPlacementColumns.state'),
      title: 'facilityState',
      sortable: true,
    },
    {
      value: t('home.amPlacementColumns.unit'),
      title: 'unit',
      sortable: true,
    },
    {
      value: t('home.amPlacementColumns.skillSet'),
      title: 'skillSet',
      sortable: true,
    },
    {
      value: t('home.amPlacementColumns.vmsReqNo'),
      title: 'vmsReqNo',
    },
    {
      value: t('home.amPlacementColumns.candidate'),
      title: 'candidate',
      clickable: true,
      sortable: true,
      navigationIds: ['candidateId', 'brandId'],
      navigationExp: '/candidate/{0}/{1}',
    },
    {
      value: t('home.amPlacementColumns.status'),
      title: 'status',
      sortable: true,
      sortByField: 'status',
    },
    {
      value: t('home.amPlacementColumns.extStatus'),
      title: 'extStatus',
      sortable: true,
    },
    {
      value: t('home.amPlacementColumns.extStatusAge'),
      title: 'extStatusAge',
      sortable: true,
      sortByField: 'extStatusAgeValue',
    },
    {
      value: t('home.amPlacementColumns.statusAge'),
      title: 'statusAge',
      sortable: true,
      sortByField: 'statusAgeValue',
    },
    {
      value: t('home.amPlacementColumns.availableStartDate'),
      title: 'availableStartDate',
      sortable: true,
      sortByField: 'availableStartDateNoFormat',
    },
    {
      value: t('home.amPlacementColumns.startDate'),
      title: 'startDate',
      sortable: true,
      sortByField: 'startDateNoFormat',
    },
    {
      value: t('home.amPlacementColumns.endDate'),
      title: 'endDate',
      sortable: true,
      sortByField: 'endDateNoFormat',
    },
    {
      value: t('home.amPlacementColumns.orderShift'),
      title: 'orderShift',
      sortable: true,
    },
    {
      value: t('home.amPlacementColumns.shiftsRequested'),
      title: 'shiftRequested',
      sortable: true,
    },
    {
      value: t('home.amPlacementColumns.credentialingRating'),
      title: 'credentialingRating',
      sortable: false,
    },
    {
      value: t('home.amPlacementColumns.credentialingAnalyst'),
      title: 'credentialingAnalyst',
      sortable: true,
      tooltip: tooltipType.IterableKeyTooltip,
      tooltipAttributes: 'credentialingAnalystAttributes',
    },
    {
      value: t('home.amPlacementColumns.length'),
      title: 'length',
      sortable: true,
      isNumber: true,
    },
    {
      value: t('home.amPlacementColumns.daysLeft'),
      title: 'daysLeft',
      sortable: true,
      sortByField: 'daysLeftValue',
    },
    {
      value: t('home.amPlacementColumns.recruiter'),
      title: 'recruiter',
      sortable: true,
      tooltip: tooltipType.IterableKeyTooltip,
      tooltipAttributes: 'recruiterAttributes',
    },
    {
      value: t('home.amPlacementColumns.clinicalManager'),
      title: 'clinicalManager',
      sortable: true,
      tooltip: tooltipType.IterableKeyTooltip,
      tooltipAttributes: 'clinicalManagerAttributes',
    },
    {
      value: t('home.amPlacementColumns.lastCueDate'),
      title: 'lastCueDate',
      sortable: true,
      sortByField: 'lastCueDateOnNoFormat',
    },
    {
      value: t('home.amPlacementColumns.lastCueNote'),
      title: 'lastCueNote',
      sortable: true,
      tooltip: tooltipType.OverflowTooltip,
    },
    {
      value: t('home.amPlacementColumns.cuedFrom'),
      title: 'cuedFrom',
      sortable: true,
      tooltip: tooltipType.IterableKeyTooltip,
      tooltipAttributes: 'cuedFromAttributes',
    },
  ] as IGridColumns[];

export const notInSubmissionColumns = [
  'startDate',
  'endDate',
  'length',
  'credentialingRating',
  'credentialingAnalyst',
  'extStatus',
  'extStatusAge',
  'daysLeft',
  'clinicalManager',
  'lastCueNote',
  'cuedFrom',
];
export const notOfferColumns = [
  'availableStartDate',
  'orderShift',
  'shiftRequested',
  'credentialingRating',
  'credentialingAnalyst',
  'extStatus',
  'extStatusAge',
  'daysLeft',
  'clinicalManager',
  'lastCueNote',
  'cuedFrom',
];
export const notBookedColumns = [
  'availableStartDate',
  'orderShift',
  'shiftRequested',
  'length',
  'extStatus',
  'extStatusAge',
  'daysLeft',
  'clinicalManager',
  'lastCueNote',
  'cuedFrom',
];
export const notOnAssignmentColumns = [
  'availableStartDate',
  'orderShift',
  'shiftRequested',
  'length',
  'statusAge',
  'credentialingRating',
  'credentialingAnalyst',
  'lastCueNote',
  'cuedFrom',
];

export const notDefaultColumns = [
  'facilityCity',
  'facilityState',
  'orderShift',
  'shiftRequested',
  'recruiter',
  'length',
  'credentialingRating',
  'credentialingAnalyst',
  'extStatus',
  'extStatusAge',
  'daysLeft',
  'clinicalManager',
  'lastCueNote',
  'cuedFrom',
];

export const activeCueColumns = ['lastCueNote', 'cuedFrom'];

