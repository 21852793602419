import { convertToPSTMoment } from 'app/helpers/dateHelper';
import { isNil } from 'lodash';
import moment from 'moment';

const PERSONAL_TIME_OFF = 'Personal Time Off';

export enum JobTypes {
  Travel = 'Travel',
  TimeOff = 'Time Off',
  PerDiem = 'Per Diem',
  Clinical = 'Clinical',
}

export enum JobTypeIDs {
  Travel = 1,
  TimeOff = null,
  PerDiem = 2,
  Clinical = 3,
}

export const getJobTypeValue = (data: any) => {
  return !isNil(data?.jobTypeID) && !isNil(data?.jobType)
    ? data.jobType
    : data?.facilityName === PERSONAL_TIME_OFF
    ? JobTypes.TimeOff
    : JobTypes.Travel;
};

export const isDisabledWorkExperience = (data: any) => {
  return data?.hasCandidateReferences || data?.placementID;
};

export const getFormattedDatePeriod = (dateString: string | null | undefined): string | null => {
  if (!dateString) return null;

  const pstNow = moment().tz('America/Los_Angeles'); //Current PST Time
  const targetDateInPst = convertToPSTMoment(dateString);

  const duration = moment.duration(pstNow.diff(targetDateInPst));

  if (duration?.asYears() >= 1) {
    const years = Math.floor(duration.asYears());
    return `${years} year${years !== 1 ? 's' : ''}`;
  } else if (duration?.asMonths() >= 1) {
    const months = Math.floor(duration.asMonths());
    return `${months} month${months !== 1 ? 's' : ''}`;
  } else if (duration?.asDays() >= 1) {
    const days = Math.floor(duration.asDays());
    return `${days} day${days !== 1 ? 's' : ''}`;
  } else {
    const hours = Math.floor(duration?.asHours());
    const minutes = duration?.minutes();
    return `${hours?.toString()?.padStart(2, '0')}:${minutes?.toString()?.padStart(2, '0')}`;
  }
};

export const getWorkExpLastUpdatedText = (data: any) => {
  const datePeriod = getFormattedDatePeriod(data?.lastUpdated);
  return data?.lastUpdatedByUser?.trim()
    ? `Changed by ${data.lastUpdatedByUser.trim()}${datePeriod ? ` - ${datePeriod} ago` : ''}`
    : '';
};
