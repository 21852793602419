import { XGridChipCell } from '@AMIEWEB/Common';
import { XGridDefaultColumn } from '@AMIEWEB/Common/XGrid/cells/XGridDefaults';
import {
  selectApprovalDocuments,
  selectFacilityMiscellaneousData,
  selectedApproveRejectRows,
} from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { GridColumns, GridCellParams } from '@mui/x-data-grid-pro';
import { Box, ClickAwayListener, Popper, XGrid, MenuItem, MenuList } from 'amn-ui-core';
import React, { useEffect, useMemo, useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { ApproveOrRejectCell } from './ApproveOrRejectCell';
import { getRateValue, getShift, getStatusName } from '../OffContractRateElements';
import { formatDate } from '@AMIEWEB/Order/OrderDetails/helper';
import Approved from 'app/assets/images/IconImages/Approve.svg';
import Rejected from 'app/assets/images/IconImages/Reject.svg';
import { cloneDeep } from 'lodash';
import { ApprovalStatusHeader } from './ApprovalStatusHeader';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { useTranslation } from 'react-i18next';
import { multiSort } from '../../FormatData';

const useStyles = makeStyles<{ rows }>()((theme, props) => ({
  grid: {
    border: 'none',
    fontSize: '14px',
    height: props.rows
      ? props.rows?.length === 0
        ? '100px'
        : props.rows?.length < 6
          ? `${props.rows.length * 39 + 66}px`
          : `${5 * 39 + 66}px`
      : '100px',
    '& .MuiDataGrid-row': {
      borderBottom: '1px solid #D5D5D5',
    },
    '& .MuiDataGrid-virtualScroller': {
      // maxHeight: !props.rows || props.rows?.length === 0 ? '57px' : '250px',
      // height: !props.rows || props.rows?.length === 0 ? '57px' : 'auto',
      borderBottom: '1px solid #D5D5D5',
    },
  },
  selectOptions: {
    padding: '10px',
    margin: '20px',
    border: 'none',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 8px #0000004D',
    borderRadius: '4px',
    opacity: 1,
  },
  popper: {
    boxShadow: '0px 2px 8px #0000004D',
    borderRadius: '4px',
    opacity: 1,
    zIndex: 5000,
    backgroundColor: '#FFFFFF',
  },
  icon: {
    height: '16px',
    width: '16px',
    borderRadius: '10px',
    border: 'none',
    marginRight: '10px',
  },
}));

export const RelatedRateDetails = props => {
  const [rows, setRows] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [rowsToDisplay, setRowsToDisplay] = useState([]);
  const [columns, setColumns] = useState<GridColumns<any>>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { classes } = useStyles({ rows: rows });
  const rateDetails = useSelector(selectApprovalDocuments);
  const changesInRelatedRateDetails = useSelector(selectedApproveRejectRows);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const data = useSelector(selectFacilityMiscellaneousData);
  const { promiseInProgress: isCallingAPI } = usePromiseTracker({ area: 'get-approval-document', delay: 0 });
  const rowHeight = 39;
  const getLastUpdated = (date, name) => {
    return formatDate(date) + ' by ' + name;
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const selectAll = statusId => {
    let tempRows = rows.map(elem => ({
      ...elem,
      statusId: statusId,
    }));
    setRows(tempRows);
    setRowsToDisplay(tempRows);
    let rateDetail = changesInRelatedRateDetails.find(x => x.rateElementId === props?.row?.rateElementId);
    if (rateDetail) {
      dispatch(facilityActions.setSelectedRejectedRows([...tempRows, rateDetail]));
    } else {
      dispatch(facilityActions.setSelectedRejectedRows(tempRows));
    }

    props.setApprovalChangesPending(true);
  };

  const handleApproveAll = () => {
    if (rows && rows.length > 0) {
      selectAll(4);
      dispatch(facilityActions.setSelectedApproveRejectOption(1));
      handleClose();
    }
  };
  const handleRejectAll = () => {
    if (rows && rows.length > 0) {
      selectAll(2);
      dispatch(facilityActions.setSelectedApproveRejectOption(2));
      handleClose();
    }
  };

  const getDisciplineName = (val: number) => {
    let value = '--';
    if (val) {
      const discipline = data?.disciplineOpts.find(ele => ele.object.ID === val);
      value = discipline?.object?.Abbreviation;
    }
    return value;
  };

  const getSpecialtyName = (val: number) => {
    let value = '--';
    if (val) {
      const specialty = data?.specialtyOpts.find(ele => ele.object.SpecialtyID === val);
      value = specialty?.label;
    }
    return value;
  };

  const getSubSpecialtyName = (val: number) => {
    let value = '--';
    if (val) {
      const subSpecialty = data?.subSpecialtyOpts.find(ele => ele.object.SubSpecialtyID === val);
      value = subSpecialty?.label;
    }
    return value;
  };

  const setStatus = (statusId, index) => {
    let relatedRows = cloneDeep(rows);
    let record = relatedRows?.find(x => x.id === index);
    record.statusId = statusId;
    setRows(relatedRows);
    let relatedRowsToDisplay = cloneDeep(rowsToDisplay);
    let recordRowsToDisplay = relatedRowsToDisplay?.find(x => x.id === index);
    recordRowsToDisplay.statusId = statusId;
    setRowsToDisplay(relatedRowsToDisplay);
    if (relatedRows && relatedRows.length > 0) {
      props.setApprovalChangesPending(true);
    }
  };

  const setHeight = () => {
    const moreThanOneElements = rows ? (rows?.length > 1 ? true : false) : false;
    props.setRelatedRateElementsHeight(
      rowHeight * (moreThanOneElements ? (rows?.length < 6 ? rows?.length : 5) : 1) +
      ((props.ccaOrAMUser && (props.row.statusId === 4 || props.row.statusId === 2)) || props.ccaUser ? 160 : 100),
    );
  };

  const relatedRateDetailsColumns = (exclude = [] as string[]): GridColumns<any> =>
    (
      [
        {
          ...XGridDefaultColumn,
          field: 'discipline',
          headerName: 'Discipline',
          headerClassName: 'super-app-theme--header',
          flex: 0,
          width: 150,
        },
        {
          ...XGridDefaultColumn,
          field: 'specialty',
          headerName: 'Specialty',
          headerClassName: 'super-app-theme--header',
          flex: 0,
          width: 150,
        },
        {
          ...XGridDefaultColumn,
          field: 'subSpecialty',
          headerName: 'Sub Specialty',
          headerClassName: 'super-app-theme--header',
          flex: 0,
          width: 150,
        },
        {
          ...XGridDefaultColumn,
          field: 'rateElementName',
          headerName: 'Rate Element',
          headerClassName: 'super-app-theme--header',
          flex: 0,
          width: 150,
        },
        {
          ...XGridDefaultColumn,
          field: 'rateValue',
          headerName: 'Rate Value',
          headerClassName: 'super-app-theme--header',
          align: 'right',
          headerAlign: 'right',
          flex: 0,
          width: 150,
        },
        {
          ...XGridDefaultColumn,
          field: 'shift',
          headerName: 'Shift',
          headerClassName: 'super-app-theme--header',
          align: 'right',
          headerAlign: 'right',
          flex: 0,
          width: 80,
        },
        {
          ...XGridDefaultColumn,
          field: 'status',
          headerName: 'Status',
          headerClassName: 'super-app-theme--header',
          align: 'left',
          headerAlign: 'left',
          flex: 0,
          width: 180,
          renderCell: XGridChipCell({
            size: 'small',
            dynamicColor: (params: GridCellParams) => {
              return props.getColor(params.value);
            },
            renderValue: (params: GridCellParams) => {
              return props.getValue(params.value);
            },
          }),
        },
        {
          ...XGridDefaultColumn,
          field: 'startDate',
          headerName: 'Start Date',
          headerClassName: 'super-app-theme--header',
          flex: 0,
          width: 150,
        },
        {
          ...XGridDefaultColumn,
          field: 'endDate',
          headerName: 'End Date',
          headerClassName: 'super-app-theme--header',
          flex: 0,
          width: 150,
        },
        {
          ...XGridDefaultColumn,
          field: 'createdAt',
          headerName: 'Created At',
          headerClassName: 'super-app-theme--header',
          flex: 0,
          width: 140,
        },
        {
          ...XGridDefaultColumn,
          field: 'createdOn',
          headerName: 'Created On',
          headerClassName: 'super-app-theme--header',
          flex: 0,
          width: 200,
        },
        {
          ...XGridDefaultColumn,
          field: 'lastUpdated',
          headerName: 'Last Updated',
          headerClassName: 'super-app-theme--header',
          flex: 0,
          width: 200,
        },
        {
          ...XGridDefaultColumn,
          field: 'approveOrReject',
          align: 'left',
          headerAlign: 'left',
          headerName: 'Approval Status',
          hideSortIcons: true,
          flex: 1,
          minWidth: 150,
          resizable: false,
          headerClassName: 'super-app-theme--header',
          sortable: false,
          disableColumnMenu: true,
          renderHeader: params => <ApprovalStatusHeader params={params} handleClick={handleClick} active={open} />,
          renderCell: params => {
            return (
              <ApproveOrRejectCell
                rateDetails={props.changesInRelatedRateDetails}
                setRateDetails={props.setChangesInRelatedRateDetails}
                row={params?.row}
                setStatus={setStatus}
                statusId={props.statusId}
                id={params.row.id}
              />
            );
          },
        },
      ] as GridColumns
    ).filter(item => !exclude.some(field => field === item.field));

  const RecordsComponent = records => {
    if (currentIndex >= records.length) {
      return [];
    }
    const next5Records = records.slice(currentIndex, currentIndex + 5);
    setCurrentIndex(currentIndex + 5);
    return next5Records.map(records => records);
  };

  const scrolledToEnd = () => {
    setRowsToDisplay([...rowsToDisplay, ...RecordsComponent(rows)]);
  };
  useEffect(() => {
    if (rateDetails?.rateElements && rateDetails.attachments?.length > 0) {
      const rowData = rateDetails?.rateElements
        ?.filter(x => x.statusId === 3)
        .map((item, index) => ({
          ...item,
          status: getStatusName(item.statusId),
          rateValue: getRateValue(item.value, item.valueMax, item.rateElementName),
          discipline: getDisciplineName(item.disciplineId),
          specialty: getSpecialtyName(item.specialtyId),
          subSpecialty: getSubSpecialtyName(item.subSpecialtyId),
          endDate: props.getValue(formatDate(item.effectiveEndDate)),
          startDate: props.getValue(formatDate(item.effectiveStartDate)),
          createdOn: getLastUpdated(item.dateCreated, item.createdBy),
          lastUpdated: getLastUpdated(item.dateLastUpdated, item.lastUpdatedBy),
          shift: getShift(item.shiftId),
          id: index + 1,
        }));
      setRows(multiSort(rowData));
      props.setRelatedRateDetailsRows(cloneDeep(multiSort(rowData)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateDetails]);

  useEffect(() => {
    let resetRows = cloneDeep(props.rows);
    setRows(resetRows);
    setRowsToDisplay(resetRows);
    dispatch(facilityActions.setSelectedRejectedRows([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cancelled]);

  useEffect(() => {
    if (props.ccaUser) {
      let columns = relatedRateDetailsColumns(['status']);
      setColumns(columns);
    } else {
      let columns = relatedRateDetailsColumns(['approveOrReject']);
      setColumns(columns);
    }
    dispatch(facilityActions.setSelectedApproveRejectOption(0));
    setHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, props.appUser]);

  useEffect(() => {
    scrolledToEnd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const grid = useMemo(() => {
    return (
      <>
        <XGrid
          columns={columns}
          rows={rowsToDisplay}
          loading={isCallingAPI}
          className={classes.grid}
          headerHeight={48}
          rowHeight={39}
          initialState={{ pinnedColumns: { right: ['approveOrReject'] ? ['approveOrReject'] : ['status'] } }}
          hideFooter
          disableMultipleSelection={true}
          onRowsScrollEnd={scrolledToEnd}
        />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, rowsToDisplay, rows]);
  return (
    <React.Fragment>
      <Box
        sx={{
          width: '100%',
          '& .super-app-theme--header': {
            backgroundColor: '#F5F5F5',
          },
        }}
      >
        {grid}
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
          style={{ inset: '0px auto auto -50px' }}
          className={classes.popper}
          disablePortal={false}
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ]}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList>
              <MenuItem onClick={handleApproveAll}>
                <Box>
                  <img src={Approved} alt="Approved" className={classes.icon} />
                </Box>
                {t('notification.facilityDetials.approveAll')}
              </MenuItem>
              <MenuItem onClick={handleRejectAll}>
                <Box>
                  <img src={Rejected} alt="Rejected" className={classes.icon} />
                </Box>
                {t('notification.facilityDetials.rejectAll')}
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Popper>
      </Box>
    </React.Fragment>
  );
};
