import { DateRangePicker } from 'app/ComponentLibrary/DateRangeNew';
import { addYears } from 'date-fns';
import { InlineEditableField } from 'app/components/Common/EditPage/InlineEditableField';
import moment from 'moment';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useEffect, useContext } from 'react';
import { DrawerContext } from 'app/components/Common/Drawer/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { pageAttributesActions } from '../store/placementPageAttributes.redux';
import { selectEditStatus, selectPageAttributes } from '../store/placementPageAttributes.selector';
import { editableFields } from '../../editUtils';
import { ActionAccessorHook } from './ActionAccessorHook';
import { missingField } from 'app/constants';
import { CustomTooltip } from '@AMIEWEB/Common';
import { getExtensionDuration } from 'app/helpers/getExtensionDuration';

export const PlacementEndDate = ({ id, maxWidth }: { id: string; maxWidth?: number | undefined }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { control, setValue, clearErrors, setError, errors, getValues } = useFormContext();

  const value = getValues('placementDate');
  const startDateValue = getValues('placementStartDate');
  const endDateValue = getValues('placementEndDate');
  const placementLength = getValues('numberOfWeeks');

  const { setPopperOpen } = useContext(DrawerContext);
  const { placementDate: status } = useSelector(selectEditStatus);
  const { editOrderStack } = useSelector(selectPageAttributes);
  const undoChecker = () => !editOrderStack.some(key => key === 'placementEndDate');
  const startDateMoment = moment(startDateValue);

  useEffect(() => {
    return () => {
      setPopperOpen?.('placementEndDate', false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateStartDate = endDate => {
    const endDateMoment = moment(endDate);
    if (startDateMoment?.isValid()) {
      const placementDateValue = {
        startDate: startDateMoment?.format('YYYY-MM-DDTHH:mm:ss'),
        endDate: endDateMoment?.format('YYYY-MM-DDTHH:mm:ss'),
      };
      setValue('placementDate', placementDateValue);
      const weeksGap = getExtensionDuration(startDateMoment, endDate);
      if (endDateMoment?.isBefore(startDateMoment)) {
        setValue('numberOfWeeks', 0);
        setError('placementEndDate', {
          type: 'validate',
          message: '',
        });
      } else if (weeksGap >= 0) {
        setValue('numberOfWeeks', weeksGap);
        clearErrors('placementEndDate');
      }
    } else if (placementLength !== null && placementLength >= 0) {
      const startDate = endDateMoment?.subtract(placementLength, 'weeks');
      setValue('placementStartDate', startDate?.format('YYYY-MM-DDTHH:mm:ss'));
      const placementDateValue = {
        startDate: startDate?.format('YYYY-MM-DDTHH:mm:ss'),
        endDate: endDateMoment?.format('YYYY-MM-DDTHH:mm:ss'),
      };
      setValue('placementDate', placementDateValue);
    }
  };

  return (
    <div>
      <Controller
        control={control}
        name={'placementEndDate'}
        render={({ ref, onChange, ...rest }) => {
          const startDate = moment(endDateValue);
          const isError = errors?.hasOwnProperty('placementEndDate');
          return (
            <>
              {/** Component is used to access inaccessible methods from react-hook-form Controller
               * to invoke actions - say undo action */}
              <CustomTooltip
                smallTooltip
                tooltipContent={
                  isError ? (
                    <div style={{ color: '#CC0000', font: 'normal normal normal 12px/30px Roboto' }}>
                      {t('placement.profile.edit.endDateInvalid')}
                    </div>
                  ) : (
                    ''
                  )
                }
                globalStyles
                isStandardContent
              >
                <ActionAccessorHook
                  actionChecker={undoChecker}
                  accessAction={() => onChange(endDateValue)}
                  hookDependancies={[editOrderStack]}
                />
                <InlineEditableField
                  {...rest}
                  id={id}
                  maxWidth={maxWidth}
                  inputProps={{
                    size: 'inline',
                    variant: 'outlined',
                    initialDateRange: {
                      startDate: startDate?.isValid() ? new Date(endDateValue) : undefined,
                      endDate: startDate?.isValid() ? new Date(endDateValue) : undefined,
                      useLabelAsValue: true,
                    },
                    maxDate: addYears(new Date(), 20),
                    quickSelect: false,
                    disablePortal: true,
                    actionBar: false,
                    single: true,
                    onOpen: open => setPopperOpen?.('startDate', open),
                    useMaxWidth: !!maxWidth,
                  }}
                  label={startDate.isValid() ? startDate.format('MM/DD/YYYY') : missingField}
                  Control={DateRangePicker}
                  onChange={newValue => {
                    if (newValue.endDate !== undefined) {
                      const newDate = moment(newValue.endDate);
                      const setTo = newDate.isValid() ? moment(newDate).format('YYYY-MM-DDTHH:mm:ss') : null;
                      calculateStartDate(newValue.endDate);
                      onChange(setTo);
                    } else {
                      setValue('numberOfWeeks', null);
                      const placementDate = { ...value, endDate: null };
                      setValue('placementDate', placementDate);
                      onChange(null);
                    }
                    dispatch(pageAttributesActions.pushToEditOrderStack(editableFields.placementEndDate));
                    // Uncomment if you want to be able to clear the date
                    // else onChange(null);
                  }}
                  setCurrentFocus={state => dispatch(pageAttributesActions.setCurrentFocus(state !== null ? id : null))}
                  error={isError}
                  isFieldError={isError}
                  helperTextDisabled={true}
                  tooltipText={status.disabled ? t('placement.profile.edit.disableText') : ''}
                  {...status}
                />
              </CustomTooltip>
            </>
          );
        }}
      />
    </div>
  );
};
