import { Grid } from 'amn-ui-core';
import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const SplitPaneContext = createContext(null);

const forceClientWidthFactor = (factor, defaultFactor) =>
  factor && factor > 0 && factor <= 1 ? factor : defaultFactor ?? null;

export const SplitPane: React.FC<{
  leftPaneLimits?: { default?: number; minWidth?: number; maxWidth?: number };
  Element?;
  onSplitPaneDragStart?: () => void;
  onSplitPaneDragEnd?: ({ clientHeight, clientWidth }) => void;
  paneRefs?: { topPaneRef?; rightPaneRef?; leftPaneRef?; bottomPaneRef? };
  [key: string]: any;
}> = ({
  Element = Grid,
  onSplitPaneDragStart = undefined,
  onSplitPaneDragEnd = undefined,
  paneRefs = { leftPaneRef: undefined, topPaneRef: undefined, rightPaneRef: undefined },
  leftPaneLimits,
  ...props
}) => {
  const [clientHeight, setClientHeight] = useState(() => props.forceClientHeight ?? null);
  const [clientWidthFactor, setClientWidthFactor] = useState(() =>
    forceClientWidthFactor(props.forceClientWidth, leftPaneLimits.default),
  );

  const dividerPosition = useRef(null);

  const containerRef = useRef(null);

  const { leftPaneRef, rightPaneRef } = paneRefs;

  const onMouseHoldDown = e => {
    dividerPosition.current = { x: e.clientX, y: e.clientY };
    onSplitPaneDragStart?.();
  };

  const onMouseHoldUp = useCallback(() => {
    /** Code: User is interacting with divider */
    if (!!dividerPosition.current) onSplitPaneDragEnd?.({ clientHeight, clientWidth: clientWidthFactor });
    dividerPosition.current = null;
  }, [clientHeight, clientWidthFactor, onSplitPaneDragEnd]);

  const adjustPaneSizes = React.useCallback(({ leftPaneWidth }) => {
    leftPaneRef.current.style.flex = `0 0 ${leftPaneWidth}px`;
    rightPaneRef.current.style.flex = `1 1 auto`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMouseHoldMove = useCallback(
    e => {
      if (!dividerPosition.current) {
        return;
      }

      if (
        !leftPaneRef
          ? true
          : /** Code: If user is dragging right-wards */
          e.movementX > 0
          ? leftPaneRef.current.offsetWidth <
            (leftPaneLimits?.maxWidth ? containerRef.current.offsetWidth * leftPaneLimits?.maxWidth : null)
          : /** Code: If user is dragging left-wards */
          e.movementX < 0
          ? leftPaneRef.current.offsetWidth >
            (leftPaneLimits?.minWidth ? containerRef.current.offsetWidth * leftPaneLimits?.minWidth : null)
          : false
      ) {
        const newLeftPaneWidth = leftPaneRef.current.offsetWidth + e.clientX - dividerPosition.current.x;
        const widthFactor = newLeftPaneWidth / containerRef.current.offsetWidth;
        setClientWidthFactor(widthFactor);
        adjustPaneSizes({ leftPaneWidth: newLeftPaneWidth });
      }
      setClientHeight(clientHeight + e.clientY - dividerPosition.current.y);

      dividerPosition.current = { x: e.clientX, y: e.clientY };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    document.addEventListener('mouseup', onMouseHoldUp);
    document.addEventListener('mousemove', onMouseHoldMove);

    return () => {
      document.removeEventListener('mouseup', onMouseHoldUp);
      document.removeEventListener('mousemove', onMouseHoldMove);
    };
  }, [onMouseHoldMove, onMouseHoldUp]);

  useEffect(() => {
    const handleResize = () => {
      if (leftPaneRef?.current 
        && rightPaneRef?.current 
        && containerRef?.current?.offsetWidth 
        && leftPaneLimits?.default) {
            const forcedFactor = forceClientWidthFactor(props.forceClientWidth, leftPaneLimits.default);
            const leftPaneWidth = forcedFactor * containerRef.current.offsetWidth;
            adjustPaneSizes({ leftPaneWidth });
        }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (containerRef.current) {
        resizeObserver.observe(containerRef.current);
    }

    return () => {
        if (containerRef.current) {
            resizeObserver.unobserve(containerRef.current);
        }
    };
}, [props.forceClientWidth, leftPaneLimits.default, leftPaneRef?.current, rightPaneRef?.current, containerRef?.current?.offsetWidth]);

  return (
    <Element {...props} ref={containerRef}>
      <SplitPaneContext.Provider
        value={{
          clientHeight,
          setClientHeight,
          onMouseHoldDown,
        }}
      >
        {props.children}
      </SplitPaneContext.Provider>
    </Element>
  );
};
