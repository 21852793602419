import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILocCoordinates, JobMatchData } from 'app/models/Candidate/CandidateJobMatch';
import { TFunction } from 'i18next';

export interface IJobMatch {
  data: JobMatchData[];
  count: number | null;
  filteredCount: number | null;
  flattenedData: any[];
  jobLocations: ILocCoordinates[];
  serviceFailure: boolean;
  drawerData?: {
    open: boolean;
    data: any;
  };
}
export const initialState: IJobMatch = {
  data: [],
  count: null,
  filteredCount: null,
  flattenedData: [],
  jobLocations: [],
  serviceFailure: false,
  drawerData: {
    open: false,
    data: undefined,
  },
};

const getJobMatchAction = createAction<{
  travelerId: number;
  brandId: number;
  limit: number;
  offset: number;
  matchFilter?: string;
  t: TFunction;
}>('GET_JOB_MATCHES');

const getJobMatchCountAction = createAction<number>('GET_JOB_MATCHES_COUNT');

const jobMatchSlice = createSlice({
  name: 'jobMatch',
  initialState,
  reducers: {
    setJobMatches(state, action: PayloadAction<JobMatchData[]>) {
      state.data = action.payload;
    },
    setCount(state, action: PayloadAction<number>) {
      state.count = action.payload;
    },
    setFilteredCount(state, action: PayloadAction<number>) {
      state.filteredCount = action.payload;
    },
    setGridData(state, action: PayloadAction<any[]>) {
      state.flattenedData = action.payload;
    },
    setJobLocations(state, action: PayloadAction<ILocCoordinates[]>) {
      state.jobLocations = action.payload;
    },
    setServiceFailure(state, action: PayloadAction<boolean>) {
      state.serviceFailure = action.payload;
    },
    setDrawerData(state, action: PayloadAction<{ open: boolean; data: any }>) {
      return {
        ...state,
        drawerData: {
          open: action.payload.open,
          data: action.payload.data,
        },
      };
    },
    setDrawerOpen(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        drawerData: {
          data: state.drawerData?.data,
          open: false,
        },
      };
    },
  },
});

const { actions } = jobMatchSlice;
export const { reducer: jobMatchReducer, name: jobMatchSliceKey } = jobMatchSlice;
export const jobMatchActions = { ...actions, getJobMatchAction, getJobMatchCountAction };
