import { Authorized } from 'oidc/userHelper';
import { AuditType } from './AuditAddHelpers';
import { userRoles } from 'oidc/userRoles';

export const auditTypeDeletePermission = (name: string, roles) => {
  if (name === AuditType.amnEvaluation || name === AuditType.cancellation || name === AuditType.dns) {
    return !Authorized(
      [userRoles.clinical_ReviewAnalyst, userRoles.clinical_Manager, userRoles.clinical_Director],
      roles,
    );
  } else if (name === AuditType.applicationReferencing || name === AuditType.prePlacementOGPIUseOnly) {
    return !Authorized(
      [
        userRoles.clinical_ReviewAnalyst,
        userRoles.clinical_Manager,
        userRoles.clinical_Director,
        userRoles.riskManagement,
        userRoles.workersCompCoordinator,
      ],
      roles,
    );
  } else if (name === AuditType.canadianTraveler) {
    return !Authorized(
      [
        userRoles.clinical_ReviewAnalyst,
        userRoles.clinical_Manager,
        userRoles.clinical_Director,
        userRoles.immigrationTeam,
      ],
      roles,
    );
  } else if (name === AuditType.clinicianExperienceSurveyResponse) {
    return !Authorized([userRoles.clinical_Manager, userRoles.clinical_Director], roles);
  } else if (name === AuditType.experienceTouchpoint) {
    return !Authorized([userRoles.clinical_Manager, userRoles.clinical_Director], roles);
  } else if (name === AuditType.ineligibleForTravel || name === AuditType.integration) {
    return !Authorized(
      [
        userRoles.clinical_ReviewAnalyst,
        userRoles.clinical_Manager,
        userRoles.clinical_Director,
        userRoles.riskManagement,
        userRoles.immigrationTeam,
        userRoles.workersCompCoordinator,
      ],
      roles,
    );
  } else if (name === AuditType.noncancellationIncident) {
    return !Authorized(
      [
        userRoles.clinical_ReviewAnalyst,
        userRoles.clinical_Manager,
        userRoles.clinical_Director,
        userRoles.riskManagement,
        userRoles.workersCompCoordinator,
        userRoles.credentialing_Leadership,
        userRoles.immigrationTeam,
      ],
      roles,
    );
  } else return true;
};
