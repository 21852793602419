import { PlacementOrderType } from 'app/models/Placement/PlacementDetails';
import axios, { AxiosError, AxiosRequestConfig, CancelToken } from 'axios';
import { IExtensionRequest, IExtensionUpdate } from 'store/redux-store/placement-status/types';
import baseAxiosInstance from 'utils/BaseApi';
import { GetPlacementSummaryDocuments_OrderReq, GetSampleResponse } from './__mocks__/placement-service';
import {
  ICSPNote,
  IUpdateDocDeadline,
  ISubmitPacketCommand,
  IUpdateComplianceStatusNotes,
  IUpdateDirectHire,
  IUpdateComplianceStatusComments,
  IUpdateOrderId,
} from 'store/redux-store/placement-details/types';
import { IContractDocWritePayload } from 'store/redux-store/placement-candidate-contract-doc-editor/async-actions';
import { IUserPayload } from 'app/models/User';
import skillRequirementData from 'app/services/PlacementServices/__mocks__/PlacementSkillsetRequirement.json';
import { ConsolidatedPlacementPayload } from './utils';
import { IRequestCueNotePayload } from '@AMIEWEB/Placement/CreateNewPlacement/CuteNotesConfirmation/model';

export class AmieWebBaseService {
  config?: AxiosRequestConfig = {};

  private cancellationToken = axios.CancelToken.source();

  private static currentInstance: AmieWebBaseService;

  static createInstance(): AmieWebBaseService {
    const activeInstance = new AmieWebBaseService();
    activeInstance.cancellationToken = axios.CancelToken.source();
    if (activeInstance.config) activeInstance.config.cancelToken = activeInstance.cancellationToken.token;
    return activeInstance;
  }

  static getInstance(): AmieWebBaseService {
    if (!this.currentInstance) this.currentInstance = this.createInstance();
    return this.currentInstance;
  }

  static cancelActiveRequests() {
    this.currentInstance.cancellationToken.cancel('RequestCancellation');
    // this.currentInstance = null;
  }

  cancelRequests() {
    this.cancellationToken.cancel('RequestCancellation');
    return AmieWebBaseService.getInstance();
  }

  cancelAndRevokeInstance() {
    this.cancellationToken.cancel('RequestCancellation');
    this.cancellationToken = axios.CancelToken.source();
    if (this.config) this.config.cancelToken = this.cancellationToken.token;
  }
}

export class PlacementService {
  private placementBase: string;

  config?: AxiosRequestConfig = {};

  private cancellationToken = axios.CancelToken.source();

  private static currentInstance: PlacementService;

  private constructor() {
    this.placementBase = globalThis?.app?.env?.REACT_APP_PLACEMENTS_SERVICE_BASE_URL;
  }

  static createInstance(): PlacementService {
    const activeInstance = new PlacementService();
    // activeInstance.cancellationToken = axios.CancelToken.source();
    // if (activeInstance.config) activeInstance.config.cancelToken = activeInstance.cancellationToken.token;
    return activeInstance;
  }

  static getInstance(): PlacementService {
    if (!this.currentInstance) this.currentInstance = this.createInstance();
    return this.currentInstance;
  }

  static cancelActiveRequests() {
    this.currentInstance.cancellationToken.cancel('RequestCancellation');
    // this.currentInstance = null;
  }

  cancelRequests() {
    this.cancellationToken.cancel('RequestCancellation');
    return PlacementService.getInstance();
  }

  cancelAndRevokeInstance() {
    this.cancellationToken.cancel('RequestCancellation');
    this.cancellationToken = axios.CancelToken.source();
    if (this.config) this.config.cancelToken = this.cancellationToken.token;
  }

  getConsolidatedPlacementDetails = (body: ConsolidatedPlacementPayload) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/profile/details`, body)
      .catch((error: AxiosError) => error.response);

  getPlacementDocuments = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/doc`)
      .catch((error: AxiosError) => error.response);

  getComplianceStatusNotes = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/compliance-status-notes`)
      .catch((error: AxiosError) => error.response);

  getComplianceStateOrderRequirement = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/detail/skillset-groups?placementOrderType=Default`)
      .catch((error: AxiosError) => error.response);

  getComplianceStateNoteCertifications = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/detail/order-certifications?placementOrderType=Default`)
      .catch((error: AxiosError) => error.response);

  getComplianceStateNoteDocdeadline = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/docdeadline`)
      .catch((error: AxiosError) => error.response);

  getComplianceStateNoteComments = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/placementcomment`)
      .catch((error: AxiosError) => error.response);

  saveComplianceStateNoteDocdeadline = (updateDocDeadline: IUpdateDocDeadline) =>
    baseAxiosInstance
      .patch(`${this.placementBase}/placement/update/docdeadline`, updateDocDeadline)
      .catch((error: AxiosError) => error.response);

  saveComplianceStatusNote = (newNote: ICSPNote) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/update/compliance-status-notes`, newNote)
      .catch((error: AxiosError) => error.response);

  updateComplianceStatusNotesByPlacementId = (updatedNotes: IUpdateComplianceStatusNotes) =>
    baseAxiosInstance
      .put(`${this.placementBase}/placements/update/compliance-status-user-note`, updatedNotes)
      .catch((error: AxiosError) => error.response);

  updateComplianceStatusCommentByPlacementId = (updatedComment: IUpdateComplianceStatusComments) =>
    baseAxiosInstance
      .patch(`${this.placementBase}/placement/update/placementcomment`, updatedComment)
      .catch((error: AxiosError) => error.response);

  getNewPlacementValidations = (
    body: {
      candidateId: number;
      brandId: number;
      orderId: number;
      facilityId: number;
      recruiterId?: number;
      availabilityDate?: string;
    },
    cancelToken: CancelToken,
  ) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/validate`, body, { cancelToken })
      .catch((error: AxiosError) => error.response);

  saveWFCPlacements = body =>
    baseAxiosInstance.post(`${this.placementBase}/placement/save`, body).catch((error: AxiosError) => error.response);

  updatePlacementDetails = body =>
    baseAxiosInstance.post(`${this.placementBase}/placement/update`, body).catch((error: AxiosError) => error.response);

  updateStatus = (body: {
    placementId: number;
    activityTypeId: number;
    activityStatusId: number;
    userId: number;
    placementOrderType: PlacementOrderType;
    candidateId: number;
  }) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/update/status`, body)
      .catch((error: AxiosError) => error.response);

  getPlacementTimestamp = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/timestamp-details`)
      .catch((error: AxiosError) => error.response);

  validatePlacementTimestamp = (data: { placementId: number; timestamp: string }) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/validate/timestamp`, data)
      .catch((error: AxiosError) => error.response);

  getPlacementProgressStatus = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/progress-bar`)
      .catch((error: AxiosError) => error.response);

  getPlacementKeyContacts = (id: number) =>
    baseAxiosInstance.get(`${this.placementBase}/placement/${id}/contact`).catch((error: AxiosError) => error.response);

  getActivePlacementsByRecruiterById = (recruiterId: number) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placements/recruiter/work-desk`, { recruiterIds: recruiterId })
      .catch((error: AxiosError) => error.response);

  getPlacementQuickGlanceTiles = (id: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${id}/quick-tiles`)
      .catch((error: AxiosError) => error.response);

  getPlacementHeader = (id: number) =>
    baseAxiosInstance.get(`${this.placementBase}/placement/${id}`).catch((error: AxiosError) => error.response);

  getCandidatePlacementCountByPid = (id: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${id}/detail/candidate-placement-counts`)
      .catch((error: AxiosError) => error.response);

  getCandidateSellingPointsByPid = (id: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${id}/detail/candidate-selling-points`)
      .catch((error: AxiosError) => error.response);

  getPlacementStatusDetails = (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/detail/status?placementOrderType=${placementOrderType}`)
      .catch((error: AxiosError) => error.response);

  getCandidateVerifiedSkillsetsByPid = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/verified-skillset`)
      .catch((error: AxiosError) => error.response);

  getCandidateTimeOffRequestsByPid = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/time-off`)
      .catch((error: AxiosError) => error.response);

  getLandGtgDetails = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/lands`)
      .catch((error: AxiosError) => error.response);

  getPlacementChangeHistory = (body: {
    entityId: string;
    pageSize: number;
    pageNumber: number;
    sortColumn: string;
    sortOrder: string;
  }) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/change-history`, body)
      .catch((error: AxiosError) => error.response);

  getPlacementOrderType = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/placement-order-type`)
      .catch((error: AxiosError) => error.response);

  getPlacementSummaryApplication_AmnReq = (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(
        `${this.placementBase}/placement/${placementId}/detail/amn-application?placementOrderType=${placementOrderType}`,
      )
      .catch((error: AxiosError) => error.response);

  getPlacementSummaryStatus_OrderReq = (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(
        `${this.placementBase}/placement/${placementId}/detail/order-status?placementOrderType=${placementOrderType}`,
      )
      .catch((error: AxiosError) => error.response);

  getPlacementSummaryFacilityStatus_OrderReq = async (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/detail/facility-status`)
      .catch((error: AxiosError) => error.response);

  getPlacementSummaryDOBStatus_OrderReq = async (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/detail/order-dob?placementOrderType=${placementOrderType}`)
      .catch((error: AxiosError) => error.response);

  getPlacementSummarySsnStatus_OrderReq = async (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/detail/order-ssn?placementOrderType=${placementOrderType}`)
      .catch((error: AxiosError) => error.response);

  getPlacementSummaryGroupedSkillsetReq = async (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(
        `${this.placementBase}/placement/${placementId}/detail/skillset-groups?placementOrderType=${placementOrderType}`,
      )
      .catch((error: AxiosError) => error.response);

  _getPlacementSummaryGroupedSkillsetReq = async (placementId: number, placementOrderType: PlacementOrderType) =>
    GetSampleResponse({ status: 200, data: skillRequirementData });
  // baseAxiosInstance
  // .get(
  //   `${this.placementBase}/placement/${placementId}/detail/skillset-groups?placementOrderType=${placementOrderType}`,
  // )
  // .catch((error: AxiosError) => error.response);

  getPlacementSummaryAudit_AmnReq = (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/detail/amn-audit?placementOrderType=${placementOrderType}`)
      .catch((error: AxiosError) => error.response);

  getPlacementSummaryEducation_AmnReq = (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(
        `${this.placementBase}/placement/${placementId}/detail/amn-education?placementOrderType=${placementOrderType}`,
      )
      .catch((error: AxiosError) => error.response);

  getPlacementSummaryExperience_Req = (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(
        `${this.placementBase}/placement/${placementId}/detail/submission-experience?placementOrderType=${placementOrderType}`,
      )
      .catch((error: AxiosError) => error.response);

  getPlacementSummaryReferences_AmnReq = (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(
        `${this.placementBase}/placement/${placementId}/detail/amn-references?placementOrderType=${placementOrderType}`,
      )
      .catch((error: AxiosError) => error.response);

  getPlacementSummarySkillSet_Req = (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(
        `${this.placementBase}/placement/${placementId}/detail/submission-skillset?placementOrderType=${placementOrderType}`,
      )
      .catch((error: AxiosError) => error.response);

  getPlacementSummaryStatus_AmnReq = (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/detail/amn-status?placementOrderType=${placementOrderType}`)
      .catch((error: AxiosError) => error.response);

  getPlacementOrderInformation = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/detail/order`)
      .catch((error: AxiosError) => error.response);

  getPlacementAssignmentInformation = async (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/assignment-extensions`)
      .catch((error: AxiosError) => error.response);

  getPlacementSummaryDates_OrderReq = (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/detail/order-dates?placementOrderType=${placementOrderType}`)
      .catch((error: AxiosError) => error.response);

  getPlacementSummarySchedule_OrderReq = (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(
        `${this.placementBase}/placement/${placementId}/detail/order-schedule?placementOrderType=${placementOrderType}`,
      )
      .catch((error: AxiosError) => error.response);

  getPlacementSummaryReference_OrderReq = (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(
        `${this.placementBase}/placement/${placementId}/detail/order-reference?placementOrderType=${placementOrderType}`,
      )
      .catch((error: AxiosError) => error.response);

  getPlacementSummaryLicense_OrderReq = (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(
        `${this.placementBase}/placement/${placementId}/detail/order-licenses?placementOrderType=${placementOrderType}`,
      )
      .catch((error: AxiosError) => error.response);

  getPlacementSummaryCertification_OrderReq = (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(
        `${this.placementBase}/placement/${placementId}/detail/order-certifications?placementOrderType=${placementOrderType}`,
      )
      .catch((error: AxiosError) => error.response);

  getPlacementSummaryMilesRadius_OrderReq = (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(
        `${this.placementBase}/placement/${placementId}/detail/order-mile-radius?placementOrderType=${placementOrderType}`,
      )
      .catch((error: AxiosError) => error.response);

  refreshPlacementDrivingDistance = (body: { placementId: number; placementOrderType: PlacementOrderType }) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/detail/candidate/shortest/distance`, body)
      .catch((error: AxiosError) => error.response);

  getPlacementSummaryMiscDetails_OrderReq = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/detail/order-misc-detail`)
      .catch((error: AxiosError) => error.response);

  getPlacementSummaryCovid_OrderReq = (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/detail/order-covid?placementOrderType=${placementOrderType}`)
      .catch((error: AxiosError) => error.response);

  // calling mock service with no real data
  getPlacementSummaryDocuments_OrderReq = (placementId: number) => GetPlacementSummaryDocuments_OrderReq();

  getPlacementSummarySkillsChecklist = (placementId: number, orderType: string = PlacementOrderType.Default) =>
    baseAxiosInstance
      .get(
        `${this.placementBase}/placement/${placementId}/detail/submission-skills-checklist?placementOrderType=${orderType}`,
      )
      .catch((error: AxiosError) => error.response);

  getPlacementDetailWorkHistory = (placementId: number, orderType: string = PlacementOrderType.Default) =>
    baseAxiosInstance
      .get(
        `${this.placementBase}/placement/${placementId}/detail/submission-work-history?placementOrderType=${orderType}`,
      )
      .catch((error: AxiosError) => error.response);

  getTransportationPicklist = () =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/pick-list/transportation?placementOrderType=StrikeOrderInHsg`)
      .catch((error: AxiosError) => error.response);

  getAirportCodesPicklist = () =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/pick-list/airport-codes?placementOrderType=StrikeOrderInHsg`)
      .catch((error: AxiosError) => error.response);

  getTravelTimePreferencePicklist = () =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/pick-list/travel-time-preferences?placementOrderType=StrikeOrderInHsg`)
      .catch((error: AxiosError) => error.response);

  getAuditDetails = (candidateId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/candidate/${candidateId}/audit?placementOrderType=${placementOrderType}`)
      .catch((error: AxiosError) => error.response);

  getReferenceAndEmploymentVerficationRecords = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/reference`)
      .catch((error: AxiosError) => error.response);

  getStrikeRequirementsByContainer = (placementId: number, container: string, subContainer: string) =>
    baseAxiosInstance
      .get(
        `${this.placementBase}/placement/${placementId}/detail/strike?container=${container}&subContainer=${subContainer}`,
      )
      .catch((error: AxiosError) => error.response);

  getBookingRequirements = (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(
        `${this.placementBase}/placement/${placementId}/detail/booked-status?placementOrderType=${placementOrderType}`,
      )
      .catch((error: AxiosError) => error.response);

  getBookingApprovedRequirements = (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(
        `${this.placementBase}/placement/${placementId}/detail/booking-approved-status?placementOrderType=${placementOrderType}`,
      )
      .catch((error: AxiosError) => error.response);

  getWaitingGoodToGoRequirements = (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/detail/wgtg-status?placementOrderType=${placementOrderType}`)
      .catch((error: AxiosError) => error.response);

  getArbitrationAgreementRequirements = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/arbitration-status`)
      .catch((error: AxiosError) => error.response);

  getStrikeStatus = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/detail/strike-status`)
      .catch((error: AxiosError) => error.response);

  getExtensionStatusDetails = (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/extension-status?placementOrderType=${placementOrderType}`)
      .catch((error: AxiosError) => error.response);

  updateExtensionStatus = body =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/extensions/status`, body)
      .catch((error: AxiosError) => error.response);

  createNewExtensionDetails = (body: IExtensionRequest) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/extensions`, body)
      .catch((error: AxiosError) => error.response);

  updateExtensionDetails = (body: IExtensionUpdate) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/extensions/update`, body)
      .catch((error: AxiosError) => error.response);

  getExtTimeOffPayerOpts = () =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/extension/time-off-payer`)
      .catch((error: AxiosError) => error.response);

  getExtDirectHireOpts = () =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/extension/direct-hire-types`)
      .catch((error: AxiosError) => error.response);

  getSubmissionsPackets = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/doc/efo`)
      .catch((error: AxiosError) => error.response);

  /** End point is used to save document from Packetgen to the cosomos container */
  saveSubmissionPacketsToCosmos = (body: ISubmitPacketCommand) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/save-doc`, body)
      .catch((error: AxiosError) => error.response);

  updateEfoDocToOkToSend = (body: {
    placementId: number;
    documentId: string;
    placementOrderType: PlacementOrderType;
    timestamp: string;
    userId: number;
    userName: string;
  }) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/update/efo-doc/ok-to-send/status`, body)
      .catch((error: AxiosError) => error.response);

  getVirtualInterviewRequirement = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/virtual-interview`)
      .catch((error: AxiosError) => error.response);

  updateKeyContact = body =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/update/key-contact`, body)
      .catch((error: AxiosError) => error.response);

  updatePlacementRegion = body =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/update/location-region`, body)
      .catch((error: AxiosError) => error.response);

  getPlacementSupervisor = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/supervisor`)
      .catch((error: AxiosError) => error.response);

  getPlacementSupervisee = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/supervisee`)
      .catch((error: AxiosError) => error.response);

  deleteSupervision = (placementId: number) =>
    baseAxiosInstance
      .delete(`${this.placementBase}/placement/${placementId}/delete-supervisor`)
      .catch((error: AxiosError) => error.response);

  likSupervisionPID = body =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/add-placement-supervisor`, body)
      .catch((error: AxiosError) => error.response);

  getSupervision = (id: number) =>
    baseAxiosInstance.get(`${this.placementBase}/placement/${id}`).catch((error: AxiosError) => error.response);

  getFacilityDefaultTemplate = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/facility-template`)
      .catch((error: AxiosError) => error.response);

  getClientConfirmationDocs = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/client-confirmation/get`)
      .catch((error: AxiosError) => error.response);

  getClientConfirmationTemplateDraft = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/client-confirmation/draft/get`)
      .catch((error: AxiosError) => error.response);

  saveClientConfirmationTemplateDraft = body =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/client-confirmation/draft`, body)
      .catch((error: AxiosError) => error.response);

  copyClientConfirmationTemplateDraft = body =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/client-confirmation/copy`, body)
      .catch((error: AxiosError) => error.response);

  deleteClientConfirmationTemplateDraft = (body: { placementId: number; userId: number; userName: string }) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/client-confirmation/draft/delete`, body)
      .catch((error: AxiosError) => error.response);

  publishConfirmationDocTokens = body =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/document/confirmation/token/publish`, body)
      .catch((error: AxiosError) => error.response);

  previewConfirmationDoc = body =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/document/confirmation/preview`, body)
      .catch((error: AxiosError) => error.response);

  createConfirmationDoc = body =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/document/confirmation/save`, body)
      .catch((error: AxiosError) => error.response);

  previewContractDoc = body =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/document/contract/preview`, body)
      .catch((error: AxiosError) => error.response);

  createContractDoc = (body: IUserPayload & IContractDocWritePayload & { isDraftManualEdit: boolean }) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/document/contract/save`, body)
      .catch((error: AxiosError) => error.response);

  getContacts = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/client-confirmation/contacts`)
      .catch((error: AxiosError) => error.response);

  getPlacementTags = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/tags/${placementId}`)
      .catch((error: AxiosError) => error.response);

  updatePlacementTags = body =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/tags/update`, body)
      .catch((error: AxiosError) => error.response);

  updateClientConfirmationDocStatus = (body: {
    placementId: number;
    documentId: string;
    userId: number;
    userName: string;
  }) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/client-confirmation/update/status/sent`, body)
      .catch((error: AxiosError) => error.response);

  updateCredentialingDocStatus = (body: {
    placementId: number;
    documentId: string;
    userId: number;
    userName: string;
  }) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/document/credentialing-packet/update`, body)
      .catch((error: AxiosError) => error.response);

  getPlacementContracts = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/contracts`)
      .catch((error: AxiosError) => error.response);

  generatePlacementContractDraft = (body: { placementId: number; userId: number; userName: string }) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/contract-template/draft`, body)
      .catch((error: AxiosError) => error.response);

  deletePlacementContractDraft = (body: { placementId: number; userId: number; userName: string }) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/contract-template/draft/delete`, body)
      .catch((error: AxiosError) => error.response);

  deletePlacementContract = (body: { placementId: number; contractId: string; userId: number; userName: string }) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/candidate-contract/delete`, body)
      .catch((error: AxiosError) => error.response);

  savePlacementContractDraft = (body: {
    placementId: number;
    templateText: string;
    userId: number;
    userName: string;
  }) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/contract-template/draft/update`, body)
      .catch((error: AxiosError) => error.response);

  updatePlacementShift = (body: { placementId: number; shiftId: number }) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/update/shift`, body)
      .catch((error: AxiosError) => error.response);

  createClassicRequestContract = (body: {
    placementId: number;
    externalSystemId?: number;
    userId: number;
    userName: string;
  }) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/candidate-contract/requestContract`, body)
      .catch((error: AxiosError) => error.response);

  publishPlacementContract = (body: {
    placementId: number;
    contractId: string;
    actionType: string;
    userId: number;
    userName: string;
  }) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/candidate-contract/publish`, body)
      .catch((error: AxiosError) => error.response);

  getPlacemenChangeId = (body: {
    copyPlacementOption: string;
    startDate: string;
    endDate: string;
    oldPlacementID: number;
    copyPlacementFacilityID: string;
    copyPlacementOrderID: string;
    currentEmployeeId: number;
  }) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/copy-placement`, body)
      .catch((error: AxiosError) => error.response);

  getSubmissionPacketDetails = (placementId: number, placementOrderType: PlacementOrderType) =>
    baseAxiosInstance
      .get(
        `${this.placementBase}/placement/${placementId}/detail/submission/packet-status?placementOrderType=${placementOrderType}`,
      )
      .catch((error: AxiosError) => error.response);

  getClassicPacketInOkToSendExists = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/${placementId}/detail/ok-to-send/packet-status`)
      .catch((error: AxiosError) => error.response);

  getReadyToSubmitUnitPlacementIds = (orderIds: number[], activityStatus: number) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/orders/validate`, { orderIds, activityStatus })
      .catch((error: AxiosError) => error.response);

  getCandidateSkillsForPlacementCreation = (candidateId: number) =>
    baseAxiosInstance
      .get(`${this.placementBase}/placement/candidate/${candidateId}/skillset-details`)
      .catch((error: AxiosError) => error.response);

  postDirectHireDetail = (data: IUpdateDirectHire) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/update/direct-hire`, data)
      .catch((error: AxiosError) => error.response);

  getCueNotesEmployee = (payload: IRequestCueNotePayload) =>
    baseAxiosInstance
      .post(`${this.placementBase}/placement/cue/notes/employees`, payload)
      .catch((error: AxiosError) => error.response);

  updateOrderIdForPlacement = (data: IUpdateOrderId) =>
    baseAxiosInstance
      .put(`${this.placementBase}/placement/change-order`, data)
      .catch((error: AxiosError) => error.response);
}
