import { Box, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { selectGlobalBanner } from 'app/ApplicationRoot/Global.selectors';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Warning from 'app/assets/images/IconImages/warning.svg';
import { globalActions } from 'app/ApplicationRoot/Global.redux';

const useStyles = makeStyles()({
  wrapper: {
    padding: '18px 24px 0px 12px',
    marginTop: '-4px',
  },
  banner: {
    backgroundColor: '#FEF6EE',
    border: '1px solid #FFA22E',
    width: '100%',
    padding: '12px 0px',
  },
  icon: {
    marginRight: '12px',
  },
  grid: {
    height: '100%',
  },
  text: {
    color: '#663B01',
    fontSize: '14px',
    alignSelf: 'center',
  },
});

export const Banner = ({ global }) => {
  const { classes, cx } = useStyles();
  const globalBanner = useSelector(selectGlobalBanner);
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      dispatch(globalActions.closeBanner());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {globalBanner.open && (
        <div
          className={cx({
            [classes.wrapper]: global,
          })}
        >
          <Box className={classes.banner}>
            <Grid container justifyContent="center" direction={'row'} alignContent="center" className={classes.grid}>
              <img src={Warning} alt="warning" className={classes.icon} />
              <Grid item className={classes.text}>
                {globalBanner.message}
              </Grid>
            </Grid>
          </Box>
        </div>
      )}
    </React.Fragment>
  );
};
