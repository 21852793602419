import React from 'react';
import { FormControlLabel, Grid, Switch, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LocalBillrateBtn } from './LocalBillrateBtn';
import { TableBillRate } from './TableBillRate';
import { selectUser } from 'oidc/user.selectors';
import {
  selectOcbrDetails,
  selectRateFeatureProps,
  selectRateOpts,
} from 'store/redux-store/create-order-ocbr/selector';
import { createOrderOcbrActions } from 'store/redux-store/create-order-ocbr/slice';

const useStyles = makeStyles()(theme => ({
  headerTextStyle: {
    fontSize: 18,
    fontWeight: 500,
    paddingRight: 12,
  },
  textStyle: {
    marginRight: 24,
  },
  labelStyle: {
    padding: '0px 12px',
  },
}));

export const BillRatesWrapper = () => {
  const { billRatesTable } = useSelector(selectRateOpts);
  const { t } = useTranslation();
  const { isFlatRate, billRates } = useSelector(selectOcbrDetails);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const { uniqueShifts } = useSelector(selectRateFeatureProps);

  const { classes } = useStyles();
  return (
    <Grid container justifyContent="flex-start" xs={12}>
      <Grid item xs={12}>
        <Typography className="page-title">{t('order.newOrder.rateCard.billRates')}</Typography>
      </Grid>
      {(uniqueShifts ?? [])?.length > 1 && (
        <Grid container xs={12} justifyContent="flex-start" alignItems="center">
          <Typography classes={{ root: classes.textStyle }}>{t('order.newOrder.rateCard.flatRates')}</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={isFlatRate}
                onChange={e => {
                  dispatch(createOrderOcbrActions.setOcbrFlateRate(e.target.checked));
                  dispatch(createOrderOcbrActions.business_deriveShiftTableDetails());
                  dispatch(
                    createOrderOcbrActions.business_deriveRateSelections({ billRates, isFlatRate: e.target.checked }),
                  );
                }}
                name="isFlatRate"
                color="primary"
              />
            }
            label={isFlatRate ? t('global.Yes') : t('global.No')}
            classes={{ label: classes.labelStyle }}
          />
        </Grid>
      )}
      <Grid item xs={12} sx={{ display: 'grid' }} style={{ position: 'relative' }}>
        <TableBillRate billRatesArray={billRatesTable} isFlatRateToggle={isFlatRate} shiftsArray={uniqueShifts} />
      </Grid>
      <Grid item>
        <LocalBillrateBtn />
      </Grid>
    </Grid>
  );
};
