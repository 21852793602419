import React from 'react';
import { useTranslation } from 'react-i18next';
import { selectGlobalSnackBar } from 'app/ApplicationRoot/Global.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { IGlobalSnackBar } from 'app/models/Global/Global';
import { makeStyles } from 'tss-react/mui';
import { GenericSnackbar } from './GenericSnackbar';
import { Box, Modal } from 'amn-ui-core';

const useStyles = makeStyles()(theme => ({
  stackSnackbarContainer: {
    position: 'fixed',
    bottom: '0px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 9999,
  },
  stackSnackbar: {
    position: 'relative',
    marginBottom: theme.spacing(2),
    zIndex: 1 /* Base z-index */,
    ':nth-child(1)': {
      zIndex: 2 /* Increase z-index for the first Snackbar */,
    },
    ':nth-child(2)': {
      zIndex: 3 /* Increase z-index for the second Snackbar */,
    },
    boxShadow: '0px 5px 10px',
    borderRadius: '4px',
  },
  boxModal: {
    position: 'fixed',
    bottom: '0px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1202,
    bgcolor: 'transparent',
    outline: 0,
    opacity: 1,
  },
}));

const StackSnackBar = () => {
  const { t } = useTranslation();
  const { messageQueues } = useSelector(selectGlobalSnackBar);
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const handleSnackClose = (keyData, onSnackClose) => {
    dispatch(
      globalActions.closeStackSnackBar({
        id: keyData,
        message: '',
        severity: null,
      }),
    );
    onSnackClose && onSnackClose();
  };

  const handleCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
  };

  return (
    <div className={classes.stackSnackbarContainer}>
      {messageQueues?.length !== 0 &&
        messageQueues?.map((messageQueue: IGlobalSnackBar) => {
          if (messageQueue?.actions && messageQueue?.actions?.length !== 0) {
            return (
              <Modal
                key={`${messageQueue?.id}-${messageQueue?.message}`}
                open={!!messageQueue?.message}
                onClose={handleCloseModal}
              >
                <Box className={classes.boxModal}>
                  <GenericSnackbar
                    key={`${messageQueue?.id}-${messageQueue?.message}`}
                    id={messageQueue?.id}
                    snackOpen={!!messageQueue?.message}
                    message={t(messageQueue?.message)}
                    severity={messageQueue?.severity}
                    variant={messageQueue?.variant || 'dark'}
                    className={`${classes.stackSnackbar} ${messageQueue?.className && messageQueue?.className}`}
                    actions={messageQueue?.actions}
                    clickAwayProps={messageQueue?.clickAwayProps}
                    disableIcon={messageQueue?.disableIcon}
                    horizontal="center"
                    vertical="bottom"
                    onSnackClose={() => handleSnackClose(messageQueue?.id, messageQueue.onSnackClose)}
                  />{' '}
                </Box>
              </Modal>
            );
          }

          return (
            <GenericSnackbar
              key={`${messageQueue?.id}-${messageQueue?.message}`}
              id={messageQueue?.id}
              snackOpen={!!messageQueue?.message}
              message={t(messageQueue?.message)}
              severity={messageQueue?.severity}
              variant={messageQueue?.variant || 'dark'}
              className={`${classes.stackSnackbar} ${messageQueue?.className && messageQueue?.className}`}
              actions={messageQueue?.actions}
              clickAwayProps={messageQueue?.clickAwayProps}
              disableIcon={messageQueue?.disableIcon}
              horizontal="center"
              vertical="bottom"
              onSnackClose={() => handleSnackClose(messageQueue?.id, messageQueue.onSnackClose)}
            />
          );
        })}
    </div>
  );
};

export default StackSnackBar;
