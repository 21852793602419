import React from 'react';
import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  value: {
    fontSize: 12,
    color: '#003E68',
    fontWeight: 500,
    paddingRight: '10%',
    width: '100%',
  },
  container: {
    maxHeight: '30px',
  },
}));

export const ValueSpace = props => {
  const { value, styles } = props;
  const { classes } = useStyles();
  const valueStyle = `${styles} ${classes.value}`;
  return (
    <Grid container className={classes.container}>
      <Grid data-testid="test-value" item md={12}>
        <div className={valueStyle}>{value}</div>
      </Grid>
    </Grid>
  );
};
