import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { NonStandardRequirementType } from 'app/models/Unit/CreateUnit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InnerIconValueSection } from '../../../OrderCreationForm/DefineUnit/UnitPreview/Common/InnerIconValueSection';
import { ValueContainer } from '../../../OrderCreationForm/DefineUnit/UnitPreview/Common/ValueContainer';
import EditIcon from '@mui/icons-material/Edit';
import {
  PreviewContent,
  PreviewContentTitle,
  ContentDivider,
} from '../../../OrderCreationForm/DefineUnit/UnitPreview/UnitPreviewDesign';

interface Props {
  data: {
    nonStandardReq: NonStandardRequirementType[] | null;
  };
  disableEdit?: boolean;
  handleEditOrder?: () => void;
}

const useStyles = makeStyles()({
  container: {
    '&:hover': {
      '& [data-id="edit-icon"]': {
        opacity: 1,
        transition: 'opacity 0.25s',
      },
    },
  },
  editItem: {
    opacity: 0,
    transition: 'opacity 0.25s',
  },
  editIcon: {
    height: '20px',
    width: '20px',
    cursor: 'pointer',
    color: '#8E9195',
  },
});

export const OrderDetailsNonStandardRequirement = (props: Props) => {
  const { nonStandardReq } = props.data;
  const { disableEdit, handleEditOrder } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <PreviewContent variant="outlined" elevation={0}>
          <Grid container direction="column" spacing={3} className={classes.container}>
            <Grid item>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <PreviewContentTitle>{t('order.createUnit.nonStandardRequirement.title')}</PreviewContentTitle>
                </Grid>
                <Grid item className={classes.editItem} data-id="edit-icon">
                  {!disableEdit ? (
                    <EditIcon
                      id={'OrderDetailsNonStandardRequirementEditTrigger'}
                      className={classes.editIcon}
                      onClick={handleEditOrder}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {nonStandardReq &&
              nonStandardReq.length > 0 &&
              nonStandardReq.map((item, index) => (
                <>
                  <InnerIconValueSection
                    id={'OrderDetailsNonStandardRequirementIsRequiredLbl_' + index}
                    isRequired={item.isRequired}
                  />
                  <Grid item>
                    <ValueContainer
                      labelContent={[
                        { label: t('order.createUnit.nonStandardRequirement.category'), size: 3 },
                        {
                          label: t('order.createUnit.nonStandardRequirement.nameOfNonStandardSkill'),
                          size: 6,
                        },
                        {
                          label: item.yearsOfExperience
                            ? t('order.createUnit.nonStandardRequirement.yearsOfExperienceTitle')
                            : '',
                          size: 3,
                        },
                      ]}
                      valueContent={[
                        { value: item.category, size: 3 },
                        { value: item.value, size: 6, docIcon: item.documentAttachmentRequired },
                        {
                          value: item.yearsOfExperience ? item.yearsOfExperience.toString() : '',
                          size: 3,
                        },
                      ]}
                      id={'OrderDetailsNonStandardRequirementContainer_' + index}
                    />
                  </Grid>
                  {nonStandardReq && index !== nonStandardReq.length - 1 && <ContentDivider />}
                </>
              ))}
          </Grid>
        </PreviewContent>
      </Grid>
    </>
  );
};
