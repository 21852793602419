import { Grid, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(theme => ({
  header: {
    color: '#52429A',
    backgroundColor: '#fff',
  },
  title: {
    paddingTop: '20px',
  },
}));
export const MyPlacementsHeader = props => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.header} justifyContent="flex-start">
        <Grid container direction="row" justifyContent="flex-start">
          <Grid item xs={12} className={classes.title}>
            <Typography variant="h5">{t('PLACEMENTS')}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
