import { Button, Popover, Tooltip } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles/styleVariables';

const useStyles = makeStyles<{ maxHeight: string | number }>()((theme, { maxHeight }) => ({
  content: { overflow: 'hidden', textOverflow: 'ellipsis', maxHeight: maxHeight },
  contentTransition: { transition: 'max-height 4s' },
  button: {
    fontSize: 12,
    color: colors.amnBlue,
    textTransform: 'none',
    '&:hover, &:focus': { background: 'none' },
    padding: '6px 0',
  },
  paper: { padding: 12, fontSize: 12 },
}));

export const VerticalContentTooltip = ({ value, maxHeight }) => {
  const { classes } = useStyles({ maxHeight });
  const { t } = useTranslation();
  const textElementRef = React.useRef<any>(null);
  const [hoverStatus, setHover] = React.useState(false);
  /** truncateState === null implies text is within maxHeight limits */
  const [truncateState, setTruncateState] = React.useState<Boolean | null>(true);
  const compareSize = () => {
    if (textElementRef.current) setHover(textElementRef.current.scrollHeight > textElementRef.current.offsetHeight);
  };
  /** compare once and add resize listener on "componentDidMount" */
  React.useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
    return () => {
      window.removeEventListener('resize', compareSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return (
    <>
      <Tooltip title={value} arrow disableHoverListener={!hoverStatus || !truncateState}>
        <div ref={textElementRef} className={truncateState ? classes.content : undefined}>
          {value}
        </div>
      </Tooltip>
      {truncateState !== null ? (
        <Button
          variant="text"
          disableRipple
          disableFocusRipple
          disableTouchRipple
          classes={{ text: classes.button }}
          onClick={() => setTruncateState(prevState => !prevState)}
        >
          {truncateState ? t('common.viewMore') : t('common.viewLess')}
        </Button>
      ) : null}
    </>
  );
};
interface IVerticalContentProps {
  value: any;
  maxHeight: number | undefined;
  forceTruncation?: boolean;
  setForceTruncation?: Function;
}

export const VerticalContentPopover: React.FC<IVerticalContentProps> = ({
  value,
  maxHeight,
  forceTruncation = true,
  setForceTruncation = undefined,
}) => {
  const { t } = useTranslation();
  const anchorElRef = React.useRef<any>(null);
  const [enablePopover, setEnablePopover] = React.useState(false);
  const [open, setOpen] = useState(false);
  const { classes } = useStyles({ maxHeight });
  /** truncateState === null implies text is within maxHeight limits */
  const [truncateState, setTruncateState] = React.useState<boolean | null>(true);
  const compareSize = () => {
    if (anchorElRef) setEnablePopover(anchorElRef?.current.scrollHeight > anchorElRef?.current.offsetHeight);
  };
  const handleViewBtnClick = () => {
    setForceTruncation ? setForceTruncation(!truncateState) : setTruncateState(prevState => !prevState);
    setOpen(false);
  };
  React.useEffect(() => {
    if (truncateState !== null) setTruncateState(forceTruncation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceTruncation]);
  React.useEffect(() => {
    /** Code: Ensures truncateState is set to null whle content is within maxHeight limits on init*/
    if (
      anchorElRef?.current &&
      anchorElRef?.current.scrollHeight === anchorElRef?.current.offsetHeight &&
      truncateState
    )
      setTruncateState(null);
    else compareSize();
  }, [truncateState]);
  React.useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
    return () => {
      window.removeEventListener('resize', compareSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
      <div
        ref={anchorElRef}
        className={truncateState ? `${classes.content} ${classes.contentTransition}` : classes.contentTransition}
        /** Code: Popver can never be opened again once truncateState == null or !enablePopover */
        onMouseOver={() => setOpen(truncateState !== null && enablePopover)}
      >
        {value}
      </div>
      {truncateState && enablePopover ? (
        <Popover
          open={open}
          anchorEl={anchorElRef.current}
          PaperProps={{ onMouseLeave: () => setOpen(false), classes: { root: classes.paper } }}
        >
          {value}
        </Popover>
      ) : null}
      {truncateState !== null ? (
        <Button
          variant="text"
          disableRipple
          disableFocusRipple
          disableTouchRipple
          classes={{ text: classes.button }}
          onClick={handleViewBtnClick}
        >
          {truncateState ? t('common.viewMore') : t('common.viewLess')}
        </Button>
      ) : null}
    </>
  );
};
