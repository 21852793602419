import { Box, CircularProgress, Theme, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useEffect, useRef, useState } from 'react';
import { ContractDetailSection } from './ContractDetailSection';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectAdditionalContractDetails, selectContractDetails } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { usePromiseTracker } from 'react-promise-tracker';
import { ContractNotes, DirectHire, FeeAmounts, LocalRadiusRule } from './ViewContractDetails/ContractSections';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.system.smokeWhite,
    margin: 0,
    padding: 0,
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
  titleRoot: {
    paddingTop: '24px',
    overflow: 'auto',
  },
  rightPanel: {
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    minHeight: '300px',
    maxHeight: '500px',
  },
  expandedRightPanel: {
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    height: 'calc(100vh - 64px)',
  },
  textColorSelected: {
    color: theme.palette.system.violet,
    textAlign: 'left',
    font: 'normal normal medium 14px / 26px Roboto',
    paddingLeft: '30px',
    fontWeight: 'bolder',
    margin: 0,
  },
  textColorUnselected: {
    color: theme.palette.framework.system.doveGray,
    textAlign: 'left',
    font: 'normal normal medium 14px / 26px Roboto',
    paddingLeft: '30px',
    fontWeight: 'normal',
    margin: 0,
  },
  selected: {
    backgroundColor: theme.palette.system.violet,
    height: '100%',
    width: '5px',
  },
  unselected: {
    backgroundColor: theme.palette.system.smokeWhite,
    height: '100%',
    width: '5px',
  },
  tab: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  scrollSticky: {
    overflow: 'hidden',
  },
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '25vh',
  },
}));

export enum ContractDetailsWrapperTabsIndex {
  LOCAL_RADIUS_RULE = 0,
  DIRECT_HIRE = 1,
  FEE_AMOUNTS = 2,
  CONTRACT_NOTES = 3,
}

export const ContractDetailsWrapper = ({ contractId, isExpanded }: { contractId: number; isExpanded: boolean }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selected, setSelected] = useState<number>(ContractDetailsWrapperTabsIndex.LOCAL_RADIUS_RULE);
  const localRadiusRef = useRef<HTMLDivElement | null>(null);
  const dirHireRef = useRef<HTMLDivElement | null>(null);
  const feeAmountsRef = useRef<HTMLDivElement | null>(null);
  const conNotesRef = useRef<HTMLDivElement | null>(null);
  const contractItems = mapContractItems(t);
  const { promiseInProgress } = usePromiseTracker({ area: 'get-additional-contract-details', delay: 0 });
  const additionalContractDetails = useSelector(selectAdditionalContractDetails);
  const contractDetails = useSelector(selectContractDetails);

  const handleClick = (index: number) => {
    setSelected(index);
    switch (index) {
      case ContractDetailsWrapperTabsIndex.LOCAL_RADIUS_RULE:
        localRadiusRef?.current?.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case ContractDetailsWrapperTabsIndex.DIRECT_HIRE:
        dirHireRef?.current?.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case ContractDetailsWrapperTabsIndex.FEE_AMOUNTS:
        feeAmountsRef?.current?.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case ContractDetailsWrapperTabsIndex.CONTRACT_NOTES:
        conNotesRef?.current?.scrollIntoView({
          behavior: 'smooth',
        });
        break;
    }
  };

  useEffect(() => {
    if (contractId) dispatch(facilityActions.getAdditionalContractAction(contractId));
  }, [contractId]);

  return (
    <>
      <LayoutGrid container xs={12} className={classes.root}>
        <LayoutGridItem item xs={4} md={3} className={classes.scrollSticky} marginTop="12px">
          <LayoutGrid container direction="row" spacing={2}>
            {contractItems.map((item, index) => (
              <ContractDetailMenu
                key={item.index}
                index={index}
                title={item.title}
                selected={selected}
                handleClick={handleClick}
              />
            ))}
          </LayoutGrid>
        </LayoutGridItem>
        <LayoutGridItem item xs={8} md={9} className={isExpanded ? classes.expandedRightPanel : classes.rightPanel}>
          {!additionalContractDetails || promiseInProgress ? (
            <CircularProgress className={classes.MuiCircularProgressRoot} />
          ) : (
            <LayoutGrid
              container
              direction="column"
              data-testid="container"
              className={classes.titleRoot}
              rowSpacing={3}
            >
              <LayoutGridItem item data-testid={`contract-detail-${ContractDetailsWrapperTabsIndex.LOCAL_RADIUS_RULE}`}>
                <div ref={localRadiusRef}>
                  <ContractDetailSection
                    content={<LocalRadiusRule contractDetails={additionalContractDetails} />}
                    title={t('order.facility.rateCard.contractDetails.localRadiusRule.title')}
                    id={`contract-detail-data-${ContractDetailsWrapperTabsIndex.CONTRACT_NOTES}`}
                  />
                </div>
              </LayoutGridItem>
              <LayoutGridItem item data-testid={`contract-detail-${ContractDetailsWrapperTabsIndex.DIRECT_HIRE}`}>
                <div ref={dirHireRef}>
                  <ContractDetailSection
                    content={<DirectHire contractDetails={additionalContractDetails} />}
                    title={t('order.facility.rateCard.contractDetails.directHire.title')}
                    id={`contract-detail-data-${ContractDetailsWrapperTabsIndex.CONTRACT_NOTES}`}
                  />
                </div>
              </LayoutGridItem>
              <LayoutGridItem item data-testid={`contract-detail-${ContractDetailsWrapperTabsIndex.FEE_AMOUNTS}`}>
                <div ref={feeAmountsRef}>
                  <ContractDetailSection
                    content={<FeeAmounts contractDetails={contractDetails} />}
                    title={t('order.facility.rateCard.contractDetails.fees.title')}
                    id={`contract-detail-data-${ContractDetailsWrapperTabsIndex.FEE_AMOUNTS}`}
                  />
                </div>
              </LayoutGridItem>
              <LayoutGridItem item data-testid={`contract-detail-${ContractDetailsWrapperTabsIndex.CONTRACT_NOTES}`}>
                <div ref={conNotesRef}>
                  <ContractDetailSection
                    content={<ContractNotes data={additionalContractDetails?.notes} />}
                    title={t('order.facility.rateCard.contractDetails.contractNotes')}
                    id={`contract-detail-data-${ContractDetailsWrapperTabsIndex.CONTRACT_NOTES}`}
                  />
                </div>
              </LayoutGridItem>
            </LayoutGrid>
          )}
        </LayoutGridItem>
      </LayoutGrid>
    </>
  );
};

export const ContractDetailMenu = ({
  index,
  title,
  handleClick,
  selected,
}: {
  index: number;
  title: string;
  handleClick: Function;
  selected: number;
}) => {
  const { classes } = useStyles();

  return (
    <LayoutGridItem
      item
      xs={12}
      onClick={() => handleClick(index)}
      key={index}
      data-testid={`contract-detail-container-${index}`}
    >
      <LayoutGrid container direction="row" className={classes.tab}>
        <LayoutGridItem item style={{ padding: '0px' }}>
          <Box className={selected === index ? classes.selected : classes.unselected} />
        </LayoutGridItem>
        <LayoutGridItem item style={{ padding: '10px 0px' }}>
          <Typography
            className={selected === index ? classes.textColorSelected : classes.textColorUnselected}
            variant="subtitle2"
            component="div"
            data-testid={`contract-detail-title-${index}`}
          >
            {title}
          </Typography>
        </LayoutGridItem>
      </LayoutGrid>
    </LayoutGridItem>
  );
};

export const mapContractItems = t => [
  {
    index: ContractDetailsWrapperTabsIndex.LOCAL_RADIUS_RULE,
    title: t('order.facility.rateCard.contractDetails.localRadiusRule.title'),
  },
  {
    index: ContractDetailsWrapperTabsIndex.DIRECT_HIRE,
    title: t('order.facility.rateCard.contractDetails.directHire.title'),
  },
  {
    index: ContractDetailsWrapperTabsIndex.FEE_AMOUNTS,
    title: t('order.facility.rateCard.contractDetails.fees.title'),
  },
  {
    index: ContractDetailsWrapperTabsIndex.CONTRACT_NOTES,
    title: t('order.facility.rateCard.contractDetails.contractNotes'),
  },
];
