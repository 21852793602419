import { FormControl, InputLabel, MenuItem, Select } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import ArrowIcon from 'app/assets/images/Credentialing/PersonalInfo/arrow.svg';

const useStyles = makeStyles()({
  selectFocus: {
    backgroundColor: '#F7F7F7 !important',
  },
  dropdown: {
    marginTop: '-0.5em !important',
  },
});

export const CustomSelect = props => {
  const { classes } = useStyles();
  const { value: selected, options, label, onChange, name, onSelectedValue } = props;

  /** option is of type MenuItem */
  const handleChange = menuOption => {
    const selectedValue = options.find(item => item.value === menuOption.props.value);
    onChange(selectedValue);
    onSelectedValue?.(selectedValue);
  };

  const getLabel = React.useCallback(
    selectedValue => options.find(x => x.value === selectedValue)?.name ?? '',
    [options],
  );

  return (
    <FormControl variant="filled" fullWidth>
      <InputLabel style={{ pointerEvents: 'auto' }} shrink={true}>
        {label}
      </InputLabel>
      <Select
        {...props}
        inputProps={{ className: classes.selectFocus }}
        value={selected?.value ?? null}
        renderValue={getLabel}
        onChange={(e, option) => handleChange(option)}
        size={props.size ?? 'small'}
        MenuProps={{
          classes: { paper: classes.dropdown },
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          'aria-label': `create-placement-select-${name}`,
        }}
        IconComponent={iconProps => (
          <img
            src={ArrowIcon}
            style={{ marginRight: '10px', marginLeft: '10px', marginTop: '4px' }}
            alt="ArrowIcon"
            {...iconProps}
          />
        )}
      >
        {options?.map((option, index) => (
          <MenuItem value={option.value} key={index}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
