import React from 'react';
import Chip from '@mui/material/Chip';
import { makeStyles } from 'tss-react/mui';
import { Theme, Tooltip } from 'amn-ui-core';
import _debounce from 'lodash/debounce';

interface IResponsiveGridChip {
  title: string;
  isVisible?: boolean;
  canDelete?: boolean;
  onDelete?: (item: any) => any;
  onClick?: (item: any) => any;
  index?: number;
  [key: string]: any;
}

interface IResponsiveGridChips {
  chips: Array<IResponsiveGridChip>;
  disableTooltip?: boolean;
  tooltipFullWidth?: boolean;
  tooltipMaxWidth?: number;
  cellWidth: number;
}

interface IRemainingChipsCount {
  invisibleChips: Array<IResponsiveGridChip>;
  disableTooltip?: boolean;
  tooltipFullWidth?: boolean;
  tooltipMaxWidth?: number;
  count: number;
}

const useStyles = makeStyles<{ tooltipFullWidth?: boolean; tooltipMaxWidth?: number }>()(
  (theme: Theme, props: { tooltipFullWidth?: boolean; tooltipMaxWidth?: number }) => ({
    chips: {
      margin: '0 5px',
      fontSize: 14,
      fontWeight: 'normal',
      // '&:hover': {
      //   backgroundColor: theme.palette.system.lightblueBackground,
      //   color: theme.palette.system.blue,
      // },
    },
    chipsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    tooltip: {
      maxWidth: props?.tooltipFullWidth ? '100%' : 400,
      backgroundColor: theme.palette.components.tooltip.backgroundColor,
      border: `1px solid ${theme.palette.system.semiTransparentBlack}`,
      fontWeight: 'normal',
      '& .MuiTooltip-tooltip': {
        fontWeight: 'normal',
        maxWidth: props?.tooltipFullWidth ? '100%' : 400,
        backgroundColor: theme.palette.components.tooltip.backgroundColor,
        border: `1px solid ${theme.palette.system.semiTransparentBlack}`,
      },
    },
    arrow: {
      color: '#ffffff',
      opacity: 1,
      '&::before': {
        boxShadow: '0px 3px 6px #00000029',
        border: `1px solid ${theme.palette.system.semiTransparentBlack}`,
      },
    },
    countsChip: {
      fontSize: 14,
      color: theme.palette.components.chips.tags.additionalChip.color,
      fontWeight: 'normal',
      '&:hover': {
        backgroundColor: theme.palette.system.lightblueBackground,
        color: theme.palette.components.chips.tags.additionalChip.color,
      },
    },
    hiddenChips: {
      margin: '5px',
      fontSize: 14,
      fontWeight: 'normal',
      // '&:hover': {
      //   backgroundColor: theme.palette.system.lightblueBackground,
      //   color: theme.palette.system.blue,
      // },
    },
  }),
);

const ResponsiveGridChips = (props: IResponsiveGridChips) => {
  const { chips, cellWidth } = props;
  const [visibleChips, setVisibleChips] = React.useState<Array<IResponsiveGridChip>>([]);
  const [invisibleChips, setInvisibleChips] = React.useState<Array<IResponsiveGridChip>>([]);
  const previousWidth = React.useRef(cellWidth || 50);
  const [manualRender, setManualRender] = React.useState<boolean>(false);
  const chipRefs = React.useRef<Array<React.RefObject<HTMLDivElement>>>([]);
  const { classes } = useStyles({
    tooltipFullWidth: props?.tooltipFullWidth,
    tooltipMaxWidth: props?.tooltipMaxWidth,
  });

  React.useEffect(() => {
    //initialize the ref array with empty refs to avaoid undefined errors
    chipRefs.current = Array(props.chips.length)
      .fill(null)
      .map(() => React.createRef());
  }, [chips]);

  React.useEffect(() => {
    setVisibleChips(props.chips);
    setInvisibleChips([]);
    previousWidth.current = cellWidth;
  }, [chips, cellWidth]);
  // Identified a bug , if you scroll Verticall through the mouse roller in the grid , it causes a glitch in the scroll , the scroll gets stuck at the end and keeps jumping.
  // Need to find out the root cause of scroll bar breakage from mouse roller - cause (wrapper ref being called from the ref of the component itself while scrolling)
  // fixed it by adding debounce/delay to the callback
  const wrapperRef = React.useCallback(
    _debounce(node => {
      const ele = node || document.getElementById('chips-Wrapper');
      //previousWidth is used to avoid state fallback values from the props due to multiple re-rendering in the grid every time the grid's state changes
      const width = (cellWidth == previousWidth.current ? cellWidth : previousWidth.current) - 4;
      if (ele && ele.offsetWidth > width) {
        //set the initial visibleWidth to the width of +n chip
        let visibleWidth = 50;
        const visible = [];
        const invisible = [];
        chips.forEach((item, index) => {
          visibleWidth = visibleWidth + chipRefs.current[index].current?.offsetWidth;
          //find out which chip moves out of viewable area , pop it out to add the (+n chip)
          if (visibleWidth >= width && invisible.length === 0 && visible.length === 0) {
            visible.push(...chips.slice(0, Math.max(index, 0)));
            invisible.push(...chips.slice(Math.max(index, 0)));
          }
        });
        setVisibleChips(visible);
        setInvisibleChips(invisible);
      }
    }, 300),
    [cellWidth, chips, manualRender],
  );
  React.useEffect(() => {
    //this is used to manually re-trigger the above effect so as to not cause any render fallbacks
    //** NOTE: This flag is very important */
    setManualRender(prev => !prev);
    return () => {};
  }, [visibleChips, invisibleChips, cellWidth]);

  const CustomChip = (params: IResponsiveGridChip) => {
    return (
      <div ref={chipRefs.current[params.index]}>
        <Chip
          label={params?.title}
          onDelete={params?.canDelete && params?.onDelete}
          onClick={params?.onClick}
          className={classes.chips}
        />
      </div>
    );
  };

  const RemainingChipsCount = (args: IRemainingChipsCount) => {
    return (
      <div>
        <Tooltip
          title={
            <div>
              {args.invisibleChips?.map((ele, ind) => {
                return <Chip className={classes.hiddenChips} label={ele.title} key={ind} />;
              })}
            </div>
          }
          disableHoverListener={props?.disableTooltip}
          classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
          arrow
        >
          <Chip label={`+${args?.count}`} className={classes.countsChip} />
        </Tooltip>
      </div>
    );
  };

  return (
    <div className={classes.chipsWrapper} id="chips-Wrapper" ref={wrapperRef}>
      {visibleChips.map((item, index) => (
        <CustomChip {...item} index={index} key={index} />
      ))}
      {invisibleChips.length > 0 && (
        <RemainingChipsCount
          count={invisibleChips.length}
          invisibleChips={invisibleChips}
          disableTooltip={props?.disableTooltip}
        />
      )}
    </div>
  );
};

export default ResponsiveGridChips;
