/* eslint-disable i18next/no-literal-string */
import React, { useEffect, useState } from 'react';
import { usePageVisitTimeTracker } from 'app-insights/appInsightsTracking';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { CardBuilder, displayType } from './Cards/CardBuilder';
import { useTranslation } from 'react-i18next';
import { PersonalInfoForm } from './Form/PersonalInfoForm';
import EditIcon from '@mui/icons-material/EditOutlined';
import { IconButton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { TravelerPayrollXGrid } from './Grid/TravelerPayrollXGrid';
import {
  ff_candidate_ui_manage_365Data_Candidate_Payroll,
  ff_candidate_ui_manage_365Data_Tax_home_information,
} from 'app/constants/FeatureFlags/Candidate/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { CustomTooltip } from '@AMIEWEB/Common';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { candidateProfileDetailsActions } from 'store/redux-store/candidate-details/slice';
import { candidateDetailActions } from '../../Profile/CandidateDetails.redux';

const useStyles = makeStyles()(theme => ({
  wrapper: {
    padding: '12px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  fontTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  gridTitle: {
    alignItems: 'center',
    padding: '16px 12px 4px 12px',
    fontSize: '18px',
    fontWeight: 'bold',
  },
}));

export const PersonalInfoTab = props => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const [travelerPayrollFeature] = useDecision(ff_candidate_ui_manage_365Data_Candidate_Payroll);
  const [permanentTaxHomeInfo] = useDecision(ff_candidate_ui_manage_365Data_Tax_home_information);
  const handleClose = () => {
    setOpen(false);
    dispatch(candidateDetailActions.setPersonalInfoError([]));
  };
  usePageVisitTimeTracker('Candidate Details:Personal Information Tab');
  const dispatch = useDispatch();

  const params = useParams<{
    travelerId: string;
    brandId: string;
  }>();

  useEffect(() => {
    dispatch(
      candidateProfileDetailsActions.getCandidatePersonalInfoTabDetails({
        candidateId: parseInt(params.travelerId),
        brandId: parseInt(params.brandId),
      }),
    );
  }, []);

  return (
    <>
      <LayoutGrid container justifyContent="flex-start" direction="row" className={classes.wrapper} alignItems="center">
        <LayoutGridItem item xs={6}>
          <LayoutGrid container spacing={2} className={classes.fontTitle}>
            Information Details
          </LayoutGrid>
        </LayoutGridItem>
        <LayoutGridItem item>
          <LayoutGrid container spacing={2}>
            <CustomTooltip tooltipContent={'Edit'}>
              <IconButton
                onClick={e => {
                  setOpen(true);
                }}
                id="edit-personal-info"
                color="secondary"
                size="medium"
              >
                <EditIcon />
              </IconButton>
            </CustomTooltip>
            <PersonalInfoForm open={open} handleClose={handleClose} />
          </LayoutGrid>
        </LayoutGridItem>
      </LayoutGrid>
      <LayoutGrid container justifyContent="flex-start" direction="row">
        <LayoutGridItem item xs={4}>
          <LayoutGridItem item spacing={2}>
            <CardBuilder disType={displayType.personalInfo} title={t('candidate.personalInfoCard.title')} />
          </LayoutGridItem>
          <LayoutGridItem item spacing={2}>
            <CardBuilder
              disType={displayType.contactInfo}
              title={t('candidate.personalInfoTabLabels.contactInformation')}
            />
          </LayoutGridItem>
        </LayoutGridItem>
        <LayoutGridItem item xs={4}>
          <CardBuilder disType={displayType.address} title={t('candidate.personalInfoTabLabels.addressTitle')} />
        </LayoutGridItem>
        <LayoutGridItem item xs={4}>
          <LayoutGridItem item>
            <CardBuilder disType={displayType.emergency} title={t('candidate.personalInfoTabLabels.emergencyTitle')} />
          </LayoutGridItem>
          <LayoutGridItem item>
            <CardBuilder
              disType={displayType.applicationQuestion}
              title={t('candidate.personalInfoTabLabels.qnTitle')}
            />
          </LayoutGridItem>
          <LayoutGridItem item>
            <CardBuilder
              disType={displayType.caseManagement}
              title={t('candidate.personalInfoTabLabels.caseManagement')}
            />
          </LayoutGridItem>
        </LayoutGridItem>
      </LayoutGrid>
      <LayoutGrid container direction="column">
        {(permanentTaxHomeInfo.enabled || travelerPayrollFeature.enabled) && (
          <LayoutGridItem item>
            <LayoutGrid container spacing={2} className={classes.gridTitle}>
              {t('candidate.personalInfoTabLabels.payrollAccounting')}
            </LayoutGrid>
          </LayoutGridItem>
        )}
        <LayoutGridItem>
          <LayoutGrid container>
            {travelerPayrollFeature.enabled && (
              <LayoutGridItem xs={8}>
                <TravelerPayrollXGrid />
              </LayoutGridItem>
            )}
            {permanentTaxHomeInfo.enabled && (
              <LayoutGridItem xs={4}>
                <CardBuilder
                  disType={displayType.permanentTaxHomeInfo}
                  title={t('candidate.personalInfoTabLabels.permanentTaxHomeInfo')}
                />
              </LayoutGridItem>
            )}
          </LayoutGrid>
        </LayoutGridItem>
      </LayoutGrid>
    </>
  );
};
