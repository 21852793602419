import { Grid } from 'amn-ui-core';
import { ChipsContainer } from 'app/ComponentLibrary/Filter';
import { generateTaskChips } from 'app/components/Tasks/TaskManagement/TaskFilters/TaskFilterUtilitis';
import { IOptionsList } from 'app/models/Tasks/Tasks';
import React, { useEffect, useState } from 'react';
import { TypeAheadCustomComponent } from './TypeAheadCustomComponent';

/**
 * @param optionsList: Can pass any array of data format
 * !! Make sure your data set includes at least 3 properties on it !!
 * The value/defaultValue is based off of this id prop, so
 * properly pass the id value to the object so it sorts by that value
 * {
 *  (id: string): id of the list item
 *  (value: string): value associated to option
 *  (name: string): name of the list item
 * }
 *
 * @param selectAllValue: If the options list has an option which selects all the options specify the name of select all value
 *
 * @param setSelectedOptions: It is the react useState variable to retrieve the options selected
 *
 * @param helperText: Helper text or the error message to be shown
 *
 * @param errors: It indicates if error exists
 */

export const TypeAheadCustomWrapper = ({
  optionsList,
  selectAllValue,
  setSelectedOptions,
  selectedValues = [],
  helperText,
  errors,
  isDisabled,
}: {
  optionsList: IOptionsList[];
  selectedValues?: IOptionsList[];
  selectAllValue?: string;
  setSelectedOptions: (e) => void;
  helperText?: string;
  errors?: boolean;
  isDisabled?: boolean;
}) => {
  const [chips, setChips] = useState({});
  const [selectedOption, setSelectedOption] = useState<any[]>([]);
  const [optionsSelected, setOptionsSelected] = useState<any[]>([]);
  const [optionAttributes, setoptionAttributes] = useState<{ filterObject: [string, unknown][] }>();

  const dispatchChip = values => {
    setOptionsSelected(values);
    setSelectedOption(values.filter(f => f.value !== selectAllValue));
  };

  const handleChipDelete = (field, chip) => {
    const selectedChips = selectedOption?.filter(d => d.value !== chip?.value?.value);
    setOptionsSelected(selectedChips);
    setSelectedOption(selectedChips);
  };

  useEffect(() => {
    setSelectedOptions(selectedOption);
    setoptionAttributes({ filterObject: selectedOption });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  useEffect(() => {
    if (selectedValues?.length > 0) {
      dispatchChip(selectedValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues]);

  useEffect(() => {
    setChips(optionAttributes?.filterObject ? generateTaskChips(optionAttributes?.filterObject, false, {}) : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionAttributes]);

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TypeAheadCustomComponent
            enableCustomScroll
            removeCloseIcon
            isMultiSelect={true}
            size="small"
            selectAllValue={selectAllValue}
            value={optionsSelected}
            options={optionsList}
            onChange={dispatchChip}
            hiddenInput={false}
            isError={errors}
            helperText={helperText}
            isDisabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <ChipsContainer
            isDisabled={isDisabled}
            chips={chips}
            onClick={() => {}}
            onDelete={(field, chip) => handleChipDelete(field, chip)}
          />
          <button style={{ display: 'none' }} type="submit" />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
