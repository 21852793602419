import { theme } from 'styles/global-styles';
import { makeStyles } from 'tss-react/mui';

export const useSkillSetStyles = makeStyles()(theme => ({
  container: {
    border: `1px solid ${theme.palette.system.lightGrey}`,
    borderRadius: '4px',
    backgroundColor: theme.palette.system.white,
    '& .MuiFilledInput-root:hover.Mui-disabled:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)!important',
    },
    '& .MuiFilledInput-root.Mui-disabled': {
      backgroundColor: theme.palette.system.grayChip,
      opacity: '0.8',
    },
    '& .MuiFilledInput-root.Mui-disabled:before': {
      borderBottomStyle: 'solid',
    },
  },
  buttonContainer: {
    display: 'grid',
    justifyItems: 'center',
  },
}));

export const useSkillHeaderStyles = makeStyles()(theme => ({
  deleteSection: {
    color: theme.palette.framework.system.tertiaryGrey,
    padding: '10px',
    fontSize: '12px !important',
    '&:hover': {
      color: `${theme.palette.system.lightBlue}`,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  primarySkillSetText: {
    color: `${theme.palette.system.lightBlue}`,
  },
  fontParams: {
    fontWeight: 600,
    fontFamily: 'Roboto',
    paddingLeft: '4px !important',
  },
  font: {
    '&:hover': {
      color: `${theme.palette.system.lightBlue}`,
      cursor: 'pointer',      
    },
    fontSize: '12px !important',
    padding: '10px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    marginRight: '-10px',
    color: theme.palette.framework.system.tertiaryGrey,
  },
  copySection: {
    color: theme.palette.framework.system.tertiaryGrey,    
    fontSize: '12px !important',
    '&:hover': {
      color: `${theme.palette.system.lightBlue}`,
      cursor: 'pointer',
      textDecoration: 'underline',
    }
  },
  divider: {
    margin: '9px 3px',
    height: '25px'
  }
}));

export const useSkillSetSectionStyles = makeStyles()(theme => ({
  fieldStyle: {
    '& .MuiFilledInput-root': {
      backgroundColor: `${theme.palette.system.grayChip} !important`,
    },
    '&:hover .MuiFilledInput-root': {
      backgroundColor: `${theme.palette.system.silver} !important`,
    },
    '&:hover .MuiInputLabel-root': {
      color: `${theme.palette.system.primary} !important`,
    },
  },
  checkbox: {
    '& label': {
      '& .MuiButtonBase-root.MuiCheckbox-root.Mui-checked': {
        color: `${theme.palette.system.main} !important`,
      },
      '&:hover .MuiButtonBase-root.MuiCheckbox-root': {
        color: `${theme.palette.system.main} !important`,
      },
    },
  },
  disabled: {
    pointerEvents: 'none',
    '& label': {
      '& .MuiButtonBase-root.MuiCheckbox-root': {
        color: `${theme.palette.disabled.main} !important`,
      },
    },
    '& .MuiFormControlLabel-label': {
      color: `${theme.palette.system.buttonDarkGray}`,
    },
  },
}));

export const useTravelTileStyles = makeStyles()(theme => ({
  fieldStyle: {
    '& .MuiFilledInput-root': {
      backgroundColor: `${theme.palette.system.grayChip} !important`,
    },
    '&:hover .MuiFilledInput-root': {
      backgroundColor: `${theme.palette.system.silver} !important`,
    },
    '&:hover .MuiInputLabel-root': {
      color: `${theme.palette.system.primary} !important`,
    },
  },
  inputs: {
    width: '100%',
    overflow: 'auto',
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    '&:hover': {
      '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
        color: theme.palette.primary.main,
      },
    },
  },
  fieldLabel: {
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Roboto',
    color: theme.palette.framework.system.charcoal,
    letterSpacing: '0px',
    opacity: '1',
  },
  paper: {
    '& .MuiAutocomplete-listbox > li': {
      borderTop: `1px solid ${theme.palette.framework.system.silver}`,
    },
    '& .MuiAutocomplete-listbox > li:first-of-type': {
      borderTop: 'none',
      marginBottom: '0',
    },
  },
  checkbox: {
    '& label': {
      '& .MuiButtonBase-root.MuiCheckbox-root.Mui-checked': {
        color: `${theme.palette.system.main} !important`,
      },
      '&:hover .MuiButtonBase-root.MuiCheckbox-root': {
        color: `${theme.palette.system.main} !important`,
      },
    },
    '&:hover .MuiButtonBase-root.MuiCheckbox-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26) !important',
    },
  },
  disabled: {
    pointerEvents: 'none',
    '& label': {
      '& .MuiButtonBase-root.MuiCheckbox-root': {
        color: `${theme.palette.disabled.main} !important`,
      },
    },
    '& .MuiFormControlLabel-label': {
      color: `${theme.palette.system.buttonDarkGray}`,
    },
  },
  travelExperienceWrapper: {
    display: 'grid',
    gap: '12px',
  },
  actionButtons: {
    color: `${theme.palette.system.neutralGray}`,
    borderRadius: '20px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${theme.palette.system.skyBlue}`,
      color: `${theme.palette.system.main}`,
    },
  },
  labelMarginRight: {
    marginRight: '11px',
  },
}));

export const useUnitGridLicenseWrapperStyles = makeStyles()(theme => ({
  searchField: {
    height: '1%',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.system.lightGrey}`,
  },
  chip: {
    margin: '0 1% 1% 0',
  },
  incompleteChip: {
    margin: '0 1% 1% 0',
    border: `2px dashed ${theme.palette.framework.system.darkGray2}`,
    opacity: 0.7,
  },
  blue: {
    color: `${theme.palette.components.avatar.color} !important`,
    backgroundColor: theme.palette.primary.main,
  },

  wrapper: {
    backgroundColor: `${theme.palette.framework.system.white} !important`,
    border: `1px solid ${theme.palette.system.lightGrey}`,
    borderRadius: 5,
    minHeight: 300,
  },
  noBorder: {
    border: 'hidden',
  },
  chipWrapper: {
    paddingTop: '0.8%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  chipIcon: {
    fontSize: 'large',
    marginLeft: 6,
  },
  green: {
    color: `${theme.palette.components.avatar.color} !important`,
    backgroundColor: theme.palette.framework.chart.green,
  },
  radioStyles: {
    '&.Mui-checked': {
      color: `${theme.palette.system.main} !important`,
    },
    '&:hover': {
      color: `${theme.palette.system.main} !important`,
      backgroundColor: 'transparent !important',
    },
  },
}));

export const useUnitLicenseGridStyles = makeStyles()((theme, props) => ({
  root: {
    '& .MuiGrid-root': {
      margin: 0,
      width: 'auto',
    },
    '& .MuiGrid-root > .MuiGrid-item': {
      padding: 0,
    },
    '& .MuiDataGrid-root': {
      border: 'none',
      borderRadius: 0,
    },
    '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      border: 'none !important',
      outline: 'none !important',
      backgroundColor: 'transparent !important',
    },
  },
  licenseGrid: {
    opacity: 1,
  },
  overlay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    whiteSpace: 'nowrap',
  },
}));

export const useCertificationTileStyles = makeStyles()(theme => ({
  subCertificateError: {
    button: {
      borderBottom: `2px solid ${theme.palette.system.errorRed} !important`,
    },
    span: {
      fontSize: '0.75rem',
      marginLeft: '12px',
      fontWeight: '400',
      marginTop: '3px',
    },
  },
  subCertificate: {
    button: {
      height: '53px',
      border: '0px',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)!important',
      backgroundColor: `${theme.palette.system.smokeWhite} !important`,
      font: 'normal normal medium 14px / 26px Roboto',
      color: `${theme.palette.system.buttonDarkGray} !important`,
      fontSize: '14px',
      svg: {
        fontSize: '25px !important',
      },
      '&:hover': {
        border: '0px',
        backgroundColor: `${theme.palette.system.hoverGray} !important`,
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
        borderBottom: `1px solid ${theme.palette.system.main} !important`,
      },
      '&:disabled': {
        opacity: '0.6',
        pointerEvents: 'none',
        cursor: 'not-allowed',
        border: '0px',
      },
    },
  },
  checkbox: {
    '& label': {
      '& .MuiButtonBase-root.MuiCheckbox-root.Mui-checked': {
        color: `${theme.palette.system.main} !important`,
      },
      '&:hover .MuiButtonBase-root.MuiCheckbox-root': {
        color: `${theme.palette.system.main} !important`,
      },
    },
  },
  disabledField: {
    opacity: '0.6',
    pointerEvents: 'none',
    cursor: 'not-allowed !important',
  },
  disabled: {
    pointerEvents: 'none',
    '& label': {
      '& .MuiButtonBase-root.MuiCheckbox-root': {
        color: `${theme.palette.disabled.main} !important`,
      },
    },
    '& .MuiFormControlLabel-label': {
      color: `${theme.palette.system.buttonDarkGray}`,
    },
  },
  certificateWrapper: {
    display: 'grid',
    gap: '12px',
  },
  actionButtons: {
    color: `${theme.palette.system.neutralGray}`,
    borderRadius: '20px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${theme.palette.system.skyBlue}`,
      color: `${theme.palette.system.main}`,
    },
  },
  selectField: {
    minWidth: 500,
    zIndex: 1000,
  },
}));

export const radioCertificationTileStyles = {
  '&.Mui-checked': {
    color: `${theme.palette.system.main} !important`,
  },
  '&:hover': {
    color: `${theme.palette.system.main} !important`,
    backgroundColor: 'transparent !important',
  },
};

export const useAndOrButtonStyles = makeStyles()(theme => ({
  menuOption: {
    paddingLeft: '30px',
  },
  andOr: {
    fontSize: '13px',
    fontWeight: '600',

    height: '25px',
    alignItems: 'center',
    paddingLeft: '14px',
    display: 'flex',
  },
  popover: {
    padding: '20px 0px',
    width: '190px',
    backgroundColor: `${theme.palette.system.white}`,
    zIndex: '999',
    position: 'absolute',
    borderRadius: '4px',
    boxShadow: `${theme.palette.system.lightGrey} 0 2px 3px 1px`,
    marginTop: '-257px',
  },
  popoverOperation: {
    '& .MuiPaper-root': {
      padding: '5px 0px',
      width: '120px',
      marginTop: '-80px',
      marginLeft: '25px',
    },
  },
  dividerSwitch: {
    height: '12px',
    border: `1px solid ${theme.palette.system.main}`,
  },
  dividerPopover: {
    height: '12px',
    border: `1px solid ${theme.palette.system.lightGrey}`,
  },
  andOrButton: {
    height: '32px',
    borderRadius: '16px',
    '&:hover .expandIcon': {
      display: 'inline-block !important',
      marginTop: '20px',
    },
  },
  expandInitial: {
    display: 'none',
    transform: 'translateY(-50%) rotate(0deg)',
    transition: 'transform 0.3s ease',
  },
  expandFinal: {
    display: 'inline-block !important',
    transform: 'translateY(-50%) rotate(180deg)',
    marginTop: '20px',
  },
  disableOptions: {
    pointerEvents: 'none',
    opacity: '0.4',
  },
  buttonAdd: {
    width: '28px',
    height: '28px',
    minWidth: '28px',
    backgroundColor: `${theme.palette.system.main}`,
    '&:hover': {
      backgroundColor: `${theme.palette.system.lightSkyBlue}`,
    },
  },
  paper: {
    width: '250px',
    overflowX: 'hidden',
    overflowY: 'auto',
    boxShadow: '2px 5px 10px #0000004D',
  },
  listItem: {
    '& .MuiListItemText-root':{
      marginTop: 1,
      marginBottom: 1,
    },
    '& .MuiListItem-root': {
      padding: '0 20px',
      paddingLeft: 8,
    },
    '& .MuiListItemButton-root': {
      padding: '0 16px',
      paddingLeft: 8,
    },
    '& .MuiButtonBase-root': {
      padding: '0 20px',
    },
  },
  menuItem: {
    maxWidth: '200px',
    padding: '2',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const useCompactStatusStyles = makeStyles()({
  formControl: {
    minWidth: 180,
    fontWeight: 400,
  },
  disableField: {
    pointerEvents: 'none',
    opacity: '0.6',
    cursor: 'not-allowed',
    svg: {
      visibility: 'hidden',
    },
  },
});
