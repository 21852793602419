import { gridStateActions, GridTag } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import { selectCredPlacementsReminderGrid } from 'app/components/Common/WorkDesk/PipelineChoice/pipelineChoice.selectors';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WorkDeskGrid } from '../../WorkDesk/WorkDeskGrid';

export const PlacementRemindersList = props => {
  const pipelineSelection = useSelector(selectCredPlacementsReminderGrid);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      gridStateActions.setGridState({
        gridTag: GridTag.CredentialsPlacementRemindersGrid,
        pageSize: 20,
        pageNumber: 1,
        previewItemId: -1,
        showPreviewDialog: false,
        sortedColumn: { column: 'startDate', direction: 'asc' },
        filterAttributes: undefined,
      }),
    );
    return () => {
      dispatch(gridStateActions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <WorkDeskGrid
        tableColumns={props.tableColumns}
        tableData={props.tableData}
        gridSelected={pipelineSelection}
        gridName={GridTag.CredentialsPlacementRemindersGrid}
        resultsTitle={'Filter Results'}
      />
    </>
  );
};
