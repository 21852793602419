import { Grid, IconButton, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { SingleRateCell, SingleOrRangeRateCell, GWWCell, DefaultCell } from './CellConfigs';

const useStyles = makeStyles()(theme => ({
  headerTextStyle: {
    fontWeight: 500,
  },
  shiftColumn: {
    display: 'flex',
    flexDirection: 'row',
    width: '152px',
    minWidth: '130px',
    height: 'fit-content',
    overflow: 'hidden',
    '& .MuiFormHelperText-root': {
      marginLeft: '0px',
      marginBottom: '0px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  columnHeader: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    height: '50px',
    width: '100%',
    color: '#333333',
    alignItems: 'center',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
  },
  columnTitle: {
    paddingLeft: '11px',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  columnContent: {
    color: '#333333',
    display: 'flex',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    margin: '0px 5px',
    alignItems: 'center',
    height: '68px',
  },
  closeBtn: {
    padding: '3px',
    marginLeft: 'auto',
    '& .MuiIconButton-label': {
      marginLeft: 'auto',
      width: 'auto',
    },
  },
}));

export const RateElementsTableCell = ({
  shiftDetails,
  title,
  rateElement,
  isDeletable = false,
  setDeleteColumn,
  isColumnHeader = false,
  setHighlightedCol,
  columnIndex,
  highlightCol,
  gwwHoursArray,
  setGwwHoursArray,
  setRow,
  value,
  setError
}: {
  shiftDetails: any;
  title: string;
  rateElement?: any;
  isDeletable: boolean;
  setDeleteColumn?: (e) => void;
  isColumnHeader: boolean;
  setHighlightedCol?: (e) => void;
  columnIndex: number;
  highlightCol: boolean;
  gwwHoursArray?: any[];
  setGwwHoursArray?: (e) => void;
  setRow?: (e) => void;
  value?: any;
  setError?: (e) => void;
}) => {
  const { classes } = useStyles();
  const index = columnIndex;

  const getCellComponent = shiftDetails => {
    switch (title) {
      case 'Guaranteed Work Week':
        return (
          <GWWCell
            label={'GWW'}
            rateElement={rateElement}
            shiftDetails={shiftDetails}
            setRow={setRow}
            value={value}
            />
        );
      case 'Local Bill Rate':
        return (
          <SingleOrRangeRateCell
            label={title}
            rateElement={rateElement}
            shiftDetails={shiftDetails}
            setRow={setRow}
            value={value}
            setError={setError}
          />
        );
      case 'Travel Bill Rate':
        return (
          <SingleOrRangeRateCell
            label={title}
            rateElement={rateElement}
            shiftDetails={shiftDetails}
            setRow={setRow}
            value={value}
            setError={setError}
          />
        );
      case 'Mileage Reimbursement':
        return (
          <SingleRateCell 
            label={'Mileage'} 
            rateElement={rateElement}
            shiftDetails={shiftDetails}
            setRow={setRow}
            value={value}
          />
        );
      default:
        return <DefaultCell />;
    }
  };

  return (
    <Grid className={classes.shiftColumn} style={{ backgroundColor: highlightCol && '#E9EDF4' }}>
      {isColumnHeader ? (
        <Grid
          className={classes.columnHeader}
          onMouseEnter={() => {
            setHighlightedCol(index);
          }}
          onMouseLeave={() => {
            setHighlightedCol(null);
          }}
        >
          <Typography
            className={classes.columnTitle}
            classes={{ root: classes.headerTextStyle }}
            style={{ whiteSpace: 'nowrap' }}
          >
            {shiftDetails?.shiftTitle}
            <IconButton
              color="primary"
              disableRipple={true}
              className={classes.closeBtn}
              disabled={!isDeletable}
              onClick={() => setDeleteColumn(index)}
              style={{ display: highlightCol ? 'flex' : 'none' }}
            >
              {isDeletable && highlightCol && <CloseIcon style={{ padding: '3px' }} />}
            </IconButton>
          </Typography>
        </Grid>
      ) : (
        <Grid xs={12} className={classes.columnContent}>
          <Grid alignItems="center" style={{ display: 'flex', flex: 'noWrap' }}>
            {getCellComponent(shiftDetails)}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
