import { ChipsContainer } from 'app/ComponentLibrary/Filter/ChipsContainer';
import React, { createRef } from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  selectUserManagementChips,
  selectUserManagementFilters,
} from './store/userManagement.selector';
import { getUserManagementActions } from './store/userManagement.redux';
import { createFilterChips } from '@AMIEWEB/RecruiterWorkDesk/MyPlacementsDesk/WorkdeskFilter/filterHelper';
import { Button, Grid } from 'amn-ui-core';
import { selectUser } from 'oidc/user.selectors';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  applyButtonContainer: {
    display: "flex",
    justifyContent: "right",
    paddingRight: "18px"
  }
}));


export const UserManagementChips = props => {
  const { classes } = useStyles();
  const chips = useSelector(selectUserManagementChips);
  const hiddenSubmitButton = createRef<HTMLButtonElement>();
  const { setValue, getValues } = useFormContext();
  const { t } = useTranslation();
  const userManagementFilterObject = useSelector(selectUserManagementFilters);
  const dispatch = useDispatch();
  const userData = useSelector(selectUser);
  const isAuthorized = Authorized([userRoles.supportAdmin], userData.userInfo);

  const handleChipDelete = (key, chip) => {
    const fieldValues = getValues(key) ?? getValues(chip.value);
    let newFieldValues: any = null;
    if (
      fieldValues &&
      Object.hasOwn(fieldValues, 'level') &&
      Object.hasOwn(fieldValues, 'value') &&
      fieldValues?.isLevelSelected === false
    ) {
      let field = cloneDeep(fieldValues);
      field = {
        ...fieldValues,
        value: fieldValues?.value?.filter(item => item !== chip?.value),
      };
      newFieldValues = field;
    } else {

      newFieldValues = Array.isArray(fieldValues)
        ? fieldValues.filter(item => item.value !== chip.value.value ?? chip?.value)
        : null;
    }
    setValue(key, newFieldValues, { shouldDirty: true });
    hiddenSubmitButton.current?.click();
    dispatch(getUserManagementActions.setDisableUserFieldEdit(true));
  };

  useEffect(() => {
    userManagementFilterObject &&
      dispatch(
        getUserManagementActions.setChips({
          ...createFilterChips(userManagementFilterObject, {}, t),
        }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userManagementFilterObject]);

  return (
    <Grid container>
      <Grid item xs={11}>
        <ChipsContainer
          chips={chips}
          onDelete={(field, chip, index) => handleChipDelete(field, chip)}
          onClick={() => { }}
        />
        <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
      </Grid >
      <Grid item xs={1} className={classes.applyButtonContainer}>
        <Button
          disabled={!isAuthorized}
          variant="contained"
          onClick={() => dispatch(getUserManagementActions.toggleCreateUserModal(true))}
        >
          {t('userManagement.addNewUser')}
        </Button>
      </Grid>
    </Grid>
  );
};
