import { TemplateCategory } from '@AMIEWEB/Common/SubmissionPreference/SubmissionEmail/AddTemplateModal';
import { IDirection } from '@AMIEWEB/Notification/Constants';
import { SmsType } from 'app/enums/Sms';
import { ICandidateConsents, INotification, ILogsFilterChips } from 'app/models/Notification/Notification';

export const defaultLogsChips: ILogsFilterChips = {
  channel: [],
  initatedBy: undefined,
  userRole: undefined,
  date: undefined,
};
export const logsInitialState = {
  channel: [],
  date: '',
  userRole: '',
  initiatedBy: '',
};
const initialCandidatesConsents: ICandidateConsents = {
  candidateId: null,
  brandId: null,
  globalConsents: {
    emails: true,
    calls: true,
    texts: true,
  },
  hasEmailConsents: true,
  hasCallConsents: true,
  hasTextConcents: true,
  withDrawnEmailConsents: [],
  withDrawnCallsConsents: [],
  withDrawnTextsConcents: [],
};
export const initialsmsCandidatesConsents: ICandidateConsents = {
  candidateId: null,
  brandId: null,
  globalConsents: {
    emails: true,
    calls: true,
    texts: true,
  },
  hasEmailConsents: true,
  hasCallConsents: true,
  hasTextConcents: true,
  withDrawnEmailConsents: [],
  withDrawnCallsConsents: [],
  withDrawnTextsConcents: [],
};
const initialStateTemplateData = {
  title:  '',
  id:  '',
  body:  '',
  description:  '',
  category:  TemplateCategory.PacketSubmission,
  isPrivate:  false,
  substitutions: [],
  subject:''
};

class NotificationState {
  private initialState: INotification;

  public constructor() {
    this.initialState = Object.create(null);
  }

  Email() {
    this.initialState.email = {
      data: null,
      open: false,
      minimized: false,
      expanded: false,
      forwardAttachmentUrls: [],
      categoryTitles: [],
      sendWithoutConsent: false,
    };
    return this;
  }

  SMS() {
    this.initialState.sms = {
      chatHistory: null,
      notes: '',
      attachments: undefined,
      queuedDataToBeSend: undefined,
      activeUnreadCount: 0,
      data: null,
      open: false,
      minimized: false,
      readonly: false,
      sendType: SmsType.one_to_one || SmsType.bulk,
      pinnedMessages: [],
      manualEntry: false,
      manuallyEnteredNumber: null,
    };
    return this;
  }

  LOG() {
    this.initialState.log = {
      data: null,
      open: false,
      minimized: false,
    };
    return this;
  }

  VOICE() {
    this.initialState.voice = {
      data: null,
      open: false,
      minimized: false,
      showDisposition: false,
    };
    return this;
  }

  GLOBALSTATE() {
    this.initialState.inbox = null;
    this.initialState.snackbarData = null;
    this.initialState.expanded = {};
    this.initialState.dropdown = {};
    this.initialState.logFilters = {
      channel: [],
      initiatedBy: '',
    };
    this.initialState.gridLogFilters = {
      channel: [],
      initiatedBy: '',
      userRole: '',
    };
    this.initialState.showLogsFilter = false;
    this.initialState.chips = defaultLogsChips;
    this.initialState.activeToasterCount = 0;
    this.initialState.candidateConsents = initialCandidatesConsents;
    this.initialState.sidePanelConsents = initialCandidatesConsents;
    this.initialState.smsCandidateConsents = initialsmsCandidatesConsents;
    this.initialState.emailConsentWithdrawn = [];
    this.initialState.inboxMessages = {};
    this.initialState.unreadCount = [];
    this.initialState.communicationLimitsEmail = [];
    this.initialState.communicationLimitsSms = [];
    this.initialState.communicationLimitsDialog = {
      open: false,
      key: ''
    };
    this.initialState.facilityBaseTemplate = null;
    this.initialState.packetFacilityTemplates = [];
    this.initialState.packetFacilityTemplatesById = initialStateTemplateData;
    this.initialState.isEdit=false;
    this.initialState.voiceAction = {
      minimize: false,
      openDeviceSelection: false,
      showDisposition: false,
    };
    this.initialState.voiceDisposition = null;
    this.initialState.voiceLoader = false;
    return this;
  }

  public get State(): INotification {
    return this.initialState;
  }
}
export const initialState = new NotificationState().GLOBALSTATE().Email().LOG().SMS().VOICE().State;
