import React from 'react';
import { ssnMaskType, SSNProps, SSNTextField } from 'app/ComponentLibrary/SSNTextField/SSNTextField';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface RestrictedSSNTextFieldProps extends Omit<SSNProps, 'mask' | 'readOnly'> {
  overrideEdit?: boolean;
  readOnly?: boolean;
  originalSSN: string;
  formName?: string;
}

export const RestrictedSSNTextField = ({
  overrideEdit,
  onChange,
  ...props
}: Omit<RestrictedSSNTextFieldProps, 'originalSSN'>) => {
  const [mask, setMask] = React.useState<ssnMaskType>('full');
  const { userInfo } = useSelector(selectUser);
  const [readOnly, setReadOnly] = React.useState<boolean>(true);

  React.useEffect(() => {
    // Editable
    if (
      overrideEdit ||
      Authorized(
        [
          userRoles.credentialing_Leadership,
          userRoles.credentialing_TeamMember,
          userRoles.clinical_QualificationsSpecialist,
        ],
        userInfo,
      )
    ) {
      // USER CAN EDIT
      setMask('nonePartial');
      setReadOnly(false);
    } else if (
      Authorized(
        [
          userRoles.accountManagement_Leadership,
          userRoles.accountManagement_TeamMember,
          userRoles.recruitment_Leadership,
          userRoles.recruitment_TeamMember,
          userRoles.credentialing_Leadership,
          userRoles.credentialing_TeamMember,
          userRoles.clinical_Manager,
          userRoles.clinical_QualificationsSpecialist,
          userRoles.clinical_ReviewAnalyst,
          userRoles.clinical_Director,
        ],
        userInfo,
      )
    ) {
      // READONLY - USER CAN VIEW FULL SSN
      setMask('nonePartial');
      setReadOnly(true);
    }
    // Partial Only
  }, [userInfo, overrideEdit]);

  return <SSNTextField {...props} mask={mask} readOnly={props.readOnly || readOnly} onChange={onChange} />;
};

/**
 * React Hook Form control with Form Validation
 */
export const RestrictedSSNFormControl = ({
  originalSSN,
  overrideEdit,
  formName = 'ssn',
  ...props
}: RestrictedSSNTextFieldProps) => {
  const { control, errors, setError, clearErrors } = useFormContext();
  const ssn: string | undefined = useWatch({ name: 'ssn' });
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={formName}
      render={({ ref, onChange, ...rest }) => {
        return (
          <RestrictedSSNTextField
            {...props}
            ssn={ssn}
            overrideEdit={overrideEdit}
            onEndEdit={_ssn => {
              if (!new RegExp(/\d{3}-\d{2}-\d{4}/g).test(_ssn) && _ssn !== originalSSN)
                setError('ssn', {
                  type: 'custom',
                  message: t('placement.profile.summary.offer.transportation.ssnError'),
                });
              else clearErrors('ssn');
              onChange(_ssn);
            }}
            error={!!errors.ssn}
            helperText={errors.ssn?.message}
          />
        );
      }}
    />
  );
};
