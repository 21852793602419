import { isArray } from 'lodash';
import {
  addIndexToItems,
  convertToAbbreviation,
  getcombinedOptionsWithDefault,
  getFacilityMileageSkillsMatched,
  getFacilityRateElements,
  getRatesWithinDateRanges,
  getRatesWithShiftMatch,
  getRatesWithValidRanges,
  getShiftId,
  getShiftName,
  groupAndSortOptions,
  RateElemenetSource,
  RateElements,
  refactorFacilityGwwValue,
  refactorFacilityMileageValue,
  refactorValue,
} from '../../helper';

const getGwwFacilityRates = (dates, shiftID, version, facilityOffcontractRates) => {
  const facilityGwwRates = getFacilityRateElements(RateElements.GWW, facilityOffcontractRates);
  const facilityGwwWithinDateRange = getRatesWithinDateRanges(dates, facilityGwwRates);
  const facilityGwwRatesWithValidStatus = getRatesWithValidRanges(facilityGwwWithinDateRange);
  let dropDownOptions = [];
  if (!version) {
    dropDownOptions = facilityGwwRatesWithValidStatus.map(facilityRate => {
      return facilityRate.shiftId
        ? {
            value: refactorFacilityGwwValue(facilityRate.value, facilityRate.valueMax),
            group: `${getShiftName(facilityRate.shiftId)} Hour Shift`,
            groupShiftId: facilityRate.shiftId,
            disciplineId: facilityRate?.disciplineId,
            specialtyId: facilityRate?.specialtyId,
            subSpecialtyId: facilityRate?.subSpecialtyId,
            shift: getShiftName(facilityRate.shiftId),
            shiftID: facilityRate.shiftId,
            statusId: facilityRate.statusId,
            source: RateElemenetSource.Facility,
            createdAt: facilityRate.createdAt,
            effectiveStartDate: facilityRate?.effectiveStartDate,
            effectiveEndDate: facilityRate?.effectiveEndDate,
            rateElementId: facilityRate.rateElementId,
            offContractId: facilityRate?.offContractId,
            reason: facilityRate?.reason,
            documentIds: facilityRate?.documentIds            
          }
        : {
            value: refactorFacilityGwwValue(facilityRate.value, facilityRate.valueMax),
            group: `All Shifts`,
            groupShiftId: null,
            disciplineId: facilityRate?.disciplineId,
            specialtyId: facilityRate?.specialtyId,
            subSpecialtyId: facilityRate?.subSpecialtyId,
            shift: null,
            shiftID: null,
            statusId: facilityRate.statusId,
            source: RateElemenetSource.Facility,
            createdAt: facilityRate.createdAt,
            effectiveStartDate: facilityRate?.effectiveStartDate,
            effectiveEndDate: facilityRate?.effectiveEndDate,
            rateElementId: facilityRate.rateElementId,
            offContractId: facilityRate?.offContractId,
            reason: facilityRate?.reason,
            documentIds: facilityRate?.documentIds
          };
    });
  } else if (version === 'v2') {
    const facilityGwwWithShiftMatch = getRatesWithShiftMatch(shiftID, facilityGwwRatesWithValidStatus);
    const facilityGwwWithNullShiftMatch = getRatesWithShiftMatch(null, facilityGwwRatesWithValidStatus);
    const combinedFacilityGwwRates = facilityGwwWithNullShiftMatch.concat(facilityGwwWithShiftMatch);
    dropDownOptions = combinedFacilityGwwRates.map((facilityRate, index) => {
      return facilityRate.shiftId
        ? {
            value: refactorFacilityGwwValue(facilityRate.value, facilityRate.valueMax),
            group: `${getShiftName(facilityRate.shiftId)} Hour Shift`,
            groupShiftId: facilityRate.shiftId,
            index: index,
            disciplineId: facilityRate?.disciplineId,
            specialtyId: facilityRate?.specialtyId,
            subSpecialtyId: facilityRate?.subSpecialtyId,
            shift: getShiftName(shiftID),
            shiftID: shiftID,
            statusId: facilityRate.statusId,
            source: RateElemenetSource.Facility,
            createdAt: facilityRate.createdAt,
            effectiveStartDate: facilityRate?.effectiveStartDate,
            effectiveEndDate: facilityRate?.effectiveEndDate,
            rateElementId: facilityRate.rateElementId,
            offContractId: facilityRate?.offContractId,
            reason: facilityRate?.reason,
          documentIds: facilityRate?.documentIds
          }
        : {
            value: refactorFacilityGwwValue(facilityRate.value, facilityRate.valueMax),
            group: `All Shifts`,
            groupShiftId: null,
            index: index,
            disciplineId: facilityRate?.disciplineId,
            specialtyId: facilityRate?.specialtyId,
            subSpecialtyId: facilityRate?.subSpecialtyId,
            shift: null,
            shiftID: null,
            statusId: facilityRate.statusId,
            source: RateElemenetSource.Facility,
            createdAt: facilityRate.createdAt,
            effectiveStartDate: facilityRate?.effectiveStartDate,
            effectiveEndDate: facilityRate?.effectiveEndDate,
            rateElementId: facilityRate.rateElementId,
            offContractId: facilityRate?.offContractId,
            reason: facilityRate?.reason,
            documentIds: facilityRate?.documentIds
          };
    });
  }

  return dropDownOptions;
};

const getShiftData = (uniqueShifts, effectiveDates, facilityOffcontractRates) => {
  //No shift selected for Order
  if (uniqueShifts.length === 0) {
    const contractDropdownOptions = [];
    const facilityGwwRates = effectiveDates?.startDate
      ? getGwwFacilityRates(effectiveDates, null, null, facilityOffcontractRates)
      : [];
    const combinedDropdownOptions = contractDropdownOptions.concat(facilityGwwRates);
    const sortedCombinedOptions = groupAndSortOptions(combinedDropdownOptions);
    const combinedGwwRateWithDefaults = getcombinedOptionsWithDefault(sortedCombinedOptions, null, null);
    return [
      {
        shiftName: null,
        GwwHoursArray: addIndexToItems(combinedGwwRateWithDefaults),
      },
    ];
  } else {
    const shiftDataWithShifts = uniqueShifts.map(item => {
      const contractDropdownOptions = [];
      const facilityGwwRates = effectiveDates?.startDate
        ? getGwwFacilityRates(effectiveDates, getShiftId(item), 'v2', facilityOffcontractRates)
        : [];
      const combinedDropdownOptions = contractDropdownOptions.concat(facilityGwwRates);
      const sortedCombinedOptions = groupAndSortOptions(combinedDropdownOptions);
      const combinedGwwRateWithDefaults = getcombinedOptionsWithDefault(sortedCombinedOptions, null, item);
      return {
        shiftName: item,
        GwwHoursArray: addIndexToItems(combinedGwwRateWithDefaults),
      };
    });
    return shiftDataWithShifts;
  }
};

export const deriveGWWDropdownData = ({ uniqueShifts, effectiveDates, facilityOffcontractRates, rateElements }) => {
  let GwwArray;
  //Contract does not have GWW
  if (isArray(rateElements) && rateElements[0]?.hoursPerPayCycle === null) {
    GwwArray = getShiftData(uniqueShifts, effectiveDates, facilityOffcontractRates);
    return GwwArray;
  } else {
    //no shift selected by user
    if (uniqueShifts.length === 0) {
      const contractDropdownOptions = rateElements?.map((obj, index) => {
        return {
          value: obj?.hoursPerPayCycle,
          group: `${obj?.shift} Hour Shift`,
          groupShiftId: getShiftId(obj?.shift),
          index: index,
          source: RateElemenetSource.Contract,
          shiftID: null,
          shift: null,
          rateElementId:obj?.rateElementId
        };
      });
      const facilityGwwRates = effectiveDates?.startDate
        ? getGwwFacilityRates(effectiveDates, null, null, facilityOffcontractRates)
        : [];
      const combinedDropdownOptions =
        !!contractDropdownOptions && contractDropdownOptions.concat(facilityGwwRates as any);
      const sortedCombinedOptions = !!combinedDropdownOptions && groupAndSortOptions(combinedDropdownOptions);
      const combinedGwwRateWithDefaults =
        !!sortedCombinedOptions && getcombinedOptionsWithDefault(sortedCombinedOptions, null, null);

      GwwArray = [
        {
          shiftName: null,
          GwwHoursArray: !!combinedGwwRateWithDefaults ? addIndexToItems(combinedGwwRateWithDefaults) : [],
        },
      ];
    }
    // shifts are selected by user
    else {
      GwwArray = uniqueShifts.map(item => {
        const foundItem = (rateElements || []).find(obj => obj.shift === item);
        if (foundItem) {
          const contractDropdownOptions = [
            {
              value: foundItem?.hoursPerPayCycle,
              index: 0,
              group: `${item} Hour Shift`,
              groupShiftId: getShiftId(item),
              source: RateElemenetSource.Contract,
              shiftID: getShiftId(item),
              shift: item,
              rateElementId:foundItem?.rateElementId
            },
          ];
          const facilityDropdownOptions = effectiveDates?.startDate
            ? getGwwFacilityRates(effectiveDates, getShiftId(item), 'v2', facilityOffcontractRates)
            : [];
          const combinedDropdown = contractDropdownOptions.concat(facilityDropdownOptions as any);
          const sortedCombinedOptions = groupAndSortOptions(combinedDropdown);
          const combinedOptionsWithDefault = getcombinedOptionsWithDefault(sortedCombinedOptions, null, item);
          return {
            shiftName: item,
            GwwHoursArray: addIndexToItems(combinedOptionsWithDefault),
          };
        } else {
          const contractDropdownOptions = [];
          const facilityDropdownOptions = effectiveDates?.startDate
            ? getGwwFacilityRates(effectiveDates, getShiftId(item), 'v2', facilityOffcontractRates)
            : [];
          const combinedDropdown = contractDropdownOptions.concat(facilityDropdownOptions as any);
          const sortedCombinedOptions = groupAndSortOptions(combinedDropdown);
          const combinedOptionsWithDefault = getcombinedOptionsWithDefault(sortedCombinedOptions, null, item);
          return {
            shiftName: item,
            GwwHoursArray: addIndexToItems(combinedOptionsWithDefault),
          };
        }
      });
    }
    return GwwArray;
  }
};

const getMileageFacilityRates = (dates, facilityOffcontractRates, disciplineArray) => {
  const facilityMileageRates = getFacilityRateElements(RateElements.MileageReimbursement, facilityOffcontractRates);
  const facilityMileageRatesWithinDateRange = getRatesWithinDateRanges(dates, facilityMileageRates);
  const facilityMileageRatesWithValidStatus = getRatesWithValidRanges(facilityMileageRatesWithinDateRange);
  const facilityMileageSkillsMatched = getFacilityMileageSkillsMatched(
    disciplineArray,
    facilityMileageRatesWithValidStatus,
  );
  const dropDownOptions = facilityMileageSkillsMatched.map(facilityRate => {
    return facilityRate.shiftId
      ? {
          value: refactorFacilityMileageValue(facilityRate.value, facilityRate.valueMax),
          group: `${getShiftName(facilityRate.shiftId)} Hour Shift`,
          groupShiftId: facilityRate.shiftId,
          disciplineId: facilityRate?.disciplineId,
          specialtyId: facilityRate?.specialtyId,
          subSpecialtyId: facilityRate?.subSpecialtyId,
          shift: getShiftName(facilityRate.shiftId),
          shiftID: facilityRate.shiftId,
          statusId: facilityRate.statusId,
          source: RateElemenetSource.Facility,
          createdAt: facilityRate.createdAt,
          effectiveStartDate: facilityRate?.effectiveStartDate,
          effectiveEndDate: facilityRate?.effectiveEndDate,
          rateElementId: facilityRate.rateElementId,
          offContractId: facilityRate?.offContractId,
          reason: facilityRate?.reason,
          documentIds: facilityRate?.documentIds
        }
      : {
          value: refactorFacilityMileageValue(facilityRate.value, facilityRate.valueMax),
          group: `All Shifts`,
          groupShiftId: null,
          disciplineId: facilityRate?.disciplineId,
          specialtyId: facilityRate?.specialtyId,
          subSpecialtyId: facilityRate?.subSpecialtyId,
          shift: null,
          shiftID: null,
          statusId: facilityRate.statusId,
          source: RateElemenetSource.Facility,
          createdAt: facilityRate.createdAt,
          effectiveStartDate: facilityRate?.effectiveStartDate,
          effectiveEndDate: facilityRate?.effectiveEndDate,
          rateElementId: facilityRate.rateElementId,
          offContractId: facilityRate?.offContractId,
          reason:facilityRate?.reason,
          documentIds: facilityRate?.documentIds
        };
  });
  return dropDownOptions;
};

export const deriveMileageValues = ({value, effectiveDates, facilityOffcontractRates, skillsets, disciplineOpts, rateElementId}) => {
  const disciplineArray = convertToAbbreviation(skillsets, disciplineOpts);
  const contractDropdownOptions =
    value !== null && value !== undefined
      ? [
          {
            value: refactorValue(value, 3),
            group: `All Shifts`,
            shift: null,
            shiftID: null,
            groupShiftId: null,
            source: RateElemenetSource.Contract,
            rateElementId: rateElementId
          },
        ]
      : [];
  const facilityDropdownOptions = effectiveDates?.startDate
    ? getMileageFacilityRates(effectiveDates, facilityOffcontractRates, disciplineArray)
    : [];
  const combinedDropdownOptions = contractDropdownOptions.concat(facilityDropdownOptions as any);
  const sortedCombinedOptions = groupAndSortOptions(combinedDropdownOptions);

  const combinedMileageRateWithDefaults = getcombinedOptionsWithDefault(
    sortedCombinedOptions,
    disciplineArray[0],
    null,
  );

  return addIndexToItems(combinedMileageRateWithDefaults);
};
