import { trackEvent } from 'app-insights/appInsightsTracking';
import { missingField } from 'app/constants';
import { SignalREventType } from 'app/enums/Common';
import { SmsType } from 'app/enums/Sms';
import { publishSms } from 'app/services/NotificationServices/NotificationService';

export const sendSms = async (payload, type) => {
  switch (type) {
    case SmsType.bulk:
      await publishSms(payload).catch(err => console.log('err', err));
  }
};
export const optionState = [
  {
    id: 1,
    value: 1,
    name: 'Unread Messages',
  },
  {
    id: 2,
    value: 2,
    name: 'Unknown Number',
    disabled: true,
  },
];

export const formatPhoneNumber = data => {
  if (data && data !== missingField) {
    if (!data.includes('+1')) {
      return '+1' + data?.replaceAll(' ', '')?.replaceAll('-', '')?.replaceAll('(', '')?.replaceAll(')', '');
    } else {
      return data;
    }
  }
  return;
};

export const logOutboundCoveredSMS = (
  smsCoverageEnabled: boolean,
  coveredEmployeeId: string,
  coveringEmployeeId: string,
  publishedOn: string,
) => {
  if (smsCoverageEnabled && coveredEmployeeId !== coveringEmployeeId) {
    trackEvent({
      type: 'event',
      name: `SMS Coverage`,
      properties: {
        EventType: SignalREventType.CoverageOutboundSMS,
        Date: publishedOn,
        CoveringUser: coveringEmployeeId,
        CoveredUser: coveredEmployeeId,
        Description: `Coverage - Outbound message for covered user: ${coveredEmployeeId}, sent by covering user: ${coveringEmployeeId}`,
      },
    });
  }
};

export const getFileFromUrl = async (url: string, name: string, type: string) => {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: type,
  });
};

export const getMultiSelectOptions = (options: any) => {
  return options?.length
    ? [
        ...options.map((opt, index) => ({
          id: index + 1,
          label: `${opt.name} (CID ${opt.contactId})`,
          ...opt,
        })),
      ]
    : [];
};
