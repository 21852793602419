import { Tooltip, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import styledComponent from 'styled-components';

const tooltipStyles = makeStyles()(theme => ({
  // eslint-disable-next-line tss-unused-classes/unused-classes
  tooltipArrow: {
    color: '#fff',
    '&::before': {
      border: '1px solid #555555',
    },
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  tooltipBackground: {
    backgroundColor: '#fff',
    color: '#555555',
    border: '1px solid #555555',
    fontSize: '12px',
  },
}));

export type Falsy = false | null | undefined | 0 | '';

export const TooltipText = styledComponent(Typography)`
  text-align: left;
  font: normal normal normal 12px/18px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`;
export const nameInitial = (name: string, newNumber: boolean = false) => {
  return name
    ? name
        ?.split(' ')
        .map(n => n[0])
        .join('')
        .replace(/[^a-zA-Z]/g, '')
        .substring(0, 2)
    : !newNumber ? 'UNK' : '#';
};

export const EllipsesTextWithToolTip = (
  text: string,
  maxLength: number,
  contentClass = undefined,
) => {
  const { classes: tooltipClasses } = tooltipStyles();
  return text.length > maxLength ? (
    <Tooltip
      classes={{
        tooltip: tooltipClasses ? tooltipClasses.tooltipBackground : undefined,
        arrow: tooltipClasses ? tooltipClasses.tooltipArrow : undefined,
      }}
      arrow
      title={text}
    >
      <span className={contentClass}>{`${text.substring(0, maxLength)}...`}</span>
    </Tooltip>
  ) : (
    <span className={contentClass}>{text}</span>
  );
};

export function TextWithEllipses(str, max) {
  return str?.length > max ? str?.substring(0, max) + '...' : str;
}
