import { ISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import i18next from 'i18next';

export const bonusTypeOptions = [
  { ID: 1, Description: 'Start Date' },
  { ID: 2, Description: 'Date Booked' },
  { ID: 10, Description: 'Additional Pay' },
  { ID: 12, Description: 'Marketing Adjustment' },
];

export const bonusPayerOptions = [
  { ID: 1, Description: 'Company' },
  { ID: 2, Description: 'Client' },
];

export const bonusPaymentTypeOptions = [
  { ID: 1, Description: 'Sign On' },
  { ID: 2, Description: 'Completion' },
  { ID: 3, Description: 'Custom' },
  { ID: 4, Description: 'Shift Req Sign On' },
  { ID: 5, Description: 'Shift Req Completion' },
];

export const bonusShortHourTypeOptions = [
  { ID: 1, Description: 'Minimum hours' },
  { ID: 2, Description: 'Minimum missed shift' },
  { ID: 3, Description: 'None' },
  { ID: 4, Description: 'Prorated' },
  { ID: 5, Description: 'Custom' },
  { ID: 6, Description: 'Standard' },
];

export const bonusLessThan13WeeksOptions = [
  { ID: 1, Description: 'Prorate' },
  { ID: 2, Description: 'Full' },
  { ID: 3, Description: 'None' },
];

export const bonusBillingTypeOptions = [
  { ID: 1, Description: 'None' },
  { ID: 2, Description: 'Flat' },
  { ID: 3, Description: 'Hourly' },
];

export const bonusApprovedMethodOptions = [
  { ID: 4, Description: 'Addendum' },
  { ID: 3, Description: 'e-mail' },
  { ID: 2, Description: 'Fax' },
  { ID: 1, Description: 'Phone' },
];

export const shiftOptions = [
  { ID: 1, Description: '12 D' },
  { ID: 2, Description: '12 N' },
  { ID: 3, Description: '12 D/N' },
  { ID: 4, Description: '8 D' },
  { ID: 5, Description: '8 E' },
  { ID: 6, Description: '8 N' },
  { ID: 7, Description: '8 D/E/N' },
  { ID: 9, Description: '8/12 D' },
  { ID: 10, Description: '8/12 N' },
  { ID: 11, Description: '8/12 D/N' },
  { ID: 12, Description: '8/12 D/E/N' },
  { ID: 13, Description: '10 D' },
  { ID: 14, Description: '10 E' },
  { ID: 15, Description: '10 N' },
  { ID: 16, Description: '12 E' },
  { ID: 17, Description: '12 D/E' },
  { ID: 18, Description: '12 E/N' },
  { ID: 19, Description: '12 D/E/N' },
  { ID: 20, Description: '11.5 D' },
  { ID: 21, Description: '11.5 N' },
  { ID: 22, Description: '11.5 D/E' },
  { ID: 23, Description: '11.5 E/N' },
  { ID: 24, Description: '11.5 D/N' },
  { ID: 25, Description: '11.5 D/E/N' },
  { ID: 26, Description: '10 D/N' },
  { ID: 27, Description: '10 D/E' },
  { ID: 28, Description: '10 E/N' },
  { ID: 29, Description: '10 D/E/N' },
  { ID: 30, Description: '8 D/E' },
  { ID: 31, Description: '8 E/N' },
  { ID: 32, Description: '8 D/N' },
  { ID: 33, Description: '7.5 D' },
  { ID: 34, Description: '7.5 E' },
  { ID: 35, Description: '7.5 N' },
  { ID: 36, Description: '7.5 D/E' },
  { ID: 37, Description: '7.5 E/N' },
  { ID: 38, Description: '7.5 D/N' },
  { ID: 39, Description: '7.5 D/E/N' },
  { ID: 40, Description: '8/12 E' },
  { ID: 41, Description: '8/12 D/E' },
  { ID: 42, Description: '8/12 E/N' },
  { ID: 43, Description: '8/10/12 D' },
  { ID: 44, Description: '8/10/12 E' },
  { ID: 45, Description: '8/10/12 N' },
  { ID: 46, Description: '8/10/12 D/E' },
  { ID: 47, Description: '8/10/12 D/N' },
  { ID: 48, Description: '8/10/12 E/N' },
  { ID: 49, Description: '8/10/12 D/E/N' },
  { ID: 50, Description: '7.5/11.5 D' },
  { ID: 51, Description: '7.5/11.5 E' },
  { ID: 52, Description: '7.5/11.5 N' },
  { ID: 53, Description: '7.5/11.5 D/E' },
  { ID: 54, Description: '7.5/11.5 D/N' },
  { ID: 55, Description: '7.5/11.5 E/N' },
  { ID: 56, Description: '7.5/11.5 D/E/N' },
  { ID: 57, Description: '7.5/10/11.5 D' },
  { ID: 58, Description: '7.5/10/11.5 E' },
  { ID: 59, Description: '7.5/10/11.5 N' },
  { ID: 60, Description: '7.5/10/11.5 D/E' },
  { ID: 61, Description: '7.5/10/11.5 D/N' },
  { ID: 62, Description: '7.5/10/11.5 E/N' },
  { ID: 63, Description: '7.5/10/11.5 D/E/N' },
  { ID: 64, Description: '11.5 E' },
  { ID: 65, Description: '9.5 D' },
  { ID: 66, Description: '9.5 E' },
  { ID: 67, Description: '9.5 N' },
  { ID: 68, Description: '9.5 D/E' },
  { ID: 69, Description: '9.5 E/N' },
  { ID: 70, Description: '9.5 D/N' },
  { ID: 71, Description: '9.5 D/E/N' },
  { ID: 72, Description: '7 D' },
  { ID: 73, Description: '7 E' },
  { ID: 74, Description: '4 D/N/E' },
  { ID: 75, Description: '4 D' },
  { ID: 76, Description: '5 D' },
  { ID: 77, Description: '6 D' },
  { ID: 78, Description: '9 D/N/E' },
  { ID: 79, Description: '11 D/N/E' },
  { ID: 80, Description: '14 D/N/E' },
  { ID: 81, Description: '16 D/N/E' },
  { ID: 82, Description: '18 D/N/E' },
  { ID: 83, Description: '8/16 D/N/E' },
  { ID: 84, Description: '8/16 D' },
  { ID: 85, Description: '8/16 N' },
  { ID: 86, Description: '8/16 E' },
  { ID: 87, Description: '14 D' },
  { ID: 88, Description: '14 E' },
  { ID: 89, Description: '7.25 D' },
  { ID: 90, Description: '6.5 D' },
  { ID: 91, Description: '8/10 D/N/E' },
  { ID: 92, Description: '8/10 D' },
  { ID: 93, Description: '8/10 N' },
  { ID: 94, Description: '8/10 E' },
  { ID: 95, Description: '6.4 D' },
  { ID: 96, Description: '6.8 D' },
  { ID: 97, Description: '7.4 D' },
];

export const MFSOpts = [
  { id: 0, value: 1, name: 'Yes', labelPrefix: 'MFS' },
  { id: 1, value: 2, name: 'No', labelPrefix: 'MFS' },
];
export const PaysGwwOpts = [
  { id: 0, value: 1, name: 'Yes', labelPrefix: 'GWW' },
  { id: 1, value: 2, name: 'No', labelPrefix: 'GWW' },
];

export const PositionOpts = [
  {
    id: 0,
    value: { min: 0, max: 0 },
    name: '0',
    labelPrefix: i18next.t('search.globalSearch.order.filterNames.positions'),
  },
  {
    id: 1,
    value: { min: 1, max: 999 },
    name: '>0',
    labelPrefix: i18next.t('search.globalSearch.order.filterNames.positions'),
  },
  {
    id: 2,
    value: { min: 1, max: 10 },
    name: '1-10',
    labelPrefix: i18next.t('search.globalSearch.order.filterNames.positions'),
  },
  {
    id: 3,
    value: { min: 10, max: 50 },
    name: '10-50',
    labelPrefix: i18next.t('search.globalSearch.order.filterNames.positions'),
  },
  {
    id: 4,
    value: { min: 50, max: 99 },
    name: '50-99',
    labelPrefix: i18next.t('search.globalSearch.order.filterNames.positions'),
  },
] as unknown as ISelection[];
