import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { panelActions } from '../../../PanelOptions/PanelOption.redux';
import { PanelChoice } from '../../../PanelOptions/PanelOption.redux';
import { useSelector } from 'react-redux';
import { candidateEmployementSelection } from '../../../Profile/CandidateDetails.selector';
import { CommonPopUpforWorkAndEducation } from '../../Common/CommonPopUpforWorkAndEducation';
import { ff_WorkExperience_Enhancement, ff_candidate_container_ui_v2 } from 'app/constants/FeatureFlags/Candidate/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { hasAddModalAccess } from '../../WorkExperienceTab/components/EditWorkExperience/WorkExperienceUtils';
import { AddWorkExperienceWrapper } from '../../WorkExperienceTab/components/AddWorkExperience/AddWorkExperienceWrapper';
import { getFirstFour } from './CardDesigns/helper';
import EmployeementCardUIV2 from './CardDesigns/EmployeementCardUIV2';
import EmployeementCardUI from './CardDesigns/EmployeementCardUI';
import { selectUser } from 'oidc/user.selectors';

export const EmploymentHistoryCard = props => {
  const dispatch = useDispatch();
  const employment = useSelector(candidateEmployementSelection);
  const [openAddPop, setOpenAddPop] = useState<boolean>(false);
  const [candidateContainerUIV2] = useDecision(ff_candidate_container_ui_v2);
  const user = useSelector(selectUser);
  const [enhancedWorkExperienceModal] = useDecision(ff_WorkExperience_Enhancement);

  const addModalAccess = useMemo(() => hasAddModalAccess(user), [user]);

  const handleClick = () => {
    dispatch(panelActions.setPanelOptions({ panelOption: PanelChoice.EmployementHistoryGrid }));
    window.history.pushState(
      null,
      '',
      `${window.location.pathname}?panel=${PanelChoice.EmployementHistoryGrid.toLowerCase()}`,
    );
  };

  //////////////////////////////////
  //US 33255 insists only 4 to be displayed --> remove below code to display all + uncomment line above
  const employmentHistories = getFirstFour(employment || []);
  /////////////////////////////////
  const handleClose = () => {
    setOpenAddPop(false);
  };

  return (
    <>
      {candidateContainerUIV2?.enabled ? (
        <EmployeementCardUIV2
          employmentHistories={employmentHistories}
          handleClick={handleClick}
          editWorkHistory={props.editWorkHistory}
          setOpenAddPop={setOpenAddPop}
          addModalAccess={addModalAccess}
        />
      ) : (
        <EmployeementCardUI
          employmentHistories={employmentHistories}
          handleClick={handleClick}
          editWorkHistory={props.editWorkHistory}
          setOpenAddPop={setOpenAddPop}
        />
      )}
      {openAddPop &&
        (enhancedWorkExperienceModal?.enabled ? (
          <AddWorkExperienceWrapper open={openAddPop} handleClose={handleClose} addType="work" />
        ) : (
          <CommonPopUpforWorkAndEducation open={openAddPop} handleClose={handleClose} addType="work" />
        ))}
    </>
  );
};
