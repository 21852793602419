import { ListItemButton, ListItemIcon, ListItemText } from 'amn-ui-core';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips/CustomTooltip';
import FolderCopyIcon from '@mui/icons-material/FolderCopyOutlined';
import React from 'react';
import { colors } from '../../../../styles/styleVariables';
import { selectPlacementStatus } from '../../../../store/redux-store/placement-status/selectors';
import { selectUser } from 'oidc/user.selectors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
export interface IHeaderLinkProps {
  closeCopyMenu: Function;
  openPlacementChangeDialog: Function;
}

export const PlacementChangeHeaderLink = (props: IHeaderLinkProps) => {
  const { t } = useTranslation();
  const { closeCopyMenu, openPlacementChangeDialog } = props;
  const { userInfo } = useSelector(selectUser);
  const { currentPlacementStatus } = useSelector(selectPlacementStatus);
  const hasPlacementChangePermission = userInfo?.externalSystemDetails?.placementCopy;
  const hasPermissionToLink = React.useMemo(() => {
    return (currentPlacementStatus?.activityTypeId === 4 && currentPlacementStatus?.activityStatusId >= 15) ||
      (currentPlacementStatus?.activityTypeId === 5 && currentPlacementStatus?.activityStatusId >= 23)
      ? true
      : false;
  }, [currentPlacementStatus]);

  const isADRoleAuthorized = React.useMemo(() => {
    return Authorized(
      [
        userRoles.accountManagement_Leadership,
        userRoles.accountManagement_TeamMember,
        userRoles.recruitment_Leadership,
      ],
      userInfo,
    );
  }, [userInfo]);
  return (
    <>
      {(hasPlacementChangePermission || isADRoleAuthorized) && (
        <CustomTooltip
          tooltipContent={!hasPermissionToLink ? t('placement.placementChange.disableText') : ''}
          contentStyle={{ fontWeight: 400, color: colors.darkText }}
        >
          <ListItemButton
            id="placement-change-button"
            disabled={!hasPermissionToLink}
            onClick={() => {
              closeCopyMenu();
              openPlacementChangeDialog();
            }}
          >
            <ListItemIcon>
              <FolderCopyIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t('placement.placementChange.title')} />
          </ListItemButton>
        </CustomTooltip>
      )}
    </>
  );
};
