export const parseSpecialitySubSpeciality = (value: number | string | null | undefined) => {
  // If value is a number, return number
  if (typeof value === 'number') return value;
  // If value is a string, split and parse
  if (typeof value === 'string') {
    const id = value.split('-').pop();
    return id ? (isNaN(Number(id)) ? null : Number(id)) : null;
  }
  return null;
};
