/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable i18next/no-literal-string */
import { HomePage } from 'app/layout/pages/HomePage';
import React, { useCallback, useEffect, useState } from 'react';
import { createRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { PlacementRemindersChips } from './Chips/PlacementRemindersChips';
import { PlacementReminderPipeline } from './DataVisualization/PlacementReminderPipeline';
import { PlacementReminderFilter } from './Filters/PlacementReminderFilter';
import {
  IFilterChipSelected,
  placementReminderActions,
  placementReminderSliceKey,
  placementReminderTotalsReducer,
  reminderstatus,
} from './Store/PlacementReminders.redux';
import { credentialWorkDeskPlacementReminderSaga } from './Store/PlacementReminders.saga';
import {
  selectRemindersGridData,
  selectRemindersLoading,
  selectRemindersSourceData,
  selectStateID,
} from './Store/PlacementReminders.selector';
import { selectPlacementDeskChips } from './Store/PlacementReminders.selector';
import { PlacementReminderColumns } from './Table/PlacementRemindersHeaders';
import { PlacementRemindersList } from './Table/PlacementRemindersList';
import { dateIsAfterToday, dateIsToday } from 'app/helpers/dateHelper';
import moment from 'moment';
import { gridStateActions } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import { createFilterChips, getFilteredData } from './Helpers/PlacementRemindersFilterHelper';
import { selectCredPlacementsReminderGrid } from 'app/components/Common/WorkDesk/PipelineChoice/pipelineChoice.selectors';
import { defaultFilterValues } from './Filters/PlacementRemindersConfig';

export const PlacementRemindersWrapper = props => {
  const formMethods = useForm({ defaultValues: defaultFilterValues, shouldUnregister: false });
  const { handleSubmit, reset } = formMethods;
  const hiddenSubmitButton = createRef<HTMLButtonElement>();
  const dispatch = useDispatch();
  useInjectReducer({ key: placementReminderSliceKey, reducer: placementReminderTotalsReducer });
  useInjectSaga({ key: placementReminderSliceKey, saga: credentialWorkDeskPlacementReminderSaga });

  const pipelineSelection = useSelector(selectCredPlacementsReminderGrid);
  const flattenedData = useSelector(selectRemindersGridData);
  const remindersSourceData = useSelector(selectRemindersSourceData);
  const selectedStateID = useSelector(selectStateID);
  const chips = useSelector(selectPlacementDeskChips);
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const loading = useSelector(selectRemindersLoading);
  const [tableData, setTableData] = useState<any[]>([]);

  useEffect(() => {
    dispatch(placementReminderActions.requestCredentialingWorkDeskPlacementReminderList());
    reset({
      ...defaultFilterValues,
    });

    //Set back to initial state when component un-mounted.
    return () => {
      dispatch(placementReminderActions.reset());
      dispatch(gridStateActions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTableColumns(PlacementReminderColumns.reduce((resultArray, item) => [...resultArray, item], [] as any[]));
    loadGridData(selectedStateID);
  }, [flattenedData, selectedStateID]);

  const title = selectedID => {
    switch (selectedID) {
      case 'today':
        return reminderstatus.today;
      case 'scheduled':
        return reminderstatus.sheduled;
      case 'all':
        return reminderstatus.all;
      default:
        return '';
    }
  };

  const loadGridData = useCallback(
    (selectedID?: any) => {
      switch (selectedID) {
        case 'today':
          setTableData([...(flattenedData?.filter(item => dateIsToday(moment(item.followUpDate))) || [])]);
          break;
        case 'scheduled':
          setTableData([...(flattenedData?.filter(item => dateIsAfterToday(moment(item.followUpDate))) || [])]);
          break;
        case 'all':
          setTableData([...flattenedData]);
          break;
        default:
          break;
      }
      if (selectedID) {
        dispatch(
          placementReminderActions.setFilterChips({
            ...chips,
            pipelineSelected: [
              ...pipelineSelection.map(item => ({
                label: title(selectedID),
                enableDelete: selectedID !== 'all' ? true : false,
                value: selectedID,
              })),
            ],
          }),
        );
      }
    },
    [flattenedData],
  );

  const sidePanelItems = [
    {
      title: 'Quick View',
      component: <div style={{ minWidth: 236 }} />,
    },
    { title: 'Advanced Filter', component: <div /> },
  ];
  //EVENTS
  const onApplyFilter = (values, selectedChips?: IFilterChipSelected) => {
    dispatch(gridStateActions.setFilterAttributes({ filterObject: values }));
    const filteredData = getFilteredData(values, remindersSourceData, selectedChips);

    dispatch(
      placementReminderActions.setFilterChips({
        ...chips,
        ...createFilterChips(values, !!selectedChips ? selectedChips : undefined),
      }),
    );
    dispatch(placementReminderActions.setFilteredData(filteredData));
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(filter => onApplyFilter(filter))}>
          <HomePage
            loading={loading}
            title="Placement Reminders"
            stickIconArray={[3, 2]}
            dataVisualization={<PlacementReminderPipeline stickChosen={null} />}
            filters={<PlacementReminderFilter onApplyFilter={onApplyFilter} />}
            table={<PlacementRemindersList tableColumns={tableColumns} tableData={tableData} />}
            chips={<PlacementRemindersChips chips={chips} onApplyFilter={onApplyFilter} />}
            sidePanelItems={sidePanelItems}
          />
          <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
        </form>
      </FormProvider>
    </>
  );
};
