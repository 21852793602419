/* eslint-disable radix */

import { Grid, IconButton, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { getMonth, getYear } from 'date-fns';

const useStyles = makeStyles()(() => ({
  root: {
    borderRadius: '5%',
  },
  iconContainer: {
    padding: 5,
  },
  icon: {
    padding: 10,
    '&:hover': {
      background: 'none',
    },
  },
}));

interface HeaderProps {
  date: Date;
  setDate: (date: Date) => void;
  nextDisabled: boolean;
  prevDisabled: boolean;
  onClickNext: () => void;
  onClickPrevious: () => void;
}

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

const Header: React.FunctionComponent<HeaderProps> = ({
  date,
  setDate,
  nextDisabled,
  prevDisabled,
  onClickNext,
  onClickPrevious,
}: HeaderProps) => {
  const { classes } = useStyles();

  return (
    <Grid container justifyContent="space-between" alignItems="center" className={classes.root}>
      <Grid item className={classes.iconContainer}>
        {!prevDisabled ? (
          <IconButton
            color="primary"
            className={classes.icon}
            onClick={onClickPrevious}
            data-testid={'header-previous-action'}
            size="small">
            <ChevronLeft color={prevDisabled ? 'disabled' : 'action'} />
          </IconButton>
        ) : null}
      </Grid>
      <Grid item>
        <Typography variant="h6">{MONTHS[getMonth(date)]} </Typography>
      </Grid>

      <Grid item>
        <Typography variant="h6">{getYear(date)}</Typography>
      </Grid>
      <Grid item className={classes.iconContainer}>
        {!nextDisabled ? (
          <IconButton
            color="primary"
            className={classes.icon}
            disabled={nextDisabled}
            onClick={onClickNext}
            data-testid={'header-next-action'}
            size="small">
            <ChevronRight color={nextDisabled ? 'disabled' : 'action'} />
          </IconButton>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default Header;
