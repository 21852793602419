import React, { useState } from 'react';
import { CircularProgress, Grid, ListItem } from 'amn-ui-core';
import { APIStatus } from 'app/enums/ApiStatus';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from 'app/ComponentLibrary/Filter/AutoCompleteTextBox/AutoCompleteTextBoxStyles';
import { TypeAhead } from 'app/components/Common/TypeAheads/TypeAhead';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ITypeAheadOption } from '../CandidateTabPanel/PreferencesTab/CustomComponents/ControlledTypeAhead';
import { getSearchLookups } from 'app/services/SharedServices/SharedServices';
import { ExceptionType } from 'app/enums/Common';
import { trackException } from 'app-insights/appInsightsTracking';
import { makeStyles } from 'tss-react/mui';
import { ErrorOutlineRounded, Warning, WarningRounded } from '@mui/icons-material';

const useRecruiterStyles = makeStyles()(() => ({
  popper: { width: 'fit-content !important' },
  input: { background: 'white' },
}));

interface IManageRecruiterProps {
  options: ITypeAheadOption[];
  open: boolean;
  showErrorMesssage?: boolean;
  handleClose: () => void;
  handleSave: (event) => void;
  onChange?: (event) => void;
  saveStatus: number | string;
  saveBtnId: string;
  closeBtnId: string;
  selected?: any;
  updatedSearchList?: any;
}

const ManageRecruiter = ({
  options,
  open,
  handleClose,
  handleSave,
  showErrorMesssage = false,
  onChange,
  saveStatus,
  saveBtnId,
  closeBtnId,
  selected,
  updatedSearchList,
}: IManageRecruiterProps) => {
  const [selectedValue, setSelectedValue] = useState<{ ID: string; Description: string }>();
  const [recuiterOptions, setRecuiterOptions] = useState<any[]>(options);
  const [isOptionsLoading, setIsOptionsLoading] = useState<boolean>(false);
  const { classes } = useStyles();
  const { classes: popperClass } = useRecruiterStyles();

  const fetchData = async recuiterValueTem => {
    setIsOptionsLoading(true);
    try {
      const recuitersList = await getSearchLookups('recruiters', recuiterValueTem);
      setRecuiterOptions(
        recuitersList.map(item => {
          return { label: item.name };
        }),
      );
      updatedSearchList(recuitersList);
    } catch (error) {
      trackException({
        exception: error,
        properties: {
          name: ExceptionType.APIRequestError,
          functionName: 'fetchData',
          area: 'src/app/components/Candidate/CandidateProfile/CandidateHeader/CandidateHeader.tsx',
        },
      });
    }
    setIsOptionsLoading(false);
  };

  return (
    <Grid container>
      <Grid item xs={7}>
        <TypeAhead
          variant="standard"
          options={recuiterOptions || []}
          popupIcon={<ExpandMoreIcon />}
          onChange={newValue => {
            setSelectedValue(newValue);
            onChange?.(newValue);
          }}
          isOptionsLoading={isOptionsLoading}
          enableCustomScroll={true}
          removeCloseIcon
          inputValue={selected || ''}
          isCustomTheme={true}
          onInputChange={fetchData}
          customClasses={{ popper: popperClass.popper }}
        />
        {/* Save status Icons-Progrss,Saved and Failed */}
        {saveStatus === APIStatus.isLoading && !showErrorMesssage && <CircularProgress style={{ width: '12px', height: '12px' }} />}
        {saveStatus === APIStatus.Completed && !showErrorMesssage && (
          <>
            <CheckIcon style={{ width: '15px', height: '15px', color: '#008000' }} />
            <label className={classes.saveSuccessText}>{`Saved`}</label>
          </>
        )}
        {saveStatus === APIStatus.Error && (
          <>
            <CloseIcon style={{ width: '15px', height: '15px', color: '#ff0000' }} />
            <label className={classes.saveFailedText}>{`Failed to update`}</label>
          </>
        )}
      {
        showErrorMesssage &&  (<>
        <ErrorOutlineRounded style={{ width: '15px', height: '15px', color: '#ff0000' }} />
            <label className={classes.saveFailedText}>{`Required`}</label></>)
      }
      </Grid>
      <Grid item>
        {/* Render only if clicked inside Text field */}
        <span>
          {/* save Icon */}
          <span
            id={saveBtnId}
            className={`${classes.inlineBoxEditItemPadding} ${classes.inlineBoxEditMainAcceptItem}`}
            onClick={() => handleSave(selectedValue)}
            aria-hidden="true"
          >
            <CheckIcon data-testid="saveIcon" id="check-icon-race-ethnicity-icon" />
          </span>

          {/* close Icon */}
          <span
            id={closeBtnId}
            className={`${classes.inlineBoxEditItemPadding} ${classes.inlineBoxEditMainRejectItem}`}
            onClick={() => handleClose()}
            onKeyDown={() => handleClose()}
            aria-hidden="true"
          >
            <CloseIcon data-testid="closeIcon" id="close-icon-race-ethnicity-icon" />
          </span>
        </span>
      </Grid>
    </Grid>
  );
};

export default ManageRecruiter;
