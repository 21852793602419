import { Controller, useFormContext, useWatch } from 'react-hook-form';
import React, { useState, useEffect } from 'react';
import { Alert, Grid } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';
import FormInputTextField from '@AMIEWEB/Candidate/CandidateProfile/WorkExperience/Edit/components/FormInputTextField';
import { SimpleCheckbox } from '@AMIEWEB/Unit/Common/SimpleCheckbox';
import { FormInputDropdown } from '../PersonalInfoTab/Form/Controls/FormInputDropdown';
import { useRequestReferenceFormFieldStyles } from './style';
import { referenceTypeOptions, IDropdownOptions } from './helper';

export const RequestReferenceFormFields = props => {
  const { placementOptions, addRefRequestDisabled } = props;  
  const { classes } = useRequestReferenceFormFieldStyles();
  const { t } = useTranslation();
  const { control, getValues, setValue } = useFormContext();
  const [referenceTypeSelected, setReferenceTypeSelected] = useState<string | null>(null);
  const [refTypeOptions, setRefTypeOptions] = useState<IDropdownOptions[]>([]);

  const isPlacement: boolean = useWatch({ name: 'placementRadio' });

  useEffect(() => {
    const refTypes = referenceTypeOptions();;
    const options = isPlacement ? refTypes?.filter(item => item.ID === 3) : refTypes?.filter(item => item.ID !== 3); //ID = 3 >> Placement
    setRefTypeOptions(options?.length ? options : []);
  }, [isPlacement]);

  useEffect(() => {
    setReferenceTypeSelected(placementOptions?.length ? 'Placement' : null);
    setValue('referenceType', placementOptions?.length ? 'Placement' : null);
    setValue('placementRadio', placementOptions?.length ? true : false);
    setValue('candidateRadio', placementOptions?.length ? false : true);
  }, [placementOptions]);

  const handleReferenceTypeChange = event => {
    setValue('referenceType', event.target.value, { shouldDirty: true });
    setReferenceTypeSelected(event.target.value);
  };

  return (
    <Grid container rowSpacing={4} direction={'column'} mt={0} mb={4}>
      {!addRefRequestDisabled && !(placementOptions?.length > 0) && (
        <Grid item>
          <Alert severity="warning">{t('candidate.referenceGrid.noPlacementIdErrorMessage')}</Alert>
        </Grid>
      )}
      {addRefRequestDisabled && (
        <Grid item>
          <Alert severity="error">{t('candidate.referenceGrid.disabledErrorMessage')}</Alert>
        </Grid>
      )}
      <Grid item pl={1}>
        <Controller
          name={'placementRadio'}
          control={control}
          render={({ onChange, ...rest }) => (
            <SimpleCheckbox
              hasRadio={true}
              isdisabled={addRefRequestDisabled || !(placementOptions.length > 0)}
              isChecked={rest.value || false}
              handleChecked={event => {
                onChange(event.target.checked);
                setValue('candidateRadio', false);
                setReferenceTypeSelected('Placement');

                setValue('referenceType', 'Placement');
              }}
              checkboxLabel={'Placement'}
              checkboxName={'AddReferenceRequestPlacementRadio'}
              id={'AddReferenceRequestPlacementRadio'}
            />
          )}
        />
        <Controller
          name={'candidateRadio'}
          control={control}
          render={({ onChange, ...rest }) => (
            <SimpleCheckbox
              hasRadio={true}
              isdisabled={addRefRequestDisabled}
              isChecked={rest.value || false}
              handleChecked={event => {
                onChange(event.target.checked);
                setValue('placementRadio', false);
                setReferenceTypeSelected(null);
                setValue('referenceType', null);
              }}
              checkboxLabel={'Candidate'}
              checkboxName={'AddReferenceRequestCandidateRadio'}
              id={'AddReferenceRequestCandidateRadio'}
            />
          )}
        />
      </Grid>
      {isPlacement && (
        <Grid item>
          <FormInputDropdown
            name={'placementId'}
            label={`Placement ID*`}
            require={true}
            options={placementOptions || []}
            handleChange={event => setValue('placementId', event.target.value, { shouldDirty: true })}
            inputValue={getValues('placementId')}
            disabled={addRefRequestDisabled}
            standard
            useMenuItem
          ></FormInputDropdown>
        </Grid>
      )}
      <Grid item>
        <FormInputDropdown
          key={'referenceType'}
          name={'referenceType'}
          label={`Reference Type*`}
          require={true}
          options={refTypeOptions || []}
          clearButton={isPlacement}
          handleChange={handleReferenceTypeChange}
          inputValue={referenceTypeSelected}
          readOnly={isPlacement}
          disabled={addRefRequestDisabled || isPlacement}
          standard
          useMenuItem
        ></FormInputDropdown>
      </Grid>
      <Grid item>
        <FormInputTextField
          name="notes"
          label={'Notes'}
          required={false}
          multiline={true}
          placeholder={t('candidate.referenceGrid.notesPlaceholder')}
          disabled={addRefRequestDisabled}
          InputProps={{
            className: classes.textAreaInputStyle,
            inputProps: { maxLength: 1000 },
          }}
          showHelperText={true}
          characterLimit={1000}
        />
      </Grid>
    </Grid>
  );
};
