/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, CanceledError } from 'axios';
import { accessTokenRequest, loginTokenRequest } from 'oidc/userManager';
import { store } from 'app/ApplicationRoot/ApplicationRoot';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React from 'react';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { httpSuccess } from 'app/services/serviceHelpers';
import { PageLoadTracker, trackEvent, trackException } from 'app-insights/appInsightsTracking';

const apiConfig: AxiosRequestConfig = {
  baseURL: globalThis?.app?.env?.REACT_APP_API_URL,
  headers: {
    Accept: 'Application/json',
    'Content-Type': 'Application/json',
  },
};

export const baseAxiosInstance = axios.create(apiConfig);

const handleGlobalError = status => {
  const { dispatch } = store;
  switch (status) {
    case 401:
      dispatch(
        globalActions.setSnackBar({
          message: 'Missing or invalid authentication token. Please refresh your page to continue',
          severity: 'error',
        }),
      );
      break;
    default:
      break;
  }
};

export const setAxiosEmployeeID = employeeID => {
  baseAxiosInstance.defaults.headers.common.employeeID = employeeID;
};

export default baseAxiosInstance;

// const store = configureAppStore();
export function api() {
  // const user = store.getState()?.user;
  // const token = user?.auth?.access_token;
  return axios.create({
    baseURL: `${globalThis?.app?.env?.REACT_APP_API_URL}`,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${123}`,
      Prefer: 'return=representation',
    },
  });
}

export const useAxiosLoader = () => {
  const [counter, setCounter] = React.useState(0);
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const interceptors = React.useMemo(() => {
    const inc = () => setCounter(c => c + 1);
    const dec = () => setCounter(c => c - 1);

    return {
      request: (config: AxiosRequestConfig<any>) => {
        inc();
        const promise = new Promise<AxiosRequestConfig>((resolve, reject) => {
          if (isAuthenticated && inProgress === InteractionStatus.None) {
            instance
              .acquireTokenSilent(accessTokenRequest(accounts))
              .then(authResponse => {
                resolve({
                  ...config,
                  headers: {
                    ...config.headers,
                    Authorization: `Bearer ${authResponse.accessToken}`,
                    timezone: new Date()
                      .toLocaleTimeString('en-us', { timeZoneName: 'long' })
                      .split(' ')
                      .slice(2)
                      .join(' '),
                  },
                });
              })
              .catch(error => {
                if (error instanceof InteractionRequiredAuthError) {
                  // fallback to interaction when silent call fails
                  return instance.acquireTokenRedirect(loginTokenRequest);
                }
              });
          } else {
            reject('Unauthenticated');
          }
        });
        return promise;
      },
      requestError: (error: AxiosError) => {
        dec();
        trackEvent({ type: 'error', name: 'API Request Error', properties: { error: error } });
        if (!(error instanceof CanceledError))
          trackException({ exception: error, properties: { name: 'API Request Error' } });
        return Promise.reject(error);
      },
      response: (response: AxiosResponse<any, any>) => {
        PageLoadTracker.dataLoaded({ item: response.config.url });
        dec();
        if (!httpSuccess(response?.status) && response?.status !== 204) {
          trackEvent({ type: 'error', name: 'Network Error', properties: { response: response } });
          trackException({ exception: new Error('Network Error'), properties: { response: response } });
        }
        if (response.status === 401) {
          handleGlobalError(response.status);
          throw new Error(response.statusText);
        }
        return response;
      },
      responseError: (error: AxiosError) => {
        PageLoadTracker.dataLoaded({ item: error?.response?.config.url ?? 'not-found' });
        dec();
        trackEvent({ type: 'error', name: 'API Response Error', properties: { error: error } });
        if (!(error instanceof CanceledError))
          trackException({ exception: error, properties: { name: 'API Response Error' } });
        handleGlobalError(error?.response?.status);
        return Promise.reject(error);
      },
    };
  }, [accounts, inProgress, instance, isAuthenticated]); // create the interceptors

  React.useEffect(() => {
    // add request interceptors
    const reqInterceptor = baseAxiosInstance.interceptors.request.use(interceptors.request, interceptors.requestError);
    // add response interceptors
    const resInterceptor = baseAxiosInstance.interceptors.response.use(
      interceptors.response,
      interceptors.responseError,
    );
    return () => {
      // remove all intercepts when done
      baseAxiosInstance.interceptors.request.eject(reqInterceptor);
      baseAxiosInstance.interceptors.response.eject(resInterceptor);
    };
  }, [interceptors]);

  return [counter > 0];
};
