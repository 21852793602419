/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable i18next/no-literal-string */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { candidateDetailsSelection } from '../../../Profile/CandidateDetails.selector';
import treeData from 'app/assets/jsons/placementStatusFilter.json';
import _ from 'lodash';
import { useDecision } from '@optimizely/react-sdk';
import { ff_candidate_container_ui_v2 } from 'app/constants/FeatureFlags/Candidate/Keys';
import { AddEducationDialog } from '../../Common/AddEducationDialog';

import {
  CandidateMatch,
  EducationTooltipType,
  checkInvalidRecord,
  getUniqueItemsByProperties,
  pickHighestDegree,
  validateIncompleteRecord,
} from './helper';
import EducationalSummaryUI from './Card Design/EducationalSummaryUI';
import EducationalSummaryUIV2 from './Card Design/EducationalSummaryUIV2';
import { useStyles } from './Card Design/utils';
import { LayoutGrid } from 'app/layout/LayoutGrid';

export const EducationSummaryCard = props => {
  const candidateDetails = useSelector(candidateDetailsSelection);
  const { classes } = useStyles();
  const [skillsetsList, setSkillsetsList] = useState<CandidateMatch[]>([]);
  const [groupedSkillSetsList, setGroupedSkillSetsList] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [candidateContainerUIV2] = useDecision(ff_candidate_container_ui_v2);

  useEffect(() => {
    if (candidateDetails) {
      const skillsetsData: any =
        candidateDetails?.verifiedSkills.length > 0
          ? candidateDetails?.verifiedSkills
          : candidateDetails?.experience?.education.length > 0
          ? candidateDetails?.experience?.education
          : [];
      let skillsets = skillsetsData.map(item => {
        return {
          ...getSkillSetLabel(item),
        };
      });
      skillsets = getUniqueItemsByProperties(skillsets, 'skillset');
      setSkillsetsList(skillsets);

      skillsets = skillsets?.filter(s => s.skillset !== '');
      // const checkfinal = skillsets?.length > 0 ? skillsets?.length : 0;

      //////Logic for grouped skillsets object////////

      let groupedSkillsets = candidateDetails?.groupedSkillsSets?.map(item => {
        return {
          ...getGroupedSkillSetLabel(item, skillsets),
        };
      });
      groupedSkillsets = groupedSkillsets?.filter(s => s.skillset !== undefined);
      groupedSkillsets = _.uniqBy(groupedSkillsets, 'skillset');
      setGroupedSkillSetsList(groupedSkillsets);

      // const groupedCheckfinal = groupedSkillsets?.length > 0 ? groupedSkillsets?.length : 0;
    }
  }, [candidateDetails]);

  const booked = treeData.filter(item => item.name === 'Booked');
  const allowedStatuses: any[] = [];

  booked[0].children.map(item => {
    allowedStatuses.push(item.name);
  });

  const getSkillSetLabel = (item: any) => {
    if (item?.degree) {
      return {
        skillset: `${item?.major || ''} - ${item.degree || ''}`,
        tooltipMessage: getTooltipMessage(item, null, EducationTooltipType.education),
      };
    }
    const highestDegree = pickHighestDegree(item?.matchingDegreesMajors, false);
    return {
      skillset:
        item?.matchingDegree && item?.matchingMajor && highestDegree && highestDegree?.isGraduationDateValid
          ? `${item?.disciplineAbbr || ''} - ${highestDegree ? highestDegree?.degree : ''}`
          : '',
      tooltipMessage: getTooltipMessage(highestDegree, null, EducationTooltipType.verifiedList),
    };
  };

  const getTooltipMessage = (data, metaData, type) => {
    if (
      data &&
      type === EducationTooltipType.groupedList &&
      metaData.matchingDegree &&
      metaData.matchingMajor &&
      !data.isGraduationDateValid &&
      metaData.isVerified
    )
      return (
        <>
          <b className={classes.tooltipRowHeader}>{`Graduation Date - `}</b>
          {data?.month !== null ? (
            <>
              <span className={classes.tooltipRowContent}>
                {data?.month?.toString().length > 1 ? `${data.month}/${data.year}` : `0${data.month}/${data.year}`}
              </span>
              <br></br>
              <span className={classes.tooltipRowContent}>{`(Graduation date cannot be in the future)`}</span>
            </>
          ) : (
            <span className={classes.tooltipRowContent}>--</span>
          )}
          <br></br>
          <b className={classes.tooltipRowHeader}>{`Major - `}</b>
          <span className={classes.tooltipRowContent}>{data.major}</span>
        </>
      );

    if (
      data &&
      type === EducationTooltipType.groupedList &&
      metaData.matchingDegree &&
      !metaData.matchingMajor &&
      data.isGraduationDateValid &&
      metaData.isVerified
    )
      return (
        <>
          <b className={classes.tooltipRowHeader}>{`Graduation Date - `}</b>
          <span className={classes.tooltipRowContent}>
            {data.month?.toString().length > 1 ? `${data.month}/${data.year}` : `0${data.month}/${data.year}`}
          </span>
          <br></br>
          <b className={classes.tooltipRowHeader}>{`Major - `}</b>
          <span className={classes.tooltipRowContent}>{`(No matching major)`}</span>
        </>
      );

    if (
      data &&
      type === EducationTooltipType.groupedList &&
      metaData.matchingDegree &&
      metaData.matchingMajor &&
      !metaData.isVerified
    )
      return (
        <>
          <b className={classes.tooltipRowHeader}>{`Graduation Date - `}</b>
          <span className={classes.tooltipRowContent}>
            {data.month?.toString().length > 1 ? `${data.month}/${data.year}` : `0${data.month}/${data.year}`}
          </span>
          <br></br>
          <b className={classes.tooltipRowHeader}>{`Major - `}</b>
          <span className={classes.tooltipRowContent}>{data.major}</span>
          <br></br>
          <span className={classes.tooltipRowContent}>{`(Skillset verification pending)`}</span>
        </>
      );
    if (type === EducationTooltipType.groupedList && metaData.isMissingDisciplineEducation)
      return (
        <>
          <span className={classes.tooltipRowContent}>{`Matching degree requirements are not defined`}</span>
        </>
      );

    if (type === EducationTooltipType.groupedList && !metaData.matchingDegree)
      return (
        <>
          <span className={classes.tooltipRowContent}>{`No matching degree found`}</span>
        </>
      );

    if (type === EducationTooltipType.education) {
      return (
        <>
          <b className={classes.tooltipRowHeader}>{`Graduation Date - `}</b>
          <span className={classes.tooltipRowContent}>
            {data?.graduatedOnMonth?.toString().length > 1
              ? `${data.graduatedOnMonth}/${data.graduatedOnYear}`
              : `0${data.graduatedOnMonth}/${data.graduatedOnYear}`}
          </span>
          <br></br>
          <b className={classes.tooltipRowHeader}>{`Major - `}</b>
          <span className={classes.tooltipRowContent}>{data.major}</span>
        </>
      );
    }

    if (data)
      return (
        <>
          <b className={classes.tooltipRowHeader}>{`Graduation Date - `}</b>
          <span className={classes.tooltipRowContent}>
            {data?.month !== null
              ? data.month.toString().length > 1
                ? `${data.month}/${data.year}`
                : `0${data.month}/${data.year}`
              : '--'}
          </span>
          <br></br>
          <b className={classes.tooltipRowHeader}>{`Major - `}</b>
          <span className={classes.tooltipRowContent}>{data.major}</span>
        </>
      );
  };

  const getGroupedSkillSetLabel = (item: any, verifiedSkillSetData) => {
    var flag = true;
    const highestDegree = pickHighestDegree(item?.matchingDegreesMajors, true);
    const tempSkillset = {
      skillset: checkInvalidRecord(item)
        ? `${item?.disciplineAbbr || ''}`
        : `${item?.disciplineAbbr || ''} - ${highestDegree ? highestDegree?.degree : ''}`,
      tooltipMessage: getTooltipMessage(highestDegree, item, EducationTooltipType.groupedList),
      showIncompleteAlert: validateIncompleteRecord(item, highestDegree),
      showAlert: checkInvalidRecord(item),
    };
    verifiedSkillSetData.map(x => {
      if (x.skillset === tempSkillset.skillset) {
        flag = false;
      }
    });
    if (flag) return tempSkillset;
    else
      return {
        skillset: undefined,
        tooltipMessage: undefined,
        showIncompleteAlert: undefined,
      };
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleNavigate = () => {
    setOpen(true);
  };

  return (
    <>
      <LayoutGrid className={classes.grid}>
        {candidateContainerUIV2?.enabled ? (
          <EducationalSummaryUIV2
            groupedSkillSetsList={groupedSkillSetsList}
            skillsetsList={skillsetsList}
            handleNavigate={() => handleNavigate()}
            showAllEducation={props.showAllEducation}
          />
        ) : (
          <EducationalSummaryUI
            open={open}
            groupedSkillSetsList={groupedSkillSetsList}
            skillsetsList={skillsetsList}
            handleClose={() => handleClose()}
            handleNavigate={() => handleNavigate()}
            showAllEducation={props.showAllEducation}
          />
        )}
      </LayoutGrid>
      {open && <AddEducationDialog open={open} handleClose={handleClose} />}
    </>
  );
};
