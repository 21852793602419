import { Box, Typography, Grid, Button, Divider, Skeleton } from 'amn-ui-core';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonPopUpforWorkAndEducation } from '../../../../Common/CommonPopUpforWorkAndEducation';
import { RowSection } from '../../../Common/RowSection';
import { RowSectionTitle } from '../../../Common/RowSectionTitle';
import { IExperienceRow, setupExperienceData } from '../helpers';
import { useSelector } from 'react-redux';
import { candidateDetailsSelection } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import {
  ff_summaryTabIconsUpdate,
  ff_candidate_container_ui_v2,
  ff_WorkExperience_Enhancement,
} from 'app/constants/FeatureFlags/Candidate/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { ICandidateDetails, Location, UnVerifiedSkill, VerifiedSkill } from 'app/models/Candidate/CandidateProfile';
import { IconType, RowSectionV2 } from '../../../Common/RowSectionV2/RowSectionV2';
import { groupBy, uniq } from 'lodash';
import { Row } from './ExperienceSection.styles';
import { getExperienceText } from './helpers';
import { CandidateSubHeader } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/SummaryTab/Common/CandidateSubHeader';
import AddIcon from '@mui/icons-material/Add';
import { selectUser } from 'oidc/user.selectors';
import { hasAddModalAccess } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/WorkExperienceTab/components/EditWorkExperience/WorkExperienceUtils';
import { usePromiseTracker } from 'react-promise-tracker';
import { AddWorkExperienceWrapper } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/WorkExperienceTab/components/AddWorkExperience/AddWorkExperienceWrapper';
import { missingField } from 'app/constants';
import { theme } from 'styles/global-styles';
import { selectWorkExperienceView } from 'store/redux-store/candidate-work-experience/selector';
import { ISkillSetData } from 'store/redux-store/candidate-work-experience/types';

const ExperienceSection = props => {
  const { classes } = props;
  const { t } = useTranslation();
  const UNKNOWN = 'Unknown';
  const candidateDetails = useSelector(candidateDetailsSelection) as ICandidateDetails;
  const { workExperienceData } = useSelector(selectWorkExperienceView);
  const [open, setOpen] = useState<boolean>(false);
  const [rowData, setRowData] = useState<IExperienceRow[]>([]);
  const [experienceChecks, setExperienceChecks] = useState<number>(0);
  const [summaryTabIconsUpdateDecision] = useDecision(ff_summaryTabIconsUpdate);
  const [candidateContainerUIV2] = useDecision(ff_candidate_container_ui_v2);
  const [noExperienceRecords, setNoExperienceRecords] = useState([]);
  const experienceCount = workExperienceData?.employment?.length || 0;
  const user = useSelector(selectUser);
  const [enhancedWorkExperienceModal] = useDecision(ff_WorkExperience_Enhancement);
  const { promiseInProgress } = usePromiseTracker({ area: 'get-candidate-details', delay: 0 });
  const addModalAccess = useMemo(() => hasAddModalAccess(user), [user]);
  const employmentGapDays = workExperienceData?.candidateWorkExperience?.employmentGapDays;

  const getSkillsetMatch = useCallback(
    (disciplineAbbr: string, specialtyAbbr: string) => {
      return candidateDetails.verifiedSkills.some(
        e => e.disciplineAbbr === disciplineAbbr && e.specialtyAbbr === specialtyAbbr,
      );
    },
    [candidateDetails.verifiedSkills],
  );

  const isSkillSetUnVerified = useCallback(
    (disciplineAbbr: string, specialtyAbbr: string) => {
      return candidateDetails.unVerifiedSkills.some(
        e => e.disciplineAbbr === disciplineAbbr && e.specialtyAbbr === specialtyAbbr,
      );
    },
    [candidateDetails.unVerifiedSkills],
  );

  const doesSkillsetExists = useCallback(
    (disciplineAbbr: string, specialtyAbbr: string) => {
      return getSkillsetMatch(disciplineAbbr, specialtyAbbr) || isSkillSetUnVerified(disciplineAbbr, specialtyAbbr);
    },
    [getSkillsetMatch, isSkillSetUnVerified],
  );

  const groupEmployment = useMemo(() => {
    return groupBy(workExperienceData?.employment, item => {
      if (item.specialtyAbbr) {
        return [item.disciplineAbbr + '-' + item.specialtyAbbr];
      }
      return [item.disciplineAbbr];
    });
  }, [workExperienceData?.employment]);

  useEffect(() => {
    // setup data for experience on discipline and specialty
    if (workExperienceData?.skillsets?.length > 0 && workExperienceData?.employment?.length > 0) {
      let data = workExperienceData?.skillsets
        .filter(item => item.disciplineAbbr !== (UNKNOWN || null))
        .map(e => {
          return setupExperienceData(
            e,
            !isSkillSetUnVerified(e.disciplineAbbr, e.specialtyAbbr),
            doesSkillsetExists(e.disciplineAbbr, e.specialtyAbbr),
          );
        });
      setRowData(data ?? []);
    } else {
      setRowData([]);
    }

    if (candidateDetails.verifiedSkills && candidateDetails.unVerifiedSkills) {
      const verifiedSkillset = candidateDetails.verifiedSkills.map((e: VerifiedSkill) => {
        return {
          disciplineAbbr: e.disciplineAbbr,
          specialtyAbbr: e.specialtyAbbr,
        };
      });

      const unVerifiedSkillset = candidateDetails.unVerifiedSkills.map((e: UnVerifiedSkill) => {
        return {
          disciplineAbbr: e.disciplineAbbr,
          specialtyAbbr: e.specialtyAbbr,
        };
      });
      const mentionedSkillsets = uniq(unVerifiedSkillset.concat(verifiedSkillset));
      const skillsetWithExperience = (workExperienceData?.skillsets ?? []).map((e: ISkillSetData) => {
        return {
          disciplineAbbr: e.disciplineAbbr,
          specialtyAbbr: e.specialtyAbbr,
        };
      });
      const skillsetWithNoExperience = mentionedSkillsets.filter(
        item =>
          !skillsetWithExperience.some(
            e => e.disciplineAbbr === item.disciplineAbbr && e.specialtyAbbr === item.specialtyAbbr,
          ),
      );
      setNoExperienceRecords(skillsetWithNoExperience);
    }

    // other logic
    let count = 0;
    workExperienceData?.skillsets?.forEach(x => {
      if (getSkillsetMatch(x.disciplineAbbr, x.specialtyAbbr) && x.yearsOfExperience > 2) {
        count += 1;
      }
    });

    if (workExperienceData?.maxEmploymentGap !== null && workExperienceData?.maxEmploymentGap === 0) {
      count += 1;
    }
    setExperienceChecks(count);
  }, [
    workExperienceData.employment,
    candidateDetails.unVerifiedSkills,
    candidateDetails.verifiedSkills,
    doesSkillsetExists,
    getSkillsetMatch,
    isSkillSetUnVerified,
  ]);

  const formatWorkExpGapsData = (workGapDetails: any[]) => {
    if (workGapDetails?.length < 1 || workGapDetails === null) {
      //show No Work Exp Gap
      return t('No Work History Gap');
    } else {
      //show Work Exp Gap Count
      return (
        <span>
          <span>{`Work History Gap: `}</span>
          <span>
            <Button
              type="button"
              variant="text"
              disableRipple
              disableTouchRipple
              disableFocusRipple
              classes={{
                root: classes.countButton,
              }}
            >
              {`${workGapDetails?.length}`}
            </Button>
          </span>
        </span>
      );
    }
  };

  const displayWorkExpGapToolTip = useCallback(() => {
    const hasGraduationDate = workExperienceData?.candidateWorkExperience?.hasGraduationDate;
    const workGapDetailsArray = employmentGapDays;
    return (
      <>
        <div className={classes.customScrollWorkExpGap}>
          {workGapDetailsArray &&
            workGapDetailsArray?.map((item, index) => {
              return (
                <div style={{ display: 'flexbox', flexDirection: 'row', padding: '5px' }} key={index}>
                  <Box>
                    <Typography variant="subtitle2" gutterBottom>
                      <span style={{ paddingLeft: '2px' }}>{`${item.gapDays} Days`} </span>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1" gutterBottom>
                      {`${item.startDate ? moment(item.startDate).format('MM/DD/YYYY') : missingField} - ${
                        item.endDate ? moment(item.endDate).format('MM/DD/YYYY') : missingField
                      }`}
                    </Typography>
                  </Box>
                </div>
              );
            })}
          {!hasGraduationDate && (
            <Typography variant="subtitle2" gutterBottom className={classes.missingEducationMessage}>
              {t(`candidate.workExperienceCard.missingGraduation`)}
            </Typography>
          )}
        </div>
      </>
    );
  }, [classes.customScrollWorkExpGap, employmentGapDays]);

  const addressOfEmployer = (e: Location) => {
    const city = e.city ? `${e.city}` : '';
    const state = e.state ? `, ${e.state}` : '';
    const country = e.country ? `, ${e.country}` : '';
    const address = `${city}${state}${country}`;

    return (
      <Row>
        <Typography variant="caption">{address}</Typography>
      </Row>
    );
  };

  const getTooltipContent = (key: string, row: IExperienceRow) => {
    const data = groupEmployment[key];
    return (
      <>
        <Grid container direction="row" wrap="nowrap">
          {data &&
            data?.length &&
            data.map(
              (e, index, list) =>
                index % 2 === 0 && (
                  <Grid container direction="row" key={index}>
                    <Grid container spacing={2} direction="column" style={{ width: '230px' }}>
                      <Grid key={index} item style={{ height: '150px' }}>
                        {!row.isVerifiedSkill && row.doesSkillSetExists && (
                          <Row>
                            <Typography variant="caption">
                              {t(`candidate.workExperienceCard.skillsetVerificationPending`)}
                            </Typography>
                          </Row>
                        )}
                        {!row.doesSkillSetExists && (
                          <Row>
                            <Typography variant="caption">
                              {t(`candidate.workExperienceCard.noSkillsetExists`)}
                            </Typography>
                          </Row>
                        )}
                        <Row>
                          <Typography variant="caption">{list[index]?.facilityName}</Typography>
                        </Row>
                        {addressOfEmployer(list[index]?.location)}
                        <Row>
                          <b>{t('candidate.workExperienceCard.experienceLength')}</b>
                          <Typography variant="caption">{getExperienceText(list[index])}</Typography>
                        </Row>
                        <Row>
                          <b>{t('candidate.workExperienceCard.startDate')}</b>
                          <Typography variant="caption">
                            {moment(list[index]?.startedOn).format('MM/DD/YYYY')}
                          </Typography>
                        </Row>
                        <Row>
                          <b>{t('candidate.workExperienceCard.endDate')}</b>
                          <Typography variant="caption">
                            {list[index].endedOn ? moment(list[index].endedOn).format('MM/DD/YYYY') : ' '.concat('--')}
                          </Typography>
                        </Row>
                      </Grid>
                      {list[index + 1] && (
                        <>
                          <Grid key={index} item style={{ width: '200px' }}>
                            <Divider style={{ marginBottom: '6px', width: '95%' }} />
                            {!row.isVerifiedSkill && (
                              <Row>
                                <Typography variant="caption">
                                  {t(`candidate.workExperienceCard.skillsetVerificationPending`)}
                                </Typography>
                              </Row>
                            )}
                            {!row.doesSkillSetExists && (
                              <Row>
                                <Typography variant="caption">
                                  {t(`candidate.workExperienceCard.noSkillsetExists`)}
                                </Typography>
                              </Row>
                            )}
                            <Row>
                              <Typography variant="caption">{list[index + 1]?.facilityName}</Typography>
                            </Row>
                            {addressOfEmployer(list[index + 1].location)}
                            <Row>
                              <b>{t('candidate.workExperienceCard.experienceLength')}</b>
                              <Typography variant="caption">{getExperienceText(list[index + 1])}</Typography>
                            </Row>
                            <Row>
                              <b>{t('candidate.workExperienceCard.startDate')}</b>
                              <Typography variant="caption">
                                {moment(list[index + 1]?.startedOn).format('MM/DD/YYYY')}
                              </Typography>
                            </Row>
                            <Row>
                              <b>{t('candidate.workExperienceCard.endDate')}</b>
                              <Typography variant="caption">
                                {list[index + 1].endedOn
                                  ? moment(list[index + 1].endedOn).format('MM/DD/YYYY')
                                  : '  '.concat('--')}
                              </Typography>
                            </Row>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                ),
            )}
        </Grid>
      </>
    );
  };

  const handleNavigate = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const experienceGapRow = (
    <>
      <Box className={candidateContainerUIV2?.enabled ? classes.alignExperienceContainer : classes.alignContainer}>
        <CustomTooltip
          style={{ color: theme.palette.framework.system.tertiaryGrey }}
          interactive={true}
          hideToolTip={employmentGapDays === null || employmentGapDays?.length < 1}
          tooltipContent={displayWorkExpGapToolTip()}
          tooltipPlacementBottom={classes.tooltipBottom}
          disabled={!summaryTabIconsUpdateDecision.enabled}
        >
          <RowSection
            header={formatWorkExpGapsData(employmentGapDays)}
            data={''}
            preData={employmentGapDays?.length < 1 ? true : false}
            color={experienceChecks === workExperienceData?.skillsets?.length + 1 ? 'Green' : 'Green'}
            showAlert={employmentGapDays?.length > 0 ? true : false}
          />
        </CustomTooltip>
      </Box>
      {rowData?.length ? (
        rowData &&
        rowData.map((row: IExperienceRow, index: number) => (
          <Box
            className={candidateContainerUIV2?.enabled ? classes.alignExperienceContainer : classes.alignContainer}
            key={index}
          >
            <CustomTooltip
              standardMargin
              tooltipContent={getTooltipContent(row.groupKey, row)}
              placement="bottom-start"
              disabled={!summaryTabIconsUpdateDecision.enabled}
              disableMaxWidth
            >
              <RowSectionV2 header={row.header} iconType={row.iconType} />
            </CustomTooltip>
          </Box>
        ))
      ) : (
        <RowSection
          header={
            <Grid container direction="column">
              <Grid item>{t('candidate.workExperienceCard.experienceUnavailableLine1')}</Grid>
              <Grid item>{t('candidate.workExperienceCard.experienceUnavailableLine2')}</Grid>
              <Grid item>
                <span className={classes.inlineLink} onClick={() => handleNavigate()}>
                  {t('candidate.workExperienceCard.addWorkExperience')}
                </span>
              </Grid>
            </Grid>
          }
          data={''}
          preData={''}
          color={experienceChecks === workExperienceData?.skillsets?.length + 1 ? 'Green' : '#006FB9'}
          showHalfwayProgress={true}
          showHalfwayProgressSecondaryIcon={true}
          showAlert={false}
        />
      )}
      {noExperienceRecords?.length > 0 &&
        noExperienceRecords.map((row, index) => (
          <RowSectionV2
            header={`${row.disciplineAbbr}-${row.specialtyAbbr}: ${t(
              'candidate.workExperienceCard.experienceNotAdded',
            )}`}
            iconType={IconType.OrangeAlert}
            key={index}
          />
        ))}
    </>
  );

  const candidateSubHeader = (
    <>
      <RowSectionTitle
        handleClick={e => {}}
        header={
          <div className={classes.boxInlineHeadLabelTxt}>
            <span>{t('Experience')}</span>
            <span className={classes.profileInlineLink} onClick={props.navigate}>
              {t('candidate.profileSummaryCard.all')}
            </span>
          </div>
        }
        data={
          <span className={classes.profileInlineLink} onClick={handleNavigate}>
            {t('candidate.profileSummaryCard.add')}
          </span>
        }
      />
      {promiseInProgress ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        experienceGapRow
      )}
    </>
  );

  const candidateSubHeaderV2 = (
    <>
      <CandidateSubHeader
        title={`${t('Experience')} (all)`}
        totalCount={experienceCount}
        showCount={true}
        rightIcon={<AddIcon color={addModalAccess ? 'inherit' : 'disabled'} />}
        iconToolTip={
          addModalAccess
            ? t('candidate.workExperienceCard.addWorkExperience')
            : t('candidate.workExperienceView.permissionDisabled')
        }
        onClick={() => (addModalAccess ? handleNavigate() : null)}
        onClickAll={props.navigate}
      >
        {promiseInProgress ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          experienceGapRow
        )}
      </CandidateSubHeader>
    </>
  );

  return (
    <>
      {candidateContainerUIV2?.enabled ? candidateSubHeaderV2 : candidateSubHeader}
      {open &&
        (enhancedWorkExperienceModal?.enabled ? (
          <AddWorkExperienceWrapper open={open} handleClose={handleClose} addType="work" />
        ) : (
          <CommonPopUpforWorkAndEducation open={open} handleClose={handleClose} addType={`work`} />
        ))}
    </>
  );
};

export default ExperienceSection;
