import { ICreateOrderPreferences, ISubmissionPreferences } from './types';

export const initialSubmissionPreferences: ISubmissionPreferences = {
  submissionLimits: { activePlacementCounts: null },
  wfcToRfo: false,
  candidateTasks: false,
  rfoToSent: false,
  sendPacket: false,
  savePacket: false,
  submissionEmailSetup: null,
  submissionPacketSetup: null,
};

export const initialState: ICreateOrderPreferences = {
  submissionPreferences: initialSubmissionPreferences,
  miscellaneous: {
    disableEmailContainer: true,
    disablePacketContainer: true,
  },
  isUnitPreferenceNull: false,
  unitDialoge: {
    dialogeOpen: false,
    dialogeItem: ''
  },
  isUnitInherited: false,
  tabValue: 0,
  customTabvalue: 0,
  formValues: null,
  loaded: false,
  isFormDirty:false
};
