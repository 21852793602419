import React, { useEffect } from 'react';
import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { selectWorkDeskCounts } from '../MyPlacementsDesk/RecruiterPlacements.selector';
import { useTranslation } from 'react-i18next';
import { IGraphicStepper } from 'app/ComponentLibrary/GraphicStepper/GraphicStepper';
import { selectRecPlacementsGrid } from '../../Common/WorkDesk/PipelineChoice/pipelineChoice.selectors';
import { pipelineChoiceActions, pipelineChoiceKeys } from '../../Common/WorkDesk/PipelineChoice/pipelineChoice.redux';
import { WorkDeskPipeline } from 'app/components/Common/WorkDesk';
import { Aggregate } from './Aggregate';
import { placementStatus, placementSubStatuses } from './utils';

const useStyles = makeStyles()({
  container: {
    flexWrap: 'nowrap',
    overflow: 'hidden',
  },
});

const nullCountString = '(0)';

export const Pipeline = props => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { pipeline, pipelineChoices, stickChosen } = props;
  const dispatch = useDispatch();

  const selection = useSelector(selectRecPlacementsGrid);

  const { candidates: candidateCounts, placements: placementCounts } = useSelector(selectWorkDeskCounts);
  const [pipelineCounts, setPipelineCounts] = React.useState<IGraphicStepper[]>(
    getPipelineCounts(candidateCounts, placementCounts, t),
  );

  useEffect(() => {
    setPipelineCounts(getPipelineCounts(candidateCounts, placementCounts, t));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateCounts, placementCounts]);

  useEffect(() => {
    return () => {
      dispatch(pipelineChoiceActions.resetByKey(pipelineChoiceKeys.REC_WORK_DESK_GRID));
    };
  }, [dispatch]);

  return (
    <Grid container spacing={2} direction="column" classes={{ container: classes.container }}>
      {pipeline === pipelineChoices.placements && (
        <Grid item container direction="row" wrap="nowrap">
          <WorkDeskPipeline
            data={pipelineCounts}
            selection={selection}
            gridSelectionKey={pipelineChoiceKeys.REC_WORK_DESK_GRID}
            noValuePipelineText={t('home.recruiterWorkDesk.pipeline.noValueText')}
            pipelineTotal={<Aggregate />}
            shrink={stickChosen !== null}
          />
        </Grid>
      )}
    </Grid>
  );
};

const getPipelineCounts = (candidateCounts, placementCounts, t) => [
  {
    category: {
      tag: `${t('home.recruiterWorkDesk.inSubmission')} ${
        candidateCounts.inSubmission
          ? `${candidateCounts.inSubmission} (${placementCounts.inSubmission})`
          : nullCountString
      }`,
      showTooltip: candidateCounts.inSubmission ? false : true,
      color: '#006FB9',
      value: placementStatus.inSubmission,
    },
    subcategory: [
      {
        tag: t('home.recruiterWorkDesk.wfc'),
        data: candidateCounts.waitingForClearance
          ? `${candidateCounts.waitingForClearance} (${placementCounts.waitingForClearance})`
          : 0,
        value: placementSubStatuses.wfc,
      },
      {
        tag: t('home.recruiterWorkDesk.rfo'),
        data: candidateCounts.requestFileOut
          ? `${candidateCounts.requestFileOut} (${placementCounts.requestFileOut})`
          : 0,
        value: placementSubStatuses.rfo,
      },
      {
        tag: t('home.recruiterWorkDesk.sent'),
        data: candidateCounts.sent ? `${candidateCounts.sent} (${placementCounts.sent})` : 0,
        value: placementSubStatuses.sent,
      },
      {
        tag: t('home.recruiterWorkDesk.intrwd'),
        data: candidateCounts.interviewed ? `${candidateCounts.interviewed} (${placementCounts.interviewed})` : 0,
        value: placementSubStatuses.intrwd,
      },
    ],
  },
  {
    category: {
      tag: `${t('home.recruiterWorkDesk.offer')} ${
        candidateCounts.offer ? `${candidateCounts.offer} (${placementCounts?.offer})` : nullCountString
      }`,
      showTooltip: candidateCounts.offer ? false : true,
      color: 'rgb(0, 139, 149)',
      value: placementStatus.offer,
    },
    subcategory: [
      {
        tag: t('home.recruiterWorkDesk.obf'),
        data: candidateCounts.offerByFacility
          ? `${candidateCounts.offerByFacility} (${placementCounts.offerByFacility})`
          : 0,
        value: placementSubStatuses.obf,
      },
      {
        tag: t('home.recruiterWorkDesk.obbt'),
        data: candidateCounts.offerBookedByTraveler
          ? `${candidateCounts.offerBookedByTraveler} (${placementCounts.offerBookedByTraveler})`
          : 0,
        value: placementSubStatuses.obbt,
      },
      {
        tag: t('home.recruiterWorkDesk.fc'),
        data: candidateCounts.facilityConfirms
          ? `${candidateCounts.facilityConfirms} (${placementCounts.facilityConfirms})`
          : 0,
        value: placementSubStatuses.fc,
      },
      {
        tag: t('home.recruiterWorkDesk.ta'),
        data: candidateCounts.travelerAccepts
          ? `${candidateCounts.travelerAccepts} (${placementCounts.travelerAccepts})`
          : 0,
        value: placementSubStatuses.ta,
      },
    ],
  },
  {
    category: {
      tag: `${t('home.recruiterWorkDesk.booked')} ${
        candidateCounts.bookedCategory
          ? `${candidateCounts.bookedCategory} (${placementCounts.bookedCategory})`
          : nullCountString
      }`,
      showTooltip: candidateCounts.bookedCategory ? false : true,
      color: '#003C69',
      value: placementStatus.booked,
    },
    subcategory: [
      {
        tag: t('home.recruiterWorkDesk.booked'),
        data: candidateCounts.bookedSubCategory
          ? `${candidateCounts.bookedSubCategory} (${placementCounts.bookedSubCategory})`
          : 0,
        value: placementSubStatuses.booked,
      },
      {
        tag: t('home.recruiterWorkDesk.ba'),
        data: candidateCounts.bookingApproved
          ? `${candidateCounts.bookingApproved} (${placementCounts.bookingApproved})`
          : 0,
        value: placementSubStatuses.ba,
      },
      {
        tag: t('home.recruiterWorkDesk.wgtg'),
        data: candidateCounts.waitingGoodToGo
          ? `${candidateCounts.waitingGoodToGo} (${placementCounts.waitingGoodToGo})`
          : 0,
        value: placementSubStatuses.wgtg,
      },
      {
        tag: t('home.recruiterWorkDesk.ppw'),
        data: candidateCounts.postponementWarning
          ? `${candidateCounts.postponementWarning} (${placementCounts.postponementWarning})`
          : 0,
        value: placementSubStatuses.ppw,
      },
      {
        tag: t('home.recruiterWorkDesk.gtg'),
        data: candidateCounts.goodToGo ? `${candidateCounts.goodToGo} (${placementCounts.goodToGo})` : 0,
        value: placementSubStatuses.gtg,
      },
    ],
  },
  {
    category: {
      tag: `${t('home.recruiterWorkDesk.onAssignment')} ${
        candidateCounts.onAssignment
          ? `${candidateCounts.onAssignment} (${candidateCounts.onAssignment})`
          : nullCountString
      }`,
      showTooltip: candidateCounts.onAssignment ? false : true,
      color: '#13A01E',
      value: placementStatus.onAssignment,
    },
    subcategory: [
      {
        tag: t('home.recruiterWorkDesk.oa'),
        data: candidateCounts.onAssignment ? `${candidateCounts.onAssignment} (${placementCounts.onAssignment})` : 0,
        value: placementSubStatuses.oa,
      },
    ],
  },
];
