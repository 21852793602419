import React from 'react';
import { useWorkExperienceStyles } from '../WorkExperience/WorkExperienceCard.style';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { Box, Divider, Skeleton } from 'amn-ui-core';
import ExperienceSection from '../WorkExperience/ExperienceSection/ExperienceSection';
import { RowSection } from '../../Common/RowSection';
import { useTranslation } from 'react-i18next';
import ApproveUnApprove from './SubComponent/ApproveUnApprove';
import { useSelector } from 'react-redux';
import {
  selectReferenceData,
  selectReferences,
} from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { usePromiseTracker } from 'react-promise-tracker';
import { CandidateContainer } from '../../Common/CandidateContainer';
import { CandidateSubHeader } from '../../Common/CandidateSubHeader';
import WorkExperienceIcon from 'app/assets/images/IconImages/WorkExperienceIcon.svg';
import AddIcon from '@mui/icons-material/Add';
import { useDecision } from '@optimizely/react-sdk';
import { ff_candidate_ui_manage_references, ff_summaryTabIconsUpdate } from 'app/constants/FeatureFlags/Candidate/Keys';
import { theme } from 'styles/global-styles';

function ExperienceUIV2({
  referenceDecision,
  showAllWorkExperience,
  showAllReferences,
  filterReferenceBy,
  addReferenceAuthorized,
  handleNavigation,
}) {
  const { classes } = useWorkExperienceStyles();
  const { t } = useTranslation();
  const selReferences = useSelector(selectReferences);
  const [showAddReference] = useDecision(ff_candidate_ui_manage_references);
  const { promiseInProgress } = usePromiseTracker({ area: 'get-candidate-summary-tab-details', delay: 0 });
  const referenceData = useSelector(selectReferenceData);
  const APPROVEDSTATUS = 'Approved';
  const UNAPPROVEDSTATUS = 'Unapproved';
  const [summaryTabIconsUpdateDecision] = useDecision(ff_summaryTabIconsUpdate);
  return (
    <>
      <LayoutGridItem pb="12px">
        <Box className={classes.container}>
          <CandidateContainer
            title={t('Work Experience')}
            icon={<img src={WorkExperienceIcon} alt="WorkExperienceIcon" />}
          >
            <ExperienceSection classes={classes} navigate={showAllWorkExperience} />
            {referenceDecision?.enabled && (
              <>
                <Divider sx={{ mb: '12px' }} />
                <CandidateSubHeader
                  title={`${t('References')} (all)`}
                  totalCount={selReferences?.items?.length || 0}
                  showCount={true}
                  rightIcon={showAddReference?.enabled && addReferenceAuthorized && <AddIcon />}
                  iconToolTip="Add References"
                  onClick={() => {
                    handleNavigation();
                  }}
                  onClickAll={() => {
                    showAllReferences();
                    filterReferenceBy(''); //No filter applied
                  }}
                >
                  <LayoutGrid
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    wrap="nowrap"
                    className={classes.content}
                  >
                    {promiseInProgress ? (
                      <LayoutGridItem xs={12}>
                        <Skeleton />
                        <Skeleton />
                      </LayoutGridItem>
                    ) : (
                      <LayoutGridItem>
                        <LayoutGrid direction="column" spacing={1}>
                          {selReferences?.items?.length === 0 ? (
                            <RowSection
                              header={t(`candidate.workExperienceCard.noReferences`)}
                              data={''}
                              showHalfwayProgress={true}
                              showHalfwayProgressSecondaryIcon={true}
                              showAlert={false}
                              preData={''}
                              color={theme.palette.system.darkGreenForIcon}
                            />
                          ) : (
                            <>
                              {referenceData?.totals?.referencesApproved > 0 && (
                                <Box className={classes.alignContainer}>
                                  <div
                                    onClick={() => {
                                      showAllReferences();
                                      filterReferenceBy(APPROVEDSTATUS);
                                    }}
                                  >
                                    <ApproveUnApprove
                                      title={APPROVEDSTATUS}
                                      status={referenceData?.totals?.referencesApproved}
                                      summaryTabIconsUpdateDecision={summaryTabIconsUpdateDecision}
                                    />
                                  </div>
                                </Box>
                              )}
                              {referenceData?.totals?.referencesApproved === 0 && (
                                <ApproveUnApprove
                                  title={APPROVEDSTATUS}
                                  status={referenceData?.totals?.referencesApproved}
                                  summaryTabIconsUpdateDecision={summaryTabIconsUpdateDecision}
                                />
                              )}
                              {referenceData?.totals?.referencesUnapproved > 0 && (
                                <Box className={classes.alignContainer}>
                                  <div
                                    onClick={() => {
                                      showAllReferences();
                                      filterReferenceBy(UNAPPROVEDSTATUS);
                                    }}
                                  >
                                    <ApproveUnApprove
                                      title={UNAPPROVEDSTATUS}
                                      status={referenceData?.totals?.referencesUnapproved}
                                      summaryTabIconsUpdateDecision={summaryTabIconsUpdateDecision}
                                    />
                                  </div>
                                </Box>
                              )}
                              {referenceData?.totals?.referencesUnapproved === 0 && (
                                <ApproveUnApprove
                                  title={UNAPPROVEDSTATUS}
                                  status={referenceData?.totals?.referencesUnapproved}
                                  summaryTabIconsUpdateDecision={summaryTabIconsUpdateDecision}
                                />
                              )}
                            </>
                          )}
                        </LayoutGrid>
                      </LayoutGridItem>
                    )}
                  </LayoutGrid>
                </CandidateSubHeader>
              </>
            )}
          </CandidateContainer>
        </Box>
      </LayoutGridItem>
    </>
  );
}

export default ExperienceUIV2;
