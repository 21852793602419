import { Typography } from 'amn-ui-core';
import { missingField } from 'app/constants';
import React from 'react';
import styledComponent from 'styled-components';

export interface IGroupedSkillset {
  isVerified?: boolean;
  disciplineId: number;
  discipline: string;
  disciplineAbbr: string;
  specialties: {
    specialtyId: number;
    specialty: string;
    specialtyAbbr: string;
    subSpecialties: { subSpecialtyId: number; subSpecialtyAbbr: string; subSpecialty: string }[];
  }[];
}

export const SkillsetTree = ({ skillset, title }: { skillset: IGroupedSkillset[]; title?: string }) => (
  <ColumnDiv>
    {title && <Key>{title}</Key>}
    {skillset.length ? (
      <UnOrderedList>
        {skillset.map((skill, skillIndex) => (
          <DisciplineList key={skillIndex.toString()}>
            <Typography variant="body1">{skill.disciplineAbbr || skill.discipline}</Typography>
            <UnOrderedList>
              {skill.specialties.map((specialty, specIndex) => (
                <li key={specIndex.toString()}>
                  <Typography variant="body1">{specialty.specialtyAbbr || specialty.specialty}</Typography>
                  <UnOrderedSpecialties>
                    {specialty.subSpecialties?.map(({ subSpecialty, subSpecialtyAbbr }, subSpecIndex) => (
                      <li key={subSpecIndex.toString()}>
                        <Typography variant="body1">{subSpecialtyAbbr || subSpecialty}</Typography>
                      </li>
                    ))}
                  </UnOrderedSpecialties>
                </li>
              ))}
            </UnOrderedList>
          </DisciplineList>
        ))}
      </UnOrderedList>
    ) : (
      <div>{missingField}</div>
    )}
  </ColumnDiv>
);

const ColumnDiv = styledComponent.div`
  display: flex;
  flex-direction: column;
`;

const Key = styledComponent.div`
  font-size: 12px;
  font-weight: 500;
  color: #333333;
`;

export const UnOrderedList = styledComponent.ul`
  margin-block: 0;
  padding-inline: 16px;
  list-style-type: disc;
  li {
    &::marker {
      color: #b9bbbc;
    }
  }
`;

const DisciplineList = styledComponent.li`
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const UnOrderedSpecialties = styledComponent.ul`
  margin-block: 0;
  padding-inline: 16px;
  list-style-type: disc;
  li {
    &::marker {
      color: #b9bbbc;
    }
  }
`;
