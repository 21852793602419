import { FormControlLabel, Checkbox } from 'amn-ui-core';
import React from 'react';
import clsx from 'clsx';
import { convertToPST } from 'app/helpers/dateHelper';
import { selectUser } from 'oidc/user.selectors';
import { useSelector } from 'react-redux';
import { Concatenate } from 'utils/string/string';
import { IConfirmation } from 'app/models/Placement/PlacementSummary';

interface ConfirmationsCheckboxProps {
  /** Checkbox label */
  label: string;
  /** React Hook Form name */
  name: string;
  onChange?: (value: IConfirmation) => void;
  /** Default state - speified by service */
  defaultState: IConfirmation;
  value: IConfirmation;
  disabled?: boolean;
  [key: string]: any;
}

export const ConfirmationCheckbox = ({
  name,
  value,
  defaultState,
  label,
  disabled = false,
  onChange,
  ...props
}: ConfirmationsCheckboxProps) => {
  const { userInfo } = useSelector(selectUser);

  const handleChange = event => {
    const {
      target: { checked },
    } = event;
    /** !!defaultState.checked accounts for null, undefined and false  */
    if (checked === !!defaultState.checked) onChange?.({ ...defaultState });
    else
      onChange?.({
        checked,
        lastUpdatedBy: Concatenate([userInfo?.firstName || '', userInfo?.lastName || ''], ' '),
        lastUpdatedById: userInfo?.employeeId || null,
        lastUpdatedAt: convertToPST(new Date(), 'yyyy-MM-DDTHH:mm:ss'),
      });
  };

  return (
    <FormControlLabel
      className={clsx('input', { disabled: disabled })}
      control={
        <Checkbox
          style={{ padding: '0px 6px' }}
          checked={value?.checked}
          name={name}
          onChange={handleChange}
          disabled={disabled}
          {...props}
        />
      }
      label={label}
    />
  );
};
