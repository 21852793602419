import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum PanelChoice {
  Tabs = 'Tabs',
  JobMatchGrid = 'JobMatches',
  EmployementHistoryGrid = 'EmploymentHistories',
  JobInterestGrid = 'JobInterests',
}

export interface IPanel {
  panelOption: string;
}

export const initialState: IPanel = {
  panelOption: PanelChoice.Tabs,
};

const panleSlice = createSlice({
  name: 'panelOption',
  initialState,
  reducers: {
    setPanelOptions(state, action: PayloadAction<IPanel>) {
      state.panelOption = action.payload.panelOption;
    },
  },
});

export const { actions: panelActions, name: panelSliceKey, reducer: panelReducer } = panleSlice;
