export const injectItem = (
  existingList,
  valueTree: any[],
  node,
  remove: boolean,
  valueKey,
  nameKey,
  userCheckedKey,
  excludeChildren = false,
) => {
  if (Array.isArray(existingList)) {
    const foundChild = existingList.map(item => item[valueKey]).indexOf(valueTree[0][valueKey]);
    if (Array.isArray(valueTree) && valueTree.length > 1) {
      const val = valueTree.shift();
      if (foundChild !== -1) {
        const children = injectItem(
          existingList[foundChild].children,
          valueTree,
          node,
          remove,
          valueKey,
          nameKey,
          userCheckedKey,
          excludeChildren,
        );
        if (children.length > 0) existingList[foundChild].children = children;
        else if (!excludeChildren) existingList.splice(foundChild, 1);
      } else {
        existingList.push({
          [nameKey]: val[nameKey],
          [valueKey]: val[valueKey],
          children: injectItem([], valueTree, node, remove, valueKey, nameKey, userCheckedKey, excludeChildren),
        });
      }
    } else {
      if (!remove) {
        if (foundChild !== -1) {
          // parent level was selected, remove selected item and any children so the entire child tree can be fully selected
          existingList.splice(foundChild, 1);
        }
        const itemToAdd: { [key: string]: any } = {
          [nameKey]: node[nameKey],
          [valueKey]: node[valueKey],
          [userCheckedKey]: node[userCheckedKey] ? node[userCheckedKey] : false,
        };
        // check any children that are within this node
        if (Array.isArray(node.children)) {
          if (excludeChildren) {
            itemToAdd.children = [];
          } else {
            node.children.forEach(item => {
              itemToAdd.children = injectItem(
                itemToAdd.children || [],
                [item],
                item,
                remove,
                valueKey,
                nameKey,
                userCheckedKey,
                excludeChildren,
              );
            });
          }
        }
        existingList.push(itemToAdd);
      } else {
        existingList.splice(foundChild, 1);
      }
    }
  }
  return existingList;
};

export const findBottomNode = (node: any[] = [], foundNode = null) => {
  node?.forEach(item => {
    if (Array.isArray(item.children)) {
      foundNode = findBottomNode(item.children);
    } else foundNode = item;
  });
  return foundNode;
};

export const calculateParentPath = (node: any[] = [], foundNode: any[] = []): any[] => {
  node?.forEach(item => {
    if (Array.isArray(item.children)) {
      foundNode = calculateParentPath(item.children, foundNode.length > 0 ? [...foundNode, item] : [item]);
    } else foundNode = [...foundNode, item];
  });
  return foundNode;
};

/**
 * Calcuates the number of children within the node array
 */
export const calculateTreeCount = (node: any[] = [], count = 0) => {
  node?.forEach(item => {
    if (Array.isArray(item.children)) {
      count += calculateTreeCount(item.children);
    } else count++;
  });
  return count;
};

/**
 * Count the number of node within the array
 */
export const totalCountNodes = tree => {
  let totalCount = 0;

  const traverse = nodes => {
    nodes?.forEach(node => {
      if (node.userChecked) {
        totalCount += 1; // Count the current node only if userChecked is true
      }
      if (Array.isArray(node.children) && node.children.length > 0) {
        traverse(node.children); // Recursively count the children
      }
    });
  };

  traverse(tree?.skillsets);
  return totalCount;
};

export const skillSetSubFilterCount = tree => {
  let totalCount = 0;
  if (tree?.allowableMismatch) {
    totalCount += 1;
  }
  if (tree?.isVerifiedOnly) {
    totalCount += 1;
  }
  if (tree?.operation) {
    totalCount += 1;
  }
  if (tree?.isExactMatch) {
    totalCount += 1;
  }
  return totalCount;
};

export function checkedIsobjectHasValue(obj) {
  return (
    obj?.allowableMismatch === true ||
    obj?.isVerifiedOnly === true ||
    (obj?.operation && obj?.operation.length > 0) ||
    (Array.isArray(obj?.skillsets) && obj?.skillsets.length > 0)
  );
}

export function updateTreeNodes(data) {
  // Function to traverse the tree and update userChecked status
  const traverseAndUpdate = node => {
    let anyDescendantChecked = false;

    if (node.children && node.children.length > 0) {
      node.children.forEach(child => {
        const childChecked = traverseAndUpdate(child);
        if (childChecked) {
          anyDescendantChecked = true;
        }
      });
    }

    // If any descendant is checked, set this node's userChecked to false
    if (anyDescendantChecked) {
      node.userChecked = false;
    }

    // Return true if this node or any descendant is checked
    return node.userChecked || anyDescendantChecked;
  };

  // Start the tree traversal and processing
  data.forEach(node => traverseAndUpdate(node));
  return data;
}

export const selectNodeOnCondition = (options, level) => {
  function traverse(nodes, level) {
    return nodes.map(node => {
      const newNode = { ...node, checked: true, userChecked: true }; // Add userChecked: true to the current node
      if (Array.isArray(node.children) && node.children.length > 0 && level === 'All') {
        newNode.children = traverse(node.children, level); // Recursively update children
      } else {
        newNode.children = [];
      }
      return newNode;
    });
  }

  return traverse(options, level);
};

export function pruneTree(data) {
  // Helper function to check if all nodes in a subtree are userChecked: false
  const isAllUserCheckedFalse = (node) => {
    if (node.userChecked) return false;
    if (node.children && node.children.length > 0) {
      return node.children.every(isAllUserCheckedFalse);
    }
    return true;
  };

  // Recursive function to traverse and prune the tree
  const traverseAndPrune = (nodes) => {
    if (!nodes || nodes.length === 0) return [];

    return nodes.reduce((acc, node) => {
      if (isAllUserCheckedFalse(node)) {
        return acc; // Skip this node
      }

      if (node.children && node.children.length > 0) {
        node.children = traverseAndPrune(node.children);
      }

      acc.push(node);
      return acc;
    }, []);
  };

  return traverseAndPrune(data);
}