import { Grid, Typography } from 'amn-ui-core';
import { VerticalContentPopover } from 'app/components/Common/Tooltips';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import { missingField } from 'app/constants';
import { PlacementOrderType } from 'app/models/Placement/PlacementDetails';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { ListSection } from '../../../customComponents/ListSection';
import { TableSection } from '../../../customComponents/TableSection';
import { getRequirementCheck } from '../../../helper';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import {
  selectServiceFailures,
  selectSubmissionInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { RequirementsRow } from '../../TableComponents/TableComponents';

/** Height values have been adjusted slightly such that btn are aligned parallely */
const AMN_COL_MAX_HEIGHT = 100;
const CANDIDATE_COL_MAX_HEIGHT = 184;

export const SkillsChecklist = () => {
  const { t } = useTranslation();
  const {
    amnReq: {
      amn: { skillsCheckList: amnSkillsChecklist },
      candidate: { skillsCheckList: candidateSkillsChecklist },
    },
    requirementFilter,
  } = useSelector(selectSubmissionInfo);
  const { skillsChecklistRequirement: serviceFailed } = useSelector(selectServiceFailures);
  const { placementId } = useContext(AddOnContext);

  const placementOrderType = useSelector(selectPlacementOrderType);
  const isStrikePlacement = Boolean(placementOrderType === PlacementOrderType.StrikeOrderInHsg);

  const [forceTruncation, setForceTruncation] = useState(true);
  const truncationProps = { forceTruncation, setForceTruncation };

  const { promiseInProgress } = usePromiseTracker({
    area: 'placement-submission-skills-checklist-requirement',
    delay: 0,
  });

  const dispatch = useDispatch();

  const { display } = useRequirementFilter('submission-amn', requirementFilter, amnSkillsChecklist?.type);

  return display ? (
    <RequirementsRow
      id="placement-submission-amn-skills-checklist"
      title={t('placement.profile.summary.submission.amn.skillsChecklist.skillsChecklistTitle')}
      requirementCell={<RowKey skillsChecklist={amnSkillsChecklist} t={t} truncationProps={truncationProps} />}
      candidateCell={
        <RowValue
          skillsCheckList={candidateSkillsChecklist}
          isStrikePlacement={isStrikePlacement}
          truncationProps={truncationProps}
        />
      }
      loading={promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() =>
        dispatch(placementSummaryActions.getSkillSChecklistRequirement({ placementId, placementOrderType }))
      }
    />
  ) : null;
};
const RowKey = ({ skillsChecklist, t, truncationProps }) => {
  return (
    <TableSection
      items={[
        {
          icon: skillsChecklist ? getRequirementCheck(skillsChecklist?.type) : null,
          primaryContent:
            skillsChecklist?.messageCategory === 1 ? (
              <Grid container>
                <Grid item xs={12}>
                  <Typography>{t(skillsChecklist?.message?.primary)}</Typography>
                </Grid>
              </Grid>
            ) : skillsChecklist?.messageCategory === 2 ? (
              <Grid container>
                <Grid item xs={12}>
                  <Typography>{t(skillsChecklist?.message?.primary)}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <VerticalContentPopover
                    value={
                      <ul className="unordered-list">
                        {skillsChecklist?.message?.ternary.map(skill => (
                          <li key={skill}>{skill}</li>
                        ))}
                      </ul>
                    }
                    maxHeight={AMN_COL_MAX_HEIGHT}
                    {...truncationProps}
                  />
                </Grid>
              </Grid>
            ) : (
              missingField
            ),
        },
      ]}
    />
  );
};

const RowValue = ({ skillsCheckList, isStrikePlacement, truncationProps }) =>
  (skillsCheckList?.data || []).length ? (
    // US #154496: Conditional rendering - strike vs non-strike. To be updated acc. to user preference.
    isStrikePlacement ? (
      <ListSection listType={'skillsChecklist'} items={skillsCheckList?.data || []} />
    ) : (
      <VerticalContentPopover
        value={<ListSection listType={'skillsChecklist'} items={skillsCheckList?.data || []} />}
        maxHeight={CANDIDATE_COL_MAX_HEIGHT}
        {...truncationProps}
      />
    )
  ) : (
    <>{missingField}</>
  );
