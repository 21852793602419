import React, { Component } from 'react';
import { Grid, GridSize } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';

interface Props {
  gridSize?: boolean | GridSize | undefined;
  contentValue?: string;
  component?: any;
  id?: string;
}

const useStyles = makeStyles()(theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      fontSize: '12px !important',
    },
  },
}));

export const InnerValueSection = (props: Props) => {
  const { gridSize, contentValue, component } = props;
  const { classes } = useStyles();
  return (
    <Grid item xs={gridSize} classes={{ root: classes.root }} id={props.id}>
      {contentValue && contentValue}
      {Component && component}
    </Grid>
  );
};
