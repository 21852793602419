import { Box, Grid, Theme, ThemeProvider, StyledEngineProvider, Tooltip, Typography,useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styledComponent from 'styled-components';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { TemplateDeleteDialog } from './TemplateDeletePopup/TemplateDeletePopup';

const useStyles = makeStyles()((theme: Theme) => ({
  gridArea: {
    border: '1px solid #B9BBBC',
    borderRadius: '5px',
    '&:hover': {
      border: '1px solid #006fb9',
    },
  },
  box: {
    borderRadius: '5px',
    width: '100%',
    height: '160px',
    backgroundColor: '#F7F7F7',
    margin: '0',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      backgroundColor: '#EFF4F9',
      '& .MuiSvgIcon-root': {
        display: 'block !important',
        color: theme.palette.system.hoverBlue,
        position: 'absolute',
        top: 10,
        right: 10
      },
    },
  },
  selection: {
    boxSizing: 'border-box',
    bottom: '10px',
    right: '5px',
    float: 'right',
    color: '#EFF4F9',
    borderRadius: '5px',
    borderColor: theme.palette.system.hoverBlue,
    padding: '3%',
    marginRight: '3%',
    height: '4vh',
    position: 'absolute',
    backgroundColor: '#EFF4F9',
    '&:hover': {
      backgroundColor: 'blue',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '15px',
    },
  },
  tooltipArrow: {
    color: '#fff',
    '&::before': {
      border: '1px solid #555555',
    },
  },
  tooltipBackground: {
    backgroundColor: '#fff',
    color: '#555555',
    border: '1px solid #555555',
    fontSize: '12px',
  },
}));

const TemplateTitle = styledComponent(Typography)`
  flex-grow: 1;
  padding: 3%;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #333333;
  white-space: nowrap;
  width: 100%; /* IE6 needs any width */
  overflow: hidden; /* "overflow" value must be different from  visible"*/
  -o-text-overflow: ellipsis; /* Opera < 11*/
  text-overflow: ellipsis; /* IE, Safari (WebKit), Opera >= 11, FF > 6 */
`;

const Description = styledComponent(Typography)`
  font-size: 14px;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  margin: 0 3%;
  font-weight: 400;
  overflow: hidden; /* "overflow" value must be different from  visible"*/
  -o-text-overflow: ellipsis; /* Opera < 11*/
  text-overflow: ellipsis; /* IE, Safari (WebKit), Opera >= 11, FF > 6 */
`;

export const TemplateTile = props => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { data, handlers, changeTracker } = props;
  const { classes } = useStyles();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);

  const deletePersonalTemplate = () => {
    handlers.handleTemplateDelete(data);
    setOpenDeleteDialog(false)
  }

  return (
    <Grid
      container
      style={{ border: props.selected ? '#006fb9 2px solid' : '#75787B 1px solid' }}
      className={classes.gridArea}
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box
            component="span"
            m={1}
            className={classes.box}
            onClick={() =>
              changeTracker.isEdited
                ? handlers.setTemplateChangeTracker({
                    isEdited: false,
                    showConfirmation: true,
                    changedTemplate: props.data,
                  })
                : props.handleSelected(props.data)
            }
          >
            {data?.title?.length > 20 ? (
              <Tooltip
                classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
                arrow
                title={data?.title}
              >
                <TemplateTitle variant="h6" style={{color: props?.selected ? theme.palette.system.hoverBlue : ''}}>
                  {data?.title.substring(0, 25) + ' ...' ?? t('notification.emails.noNameAvailable')}
                </TemplateTitle>
              </Tooltip>
            ) : (
              <TemplateTitle variant="h6" style={{color: props?.selected ? theme.palette.system.hoverBlue : ''}}>
                {data?.title ?? t('notification.emails.noNameAvailable')}
              </TemplateTitle>
            )}
            {data?.description?.length > 100 ? (
              <Tooltip
                classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
                arrow
                title={data.description}
              >
                <Description variant="h6">{data.description.substring(0, 99) + ' ...'}</Description>
              </Tooltip>
            ) : (
              <Description variant="h6">{data.description}</Description>
            )}
            {data?.isPrivate &&
              <DeleteOutlined
                fontSize="medium"
                style={{display: 'none'}}
                onClick={(event) => {
                  event.stopPropagation();
                  setOpenDeleteDialog(true);
                }}
                data-testid={'template-delete-icon'}
              />}
          </Box> 
        </ThemeProvider>
      </StyledEngineProvider>
      <TemplateDeleteDialog
        templateName={data?.title}
        openDeleteDialog={openDeleteDialog}
        onDeleteDialogClose={() => setOpenDeleteDialog(false)}
        deletePersonalTemplate={() => deletePersonalTemplate()}
      />
    </Grid>
  );
};
