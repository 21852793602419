import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Grid, Skeleton } from '@mui/material';
import YouAreAllDoneImg from 'app/assets/images/img-great-job-youre-all-caught-up.svg';
import NoTaskLeftImg from 'app/assets/images/img-impressive-no-tasks-left-for-you.svg';
import NothingToSeeHereImg from 'app/assets/images/img-nothing-to-see-here.svg';
import { TaskEntity } from 'app/models/Tasks/Tasks';
import { selectUser } from 'oidc/user.selectors';
import { TaskItem } from './TaskItem';
import {
  selectedIsExternalTaskUpdated,
  selectedUpdatedId,
  selectExternalTaskId,
  selectExternalTaskList,
  selectTaskId,
  selectTaskStatusData,
  selectInternalTaskListData,
} from '../store/Tasks.selectors';
import { taskDetailsActions } from '../store/Tasks.redux';
import { isOverflown } from 'app/helpers/componentHelpers';
import { getEmployeeId } from '@AMIEWEB/Notification/Tasks/Common_v2/utils';

const useStyles = makeStyles()(theme => ({
  validationLoader: {
    top: '50%',
  },
  noTaskIconPlacement: {
    height: '120px',
    textAlign: 'center',
  },
  noTaskIconMessage: {
    font: 'italic normal normal 14px/20px Roboto',
    textAlign: 'center',
    color: '#333333',
    opacity: '1',
    height: '19px',
  },
  customScroll: {
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingLeft: '12px',
  },
  skeletonContainer: {
    height: 125,
    border: ` 1px solid ${theme.palette.system.paleGray}`,
    boxShadow: `0px 3px 6px ${theme.palette.system.darkBlackBlue}`,
    borderColor: theme.palette.system.lightFadedGray,
    background: `${theme.palette.common.white} 0% 0% no-repeat padding-box`,
    flexWrap: 'nowrap',
    borderRadius: '4px',
    flexDirection: 'column',
    margin: '0px',
    width: '272px',
    padding: '6px',
  },
  skeletonItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export const TaskItemSkeleton = ({ isExternal }: { isExternal: boolean }) => {
  const { classes } = useStyles();
  return (
    <Grid container spacing={1} className={classes.skeletonContainer}>
      <Grid item xs={12} className={classes.skeletonItem}>
        {!isExternal && <Skeleton variant="rectangular" width={'20px'} />}
        <Skeleton variant="rectangular" width={'200px'} />
        {isExternal && <Skeleton variant="rectangular" width={'20px'} />}
        <Skeleton variant="rectangular" width={'5px'} />
      </Grid>
      <Grid item xs={12} className={classes.skeletonItem}>
        {!isExternal && <Skeleton variant="rectangular" width={'10px'} />}
        <Skeleton variant="rectangular" width={'100px'} />
        <Skeleton variant="rectangular" width={'100px'} />
      </Grid>
      <Grid item xs={12} className={classes.skeletonItem}>
        <Skeleton variant="rectangular" width={'50px'} />
      </Grid>
      <Grid item xs={12} display={'flex'} flexDirection={'row'} justifyContent={'start'}>
        <Skeleton variant="circular" width={'24px'} height={'24px'} />
        <Skeleton variant="circular" width={'24px'} height={'24px'} sx={{ marginLeft: '6px' }} />
      </Grid>
    </Grid>
  );
};

export const TaskItemsList = ({
  setTaskID,
  setModalOpen,
  displayMyTasks = false,
  filterDataOptions,
  currentContainer,
  getAllIds,
  isExternal,
  setExternalDetails,
  statusChanged,
}: {
  setTaskID: (id) => void;
  setModalOpen: (state) => void;
  displayMyTasks?: boolean;
  filterDataOptions?: any[];
  currentContainer: number;
  getAllIds: any;
  isExternal: boolean;
  setExternalDetails;
  statusChanged: boolean;
}) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { promiseInProgress: isCallingAPI } = usePromiseTracker({ area: 'get-task-list', delay: 0 });
  const { promiseInProgress: isExternalAPI } = usePromiseTracker({ area: 'get-external-task', delay: 0 });
  const userInfo = useSelector(selectUser);
  const createdTaskId = useSelector(selectTaskId);
  const externalCreatedId = useSelector(selectExternalTaskId);
  const updatedId = useSelector(selectedUpdatedId);
  const taskStatusUpdate = useSelector(selectTaskStatusData);
  const taskListData = useSelector(selectInternalTaskListData);
  const randomIndex = Math.floor(Math.random() * 3);
  const externalTaskData = useSelector(selectExternalTaskList);
  const isUpdated = useSelector(selectedIsExternalTaskUpdated);
  const [overflown, setOverflown] = useState<boolean>(false);

  const socialIcons: { icon; message: string }[] = [
    {
      icon: <img src={YouAreAllDoneImg} alt="All done" />,
      message:
        currentContainer === TaskEntity.CANDIDATES && isExternal
          ? t('notification.taskSidePanel.noTasksLeftCandidate')
          : t('notification.taskSidePanel.greatJobYouAreAllDone'),
    },
    {
      icon: <img src={NothingToSeeHereImg} alt="Nothing to see" />,
      message: t('notification.taskSidePanel.nothingToSeeHere'),
    },
    {
      icon: <img src={NoTaskLeftImg} alt="No task left" />,
      message: t('notification.taskSidePanel.noTasksLeft'),
    },
  ];

  const getExternalTaskData = () => {
    if (getAllIds?.placementId > 0 || getAllIds?.candidateId > 0) {
      const body = {
        PlacementIds: getAllIds?.placementId > 0 ? [getAllIds?.placementId] : [],
        StatusIds: filterDataOptions,
        CandidateIds: getAllIds?.candidateId > 0 ? [getAllIds?.candidateId] : [],
      };
      if (isExternal) dispatch(taskDetailsActions.getExternalTaskList(body));
    }
  };
  const getTaskData = async () => {
    switch (currentContainer) {
      case TaskEntity.CANDIDATES:
        const getCandidateBody = {
          candidateId: getAllIds?.candidateId,
          statusIds: filterDataOptions,
          assignedToId: displayMyTasks ? getEmployeeId(userInfo?.userInfo) : null,
          companyId: 0,
        };
        if (getCandidateBody?.candidateId > 0) {
          dispatch(taskDetailsActions.getInternalTaskListAction(getCandidateBody));
        }
        break;
      case TaskEntity.PLACEMENTS:
        const getPlacementBody = {
          placementId: getAllIds?.placementId,
          statusIds: filterDataOptions,
          assignedToId: displayMyTasks ? getEmployeeId(userInfo?.userInfo) : null,
        };
        if (getPlacementBody?.placementId > 0) {
          dispatch(taskDetailsActions.getInternalTaskListAction(getPlacementBody));
        }
        break;
      case TaskEntity.FACILITIES:
        const getFacilityBody = {
          facilityId: getAllIds?.facilityId,
          statusIds: filterDataOptions,
          assignedToId: displayMyTasks ? getEmployeeId(userInfo?.userInfo) : null,
        };
        if (getFacilityBody?.facilityId > 0) {
          dispatch(taskDetailsActions.getInternalTaskListAction(getFacilityBody));
        }
        break;
      case TaskEntity.ORDERS:
        const orderid = getAllIds?.orderId ? parseInt(getAllIds?.orderId) : 0;
        const getOrderBody = {
          orderId: orderid,
          statusIds: filterDataOptions,
          assignedToId: displayMyTasks ? getEmployeeId(userInfo?.userInfo) : null,
        };
        if (getOrderBody?.orderId > 0) {
          dispatch(taskDetailsActions.getInternalTaskListAction(getOrderBody));
        }
        break;
      default:
        break;
    }
  };

  const setExternalTaskDetails = taskData => {
    dispatch(
      taskDetailsActions.setCurrentExternalTask({
        id: taskData?.taskId,
        placementId: taskData?.placementId,
        candidateId: taskData?.candidateId,
        taskNameId: taskData?.taskNameId,
        taskCategoryId: taskData?.taskCategoryId,
        categorySubType1: taskData?.categorySubType1,
        categorySubType2: taskData?.categorySubType2,
      }),
    );
    setExternalDetails({
      id: taskData?.taskId,
      placementId: taskData?.placementId,
      candidateId: taskData?.candidateId,
      taskNameId: taskData?.taskNameId,
      taskCategoryId: taskData?.taskCategoryId,
    });
  };

  useEffect(() => {
    setOverflown(isOverflown(document.getElementById('scrollable-list')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    if (getAllIds?.placementId > 0 || getAllIds?.candidateId > 0) {
      if (isExternal) {
        getExternalTaskData();
      } else {
        getTaskData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getAllIds?.placementId,
    getAllIds?.candidateId,
    filterDataOptions,
    displayMyTasks,
    statusChanged,
    taskStatusUpdate,
    isExternal,
  ]);

  useEffect(() => {
    if (updatedId && updatedId > 0) {
      setTimeout(() => {
        if (!isExternal) getTaskData();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedId]);

  useEffect(() => {
    if (isUpdated) {
      setTimeout(() => {
        if (isExternal) getExternalTaskData();
      }, 750);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated]);

  useEffect(() => {
    if (createdTaskId) {
      getTaskData();
    }
    if (externalCreatedId) getExternalTaskData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdTaskId, externalCreatedId]);

  useEffect(() => {
    if (getAllIds?.facilityId > 0 || getAllIds?.orderId > 0) {
      if (!isExternal) {
        getTaskData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllIds?.facilityId, getAllIds?.orderId, displayMyTasks, statusChanged, taskStatusUpdate, filterDataOptions]);

  return (
    <div id="scrollable-list" className={classes.customScroll} style={{ marginRight: overflown ? '0px' : '12px' }}>
      {isExternal ? (
        <div>
          {isExternalAPI ? (
            <TaskItemSkeleton isExternal={true} />
          ) : (
            <div data-testid="task-items-list">
              {externalTaskData && externalTaskData?.length > 0 ? (
                externalTaskData?.map(taskData => (
                  <TaskItem
                    key={taskData.taskId}
                    isExternal={isExternal}
                    task={taskData}
                    container={currentContainer}
                    setTaskID={setTaskID}
                    cardClickFxn={e => {
                      const input = e.target as HTMLElement;
                      dispatch(taskDetailsActions.setIsExternalTask(isExternal));
                      setExternalTaskDetails(taskData);
                      if (input.innerText === 'Edit') {
                        setModalOpen(false);
                      } else {
                        setModalOpen(true);
                      }
                    }}
                  />
                ))
              ) : (
                <div data-testid="task-items-list-no-tasks">
                  <div
                    className={classes.noTaskIconPlacement}
                    style={{ justifyItems: 'center', paddingTop: '9rem', paddingBottom: '30px' }}
                  >
                    {socialIcons[randomIndex].icon}
                  </div>
                  <div
                    className={classes.noTaskIconMessage}
                    style={{ justifyItems: 'center', paddingTop: '9rem', paddingBottom: '30px' }}
                  >
                    {socialIcons[randomIndex].message}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <>
          {isCallingAPI ? (
            <TaskItemSkeleton isExternal={false} />
          ) : (
            <div data-testid="task-items-list">
              {taskListData && taskListData?.length > 0 ? (
                taskListData?.map(taskData => (
                  <TaskItem
                    key={taskData.taskId}
                    isExternal={isExternal}
                    task={taskData}
                    container={currentContainer}
                    setTaskID={setTaskID}
                    cardClickFxn={e => {
                      const input = e.target as HTMLElement;
                      if (input.innerText === 'Edit') {
                        setModalOpen(false);
                      } else {
                        setModalOpen(true);
                      }
                    }}
                  />
                ))
              ) : (
                <div data-testid="task-items-list-no-tasks">
                  <div
                    className={classes.noTaskIconPlacement}
                    style={{ justifyItems: 'center', paddingTop: '9rem', paddingBottom: '30px' }}
                  >
                    {socialIcons[randomIndex].icon}
                  </div>
                  <div
                    className={classes.noTaskIconMessage}
                    style={{ justifyItems: 'center', paddingTop: '9rem', paddingBottom: '30px' }}
                  >
                    {socialIcons[randomIndex].message}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};
