import { useMediaQuery, useTheme } from 'amn-ui-core';

export enum ScreenSize {
  none,
  xs,
  sm,
  md,
  lg,
  xl,
}

export const useScreenSize = (): ScreenSize => {
  const theme = useTheme();
  const xl = useMediaQuery(theme.breakpoints.up('xl'));
  const l = useMediaQuery(theme.breakpoints.up('lg'));
  const m = useMediaQuery(theme.breakpoints.up('md'));
  const s = useMediaQuery(theme.breakpoints.up('sm'));
  const xs = useMediaQuery(theme.breakpoints.up('xs'));

  return xl
    ? ScreenSize.xl
    : l
    ? ScreenSize.lg
    : m
    ? ScreenSize.md
    : s
    ? ScreenSize.sm
    : xs
    ? ScreenSize.xs
    : ScreenSize.none;
};
