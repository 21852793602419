import EMRList from 'app/assets/jsons/EMR.json';
import { virtualRatingList } from 'app/constants';
import {
  formatTypeAheadDisciplineOptions,
  formatTypeAheadEMR,
  formatTypeAheadSpecialtyOptions,
  formatTypeAheadSubSpecialtyOptions,
  formatTypeAheadVirtualRatingOptions,
} from 'app/helpers/typeAheadHelper';
import { getDisciplineSpecialtyData } from 'app/services/SharedServices/SharedServices';
import { ITypeAheadOption } from 'app/models/Orders/OrderDetails';
import { TravelExperienceSections } from 'app/enums/Common';
import { IGroupedTypeAheadOption } from '@AMIEWEB/NewOrder/types';
import { sortBy } from 'lodash';

export async function GetDisciplineOptions() {
  const result = await getDisciplineSpecialtyData();
  if (result) {
    return formatTypeAheadDisciplineOptions(result.data.disciplines);
  }
}

export async function GetSpecialtyOptions() {
  const result = await getDisciplineSpecialtyData();
  if (result) {
    return formatTypeAheadSpecialtyOptions(result.data.disciplineSpecialties);
  }
}

export async function GetSubSpecialtyOptions() {
  const result = await getDisciplineSpecialtyData();
  if (result) {
    return formatTypeAheadSubSpecialtyOptions(result.data.subSpecialties);
  }
}

export async function GetDivisionDisciplinesOptions() {
  const result = await getDisciplineSpecialtyData();
  if (result) {
    return result.data.divisionDisciplines;
  }
}

export const filterDisciplineList = (amnDivision: number, disciplines: any[], divisionDisciplines: any[]) => {
  const formattedDiscipline: ITypeAheadOption[] = [];
  const divisionDisciplineList = divisionDisciplines?.filter(x => x?.amnDivisionId === amnDivision);
  const uniqueDisciplineIds: number[] = [];
  divisionDisciplineList?.forEach(item => {
    if (!uniqueDisciplineIds?.includes(item?.disciplineId)) {
      uniqueDisciplineIds.push(item?.disciplineId);
    }
  });
  uniqueDisciplineIds?.map(id => {
    disciplines?.map(item => {
      if (item?.object?.ID === id) {
        formattedDiscipline.push(item);
      }
    });
  });
  return formattedDiscipline;
};

export async function GetDisciplineSpecialtyAndSubSpecialtyOptions() {
  const result = await getDisciplineSpecialtyData();
  if (result) {
    const disciplineOptions = formatTypeAheadDisciplineOptions(result.data.disciplines);
    const specialtyOptions = formatTypeAheadSpecialtyOptions(result.data.disciplineSpecialties);
    const subSpecialtyOptions = formatTypeAheadSubSpecialtyOptions(result.data.subSpecialties);
    return {
      disciplines: disciplineOptions,
      specialties: specialtyOptions,
      subSpecialties: subSpecialtyOptions,
      disciplineSpecialties: result.data.disciplineSpecialties,
      divisionDisciplines: result.data.divisionDisciplines,
    };
  }
}

export function GetVirtualRating() {
  return formatTypeAheadVirtualRatingOptions(virtualRatingList);
}

export function GetEMRValues() {
  return formatTypeAheadEMR(EMRList);
}

export const formatDataForAutoComplete = options => {
  const sortedOptions = options && options.length > 0 ?
    sortBy(options, function (o) {
      return new Number(o.value);
    }, ['asc']) : [];

  return sortedOptions.map(item => {
    const option: ITypeAheadOption = {
      object: item,
      label: item.value +'. '+ item.name,
    };
    return option;
  });
};

export const formatDataForTravelExperiences = (
  sectionTitle: TravelExperienceSections,
  options: any,
  sectionId: number,
): IGroupedTypeAheadOption[] => {
  return options.map(x => {
    return {
      groupBy: sectionTitle,
      label: x.name,
      object: {
        optionId: x.value,
        title: x.name,
        id: `${sectionId}-${x.value}`,
      },
    };
  });
};