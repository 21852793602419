import { instanceOfISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import { isItemIncluded, itemIsInRange } from 'app/ComponentLibrary/Filter/helper';
import moment from 'moment';
import { ExpiringDocumentsFilterName } from '../Filters/ExpiringFilterConfig';
import { IFilterChipSelected } from '../Models/ExpiringDocumentInterfaces';

export const createFilterChips = (filter, selectedChips?: IFilterChipSelected) => ({
  [ExpiringDocumentsFilterName.placementId]: generateFieldChips(
    filter[ExpiringDocumentsFilterName.placementId],
    selectedChips?.placementId || undefined,
  ),
  [ExpiringDocumentsFilterName.candidateName]: generateFieldChips(
    filter[ExpiringDocumentsFilterName.candidateName],
    selectedChips?.candidateName || undefined,
  ),
  [ExpiringDocumentsFilterName.credential]: generateFieldChips(
    filter[ExpiringDocumentsFilterName.credential],
    selectedChips?.credential || undefined,
  ),
  [ExpiringDocumentsFilterName.daysUntilExpiration]: generateFieldChips(
    filter[ExpiringDocumentsFilterName.daysUntilExpiration],
    selectedChips?.daysUntilExpiration || undefined,
  ),
  [ExpiringDocumentsFilterName.expirationDate]: generateFieldChips(
    filter[ExpiringDocumentsFilterName.expirationDate],
    selectedChips?.expirationDate || undefined,
  ),
  [ExpiringDocumentsFilterName.facilityName]: generateFieldChips(
    filter[ExpiringDocumentsFilterName.facilityName],
    selectedChips?.facilityName || undefined,
  ),
  [ExpiringDocumentsFilterName.credentialCategory]: generateFieldChips(
    filter[ExpiringDocumentsFilterName.credentialCategory],
    selectedChips?.credentialCategory || undefined,
  ),
  [ExpiringDocumentsFilterName.credentialGroup]: generateFieldChips(
    filter[ExpiringDocumentsFilterName.credentialGroup],
    selectedChips?.credentialGroup || undefined,
  ),
  [ExpiringDocumentsFilterName.candidateId]: generateFieldChips(
    filter[ExpiringDocumentsFilterName.candidateId],
    selectedChips?.candidateId || undefined,
  ),
  [ExpiringDocumentsFilterName.tier]: generateFieldChips(
    filter[ExpiringDocumentsFilterName.tier],
    selectedChips?.tier || undefined,
  ),
});

export const getFilteredData = (filter, sourceData, chipSelected?: IFilterChipSelected) =>
  sourceData.reduce(
    (resultArray, item) =>
      isItemIncluded(
        item['placementId'],
        parseData(chipSelected?.placementId) || parseData(filter[ExpiringDocumentsFilterName.placementId]),
      ) &&
      isItemIncluded(
        item['candidateName'],
        chipSelected?.candidateName || filter[ExpiringDocumentsFilterName.candidateName],
      ) &&
      isItemIncluded(item['credential'], chipSelected?.credential || filter[ExpiringDocumentsFilterName.credential]) &&
      (chipSelected?.daysUntilExpiration || filter[ExpiringDocumentsFilterName.daysUntilExpiration]
        ? parseInt(item['daysUntilExpiration'] || '0') <=
          parseInt(filter[ExpiringDocumentsFilterName.daysUntilExpiration])
        : true) &&
      (filter[ExpiringDocumentsFilterName.expirationDate]
        ? item['expirationDate']
          ? itemIsInRange(item['expirationDate'], filter[ExpiringDocumentsFilterName.expirationDate])
          : false
        : true) &&
      isItemIncluded(item['tier'], chipSelected?.tier || filter[ExpiringDocumentsFilterName.tier]) &&
      isItemIncluded(
        item['credentialCategory'],
        chipSelected?.credentialCategory || filter[ExpiringDocumentsFilterName.credentialCategory],
      ) &&
      isItemIncluded(
        item['credentialGroup'],
        chipSelected?.credentialGroup || filter[ExpiringDocumentsFilterName.credentialGroup],
      ) &&
      isItemIncluded(
        item['facilityName'],
        chipSelected?.facilityName || filter[ExpiringDocumentsFilterName.facilityName],
      ) &&
      isItemIncluded(
        item['candidateId'],
        parseData(chipSelected?.candidateId) || parseData(filter[ExpiringDocumentsFilterName.candidateId]),
      )
        ? [...resultArray, item]
        : resultArray,
    [],
  );

export const generateFieldChips = (values, selectedChip?) => {
  return typeof values !== 'string' && (values && values?.startDate && values?.endDate) != undefined
    ? [
        {
          label: `${moment(values.startDate.toLocaleDateString()).format('MM/DD/YYYY')} - ${moment(
            values.endDate.toLocaleDateString(),
          ).format('MM/DD/YYYY')}`,
          enableDelete: true,
          value: values,
          isSelected: selectedChip?.some(chip => chip.value === values) || false,
        },
      ]
    : typeof values === 'string' && values !== ''
    ? [
        {
          label: values,
          enableDelete: true,
          value: values,
          isSelected: selectedChip?.some(chip => chip.value === values) || false,
        },
      ]
    : values?.length
    ? instanceOfISelection(values[0])
      ? values?.reduce(
          (result, choice) =>
            choice.value === 'All'
              ? result
              : [
                  ...result,
                  {
                    label: choice.name,
                    enableDelete: true,
                    value: choice,
                    isSelected: selectedChip?.some(chip => chip.value === choice.value) || false,
                  },
                ],
          [],
        )
      : values?.reduce(
          (result, choice) =>
            choice === 'All'
              ? result
              : [
                  ...result,
                  {
                    label: choice,
                    enableDelete: true,
                    value: choice,
                    isSelected: selectedChip?.includes(choice) || false,
                  },
                ],
          [],
        )
    : [];
};

//
// ─── COMMON FUNCTIONS ───────────────────────────────────────────────────────────
//
export const determineChipSelected = (newChip, selected) =>
  newChip.isSelected ? null : [instanceOfISelection(newChip) ? newChip : newChip.value];

export const parseData = data => {
  return data?.reduce(function (newArr, item) {
    newArr.push(parseInt(item?.value));
    return newArr;
  }, []);
};
