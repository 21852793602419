import { useEffect } from 'react';

/** Component is used to access inaccessible methods from react-hook-form Controller
 * to invoke actions - say undo action */
export const ActionAccessorHook = ({ hookDependancies, actionChecker, accessAction, ...props }) => {
  useEffect(() => {
    if (actionChecker()) accessAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...hookDependancies]);
  return null;
};
