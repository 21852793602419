import { TextField } from 'amn-ui-core';
import styledComponent from 'styled-components';

const backgroundColor = '#ffffff';
const radius = '5px';

export const CustomTextField = styledComponent(TextField)`
  background-color: ${backgroundColor};
  border-top-left-radius: ${radius};
  border-top-right-radius: ${radius};
`;
