import React from 'react';
import { Box, Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { KeyValuePair } from './KeyValuePair';
import { missingField } from 'app/constants';

const useStyles = makeStyles<{ disabled: boolean }>()((theme, { disabled = false }) => ({
  container: {
    border: `1px solid ${theme.palette.system.borderColor1}`,
    background: theme.palette.components.box.container.backgroundColor,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    opacity: disabled ? 0.4 : 1,
    pointerEvents: disabled ? 'none' : 'inherit',
  },
  titleBar: {
    borderBottom: `1px solid ${theme.palette.system.borderColor1}`,
    background: theme.palette.system.offWhite,
    color: theme.palette.system.darkGray,
    fontSize: 14,
    padding: 13,
    fontWeight: 500,
  },
  children: {
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
}));

const getNumberValue = num => (Number.isFinite(num) ? num : missingField);

export const ActiveSubmissions = props => {
  const { activePlacementCounts, promiseInProgress, disabled } = props;
  const { t } = useTranslation();
  const { classes } = useStyles({disabled});

  return (
    <Box className={classes.container} data-testid="Active-Submission-container">
      <Box className={classes.titleBar}>{t('Preferences.submissionLimits.activeSubmissions')}</Box>
      <Box className={classes.children}>
        <KeyValuePair
          keyOfValue={t('Preferences.submissionLimits.requestFileOut')}
          value={getNumberValue(activePlacementCounts?.rfo)}
          isLoading={promiseInProgress}
        />
        <KeyValuePair
          keyOfValue={t('Preferences.submissionLimits.sent')}
          value={getNumberValue(activePlacementCounts?.sent)}
          isLoading={promiseInProgress}
        />
        <KeyValuePair
          keyOfValue={t('Preferences.submissionLimits.other')}
          value={getNumberValue(activePlacementCounts?.others)}
          isLoading={promiseInProgress}
        />
      </Box>
    </Box>
  );
};
