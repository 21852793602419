import { TaskEntity } from 'app/models/Tasks/Tasks';
import { getAllEmployees } from 'app/services/TaskServices/TaskServices';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useDebounce } from 'utils/customHooks/useDebounce';
import { ITypeAheadOption } from '../CustomComponents/ControlledTypeAheadWithAvatar';
import { MultiSelectTypeAheadInlineChips } from '../CustomComponents/MultiSelectTypeAheadInlineChips';
import { GenerateAllEmployeeObject } from './FormatData';

export const AssignedToValue = props => {
  const {
    placementEmployees,
    assignedToValue,
    setAssignedValue,
    ccValues,
    error,
    helperText,
    isDisabled,
    isTouched,
    container,
    formMethods,
    taskVar,
  } = props;
  const { t } = useTranslation();
  const [employeeData, setEmployeeData] = useState<ITypeAheadOption[]>([]);
  const [keyword, keywordValue, setKeyword] = useDebounce<string>('', 500);
  const { promiseInProgress } = usePromiseTracker({ area: 'assigned-to', delay: 0 });

  const getFilteredData = employees => {
    const assignedEmployees = assignedToValue?.map(e => e?.object?.empId);
    const ccEmployees = ccValues?.map(e => e?.object?.empId);
    const filteredData = employees
      ?.filter(a => !assignedEmployees?.includes(a?.object?.empId) && !ccEmployees?.includes(a?.object?.empId))
      .map(obj => obj);
    return filteredData;
  };

  const fetchEmployeeDetails = async (searchKey: string) => {
    if (searchKey && keyword?.length > 2) {
      await getAllEmployees(searchKey).then(data => {
        const formatData = GenerateAllEmployeeObject(data);
        const filteredData = getFilteredData(formatData);
        setEmployeeData(prevData => filteredData);
      });
    } else {
      const empData = placementEmployees?.length > 0 ? placementEmployees : [];
      const filterData = empData?.length > 0 ? getFilteredData(empData) : [];
      setEmployeeData(filterData);
    }
  };

  useEffect(() => {
    trackPromise(fetchEmployeeDetails(keyword), 'assigned-to');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    if (placementEmployees && placementEmployees.length > 0) {
      const filteredData = ccValues?.length > 0 ? getFilteredData(placementEmployees) : placementEmployees;
      setEmployeeData(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placementEmployees, ccValues]);
  return (
    <React.Fragment>
      <MultiSelectTypeAheadInlineChips
        required={true}
        label={
          assignedToValue?.length > 0
            ? t('notification.createTask.assignedToLabelWithCount', {
                assigneeCount: `(${assignedToValue?.length})`,
              })
            : t('notification.createTask.assignedToLabel')
        }
        options={employeeData}
        value={assignedToValue}
        onChange={setAssignedValue}
        setKeyword={setKeyword}
        isLoading={promiseInProgress}
        isDisabled={isDisabled}
        error={error}
        helperText={helperText}
        inputValue={keyword}
        isTouched={isTouched}
        isOpenOption={container === TaskEntity.PLACEMENTS ? true : false}
        formMethods={formMethods}
        taskVar={taskVar}
      />
    </React.Fragment>
  );
};
