import { Box, Grid, IconButton, Link, Paper, Typography } from 'amn-ui-core';
import React from 'react';
import excelIcon from 'app/assets/images/IconImages/DepictIcons/ExcelIcon.svg';
import jpgIcon from 'app/assets/images/IconImages/DepictIcons/JpgIcon.svg';
import outlookIcon from 'app/assets/images/IconImages/DepictIcons/OutlookIcon.svg';
import pdfIcon from 'app/assets/images/IconImages/DepictIcons/PdfIcon.svg';
import pngIcon from 'app/assets/images/IconImages/DepictIcons/PngIcon.svg';
import wordIcon from 'app/assets/images/IconImages/DepictIcons/WordIcon.svg';
import { makeStyles } from 'tss-react/mui';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { colors } from 'styles/styleVariables';
import { OverflowTooltip } from '../Tooltips';

const useStyles = makeStyles<{ error: boolean; errorSeverity?: 'warning' | 'error' }>()(
  (theme, { error, errorSeverity }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: 3,
    },
    cardContainer: {
      border: `1px solid ${error ? (errorSeverity === 'warning' ? '#FFA22E' : '#CC0000') : colors.containerBorder}`,
      borderRadius: 0,
      boxShadow: 'none',
      padding: 12,
    },
    iconGrid: {
      width: 'min-content',
    },
    deleteBtn: {
      padding: 2,
    },
    fileSection: {
      width: 'inherit',
      paddingLeft: 6,
    },
    deleteIcon: {
      color: '#D1D1D1',
      '&:hover': {
        color: colors.amnLightBlue,
      },
    },
    size: {
      color: '#888888',
      opacity: 0.6,
      fontSize: 11,
    },
    tooltip: {
      maxWidth: 180,
      fontSize: 14,
      color: error ? (errorSeverity === 'warning' ? '#FFA22E' : '#CC0000') : colors.text,
    },
    errorMessage: {
      color: errorSeverity === 'warning' ? '#FFA22E' : '#CC0000',
      fontSize: 11,
    },
    documentName: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
);

export const DocumentCard = ({
  hash,
  fileName,
  fileType,
  fileSize,
  sizeDepicter = 'Kb',
  handleDelete,
  error = false,
  errorSeverity = undefined,
  errorMessage = '',
  isViewOnly = false,
  saasAttachmentUrl = '',
}) => {
  const { classes } = useStyles({ error, errorSeverity });

  const icon = React.useMemo(() => getIcon(fileType ?? fileName.split('.').pop()), [fileType, fileName]);

  const size = React.useMemo(() => `${fileSize ? Math.floor(fileSize / 1024) : 0} ${sizeDepicter}`, [fileSize]);

  const documentBody = () => {
    return (
      <Box className={classes.container}>
          <Paper classes={{ root: classes.cardContainer }}>
            <Grid container direction="row" wrap="nowrap">
              <Grid item classes={{ root: classes.iconGrid }}>
                <img src={icon} alt="#" style={{ whiteSpace: 'pre' }}></img>
              </Grid>
              <Grid item classes={{ root: classes.fileSection }}>
                <OverflowTooltip contentClass={classes.tooltip} tooltipContent={fileName} value={fileName} />
                <div className={classes.size}>{size}</div>
              </Grid>
              {!isViewOnly && (
                <Grid item>
                  <IconButton color="secondary" size="medium" onClick={() => handleDelete(hash)} classes={{ root: classes.deleteBtn }}>
                    <DeleteOutlinedIcon classes={{ root: classes.deleteIcon }} />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Paper>
          {error && errorMessage && <Box className={classes.errorMessage}>{errorMessage}</Box>}
      </Box>
    );
  };

  return (
    <>
      {isViewOnly ? (
        <a
          href={saasAttachmentUrl}
          className={classes.documentName}
          style={{ color: '#006FB9' }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {documentBody()}
        </a>
      ) : (
        <>{documentBody()}</>
      )}
    </>
  );
};

const getIcon = fileType => {
  switch (fileType) {
    case 'xls':
    case 'xlsx':
    case 'csv':
      return excelIcon;
    case 'jpeg':
    case 'jpg':
      return jpgIcon;
    case 'pst':
    case 'msg':
      return outlookIcon;
    case 'pdf':
      return pdfIcon;
    case 'png':
    case 'PNG':
      return pngIcon;
    case 'docx':
    case 'doc':
      return wordIcon;
    default:
      return undefined;
  }
};
