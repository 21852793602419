import React from 'react';
import { Grid, Typography } from 'amn-ui-core';
import styledComponent from 'styled-components';
import GreenCheck from 'app/assets/images/IconImages/GreenCheckCircleIcon.svg';
import OrangeWarning from 'app/assets/images/IconImages/OrangeWarningIcon.svg';
import RedWarning from 'app/assets/images/IconImages/RedWarningIcon.svg';
import { colors } from 'styles/styleVariables';
import { useDecision } from '@optimizely/react-sdk';
import { ff_placement_ui_assignment_icons } from 'app/constants/FeatureFlags/Placement/Keys';

interface AssignmentTitleProps {
  title: string;
}
export const DetailsTitle = ({ title }: AssignmentTitleProps) => {
  const [enableAssignment] = useDecision(ff_placement_ui_assignment_icons);
  return (
    <Grid container spacing={3} wrap="nowrap" alignItems="center">
      <Grid item>{<Title variant="h5">{title}</Title>}</Grid>
      {enableAssignment?.enabled && false ? (
        <>
          <CountItem
            icon={<img src={GreenCheck} alt="Pass" style={{ width: '16px', height: '16px' }} />}
            count={`1/1`}
          />
          <CountItem
            icon={<img src={RedWarning} alt="Logic" style={{ width: '16px', height: '16px' }} />}
            count={`1/1`}
          />
          <CountItem
            icon={<img src={OrangeWarning} alt="Fail" style={{ width: '16px', height: '16px' }} />}
            count={`1/1`}
          />
        </>
      ) : null}
    </Grid>
  );
};

const CountItem = ({ icon, count }) => {
  return (
    <CountItemWrapper
      //@ts-ignore
      item
    >
      <Grid container spacing={1} wrap="nowrap" alignItems="center">
        <Grid item>{icon}</Grid>
        <Grid item>
          <Typography>{count}</Typography>
        </Grid>
      </Grid>
    </CountItemWrapper>
  );
};

const Title = styledComponent(Typography)`
  color: ${colors.text};
  font-size: 16px;
  font-weight: 500;
`;

const CountItemWrapper = styledComponent(Grid)`
    padding: 4px !important;
    border-radius: 4px;
    border: '1px solid #0069af'};
    margin-right: 12px;
  `;
