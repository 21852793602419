/* eslint-disable tss-unused-classes/unused-classes */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonSpace, MessageHeader } from '../OrderDesign';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Avatar, Divider, Button, Box, Dialog, DialogActions, DialogTitle } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ResponsiveColumnContainer } from 'app/layout/ResposiveColumnContainer';
import { MediaQuery } from 'app/layout/MediaQuery';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { orderDataActions } from '../Store/Order.redux';
import { selectOrderCreationProcessStep } from 'app/components/Order/Store/Order.selectors';

const useStyles = makeStyles()(theme => ({
  container: {
    flexWrap: 'nowrap',
    width: '100%',
  },
  containerSecond: {
    marginTop: '10px',
  },
  title: {
    color: '#AC98DB',
    fontSize: 16,
    fontWeight: 500,
  },
  divider: {
    margin: '0 12px',
  },
  textContent: {
    textAlign: 'left',
    alignSelf: 'column',
    color: '#333333 !important',
    fontSize: '14px !important',
    textTransform: 'uppercase',
    fontWeight: 'initial',
  },
  textContentSecond: {
    textAlign: 'left',
    alignSelf: 'column',
    color: '#555555 !important',
    fontSize: '14px !important',
  },
  avatarName: {
    height: 50,
    width: 20,

    fontSize: 20,
    fontWeight: 500,
    backgroundColor: '#61489E',
    textTransform: 'uppercase',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  },
  avatarText: {
    height: 50,
    width: 146,
    marginRight: 10,
    fontSize: 20,
    fontWeight: 500,
    backgroundColor: '#AC98DB',
    textTransform: 'uppercase',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    justifyContent: 'start',
    paddingLeft: '5px',
  },
  containerAvatar: {
    display: 'flex',
    justifyContent: 'start',
  },
  myAvatarAlignment: {
    display: 'flex',
    flexDirection: 'row',
  },
  contactDrawer: {
    marginTop: 10,
    cursor: 'not-allowed',
  },
  hideBlock: {
    display: 'none',
  },
  userIcon: {
    backgroundColor: '#F8F8F8',
    justifyContent: 'start',
    width: '95%',
    border: '1px solid #006fb9',
    '&:hover': {
      backgroundColor: '#006FB9',
      color: '#fff',
      border: '1px solid #006fb9',
    },
  },
  interactIcons: {
    paddingTop: '6px',
  },
  orderStatus: {
    backgroundColor: '#34B233',

    fontSize: '14px',
    width: 'max-content',
    padding: '8px',
    height: '27px',
    justifyContent: 'space-between',
    minWidth: '153px',
    maxWidth: '165px',
    [theme.breakpoints.up('lg')]: {
      width: 'inherit !important',
    },
  },
  statusSecond: {
    fontSize: '12px !important',
    width: '128px !important',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    minWidth: '0px !important',
  },

  placementStatus: {
    backgroundColor: '#003C69',
    height: '27px',
    fontSize: '14px',
    width: 'max-content',
    padding: '8px',
    minWidth: '153px',
    maxWidth: '165px',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  placementStatusText: {
    display: 'block',
  },
  brand: {
    color: '#E89120 !important',
    fontWeight: 500,
    paddingLeft: '5px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px !important',
    },
  },
  inDemand: {
    color: '#003E68 !important',
    fontWeight: 500,
  },
  rowHeadDefaultColor: {
    color: '#333333 !important',
  },
  rowContentDefaultColor: {
    color: '#555555 !important',
  },
  rowHead: {
    fontSize: '14px !important',
    fontWeight: 400,
  },
  rowContent: {
    fontSize: '14px !important',
    fontWeight: 600,
  },
  myRow: {
    flexGrow: 1,
  },
  contactIcons: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerInfo: {
    display: 'flex',
    whiteSpace: 'nowrap',
  },
  alignContent: {
    display: 'table-row',
  },
  alignContentCustom: {
    padding: '3px 0px 0px 6px !important',
  },
  myTableLayout: {
    display: 'table',
    borderCollapse: 'separate',
  },
  defaultAlign: {
    display: 'table-cell',
    padding: '7px 10px 5px 0px',
  },
  customInDemand: {
    display: 'flex',
    fontSize: '12px',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
  },
  lowRatioLayout: {
    fontSize: '12px !important',
    whiteSpace: 'nowrap',
  },
  smallerDefaultAlign: {
    display: 'table-cell',
    padding: '5px 20px 5px 0px',
  },
  buttonText: {
    paddingTop: '2px',
  },
  customFontSize: {
    fontSize: '25px !important',
  },

  actionContainer: {
    backgroundColor: '#6699CC70',
    width: 'inherit',
    display: 'flex',
    padding: '7px',
    height: 38,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '12px',
    [theme.breakpoints.down('md')]: {
      marginTop: '7px !important',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px !important',
    },
  },
  blueRibbonContainer: {
    backgroundColor: '#E6E1F2',
    width: 'inherit',
    display: 'flex',
    padding: '0px 0px 0px 12px',
    minHeight: 43,
    alignItems: 'center',
    marginTop: '12px',
    [theme.breakpoints.down('md')]: {
      marginTop: '7px !important',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px !important',
    },
  },
  blueRibbonAlign: {
    padding: 'initial',
  },
  rowHeadCustom: {
    color: '#003E68',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    },
  },
  rowContentCustom: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    },
  },
  addInfo: {
    color: '#006FB9',
    paddingLeft: '5px',
    textDecoration: 'underline',
  },
  orderStatusText: {
    textOverflow: 'ellipsis',
    display: 'block',
    overflow: 'hidden',
  },
  fallBack: { display: 'none' },
  address: {
    color: '#333333',
    fontWeight: 500,
  },
  additionalOrders: {
    color: '#61489E',
    fontWeight: 500,

    textTransform: 'uppercase',
  },
  link: {
    color: '#006FB9',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  linkNoDecor: {
    color: '#006FB9',
  },
  blueRibbonActive: {
    display: 'block',
  },
  blueRibbonInActive: {
    display: 'none',
  },
  displayFlex: {
    display: 'flex',
  },
  buleRibbonStartPan: {
    display: 'flex',
    width: '20%',
  },
  buleRibbonRightPan: {
    display: 'flex',
    alignItems: 'flex-end',
    flexGrow: 1,
  },
  buleRibbonLeftPan: {
    display: 'flex',
    alignItems: 'flex-start',
    flexGrow: 1,
  },
  arrowIconRoot: {
    color: '#006FB9',
  },
  navigationArrow: {
    display: 'flex',
    alignItems: 'center',
    '&:visited': {
      color: 'transparent',
    },
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'transparent',
  },
  button: {
    width: '100px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#006FB9',
      color: '#ffffff',
    },
    '&[data-type="large"]': {
      width: '250px',
    },
  },
  buttonWidth: {
    marginRight: theme.spacing(1),
  },
  buttonHeight: {
    lineHeight: 2,
  },
  cancelButton: {
    backgroundColor: '#8E9195',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#8E9195',
    },
  },
  modalButton: {
    width: 100,
  },
}));

interface FacilityValue {
  division: string;
  facilityAddress: {
    line1: string;
    line2: string;
  };
  hospitalAccountManager: {
    userId: number;
    firstName: string;
    lastName: string;
  };
  regionalDirector: {
    userId: number;
    firstName: string;
    lastName: string;
  };
  facilityId: number;
  facilityName: string;
  technologyVendor: string;
  healthSystem: string;
}

interface Props {
  facilityData: FacilityValue;
  type: string;
  activateStep: number;
  onBack(value: unknown): void;
  isUnit: boolean;
  setClickOnBackButton(value: boolean): void;
}

const getName = (data: { firstName: string; lastName: string }) => {
  return `${data?.firstName || ''} ${data?.lastName || ''}`;
};

export const OrderCreationHeader = (props: Props) => {
  const { facilityData, type, isUnit, setClickOnBackButton } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [openCancelConfirmation, setOpenCancelConfirmation] = React.useState(false);
  const dispatch = useDispatch();
  const storeOrderCreationProcessStep = useSelector(selectOrderCreationProcessStep);

  const {
    hospitalAccountManager,
    division = '',
    facilityAddress,
    facilityId,
    facilityName = '',
    regionalDirector,
    technologyVendor = '',
    healthSystem = '',
  } = facilityData;

  const backButton = () => {
    if (storeOrderCreationProcessStep) {
      if (storeOrderCreationProcessStep.currentStep === 'SelectUnit') {
        setOpenCancelConfirmation(true);
      } else if (
        storeOrderCreationProcessStep.currentStep === 'EditOrderRequirement' ||
        storeOrderCreationProcessStep.currentStep === 'UpdateOrderDetails' ||
        storeOrderCreationProcessStep.currentStep === 'SubmitOrder'
      ) {
        setClickOnBackButton(true);
      }
    } else {
      setOpenCancelConfirmation(true);
    }
  };

  const handleCancelConfirmClose = (isConfirm: boolean) => {
    if (isConfirm) {
      dispatch(orderDataActions.setNewOrder(null));
      window.history.pushState(null, '', `${window.location.pathname}`);
    }
    setOpenCancelConfirmation(false);
  };

  return (
    <>
      <Grid container xs={12} direction="row" classes={{ container: classes.container }} data-testid="table-test-id">
        <Grid item direction="column" classes={{ root: classes.containerAvatar }}>
          <Grid item direction="row" classes={{ root: classes.myAvatarAlignment }}>
            <Avatar variant="square" classes={{ root: classes.avatarName, fallback: classes.fallBack }}>
              {}
            </Avatar>
            <Avatar
              id={'facilityHeaderid'}
              variant="square"
              classes={{ root: classes.avatarText }}
              data-testId="facility-id"
            >
              {`FID ${facilityId}`}
            </Avatar>
          </Grid>
          <Grid item classes={{ root: classes.contactDrawer }} className={isUnit ? classes.hideBlock : ''}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.userIcon}
              startIcon={<VisibilityIcon color="disabled" classes={{ root: classes.customFontSize }} />}
              disabled
            >
              <span className={classes.buttonText}>{`Preview`}</span>
            </Button>
          </Grid>
        </Grid>
        <Grid item direction="column" classes={{ root: classes.container }}>
          <Grid
            item
            direction="row"
            classes={{ root: classes.title }}
            id={'facilityHeadername'}
            data-testId="facility-name"
          >
            {facilityName}
          </Grid>
          <Grid item direction="row" classes={{ root: classes.headerInfo }}>
            <Grid item direction="column">
              <table>
                {!isUnit && (
                  <ResponsiveColumnContainer
                    id={'facilityHeaderorderType'}
                    rowHead={`${t('order.facility.orderCreationHeader.orderType')}`}
                    rowContent={type}
                    classes={classes}
                    minWidth={'xs'}
                    customRowHeadClass={isUnit ? `${classes.hideBlock}` : ``}
                    customRowContentClass={isUnit ? `${classes.hideBlock}` : `${classes.link}`}
                  />
                )}
                <ResponsiveColumnContainer
                  id={'facilityHeaderorderdivision'}
                  rowHead={t('order.facility.orderCreationHeader.division')}
                  rowContent={division || ''}
                  classes={classes}
                  minWidth={'xs'}
                />
                <ResponsiveColumnContainer
                  id={'facilityHeaderordertechnologyVendor'}
                  rowHead={t('order.facility.orderCreationHeader.technologyVendor')}
                  rowContent={technologyVendor}
                  classes={classes}
                  maxWidth={'md'}
                />
              </table>
            </Grid>
            <MediaQuery minWidth={'xs'}>
              <Divider orientation="vertical" flexItem classes={{ root: classes.divider }} />
            </MediaQuery>
            <Grid item direction="column">
              <table>
                <ResponsiveColumnContainer
                  id={'facilityHeaderorderaccountManager'}
                  rowHead={t('order.facility.orderCreationHeader.accountManager')}
                  rowContent={getName(hospitalAccountManager)}
                  classes={classes}
                  minWidth={'xs'}
                />
                <ResponsiveColumnContainer
                  id={'facilityHeaderorderregionalDirector'}
                  rowHead={t('order.facility.orderCreationHeader.regionalDirector')}
                  rowContent={getName(regionalDirector)}
                  classes={classes}
                  minWidth={'xs'}
                />
                <ResponsiveColumnContainer
                  id={'facilityHeaderorderhealthcareSystem'}
                  rowHead={t('order.facility.orderCreationHeader.healthcareSystem')}
                  rowContent={healthSystem}
                  classes={classes}
                  maxWidth={'md'}
                />
              </table>
            </Grid>
            <MediaQuery minWidth={'lg'}>
              <Divider orientation="vertical" flexItem classes={{ root: classes.divider }} />
            </MediaQuery>
            <Grid item direction="column">
              <table>
                <ResponsiveColumnContainer
                  id={'facilityHeaderorderhealthcareSystem'}
                  rowHead={t('order.facility.orderCreationHeader.healthcareSystem')}
                  rowContent={healthSystem}
                  classes={classes}
                  minWidth={'lg'}
                />
                <ResponsiveColumnContainer
                  id={'facilityHeaderordertechnologyVendor'}
                  rowHead={t('order.facility.orderCreationHeader.technologyVendor')}
                  rowContent={technologyVendor}
                  classes={classes}
                  minWidth={'lg'}
                />
              </table>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        direction="row"
        className={storeOrderCreationProcessStep ? classes.blueRibbonActive : classes.blueRibbonInActive}
        classes={{ container: classes.container }}
      >
        <Grid item direction="row" classes={{ root: classes.blueRibbonContainer }}>
          <Grid item className={classes.buleRibbonStartPan}>
            <table>
              <ResponsiveColumnContainer
                id={'facilityHeaderorderadditionalOrders'}
                rowHead={t('order.facility.orderCreationHeader.additionalOrders')}
                rowContent={' '}
                classes={classes}
                customRowHeadClass={classes.additionalOrders}
              />
            </table>
          </Grid>
        </Grid>
      </Grid>
      <Grid container xs={12} direction="row" classes={{ container: classes.container }}>
        <Grid item direction="row" classes={{ root: classes.actionContainer }}>
          <Grid item className={classes.displayFlex}>
            <table>
              {storeOrderCreationProcessStep && (
                <ResponsiveColumnContainer
                  id={'facilityHeaderadditionalOrdersContainer'}
                  rowHead={
                    <Box onClick={backButton} className={classes.navigationArrow}>
                      <ArrowBackIcon fontSize="small" classes={{ root: classes.arrowIconRoot }} />
                    </Box>
                  }
                  rowContent={' '}
                  classes={classes}
                  customRowHeadClass={classes.additionalOrders}
                />
              )}
            </table>
          </Grid>
          <Dialog
            open={openCancelConfirmation}
            onClose={() => handleCancelConfirmClose(false)}
            fullWidth
            maxWidth={'sm'}
            aria-labelledby="cancel-confirmation"
            aria-describedby="cancel-confirmation-description"
            disableEscapeKeyDown
          >
            <DialogTitle id="cancel-confirmation-title">
              <MessageHeader>{`Are you sure you want to cancel?`}</MessageHeader>
            </DialogTitle>
            <DialogActions>
              <ButtonSpace>
                <Button
                  onClick={() => handleCancelConfirmClose(false)}
                  disableElevation
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  color="inherit"
                  className={`${classes.button} ${classes.buttonWidth} ${classes.buttonHeight} ${classes.modalButton}`}
                  data-testid="test dialog confirm button"
                >
                  {`No`}
                </Button>
                <Button
                  onClick={() => handleCancelConfirmClose(true)}
                  disableElevation
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  variant="contained"
                  color="primary"
                  className={`${classes.button} ${classes.buttonWidth} ${classes.buttonHeight} ${classes.modalButton}`}
                  data-testid="test dialog cancel button"
                >
                  {`Yes`}
                </Button>
              </ButtonSpace>
            </DialogActions>
          </Dialog>
          <Grid item className={classes.displayFlex}>
            <table>
              <ResponsiveColumnContainer
                id={'facilityHeaderorderaddress'}
                rowHead={`${facilityAddress?.line1 || ''} ${facilityAddress?.line2 || ''}`}
                classes={classes}
                customRowHeadClass={classes.address}
              />
            </table>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
