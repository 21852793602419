import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomTextField } from '../../CustomComponents/CustomTextField';
import { Checkbox, FormControlLabel, Grid } from 'amn-ui-core';

export const TravelPartner = () => {
  const { control } = useFormContext();
  return <Controller name="travel" control={control} render={({ ref, ...rest }) => <Control {...rest} />} />;
};

const Control = ({ value, onChange }) => {
  const handleChange = React.useCallback(
    (key: 'travelsWithPartner' | 'travelPartner', newValue) =>
      key === 'travelsWithPartner'
        ? onChange({ travelsWithPartner: newValue, travelPartner: null })
        : onChange({ ...value, travelPartner: newValue }),
    [onChange, value],
  );

  return (
    <Grid container spacing={2}>
      <Grid item>
        <FormControlLabel
          sx={{ marginRight: 0 }}
          value={value?.travelsWithPartner}
          control={<Checkbox sx={{ padding: '0 9px' }} />}
          label="Travels With Partner"
          onChange={(_, newState) => handleChange('travelsWithPartner', newState)}
        />
      </Grid>
      {value?.travelsWithPartner ? (
        <Grid item sx={{ minWidth: '150px' }}>
          <CustomTextField
            label="Partner Name"
            value={value?.travelPartner}
            onChange={e => handleChange('travelPartner', e.target.value)}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};
