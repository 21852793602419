import { IElementAccessor } from 'app/components/Common/EditPage/PageActions';
import React from 'react';

export type ITabbingType = Omit<IElementAccessor, 'value'>;
interface UseTabbingOrderProps {
  tabOrderList: ITabbingType[];
  onSave?: (event) => void;
  onUndo?: (event) => void;
}

/**
 * @param tabOrderList list of IDs to focus on next in the form
 */
export const useTabbingOrder = (props: UseTabbingOrderProps) => {
  // TODO: @RIJASH  Add this to PageActions with custom handlers for onSave/onUndo :31st Oct 2022
  // TODO: set up custom item in redux store that's used here (E.g., setting the IdList)
  // others can then use an action to disable tabbing if this is enabled for the component that is using it, set their own Ids,
  // or otherwise control this through redux actions
  const [currentKey, setCurrentKey] = React.useState<number>(0);
  const [idList, setIdList] = React.useState<ITabbingType[]>(props.tabOrderList);
  const { onSave, onUndo } = props;

  const focusNextField = React.useCallback(
    (event, index) => {
      const doc = document.getElementById(idList[index].id);
      if (doc && !doc?.hasAttribute('disabled')) {
        event.preventDefault();
        event.stopPropagation();
        if (index === idList.length - 1) setCurrentKey(0);
        else setCurrentKey(index + 1);
        doc.focus();
        if (idList[index].action === 'click') {
          doc.click();
        }
      } else if (index + 1 <= idList.length - 1) {
        // find next available field if the current one is not found or is disabled,
        // or end at the end of the list
        focusNextField(event, index + 1);
      }
    },
    [idList],
  );

  const pageKeyDownHandler = React.useCallback(
    event => {
      if (event.keyCode === 9) focusNextField(event, currentKey);
      else if (event.keyCode === 83 && (event.metaKey || event.ctrlKey)) {
        onSave?.(event);
      } else if (event.keyCode === 90 && (event.metaKey || event.ctrlKey)) {
        onUndo?.(event);
      }
    },
    [currentKey, focusNextField, onSave, onUndo],
  );

  const setCurrentFocusId = id => {
    const index = idList.findIndex(item => item.id === id);
    if (index !== -1) {
      setCurrentKey(index);
    }
  };

  const updateIdList = (newList, currentFocusIndex = 0) => {
    setIdList(newList);
    setCurrentKey(currentFocusIndex);
  };

  React.useEffect(() => {
    window.addEventListener('keydown', pageKeyDownHandler);
    return () => {
      window.removeEventListener('keydown', pageKeyDownHandler);
    };
  }, [pageKeyDownHandler]);

  return { setCurrentFocusId, updateIdList };
};
