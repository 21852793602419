import { CircularProgress, Container, Paper, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useEffect, useState } from 'react';
import { SalesReportUserSearch } from './SalesReportUserSearch';
import useDocumentTitle from 'utils/customHooks/useDocumentTitle';
import { trackPageView, usePageLoadingTracker, usePageVisitTimeTracker } from 'app-insights/appInsightsTracking';
import { useDispatch, useSelector } from 'react-redux';
import { selectNavigationStick } from '@AMIEWEB/GlobalNavigationMenu/NavigationStick.selectors';
import { navigationStickActions, reportMenu } from '@AMIEWEB/GlobalNavigationMenu/NavigationStick.redux';
import { getSearchFilterOptions } from 'app/services/SharedServices/SharedServices';
import { FormProvider, useForm } from 'react-hook-form';
import { addDays } from 'date-fns';
import { SalesReportDetails } from './SalesReportDetails';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { reportDetailsReducer, reportsViewSliceKey } from 'store/redux-store/report-details/slice';
import { reportDetailsSaga } from 'store/redux-store/report-details/saga';
import { selectR225ReportDetails } from 'store/redux-store/report-details/selectors';
import { usePromiseTracker } from 'react-promise-tracker';
import NoReportData from 'app/assets/images/NoReportData.svg';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const useStyles = makeStyles<{ controlsHeight: string }>()((theme, { controlsHeight }) => ({
  root: {
    backgroundColor: theme.palette.framework.system.backgroundGrey,
    maxHeight: controlsHeight,
    minHeight: controlsHeight,
    boxShadow: 'none',
  },
  rightContainer: {
    backgroundColor: theme.palette.common.white,
    maxHeight: controlsHeight,
    paddingLeft: 12,
    overflowY: 'auto',
  },
  pagePadding: {
    padding: '12px 0px 12px 12px !important',
    width: 'calc(100% + 12px)',
  },
  validationLoader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: '50%',
  },
  progressLoader: {
    position: 'absolute',
    left: 'calc(60% - 20px)',
    top: '50%',
  },
  imageLoader: {
    position: 'absolute',
    left: 'calc(55% - 20px)',
    top: '45%',
  },
}));

export const SalesReportWrapper = props => {
  const { workDesk } = props;
  useInjectReducer({ key: reportsViewSliceKey, reducer: reportDetailsReducer });
  useInjectSaga({ key: reportsViewSliceKey, saga: reportDetailsSaga });
  const { t } = useTranslation();
  const { innerHeight } = window;
  const customHeight = `${_.round(_.divide(innerHeight, 100) * (innerHeight > 720 && workDesk ? 88 : innerHeight > 720   ? 92 : workDesk ? 82 : 86))}px`;
  const { classes,cx } = useStyles({ controlsHeight: customHeight });
  const dispatch = useDispatch();
  const pageTitle = t('reportPages.r225.pageTitle');
  useDocumentTitle({ title: pageTitle });
  usePageVisitTimeTracker(pageTitle);
  trackPageView({ name: pageTitle });
  const { resetPageDidLoad, pageDidLoad } = usePageLoadingTracker({ name: pageTitle });
  const { selectedMenu } = useSelector(selectNavigationStick);
  const [optionsList, setOptionList] = useState<any>({});
  const [optionsLoaded, setOptionsLoaded] = useState<boolean>(false);
  const formMethods = useForm({
    defaultValues: { userSearch: null, dateRange: { startDate: new Date(), endDate: addDays(new Date(), 7) } },
  });
  const { promiseInProgress } = usePromiseTracker({
    area: 'get-r225-report',
    delay: 0,
  });
  const r225Report = useSelector(selectR225ReportDetails)?.response;

  const getDropDownOptions = async () => {
    resetPageDidLoad();
    const [recruiters, directors, brands] = await Promise.all([
      getSearchFilterOptions('recruiters', true),
      getSearchFilterOptions('recruitmentdirectors', true),
      getSearchFilterOptions('brands', true),
    ]);
    setOptionList({ recruiters: recruiters, directors: directors, brands: brands });
    setOptionsLoaded(true);
    pageDidLoad();
  };

  useEffect(() => {
    if (!['report'].includes(selectedMenu.key) && !workDesk) {
      const subMenu = reportMenu.subMenu.find(x => x.key === 'recruiterSales');
      dispatch(navigationStickActions.setSelectedMenuByKey('report'));
      dispatch(navigationStickActions.setSelectedSubMenu(subMenu));
    }
    getDropDownOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...formMethods}>
      <Container
        disableGutters
        maxWidth={false}
        className={cx({
          page: true,
          [classes.pagePadding] : innerHeight < 720 && !workDesk,
        })}
      >
        <Paper
          id="recruiter-sales-report-page"
          classes={{ root: classes.root }}
          sx={{
            ...(workDesk && { marginLeft: '-12px' }),
          }}
        >
          <LayoutGrid container direction="column">
            <LayoutGridItem md={12} xs={12}>
              <Typography style={{ fontWeight: 500 }}>{t('reportPages.r225.title')}</Typography>
            </LayoutGridItem>
            <LayoutGridItem md={12} xs={12}>
              {optionsLoaded && (
                <LayoutGrid container direction="row" md={12} xs={12}>
                  <LayoutGridItem md={3} xs={12}>
                    <SalesReportUserSearch optionsList={optionsList} {...props} />
                  </LayoutGridItem>
                  <LayoutGridItem md={9} xs={12} className={classes.rightContainer}>
                    {(!optionsLoaded || promiseInProgress) && (
                      <CircularProgress
                        className={promiseInProgress ? classes.progressLoader : classes.validationLoader}
                      />
                    )}
                    {r225Report?.length === 0 && optionsLoaded && !promiseInProgress && (
                      <img className={classes.imageLoader} src={NoReportData} alt="No Report Data" />
                    )}
                    <SalesReportDetails optionsList={optionsList} {...props} />
                  </LayoutGridItem>
                </LayoutGrid>
              )}
            </LayoutGridItem>
          </LayoutGrid>
        </Paper>
      </Container>
    </FormProvider>
  );
};
