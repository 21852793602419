import { selectUser } from 'oidc/user.selectors';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { HomePage } from 'app/layout/pages/HomePage';
import { CoverageRequestGrid } from './Tabs/Request/CoverageRequestGrid';
import { gridStateActions } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { CoverageFilter } from './CoverageFilter';
import { IFilterChipSelected } from 'app/models/UserCoverage/UserCoverage';
import { FormProvider, useForm } from 'react-hook-form';
import { defaultCoverageFilterValues, getDefaultCoverageFilterValues } from './Tabs/Request/filterUtils';
import { CoverageResultschip } from './CoverageResultsChips';
import { SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { coverageReducer, coverageSliceKey } from 'store/redux-store/user-coverage/slice';
import { coverageSaga } from 'store/redux-store/user-coverage/saga';
import { GenericTabs } from '@AMIEWEB/Common/Tabs/GenericTabs';

export const CoverageDeskWrapper = ({ workDeskDomain }: { workDeskDomain: string }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userInfo } = useSelector(selectUser);
  useInjectReducer({ key: coverageSliceKey, reducer: coverageReducer });
  useInjectSaga({ key: coverageSliceKey, saga: coverageSaga });
  const [customTabvalue, setCustomTabValue] = useState<number | undefined>(undefined);
  const formMethods = useForm({ defaultValues: defaultCoverageFilterValues, shouldUnregister: false });
  const {
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = formMethods;

  const onTabChanged = (tab: number) => {
    dispatch(gridStateActions.reset());
    // reset custom value to undefined so useEffect gets triggered on new change
    setCustomTabValue(undefined);
  };

  useEffect(() => {
    if (userInfo) {
      const defaultFilterValues = getDefaultCoverageFilterValues(userInfo);
      reset({ ...defaultFilterValues });
      dispatch(
        gridStateActions.setFilterAttributes({
          filterObject: Object.entries(defaultFilterValues),
        }),
      );
    }
  }, []);

  React.useEffect(() => {
    return () => {
      dispatch(gridStateActions.reset());
      dispatch(globalActions.closeBanner());
    };
  }, []);

  const tabs = React.useMemo(
    () => [{ label: `${t('home.coverageWorkDesk.tabs.request.name')}`, component: null }],
    [t],
  );

  const getTable = () => {
    // return table based on tab selection
    return <CoverageRequestGrid workDeskDomain={workDeskDomain} />;
  };

  const onApplyFilter = (values, selectedChips?: IFilterChipSelected) => {
    dispatch(
      gridStateActions.setFilterAttributes({
        filterObject: Object.entries(values),
        selectedChipFilters: selectedChips,
      }),
    );
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(filter => onApplyFilter(filter))}>
        <HomePage
          title={t('home.coverageWorkDesk.tabs.request.title')}
          stickIconArray={[3, 2]}
          filters={<CoverageFilter />}
          chips={<CoverageResultschip category={SearchType.coverage} onApplyFilter={onApplyFilter} />}
          subMenu={
            <GenericTabs
              tabs={tabs}
              modifyUrl
              hidePanel={true}
              bottomBorder={true}
              onTabChanged={onTabChanged}
              analytics={{ name: 'Coverage Work Desk' }}
            />
          }
          table={getTable()}
          showSidePanel={false}
        />
      </form>
    </FormProvider>
  );
};
