import { Grid, Typography } from 'amn-ui-core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { TableSection } from '../../../customComponents/TableSection';
import { getRequirementCheck } from '../../../helper';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import {
  selectServiceFailures,
  selectSubmissionInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { RequirementsRow } from '../../TableComponents/TableComponents';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';

export const CandidateStatus = () => {
  const { t } = useTranslation();
  const {
    amnReq: {
      amn: { status: amnReq },
      candidate: { status: candidateReq },
    },
    requirementFilter,
  } = useSelector(selectSubmissionInfo);
  const { statusAmnRequirement: serviceFailed } = useSelector(selectServiceFailures);

  const { placementId } = useContext(AddOnContext);

  const { promiseInProgress } = usePromiseTracker({ area: 'placement-submission-status-amn-requirement', delay: 0 });

  const dispatch = useDispatch();

  const { display } = useRequirementFilter('submission-amn', requirementFilter, amnReq?.type);

  return display ? (
    <RequirementsRow
      id="placement-submission-amn-status"
      title={t('placement.profile.summary.submission.amn.status.title')}
      requirementCell={<RowKey status={amnReq} t={t} />}
      candidateCell={<RowValue status={candidateReq} />}
      loading={promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() =>
        dispatch(placementSummaryActions.getStatusAmnRequirement({ placementId, isRetryingService: true }))
      }
    />
  ) : null;
};

const ListContent = ({ list }) => {
  const { t } = useTranslation();
  return (
    <ul className="unordered-list unordered-list-item">
      {list.map(item =>
        Array.isArray(item) ? (
          <ListContent list={item} />
        ) : (
          <li>
            <Typography variant="body1">{t(item)}</Typography>
          </li>
        ),
      )}
    </ul>
  );
};

const RowKey = ({ status, t }) => (
  <TableSection
    items={[
      {
        icon: status ? getRequirementCheck(status.type) : null,
        primaryContent: (
          <Grid container>
            <Grid item xs={12}>
              <Typography>{t(status?.message?.secondary)}</Typography>
            </Grid>
            <Grid item xs={12}>
              {status?.message?.ternary && <ListContent list={status?.message?.ternary} />}
            </Grid>
          </Grid>
        ),
      },
    ]}
  />
);

const RowValue = ({ status }) => <Typography variant="body1">{status ? status.data.status : ''} </Typography>;
