import React from 'react';
import { XGridDefaultColumn } from '@AMIEWEB/Common/XGrid/cells/XGridDefaults';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { LicenseRequirementRenderCell } from './LicenseRequirementRenderCell';
import { LicenseStateRenderCell } from '../../OrderLicense/StateRenderCell';
import { CompactStatusRenderCell } from '../../OrderLicense/CompactStatusRenderCell';
import { UnitCompactStatusRenderCell } from './Experience/UnitCompactStatusRenderCell';
import { UnitStateRenderCell } from './Experience/UnitStateRenderCell';
import { UnitLicenseRequirementRenderCell } from './Experience/UnitRequirementRenderCell';
import { Checkbox, materialUiXGrid } from 'amn-ui-core';
import { missingDate, missingField } from 'app/constants';
const defaultTaskWordDeskColumn = {
  ...XGridDefaultColumn,
  flex: 0,
};

export const unitLicenseColumns = (isDisabled, t, isNewOrderCretion = false): GridColumns => {
  const columns = [
    {
      ...defaultTaskWordDeskColumn,
      field: 'credentialName',
      headerName: t('order.license.licenseName'),
      flex: 1,
      hide: false,
      sortable: false,
    },
    {
      ...defaultTaskWordDeskColumn,
      field: 'compactStatus',
      headerName: t('order.license.compactStatus'),
      width: 180,
      hide: false,
      sortable: false,
      renderCell: params => (
        <CompactStatusRenderCell param={params} isDisabled={isDisabled} isNewOrderCretion={isNewOrderCretion} />
      ),
    },
    {
      ...defaultTaskWordDeskColumn,
      headerName: t('order.license.state'),
      field: 'state',
      width: 150,
      hide: false,
      sortable: false,
      renderCell: params => (
        <LicenseStateRenderCell param={params} isDisabled={isDisabled} isNewOrderCretion={isNewOrderCretion} />
      ),
    },
    {
      ...defaultTaskWordDeskColumn,
      headerName: 'Requirements',
      field: 'setting',
      flex: 1,
      hide: false,
      sortable: false,
      renderCell: params => <LicenseRequirementRenderCell param={params} isDisabled={isDisabled} />,
    },
  ];

  return columns;
};

export const unitLicenseGridColumnsAndOr = (
  groupIndex,
  skillSetIndex,
  t,
  selectedRowDetails,
  setSelectedRowDetails?,
): GridColumns => {
  const columns = [
    {
      ...materialUiXGrid.GRID_CHECKBOX_SELECTION_COL_DEF,
      headerCheckboxSelection: false,
      disableClickEventBubbling: true,
      renderHeader: () => null,
      renderCell: (params: GridRenderCellParams) => {
        const api = materialUiXGrid.useGridApiContext();
        const onClick = e => {
          const selectedRows = {
            ...(selectedRowDetails || {}),
            [`${params.id}`]: !selectedRowDetails?.[`${params.id}`],
          };
          selectedRowDetails = selectedRows;
          const filteredData = Object.entries(selectedRowDetails).reduce((acc, [key, value]) => {
            if (value === true) {
              acc[key] = value;
            }
            return acc;
          }, {});
          if (setSelectedRowDetails) setSelectedRowDetails(filteredData);
          const selectionModel = Object.keys(selectedRowDetails)
            .filter(key => selectedRowDetails[key] === true)
            .map(Number);
          api.current.setSelectionModel(selectionModel);
          e.stopPropagation();
        };
        return <Checkbox checked={selectedRowDetails?.[`${params.id}`]} onClick={onClick} />;
      },
    },
    {
      ...defaultTaskWordDeskColumn,
      field: 'credentialName',
      headerName: t('order.license.licenseName'),
      flex: 1,
      hide: false,
      sortable: true,
    },
    {
      ...defaultTaskWordDeskColumn,
      field: 'compactStatus',
      headerName: t('order.license.compactStatus'),
      width: 180,
      hide: false,
      sortable: false,
      renderCell: params => (
        <UnitCompactStatusRenderCell
          param={params}
          groupIndex={groupIndex}
          skillSetIndex={skillSetIndex}
        />
      ),
    },
    {
      ...defaultTaskWordDeskColumn,
      headerName: t('order.license.state'),
      field: 'state',
      width: 150,
      hide: false,
      sortable: false,
      renderCell: params => (
        <UnitStateRenderCell param={params} groupIndex={groupIndex} skillSetIndex={skillSetIndex} />
      ),
      valueFormatter: params => {
        const data = params.api?.getRow(params.id);
        if (data) {
          return data.licenseStateDetails?.map(detail => detail?.state?.name).join(', ');
        }
        return missingField;
      },
    },
    {
      ...defaultTaskWordDeskColumn,
      headerName: 'Requirements',
      field: 'setting',
      flex: 1,
      hide: false,
      sortable: false,
      renderCell: params => (
        <UnitLicenseRequirementRenderCell param={params} groupIndex={groupIndex} skillSetIndex={skillSetIndex} />
      ),
    },
  ];

  return columns;
};
