import { Avatar, Box, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Tooltip } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import MailBoxIcon from 'app/assets/images/MailBox.svg';
import PhoneIcon from 'app/assets/images/Phone.svg';
import { Name2 } from 'app/models/Candidate/CandidateProfile';
import { useTranslation } from 'react-i18next';
import EmailDisabled from 'app/assets/images/CandidateHeader/email-Disabled.svg';
import PhoneDisabled from 'app/assets/images/CandidateHeader/PhoneDisabled.svg';
import { useEnableEmailCommunication, useEnableVoiceCommunication } from 'oidc/CommunicationEnabler';
import { useHeaderOptions } from '@AMIEWEB/Common/Buttons/UseHeaderOptions';
import { searchNotificationData } from '@AMIEWEB/GlobalSearch/helper';
import { ChannelType, SendType, UseType } from '@AMIEWEB/Notification/Constants';
import { EmailDefaultValues } from 'app/models/Email/IEmail';
import { IEmployee, IToken } from 'app/models/Notification/Notification';
import _ from 'lodash';
import { selectUser } from 'oidc/user.selectors';
import { useSelector, useDispatch } from 'react-redux';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { Concatenate } from 'utils/string/string';
import { getDefaultSender } from '@AMIEWEB/Candidate/CandidateProfile/CandidateHeader/NotificationHelper';

const useStyles = makeStyles()(theme => ({
  drawer: {
    flexShrink: 0,
    '& .MuiPaper-root': {
      top: '45px',
      width: '420px',
    },
    '& .MuiListItem-gutters': {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    '& .MuiListItemAvatar-root': {
      minWidth: '0px',
    },
  },
  userAvatar: {
    width: '40px',
    height: '40px',
    borderRadius: '80px',
    opacity: 1,
    color: 'white',
    backgroundColor: '#52429A',
  },
  root: {
    padding: '0px 10px',
    width: '100%',
    backgroundColor: '#fff',
  },
  userAvatarPad: {
    paddingRight: '8px',
  },
  actionIconPad: {
    paddingLeft: '8px',
  },
  primaryText: {
    fontSize: '16px',
    color: '#003E68',
  },
  secondaryText: {
    fontSize: '12px',
    color: '#333333',
  },
  tooltip: {
    display: 'grid',
  },
  tooltipArrow: {
    color: '#fff',
    '&::before': {
      border: '1px solid #555555',
    },
  },
  tooltipBackground: {
    backgroundColor: '#fff',
    color: '#555555',
    border: '1px solid #555555',
    fontSize: '12px',
  },
}));

interface IOrderTab {
  contactDetails: any[];
}

export const OrderTab = (props: IOrderTab) => {
  const { classes } = useStyles();

  const { t } = useTranslation();
  const { contactDetails } = props;

  const enableEmailCommunication = useEnableEmailCommunication();
  const enableVoiceCommunication = useEnableVoiceCommunication();

  const displayNullData = data => {
    if (data.firstName === undefined && data.lastName === undefined) {
      return true;
    } else return false;
  };
  const genInitials = (name: Name2) => {
    return `${name?.firstName?.substring(0, 1) || ''}${name?.lastName?.substring(0, 1) || 'UNK'}`;
  };

  const ContactList = props => {
    const { userInfo } = useSelector(selectUser);
    const notificationData = useSelector(notificationSelection);
    const dispatch = useDispatch();
    const { getEmailSubject } = useHeaderOptions();
    const handleCommunication = (args: { channelType: ChannelType; contactData: any }) => {
      const notificationUser = searchNotificationData({ userInfo });
      const { contactData } = args;
      const tos = {
        designation: contactData?.title,
        contactId: contactData?.contactId,
        phoneNumber: contactData?.phoneNumber,
        email: contactData?.email,
        name: contactData?.name?.firstName + ' ' + contactData?.name?.lastName,
      };
      const associatedRecords = [
        {
          name: 'partnerDesignation',
          value: contactData?.title,
        },
        {
          name: 'userId',
          value: contactData?.contactId,
        },
      ];
      const sender: IEmployee = getDefaultSender({ userInfo });
      if (args.channelType === ChannelType.voice) {
        const VoiceData = {
          manualLog: false,
          channel: args.channelType,
          associatedRecords: associatedRecords,
          sender: sender,
          sendType: SendType.one_to_one,
          tos: [tos],
          useType: UseType.Placement,
          userRole: Concatenate(userInfo?.roles || [], ','),
          isInternal: true,
        };
        if (!notificationData.voice.open && notificationData.voice.minimized) {
          dispatch(
            notificationDataActions.setVoiceInteraction({
              open: true,
              minimized: false,
              showDisposition: true,
            }),
          );
          if (notificationData.voice.data.tos.find(Boolean).contactId !== args.contactData?.id) {
            dispatch(notificationDataActions.setSnackBarData({ channel: 'voice', manual: false, changeWarning: true }));
          }
        } else {
          dispatch(
            notificationDataActions.setVoiceData({
              data: VoiceData,
              open: true,
              minimized: false,
            }),
          );
        }
      } else {
        const currentUrl = window.location.href?.split('?')[0];
        const body = `<p>&nbsp;</p><p>&nbsp;</p><p>Link To Profile : <a href=${currentUrl}>${currentUrl}</a><br><br>{{signature}}</p>`;
        const substitutions: Array<IToken> = [
          {
            name: 'Signature',
            input: '{{signature}}',
          },
        ];
        if (!notificationData.email.open && notificationData.email.minimized) {
          dispatch(
            notificationDataActions.setEmailInteraction({
              open: true,
              minimized: false,
            }),
          );
          if (!_.isEqual(notificationData.email.data!.tos, [])) {
            dispatch(notificationDataActions.setSnackBarData({ channel: 'email', manual: false, changeWarning: true }));
          }
        } else {
          dispatch(
            notificationDataActions.setEmailData({
              data: {
                ...EmailDefaultValues(),
                ...notificationUser,
                substitutions: substitutions,
                useType: UseType.Placement,
                emailType: SendType.one_to_one,
                associatedRecords: associatedRecords,
                body: body,
                isInternal: true,
                subject: getEmailSubject(),
                tos: [tos],
              },
              open: true,
              minimized: false,
            }),
          );
        }
      }
    };
    return (
      <>
        <Grid item className={classes.root}>
          <Grid item className={`${classes.drawer} `}>
            <List>
              <ListItem disableGutters>
                {!displayNullData(props.name) ? (
                  <>
                    <ListItemAvatar className={classes.userAvatarPad}>
                      <Avatar data-avatar className={classes.userAvatar}>
                        {genInitials(props.name)}
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      primary={`${props?.name?.firstName || ''} ${props?.name?.middleName || ''} ${
                        props?.name?.lastName || ''
                      }`}
                      secondary={props.title}
                      classes={{ primary: classes.primaryText, secondary: classes.secondaryText }}
                    />

                    <ListItemAvatar className={classes.actionIconPad}>
                      {enableEmailCommunication && !!props?.email && props?.email !== '' ? (
                        <Tooltip
                          title={
                            <span className={classes.tooltip}>
                              <span>{props?.email || ''}</span>{' '}
                            </span>
                          }
                          disableHoverListener={!props?.email}
                          arrow
                          placement="bottom-end"
                          classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
                        >
                          <img
                            src={MailBoxIcon}
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              handleCommunication({ channelType: ChannelType.email, contactData: { ...props } })
                            }
                          />
                        </Tooltip>
                      ) : (
                        <img src={EmailDisabled} />
                      )}
                    </ListItemAvatar>
                    <ListItemAvatar className={classes.actionIconPad}>
                      {enableVoiceCommunication && !!props?.phoneNumber && props?.phoneNumber !== '' ? (
                        <Tooltip
                          title={
                            <span className={classes.tooltip}>
                              <span>
                                {props.countryDialingCode || '+1 '}
                                {props?.phoneNumber || ''}
                              </span>{' '}
                            </span>
                          }
                          disableHoverListener={!props?.phoneNumber}
                          arrow
                          placement="bottom-end"
                          classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
                        >
                          <img
                            src={PhoneIcon}
                            style={{ cursor: 'pointer' }}
                            alt="Phone"
                            onClick={() =>
                              handleCommunication({ channelType: ChannelType.voice, contactData: { ...props } })
                            }
                          />
                        </Tooltip>
                      ) : (
                        <img src={PhoneDisabled} alt="Phone disabled" />
                      )}
                    </ListItemAvatar>
                  </>
                ) : (
                  <b>{t('candidate.myPlacements.placementContactsDrawer.NA')}</b>
                )}
              </ListItem>
            </List>
          </Grid>
          <Grid item>{props.index < 4 ? <Divider /> : ''}</Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Grid container classes={{ container: classes.root }}>
        {contactDetails?.map((data, index) => {
          return (
            <ContactList
              title={data.contactName}
              name={data.name}
              index={index}
              email={data.email}
              phoneNumber={data.phoneNumber}
              contactId={data.contactId}
            />
          );
        })}
      </Grid>

      <Box className={classes.root}></Box>
    </>
  );
};
