import React, { useEffect } from 'react';
import 'date-fns';
import { Grid, Theme, DatePicker, TextField, LocalizationProvider } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { Currency } from './CustomComponents/Currency';
import { useFormContext, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { selectMiscellaneousFields } from './PreferenceForm/Preference.selectors';
import { preferenceActions } from './PreferenceForm/Preference.redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
    marginLeft: 0,
  },
  currencyGrid: {
    paddingLeft: '0 !important',
  },
}));

export const MiscellaneousFields = props => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const fields = useSelector(selectMiscellaneousFields);
  const { control, watch } = useFormContext();
  const weeklyPayRateValue = watch('weeklyPayRate');
  const avStartDateValue = watch('avStartDate');

  useEffect(() => {
    props.onValueChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weeklyPayRateValue, avStartDateValue]);

  useEffect(() => {
    props.setFieldValue('weeklyPayRate', fields.weeklyPayRate);
    props.setFieldValue('avStartDate', fields.avStartDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  const updateReduxState = ({ weeklyPayRate, avStartDate }) => {
    dispatch(preferenceActions.setMiscellaneousFields({ ...fields, weeklyPayRate, avStartDate }));
  };

  return (
    <Grid container alignItems="center" classes={{ root: classes.root }} spacing={2} data-testid="container">
      <Grid item classes={{ root: classes.currencyGrid }}>
        <Controller
          name="weeklyPayRate"
          control={control}
          render={({ ref, onBlur, ...rest }) => (
            <Currency
              disabled={fields.isPayRateReadOnly}
              label={t('candidate.preferenceForm.currencyFieldLabel')}
              onBlur={val => {
                onBlur();
                updateReduxState({ weeklyPayRate: val, avStartDate: fields.avStartDate });
              }}
              {...rest}
            />
          )}
        />
      </Grid>
      <Grid item>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            name="avStartDate"
            control={control}
            render={({ ref, value, onBlur, ...rest }) => (
              <DatePicker
                inputFormat="MM/dd/yyyy"
                // the below assignment avoids loading field with current date
                value={value || fields.avStartDate}
                label={t('candidate.preferenceForm.startDateLabel')}
                disabled={fields.isStartDateReadOnly}
                renderInput={p => (
                  <TextField
                    {...p}
                    variant="filled"
                    onBlur={event => {
                      onBlur();
                      const newDate = new Date(event.target.value);
                      updateReduxState({
                        weeklyPayRate: fields.weeklyPayRate,
                        avStartDate: event.target.value ? newDate.toISOString() : null,
                      });
                    }}
                  />
                )}
                onAccept={val => {
                  if (val) updateReduxState({ weeklyPayRate: fields.weeklyPayRate, avStartDate: val.toISOString() });
                }}
                {...rest}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};
