import React from 'react';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { makeStyles } from 'tss-react/mui';
import WorkInProgress from 'app/assets/images/Common/WorkInProgress.png';

const useStyles = makeStyles()({
  root: {
    width: 'calc(100% + 36px)',
  },
  workInProgressContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 0 10px 20px',
    'justify-content': 'center',
    'align-items': 'center',
  },
  workInProgressImage: {
    width: '20%',
    height: '20%',
    padding: '0.5rem 2rem',
  },
});

export const WorkInProgressComponent = () => {
  const { classes } = useStyles();

  return (
    <LayoutGrid classes={{ root: classes.root }}>
      <LayoutGridItem sm={12} xs={12} md={12} lg={12}>
        <div className={classes.workInProgressContainer}>
          <img
            src={WorkInProgress}
            className={classes.workInProgressImage}
            alt={`WorkInProgressImage`}
            data-testid="workInProgressImage"
          />
        </div>
      </LayoutGridItem>
    </LayoutGrid>
  );
};
