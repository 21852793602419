/* eslint-disable @typescript-eslint/naming-convention */
import AccountManagementPlacementData from './AccountManagementPlacementData.json';
import PlacementHeader from './PlacementDetails.json';
import GlanceData from './PlacementDetailGlanceData.json';
import KeyContacts from './PlacementKeyContacts.json';
import OrderComparsions from './Placements_comparisons_order.json';
import CandidateLicenses from './CandidateLicenses.json';
import CandidateCertificates from './CandidateCertifications.json';
import CandidateEducation from './CandidateEducation.json';
import CandidateShifts from './CandidateShifts.json';
import CandidateExperience from './CandndiateExperience.json';
import PlacementModalPopData from './CandidatePlacementModalDetails.json';
import CandidatePlacementCount from './CandidatePlacementCount.json';
import StrikeTransportationData from './StrikeTransportationData.json';
import Ref_EmVerif_Records from './ReferencesAndEmploymentVerificationRecords.json';
import SamplePdfDoc from './SamplePdfDoc.json';

export async function GetAccountManagementPlacementInfo() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: AccountManagementPlacementData,
      });
    }, 1000);
  });
}

export async function GetPlacementDetailsHeaderData() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: PlacementHeader,
      });
    }, 1000);
  });
}

export async function GetQuickGlanceData() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: GlanceData,
      });
    }, 5000);
  });
}

export async function GetPlacementKeyContactsData() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: KeyContacts,
      });
    }, 5000);
  });
}

export async function GetCandidatePlacementCount() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: CandidatePlacementCount,
      });
    }, 1000);
  });
}

export async function GetOrderComparisonData() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: OrderComparsions,
      });
    }, 1000);
  });
}

export async function GetCandidateLiceneseData() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: CandidateLicenses,
      });
    }, 1000);
  });
}

export async function GetCandidateCertData() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: CandidateCertificates,
      });
    }, 1000);
  });
}

export async function GetCandidateEducationData() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: CandidateEducation,
      });
    }, 1000);
  });
}

export async function GetCandidateExperienceData() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: CandidateExperience,
      });
    }, 1000);
  });
}

export async function GetCandidateRequestedShiftsData() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: CandidateShifts,
      });
    }, 1000);
  });
}

export async function getCandidateTimeOffRequestsData() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: [
          {
            startDate: '08/11/2021 00:00:00',
            endDate: '09/06/2021 00:00:00',
          },
          {
            startDate: '09/12/2021 00:00:00',
            endDate: '09/23/2021 00:00:00',
          },
        ],
      });
    }, 1000);
  });
}

export async function GetCandidateModalPlacementsData() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: PlacementModalPopData,
      });
    }, 1000);
  });
}

/** Mock service for PlacementSummary */
export async function GetPlacementSummarySkillSet_OrderReq() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: {
          orderRequirement: {
            type: 1,
            data: [
              {
                verifiedByCM: true,
                disciplineId: 1,
                disciplineAbbr: 'RN',
                specialties: [
                  { specialtyId: 4, specialtyAbbr: 'PICU', subSpecialties: [] },
                  { specialtyId: 18, specialtyAbbr: 'CVICU', subSpecialties: [] },
                ],
              },
            ],
          },
          candidateRequirement: {
            type: null,
            data: [
              {
                verifiedByCM: true,
                disciplineId: 1,
                disciplineAbbr: 'RN',
                specialties: [
                  { specialtyId: 4, specialtyAbbr: 'PICU', subSpecialties: [] },
                  { specialtyId: 18, specialtyAbbr: 'CVICU', subSpecialties: [] },
                ],
              },
            ],
          },
          nextStatusRequirement: [{ activityTypeId: 1, activityStatusId: 2 }],
        },
      });
    }, 1000);
  });
}

export async function GetPlacementSummaryStatus_OrderReq() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: {
          orderRequirement: {
            type: null,
            data: 'No',
          },
          candidateRequirement: {
            type: null,
            data: null,
          },
        },
      });
    }, 1000);
  });
}

export async function GetPlacementSummaryDates_OrderReq() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: {
          orderRequirement: {
            type: null,
            data: {
              startDate: '2021-08-23T00:00:00',
              endDate: '2021-11-20T00:00:00',
              numOfWeeks: 13,
            },
          },
          candidateRequirement: {
            type: null,
            data: {
              availabilityDate: '2021-11-22T00:00:00',
            },
          },
          nextStatusRequirement: [
            {
              activityTypeId: 1,
              activityStatusId: 2,
            },
          ],
        },
      });
    }, 1000);
  });
}

export async function GetPlacementSummarySchedule_OrderReq() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: {
          amnRequirement: { type: null, data: null },
          orderRequirement: {
            type: 1,
            data: {
              shifts: [{ id: 2, description: '12 N' }],
              float: {
                isRequired: true,
                floatToUnit: 'test unit shall display overflow tooltip on hover',
                floatToLocation: 'test location',
                floatToMiles: 1,
              },
              flexibleSchedule: { isRequired: false, workDuringWeekend: 1, workDuringHoliday: 1 },
            },
          },
          candidateRequirement: { type: null, data: { requestedShift: '12 N' } },
          nextStatusRequirement: [{ activityTypeId: 1, activityStatusId: 2 }],
        },
      });
    }, 1000);
  });
}

export async function GetPlacementSummaryExperience_OrderReq() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: {
          amnRequirement: { type: null, data: null },
          orderRequirement: {
            type: 1,
            data: {
              yearsOfExperience: 10,
              emr: { isRequired: true, values: ['test1', 'test2', 'test3'] },
            },
          },
          candidateRequirement: {
            type: null,
            data: {
              totalYearsOfExp: 10,
              totalMonthsOfExp: 10,
              candidateYearsOfExpBySkillsetInfo: [
                { disciplineAbbr: 'RN', specialtyAbbr: 'MS', yearsOfExperience: 10, monthsOfExperience: 10 },
              ],
            },
          },
          nextStatusRequirement: [],
        },
      });
    }, 1000);
  });
}

export async function GetPlacementSummaryReference_OrderReq() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: {
          amnRequirement: { type: null, data: null },
          orderRequirement: {
            type: null,
            data: { referencesToRfo: { orderType: 3, order: 10, facilityType: 3, facility: 10 } },
          },
          candidateRequirement: {
            type: null,
            data: [
              {
                referenceIcon: 1,
                facilityName: 'Texas Health Presbyterian Hospital - Rockwall ',
                completedDate: '2022-02-17T00:00:00',
                evaluatorTitle: 'Charge Nurse',
                message: null,
              },
              {
                referenceIcon: 1,
                facilityName: 'Texas Health Presbyterian Hospital - Rockwall ',
                completedDate: '2022-02-14T00:00:00',
                evaluatorTitle: 'Charge RN',
                message: null,
              },
            ],
          },
          nextStatusRequirement: [{ activityTypeId: 1, activityStatusId: 2 }],
        },
      });
    }, 1000);
  });
}

export async function GetPlacementSummarySkillsChecklist_OrderReq() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: {
          amnRequirement: { type: null, data: null },
          candidateRequirement: {
            type: null,
            data: {
              placementSkillsChecklist: [
                {
                  checklistIcon: 2,
                  checklistName: 'SK-PICU',
                  completedDate: '2021-02-11T14:21:06',
                  message: 'Skills checklist matches order skillset and is more than 1 year old',
                },
              ],
            },
          },
          nextStatusRequirement: [],
        },
      });
    }, 1000);
  });
}

export async function GetPlacementSummaryLicense_OrderReq() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: {},
      });
    }, 1000);
  });
}

export async function GetPlacementSummaryCertification_OrderReq() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: {},
      });
    }, 1000);
  });
}

export async function GetPlacementSummaryMilesRadius_OrderReq() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: { orderRequirement: { type: 1, data: 1 }, candidateRequirement: { data: 1 } },
      });
    }, 1000);
  });
}

export async function GetPlacementSummaryInterview_OrderReq() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: {},
      });
    }, 1000);
  });
}

export async function GetPlacementSummaryCovid_OrderReq() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: {
          orderRequirement: {
            type: 2,
            data: { isVaccinationRequired: true, declinations: ['Medical', 'Personal', 'Religious'] },
          },
          candidateRequirement: { data: { vaccinationStatus: 'Unknown' } },
        },
      });
    }, 1000);
  });
}

export async function GetPlacementSummaryDOB_OrderReq() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: {},
      });
    }, 1000);
  });
}

export async function GetPlacementSummarySSN_OrderReq() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: {},
      });
    }, 1000);
  });
}

export async function GetPlacementSummaryDocuments_OrderReq() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: {},
      });
    }, 1000);
  });
}

export async function GetStrikeTransportationData() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: StrikeTransportationData,
      });
    }, 1000);
  });
}

export async function GetRef_EmVerif_Records() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: Ref_EmVerif_Records,
      });
    });
  });
}

export async function GetSampleDoc() {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status: 200,
        data: SamplePdfDoc.data,
      });
    }, 1000);
  });
}

export async function GetSampleResponse({ status, data = undefined, asyncTimeout = 2000 }) {
  return new Promise<any>(resolve => {
    setTimeout(() => {
      resolve({
        status,
        data,
      });
    }, asyncTimeout);
  });
}
