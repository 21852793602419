import React, { useEffect, useState } from 'react';
import { TextField, Chip, Grid, Theme, Autocomplete } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { DisableAppScroll, EnableAppScroll } from 'app/layout/Layout';

export interface IMultiTypeAheadOption {
  object: any;
  label: string;
  isReadOnly: boolean;
}

const useStyles: any = makeStyles()((theme: Theme) => ({
  selectGrid: {
    width: 300,
  },
  chip: {
    margin: '16px 16px 0 0',
  },
  underline: {
    // '&:hover': {
    //   '&:before': {
    //     borderBottom: '1px solid #009AD9',
    //   },
    // },
    // '&:before': {
    //   borderBottom: '1px solid #009AD9',
    // },
  },
  focused: {
    // backgroundColor: '#ffffff !important',
  },
}));

export const MultiSelectTypeAhead = props => {
  const { label, options, isError, helperText } = props;
  const { classes } = useStyles();
  const [chips, setChips] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const chipElements = props.value.map((option, index) => {
      if (option?.isReadOnly) return <Chip key={index} label={option.label} className={classes.chip} />;
      return (
        <Chip
          key={index}
          label={option.label}
          className={classes.chip}
          onDelete={() => {
            const data = props.value.filter(entry => entry !== option);
            props.onChange(data);
          }}
        />
      );
    });
    setChips(chipElements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  return (
    <Grid container direction="column">
      <Grid item>
        <Autocomplete
          multiple
          filterSelectedOptions
          disableClearable
          value={props.value}
          isOptionEqualToValue={(option, value) => option.object.ID === value.object.ID}
          options={options}
          getOptionLabel={(option: IMultiTypeAheadOption) => option.label}
          popupIcon={null}
          renderTags={() => null}
          renderInput={params => (
            <TextField
              error={isError}
              onFocus={props.onFocus}
              onBlur={props.onBlur}
              helperText={helperText}
              label={label}
              {...params}
              variant="filled"
              InputProps={{
                ...params.InputProps,
                classes: { underline: classes.underline, focused: classes.focused },
              }}
            />
          )}
          onChange={(e, data) => {
            props.onChange(data);
          }}
          className={classes.selectGrid}
          onOpen={() => options.length && DisableAppScroll()}
          onClose={EnableAppScroll}
        />
      </Grid>
      <Grid item>{chips}</Grid>
    </Grid>
  );
};
