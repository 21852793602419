import { Button, Grid, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import { missingField } from 'app/constants';
import { PlacementOrderType } from 'app/models/Placement/PlacementDetails';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { TableSection } from '../../../customComponents/TableSection';
import { getRequirementCheck } from '../../../helper';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import {
  selectAuditDetails,
  selectServiceFailures,
  selectSubmissionInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { RequirementsRow } from '../../TableComponents/TableComponents';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { AuditModal } from './AuditsGrid/AuditModal';
import { GridLinkOperator } from '@mui/x-data-grid-pro';
import { CustomTooltip } from 'app/components/Common/Tooltips';

const useStyles = makeStyles()({
  button: {
    cursor: 'pointer',
    color: '#006FB9',
    justifyContent: 'flex-start',
    marginLeft: '3px',
    fontWeight: 450,
    padding: '0px',
    margin: '0px !important',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
  tooltipContent: {
    fontWeight: 'normal',
    color: '#000',
  },
});

export const Audit = () => {
  const { t } = useTranslation();
  const {
    amnReq: {
      amn: { audit: amnReq },
      candidate: { audit: candidateReq },
    },
    requirementFilter,
  } = useSelector(selectSubmissionInfo);
  const placementOrderType = useSelector(selectPlacementOrderType);
  const { auditAmnRequirement: serviceFailed } = useSelector(selectServiceFailures);
  const { placementId, candidateId } = useContext(AddOnContext);

  const { promiseInProgress } = usePromiseTracker({ area: 'placement-submission-audit-amn-requirement', delay: 0 });

  const dispatch = useDispatch();
  const { display } = useRequirementFilter('submission-amn', requirementFilter, amnReq?.type);

  return display ? (
    <RequirementsRow
      id="placement-submission-amn-audit"
      title={t('placement.profile.summary.submission.amn.audit.title')}
      requirementCell={<RowKey audit={amnReq} t={t} />}
      candidateCell={<RowValue audit={candidateReq} t={t} {...{ placementOrderType, placementId, candidateId }} />}
      loading={promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() =>
        dispatch(placementSummaryActions.getAuditAmnRequirement({ placementId, isRetryingService: true }))
      }
    />
  ) : null;
};

const RowKey = ({ audit, t }) => (
  <TableSection
    items={[
      {
        icon: audit ? getRequirementCheck(audit.type) : null,
        primaryContent: t(audit?.message?.primary),
      },
    ]}
  />
);

const RowValue = ({ audit, t, placementOrderType, placementId, candidateId }) => {
  const { classes } = useStyles();
  const [openAuditModal, setOpenAuditModal] = React.useState<boolean>(false);
  const [filterModel, setFilterModel] = React.useState<any>([]);
  const auditDetails = useSelector(selectAuditDetails);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (openAuditModal)
      dispatch(
        placementSummaryActions.getAuditRecords({
          placementOrderType,
          candidateId,
        }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAuditModal]);

  return (
    <>
      <TableSection
        items={[
          {
            primaryContent: (
              <>
                <Grid container wrap="nowrap" spacing={1} alignItems="center">
                  <Grid item>{t('placement.profile.summary.submission.amn.audit.line1')}:</Grid>
                  <Grid item>
                    {audit && audit?.data ? (
                      audit.data?.holdFileOut > 0 ? (
                        <Button
                          type="button"
                          variant="text"
                          disableRipple
                          disableTouchRipple
                          disableFocusRipple
                          onClick={() => {
                            setOpenAuditModal(true);
                            setFilterModel(
                              placementOrderType === PlacementOrderType.StrikeOrderInHsg
                                ? {
                                    items: [
                                      {
                                        columnField: `strikeApproved`,
                                        operatorValue: `contains`,
                                        value: t('global.No'),
                                      },
                                      { columnField: `holdFileOut`, operatorValue: `contains`, value: t('global.Yes') },
                                    ],
                                    linkOperator: GridLinkOperator.And,
                                  }
                                : {
                                    items: [
                                      { columnField: `holdFileOut`, operatorValue: `contains`, value: t('global.Yes') },
                                    ],
                                  },
                            );
                          }}
                          classes={{
                            root: classes.button,
                          }}
                        >
                          {audit.data?.holdFileOut}
                        </Button>
                      ) : (
                        <span>{audit?.data?.holdFileOut ?? 0}</span>
                      )
                    ) : (
                      <>{missingField}</>
                    )}
                  </Grid>
                </Grid>
                {audit?.data?.message ? (
                  audit?.data?.approvedByName ? (
                    <Grid item>
                      <Typography variant="body2">
                        <CustomTooltip
                          tooltipContent={audit.data?.approvedByName}
                          standardMargin={true}
                          isInlineContent={true}
                        >
                          <span className={classes.tooltipContent}>{audit.data?.message}</span>
                        </CustomTooltip>
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item>
                      <Typography variant="body2">{audit.data?.message}</Typography>
                    </Grid>
                  )
                ) : (
                  <></>
                )}
              </>
            ),
          },
          {
            primaryContent: (
              <Grid container wrap="nowrap" spacing={1} alignItems="center">
                <Grid item>{t('placement.profile.summary.submission.amn.audit.line2')}:</Grid>
                <Grid item>
                  {audit && audit.data ? (
                    audit.data.ongoing > 0 ? (
                      <Button
                        type="button"
                        variant="text"
                        disableRipple
                        disableTouchRipple
                        disableFocusRipple
                        onClick={() => {
                          setOpenAuditModal(true);
                          setFilterModel({
                            items: [{ columnField: `status`, operatorValue: `contains`, value: `Ongoing` }],
                          });
                        }}
                        classes={{
                          root: classes.button,
                        }}
                      >
                        {audit.data.ongoing}
                      </Button>
                    ) : (
                      <span>{audit.data.ongoing ?? 0}</span>
                    )
                  ) : (
                    <>{missingField}</>
                  )}
                </Grid>
              </Grid>
            ),
          },
          {
            primaryContent: (
              <Grid container wrap="nowrap" spacing={1} alignItems="center">
                <Grid item>{t('placement.profile.summary.submission.amn.audit.line3')}:</Grid>
                <Grid item>
                  {audit && audit.data ? (
                    audit.data.allAudit > 0 ? (
                      <Button
                        type="button"
                        variant="text"
                        disableRipple
                        disableTouchRipple
                        disableFocusRipple
                        onClick={() => {
                          setOpenAuditModal(true);
                          setFilterModel({
                            items: [],
                          });
                        }}
                        classes={{ root: classes.button }}
                      >
                        {audit.data.allAudit ?? 0}
                      </Button>
                    ) : (
                      <span>{audit.data.allAudit ?? 0}</span>
                    )
                  ) : (
                    <>{missingField}</>
                  )}
                </Grid>
              </Grid>
            ),
          },
        ]}
      />
      <GenericDialog
        open={openAuditModal}
        draggable
        disableEnforceFocus
        onClose={() => setOpenAuditModal(false)}
        dialogTitleProps={{
          text: t('placement.profile.summary.submission.amn.audit.title'),
          expandable: true,
          closeButton: true,
        }}
        fullWidth
        maxWidth={'md'}
        variant="blue"
      >
        <AuditModal
          auditDetails={auditDetails}
          forceClose={() => setOpenAuditModal(false)}
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
          refreshData={() =>
            dispatch(
              placementSummaryActions.getAuditRecords({
                placementOrderType,
                candidateId,
              }),
            )
          }
          serviceTrackerId={'placement-submission-audit-records'}
        />
      </GenericDialog>
    </>
  );
};
