import { missingField } from 'app/constants';
import { convertToFormat } from 'app/helpers/dateHelper';
import React from 'react';
import styled from 'styled-components';

const formatDate = date => convertToFormat(date, 'MM/DD/yyyy');

export const TimeOffItem = ({ timeOff }) => {
  const dateRenderer = React.useMemo(() => {
    return timeOff.length ? (
      <>
        {timeOff.map(({ startDate, endDate }, key) => (
          <RowDataDiv key={key}>
            {endDate === null ? formatDate(startDate) : `${formatDate(startDate)} - ${formatDate(endDate)}`}
          </RowDataDiv>
        ))}
      </>
    ) : (
      missingField
    );
  }, [timeOff]);

  return <>{dateRenderer}</>;
};

const RowDataDiv = styled.div`
  font-size: 14px;
  white-space: pre-wrap;
`;
