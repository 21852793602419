import { InlineEditableField } from 'app/components/Common/EditPage/InlineEditableField';
import { CustomTextField } from 'app/components/Placement/PlacementDetails/Edit/CustomComponents/CustomTextField';
import { selectPlacementStatus } from 'store/redux-store/placement-status/selectors';
import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CreatePlacementContext } from '../../CreatePlacementWrapper';
import { createPlacementTabIDs } from '../../helper';
import { isWFC, isInitialStatus } from '../../../NextPlacementStatus/StatusDefaults';
import { selectNewPlacementChoice } from '../../../../../../store/redux-store/new-placement/selectors';

export const PlacementSellingPoints = ({ ...props }) => {
  const { control } = useFormContext();

  const { setCurrentFocusId } = useContext(CreatePlacementContext);

  const { candidate } = useSelector(selectNewPlacementChoice);
  const { currentPlacementStatus } = useSelector(selectPlacementStatus);

  return (
    <Controller
      control={control}
      name="sellingPoints"
      render={({ ref, onChange, value, ...rest }) => (
        <InlineEditableField
          id={createPlacementTabIDs.createPlacementSellingPoints}
          {...rest}
          alignStart
          label={value}
          inputProps={{ value, minWidth: 80, multiline: true, renderedFromInlineEditor: true }}
          Control={CustomTextField}
          onChange={newValue => {
            onChange(newValue);
          }}
          setCurrentFocus={() => setCurrentFocusId(createPlacementTabIDs.createPlacementSellingPoints)}
          disabled={!candidate || !(isWFC(currentPlacementStatus) || isInitialStatus(currentPlacementStatus))}
        />
      )}
    />
  );
};
