import { getUserInfo } from 'app/services/SharedServices/SharedServices';
import { IUser, IUserInfo } from './user.redux';
import { IRoles, userRoleList, userRoles } from './userRoles';
import { LogLevel } from '@microsoft/signalr';

export const fetchUserInfo = (): any => {
  const promise = new Promise((resolve, reject) => {
    getUserInfo()
      .then(res => {
        if (!!res) resolve(res);
        else reject('server error');
      })
      .catch(err => reject(err));
  });
  return promise;
};

export const Authorized = (availableRoles: (keyof IRoles)[], userInfo?: IUserInfo) => {
  const authorized =
    userInfo?.roles?.some(r => userRoleList.includes(r)) &&
    (availableRoles.includes(userRoles.all) || availableRoles.some(r => userInfo?.roles?.includes(r)));
  return authorized ?? false;
};

export const getOverrideUserID = () => {
  try {
    if (globalThis?.app?.env?.REACT_APP_ENV === 'DEV' || globalThis?.app?.env?.REACT_APP_ENV === 'QA') {
      const queryParams = new URLSearchParams(window.location.search);
      let employeeID: any = queryParams.get('employeeID') ?? queryParams.get('employeeid');
      if (employeeID !== null && employeeID !== undefined) {
        if (employeeID === '' || employeeID === 'null') {
          sessionStorage.removeItem('employeeID');
          employeeID = undefined;
        } else {
          employeeID = Number(employeeID);
          sessionStorage.setItem('employeeID', employeeID);
        }
      } else {
        const found = sessionStorage.getItem('employeeID');
        if (found) employeeID = Number(found);
        else employeeID = undefined;
      }
      return employeeID;
    } else return undefined;
  } catch (error) {
    return undefined;
  }
};

export const getOverrideUserRoles = () => {
  try {
    if (globalThis?.app?.env?.REACT_APP_ENV === 'DEV' || globalThis?.app?.env?.REACT_APP_ENV === 'QA') {
      const queryParams = new URLSearchParams(window.location.search);
      let roles: any = queryParams.get('userRoles');
      if (roles !== null && roles !== undefined) {
        if (roles === '' || roles === 'null') {
          sessionStorage.removeItem('userRoles');
          roles = undefined;
        } else {
          sessionStorage.setItem('userRoles', roles);
          roles = roles.split(',');
        }
      } else {
        const found = sessionStorage.getItem('userRoles');
        if (found) roles = found.split(',');
        else roles = undefined;
      }
      return roles;
    } else return undefined;
  } catch (error) {
    return undefined;
  }
};

export const getOverrideNetwork = () => {
  try {
    if (globalThis?.app?.env?.REACT_APP_ENV === 'DEV' || globalThis?.app?.env?.REACT_APP_ENV === 'QA') {
      const queryParams = new URLSearchParams(window.location.search);
      let networkOverride: any = queryParams.get('networkOverride') ?? queryParams.get('networkOverride');
      if (networkOverride !== null && networkOverride !== undefined) {
        if (networkOverride === '' || networkOverride === 'null') {
          sessionStorage.removeItem('networkOverride');
          networkOverride = undefined;
        } else {
          networkOverride = networkOverride === 'true';
          sessionStorage.setItem('networkOverride', networkOverride);
        }
      } else {
        const found = sessionStorage.getItem('networkOverride');
        if (found) networkOverride = found === 'true';
        else networkOverride = undefined;
      }
      return networkOverride;
    } else return undefined;
  } catch (error) {
    return undefined;
  }
};

export const removeOverridenUserID = () => {
  sessionStorage.removeItem('userID');
};

const getNameSegments = (name: string | undefined) => {
  return name?.split(' ') || [];
};

export const getFirstName = (name: string | undefined) => {
  const nameList = getNameSegments(name);
  if ((nameList.length || 0) > 0) return nameList[0];
  return '';
};

export const getLastName = (name: string | undefined) => {
  const nameList = getNameSegments(name);
  if ((nameList.length || 0) > 1) return nameList[nameList.length - 1];
  return '';
};

export const userInitials = (userInfo: IUser) => {
  if (userInfo?.userInfo !== null && userInfo?.userInfo !== undefined) {
    return `${userInfo.userInfo?.firstName?.substring(0, 1) || ''}${
      userInfo.userInfo?.lastName?.substring(0, 1) || 'UNK'
    }`;
  } else {
    return null;
  }
};

export const getOverrideSignalRLogLevel = () => {
  try {
    if (globalThis?.app?.env?.REACT_APP_ENV === 'DEV' || globalThis?.app?.env?.REACT_APP_ENV === 'QA') {
      const queryParams = new URLSearchParams(window.location.search);
      let logLevel: string = queryParams.get('signalRLogLevel') ?? queryParams.get('signalrloglevel');
      if (logLevel !== null && logLevel !== undefined) {
        if (logLevel === '' || logLevel === 'null') {
          sessionStorage.removeItem('signalRLogLevel');
          logLevel = undefined;
        } else {
          logLevel = String(logLevel);
          sessionStorage.setItem('signalRLogLevel', logLevel);
        }
      } else {
        const found = sessionStorage.getItem('signalRLogLevel');
        if (found) logLevel = String(found);
        else logLevel = undefined;
      }
      return logLevel;
    } else {
      return undefined;
    }
  } catch (error) {
    return undefined;
  }
};
