/* eslint-disable @typescript-eslint/naming-convention */
/**Add the business logic related actions here */

import { IUserRoles } from '@AMIEWEB/Settings/AutoTaskManagement/componenets/UserRolesSection/UserRolesSection';
import { createAction } from '@reduxjs/toolkit';
import { IRefreshActionType, ISaveTypeOnPageChange } from 'app/models/AutoTaskManagement/AutoTaskManagement';

const business_setSelectedTaskIdAndRow = createAction<number>('BUSINESS_SET_SELECTED_TASK_AND_ROW');
const business_setAssignedUserRoles = createAction<IUserRoles[]>('BUSINESS_SET_ASSIGNED_USER_ROLES');
const business_handleAutoTaskConfirmDialogAction = createAction<string>('BUSINESS_HANDLE_AUTO_TASK_CONFIRM_DIALOG_ACTION');
const business_handleAutoTaskStatusChange = createAction<string>('BUSINESS_HANDLE_AUTO_TASK_STATUS_CHANGE');
const business_handleRefreshGrid = createAction<IRefreshActionType>('BUSINESS_HANDLE_REFRESH_GRID');
const business_handleApplyFilters = createAction<any>('BUSINESS_HANDLE_APPLY_FILTERS');
const business_handleSaveActionFromDailog = createAction<any>('BUSINESS_HANDLE_SAVE_ACTION_FROM_DIALOG');
const business_initiateAutoTaskManagementPage = createAction('BUSINESS_INITIATE_AUTO_TASK_PAGE');
const business_handleSaveOnPageChange = createAction<ISaveTypeOnPageChange>('BUSINESS_HANDLE_SAVE_ON_PAGE_CHANGE');
export const businessActions = {
  business_setSelectedTaskIdAndRow,
  business_setAssignedUserRoles,
  business_handleAutoTaskConfirmDialogAction,
  business_handleAutoTaskStatusChange,
  business_handleRefreshGrid,
  business_handleApplyFilters,
  business_handleSaveActionFromDailog,
  business_initiateAutoTaskManagementPage,
  business_handleSaveOnPageChange
};
