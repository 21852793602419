import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomRangeDropdown from '../common/CustomRangeDropdown';
import { numberType } from '../common/CustomRangeDropdown';
import {
  ContractType,
  convertToAbbreviation,
  isDisciplineMatch,
  isNewRateElementRequired,
  isRangeValue,
  RateElemenetSource,
  RateElements,
  refactorInputValue,
} from '../helper';
import _ from 'lodash';
import { Status } from 'app/enums/Facility';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { selectUser } from 'oidc/user.selectors';
import moment from 'moment';
import {
  selectBillRateSelections,
  selectCreateOrderMiscellaneousData,
  selectOcbrDetails,
  selectOcbrSavedDetails,
  selectRateFeatureProps,
} from 'store/redux-store/create-order-ocbr/selector';
import { createOrderOcbrActions } from 'store/redux-store/create-order-ocbr/slice';
import { missingField } from 'app/constants';

const useStyles = makeStyles()(theme => ({
  containerStyle: {
    padding: '6px 0px',
  },
  itemStyle: {
    padding: '6px',
  },
  headerTextStyle: {
    fontWeight: 500,
  },
}));
export const MileageReimbursement = ({ label, name, shiftRates = [] }) => {
  const { billRates: ocbrBillRates, effectiveDates, isFlatRate } = useSelector(selectOcbrDetails);
  const { billRates: ocbrSavedBillRates } = useSelector(selectOcbrSavedDetails);
  const { disciplineOpts } = useSelector(selectCreateOrderMiscellaneousData);

  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const { skillsets } = useSelector(selectRateFeatureProps);

  const rateSelections = useSelector(selectBillRateSelections);

  const { value, ocbr } = React.useMemo(() => {
    const selection = rateSelections[name];
    return !selection ? { value: null, ocbr: ContractType.OnContract } : selection;
  }, [rateSelections]);

  const handleChange = newValue =>
    dispatch(
      createOrderOcbrActions.setBillRateSelection({
        [name]: {
          value: newValue,
          ocbr: newValue?.statusId
            ? newValue.statusId
            : isRangeValue(newValue)
            ? ContractType.OffContract
            : newValue?.source === RateElemenetSource.Contract
            ? ContractType.OnContract
            : ContractType.OnContract,
        },
      }),
    );
  // useEffect(() => {
  //   handleChange(shiftRates[0]?.value && shiftRates[0]?.source === RateElemenetSource.Contract ? shiftRates[0] : null);
  // }, [shiftRates]);

  /**
   * Get available skillsets
   */
  const disciplineArray = convertToAbbreviation(skillsets, disciplineOpts);

  const checkIsBillRateOcbr = val => {
    if (isRangeValue(val)) return true;
    else if (val?.source === RateElemenetSource.Facility) return true;
    else return false;
  };

  /**
   * Updates the Redux with new  value when user enters a billrate
   * @param newVal the values from input field
   */
  const updateReduxValue = val => {
    const isOcbr = checkIsBillRateOcbr(val);
    if (val !== undefined && ocbrBillRates) {
      const billRates = getBillRateData(val, ocbrBillRates, isOcbr);
      if (!_.isEqual(billRates, ocbrBillRates)) {
        dispatch(createOrderOcbrActions.setOcbrSavedBillRates(billRates));
        dispatch(createOrderOcbrActions.business_updateBillRates({ billRates }));
        dispatch(createOrderOcbrActions.business_deriveRateSelections({ isFlatRate, billRates }));
      }
    } else if (val !== undefined && !ocbrBillRates) {
      const billRates = getBillRateData(val, [], isOcbr);
      if (!_.isEqual(ocbrBillRates, billRates)) {
        dispatch(createOrderOcbrActions.setOcbrSavedBillRates(billRates));
        dispatch(createOrderOcbrActions.business_updateBillRates({ billRates }));
        dispatch(createOrderOcbrActions.business_deriveRateSelections({ isFlatRate, billRates }));
      }
    }
  };
  const updateSavedReduxValue = newVal => {
    const isOcbr = checkIsBillRateOcbr(newVal);
    if (newVal !== undefined && ocbrSavedBillRates) {
      const billRates = getSavedBillRateData(newVal, ocbrSavedBillRates, isOcbr);
      if (!_.isEqual(billRates, ocbrSavedBillRates)) {
        dispatch(createOrderOcbrActions.setOcbrSavedBillRates(billRates));
      }
    } else if (newVal !== undefined && !ocbrSavedBillRates) {
      const billRates = getSavedBillRateData(newVal, [], isOcbr);
      if (!_.isEqual(ocbrSavedBillRates, billRates)) {
        dispatch(createOrderOcbrActions.setOcbrSavedBillRates(billRates));
      }
    }
  };
  /**
   * @param newVal object which can be range or single value
   * @returns  updates array with new billrate
   */
  const getBillRateData = (val, billRatesArrOrg, isOcbr) => {
    //removed the existing value
    const billRatesArr = (billRatesArrOrg ?? []).reduce((resultArray, item) => {
      if (item.type === RateElements.MileageReimbursement) return resultArray;
      return [...resultArray, item];
    }, []);

    //when value is removed from field
    if (val === null) {
      return billRatesArr;
    }
    disciplineArray.forEach(element => {
      const orderEndDate = effectiveDates?.endDate ? moment(effectiveDates?.endDate).format() : null;
      const orderStartDate = moment(val.effectiveEndDate).add(1, 'days').format();
      const isExtraRateEleRequired = isNewRateElementRequired(effectiveDates?.endDate, val.effectiveEndDate);
      //check for discipline equality
      const disciplineMatch = isDisciplineMatch(element, val);
      const temp = {
        offContractId: null,
        disciplineId: element?.disciplineId,
        // rateElementId: uuid(),
        rateElementId: billRatesArr?.mileageRateElementId,
        specialtyId: element?.specialtyId,
        subSpecialtyId: element?.subSpecialtyId,
        shiftId: null,
        shift: null,
        type: RateElements.MileageReimbursement,
        billRate: refactorInputValue(val),
        isOcbr: isOcbr,
        statusId: isOcbr ? Status.Unsaved : null,
        createdAt: val?.createdAt,
        createdBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
        reason: missingField,
        effectiveStartDate:
          val?.source === RateElemenetSource.Facility ? val.effectiveStartDate : effectiveDates?.startDate,
        effectiveEndDate: val?.source === RateElemenetSource.Facility ? val.effectiveEndDate : effectiveDates?.endDate,
        lastUpdatedBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
        dateLastUpdated: moment().format(),
        dateCreated: moment().format(),
        source: isRangeValue(val) ? RateElemenetSource.Order : val?.source,
        documentIds: val?.documentIds,
      };
      //check if value is from facility
      if (val.source === RateElemenetSource.Facility) {
        Object.assign(temp, {
          offContractId: disciplineMatch && val.offContractId ? val.offContractId : null,
          rateElementId: disciplineMatch && val.rateElementId ? val.rateElementId : uuid(),
          statusId: disciplineMatch ? val.statusId : Status.Unsaved,
          createdAt: disciplineMatch ? val?.createdAt : null,
          source: disciplineMatch ? val.source : RateElemenetSource.Order,
          effectiveStartDate: disciplineMatch ? val.effectiveStartDate : effectiveDates?.startDate,
          effectiveEndDate: disciplineMatch ? val.effectiveEndDate : effectiveDates?.endDate,
          reason: disciplineMatch && val?.reason ? val?.reason : missingField,

          documentIds: disciplineMatch ? val.documentIds : [],
        });
      }
      billRatesArr.push(temp);
      if (isExtraRateEleRequired && disciplineMatch) {
        const additonalRateElement = {
          ...temp,
          offContractId: null,
          rateElementId: uuid(),
          statusId: Status.Unsaved,
          effectiveStartDate: orderStartDate,
          effectiveEndDate: orderEndDate,
          source: RateElemenetSource.Order,
          createdAt: null,
          documentIds: [],
          reason: missingField,
          isExtra: true,
        };
        billRatesArr.push(additonalRateElement);
      }
    });
    return billRatesArr || [];
  };

  const getSavedBillRateData = (val, billRatesArrOrg, isOcbr) => {
    //removed the existing value
    const billRatesArr = (billRatesArrOrg ?? []).reduce((resultArray, item) => {
      if (item.type === RateElements.MileageReimbursement) return resultArray;
      return [...resultArray, item];
    }, []);

    //when value is removed from field
    if (val === null) {
      return billRatesArr;
    }
    disciplineArray.forEach(element => {
      //check for discipline equality
      const disciplineMatch = isDisciplineMatch(element, val);
      const temp = {
        offContractId: null,
        disciplineId: element?.disciplineId,
        // rateElementId: uuid(),
        rateElementId: disciplineMatch && val.rateElementId ? val.rateElementId : uuid(),
        specialtyId: element?.specialtyId,
        subSpecialtyId: element?.subSpecialtyId,
        shiftId: null,
        shift: null,
        type: RateElements.MileageReimbursement,
        billRate: refactorInputValue(val),
        isOcbr: isOcbr,
        statusId: isOcbr ? Status.Unsaved : null,
        createdAt: val?.createdAt,
        createdBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
        reason: missingField,
        effectiveStartDate:
          val?.source === RateElemenetSource.Facility ? val.effectiveStartDate : effectiveDates?.startDate,
        effectiveEndDate: val?.source === RateElemenetSource.Facility ? val.effectiveEndDate : effectiveDates?.endDate,
        lastUpdatedBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
        dateLastUpdated: moment().format(),
        dateCreated: moment().format(),
        source: isRangeValue(val) ? RateElemenetSource.Order : val?.source,
        documentIds: val?.documentIds,
      };
      //check if value is from facility
      if (val.source === RateElemenetSource.Facility) {
        Object.assign(temp, {
          offContractId: disciplineMatch && val.offContractId ? val.offContractId : null,
          rateElementId: disciplineMatch && val.rateElementId ? val.rateElementId : uuid(),
          statusId: disciplineMatch ? val.statusId : Status.Unsaved,
          createdAt: disciplineMatch ? val?.createdAt : null,
          source: disciplineMatch ? val.source : RateElemenetSource.Order,
          effectiveStartDate: disciplineMatch ? val.effectiveStartDate : effectiveDates?.startDate,
          effectiveEndDate: disciplineMatch ? val.effectiveEndDate : effectiveDates?.endDate,
          reason: disciplineMatch && val?.reason ? val?.reason : missingField,

          documentIds: disciplineMatch ? val.documentIds : [],
        });
      }
      billRatesArr.push(temp);
    });
    return billRatesArr || [];
  };
  return (
    <>
      <CustomRangeDropdown
        value={value}
        options={shiftRates}
        onChange={newVal => {
          handleChange(newVal);
        }}
        onBlur={newVal => {
          updateReduxValue(newVal);
          updateSavedReduxValue(newVal);
        }}
        label={label}
        name={name}
        labelPrefix="$"
        labelPostfix={undefined}
        type={numberType.singleRate}
        isChanged={ocbr ?? ContractType.OnContract}
      />
    </>
  );
};
export const MileageReimbursementWrapper = ({ label, name, shiftRates = [] }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Grid container direction="row" xs={12} classes={{ container: classes.containerStyle }}>
        {
          <Grid container direction="column" xs={10}>
            <Grid item classes={{ item: classes.itemStyle }}>
              <MileageReimbursement label={label} name={name} shiftRates={shiftRates} />
            </Grid>
          </Grid>
        }
      </Grid>
    </>
  );
};
