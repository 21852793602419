import { contactStatusChoice, IActionList, IClientContactDetails } from 'app/models/ClientContact/ClientContact';
import { IOptionsList } from 'app/models/Tasks/Tasks';

export const selectAllOption = 'SELECT ALL';

export const generateUnits = facility => {
  const actionList = facility.length
    ? [
        { id: '0', name: 'SELECT ALL', value: 'SELECT ALL' },
        ...facility?.map(opt => ({
          id: opt.unitId,
          value: opt.name,
          name: opt.name,
        })),
      ]
    : [];
  return actionList;
};

export const formatActionList = (list: IActionList[]) => {
  const actionList = list.length
    ? [
        { id: '0', name: 'SELECT ALL', value: 'SELECT ALL' },
        ...list?.map(opt => ({
          id: opt.id?.toString(),
          value: opt.description,
          name: opt.description,
        })),
      ]
    : [];
  return actionList;
};

export const formatSelectedValues = (list: IOptionsList[]) => {
  const actionIds = list.length ? [...list?.map(opt => parseInt(opt.id))] : [];
  return actionIds;
};

export const formatUnitValues = (list: IOptionsList[]) => {
  return list?.map(x => ({
    name: x?.name,
    unitId: parseInt(x?.id),
  }));
};

export const formatActionValues = (list: IOptionsList[]) => {
  return list?.map(x => ({
    description: x?.name,
    id: parseInt(x?.id),
  }));
};

export const formatSelectedClientContact = (contactData: IClientContactDetails) => {
  return {
    firstName: contactData.firstName,
    lastName: contactData.lastName,
    title: contactData.title,
    email: contactData.email,
    contactPreferenceTypeId: contactData.contactPreferenceTypeId,
    statusId: 1,
    phoneNumber: contactData.phoneNumbers
      ? contactData.phoneNumbers[0].countryDialingCode + contactData.phoneNumbers[0]?.phoneNumber
      : null,
    contactPreference: '',
    contactStatus: contactStatusChoice.find(s => s.Id === contactData.contactStatusId)?.Description,
    actionValues: contactData.actions,
    selectedUnits: contactData.units,
  };
};

export const formatUpdatePayload = (contactDetails: IClientContactDetails) => {
  return {
    ...contactDetails,
    doNotCall: contactDetails?.contactPreferenceTypeId === 3,
    contactStatusId: 2,
  };
};
