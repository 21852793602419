import { RootState } from 'types';
import { initialState } from './slice';
import { createSelector } from '@reduxjs/toolkit';
import { isArrayElementsEqual } from './helper';

const selectDomain = (state: RootState) => state?.placementRequests || initialState;
export const selectAutoTaskManagement = createSelector([selectDomain], placementRequests => placementRequests);
export const selectAutoTaskGridData = createSelector([selectDomain], placementRequests => placementRequests.gridData);
export const selectSelectedRowId = createSelector(
  [selectDomain],
  placementRequests => placementRequests.selectedData,
);
export const selectSelectedGridRow = createSelector([selectDomain], placementRequests => placementRequests.selectedRow);
export const selectAssignedRoles = createSelector([selectDomain], placementRequests => placementRequests.assignedRoles);
export const selectSelectedUserRoles = createSelector(
  [selectDomain],
  placementRequests => placementRequests.selectedUserRoles,
);
export const selectCurrentStatus = createSelector([selectDomain], placementRequests => placementRequests.currentStatus);
export const selectUserRolesOptionsList = createSelector(
  [selectDomain],
  placementRequests => placementRequests.userRolesOptionsList,
);
export const selectIsAutoTaskDetailsModified = createSelector([selectDomain], placementRequests => {
  return (
    placementRequests.selectedRow?.statusId !== placementRequests.currentStatus?.statusId ||
    !isArrayElementsEqual(placementRequests.selectedRow?.assignedRoles, placementRequests?.assignedRoles)
  );
});
export const selectAutoTaskManagementStatusLookUp = createSelector(
  [selectDomain],
  placementRequests => placementRequests.autoTaskManagementStatusLookUp,
);
export const selectAutoTaskUpdateResponse = createSelector(
  [selectDomain],
  placementRequests => placementRequests.autoTaskUpdateResponse,
);
