import { Button, Grid, IconButton, Typography } from 'amn-ui-core';
import React, { useCallback, useEffect, useRef } from 'react';
import { ITemplate } from 'store/redux-store/confirmation-template-editor/types';
import { makeStyles } from 'tss-react/mui';
import { PinIcon, UnpinIcon } from './utils';

const useStyles = makeStyles<{ isDraft: boolean; isSelected: boolean; hoverOverDraftBtn: boolean }>()(
  (theme, { isDraft, isSelected, hoverOverDraftBtn }) => ({
    container: {
      borderRadius: 4,
      padding: 12,
      backgroundColor: isSelected ? 'rgba(0,111,185, 0.1)' : 'white',
      border: isSelected ? '2px solid rgba(0,111,185)' : '1px solid #D9D9D9',
      '&:hover': {
        backgroundColor: 'rgba(0,111,185, 0.1)',
        cursor: 'pointer',
      },
    },
    title: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 14,
      whiteSpace: 'nowrap',
      maxWidth: isDraft ? (hoverOverDraftBtn ? 150 : 200) : 275,
    },
    button: {
      fontSize: 14,
      backgroundColor: '#F0F0F0',
      color: '#333333',
      border: '1px solid #CBCCCC',
      borderRadius: 20,
      opacity: 1,
      textTransform: 'none',
      padding: '6px 12px',
      transition: 'all 0.3s ease-out',
      '&:after': {
        content: '"Draft"',
      },
      '&:hover': {
        backgroundColor: '#006FB9',
      },
      '&:hover:after': {
        color: 'white',
        content: '"Delete Draft"',
      },
    },
    templateBody: {
      fontSize: '11px',
      overflow: 'hidden',
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
    },
    updatedContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: '4px',
    },
  }),
);

const preventPropogation = e => {
  e.preventDefault();
  e.stopPropagation();
};

export const TemplateCard: React.FC<{
  template: ITemplate;
  isSelected: boolean;
  pinnable?: boolean;
  pinCard?: boolean;
  [key: string]: any;
}> = ({
  template: { templateName: title, templateText: content, isDraft, updatedByName, updatedOn },
  isSelected,
  pinnable = true,
  pinCard = false,
  ...props
}) => {
  const [hoverOverDraftBtn, setHoverOverDraftBtn] = React.useState(false);
  const [hoverOverCard, setHoverOverCard] = React.useState(false);
  const { classes } = useStyles({ isDraft, isSelected, hoverOverDraftBtn });
  const gridRef = useRef<HTMLDivElement>();
  const _content = React.useMemo(() => {
    const element = document.createElement('div');
    element.innerHTML = content;
    return element.textContent;
  }, [content]);

  const cardHoverHandler = useCallback(() => setHoverOverCard(true), []);
  const cardLeaveHandler = useCallback(() => setHoverOverCard(false), []);

  useEffect(() => {
    const gridElement = gridRef?.current;
    if (gridElement) {
      gridElement.addEventListener('mouseenter', cardHoverHandler);
      return () => {
        gridElement.removeEventListener('mouseenter', cardHoverHandler);
      };
    }
  }, [gridRef, cardHoverHandler]);

  useEffect(() => {
    if (!pinCard) {
      setHoverOverCard(false);
    }
  }, [pinCard]);

  const onDelete = e => {
    preventPropogation(e);
    props.onDelete?.();
  };

  const onPin = e => {
    preventPropogation(e);
    props.onPin?.();
  };

  const onUnpin = e => {
    preventPropogation(e);
    props.onUnpin?.();
  };

  return (
    <Grid
      ref={gridRef}
      container
      classes={{ root: classes.container }}
      component="div"
      onClick={props.onClick}
      onMouseLeave={cardLeaveHandler}
    >
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="space-between" wrap="nowrap">
          <Grid item>
            <Typography color={!isSelected ? '#333333' : '#006FB9'} classes={{ root: classes.title }}>
              {title}
            </Typography>
          </Grid>
          {isDraft ? (
            <Grid item>
              <Button
                className={classes.button}
                variant="outlined"
                size="small"
                onClick={onDelete}
                disabled={props.restrictToMergeEngine}
                onMouseEnter={() => setHoverOverDraftBtn(true)}
                onMouseLeave={() => setHoverOverDraftBtn(false)}
              />
            </Grid>
          ) : pinnable ? (
            pinCard ? (
              <Grid item>
                <IconButton color="primary" size="small" onClick={onUnpin}>
                  <UnpinIcon />
                </IconButton>
              </Grid>
            ) : (
              hoverOverCard && (
                <Grid item>
                  <IconButton color="primary" size="small" onClick={onPin}>
                    <PinIcon />
                  </IconButton>
                </Grid>
              )
            )
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" classes={{ root: classes.templateBody }}>
          {_content}
        </Typography>
      </Grid>
      <Grid classes={{ root: classes.updatedContainer }} item xs={12}>
        <Typography
          variant="body2"
          component={'span'}
          sx={theme => ({ color: theme.palette.framework.system.doveGray })}
        >
          <Typography
            variant="body2"
            component={'span'}
            fontWeight={'medium'}
            sx={theme => ({ color: theme.palette.framework.system.doveGray })}
          >{`Last Updated: `}</Typography>
          {`${updatedByName}`}&nbsp;
        </Typography>
        <Typography
          sx={theme => ({ whiteSpace: 'preline', color: theme.palette.framework.system.doveGray })}
          variant="body2"
          component={'span'}
        >
          {`(${updatedOn})`}
        </Typography>
      </Grid>
    </Grid>
  );
};
