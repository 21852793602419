/* eslint-disable i18next/no-literal-string */
import { Paper } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { IGraphicStepper } from 'app/ComponentLibrary/GraphicStepper/GraphicStepper';
import { GraphicStepperSingle } from 'app/ComponentLibrary/GraphicStepper/GraphicStepperSingle';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { OrderCurrentPlacements } from 'app/models/Orders/OrderDetails';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PreviewContentTitle } from '../../../OrderCreationForm/DefineUnit/UnitPreview/UnitPreviewDesign';
import Auto from 'app/assets/images/Order/Auto.svg';
import { selectAutomationStatus } from 'app/components/Order/OrderDetails/OrderPreferences/store/OrderPreference.selector';
import { useSelector } from 'react-redux';

const useStyles = makeStyles()({
  data: {
    fontWeight: 500,
  },
  dataColor: {
    color: '#555555',
  },
  inlineSteperIcon: {
    position: 'relative',
    left: '-5px',
    top: '-1px',
  },
});

interface Props {
  data: {
    currentPlacements: OrderCurrentPlacements | null;
  };
}

export const OrderDetailsCurrentPlacements = ({ data }: Props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [currentPlacements, setCurrentPlacements] = useState<IGraphicStepper[]>([]);
  const isSubmissionAutomationEnabled = useSelector(selectAutomationStatus);

  useEffect(() => {
    setCurrentPlacements([
      {
        category: {
          tag: t('order.createUnit.currentPlacements.inSubmission'),
          showTooltip: false,
          value: t('order.createUnit.currentPlacements.inSubmission'),
          color: 'white',
        },
        subcategory: [
          {
            tag: 'Automation',
            showTooltip: isSubmissionAutomationEnabled === true,
            tooltipContent: 'Automation is On',
            data: data.currentPlacements?.inSubmission || 0,
            value: '',
            icon: isSubmissionAutomationEnabled ? <img src={Auto} className={classes.inlineSteperIcon} /> : <></>,
          },
        ],
      },
      {
        category: {
          tag: t('order.createUnit.currentPlacements.interviewing'),
          showTooltip: false,
          value: t('order.createUnit.currentPlacements.interviewing'),
          color: 'white',
        },
        subcategory: [
          {
            tag: '',
            showTooltip: false,
            tooltipContent: 'Automation is On',
            data: data.currentPlacements?.interviewing || 0,
            value: '',
            icon: false ? <img src={Auto} className={classes.inlineSteperIcon} /> : <></>,
          },
        ],
      },
      {
        category: {
          tag: t('order.createUnit.currentPlacements.offered'),
          showTooltip: false,
          value: t('order.createUnit.currentPlacements.offered'),
          color: 'white',
        },
        subcategory: [
          {
            tag: '',
            showTooltip: false,
            tooltipContent: 'Automation is On',
            data: data.currentPlacements?.offered || 0,
            value: '',
            icon: false ? <img src={Auto} className={classes.inlineSteperIcon} /> : <></>,
          },
        ],
      },
      {
        category: {
          tag: t('order.createUnit.currentPlacements.booked'),
          showTooltip: false,
          value: t('order.createUnit.currentPlacements.booked'),
          color: 'white',
        },
        subcategory: [
          {
            tag: '',
            showTooltip: false,
            tooltipContent: 'Automation is On',
            data: data.currentPlacements?.booked || 0,
            value: '',
            icon: false ? <img src={Auto} className={classes.inlineSteperIcon} /> : <></>,
          },
        ],
      },
    ]);
  }, [
    isSubmissionAutomationEnabled,
    classes.inlineSteperIcon,
    data.currentPlacements,
    t,
  ]);

  return (
    <>
      <LayoutGridItem item xs={12}>
        <Paper className="layout" variant="outlined" elevation={0}>
          <LayoutGrid container direction="column">
            <LayoutGridItem item>
              <PreviewContentTitle>{t('order.createUnit.currentPlacements.title')}</PreviewContentTitle>
            </LayoutGridItem>
            <LayoutGridItem item xs={12}>
              {currentPlacements.length && (
                <GraphicStepperSingle
                  items={currentPlacements}
                  handleItemClick={() => {}} // not used right now
                  selected={[]} // not used right now
                  noValueText="No data found"
                />
              )}
            </LayoutGridItem>
            <LayoutGrid container spacing={0} item xs={12} className={classes.dataColor}>
              <LayoutGridItem item xs={5}>
                <LayoutGrid container spacing={0} direction="column">
                  <LayoutGridItem item className={classes.data}>
                    {t('order.createUnit.currentPlacements.dateOfLastBooking')}
                  </LayoutGridItem>
                  <LayoutGridItem item>{data.currentPlacements?.lastBookedDate || 'N/A'}</LayoutGridItem>
                </LayoutGrid>
              </LayoutGridItem>
              <LayoutGridItem item xs={7}>
                <LayoutGrid container spacing={0} direction="column">
                  <LayoutGridItem item className={classes.data}>
                    {t('order.createUnit.currentPlacements.toaAtFacility')}
                  </LayoutGridItem>
                  <LayoutGridItem item>{data.currentPlacements?.onAssignment || 0}</LayoutGridItem>
                </LayoutGrid>
              </LayoutGridItem>
            </LayoutGrid>
          </LayoutGrid>
        </Paper>
      </LayoutGridItem>
    </>
  );
};
