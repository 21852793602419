import React from 'react';
import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useFormContext } from 'react-hook-form';
import { ControlledTypeAheadWithCheckBox } from './ControlledTypeAheadWithCheckBox';
import { useSelector } from 'react-redux';
import { selectOrderPriorities } from '@AMIEWEB/Order/Store/OrderCreation.selectors';

const useStyles = makeStyles()({
  orderPrioritiesField: {
    minHeight: '56px',
    backgroundColor: '#fff !important',
    border: 'thin #e7e2e2 solid !important',
    '&:hover': {
      backgroundColor: '#fff !important',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff !important',
    },
  },
});

export const OrderPriorities = props => {
  const { classes } = useStyles();
  const { control, register } = useFormContext();
  const { defaultValue, selectedValue, disabledValue } = props;
  const orderPriorities = useSelector(selectOrderPriorities);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.orderPrioritiesField}>
        {orderPriorities?.length && (
          <ControlledTypeAheadWithCheckBox
            id={'createUnitOrderPrioritiesDropDown'}
            register={register}
            name="orderPriorities"
            label={`Order Priorities`}
            control={control}
            options={orderPriorities}
            onChange={priority => props.handleOrderPriorities(priority)}
            defaultValue={defaultValue}
            selectedvalue={selectedValue}
            disabledValue={disabledValue}
          />
        )}
      </Grid>
    </Grid>
  );
};
