import React from 'react';
import { Grid, materialUiXGrid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';
import { XGrid } from '@AMIEWEB/Common/XGrid/XGrid';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { GridSortModel } from '@mui/x-data-grid-pro';
import _ from 'lodash';
import { unitPreferenceActions } from 'store/redux-store/unit-preferences/slice';
import { facilityPreferenceActions } from 'store/redux-store/facility-preferences/slice';

const useStyles = makeStyles()({
  gridContainer: { padding: '24px 0px 34px 0px ', height: '100%' },
});

interface IUpdatePreference {
  tableTitle: string;
  tableData: any[];
  columns: any[];
  isExpanded?: boolean;
  selectedGridData: number[];
  onResetColumns: () => void;
  onGridRefresh: () => void;
}

export const UpdatePreferences = ({
  tableTitle,
  tableData,
  columns,
  isExpanded,
  onResetColumns,
  onGridRefresh,
  selectedGridData,
}: IUpdatePreference) => {
  const { classes } = useStyles();

  const defaultSort: any = [{ field: 'lastUpdatedOn', sort: 'desc' }];
  const dispatch = useDispatch();
  const apiRef = materialUiXGrid.useGridApiRef();
  const [currentSortModel, setCurrentSortModel] = React.useState<any[]>([]);
  const [resetGridButton, setResetGridButton] = React.useState<boolean>(false);

  const onSortModelChanged = (model: GridSortModel) => {
    setCurrentSortModel(model);
    setResetGridButton(true);
  };

  const handleResetColumns = () => {
    setCurrentSortModel([]);
    setResetGridButton(false);
    onResetColumns();
  };

  const selectionModalChange = (selected, details) => {
    if (tableTitle === 'Orders') {
      dispatch(unitPreferenceActions.setPreferenceSelectionModal(selected));
    } else {
      dispatch(facilityPreferenceActions.setPreferenceSelection(selected));
    }
  };

  const GridComponent = React.useMemo(() => {
    return (
      <XGrid
        apiRef={apiRef}
        disableDensitySelector
        pagination={false}
        defaultSortModel={defaultSort}
        sortModel={currentSortModel}
        onSortModelChange={onSortModelChanged}
        onColumnWidthChange={() => {
          setResetGridButton(true);
        }}
        onColumnVisibilityModelChange={() => {
          setResetGridButton(true);
        }}
        title={tableTitle}
        rows={tableData}
        selectionModel={selectedGridData}
        onSelectionModelChange={selectionModalChange}
        columns={columns}
        autoHeight={false}
        height={!isExpanded ? 374 : window.innerHeight - 180}
        disableSelectionOnClick
        rowCount={selectedGridData?.length ?? (tableData || []).length}
        checkboxSelection
        hideFooter={true}
        inDialog
        toolbarProps={{
          refreshButton: true,
          resetButton: resetGridButton,
          refreshGrid: () => {
            onGridRefresh();
          },
          resetColumns: () => {
            handleResetColumns();
            dispatch(
              globalActions.setSnackBar({
                message: 'Columns have been reset.',
                severity: 'success',
              }),
            );
          },
        }}
      />
    );
  }, [tableData, columns, currentSortModel, resetGridButton, isExpanded, selectedGridData]);

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item xs={12}>
        {GridComponent}
      </Grid>
    </Grid>
  );
};
