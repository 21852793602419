import { IElementAccessor } from 'app/components/Common/EditPage/PageActions';

export enum editableFields {
  placementDate = 'placementDate',
  placementStartDate = 'placementStartDate',
  placementEndDate = 'placementEndDate',
  numberOfWeeks = 'numberOfWeeks',
  availabilityDate = 'availabilityDate',
  bestTimeToCall = 'bestTimeToCall',
  requestedShift = 'requestedShift',
  travelsWithPartner = 'travelsWithPartner',
  travelPartner = 'travelPartner',
  sellingPoints = 'sellingPoints',
  requestedTimeOff = 'requestedTimeOff',
  ssn = 'ssn',
  dob = 'dob',
  arrivalDate = 'arrivalDate',
  arrivalTime = 'arrivalTime',
  placementShift = 'placementShift',
  vmsReqNo = 'vmsReqNo',
  contractHours = 'contractHours',
}

export const editAccessor = 'edit-accessor';
export const editFromDrawerAccessor = 'edit-drawer-accessor';

const concatWithEditAccessor = key => `${editAccessor}-${key}`;
const concatWithDrawerAccessor = key => `${editFromDrawerAccessor}-${key}`;

export const editAccessors = [
  {
    id: concatWithEditAccessor(editableFields.placementStartDate),
    value: editableFields.placementStartDate,
    action: 'click',
  },
  {
    id: concatWithEditAccessor(editableFields.placementEndDate),
    value: editableFields.placementEndDate,
    action: 'click',
  },
  {
    id: concatWithEditAccessor(editableFields.numberOfWeeks),
    value: editableFields.placementStartDate,
    action: 'click',
  },
  { id: concatWithEditAccessor(editableFields.placementShift), value: editableFields.placementShift, action: 'click' },
  { id: concatWithEditAccessor(editableFields.bestTimeToCall), value: editableFields.bestTimeToCall, action: 'click' },
  {
    id: concatWithEditAccessor(editableFields.availabilityDate),
    value: editableFields.availabilityDate,
    action: 'click',
  },
  {
    id: concatWithEditAccessor(editableFields.requestedShift),
    value: editableFields.requestedShift,
    action: 'click',
  },
  {
    id: concatWithEditAccessor(editableFields.requestedTimeOff),
    value: editableFields.requestedTimeOff,
    action: 'focus',
  },
  {
    id: concatWithEditAccessor(editableFields.travelsWithPartner),
    value: editableFields.travelsWithPartner,
    action: 'focus',
  },
  { id: concatWithEditAccessor(editableFields.travelPartner), value: editableFields.travelPartner, action: 'click' },
  { id: concatWithEditAccessor(editableFields.sellingPoints), value: editableFields.sellingPoints, action: 'click' },
] as IElementAccessor[];

export const editFromDrawerAccessors = [
  {
    id: concatWithDrawerAccessor(editableFields.placementStartDate),
    value: editableFields.placementStartDate,
    action: 'click',
  },
  {
    id: concatWithDrawerAccessor(editableFields.placementEndDate),
    value: editableFields.placementEndDate,
    action: 'click',
  },
  { id: concatWithDrawerAccessor(editableFields.numberOfWeeks), value: editableFields.numberOfWeeks, action: 'click' },

  {
    id: concatWithDrawerAccessor(editableFields.availabilityDate),
    value: editableFields.availabilityDate,
    action: 'click',
  },
  {
    id: concatWithDrawerAccessor(editableFields.bestTimeToCall),
    value: editableFields.bestTimeToCall,
    action: 'click',
  },
  {
    id: concatWithDrawerAccessor(editableFields.requestedShift),
    value: editableFields.requestedShift,
    action: 'click',
  },
  {
    id: concatWithDrawerAccessor(editableFields.requestedTimeOff),
    value: editableFields.requestedTimeOff,
    action: 'focus',
  },
  {
    id: concatWithDrawerAccessor(editableFields.travelsWithPartner),
    value: editableFields.travelsWithPartner,
    action: 'focus',
  },
  { id: concatWithDrawerAccessor(editableFields.travelPartner), value: editableFields.travelPartner, action: 'click' },
  { id: concatWithDrawerAccessor(editableFields.sellingPoints), value: editableFields.sellingPoints, action: 'click' },
] as IElementAccessor[];

const headerFields = [
  editableFields.placementStartDate,
  editableFields.placementEndDate,
  editableFields.numberOfWeeks,
  editableFields.availabilityDate,
  editableFields.bestTimeToCall,
];

export const getEditableItemIds = (tab, travelsWithPartner = false, fromDrawer = false) => {
  if (fromDrawer)
    return travelsWithPartner
      ? editFromDrawerAccessors
      : editFromDrawerAccessors.filter(accessor => accessor.value !== editableFields.travelPartner);
  if (!tab || tab === '0')
    return travelsWithPartner
      ? editAccessors
      : editAccessors.filter(accessor => accessor.value !== editableFields.travelPartner);
  else return editAccessors.filter(accessor => headerFields.some(field => accessor.value === field));
};

export const editableKeys = {
  placement: [
    'bestTimeToCall',
    'requestedShift',
    'travelsWithPartner',
    'travelPartner',
    'placementDate',
    'numberOfWeeks',
    'placementStartDate',
    'placementEndDate',
    'availabilityDate',
    'sellingPoints',
    'requestedTimeOff',
    'placementShift',
    'vmsReqNo',
    'contractHours',
  ],
  ssn: 'ssn',
  dob: 'dob',
  strikePlacementProps: [
    'transportationChosen',
    'travelTimePreferenceChosen',
    'gender',
    'departureAirport',
    'datesConfirmed',
    'dnsCleared',
    'travelConfirmed',
    'arrivalDate',
    'arrivalTime',
  ],
  placementShift: 'placementShift',
};

export const extractPlacementServiceKeys = dirtyFields =>
  Object.keys(dirtyFields).reduce((results, key) => {
    if (['ssn', 'dob'].includes(key)) return results;
    /** Code: Temporary insertion till API replace prop shiftId */ else if (key === 'placementShift')
      results.push('shiftId');
    else if (
      (key === 'placementStartDate' || key === 'placementEndDate' || key === 'numberOfWeeks') &&
      !results?.find(item => item === 'placementDate')
    ) {
      results.push('placementDate');
    }
    results.push(key);
    return results;
  }, []);

export const extractStrikePlacementServiceKeys = dirtyFields => {
  const editedKeys = Object.keys(dirtyFields);
  const transportationChosenIndex = editedKeys.findIndex(item => item === 'transportationChosen');
  const travelPreferenceChosenIndex = editedKeys.findIndex(item => item === 'travelTimePreferenceChosen');
  if (transportationChosenIndex !== -1) editedKeys.splice(transportationChosenIndex, 1, 'transportationId');
  if (travelPreferenceChosenIndex !== -1) editedKeys.splice(travelPreferenceChosenIndex, 1, 'travelTimePreferenceId');
  const ssn = editedKeys.findIndex(item => item === 'ssn');
  if (ssn !== -1) editedKeys.splice(ssn, 1);
  const dob = editedKeys.findIndex(item => item === 'dob');
  if (dob !== -1) editedKeys.splice(dob, 1);
  return editedKeys;
};

export const isPlacementEdited = editedFields => editedFields.some(field => editableKeys.placement.includes(field));
export const isSsnEdited = editedFields => editedFields.some(field => field === editableKeys.ssn);
export const isDobEdited = editedFields => editedFields.some(field => field === editableKeys.dob);
export const isShiftEdited = editedFields => editedFields.some(field => field === editableKeys.placementShift);
export const isStrikePropsEdited = editedFields =>
  editedFields.some(field => editableKeys.strikePlacementProps.includes(field));
