import { makeStyles } from 'tss-react/mui';
import React from 'react';
import CustomRangeDropdown, { isInstanceOfIRange } from '../common/CustomRangeDropdown';
import { numberType } from '../common/CustomRangeDropdown';
import { useDispatch, useSelector } from 'react-redux';
import {
  ContractType,
  getShiftId,
  isNewRateElementRequired,
  isRangeValue,
  RateElemenetSource,
  RateElements,
  refactorInputSingleValue,
} from '../helper';
import _, { isArray } from 'lodash';
import { Status } from 'app/enums/Facility';
import uuid from 'react-uuid';
import { selectUser } from 'oidc/user.selectors';
import moment from 'moment';
import {
  selectContractDetails,
  selectBillRateSelections,
  selectOcbrDetails,
  selectOcbrSavedDetails,
} from 'store/redux-store/create-order-ocbr/selector';
import { createOrderOcbrActions } from 'store/redux-store/create-order-ocbr/slice';
import { missingField } from 'app/constants';
const useStyles = makeStyles()(theme => ({
  containerStyle: {
    padding: '6px ',
    flexWrap: 'nowrap',
  },
  itemStyle: {
    padding: '6px ',
  },
  headerTextStyle: {
    fontWeight: 500,
    width: 73,
  },
  billRateItem: {
    flexShrink: 0,
    display: 'flex',
  },
  divider: {
    height: '100%',
    margin: '0 6px',
  },
}));
export const GwwRateField = ({ label, name, shiftHours = [], shift = null }) => {
  const contractDetails = useSelector(selectContractDetails);
  const { billRates: ocbrBillRates, effectiveDates, isFlatRate } = useSelector(selectOcbrDetails);
  const { billRates: ocbrSavedBillRates } = useSelector(selectOcbrSavedDetails);

  const user = useSelector(selectUser);
  const rateSelections = useSelector(selectBillRateSelections);

  const { value, ocbr } = React.useMemo(() => {
    const selection = rateSelections[name];
    return !selection ? { value: null, ocbr: ContractType.OnContract } : selection;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateSelections]);

  const dispatch = useDispatch();
  const handleChange = newValue =>
    dispatch(
      createOrderOcbrActions.setBillRateSelection({
        [name]: { value: newValue, ocbr: determineOcbr(newValue, shiftHours, contractDetails?.rateElements, shift) },
      }),
    );

  const checkIsBillRateOcbr = val => {
    if (
      !(
        contractDetails &&
        isArray(contractDetails?.rateElements) &&
        contractDetails?.rateElements[0].hoursPerPayCycle === null
      )
    ) {
      //Case 1- Contract has GWW
      if (val?.statusId) {
        return true;
      } else if (shiftHours?.length > 0) {
        const contractValues = shiftHours?.filter(item => item.source === RateElemenetSource.Contract);
        const foundMatch = contractValues?.some(item => {
          if (isInstanceOfIRange(item) && isInstanceOfIRange(val)) {
            return item?.min?.value + '' === val?.min?.value;
          } else if (isInstanceOfIRange(item) && !isInstanceOfIRange(val)) {
            return false;
          } else if (!isInstanceOfIRange(item) && isInstanceOfIRange(val)) {
            return item?.value + '' === val?.min.value ? true : false;
          } else {
            return item?.value + '' === val?.value;
          }
        });
        if (foundMatch || val === null) {
          return false;
        } else {
          return true;
        }
      } else if (val === null) {
        return false;
      } else {
        return true;
      }
    } else {
      //Case 2- Contract does not have GWW
      if (val?.statusId) {
        return true;
      } else {
        return val !== null;
      }
    }
  };
  const updateReduxValue = newVal => {
    const isOcbrValue = checkIsBillRateOcbr(newVal);
    if (newVal !== undefined && ocbrBillRates) {
      const billRates = getBillRateData(newVal, ocbrBillRates, isOcbrValue);
      if (!_.isEqual(billRates, ocbrBillRates)) {
        dispatch(createOrderOcbrActions.business_updateBillRates({ billRates }));
        dispatch(createOrderOcbrActions.business_deriveRateSelections({ isFlatRate: false, billRates }));
      }
    } else if (newVal !== undefined && !ocbrBillRates) {
      const billRates = getBillRateData(newVal, [], isOcbrValue);
      if (!_.isEqual(ocbrBillRates, billRates)) {
        dispatch(createOrderOcbrActions.business_updateBillRates({ billRates }));
        dispatch(createOrderOcbrActions.business_deriveRateSelections({ isFlatRate: false, billRates }));
      }
    }
  };
  const updateSavedReduxValue = newVal => {
    const isOcbr = checkIsBillRateOcbr(newVal);
    if (newVal !== undefined && ocbrSavedBillRates) {
      const billRates = getSavedBillRateData(newVal, ocbrSavedBillRates, isOcbr);
      if (!_.isEqual(billRates, ocbrSavedBillRates)) {
        dispatch(createOrderOcbrActions.setOcbrSavedBillRates(billRates));
      }
    } else if (newVal !== undefined && !ocbrSavedBillRates) {
      const billRates = getSavedBillRateData(newVal, [], isOcbr);
      if (!_.isEqual(ocbrSavedBillRates, billRates)) {
        dispatch(createOrderOcbrActions.setOcbrSavedBillRates(billRates));
      }
    }
  };

  const getBillRateData = (newVal, billRatesArrOrg, ocbr) => {
    //delete existing values
    const billRatesArr = (billRatesArrOrg ?? []).reduce((resultArray, item) => {
      if (item.shiftId === shiftHours[shiftHours.length - 1]?.shiftID && item.type === RateElements.GWW)
        return resultArray;
      return [...resultArray, item];
    }, []);
    //Delete from Redux when value removed
    if (newVal === null) {
      return billRatesArr;
    }

    const orderEndDate = effectiveDates?.endDate ? moment(effectiveDates?.endDate).format() : null;
    const orderStartDate = moment(newVal.effectiveEndDate).add(1, 'days').format();
    const isExtraRateEleRequired = isNewRateElementRequired(effectiveDates?.endDate, newVal.effectiveEndDate);

    const temp = {
      offContractId: newVal.offContractId ? newVal.offContractId : null,
      rateElementId: newVal.rateElementId ? newVal.rateElementId : uuid(),
      disciplineId: null,
      specialtyId: null,
      subSpecialtyId: null,
      shiftId: shift === null ? null : getShiftId(shift),
      shift: shift,
      type: RateElements.GWW,
      billRate: refactorInputSingleValue(newVal),
      isOcbr: ocbr,
      statusId: ocbr ? newVal?.statusId ?? Status.Unsaved : null,
      createdAt: newVal?.createdAt,
      createdBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
      reason: newVal.reason ? newVal.reason : missingField,

      documentIds: newVal?.documentIds,
      effectiveStartDate:
        newVal?.source === RateElemenetSource.Facility ? newVal.effectiveStartDate : effectiveDates?.startDate,
      effectiveEndDate:
        newVal?.source === RateElemenetSource.Facility ? newVal.effectiveEndDate : effectiveDates?.endDate,
      lastUpdatedBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
      dateLastUpdated: moment().format(),
      dateCreated: moment().format(),
      source: isRangeValue(newVal) ? RateElemenetSource.Order : newVal?.source,
    };

    billRatesArr.push(temp);
    if (isExtraRateEleRequired) {
      const additonalRateElement = {
        ...temp,
        offContractId: null,
        rateElementId: uuid(),
        statusId: Status.Unsaved,
        effectiveStartDate: orderStartDate,
        effectiveEndDate: orderEndDate,
        source: RateElemenetSource.Order,
        documentIds: [],
        createdAt: null,
        reason: missingField,
        isExtra: true,
      };
      billRatesArr.push(additonalRateElement);
    }
    return billRatesArr || [];
  };

  const getSavedBillRateData = (newVal, billRatesArrOrg, ocbr) => {
    //delete existing values
    const billRatesArr = (billRatesArrOrg ?? []).reduce((resultArray, item) => {
      if (item.shiftId === shiftHours[shiftHours.length - 1]?.shiftID && item.type === RateElements.GWW)
        return resultArray;
      return [...resultArray, item];
    }, []);
    //Delete from Redux when value removed
    if (newVal === null) {
      return billRatesArr;
    }

    const temp = {
      offContractId: newVal.offContractId ? newVal.offContractId : null,
      rateElementId: newVal.rateElementId ? newVal.rateElementId : uuid(),
      disciplineId: null,
      specialtyId: null,
      subSpecialtyId: null,
      shiftId: shift === null ? null : getShiftId(shift),
      shift: shift,
      type: RateElements.GWW,
      billRate: refactorInputSingleValue(newVal),
      isOcbr: ocbr,
      statusId: ocbr ? newVal?.statusId ?? Status.Unsaved : null,
      createdAt: newVal?.createdAt,
      createdBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
      reason: newVal.reason ? newVal.reason : missingField,

      documentIds: newVal?.documentIds,
      effectiveStartDate:
        newVal?.source === RateElemenetSource.Facility ? newVal.effectiveStartDate : effectiveDates?.startDate,
      effectiveEndDate:
        newVal?.source === RateElemenetSource.Facility ? newVal.effectiveEndDate : effectiveDates?.endDate,
      lastUpdatedBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
      dateLastUpdated: moment().format(),
      dateCreated: moment().format(),
      source: isRangeValue(newVal) ? RateElemenetSource.Order : newVal?.source,
    };

    billRatesArr.push(temp);
    return billRatesArr || [];
  };

  return (
    <>
      <CustomRangeDropdown
        value={value}
        options={shiftHours}
        onChange={newVal => {
          handleChange(newVal);
        }}
        onBlur={newVal => {
          updateReduxValue(newVal);
          updateSavedReduxValue(newVal);
        }}
        label={label}
        name={name}
        labelPostfix={'Hours'}
        labelPrefix={undefined}
        type={numberType.number}
        isChanged={ocbr}
      />
    </>
  );
};

const determineOcbr = (value, shiftHours, rateElements, shift) => {
  if (!(isArray(rateElements) && rateElements[0].hoursPerPayCycle === null)) {
    //Case 1- Contract has GWW
    if (value?.statusId) {
      return value?.statusId;
    } else if (shiftHours?.length > 0) {
      const foundMatch = shiftHours?.some(item => {
        if (isInstanceOfIRange(item) && isInstanceOfIRange(value)) {
          return item?.min?.value + '' === value?.min?.value;
        } else if (isInstanceOfIRange(item) && !isInstanceOfIRange(value)) {
          return false;
        } else if (!isInstanceOfIRange(item) && isInstanceOfIRange(value)) {
          return item?.value + '' === value?.min.value ? true : false;
        } else {
          return item?.value + '' === value?.value;
        }
      });
      if (foundMatch || value === null) {
        return ContractType.OnContract;
      } else {
        return ContractType.OffContract;
      }
    } else if (value === null) {
      return ContractType.OnContract;
    } else {
      return ContractType.OffContract;
    }
  } else {
    //Case 2- Contract does not have GWW
    if (value?.statusId) {
      return value?.statusId;
    } else if (shift?.length) {
      return value === null ? ContractType.OnContract : ContractType.OffContract;
    } else {
      return ContractType.OnContract;
    }
  }
};
