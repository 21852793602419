/* eslint-disable tss-unused-classes/unused-classes */
import { Avatar, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import { missingField } from 'app/constants';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { ListSection } from '../../../customComponents/ListSection';
import { TableSection } from '../../../customComponents/TableSection';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import {
  selectReferenceAndEmploymentVerificationRecords,
  selectServiceFailures,
  selectSubmissionInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { RequirementsRow } from '../../TableComponents/TableComponents';
import { ReferencesModal } from '../amnRequirementRows/ReferencesGrid/ReferencesModal';
import { formatPlacementReferences, getPlacementReferencesFilterModel } from './helper';
import { useDecision } from '@optimizely/react-sdk';
import { ff_placement_ui_requirement_check } from 'app/constants/FeatureFlags/Placement/Keys';
import { getRequirementCheck } from '../../../helper';
import { theme } from 'styles/global-styles';
import { WhiteTooltip } from '@AMIEWEB/Common';
import { extractReferenceAndEmploymentVerifications } from 'store/redux-store/placement-summary/sagaHelper';

const useStyles = makeStyles()({
  countCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  countTag: {
    marginRight: 8,
  },
  button: {
    cursor: 'pointer',
    color: '#006FB9',
    fontWeight: 450,
    minWidth: 0,
    padding: 0,
    textDecoration: 'none',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
  refSection: {
    padding: '12px',
  },
  blue: {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: theme.palette.system.primary,
    width: '22px',
    height: '22px',
    fontSize: '14px',
    display: 'inline-flex',
    margin: '0 0.4rem 0 0.4rem',
  },
  green: {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: theme.palette.framework.system.green,
    width: '22px',
    height: '22px',
    fontSize: '14px',
    display: 'inline-flex',
    margin: '0 0.4rem 0 0.4rem',
  },
});

export const References = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [display, setDisplay] = React.useState(true);
  const {
    orderReq: {
      order: { references: orderReqItem },
      candidate: { references: candidateReqItem },
    },
    requirementFilter,
  } = useSelector(selectSubmissionInfo);
  const { referenceOrderRequirement: serviceFailed } = useSelector(selectServiceFailures);
  const [requirementCheckFlag] = useDecision(ff_placement_ui_requirement_check);
  const placementOrderType = useSelector(selectPlacementOrderType);
  const { placementId, candidateId } = useContext(AddOnContext);

  const { promiseInProgress } = usePromiseTracker({
    area: 'placement-submission-reference-order-requirement',
    delay: 0,
  });

  const dispatch = useDispatch();

  const { filter } = useRequirementFilter('submission-order', requirementFilter);

  useEffect(() => {
    const state =
      /**True if filter is not applied */
      !filter.length
        ? true
        : /** Check if risk is applied */
          filter.includes(candidateReqItem?.type) ||
          /** pass / fail is in orderRequirement column and applied in filter */
          (requirementCheckFlag && (filter || []).includes(orderReqItem?.type));
    setDisplay(state);
  }, [candidateReqItem, filter, orderReqItem, requirementCheckFlag]);

  return display ? (
    <RequirementsRow
      id="placement-submission-order-references"
      title={t('placement.profile.summary.submission.order.reference.title')}
      requirementCell={
        orderReqItem ? (
          <RowKey
            references={orderReqItem}
            t={t}
            requirementCheckFlag={requirementCheckFlag?.enabled}
            classes={classes}
          />
        ) : (
          missingField
        )
      }
      candidateCell={
        candidateReqItem ? (
          <RowValue
            referencesData={formatPlacementReferences(candidateReqItem)}
            classes={classes}
            requirementCheckFlag={requirementCheckFlag?.enabled}
            t={t}
            orderReqItem={orderReqItem}
            {...{ placementOrderType, candidateId, placementId }}
          />
        ) : (
          missingField
        )
      }
      loading={promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() => dispatch(placementSummaryActions.getReferenceOrderRequirement({ placementId }))}
    />
  ) : null;
};

const RowKey = ({ references, t, requirementCheckFlag, classes }) => {
  return (
    <>
      {requirementCheckFlag ? (
        <>
          {references?.data?.numberOfReferenceToRFO ? (
            <TableSection
              items={[
                {
                  icon: references ? getRequirementCheck(references?.type) : null,
                  primaryContent: (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                          <span>{t('placement.profile.summary.submission.order.reference.referencestitle')}</span>
                          <span>{references?.data?.numberOfReferenceToRFO}</span>
                        </div>
                        <WhiteTooltip
                          title={
                            references?.data?.isRequired
                              ? t('placement.common.required')
                              : t('placement.common.preferred')
                          }
                        >
                          <Avatar
                            variant={references?.data?.isRequired ? 'primary' : 'green'}
                            className={`${references?.data?.isRequired ? classes.blue : classes.green}`}
                          >
                            {references?.data?.isRequired ? 'R' : 'P'}
                          </Avatar>
                        </WhiteTooltip>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        <span>{t('placement.profile.summary.submission.order.reference.timeframeTitle')}</span>
                        <span>
                          {(references?.data?.withinMonths === null && references?.data?.withinNumberOfMonths > 0) ||
                          (!references?.data?.withinMonths &&
                            references?.data?.withinNumberOfMonths &&
                            references?.data?.numberOfReferenceToRFO > 0) // withinMonth == IsEntireWorkHistory as per API
                            ? t(`placement.profile.summary.submission.order.reference.timeFrameValue`, {
                                orderMonths: references?.data?.withinNumberOfMonths,
                              })
                            : t('placement.profile.summary.submission.order.reference.entireWorkhistory')}
                        </span>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        <span>{t('placement.profile.summary.submission.order.reference.typeTitle')}</span>
                        <span>
                          {references?.data?.referenceFromSupervisor
                            ? t('placement.profile.summary.submission.order.reference.supervisorType')
                            : t('placement.profile.summary.submission.order.commons.any')}
                        </span>
                      </div>
                    </div>
                  ),
                },
              ]}
            />
          ) : (
            missingField
          )}
        </>
      ) : (
        <TableSection
          items={[
            {
              primaryContent: `${t('placement.profile.summary.submission.order.reference.line1')}: ${
                references && references.data ? references?.data?.facilityRfoCount : missingField
              }`,
            },
            {
              primaryContent: `${t('placement.profile.summary.submission.order.reference.line2')}: ${
                references && references?.data ? references.data?.orderRfoCount : missingField
              }`,
            },
          ]}
        />
      )}
    </>
  );
};

const validateMonthsDisplay = (orderReqItem, requirementCheckFlag) => {
  if (requirementCheckFlag) {
    if (!orderReqItem?.data?.withinMonths && !orderReqItem?.data?.withinNumberOfMonths) {
      return false;
    } else if (orderReqItem?.data?.withinMonths) {
      return false;
    } else if (
      !orderReqItem?.data?.withinMonths &&
      orderReqItem?.data?.numberOfReferenceToRFO &&
      orderReqItem?.data?.withinNumberOfMonths &&
      orderReqItem?.data?.withinNumberOfMonths > 0
    ) {
      return true;
    }
  } else {
    return false;
  }
};

const RowValue = ({
  referencesData,
  classes,
  t,
  placementOrderType,
  candidateId,
  requirementCheckFlag,
  orderReqItem,
  placementId,
}) => {
  const [openReferenceModal, setOpenReferenceModal] = React.useState<boolean>(false);
  const [filterModel, setFilterModel] = React.useState<any>([]);
  const [sectionName, setSectionName] = React.useState<string | undefined>();
  const [categoryName, setCategoryName] = React.useState<string | undefined>();
  const [gridHeight, setGridHeight] = React.useState<number>(250);
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const referenceRecords = useSelector(selectReferenceAndEmploymentVerificationRecords);
  const [referanceRecordWithMonth, setReferanceRecordWithMonth] = React.useState<any>([]);
  const displayMonthsGrid = validateMonthsDisplay(orderReqItem, requirementCheckFlag);
  // extractReferenceAndEmploymentVerifications
  const dispatch = useDispatch();
  const numberOfMonth = orderReqItem ? orderReqItem?.data?.withinNumberOfMonths : 0;

  const openModal = (sectionName, category) => {
    if (sectionName === 'References' || sectionName === 'Employment Verifications') {
      setSectionName(sectionName);
      setFilterModel(getPlacementReferencesFilterModel(sectionName, category));
      setOpenReferenceModal(true);
      setCategoryName(category);
    }
  };

  const getDocumentHeight = () => {
    var body = document.body,
      html = document.documentElement;

    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    return height;
  };

  useEffect(() => {
    if (requirementCheckFlag) {
      const filteredRecords = referenceRecords.filter(
        record =>
          record?.withInLastNMonthsTimeframe &&
          record?.withInLastNMonthsTimeframe === true &&
          record?.referenceTypeId !== null,
      );
      if (filteredRecords?.length > 0) {
        setReferanceRecordWithMonth(filteredRecords);
      }
    }
  }, [referenceRecords]);

  useEffect(() => {
    if (referencesData && referencesData?.candidateReferenceDetailResponses)
      dispatch(
        placementSummaryActions.setReferenceAndEmploymentVerificationRecords(
             extractReferenceAndEmploymentVerifications(referencesData?.candidateReferenceDetailResponses)
        ),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referencesData?.orderId]);

  const resizeGrid = React.useCallback(() => {
    if (expanded) {
      const height = getDocumentHeight();
      const documentMinusDialogHeaderHeight = 170;
      requirementCheckFlag
        ? setGridHeight(height - documentMinusDialogHeaderHeight)
        : setGridHeight((height - documentMinusDialogHeaderHeight) / 2);
    }
  }, [expanded]);

  React.useEffect(() => {
    resizeGrid();
    window.addEventListener('resize', resizeGrid, true);
    return () => {
      window.removeEventListener('resize', resizeGrid);
    };
  }, [resizeGrid]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ListSection
            listType={'placementCandidateReferences'}
            items={[referencesData?.references] ?? []}
            openModal={openModal}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ListSection
            listType={'placementCandidateReferences'}
            items={[referencesData?.employmentVerifications] ?? []}
            openModal={openModal}
          />
        </Grid>
      </Grid>

      <GenericDialog
        open={openReferenceModal}
        draggable
        disableEnforceFocus
        onClose={() => setOpenReferenceModal(false)}
        dialogTitleProps={{
          text: t('placement.profile.summary.submission.order.reference.referenceModalTitle'),
          expandable: true,
          closeButton: true,
          onExpandChange: expanded => {
            setExpanded(expanded);
            if (expanded) resizeGrid();
          },
        }}
        fullWidth
        maxWidth={'md'}
        variant="blue"
      >
        <div style={{ height: '500px' }}>
          {displayMonthsGrid && (
            <ReferencesModal
              sectionName={sectionName}
              referenceRecords={referanceRecordWithMonth}
              filterModel={filterModel}
              onFilterModelChange={setFilterModel}
              primaryModal={true}
              gridLabel={true}
              numberOfMonth={numberOfMonth}
              refreshData={() => {
                dispatch(placementSummaryActions.getReferenceAndEmploymentVerificationRecords({placementId}));
                openModal(sectionName, categoryName);
              }}
              serviceTrackerId={'placement-submission-reference-emp-verfication-records'}
            />
          )}
          <ReferencesModal
            sectionName={sectionName}
            referenceRecords={referenceRecords}
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            primaryModal={false}
            gridLabel={!displayMonthsGrid}
            refreshData={() => {
              dispatch(placementSummaryActions.getReferenceAndEmploymentVerificationRecords({placementId}));
              openModal(sectionName, categoryName);
            }}
            serviceTrackerId={'placement-submission-reference-emp-verfication-records'}
          />
        </div>
      </GenericDialog>
    </>
  );
};
