import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { call, fork, getContext, put, all, select } from 'redux-saga/effects';
import { SetPlacementFailRiskRequirementMessage } from 'store/redux-store/new-placement/saga';
import { placementStatusAction } from 'store/redux-store/placement-status/slice';
import InitPlacementSummary, { IConsolidatedRequirementResponse } from '../init.types';
import { IPlacementSummaryRequest, placementSummaryActions } from '../slice';
import { selectPlacementConsolidatedDetails } from '../consolidatedStoreSelectors';

export function* processBookingRequirements(
  response: IConsolidatedRequirementResponse<any>,
  context: InitPlacementSummary,
) {
  try {
    if (!httpSuccess(response?.status)) {
      context.setServiceFailureByKey({ key: 'bookingRequirements', failureStatus: true });
      return;
    }
    const { clientConfirmation, candidateContract, nextStatusRequirement = [] } = response.data ?? {};
    yield fork(common, { clientConfirmation, candidateContract, nextStatusRequirement });
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'processBookingRequirements',
        area: 'src/store/redux-store/placement-summary/common-saga/bookingRequirements.ts',
      },
    });
  }
}

export function* requestBookingRequirements(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if(action.payload.isInitLoad && consolidatedDetails){
      response = {
        status : consolidatedDetails.summary.booking?.bookedStatusDetails?.status,
        data :consolidatedDetails.summary.booking?.bookedStatusDetails?.data
      }
    }

    if(!response?.status || !response?.data){
      response = yield call(
        placementService.getBookingRequirements,
        action.payload.placementId,
        action.payload.placementOrderType,
      );
    }
    
    if (httpSuccess(response?.status)) {
      const { clientConfirmation, candidateContract, nextStatusRequirement = [] } = response.data ?? {};
      /** Placeholder Store state into redux */

      yield fork(common, { clientConfirmation, candidateContract, nextStatusRequirement });
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestBookingRequirements',
        area: 'src/store/redux-store/placement-summary/common-saga/bookingRequirements.ts',
      },
    });
  }
}

function* common({ clientConfirmation, candidateContract, nextStatusRequirement }) {
  /** Clear all errors for -  getBookingApprovedRequirements */
  yield all(
    [IPlacementRequirementKey.bookingClientConfirmation, IPlacementRequirementKey.bookingCandidateContract].map(id =>
      put(
        placementStatusAction.removeFailedRequirement({
          requirement: { id },
        }),
      ),
    ),
  );

  if (clientConfirmation)
    yield fork(SetPlacementFailRiskRequirementMessage, {
      payload: {
        messageId: clientConfirmation.messageId,
        version: clientConfirmation.messageVersion,
        passFailType: clientConfirmation.type,
        data: undefined, // clientConfirmation.data?.isClientConfirmationSent,
        messageTypeId: IPlacementRequirementKey.bookingClientConfirmation,
        tooltipTypeParam: undefined,
        nextStatusRequirementParam: nextStatusRequirement,
      },
      type: placementSummaryActions.setFailedRequirement.type,
    });

  if (candidateContract)
    yield fork(SetPlacementFailRiskRequirementMessage, {
      payload: {
        messageId: candidateContract.messageId,
        version: candidateContract.messageVersion,
        passFailType: candidateContract.type,
        data: undefined, // candidateContract.data?.isRequestContractStatus,
        messageTypeId: IPlacementRequirementKey.bookingCandidateContract,
        tooltipTypeParam: undefined,
        nextStatusRequirementParam: nextStatusRequirement,
      },
      type: placementSummaryActions.setFailedRequirement.type,
    });
}
