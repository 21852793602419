import { Grid, Paper } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { IPlacementStatus, PlacementOrderType } from 'app/models/Placement/PlacementDetails';
import { selectUser } from 'oidc/user.selectors';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { StatusChangeConfirmDialog } from '../../NextPlacementStatus/StatusChangeConfirmDialog';
import { Risks } from '../../PlacementDetails/PlacementTabPanel/PlacementSummaryTab/Risks';
import { selectPlacementStatus } from '../../../../../store/redux-store/placement-status/selectors';
import { getSaveRequest, getUpdateRequest } from '../helper';
import { newPlacementActions } from '../../../../../store/redux-store/new-placement/slice';
import {
  selectCreatePlacementOrderType,
  selectNewPlacementChoice,
  selectNewPlacementId,
} from '../../../../../store/redux-store/new-placement/selectors';
import { SelectPlacementStatus } from './SelectPlacementStatus';
import { DefaultCurrentPlacementStatus, isNegativeStatus } from '../../NextPlacementStatus/StatusDefaults';
import { WhatsNext } from './WhatsNext';

const useStyles = makeStyles()({
  root: {
    maxHeight: 380,
    minHeight: 380,
    overflowX: 'hidden',
  },
});

export const CreatePlacementStatus = () => {
  const {
    currentPlacementStatus = DefaultCurrentPlacementStatus,
    availablePlacementStatus = [],
    failedRequirements,
    updatePlacementStatusResponse,
  } = useSelector(selectPlacementStatus);

  const { candidate, order } = useSelector(selectNewPlacementChoice);
  const newPlacementId = useSelector(selectNewPlacementId);
  const placementOrderType = useSelector(selectCreatePlacementOrderType);
  const { userInfo } = useSelector(selectUser);
  const { getValues, formState } = useFormContext();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { promiseInProgress: gettingCandidate } = usePromiseTracker({
    area: 'new-placement-get-candidate',
    delay: 0,
  });
  const { promiseInProgress: gettingOrder } = usePromiseTracker({ area: 'new-placement-get-order', delay: 0 });
  const { promiseInProgress: isSubmitting } = usePromiseTracker({ area: 'new-placement-submit', delay: 0 });
  const { promiseInProgress: isValidating } = usePromiseTracker({ area: 'new-placement-get-validations', delay: 0 });

  const [selectedStatus, setSelectedStatus] = React.useState<IPlacementStatus | undefined>();
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState<boolean>(false);

  const onStatusSelected = (status: IPlacementStatus) => {
    setSelectedStatus(status);
    setConfirmDialogOpen(true);
  };

  const submitStatusChange = (status: IPlacementStatus) => {
    if (isNegativeStatus(status)) {
      // Update status to declined. Save any fields that were modified
      if ((newPlacementId ?? 0) > 0)
        dispatch(
          newPlacementActions.declinePlacement({
            placementId: newPlacementId ?? -1,
            placementStatus: status,
            placementOrderType: placementOrderType || PlacementOrderType.Default,
            candidateId: candidate?.candidateId ?? -1,
            placementUpdate: formState.isDirty
              ? {
                  updates: getUpdateRequest(
                    getValues() as any,
                    Object.keys(formState.dirtyFields),
                    userInfo?.employeeId,
                    newPlacementId,
                  ),
                }
              : undefined,
          }),
        );
    } else {
      const candidateValue = {
        object: {
          candidateId: candidate?.candidateId,
          brandId: candidate?.brandId,
        },
      };
      dispatch(
        newPlacementActions.savePlacementAction(
          getSaveRequest({
            ...(getValues() as any),
            startDate: order?.startDate,
            endDate: order?.endDate,
            userId: userInfo?.employeeId,
            status,
            placementOrderType: placementOrderType ?? PlacementOrderType.Default,
            candidate: getValues('candidate') ?? candidateValue,
          }),
        ),
      );
    }
  };

  const onCloseDialog = React.useCallback(() => {
    setConfirmDialogOpen(false);
    setSelectedStatus(undefined);
  }, []);

  React.useEffect(() => {
    if (updatePlacementStatusResponse?.success) {
      // successfully created/updated placement
      onCloseDialog();
      if (updatePlacementStatusResponse.props?.placementCreated && newPlacementId !== -1) {
        // new placement created
        dispatch(
          globalActions.setSnackBar({
            message: t('placement.create.snackbar.success', { placementId: newPlacementId }),
            severity: 'success',
            portalRefId: 'create-placement-dialog',
          }),
        );
      } else {
        // placement updated
        dispatch(
          globalActions.setSnackBar({
            message: t('placement.profile.placementStatusUpdate.successSnackbar'),
            severity: 'success',
            portalRefId: 'update-placement-dialog',
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePlacementStatusResponse, newPlacementId]);

  return (
    <React.Fragment>
      <Grid item xs={4}>
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <SelectPlacementStatus
              loading={gettingCandidate || gettingOrder || isSubmitting || isValidating}
              currentPlacementStatus={currentPlacementStatus}
              availablePlacementStatus={candidate && order ? availablePlacementStatus : []}
              failedRequirements={failedRequirements}
              onStatusSelected={onStatusSelected}
            />
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0} classes={{ root: classes.root }}>
              <Grid container spacing={2} style={{ overflow: 'auto' }}>
                <Grid item xs={12}>
                  <Risks expanded={false} loading={isValidating} />
                </Grid>
                <Grid item xs={12}>
                  <WhatsNext
                    loading={gettingCandidate || gettingOrder || isSubmitting || isValidating}
                    availablePlacementStatus={candidate && order ? availablePlacementStatus : []}
                    failedRequirements={failedRequirements}
                    currentPlacementStatus={currentPlacementStatus}
                    onStatusSelected={onStatusSelected}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      {selectedStatus && (
        <StatusChangeConfirmDialog
          open={confirmDialogOpen}
          status={selectedStatus}
          submitting={isSubmitting}
          error={updatePlacementStatusResponse && updatePlacementStatusResponse.success === false}
          type={updatePlacementStatusResponse?.successType}
          onSubmit={submitStatusChange}
          onClose={onCloseDialog}
          closeButton={false}
        />
      )}
    </React.Fragment>
  );
};
