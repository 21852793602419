import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { useWatch } from 'react-hook-form';
import { useEditableRequirement } from './EditRequirementsHelper';
import { TransportationCheck } from './EditablePassFailLogic';

export const useEditableRequirementTransportation = () => {
  // DEPARTURE AIRPORT
  const transportation: any = useWatch({ name: 'transportationChosen' });
  useEditableRequirement({
    data: transportation?.transportation,
    requirementId: IPlacementRequirementKey.transportation,
    failRequirement: {
      id: IPlacementRequirementKey.transportation,
      message: 'Transportation: Drive or Flight',
      tooltip: {
        message: 'Transportation should be either Drive or Flight',
      },
    },
    requirementKey: 'transportation',
    requirementCheck: TransportationCheck,
  });
};
