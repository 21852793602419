import { Divider, Grid, Theme, Typography } from 'amn-ui-core';
import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { CONSTANTS_NOTIFICATIONS } from '../../Common/FormatData';
const useStyles = makeStyles<{ padding?: number }>()((theme: Theme, { padding }) => ({
  root: {
    height: '100%',
    width: '100%',
    margin: '0px',
    padding: '0px',
  },
  item: {
    width: '100%',
    backgroundColor: '#52429A',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
  },
  itemContent: {
    position: 'relative',
    font: '12px',
    display: 'flex',
    backgroundColor: '#52429A',
    width: '100%',
    height: '30px',
    paddingLeft: `${padding}px`,
    margin: '0px',
    zIndex: 5,
    '&:hover': {
      backgroundColor: '#372C67 !important',
    },
  },
  itemContentMarkAsRead: {
    font: '12px',
    display: 'flex',
    opacity: 0.5,
    width: '100%',
    height: '30px',
    paddingLeft: `${padding}px`,
    margin: '0px',
  },
}));
export const ActionPanel = ({ renderList, onClickActionMenu, setopenGoToPopper, isUnread }) => {
  let padding;
  const CHARACTER_LENGTH = 10;
  if (renderList[1].name.length > CHARACTER_LENGTH) {
    padding = 6;

  } else {
    padding = 12;
  }
  const { classes } = useStyles({ padding });
  useEffect(() => {
    return () => {
      setopenGoToPopper(false);
    };
  }, []);

  return (
    <Grid container classes={{ root: classes.root }}>
      <Grid item classes={{ root: classes.item }} xs={12}>
        {renderList.map((item, key) => {
          if (!isUnread && item.name === CONSTANTS_NOTIFICATIONS.ACTION_PANEL.MARK_READ) {
            return (
              <Grid
                className={classes.itemContentMarkAsRead}
                key={key}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <Typography style={{ fontSize: '12px', paddingTop: '7px' }}>{item.name}</Typography>
                <span style={{ marginLeft: 'auto', justifyContent: 'center', alignContent: 'center' }}>
                  {item?.seconadryComponent ? <item.seconadryComponent /> : null}
                </span>
                <Divider />
              </Grid>
            );
          }
          return (
            <Grid
              className={classes.itemContent}
              key={key}
              onClick={e => onClickActionMenu({ name: item.name, event: e })}
            >
              <Typography style={{ fontSize: '12px', paddingTop: '7px' }}>{item.name}</Typography>
              <span style={{ marginLeft: 'auto', justifyContent: 'center', alignContent: 'center' }}>
                {item?.seconadryComponent ? <item.seconadryComponent /> : null}
              </span>
              <Divider />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
