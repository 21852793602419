/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Grid, materialUiXGrid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { Expandable } from 'app/components/Common/Expandable/Expandable';
import { Title } from 'app/components/Order/OrderDetails/OrderPreferences/customComponents/OrderPreferencesDesign';
import { useTranslation } from 'react-i18next';
import { XGrid } from 'app/components/Common/XGrid/XGrid';
import { useSelector } from 'react-redux';
import {
  selectContractDetails,
  selectRateElements,
  selectSpecialtySubSpecialty,
} from 'app/components/Facility/FacilityStore/Facility.selector';
import { setRateElementColumns } from './RateElement';
import { missingField } from 'app/constants';
const useStyles = makeStyles()({
  gridComponent: {
    height: '50rem',
    border: 'none',
    fontSize: '14px',
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: '#F5F5F5',
    },
  },
  boxComponent: {
    height: 'auto',
  },
});

export const RateElements = props => {
  const gridApi = materialUiXGrid.useGridApiRef();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const rateElements = useSelector(selectRateElements);
  const contract = useSelector(selectContractDetails);
  const specSubSpec = useSelector(selectSpecialtySubSpecialty);
  const [columns, setColumns] = React.useState<any>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [sortModel, setSortModel] = React.useState<any>();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(50);

  const onSortModelChange = model => {
    setSortModel(model);
  };

  const fetchRowData = () => {
    if (rateElements?.rateElements) {
      const data = rateElements.rateElements.map((item, index) => ({
        ...item,
        status: contract?.status,
        id: index + 1,
      }));
      setRows(data);
    }
  };
  const fetchRateElementsColumns = () => {
    setRows([]);
    !rateElements?.shifts
      ? setColumns(setRateElementColumns([]))
      : setColumns(setRateElementColumns(rateElements?.shifts));
  };

  const onPageChange = (page: number) => {
    setPageNumber(page);
    props.setPageChanged(true);
  };
  const onPageSizeChange = (size: number) => {
    setPageSize(size);
    props.setPageChanged(true);
  };

  useEffect(() => {
    fetchRowData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  useEffect(() => {
    fetchRateElementsColumns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateElements]);

  const Grid2 = React.useMemo(() => {
    return (
      <XGrid
        apiRef={gridApi}
        page={pageNumber}
        pageSize={pageSize}
        defaultRowsPerPage={50}
        paginationMode={'client'}
        columns={columns}
        rows={rows || []}
        density="standard"
        className={classes.gridComponent}
        headerHeight={48}
        rowHeight={39}
        rowCountDisplay={undefined}
        disableColumnSelector
        disableSelectionOnClick
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        disableTitleCount={true}
        title={''}
        toolbar={false}
        autoHeight={true}
        hideFooter={!rows || rows?.length < 1}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, rows, onPageChange, onPageSizeChange, pageSize, pageNumber]);

  return (
    <React.Fragment>
      <Expandable
        expanded
        titleComponent={
          <Title>
            {t('order.facility.rateCard.rateElements.title')}
            {specSubSpec?.disciplineAbbreviation ? ` - ${specSubSpec.disciplineAbbreviation}` : ''}
            {specSubSpec?.specailty ? `, ${specSubSpec.specailty}` : ''}
            {specSubSpec?.subSpecialty && specSubSpec?.subSpecialty !== missingField
              ? `, ${specSubSpec.subSpecialty}`
              : ''}
          </Title>
        }
        unmountOnExit={false}
      >
        <Box className={classes.boxComponent}>{Grid2}</Box>
      </Expandable>
    </React.Fragment>
  );
};

export const RateElementTableCell = param => {
  return <Grid style={{ fontWeight: 600, fontSize: '14px' }}>{param}</Grid>;
};
