import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { Grid, Typography } from 'amn-ui-core';
import { CustomCalendar } from 'app/ComponentLibrary/Filter';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useDispatch, useSelector } from 'react-redux';
import { selectSearchResults } from 'store/redux-store/global-search/selectors';
import { selectGridSelection } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.selector';
import { differenceInWeeks } from 'date-fns';
import { updateOrderDates } from 'app/services/OrderServices/OrderServices';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { selectGridState } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';
import { selectUser } from 'oidc/user.selectors';
import moment from 'moment';
interface IUpdateOrderDatesModalProps {
  open: boolean;
  orderStartDate?: Date;
  orderEndDate?: Date;
  onClose: () => void;
  handleFetch: any;
}
const useStyles = makeStyles()(theme => ({
  dialog: {
    minWidth: '600px',
    '& .MuiDialog-paperWidthSm': {
      minWidth: '600px',
      maxWidth: '100%',
    },
  },
  dialogueContent: {
    padding: 0,
    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .dialogContentRoot': {
      padding: 0,
    },
  },
  alertBox: {
    fontWeight: 400,
    width: '100%',
    height: 70,
    padding: '20px 30px',
    textAlign: 'center',
    backgroundColor: '#FFF4E5',
  },
  datesContainer: {
    padding: '0 8px',
  },
  icon: {
    color: '#FFA22E',
  },
}));
const UpdateOrderDatesModal = (props: IUpdateOrderDatesModalProps) => {
  const [startDate, setStartDate] = React.useState<any>(props.orderStartDate || null);
  const [endDate, setEndDate] = React.useState<any>(props.orderEndDate || null);
  const [error, setError] = React.useState<boolean>(false);
  const [disabled, setDisabled] = React.useState<boolean>(true);
  const { items } = useSelector(selectSearchResults);
  const { selectedData } = useSelector(selectGridSelection);
  const gridState = useSelector(selectGridState);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const { classes } = useStyles();

  React.useEffect(() => {
    if (props?.open) {
      setStartDate(props?.orderStartDate || null);
      setEndDate(props?.orderEndDate || null);
    }
  }, [props]);

  React.useEffect(() => {
    if (startDate && endDate) {
      const isEqual =
        new Date(startDate)?.getTime() === new Date(props.orderStartDate)?.getTime() &&
        new Date(endDate)?.getTime() === new Date(props.orderEndDate)?.getTime();
      const isDateValid = new Date(startDate)?.getTime() <= new Date(endDate)?.getTime();
      if (!isEqual) {
        if (isDateValid) {
          setDisabled(false);
          setError(false);
        } else {
          setError(true);
          setDisabled(true);
        }
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }
  }, [startDate, endDate]);

  const handleCloseModal = (e, reason) => {
    if (reason !== 'backdropClick') {
      handleClose();
    }
  };

  const handleClose = () => {
    setDisabled(true);
    setError(false);
    props.onClose();
  };

  const handleUpdateOrderDates = async () => {
    const selections = items.filter(row => selectedData.includes(row.id));
    const orders = selections?.map(item => ({ orderId: item?.orderId, typeId: item?.orderTypeId }));
    const orderStartDate = moment(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
    const orderEndDate = moment(endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
    const numOfWeeks = differenceInWeeks(new Date(orderEndDate), new Date(orderStartDate), { roundingMethod: 'round' });
    const payload = {
      orders,
      startDate: orderStartDate,
      endDate: orderEndDate,
      numOfWeeks,
      currentUserId: user.userInfo.employeeId,
    };
    try {
      setDisabled(true);
      const response = await updateOrderDates(payload);
      props?.handleFetch(gridState.pageSize, gridState.pageNumber, gridState.sortedColumn);
      dispatch(
        globalActions.setSnackBar({
          message: `${response.data.message}`,
          severity: 'success',
        }),
      );
      handleClose();
    } catch (err) {
      dispatch(
        globalActions.setSnackBar({
          message: `${err.message}`,
          severity: 'success',
        }),
      );
    }
  };
  const handleUpdateDateChanges = (date: any, range: 'startDate' | 'endDate') => {};
  return (
    <div>
      <GenericDialog
        open={props?.open}
        onClose={(e, reason) => handleCloseModal(e, reason)}
        dialogTitleProps={{
          id: 'update-order-dates-modal',
          text: 'Update Start and End Dates',
          closeButton: true,
          expandable: true,
        }}
        variant="blue"
        dialogActions={[
          {
            text: 'Cancel',
            color: 'tertiary',
            variant: 'contained',
            useDialogOnCloseEvent: true,
            id: 'cancel-btn',
            'data-testid': 'cancel-btn',
          },
          {
            text: 'Save',
            variant: 'contained',
            color: 'primary',
            onClick: handleUpdateOrderDates,
            id: 'save-btn',
            disabled: disabled,
            'data-testid': 'save-btn',
            tooltipProps: {
              tooltipContent: 'Order Start Date and Order End Date are required',
              disabled: !disabled,
            },
          },
        ]}
        className={classes.dialog}
        dialogContentProps={{
          classes: {
            root: classes.dialogueContent,
          },
        }}
      >
        <div className={classes.alertBox} data-testId="body-msg" id="body-msg">
          <Typography color="#663B01">
            <span style={{ margin: 5, textAlign: 'center' }}>
              <WarningAmberIcon className={classes.icon} />
            </span>
            This action will only apply to selected HSG Orders. To continue, please populate both date fields.
          </Typography>
        </div>
        <Grid
          container
          spacing={2}
          minWidth={600}
          className={classes.datesContainer}
          data-testId="dates-container"
          id="dates-container"
        >
          <Grid item xs={6}>
            <div style={{ minWidth: '265px', marginTop: '37px', marginLeft: 20 }}>
              <Typography fontWeight={600}>{`Start Date`}</Typography>
              <CustomCalendar
                fullwidth
                onChange={e => {
                  setStartDate(e);
                  handleUpdateDateChanges(e, 'startDate');
                }}
                textFieldVariant={'filled'}
                label="Start Date *"
                value={startDate}
                hasHelperText={true}
                errorObject={error ? { message: 'Start Date cannot be after End Date' } : null}
                data-testId="start-date"
                id="start-date"
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ minWidth: '265px', marginTop: '37px', marginRight: 20 }}>
              <Typography fontWeight={600}>{`End Date`}</Typography>
              <CustomCalendar
                fullwidth
                onChange={e => {
                  setEndDate(e);
                  handleUpdateDateChanges(e, 'endDate');
                }}
                textFieldVariant={'filled'}
                label="End Date *"
                value={endDate}
                data-testId="end-date"
                id="end-date"
              />
            </div>
          </Grid>
        </Grid>
      </GenericDialog>
    </div>
  );
};

export default UpdateOrderDatesModal;
