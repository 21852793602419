import { Collapse, Grid } from 'amn-ui-core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getSortedData, getUnique } from 'app/helpers/arrayHelpers';
import {
  selectFilters,
  selectGridFiltersExpanded,
  selectGridState,
} from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import { useDispatch, useSelector } from 'react-redux';

import { CustomFilter } from 'app/ComponentLibrary/Filter/CustomFilter';
import { FilterType } from 'app/enums/Common';
import { ILookupOptions } from 'store/redux-store/lookup/initialState';
import { IStateCityZip } from 'app/models/Candidate/IStateCityZip';
import { OrderFilterSpecs } from './OrdersFilterUtils';
import { PositionOpts } from '../../Common/OptionsHelper';
import { SearchPageIcons } from 'app/ComponentLibrary/Filter/SearchPageIcons';
import { SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import { Theme } from 'amn-ui-core';
import { getAllCitiesAndZips } from '@AMIEWEB/GlobalSearch/Candidate/FilterUtils';
import { getCitiesAndZipsByStates } from 'app/services/SharedServices/SharedServices';
import { gridStateActions } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { initialFilterOrderChips } from '../../Common/Constants';
import { lookupOptionSelector } from 'store/redux-store/lookup/lookup.selectors';
import { makeStyles } from 'tss-react/mui';
import { trackPromise } from 'react-promise-tracker';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()((theme: Theme) => ({
  filterCollapse: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '3px',
  },
  collapse: {
    paddingTop: '4px',
  },
}));

export const OrderSearchFilter = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { reset, getValues } = useFormContext();
  const [allSpecialities, setAllSpecialities] = useState<any[]>([]);
  const [allSubSpecialities, setAllSubSpecialities] = useState<any[]>([]);
  const gridStateFilters = useSelector(selectFilters);
  const gridState = useSelector(selectGridState);
  // const placementDetails = useSelector(selectPlacementDetails);
  const loadedData = useSelector(lookupOptionSelector) as ILookupOptions;
  const allCitiesandZipsData = useRef<IStateCityZip[]>();
  const filtersExpanded = useSelector(selectGridFiltersExpanded);

  const handleChange = () => {
    dispatch(gridStateActions.setFiltersExpanded(!filtersExpanded));
  };

  const gridSpecs = useRef(
    new OrderFilterSpecs(
      {
        divisionOpts: [],
        disciplineOpts: [],
        specialtiesOpts: [],
        subSpecialtiesOpts: [],
        facilityStatusesOpts: [],
        orderTypesOpts: [],
        orderPrioritiesOpts: [],
        statesOpts: [],
        compactStatesOpts: [],
        shiftOpts: [],
        accountManagerOpts: [],
        cityOptions: [],
      },
      gridStateFilters,
    ),
  );
  const [fields, setFields] = React.useState(gridSpecs.current.getOrderFilterSpecs());

  const getMultiSelectOptions = (options, labelPrefix?, labelPostfix?, withoutAllOption?: boolean) => {
    const opts = (options || []).map((opt, index) => ({
      id: index + 1,
      labelPrefix,
      labelPostfix,
      ...opt,
    }));
    if (!withoutAllOption) opts.unshift({ id: 0, name: 'All', value: 'All', labelPrefix });
    return opts;
  };
  const getDisciplinesMultiSelectOptions = options => {
    const array = options?.reduce(
      (resultArray, item) => [
        ...resultArray,
        {
          value: item?.value?.slice(item?.value?.indexOf('_') + 1),
          name: item.name,
          corelation: item?.value,
        },
      ],
      [],
    );
    const sortedData = getSortedData(array || [], FilterType.string);
    return sortedData;
  };

  const findspecialities = disciplines => {
    const specialtiesArray: any[] = [];
    if (disciplines?.length > 0) {
      disciplines?.forEach(item => {
        const specialties = allSpecialities.filter(
          curr => curr?.corelation?.slice(0, curr?.corelation?.indexOf('_')) === item?.value,
        );
        if (specialties) {
          specialtiesArray.push(...specialties);
        }
      });
      const sortedData = getSortedData(specialtiesArray, FilterType.string);
      const uniqueSpecialities = getUnique(
        sortedData?.filter(x => x.name),
        'name',
      );
      if (uniqueSpecialities?.length >= 1) {
        gridSpecs.current.filterSpecs.specialtiesOpts = getMultiSelectOptions(uniqueSpecialities);
        const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
        setFields(fieldsSchema);
        findSubSpecialities(specialtiesArray);
      } else {
        gridSpecs.current.filterSpecs.subSpecialtiesOpts = [];
        gridSpecs.current.filterSpecs.specialtiesOpts = getMultiSelectOptions(uniqueSpecialities);
        const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
        setFields(fieldsSchema);
      }
    } else {
      gridSpecs.current.filterSpecs.specialtiesOpts = getUnique(
        allSpecialities?.filter(x => x.name),
        'name',
      );
      const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
      setFields(fieldsSchema);
      findSubSpecialities([]);
    }
  };

  const findSubSpecialities = specialities => {
    const subSpecialtiesArray: any[] = [];
    if (specialities?.length > 0) {
      specialities?.forEach(item => {
        allSubSpecialities?.forEach(curr => {
          if (
            curr?.corelation?.substr(0, curr?.corelation?.lastIndexOf('_')) ===
            item?.corelation?.slice(item?.corelation?.indexOf('_') + 1)
          ) {
            if (curr?.name !== 'All') {
              subSpecialtiesArray.push(curr);
            }
          }
        });
      });
      const sortedData = getSortedData(subSpecialtiesArray, FilterType.string);
      const uniqueSubSpecialities = getUnique(
        sortedData?.filter(x => x.name),
        'name',
      );
      gridSpecs.current.filterSpecs.subSpecialtiesOpts = getMultiSelectOptions(uniqueSubSpecialities);
      const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
      setFields(fieldsSchema);
    } else {
      gridSpecs.current.filterSpecs.subSpecialtiesOpts = getUnique(
        allSubSpecialities?.filter(x => x.name),
        'name',
      );
    }
  };

  const fetchOrderFilterSpecs = async () => {
    const divisionOpts = getMultiSelectOptions(loadedData?.divisionOptions || []);
    const disciplineOpts = getMultiSelectOptions(loadedData?.disciplineOptions || []);
    const allSpecialtiesOpts = getMultiSelectOptions(
      getDisciplinesMultiSelectOptions(loadedData?.specialtiesOptions || []),
    );
    const specialtiesOpts = getUnique(
      allSpecialtiesOpts?.filter(x => x.name),
      'name',
    );
    setAllSpecialities(allSpecialtiesOpts);

    const allSubSpecialtiesOpts = getMultiSelectOptions(
      getDisciplinesMultiSelectOptions(loadedData?.subSpecialtiesOptions) || [],
    );
    const subSpecialtiesOpts = getUnique(
      allSubSpecialtiesOpts?.filter(x => x.name),
      'name',
    );
    setAllSubSpecialities(allSubSpecialtiesOpts);

    const facilityStatusesOpts = getMultiSelectOptions(loadedData?.facilityStatusOptions || []);
    const orderTypesOpts = getMultiSelectOptions(loadedData?.orderTypeOptions || []);
    const orderPrioritiesOpts = getMultiSelectOptions(loadedData?.orderPriorityOptions || []);
    const statesLookups = loadedData?.stateOptions;
    const states = getMultiSelectOptions(statesLookups || []);
    const compactStatesOpts = loadedData?.compactStateOptions;
    const compactStates = compactStatesOpts?.map(item => {
      const stateAbbrs = item.name?.split(',');
      return {
        name: item?.value?.replace('?', "'")?.replace('Only ', '')?.replace('LIC', 'Licence'),
        value: states.filter(s => stateAbbrs.includes(s.value)),
        isCompactOpt: true,
      };
    });
    const statesOpts = getMultiSelectOptions([...compactStates, ...statesLookups] || []);
    const shiftOpts = getMultiSelectOptions(loadedData?.shiftListOptions || []);
    const accountManagerOpts = getMultiSelectOptions(loadedData?.accountManagerOptions || []);

    allCitiesandZipsData.current = (await getCitiesAndZipsByStates({
      states: [statesLookups?.[0]?.name],
      cities: undefined,
    })) as IStateCityZip[];
    const AllCitiesAndZips = getAllCitiesAndZips(allCitiesandZipsData.current);
    const cityOpts = AllCitiesAndZips?.cities;

    gridSpecs.current.filterSpecs = {
      divisionOpts,
      disciplineOpts,
      specialtiesOpts,
      subSpecialtiesOpts,
      facilityStatusesOpts,
      orderTypesOpts,
      orderPrioritiesOpts,
      statesOpts,
      compactStatesOpts,
      shiftOpts,
      accountManagerOpts,
      cityOptions: cityOpts,
    };
    const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
    setFields(fieldsSchema);
  };

  const updateGridState = useCallback(
    async (selectedStates: string[], selectedCities: string[], gridStateFilters: any) => {
      if (!gridStateFilters || !allCitiesandZipsData.current) return;
      let filteredCities: IStateCityZip[];
      if (!selectedStates.length) filteredCities = allCitiesandZipsData.current;
      else {
        filteredCities = (await getCitiesAndZipsByStates({
          states: selectedStates,
          cities: [],
        })) as IStateCityZip[];
      }
      gridSpecs.current.filterSpecs.cityOptions = getAllCitiesAndZips(filteredCities).cities;
      gridSpecs.current.gridStateFilters = gridStateFilters;
      const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
      setFields(fieldsSchema);
    },
    [],
  );
  const defaultAppliedFilters = {
    ...initialFilterOrderChips,
    positions: PositionOpts[1],
  };

  const resetForm = () => {
    reset({ ...defaultAppliedFilters });
    dispatch(
      gridStateActions.setFilterAttributes({
        ...gridState.filterAttributes,
        filterObject: Object.entries(defaultAppliedFilters),
        selectedChipFilters: {},
      }),
    );
    props.onApplyFilter(defaultAppliedFilters);
  };

  useEffect(() => {
    findspecialities(gridStateFilters?.filters?.disciplineIds);
  }, [gridStateFilters?.filters?.disciplineIds]);

  useEffect(() => {
    if (gridStateFilters?.filters?.specialtyIds) {
      findSubSpecialities(gridStateFilters?.filters?.specialtyIds);
    } else {
      findspecialities(gridStateFilters?.filters?.disciplineIds);
    }
  }, [gridStateFilters?.filters?.specialtyIds]);

  useEffect(() => {
    if (!gridStateFilters || !allCitiesandZipsData.current) return;
    const selectedStates = !(gridStateFilters?.filters?.states as any[])?.length
      ? []
      : (gridStateFilters?.filters?.states as any[]).reduce(
          (result, item) => (!item?.isCompactOpt ? [...result, item] : result),
          [],
        );

    const selectedCities = (
      !(gridStateFilters?.filters?.city as any[])?.length ? [] : gridStateFilters?.filters?.city
    ) as any[];
    updateGridState(
      selectedStates.map(x => x.name),
      selectedCities.map(x => x.name),
      gridStateFilters,
    );
  }, [gridStateFilters, updateGridState]);

  useEffect(() => {
    trackPromise(fetchOrderFilterSpecs(), 'gloabl-search-call');
    resetForm();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={10.8}>
        <Collapse timeout={200} in={filtersExpanded} collapsedSize={45} className={classes.collapse}>
          <CustomFilter
            fields={fields}
            justifyContent={'flex-start'}
            version2
            resetBtn
            disableFormSubmit
            analytics={{
              caller: t('Order Bonus Copy Search'),
              onApply: true,
              properties: {
                category: SearchType.order,
              },
            }}
            onApply={() => {
              props.onApplyFilter(getValues());
            }}
          />
        </Collapse>
      </Grid>
      <Grid item xs={1.2} className={classes.filterCollapse}>
        <SearchPageIcons
          resetlbutton
          funnelButton
          checked={filtersExpanded}
          onResetForm={() => resetForm()}
          onAdvancedFilterClick={() => handleChange()}
        />
      </Grid>
    </Grid>
  );
};
