import { OrderConstants } from 'app/constants/OrderCreationForm';

export const calculateIsCreditHold = (creditStatus: string | undefined) => {
  return creditStatus === OrderConstants.STATUS_ON_HOLD;
};

export const calculateIsCreditPending = (creditStatus: string | undefined) => {
  return creditStatus === OrderConstants.STATUS_PENDING;
};

export const getAddress = (location: { address1: string; city: string; state: string; zipCode: string }) => {
  let data = '';
  if (location?.zipCode) data = location.city + ', ' + location.state + ', ' + location.zipCode;
  else data = location?.city + ', ' + location?.state;
  return {
    addressLine1: location?.address1,
    addressLine2: data,
  };
};

export const calculateIsMaxBookingLimit = (bookingLimit: number, openOrderCount: number) => {
  return bookingLimit !== null && bookingLimit >= openOrderCount;
};

export const getValue = (value: boolean | null) => {
  if (value) return 'Y';
  if (value === false) return 'N';
  return '-';
};

export const getCreditCheck = (creditStatus: string) => {
  let isCreditHold = calculateIsCreditHold(creditStatus);
  let isCreditPending = calculateIsCreditPending(creditStatus);
  return getValue(isCreditHold || isCreditPending);
};
