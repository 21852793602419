import { createSelector } from '@reduxjs/toolkit';
import { PlacementOrderType } from 'app/models/Placement/PlacementDetails';
import { requirementType } from 'app/models/Placement/PlacementSummary';
import { RootState } from 'types';
import {
  selectBookingInfo,
  selectEditableFields,
  selectEditableRequirementTypes,
  selectOfferInfo,
  selectSubmissionInfo,
} from '../placement-summary/selectors';
import { initialState } from './slice';
import { selectPlacementConsolidatedDetails } from '../placement-summary/consolidatedStoreSelectors';

const selectDomain = (state: RootState) => state?.placementDetails || initialState;

export const selectPlacementDetails = createSelector([selectDomain], placementData => placementData);

export const selectPlacementTimestamp = createSelector([selectDomain], state => state.timestamp);

export const selectPlacementProgress = createSelector(
  [selectDomain],
  placementData => placementData.progress || initialState.progress,
);

export const selectPlacementHeaderData = createSelector(
  [selectDomain],
  placementData => placementData.header || initialState.header,
);

export const selectPlacementHeaderCanidate = createSelector(
  [selectDomain],
  placementData => placementData.header?.candidate || initialState.header.candidate,
);

export const selectQuickGlanceTilesData = createSelector(
  [selectDomain],
  placementData => placementData.quickGlance || initialState.quickGlance,
);

export const selectPlacementContactsData = createSelector(
  [selectDomain],
  placementData => placementData.contacts || initialState.contacts,
);

//to be refector
export const selectPlacementCandidateRecords = createSelector(
  [selectDomain],
  placementData => placementData.candidateRecords || initialState.candidateRecords,
);

export const selectPlacementModalCategoryData = createSelector(
  [selectDomain],
  placementData => placementData.modalData || initialState.modalData,
);

export const selectComparisonData = createSelector(
  [selectDomain],
  placementData => placementData.candidateRecords.comparisons,
);
export const selectPlacementLandsGtgDetails = createSelector(
  [selectDomain],
  placementData => placementData.landsGtgDetails || initialState.landsGtgDetails,
);

export const selectCredOneDetails = createSelector(
  [selectDomain],
  placementData => placementData.credOneDetails || initialState.credOneDetails,
);

export const selectAvoidPlacementParentForm = createSelector(
  [selectDomain],
  placementData => placementData.avoidPlacementParentForm || initialState.avoidPlacementParentForm,
);

export const selectIslandsCancel = createSelector(
  [selectDomain],
  placementData => placementData.isLandsCancel || initialState.isLandsCancel,
);

export const selectPassportCredentials = createSelector(
  [selectDomain],
  placementData => placementData.passportCredentials || initialState.passportCredentials,
);

export const selectIsLicenseSelect = createSelector(
  [selectDomain],
  placementData => placementData.isLicenseSelect || initialState.isLicenseSelect,
);
export const selectIsLicenseSelectRadio = createSelector(
  [selectDomain],
  placementData => placementData.isLicenseSelectRedio || initialState.isLicenseSelectRedio,
);

export const selectLandGtgCompletionStatus = createSelector(
  [selectDomain],
  placementData => placementData.landsGtgCompletionStatus || initialState.landsGtgCompletionStatus,
);

export const selectChangeHistory = createSelector([selectDomain], placementData => placementData.changeHistory);

export const selectPlacementOrderType = createSelector([selectDomain], state => state.placementOrderType);

export const selectRequiredDataLoadStatus = createSelector([selectDomain], state => state.requiredDataLoadStatus);

export const selectResetForm = createSelector([selectDomain], state => state.resetForm);

export const selectEditableData = createSelector(
  [
    selectPlacementHeaderData,
    selectPlacementCandidateRecords,
    selectEditableFields,
    selectPlacementOrderType,
    selectPlacementConsolidatedDetails,
  ],
  (header, candidateRecords, summary, placementOrderType, consolidatedResult) => ({
    bestTimeToCall: header.candidateBestTimeToCall,
    travelsWithPartner: candidateRecords.candidateSellingPointCounts.travelsWithPartner,
    travelPartner: candidateRecords.candidateSellingPointCounts.travelsWithPartnerName,
    placementDate: header.placementDate,
    availabilityDate: header.availabilityDate,
    sellingPoints: candidateRecords.candidateSellingPointCounts.placementSellingPoints,
    requestedTimeOff: candidateRecords.comparisons.candidate.requestedTimeOff,
    ssn: header.candidate.ssn?.ssn,
    dob: header.candidate.dob?.dob,
    requestedShift: summary.requestedShift,
    vmsReqNo: header.vmsReqDetails.vmsReqNo,
    placementShift: summary.placementShift,
    contractHours: header.contractHours,
    placementStartDate: header.placementStartDate,
    placementEndDate: header.placementEndDate,
    numberOfWeeks: header.numberOfWeeks,
    ...(placementOrderType === PlacementOrderType.StrikeOrderInHsg ? summary : {}),
  }),
);

export const selectSubmissionAmnRequirementTypes = createSelector(
  [selectSubmissionInfo, selectPlacementOrderType],
  (
    {
      amnReq: {
        amn: { skillset, status, audit, experience, workHistory, skillsCheckList, application, education },
        candidate: { references },
      },
    },
    orderType,
  ) => [
    skillset?.data?.verifiedByCmType,
    status?.type,
    audit?.type,
    experience?.type,
    workHistory?.type,
    skillsCheckList?.type,
    application?.data.suspensionType,
    application?.data.rightToWorkVerificationType,
    references?.type,
    education?.type,
    ...(orderType === PlacementOrderType.StrikeOrderInHsg ? [skillset?.data?.strikeMatchType] : []),
  ],
);

export const selectSubmissionOrderRequirementTypes = createSelector(
  [selectSubmissionInfo, selectEditableRequirementTypes, selectPlacementOrderType],
  (
    {
      orderReq: {
        order: {
          status,
          licenses,
          certifications,
          dates,
          milesRadius,
          covid,
          orderDob,
          orderSsn,
          workHistory,
          virtualInterview,
        },
        candidate: { certifications: candidateCertifications, references },
      },
    },
    { availabilityDate, placementDate, shiftOffered, orderDob: orderDobEditable, orderSsn: orderSsnEditable },
    orderType,
  ) => [
    status?.type,
    licenses?.type,
    ...(certifications?.data || []).map(item => item.type),
    availabilityDate?.requirement,
    placementDate?.requirement,
    shiftOffered?.requirement,
    milesRadius?.type,
    covid?.type,
    references?.type,
    virtualInterview?.type,
    workHistory?.type,
    /**Add only if  DOB and SSN are required at order level */
    ...(orderDob?.type ? [orderDobEditable?.requirement] : []),
    ...(orderSsn?.type ? [orderSsnEditable?.requirement] : []),
    /** Accounts for Risk icons in Order req - Certification - Candidate column */
    ...(candidateCertifications?.data.isCandidateCertsExpiring ||
    candidateCertifications?.data.isCredentialCertsExpiring
      ? [requirementType.risk]
      : []),
  ],
);

export const selectOfferConfirmationRequirementTypes = createSelector(
  [selectOfferInfo, selectPlacementOrderType],
  ({ confirmation: { datesConfirmed, dnsCleared, travelConfirmed } }, orderType) =>
    orderType === PlacementOrderType.StrikeOrderInHsg
      ? [datesConfirmed?.type, dnsCleared?.type, travelConfirmed?.type]
      : [],
);

export const selectOfferTransportationRequirementTypes = createSelector(
  [selectOfferInfo, selectPlacementOrderType],
  (
    {
      transportation: {
        gender,
        transportation,
        travelTimePreference,
        dob,
        ssn,
        departureAirport,
        departureItineraryLink,
        arrivalDate,
        arrivalTime,
      },
    },
    orderType,
  ) =>
    orderType === PlacementOrderType.StrikeOrderInHsg
      ? [
          gender?.type,
          transportation?.type,
          travelTimePreference?.type,
          dob?.type,
          ssn?.type,
          departureAirport?.type,
          departureItineraryLink?.type,
          arrivalDate?.type,
          arrivalTime?.type,
        ]
      : [],
);

export const selectBookingConfirmationRequirementTypes = createSelector(
  [selectBookingInfo, selectPlacementOrderType],
  ({ confirmation: { arrived, directDeposit, wisely } }, orderType) =>
    orderType === PlacementOrderType.StrikeOrderInHsg ? [arrived?.type, directDeposit?.type, wisely?.type] : [],
);
export const selectBookingCredentialingRequirementTypes = createSelector(
  [selectBookingInfo, selectPlacementOrderType],
  ({ credentialing: { gtgComplete, finalizedByQs } }, orderType) =>
    orderType === PlacementOrderType.StrikeOrderInHsg ? [gtgComplete?.type, finalizedByQs?.type] : [],
);

/**
 * Used to validate placement timestamps and data before updating a placement
 */

export const selectPlacementStrikeData = createSelector([selectBookingInfo, selectOfferInfo], (booking, offer) => {
  return {
    datesConfirmed: offer.confirmation.datesConfirmed?.data?.checked ?? false,
    dnsCleared: offer.confirmation.dnsCleared?.data?.checked ?? false,
    travelConfirmed: offer.confirmation.travelConfirmed?.data?.checked ?? false,
    transportationId: offer.transportation.transportation.data.transportationId,
    travelTimePreferenceId: offer.transportation.travelTimePreference.data.travelTimePreferenceId,
    departureAirport: offer.transportation.departureAirport.data,
    arrivalDate: offer.transportation.arrivalDate.data,
    arrivalTime: offer.transportation.arrivalTime.data,
    departureItineraryLink: offer.transportation.departureItineraryLink.data,
    arrived: booking.confirmation.arrived?.data.checked ?? false,
    directDeposit: booking.confirmation.directDeposit?.data.checked ?? false,
    wisely: booking.confirmation.wisely?.data.checked,
    finalizedByQs: booking.credentialing.finalizedByQs?.data.checked ?? false,
    gtgComplete: booking.credentialing.gtgComplete?.data.checked ?? false,
  };
});

export const selectMiscellaneousPlacementData = createSelector([selectDomain], details => ({
  disciplineData: details.disciplineData,
  licensesList: details.licensesList,
  passportChecklist: details.passportChecklist,
  extTimeOffPayerOpts: details.extTimeOffPayerOpts || [],
  extDirectHireOpts: details.extDirectHireOpts ?? [],
}));

export const selectDirectHireOption = createSelector([selectDomain], details => details.selectedDirectHireDetail);

export const selectKeyInternalContactsEditor = createSelector(
  [selectDomain],
  details => details.editor.keyInternalContacts,
);

export const selectPlacementModificationTabRendered = createSelector(
  [selectDomain],
  data => data.placementModificationTabRendered,
);

export const selectPlacementSupervision = createSelector(
  [selectDomain],
  placementData => placementData.placementLinkedSupervision || initialState.placementLinkedSupervision,
);

export const selectPlacementSupervisee = createSelector(
  [selectDomain],
  placementData => placementData.placementLinkedSupervisee || initialState.placementLinkedSupervisee,
);

export const selectPlacementLinkModalOpen = createSelector(
  [selectDomain],
  placementData => placementData.placementLinkModalOpen,
);

export const selectComplianceStatusProgressNotes = createSelector(
  [selectDomain],
  placementData => placementData.complianceStatusProgress,
);

export const selectPlacementSupervisorDetals = createSelector(
  [selectDomain],
  placementData => placementData.placementLinkDetails,
);

export const selectPlacementTags = createSelector(
  [selectDomain],
  placementData => placementData.placementTags || initialState.placementTags,
);

export const selectPlacementLink = createSelector([selectDomain], placementData => placementData.placementLink);

export const selectEditPlacementRegion = createSelector(
  [selectDomain],
  placementData => placementData?.editPlacementRegion,
);

export const selectedComplianceStatusNoteList = createSelector(
  [selectDomain],
  placementData => placementData.complianceStatusProgress.originalNotesList,
);

export const selectFocusedHeight = createSelector([selectDomain], placementData => placementData.focusedHeight);

export const selectIsUpdatedOrderId = createSelector([selectDomain], placementData => placementData.isUpdatedOrderId);

export const selectContactsExpanded = createSelector([selectDomain], placementData => placementData.isContactsExpanded);
