import { candidateDetailsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { Divider, Grid } from 'amn-ui-core';
import { missingField } from 'app/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    transform: 'translate(10px, 0px)',
    backgroundColor: theme.palette.system.lightFadedGray,
    minHeight: '110px',
    width: '100%',
    padding: '15px',
  },
  header: {
    fontWeight: 500,
  },
  subHeader: {
    fontWeight: 500,
    fontSize: '12px',
    alignSelf: 'flex-start',
    width: '80px',
  },
  itemCell: {
    paddingLeft: '10px !important',
  },
  gridContainer: {
    paddingTop: '10px',
  },
  divider: {
    paddingRight: '10px',
  },
  item: {
    fontSize: '14px',
    alignSelf: 'center',
    paddingTop: '10px !important',
  },
  gridAlignment: {
    alignItems: 'center',
  },
}));

export const AuditHeaderSection = props => {
  const { existingFormValues } = props;
  const { classes } = useStyles();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const { t } = useTranslation();
  return (
    <Grid container spacing={2} className={classes.root} xs={12}>
      <Grid item className={classes.itemCell} xs={3}>
        <Grid container direction={'column'} spacing={0}>
          <Grid item className={classes.header}>
            {`${candidateDetails.name.first} ${candidateDetails.name.last}`}
          </Grid>
          <Grid item>{candidateDetails.travelerId}</Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.divider}>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item className={classes.itemCell} xs={4}>
        <Grid container direction={'column'} spacing={1} className={classes.gridContainer}>
          <Grid item>
            <Grid container spacing={2} wrap="nowrap" className={classes.gridAlignment}>
              <Grid item className={classes.subHeader}>
                {t('candidate.auditCard.auditType')}
              </Grid>
              <Grid item className={classes.item}>
                {existingFormValues.pType?.label ?? missingField}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2} className={classes.gridAlignment}>
              <Grid item className={classes.subHeader}>
                {t('candidate.auditCard.status')}
              </Grid>
              <Grid item className={classes.item}>
                {existingFormValues.pStatus?.label ?? missingField}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.divider}>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item className={classes.itemCell} xs={4}>
        <Grid container direction={'column'} spacing={1} className={classes.gridContainer}>
          <Grid item>
            <Grid container spacing={2} wrap="nowrap" className={classes.gridAlignment}>
              <Grid item className={classes.subHeader} wrap="nowrap">
                {t('candidate.auditCard.category')}
              </Grid>
              <Grid item className={classes.item}>
                {existingFormValues.pCategory?.label ?? missingField}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2} className={classes.gridAlignment}>
              <Grid item className={classes.subHeader} wrap="nowrap">
                {t('candidate.auditCard.PID')}
              </Grid>
              <Grid item className={classes.item}>
                {existingFormValues.pPlacementID ?? missingField}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
