import React, { useEffect } from 'react';
import { Grid } from 'amn-ui-core';
import { missingField } from 'app/constants';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFacilityContractAffiliate,
  selectFacilityDetail,
} from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { useTranslation } from 'react-i18next';
import { BoxContainer } from '../../CustomComponents/BoxContainer';
import { RowSection } from '../../GeneralTab/Utils';
import { LayoutGrid } from 'app/layout/LayoutGrid';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import LaunchIcon from '@mui/icons-material/Launch';
import { FacilityDialog } from '@AMIEWEB/Facility/FacilityDetails/FacilityDialog';
import { AffiliatesXgridModal } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/RateCardTab/RateCardSections/AffiliatesXgridModal';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles()({
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  smallIcon: {
    fontSize: '20px',
  },
  largeDialogContainer: {
    minWidth: '960px',
    minHeight: '538px',
  },
  scrollableLargeDialogContent: {
    overflowY: 'auto',
    height: '488px',
    scrollbarGutter: 'stable',
  },
});

export const ContractAffiliates = () => {
  const params = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const facilityDetails = useSelector(selectFacilityDetail);
  const contractAffiliateData = useSelector(selectFacilityContractAffiliate);
  const [fixedRowHeaderWidth, setFixedRowHeaderWidth] = React.useState(0);
  const [isAssociatedAffiliatesModalOpen, setFacilityCreditNotesOpen] = React.useState<boolean>(false);

  const handleMeasureWidth = (width: number) => {
    setFixedRowHeaderWidth(oldWidth => Math.max(oldWidth, width));
  };

  useEffect(() => {
    if (params?.id) dispatch(facilityActions.getContractAffiliateData(Number(params?.id)));
  }, [facilityDetails?.facilityId]);

  return (
    <>
      <BoxContainer
        title={t('order.facility.rateCard.contractAffiliate.title')}
        iconToolTip={t('order.facility.rateCard.affiliatesModal.viewAssociatedFacilities')}
        onClick={() => setFacilityCreditNotesOpen(true)}
        rightIcon={contractAffiliateData?.affiliateId && <LaunchIcon className={classes.smallIcon} />}
        takeFullHeight
      >
        {contractAffiliateData ? (
          <Grid container direction="column">
            <RowSection
              header={t('order.facility.rateCard.contractAffiliate.status')}
              data={contractAffiliateData?.status || missingField}
              handleMeasureWidth={handleMeasureWidth}
              fixedHeaderWidth={fixedRowHeaderWidth}
            />
            <RowSection
              header={t('order.facility.rateCard.contractAffiliate.id')}
              data={contractAffiliateData?.affiliateId.toString() || missingField}
              handleMeasureWidth={handleMeasureWidth}
              fixedHeaderWidth={fixedRowHeaderWidth}
            />
            <RowSection
              header={t('order.facility.rateCard.contractAffiliate.name')}
              data={contractAffiliateData?.affiliateName || missingField}
              handleMeasureWidth={handleMeasureWidth}
              fixedHeaderWidth={fixedRowHeaderWidth}
            />
            <RowSection
              header={t('order.facility.rateCard.contractAffiliate.city')}
              data={contractAffiliateData?.city || missingField}
              handleMeasureWidth={handleMeasureWidth}
              fixedHeaderWidth={fixedRowHeaderWidth}
            />
            <RowSection
              header={t('order.facility.rateCard.contractAffiliate.state')}
              data={contractAffiliateData?.state || missingField}
              handleMeasureWidth={handleMeasureWidth}
              fixedHeaderWidth={fixedRowHeaderWidth}
            />
            <RowSection
              header={t('order.facility.rateCard.contractAffiliate.amnDivisionSource')}
              data={contractAffiliateData?.amnDivisionSource || missingField}
              handleMeasureWidth={handleMeasureWidth}
              fixedHeaderWidth={fixedRowHeaderWidth}
            />
          </Grid>
        ) : (
          <LayoutGrid direction="column">
            <div className={classes.center}>{t('order.facility.rateCard.delightMessage')}</div>
          </LayoutGrid>
        )}
        {isAssociatedAffiliatesModalOpen && (
          <FacilityDialog
            open={isAssociatedAffiliatesModalOpen}
            children={<AffiliatesXgridModal affiliationId={Number(contractAffiliateData.affiliateId)} />}
            handleClose={() => setFacilityCreditNotesOpen(false)}
            headerTitle={t('order.facility.rateCard.affiliatesModal.title')}
            customContentStyles={classes.scrollableLargeDialogContent}
            custonDialogStyles={classes.largeDialogContainer}
          />
        )}
      </BoxContainer>
    </>
  );
};
