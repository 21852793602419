import React, { useMemo, useState } from 'react';
import { Box, Button, ClickAwayListener, Fade, Grid, Paper, Popper } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { DisableAppScroll, EnableAppScroll } from 'app/layout/Layout';
import { useEffect } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import _, { isArray, throttle } from 'lodash';
import { CustomTypeAhead, ISelection } from 'app/ComponentLibrary/Filter/TypeAheadDropdown/CustomTypeAhead';
import defaultFilter from 'app/assets/images/Tasks/DefaultFilter.svg';
import filterOnHover from 'app/assets/images/Tasks/FilterOnHover.svg';
import filterExpanded from 'app/assets/images/Tasks/FilterOnExpand.svg';
import filterClicked from 'app/assets/images/Tasks/FilterOnSelect.svg';
import filterApplied from 'app/assets/images/Tasks/FilterOnApply.svg';

const useStyles = makeStyles<{
  isSelected: boolean;
  open: boolean;
  anchorRef: boolean;
  removeActionContainer: boolean;
}>()((theme, { isSelected, open, anchorRef, removeActionContainer }) => ({
  root: {},
  popper: {
    marginTop: '4px !important',
    zIndex: 5000,
  },
  paperDropdown: {
    boxShadow: '0px 2px 8px #0000004D',
  },
  headerContainer: {
    minWidth: 250,
    padding: '12px 20px 6px',
  },
  headerContainerNoSearchBoxSingleOption: {
    minWidth: 250,
    padding: '0px 20px 0px',
  },
  actionContainer: {
    visibility: removeActionContainer ? 'hidden' : undefined,
    backgroundColor: '#ffffff',
    borderTop: removeActionContainer ? undefined : '1px solid #cccccc',
    padding: removeActionContainer ? 0 : '6px 20px',
    height: removeActionContainer ? 0 : undefined,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  button: {
    fontSize: 14,
    textTransform: 'none',
    '&:hover, &:focus': {
      background: 'none',
    },
  },
 
  inActive: {
    color: '#888888',
  },
  clearAllButton: {
    padding: 0,
  },
  actionButton: {
    padding: '6px 0px',
  },
  countTag: {
    color: '#555555',
    fontSize: 14,
    fontWeight: 600,
  },
  filterIcon: {
    padding: '0px',
    border: '0px',
    maxWidth: '40px !important',
    minWidth: '35px !important',
    '&:hover': {
      background: 'none',
      border: '0px',
    },
  },
}));

export const AVOID_CLICK_AWAY_LABEL = 'avoid-click-away-type-ahead-drop-down';

export const TaskCustomFilter = props => {
  const {
    isMultiSelect,
    value: selected,
    selectAllValue,
    options: providedOptions,
    hiddenInput,
    isDisabled,
    /** Prop to utilize external anchor element */
    anchorRef,
    removeActionContainer = false,
    customClass,
    getOptionDisabled,
    searchBoxHiddenWithSingleOption = false,
  } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [options, setOptions] = useState<ISelection[]>([]);
  const trackerArea = `${props.name}-async-typeAhead`;
  const { promiseInProgress } = usePromiseTracker({ area: trackerArea, delay: 0 });
  const [isNewSelectionApplied, setIsNewSelectionApplied] = useState<boolean>(false);
  const [prevState, setPrevState] = useState<any>(selected);
  const [hoverFunnel, setHoverFunnel] = useState(false);
  const open = Boolean(anchorEl);
  const { classes } = useStyles({
    isSelected: isMultiSelect ? selected && selected.length > 0 : selected !== null,
    open,
    anchorRef,
    removeActionContainer,
  });

  const id = open ? `custom-type-ahead-${props.name}` : undefined;

  const fetchOptions = async request => {
    const response = await props.fetchOptions(request);
    setOptions(
      (response || []).map(opt => ({
        id: opt.value,
        name: opt.name,
        value: opt.value,
      })),
    );
  };

  const onApplyEvent = () => {
    setIsNewSelectionApplied(true);
    if (isArray(selected)) {
      const selectedValues = _.cloneDeep(selected).sort(function (a, b) {
        return a.value === b.value ? 0 : a.value > b.value ? 1 : -1;
      });
      props.apply(selectedValues);
    } else {
      props.apply(selected);
    }
    closeActions();
  };

  const closeActions = () => {
    setAnchorEl(null);
    EnableAppScroll();
  };

  const cancelChanges = () => {
    props.onChange(prevState);
    closeActions();
  };

  const clearAll = () => props.onChange(isMultiSelect ? [] : null);

  const handleClose = () => {
    !isNewSelectionApplied && props.onChange(prevState);
    if (anchorEl) {
      anchorEl.focus();
    }
    closeActions();
  };

  const handleOpen = event => {
    if (!open) {
      trackPromise(fetchOptions(undefined), trackerArea);
      setAnchorEl(anchorRef ? anchorRef.current : event.currentTarget);
      setIsNewSelectionApplied(false);
      setPrevState(selected);

      DisableAppScroll();
    } else {
      if (props.hideApply) {
        onApplyEvent();
        return;
      }
      cancelChanges();
    }
  };

  const onClickAway = event => {
    if (props.hideApply) {
      onApplyEvent();
      return;
    }
    if (event.target.type !== 'submit' && event.target.id !== AVOID_CLICK_AWAY_LABEL) {
      !anchorRef && !isNewSelectionApplied && props.onChange(prevState);
      closeActions();
    }
  };

  const throttleService = useMemo(
    () => throttle(request => trackPromise(fetchOptions(request), trackerArea), 2000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => {
      if (
        isMultiSelect &&
        selectAllValue &&
        options &&
        selected?.length !== options.length &&
        selected?.some(item => item.value === selectAllValue)
      )
        props.onChange(_.cloneDeep(selected).splice(1, selected.length - 1));
      return EnableAppScroll();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(selected), JSON.stringify(options)],
  );

  useEffect(() => {
    if ((providedOptions || []).length > 0 && !props.fetchOptions) {
      setOptions(providedOptions);
    }
  }, [props.fetchOptions, providedOptions]);
  interface PropType {
    iconStyle?: any;
    toggleArrow?: boolean;
  }
  const FilterButton = ({ iconStyle, toggleArrow }: PropType) => {
    return (
      <Box>
        <img
          style={iconStyle ? iconStyle : { height: 36, width: 39 }}
          alt="FunnelFilter-icon"
          src={toggleArrow ? filterOnHover : defaultFilter}
        />
      </Box>
    );
  };

  const ClickedFilterButton = ({ iconStyle, toggleArrow }: PropType) => {
    return (
      <Box>
        <img
          style={
            !open
              ? iconStyle
                ? iconStyle
                : { height: 36, width: 39 }
              : { boxShadow: '0px 3px 6px #0000004D', height: '36px', width: '37px', borderRadius: '5px' }
          }
          alt="FunnelFilter-icon"
          src={!open ? (toggleArrow ? filterApplied : filterClicked) : filterExpanded}
        />
      </Box>
    );
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Button
          onMouseEnter={() => setHoverFunnel(true)}
          onMouseLeave={() => setHoverFunnel(false)}
          disableRipple
          variant="outlined"
          aria-describedby={id}
          disabled={!!isDisabled}
          endIcon={
            !open ? (
              selected?.length === 0 ? (
                <FilterButton toggleArrow={hoverFunnel} />
              ) : (
                <img style={{ height: 36, width: 39 }} alt="FunnelFilter-icon" src={filterApplied} />
              )
            ) : (
              <ClickedFilterButton toggleArrow={selected?.length > 0} />
            )
          }
          classes={{ root: classes.filterIcon }}
          onClick={handleOpen}
          id={props.anchorAccesorid}
        ></Button>
      </div>
      <Popper
        className={customClass ? `${customClass}` : `${classes.popper}`}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={onClickAway}>
            <Fade {...TransitionProps} in={open} timeout={300}>
              <Paper aria-label={id} classes={{ root: classes.paperDropdown }}>
                <Grid
                  item
                  container
                  justifyContent={isMultiSelect ? 'space-between' : 'flex-end'}
                  alignItems="center"
                  classes={{
                    root: searchBoxHiddenWithSingleOption
                      ? classes.headerContainerNoSearchBoxSingleOption
                      : classes.headerContainer,
                  }}
                >
                  {isMultiSelect ? (
                    <div className={classes.countTag}>{`${
                      selectAllValue &&
                      options &&
                      selected &&
                      selected.length &&
                      options?.length &&
                      selected.length === options?.length
                        ? options?.length - 1
                        : selected?.length || 0
                    } ${t('search.filter.Selected')}`}</div>
                  ) : null}
                  {!props.hideClearAll ? (
                    <Button
                      className="clear-all-button"
                      aria-label="clear-all-button"
                      variant="text"
                      color='primary'
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      onClick={() => clearAll()}
                      disabled={!isMultiSelect}
                      classes={{ text: ` ${classes.button} ${classes.clearAllButton}` }}
                    >
                      {t('search.filter.clearAll')}
                    </Button>
                  ) : null}
                </Grid>
                <CustomTypeAhead
                  enableCustomScroll
                  removeCloseIcon
                  variant="outlined"
                  size="small"
                  handleClose={handleClose}
                  options={options}
                  isLoading={promiseInProgress}
                  onInputChange={throttleService}
                  hiddenInput={hiddenInput}
                  getOptionDisabled={getOptionDisabled}
                  unreadInboxCheck={true}
                  {...props}
                />
                {!props.hideApply ? (
                  <div className={classes.actionContainer}>
                    <Grid item container justifyContent="flex-end" alignItems="center">
                      <Button
                        className="clear-all-button"
                        aria-label="apply-button"
                        variant="text"
                        color='primary'     
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        onClick={() => onApplyEvent()}
                        classes={{ text: `${classes.button} ${classes.actionButton}` }}
                        id={props.hiddenApplyBtn}
                      >
                        {t('search.filter.apply')}
                      </Button>
                      <Button
                        className="clear-all-button"
                        aria-label="cancel-button"
                        variant="text"
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        onClick={() => cancelChanges()}
                        classes={{ text: `${classes.button} ${classes.actionButton} ${classes.inActive}` }}
                      >
                        {t('search.filter.cancel')}
                      </Button>
                    </Grid>
                  </div>
                ) : null}
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </React.Fragment>
  );
};
