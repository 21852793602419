/* eslint-disable @typescript-eslint/return-await */
import { calculateTreeCount } from '@AMIEWEB/Common/TreeView/TreeViewHelpers';
import { PlacementSearchFilterName } from '@AMIEWEB/GlobalSearch/Placement/SearchUtils';
import { getFilteredOptions } from '@AMIEWEB/GlobalSearch/helper';
import { FilterCompTypes } from 'app/ComponentLibrary/Filter/utils';
import { getSearchLookups } from 'app/services/SharedServices/SharedServices';

async function getSearchLookupsWithFilters(lookup, keyword) {
  const data = await getSearchLookups(lookup, keyword);
  if (data) {
    if (lookup === 'facilities') {
      return data?.map(d => {
        return {
          value: d.value,
          name: d.name + ', ' + d.value,
          labelPrefix: `${getPrefixes(lookup)}`,
        };
      });
    }
    return data?.map(opt => ({ ...opt, labelPrefix: `${getPrefixes(lookup)}` }));
  }
  return [];
}

const getPrefixes = lookup => {
  switch (lookup) {
    case 'recruiters':
      return 'Recruiter';
    case 'accountmanagers':
      return 'Account Manager';
    case 'credentialinganalysts':
      return 'Credentialing Analyst';
    case 'cuedto':
      return 'Cued To';
    case 'facilities':
      return 'Facility';
    default:
      return lookup;
  }
};

export const defaultPlacementFilters = {
  [PlacementSearchFilterName.pid]: null,
  [PlacementSearchFilterName.oid]: null,
  [PlacementSearchFilterName.facility]: null,
  [PlacementSearchFilterName.archiveStatus]: { id: 0, labelPrefix: 'Archive', name: 'No', value: 'N' },
  [PlacementSearchFilterName.status]: [
    {
      name: 'Submission',
      value: '1',
      children: [
        {
          name: 'Waiting for Clearance',
          value: '1',
        },
        {
          name: 'Request File Out',
          value: '2',
        },
        {
          name: 'Sent',
          value: '3',
        },
        {
          name: 'Interview Scheduled',
          value: '16',
        },
        {
          name: 'Interviewed',
          value: '6',
        },
        {
          name: 'OK to Send',
          value: '8',
        },
        {
          name: 'Declined by Traveler',
          value: '4',
        },
        {
          name: 'Declined by Facility',
          value: '5',
        },
        {
          name: 'Request Cancelled',
          value: '7',
        },
      ],
    },
    {
      name: 'Offer',
      value: '3',
      children: [
        {
          name: 'Declined by Traveler',
          value: '4',
        },
        {
          name: 'Declined by Facility',
          value: '5',
        },
        {
          name: 'Offer by Facility',
          value: '9',
        },
        {
          name: 'Offer Booked by Traveler',
          value: '10',
        },
        {
          name: 'Traveler Accepts',
          value: '11',
        },
        {
          name: 'Facility Confirms',
          value: '12',
        },
      ],
    },
    {
      name: 'Booking',
      value: '4',
      children: [
        {
          name: 'Booked',
          value: '13',
        },
        {
          name: 'Booking Approved',
          value: '14',
        },
        {
          name: 'Waiting Good To Go',
          value: '15',
        },
        {
          name: 'Postponement Warning',
          value: '17',
        },
        {
          name: 'Good To Go',
          value: '18',
        },
      ],
    },
    {
      name: 'Assignment',
      value: '5',
      children: [
        {
          name: 'On Assignment',
          value: '23',
        },
        {
          name: 'Assignment Complete',
          value: '24',
        },
      ],
    },
  ],
  [PlacementSearchFilterName.extensionStatus]: null,
  [PlacementSearchFilterName.startDate]: null,
  [PlacementSearchFilterName.endDate]: null,
  [PlacementSearchFilterName.recruiter]: null,
  [PlacementSearchFilterName.accountManager]: null,
  [PlacementSearchFilterName.credentialingAnalyst]: null,
  [PlacementSearchFilterName.placementCuedTo]: null,
  [PlacementSearchFilterName.region]: null,
};

export const archiveOpts = [
  { id: 0, labelPrefix: 'Archive', name: 'No', value: 'N' },
  { id: 1, labelPrefix: 'Archive', name: 'Yes', value: 'Y' },
];

export const defaultValues = {
  archiveStatus: [{ id: 0, labelPrefix: 'Archive', name: 'No', value: 'N' }],
};

export class AssociatedPlacementsFilter {
  constructor(public filterSpecs: any, public t: any, public regionFlag?: boolean) {}

  getPlacementsFilterSpecs = () => {
    const filters = [
      {
        filterName: this.t('search.globalSearch.placement.filterNames.pid'),
        name: PlacementSearchFilterName.pid,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookupsWithFilters('PID', key),
        isMultiSelect: true,
        searchOnCharacterCount: 1,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.oid'),
        name: PlacementSearchFilterName.oid,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookupsWithFilters('OID', key),
        isMultiSelect: true,
        searchOnCharacterCount: 1,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.facility'),
        name: PlacementSearchFilterName.facility,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookupsWithFilters('facilities', key),
        isMultiSelect: true,
        searchOnCharacterCount: 1,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('placement.placementFilters.archive'),
        name: PlacementSearchFilterName.archiveStatus,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        options: archiveOpts,
        searchOnCharacterCount: 1,
        hideClearAll: true,
      },
      {
        filterName: this.t('placement.placementFilters.placementStatus'),
        name: PlacementSearchFilterName.status,
        type: FilterCompTypes.TREE,
        isMultiSelect: true,
        options: this.filterSpecs.statusOpts,
        placeholder: 'Select',
        selectAllValue: 'All',
        version2: true,
        returnsObjectAsValue: true,
        size: 'large',
        customSelectedCount: value => calculateTreeCount(value),
        searchOnCharacterCount: 1,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.extensionStatus'),
        name: PlacementSearchFilterName.extensionStatus,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.extensionOpts, key),
        searchOnCharacterCount: 1,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.startDate'),
        name: PlacementSearchFilterName.startDate,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: this.t('search.globalSearch.placement.filterNames.startDate'),
        useMaxWidth: true,
        maxWidth: 210,
        clickAway: true,
        disablePortal: true,
        quickSelect: false,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.endDate'),
        name: PlacementSearchFilterName.endDate,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: this.t('search.globalSearch.placement.filterNames.endDate'),
        useMaxWidth: true,
        maxWidth: 210,
        clickAway: true,
        disablePortal: true,
        quickSelect: false,
      },
      {
        filterName: this.t('placement.placementFilters.recruiter'),
        name: PlacementSearchFilterName.recruiter,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookupsWithFilters('recruiters', key),
        isMultiSelect: true,
        searchOnCharacterCount: 1,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('placement.placementFilters.accountManager'),
        name: PlacementSearchFilterName.accountManager,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookupsWithFilters('accountmanagers', key),
        isMultiSelect: true,
        searchOnCharacterCount: 1,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('placement.placementFilters.credentialingAnalyst'),
        name: PlacementSearchFilterName.credentialingAnalyst,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookupsWithFilters('credentialinganalysts', key),
        isMultiSelect: true,
        searchOnCharacterCount: 1,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('placement.placementFilters.cuedTo'),
        name: PlacementSearchFilterName.placementCuedTo,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookupsWithFilters('cuedto', key),
        isMultiSelect: true,
        searchOnCharacterCount: 1,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
    ];
    const regionFilter = [
      {
        filterName: this.t('search.globalSearch.placement.filterNames.region'),
        name: PlacementSearchFilterName.region,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.regionOpts, key),
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
        searchOnCharacterCount: 1,
      },
    ];
    const filterList = this.regionFlag ? [...filters, ...regionFilter] : filters;
    return filterList;
  };
}
