import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IPlacementRequests, IAutotaskGridData, IAutoTaskTaskStatus, IUpdateAutoTaskManagementResponse } from 'app/models/AutoTaskManagement/AutoTaskManagement';
import { serviceActions } from './async-actions';
import { businessActions } from './business-actions';
import { IUserRoles } from '@AMIEWEB/Settings/AutoTaskManagement/componenets/UserRolesSection/UserRolesSection';

export const initialState: IPlacementRequests = {
  gridData: [],
  selectedData: undefined,
  responseData: [],
  filters: undefined,
  selectedRow: {} as IAutotaskGridData,
  assignedRoles: [],
  selectedUserRoles: [],
  currentStatus: {} as IAutoTaskTaskStatus,
  userRolesOptionsList: [],
  selectedFilters: {},
  autoTaskUpdateResponse : {} as IUpdateAutoTaskManagementResponse,
  autoTaskManagementStatusLookUp:[]
};
const placementRequestsSlice = createSlice({
  name: 'placementRequests',
  initialState,
  reducers: {
    setGridData(state, action: PayloadAction<Array<IAutotaskGridData>>) {
      state.gridData = action.payload;
    },
    setResponseData(state, action: PayloadAction<Array<IAutotaskGridData>>) {
      state.responseData = action.payload;
    },
    setSelectedData(state, action: PayloadAction<any>) {
      state.selectedData = action.payload
    },
    setFilters(state, action: PayloadAction<any>) {
      state.filters = action.payload
    },
    setSelectedFilters(state, action: PayloadAction<any>) {
      state.selectedFilters = action.payload
    },
    setSelectedRow(state, action: PayloadAction<IAutotaskGridData>) {
      state.selectedRow = action.payload;
    },
    setAssignedRoles(state, action: PayloadAction<number[]>) {
      state.assignedRoles = action.payload;
    },
    setSelectedUserRoles(state, action: PayloadAction<IUserRoles[]>) {
      state.selectedUserRoles = action.payload;
    },
    currentStatus(state, action: PayloadAction<IAutoTaskTaskStatus>) {
      state.currentStatus = action.payload;
    },
    setUserRolesOptionsList(state, action: PayloadAction<IUserRoles[]>) {
      state.userRolesOptionsList = action.payload;
    },
    setAutoTaskUpdateResponse(state,action : PayloadAction<IUpdateAutoTaskManagementResponse>){
      state.autoTaskUpdateResponse =  action.payload
    },
    setAutoTaskManagementStatusLookUp(state,action:PayloadAction<IAutoTaskTaskStatus[]>){
      state.autoTaskManagementStatusLookUp = action.payload
    },
    reset() {
      return { ...initialState };
    },
  }
})

const { actions } = placementRequestsSlice;
export const { reducer: placementRequestsReducer, name: placementRequestsSliceKey } = placementRequestsSlice;
export const placementRequestsAction = { ...actions, ...serviceActions, ...businessActions };
