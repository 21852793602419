import React from 'react';
import { Grid } from 'amn-ui-core';
import { SearchField } from './CandidateSearch';
import { SelectedCandidate } from './SelectedCandidate';

export const CandidateSection = props => (
  <Grid item xs={4}>
    <Grid container spacing={2} direction="column">
      <Grid item>
        <SearchField {...props} />
      </Grid>
      <Grid item>
        <SelectedCandidate />
      </Grid>
    </Grid>
  </Grid>
);
