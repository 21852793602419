import { CustomTooltip } from 'app/components/Common/Tooltips';
import React from 'react';
import { ChannelType } from '../Constants';

import Call from 'assets/images/WorkDesk/Call.png';
import email from 'assets/images/WorkDesk/email.png';
import { NotificationElement } from './RecuriterNotificationElement';
import { Typography } from 'amn-ui-core';

/**Below implementaion is limited to REC workdesk */
export const CandidateNotification = ({ tooltip = true, ...props }) =>
  props.displayText ? (
    <React.Fragment>
      {props.v2 && (
        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '7px', paddingRight: '6px' }}>
          <NotificationElement {...props} />
          <div style={{ paddingLeft: '6px' }}>
            <Typography variant="body2">{props.displayText}</Typography>
          </div>
        </div>
      )}
      {!props.v2 && (
        <React.Fragment>
          {tooltip && (
            <CustomTooltip
              value={<NotificationElement {...props} />}
              tooltipContent={props.displayText}
              standardMargin
            />
          )}
          {!tooltip && <NotificationElement {...props} />}
        </React.Fragment>
      )}
    </React.Fragment>
  ) : props.channel === ChannelType.voice ? (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img src={Call} alt={''} />
    </div>
  ) : props.channel === ChannelType.email ? (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img src={email} alt={''} />
    </div>
  ) : null;
