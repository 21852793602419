import { Configuration, PublicClientApplication } from '@azure/msal-browser';

const msConfig: Configuration = {
  auth: {
    clientId: `${globalThis?.app?.env?.REACT_APP_OIDC_CLIENT_ID}`,
    authority: `${globalThis?.app?.env?.REACT_APP_IDP_AUTHORITY}`,
    redirectUri: `${globalThis?.app?.env?.REACT_APP_BASE_URL}/callback`,
    postLogoutRedirectUri: `${globalThis?.app?.env?.REACT_APP_BASE_URL}/signout`,
    // protocolMode: ProtocolMode.OIDC,
  },
  cache: {
    cacheLocation: 'localStorage',
  }
};

export const msalInstance = new PublicClientApplication(msConfig);

export const accessTokenRequest = accounts => ({
  account: accounts[0],
  scopes: ['openid', 'profile', 'email', `${globalThis?.app?.env?.REACT_APP_OIDC_SCOPE}`],
});

export const loginTokenRequest = {
  scopes: ['openid', 'profile', 'email', `${globalThis?.app?.env?.REACT_APP_OIDC_SCOPE}`],
};
