import { DateRange, DefinedRange } from 'app/ComponentLibrary/DateRangeNew';
import { addDays } from 'date-fns';

export const defaultDateRange = (date: number | Date): DefinedRange[] => {
  return [
    {
      label: '1 Day',
      startDate: addDays(date, 1),
      endDate: addDays(date, 1),
      useLabelAsValue: false,
    },
    {
      label: '2 Days',
      startDate: addDays(date, 2),
      endDate: addDays(date, 2),
      useLabelAsValue: false,
    },
    {
      label: '7 Days',
      startDate: addDays(date, 7),
      endDate: addDays(date, 7),
      useLabelAsValue: false,
    },
    {
      label: '20 Days',
      startDate: addDays(date, 20),
      endDate: addDays(date, 20),
      useLabelAsValue: false,
    },
    {
      label: '30 Days',
      startDate: addDays(date, 30),
      endDate: addDays(date, 30),
      useLabelAsValue: false,
    },
    {
      label: '60 Days',
      startDate: addDays(date, 60),
      endDate: addDays(date, 60),
      useLabelAsValue: false,
    },
  ];
};
