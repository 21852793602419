import React from 'react';
import { XGridDefaultColumn } from '@AMIEWEB/Common/XGrid/cells/XGridDefaults';
import { GridCellParams, GridColumns } from '@mui/x-data-grid';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { XGridIconCell } from 'app/components/Common/XGrid/cells/XGridIconCell';
import { Link, Typography } from 'amn-ui-core';
import { missingField } from 'app/constants';
import { XGridOverflowCell } from '@AMIEWEB/Common/XGrid/cells/XGridOverflowCell';
import { useDispatch } from 'react-redux';
import { XGridChipCell, XGridToolTipCell } from '@AMIEWEB/Common';
import { FacilityContactsActions } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.redux';
import { StatusDropDownValues } from '@AMIEWEB/Common/StatusDropDown/StatusDropDown';
import { getAbbreviation } from './helper';
import StarIcon from '@mui/icons-material/Star';
import i18next from 'i18next';
import { makeStyles } from 'tss-react/mui';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';

const useStyles = makeStyles()(theme => ({
  tooltipContent: {
    color: theme.palette.components.tooltip.color,
    fontSize: '14px !important',
    margin: '0px',
    padding: '0px',
    maxHeight: '300px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  tooltip: {
    color: theme.palette.components.tooltip.color,
    padding: '12px 12px 12px 28px',
  },
  tooltipValue: {
    color: theme.palette.components.tooltip.color,
    fontSize: '14px',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const ViewNotesLink = (params: GridCellParams) => {
  const dispatch = useDispatch();

  const handleLink = () => {
    dispatch(
      FacilityContactsActions.setFacilityClientContactDrawer({
        open: true,
        notes: params?.row?.notes,
        status: StatusDropDownValues.ACTIVE,
        contactId: params?.row?.contactId,
        name: params.row.name,
        nameAbbreviation: getAbbreviation(params.row.firstName, params.row.lastName),
        role: params.row.title,
        statusLastUpdated: params?.row?.statusLastUpdated,
      }),
    );
  };

  return <Link onClick={() => handleLink()}>{'View Notes'}</Link>;
};

const getColor = (status: string, theme) => {
  switch (status) {
    case StatusDropDownValues.ACTIVE:
      return theme.palette.framework.system.green;
    default:
      return missingField;
  }
};

export const contactColumns = (theme, t, handleStarClick, handleFirstNameClick): GridColumns => {
  const { classes } = useStyles();

  const TooltipContent = ({ content }: { content: string }) => {
    return (
      <ul className={classes.tooltipContent}>
        {content.split(',').map((item, index) => {
          return (
            <li style={{ marginRight: '25px' }} key={index}>
              {item}
            </li>
          );
        })}
      </ul>
    );
  };

  const TooltipCellValue = ({ content }: { content: string }) => {
    return content !== missingField ? (
      <Typography className={classes.tooltipValue}>{content}</Typography>
    ) : (
      <Typography className={classes.tooltipValue}>{missingField}</Typography>
    );
  };

  return [
    {
      ...XGridDefaultColumn,
      field: 'starIcon',
      headerName: i18next.t('facility.units.unitContacts.gridHeaders.priority'),
      flex: 0,
      width: 50,
      renderCell: param => {
        return XGridIconCell({
          icon: param.row.isPrimary ? (
            <StarIcon
              style={{
                color: theme.palette.system.lightNavyBlue,
                cursor: 'pointer',
              }}
            />
          ) : (
            <StarBorderIcon
              style={{
                color: theme.palette.framework.system.charcoal,
                cursor: 'pointer',
              }}
            />
          ),
          align: 'center',
          onClick: handleStarClick,
        })(param.row);
      },
    },
    {
      ...XGridDefaultColumn,
      field: 'firstName',
      headerName: i18next.t('facility.units.unitContacts.gridHeaders.firstName'),
      flex: 0,
      width: 150,
      renderCell: XGridOverflowCell()(),
    },
    {
      ...XGridDefaultColumn,
      field: 'lastName',
      headerName: i18next.t('facility.units.unitContacts.gridHeaders.lastName'),
      flex: 0,
      width: 150,
      renderCell: XGridOverflowCell()(),
    },
    {
      ...XGridDefaultColumn,
      field: 'prefix',
      headerName: i18next.t('facility.units.unitContacts.gridHeaders.prefix'),
      flex: 0,
      width: 50,
      renderCell: XGridOverflowCell()(),
    },
    {
      ...XGridDefaultColumn,
      field: 'goesBy',
      headerName: i18next.t('facility.units.unitContacts.gridHeaders.goesBy'),
      flex: 1,
      width: 85,
      renderCell: XGridOverflowCell()(),
    },
    {
      ...XGridDefaultColumn,
      field: 'email',
      headerName: i18next.t('facility.units.unitContacts.gridHeaders.email'),
      flex: 0,
      width: 250,
      // @ts-ignore
      renderCell: XGridOverflowCell()(),
    },
    {
      ...XGridDefaultColumn,
      field: 'phoneNumber',
      headerName: i18next.t('facility.units.unitContacts.gridHeaders.phone'),
      flex: 0,
      width: 120,
      // @ts-ignore
      renderCell: XGridOverflowCell()(),
    },
    {
      ...XGridDefaultColumn,
      field: 'title',
      headerName: i18next.t('facility.units.unitContacts.gridHeaders.title'),
      flex: 0,
      width: 100,
      renderCell: XGridOverflowCell()(),
    },
    {
      ...XGridDefaultColumn,
      field: 'contactPreference',
      headerName: i18next.t('facility.units.unitContacts.gridHeaders.contactPreference'),
      flex: 0,
      width: 100,
      renderCell: XGridOverflowCell()(),
    },
    {
      ...XGridDefaultColumn,
      field: 'doNotCallIn',
      headerName: i18next.t('facility.units.unitContacts.gridHeaders.doNotCall'),
      flex: 0,
      width: 80,
      renderCell: XGridOverflowCell()(params =>
        !isNullOrUndefined(params.row.doNotCallIn)
          ? params.row.doNotCallIn === 'Y'
            ? t('Yes')
            : t('No')
          : missingField,
      ),
    },
    {
      ...XGridDefaultColumn,
      field: 'city',
      headerName: i18next.t('facility.units.unitContacts.gridHeaders.city'),
      flex: 0,
      width: 100,
      renderCell: XGridOverflowCell()(),
    },
    {
      ...XGridDefaultColumn,
      field: 'state',
      headerName: i18next.t('facility.units.unitContacts.gridHeaders.state'),
      flex: 0,
      width: 75,
      renderCell: XGridOverflowCell()(),
    },
    {
      ...XGridDefaultColumn,
      field: 'status',
      headerName: i18next.t('facility.units.unitContacts.gridHeaders.status'),
      flex: 0,
      width: 100,

      renderCell: XGridChipCell({
        size: 'small',
        color: params => getColor(params.value, theme),
        renderValue: (params: GridCellParams) => {
          return params.value === StatusDropDownValues.ACTIVE
            ? StatusDropDownValues.ACTIVE
            : StatusDropDownValues.INACTIVE;
        },
        showHandIcon: true,
      }),
    },
    {
      ...XGridDefaultColumn,
      field: 'notes',
      headerName: i18next.t('facility.units.unitContacts.gridHeaders.notes'),
      flex: 0,
      width: 100,
      renderCell: ViewNotesLink,
    },
    {
      ...XGridDefaultColumn,
      field: 'actionsSortField',
      headerName: i18next.t('facility.units.unitContacts.gridHeaders.actions'),
      flex: 0,
      width: 150,
      renderCell: params =>
        XGridToolTipCell({
          tooltipContent: () => <TooltipContent content={params.row.actionsSortField} />,
          value: () => <TooltipCellValue content={params.row.actionsSortField} />,
          disabled: () => params.formattedValue === missingField,
          tooltipProps: {
            placement: 'bottom-end',
            tooltipContent: undefined,
            classes: { tooltip: classes.tooltip },
          },
        })(params.row.actionsSortField),
    },
    {
      ...XGridDefaultColumn,
      field: 'units',
      headerName: i18next.t('facility.units.unitContacts.gridHeaders.units'),
      flex: 0,
      width: 150,
      renderCell: params =>
        XGridToolTipCell({
          tooltipContent: () => <TooltipContent content={params.row.unitAttributes.tooltipContent} />,
          value: () => <TooltipCellValue content={params.row.unitAttributes.tooltipContent} />,
          disabled: () => params.formattedValue === missingField,
          tooltipProps: {
            placement: 'bottom-end',
            tooltipContent: undefined,
            classes: { tooltip: classes.tooltip },
          },
        })(params.row.unitAttributes.tooltipContent),
    },
  ];
};
