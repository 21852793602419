import React, { useState } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {  FormControl, FormGroup, FormControlLabel, Checkbox, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';

const useStyles = makeStyles()((theme) => ({
  formControl: {
    margin: theme.spacing(3),
    width: '100%',
    marginRight: 0,
    marginLeft: 0,
  },
  formControlLabeRoot: {
    marginRight: theme.spacing(5),
  },

  formLabelRoot: {
    color: '#52429a',
    fontSize: 14,
    paddingBottom: '1%',
  },
 
}));

export interface ICheckbox {
  label: string;
  name: string;
  object?: any;
  checked?: boolean;
  isReadOnly?: boolean;
}

export const CheckboxContainer = props => {
  const { classes } = useStyles();
  const { title, value: checkboxes, multiSelect, anyOptionName } = props;
  const [prevData, setPrevData] = useState<ICheckbox[]>(checkboxes);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, item) => {
    let modifiedCheckboxes: ICheckbox[] = [];
    const anyOption: ICheckbox | null = anyOptionName ? checkboxes.find(option => option.name === anyOptionName) : null;
    ///////////////Logic for multi-select/////////////
    if (multiSelect) {
      /////////////condition =>  unchecking 'any' option///////////
      if (anyOption && anyOption.name === item.name && item.checked) {
        modifiedCheckboxes = prevData;
      }
      ////////////checking an option when any is checked/////////
      else if (anyOption && anyOption.checked && !item.checked) {
        modifiedCheckboxes = _.cloneDeep(checkboxes).map(checkbox => {
          checkbox.checked =
            checkbox.name === anyOption.name
              ? false
              : checkbox.name === item.name
              ? !checkbox.checked
              : checkbox.checked;
          return checkbox;
        });
      }
      ////////////condition => checking 'any' option///////////////
      else if (anyOption && anyOption.name === item.name && !item.checked) {
        //////////////Improper destructuring observed////////////////
        setPrevData(_.cloneDeep(checkboxes));
        modifiedCheckboxes = _.cloneDeep(checkboxes).map(checkbox => {
          checkbox.checked = checkbox.name === anyOption.name ? true : !checkbox.isReadOnly ? false : checkbox.checked;
          return checkbox;
        });
      }
      ////////////random clicks/////////////////
      else {
        modifiedCheckboxes = _.cloneDeep(checkboxes).map(checkbox => {
          checkbox.checked = checkbox.name === item.name ? !checkbox.checked : checkbox.checked;
          return checkbox;
        });
        ////////////////condition => to identify if all options are unchecked by user => hence check any option
        if (anyOption && item.checked && !modifiedCheckboxes.some(checkbox => checkbox.checked)) {
          const modifiedAnyOption = modifiedCheckboxes.find(option => option.name === anyOptionName);
          if (modifiedAnyOption) modifiedAnyOption.checked = true;
        }
      }
    }
    //////////////////Logic for Single-select////////////////////
    else {
      modifiedCheckboxes = _.cloneDeep(checkboxes).map(checkbox => {
        checkbox.checked = checkbox.name === item.name;
        return checkbox;
      });
    }
    props.onChange(modifiedCheckboxes);
  };

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <Typography classes={{ root: classes.formLabelRoot }}>{title}</Typography>
      <FormGroup row>
        {checkboxes.map((checkboxItem, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon color='secondary' />}
                onChange={e => handleChange(e, checkboxItem)}
                checked={checkboxItem.checked}
                disabled={checkboxItem.isReadOnly}
                disableFocusRipple
                disableTouchRipple
                style={{ background: 'none' }}
                size="small"
                aria-label="checkbox-item"
                inputProps={{ 'aria-label': 'checkbox-input' }}
              />
            }
            label={checkboxItem.label}
            classes={{ root: classes.formControlLabeRoot }}
            data-testid="clickable-item"
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};
