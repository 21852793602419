import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { serviceActions } from './async-actions';
import { initialState } from './initialState';
import { ISubmissionPreferences, IUnitDialoge } from './types';


const getUnitPreferences = createAction<{
  unitId: number;
}>('GET_UNIT_PREFERENCES');

const slice = createSlice({
  name: 'createOrderPreferences',
  initialState,
  reducers: {
    setPreferences(state, action: PayloadAction<ISubmissionPreferences>) {
      return {
        ...state,
        submissionPreferences: {
          ...state.submissionPreferences,
          ...action.payload,
        },
      };
    },
    setActivePlacementCounts(state, action: PayloadAction<{ rfo: number; sent: number; others: number }>) {
      return {
        ...state,
        submissionPreferences: {
          ...state.submissionPreferences,
          submissionLimits: { activePlacementCounts: { ...action.payload } },
        },
      };
    },
    setMiscellaneousData(
      state,
      action: PayloadAction<{ key: 'disableEmailContainer' | 'disablePacketContainer'; value: boolean }>,
    ) {
      state.miscellaneous = {
        ...state.miscellaneous,
        [action.payload.key]: action.payload.value,
      }
    },
    setIsUnitPreferenceNull(state, action: PayloadAction<boolean>) {
      state.isUnitPreferenceNull = action.payload;
    },
    setIsUnitInherited(state, action: PayloadAction<boolean>) {
      state.isUnitInherited = action.payload;
    },
    setIsFormDirty(state, action: PayloadAction<boolean>) {
      state.isFormDirty = action.payload;
    },
    setUnitDialoge(state, action: PayloadAction<IUnitDialoge>) {
      state.unitDialoge = action.payload;
    },
    setTabValue(state, action: PayloadAction<number>) {
      state.tabValue = action.payload
    },
    setCustomTabValue(state, action: PayloadAction<number>) {
      state.customTabvalue = action.payload;
    },
    setFormValue(state, action: PayloadAction<any>) {
      state.formValues = action.payload;
    },
  },
});

export const createOrderPreferenceActions = {
  ...slice.actions,
  ...serviceActions,
  getUnitPreferences
};

export const { name: createOrderPreferenceSliceKey, reducer: createOrderPreferenceReducer } = slice;
