import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { trackPromise } from 'react-promise-tracker';
import { put, getContext, call, select } from 'redux-saga/effects';
import { placementDetailsAction } from 'store/redux-store/placement-details/slice';
import { resetFailureStatus } from '../saga';
import { IPlacementSummaryRequest, placementSummaryActions } from '../slice';
import InitPlacementSummary, { IConsolidatedRequirementResponse } from '../init.types';
import { selectPlacementConsolidatedDetails } from '../consolidatedStoreSelectors';

const TrackAssignmentInfoWrapper = (fn, ...args) => trackPromise(fn(...args), 'placement-assignment-info-requirement');

export function* requestAssignmentInformationRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('assignmentInformationRequirement'));
    const placementService: PlacementService = yield getContext('placementService');
    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if(action.payload.isInitLoad && consolidatedDetails){
      response = {
        status : consolidatedDetails.summary.extensions?.status,
        data :consolidatedDetails.summary.extensions?.data
      }
    }

    if(!response?.status || !response?.data){
      response = yield call(
        TrackAssignmentInfoWrapper,
        placementService.getPlacementAssignmentInformation,
        action.payload.placementId,
      );
    }

    if (httpSuccess(response?.status)) {
      yield put(placementSummaryActions.setAssignmentInformation(response.data));
      yield put(placementSummaryActions.setExtensionsInfo(response.data.extensions));
      yield put(
        placementDetailsAction.selectedDirectHireDetail({
          directHireOptionId: response.data.directHireTypeId,
          directHireTypeDescription: response.data.directHireTypeDescription,
          directHireLastUpdated: response.data.directHireLastUpdated,
          directHireLastUpdatedBy: response.data.directHireLastUpdatedBy,
        }),
      );
    } else
      yield put(
        placementSummaryActions.setServiceFailureByKey({
          key: 'assignmentInformationRequirement',
          failureStatus: true,
        }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestAssignmentInformationRequirment',
        area: 'src/store/redux-store/placement-summary/common-saga/assignmentInformationRequirement.ts',
      },
    });
  }
}

export function processAssignmentInformationRequirement(
  response: IConsolidatedRequirementResponse<any>,
  context: InitPlacementSummary,
) {
  try {
    if (!httpSuccess(response.status)) {
      context.setServiceFailureByKey({ key: 'assignmentInformationRequirement', failureStatus: true });
      return;
    }
    const { data } = response;
    context.setAssignmentInformation(data);
    context.setExtensionsInfo(data.extensions);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'processAssignmentInformationRequirement',
        area: 'src/store/redux-store/placement-summary/common-saga/assignmentInformationRequirement.ts',
      },
    });
  }
}
