export const colors = {
  white: '#FFF',
  black: '#000',
  amnBlue: '#006FB9',
  amnLightBlue: '#009AD9',
  amnNavyBlue: '#0D2A3B',
  amnGrey: '#8E9195',
  containerBorder: '#ececec',
  text: '#555555',
  darkText: '#333333',
  amnDarkGrey: '#8C8C8C',
  amnDeepBlue: '#000029',
  amnDeeperBlue: '#003E68',
  amnPurple: '#52429A',
  amnLightGrey: '#cccccc',
  amnLightredGrey: '#f8f7f7',
  errorRed: '#f44336'
};

export const container = {
  borderRadius: '4px',
};
