import axios, { AxiosRequestConfig } from "axios";

export class CoverageService {
    private coverageBase: string;
  
    config?: AxiosRequestConfig = {};
  
    private cancellationToken = axios.CancelToken.source();
  
    private static currentInstance: CoverageService;
  
    private constructor() {
      this.coverageBase = globalThis?.app?.env?.REACT_APP_PLACEMENTS_SERVICE_BASE_URL;
    }
  
    static createInstance(): CoverageService {
      const activeInstance = new CoverageService();
      // activeInstance.cancellationToken = axios.CancelToken.source();
      // if (activeInstance.config) activeInstance.config.cancelToken = activeInstance.cancellationToken.token;
      return activeInstance;
    }
  
    static getInstance(): CoverageService {
      if (!this.currentInstance) this.currentInstance = this.createInstance();
      return this.currentInstance;
    }
  
    static cancelActiveRequests() {
      this.currentInstance.cancellationToken.cancel('RequestCancellation');
      // this.currentInstance = null;
    }
  
    cancelRequests() {
      this.cancellationToken.cancel('RequestCancellation');
      return CoverageService.getInstance();
    }
  
    cancelAndRevokeInstance() {
      this.cancellationToken.cancel('RequestCancellation');
      this.cancellationToken = axios.CancelToken.source();
      if (this.config) this.config.cancelToken = this.cancellationToken.token;
    }
  }