import { XGrid } from 'app/components/Common/XGrid/XGrid';

import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getExpandedGridTableColumns, getExpandedVersionColumns, getMainTableColumns } from '../Helpers/SkillCheklistsHelper';
import {
  CredentialingLookups,
  CredentialsSelection,
  candidateDetailsSelection,
  selectAllPassportSkillChecklist,
} from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { Credentials } from 'app/models/Credentialing/Credentials';
import { clinicalQualificationsSkillsChecklistsData } from '../../TableData';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import { XGridToolbar } from '@AMIEWEB/Common/XGrid/Toolbar/XGridToolbar';
import { useSkillsChecklistStyles } from './ClinicalQualifications.style';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { selectUser } from 'oidc/user.selectors';
import { AttachmentPreviewDialog } from '../../Attachments/AttachmentPreview/AttachmentPreviewDialog';
import { AttachmentAction } from '../../Attachments/AttachmentHoverView';
import uuid from 'react-uuid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGridProProps } from '@mui/x-data-grid-pro/models/dataGridProProps';
import { ExpandedDetailPanel } from '../Common/ExpandedDetailPanel';
import { VersionPanel } from '../Common/VersionPanel';
import { GridColDef } from '@mui/x-data-grid-pro';
import { isPositiveNumber } from 'app/helpers/componentHelpers';
import { getSkillChecklistById } from 'app/services/CandidateServices/CandidateServices';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { materialUiXGrid } from 'amn-ui-core';

export const SkillsChecklistExpandableTab = props => {
  const { classes } = useSkillsChecklistStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const credentials = useSelector(CredentialsSelection) as Credentials;
  const lookupselection = useSelector(CredentialingLookups);
  const user = useSelector(selectUser);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const passportSkillChecklists = useSelector(selectAllPassportSkillChecklist);

  const [versionOpen, setVersionOpen] = useState<boolean>(false);
  const [columns, setColumns] = useState<any>([]);
  const [clearChildMessages, setClearChildMessages] = useState<boolean>(false);
  const [detailsPanelHeight, setDetailsPanelHeight] = useState<number | 'auto'>('auto');
  const [showAttachmentPreview, setShowAttachmentPreview] = useState<{
    open: boolean;
    action: AttachmentAction;
    requirementID: number;
    requirementTypeID: number;
    travelerId: number;
    brandId: number;
  }>({
    open: false,
    action: AttachmentAction.view,
    requirementID: 0,
    requirementTypeID: 0,
    travelerId: 0,
    brandId: 0,
  });

  const getGridColumns = () => {
    return getMainTableColumns(true, false, true, setClearChildMessages, setShowAttachmentPreview, setVersionOpen)
  };

  useEffect(() => {
    setColumns(getGridColumns());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAttachmentDialogClose = () => {
    setShowAttachmentPreview({ ...showAttachmentPreview, open: false });
  };

  const getGridData = () =>
    clinicalQualificationsSkillsChecklistsData.getTableRows(
      credentials.clinicalQualifications.filter(item => item.subCategory === 'Skills Checklist'),
      lookupselection,
      false,
      () => { },
      0,
      true,
    );

  // DO NOT DELETE, WE WILL NEED THIS IN FUTURE
  // const enableDelete = React.useCallback(
  //   button => {
  //     if (
  //       Authorized(
  //         [userRoles.recruitment, userRoles.recruitment_Leadership, userRoles.recruitment_TeamMember],
  //         user.userInfo,
  //       )
  //     ) {
  //       if (selectedData.length !== 0 && !loading) {
  //         return false;
  //       } else {
  //         return true;
  //       }
  //     } else {
  //       return true;
  //     }
  //   },
  //   [loading, selectedData.length, user.userInfo],
  // );

  const bulkDelete = [
    {
      key: 'deletePreferences',
      Icon: DeleteOutlinedIcon,
      disabled: true, //enableDelete,
      onClick: () => { }, //setOpenDeleteDialog(true),
      tooltipProps: {
        tooltipContent: 'Delete Licensure/s',
      },
    },
  ];

  const resetColumns = () => {
    setColumns(getGridColumns());
    setVersionOpen(false);
  }

  const getDetailPanelContent = React.useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(({ row }) => {
    if (versionOpen) {
      setDetailsPanelHeight(row?.versionRows?.length * 50 + 50);
      return (
        <VersionPanel
          title={''}
          versionCount={row?.versionRows?.length}
          columns={getExpandedVersionColumns(setClearChildMessages, setShowAttachmentPreview)}
          rows={row?.versionRows || []}
        />
      );
    } else {
      return (
        <ExpandedDetailPanel
          title={''}
          rows={[row]}
          columns={getExpandedGridTableColumns()}
          secondGridTitle={`Versions (${row?.versionRows?.length})`}
          secondGridColumns={getExpandedVersionColumns(setClearChildMessages, setShowAttachmentPreview)}
          secondGridRows={row?.versionRows || []}
          dataUnavailableMessage={'No historical skills checklists available'}
        />
      );
    }
  }, [versionOpen]);

  const SkillsChecklistXGrid = React.useMemo(() => {
    return (
      <>
        <XGrid
          title="Skills Checklist"
          rows={getGridData()}
          columns={columns}
          getRowId={model => model.rowID}
          experimentalFeatures={{ columnGrouping: true }}
          rowHeight={60}
          headerHeight={70}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => 'auto'}
          disableSelectionOnClick={true}
          components={{
            Toolbar: XGridToolbar,
          }}
          className={classes.grid}
          toolbarProps={{
            rightActions: bulkDelete,
            bulkDelete,
            refreshButton: true,
            resetButton: true,
            resetColumns: () => resetColumns(),
            refreshGrid: () => {
              resetColumns();
              // handleRefreshGrid();
            },
          }}
        />
      </>
    );
  }, [columns, credentials.clinicalQualifications, lookupselection, resetColumns, versionOpen]);

  return (
    <>
      {SkillsChecklistXGrid}
      <AttachmentPreviewDialog
        data={showAttachmentPreview}
        onClose={onAttachmentDialogClose}
        clearChildMessages={clearChildMessages}
        setClearChildMessages={setClearChildMessages}
      />
    </>
  );
};
