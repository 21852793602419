import UnitDetailCard from '@AMIEWEB/Order/OrderCreationForm/Common/UnitDetailCard';
import { Grid } from 'amn-ui-core';
import React from 'react';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { FirstDayInstructionContainer } from './FirstDayInstruction';
import { useTranslation } from 'react-i18next';
import { ClientContactsContainer } from './ClientContacts/ClientContacts';

function UnitDetailSection({ firstDayInstructionsRef, clientContactsRef }) {
  const { t } = useTranslation();

  return (
    <>
      <Grid container direction="row" columnSpacing={2}>
        <Grid item lg={12} ref={clientContactsRef}>
          <UnitDetailCard
            icon={<DescriptionOutlinedIcon />}
            title={t('facility.units.unitDetails.additionalInformation.clientContacts')}
            children={<ClientContactsContainer />}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" columnSpacing={2}>
        <Grid item lg={12} ref={firstDayInstructionsRef}>
          <UnitDetailCard
            icon={<DescriptionOutlinedIcon />}
            title={t('facility.instructionsTab.firstDayInstructions')}
            children={<FirstDayInstructionContainer />}
            minHeight="215px"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default UnitDetailSection;
