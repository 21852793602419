import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectPlacementConfirmationTemplatesEditor } from 'store/redux-store/placement-client-confirmation-doc-editor/selector';
import { DocumentsPanel } from '../customComponents/DocumentsPanel';
import { ActionModal } from '../customComponents';
import { pConfirmationDocEditorActions } from 'store/redux-store/placement-client-confirmation-doc-editor/slice';

export const EngineTemplates = ({ selectedDoc, onDocumentClick, setSideView }) => {
  const { t } = useTranslation();
  const {
    documents: documentList,
    hasDraftTemplate,
    pageAttributes: { userHasOptedToDeleteExistingDraft },
  } = useSelector(selectPlacementConfirmationTemplatesEditor);

  // @ts-ignore
  const [modalProps, setModalProps] = React.useState<IActionModalProps>({ open: false });
  // @ts-ignore
  const closeModal = React.useCallback(() => setModalProps({ open: false }), []);

  const dispatch = useDispatch();

  const handleGenerate = React.useCallback(() => {
    if (hasDraftTemplate && !userHasOptedToDeleteExistingDraft)
      setModalProps({
        open: true,
        title: t('templateEditor.modal.title.overrideAmieWebDraft'),
        message: t('templateEditor.modal.message.overrideConfirmationDraft'),
        primaryBtns: [
          {
            text: t('cancelModal.confirmation.yes'),
            onClick: () => {
              dispatch(pConfirmationDocEditorActions.business_switchToDefaultTemplateDraftView());
              dispatch(pConfirmationDocEditorActions.markCurrentDraftForHardDelete(true));
            },
          },
        ],
        secondaryBtns: [
          {
            text: t('cancelModal.confirmation.cancel'),
            onClick: closeModal,
          },
        ],
      });
    else dispatch(pConfirmationDocEditorActions.business_switchToDefaultTemplateDraftView());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDraftTemplate, userHasOptedToDeleteExistingDraft]);

  return (
    <>
      <DocumentsPanel
        documentList={documentList}
        handleGenerate={handleGenerate}
        onDocumentClick={onDocumentClick}
        selectedDoc={selectedDoc}
        noDocCreatedMessage={t('templateEditor.templateName.search.noConfirmationTemplateCreated')}
        noDocAvailbaleMessage={t('templateEditor.templateName.search.noConfirmationTemplateCreated')}
        docType="confirmation-doc"
      />
      <ActionModal {...modalProps} />
    </>
  );
};
