/* eslint-disable i18next/no-literal-string */
import * as React from 'react';
import { Grid, Typography, useMediaQuery, useTheme, Select, MenuItem } from 'amn-ui-core';
import { Pagination as MUIPagination } from '@mui/material';
import styledComponent, { css } from 'styled-components';
import { useState } from 'react';

export interface IPaginationProps {
  hideResultsPerPageSelection?: boolean;
  hidePageCount?: boolean;
  isLoading: boolean;
  pagination: IPaginationItem;
  pageOptions?: number[];
  handleResultsPerPageChange: (value: number, page: number) => void;
  handlePageChange: (value: number, resultsPerPage: number) => void;
}

export interface IPaginationItem {
  page: number;
  resultsPerPage: number;
  totalResults: number;
}

const ComponentWrapper = styledComponent(Grid)`
  align-items: center;
  display: flex;
  width: 100%;

  @media (min-width: 960px) {
    padding-right: 15px;
  }
`;

const PaginationWrapper = styledComponent(Grid)`
  .MuiButtonBase-root.MuiPaginationItem-root{
    font-size:14px;
  }
  
  @media (max-width: 959px) {
    &.MuiGrid-root {
      margin-bottom: 10px;    
    }
    .MuiPagination-ul {
      justify-content: center;
    }
  }
`;

const PageGrid = styledComponent(({ hideResultsPerPageSelection, ...rest }) => React.createElement(Grid, rest))`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${p =>
    p.hideResultsPerPageSelection &&
    css`
      justify-content: flex-end;
    `};

  @media (min-width: 960px) {
    justify-content: flex-end;
    margin-top: 10px;
  }
`;

const CenterGrid = styledComponent(Grid)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledTypography = styledComponent(Typography)`
  &.MuiTypography-root {
    margin-left: 10px;
    font-size:14px;
  }
`;

export const Pagination = (props: IPaginationProps) => {
  const [resultsPerPage, setResultsPerPage] = useState(
    !!props.pagination?.resultsPerPage ? props.pagination?.resultsPerPage : 0,
  );
  const [page, setPage] = useState(props.pagination.page);
  const [pageCount, setPageCount] = useState(
    Math.ceil(
      !!props.pagination?.resultsPerPage ? (props.pagination?.totalResults || 0) / props.pagination?.resultsPerPage : 0,
    ),
  );
  const pageOptions = props.pageOptions || [10, 25, 50, 100];

  React.useEffect(() => {
    if (!!props.pagination?.resultsPerPage) {
      setResultsPerPage(props.pagination?.resultsPerPage);
      setPageCount(
        Math.ceil(
          !!props.pagination?.resultsPerPage ? props.pagination?.totalResults / props.pagination?.resultsPerPage : 0,
        ),
      );
    }
    setPage(props.pagination.page);
  }, [props.pagination, props.pagination?.totalResults]);

  const handleResultsPerPageChange = (event: any) => {
    setResultsPerPage(event.target.value);
    setPageCount(Math.ceil(props.pagination.totalResults / event.target.value));
    setPage(1);
    props.handleResultsPerPageChange(event.target.value, 1);
  };

  const handlePageChange = (value: number) => {
    setPage(value);
    props.handlePageChange(value, resultsPerPage);
  };
  const isWideScreen = useMediaQuery(useTheme().breakpoints.up('md')) ?? false;

  return (
    <ComponentWrapper container>
      <PaginationWrapper
        item
        xs={12}
        md={(props.hideResultsPerPageSelection && props.hidePageCount) ? 12 : 5}
        data-testid="pagination-wrapper"
      >
        {pageCount > 0 && !props.isLoading && (
          <MUIPagination
            siblingCount={isWideScreen ? 1 : 0}
            color="secondary"
            count={pageCount}
            page={page}
            onChange={(e, value) => handlePageChange(value)}
          />
        )}
      </PaginationWrapper>
      {isWideScreen && (
        <>
          {pageCount > 0 && !props.isLoading && !props.hideResultsPerPageSelection && (
            <CenterGrid item xs={12} md={3}>
              <Select
                disableUnderline
                value={resultsPerPage}
                onChange={handleResultsPerPageChange}
                data-testid="results-per-page-select"
                size="small"
              >
                {pageOptions.map((option, index) => {
                  return (
                    <MenuItem key={index} value={option}>
                      {option.toString()}
                    </MenuItem>
                  );
                })}
              </Select>
              <StyledTypography>Results Per Page</StyledTypography>
            </CenterGrid>
          )}
          {!props.hidePageCount && (
            <PageGrid
              item
              hideResultsPerPageSelection={props.hideResultsPerPageSelection}
              xs={12}
              md={props.hideResultsPerPageSelection ? 7 : 4}
            >
              {pageCount > 0 && !props.isLoading && <StyledTypography>{`${page} of ${pageCount} page(s)`}</StyledTypography>}
            </PageGrid>
          )}
        </>
      )}
    </ComponentWrapper>
  );
};
