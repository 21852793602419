import { makeStyles } from 'tss-react/mui';

export const useUnitDetailsWrapperStyles = makeStyles()(theme => ({
  containerElement: {
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
  },
  container: {
    display: 'flex',
  },
  addJustButton: {
    marginBottom: '5px',
  },
  buttonWidth: {
    marginRight: theme.spacing(1),
  },
  sectionsContainer: {
    flexDirection: 'row',
    maxWidth: '100%',
    minWidth: '60%',
    marginLeft: '12px',
  },
  section: {
    marginTop: '12px',
  },
}));

export const useUnitNavigationStyles = makeStyles()(theme => ({
  leftTab: {
    position: 'sticky',
    top: '12px',
    height: '90vh',
    width: '15rem',
    padding: '0.1rem',
    border: `1px solid ${theme.palette.system.lightFadedGray}`,
    borderRadius: '4px',
    '@media (min-width: 768px) and (max-width: 1024px)': {
      width: '12rem',
    },
  },
  menu: {
    margin: '0.5rem',
  },
  mainMenuItem: {
    height: '19px',
    textAlign: 'left',
    fontSize: 14,
    cursor: 'pointer !important',
    letterSpacing: '0px',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-line',
    opacity: '1',
  },
  subMenuItem: {
    height: '16px',
    textAlign: 'left',
    marginLeft: '9px',
    fontSize: 12,
    cursor: 'pointer !important',
    letterSpacing: '0px',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-line',
    opacity: '1',
  },
  tab: {
    '& .MuiButton-label': {
      justifyContent: 'start',
    },
    width: '100%',
    wordBreak: 'keep-all',
    textAlign: 'start',
    margin: '7px 10px',
  },
  selected: {
    backgroundColor: theme.palette.system.violet,
    height: '26px',
    width: '4px',
    '@media (min-width: 768px) and (max-width: 1024px)': {
      height: '22px',
    },
  },
  unselected: {
    height: '26px',
    width: '4px',
  },
  textColorSelected: {
    color: theme.palette.system.violet,
  },
  textFontSelected: {
    fontWeight: 500,
  },
  textColorUnselected: {
    color: theme.palette.components.typography.color,
    '&:hover': {
      color: theme.palette.system.violet,
    },
  },
}));
