import { InputAdornment, TextField, Typography } from 'amn-ui-core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from '../../../ComponentLibrary/DateRangeNew';
import { makeStyles } from 'tss-react/mui';
import { addDays, format, isSaturday, isSunday, isWeekend } from 'date-fns';
import { FormatDate } from '@AMIEWEB/Notification/Tasks/Common/FormatData';
import { useDispatch, useSelector } from 'react-redux';
import { taskDetailsActions } from '../store/Tasks.redux';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import moment from 'moment';
import { selectUser } from 'oidc/user.selectors';
import { selectDueDateFirstView, selectedIsExternalTaskUpdated, selectedUpdatedId } from '../store/Tasks.selectors';
import { IDueDateView, IExternalCC, TaskStatus } from 'app/models/Tasks/Tasks';

const useStyles = makeStyles<{ isPastDueDate: boolean }>()((theme, props) => ({
  dueDateInput: {
    '& .MuiOutlinedInput-input': {
      fontSize: '12px',
    },
  },
  dateField: {
    width: '138px',
    '& .MuiOutlinedInput-root': {
      paddingRight: '4px',
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: props.isPastDueDate ? theme.palette.system.errorRed : theme.palette.primary.main,
        borderWidth: '2px',
      },
    },
  },
  adornment: {
    backgroundColor: theme.palette.system.paleBlue,
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    cursor: 'pointer',
  },
  adornmentText: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    fontWeight: '500',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: 'inherit',
  },
}));

export const ExternalTaskDateRenderer = ({
  details,
  setIsPopperOpen,
}: {
  details: any;
  setIsPopperOpen?: (e: boolean) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUser = useSelector(selectUser);
  const taskDueDateFirstView = useSelector(selectDueDateFirstView);
  const isExternalTaskUpdatedFlag = useSelector(selectedIsExternalTaskUpdated);
  const externalTaskUpdatedId = useSelector(selectedUpdatedId);
  const [isDueDateFirstView, setDuedateFirstView] = useState<boolean>(true);
  const [dueDateValue, setDueDateValue] = useState<any>('');
  const [isPastDueDate, setIsPastDueDate] = useState<boolean>(false);
  const { classes } = useStyles({ isPastDueDate });
  const [isHovered, setHovered] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const date = new Date();

  useEffect(() => {
    if (taskDueDateFirstView?.length > 0) {
      const presentDueDateDetails = taskDueDateFirstView?.find(task => task?.taskId === details?.taskId);
      setDuedateFirstView(presentDueDateDetails?.isFirstView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskDueDateFirstView]);

  useEffect(() => {
    if (details) {
      const extractedDate = format(new Date(details?.dueDate), 'MM/dd/yyyy');
      setDueDateValue(extractedDate);
    }
    setIsDisabled(
      details?.createdBy?.id !== activeUser?.userInfo?.employeeId ||
        details?.statusId === TaskStatus.Cancelled ||
        details?.statusId === TaskStatus.Completed,
    );
    moment().isAfter(moment(details?.dueDate).endOf('day')) ? setIsPastDueDate(true) : setIsPastDueDate(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  const addBusinessDay = (selectedDate: Date) => {
    let newDate = addDays(selectedDate, 1);

    // Check if the new date is a weekend (Saturday or Sunday)
    while (isWeekend(newDate) || isSaturday(newDate) || isSunday(newDate)) {
      newDate = addDays(newDate, 1);
    }
    const formattedDate = new Date(newDate?.setHours(18));
    return formattedDate;
  };

  const successMessage = () => {
    dispatch(
      globalActions.setSnackBar({
        message: t('notification.createTask.taskEditSuccess'),
        severity: 'success',
      }),
    );
  };

  const updateTask = async (UpdatedDateValue: Date) => {
    const customDateFormat = UpdatedDateValue?.setHours(23, 59, 59);
    const initialState = {
      assignedTo: details?.assignedTo?.id,
      cc: details?.cc?.map(user => user?.id),
      task: details?.task,
      taskName: details?.taskName,
      taskCategory: details?.taskCategory,
      description: details?.description,
      dueDate: new Date(details?.dueDate),
      categorySubType1: details?.subCategory1,
      categorySubType2: details?.subCategory2,
    };
    const updateTask = {
      externalTaskId: details?.placementId + '-' + details?.candidateId + '-' + details?.taskNameId,
      id: details?.taskId,
      description: details?.description,
      dueDate: FormatDate(customDateFormat),
      lastUpdatedDate: FormatDate(new Date()),
      lastUpdatedByName: activeUser?.userInfo?.firstName + ' ' + activeUser?.userInfo?.lastName,
      lastUpdatedById: activeUser?.userInfo?.employeeId,
      ccs: details?.cc?.map(data => {
        const ccData: IExternalCC = {
          ccFirstName: data?.firstName,
          ccLastName: data?.lastName,
          ccId: data?.id,
        };
        return ccData;
      }),
      categorySubType1: details?.subCategory1,
      categorySubType2: details?.subCategory2,
    };
    dispatch(taskDetailsActions.sendUpdateExternalTask({ updateTask, initialTask: initialState }));
    dispatch(taskDetailsActions.setIsUpdatingTaskFlag(true));
    const updatedDateView: IDueDateView[] = [
      {
        isFirstView: false,
        taskId: details?.taskId,
      },
    ];
    dispatch(taskDetailsActions.setDuedateFirstView(updatedDateView));
  };

  const incrementDate = () => {
    const updatedValue = addBusinessDay(new Date(details?.dueDate));
    updateTask(updatedValue);
  };

  useEffect(() => {
    if (isExternalTaskUpdatedFlag && externalTaskUpdatedId) {
      successMessage();
      dispatch(taskDetailsActions.setTaskUpdatedId(undefined));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExternalTaskUpdatedFlag, externalTaskUpdatedId]);

  return (
    <div
      style={{
        pointerEvents: isDisabled ? 'none' : 'auto',
      }}
    >
      {isDueDateFirstView ? (
        <TextField
          variant="outlined"
          size="small"
          error={isPastDueDate}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          className={classes.dateField}
          value={dueDateValue}
          label={t('notification.createTask.dueDateFieldLabel')}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {isHovered && !isDisabled && (
                  <div className={classes.adornment} onClick={() => incrementDate()}>
                    <Typography className={classes.adornmentText}>{t('notification.createTask.plusOne')}</Typography>
                  </div>
                )}
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <DateRangePicker
          variant="outlined"
          placeholder={t('notification.createTask.dueDateFieldLabel')}
          secondaryLabel={t('notification.createTask.dueDateFieldLabel')}
          className={classes.dueDateInput}
          useMaxWidth={true}
          single={true}
          value={dueDateValue}
          initialDateRange={dueDateValue}
          minDate={addDays(date, -1)}
          maxWidth={136}
          isDisabled={isDisabled}
          disableEdit={true}
          helperTextDisabled={true}
          actionBar={false}
          clickAway={true}
          keepClickAwayVal={true}
          trailingIconShow
          popperPlacement={'bottom-end'}
          fallbackPlacements={['top-end']}
          onChange={e => {
            const dueDate = e?.endDate ? FormatDate(e?.endDate?.setHours(18)) : '';
            setDueDateValue(dueDate);
            e?.endDate && updateTask(new Date(e?.endDate?.setHours(18)));
          }}
          onOpen={setIsPopperOpen}
          error={isPastDueDate}
          definedRanges={[
            {
              label: t('notification.createTask.dueDateLabels.today'),
              startDate: date,
              endDate: date,
              useLabelAsValue: false,
            },
            {
              label: t('notification.createTask.dueDateLabels.tomorrow'),
              startDate: addDays(date, 1),
              endDate: addDays(date, 1),
              useLabelAsValue: false,
            },
            {
              label: t('notification.createTask.dueDateLabels.2Days'),
              startDate: addDays(date, 2),
              endDate: addDays(date, 2),
              useLabelAsValue: false,
            },
            {
              label: t('notification.createTask.dueDateLabels.7Days'),
              startDate: addDays(date, 7),
              endDate: addDays(date, 7),
              useLabelAsValue: false,
            },
            {
              label: t('notification.createTask.dueDateLabels.30Days'),
              startDate: addDays(date, 30),
              endDate: addDays(date, 30),
              useLabelAsValue: false,
            },
          ]}
        />
      )}
    </div>
  );
};
