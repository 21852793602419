import React, { useMemo, useState } from 'react';
import { useDebounce } from 'utils/customHooks/useDebounce';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { FilterTypeAhead, ITypeAheadOption } from './FilterTypeAhed';

export function instanceOfTypeAhead(object: any): object is ITypeAheadOption {
  return object && typeof object === 'object' && 'label' in object && 'value' in object && 'object' in object;
}

export const AsyncTypeAhead = props => {
  const [options, setOptions] = useState<ITypeAheadOption[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [keyword, keywordValue, setKeyword] = useDebounce<string>('', 1500);
  const trackerArea = `${props.name}-async-typeAhead`;
  const { promiseInProgress } = usePromiseTracker({ area: trackerArea, delay: 0 });

  const fetchOptions = async () => {
    if (keyword) {
      const response = await props.fetchOptions(keyword);
      setOptions(
        (response || []).map(opt => ({
          object: opt,
          label: opt.name,
          value: opt.value,
        })),
      );
    }
  };

  useMemo(() => {
    trackPromise(fetchOptions(), trackerArea);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  return (
    <FilterTypeAhead
      options={keyword ? options : []}
      placeholder={props.filterName}
      variant={'outlined'}
      size={'small'}
      isOptionsLoading={promiseInProgress}
      hasAsyncOptions
      searchOnCharacterCount={3}
      onChange={newValue => props.onChange(props.isMultiSelect ? [...props.value, newValue] : newValue)}
      onInputChange={key => {
        setKeyword(key);
      }}
      enableCustomScroll
      removeCloseIcon
      {...props}
    />
  );
};
