import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Theme, Grid, CircularProgress } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { Cancel } from 'app/components/Common/CancelModal/Cancel';
import { useTranslation } from 'react-i18next';
import { AddEducation } from './AddEducation';
import _ from 'lodash';
import { candidateDetailsSelection, candidateEducationSelection } from '../../Profile/CandidateDetails.selector';
import { useDispatch, useSelector } from 'react-redux';
import { candidateDetailActions } from '../../Profile/CandidateDetails.redux';
import { Education } from 'app/models/Candidate/CandidateProfile';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { postCandidateEducation } from 'app/services/CandidateServices/CandidateServices';
import { selectUser } from 'oidc/user.selectors';
import countriesData from 'app/assets/jsons/Country.json';
import { getDegreeList, getMajorList } from 'app/services/SharedServices/SharedServices';
import { useReadOnly } from 'oidc/userRoles';
import { httpSuccess } from 'app/services/serviceHelpers';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContainer: {
    minWidth: '530px',
  },
  dialogContent: {
    paddingTop: 0,
    overflow: 'hidden',
    'overflow-y': 'scroll',
  },
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50vh',
  },
}));

export const MONTHS = [
  { id: 1, label: 'January', value: 'January' },
  { id: 2, label: 'February', value: 'February' },
  { id: 3, label: 'March', value: 'March' },
  { id: 4, label: 'April', value: 'April' },
  { id: 5, label: 'May', value: 'May' },
  { id: 6, label: 'June', value: 'June' },
  { id: 7, label: 'July', value: 'July' },
  { id: 8, label: 'August', value: 'August' },
  { id: 9, label: 'September', value: 'September' },
  { id: 10, label: 'October', value: 'October' },
  { id: 11, label: 'November', value: 'November' },
  { id: 12, label: 'December', value: 'December' },
];

enum ExcludedCountries {
  VIRGIN_ISLANDS = 213,
  PUERTO_RICO = 17,
}

const getCountryOpts = () =>
  countriesData.countries
    .filter(x => x.ID !== ExcludedCountries.PUERTO_RICO && x.ID !== ExcludedCountries.VIRGIN_ISLANDS)
    .map(e => ({
      label: e.Description,
      value: e.CountryDialingCode,
      id: e.ID,
    }));

const getYearOpts = () => {
  const year = new Date().getFullYear() - 60;
  return Array.from(new Array(71), (val, index) => {
    return {
      id: year + index,
      label: (year + index).toString(),
      value: index,
    };
  });
};

export const AddEducationDialog = props => {
  const { open, handleClose } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const formMethods = useForm({ mode: 'all' });
  const [cancel, setCancel] = useState<boolean>(false);
  const candidateEdcuationSelector = useSelector(candidateEducationSelection);
  const dispatch = useDispatch();
  const [majorList, setMajorList] = useState<any[]>([]);
  const [degreeList, setDegreeList] = useState<any[]>([]);
  const [countryOptions] = useState(getCountryOpts());
  const [yearOptions] = useState(getYearOpts());
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const user = useSelector(selectUser);
  const { readOnly } = useReadOnly();
  const {
    handleSubmit,
    setError,
    formState: { dirtyFields, errors, touched },
  } = formMethods;

  useEffect(() => {
    if (majorList.length === 0 || degreeList.length) {
      fetchEducation();
      setMajorList(majorList);
      setDegreeList(degreeList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEducation = async () => {
    setIsLoading(true);
    var majorResult = await getMajorList();
    var mapMajor = majorResult?.data.map(x => {
      return {
        id: x.value,
        label: x.name,
      };
    });
    setMajorList(mapMajor);
    var degreeResult = await getDegreeList();
    var mapDegree = degreeResult?.data.map(x => {
      return {
        id: x.value,
        label: x.name,
      };
    });
    setDegreeList(mapDegree);

    setIsLoading(false);
  };

  const validateEducationForm = data => {
    var isEducationFormValid = true;
    if (data.pSchool?.length > 100) {
      setError('pSchool', {
        type: 'maxLength',
        message: 'max character limit: 100',
      });
      isEducationFormValid = false;
    }
    if (data.piCity && data.piCity?.length > 100) {
      setError('piCity', {
        type: 'maxLength',
        message: 'max character limit: 100',
      });
      isEducationFormValid = false;
    }
    return isEducationFormValid;
  };

  const onSubmit = async (data, e) => {
    if (!validateEducationForm(data)) return;
    const state = typeof data.pState === 'string' ? data.pState : data.pState?.label;
    const bodyPayload = {
      candidateId: candidateDetails.travelerId,
      brandId: candidateDetails.brandId,
      schoolId: 0,
      graduatedOn: `${data.pMonth?.id}/${data.pYear?.id}`,
      graduatedOnYear: parseInt(data.pYear?.id),
      graduatedOnMonth: data.pMonth?.id || null,
      school: data.pSchool,
      majorId: parseInt(data.pMajor?.id),
      major: data.pMajor?.label,
      degreeId: parseInt(data.pDegree?.id),
      degree: data.pDegree?.label,
      location: {
        address1: null,
        address2: null,
        city: typeof data.piCity === 'string' ? data.piCity : data.piCity?.label,
        stateProvinceID: typeof data.pState === 'string' ? '' : data.pState?.id,
        state: state,
        country: data.pCountry?.label,
        zip: data.piZip || null,
        countryId: data.pCountry?.id,
        latitude: null,
        longitude: null,
      },
      currentEmployeeId: user.userInfo?.employeeId || 8410,
      currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
    };
    setIsLoading(true);
    const response = await postCandidateEducation(bodyPayload);
    if (httpSuccess(response.status)) {
      dispatch(
        globalActions.setSnackBar({
          message: t('candidate.educationCard.addSuccessEducation'),
          severity: 'success',
        }),
      );
      const result: Education = {
        graduatedOn: `${data.pMonth?.id}/${data.pYear?.id}`,
        graduatedOnYear: response.data.graduatedOnYear,
        graduatedOnMonth: response.data.graduatedOnMonth,
        schoolId: response.data.schoolId,
        school: response.data.school,
        majorId: response.data.majorId,
        major: response.data.major,
        degreeId: response.data.degreeId,
        degree: response.data.degree,
        location: {
          address1: '',
          city: response.data.location.city || null,
          stateProvinceID: '',
          state: response.data.location.state,
          country: response.data.location.country,
          zip: response.data.location.zip,
        },
      };
      dispatch(candidateDetailActions.setEducation([...candidateEdcuationSelector, result]));
      setIsLoading(false);
    } else {
      dispatch(
        globalActions.setSnackBar({
          message: t('common.failedLoad'),
          severity: 'error',
        }),
      );
      setIsLoading(false);
    }
    handleClose();
  };

  const onError = () => {
    setSaveDisabled(true);
  };

  const handleCancel = () => {
    if (!_.isEmpty(errors) || !_.isEmpty(dirtyFields) || !_.isEmpty(touched)) {
      setCancel(true);
    } else {
      yesAction();
    }
  };

  const yesAction = () => {
    handleClose();
    setCancel(false);
  };
  const noAction = () => {
    setCancel(false);
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          {isLoading ? (
            <CircularProgress className={classes.MuiCircularProgressRoot} />
          ) : (
            <>
              <GenericDialog
                variant={'blue'}
                classes={{ paper: classes.dialogContainer }}
                open={open}
                disablePortal
                disableEscapeKeyDown
                dialogTitleProps={{
                  text: `${t('candidate.dialogActions.AddEducationTitle')}`,
                  closeButton: true,
                  expandable: true,
                }}
                dialogActions={[
                  {
                    onClick: handleCancel,
                    text: 'CANCEL',
                    variant: 'contained',
                    color: 'tertiary',
                  },
                  {
                    text: `${t('candidate.dialogActions.SaveEducation')}`,
                    variant: 'contained',
                    type: 'submit',
                    disabled: saveDisabled || readOnly,
                    tooltipProps: readOnly
                      ? {
                          tooltipContent: t('global.readOnlyTooltip'),
                        }
                      : undefined,
                  },
                ]}
                dialogContentProps={{
                  classes: { root: classes.dialogContent },
                }}
                onClose={handleCancel}
              >
                <Grid spacing={3}>
                  <Grid item xs={12}>
                    <AddEducation
                      majorData={majorList}
                      degreeData={degreeList}
                      setSaveDisabled={setSaveDisabled}
                      countriesData={countryOptions}
                      monthsData={MONTHS}
                      yearsData={yearOptions}
                    />
                  </Grid>
                </Grid>
              </GenericDialog>
              {cancel && <Cancel openDialog={cancel} handleConfirmAction={yesAction} handleCancelAction={noAction} />}
            </>
          )}
        </form>
      </FormProvider>
    </>
  );
};
