import { Avatar, Divider, Typography } from 'amn-ui-core';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useEffect, useMemo, useState } from 'react';
import DocumentIcon from 'app/assets/images/description-24px.svg';
import { CustomTooltip } from '@AMIEWEB/Common';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FacilityUnitActions } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.redux';
import { missingField } from 'app/constants';
import { checkQualificationCount, transformUnitList } from './helper';
import { cloneDeep } from 'lodash';
import { UnitCard } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/CustomComponents/UnitCard';
import { useSelectedUnitDetails, useIsUserAccountManager, useUnitStatusInActive } from '../unitHelper';
import { selectOrderRequirementTypes } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { selectCertificatesFullName } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';

const useQualificationStyles = makeStyles()(theme => ({
  tooltip: {
    color: theme.palette.components.tooltip.color,
    fontWeight: 'normal',
    fontSize: '14px !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
}));

export enum Qualifications {
  Licenses,
  Certifications,
  skills,
}

interface ICardLegendData {
  abbreviation: string;
  isDocAttachmentRequired: boolean;
  isRequired: boolean;
  tooltipDescription: string;
}

export interface IQualificationSubSectionData {
  abbreviation: string;
  documentAttachmentRequired: boolean;
  isRequired: boolean;
  yearsOfExperience: number;
  licenseAbbreviation: string;
  stateAbbreviation: string;
  isMultiState: boolean;
  text: string;
  licenseDescription: string;
  requirementTypeId?: number;
  certificateDescription?: string;
}

export interface IFormatQualification {
  abbreviation: string;
  isDocAttachmentRequired: boolean;
  isRequired: boolean;
  tooltipDescription: string;
}

/**
 * Renders qualification component
 */
const UnitQualifications = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAuthorized = useIsUserAccountManager();
  const params = useParams<{ id: string; unitId: string }>();
  const selectedUnitDetail = useSelectedUnitDetails();
  const isUnitInActive = useUnitStatusInActive();
  const unitQualification = selectedUnitDetail?.qualification;

  const totalQualificationCount = checkQualificationCount(
    unitQualification?.certifications?.length,
    unitQualification?.licenses?.length,
    unitQualification?.skillChecklists?.length,
  );

  const qualificationTypeId = {
    licenses: 'licenses',
    certifications: 'certifications',
    skillsChecklist: 'skillsChecklist',
  };

  useEffect(() => {
    if (!selectedUnitDetail?.length) dispatch(FacilityUnitActions.requestFacilityUnitsList({ id: Number(params?.id) }));
  }, [params?.id]);

  useEffect(() => {
    const requirementTypeIds = unitQualification?.certifications?.map(item => item.requirementTypeId);
    if (requirementTypeIds?.length) {
      dispatch(
        FacilityUnitActions.requestFacilityUnitsCertificatesFullName({ requirementTypeIds: requirementTypeIds }),
      );
    }
  }, [unitQualification?.certifications]);

  return (
    <UnitCard
      title={`${t('facility.units.qualifications.title')}:  ${totalQualificationCount}`}
      rightIcon={true}
      showEditIcon={isAuthorized && !isUnitInActive}
    >
      <LayoutGridItem container item p="12px" rowSpacing={2}>
        <UnitContent
          title={t('facility.units.qualifications.licenses')}
          data={unitQualification?.licenses}
          type={Qualifications.Licenses}
          id={qualificationTypeId.licenses}
        />
        <Divider sx={{ width: '98%' }} />
        <UnitContent
          title={t('facility.units.qualifications.certifications')}
          data={unitQualification?.certifications}
          type={Qualifications.Certifications}
          id={qualificationTypeId.certifications}
        />
        <Divider sx={{ width: '98%' }} />
        <UnitContent
          title={t('facility.units.qualifications.skills')}
          data={unitQualification?.skillChecklists}
          type={Qualifications.skills}
          id={qualificationTypeId.skillsChecklist}
        />
      </LayoutGridItem>
    </UnitCard>
  );
};

/**
 * Renders required and preferred with respective item
 */
const CardLegend = ({ dataItem, index, id }: { dataItem: ICardLegendData; index: number; id: string }) => {
  const { classes } = useQualificationStyles();
  return (
    <LayoutGridItem container item pb="12px" columnSpacing={2} key={index} id={`${id}-container-${index}`}>
      <LayoutGridItem item>
        <Avatar
          variant={dataItem?.isRequired ? 'primary' : 'green'}
          id={`${id}-${index}-avatar`}
          sx={theme => ({
            width: '24px',
            height: '24px',
            color: theme.palette.components.avatar.color,
            fontSize: '14px !important',
          })}
        >
          {dataItem?.isRequired ? 'R' : 'P'}
        </Avatar>
      </LayoutGridItem>
      <LayoutGridItem item sx={{ display: 'flex', alignItems: 'center' }}>
        <CustomTooltip
          arrow
          standardMargin
          tooltipContent={dataItem.tooltipDescription}
          children={
            <Typography
              pr={'12px'}
              className={classes.tooltip}
              sx={theme => ({
                width: dataItem.abbreviation.length > 20 ? '250px' : 'auto',
                color: theme.palette.components.typography.color,
              })}
              id={`${id}-${index}-abbreviation`}
            >
              {dataItem.abbreviation}
            </Typography>
          }
        />
        {dataItem.isDocAttachmentRequired && (
          <img src={DocumentIcon} alt={`doc-img`} width="16px" height="20px" id={`${id}-${index}-document-img`} />
        )}
      </LayoutGridItem>
    </LayoutGridItem>
  );
};

/**
 * Renders unit content for qualification
 */
const UnitContent = ({
  title,
  data,
  type,
  id,
}: {
  title: string;
  data: IQualificationSubSectionData[];
  type: number;
  id: string;
}) => {
  let firstHalf = [];
  let secondHalf = [];
  const { certifications: certificationsDirectory } = useSelector(selectOrderRequirementTypes);
  const [mappedUnitList, setMappedUnitList] = useState<IFormatQualification[]>(transformUnitList(type, data));
  const certificatesFullName = useSelector(selectCertificatesFullName);

  useEffect(() => {
    if (type === Qualifications.Certifications && certificationsDirectory.length > 0) {
      const subCertifications = certificationsDirectory.filter(lookup => !lookup.category) || [];
      const distinguishedCertsAndSubCerts = data?.map(savedCert => ({
        ...savedCert,
        abbreviation: !!subCertifications.find(lookup => lookup.requirementTypeID === savedCert.requirementTypeId)
          ? `CERT - ${savedCert.abbreviation}`
          : savedCert.abbreviation,
        certificateDescription: certificatesFullName.find(i => i.requirementTypeId === savedCert.requirementTypeId)
          ?.description,
      }));
      setMappedUnitList(transformUnitList(type, distinguishedCertsAndSubCerts));
    } else {
      setMappedUnitList(transformUnitList(type, data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certificationsDirectory, data]);

  const qualificationDetails = useMemo(() => {
    if (mappedUnitList?.length && mappedUnitList?.length > 3) {
      const clonedArray = cloneDeep(mappedUnitList);
      const middleIndex = Math.ceil(mappedUnitList.length / 2);
      firstHalf = clonedArray.splice(0, middleIndex);
      secondHalf = clonedArray;
    } else {
      firstHalf = mappedUnitList;
    }
    return (
      <LayoutGridItem container item direction="column" rowSpacing={2}>
        <LayoutGridItem item>
          <Typography
            sx={theme => ({
              fontSize: '14px !important',
              color: theme.palette.components.typography.color,
              fontWeight: 'bold',
            })}
          >{`${title} (${mappedUnitList?.length})`}</Typography>
        </LayoutGridItem>
        {mappedUnitList?.length ? (
          <LayoutGridItem container item rowSpacing={1}>
            <LayoutGridItem xs={6}>
              {firstHalf.map((item, index) => (
                <CardLegend dataItem={item} index={index} id={id} />
              ))}
            </LayoutGridItem>
            <LayoutGridItem xs={6}>
              {secondHalf.map((item, index) => (
                <CardLegend dataItem={item} index={firstHalf.length++} id={id} />
              ))}
            </LayoutGridItem>
          </LayoutGridItem>
        ) : (
          missingField
        )}
      </LayoutGridItem>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedUnitList]);

  return <>{qualificationDetails}</>;
};

export default UnitQualifications;
