import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import {
  FacilityContactSliceKey,
  FacilityContactsReducer,
} from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.redux';
import { facilityClientContactsSaga } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.saga';
import {
  FacilityUnitActions,
  FacilityUnitKey,
  FacilityUnitReducer,
} from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.redux';
import { facilityUnitsSaga } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.saga';
import UnitDetailHeader from '@AMIEWEB/Order/OrderCreationForm/Components/UnitDetail/UnitDetailHeader';
import UnitDetailWrapper from '@AMIEWEB/Order/OrderCreationForm/Components/UnitDetail/UnitDetailWrapper';
import { CustomCircularProgress } from '@AMIEWEB/Order/OrderDetails/OrderDetailsDesign';
import { orderCreationActions } from '@AMIEWEB/Order/Store/OrderCreation.actions';
import {
  orderCreationReducer,
  orderCreationReset,
  orderCreationSliceKey,
} from '@AMIEWEB/Order/Store/OrderCreation.redux';
import { orderCreationSaga } from '@AMIEWEB/Order/Store/OrderCreation.saga';
import {
  selectCreatedUnitId,
  selectFacilityDetails,
  selectFilterCertificationsDecision,
  selectMandatoryFieldsStatus,
  selectMessage,
  selectNewUnit,
  selectProceedToUnitCreation,
  selectStatus,
} from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { useDecision } from '@optimizely/react-sdk';
import { Alert, Grid } from 'amn-ui-core';
import { ff_order_ui_qualificationlist } from 'app/constants/FeatureFlags/Order/Keys';
import { DetailsPage } from 'app/layout/pages/DetailsPage';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { createOrderOcbrSaga } from 'store/redux-store/create-order-ocbr/saga';
import { createOrderOcbrReducer, createOrderOcbrSliceKey } from 'store/redux-store/create-order-ocbr/slice';
import { makeStyles } from 'tss-react/mui';
import useDocumentTitle from 'utils/customHooks/useDocumentTitle';

import {
  selectCreatedUnit,
  selectCreatedUnitFromOrder,
} from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';
import { orderCreationNewReducer, orderCreationNewSliceKey } from '@AMIEWEB/NewOrder/Store/OrderCreation.redux';
import { FacilityTabs } from '@AMIEWEB/Facility/FacilityDetails/FacilityBody/FacilityBody';

interface IUnitWrapper {
  onNewUnitNav: (value: boolean, unitId: number) => void;
  facilityData: any;
  isUnit: boolean;
}

const useStyles = makeStyles()(theme => ({
  customAlert: {
    width: '100%',
    zIndex: 99,
    top: '40px',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    marginBottom: '12px',
    '& .MuiAlert-icon': {
      fontSize: 24,
    },
    alignItems: 'center',
    height: '52px',
  },
  alertMessage: {
    padding: '13px 0',
  },
  alertIcon: {
    padding: '9px 0',
  },
  fullWidth: {
    width: '100%',
  },
  addJustButton: {
    marginBottom: '5px',
  },
  cancelButton: {
    backgroundColor: '#8E9195',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#8E9195',
    },
  },
  tooltipPlacementBottom: {
    margin: 0,
    marginBottom: 22,
  },
  button: {
    width: 'auto',
    fontSize: '12px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#006FB9',
      color: '#ffffff',
    },
  },
  buttonWidth: {
    marginRight: theme.spacing(1),
  },
}));

export const NewUnitWrapper = (props: IUnitWrapper) => {
  const params = useParams<{ id: string }>();
  useDocumentTitle({ title: `Create Unit - FID ${params.id}` });
  useInjectReducer({ key: orderCreationSliceKey, reducer: orderCreationReducer });
  useInjectSaga({ key: orderCreationSliceKey, saga: orderCreationSaga });
  useInjectReducer({ key: createOrderOcbrSliceKey, reducer: createOrderOcbrReducer });
  useInjectSaga({ key: createOrderOcbrSliceKey, saga: createOrderOcbrSaga });
  useInjectSaga({ key: FacilityUnitKey, saga: facilityUnitsSaga });
  useInjectSaga({ key: FacilityContactSliceKey, saga: facilityClientContactsSaga });
  useInjectReducer({ key: FacilityContactSliceKey, reducer: FacilityContactsReducer });
  useInjectReducer({ key: FacilityUnitKey, reducer: FacilityUnitReducer });
  useInjectReducer({ key: orderCreationNewSliceKey, reducer: orderCreationNewReducer });

  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [openNavConfirmation, setOpenNavConfirmation] = useState<boolean>(false);
  const facilityDetails = useSelector(selectFacilityDetails);
  const appStatus = useSelector(selectStatus);
  const newUnitFromOrder = useSelector(selectCreatedUnitFromOrder);
  const newUnit = useSelector(selectCreatedUnit);
  const message = useSelector(selectMessage);
  const createdUnitId = useSelector(selectCreatedUnitId);
  const newUnitObj = useSelector(selectNewUnit);
  const isMandatoryFilled = useSelector(selectMandatoryFieldsStatus);
  const proceedToUnitCreation = useSelector(selectProceedToUnitCreation);
  const [filterCertListFlag] = useDecision(ff_order_ui_qualificationlist);
  const selectDecision = useSelector(selectFilterCertificationsDecision);
  if (selectDecision === undefined || selectDecision === null) {
    dispatch(orderCreationActions.setCertificationsFlagDecision(filterCertListFlag.enabled));
  }
  const [openCancelConfirmation, setOpenCancelConfirmation] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);

  const formMethods = useForm({ defaultValues: newUnitObj, shouldUnregister: false });
  const { reset } = formMethods;

  const handleBeforeUnload = event => {
    if (isDirty) {
      event.returnValue = '';
      event.preventDefault();
      setOpenCancelConfirmation(true);
    }
  };

  useEffect(() => {
    if (Number(params.id)) {
      dispatch(FacilityUnitActions.getLicenses({ id: parseInt(params.id) }));
      dispatch(FacilityUnitActions.getCompactStatus());
      dispatch(FacilityUnitActions.getIssuedBy());
      dispatch(FacilityUnitActions.setSelectedUnit(null));
      const facilityId = Number(params.id);
      dispatch(orderCreationActions.unitCreateionInitializationAction({ id: facilityId }));
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useMemo(() => {
    if (!!appStatus && appStatus === 'succeeded') {
      reset(newUnitObj);
    } else if (!!appStatus && appStatus === 'proceedToParent') {
      const newUnitId = createdUnitId;
      reset({});
      if (!!proceedToUnitCreation && proceedToUnitCreation === true && newUnitId > 0) {
        setOpenNavConfirmation(true);
      } else {
        dispatch(orderCreationReset());
        if (!newUnit) {
          history.push({
            pathname: `/facility/${facilityDetails.facilityId}`,
            search: `tab=${FacilityTabs.Unit_Tab}`,
            state: 'navigationCycle',
          });
        } else {
          history.push(`/facility/${facilityDetails.facilityId}/order/new`);
        }
        dispatch(FacilityUnitActions.setCreateNewUnitFromOrder(true));
      }
    }
  }, [appStatus, proceedToUnitCreation, createdUnitId, newUnitFromOrder]);

  const handleCancelConfirmClose = (isConfirm: boolean) => {
    if (isConfirm) {
      history.push({
        pathname: `/facility/${facilityDetails.facilityId}`,
        state: 'navigationCycle',
      });
    }
  };

  const handleNavConfirmClose = (confirmation: boolean, activateStep?: number) => {
    if (confirmation) {
      if (createdUnitId > 0 && !!proceedToUnitCreation && proceedToUnitCreation === true) {
        history.push({
          pathname: `/facility/${facilityDetails.facilityId}/order/new`,
          state: 'navigationCycle',
        });
      } else {
        history.push({
          pathname: `/facility/${facilityDetails.facilityId}`,
          state: 'navigationCycle',
        });
      }
    } else {
      history.push({
        pathname: `/facility/${facilityDetails.facilityId}`,
        state: 'navigationCycle',
      });
    }
    setOpenNavConfirmation(false);
  };

  return appStatus !== 'loading' && !!facilityDetails.facilityId ? (
    <>
      <DetailsPage
        title={'Create Unit'}
        isCreateForm={false}
        paddingBottom="4rem"
        head={
          <UnitDetailHeader />
        }
        infoBanner={
          isMandatoryFilled !== true &&
          message !== undefined &&
          message !== null &&
          message !== '' && (
            <Grid item xs={12}>
              <Alert
                severity="error"
                className={classes.customAlert}
                classes={{
                  message: classes.alertMessage,
                  icon: classes.alertIcon,
                }}
              >
                {message}
              </Alert>
            </Grid>
          )
        }
      >
        <UnitDetailWrapper />
      </DetailsPage>
      {openCancelConfirmation && (
        <GenericDialog
          open={openCancelConfirmation}
          onClose={() => setOpenCancelConfirmation(false)}
          dialogTitleProps={{ text: t('Are you sure you want to cancel?') }}
          fullWidth
          maxWidth={'sm'}
          disableEscapeKeyDown
          dialogActions={[
            {
              text: 'No',
              variant: 'contained',
              color: 'tertiary',
              onClick: () => {
                setOpenCancelConfirmation(false);
              },
            },
            {
              text: 'Yes',
              variant: 'contained',
              onClick: () => {
                handleCancelConfirmClose(true);
              },
            },
          ]}
        />
      )}
      {openNavConfirmation && (
        <GenericDialog
          open={openNavConfirmation}
          onClose={() => handleNavConfirmClose(false)}
          dialogTitleProps={{ text: t('order.facilityDetails.orderCreationNavConfirmationMessage') }}
          fullWidth
          maxWidth={'sm'}
          disableEscapeKeyDown
          dialogActions={[
            {
              text: 'No',
              variant: 'contained',
              color: 'tertiary',
              onClick: () => {
                handleNavConfirmClose(false);
              },
            },
            {
              text: 'Yes',
              variant: 'contained',
              onClick: () => {
                handleNavConfirmClose(true, 5);
              },
            },
          ]}
        />
      )}
    </>
  ) : (
    <CustomCircularProgress />
  );
};
