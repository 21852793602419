import { PlacementsFilterName } from '../Filters/PlacementFilterConfig';
import { instanceOfISelection, ISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import { isItemIncluded, isSubstringIncluded, itemIsInRange } from 'app/ComponentLibrary/Filter/helper';
import { IFilterChipSelected, IFilterSpecs } from '../Models/PlacementStatusInterfaces';
import moment from 'moment';

const filterChoiceWithNewOptions = (
  choices: string[],
  newOpts: ISelection[],
  fieldName: string,
  setValue: (
    name: string,
    value: unknown,
    options?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
        }>
      | undefined,
  ) => void,
) => {
  const newChoices = choices.reduce((resultArray, choice) => {
    if (newOpts.some(opt => opt.value === choice)) resultArray.push(choice);
    return resultArray;
  }, [] as string[]);

  setValue(fieldName, newChoices, { shouldDirty: true });
  return newChoices;
};

export const filterUserChoices = (
  filterSpecs: IFilterSpecs,
  values: any,
  setValue: (
    name: string,
    value: unknown,
    options?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
        }>
      | undefined,
  ) => void,
) => {
  //For startDate
  values.startDate && setValue(PlacementsFilterName.startDate, values.startDate, { shouldDirty: true });

  return {
    ...values,
    [PlacementsFilterName.placementId]: filterChoiceWithNewOptions(
      values[PlacementsFilterName.placementId],
      filterSpecs.placementIDOpts,
      PlacementsFilterName.placementId,
      setValue,
    ),
    [PlacementsFilterName.candidateName]: filterChoiceWithNewOptions(
      values[PlacementsFilterName.candidateName],
      filterSpecs.candidateNameOpts,
      PlacementsFilterName.candidateName,
      setValue,
    ),
    [PlacementsFilterName.placementStatus]: filterChoiceWithNewOptions(
      values[PlacementsFilterName.placementStatus],
      filterSpecs.placementStatusOpts,
      PlacementsFilterName.placementStatus,
      setValue,
    ),
    [PlacementsFilterName.clientName]: filterChoiceWithNewOptions(
      values[PlacementsFilterName.clientName],
      filterSpecs.clientNameOpts,
      PlacementsFilterName.clientName,
      setValue,
    ),
    [PlacementsFilterName.disciplineSpecialty]: filterChoiceWithNewOptions(
      values[PlacementsFilterName.disciplineSpecialty],
      filterSpecs.disciplineSpecilityOpts,
      PlacementsFilterName.disciplineSpecialty,
      setValue,
    ),
    [PlacementsFilterName.candidateId]: filterChoiceWithNewOptions(
      values[PlacementsFilterName.candidateId],
      filterSpecs.candidateIdOpts,
      PlacementsFilterName.candidateId,
      setValue,
    ),
  };
};

export const getFilteredData = (filter, sourceData, chipSelected?: IFilterChipSelected) =>
  sourceData.reduce(
    (resultArray, item) =>
      isItemIncluded(
        item['placementId'],
        parseData(chipSelected?.placementId) || parseData(filter[PlacementsFilterName.placementId]),
      ) &&
      isItemIncluded(
        item['candidateName'],
        chipSelected?.candidateName || filter[PlacementsFilterName.candidateName],
      ) &&
      (filter[PlacementsFilterName.startDate]
        ? item['startDate']
          ? itemIsInRange(item['startDate'], filter[PlacementsFilterName.startDate])
          : false
        : true) &&
      isItemIncluded(
        item['placementStatus'],
        chipSelected?.placementStatus || filter[PlacementsFilterName.placementStatus],
      ) &&
      isItemIncluded(item['facilityName'], chipSelected?.clientName || filter[PlacementsFilterName.clientName]) &&
      isSubstringIncluded(
        item['discipline'],
        chipSelected?.disciplineSpecialty || filter[PlacementsFilterName.disciplineSpecialty],
      ) &&
      isItemIncluded(
        item['candidateId'],
        parseData(chipSelected?.candidateId) || parseData(filter[PlacementsFilterName.candidateId]),
      )
        ? [...resultArray, item]
        : resultArray,
    [],
  );

export const generateFieldChips = (values, selectedChip?) => {
  return typeof values !== 'undefined' && typeof values !== 'string' && values?.startDate && values?.endDate
    ? [
        {
          label: `${moment(values.startDate?.toLocaleDateString?.()).format('MM/DD/YYYY')} - ${moment(
            values.endDate?.toLocaleDateString?.(),
          ).format('MM/DD/YYYY')}`,
          enableDelete: true,
          value: values,
          isSelected: selectedChip?.some(chip => chip.value === values) || false,
        },
      ]
    : values && values.length
    ? instanceOfISelection(values[0])
      ? values.reduce(
          (result, choice) =>
            choice.value === 'All'
              ? result
              : [
                  ...result,
                  {
                    label: choice.name,
                    enableDelete: true,
                    value: choice,
                    isSelected: selectedChip?.some(chip => chip.value === choice.value) || false,
                  },
                ],

          [],
        )
      : values.reduce(
          (result, choice) =>
            choice === 'All'
              ? result
              : [
                  ...result,
                  {
                    label: choice,
                    enableDelete: true,
                    value: choice,
                    isSelected: selectedChip?.includes(choice) || false,
                  },
                ],
          [],
        )
    : [];
};

export const createFilterChips = (filter, selectedChips?: IFilterChipSelected) => ({
  [PlacementsFilterName.placementId]: generateFieldChips(
    filter[PlacementsFilterName.placementId],
    selectedChips?.placementId || undefined,
  ),
  [PlacementsFilterName.candidateName]: generateFieldChips(
    filter[PlacementsFilterName.candidateName],
    selectedChips?.candidateName || undefined,
  ),
  [PlacementsFilterName.startDate]: generateFieldChips(
    filter[PlacementsFilterName.startDate],
    selectedChips?.startDate || undefined,
  ),
  [PlacementsFilterName.placementStatus]: generateFieldChips(
    filter[PlacementsFilterName.placementStatus],
    selectedChips?.placementStatus || undefined,
  ),
  [PlacementsFilterName.clientName]: generateFieldChips(
    filter[PlacementsFilterName.clientName],
    selectedChips?.clientName || undefined,
  ),
  [PlacementsFilterName.disciplineSpecialty]: generateFieldChips(
    filter[PlacementsFilterName.disciplineSpecialty],
    selectedChips?.disciplineSpecialty || undefined,
  ),
  [PlacementsFilterName.candidateId]: generateFieldChips(
    filter[PlacementsFilterName.candidateId],
    selectedChips?.candidateId || undefined,
  ),
});

//
// ─── COMMON FUNCTIONS ───────────────────────────────────────────────────────────
//
export const determineChipSelected = (newChip, selected) =>
  newChip.isSelected ? null : [instanceOfISelection(newChip) ? newChip : newChip.value];

  export const parseData = data => {
    return data?.reduce(function (newArr, item) {
      if (typeof item === 'object' && item?.value !== undefined) {
        newArr.push(parseInt(item.value));
        return newArr;
      }
      newArr.push(parseInt(item?.value));
      return newArr;
    }, []);
  };
