import { FormControl, InputLabel, MenuItem, Select, SelectProps } from 'amn-ui-core';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export type IOption = {
  id: any;
  label: any;
  value?: any;
};

export type FormInputDropDownProps = SelectProps & {
  name: string;
  label: string;
  required: boolean;
  options: IOption[];
  isDisabled: boolean;
};

export const FormInputDropdown = (props: FormInputDropDownProps) => {
  const { control, watch } = useFormContext();
  const watchedValue = watch(props.name) ?? '';

  return (
    <FormControl fullWidth variant="filled">
      <Controller
        name={props.name}
        control={control}
        defaultValue={watchedValue}
        label={props.label}
        render={rest => (
          <>
            <InputLabel id="form-input-drop-down" style={{ pointerEvents: 'auto' }}>
              {props.label}
            </InputLabel>
            <Select
              required={props.required}
              label={props.label}
              labelId="form-input-drop-down"
              variant="filled"
              disabled={props.isDisabled}
              {...rest}
            >
              {props.options.length > 0 &&
                props.options.map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
          </>
        )}
      />
    </FormControl>
  );
};
