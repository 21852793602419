import React from 'react';
import { useTranslation } from 'react-i18next';
import styledComponent from 'styled-components';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  root: {
    height: '500px !important',
    overflow: 'hidden',
    textAlign: 'center',
  },
});

interface Props {
  searchTerm: string;
  category: string;
}

export const NoDataPage: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <Wrapper>
        <Title>
          <p data-testid="nodata.line1">{t('noDataPage.line1')}</p>
          <p>{t('noDataPage.line2', { searchTerm: props.searchTerm, category: props.category })}</p>
          <p>{t('noDataPage.line3')}</p>
        </Title>
      </Wrapper>
    </div>
  );
};

const Wrapper = styledComponent.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 320px;
  background: rgb(244, 245, 247);
`;

const Title = styledComponent.div`
  margin-top: -10vh;
  font-weight: bold;
  color: black;
  font-size: 16px;
  span {
    font-size: 16px;
  }
`;
