import {
    ff_notifications_ui_account_management_workdesk_bulk_sms,
    ff_notifications_ui_candidate_search_bulk_sms,
    ff_notifications_ui_credentialing_workdesk_bulk_sms,
    ff_notifications_ui_recruitment_workdesk_bulk_sms,
    ff_notifications_ui_account_management_workdesk_bulk_email,
    ff_notifications_ui_recruitment_workdesk_bulk_email,
    ff_notifications_ui_credentialing_workdesk_bulk_email,
    ff_notifications_ui_candidate_search_bulk_email
} from 'app/constants/FeatureFlags/Tasks/Notifications/Keys'

export enum SearchGrid {
    recruitmentWorkDesk = 'recruitmentWorkDesk',
    accountManagementWorkDesk = 'accountManagementWorkDesk',
    credentialingWorkDesk = 'credentialingWorkDesk',
    candidateSearchGrid = 'candidateSearchGrid'
}

export const searchGridBulkSmsFFKey = {
    [SearchGrid.recruitmentWorkDesk]: ff_notifications_ui_recruitment_workdesk_bulk_sms,
    [SearchGrid.accountManagementWorkDesk]: ff_notifications_ui_account_management_workdesk_bulk_sms,
    [SearchGrid.credentialingWorkDesk]: ff_notifications_ui_credentialing_workdesk_bulk_sms,
    [SearchGrid.candidateSearchGrid]: ff_notifications_ui_candidate_search_bulk_sms
}

export const searchGridBulkEmailFFKey = {
    [SearchGrid.accountManagementWorkDesk]: ff_notifications_ui_account_management_workdesk_bulk_email,
    [SearchGrid.candidateSearchGrid]: ff_notifications_ui_candidate_search_bulk_email,
    [SearchGrid.credentialingWorkDesk]: ff_notifications_ui_credentialing_workdesk_bulk_email,
    [SearchGrid.recruitmentWorkDesk]: ff_notifications_ui_recruitment_workdesk_bulk_email
}