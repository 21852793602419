import { requirementType } from './PlacementSummary';

export class Requirements {
  aggregate: number = 0;
  passed: number = 0;
  failed: number = 0;
  risks: number = 0;

  add(type?: requirementType, incrementBy?: number) {
    switch (type) {
      case requirementType.pass:
        this.passed += incrementBy ?? 1;
        this.aggregate += incrementBy ?? 1;
        break;
      case requirementType.fail:
        this.failed += incrementBy ?? 1;
        this.aggregate += incrementBy ?? 1;
        break;
      case requirementType.risk:
        this.risks += incrementBy ?? 1;
        break;
      default:
        break;
    }
  }
}
