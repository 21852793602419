import {
  ISkillsetTree,
  ISkillsetTreeDiscipline,
  ISkillsetTreeSpecialty,
  ISkillsetTreeSubSpecialty,
} from 'app/models/Candidate/CandidateProfile';
import _ from 'lodash';

export enum SkillsetType {
  verified = 'verified',
  unverified = 'unverified',
}

export enum SkillsetCategory {
  discipline = 'discipline',
  specialty = 'specialty',
  subspecialty = 'subspecialty',
}

export enum IReason {
  backdropClick = 'backdropClick',
}

export interface ISkillsetItem {
  category: SkillsetCategory;
  id: string;
  skillset: SkillsetDetails;
}

export const skillsetGroups: ISkillsetGroup = {
  [SkillsetType.unverified]: {
    title: 'Unverified',
    items: [],
  },
  [SkillsetType.verified]: {
    title: 'Verified',
    items: [],
  },
};

export interface ISkillsetGroup {
  [SkillsetType.unverified]: {
    title: string;
    items: ISkillsetItem[];
  };
  [SkillsetType.verified]: {
    title: string;
    items: ISkillsetItem[];
  };
}

export interface SkillsetDetails {
  type: SkillsetType;
  discipline: ISkillsetTreeDiscipline | null;
  specialty: ISkillsetTreeSpecialty | null;
  subspecialty: ISkillsetTreeSubSpecialty | null;
  label: string;
}

export interface ISkillsetTreeItem {
  disciplineAbbr: string;
  specialtyAbbr?: string;
  subSpecialtyAbbr?: string;
}

export const getSkillsetsByType = (type: SkillsetType, candidateSkillSetTree: ISkillsetTree) => {
  const skillsets: ISkillsetItem[] = [];
  candidateSkillSetTree?.candidateSkillSets?.map(discipline => {
    if (discipline[type])
      if (discipline.specialties?.filter(item => item[type]).length > 0) {
        discipline.specialties.map(specialty => {
          if (specialty[type]) {
            if (specialty.subSpecilities && specialty.subSpecilities.filter(item => item[type]).length > 0) {
              return specialty.subSpecilities.map(subspecialty => {
                if (subspecialty[type]) {
                  const label = `${discipline.abbreviation} - ${specialty.description}, ${subspecialty.description}`;
                  skillsets.push({
                    category: SkillsetCategory.subspecialty,
                    id: label,
                    skillset: {
                      type: type,
                      discipline: discipline,
                      specialty: specialty,
                      subspecialty: subspecialty,
                      label: label,
                    },
                  });
                }
              });
            } else {
              const label = `${discipline.abbreviation} - ${specialty.description}`;
              skillsets.push({
                category: SkillsetCategory.specialty,
                id: label,
                skillset: {
                  type: type,
                  discipline: discipline,
                  specialty: specialty,
                  subspecialty: null,
                  label: label,
                },
              });
            }
          }
        });
      }
  });
  return skillsets;
};

export const getSkillsetUpdateItem = (
  skillset: SkillsetDetails,
  candidateSkillSetTree: ISkillsetTree,
  columns: ISkillsetGroup,
) => {
  const disciplineItem = candidateSkillSetTree?.candidateSkillSets?.find(
    discipline => discipline.disciplineId === skillset.discipline.disciplineId,
  );
  const specialtyItem = disciplineItem?.specialties?.find(
    specialty => specialty.specilityId === skillset.specialty.specilityId,
  );
  const subspecialtyItem = specialtyItem?.subSpecilities?.find(
    subspecialty => subspecialty.subSpecialtyID === skillset.subspecialty?.subSpecialtyID,
  );

  const existingUnverifiedItems = columns[SkillsetType.unverified].items;
  const existingVerifiedItems = columns[SkillsetType.verified].items;

  const existingSkillsetCount = {
    unverifiedDiscipline: existingUnverifiedItems.filter(
      item => item.skillset.discipline.disciplineId === disciplineItem.disciplineId,
    ).length,
    verifiedDiscipline: existingVerifiedItems.filter(
      item => item.skillset.discipline.disciplineId === disciplineItem.disciplineId,
    ).length,
    unverifiedSpecialty: existingUnverifiedItems.filter(
      item =>
        item.skillset.discipline.disciplineId === disciplineItem.disciplineId &&
        item.skillset.specialty.specilityId === specialtyItem.specilityId,
    ).length,
    verifiedSpecialty: existingVerifiedItems.filter(
      item =>
        item.skillset.discipline.disciplineId === disciplineItem.disciplineId &&
        item.skillset.specialty?.specilityId === specialtyItem?.specilityId,
    ).length,
  };

  if (subspecialtyItem) {
    if (
      (skillset.type === SkillsetType.unverified && subspecialtyItem.unverified && subspecialtyItem?.isAdded != true) ||
      (skillset.type === SkillsetType.verified && subspecialtyItem.verified && subspecialtyItem?.isAdded != true)
    )
      return {
        ...disciplineItem,
        specialties: [
          {
            ...specialtyItem,
            subSpecilities: [
              {
                ...subspecialtyItem,
              },
            ],
          },
        ],
      };

    return {
      ...disciplineItem,
      specialties: [
        {
          ...specialtyItem,
          subSpecilities: [
            {
              ...subspecialtyItem,
              uiUnverifiedFlag: subspecialtyItem.isAdded ? skillset.type === SkillsetType.unverified : true,
              uiVerifiedFlag: subspecialtyItem.isAdded ? skillset.type === SkillsetType.verified : true,
              unverified: skillset.type === SkillsetType.unverified,
              verified: skillset.type === SkillsetType.verified,
            },
          ],
          uiUnverifiedFlag: !(skillset.type === SkillsetType.unverified
            ? existingSkillsetCount.unverifiedSpecialty > 1
            : existingSkillsetCount.unverifiedSpecialty > 0),
          uiVerifiedFlag: !(skillset.type === SkillsetType.verified
            ? existingSkillsetCount.verifiedSpecialty > 1
            : existingSkillsetCount.verifiedSpecialty > 0),
          unverified:
            skillset.type === SkillsetType.unverified ||
            (skillset.type === SkillsetType.verified && existingSkillsetCount.unverifiedSpecialty > 0),
          verified:
            skillset.type === SkillsetType.verified ||
            (skillset.type === SkillsetType.unverified && existingSkillsetCount.verifiedSpecialty > 0),
        },
      ],
      uiUnverifiedFlag: !(skillset.type === SkillsetType.unverified
        ? existingSkillsetCount.unverifiedDiscipline > 1
        : existingSkillsetCount.unverifiedDiscipline > 0),
      uiVerifiedFlag: !(skillset.type === SkillsetType.verified
        ? existingSkillsetCount.verifiedDiscipline > 1
        : existingSkillsetCount.verifiedDiscipline > 0),
      unverified:
        skillset.type === SkillsetType.unverified ||
        (skillset.type === SkillsetType.verified && existingSkillsetCount.unverifiedDiscipline > 0),
      verified:
        skillset.type === SkillsetType.verified ||
        (skillset.type === SkillsetType.unverified && existingSkillsetCount.verifiedDiscipline > 0),
    };
  } else if (specialtyItem) {
    if (
      (skillset.type === SkillsetType.unverified && specialtyItem.unverified && skillset.specialty.isAdded != true) ||
      (skillset.type === SkillsetType.verified && specialtyItem.verified && skillset.specialty.isAdded != true)
    ) {
      return {
        ...disciplineItem,
        specialties: [
          {
            ...specialtyItem,
            subSpecilities: [],
          },
        ],
      };
    }

    return {
      ...disciplineItem,
      specialties: [
        {
          ...specialtyItem,
          subSpecilities: [],
          uiUnverifiedFlag: !(skillset.type === SkillsetType.unverified
            ? existingSkillsetCount.unverifiedSpecialty > 1
            : existingSkillsetCount.unverifiedSpecialty > 0),
          uiVerifiedFlag: !(skillset.type === SkillsetType.verified
            ? existingSkillsetCount.verifiedSpecialty > 1
            : existingSkillsetCount.verifiedSpecialty > 0),
          unverified:
            skillset.type === SkillsetType.unverified ||
            (skillset.type === SkillsetType.verified && existingSkillsetCount.unverifiedSpecialty > 0),
          verified:
            skillset.type === SkillsetType.verified ||
            (skillset.type === SkillsetType.unverified && existingSkillsetCount.verifiedSpecialty > 0),
        },
      ],
      uiUnverifiedFlag: !(skillset.type === SkillsetType.unverified
        ? existingSkillsetCount.unverifiedDiscipline > 1
        : existingSkillsetCount.unverifiedDiscipline > 0),
      uiVerifiedFlag: !(skillset.type === SkillsetType.verified
        ? existingSkillsetCount.verifiedDiscipline > 1
        : existingSkillsetCount.verifiedDiscipline > 0),
      unverified:
        skillset.type === SkillsetType.unverified ||
        (skillset.type === SkillsetType.verified && existingSkillsetCount.unverifiedDiscipline > 0),
      verified:
        skillset.type === SkillsetType.verified ||
        (skillset.type === SkillsetType.unverified && existingSkillsetCount.verifiedDiscipline > 0),
    };
  }
  return null;
};

export const getDeleteSkillsetItem = (
  skillset: SkillsetDetails,
  candidateSkillSetTree: ISkillsetTree,
  columns: ISkillsetGroup,
) => {
  const deletedSkillset: ISkillsetTreeDiscipline[] = [];
  const disciplineItem = candidateSkillSetTree?.candidateSkillSets?.find(
    discipline => discipline.disciplineId === skillset.discipline.disciplineId,
  );
  const specialtyItem = disciplineItem?.specialties?.find(
    specialty => specialty.specilityId === skillset.specialty.specilityId,
  );
  const subspecialtyItem = specialtyItem?.subSpecilities?.find(
    subspecialty => subspecialty.subSpecialtyID === skillset.subspecialty?.subSpecialtyID,
  );

  const existingUnverifiedItems = columns[SkillsetType.unverified].items;
  const existingVerifiedItems = columns[SkillsetType.verified].items;

  const existingSkillsetCount = {
    unverifiedDiscipline: existingUnverifiedItems.filter(
      item => item.skillset.discipline.disciplineId === disciplineItem.disciplineId,
    ).length,
    verifiedDiscipline: existingVerifiedItems.filter(
      item => item.skillset.discipline.disciplineId === disciplineItem.disciplineId,
    ).length,
    unverifiedSpecialty: existingUnverifiedItems.filter(
      item =>
        item.skillset.discipline.disciplineId === disciplineItem.disciplineId &&
        item.skillset.specialty.specilityId === specialtyItem.specilityId,
    ).length,
    verifiedSpecialty: existingVerifiedItems.filter(
      item =>
        item.skillset.discipline.disciplineId === disciplineItem.disciplineId &&
        item.skillset.specialty?.specilityId === specialtyItem?.specilityId,
    ).length,
  };

  if (skillset.type === SkillsetType.verified) {
    existingVerifiedItems
      .filter(
        item =>
          !(
            item.skillset.discipline.disciplineId === disciplineItem.disciplineId &&
            item.skillset.specialty?.specilityId === specialtyItem?.specilityId &&
            item.skillset.subspecialty?.subSpecialtyID === subspecialtyItem?.subSpecialtyID
          ),
      )
      .map(item =>
        deletedSkillset.push({
          ...item.skillset.discipline,
          specialties: [
            {
              ...item.skillset.specialty,
              subSpecilities: [
                {
                  ...item.skillset.subspecialty,
                },
              ],
            },
          ],
        }),
      );
  }

  if (subspecialtyItem) {
    deletedSkillset.push({
      ...disciplineItem,
      specialties: [
        {
          ...specialtyItem,
          subSpecilities: [
            {
              ...subspecialtyItem,
              uiUnverifiedFlag: skillset.type === SkillsetType.unverified,
              uiVerifiedFlag: skillset.type === SkillsetType.verified,
              unverified: false,
              verified: false,
            },
          ],
          uiUnverifiedFlag: skillset.type === SkillsetType.unverified && existingSkillsetCount.unverifiedSpecialty < 2,
          uiVerifiedFlag: skillset.type === SkillsetType.verified && existingSkillsetCount.verifiedSpecialty < 2,
          unverified: false,
          verified: false,
        },
      ],
      uiUnverifiedFlag: skillset.type === SkillsetType.unverified && existingSkillsetCount.unverifiedDiscipline < 2,
      uiVerifiedFlag: skillset.type === SkillsetType.verified && existingSkillsetCount.verifiedDiscipline < 2,
      unverified: false,
      verified: false,
    });
  } else if (specialtyItem) {
    deletedSkillset.push({
      ...disciplineItem,
      specialties: [
        {
          ...specialtyItem,
          subSpecilities: [],
          uiUnverifiedFlag: skillset.type === SkillsetType.unverified,
          uiVerifiedFlag: skillset.type === SkillsetType.verified,
          unverified: false,
          verified: false,
        },
      ],
      uiUnverifiedFlag: skillset.type === SkillsetType.unverified && existingSkillsetCount.unverifiedDiscipline < 2,
      uiVerifiedFlag: skillset.type === SkillsetType.verified && existingSkillsetCount.verifiedDiscipline < 2,
      unverified: false,
      verified: false,
    });
  }
  return deletedSkillset;
};

export const combineDuplicates = (
  candidateSkillSets: ISkillsetTreeDiscipline[],
  existingSkillsets: ISkillsetTreeDiscipline[],
) => {
  let newSkillsets: ISkillsetTreeDiscipline[] = [];

  candidateSkillSets?.map(discipline => {
    const duplicateDiscipline = newSkillsets.find(item => item.disciplineId === discipline.disciplineId);
    const existingDiscipline = existingSkillsets.find(disc => disc.disciplineId === discipline.disciplineId);
    if (duplicateDiscipline) {
      let combinedSpecialties = [...duplicateDiscipline.specialties];
      discipline.specialties.map(specialty => {
        const duplicateSpecialty = duplicateDiscipline.specialties?.find(
          newSpecialty => newSpecialty.specilityId === specialty.specilityId,
        );
        if (duplicateSpecialty) {
          const combinedSubSpecialties = [...duplicateSpecialty.subSpecilities];
          specialty.subSpecilities.map(subSpecialty => {
            combinedSubSpecialties.push(subSpecialty);
          });
          combinedSpecialties = combinedSpecialties.map(item => {
            if (item.specilityId === specialty.specilityId) {
              return {
                ...item,
                subSpecilities: combinedSubSpecialties,
                verified: item.verified || specialty.verified,
                unverified: item.unverified || specialty.unverified,
                uiVerifiedFlag:
                  (discipline.verified &&
                    !existingDiscipline.specialties.find(
                      skillset => skillset.verified && skillset.specilityId === specialty.specilityId,
                    )) ||
                  duplicateSpecialty.uiVerifiedFlag ||
                  specialty.isAdded === true,
                uiUnverifiedFlag:
                  (discipline.unverified &&
                    !duplicateDiscipline.specialties.find(
                      skillset => skillset.unverified && skillset.specilityId === specialty.specilityId,
                    )) ||
                  duplicateSpecialty.uiUnverifiedFlag ||
                  specialty.isAdded === true,
              };
            }
            return item;
          });
        } else {
          combinedSpecialties.push(specialty);
        }
      });
      newSkillsets = newSkillsets.map(disc => {
        if (disc.disciplineId === discipline.disciplineId) {
          return {
            ...disc,
            specialties: combinedSpecialties,
            verified: disc.verified || discipline.verified,
            unverified: disc.unverified || discipline.unverified,
            uiVerifiedFlag:
              disc.verified &&
              (!existingSkillsets.find(skillset => skillset.verified && skillset.disciplineId === disc.disciplineId) ||
                disc.isAdded === true),
            uiUnverifiedFlag:
              disc.unverified &&
              (!existingSkillsets.find(
                skillset => skillset.unverified && skillset.disciplineId === disc.disciplineId,
              ) ||
                disc.isAdded === true),
          };
        }
        return disc;
      });
    } else {
      newSkillsets.push(discipline);
    }
  });
  return newSkillsets;
};
