/* eslint-disable i18next/no-literal-string */
import { Checkbox, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useCallback, useEffect, useState } from 'react';
import { AttachmentPreview } from './AttachmentPreview';
import { IAttachments } from 'app/models/Credentialing/Credentials';
import { IconButton } from 'amn-ui-core';
import { Add } from '@mui/icons-material';
import { CustomTooltip } from 'app/components/Common/Tooltips/CustomTooltip';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { isCredentialing } from 'oidc/userRoles';

const useStyles = makeStyles()(theme => ({
  tileDetails: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  tile: {
    padding: '9px',
  },
  info: {
    fontSize: '12px',

    margin: '0px 0px 6px 39px',
  },
  addButton: {
    backgroundColor: theme.palette.components.button.secondary.backgroundColor,
    '&:hover': {
      backgroundColor: theme.palette.components.button.secondary.backgroundColor,
    },
    width: '38px',
    height: '38px',
    borderRadius: '4px',
    color: '#fff',
    marginTop: '-10px',
  },
  disableAddButton: {
    backgroundColor: theme.palette.framework.system.whisper,
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: theme.palette.framework.system.whisper,
    },
    width: '38px',
    height: '38px',
    borderRadius: '4px',
    color: '#fff',
    marginTop: '-10px',
  },
  addButtonAlign: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row-reverse',
    paddingRight: '23px',
  },
  addJustWidth: {
    width: '100%',
  },
}));

export const AttachmentPreviewContent = ({
  attachments,
  onAttachmentSelected,
  allCheckboxToggle,
  credential,
  isDownload,
  handleDelete,
  selectedAttachments,
  onAddDocumentClick,
}: {
  attachments: any;
  onAttachmentSelected: (selected: any, booleanChecked: boolean, booleanAllChecked: boolean) => void;
  allCheckboxToggle?: boolean;
  credential: any;
  isDownload: boolean;
  handleDelete: (selected: any) => void;
  selectedAttachments?: IAttachments[];
  onAddDocumentClick: () => void;
}) => {
  const { classes } = useStyles();
  const [selectAllBool, setSelectAllBool] = useState<boolean>();
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  //Todo : No Query params for selectAll get data directly store in document viewer component
  //Check if data exists redux get it from state else mock data in document viewer component.
  const onSelection = event => {
    if (event.target.checked === true) {
      setSelectAllBool(true);

      onAttachmentSelected(null, false, true);
    } else {
      setSelectAllBool(false);

      onAttachmentSelected(null, false, false);
    }
  };

  const myAllCheckboxToggle = useCallback(() => {
    return allCheckboxToggle;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCheckboxToggle]);

  useEffect(() => {
    setSelectAllBool(allCheckboxToggle);
  }, [allCheckboxToggle, selectAllBool]);

  return (
    <Grid container direction="column">
      <Grid item className={classes.addJustWidth}>
        <Checkbox
          size="small"
          color="primary"
          onChange={onSelection}
          name="selectAll"
          checked={myAllCheckboxToggle()}
          data-testid="attachment-checkbox-all"
        />
        SELECT ALL
        <Grid container>
          <Grid item>
            <div className={classes.info}>
              {isDownload
                ? `Use SELECT ALL or Select to download the selected attachments.`
                : `Use SELECT ALL or Select the attachments to see a magnified view.`}
            </div>
          </Grid>
          <Grid item className={classes.addButtonAlign}>
            <CustomTooltip
              tooltipContent={t('Add Document')}
              placement={'bottom'}
              value={
                <IconButton
                  color="primary"
                  onClick={() => isCredentialing(user?.userInfo?.roles || []) && onAddDocumentClick()}
                  aria-label="add-document-button"
                  className={
                    isCredentialing(user?.userInfo?.roles || []) ? classes.addButton : classes.disableAddButton
                  }
                  size="small"
                >
                  <Add />
                </IconButton>
              }
            ></CustomTooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container className={classes.tileDetails}>
          {attachments.map((item, index) => (
            <Grid item className={classes.tile} style={{ width: '240px' }}>
              <AttachmentPreview
                docData={item}
                onAttachmentSelected={onAttachmentSelected}
                number={index}
                selectAll={selectAllBool}
                credential={credential}
                handleDelete={handleDelete}
                selectedAttachments={selectedAttachments}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
