export interface DateRange extends Omit<DefinedRange, 'label' | 'startDate' | 'endDate'> {
  startDate?: Date;
  endDate?: Date;
  label?: string;
}

export type Setter<T> = React.Dispatch<React.SetStateAction<T>> | ((value: T) => void);

export enum NavigationAction {
  Previous = -1,
  Next = 1,
}

export type DefinedRange = {
  /** start date when date range, selected date when single date picker */
  startDate: Date;
  /** end date wheb date range, selected date at 23:59:59 hours when single date picker */
  endDate: Date;
  /** label that is displayed in quick select, or returned during any selection */
  label: string;
  /** use quick select as textfield value when selected */
  useLabelAsValue?: boolean;
  /**
   * used as return value for selected range where the data is stored knows it was for a single
   * date picker and not a date range
   */
  single?: boolean;
};

export enum EDateRangePickTabbingIDs {
  textfield = 'date-range-picker-text-field',
  applyButton = 'date-range-picker-apply-button',
  cancelButton = 'date-range-picker-cancel-button',
}
