import React from 'react';
import { makeStyles } from 'tss-react/mui';
interface Props {
  data: string[];
  showCount?: boolean;
  count?: number;
}

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
  },
  count: {
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    color: '#0F6FB9',
    fontWeight: 'bold',
    paddingLeft: '8px',
  },
}));

export const MultiLineSpan: React.FC<Props> = props => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <span>
        {props.data &&
          props.data.map((item, key) => (
            <span key={key}>
              {item}
              <br />
            </span>
          ))}
      </span>
      {props.showCount && (
        <span className={classes.count}>
          <span>{'+'}</span>
          {props.count}
        </span>
      )}
    </div>
  );
};
