import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Fade,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from 'amn-ui-core';
import React, { useMemo, useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from 'tss-react/mui';
import { ISelection } from '../TypeAheadDropdown/CustomTypeAhead';
import { useTranslation } from 'react-i18next';
import { DisableAppScroll, EnableAppScroll } from 'app/layout/Layout';
import { getCandidateSearchLookups } from 'app/services/SharedServices/SharedServices';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { CandidateSubSearch, ICandidateSubSearch } from './CandidateSubSearch';
import _ from 'lodash';
import { Concatenate } from 'utils/string/string';
import { useSelector } from 'react-redux';
import { selectFilters } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';

const useStyles = makeStyles<{
  isSelected: boolean;
  open: boolean;
  anchorRef: boolean;
  removeActionContainer: boolean;
}>()((theme, { isSelected, open, anchorRef, removeActionContainer }) => ({
  root: {},
  outlineAnchor: {
    borderRadius: 4,
    width: '100%',
    minHeight: '36.7px',
    justifyContent: 'space-between',
    textTransform: 'none',
    color: open ? '#52429A' : isSelected ? '#555555' : '#C0C0C0',
    fontSize: 12,
    fontWeight: 400,
    '&:hover, &:focus': {
      backgroundColor: '#ffffff',
    },
    visibility: anchorRef ? 'hidden' : undefined,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    '&:hover': {
      borderColor: 'black',
    },
  },
  paperDropdown: {
    boxShadow: '0px 2px 8px #0000004D',
  },
  popper: {
    marginTop: 4,
    zIndex: 5000,
  },
  topActionContainer: {
    boxShadow: '0px 12px 10px -17px #0000004D',
    zIndex: 3,
    position: 'relative',
    padding: '0px 16px 0px 16px',
  },
  actionContainer: {
    visibility: removeActionContainer ? 'hidden' : undefined,
    backgroundColor: '#ffffff',
    borderTop: removeActionContainer ? undefined : '1px solid #cccccc',
    padding: removeActionContainer ? 0 : '10px 8px',
    height: removeActionContainer ? 0 : undefined,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  button: {
    fontSize: '12px',
    fontWeight: 'bold',
    textTransform: 'none',
    '&:hover, &:focus': {
      background: 'none',
    },
  },
  inActive: {
    color: '#888888',
  },
  actionButton: {
    padding: '6px 0px',
  },
  loadingLabel: {
    color: '#888888',
    fontSize: '14px',
    padding: '8px 0px 8px 16px',
  },
  otherName: {
    color: '#888888',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
  },
}));

export interface ICandidateOption extends Omit<ISelection, 'value'> {
  value: string;
  candidateId: number;
  brands: string;
  otherName: string;
}

export const AVOID_CLICK_AWAY_LABEL = 'avoid-click-away-type-ahead-drop-down';
export const CANDIDATE_SEARCH_TRACKER = 'candidate-aggregate-search';

export interface ICandidateAggregateSearch extends ICandidateSubSearch {
  selections: ICandidateOption[];
}
type FilterObject = {
  id: string;
  name: string;
  value: string;
};
export function instanceOfAggregateCandidate(object: any): object is ICandidateAggregateSearch {
  return (
    object &&
    typeof object === 'object' &&
    'selections' in object &&
    'firstName' in object &&
    'lastName' in object &&
    'goesBy' in object &&
    'cid' in object &&
    'otherName' in object &&
    'phoneNumber' in object &&
    'email' in object
  );
}

const initialCandidateSubSearch: ICandidateSubSearch = {
  firstName: null,
  lastName: null,
  goesBy: null,
  cid: null,
  otherName: null,
  phoneNumber: null,
  email: null,
};

export const initialCandidateAggregateSearch: ICandidateAggregateSearch = {
  ...initialCandidateSubSearch,
  selections: [],
};
export const CandidateAggregateSearch: React.FC<{
  value: ICandidateAggregateSearch;
  onChange: (selections: ICandidateAggregateSearch) => void;
  [key: string]: any;
}> = props => {
  const {
    value: aggregate = initialCandidateAggregateSearch,
    selectAllValue,
    filterName,
    isDisabled,
    /** Prop to utilize external anchor element */
    anchorRef,
    removeActionContainer = false,
    customClass,
    hasControlError,
    isNested = false,
    controlErrorText,
    minWidth,
    isMultiSelect,
  } = props;

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const [options, setOptions] = React.useState<ICandidateOption[]>([]);
  const [prevState, setPrevState] = useState<ICandidateAggregateSearch>(aggregate);
  const [cachedSubSearch, cacheSubSearchFilter] = useState<ICandidateAggregateSearch | undefined>(undefined);
  const [isNewSelectionApplied, setIsNewSelectionApplied] = useState<boolean>(true);
  const [isNewSubFilterApplied, setIsNewSubFilterApplied] = useState<boolean>(true);
  const { classes } = useStyles({
    isSelected: isMultiSelect
      ? aggregate &&
        (aggregate?.selections?.length > 0 || Object.keys(aggregate).some(key => (aggregate[key] ? (key === 'selections'? false : true) : false)))
      : aggregate !== null,
    open,
    anchorRef,
    removeActionContainer,
  });
  const [{ trackerArea, id }] = React.useState({
    trackerArea: `${CANDIDATE_SEARCH_TRACKER}-${props.name}`,
    id: `candidate-filter-${props.name}`,
  });
  const [selectedCountTag, setSelectedCountTag] = useState<string>(filterName);
  const [subFilterCount, setSubfilterCount] = useState<number>(0);
  const { promiseInProgress: loadingOpts } = usePromiseTracker({ area: trackerArea, delay: 0 });

  const prevAbortControllerRef = React.useRef<AbortController>(null);
  const filterAttributes = useSelector(selectFilters);
  const [selectedRecruiterAndBrandIds, setSelectedRecruiterAndBrandIds] = useState({});

  const fetchCandidates = React.useCallback(async filters => {
    setOptions([]);
    prevAbortControllerRef.current?.abort('CandidateAggregateSearch - throttling');

    const abortController = new AbortController();
    prevAbortControllerRef.current = abortController;

    const candidates = await getCandidateSearchLookups(filters, abortController.signal);

    setOptions(
      (candidates || []).map(opt => ({
        ...opt,
        id: opt.travelerId,
        candidateId: opt.travelerId,
        value: opt.travelerId,
        name: Concatenate([opt.firstName, opt.lastName], ' '),
        otherName: Concatenate([opt.goesBy, opt.maidenName], '/'),
        brands: opt.brands,
      })),
    );
  }, []);

  const throttleService = useMemo(
    () =>
      _.throttle(request => {
        trackPromise(fetchCandidates(request), trackerArea);
      }, 2000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleOpen = event => {
    if (!open) {
      //   trackPromise(fetchOptions(undefined), trackerArea);
      setAnchorEl(anchorRef ? anchorRef.current : event.currentTarget);
      // Setup for probable cancelChanges
      setIsNewSelectionApplied(false);
      setPrevState(aggregate);
      throttleService({ ...aggregate, ...selectedRecruiterAndBrandIds });
      DisableAppScroll();
    } else {
      if (props.hideApply) {
        onApplyEvent();
        return;
      }
      cancelChanges();
    }
  };
  const onClickAway = event => {
    if (props.hideApply || (props.applyOnClickAway && aggregate?.selections?.length === 0)) {
      onApplyEvent();
      return;
    }
    if (props.hideApply || (props.applyOnClickAway && aggregate?.selections?.length > 0)) {
      const keys = Object.keys(aggregate);
      const isUnEqual = prevState && aggregate ? keys.some(key => !_.isEqual(prevState[key], aggregate[key])) : false;
      if (isUnEqual) {
        setIsNewSelectionApplied(true);
        setIsNewSubFilterApplied(true);
        props.apply({ ...initialCandidateAggregateSearch, selections: aggregate?.selections });
        closeActions();
        return;
      } else {
        closeActions();
      }
    }
    if (event.target.type !== 'submit' && event.target.id !== AVOID_CLICK_AWAY_LABEL) {
      /** Temp fix check on anchorRef --> avoid reset action if using external ref */
      !anchorRef && !isNewSelectionApplied && props.onChange(prevState);
      closeActions();
    }
  };

  const closeActions = () => {
    setAnchorEl(null);
    EnableAppScroll();
  };
  const cancelChanges = () => {
    props.onChange(prevState);
    closeActions();
  };
  const onApplyEvent = () => {
    setIsNewSelectionApplied(true);
    setIsNewSubFilterApplied(true);
    props.apply(aggregate);
    closeActions();
  };

  const handleSelectAll = () => {
    props.onChange({ ...initialCandidateAggregateSearch, selections: options });
  };

  const handleClearAll = () => {
    cacheSubSearchFilter(undefined);
    setSubfilterCount(0);
    props.onChange(initialCandidateAggregateSearch);
    throttleService(initialCandidateAggregateSearch);
  };

  const stringifiedSelections = JSON.stringify(aggregate);

  const handleChange = React.useCallback(
    (current: ICandidateOption, newState: boolean) => {
      const newSelections = newState
        ? [...aggregate?.selections, current]
        : aggregate?.selections.filter(x => x.id !== current.id);
      props.onChange({
        ...(newSelections.length > 0 ? initialCandidateAggregateSearch : aggregate),
        selections: newSelections,
      });
      setSubfilterCount(0); // when use select checkbox making subfilter count 0
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stringifiedSelections],
  );

  function countNonNullValues(obj) {
    let nonNullCount = 0;

    for (const key in obj) {
      if (key !== 'selections' && obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
        nonNullCount++;
      }
    }

    return nonNullCount;
  }

  const handleSubFilterChange = React.useCallback(
    newOptFilter => {
      props.onChange({ ...aggregate, ...newOptFilter, selections: aggregate?.selections });
      setSubfilterCount(countNonNullValues(newOptFilter));
      cacheSubSearchFilter(newOptFilter);
      setIsNewSubFilterApplied(false);
      throttleService({ ...newOptFilter, ...selectedRecruiterAndBrandIds });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aggregate, throttleService, selectedRecruiterAndBrandIds],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkedOptIds = React.useMemo(() => aggregate?.selections?.map(o => o.id), [stringifiedSelections]);

  React.useEffect(
    () => {
      const selected = aggregate?.selections ?? [];
      const appliedSubFilterCount = countNonNullValues(aggregate);
      setSubfilterCount(appliedSubFilterCount);
      /** Condition ensures selectAllValue is removed if any options are manually removed - say chip deletion */
      if (
        selectAllValue &&
        options &&
        selected.length !== options.length &&
        selected.some(item => item.value === selectAllValue)
      )
        props.onChange({ ...aggregate, selections: _.cloneDeep(selected).splice(1, selected.length - 1) });

      if (!selected?.length && !subFilterCount) {
        setSelectedCountTag(filterName);
      } else if (selected?.length > 0) {
        setSelectedCountTag(
          `(${appliedSubFilterCount ? appliedSubFilterCount + selected.length : selected.length}) ${filterName}`,
        );
      } else if (!selected?.length && subFilterCount) {
        setSelectedCountTag(`(${subFilterCount}) ${filterName}`);
      }

      return EnableAppScroll();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stringifiedSelections, JSON.stringify(options), subFilterCount],
  );

  const handleKeyDown = React.useCallback(
    event => {
      if (open) {
        /** Code: Apply on click on enter key */
        if (event.key === 'Enter') {
          onApplyEvent();
        } else if (event.key === 'Escape') {
          /** Code: Cancel on click on Esc key */
          cancelChanges();
        } else if (event.altKey && event.key.toLowerCase() === 'c') {
          /** Code: Apply on click on aly + "c" key */
          cancelChanges();
        }
      }
      /** Add future key down features below */
    },
    [cancelChanges, onApplyEvent, open],
  );

  React.useEffect(() => {
    document.body.addEventListener('keydown', handleKeyDown);

    return () => {
      document.body.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  React.useEffect(() => {
    if (filterAttributes && filterAttributes.filters) {
      const recruiterIds = (filterAttributes.filters?.recruiterIds as FilterObject[]) || [];
      const brandIds = (filterAttributes.filters?.brandIds as FilterObject[]) || [];

      const stringRecrIds = recruiterIds.map(obj => obj.value).join(', ');
      const stringbrandIds = brandIds.map(obj => obj.value).join(', ');

      setSelectedRecruiterAndBrandIds({
        recruiterIds: stringRecrIds ? stringRecrIds : null,
        brandIds: stringbrandIds ? stringbrandIds : null,
      });
    }
  }, [filterAttributes]);

  return (
    <Box>
      <div className={classes.root}>
        <Button
          disableRipple
          variant="outlined"
          style={{
            borderColor: hasControlError ? '#D90000' : '',
            color: hasControlError ? '#D90000' : '',
            height: isNested ? '10px' : '',
            minWidth: minWidth ? minWidth : '',
          }}
          aria-describedby={id}
          disabled={!!isDisabled}
          endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          classes={{ root: classes.outlineAnchor }}
          onClick={handleOpen}
          id={props.anchorAccesorid}
        >
          {isNested ? (
            <pre style={{ font: 'normal normal normal 12px Roboto' }}>{selectedCountTag}</pre>
          ) : (
            selectedCountTag
          )}
        </Button>
        {hasControlError ? (
          <div style={{ fontSize: '0.7rem', color: 'red', marginLeft: '3px' }}>
            <span>{controlErrorText}</span>
          </div>
        ) : null}
      </div>
      <Popper
        className={customClass ?? classes.popper}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={[
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top', 'right'],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={onClickAway}>
            <Fade {...TransitionProps} in={open} timeout={300}>
              <Paper aria-label={id} classes={{ root: classes.paperDropdown }}>
                <Typography variant="subtitle2" gutterBottom style={{ padding: '10px 0px 5px 16px' }}>
                  {selectedCountTag}
                </Typography>
                <CandidateSubSearch value={aggregate} onChange={handleSubFilterChange} />
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  classes={{
                    root: classes.topActionContainer,
                  }}
                >
                  <Button
                    className="clear-all-button"
                    aria-label="apply-button"
                    variant="text"
                    color="primary"
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    classes={{ text: `${classes.button} ${classes.actionButton}` }}
                    onClick={handleSelectAll}
                  >
                    {t('search.filter.candidateSearch.selectAll')}
                  </Button>
                  <Button
                    className="clear-all-button"
                    aria-label="cancel-button"
                    variant="text"
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    color={aggregate?.selections?.length > 0 ? 'primary' : 'secondary'}
                    onClick={handleClearAll}
                    classes={{ text: `${classes.button} ${classes.actionButton}` }}
                  >
                    {t('search.filter.candidateSearch.clearAll')}
                  </Button>
                </Grid>
                {loadingOpts ? (
                  <div className={classes.loadingLabel}>{t('Loading...')}</div>
                ) : options.length === 0 ? (
                  <div className={classes.loadingLabel}>{t('No Options')}</div>
                ) : (
                  <List
                    sx={{
                      width: '100%',
                      bgcolor: 'background.paper',
                      position: 'relative',
                      overflow: 'auto',
                      maxHeight: 300,
                      maxWidth: '540px',
                      '& ul': { padding: 0 },
                    }}
                  >
                    {options.map(opt => {
                      const labelId = `checkbox-list-label-${opt}`;
                      return (
                        <ListItem key={opt.id} disablePadding>
                          <ListItemSecondaryAction sx={{ top: '30%' }}>{opt.candidateId}</ListItemSecondaryAction>
                          <ListItemButton
                            role={undefined}
                            onClick={e => {
                              // @ts-ignore
                              if (e.key !== 'Enter') {
                                handleChange(opt, !checkedOptIds?.includes(opt.id));
                              }
                            }}
                            sx={{ paddingBottom: '0px', paddingTop: '0px' }}
                            dense
                          >
                            <ListItemIcon sx={{ minWidth: '30px' }}>
                              <Checkbox
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                                checked={checkedOptIds?.includes(opt.id)}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={labelId}
                              primary={
                                <Typography variant="subtitle2" sx={{ display: 'flex', gap: '3px' }}>
                                  <span>{opt.name}</span>
                                  {opt.otherName && <span className={classes.otherName}>{`(${opt.otherName})`}</span>}
                                </Typography>
                              }
                              secondary={opt.brands}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                )}

                {removeActionContainer ? null : (
                  <div className={classes.actionContainer}>
                    <Grid item container justifyContent="flex-end" alignItems="center">
                      <Button
                        className="clear-all-button"
                        aria-label="cancel-button"
                        variant="text"
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        onClick={() => cancelChanges()}
                        classes={{ text: `${classes.button} ${classes.actionButton} ${classes.inActive}` }}
                      >
                        {t('search.filter.cancel')}
                      </Button>
                      <Button
                        className="clear-all-button"
                        aria-label="apply-button"
                        variant="text"
                        color="primary"
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        onClick={() => onApplyEvent()}
                        classes={{ text: `${classes.button} ${classes.actionButton}` }}
                        id={props.hiddenApplyBtn}
                      >
                        {t('search.filter.apply')}
                      </Button>
                    </Grid>
                  </div>
                )}
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
};
