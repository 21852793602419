import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { Grid, Divider, FormControlLabel, Checkbox, CircularProgress } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { FormProvider, useForm } from 'react-hook-form';
import { Cancel } from 'app/components/Common/CancelModal/Cancel';
import CCCFormIcon from 'app/assets/images/IconImages/clientContact-2.svg';
import _cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';
import { FacilityResponse } from 'app/models/Facility/FacilityDetails';
import { IClientContactRequest } from '../ContactTab/Store/FacilityContacts.redux';
import { CCFirstName } from './FormFields/CCFirstName';
import { CCLastName } from './FormFields/CCLastName';
import { CCTitle } from './FormFields/CCTitle';
import { CCEmail } from './FormFields/CCEmail';
import { ContactPreference } from './FormFields/ContactPreference';
import { ContactStatus } from './FormFields/ContactStatus';
import { CCPhoneNumber } from './FormFields/CCPhoneNumber';
import { clientContactActions } from './store/ClientContact.redux';
import {
  selectClientContactDetails,
  selectContactId,
  selectIsClientContactEditModalOpen,
} from './store/ClientContact.selector';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { usePromiseTracker } from 'react-promise-tracker';
import { FacilityUnits } from './FacilityUnits';
import { CCAction } from './FormFields/CCAction';
import { IOptionsList } from 'app/models/Tasks/Tasks';
import { formatSelectedClientContact, formatSelectedValues } from './Transformers/FormatData';
import { contactStatusChoice, IClientContactDetails } from 'app/models/ClientContact/ClientContact';
import { formatPhoneNumber, formatUpdateClientContactDetails } from './Transformers/DataFormatter';
import { isEmpty } from 'lodash';

export const clientContactModalStyles = makeStyles()(theme => ({
  modalContainer: {
    '& .MuiDialogContent-root': {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: '500px',
        maxHeight: '704px',
      },
    },
    '& .MuiTypography-h6': {
      userSelect: 'none',
      msUserSelect: 'none',
      MozUserSelect: 'none',
      WebkitUserSelect: 'none',
    },
  },
  body: {
    flexDirection: 'row',
  },
  section: {
    margin: '22px 0px',
  },
  sectionHeader: {
    padding: '0px 0px 20px',
    display: 'inline-flex',
  },
  sectionFields: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '14px',
  },
  actionsUnits: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  sectionText: {
    font: 'normal medium 19px Roboto',
    fontWeight: 600,
    fontSize: '18px',
    userSelect: 'none',
    msUserSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
  },
  divider: {
    height: '1px',
  },
  validationLoader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: '50%',
  },
  doNotCall: {
    marginLeft: '26px',
    whiteSpace: 'nowrap',
    '& .MuiTypography-body1': {
      fontSize: '14px',
    },
    '& .MuiIconButton-colorSecondary:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.0)',
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.0)',
    },
  },
  actionError: {
    color: '#f44336',
    font: 'normal medium 10px Roboto',
    fontWeight: 400,
    fontSize: '12px',
  },
}));

export const CreateClientContactModal = ({
  open,
  facilityDetails,
  handleClose,
}: {
  open: boolean;
  facilityDetails?: FacilityResponse;
  handleClose: () => void;
}) => {
  const contactId = useSelector(selectContactId);
  const { t } = useTranslation();
  const { classes } = clientContactModalStyles();
  const selectedClientContactDetails = useSelector(selectClientContactDetails);
  const selectIsContactEditModelOpen = useSelector(selectIsClientContactEditModalOpen);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [doNotCallIsChecked, setDoNotCallIsChecked] = useState<boolean>(false);
  const [actionValues, setActionValues] = useState<IOptionsList[]>([]);
  const [isActionError, setActionError] = useState<boolean>(false);
  const [isPhoneNumberError, setPhoneNumberError] = useState<boolean>(false);
  const [disableCheck, setDisableCheck] = useState<boolean>(false);
  const [isInValidEmail, setIsInValidEmail] = useState<boolean>(false);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [isValidPhoneNumber, setValidPhoneNumber] = useState<boolean>(true);
  const { promiseInProgress: isCallingAPI } = usePromiseTracker({ area: 'save-client-contact', delay: 0 });
  const { promiseInProgress: isCallingUpdateAPI } = usePromiseTracker({ area: 'update-client-contact', delay: 0 });
  const { promiseInProgress: isCallingFetchAPI } = usePromiseTracker({ area: 'get-client-contacts-by-id', delay: 0 });
  const dispatch = useDispatch();

  const isApiLoading = isCallingAPI || isCallingUpdateAPI || isCallingFetchAPI;

  const formMethods = useForm({
    mode: 'onSubmit',
  });
  const {
    handleSubmit,
    trigger,
    setValue,
    reset,
    formState: { dirtyFields },
  } = formMethods;

  const submitClientContact = formData => {
    if (selectIsContactEditModelOpen) {
      const clientContactData = _cloneDeep(formData) as IClientContactDetails;
      formatUpdateClientContactDetails(
        clientContactData,
        formData,
        facilityDetails,
        contactStatusChoice,
        actionValues,
        selectedUnits,
        selectedClientContactDetails,
      );
      const editDetails = { contactId: selectedClientContactDetails?.contactId, ...clientContactData };
      dispatch(clientContactActions.updateClientContactDetails(editDetails));
    } else {
      const newClientContact = _cloneDeep(formData) as IClientContactRequest;
      newClientContact.phoneNumber = formatPhoneNumber(formData?.phoneNumber);
      newClientContact.facilityId = facilityDetails?.facilityId;
      newClientContact.phoneTypeID = 6;
      newClientContact.extensionNotes = 'note';
      newClientContact.countryDialingCode = '1';
      newClientContact.activeSSC = false;
      newClientContact.roleActionIds = formatSelectedValues(actionValues);
      newClientContact.unitIds = formatSelectedValues(selectedUnits);
      dispatch(clientContactActions.saveClientContact(newClientContact));
    }
  };

  const onCloseCall = () => {
    setCancelModalOpen(false);
    dispatch(clientContactActions.setIsClientContactEditModelOpen(false));
    dispatch(clientContactActions.setCreatedContactId(undefined));
    dispatch(clientContactActions.setClientContactDetails(undefined));
    dispatch(clientContactActions.setIsClientContactEditModelOpen(false));
    handleClose();
  };

  const onCloseModal = () => {
    if (!isEmpty(dirtyFields)) {
      setCancelModalOpen(true);
    } else {
      onCloseCall();
    }
  };

  useEffect(() => {
    if (actionValues?.length > 0) {
      setActionError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionValues]);

  useEffect(() => {
    if (selectedClientContactDetails) {
      const contactDetails = formatSelectedClientContact(selectedClientContactDetails);
      reset(contactDetails, { isDirty: false });
      if (selectedClientContactDetails?.contactPreferenceTypeId === 3) {
        setDoNotCallIsChecked(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientContactDetails?.contactId]);

  useEffect(() => {
    if (isValidPhoneNumber) {
      setPhoneNumberError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidPhoneNumber]);

  useEffect(() => {
    setValue('contactPreference', undefined, { shouldDirty: false });
  }, [setValue]);

  useEffect(() => {
    if (contactId && Number(contactId) > 0) {
      onCloseCall();
    } else if (contactId && Number(contactId) <= 0) {
      dispatch(
        globalActions.setSnackBar({
          message: 'ERROR: Client Contact is not created',
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId]);

  useEffect(() => {
    if (facilityDetails?.facilityId) {
      dispatch(clientContactActions.getFacilityUnits(facilityDetails?.facilityId));
    }
    dispatch(clientContactActions.getActionsList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityDetails?.facilityId]);

  return (
    <FormProvider {...formMethods}>
      <GenericDialog
        className={classes.modalContainer}
        open={open}
        disableEnforceFocus
        variant="blue"
        onClose={(e, reason) => {
          if (reason === 'backdropClick') return;
          onCloseModal();
        }}
        dialogTitleProps={{
          text: selectIsContactEditModelOpen
            ? t('clientContactModal.editModalTitle')
            : t('clientContactModal.modalTitle'),
          expandable: true,
          closeButton: true,
          leadingIcon: <img src={CCCFormIcon} alt="form" />,
        }}
        fullWidth
        maxWidth={'md'}
        dialogActions={[
          {
            text: t('clientContactModal.cancelBtn'),
            variant: 'contained',
            color: 'tertiary',
            onClick: onCloseModal,
          },
          {
            text: selectIsContactEditModelOpen ? t('clientContactModal.saveBtn') : t('clientContactModal.submitBtn'),
            variant: 'contained',
            disabled: false,
            onClick: async e => {
              if (!(actionValues?.length > 0)) {
                setActionError(true);
              }
              if (!isValidPhoneNumber) {
                setPhoneNumberError(true);
              }
              const valid = await trigger();
              if (valid && actionValues?.length > 0 && !isInValidEmail && isValidPhoneNumber) {
                handleSubmit(submitClientContact)().catch(err => {
                  console.log('ERROR: ', err.message | err);
                });
              }
            },
          },
        ]}
      >
        <Grid item className={classes.body}>
          {isApiLoading && <CircularProgress className={classes.validationLoader} />}
          <Grid item className={classes.section}>
            <Grid item xs={12} className={classes.sectionHeader}>
              <Grid item xs={12} className={classes.sectionText}>
                {t('clientContactModal.subHeaders.contactDetails')}
              </Grid>
            </Grid>
            <Grid item className={classes.sectionFields}>
              <CCFirstName formMethods={formMethods} disabled={isApiLoading} />
              <CCLastName formMethods={formMethods} disabled={isApiLoading} />
              <CCTitle formMethods={formMethods} disabled={isApiLoading} />
              <CCEmail
                formMethods={formMethods}
                setIsInValidEmail={setIsInValidEmail}
                isInValidEmail={isInValidEmail}
                disabled={isApiLoading}
              />
              <Grid item xs={12} style={{ display: 'inline-flex' }}>
                <CCPhoneNumber
                  formMethods={formMethods}
                  setValidPhoneNumber={setValidPhoneNumber}
                  isPhoneNumberError={isPhoneNumberError}
                  disabled={isApiLoading}
                />
              </Grid>
            </Grid>
          </Grid>

          <Divider className={classes.divider} />

          <Grid item className={classes.section}>
            <Grid item xs={12} className={classes.sectionHeader}>
              <Grid item xs={12} className={classes.sectionText} style={{ margin: 'auto' }}>
                {t('clientContactModal.subHeaders.preferences')}
              </Grid>
              <Grid item xs={12} className={classes.doNotCall}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={doNotCallIsChecked}
                      onClick={() => setDoNotCallIsChecked(!doNotCallIsChecked)}
                      disabled={disableCheck || isApiLoading}
                      disableFocusRipple={true}
                      disableTouchRipple={true}
                      disableRipple={true}
                    />
                  }
                  label={t('clientContactModal.doNotCall')}
                />
              </Grid>
            </Grid>
            <Grid item className={classes.sectionFields}>
              <ContactPreference
                formMethods={formMethods}
                doNotCallIsChecked={doNotCallIsChecked}
                setDisableCheck={setDisableCheck}
                disabled={isApiLoading}
              />
              <ContactStatus formMethods={formMethods} disabled={isApiLoading} />
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid item className={classes.section}>
            <Grid container spacing={4} className={classes.actionsUnits}>
              <Grid item xs={6} className={classes.sectionHeader}>
                <Grid item className={classes.sectionText} style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {t('clientContactModal.subHeaders.actions')}
                  <div className={classes.actionError} style={{ paddingLeft: '12px', paddingTop: '6px' }}>
                    {isActionError && t('clientContactModal.subHeaders.actionError')}
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={6} className={classes.sectionHeader}>
                <Grid item className={classes.sectionText}>
                  {t('clientContactModal.subHeaders.units')}
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={8} className={classes.actionsUnits} style={{ padding: '6px 6px' }}>
              <Grid item xs={6}>
                <CCAction selectActionValues={setActionValues} disabled={isApiLoading} />
              </Grid>
              <Grid item xs={6}>
                <FacilityUnits setSelectedUnits={setSelectedUnits} disabled={isApiLoading} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GenericDialog>
      <Cancel
        openDialog={cancelModalOpen}
        handleConfirmAction={() => onCloseCall()}
        handleCancelAction={() => {
          setCancelModalOpen(false);
        }}
      />
    </FormProvider>
  );
};
