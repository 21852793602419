import { missingField } from 'app/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { KeyValueRow } from '../TableComponents/KeyValueTable/KeyValueRow';
import { ExtensionEditWrapper, ExtEditTarget } from './ExtensionEditIcon';

export const ExtAmnHousingRow = ({ 
  extContainerIndex,
  extensionStatusId, 
  amnHousing 
}) => {
  const { t } = useTranslation();
  return (
    <KeyValueRow
      id="placement-ext-status-row"
      rowTitle={t('placement.profile.summary.extension.amnHousing')} 
      loading={false}
    >
      <ExtensionEditWrapper 
        target={ExtEditTarget.amnHousing}
        extContainerIndex={extContainerIndex}
        extensionStatusId={extensionStatusId}
      >
        {
          amnHousing === null 
            ? missingField 
            : amnHousing 
            ? t('placement.profile.summary.extension.yes') 
            : t('placement.profile.summary.extension.no')
        }
      </ExtensionEditWrapper>
    </KeyValueRow>
  );
}