import { PayloadAction } from '@reduxjs/toolkit';
import {
  EditorType,
  IUserPreferenceState,
  IEditorPreference,
  userDevicePreferenceActions,
} from './userPreference.redux';
import { trackException } from 'app-insights/appInsightsTracking';
import { call, put, select } from 'redux-saga/effects';
import { PreferenceCosmosKeys } from './utils';
import { saveEditorPreferences } from './userPreference.saga';
import { selectEditorPreferences } from './userPreference.selectors';

export function* saveConfirmationEditorPreference(
  action: PayloadAction<{ editor: EditorType; preference: IUserPreferenceState<IEditorPreference> }>,
) {
  try {
    /** Code: To maintain complementary logic - client confirmation editor screens */
    if (!['settingsConfirmationTemplateEditor', 'placementConfirmationDocEditor'].includes(action.payload.editor))
      return;

    const complementaryEditor =
      action.payload.editor === 'settingsConfirmationTemplateEditor'
        ? 'placementConfirmationDocEditor'
        : 'settingsConfirmationTemplateEditor';

    const existingPrefDirectory = yield select(selectEditorPreferences);

    const newPreferences = {
      ...action.payload,
      preference: {
        ...action.payload.preference,
        value: {
          ...action.payload.preference.value,
          editorWidth: existingPrefDirectory[action.payload.editor].editorWidth,
        },
      },
    };

    const complementaryScreenPreference: IUserPreferenceState<IEditorPreference> = {
      id:
        action.payload.preference.id === PreferenceCosmosKeys.settingsConfirmationTemplateEditor
          ? PreferenceCosmosKeys.placementConfirmationDocEditor
          : PreferenceCosmosKeys.settingsConfirmationTemplateEditor,
      value: {
        ...action.payload.preference.value,
        editorWidth: existingPrefDirectory[complementaryEditor].editorWidth,
      },
    };

    yield put(userDevicePreferenceActions.setEditorPreference(newPreferences));
    yield put(
      userDevicePreferenceActions.setEditorPreference({
        editor: complementaryEditor,
        preference: complementaryScreenPreference,
      }),
    );

    yield call(saveEditorPreferences, {
      type: userDevicePreferenceActions.saveEditorPreferences.type,
      payload: { preference: [newPreferences.preference, complementaryScreenPreference] },
    });
  } catch (exception) {
    trackException({
      exception,
      properties: {
        functionName: 'saveConfirmationEditorPreference',
        area: 'src/oidc/UserDevicePreference/business.saga',
      },
    });
  }
}

export function* saveEditorWidthPreference(action: PayloadAction<{ editor: EditorType; width: number }>) {
  try {
    const existingPrefDirectory = yield select(selectEditorPreferences);
    const currentPreference: IUserPreferenceState<IEditorPreference> = existingPrefDirectory[action.payload.editor];

    const newPreference = {
      ...currentPreference,
      value: { ...currentPreference.value, editorWidth: action.payload.width },
    };

    yield put(
      userDevicePreferenceActions.setEditorPreference({
        editor: action.payload.editor,
        preference: newPreference,
      }),
    );

    yield call(saveEditorPreferences, {
      type: userDevicePreferenceActions.saveEditorPreferences.type,
      payload: { preference: [newPreference] },
    });
  } catch (exception) {
    trackException({
      exception,
      properties: {
        functionName: 'saveEditorWidthPreference',
        area: 'src/oidc/UserDevicePreference/business.saga',
      },
    });
  }
}
