import { TypeAhead } from '@AMIEWEB/Common';
import { Popper } from 'amn-ui-core';
import { ITypeAheadOption } from 'app/ComponentLibrary/Filter/FilterTypeAhed';
import { getSearchLookups } from 'app/services/SharedServices/SharedServices';
import { throttle } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { makeStyles } from 'tss-react/mui';

const trackArea = 'create-placement-recruiter-lookup';

const useStyles = makeStyles()(() => ({
  recruiterItem: {
    minWidth: '160px',
  },
}));

export const Recruiter = ({ ...props }) => {
  const { classes } = useStyles();
  const { promiseInProgress } = usePromiseTracker({ area: trackArea, delay: 0 });

  const [options, setOptions] = useState<ITypeAheadOption[]>([]);

  const fetchOptions = React.useCallback(async request => {
    const response = await getSearchLookups('recruiters', request);
    setOptions(
      (response || []).map(opt => ({
        object: { id: opt.value, name: opt.name, value: opt.value },
        label: opt.name,
      })),
    );
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttleService = useMemo(() => throttle(request => trackPromise(fetchOptions(request), trackArea), 2000), []);

  return (
    <Controller
      name="recruiter"
      render={({ ref, ...rest }) => (
        <TypeAhead
          {...rest}
          options={options}
          hasAsyncOptions
          removeCloseIcon
          forcePopupIcon={false}
          onInputChange={throttleService}
          isOptionsLoading={promiseInProgress}
          placeholder={rest?.value?.label ?? 'Select'}
          popperComponent={PopperComponent}
          onFocus={throttleService}
          label="Recruiter"
          size="small"
          customClasses={{ root: classes.recruiterItem }}
        />
      )}
    />
  );
};

const PopperComponent = props => (
  <Popper {...props} style={{ width: 'fit-content', minWidth: 150 }} placement="bottom-start" />
);
