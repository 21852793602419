import { Checkbox, FormControlLabel, Radio, SxProps } from 'amn-ui-core';
import React from 'react';
import { useSampleCheckBoxStyles } from './Styles';

interface Props {
  isChecked: boolean | null;
  handleChecked: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checkboxLabel: string;
  checkboxName: string;
  labelStyle?: string;
  isdisabled?: boolean;
  hasRadio?: boolean;
  id?: string;
  defaultValue?: boolean;
  sx?: SxProps;
}

export const SimpleCheckbox = (props: Props) => {
  const { classes } = useSampleCheckBoxStyles();
  const {
    isChecked,
    handleChecked,
    checkboxLabel,
    checkboxName,
    labelStyle,
    isdisabled,
    hasRadio,
    id,
    defaultValue,
    sx,
  } = props;
  return (
    <>
      <FormControlLabel
        control={
          <>
            {hasRadio ? (
              <Radio
                id={id}
                disableFocusRipple
                disableRipple
                disableTouchRipple
                disabled={isdisabled}
                checked={isChecked ? true : false}
                onChange={handleChecked}
                className={isdisabled ? `${classes.disabled} ${classes.checkboxPadding}` : `${classes.checkboxPadding}`}
                name={checkboxName}
                color="primary"
              />
            ) : (
              <Checkbox
                id={id}
                disableFocusRipple
                disableRipple
                disableTouchRipple
                disabled={isdisabled}
                defaultChecked={defaultValue || false}
                checked={isChecked ? true : false}
                onChange={handleChecked}
                className={isdisabled ? `${classes.disabled} ${classes.checkboxPadding}` : `${classes.checkboxPadding}`}
                name={checkboxName}
                color="primary"
                sx={sx}
              />
            )}
          </>
        }
        label={checkboxLabel}
        className={
          isdisabled ? `${labelStyle} ${classes.checkboxLabelDeActivated}` : `${labelStyle} ${classes.checkboxLabel}`
        }
      />
    </>
  );
};
