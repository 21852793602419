import { Box, Typography } from 'amn-ui-core';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useEffect } from 'react';
import { useUnitNavigationStyles } from './Styles';

export const UnitNavigationMenu = ({ navRefs }) => {
  const { classes, cx } = useUnitNavigationStyles();
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const scrollWindow = document.getElementById('app-component-access-id') as HTMLElement;

  const nearestIndex = React.useCallback((currentPosition, sectionPositionArray, startIndex, endIndex) => {
    if (startIndex === endIndex) return startIndex;
    else if (startIndex === endIndex - 1) {
      if (
        Math.abs(sectionPositionArray[startIndex].navRef.current?.offsetTop - currentPosition) <
        Math.abs(sectionPositionArray[endIndex].navRef.current?.offsetTop - currentPosition)
      )
        return startIndex;
      else return endIndex;
    } else {
      var nextNearest = ~~((startIndex + endIndex) / 2);
      var a = Math.abs(sectionPositionArray[nextNearest].navRef.current?.offsetTop - currentPosition);
      var b = Math.abs(sectionPositionArray[nextNearest + 1].navRef.current?.offsetTop - currentPosition);
      if (a < b) {
        return nearestIndex(currentPosition, sectionPositionArray, startIndex, nextNearest);
      } else {
        return nearestIndex(currentPosition, sectionPositionArray, nextNearest, endIndex);
      }
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const doc = document.getElementById('containerElement');
      const bounds = doc.getBoundingClientRect();
      const offset = doc.offsetTop;
      if (scrollWindow.scrollTop + scrollWindow.clientHeight >= scrollWindow.scrollHeight) {
        setActiveIndex(navRefs.length - 1);
      } else {
        var index = nearestIndex(
          bounds.top > offset ? 0 : Math.abs(bounds.top - offset),
          navRefs,
          0,
          navRefs.length - 1,
        );
        setActiveIndex(index);
      }
    };
    document.addEventListener('wheel', handleScroll, true);
    return () => {
      document.removeEventListener('wheel', handleScroll, true);
    };
  }, [navRefs, nearestIndex, activeIndex]);

  const handleClick = index => {
    setActiveIndex(index);
    setTimeout(() => {
      navRefs[index].navRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, 50);
  };

  return (
    <React.Fragment>
      <div className={classes.leftTab}>
        <LayoutGrid className={classes.menu}>
          {navRefs &&
            navRefs.map((item, index) => {
              return (
                <Box key={index} className={classes.tab}>
                  <LayoutGrid container>
                    <LayoutGrid item xs={12} md={1}>
                      <LayoutGridItem item sx={{ padding: '0px  !important' }}>
                        <Box
                          className={
                            item.categoryIndex === navRefs[activeIndex].categoryIndex && item?.mainMenuItem
                              ? classes.selected
                              : classes.unselected
                          }
                        />
                      </LayoutGridItem>
                    </LayoutGrid>
                    <LayoutGrid item xs={12} md={11}>
                      <LayoutGridItem item sx={{ padding: '0px  !important', marginBottom: item.multiLine && '25px' }}>
                        <Typography
                          variant="body2"
                          classes={{
                            root: cx({
                              [classes.mainMenuItem]: item?.mainMenuItem,
                              [classes.subMenuItem]: !item?.mainMenuItem,
                              [classes.textFontSelected]:
                                item.categoryIndex === navRefs[activeIndex].categoryIndex && item?.mainMenuItem,
                              [classes.textColorSelected]:
                                activeIndex === index ||
                                (item.categoryIndex === navRefs[activeIndex].categoryIndex && item?.mainMenuItem),
                              [classes.textColorUnselected]: !(
                                activeIndex === index ||
                                (item.categoryIndex === navRefs[activeIndex].categoryIndex && item?.mainMenuItem)
                              ),
                            }),
                          }}
                          onClick={() => {
                            handleClick(index);
                          }}
                        >
                          {item.label}
                        </Typography>
                      </LayoutGridItem>
                    </LayoutGrid>
                  </LayoutGrid>
                </Box>
              );
            })}
        </LayoutGrid>
      </div>
    </React.Fragment>
  );
};
