import {
  Button,
  Divider,
  Typography,
  Paper,
  Popper,
  Fade,
  List,
  ListItem,
  ClickAwayListener,
  ListItemButton,
  ListItemIcon,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';

import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import { CustomTooltip } from '@AMIEWEB/Common';

const useStyles = makeStyles()(theme => ({
  disabledSelectField: {
    minWidth: '140px',
    // height: '28px',
    fontSize: '12px !important',
    fontWeight: 'bold',
    backgroundColor: `${theme.palette.system.primary} !important`,
    opacity: '50%',
    color: theme.palette.components.button.primary.color,
    textAlign: 'start',
    alignItems: 'center',
    justifyContent: 'flex-start',
    lineHeight: '20px',
    '& .Mui-disabled': {
      color: `${theme.palette.components.button.primary.color} !important`,
      '-webkit-text-fill-color': `${theme.palette.components.button.primary.color} !important`,
    },
  },

  selectField: {
    minWidth: '140px',
    fontWeight: 'bold',
    fontSize: '12px !important',
    textAlign: 'start',
    lineHeight: '20px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
  },
  selectFieldFocused: {
    minWidth: '140px',
    fontWeight: 'bold',
    fontSize: '12px !important',
    textAlign: 'start',
    lineHeight: '20px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
  },
  listStyle: {
    maxHeight: 210,
    paddingBottom: '0px',
    overflowX: 'hidden',
    overflowY: 'auto',
    minWidth: '200px',
  },

  expandIcon: {
    marginTop: '-1px',
  },
}));

export const FilterPreferenceSelector = ({
  filterOptions,
  selectedFilterOption,
  setMangeFilters,
  handleFilterOptionsChange,
  size,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpened(prev => !prev);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsMenuOpened(false);
  };
  return (
    <div>
      <CustomTooltip
        tooltipContent={t(`global.filterPreferences.saveFiltersMessage`)}
        disabled={filterOptions.length !== 0}
        placement="bottom"
        arrow
      >
        <>
          <Button
            onClick={handleClick}
            variant="contained"
            color="primary"
            size={size}
            className={
              !filterOptions.length
                ? classes.disabledSelectField
                : isMenuOpened
                ? classes.selectFieldFocused
                : classes.selectField
            }
            endIcon={
              isMenuOpened ? (
                <ExpandLessOutlinedIcon className={classes.expandIcon} />
              ) : (
                <ExpandMoreOutlinedIcon className={classes.expandIcon} />
              )
            }
          >
            <Typography style={{ minWidth: '85px', fontSize: '12px' }}>
              {selectedFilterOption || 'SELECT FILTERS'}
            </Typography>
          </Button>
          <Popper open={isMenuOpened} anchorEl={anchorEl} placement="bottom-start" transition sx={{ zIndex: 1 }}>
            {({ TransitionProps }) => (
              <ClickAwayListener onClickAway={handleClose}>
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <List className={classes.listStyle}>
                      {(!filterOptions.length || selectedFilterOption === 'SELECT FILTERS') && (
                        <ListItem
                          disableGutters
                          disablePadding
                          value={`SELECT FILTERS`}
                          style={{ display: selectedFilterOption === 'SELECT FILTERS' ? 'none' : 'default' }}
                          key={0}
                        >
                          <ListItemButton>
                            <Typography variant="body1">{t('ButtonDropdown.listItem.default')}</Typography>
                          </ListItemButton>
                        </ListItem>
                      )}
                      {filterOptions.map((item, index) => (
                        <ListItem
                          disableGutters
                          disablePadding
                          key={index + 1}
                          value={item}
                          aria-labelledby={item}
                          onClick={e => {
                            handleFilterOptionsChange(item);
                            setIsMenuOpened(false);
                          }}
                          selected={selectedFilterOption === item}
                        >
                          <ListItemButton sx={{ height: '40px' }}>
                            <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                              {item}
                            </Typography>
                          </ListItemButton>
                        </ListItem>
                      ))}
                      <Divider />
                      <ListItem disableGutters disablePadding onClick={() => setMangeFilters(true)}>
                        <ListItemButton sx={{ height: '40px' }}>
                          <ListItemIcon sx={{ minWidth: '32px' }}>
                            <SettingsOutlined color="primary" />
                          </ListItemIcon>
                          <Typography variant="body1" color="primary">
                            {t('Manage Filters')}
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Paper>
                </Fade>
              </ClickAwayListener>
            )}
          </Popper>
        </>
      </CustomTooltip>
    </div>
  );
};
