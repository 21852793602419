import { FormatDate } from '@AMIEWEB/Notification/Tasks/Common/FormatData';
import { IRequestCoverageProp } from 'app/models/UserCoverage/UserCoverage';
import { isEmpty } from 'lodash';

export enum CoverageRequestsGridKeys {
  coverageFor = 'coverageFor',
  coveredBy = 'coveredBy',
  dateRange = 'dateRange',
  displayCoverageDuration = 'displayCoverageDuration',
  notes = 'notes',
  createdBy = 'createdBy',
  createdDate = 'createdDate',
  lastUpdatedBy = 'lastUpdatedBy',
}

export interface ISortModel {
  field: string;
  sort: 'asc' | 'desc';
}

export const coverageColumnVisibilityModel = {
  coverageFor: true,
  coveredBy: true,
  dateRange: true,
  displayCoverageDuration: true,
  notes: true,
  task: true,
  createdBy: true,
  createdDate: true,
  lastUpdatedBy: true,
};

export const formatFilterAttributes = filterAttributes => {
  const filterData: IRequestCoverageProp = {
    coveredEmployeeIds: !isEmpty(filterAttributes?.selectedChipFilters?.coverageFor)
      ? filterAttributes?.selectedChipFilters?.coverageFor?.map(x => x?.value)
      : filterAttributes?.filters?.coverageFor?.map(x => x?.value),
    coveringEmployeeIds: !isEmpty(filterAttributes?.selectedChipFilters?.coveredBy)
      ? filterAttributes?.selectedChipFilters?.coveredBy?.map(x => x?.value)
      : filterAttributes?.filters?.coveredBy?.map(x => x?.value),
    createdByIds: !isEmpty(filterAttributes?.selectedChipFilters?.createdBy)
      ? filterAttributes?.selectedChipFilters?.createdBy?.map(x => x?.value)
      : filterAttributes?.filters?.createdBy?.map(x => x?.value),
    startDate: filterAttributes?.filters?.dateRange
      ? FormatDate(filterAttributes?.filters?.dateRange?.startDate?.setHours(15))
      : null,
    endDate: filterAttributes?.filters?.dateRange
      ? FormatDate(filterAttributes?.filters?.dateRange?.endDate?.setHours(15))
      : null,
  };
  return filterData;
};

export const checkRequiredFilters = (filters: IRequestCoverageProp) => {
  return (
    filters?.coveredEmployeeIds?.length ||
    filters?.coveringEmployeeIds?.length ||
    filters?.createdByIds?.length ||
    filters?.startDate ||
    filters?.endDate
  );
};
