import CloseIcon from '@mui/icons-material/Close';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import EmailMenu from 'app/assets/images/EmailMenu.svg';
import {
  Backdrop,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  ThemeProvider,
  StyledEngineProvider,
  Toolbar,
  useTheme,
} from 'amn-ui-core';
import { trackEvent } from 'app-insights/appInsightsTracking';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import Minimize from 'app/assets/images/Notification/MinimizeIcon.svg';
import {
  candidateDetailsSelection,
  selectCandidateConsentLinks,
} from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { CustomBackdrop } from 'app/components/Common/Backdrop/CustomBackdrop';
import { selectGridTag } from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import {
  IEmployee,
  IEmailTemplate,
  IPublishEmail,
  ITemplate,
  ITemplateChangeTracker,
  TemplateType,
} from 'app/models/Notification/Notification';
import { saveDraftEmail } from 'app/services/NotificationServices/NotificationService';
import {
  GetAvailableCategory,
  GetTemplatesByCategory,
  SaveTemplate,
} from 'app/services/NotificationServices/TemplateService';
import { selectUser } from 'oidc/user.selectors';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Draggable from 'react-draggable';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast, Zoom } from 'react-toastify';
import { ChangeBanner } from '../../Common/ChangeBanner';
import { ChangeConfirmationWithAction, GenericConfirmationDialog } from '../../Common/CloseDialog';
import { ToasterContent } from '../../Common/ToasterContent';
import { ToasterHeader } from '../../Common/ToasterHeader';
import { ChannelType, SendType, UseType } from '../../Constants';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import {
  notificationSelection,
  selectCategoryTitles,
  selectCommunicationLimitsDialog,
  selectCommunicationLimitsEmail,
  selectEmailBCC,
  selectEmailConsentWithdrawn,
  selectEmailType,
  selectForwardAttachmentUrls,
  selectSendType,
  selectSendWithoutConsent,
  selectSender,
} from 'store/redux-store/notification/notification.selector';
import { NotificationContext } from '../../NotificationWrapper';
import { ModalAppBar, ModalClose, ModalTitle } from '../../styles';
import { Compose } from './ComposeContainer';
import { Preview } from './PreviewContainer';
import { EmailDefaultValues, IEmailData } from 'app/models/Email/IEmail';
import _ from 'lodash';
import { missingField } from 'app/constants';
import { Concatenate } from 'utils/string/string';
import { useEnableOneTrustConsents } from 'oidc/CommunicationEnabler';
import { usePromiseTracker } from 'react-promise-tracker';
import { coverageActions } from 'store/redux-store/user-coverage/async-actions';
import { useDecision } from '@optimizely/react-sdk';
import { ff_notifications_coverage_email } from 'app/constants/FeatureFlags/EmployeeCoverage/keys';
import { selectActiveStep, selectDeletedAttachments } from 'app/ApplicationRoot/Global.selectors';
import { selectUserDetails } from '@AMIEWEB/Settings/UserManagement/store/userManagement.selector';
import { selectPlacementHeaderData } from 'store/redux-store/placement-details/selectors';
import { PlacementDocumentFileNamePrefix } from 'store/redux-store/placement-documents/types';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { ff_notifications_enable_shareddesk_communication } from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';
import { useEmailStyles } from './Email.styles';
import { EmailActionButtons } from './EmailActions';
import { SaveTemplateModal } from './SaveTemplateModal';
import { getModalTitle, getSignatureSubstitution, sendEmail } from './EmailHelper';
import { EmailSettings } from './EmailSettings';

export const Email = ({
  attachments,
  setAttachments,
  urlAttachments,
  setUrlAttachments,
  senderMenuValue,
  setSenderMenuValue,
}) => {
  const { t } = useTranslation();
  const { classes } = useEmailStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { onClose, handleSubmitResponse } = useContext(NotificationContext);

  const { promiseInProgress: callingConsentsSaga } = usePromiseTracker({ area: 'email-consents-saga', delay: 0 });
  const { promiseInProgress: trackFetchCoverage } = usePromiseTracker({
    area: 'fetch-active-coverage-provided-by-user',
    delay: 0,
  });
  const { promiseInProgress: trackCommunicationLimits } = usePromiseTracker({
    area: 'communication-limits-saga',
    delay: 0,
  });

  const enableOneTrustConsents = useEnableOneTrustConsents();
  const [coverageFlag] = useDecision(ff_notifications_coverage_email);
  const [sharedDeskFlag] = useDecision(ff_notifications_enable_shareddesk_communication);

  const globalData = useSelector(notificationSelection);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const user = useSelector(selectUser);
  const userPreference = useSelector(selectUserPreference);
  const forwardAttachmentUrls = useSelector(selectForwardAttachmentUrls);
  const sendWithoutConsent = useSelector(selectSendWithoutConsent);
  const emailSendType = useSelector(selectSendType);
  const emailConsents = useSelector(selectEmailConsentWithdrawn);
  const emailSender = useSelector(selectSender);
  const bccData = useSelector(selectEmailBCC);
  const activeStep = useSelector(selectActiveStep);
  const deletedAttachments = useSelector(selectDeletedAttachments);
  const emailType = useSelector(selectEmailType);
  const gridTag = useSelector(selectGridTag);
  const userDetails = useSelector(selectUserDetails);
  const communicationLimitsEmail = useSelector(selectCommunicationLimitsEmail);
  const categoryTitles = useSelector(selectCategoryTitles);
  const limitsDialog = useSelector(selectCommunicationLimitsDialog);
  const placementDetails = useSelector(selectPlacementHeaderData);
  const candidateConsentLinks = useSelector(selectCandidateConsentLinks);

  const [notificationData, setNotificationData] = useState(globalData?.email?.data!);
  const [originalAttachmentUrls, setOriginalAttachmentUrls] = useState<string[]>([]);
  const [isOverflowActive, setOverflowActive] = useState(false);
  const [disableSend, setDisableSend] = useState<boolean>(true);
  const [isExpanded, setIsExpanded] = useState<boolean>(globalData.email.expanded ? globalData.email.expanded : false);
  const [isDocumentDownloaded, setIsDocumentDownloaded] = useState<boolean>(false);
  const [titlesList, setTitlesList] = useState<string[]>([]);
  const [emailTemplates, setEmailTemplates] = useState<IEmailTemplate>({
    showTemplate: false,
    selectedTemplate: notificationData?.selectedTemplate || {
      title: '',
      id: '',
      body: '',
      description: '',
      category: '',
      isPrivate: false,
      substitutions: [],
    },
    publicCategory: [],
    personalCategory: [],
    publicTemplates: notificationData?.publicTemplates || [],
    personalTemplates: notificationData?.personalTemplates || [],
    selectedPersonalCategory: notificationData?.selectedPersonalCategory || '',
    selectedPublicCategory: notificationData?.selectedPublicCategory || '',
    templateTab: TemplateType.Public,
  });

  const [templateChangeTracker, setTemplateChangeTracker] = useState<ITemplateChangeTracker>({
    isEdited: false,
    showConfirmation: false,
    changedTemplate: null,
  });
  const [templateSaveTitleError, setTemplateSaveTitleError] = useState<string>('');
  const [templateSaveDescError, setTemplateSaveDescError] = useState<string>('');
  const [templateSaveCategoryError, setTemplateSaveCategoryError] = useState<string>('');
  const [selectedTemplateSaveCategory, setSelectedTemplateSaveCategory] = useState<string>('');
  const [defaultEmailData, setDefaultEmailData] = useState<IEmailData>({ ...EmailDefaultValues() });

  const [loader, setLoader] = useState({
    backdropLoader: true,
    templateSelectionGridLoader: false,
  });
  const [action, setAction] = useState({
    minimize: false,
    openCompose: true,
  });
  const [groupEmail, setGroupEmail] = useState<boolean>(emailType === SendType.one_to_one || false);

  const loadingEmailModal = useMemo(
    () => callingConsentsSaga || trackFetchCoverage || trackCommunicationLimits,
    [callingConsentsSaga, trackFetchCoverage, trackCommunicationLimits],
  );
  const data = globalData?.email?.data!;
  const kofaxCandidateDetails = globalData?.sms?.chatHistory?.candidateDetails;
  const closeModalData = {
    email: {
      data: null,
      open: false,
      minimized: false,
      expanded: false,
    },
  };

  useEffect(() => {
    if (groupEmail) {
      dispatch(notificationDataActions.setEmailType(SendType.one_to_one));
    } else {
      dispatch(notificationDataActions.setEmailType(SendType.bulk));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupEmail, emailTemplates.selectedTemplate]);

  useEffect(() => {
    getTemplatesWithCategory();
    const open = () => {
      setOverflowActive(true);
    };

    const close = () => {
      setOverflowActive(false);
    };
    const sharedProfile = user.userInfo?.sharedProfile;
    const senderInfo: IEmployee = {
      ...globalData.email?.data?.sender,
      name: Concatenate([sharedProfile?.firstName || '', sharedProfile?.lastName || ''], ' '),
      email: sharedProfile?.email,
    };
    if (sharedDeskFlag.enabled && !isNullOrUndefined(sharedProfile)) {
      dispatch(notificationDataActions.setEmailSender(senderInfo));
      setDefaultEmailData({
        ...notificationData,
        sender: senderInfo,
      });
    } else {
      setDefaultEmailData({ ...notificationData });
    }

    window.addEventListener('focus', close);

    window.addEventListener('blur', open);

    return () => {
      window.removeEventListener('focus', close);
      window.removeEventListener('blur', open);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFile = async () => {
    const blobUrlToFile = async (blobUrl, fileName, fileType) => {
      const response = await fetch(blobUrl);
      const blob = await response.blob();
      return new File([blob], fileName, { type: fileType });
    };
    const fileNameSuffix = `${placementDetails?.candidate.firstName}_${placementDetails?.candidate.lastName}_${placementDetails?.placementId}.pdf`;
    if (data?.attachmentData && data?.packetSubmission && !isDocumentDownloaded) {
      const blobData = data?.attachmentData;
      let fileName = data?.attachmentData?.documentPacket?.fileName ?? data?.attachmentData?.fileName;
      fileName = fileName?.includes('AMN')
        ? fileName
        : `${PlacementDocumentFileNamePrefix.submission}${fileNameSuffix}`;
      const file = await blobUrlToFile(blobData.blobUrl, fileName, blobData.fileType);
      if (deletedAttachments.length > 0) {
        if (!deletedAttachments.includes(file.name)) {
          setAttachments([file]);
        }
      } else {
        setAttachments([file]);
      }
      setIsDocumentDownloaded(true);
    }
    if (data?.attachmentData && data?.confirmationLetter && !isDocumentDownloaded) {
      const blobData = data?.attachmentData;
      const fileName = `${PlacementDocumentFileNamePrefix.confirmation}${fileNameSuffix}`;
      const file = await blobUrlToFile(blobData.blobUrl, fileName, blobData.fileType);
      setAttachments([file]);
      setIsDocumentDownloaded(true);
    }
  };

  const getForwardAttachments = () => {
    if (forwardAttachmentUrls && forwardAttachmentUrls?.length > 0 && !notificationData.attachmentCannotBeRemoved) {
      const files = [];
      forwardAttachmentUrls?.forEach(attachment => {
        const splitted = attachment.split('/');
        const lastSplitted = splitted?.[splitted?.length - 1] ?? '';
        const file = new File([], lastSplitted);
        files.push(file);
      });
      setUrlAttachments(files);
    }
  };

  useEffect(() => {
    getFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (forwardAttachmentUrls) {
      setOriginalAttachmentUrls(forwardAttachmentUrls);
      getForwardAttachments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forwardAttachmentUrls]);

  useEffect(() => {
    setNotificationData({
      ...notificationData,
      sender: emailSender,
      bccs: bccData,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailSender, bccData]);

  useEffect(() => {
    if (limitsDialog?.open && limitsDialog?.key === ChannelType.email) {
      handleModalClose();
    }
  }, [limitsDialog?.open]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      image: ['.jpg', '.jpeg', '.png'],
      doc: ['.pdf', '.xlsx', '.xlsm', '.doc', '.docx'],
    },
    maxSize: 5242880,
    noClick: true,

    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length !== 0) {
        fileRejections.map(f => {
          var err = f.errors;
          err.map(e => {
            if (
              e.code === 'file-invalid-type' ||
              e.message === 'File type must be .jpg,.jpeg,.png,.pdf,.xlsx,.xlsm,.doc,.docx'
            ) {
              dispatch(
                globalActions.setSnackBar({
                  message: 'Please choose valid file format (.jpeg, .png, .docx, .xls, .pdf)',
                  severity: 'error',
                }),
              );
            } else if (e.code === 'file-too-large') {
              dispatch(
                globalActions.setSnackBar({
                  message: 'The individual file size should not exceed 5MB',
                  severity: 'error',
                }),
              );
            }
          });
        });
      }

      if (acceptedFiles.length !== 0) {
        const files = attachments && (attachments.concat(acceptedFiles) as File[]);
        if (files.length < 6) handleAttachments(files);
        else
          dispatch(
            globalActions.setSnackBar({
              message: 'Please limit file selections to 5 attachments ',
              severity: 'error',
            }),
          );
      }
    },
  });

  useEffect(() => {
    const shallDispatchConsents =
      (emailSendType === SendType.bulk &&
        !(notificationData?.useType === UseType.ClientContacts || notificationData?.useType === UseType.Placement)) ||
      (emailSendType === SendType.one_to_one &&
        !notificationData?.packetSubmission &&
        !notificationData?.confirmationLetter);
    if (notificationData?.tos?.length > 0) {
      const candbrand = notificationData?.tos?.map(item => ({
        contactId: `${item?.contactId || kofaxCandidateDetails?.travelerId}`,
        brandId: `${item?.brandId || kofaxCandidateDetails?.brand}`,
      }));
      enableOneTrustConsents &&
        shallDispatchConsents &&
        dispatch(notificationDataActions.getConsentsWithdrawn([...candbrand]));
    }

    return () => {
      dispatch(notificationDataActions.setEmailConsentWithdrawn([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationData?.tos?.length]);

  useEffect(() => {
    setDisableSend(true);
    if (emailSendType === SendType.one_to_one) {
      for (const items of notificationData?.tos) {
        const itemWithoutConsent = emailConsents?.findIndex(
          con => con?.contactId === items?.contactId?.toString() && con?.brandId === items?.brandId?.toString(),
        );
        if (items.email !== missingField && (itemWithoutConsent === -1 || sendWithoutConsent)) {
          setDisableSend(false);
          break;
        }
      }
    } else {
      for (const items of notificationData?.tos) {
        const consent = emailConsents?.find(
          con => con?.contactId === items?.contactId?.toString() && con?.brandId === items?.brandId?.toString(),
        );
        const limits = communicationLimitsEmail?.find(
          con => con?.contactId === items?.contactId.toString() && con?.brandId === items?.brandId.toString(),
        );
        if (items.email !== missingField && !consent && !limits) {
          setDisableSend(false);
          break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationData?.tos?.length, emailConsents?.length, communicationLimitsEmail?.length]);

  useEffect(() => {
    if (categoryTitles && categoryTitles?.length > 0) {
      setTitlesList([...categoryTitles]);
    }
  }, [categoryTitles]);

  useEffect(() => {
    const currTitle = emailTemplates.selectedTemplate?.title;
    if (titlesList?.includes(currTitle?.toLowerCase())) {
      setTemplateSaveTitleError(t('notification.emails.duplicateTemplateNameWarning'));
    } else if (templateSaveTitleError === t('notification.emails.duplicateTemplateNameWarning')) {
      setTemplateSaveTitleError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titlesList, emailTemplates.selectedTemplate?.title]);

  useEffect(() => {
    if (selectedTemplateSaveCategory?.length > 0) {
      dispatch(notificationDataActions.getTemplatesByCategory({ category: selectedTemplateSaveCategory }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateSaveCategory]);

  const CancelTemplateModel = () => {
    const template: ITemplate = JSON.parse(JSON.stringify(emailTemplates.selectedTemplate));
    template.title = '';
    setEmailTemplates(prevState => ({ ...prevState, selectedTemplate: template }));
    dispatch(globalActions.setActiveStep(1));
  };

  const handleEmailModalClose = () => {
    if (!_.isEqual(notificationData, defaultEmailData) || attachments?.length > 0) {
      onClose(ChannelType.email);
    } else {
      handleModalClose();
    }
  };

  const getStepsComponent = (currentStep: number) => {
    switch (currentStep) {
      case 1:
        return (
          <div
            className={isExpanded ? classes.expandedBodyContainer : classes.bodyContainer}
            style={{ paddingRight: isExpanded ? '' : emailTemplates.showTemplate ? '' : '' }}
          >
            <Grid container spacing={2}>
              {loader.backdropLoader && <Backdrop className={classes.backdrop} open />}

              {emailTemplates.showTemplate && (
                <EmailSettings
                  isExpanded={isExpanded}
                  emailTemplates={emailTemplates}
                  setEmailTemplates={setEmailTemplates}
                  groupEmail={groupEmail}
                  setGroupEmail={setGroupEmail}
                  action={action}
                  setAction={setAction}
                  handleMinimize={handleMinimize}
                  loader={loader}
                  setLoader={setLoader}
                  templateChangeTracker={templateChangeTracker}
                  handleEmailPropertyChange={handleEmailPropertyChange}
                  handleAttachments={handleAttachments}
                  setTemplateChangeTracker={setTemplateChangeTracker}
                  getTemplatesWithCategory={getTemplatesWithCategory}
                  handleSelectedTemplate={handleSelectedTemplate}
                />
              )}
              <Grid
                item
                xs={emailTemplates.showTemplate ? (isExpanded ? 10 : 9) : 12}
                style={{ paddingLeft: isExpanded ? '0px' : '0px', paddingTop: isExpanded ? '12px' : '0px' }}
              >
                <ModalAppBar
                  elevation={0}
                  id="draggableArea"
                  position="static"
                  style={{ height: '55px', justifyContent: 'center', paddingBottom: '0%' }}
                >
                  <Toolbar disableGutters className={classes.newToolbar}>
                    {!emailTemplates.showTemplate && (
                      <Button style={{ minWidth: '10px', padding: '6px 0px' }}>
                        <img
                          src={EmailMenu}
                          alt=""
                          onClick={() => setEmailTemplates(prevState => ({ ...prevState, showTemplate: true }))}
                          data-testid="email-settings-icon"
                        />
                      </Button>
                    )}
                    <ModalTitle variant="h6" style={{ padding: emailTemplates.showTemplate ? '0px' : '' }}>
                      {getModalTitle(activeStep, emailTemplates.selectedTemplate.title, t)}
                    </ModalTitle>
                    {activeStep < 3 &&
                      (isExpanded ? (
                        <IconButton
                          // color="primary"
                          className={classes.closeSelection}
                          disableRipple
                          disableFocusRipple
                          onClick={() => setIsExpanded(false)}
                        >
                          <FullscreenExit />
                        </IconButton>
                      ) : (
                        <IconButton
                          // color="primary"
                          className={classes.closeSelection}
                          disableRipple
                          disableFocusRipple
                          onClick={() => setIsExpanded(true)}
                        >
                          <Fullscreen />
                        </IconButton>
                      ))}
                    {activeStep < 3 && (
                      <IconButton
                        // color="primary"
                        className={classes.closeSelection}
                        style={{ marginLeft: 8 }}
                      >
                        <img src={Minimize} alt="Email" className={classes.minimizedIcon} onClick={handleMinimize} />
                      </IconButton>
                    )}
                    <ModalClose>
                      <IconButton
                        // color="primary"
                        className={classes.closeSelection}
                      >
                        <CloseIcon onClick={handleEmailModalClose} />
                      </IconButton>
                    </ModalClose>
                  </Toolbar>
                </ModalAppBar>
                {activeStep === 1 &&
                  globalData?.snackbarData?.channel === ChannelType.email &&
                  globalData.snackbarData?.changeWarning && (
                    <ChangeBanner message={t('notification.finishEmailLogWarning')} />
                  )}

                {activeStep === 1 &&
                  globalData?.snackbarData?.channel === ChannelType.voice &&
                  !globalData?.snackbarData?.manual &&
                  globalData?.snackbarData?.changeWarning && (
                    <ChangeBanner message={t('notification.finishEmailLogWarning')} />
                  )}

                <div
                  className={isExpanded ? classes.expandedScroll : classes.scroll}
                  style={{
                    height: isExpanded
                      ? globalData.snackbarData?.changeWarning
                        ? '77vh'
                        : emailTemplates.showTemplate
                        ? '84vh'
                        : '84vh'
                      : emailTemplates.showTemplate
                      ? '580px'
                      : '580px',
                    padding: isExpanded
                      ? emailTemplates.showTemplate
                        ? '10px 16px 10px 22px'
                        : '10px 16px 10px 30px'
                      : '10px 22px',
                  }}
                >
                  <Compose
                    data={notificationData}
                    onChange={handleEmailPropertyChange}
                    attachments={attachments}
                    urlAttachments={urlAttachments}
                    handleAttachments={handleAttachments}
                    handleUrlAttachments={handleUrlAttachments}
                    html={notificationData?.body}
                    subject={notificationData?.subject}
                    loader={loader}
                    handlers={{
                      handleEmailPropertyChange,
                      handleAttachments,
                      setAction,
                      setLoader,
                      setTemplateChangeTracker,
                    }}
                    action={action}
                    showUploadBackdrop={isDragActive}
                    showOverlay={isOverflowActive}
                    getInputProps={getInputProps}
                    expanded={isExpanded}
                    changeTracker={templateChangeTracker}
                    senderMenuValue={senderMenuValue}
                    setSenderMenuValue={setSenderMenuValue}
                    setDefaultEmailData={setDefaultEmailData}
                  />
                </div>
                <hr />
                <div style={{ alignItems: 'end', justifyContent: 'right', display: 'flex' }}>
                  <EmailActionButtons
                    isExpanded={isExpanded}
                    anchorSaveEl={anchorSaveEl}
                    loader={loader}
                    saveMenuOpen={saveMenuOpen}
                    handleSaveMenuClose={handleSaveMenuClose}
                    CancelTemplateModel={CancelTemplateModel}
                    handleSaveButtonClick={handleSaveButtonClick}
                    canSaveTemplate={canSaveTemplate}
                    templateSaveTitleError={templateSaveTitleError}
                    templateSaveDescError={templateSaveDescError}
                    templateSaveCategoryError={templateSaveCategoryError}
                    handleSaveTemplateMenu={handleSaveTemplateMenu}
                    handleSaveDraftMenu={handleSaveDraftMenu}
                    emailTemplates={emailTemplates}
                    titlesList={titlesList}
                    handleSaveTemplate={handleSaveTemplate}
                    notificationData={notificationData}
                    handleSubmit={handleSubmit}
                    handleModalClose={handleEmailModalClose}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        );

      case 2:
        return (
          <Preview
            data={{ ...notificationData, sender: emailSender, bccs: [...bccData] }}
            attachments={attachments}
            urlAttachments={urlAttachments}
            handleAttachments={handleAttachments}
            handleUrlAttachments={handleUrlAttachments}
            setLoader={setLoader}
            loader={loader}
            signatureSubstitution={getSignatureSubstitution(senderMenuValue, user, userDetails, userPreference)}
            expanded={isExpanded}
          />
        );

      case 3:
        return (
          <SaveTemplateModal
            isExpanded={isExpanded}
            templateSaveTitleError={templateSaveTitleError}
            templateSaveDescError={templateSaveDescError}
            templateSaveCategoryError={templateSaveCategoryError}
            emailTemplates={emailTemplates}
            setEmailTemplates={setEmailTemplates}
            setTemplateSaveTitleError={setTemplateSaveTitleError}
            setTemplateSaveDescError={setTemplateSaveDescError}
            selectedTemplateSaveCategory={selectedTemplateSaveCategory}
            setSelectedTemplateSaveCategory={setSelectedTemplateSaveCategory}
            setTemplateSaveCategoryError={setTemplateSaveCategoryError}
          />
        );
    }
  };

  const handleAttachments = (files: File[]) => {
    setAttachments(files);
  };
  const handleUrlAttachments = (files: File[]) => {
    setUrlAttachments(files);
  };

  const handleEmailPropertyChange = (emailData: IEmailData) => {
    dispatch(
      notificationDataActions.setEmailData({
        data: emailData,
        open: true,
        minimized: false,
        expanded: isExpanded,
        sendWithoutConsent: sendWithoutConsent,
      }),
    );
    setNotificationData(emailData);
  };

  const handleSubmit = async () => {
    var payload: IPublishEmail = {
      dispatch,
      notificationData,
      attachments,
      gridTag,
      emailType,
      emailConsents,
      communicationLimitsEmail,
      emailSendType,
      senderMenuValue,
      user,
      userDetails,
      userPreference,
      sharedDeskFlag: sharedDeskFlag.enabled,
      globalData,
      originalAttachmentUrls,
      urlAttachments,
      candidateDetails,
      handleSubmitResponse,
      successMessage: t('placement.profile.clientConfirmation.successSentMail'),
      errorMessage: 'Failed to send email',
      loader,
      setLoader,
      candidateConsentLinks,
    };

    await sendEmail(payload).then(response => {
      if (response) {
        setAttachments([]);
        setUrlAttachments([]);
        setSenderMenuValue(null);
      }
    });
  };

  const canSaveTemplate = () => {
    return !(
      (notificationData?.channel === ChannelType.email && !notificationData?.body) ||
      loader.backdropLoader ||
      (notificationData.body && !templateChangeTracker.isEdited)
    );
  };

  const handleSaveTemplate = () => {
    const templateData = JSON.parse(JSON.stringify(emailTemplates.selectedTemplate));
    templateData['channel'] = ChannelType.email;
    setLoader({ ...loader, backdropLoader: true });
    templateData.body = notificationData?.body;
    SaveTemplate(templateData)
      .then(response => {
        setLoader({ ...loader, backdropLoader: false });
        dispatch(globalActions.setActiveStep(1));
        getTemplatesWithCategory();
        handleSubmitResponse(true, 'success', 'The email is saved as template successfully');
        dispatch(notificationDataActions.getTemplatesByCategory({ category: selectedTemplateSaveCategory }));
      })
      .catch(err => {
        setLoader({ ...loader, backdropLoader: false });
        handleSubmitResponse(
          true,
          'error',
          'A template already exists with the same Title. Please update your Template Title.',
        );
      });
  };

  const getTemplatesWithCategory = (category?: string) => {
    GetAvailableCategory(ChannelType.email).then(response => {
      setEmailTemplates(prevState => ({
        ...prevState,
        personalCategory: response?.private ?? [],
        publicCategory:
          response?.public?.filter(item => item !== 'Pipeline Management' && item !== 'Margin Tool') ?? [],
      }));
      if (category && !response?.private.includes(emailTemplates.selectedPersonalCategory)) {
        setEmailTemplates(prevState => ({ ...prevState, selectedPersonalCategory: '' }));
      }
    });
    if (category) {
      setLoader(prevState => ({ ...prevState, templateSelectionGridLoader: true }));
      GetTemplatesByCategory(category, 'email').then(response => {
        setLoader(prevState => ({ ...prevState, templateSelectionGridLoader: false }));
        setEmailTemplates(prevState => ({
          ...prevState,
          personalTemplates: response?.filter((item: { isPrivate: any }) => {
            return item.isPrivate;
          }),
        }));
      });
    }
  };

  const handleMaximize = () => {
    setAction({
      ...action,
      minimize: false,
      openCompose: true,
    });
  };

  const handleMinimize = () => {
    const id = data?.channel;
    setAction({ ...action, minimize: true, openCompose: false });
    dispatch(notificationDataActions.setEmailInteraction({ open: false, minimized: true }));
    dispatch(notificationDataActions.setSnackBarData(null));
    dispatch(notificationDataActions.incrementActiveToaster());
    dispatch(
      notificationDataActions.setEmailData({
        data: {
          ...data,
          selectedTemplate: emailTemplates.selectedTemplate,
          publicTemplates: emailTemplates?.publicTemplates ?? [],
          selectedPublicCategory: emailTemplates.selectedPublicCategory,
          personalTemplates: emailTemplates?.personalTemplates ?? [],
          selectedPersonalCategory: emailTemplates.selectedPersonalCategory,
        },
        open: false,
        minimized: true,
        expanded: isExpanded,
        sendWithoutConsent: sendWithoutConsent,
      }),
    );
    toast(
      <ToasterContent
        id={id}
        channel={ChannelType.email}
        info={data}
        header={
          data?.sendType === 'bulk'
            ? `${data?.tos.length} Recipients`
            : notificationData.packetSubmission || notificationData?.confirmationLetter
            ? ToasterHeader(
                data?.associatedRecords?.find(record => record.name === 'candidate')?.value!,
                data?.associatedRecords?.find(record => record.name === 'travelerId')?.value!,
              )
            : emailTemplates?.selectedTemplate?.category === 'Job Match' &&
              emailTemplates?.selectedTemplate?.title === 'Default Jobs List'
            ? `Send Orders`
            : ToasterHeader(data?.tos.find(Boolean)?.name!, data?.tos.find(Boolean)?.contactId!.toString())
        }
        subheader={
          data?.sendType === 'bulk'
            ? null
            : ` Subject: ${
                data?.subject?.length > 15 ? data?.subject?.substring(0, 15) + ' ...' : data?.subject ?? '—'
              }`
        }
        maximize={handleMaximize}
        onClose={onClose}
      />,
      {
        toastId: id,
        containerId: 'channel',
        position: 'bottom-right',
        closeButton: false,
        hideProgressBar: true,
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
        transition: Zoom,
      },
    );
  };
  const [anchorSaveEl, setAnchorSaveEl] = React.useState<HTMLElement | null>(null);
  const saveMenuOpen = Boolean(anchorSaveEl);

  const handleSaveButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorSaveEl(event.currentTarget);
  };
  const handleSaveMenuClose = () => {
    setAnchorSaveEl(null);
  };
  const handleSaveTemplateMenu = () => {
    setAnchorSaveEl(null);
    dispatch(globalActions.setActiveStep(3));
    const template = JSON.parse(JSON.stringify(emailTemplates.selectedTemplate));
    template.title = '';
    setEmailTemplates(prevState => ({ ...prevState, selectedTemplate: template }));
  };

  const handleSaveDraftMenu = () => {
    trackEvent({ type: 'click', name: 'Save As Draft' });
    setAnchorSaveEl(null);
    setLoader({ ...loader, backdropLoader: true });
    if (notificationData.channel === ChannelType.email && activeStep === 1) {
      const emailForm = new FormData();
      attachments && attachments.map(attachment => emailForm.append('attachments', attachment ?? null));
      const draftData = JSON.parse(JSON.stringify(notificationData));
      if (notificationData?.body && notificationData?.substitutions) {
        if (notificationData?.body?.indexOf('{{') !== -1) {
          const substitution = {};
          notificationData?.substitutions?.map((item: { input: string; value: any }) => {
            substitution[item?.input.replace('{{', '').replace('}}', '')] = item?.value;
          });
          draftData.substitutions = substitution;
        } else {
          draftData.substitutions = {};
        }
      }

      if (!!notificationData.attachmentCannotBeRemoved) {
        draftData.attachmentUrls = notificationData.attachmentUrls!.map(x => x.url);
      }
      emailForm.append('emailDraft', JSON.stringify(draftData));
      saveDraftEmail(emailForm)
        .then(() => {
          setLoader({ ...loader, backdropLoader: false });
          handleSubmitResponse(true, 'success', 'The email is saved as draft successfully!');
          onClose(ChannelType.email);
          dispatch(
            notificationDataActions.setNotificationData({
              ...globalData,
              email: { data: globalData.email?.data, open: false, minimized: false, expanded: false },
              snackbarData: {
                showCloseAction: false,
                showResponse: true,
                message: 'The email is saved as draft successfully!',
                severity: 'success',
              },
            }),
          );
          setAttachments([]);
          dispatch(globalActions.setDeletedAttachments([]));
          setUrlAttachments([]);
          setSenderMenuValue(null);
        })
        .catch(() => {
          setLoader({ ...loader, backdropLoader: false });
          handleSubmitResponse(true, 'error', 'Failed to save draft');
        });
    }
  };

  const handleModalClose = () => {
    dispatch(
      notificationDataActions.setNotificationData({
        ...globalData,
        ...closeModalData,
      }),
    );
    dispatch(notificationDataActions.setSnackBarData(null));
    setAttachments([]);
    setUrlAttachments([]);
    dispatch(globalActions.setDeletedAttachments([]));
    setSenderMenuValue(null);
    dispatch(globalActions.setActiveStep(1));
    dispatch(notificationDataActions.setCommunicationLimitsEmail([]));
  };

  const handleSelectedTemplate = (template: ITemplate) => {
    setEmailTemplates(prevState => ({ ...prevState, selectedTemplate: template }));
    setSelectedTemplateSaveCategory(template?.category ?? '');
    setTemplateChangeTracker(prevState => ({ ...prevState, changedTemplate: template }));
    const emailInfo = JSON.parse(JSON.stringify(notificationData));
    emailInfo.body = template.body;
    emailInfo.subject = template.subject;
    dispatch(
      notificationDataActions.setNotificationData({
        ...globalData,
        email: {
          data: emailInfo,
          open: true,
          minimized: false,
          expanded: isExpanded,
        },
      }),
    );
    setNotificationData(emailInfo);
  };

  return (
    <Grid container>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {action.openCompose && (
            <Modal open className={classes.modal} hideBackdrop closeAfterTransition>
              <>
                {(loader.backdropLoader || loadingEmailModal) && <CustomBackdrop open />}
                <Draggable handle="#draggableArea" bounds="parent">
                  <div
                    className={
                      isExpanded
                        ? emailTemplates.showTemplate
                          ? classes.templateExpandedPaper
                          : classes.expandedPaper
                        : classes.paper
                    }
                  >
                    {activeStep !== 1 && (
                      <ModalAppBar elevation={0} id="draggableArea" position="static" style={{ height: '50px' }}>
                        <Toolbar disableGutters className={classes.toolbar}>
                          <MailOutlineIcon />
                          <ModalTitle variant="h6">
                            {getModalTitle(activeStep, emailTemplates.selectedTemplate.title, t)}
                          </ModalTitle>
                          {activeStep < 3 &&
                            (isExpanded ? (
                              <IconButton
                                className={classes.closeSelectionPreview}
                                disableRipple
                                disableFocusRipple
                                onClick={() => setIsExpanded(false)}
                              >
                                <FullscreenExit />
                              </IconButton>
                            ) : (
                              <IconButton
                                className={classes.closeSelectionPreview}
                                disableRipple
                                disableFocusRipple
                                onClick={() => setIsExpanded(true)}
                              >
                                <Fullscreen />
                              </IconButton>
                            ))}
                          {activeStep < 3 && (
                            <img
                              src={Minimize}
                              alt="Email"
                              className={classes.minimizedIcon}
                              onClick={handleMinimize}
                            />
                          )}
                          <ModalClose>
                            <IconButton className={classes.closeSelection}>
                              <CloseIcon onClick={handleEmailModalClose} />
                            </IconButton>
                          </ModalClose>
                        </Toolbar>
                      </ModalAppBar>
                    )}
                    {activeStep !== 1 &&
                      globalData?.snackbarData?.channel === ChannelType.email &&
                      globalData.snackbarData?.changeWarning && (
                        <ChangeBanner message={t('notification.finishEmailLogWarning')} />
                      )}

                    {activeStep === 2 &&
                      notificationData.channel === ChannelType.email &&
                      !notificationData.subject && <ChangeBanner message={t('notification.addSubject')} />}

                    {activeStep !== 1 &&
                      globalData?.snackbarData?.channel === ChannelType.voice &&
                      !globalData?.snackbarData?.manual &&
                      globalData?.snackbarData?.changeWarning && (
                        <ChangeBanner message={t('notification.finishEmailLogWarning')} />
                      )}
                    <div {...getRootProps()}>{getStepsComponent(activeStep)}</div>

                    <Grid
                      container
                      spacing={0}
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      className={isExpanded ? classes.expandedCloseDialog : classes.closeDialog}
                    >
                      <Grid item>
                        {templateChangeTracker.showConfirmation && (
                          <ChangeConfirmationWithAction
                            yesAction={() => {
                              handleSelectedTemplate(templateChangeTracker.changedTemplate);
                              setTemplateChangeTracker(prevState => ({
                                ...prevState,
                                isEdited: false,
                                showConfirmation: false,
                              }));
                            }}
                            noAction={() => {
                              setTemplateChangeTracker(prevState => ({
                                ...prevState,
                                isEdited: true,
                                showConfirmation: false,
                              }));
                            }}
                            message={t('notification.emails.changeTemplateWarning')}
                          />
                        )}
                      </Grid>
                    </Grid>
                    {globalData?.snackbarData?.channel === ChannelType.email && (
                      <GenericConfirmationDialog
                        contentText={t('notification.emails.discardChangesContent')}
                        titleText={t('notification.emails.discardChanges')}
                        open={!globalData.snackbarData?.changeWarning}
                        onYesAction={handleModalClose}
                        onCancelAction={() => dispatch(notificationDataActions.setSnackBarData(null))}
                      />
                    )}
                    {activeStep !== 1 && (
                      <Box
                        className={isExpanded ? classes.expandedNavigationSpace : classes.navigationSpace}
                        boxShadow={6}
                      >
                        <EmailActionButtons
                          isExpanded={isExpanded}
                          anchorSaveEl={anchorSaveEl}
                          loader={loader}
                          saveMenuOpen={saveMenuOpen}
                          handleSaveMenuClose={handleSaveMenuClose}
                          CancelTemplateModel={CancelTemplateModel}
                          handleSaveButtonClick={handleSaveButtonClick}
                          canSaveTemplate={canSaveTemplate}
                          templateSaveTitleError={templateSaveTitleError}
                          templateSaveDescError={templateSaveDescError}
                          templateSaveCategoryError={templateSaveCategoryError}
                          handleSaveTemplateMenu={handleSaveTemplateMenu}
                          handleSaveDraftMenu={handleSaveDraftMenu}
                          emailTemplates={emailTemplates}
                          titlesList={titlesList}
                          handleSaveTemplate={handleSaveTemplate}
                          notificationData={notificationData}
                          handleSubmit={handleSubmit}
                        />
                      </Box>
                    )}
                  </div>
                </Draggable>
              </>
            </Modal>
          )}
        </ThemeProvider>
      </StyledEngineProvider>
    </Grid>
  );
};
