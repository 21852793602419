import React from 'react';
import { useStylesExperience } from './Unit.styles';
import { Divider, Grid, Skeleton } from 'amn-ui-core';
export const ExperienceSkeleton = () => {
  const { classes } = useStylesExperience();
  return (
    <>
      <Grid
        className={classes.boxBorder}
        style={{
          marginBottom: '0.2rem',
        }}
      >
        <div>
          <Grid p={2} display="flex" flexDirection="row" item alignItems="center" gap={3}>
            <TileComponent rightSideFirstWidth={258} rightSideSecondWidth={0} rightSectionWidth={268} />
            <Skeleton variant="rounded" height={27} width={76} sx={{ borderRadius: '16px' }} />
            <Skeleton variant="rounded" height={27} width={76} sx={{ borderRadius: '16px' }} />
          </Grid>
          <Divider />
          <Grid p={2} display="flex" flexDirection="row" item gap={3}>
            <Grid xs={4} display={'flex'} gap={1} flexDirection={'column'}>
              <Skeleton variant="rectangular" height={19} width={46} sx={{ marginBottom: '5px' }} />
              <TileComponent rightSideFirstWidth={99} rightSideSecondWidth={87} rightSectionWidth={360} />
              <div className={classes.firstColumn}>
                <Skeleton variant="rounded" height={27} width={76} sx={{ borderRadius: '16px' }} />
                <Skeleton variant="rounded" height={27} width={76} sx={{ borderRadius: '16px' }} />
              </div>
            </Grid>
            <Grid xs={4} display={'flex'} gap={1} flexDirection={'column'}>
              <Skeleton variant="rectangular" height={19} width={74} sx={{ marginBottom: '5px' }} />
              <TileComponent rightSideFirstWidth={90} rightSideSecondWidth={105} rightSectionWidth={360} />
              <TileComponent rightSideFirstWidth={90} rightSideSecondWidth={105} rightSectionWidth={360} />
              <TileComponent rightSideFirstWidth={90} rightSideSecondWidth={105} rightSectionWidth={360} />
              <TileComponent rightSideFirstWidth={90} rightSideSecondWidth={105} rightSectionWidth={360} />
            </Grid>
            <Grid xs={4} display={'flex'} gap={1} flexDirection={'column'}>
              <Skeleton variant="rectangular" height={19} width={98} sx={{ marginBottom: '5px' }} />
              <TileComponent rightSideFirstWidth={100} rightSideSecondWidth={15} rightSectionWidth={360} />
              <TileComponent rightSideFirstWidth={100} rightSideSecondWidth={15} rightSectionWidth={360} />
              <TileComponent rightSideFirstWidth={100} rightSideSecondWidth={0} rightSectionWidth={360} />
              <TileComponent rightSideFirstWidth={100} rightSideSecondWidth={15} rightSectionWidth={360} />
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Skeleton
        variant="rectangular"
        width={37}
        height={18}
        sx={{ marginTop: '5px', marginBottom: '5px', marginLeft: '14px' }}
      />
      <Grid
        className={classes.boxBorder}
        style={{
          marginBottom: '0.2rem',
        }}
      >
        <div>
          <Grid p={2} display="flex" flexDirection="row" item alignItems="center" gap={3}>
            <TileComponent rightSideFirstWidth={258} rightSideSecondWidth={0} rightSectionWidth={268} />
          </Grid>
          <Divider />
          <Grid p={2} display="flex" flexDirection="row" item gap={3}>
            <Grid xs={6} display={'flex'} gap={1} flexDirection={'column'}>
              <Skeleton variant="rectangular" height={19} width={64} sx={{ marginBottom: '5px' }} />
              <TileComponent rightSideFirstWidth={400} rightSideSecondWidth={15} rightSectionWidth={572} />
              <Skeleton variant="rectangular" height={19} width={32} />
              <TileComponent rightSideFirstWidth={400} rightSideSecondWidth={15} rightSectionWidth={572} />
              <Skeleton variant="rectangular" height={19} width={32} />
              <TileComponent rightSideFirstWidth={400} rightSideSecondWidth={0} rightSectionWidth={572} />
            </Grid>
            <Grid xs={6} display={'flex'} gap={1} flexDirection={'column'}>
              <Skeleton variant="rectangular" height={19} width={106} sx={{ marginBottom: '5px' }} />
              <TileComponent rightSideFirstWidth={400} rightSideSecondWidth={15} rightSectionWidth={572} />
              <Skeleton variant="rectangular" height={19} width={30} sx={{ marginBottom: '5px' }} />
              <TileComponent rightSideFirstWidth={400} rightSideSecondWidth={0} rightSectionWidth={572} />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
};
const TileComponent = ({ rightSideFirstWidth, rightSideSecondWidth, rightSectionWidth }) => {
  const { classes } = useStylesExperience();
  return (
    <div className={classes.tileComponent}>
      <div className={classes.leftSection}>
        <Skeleton variant="rectangular" width={15} height={15} />
      </div>
      <div className={classes.RightSection} style={{ width: `${rightSectionWidth}px` }}>
        <Skeleton variant="rectangular" width={rightSideFirstWidth} height={15} />
        <Skeleton variant="rectangular" width={rightSideSecondWidth} height={15} />
      </div>
    </div>
  );
};
