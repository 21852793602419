import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomFilter } from 'app/ComponentLibrary/Filter/CustomFilter';
import { FilterCompTypes } from 'app/ComponentLibrary/Filter/utils';
import { AuditPlacementFilterName, placementFilterLabels } from './AuditAddHelpers';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import { getAmnEndOfWeek, getAmnStartOfWeek } from 'utils/dates/dateExtraction';
import { calculateTreeCount } from '@AMIEWEB/Common/TreeView/TreeViewHelpers';

export const AuditPlacementSearchFilters = props => {
  const { filterSpecs, onChangeFilter, onApplyFilter } = props;
  const { t } = useTranslation();
  const date = new Date();

  const getAuditPlacementFilterSpecs = filterSpecs => [
    {
      filterName: placementFilterLabels[AuditPlacementFilterName.placementID],
      name: AuditPlacementFilterName.placementID,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      options: filterSpecs?.placementId,
      placeholder: t('placement.profile.tabs.passportCredSearchFilters.placeholder'),
      version2: true,
      returnsObjectAsValue: true,
      size: 'small',
      selectAllValue: 'All',
    },
    {
      filterName: placementFilterLabels[AuditPlacementFilterName.startDate],
      name: AuditPlacementFilterName.startDate,
      type: FilterCompTypes.DATERANGEV2,
      secondaryLabel: placementFilterLabels[AuditPlacementFilterName.startDate],
      variant: 'outlined',
      useMaxWidth: true,
      maxWidth: 130,
      single: true,
      placeholder: placementFilterLabels[AuditPlacementFilterName.startDate],
      version2: true,
      returnsObjectAsValue: true,
      size: 'small',
      definedRanges: [
        {
          label: 'Today',
          startDate: date,
          endDate: date,
          useLabelAsValue: false,
        },
        {
          label: 'This Week',
          startDate: startOfDay(getAmnStartOfWeek()),
          endDate: endOfDay(getAmnEndOfWeek()),
          useLabelAsValue: false,
        },
        {
          label: 'Next 13 weeks',
          startDate: startOfDay(date),
          endDate: endOfDay(addDays(date, 91)),
          useLabelAsValue: false,
        },
      ],
      minDate: new Date('01-01-1990'),
    },
    {
      filterName: placementFilterLabels[AuditPlacementFilterName.endDate],
      name: AuditPlacementFilterName.endDate,
      type: FilterCompTypes.DATERANGEV2,
      secondaryLabel: placementFilterLabels[AuditPlacementFilterName.endDate],
      variant: 'outlined',
      useMaxWidth: true,
      maxWidth: 130,
      single: true,
      placeholder: placementFilterLabels[AuditPlacementFilterName.endDate],
      version2: true,
      returnsObjectAsValue: true,
      size: 'small',
      selectAllValue: 'All',
      definedRanges: [
        {
          label: 'Today',
          startDate: date,
          endDate: date,
          useLabelAsValue: false,
        },
        {
          label: 'This Week',
          startDate: startOfDay(getAmnStartOfWeek()),
          endDate: endOfDay(getAmnEndOfWeek()),
          useLabelAsValue: false,
        },
        {
          label: 'Next 13 weeks',
          startDate: startOfDay(date),
          endDate: endOfDay(addDays(date, 91)),
          useLabelAsValue: false,
        },
      ],
      minDate: new Date('01-01-1990'),
    },
    {
      filterName: placementFilterLabels[AuditPlacementFilterName.status],
      name: AuditPlacementFilterName.status,
      type: FilterCompTypes.TREE,
      isMultiSelect: true,
      options: filterSpecs?.status,
      placeholder: t('placement.profile.tabs.passportCredSearchFilters.placeholder'),
      version2: true,
      returnsObjectAsValue: true,
      size: 'large',
      selectAllValue: 'All',
      customSelectedCount: value => calculateTreeCount(value),
      handleApplyTreeData: value => onApplyFilter(AuditPlacementFilterName.status, value),
    },
    {
      filterName: placementFilterLabels[AuditPlacementFilterName.facility],
      name: AuditPlacementFilterName.facility,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      options: filterSpecs?.facilityName,
      placeholder: t('placement.profile.tabs.passportCredSearchFilters.placeholder'),
      version2: true,
      returnsObjectAsValue: true,
      size: 'large',
      selectAllValue: 'All',
    },
    {
      filterName: placementFilterLabels[AuditPlacementFilterName.unit],
      name: AuditPlacementFilterName.unit,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      options: filterSpecs?.unit,
      placeholder: t('placement.profile.tabs.passportCredSearchFilters.placeholder'),
      version2: true,
      returnsObjectAsValue: true,
      size: 'large',
      selectAllValue: 'All',
    },
    {
      filterName: placementFilterLabels[AuditPlacementFilterName.clinicalManager],
      name: AuditPlacementFilterName.clinicalManager,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      options: filterSpecs?.clinicalManager?.sort((a, b) => (b.name > a.name ? -1 : 1)),
      placeholder: t('placement.profile.tabs.passportCredSearchFilters.placeholder'),
      version2: true,
      returnsObjectAsValue: true,
      size: 'large',
    },
  ];

  return (
    <React.Fragment>
      <CustomFilter
        fields={getAuditPlacementFilterSpecs(filterSpecs)}
        justifyContent={'flex-start'}
        version2
        enableReset
        onResetForm={() => props.resetFilter()}
        onApply={onApplyFilter}
        onChangeValues={onChangeFilter}
        disableFormSubmit
      />
    </React.Fragment>
  );
};
