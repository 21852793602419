import { AppBar, Typography } from 'amn-ui-core';
import styledComponent from 'styled-components';

export const ModalTitle = styledComponent(Typography)`
  flexgrow: 1;
  padding-bottom: 15px;
  color: #ffffff;
  padding-left: 15px;
`;

export const ModalAppBar = styledComponent(AppBar)`
  width: 100%;
  height: 51px;
  background: #009ad9;
  border-radius: 4px 4px 0px 0px;
  opacity: 1;
`;
