import React from 'react';
import { Box } from 'amn-ui-core';
import Reject from 'app/assets/images/IconImages/Reject.svg';
import Rejected from 'app/assets/images/IconImages/Rejected.svg';
import RejectGreyOutline from 'app/assets/images/IconImages/RejectGreyOutline.svg';
interface PropType {
  iconStyle?: any;
  toggle?: boolean;
  showGreyOutline?: boolean;
}
export const RejectButton = ({ iconStyle, toggle, showGreyOutline }: PropType) => {
  return (
    <Box>
      <img
        style={iconStyle ? iconStyle : { height: 36, width: 39 }}
        alt="reject-icon"
        src={toggle ? Rejected : (showGreyOutline? RejectGreyOutline : Reject)}
      />
    </Box>
  );
};
