import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { FormInputProps } from '../../../PersonalInfoTab/Form/Controls/FormInputProps';
import { IconButton, InputAdornment } from 'amn-ui-core';
import moment from 'moment';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { CustomTooltip } from '@AMIEWEB/Common';
import { AddDateTimeIcon } from './AddDateTimeButton';
import { makeStyles } from 'tss-react/mui';

const useStyles: any = makeStyles()({
  addDateTimeBtn: {
    '& svg': {
      fill: '#888 !important',
      '&:hover': {
        fill: '#009ad9 !important',
      },
    },
  },
});

export const CustomFormInputText = ({
  name,
  label,
  require,
  message,
  handleChange,
  inputValue,
  readOnly,
  customClass,
  type,
  spellCheck,
  maxRows,
  placeholder,
  labelClass,
  inputRef,
  multiline,
  customInputClass,
}: FormInputProps) => {
  const { classes } = useStyles();
  const { control, register, errors } = useFormContext();
  const [labelShrink, setLabelShrink] = useState(false);
  inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setLabelShrink(inputValue ? true : false);
  }, [inputValue]);

  const onClick = event => {
    if (inputRef && inputRef.current) {
      const value = inputRef.current.value;
      const [start, end] = [inputRef.current.selectionStart, inputRef.current.selectionEnd];
      const timeStamp = moment().format('MM/DD/YYYY hh:mm:ss A');
      inputRef.current.value = null;

      const newValue = value.substring(0, start) + timeStamp + value.substring(end, value.length);
      inputRef.current.value = newValue;
      handleChange?.(newValue);
      event.preventDefault();
    }
  };

  const TextBox = useMemo(() => {
    return (
      <>
        <Controller
          name={name}
          control={control}
          render={({ ref, value, onChange, ...rest }) => (
            <TextField
              autoComplete="off"
              multiline={multiline}
              maxRows={maxRows}
              id={`txt${name}`}
              variant="filled"
              type={type || 'text'}
              label={label}
              value={inputValue}
              defaultValue={inputValue}
              onChange={e => {
                handleChange?.(e);
                if (e.target.value === '') setLabelShrink(false);
                else setLabelShrink(true);
              }}
              InputLabelProps={{ shrink: labelShrink || placeholder?.length > 0, className: labelClass }}
              inputRef={inputRef}
              fullWidth
              placeholder={placeholder}
              {...rest}
              InputProps={{
                className: customInputClass ?? '',
                readOnly: readOnly,
                endAdornment: (
                  <InputAdornment position="end" onClick={onClick}>
                    <IconButton
                      color="primary"
                      size="small"
                      disabled={readOnly}
                      sx={{ position: 'absolute', top: 0, right: 0 }}
                      type="button"
                      className={classes.addDateTimeBtn}
                    >
                      <CustomTooltip tooltipContent="Add Date & Time">
                        <AddDateTimeIcon viewBox="0 0 30 30" />
                      </CustomTooltip>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!!errors[name]}
              disabled={readOnly}
              inputProps={{
                'aria-label': name,
                spellCheck: spellCheck,
              }}
              helperText={
                <LayoutGrid container direction="row" justifyContent="space-between" spacing={2} xs={12}>
                  {errors[name] ? (
                    errors[name]?.type === 'required' ? (
                      <LayoutGridItem item>Required</LayoutGridItem>
                    ) : (
                      <LayoutGridItem item mt="0">
                        {message}
                      </LayoutGridItem>
                    )
                  ) : (
                    <LayoutGridItem item></LayoutGridItem>
                  )}
                </LayoutGrid>
              }
            />
          )}
        />
      </>
    );
  }, [
    control,
    customClass,
    errors,
    handleChange,
    inputValue,
    label,
    labelShrink,
    message,
    name,
    readOnly,
    register,
    require,
    type,
  ]);

  return <>{TextBox}</>;
};
