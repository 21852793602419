import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectJoMatchLocations, selectJobMatches } from './JobMatch.selectors';
import { useTranslation } from 'react-i18next';
import { getMapNavData } from './gridUtils';
import { candidateDetailsSelection } from '../Profile/CandidateDetails.selector';
import { AmnMapView } from '../../../Common/Map';
import { GenerateMapOrderData, SortJobMatches } from './mapHelper';

export const OrderMapView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const locationCoordinates = useSelector(selectJoMatchLocations);
  const jobMatches = useSelector(selectJobMatches);
  const candidateDetails = useSelector(candidateDetailsSelection);

  const orderNavData = getMapNavData('Order Details', candidateDetails?.travelerId, candidateDetails?.brandId);
  const facilityNavData = getMapNavData('Facility Details', candidateDetails?.travelerId, candidateDetails?.brandId);

  const popoverData = GenerateMapOrderData(jobMatches, dispatch, t, orderNavData, facilityNavData);

  const SortClusterEntities = selectedOrderIds => {
    const entities = SortJobMatches(jobMatches, selectedOrderIds);
    return GenerateMapOrderData(entities, dispatch, t, orderNavData, facilityNavData, true);
  };

  return (
    <AmnMapView
      locationCoordinates={locationCoordinates}
      entities={popoverData}
      SortClusterEntities={SortClusterEntities}
    />
  );
};
