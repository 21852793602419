import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { Box, CircularProgress, Grid, Skeleton } from 'amn-ui-core';
import React, { useCallback, useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { IRateDocument } from 'store/redux-store/create-order-ocbr/types';
import { DocumentCard } from '@AMIEWEB/Common/DocumentDropzone/DocumentCard';
import { useTranslation } from 'react-i18next';
import { Cancel } from '@AMIEWEB/Common';
import { usePromiseTracker } from 'react-promise-tracker';
import {
  IAttachments,
  ISaveOCBRAttachments,
  ISaveRateElementCommand,
  facilityActions,
} from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { selectUploadedDocuments } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';

const useStyles = makeStyles<{ modalHasError: boolean }>()((theme, { modalHasError }) => ({
  paper: {
    width: '600px',
    maxWidth: '600px',
    '& .MuiDialogActions-root': {
      padding: '12px 24px',
      boxShadow: '0px -1px 3px #00000029',
    },
  },
  infoSection: {
    padding: modalHasError ? '0 0 12px 0' : '24px 0 12px 0',
  },
  docCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    maxHeight: 400,
    overflowY: 'auto',
  },
  skeleton: {
    height: 20,
    borderRadius: 4,
  },
  validationLoader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: '50%',
  },
  minHeight: {
    minHeight: '100px',
  },
}));

interface IErrorDoc {
  errorSeverity: 'error' | 'warning';
  errorMessage: string;
}

const isolateAccepetedDocs = (docs = []) =>
  docs.map((x, index) =>
    index > 4
      ? { ...x, error: true, errorSeverity: 'error', errorMessage: 'Limit of 5 files reached' }
      : { ...x, error: false, errorSeverity: undefined, errorMessage: undefined },
  );

const DOC_PROCESS_TRACKER = 'track-rate-doc-upload';
export const ViewUploadedDocuments = ({
  appUser,
  facilityId,
  addDocsModalOpen,
  setAddDocsModalOpen,
  gridSpecs: { gridSelections, rows },
  displayDescription = true,
  existingAttachments,
  isEditing,
  row,
}: {
  appUser: any;
  facilityId: number;
  addDocsModalOpen: boolean;
  setAddDocsModalOpen: (params: boolean) => void;
  gridSpecs?: {
    gridSelections?: any;
    rows?: any;
  };
  existingAttachments?: IAttachments[];
  displayDescription?: boolean;
  isEditing?: boolean;
  row?: any;
}) => {
  const [errorProps, setErrorProps] = useState({
    error: false,
    errorMessage: undefined,
    errorSeverity: undefined,
  });
  const { classes } = useStyles({ modalHasError: errorProps?.error });

  const { t } = useTranslation();
  const [expectedDocs, setExpectedDocs] = useState([]);
  const [failedDocs, setFailedDocs] = useState<(IRateDocument & IErrorDoc)[]>([]);
  const [affectedRows, setAffectedRows] = useState<any>([]);
  // Code: Refer Status List from './OffContractOrderPage' (Pending = 1, Unsaved = 7)
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const { promiseInProgress: processingDocs } = usePromiseTracker({ area: DOC_PROCESS_TRACKER, delay: 0 });
  const { promiseInProgress: isCallingAPI } = usePromiseTracker({ area: 'save-ocbr-attachments', delay: 0 });
  const { promiseInProgress: isCallingDocAPI } = usePromiseTracker({
    area: `get-approval-document-${row?.rateElementId}`,
    delay: 0,
  });
  const { promiseInProgress: updatingRateElement } = usePromiseTracker({ area: 'update-rate-elements', delay: 0 });
  const dispatch = useDispatch();
  const documents = useSelector(selectUploadedDocuments);
  const resetDialogError = () =>
    setErrorProps({
      error: false,
      errorMessage: undefined,
      errorSeverity: undefined,
    });
  const onAddFormClose = useCallback(() => {
    setAddDocsModalOpen(false);
    setOpenCancelDialog(false);
    !isEditing && setExpectedDocs([]);
    setFailedDocs([]);
    resetDialogError();
    setIsChanged(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isCallingAPI && documents?.length > 0) {
      dispatch(facilityActions.setUploadedDocuments([]));
      !isEditing && onAddFormClose();
      !isEditing && setAffectedRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  useEffect(() => {
    dispatch(facilityActions.setUploadDocumentModalOpen(addDocsModalOpen));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDocsModalOpen]);

  useEffect(() => {
    setExpectedDocs(isolateAccepetedDocs(existingAttachments || []));
  }, [existingAttachments, addDocsModalOpen]);

  useEffect(() => {
    if (!isCallingAPI && !updatingRateElement && !isCallingDocAPI && isChanged) {
      onAddFormClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCallingAPI, updatingRateElement, isCallingDocAPI]);

  return (
    <>
      <GenericDialog
        open={addDocsModalOpen}
        disableEscapeKeyDown
        fullWidth
        variant="blue"
        classes={{ paper: classes.paper }}
        onClose={onAddFormClose}
        dialogTitleProps={{
          text: t('order.approvalDocuments.title'),
          closeButton: true,
          size: 'small',
        }}
        dialogContentProps={{ ...errorProps, classes: { root: classes.minHeight } }}
      >
        <div style={{ padding: '8px 0px 10px 0' }}>
          {isCallingDocAPI && <CircularProgress className={classes.validationLoader} />}
          <Grid item container direction="column" xs={12}>
            <Grid item>
              <Box fontSize="14px" fontWeight="500">
                {existingAttachments?.length &&
                  `${t('order.approvalDocuments.viewDocuments')} (${existingAttachments?.length})`}
              </Box>
            </Grid>
            <Grid item classes={{ root: classes.docCardContainer }}>
              {processingDocs && (
                <>
                  <Skeleton variant="rectangular" classes={{ root: classes.skeleton }} />
                  <Skeleton variant="rectangular" classes={{ root: classes.skeleton }} />
                </>
              )}
              {expectedDocs?.map(({ hash, name, type, size, saasAttachmentUrl, ...rest }) => (
                <DocumentCard
                  hash={hash}
                  fileName={name}
                  fileType={type}
                  fileSize={size}
                  {...rest}
                  handleDelete={() => {
                    return;
                  }}
                  isViewOnly={true}
                  saasAttachmentUrl={saasAttachmentUrl}
                />
              ))}
              {failedDocs.map(({ hash, name, type, size, ...rest }) => (
                <DocumentCard
                  hash={hash}
                  fileName={name}
                  fileType={type}
                  fileSize={size}
                  {...rest}
                  error
                  handleDelete={() => {
                    return;
                  }}
                  isViewOnly={true}
                />
              ))}
            </Grid>
          </Grid>
        </div>
      </GenericDialog>
      <Cancel
        openDialog={openCancelDialog}
        handleConfirmAction={onAddFormClose}
        handleCancelAction={() => {
          setOpenCancelDialog(false);
        }}
      />
    </>
  );
};
