import {
  getDisciplineSpecialtyData,
  getEthnicityLookups,
  getHearsayCertifications,
  getInterviewReason,
  getLicenseTypes,
  getSearchFilterOptions,
  getTravelerCompanyList,
} from 'app/services/SharedServices/SharedServices';
import { all, put, select, takeLeading } from 'redux-saga/effects';
import { ILookupOptions, ILookupState } from './initialState';
import { lookupActions } from './lookup.slice';
import statesData from 'app/assets/jsons/State.json';
import countriesData from 'app/assets/jsons/Country.json';
import {
  getGraduationYear,
  months,
  parseDropDown,
} from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/ProfileTab/Education/EducationHelper';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { ITypeAheadOption } from 'app/components/Tasks/CustomComponents/ControlledTypeAheadWithAvatar';
import { formatTypeAheadOptions } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/PreferencesTab/FormatData';
import { lookupLoaderSelector, lookupOptionLoaderSelector } from './lookup.selectors';
import {
  formatTypeAheadDisciplineOptions,
  formatTypeAheadSpecialtyOptions,
  formatTypeAheadSubSpecialtyOptions,
} from 'app/helpers/typeAheadHelper';
import i18next from 'i18next';

export function* getInitialLookupLoad() {
  const isLoaded = yield select(lookupLoaderSelector);
  try {
    if (!isLoaded) {
      const response = yield all([
        getSearchFilterOptions('majors'),
        getSearchFilterOptions('degrees'),
        getTravelerCompanyList(),
        getDisciplineSpecialtyData(),
        getEthnicityLookups(),
        getInterviewReason(),
        getSearchFilterOptions('divisions'),
        getHearsayCertifications(),
        getLicenseTypes(),
        getSearchFilterOptions('audittype'),
        getSearchFilterOptions('auditaction'),
        getSearchFilterOptions('auditstatus'),
        getSearchFilterOptions('auditresponsible'),
        getSearchFilterOptions('skillsetRejectionReason'),
        getSearchFilterOptions('maritalStatus'),
        getSearchFilterOptions('furnitureExtras'),
        getSearchFilterOptions('keyFactsAboutTraveler'),
        getSearchFilterOptions('typeBed'),
        getSearchFilterOptions('housingType'),
      ]);
      const result = response[3];
      const disciplineOptions = formatTypeAheadDisciplineOptions(result.data.disciplines);
      const specialtyOptions = formatTypeAheadSpecialtyOptions(result.data.disciplineSpecialties);
      const subSpecialtyOptions = formatTypeAheadSubSpecialtyOptions(result.data.subSpecialties);

      const raceEthnicityResponse: [{ name: string; value: string }] = response[4];
      const raceEthnicityDropDown = raceEthnicityResponse.map(ethnicityItem => ({
        ID: ethnicityItem.value,
        Description: ethnicityItem.name,
      }));
      const raceEthnicityOptions: ITypeAheadOption[] = formatTypeAheadOptions(raceEthnicityDropDown);

      const payload: ILookupState = {
        disciplines: disciplineOptions,
        specialties: specialtyOptions,
        subSpecialties: subSpecialtyOptions,
        discSpecMapping: result.data.disciplineSpecialties,
        divisionDisciplines: result.data.divisionDisciplines,
        countries: countriesData.countries,
        states: statesData.states,
        majors: parseDropDown(response[0]),
        degrees: parseDropDown(response[1]),
        brands: response[2]?.data,
        graduationMonth: months,
        graduationYear: getGraduationYear(),
        raceEthnicityOptions: raceEthnicityOptions ?? [],
        interviewReasons: response[5]?.data,
        isLoaded: true,
        divisions: response[6],
        hearsayCertifications: response[7]?.data,
        licenseTypes: response[8]?.data,
        auditType: response[9],
        auditAction: response[10],
        auditStatus: response[11],
        auditResponsible: response[12],
        skillsetRejectionReason: response[13],
        maritalStatus: response[14],
        furnitureExtras: response[15],
        keyFacts: response[16],
        typeBed: response[17],
        housingType: response[18],
      };
      yield put(lookupActions.setDiscipline(payload));
    }
  } catch (error) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

export function* getLookupOptionsLoad() {
  const isLoaded = yield select(lookupOptionLoaderSelector);
  try {
    if (!isLoaded) {
      const response = yield all([
        getSearchFilterOptions('divisions', true),
        getSearchFilterOptions('disciplines', true),
        getSearchFilterOptions('discipline_specialties', true),
        getSearchFilterOptions('specialty_subspecialties', true),
        getSearchFilterOptions('facilitystatuses', true),
        getSearchFilterOptions('ordertypes', true),
        getSearchFilterOptions('orderpriorities', true),
        getSearchFilterOptions('compact_states', true),
        getSearchFilterOptions('shiftlist', true),
        getSearchFilterOptions('accountmanagers', true),
        getSearchFilterOptions('states', true),
      ]);
      const payload: ILookupOptions = {
        divisionOptions: response[0],
        disciplineOptions: response[1],
        specialtiesOptions: response[2],
        subSpecialtiesOptions: response[3],
        facilityStatusOptions: response[4],
        orderTypeOptions: response[5],
        orderPriorityOptions: response[6],
        compactStateOptions: response[7],
        shiftListOptions: response[8],
        accountManagerOptions: response[9],
        stateOptions: response[10],
        isOptionsLoaded: true,
      };
      yield put(lookupActions.setLookupOptions(payload));
    }
  } catch (error) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

function* lookupWatcher() {
  yield takeLeading(lookupActions.getAllLookup, getInitialLookupLoad);
  yield takeLeading(lookupActions.getAllLookupOptions, getLookupOptionsLoad);
}

export function* lookupSaga() {
  yield all([lookupWatcher()]);
}
