import { createAction } from '@reduxjs/toolkit';
import { orderCreationSlice } from './OrderCreation.redux';
import { EMRType, QualificationType, SkillSetType, TravelExperienceType } from 'app/models/Unit/CreateUnit';
import { NewUIOrder } from 'app/models/Orders/OrderDetails';

export const orderCreateionInitializationAction = createAction<{
  id: number;
}>('ORDER_CREATION_INITIALIZATION');

export const orderDetailsEditInitializationAction = createAction<{
  id: number;
}>('ORDER_DETAILS_EDIT_INITIALIZATION');

export const unitCreateionInitializationAction = createAction<{
  id: number;
}>('UNIT_CREATION_INITIALIZATION');

export const unitEditInitializationAction = createAction<{
  id: number;
  unitId: number;
  editMode: string;
  remberMode: boolean;
  isNewUnitPage: boolean;
}>('UNIT_EDIT_INITIALIZATION');

export const getOrderCreationFacilityDetailsAction = createAction<{
  id: number;
}>('GET_ORDER_CREATION_FACILITY_DETAILS');

export const getOrderEditSummaryDetailsAction = createAction<{
  id: number;
}>('GET_ORDER_EDIT_SUMMARY_DETAILS');

export const getOrderCreationFacilityUnitDetailsAction = createAction<{
  id: number;
}>('GET_ORDER_CREATION_FACILITY_UNIT_DETAILS');

export const getOrderStageUiComponetsAction = createAction<{
  isInitialLoad: boolean | false;
}>('GET_ORDER_CREATION_UI_COMPONENTS');

export const getOrderStageEditRequremnetUiComponetsAction = createAction<{
  isInitialLoad: boolean | false;
}>('GET_ORDER_CREATION_UI_EDIT_REQUREMENT_COMPONENTS');

export const getOrderStageEditDetailsUiComponetsAction = createAction<{
  isInitialLoad: boolean | false;
}>('GET_ORDER_CREATION_UI_EDIT_DETAILS_COMPONENTS');

export const getUnitStageEditDetailsUiComponetsAction = createAction<{
  isInitialLoad: boolean | false;
}>('GET_UNIT_CREATION_UI_EDIT_DETAILS_COMPONENTS');

export const getUnitStageUpdateDetailsUiComponetsAction = createAction<{
  isInitialLoad: boolean | false;
}>('GET_UPDATE_UNIT_DETAILS_UI_EDIT_DETAILS_COMPONENTS');

export const getOrderStageInformationUiComponetsAction = createAction<{
  isInitialLoad: boolean | false;
}>('GET_ORDER_CREATION_UI_INFORMATION_COMPONENTS');

export const getOrderStageBillrateUiComponetsAction = createAction<{
  isInitialLoad: boolean | false;
}>('GET_ORDER_CREATION_UI_BILLRATE_COMPONENTS');

export const getOrderStagePreviewUiComponetsAction = createAction<{
  isInitialLoad: boolean | false;
}>('GET_ORDER_CREATION_UI_PREVIEW_COMPONENTS');

export const getOrderPrevStageComponetsAction = createAction<{
  data: string;
}>('GET_ORDER_PREV_STAGE_COMPONENTS');

export const orderCreationSelectUnitSetSelectedUnitAction = createAction<{
  id: number;
}>('ORDER_CREATION_SET_SELECTED_UNIT');

export const orderCreationSelectUnitSetHoverdUnitAction = createAction<{
  id: number;
}>('ORDER_CREATION_SET_HOVERD_UNIT');

export const orderCreationDetermineOrderRequirementEditModeAction = createAction(
  'ORDER_CREATION_DETERMINE_ORDER_REQUIREMENT_EDIT_MODE',
);

export const orderCreationDetermineOrderRequirementEditDalogCloseAction = createAction(
  'ORDER_CREATION_DETERMINE_ORDER_REQUIREMENT_EDIT_DALOG_CLOSE',
);

export const orderCreationProceedToOrderRequirementEditAction = createAction<{
  mode: string;
}>('ORDER_CREATION_PROCEED_TO_ORDER_REQUIREMENT_EDIT');

export const orderCreationProcessOrderRequirementEditAction = createAction<{
  data: any;
}>('ORDER_CREATION_PROCESS_ORDER_REQUIREMENT_EDIT');

export const orderCreationProcessOrderInformationEditAction = createAction<{
  data: any;
}>('ORDER_CREATION_PROCESS_ORDER_INFORMATION_EDIT');

export const orderCreationProcessOrderBillRateEditAction = createAction<{
  data: any;
}>('ORDER_CREATION_PROCESS_ORDER_BILLRATE_EDIT');

export const orderCreationProceedToOrderInfromationAction = createAction<{
  data: any;
}>('ORDER_CREATION_PROCEED_TO_ORDER_INFORMATION');

export const orderCreationSubmitOrderAction = createAction<{
  data: any;
}>('ORDER_CREATION_SUBMIT_ORDER');

export const orderCreationSubmitOrderDetailsAction = createAction<{
  data: any;
}>('ORDER_CREATION_SUBMIT_ORDER_DETAILS');

export const unitCreationSubmitUnitDetailsAction = createAction<{
  data: any;
  isNewUnitPage?: boolean;
}>('UNIT_CREATION_SUBMIT_UNIT_DETAILS');

export const unitUpdateSubmitUnitDetailsAction = createAction<{
  data: any;
  isNewUnitPage?: boolean;
}>('UNIT_UPDATE_SUBMIT_UNIT_DETAILS');

export const requestOrderCreationRequirementTypesDetailsAction = createAction(
  'ORDER_CREATION_DETERMINE_ORDER_REQUIREMENT_TYPES_DETAILS',
);

export const requestOrderCreationRequirementLookupDetailsAction = createAction<{
  category: string;
}>('ORDER_CREATION_PROCEED_TO_ORDER_REQUIREMENT_LOOKUP_DETAILS');

export const requestOrderCreationMiscellaneousApiDetailsAction = createAction(
  'ORDER_CREATION_MISCELLANEOUS_API_DETAILS',
);

export const requestOrderCreationSearchCertificationsDetailsAction = createAction<{
  searchQuery: string | null | undefined;
}>('ORDER_CREATION_SEARCH_CERTIFICATIONS_DETAILS');

export const requestOrderCreationSearchLicenseDetailsAction = createAction<{
  searchQuery: string | null | undefined;
}>('ORDER_CREATION_SEARCH_LICENSE_DETAILS');

export const requestOrderCreationUpdateQualificationDetailsAction = createAction<{
  qualificationData: QualificationType;
}>('ORDER_CREATION_UPDATE_QUALIFICATION_DETAILS');

export const requestOrderCreationUpdateBestBetsIdDetailsAction = createAction<{
  data: any;
}>('ORDER_CREATION_UPDATE_BESTBETSID_DETAILS');

export const requestOrderCreationUpdateVirtualRatingDetailsAction = createAction<{
  data: any;
}>('ORDER_CREATION_UPDATE_VIRTUALRATING_DETAILS');

export const requestOrderCreationUpdatePropertyDetailsAction = createAction<{
  propertyName: string;
  data: any;
}>('ORDER_CREATION_UPDATE_PROPERTY_DETAILS');

export const requestOrderCreationUpdateEmrDetailsAction = createAction<{
  data: EMRType | null | undefined;
}>('ORDER_CREATION_UPDATE_EMR_DETAILS');

export const requestOrderCreationUpdateTravelExperienceDetailsAction = createAction<{
  data: TravelExperienceType | null | undefined;
}>('ORDER_CREATION_UPDATE_TRAVEL_EXPERIENCE_DETAILS');

export const requestOrderCreationUpdateNewGradDetailsAction = createAction<{
  data: boolean;
}>('ORDER_CREATION_UPDATE_NEW_GRAD_DETAILS');

export const requestOrderCreationUpdateSkillSetsDetailsAction = createAction<{
  data: SkillSetType[];
}>('ORDER_CREATION_UPDATE_SKILL_SETS_DETAILS');

export const requestOrderCreationUpdateOrderDetailsAction = createAction<{
  data: NewUIOrder;
}>('ORDER_CREATION_UPDATE_ORDER_DETAILS');

export const requestOrderCreationFormatedSkillCheckListDetailsAction = createAction<{
  searchQuery: string;
}>('ORDER_CREATION_UPDATE_FORMATED_SKILLCHECKLIST_DETAILS');

export const requestOrderCreationSearchSkillCheckListDetailsAction = createAction<{
  searchQuery: string | null | undefined;
}>('ORDER_CREATION_SEARCH_SKILLCHECKLIST_DETAILS');

export const getOrderPriorityTypes = createAction('ORDER_PRIORITY_TYPES');

export const getBestBetsOptions = createAction('BEST_BETS_OPTIONS');

export const getTravelExperienceOptions = createAction('TRAVEL_EXPERIENCE_OPTIONS');

export const orderCreationActions = {
  ...orderCreationSlice.actions,
  getOrderCreationFacilityDetailsAction,
  getOrderEditSummaryDetailsAction,
  getOrderCreationFacilityUnitDetailsAction,
  orderCreateionInitializationAction,
  orderDetailsEditInitializationAction,
  unitCreateionInitializationAction,
  unitEditInitializationAction,
  getOrderStageUiComponetsAction,
  getOrderStageEditRequremnetUiComponetsAction,
  getOrderStageEditDetailsUiComponetsAction,
  getUnitStageEditDetailsUiComponetsAction,
  getUnitStageUpdateDetailsUiComponetsAction,
  getOrderStageInformationUiComponetsAction,
  getOrderStageBillrateUiComponetsAction,
  getOrderStagePreviewUiComponetsAction,
  getOrderPrevStageComponetsAction,
  orderCreationSelectUnitSetSelectedUnitAction,
  orderCreationSelectUnitSetHoverdUnitAction,
  orderCreationDetermineOrderRequirementEditModeAction,
  orderCreationDetermineOrderRequirementEditDalogCloseAction,
  orderCreationProceedToOrderRequirementEditAction,
  orderCreationProcessOrderRequirementEditAction,
  orderCreationProcessOrderInformationEditAction,
  orderCreationProcessOrderBillRateEditAction,
  orderCreationProceedToOrderInfromationAction,
  orderCreationSubmitOrderAction,
  orderCreationSubmitOrderDetailsAction,
  unitCreationSubmitUnitDetailsAction,
  unitUpdateSubmitUnitDetailsAction,
  requestOrderCreationRequirementTypesDetailsAction,
  requestOrderCreationRequirementLookupDetailsAction,
  requestOrderCreationMiscellaneousApiDetailsAction,
  requestOrderCreationSearchCertificationsDetailsAction,
  requestOrderCreationSearchLicenseDetailsAction,
  requestOrderCreationUpdateQualificationDetailsAction,
  requestOrderCreationUpdateBestBetsIdDetailsAction,
  requestOrderCreationUpdateVirtualRatingDetailsAction,
  requestOrderCreationUpdatePropertyDetailsAction,
  requestOrderCreationUpdateEmrDetailsAction,
  requestOrderCreationUpdateTravelExperienceDetailsAction,
  requestOrderCreationFormatedSkillCheckListDetailsAction,
  requestOrderCreationSearchSkillCheckListDetailsAction,
  requestOrderCreationUpdateNewGradDetailsAction,
  requestOrderCreationUpdateSkillSetsDetailsAction,
  requestOrderCreationUpdateOrderDetailsAction,
  getTravelExperienceOptions,
  getOrderPriorityTypes,
  getBestBetsOptions,
};
