import { Checkbox, Grid, Paper } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import React from 'react';
import DocViewerFit from 'app/components/Common/DocViewer/DocViewer';
import {
  downloadFile,
  getFileNameFormat,
  isNullWithDefaultVal,
  isPrevSupported,
  isFileTypeHtml,
} from 'app/components/Common/DocViewer/Helpers/DocHelpers';
import { IAttachment } from 'app/models/Credentialing/Credentials';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { isCredentialing } from 'oidc/userRoles';
import { selectViewSkillsChecklistPassport } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles()(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: '12px',
    padding: '10px',
  },
  nonSelectable: {
    alignSelf: 'center',
    width: '100%',
  },
  documentView: {
    marginTop: '5px',
    marginBottom: '5px',
    width: '100%',
    overflow: 'hidden',
    height: '120px',
  },
  comment: {
    margin: '6px 0px',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    lineHeight: 1.5,
    maxHeight: '40px',
  },

  actions: {
    justifyContent: 'space-between',
    alignSelf: 'flex-end',
  },
  headerSpace: {
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
    marginBottom: '6px',
  },
  checkBox: {
    width: '40px',
  },
  icon: {
    color: '#B9BBBC',
    cursor: 'pointer',
    '&:hover': {
      color: '#006FB9',
    },
  },
  disableIcon: {
    color: theme.palette.framework.system.lightGray,
    cursor: 'not-allowed',
    '&:hover': {
      color: theme.palette.framework.system.lightGray,
    },
  },
  doc: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  unsupportDoc: {
    color: '#797676',
    fontSize: 120,
  },
}));

export const AttachmentPreview = props => {
  const { classes } = useStyles();
  const user = useSelector(selectUser);
  const { docData, number, onAttachmentSelected, selectAll, credential, handleDelete } = props;
  const { t } = useTranslation();
  const viewSkillsChecklistPassport = useSelector(selectViewSkillsChecklistPassport);
  const dispatch = useDispatch();

  const onDelete = (e, item) => {
    handleDelete(item, true);
  };

  const onAttachmentSelection = (event, selectedData, selectAll) => {
    onAttachmentSelected(selectedData, event.target.checked, selectAll);
  };

  //
  // ─── FILE PREVIEW SUPPORTED OR NOT ──────────────────────────────────────────────
  //
  const UnsupportedFilePreview = (doctype: { docType: string }) => {
    const { classes } = useStyles();

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.doc}>
          {isFileTypeHtml(doctype.docType) ? (
            <LanguageIcon className={classes.unsupportDoc} />
          ) : (
            <DescriptionOutlinedIcon className={classes.unsupportDoc} />
          )}
        </Grid>
      </Grid>
    );
  };

  const displayPreviewForSkillChecklist = () => {
    return (
      <div className={classes.nonSelectable}>
        <div>{`Skills Checklist - ${viewSkillsChecklistPassport?.type} ${viewSkillsChecklistPassport?.versionId}`}</div>
        <div>
          {`${credential.credential.lastUpdatedByName.first} ${credential.credential.lastUpdatedByName.last} | ${
            credential.credential.lastUpdate != null
              ? Moment(credential.credential.lastUpdate).format('MM/DD/YYYY h:mm:ss a')
              : 'N/A'
          }`}
        </div>
      </div>
    );
  };

  const handleDownload = (item, event) => {
    if (item?.isSkillChecklist) {
      event.stopPropagation();
    } else {
      downloadFile(
        item?.document?.uri,
        item?.document?.fileData,
        getFileNameFormat(
          credential?.credential?.requirementType,
          `Attachment ${item.sortOrder + 1}`,
          item.lassttUpdated,
        ),
        item?.document?.fileType,
      );
      dispatch(
        globalActions.setSnackBar({
          message: t('search.grid.download.successMessage'),
          severity: 'success',
        }),
      );
    }
  };

  const buildMyDocGlance = (item, number) => {
    return (
      <div className={classes.main}>
        <div className={classes.headerSpace}>
          <div className={classes.checkBox}>
            <Checkbox
              size="small"
              color="primary"
              onChange={event => onAttachmentSelection(event, item, selectAll)}
              checked={item?.checkBox ?? false}
              data-testid="attachment-checkbox"
            />
          </div>

          {item?.isSkillChecklist ? (
            displayPreviewForSkillChecklist()
          ) : (
            <div className={classes.nonSelectable}>
              <div>{item.title ? item.title : `Attachment ${item.sortOrder + 1}`}</div>
              <div>
                {`${isNullWithDefaultVal(item.lastUpdatedBy)} | ${
                  item.lastUpdated != null ? Moment(item.lastUpdated).format('MM/DD/YYYY') : 'N/A'
                }`}
              </div>
            </div>
          )}
        </div>
        <div className={`${classes.documentView}`}>
          {isPrevSupported(item?.document?.fileType) && !item.isSkillChecklist ? (
            <DocViewerFit docs={[item.document] as IAttachment[]} preview={true} />
          ) : (
            <UnsupportedFilePreview docType={item?.document?.fileType} />
          )}
        </div>
        <div className={classes.comment}> {isNullWithDefaultVal(item.comments)}</div>
        <div className={classes.actions}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid
              item
              spacing={1}
              onClick={event => {
                handleDownload(item, event);
              }}
              data-testid="attachment-download"
            >
              <GetAppIcon className={!item?.isSkillChecklist ? classes.icon : classes.disableIcon} />
            </Grid>
            {item.travelerRequirementImageID ? (
              <Grid
                item
                onClick={e =>
                  !item?.isSkillChecklist && isCredentialing(user?.userInfo?.roles || []) && onDelete(e, item)
                }
                data-testid="attachment-delete"
              >
                <DeleteIcon
                  className={
                    isCredentialing(user?.userInfo?.roles || []) && !item?.isSkillChecklist
                      ? classes.icon
                      : classes.disableIcon
                  }
                />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Paper className={`paper paper-small`} elevation={0} style={{ height: '100%' }}>
        {docData && buildMyDocGlance(docData, number)}
      </Paper>
    </React.Fragment>
  );
};
