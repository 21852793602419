import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { PlacementOrderType } from 'app/models/Placement/PlacementDetails';
import { AxiosError, AxiosResponse } from 'axios';
import baseAxiosInstance from 'utils/BaseApi';

const placementBase = globalThis?.app?.env?.REACT_APP_PLACEMENTS_SERVICE_BASE_URL;
const sharedBase = globalThis?.app?.env?.REACT_APP_TRACK_CHANGE_SERVICE_BASE_URL;

export async function placementByStatus(body: object, pageNumber: number, pageSize: number, sortColumns: string[]) {
  try {
    const columns = sortColumns?.join(',') ?? '';
    const url = `${placementBase}/placements/bystatus?PageNumber=${pageNumber}&PageSize=${pageSize}&SortColumns=${columns}`;
    const response = await baseAxiosInstance.post(url, body);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'placementByStatus',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
    return 'error';
  }
}

export async function updatePlacementArchiveStatus(body: object) {
  try {
    const url = `${placementBase}/placement/archive`;
    return await baseAxiosInstance.post(url, body);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updatePlacementArchiveStatus',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
    return 'error';
  }
}

export async function getPlacementsByVolume() {
  try {
    const url = `${placementBase}/placements/byvolume`;
    const response = await baseAxiosInstance.get(url);
    return response?.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getPlacementsByVolume',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
}

export const getActivePlacementsByCandidate = async (id: string, pageNumber: number = 1, pageSize: number = 1000) => {
  try {
    const url = `${placementBase}/placements/activeplacementsbycandidateid/${id}?PageNumber=${pageNumber}&PageSize=${pageSize}`;
    const response = await baseAxiosInstance.get(url);
    if (!response || response.status < 200 || response.status > 299) throw new Error();
    return response?.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getActivePlacementsByCandidate',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
};

export const getEventsByPlacement = async (placementId: number) => {
  try {
    const url = `${placementBase}/events/byplacementId/${placementId}`;
    const response = await baseAxiosInstance.get(url);
    if (!response || response.status < 200 || response.status > 299) throw new Error();
    return response?.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getEventsByPlacement',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
};

export const getEventsTypes = async () => {
  try {
    const url = `${placementBase}/events/eventtypes`;
    const response = await baseAxiosInstance.get(url);
    if (!response || response.status < 200 || response.status > 299) throw new Error();
    return response?.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getEventsTypes',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
};

export const getPlacementReminders = async (eventType: 'today' | 'scheduled' | 'all') => {
  try {
    const url = `${placementBase}/placements/reminders/${eventType}`;
    const response = await baseAxiosInstance.get(url);
    if (!response || response.status < 200 || response.status > 299) throw new Error();
    return response?.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getPlacementReminders',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
};

export async function getActivePlacementsByAccountManager(accountManagerId: number[]) {
  try {
    const url = `${placementBase}/placements/accountmanager/work-desk`;
    const response = await baseAxiosInstance.post(url,{ accountManagerIds:accountManagerId});
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getActivePlacementsByAccountManager',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
}

export async function getPlacementOrderType(placementId: number) {
  try {
    const url = `${placementBase}/placement/${placementId}/placement-order-type`;
    const response = await baseAxiosInstance.get(url);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getPlacementOrderType',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
}

export async function getPlacementHeader(id: number) {
  try {
    const url = `${placementBase}/placement/${id}`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getPlacementHeader',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
}

export async function getPlacementDetailNotes(id: number) {
  try {
    const url = `${placementBase}/placement/${id}/detail/notes`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getPlacementDetailNotes',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
}

export async function getPlacementsOrderComparisons(id: number) {
  try {
    const url = `${placementBase}/placement/${id}/detail/order-comparison`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getPlacementsOrderComparisons',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
}

export async function getCandidateCertificates(id: number) {
  try {
    const url = `${placementBase}/placement/${id}/candidate-certificates`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateCertificates',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
}

export async function getCandidateEducation(id: number) {
  try {
    const url = `${placementBase}/placement/${id}/candidate-education`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateEducation',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
}

export const getRequirementCheckList = async () => {
  try {
    const url = `${placementBase}/events/getdropdownvaluesforchecklist`;
    const response = await baseAxiosInstance.get(url);
    if (!response || response.status < 200 || response.status > 299) throw new Error();
    return response?.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getRequirementCheckList',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
};

export async function postRequirementDetails(body: object) {
  try {
    const url = `${placementBase}/placements/create/checklistitem `;
    const response = await baseAxiosInstance.post(url, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postRequirementDetails',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
    return 'error';
  }
}

export async function getCandidateDirections(id: number) {
  try {
    const url = `${placementBase}/placement/${id}/candidate-radius`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateDirections',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
}

export async function getPlacementCandidatePlacement(id: number, categoryId: number) {
  try {
    const url = `${placementBase}/placement/${id}/category/${categoryId}`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getPlacementCandidatePlacement',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
}

export async function getNewPlacementValidations(body: {
  candidateId: number;
  brandId: number;
  orderId: number;
  facilityId: number;
  recruiterId?: number;
  availabilityDate?: string;
}) {
  try {
    const url = `${placementBase}/placement/validate`;
    return await baseAxiosInstance.post(url, body);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getNewPlacementValidations',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
}

export async function saveWFCPlacements(body) {
  try {
    const url = `${placementBase}/placement/save`;
    return await baseAxiosInstance.post(url, body);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'saveWFCPlacements',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
}

export async function updatePlacementDetails(body) {
  try {
    const url = `${placementBase}/placement/update`;
    return await baseAxiosInstance.post(url, body);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updatePlacementDetails',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
    return error?.response;
  }
}

export async function updateStrikePlacementDetails(body) {
  try {
    const url = `${placementBase}/placement/update/strike-placement`;
    return await baseAxiosInstance.post(url, body);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateStrikePlacementDetails',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
    return error?.response;
  }
}

export async function getPlacementEditableStatuses(placementId: number) {
  try {
    const url = `${placementBase}/placement/${placementId}/disabled-fields`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getPlacementEditableStatuses',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
}

export async function getPlacementChangeHistory(body: {
  entityId: string;
  pageSize: number;
  pageNumber: number;
  sortColumn: string;
  sortOrder: string;
}) {
  try {
    const url = `${placementBase}/placement/change-history`;
    const response = await baseAxiosInstance.post(url, body);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getPlacementChangeHistory',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
}

export async function updateStatus(body: {
  placementId: number;
  activityTypeId: number;
  activityStatusId: number;
  userId: number;
  placementOrderType: PlacementOrderType;
  candidateId: number;
}): Promise<AxiosResponse<any> | undefined> {
  try {
    const url = `${placementBase}/placement/update/status`;
    const response = await baseAxiosInstance.post(url, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateStatus',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
}

export async function archivePlacement(body: {
  placements: [
    {
      placementId: number;
      activityStatusId: number;
    },
  ];
  userId: number;
}): Promise<AxiosResponse<any> | undefined> {
  try {
    const url = `${placementBase}/placement/archive`;
    const response = await baseAxiosInstance.post(url, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'archivePlacement',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
}

/** Placement Summary - Start */

/*export async function getPlacementSummaryInterview_OrderReq(placementId: number) {
  try {
    return await GetPlacementSummaryInterview_OrderReq();
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getPlacementSummaryInterview_OrderReq',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
}*/

/*export async function getPlacementSummaryDOB_OrderReq(placementId: number) {
  try {
    return await GetPlacementSummaryDOB_OrderReq();
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getPlacementSummaryDOB_OrderReq',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
}*/

/*export async function getPlacementSummarySSN_OrderReq(placementId: number) {
  try {
    return await GetPlacementSummarySSN_OrderReq();
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getPlacementSummarySSN_OrderReq',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
}*/
/** Placement Summary - End */

/** Order Automation - Start*/

export async function getReadyToSubmitPlacementIds(orderId: number, activityStatus) {
  try {
    const url = `${placementBase}/placement/order/${orderId}/validate/${activityStatus}`;
    return await baseAxiosInstance.get(url);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getReadyToSubmitPlacementIds',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
}

/** Order Automation - End*/

export async function getPlacementDocuments({ placementId }) {
  try {
    const url = `${placementBase}/placement/${placementId}/doc`;
    const response = await baseAxiosInstance.get(url);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getPlacementDocuments',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
}

export async function getLandGtgDetails(placementId: number) {
  try {
    const url = `${placementBase}/placement/${placementId}/lands`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getLandGtgDetails',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
}

export async function postLandGtgDetails(data: any) {
  try {
    const url = `${placementBase}/placement/lands`;
    const response = await baseAxiosInstance.post(url, data);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postLandGtgDetails',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
    return error.response;
  }
}

export async function postFinalizedByQs(data: any) {
  try {
    const url = `${placementBase}/placementfinalizedby/update`;
    const response = await baseAxiosInstance.post(url, data);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postFinalizedByQs',
        area: 'src/app/services/PlacementServices/PlacementServices.ts',
      },
    });
  }
}

export const syncPlacementWithPeopleSoft = (placementIds: [number]) => {
  const url = `${sharedBase}/placement/peoplesoft`;
  return baseAxiosInstance
    .post(url, placementIds)
    .then(response => {
      return response;
    })
    .catch((error: AxiosError) => {
      return error.response;
    });
};

export const getPeopleSoftDetails = (placementId: number): Promise<AxiosResponse<any> | undefined> => {
  const url = `${sharedBase}/placement/${placementId}/peoplesoft`;
  return baseAxiosInstance
    .get(url)
    .then(response => {
      return response;
    })
    .catch((error: AxiosError) => {
      return error.response;
    });
};

export const getCandidateSellingPointsByPid = (id: number) =>
  baseAxiosInstance
    .get(`${placementBase}/placement/${id}/detail/candidate-selling-points`)
    .then(res => res.data)
    .catch((error: AxiosError) => error.response);