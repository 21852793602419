/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui';
import { CustomFilter } from 'app/ComponentLibrary/Filter';
import { selectUser } from 'oidc/user.selectors';
import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Concatenate } from 'utils/string/string';
import { selectActiveAccountManagers, selectFilterSpecs } from './AmPlacementsDesk.selector';
import { amPlacementsFilterName, defaultFilterValues, getFilterSpecs } from './filterUtils';
import { amPlacementsAction, defaultFilterChipSelected } from './AmPlacementsDesk.redux';
import { pipelineChoiceActions, pipelineChoiceKeys } from '@AMIEWEB/Common/WorkDesk/PipelineChoice/pipelineChoice.redux';

const useStyles = makeStyles()({
  accountManagerField: {
    minWidth: 200,
  },
  placementRecruiterField: {
    minWidth: 155,
  },
  affiliateField: {
    minWidth: 134,
  },
  staffingOrOrderField: {
    minWidth: 126,
  },
  technologyVendorField: {
    minWidth: 146,
  },
});

export const PlacementsFilter = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { userInfo } = useSelector(selectUser);
  const accountManagers = useSelector(selectActiveAccountManagers);
  const filterSpecs = useSelector(selectFilterSpecs);
  const [fields, setFields] = useState(getFilterSpecs(filterSpecs, accountManagers, t, classes));

  const dispatch = useDispatch();
  const {
    setValue,
    formState: { dirtyFields },
  } = useFormContext();

  const resetFilter = () => {
    const placementAccountManager = [{
      id: userInfo.employeeId,
      name: Concatenate([userInfo?.firstName || '', userInfo?.lastName || ''], ' '),
      value: userInfo.employeeId,
      object: {
        userId: userInfo.employeeId,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
      },
    }];
    const newFilter = {
      ...defaultFilterValues,
      placementAccountManager,
    };
    Object.entries(newFilter).map(item =>
      setValue(item[0], item[1], {
        shouldDirty: item[0] === amPlacementsFilterName.placementAccountManager,
      }),
    );
    props.onApplyFilter(newFilter, defaultFilterChipSelected, []);
    dispatch(amPlacementsAction.setFilterChipSelected(defaultFilterChipSelected));
    dispatch(
      pipelineChoiceActions.setPipelineChoice({
        key: pipelineChoiceKeys.AM_WORK_DESK_GRID,
        selection: [],
      }),
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setFields(getFilterSpecs(filterSpecs, accountManagers, t, classes)), [filterSpecs, accountManagers]);

  return (
    <CustomFilter
      fields={fields}
      onResetForm={() => resetFilter()}
      justifyContent={'flex-start'}
      version2
      falseProp={dirtyFields}
      analytics={{
        caller: 'Account Management WorkDesk Filter',
        onApply: true,
        properties: {
          category: 'Placements',
        },
      }}
    />
  );
};
