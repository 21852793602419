/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonIcon from '@mui/icons-material/Person';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Skeleton,
  Tooltip,
  Typography,
} from 'amn-ui-core';
import EmailDisabled from 'app/assets/images/CandidateHeader/email-Disabled.svg';
import ReasonInfo from 'app/assets/images/CandidateHeader/info.svg';
import PhoneDisabled from 'app/assets/images/CandidateHeader/PhoneDisabled.svg';
import MessageDisabled from 'app/assets/images/CandidateHeader/Text-Disabled.svg';
import MailBoxIcon from 'app/assets/images/MailBox.svg';
import MessageIcon from 'app/assets/images/Message.svg';
import AddIcCallIcon from 'app/assets/images/Notification/add-call.svg';
import PhoneIcon from 'app/assets/images/Phone.svg';
import PhoneIconOutline from 'app/assets/images/IconImages/CallIconOutline.svg';
import MailBoxIconOutline from 'app/assets/images/IconImages/MailIconOutline.svg';
import MessageIconOutline from 'app/assets/images/IconImages/MessageIconOutline.svg';
import { candidateDetailActions } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { CustomBackdrop } from 'app/components/Common/Backdrop/CustomBackdrop';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { MessageDisabledTooltip } from 'app/components/Common/Tooltips/MessageDisabledTooltip';
import { ChannelType, ContactType } from 'app/components/Notification/Constants';
import {
  notificationDataActions,
  notificationDataKey,
  notificationDataReducer,
} from 'store/redux-store/notification/notification.redux';
import { notificationSelection, selectCandidatesConsents } from 'store/redux-store/notification/notification.selector';
import { UserRoleSearchResultItem } from 'app/components/Order/OrderDetails/OrderHeader/OrderHeader';
import { convertToFormat } from 'app/helpers/dateHelper';
import { ResponsiveColumnContainer } from 'app/layout/ResposiveColumnContainer';
import { EmergencyContact, IAddress, ICandidateDetails, IPhoneNumber } from 'app/models/Candidate/CandidateProfile';
import { updateHeaderDetails } from 'app/services/CandidateServices/CandidateServices';
import { getSearchLookups } from 'app/services/SharedServices/SharedServices';
import moment from 'moment';
import { selectUser } from 'oidc/user.selectors';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import { useReadOnly, userRoles } from 'oidc/userRoles';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'redux-injectors';
import { GetMomentOfDate } from 'utils/dates/moment';
import { Concatenate } from 'utils/string/string';
import { LayoutGrid, LayoutGridItem } from '../../../../layout/LayoutGrid';
import { MediaQuery } from '../../../../layout/MediaQuery';
import {
  candidateDetailsSelection,
  selectAuditData,
  selecthasActivePlacements,
  selectSkillSetTree,
} from '../Profile/CandidateDetails.selector';
import {
  allCandidateStatuses,
  CandidateStatus,
  getCandidateStatuses,
  statusChangeReasons,
} from './CandidateStatusEditRuleHelper';
import { setNotificationData } from './NotificationHelper';
import { SkillSetWrapper } from './ManageSkillSet/SkillSetWrapper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VerifiedIcon from 'app/assets/images/CandidateHeader/VerifiedCheckmark.svg';
import UnverifiedIcon from 'app/assets/images/CandidateHeader/HearingBack.svg';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import styledComponent from 'styled-components';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { Authorized } from 'oidc/userHelper';
import {
  useEnableEmailCommunication,
  useEnableSmsCommunication,
  useEnableVoiceCommunication,
} from 'oidc/CommunicationEnabler';
import { TagsWrapper } from './TagsWrapper/TagsWrapper';
import { useDecision } from '@optimizely/react-sdk';
import { ff_candidate_tags } from 'app/constants/FeatureFlags/Candidate/Keys';
import useCandidateHeaderStyle from './CandidateHeader.styles';
import states from 'app/assets/jsons/State.json';
import {
  ICandidateDrawerConsent,
  OperationType,
  PanelControls,
  SideDrawer,
} from 'app/components/Common/Drawer/SidePanel';
import _ from 'lodash';
import { ContactTab } from './ContactTab';
import { IRecentHistoryCandidate } from 'app/models/Global/RecentHistoryModels';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { missingField } from '../../../Notification/MultiChannel/Sms/CredentialingAnalyst/PlacementDisplay';
import { InlineEditText } from '@AMIEWEB/Common/Textfield/InlineEditText';
import { InlineTextInput } from './InlineTextInput';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { defaultDateRange } from './Defaults';
import { isPositiveNumber } from 'app/helpers/componentHelpers';
import HeaderButton from '@AMIEWEB/Common/Buttons/HeaderButton';
import { getExperienceData } from '../QuickGlanceBar/helper';
import { ITypeAheadOption } from '../CandidateTabPanel/PreferencesTab/CustomComponents/ControlledTypeAhead';
import LockIcon from '@mui/icons-material/Lock';
import ManageRecruiter from './ManageRecruiter';
import CommunicationStack from '@AMIEWEB/Notification/MultiChannel/CommunicationTooltip/CommunicationStack';
import smsWithdrawYellow from 'app/assets/images/SMS-withdraw-yellow.svg';
import emailWithdrawYellow from 'app/assets/images/Email-withdraw-yellow.svg';
import phoneWithdrawYellow from 'app/assets/images/phone-withdraw-yellow.svg';
import EmailStack from '@AMIEWEB/Notification/MultiChannel/CommunicationTooltip/EmailStack';
import { selectDetailsPageDrawer } from 'app/ApplicationRoot/Global.selectors';
import { selectCoveredInbox } from 'store/redux-store/user-coverage/selectors';
import { CreatePlacementWrapper } from '@AMIEWEB/Placement/CreatePlacement';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { PreferenceCosmosKeys } from 'oidc/UserDevicePreference/utils';
import { usePromiseTracker } from 'react-promise-tracker';
import { selectWorkExperienceView } from 'store/redux-store/candidate-work-experience/selector';
import { calculateDisplayChipsLength, calculateEachDisplayChipsLength } from './CandidateHeaderHelper';

export interface ICandidateHeaderDetails {
  candidateId: number;
  goesBy?: string | null;
  firstName?: string;
  lastName?: string;
  middleName?: string | null;
  recruiterId?: number | undefined;
  recruiterName?: string;
  availabilityDate?: string;
  callBackDate?: string;
  brandId?: number;
  currentEmployeeName: string;
  currentEmployeeId: number;
  primaryPhoneNumber?: string | null;
  mobilePhoneNumber?: string | null;
  primaryEmail?: string | null;
  secondaryEmail?: string | null;
  candidateStatusId?: number;
  candidateStatus?: string;
  inactivityReasonId?: number;
  inactivityReason?: string;
  phoneNumbers?: IPhoneNumber[] | null;
  addresses?: IAddress[];
  timestamp?: string;
  ethnicityId?: string;
  ethnicityDescription?: string;
  gender?: string;
  emergencyContactInfo?: EmergencyContact;
  notes?: string;
  applicationQuestions?: {
    licenseInvestigationSuspension: string;
    investigationSuspensionExplanation: string;
    usRightToWorkVerification: string;
    visaType: string;
  };
}

export interface ICandidateAddressDetails {
  candidateId: number;
  brandId: number;
  addresses?: IAddress[];
  currentEmployeeName: string;
  currentEmployeeId: number;
}

enum EditType {
  availableOn = 'availableOn',
  callbackTo = 'callbackTo',
  candidateSummary = 'candidateSummary',
  goesBy = 'goesBy',
  availableOnHeader = 'availableOnHeader',
  callbackToHeader = 'callbackToHeader',
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const CandidateHeader = ({ onNameClick }: { onNameClick?: (value) => void }) => {
  useInjectReducer({ key: notificationDataKey, reducer: notificationDataReducer });
  const { t } = useTranslation();
  const { classes } = useCandidateHeaderStyle();
  const { width } = useWindowDimensions();

  const candidateDetails = useSelector(candidateDetailsSelection);
  const { workExperienceData } = useSelector(selectWorkExperienceView);
  const detailsPageDrawer = useSelector(selectDetailsPageDrawer);
  const openProfileDrawer = detailsPageDrawer?.open;
  const user = useSelector(selectUser);
  const userPreference = useSelector(selectUserPreference);
  const notificationData = useSelector(notificationSelection);
  const coveredInbox = useSelector(selectCoveredInbox);
  const hasActivePlacements = useSelector(selecthasActivePlacements);
  const candidateConsents = useSelector(selectCandidatesConsents);
  const candidateSkillSetTree = useSelector(selectSkillSetTree);
  const candidateAuditDetails = useSelector(selectAuditData);

  const [isShowingBlank, setIsShowingBlank] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [recruiterValue, setRecruiterValue] = useState<string>(null);
  let emptyString = '';
  const [recruiterOpen, setRecruiterOpen] = useState<boolean>(false);
  const [enableRecruiterEdit, setEnableRecruiterEdit] = useState<boolean>(false);
  const [recruiterOptions, setRecruiterOptions] = useState<string[]>([]);
  const [recruiterSearchList, setRecruiterSearchList] = useState<UserRoleSearchResultItem[]>([]);
  const [autocompleteListLoadding, setAutocompleteListLoadding] = useState<boolean>(false);
  const [goesByValue, setGoesByValue] = useState<any>(candidateDetails?.goesBy);
  const [enablegoesByEdit, setEnableGoesByEdit] = useState<boolean>(false);
  const [enableavailableOnStartEdit, setEnableAvailableOnStartEdit] = useState<boolean>(false);
  const [enablecallbackToStartEdit, setEnableCallbackToEdit] = useState<boolean>(false);
  const avatarText = Concatenate(
    [candidateDetails.name?.first?.trim().charAt(0), candidateDetails.name?.last?.trim().charAt(0)],
    '',
  );
  const recruiter = Concatenate([candidateDetails?.recruiter?.first, candidateDetails?.recruiter?.last], ' ');
  const candidateName = Concatenate(
    [candidateDetails?.name?.first, candidateDetails?.name?.middle || '', candidateDetails?.name?.last],
    ' ',
  );
  const [isUpdateHeader, setIsUpdateHeader] = useState<boolean>(false);
  const [showNotificationBackdrop, setShowNotificationBackdrop] = useState<boolean>(false);
  const [isRecruiter, setIsRecruiter] = useState(false);
  const [status, setStatus] = React.useState('');
  const [reason, setReason] = React.useState('');
  const [isEditAccess, setIsEditAccess] = React.useState<boolean | null>(null);
  const [dropdownOptions, setDropdownOptions] = useState<any>('');
  const [showReasonPopup, setShowReasonPopup] = useState<boolean>(false);
  const [isStatusChanged, setIsStatusChanged] = useState<boolean>(false);
  const [openSkillSet, setOpenSkillSet] = React.useState(false);
  const { readOnly } = useReadOnly();
  const [openCreatePlacementForm, setOpenCreatePlacementForm] = useState<boolean>(false);
  const [showTagsSection] = useDecision(ff_candidate_tags);
  const [savedValues, setSavedValues] = useState<{
    availableOn: string | null;
    callbackTo: string | null;
    candidateSummary: string | null;
    goesBy: string | null;
    recruiterValue: string | null;
    timestamp: string | null;
    isInitialValue: boolean | true;
  }>({
    availableOn: null,
    callbackTo: null,
    candidateSummary: null,
    goesBy: null,
    recruiterValue: null,
    timestamp: null,
    isInitialValue: true,
  });
  const [availableOnStartValue, setAvailableOnStartValue] = useState<string | null>('');
  const [availableOnStartValueHeader, setAvailableOnStartValueHeader] = useState<string | null>('');
  const [callbackToValue, setCallbackToValue] = useState<string | null>('');
  const [callbackToValueHeader, setCallbackToValueHeader] = useState<string | null>('');
  const [candidateSummaryValue, setCandidateSummaryValue] = useState<string | null>(null);
  const [saveStatus, setSaveStatus] = useState<number | null>();
  const [clickAvailableOn, setClickAvailableOn] = useState<boolean>(false);
  const [clickCallbackTo, setClickCallbackTo] = useState<boolean>(false);
  const [clickCallbackToHeader, setClickCallbackToHeader] = useState<boolean>(false);
  const [clickAvailableOnHeader, setClickAvailableOnHeader] = useState<boolean>(false);
  const [clickCandidateSummary, setClickCandidateSummary] = useState<boolean>(false);
  const [clickGoesBy, setClickGoesBy] = useState<boolean>(false);
  const [skillsetWidth, setSkillsetWidth] = useState<number>(5);
  const [addressBarWidth, setAddressBarWidth] = useState<number>(7);
  const [skillsetChipWidth, setSkillsetChipWidth] = useState<string>('30%');
  const [displayChips, setDisplayChips] = useState<any[]>([]);
  const [addressWidth, setAddressWidth] = useState<string>('87%');

  const [isCallbackHidden, setIsCallbackHidden] = useState<boolean>(false);
  const [isAvailablilityHidden, setIsAvailablilityHidden] = useState<boolean>(false);
  const [isCallbackHiddenHeader, setIsCallbackHiddenHeader] = useState<boolean>(false);
  const [isAvailablilityHiddenHeader, setIsAvailablilityHiddenHeader] = useState<boolean>(false);
  const [emptyRecruiterName, setEmptyRecruiterName] = useState<boolean>(false);
  const [anchorElManageSkillSet, setAnchorElManageSkillSet] = React.useState<null | HTMLElement>(null);
  const hasCallConsents = hasActivePlacements ? true : candidateConsents.hasCallConsents;
  const hasEmailConsents = hasActivePlacements ? true : candidateConsents.hasEmailConsents;
  const hasTextConcents = hasActivePlacements ? true : candidateConsents.hasTextConcents;
  const candidateEmails = [candidateDetails.primaryEmail, candidateDetails.secondaryEmail].filter(Boolean);
  const [openEmailTooltip, setOpenEmailTooltip] = React.useState<boolean>(false);
  const [showAddressTooltip, setShowAddressTooltip] = React.useState<boolean>(false);
  const { promiseInProgress } = usePromiseTracker({ area: 'get-candidate-details', delay: 0 });

  const enableEmailCommunication = useEnableEmailCommunication();
  const enableSmsCommunication = useEnableSmsCommunication();
  const enableVoiceCommunication = useEnableVoiceCommunication();  

  const [consentsData, setConsentsData] = useState<ICandidateDrawerConsent>({
    hasCallConsents: true,
    hasEmailConsents: true,
    hasTextConsents: true,
  });

  useEffect(() => {
    setConsentsData({
      hasCallConsents: hasActivePlacements ? true : candidateConsents?.hasCallConsents,
      hasEmailConsents: hasActivePlacements ? true : candidateConsents?.hasEmailConsents,
      hasTextConsents: hasActivePlacements ? true : candidateConsents?.hasTextConcents,
    });
  }, [candidateConsents, hasActivePlacements]);

  useEffect(() => {
    if (!candidateDetails?.callBackDate) {
      setIsCallbackHidden(true);
      setIsCallbackHiddenHeader(true);
    }
    if (!candidateDetails?.availableOn) {
      setIsAvailablilityHidden(true);
      setIsAvailablilityHiddenHeader(true);
    }
    if (candidateDetails.candidateStatus) {
      setStatus(candidateDetails.candidateStatus.replace(' with ', ' w/'));
    }

    if (candidateDetails?.inactivityReason !== null) {
      setReason(candidateDetails?.inactivityReason);
    }
    setGoesByValue(candidateDetails?.goesBy);
    setAvailableOnStartValue(candidateDetails?.availableOn);
    setCallbackToValue(candidateDetails?.callBackDate);
    setCandidateSummaryValue(candidateDetails?.candidateSummary);    
  }, [candidateDetails]);

  useEffect(() => {
    if (candidateDetails?.recruiter) {
      setRecruiterValue(Concatenate([candidateDetails?.recruiter?.first, candidateDetails?.recruiter?.last], ' '));
    }
  }, [candidateDetails?.recruiter]);

  const handleSkillSetClose = () => {
    setOpenSkillSet(false);
    dispatch(
      candidateDetailActions.setSkillSetTree({
        ...candidateSkillSetTree,
      }),
    );
    dispatch(candidateDetailActions.setAddSkillSetTree(null));
  };

  const skills = () => {
    const verifiedSkills: string[] = [];
    const verifyDuplicateSkills: Map<string, boolean> = new Map<string, boolean>();
    candidateDetails?.verifiedSkills?.forEach(skill => {
      let res = `${skill.disciplineAbbr} - ${skill.specialtyAbbr}`;
      if (skill.subSpecialtyAbbr) res += ', ' + skill.subSpecialtyAbbr;
      if (!verifyDuplicateSkills.has(res)) {
        verifiedSkills.push(res);
        verifyDuplicateSkills.set(res, true);
      }
    });
    return verifiedSkills.join(' | ');
  };

  const handleChange = event => {
    setStatus(event.target.value);
    setIsStatusChanged(true);
    if (event.target.value === 'Inactive' || event.target.value === 'Inactive w/Audit') {
      setShowReasonPopup(true);
    } else {
      setReason('');
    }
  };

  const handleReasonSelect = event => {
    setReason(event.target.value);
  };

  const checkReasonVisibility = (id: any) => {
    return id === 4 || id === 26 || id === 31 || id === 8 || id === 10 || id === 6 || id === 12 || id === 11;
  };
  const checkReasonVisibilityForRecruiter = (id: number) => {
    return (
      id === 4 || id === 26 || id === 31 || id === 8 || id === 10 || id === 6 || id === 12 || id === 11 || id === 21
    );
  };

  const getInactivityReason = (id: any) => {
    if (
      id === 4 ||
      id === 12 ||
      id === 29 ||
      id === 2 ||
      id === 33 ||
      id === 26 ||
      id === 21 ||
      id === 30 ||
      id === 31 ||
      id === 6 ||
      id === 8 ||
      id === 0 ||
      id === 34 ||
      id === 32 ||
      id === 10 ||
      id === 11
    ) {
      return `Reason: ${statusChangeReasonOptions.filter(x => x.value === id)[0].name}`;
    }
  };

  const _onNameClick = () => {
    onNameClick?.(0);
  };

  const fetchData = async recruiterValueTem => {
    try {
      setAutocompleteListLoadding(true);
      const recruitersList = await getSearchLookups('recruiters', recruiterValueTem);
      setRecruiterOptions(
        recruitersList.map(item => {
          return item.name;
        }),
      );
      setRecruiterSearchList(recruitersList);
    } catch (error) {
      trackException({
        exception: error,
        properties: {
          name: ExceptionType.APIRequestError,
          functionName: 'fetchData',
          area: 'src/app/components/Candidate/CandidateProfile/CandidateHeader/CandidateHeader.tsx',
        },
      });
    }
    setAutocompleteListLoadding(false);
  };

  useEffect(() => {
    if (isPositiveNumber(candidateDetails.travelerId)) {
      dispatch(
        candidateDetailActions.getTagsList({
          travelerId: candidateDetails.travelerId,
          brandId: candidateDetails.brandId,
        }),
      );
      fetchData('');
      const recentHistoryPrefs = userPreference?.recentHistory?.value;
      let newRecentHistoryPrefs = _.cloneDeep(recentHistoryPrefs);
      let candidateArray: IRecentHistoryCandidate[] = recentHistoryPrefs?.candidate
        ? [...recentHistoryPrefs?.candidate]
        : [];

      let candidateId = candidateDetails?.travelerId;
      let brandId = candidateDetails?.brandId;

      const candidateVal: IRecentHistoryCandidate = {
        candidateId: candidateDetails?.travelerId,
        brandId: candidateDetails?.brandId,
        candidateName: candidateName,
      };
      if (candidateId !== 0 && recentHistoryPrefs !== null) {
        let index = candidateArray.findIndex(item => item.candidateId === candidateId && item.brandId === brandId);

        if (index === -1) {
          candidateArray.unshift(candidateVal);
          if (candidateArray.length > 5) {
            candidateArray = candidateArray.slice(0, 5);
          }
        } else {
          candidateArray.splice(index, 1);
          candidateArray.unshift(candidateVal);
          if (candidateArray?.length > 5) {
            candidateArray = candidateArray.slice(0, 5);
          }
        }
        newRecentHistoryPrefs = { ...newRecentHistoryPrefs, candidate: candidateArray };
        dispatch(
          userDevicePreferenceActions.saveUserRecentHistoryPreferenceAction({
            id: PreferenceCosmosKeys.recentHistory,
            value: newRecentHistoryPrefs,
          }),
        );
      }
    }
  }, [candidateDetails.travelerId, candidateDetails.brandId]);

  const handleClose = () => {
    setShowReasonPopup(false);
    if (candidateDetails.candidateStatus === 'Inactive with Audit') {
      setStatus('Inactive w/Audit');
    } else if (candidateDetails.candidateStatus === 'Active with Audit') {
      setStatus('Active w/Audit');
    } else if (candidateDetails.candidateStatus === 'Pending with Audit') {
      setStatus('Pending w/Audit');
    } else {
      setStatus(candidateDetails.candidateStatus);
      setReason('');
    }
    setIsStatusChanged(false);
  };

  const handleSubmit = () => {
    updateCandidateStatus();
  };

  const setCandidateStatusValue = () => {
    if (status && status.replace(' w/', ' with ') !== candidateDetails.candidateStatus) {
      setStatus(status);
    } else if (!status && candidateDetails.candidateStatus) {
      setStatus(candidateDetails.candidateStatus.replace(' with ', ' w/'));
    } else if (candidateDetails.candidateStatus) {
      setStatus(candidateDetails.candidateStatus.replace(' with ', ' w/'));
    }
  };

  const reasonDropdownIcon = props => {
    return <KeyboardArrowDownIcon style={{ fontSize: 27 }} />;
  };

  useEffect(() => {
    if (status === '' && candidateDetails.candidateStatus !== null) {
      setCandidateStatusValue();
    } else {
      if (status && status.replace(' w/', ' with ') !== candidateDetails.candidateStatus) {
        setCandidateStatusValue();
      } else {
        setStatus(status);
      }
    }
    const statuses = getCandidateStatuses(user.userInfo, status);
    if (statuses !== undefined) {
      setIsEditAccess(true);
      setDropdownOptions(statuses);
    } else {
      setIsEditAccess(false);
    }
    if (
      status !== candidateDetails.candidateStatus &&
      status !== '' &&
      status !== 'Inactive' &&
      status !== 'Inactive w/Audit' &&
      isStatusChanged
    ) {
      setIsStatusChanged(false);
      updateCandidateStatus();
    }
  }, [candidateDetails.candidateStatus, status]);

  const getReasonId = () => {
    let reasonId = null;
    if (reason) {
      const filteredReason = statusChangeReasonOptions.filter(x => x.name.toLowerCase() === reason.toLowerCase());

      if (filteredReason && filteredReason.length > 0) {
        reasonId = filteredReason[0].value;
      }
    }
    return reasonId;
  };

  const updateCandidateStatus = () => {
    const statusId = allCandidateStatuses.filter(x => x.name === status)[0].value;
    const reasonId = getReasonId();

    const updatedHeaderDetails: ICandidateHeaderDetails = {
      candidateId: candidateDetails?.travelerId,
      goesBy: goesByValue,
      recruiterId:
        getRecruiterId(recruiterValue) !== 0
          ? getRecruiterId(recruiterValue)
          : candidateDetails?.recruiter?.recruiterId
          ? candidateDetails?.recruiter?.recruiterId
          : null,
      recruiterName:
        candidateDetails.recruiter?.first?.length > 0
          ? `${candidateDetails.recruiter?.first} ${candidateDetails.recruiter?.last}`
          : emptyString,
      availabilityDate:
        moment(availableOnStartValue).format('yyyy-MM-DD') !== 'Invalid date'
          ? moment(availableOnStartValue).format('yyyy-MM-DD')
          : availableOnStartValue,
      callBackDate:
        moment(callbackToValue).format('yyyy-MM-DD') !== 'Invalid date'
          ? moment(callbackToValue).format('yyyy-MM-DD')
          : callbackToValue,
      brandId: candidateDetails?.brandId,
      currentEmployeeId: user.userInfo?.employeeId || 8410,
      currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
      primaryPhoneNumber: candidateDetails.primaryPhoneNumber || '',
      mobilePhoneNumber: candidateDetails.mobilePhoneNumber || '',
      primaryEmail: candidateDetails.primaryEmail,
      secondaryEmail: candidateDetails.secondaryEmail,
      candidateStatusId: statusId,
      candidateStatus: status ? status.replace(' w/', ' with ') : status,
      inactivityReasonId: reason !== '' ? reasonId : null,
      inactivityReason: reason !== '' ? reason : null,
      timestamp: candidateDetails?.amieTravelerTimestamp,
      notes: candidateDetails.candidateSummary ? candidateDetails.candidateSummary : '',
    };
    setIsUpdateHeader(false);
    setShowReasonPopup(false);
    postUpdatedHeaderDetails(updatedHeaderDetails);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getRecruiterId = (name: string) => {
    return name?.length > 0 ? parseInt(`${recruiterSearchList.filter(x => x.name === name)[0]?.value ?? 0}`) : 0;
  };

  useEffect(() => {
    setIsRecruiter(
      Authorized(
        [userRoles.recruitment, userRoles.recruitment_TeamMember, userRoles.recruitment_Leadership],
        user.userInfo,
      ),
    );
  }, [user.userInfo]);

  const postUpdatedHeaderDetails = async (data: ICandidateHeaderDetails) => {
    const result = await updateHeaderDetails(data);
    if (result && result !== '') {
      setEnableRecruiterEdit(false);
      setEnableGoesByEdit(false);
      setEnableCallbackToEdit(false);
      setEnableAvailableOnStartEdit(false);
      dispatch(
        candidateDetailActions.setCandidateDetails({
          ...candidateDetails,
          inactivityReasonId: data.inactivityReasonId,
          inactivityReason: data.inactivityReason,
          candidateStatusId: data.candidateStatusId,
          candidateStatus: allCandidateStatuses.filter(x => x.value === data.candidateStatusId)[0].name,
          candidateSummary: data.notes ? data.notes : '',
          goesBy: data.goesBy,
          amieTravelerTimestamp: result.timestamp,
          recruiter:
            data.recruiterName === ''
              ? { first: '', last: '', recruiterId: data.recruiterId, email: '' }
              : {
                  ...candidateDetails.recruiter,
                  first: data.recruiterName?.split(' ')?.[0],
                  last: data.recruiterName?.split(' ')?.[1],
                  recruiterId: data.recruiterId,
                },
        }),
      );
      dispatch(
        globalActions.setSnackBar({
          severity: 'success',
          message: t('candidate.candidateHeader.updateCandidateDetails'),
        }),
      );
    }
  };

  const availableOnMoment = GetMomentOfDate(candidateDetails?.availableOn);
  const closeProfileDrawer = () => {
    resetFields(candidateDetails);
    dispatch(globalActions.setDetailsPageDrawer({ open: false }));
    dispatch(
      notificationDataActions.setFilters({
        channel: [],
        initiatedBy: '',
      }),
    );
  };

  useEffect(() => {
    if (
      notificationData.email.open ||
      notificationData.voice.open ||
      notificationData.sms.open ||
      notificationData.log.open
    ) {
      setShowNotificationBackdrop(false);
    }
  }, [notificationData]);
  
  useEffect(() => {
    setAddressSkillsetBar();
  }, [displayChips, width]);

  const setNotificationStatus = channel => {
    switch (channel) {
      case 'email':
        setShowNotificationBackdrop(!(notificationData.email.open || notificationData.email.open));
        break;
      case 'voice':
        setShowNotificationBackdrop(!(notificationData.voice.open || notificationData.email.open));

        break;
      case 'sms':
        setShowNotificationBackdrop(!(notificationData.sms.open || notificationData.email.open));

        break;
      case 'log':
        setShowNotificationBackdrop(!(notificationData.log.open || notificationData.email.open));
        break;
    }
  };

  const handleNotificationData = async (
    channel: ChannelType,
    contactType: ContactType | null,
    manualLog: boolean = false,
    sendWithoutConsent: boolean = false,
  ) => {
    setNotificationStatus(channel);
    setNotificationData(
      user,
      user.userInfo?.phoneNumber,
      candidateDetails,
      dispatch,
      notificationData,
      channel,
      contactType,
      manualLog,
      skills,
      setShowNotificationBackdrop,
      sendWithoutConsent,
      coveredInbox,
    );
  };

  const skillsetChips = useMemo(() => {
    const chips: any = [];
    const verified: string[] = [];
    const unverified: string[] = [];
    candidateDetails?.skillSetTree?.candidateSkillSets?.forEach(discipline => {
      if (discipline.verified) {
        if (discipline.specialties?.filter(item => item.verified).length > 0) {
          discipline.specialties?.forEach(specialty => {
            if (specialty.verified) {
              if (specialty.subSpecilities?.filter(item => item.verified).length > 0) {
                specialty.subSpecilities?.forEach(subspecialty => {
                  if (subspecialty.verified) {
                    verified.push(`${discipline.abbreviation}-${specialty.description}, ${subspecialty.description}`);
                  }
                });
              } else {
                verified.push(`${discipline.abbreviation}-${specialty.description}`);
              }
            }
          });
        } else {
          verified.push(discipline.abbreviation);
        }
      }
      if (discipline.unverified) {
        if (discipline.specialties?.filter(item => item.unverified).length > 0) {
          discipline.specialties?.forEach(specialty => {
            if (specialty.unverified) {
              if (specialty.subSpecilities?.filter(item => item.unverified).length > 0) {
                specialty.subSpecilities?.forEach(subspecialty => {
                  if (subspecialty.unverified) {
                    unverified.push(`${discipline.abbreviation}-${specialty.description}, ${subspecialty.description}`);
                  }
                });
              } else {
                unverified.push(`${discipline.abbreviation}-${specialty.description}`);
              }
            }
          });
        } else {
          unverified.push(discipline.abbreviation);
        }
      }
    });

    let displayChips = [];
    verified.map((item, index) => {
      chips.push(
        <CustomTooltip tooltipContent={item} standardMargin>
          <Chip
            className={`${classes.chip} ${classes.chipToolTip}`}
            variant={'outlined'}
            avatar={
              <div className={classes.verifiedIcon}>
                <img src={VerifiedIcon} alt="Verified" />
              </div>
            }
            label={item}
            key={index}
          />
        </CustomTooltip>,
      );
      displayChips.push(item);
    });
    unverified.map((item, index) => {
      chips.push(
        <CustomTooltip tooltipContent={item} standardMargin>
          <Chip
            className={`${classes.chip} ${classes.chipToolTip}`}
            variant={'outlined'}
            avatar={
              <div className={classes.verifiedIcon}>
                <img src={UnverifiedIcon} alt="UnVerified" />
              </div>
            }
            label={item}
            key={index}
          />
        </CustomTooltip>,
      );
      displayChips.push(item);
    });
    setDisplayChips(displayChips);

    if (chips.length > 4) {
      const tempChips = chips.slice(0, 4);
      const remainingChips = chips.slice(4, chips.length);
      tempChips.push(
        <Tooltip
          title={
            <Grid container direction="row">
              {remainingChips.map((item, index) => {
                return (
                  <Grid item style={{ padding: '5px 0px' }} key={index}>
                    {item}
                  </Grid>
                );
              })}
            </Grid>
          }
          arrow
          placement="bottom"
          classes={{
            tooltip: `${classes.tooltipBackground} ${classes.tooltipSize}`,
            arrow: classes.tooltipArrow,
          }}
        >
          <Chip className={classes.tooltipChip} variant={'outlined'} label={`+${remainingChips.length}`} />
        </Tooltip>,
      );
      return tempChips;
    }
    return chips;
  }, [candidateDetails?.skillSetTree?.candidateSkillSets]);

  const filterOptions = Object.values(allCandidateStatuses);
  const statusChangeReasonOptions = Object.values(statusChangeReasons);
  if (Authorized([userRoles.clinical_QualificationsSpecialist], user.userInfo)) {
    const temp = filterOptions[0];
    filterOptions[0] = filterOptions[5];
    filterOptions[5] = temp;
  }

  const checkAccesibility = (status: any) => {
    if (dropdownOptions) {
      return dropdownOptions.includes(status);
    }
  };

  const CandidateSatusControl = React.memo((props: any) => {
    return (
      <>
        <Select
          className={props.candidateStatus}
          inputProps={{ className: classes.selectFocus }}
          MenuProps={{
            classes: {
              paper:
                Authorized([userRoles.clinical_QualificationsSpecialist], user.userInfo) ||
                Authorized([userRoles.recruitment_Leadership, userRoles.recruitment_TeamMember], user.userInfo)
                  ? classes.dropDownStyle
                  : classes.dropdownStyle,
            },
          }}
          IconComponent={DropDownIcon}
          value={status}
          onChange={handleChange}
          disableUnderline={true}
          disabled={props.disabled}
        >
          {filterOptions.map((item, index) => {
            const hideStatus = status === item.name ? 'none' : '';
            return (
              <MenuItem
                className={classes.dropDownItem}
                style={{ display: hideStatus }}
                disableTouchRipple
                value={item.name}
                disabled={!checkAccesibility(item)}
                key={index}
              >
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </>
    );
  });

  const handleChangeField = (property, value) => {
    if (property === EditType.availableOn) {
      setAvailableOnStartValue(value);
      setSaveStatus(null);
    }
    if (property === EditType.callbackTo) {
      setCallbackToValue(value);
      setSaveStatus(null);
    }
    if (property === EditType.candidateSummary) {
      setCandidateSummaryValue(value ? value : '');
      setSaveStatus(null);
    }
    if (property === EditType.goesBy) {
      setGoesByValue(value ? value : '');
      setSaveStatus(null);
    }
    if (property === EditType.availableOnHeader) {
      setAvailableOnStartValueHeader(value);
      setSaveStatus(null);
    }
    if (property === EditType.callbackToHeader) {
      setCallbackToValueHeader(value);
      setSaveStatus(null);
    }
  };

  const postUpdatedDetails = async (candidate: ICandidateDetails, data: ICandidateHeaderDetails) => {
    const result = await updateHeaderDetails(data);
    if (result && result !== '') {
      dispatch(
        candidateDetailActions.setCandidateDetails({
          ...candidate,
          availableOn: data.availabilityDate,
          callBackDate: data.callBackDate,
          candidateSummary: data.notes ? data.notes : '',
          goesBy: data.goesBy,
          amieTravelerTimestamp: result.timestamp,
          recruiter:
            data.recruiterName === ''
              ? { first: '', last: '', recruiterId: data.recruiterId, email: '' }
              : {
                  ...candidateDetails.recruiter,
                  first: data.recruiterName?.split(' ')?.[0],
                  last: data.recruiterName?.split(' ')?.[1],
                  recruiterId: data.recruiterId,
                },
          candidateStatusId: data.candidateStatusId,
          candidateStatus: allCandidateStatuses.filter(x => x.value === data.candidateStatusId)[0].name,
          inactivityReason: data.inactivityReason,
          inactivityReasonId: data.inactivityReasonId,
        }),
      );
      setSaveStatus(2);
      setSavedValues({
        availableOn: availableOnStartValue,
        callbackTo: callbackToValue,
        candidateSummary: data.notes ? data.notes : '',
        goesBy: data.goesBy,
        recruiterValue: data.recruiterName,
        timestamp: result.timestamp,
        isInitialValue: false,
      });
    } else {
      setSaveStatus(3);
    }
  };

  useEffect(() => {
    if (!savedValues.isInitialValue) {
      setTimeout(() => {
        resetFields(candidateDetails);
      }, 5000);
    }
  }, [savedValues]);

  const resetFields = candidate => {
    setAvailableOnStartValue(savedValues.availableOn || candidate.availableOn);
    setAvailableOnStartValueHeader('');
    setClickCallbackTo(false);
    setSaveStatus(null);
    setClickCallbackToHeader(false);
    setClickAvailableOn(false);
    setClickAvailableOnHeader(false);

    setCallbackToValue(savedValues.callbackTo || candidate.callBackDate);
    setCallbackToValueHeader('');
    setCandidateSummaryValue(savedValues.candidateSummary || candidate.candidateSummary);
    setClickCandidateSummary(false);
    setGoesByValue(savedValues.goesBy || candidate.goesBy);
    setClickGoesBy(false);
    setRecruiterValue(
      recruiterValue?.length > 0
        ? savedValues.recruiterValue || Concatenate([candidate?.recruiter?.first, candidate?.recruiter?.last], ' ')
        : emptyString,
    );
    setRecruiterOpen(false);
  };

  const saveUpdatedDate = async (candidate: ICandidateDetails, data: ICandidateHeaderDetails) => {
    setSaveStatus(1);
    const result = await updateHeaderDetails(data);
    if (result && result !== '') {
      dispatch(
        candidateDetailActions.setCandidateDetails({
          ...candidate,
          availableOn: data.availabilityDate,
          callBackDate: data.callBackDate,
          amieTravelerTimestamp: result.timestamp,
          candidateSummary: data.notes ? data.notes : '',
        }),
      );
      setSaveStatus(2);
      setSavedValues({
        availableOn: availableOnStartValueHeader,
        callbackTo: callbackToValueHeader,
        candidateSummary: data.notes ? data.notes : null,
        goesBy: data.goesBy,
        recruiterValue: data.recruiterName,
        timestamp: result.timestamp,
        isInitialValue: false,
      });
    } else {
      setSaveStatus(3);
    }
  };

  const handleSave = async (data?: string, field?: string) => {
    if (field === 'recruiter' && !data && (!recruiterValue || isShowingBlank == true)) {
      setEmptyRecruiterName(true);
      return;
    }
    const statusId = allCandidateStatuses.filter(x => x.name === status)[0].value;
    setSaveStatus(1);
    const reasonId = getReasonId();

    if (availableOnStartValue !== 'Invalid Date' && callbackToValue !== 'Invalid Date') {
      setEmptyRecruiterName(false);
      const updatedHeaderDetails: ICandidateHeaderDetails = {
        candidateId: candidateDetails?.travelerId,
        goesBy:
          (data === '' || (data && data !== missingField)) && clickGoesBy
            ? data
            : savedValues.goesBy
            ? savedValues.goesBy
            : candidateDetails.goesBy,
        recruiterId:
          getRecruiterId(recruiterValue) !== 0
            ? getRecruiterId(recruiterValue)
            : candidateDetails?.recruiter?.recruiterId
            ? candidateDetails?.recruiter?.recruiterId
            : null,
        recruiterName:
          recruiterValue?.length > 0
            ? recruiterValue
            : candidateDetails?.recruiter?.first?.length > 0
            ? `${(candidateDetails?.recruiter?.first + ' ' + candidateDetails?.recruiter?.last || '').trim()}`
            : emptyString,
        availabilityDate:
          availableOnStartValue !== ''
            ? moment(availableOnStartValue).format('YYYY-MM-DDTHH:mm:ss') !== 'Invalid date'
              ? moment(availableOnStartValue).format('YYYY-MM-DDTHH:mm:ss')
              : availableOnStartValue
            : candidateDetails.availableOn,
        callBackDate:
          callbackToValue !== ''
            ? moment(callbackToValue).format('YYYY-MM-DDTHH:mm:ss') !== 'Invalid date'
              ? moment(callbackToValue).format('YYYY-MM-DDTHH:mm:ss')
              : callbackToValue
            : candidateDetails.callBackDate,
        brandId: candidateDetails?.brandId,
        currentEmployeeId: user.userInfo?.employeeId || 0,
        currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
        primaryPhoneNumber: candidateDetails.primaryPhoneNumber,
        mobilePhoneNumber: candidateDetails.mobilePhoneNumber,
        primaryEmail: candidateDetails.primaryEmail,
        secondaryEmail: candidateDetails.secondaryEmail?.length > 0 ? candidateDetails.secondaryEmail : '',
        candidateStatusId: statusId,
        candidateStatus: status ? status.replace(' w/', ' with ') : status,
        notes:
          (data === '' || (data && data !== missingField)) && !clickGoesBy
            ? data.replace(/\r?\n/g, '\r\n')
            : savedValues?.candidateSummary
            ? savedValues?.candidateSummary?.replace(/\r?\n/g, '\r\n')
            : candidateDetails?.candidateSummary?.replace(/\r?\n/g, '\r\n'),
        timestamp: candidateDetails?.amieTravelerTimestamp || savedValues.timestamp,
        inactivityReasonId: reason !== '' ? reasonId : null,
        inactivityReason: reason !== '' ? reason : null,
      };
      postUpdatedDetails(candidateDetails, updatedHeaderDetails);
    }
  };

  const getCandidateSummaryValue = candidate => {
    const summaryValue =
      candidateSummaryValue || savedValues.candidateSummary || candidate.candidateSummary || missingField;
    return summaryValue && summaryValue !== missingField ? summaryValue : missingField;
  };

  const handleHeaderUpdate = async (data?: string) => {
    const statusId = allCandidateStatuses.filter(x => x.name === status)[0].value;
    const reasonId = getReasonId();
    const recruiterIdValue = getRecruiterId(recruiterValue);
    const updatedHeaderDetails: ICandidateHeaderDetails = {
      candidateId: candidateDetails?.travelerId,
      goesBy: candidateDetails?.goesBy,
      recruiterId:
        recruiterIdValue !== 0
          ? recruiterIdValue
          : candidateDetails?.recruiter?.recruiterId
          ? candidateDetails?.recruiter?.recruiterId
          : null,
      recruiterName:
        candidateDetails?.recruiter?.first?.length > 0
          ? `${candidateDetails?.recruiter?.first} ${candidateDetails?.recruiter?.last}`
          : emptyString,
      availabilityDate: clickAvailableOnHeader
        ? moment(availableOnStartValueHeader).format('YYYY-MM-DDTHH:mm:ss') !== 'Invalid date'
          ? moment(availableOnStartValueHeader).format('YYYY-MM-DDTHH:mm:ss')
          : availableOnStartValueHeader
        : candidateDetails.availableOn,
      callBackDate: clickCallbackToHeader
        ? moment(callbackToValueHeader).format('YYYY-MM-DDTHH:mm:ss') !== 'Invalid date'
          ? moment(callbackToValueHeader).format('YYYY-MM-DDTHH:mm:ss')
          : callbackToValueHeader
        : candidateDetails.callBackDate,
      brandId: candidateDetails?.brandId,
      currentEmployeeId: user.userInfo?.employeeId || 0,
      currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
      primaryPhoneNumber: candidateDetails.primaryPhoneNumber,
      mobilePhoneNumber: candidateDetails.mobilePhoneNumber,
      primaryEmail: candidateDetails.primaryEmail,
      secondaryEmail: candidateDetails.secondaryEmail?.length > 0 ? candidateDetails.secondaryEmail : '',
      candidateStatusId: statusId,
      candidateStatus: status ? status.replace(' w/', ' with ') : status,
      notes: candidateDetails?.candidateSummary ? candidateDetails?.candidateSummary : '',
      timestamp: savedValues.timestamp || candidateDetails?.amieTravelerTimestamp,
      inactivityReasonId: reason !== '' ? reasonId : null,
      inactivityReason: reason !== '' ? reason : null,
    };
    saveUpdatedDate(candidateDetails, updatedHeaderDetails);
  };

  const getAddress = () => {
    return `${candidateDetails?.address?.address1 || ''}${!!candidateDetails?.address?.address1 ? ',' : ''} ${
      candidateDetails?.address?.address2 || ''
    }${!!candidateDetails?.address?.address2 ? ',' : ''} ${candidateDetails?.address?.city || ''}${
      !!candidateDetails?.address?.stateProvinceId ? ',' : ''
    } ${candidateDetails?.address?.stateProvinceId || ''} ${candidateDetails?.address?.zip || ''}${
      !!candidateDetails?.address?.zip ? ',' : ''
    } ${candidateDetails?.address?.country || ''}`;
  };

  const getCandidateTabs = candidate => {
    return [
      {
        name: 'Summary',
        kpiTiles: KPIs(candidate),
        tags: <TagsWrapper buttonId={'tags-side-panel-accessor'} />,
        info: {
          brand: candidate.brand,
          address: candidate.address,
          recruiter:
            candidate.recruiter?.first && candidate.recruiter?.last
              ? `${candidate.recruiter.first} ${candidate.recruiter.last}`
              : missingField,
          time: missingField,
        },
        detailsSection: [
          {
            info: t('candidate.sidePanel.callBack'),
            value: candidate.callBackDate
              ? moment(new Date(candidate.callBackDate)).format('MM/DD/YYYY')
              : missingField,
            component: (
              <InlineEditText
                data={callbackToValue !== '' ? callbackToValue : candidate.callBackDate ? candidate.callBackDate : null}
                name={EditType.callbackTo}
                saveStatus={saveStatus}
                isHandleClick={clickCallbackTo}
                isEditable={true}
                type={'date'}
                disabled={false}
                setIsHandleClick={value => setClickCallbackTo(value)}
                handleChangeValue={handleChangeField}
                handleSave={handleSave}
                reset={() => resetFields(candidate)}
                customDateRangePresets={defaultDateRange}
                isHidden={isCallbackHidden}
                setIsHidden={setIsCallbackHidden}
              />
            ),
          },
          {
            info: t('candidate.sidePanel.lastContacted'),
            value: candidate.lastContactedOn
              ? moment(new Date(candidate.lastContactedOn)).format('MM/DD/YYYY')
              : missingField,
          },
          {
            info: t('candidate.sidePanel.availableToStart'),
            value: candidate.availableOn ? moment(new Date(candidate.availableOn)).format('MM/DD/YYYY') : missingField,
            component: (
              <InlineEditText
                data={
                  availableOnStartValue !== ''
                    ? availableOnStartValue
                    : candidate.availableOn
                    ? candidate.availableOn
                    : null
                }
                name={EditType.availableOn}
                saveStatus={saveStatus}
                isHandleClick={clickAvailableOn}
                isEditable={true}
                type={'date'}
                disabled={false}
                setIsHandleClick={value => setClickAvailableOn(true)}
                handleChangeValue={handleChangeField}
                handleSave={handleSave}
                reset={() => resetFields(candidate)}
                customDateRangePresets={defaultDateRange}
                isHidden={isAvailablilityHidden}
                setIsHidden={setIsAvailablilityHidden}
              />
            ),
          },
          {
            info: t('candidate.sidePanel.lastAssignmentEnded'),
            value: lastAssignmentEnded(candidate.activePlacements, candidate.placement?.placementInfo) || missingField,
          },
          {
            info: t('candidate.sidePanel.nextPlacementStarts'),
            value: nextPlacementStartDate(candidate.activePlacements),
          },
          {
            info: t('candidate.sidePanel.lastApplicationDate'),
            value: candidate.applicationDate
              ? moment(new Date(candidate.applicationDate)).format('MM/DD/YYYY') || missingField
              : missingField,
          },
        ],
        additionalSection: [
          {
            header: t('candidate.sidePanel.summary'),
            editable: true,
            open: clickCandidateSummary,
            onClickEdit: value => {
              setClickCandidateSummary(true);
            },
            comments: '',
            summary: candidateSummaryValue?.replace(/(?:\\[n])+/g, `\n`)?.replace(/(?:\\[r])+/g, `\r`),
            component: (
              <InlineEditText
                data={getCandidateSummaryValue(candidate) === missingField ? '' : getCandidateSummaryValue(candidate)}
                name={EditType.candidateSummary}
                saveStatus={saveStatus}
                isHandleClick={clickCandidateSummary}
                isEditable={true}
                type={'text'}
                disabled={false}
                setIsHandleClick={value => {}}
                handleChangeValue={handleChangeField}
                handleSave={handleSave}
                reset={() => resetFields(candidate)}
              />
            ),
          },
        ],
        summary: 'summary',
      },
      {
        name: t('candidate.sidePanel.contact'),
        summary: 'contact',
        component: (
          <ContactTab
            onClose={closeProfileDrawer}
            isSearchGrid={false}
            candidateDetails={candidateDetails}
            handleNotificationData={handleNotificationData}
            isViewDetails={false}
            consentsData={consentsData}
          />
        ),
      },
      {
        name: t('candidate.sidePanel.notes'),
        summary: 'notes',
      },
    ];
  };

  const getCandidateDescriptionHeader = candidate => {
    return {
      imageSrc: '',
      name: `${candidate.name?.first} ${candidate.name?.last}`,
      relationSets: candidateSkills(candidate.verifiedSkills),
      status: getCandidateStatus(candidate.candidateStatusId, candidate.candidateStatus),
      passport: candidate.hasAcceptedMobileTermsAndConditions === 'Y',
      operations: [
        {
          value:
            candidate?.phoneNumbers && candidate?.phoneNumbers?.length > 0 ? (
              <CommunicationStack
                channelType={ChannelType.voice}
                phoneNumbers={candidate?.phoneNumbers || []}
                backDrop={flag => setShowNotificationBackdrop(flag)}
                showWarningMessage={!hasCallConsents}
                candidateProfile
              />
            ) : (userPreference && userPreference?.deviceList && userPreference.deviceList?.length === 0) ||
              userPreference.deviceLine?.length === 0 ? (
              t('notification.consents.noDevice')
            ) : (
              t('notification.consents.noNumber')
            ),
          icon:
            (candidate.primaryPhoneNumber || candidate.mobilePhoneNumber) && !consentsData?.hasCallConsents
              ? phoneWithdrawYellow
              : PhoneIconOutline,
          type: OperationType.Phone,
          disabled: !candidate.primaryPhoneNumber && !candidate.mobilePhoneNumber,
          handler: () => {
            if (
              candidate?.primaryPhoneNumber &&
              userPreference &&
              userPreference?.deviceList &&
              userPreference?.deviceList?.length !== 0 &&
              userPreference?.deviceLine.length
            ) {
              handleNotificationData(ChannelType.voice, ContactType.primary);
            } else if (
              candidate?.mobilePhoneNumber &&
              userPreference &&
              userPreference?.deviceList &&
              userPreference?.deviceList.length !== 0 &&
              userPreference?.deviceLine.length !== 0
            ) {
              handleNotificationData(ChannelType.voice, ContactType.secondary);
            }
          },
        },
        {
          value:
            candidate.primaryEmail || candidate.secondaryEmail ? (
              <EmailStack
                emails={candidateEmails}
                showWarningMessage={!hasEmailConsents}
                backDrop={flag => setShowNotificationBackdrop(flag)}
                candidateProfile
              />
            ) : (
              t('notification.consents.noEmail')
            ),
          icon:
            (candidate.primaryEmail || candidate.secondaryEmail) && !consentsData?.hasEmailConsents
              ? emailWithdrawYellow
              : MailBoxIconOutline,
          type: OperationType.Mail,
          disabled: !candidate.primaryEmail && !candidate.secondaryEmail,
          handler: () => {
            if (candidate.primaryEmail) {
              handleNotificationData(ChannelType.email, ContactType.primary);
            } else if (candidate.secondaryEmail) {
              handleNotificationData(ChannelType.email, ContactType.secondary);
            }
          },
        },
        {
          value:
            user.userInfo && (!user.userInfo.phoneNumber || user.userInfo.phoneNumber === '') ? (
              t('notification.consents.textingDisabled')
            ) : candidate?.phoneNumbers && candidate?.phoneNumbers?.length > 0 ? (
              <CommunicationStack
                channelType={ChannelType.sms}
                phoneNumbers={candidate?.phoneNumbers || []}
                backDrop={flag => setShowNotificationBackdrop(flag)}
                showWarningMessage={!hasCallConsents}
                candidateProfile
              />
            ) : (
              t('notification.consents.noNumber')
            ),
          icon:
            (candidate.primaryPhoneNumber || candidate.mobilePhoneNumber) && !consentsData?.hasTextConsents
              ? smsWithdrawYellow
              : MessageIconOutline,
          type: OperationType.Message,
          disabled: !candidate.primaryPhoneNumber && !candidate.mobilePhoneNumber,
          handler: () => {
            if (candidate.primaryPhoneNumber) {
              handleNotificationData(ChannelType.sms, ContactType.primary);
            } else if (candidate.mobilePhoneNumber) {
              handleNotificationData(ChannelType.sms, ContactType.secondary);
            }
          },
        },
      ],
    };
  };

  const getCandidateStatus = (statusId, statusName) => {
    if ([CandidateStatus.ACTIVE, CandidateStatus.ACTIVE_WITH_AUDIT].includes(statusId)) {
      return {
        title: statusName,
        color: 'rgb(95, 176, 87)',
        background: 'rgba(95, 176, 87, 0.2)',
      };
    } else if ([CandidateStatus.TERMINATE, CandidateStatus.PENDING_WITH_AUDIT].includes(statusId)) {
      return {
        title: statusName,
        color: 'rgb(171,0,0)',
        background: 'rgba(171,0,0, 0.2)',
      };
    } else if (
      [
        CandidateStatus.PENDING,
        CandidateStatus.HOLDING,
        CandidateStatus.APPLICATION,
        CandidateStatus.TERM_HOLD,
      ].includes(statusId)
    ) {
      return {
        title: statusName,
        color: 'rgb(82, 66, 154)',
        background: 'rgba(82, 66, 154, 0.2)',
      };
    } else {
      return {
        title: statusName,
        color: 'rgb(136,136,136)',
        background: 'rgba(136,136,136, 0.2)',
      };
    }
  };

  const candidateSkills = skillset => {
    const verifiedSkills: string[] = [];
    const verifyDuplicateSkills: Map<string, boolean> = new Map<string, boolean>();
    skillset?.forEach(skill => {
      let res = `${skill.disciplineAbbr}-${skill.specialtyAbbr}`;
      if (skill.subSpecialtyAbbr) res += ', ' + skill.subSpecialtyAbbr;
      if (!verifyDuplicateSkills.has(res)) {
        verifiedSkills.push(res);
        verifyDuplicateSkills.set(res, true);
      }
    });
    return verifiedSkills;
  };

  const KPIs = candidate => {
    const kpis: string[] = [];
    const totalExperienceData = getExperienceData(
      workExperienceData?.candidateWorkExperience?.totalYearsOfExp,
      workExperienceData?.candidateWorkExperience?.totalMonthsOfExp,
    );
    kpis.push(`Experience=${workExperienceData?.skillsets?.length > 0 ? totalExperienceData : missingField}`);
    kpis.push(`Placements=${candidate.activePlacements ? `${candidate.activePlacements.length}` : missingField}`);
    kpis.push(
      `Booked=${
        candidate.placement?.weeksBooked
          ? candidate.placement?.weeksBooked !== 0
            ? `${candidate.placement?.weeksBooked}W`
            : '0'
          : missingField
      }`,
    );
    return kpis;
  };

  const getLastEndedActivePlacementDate = activePlacements => {
    const sortedActivePlacement = _.cloneDeep(candidateDetails?.activePlacements)?.sort((a, b) => {
      return moment(new Date(a.startDate)).isBefore(moment(new Date(b.startDate))) ? -1 : 1;
    });
    if (sortedActivePlacement && sortedActivePlacement?.length > 0) {
      return sortedActivePlacement[sortedActivePlacement?.length - 1]
        ? moment(new Date(sortedActivePlacement[sortedActivePlacement?.length - 1].endDate)).format('MM/DD/YYYY')
        : missingField;
    }
    return missingField;
  };

  const getLastPlacementEndDate = placementInfo => {
    const sortedPlacementInfo = _.cloneDeep(placementInfo)?.sort((a, b) => {
      return moment(new Date(a.startDate)).isBefore(moment(new Date(b.startDate))) ? -1 : 1;
    });
    if (sortedPlacementInfo && sortedPlacementInfo?.length > 0) {
      return sortedPlacementInfo[sortedPlacementInfo?.length - 1].endDate
        ? moment(new Date(sortedPlacementInfo[sortedPlacementInfo?.length - 1].endDate)).format('MM/DD/YYYY')
        : missingField;
    }
    return missingField;
  };

  const lastAssignmentEnded = (activePlacements, placementInfo) =>
    activePlacements && activePlacements.length > 0
      ? getLastEndedActivePlacementDate(activePlacements)
      : getLastPlacementEndDate(placementInfo);

  const nextPlacementStartDate = activePlacements => {
    const sortedPlacementInfo = _.cloneDeep(activePlacements)?.sort((a, b) => {
      return moment(new Date(a.startDate)).isBefore(moment(new Date(b.startDate))) ? -1 : 1;
    });
    if (sortedPlacementInfo && sortedPlacementInfo?.length > 0) {
      const nextPlacementDateDifference =
        (new Date(sortedPlacementInfo[0]?.endDate) as any) - (new Date(sortedPlacementInfo[0]?.startDate) as any);
      return new Date(nextPlacementDateDifference).getUTCDate() - 1 > 1
        ? moment(new Date(sortedPlacementInfo[0].startDate)).format('MM/DD/YYYY') || missingField
        : missingField;
    }
    return missingField;
  };

  const setAddressSkillsetBar = () => {    
    const address = getAddress();
    const chipLength = calculateDisplayChipsLength(displayChips);

    const eachCarLength = 10;
    const eachBoxLength = 90;
    const chipPixelLength = eachCarLength * chipLength;
    const requiredBoxSize = Math.sqrt(Math.pow(chipPixelLength / eachBoxLength, 2));
    setShowAddressTooltip(false);

    if (requiredBoxSize <= 2) {
      setShowAddressTooltip(true);
      setSkillsetWidth(2);
      setAddressBarWidth(10);
      setAddressWidth('80%');
      setSkillsetChipWidth('85%');
    } else if (requiredBoxSize > 2 && requiredBoxSize <= 8) {
      setSkillsetWidth(requiredBoxSize);
      setAddressBarWidth(12 - requiredBoxSize);
      setAddressWidth('85%');
      setSkillsetChipWidth('85%');
      if (address && address.length > 80) {
        setShowAddressTooltip(true);
      }
      else {
        setAddressTooltip(requiredBoxSize, address);
      }
    } else if (requiredBoxSize > 8 && requiredBoxSize <= 14) {
      setSkillsetWidth(8);
      setAddressBarWidth(4);
      setAddressWidth('82%');
      setSkillsetChipWidth('85%');
      if (address && address.length > 80) {
        setShowAddressTooltip(true);
      }
      else {
        setAddressTooltip(8, address);
      }
    } else if (requiredBoxSize > 14) {
      setSkillsetWidth(9);
      setAddressBarWidth(3);
      setAddressWidth('76%');
      setSkillsetChipWidth('85%');
      if (address && address.length > 60) {
        setShowAddressTooltip(true);
      }
      else {
        setAddressTooltip(9, address);
      }
    } else {
      setSkillsetWidth(6);
      setAddressBarWidth(6);
      setAddressWidth('85%');
      setSkillsetChipWidth('85%');
      if (address && address.length > 80) {
        setShowAddressTooltip(true);
      }
    }

  };

  const setAddressTooltip = (skillsetBarWidth, address) => {
    if (skillsetBarWidth >= 8 && width > 1500 && width < 1750 && address && address.length > 40) {
      setShowAddressTooltip(true);
    }
    if (skillsetBarWidth >= 8 && width > 1750 && address && address.length > 48) {
      setShowAddressTooltip(true);
    }
  };

  /**
   * Method to open manage skill set modal
   */
  const handleManageSkillSetClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElManageSkillSet(event.currentTarget);
  };

  const formatRecruiterOptions = (options: any[]) => {
    const formatOptions = options.map(item => {
      const option: ITypeAheadOption = {
        object: item,
        label: item,
      };
      return option;
    });

    if (recruiter && !formatOptions?.find(item => item.label === recruiter)) {
      formatOptions.push({
        object: recruiter,
        label: recruiter,
      });
    }
    return formatOptions;
  };

  const renderBrandValue = () => {
    const brand = candidateDetails?.brand || '';
    const divisionType = candidateDetails?.divisionType || '';

    const totalLength = brand.length + divisionType.length;

    if (totalLength > 23) {
      let trimmedBrand = brand;
      let trimmedDivisionType = divisionType;

      if (trimmedBrand.length > trimmedDivisionType.length) {
        trimmedBrand = trimmedBrand.substring(0, 23 - trimmedDivisionType.length);
      } else {
        trimmedDivisionType = trimmedDivisionType.substring(0, 23 - trimmedBrand.length);
      }

      return (
        <Tooltip title={`${brand}${divisionType ? ', ' : ''}${divisionType}`}>
          <span>
            {trimmedDivisionType}
            {trimmedBrand && ', '}
            {trimmedBrand}
          </span>
        </Tooltip>
      );
    }

    return (
      <>
        <span>
          {divisionType}
          {divisionType && ', '}
        </span>
        <span>{brand}</span>
      </>
    );
  };

  return (
    <>
      <CustomBackdrop open={showNotificationBackdrop} />
      <Grid container xs={12} direction="row" classes={{ container: classes.container }} data-testid="table-test-id">
        <Grid item direction="column" classes={{ root: classes.containerAvatar }}>
          <Grid item direction="row" classes={{ root: classes.myAvatarAlignment }}>
            {promiseInProgress ? (
              <Skeleton variant="rectangular" width={200} height={50} />
            ) : (
              <HeaderButton
                headerAbbr={avatarText}
                headerId={`CID ${candidateDetails.travelerId}`}
                headerAbbrBackground="#34B233"
                headerIdBackground="#237422"
              />
            )}
          </Grid>
          <Grid item classes={{ root: classes.contactDrawer }}>
            {promiseInProgress ? (
              <Skeleton variant="rectangular" width={200} height={35} />
            ) : (
              <Button
                variant="outlined"
                color="primary"
                className={classes.userIcon}
                startIcon={<PersonIcon classes={{ root: classes.customFontSize }} />}
                onClick={() => {
                  dispatch(globalActions.setSmsIconDrawer({ open: false }));
                  dispatch(globalActions.resetAllRecentHistoryDrawers());
                  dispatch(globalActions.setDetailsPageDrawer({ open: true }));
                }}
                id={'CandidateContactSidePanelOpenTrigger'}
              >
                <span className={classes.buttonText}>{t('Contact')}</span>
              </Button>
            )}
          </Grid>
        </Grid>

        <Grid item direction="column" classes={{ root: classes.container }} sx={{ width: '100%' }}>
          {promiseInProgress ? (
            <Skeleton variant="rectangular" width={'25%'} height={24} sx={{ marginLeft: 1 }} />
          ) : (
            <Grid item direction="row">
              <Box className={classes.title} onClick={_onNameClick} id={'CandidateNameLbl'}>
                {candidateName}
              </Box>
            </Grid>
          )}
          <Grid item direction="row" classes={{ root: classes.headerInfo }}>
            <Grid item direction="column" className={classes.goesBy}>
              {promiseInProgress ? (
                <Skeleton variant="rectangular" height={20} sx={{ margin: 1 }} />
              ) : (
                <table>
                  <ResponsiveColumnContainer
                    rowHead={t('candidate.candidateHeader.goesBy')}
                    rowContent={
                      <InlineTextInput
                        data={goesByValue || candidateDetails.goesBy}
                        name={EditType.goesBy}
                        saveStatus={saveStatus}
                        isHandleClick={clickGoesBy}
                        isEditable={true}
                        type={'text'}
                        disabled={false}
                        setIsHandleClick={value => {
                          setClickGoesBy(value);
                        }}
                        handleChangeValue={handleChangeField}
                        handleSave={handleSave}
                        reset={() => resetFields(candidateDetails)}
                        customClasses={classes.goesByTextArea}
                      />
                    }
                    classes={classes}
                    id={'CandidateHeaderGoesByLbl'}
                  />
                </table>
              )}
              {promiseInProgress ? (
                <Skeleton variant="rectangular" height={40} sx={{ margin: 1 }} />
              ) : (
                <table>
                  <ResponsiveColumnContainer
                    rowHead={
                      <LayoutGrid direction="row" classes={{ container: classes.container }}>
                        <LayoutGridItem>
                          {candidateDetails?.primaryPhoneNumber ? (
                            <div
                              role="button"
                              tabIndex={-1}
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => handleNotificationData(ChannelType.voice, ContactType.primary, true)}
                              onKeyPress={() => handleNotificationData(ChannelType.voice, ContactType.primary, true)}
                            >
                              <img src={AddIcCallIcon} alt={t('logcall')} />
                            </div>
                          ) : candidateDetails?.mobilePhoneNumber ? (
                            <div
                              role="button"
                              tabIndex={-1}
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => handleNotificationData(ChannelType.voice, ContactType.secondary, true)}
                              onKeyPress={() => handleNotificationData(ChannelType.voice, ContactType.secondary, true)}
                            >
                              <img src={AddIcCallIcon} alt={t('logcall')} />
                            </div>
                          ) : (
                            <div
                              role="button"
                              tabIndex={-1}
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => handleNotificationData(ChannelType.voice, null, true)}
                              onKeyPress={() => handleNotificationData(ChannelType.voice, null, true)}
                            >
                              <img src={AddIcCallIcon} alt={t('logcall')} />
                            </div>
                          )}
                        </LayoutGridItem>
                        <LayoutGridItem className={classes.gridIcons}>
                          {enableVoiceCommunication ? (
                            <Tooltip
                              title={
                                <span className={classes.tooltip}>
                                  {userPreference &&
                                  userPreference?.deviceList &&
                                  userPreference?.deviceList?.length === 0 ? (
                                    <>
                                      <span>{t('notification.consents.noDevice')}</span>
                                    </>
                                  ) : candidateDetails?.phoneNumbers && candidateDetails?.phoneNumbers?.length > 0 ? (
                                    <>
                                      <CommunicationStack
                                        phoneNumbers={candidateDetails.phoneNumbers}
                                        channelType={ChannelType.voice}
                                        backDrop={flag => setShowNotificationBackdrop(flag)}
                                        showWarningMessage={!hasCallConsents}
                                        candidateProfile
                                      />
                                    </>
                                  ) : (
                                    <span>{t('notification.consents.noNumber')}</span>
                                  )}
                                </span>
                              }
                              arrow
                              placement="bottom"
                              classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
                            >
                              {userPreference?.deviceList?.length !== 0 && candidateDetails?.primaryPhoneNumber ? (
                                <img
                                  src={hasCallConsents ? PhoneIcon : phoneWithdrawYellow}
                                  alt="Phone"
                                  style={{
                                    cursor: 'pointer',
                                    marginLeft: '5px',
                                  }}
                                  className={classes.withdrawIcons}
                                  onClick={() => handleNotificationData(ChannelType.voice, ContactType.primary)}
                                />
                              ) : userPreference?.deviceList?.length !== 0 && candidateDetails?.mobilePhoneNumber ? (
                                <img
                                  src={hasCallConsents ? PhoneIcon : phoneWithdrawYellow}
                                  className={classes.withdrawIcons}
                                  alt="Phone"
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => handleNotificationData(ChannelType.voice, ContactType.secondary)}
                                />
                              ) : (
                                <img src={PhoneDisabled} alt="Phone disabled" />
                              )}
                            </Tooltip>
                          ) : (
                            <img src={PhoneDisabled} alt="Phone disabled" />
                          )}
                        </LayoutGridItem>
                        <LayoutGridItem className={classes.gridIcons}>
                          {enableEmailCommunication ? (
                            <div
                              onMouseEnter={() => setOpenEmailTooltip(true)}
                              onMouseLeave={() => setOpenEmailTooltip(false)}
                            >
                              <Tooltip
                                title={
                                  <span className={classes.tooltip}>
                                    {candidateDetails?.primaryEmail || candidateDetails?.secondaryEmail ? (
                                      <>
                                        <EmailStack
                                          emails={candidateEmails}
                                          showWarningMessage={!hasEmailConsents}
                                          onClose={() => setOpenEmailTooltip(false)}
                                          candidateProfile
                                        />
                                      </>
                                    ) : (
                                      <span>{t('notification.consents.noEmail')}</span>
                                    )}
                                  </span>
                                }
                                disableHoverListener={
                                  !candidateDetails?.primaryEmail && !candidateDetails?.secondaryEmail
                                }
                                arrow
                                open={openEmailTooltip}
                                placement="bottom"
                                classes={{
                                  tooltip: classes.tooltipBackground,
                                  arrow: classes.tooltipArrow,
                                  popper: classes.tooltipPopper,
                                }}
                              >
                                {candidateDetails?.primaryEmail ? (
                                  <img
                                    src={hasEmailConsents ? MailBoxIcon : emailWithdrawYellow}
                                    alt="MailBox"
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                    className={classes.withdrawIcons}
                                    onClick={() =>
                                      handleNotificationData(ChannelType.email, ContactType.primary, false, true)
                                    }
                                  />
                                ) : candidateDetails?.secondaryEmail ? (
                                  <img
                                    src={hasEmailConsents ? MailBoxIcon : emailWithdrawYellow}
                                    alt="MailBox"
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                    className={classes.withdrawIcons}
                                    onClick={() =>
                                      handleNotificationData(ChannelType.email, ContactType.secondary, false, true)
                                    }
                                  />
                                ) : (
                                  <img src={EmailDisabled} alt="Email disabled" />
                                )}
                              </Tooltip>
                            </div>
                          ) : (
                            <img src={EmailDisabled} alt="Email disabled" />
                          )}
                        </LayoutGridItem>
                        <LayoutGridItem className={classes.gridIcons}>
                          {enableSmsCommunication ? (
                            !user.userInfo?.phoneNumber ? (
                              <MessageDisabledTooltip>
                                <img src={MessageDisabled} alt="Message disabeld" />
                              </MessageDisabledTooltip>
                            ) : (
                              <Tooltip
                                title={
                                  <span className={classes.tooltip}>
                                    {candidateDetails?.phoneNumbers && candidateDetails?.phoneNumbers?.length > 0 ? (
                                      <>
                                        <CommunicationStack
                                          phoneNumbers={candidateDetails.phoneNumbers}
                                          channelType={ChannelType.sms}
                                          backDrop={flag => setShowNotificationBackdrop(flag)}
                                          showWarningMessage={!hasTextConcents}
                                          candidateProfile
                                        />
                                      </>
                                    ) : (
                                      <span>{t('notification.consents.noNumber')}</span>
                                    )}
                                  </span>
                                }
                                arrow
                                placement="bottom"
                                classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
                              >
                                {candidateDetails?.primaryPhoneNumber ? (
                                  <img
                                    src={hasTextConcents ? MessageIcon : smsWithdrawYellow}
                                    alt="Message"
                                    className={classes.withdrawIcons}
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => handleNotificationData(ChannelType.sms, ContactType.primary)}
                                  />
                                ) : candidateDetails?.mobilePhoneNumber ? (
                                  <img
                                    src={hasTextConcents ? MessageIcon : smsWithdrawYellow}
                                    alt="Message"
                                    className={classes.withdrawIcons}
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => handleNotificationData(ChannelType.sms, ContactType.secondary)}
                                  />
                                ) : (
                                  <img src={MessageDisabled} alt="Message disabled" />
                                )}
                              </Tooltip>
                            )
                          ) : (
                            <img src={MessageDisabled} alt="Message disabled" />
                          )}
                        </LayoutGridItem>
                      </LayoutGrid>
                    }
                    classes={classes}
                    customRowHeadClass={classes.contactIcons}
                  />
                </table>
              )}
            </Grid>
            <Divider orientation="vertical" flexItem classes={{ root: classes.divider }} />
            <Grid item direction="column" className={classes.dateContent}>
              {promiseInProgress ? (
                <Skeleton variant="rectangular" height={70} sx={{ margin: 1 }} />
              ) : (
                <table>
                  <ResponsiveColumnContainer
                    rowHead={t('candidate.candidateHeader.availableToStart')}
                    rowContent={
                      <InlineEditText
                        data={
                          availableOnStartValueHeader !== ''
                            ? availableOnStartValueHeader
                            : candidateDetails.availableOn
                            ? candidateDetails.availableOn
                            : null
                        }
                        name={EditType.availableOnHeader}
                        saveStatus={saveStatus}
                        isHandleClick={clickAvailableOnHeader}
                        isEditable={true}
                        type={'date'}
                        disabled={false}
                        setIsHandleClick={value => setClickAvailableOnHeader(value)}
                        handleChangeValue={handleChangeField}
                        handleSave={handleHeaderUpdate}
                        reset={() => resetFields(candidateDetails)}
                        customDateRangePresets={defaultDateRange}
                        isHidden={isAvailablilityHiddenHeader}
                        setIsHidden={setIsAvailablilityHiddenHeader}
                        customClasses={classes.datepickerCustomeClass}
                      />
                    }
                    classes={classes}
                    minWidth={'md'}
                    id={'CandidateHeaderAvailableToStartLbl'}
                  />
                  <ResponsiveColumnContainer
                    rowHead={t('candidate.candidateHeader.availableToStart')}
                    rowContent={availableOnMoment.isValid() && availableOnMoment.format('MM/DD/YYYY')}
                    classes={classes}
                    minWidth={'xs'}
                    maxWidth={'sm'}
                    customRowHeadClass={`${classes.lowRatioLayout} ${classes.rowHeadDefaultColor}`}
                    customRowContentClass={`${classes.lowRatioLayout} ${classes.rowContentDefaultColor}`}
                    id={'CandidateHeaderAvailableToStartLbl'}
                  />
                  <ResponsiveColumnContainer
                    rowHead={t('candidate.candidateHeader.callBack')}
                    rowContent={
                      <InlineEditText
                        data={
                          callbackToValueHeader !== ''
                            ? callbackToValueHeader
                            : candidateDetails.callBackDate
                            ? candidateDetails.callBackDate
                            : null
                        }
                        name={EditType.callbackToHeader}
                        saveStatus={saveStatus}
                        isHandleClick={clickCallbackToHeader}
                        isEditable={true}
                        type={'date'}
                        disabled={false}
                        setIsHandleClick={value => setClickCallbackToHeader(value)}
                        handleChangeValue={handleChangeField}
                        handleSave={handleHeaderUpdate}
                        reset={() => resetFields(candidateDetails)}
                        customDateRangePresets={defaultDateRange}
                        isHidden={isCallbackHiddenHeader}
                        setIsHidden={setIsCallbackHiddenHeader}
                      />
                    }
                    classes={classes}
                    minWidth={'md'}
                    id={'CandidateHeaderCallBackDateLbl'}
                  />
                  <ResponsiveColumnContainer
                    rowHead={t('candidate.candidateHeader.callBack')}
                    rowContent={convertToFormat(candidateDetails?.callBackDate, 'MM/DD/YYYY')}
                    classes={classes}
                    minWidth={'xs'}
                    maxWidth={'sm'}
                    customRowHeadClass={`${classes.lowRatioLayout} ${classes.rowHeadDefaultColor}`}
                    customRowContentClass={`${classes.lowRatioLayout} ${classes.rowContentDefaultColor}`}
                    id={'CandidateHeaderCallBackDateLbl'}
                  />
                  <ResponsiveColumnContainer
                    rowHead={
                      <Grid container className={classes.statusContainer}>
                        <Grid xs={12} item>
                          {t('candidate.candidateHeader.candidateStatus')}
                        </Grid>
                        <Grid xs={12} item>
                          <FormControl fullWidth>
                            <CustomTooltip
                              standardMargin
                              tooltipContent={t(
                                readOnly ? 'readOnlyTooltip' : 'You do not have the permission to edit',
                              )}
                              disabled={(isEditAccess || false) && !readOnly}
                            >
                              <CandidateSatusControl
                                candidateStatus={
                                  candidateAuditDetails?.totals?.holdFileOut > 0
                                    ? classes.candidateStatusAuditFileHoldStyle
                                    : candidateAuditDetails?.totals?.ongoing > 0
                                    ? classes.candidateStatusAuditOngoingStyle
                                    : !isEditAccess || readOnly
                                    ? classes.disabledSelect
                                    : classes.candidateStatusDropdown
                                }
                                disabled={!isEditAccess || readOnly}
                              />
                            </CustomTooltip>
                          </FormControl>
                        </Grid>
                      </Grid>
                    }
                    classes={classes}
                    exact={'md'}
                    // customAlign={classes.alignContentCustom}
                    headColSpan={2}
                  />
                  <ResponsiveColumnContainer
                    rowHead={t('candidate.candidateHeader.recruiter')}
                    rowContent={recruiter}
                    classes={classes}
                    minWidth={'xs'}
                    maxWidth={'sm'}
                    customRowHeadClass={`${classes.lowRatioLayout} ${classes.rowHeadDefaultColor}`}
                    customRowContentClass={`${classes.lowRatioLayout} ${classes.rowContentDefaultColor}`}
                    id={'CandidateHeaderRecruiterLbl'}
                  />
                </table>
              )}
            </Grid>
            <MediaQuery minWidth={'md'}>
              <Divider orientation="vertical" flexItem classes={{ root: classes.divider }} />
            </MediaQuery>
            <Grid item direction="column" className={classes.brandInfo}>
              {promiseInProgress ? (
                <Skeleton variant="rectangular" sx={{ margin: 1 }} />
              ) : (
                <table>
                  <ResponsiveColumnContainer rowContent={renderBrandValue()} classes={classes} minWidth={'md'} />
                </table>
              )}
              {promiseInProgress ? (
                <Skeleton variant="rectangular" sx={{ margin: 1 }} />
              ) : (
                <table>
                  <ResponsiveColumnContainer
                    rowHead={t('candidate.candidateHeader.recruiter')}
                    rowContent={
                      !recruiterOpen ? (
                        <Box sx={{ display: 'flex' }}>
                          <Typography
                            onClick={event =>
                              !isRecruiter ? event.stopPropagation() : (setIsShowingBlank(true), setRecruiterOpen(true))
                            }
                            className={classes.name}
                          >
                            {recruiterValue || recruiter || missingField}
                          </Typography>
                          {!isRecruiter && (
                            <CustomTooltip tooltipContent={t('Cannot be edited')}>
                              <LockIcon className={classes.mticonstyle} />
                            </CustomTooltip>
                          )}
                        </Box>
                      ) : (
                        <>
                          <ManageRecruiter
                            showErrorMesssage={emptyRecruiterName}
                            open={true}
                            options={formatRecruiterOptions(recruiterOptions)}
                            handleSave={() => handleSave(undefined, 'recruiter')}
                            onChange={value => {
                              if (value?.label) {
                                setRecruiterValue(value.label);
                                setEmptyRecruiterName(false);
                              } else {
                                setRecruiterValue(emptyString);
                              }
                              setIsShowingBlank(false);
                            }}
                            saveStatus={saveStatus ? saveStatus - 1 : ''}
                            selected={recruiterValue || recruiter}
                            handleClose={() => {
                              setEmptyRecruiterName(false);
                              setRecruiterOpen(false);
                              resetFields(candidateDetails);
                            }}
                            saveBtnId="save-recruiter-profile-tab-btn"
                            closeBtnId="close-recruiter-profile-tab-btn"
                            updatedSearchList={updatedList => setRecruiterSearchList(updatedList)}
                          />
                        </>
                      )
                    }
                    classes={classes}
                    minWidth={'md'}
                    id={'CandidateHeaderRecruiterLbl'}
                  />
                </table>
              )}
              {promiseInProgress ? (
                <Skeleton variant="rectangular" sx={{ margin: 1 }} />
              ) : (
                <table>
                  <ResponsiveColumnContainer
                    rowHead={
                      <Grid container className={classes.statusContainer}>
                        <Grid xs={12} item>
                          {t('candidate.candidateHeader.placementStatus')}
                        </Grid>
                        <Grid xs={12} item>
                          <Avatar
                            variant="rounded"
                            className={classes.placementStatus}
                            id={'CandidateHeaderPlacementStatusLbl'}
                          >
                            {candidateDetails?.placementStatus}
                          </Avatar>
                        </Grid>
                      </Grid>
                    }
                    classes={classes}
                    exact={'md'}
                    // customAlign={classes.alignContentCustom}
                    headColSpan={2}
                  />
                </table>
              )}
            </Grid>
            <Divider orientation="vertical" flexItem classes={{ root: classes.divider }} />
            <Grid item direction="column" className={classes.status}>
              {promiseInProgress ? (
                <Skeleton variant="rectangular" height={70} sx={{ margin: 1 }} />
              ) : (
                <table>
                  <ResponsiveColumnContainer
                    rowHead={t('candidate.candidateHeader.candidateStatus')}
                    rowContent={
                      <FormControl fullWidth>
                        <Grid container>
                          <Grid item>
                            <CustomTooltip
                              standardMargin
                              tooltipContent={t(
                                readOnly ? 'readOnlyTooltip' : 'You do not have the permission to edit',
                              )}
                              disabled={(isEditAccess || false) && !readOnly}
                            >
                              <CandidateSatusControl
                                candidateStatus={
                                  candidateAuditDetails?.totals?.holdFileOut > 0
                                    ? classes.candidateStatusAuditFileHoldStyle
                                    : candidateAuditDetails?.totals?.ongoing > 0
                                    ? classes.candidateStatusAuditOngoingStyle
                                    : !isEditAccess || readOnly
                                    ? classes.disabledSelect
                                    : classes.candidateStatusDropdown
                                }
                                disabled={!isEditAccess || readOnly}
                              />
                            </CustomTooltip>
                          </Grid>
                          {candidateDetails.inactivityReasonId &&
                            (status === 'Inactive' || status === 'Inactive w/Audit') && (
                              <Grid item direction="column">
                                <CustomTooltip
                                  tooltipContent={getInactivityReason(candidateDetails.inactivityReasonId)}
                                >
                                  <img src={ReasonInfo} alt="Reason info" className={classes.reasonInfoIcon} />
                                </CustomTooltip>
                              </Grid>
                            )}
                          {/* Audit status file out  -show hand icon */}
                          {candidateAuditDetails?.totals?.holdFileOut > 0 && (
                            <Grid item direction="column" className={classes.failIconContainer}>
                              <FailIcon style={{ color: '#CC001C' }} />
                            </Grid>
                          )}
                        </Grid>
                      </FormControl>
                    }
                    classes={classes}
                    minWidth={'lg'}
                  />
                  <ResponsiveColumnContainer
                    rowHead={t('candidate.candidateHeader.placementStatus')}
                    rowContent={
                      <Avatar
                        variant="rounded"
                        className={classes.placementStatus}
                        id={'CandidateHeaderPlacementStatusLbl'}
                      >
                        {candidateDetails?.placementStatus}
                      </Avatar>
                    }
                    classes={classes}
                    minWidth={'lg'}
                  />
                </table>
              )}
            </Grid>

            {showTagsSection?.enabled && (
              <>
                <Divider orientation="vertical" flexItem classes={{ root: classes.divider }} />
                <Grid item classes={{ root: classes.tags }} direction="column">
                  {promiseInProgress ? (
                    <Skeleton variant="rectangular" height={70} sx={{ margin: 1, marginRight: 0 }} />
                  ) : (
                    <TagsWrapper candidateDetails={candidateDetails} />
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        {openProfileDrawer && (
          <div data-testid="candidate-side-panel">
            <SideDrawer
              topHeader={{
                title: '',
                panelControls: new Map<PanelControls, Function>([[PanelControls.Close, closeProfileDrawer]]),
              }}
              descHeader={getCandidateDescriptionHeader(candidateDetails)}
              tabs={getCandidateTabs(candidateDetails)}
              closeProfileDrawer={closeProfileDrawer}
              openProfileDrawer
              isDrawer={true}
              isCandidateHeader={true}
              setOpenCreatePlacementForm={setOpenCreatePlacementForm}
              consentsData={consentsData}
            />
            {openCreatePlacementForm && (
              <CreatePlacementWrapper
                isDefaultCandidate
                open
                handleClose={() => setOpenCreatePlacementForm(false)}
                initialArgs={{
                  candidateId: candidateDetails.travelerId,
                  brandId: candidateDetails.brandId,
                }}
              />
            )}
          </div>
        )}
      </Grid>
      <Grid
        container
        xs={12}
        direction="row"
        classes={{ container: `${classes.container} ${classes.containerSecond}` }}
      >
        <Grid item className={classes.myTableLayout}>
          <ResponsiveColumnContainer
            rowHead={
              <span className={classes.customInDemand}>
                <span className={classes.inDemand}>{`${candidateDetails?.divisionType},`}</span>
                <span className={classes.brand}>{candidateDetails?.brand}</span>
              </span>
            }
            classes={classes}
            minWidth={'xs'}
            maxWidth={'sm'}
            customAlign={classes.alignContentCustom}
          />
        </Grid>
        <Grid item className={classes.myTableLayout}>
          <ResponsiveColumnContainer
            rowHead={t('candidate.candidateHeader.candidateStatusSmall')}
            rowContent={
              <Avatar variant="rounded" className={`${classes.candidateStatus} ${classes.statusSecond}`}>
                {
                  <>
                    <span>{candidateDetails?.candidateStatus}</span>{' '}
                    <span>
                      <ExpandMoreIcon />
                    </span>
                  </>
                }
              </Avatar>
            }
            classes={classes}
            minWidth={'xs'}
            maxWidth={'sm'}
            customRowHeadClass={`${classes.lowRatioLayout} ${classes.rowHeadDefaultColor}`}
            customAlign={classes.alignContentCustom}
          />
        </Grid>
        <Grid item className={classes.myTableLayout}>
          <ResponsiveColumnContainer
            rowHead={t('candidate.candidateHeader.placementStatusSmall')}
            rowContent={
              <Avatar
                variant="rounded"
                className={`${classes.placementStatus} ${classes.statusSecond} ${classes.placementStatusText}`}
              >
                {candidateDetails?.placementStatus}
              </Avatar>
            }
            classes={classes}
            minWidth={'xs'}
            maxWidth={'sm'}
            customRowHeadClass={`${classes.lowRatioLayout} ${classes.rowHeadDefaultColor}`}
            customAlign={classes.alignContentCustom}
          />
        </Grid>
      </Grid>
      {promiseInProgress ? (
        <Skeleton variant="rectangular" width={'100%'} height={55} />
      ) : (
        <Grid
          container
          xs={12}
          direction="row"
          classes={{ container: `${classes.container} ${classes.actionContainer}` }}
        >
          <Grid item xs={addressBarWidth}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={0.7} className={classes.rowHeadCustom} style={{minWidth: 80}}>
                {t('candidate.candidateHeader.location')}
              </Grid>
              <Grid item xs={10.5} className={`${classes.rowContentCustom} ${classes.rowContentDefaultColor}`}  style={{maxWidth: addressWidth}}>
                {candidateDetails?.address?.address1 ? (
                  <CustomTooltip tooltipContent={showAddressTooltip ? getAddress() : ''}>
                    <div className={`${classes.addressText}`} >
                      {getAddress()}
                    </div>
                  </CustomTooltip>
                ) : (
                  missingField
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={skillsetWidth}>
            <Grid container direction="row" className={classes.rowHeadSkillsetBox}>
              <Grid item className={classes.rowHeadCustom} style={{ paddingRight: '10px', maxWidth: '110px' }}>
                {t('candidate.candidateHeader.skillset')}
              </Grid>
              {skillsetChips?.map((item, index) => {
                return (
                  <Grid
                    item
                    key={index}
                    className={classes.rowHeadSkillset}
                    style={{ maxWidth: index > 3 ? '65px' : calculateEachDisplayChipsLength(displayChips, skillsetChipWidth, index) }}
                  >
                    {item}
                  </Grid>
                );
              })}
              <Grid item style={{ alignSelf: 'center', minWidth: '2%' }}>
                <IconButton
                  color="primary"
                  aria-label="more"
                  aria-controls="SkillSet"
                  aria-haspopup="true"
                  classes={{ root: classes.iconButton }}
                  size="small"
                  onClick={handleManageSkillSetClick}
                >
                  <MoreVertIcon />
                </IconButton>
              </Grid>
              <Menu
                id="manage-skill-set-menu"
                sx={{ marginTop: '15px', padding: '8px 8px' }}
                anchorEl={anchorElManageSkillSet}
                open={Boolean(anchorElManageSkillSet)}
                onClose={() => setAnchorElManageSkillSet(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorElManageSkillSet(null);
                    setOpenSkillSet(true);
                  }}
                  sx={theme => ({
                    color: theme.palette.framework.system.charcoal,
                    height: '48px',
                  })}
                >
                  {t('candidate.candidateHeader.manageSkillSet')}
                </MenuItem>
              </Menu>
              <Grid>
                {openSkillSet && (
                  <SkillSetWrapper
                    handleClose={handleSkillSetClose}
                    open={openSkillSet}
                    candidateId={candidateDetails.travelerId}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      <GenericDialog
        open={showReasonPopup}
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
        dialogTitleProps={{
          text: t('Change Candidate Status?'),
        }}
        dialogActions={[
          {
            text: t('search.grid.delete.dialogCancelButton'),
            variant: 'text',
            color: 'tertiary',
            onClick: handleClose,
          },
          {
            text: t('Ok'),
            variant: 'contained',
            color: 'primary',
            disabled: reason === '',
            onClick: handleSubmit,
          },
        ]}
      >
        <span>
          {t(
            `Are you sure you want to change the candidate status? If yes, please select the reason for status change.`,
          )}
        </span>
        <FormControl variant="filled" style={{ minWidth: 306 }}>
          <InputLabel id="demo-simple-select-filled-label">{`Reason for status change`}</InputLabel>
          <Select
            MenuProps={{
              classes: {
                paper: classes.reasonCodeMenu,
              },
            }}
            value={reason}
            onChange={handleReasonSelect}
            IconComponent={reasonDropdownIcon}
          >
            {statusChangeReasonOptions.map((item, index) => {
              const hideStatus = reason === item.name ? 'none' : '';
              return (
                <MenuItem
                  style={{ display: hideStatus }}
                  disableTouchRipple
                  value={item.name}
                  disabled={
                    isRecruiter ? !checkReasonVisibilityForRecruiter(item.value) : !checkReasonVisibility(item.value)
                  }
                  key={index}
                >
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </GenericDialog>
    </>
  );
};

export const DropDownIcon = props => {
  return (
    <KeyboardArrowDownIcon
      fontSize="small"
      sx={{
        pointerEvents: 'none',
        marginLeft: '-30px',
      }}
    />
  );
};

const FailIcon = styledComponent(PanToolOutlinedIcon)`
  color: #d53943;
  padding: 3px;
`;
