export enum ControlType {
  Text = 'TextField',
  Dropdown = 'Dropdown',
  SingleSelectDropdown = 'SingleSelectDropdown',
}

export enum CovidVaccinationStatus {
  FULLY_VACCINATED = 'Fully Vaccinated',
  PARTIALLY_VACCINATED = 'Partially Vaccinated',
  VACCINATED = 'Vaccinated',
  VACCINE_INTERNATIONAL = 'Vaccine-International',
  BOOSTER_UP_TO_DATE = 'Booster/Up to Date',
}

export enum SellingNoteFields {
  LICENSURE = 'Licensures',
  CERTIFICATIONS = 'Certifications',
  SKILL_SET_EXPERIENCE = 'Skillset Experience',
  STRONG_AREAS = 'Strong Areas',
  TIME_OFF_REQUESTS = 'Time off Requests',
  REFERENCES = 'References',
  BEST_CONTACT_NUMBER = 'Best Contact Number',
  PREFERRED_TIME_TO_REACH = 'Preferred Time to Reach',
  TRAVEL_EXPERIENCE = 'Travel Experience',
  COVID_VACCINATION_STATUS = 'COVID Vaccination Status',
  ADDITIONAL_HIGHLIGHTS = 'Additional Highlights',
}
