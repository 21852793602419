import React from 'react';
import { useTranslation } from 'react-i18next';
import { selectGlobalSnackBar } from 'app/ApplicationRoot/Global.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { makeStyles } from 'tss-react/mui';
import { GenericSnackbar } from './GenericSnackbar';
import { IGlobalSnackBar } from 'app/models/Global/Global';
import { Portal } from 'amn-ui-core';

const useStyles = makeStyles()(theme => ({
  stackSnackbarContainer: {
    position: 'absolute',
    bottom: '0px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 9999,
  },
  queueSnackbar: {
    position: 'relative',
    marginBottom: theme.spacing(2),
    zIndex: 1 /* Base z-index */,
    ':nth-child(1)': {
      zIndex: 2 /* Increase z-index for the first Snackbar */,
    },
    ':nth-child(2)': {
      zIndex: 3 /* Increase z-index for the second Snackbar */,
    },
    boxShadow: '0px 5px 10px',
    borderRadius: '4px',
  },
}));
const QueueSnackBar = () => {
  const { t } = useTranslation();
  const { singleMessage } = useSelector(selectGlobalSnackBar);
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const portalRef = React.useRef<any>();
  const setPortalRef = refCurrent => {
    portalRef.current = refCurrent;
  };

  const handleSnackClose = (keyData, onSnackClose) => {
    dispatch(
      globalActions.closeSnackBar({
        id: keyData,
        message: '',
        severity: null,
      }),
    );
    onSnackClose?.();
  };

  return (
    <Portal container={portalRef?.current}>
      <div className={classes.stackSnackbarContainer}>
        {singleMessage?.length !== 0 &&
          singleMessage?.map((messageQueue: IGlobalSnackBar) => {
            if (messageQueue.portalRefId) {
              setPortalRef(document.getElementById(messageQueue.portalRefId));
            } else {
              setPortalRef(undefined);
            }

            return (
              <GenericSnackbar
                key={`${messageQueue?.id}-${messageQueue?.message}`}
                id={messageQueue?.id}
                snackOpen={!!messageQueue?.message}
                message={t(messageQueue?.message)}
                severity={messageQueue?.severity}
                variant={messageQueue?.variant || 'dark'}
                className={`${classes.queueSnackbar} ${messageQueue?.className && messageQueue?.className}`}
                actions={messageQueue?.actions}
                clickAwayProps={messageQueue?.clickAwayProps}
                disableIcon={messageQueue?.disableIcon}
                horizontal="center"
                vertical="bottom"
                onSnackClose={() => handleSnackClose(messageQueue?.id, messageQueue.onSnackClose)}
              />
            );
          })}
      </div>
    </Portal>
  );
};

export default QueueSnackBar;
