import { Grid, CircularProgress } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';

const useStyles = makeStyles()({
  loadingGrid: {
    padding: '20px',
  },
});

export const LoadingGrid = () => {
  const { classes } = useStyles();
  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.loadingGrid}>
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};
