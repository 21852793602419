import { GridColumnHeaderParams } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { IconButton } from 'amn-ui-core';
import { useSelector } from 'react-redux';
import { selectApproveRejectSelectedOption } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { makeStyles } from 'tss-react/mui';

interface IGridHeaderProps {
  handleClick: (e) => void;
  active: boolean;
  [key: string]: any;
  headerName: string;
}

const useStyles = makeStyles<{ selectedOption; hover; headerName }>()((theme, props) => ({
  headerTitle: {
    color:
      props.selectedOption === 1
        ? theme.palette.components.typography.success.color
        : props.selectedOption === 2
        ? '#CC0000'
        : props.hover
        ? '#009AD9'
        : '#333333',
    fontWeight: 'bold',
    padding: '5px',
    display: 'block',
    width: '90px',
    marginLeft: props.headerName ? '-5px' : '0px',
    marginRight: '10px',
  },
}));

const ApprovalStatusHeaderRenderer = React.memo(function ApprovalStatusHeaderRenderer(headerParams: IGridHeaderProps) {
  const [headerName, setHeaderName] = useState<string>('Approval Status');
  const selectedOption = useSelector(selectApproveRejectSelectedOption);
  const [hover, setHover] = useState<boolean>(false);
  const { classes } = useStyles({ selectedOption: selectedOption, hover: hover, headerName: headerParams.headerName });
  useEffect(() => {
    if (selectedOption === 1) {
      setHeaderName('Approve All');
    } else if (selectedOption === 2) {
      setHeaderName('Reject All');
    } else {
      if (headerParams.headerName) {
        setHeaderName(headerParams.headerName);
      } else {
        setHeaderName('Approval Status');
      }
    }
  }, [selectedOption]);
  return (
    <div
      style={{ display: 'flex', justifyContent: 'space-between', flex: 1, flexDirection: 'row', alignItems: 'center' }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => setHover(false)}
    >
      <div>
        {selectedOption === 1 ? (
          <CheckCircleIcon fontSize="small" color="success" />
        ) : selectedOption === 2 ? (
          <CancelIcon fontSize="small" color="error" />
        ) : (
          <></>
        )}
        <span className={classes.headerTitle}>{headerName}</span>
      </div>
      <div>
        <IconButton size="small" onClick={headerParams.handleClick}>
          <ExpandMoreOutlined
            color={
              selectedOption === 1
                ? 'success'
                : selectedOption === 2
                ? 'error'
                : headerParams.active === true
                ? 'primary'
                : 'info'
            }
          />
        </IconButton>
      </div>
    </div>
  );
});
export const ApprovalStatusHeader = ({
  params,
  handleClick,
  active,
  headerName,
}: {
  params: GridColumnHeaderParams;
  handleClick: (e) => void;
  active: boolean;
  headerName?: string;
}) => {
  return <ApprovalStatusHeaderRenderer headerName={headerName} handleClick={handleClick} active={active} {...params} />;
};
