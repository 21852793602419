import { makeStyles } from 'tss-react/mui';
import { missingField } from 'app/constants';
import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';

const useStyles = makeStyles()({
  root: { display: 'flex', flexDirection: 'column', padding: '6px 6px' },
  pair: { display: 'flex', flexDirection: 'row', margin: '2px 0px' },
  value: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    color: '#555555',
    fontSize: 12,
    paddingLeft: 6,
  },
});

export const XGridContactHoverView = (attributesKey: string) => (params: GridCellParams) => {
  return <ContactHoverView attributes={params.row[attributesKey]} params={params} />;
};

const ContactHoverView = ({ attributes, params }: { attributes: { [x: string]: any }; params: GridCellParams }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      {attributes.map((item, i) => (
        <div className={classes.pair} key={i}>
          <img src={item.key} alt="" />
          <div className={item.value ? classes.value : undefined}>{item.value || `  ${missingField}`}</div>
        </div>
      ))}
    </div>
  );
};
