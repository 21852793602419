/* eslint-disable i18next/no-literal-string */
/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, List, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useEffect, useState } from 'react';
import { DocumentDropzone } from 'app/components/Common/DocumentDropzone/DocumentDropzone';
import { DocumentDropzoneAttachments } from './DocumentDropzoneAttachments';
import { useDispatch, useSelector } from 'react-redux';
import { candidateDetailActions } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import {
  existingAttachmentsSelection,
  newAttachmentsSelection,
} from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { AttachmentsSelection } from '../../../../Profile/CandidateDetails.selector';
import { IAttachments } from 'app/models/Credentialing/Credentials';
import { AttachmentItemState } from '../AttachmentPreview/AttachmentPreviewDialog';
import { onDeleteAttachment } from '../../CredentialsHelpers';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { useTranslation } from 'react-i18next';
const mime = require('mime-types');

const useStyles = makeStyles()(theme => ({
  divider: {
    margin: '10px 0px 10px 0px',
    height: 2,
  },
  listRoot: {
    maxHeight: '260px',
    overflowY: 'auto',
    width: '100%',
    height: '100%',
    padding: `0px`,
  },
}));

export const DocumentDropzoneWrapper = props => {
  const { classes } = useStyles();
  const attachmentsData = useSelector(AttachmentsSelection);
  const newAttachmentData = useSelector(newAttachmentsSelection);
  const existingAttachments = useSelector(existingAttachmentsSelection);
  const [newAttachments, setNewAttachments] = useState<any>(newAttachmentData || []);
  const [existAttachments, setExistAttachments] = useState<any>(props.existingAttachments || existingAttachments);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setNewAttachments(newAttachmentData || []);
  }, [newAttachmentData]);

  function getBinaryFromFile(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result));
      reader.addEventListener('error', err => reject(err));
      reader.readAsBinaryString(file);
    });
  }

  //Dropzone Attachments.
  const documentUploadFunc = acceptedFiles => {
    acceptedFiles.forEach(file => {
      var fileExt = file.name.split('.').pop();
      getBinaryFromFile(file).then((binary: any) => {
        const base64EncodedStr = btoa(binary);
        const attachment = {
          name: file.name,
          type: fileExt,
          data: base64EncodedStr,
          status: AttachmentItemState.default,
          title: '',
        };
        dispatch(candidateDetailActions.appendNewAttachment(attachment));
      });
    });
  };

  //Handle Logic For Delete/Edit Attachments
  const onAttachmentTitleEdit = (e, id) => {
    let updatedAttachments = newAttachments;

    updatedAttachments =
      e?.target?.value === ''
        ? updatedAttachments?.map((item, index) => {
            if (index === parseInt(id) - 1) {
              return { ...item, title: null };
            }
            return item;
          })
        : updatedAttachments?.map((item, index) => {
            if (index === parseInt(id) - 1) {
              return { ...item, title: e?.target?.value };
            }
            return item;
          });

    dispatch(candidateDetailActions.setNewAttachments(updatedAttachments));
  };

  const onNewAttachmentDelete = id => {
    const updatedAttachments = newAttachments?.filter((item, index) => index !== parseInt(id)) || [];
    dispatch(candidateDetailActions.setNewAttachments(updatedAttachments));
  };

  const onAttachmentDelete = id => {
    dispatch(
      globalActions.setSnackBar({
        message: t('search.grid.download.deleteMessage'),
        severity: 'info',
      }),
    );

    const updatedAttachments =
      existAttachments?.filter((item, index) => item?.travelerRequirementImageID !== parseInt(id)) || [];
    setExistAttachments(updatedAttachments);
    dispatch(candidateDetailActions.setExistingAttachments(updatedAttachments));
    const delRec = attachmentsData
      ? attachmentsData?.find((item, index) => item?.travelerRequirementImageID === parseInt(id))
      : existAttachments?.find((item, index) => item?.travelerRequirementImageID === parseInt(id));
    deleteAttachmentItem(delRec as IAttachments).then(() => {
      dispatch(candidateDetailActions.setAttachments(updatedAttachments));
      dispatch(candidateDetailActions.setSelPreviewAttachments(updatedAttachments));
    });
  };

  const deleteAttachmentItem = async (item: IAttachments) => {
    await onDeleteAttachment(props.data.travelerId, props.data.requirementID, item, attachmentsData, dispatch)
      .then(res => {
        dispatch(
          globalActions.setSnackBar({
            message: t('search.grid.delete.snackbarSuccess'),
            severity: 'success',
          }),
        );
      })
      .catch(err => {
        dispatch(
          globalActions.setSnackBar({
            message: t('search.grid.delete.snackbarError'),
            severity: 'error',
          }),
        );
      });
  };

  return (
    <>
      {existingAttachments && !props.isAdd && (
        <>
          <LayoutGrid container>
            <LayoutGridItem item xs>
              <Typography component="span" variant="body2">
                Existing Attachments
              </Typography>
            </LayoutGridItem>
          </LayoutGrid>
          <LayoutGrid container>
            <LayoutGridItem item xs>
              <List classes={{ root: classes.listRoot }}>
                {existAttachments?.map((value, index) =>
                  React.cloneElement(
                    <DocumentDropzoneAttachments
                      itemNumber={value?.travelerRequirementImageID}
                      docName={`Attachment${index + 1}.${mime.extension(
                        value?.document?.fileType !== '' ? value?.document?.fileType ?? 'text/plain' : 'text/plain',
                      )}`}
                      docType={
                        value?.document?.fileType !== '' ? value?.document?.fileType ?? 'text/plain' : 'text/plain'
                      }
                      docID={String.fromCharCode(index + 65)}
                      docEdit={false}
                      dateAddedBy={value?.lastUpdatedBy}
                      item={value}
                      submitFailedAttachment={props.submitFailedAttachment}
                      onAttachmentTitleEdit={onAttachmentTitleEdit}
                      onAttachmentDelete={onAttachmentDelete}
                      status={AttachmentItemState.default}
                      canDelete={true}
                      documentTitle={value?.title}
                      editTitle={false}
                    />,
                    {
                      key: index,
                    },
                  ),
                )}
              </List>
            </LayoutGridItem>
          </LayoutGrid>
        </>
      )}
      <Divider variant="middle" className={classes.divider} />
      <LayoutGrid container>
        <LayoutGridItem item xs={4}>
          <LayoutGrid container>
            <LayoutGridItem item xs style={{ paddingBottom: '10px' }}>
              <Typography component="span" variant="body2">
                Add Attachments
              </Typography>
            </LayoutGridItem>
          </LayoutGrid>
          <DocumentDropzone
            disabled={props.dissableUpload}
            onDrop={acceptedFiles => documentUploadFunc(acceptedFiles)}
          />
        </LayoutGridItem>
        <LayoutGridItem item xs={8}>
          <List classes={{ root: classes.listRoot }}>
            {newAttachments.map((value, index) =>
              React.cloneElement(
                <DocumentDropzoneAttachments
                  itemNumber={index}
                  docName={value.name}
                  docType={mime.lookup(value.type || 'application/unknown')}
                  docID={(index + 1).toString()}
                  docEdit={true}
                  item={value}
                  submitFailedAttachment={props.submitFailedAttachment}
                  onAttachmentTitleEdit={onAttachmentTitleEdit}
                  onAttachmentDelete={onNewAttachmentDelete}
                  status={value.status}
                  canDelete={value.status === 2 ? false : true}
                  editTitle={true}
                />,
                {
                  key: index,
                },
              ),
            )}
          </List>
        </LayoutGridItem>
      </LayoutGrid>
    </>
  );
};
