/* eslint-disable react-hooks/exhaustive-deps */
import { HomePage } from 'app/layout/pages/HomePage';
import React from 'react';
import { createRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { gridStateActions, GridTag } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import {
  selectUnitChips,
  selectUnitsSourceData,
  selectIsUnitSelected,
  selectUnit,
} from './Store/FacilityUnit.selector';
import { FacilityUnitsList } from './Grid/FacilityUnitsList';
import { getOrdersCount, getPositionsCount } from './Grid/FacilityUnitsHeader';
import { FacilityUnitActions, IFilterChipSelected } from './Store/FacilityUnit.redux';
import { createFilterChips, getFilteredData } from './Helpers/FacilityUnitsFilterHelper';
import { UnitFilter } from './Filter/UnitFilter';
import { FacilityUnitChips } from './Chips/FacilityUnitsChips';
import { defaultFilterValues } from './Filter/UnitFilterConfig';
import { useDecision } from '@optimizely/react-sdk';
import { ff_order_ui_qualificationlist } from 'app/constants/FeatureFlags/Order/Keys';
import { selectFilterCertificationsDecision } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { orderCreationActions } from '@AMIEWEB/Order/Store/OrderCreation.actions';
import { useParams } from 'react-router-dom';

export const FacilityUnitTab = () => {
  const formMethods = useForm({ defaultValues: defaultFilterValues, shouldUnregister: false });
  const { handleSubmit } = formMethods;
  const params = useParams<{ id: string }>();
  const hiddenSubmitButton = createRef<HTMLButtonElement>();
  const dispatch = useDispatch();
  const chips = useSelector(selectUnitChips);
  const flattenedData = useSelector(selectUnitsSourceData);
  const selectedUnit = useSelector(selectUnit);
  const isUnitSelected = useSelector(selectIsUnitSelected);
  const [filterCertListFlag] = useDecision(ff_order_ui_qualificationlist);
  const selectDecision = useSelector(selectFilterCertificationsDecision);
  if (selectDecision === undefined || selectDecision === null) {
    dispatch(orderCreationActions.setCertificationsFlagDecision(filterCertListFlag.enabled));
  }

  const onApplyFilter = async (values, selectedChips?: IFilterChipSelected) => {
    dispatch(
      gridStateActions.setGridState({
        gridTag: GridTag.FacilityUnitsGrid,
        pageSize: 250,
        pageNumber: 1,
        previewItemId: -1,
        showPreviewDialog: false,
        //sortedColumn: { column: 'firstName', direction: 'asc' },
        sortedColumn: null,
      }),
    );
    dispatch(gridStateActions.setFilterAttributes({ filterObject: Object.entries(values) }));
    const filteredData = getFilteredData(values, flattenedData, selectedChips);
    dispatch(
      FacilityUnitActions.setFilterChips({
        ...chips,
        ...createFilterChips(values, !!selectedChips ? selectedChips : undefined),
      }),
    );
    dispatch(FacilityUnitActions.setFilteredData(filteredData));
    dispatch(
      FacilityUnitActions.setSearchResults({
        positions: getPositionsCount(filteredData),
        orders: getOrdersCount(filteredData),
      }),
    );
  };

  React.useEffect(() => {
    if (isUnitSelected && params?.id) {
      dispatchUnitChanges({ ...selectedUnit, facilityId: params?.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUnitSelected, params?.id]);

  const dispatchUnitChanges = (obj: any) => {
    dispatch(FacilityUnitActions.setUnitsClone(obj));
    dispatch(FacilityUnitActions.setSelectedUnit(obj));
  };

  React.useEffect(() => {
    if (params?.id) {
      dispatch(
        gridStateActions.setGridState({
          gridTag: GridTag.FacilityUnitsGrid,
          pageSize: 250,
          pageNumber: 1,
          previewItemId: -1,
          showPreviewDialog: false,
          sortedColumn: null,
          filterAttributes: undefined,
        }),
      );
    }
    return () => {
      dispatch(gridStateActions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(filter => onApplyFilter(filter))}>
        <HomePage
          title=""
          filters={<UnitFilter onApplyFilter={onApplyFilter} />}
          table={<FacilityUnitsList />}
          chips={<FacilityUnitChips chips={chips} onApplyFilter={onApplyFilter} />}
          showSidePanel={false}
        />
        <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
      </form>
    </FormProvider>
  );
};
