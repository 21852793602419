import { missingField } from 'app/constants';
import { modsEnums } from 'app/models/Placement/PlacementMods';
import moment from 'moment';
import { placementModsAction } from 'store/redux-store/placement-mods/slice';
import { GridState } from '@mui/x-data-grid';
import { differenceInDays, isBefore, isValid } from 'date-fns';
import { TemplateTypes } from './CommonComponents/Utils';
import { httpSuccess } from 'app/services/serviceHelpers';
import i18next from 'i18next';

export const getColor = (val: string) => {
  switch (val) {
    case 'Recruiter Approved':
      return '#FC9508';
    case 'Pending':
      return '#FC9508';
    case 'Approved':
      return '#5FB661';
    case 'Void - Approved':
      return '#5f5a5a';
    case 'Void - Denied':
      return '#5f5a5a';
    case 'Denied':
      return '#CB0813';
    default:
      return '#000000';
  }
};

export const compareDates = (date, dateToCompare) => {
  if (moment(date) > moment(dateToCompare)) {
    return true;
  } else {
    return false;
  }
};

export const statusHandler = (id: number, voidStatus: string = null) => {
  if (!voidStatus || voidStatus === 'N') {
    switch (id) {
      case 1:
        return 'Pending';
      case 2:
        return 'Approved';
      case 3:
        return 'Denied';
      case 4:
        return 'Recruiter Approved';
      default:
        return '--';
    }
  } else if (voidStatus === 'Y') {
    switch (id) {
      case 2:
        return 'Void - Approved';
      case 3:
        return 'Void - Denied';
      default:
        return '--';
    }
  }
};

export const getAmount = (value: number) => {
  if (value === null || value === undefined) {
    return missingField;
  } else if (value === 0) {
    return `${value}`;
  } else {
    return `${value.toFixed(2)}`;
  }
};

export const getNumberFormattedValue = (value: number, _missingField = null) => {
  if (value === null || value === undefined) {
    return '';
  } else {
    return isNumber(value) ? `${value?.toFixed(2)}` : '';
  }
};

export const isNumber = (value: any) => {
  return typeof value === 'number';
};

export const getFormattedTwoDigits = (value: any) => {
  if (value === null || value === undefined) {
    return '';
  } else {
    return isNumber(value) ? `${value?.toFixed(2)}` : '';
  }
};

export const localToServerFieldMapping = (localField: string) => {
  let mappedField = {
    type: 'descTravelerOptionType',
    description: 'description',
    amount: 'amount',
    acceptance: 'descAcceptanceStatus',
    effectiveDate: 'effectiveDate',
    approvalDate: 'approvalDate',
    status: 'approvalStatusId',
    task: 'hasAssignedTask',
    recruiterApproved: 'recruiterApprovalDate',
    byRecruiter: 'recruiterApprovedByName',
    lastUpdated: 'lastUpdated',
    approvedBy: 'approvedByName',
    standard: 'Standard',
  };
  return mappedField[localField];
};

export const getSelectedModDetails = (selectedRow, dispatch, placementId) => {
  switch (selectedRow?.type?.replaceAll(/['/.\s]/g, '')) {
    case 'StartDate': {
      dispatch(
        placementModsAction.getStartDateModDetails({
          placementId: placementId,
          travelerOptionId: selectedRow?.travelerOptionId,
        }),
      );
      break;
    }
    case 'MinReqHours': {
      dispatch(
        placementModsAction.getMinReqHoursModDetails({
          placementId: placementId,
          travelerOptionId: selectedRow?.travelerOptionId,
        }),
      );
      break;
    }
    case 'EndDate': {
      dispatch(
        placementModsAction.getEndDateModDetails({
          placementId: placementId,
          travelerOptionId: selectedRow?.travelerOptionId,
        }),
      );
      break;
    }
    case 'Shift': {
      dispatch(
        placementModsAction.getShiftModDetailAction({
          placementId: placementId,
          travelerOptionId: selectedRow?.travelerOptionId,
        }),
      );
      break;
    }
    case 'PayRate': {
      dispatch(
        placementModsAction.getPayRateModDetails({
          placementId: placementId,
          travelerOptionId: selectedRow?.travelerOptionId,
        }),
      );
      dispatch(placementModsAction.getAdditionalPremiumPayRate());
      dispatch(placementModsAction.getExceptionsPayRateGww({ excludeOther: true }));
      break;
    }
    case 'LodgingPerDiemAdjustment': {
      dispatch(
        placementModsAction.getLodgingModDetails({
          placementId: placementId,
          travelerOptionId: selectedRow?.travelerOptionId,
        }),
      );
      break;
    }
    case 'BillRate': {
      dispatch(
        placementModsAction.getBillRateModDetails({
          placementId: placementId,
          travelerOptionId: selectedRow?.travelerOptionId,
        }),
      );
      dispatch(placementModsAction.getPricingReason());
      dispatch(placementModsAction.getExceptionsPayRateGww({ excludeOther: false }));
      dispatch(placementModsAction.getRateShiftList());
      dispatch(placementModsAction.getCancellationHoursFrequencyList());
      break;
    }
    case 'PTO': {
      dispatch(
        placementModsAction.getPtoModDetailAction({
          placementId: placementId,
          travelerOptionId: selectedRow?.travelerOptionId,
        }),
      );
      break;
    }
    case 'Insurance': {
      dispatch(
        placementModsAction.getInsuranceModDetails({
          placementId: placementId,
          travelerOptionId: selectedRow?.travelerOptionId,
        }),
      );
      dispatch(placementModsAction.getInsuranceTypes());
      dispatch(placementModsAction.getEffectiveDateTypes());
      break;
    }
    case 'OGPIntlLoan': {
      dispatch(
        placementModsAction.getOgpInternationalLoanModDetailAction({
          placementId: placementId,
          travelerOptionId: selectedRow?.travelerOptionId,
        }),
      );
      break;
    }
    case 'Timeoff': {
      dispatch(
        placementModsAction.getTimeOffModDetails({
          placementId: placementId,
          travelerOptionId: selectedRow?.travelerOptionId,
        }),
      );
      break;
    }
    case 'Furniture': {
      dispatch(
        placementModsAction.getFurnitureModDetails({
          placementId: placementId,
          travelerOptionId: selectedRow?.travelerOptionId,
        }),
      );
      break;
    }
    case 'Bonus': {
      dispatch(
        placementModsAction.getBonusModDetailAction({
          placementId: placementId,
          travelerOptionId: selectedRow?.travelerOptionId,
        }),
      );
      break;
    }
    case 'Reimbursement': {
      dispatch(
        placementModsAction.getReimbursement({
          placementId: placementId,
          travelerOptionId: selectedRow?.travelerOptionId,
        }),
      );
      dispatch(placementModsAction.getPayeeList());
      dispatch(placementModsAction.getReimbursementCategoryAndFrequency());
      break;
    }
    case 'EMILMO': {
      dispatch(
        placementModsAction.getEmiLmoModDetailAction({
          placementId: placementId,
          travelerOptionId: selectedRow?.travelerOptionId,
        }),
      );
      break;
    }

    case 'Housing': {
      dispatch(
        placementModsAction.getHousingModDetailAction({
          placementId: placementId,
          travelerOptionId: selectedRow?.travelerOptionId,
        }),
      );
      break;
    }
    case 'Preferences': {
      dispatch(
        placementModsAction.getPreferencesModDetailAction({
          placementId: placementId,
          travelerOptionId: selectedRow?.travelerOptionId,
        }),
      );
      break;
    }
    case 'EndingTravel': {
      dispatch(
        placementModsAction.getTravelAllowanceModDetailAction({
          placementId: placementId,
          travelerOptionId: selectedRow?.travelerOptionId,
          modEnum: modsEnums.EndingTravel,
        }),
      );
      break;
    }
    case 'ArrivingTravel': {
      dispatch(
        placementModsAction.getTravelAllowanceModDetailAction({
          placementId: placementId,
          travelerOptionId: selectedRow?.travelerOptionId,
          modEnum: modsEnums.ArrivingTravel,
        }),
      );
      break;
    }
    case 'InterimTravel': {
      dispatch(
        placementModsAction.getTravelAllowanceModDetailAction({
          placementId: placementId,
          travelerOptionId: selectedRow?.travelerOptionId,
          modEnum: modsEnums.InterimTravel,
        }),
      );
      break;
    }
    case 'PayrollShiftDifferential': {
      dispatch(placementModsAction.getShiftDifferentialTypes());
      dispatch(placementModsAction.getShiftDifferentialDescriptionTypes());
      dispatch(placementModsAction.getShiftDifferentialTemplateTypes());
      dispatch(
        placementModsAction.getShiftDifferentialTemplateItems({
          shiftDifferentialTemplateTypeId: TemplateTypes.Standard,
        }),
      );
      dispatch(
        placementModsAction.getPayrollShiftDifferential({
          placementId: placementId,
          travelerOptionId: selectedRow?.travelerOptionId,
        }),
      );
      break;
    }
    case 'BillingShiftDifferential': {
      dispatch(placementModsAction.getShiftDifferentialTypes());
      dispatch(placementModsAction.getShiftDifferentialDescriptionTypes());
      dispatch(placementModsAction.getShiftDifferentialTemplateTypes());
      dispatch(
        placementModsAction.getShiftDifferentialTemplateItems({
          shiftDifferentialTemplateTypeId: TemplateTypes.Standard,
        }),
      );
      dispatch(
        placementModsAction.getBillingShiftDifferential({
          placementId: placementId,
          travelerOptionId: selectedRow?.travelerOptionId,
        }),
      );
      break;
    }
  }
};

export const endingTravelPayerOption = [
  { Id: 1, Description: 'Company' },
  { Id: 2, Description: 'Client' },
];
export const endingTravelPayableOption = [
  { Id: 1, Description: 'Traveler' },
  { Id: 2, Description: 'Vendor' },
];
export const shiftOption = [
  { ID: 1, Description: '12 D' },
  { ID: 2, Description: '12 N' },
  { ID: 3, Description: '12 D/N' },
  { ID: 4, Description: '8 D' },
  { ID: 5, Description: '8 E' },
  { ID: 6, Description: '8 N' },
  { ID: 7, Description: '8 D/E/N' },
  { ID: 9, Description: '8/12 D' },
  { ID: 10, Description: '8/12 N' },
  { ID: 11, Description: '8/12 D/N' },
  { ID: 12, Description: '8/12 D/E/N' },
  { ID: 13, Description: '10 D' },
  { ID: 14, Description: '10 E' },
  { ID: 15, Description: '10 N' },
  { ID: 16, Description: '12 E' },
  { ID: 17, Description: '12 D/E' },
  { ID: 18, Description: '12 E/N' },
  { ID: 19, Description: '12 D/E/N' },
  { ID: 20, Description: '11.5 D' },
  { ID: 21, Description: '11.5 N' },
  { ID: 22, Description: '11.5 D/E' },
  { ID: 23, Description: '11.5 E/N' },
  { ID: 24, Description: '11.5 D/N' },
  { ID: 25, Description: '11.5 D/E/N' },
  { ID: 26, Description: '10 D/N' },
  { ID: 27, Description: '10 D/E' },
  { ID: 28, Description: '10 E/N' },
  { ID: 29, Description: '10 D/E/N' },
  { ID: 30, Description: '8 D/E' },
  { ID: 31, Description: '8 E/N' },
  { ID: 32, Description: '8 D/N' },
  { ID: 33, Description: '7.5 D' },
  { ID: 34, Description: '7.5 E' },
  { ID: 35, Description: '7.5 N' },
  { ID: 36, Description: '7.5 D/E' },
  { ID: 37, Description: '7.5 E/N' },
  { ID: 38, Description: '7.5 D/N' },
  { ID: 39, Description: '7.5 D/E/N' },
  { ID: 40, Description: '8/12 E' },
  { ID: 41, Description: '8/12 D/E' },
  { ID: 42, Description: '8/12 E/N' },
  { ID: 43, Description: '8/10/12 D' },
  { ID: 44, Description: '8/10/12 E' },
  { ID: 45, Description: '8/10/12 N' },
  { ID: 46, Description: '8/10/12 D/E' },
  { ID: 47, Description: '8/10/12 D/N' },
  { ID: 48, Description: '8/10/12 E/N' },
  { ID: 49, Description: '8/10/12 D/E/N' },
  { ID: 50, Description: '7.5/11.5 D' },
  { ID: 51, Description: '7.5/11.5 E' },
  { ID: 52, Description: '7.5/11.5 N' },
  { ID: 53, Description: '7.5/11.5 D/E' },
  { ID: 54, Description: '7.5/11.5 D/N' },
  { ID: 55, Description: '7.5/11.5 E/N' },
  { ID: 56, Description: '7.5/11.5 D/E/N' },
  { ID: 57, Description: '7.5/10/11.5 D' },
  { ID: 58, Description: '7.5/10/11.5 E' },
  { ID: 59, Description: '7.5/10/11.5 N' },
  { ID: 60, Description: '7.5/10/11.5 D/E' },
  { ID: 61, Description: '7.5/10/11.5 D/N' },
  { ID: 62, Description: '7.5/10/11.5 E/N' },
  { ID: 63, Description: '7.5/10/11.5 D/E/N' },
  { ID: 64, Description: '11.5 E' },
  { ID: 65, Description: '9.5 D' },
  { ID: 66, Description: '9.5 E' },
  { ID: 67, Description: '9.5 N' },
  { ID: 68, Description: '9.5 D/E' },
  { ID: 69, Description: '9.5 E/N' },
  { ID: 70, Description: '9.5 D/N' },
  { ID: 71, Description: '9.5 D/E/N' },
  { ID: 72, Description: '7 D' },
  { ID: 73, Description: '7 E' },
  { ID: 74, Description: '4 D/N/E' },
  { ID: 75, Description: '4 D' },
  { ID: 76, Description: '5 D' },
  { ID: 77, Description: '6 D' },
  { ID: 78, Description: '9 D/N/E' },
  { ID: 79, Description: '11 D/N/E' },
  { ID: 80, Description: '14 D/N/E' },
  { ID: 81, Description: '16 D/N/E' },
  { ID: 82, Description: '18 D/N/E' },
  { ID: 83, Description: '8/16 D/N/E' },
  { ID: 84, Description: '8/16 D' },
  { ID: 85, Description: '8/16 N' },
  { ID: 86, Description: '8/16 E' },
  { ID: 87, Description: '14 D' },
  { ID: 88, Description: '14 E' },
  { ID: 89, Description: '7.25 D' },
  { ID: 90, Description: '6.5 D' },
  { ID: 91, Description: '8/10 D/N/E' },
  { ID: 92, Description: '8/10 D' },
  { ID: 93, Description: '8/10 N' },
  { ID: 94, Description: '8/10 E' },
  { ID: 95, Description: '6.4 D' },
  { ID: 96, Description: '6.8 D' },
  { ID: 97, Description: '7.4 D' },
];
export const ptoOption = [
  {
    ID: 1,
    Description: 'OGP01A',
    FullDescription: '3120 CNTR 40 HRS PTO@1560&3120',
  },
  {
    ID: 2,
    Description: 'OGP01B',
    FullDescription: '4160 CNTR 40 HRS PTO@1560&3120',
  },
  {
    ID: 3,
    Description: 'OGP02A',
    FullDescription: '2080 CNTR 36 HRS PTO@1560',
  },
  {
    ID: 4,
    Description: 'OGP02B',
    FullDescription: '2080 CNTR 40 HRS PTO@1560',
  },
  {
    ID: 5,
    Description: 'OGP02C',
    FullDescription: '3120 CNTR 80 HRS PTO@1560',
  },
  {
    ID: 6,
    Description: 'OGP02D',
    FullDescription: '4160 CNTR 120 HRS PTO@1560',
  },
  {
    ID: 7,
    Description: 'OGP03A',
    FullDescription: '2080 CNTR 36 HRS PTO@936',
  },
  {
    ID: 8,
    Description: 'OGP03B',
    FullDescription: '3120 CNTR 80 HRS PTO@936',
  },
  {
    ID: 9,
    Description: 'OGP03C',
    FullDescription: '4160 CNTR 120 HRS PTO@936',
  },
  {
    ID: 10,
    Description: 'OGP04A',
    FullDescription: '4.92 PTO PER 80 CNTR HRS WRKD',
  },
  {
    ID: 11,
    Description: 'CON01A',
    FullDescription: '1.67 PTO/36 SVC - WAIT 468 HRS',
  },
  {
    ID: 12,
    Description: 'CON01B',
    FullDescription: '1.50 PTO/40 SVC - WAIT 520 HRS',
  },
  {
    ID: 13,
    Description: 'OGP03D',
    FullDescription: '3120 CNTR 72 HRS PTO@936',
  },
  {
    ID: 14,
    Description: 'OGP03E',
    FullDescription: '3120 CNTR 76 HRS PTO@936',
  },
  {
    ID: 15,
    Description: 'OGP03F',
    FullDescription: '4160 CNTR 108 HRS PTO@936',
  },
  {
    ID: 16,
    Description: 'OGP05A',
    FullDescription: '3120 CNTR 80 HRS PTO@1080',
  },
  {
    ID: 17,
    Description: 'OGPXXX',
    FullDescription: 'No PTO Plan Offered',
  },
  {
    ID: 18,
    Description: 'OGP07A',
    FullDescription: '3120/80/1PTO/39SVC',
  },
  {
    ID: 19,
    Description: 'OGP07B',
    FullDescription: '4160/120/1PTO/34.6SVC',
  },
  {
    ID: 20,
    Description: 'OGP07C',
    FullDescription: '2080/40/1PTO/52SVC',
  },
  {
    ID: 21,
    Description: 'OGPAH1',
    FullDescription: '4160/160/1PTO/26SV',
  },
  {
    ID: 22,
    Description: 'OGP07D',
    FullDescription: '6240/180/1PTO/34.6SVC',
  },
];
export const selectedModValue = modsDialogDetails => {
  return modsDialogDetails.selectedMod?.replaceAll(/['/.\s]/g, '');
};

export const getModsWidth = currentMod => {
  if (
    currentMod === 'PayRate' ||
    currentMod === 'BillRate' ||
    currentMod === 'Timeoff' ||
    currentMod === 'Furniture' ||
    currentMod === 'Bonus' ||
    currentMod === 'InterimTravel' ||
    currentMod === 'EMILMO' ||
    currentMod === 'Housing' ||
    currentMod === 'Preferences' ||
    currentMod === 'PayrollShiftDifferential' ||
    currentMod === 'BillingShiftDifferential'
  ) {
    return null;
  } else {
    return '340px';
  }
};

export const bonusTypeOption = [
  { Id: 1, Description: 'Start Date' },
  { Id: 2, Description: 'Date Booked' },
  { Id: 3, Description: 'Inconvenience' },
  { Id: 4, Description: 'Quick Start' },
  { Id: 7, Description: 'Miscellaneous' },
  { Id: 8, Description: 'Competitive' },
  { Id: 9, Description: 'Shift Required Bonus' },
  { Id: 10, Description: 'Additional Pay' },
  { Id: 11, Description: 'New Grad Tuition' },
  { Id: 12, Description: 'Marketing Adjustment' },
];
export const bonusPaymentTypeOption = [
  { Id: 1, Description: 'Sign On' },
  { Id: 2, Description: 'Completion' },
  { Id: 3, Description: 'Custom' },
  { Id: 4, Description: 'Shift Req Sign On' },
  { Id: 5, Description: 'Shift Req Completion' },
];

export const bonusBillingTypeOption = [
  { Id: 1, Description: 'None' },
  { Id: 2, Description: 'Flat' },
  { Id: 3, Description: 'Hourly' },
];

export const bonusShortHourTypeOption = [
  { Id: 1, Description: 'Minimum hours' },
  { Id: 2, Description: 'Minimum missed shift' },
  { Id: 3, Description: 'None' },
  { Id: 4, Description: 'Prorated' },
  { Id: 5, Description: 'Custom' },
  { Id: 6, Description: 'Standard' },
];
export const bonusApprovedByOption = [{ Id: 1, Description: 'AMIE' }];
export const bonusApprovedMethodOption = [
  { Id: 4, Description: 'Addendum' },
  { Id: 3, Description: 'e-mail' },
  { Id: 2, Description: 'Fax' },
  { Id: 1, Description: 'Phone' },
];

export const generateRowId = placementId => {
  return `${placementId}-${Math.random()}`;
};

export const getFormattedTimeOff = timeOffRequests => {
  return timeOffRequests?.map(item => {
    const data = {
      id: item.id ? item.id : `${generateRowId(item.placementId)}}`,
      placementId: item.placementId,
      travelerOptionId: item.travelerOptionId,
      placementTimeOffId: item.placementTimeOffId,
      startDate: item?.startDate ? item?.startDate : '',
      endDate: item?.endDate ? item?.endDate : '',
      days:
        !isValid(new Date(item?.startDate)) ||
        !isValid(new Date(item?.endDate)) ||
        isBefore(new Date(item?.endDate), new Date(item?.startDate))
          ? null
          : differenceInDays(new Date(item?.endDate), new Date(item?.startDate)) + 1,
      hours: isNumber(item.hours) ? item.hours : null,
      cost: item.cost ? item.cost : null,
      placementTimeOffPayerId: item.placementTimeOffPayerId,
      timestamp: item.timestamp,
      isModified: item.isModified,
      facilityApprovedBy: item.facilityApprovedBy ? item.facilityApprovedBy : '',
      interimPlacementId: item.interimPlacementId,
      interimTravelerOptionId: item.interimTravelerOptionId,
      timeOffPayerDescription: item.timeOffPayerDescription ? item.timeOffPayerDescription : '',
      placementTimeStamp: item.placementTimeStamp,
      approvalStatusId: item.approvalStatusId,
      voidStatus: item.voidStatus,
      assigned: item?.assigned,
    };
    return data;
  });
};
export const getFormattedPayrollShiftDifferential = (
  payrollShiftDiffRequests,
  shiftDifferentialTypes,
  shiftDifferentialDescriptionTypes,
  isModEdit,
) => {
  return payrollShiftDiffRequests?.map((item, index) => {
    index++;
    const data = {
      id: isModEdit ? (item.id ? item.id : `${generateRowId(item.placementId)}`) : `${item.placementId}-${index}`,
      placementId: item.placementId,
      travelerOptionId: item.travelerOptionId,
      shiftDifferentialId: item.shiftDifferentialId,
      shiftDifferentialDescriptionTypeId: item.shiftDifferentialDescriptionTypeId,
      shiftDifferentialDescriptionType: item.shiftDifferentialDescriptionTypeId
        ? shiftDifferentialDescriptionTypes.find(t => parseInt(t.ID) === item.shiftDifferentialDescriptionTypeId)
            ?.Description
        : '--',
      shiftDifferentialTypeId: item.shiftDifferentialTypeId,
      shiftDifferentialType: item.shiftDifferentialTypeId
        ? shiftDifferentialTypes.find(t => parseInt(t.ID) === item.shiftDifferentialTypeId)?.Description
        : '--',
      amount: isNumber(item.amount) ? item.amount : null,
      startTime: item?.startTime ? item?.startTime : '',
      endTime: item?.endTime ? item?.endTime : '',
      monday: item.monday,
      tuesday: item.tuesday,
      wednesday: item.wednesday,
      thursday: item.thursday,
      friday: item.friday,
      saturday: item.saturday,
      sunday: item.sunday,
      selectAll: item.selectAll,
      timestamp: item.timestamp,
      facilityApprovedBy: item.facilityApprovedBy ? item.facilityApprovedBy : '',
      interimPlacementId: item.interimPlacementId,
      interimTravelerOptionId: item.interimTravelerOptionId,
      timeOffPayerDescription: item.timeOffPayerDescription ? item.timeOffPayerDescription : '',
      placementTimestamp: item.placementTimestamp,
      approvalStatusId: item.approvalStatusId,
      voidStatus: item.voidStatus,
      assigned: item?.assigned,
    };
    return data;
  });
};

export const getFormattedFurniture = furnitureRequests => {
  return furnitureRequests?.map((item, index) => {
    const data = {
      id: `${item.placementId}-${index}`,
      placementId: item.placementId,
      travelerOptionId: item.travelerOptionId,
      placementFurnitureId: item.placementFurnitureId,
      furnitureCostId: item.furnitureCostId,
      description: item.description,
      standardCost: item.standardCost,
      costToTraveler: item.costToTraveler,
      quantity: item.quantity,
      include: item.include,
    };
    return data;
  });
};

export const getFormattedUtility = utilityRequests => {
  return utilityRequests?.map((item, index) => {
    const data = {
      id: `${item.placementId}-${index}`,
      placementId: item.placementId,
      travelerOptionId: item.travelerOptionId,
      billable: item.billable,
      costToTraveler: item.costToTraveler,
      description: item.description,
      include: item.include,
      placementUtilityId: item.placementUtilityId,
      standardCost: item.standardCost,
      utilityCostId: item.utilityCostId,
    };
    return data;
  });
};

export const getFormattedBillingShiftDifferential = (
  billingShiftDiffRequests,
  shiftDifferentialTypes,
  shiftDifferentialDescriptionTypes,
  isModEdit,
) => {
  return billingShiftDiffRequests?.map((item, index) => {
    const data = {
      id: isModEdit ? (item.id ? item.id : `${generateRowId(item.placementId)}`) : `${item.placementId}-${index}`,
      placementId: item.placementId,
      travelerOptionId: item.travelerOptionId,
      shiftDifferentialId: item.shiftDifferentialId,
      shiftDifferentialDescriptionTypeId: item.shiftDifferentialDescriptionTypeId,
      shiftDifferentialDescriptionType: item.shiftDifferentialDescriptionTypeId
        ? shiftDifferentialDescriptionTypes.find(t => parseInt(t.ID) === item.shiftDifferentialDescriptionTypeId)
            ?.Description
        : '--',
      shiftDifferentialTypeId: item.shiftDifferentialTypeId,
      shiftDifferentialType: item.shiftDifferentialTypeId
        ? shiftDifferentialTypes.find(t => parseInt(t.ID) === item.shiftDifferentialTypeId)?.Description
        : '--',
      amount: isNumber(item.amount) ? item.amount : null,
      startTime: item?.startTime ? item?.startTime : '',
      endTime: item?.endTime ? item?.endTime : '',
      monday: item.monday,
      tuesday: item.tuesday,
      wednesday: item.wednesday,
      thursday: item.thursday,
      friday: item.friday,
      saturday: item.saturday,
      sunday: item.sunday,
      selectAll: item.selectAll,
      timestamp: item.timestamp,
      facilityApprovedBy: item.facilityApprovedBy ? item.facilityApprovedBy : '',
      interimPlacementId: item.interimPlacementId,
      interimTravelerOptionId: item.interimTravelerOptionId,
      timeOffPayerDescription: item.timeOffPayerDescription ? item.timeOffPayerDescription : '',
      placementTimeStamp: item.placementTimeStamp,
      approvalStatusId: item.approvalStatusId,
      voidStatus: item.voidStatus,
      assigned: item?.assigned,
    };
    return data;
  });
};

export const fetchDetailsforAddMod = (currentMod, placementId, dispatch) => {
  switch (currentMod.id) {
    case modsEnums.MinReqHours: {
      dispatch(
        placementModsAction.getMinReqHoursModDetails({
          placementId: placementId,
          travelerOptionId: null,
          newMod: true,
        }),
      );
      break;
    }
    case modsEnums.PayRate: {
      dispatch(
        placementModsAction.getPayRateModDetails({
          placementId: placementId,
          travelerOptionId: null,
          newMod: true,
        }),
      );
      dispatch(placementModsAction.getAdditionalPremiumPayRate());
      dispatch(placementModsAction.getExceptionsPayRateGww({ excludeOther: true }));
      break;
    }
    case modsEnums.LodingPerDiemAdjustment: {
      dispatch(
        placementModsAction.getLodgingModDetails({
          placementId: placementId,
          travelerOptionId: null,
          newMod: true,
        }),
      );
      break;
    }
    case modsEnums.StartDate: {
      dispatch(
        placementModsAction.getStartDateModDetails({
          placementId: placementId,
          travelerOptionId: null,
          newMod: true,
        }),
      );
      break;
    }
    case modsEnums.Insurance: {
      dispatch(
        placementModsAction.getInsuranceModDetails({
          placementId: placementId,
          travelerOptionId: null,
          newMod: true,
        }),
      );
      dispatch(placementModsAction.getInsuranceTypes());
      dispatch(placementModsAction.getEffectiveDateTypes());
      break;
    }
    case modsEnums.EndDate: {
      dispatch(
        placementModsAction.getEndDateModDetails({
          placementId: placementId,
          travelerOptionId: null,
          newMod: true,
        }),
      );
      break;
    }
    case modsEnums.Reimbursement: {
      dispatch(
        placementModsAction.getReimbursement({
          placementId: placementId,
          travelerOptionId: null,
          newMod: true,
        }),
      );
      dispatch(placementModsAction.getPayeeList());
      dispatch(placementModsAction.getReimbursementCategoryAndFrequency());

      break;
    }
    case modsEnums.Shift: {
      dispatch(
        placementModsAction.getShiftModDetailAction({
          placementId: placementId,
          travelerOptionId: null,
          newMod: true,
        }),
      );
      break;
    }
    case modsEnums.ArrivingTravel: {
      dispatch(
        placementModsAction.getTravelAllowanceModDetailAction({
          placementId: placementId,
          travelerOptionId: null,
          modEnum: modsEnums.ArrivingTravel,
          newMod: true,
        }),
      );
      break;
    }
    case modsEnums.TimeOff: {
      dispatch(
        placementModsAction.getTimeOffModDetails({
          placementId: placementId,
          travelerOptionId: null,
          newMod: true,
        }),
      );
      break;
    }
    case modsEnums.Furniture: {
      dispatch(
        placementModsAction.getFurnitureModDetails({
          placementId: placementId,
          travelerOptionId: null,
          newMod: true,
        }),
      );
      break;
    }
    case modsEnums.EndingTravel: {
      dispatch(
        placementModsAction.getTravelAllowanceModDetailAction({
          placementId: placementId,
          travelerOptionId: null,
          modEnum: modsEnums.EndingTravel,
          newMod: true,
        }),
      );
      break;
    }
    case modsEnums.PayrollShiftDifferential: {
      dispatch(placementModsAction.getShiftDifferentialTypes());
      dispatch(placementModsAction.getShiftDifferentialDescriptionTypes());
      dispatch(placementModsAction.getShiftDifferentialTemplateTypes());
      dispatch(
        placementModsAction.getShiftDifferentialTemplateItems({
          shiftDifferentialTemplateTypeId: TemplateTypes.Standard,
        }),
      );
      dispatch(
        placementModsAction.getPayrollShiftDifferential({
          placementId: placementId,
          travelerOptionId: null,
          newMod: true,
        }),
      );
      break;
    }
    case modsEnums.BillRate: {
      dispatch(
        placementModsAction.getBillRateModDetails({
          placementId: placementId,
          travelerOptionId: null,
          newMod: true,
        }),
      );
      dispatch(placementModsAction.getPricingReason());
      dispatch(placementModsAction.getExceptionsPayRateGww({ excludeOther: false }));
      dispatch(placementModsAction.getRateShiftList());
      dispatch(placementModsAction.getCancellationHoursFrequencyList());
      break;
    }
    case modsEnums.InterimTravel: {
      dispatch(
        placementModsAction.getTravelAllowanceModDetailAction({
          placementId: placementId,
          travelerOptionId: null,
          modEnum: modsEnums.InterimTravel,
          newMod: true,
        }),
      );
      break;
    }
    case modsEnums.EMILMO: {
      dispatch(
        placementModsAction.getEmiLmoModDetailAction({
          placementId: placementId,
          travelerOptionId: null,
          newMod: true,
        }),
      );
      break;
    }
    case modsEnums.Preferences: {
      dispatch(
        placementModsAction.getPreferencesModDetailAction({
          placementId: placementId,
          travelerOptionId: null,
          newMod: true,
        }),
      );
      break;
    }
    case modsEnums.Bonus: {
      dispatch(
        placementModsAction.getBonusModDetailAction({
          placementId: placementId,
          travelerOptionId: null,
          newMod: true,
        }),
      );
      break;
    }
    case modsEnums.BillingShiftDifferential: {
      dispatch(placementModsAction.getShiftDifferentialTypes());
      dispatch(placementModsAction.getShiftDifferentialDescriptionTypes());
      dispatch(placementModsAction.getShiftDifferentialTemplateTypes());
      dispatch(
        placementModsAction.getShiftDifferentialTemplateItems({
          shiftDifferentialTemplateTypeId: TemplateTypes.Standard,
        }),
      );
      dispatch(
        placementModsAction.getBillingShiftDifferential({
          placementId: placementId,
          travelerOptionId: null,
          newMod: true,
        }),
      );
      break;
    }
    case modsEnums.PTO: {
      dispatch(
        placementModsAction.getPtoModDetailAction({
          placementId: placementId,
          travelerOptionId: null,
          newMod: true,
        }),
      );
      break;
    }
    case modsEnums.Housing: {
      removeWhichhasZerosId();
      dispatch(
        placementModsAction.getHousingModDetailAction({
          placementId: placementId,
          travelerOptionId: null,
          newMod: true,
        }),
      );
      break;
    }
    case modsEnums.OGPLoan: {
      dispatch(
        placementModsAction.getOgpInternationalLoanModDetailAction({
          placementId: placementId,
          travelerOptionId: null,
          newMod: true,
        }),
      );
      break;
    }
  }
};

export const truncateNumber = (param: number) => {
  const splited = param.toString().split('.');
  if (splited[0].length >= 6) {
    return splited[0];
  } else {
    return Number(param).toFixed(4).substring(0, 6);
  }
};

export const isDepositRefundable = (desposit: any, id: number) => {
  let isRefundable = 'N';
  if (desposit && desposit.length > 0) {
    for (const item of desposit) {
      if (item.housingDepositTypeID === id) {
        if (item.refundable === 'Y') {
          isRefundable = item.refundable;
        }
      }
    }
  }
  return isRefundable;
};

export const truncateZeros = (param: number) => {
  if (!param) {
    return '0.00';
  }
  const paramString = param.toString();
  const split = paramString.split('.');
  if (split.length === 1) {
    return `${paramString}.00`;
  }
  if (split[1].length === 1) {
    return `${paramString}0`;
  }
  if (split[1].length > 2) {
    return `${split[0]}.${split[1].substring(0, 2)}`;
  }
  return paramString;
};

export const getDepositAmountById = (desposit: any, id: number) => {
  let amount: string = '0.00';
  if (desposit && desposit.length > 0) {
    for (const item of desposit) {
      if (item.housingDepositTypeID === id) {
        amount = truncateZeros(item.amount);
      }
    }
  }
  return amount;
};

export const formatTypeAheadOptions = options =>
  options.map(item => {
    const option = {
      id: parseInt(item.value),
      Description: item.name,
    };
    return option;
  });

export const formatTypeAheadOptionsWithFullDescription = (options, fullDescriptionOptions) =>
  options.map(item => {
    const option = {
      id: parseInt(item.value),
      Description: item.name,
      FullDescription: fullDescriptionOptions.find(o => o.value === item.value)?.name,
    };
    return option;
  });

export const housingProviderOption = [
  { Id: 1, Description: 'Company' },
  { Id: 2, Description: 'Client' },
];

export const travelerRequestedHousingTypeOption = [
  { Id: 1, Description: 'Private Studio' },
  { Id: 2, Description: 'Private One Bedroom' },
  { Id: 3, Description: 'Shared Two Bedroom' },
  { Id: 4, Description: 'Subsidy' },
  { Id: 5, Description: 'Private Two Bedroom' },
  { Id: 6, Description: 'Private Three Bedroom' },
  { Id: 7, Description: 'Shared Three Bedroom' },
  { Id: 8, Description: 'Shared Four Bedroom' },
  { Id: 9, Description: 'Dormitory' },
];

export const actualHousingOption = [
  { Id: 1, Description: 'Private Studio' },
  { Id: 2, Description: 'Private One Bedroom' },
  { Id: 3, Description: 'Shared Two Bedroom' },
  { Id: 4, Description: 'Subsidy' },
  { Id: 5, Description: 'Private Two Bedroom' },
  { Id: 6, Description: 'Private Three Bedroom' },
  { Id: 7, Description: 'Shared Three Bedroom' },
  { Id: 8, Description: 'Shared Four Bedroom' },
  { Id: 9, Description: 'Dormitory' },
];

export const reasonForChangeOption = [
  { ID: 1, Id: 1, Description: 'Pays to Stay' },
  { ID: 2, Id: 2, Description: 'Agrees to Move' },
  { ID: 3, Id: 3, Description: 'Availability' },
  { ID: 4, Id: 4, Description: 'Same Price as Requested' },
  { ID: 5, Id: 5, Description: 'Traveler Requested' },
  { ID: 6, Id: 6, Description: 'Other' },
  { ID: 7, Id: 7, Description: 'Vacancy' },
  { ID: 8, Id: 8, Description: 'Hotel' },
];

export const gridFilterRowCount = (state: GridState) => {
  const filterRowCount = Object.entries(state.filter.filteredRowsLookup ?? {})?.filter(item => item[1]).length;
  return filterRowCount;
};

export const validDecimalAndLength = (
  value: string,
  restrictions: { numberLength: number; decimalLength: number },
): boolean => {
  const regex = new RegExp(
    `^([0-9]{0,${restrictions.numberLength}}[.]{1}[0-9]{0,${restrictions.decimalLength}}|[0-9]{0,${restrictions.numberLength}})$`,
  );
  const validate = value.match(regex);
  // Code: Uncomment to restrict overall length regardless of decimal place
  return validate !== null;
};

export const findKeyBaseOnValue = (arrayObj: any, value: string) => {
  return arrayObj.find(item => item.Description === value);
};
export const findOptionBasedOnId = (arrayObj: any, id: number) => {
  return arrayObj.find(item => item.ID === id);
};

export const sanitizeString = str => {
  const val =
    str &&
    str
      ?.replaceAll(' ', '%20')
      .replaceAll('!', '%21')
      .replaceAll('"', '%22')
      .replaceAll('#', '%23')
      .replaceAll('$', '%24')
      .replaceAll('%', '%25')
      .replaceAll('&', '%26')
      .replaceAll("'", '%27')
      .replaceAll('(', '%28')
      .replaceAll(')', '%29')
      .replaceAll('*', '2A')
      .replaceAll('+', '%2B')
      .replaceAll(',', '%2C')
      .replaceAll('/', '%2F')
      .replaceAll(':', '%3A')
      .replaceAll(';', '%3B')
      .replaceAll('=', '%3D')
      .replaceAll('?', '%3F')
      .replaceAll('@', '%40')
      .replaceAll('[', '%5B')
      .replaceAll(']', '%5D');
  return val;
};

export const getModName = mod => mod?.type?.replaceAll(/['.\s]/g, '');
export const dataSorting = (data: any) => {
  return data.sort((a, b) => (a.Description > b.Description ? 1 : -1));
};

export const handleHousingDeposits = (deposits: any, depositId: number, value: string, type: string) => {
  if (deposits && deposits.length > 0) {
    const updateValues = deposits.map(item => {
      if (item.housingDepositTypeID === depositId) {
        if (type === 'amount') {
          let updateValue: any = {};
          if (parseFloat(value) > 0) {
            updateValue = { amount: parseFloat(value), include: 'Y' };
          } else {
            updateValue = { amount: parseFloat(value), include: 'N', refundable: 'N' };
          }
          return { ...item, ...updateValue };
        }
        if (type === 'refundable') {
          return { ...item, refundable: value };
        }
      } else {
        return item;
      }
    });
    return updateValues;
  } else {
    return deposits;
  }
};

export const removeWhichhasZerosId = () => {
  const storage = JSON.parse(localStorage.getItem('housingBannerMsg'));
  if (storage && storage.length > 0) {
    // if record reached to 100 removing initial 50
    const slicedArray = storage.length === 100 ? storage.slice(50) : storage;
    localStorage.setItem('housingBannerMsg', JSON.stringify(slicedArray));
    // if recored has zero travelerOptionId removing it
    for (let index = 0; index < slicedArray.length; index++) {
      const element = slicedArray[index];
      if (element.travelerOptionId === 0) {
        slicedArray.splice(index, 1);
      }
    }

    localStorage.setItem('housingBannerMsg', JSON.stringify(slicedArray));
  }
};
export function snackBarForModSave(response, modName) {
  if (httpSuccess(response.status) && response.data) {
    return {
      message: i18next.t('placement.placementMods.placementModsAPIMessages.saveSuccess'),
      severity: 'success',
    };
  } else {
    if (!response?.data || response?.data?.responseCode === -2) {
      return {
        message: i18next.t('placement.placementMods.placementModsAPIMessages.apifailed'),
        severity: 'error',
      };
    } else {
      return {
        message: `${modName} ${i18next.t('placement.placementMods.placementModsAPIMessages.apiError')}`,
        severity: 'error',
      };
    }
  }
}

export const modsName = {
  minReqHours: 'Minimum Required Hours',
  payRate: 'Pay Rate',
  lodging: 'Lodging Per Diem',
  endDate: 'End Date',
  shift: 'Shift',
  reimbursement: 'Reimbursement',
  startDate: 'Start Date',
  emiLmo: 'EMI LMO',
  preference: 'Preference',
  timeOff: 'Time Off',
  furniture: 'Furniture',
  payrollShift: 'Payroll Shift Differential',
  insurance: 'Insurance',
  billRate: 'Bill Rate',
  bonus: 'Bonus',
  ogp: 'OGP International Loan',
  pto: 'PTO',
  billingShift: 'Billing Shift Differential',
  housing: 'Housing',
};
