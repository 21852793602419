/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable i18next/no-literal-string */
import { GridCellParams } from '@mui/x-data-grid-pro';
import { materialUiXGrid } from 'amn-ui-core';
import { Box, ClickAwayListener } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { CustomTooltip } from '../../Tooltips';
import statesData from 'app/assets/jsons/State.json';
import { missingField } from 'app/constants';
import _ from 'lodash';

const useStyles = makeStyles()({
  hidden: {
    display: 'none',
  },
  input: {
    width: '100%',
    height: '30px',
    border: 'none',
    '&:focus': {
      border: '1px dotted gray',
      outline: 'none',
    },
  },
  inputInvalid: {
    color: 'red',
  },
  inputValid: {
    color: '#a3a2a2',
  },
});

interface XGridEditableCellProps {
  onEndEdit: ({ value, params }: { value: any; params: GridCellParams }) => void;
  validator?: ({ value, params }: { value: any; params: GridCellParams }) => boolean;
  placeholder?: string;
  mandatory?: ({ value, params }: { value: any; params: GridCellParams }) => boolean;
  editOnSingleClick?: boolean;
  spellCheck?: boolean;
  Input?: any;
  fetchData?: ({ value }: { value: string }) => any;
  externalValidator?: ({ value, params }: { value: any; params: GridCellParams }) => any;
  disableCellEdit?: ({ value, params }: { value: any; params: GridCellParams }) => boolean;
}

export const XGridEditableCell =
  ({ ...props }: XGridEditableCellProps) =>
  (params: GridCellParams) => {
    return <XGridEditableCellContent params={params} props={props} />;
  };

const XGridEditableCellContent = ({ props, params }: { props: XGridEditableCellProps; params: GridCellParams }) => {
  const { classes, cx } = useStyles();
  const api = materialUiXGrid.useGridApiContext();
  const {
    onEndEdit,
    validator,
    placeholder,
    mandatory,
    spellCheck,
    editOnSingleClick = false,
    Input,
    fetchData,
    externalValidator,
    disableCellEdit,
  } = props;

  const [editing, setEditing] = React.useState<boolean>(false);
  const [editValue, setEditValue] = React.useState<any>(params.value);
  const [valid, setValid] = React.useState<boolean>(true);
  const [firstInteraction, setFirstInteraction] = React.useState<boolean>(true);
  const [zipMetaData, setZipMetaData] = React.useState<any>();

  React.useEffect(() => {
    if (!_.isString(params.value)) {
      setEditValue('');
    } else {
      setEditValue(params.value);
    }
    if (validator && !firstInteraction && _.isString(params.value)) {
      setValid(validator({ value: editValue, params: params }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.value, validator]);

  React.useEffect(() => {
    if (!_.isString(params.value)) {
      setEditValue('');
    } else {
      setEditValue(params.value);
    }
    if (externalValidator && !firstInteraction && _.isString(params.value)) {
      setValid(externalValidator({ value: editValue, params: params }) ? false : true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.value, externalValidator]);

  React.useEffect(() => {
    if (validator && !firstInteraction) {
      setValid(validator({ value: editValue, params: params }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, params.value, validator]);

  const startEditing = React.useCallback(() => {
    setFirstInteraction(false);
    if (!editOnSingleClick) setEditing(true);
    setTimeout(() => {
      if (editOnSingleClick) setEditing(true);
      document.getElementById(`grid-cell-input-${params.row.id}-${params.colDef.field}`)?.focus();
    }, 100);
  }, [editOnSingleClick, params.colDef.field, params.row.id]);

  const _onEndEditing = () => {
    if (editing) {
      setEditing(false);
      if (params.colDef.field === 'zip') {
        api?.current?.updateRows([
          {
            ...params.row,
            [params.colDef.field]: editValue,
            city: zipMetaData ? zipMetaData?.city : params.row.city,
            state: zipMetaData
              ? statesData.states.find(x => x.Description == zipMetaData?.state)?.StateNumber
              : params.row.state,
            stateProvinceID: zipMetaData
              ? statesData.states.find(x => x.Description == zipMetaData?.state)?.ID
              : params.row.stateProvinceID,
            country: editValue ? 'USA' : null,
            countryId: 1,
          },
        ]);
      } else api?.current?.updateRows([{ ...params.row, [params.colDef.field]: editValue }]);
      onEndEdit({ value: editValue, params: params });
      if (validator) {
        setValid(validator({ value: editValue, params: params }));
      }
    }
  };

  const onKeyUp = React.useCallback(
    event => {
      if (
        event.keyCode === 13 &&
        document.activeElement?.getAttribute('data-field') === params.field &&
        document.activeElement?.getAttribute('data-rowindex') ===
          `${api?.current?.getRowIndexRelativeToVisibleRows(params.id)}`
      ) {
        startEditing();
      }
    },
    [api, params.field, params.id, startEditing],
  );

  React.useEffect(() => {
    document.addEventListener('keyup', onKeyUp);
    return () => {
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [onKeyUp, params]);

  const onInputChanged = value => {
    if (params.colDef.field === 'zip' && params.row.country === 'USA' && value?.length > 4) {
      fetchData?.(value).then(data => {
        if (data?.length > 0) {
          setZipMetaData({ city: data[0].city, state: data[0].stateDescription });
        }
      });
    }
    setEditValue(value);
  };

  const checkDisabled = () => {
    if (disableCellEdit) {
      return disableCellEdit({ value: editValue, params: params });
    } else return false;
  };

  return (
    <React.Fragment>
      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={_onEndEditing}>
        <div style={{ width: '100%' }} className={cx({ [classes.hidden]: !editing })}>
          {Input ? (
            <Input params={params} onChange={onInputChanged} onEndEdit={_onEndEditing} />
          ) : (
            <input
              autoComplete="off"
              id={`grid-cell-input-${params.row.id}-${params.colDef.field}`}
              onChange={event => {
                onInputChanged(event.target.value);
              }}
              value={editValue}
              className={cx(classes.input)}
              onSubmit={_onEndEditing}
              onKeyDown={event => event.stopPropagation()} //Adding this to make sure we can enter 'Space key' (or other special keys) while Editing a Cell (Default behaviour of Space key on XGrid is to navigate to the last cell of a column)
              onKeyUp={event => {
                if (event.keyCode === 13) _onEndEditing();
                else if (event.keyCode === 27) {
                  setEditValue(params.value);
                  setEditing(false);
                }
              }}
              disabled={checkDisabled()}
              spellCheck={spellCheck}
            />
          )}
        </div>
      </ClickAwayListener>
      <Box
        onClick={editOnSingleClick ? startEditing : undefined}
        onDoubleClick={!editOnSingleClick ? startEditing : undefined}
        className={cx({
          [classes.hidden]: editing,
          [classes.inputInvalid]: !valid || params.row[`${params.field}_error`] === true,
        })}
        style={{ width: '100%', minHeight: '20px' }}
      >
        {editValue ? (
          externalValidator?.({ value: editValue, params: params }) ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ padding: '0px 5px 2px 0px' }}>
                <CustomTooltip tooltipContent={externalValidator?.({ value: editValue, params: params })}>
                  <WarningIcon style={{ color: 'red', height: '14px', width: '14px' }} />
                </CustomTooltip>
              </span>
              <span
                className={cx({
                  [classes.inputInvalid]: !valid || params.row[`${params.field}_error`] === true,
                  [classes.inputValid]: valid && !params.row[`${params.field}_error`],
                })}
              >
                {editValue}
              </span>
            </div>
          ) : (
            editValue
          )
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {(mandatory?.({ value: params.value, params }) ||
              (params.colDef.field === 'reasonForLeaving' && params.row.facilityName !== 'Personal Time Off')) &&
              (!validator?.({ value: params.value, params }) || false) && (
                <span style={{ padding: '0px 5px 2px 0px' }}>
                  <CustomTooltip tooltipContent={'This field is required'}>
                    <WarningIcon style={{ color: 'red', height: '14px', width: '14px' }} />
                  </CustomTooltip>
                </span>
              )}
            <span
              className={cx({
                [classes.inputInvalid]: !valid || params.row[`${params.field}_error`] === true,
                [classes.inputValid]: valid && !params.row[`${params.field}_error`],
              })}
            >{`${
              !params.value && !mandatory?.({ value: params.value, params }) && checkDisabled()
                ? missingField
                : placeholder || params.colDef.headerName
            }`}</span>
          </div>
        )}
      </Box>
    </React.Fragment>
  );
};
