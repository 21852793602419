import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { getPassportPreferences } from 'app/services/CandidateServices/CandidateServices';
import { getSearchFilterOptions } from 'app/services/SharedServices/SharedServices';
import { trackPromise } from 'react-promise-tracker';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { processPassportPreCheckPreferences } from '../helper';
import { passportPreCheckPreferencesActions } from './PassportPreCheckPreferences.redux';

const TrackPassportPreferences = (fn, ...args) => trackPromise(fn(...args), 'get-passport-preferences');

export function* getPassportPreCheckPreferences(
  action: PayloadAction<{
    travelerId: number;
  }>,
) {
  try {
    const [response, disciplines, specialties] = yield all([
      call(TrackPassportPreferences, getPassportPreferences, action.payload.travelerId),
      call(getSearchFilterOptions, 'disciplines'),
      call(getSearchFilterOptions, 'specialties'),
    ]);

    const data: any[] = processPassportPreCheckPreferences(response?.data, disciplines, specialties);
    if (response) {
      yield put(passportPreCheckPreferencesActions.setData(data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getPassportPreCheckPreferences',
        area: 'src/app/components/Candidate/CandidateProfile/CandidateTabPanel/JobPreferencesTab/PassportPreCheckPreferences/store/PassportPreCheckPreferences.saga.ts',
      },
    });
  }
}

export function* passportPreCheckPreferencesSaga() {
  yield takeLatest(
    passportPreCheckPreferencesActions.getPassportPreCheckPreferencesAction.type,
    getPassportPreCheckPreferences,
  );
}
