import React from 'react';
import { DrawerFitted } from '@AMIEWEB/Common';
import { Divider, useTheme } from 'amn-ui-core';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { HeaderNavigationPanel, TabsSection, UserInfoPanel } from './ManageSidePanelComponent';

export interface IDrawerTabs {
  id: number;
  name: string;
  component: React.ReactElement;
}

const ClientContractPanel = ({
  open,
  tabs,
  handleClose,
}: {
  open: boolean;
  tabs: IDrawerTabs[];
  handleClose: () => void;
}) => {
  const theme = useTheme();
  return (
    <DrawerFitted
      open={open}
      width={450}
      top={0}
      backgroundColor={theme.palette.framework.system.white}
      shadow
    >
      <DrawerContent tabs={tabs} handleClose={() => handleClose()} />
    </DrawerFitted>
  );
};

const DrawerContent = ({ tabs, handleClose }: { tabs: IDrawerTabs[]; handleClose: () => void }) => {
  return (
    <LayoutGrid container>
      <LayoutGridItem item xs={12}>
        <HeaderNavigationPanel handleClose={() => handleClose()} />
        <Divider sx={{ width: '100%' }} />
        <UserInfoPanel />
      </LayoutGridItem>

      <LayoutGridItem item xs={12}>
        <TabsSection tabs={tabs} />
      </LayoutGridItem>
    </LayoutGrid>
  );
};

export default ClientContractPanel;
