export enum AMNDivisionType {
  unknown = 0,
  allied = 1,
  intl = 2,
  amnNurse = 3,
  inDemand = 4,
  localStaffing = 5,
  hsg = 6,
}
export enum ActiveStatusType {
  WFC = 1,
  RFO = 2,
}

export const AMNDivision = {
  Travel: 'AMN Nurse',
  Local: 'Local Staffing',
  HSG: 'AMN Labor Disruption',
  Allied: 'Allied',
  InDemand: 'InDemand',
  Intl: "Int'l",
};
