import { Alert, CircularProgress } from '@mui/material';
import { CalculationTypes, OrderBonusFieldNames } from '../Common/Constants';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React from 'react';
import {
  bonusApprovedMethodOptions,
  bonusBillingTypeOptions,
  bonusLessThan13WeeksOptions,
  bonusPayerOptions,
  bonusPaymentTypeOptions,
  bonusShortHourTypeOptions,
  bonusTypeOptions,
  shiftOptions,
} from '../Common/OptionsHelper';
import { bonusTypeSelectionDefaultValues } from '../Common/Utils';
import { FormInputDatePicker } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/PersonalInfoTab/Form/Controls/FormInputDatePicker';
import { OrderBonusAutoComplete } from '../CustomComponents/OrderBonusAutoComplete';
import { OrderBonusTextField } from '../CustomComponents/OrderBonusTextField';
import { Theme } from 'amn-ui-core';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { useFormContext } from 'react-hook-form';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()((theme: Theme) => ({
  leftGridContainer: { maxHeight: 'calc(100vh - 140px)', overflowY: 'scroll' },
  controlsGridContainer: { marginTop: 30, paddingLeft: 50, paddingRight: 50 },
  datePickerInput: {
    height: '55px',
    width: 'auto',
    '& .css-bw8hcn-MuiInputBase-input-MuiFilledInput-input.Mui-disabled': {
      WebkitTextFillColor: theme.palette.system.black,
    },
  },
  datePickerLabel: {
    fontSize: '14px !important',
    color: theme.palette.text.secondary,
    '&.Mui-focused': {
      color: theme.palette.system.navyBlue,
    },
    '&[class*="MuiInputLabel-outlined"]': {
      marginTop: '1px',
    },
    '&[class*="Mui-disabled"]': {
      color: theme.palette.system.black,
    },
  },

  validationLoader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: '50%',
  },
}));

export const OrderBonusForm = props => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { setValue, watch } = useFormContext();
  const {
    disableControls,

    isNewOrderBonus,
    errorMessage,
  } = props;
  const { promiseInProgress: isOrderBonusShiftsAPI } = usePromiseTracker({
    area: 'get-order-bonus-shift-list',
    delay: 0,
  });
  const { promiseInProgress: isCalculatedAmountAPI } = usePromiseTracker({
    area: 'get-order-bonus-calculated-amount',
    delay: 0,
  });
  const { promiseInProgress: isSaveCallingAPI } = usePromiseTracker({ area: 'save-order-bonus', delay: 0 });

  return (
    <>
      {(isOrderBonusShiftsAPI || isCalculatedAmountAPI || isSaveCallingAPI) && (
        <CircularProgress className={classes.validationLoader} />
      )}

      <LayoutGridItem xs={12} style={{ minWidth: '500px' }}>
        {errorMessage && (
          <Alert severity="error" style={{ position: 'sticky', top: 0, zIndex: 9999 }}>
            {errorMessage}
          </Alert>
        )}
      </LayoutGridItem>
      <LayoutGrid container item xs={9} direction="column" className={classes.leftGridContainer}>
        <LayoutGrid container direction="row" className={classes.controlsGridContainer}>
          <LayoutGridItem item xs={6}>
            <OrderBonusAutoComplete
              name={OrderBonusFieldNames.bonusType}
              options={_.orderBy(bonusTypeOptions, 'Description', 'asc')}
              onChange={value => {
                if (isNewOrderBonus) {
                  bonusTypeSelectionDefaultValues(value).forEach(field =>
                    setValue(field.Name, field.Value, { shouldDirty: true }),
                  );
                }
              }}
              disabled={disableControls}
            />
          </LayoutGridItem>

          <LayoutGridItem item xs={6}>
            <OrderBonusTextField
              name={OrderBonusFieldNames.bonusAmount}
              decimalField
              calculationType={CalculationTypes.bonusRate}
              bonusBillAmountValue={watch(OrderBonusFieldNames.bonusBillAmount)}
              {...props}
            />
          </LayoutGridItem>

          <LayoutGridItem item xs={6}>
            <FormInputDatePicker
              name={OrderBonusFieldNames.startDate}
              label={t('order.orderBonusTab.formFields.startDate')}
              handleChange={event => setValue(OrderBonusFieldNames.startDate, event, { shouldDirty: true })}
              inputValue={watch(OrderBonusFieldNames.startDate) ?? ''}
              customClass={classes.datePickerInput}
              labelClass={classes.datePickerLabel}
              require={true}
              maxWidth={520}
              trailingIconVisible={true}
              readOnly={disableControls}
            ></FormInputDatePicker>
          </LayoutGridItem>

          <LayoutGridItem item xs={6}>
            <FormInputDatePicker
              name={OrderBonusFieldNames.endDate}
              label={t('order.orderBonusTab.formFields.endDate')}
              handleChange={event => setValue(OrderBonusFieldNames.endDate, event, { shouldDirty: true })}
              inputValue={watch(OrderBonusFieldNames.endDate) ?? ''}
              customClass={classes.datePickerInput}
              labelClass={classes.datePickerLabel}
              maxWidth={520}
              require={true}
              trailingIconVisible={true}
              readOnly={disableControls}
            ></FormInputDatePicker>
          </LayoutGridItem>

          <LayoutGridItem item xs={6}>
            <OrderBonusAutoComplete
              name={OrderBonusFieldNames.payer}
              options={_.orderBy(bonusPayerOptions, 'Description', 'asc')}
              onChange={value => setValue(OrderBonusFieldNames.payer, value)}
              disabled={disableControls}
            />
          </LayoutGridItem>

          <LayoutGridItem item xs={6}>
            <OrderBonusAutoComplete
              name={OrderBonusFieldNames.paymentType}
              options={_.orderBy(bonusPaymentTypeOptions, 'Description', 'asc')}
              onChange={value => setValue(OrderBonusFieldNames.paymentType, value)}
              disabled={disableControls}
            />
          </LayoutGridItem>

          <LayoutGridItem item xs={6}>
            <OrderBonusAutoComplete
              name={OrderBonusFieldNames.shortHourType}
              options={_.orderBy(bonusShortHourTypeOptions, 'Description', 'asc')}
              onChange={value => setValue(OrderBonusFieldNames.shortHourType, value)}
              disabled={disableControls}
            />
          </LayoutGridItem>
          <LayoutGridItem item xs={6}>
            <OrderBonusAutoComplete
              name={OrderBonusFieldNames.lessThan13Weeks}
              options={_.orderBy(bonusLessThan13WeeksOptions, 'Description', 'asc')}
              onChange={value => setValue(OrderBonusFieldNames.lessThan13Weeks, value)}
              disabled={disableControls}
            />
          </LayoutGridItem>

          <LayoutGridItem item xs={6}>
            <OrderBonusTextField name={OrderBonusFieldNames.minWeeksWorked} numberField {...props} />
          </LayoutGridItem>
          <LayoutGridItem item xs={6}></LayoutGridItem>

          <LayoutGridItem item xs={4}>
            <OrderBonusAutoComplete
              name={OrderBonusFieldNames.billingType}
              options={_.orderBy(bonusBillingTypeOptions, 'Description', 'asc')}
              onChange={value => setValue(OrderBonusFieldNames.billingType, value)}
              disabled={disableControls}
            />
          </LayoutGridItem>

          <LayoutGridItem item xs={4}>
            <OrderBonusTextField name={OrderBonusFieldNames.bonusBillAmount} decimalField {...props} />
          </LayoutGridItem>

          <LayoutGridItem item xs={4}>
            <OrderBonusTextField
              name={OrderBonusFieldNames.billingAmount}
              decimalField
              calculationType={CalculationTypes.companyBillRate}
              bonusBillAmountValue={watch(OrderBonusFieldNames.bonusBillAmount)}
              {...props}
            />
          </LayoutGridItem>

          <LayoutGridItem item xs={4}>
            <OrderBonusTextField name={OrderBonusFieldNames.approvedBy} maxLength={255} {...props} />
          </LayoutGridItem>

          <LayoutGridItem item xs={4}>
            <OrderBonusAutoComplete
              name={OrderBonusFieldNames.approvalType}
              options={_.orderBy(bonusApprovedMethodOptions, 'Description', 'asc')}
              onChange={value => setValue(OrderBonusFieldNames.approvalType, value)}
              disabled={disableControls}
            />
          </LayoutGridItem>

          <LayoutGridItem item xs={4}>
            <FormInputDatePicker
              name={OrderBonusFieldNames.approvalDate}
              label={t('order.orderBonusTab.formFields.approvalDate')}
              handleChange={event => setValue('approvalDate', event, { shouldDirty: true })}
              inputValue={watch(OrderBonusFieldNames.approvalDate) ?? ''}
              customClass={classes.datePickerInput}
              labelClass={classes.datePickerLabel}
              maxWidth={360}
              trailingIconVisible={true}
              require={true}
              readOnly={disableControls}
            ></FormInputDatePicker>
          </LayoutGridItem>

          <LayoutGridItem item xs={6}>
            <OrderBonusTextField
              name={OrderBonusFieldNames.notes}
              notesField
              notesValue={watch(OrderBonusFieldNames.notes)}
              maxLength={6000}
              characterLimit={6000}
              {...props}
            />
          </LayoutGridItem>

          <LayoutGridItem item xs={6}>
            <OrderBonusAutoComplete
              name={OrderBonusFieldNames.shiftsApplicable}
              label={t('order.orderBonusTab.formFields.shiftsApplicable')}
              options={shiftOptions}
              multiple={true}
              disabled={disableControls}
            />
          </LayoutGridItem>
        </LayoutGrid>
      </LayoutGrid>
    </>
  );
};
