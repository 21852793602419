import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IBanner,
  ICandidate,
  IFacility,
  IFailedService,
  IGlobal,
  IGlobalSnackBar,
  IOrder,
  ISnackBar,
} from 'app/models/Global/Global';
import { initialEmailSignature, initialNotificationPreference } from 'oidc/UserDevicePreference/utils';

export const defaultSnackbar: ISnackBar = {
  message: undefined,
  severity: undefined,
};

export const initialState: IGlobal = {
  sessionLoaded: false,
  isProfileOpen: false,
  isEmailSignatureExpanded: false,
  emailSignature: initialEmailSignature.value,
  notificationPreference: initialNotificationPreference.value,
  banner: {
    open: false,
    message: null,
  },
  candidateDrawerData: {
    open: false,
    candidate: {
      candidateId: 0,
      brandId: 0,
    },
  },
  facilityDrawerData: {
    open: false,
    facility: {
      facilityId: 0,
    },
  },
  orderDrawerData: {
    open: false,
    order: {
      orderId: '',
    },
  },
  detailsPageDrawer: {
    open: false,
  },
  smsIconDrawer: {
    open: false,
  },
  candidateProfileDrawer: {
    open: false,
  },
  activeStep: 1,
  deletedAttachments: [],
  newAppVersionAvailable: false,
  catastrophicDialog: {
    open: false,
  },
  failedServices: [],
  globalSnackbar: {
    singleMessage: [],
    messageQueues: [],
  },
};

const globalSlice = createSlice({
  name: 'global',
  initialState: initialState,
  reducers: {
    setNewAppVersionAvailable(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        newAppVersionAvailable: action.payload,
      };
    },
    setSessionLoaded(state, action: PayloadAction) {
      return {
        ...state,
        sessionLoaded: true,
      };
    },
    /**
     * External action. Call this to set a snackbar. This supports a Queue that can be called multiple times
     */
    setSnackBar(state, action: PayloadAction<IGlobalSnackBar>) {
      const queueData = {
        ...action.payload,
        id: action.payload.id || new Date().getTime() + Math.random(),
        severity: action.payload?.severity || null,
      };
      const isMessageExist =
        state.globalSnackbar.singleMessage?.find(notification => notification.message === action.payload.message) ??
        false;
      if (!isMessageExist)
        return {
          ...state,
          globalSnackbar: {
            ...state.globalSnackbar,
            singleMessage: [...state.globalSnackbar.singleMessage, queueData],
          },
        };

      return state;
    },
    /** External action to remove the completed snackbar from the queue */
    closeSnackBar(state, action: PayloadAction<IGlobalSnackBar>) {
      return {
        ...state,
        globalSnackbar: {
          ...state.globalSnackbar,
          singleMessage: state.globalSnackbar.singleMessage.filter(
            (messageQueue: IGlobalSnackBar) => messageQueue.id !== action.payload.id,
          ),
        },
      };
    },
    /**
     * External action. Call this to set a snackbar. This supports a Queue that can be called multiple times
     */
    setStackSnackBar(state, action: PayloadAction<Omit<IGlobalSnackBar, 'className'>>) {
      const queueData = {
        ...action.payload,
        id: action.payload.id || new Date().getTime() + Math.random(),
        severity: action.payload?.severity || null,
      };
      const isMessageExist =
        state.globalSnackbar.messageQueues?.find(notification => notification.message === action.payload.message) ??
        false;
      if (!isMessageExist)
        return {
          ...state,
          globalSnackbar: {
            ...state.globalSnackbar,
            messageQueues: [...state.globalSnackbar.messageQueues, queueData],
          },
        };

      return state;
    },
    /** External action to remove the completed snackbar from the queue */
    closeStackSnackBar(state, action: PayloadAction<IGlobalSnackBar>) {
      return {
        ...state,
        globalSnackbar: {
          ...state.globalSnackbar,
          messageQueues: state.globalSnackbar.messageQueues.filter(
            (messageQueue: IGlobalSnackBar) => messageQueue.id !== action.payload.id,
          ),
        },
      };
    },
    setBanner(state, action: PayloadAction<Omit<IBanner, 'open'>>) {
      return {
        ...state,
        banner: {
          ...action.payload,
          open: true,
        },
      };
    },
    closeBanner(state, action: PayloadAction) {
      return {
        ...state,
        banner: {
          open: false,
          message: null,
          severity: 'warning',
          position: 'flex-start',
        },
      };
    },
    setProfileOpen(state, action: PayloadAction<boolean>) {
      state.isProfileOpen = action.payload;
    },
    setEmailSignature(state, action: PayloadAction<any>) {
      state.emailSignature = action.payload;
    },
    setEmailSignatureExpanded(state, action: PayloadAction<any>) {
      state.isEmailSignatureExpanded = action.payload;
    },
    setNotificationPreference(state, action: PayloadAction<any>) {
      state.notificationPreference = action.payload;
    },
    setCandidateDrawerData(state, action: PayloadAction<{ open: boolean; candidate: ICandidate }>) {
      state.candidateDrawerData = action.payload;
    },
    resetCandidateDrawerData(state) {
      state.candidateDrawerData = { open: false, candidate: { candidateId: 0, brandId: 0 } };
    },
    setFacilityDrawerData(state, action: PayloadAction<{ open: boolean; facility: IFacility }>) {
      state.facilityDrawerData = action.payload;
    },
    resetFacilityDrawerData(state) {
      state.facilityDrawerData = { open: false, facility: { facilityId: 0 } };
    },
    setOrderDrawerData(state, action: PayloadAction<{ open: boolean; order: IOrder }>) {
      state.orderDrawerData = action.payload;
    },
    resetOrderDrawerData(state) {
      state.orderDrawerData = { open: false, order: { orderId: '' } };
    },
    resetAllRecentHistoryDrawers(state) {
      state.candidateDrawerData = { open: false, candidate: { candidateId: 0, brandId: 0 } };
      state.facilityDrawerData = { open: false, facility: { facilityId: 0 } };
      state.orderDrawerData = { open: false, order: { orderId: '' } };
    },
    setDetailsPageDrawer(state, action: PayloadAction<{ open: boolean }>) {
      state.detailsPageDrawer = action.payload;
    },
    setSmsIconDrawer(state, action: PayloadAction<{ open: boolean }>) {
      state.smsIconDrawer = action.payload;
    },
    setCandidateProfileDrawer(state, action: PayloadAction<{ open: boolean }>) {
      state.candidateProfileDrawer = action.payload;
    },
    setActiveStep(state, action: PayloadAction<number>) {
      state.activeStep = action.payload;
    },
    setDeletedAttachments(state, action: PayloadAction<String[]>) {
      state.deletedAttachments = action.payload;
    },
    setCatastrophicDialog(state, action: PayloadAction) {
      return {
        ...state,
        catastrophicDialog: {
          open: true,
        },
      };
    },
    closeCatastrophicDialog(state, action: PayloadAction) {
      return {
        ...state,
        catastrophicDialog: {
          open: false,
        },
      };
    },
    setFailedServices(state, action: PayloadAction<{ failedServices: IFailedService[] }>) {
      state.failedServices = action.payload.failedServices;
    },
    resetFailedServices(state) {
      state.failedServices = [];
    },
  },
});

const validateAppVersion = createAction<
  { interval?: number; forceValidation?: boolean; updateVersionNumberWithoutNotice?: boolean } | undefined
>('VALIDATE_APP_VERSION');

const { actions } = globalSlice;

export const { reducer: globalReducer, name: globalSliceKey } = globalSlice;
export const globalActions = {
  ...actions,
  validateAppVersion,
};
