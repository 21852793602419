import { GridLinkOperator } from '@mui/x-data-grid-pro';

export const formatPlacementReferences = candidateRequirement => {
  const formated = {
    references: {
      sectionName: 'References',
      sectionCount: candidateRequirement?.data?.placementReferences?.references ?? 0,
      isExpired: null,
      details: [
        {
          isExpired: candidateRequirement?.type === 3 ? false : null,
          sectionName: 'Approved',
          sectionCount: candidateRequirement?.data?.placementReferences?.approvedReferences ?? 0,
        },
        {
          isExpired: null,
          sectionName: 'Verified',
          sectionCount: candidateRequirement?.data?.placementReferences?.verifiedReferences ?? 0,
        },
        {
          isExpired: null,
          sectionName: 'Reference in Progress',
          sectionCount: candidateRequirement?.data?.placementReferences?.inProgressReferences ?? 0,
        },
        {
          isExpired: null,
          sectionName: 'Unapproved',
          sectionCount: candidateRequirement?.data?.placementReferences?.unapprovedReferences ?? 0,
        },
      ],
    },
    employmentVerifications: {
      sectionName: 'Employment Verifications',
      sectionCount: candidateRequirement?.data?.employmentReferences?.employmentVerifications ?? 0,
      isExpired: null,
      details: [
        {
          isExpired: null,
          sectionName: 'Approved',
          sectionCount: candidateRequirement?.data?.employmentReferences?.approvedEmploymentVerifications ?? 0,
        },
        {
          isExpired: null,
          sectionName: 'Verified',
          sectionCount: candidateRequirement?.data?.employmentReferences?.verifiedEmploymentVerifications ?? 0,
        },
        {
          isExpired: null,
          sectionName: 'Reference in Progress',
          sectionCount: candidateRequirement?.data?.employmentReferences?.inProgressEmploymentVerifications ?? 0,
        },
      ],
    },
    candidateReferenceDetailResponses: candidateRequirement?.data?.candidateReferenceDetailResponses ?? [],
  };

  return formated;
};

export const getPlacementReferencesFilterModel = (sectionName, category) => {
  if (sectionName === 'References') {
    const filters: any = {
      items: [
        {
          columnField: 'referenceType',
          operatorValue: 'includes',
          value: ['Checkster', 'Evaluation', 'Online', 'SSC Eval', 'Telephone', 'Web', 'Written'],
        },
      ],
      linkOperator: GridLinkOperator.And,
    };
    if (
      category === 'Approved' ||
      category === 'Verified' ||
      category === 'Reference in Progress' ||
      category === 'Unapproved'
    ) {
      filters.items.push({ columnField: 'status', operatorValue: 'equals', value: category });
    }
    return filters;
  } else if (sectionName === 'Employment Verifications') {
    const filters: any = {
      items: [
        {
          columnField: 'referenceType',
          operatorValue: 'equals',
          value: 'EMPV',
        },
      ],
      linkOperator: GridLinkOperator.And,
    };
    if (category === 'Approved' || category === 'Verified' || category === 'Reference in Progress') {
      filters.items.push({ columnField: 'status', operatorValue: 'equals', value: category });
    }
    return filters;
  }
};
