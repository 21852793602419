import { takeLatest, put, call, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { initialFormState, preferenceActions, PreferenceDirtyFields } from './Preference.redux';
import {
  getCandidateJobPreference,
  SaveCandidateJobPreference,
} from '../../../../../../services/CandidateServices/CandidateServices';
import { selectJobPreferences } from './Preference.selectors';
import { trackPromise } from 'react-promise-tracker';
import { GenerateFormData, CreatePreferencePostData } from './helper';
import { formatTypeAheadSpecialtyOptions } from 'app/helpers/typeAheadHelper';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { lookupSelector } from 'store/redux-store/lookup/lookup.selectors';

const TrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'preference-form');

/**
 * Candidate job preference get action
 * @param action
 */
export function* requestPreferences(action: PayloadAction<{ candidateId: number; recruiterId: number | null }>) {
  try {
    const { formData } = yield select(selectJobPreferences);
    const lookupState = yield select(lookupSelector);

    const preferences = yield call(
      TrackWrapper,
      getCandidateJobPreference,
      action.payload.candidateId,
      action.payload.recruiterId,
    );

    const disciplineOpts = !!lookupState ? lookupState.disciplines : [];
    const specialtyOpts = !!lookupState ? formatTypeAheadSpecialtyOptions(lookupState.discSpecMapping) : [];
    const {
      skillSets,
      clientSettings,
      locations,
      placementTypes,
      jobExperiences,
      shiftTimes,
      shiftLengths,
      placementLengths,
      facilityTypes,
      timeOffRequests,
      miscellaneousFields,
      recruiterEnterdDataExists,
    } = GenerateFormData(preferences, formData, disciplineOpts, specialtyOpts);

    yield put(
      preferenceActions.setPreferences({
        preferenceAPIresponse: preferences,
        formData: {
          skillSets,
          clientSettings,
          locations,
          placementTypes,
          jobExperiences,
          shiftTimes,
          shiftLengths,
          placementLengths,
          facilityTypes,
          timeOffRequests,
          miscellaneousFields,
        },
        preferenceSaveEvent: false,
        recruiterEnterdDataExists,
        miscellaneousSpecs: {
          disciplineOpts,
          specialtyOpts,
        },
      }),
    );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestPreferences',
        area: 'src/app/components/Candidate/CandidateProfile/CandidateTabPanel/PreferencesTab/PreferenceForm/Preference.saga.ts',
      },
    });
  }
}

/**
 * Candidate job preference save action
 * @param action
 */
export function* savePreferences(
  action: PayloadAction<{ formData: any; dirtyFields: PreferenceDirtyFields; userData: any }>,
) {
  try {
    const prevData = yield select(selectJobPreferences);
    const createPrefCommand = {
      currentData: CreatePreferencePostData(
        action.payload.formData,
        action.payload.dirtyFields,
        prevData,
        action.payload.userData,
      ),
      candidateGuid: action.payload.userData.candidateGuid,
      userId: action.payload.userData.userId,
      userName: action.payload.userData.userName,
    };
    const response = yield call(TrackWrapper, SaveCandidateJobPreference, createPrefCommand);
    if (response) {
      const {
        skillSets,
        clientSettings,
        locations,
        placementTypes,
        jobExperiences,
        shiftTimes,
        shiftLengths,
        placementLengths,
        facilityTypes,
        timeOffRequests,
        miscellaneousFields,
        recruiterEnterdDataExists,
      } = GenerateFormData(
        response,
        initialFormState,
        prevData.miscellaneousSpecs.disciplineOpts,
        prevData.miscellaneousSpecs.specialtyOpts,
      );

      yield put(
        preferenceActions.setPreferences({
          ...prevData,
          preferenceAPIresponse: response,
          formData: {
            skillSets,
            clientSettings,
            locations,
            placementTypes,
            jobExperiences,
            shiftTimes,
            shiftLengths,
            placementLengths,
            facilityTypes,
            timeOffRequests,
            miscellaneousFields,
          },
          preferenceSaveEvent: false,
          recruiterEnterdDataExists,
        }),
      );
      yield put(preferenceActions.setPreferenceSaveEvent(true));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'savePreferences',
        area: 'src/app/components/Candidate/CandidateProfile/CandidateTabPanel/PreferencesTab/PreferenceForm/Preference.saga.ts',
      },
    });
  }
}

export function* preferenceSaga() {
  yield takeLatest(preferenceActions.requestPreferencesAction.type, requestPreferences);
  yield takeLatest(preferenceActions.savePreferencesAction.type, savePreferences);
}
