import { XGridUnitClickCell } from '@AMIEWEB/Common/XGrid/cells/XGridUnitClickCell';
import { GridColumns } from '@mui/x-data-grid';
import { XGridBlueCell } from 'app/components/Common/XGrid/cells/XGridBlueCell';
import { XGridDefaultColumn } from 'app/components/Common/XGrid/cells/XGridDefaults';
import { xGridDateSort } from 'app/components/Common/XGrid/sorting/XGridSorting';
import { missingField } from 'app/constants';
import { GetMomentOfDate } from 'utils/dates/moment';

const defaultColumnProps = { ...XGridDefaultColumn, flex: 0 };

export const getExistngUnitColumns = (onClick: Function, t): GridColumns =>
  [
    {
      ...defaultColumnProps,
      headerName: t('Unit ID'),
      field: 'unitId',
      width: 150,
      // @ts-ignore
      renderCell: XGridUnitClickCell({ onClick, id: 'xGrid-unit-id-link' }),
    },
    {
      ...defaultColumnProps,
      headerName: t('Unit Name'),
      field: 'unitName',
      width: 150,
    },
    {
      ...defaultColumnProps,
      headerName: t('Unit Status'),
      field: 'unitstatus',
      width: 150,
    },
    {
      ...defaultColumnProps,
      headerName: t('Orders'),
      field: 'orders',
      width: 150,
    },
    {
      ...defaultColumnProps,
      headerName: t('Positions'),
      field: 'positionsAvailable',
      width: 160,
    },
    {
      ...defaultColumnProps,
      headerName: t('Automation Status'),
      field: 'automationStatus',
      width: 220,
    },
    {
      ...defaultColumnProps,
      headerName: t('Last Updated'),
      field: 'lastUpdatedOn',
      width: 160,
      sortComparator: xGridDateSort,
    },
    {
      ...defaultColumnProps,
      headerName: t('Last Updated By'),
      field: 'lastUpdatedBy',
      width: 220,
    },
  ];

export const extractRows = existingUnits =>
  existingUnits.map(item => {
    const lastUpdatedDate = GetMomentOfDate(item.lastUpdatedOn);
    return {
      ...item,
      id: item.unitId,
      unitId: item.unitId ?? missingField,
      unitName: item.unitName ?? missingField,
      unitstatus: item.status ?? missingField,
      orders: item.orders ?? missingField,
      positionsAvailable: item.positionsAvailable ?? missingField,
      automationStatus: item.automationStatus ?? missingField,
      lastUpdatedOn: lastUpdatedDate.isValid() ? lastUpdatedDate.format('MM/DD/YYYY') : missingField,
      lastUpdatedBy: item.lastUpdatedBy ?? missingField,
    };
  });
