import { IToken } from 'app/models/Notification/Notification';
import { IUser } from 'oidc/user.redux';
import { IUserPreference } from 'oidc/UserDevicePreference/userPreference.redux';
import { Concatenate } from 'utils/string/string';

export const signatureSubstitution = (user: IUser, userPreference: IUserPreference): IToken[] => {
  return [
    {
      name: 'Sender Name',
      input: '{{sender_name}}',
      value:
        userPreference.emailSignature && userPreference.emailSignature.value
          ? userPreference.emailSignature?.value.filter(val => val.name === 'Sender Name')[0]?.value === ''
            ? Concatenate([user?.userInfo?.firstName || '', user?.userInfo?.lastName || ''], ' ')
            : userPreference.emailSignature?.value.filter(val => val.name === 'Sender Name')[0]?.value
          : '',
    },

    {
      name: 'Brand',
      input: '{{brand}}',
      value: `${user?.userInfo?.companyBrandName}`,
    },
    {
      name: 'Brand Id',
      input: '{{brand_id}}',
      value: `${user?.userInfo?.companyId}`,
    },
    {
      name: 'Mobile Number',
      input: '{{mobile_number}}',
      value:
        userPreference.emailSignature && userPreference.emailSignature.value
          ? userPreference.emailSignature?.value.filter(val => val.name === 'Mobile Number')[0]?.value
          : '',
    },
    {
      name: 'Direct Number',
      input: '{{direct_number}}',
      value: user.userInfo?.directNumber,
    },
    {
      name: 'Text Number',
      input: '{{text_number}}',
      value:
        userPreference.emailSignature && userPreference.emailSignature.value
          ? userPreference.emailSignature?.value.filter(val => val.name === 'Text Number')[0]?.value
          : '',
    },
    {
      name: 'Division',
      input: '{{division}}',
      value:
        userPreference.emailSignature && userPreference.emailSignature.value
          ? userPreference.emailSignature?.value.filter(val => val.name === 'Division')[0]?.value
          : '',
    },
    {
      name: 'Title',
      input: '{{title}}',
      value:
        userPreference.emailSignature && userPreference.emailSignature.value
          ? userPreference.emailSignature?.value.filter(val => val.name === 'Title')[0]?.value
          : '',
    },

    {
      name: 'Passport Link',
      input: '{{passport_link}}',
      value:
        userPreference.emailSignature && userPreference.emailSignature.value
          ? userPreference.emailSignature?.value.filter(val => val.name === 'Passport Link')[0]?.value
            ? 'true'
            : 'false'
          : 'false',
    },
    {
      name: 'Referral Link',
      input: '{{referral_link}}',
      value:
        userPreference.emailSignature && userPreference.emailSignature.value
          ? userPreference.emailSignature?.value.filter(val => val.name === 'Referral Link')[0]?.value
            ? 'true'
            : 'false'
          : 'false',
    },
    {
      name: 'Branded Signature',
      input: '{{branded_signature}}',
      value:
        userPreference.emailSignature && userPreference.emailSignature.value
          ? userPreference.emailSignature?.value.filter(val => val.name === 'Branded Signature')[0]?.value
            ? 'true'
            : 'false'
          : 'false',
    },
  ];
};
