import { Typography } from 'amn-ui-core';
import React from 'react';
import { XGridCellPadding } from '../XGridDefaults';

/**
 * Hover view renderer to display standard text
 * @param params X-Grid Params: `GridCellParams`
 * @returns JSXElement
 */
export const XGridDefaultHoverView = params => {
  return (
    <Typography variant="body2" style={{ padding: XGridCellPadding, fontSize:'14px' }}>
      {params.value}
    </Typography>
  );
};
