import { getTaskEntityValue } from 'app/components/Notification/Tasks/Common/FormatData';
import { taskDetailsActions, taskDetailsReducer, taskDetailsSliceKey } from 'app/components/Tasks/store/Tasks.redux';
import { taskDetailsSaga } from 'app/components/Tasks/store/Tasks.saga';
import {
  SelectedContainer,
  selectedUpdatedId,
  selectTaskUpdatedData,
  selectEntityId,
} from 'app/components/Tasks/store/Tasks.selectors';
import {
  NotificationBrand,
  NotificationSender,
  StatusUpdateModalVersion,
  Task,
  TaskEntity,
  TaskNotificationType,
} from 'app/models/Tasks/Tasks';
import moment from 'moment-timezone';
import { selectUser } from 'oidc/user.selectors';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

export const useEditTaskNotification = () => {
  useInjectSaga({ key: taskDetailsSliceKey, saga: taskDetailsSaga });
  useInjectReducer({ key: taskDetailsSliceKey, reducer: taskDetailsReducer });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const updatedId = useSelector(selectedUpdatedId);
  const updatedTaskData = useSelector(selectTaskUpdatedData);
  const currentContainer = useSelector(SelectedContainer);
  const globalEntityId = useSelector(selectEntityId);
  const userInfo = useSelector(selectUser);

  const formatUserData = user => {
    const name = user?.object?.label?.split(' ');
    const formatedUser = {
      name: name[0] + ' ' + name[1],
      email:'',
      phoneNumber: '',
      contactId: user?.object?.empId?.toString(),
    };
    return formatedUser;
  };

  const getAssignedToList = () => {
    const assignedToArray: any[] = [];
    updatedTaskData?.assignedTo?.forEach(assigned => {
      if (updatedTaskData?.sentBy !== assigned?.object?.empId) {
        const userData = formatUserData(assigned);
        assignedToArray.push(userData);
      }
    });
    updatedTaskData?.cc?.forEach(ccUser => {
      if (updatedTaskData?.sentBy !== ccUser?.object?.empId) {
        const userData = formatUserData(ccUser);
        assignedToArray.push(userData);
      }
    });

    if (updatedTaskData.sentByUser.id !== updatedTaskData.sentBy) {
      const createdByUser = {
        name: updatedTaskData?.sentByUser?.firstName + ' ' + updatedTaskData?.sentByUser?.lastName,
        email:'',
        phoneNumber: '',
        contactId: updatedTaskData?.sentByUser?.id?.toString(),
      };
      assignedToArray.push(createdByUser);
    }
    const key = 'contactId';
    const filteredList = assignedToArray.filter(
      (contact, index, self) => self.findIndex(c => c[key] === contact[key]) === index,
    );
    return filteredList;
  };

  const getTaskUpdateNotifications = notificationEntity => {
    let entityId: string = '0';
    let primaryText: string = '';
    if (currentContainer) {
      if (currentContainer === TaskEntity.PLACEMENTS) {
        if (globalEntityId?.placementId !== undefined) {
          primaryText = updatedTaskData?.candidateName;
          entityId = updatedTaskData?.candidateId?.toString();
        }
      } else if (currentContainer === TaskEntity.FACILITIES) {
        entityId = globalEntityId?.facilityId?.toString();
        primaryText = updatedTaskData?.facilityName;
      } else if (currentContainer === TaskEntity.ORDERS) {
        entityId = globalEntityId?.facilityId?.toString();
        primaryText = updatedTaskData?.facilityName;
      } else if (currentContainer === TaskEntity.CANDIDATES) {
        entityId = globalEntityId?.candidateId?.toString();
        primaryText = updatedTaskData?.candidateName;
      }
    } else if (notificationEntity) {
      if (notificationEntity === TaskEntity.PLACEMENTS) {
        primaryText = updatedTaskData?.candidateName;
        entityId = updatedTaskData?.candidateId?.toString();
      } else if (notificationEntity === TaskEntity.FACILITIES) {
        primaryText = updatedTaskData?.facilityName;
        entityId = updatedTaskData?.facilityId?.toString();
      } else if (notificationEntity === TaskEntity.ORDERS) {
        primaryText = updatedTaskData?.facilityName;
        entityId = updatedTaskData?.facilityId?.toString();
      } else if (notificationEntity === TaskEntity.CANDIDATES) {
        primaryText = updatedTaskData?.candidateName;
        entityId = updatedTaskData?.candidateId?.toString();
      }
    } else {
      entityId = '0';
    }

    if (primaryText) {
      const taskNotificationPayload = {
        brand: NotificationBrand,
        useType: Task,
        useSubType: TaskNotificationType.TASKEDITED.toString(),
        sendType: 'Bulk',
        sender: NotificationSender,
        ttlValue: 2,
        ttlPeriod: 'w',
        associatedRecords: [
          {
            name: 'TaskId',
            value: updatedTaskData?.taskId?.toString(),
          },
          {
            name: 'DueDate',
            value: updatedTaskData?.dueDate,
          },
          {
            name: 'CurrentContainer',
            value: getTaskEntityValue(currentContainer ?? notificationEntity, t),
          },
          {
            name: 'PrimaryContext',
            value: primaryText,
          },
          {
            name: 'PlacementId',
            value: globalEntityId ? globalEntityId?.placementId?.toString() : updatedTaskData?.placementId?.toString(),
          },
          {
            name: 'FacilityId',
            value: globalEntityId?.facilityId
              ? globalEntityId?.facilityId?.toString()
              : updatedTaskData?.facilityId?.toString(),
          },
          {
            name: 'UnitId',
            value: updatedTaskData?.unitId ? updatedTaskData?.unitId?.toString() : '',
          },
          {
            name: 'CandidateId',
            value: globalEntityId?.candidateId
              ? globalEntityId?.candidateId.toString()
              : updatedTaskData?.candidateId?.toString(),
          },
          {
            name: 'OrderId',
            value: globalEntityId?.orderId ? globalEntityId?.orderId.toString() : updatedTaskData?.orderId?.toString(),
          },
          {
            name: 'Description',
            value: userInfo?.userInfo?.firstName + ' ' + userInfo?.userInfo?.lastName + ' has updated Task Details.',
          },
          {
            name: 'TaskCategory',
            value: updatedTaskData?.taskCategoryText ? updatedTaskData?.taskCategoryText : '',
          },
          {
            name: 'EntityId',
            value: entityId,
          },
          {
            name: 'UserName',
            value: userInfo?.userInfo?.firstName + ' ' + userInfo?.userInfo?.lastName,
          },
          {
            name: 'TaskName',
            value: updatedTaskData?.task,
          },
          {
            name: 'SentDate',
            value: moment.tz(new Date(), 'America/Los_Angeles').format(),
          },
          {
            name: 'Version',
            value: StatusUpdateModalVersion.PARENT_TASK.toString(),
          },
        ],
        body: '',
        level: 1,
        templateId: '',
        tos: getAssignedToList(),
        ccs: [],
        groupName: '',
      };
      if (taskNotificationPayload?.tos?.length > 0) {
        if (globalEntityId?.companyId > 0 || updatedTaskData?.companyId > 0) {
          taskNotificationPayload?.associatedRecords?.push({
            name: 'BrandId',
            value:
              globalEntityId?.companyId > 0
                ? globalEntityId?.companyId?.toString()
                : updatedTaskData?.companyId?.toString(),
          });
        }
        dispatch(taskDetailsActions.sendTaskCreatedNotification(taskNotificationPayload));
      }
    }
  };
  useEffect(() => {
    if (updatedId && updatedId > 0 && updatedTaskData) {
      let notificationEntity = null;
      if (
        updatedTaskData?.placementId &&
        updatedTaskData?.facilityId &&
        updatedTaskData?.orderId &&
        updatedTaskData?.candidateId
      ) {
        notificationEntity = TaskEntity.PLACEMENTS;
      } else if (updatedTaskData?.orderId && updatedTaskData?.facilityId) {
        notificationEntity = TaskEntity.ORDERS;
      } else if (updatedTaskData?.facilityId) {
        notificationEntity = TaskEntity.FACILITIES;
      } else if (updatedTaskData?.candidateId) {
        notificationEntity = TaskEntity.CANDIDATES;
      }
      getTaskUpdateNotifications(notificationEntity);
      dispatch(taskDetailsActions.setTaskUpdatedId(undefined));
      dispatch(taskDetailsActions.setTaskUpdatedData(undefined));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedId, updatedTaskData]);
};
