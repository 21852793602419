import React, { useState } from 'react';
import { Grid, Theme, Typography } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';
import { XGrid } from 'app/components/Common/XGrid/XGrid';
import { useGridPreferences } from 'app/components/Common/XGrid/Hooks/useGridPreferences';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { GridColumns, GridCallbackDetails, GridFilterModel, GridSortDirection } from '@mui/x-data-grid-pro';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import { ff_ui_Candidate_Summary_Manage_Audits } from 'app/constants/FeatureFlags/Candidate/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { selectUser } from 'oidc/user.selectors';
import { auditTypeDeletePermission } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/SummaryTab/Audit/Helpers/AuditDeleteHelpers';
import { makeStyles } from 'tss-react/mui';
import { GridType } from './types';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContent: {
    padding: '0px 30px 20px 30px',
    fontSize: '14px',
  },
}));

export const AMNModalGrid = ({
  rows,
  filterModel,
  onFilterModelChange,
  refreshData,
  onHandleAdd,
  onHandleDelete,
  serviceTrackerId = '',
  gridHeight = 0,
  columnsParams,
  translationKey,
  defaultSortColumn,
  XGRID_COLUMN_SORT_DIRECTION,
  marginBottom,
  enableDelete,
  auditType,
  gridLabel,
  numberOfMonth = 0,
  gridType = GridType?.any,
}: {
  rows: any[];
  filterModel?: GridFilterModel;
  onFilterModelChange?: (model: GridFilterModel, details: GridCallbackDetails) => void;
  refreshData: Function;
  onHandleAdd?: Function;
  onHandleDelete?: Function;
  serviceTrackerId: string;
  gridHeight: number;
  columnsParams: GridColumns;
  translationKey: string;
  defaultSortColumn: string;
  XGRID_COLUMN_SORT_DIRECTION: GridSortDirection;
  marginBottom: string | number;
  enableDelete?: boolean;
  auditType?: string;
  gridLabel: boolean;
  numberOfMonth: number;
  gridType?: GridType;
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { promiseInProgress: loading } = usePromiseTracker({ area: serviceTrackerId, delay: 0 });
  const [addAuditFeature] = useDecision(ff_ui_Candidate_Summary_Manage_Audits);
  const user = useSelector(selectUser);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const display = gridType === GridType.audit ? rows.length > 0 : true;
  const [absoluteColumns] = useState(columnsParams);
  const { columns, sortModel, setSortModel, setDefaultView } = useGridPreferences({
    gridTag: t(`${translationKey}.gridTag`),
    gridColumns: absoluteColumns,
    defaultSort: [
      {
        field: defaultSortColumn,
        sort: XGRID_COLUMN_SORT_DIRECTION,
      },
    ],
  });

  const willDelete = () => {
    setDeleteDialogOpen(true);
  };

  const bulkDelete = [
    {
      key: 'deleteAudits',
      Icon: DeleteOutlinedIcon,
      disabled: !auditTypeDeletePermission(auditType, user.userInfo) ? enableDelete : true,
      onClick: willDelete,
      tooltipProps: {
        tooltipContent: 'Delete',
      },
    },
  ];

  const resetColumns = () => {
    setDefaultView(true);
    dispatch(
      globalActions.setSnackBar({
        message: t(`${translationKey}.resetColumnsSnackBarMessage`),
        severity: 'success',
      }),
    );
  };

  const onSortModelChange = model => {
    setSortModel(model);
  };

  const onCancel = () => {
    setDeleteDialogOpen(false);
  };

  const deleteAudit = () => {
    setDeleteDialogOpen(false);
    onHandleDelete();
  };

  const getLeftActions = () => {
    const actions = [];
    if (onHandleAdd && addAuditFeature.enabled) {
      actions.push({
        title: 'AUDIT',
        Icon: AddIcon,
        onClick: onHandleAdd,
      });
    }
    return actions;
  };

  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: marginBottom }}>
        {gridLabel ? (
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ padding: '6px' }}>
              {t(`${translationKey}.defaultSortFilterLabel`)}
            </Typography>
          </Grid>
        ) : (
          <></>
        )}
        {display ? (
          <Grid item xs={12}>
            <XGrid
              gridTag={t(`${translationKey}.gridTag`)}
              rows={rows}
              columns={columns}
              disableSelectionOnClick
              pagination={false}
              hideFooter
              sortModel={sortModel}
              onSortModelChange={onSortModelChange}
              height={gridHeight > 0 ? gridHeight : undefined}
              autoHeight={gridHeight <= 0}
              filterModel={filterModel}
              onFilterModelChange={onFilterModelChange}
              inDialog
              title={t(`${translationKey}.title`, { N: numberOfMonth })}
              loading={loading}
              toolbarProps={{
                rightActions: bulkDelete,
                bulkDelete: bulkDelete,
                refreshButton: true,
                resetColumns: resetColumns,
                refreshGrid: refreshData,
                leftActions: getLeftActions(),
              }}
            />
          </Grid>
        ) : null}
      </Grid>
      <GenericDialog
        open={deleteDialogOpen}
        variant={'white'}
        dialogTitleProps={{
          text: 'Delete (Audit)?',
          closeButton: false,
        }}
        dialogContentProps={{ classes: { root: classes.dialogContent } }}
        dialogActions={[
          {
            text: 'Cancel',
            onClick: onCancel,
            color: 'tertiary',
          },
          {
            text: 'Delete',
            variant: 'contained',
            onClick: deleteAudit,
          },
        ]}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body1">Are you sure you want to permanently delete ?</Typography>
          </Grid>
        </Grid>
      </GenericDialog>
    </>
  );
};
