import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { Box, Grid, Skeleton, Typography } from 'amn-ui-core';
import { CustomCheckbox } from '@AMIEWEB/Common/Checkbox/CustomCheckbox';
import { CustomRadioButton } from '@AMIEWEB/Order/OrderDetails/OrderPreferences/SubmissionPreferences/SubmissionLimits/MaxSubmission';
import { useSelector } from 'react-redux';
import { EmailSettings } from '../SubmissionEmail/BulkEmailSettings';
import { initialPacketState } from '@AMIEWEB/Common/SubmissionPreference/SubmissionPacket/SubmissionPacketSelector';
import { selectPacketCategoryTitles } from 'store/redux-store/notification/notification.selector';
import { FormNames, deriveAttentionToOpts, deriveSendToOpts } from '../Common/helper';
import { isValidJSONString } from 'utils/string/string';

const useStyles = makeStyles<{ isFetchingPreferences: boolean }>()((theme, { isFetchingPreferences }) => ({
  fieldContainer: {
    marginLeft: 12,
  },
  candidateTaskContainer: {
    paddingTop: isFetchingPreferences ? 6 : undefined,
  },
}));

export interface ISendPacket {
  save?: boolean;
  saveAndSend?: boolean;
  doNotSave?: boolean;
}

export const initialSendPacketState: ISendPacket = {
  save: false,
  saveAndSend: false,
  doNotSave: false,
};

export enum Automate {
  RFO = 'rfoToSent',
  SendPacket = 'sendPacket',
}

export enum placementStage {
  submission = 'submission',
}

export const AutomatePlacement = props => {
  const {
    isStrike = false,
    isFetchingPreferences: { isFetchingPreferences },
    rfoFlag,
    updateConatinerState = () => {},
    clientContacts = [],
    submissionEmailSetup,
    submissionPacketSetup,
  } = props;

  const categoryTitles = useSelector(selectPacketCategoryTitles);

  const { t } = useTranslation();
  const { cx, classes } = useStyles({ isFetchingPreferences });
  const { control, getValues, setValue } = useFormContext();

  const handleDisablePacketContainer = React.useCallback(
    (sendPacketValue, rfoValue) => {
      const disablePacketContainer = !((sendPacketValue?.saveAndSend || sendPacketValue?.save) && rfoValue);
      updateConatinerState(disablePacketContainer, 'disablePacketContainer');

      const packetSetup = isValidJSONString(submissionPacketSetup) ? JSON.parse(submissionPacketSetup) : null;
      if (disablePacketContainer) {
        // clearing form values only if packet setup container is disabled!
        setValue(FormNames.PacketTemplates, initialPacketState);
        setValue(FormNames.NoteToFacility, '');
        setValue(
          FormNames.AttentionTo,
          deriveAttentionToOpts(clientContacts, packetSetup?.attentionTo, disablePacketContainer),
        );
      } else if (!!submissionPacketSetup) {
        if (packetSetup.noteToFacility) {
          setValue(FormNames.NoteToFacility, packetSetup.noteToFacility);
        }
        if (packetSetup.packetTemplate) setValue(FormNames.PacketTemplates, packetSetup.packetTemplate);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientContacts, submissionPacketSetup],
  );

  const handleDisableEmailContainer = React.useCallback(
    (sendPacketValue, rfoValue) => {
      const disableEmailContainer = !(sendPacketValue?.saveAndSend && rfoValue);
      updateConatinerState(disableEmailContainer, 'disableEmailContainer');

      const emailSetup = isValidJSONString(submissionEmailSetup) ? JSON.parse(submissionEmailSetup) : null;
      setValue(FormNames.SendTo, deriveSendToOpts(clientContacts, emailSetup?.sendTo, disableEmailContainer));
      if (disableEmailContainer) {
        setValue(FormNames.EmailSettings, '');
        setValue(FormNames.EmailTemplate, null);
      } else if (!!submissionEmailSetup) {
        setValue(FormNames.EmailSettings, emailSetup?.bulkEmailSettings ?? EmailSettings.Group);
        setValue(FormNames.EmailTemplate, emailSetup?.emailTemplateId ?? categoryTitles?.[0]?.id ?? '');
      } else {
        setValue(FormNames.EmailSettings, EmailSettings.Group);
        setValue(FormNames.EmailTemplate, categoryTitles?.[0]?.id ?? '');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientContacts, submissionEmailSetup, categoryTitles],
  );

  const handleRFOChange = React.useCallback(
    (onChange, newValue) => {
      onChange(newValue);

      const sendPacketValue = newValue
        ? { ...initialSendPacketState, save: !isStrike, doNotSave: !!isStrike }
        : initialSendPacketState;
      setValue(Automate.SendPacket, sendPacketValue);

      handleDisablePacketContainer(sendPacketValue, newValue);
      handleDisableEmailContainer(sendPacketValue, newValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleDisableEmailContainer, handleDisablePacketContainer, isStrike],
  );

  const handleSendPacketChange = React.useCallback(
    newState => {
      const _rfoValue = getValues('rfoToSent');
      handleDisablePacketContainer(newState, _rfoValue);
      handleDisableEmailContainer(newState, _rfoValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleDisableEmailContainer, handleDisablePacketContainer],
  );

  return (
    <Grid container direction="column">
      <Grid item classes={{ root: classes.fieldContainer }}>
        <Controller
          name="wfcToRfo"
          control={control}
          defaultValue={false}
          render={({ ref, onChange, ...rest }) =>
            isFetchingPreferences ? (
              <Skeleton variant="rectangular" />
            ) : (
              <CustomCheckbox
                {...rest}
                size="medium"
                label={t('order.orderDetails.preferences.submission.submissionLimits.wfctorfo')}
                onChange={newValue => onChange(newValue)}
              />
            )
          }
        />
      </Grid>
      <Grid item classes={{ root: classes.fieldContainer }}>
        <Controller
          name="rfoToSent"
          control={control}
          defaultValue={false}
          render={({ ref, onChange, ...rest }) =>
            isFetchingPreferences ? (
              <Skeleton variant="rectangular" />
            ) : (
              <CustomCheckbox
                {...rest}
                size="medium"
                label={t('order.orderDetails.preferences.submission.submissionLimits.rfo.title')}
                onChange={newValue => handleRFOChange(onChange, newValue)}
                disabled={rfoFlag && !rfoFlag?.enabled}
              />
            )
          }
        />
        <Controller
          name="sendPacket"
          control={control}
          disabled={rfoFlag && !rfoFlag?.enabled}
          render={({ ref, ...rest }) => (
            <CustomRadioGroup {...rest} rfoFlag={rfoFlag} handleChangeEvent={handleSendPacketChange} />
          )}
        />
      </Grid>
      <Grid item classes={{ root: cx(classes.candidateTaskContainer, classes.fieldContainer) }}>
        <Controller
          name="candidateTasks"
          control={control}
          defaultValue={false}
          render={({ ref, onChange, ...rest }) =>
            isFetchingPreferences ? (
              <Skeleton variant="rectangular" />
            ) : (
              <CustomCheckbox
                {...rest}
                size="medium"
                label={t('Candidate Task Created')}
                onChange={newValue => onChange(newValue)}
              />
            )
          }
        />
      </Grid>
    </Grid>
  );
};

const useRadioStyles = makeStyles()(theme => ({
  sendContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginLeft: '30px',
  },
  radioButton: {
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    '& .MuiFormControlLabel-root': {
      marginLeft: '0px !important',
    },
  },
}));

const CustomRadioGroup = ({
  value: { save, saveAndSend, doNotSave },
  onChange,
  rfoFlag,
  handleChangeEvent,
  ...props
}) => {
  const { classes } = useRadioStyles();
  const { t } = useTranslation();
  const { watch, trigger } = useFormContext();
  const isEnabled = watch(Automate.RFO);
  const isButtonDisabled = !isEnabled || (rfoFlag && !rfoFlag?.enabled);

  const handleChange = React.useCallback(
    (key: 'save' | 'saveAndSend' | 'doNotSave') => {
      if (isButtonDisabled) return;
      const newState = { ...initialSendPacketState, [key]: true };
      onChange(newState);
      handleChangeEvent(newState);
    },
    [handleChangeEvent, isEnabled, onChange],
  );

  return (
    <Box className={classes.sendContainer}>
      <Typography variant="body1">
        {t('order.orderDetails.preferences.submission.submissionLimits.rfo.send.title')}
      </Typography>
      <div className={classes.radioButton}>
        <CustomRadioButton
          label={t('global.button.save')}
          onClick={e => handleChange('save')}
          checked={save}
          disabled={isButtonDisabled}
        />
        <CustomRadioButton
          label={t('order.orderDetails.preferences.submission.submissionLimits.rfo.send.save&Send')}
          onClick={e => {
            handleChange('saveAndSend');
            trigger(['sendTo']);
          }}
          checked={saveAndSend}
          disabled={isButtonDisabled}
        />
        <CustomRadioButton
          label={t('order.orderDetails.preferences.submission.submissionLimits.rfo.send.doNotSave')}
          onClick={e => handleChange('doNotSave')}
          checked={doNotSave}
          disabled={isButtonDisabled}
        />
      </div>
    </Box>
  );
};
