import { Divider, Paper } from 'amn-ui-core';
import styledComponent from 'styled-components';

export const UnitTitle = styledComponent.div`
  font-weight: 600;
  font-size: 18px;
`;

export const PreviewContent = styledComponent(Paper)`
  padding: 12px;
`;

export const InnerTitle = styledComponent.div`
  padding-left: 12px;
  font-weight: 600;
`;

export const PreviewContentTitle = styledComponent.div`
  font-weight: 500;
  font-size: 16px;
`;

export const IconLabel = styledComponent.span`
  padding-left: 7px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContentDivider = styledComponent(Divider)`
  width: calc(100% - 17px);
  margin-left: 7px;
  margin: 12px 0px 6px 0px;
`;

export const SkillSetDivider = styledComponent(Divider)`
  width: 100%;
  margin-bottom: 6px;
  margin-top: 20px;
`;

export const ContentTitle = styledComponent.div`
  font-size: 14px;
  font-weight: 500;
  padding-right: 10px;
`;

export const Contentlabel = styledComponent.span`
  padding-right: 12px;
  padding-left: 5px;
  font-size: 14px;
`;

export const InnerSubTitle = styledComponent.div`
  font-size: 14px;
  font-weight: 500;
  padding-right: 10px;
  padding-left: 10px;
`;

export const Requirementlabel = styledComponent.div`
  padding-left: 5px;
  font-size: 14px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;