import { RootState } from 'types';
import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './userManagement.redux';

const selectDomain = (state: RootState) => state?.userManagement || initialState;
export const selectUserManagementFilters = createSelector([selectDomain], data => data?.appliedFilters);
export const selectUserManagementChips = createSelector([selectDomain], data => data.chips);
export const selectUserLists = createSelector([selectDomain], data => data.items);
export const setUserCounts = createSelector([selectDomain], data => data.count);
export const selectAppliedFiltersFlag = createSelector([selectDomain], data => data.appliedFiltersFlag);
export const selectEmpInfo = createSelector([selectDomain], data => data.empInfo);
export const selectCheckedRoles = createSelector([selectDomain], data => data.checkedRoles);
export const selectEmpCardInfo = createSelector([selectDomain], data => data.empCardInfo);
export const selectIsDataChanged = createSelector([selectDomain], data => data.isDataChanged);
export const selectDialogeOpen = createSelector([selectDomain], data => data.dialogeOpen);
export const selectEmpRoles = createSelector([selectDomain], data => data.empRoles);
export const selectedCardId = createSelector([selectDomain], data => data.selectedCardId);
export const selectedEmployeeAssociationTypeOptions = createSelector([selectDomain],
     data => data.employeeAssociationTypeOptions);
export const selectedEmployeeAssociations = createSelector([selectDomain], data => data.employeeAssociations);
export const selectedTabValue = createSelector([selectDomain], data => data.tabValue);
export const selectedcustomTabvalue = createSelector([selectDomain], data => data.customTabvalue);
export const selectParentAssociationChips = createSelector([selectDomain], data => data.parentAssociationChips);
export const selectChildAssociationsChips = createSelector([selectDomain], data => data.childAssociationsChips);
export const selectInitialParentAssociationChips = createSelector([selectDomain],
     data => data.initialParentAssociationChips);
export const selectAssociationsDiffer = createSelector([selectDomain], data => data.associationsDiffer);
export const selectInitialChildAssociationsChips = createSelector([selectDomain],
     data => data.initialChildAssociationsChips);
export const selectUserDetails = createSelector([selectDomain], data => data.userDetails);
export const selectUserDetailsFields = createSelector([selectDomain], data => data.userDetailFields);
export const selectStatusOptions = createSelector([selectDomain], data => data.statusOptions);
export const selectCompanyOptions = createSelector([selectDomain], data => data.companyOptions);
export const selectCreateNewUser = createSelector([selectDomain], data => data.addNewUser);
export const selectCreateNewUserErrorFields = createSelector([selectDomain], data => data.addNewUser.errorFields);
export const selectUpdateUserInfoErrorFields = createSelector([selectDomain], data => data.updateUserInfoErrorFields);
export const selectDisableUserFieldEdit = createSelector([selectDomain], data => data.disableUserFieldEdit);