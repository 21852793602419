import {
  IPlacementHeader,
  IPlacementKeyContacts,
  IPlacementCandidateRecords,
  GlanceDataStatus,
  IQuickGlance,
  ICandidateComparisonData,
  IOrderComparisonData,
  IPlacementProgress,
  ILandsGtgDetails,
} from 'app/models/Placement/PlacementDetails';

export enum PlacementDetailsSubTab {
  SUMMARY = 0,
  CREDENTIALING,
}

export enum CalculationType {
  IndividualExperience = 1,
  CombinedExperience = 2,
}

export const initialProgress: IPlacementProgress = {
  placementId: null,
  activityTypeId: null,
  activityStatusId: null,
  activityStatus: null,
  categoryProgress: [],
  subcategoryProgress: [],
} as unknown as IPlacementProgress;

export const initialHeaderState: IPlacementHeader = {
  placementId: 0,
  activityStatusId: 0,
  placementStatus: '',
  availabilityDate: null,
  placementDate: {
    startDate: null,
    endDate: null,
  },
  placementStartDate: null,
  placementEndDate: null,
  placementUnit: '',
  orderId: 0,
  orderTypeId: -1,
  brandId: 0,
  brandName: '',
  facility: {
    id: 0,
    name: '',
    city: '',
    state: '',
    postalCode: '',
  },
  technologyVendorId: 0,
  technologyVendorName: '',
  candidate: {
    id: 0,
    firstName: '',
    middleName: '',
    lastName: '',
    goesByName: '',
    ssn: undefined,
    dob: null,
    phoneNumber: {
      phoneTypeDescription: '',
      phoneTypeId: 0,
      phoneNumber: '',
      countryDialingCode: '',
    },
    address: {
      address1: '',
      address2: '' || null,
      city: '',
      stateProvinceID: '',
      zip: '',
      addressType: '',
      addressTypeId: 0 || null,
    },
  },
  candidateLastContactedOn: null,
  candidatePrimaryEmail: '',
  candidateBestTimeToCall: null,
  skillsets: [],
  lastUpdatePlacement: null,
  lastUpdateBy: null,
  isArchived: null,
  supervisorRequired: false,
  vmsReqDetails: {
    number: '',
    lastUpdated: '',
    lastUpdatedById: null,
    lastUpdatedBy: '',
  },
  contractHours: '',
  numberOfWeeks: null,
} as unknown as IPlacementHeader;

const nullGlanceData = { status: GlanceDataStatus.neutral };

export const initialQuickGlance: IQuickGlance = {
  audits: nullGlanceData,
  fileOutAudit: nullGlanceData,
  licenseIssue: nullGlanceData,
  mfs: nullGlanceData,
  screen: null,
  references: nullGlanceData,
  skillsChecklist: nullGlanceData,
};

export const initialContactsState: IPlacementKeyContacts = {
  placementId: 0,
  facility: {
    id: 0,
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    phoneNumber: '',
    country: '',
    amnDivisionType: '',
    amnDivisionTypeId: null,
  },
  recruiter: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  regionalDirector: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  accountManager: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  credentialingAnalyst: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  clinicalManager: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  clientContractSpecialist: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  facilityContacts: [],
};

export const initialCandidateRecords: IPlacementCandidateRecords = {
  credentialingCertifications: [],
  placementSkillCheckLists: [],
  requiredQualifications: '',
  preferredQualifications: '',
  internalNotes: '',
  candidatePlacementCounts: {
    submittals: 0,
    interviews: 0,
    offersAccepts: 0,
    hasMspClient: true,
    sameMspPlacements: 0,
  },
  candidateSellingPointCounts: {
    currentAmnTraveler: false,
    amnAssignmentsComplete: 0,
    travelsWithPartner: false,
    travelsWithPartnerName: null,
    placementSellingPoints: '',
    returnToFacility: false,
    returnToFacilityEndOfAssignment: '',
    returnToFacilityAssignmentLength: '',
    returnToFacilityCount: 0,
    hasMspClient: false,
    returnToMsp: false,
    returnToMspEndOfAssignment: '',
    returnToMspAssignmentLength: '',
    returnToMspCount: 0,
  },
  strikeStatus: {
    eventManagementStatusId: 0,
    eventManagementStatus: '',
    schedulingStatusId: 0,
    schedulingStatus: '',
  },

  comparisons: {
    order: {
      yearsOfExperience: null,
      startDate: null,
      endDate: null,
      expectedHours: null,
      contractGWW: false,
      orderGWW: null,
      licenseState: null,
      referencesRequired: null,
      referencesToRfo: null,
      radiusMile: null,
      orderShifts: [],
      skillsets: [],
    } as unknown as IOrderComparisonData,
    candidate: {
      candidateId: null,
      licenses: {
        licenses: [],
      },
      certificates: [],
      educationList: [],
      skillsets: [],
      requestedShift: null,
      availabilityDate: null,
      referencesFulfilled: null,
      requestedTimeOff: {
        isRequestedTimeOffMakeUp: false,
        requestedTimeOff: [],
        requestedTimeOffMode: null,
        requestedTimeOffNote: '',
      },
      experiences: [],
      totalYearsOfExp: null,
      totalMonthsOfExp: null,
      credentialCertificates: {
        count: null,
      },
      directions: {
        radius: null,
        errorMessage: null,
      },
    } as unknown as ICandidateComparisonData,
  },
  workHistoryData: {
    workHistoryIcon: 0,
    lastUpdateIcon: 1,
    workHistoryLastUpdatedDate: null,
    message: '',
    dateMessage: '',
  },
} as unknown as IPlacementCandidateRecords;

export const intialLandsGtgdetails: ILandsGtgDetails = {
  placementId: 0,
  notRequired: false,
  notRequiredReasonId: null,
  notRequiredReason: null,
  licenseNumber: '',
  licenseActive: false,
  hitsOnLicenseMatchAudit: false,
  auditScreenedWithClient: false,
  statesInNursys: false,
  statesInNursysReason: '',
  nursysMatchOnFile: false,
  licenseIssuedAfterGraduation: false,
  checkedDob: false,
  expirationDate: '',
  typeId: 0,
  levelId: 0,
  discMatchAssign: false,
  lastUpdated: '',
  lastUpdatedByUserId: 0,
  lastUpdatedByName: {
    first: '',
    last: '',
  },
  timestamp: '',
  cabreezeAct: null,
  confirmCaLicense: null,
  confirmCaLicenseReason: null,
  isLandsComplete: null,
  gtgNotRequired: null,
  gtgNotRequiredReasonId: null,
  gtgNotRequiredReason: null,
  crimCheckDate: null,
  i9Uploaded: null,
  supportDocListId: null,
  supportDocUploaded: null,
  namesNumbersMatchI9: null,
  eVerifyDate: null,
  notForeignTraveler: null,
  foreignTravelerFUDate: null,
  foreignTravelerEvent: null,
  checkState: null,
  checkStateReason: null,
  checkStateExceptId: null,
  missExpEvents: null,
  noExpAssign: null,
  packetSentDate: null,
  packetNotRequired: null,
  gtgLastUpdated: null,
  gtgLastUpdatedByUserId: 0,
  gtgLastUpdatedByName: {
    first: null,
    last: null,
  },
  isgtgComplete: null,
  i9FinalAuditCompleted: null,
  i9eVerifyCode: null,
  allCertificationsVerified: null,
  licenseStateProvinceId: null,
  licenseStateProvice: null,
  licenseReviewTeamClearedHits: null,
  isAlliedClinician: null,
  nursysLastnameMatchesLicense: null,
  nursysLastnameNotMatchesLicenseReason: null,
  isProofOfResidencyOnFileForCompact: null,
  nursysMatchOnFileReason: null,
  currentEmployeeId: 8140,
};
