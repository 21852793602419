import CloseIcon from '@mui/icons-material/Close';
import { Button, Checkbox, Modal, Radio, Toolbar, Typography, Autocomplete } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { CustomGridColumn, ModalAppBar, ModalClose, ModalTitle } from '../CredentialingAnalyst/BrandSelectionModal';
import { useTranslation } from 'react-i18next';
import { getAttachmentFileExtension, getFileName } from 'app/components/Notification/Common/AttachmentFileTypes';
import { TFunction } from 'i18next';
import { getActivePlacementsByCandidate } from 'app/services/PlacementServices/PlacementServices';
import { PagingData } from 'app/models/PagingData';
import { PlacementInfo } from 'app/models/Candidate/CandidateProfile';
import { GridColDef, GridColumns } from '@mui/x-data-grid-pro';
import { XGrid } from 'app/components/Common/XGrid/XGrid';
import _ from 'lodash';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { ContactProps } from 'app/models/Notification/Notification';
import { selectUser } from 'oidc/user.selectors';
import { useSelector } from 'react-redux';

const useStyles: any = makeStyles()(theme => ({
  root: { margin: '1%' },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: '1200px',
    outline: 'none',
    borderRadius: '5px',
    position: 'absolute',
    '&>div:nth-child(5)': {
      margin: '0px 1rem',
    },
  },
  content: {
    padding: '10px 20px 20px 20px',
  },
  navigationButton: {
    position: 'relative',
    float: 'right',
    padding: '25px',
  },
  cancel: {
    marginRight: '20px',
  },
  attachmentStyle: {
    textDecoration: 'none',
    color: '#333333',
  },
  attachmentHeader: {
    marginTop: '25px',
  },
  fieldHeader: {
    marginLeft: '25px',
    fontWeight: 500,
    fontSize: '14px',
  },
  dropDown: {
    padding: '25px',
  },
  copyToHeader: {
    fontWeight: 500,
    fontSize: '12px',
    opacity: 1,
  },
  dropdownTextBox: {
    backgroundColor: '#F7F7F7',
  },
  dropdownText: {
    backgroundColor: 'none',
  },
}));

export interface GridRow {
  isRadioButtonSelected: boolean;
}

interface CcPatners {
  firstName: string;
  lastName: string;
  designation: string;
  email: string;
}

interface AttachmentsProps {
  travelerId: string;
  handleClose: () => void;
  handleNext: (selectedRow: (GridRow & PlacementInfo) | undefined, isUrgent: boolean, ccs: ContactProps[]) => void;
  attachmentUrls: [string, string, string][];
}
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FilterColumns = (t: TFunction, changeRowSelection: (rowPID: any, rows: (GridRow & PlacementInfo)[]) => void) => {
  const columns = CustomGridColumn(t);
  const filterColumns: GridColumns = [
    {
      field: 'id',
      renderHeader: () => <span></span>,
      flex: 0.3,
      renderCell: params => {
        return (
          <Radio
            checked={params.row.isRadioButtonSelected}
            name="pid-radio"
            onChange={() =>
              changeRowSelection(params.id, Object.values<GridRow & PlacementInfo>(params.api.state.rows.idRowsLookup))
            }
          />
        );
      },
    },
  ];
  for (const column of columns) {
    if (column.title === 'assignedCA') continue;
    const filteredColumn: GridColDef = {
      field: column.title,
      headerName: column.value,
      flex: 1,
    };
    filterColumns.push(filteredColumn);
  }
  const dayToStart = filterColumns[5];
  filterColumns[5] = filterColumns[4];
  filterColumns[4] = dayToStart;
  filterColumns[5].headerName = 'Start Date';
  return filterColumns;
};

const AttachmentDetailsModal = ({ handleClose, handleNext, attachmentUrls, travelerId }: AttachmentsProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [rows, setRows] = useState<(GridRow & PlacementInfo)[]>([]);
  const isUrgent = useRef(false);
  const [gridLoad, setGridLoad] = useState(false);
  const [data, setData] = useState<CcPatners[]>([]);
  const [dropDown, setDropDown] = useState(true);
  const [ccs, setCcs] = useState<CcPatners[]>([]);
  const userInfo = useSelector(selectUser);

  const changeRowSelection = useCallback(
    (rowPID: any, _rows: (GridRow & PlacementInfo)[]) => {
      setCcs([]);
      const copiedRows = _.cloneDeep(_rows);
      let selectedRow: GridRow & PlacementInfo;
      copiedRows?.forEach((row, index) => {
        row.isRadioButtonSelected = rowPID === row?.placementId + '';
        if (rowPID === row?.placementId + '') {
          selectedRow = row;
          setDropDown(false);
        }
      });
      setRows(copiedRows);
      const checkEmailNotSame = (email1, email2) => email1 !== email2;
      const getRoleObject = (firstName, lastName, designation, email) => {
        return {
          firstName: firstName,
          lastName: lastName,
          designation: designation,
          email: email,
        };
      };
      placementService.getPlacementKeyContacts(selectedRow!.placementId).then(res => {
        const keyInternalContacts: CcPatners[] = [];
        if (!res) return;
        if (res.data.recruiter && checkEmailNotSame(res.data.recruiter.email, userInfo.userInfo?.email))
          keyInternalContacts.push(
            getRoleObject(
              res.data.recruiter.firstName,
              res.data.recruiter.lastName,
              ' (Recruiter)',
              res.data.recruiter.email,
            ),
          );
        if (res.data.regionalDirector && checkEmailNotSame(res.data.regionalDirector.email, userInfo.userInfo?.email))
          keyInternalContacts.push(
            getRoleObject(
              res.data.regionalDirector.firstName,
              res.data.regionalDirector.lastName,
              ' (Regional Director)',
              res.data.regionalDirector.email,
            ),
          );
        if (res.data.accountManager && checkEmailNotSame(res.data.accountManager.email, userInfo.userInfo?.email))
          keyInternalContacts.push(
            getRoleObject(
              res.data.accountManager.firstName,
              res.data.accountManager.lastName,
              ' (Account Manager)',
              res.data.accountManager.email,
            ),
          );
        if (
          res.data.credentialingAnalyst &&
          checkEmailNotSame(res.data.credentialingAnalyst.email, userInfo.userInfo?.email)
        )
          keyInternalContacts.push(
            getRoleObject(
              res.data.credentialingAnalyst.firstName,
              res.data.credentialingAnalyst.lastName,
              ' (Credentialing Analyst)',
              res.data.credentialingAnalyst.email,
            ),
          );
        if (res.data.clinicalManager && checkEmailNotSame(res.data.clinicalManager.email, userInfo.userInfo?.email))
          keyInternalContacts.push(
            getRoleObject(
              res.data.clinicalManager.firstName,
              res.data.clinicalManager.lastName,
              ' (Clinical Manager)',
              res.data.clinicalManager.email,
            ),
          );
        setData(keyInternalContacts);
      });
    },
    [setCcs],
  );

  const columnRef = useRef(FilterColumns(t, changeRowSelection));
  const placementService = PlacementService.getInstance();

  useEffect(() => {
    setGridLoad(true);
    getActivePlacementsByCandidate(travelerId)
      .then(activePlacementsRes => {
        const pagingData: PagingData<PlacementInfo> = activePlacementsRes;
        const items = pagingData.items.map(x => ({
          ...x,
          id: x.placementId,
          isRadioButtonSelected: false,
        })) as (GridRow & PlacementInfo)[];
        setRows(items);
      })
      .catch(() => setRows([]))
      .finally(() => setGridLoad(false));
  }, [travelerId]);

  const AttachmentsElements = (
    <>
      {attachmentUrls.map(([fileName, url, signedUrl]) => (
        <div
          key={fileName}
          style={{
            background: '#EFF0F2',
            borderRadius: '5px',
            color: '#333333',
            padding: '2px 8px',
            marginRight: '8px',
          }}
        >
          <a
            key={fileName}
            className={classes.attachmentStyle}
            href={signedUrl}
            target="_blank"
            rel="noopener noreferrer"
            download={getFileName(url)}
          >
            {getAttachmentFileExtension(url)}
          </a>
        </div>
      ))}
    </>
  );

  const result = (
    <Modal open className={classes.modal} closeAfterTransition>
      <div className={classes.paper}>
        <ModalAppBar elevation={0} id="draggableArea" position="static">
          <Toolbar disableGutters>
            <ModalTitle variant="h6" style={{ margin: '0px' }}>
              {t('notification.sms.kofax.attachmentHeader')}
            </ModalTitle>
            <ModalClose color="default" aria-label="upload picture">
              <CloseIcon onClick={handleClose} />
            </ModalClose>
          </Toolbar>
        </ModalAppBar>
        <Typography variant="body1" className={`${classes.fieldHeader} ${classes.attachmentHeader}`}>
          {t('notification.sms.kofax.header')}
        </Typography>
        <div className={classes.content} style={{ display: 'flex', position: 'relative' }}>
          {AttachmentsElements}
          <div style={{ position: 'absolute', right: '2rem', top: '-1.6rem' }}>
            <Checkbox
              name="urgency"
              color="primary"
              onChange={() => {
                isUrgent.current = !isUrgent.current;
              }}
            />
            <label htmlFor="urgency" style={{ fontSize: '0.9rem', fontFamily: 'sans-serif' }}>
              {t('Urgent')}
            </label>
          </div>
        </div>
        <Typography variant="body1" className={`${classes.fieldHeader}`}>
          {t('notification.sms.kofax.gridHeader')}
        </Typography>
        <XGrid
          disableColumnFilter
          disableTitleCount
          disableColumnReorder
          disableColumnResize
          disableMultipleColumnsFiltering
          disableMultipleSelection
          disableStateRowSelection
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          pagination={false}
          disableExtendRowFullWidth
          columns={columnRef.current}
          rows={rows}
          loading={gridLoad}
          autoHeight
          rowsPerPageOptions={[100]}
          disableSelectionOnClick
          rowCount={rows.length}
          toolbar={false}
          title={''}
        />
        <div className={classes.dropDown}>
          <Typography variant="body1" className={`${classes.copyToHeader}`}>
            {t('notification.sms.kofax.copyToLabel')}
          </Typography>
          <Autocomplete
            limitTags={1}
            disabled={dropDown}
            disableClearable
            onChange={(event, value) => setCcs(value as CcPatners[])}
            multiple
            clearOnBlur
            disableListWrap={true}
            id="checkboxes-tags-demo"
            disableCloseOnSelect
            options={data}
            value={ccs}
            getOptionLabel={(option: CcPatners) => `${option.firstName}  ${option.lastName} ${option.designation}`}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                <span>
                  <b>{`${option?.firstName} ${option?.lastName}`}</b>
                  {`${option?.designation}`}
                </span>
              </li>
            )}
            style={{ width: 423 }}
            renderInput={params => (
              <TextField {...params} label="Email Cc To" variant="filled" className={classes.dropdownTextBox} />
            )}
            renderTags={(tagValue, getTagProps) => {
              return (
                <span className={classes.dropdownText}>
                  <b>
                    {tagValue[0]?.firstName} {tagValue[0]?.lastName}
                  </b>
                  {`${tagValue[0]?.designation}`}
                  {tagValue.length > 1 && <b style={{ color: '#1BA4DC' }}> +{tagValue.length - 1}</b>}
                </span>
              );
            }}
          />
        </div>

        <div className={classes.navigationButton}>
          <Button variant="text" className={classes.cancel} onClick={handleClose}>
            {t('global.button.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              const selectedRow = rows.find(x => x.isRadioButtonSelected === true);
              handleNext(
                selectedRow,
                isUrgent.current,
                ccs.map<ContactProps>(v => ({
                  email: v.email,
                  name: v.firstName + ' ' + v.lastName,
                  senderId: '',
                  userId: '',
                  NtUserName: '',
                  deviceName: '',
                  lineNumber: '',
                  phoneNumber: '',
                })),
              );
            }}
          >
            {t('global.button.next')}
          </Button>
        </div>
      </div>
    </Modal>
  );
  return result;
};

export default AttachmentDetailsModal;
