import React from 'react';

export const usePaneResetter = ({ pane, complementaryPaneExists }) => {
  const [resetFlexFactor, setFlex] = React.useState(null);

  React.useEffect(() => {
    if (!pane) return;

    /** Code: Resize current pane, if complementary pane is removed from DOM */
    if (!complementaryPaneExists) {
      setFlex(pane.style?.flex);
      pane.style.flex = '1 1 auto';
    } else if (resetFlexFactor)
      /** Code: Revert to previous state, when complementary pane is rendered into DOM again */
      pane.style.flex = resetFlexFactor;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complementaryPaneExists]);
};
