import { Grid, Link, Skeleton, Typography } from 'amn-ui-core';
import { missingField } from 'app/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { IPlacementOrder } from 'store/redux-store/new-placement/types';
import { Concatenate } from 'utils/string/string';

export const FundamentalInfo: React.FC<IPlacementOrder> = ({ orderId, facility, facilityId, city, state }) => {
  const { t } = useTranslation();

  const { promiseInProgress } = usePromiseTracker({ area: 'new-placement-get-order' });

  const { facilityAddress, place } = React.useMemo(
    () => ({
      facilityAddress: facility ? `${facility},` : null,
      place: Concatenate([city, state], ', '),
    }),
    [facility, city, state],
  );

  return promiseInProgress ? (
    <Skeleton width="100%" />
  ) : (
    <Grid container flexDirection="column" spacing={1}>
      <Grid item sx={{ display: 'flex', gap: '6px' }}>
        <Typography component="span" variant="body1" color="charcoal" fontWeight={500}>
          {t('placement.create.popup.oid')}
        </Typography>
        {orderId ? (
          <Link
            href={`${window.location.origin}/order/${orderId}`}
            variant="body1"
            color="navyBlue"
            target="_blank"
            rel="noopener noreferrer"
            underline="hover"
          >
            {orderId}
          </Link>
        ) : (
          <Typography>{missingField}</Typography>
        )}
      </Grid>
      {orderId ? (
        <Grid item>
          <Link
            href={`${window.location.origin}/facility/${facilityId}`}
            variant="body1"
            color="navyBlue"
            target="_blank"
            rel="noopener noreferrer"
            underline="hover"
            sx={{ marginRight: '6px' }}
          >
            {facilityAddress}
          </Link>
          <Typography component="span" variant="body1" color="charcoal" fontWeight={500}>
            {place}
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
};
