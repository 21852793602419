import { IUserDetails } from '@AMIEWEB/Settings/UserManagement/store/userManagement.redux';
import { Concatenate } from 'utils/string/string';
import { formatPhoneNumber } from 'app/helpers/phoneHelpers';

export const signatureSubstitutionCovered = (userDetails: IUserDetails) => {
  return [
    {
      name: 'Sender Name',
      input: '{{sender_name}}',
      value: Concatenate([userDetails?.firstName || '', userDetails?.lastName || ''], ' ')
    },

    {
      name: 'Brand',
      input: '{{brand}}',
      value: userDetails?.companyBrandName
    },
    {
      name: 'Brand Id',
      input: '{{brand_id}}',
      value: String(userDetails?.companyId)
    },
    {
      name: 'Mobile Number',
      input: '{{mobile_number}}',
      value: userDetails?.phoneNumber
    },
    {
      name: 'Direct Number',
      input: '{{direct_number}}',
      value: userDetails?.phoneNumber
    },
    {
      name: 'Text Number',
      input: '{{text_number}}',
      value: userDetails?.smsNumber ? formatPhoneNumber(userDetails?.smsNumber) : ''
    },
    {
      name: 'Division',
      input: '{{division}}',
      value: '',
    },
    {
      name: 'Title',
      input: '{{title}}',
      value: '',
    },

    {
      name: 'Passport Link',
      input: '{{passport_link}}',
      value: 'true'
    },
    {
      name: 'Referral Link',
      input: '{{referral_link}}',
      value: 'true'
    },
    {
      name: 'Branded Signature',
      input: '{{branded_signature}}',
      value: 'false',
    },
  ];
};
