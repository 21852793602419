import LoadingButton from '@mui/lab/LoadingButton';
import { Grid } from 'amn-ui-core';
import { selectUser } from 'oidc/user.selectors';
import { isReadOnly } from 'oidc/userRoles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { CONFIRMATION_ACTION } from 'store/redux-store/placement-requests/helper';
import { selectIsAutoTaskDetailsModified } from 'store/redux-store/placement-requests/selectors';
import { placementRequestsAction } from 'store/redux-store/placement-requests/slice';
import { makeStyles } from 'tss-react/mui';
import { ConfirmationDialog } from './ConfirmationDialog';
const useStyles = makeStyles()(theme => ({
  container: {
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    height: 60,
    padding: 12,
  },
  elevateContainer: {
    backdropFilter: 'blur(2px)',
  },
  lowerContainer: {
    zIndex: 0,
  },
  item: {
    margin: '0px 6px',
  },
}));

const PageSaveBtnAccessor = 'page-save-change-action';
const PageClearBtnAccessor = 'page-clear-change-action';

export interface IDialogModal {
  open: boolean;
  type?: string;
  selectedRowId?: number;
}
export const PageActions = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { promiseInProgress: updateAutoTaskLoading } = usePromiseTracker({
    area: 'update-auto-task-management',
    delay: 0,
  });
  const isAutoTaskDetailsModified = useSelector(selectIsAutoTaskDetailsModified);
  const appUser = useSelector(selectUser);
  const [dialogModal, setDialogModal] = useState<IDialogModal>({ open: false, type: null });
  useEffect(() => {
    if (dialogModal.open && !updateAutoTaskLoading) setDialogModal({ open: false, type: null });
  }, [updateAutoTaskLoading]);

  const handleButtonClick = buttonType => {
    if (buttonType === 'CANCEL') {
      setDialogModal({ open: true, type: CONFIRMATION_ACTION.CANCEL });
    } else if (buttonType === 'SAVE') {
      dispatch(placementRequestsAction.business_handleAutoTaskConfirmDialogAction(CONFIRMATION_ACTION.SAVE));
    }
  };

  const handleKeepChanges = () => {
    setDialogModal({ open: false, type: null });
  };
  const handleDiscard = () => {
    dispatch(placementRequestsAction.business_handleAutoTaskConfirmDialogAction(CONFIRMATION_ACTION.DISCARD));
    setDialogModal({ open: false, type: null });
  };
  return (
    <>
      {' '}
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        classes={{ container: classes.container, root: true ? classes.elevateContainer : classes.lowerContainer }}
      >
        <Grid item classes={{ item: classes.item }}>
          <LoadingButton
            id={PageClearBtnAccessor}
            variant="contained"
            size="large"
            color='tertiary'
            disableTouchRipple
            disableFocusRipple
            disabled={!isAutoTaskDetailsModified || isReadOnly(appUser?.userInfo?.roles || [])}
            type="button"
            onClick={() => {
              handleButtonClick('CANCEL');
            }}
          >
            {t('CANCEL')}
          </LoadingButton>
        </Grid>
        <Grid item classes={{ item: classes.item }}>
          <LoadingButton
            id={PageSaveBtnAccessor}
            variant="contained"
            size="large"
            color='primary'
            disabled={!isAutoTaskDetailsModified || isReadOnly(appUser?.userInfo?.roles || []) || updateAutoTaskLoading}
            onClick={() => handleButtonClick('SAVE')}
            loading={updateAutoTaskLoading}
          >
            {t('SAVE')}
          </LoadingButton>
        </Grid>
      </Grid>
      <ConfirmationDialog
        dialogeOpen={dialogModal.open}
        keepChanges={handleKeepChanges}
        handleDiscard={handleDiscard}
      />
    </>
  );
};
