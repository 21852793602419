import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ICoverage,
  ICoverageDetails,
  ICoverageFilterChips,
  ICoverageGridData,
  ICoverageRequest,
  defaultChips,
  IDenyCoverageResponse,
  ICoveredUserInbox,
  ICoveredUserInboxes,
} from 'app/models/UserCoverage/UserCoverage';

export const initialState: ICoverage = {
  chips: defaultChips,
  coverageRequest: undefined,
  requestedCoverage: [],
  activeCoverage: [] as ICoverageDetails[],
  coverageRequestsGridData: {} as ICoverageGridData,
  newCreatedCoverageId: undefined,
  updatedCoverageId: undefined,
  denyCoverage: [],
  emailCoverage: [],
  inboxCoverage: {
    coveredInboxes: [] as ICoveredUserInbox[],
    activeInbox: {} as ICoveredUserInbox,
  } as ICoveredUserInboxes,
};

export const coverageSlice = createSlice({
  name: 'coverage',
  initialState,
  reducers: {
    setCoverageRequest(state, action: PayloadAction<ICoverageRequest | undefined>) {
      state.coverageRequest = action.payload;
    },
    setRequestedCoverage(state, action: PayloadAction<any | undefined>) {
      state.requestedCoverage = action.payload;
    },
    setActiveCoverage(state, action: PayloadAction<any | undefined>) {
      state.activeCoverage = action.payload;
    },
    setCoverageRequestGridData(state, action: PayloadAction<any | undefined>) {
      state.coverageRequestsGridData = action.payload;
    },
    setFilterChips(state, action: PayloadAction<ICoverageFilterChips>) {
      state.chips = action.payload;
    },
    setNewCoverageId(state, action: PayloadAction<string | undefined>) {
      state.newCreatedCoverageId = action.payload || undefined;
    },
    setUpdatedCoverageId(state, action: PayloadAction<string | undefined>) {
      state.updatedCoverageId = action.payload || undefined;
    },
    setDenyCoverageList(state, action: PayloadAction<IDenyCoverageResponse[] | undefined>) {
      state.denyCoverage = action.payload;
    },
    setEmailCoverage(state, action: PayloadAction<any[]>) {
      state.emailCoverage = action.payload;
    },
    setInboxCoverage(state, action: PayloadAction<Array<ICoveredUserInbox>>) {
      state.inboxCoverage = {
        ...state.inboxCoverage,
        coveredInboxes: action.payload,
      };
    },
    setActiveInbox(state, action: PayloadAction<ICoveredUserInbox>) {
      state.inboxCoverage = {
        ...state.inboxCoverage,
        activeInbox: action.payload,
      };
    },
    reset() {
      return { ...initialState };
    },
  },
});

export const { name: coverageSliceKey, reducer: coverageReducer } = coverageSlice;
