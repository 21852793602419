/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
  IconButton,
  Chip,
  Avatar,
  Box,
  Tooltip,
  useTheme,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import SearchIcon from '@mui/icons-material/Search';
import { Certification } from 'app/models/Unit/CreateUnit';
import { useTranslation } from 'react-i18next';
import { getCertifications } from 'app/components/Order/OrderCreationForm/DefineUnit/Helper';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsEditUnit, selectOrderCreationProcessStep } from 'app/components/Order/Store/Order.selectors';
import { getCredentialLookups, getRequirementTypes } from 'app/services/CredentialingServices/CredentialingServices';
import { useDecision } from '@optimizely/react-sdk';
import { ff_order_ui_qualificationlist } from 'app/constants/FeatureFlags/Order/Keys';
import { selectFilterCertificationsDecision } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { orderCreationActions } from '@AMIEWEB/Order/Store/OrderCreation.actions';
import { AMNDivisionType } from 'app/models/enums/AMNDivisionType';

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    height: '15%',
    maxHeight: 300,
    overflow: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
  },
  searchField: {
    height: '1%',
    width: '100%',
    borderBottom: '1px solid #CCCCCC',
  },
  chip: {
    margin: '0 1% 1% 0',
  },

  wrapper: {
    backgroundColor: '#FFFFFF !important',
    border: '1px solid #CCCCCC',
    borderRadius: 5,
    minHeight: 300,
  },
  item: {
    borderBottom: '1px solid #EFEFEF',
    borderColor: '#f4f5f738',
  },
  noBorder: {
    border: 'hidden',
  },
  chipWrapper: {
    paddingTop: '0.8%',
  },
  icon: {
    borderRadius: 3,
    padding: 2,
    border: '1px solid #cccccc',
    marginTop: 6,
  },

  chipIcon: {
    fontSize: 'large',
    marginLeft: 6,
  },
  iconSize: {
    fontSize: 'larger',
    padding: 4,
  },
  listItemContent: {
    display: '-webkit-box',
    maxWidth: '50ch',
    fontSize: 14,
    lineHeight: 1.4,
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tooltip: {
    backgroundColor: theme.palette.components.tooltip.backgroundColor,
    color: '#333333',
    opacity: 1,
    textAlign: 'left',
    letterSpacing: '0px',
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #8C8C8C',
  },
  arrow: {
    color: theme.palette.components.tooltip.arrowColor,
    opacity: 1,
    '&::before': {
      boxShadow: '0px 3px 6px #00000029',
      border: '1px solid #8C8C8C',
    },
  },
  placementBottom: {
    marginBottom: 0,
  },
}));

interface RequirementType {
  category: string;
  subCategory: string | null;
  requirementTypeID: number;
  abbreviation: string;
  credentialName: string;
}

const shouldShowType = item => {
  return (
    item?.subCategory === 'Other Certifications' ||
    item?.subCategory === 'Pending Molecule Development' ||
    item?.category === 'Education & Online Exams'
  );
};

interface Props {
  division?: number;
  onCertificationPropChange: (property: string, chips: any) => void;
  isdisabled?: boolean;
  unitCertifications?: Certification[] | null;
}

export const UnitCertification = (props: Props) => {
  /* eslint-disable i18next/no-literal-string */
  const isEditUnit = useSelector(selectIsEditUnit);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const theme = useTheme();
  const { division, onCertificationPropChange, isdisabled, unitCertifications } = props;
  const initialCertifications = unitCertifications ? unitCertifications : [];
  const storeOrderCreationProcessStep = useSelector(selectOrderCreationProcessStep);
  const [selectedCertifications, setSelectedCertifications] = useState<{ id: number; isRequired: boolean | null }[]>(
    getCertifications(initialCertifications, isEditUnit, division, storeOrderCreationProcessStep),
  );
  const [chips, setChips] = useState<Certification[]>([]);
  const [searchData, setSearchData] = useState<any[]>([]);
  const [searchResult, setSearchResult] = React.useState<RequirementType[]>([]);
  const [RequirementTypes, setRequirementTypes] = React.useState<any>([]);
  const [lookUps, setLookUps] = React.useState<any>([]);

  const [filterCertListFlag] = useDecision(ff_order_ui_qualificationlist);
  const selectDecision = useSelector(selectFilterCertificationsDecision);
  if (selectDecision === undefined || selectDecision === null) {
    dispatch(orderCreationActions.setCertificationsFlagDecision(filterCertListFlag.enabled));
  }

  useEffect(() => {
    if (unitCertifications) {
      const prepareChips: Certification[] = [];
      const selected: any[] = [];
      unitCertifications?.map((item, indx) => {
        prepareChips.push({
          requirementTypeId: item.requirementTypeId,
          abbreviation: item.abbreviation,
          isRequired: item.isRequired,
          documentAttachmentRequired: item.documentAttachmentRequired,
        });
        selected.push({
          id: item.requirementTypeId,
          isRequired: item.isRequired,
        });
      });

      setChips(prepareChips);
      if (selected.length) setSelectedCertifications(selected);
    }
  }, [division]);

  const fetchData = async () => {
    const data = await getRequirementTypes();
    const dataLookups = await getCredentialLookups('all');
    setLookUps(dataLookups);
    const requirementTypes = selectDecision ? data : data?.filter(req => req.unitId === 1);
    setRequirementTypes(requirementTypes);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (RequirementTypes.length > 0) {
      let lookupList: any[] = [];
      RequirementTypes?.forEach(item => {
        const req = { ...item };
        const lookupTypes = lookUps?.find(
          lookup =>
            lookup.requirementTypeID === item.requirementTypeID &&
            (item.requirementTypeID === 222 ? lookup.lookUpField === 'ReqTypeID' : lookup.lookUpField === 'TypeID') &&
            lookup.lookups !== undefined,
        );

        if (lookupTypes !== undefined)
          lookupTypes.lookups?.forEach(lookupType => {
            if (
              !lookupList.find(
                x => x.requirementTypeID === lookupType.value * 1000 || x.abbreviation === lookupType.description,
              )
            )
              //&& lookupType.value != 243
              lookupList.push({
                requirementTypeID: lookupType.value * 1000,
                abbreviation: lookupType.description,
              });
          });

        const certification = lookupList.find(x => x.abbreviation === req.abbreviation);
        //Add if not exist else remove lookup and add requirement
        if (!certification || !certification.credentialName) {
          lookupList = [...lookupList.filter(x => x.abbreviation !== req.abbreviation), req];
        }
      });
      setSearchData(lookupList);
      setDefaultSearchList(lookupList);
      if (!unitCertifications || (unitCertifications && unitCertifications.length <= 0)) {
        if (
          !isEditUnit &&
          (division === AMNDivisionType.amnNurse || division === AMNDivisionType.allied) &&
          !storeOrderCreationProcessStep
        ) {
          setChips([
            {
              requirementTypeId: 47,
              abbreviation: 'BLS',
              isRequired: true,
              documentAttachmentRequired: false,
            },
          ]);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [RequirementTypes]);

  const setDefaultSearchList = list => {
    setSearchResult(list);
  };
  const handleToggle = (value: number) => () => {
    const certification = selectedCertifications.some(x => x.id === value);
    const selected = selectedCertifications.filter(x => x.id !== value);
    if (!certification) {
      selected.push({ id: value, isRequired: true });
      const selectedCertifiedChip = searchData.filter(t => t.requirementTypeID === value)[0];
      if (selectedCertifiedChip) {
        setChips([
          ...chips,
          {
            requirementTypeId: selectedCertifiedChip.requirementTypeID,
            abbreviation: selectedCertifiedChip.abbreviation,
            isRequired: true,
            documentAttachmentRequired: null,
          },
        ]);
      }
    } else setChips(chips.filter(x => x.requirementTypeId !== value));

    setSelectedCertifications(selected);
  };

  const handleChange = (event, certification) => {
    var existingChips = [...chips];
    var index = existingChips.findIndex(item => item.requirementTypeId === certification.requirementTypeID);
    if (index >= 0) {
      existingChips[index] = {
        requirementTypeId: existingChips[index].requirementTypeId,
        abbreviation: existingChips[index].abbreviation,
        isRequired: event.target.value === 'req',
        documentAttachmentRequired: existingChips[index].documentAttachmentRequired,
      };
      setChips(existingChips);
    } else {
      setChips([
        ...chips,
        {
          requirementTypeId: certification.requirementTypeID,
          abbreviation: certification.abbreviation,
          isRequired: event.target.value === 'req',
          documentAttachmentRequired: null,
        },
      ]);
    }

    const certifications = selectedCertifications?.filter(cert => cert.id !== certification.requirementTypeID);
    certifications.push({
      id: certification.requirementTypeID,
      isRequired: event.target.value === 'req',
    });

    setSelectedCertifications(certifications);
  };

  const handleDocumentRequired = requirementTypeId => {
    const existingChips = [...chips];
    var existingChip = existingChips?.find(x => x.requirementTypeId === requirementTypeId);
    if (existingChip && existingChip?.documentAttachmentRequired) {
      return true;
    } else return false;
  };

  const isRequirementOfType = (value: RequirementType, type: boolean | null) => {
    return type === null
      ? selectedCertifications.some(x => x.id === value.requirementTypeID)
      : selectedCertifications.some(x => x.id === value.requirementTypeID && x.isRequired === type);
  };

  const handleSearch = event => {
    if (event.target.value.length < 2) return setDefaultSearchList(searchData);
    const results = searchData.filter(
      item =>
        `${item.abbreviation} - ${item.credentialName}`
          .toLowerCase()
          .replace(/\s|\.|\(|\)/g, '')
          .includes(event.target.value.toLowerCase().replace(/\s|\.|\(|\)/g, '')) ||
        (!!item.types &&
          shouldShowType(item) &&
          item.types
            ?.map(type =>
              type.description
                .toLowerCase()
                .replace(/\s|\.|\(|\)/g, '')
                .includes(event.target.value.toLowerCase().replace(/\s|\.|\(|\)/g, '')),
            )
            .indexOf(true) !== -1),
    );

    results.length > 0 ? setSearchResult(results) : setSearchResult(searchData);
  };

  const handleDelete = requirementTypeId => {
    setChips(chips.filter(entry => entry.requirementTypeId !== requirementTypeId));
    setSelectedCertifications(selectedCertifications.filter(x => x.id != requirementTypeId));
  };

  const handleClick = requirementTypeId => {
    const existingChips = [...chips];
    var index = existingChips.findIndex(item => item.requirementTypeId === requirementTypeId);
    if (index >= 0) {
      existingChips[index] = {
        requirementTypeId: existingChips[index].requirementTypeId,
        abbreviation: existingChips[index].abbreviation,
        isRequired: existingChips[index].isRequired,
        documentAttachmentRequired: !existingChips[index].documentAttachmentRequired,
      };
      setChips(existingChips);
    }
  };

  useEffect(() => {
    onCertificationPropChange('certifications', chips);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chips]);

  return (
    <>
      <Box className={classes.wrapper}>
        <TextField
          variant="outlined"
          color="primary"
          placeholder={t('order.createUnit.qualifications.placeHolder')}
          id="txtCertification"
          autoComplete="on"
          onChange={handleSearch}
          disabled={isdisabled}
          fullWidth
          classes={{ root: classes.searchField }}
          InputProps={{
            startAdornment: <SearchIcon style={{ opacity: 0.6 }} />,
            classes: { notchedOutline: classes.noBorder },
          }}
        />
        <List className={classes.root} id={'createUnitQualificationsCertificationsSearchResultListContainer'}>
          {searchResult.map(value => {
            const labelId = `checkbox-list-label-${value}`;
            return (
              <ListItem
                key={value.requirementTypeID}
                role={undefined}
                dense
                button
                disabled={isdisabled}
                onClick={handleToggle(value.requirementTypeID)}
                className={classes.item}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    disabled={isdisabled}
                    checked={isRequirementOfType(value, null)}
                    tabIndex={-1}
                    inputProps={{ 'aria-labelledby': labelId }}
                    color="primary"
                  />
                </ListItemIcon>
                <Tooltip
                  title={
                    value.credentialName ? `${value.abbreviation} - ${value.credentialName}` : `${value.abbreviation}`
                  }
                  placement="bottom"
                  arrow
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.arrow,
                    tooltipPlacementBottom: classes.placementBottom,
                  }}
                >
                  <ListItemText
                    classes={{
                      primary: classes.listItemContent,
                    }}
                    id={labelId}
                    style={{
                      marginRight: selectedCertifications.some(x => x.id === value.requirementTypeID) ? '130px' : '0px',
                    }}
                    primary={
                      value.credentialName ? `${value.abbreviation} - ${value.credentialName}` : `${value.abbreviation}`
                    }
                  />
                </Tooltip>
                <ListItemSecondaryAction>
                  {isRequirementOfType(value, null) && (
                    <Grid container>
                      <Grid item>
                        <RadioGroup aria-label="Required" name={'required'} onChange={e => handleChange(e, value)} row>
                          <FormControlLabel
                            value="req"
                            disabled={isdisabled}
                            control={
                              <Radio
                                color="primary"
                                disableRipple
                                disableTouchRipple
                                disableFocusRipple
                                checked={isRequirementOfType(value, true)}
                              />
                            }
                            label="Req"
                          />
                          <FormControlLabel
                            value="pref"
                            disabled={isdisabled}
                            control={
                              <Radio
                                color="primary"
                                disableRipple
                                disableTouchRipple
                                disableFocusRipple
                                checked={isRequirementOfType(value, false)}
                              />
                            }
                            label="Pref"
                          />
                        </RadioGroup>
                      </Grid>
                      <Grid item>
                        <Tooltip
                          title={'Document attached with submission'}
                          placement="bottom"
                          arrow
                          classes={{
                            tooltip: classes.tooltip,
                            arrow: classes.arrow,
                            tooltipPlacementBottom: classes.placementBottom,
                          }}
                        >
                          <IconButton
                            color="primary"
                            edge="end"
                            aria-label="document"
                            disableFocusRipple
                            disableTouchRipple
                            disableRipple
                            disabled={isdisabled}
                            className={`${classes.icon}`}
                            onClick={() => handleClick(value.requirementTypeID)}
                            size="small"
                          >
                            <DescriptionOutlinedIcon
                              style={{
                                fill: `${
                                  handleDocumentRequired(value.requirementTypeID) && !isdisabled ? '#FFFFFF' : '#B8BABC'
                                }`,
                              }}
                              className={classes.iconSize}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </Box>
      <div className={classes.chipWrapper} id={'createUnitQualificationsCertificationsAddedItemContainer'}>
        {chips &&
          chips.map(item => {
            return (
              <Chip
                disabled={isdisabled}
                variant="outlined"
                avatar={<Avatar variant={item.isRequired ? 'primary' : 'green'}>{item.isRequired ? 'R' : 'P'}</Avatar>}
                key={item.requirementTypeId}
                label={
                  <React.Fragment>
                    {item.abbreviation}
                    {item.documentAttachmentRequired && (
                      <DescriptionOutlinedIcon
                        style={{ fill: theme.palette.components.icons.secondary.backgroundColor }}
                        className={classes.chipIcon}
                      />
                    )}
                  </React.Fragment>
                }
                className={classes.chip}
                onDelete={() => handleDelete(item.requirementTypeId)}
              />
            );
          })}
      </div>
    </>
  );
};
