import { Popper } from 'amn-ui-core';
import { ITypeAheadOption } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/PreferencesTab/CustomComponents/ControlledTypeAhead';
import { InlineEditableField } from 'app/components/Common/EditPage/InlineEditableField';
import { TypeAhead } from 'app/components/Common/TypeAheads/TypeAhead';
import { selectPlacementStatus } from 'store/redux-store/placement-status/selectors';
import { getSearchLookups } from 'app/services/SharedServices/SharedServices';
import { throttle } from 'lodash';
import React, { useContext, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useSelector } from 'react-redux';
import { CreatePlacementContext } from '../../CreatePlacementWrapper';
import { createPlacementTabIDs } from '../../helper';
import { isInitialStatus } from '../../../NextPlacementStatus/StatusDefaults';
import { selectNewPlacementChoice } from '../../../../../../store/redux-store/new-placement/selectors';

const trackArea = 'create-placement-recruiter-lookup';

export const Recruiter = ({ ...props }) => {
  const { control } = useFormContext();
  const { candidate, newPlacementId } = useSelector(selectNewPlacementChoice);
  const { currentPlacementStatus } = useSelector(selectPlacementStatus);
  const { promiseInProgress } = usePromiseTracker({ area: trackArea, delay: 0 });

  const [options, setOptions] = useState<ITypeAheadOption[]>([]);

  const { setCurrentFocusId } = useContext(CreatePlacementContext);

  const fetchOptions = async request => {
    const response = await getSearchLookups('recruiters', request);
    setOptions(
      (response || []).map(opt => ({
        object: { id: opt.value, name: opt.name, value: opt.value },
        label: opt.name,
      })),
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttleService = useMemo(() => throttle(request => trackPromise(fetchOptions(request), trackArea), 2000), []);

  return (
    <Controller
      control={control}
      name="recruiter"
      render={({ ref, onChange, value, ...rest }) => (
        <InlineEditableField
          id={createPlacementTabIDs.createPlacementRecruiter}
          inputProps={{
            ...rest,
            variant: 'outlined',
            size: 'small',
            options,
            autoFocus: true,
            removeCloseIcon: true,
            isOptionsLoading: promiseInProgress,
            hasAsyncOptions: true,
            invokeInitialAsyncData: true,
            onFocus: () => throttleService(''),
            onChangeReflection: loc => {
              if (loc) {
                onChange(loc);
              }
            },
            onInputChange: throttleService,
            placeholder: value ? value.label : 'Select',
            popperComponent: PopperComponent,
          }}
          Control={TypeAhead}
          label={value?.label}
          alignStart
          setCurrentFocus={() => setCurrentFocusId(createPlacementTabIDs.createPlacementRecruiter)}
          {...rest}
          disabled={!candidate || !!newPlacementId || !isInitialStatus(currentPlacementStatus)}
        />
      )}
    />
  );
};

const PopperComponent = props => (
  <Popper {...props} style={{ width: 'fit-content', minWidth: 150 }} placement="bottom-start" />
);
