import { GridCellParams } from '@mui/x-data-grid-pro';
import { Badge } from 'amn-ui-core';
import React, { useEffect, useState } from 'react';
import { DescriptionOutlined } from '@mui/icons-material';
import { colors } from 'styles/styleVariables';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { selectGridSelection } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.selector';
import {
  IApprovalDocuments,
  IAttachments,
  IRequestApprovalDocument,
  facilityActions,
} from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { selectApprovalDocuments } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import {
  selectDocContainer,
  selectRateFeatureProps,
} from 'store/redux-store/create-order-ocbr/selector';
import { ViewUploadedDocuments } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/RateCardTab/RateCardSections/OffContractTable/ViewUploadedDocuments';
import { Status } from 'app/enums/Facility';
import { CustomTooltip } from '@AMIEWEB/Common';
import { UploadDocumentWrapper } from './UploadDocumentWrapper';
import { IRateDocument } from 'store/redux-store/create-order-ocbr/types';
import { gridSelectionActions } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.redux';

const useStyles = makeStyles<{ hasDocument: boolean }>()((theme, { hasDocument }) => ({
  icon: {
    color: colors.darkText,
    opacity: hasDocument ? 1 : 0.3,
  },
  badge: {
    backgroundColor: colors.amnBlue,
    padding: '0 !important',
    fontSize: 11,
    height: 16,
    minWidth: 16,
    top: '20%',
  },
}));

export const XGridDocCell: React.FC<GridCellParams> = ({ row }) => {
  const appUser = useSelector(selectUser);
  const { hasDocument, displayCount } = React.useMemo(() => {
    const count = (row.documentIds ?? []).length;
    return { count, hasDocument: !!count, displayCount: count > 1 ? count : undefined };
  }, [row.documentIds]);
  const { classes } = useStyles({ hasDocument });
  const { selectedData: gridSelections } = useSelector(selectGridSelection);
  const dispatch = useDispatch();
  const [uploadDocumentOpen, setUploadDocumentOpen] = useState<boolean>(false);
  const documents = useSelector(selectApprovalDocuments);
  const [allAttachments, setAllAttachment] = useState<IApprovalDocuments[]>([]);
  const [allAttachmentsUnsaved, setAllAttachmentUnsaved] = useState<IRateDocument[]>([]);
  const { facilityId } = useSelector(selectRateFeatureProps);
  const docContainer = useSelector(selectDocContainer);

  useEffect(() => {
    setAllAttachment([]);
  }, []);

  const handleUploadDocumentBtn = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();

    if (uploadDocumentOpen) return;
    if (row?.statusId === Status.Unsaved || row?.statusId == Status.Pending) {
      var selectedData = [row.id];
      dispatch(gridSelectionActions.setGridSelections({ selectedData: selectedData, isCompareGridOpen: false }));
      dispatch(facilityActions.setUploadDocumentModalOpen(true));
      setUploadDocumentOpen(true);

      var attach: IRateDocument[] = [];
      row?.documentIds.forEach(docId => {
        const doc = docContainer.find(d => d.hash === docId);
        attach.push(doc);
      });
      setAllAttachmentUnsaved(attach);
    } else {
      if (uploadDocumentOpen) return;
      var selectedData = [row.id];
      dispatch(gridSelectionActions.setGridSelections({ selectedData: selectedData, isCompareGridOpen: false }));
      dispatch(facilityActions.setUploadDocumentModalOpen(true));
      setUploadDocumentOpen(true);

      const payload: IRequestApprovalDocument = {
        facilityId: facilityId,
        rateElementId: row?.rateElementId,
      };
      dispatch(facilityActions.getApprovalDocuments(payload));
    }
  };

  useEffect(() => {
    if (documents) {
      setAllAttachment([documents]);
    }
  }, [documents]);

  return (
    <CustomTooltip tooltipContent="Documents" arrow={true} disabled={uploadDocumentOpen}>
      <div onClick={handleUploadDocumentBtn}>
        <Badge badgeContent={displayCount} overlap="circular" color="primary" classes={{ badge: classes.badge }}>
          <DescriptionOutlined classes={{ root: classes.icon }} />
        </Badge>
        {row?.statusId === Status.Unsaved || row?.statusId == Status.Pending
          ? allAttachmentsUnsaved?.length > 0 && (
            <UploadDocumentWrapper
              addDocsModalOpen={uploadDocumentOpen}
              setAddDocsModalOpen={setUploadDocumentOpen}
              gridSpecs={{ gridSelections, row }}
              displayDescription={false}
              existingAttachments={allAttachmentsUnsaved}
              isEditing={true}
            />
          )
          : allAttachments?.length > 0 && (
            <ViewUploadedDocuments
              appUser={appUser}
              facilityId={facilityId}
              addDocsModalOpen={uploadDocumentOpen}
              setAddDocsModalOpen={setUploadDocumentOpen}
              gridSpecs={{ gridSelections, row }}
              displayDescription={false}
              isEditing={true}
              existingAttachments={
                allAttachments?.find(item => item.rateElementId === row?.rateElementId)?.attachments
              }
              row={row}
            />
          )}
      </div>
    </CustomTooltip>
  );
};
