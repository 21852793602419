/* eslint-disable i18next/no-literal-string */
import { Box, Divider, Grid, Theme, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useEffect, useRef, useState } from 'react';
import { AddressSection } from './AddressSection';
import { ContactInfoSection } from './ContactInfoSection';
import { EmergencyContact } from './EmergencyContact/EmergencyContact';
import { PersonalInfoSection } from './PersonalInfoSection';
import { ApplicationQuestions } from './ApplicationQuestions';
import { useDecision } from '@optimizely/react-sdk';
import { ff_candidate_tags } from 'app/constants/FeatureFlags/Candidate/Keys';
import { useSelector } from 'react-redux';
import {
  candidateDetailsSelection,
  selectPersonalInfoError,
} from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { useFormContext } from 'react-hook-form';
import { BannerMessage } from '@AMIEWEB/Settings/AutoTaskManagement/common/BannerMessage';
import { Skeleton } from '@mui/lab';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: '#f5f5f5',
    margin: 0,
    padding: 0,
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
  Titleroot: {
    padding: '10px 5px 0px 5px',
    overflow: 'auto',
  },
  rightPanel: {
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    minHeight: '300px',
    maxHeight: 'calc(100vh - 193px)',
  },
  expandedRightPanel: {
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    height: 'calc(100vh - 127px)',
  },
  textColorSelected: {
    color: '#0D6FB9',
    textAlign: 'left',
    font: 'normal normal medium 14px / 26px Roboto',
    paddingLeft: '30px',
    fontWeight: 500,
    margin: 0,
  },
  textColorUnselected: {
    color: '#555555',
    textAlign: 'left',
    font: 'normal normal medium 14px / 26px Roboto',
    paddingLeft: '30px',
    fontWeight: 500,
    margin: 0,
  },
  selected: {
    backgroundColor: '#0D6FB9',
    height: '100%',
    width: '5px',
  },
  unselected: {
    backgroundColor: '#f5f5f5',
    height: '100%',
    width: '5px',
  },
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50vh',
  },
  tab: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  scrollSticky: {
    overflow: 'hidden',
  },
  divider: {
    height: '2px',
    margin: '10px 10px 10px 21px',
    backgroundColor: '#00000014',
  },
  banner: {
    position: 'sticky',
    marginTop: '-12px',
    marginLeft: '-12px',
    marginRight: '-2px',
    zIndex: 2000,
  },
  skeletonBody: {
    width: '200px',
    margin: '12px',
    height: '35px',
  },
}));

export enum PersonalInfoWrapperTabsIndex {
  PersonalInformation = 0,
  ContactInformation,
  Addresses,
  EmergencyContact,
  Applications,
}

export const PersonalInfoWrapper = props => {
  const { classes } = useStyles();
  const [showApplicationQuestions] = useDecision(ff_candidate_tags);
  const [selected, setSelected] = useState<number>(props?.defaultTabIndex || 0);
  const pInfoRef = useRef<HTMLDivElement | null>(null);
  const contRef = useRef<HTMLDivElement | null>(null);
  const addRef = useRef<HTMLDivElement | null>(null);
  const bannerRef = useRef<HTMLDivElement | null>(null);
  const emergencyContactRef = useRef<HTMLDivElement | null>(null);
  const applicationRef = useRef<HTMLDivElement | null>(null);
  const personalInfoError = useSelector(selectPersonalInfoError);
  const candidateDetailsSel = useSelector(candidateDetailsSelection);
  const { setError, getValues, reset } = useFormContext();

  useEffect(() => {
    handleClick(selected);
  }, [selected]);

  useEffect(() => {
    if (personalInfoError?.length > 0) {
      personalInfoError?.map(error => {
        if (error?.isError && error?.errorField) {
          setError(error?.errorField, { type: 'manual', message: '' });
        }
      });
      personalInfoError?.some(error => error?.isError) && bannerRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [personalInfoError]);

  const handleClick = index => {
    setSelected(index);
    switch (index) {
      case 0:
        pInfoRef?.current?.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case 1:
        contRef?.current?.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case 2:
        addRef?.current?.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case 3:
        emergencyContactRef?.current?.scrollIntoView({
          behavior: 'smooth',
        });
        break;
      case 4:
        applicationRef?.current?.scrollIntoView({
          behavior: 'smooth',
        });
        break;
    }
  };
  const refreshPage = () => {
    history.go(0);
  };

  return (
    <>
      <LayoutGrid container xs={12} className={classes.root}>
        <LayoutGridItem item xs={4} md={3} className={classes.scrollSticky} marginTop="12px">
          <LayoutGrid container direction="row" spacing={2}>
            <LayoutGridItem item xs={12} onClick={() => handleClick(0)}>
              <LayoutGrid container direction="row" className={classes.tab}>
                <LayoutGridItem item style={{ padding: '0px' }}>
                  <Box className={selected === 0 ? classes.selected : classes.unselected} />
                </LayoutGridItem>
                <LayoutGridItem item style={{ padding: '10px 0px' }}>
                  <Typography
                    className={selected === 0 ? classes.textColorSelected : classes.textColorUnselected}
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                  >
                    Personal Information
                  </Typography>
                </LayoutGridItem>
              </LayoutGrid>
            </LayoutGridItem>
            <LayoutGridItem item xs={12} onClick={() => handleClick(1)}>
              <LayoutGrid container direction="row" className={classes.tab}>
                <LayoutGridItem item style={{ padding: '0px' }}>
                  <Box className={selected === 1 ? classes.selected : classes.unselected} />
                </LayoutGridItem>
                <LayoutGridItem item style={{ padding: '10px 0px' }}>
                  <Typography
                    className={selected === 1 ? classes.textColorSelected : classes.textColorUnselected}
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                  >
                    Contact Information
                  </Typography>
                </LayoutGridItem>
              </LayoutGrid>
            </LayoutGridItem>
            <LayoutGridItem item xs={12} onClick={() => handleClick(2)}>
              <LayoutGrid container direction="row" className={classes.tab}>
                <LayoutGridItem item style={{ padding: '0px' }}>
                  <Box className={selected === 2 ? classes.selected : classes.unselected} />
                </LayoutGridItem>
                <LayoutGridItem item style={{ padding: '10px 0px' }}>
                  <Typography
                    className={selected === 2 ? classes.textColorSelected : classes.textColorUnselected}
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                  >
                    Addresses
                  </Typography>
                </LayoutGridItem>
              </LayoutGrid>
            </LayoutGridItem>
            <LayoutGridItem item xs={12} onClick={() => handleClick(3)}>
              <LayoutGrid container direction="row" className={classes.tab}>
                <LayoutGridItem item style={{ padding: '0px' }}>
                  <Box className={selected === 3 ? classes.selected : classes.unselected} />
                </LayoutGridItem>
                <LayoutGridItem item style={{ padding: '10px 0px' }}>
                  <Typography
                    className={selected === 3 ? classes.textColorSelected : classes.textColorUnselected}
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                  >
                    Emergency Contact
                  </Typography>
                </LayoutGridItem>
              </LayoutGrid>
            </LayoutGridItem>
            <LayoutGridItem item xs={12} onClick={() => handleClick(4)}>
              <LayoutGrid container direction="row" className={classes.tab}>
                <LayoutGridItem item style={{ padding: '0px' }}>
                  <Box className={selected === 4 ? classes.selected : classes.unselected} />
                </LayoutGridItem>
                <LayoutGridItem item style={{ padding: '10px 0px' }}>
                  <Typography
                    className={selected === 4 ? classes.textColorSelected : classes.textColorUnselected}
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                  >
                    Application Questions
                  </Typography>
                </LayoutGridItem>
              </LayoutGrid>
            </LayoutGridItem>
          </LayoutGrid>
        </LayoutGridItem>
        {props.isLoading ? (
          <>
            <LayoutGridItem
              item
              xs={8}
              md={9}
              className={props.isExpanded ? classes.expandedRightPanel : classes.rightPanel}
            >
              <LayoutGrid container direction="column" data-testid="container" className={classes.Titleroot}>
                {Array.from({ length: 8 }).map((_, index) => (
                  <Box key={index} display="flex" justifyContent="space-between" mb={2}>
                    <Skeleton variant="rectangular" className={classes.skeletonBody} />
                    <Skeleton variant="rectangular" className={classes.skeletonBody} />
                    <Skeleton variant="rectangular" className={classes.skeletonBody} />
                  </Box>
                ))}
              </LayoutGrid>
            </LayoutGridItem>
          </>
        ) : (
          <LayoutGridItem
            item
            xs={8}
            md={9}
            className={props.isExpanded ? classes.expandedRightPanel : classes.rightPanel}
          >
            {personalInfoError?.map(
              (error, index) =>
                error.isError && (
                  <Grid key={index} className={classes.banner} ref={bannerRef}>
                    <BannerMessage
                      message={error.errorMessage}
                      refreshButton={error?.errorField !== 'piSSN'}
                      refreshAction={refreshPage}
                    />
                  </Grid>
                ),
            )}
            <LayoutGrid container direction="column" data-testid="container" className={classes.Titleroot}>
              <LayoutGridItem item>
                <div ref={pInfoRef}>
                  <PersonalInfoSection
                    isSSNViewableByUser={props.isSSNViewableByUser}
                    isSSNEditableByUser={props.isSSNEditableByUser}
                    isDOBViewableByUser={props.isDOBViewableByUser}
                    isDOBEditableByUser={props.isDOBEditableByUser}
                    isNameEditableByUser={props.isNameEditableByUser}
                    isGenderViewableByUser={props.isGenderViewableByUser}
                    isGenderEditableByUser={props.isGenderEditableByUser}
                    canSelectDeclineToState={props.canSelectDeclineToState}
                    isNUIDEditableByUser={props.isNUIDEditableByUser}
                  />
                </div>
              </LayoutGridItem>
              <Divider variant="middle" className={classes.divider} />
              <LayoutGridItem item>
                <div ref={contRef}>
                  <ContactInfoSection
                    checkContactEdit={props.checkContactEdit}
                    handleShowInfoMessage={props.handleShowInfoMessage}
                  />
                </div>
              </LayoutGridItem>
              <Divider variant="middle" className={classes.divider} />
              <LayoutGridItem item>
                <div ref={addRef}>
                  <AddressSection checkRoleTypeEdit={props.checkContactEdit} />
                </div>
              </LayoutGridItem>

              <Divider variant="middle" className={classes.divider} />
              <LayoutGridItem item>
                <div ref={emergencyContactRef}>
                  <EmergencyContact />
                </div>
              </LayoutGridItem>
              {showApplicationQuestions.enabled && (
                <>
                  <Divider variant="middle" className={classes.divider} />
                  <LayoutGridItem>
                    <div ref={applicationRef}>
                      <ApplicationQuestions isApplicationQuestionsEditable={props.isApplicationQuestionsEditable} />
                    </div>
                  </LayoutGridItem>
                </>
              )}
            </LayoutGrid>
          </LayoutGridItem>
        )}
      </LayoutGrid>
    </>
  );
};
