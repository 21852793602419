import React, { useEffect, useState } from 'react';
import { Grid, ThemeProvider, StyledEngineProvider,useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { ReqPrefSection } from '../Common/ReqPrefSection';
import { reqPref } from 'app/enums/Unit';
import { SingleLineInput } from '../Common/SingleLineInput';
import { useTranslation } from 'react-i18next';
import { SimpleCheckbox } from '../Common/SimpleCheckbox';
import { NewUnit, Reference } from 'app/models/Unit/CreateUnit';

interface Props {
  unit: NewUnit;
  onUnitPropChange: (property: string, value: any) => void;
  disableEdit?: boolean;
}

const useStyles = makeStyles()(theme => ({
  wrapper: {
    paddingTop: theme.spacing(2),
  },
  titleWrapper: {
    paddingBottom: '1%',
  },
}));

export const References = (props: Props) => {
  const { unit, onUnitPropChange, disableEdit } = props;
  const { classes } = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const [referencesValue, setReferencesValue] = useState<Reference>({
    isRequired: unit?.reference?.isRequired,
    numberOfReferenceRequired: unit?.reference?.numberOfReferenceRequired,
    numberOfReferenceToRFO: unit?.reference?.numberOfReferenceToRFO,
    withinMonths: unit?.reference?.withinMonths ? unit?.reference?.withinMonths : false,
    referenceFromSupervisor: unit?.reference?.referenceFromSupervisor
      ? unit?.reference?.referenceFromSupervisor
      : false,
  });
  const handleReqPrefCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReferencesValue({ ...referencesValue, isRequired: event.target.value === reqPref.req });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.type === 'checkbox')
      setReferencesValue({ ...referencesValue, [event.target.name]: event.target.checked });
    else setReferencesValue({ ...referencesValue, [event.target.name]: getNumericValue(event.target.value) });
  };

  const getNumericValue = (value: string) => {
    const numericValue = parseInt(value);
    return numericValue >= 0 ? numericValue : '';
  };

  useEffect(() => {
    onUnitPropChange('reference', referencesValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referencesValue]);

  return (
    <div>
      {/* TODO: Remove this theme wrapper since it uses the global theme */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UnitDetailsBody variant="outlined">
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={1}>
                  <Grid item className={classes.titleWrapper}>
                    <UnitCardsTitle>{t('order.createUnit.references.title')}</UnitCardsTitle>
                  </Grid>
                </Grid>
                <span className={classes.wrapper}>
                  <ReqPrefSection
                    isRequired={referencesValue.isRequired}
                    handleReqPref={handleReqPrefCheck}
                    isdisabled={disableEdit}
                    id={'createUnitReferencesReqPrefRadio'}
                  />
                </span>
                <Grid item container direction="row" spacing={2} className={classes.wrapper}>
                  <Grid item xs={6}>
                    <SingleLineInput
                      id={'createUnitReferencesNumberOfReferencesRequiredTxt'}
                      inputName={'numberOfReferenceRequired'}
                      isdisabled={disableEdit}
                      inputLabel={t('order.createUnit.references.numberOfReferencesRequired')}
                      inputDefaultValue={''}
                      inputValue={referencesValue.numberOfReferenceRequired}
                      handleChange={handleChange}
                      inputType={'number'}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SingleLineInput
                      id={'createUnitReferencesVumberOfReferencesToRFOTxt'}
                      inputName={'numberOfReferenceToRFO'}
                      inputLabel={t('order.createUnit.references.numberOfReferencesToRFO')}
                      inputValue={referencesValue.numberOfReferenceToRFO}
                      isdisabled={disableEdit}
                      inputDefaultValue={''}
                      handleChange={handleChange}
                      inputType={'number'}
                    />
                  </Grid>
                </Grid>
                <Grid item container direction="row" spacing={2} className={classes.wrapper}>
                  <Grid item>
                    <SimpleCheckbox
                      id={'createUnitReferencesWithinMonthsCheck'}
                      isChecked={referencesValue.withinMonths}
                      handleChecked={handleChange}
                      isdisabled={disableEdit}
                      checkboxLabel={t('order.createUnit.references.withinMonths')}
                      checkboxName={'withinMonths'}
                    />
                  </Grid>
                  <Grid item>
                    <SimpleCheckbox
                      id={'createUnitReferencesReferencefromSupervisorCheck'}
                      isChecked={referencesValue.referenceFromSupervisor}
                      handleChecked={handleChange}
                      isdisabled={disableEdit}
                      checkboxLabel={t('order.createUnit.references.referencefromSupervisor')}
                      checkboxName={'referenceFromSupervisor'}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </UnitDetailsBody>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};
