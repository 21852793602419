import React, { useMemo } from 'react';
import SearchSuggestionIcon from 'app/assets/images/Tasks/search_suggestion_icon.png';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { SearchType } from 'app/models/GlobalSearch/GlobalSearch';

const useStyles = makeStyles()({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'inherit',
  },
  img: {
    width: '154px',
  },
  searchSuggestionText: {
    paddingTop: '10px',
    font: 'italic normal normal 14px/20px Roboto',
    textAlign: 'center',
  },
});

export const SearchRecommendation = ({ entity }: { entity: SearchType }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const searchText = useMemo(()=>{
     return entity === SearchType.tasks? t('search.globalSearch.task.searchRecommendationText'):
    entity === SearchType.coverage ? t('search.globalSearch.coverage.searchRecommendationText')
    : t('search.globalSearch.placement.searchRecommendationText')
  },[entity])
  
  return (
    <div className={classes.content}>
      <div>
        <img alt={`Search Suggestion Icon`} src={SearchSuggestionIcon} className={classes.img} />
      </div>
      <div className={classes.searchSuggestionText}>
        <p>
          {searchText}
        </p>
      </div>
    </div>
  );
};
