import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';

const SendToButtonGroupStyles = makeStyles()(theme => ({
  buttonTitle: {
    textAlign: 'center',
    font: 'normal normal normal 13px/20px Roboto',
    color: theme.palette.framework.system.doveGray,
    opacity: '1',
    width: '100%',
    letterSpacing: '0',
    textTransform: 'none',
  },
  buttonTitleOnClick: {
    textAlign: 'center',
    font: 'normal normal normal 13px/20px Roboto',
    color: theme.palette.components.button.primary.color,
    opacity: '1',
    width: '100%',
    fontWeight: 'inherit',
    letterSpacing: '0',
    textTransform: 'none',
  },
  ccButton: {
    width: '60px',
    height: '36px',
    background: `${theme.palette.components.button.primary.color} 0% 0% no-repeat padding-box`,
    opacity: '1',
    padding: '10px 5px',
  },
  ccButtonOnClick: {
    width: '60px',
    height: '36px',
    background: `${theme.palette.components.button.secondary.backgroundColor} 0% 0% no-repeat padding-box`,
    opacity: '1',
    padding: '10px 5px',
    '&:hover': {
      background: theme.palette.components.button.secondary.backgroundColor,

    },
  },
  bccButton: {
    width: '62px',
    height: '36px',
    background: `${theme.palette.components.button.primary.color} 0% 0% no-repeat padding-box`,
    opacity: '1',
    padding: '10px 5px',
  },
  bccButtonOnClick: {
    width: '62px',
    height: '36px',
    background: `${theme.palette.components.button.secondary.backgroundColor} 0% 0% no-repeat padding-box`,
    opacity: '1',
    padding: '10px 5px',
    '&:hover': {
      background: theme.palette.components.button.secondary.backgroundColor,     
    },
  },
  toButton: {
    width: '69px',
    height: '36px',
    background: `${theme.palette.components.button.primary.color} 0% 0% no-repeat padding-box`,
    borderRadius: '4px 0px 0px 4px',
    opacity: '1',
    padding: '10px 5px',
  },
  toButtonOnClick: {
    width: '69px',
    height: '36px',
    background: `${theme.palette.components.button.secondary.backgroundColor} 0% 0% no-repeat padding-box`,
    borderRadius: '4px 0px 0px 4px',
    opacity: '1',
    padding: '10px 5px',
    '&:hover': {
      background: theme.palette.components.button.secondary.backgroundColor,
    },
  },
}));

export enum Tabs {
  To = 'To',
  CC = 'CC',
  BCC = 'BCC',
}

export const SendToButtonGroup = props => {
  const { handleTabChange, index, selectedTab = Tabs.To } = props;
  const { classes, cx } = SendToButtonGroupStyles();
  const [currentTab, setCurrentTab] = useState(Tabs.To);

  useEffect(() => {
    setCurrentTab(selectedTab);
    handleTabChange(selectedTab, index, false);
  }, [selectedTab]);

  const changeStyle = tab => {
    setCurrentTab(tab);
    handleTabChange(tab, index, true);
  };

  return (
    <ButtonGroup>
      <Button
        onClick={() => {
          return changeStyle(Tabs.To);
        }}
        className={cx({
          [classes.toButtonOnClick]: currentTab === Tabs.To,
          [classes.toButton]: currentTab !== Tabs.To,
        })}
      >
        <div
          className={cx({
            [classes.buttonTitleOnClick]: currentTab === Tabs.To,
            [classes.buttonTitle]: currentTab !== Tabs.To,
          })}
        >
          {Tabs.To}
        </div>
      </Button>
      <Button
        onClick={() => {
          return changeStyle(Tabs.CC);
        }}
        className={cx({
          [classes.ccButtonOnClick]: currentTab === Tabs.CC,
          [classes.ccButton]: currentTab !== Tabs.CC,
        })}
      >
        <div
          className={cx({
            [classes.buttonTitleOnClick]: currentTab === Tabs.CC,
            [classes.buttonTitle]: currentTab !== Tabs.CC,
          })}
        >
          {Tabs.CC}
        </div>
      </Button>
      <Button
        onClick={() => {
          return changeStyle(Tabs.BCC);
        }}
        className={cx({
          [classes.bccButtonOnClick]: currentTab === Tabs.BCC,
          [classes.bccButton]: currentTab !== Tabs.BCC,
        })}
      >
        <div
          className={cx({
            [classes.buttonTitleOnClick]: currentTab === Tabs.BCC,
            [classes.buttonTitle]: currentTab !== Tabs.BCC,
          })}
        >
          {Tabs.BCC}
        </div>
      </Button>
    </ButtonGroup>
  );
};
