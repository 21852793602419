import { Box, Grid, Typography } from 'amn-ui-core';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import useCommunication from './UseCommunication';
import { ChannelType } from '@AMIEWEB/Notification/Constants';
import EmailBlue from 'app/assets/images/Email-Blue.svg';
import MailBoxIcon from 'app/assets/images/MailBox.svg';
import { ICandidateDetails } from 'app/models/Candidate/CandidateProfile';
import emailwithdrawyellow from 'app/assets/images/email-revoke-yellow.svg';

const useStyles = makeStyles()(() => ({
  email: {
    fontSize: 'small',
    display: 'flex',
    color: '#006FB9',
    maxWidth: 800,
    cursor: 'pointer',
  },
  emailHover: {
    fontSize: 'small',
    textDecoration: 'underline',
    color: '#006FB9',
    display: 'flex',
    maxWidth: 800,
    cursor: 'pointer',
  },
  emailDisabled: {
    fontSize: 'small',
    display: 'flex',
    color: '#333333',
    maxWidth: 800,
    cursor: 'default',
  },
  phoneDescription: {
    margin: '6px 0px',
    fontWeight: 500,
    fontSize: 12,
  },
  wrapper: {
    marginTop: 10,
    minWidth: 255,
  },
  emailAddress: {
    margin: '6px 2px',
    fontSize: 14,
    fontWeight: 400,
    textWrap: 'nowrap',
  },
  warningMessage: {
    color: '#CC0000',
    textAlign: 'left',
  },
  warningMessageContainer: {
    margin: '0px 18px',
  },
}));

interface IEmailStackProps {
  emails: Array<string>;
  backDrop?: any;
  showWarningMessage?: boolean;
  candidateDetails?: ICandidateDetails;
  onClose?: () => void;
  communicationConsentFlag?: boolean;
  candidateProfile?: boolean;
}
const EmailStack = (props: IEmailStackProps) => {
  const {
    emails,
    backDrop,
    showWarningMessage,
    candidateDetails,
    communicationConsentFlag,
    candidateProfile = false,
  } = props;
  const { classes } = useStyles();
  const { initiateCommunication } = useCommunication();
  const warningMessage = 'Candidate has opted out of Email communication.';
  const communicationConsent = communicationConsentFlag ? true : showWarningMessage ? false : true;

  const CommunicationItems = item => {
    const enabledIcon = EmailBlue;
    const disabledIcon = MailBoxIcon;
    const [hover, setHover] = React.useState<boolean>(false);
    return (
      <Box>
        <Box style={{ display: 'flex', justifyContent: 'start', margin: '0px 10px' }} data-testid="email-element">
          <Box
            className={
              !communicationConsent && !candidateProfile
                ? classes.emailDisabled
                : hover
                ? classes.emailHover
                : classes.email
            }
            onClick={() => {
              if (!communicationConsent && !candidateProfile) return;
              backDrop && backDrop(true);
              props?.onClose && props?.onClose();
              initiateCommunication({
                channelType: ChannelType.email,
                email: item?.email,
                candidateDetails: candidateDetails,
              });
              backDrop && backDrop(false);
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            data-testid="communication-handler-wrapper"
          >
            <img
              src={!communicationConsent ? emailwithdrawyellow : hover ? enabledIcon : disabledIcon}
              style={{ width: 25, margin: '5px 8px' }}
            />
            <p className={classes.emailAddress} data-testid="email-address">
              {item?.email || ''}
            </p>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box className={classes.wrapper} data-testid="email-address-wrapper">
      <Box>
        {showWarningMessage && (
          <Grid item className={classes.warningMessageContainer}>
            <Typography className={classes.warningMessage}>{warningMessage}</Typography>
          </Grid>
        )}
        <Box>
          {emails?.map(ele => (
            <CommunicationItems email={ele} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default EmailStack;
