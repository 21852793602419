import { missingField } from 'app/constants';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSubmissionInfo,
  selectServiceFailures,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { RequirementsRow } from '../../TableComponents/TableComponents';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import { ff_placement_ui_requirement_check } from 'app/constants/FeatureFlags/Placement/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { placementSummaryActions } from 'store/redux-store/placement-summary/slice';
import { Typography } from 'amn-ui-core';
import { TableSection } from '../../../customComponents/TableSection';
import { getRequirementCheck } from '../../../helper';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { WhiteTooltip } from '@AMIEWEB/Common';

export const WorkHistory = () => {
  const { t } = useTranslation();
  const { placementId } = useContext(AddOnContext);
  const dispatch = useDispatch();
  const {
    orderReq: {
      order: { workHistory: orderWorkHistory },
    },
    requirementFilter,
  } = useSelector(selectSubmissionInfo);
  const { workHistoryOrderRequirement: serviceFailed } = useSelector(selectServiceFailures);
  const [requirementCheckFlag] = useDecision(ff_placement_ui_requirement_check);

  const { promiseInProgress: promiseInProgressOrderWorkHistory } = usePromiseTracker({
    area: 'placement-submission-work-history-order-requirement',
    delay: 0,
  });
  const { promiseInProgress } = usePromiseTracker({
    area: 'pplacement-submission-mice-details-order-requirement',
    delay: 0,
  });
  const { display } = useRequirementFilter('submission-order', requirementFilter, orderWorkHistory?.type);

  return display ? (
    <RequirementsRow
      id="placement-submission-order-work-history"
      title={t('placement.profile.summary.submission.order.workHistory.title')}
      requirementCell={
        requirementCheckFlag.enabled && orderWorkHistory && orderWorkHistory?.type ? (
          <RowKey
            orderWorkHistory={orderWorkHistory ?? null}
            workHistoryGapInDays={
              orderWorkHistory?.data?.workHistoryGapInDays
                ? `${orderWorkHistory?.data?.workHistoryGapInDays} days`
                : `0 days`
            }
            workHistoryGapInYears={
              orderWorkHistory?.data
                ? orderWorkHistory?.data?.entireWorkHistory
                  ? `over all work history`
                  : `within the last ${orderWorkHistory?.data?.workHistoryGapInYears} years`
                : `0 years`
            }
            t={t}
          />
        ) : (
          <Typography variant="body1">{missingField}</Typography>
        )
      }
      candidateCell={missingField}
      loading={requirementCheckFlag.enabled ? promiseInProgressOrderWorkHistory : promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() =>
        requirementCheckFlag.enabled
          ? dispatch(placementSummaryActions.workHistorySubmissionRequirement({ placementId, isRetryingService: true }))
          : dispatch(placementSummaryActions.getMiscDetailsOrderRequirement({ placementId, isRetryingService: true }))
      }
    />
  ) : null;
};

const RowKey = ({ orderWorkHistory, workHistoryGapInDays, workHistoryGapInYears, t }) => (
  <>
    <TableSection
      items={[
        {
          icon: orderWorkHistory ? getRequirementCheck(orderWorkHistory?.type) : null,
          primaryContent: orderWorkHistory ? (
            <div>
              <span style={{ paddingRight: '3px' }}>
                {t('placement.profile.summary.submission.order.orderWorkHistory.text', {
                  workHistoryGapInDays,
                  workHistoryGapInYears,
                })}
              </span>
              <WhiteTooltip
                arrow
                title={<span>{t('placement.profile.summary.submission.order.orderWorkHistory.message')}</span>}
              >
                <InfoOutlinedIcon style={{ color: '#005BBB', fontSize: '16px' }} />
              </WhiteTooltip>
            </div>
          ) : (
            missingField
          ),
        },
      ]}
    />
  </>
);
