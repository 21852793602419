import { ICheckbox } from './CustomComponents/CheckboxContainer';
import { ITypeAheadOption } from './CustomComponents/ControlledTypeAhead';
import { IMultiTypeAheadOption } from '../../../../Common/TypeAheads/MultiSelectTypeAhead';
import {
  GetShiftTimes,
  GetFacilityTypes,
  GetShiftLengths,
  GetPlacementLengths,
  GetPlacementTypes,
  GetJobExpLevels,
  GetClientSettings,
} from 'utils/common/CandidatePreferences';
import { Concatenate } from 'utils/string/string';
import { GetRadii } from 'utils/common/CandidatePreferences/Radius';
import { ILocationSearch } from 'app/models/Candidate/CandidateJobPreference';

const formatCheckBoxOptions = (options: { ID: number; Description: string; Name: string }[]) =>
  options.map(item => {
    const option: ICheckbox = {
      object: item,
      label: item.Description,
      name: item.Name,
      checked: false,
      isReadOnly: false,
    };
    return option;
  });

export const formatTypeAheadOptions = options =>
  options.map(item => {
    const option: ITypeAheadOption = {
      object: item,
      label: item.Description,
    };
    return option;
  });

const formatMultiSelectTypeAheadOptions = (options: { ID: number; Description: string }[]) =>
  options.map(item => {
    const option: IMultiTypeAheadOption = {
      object: item,
      label: item.Description,
      isReadOnly: false,
    };
    return option;
  });

export const GenerateLocationOptions = (locations: ILocationSearch[]) =>
  locations
    ? locations.map(loc => {
        const parsedOption = {
          stateId: parseInt((loc.stateId as unknown) as string),
          stateProvinceId: loc.stateProvinceId,
          stateDescription: loc.stateDescription,
          cityId: loc.cityId ? parseInt((loc.cityId as unknown) as string) : null,
          city: loc.city,
          zipId: loc.zipId ? parseInt((loc.zipId as unknown) as string) : null,
          zip: loc.zip ? parseInt((loc.zip as unknown) as string) : null,
        };
        const option: ITypeAheadOption = {
          object: parsedOption,
          label: Concatenate([loc.city, loc.stateDescription, loc.zip], ', '),
        };
        return option;
      })
    : [];

//Job Types
export function GetPlacementTypeCheckboxes() {
  const placementTypes = GetPlacementTypes();
  return formatCheckBoxOptions(placementTypes);
}

//Job Types
export function GetJobExpLevelsCheckboxes() {
  const jobExpLevels = GetJobExpLevels();
  return formatCheckBoxOptions(jobExpLevels);
}

//PlacementLengthData
export function GetPlacementLengthCheckboxes() {
  const placementLengths = GetPlacementLengths();
  return formatCheckBoxOptions(placementLengths);
}

//ShiftTimes
export function GetShiftTimeCheckboxes() {
  const shiftTimes = GetShiftTimes();
  return formatCheckBoxOptions(shiftTimes);
}

//ShiftLengts
export function GetShiftLengthCheckboxes() {
  const shiftLengths = GetShiftLengths();
  return formatCheckBoxOptions(shiftLengths);
}

//FacilityTypes
export function GetFacilityTypeCheckboxes() {
  const facilityTypes = GetFacilityTypes();
  return formatCheckBoxOptions(facilityTypes);
}

//ClientSettings
export function GetClientSettingOptions() {
  const clientSettings = GetClientSettings();
  return formatMultiSelectTypeAheadOptions(clientSettings);
}

//Radii
export function GetRadiiOptions() {
  const radii = GetRadii();
  return formatTypeAheadOptions(radii);
}
