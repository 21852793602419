import { SortColumn } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PlacementSearchFilterName } from 'app/components/GlobalSearch/Placement/SearchUtils';
import { TFunction } from 'i18next';

export interface IAssociatedPlacements {
  count: number;
  items: any[];
  applyFilters: boolean;
}

export const initialState: IAssociatedPlacements = {
  count: 0,
  items: [],
  applyFilters: false,
};

export interface IRootFilter {
  [PlacementSearchFilterName.cid];
  [PlacementSearchFilterName.oid];
  [PlacementSearchFilterName.facility];
}

export type concernedPlacementsKey =
  | PlacementSearchFilterName.oid
  | PlacementSearchFilterName.cid
  | PlacementSearchFilterName.facility;

const getPlacementsAction = createAction<{
  filter?: any;
  pageNumber: number;
  pageSize: number;
  sortedColumn?: { column: string; direction: 'asc' | 'desc' } | undefined | null;
  multiSortedColumns?: SortColumn[];
  translation: TFunction;
}>('GET_CONCERNED_PLACEMENTS');

const reduxSlice = createSlice({
  name: 'associatedPlacements',
  initialState,
  reducers: {
    setData(state, action: PayloadAction<{ count: number; items: any[] }>) {
      return { ...state, ...action.payload };
    },
    setApplyFilter(state, action: PayloadAction<boolean>) {
      state.applyFilters = action.payload;
    },
    reset() {
      return { ...initialState };
    },
  },
});

export const associatedPlacementActions = { ...reduxSlice.actions, getPlacementsAction };
export const { name: associatedPlacementSliceKey, reducer: associatedPlacementReducer } = reduxSlice;
