import { JsonHubProtocol, HubConnectionState, HubConnectionBuilder, LogLevel, HubConnection } from '@microsoft/signalr';
import { trackEvent } from 'app-insights/appInsightsTracking';

const startSignalRConnection = async (connection: HubConnection) => {
  try {
    await connection.start();
    console.assert(connection.state === HubConnectionState.Connected);
    console.log('SignalR connection established');
    trackEvent({
      type: 'event',
      name: 'SignalR Connection - Connection established to signalR',
      properties: { connectionId: connection.connectionId },
    });
  } catch (err) {
    console.assert(connection.state === HubConnectionState.Disconnected);
    setTimeout(() => startSignalRConnection(connection), 5000);
    trackEvent({
      type: 'error',
      name: 'SignalR Connection failed',
      properties: { error: err },
    });
  }
};

// Set up a SignalR connection to the specified hub URL, and actionEventMap.
// actionEventMap should be an object mapping event names, to eventHandlers that will
// be dispatched with the message body.
export const setupSignalRConnection = (
  employeeId: string,
  sharedDeskEmployeeId: string,
  accessToken: string,
  signalRLogLevel: string,
) => {
  if (!employeeId) {
    const ERROR_TEXT = `Connection to Azure SignalR will not be made as the employeeId in Invalid`;
    console.error(ERROR_TEXT);
    trackEvent({
      type: 'error',
      name: ERROR_TEXT,
    });
  } else {
    let connectionUrl = `${globalThis?.app?.env?.REACT_APP_NOTIFICATION_HUB_URL}?user=${employeeId}`;
    // If sharedDeskEmployeeId is present, then subscribe to sharedDesk signalR group
    if (sharedDeskEmployeeId) {
      connectionUrl = `${connectionUrl}&group=${sharedDeskEmployeeId}`;
    }
    // creating the connection instance
    // withAutomaticReconnect will automatically try to reconnect at intervals 5sec, 10sec, and 15sec
    const connection = new HubConnectionBuilder()
      .withUrl(connectionUrl, {
        withCredentials: false,
        accessTokenFactory: () => {
          return `${accessToken}`;
        },
      })
      .withAutomaticReconnect([5000, 10000, 15000])
      .withHubProtocol(new JsonHubProtocol())
      .configureLogging(getLogLevel(signalRLogLevel ?? ''))
      .build();

    // keepAliveIntervalInMilliseconds default is 15000 and we are using default
    // serverTimeoutInMilliseconds default is 30000 and we are using 60000 set below
    connection.serverTimeoutInMilliseconds = 60000;

    // re-establish the connection if connection dropped
    connection.onclose(error => {
      console.assert(connection.state === HubConnectionState.Disconnected);
      console.log('Connection closed due to error. Try refreshing this page to restart the connection', error);
      trackEvent({ type: 'error', name: 'SignalR Connection - Connection closed due to error', properties: { error } });
    });

    connection.onreconnecting(error => {
      console.assert(connection.state === HubConnectionState.Reconnecting);
      console.log('Connection lost due to error. Reconnecting.');
      trackEvent({
        type: 'error',
        name: 'SignalR Connection - Connection lost due to error. Reconnecting',
        properties: { error },
      });
    });

    connection.onreconnected(connectionId => {
      console.assert(connection.state === HubConnectionState.Connected);
      console.log('Connection reestablished. Connected with connectionId', connectionId);
      trackEvent({
        type: 'error',
        name: 'SignalR Connection - Connection reestablished. Connected with connectionId',
        properties: { connectionId },
      });
    });
    startSignalRConnection(connection);

    return connection;
  }
};

const getLogLevel = (logLevel: string) => {
  switch (logLevel.toLowerCase()) {
    case 'trace':
      return LogLevel.Trace;
    case 'debug':
      return LogLevel.Debug;
    case 'information':
      return LogLevel.Information;
    case 'warning':
      return LogLevel.Warning;
    case 'error':
      return LogLevel.Error;
    case 'none':
      return LogLevel.None;
    default:
      return LogLevel.Error;
  }
};
