import React from 'react';
import {  Grid, GridSize, Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
interface Props {
  gridSize?: boolean | GridSize | undefined;
  contentValue: string;
  children?: React.ReactNode;
  id?: string;
}

const useStyles = makeStyles()((theme: Theme) =>
  ({
    content: {
      whiteSpace: 'pre-wrap',
    },
    root: {
      paddingBottom: theme.spacing(2),
    },
  }));

export const InnerValueHTMLSection = (props: Props) => {
  const { gridSize, contentValue, children, id } = props;

  const { classes } = useStyles();

  const text = contentValue.replace(/(\r\n|\n|\r)/gm, '<br/>');

  const lines = text.split('<br/>');

  const renderLine = (line, index) => (
    <span className={classes.content}>
      {line}
      {index < lines.length - 1 && <br />}
    </span>
  );

  return (
    <Grid item xs={gridSize} className={classes.root} id={id}>
      {lines.map((line, index) => renderLine(line, index))}
      {children}
    </Grid>
  );
};
