import { GridColumns } from '@mui/x-data-grid-pro';
import { XGridDefaultColumn } from 'app/components/Common/XGrid/cells/XGridDefaults';
import { CoverageRequestsGridKeys } from './gridHelper';

export const getCoverageRequestColumns = (t, flexColumns?): GridColumns => {
  const defaultCRWorkDeskColumn = {
    ...XGridDefaultColumn,
    // replace this to have flex column layout for default columns
    // flex: flexColumns ? 1 : 0
    flex: 0,
  };
  return [
    {
      ...defaultCRWorkDeskColumn,
      headerName: t('home.coverageWorkDesk.tabs.request.columns.coverageFor'),
      field: CoverageRequestsGridKeys.coverageFor,
      width: 180,
      sortable: false,
    },
    {
      ...defaultCRWorkDeskColumn,
      headerName: t('home.coverageWorkDesk.tabs.request.columns.coveredBy'),
      field: CoverageRequestsGridKeys.coveredBy,
      width: 180,
      sortable: false,
    },
    {
      ...defaultCRWorkDeskColumn,
      headerName: t('home.coverageWorkDesk.tabs.request.columns.dateRange'),
      field: CoverageRequestsGridKeys.dateRange,
      width: 190,
      sortable: false,
    },
    {
      ...defaultCRWorkDeskColumn,
      headerName: t('home.coverageWorkDesk.tabs.request.columns.duration'),
      field: CoverageRequestsGridKeys.displayCoverageDuration,
      width: 90,
      sortable: false,
    },
    {
      ...defaultCRWorkDeskColumn,
      headerName: t('home.coverageWorkDesk.tabs.request.columns.notes'),
      field: CoverageRequestsGridKeys.notes,
      width: 240,
      sortable: false,
    },
    {
      ...defaultCRWorkDeskColumn,
      headerName: t('home.coverageWorkDesk.tabs.request.columns.createdBy'),
      field: CoverageRequestsGridKeys.createdBy,
      width: 180,
      sortable: false,
    },
    {
      ...defaultCRWorkDeskColumn,
      headerName: t('home.coverageWorkDesk.tabs.request.columns.createdDate'),
      field: CoverageRequestsGridKeys.createdDate,
      width: 180,
    },
    {
      ...defaultCRWorkDeskColumn,
      headerName: t('home.coverageWorkDesk.tabs.request.columns.lastUpdatedBy'),
      field: CoverageRequestsGridKeys.lastUpdatedBy,
      width: 180,
      sortable: false,
    },
  ];
};
