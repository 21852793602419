import { Avatar, Divider, Grid, IconButton, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect } from 'react';
import CloseIcon from 'app/assets/images/close-24px.svg';
import MailBoxIcon from 'app/assets/images/MailBox.svg';
import MessageIcon from 'app/assets/images/Message.svg';
import PhoneIcon from 'app/assets/images/Phone.svg';
import { DrawerFitted } from 'app/components/Common/Drawer/Drawer';
import { Name2 } from 'app/models/Candidate/CandidateProfile';
import { List, ListItem, ListItemText, ListItemAvatar } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';
import { trackEvent } from 'app-insights/appInsightsTracking';

const useStyles = makeStyles()(theme => ({
  drawer: {
    flexShrink: 0,
    '& .MuiPaper-root': {
      top: '45px',
      width: '420px',
    },
    '& .MuiListItem-gutters': {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    '& .MuiListItemAvatar-root': {
      minWidth: '0px',
    },
  },
  drawerInner: {
    padding: '10px 15px',
    width: '100%',
  },
  drawerTitle: {
    top: '79px',
    left: '20px',
    width: '200px',
    height: '24px',
    textAlign: 'left',
    font: 'normal normal normal 18px/24px Roboto',
    letterSpacing: '0px',
    color: '#333333',
    opacity: 1,
  },
  drawerSegment: {
    width: '100%',
    textAlign: 'left',
  },
  mainTitle: {
    left: '31px',
    fontWeight: 'bold',
    height: '21px',
    color: '#003E68',
    textAlign: 'left',
    font: 'normal normal medium 16px/22px Roboto',
    letterSpacing: '0px',
    opacity: 1,
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  userAvatar: {
    width: '38px',
    height: '38px',
    borderRadius: '80px',
    opacity: 1,
    color: 'white',
    backgroundColor: '#52429A',
  },
  itemList: {
    opacity: 1,
    paddingBottom: '2%',
  },
  name: {
    paddingRight: '10px',
  },
  closeIcon: {
    flexGrow: 0.8,
    display: 'flex',
  },
  closeIconPad: {
    paddingRight: '0px',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  drawerTitleAlign: {
    display: 'flex',
  },
  itemListPad: {
    padding: '10px 0px 0px 15px',
  },
  userAvatarPad: {
    paddingRight: '8px',
  },
  actionIconPad: {
    paddingLeft: '8px',
  },
}));

export const PlacementContactsDrawer = props => {
  const { classes } = useStyles();
  const { open, contacts } = props;
  const { t } = useTranslation();

  const closeDrawer = event => {
    props.onCloseDrawer();
  };

  useEffect(() => {
    if (open) {
      trackEvent({ type: 'click', name: 'Placement Contacts Opened' });
    }
  }, [open]);

  const genInitials = (name: Name2) => {
    return `${name?.firstName?.substring(0, 1) || ''}${name?.lastName?.substring(0, 1) || 'UNK'}`;
  };

  const displayNullData = data => {
    if (data.firstName === undefined && data.lastName === undefined) {
      return true;
    } else return false;
  };

  const ContactList = props => {
    return (
      <>
        <Grid item className={classes.itemList}>
          <Grid item className={`${classes.drawerSegment} ${classes.mainTitle}`}>
            {props.title}
          </Grid>
          <Grid item className={`${classes.drawer} `}>
            <List>
              <ListItem disableGutters>
                {!displayNullData(props.name) ? (
                  <>
                    <ListItemAvatar className={classes.userAvatarPad}>
                      <Avatar data-avatar className={classes.userAvatar}>
                        {genInitials(props.name)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      className={classes.name}
                      primary={`${props?.name?.firstName || ''} ${props?.name?.middleName || ''} ${
                        props?.name?.lastName || ''
                      }`}
                    />
                    <ListItemAvatar className={classes.actionIconPad}>
                      <img src={MailBoxIcon} />
                    </ListItemAvatar>
                    <ListItemAvatar className={classes.actionIconPad}>
                      <img src={PhoneIcon} />
                    </ListItemAvatar>
                    <ListItemAvatar className={classes.actionIconPad}>
                      <img src={MessageIcon} />
                    </ListItemAvatar>
                  </>
                ) : (
                  <b>{t('candidate.myPlacements.placementContactsDrawer.NA')}</b>
                )}
              </ListItem>
            </List>
          </Grid>
          <Grid item>{props.idx < 4 ? <Divider /> : ''}</Grid>
        </Grid>
      </>
    );
  };

  return (
    <DrawerFitted open={open} onDrawerClose={closeDrawer} width={340}>
      <Grid className={classes.drawerInner} direction="column" alignContent="center" container>
        <Grid item className={classes.drawerSegment}>
          <Grid wrap="nowrap" alignItems="center" container className={classes.drawerTitleAlign}>
            <Grid item>
              <Typography component="span" variant="body2" color="textPrimary" className={classes.drawerTitle}>
                {t('candidate.myPlacements.placementContactsDrawer.navigationTitle')}
              </Typography>
            </Grid>
            <Grid item className={classes.closeIcon} justifyContent="flex-end">
              <IconButton
                edge="end"
                onClick={closeDrawer}
                className={classes.closeIconPad}
                data-testid="dt-id-close-drawer"
                color="primary"
                size="small">
                <img src={CloseIcon} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.itemListPad}>
          {contacts?.map((data, idx) => {
            return <ContactList title={data.contactName} name={data.name} idx={idx} />;
          })}
        </Grid>
      </Grid>
    </DrawerFitted>
  );
};
