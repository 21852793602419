import { createAction } from '@reduxjs/toolkit';
import { DocStatus } from './types';

const getPlacementDocuments = createAction<{
  placementId: number;
}>('GET_PLACEMENT_DOCUMENTS');

const getPlacementSubmitPackets = createAction<{
  placementId: number;
}>('GET_PLACEMENT_SUBMIT_PACKETS');

const downloadSubmitPacket = createAction<{
  docId: string;
  avoidStepUpdate?: boolean;
}>('DOWNLOAD_PLACEMENT_SUBMIT_DOCUMENT');

const downloadConfirmationDoc = createAction<{
  docId: string;
  docStatus: DocStatus;
  source: 'Classic' | 'Web'
}>('DOWNLOAD_PLACEMENT_CLIENT_CONFIRMATION_DOCUMENT');

const getClientConfirmationDocs = createAction<{
  placementId: number;
}>('GET_PLACEMENT_CLIENT_CONFIRMATION_DOCS');

const deleteDraftTemplate = createAction<{
  placementId: number;
}>('DELETE_PLACEMENT_CLIENT_CONFIRMATION_DRAFT');

const updateClientConfirmationDocStatus = createAction<{
  placementId: number;
  documentId: string;
}>('UPDATE_CLIENT_CONFIRMATION_DOC_STATUS');

const updateCredentialingDocStatus = createAction<{
  placementId: number;
  documentId: string;
}>('UPDATE_CREDENTIALING_DOC_STATUS');

export const serviceActions = {
  getPlacementDocuments,
  getPlacementSubmitPackets,
  getClientConfirmationDocs,
  downloadSubmitPacket,
  downloadConfirmationDoc,
  deleteDraftTemplate,
  updateClientConfirmationDocStatus,
  updateCredentialingDocStatus,
};
