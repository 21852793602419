import React from 'react';
import { ErrorAction } from 'app/components/Common/ErrorPage/ErrorAction';
import { Table, RowTitle, Loading } from '../../../CommonTableComponents/CommonTableComponents';
import { TableCell as DetailsCell } from 'app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/CommonTableComponents/CommonTableComponents';

export const DetailsTable = ({ ...props }) => {
  return (
    <Table>
      <tbody>{props.children}</tbody>
    </Table>
  );
};

interface DetailsRowProps {
  title: string;
  detailCell: string | JSX.Element;
  loading?: boolean;
  [key: string]: any;
}
export const DetailsRow = ({
  title,
  detailCell,
  loading,
  transparentTop = false,
  transparentBottom = false,
  hasError = false,
  onTryAgain,
  ...props
}: DetailsRowProps) => {
  const cellStyle = {
    borderTop: transparentTop ? 'transparent' : undefined,
    borderBottom: transparentBottom ? 'transparent' : undefined,
    paddingTop: transparentTop ? 0 : undefined,
    paddingBottom: transparentBottom ? 0 : undefined,
  };

  return (
    <tr {...props}>
      <DetailsCell style={{ width: '20%', ...cellStyle }}>
        <RowTitle variant="body1">{title}</RowTitle>
      </DetailsCell>
      {hasError && !loading ? (
        <DetailsCell colSpan="2">
          <ErrorAction onTryAgain={onTryAgain} />
        </DetailsCell>
      ) : (
        <>
          <DetailsCell style={{ width: '60%', ...cellStyle }}>{loading ? <Loading /> : detailCell}</DetailsCell>
        </>
      )}
    </tr>
  );
};
