import { makeStyles } from 'tss-react/mui';
import { Box, Grid, MenuItem, MenuList, Paper, Popper, Tab, Tabs, Theme } from 'amn-ui-core';
import { trackEvent } from 'app-insights/appInsightsTracking';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import styledComponent from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  dense: boolean;
}

const useStyles = makeStyles<{ enlarged: boolean }>()((theme: Theme, { enlarged }) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'inherit',
    width: 'inherit',
  },
  panel: {
    backgroundColor: 'inherit',
    color: '#52429A',
  },
  panelText: {
    fontSize: enlarged ? 16 : 14,
    textTransform: 'none',
    minWidth: 'fit-content',
  },
  hoverViewWrapper: {
    display: 'inline-block',
  },
  tabIndicator: {
    height: 4,
    backgroundColor: '#52429A',
  },
  defaultTabStyle: {
    color: '#333333',
    opacity: 1,
  },
  activeTabStyle: { color: '#52429A' },
  addOnGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  paper: {
    width: 175,
    pointerEvents: 'auto',
    padding: '5px',
    marginLeft: '1px',
  },
}));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, dense, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (dense ? <Box>{children}</Box> : <TabBox>{children}</TabBox>)}
    </div>
  );
}

const TabBox = styledComponent(Box)`
  padding: 12px 0 !important;
`;
function a11yProps(index: number) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const deriveInitTab = numOfTabs => {
  const tab = parseInt(queryString.parse(window.location.search).tab);
  const isTabSelectionValid = tab !== null && tab !== undefined && !isNaN(tab) && tab >= 0 && tab < numOfTabs;
  return isTabSelectionValid ? tab : 0;
};

export interface customTabsProps {
  tabs?: {
    label?: string;
    component?: any;
    description?: string;
    value?: number;
    getSelectedValue?: Function;
    isHover?: boolean;
    options?: any;
    onClick?: any;
  }[];
  modifyUrl?: boolean;
  dense?: boolean;
  analytics?: {
    name: string;
  };
  customValue?: number;
  customHandleChange?: (event: React.ChangeEvent<{}>, newValue: number) => void;
  hidePanel?: boolean;
  addOnComponent?: JSX.Element;
  tabCol?: boolean | 'auto' | 1 | 4 | 10 | 2 | 7 | 6 | 5 | 3 | 8 | 9 | 11 | 12 | undefined;
  onTabChanged?: (tab) => void;
  enlarged?: boolean;
  version1?: boolean;
}

export const CustomTabs = ({
  tabs,
  modifyUrl,
  analytics,
  customValue,
  customHandleChange,
  hidePanel = false,
  dense = false,
  addOnComponent,
  tabCol,
  onTabChanged,
  enlarged = false,
  version1 = false,
}: customTabsProps) => {
  const { classes } = useStyles({ enlarged });
  const history = useHistory();
  const location = useLocation();

  const [value, setValue] = useState(() => deriveInitTab(tabs?.length));
  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(() => {
    if (modifyUrl) {
      setValue(deriveInitTab(tabs?.length));
    }
  }, [history.location.search, modifyUrl, tabs?.length]);

  /**
   * Code: customValue should be used only when modifyUrl === false,
   * else the results can collide with each other under edge cases.
   * */
  useEffect(() => {
    if (customValue !== undefined) {
      setValue(customValue);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customValue]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      if (modifyUrl && newValue !== value) {
        if (version1) {
          const searchParam = new URLSearchParams({ tab: newValue.toString() });
          history.push({ pathname: history.location.pathname, search: searchParam.toString(), state: location?.state });
        } else history.push({ pathname: history.location.pathname, search: `tab=${newValue}`, state: location?.state });
      } else setValue(newValue);

      if (analytics && tabs) {
        trackEvent({ type: 'click', name: `tabChange:${analytics.name}`, properties: { tab: tabs[newValue].label } });
      }
      onTabChanged?.(newValue);
    },
    [analytics, history, modifyUrl, onTabChanged, tabs, value, version1],
  );

  const handlePopoverOpen = React.useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handlePopoverClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOnClick = React.useCallback((option, getSelectedValue) => {
    setAnchorEl(null);
    getSelectedValue(option);
  }, []);

  return (
    <div className={classes.root}>
      <PanelPaper elevation={0} className={classes.panel}>
        <Grid container direction="row" justifyContent="space-between" xs={12}>
          <Grid item xs={tabCol ? tabCol : addOnComponent ? 11 : 12}>
            <Tabs
              value={value}
              classes={{ indicator: classes.tabIndicator }}
              textColor="inherit"
              onChange={customHandleChange ? customHandleChange : handleChange}
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              variant="scrollable"
            >
              {tabs?.map((tab, index) =>
                tab.isHover ? (
                  <Box key={index.toString()} className={classes.hoverViewWrapper} onMouseLeave={handlePopoverClose}>
                    <Tab
                      onMouseEnter={handlePopoverOpen}
                      key={index.toString()}
                      classes={{ root: classes.panelText }}
                      label={tab.label}
                      className={value === index ? classes.activeTabStyle : classes.defaultTabStyle}
                      {...a11yProps(index)}
                    />
                    <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} id="menu-list-grow">
                      <Paper>
                        <MenuList className={classes.paper}>
                          {tab?.options?.map((option, indx) => (
                            <MenuItem
                              key={indx.toString()}
                              onClick={() => handleOnClick(option.name, tab.getSelectedValue)}
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Paper>
                    </Popper>
                  </Box>
                ) : (
                  <Tab
                    key={index.toString()}
                    classes={{ root: classes.panelText }}
                    label={tab.label}
                    className={value === index ? classes.activeTabStyle : classes.defaultTabStyle}
                    {...a11yProps(index)}
                  />
                ),
              )}
            </Tabs>
          </Grid>
          {addOnComponent && (
            <Grid item classes={{ root: classes.addOnGrid }} xs={1}>
              {addOnComponent}
            </Grid>
          )}
        </Grid>
      </PanelPaper>
      {hidePanel
        ? null
        : tabs?.map((tab, index) => (
            <TabPanel key={index.toString()} value={value} index={index} dense={dense}>
              {tab.component}
            </TabPanel>
          ))}
    </div>
  );
};

const PanelPaper = styledComponent(Paper)`
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #e3dfdf;
`;
