import { Button, Divider, Grid } from 'amn-ui-core';
import styledComponent from 'styled-components';

export const OrderCreationBodySpace = styledComponent.div`
  padding: 0px 12px;
  background: #f4f5f7;
`;

export const OrderTitle = styledComponent.div`
  padding: 7px 0px;
  font-size: 18px;
  font-family; Roboto,Regular;
  color: #333;
`;

export const OrderCreationScrollable = styledComponent.div`
  height: 93vh;
  width: 93vw;
  overflow: hidden auto;
`;

export const Header = styledComponent(Grid)`
  background: #f4f5f7;
  opacity: 1;
  display: flex;
  flex-direction: row;
  padding: 5px 0px;
`;
export const InnerSection = styledComponent.div`
  display: flex;
  flex-direction: row;
  width: inherit;
`;
export const HeaderSection = styledComponent.div`
  display: flex;
  flex-direction: column;
  width: inherit;
`;

export const FacilityNameCard = styledComponent.div`
  width: 80px;
  height: 80px;
  background: #34b233;
  border-radius: 4px;
  padding: 5%;
  font-size: 42px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  margin-right: 15px;
`;

export const OrderHeader = styledComponent.div`
  font-size: 20px;
  color: #52429a;
`;

export const FacilityName = styledComponent.div`
  display: block;
  font-size: 12px;
  color: #333333;
  padding-bottom: 10px;
`;

export const CustomDivider = styledComponent(Divider)`
  opacity: 1;
  border: 1px solid #d1d1d1;
`;

export const ButtonSection = styledComponent.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 12px 36px 12px 0px;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1000;
  backdrop-filter: blur(2px);
`;

export const CustomButton = styledComponent(Button)`
  margin-right: 10px;
`;

export const CancelButton = styledComponent(CustomButton)`
  background: #8e9195;
  color: white;
`;

export const OrderBody = styledComponent.div`
  padding-left: 5;
  padding-right: 5;
  padding-bottom: 5;
`;

export const MessageHeader = styledComponent.div`
  padding-top: 2%;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  text-align: left;
`;

export const MessageBody = styledComponent.div`
  text-align: justify;
  padding-right: 13%;
  font-size: 15px;
`;

export const ButtonSpace = styledComponent.div`
  display: flex;
  padding-bottom: 3%;
  margin-left: 7px;
`;

export const ReviewFacilityBody = styledComponent.div`
  padding-left: inherit;
  font-size: 12px;
  padding-top: 1%;
  padding-bottom: 1%;
`;

export const GwwSpace = styledComponent.div`
  padding-top: 1%;
`;

export const CheckboxText = styledComponent.span`
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 30px;
`;

export const ButtonGrid = styledComponent(Grid)`
  display: flex;
`;

export const ChipsGrid = styledComponent(Grid)`
  width: 100%;
  height: 100%;
`;

export const ErrorGrid = styledComponent(Grid)`
  width: 100%;
  height: 100%;
  color: #cc0000;
  margin-top: 2%;
`;

export const ShiftChecks = styledComponent.span`
  padding-right: 30px;
  max-width: 100px;
`;

export const ShiftAvailableSection = styledComponent.div`
  padding: 20px 0px;
`;

export const ShiftAvailableHeader = styledComponent.div`
  color: #555555;
  font-weight: 500;
  font-size: 12px;
`;

export const AccordionText = styledComponent.div`
  width: 100%;
  text-align: right;
  font-weight: 500;
  font-size: 12px;
`;

export const DateSection = styledComponent.div`
  padding-top: 0%;
`;
