import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { useWatch } from 'react-hook-form';
import { useEditableRequirement } from './EditRequirementsHelper';
import { dnsClearedCheck } from './EditablePassFailLogic';

export const useEditableRequirementDnsCleared = () => {
  // DNS CLEARED
  const dnsCleared: any = useWatch({ name: 'dnsCleared' });

  useEditableRequirement({
    data: dnsCleared?.checked,
    requirementId: IPlacementRequirementKey.dnsCleared,
    requirementKey: 'dnsCleared',
    requirementCheck: dnsClearedCheck,
    failRequirement: {
      id: IPlacementRequirementKey.dnsCleared,
      message: 'DNS: Not Cleared',
      tooltip: {
        message: 'DNS not cleared for the Candidate.',
      },
    },
    riskRequirement: {
      id: IPlacementRequirementKey.dnsCleared,
      message: 'DNS: Not Cleared',
      tooltip: {
        message: 'DNS not cleared for the Candidate.',
      },
    },
  });
};
