import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import React from 'react';

export const SellingNotesConfirmation = props => {
  const { open, setDialogOpen, handleOpenSellingNotes } = props;

  return (
    <GenericDialog
      open={open}
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
      onClose={() => setDialogOpen(false)}
      dialogTitleProps={{
        style: { paddingTop: '20px' },
        text: 'Are you sure want to proceed?',
        closeButton: true,
      }}
      dialogActions={[
        {
          text: 'No',
          variant: 'contained',
          onClick: () => setDialogOpen(false),
          color: 'tertiary',
        },
        {
          text: 'Yes',
          variant: 'contained',
          color: 'primary',
          onClick: () => handleOpenSellingNotes(true),
        },
      ]}
    >
      <span>{`This action will replace the existing content.`}</span>
    </GenericDialog>
  );
};
