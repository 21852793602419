import { HomePage } from 'app/layout/pages/HomePage';
import React, { useEffect } from 'react';
import { createRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { gridStateActions, GridTag } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import { defaultFilterValues } from './Filters/ClientContactFilterConfig';
import { FacilityContactsActions, IFilterChipSelected } from './Store/FacilityContacts.redux';
import { FacilityContactsList } from './Grid/FacilityContactsList';
import {
  selectContactDeskChips,
  selectContactsLoading,
  selectContactsSourceData,
  selectState,
} from './Store/FacilityContacts.selector';
import { ClientContactFilter } from './Filters/ClientContactFilter';
import { FacilityContactsChips } from './Chips/FacilityContactsChips';
import { createFilterChips, getFilteredData } from './Helpers/FacilityContactFilterHelper';
import { selectContactId } from 'app/components/Facility/FacilityDetails/FacilityTabs/CreateClientContact/store/ClientContact.selector';
import { clientContactActions } from 'app/components/Facility/FacilityDetails/FacilityTabs/CreateClientContact/store/ClientContact.redux';
import { useParams } from 'react-router-dom';

export const FacilityContactTab = () => {
  const params = useParams<{ id: string }>();
  const formMethods = useForm({ defaultValues: defaultFilterValues, shouldUnregister: false });
  const { handleSubmit } = formMethods;
  const hiddenSubmitButton = createRef<HTMLButtonElement>();
  const dispatch = useDispatch();
  const loading = useSelector(selectContactsLoading);
  const chips = useSelector(selectContactDeskChips);
  const flattenedData = useSelector(selectContactsSourceData);
  const selectStateId = useSelector(selectState);
  const selectCreatedClientContactId = useSelector(selectContactId);

  useEffect(() => {
    if (selectCreatedClientContactId && params?.id) {
      dispatch(
        clientContactActions.getFacilityAndUnitClientContacts({
          facilityId: Number(params?.id),
          pageNumber: 1,
          pageSize: 1000,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCreatedClientContactId, params?.id]);

  React.useEffect(() => {
    const filteredData = flattenedData.filter(item => item.descStatus === selectStateId);
    dispatch(FacilityContactsActions.setFilteredData(filteredData));
    if (selectStateId) {
      dispatch(
        FacilityContactsActions.setFilterChips({
          ...chips,
          status: [
            {
              label: selectStateId,
              enableDelete: false,
              value: selectStateId,
            },
          ],
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flattenedData, selectStateId]);

  //EVENTS
  const onApplyFilter = (values, selectedChips?: IFilterChipSelected) => {
    dispatch(gridStateActions.setFilterAttributes({ filterObject: Object.entries(values) }));
    const filteredData = getFilteredData(values, flattenedData, selectedChips);
    dispatch(FacilityContactsActions.setSelectedContacts(filteredData.filter(item => item.selected === true)));
    dispatch(
      FacilityContactsActions.setFilterChips({
        ...chips,
        ...createFilterChips(values, !!selectedChips ? selectedChips : undefined),
      }),
    );
    dispatch(FacilityContactsActions.setFilteredData(filteredData));
  };

  React.useEffect(() => {
    dispatch(
      gridStateActions.setGridState({
        gridTag: GridTag.FacilityClientContactsGrid,
        pageSize: 25,
        pageNumber: 1,
        previewItemId: -1,
        showPreviewDialog: false,
        //sortedColumn: { column: 'firstName', direction: 'asc' },
        sortedColumn: null,
        filterAttributes: undefined,
      }),
    );
    return () => {
      dispatch(gridStateActions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (params?.id)
      dispatch(
        clientContactActions.getFacilityAndUnitClientContacts({ facilityId: params.id, pageNumber: 1, pageSize: 1000 }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(filter => onApplyFilter(filter))}>
          <HomePage
            title=""
            filters={<ClientContactFilter onApplyFilter={onApplyFilter} />}
            table={<FacilityContactsList />}
            chips={<FacilityContactsChips chips={chips} onApplyFilter={onApplyFilter} />}
            showSidePanel={false}
            shrink={true}
          />
          <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
        </form>
      </FormProvider>
    </>
  );
};
