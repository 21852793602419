import { Box, Grid, Skeleton } from 'amn-ui-core';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React from 'react';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips';
import { RowSection } from '../../Common/RowSection';
import { usePromiseTracker } from 'react-promise-tracker';
import { CandidateNavigation } from '../../CardNavigation';
import { useTranslation } from 'react-i18next';
import { useStyles } from './utils';
import { CandidateContainer } from '../../Common/CandidateContainer';
import VirtualinterviewIcon from 'app/assets/images/IconImages/VirtualinterviewIcon.svg';
import { theme } from 'styles/global-styles';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';

function VirtualInterviewUIV2({ skillsetsList, handleNavigationToVirtualInterview, checkAccessPermission, navigate }) {
  const { promiseInProgress } = usePromiseTracker({ area: 'get-candidate-summary-tab-details', delay: 0 });
  const { t } = useTranslation();
  const { classes } = useStyles();
  const user = useSelector(selectUser);
  return (
    <>
      <LayoutGridItem style={{ width: '100%' }} pb="12px">
        <Box className={classes.container}>
          <CandidateContainer
            title={`${t('candidate.virtualInterviewCard.title')} (all)`}
            icon={<img src={VirtualinterviewIcon} alt="VirtualinterviewIcon" />}
            rightIcon={true}
            enableLink={true}
            onClickAll={handleNavigationToVirtualInterview}
            children={
              <>
                {promiseInProgress ? (
                  <LayoutGridItem mr={2}>
                    <Skeleton />
                    <Skeleton />
                  </LayoutGridItem>
                ) : (
                  <LayoutGrid direction="column" spacing={1} className={classes.rowItem}>
                    {skillsetsList?.length > 0 ? (
                      skillsetsList?.map((item, index) => {
                        return (
                          <CustomTooltip
                            style={{ color: theme.palette.framework.system.tertiaryGrey }}
                            interactive={true}
                            tooltipContent={item?.toolTipMessage}
                            key={index}
                          >
                            <RowSection
                              header={item.skillset}
                              data={''}
                              preData={item.interviewStatus}
                              color={theme.palette.system.darkGreenForIcon}
                              showAlert={false}
                              showHalfwayProgress={item.interviewStatus ? false : true}
                              showHalfwayProgressSecondaryIcon={item.interviewStatus ? false : true}
                            />
                          </CustomTooltip>
                        );
                      })
                    ) : (
                      <RowSection
                        header={
                          <Grid container direction="column">
                            <Grid item>{t('candidate.virtualInterviewCard.interviewUnavailableLine1')}</Grid>
                            <Grid item>
                              <span>{t('candidate.virtualInterviewCard.interviewUnavailableLine2')}</span>
                              {checkAccessPermission() && <span>{t('candidate.virtualInterviewCard.youCan')}</span>}
                            </Grid>
                            {checkAccessPermission() && (
                              <Grid item>
                                <span
                                  className={classes.inlineLink}
                                  onClick={() => navigate(CandidateNavigation.VirtualInterview)}
                                >
                                  {t('candidate.virtualInterviewCard.addInterview')}
                                </span>
                              </Grid>
                            )}
                          </Grid>
                        }
                        data={''}
                        preData={''}
                        color={theme.palette.framework.system.navyBlue}
                        showHalfwayProgress={true}
                        showHalfwayProgressSecondaryIcon={true}
                        showAlert={false}
                      />
                    )}
                  </LayoutGrid>
                )}
              </>
            }
          />
        </Box>
      </LayoutGridItem>
    </>
  );
}

export default VirtualInterviewUIV2;
