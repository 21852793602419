import { IOrderBonusCommand, IOrderBonusSave, IStrikeDetailsRequest } from './Order.redux';

import { createAction } from '@reduxjs/toolkit';

const getRateElementsAction = createAction<number>('GET_ORDER_OCBR_RATEELEMENTS_DETAILS');
const getOrdersAction = createAction<{ searchString: string; serverPageSize: number }>('GET_ORDERS_DATA');
const getOrderStrike = createAction<{
  facilityId: number;
  orderId: number;
}>('GET_ORDERS_STRIKE');
const updateOrderStrike = createAction<IStrikeDetailsRequest>('UPDATE_ORDERS_STRIKE');
const getOrderBonuses = createAction<IOrderBonusCommand>('GET_ORDER_BONUSES');

const getOrderTypes = createAction<null>('GET_ORDER_TYPES');
const getRegions = createAction('GET_REGIONS');
const getOrderBonusList = createAction<number>('GET_ORDER_BONUS_LIST');
const getOrderBonusShiftList = createAction<{orderId:number,bonusId:number}>('GET_ORDER_BONUS_SHIFT_LIST');
const getOrderBonusCalculatedAmount = createAction<{orderId:number,amount:number,type:string}>('GET_ORDER_BONUS_CALCULATED_AMOUNT');
const saveOrderBonus = createAction<IOrderBonusSave>('SAVE_ORDER_BONUS');
const copyOrderBonus = createAction<{sourceOrderId:number,destinationOrderId:number,bonusTemplateId:number,userId:number}>('COPY_ORDER_BONUS');
const deleteOrderBonus = createAction<{ orderId: number; bonusIds: number[];totalRows:number }>('DELETE_ORDER_BONUS');
const getOrderBonusChangeHistory = createAction<{orderId:string,bonusId:string}>('GET_ORDER_BONUS_CHANGE_HISTORY');


export const serviceActions = {
  getRateElementsAction,
  getOrdersAction,
  getOrderStrike,
  updateOrderStrike,
  getOrderBonuses,
  getOrderTypes,
  getRegions,
  getOrderBonusList,
  getOrderBonusShiftList,
  getOrderBonusCalculatedAmount,
  saveOrderBonus,
  copyOrderBonus,
  deleteOrderBonus,
  getOrderBonusChangeHistory,
};
