import { Grid, Typography } from 'amn-ui-core';
import { missingField } from 'app/constants';
import { convertToPSTMoment } from 'app/helpers/dateHelper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IPlacementOrder } from 'store/redux-store/new-placement/types';
import styled from 'styled-components';
import { Concatenate } from 'utils/string/string';

export const ShiftInfo: React.FC<IPlacementOrder> = order => {
  const { t } = useTranslation();

  const { shifts, startDate, placementLength } = React.useMemo(() => {
    if (!order?.orderId) return { shifts: missingField, startDate: missingField, placementLength: missingField };
    const dateMoment = convertToPSTMoment(order.startDate ?? null);

    return {
      shifts: order ? Concatenate([...order.shifts], ', ') : missingField,
      startDate: order ? (dateMoment.isValid() ? dateMoment.format('MM/DD/YYYY') : t('common.asap')) : missingField,
      placementLength: order.placementLength
        ? `${order.placementLength} ${t('placement.create.popup.weeks')}`
        : missingField,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={1} flexWrap="nowrap">
          <KeyColumnGrid>
            <Typography variant="body1" color="charcoal">
              {t('Shifts: ')}
            </Typography>
          </KeyColumnGrid>
          <Grid item>
            <Typography variant="body1" color="charcoal" fontWeight={500}>
              {shifts}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} flexWrap="nowrap">
          <KeyColumnGrid>
            <Typography variant="body1" color="charcoal">
              {t('Start Date: ')}
            </Typography>
          </KeyColumnGrid>
          <Grid item>
            <Typography variant="body1" color="charcoal" fontWeight={500}>
              {startDate}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} flexWrap="nowrap">
          <KeyColumnGrid>
            <Typography variant="body1" color="charcoal">
              {t('Length: ')}
            </Typography>
          </KeyColumnGrid>
          <Grid item>
            <Typography variant="body1" color="charcoal" fontWeight={500}>
              {placementLength}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const KeyColumnGrid = styled(Grid)`
  min-width: 75px;
`;

KeyColumnGrid.defaultProps = { item: true };
