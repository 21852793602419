import { Typography } from 'amn-ui-core';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const SaveModal = ({ isSaveModalOpen, setSaveModalOpen, updateExistingFilter }) => {
  const { t } = useTranslation();
  return (
    <GenericDialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={isSaveModalOpen}
      maxWidth="sm"
      fullWidth
      onClose={() => setSaveModalOpen(false)}
      dialogTitleProps={{
        id: 'alert-dialog-title',
        text: 'Save Filters',
      }}
      dialogActions={[
        {
          text: 'Cancel',
          variant: 'text',
          color: 'tertiary',
          useDialogOnCloseEvent: true,
        },
        {
          text: 'Yes',
          variant: 'contained',
          color: 'primary',
          onClick: () => updateExistingFilter(),
        },
      ]}
    >
      <Typography>{t('Are you sure that you want to save the changes?')}</Typography>
    </GenericDialog>
  );
};
