import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { httpSuccess } from 'app/services/serviceHelpers';
import { trackPromise } from 'react-promise-tracker';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import { FacilityService } from 'app/services/FacilityServices/facility-service';
import { createOrderPreferenceActions } from './slice';
import { isNullOrUndefined } from '@microsoft/applicationinsights-core-js';

const TrackUnitPreferencesWrapper = (fn, ...args) =>
  trackPromise(
    fn(...args),
    !!args[args.length - 1] ? `get-create-order-preferences-${args[args.length - 1]}` : 'get-create-order-preferences',
  );

export function* requestUnitPreferences(
  action: PayloadAction<{
    unitId: number;
  }>,
) {
  try {
    if (!isNullOrUndefined(action.payload.unitId)) {
      const facilityService: FacilityService = yield getContext('facilityService');
      const response = yield call(
        TrackUnitPreferencesWrapper,
        facilityService.getUnitPreferences,
        action.payload.unitId,
      );
      if (httpSuccess(response.status)) {
        yield put(createOrderPreferenceActions.setPreferences({ ...response.data }))
        yield put(createOrderPreferenceActions.setIsUnitPreferenceNull(false));
      } else if (response.status === 204) {
        yield put(createOrderPreferenceActions.setIsUnitPreferenceNull(true));
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestUnitPreferences',
        area: 'unit-preference/saga',
      },
    });
  }
};

export function* orderCreationNewPreferenceSaga() {
  yield takeLatest(createOrderPreferenceActions.getUnitPreferences.type, requestUnitPreferences);
}
