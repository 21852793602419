import { getFilterRequest } from './helper';
import React from 'react';
import { Box } from 'amn-ui-core';
import { FormProvider, useForm } from 'react-hook-form';
import { CommunicationLogsFilter } from './CommunicationLogsFilter';
import { defaultValues } from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { candidateDetailActions, loadGridUserLogs, loadKpis } from '../../Profile/CandidateDetails.redux';
import { RouteParams } from '../../Profile/CandidateProfile';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { createFilterChips } from './helper';
import { ChipsContainer } from 'app/ComponentLibrary/Filter';
import { candidateDetailsSelection } from '../../Profile/CandidateDetails.selector';
import { selectUser } from 'oidc/user.selectors';
import { gridStateActions } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';

export const LogsFilterWrapper = (props: any) => {
  const params = useParams<RouteParams>();
  const formMethods = useForm({ defaultValues });
  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { dirtyFields },
  } = formMethods;
  const dispatch = useDispatch();
  const notificationData = useSelector(notificationSelection);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const user = useSelector(selectUser);
  const chips = notificationData.chips;
  const onApplyFilter = values => {
    const request = getFilterRequest(values, dirtyFields);
    if (request.initiatedBy === 'Me') {
      request.initiatedBy = user?.userInfo?.email;
    } else if(request.initiatedBy === 'Shared Desk') {
      request.initiatedBy = user?.userInfo?.sharedProfile?.email;
    }
    const updatedChannel = request.channel && request.channel.length > 0 ? [...request.channel] : [];
    request.channel = updatedChannel.map(channel =>
      channel === 'Call' ? 'Voice' : channel === 'SMS' ? 'Conversation' : channel,
    );

    dispatch(notificationDataActions.setFilterChips(createFilterChips(values)));
    dispatch(notificationDataActions.setGridFilters(request));
    dispatch(gridStateActions.setPageAttributes({ pageSize: 50, pageNumber: 1 }));
    dispatch(
      loadGridUserLogs({
        ...request,
        pageNumber: 1,
        pageSize: 50,
        brandId: params.brandId.toString(),
        contactId: params.travelerId.toString(),
      }),
    );
    dispatch(
      loadKpis({
        ...request,
        brandId: params.brandId.toString(),
        contactId: params.travelerId.toString(),
      }),
    );
    const checkRequestValues = () => {
      const hasNonEmptyValues = Object.values(request).some(
        (value) => value !== undefined && value !== null && value !== '' && (value as any[]).length !== 0
      );
      dispatch(candidateDetailActions.setFilterApplied(hasNonEmptyValues));
    };
    checkRequestValues();
  };

  const handleChipDelete = (field, chip) => {
    const chipsTemp = JSON.parse(JSON.stringify(chips));
    if (field === 'channel') {
      setValue(
        field,
        chipsTemp['channel']
          .filter(values => values.label !== chip.label)
          .map(value => ({
            label: value.label,
            value: value.label,
            enableDelete: true,
          })),
      );
    } else {
      setValue(field, null);
    }
    if (field === 'channel' && chipsTemp['channel'].length > 1) {
      dispatch(
        notificationDataActions.setFilterChips({
          ...chips,
          [field]: chipsTemp['channel'].filter(values => values.label !== chip.label),
        }),
      );
    } else {
      dispatch(
        notificationDataActions.setFilterChips({
          ...chips,
          [field]: undefined,
        }),
      );
    }

    onApplyFilter(getValues());
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onApplyFilter)}>
        <CommunicationLogsFilter />
        <Box marginBottom={'5px'} marginTop={'10px'}>
          <ChipsContainer chips={chips} onDelete={(field, chip) => handleChipDelete(field, chip)} />
        </Box>
      </form>
    </FormProvider>
  );
};
