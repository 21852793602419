import SearchGridWrapper from 'app/layout/pages/SearchGridWrapper';
import React, { createRef, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { cloneDeep } from 'lodash';
import { gridStateActions } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { useDispatch, useSelector } from 'react-redux';
import FacilityCommunicationFilters from './FacilityCommunicationHistoryFilters';
import { CommunicationHistoryTab, DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from '../Grid/FacilityCommunicationHistory';
import { RootState } from 'types';
import { selectFacilityDetail } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { FacilityResponse } from 'app/models/Facility/FacilityDetails';
import { selectFilters } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import FacilityCommunicationHistoryChips from '../Chips/FacilityCommunicationHistoryChips';
import { facilityCommunicationHistoryActions, IFacilityLogs } from '../store/FacilityCommunicationHistory.redux';
import _ from 'lodash';
import { hasChipsFilterValue, requestFilterPayload } from '../Helpers/helper';
import { useParams } from 'react-router-dom';

export const FacilityCommunicationHistoryWrapper = () => {
  const formMethods = useForm();
  const { handleSubmit, setValue } = formMethods;
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  const facilityDetailsResponse: FacilityResponse | null = useSelector(selectFacilityDetail);
  const [filterKey] = React.useState<any>(
    `FacilityCommuincationHistory-${facilityDetailsResponse?.facilityId || params.id}`,
  );

  const filterPreference = useSelector((state: RootState) => {
    return state.userPreferenceData.userGridPreferences.find(item => item.id === filterKey);
  });
  const appliedFilterValues = useSelector(selectFilters);

  const [preferencesLoaded, setPreferencesLoaded] = React.useState<boolean>(false);
  const [rendered, setRendered] = React.useState<boolean>(false);

  const onApplyFilter = (values, newFilterChips?) => {
    let requestPayload;
    requestPayload = requestFilterPayload(
      newFilterChips && hasChipsFilterValue(newFilterChips) ? newFilterChips : values,
    );

    dispatch(
      gridStateActions.setFilterAttributes({
        filterObject: Object.entries(values),
        selectedChipFilters: newFilterChips,
      }),
    );

    dispatch(
      gridStateActions.setFilterAttributes({
        filterObject: Object.entries(values),
        selectedChipFilters: newFilterChips,
      }),
    );

    if (rendered) {
      saveFilterPreferences(values, newFilterChips);
    }
    if (params.id) {
      const facilityLogsPayload: IFacilityLogs = {
        ...requestPayload,
        facilityId: parseInt(params.id),
        pageSize: DEFAULT_PAGE_SIZE,
        pageNumber: DEFAULT_PAGE_NUMBER,
      };
      dispatch(facilityCommunicationHistoryActions.getFacilityLogs(facilityLogsPayload));
    }
  };

  const saveFilterPreferences = useCallback(
    (values: any, newChipSelections?: any) => {
      if (!facilityDetailsResponse?.facilityId) {
        return;
      }
      const filters = values || appliedFilterValues?.filters;

      const preferenceData = {
        id: filterKey,
        value: {
          filtersApplied: filters,
          selectedChipFilters: newChipSelections,
        },
      };
      filters && dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
    },
    [facilityDetailsResponse?.facilityId],
  );

  React.useEffect(() => {
    const appliedFilters = filterPreference?.value?.filtersApplied;
    const selectedChips = filterPreference?.value?.selectedChipFilters;
    if (appliedFilters) {
      if (!preferencesLoaded) {
        setPreferencesLoaded(true);
        if (!!appliedFilters) {
          Object.keys(appliedFilters).forEach(key => {
            setValue(key, appliedFilters[key]);
          });
          onApplyFilter(appliedFilters, undefined);
          dispatch(
            gridStateActions.setFilterAttributes({
              filterObject: Object.entries(appliedFilters),
              selectedChipFilters: selectedChips,
            }),
          );
        }
      }
    }
  }, [filterPreference, filterPreference?.value?.selectedChipFilters]);

  React.useEffect(() => {
    dispatch(gridStateActions.setGrid('FacilityCommunicationHistoryGrid'));
    setRendered(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(gridStateActions.reset());
    };
  }, []);

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(filter => onApplyFilter(filter))}>
          <SearchGridWrapper
            filters={<FacilityCommunicationFilters onApplyFilter={onApplyFilter} filterPreference={filterPreference} />}
            table={<CommunicationHistoryTab filterPreference={filterPreference} />}
            chips={
              <FacilityCommunicationHistoryChips
                onApplyFilter={onApplyFilter}
                saveFilterPreferences={saveFilterPreferences}
              />
            }
            loading={false}
            tableMinHeight={538}
          />
        </form>
      </FormProvider>
    </>
  );
};
