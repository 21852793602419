import { FilterCompTypes } from 'app/ComponentLibrary/Filter/utils';
import { calculateTreeCount } from 'app/components/Common/TreeView/TreeViewHelpers';
import { getCitiesByKeyword, getSearchLookups } from 'app/services/SharedServices/SharedServices';
import {
  dropDownFilterOptions,
  getFacilityIDSearchLookups,
  getFacilitySearchLookups,
  getFilteredOptions,
} from '../helper';
import { PlacementSearchFilterName } from './SearchUtils';
import { addDays, endOfDay, startOfDay } from 'date-fns';

export const getPlacementTagsOpts = tagsData => {
  return tagsData.map(item => {
    return {
      id: item.id,
      name: item.name,
      value: item.value,
    };
  });
};

export class PlacementsFilter {
  constructor(
    public filterSpecs: any,
    public t: any,
    public styleClasses: any,
    public arbitrationAgreementFlag: boolean,
    public regionFlag?: boolean,
  ) {}

  getPlacementSearchFilterSpecs = () => {
    const date = new Date();
    const filters = [
      {
        filterName: this.t('search.globalSearch.placement.filterNames.placementRecruiter'),
        name: PlacementSearchFilterName.recruiter,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookups('recruiters', key),
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.placementAcctManager'),
        name: PlacementSearchFilterName.accountManager,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookups('accountmanagers', key),
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.PlacementCredAnalyst'),
        name: PlacementSearchFilterName.credentialingAnalyst,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookups('credentialinganalysts', key),
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.PlacementCCS'),
        name: PlacementSearchFilterName.placementClinicalContractSpecialist,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookups('clientcontractspecialist', key),
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.PlacementClinicalManager'),
        name: PlacementSearchFilterName.placementClinicalManager,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookups('clinicalmanager', key),
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.archiveStatus'),
        name: PlacementSearchFilterName.archiveStatus,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        options: archiveOpts,
        hideClearAll: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.status'),
        name: PlacementSearchFilterName.status,
        type: FilterCompTypes.TREE,
        isMultiSelect: true,
        options: this.filterSpecs.statusOpts,
        placeholder: 'Select',
        isSelectAll: true,
        version2: true,
        returnsObjectAsValue: true,
        size: 'large',
        customSelectedCount: value => calculateTreeCount(value),
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.submission'),
        name: PlacementSearchFilterName.submission,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        applyOnClickAway: true,
        isMultiSelect: true,
        options: this.filterSpecs.submissionOpts,
        isSelectAll: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.extensionStatus'),
        name: PlacementSearchFilterName.extensionStatus,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.extensionOpts, key),
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.amnDivision'),
        name: PlacementSearchFilterName.amnDivision,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.amnDivisionOpts, key),
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.brand'),
        name: PlacementSearchFilterName.brand,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.brandOpts, key),
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.candidate'),
        name: PlacementSearchFilterName.candidate,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookups('candidates', key),
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.cid'),
        name: PlacementSearchFilterName.cid,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookups('CID', key),
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.nonContacted'),
        name: PlacementSearchFilterName.nonContacted,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: this.t('search.globalSearch.placement.filterNames.nonContacted'),
        useMaxWidth: true,
        single: true,
        maxWidth: 140,
        clickAway: false,
        applyOnClickAway: true,
        disablePortal: true,
        definedRanges: [
          {
            label: '1 Day',
            startDate: startOfDay(addDays(date, -1)),
            endDate: endOfDay(addDays(date, -1)),
            useLabelAsValue: false,
          },
          {
            label: '2 Days',
            startDate: startOfDay(addDays(date, -2)),
            endDate: endOfDay(addDays(date, -2)),
            useLabelAsValue: false,
          },
          {
            label: '7 Days',
            startDate: startOfDay(addDays(date, -7)),
            endDate: endOfDay(addDays(date, -7)),
            useLabelAsValue: false,
          },
          {
            label: '20 Days',
            startDate: startOfDay(addDays(date, -20)),
            endDate: endOfDay(addDays(date, -20)),
            useLabelAsValue: false,
          },
          {
            label: '30 Days',
            startDate: startOfDay(addDays(date, -30)),
            endDate: endOfDay(addDays(date, -30)),
            useLabelAsValue: false,
          },
          {
            label: '60 Days',
            startDate: startOfDay(addDays(date, -60)),
            endDate: endOfDay(addDays(date, -60)),
            useLabelAsValue: false,
          },
        ],
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.tags'),
        name: PlacementSearchFilterName.placementTags,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.tagsOptions, key),
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.oid'),
        name: PlacementSearchFilterName.oid,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookups('OID', key),
        isMultiSelect: true,
        applyOnClickAway: true,
        searchOnCharacterCount: 1,
        isSelectAll: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.pid'),
        name: PlacementSearchFilterName.pid,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookups('PID', key),
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.facility'),
        name: PlacementSearchFilterName.facility,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: getFacilitySearchLookups,
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.facilityID'),
        name: PlacementSearchFilterName.facilityID,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: getFacilityIDSearchLookups,
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.unit'),
        name: PlacementSearchFilterName.unit,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookups('units', key),
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.contractAffiliate'),
        name: PlacementSearchFilterName.contractAffiliate,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookups('contractaffiliates', key),
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.orderType'),
        name: PlacementSearchFilterName.orderType,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.orderTypeOpts, key),
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.screen'),
        name: PlacementSearchFilterName.screen,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.screenOpts, key),
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.city'),
        name: PlacementSearchFilterName.city,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getCitiesByKeyword(key),
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.state'),
        name: PlacementSearchFilterName.state,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.stateOpts, key),
        isSelectAll: true,
        filterOptions: (options, { inputValue }) => {
          return dropDownFilterOptions(options, inputValue);
        },
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.mspClient'),
        name: PlacementSearchFilterName.mspClient,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.mspClientOpts, key),
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.discipline'),
        name: PlacementSearchFilterName.discipline,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.disciplineOpts, key),
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.specialty'),
        name: PlacementSearchFilterName.specialty,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.specialtyOpts, key),
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.availableStartDate'),
        name: PlacementSearchFilterName.availableStartDate,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: this.t('search.globalSearch.placement.filterNames.availableStartDate'),
        useMaxWidth: true,
        maxWidth: 210,
        clickAway: true,
        disablePortal: true,
        quickSelect: false,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.startDate'),
        name: PlacementSearchFilterName.startDate,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: this.t('search.globalSearch.placement.filterNames.startDate'),
        useMaxWidth: true,
        maxWidth: 210,
        clickAway: true,
        disablePortal: true,
        quickSelect: false,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.endDate'),
        name: PlacementSearchFilterName.endDate,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: this.t('search.globalSearch.placement.filterNames.endDate'),
        useMaxWidth: true,
        maxWidth: 210,
        clickAway: true,
        disablePortal: true,
        quickSelect: false,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.placementCuedTo'),
        name: PlacementSearchFilterName.placementCuedTo,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookups('cuedto', key),
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.contractAffiliateID'),
        name: PlacementSearchFilterName.contractAffiliateIds,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookups('contractaffiliateid', key),
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.placement.filterNames.staffingVendor'),
        name: PlacementSearchFilterName.staffingVendor,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.staffingVendorOpts, key),
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
    ];
    const regionFilter = [
      {
        filterName: this.t('search.globalSearch.placement.filterNames.region'),
        name: PlacementSearchFilterName.region,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.regionOpts, key),
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
        searchOnCharacterCount: 1,
        fallbackPlacements: ['top', 'right'],
      },
    ];
    const arbitrationFilter = [
      {
        filterName: this.t('search.globalSearch.placement.filterNames.arbitrationStatus'),
        name: PlacementSearchFilterName.arbitrationStatus,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.arbitrationStatusOpts, key),
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
    ];
    const recruitmentLeaders = [
      {
        filterName: this.t('search.globalSearch.placement.filterNames.recruitmentLeaders'),
        name: PlacementSearchFilterName.recruitmentLeaders,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        fetchOptions: async key => await getSearchLookups('recruitmentleaders', key),
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
        customClass: this.styleClasses?.recruitmentLeaders,
      },
    ];
    const filterListWithRegion = this.regionFlag ? [...filters, ...regionFilter] : filters;
    const filterList = this.arbitrationAgreementFlag
      ? [...filterListWithRegion, ...arbitrationFilter, ...recruitmentLeaders]
      : [...filterListWithRegion, ...recruitmentLeaders];
    return filterList;
  };
}

export const archiveOpts = [
  { id: 0, labelPrefix: 'Archive Status', name: 'No', value: 'N' },
  { id: 1, labelPrefix: 'Archive Status', name: 'Yes', value: 'Y' },
];
