import React from 'react';
import styledComponent from 'styled-components';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { FormInputText } from './Controls/FormInputText';
import DragDropIcon from '@mui/icons-material/DragIndicator';
import { LayoutGrid, LayoutGridItem } from '../../../../../../../layout/LayoutGrid';
import { FormMultiSelectDropDown } from './Controls/FormMultiSelectDropDown';
import { ControlType } from './helpers/SellingNotesEnum';
import { FormInputCheckbox } from '../../../PersonalInfoTab/Form/Controls/FormInputCheckbox';
import { FormInputDropdown } from '../../../PersonalInfoTab/Form/Controls/FormInputDropdown';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

const DragItem = styledComponent.div`
  padding: 10px;
  background: white;
  margin: 0 0 8px 0;
  display: grid;
  grid-gap: 20px;
  flex-direction: column;
  width: 100%;
  .MoreVertIcon {
    color: white;
    height: 18px;
    width: 18px;
  }
  &:hover {
    background: #f4f5f7;
  }
  &:hover .MoreVertIcon {
    color: #888888;
  }
`;
const useStyles = makeStyles()({
  controlComponent: {
    paddingLeft: '3px',
  },
});
const SellingNotesControls = ({ item, provided, snapshot, customClass, setChanged, handleChecked }) => {
  const { setValue } = useFormContext();
  const { classes } = useStyles();
  const getControl = customSwitch({
    [ControlType.Text]: (
      <FormInputText
        inputValue={item.defaultValue}
        readOnly={item.readOnly}
        name={item.fieldName}
        key={item.id}
        label={item.label}
        require={false}
        handleChange={() => setChanged()}
        message={'Required'}
      />
    ),
    [ControlType.Dropdown]: (
      <FormMultiSelectDropDown
        fieldName={item.fieldName}
        label={item.label}
        data={item.mappedResult}
        defaultValue={item?.defaultValue}
        customClass={customClass}
        setChanged={setChanged}
      />
    ),
    [ControlType.SingleSelectDropdown]: (
      <FormInputDropdown
        name={item.fieldName}
        options={item.mappedResult}
        label={item.label}
        require={false}
        handleChange={event => {
          setValue(item.fieldName, event?.target?.value, { shouldDirty: true });
          setChanged(setChanged);
        }}
        inputValue={item.defaultValue}
        isCheckBox={true}
      />
    ),
  })([]);

  return (
    <DragItem ref={provided.innerRef} snapshot={snapshot} {...provided.draggableProps} {...provided.dragHandleProps}>
      <LayoutGrid style={{ width: '100%' }} direction="row">
        <LayoutGridItem style={{ width: '3%', padding: 0 }}>
          <DragDropIcon className={'MoreVertIcon'} />
        </LayoutGridItem>
        <LayoutGridItem style={{ width: '12%', padding: '20px 15px' }}>
          <FormInputCheckbox handleChecked={handleChecked} name={`${item.fieldName}Checkbox`} isChecked={true} checkboxLabel={``} />
        </LayoutGridItem>
        <LayoutGridItem className={classes.controlComponent} style={{ width: '85%' }}>
          {getControl(item.type)}
        </LayoutGridItem>
      </LayoutGrid>
    </DragItem>
  );
};

export default SellingNotesControls;
