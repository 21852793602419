import React, { createRef, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ChipsContainer } from 'app/ComponentLibrary/Filter';
import { instanceOfISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import { selectFilters, selectGridState } from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import { gridStateActions } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import { generateChips } from '@AMIEWEB/Placement/PlacementDetails/PlacementTabPanel/PassportCredentialTab/Filters/PassportCredFilterUtility';
import { determineChipSelected } from '@AMIEWEB/AmWorkDesk/PlacementsDesk/filterHelper';
import { Grid, Collapse } from 'amn-ui-core';
import { SearchPageIcons } from 'app/ComponentLibrary/Filter/SearchPageIcons';
import { makeStyles } from 'tss-react/mui';
import { returnTaskFilterBody } from './FilterSearchUtils';
import { cloneDeep } from 'lodash';

const useStyles = makeStyles()({
  fillterCollapse: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export const FilterSearchChips = props => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { getValues, setValue } = useFormContext();
  const [chips, setChips] = useState({});
  const [showChipsCollapseBtn, setShowChipsCollapseBtn] = useState(false);
  const hiddenSubmitButton = createRef<HTMLButtonElement>();
  const filterObject = useSelector(selectFilters);
  const { filterAttributes } = useSelector(selectGridState);
  const [chipsContainerHeight, setChipsContainerHeight] = useState<number>(0);
  const divRef = useRef(null);
  const { collapsable = true } = props;
  const [checked, setChecked] = React.useState(false);
  const handleChange = () => {
    setChecked(prev => !prev);
  };

  useEffect(() => {
    const chipsData = Object.keys(chips).some(key => {
      const value = chips[key];
      if (Array.isArray(value) && value.length > 0) {
        return true;
      }
      if (typeof value === 'object' && value !== null && Object.keys(value).length > 0) {
        return true;
      }
      return false;
    });
    setShowChipsCollapseBtn(chipsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chips]);

  const handleChipDelete = (field, chip) => {
    const chosenValue = getValues(field);

    if (chosenValue && chosenValue.length > 0 && Array.isArray(chosenValue)) {
      const newValues = cloneDeep(chosenValue);
      if (instanceOfISelection(chip)) {
      }

      if (!chip.value[0]?.children) {
        newValues.splice(
          newValues.findIndex(opt =>
            props.isPrefixLabelAvailable ? opt.name === chip.value?.name : opt.name === chip.label,
          ),
          1,
        );
      } else {
        newValues?.forEach(parentChips => {
          const index = parentChips?.children?.findIndex(opt =>
            props.isPrefixLabelAvailable
              ? opt.name === chip.value[0]?.children[0]?.name && parentChips.name === chip.value[0]?.name
              : opt.name === chip.label,
          );
          if (index >= 0) {
            parentChips?.children?.splice(index, 1);
          }
        });
        const zeroChildrenIndex = newValues.findIndex(parent => parent?.children?.length <= 0);
        if (zeroChildrenIndex >= 0) {
          newValues.splice(zeroChildrenIndex, 1);
        }
      }
      setValue(field, newValues);
    } else {
      setValue(field, null);
    }

    const newFilters = returnTaskFilterBody(field, chip.value?.value, filterAttributes?.selectedChipFilters, true);
    dispatch(gridStateActions.setSelectedChips(newFilters));
    props.onApplyFilter(getValues());
  };

  useEffect(() => {
    setChips(filterObject?.filters ? generateChips(filterObject?.filters, filterObject?.selectedChipFilters) : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterObject?.selectedChipFilters]);

  const getNewSelection = (key, chip) => ({
    ...filterObject?.selectedChipFilters,
    [key]: determineChipSelected(
      chip,
      filterObject?.selectedChipFilters ? filterObject?.selectedChipFilters[key] : undefined,
    ),
  });

  const handleChipClick = (key, chip) => {
    if (!Array.isArray(getValues(key))) return;
    const newSelections = getNewSelection(key, chip);
    setChips(generateChips({ ...getValues() }, newSelections));
    props.onApplyFilter(getValues(), newSelections);
  };

  useEffect(() => {
    setChipsContainerHeight(divRef?.current?.clientHeight ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divRef, showChipsCollapseBtn, chips]);

  const CollapseFilters = useMemo(() => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Collapse in={checked} collapsedSize={38}>
              <div ref={divRef}>
                <ChipsContainer
                  chips={chips}
                  onDelete={(field, chip, index) => handleChipDelete(field, chip)}
                  onClick={(field, chip, index) => handleChipClick(field, chip)}
                  isCollapse={!checked}
                />
              </div>
              <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
            </Collapse>{' '}
          </Grid>
          <Grid item xs={1} className={classes.fillterCollapse}>
            {chipsContainerHeight > 38 && (
              <SearchPageIcons KeyboardArrowIcon onAdvancedChipsClick={() => handleChange()} checked={checked} />
            )}
          </Grid>
        </Grid>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divRef, checked, showChipsCollapseBtn, chips, chipsContainerHeight]);

  return (
    <>
      {showChipsCollapseBtn && collapsable ? (
        <React.Fragment>{CollapseFilters}</React.Fragment>
      ) : (
        <>
          <ChipsContainer
            chips={chips}
            onClick={handleChipClick}
            onDelete={(field, chip) => handleChipDelete(field, chip)}
          />
          <button style={{ display: 'none' }} type="submit" />
        </>
      )}
    </>
  );
};
