import { Theme, Typography, Divider, FormControlLabel, Radio, debounce } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInputDropdown } from '../PersonalInfoTab/Form/Controls/FormInputDropdown';
import { FormInputText } from '../PersonalInfoTab/Form/Controls/FormInputText';
import { isWithinInterval, isAfter, isBefore } from 'date-fns';
import { FormInputDatePicker } from '../PersonalInfoTab/Form/Controls/FormInputDatePicker';
import { ControlledTypeAhead, ITypeAheadOption } from '../PreferencesTab/CustomComponents/ControlledTypeAhead';
import { getCitiesAndZipsByStates } from 'app/services/SharedServices/SharedServices';
import { FormInputCheckbox } from '../PersonalInfoTab/Form/Controls/FormInputCheckbox';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { candidateDetailsSelection } from '../../Profile/CandidateDetails.selector';
import { useSelector } from 'react-redux';
import { ff_ui_candidate_manage_EquipmentProcedure } from 'app/constants/FeatureFlags/Candidate/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { CustomTooltip, TypeAhead } from '@AMIEWEB/Common';
import statesData from 'app/assets/jsons/State.json';
import { IOption } from '../ProfileTab/Education/FormInputDropdown';
import { FormInputRadio } from '../../WorkExperience/Edit/components/FormInputRadio';
import FormInputTextField from '../../WorkExperience/Edit/components/FormInputTextField';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: '0px 0px',
  },
  action: {
    display: 'flex',
    flexDirection: 'row',
  },
  actionBtn: {
    fontSize: 12,
    padding: 0,
    minWidth: 30,
  },
  actionNoBtn: {
    color: '#888888',
  },

  alingmentField: {
    paddingRight: '25px !important',
  },
  currentlyWorking: {
    marginBottom: '-15px',
  },
  datePickerInput: {
    height: '55px',
  },
  checkBoxContainer: {
    marginBottom: '-30px',
  },
  checkBoxInput: {
    paddingTop: '24px !important',
  },
  buttonStyle: {
    color: '#611A16',
    fontSize: '14px',
    fontWeight: 500,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  infoIcon: {
    color: '#34AADC',
    height: '20px',
    width: '20px',
  },
  infoIconAlignment: {
    paddingTop: '30px !important',
  },
  label: {
    fontSize: '14px !important',
    color: 'rgba(0, 0, 0, 0.37)',
    '&.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.37)',
    },
    '&[class*="MuiInputLabel-outlined"]': {
      marginTop: '1px',
    },
  },
}));

enum CountryCodes {
  USA = 1,
  CANADA = 2,
}

export const AddWorkExperience = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const [filteredSpecialty, setFilteredSpecialty] = React.useState<ITypeAheadOption[]>([]);
  const [candidateManageEquipmentProcedure] = useDecision(ff_ui_candidate_manage_EquipmentProcedure);
  const [pSupervisor, setPSupervisor] = useState<boolean>(false);
  const [pTeletherapy, setPTeletherapy] = useState<boolean>(false);
  const [pHospital, setPHospital] = useState<boolean>(false);
  const [pStaffing, setPStaffing] = useState<boolean>(false);
  const [pOngoing, setPOngoing] = useState<boolean>(false);
  const [addWorkExperience, setAddWorkExperience] = useState<boolean>(true);
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [startValue, setStartValue] = useState<Date>(null);
  const [endValue, setEndValue] = useState<Date>(null);
  const [addTimeOff, setAddTimeOff] = useState<boolean>(false);
  const { errors, getValues, setValue, clearErrors, control, register, setError, formState, watch } = useFormContext();
  const [charCount, setCharCount] = useState<number>(0);
  const [cityOptions, setCityOptions] = useState<IOption[]>([]);
  const [statesOptions, setStatesOptions] = useState<IOption[]>([]);

  const facilityEmployer = watch('pFacilityEmployer');
  const discipline = watch('piDiscipline');
  const speciality = watch('piSpecialty');
  const selectedState = watch('pState');
  const selectedCity = watch('piCity');
  const onGoing = watch('pOngoing');
  const selectedCountry = watch('pCountry');

  useEffect(() => {
    if (!getValues('pAddWorkExperience') && addWorkExperience) {
      setValue('pAddTimeOff', false);
      setValue('pAddWorkExperience', addWorkExperience);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStartDateChange = event => {
    setValue('pStart', event, { shouldDirty: true });
    setStartValue(new Date(event));
    clearErrors('pStart');
  };

  useEffect(() => {
    const endFromDate = endValue ? new Date(endValue) : null;
    const startDate = startValue ? new Date(startValue) : null;
    if (addTimeOff) {
      if (isOverlappingDates(startDate, endFromDate)) {
        setErrorMessageOpen(true);
        props.setSaveDisabled(true);
      } else {
        setErrorMessageOpen(false);
        props.setSaveDisabled(false);
      }
    }

    const currentlyWorking = getValues('currentlyWorking');
    if (!currentlyWorking && !pOngoing && endFromDate?.getTime() < startDate?.getTime()) {
      setError('pEnd', {
        type: 'validate',
        message: 'End date must be greater than start date',
      });
    } else {
      clearErrors('pEnd');
    }
    if (startDate?.getTime() > endFromDate?.getTime()) {
      setError('pStart', {
        type: 'validate',
        message: 'Start date must be less than end date',
      });
    } else {
      clearErrors('pStart');
    }
  }, [startValue, endValue]);

  const handleEndDateChange = event => {
    setValue('pEnd', event, { shouldDirty: true });
    setEndValue(new Date(event));
    clearErrors('pEnd');
  };

  const handleSpecialtyChange = event => {
    setValue('piSpecialty', event.target.value, { shouldDirty: true });
  };

  const handlePNotes = event => {
    setValue('pnotes', event.target.value, { shouldDirty: true });
    setCharCount(event.target.value?.length);
  };

  const onSelectionChange = choice => {
    const filteredData = props.typeAheadOptions?.specialties.filter(data => {
      return data.object.DisciplineID === choice.object.ID;
    });
    setFilteredSpecialty(filteredData);
    filteredData.length && setValue('piSpecialty', filteredData[0].label, { shouldDirty: true });
  };

  const isOverlappingDates = (startDate: Date, endDate: Date) => {
    let isOverlapping = false;
    candidateDetails?.experience?.employment.map(item => {
      if (
        item.endedOn &&
        (isWithinInterval(startDate, {
          start: new Date(item.startedOn),
          end: item.endedOn ? new Date(item.endedOn) : null,
        }) ||
          isWithinInterval(endDate, {
            start: new Date(item.startedOn),
            end: item.endedOn ? new Date(item.endedOn) : null,
          }))
      ) {
        isOverlapping = true;
      } else if (
        !item.endedOn &&
        (isAfter(startDate, new Date(item.startedOn)) || isAfter(endDate, new Date(item.startedOn)))
      ) {
        isOverlapping = true;
      } else if (
        item.endedOn &&
        isBefore(startDate, new Date(item.startedOn)) &&
        isAfter(endDate, new Date(item.endedOn))
      ) {
        isOverlapping = true;
      }
    });
    return isOverlapping;
  };

  const handleCurrentlyWorkingChange = event => {
    if (event && event.target.value === 'true') {
      errors['pReason'] = '';
    }

    setValue('currentlyWorking', event.target.value === 'true' ? true : false, { shouldDirty: true });
  };

  const handleWorkEndDateChange = event => {
    if (event && event !== '') {
      const currentDate = new Date().getTime();
      const endDate = new Date(event).getTime();
      const isCurrentlyWorking = currentDate < endDate;

      if (isCurrentlyWorking) {
        errors['pReason'] = '';
      }
      setValue('currentlyWorking', isCurrentlyWorking ? true : false, { shouldDirty: true });
    }
  };

  useEffect(() => {
    const country = getValues('pCountry');
    if (country?.id === CountryCodes.USA || country?.id === CountryCodes.CANADA) {
      const states = statesData.states
        .filter(e => e.CountryID === country?.id)
        .map(e => ({ id: e.ID, label: e.Description, value: e.StateNumber }));

      setStatesOptions(states);
      setValue('pState', states[0]);
    } else if (formState['dirtyFields']['pCountry'] || formState['touched']['pCountry']) {
      setStatesOptions([]);

      // DO NOT REMOVE SetTimeout otherwise state wont reset once you change state from USA to non-USA.
      setTimeout(() => {
        setValue('pState', '');
      }, 0);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCountry?.id === CountryCodes.USA && selectedState) {
      fetchCities();
    } else {
      setCityOptions([]);
    }

    setTimeout(() => {
      setValue('piCity', '');
    }, 0);
  }, [selectedState]);

  const fetchCities = async () => {
    const selectedStates = typeof selectedState ? [selectedState?.label] : [];
    const result = getCitiesAndZipsByStates({
      states: selectedStates,
      cities: [],
    });

    let filteredCities = [];
    result.then(res => {
      filteredCities = res[0]?.cities?.map(item => {
        return { id: item.id, label: item.name };
      });

      setCityOptions(filteredCities);
    });
  };

  const onTextSearch = async (key: string, searchMode: 'c' | 'z') => {
    const selectedStates = selectedState ? [selectedState?.label] : [];
    if (searchMode === 'c') {
      const result = getCitiesAndZipsByStates({
        states: selectedStates,
        cities: [],
        searchMode: searchMode,
        searchValue: key,
      });

      let filteredCities = [];
      result.then(res => {
        filteredCities = res[0]?.cities?.map(item => {
          return { id: item.id, label: item.name };
        });

        setCityOptions(filteredCities);
      });
    }
  };

  const yesAction = () => {
    setErrorMessageOpen(false);
    props.setSaveDisabled(false);
  };

  const enableSaveOnChanges = () => {
    const values = getValues();
    const { pFacilityEmployer, piDiscipline, piSpecialty, pState, piCity, pStart, pCountry } = values;

    if (addWorkExperience) {
      if (!pFacilityEmployer) {
        props.setSaveDisabled(true);
        return;
      } else if (!piDiscipline) {
        props.setSaveDisabled(true);
        return;
      } else if (!piSpecialty) {
        props.setSaveDisabled(true);
        return;
      } else if (!pState && (pCountry?.id === CountryCodes.USA || pCountry?.id === CountryCodes.CANADA)) {
        props.setSaveDisabled(true);
        return;
      } else if (!piCity && pCountry?.id === CountryCodes.USA) {
        props.setSaveDisabled(true);
        return;
      } else if (!pStart) {
        props.setSaveDisabled(true);
        return;
      } else {
        props.setSaveDisabled(false);
        return;
      }
    } else {
      if (!pStart) {
        props.setSaveDisabled(true);
        return;
      } else if (errorMessageOpen) {
        props.setSaveDisabled(true);
        return;
      } else {
        props.setSaveDisabled(false);
        return;
      }
    }
  };

  useEffect(() => {
    enableSaveOnChanges();
  }, [facilityEmployer, discipline, speciality, selectedState, selectedCity, startValue, onGoing]);

  return (
    <>
      <LayoutGrid
        container
        direction="column"
        className={classes.root}
        spacing={4}
        style={{ marginTop: '10px', width: '870px', paddingRight: '24px' }}
      >
        <LayoutGridItem className={`${classes.alingmentField} ${classes.currentlyWorking}`}>
          <Typography component="span">{`Add Work Experience or Time Off?`}</Typography>
        </LayoutGridItem>
        <LayoutGridItem>
          <Controller
            name="pAddWorkExperience"
            key={'Work Experience'}
            control={control}
            render={({ ref, value, onChange, ...rest }) => (
              <FormControlLabel
                name="pAddWorkExperience"
                key={'Work Experience'}
                control={
                  <Radio
                    checked={addWorkExperience}
                    onChange={e => {
                      setAddWorkExperience(!addWorkExperience);
                      setAddTimeOff(false);
                      setValue('pAddTimeOff', false);
                      setValue('pAddWorkExperience', !addWorkExperience);
                    }}
                  />
                }
                label={'Work Experience'}
              />
            )}
          />
          <Controller
            key={'Time Off'}
            name="pAddTimeOff"
            control={control}
            render={({ ref, value, onChange, ...rest }) => (
              <FormControlLabel
                key={'Time Off'}
                name="pAddTimeOff"
                control={
                  <Radio
                    checked={addTimeOff}
                    onChange={e => {
                      setAddTimeOff(!addTimeOff);
                      setAddWorkExperience(false);
                      setValue('pAddTimeOff', !addTimeOff);
                      setValue('pAddWorkExperience', false);
                    }}
                  />
                }
                label={'Time Off'}
              />
            )}
          />
        </LayoutGridItem>
        {addWorkExperience ? (
          <>
            <LayoutGridItem item>
              <LayoutGrid
                container
                classes={{ root: classes.root }}
                direction="row"
                data-testid="container"
                justifyContent="flex-start"
                spacing={2}
              >
                <LayoutGridItem item xs={5}>
                  <FormInputText
                    name="pFacilityEmployer"
                    label={'Facility/Employer *'}
                    message={errors['pFacilityEmployer']?.message || ''}
                  />
                </LayoutGridItem>
                <LayoutGridItem item xs={4}>
                  <ControlledTypeAhead
                    mandatory={false}
                    filledNormal={false}
                    register={register}
                    control={control}
                    name="piDiscipline"
                    label={'Discipline *'}
                    options={props.typeAheadOptions?.disciplines || []}
                    onChange={discipline => onSelectionChange(discipline)}
                    isError={errors.piDiscipline ? true : false}
                    helperText={errors.piDiscipline && errors.piDiscipline.message}
                    customScroll
                    disabled={false}
                    id={'createUnitExperienceDiscipline'}
                  />
                </LayoutGridItem>
                <LayoutGridItem item xs={3}>
                  <FormInputDropdown
                    standard
                    name={'piSpecialty'}
                    label={`Specialty *`}
                    options={filteredSpecialty.length ? filteredSpecialty : []}
                    inputValue={getValues('piSpecialty') || ''}
                    handleChange={handleSpecialtyChange}
                    isCheckBox={true}
                    useMenuItem
                  />
                </LayoutGridItem>
              </LayoutGrid>
            </LayoutGridItem>
            <LayoutGridItem item>
              <LayoutGrid
                container
                classes={{ root: classes.root }}
                direction="row"
                data-testid="container"
                justifyContent="flex-start"
                spacing={2}
              >
                <LayoutGridItem item xs={5}>
                  <Controller
                    name="pCountry"
                    defaultValue={null}
                    control={control}
                    render={({ ref, ...rest }) => (
                      <TypeAhead options={props.countriesData} label="Country" {...rest} isError={errors['pCountry']} />
                    )}
                  />
                </LayoutGridItem>
              </LayoutGrid>
            </LayoutGridItem>
            <LayoutGridItem item>
              <LayoutGrid
                container
                classes={{ root: classes.root }}
                direction="row"
                data-testid="container"
                justifyContent="flex-start"
                spacing={2}
              >
                <LayoutGridItem item xs={5}>
                  {watch('pCountry')?.id === CountryCodes.USA || watch('pCountry')?.id === CountryCodes.CANADA ? (
                    <Controller
                      name="pState"
                      defaultValue={statesOptions[0] || null}
                      control={control}
                      render={({ ref, ...rest }) => (
                        <TypeAhead options={statesOptions || []} label="State *" {...rest} isError={errors['pState']} />
                      )}
                    />
                  ) : (
                    <FormInputTextField name="pState" label={'State'} />
                  )}
                </LayoutGridItem>
                <LayoutGridItem item xs={4}>
                  {selectedCountry?.id === CountryCodes.USA ? (
                    <Controller
                      name="piCity"
                      defaultValue={null}
                      control={control}
                      render={({ ref, ...rest }) => (
                        <TypeAhead
                          options={cityOptions || []}
                          label="City *"
                          onInputChange={debounce(text => onTextSearch(text, 'c'), 1500)}
                          {...rest}
                        />
                      )}
                    />
                  ) : (
                    <FormInputTextField name="piCity" label={'City'} required={false} />
                  )}
                </LayoutGridItem>
                <LayoutGridItem item xs={3}>
                  <FormInputText name="piZip" label={'Zip Code'} message={errors['piZip']?.message || ''} />
                </LayoutGridItem>
              </LayoutGrid>
            </LayoutGridItem>
            <LayoutGridItem item>
              <Divider></Divider>
            </LayoutGridItem>
            <LayoutGridItem item>
              <LayoutGrid
                container
                classes={{ root: classes.root }}
                direction="column"
                data-testid="container"
                justifyContent="flex-start"
                spacing={2}
              >
                <LayoutGridItem className={`${classes.alingmentField} ${classes.currentlyWorking}`}>
                  <Typography component="span">{`Currently Working`}</Typography>
                </LayoutGridItem>
                <LayoutGridItem>
                  <LayoutGrid
                    container
                    classes={{ root: classes.root }}
                    direction="row"
                    data-testid="container"
                    justifyContent="flex-start"
                    spacing={3}
                  >
                    <LayoutGridItem>
                      <FormInputRadio
                        name="currentlyWorking"
                        value={getValues('currentlyWorking') || true}
                        handleChange={e => handleCurrentlyWorkingChange(e)}
                      />
                    </LayoutGridItem>
                    <LayoutGridItem className={classes.infoIconAlignment}>
                      <CustomTooltip tooltipContent={t('candidate.workExperienceCard.currentlyWorkingTooltip')}>
                        <ErrorOutlineIcon className={classes.infoIcon} />
                      </CustomTooltip>
                    </LayoutGridItem>
                  </LayoutGrid>
                </LayoutGridItem>
              </LayoutGrid>
              <LayoutGrid
                container
                classes={{ root: classes.root }}
                direction="row"
                data-testid="container"
                justifyContent="flex-start"
                spacing={2}
              >
                <LayoutGridItem item xs={4}>
                  <FormInputDatePicker
                    name={'pStart'}
                    label={'Start Date *'}
                    handleChange={handleStartDateChange}
                    customClass={classes.datePickerInput}
                    labelClass={classes.label}
                    trailingIconVisible={true}
                    inputValue={getValues('pStart')}
                  ></FormInputDatePicker>
                </LayoutGridItem>
                <LayoutGridItem item xs={4}>
                  <FormInputDatePicker
                    name={'pEnd'}
                    label={'End Date'}
                    handleChange={handleWorkEndDateChange}
                    readOnly={false}
                    customClass={classes.datePickerInput}
                    labelClass={classes.label}
                    trailingIconVisible={true}
                    inputValue={getValues('pEnd')}
                  ></FormInputDatePicker>
                </LayoutGridItem>
                <LayoutGridItem item xs={4}>
                  <FormInputText
                    name="pReason"
                    readOnly={false}
                    label={'Reason for Leaving'}
                    message={errors['pReason']?.message || ''}
                  />
                </LayoutGridItem>
              </LayoutGrid>
            </LayoutGridItem>
            <LayoutGridItem item>
              <Divider></Divider>
            </LayoutGridItem>
            <LayoutGridItem item>
              <LayoutGrid
                container
                classes={{ root: classes.root }}
                direction="row"
                data-testid="container"
                justifyContent="flex-start"
                spacing={2}
              >
                <LayoutGridItem item xs={4}>
                  <FormInputText
                    name="pBeds"
                    label={'# of Beds in Facility'}
                    require={false}
                    type={'number'}
                    message={errors['pBeds']?.message || ''}
                  />
                </LayoutGridItem>
                <LayoutGridItem item xs={4}>
                  <FormInputText
                    name="pUnitFloorDept"
                    label={'Unit/Floor/Dept'}
                    require={false}
                    message={errors['pUnitFloorDept']?.message || ''}
                  />
                </LayoutGridItem>
                <LayoutGridItem item xs={4}>
                  <FormInputText
                    name="pPosition"
                    label={'Position Held'}
                    require={false}
                    message={errors['pPosition']?.message || ''}
                  />
                </LayoutGridItem>
              </LayoutGrid>
            </LayoutGridItem>
            <LayoutGridItem item>
              <Divider></Divider>
            </LayoutGridItem>
            <LayoutGridItem item className={classes.checkBoxContainer}>
              <LayoutGrid
                container
                classes={{ root: classes.root }}
                direction="row"
                data-testid="container"
                justifyContent="flex-start"
                spacing={2}
              >
                <LayoutGridItem item xs={6}>
                  <Controller
                    name="piTravelerCompany"
                    defaultValue={null}
                    control={control}
                    render={({ ref, ...rest }) => (
                      <TypeAhead
                        options={props.travelCompanyData || []}
                        label="Travel Company"
                        {...rest}
                        isError={errors['piTravelerCompany']}
                      />
                    )}
                  />
                </LayoutGridItem>
                <LayoutGridItem item xs={3}>
                  <LayoutGrid
                    container
                    classes={{ root: classes.root }}
                    direction="column"
                    data-testid="container"
                    justifyContent="flex-start"
                    spacing={2}
                  >
                    <LayoutGridItem item className={classes.checkBoxInput}>
                      <FormInputCheckbox
                        name={`pSupervisor`}
                        isChecked={pSupervisor}
                        handleChecked={() => {
                          setPSupervisor(!pSupervisor);
                        }}
                        checkboxLabel={`Supervisor`}
                        isDisabled={false}
                        checkboxErrorName={`CheckboxError pSupervisor`}
                      ></FormInputCheckbox>
                    </LayoutGridItem>
                    <LayoutGridItem item className={classes.checkBoxInput}>
                      <FormInputCheckbox
                        name={`pHospital`}
                        isChecked={pHospital}
                        handleChecked={() => {
                          setPHospital(!pHospital);
                        }}
                        checkboxLabel={`Teaching Hospital`}
                        isDisabled={false}
                        checkboxErrorName={`CheckboxError pHospital`}
                      ></FormInputCheckbox>
                    </LayoutGridItem>
                  </LayoutGrid>
                </LayoutGridItem>
                <LayoutGridItem item xs={3}>
                  <LayoutGrid
                    container
                    classes={{ root: classes.root }}
                    direction="row"
                    data-testid="container"
                    justifyContent="flex-start"
                    spacing={2}
                  >
                    <LayoutGridItem item className={classes.checkBoxInput}>
                      <FormInputCheckbox
                        name={`pTeletherapy`}
                        isChecked={pTeletherapy}
                        handleChecked={() => {
                          setPTeletherapy(!pTeletherapy);
                        }}
                        checkboxLabel={`Teletherapy`}
                        isDisabled={false}
                        checkboxErrorName={`CheckboxError pTeletherapy`}
                      ></FormInputCheckbox>
                    </LayoutGridItem>
                    <LayoutGridItem item className={classes.checkBoxInput}>
                      <FormInputCheckbox
                        name={`pStaffing`}
                        isChecked={pStaffing}
                        handleChecked={() => {
                          setPStaffing(!pStaffing);
                        }}
                        checkboxLabel={`Local Staffing Agency`}
                        isDisabled={false}
                        checkboxErrorName={`CheckboxError pStaffing`}
                      ></FormInputCheckbox>
                    </LayoutGridItem>
                  </LayoutGrid>
                </LayoutGridItem>
              </LayoutGrid>
            </LayoutGridItem>

            {candidateManageEquipmentProcedure.enabled && (
              <>
                <LayoutGridItem item>
                  <Divider></Divider>
                </LayoutGridItem>

                <LayoutGridItem item>
                  <LayoutGrid
                    container
                    classes={{ root: classes.root }}
                    direction="column"
                    data-testid="container"
                    spacing={2}
                  >
                    <LayoutGridItem item xs={12}>
                      <Typography component="span">{t('candidate.referenceGrid.equipmentProcedures')}</Typography>
                    </LayoutGridItem>
                    <LayoutGridItem item xs={12}>
                      <FormInputText
                        name="pnotes"
                        label={t('candidate.referenceGrid.equipmentProcedures')}
                        handleChange={handlePNotes}
                        inputValue={getValues('pnotes')}
                        message={errors['pnotes']?.message || ''}
                        multiline
                        spellCheck
                        inputLength={1000}
                        charCount={charCount}
                      />
                    </LayoutGridItem>
                  </LayoutGrid>
                </LayoutGridItem>
              </>
            )}
          </>
        ) : (
          <>
            {errorMessageOpen && (
              <LayoutGridItem style={{ height: '100px' }}>
                <LayoutGrid
                  container
                  direction="row"
                  className={classes.root}
                  spacing={2}
                  style={{ backgroundColor: '#FDEDE9', padding: '10px', paddingBottom: '15px' }}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  xs={12}
                >
                  <LayoutGridItem>
                    <LayoutGrid container direction="row" className={classes.root} spacing={2}>
                      <LayoutGridItem>
                        <ErrorOutlineIcon style={{ color: '#F4574A' }} />
                      </LayoutGridItem>
                      <LayoutGridItem>
                        <LayoutGrid container direction="column" className={classes.root} spacing={0}>
                          <LayoutGridItem>
                            <span style={{ color: '#611A16', fontSize: '14px', fontWeight: 400 }}>
                              {
                                'Dates are overlapping with existing work experience. This will cause a delay in submission,'
                              }
                            </span>
                          </LayoutGridItem>
                          <LayoutGridItem>
                            <span style={{ color: '#611A16', fontSize: '14px', fontWeight: 400 }}>
                              {'do you wish to proceed?'}
                            </span>
                          </LayoutGridItem>
                        </LayoutGrid>
                      </LayoutGridItem>
                    </LayoutGrid>
                  </LayoutGridItem>
                  <LayoutGridItem>
                    <LayoutGrid
                      container
                      direction="row"
                      className={classes.root}
                      spacing={2}
                      style={{ paddingRight: '15px' }}
                    >
                      <LayoutGridItem>
                        <span className={classes.buttonStyle} onClick={() => setErrorMessageOpen(false)}>
                          {'No'}
                        </span>
                      </LayoutGridItem>
                      <LayoutGridItem>
                        <span className={classes.buttonStyle} onClick={() => yesAction()}>
                          {'Yes'}
                        </span>
                      </LayoutGridItem>
                    </LayoutGrid>
                  </LayoutGridItem>
                </LayoutGrid>
              </LayoutGridItem>
            )}
            <LayoutGridItem item>
              <LayoutGrid
                container
                classes={{ root: classes.root }}
                direction="row"
                data-testid="container"
                justifyContent="flex-start"
                alignItems={'center'}
                spacing={4}
                xs={12}
              >
                <LayoutGridItem item xs={4}>
                  <FormInputDatePicker
                    name={'pStart'}
                    label={'Start Date *'}
                    handleChange={handleStartDateChange}
                    customClass={classes.datePickerInput}
                    trailingIconVisible={true}
                    inputValue={getValues('pStart')}
                  ></FormInputDatePicker>
                </LayoutGridItem>
                <LayoutGridItem item xs={4}>
                  <FormInputDatePicker
                    name={'pEnd'}
                    label={'End Date'}
                    handleChange={handleEndDateChange}
                    customClass={classes.datePickerInput}
                    trailingIconVisible={true}
                    inputValue={getValues('pEnd')}
                  ></FormInputDatePicker>
                </LayoutGridItem>
                <LayoutGridItem item xs={4}>
                  <FormInputCheckbox
                    name={`pOngoing`}
                    isChecked={pOngoing}
                    handleChecked={() => {
                      setPOngoing(!pOngoing);
                      if (!pOngoing == true) {
                        setValue('pEnd', null);
                        setEndValue(null);
                      }
                    }}
                    checkboxLabel={`Ongoing`}
                    isDisabled={false}
                    checkboxErrorName={`CheckboxError pOngoing`}
                  ></FormInputCheckbox>
                </LayoutGridItem>
                <LayoutGridItem xs={6}>
                  <FormInputText
                    name="pPaidTimeOffReason"
                    label={'Reason for Leaving'}
                    require={false}
                    message={errors['pPaidTimeOffReason']?.message || ''}
                  />
                </LayoutGridItem>
              </LayoutGrid>
            </LayoutGridItem>
          </>
        )}
      </LayoutGrid>
    </>
  );
};
