/* eslint-disable tss-unused-classes/unused-classes */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Grid, Avatar, Divider, Button, Tooltip, Link } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Concatenate } from 'utils/string/string';
import { HeaderContactDrawerOrder } from '../../../Candidate/CandidateProfile/CandidateHeader/HeaderContactDrawer';
import PersonIcon from '@mui/icons-material/Person';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ResponsiveColumnContainer } from 'app/layout/ResposiveColumnContainer';
import { OrderHeaderSection } from 'app/models/Orders/OrderDetails';
import { getSearchLookups } from 'app/services/SharedServices/SharedServices';
import { selectOrderCreationProcessStep, selectOrderDetailsUIView } from 'app/components/Order/Store/Order.selectors';
import { convertToPST } from 'app/helpers/dateHelper';
import { selectUser } from 'oidc/user.selectors';
import { CustomEditable } from 'app/components/Candidate/CandidateProfile/CandidateHeader/Common/CustomEditable';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import _ from 'lodash';
import { IRecentHistoryOrder } from 'app/models/Global/RecentHistoryModels';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { globalSearchActions } from 'store/redux-store/global-search/slice';
import HeaderButton from '@AMIEWEB/Common/Buttons/HeaderButton';
import { PreferenceCosmosKeys } from 'oidc/UserDevicePreference/utils';

const useStyles = makeStyles<void, 'focused'>()((theme, _params, classes) => ({
  container: {
    flexWrap: 'nowrap',
    width: '100%',
  },
  containerSecond: {
    marginTop: '10px',
  },
  title: {
    color: '#52429A',
    fontSize: 16,
    paddingTop: 5,
  },
  divider: {
    margin: '0 12px',
    [theme.breakpoints.between('sm', 'md')]: {
      margin: '0 8px',
    },
  },
  textContent: {
    textAlign: 'left',
    alignSelf: 'column',
    color: '#333333 !important',
    fontSize: '14px !important',
    textTransform: 'uppercase',
    fontWeight: 'initial',
  },
  textContentSecond: {
    textAlign: 'left',
    alignSelf: 'column',
    color: '#555555 !important',
    fontSize: '14px !important',
  },

  avatarName: {
    height: 47,
    width: 18,
    'font-size': '1.1rem',
    fontWeight: 500,
    backgroundColor: '#52429A',
    textTransform: 'uppercase',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  },
  avatarText: {
    height: 47,
    width: 135,
    marginRight: 10,
    fontSize: 20,
    fontWeight: 500,
    backgroundColor: '#38297B',
    textTransform: 'uppercase',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    justifyContent: 'start',
    paddingLeft: '5px',
  },

  customTooltip: {
    backgroundColor: '#ffffff',
    color: '#0D2A3B',
    opacity: 1,
    width: '119px',
    textAlign: 'left',
    font: 'normal normal normal 12px/20px IBM Plex Sans',
    letterSpacing: '0px',
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #8C8C8C',
  },
  customArrow: {
    color: '#ffffff',
    opacity: 1,
    '&::before': {
      boxShadow: '0px 3px 6px #00000029',
      border: '1px solid #8C8C8C',
    },
  },
  containerAvatar: {
    display: 'flex',
    justifyContent: 'start',
  },
  myAvatarAlignment: {
    display: 'flex',
    flexDirection: 'row',
  },
  contactDrawer: {
    marginTop: 10,
  },
  userIcon: {
    width: '95%',
    backgroundColor: '#F8F8F8',
    'padding-left': '0.7rem',
    'padding-right': '0.7rem',
    justifyContent: 'start',
    border: '1px solid #006fb9',
    '&:hover': {
      backgroundColor: '#006FB9',
      color: '#fff',
      border: '1px solid #006fb9',
    },
  },
  interactIcons: {
    paddingTop: '6px',
  },
  orderStatus: {
    backgroundColor: '#34B233',

    fontSize: '14px',
    width: 'max-content',
    padding: '8px',
    height: '27px',
    justifyContent: 'space-between',
    minWidth: '120px',
    maxWidth: '150px',
    [theme.breakpoints.up('lg')]: {
      width: 'inherit !important',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '140px',
      minWidth: '140px',
    },
  },
  statusSecond: {
    fontSize: '14px !important',
    width: '128px !important',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    minWidth: '0px !important',
  },

  brand: {
    color: '#E89120 !important',
    fontWeight: 500,
    paddingLeft: '0px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      whiteSpace: 'break-spaces',
    },
  },

  rowHeadDefaultColor: {
    color: '#333333 !important',
  },
  rowContentDefaultColor: {
    color: '#333333 !important',
  },
  rowHead: {
    fontSize: '14px !important',
    fontWeight: 400,
    [theme.breakpoints.up('lg')]: {
      fontSize: '14px !important',
    },
  },
  rowContent: {
    fontSize: '14px !important',
    fontWeight: 500,
  },
  myRow: {
    flexGrow: 1,
  },
  contactIcons: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    //whiteSpace: 'nowrap',
  },
  alignContent: {
    display: 'table-row',
  },
  alignContentCustom: {
    // flexDirection: 'column',
    padding: '3px 0px 0px 6px !important',
  },
  myTableLayout: {
    display: 'table',
    borderCollapse: 'separate',
  },
  defaultAlign: {
    display: 'table-cell',
    padding: '7px 7px 5px 0px',
  },
  customInDemand: {
    display: 'flex',
    fontSize: '12px',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
  },
  lowRatioLayout: {
    fontSize: '14px !important',
    whiteSpace: 'nowrap',
  },
  smallerDefaultAlign: {
    display: 'table-cell',
    padding: '5px 20px 5px 0px',
  },
  buttonText: {
    paddingTop: '2px',
    'font-size': '0.8rem',
  },
  customFontSize: {
    fontSize: '25px !important',
  },
  tooltip: {
    display: 'grid',
  },
  tooltipArrow: {
    color: '#fff',
    '&::before': {
      border: '1px solid #555555',
    },
  },
  tooltipBackground: {
    backgroundColor: '#fff',
    color: '#555555',
    border: '1px solid #555555',
    fontSize: '12px',
  },
  actionContainer: {
    backgroundColor: '#6699CC70',
    width: 'inherit',
    display: 'flex',
    padding: '7px',
    'padding-right': '0',
    marginRight: 0,
    minHeight: 38,
    justifyContent: 'space-between',
    'border-radius': '0.2rem',
    marginTop: '0',
    [theme.breakpoints.down('md')]: {
      marginTop: '10px !important',
      maxWidth: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px !important',
      maxWidth: '100%',
    },
  },
  rowHeadCustom: {
    color: '#003E68',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    },
  },
  rowContentCustom: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    },
  },
  tooltipWidth: {
    maxWidth: '200px',
  },
  addInfo: {
    color: '#006FB9',
    paddingLeft: '5px',
    textDecoration: 'underline',
  },
  orderStatusText: {
    textOverflow: 'ellipsis',
    display: 'block',
    overflow: 'hidden',
  },
  fallBack: { display: 'none' },
  address: {
    fontWeight: 500,
  },
  commonStyle: {
    display: 'flex',
  },
  commonStyleTable: {
    // display: 'table-caption',
  },
  addressFontWrap: {
    fontSize: '0.8rem !important',
  },
  root: {
    overflow: 'hidden',
    fontSize: 14,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      '&$popupIndicator': {
        opacity: 1,
        transition: 'opacity 0.25s',
      },
    },
    [`&.${classes.focused}`]: {
      backgroundColor: '#fff',
    },
  },
  autocomplete: {
    transition: 'opacity 0.25s',
    '&:hover': {
      '& [data-id="edit-icon"]': {
        opacity: 1,
      },
      transition: 'opacity 0.25s',
    },
  },
  editItem: {
    opacity: 0.75,
    transition: 'opacity 0.15s',
    color: '#8d8686',
    cursor: 'pointer',
    position: 'relative',
    top: '1px',
    '&.enabled': {
      opacity: 1,
    },
    '& svg ': {
      height: '17px',
      width: '17px',
    },
  },
  userRoleColumns: {
    'min-width': '310px',
  },
  focused: {},
  rowHeadCustomInline: {
    position: 'relative',
    '&.regionalDirector': {
      top: '-7px',
    },
  },
  rowContentCustomInline: {
    position: 'relative',
    top: '1px',
    '&.accountManger': {
      top: '-2px',
    },
    '&.regionalDirector': {
      top: '-8px',
    },
  },
  inlineEditMode: {
    cursor: 'default',
    '&.editEnabled': {
      'border-bottom': '1px solid #000',
    },
    '& .MuiAutocomplete-input': {
      cursor: 'default',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#1a1919',
    },
  },
}));

interface headerDetailInterface {
  headerDetails: OrderHeaderSection;
  lastVerifiedUpdateStatus?: boolean;
  handleOrderPropertyUpdate: (property: string, value: any) => void;
}

export interface UserRoleSearchResultItem {
  value: number;
  name: string;
}

export const OrderHeader = (props: headerDetailInterface) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const userData = useSelector(selectUser);
  const { headerDetails, handleOrderPropertyUpdate, lastVerifiedUpdateStatus } = props;
  const [lastVerifiedDate, setLastVerifiedDate] = useState<string>(convertToPST(headerDetails.lastVerified));
  const [lastVerfiredBy, setLastVerfiredBy] = useState<string>(headerDetails.verifiedBy);
  const userInfo = {
    userId: userData.userInfo?.employeeId,
    userName: userData.userInfo?.firstName + ' ' + userData.userInfo?.lastName,
  };
  const [openProfileDrawer, setOpenProfileDrawer] = useState<boolean>(false);
  const [autocompleteListLoadding, setAutocompleteListLoadding] = useState<boolean>(false);
  const [enableAccountmangerEdit, setEnableAccountmangerEdit] = useState<boolean>(false);
  const [enableRegionalDirectorEdit, setEnableRegionalDirectorEdit] = useState<boolean>(false);
  const [accountManagerValue, setAccountManagerValue] = useState<string>(headerDetails.accountManager);
  const [regionalDirectorValue, setRegionalDirectorValue] = useState<string>(headerDetails.regionalDirector);
  const [accountManagerOptions, setAccountManagerOptions] = useState<string[]>([]);
  const [regionalDirectorOptions, setRegionalDirectorOptions] = useState<string[]>([]);
  const [accountManagerSearchList, setAccountManagerSearchList] = useState<UserRoleSearchResultItem[]>([]);
  const [regionalDirectorSearchList, setRegionalDirectorSearchList] = useState<UserRoleSearchResultItem[]>([]);
  const storeOrderCreationProcessStep = useSelector(selectOrderCreationProcessStep);
  const selectOrderUiDetails = useSelector(selectOrderDetailsUIView);

  const avatarText = Concatenate([`OID ${headerDetails.orderID}`]);
  const dispatch = useDispatch();

  const closeProfileDrawer = () => {
    setOpenProfileDrawer(false);
    dispatch(globalActions.setDetailsPageDrawer({ open: false }));
  };

  const enableInlineEditMode = target => {
    if (selectOrderUiDetails && selectOrderUiDetails.currentStep === 'UpdateOrderReqDetails') {
      return false;
    }
    if (target === 'accountManager') {
      fetchData(undefined, undefined);
      setEnableAccountmangerEdit(!enableAccountmangerEdit);
    } else if (target === 'regionalDirector') {
      fetchData(undefined, undefined);
      setEnableRegionalDirectorEdit(!enableRegionalDirectorEdit);
    }
  };

  const handleChangeValue = (property, value) => {
    if (property === 'accountManager') {
      setAccountManagerValue(value);
    } else if (property === 'regionalDirector') {
      setRegionalDirectorValue(value);
    }
  };

  useEffect(() => {
    if (lastVerifiedUpdateStatus) {
      const verifiedDate = convertToPST(new Date());
      // setInProgress(false);
      setLastVerifiedDate(verifiedDate ? verifiedDate : '');
      setLastVerfiredBy(userInfo.userName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastVerifiedUpdateStatus]);

  const fetchData = async (accountManagerValue, regionalDirectorValue) => {
    try {
      setAutocompleteListLoadding(true);
      const accountManagerList = await getSearchLookups('accountmanager', accountManagerValue);
      setAccountManagerOptions(
        accountManagerList.map(item => {
          return item.name;
        }),
      );
      setAccountManagerSearchList(accountManagerList);

      const regionalDirectorList = await getSearchLookups('regionaldirector', regionalDirectorValue);
      setRegionalDirectorOptions(
        regionalDirectorList.map(item => {
          return item.name;
        }),
      );

      setRegionalDirectorSearchList(regionalDirectorList);
    } catch (error) {
      trackException({
        exception: error,
        properties: {
          name: ExceptionType.CommonUIError,
          functionName: 'fetchData',
          area: 'src/app/components/Order/OrderDetails/OrderHeader/OrderHeader.tsx',
        },
      });
    }
    setAutocompleteListLoadding(false);
  };

  useEffect(() => {
    const accountManagerValTemp = accountManagerValue.length <= 0 ? '*' : accountManagerValue;
    const regionalDirectorValTemp = regionalDirectorValue.length <= 0 ? '*' : regionalDirectorValue;
    fetchData(accountManagerValTemp, regionalDirectorValTemp);
  }, [accountManagerValue, regionalDirectorValue]);

  const titleToolTip = (maxContent: number, onRibbonDisplay?: boolean) => {
    const groupedSkillsets = headerDetails?.skillSet?.reduce((skillsetsbyKeyVal, skillset) => {
      const value = skillset['disciplineAbbr'] || '';
      skillsetsbyKeyVal[value] = (skillsetsbyKeyVal[value] || []).concat(skillset);
      return skillsetsbyKeyVal;
    }, {});

    const result: string[] = [];
    for (const key in groupedSkillsets) {
      result?.push(
        `${key}${groupedSkillsets[key]?.map((item, index) => {
          return ' ' + item.specialtyAbbr;
        })}`,
      );
    }

    return (onRibbonDisplay ? result.slice(0, maxContent) : result.slice(maxContent)).map((skillset, index) => {
      return `${skillset}${
        index === (onRibbonDisplay ? result?.slice(0, maxContent) : result?.slice(maxContent)).length - 1 ? '' : ' | '
      }`;
    });
  };

  return (
    <>
      <Grid
        container
        xs={12}
        lg={12}
        md={12}
        direction="row"
        classes={{ container: classes.container }}
        data-testid="table-test-id"
      >
        <Grid item direction="column" classes={{ root: classes.containerAvatar }}>
          <Grid item direction="row" classes={{ root: classes.myAvatarAlignment }}>
            <HeaderButton
              headerAbbr=""
              headerId={`OID ${headerDetails.orderID}`}
              headerAbbrBackground="#52429A"
              headerIdBackground="#38297B"
            />
          </Grid>
          <Grid item classes={{ root: classes.contactDrawer }}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.userIcon}
              startIcon={<PersonIcon classes={{ root: classes.customFontSize }} />}
              onClick={() => {
                dispatch(globalSearchActions.setDrawerData({ open: false, data: undefined }));
                dispatch(globalActions.resetAllRecentHistoryDrawers());
                setOpenProfileDrawer(true);
                dispatch(globalActions.setDetailsPageDrawer({ open: true }));
              }}
            >
              <span className={classes.buttonText} id={'OrderKeyContactsOpenTriggerBtn'}>{`Key Contacts`}</span>
            </Button>
          </Grid>
        </Grid>
        <Grid item direction="column" classes={{ root: classes.container }}>
          <Grid item direction="row" classes={{ root: classes.title }}>
            {!storeOrderCreationProcessStep ||
            !(storeOrderCreationProcessStep && storeOrderCreationProcessStep.isEditStarted) ? (
              <Link href={`/facility/${headerDetails.facilityId}`} id={'OrderFacilityNameLbl'}>
                {headerDetails.facilityName}
              </Link>
            ) : (
              <span>{headerDetails.facilityName}</span>
            )}
          </Grid>
          <Grid item direction="row" classes={{ root: classes.headerInfo }}>
            <Grid item direction="column">
              <table>
                <ResponsiveColumnContainer
                  rowHead={
                    <Grid container>
                      <Grid container xs={12} lg={12} md={12} direction="row" className={classes.addressFontWrap} item>
                        <span id={'OrderFacilityAddressLbl'}>
                          {`${headerDetails.location.addressLine1}`}
                          <br />
                          {`${headerDetails.location.addressLine2}`}
                        </span>
                      </Grid>
                    </Grid>
                  }
                  customRowHeadClass={classes.address}
                  // customAlign={classes.alignContentCustom}
                  headColSpan={1}
                  classes={classes}
                />
              </table>
            </Grid>

            <Divider orientation="vertical" flexItem classes={{ root: classes.divider }} />
            <Grid item direction="column">
              <table className={classes.commonStyleTable}>
                <ResponsiveColumnContainer
                  rowHead={t('order.orderDetails.orderHeader.division')}
                  rowContent={headerDetails.division}
                  classes={classes}
                  customRowHeadClass={classes.rowHeadCustomInline}
                  customRowContentClass={classes.rowContentCustomInline}
                  disableContentWrap
                  id={'OrderDivisionLbl'}
                />
                <ResponsiveColumnContainer
                  rowHead={t('order.orderDetails.orderHeader.type')}
                  rowContent={headerDetails.type}
                  classes={classes}
                  customRowHeadClass={classes.rowHeadCustomInline}
                  customRowContentClass={classes.rowContentCustomInline}
                  disableContentWrap
                  id={'OrderTypeLbl'}
                />
              </table>
              <table className={classes.commonStyleTable}>
                <ResponsiveColumnContainer
                  rowHead={t('order.orderDetails.orderHeader.status')}
                  rowContent={
                    <Avatar variant="rounded" className={classes.orderStatus}>
                      {
                        <>
                          <span className={classes.orderStatusText}>{headerDetails.status}</span>{' '}
                          <span>
                            <ExpandMoreIcon />
                          </span>
                        </>
                      }
                    </Avatar>
                  }
                  classes={classes}
                  exact={'sm'}
                />
              </table>
            </Grid>

            <Divider orientation="vertical" flexItem classes={{ root: classes.divider }} />

            <Grid item direction="column" style={{ width: 'min-content' }}>
              <table className={`${classes.commonStyleTable} ${classes.userRoleColumns}`}>
                <ResponsiveColumnContainer
                  rowHead={t('order.orderDetails.orderHeader.accountManager')}
                  rowContent={
                    <CustomEditable
                      candidateDetails={selectOrderUiDetails && !selectOrderUiDetails.creditStatus}
                      fieldValue={accountManagerValue}
                      enableEdit={enableAccountmangerEdit}
                      fieldName={'accountManager'}
                      editableType={'dropDown'}
                      enableInlineEditMode={enableInlineEditMode}
                      handleChangeValue={handleChangeValue}
                      handleOrderPropertyUpdate={handleOrderPropertyUpdate}
                      recuiterOptions={accountManagerOptions}
                      autocompleteListLoadding={autocompleteListLoadding}
                      recuiterSearchList={accountManagerSearchList}
                      isRecruiter={true}
                    />
                  }
                  classes={classes}
                  customRowHeadClass={`${classes.rowHeadCustomInline} accountManger`}
                  customRowContentClass={`${classes.rowContentCustomInline} accountManger`}
                />
                <ResponsiveColumnContainer
                  rowHead={t('order.orderDetails.orderHeader.regionalDirector')}
                  rowContent={
                    <CustomEditable
                      candidateDetails={selectOrderUiDetails && !selectOrderUiDetails.creditStatus}
                      fieldValue={regionalDirectorValue}
                      enableEdit={enableRegionalDirectorEdit}
                      fieldName={'regionalDirector'}
                      editableType={'dropDown'}
                      enableInlineEditMode={enableInlineEditMode}
                      handleChangeValue={handleChangeValue}
                      handleOrderPropertyUpdate={handleOrderPropertyUpdate}
                      recuiterOptions={regionalDirectorOptions}
                      autocompleteListLoadding={autocompleteListLoadding}
                      recuiterSearchList={regionalDirectorSearchList}
                      isRecruiter={true}
                    />
                  }
                  classes={classes}
                  customRowHeadClass={`${classes.rowHeadCustomInline} regionalDirector`}
                  customRowContentClass={`${classes.rowContentCustomInline} regionalDirector`}
                />
              </table>
            </Grid>

            <Divider orientation="vertical" flexItem classes={{ root: classes.divider }} />

            <Grid item direction="column" style={{ width: 'fit-content' }}>
              <table className={classes.commonStyleTable}>
                <ResponsiveColumnContainer
                  rowHead={t('order.orderDetails.orderHeader.status')}
                  rowContent={
                    <Avatar variant="rounded" className={classes.orderStatus}>
                      {
                        <>
                          <span id={'OrderStatusLbl'} className={classes.orderStatusText}>
                            {'N/A'}
                          </span>{' '}
                          <span>
                            <ExpandMoreIcon />
                          </span>
                        </>
                      }
                    </Avatar>
                  }
                  classes={classes}
                  minWidth={'md'}
                />
              </table>
            </Grid>
            <Divider orientation="vertical" flexItem classes={{ root: classes.divider }} />
            <Grid item direction="column" style={{ width: 'fit-content' }}>
              <table className={classes.commonStyleTable}>
                <ResponsiveColumnContainer
                  rowHead={t('order.orderDetails.orderHeader.lastVerifiedDate')}
                  rowContent={lastVerifiedDate}
                  classes={classes}
                  customRowHeadClass={classes.rowHeadCustomInline}
                  customRowContentClass={classes.rowContentCustomInline}
                  disableContentWrap
                  id={'OrderlastVerifiedLbl'}
                />
                <ResponsiveColumnContainer
                  rowHead={t('order.orderDetails.orderHeader.lastVerifiedBy')}
                  rowContent={lastVerfiredBy}
                  classes={classes}
                  customRowHeadClass={classes.rowHeadCustomInline}
                  customRowContentClass={classes.rowContentCustomInline}
                  disableContentWrap
                  id={'OrderlastVerifiedLbl'}
                />
              </table>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <HeaderContactDrawerOrder
        closeProfileDrawer={closeProfileDrawer}
        openProfileDrawer={openProfileDrawer}
        avatarText={avatarText}
        candidateName={headerDetails.facilityName}
        orderDetails={headerDetails}
      />
      <Grid
        container
        xs={12}
        lg={12}
        md={12}
        direction="row"
        classes={{ container: `${classes.container} ${classes.containerSecond}` }}
      >
        <Grid item container xs={12} lg={12} md={12} direction="row" classes={{ root: classes.actionContainer }}>
          <Grid item style={{ display: 'flex' }}>
            <Grid item style={{ display: 'flex' }}>
              <table style={{ display: 'table-caption' }}>
                <ResponsiveColumnContainer
                  rowHead={t('order.orderDetails.orderHeader.unit')}
                  rowContent={headerDetails.unit}
                  classes={classes}
                  customRowHeadClass={classes.rowHeadCustom}
                  customRowContentClass={`${classes.rowContentCustom} ${classes.rowContentDefaultColor}`}
                  id={'OrderUnitLbl'}
                />
              </table>
            </Grid>
          </Grid>

          <Grid item style={{ display: 'flex' }}>
            <table style={{ display: 'table-caption' }}>
              <ResponsiveColumnContainer
                rowHead={t('candidate.candidateHeader.skillset')}
                rowContent={
                  <span style={{ display: 'flex' }}>
                    <span>{titleToolTip(4, true)}</span>
                    <span className={classes.addInfo}>
                      {
                        <Tooltip
                          title={titleToolTip(4)}
                          arrow
                          placement="bottom"
                          classes={{
                            tooltip: `${classes.tooltipBackground} ${classes.tooltipWidth}`,
                            arrow: classes.tooltipArrow,
                          }}
                        >
                          <div id={'OrderSkillSetLbl'}>
                            {headerDetails?.skillSet?.slice(4).length > 0
                              ? ` +${headerDetails?.skillSet?.slice(4).length}`
                              : ''}
                          </div>
                        </Tooltip>
                      }
                    </span>
                  </span>
                }
                classes={classes}
                customRowHeadClass={classes.rowHeadCustom}
                customRowContentClass={`${classes.rowContentCustom} ${classes.rowContentDefaultColor}`}
              />
            </table>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export const RowContainer = props => (
  <table style={{ width: '100%' }}>
    <tbody style={{ width: '100%' }}>{props.children}</tbody>
  </table>
);
