import { formatPhoneNumber } from 'app/components/Notification/MultiChannel/BulkSms/MessageHelpers';
import { missingField } from 'app/constants';
import { convertToFormat } from 'app/helpers/dateHelper';
import { CredentialingPlacement } from 'app/models/Credentialing/CredentialingPlacement';
import moment from 'moment';
import Call from 'app/assets/images/WorkDesk/Call.png';
import email from 'app/assets/images/WorkDesk/email.png';

//
// ─── GENERATE THE FLATTEN DATA FOR GRID ─────────────────────────────────────────
//

const getWorkEntitiesCommunicationAttributes = item => {
  return [
    { key: Call, value: item?.phoneNumber },
    { key: email, value: item?.email },
  ];
};
export const PlacementStatusFlattenData = (data): CredentialingPlacement[] => {
  const Data: CredentialingPlacement[] = [];
  data.map((item, id) => {
    const checkData: CredentialingPlacement = {
      id: `${item.candidateId}-${id}`,
      candidateId: parseInt(item.candidateId),
      candidateName: `${item.candidateName.firstName ?? ''} ${item.candidateName.lastName ?? ''}`,
      candidatePhone: (item.candidateName.phoneNumber) || missingField,
      candidateEmail: item.candidateName.email || missingField,
      accountManager: getWorkEntitiesCommunicationAttributes(item.accountManager),
      accountManagerName: `${item.accountManager?.firstName ?? missingField} ${
        item.accountManager?.lastName ?? missingField
      }`,
      clinicalManager: getWorkEntitiesCommunicationAttributes(item.clinicalManager),
      clinicaliManagerName: `${item.clinicalManager?.firstName ?? missingField} ${
        item.clinicalManager?.lastName ?? missingField
      }`,
      recruiter: getWorkEntitiesCommunicationAttributes(item.recruiter),
      recruiterId: item.recruiter?.employeeId,
      recruiterName: `${item.recruiter?.firstName ?? missingField} ${item.recruiter?.lastName ?? missingField}`,
      credentialingAnalyst: getWorkEntitiesCommunicationAttributes(item?.credentialingAnalyst),
      credentialingAnalystName: `${item?.credentialingAnalyst?.firstName ?? missingField} ${
        item?.credentialingAnalyst?.lastName ?? missingField
      }`,
      lastContactedOn: convertToFormat(item?.lastContactedOn, 'MM/DD/YYYY') || missingField,
      unit: item?.unit || missingField,
      discipline: `${item.discipline}/${item.specialty}`,
      placementId: parseInt(item.placementId),
      placementStatus: item.placementStatus,
      startDate: convertToFormat(item.startDate, 'MM/DD/YYYY') || missingField,
      facilityId: item.facilityId,
      facilityName: item.facilityName,
      facilityNameLower: item.facilityName?.toLowerCase(),
      brandId: item.brandId,
      clinicianLastName: `${item.candidateName.lastName?.toLowerCase() ?? ''}`,
      startDateNoFormat: item.startDate || '',
      endDate: convertToFormat(item.endDate, 'MM/DD/YYYY') || missingField,
      skillSet: item.specialty || missingField,
      expiringCredentialsCount: item.expiringCredentialsCount,
      statusAbbr: item.statusAbbr,
      previewDrawerViewerId: parseInt(item.candidateId),
    };
    if (!Data.includes(checkData)) {
      Data.push(checkData);
    }
  });
  return Data.sort(dateSort);
};

//
// ─── COMMON FUNCTIONS ───────────────────────────────────────────────────────────
//
const dateSort = (a: any, b: any) => (moment.utc(a.startDate) as any) - (moment.utc(b.startDate) as any);
