/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui';

export const useCovidCardStyes = makeStyles()(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  boxInlineHeadLabelTxt: {
    'font-weight': '600',
    'margin-top': '0.3rem',
  },
  addLink: {
    paddingTop: 6,
    paddingBottom: 0,
  },
  tooltipRowHeader: {
    color: '#333333',
    fontSize: 13,
  },
  tooltipRowContent: {
    color: '#555555',
    fontSize: 13,
  },
}));
