import React from 'react';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';

export const SupervisorDeleteDialog = props => {
    const { openDeleteDialog, onDeleteDialogClose, deleteSkillSets } = props;
    return (
        <GenericDialog
            open={openDeleteDialog}
            onClose={(event, reason) => {
                if (reason === 'backdropClick') {
                    event.preventDefault();
                    event.stopProgagation();
                    return;
                }
            }}
            disableEscapeKeyDown
            maxWidth="sm"
            fullWidth
            dialogTitleProps={{
                style: { paddingTop: '20px' },
                text: 'Delete Skill Set?',
                closeButton: false,
            }}
            dialogActions={[
                {
                    text: 'Cancel',
                    variant: 'contained',
                    onClick: () => onDeleteDialogClose(),
                },
                {
                    text: 'Yes',
                    variant: 'contained',
                    color: 'primary',
                    onClick: () => deleteSkillSets(),
                },
            ]}
        >
            <span>
                <span style={{ fontWeight: 500 }}>{`Are you sure you want to delete the selected skill set(s) from the list?`}</span>
            </span>
        </GenericDialog>
    );
};
