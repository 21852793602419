export const formatOrderRequirements = (unit, isRequired: Boolean) => {
  const NewLine = '\r\n';
  let qualification: string = '';

  if (unit?.experience?.skillSets?.some(x => x.isRequired === isRequired)) {
    qualification += 'Experience: ';
    qualification += unit?.experience?.skillSets
      .filter(x => x?.isRequired === isRequired)
      .map(
        x =>
          `${x?.discipline}, ${x?.specialty}${
            x?.subSpecialtyId && x?.subSpecialtyId > 0 ? `, ${x?.subSpecialty}` : ''
          }${x?.yearsOfExperience && x?.yearsOfExperience > 0 ? `, ${x?.yearsOfExperience} Years` : ''}`,
      )
      .join('; ');

    qualification += unit?.experience?.isNewGrad ? `, New Grad${NewLine}` : NewLine;
  }

  if (unit?.travelExperience?.isRequired === isRequired) {
    qualification += 'Travel Experience: ';
    qualification +=
      unit?.travelExperience?.yearsOfExperience > 0 ? `${unit?.travelExperience?.yearsOfExperience} Years` : '';
    qualification +=
      unit?.travelExperience?.numberOfAssignments > 0
        ? `${unit?.travelExperience?.numberOfAssignments} Assignments`
        : '';
    qualification += unit?.travelExperience?.inSpecialty ? ', In Specialty' : '';
    qualification += unit?.travelExperience?.general ? ', General' : '';
    qualification += NewLine;
  }

  if (unit?.qualification != null) {
    if (isRequired) {
      const licenseState = [];
      licenseState?.push(
        unit?.qualification?.activeLicense && unit?.facilityState ? `Active ${unit?.facilityState} License,  ` : '',
      );
      licenseState.push(unit?.qualification?.activeLicense && !unit?.facilityState ? 'Active License,  ' : '');
      licenseState.push(unit.qualification?.compactLicense ? 'Compact License, ' : '');
      licenseState.push(unit.qualification?.activeLicense || unit.qualification?.compactLicense ? NewLine : '');
      qualification += licenseState?.join('').replace(/,$/, '');
    }

    const hasCertifications = unit?.qualification?.certifications?.some(x => x?.isRequired === isRequired);
    qualification += hasCertifications ? 'Certifications: ' : '';

    const filteredCert = unit?.qualification?.certifications?.filter(x => x?.isRequired === isRequired);
    if (filteredCert) {
      qualification += filteredCert
        .map(cert => `${cert?.abbreviation}${cert?.documentAttachmentRequired ? ' (copy needed)' : ''}`)
        .join('; ');
    }
    qualification += hasCertifications ? NewLine : '';

    const hasLicenses = unit?.qualification?.licenses?.some(x => x?.isRequired === isRequired);
    qualification += hasLicenses ? 'Licenses: ' : '';
    const filteredLicenses = unit?.qualification?.licenses?.filter(x => x?.isRequired === isRequired);
    if (filteredLicenses) {
      qualification += filteredLicenses
        .map(
          license =>
            `${license?.licenseAbbreviation}${license?.documentAttachmentRequired ? ' (copy needed)' : ''}`,
        )
        .join('; ');
    }
    qualification += hasLicenses ? NewLine : '';

    const hasChecklists = unit?.qualification?.skillChecklists?.some(x => x?.isRequired === isRequired);
    qualification += hasChecklists ? 'Skills: ' : '';

    const filteredSkills = unit?.qualification?.skillChecklists?.filter(x => x?.isRequired === isRequired);
    if (filteredSkills) {
      qualification += filteredSkills
        .map(skill => `${skill?.text}${skill?.yearsOfExperience > 0 ? `, ${skill?.yearsOfExperience} Years` : ''}`)
        .join('; ');
    }
    qualification += hasChecklists ? NewLine : '';

    if (unit.qualification.emr?.isRequired === isRequired && unit?.qualification?.emr?.values?.length) {
      qualification += 'EMR: ';
      qualification += unit?.qualification?.emr?.values.join('; ');
      qualification += NewLine;
    }
  }

  const filteredNSR = unit?.nonStandardRequirement?.filter(x => x?.isRequired === isRequired);
  if (filteredNSR) {
    filteredNSR.forEach(nsr => {
      qualification += `${nsr?.category}: ${nsr?.value}${
        nsr?.yearsOfExperience ? `, ${nsr?.yearsOfExperience} Years` : ''
      }${nsr?.documentAttachmentRequired ? ' (copy needed)' : ''}`;
      qualification += NewLine;
    });
  }
  return qualification;
};
