export function checkUserRolesForAccess(log, user): boolean {

    const isRecruitment = log?.userRoles.some(item => item.toLowerCase().includes("recruitment"));
    const isClinical = log?.userRoles.some(item => item.toLowerCase().includes("clinical"));

    if (isRecruitment) {
        return user?.userInfo?.roles?.some(item => item.toLowerCase().includes("recruitment"));
    }
    if (isClinical) {
        return user?.userInfo?.roles?.some(item => item.toLowerCase().includes("clinical"));
    }
    if (!isRecruitment && !isClinical) {
        return true;
    }
    return false;
};
