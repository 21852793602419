import { GridTag, gridStateActions } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import { selectFacility } from 'app/components/Facility/FacilityStore/Facility.selector';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ff_task_ui_createclientcontact } from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { usePromiseTracker } from 'react-promise-tracker';
import { XGrid, calculateComponentRemainingHeight } from '@AMIEWEB/Common';
import { materialUiXGrid, useTheme } from 'amn-ui-core';
import { selectGridSelection } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.selector';
import { GridCellParams, GridColumnHeaderParams, GridColumns, GridSortModel } from '@mui/x-data-grid-pro';
import { getHomePageSupportComponentHeight } from 'app/layout/pages/HomePage';
import { useWindowResize } from 'utils/customHooks/useWindowResize';
import { contactColumns } from 'app/components/Unit/ClientContact/ClientContactGridUtils';
import {
  selectClientContractDrawer,
  selectContactsGridData,
  selectContactsLoading,
  selectContactsSourceData,
} from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.selector';
import { CreateClientContactModal } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/CreateClientContact/CreateClientContact';
import { clientContactActions } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/CreateClientContact/store/ClientContact.redux';
import { FacilityContactsActions } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.redux';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { trackEvent } from 'app-insights/appInsightsTracking';
import ClientContactDrawer from './ClientContactDrawer';
import { StatusDropDownValues } from '@AMIEWEB/Common/StatusDropDown/StatusDropDown';
import { getAbbreviation } from './helper';
import { selectClientContactDetails } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/CreateClientContact/store/ClientContact.selector';

export const UnitClientContactGrid = ({
  gridTitle = '',
  handleChange,
  handleAllSelection,
}: {
  gridTitle?: string;
  handleChange?: (e) => void;
  handleAllSelection?: (e) => void;
}) => {
  const facilitySelector = useSelector(selectFacility);
  const clientContractDrawer = useSelector(selectClientContractDrawer);
  const contactsFlattenedData = useSelector(selectContactsGridData);
  const contactsGridSourceData = useSelector(selectContactsSourceData);
  const selectedClientContactDetails = useSelector(selectClientContactDetails);
  const { selectedData } = useSelector(selectGridSelection);
  const contactsLoading = useSelector(selectContactsLoading);
  const [createClientContactFlag] = useDecision(ff_task_ui_createclientcontact, { autoUpdate: true });
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [createContactModalOpen, setCreateContactModalOpen] = useState(false);
  const createClientContactDecision = createClientContactFlag?.enabled;
  const { promiseInProgress: isContactGridLoading } = usePromiseTracker({ area: 'get-fau-client-contacts', delay: 0 });
  const [gridHeight, setGridHeight] = useState<number | undefined>(50);
  const apiRef = materialUiXGrid.useGridApiRef();
  const [sortModel, setSortModel] = useState<any>([{ field: 'firstName', sort: 'desc' }]);
  const defaultSortModel: any[] = [{ field: 'firstName', sort: 'desc' }];

  const updateGridHeight = () =>
    setGridHeight(calculateComponentRemainingHeight('home-page-paper', getHomePageSupportComponentHeight, 12));
  useWindowResize(updateGridHeight, updateGridHeight);

  const onStarIconClick = contactId => {
    dispatch(
      FacilityContactsActions.updateStarIconForContactsGridForUnitEdit({
        ...contactId,
        isNewUnitEdit: Boolean(handleChange),
      }),
    );
  };

  useEffect(() => {
    if (!contactsLoading) {
      const selectedDataSet = new Set(selectedData);
      const updatedFilteredClientContacts = contactsFlattenedData?.map((item, index) => {
        const isSelected = selectedDataSet.has(index);
        return {
          ...item,
          selected: isSelected,
          isPrimary: item.isPrimary && isSelected,
        };
      });
      const selectedContactIds = updatedFilteredClientContacts?.filter(item => item.selected);
      const updatedClientContacts = contactsGridSourceData?.map(item => {
        const isSelected = selectedContactIds.some(selected => selected.contactId === item.contactId);
        return {
          ...item,
          selected: isSelected,
          isPrimary: item.isPrimary && isSelected,
        };
      });
      dispatch(FacilityContactsActions.setFlattenedData(updatedClientContacts));
      dispatch(FacilityContactsActions.setFilteredData(updatedFilteredClientContacts));
    }
  }, [selectedData]);

  useEffect(() => {
    if (selectedClientContactDetails?.contactId) {
      setCreateContactModalOpen(true);
    }
  }, [selectedClientContactDetails?.contactId]);

  const onFirstNameClick = (e, params: any) => {
    dispatch(
      facilityActions.setDrawerData({ open: true, data: { displayValue: params.row.firstName, ...params.row } }),
    );
    trackEvent({
      type: 'click',
      name: 'Facility Details Side Panel',
      properties: { panel: 'Client Contact' },
    });
  };

  const [columns, setColumns] = useState<GridColumns>(
    contactColumns(theme, t, onStarIconClick, onFirstNameClick) as GridColumns,
  );

  /**
   * Method to set units grid data
   */
  const rows = React.useMemo(() => {
    const uniqueRows = (contactsFlattenedData || []).map((row, index) => ({ ...row, id: index }));
    return uniqueRows;
  }, [contactsFlattenedData]);

  useEffect(() => {
    dispatch(
      gridStateActions.setGridState({
        gridTag: GridTag.UnitSelectClientContactsGrid,
        pageSize: rows?.length,
        pageNumber: 1,
        previewItemId: -1,
        showPreviewDialog: false,
        sortedColumn: null,
        filterAttributes: undefined,
      }),
    );
    return () => {
      dispatch(gridStateActions.reset());
    };
  }, [rows?.length]);

  /**
   * Method to trigger on sort model change
   * @param page
   */
  const onSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
  };

  const handleCellClick = React.useCallback(
    (params: GridCellParams) => {
      if (Boolean(handleChange && (params.field === 'starIcon' || params.field === '__check__'))) {
        handleChange({
          isPrimary: params.field === 'starIcon' ? !params.row.isPrimary : params.row.isPrimary,
          clientContactId: params.row.contactId,
          selected:
            params.field === 'starIcon' && !params.row.isPrimary && !params.row.selected
              ? true
              : params.field === '__check__'
              ? !params.row.selected
              : params.row.selected,
        });
      }
      if (params.field === 'status') {
        dispatch(
          FacilityContactsActions.setFacilityClientContactDrawer({
            open: true,
            notes: params?.row?.notes,
            status: StatusDropDownValues.ACTIVE,
            contactId: params?.row?.contactId,
            name: params.row.name,
            nameAbbreviation: getAbbreviation(params.row.firstName, params.row.lastName),
            role: params.row.title,
          }),
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [handleChange],
  );
  const handleHeaderClick = React.useCallback(
    (params: GridColumnHeaderParams) => {
      if (Boolean(handleAllSelection && params.field === '__check__')) {
        if (selectedData?.length) {
          handleAllSelection([]);
        } else {
          const selectedRows = rows.map(({ contactId, isPrimary }) => ({ clientContactId: contactId, isPrimary }));
          handleAllSelection(selectedRows);
        }
      }
    },
    [handleAllSelection],
  );

  return (
    <>
      <XGrid
        gridTag={GridTag.UnitSelectClientContactsGrid}
        apiRef={apiRef}
        loading={isContactGridLoading}
        columns={columns}
        rows={rows}
        autoHeight={true}
        checkboxSelection
        selectionModel={selectedData}
        disableSelectionOnClick
        disablePaggination
        height={gridHeight}
        sortModel={sortModel}
        defaultSortModel={defaultSortModel}
        defaultRowsPerPage={rows?.length || 0}
        title={gridTitle}
        onSortModelChange={onSortModelChange}
        onCellClick={handleCellClick}
        onColumnHeaderClick={handleHeaderClick}
        hideFooter={true}
        toolbarProps={{
          refreshButton: true,
          resetButton: true,
          refreshGrid: () => {
            dispatch(
              clientContactActions.getFacilityAndUnitClientContacts({
                facilityId: facilitySelector.facilityResponse?.facilityId,
              }),
            );
          },
          resetColumns: () => setColumns(contactColumns(theme, t, onStarIconClick, onFirstNameClick) as GridColumns),
        }}
      />
      {createClientContactDecision && createContactModalOpen && (
        <CreateClientContactModal
          open={createContactModalOpen}
          facilityDetails={facilitySelector?.facilityResponse!}
          handleClose={() => {
            setCreateContactModalOpen(false);
          }}
        />
      )}
      {clientContractDrawer?.open && <ClientContactDrawer />}
    </>
  );
};
