import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import baseAxiosInstance from 'utils/BaseApi';

const facilityBase = globalThis?.app?.env?.REACT_APP_FACILITIES_SERVICE_BASE_URL;

export async function saveClientContactsData(body) {
  try {
    const response = await baseAxiosInstance.post(`${facilityBase}/ClientContacts/clientcontact/create`, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'saveClientContactsData',
        area: 'src/app/components/Facility/FacilityDetails/FacilityTabs/CreateClientContact/services/ClientContact.services.ts',
      },
    });
    return error;
  }
}

export async function updateClientContacts(body) {
  try {
    const response = await baseAxiosInstance.post(`${facilityBase}/ClientContacts/clientcontact/modal/update`, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateClientContacts',
        area: 'src/app/components/Facility/FacilityDetails/FacilityTabs/CreateClientContact/services/ClientContact.services.ts',
      },
    });
    return error;
  }
}

export async function getClientContactsByFacilityId(body) {
  try {
    const response = await baseAxiosInstance.post(`${facilityBase}/clientcontacts/facility/clientcontacts`, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getClientContactsByFacilityId',
        area: 'src/app/components/Facility/FacilityDetails/FacilityTabs/CreateClientContact/services/ClientContact.services.ts',
      },
    });
    return error;
  }
}

export async function getFacilityClientContactById(id: number) {
  try {
    const response = await baseAxiosInstance.get(`${facilityBase}/clientcontacts/clientcontact/${id}`);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getFacilityClientContacts',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return error;
  }
}

export async function deleteClientContactById(id: number) {
  try {
    const response = await baseAxiosInstance.delete(`${facilityBase}/clientcontacts/clientcontact/${id}`);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'deleteClientContactById',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return error;
  }
}

export async function fetchActionsList() {
  try {
    const response = await baseAxiosInstance.get(`${facilityBase}/ClientContacts/actionlist`);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'fetchActionsList',
        area: 'src/app/components/Facility/FacilityDetails/FacilityTabs/CreateClientContact/services/ClientContact.services.ts',
      },
    });
    return error;
  }
}

export async function getFacilityUnitsData(facilityId) {
  try {
    const response = await baseAxiosInstance.get(`${facilityBase}/units/facility/${facilityId}`);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'saveTaskMetaData',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return error;
  }
}
