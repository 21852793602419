import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GridTag, gridStateActions } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { materialUiXGrid } from 'amn-ui-core';
import { useDispatch, useSelector } from 'react-redux';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { XGrid } from '@AMIEWEB/Common';
import { DEFAULT_PAGE_SIZE } from '@AMIEWEB/GlobalSearch/SearchResultsPage';
import { selectFilters, selectGridState } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';
import { orderUnitColumnsConfig } from '../UnitTab/Grid/utils';
import { FacilityUnitActions } from '../UnitTab/Store/FacilityUnit.redux';
import { selectOrdersForUnit } from '../UnitTab/Store/FacilityUnit.selector';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import { setOrderUnitsPayload } from './helper';

const UnitOrderTab = () => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string; unitId: string }>();
  const apiRef = materialUiXGrid.useGridApiRef();
  const gridState = useSelector(selectGridState);
  const ordersForUnit = useSelector(selectOrdersForUnit);
  const { promiseInProgress: isOrderAPILoading } = usePromiseTracker({
    area: 'get-orders-for-unit',
    delay: 0,
  });
  const { t } = useTranslation();
  const [orderUnitColumns, setOrderUnitColumns] = useState<any>(orderUnitColumnsConfig());
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState<number>(250);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const defaultSortModel: any[] = [{ field: 'orderId', sort: 'desc' }];
  const appliedFilterValues = useSelector(selectFilters);
  const [sortModel, setSortModel] = React.useState<any>([{ field: 'orderId', sort: 'desc' }]);
  const paging = ordersForUnit?.paging;

  /**
   * Method to trigger on page change
   * @param page
   */
  const onPageChange = (page: number) => {
    setPageNumber(page);
    dispatch(gridStateActions.setPageNumber(page));
    const request = {
      unitId: Number(params.unitId),
      pageSize: pageSize,
      pageNumber: page + 1,
    };
    dispatch(FacilityUnitActions.getOrdersByUnitId(setOrderUnitsPayload(request, appliedFilterValues?.filters)));
  };

  /**
   * Method to trigger on page size change
   * @param page
   */
  const onPageSizeChange = (offset: number) => {
    setPageSize(offset);
    dispatch(gridStateActions.setPageSize(offset));
    const request = {
      unitId: Number(params.unitId),
      pageSize: offset,
      pageNumber: pageNumber,
    };
    dispatch(FacilityUnitActions.getOrdersByUnitId(setOrderUnitsPayload(request, appliedFilterValues?.filters)));
  };

  /**
   * Method to trigger on sort model change
   * @param page
   */
  const onSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
  };

  useEffect(() => {
    if (Number(params.unitId)) {
      const request = {
        unitId: Number(params.unitId),
        pageSize: pageSize,
        pageNumber: pageNumber,
      };
      dispatch(gridStateActions.setPageNumber(1));
      dispatch(gridStateActions.setPageSize(250));
      dispatch(FacilityUnitActions.getOrdersByUnitId(setOrderUnitsPayload(request, appliedFilterValues?.filters)));
    }
  }, []);

  useEffect(() => {
    setRows(ordersForUnit?.items ?? []);
  }, [ordersForUnit]);

  return (
    <XGrid
      gridTag={GridTag.FacilityUnitsGrid}
      apiRef={apiRef}
      loading={isOrderAPILoading}
      columns={orderUnitColumns}
      rows={rows}
      page={paging?.pageNumber && paging?.pageNumber - 1}
      pageSize={pageSize}
      autoHeight={false}
      rowsPerPageOptions={[10, 25, 50, 100, 250]}
      defaultRowsPerPage={gridState.pageSize || DEFAULT_PAGE_SIZE}
      disableSelectionOnClick
      secondaryTitle={t('facility.units.unitDetails.tabs.ordersTab.positions', {
        numberOfPositions: paging?.positions ?? 0,
      })}
      rowCount={paging?.total ?? 0}
      rowCountDisplay={paging?.total}
      sortModel={sortModel}
      defaultSortModel={defaultSortModel}
      title={t('facility.units.unitDetails.tabs.ordersTab.title')}
      pagination={true}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onSortModelChange={onSortModelChange}
      paginationMode="server"
      toolbarProps={{
        refreshButton: true,
        resetButton: true,
        refreshGrid: () => {
          const request = {
            unitId: Number(params.unitId),
            pageSize: pageSize,
            pageNumber: pageNumber,
          };
          dispatch(FacilityUnitActions.getOrdersByUnitId(setOrderUnitsPayload(request, appliedFilterValues?.filters)));
          setSortModel(defaultSortModel);
        },
        resetColumns: () => setOrderUnitColumns(orderUnitColumnsConfig()),
      }}
    />
  );
};

export default UnitOrderTab;
