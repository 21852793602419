/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { FormControl, Grid, InputLabel, ThemeProvider, StyledEngineProvider,useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { reqPref } from 'app/enums/Unit';
import { TravelExperienceType } from 'app/models/Unit/CreateUnit';
import { ReqPrefSection } from '../Common/ReqPrefSection';
import { SimpleCheckbox } from '../Common/SimpleCheckbox';
import { SimpleDropdown } from '../Common/SimpleDropdown';
import { InstructionText, UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { useTranslation } from 'react-i18next';
import { ToggleNoSelection, IToggleProperty } from '../ToggleNoSelection';
import { useSelector } from 'react-redux';
import { selectOrderCreationProcessStep } from 'app/components/Order/Store/Order.selectors';

interface ITravelExperience {
  onUnitPropChange: (property: string, value: any) => void;
  disableEdit?: boolean;
  hasRadio?: boolean;
  travelExperienceData?: any | null;
}

const useStyles = makeStyles()(theme => ({
  orLabel: {
    maxWidth: 38,
    textAlign: 'center',
  },
  reqPrefSection: {
    paddingTop: '12px !important',
    paddingBottom: '12px !important',
  },
}));

export const TravelExperience = (props: ITravelExperience) => {
  const { classes } = useStyles();
  const { onUnitPropChange, disableEdit, travelExperienceData } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const dropDownData = {
    defaultText: 'Select',
    data: ['Select', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
  };

  const [yearsError, setYearsError] = React.useState({
    assignment: false,
    experience: false,
  });

  const [isRequired, setIsRequired] = React.useState(
    travelExperienceData ? (travelExperienceData?.isRequired ? true : false) : true,
  );
  const [showPanel, setShowPanel] = React.useState<boolean>();
  const [yesNoOptionSelected, setYesNoOptionSelected] = React.useState<boolean>(
    travelExperienceData?.numberOfAssignments || travelExperienceData?.yearsOfExperience ? true : false,
  );
  const [assignments, setAssignments] = React.useState(dropDownData.data[0]);
  const [years, setYears] = React.useState(dropDownData.data[0]);
  const storeOrderCreationProcessStep = useSelector(selectOrderCreationProcessStep);

  const initialData = travelExperienceData
    ? travelExperienceData
    : {
        isRequired: true,
        inSpecialty: false,
        general: false,
      };
  const [travelExperience, setTravelExperience] = React.useState<TravelExperienceType>(initialData);

  useEffect(() => {
    if (travelExperienceData) {
      setTravelExperience(travelExperienceData);
      setYears(travelExperienceData?.yearsOfExperience);
      setAssignments(travelExperienceData?.numberOfAssignments);
      // setShowPanel(true);
    }
    if (
      (!disableEdit || storeOrderCreationProcessStep) &&
      (travelExperienceData?.numberOfAssignments || travelExperience.yearsOfExperience)
    ) {
      setShowPanel(true);
    } else {
      setShowPanel(false);
    }
  }, []);

  const toggleData: IToggleProperty = {
    showPanel,
    setShowPanel,
    onUnitPropChange,
    propertyName: 'travelExperience',
    propertyValue: travelExperience,
  };
  const toggleNoSelection = ToggleNoSelection(toggleData);

  const handleRPChange = event => {
    const v = event.target.value === reqPref.req;
    setIsRequired(v);
    setTravelExperience({ ...travelExperience, isRequired: v });
  };

  const handleAssignmentChange = event => {
    setAssignments(event.target.value);
    setYears(dropDownData.data[0]);

    setYearsError({ ...yearsError, assignment: true });

    setTravelExperience({
      ...travelExperience,
      numberOfAssignments: parseInt(event.target.value),
      yearsOfExperience: 0,
    });
  };

  const handleYearsChange = event => {
    setYears(event.target.value);
    setAssignments(dropDownData.data[0]);

    setYearsError({ ...yearsError, experience: true });

    setTravelExperience({
      ...travelExperience,
      yearsOfExperience: parseInt(event.target.value),
      numberOfAssignments: 0,
    });
  };

  const handleCBChange = event => {
    setTravelExperience({ ...travelExperience, [event.target.name]: event.target.checked });
  };

  const handleCheckBoxChangeX = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleNoSelection.handleShowPanelX(event.target.checked);
    setYesNoOptionSelected(event.target.checked);
  };

  useEffect(() => {
    toggleNoSelection.onPropChange(showPanel, undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [travelExperience]);

  return (
    <div>
      {/* TODO: Remove this theme wrapper since it uses the global theme */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UnitDetailsBody variant="outlined">
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={1}>
                  <Grid item>
                    <UnitCardsTitle>{t('order.createUnit.travelExperience.title')}</UnitCardsTitle>
                  </Grid>
                  <SimpleCheckbox
                    id={'createUnitTravelExperienceEnableCheck'}
                    isChecked={yesNoOptionSelected ? true : false}
                    isdisabled={disableEdit}
                    hasRadio={false}
                    handleChecked={handleCheckBoxChangeX}
                    checkboxLabel={`Yes`}
                    checkboxName={'no-cbx'}
                  />
                </Grid>
              </Grid>
              {showPanel && (
                <>
                  <Grid item xs={12} className={classes.reqPrefSection}>
                    <ReqPrefSection
                      isRequired={isRequired}
                      handleReqPref={handleRPChange}
                      isdisabled={disableEdit}
                      id={'createUnitTravelExperienceReqPrefRadio'}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid alignItems="center" container direction="row" spacing={3}>
                      <Grid item xs={3}>
                        <FormControl fullWidth variant="filled">
                          <InputLabel id="no-of-assignment">
                            {t('order.createUnit.travelExperience.numberOfAssignments')}
                          </InputLabel>
                          <SimpleDropdown
                            id={'createUnitTravelExperienceNumberOfAssignmentsDropdown'}
                            inputName={'numberOfAssignments'}
                            defaultInputList={dropDownData.data}
                            defaultInputValue={dropDownData.data[0]}
                            value={assignments}
                            marginLeft={0}
                            onChange={handleAssignmentChange}
                            minWidth={'160px !important'}
                            disabled={disableEdit}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={1} className={classes.orLabel}>
                        {t('order.createUnit.travelExperience.or')}
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl fullWidth variant="filled" disabled={disableEdit}>
                          <InputLabel id="years-of-experience">
                            {t('order.createUnit.travelExperience.yearsOfExperience')}
                          </InputLabel>
                          <SimpleDropdown
                            id={'createUnitTravelExperienceYearsOfExperienceDropdown'}
                            defaultInputList={dropDownData.data}
                            defaultInputValue={dropDownData.data[0]}
                            value={years}
                            marginLeft={0}
                            onChange={handleYearsChange}
                            minWidth={'160px !important'}
                            disabled={disableEdit}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item>
                        <SimpleCheckbox
                          id={'createUnitTravelExperienceInSpecialityCheckbox'}
                          isChecked={travelExperience?.inSpecialty}
                          handleChecked={handleCBChange}
                          checkboxLabel={t('order.createUnit.travelExperience.inSpeciality')}
                          checkboxName={'inSpecialty'}
                          isdisabled={disableEdit}
                        />
                      </Grid>
                      <Grid item>
                        <SimpleCheckbox
                          id={'createUnitTravelExperienceInGeneralCheckbox'}
                          isChecked={travelExperience?.general}
                          handleChecked={handleCBChange}
                          checkboxLabel={t('order.createUnit.travelExperience.general')}
                          checkboxName={'general'}
                          isdisabled={disableEdit}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <InstructionText>
                      {yearsError.assignment &&
                        yearsError.experience &&
                        t('order.createUnit.travelExperience.instructionText')}
                    </InstructionText>
                  </Grid>
                </>
              )}
            </Grid>
          </UnitDetailsBody>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};
