import { GridCellParams } from '@mui/x-data-grid-pro';
import React from 'react';
import { Link } from 'amn-ui-core';
import { OrderDispatchComponent } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/OrderMatchTab/OrderMatchesGrid/OrderDrawerHelper';

export const OrderMatchesXGridSideBarViewCell =
  (path: string, fieldList: string[] = [], isHoverView?: boolean) =>
  (params: GridCellParams) => {
    return (
      <OrderMatchesXGridSideBarViewCellContent
        path={path}
        fieldList={fieldList}
        isHoverView={isHoverView}
        params={params}
      />
    );
  };

const OrderMatchesXGridSideBarViewCellContent = ({
  path,
  fieldList,
  isHoverView,
  params,
}: {
  path: any;
  fieldList: any;
  isHoverView: any;
  params: GridCellParams;
}) => {
  const [link, setLink] = React.useState<string>('');

  React.useEffect(() => {
    let linkBuild = path;
    fieldList.forEach((id, index) => {
      linkBuild = linkBuild.replace(`{${index}}`, `${params.row[id]}`);
    });
    setLink(linkBuild);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, fieldList]);
  const stopImmediatePropagation = event => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <Link
      href={link}
      style={{ padding: isHoverView ? '0px 8px' : '0px' }}
      onClick={stopImmediatePropagation}
    >
      <OrderDispatchComponent
        displayValue={params.row.previewDrawerViewerId}
        type={params.row.previewDrawerViewerType}
        selectedIndex={params.row.indexCounter}
        selectedorder={{
          row: params.row,
        }}
      />
    </Link>
  );
};
