import React from 'react';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { Typography } from 'amn-ui-core';

const useStyles = makeStyles()((theme) => ({
    wrapper: {
        position: 'absolute',
        top: 0,
        left: '10vw',
    },
    dialog: {
        top: 0,
        marginBottom: '20px',
        '& .MuiDialog-paperWidthSm': {
            width: '390px',
            height: '235px'
        },
    },
    dialogueContent: {
        padding: 0,
        '& .MuiDialogContent-root': {
            padding: 0,
        },
        '& .dialogContentRoot': {
            padding: 0,
        },
    },
    text: {
        fontSize: '14px',
        color: theme.palette.components.typography.color,
        padding: '0px 30px'
    },
}))
export const DiscardChangesDialog = props => {
  const { classes } = useStyles();
  const { dialogeOpen, keepChanges, handleChanges } = props;
  const { t } = useTranslation();
  return (
    <div className={classes.wrapper} data-testid="cancel-dialog">
    <GenericDialog
        open={dialogeOpen}
        className={classes.dialog}
        dialogTitleProps={{
            text: 'Discard Changes?',
        }}
        dialogContentProps={{
            classes: {
                root: classes.dialogueContent,
            },
        }}
        dialogActions={[
            {
                text: 'Cancel',
                onClick: keepChanges
            },
            {
                text: 'YES',
                variant: 'contained',
                color: 'primary',
                onClick: handleChanges,
            },
        ]}
    >
        <Typography className={classes.text}>
            {t('userManagement.discardChangesMessage')}
        </Typography>
    </GenericDialog >
</div>
  );
};
