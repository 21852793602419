import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { SubmissionError } from '@AMIEWEB/GlobalSearch/Placement/SubmissionError';

const useStyles = makeStyles()({
  root: { display: 'flex', flexDirection: 'column', padding: '6px 6px' },
});

export const XGridSubmissionHoverView = (attributesKey: string) => (params: GridCellParams) => {
  return <SubmissionHoverView attributes={params.row[attributesKey]} params={params} />;
};

const SubmissionHoverView = ({ attributes, params }: { attributes: any; params: GridCellParams }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <SubmissionError submissionInfo={attributes} statusDuration={params.row.statusAgeValue} />
    </div>
  );
};
