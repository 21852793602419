export const ff_interviewViewHistory = 'ff_candidate_ui_interview_viewhistory';
export const ff_contactInformationVeryCommunicationLink = 'ff_candidate_ui_contactinformation_verycommunicationlink';
export const ff_skillsChecklistSection = 'ff_candidate_ui_summary_skills_checklist';
export const ff_tsceformIDUpdate = 'ff_candidate_ui_credential_tsceform_id_update';
export const ff_roleTypeEditPermissions = 'ff_candidate_ui_personalinformation_roletype';
export const ff_ui_facility_search_manage_facility = 'ff_ui_facility_search_manage_facility';
export const ff_editSellingNotes = 'ff_ui_Candidate_edit_selling_notes';
export const ff_editUnverifiedLicense = 'ff_ui_Candidate_edit_unverified_licenses';
export const ff_viewReferences = 'ff_ui_Candidate_Summary_View_References';
export const ff_summaryTabIconsUpdate = 'ff_summary_tab_icons_update';
export const ff_autoGenerateSellingNotes = 'ff_ui_auto_generate_selling_notes';
export const ff_ui_Candidate_Summary_send_virtual_interview_invite =
  'ff_ui_Candidate_Summary_send_virtual_interview_invite';
export const ff_candidate_ui_job_interests = 'ff_candidate_ui_job_interests';
export const ff_candidate_ui_job_match = 'ff_candidate_ui_job_match';
export const ff_candidate_ui_preferences = 'ff_candidate_ui_preferences';
export const ff_candidate_tags = 'ff_candidate_ui_tags';
export const ff_ui_viewing_skill_checklist = 'ff_ui_viewing_skill_checklist';
export const ff_candidate_ui_search_job_interest = 'ff_candidate_ui_search_job_interest';
export const ff_candidate_ui_search_job_match = 'ff_candidate_ui_search_job_match';
export const ff_candidate_ui_manage_references = 'ff_candidate_ui_manage_references';
export const ff_ui_viewing_editing_candidate_applications_questions =
  'ff_ui_viewing_editing_candidate_applications_questions';
export const ff_ui_candidate_manage_EquipmentProcedure = 'ff_ui_candidate_manage_EquipmentProcedure';
export const ff_ui_candidate_Credentialing_One = 'ff_ui_candidate_Credentialing_One';
export const ff_candidate_ui_view_ADPID = 'ff_candidate_ui_view_ADPID';
export const ff_candidate_ui_manage_NUID = 'ff_candidate_ui_manage_NUID';
export const ff_candidate_ui_manage_time_off = 'ff_candidate_ui_manage_time_off';
export const ff_candidate_ui_view_SupervisorCaseLoad = 'ff_candidate_ui_view_SupervisorCaseLoad';
export const ff_candidate_ui_manage_SupervisorQualifications = 'ff_candidate_ui_manage_SupervisorQualifications';
export const ff_candidate_api_candidate_search_index = 'ff_candidate_api_candidate_search_index';
export const ff_ui_Candidate_Summary_Manage_Audits = 'ff_ui_Candidate_Summary_Manage_Audits';
export const ff_candidate_ui_manage_365Data_Candidate_Payroll = 'ff_candidate_ui_manage_365Data_Candidate_Payroll';
export const ff_candidate_ui_manage_365Data_Tax_home_information =
  'ff_candidate_ui_manage_365Data_Tax_home_information';
export const ff_candidate_container_ui_v2 = 'ff_candidate_container_ui_v2';
export const ff_candidate_ui_view_Externaltasks = 'ff_candidate_ui_view_externaltasks';
export const ff_WorkExperience_Enhancement = 'ff_WorkExperience_Enhancement';
export const ff_candidate_ui_passport_precheck_preferences = 'ff_candidate_ui_passport_precheck_preferences';
export const ff_notifications_share_orders_to_passport = 'ff_notifications_share_orders_to_passport';
