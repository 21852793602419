import React, { useMemo, useEffect } from 'react';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'app/ComponentLibrary/DateRangeNew';
import moment from 'moment';
import { makeStyles } from 'tss-react/mui';
import { addDays, addMonths, endOfDay, isSameMonth, startOfDay } from 'date-fns';
import { getAmnEndOfWeek, getAmnStartOfWeek } from 'utils/dates/dateExtraction';
import { Typography } from 'amn-ui-core';
import { Controller, useFormContext } from 'react-hook-form';
import { SalesReportResult } from './SalesReportResult';
import { useSelector } from 'react-redux';
import { selectInputDetails, selectR225ReportDetails } from 'store/redux-store/report-details/selectors';

const useStyles = makeStyles()(theme => ({
  root: {
    position: 'relative',
    marginTop: '-7px',
    backgroundColor: theme.palette.common.white,
    paddingRight: 12,
  },
  dateRangePicker: {
    height: 56,
  },
}));

export const SalesReportDetails = props => {
  const { control, setValue } = useFormContext();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const inputDetails = useSelector(selectInputDetails);
  const inputReportDetails = useSelector(selectR225ReportDetails)?.inputDetails;

  const reportGeneratedDate = useMemo(() => {
    const currentDate = new Date();
    return `${currentDate.toLocaleString('en-us', {
      month: 'long',
      day: 'numeric',
    })}, ${currentDate.getFullYear()}`;
  }, []);

  useEffect(() => {
    if (inputDetails?.tabSelected) {
      setValue(
        'dateRange',
        { startDate: startOfDay(getAmnStartOfWeek()), endDate: endOfDay(getAmnEndOfWeek()) },
        { shouldDirty: true },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDetails?.tabSelected]);

  useEffect(() => {
    setValue(
      'dateRange',
      { startDate: startOfDay(getAmnStartOfWeek()), endDate: endOfDay(getAmnEndOfWeek()) },
      { shouldDirty: true },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutGrid container direction="column" className={classes.root}>
      <LayoutGridItem md={12} xs={12}>
        <LayoutGridItem md={3} xs={12}>
          <Controller
            name={'dateRange'}
            control={control}
            render={({ ref, onChange, value }) => (
              <DateRangePicker
                variant={'filled'}
                showIcon
                trailingIconShow
                placeholder={t('reportPages.r225.selectDateRange')}
                onChange={val => {
                  onChange(val);
                }}
                value={{
                  startDate: value?.startDate,
                  endDate: value?.endDate,
                }}
                applyOnClickAway
                keepClickAwayVal
                defaultFirstMonth={new Date(value.startDate)}
                defaultSecondMonth={
                  isSameMonth(value.startDate, value.endDate) ? addMonths(value.startDate, 1) : value.endDate
                }
                actionBar
                isDateIconReq={true}
                fullWidth
                useMaxWidth={false}
                className={classes.dateRangePicker}
                initialDateRange={{
                  startDate: value?.startDate,
                  endDate: value?.endDate,
                  label:
                    moment(value?.startDate).format('MM/DD/YYYY') === moment(value?.endDate).format('MM/DD/YYYY')
                      ? `${moment(value?.startDate).format('MM/DD/YYYY')}`
                      : `${moment(value?.startDate).format('MM/DD/YYYY')} - ${moment(value?.endDate).format(
                          'MM/DD/YYYY',
                        )}`,
                  useLabelAsValue: false,
                }}
                definedRanges={[
                  {
                    label: t('reportPages.r225.thisWeek'),
                    startDate: startOfDay(getAmnStartOfWeek()),
                    endDate: endOfDay(getAmnEndOfWeek()),
                    useLabelAsValue: false,
                  },
                  {
                    label: t('reportPages.r225.lastWeek'),
                    startDate: startOfDay(addDays(getAmnStartOfWeek(), -7)),
                    endDate: endOfDay(addDays(getAmnEndOfWeek(), -7)),
                    useLabelAsValue: false,
                  },
                ]}
              />
            )}
          />
        </LayoutGridItem>
      </LayoutGridItem>

      <LayoutGridItem md={12} xs={12} sx={{ paddingTop: 20 }}>
        {inputReportDetails?.title && (
          <>
            <Typography variant={'subtitle1'} >{inputReportDetails?.title}</Typography>
            <Typography variant={'caption'}>
              {`${t('reportPages.r225.generated')} ${reportGeneratedDate}`}
            </Typography>
          </>
        )}
      </LayoutGridItem>

      <LayoutGridItem md={12} xs={12}>
        <SalesReportResult {...props} />
      </LayoutGridItem>
    </LayoutGrid>
  );
};
