import React from 'react';
import { Avatar } from "amn-ui-core";
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';
import labProfile from 'app/assets/images/lab-profile.svg';
import { ICoverageDetails } from 'app/models/UserCoverage/UserCoverage';

const useStyles = makeStyles()(theme => ({
    card: {
        display: 'flex',
        width: '100%',
        background: '#FCFCFD 0% 0% no-repeat padding-box',
        border: '1px solid #D9D9D9',
        borderRadius: '7px',
        marginBottom: '12px',
    },
    cardContent: {
        display: 'flex',
        padding: '12px 13px',
        overflow: 'hidden',
        width: '100%',
    },
    avatarContainer: {
        flexDirection: 'column',
        marginRight: '12px',
    },
    avatar: {
        color: 'white',
        backgroundColor: '#52429A',
        textTransform: 'uppercase',
        height: '36px',
        width: '36px',
        fontSize: '18px',
        margin: '0px auto',
    },
    details: {
        width: '100%',
    },
    cardHeader: {
        fontWeight: 'bold',
    },
    row: {
        display: 'flex',
        alignItems: 'baseline',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '14px',
        marginTop: '6px',
    },
    subHeader: {
        fontWeight: 'bold',
        fontSize: '12px',
        color: '#333333',
        whiteSpace: 'nowrap',
    },
    notes: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
        marginTop: '6px',
        fontSize: '14px',
    },
    docIcon: {
        cursor: 'pointer',
        width: '22px',
        marginLeft: 'auto',
        padding: 0,
        '&:hover': {
            color: '#41A2DF',
        },
    },
}));

export const CoverageCard = ({ data, avatarColor, }: { data: ICoverageDetails, avatarColor: string }) => {
    const { classes } = useStyles();
    const startDate = moment(data?.startDate).format('MM/DD/YYYY');
    const endDate = moment(data?.endDate).format('MM/DD/YYYY');

    return (
        <div className={classes.card}>
            <div className={classes.cardContent}>
                <div className={classes.avatarContainer}>
                    <Avatar className={classes.avatar} style={{ backgroundColor: avatarColor }}>
                        {`${data?.coveredEmployeeFirstName?.substring(0, 1)}${data?.coveredEmployeeLastName?.substring(0, 1)}`}
                    </Avatar>
                </div>
                <div className={classes.details}>
                    <div className={classes.row} style={{ marginTop: 0, alignItems: 'start' }}>
                        <div className={classes.cardHeader}>{`${data?.coveredEmployeeFirstName} ${data?.coveredEmployeeLastName}`}</div>
                        <img src={labProfile} className={classes.docIcon} onClick={() => { }} />
                    </div>
                    <div className={classes.row}>
                        <div>{`${startDate} - ${endDate}`}</div>
                    </div>
                    <div className={classes.row} style={{ whiteSpace: 'break-spaces' }}>
                        <div className={classes.subHeader}>{'TOA:' + ' '}</div>
                        <div>{` ${data?.totalDaysOfCoverage}`}</div>
                    </div>
                    <div className={classes.row} style={{ whiteSpace: 'break-spaces' }}>
                        <div className={classes.subHeader}>{'Coverage:' + ' '}</div>
                        <div>{` ${data?.coverageTypeDescription}`}</div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.subHeader}>{'Notes:'}</div>
                    </div>
                    <div className={classes.notes}>{data?.notes}</div>
                </div>
            </div>
        </div>
    )
}