import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { orderPreferenceActions } from './store/OrderPreference.redux';
import { selectOrderPreferenceAlerts } from './store/OrderPreference.selector';
import { globalActions } from 'app/ApplicationRoot/Global.redux';

export enum orderPreferenceAlert {
  automationOnSuccess = 1,
  automationOffSuccess,
  automationFailed,
  distributionUpdationSuccess,
  distributionUpdationFailed,
}

export const useOrderPreferenceAlerts = () => {
  const { t } = useTranslation();
  const alert = useSelector(selectOrderPreferenceAlerts);
  const dispatch = useDispatch();

  const getAlertProps = customSwitch({
    [orderPreferenceAlert.automationOnSuccess]: {
      message: t('Automation turned on successfully.'),
      severity: 'success',
    },
    [orderPreferenceAlert.automationOffSuccess]: {
      message: t('Automation turned off successfully.'),
      severity: 'success',
    },
    [orderPreferenceAlert.automationFailed]: {
      message: t("Automation couldn't be turned on due to some technical issue."),
      severity: 'error',
    },
    [orderPreferenceAlert.distributionUpdationSuccess]: {
      message: t('Changes saved successfully.'),
      severity: 'success',
    },
    [orderPreferenceAlert.distributionUpdationFailed]: {
      message: t('An error occurred while saving the changes.'),
      severity: 'error',
    },
  })('');

  useEffect(() => {
    if (alert) {
      dispatch(globalActions.setSnackBar({ ...getAlertProps(alert) }));
      /** Reset action */
      dispatch(orderPreferenceActions.setAlert(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert]);
};
