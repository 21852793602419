import { missingField } from 'app/constants';
import { IExperience } from 'app/models/Placement/PlacementDetails';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useSelector, useDispatch } from 'react-redux';
import { Concatenate } from 'utils/string/string';
import {
  selectSubmissionInfo,
  selectServiceFailures,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { RequirementsRow } from '../../TableComponents/TableComponents';
import { ModalTableHeader } from 'app/models/Orders/OrderDetails';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import styledComponent from 'styled-components';
import { TableSection } from '../../../customComponents/TableSection';
import { Button, Typography, Avatar } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';

const SkillsHeader: ModalTableHeader[] = [
  { title: 'skillset', value: 'Skill Set' },
  { title: 'lastThreeYearsYearsOfExperience', value: 'Last 3 Years' },
  { title: 'totalYearsOfExperience', value: 'Total Experience' },
];

function getExperienceData(years: number | undefined, months: number | undefined) {
  if ((years == null && months == null) || (years === 0 && months === 0)) return '';
  else if (years == null || years === 0) return `${months} mo`;
  else if (months == null || months === 0) return `${years} yr`;
  else return `${years} yr, ${months} mo`;
}

function getExperienceDataWithFormating(years: number | undefined, months: number | undefined) {
  if ((years == null && months == null) || (years === 0 && months === 0)) return missingField;
  else if (years == null || years === 0) return `${months} mo`;
  else if (months == null || months === 0) return `${years} yr`;
  else return `${years} yr, ${months} mo`;
}

const useStyles = makeStyles()(theme=>({
  yoeCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 6,
  },
  button: {
    cursor: 'pointer',
    color: '#006FB9',
    fontWeight: 450,
    textTransform: 'inherit',
    fontSize: 14,
    padding: 0,
    paddingLeft: '2px',
    'min-width': 'inherit',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  },

  blue: {
    width: '22px',
    height: '22px',
    fontSize: '12px',
    display: 'inline-flex',
    margin: '0 0.4rem 0 0.4rem',
  },
  green: {
    width: '22px',
    height: '22px',
    fontSize: '12px',
    display: 'inline-flex',
    margin: '0 0.4rem 0 0.4rem',
  },
  inlineDiv: {
    marginBottom: '1rem',
  },
}))

export const Experience = () => {
  const { t } = useTranslation();
  const {
    orderReq: {
      order: { experience: orderReqItem },
      candidate: { experience: candidateReqItem },
    },
    requirementFilter,
  } = useSelector(selectSubmissionInfo);
  const { experienceRequirement: serviceFailed } = useSelector(selectServiceFailures);
  const placementOrderType = useSelector(selectPlacementOrderType);
  const { placementId } = useContext(AddOnContext);

  const { promiseInProgress } = usePromiseTracker({
    area: 'placement-submission-experience-requirement',
    delay: 0,
  });

  const dispatch = useDispatch();
  const { display } = useRequirementFilter('submission-order', requirementFilter);

  return display ? (
    <RequirementsRow
      id="placement-submission-order-experience"
      title={t('placement.profile.summary.submission.order.experience.title')}
      requirementCell={orderReqItem ? <RowKey experience={orderReqItem} t={t} /> : missingField}
      candidateCell={<RowValue experience={candidateReqItem} t={t} />}
      loading={promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() => {
        dispatch(
          placementSummaryActions.getExperienceRequirement({
            placementId,
            placementOrderType,
            isRetryingService: true,
          }),
        );
      }}
    />
  ) : null;
};

const RowKey = ({ experience, t }) => {
  const { classes } = useStyles();
  return (
    <TableSection
      items={[
        {
          primaryContent: (
            <>
              {experience ? (
                <div className={classes.inlineDiv}>
                  <span>{`${t('placement.profile.summary.submission.order.experience.line1')} ${
                    experience?.data && experience?.data.yearsOfExperience
                      ? experience.data.yearsOfExperience
                      : missingField
                  }`}</span>
                </div>
              ) : (
                <></>
              )}
              {experience?.data?.emr ? (
                <div className={classes.inlineDiv}>
                  <span>{t('placement.profile.summary.submission.order.experience.line2')}</span>
                  <span>
                    {
                      <Avatar   variant={experience?.data?.emr.isRequired ?'primary':'green'} className={` ${experience?.data?.emr.isRequired ? classes.blue : classes.green}`}>
                        {experience?.data?.emr.isRequired ? 'R' : 'P'}
                      </Avatar>
                    }
                  </span>
                </div>
              ) : (
                <></>
              )}
              {experience?.data?.emr?.values ? (
                <div>
                  <span>{Concatenate(experience?.data?.emr?.values, ', ')}</span>
                </div>
              ) : (
                <></>
              )}
            </>
          ),
        },
      ]}
    />
  );
};

const RowValue = ({ experience, t }) => {
  const { classes } = useStyles();
  const { setModalProps } = useContext(AddOnContext);
  const {
    candidateYearsOfExpBySkillsetInfo: experiences = [],
    totalYearsOfExp: yoe = 0,
    totalMonthsOfExp: moe,
  } = experience?.data || {};

  const flattenSkillsetData = (skillsetInfo: IExperience[]) =>
    skillsetInfo.reduce(
      (resultArray, info) => [
        ...resultArray,
        {
          skillset: Concatenate([info.disciplineAbbr, info.specialtyAbbr], ', '),
          lastThreeYearsYearsOfExperience: (
            <>
              {getExperienceDataWithFormating(
                info.lastThreeYearsYearsOfExperience,
                info.lastThreeYearsMonthsOfExperience,
              )}
            </>
          ),
          totalYearsOfExperience: (
            <>{getExperienceDataWithFormating(info.totalYearsOfExperience, info.totalMonthsOfExperience)}</>
          ),
        },
      ],
      [] as any[],
    );

  return (
    <div className={classes.yoeCell}>
      <Key variant="body1">
        <span style={{ whiteSpace: 'nowrap' }}>
          <b>{`${t('placement.profile.summary.submission.amn.experince.yearsOfExperience')}:`}</b>
        </span>
      </Key>
      <Button
        type="button"
        variant="text"
        disableRipple
        disableTouchRipple
        disableFocusRipple
        onClick={() =>
          setModalProps?.({
            onChange: e => setModalProps?.(null),
            title: `${t('placement.profile.summary.submission.amn.experince.title')}`,
            subTitle: (
              <span style={{ fontSize: '14px' }}>
                {`${t('placement.profile.summary.submission.amn.experince.yearsOfExperience')}: `}{' '}
                <b>{getExperienceData(yoe, moe)}</b>
              </span>
            ),
            columns: SkillsHeader,
            rows: flattenSkillsetData(experiences || []),
          })
        }
        disabled={!(yoe || moe)}
        classes={{ root: classes.button }}
      >
        {Number.isFinite(yoe) ? (moe ? getExperienceData(yoe, moe) : yoe) : missingField}
      </Button>
    </div>
  );
};

const Key = styledComponent(Typography)`
  padding-top: 1px;
`;
