import { Box, LinearProgress, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';

const useStyes = makeStyles()(theme => ({
  progreesBar: {
    backgroundColor: '#d0d0d0',
  },
  ProgreesBarS: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: 'Green !important',
    },
  },
}));
export const LinearProgressWithLabel = props => {
  const { classes } = useStyes();

  return (
    <>
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Box style={{ width: '100%' }}>
          <LinearProgress
            variant="determinate"
            {...props}
            className={props.value === 100 ? classes.ProgreesBarS : classes.progreesBar}
          />
        </Box>
      </Box>
      <Box style={{ minWidth: 35, textAlign: 'right', height: '9px' }}>
        <Typography variant="body2">{`${props.filledItemscount}/${props.totalItems}`}</Typography>
      </Box>
    </>
  );
};
