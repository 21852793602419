import React from 'react';
import { makeStyles } from 'tss-react/mui';
import {  Grid, Typography } from 'amn-ui-core';

const useStyles = makeStyles()(() =>
  ({
    root: {
      height: 'auto',
      maxWidth: '280px',
      maxHeight: '300px',
      overflow: 'auto',
    },
    title: {
      marginLeft: '3px',
    },
    values: {
      textAlign: 'left',
      fontSize: 12,
      color: '#003E68',
      opacity: 1,
      padding: '5px',
      margin: 0,
      lineHeight: '16px',
    },
  }));

interface Props {
  data: string[];
  title?: string;
}

export const HoverPopUp = (props: Props) => {
  const { classes } = useStyles();
  return (
    <Grid container className={classes.root}>
      {props.title && (
        <Typography variant="subtitle2" className={classes.title}>
          {props.title}
        </Typography>
      )}
      {props.data.map((value: string) => (
        <Grid item xs={12} className={classes.values}>
          {value}
        </Grid>
      ))}
    </Grid>
  );
};
