import React from 'react';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { CandidateContract as CandidateContractEditor } from './CandidateContract';
import { ClientConfirmations as ClientConfirmationsEditor } from './ClientConfirmations';
import { navigationStickActions, settingMenu } from '@AMIEWEB/GlobalNavigationMenu/NavigationStick.redux';
import { useParams } from 'react-router-dom';
import { selectNavigationStick } from '@AMIEWEB/GlobalNavigationMenu/NavigationStick.selectors';
import { useSelector, useDispatch } from 'react-redux';

const renderer = customSwitch({
  'candidate-contracts': <CandidateContractEditor />,
  'client-confirmations': <ClientConfirmationsEditor />,
})(null);

const subMenuMapper = customSwitch({
  'client-confirmations': 'templateEditor.clientConfirmation',
  'candidate-contracts': 'templateEditor.candidateContracts',
})(null);

export const TemplateEditor = () => {
  const { editor } = useParams<{ editor: 'candidate-contracts' | 'client-confirmations' }>();

  const { selectedMenu } = useSelector(selectNavigationStick);

  const dispatch = useDispatch();

  /** Logic to highlight sidenav if routes are directly accessed */
  React.useEffect(() => {
    if (!['settings', 'templateEditor'].includes(selectedMenu.key)) {
      const menu = settingMenu.subMenu.find(x => x.key === 'templateEditor');

      // @ts-ignore
      dispatch(navigationStickActions.setSelectedMenu(menu));
      const subMenuKey = subMenuMapper(editor);
      const subMenu = menu.subMenu.find(x => x.key === subMenuKey);
      dispatch(navigationStickActions.setSelectedSubMenu(subMenu));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return React.useMemo(() => renderer(editor), [editor]);
};
