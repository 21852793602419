import React from 'react';
import { useTranslation } from 'react-i18next';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';

export const Undo = ({
  openDialog,
  handleConfirmAction,
  handleCancelAction,
  undoButtonId,
  confirmButtonId,
}: {
  openDialog: boolean;
  handleConfirmAction: any;
  handleCancelAction: any;
  undoButtonId?: string;
  confirmButtonId?: string;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <GenericDialog
        open={openDialog}
        maxWidth="sm"
        dialogTitleProps={{ text: t('undoModal.undoConfirmation') }}
        dialogActions={[
          {
            id: undoButtonId,
            text: t('undoModal.confirmation.cancel'),
            onClick: handleCancelAction,
            color: 'tertiary',
          },
          {
            id: confirmButtonId,
            text: t('undoModal.confirmation.yes'),
            onClick: handleConfirmAction,
            variant: 'contained',
          },
        ]}
      >
        {t('undoModal.saveConfirmation')}
      </GenericDialog>
    </>
  );
};
