import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { candidateDetailsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { useTranslation } from 'react-i18next';
import { Grid } from 'amn-ui-core';
import { WorkExperienceModal } from './WorkExperienceModal';
import { selectSelectedWorkExperience } from 'store/redux-store/candidate-work-experience/selector';
import ReferenceWrapper from './AddEditReference/ReferenceWrapper';
import { WorkExperienceViewStyles } from '../EditWorkExperience/WorkExperienceModal.style';
const PERSONAL_TIME_OFF = 'Personal Time Off';

export const WorkExperienceView = ({ opened, handleClosed }) => {
  const { t } = useTranslation();
  const { classes } = WorkExperienceViewStyles();

  const candidateDetails = useSelector(candidateDetailsSelection);
  const selectedWorkExperience = useSelector(selectSelectedWorkExperience);
  const [editModalTitle, setEditModalTitle] = useState<boolean>(false);
  const [isTravelWorkExp, setIsTravelWorkExp] = useState<boolean>(true);

  useEffect(() => {
    if (selectedWorkExperience?.facilityName === PERSONAL_TIME_OFF) {
      setIsTravelWorkExp(false);
    }
  }, []);

  const handleDataFromChild = data => {
    setEditModalTitle(data);
  };
  return (
    <GenericDialog
      variant="blue"
      classes={{ paper: classes.dialogContainer }}
      open={opened}
      onClose={handleClosed}
      dialogTitleProps={{
        text: editModalTitle
          ? `${t('candidate.dialogActions.EditWorkExperienceTitle')} - ${candidateDetails.name.first} ${
              candidateDetails.name.last
            } (${candidateDetails.travelerId})`
          : `${t('candidate.dialogActions.WorkExperienceTitle')} - ${candidateDetails.name.first} ${
              candidateDetails.name.last
            } (${candidateDetails.travelerId})`,
        closeButton: true,
        expandable: true,
      }}
      dialogContentProps={{
        classes: { root: classes.dialogContent },
      }}
    >
      <Grid container>
        <Grid item xs={12} pt={4}>
          <WorkExperienceModal selectedWorkExperience={selectedWorkExperience} onEditOpen={handleDataFromChild} />
        </Grid>

        {isTravelWorkExp && <ReferenceWrapper />}
      </Grid>
    </GenericDialog>
  );
};
