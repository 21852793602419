import { Chip, Grid, IconButton, Link, Typography } from 'amn-ui-core';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IExternalTaskModalDetails, ITaskAttachmentList, SubTypeId, TaskStatus } from 'app/models/Tasks/Tasks';
import CloseIcon from '@mui/icons-material/Close';
import { selectUser } from 'oidc/user.selectors';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TaskRecipientAvatars } from '../TaskManagement/RecipientAvatars';
import { missingField } from 'app/constants';
import { RecipientStatusIcon } from './StatusIcon';
import { useTheme } from 'amn-ui-core';
import { CustomTooltip } from '@AMIEWEB/Common';
import { SubTaskOnContext } from './TaskDetails';
import { ExternalTaskDateRenderer } from './ExternalTaskDateRenderer';
import { getAttachmentIcon, getFileName } from '@AMIEWEB/Notification/Common/AttachmentFileTypes';
import { getExternalRecipientsForAvatars } from './helper';

export const TaskDetailsExternalRightPanel = ({
  classes,
  taskDetails,
  isExternal = false,
  setEditExternalOpen,
  setCreateModalOpen,
  attachments,
  setDeleteModalOpen,
  setAttachmentIndex,
  setIsPopperOpen,
}: {
  classes: any;
  taskDetails: IExternalTaskModalDetails;
  isExternal?: boolean;
  setEditExternalOpen: (e) => void;
  setCreateModalOpen: (e) => void;
  attachments: ITaskAttachmentList[];
  setDeleteModalOpen: (e) => void;
  setAttachmentIndex: (e) => void;
  setIsPopperOpen: (e) => void;
}) => {
  const { t } = useTranslation();
  const [isEditDisabled, setEditDisabled] = useState(true);
  const [hasSubType1, setHasSubType1] = useState<boolean>(false);
  const [hasSubType2, setHasSubType2] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [textHeight, setTextHeight] = useState<any>('40px');
  const userInfo = useSelector(selectUser);
  const user = userInfo?.userInfo;
  const { expanded } = useContext(SubTaskOnContext);
  const theme = useTheme();

  const deleteAttachmentUser = task => {
    const isValidUser = task?.createdBy?.id === user?.employeeId || user?.employeeId === task?.assignedTo?.id;
    return isValidUser;
  };

  const eligibleExternalEdit = () => {
    if (taskDetails?.isAMIEWebRequest) {
      setEditDisabled(
        taskDetails?.createdBy?.id !== user?.employeeId ||
          taskDetails?.statusId === TaskStatus.Cancelled ||
          taskDetails?.statusId === TaskStatus.Completed,
      );
    } else {
      const isRecruiter = user?.roles?.includes('Recruitment');
      if (isRecruiter) {
        setEditDisabled(true);
      } else {
        setEditDisabled(false);
      }
    }
  };

  useEffect(() => {
    if (taskDetails && isExternal) {
      eligibleExternalEdit();
      if (
        taskDetails?.subCategory1?.subTypeId === SubTypeId.License ||
        taskDetails?.subCategory1?.subTypeId === SubTypeId.Certification
      ) {
        setHasSubType1(true);
      }
      if (
        taskDetails?.subCategory2?.subTypeId === SubTypeId.State ||
        taskDetails?.subCategory2?.subTypeId === SubTypeId.IssuingBody
      ) {
        setHasSubType2(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskDetails]);

  return (
    <Grid data-testid="task-details-right-content" style={{ width: 'inherit', border: '1px solid #D9D9D9' }}>
      <div className={classes.gridRightContainer}>
        <div data-testid="task-details-status-container" className={classes.detailsRightColumn}>
          <div style={{ padding: '24px 24px 24px 12px' }}>
            <div className={classes.flexContainer}>
              <RecipientStatusIcon
                classes={classes}
                details={taskDetails}
                isExternal={true}
                isEligableToUpdate={!isEditDisabled}
                isSubtask={false}
                statusDetails={{
                  statusId: taskDetails?.statusId === TaskStatus.New ? TaskStatus.Pending : taskDetails?.statusId,
                }}
                coverageUser={null}
              />

              <div style={{ marginLeft: '9px', marginRight: 'auto', display: 'inline-grid' }}>
                <div
                  data-testid="task-details-title"
                  className={classes.taskTitle}
                  style={
                    taskDetails?.statusId === TaskStatus.Completed || taskDetails?.statusId === TaskStatus.Cancelled
                      ? {
                          textDecorationLine: 'line-through',
                          color: theme.palette.system.neutralGray,
                          opacity: 0.5,
                        }
                      : { color: theme.palette.framework.system.charcoal }
                  }
                >
                  {taskDetails?.task}
                </div>
              </div>
              <div className={classes.headerRight}>
                <div className={classes.replyBtnContainer}>
                  <CustomTooltip standardMargin arrow tooltipContent={t('search.grid.editMenu.edit')}>
                    <IconButton
                      color="secondary"
                      size="medium"
                      disabled={isEditDisabled}
                      disableRipple
                      onClick={() => (isExternal ? setEditExternalOpen(true) : setCreateModalOpen(true))}
                    >
                      <EditOutlinedIcon className={classes.replyBtnIcon} />
                    </IconButton>
                  </CustomTooltip>
                </div>
                <div style={{ paddingLeft: '12px' }}>
                  <ExternalTaskDateRenderer details={taskDetails} setIsPopperOpen={setIsPopperOpen} />
                </div>
              </div>
            </div>
            <div
              style={{
                fontSize: '14px',
                color: theme.palette.system.neutralGray,
                lineHeight: '20px',
                marginLeft: '36px',
                marginBottom: '9px',
              }}
              data-testid="task-details-task-category"
            >
              {taskDetails?.taskCategory?.title}
            </div>

            {hasSubType1 && (
              <div style={{ display: 'flex', alignItems: 'flex-start', marginLeft: '37px' }}>
                <div className={classes.subTypeHeading}>
                  {`${
                    taskDetails?.subCategory1?.subTypeId === SubTypeId.License
                      ? t('notification.taskDetails.license')
                      : t('notification.taskDetails.certification')
                  }: `}
                </div>
                <div className={classes.subtypeText}>
                  {taskDetails?.subCategory1?.subTypeId === SubTypeId.License
                    ? taskDetails?.subCategory1?.subTypeDataText?.split('(')[1]?.split(')')[0]
                    : taskDetails?.subCategory1?.subTypeDataText}
                </div>
              </div>
            )}
            {hasSubType2 && (
              <div style={{ display: 'flex', alignItems: 'flex-start', marginLeft: '37px' }}>
                <div className={classes.subTypeHeading}>
                  {`${
                    taskDetails?.subCategory2?.subTypeId === SubTypeId.State
                      ? t('notification.taskDetails.state')
                      : t('notification.taskDetails.issuingBody')
                  }: `}
                </div>
                <div className={classes.subtypeText}>{taskDetails?.subCategory2?.subTypeDataText}</div>
              </div>
            )}
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography style={{ fontSize: '14px !important' }} className={classes.descriptionTitle}>
                  {t('notification.taskDetails.description')}
                </Typography>
              </div>
              <div
                style={{
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontFamily: 'Roboto',
                  marginBottom: '6px',
                  marginLeft: '36px',
                  whiteSpace: 'pre-line',
                }}
                data-testid="task-details-description"
              >
                {taskDetails?.description ? (
                  <>
                    <Typography
                      id="typography"
                      variant="body1"
                      style={{
                        lineHeight: '20px',
                        fontSize: '14px',
                        height: taskDetails?.description?.length < 230 ? 'auto' : textHeight,
                        overflow: 'hidden',
                      }}
                    >
                      {taskDetails?.description}
                    </Typography>
                    <Link
                      data-testid="task-details-show-btn"
                      component="button"
                      onClick={e => {
                        e.preventDefault();
                        setShowMore(s => !s);
                        if (!showMore) {
                          setTextHeight('auto');
                        } else {
                          setTextHeight('40px');
                        }
                      }}
                      hidden={taskDetails?.description?.length <= 230}
                    >
                      {showMore ? t('notification.subTask.viewLess') : t('notification.subTask.viewMore')}
                    </Link>
                  </>
                ) : (
                  missingField
                )}
              </div>
              {taskDetails?.attachments && (
                <div style={{ marginBottom: '9px', marginLeft: '33px' }}>
                  {taskDetails?.attachments &&
                    attachments?.map((attachment: ITaskAttachmentList, index) => (
                      <Chip
                        classes={{ icon: classes.fileIcon, label: classes.chipLabel }}
                        key={index}
                        className={classes.attachmentChip}
                        icon={
                          <div>
                            <img
                              src={getAttachmentIcon(
                                attachment?.attachmentType && attachment?.attachmentType?.toLowerCase(),
                              )}
                              alt={t('FileIcon')}
                            />
                          </div>
                        }
                        label={getFileName(attachment.saasAttachmentUrl)}
                        onClick={() => {
                          window.open(attachment?.saasAttachmentUrl, '_blank');
                        }}
                        deleteIcon={deleteAttachmentUser(taskDetails) && <CloseIcon />}
                        onDelete={
                          deleteAttachmentUser(taskDetails)
                            ? () => {
                                setDeleteModalOpen(true);
                                setAttachmentIndex(index);
                              }
                            : undefined
                        }
                      />
                    ))}
                </div>
              )}
            </>

            <div
              style={{
                display: 'flex',
                width: '100%',
                flexWrap: 'nowrap',
                margin: '6px auto 0px 36px',
                overflow: expanded ? 'auto hidden' : null,
                maxWidth: expanded ? '70%' : null,
              }}
            >
              {TaskRecipientAvatars(
                null,
                false,
                getExternalRecipientsForAvatars(taskDetails),
                taskDetails,
                5,
                taskDetails?.createdBy,
              )}
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
};
