/* eslint-disable i18next/no-literal-string */
import { Grid, Table, Typography } from 'amn-ui-core';
import { ErrorAction } from 'app/components/Common/ErrorPage/ErrorAction';
import { requirementType } from 'app/models/Placement/PlacementSummary';
import React from 'react';
import { Loading, TableCell } from '../../../CommonTableComponents/CommonTableComponents';
import styledComponent from 'styled-components';
import { getRequirementCheck } from '../../../helper';
import { useSelector } from 'react-redux';
import { selectOfferInfo } from '../../../../../../../../../store/redux-store/placement-summary/selectors';

export const TransportationTable = ({ headerComponent, id, ...props }) => {
  return (
    <Table id={id}>
      {headerComponent && (
        <thead style={{ width: '100%' }}>
          <TableCell colSpan={2}>{headerComponent}</TableCell>
        </thead>
      )}
      <tbody>{props.children}</tbody>
    </Table>
  );
};

interface TransporationRowProps {
  id: string;
  label: string;
  controlComponent?: JSX.Element;
  loading?: boolean;
  hasError?: boolean;
  onTryAgain?: Function;
  requirement?: requirementType;
  [key: string]: any;
}
export const TransportationRow = ({
  id,
  label,
  controlComponent,
  loading,
  transparentTop = false,
  transparentBottom = false,
  hasError = false,
  onTryAgain,
  requirement,
  ...props
}: TransporationRowProps) => {
  const cellStyle = {
    borderTop: transparentTop ? 'transparent' : undefined,
    borderBottom: transparentBottom ? 'transparent' : undefined,
    paddingTop: transparentTop ? 0 : undefined,
    paddingBottom: transparentBottom ? 0 : undefined,
  };

  const {
    transportation: { reqCounts: transportationCounts },
  } = useSelector(selectOfferInfo);

  return (
    <tr {...props}>
      {/* If this is one API that returns everything, then move the error component to the TransportationsTable instead of each row */}
      {hasError && !loading ? (
        <TableCell colSpan="3">
          <ErrorAction onTryAgain={onTryAgain} />
        </TableCell>
      ) : (
        <>
          <TableCell style={{ width: '50%', ...cellStyle }}>
            <Grid container spacing={2} wrap="nowrap" alignItems="center">
              {transportationCounts.aggregate > 0 && (
                <Grid style={{ width: '32px', minWidth: '32px' }} item>
                  {getRequirementCheck(requirement)}
                </Grid>
              )}
              <Grid item>
                <Typography variant="body1">{label}</Typography>
              </Grid>
            </Grid>
          </TableCell>
          <TableCell style={{ width: '50%', ...cellStyle }}>
            {loading ? (
              <Loading />
            ) : (
              <Grid container spacing={2}>
                <FlexGridItem item>{controlComponent}</FlexGridItem>
              </Grid>
            )}
          </TableCell>
        </>
      )}
    </tr>
  );
};

const FlexGridItem = styledComponent(Grid)`
  display: flex;
  flex-direction: column;
  flex: 1 1;
`;
