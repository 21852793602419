import { CircularProgress, Theme } from 'amn-ui-core';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import React, { useState, useEffect, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import { Cancel } from 'app/components/Common/CancelModal/Cancel';
import { useDispatch, useSelector } from 'react-redux';
import {
  candidateDetailsSelection,
  editAuditSelector,
} from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { selectUser } from 'oidc/user.selectors';
import { IAuditItems, IAuditPostData } from 'app/models/Candidate/CandidateProfile';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import moment from 'moment';
import { AuditAddWrapper } from './AuditAddWrapper';
import { defaultFormValues } from './Helpers/AuditAddHelpers';
import {
  associatedPlacementActions,
  associatedPlacementReducer,
  associatedPlacementSliceKey,
} from '@AMIEWEB/Placement/AssociatedPlacements/store/associatedPlacements.redux';
import { useTranslation } from 'react-i18next';
import { PlacementSearchFilterName } from '@AMIEWEB/GlobalSearch/Placement/SearchUtils';
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from '@AMIEWEB/GlobalSearch/SearchResultsPage';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { associatedPlacementsSaga } from '@AMIEWEB/Placement/AssociatedPlacements/store/associatedPlacements.saga';
import { useReadOnly } from 'oidc/userRoles';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContainer: {
    minWidth: '960px',
  },
  dialogContent: {
    padding: 0,
    overflow: 'hidden',
  },
  dialogTitleProps: {
    height: '50px',
    padding: '10px 20px',
  },
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50vh',
  },
}));

export const AuditAddModal = props => {
  const { open, handleClose, isAddAudit, disabled } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useInjectReducer({ key: associatedPlacementSliceKey, reducer: associatedPlacementReducer });
  useInjectSaga({ key: associatedPlacementSliceKey, saga: associatedPlacementsSaga });
  const [cancel, setCancel] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<any>();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const user = useSelector(selectUser);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const editAuditData = useSelector(editAuditSelector);
  const formMethods = useForm({ mode: 'all', defaultValues: defaultFormValues });
  const {
    handleSubmit,
    formState: { dirtyFields, errors, touched },
  } = formMethods;
  const { readOnly } = useReadOnly();

  useEffect(() => {
    const formAuditValues = isAddAudit ? defaultFormValues : editAuditData?.editAuditDataDetails;
    setFormValues(formAuditValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAuditData]);

  useEffect(() => {
    const formAuditValues = isAddAudit ? defaultFormValues : editAuditData?.editAuditDataDetails;
    setFormValues(formAuditValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPlacementsFunction = () => {
    setIsLoading(true);
    dispatch(
      associatedPlacementActions.getPlacementsAction({
        filter: { [PlacementSearchFilterName.cid]: [candidateDetails.travelerId.toString()] },
        pageSize: DEFAULT_PAGE_SIZE,
        pageNumber: DEFAULT_PAGE_NUMBER,
        translation: t,
        sortedColumn: { column: 'placementCreated', direction: 'desc' },
      }),
    );
    setIsLoading(false);
    dispatch(associatedPlacementActions.reset());
  };

  const onSubmit = async (data, e) => {
    const auditTypeID = formValues.pType ? parseInt(formValues.pType.object.ID) : null;
    const auditCategoryID = formValues.pCategory ? parseInt(formValues.pCategory.object.ID) : null;
    const auditSubCategoryID = formValues.pSubCategory ? parseInt(formValues.pSubCategory.object.ID) : null;
    const primaryResponsibleID = formValues.pPrimaryResponsibleParty
      ? parseInt(formValues.pPrimaryResponsibleParty.object.ID)
      : null;
    const statusID = formValues.pStatus ? parseInt(formValues.pStatus.object.ID) : null;
    const actionID = formValues.pAction ? parseInt(formValues.pAction.object.ID) : null;
    const payload: IAuditPostData = {
      travelerId: candidateDetails?.travelerId,
      auditId: isAddAudit ? 0 : editAuditData?.rowData?.auditId,
      auditTypeID: auditTypeID,
      auditCategoryID: auditCategoryID,
      auditSubCategoryID: auditSubCategoryID,
      statusID: statusID,
      auditActionID: actionID,
      personResponsibleID: primaryResponsibleID,
      description: formValues.pDescription ? formValues.pDescription : '',
      holdFileOut: formValues.pHoldFileOut ? formValues.pHoldFileOut : false,
      placementID: formValues.pPlacementID ? formValues.pPlacementID : null,
      currentEmployeeId: user.userInfo?.employeeId,
      auditType: formValues.pType ? formValues.pType.label : null,
      auditCategory: formValues.pCategory ? formValues.pCategory.label : null,
      auditSubCategory: formValues.pSubCategory ? formValues.pSubCategory.label : null,
      status: formValues.pStatus ? formValues.pStatus.label : null,
      auditAction: formValues.pAction.label ? formValues.pAction.label : null,
      personResponsible: formValues.pPrimaryResponsibleParty ? formValues.pPrimaryResponsibleParty.label : null,
      currentEmployeeName: user.userInfo.firstName + user.userInfo.lastName,
    };

    const auditData: IAuditItems = {
      actionable: formValues.pActionable || false,
      auditAction: formValues.pAction?.label,
      auditActionId: actionID,
      auditCategory: formValues.pCategory ? formValues.pCategory.label : null,
      auditCategoryId: auditCategoryID,
      auditId: isAddAudit ? 0 : editAuditData?.rowData?.auditId,
      auditTypeId: auditTypeID,
      auditSubCategory: formValues.pSubCategory ? formValues.pSubCategory.label : null,
      auditSubCategoryId: auditSubCategoryID,
      auditType: formValues.pType?.label,
      description: formValues.pDescription ? formValues.pDescription : '',
      group: formValues.pGroup || null,
      holdFileOut: formValues.pHoldFileOut || false,
      insertDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
      insertedByUser: `${user.userInfo.firstName} ${user.userInfo.lastName}`,
      insertedByUserId: user.userInfo?.employeeId,
      lastUpdated: moment().format('YYYY-MM-DDTHH:mm:ss'),
      lastUpdatedById: user.userInfo?.employeeId,
      lastUpdatedByUser: `${user.userInfo.firstName} ${user.userInfo.lastName}`,
      personResponsible: formValues.pPrimaryResponsibleParty?.label,
      personResponsibleId: primaryResponsibleID,
      placementId: formValues.pPlacementID ? formValues.pPlacementID : null,
      status: formValues.pStatus?.label,
      statusId: statusID,
      travelerId: candidateDetails?.travelerId,
    };
    setIsLoading(true);
    dispatch(candidateDetailActions.postCandidateAudit({ reqBody: payload, auditDetails: auditData }));
    setFormValues(defaultFormValues);
    handleClose();
    setIsLoading(false);
  };

  const onError = (data, e) => {};

  const handleCancel = () => {
    if (!saveDisabled(formValues) || (isAddAudit && (formValues.pType || formValues.pPlacementID))) {
      setCancel(true);
    } else {
      yesAction();
    }
  };
  const yesAction = () => {
    handleClose();
    setFormValues(defaultFormValues);
    setCancel(false);
  };
  const noAction = () => {
    setCancel(false);
  };

  const saveDisabled = useCallback(
    data => {
      if (isAddAudit) {
        return (
          !data?.pType ||
          !data?.pCategory ||
          !data?.pStatus ||
          !data?.pAction ||
          !data?.pPrimaryResponsibleParty ||
          (data?.isSubCategoryRequired && !data.pSubCategory) ||
          (data?.isPlacementRequired && !data.pPlacementID)
        );
      } else {
        if (data?.pType !== editAuditData?.editAuditDataDetails?.pType) {
          return false;
        }

        if (data?.pCategory !== editAuditData?.editAuditDataDetails?.pCategory) {
          if (data?.isSubCategoryRequired) {
            if (data?.pSubCategory === null) return true;
          } else return false;
        }

        if (data?.pPrimaryResponsibleParty !== editAuditData?.editAuditDataDetails?.pPrimaryResponsibleParty) {
          return false;
        }
        if (data?.pSubCategory !== editAuditData?.editAuditDataDetails?.pSubCategory) {
          return false;
        }

        if (data?.pStatus !== editAuditData?.editAuditDataDetails?.pStatus) {
          return false;
        }

        if (data?.pHoldFileOut !== editAuditData?.editAuditDataDetails?.pHoldFileOut) {
          return false;
        }

        if (data?.pAction !== editAuditData?.editAuditDataDetails?.pAction) {
          return false;
        }

        if (data?.pDescription !== editAuditData?.editAuditDataDetails?.pDescription) {
          return false;
        }
        if (data?.pPlacementID !== editAuditData?.editAuditDataDetails?.pPlacementID) {
          return false;
        }

        return true;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAddAudit, formValues],
  );

  const undoChanges = () => {
    setFormValues(editAuditData?.editAuditDataDetails);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        {isLoading ? (
          <CircularProgress className={classes.MuiCircularProgressRoot} />
        ) : (
          <GenericDialog
            variant={'blue'}
            classes={{ paper: classes.dialogContainer }}
            open={open}
            disablePortal
            maxWidth="md"
            disableEscapeKeyDown
            dialogTitleProps={{
              classes: { root: classes.dialogTitleProps },
              text: disabled ? 'View Audit' : isAddAudit ? `Add Audit` : `Edit Audit`,
              closeButton: true,
              expandable: true,
              undoButton: isAddAudit ? false : true,
              onUndoClick: () => undoChanges(),
              onExpandChange: expanded => setIsExpanded(expanded),
            }}
            dialogActions={[
              {
                onClick: handleCancel,
                text: disabled ? 'CLOSE' : 'CANCEL',
                variant: 'contained',
                color: 'tertiary',
              },
              {
                text: `SAVE`,
                variant: 'contained',
                type: 'submit',
                disabled: saveDisabled(formValues),
                hidden: disabled,
              },
            ]}
            dialogContentProps={{
              classes: { root: classes.dialogContent },
            }}
            onClose={handleCancel}
          >
            <AuditAddWrapper
              setFormValues={setFormValues}
              existingFormValues={formValues}
              isExpanded={isExpanded}
              addMode={isAddAudit}
              disabled={!isAddAudit && disabled}
              getPlacementsFunction={getPlacementsFunction}
            />
          </GenericDialog>
        )}
        {cancel && <Cancel openDialog={cancel} handleConfirmAction={yesAction} handleCancelAction={noAction} />}
      </form>
    </FormProvider>
  );
};
