import React from 'react';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  dialogContainer: {
    minWidth: '600px',
    minHeight: '400px',
  },
  dialogContent: {
    padding: 0,
    overflow: 'hidden',
  },
}));

/**
 * Component that renders facility dialog
 */
export const FacilityDialog = ({
  open,
  headerTitle,
  children,
  handleClose,
  customContentStyles,
  custonDialogStyles,
}: {
  open: boolean;
  headerTitle: string;
  children: React.ReactNode;
  handleClose: () => void;
  customContentStyles?: string;
  custonDialogStyles?: string;
}) => {
  const { classes } = useStyles();

  return (
    <GenericDialog
      variant={'blue'}
      classes={{
        paper: custonDialogStyles ? custonDialogStyles : classes.dialogContainer,
      }}
      open={open}
      disablePortal
      disableEnforceFocus
      disableEscapeKeyDown
      dialogTitleProps={{
        text: headerTitle,
        closeButton: true,
        expandable: true,
      }}
      dialogContentProps={{
        classes: { root: customContentStyles ? customContentStyles : classes.dialogContent },
      }}
      onClose={() => handleClose()}
    >
      {children}
    </GenericDialog>
  );
};
