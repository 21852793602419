import React, { ReactNode } from 'react';
import { Box, IconButton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { CustomTooltip } from '@AMIEWEB/Common';

const useStyles = makeStyles()(theme => ({
  container: {
    border: `1px solid ${theme.palette.system.paleGray}`,
    background: theme.palette.components.box.container.backgroundColor,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  fullHeightContainer: {
    height: '100%',
  },
  titleBar: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.system.paleGray}`,
    background: theme.palette.system.offWhite,
    color: theme.palette.components.box.titleBar.color,
    fontSize: 16,
    padding: '12px 12px 12px 12px',
    fontWeight: 500,
  },
  title: {
    paddingLeft: '12px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  clickable: {
    cursor: 'pointer !important',
    '&:hover': {
      borderRadius: '50%',
    },
  },
  children: {
    padding: '12px',
  },
  icon: {
    position: 'relative',
    display: 'inline-block',
    '& img': {
      transition: 'filter 0.3s ease',
      filter: 'grayscale(100%)',
    },
    '&$iconHovered img': {
      filter: 'none',
    },
  },
  onHover: {
    '&:hover': {
      color: theme.palette.system.navyBlue,
    },
  },
  onHoverUnderline: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.system.navyBlue,
      textDecoration: 'underline',
    },
  },
  titleHovered: {
    backgroundColor: theme.palette.system.dimFadedBlack,
  },
}));

export const BoxContainer = ({
  icon,
  title,
  rightIcon,
  iconToolTip,
  onClick,
  children,
  takeFullHeight,
}: {
  icon?: ReactNode;
  title: string;
  rightIcon?: ReactNode;
  iconToolTip?: string;
  onClick?: any;
  children;
  takeFullHeight?;
}) => {
  const { classes, cx } = useStyles();
  const [isHovered, setIsHovered] = React.useState(false);

  const handleRightIconClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Box className={cx(classes.container, { [classes.fullHeightContainer]: takeFullHeight })}>
      <Box
        className={`${classes.titleBar} ${rightIcon && isHovered && classes.titleHovered && classes.onHover}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {(icon && (
          <Box className={`${classes.titleContainer} ${rightIcon && classes.onHover}`}>
            <Box className={`${classes.icon}`}>{icon}</Box>
            <Box
              className={`${classes.title} ${rightIcon && classes.onHoverUnderline}`}
              onClick={event => (rightIcon ? handleRightIconClick() : event.stopPropagation())}
            >
              {title}
            </Box>
          </Box>
        )) || (
          <Box className={`${classes.titleContainer} ${rightIcon && classes.onHover}`}>
            <Box
              className={rightIcon && classes.onHoverUnderline}
              onClick={event => (rightIcon ? handleRightIconClick() : event.stopPropagation())}
            >
              {title}
            </Box>
          </Box>
        )}

        {rightIcon && (
          <CustomTooltip placement="bottom" arrow={true} tooltipContent={iconToolTip}>
            <Box
              onClick={event => (rightIcon ? handleRightIconClick() : event.stopPropagation())}
              className={cx(classes.clickable, classes.icon)}
              sx={{ visibility: 'visible' }}
            >
              <IconButton color="secondary" size="medium" sx={{ padding: 0 }}>
                {rightIcon}
              </IconButton>
            </Box>
          </CustomTooltip>
        )}
      </Box>
      <Box className={classes.children}>{children}</Box>
    </Box>
  );
};
