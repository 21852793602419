/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable i18next/no-literal-string */
import { GridTag, gridStateActions } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import { selectCredPlacementsStatusGrid } from 'app/components/Common/WorkDesk/PipelineChoice/pipelineChoice.selectors';
import { simpleGroupBy } from 'app/helpers/arrayHelpers';
import { HomePage } from 'app/layout/pages/HomePage';
import React, { useEffect, useState } from 'react';
import { createRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { PlacementStatusChips } from './Chips/PlacementStatusChips';
import { PlacementPipeline } from './DataVisualization/PlacementPipeline';
import { PlacementFilter } from './Filters/PlacementFilter';
import { defaultFilterValues } from './Filters/PlacementFilterConfig';
import { createFilterChips, getFilteredData } from './Helpers/PlacementStatusFilterHelper';
import { IFilterChipSelected } from './Models/PlacementStatusInterfaces';
import { placementStatusActions, placementStatusReducer, placementStatusSliceKey } from './Store/PlacementStatus.redux';
import { credentialWorkDeskPlacementStatusSaga } from './Store/PlacementStatus.saga';
import {
  selectCredWorkdeskDataLoaded,
  selectPlacementDeskChips,
  selectPlacementGridData,
  selectPlacementLoading,
  selectPlacementSourceData,
} from './Store/PlacementStatus.selector';
import { getPlacementStatusColumns } from './Table/PlacementStatusHeaders';
import { PlacementStatusList } from './Table/PlacementStatusList';
import { checkForPipelineEquality, placementStatus, placementSubStatuses } from 'app/components/RecruiterWorkDesk/MyPlacementsDesk/utils';
import {
  pipelineChoiceKeys,
  pipelineChoiceActions,
} from 'app/components/Common/WorkDesk/PipelineChoice/pipelineChoice.redux';
import { selectFilters } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { RootState } from 'types';

interface IFilterValues {
  id: any;
  value: any;
  [key: string]: any;
}

interface DateRangeFilters {
  startDate: string;
  endDate: string;
  [key: string]: any;
}

interface IPipeline {
  category: number;
  subcategory: number | null;
  value?: any;
}
interface IFilters {
  placementId: Array<IFilterValues>,
  candidateName: Array<IFilterValues>,
  startDate: DateRangeFilters,
  placementStatus: Array<IFilterValues>,
  clientName: Array<IFilterValues>,
  disciplineSpecialty: Array<IFilterValues>,
  candidateId: Array<IFilterValues>,
}

export const PlacementStatusWrapper = props => {
  useInjectReducer({ key: placementStatusSliceKey, reducer: placementStatusReducer });
  useInjectSaga({ key: placementStatusSliceKey, saga: credentialWorkDeskPlacementStatusSaga });

  const { t } = useTranslation();
  const pipelineSelection = useSelector(selectCredPlacementsStatusGrid);
  const flattenedData = useSelector(selectPlacementGridData);
  const placementsSourceData = useSelector(selectPlacementSourceData);
  const chips = useSelector(selectPlacementDeskChips);
  const loading = useSelector(selectPlacementLoading);
  const appliedFilter = useSelector(selectFilters);
  const hiddenSubmitButton = createRef<HTMLButtonElement>();
  // Filter
  const formMethods = useForm({ defaultValues: defaultFilterValues, shouldUnregister: false });
  const { handleSubmit, setValue, reset } = formMethods;
  const dispatch = useDispatch();
  const gridTag = 'credentialing-workdesk-filters';
  const dataLoaded = useSelector(selectCredWorkdeskDataLoaded);
  const gridPreferences = useSelector((state: RootState) => {
    return state.userPreferenceData.userGridPreferences.find(item => item.id === gridTag);
  });
  const [preferencesLoaded, setPreferencesLoaded] = React.useState<boolean>(false);
  const pipeLineChoice = React.useRef<any>([]);
  const [tableColumns, setTableColumns] = useState<any[]>(getPlacementStatusColumns(t, true));
  const [tableData, setTableData] = useState<any[]>([]);

  // const reArrangeColumns = (notColumns, flexColumns) =>
  //   setTableColumns(
  //     getPlacementStatusColumns(t, flexColumns).reduce(
  //       (resultArray, item) => (notColumns.includes(item.field) ? resultArray : [...resultArray, item]),
  //       [] as any[],
  //     ),
  //   );

  useEffect(() => {
    const selectedGroups = simpleGroupBy(pipelineSelection, 'category');
    const categoriesSelected = Object.keys(selectedGroups);
    if (preferencesLoaded) {
      savePipelineSelection(pipelineSelection);
    };
    setTableColumns(getPlacementStatusColumns(t, true));

    pipelineSelection.length
      ? loadGridData({
          category: [
            // categories are unique selections --> check for unique values in selectedGroups
            ...categoriesSelected.reduce(
              (resultArray, item) =>
                selectedGroups[item].length === 1 && selectedGroups[item][0].subcategory === null
                  ? [...resultArray, selectedGroups[item][0].value]
                  : resultArray,
              [] as string[],
            ),
          ],
          subcategory: [
            ...pipelineSelection.reduce(
              (resultArray, item) => (item.subcategory !== null ? [...resultArray, item.value] : resultArray),
              [] as string[],
            ),
          ],
        })
      : loadGridData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipelineSelection, flattenedData]);

  const setMyCategorySelection = category => {
    switch (category) {
      case placementStatus.offer:
        setTableData([
          ...flattenedData.filter(
            item =>
              item.statusAbbr === placementSubStatuses.obf ||
              item.statusAbbr === placementSubStatuses.obbt ||
              item.statusAbbr === placementSubStatuses.fc ||
              item.statusAbbr === placementSubStatuses.ta,
          ),
        ]);
        break;
      case placementStatus.booked:
        setTableData([
          ...flattenedData.filter(
            item =>
              item.statusAbbr === placementSubStatuses.booked ||
              item.statusAbbr === placementSubStatuses.ba ||
              item.statusAbbr === placementSubStatuses.wgtg ||
              item.statusAbbr === placementSubStatuses.ppw ||
              item.statusAbbr === placementSubStatuses.ba ||
              item.statusAbbr === placementSubStatuses.wgtg ||
              item.statusAbbr === placementSubStatuses.gtg,
          ),
        ]);
        break;
      default:
        setTableData([...flattenedData.filter(item => category.includes(item.placementStatus))]);
    }
  };

  const loadGridData = (selected?: { category: string[]; subcategory: string[] }) => {
    if (selected) {
      if (selected.category.length > 0 && selected.subcategory.length === 0) {
        setMyCategorySelection(selected.category[0]);
      } else {
        setTableData([
          ...flattenedData.filter(
            item => selected.category.includes(item.placementStatus) || selected.subcategory.includes(item.statusAbbr),
          ),
        ]);
      }
      dispatch(
        placementStatusActions.setFilterChips({
          ...chips,
          pipelineSelected: [
            ...pipelineSelection.map(item => ({ label: item.value, enableDelete: true, value: item })),
          ],
        }),
      );
    } else {
      setTableData([...flattenedData]);
      dispatch(
        placementStatusActions.setFilterChips({
          ...chips,
          pipelineSelected: [],
        }),
      );
    }
  };

  const saveFilterPreferences = (values: IFilters | any, pipeline?: Array<IPipeline> | undefined) => {
    const filters = values || appliedFilter?.filters;
    const pipelineSelected = pipeline || pipeLineChoice.current;
    const preferenceData = {
      id: gridTag,
      value: {
        pipelineSelected: pipelineSelected,
        filtersApplied: filters,
      },
    };
    filters && dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
    dispatch(
      pipelineChoiceActions.setPipelineChoice({
        key: pipelineChoiceKeys.CRED_PLACEMENT_STATUS_WDG,
        selection: pipelineSelected,
      }),
    );
  };

  const savePipelineSelection = pipelineSelection => {
    pipeLineChoice.current = pipelineSelection;
    const pipelineChoice = gridPreferences?.value?.pipelineSelected;
    const isPipelineEqual = checkForPipelineEquality(pipelineSelection, pipelineChoice);
    const preferenceData = {
      id: gridTag,
      value: {
        ...gridPreferences?.value,
        pipelineSelected: pipelineSelection,
      },
    };
    !isPipelineEqual && dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
  };
  //EVENTS
  const onApplyFilter = (values, selectedChips?: IFilterChipSelected, pipelineSelected?) => {
    dispatch(gridStateActions.setFilterAttributes({ filterObject: Object.entries(values) }));
    const filteredData = getFilteredData(values, placementsSourceData, selectedChips);

    dispatch(
      placementStatusActions.setFilterChips({
        ...chips,
        ...createFilterChips(values, !!selectedChips ? selectedChips : undefined),
      }),
    );
    dispatch(placementStatusActions.setFilteredData(filteredData));
    saveFilterPreferences(values, pipelineSelected);
  };

  const sidePanelItems = [
    {
      title: 'Quick View',
      component: <div style={{ minWidth: 236 }} />,
    },
    { title: 'Advanced Filter', component: <div /> },
  ];

  useEffect(() => {
    dispatch(
      pipelineChoiceActions.setPipelineChoice({
        key: pipelineChoiceKeys.CRED_PLACEMENT_STATUS_WDG,
        selection: [],
      }),
    );
    dispatch(placementStatusActions.credentialingWorkDeskPlacementStatusData({ userId: 0 }));
    reset({
      ...defaultFilterValues,
    });

    //Set back to initial state when component un-mounted.
    return () => {
      dispatch(placementStatusActions.reset());
      dispatch(gridStateActions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const appliedFilters = gridPreferences?.value?.filtersApplied;
    const pipelineSelected = gridPreferences?.value?.pipelineSelected;

    if ((appliedFilters || pipelineSelected?.length > 0) && dataLoaded) {
      if (!preferencesLoaded) {
        setPreferencesLoaded(true)
        dispatch(placementStatusActions.credentialingWorkDeskPlacementStatusData({ userId: 0 }));
        if (!!appliedFilters) {
          dispatch(gridStateActions.setGrid(GridTag.CredentialsGrid));
          onApplyFilter(appliedFilters, undefined, pipelineSelected);
          dispatch(gridStateActions.setFilterAttributes({ filterObject: Object.entries(appliedFilters) }));
          Object.keys(appliedFilters).forEach(key => {
            setValue(key, appliedFilters[key], {
              shouldDirty: appliedFilters?.placementId?.length > 0 && key === 'placementId',
            });
          });
        }
        if (pipelineSelected?.length > 0) {
          pipeLineChoice.current = pipelineSelected;
          dispatch(
            pipelineChoiceActions.setPipelineChoice({
              key: pipelineChoiceKeys.CRED_PLACEMENT_STATUS_WDG,
              selection: pipelineSelected,
            }),
          );
        } else {
          dispatch(
            pipelineChoiceActions.setPipelineChoice({
              key: pipelineChoiceKeys.CRED_PLACEMENT_STATUS_WDG,
              selection: [],
            }),
          );
        }
      }
    }
  }, [gridPreferences, preferencesLoaded, dataLoaded]);

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(filter => onApplyFilter(filter))}>
          <HomePage
            loading={loading}
            title={t('home.credentialingWorkDesk.placementTitle')}
            stickIconArray={[3, 2]}
            dataVisualization={<PlacementPipeline stickChosen={null} />}
            filters={<PlacementFilter onApplyFilter={onApplyFilter} chips={chips} />}
            table={<PlacementStatusList tableColumns={tableColumns} tableData={tableData} />}
            chips={<PlacementStatusChips chips={chips} onApplyFilter={onApplyFilter} />}
            sidePanelItems={sidePanelItems}
          />
          <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
        </form>
      </FormProvider>
    </>
  );
};
