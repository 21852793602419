import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { IconButton, materialUiXGrid } from "amn-ui-core";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import React from 'react';

export const CustomDetailPanelToggle = (props: { param: GridRenderCellParams, setVersionOpen }) => {
    const { id, value: isExpanded } = props.param;
    const api = materialUiXGrid.useGridApiContext();
    
    return (
      <IconButton
        color="primary"
        size="small"
        aria-label={isExpanded ? 'Close' : 'Open'}
        onClick={() => {
          api?.current.toggleDetailPanel(id)
          props.setVersionOpen(false)
        }}
      >
        {api?.current.getExpandedDetailPanels().find(item => item === id) ? (
          <ExpandLessOutlinedIcon/>
        ) : (
          <ExpandMoreOutlinedIcon/>
        )}
      </IconButton>
    );
  }