import ArrowRightAltSharpIcon from '@mui/icons-material/ArrowRightAltSharp';
import { makeStyles } from 'tss-react/mui';
import React, { createContext } from 'react';
import { IDirection } from '../Constants';
import Error from 'app/assets/images/IconImages/Error.svg';

export const NotificationContext = createContext<any | null>(null);

interface IConversationCountProps {
  direction?: string;
  outboundCount?: string;
  inboundCount?: string;
  channel?: string;
  undeliveredCount?: number | null;
}

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'contents',
  },
  outboundArrow: {
    transform: 'rotate(-45deg)',
  },
  inboundArrow: {
    transform: 'rotate(135deg)',
  },
  inboundSpacing: {
    marginLeft: '10px',
  },
  warning: {
    marginLeft: '10px',
    marginBottom: '3px',
    marginRight: '5px',
  },
}));

export function ConversationCount(props: IConversationCountProps) {
  const { channel = 'Conversation', direction, outboundCount, inboundCount, undeliveredCount } = props;
  const { classes, cx } = useStyles();

  return (
    <div className={classes.root}>
      {channel === 'Conversation' ? (
        <>
          <ArrowRightAltSharpIcon color="primary" className={classes.outboundArrow} />
          {outboundCount}
          <ArrowRightAltSharpIcon color="primary" className={cx(classes.inboundArrow, classes.inboundSpacing)} />
          {inboundCount}
          {undeliveredCount && undeliveredCount > 0 ? (
            <>
              <img src={Error} className={classes.warning} />
              {undeliveredCount > 99 ? '99+' : undeliveredCount}
            </>
          ) : (
            <></>
          )}
        </>
      ) : direction === IDirection.Outbound ? (
        <ArrowRightAltSharpIcon color="primary" className={classes.outboundArrow} />
      ) : direction === IDirection.Inbound ? (
        <ArrowRightAltSharpIcon color="primary" className={classes.inboundArrow} />
      ) : null}
    </div>
  );
}
