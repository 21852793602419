import { XGrid } from 'app/components/Common/XGrid/XGrid';
import { DrawerFitted } from 'app/components/Common/Drawer/Drawer';
import { gridStateActions, GridTag } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import { calculateComponentRemainingHeight } from 'app/components/Common/XGrid/Hooks/useGridHeight';
import { CandidateDrawerPreviewer } from 'app/components/GlobalSearch/Candidate/CandidateDrawerPreviewer';
import { getHomePageSupportComponentHeight } from 'app/layout/pages/HomePage';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowResize } from 'utils/customHooks/useWindowResize';
import { globalSearchActions } from 'store/redux-store/global-search/slice';
import { selectSearchResults } from 'store/redux-store/global-search/selectors';
import { selectGridSelection } from 'app/components/Common/Grid/GridSelection/GridSelection.selector';
import {
  useEnableBulkEmail,
  useEnableBulkSms,
  useEnableEmailCommunication,
  useEnableSmsCommunication,
} from 'oidc/CommunicationEnabler';
import { useBulkCommunication } from 'app/components/Notification/Common/BulkCommunication';
import { navigationProfilesAction } from '@AMIEWEB/GlobalSearch/store/NavigationProfiles.redux';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { materialUiXGrid, useTheme } from 'amn-ui-core';
import { SearchGrid } from 'app/constants/FeatureFlags/smsKeys';

export const PlacementStatusList = props => {
  const apiRef = materialUiXGrid.useGridApiRef();
  const { tableData, tableColumns } = props;
  const MAX_ALLOWED_EMAIL_SELECTION = 250;
  const MAX_ALLOWED_SMS_SELECTION = 150;
  const CREATE_MESSAGE_WARNING = `Maximum of ${MAX_ALLOWED_SMS_SELECTION} recipients permitted. Please reduce your selections and try again.`;
  const CREATE_EMAIL_WARNING = `Maximum of ${MAX_ALLOWED_EMAIL_SELECTION} recipients permitted. Please reduce your selections and try again.`;
  const { items, drawerData } = useSelector(selectSearchResults);
  const { selectedData } = useSelector(selectGridSelection);
  const dispatch = useDispatch();
  const [gridHeight, setGridHeight] = React.useState<number | undefined>(50);
  const updateGridHeight = () =>
    setGridHeight(calculateComponentRemainingHeight('home-page-paper', getHomePageSupportComponentHeight, 70));
  useWindowResize(updateGridHeight, updateGridHeight);
  // X-Grid Sorting
  const defaultSort = [{ field: 'startDate', sort: 'asc' }];
  const [xGridSortModel, setxGridSortModel] = React.useState<any>(defaultSort);
  // maintain the previous sort model if the currently sorted column is removed from the grid
  const [prevXGridSortModel, setPrevXGridSortModel] = React.useState<any>(defaultSort);
  const onSortModelChange = model => {
    setxGridSortModel(model);
    setPrevXGridSortModel(model);
  };
  const theme = useTheme();
  const enableSmsCommunication = useEnableSmsCommunication();
  const enableEmailCommunication = useEnableEmailCommunication();
  const enableBulkSms = useEnableBulkSms(SearchGrid.credentialingWorkDesk);
  const enableBulkEmail = useEnableBulkEmail(SearchGrid.credentialingWorkDesk);
  const { handleCreateEmail, handleSmsSelection, handleSelectedSmsDataFilter, enableSmsCreate } =
    useBulkCommunication();
  const activate = enableSmsCreate();

  React.useEffect(() => {
    if (prevXGridSortModel?.length > 0 && tableColumns.map(item => item.field).includes(prevXGridSortModel[0].field)) {
      setxGridSortModel([...prevXGridSortModel]);
    } else if (tableColumns.map(item => item.field).includes(defaultSort[0].field)) {
      setxGridSortModel([...defaultSort]);
    } else {
      setxGridSortModel(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableColumns]);

  useEffect(() => {
    dispatch(
      gridStateActions.setGridState({
        gridTag: GridTag.CredentialsPlacementStatusGrid,
        pageSize: 20,
        pageNumber: 1,
        previewItemId: -1,
        showPreviewDialog: false,
        sortedColumn: { column: 'startDate', direction: 'desc' },
        filterAttributes: undefined,
      }),
    );
    return () => {
      dispatch(gridStateActions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    dispatch(gridStateActions.setPageNumber(1));
  }, [dispatch, tableData]);

  const leftActions = React.useMemo(() => {
    if (tableData?.length > 0) {
      return [
        {
          title: 'global.xgrid.toolbar.email',
          Icon: EmailOutlinedIcon,
          disabled:
            !enableEmailCommunication ||
            !enableBulkEmail ||
            selectedData?.length < 1 ||
            selectedData?.length > MAX_ALLOWED_EMAIL_SELECTION,
          onClick: handleCreateEmail,
          tooltipProps: {
            tooltipContent: CREATE_EMAIL_WARNING,
            disabled: selectedData?.length <= MAX_ALLOWED_EMAIL_SELECTION,
          },
        },
        {
          title: 'global.xgrid.toolbar.sms',
          Icon: ChatOutlinedIcon,
          disabled:
            !activate ||
            !enableBulkSms ||
            !enableSmsCommunication ||
            handleSelectedSmsDataFilter()?.length < 1 ||
            handleSelectedSmsDataFilter()?.length > MAX_ALLOWED_SMS_SELECTION,
          onClick: handleSmsSelection,
          tooltipProps: {
            tooltipContent: CREATE_MESSAGE_WARNING,
            disabled: handleSelectedSmsDataFilter()?.length <= MAX_ALLOWED_SMS_SELECTION,
          },
        },
      ];
    }
  }, [
    CREATE_EMAIL_WARNING,
    CREATE_MESSAGE_WARNING,
    activate,
    enableEmailCommunication,
    enableSmsCommunication,
    handleCreateEmail,
    handleSelectedSmsDataFilter,
    handleSmsSelection,
    selectedData?.length,
    tableData?.length,
  ]);

  const handleStateChange = state => {
    const clonedTable = _.cloneDeep(tableData);
    const searchItem = state.sorting.sortedRows.reduce((resultArray, i) => {
      const element = clonedTable.find(j => j.id === i);
      return element ? [...resultArray, element] : [...resultArray];
    }, []);

    if (searchItem.length === tableData.length) {
      searchItem.map((item, index) => (item.indexCounter = index));
    }
  };

  useEffect(() => {
    const sortedRows = apiRef.current.getSortedRows();
    dispatch(
      globalSearchActions.setSearchResults({
        items: sortedRows,
        count: sortedRows?.length,
        miscellaneous: {},
      }),
    );
    dispatch(
      navigationProfilesAction.setNavigationData({
        items: sortedRows,
      }),
    );
  }, [xGridSortModel]);

  return (
    <div>
      <XGrid
        apiRef={apiRef}
        columns={tableColumns}
        rows={tableData || []}
        autoHeight={false}
        height={gridHeight}
        rowsPerPageOptions={[20, 50, 100, 250]}
        disableSelectionOnClick
        loading={false}
        rowCount={(tableData || []).length}
        sortModel={xGridSortModel}
        onSortModelChange={onSortModelChange}
        onStateChange={handleStateChange}
        checkboxSelection
        toolbarProps={{
          leftActions: leftActions,
        }}
      />
      {drawerData?.open && (
        <DrawerFitted
          onDrawerClose={() => {
            dispatch(globalSearchActions.setDrawerData({ open: false, data: undefined }));
          }}
          width={400}
          top={0}
          backgroundColor={theme.palette.framework.system.whisper}
          open={drawerData?.open || false}
        >
          {drawerData?.data ? <CandidateDrawerPreviewer isSearchGrid={true} isWorkDesk={true} /> : null}
        </DrawerFitted>
      )}
    </div>
  );
};
