import { Controller, useFormContext } from 'react-hook-form';
// import { ICustomOption } from '@AMIEWEB/Placement/CreatePlacement/CustomComponents/CustomTypeAhead';
import { CircularProgress, IconButton, Theme, Typography } from 'amn-ui-core';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useEffect, useMemo, useState } from 'react';
import { SessionKey, appendSessionValue, getSessionValue } from 'utils/customHooks/sessionStorage/sessionHelpers';
import { getFilterRequest, revalidateChipSelection } from '@AMIEWEB/GlobalSearch/helper';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';

import { OrderBonusChips } from './Search/OrderBonusChips';
import { OrderSearchFilter } from './Search/OrderSearchFilter';
import { OrderSearchGrid } from './Search/OrderSearchGrid';
import SearchGridWrapper from 'app/layout/pages/SearchGridWrapper';
import SearchIcon from '@mui/icons-material/Search';
import { SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import { colors } from 'styles/styleVariables';
import { getOrderOptions } from 'app/services/OrderServices/OrderServices';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { globalSearchActions } from 'store/redux-store/global-search/slice';
import { gridSelectionActions } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.redux';
import { GridTag, gridStateActions } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { makeStyles } from 'tss-react/mui';
import { selectGridState } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';
import { throttle } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ICustomOption, OrderBonusCopyOrderSearch } from '../CustomComponents/OrderBonusCopyOrderSearch';

const useStyles = makeStyles()((theme: Theme) => ({
  searchButton: {
    '& .MuiSvgIcon-root': {
      color: `${colors.white}`,
      backgroundColor: `${colors.amnLightGrey}`,
      borderRadius: '6px 6px 6px 6px',
      width: '1.3em',
      height: '1.3em',
    },
    '& .MuiTypography-root': {
      color: `${colors.darkText}`,
      paddingLeft: '10px',
    },
    '&:hover': { background: 'none' },
    '&:hover .MuiSvgIcon-root ': {
      backgroundColor: `${colors.amnLightBlue}`,
    },
    '&:hover .MuiTypography-root': {
      color: `${colors.amnLightBlue}`,
    },
  },
  validationLoader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: '50%',
  },
}));

export const CopyOrderBonusForm = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { control } = useFormContext();
  const { classes } = useStyles();
  const [showHistory, setShowHistory] = React.useState<boolean>(false);
  const [options, setOptions] = useState<ICustomOption[]>([]);
  const { promiseInProgress: orderPromiseInProgress } = usePromiseTracker({ area: 'order-throttle-area', delay: 0 });
  const { promiseInProgress: isCopyOrderBonusCallingAPI } = usePromiseTracker({ area: 'copy-order-bonus', delay: 0 });

  const { copyOrderBonusProps, setCopyOrderBonusProps } = props;
  const [defaultOrderColumn] = useState<any>({ column: 'orderId', direction: 'desc' });
  const gridState = useSelector(selectGridState);

  const onApplyFilter = (values, newFilterChips?) => {
    const filters = getFilterRequest(values, newFilterChips);
    const selectedChipFilters =
      newFilterChips || revalidateChipSelection(values, gridState.filterAttributes?.selectedChipFilters || []);
    dispatch(
      gridStateActions.setFilterAttributes({
        ...gridState.filterAttributes,
        filterObject: Object.entries(values),
        selectedChipFilters,
      }),
    );
    dispatch(gridStateActions.setPageNumber(1));
    dispatch(
      globalSearchActions.getSearchResults({
        searchType: SearchType.order,
        keyword: '',
        pageNumber: 1,
        pageSize: 250,
        sortedColumn: defaultOrderColumn,
        filter: filters || {},
        translation: t,
      }),
    );
    setCopyOrderBonusProps({ ...copyOrderBonusProps, disableCopyButton: true });
  };

  useEffect(() => {
    setCopyOrderBonusProps({
      ...copyOrderBonusProps,
      searchScreen: false,
      searchResult: { orderId: null, facility: null },
      defaultOption: null,
    });
    dispatch(gridStateActions.setGrid(GridTag.CopyOrderBonusGrid));
    return () => {
      dispatch(gridStateActions.reset());
      dispatch(globalSearchActions.reset());
      dispatch(globalActions.closeBanner());
      dispatch(
        gridSelectionActions.setGridSelections({
          selectedData: [],
        }),
      );
    };
  }, []);

  const throttleOrderService = useMemo(
    () => throttle(request => trackPromise(fetchOrderOptions(request), 'order-throttle-area'), 2000),
    [],
  );

  const fetchOrderOptions = async request => {
    setShowHistory(false);
    await getOrderOptions(request).then(res =>
      setOptions(
        res.map(
          opt =>
            ({
              primaryLabel: opt.orderId.toString(),
              secondaryLabel: opt.facility,
            } as ICustomOption),
        ),
      ),
    );
  };

  const setSessionOptions = () => {
    setShowHistory(true);
    const newOpts = getSessionValue(SessionKey.recentOrders)
      ?.filter(x => x.primaryLabel)
      ?.map(
        opt =>
          ({
            primaryLabel: opt?.primaryLabel,
            secondaryLabel: opt?.secondaryLabel,
          } as ICustomOption),
      );
    if (newOpts) {
      setOptions(newOpts);
    }
    return newOpts;
  };

  useEffect(() => {
    setSessionOptions();
  }, [copyOrderBonusProps?.defaultOption]);

  const handleSearchDialogOPen = () => {
    setCopyOrderBonusProps({ ...copyOrderBonusProps, searchScreen: true });
  };
  return (
    <>
      <LayoutGrid container>
        <LayoutGrid container item xs={12} direction="column">
          {!copyOrderBonusProps?.searchScreen && isCopyOrderBonusCallingAPI && (
            <CircularProgress className={classes.validationLoader} />
          )}
          {!copyOrderBonusProps?.searchScreen && (
            <LayoutGrid
              container
              direction="row"
              style={{ marginTop: 40, paddingLeft: 40, display: copyOrderBonusProps?.searchScreen ? 'none' : 'flex' }}
            >
              <LayoutGridItem item xs={8}>
                <Controller
                  name="orderID"
                  defaultValue={copyOrderBonusProps?.defaultOption || null}
                  control={control}
                  render={({ ref, onChange, ...rest }) => (
                    <>
                      <OrderBonusCopyOrderSearch
                        style={{ backgroundColor: 'rgba(0,0,0, 0.04)' }}
                        onInputChange={throttleOrderService}
                        options={options}
                        popUpIcon={true}
                        variant={'filled'}
                        loading={orderPromiseInProgress}
                        searchOnCharacterCount={3}
                        label={t('order.orderBonusTab.orderId')}
                        onChange={(event, newValue) => {
                          onChange(newValue);
                          if (newValue) {
                            appendSessionValue(SessionKey.recentOrders, newValue);
                            setCopyOrderBonusProps({
                              ...copyOrderBonusProps,
                              searchResult: { orderId: newValue.primaryLabel, facility: newValue?.secondaryLabel },
                              disableCopyButton: false,
                            });
                          } else {
                            setCopyOrderBonusProps({
                              ...copyOrderBonusProps,
                              searchResult: { orderId: null, facility: null },
                              disableCopyButton: true,
                            });
                          }
                        }}
                        {...rest}
                        showHistory={showHistory}
                        resetHistory={() => setSessionOptions()}
                        historyTitle={t('order.orderBonusTab.recentOrders')}
                      />
                    </>
                  )}
                />
              </LayoutGridItem>
              <LayoutGridItem item xs={4}>
                <IconButton
                  disableTouchRipple
                  disableFocusRipple
                  size="medium"
                  className={classes.searchButton}
                  onClick={handleSearchDialogOPen}
                >
                  <SearchIcon />
                  <Typography>{t('order.orderBonusTab.orders')}</Typography>
                </IconButton>
              </LayoutGridItem>
            </LayoutGrid>
          )}
          {copyOrderBonusProps?.searchScreen && (
            <LayoutGrid
              container
              direction="row"
              style={{ marginTop: 40, paddingLeft: 40, display: !copyOrderBonusProps?.searchScreen ? 'none' : 'flex' }}
            >
              <LayoutGridItem item xs={12}>
                <SearchGridWrapper
                  filters={<OrderSearchFilter onApplyFilter={onApplyFilter} />}
                  chips={<OrderBonusChips onApplyFilter={onApplyFilter} />}
                  table={<OrderSearchGrid {...props} />}
                  loading={false}
                  tableMinHeight={538}
                />
              </LayoutGridItem>
            </LayoutGrid>
          )}
        </LayoutGrid>
      </LayoutGrid>
    </>
  );
};
