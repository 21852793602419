import React, { useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, ClickAwayListener, Fade, Grid, Paper, Popper } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { EnableAppScroll } from 'app/layout/Layout';
import { useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';
import _, { isArray } from 'lodash';
import { CustomizedLicenseStateTypeAhead } from '@AMIEWEB/Order/OrderCreationForm/Components/OrderLicense/CustomizedLicenseTypeAhead';
import { ISelection } from 'app/ComponentLibrary/Filter/TypeAheadDropdown/CustomTypeAhead';

const useStyles = makeStyles<{
  isSelected: boolean;
  open: boolean;
  anchorRef: boolean;
  removeActionContainer: boolean;
}>()((theme, { isSelected, open, anchorRef, removeActionContainer }) => ({
  root: {},
  outlineAnchor: {
    borderRadius: 4,
    width: '100%',
    minHeight: '36.7px',
    justifyContent: 'space-between',
    textTransform: 'none',
    color: open ? '#52429A' : isSelected ? '#555555' : '#C0C0C0',
    fontSize: 12,
    fontWeight: 400,
    '&:hover, &:focus': {
      backgroundColor: '#ffffff',
    },
    visibility: anchorRef ? 'hidden' : undefined,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    '&:hover': {
      borderColor: 'black',
    },
  },
  popper: {
    marginTop: 4,
    zIndex: 5000,
  },
  paperDropdown: {
    boxShadow: '0px 2px 8px #0000004D',
  },
  headerContainer: {
    minWidth: 250,
    padding: '12px 20px 6px',
  },
  headerContainerNoSearchBoxSingleOption: {
    minWidth: 250,
    padding: '0px 20px 0px',
  },
  actionContainer: {
    visibility: removeActionContainer ? 'hidden' : undefined,
    backgroundColor: '#ffffff',
    borderTop: removeActionContainer ? undefined : '1px solid #cccccc',
    padding: removeActionContainer ? 0 : '6px 20px',
    height: removeActionContainer ? 0 : undefined,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  button: {
    fontSize: 14,
    textTransform: 'none',
    '&:hover, &:focus': {
      background: 'none',
    },
  },

  inActive: {
    color: '#888888',
  },
  clearAllButton: {
    padding: 0,
  },
  actionButton: {
    padding: '6px 0px',
  },
  countTag: {
    color: '#555555',
    fontSize: 14,
    fontWeight: 600,
  },
}));

export const AVOID_CLICK_AWAY_LABEL = 'avoid-click-away-type-ahead-drop-down';

export const OrderLicenseTypeAheadDropdown = props => {
  const {
    isMultiSelect,
    value: selected,
    selectAllValue,
    options: providedOptions,
    filterName,
    isDisabled,
    /** Prop to utilize external anchor element */
    anchorRef,
    removeActionContainer = false,
    customClass,
    hasControlError,
    isNested = false,
    searchBoxHiddenWithSingleOption = false,
    fallbackPlacements,
    minWidth,
    isNewOrderCreation = false,
    showDefaultIsRequired = true,
  } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [options, setOptions] = useState<ISelection[]>([]);
  const trackerArea = `${props.name}-async-typeAhead`;
  //   const { promiseInProgress } = usePromiseTracker({ area: trackerArea, delay: 0 });
  const [selectedCountTag, setSelectedCountTag] = useState<string>(filterName);
  const [selectedStates, setSelectedStates] = useState<any[]>([]);

  const open = Boolean(anchorEl);
  const { classes } = useStyles({
    isSelected: isMultiSelect ? selected && selected.length > 0 : selected !== null,
    open,
    anchorRef,
    removeActionContainer,
  });

  const id = open ? `custom-type-ahead-${props.name}` : undefined;

  const fetchOptions = async request => {
    const response = await props.fetchOptions(request);
    setOptions(
      (response || []).map(opt => ({
        id: opt.value,
        name: opt.name,
        value: opt.value,
      })),
    );
  };

  const onApplyEvent = () => {
    if (isArray(selectedStates)) {
      const selectedValues = _.cloneDeep(selectedStates).sort(function (a, b) {
        return a?.state?.id === b?.state?.id ? 0 : a?.state?.id > b?.state?.id ? 1 : -1;
      });
      props.apply(selectedValues);
    } else {
      props.apply(selectedStates);
    }

    closeActions();
  };

  const closeActions = () => {
    setAnchorEl(null);
  };

  const cancelChanges = () => {
    setSelectedStates(selected);
    closeActions();
  };

  const clearAll = () => {
    setSelectedStates([]);
  };

  const handleOpen = event => {
    if (!open) {
      trackPromise(fetchOptions(undefined), trackerArea);
      setAnchorEl(anchorRef ? anchorRef.current : event.currentTarget);
    } else {
      if (props.hideApply) {
        onApplyEvent();
        return;
      }
      cancelChanges();
    }
  };

  const onClickAway = event => {
    if (event.target.type !== 'submit') {
      setSelectedStates(selected);
      closeActions();
    }
  };

  useEffect(
    () => {
      if (isMultiSelect && options && selectedStates?.length !== options.length)
        setSelectedCountTag(
          isNewOrderCreation
            ? selected?.length > 0
              ? `${filterName} (${selected.length})`
              : `${filterName}`
            : selected?.length > 0
            ? `(${selected.length}) ${filterName} `
            : `${filterName}`,
        );
      return EnableAppScroll();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(selected), JSON.stringify(options)],
  );

  useEffect(() => {
    setSelectedStates(selected);
  }, [selected]);

  useEffect(() => {
    if ((providedOptions || []).length > 0 && !props.fetchOptions) {
      setOptions(providedOptions);
    }
  }, [props.fetchOptions, providedOptions]);

  return (
    <Box>
      <div className={classes.root}>
        <Button
          disableRipple
          variant="outlined"
          style={{
            borderColor: hasControlError ? '#D90000' : '',
            color: hasControlError ? '#D90000' : '',
            height: isNested ? '10px' : '',
            minWidth: minWidth ? minWidth : '',
          }}
          aria-describedby={id}
          disabled={isDisabled}
          endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          classes={{ root: classes.outlineAnchor }}
          onClick={handleOpen}
          id={props.anchorAccesorid}
        >
          {isNested ? (
            <pre style={{ font: 'normal normal normal 12px Roboto' }}>{selectedCountTag}</pre>
          ) : (
            selectedCountTag
          )}
        </Button>
      </div>
      <Popper
        className={customClass ? `${customClass}` : `${classes.popper}`}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={[
          {
            name: 'flip',
            options: {
              fallbackPlacements: fallbackPlacements,
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={onClickAway}>
            <Fade {...TransitionProps} in={open} timeout={300}>
              <Paper aria-label={id} classes={{ root: classes.paperDropdown }}>
                <Grid
                  item
                  container
                  justifyContent={isMultiSelect ? 'space-between' : 'flex-end'}
                  alignItems="center"
                  classes={{
                    root: searchBoxHiddenWithSingleOption
                      ? classes.headerContainerNoSearchBoxSingleOption
                      : classes.headerContainer,
                  }}
                >
                  {isMultiSelect ? (
                    <div className={classes.countTag}>{`${
                      selectAllValue &&
                      options &&
                      options?.length &&
                      selectedStates &&
                      selectedStates?.length &&
                      selectedStates?.length === options?.length
                        ? options?.length - 1
                        : selectedStates?.length || 0
                    } ${t('search.filter.Selected')}`}</div>
                  ) : null}
                  {!props.hideClearAll ? (
                    <Button
                      className="clear-all-button"
                      aria-label="clear-all-button"
                      variant="text"
                      color="primary"
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      onClick={() => clearAll()}
                      disabled={!isMultiSelect}
                      classes={{ text: ` ${classes.button} ${classes.clearAllButton}` }}
                    >
                      {t('search.filter.clearAll')}
                    </Button>
                  ) : null}
                </Grid>
                <CustomizedLicenseStateTypeAhead
                  options={options}
                  selectedValues={selectedStates}
                  setSelectedStates={setSelectedStates}
                  showDefaultIsRequired={showDefaultIsRequired}
                />
                {!props.hideApply ? (
                  <div className={classes.actionContainer}>
                    <Grid item container justifyContent="flex-end" alignItems="center">
                      <Button
                        className="clear-all-button"
                        aria-label="apply-button"
                        variant="text"
                        color="primary"
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        onClick={() => onApplyEvent()}
                        classes={{ text: `${classes.button} ${classes.actionButton}` }}
                        id={props.hiddenApplyBtn}
                      >
                        {t('search.filter.apply')}
                      </Button>
                      <Button
                        className="clear-all-button"
                        aria-label="cancel-button"
                        variant="text"
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        onClick={() => cancelChanges()}
                        classes={{ text: `${classes.button} ${classes.actionButton} ${classes.inActive}` }}
                      >
                        {t('search.filter.cancel')}
                      </Button>
                    </Grid>
                  </div>
                ) : null}
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
};
