import { Grid, Typography, Divider, IconButton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import { RateElementsTableCell } from './RateElementsTableCell';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles<{ alignPopper: boolean }>()((theme, props) =>
({
  headerTextStyle: {
    fontWeight: 500,
  },
  divider: {
    height: '50%',
    margin: '0 2px',
    width: '2px',
    display: 'flex',
    alignSelf: 'center',
  },
  titleStyle: {
    height: '56px',
  },
  tableRow: {
    minWidth: 'max-content',
    flexWrap: 'nowrap',
  },
  shiftColumn: {
    display: 'flex',
    flexDirection: 'row',
    width: '152px',
    minWidth: '130px',
  },
  columnTitle: {
    paddingLeft: '11px',
    alignItems: 'center',
    display: 'flex',
    fontSize: '12px',
  },
  columnContent: {
    color: '#333333',
    alignItems: 'center',
    height: '68px',
  },
  closeBtn: {
    padding: '3px',
    marginLeft: 'auto',
    '& .MuiIconButton-label': {
      marginLeft: 'auto',
      width: 'auto',
    },
  },
}));

export const RateElementsTableRow = (props: any) => {
  const { classes } = useStyles({ alignPopper: props.shiftCount > 3 });
  const [highlightedRow, setHighlightedRow] = useState<boolean>();
  
  return (
    <Grid container className={classes.tableRow} style={{ backgroundColor: highlightedRow && '#E9EDF4' }}>
      {/* Row 1, First Column */}
      <Grid
        container
        item
        direction="column"
        className={classes.shiftColumn}
        onMouseEnter={() => setHighlightedRow(true)}
        onMouseLeave={() => setHighlightedRow(false)}
      >
        <Grid container xs={12} className={classes.columnContent}>
          <Grid
            container
            item
            classes={{ root: `${classes.titleStyle}` }}
            style={{ backgroundColor: highlightedRow ? '#E9EDF4' : '#FFF', width: '100%', flexWrap: 'nowrap' }}
          >
            <Typography className={classes.columnTitle} classes={{ root: classes.headerTextStyle }}>
              {props.rateElement.name}
            </Typography>
            <IconButton
              color="primary"
              disableRipple={true}
              className={classes.closeBtn}
              onClick={() => {
                props.deleteRateElement(props.rateElement)
              }}
              style={{ display: highlightedRow ? 'flex' : 'none' }}
            >
              {highlightedRow && <CloseIcon style={{ marginLeft: 'auto', padding: '3px' }} />}
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Divider
        style={{ opacity: '0' }}
        classes={{ root: classes.divider }}
        orientation="vertical"
        variant="middle"
      />
      {/* Row 1, Columns 2:n-1 */}
      {props.rateElement?.shifts?.map((item, index) => {
        return (
          <>
            <RateElementsTableCell
              shiftDetails={item}
              title={props.rateElement.attr}
              isDeletable={true}
              rateElement={props.rateElement}
              setDeleteColumn={props.setDeleteColumn}
              isColumnHeader={false}
              setHighlightedCol={props.setHighlightedCol}
              columnIndex={index}
              highlightCol={index === props.highlightedCol}
              value={item.inputValue ?? null}
              setRow={props.setRow}
              setError={props.setError}
            />
            <Divider
              style={{ opacity: '0' }}
              classes={{ root: classes.divider }}
              orientation="vertical"
              variant="middle"
            />
          </>
        );
      })}
    </Grid>
  );
}