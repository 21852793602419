import { Credentials, IAttachments, ICredentials } from 'app/models/Credentialing/Credentials';
import { ICustomHeader, gridControlType } from 'app/models/Grid';
import { createCredential, editCredential } from 'app/services/CredentialingServices';
import React from 'react';
import moment from 'moment';
import { CredentialsGridType } from './CredentialsGrid';
import _ from 'lodash';
import { candidateDetailActions } from '../../Profile/CandidateDetails.redux';
import {
  addAttachment,
  deleteAttachment,
  deleteCredential,
  getModels,
} from 'app/services/CredentialingServices/CredentialingServices';
import {
  gridTypeToObject,
  isSubCategory,
  stringToGridType,
  stringToGridTypeParent,
  gridTypeToString,
} from './Search/searchHelpers';
import { trackEvent } from 'app-insights/appInsightsTracking';
import { AttachmentAction } from './Attachments/AttachmentHoverView';
import {
  licensureData,
  clinicalQualificationsSkillsChecklistsData,
  clinicalQualificationsData,
  clinicalQualificationsAssessmentsData,
  employeeHealthData,
  certificationsData,
  otherCertificationsData,
  educationsData,
  lifeSupportCertificationsData,
  humanResourcesData,
  humanResourcesDrugScreensData,
  humanResourcesIdentificationsData,
  humanResourcesFormsData,
  facilityTrackingSheetData,
  clientSpecificContractEnclosuresData,
  pendingMoleculeDevelopmentData,
  managerReviewSensitiveAccountData,
  employeeHealthLabResultsData,
  employeeHealthMedicalRecordsData,
} from './TableData';
import { CredentialingLookup } from 'app/models/Credentialing/CredentialingLookups';
import { SkillsChecklistExpandableTab } from './Tabs/ClinicalQualificationsTabs/SkillsChecklistTab';
import { ClinicalQualificaitonsTab } from './Tabs/ClinicalQualificationsTabs/ClinicalQualificationsTab';

export enum levels {
  ZERO = 0,
}

export const getDataSet = (creds: Credentials | null, credentialsGridType: CredentialsGridType) => {
  switch (credentialsGridType) {
    case CredentialsGridType.licensure:
      return creds?.licenses || [];
    case CredentialsGridType.clinicalQualificationsSkillsChecklists:
      return (creds?.clinicalQualifications || []).filter(item => item.subCategory === 'Skills Checklist');
    case CredentialsGridType.clinicalQualifications:
      return (creds?.clinicalQualifications || []).filter(item => item.subCategory === 'Clinical Qualifications');
    case CredentialsGridType.clinicalQualificationsAssessments:
      return (creds?.clinicalQualifications || []).filter(item => item.subCategory === 'Assessment');
    case CredentialsGridType.certifications:
      return (creds?.certifications || []).filter(item => item.subCategory === 'Certifications');
    case CredentialsGridType.certificationsOtherCertifications:
      return (creds?.certifications || []).filter(item => item.subCategory === 'Other Certifications');
    case CredentialsGridType.certificationsLifeSupportCertifications:
      return (creds?.certifications || []).filter(item => item.subCategory === 'Life Support Certification');

    case CredentialsGridType.humanResourcesBackgroundChecks:
      return (creds?.humanResourcesCredentials || []).filter(item => item.subCategory === 'Background Checks');
    case CredentialsGridType.humanResourcesDrugScreens:
      return (creds?.humanResourcesCredentials || []).filter(item => item.subCategory === 'Drug Screens');
    case CredentialsGridType.humanResourcesIdentifications:
      return (creds?.humanResourcesCredentials || []).filter(item => item.subCategory === 'Identifications');
    case CredentialsGridType.humanResourcesForms:
      return (creds?.humanResourcesCredentials || []).filter(item => item.subCategory === 'Forms');

    case CredentialsGridType.educationAndOnlineForms:
      return creds?.educationOnlineForms || [];

    case CredentialsGridType.employeeHealthImmunizations:
      return (creds?.employeeHealth || []).filter(item => item.subCategory === 'Immunizations & Titers');
    case CredentialsGridType.employeeHealthLabResults:
      return (creds?.employeeHealth || []).filter(item => item.subCategory === 'Lab Results');
    case CredentialsGridType.employeeHealthMedicalRecords:
      return (creds?.employeeHealth || []).filter(item => item.subCategory === 'Medical Records');

    case CredentialsGridType.miscellaneousClientSpecificContractEnclosures:
      return (creds?.miscellaneous || []).filter(item => item.subCategory === 'Client Specific Contract Enclosure(s)');
    case CredentialsGridType.miscellaneousPendingMoleculeDevelopment:
      return (creds?.miscellaneous || []).filter(item => item.subCategory === 'Pending Molecule Development');
    case CredentialsGridType.miscellaneousFacilityTrackingSheet:
      return (creds?.miscellaneous || []).filter(item => item.subCategory === 'Facility Tracking Sheet');
    case CredentialsGridType.miscellaneousManagerReviewSensitiveAccount:
      return (creds?.miscellaneous || []).filter(item => item.subCategory === 'Manager Review-Sensitive Account');
    default:
      return [];
  }
};

export const getColumnsAndRows = (
  creds: Credentials | null,
  credentialsGridType: CredentialsGridType,
  credentialingLookups: CredentialingLookup[],
  additionalFilter: any[] = [],
  viewingDuplicates = false,
  onAttachmentAction: (
    action: AttachmentAction,
    requirementID: number,
    requirementTypeID: number,
    travelerId: number,
    brandId: number,
  ) => void,
) => {
  let columns: ICustomHeader[] = [];
  let rows: any[] = [];
  const d = getDataSet(creds, credentialsGridType) as ICredentials[];
  let dataSet: ICredentials[] = d;
  additionalFilter?.forEach(filter => {
    dataSet = dataSet.filter(filter);
  });

  const lookups = isSubCategory('', '', credentialsGridType)
    ? credentialingLookups?.filter(item => item['subCategory'] === gridTypeToString(credentialsGridType)) || []
    : credentialingLookups;

  switch (credentialsGridType) {
    case CredentialsGridType.licensure:
      columns = licensureData.getTableColumns(dataSet.length > 0, viewingDuplicates);
      rows = licensureData.getTableRows(dataSet, lookups, viewingDuplicates, onAttachmentAction);
      break;
    case CredentialsGridType.clinicalQualificationsSkillsChecklists:
      columns = clinicalQualificationsSkillsChecklistsData.getTableColumns(dataSet.length > 0, viewingDuplicates);
      rows = clinicalQualificationsSkillsChecklistsData.getTableRows(
        dataSet,
        lookups,
        viewingDuplicates,
        onAttachmentAction,
      );
      break;
    case CredentialsGridType.clinicalQualifications:
      columns = clinicalQualificationsData.getTableColumns(dataSet.length > 0, viewingDuplicates);
      rows = clinicalQualificationsData.getTableRows(dataSet, lookups, viewingDuplicates, onAttachmentAction);
      break;
    case CredentialsGridType.clinicalQualificationsAssessments:
      columns = clinicalQualificationsAssessmentsData.getTableColumns(dataSet.length > 0, viewingDuplicates);
      rows = clinicalQualificationsAssessmentsData.getTableRows(
        dataSet,
        lookups,
        onAttachmentAction,
        levels.ZERO,
        true,
        viewingDuplicates,
      );
      break;
    case CredentialsGridType.certifications:
      columns = certificationsData.getTableColumns(dataSet.length > 0, viewingDuplicates);
      rows = certificationsData.getTableRows(dataSet, lookups, viewingDuplicates, onAttachmentAction);
      break;
    case CredentialsGridType.certificationsOtherCertifications:
      columns = otherCertificationsData.getTableColumns(dataSet.length > 0, viewingDuplicates);
      rows = otherCertificationsData.getTableRows(dataSet, lookups, viewingDuplicates, onAttachmentAction);
      break;
    case CredentialsGridType.certificationsLifeSupportCertifications:
      columns = lifeSupportCertificationsData.getTableColumns(dataSet.length > 0, viewingDuplicates);
      rows = lifeSupportCertificationsData.getTableRows(dataSet, lookups, viewingDuplicates, onAttachmentAction);
      break;

    case CredentialsGridType.humanResourcesBackgroundChecks:
      columns = humanResourcesData.getTableColumns(dataSet.length > 0, viewingDuplicates);
      rows = humanResourcesData.getTableRows(dataSet, lookups, onAttachmentAction, levels.ZERO, true, viewingDuplicates);
      break;
    case CredentialsGridType.humanResourcesDrugScreens:
      columns = humanResourcesDrugScreensData.getTableColumns(dataSet.length > 0, viewingDuplicates);
      rows = humanResourcesDrugScreensData.getTableRows(dataSet, lookups, viewingDuplicates, onAttachmentAction);
      break;
    case CredentialsGridType.humanResourcesIdentifications:
      columns = humanResourcesIdentificationsData.getTableColumns(dataSet.length > 0, viewingDuplicates);
      rows = humanResourcesIdentificationsData.getTableRows(dataSet, lookups, viewingDuplicates, onAttachmentAction);
      break;
    case CredentialsGridType.humanResourcesForms:
      columns = humanResourcesFormsData.getTableColumns(dataSet.length > 0, viewingDuplicates);
      rows = humanResourcesFormsData.getTableRows(dataSet, lookups, viewingDuplicates, onAttachmentAction);
      break;

    case CredentialsGridType.educationAndOnlineForms:
      columns = educationsData.getTableColumns(dataSet.length > 0, viewingDuplicates);
      rows = educationsData.getTableRows(dataSet, lookups, viewingDuplicates, onAttachmentAction);
      break;

    case CredentialsGridType.employeeHealthImmunizations:
      columns = employeeHealthData.getTableColumns(dataSet.length > 0, viewingDuplicates);
      rows = employeeHealthData.getTableRows(dataSet, lookups, viewingDuplicates, onAttachmentAction);
      break;
    case CredentialsGridType.employeeHealthLabResults:
      columns = employeeHealthLabResultsData.getTableColumns(dataSet.length > 0, viewingDuplicates);
      rows = employeeHealthData.getTableRows(dataSet, lookups, viewingDuplicates, onAttachmentAction);
      break;
    case CredentialsGridType.employeeHealthMedicalRecords:
      columns = employeeHealthMedicalRecordsData.getTableColumns(dataSet.length > 0, viewingDuplicates);
      rows = employeeHealthData.getTableRows(dataSet, lookups, viewingDuplicates, onAttachmentAction);
      break;

    case CredentialsGridType.miscellaneousFacilityTrackingSheet:
      columns = facilityTrackingSheetData.getTableColumns(dataSet.length > 0, viewingDuplicates);
      rows = facilityTrackingSheetData.getTableRows(dataSet, lookups, viewingDuplicates, onAttachmentAction);
      break;
    case CredentialsGridType.miscellaneousClientSpecificContractEnclosures:
      columns = clientSpecificContractEnclosuresData.getTableColumns(dataSet.length > 0, viewingDuplicates);
      rows = clientSpecificContractEnclosuresData.getTableRows(dataSet, lookups, viewingDuplicates, onAttachmentAction);
      break;
    case CredentialsGridType.miscellaneousPendingMoleculeDevelopment:
      columns = pendingMoleculeDevelopmentData.getTableColumns(dataSet.length > 0, viewingDuplicates);
      rows = pendingMoleculeDevelopmentData.getTableRows(dataSet, lookups, viewingDuplicates, onAttachmentAction);
      break;
    case CredentialsGridType.miscellaneousManagerReviewSensitiveAccount:
      columns = managerReviewSensitiveAccountData.getTableColumns(dataSet.length > 0, viewingDuplicates);
      rows = managerReviewSensitiveAccountData.getTableRows(dataSet, lookups, onAttachmentAction, levels.ZERO, true, viewingDuplicates);
      break;
    default:
      break;
  }
  return {
    columns: columns,
    rows: _.cloneDeep(rows),
  };
};

export const formatEntry = (
  key,
  value,
  column: ICustomHeader | undefined,
  originalValue: any,
  name: { first: any; last: any },
) => {
  let val;
  if (column?.control) {
    switch (column?.control?.type) {
      case gridControlType.dropDown:
        val = value === 'N/A' ? null : value;
        break;
      case gridControlType.checkbox:
        if (`${key}` === 'levelID') {
          val = value === true ? 307 : value === false || value === null ? 306 : value;
        } else {
          val = value === true ? 1 : value === false || value === null ? 2 : value;
        }
        break;
      case gridControlType.date:
      case gridControlType.dateLabel:
        if (value === 'N/A') val = originalValue;
        else if (value !== null && value !== undefined) {
          val = `${moment(value.endsWith('Z') ? value : `${value}`).format('yyyy-MM-DDTHH:mm:ss')}`;
        } else if (value === null) val = value;
        else val = originalValue;
        break;
      case gridControlType.name:
        if (value !== originalValue) val = value === null ? null : name;
        else val = originalValue;
        break;
      case gridControlType.textfieldnumber:
        if (value === null || value === undefined || value === '') val = null;
        else val = Number(value);
        break;
      case gridControlType.textfield:
        if (value === null || value === undefined || value === '') val = null;
        else val = value;
        break;
      default:
        val = value;
        break;
    }
  } else {
    val = value;
  }
  return val;
};

export const formatRowData = (
  updatedRow,
  credentials,
  credentialsGridType,
  credentialingLookups,
  name: { first: any; last: any },
  onAttachmentAction: (
    action: AttachmentAction,
    requirementID: number,
    requirementTypeID: number,
    travelerId: number,
    brandId: number,
  ) => void,
  employeeID: number,
) => {
  const dataSet = getDataSet(credentials, credentialsGridType);
  const foundData = (dataSet as any[]).filter(item => item.requirementID === updatedRow.rowID);
  const { columns } = getColumnsAndRows(
    credentials,
    credentialsGridType,
    credentialingLookups,
    [],
    false,
    onAttachmentAction,
  );

  if (foundData && foundData.length > 0) {
    const data = foundData[0];
    Object.entries(updatedRow)?.forEach((entry: any[]) => {
      if (data.hasOwnProperty(`${entry[0]}`) && (typeof entry[1] !== 'object' || entry[1] === null)) {
        data[`${entry[0]}`] = formatEntry(
          entry[0],
          entry[1],
          columns.find(column => column.title === `${entry[0]}`),
          data[`${entry[0]}`],
          name,
        );
      } else if (`${entry[0]}` === 'detailRows') {
        entry[1].forEach(rows => {
          rows.forEach(rowsAndColumns => {
            rowsAndColumns.rows?.forEach(detailRow => {
              Object.entries(detailRow)?.forEach(detailEntry => {
                if (
                  data.hasOwnProperty(`${detailEntry[0]}`) &&
                  (typeof detailEntry[1] !== 'object' || detailEntry[1] === null)
                ) {
                  data[`${detailEntry[0]}`] = formatEntry(
                    detailEntry[0],
                    detailEntry[1],
                    rowsAndColumns.columns.find(column => column.title === `${detailEntry[0]}`),
                    data[`${detailEntry[0]}`],
                    name,
                  );
                }
              });
            });
          });
        });
      }
    });
    // Update last updated date and name
    data.lastUpdate = `${moment().format('yyyy-MM-DDTHH:mm:ss')}Z`;
    data.lastUpdatedByID = employeeID;
    return data;
  } else {
    return null;
  }
};

export const onNewSave = async (updatedRow, candidateId): Promise<any> => {
  const promise = new Promise<any>((resolve, reject) => {
    const data = updatedRow;
    createCredential(candidateId, data)
      .then(response => {
        const status = response?.status || 0;
        if (status >= 200 && status <= 226 && response !== undefined) {
          resolve(response.data);
          trackEvent({ type: 'event', name: 'Credential Added', timerKey: 'addCredential' });
          trackEvent({ type: 'duration', name: 'Credential Added Acknoledgement', properties: { duration: 5000 } });
        } else {
          reject(response);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
  return promise;
};

/**
 * Formats the updated data into a form the profile context and API can accept
 * @param updatedRow The updated data for the credential
 */
export const onEditSave = async (
  updatedRow,
  credentials,
  credentialsGridType,
  credentialingLookups,
  dispatch,
  name: { first: any; last: any },
  onAttachmentAction: (
    action: AttachmentAction,
    requirementID: number,
    requirementTypeID: number,
    travelerId: number,
    brandId: number,
  ) => void,
  employeeID: number,
): Promise<any> => {
  const promise = new Promise<any>((resolve, reject) => {
    const data = formatRowData(
      updatedRow,
      credentials,
      credentialsGridType,
      credentialingLookups,
      name,
      onAttachmentAction,
      employeeID,
    );
    // API call
    editCredential(credentials?.candidateId, data)
      .then(response => {
        const status = response?.status || 0;
        if (status >= 200 && status <= 226 && response !== undefined) {
          trackEvent({ type: 'event', name: 'Credential Edit Completed', timerKey: 'editCredential' });
          const updatedCredentials = updateCredentialsLocal(
            response.data,
            credentialsGridType,
            credentials,
            updatedRow.rowID,
            response?.status === 204,
          );
          const { rows } = getColumnsAndRows(
            updatedCredentials,
            credentialsGridType,
            credentialingLookups,
            [],
            false,
            onAttachmentAction,
          );
          //updateCredential(_.cloneDeep(updatedCredentials)); #CodeChange
          if (updatedCredentials !== null) dispatch(candidateDetailActions.setCredentials(updatedCredentials));
          resolve({
            rowID: updatedRow.rowID,
            data: rows.find(item => item.rowID === updatedRow.rowID),
            continueEditing: false,

            // response.data['requirementTypeID'] === 118 && response.data['verifiedID'] === 1 ? false : true,
          });
        } else {
          if (response.status === 409) {
            reject(`${response.data}`);
          } else reject('server error');
        }
      })
      .catch(error => {
        reject('server error');
      });
  });
  return promise;
};

/**
 * Update a credential in the local object
 * @param newRecord new instance of record that's being updated
 * @param credentialsGridType type of credential
 * @param credentials list of all credentials
 * @param requirementID requirementID
 * @param removeRecord whether or not to remove the record (e.g., expired credential)
 * @returns List of all credentials or null
 */
export const updateCredentialsLocal = (
  newRecord,
  credentialsGridType,
  credentials,
  requirementID,
  removeRecord,
): Credentials | null => {
  const creds = credentials;
  if (creds === null) return null;

  try {
    const { index } = findCredentialByRequirementID(requirementID, creds);
    if (index !== null) {
      const gridTypeObject = gridTypeToObject(credentialsGridType);
      if (removeRecord) creds[gridTypeObject].splice(index, 1);
      else creds[gridTypeObject][index] = newRecord;
    }
    return creds;
  } catch (e) {
    return creds;
  }
};

/**
 * Add credential to local object of all credentials
 * @param newRecord new credential record
 * @param credentialsGridType type of credential to add
 * @param credentials list of all credentials
 * @returns `Credentials | null`
 */
export const addCredentialLocal = (newRecord, credentialsGridType, credentials): Credentials | null => {
  const creds = credentials;
  if (creds === null) return null;
  creds[gridTypeToObject(credentialsGridType)].push(newRecord);
  return creds;
};

/**
 * Get a blank model of a given credential type
 * @param gridType type of credential
 * @returns Promise of an empty credential object of the given type
 */
export const getCredentialModel = async (gridType: CredentialsGridType): Promise<any> => {
  const promise = new Promise((resolve, reject) => {
    getModels()
      .then(response => {
        if (response) {
          resolve(response[gridTypeToObject(gridType)][0]);
        }
        reject();
      })
      .catch(error => {
        reject();
      });
  });
  return promise;
};

/** find the credential date field position in grid table and returns its formatted value  */
export const findMyComparingCredentialDate = (overAllData: any, key: keyof ICredentials) => {
  let tempValue: any;
  if (overAllData.hasOwnProperty(key)) {
    tempValue = overAllData[key];
  } else {
    overAllData.detailRows?.forEach(rows => {
      rows.forEach(rowsColumns => {
        rowsColumns.rows.forEach(row => {
          if (row.hasOwnProperty(key)) {
            tempValue = row[key];
          }
        });
      });
    });
  }
  if (tempValue !== undefined) {
    return moment(moment(tempValue).format('yyyy-MM-DD'));
  } else return null;
};

/**
 * Find a credential from a requirementID
 * @param requirementID requirementID
 * @param credentials list of all credentials
 * @returns found credential, index, gridType, parentGridType, and requirementID of found credential
 */
export const findCredentialByRequirementID = (
  requirementID: number,
  credentials: Credentials | null | undefined,
): {
  credential: ICredentials | null;
  index: number | null;
  gridType: CredentialsGridType;
  parentGridType: CredentialsGridType;
  requirementID: number;
} => {
  let foundCredential: ICredentials | null = null;
  let index: number = -1;
  if (credentials !== null && credentials !== undefined) {
    const a = Object.entries(credentials);

    for (const item of a) {
      if (typeof item[1] === 'object') {
        index = item[1].map(j => j.requirementID).indexOf(requirementID);
        if (index !== -1) {
          foundCredential = item[1][index];
          break;
        }
      }
    }
  }
  return {
    credential: foundCredential,
    index: index,
    gridType: stringToGridType(foundCredential?.category, foundCredential?.subCategory),
    parentGridType: stringToGridTypeParent(foundCredential?.category, foundCredential?.subCategory),
    requirementID: requirementID,
  };
};

/**
 * Network request to delete a credential
 * @param requirementID requirementID
 * @param credentials list of all local credentials
 * @param dispatch redux dispatch
 * @returns Promise of success/failure boolean
 */
export const onDeleteCredential = (
  requirementID: number,
  credentials: Credentials | null,
  dispatch: any,
): Promise<boolean> => {
  const promise = new Promise<boolean>((resolve, reject) => {
    deleteCredential(credentials?.candidateId, requirementID)
      .then(response => {
        const status = response?.status || 0;
        if ((status >= 200 && status <= 226) || status === 410) {
          const updatedCredentials = deleteCredentialLocal(requirementID, credentials);
          if (updatedCredentials !== null) dispatch(candidateDetailActions.setCredentials(updatedCredentials));
          resolve(true);
        } else {
          reject(false);
        }
      })
      .catch(error => {
        reject(false);
      });
  });
  return promise;
};

/**
 * Delete a credential in the local object
 * @param requirementID requirementID
 * @param credentials list of all credentials
 * @returns list of all credentials or null
 */
export const deleteCredentialLocal = (requirementID: number, credentials: Credentials | null): Credentials | null => {
  let index: number = -1;
  if (credentials !== null) {
    const a = Object.entries(credentials);
    for (const item of a) {
      if (typeof item[1] === 'object' && index === -1) {
        index = item[1].map(j => j.requirementID).indexOf(requirementID);
        if (index !== -1) {
          item[1].splice(index, 1);
          credentials[item[0]] = item[1];
          break;
        }
      }
    }
  }
  return credentials;
};

export const onDeleteAttachment = (
  candidateId: number,
  travelerRequirementId: number,
  selectedAttachments: any,
  attachments: any,
  dispatch: any,
): Promise<boolean> => {
  if (!candidateId || !travelerRequirementId) throw new Error('parameters cannot be null or default value');
  const promise = new Promise<boolean>((resolve, reject) => {
    deleteAttachment(candidateId, travelerRequirementId, selectedAttachments.travelerRequirementImageID)
      .then(response => {
        const status = response || 0;
        if (
          (status >= 200 && status <= 226) ||
          status === 410 ||
          response === selectedAttachments.travelerRequirementImageID
        ) {
          const updatedAttachments = deleteAttachmentLocal(selectedAttachments, attachments);
          if (updatedAttachments !== null)
            dispatch(candidateDetailActions.setAttachments(updatedAttachments as IAttachments[]));
          resolve(true);
        } else {
          reject(false);
        }
      })
      .catch(error => {
        reject(false);
      });
  });
  return promise;
};

export const onAddAttachment = (
  candidateId: string | number,
  travelerRequirementId: string | number,
  attachments: any = [],
  newAttachment: any,
  user: any,
  dispatch: any,
): Promise<boolean> => {
  const attachmentItem = {
    extension: newAttachment?.type || '',
    comments: 'N/A',
    lastUpdatedByID: user.userInfo?.employeeId || 0,
    data: newAttachment?.data || '',
    title: newAttachment?.title || null,
  };
  if (candidateId > 0 && travelerRequirementId > 0) {
    const promise = new Promise<boolean>(async (resolve, reject) => {
      addAttachment(candidateId, travelerRequirementId, attachmentItem)
        .then(response => {
          const status = response || 0;
          if ((status >= 200 && status <= 226) || status === 410) {
            const updatedAttachments = [...attachments, attachmentItem as unknown as IAttachments];
            if (updatedAttachments !== null) {
              dispatch(candidateDetailActions.setAttachments(updatedAttachments as IAttachments[]));
            }
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch(error => {
          reject(false);
        });
    });
    return promise;
  }
};

export const deleteAttachmentLocal = (selectedAttachments: any, attachments: any) => {
  if (selectedAttachments?.length > 0) {
    for (const item of selectedAttachments) {
      attachments = attachments.filter(d => d.travelerRequirementImageID !== item.travelerRequirementImageID);
    }
  }
  return attachments;
};

export function getBinaryFromFile(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result));
    reader.addEventListener('error', err => reject(err));
    reader.readAsBinaryString(file);
  });
}

export const useNewGrid = (type: CredentialsGridType, props?: any) => {
  const updatedGridTypes = [
    {
      gridType: CredentialsGridType.clinicalQualificationsSkillsChecklists,
      component: <SkillsChecklistExpandableTab />,
    },
    {
      gridType: CredentialsGridType.clinicalQualifications,
      component: <ClinicalQualificaitonsTab credentialsGridType={props.credentialsGridType} credentialingLookups={props.credentialingLookups}
        credentials={props.credentials} />
    }
  ];
  return updatedGridTypes.find(item => item.gridType === type);
}
