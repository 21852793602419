import { ICheckbox } from '../CustomComponents/CheckboxContainer';
import { IMiscellaneousFields, PreferenceDirtyFields } from './Preference.redux';
import { GetClientSettingOptions, GetRadiiOptions } from '../FormatData';
import { ITypeAheadOption } from '../CustomComponents/ControlledTypeAhead';
import { Concatenate } from 'utils/string/string';
import { GeneralPreference } from 'app/models/Candidate/CandidateJobPreference';

export const resetFieldData = (data, reset) => {
  if (!data || (Array.isArray(data) && data.length === 0)) return;
  let newData;
  if (Array.isArray(data) && instanceOfICheckbox(data[0])) {
    newData = uncheckNonDisabledCheckboxes(data);
    const anyOption = newData.find(option => option.object.ID === -1);
    if (anyOption && !newData.some(option => option.isReadOnly)) anyOption.checked = true;
  } else if (instanceOfIMiscellaneousFields(data)) newData = removeMiscellaneousFields(data);
  else newData = removeNonDisabledOptions(data);
  newData && reset(newData);
};

const uncheckNonDisabledCheckboxes = (checkboxes: ICheckbox[]) =>
  checkboxes.map(item => {
    if (!item.isReadOnly) item.checked = false;
    return item;
  });

const removeNonDisabledOptions = options =>
  options.reduce((resultArray, option) => {
    if (option.isReadOnly) resultArray.push(option);
    return resultArray;
  }, []);

const removeMiscellaneousFields = (fields: IMiscellaneousFields) => {
  return {
    avStartDate: fields.isStartDateReadOnly ? fields.avStartDate : null,
    isStartDateReadOnly: fields.isStartDateReadOnly,
    weeklyPayRate: fields.isPayRateReadOnly ? fields.weeklyPayRate : '',
    isPayRateReadOnly: fields.isPayRateReadOnly,
  } as IMiscellaneousFields;
};

function instanceOfICheckbox(object: any): object is ICheckbox {
  return 'label' in object && 'name' in object && 'object' in object && 'checked' in object && 'isReadOnly' in object;
}

function instanceOfIMiscellaneousFields(object: any): object is IMiscellaneousFields {
  return (
    'avStartDate' in object &&
    'isStartDateReadOnly' in object &&
    'weeklyPayRate' in object &&
    'isPayRateReadOnly' in object
  );
}

//////////////////////////////////////////////////////
//////Saga heleper functions//////
//////////////////////////////////////////////////////
//////Get Request helpers//////
///////////////////////////////

// Defined here to acquire scope to determine data across functions
var recruiterEnterdDataExists: boolean = false;
const DoesRecruiterEnteredDataExist = recruiterId => {
  if (recruiterId) recruiterEnterdDataExists = true;
};

const bindCheckboxWithApiData = (uiOption: ICheckbox, apiOptions) => {
  if (apiOptions && apiOptions.length === 0 && uiOption.object.ID === -1) {
    uiOption.checked = true;
    return uiOption;
  }

  const apiOption = apiOptions?.find(option => option.typeId === uiOption.object.ID);
  if (apiOption) {
    uiOption.checked = true;
    uiOption.isReadOnly = apiOption.recruiterId ? false : true;
    DoesRecruiterEnteredDataExist(apiOption);
  }
  return uiOption;
};

export const GenerateFormData = (preferences, formData, disciplineOptions, specialtyOptions) => {
  const skillSets = preferences.disciplinesAndSpecialties.map(apiOpt => {
    DoesRecruiterEnteredDataExist(apiOpt.recruiterId);
    const discipline = disciplineOptions.find(option => option.object.ID === apiOpt.disciplineId);
    const specialty = specialtyOptions.find(option => option.object.SpecialtyID === apiOpt.specialtyId);
    const isReadOnly = apiOpt.recruiterId ? false : true;
    return { discipline, specialty, isReadOnly };
  });

  const clientSettingOptions = GetClientSettingOptions();
  const clientSettings = preferences.clientSettings.map(apiOpt => {
    DoesRecruiterEnteredDataExist(apiOpt.recruiterId);
    const option = clientSettingOptions?.find(option => option.object.ID === apiOpt.typeId);
    if (option) {
      option.isReadOnly = apiOpt.recruiterId ? false : true;
    }
    return option;
  });

  const radiiOptions: ITypeAheadOption[] = GetRadiiOptions();
  const locations = preferences.locations.map(apiOpt => {
    DoesRecruiterEnteredDataExist(apiOpt.recruiterId);
    const loc: ITypeAheadOption = {
      object: {
        stateId: apiOpt.stateId,
        stateProvinceId: apiOpt.stateProvinceId,
        stateDescription: apiOpt.stateDescription,
        cityId: apiOpt.cityId,
        city: apiOpt.city,
        zipId: apiOpt.cityId,
        zip: apiOpt.zip,
      },
      label: Concatenate([apiOpt.city, apiOpt.stateDescription, apiOpt.zip], ', '),
    };
    const radius = radiiOptions.find(option => option.object.Value === apiOpt.nearByRadiusMiles);
    const optionIsReadOnly = apiOpt.recruiterId ? false : true;
    return { location: loc, radius: radius, isReadOnly: optionIsReadOnly };
  });

  const shiftTimes = JSON.parse(JSON.stringify(formData.shiftTimes));
  const shiftTimeCheckBoxes = shiftTimes.map(uiOption => bindCheckboxWithApiData(uiOption, preferences.shiftTimes));

  const shiftLengths = JSON.parse(JSON.stringify(formData.shiftLengths));
  const shiftLengthCheckBoxes = shiftLengths.map(uiOption =>
    bindCheckboxWithApiData(uiOption, preferences.shiftLengths),
  );

  const placementLengths = JSON.parse(JSON.stringify(formData.placementLengths));
  const placementLengthCheckBoxes = placementLengths.map(uiOption =>
    bindCheckboxWithApiData(uiOption, preferences.placementLengths),
  );

  const facilityTypes = JSON.parse(JSON.stringify(formData.facilityTypes));
  const facilityTypeCheckBoxes = facilityTypes.map(uiOption =>
    bindCheckboxWithApiData(uiOption, preferences.facilityTypes),
  );

  const placementTypes = JSON.parse(JSON.stringify(formData.placementTypes));
  const placementTypeCheckboxes = placementTypes.map(uiOption =>
    bindCheckboxWithApiData(uiOption, preferences.placementTypes),
  );

  const jobExperiences = JSON.parse(JSON.stringify(formData.jobExperiences));
  const jobExperienceCheckboxes = jobExperiences.map(uiOption =>
    bindCheckboxWithApiData(uiOption, preferences.jobExperienceLevels),
  );

  const timeOffRequests = preferences?.timeOffRequests?.map((request, index) => {
    const startDate = request?.startDate;
    const endDate = request?.endDate;
    const choice = {
      key: index,
      value: { start: startDate, end: endDate },
      isReadOnly: request.recruiterId ? false : true,
    };
    return choice;
  });

  const isStartDateReadOnly = () => {
    if (preferences && preferences.preferredStartDate) {
      if (preferences.preferredStartDate.recruiterId) {
        DoesRecruiterEnteredDataExist(preferences.preferredStartDate.recruiterId);
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  const isPayRateReadOnly = () => {
    if (preferences && preferences.preferredWeeklyPayRate) {
      if (preferences.preferredWeeklyPayRate.recruiterId) {
        DoesRecruiterEnteredDataExist(preferences.preferredWeeklyPayRate.recruiterId);
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const miscellaneousFields: IMiscellaneousFields = {
    avStartDate: preferences && preferences.preferredStartDate ? preferences.preferredStartDate.value : null,
    isStartDateReadOnly: isStartDateReadOnly(),
    weeklyPayRate: preferences && preferences.preferredWeeklyPayRate ? preferences.preferredWeeklyPayRate.value : '',
    isPayRateReadOnly: isPayRateReadOnly(),
  };

  return {
    skillSets,
    clientSettings,
    locations,
    placementTypes: placementTypeCheckboxes,
    jobExperiences: jobExperienceCheckboxes,
    shiftTimes: shiftTimeCheckBoxes,
    shiftLengths: shiftLengthCheckBoxes,
    placementLengths: placementLengthCheckBoxes,
    facilityTypes: facilityTypeCheckBoxes,
    timeOffRequests,
    miscellaneousFields,
    recruiterEnterdDataExists,
  };
};

////////////////////////////////
//////Save Request helpers//////
////////////////////////////////
export const CreatePreferencePostData = (formData, dirtyFields: PreferenceDirtyFields, prevData, userData) => {
  const skills = dirtyFields.skillSet
    ? formData.skillSet.reduce((resultArray, option) => {
        if (!option.isReadOnly) {
          const skill = {
            disciplineId: option.discipline.object.ID,
            discipline: option.discipline.object.Description,
            specialtyId: option.specialty?.object?.SpecialtyID,
            specialty: option.specialty?.object?.Description,
            updatedBy: userData.updatedBy,
          };
          resultArray.push(skill);
        }
        return resultArray;
      }, [])
    : null;

  const determineLocationType = (stateId, cityId, zipId, radiusValue) => {
    return radiusValue ? 'radiusByMiles' : zipId ? 'zip' : cityId ? 'city' : stateId ? 'state' : '';
  };
  const preferredLocations = dirtyFields.preferredLocations
    ? formData.preferredLocations.reduce((resultArray, option) => {
        if (!option.isReadOnly) {
          const location = {
            locationType: determineLocationType(
              option.location.object.stateId,
              option.location.object.cityId,
              option.location.object.zipId,
              option.radius?.object?.Value,
            ),
            stateId: option.location.object.stateId,
            stateProvinceId: option.location.object.stateProvinceId,
            stateDescription: option.location.object.stateDescription,
            cityId: option.location.object.cityId,
            city: option.location.object.city,
            zipId: option.location.object.zipId,
            zip: option.location.object.zip,
            nearByRadiusMiles: option.radius?.object?.Value,
            updatedBy: userData.updatedBy,
          };
          resultArray.push(location);
        }
        return resultArray;
      }, [])
    : null;

  const clientSettings = dirtyFields.clientSettings
    ? formData.clientSettings?.reduce((resultArray, option) => {
        if (!option.isReadOnly) {
          const clientSetting: GeneralPreference = {
            typeId: option.object.ID,
            type: option.object.Description,
            updatedBy: userData.updatedBy,
          };
          resultArray.push(clientSetting);
        }
        return resultArray;
      }, [])
    : null;

  const timeOffRequestsChosen = dirtyFields.timeOffRequests
    ? formData.timeOffRequests?.reduce((resultArray, option) => {
        if (!option.isReadOnly) {
          const timeOff = {
            startDate: option.value.start,
            endDate: option.value.end,
            updatedBy: userData.updatedBy,
          };
          resultArray.push(timeOff);
        }
        return resultArray;
      }, [])
    : null;

  const avStartDate = dirtyFields.avStartDate
    ? formData.avStartDate && !(prevData?.preferredStartDate && prevData?.preferredStartDate.recruiterId === null)
      ? [
          {
            value: formData.avStartDate,
            updatedBy: userData.updatedBy,
          },
        ]
      : []
    : null;

  const weeklyPayRate = dirtyFields.weeklyPayRate
    ? formData.weeklyPayRate &&
      !(prevData?.preferredWeeklyPayRate && prevData?.preferredWeeklyPayRate.recruiterId === null)
      ? [
          {
            value: parseFloat(formData.weeklyPayRate),
            updatedBy: userData.updatedBy,
          },
        ]
      : []
    : null;

  const formatCheckboxes = options =>
    options.reduce((resultArray, option) => {
      if (option.object.ID !== -1 && option.checked && !option.isReadOnly) {
        const item: GeneralPreference = {
          typeId: option.object.ID,
          type: option.object.Description,
          updatedBy: userData.updatedBy,
        };
        resultArray.push(item);
      }
      return resultArray;
    }, []);

  return {
    candidateId: userData.candidateId,
    recruiterId: userData.recruiterId,
    disciplinesAndSpecialties: skills,
    locations: preferredLocations,
    preferredStartDate: avStartDate,
    placementTypes: dirtyFields.placementTypes ? formatCheckboxes(formData.placementTypes) : null,
    jobExperienceLevels: dirtyFields.jobExperiences ? formatCheckboxes(formData.jobExperiences) : null,
    clientSettings: clientSettings,
    shiftTimes: dirtyFields.shiftTimes
      ? !DoNotUpdateApi(formData.shiftTimes, prevData.preferenceAPIresponse.shiftTimes)
        ? formatCheckboxes(formData.shiftTimes)
        : null
      : null,
    shiftLengths: dirtyFields.shiftLengths
      ? !DoNotUpdateApi(formData.shiftLengths, prevData.preferenceAPIresponse.shiftLengths)
        ? formatCheckboxes(formData.shiftLengths)
        : null
      : null,
    placementLengths: dirtyFields.placementLengths
      ? !DoNotUpdateApi(formData.placementLengths, prevData.preferenceAPIresponse.placementLengths)
        ? formatCheckboxes(formData.placementLengths)
        : null
      : null,
    facilityTypes: dirtyFields.facilityTypes
      ? !DoNotUpdateApi(formData.facilityTypes, prevData.preferenceAPIresponse.facilityTypes)
        ? formatCheckboxes(formData.facilityTypes)
        : null
      : null,
    timeOffRequests: timeOffRequestsChosen,
    preferredWeeklyPayRate: weeklyPayRate,
  };
};

const DoNotUpdateApi = (checkboxes: ICheckbox[], prevData) =>
  checkboxes.every(option => !option.checked) && prevData.length === 0;
