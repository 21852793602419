import { Typography } from 'amn-ui-core';
import { useDispatch } from 'react-redux';
import React from 'react';
import { facilityActions } from '../../FacilityStore/Facility.redux';
import { trackEvent } from '../../../../../app-insights/appInsightsTracking';

export const FacilityDrawer = ({ displayValue, ...props }) => {
  const dispatch = useDispatch();
  return (
    <Typography
      onClick={() => {
        dispatch(facilityActions.setDrawerData({ open: true, data: { displayValue, ...props } }));
        trackEvent({
          type: 'click',
          name: 'Facility Details Side Panel',
          properties: { panel: 'Client Contact' },
        });
      }}
    >
      {displayValue}
    </Typography>
  );
};
