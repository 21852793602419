import React from 'react';
import { Link, Typography } from 'amn-ui-core';
import { auditTypeViewEditPermission } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/SummaryTab/Audit/Helpers/AuditAddHelpers';
import { selectUser } from 'oidc/user.selectors';
import { useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';
import { ff_ui_Candidate_Summary_Manage_Audits } from 'app/constants/FeatureFlags/Candidate/Keys';

export const GridCellEditAudit = props => {
  const { params, onHandleEdit } = props;
  const [editAuditFeature] = useDecision(ff_ui_Candidate_Summary_Manage_Audits);
  const user = useSelector(selectUser);

  const handleClick = event => {
    editAuditFeature &&
      (!auditTypeViewEditPermission(params.row?.auditType, user.userInfo).canView
        ? event.stopPropagation()
        : onHandleEdit(params));
  };

  return (
    <Link onClick={event => handleClick(event)}>
      <Typography aria-disabled={!auditTypeViewEditPermission(params.row?.auditType, user.userInfo).canView}>
        {params.row?.auditType}
      </Typography>
    </Link>
  );
};

export default GridCellEditAudit;
