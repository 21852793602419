export const ff_ReadOnly = 'ff_ui_readonly';
export const ff_global_search_title = 'ff_global_ui_global_search_title';
export const ff_global_new_app_version = 'ff_global_ui_new_app_version';
export const ff_global_caching_static_api_response = 'ff_global_ui_caching_static_api_response';
export const ff_task_ui_coverageaccountsettings = 'ff_task_ui_coverageaccountsettings';
export const ff_global_ui_walkme_injected_script = 'ff_global_ui_walkme_injected_script';
export const ff_task_api_getcoveragelist = 'ff_task_api_getcoveragelist';
export const ff_placement_ui_arbitration_agreement_grids = 'ff_placement_ui_arbitration_agreement_grids';
export const ff_placement_automation_preferences_add_facility_templates = 'ff_placement_automation_preferences_add_facility_templates'
export const ff_global_ui_logrocket = 'ff_global_ui_logrocket'
export const ff_maintaince_key = 'maintainance'