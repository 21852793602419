import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectEditableRequirementTypes } from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { TransportationRow } from './TransportationTable';
import { ArrivalTime } from '@AMIEWEB/Placement/PlacementDetails/Edit/Components/ArrivalTime';

export const TransportationArrivalTime = () => {
  const { t } = useTranslation();
  const { arrivalTime } = useSelector(selectEditableRequirementTypes);
  return (
    <TransportationRow
      id="placement-offer-transportation-arrivalTime"
      label={t('placement.profile.summary.offer.transportation.arrivalTime')}
      controlComponent={<ArrivalTime />}
      requirement={arrivalTime?.requirement}
    />
  );
};
