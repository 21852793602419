export const appendNewCertificateRows = (
  addedSubCerts,
  allSelectedSubCerts,
  currentRowValues,
  appendRequirement,
  skillSetIndex,
) => {
  let selectedSubCerts = allSelectedSubCerts;
  addedSubCerts.forEach(subCert => {
    const certificateValueToAppend = {
      documentAttachmentRequired: false,
      isRequired: true,
      certificates: currentRowValues?.certificates,
      subCertificate: [
        {
          subCertificate: {
            name: subCert?.name,
            value: subCert?.value,
            id: subCert?.id,
          },
        },
      ],
      issuedBy: currentRowValues?.issuedBy,
    };
    appendRequirement(certificateValueToAppend);
    selectedSubCerts = handleAddSubCert(selectedSubCerts, subCert?.id, skillSetIndex);
  });
  return selectedSubCerts;
};
export const handleAddSubCert = (allSelectedSubCerts, idToAdd, skillIndex) => {
  let selectedSubCert = allSelectedSubCerts?.map(eachSkillSet => {
    if (eachSkillSet?.skillSetIndex === skillIndex) {
      return {
        ...eachSkillSet,
        subCerts: [...eachSkillSet?.subCerts, idToAdd],
      };
    } else {
      return eachSkillSet;
    }
  });
  return selectedSubCert;
};
export const handleDeleteSubCert = (allSelectedSubCerts, idToDelete, skillIndex) => {
  let selectedSubCert = allSelectedSubCerts?.map(eachSkillSet => {
    if (eachSkillSet?.skillSetIndex === skillIndex) {
      return {
        ...eachSkillSet,
        subCerts: eachSkillSet?.subCerts?.filter(id => id !== idToDelete),
      };
    } else {
      return eachSkillSet;
    }
  });
  return selectedSubCert;
};
