/* eslint-disable react-hooks/exhaustive-deps */
import { OrderConstants } from 'app/constants/OrderCreationForm';
import prioritiesData from 'app/assets/jsons/OrderCreation/OrderPriorities.json';
import {
  OrderPriorityType,
  QualificationType,
  Reference,
  SkillcheckList,
  TravelExperienceType,
} from 'app/models/Unit/CreateUnit';
import { AMNDivisionType } from 'app/models/enums/AMNDivisionType';

export const formatYOEDropdown = (item: number) => {
  return item + (item === 1 || item === 0 ? ' year' : ' years');
};

export const commonVIPriority = 'Voice Advantage (Interview)';

export const getNewUnit = (facilityData, userId) => {
  return {
    name: '',
    unitId: 0,
    facilityId: facilityData.facilityId,
    facilityState: facilityData.location.state,
    costCenter: '',
    floor: '',
    experience: {
      isNewGrad: false,
      skillSets: [],
    },
    nurse: null,
    nurseRatio: null,
    patient: null,
    fyreIntegration: facilityData.amnDivisionTypeId === AMNDivisionType.hsg ? false : true,
    status: 'Active',
    statusId: 0,
    bestBets: facilityData.bestBets,
    bestBetsId: facilityData.bestBetsId,
    unitSize: null,
    externalJobDescription: '',
    internalNotes: '',
    reference: {
      isRequired: facilityData.amnDivisionTypeId === AMNDivisionType.hsg ? false : true,
      numberOfReferenceRequired:
        facilityData.amnDivisionTypeId === AMNDivisionType.hsg ? 0 : facilityData.numberOfReferenceRequired,
      numberOfReferenceToRFO:
        facilityData.amnDivisionTypeId === AMNDivisionType.hsg ? 0 : facilityData.numberOfReferenceToRFO,
      withinMonths: false,
      referenceFromSupervisor: false,
    },
    travelExperience: null,
    qualification: {
      activeLicense: false,
      compactLicense: false,
      emr: null,
      certifications: [],
      skillChecklists: [],
      licenses: [],
    },
    virtualRating: [],
    orderPriorities: getOrderPriorities({
      bedSize: facilityData.bedSize,
      teaching: facilityData.isTeaching,
      magnetStatus: facilityData.isMagnetStatus,
      traumaCenter: facilityData.isTraumaCenter,
    }),
    float: null,
    flexibleSchedule: null,
    nonStandardRequirement: [],
    currentUserId: userId,
    yearsOfExperience: null,
    standardizedRequirements: null,
    lastUpdated: null,
    lastUpdatedBy: null,
    unitContacts: null,
    linkedToFacility: 'N',
    firstDayInstructions: null,
  };
};

export const formatChipLabel = (label1: any, label2?: any, label3?: any) => {
  return label1 + (label2 ? ', ' + label2 : '') + (label3 ? ', ' + label3 + (label3 < 2 ? 'yr' : 'yrs') : '');
};

export const getOrderPriorities = facilityData => {
  const defaultPriorities: string[] = [];
  for (var property in facilityData) {
    if (
      (property === 'bedSize' && facilityData[property] != null && facilityData[property] < 200) ||
      (property !== 'bedSize' && facilityData[property])
    ) {
      defaultPriorities.push(OrderConstants.OrderPrioritiesLabel[property]);
    }
  }
  return getOrderPriorityObject(defaultPriorities);
};

export const getVIPriorities = () => {
  return prioritiesData.filter(x => (x.ID >= 30 && x.ID <= 38) || x.ID == 45).map(x => x.Description);
};

export const getOrderPriorityValues = (orderPriority: OrderPriorityType[]) => {
  const orderPrioritiesData: string[] = [];
  orderPriority?.map(priority => {
    return prioritiesData?.filter(item => {
      if (item.ID === priority.id) {
        orderPrioritiesData.push(item.Description);
      }
    });
  });
  return orderPrioritiesData;
};

export const getOrderPriorityObject = (value: string[]) => {
  const orderPriorities = value.map(priority => {
    const existingPriority = prioritiesData.filter(item => {
      if (item.Description === priority) {
        return item.ID;
      }
    })[0];
    return {
      id: existingPriority.ID,
      description: existingPriority.Description,
    };
  });
  return orderPriorities;
};

export const getTravelExperience = (experience: TravelExperienceType) => {
  let value = '';
  if (experience.numberOfAssignments) value = experience.numberOfAssignments + ' Assignments';
  if (experience.yearsOfExperience) value = experience.yearsOfExperience + ' Years';
  if (experience.general) value += '\xa0\xa0' + '|' + '\xa0\xa0' + 'General';
  if (experience.inSpecialty) value += '\xa0\xa0' + '|' + '\xa0\xa0' + 'In Specialty';
  return value;
};

export const getSkill = (skill: SkillcheckList) => {
  let value = skill.text;
  if (skill.yearsOfExperience) value += ', ' + skill.yearsOfExperience + 'y';
  return value;
};

export const getEMRValue = (emrvalue: string[]) => {
  let value = '';
  emrvalue.map((item, index) => {
    if (index !== emrvalue.length - 1) value += item + ', ';
    else value += item;
  });
  return value;
};

export const getOrderPrioritiesValue = (priorities: OrderPriorityType[]) => {
  let value = '';
  priorities.map((item, index) => {
    if (index !== priorities.length - 1) value += item.description + ', ';
    else value += item.description;
  });
  return value.length > 0 ? value : '-';
};

export const getQualification = (qualification: QualificationType, stateName?: string) => {
  let value = '';
  if (qualification.activeLicense && qualification.compactLicense)
    return (value +=
      `Active ${stateName ? stateName + ' ' : ''}License` + '\xa0\xa0' + '|' + '\xa0\xa0' + 'Compact License');
  if (qualification.activeLicense) return (value += `Active ${stateName ? stateName + ' ' : ''}License`);
  if (qualification.compactLicense) return (value += 'Compact License');
  return value;
};

export const getReference = (ref: Reference) => {
  let value = '';
  if (ref.withinMonths && ref.referenceFromSupervisor)
    return (value += 'Within 12 Months' + '\xa0\xa0' + '|' + '\xa0\xa0' + 'Reference from Supervisor and Above');
  if (ref.withinMonths) return (value += 'Within 12 Months');
  if (ref.referenceFromSupervisor) return (value += 'Reference from Supervisor and Above');
  return value;
};
