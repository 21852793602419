import { Grid, Skeleton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { GlanceTile, GlanceStatus } from 'app/components/Common/GlanceTile/NewGlanceTile';
import React from 'react';
import { GlanceDataStatus, QuickGlance } from 'app/models/Facility/FacilityDetails';
import { selectIsFacilityByIdRequestSuccess } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { useSelector } from 'react-redux';
interface QuickGlanceBar {
  quickGlanceDetails: QuickGlance;
  onClick(value: number): void;
}

const useStyles = makeStyles()({
  tile: {
    flex: 1,
    'font-weight': '500',
  },
  glanceTootlTipText: {
    padding: '0.3rem 1.6rem',
  },
});

export const FacilityQuickGlance: React.FC<QuickGlanceBar> = props => {
  const { classes } = useStyles();
  const facilityLoading = useSelector(selectIsFacilityByIdRequestSuccess);

  const glanceTileArray = [
    {
      title: `CREDIT CHECK`,
      status: {
        data: props.quickGlanceDetails.creditCheck,
        colorCode:
          props.quickGlanceDetails.creditCheck === GlanceDataStatus.yes
            ? GlanceStatus.red
            : props.quickGlanceDetails.creditCheck === GlanceDataStatus.no
            ? GlanceStatus.green
            : GlanceStatus.neutral,
      },
      showTooltip: false,
      tooltipText: <div className={classes.glanceTootlTipText}>{``}</div>,
    },
    {
      title: `BOOKING LIMIT`,
      status: {
        data: props.quickGlanceDetails.bookingLimit,
        colorCode: GlanceStatus.neutral,
      },
      showTooltip: false,
      tooltipText: <div className={classes.glanceTootlTipText}>{``}</div>,
    },
    {
      title: `OPEN POSITIONS`,
      status: {
        data: props.quickGlanceDetails.openPositions,
        colorCode: GlanceStatus.neutral,
      },
      showTooltip: false,
      tooltipText: <div className={classes.glanceTootlTipText}>{``}</div>,
    },
    {
      title: `FILES OUT`,
      status: {
        data: props.quickGlanceDetails.filesOut,
        colorCode: GlanceStatus.neutral,
      },
      showTooltip: true,
      tooltipText: <div className={classes.glanceTootlTipText}>{`Status includes Sent-Offer`}</div>,
    },
    {
      title: `CURRENT OA`,
      status: {
        data: props.quickGlanceDetails.currentOA,
        colorCode: GlanceStatus.neutral,
      },
      showTooltip: true,
      tooltipText: <div className={classes.glanceTootlTipText}>{`Current On Assignment Placements`}</div>,
    },
    {
      title: `HISTORICAL OA`,
      status: {
        data: props.quickGlanceDetails.historicalOA,
        colorCode: GlanceStatus.neutral,
      },
      showTooltip: true,
      tooltipText: <div className={classes.glanceTootlTipText}>{`Historical On Assignment Placements`}</div>,
    },
    {
      title: `LOCATION OA`,
      status: {
        data: props.quickGlanceDetails.locationOA,
        colorCode: GlanceStatus.neutral,
      },
      showTooltip: false,
      tooltipText: <div className={classes.glanceTootlTipText}>{``}</div>,
    },
  ];

  return (
    <Grid
      justifyContent="flex-start"
      direction="row"
      alignItems="center"
      container
      spacing={2}
      style={{ flexWrap: 'wrap', paddingRight: '1px' }}
    >
      {facilityLoading ? (
        glanceTileArray.map(tile => (
          <Grid item className={classes.tile}>
            <Skeleton variant="rectangular" width={200} height={30} />
          </Grid>
        ))
      ) : (
      glanceTileArray.map(tile => (
        <Grid item className={classes.tile}>
          <GlanceTile {...tile} />
        </Grid>
      ))
      )}
    </Grid>
  );
};
