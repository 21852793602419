import React, { useMemo } from 'react';
import { useEducationalCardStyles } from './utils';
import { PaperCard, Title } from '../styles';
import { Grid, IconButton, Skeleton } from 'amn-ui-core';
import { usePromiseTracker } from 'react-promise-tracker';
import EditIcon from 'app/assets/images/Credentialing/PersonalInfo/edit.svg';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DegreeSection } from '../EducationCard';
import { useTranslation } from 'react-i18next';
import { theme } from 'styles/global-styles';

function EducationCardUI({ handleToggle, setOpenAddPop, educationArray }) {
  const { classes } = useEducationalCardStyles();
  const { promiseInProgress } = usePromiseTracker({ area: 'get-candidate-details', delay: 0 });
  const { t } = useTranslation();
  const degreeSections = useMemo(() => {
    return educationArray?.map((edu, key) => {
      return (
        <DegreeSection
          degree={edu.degree}
          major={edu?.major}
          school={edu?.school}
          city={edu.location?.city}
          state={edu?.location?.state}
          country={edu.location?.country}
          graduatedOn={edu.graduatedOn}
          index={key}
          key={key}
        />
      );
    });
  }, [educationArray]);
  return (
    <PaperCard elevation={0}>
      {promiseInProgress ? (
        <Grid item mr={2}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Grid>
      ) : (
        <Grid container direction="column" spacing={2} style={{ width: '100%', margin: 0 }}>
          <Grid container>
            <Grid item xs={6}>
              <Title>{t('candidate.educationCard.title')}</Title>
            </Grid>
            <Grid item xs={6} className={classes.editLink}>
              <IconButton
                color="secondary"
                onClick={e => {
                  handleToggle(e);
                }}
                size="medium"
              >
                <img src={EditIcon} alt="Edit" />
              </IconButton>
              <IconButton
                color="secondary"
                onClick={() => {
                  setOpenAddPop(true);
                }}
                style={{ padding: 0 }}
                size="medium"
              >
                <AddCircleOutlineIcon style={{ color: theme.palette.system.cornflowerBlue, padding: 0 }} />
              </IconButton>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item classes={{}} container spacing={7}>
              {degreeSections}
            </Grid>
          </Grid>
        </Grid>
      )}
    </PaperCard>
  );
}

export default EducationCardUI;
