import { getContractEndDate, getDropdownMatchedRate } from '@AMIEWEB/Order/OrderCreationForm/RateCard/BillRates/helper';
import {
  Shifts,
  Status,
} from '@AMIEWEB/Order/OrderCreationForm/RateCard/OffContractRateElementTable/OffContractOrderPage';
import {
  ContractType,
  RateElemenetSource,
  RateElements,
  getGwwDefaultValue,
  getShiftId,
  isNewRateElementRequired,
  refactorValue,
  validateBillRateSource,
} from '@AMIEWEB/Order/OrderCreationForm/RateCard/helper';
import { missingField } from 'app/constants';
import { Status as BillRateStatus } from 'app/enums/Facility';
import moment from 'moment';
import uuid from 'react-uuid';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';

export const getRateElementName = (RateElementNameId: number) => {
  switch (RateElementNameId) {
    case RateElements.GWW:
      return 'GWW';
    case RateElements.MileageReimbursement:
      return 'Mileage Reimbursement';
    case RateElements.TravelBillRate:
      return 'Travel Bill Rate';
    case RateElements.LocalBillRate:
      return 'Local Bill Rate';
    default:
      return missingField;
  }
};

// Get Skill set names array
export const getSkillSetNames = (skillset: string) => {
  return skillset.split(',') ?? [];
};

// Discipline name function below
export const getDisciplineName = (val: number, disciplineOpts = []) => {
  if (!!val) {
    const discipline = disciplineOpts.find(ele => ele.object.ID === val);
    return discipline?.object?.Abbreviation;
  }
  return missingField;
};

// Speciality name function below
export const getSpecialtyName = (val: number, specialtyOpts = []) => {
  if (!!val) {
    const specialty = specialtyOpts.find(ele => ele.object.SpecialtyID === val);
    return specialty?.label;
  }
  return missingField;
};

// Sub Speciality name function below
export const getSubSpecialtyName = (val: number, subSpecialtyOpts = []) => {
  if (!!val) {
    const subSpecialty = subSpecialtyOpts.find(ele => ele.object.SubSpecialtyID === val);
    return subSpecialty?.label;
  }
  return missingField;
};

// get  ratevalue function
export const getRateValue = (value: number, valueMax: number, rateElement: number) => {
  let val = missingField;
  if (rateElement === RateElements.GWW) {
    val = `${value} Hrs`;
  } else if (rateElement === RateElements.MileageReimbursement) {
    val = `$ ${value}`;
  } else {
    if (valueMax > 0) {
      val = `$ ${value} - $ ${valueMax}`;
    } else {
      val = `$ ${value}`;
    }
  }
  return val;
};

// SHIFT COLUMN
export const getShift = (val: number) => {
  let value = 'All';
  if (val) {
    const shift = Shifts.find(ele => ele.id === val);
    value = shift.title;
  }
  return value;
};

//  STATUS COLUMN
export const getStatusName = (val: number) => {
  let value = missingField;
  if (val) {
    // Fecth the status details like approved or rejected from the facility details
    const status = Status.find(ele => ele.id === val);
    value = status.title;
  }
  return value;
};

//Default Values GWW and Mileage reimbursement
export const getDefaultGwwMileageRate = (contractDetails, shifts, effectiveDates, user, skillset) => {
  //Default for GWW and Mileage Reimbursement
  let ocbrBillRatesTemp = [];
  if (shifts.length > 0) {
    shifts.forEach(shiftItem => {
      const defaultValue = getGwwDefaultValue(contractDetails, shiftItem);
      const temp = {
        offContractId: null,
        rateElementId: defaultValue?.rateElementId ? defaultValue?.rateElementId : uuid(),
        disciplineId: null,
        specialtyId: null,
        subSpecialtyId: null,
        shiftId: getShiftId(shiftItem),
        shift: shiftItem,
        type: RateElements.GWW,
        billRate: { isRange: false, value: defaultValue?.hoursPerPayCycle + '' },
        isOcbr: false,
        statusId: null,
        createdAt: null,
        createdBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
        reason: null,
        effectiveStartDate: effectiveDates?.startDate,
        effectiveEndDate: effectiveDates?.endDate,
        lastUpdatedBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
        dateLastUpdated: moment().format(),
        dateCreated: moment().format(),
        source: RateElemenetSource.Contract,
      };
      defaultValue?.hoursPerPayCycle && ocbrBillRatesTemp.push(temp);
    });
  }

  //Mileage reimbursement default value
  const defaultValueMileage = contractDetails?.amountPerMile;
  const defaultMileageId = contractDetails?.mileageRateElementId;
  skillset.forEach(skillSetItem => {
    const temp = {
      offContractId: null,
      rateElementId: defaultMileageId ? defaultMileageId : uuid(),
      disciplineId: skillSetItem.disciplineId,
      specialtyId: skillSetItem.specialtyId,
      subSpecialtyId: skillSetItem.subSpecialtyId,
      shiftId: null,
      shift: null,
      type: RateElements.MileageReimbursement,
      billRate: { isRange: false, value: refactorValue(defaultValueMileage, 3) + '' },
      isOcbr: false,
      statusId: null,
      createdAt: null,
      createdBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
      reason: null,
      effectiveStartDate: effectiveDates?.startDate,
      effectiveEndDate: effectiveDates?.endDate,
      lastUpdatedBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
      dateLastUpdated: moment().format(),
      dateCreated: moment().format(),
      source: RateElemenetSource.Contract,
    };
    defaultValueMileage && ocbrBillRatesTemp.push(temp);
  });

  return ocbrBillRatesTemp;
};

//DEFAULT TRAVEL BILL RATE
export const getDefaultTravelBillRates = (
  localAndTravelInputFieldArray,
  shifts,
  effectiveDates,
  contractDetails,
  user,
  isFlatRate,
) => {
  const ocbrBillRatesTemp = [];
  localAndTravelInputFieldArray?.forEach(skillSetDetails => {
    const skillsetArray = getSkillSetNames(skillSetDetails?.skillSet);
    const dropDownOptionTravel = skillSetDetails?.shiftDetails[0]?.shiftRatesArray;
    const maxValue = getHighestValue(dropDownOptionTravel);
    //Either order has no shift or single shift
    if (shifts.length < 2) {
      const isOcbr =
        maxValue?.valueObj &&
        isBillRateOcbrV2(
          maxValue,
          skillSetDetails?.shiftDetails[0]?.shiftName === null
            ? null
            : getShiftId(skillSetDetails?.shiftDetails[0]?.shiftName),
        );
      const temp = {
        offContractId: null,
        rateElementId: maxValue?.rateElementId,
        disciplineId: skillSetDetails?.disciplineId,
        disciplineAbbreviation: skillsetArray[0],
        specialtyId: skillSetDetails?.specialtyId,
        specialtyName: skillsetArray[1],
        subSpecialtyId: skillSetDetails?.subSpecialtyId,
        subSpecialtyName: skillsetArray[2],
        shiftId:
          skillSetDetails?.shiftDetails[0]?.shiftName === null
            ? null
            : getShiftId(skillSetDetails?.shiftDetails[0]?.shiftName),
        shift: skillSetDetails?.shiftDetails[0]?.shiftName,
        type: RateElements.TravelBillRate,
        billRate: maxValue?.valueObj,
        isOcbr: isOcbr,
        statusId: isOcbr ? BillRateStatus.Unsaved : null,
        createdAt: null,
        createdBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
        reason: null,
        effectiveStartDate: effectiveDates?.startDate,
        effectiveEndDate: effectiveDates?.endDate,
        lastUpdatedBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
        dateLastUpdated: moment().format(),
        dateCreated: moment().format(),
        source: RateElemenetSource.Contract,
      };
      maxValue?.valueObj && ocbrBillRatesTemp.push(temp as any);
    }
    //flat rate is on and more than one shift selected at order level
    const orderEndDate = effectiveDates?.endDate ? moment(effectiveDates.endDate).format() : null;

    if (isFlatRate && shifts.length > 1) {
      shifts.forEach(shiftItem => {
        const matchedRateFromDropdown = getDropdownMatchedRate(
          maxValue.valueObj,
          shiftItem,
          skillSetDetails.shiftDetails[0].shiftRatesArray,
        );
        //if there is matched value create off contract based on it
        if (matchedRateFromDropdown) {
          const matchedRateElement = {
            offContractId:
              matchedRateFromDropdown.source == RateElemenetSource.Facility
                ? matchedRateFromDropdown.offContractId
                : null,
            rateElementId: matchedRateFromDropdown.rateElementId,
            // rateElementId:matchedRateFromDropdown.source == RateElemenetSource.Facility ? matchedRateFromDropdown.offContractId : uuid(),
            disciplineId: skillSetDetails?.disciplineId,
            disciplineAbbreviation: skillsetArray[0],
            specialtyId: skillSetDetails?.specialtyId,
            specialtyName: skillsetArray[1],
            subSpecialtyId: skillSetDetails?.subSpecialtyId,
            subSpecialtyName: skillsetArray[2],
            shiftId: getShiftId(shiftItem),
            shift: shiftItem,
            type: RateElements.TravelBillRate,
            billRate: maxValue?.valueObj,
            isOcbr: matchedRateFromDropdown.source == RateElemenetSource.Facility ? true : false,
            statusId:
              matchedRateFromDropdown.source == RateElemenetSource.Facility ? matchedRateFromDropdown.statusId : null,
            createdAt:
              matchedRateFromDropdown.source == RateElemenetSource.Facility ? matchedRateFromDropdown.createdAt : null,
            createdBy:
              matchedRateFromDropdown.source == RateElemenetSource.Facility
                ? matchedRateFromDropdown.createdBy
                : `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
            reason:
              matchedRateFromDropdown.source == RateElemenetSource.Facility ? matchedRateFromDropdown.reason : null,
            effectiveStartDate:
              matchedRateFromDropdown.source == RateElemenetSource.Facility
                ? matchedRateFromDropdown.effectiveStartDate
                : effectiveDates?.startDate,
            effectiveEndDate:
              matchedRateFromDropdown.source == RateElemenetSource.Facility
                ? matchedRateFromDropdown.effectiveEndDate
                : effectiveDates?.endDate,
            lastUpdatedBy:
              matchedRateFromDropdown.source == RateElemenetSource.Facility
                ? matchedRateFromDropdown.lastUpdatedBy
                : `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
            dateLastUpdated:
              matchedRateFromDropdown.source == RateElemenetSource.Facility
                ? matchedRateFromDropdown.dateLastUpdated
                : moment().format(),
            dateCreated:
              matchedRateFromDropdown.source == RateElemenetSource.Facility
                ? matchedRateFromDropdown.dateCreated
                : moment().format(),
            source: matchedRateFromDropdown.source,
          };
          maxValue?.valueObj && ocbrBillRatesTemp.push(matchedRateElement as any);
          //check if there is a date gap
          const isExtraRateEleRequired =
            matchedRateFromDropdown.source == RateElemenetSource.Facility
              ? isNewRateElementRequired(effectiveDates?.endDate, matchedRateFromDropdown.effectiveEndDate)
              : false;
          if (isExtraRateEleRequired) {
            const extraElementStartDate = moment(
              getContractEndDate(maxValue.valueObj, shiftItem, skillSetDetails.shiftDetails[0].shiftRatesArray),
            )
              .add(1, 'days')
              .format();
            ocbrBillRatesTemp.push({
              ...matchedRateElement,
              offContractId: uuid(),
              rateElementId: uuid(),
              shiftId: getShiftId(shiftItem),
              shift: shiftItem,
              isOcbr: true,
              statusId: BillRateStatus.Unsaved,
              effectiveStartDate: extraElementStartDate,
              effectiveEndDate: orderEndDate,
              source: RateElemenetSource.Order,
              createdAt: null,
              createdBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
              dateCreated: moment().format(),
              lastUpdatedBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
              dateLastUpdated: moment().format(),
              isExtra: true,
            });
          }
        }
        //There is no matching rate in dropdown- create a new off contract for the shift in iteration
        else {
          const newOffcontractRate = {
            offContractId: uuid(),
            rateElementId: uuid(),
            disciplineId: skillSetDetails?.disciplineId,
            disciplineAbbreviation: skillsetArray[0],
            specialtyId: skillSetDetails?.specialtyId,
            specialtyName: skillsetArray[1],
            subSpecialtyId: skillSetDetails?.subSpecialtyId,
            subSpecialtyName: skillsetArray[2],
            shiftId: getShiftId(shiftItem),
            shift: shiftItem,
            type: RateElements.TravelBillRate,
            billRate: maxValue?.valueObj,
            isOcbr: true,
            statusId: BillRateStatus.Unsaved,
            createdAt: null,
            createdBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
            reason: null,
            effectiveStartDate: effectiveDates?.startDate,
            effectiveEndDate: effectiveDates?.endDate,
            lastUpdatedBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
            dateLastUpdated: moment().format(),
            dateCreated: moment().format(),
            source: RateElemenetSource.Order,
          };
          maxValue?.valueObj && ocbrBillRatesTemp.push(newOffcontractRate as any);
        }
      });
      //Create a dummy rate element for Flate Rate On consdition
      const flateRateOnElement = {
        offContractId: uuid(),
        rateElementId: maxValue?.rateElementId,
        disciplineId: skillSetDetails?.disciplineId,
        disciplineAbbreviation: skillsetArray[0],
        specialtyId: skillSetDetails?.specialtyId,
        specialtyName: skillsetArray[1],
        subSpecialtyId: skillSetDetails?.subSpecialtyId,
        subSpecialtyName: skillsetArray[2],
        shiftId: null,
        shift: null,
        type: RateElements.TravelBillRate,
        billRate: maxValue?.valueObj,
        isOcbr: maxValue?.shiftId ? true : false,
        statusId: maxValue?.shiftId ? BillRateStatus.Unsaved : ContractType.OnContract,
        createdAt: null,
        createdBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
        reason: null,
        effectiveStartDate: effectiveDates?.startDate,
        effectiveEndDate: effectiveDates?.endDate,
        lastUpdatedBy: `${user.userInfo?.firstName ?? ''} ${user.userInfo?.lastName ?? ''}`,
        dateLastUpdated: moment().format(),
        dateCreated: moment().format(),
      };
      maxValue?.valueObj && ocbrBillRatesTemp.push(flateRateOnElement as any);
    }
  });
  return ocbrBillRatesTemp;
};

const isBillRateOcbrV2 = (maxValue, shiftId) => {
  if (maxValue?.groupShiftId !== shiftId) return false;
  else return true;
};
const getHighestValue = dropDownOptions => {
  const option =
    !!dropDownOptions.length &&
    dropDownOptions.reduce((prev, curr) => {
      if (curr?.value && prev?.value && validateBillRateSource(curr, RateElemenetSource.Contract)) {
        return prev?.value > curr.value ? prev : curr;
      } else if (curr?.value && validateBillRateSource(curr, RateElemenetSource.Contract)) return curr;
      else if (prev?.value) return prev;
    });
  const valueObj =
    option?.value && validateBillRateSource(option, RateElemenetSource.Contract)
      ? { isRange: false, value: option?.value }
      : null;
  return {
    valueObj,
    shiftId: option?.groupShiftId,
    rateElementId: option?.rateElementId,
  };
};

//@Team @Souharda - remove these function if not used
/**
 * Check if OCBR
 */
const isBillRateOcbr = (valueObj, skillSetDetails, shiftID, contractDetails) => {
  if (valueObj?.isRange) {
    return true;
  } else {
    const matchedSkillset = (contractDetails?.billRates || []).find(
      item =>
        item?.disciplineId === skillSetDetails?.disciplineId &&
        item?.specialtyId === skillSetDetails?.specialtyId &&
        item?.subspecialtyId === skillSetDetails?.subSpecialtyId,
    );
    if (!matchedSkillset) return true;
    else {
      //@ts-ignore
      const foundMatch = matchedSkillset?.shifts.find(item => item?.shiftId === shiftID || item?.shiftId === null);
      const cotractValue = foundMatch?.regularRate ? `${refactorValue(foundMatch?.regularRate, 2)}` : null;
      return cotractValue === `${valueObj?.value}` ? false : true;
    }
  }
};

export const isOrderEffectiveDatesValid = (startdate, enddate) => {
  if (!startdate || !enddate) return true;

  return false;
};

/**
 * @returns decimal point based on the rate element type
 */
export const getDecimalValue = () =>
  customSwitch({
    [RateElements.TravelBillRate]: 2,
    [RateElements.LocalBillRate]: 2,
    [RateElements.GWW]: 0,
    [RateElements.ExpectedHours]: 0,
    [RateElements.MileageReimbursement]: 3,
  })('error');
