import React from 'react';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HearingIcon from '@mui/icons-material/Hearing';
import { makeStyles } from 'tss-react/mui';


const useStyles = makeStyles()({
  icon: {
    '& .MuiSvgIcon-root': {
      height: '16px',
    }
  }
})
const SkillSetsCell = props => {
  const preferenceSkillSets = props?.data?.preferenceSkills?.join('/');
  const verifiedSkillSets = props?.data?.verifiedSkills?.join('/');
  const unVerifiedSkills = props?.data?.unVerifiedSkills?.join('/');
  const { classes } = useStyles();
  return (
    <div>
      {props?.data?.preferenceSkills ? props?.data?.preferenceSkills.map(item => (
         <>
        <p style={{ width: '60%', marginTop: 0, marginBottom: 0, margin: '3px 0px 0px 11px' }}>{item?.replaceAll(',','-')} </p>
       </>
      )) : (
        <>
        {props?.data?.verifiedSkills?.length > 0 &&  props?.data?.verifiedSkills?.map(item =>(
          <div style={{display : 'flex', minHeight: '11px'}}>
            <p style={{ width: '60%', marginTop: 0, marginBottom: 0, margin: '3px 0px 0px 11px' }}>{item?.replaceAll(',','-')} </p>
            <DoneAllIcon color="success" fontSize='small' style={{margin: '3px 2px', height: '16px'}} className={classes.icon}/>
          </div>
        ))}
        {props?.data?.unVerifiedSkills?.length > 0 &&  props?.data?.unVerifiedSkills?.map(item =>(
           <div style={{display : 'flex', minHeight: '11px'}}>
          <p style={{ width: '60%', marginTop: 0, marginBottom: 0, margin: '3px 0px 0px 11px' }}>{item?.replaceAll(',','-')}</p>
           <HearingIcon fontSize='small' style={{margin: '3px 2px', height: '16px'}} className={classes.icon}/>
         </div>
        ))}
        </>
      )}
    </div>
  );
};

export default SkillSetsCell;
