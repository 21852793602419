export const EMOJI_LIST = [
  { id: '1', name: 'Grinning face with big eyes', value: '😃' },
  { id: '2', name: 'Grinning face with smiling eyes', value: '😄' },
  { id: '3', name: 'Grinning squinting face', value: '😆' },
  { id: '4', name: 'Grinning face with sweat', value: '😅' },
  { id: '5', name: 'Face with tears of joy', value: '😂' },
  { id: '6', name: 'Slightly smiling face', value: '🙂' },
  { id: '7', name: 'Upside-down face', value: '🙃' },
  { id: '8', name: 'Winking face', value: '😉' },
  { id: '9', name: 'Smiling face with smiling eyes', value: '😊' },
  { id: '10', name: 'Smiling face with halo', value: '😇' },
  { id: '11', name: 'Smiling face with heart-eyes', value: '😍' },
  { id: '12', name: 'Face savoring food', value: '😋' },
  { id: '13', name: 'Money-mouth face', value: '🤑' },
  { id: '14', name: 'Thinking face', value: '🤔' },
  { id: '15', name: 'Zipper-mouth face', value: '🤐' },
  { id: '16', name: 'Neutral face', value: '😑' },
  { id: '17', name: 'Unamused face', value: '😒' },
  { id: '18', name: 'Pensive face', value: '😔' },
  { id: '19', name: 'Sleeping face', value: '😴' },
  { id: '20', name: 'Face with thermometer', value: '🤒' },
  { id: '21', name: 'Face with crossed-out eyes', value: '😵' },
  { id: '22', name: 'Cowboy hat face', value: '🤠' },
  { id: '23', name: 'Partying face', value: '🥳' },
  { id: '24', name: 'Smiling face with sunglasses', value: '😎' },
  { id: '25', name: 'Frowning face', value: '☹️' },
  { id: '26', name: 'Hushed face', value: '😯' },
  { id: '27', name: 'Loudly crying face', value: '😭' },
  { id: '27', name: 'Face with steam from nose', value: '😤' },
];
