import { FilterCompTypes } from 'app/ComponentLibrary/Filter/utils';

export enum PlacementsFilterName {
  placementId = 'placementId',
  candidateName = 'candidateName',
  startDate = 'startDate',
  placementStatus = 'placementStatus',
  clientName = 'clientName',
  disciplineSpecialty = 'disciplineSpecialty',
  candidateId = 'candidateId',
}

export const defaultFilterValues = {
  [PlacementsFilterName.placementId]: [],
  [PlacementsFilterName.candidateName]: [],
  [PlacementsFilterName.placementStatus]: [],
  [PlacementsFilterName.clientName]: [],
  [PlacementsFilterName.disciplineSpecialty]: [],
  [PlacementsFilterName.candidateId]: [],
  [PlacementsFilterName.startDate]: null,
};

export const getFilterSpecs = (filterSpecs, t, styleClasses) => [
  {
    filterName: t('home.credentialingWorkDesk.placementFilter.placementId'),
    name: PlacementsFilterName.placementId,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs?.placementIDOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.placementIdField,
  },
  {
    filterName: t('home.credentialingWorkDesk.placementFilter.candidateName'),
    name: PlacementsFilterName.candidateName,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs?.candidateNameOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.candidateNameField,
    returnsObjectAsValue: true,
  },
  {
    filterName: t('home.credentialingWorkDesk.placementFilter.startDate'),
    name: PlacementsFilterName.startDate,
    placeholder: 'Placement Start Date',
    type: FilterCompTypes.DATERANGEV2,
    clickAway: true,
    variant: 'outlined',
  },
  {
    filterName: t('home.credentialingWorkDesk.placementFilter.placementStatus'),
    name: PlacementsFilterName.placementStatus,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs?.placementStatusOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    returnsObjectAsValue: true,
    selectFieldStyle: styleClasses.placementStatusField,
  },
  {
    filterName: t('home.credentialingWorkDesk.placementFilter.clientName'),
    name: PlacementsFilterName.clientName,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs?.clientNameOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.clientNameField,
    returnsObjectAsValue: true,
  },
  {
    filterName: t('home.credentialingWorkDesk.placementFilter.disciplineSpecialty'),
    name: PlacementsFilterName.disciplineSpecialty,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs?.disciplineSpecilityOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.disciplineSpecialtyField,
  },
  {
    filterName: t('home.credentialingWorkDesk.placementFilter.candidateId'),
    name: PlacementsFilterName.candidateId,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs?.candidateIdOpts,
    placeholder: 'Select',
    selectAllValue: 'All',
    version2: true,
    selectFieldStyle: styleClasses.candidateIdField,
  },
];
