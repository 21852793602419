import React from 'react';
import { Grid, Theme } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';
import StarIcon from '@mui/icons-material/Star';
import Chip from '@mui/material/Chip';
import StatusTile, { StatusTileProps } from '@AMIEWEB/Unit/Common/StatusTile';
import { makeStyles } from 'tss-react/mui';
import { transformUnitExperienceForStatusTile } from '../Qualifications/helper';
import { ISkillSetInfo } from '@AMIEWEB/Facility/FacilityStore/Facility.model';

const useStyles = makeStyles()((theme: Theme) => ({
  starIcon: {
    color: theme.palette.system.buttonGray,
  },
}));

const SkillSetTitle = ({ skillSetData, isPrimary }: { skillSetData: ISkillSetInfo; isPrimary: boolean }) => {
  const { classes } = useStyles();
  const { t: translation } = useTranslation();
  const transformSkillSet: StatusTileProps = transformUnitExperienceForStatusTile(skillSetData);

  return (
    <Grid container alignItems="center" columnSpacing={2}>
      {transformSkillSet && (
        <Grid item sx={{ width: '310px' }}>
          <StatusTile tileData={transformSkillSet} />
        </Grid>
      )}

      {skillSetData?.isNewGrad && (
        <Grid item>
          <Chip label={translation('facility.units.experience.newGradTag')} />{' '}
        </Grid>
      )}

      {isPrimary && (
        <Grid item>
          <Chip
            icon={
              <StarIcon
                sx={{
                  fontSize: 'medium',
                }}
              />
            }
            label={translation('facility.units.unitDetails.unitRequirements.primarySkillSet')}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default SkillSetTitle;
