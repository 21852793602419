import { StatusDropDownValues } from '@AMIEWEB/Common/StatusDropDown/StatusDropDown';
import { ContactStatus } from 'app/enums/Unit';
import moment from 'moment';

export const getAbbreviation = (firstName: string, lastName: string) => {
  const abbreviatedFirstName = firstName.charAt(0).toUpperCase();
  const abbreviatedLastName = lastName.charAt(0).toUpperCase();
  return `${abbreviatedFirstName}${abbreviatedLastName}`;
};

export const getStatusIdForContacts = (contractStatus: string) => {
  switch (contractStatus) {
    case StatusDropDownValues.ACTIVE:
      return ContactStatus.Active;

    case StatusDropDownValues.INACTIVE:
      return ContactStatus.Inactive;
  }
};

export const setUpdatedByClientContract = (statusLastUpdated: string | Date) => {
  return `${statusLastUpdated ? moment(statusLastUpdated).format('MM/DD/YYYY') : moment().format('MM-DD-YYYY')}`;
};
