/**All Update call saga functions */

import { trackException } from 'app-insights/appInsightsTracking';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { ExceptionType } from 'app/enums/Common';
import { IAutoTaskTaskStatus } from 'app/models/AutoTaskManagement/AutoTaskManagement';
import { PlacementRequestService } from 'app/services/PlacementRequestService/placementRequestService';
import { httpSuccess } from 'app/services/serviceHelpers';
import { IUser } from 'oidc/user.redux';
import { selectUser } from 'oidc/user.selectors';
import { trackPromise } from 'react-promise-tracker';
import { call, getContext, put, select } from 'redux-saga/effects';
import { getUpdateAutoTaskManagmentPaylod } from './business.saga';
import { selectAssignedRoles, selectCurrentStatus, selectSelectedGridRow } from './selectors';
import { placementRequestsAction } from './slice';
import i18next from 'i18next';

const TrackPostOperationsAPI = (fn, ...args) => trackPromise(fn(...args), 'update-auto-task-management');

export function* updateAutoTaskManagement() {
  const defaultRowData = yield select(selectSelectedGridRow);
  const userData: IUser = yield select(selectUser);
  const assignedRoles: number[] = yield select(selectAssignedRoles);
  const currentStatus: IAutoTaskTaskStatus = yield select(selectCurrentStatus);
  const updatePayload = getUpdateAutoTaskManagmentPaylod(
    defaultRowData,
    userData?.userInfo?.employeeId,
    assignedRoles,
    currentStatus.statusId,
  );
  try {
    const AutoTaskManagementService: PlacementRequestService = yield getContext('placementRequestService');
    const response = yield call(
      TrackPostOperationsAPI,
      AutoTaskManagementService.updateAutoTaskManagement,
      updatePayload,
    );
    yield put(placementRequestsAction.setAutoTaskUpdateResponse(response.data));
    if (httpSuccess(response.status)) {
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('alerts.saveSuccess'),
          severity: 'success',
        }),
      );
    } else {
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('taskDetails.updateNotSaved'),
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    if (error?.response) {
      yield put(placementRequestsAction.setAutoTaskUpdateResponse(error?.response?.data));
    } else {
      if (!error?.response?.data || error?.response?.data?.responseCode < 0) {
        yield put(
          globalActions.setSnackBar({
            message: i18next.t('taskDetails.updateNotSaved'),
            severity: 'error',
          }),
        );
      }
      trackException({
        exception: error,
        properties: {
          name: ExceptionType.APIRequestError,
          functionName: 'updateAutoTaskManagement',
          area: 'src/store/redux-store/placement-requests/update.saga.ts',
        },
      });
    }
  }
}
