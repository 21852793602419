/* eslint-disable i18next/no-literal-string */
import { TableRow, TableCell, Checkbox, IconButton, Menu, MenuItem } from 'amn-ui-core';
import { trackEvent } from 'app-insights/appInsightsTracking';
import { IconWithText } from 'app/components/ClientContact/IconWithText';
import { ICustomHeader } from 'app/models/Grid';
import React from 'react';
import { MultiLineSpan } from '../MultiLineSpan/MultiLineSpan';
import HoverView from './HoverView';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownwardIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpwardIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import HighlightStarIcon from 'app/assets/images/CandidateProfile/highlightStar.svg';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { isCredentialing, isRecruitment } from 'oidc/userRoles';
interface GridTableRowProps {
  columns;
  row;
  rowKey;
  refList;
  editingIndex;
  searchHighlight;
  handleRowSelect;
  selectDisabled;
  searchFoundIndex;
  disableRowColors;
  rowSelectionKey;
  hasExpandableRow;
  staticFirstColumn;
  highlightedRow;
  component;
  _onNavigation;
  onStateNavigation;
  _onValueChanged;
  expandRow;
  centerHoverView;
  gridName;
  onVersionView;
  rowIconAction;
  starIconAction;
  hasEditMenu;
  rowExpand;
  hasSingleEditIcon;
  canEdit;
  handlePopupClose;
  setOpenEditNotAvailable;
  analytics;
  handleClick;
  hasPermissions;
  editRow;
  editRowKey;
  _willDelete;
  expandedSubRow: boolean; // child row to an expanded row
  classes;
  onElementClick;
  restrictRolePermissions?;
}

export const GridTableRow = ({
  columns,
  row,
  rowKey,
  refList,
  editingIndex,
  searchHighlight,
  handleRowSelect,
  selectDisabled,
  searchFoundIndex,
  disableRowColors,
  rowSelectionKey,
  hasExpandableRow,
  staticFirstColumn,
  highlightedRow,
  component,
  _onNavigation,
  onStateNavigation,
  _onValueChanged,
  expandRow,
  centerHoverView,
  gridName,
  onVersionView,
  rowIconAction,
  starIconAction,
  hasEditMenu,
  rowExpand,
  hasSingleEditIcon,
  canEdit,
  handlePopupClose,
  setOpenEditNotAvailable,
  analytics,
  handleClick,
  hasPermissions,
  editRow,
  editRowKey,
  _willDelete,
  expandedSubRow,
  classes,
  onElementClick,
  restrictRolePermissions,
}: GridTableRowProps) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const editRef = React.useRef<any>(null);
  const [anchorEl2, setAnchorEl2] = React.useState<HTMLElement | null>(null);
  const setEditKey = data => {
    editRef.current = data;
  };

  const getURL = (navigationExp: string, navigationIDs: string[] | null): string | null => {
    //To Avoid clicks in navigations jobMatch gridview
    if (!navigationExp) {
      return null;
    }
    if (!navigationIDs) {
      return navigationExp;
    }

    let newNavigationExp = navigationExp;
    navigationIDs?.forEach((x, i) => {
      newNavigationExp = newNavigationExp.replace(`{${i}}`, x);
    });
    return newNavigationExp;
  };

  const _handleClose = React.useCallback(() => {
    setAnchorEl2(null);
  }, []);

  const onMenuClick = React.useCallback(
    e => {
      if (!hasPermissions?.canEdit) {
        if (!row?.isEditing) {
          editRow((anchorEl2 as unknown as any)?.dataset?.rowid);
          _handleClose();
        }
        _handleClose();
      } else return null;
    },
    [_handleClose, anchorEl2, editRow, hasPermissions, row?.isEditing],
  );

  const onMenuIconClicked = React.useCallback(
    e => {
      trackEvent({
        type: 'click',
        name: `${analytics?.tableName}:Eclipse Icon Clicked`,
      });
      setEditKey(row.rowID);
      setAnchorEl2(e.currentTarget);
      handleClick(e, rowKey, row.rowID);
    },
    [analytics, handleClick, row, rowKey],
  );

  const rowBackground =
    expandedSubRow || row.viewVersions
      ? classes.expandedVersionRow
      : row.expanded || searchFoundIndex === row.rowID
      ? classes.expandedRow
      : highlightedRow === rowKey
      ? classes.highlightedRow
      : '';

  return (
    <TableRow
      data-rowid={row.rowID}
      data-editingrow={row.isEditing && rowKey === editingIndex}
      innerRef={refList[rowKey]}
      className={`${classes.table}`}
    >
      {rowSelectionKey ? (
        <TableCell key={rowKey} className={rowBackground}>
          <Checkbox
            classes={{ checked: classes.checked }}
            disabled={selectDisabled && !row.selected}
            checked={row.selected || false}
            disableFocusRipple
            disableTouchRipple
            style={{ background: 'none', padding: '0px' }}
            onChange={e => handleRowSelect(e, row, row?.rowId ?? 0)}
          />
        </TableCell>
      ) : null}
      {starIconAction ? (
        <TableCell key={rowKey}>
          <IconButton
            color="secondary"
            // classes={{ root: classes.iconButton }}
            onClick={e => {
              starIconAction?.onClick(row.contactId);
            }}
            size="medium"
          >
            {row.isCheckBoxReq && row.selected && row.isPrimary ? (
              <img src={HighlightStarIcon} />
            ) : row.isPrimary ? (
              <img src={HighlightStarIcon} />
            ) : (
              <StarBorderOutlinedIcon />
            )}
          </IconButton>
        </TableCell>
      ) : null}
      {hasExpandableRow && (
        <TableCell
          data-searchhighlight={searchHighlight && searchFoundIndex === row.rowID ? 'highlight' : ''}
          className={`${classes.tablecell} ${classes.expandedTableCell} ${rowBackground} ${
            rowExpand ? classes.staticRowCell : ''
          }`}
          onClick={!row?.isEditing ? () => expandRow(rowKey, !row.expanded) : undefined}
        >
          {row.expandable && !row?.isEditing && (
            <React.Fragment>
              {!row.expanded ? <KeyboardArrowDownwardIcon /> : <KeyboardArrowUpwardIcon />}
            </React.Fragment>
          )}
          {rowExpand ? row.status : null}
        </TableCell>
      )}

      {!hasExpandableRow && rowExpand && (
        <TableCell
          data-searchhighlight={searchHighlight && searchFoundIndex === row.rowID ? 'highlight' : ''}
          className={`${classes.tablecell} ${rowBackground} ${rowExpand ? classes.staticRowCell : ''}`}
        >
          {rowExpand ? row.status : null}
        </TableCell>
      )}
      {columns.map((header: ICustomHeader, key) => (
        <React.Fragment key={key}>
          {/* {staticFirstColumn && key === 1 && <TableCell className={`${classes.staticSecondRowCell}`} />} */}
          {!header.hidden && (
            <TableCell
              data-searchhighlight={searchHighlight && searchFoundIndex === row.rowID ? 'highlight' : ''}
              className={`${classes.tablecell} 
            ${rowBackground} ${staticFirstColumn && key === 0 ? classes.staticRowCell : ''} ${header.cellClass || ''}`}
              colSpan={header?.colSpan || 0}
            >
              {header.clickable && row[header.title] !== '--' && row[header.title] !== '— ' ? (
                <HoverView
                  component={component}
                  rowID={row.rowID}
                  isModalOpen={header.isModalOpen}
                  text={row[header.title]}
                  tooltipAttributes={row[header.tooltipAttributes as unknown as string]}
                  header={header}
                  link={getURL(
                    header.navigationExp as string,
                    (header.navigationIds as string[])?.map(item => row[item]),
                  )}
                  gridName={gridName}
                  data={row}
                  searchInitiation={searchHighlight && searchFoundIndex === row.rowID}
                  onNavigation={_onNavigation}
                  onClick={() => onElementClick(header.title)}
                  onStateNavigation={onStateNavigation}
                  centerHoverView={centerHoverView}
                  filterOptionsOverride={row[`${header.title}FilterOptions`]}
                  disableRowColors={disableRowColors}
                  edit={{
                    rowKey: rowKey,
                    key: header.title,
                    isEditing: row?.isEditing,
                    onValueChanged: _onValueChanged,
                  }}
                  restrictRolePermissions={restrictRolePermissions}
                />
              ) : (
                <React.Fragment>
                  {row[header.title] === null && !header.control ? (
                    <span></span>
                  ) : (
                    <React.Fragment>
                      {header.versioning ? (
                        // <Box
                        //   className={
                        //     !row.isEditing && (row.versionRows?.length || 0) > 0
                        //       ? classes.pointer
                        //       : classes.disabledPointer
                        //   }
                        //   onClick={
                        //     !row.isEditing && (row.versionRows?.length || 0) > 0
                        //       ? () => onVersionView(rowKey)
                        //       : undefined
                        //   }
                        // >
                        //   {row[header.title]}
                        // </Box>
                        <IconButton
                          color="secondary"
                          aria-label="more"
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          // classes={{ root: classes.iconButton }}
                          className={`${
                            (row.expanded || row?.isEditing) && !row.viewVersions && !row.exceptionWaive
                              ? classes.tableHeaderExpanded
                              : ''
                          }  ${
                            !row?.isEditing && (row.versionRows?.length || 0) > 0
                              ? classes.pointer
                              : classes.disabledPointer
                          }`}
                          onClick={
                            !row.isEditing && (row.versionRows?.length || 0) > 0
                              ? () => onVersionView(rowKey)
                              : undefined
                          }
                          disabled={!row?.isEditing && (row.versionRows?.length || 0) > 0 ? false : true}
                          title={!row?.isEditing && (row.versionRows?.length || 0) > 0 ? '' : 'versions not available'}
                          size="medium"
                        >
                          {row[header.title]}
                        </IconButton>
                      ) : header.icon ? (
                        <img src={row[header.title]} alt={t('search.grid.icon')}></img>
                      ) : header.materialUIIcon ? (
                        <React.Fragment>{row[header.title]}</React.Fragment>
                      ) : header.iconWithText ? (
                        <IconWithText data={row[header.title]} />
                      ) : (
                        <React.Fragment>
                          {header.multiLine ? (
                            header.hasHoverView ? (
                              <HoverView
                                text={row[header.title]}
                                tooltipAttributes={row[header.tooltipAttributes as unknown as string]}
                                header={header}
                                data={row}
                                searchInitiation={searchHighlight && searchFoundIndex === row.rowID}
                                centerHoverView={centerHoverView}
                                onStateNavigation={onStateNavigation}
                                filterOptionsOverride={row[`${header.title}FilterOptions`]}
                                disableRowColors={disableRowColors}
                                edit={{
                                  rowKey: rowKey,
                                  key: header.title,
                                  isEditing: row?.isEditing,
                                  onValueChanged: _onValueChanged,
                                }}
                                restrictRolePermissions={restrictRolePermissions}
                              />
                            ) : (
                              <MultiLineSpan data={row[header.title]}></MultiLineSpan>
                            )
                          ) : (
                            <HoverView
                              text={row[header.title]}
                              tooltipAttributes={row[header.tooltipAttributes as unknown as string]}
                              header={header}
                              data={row}
                              searchInitiation={searchHighlight && searchFoundIndex === row.rowID}
                              centerHoverView={centerHoverView}
                              onStateNavigation={onStateNavigation}
                              filterOptionsOverride={row[`${header.title}FilterOptions`]}
                              disableRowColors={disableRowColors}
                              gridName={gridName}
                              edit={{
                                rowKey: rowKey,
                                key: header.title,
                                isEditing: row?.isEditing,
                                onValueChanged: _onValueChanged,
                              }}
                              restrictRolePermissions={restrictRolePermissions}
                            />
                          )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </TableCell>
          )}
        </React.Fragment>
      ))}
      {rowIconAction && (
        <TableCell
          data-searchhighlight={searchHighlight && searchFoundIndex === row.rowID ? 'highlight' : ''}
          className={`${classes.tablecell} ${classes.kababMenu} ${!row?.isEditing ? classes.pointer : ''} 
      ${rowBackground}`}
        >
          <IconButton
            color="secondary"
            aria-label="more"
            aria-controls="simple-menu"
            aria-haspopup="true"
            // classes={{ root: classes.iconButton }}
            className={row.expanded && !row.viewVersions ? classes.tableHeaderExpanded : ''}
            onClick={e => {
              if (!row?.isEditing) {
                rowIconAction?.onClick(row.rowID);
              }
            }}
            size="medium"
          >
            {rowIconAction.icon}
          </IconButton>
        </TableCell>
      )}
      {hasEditMenu && (
        <TableCell
          data-searchhighlight={searchHighlight && searchFoundIndex === row.rowID ? 'highlight' : ''}
          className={`${classes.tablecell} ${classes.kababMenu} ${!row?.isEditing ? classes.pointer : ''} 
        ${rowBackground}`}
        >
          {!row?.isEditing && (
            <>
              {hasSingleEditIcon ? (
                <IconButton
                  color="secondary"
                  aria-label="more"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  // classes={{ root: classes.iconButton }}
                  className={row.expanded && !row.viewVersions ? classes.tableHeaderExpanded : ''}
                  onClick={e => {
                    if (!row?.isEditing) {
                      if (canEdit(row)) handlePopupClose(row.rowID);
                      else setOpenEditNotAvailable(true);
                    }
                  }}
                  size="medium"
                >
                  <EditIcon />
                </IconButton>
              ) : (
                <React.Fragment>
                  <IconButton
                    color="secondary"
                    data-rowid={row.rowID}
                    aria-label="more"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    // classes={{ root: classes.iconButton }}
                    className={row.expanded && !row.viewVersions ? classes.tableHeaderExpanded : ''}
                    onClick={onMenuIconClicked}
                    size="small"
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl2}
                    keepMounted
                    open={Boolean(anchorEl2)}
                    onClose={_handleClose}
                    PaperProps={{
                      style: {
                        boxShadow: 'none',
                        border: '1px solid #d1d1d1',
                      },
                    }}
                  >
                    <MenuItem
                      onClick={onMenuClick}
                      disabled={
                        hasPermissions?.canEdit ||
                        row.versioning === null ||
                        (isRecruitment(user?.userInfo?.roles || []) &&
                          !isCredentialing(user?.userInfo?.roles || []) &&
                          !row.hasOwnProperty('licenseNumber')) ||
                        (isRecruitment(user?.userInfo?.roles || []) &&
                          !isCredentialing(user?.userInfo?.roles || []) &&
                          row.hasOwnProperty('licenseNumber') &&
                          row.verifiedID)
                      }
                    >
                      {t('search.grid.editMenu.edit')}
                    </MenuItem>
                    <MenuItem
                      onClick={e => {
                        if (!hasPermissions?.canDelete) {
                          _willDelete();
                          _handleClose();
                        } else return null;
                      }}
                      disabled={hasPermissions?.canDelete && row.verifiedID}
                    >
                      {t('search.grid.editMenu.delete')}
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </>
          )}
        </TableCell>
      )}
    </TableRow>
  );
};
