import { getOpenOrdersCountForFacility } from '../OrderServices/OrderServices';
import baseAxiosInstance from 'utils/BaseApi';
import { NewUnit } from 'app/models/Unit/CreateUnit';
import { FacilityResponse } from 'app/models/Facility/FacilityDetails';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import {
  IFacilityRegionUpdateCommand,
  IRequestApprovalDocument,
  IUpdateDrugScreenPolicyCommand,
  IUpdateFacilityInstructionsCommand,
  IUpdateInternalContactCommand,
  IUpdateReferencesCommand,
} from '@AMIEWEB/Facility/FacilityStore/Facility.model';
import { IBestBets } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/GeneralTab/AtAGlance/ManageBestBetsForFacility';

const facilityBase = globalThis?.app?.env?.REACT_APP_FACILITIES_SERVICE_BASE_URL;

export async function searchFacilities(searchString: string, pageNumber = 1) {
  try {
    const response = await baseAxiosInstance.post(`${facilityBase}/Facilities/search`, {
      keyword: searchString,
      pageNumber,
    });
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'searchFacilities',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return null;
  }
}

export async function searchSkillsets(keyword: string) {
  try {
    const response = await baseAxiosInstance.post(`${facilityBase}/units/skillchecklist?keyword=${keyword}`);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'searchSkillsets',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return null;
  }
}

export async function getFacilityDetails(id: number) {
  try {
    const response = await baseAxiosInstance.get(`${facilityBase}/Facilities/Facility/${id}`);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getFacilityDetails',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return null;
  }
}
export async function getFacilityDetailsWithOpenOrderCount(
  id: number,
): Promise<{ facilityResponse: FacilityResponse | null; openOrdersCount: number }> {
  const [facilityResponse, openOrdersCount] = await Promise.all([
    getFacilityDetails(id),
    getOpenOrdersCountForFacility(id),
  ]);

  return {
    facilityResponse,
    openOrdersCount,
  };
}
export async function getUnitsForFacility(facilityId: number) {
  try {
    const response = await baseAxiosInstance.get(`${facilityBase}/units/facility/${facilityId}`);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getUnitsForFacility',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return null;
  }
}

export async function getUnitDetailsForFacility(facilityId: number, unitId: number) {
  try {
    const response = await baseAxiosInstance.get(`${facilityBase}/units/details/unit/${unitId}/facility/${facilityId}`);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getUnitDetailsForFacility',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return null;
  }
}

export async function getUnitsFilterForFacility(facilityId: number) {
  try {
    const response = await baseAxiosInstance.get(`${facilityBase}/units/facility/${facilityId}?withfilters=true`);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getUnitsFilterForFacility',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return null;
  }
}

export async function getUnitDetails(unitId: number) {
  try {
    const response = await baseAxiosInstance.get(`${facilityBase}/units/unit/${unitId}`);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getUnitDetails',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
  }
}

export async function getFacilityClientContacts(id: number, pageNumber?: number, pageSize?: number) {
  try {
    const response = await baseAxiosInstance.post(`${facilityBase}/Facilities/facility/clientcontacts`, {
      facilityId: id,
      pageNumber: pageNumber ?? 1,
      pageSize: pageSize ?? 1000,
    });
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getFacilityClientContacts',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return null;
  }
}

export async function updateStarFacilityClientContacts(data: any) {
  try {
    const response = await baseAxiosInstance.post(`${facilityBase}/Facilities/facility/primarycontacts`, data);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateStarFacilityClientContacts',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return null;
  }
}

export async function createUnit(data: NewUnit) {
  try {
    const response = await baseAxiosInstance.post(`${facilityBase}/units/unit/save`, data);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'createUnit',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return error.response?.data ? error.response?.data : 'An error occurred while creating new unit';
  }
}

export async function createNewUnit(data: any) {
  try {
    const response = await baseAxiosInstance.post(`${facilityBase}/units/unit/save`, data);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'createNewUnit',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return error.response?.data ? error.response?.data : 'An error occurred while creating new unit';
  }
}

export async function getClientContact(searchString: string, pageNumber = 1) {
  try {
    const response = await baseAxiosInstance.post(`${facilityBase}/ClientContacts/search`, {
      keyword: searchString,
      pageNumber,
    });
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getClientContact',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return 'error';
  }
}

export async function getFacilityContract(id: number) {
  try {
    const response = await baseAxiosInstance.get(`${facilityBase}/Facilities/facility/contractdetails/${id}`);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getFacilityContract',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return 'error';
  }
}

export async function getAdditionalContractDetail(id: number) {
  try {
    const response = await baseAxiosInstance.get(
      `${facilityBase}/Facilities/facility/additional-contract-details/${id}`,
    );
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getAdditionalContractDetail',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return 'error';
  }
}

export async function getFacilityContractDiscipline(id: number) {
  try {
    const response = await baseAxiosInstance.get(`${facilityBase}/Facilities/facility/disciplines/${id}`);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getFacilityContractDiscipline',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return 'error';
  }
}

export async function getDisciplineSpecialityRates(request: any) {
  try {
    const response = await baseAxiosInstance.post(`${facilityBase}/Facilities/facility/specialtyrates`, request);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getDisciplineSpecialityRates',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
  }
}

export async function getRateElements(request: any) {
  try {
    const response = await baseAxiosInstance.post(`${facilityBase}/facilities/facility/rate-elements`, request);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getRateElements',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
  }
}

/**
 * Saves only off contract details to COSMOS DB
 * @param request
 * @returns Off contract details
 */
export async function postOffcontractDetails(payload: any) {
  try {
    const response = await baseAxiosInstance.post(`${facilityBase}/offcontract/offcontract/save`, payload);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postOffcontractDetails',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
  }
}

export async function getOffContractRateElements(payload: any) {
  try {
    const response = await baseAxiosInstance.post(`${facilityBase}/offcontract/rateelements/search`, payload);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getOffContractRateElements',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
  }
}

export async function getOffContractApprovalDocument(payload: IRequestApprovalDocument) {
  try {
    const response = await baseAxiosInstance.get(
      `${facilityBase}/offcontract/relateditems/facility/${payload.facilityId}/rateelement/${payload.rateElementId}`,
    );
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getOffContractApprovalDocument',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
  }
}

/**
 * Updates rateElements to COSMOS DB
 * @param request
 * @returns Updated RateElements
 */
export async function postRateElements(payload: any) {
  try {
    const response = await baseAxiosInstance.post(`${facilityBase}/offcontract/rateelement/update`, payload);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postRateElement',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
  }
}

/**
 * Save attachments to Blob storage and refrences in COSMOS DB
 * @param request
 * @returns saves attachments in blob and cosmos db
 */
export async function saveOCBRAttachments(payload: any) {
  try {
    const response = await baseAxiosInstance.post(`${facilityBase}/offcontract/attachment/save`, payload);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'saveOCBRAttachments',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return error;
  }
}

/**
 * Saves updated internal contacts
 * @param request
 * @returns facilityId
 */
export async function postUpdateInternalContacts(payload: IUpdateInternalContactCommand) {
  try {
    const response = await baseAxiosInstance.post(
      `${facilityBase}/facilities/facility/update/internal-contact`,
      payload,
    );
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postUpdateInternalContacts',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return error.response;
  }
}

/**
 * Saves updated Facility Region
 * @param request
 * @returns regionId
 */
export async function postUpdateFaciliytRegion(payload: IFacilityRegionUpdateCommand) {
  try {
    const response = await baseAxiosInstance.post(
      `${facilityBase}/facilities/facility/update/location-region`,
      payload,
    );
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'UpdateFacilityLocationRegion',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return error.response ?? { status: 500 };
  }
}

export async function getInstructionsData(facilityId: number) {
  try {
    const response = await baseAxiosInstance.get(`${facilityBase}/facilities/facility//qs-instructions/${facilityId}`);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getInstructionsData',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
  }
}

export async function postInstructionsData(payload: IUpdateFacilityInstructionsCommand) {
  try {
    const response = await baseAxiosInstance.post(
      `${facilityBase}/facilities/facility/update/qs-instructions`,
      payload,
    );
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postInstructionsData',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return error.response;
  }
}

export async function updateFacilityUnitStatus(payload: {
  facilityId: number;
  unitId: number;
  isDisableContactsRequired: boolean;
  currentEmployeeId: number;
  currentEmployeeName: string;
}) {
  try {
    const response = await baseAxiosInstance.post(`${facilityBase}/units/unit/update/status-inactive`, payload);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'deactivateUnit',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
  }
}

export async function updateNotesStatus(payload: {
  contactId: number;
  notes: string;
  statusId: string;
  currentEmployeeId: string;
  currentEmployeeName: string;
}) {
  try {
    const response = await baseAxiosInstance.post(`${facilityBase}/clientcontacts/clientcontact/update`, payload);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateNotesStatus',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return error.response;
  }
}

export async function getFacilityContactAffiliate(facilityId: number) {
  try {
    const response = await baseAxiosInstance.get(`${facilityBase}/facilities/facility/contractaffiliate/${facilityId}`);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getFacilityContactAffiliate',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return error.response;
  }
}

/**
 * Saves updated drug screen policy
 * @param request
 * @returns facilityId and lastUpdatedDate
 */
export async function postUpdateDrugScreenPolicy(payload: IUpdateDrugScreenPolicyCommand) {
  try {
    const response = await baseAxiosInstance.post(
      `${facilityBase}/facilities/facility/update/drug-screen-policy`,
      payload,
    );
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postUpdateDrugScreenPolicy',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return error.response;
  }
}

export async function updateFacilityReferences(payload: IUpdateReferencesCommand) {
  try {
    const response = await baseAxiosInstance.post(`${facilityBase}/facilities/facility/update/references`, payload);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateFacilityReferences',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return error.response;
  }
}

export async function getFacilitiesForAffiliate(affiliationId: number) {
  try {
    const response = await baseAxiosInstance.get(
      `${facilityBase}/facilities/facility/relatedfacilities/${affiliationId}`,
    );
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getFacilitiesForAffiliate',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return error.response;
  }
}

export async function getCertificatesFullName(requirementTypeIds: number[]) {
  try {
    const response = await baseAxiosInstance.post(`${facilityBase}/units/unit/certifications/detail`, {
      requirementTypeIds: requirementTypeIds,
    });
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCertificatesFullName',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return 'error';
  }
}

export async function getSkillSetsForUnitByUnitId(unitId: number) {
  try {
    const response = await baseAxiosInstance.get(`${facilityBase}/units/unit/skillset/${unitId}`);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getSkillSetsForUnitByUnitId',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return 'error';
  }
}

export async function updateFacilityBestBets(payload: IBestBets) {
  try {
    const response = await baseAxiosInstance.post(
      `${facilityBase}/facilities/facility/update/facility-unit-best-bets`,
      payload,
    );
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateFacilityReferences',
        area: 'src/app/services/FacilityServices/FacilityServices.ts',
      },
    });
    return error.response;
  }
}
