import { GetMomentOfDate } from '../dates/moment';
import { convertToPST } from 'app/helpers/dateHelper';

const getDayTag = useTagAbbrevation => (useTagAbbrevation ? 'D' : 'Days');
const getHourTag = useTagAbbrevation => (useTagAbbrevation ? 'Hr' : 'Hours');
const getMinutesTag = useTagAbbrevation => (useTagAbbrevation ? 'Min' : 'Minutes');

export const getStatusAge = (date, useTagAbbrevation = true, markWithAgo = false) => {
  const dateMoment = GetMomentOfDate(date);
  const now = GetMomentOfDate(convertToPST(new Date()));
  const totalHourDifference = now.diff(dateMoment, 'hours');
  const days = now.diff(dateMoment, 'days');
  const hours = totalHourDifference - days * 24;
  const minutes = now.diff(dateMoment, 'minutes') - totalHourDifference * 60;
  const statusAge =
    days >= 999
      ? `999 ${getDayTag(useTagAbbrevation)}`
      : days >= 3
      ? `${days} ${getDayTag(useTagAbbrevation)}`
      : days >= 1
      ? `${days} ${getDayTag(useTagAbbrevation)} ${hours ? `${hours} ${getHourTag(useTagAbbrevation)}` : ''}`
      : hours
      ? `${hours} ${getHourTag(useTagAbbrevation)} ${minutes ? `${minutes} ${getMinutesTag(useTagAbbrevation)}` : ''}`
      : `${minutes} ${getMinutesTag(useTagAbbrevation)}`;
  const response = {
    message: markWithAgo ? `${statusAge} ago` : statusAge,
    totalMinutes: now.diff(dateMoment, 'minutes'),
  };
  return response;
};
