import { dateIsToday, dateIsAfterToday } from 'app/helpers/dateHelper';
import { instanceOfISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import { filterChoiceWithNewOptions, isItemIncluded, itemIsInRange } from 'app/ComponentLibrary/Filter/helper';
import { IPlacementReminder } from 'app/models/Credentialing/PlacementReminder';
import { RemindersFilters } from '../Filters/PlacementRemindersConfig';
import { IFilterChipSelected, IFilterSpecs } from '../Store/PlacementReminders.redux';
import moment from 'moment';

export const filterUserChoices = (
  filterSpecs: IFilterSpecs,
  values: any,
  setValue: (
    name: string,
    value: unknown,
    options?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
        }>
      | undefined,
  ) => void,
) => {
  return {
    ...values,
    [RemindersFilters.placementId]: filterChoiceWithNewOptions(
      values[RemindersFilters.placementId],
      filterSpecs.placementIDOpts,
      RemindersFilters.placementId,
      setValue,
    ),
    [RemindersFilters.candidateName]: filterChoiceWithNewOptions(
      values[RemindersFilters.candidateName],
      filterSpecs.candidateNameOpts,
      RemindersFilters.candidateName,
      setValue,
    ),
    [RemindersFilters.placementStatus]: filterChoiceWithNewOptions(
      values[RemindersFilters.placementStatus],
      filterSpecs.placementStatusOpts,
      RemindersFilters.placementStatus,
      setValue,
    ),
    [RemindersFilters.clientName]: filterChoiceWithNewOptions(
      values[RemindersFilters.clientName],
      filterSpecs.clientNameOpts,
      RemindersFilters.clientName,
      setValue,
    ),
    [RemindersFilters.candidateId]: filterChoiceWithNewOptions(
      values[RemindersFilters.candidateId],
      filterSpecs.candidateIdOpts,
      RemindersFilters.candidateId,
      setValue,
    ),
    [RemindersFilters.reminderStatus]: filterChoiceWithNewOptions(
      values[RemindersFilters.reminderStatus],
      filterSpecs.reminderStatusOpts,
      RemindersFilters.reminderStatus,
      setValue,
    ),
    [RemindersFilters.reminderType]: filterChoiceWithNewOptions(
      values[RemindersFilters.reminderType],
      filterSpecs.reminderTypeOpts,
      RemindersFilters.reminderType,
      setValue,
    ),
  };
};

export const parseData = data => {
  return data?.reduce(function (newArr, item) {
    newArr.push(parseInt(item?.value));
    return newArr;
  }, []);
};

export const getFilteredData = (filter, sourceData, chipSelected?: IFilterChipSelected) =>
  sourceData.reduce(
    (resultArray, item) =>
      isItemIncluded(
        item['placementId'],
        parseData(chipSelected?.placementId) || parseData(filter[RemindersFilters.placementId]),
      ) &&
      isItemIncluded(item['candidateName'], chipSelected?.candidateName || filter[RemindersFilters.candidateName]) &&
      isItemIncluded(
        item['placementStatus'],
        chipSelected?.placementStatus || filter[RemindersFilters.placementStatus],
      ) &&
      isItemIncluded(item['clientName'], chipSelected?.clientName || filter[RemindersFilters.clientName]) &&
      isItemIncluded(item['reminderType'], chipSelected?.reminderType || filter[RemindersFilters.reminderType]) &&
      isItemIncluded(item['reminderStatus'], chipSelected?.reminderStatus || filter[RemindersFilters.reminderStatus]) &&
      isItemIncluded(
        item['travelerId'],
        parseData(chipSelected?.candidateId) || parseData(filter[RemindersFilters.candidateId]),
      ) &&
      (filter[RemindersFilters.startDate]
        ? item['startDate']
          ? itemIsInRange(item['startDate'], filter[RemindersFilters.startDate])
          : false
        : true) &&
      (filter[RemindersFilters.reminderDate]
        ? item['followUpDate']
          ? itemIsInRange(item['followUpDate'], filter[RemindersFilters.reminderDate])
          : false
        : true) &&
      (chipSelected?.daysBeforeStart || filter[RemindersFilters.daysBeforeStart]
        ? parseInt(item['daysBeforeStart'] || '0') <= parseInt(filter[RemindersFilters.daysBeforeStart])
        : true)
        ? [...resultArray, item]
        : resultArray,
    [],
  );

export const generateFieldChips = (values, selectedChip?) => {
  return typeof values !== 'string' && (values && values?.startDate && values?.endDate) != undefined
    ? [
        {
          label: `${moment(values.startDate.toLocaleDateString()).format('MM/DD/YYYY')} - ${moment(
            values.endDate.toLocaleDateString(),
          ).format('MM/DD/YYYY')}`,
          enableDelete: true,
          value: values,
          isSelected: selectedChip?.some(chip => chip.value === values) || false,
        },
      ]
    : typeof values === 'string' && values !== ''
    ? [
        {
          label: values,
          enableDelete: true,
          value: values,
          isSelected: selectedChip?.some(chip => chip.value === values) || false,
        },
      ]
    : values?.length
    ? instanceOfISelection(values[0])
      ? values?.reduce(
          (result, choice) =>
            choice.value === 'All'
              ? result
              : [
                  ...result,
                  {
                    label: choice.name,
                    enableDelete: true,
                    value: choice,
                    isSelected: selectedChip?.some(chip => chip.value === choice.value) || false,
                  },
                ],
          [],
        )
      : values.reduce(
          (result, choice) =>
            choice === 'All'
              ? result
              : [
                  ...result,
                  {
                    label: choice,
                    enableDelete: true,
                    value: choice,
                    isSelected: selectedChip?.includes(choice) || false,
                  },
                ],
          [],
        )
    : [];
};

export const createFilterChips = (filter, selectedChips?: IFilterChipSelected) => ({
  [RemindersFilters.placementId]: generateFieldChips(
    filter[RemindersFilters.placementId],
    selectedChips?.placementId || undefined,
  ),
  [RemindersFilters.candidateName]: generateFieldChips(
    filter[RemindersFilters.candidateName],
    selectedChips?.candidateName || undefined,
  ),
  [RemindersFilters.placementStatus]: generateFieldChips(
    filter[RemindersFilters.placementStatus],
    selectedChips?.placementStatus || undefined,
  ),
  [RemindersFilters.startDate]: generateFieldChips(
    filter[RemindersFilters.startDate],
    selectedChips?.startDate || undefined,
  ),
  [RemindersFilters.daysBeforeStart]: generateFieldChips(
    filter[RemindersFilters.daysBeforeStart],
    selectedChips?.daysBeforeStart || undefined,
  ),
  [RemindersFilters.clientName]: generateFieldChips(
    filter[RemindersFilters.clientName],
    selectedChips?.clientName || undefined,
  ),
  [RemindersFilters.reminderDate]: generateFieldChips(
    filter[RemindersFilters.reminderDate],
    selectedChips?.reminderDate || undefined,
  ),
  [RemindersFilters.reminderType]: generateFieldChips(
    filter[RemindersFilters.reminderType],
    selectedChips?.reminderType || undefined,
  ),
  [RemindersFilters.reminderStatus]: generateFieldChips(
    filter[RemindersFilters.reminderStatus],
    selectedChips?.reminderStatus || undefined,
  ),
  [RemindersFilters.candidateId]: generateFieldChips(
    filter[RemindersFilters.candidateId],
    selectedChips?.candidateId || undefined,
  ),
});

export const determineChipSelected = (newChip, selected) =>
  newChip.isSelected ? null : [instanceOfISelection(newChip) ? newChip : newChip.value];

export const formatDisciplineSpecialty = data => {};
//?.replace('/', ',')

export const setPlacementVolumeTotals = (response: IPlacementReminder[]) => {
  let today = 0;
  let scheduled = 0;
  for (const item of response) {
    if (dateIsToday(moment(item.followUpDate))) today += 1;
    else if (dateIsAfterToday(moment(item.followUpDate))) scheduled += 1;
  }
  return {
    today: today,
    scheduled: scheduled,
    all: response?.length || 0,
  };
};
