import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "types";

const selectDomain = (state: RootState) => state.facilityCommunicationHistory;

export const selectFacilityCommunicationLog = createSelector(
    [selectDomain],
    (domain) => domain?.facilityCommunicationLog
);
export const selectGridLoading = createSelector(
    [selectDomain],
    (domain) => domain?.isGridLoading
);
export const selectClientContacts = createSelector(
    [selectDomain],
    (domain) => domain?.facilityClientContacts
);

export const selectUnitsLookusps = createSelector([selectDomain], (domain) => domain?.facilityUnits);

export const selectCommunicationTypeOpts = createSelector([selectDomain], (domain) => domain?.communicationTypeOpts);

export const selectUserRoles = createSelector([selectDomain], (domain) => domain?.facilityUserRoles);

export const selectInitiatedBy = createSelector([selectDomain], (domain) => domain?.facilityInitiatedBy);

export const selectFilterChips = createSelector([selectDomain], (domain) => domain?.chips);
