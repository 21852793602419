import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAutomationKey } from 'app/models/Orders/OrderPreference';
import { serviceActions } from './async-actions';
import { initialState } from './initialState';
import { IBookingTemplate, ExistingUnitPreferences, IFacilityPreferences, IFacilityTemplate, ITemplate } from './types';

const slice = createSlice({
  name: 'facilityPreferences',
  initialState,
  reducers: {
    setPreferences(state, action: PayloadAction<IFacilityPreferences>) {
      return { ...state, ...action.payload };
    },
    setActivePlacementCounts(state, action: PayloadAction<{ rfo: number; sent: number; others: number }>) {
      return {
        ...state,
        submissionLimits: { activePlacementCounts: { ...action.payload } }
      };
    },
    setUpdateAutomationApiProgressStatus(state, action: PayloadAction<{ status: boolean | false }>) {
      return {
        ...state,
        updateInprogress: action.payload.status,
      };
    },
    setPreferencesByStage(state, action: PayloadAction<{ key: IAutomationKey; data }>) {
      return {
        ...state,
        [action.payload.key]: {
          ...action.payload.data,
        },
      };
    },
    setConfirmationTemplates(state, action: PayloadAction<ITemplate[]>) {
      return { ...state, confirmationTemplates: [...action.payload] };
    },
    setFacilityTemplate(state, action: PayloadAction<IFacilityTemplate>) {
      return { ...state, facilityTemplate: action.payload };
    },
    setFacilityBookingTemplates(state, action: PayloadAction<IBookingTemplate>) {
      return { ...state, bookingTemplate: action.payload };
    },
    setIBookingFacilityDirty(state, action: PayloadAction<boolean>) {
      return { ...state, bookingFacilityDirty: action.payload };
    },
    setBookingFacilityReset(state, action: PayloadAction<boolean>) {
      return { ...state, bookingFacilityReset: action.payload };
    },
    setConfirmationDialog(state, action: PayloadAction<boolean>) {
      state.confirmationDialog = action.payload
    },
    setTabValue(state, action: PayloadAction<number>) {
      state.tabValue = action.payload
    },
    setIsPreferenceChaged(state, action: PayloadAction<boolean>) {
      state.isPreferenceChanged = action.payload
    },
    setUpdateFacilityTemplateProgressStatus(state, action: PayloadAction<{ status: boolean | false }>) {
      return {
        ...state,
        templateUpdateInProgress: action.payload.status,
      };
    },
    setMiscellaneousData(
      state,
      action: PayloadAction<{ key: 'disableEmailContainer' | 'disablePacketContainer'; value: boolean }>,
    ) {
      return { ...state, miscellaneous: { ...state.miscellaneous, [action.payload.key]: action.payload.value } };
    },
    setExsistingUnits(state, action: PayloadAction<ExistingUnitPreferences[]>) {
      return { ...state, existingUnits: action.payload };
    },
    setPreferenceUpdateDialog(state, action: PayloadAction<boolean>) {
      return { ...state, updateDialogOpen: action.payload };
    },
    setPreferenceSelection(state, action: PayloadAction<number[]>) {
      return { ...state, preferenceSelection: action.payload };
    },
  },
});

export const facilityPreferenceActions = {
  ...slice.actions,
  ...serviceActions,
};

export const { name: facilityPreferenceSliceKey, reducer: facilityPreferenceReducer } = slice;
