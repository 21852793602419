import { createTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { theme } from 'styles/global-styles';

export const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    height: '15%',
    maxHeight: 250,
    overflow: 'auto',
    paddingTop: 10,
    paddingBottom: 0,
  },

  wrapper: {
    backgroundColor: `${theme.palette.system.white} !important`,
    maxHeight: 300,
  },

  item: {
    borderBottom: `1px solid ${theme.palette.system.brightGray}`,
    borderColor: theme.palette.system.pewter,
    maxHeight: '2rem',
  },

  icon: {
    borderRadius: 3,
    padding: 2,
    border: `1px solid ${theme.palette.system.lightGrey}`,
    marginTop: 6,
  },
  defaultBackgroundColor: {
    backgroundColor: theme.palette.framework.system.white,
  },
  selectBackgroundColor: {
    backgroundColor: theme.palette.framework.system.skyBlue,
    border: `1px solid ${theme.palette.framework.system.white}`,
    ' svg': {
      fill: `${theme.palette.framework.system.white} !important`,
    },
  },

  iconSize: {
    fontSize: 'larger',
    padding: 4,
  },
  listItemContent: {
    display: '-webkit-box',
    maxWidth: '50ch',
    fontSize: 14,
    lineHeight: 1.4,
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    left: '-1.5rem',
    position: 'relative',
  },
  tooltip: {
    backgroundColor: theme.palette.components.tooltip.backgroundColor,
    color: theme.palette.system.black,
    opacity: 1,
    textAlign: 'left',
    letterSpacing: '0px',
    boxShadow: `0px 3px 6px ${theme.palette.system.darkBlackBlue}`,
    border: `1px solid ${theme.palette.system.darkGrey}`,
  },
  arrow: {
    color: theme.palette.components.tooltip.arrowColor,
    opacity: 1,
    '&::before': {
      boxShadow: `0px 3px 6px ${theme.palette.system.darkBlackBlue}`,
      border: `1px solid ${theme.palette.system.darkGrey}`,
    },
  },
  placementBottom: {
    marginBottom: 0,
  },

  textField: {
    fontSize: 12,
    marginLeft: 12,
    marginRight: 12,
    color: theme.palette.system.violet,
    '&::placeholder': {
      opacity: 1,
      fontSize: 12,
      color: theme.palette.system.dimGrey2,
    },
  },
  checkboxItem: {
    paddingLeft: '10px',
  },
}));

export const smallTextFieldTheme = createTheme(theme, {
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          height: '37.625px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: { height: '37.625px' },
      },
    },
  },
});
