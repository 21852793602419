import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './CandidateDetails.redux';

const selectDomain = (state: RootState) => state.candidateDetails || initialState;

export const candidateDetailsSelection = createSelector([selectDomain], details => details);
export const referenceGridSelector = createSelector([selectDomain], details => details?.referenceGrid?.data);
export const referenceGridLoadingSelector = createSelector(
  [selectDomain],
  details => details?.referenceGrid?.isLoading,
);
export const gridLoadingSelector = createSelector([selectDomain], details => details?.loading);
export const selectAppliedFilter = createSelector([selectDomain], details => details?.isFilterApplied);
export const selectCommunicationChannels = createSelector([selectDomain], details => details?.channels);
export const supervisorDataSelector = createSelector([selectDomain], details => details?.supervisor);
export const candidateEducationSelection = createSelector([selectDomain], details => details?.experience?.education);
export const candidateEmployementSelection = createSelector([selectDomain], details => details?.experience?.employment);
export const candidateLicenseSelection = createSelector([selectDomain], details => details?.licenses);
export const ActivePlacementSelection = createSelector([selectDomain], details => details.activePlacements);
export const CredentialsSelection = createSelector(
  [selectDomain],
  details => details.credentials ?? initialState.credentials,
);
export const selectDefaultChannels = createSelector([selectDomain], details => details?.defaultChannels);
export const selectChannelValues = createSelector([selectDomain], details => details?.channelValues);
export const CredentialsReloadData = createSelector([selectDomain], details => details?.credentials?.reloadData);
export const AttachmentsSelection = createSelector([selectDomain], details => details?.credentials?.attachments);
export const newAttachmentsSelection = createSelector([selectDomain], details => details.newAttachments);
export const existingAttachmentsSelection = createSelector([selectDomain], details => details.existingAttachments);
export const historyAttachmentsSelection = createSelector([selectDomain], details => details.historyAttachments);
export const previewAttachmentsSelection = createSelector([selectDomain], details => details.selPreviewAttachments);
export const reLoadingSelection = createSelector([selectDomain], details => details.reLoading);
export const educationActiveSelection = createSelector([selectDomain], details => details.isEducationActive);
export const isPreviewSelection = createSelector([selectDomain], details => details.isPreview);
export const selectSkillSetTree = createSelector([selectDomain], details => details.skillSetTree);
export const addedSkillSetTree = createSelector([selectDomain], details => details.addSkillSetTree);
export const selectSkillsChecklist = createSelector([selectDomain], details => details.skillsChecklist);
export const isAddLicenseSelection = createSelector([selectDomain], details => details.isAddLicense);
export const selectAddUnverifiedCredType = createSelector([selectDomain], details => details.addUnverifiedCredType);
export const selectAuditData = createSelector([selectDomain], details => details.auditData);
export const selectReferenceData = createSelector([selectDomain], details => details.referenceData);
export const selectChangeHistory = createSelector([selectDomain], details => details.changeHistory);
export const CredentialingLookups = createSelector(
  [selectDomain],
  details => details?.credentials?.credentialingLookups,
);
export const RequirementTypes = createSelector([selectDomain], details => details?.credentials?.requirementTypes);

export const selectMasterCopyData = createSelector([selectDomain], details => details.masterCopyDropDowns);
export const selectReferences = createSelector([selectDomain], details => details.references);
export const getEMRList = createSelector([selectDomain], details => details.emrList);
export const savedEMRList = createSelector([selectDomain], details => details.equipmentsProcedure);
export const getPreferredTimeToReach = createSelector([selectDomain], details => details.preferredTimeToReach);
export const selectSellingNotesItems = createSelector([selectDomain], details => details.sellingNotesItems);
export const selectRaceEthnicity = createSelector([selectDomain], details => details.raceEthnicity);
export const selectEthnicityOptions = createSelector([selectDomain], details => details.raceEthnicityOptions);
export const selectGenderOptions = createSelector([selectDomain], details => details.genderOptions);
export const selectEthnicityId = createSelector([selectDomain], details => details.ethnicityId);
export const selectEmergencyContact = createSelector([selectDomain], details => details.emergencyContact);
export const selectTags = createSelector([selectDomain], details => details.tagsList);
export const selectAllPassportSkillChecklist = createSelector(
  [selectDomain],
  details => details.passportSkillChecklist,
);
export const selectViewSkillsChecklistPassport = createSelector(
  [selectDomain],
  details => details.viewSkillsChecklistPassport,
);

export const candidateSaveStatusSelector = createSelector([selectDomain], details => details.candidateSaveStatus);
export const placementSkillsetExperienceSelector = createSelector(
  [selectDomain],
  details => details.placementSkillsetExperience,
);
export const requestReferenceSaveStatusSelector = createSelector(
  [selectDomain],
  details => details.requestReferenceSaveStatus,
);

export const saveContactTabStatus = createSelector([selectDomain], details => details.contactTabSaveStatus);
export const editExperienceSelector = createSelector([selectDomain], details => details.editExperience);
export const disableLoadMore = createSelector([selectDomain], details => details.disableLoadMore);
export const autoScroll = createSelector([selectDomain], details => details.scrolled);
export const communicationLog = createSelector([selectDomain], details => details.communicationLog);
export const editAuditSelector = createSelector([selectDomain], details => details.editAuditData);
export const selectPersonalInfoError = createSelector([selectDomain], details => details.personalInfoError);
export const selecthasActivePlacements = createSelector([selectDomain], details => details.hasActivePlacements);
export const selectCandidateConsentLinks = createSelector([selectDomain], details => details.candidateConsentLinks);
