/* eslint-disable i18next/no-literal-string */
import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { FilterOptions } from 'app/models/DropDown';

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { deepEqual } from 'utils/common/comparison';

const useStyles = makeStyles()(theme => ({
  selectField: {
    backgroundColor: theme.palette.framework.system.whisper,
  },
  selectFieldSmall: {
    padding: '4px 0px',
    backgroundColor: 'white',
  },
  dropdownStyle: {
    border: '1px solid #D1D1D1',
    boxShadow: 'none',
    borderRadius: 3,
    marginTop: '5px',
  },


  menu: {
    color: '#333333',
    fontSize: 12,
  },
  gridItem: {
    width: '100%',
  },
  alignMents: {
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(0),
    height: '37.625px',
    minWidth: 120,
    width: '100%',
    '& .MuiFilledInput-root.Mui-focused': {
      backgroundColor:  theme.palette.framework.system.whisper,
    },
    '& .MuiSelect-select': {
      '&:focus': {
        backgroundColor:  theme.palette.framework.system.whisper,
      },
    },
  },
  textField: {
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  textFieldInput: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    fontSize: '14px',
  },

  //Small
  selectFocusSmall: {
    paddingTop: 6,
    paddingBottom: 6,
    backgroundColor: '#ffffff !important',
    fontSize: 12,
  },
  selectFocusLarge: {
    paddingTop: '22px',
  },
  fakeDisabled: {
    '&.Mui-disabled': {
      opacity: 1,
      pointerEvents: 'all',
      backgroundColor: '#FFF',
    },
  },
}));

export interface AccordionMenuProps {
  name?: string;
  show?: boolean;
  handleChange: any;
  filterOptions: FilterOptions[];
  large?: boolean;
  value?: any;
  maxWidth?: number;
  inputLabel?: string;
  color?: 'primary' | 'secondary' | undefined;
  disabled?: boolean;
  onFocus?: any;
  placeholder?: {
    value: any;
    description: string;
  };
  multiselect?: boolean;
  filter?: boolean;
  menuPaperStyle?: string;
}
export const AccordionMenu = (props: AccordionMenuProps) => {
  const { classes, cx } = useStyles();

  const { t } = useTranslation();
  const {
    name,
    filterOptions,
    large = true,
    disabled = false,
    maxWidth,
    value,
    inputLabel,
    color = 'primary',
    onFocus,
    placeholder,
    multiselect = false,
    filter = false,
    menuPaperStyle,
  } = props;
  const [val, setVal] = useState<any>(
    placeholder && value !== undefined && value !== null ? placeholder.value : props.value,
  );
  const [filterValue, setFilterValue] = useState<string>('');
  const [initialFilterOptions, setInitialFilterOptions] = useState<any>(filterOptions);
  const [localFilterOptions, setLocalFilterOptions] = useState<any>(filterOptions);
  const isDisabled = disabled || filterOptions.length === 0 || value === undefined;

  useEffect(() => {
    setVal(value);
  }, [value]);

  useEffect(() => {
    // if (localFilterOptions === null || localFilterOptions.length === 0) {
    setLocalFilterOptions(filterOptions);
    if (!deepEqual(initialFilterOptions, filterOptions)) {
      setInitialFilterOptions(filterOptions);
      setFilterValue('');
    } else if (filterValue) onFilter({ target: { value: filterValue } });
    // setFilterValue('');
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  const handleSelection = event => {
    setVal(event.target.value as any[]);
    props.handleChange(event);
  };

  const onFilter = event => {
    setFilterValue(event.target.value);
    let l: FilterOptions[] = _.cloneDeep(filterOptions);
    if (event.target.value !== '') {
      l = l.filter(item => {
        if (item.description.toLowerCase().includes(event.target.value.toLowerCase())) return true;
        else if ((multiselect && item.value === val) || (typeof val === 'object' && val.includes(item.value)))
          return true;
        return false;
      });
    }
    setLocalFilterOptions(l);
  };

  const clearFilter = () => {
    setFilterValue('');
    setLocalFilterOptions(filterOptions);
  };

  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <Grid container spacing={0} className={classes.alignMents} style={maxWidth ? { maxWidth: `${maxWidth}px` } : {}}>
      <Grid xs={12} item className={classes.gridItem}>
        <FormControl size="small" variant={large ? 'filled' : 'standard'} className={classes.formControl}>
          {
            <InputLabel
              id="demo-simple-select-filled-label"
              variant={large ? 'filled' : 'outlined'}
            >
              {inputLabel}
            </InputLabel>
          }
          <Select
            name={name}
            labelId="demo-simple-select-filled-label"
            multiple={multiselect}
            disabled={isDisabled}
            color={color}
            id="default"
            variant={large ? 'filled' : 'outlined'}
            value={val}
            onClose={clearFilter}
            className={large ? classes.selectField : classes.selectFieldSmall}
            IconComponent={ExpandMoreOutlinedIcon}
            onChange={handleSelection}
            inputProps={{ className: !large ? classes.selectFocusSmall : '' }}
            classes={{
              root: cx({ [classes.selectFocusLarge]: large }),
            }}
            MenuProps={{
              classes: { paper: cx({ [classes.dropdownStyle]: !large, [menuPaperStyle ?? '']: large }) },
              variant: 'selectedMenu',
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              autoFocus: false,
              MenuListProps: {
                autoFocus: false,
                autoFocusItem: false,
              },
            }}
            onFocus={onFocus}
            renderValue={
              multiselect
                ? val.length === 1
                  ? () => placeholder?.description || ''
                  : selected =>
                      localFilterOptions
                        .filter(item => (selected as any[]).includes(item.value))
                        .map(item => item.description)
                        .join(', ')
                : undefined
            }
          >
            {placeholder !== undefined && placeholder !== null && (
              <MenuItem key={placeholder.value} value={placeholder.value!} disabled>
                {placeholder.description!}
              </MenuItem>
            )}
            {(!value || value === '' || value === 'N/A') && !large && isDisabled && (
              <MenuItem value={t('search.grid.notAvailable')} disabled>
                {t('search.grid.notAvailable')}
              </MenuItem>
            )}
            {filter && (
              <MenuItem
                disabled
                value={-100}
                className={classes.fakeDisabled}
                onKeyDown={e => e.stopPropagation()}
                onClickCapture={stopImmediatePropagation}
                divider
              >
                <FormControl variant="outlined" className={classes.gridItem}>
                  <TextField
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    variant="outlined"
                    size="small"
                    value={filterValue}
                    onChange={onFilter}
                    className={classes.textField}
                    InputProps={{
                      classes: {
                        input: classes.textFieldInput,
                      },
                    }}
                    placeholder={'Filter Options'}
                  />
                </FormControl>
              </MenuItem>
            )}
            {localFilterOptions.map((item, index) => {
              return (
                true && (
                  <MenuItem disabled={item.disabled} className={classes.menu} key={item.value} value={item.value}>
                    {!multiselect ? (
                      item.description
                    ) : (
                      <React.Fragment>
                        <Checkbox color="primary" size="small" checked={(val as any[]).indexOf(item.value) > -1} />
                        <ListItemText disableTypography primary={item.description} />
                      </React.Fragment>
                    )}
                  </MenuItem>
                )
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
