import React from 'react';
import { useTranslation } from 'react-i18next';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';

export const Resubmit = ({
  openDialog,
  handleConfirmAction,
  handleCancelAction,
  cancelButtonId,
  confirmButtonId,
}: {
  openDialog: boolean;
  handleConfirmAction: () => void;
  handleCancelAction: () => void;
  cancelButtonId?: string;
  confirmButtonId?: string;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <GenericDialog
        open={openDialog}
        maxWidth="xs"
        dialogTitleProps={{ text: t('notification.uploadApprovalDocuments.resubmitPopup.title') }}
        dialogContentProps={{
          variant: 'text',
        }}
        dialogActions={[
          {
            id: cancelButtonId,
            text: t('notification.uploadApprovalDocuments.resubmitPopup.notNowButton'),
            onClick: handleCancelAction,
            color: 'tertiary',
          },
          {
            id: confirmButtonId,
            text: t('notification.uploadApprovalDocuments.resubmitPopup.resubmitButton'),
            onClick: handleConfirmAction,
            variant: 'contained',
          },
        ]}
      >
        {t('notification.uploadApprovalDocuments.resubmitPopup.description')}
      </GenericDialog>
    </>
  );
};
