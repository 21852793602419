import React from 'react';
import { SidePanel, TemplateEditorWrapper } from '@AMIEWEB/Common/TemplateEditor';
import { useDispatch, useSelector } from 'react-redux';
import { selectTemplatesEditor } from 'store/redux-store/confirmation-template-editor/selectors';
import {
  confirmationTemplateEditorActions,
  confirmationTemplateEditorReducer,
  confirmationTemplateEditorSliceKey,
} from 'store/redux-store/confirmation-template-editor/slice';
import { ITemplate } from 'store/redux-store/confirmation-template-editor/types';
import { usePromiseTracker } from 'react-promise-tracker';
import { useReadOnly } from 'oidc/userRoles';
import { Typography } from 'amn-ui-core';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { templateEditorSaga } from 'store/redux-store/confirmation-template-editor/saga';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { selectEditorPreferences } from 'oidc/UserDevicePreference/userPreference.selectors';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { PreferenceCosmosKeys } from 'oidc/UserDevicePreference/utils';

export const ClientConfirmations = () => {
  useInjectReducer({ key: confirmationTemplateEditorSliceKey, reducer: confirmationTemplateEditorReducer });
  useInjectSaga({ key: confirmationTemplateEditorSliceKey, saga: templateEditorSaga });
  const [sideView, setSideView] = React.useState<SidePanel>('templates');

  const { t } = useTranslation();
  const history = useHistory();

  const {
    templates,
    selectedTemplate,
    mergeTokenList,
    miscellaneous: { forceModalCloseFlag },
  } = useSelector(selectTemplatesEditor);

  const {
    settingsConfirmationTemplateEditor: { value: editorPreference },
    hasLoadedPreferences,
  } = useSelector(selectEditorPreferences);

  const { readOnly } = useReadOnly();

  const { promiseInProgress: isLoading } = usePromiseTracker({ area: 'document-templates-editor', delay: 0 });
  const { promiseInProgress: isSavingDraft } = usePromiseTracker({
    area: 'document-templates-editor-save-draft',
    delay: 0,
  });
  const { promiseInProgress: isPublishing } = usePromiseTracker({
    area: 'document-templates-editor-publish',
    delay: 0,
  });

  const { promiseInProgress: isDeletingDraft } = usePromiseTracker({
    area: 'document-templates-delete-draft',
    delay: 0,
  });

  const dispatch = useDispatch();

  const onTemplateClick = template => {
    dispatch(confirmationTemplateEditorActions.setSelectedTemplate(template));
  };

  const onPublish = (template: ITemplate) => {
    dispatch(confirmationTemplateEditorActions.publishTemplate(template));
  };

  const onSave = (template: ITemplate) => {
    dispatch(confirmationTemplateEditorActions.saveDraftTemplate(template));
  };

  const handleDelete = (templateId: number) => {
    dispatch(confirmationTemplateEditorActions.deleteDraftTemplate({ templateId }));
  };

  const updatePreferences = preference => {
    dispatch(
      userDevicePreferenceActions.business_saveConfirmationEditorPreference({
        editor: 'settingsConfirmationTemplateEditor',
        preference: { id: PreferenceCosmosKeys.settingsConfirmationTemplateEditor, value: preference },
      }),
    );
  };

  const updateEditorWidthPreference = width => {
    dispatch(
      userDevicePreferenceActions.business_saveEditorWidthPreference({
        editor: 'settingsConfirmationTemplateEditor',
        width,
      }),
    );
  };

  const pageTitle = React.useMemo(
    () => <Typography variant={'h6'}>{t('templateEditor.pageTitle.clientConfirmation')}</Typography>,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useEffect(() => {
    dispatch(confirmationTemplateEditorActions.onInitAction());

    return () => {
      dispatch(confirmationTemplateEditorActions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [{ titleIcons }] = React.useState({ titleIcons: ['tokens', 'templates'] });

  return (
    <TemplateEditorWrapper
      //@ts-ignore
      templates={templates}
      //@ts-ignore
      selectedTemplate={selectedTemplate}
      onTemplateClick={onTemplateClick}
      handleDelete={handleDelete}
      onPublish={onPublish}
      onSave={onSave}
      mergeTokenList={mergeTokenList}
      pageTitle={pageTitle}
      isLoading={!hasLoadedPreferences}
      isLoadingSideView={isLoading}
      isSavingDraft={isSavingDraft}
      isPublishing={isPublishing}
      isDeletingDraft={isDeletingDraft}
      forceModalCloseFlag={forceModalCloseFlag}
      userIsReadOnly={readOnly}
      titleIcons={titleIcons}
      documentType="client-confirmation"
      history={history}
      sideViewProps={[sideView, setSideView]}
      editorPreference={editorPreference}
      updateTemplatePreferences={updatePreferences}
      onSplitPaneDragEnd={({ clientWidth }) => updateEditorWidthPreference(clientWidth)}
    />
  );
};
