import { BonusTypes, OrderBonusFieldNames } from './Constants';
import {
  bonusApprovedMethodOptions,
  bonusBillingTypeOptions,
  bonusLessThan13WeeksOptions,
  bonusPayerOptions,
  bonusPaymentTypeOptions,
  bonusShortHourTypeOptions,
  bonusTypeOptions,
} from './OptionsHelper';

import { addMonths } from 'date-fns';
import { formatDate } from '../../helper';
import { missingField } from 'app/constants';
import { theme } from 'styles/global-styles';

export const getAmount = (value: number) => {
  if (value === null || value === undefined) {
    return missingField;
  } else if (value === 0) {
    return `$ ${getDecimalValues(value)}`;
  } else {
    return `$ ${getDecimalValues(value)}`;
  }
};

export const getDecimalValues = (num: number) => {
  return Number(num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] as unknown).toFixed(2);
};

export const convertStringToByteArray = (date: string) => {
  let bytes = [];
  for (var i = 0; i < date.length; ++i) {
    bytes.push(date.charCodeAt(i));
  }
  return bytes;
};

export const getColor = (status: string) => {
  switch (status) {
    case 'Approved':
      return theme.palette.framework.system.kellyGreen;
    case 'Pending':
      return theme.palette.framework.chart.orange;
    case 'Expired':
      return theme.palette.framework.chart.darkRed;
    case 'Void':
      return theme.palette.primary.main;
    default:
      return theme.palette.framework.system.charcoal;
  }
};

export const bonusTypeSelectionDefaultValues = bonusTypeSelection => [
  {
    Name: OrderBonusFieldNames.bonusAmount,
    Value: bonusTypeSelection.Description !== BonusTypes.startDate ? getDecimalValues(1) : null,
  },
  {
    Name: OrderBonusFieldNames.startDate,
    Value: bonusTypeSelection.Description !== BonusTypes.startDate ? new Date() : null,
  },
  {
    Name: OrderBonusFieldNames.endDate,
    Value: bonusTypeSelection.Description !== BonusTypes.startDate ? addMonths(new Date(), 5) : null,
  },
  {
    Name: OrderBonusFieldNames.payer,
    Value: bonusTypeSelection.Description !== BonusTypes.startDate ? bonusPayerOptions[0] : null,
  },
  {
    Name: OrderBonusFieldNames.paymentType,
    Value:
      bonusTypeSelection.Description === BonusTypes.additionalPay ||
      bonusTypeSelection.Description === BonusTypes.marketingAdjustment
        ? bonusPaymentTypeOptions[2]
        : null,
  },
  {
    Name: OrderBonusFieldNames.shortHourType,
    Value: bonusTypeSelection.Description !== BonusTypes.startDate ? bonusShortHourTypeOptions[5] : null,
  },
  {
    Name: OrderBonusFieldNames.lessThan13Weeks,
    Value:
      bonusTypeSelection.Description === BonusTypes.additionalPay ||
      bonusTypeSelection.Description === BonusTypes.marketingAdjustment
        ? bonusLessThan13WeeksOptions[1]
        : bonusTypeSelection.Description === BonusTypes.dateBooked
        ? bonusLessThan13WeeksOptions[2]
        : null,
  },
  {
    Name: OrderBonusFieldNames.billingType,
    Value:
      bonusTypeSelection.Description === BonusTypes.additionalPay ||
      bonusTypeSelection.Description === BonusTypes.marketingAdjustment
        ? bonusBillingTypeOptions[2]
        : bonusTypeSelection.Description === BonusTypes.dateBooked
        ? bonusBillingTypeOptions[1]
        : null,
  },
  {
    Name: OrderBonusFieldNames.approvedBy,
    Value: bonusTypeSelection.Description !== BonusTypes.startDate ? 'Client' : null,
  },
  {
    Name: OrderBonusFieldNames.approvalType,
    Value: bonusTypeSelection.Description !== BonusTypes.startDate ? bonusApprovedMethodOptions[1] : null,
  },
  {
    Name: OrderBonusFieldNames.minWeeksWorked,
    Value:
      bonusTypeSelection.Description === BonusTypes.additionalPay ||
      bonusTypeSelection.Description === BonusTypes.marketingAdjustment
        ? 1
        : null,
  },
  {
    Name: OrderBonusFieldNames.billingAmount,
    Value:null,
  },
  {
    Name: OrderBonusFieldNames.bonusBillAmount,
    Value:null,
  },
  {
    Name: OrderBonusFieldNames.approvalDate,
    Value:null,
  },
  {
    Name: OrderBonusFieldNames.notes,
    Value:null,
  },
  {
    Name: OrderBonusFieldNames.shiftsApplicable,
    Value:[],
  },
];

export const loadBonusDetailsByBonusID = rowData => [
  {
    Name: OrderBonusFieldNames.bonusType,
    Value: bonusTypeOptions.find(x => x.ID === rowData?.bonusTypeId) || null,
  },
  {
    Name: OrderBonusFieldNames.bonusAmount,
    Value: rowData?.bonusAmount ? getDecimalValues(rowData?.bonusAmount)?.toString() : getDecimalValues(0),
  },
  {
    Name: OrderBonusFieldNames.startDate,
    Value: rowData?.startDate ? formatDate(rowData?.startDate) : null,
  },
  {
    Name: OrderBonusFieldNames.endDate,
    Value: rowData?.endDate ? formatDate(rowData?.endDate) : null,
  },
  {
    Name: OrderBonusFieldNames.payer,
    Value: bonusPayerOptions.find(x => x.Description === rowData?.descPayer) || null,
  },
  {
    Name: OrderBonusFieldNames.paymentType,
    Value: bonusPaymentTypeOptions.find(x => x.ID === rowData.paymentTypeId) || null,
  },
  {
    Name: OrderBonusFieldNames.shortHourType,
    Value: bonusShortHourTypeOptions.find(x => x.ID === rowData.bonusShortHoursTypeId) || null,
  },
  {
    Name: OrderBonusFieldNames.lessThan13Weeks,
    Value: bonusLessThan13WeeksOptions.find(x => x.Description === rowData?.descShortAssignmentBonus) || null,
  },
  {
    Name: OrderBonusFieldNames.minWeeksWorked,
    Value: rowData?.minimumWeeksWorked?.toString() || null,
  },
  {
    Name: OrderBonusFieldNames.billingType,
    Value: bonusBillingTypeOptions.find(x => x.Description === rowData?.descBillingType) || null,
  },
  {
    Name: OrderBonusFieldNames.bonusBillAmount,
    Value: rowData?.bonusBillingAmount
      ? getDecimalValues(rowData?.bonusBillingAmount)?.toString()
      : getDecimalValues(0),
  },
  {
    Name: OrderBonusFieldNames.billingAmount,
    Value: rowData?.billingAmount ? getDecimalValues(rowData?.billingAmount)?.toString() : getDecimalValues(0),
  },
  {
    Name: OrderBonusFieldNames.approvedBy,
    Value: rowData?.approvedBy || '',
  },
  {
    Name: OrderBonusFieldNames.approvalType,
    Value: bonusApprovedMethodOptions.find(x => x.ID === rowData?.approvalTypeId) || null,
  },
  {
    Name: OrderBonusFieldNames.approvalDate,
    Value: rowData?.approvalDate ? formatDate(rowData?.approvalDate) : null,
  },
  {
    Name: OrderBonusFieldNames.notes,
    Value: rowData?.notes || '',
  },
];
