import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { HomePage } from '../../AppHome/AppHome';
import { DefaultHomeSwitch } from '../../AppHome/DefaultHomeSwitch';
import { CustomBackdrop } from '../../Common/Backdrop/CustomBackdrop';
import useDocumentTitle from 'utils/customHooks/useDocumentTitle';
import { pageNames } from 'app/constants/PageNames';
import { trackPageView } from 'app-insights/appInsightsTracking';
import { useTranslation } from 'react-i18next';
import { CoverageDeskWrapper } from './CoverageDeskWrapper';
import { coverageReducer, coverageSliceKey } from 'store/redux-store/user-coverage/slice';
import { coverageSaga } from 'store/redux-store/user-coverage/saga';
import { GenericTabs } from '@AMIEWEB/Common/Tabs/GenericTabs';

const useStyles = makeStyles()(theme => ({
  root: {
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: theme.palette.framework.system.whisper,
    flexWrap: 'nowrap',
  },
}));

export const CoverageWorkDesk = () => {
  const { t } = useTranslation();
  useInjectReducer({ key: coverageSliceKey, reducer: coverageReducer });
  useInjectSaga({ key: coverageSliceKey, saga: coverageSaga });
  const { classes } = useStyles();

  useDocumentTitle({ title: pageNames.workdeskCoverage });

  const tabs = React.useMemo(
    () => [
      {
        label: `${t('home.coverageWorkDesk.coverageTab')}`,
        component: <CoverageDeskWrapper workDeskDomain={HomePage.AM_WORK_DESK} />,
      },
    ],
    [t],
  );

  React.useEffect(() => {
    trackPageView({ name: pageNames.workdeskCoverage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <CustomBackdrop open={false} />
      <GenericTabs
        tabs={tabs}
        modifyUrl
        dense
        addOnComponent={<DefaultHomeSwitch page={HomePage.COVERAGE} enlarged={true} />}
      />
    </div>
  );
};
