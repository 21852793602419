import { makeStyles } from 'tss-react/mui';

export const useTreeItemStyles = makeStyles<void, 'expanded'>()((theme, _params, classes) => ({
  root: {
    color: theme.palette.text.secondary,
    backgroundColor: '#f5f5f5',
    '&.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: 'inherit',
    },
    '.MuiTreeItem-content:hover': {
      backgroundColor: 'inherit !important',
    },
  },
  content: {
    // color: theme.palette.text.secondary,
    padding: '6px !important',
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
    [`.${classes.expanded} > &`]: {
      fontWeight: theme.typography.fontWeightRegular,
    },
    flexDirection: 'row-reverse',
    '&.MuiTreeItem-root': {
      padding: '6px',
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#F5F5F5',
    },
    backgroundColor: '#f5f5f5',
    color: '#f5f5f5',
  },
  group: {
    marginLeft: 0,
  },
  expanded: {},
  selected: {
    backgroundColor: '#f5f5f5 !important',
  },
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
    padding: 0,
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    //padding: theme.spacing(0.5, 0),
    backgroundColor: '#f5f5f5',
  },
  labelText: {
    fontWeight: 400,
    fontSize: '14px',
    flexGrow: 1,
    color: '#000000',
    padding: 0,
  },
  subSpecialty: {
    padding: '0 0 0 20px',
  },
}));
