/* eslint-disable i18next/no-literal-string */
import React, { useEffect, useState } from 'react';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import states from 'app/assets/jsons/State.json';
import moment from 'moment';
import KeyboardArrowDownwardIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import _ from 'lodash';
import { BuildRoleTypeChips } from '../Form/Controls/BuildRoleTypeChips';
import { useTranslation } from 'react-i18next';
import { dates } from '../Form/PersonalInfoHelper';
import { formatAddress } from './helper';

const useStyles = makeStyles()(theme => ({
  container: {
    padding: '0px 20px 5px 0px',
    flexWrap: 'nowrap',
  },
  fontTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    maxWidth: '231px',
  },
  secondary: {
    fontSize: '14px',
    color: '#888888',
  },
  expand: {
    paddingTop: '10px',
  },
}));

export const AddressGroup = props => {
  const { classes } = useStyles();
  const { title, addresses } = props;
  const [expand, setExpand] = useState<boolean>(false);
  const [sortAddress, SetSortAddress] = useState(addresses);
  const { t } = useTranslation();

  useEffect(() => {
    if (addresses.length) {
      SetSortAddress(
        _.orderBy(
          addresses?.map((item, idx) => {
            return { ...item, sortOrder: idx };
          }),
          (o: any) => {
            return o != null ? o.dateMoveIn : o;
          },
          ['desc'],
        ),
      );
    }
  }, [addresses]);

  const BuildAddressSections = props => {
    const { item, state } = props;
    const { addressLine1, addressLine2, addressLine3 } = formatAddress({
      address1: item.address1,
      address2: item.address2,
      city: item.city,
      stateAbbrev: state || item.stateProvinceId || null,
      zipValue: item.zip,
      country: item.country,
    });
    return (
      <>
        <LayoutGrid container direction="row" justifyContent="space-between" className={classes.container}>
          <LayoutGridItem>
            <Typography className={classes.fontTitle}>{addressLine1}</Typography>
            {addressLine2 ? (
              <Typography className={classes.fontTitle}>{addressLine2}</Typography>
            ) : (
              <Typography className={classes.fontTitle}>{`-`}</Typography>
            )}
            {addressLine3 ? (
              <Typography className={classes.fontTitle}>{addressLine3}</Typography>
            ) : (
              <Typography className={classes.fontTitle}>{`-`}</Typography>
            )}
          </LayoutGridItem>
          <LayoutGridItem>
            <Typography className={classes.secondary}>{`${
              item.dateMoveIn ? moment(new Date(item.dateMoveIn)).format('MM/DD/YYYY') : 'N/A'
            } - ${item.dateMoveOut ? moment(new Date(item.dateMoveOut)).format('MM/DD/YYYY') : 'N/A'}`}</Typography>
          </LayoutGridItem>
        </LayoutGrid>
      </>
    );
  };

  const getCurrent = (fromDate, toDate) => {
    var todayDate = moment(new Date()).format('MM/DD/YYYY');
    var startDate = fromDate ? moment(fromDate).format('MM/DD/YYYY') : 'N/A';
    var endDate = toDate ? moment(toDate).format('MM/DD/YYYY') : 'N/A';
    return (fromDate === null || dates.lt(startDate, todayDate) || dates.eq(startDate, todayDate)) &&
      (toDate === null || dates.gt(endDate, todayDate))
      ? true
      : false;
  };

  const getSortedAddress = addressData => {
    if (addressData) {
      const res = _.orderBy(
        addressData,
        (o: any) => {
          return o != null ? o.fromDate : o;
        },
        ['desc'],
      );

      return res
        ? res.filter(d => {
            if (d !== undefined) {
              return d;
            }
          })
        : [];
    }
    return [];
  };

  const ProcessChips = props => {
    return (
      <>
        <LayoutGrid>
          <LayoutGridItem>
            <BuildRoleTypeChips
              roleTypeItems={props.x.addressRoles}
              fromDate={props.x.fromDate}
              toDate={props.x.toDate}
              moveIn={props.item.dateMoveIn}
              moveOut={props.item.dateMoveOut}
            />
          </LayoutGridItem>
        </LayoutGrid>
      </>
    );
  };

  const BuildRoleTypeChip = props => {
    const { item } = props;
    const [currentAddressData, setCurrentAddressData] = useState<any[]>();
    const [nonCurrentAddressData, setNonCurrentAddressData] = useState<any[]>();

    useEffect(() => {
      const curRes = item?.addressDates?.map(d => {
        if (getCurrent(d.fromDate, d.toDate)) {
          return d;
        }
      });
      setCurrentAddressData(getSortedAddress(curRes));

      const nonCurRes = item?.addressDates?.map(d => {
        if (!getCurrent(d.fromDate, d.toDate)) {
          return d;
        }
      });
      setNonCurrentAddressData(getSortedAddress(nonCurRes));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);
    return (
      <>
        <Typography style={{ marginBottom: `${item?.addressDates && item?.addressDates.length > 0 ? '10px' : '5px'}` }}>
          {t('candidate.addressCard.roleType')}
        </Typography>
        {currentAddressData && currentAddressData.length > 0
          ? currentAddressData?.map(x => {
              return <ProcessChips x={x} item={item?.addressDates} />;
            })
          : ''}
        {nonCurrentAddressData && nonCurrentAddressData.length > 0
          ? nonCurrentAddressData?.map(x => {
              return <ProcessChips x={x} item={item?.addressDates} />;
            })
          : ''}
        {currentAddressData &&
        currentAddressData.length > 0 &&
        nonCurrentAddressData &&
        nonCurrentAddressData.length > 0
          ? ''
          : '-'}
      </>
    );
  };

  const BuildSections = props => {
    const { expand } = props;
    return (
      <>
        {sortAddress.map((item, index) => {
          const state = states.states.find(state => state.Description === item.state)?.ID;
          if (!expand) {
            if (index === 0) {
              return (
                <>
                  <BuildAddressSections item={item} state={state} />
                  {item.addressDates && <BuildRoleTypeChip item={item} />}
                </>
              );
            }
          } else {
            return (
              <>
                <BuildAddressSections item={item} state={state} />
                {item.addressDates && <BuildRoleTypeChip item={item} />}
              </>
            );
          }
        })}
      </>
    );
  };

  return (
    <>
      {sortAddress.length > 1 ? (
        !expand ? (
          <>
            <BuildSections expand={false} />
            <Typography className={classes.expand} color="primary" onClick={event => setExpand(true)}>
              {`Show Older ${title} ${sortAddress.length - 1 > 1 ? 'Addresses' : 'Address'}`}
              <KeyboardArrowDownwardIcon color="primary" />
            </Typography>
          </>
        ) : (
          <>
            <BuildSections expand={true} />
            <Typography className={classes.expand} color="primary" onClick={event => setExpand(false)}>
              {`Hide Older ${title} ${sortAddress.length - 1 > 1 ? 'Addresses' : 'Address'}`}
              <KeyboardArrowUpIcon color="primary" />
            </Typography>
          </>
        )
      ) : sortAddress.length > 0 ? (
        <BuildSections expand={true} />
      ) : (
        <Typography className={classes.fontTitle}>{`-`}</Typography>
      )}
    </>
  );
};
