/* eslint-disable tss-unused-classes/unused-classes */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { NewOrder } from 'app/models/Orders/OrderDetails';
import { DropDownItem } from 'app/models/DropDown';

const useStyles = makeStyles()(() => ({
  element: {
    height: '100%',
    width: '100%',
    background: '#fff',
  },
  label: {
    fontSize: 16,
  },
  root: {
    border: '1px solid #d2d2d1',
    overflow: 'hidden',
    borderRadius: 4,
    fontSize: 16,
    backgroundColor: '#fff',
    '&:hover': {
      borderColor: '#aaa',
      backgroundColor: '#fff',
    },
    '&:focus': {
      borderColor: '#aaa',
      borderRadius: 4,
      backgroundColor: '#fff',
    },
  },
  dropdownStyle: {
    backgroundColor: '#fff',
    border: '1px solid #D1D1D1',
    borderRadius: 4,
    boxShadow: 'none',
    marginTop: '2px',
    maxwidth: '200',
    maxHeight: 300,
  },
  disabled: {
    border: 'none',
    overflow: 'hidden',
    borderRadius: 4,
    fontSize: 16,
    backgroundColor: '#eee',
  },
  reqTextareastyle: {
    border: 'thin #02b9ff solid !important',
  },
}));

export const DropDownElement = ({
  order,
  onOrderPropertyChange,
  label,
  name,
  options,
  defaultOption = null,
  disabled = false,
  dissabledOption,
  req,
  id,
}: {
  order: NewOrder;
  onOrderPropertyChange: (property: string, value: any) => void;
  label: string;
  name: string;
  options: DropDownItem[];
  defaultOption?: DropDownItem;
  disabled?: boolean;
  dissabledOption?: number[];
  req?: boolean;
  id?: string;
}) => {
  const { classes } = useStyles();
  const [value, setValue] = useState<any>(defaultOption?.Description || order[name]);

  useEffect(() => {
    if (defaultOption?.Description) {
      handleChange(defaultOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const handleChange = event => {
    if (event?.target?.value) {
      onOrderPropertyChange(name, event.target.value);
      setValue(event.target.value);
    } else if (event?.Description) {
      onOrderPropertyChange(name, event.Description);
      setValue(event.Description);
    }
  };

  const isOptionDissabled = (id: number) => {
    return dissabledOption && dissabledOption.length > 0 && dissabledOption.filter(x => x === id).length > 0;
  };

  return (
    <FormControl variant="filled" className={classes.element}>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <Select
        id={id}
        data-testid="test drop down element"
        value={value}
        disableUnderline
        disabled={disabled}
        classes={classes}
        inputProps={{
          className: req ? classes.reqTextareastyle : '',
        }}
        MenuProps={{
          classes: { paper: classes.dropdownStyle },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        onChange={handleChange}
        IconComponent={ExpandMoreOutlinedIcon}
      >
        {options.map(item => (
          <MenuItem style={{ height: 30 }} disabled={isOptionDissabled(item.ID)} key={item.ID} value={item.Description}>
            {item.Description}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
