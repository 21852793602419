import React from 'react';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  root: {
    color: '#0098DB',
    fontSize: 30,
    //postion and tranformation are done to mark the lat/lng at pin tail - for accuracy
    position: 'absolute',
    transform: 'translate(-50%, -100%)',
    cursor: 'pointer',
  },
});

//Props are required eventhough not used.
//These props are implicitly accessed by GoogleMap for rendering markers
export const LocationPin = props => {
  const { classes } = useStyles();
  return <LocationOnOutlinedIcon classes={{ root: classes.root }} {...props} />;
};
