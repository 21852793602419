import React from 'react';
import Avatar from '@mui/material/Avatar';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    indicator: {
        fontSize: '12px'
    }

}));

export const CandidateAvatar = ({ firstName, lastName, avatarColor }) => {
    const { classes } = useStyles();
    const handleHover = () => {
    };
    const [isHovered, setIsHovered] = React.useState(false);
    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{ position: 'relative' }}
            data-testid="covered-candidate-avatar"
        >
            <Avatar
                sx={{
                    width: 23.67,
                    height: 23.67,
                    backgroundColor: avatarColor,
                    borderRadius: '50%',
                    '&:hover': {
                        height: 23.67,
                        width: '105%',
                        borderRadius: '16px',
                    },
                }}
                onMouseOver={handleHover}
            >
                {isHovered ? (
                    <span className={classes.indicator}>{`o/b/o ${firstName} ${lastName}`}</span>
                ) : (
                    <span className={classes.indicator}>{`${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`}</span>
                )}
            </Avatar>
        </div>
    );
};

