import { ErrorMessage } from '@hookform/error-message';
import { FormControl, Grid, TextField, TextFieldProps, Typography } from 'amn-ui-core';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { colors } from 'styles/styleVariables';

export type FormInputTextFieldProps = Omit<
    TextFieldProps & {
        required?: boolean;
        characterLimit?: number;
        characterLength?: number;
    },
    'variant'
>;

const FormInputTextField = (props: FormInputTextFieldProps) => {
    const { control, errors } = useFormContext();

    return (
        <FormControl fullWidth variant="filled">
            <Controller
                name={props.name}
                control={control}
                render={rest => (
                    <>
                        <TextField
                            defaultValue={props.defaultValue ?? ''}
                            variant="filled"
                            fullWidth
                            label={props.label}
                            type={props.type ?? 'text'}
                            error={errors[props.name]}
                            disabled={props.disabled}
                            helperText={
                                <Grid container direction="row" justifyContent="space-between" spacing={2} xs={12}>
                                    {props.characterLength != undefined ? (
                                        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-end">
                                            <Grid item></Grid>
                                            <Grid item >{`${rest.value?.length}/${props.characterLimit}`}</Grid>
                                        </Grid>
                                    ) : (
                                        <Grid item></Grid>
                                    )}
                                </Grid>
                            }
                            {...rest}
                            {...props}
                        />
                        <ErrorMessage
                            errors={errors}
                            name={props.name}
                            render={({ message }) => (
                                <Typography style={{ color: colors.errorRed, fontSize: '0.75rem' }}>
                                    {errors[props.name].type === 'required' ? 'Required' : message}
                                </Typography>
                            )}
                        />
                    </>
                )}
            />
        </FormControl >
    );
};

export default FormInputTextField;
