/* eslint-disable i18next/no-literal-string */
import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
  Alert,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import DeleteIcon from '@mui/icons-material/Delete';
import { getIcon } from './DocumentDropzoneHelper';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { Concatenate } from 'utils/string/string';
import moment from 'moment';
import { AttachmentItemState } from '../AttachmentPreview/AttachmentPreviewDialog';
import Checkmark from 'app/assets/images/Credentialing/checkmark.svg';
import { isCredentialing } from 'oidc/userRoles';

const useStyles = makeStyles()(theme => ({
  container: {
    border: '1px solid #ECECEC',
    borderRadius: '5px',
    margin: '0px 0px 8px 0px',
  },
  listItem: {
    padding: '10px',
    borderRadius: '5px',
  },
  error: {
    border: '1px solid #d9553d',
    borderRadius: '5px',
    margin: '0px 0px 8px 0px',
  },
  errorLink: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  btnAdjustment: {
    paddingRight: '15px',
  },
  updateDetails: {
    paddingRight: '15px',
  },
  docIcon: {
    paddingRight: '5px',
  },
  fileNameActions: {
    marginBottom: '10px',
  },
  docTitle: {
    width: 150,
    maxWidth: '100%',
  },
  icon: {
    textAlign: 'end',
  },
}));

export const DocumentDropzoneAttachments = ({
  itemNumber,
  docID,
  docName,
  docType,
  docEdit,
  dateAdded,
  dateAddedBy,
  item,
  submitFailedAttachment,
  onAttachmentTitleEdit,
  onAttachmentDelete,
  onErrorLinkSelection,
  status,
  canDelete,
  documentTitle,
  editTitle,
}: {
  itemNumber: number;
  docType: string;
  docName: string;
  docID: string;
  docEdit?: boolean;
  dateAdded?: string;
  dateAddedBy?: string;
  item: any;
  submitFailedAttachment: Function;
  onAttachmentDelete: Function;
  onAttachmentTitleEdit: Function;
  onErrorLinkSelection?: Function;
  status: AttachmentItemState;
  canDelete?: boolean | null;
  documentTitle?: string | null;
  editTitle: boolean | null;
}) => {
  const { classes } = useStyles();
  const appUser = useSelector(selectUser);
  const docIcon = getIcon(docType) ?? getIcon('application/unknown');
  const [title, setTitle] = useState('');

  useEffect(() => {
    setTitle(documentTitle ? documentTitle : `Attachment Title ${docID}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentTitle]);

  return (
    <div className={status === AttachmentItemState.error ? classes.error : classes.container}>
      <ListItem className={classes.listItem}>
        <ListItemIcon className={classes.btnAdjustment}>
          <TextField
            className={classes.docTitle}
            label={`Attachment Title`}
            type="text"
            variant="outlined"
            disabled={editTitle ? false : true}
            size="small"
            value={title}
            onChange={e => {
              setTitle(e?.target?.value ?? '');
              onAttachmentTitleEdit(e, docID);
            }}
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Box className={classes.fileNameActions}>
              <Box component="span" className={classes.docIcon}>
                {docIcon}
              </Box>
              <Box component="span" className={classes.docIcon}>
                {docName}
              </Box>
              <Box component="span">
                {/*docEdit ? <EditIcon fontSize="small" onClick={onAttachmentTitleEdit(docID)} /> : ''*/}
              </Box>
            </Box>
          }
          secondary={
            <React.Fragment>
              <Typography component="span" variant="body2" className={classes.updateDetails}>
                Date Added: {dateAdded ? dateAdded : moment().format('MM/DD/yyyy')}
              </Typography>
              <Typography component="span" variant="body2">
                Added by:
                {dateAddedBy
                  ? dateAddedBy
                  : Concatenate([appUser.userInfo?.firstName || '', appUser.userInfo?.lastName || ''], ' ')}
              </Typography>
            </React.Fragment>
          }
        />

        <ListItemSecondaryAction>
          <div>
            {canDelete && canDelete === true ? (
              <IconButton color="secondary" edge="end" aria-label="delete" size="medium">
                <DeleteIcon
                  fontSize="small"
                  style={{ color: '#B9BBBC' }}
                  onClick={e => isCredentialing(appUser?.userInfo?.roles || []) && onAttachmentDelete(itemNumber)}
                />
              </IconButton>
            ) : (
              <></>
            )}
            {status === AttachmentItemState.loading && (
              <Box className={classes.icon}>
                <CircularProgress size={'1rem'} />
              </Box>
            )}
            {status === AttachmentItemState.success && (
              <Box className={classes.icon}>
                <img src={Checkmark} alt="Checkmark" />
              </Box>
            )}
          </div>
        </ListItemSecondaryAction>
      </ListItem>
      {status === AttachmentItemState.error && (
        <Alert severity="error">
          <Typography component="span" variant="body2">
            Unable to upload. Network interruption. &nbsp;
            <Typography
              component="span"
              variant="body2"
              onClick={() => submitFailedAttachment(item)}
              className={classes.errorLink}
            >
              Please try again
            </Typography>
            .
          </Typography>
        </Alert>
      )}
    </div>
  );
};
