import { IGraphicStepper } from 'app/ComponentLibrary/GraphicStepper/GraphicStepper';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectExpiringCounts } from '../Store/ExpiringCredential.selector';
import { useTranslation } from 'react-i18next';
import { WorkDeskPipeline } from '../../WorkDesk/WorkDeskPipeline';
import { selectCredExpiringDaysGrid } from 'app/components/Common/WorkDesk/PipelineChoice/pipelineChoice.selectors';
import { pipelineChoiceKeys } from 'app/components/Common/WorkDesk/PipelineChoice/pipelineChoice.redux';
import { expiringCredentialsSubStatus } from '../Models/ExpiringDocumentInterfaces';

export const ExpiringCredentialsPipeline = () => {
  const { t } = useTranslation();
  const counts = useSelector(selectExpiringCounts);
  const pipelineSelection = useSelector(selectCredExpiringDaysGrid);
  const [tiers, setTiers] = useState<IGraphicStepper[]>([]);

  useEffect(() => {
    setTiers([
      {
        category: {
          tag: `${t(`${expiringCredentialsSubStatus.today}`)}`,
          showTooltip: counts.today.reduce((prev, next) => prev + next) ? false : true,
          value: expiringCredentialsSubStatus.today,
          color: '',
        },
        subcategory: [
          ...counts.today.map((item, index) => ({
            tag: t(`T${index + 1}`),
            data: item,
            showTooltip: item === 0 ? true : false,
            value: `${expiringCredentialsSubStatus.today} - T${index + 1}`,
          })),
        ],
      },
      {
        category: {
          tag: `${t(`${expiringCredentialsSubStatus.tomorrow}`)}`,
          showTooltip: counts.tomorrow.reduce((prev, next) => prev + next) ? false : true,
          value: expiringCredentialsSubStatus.tomorrow,
          color: '',
        },
        subcategory: [
          ...counts.tomorrow.map((item, index) => ({
            tag: t(`T${index + 1}`),
            data: item,
            showTooltip: item === 0 ? true : false,
            value: `${expiringCredentialsSubStatus.tomorrow} - T${index + 1}`,
          })),
        ],
      },
      {
        category: {
          tag: `${t(`${expiringCredentialsSubStatus.next7Days}`)}`,
          showTooltip: counts.nextSevenDays.reduce((prev, next) => prev + next) ? false : true,
          value: expiringCredentialsSubStatus.next7Days,
          color: '',
        },
        subcategory: [
          ...counts.nextSevenDays.map((item, index) => ({
            tag: t(`T${index + 1}`),
            data: item,
            showTooltip: item === 0 ? true : false,
            value: `${expiringCredentialsSubStatus.next7Days} - T${index + 1}`,
          })),
        ],
      },
      {
        category: {
          tag: `${t(`${expiringCredentialsSubStatus.next14Days}`)}`,
          showTooltip: counts.nextFourteenDays.reduce((prev, next) => prev + next) ? false : true,
          value: expiringCredentialsSubStatus.next14Days,
          color: '',
        },
        subcategory: [
          ...counts.nextFourteenDays.map((item, index) => ({
            tag: t(`T${index + 1}`),
            data: item,
            showTooltip: item === 0 ? true : false,
            value: `${expiringCredentialsSubStatus.next14Days} - T${index + 1}`,
          })),
        ],
      },
      {
        category: {
          tag: `${t(`${expiringCredentialsSubStatus.next30Days}`)}`,
          showTooltip: counts.nextThirtyDays.reduce((prev, next) => prev + next) ? false : true,
          value: expiringCredentialsSubStatus.next30Days,
          color: '',
        },
        subcategory: [
          ...counts.nextThirtyDays.map((item, index) => ({
            tag: t(`T${index + 1}`),
            data: item,
            showTooltip: item === 0 ? true : false,
            value: `${expiringCredentialsSubStatus.next30Days} - T${index + 1}`,
          })),
        ],
      },
      {
        category: {
          tag: `${t(`${expiringCredentialsSubStatus.allExpiring}`)}`,
          showTooltip: counts.allExpiring.reduce((prev, next) => prev + next) ? false : true,
          value: expiringCredentialsSubStatus.allExpiring,
          color: '',
        },
        subcategory: [
          ...counts.allExpiring.map((item, index) => ({
            tag: t(`T${index + 1}`),
            data: item,
            showTooltip: item === 0 ? true : false,
            value: `${expiringCredentialsSubStatus.allExpiring} - T${index + 1}`,
          })),
        ],
      },
      {
        category: {
          tag: `${t(`${expiringCredentialsSubStatus.onAssignment}`)}`,
          showTooltip: counts.onAssignment.reduce((prev, next) => prev + next) ? false : true,
          value: expiringCredentialsSubStatus.onAssignment,
          color: '',
        },
        subcategory: [
          ...counts.onAssignment.map((item, index) => ({
            tag: t(`T${index + 1}`),
            data: item,
            showTooltip: item === 0 ? true : false,
            value: `${expiringCredentialsSubStatus.onAssignment} - T${index + 1}`,
          })),
        ],
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counts]);

  return (
    <WorkDeskPipeline
      data={tiers}
      gridSelectionKey={pipelineChoiceKeys.CRED_EXPIRING_DAYS}
      selection={pipelineSelection}
      boxStepper
    />
  );
};
