import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
import { IChip } from "app/ComponentLibrary/Filter/ChipsContainer";
import { IAssociationChip } from "app/models/Notification/Notification";
import { IUserDetailFields, IUserDetailsUpdatePayload, IUserFieldValidationError, initialUserDetailFields } from "../Helper";

export interface IUserManagement {
    count: number;
    chips?: IUserManagementChips;
    appliedFilters?: any | null;
    items?: any[] | [];
    appliedFiltersFlag?: boolean;
    empInfo?: any;
    empRoles?: any[];
    checkedRoles?: any[];
    empCardInfo?: any;
    selectedCardId?: any;
    dialogeOpen?: boolean;
    isDataChanged?: boolean;
    employeeAssociationTypeOptions: any[];
    employeeAssociations: any;
    tabValue?: number;
    customTabvalue?: number;
    addNewUser: {
        openNewUserModal: boolean;
        createUserPayload: ICreateUserPayload;
        errorFields: Array<IUserFieldValidationError>;
    }
    parentAssociationChips?: IAssociationChip[];
    childAssociationsChips?: IAssociationChip[];
    initialParentAssociationChips?: IAssociationChip[];
    initialChildAssociationsChips?: IAssociationChip[];
    associationsDiffer?: boolean;
    userDetails: IUserDetails[];
    userDetailFields: IUserDetailFields;
    disableUserFieldEdit: boolean;
    statusOptions: any[];
    companyOptions: any[];
    updateUserInfoErrorFields: Array<IUserFieldValidationError>;
}

export interface ICreateUserPayload {
    firstName: string;
    lastName: string;
    userName: string;
    statusId: number;
    companyId: number;
    faxNumber: string;
    phoneNumber: string;
    emailId: string;
}

export interface IUserManagementPayload {
    firstName?: any,
    lastName?: any,
    userName?: any,
    statusId?: any,
    employeeId?: any,
    employeeRoleId?: any,
    companyId?: any,
    pageNumber?: any,
    pageSize?: any,
}
export interface IUpdateRoleLinkPayload {
    employeeId?: number,
    roleIds?: any[],
}
export interface IUpdateAssociationkPayload {
    employeeId?: number,
    associations?: any[],
}
export interface IEmployeeRolesPayload {
    employeeId: number;
}
export interface IUserManagementChips {
    eid: IChip | any;
    usernames: IChip | any;
    employeeNames: IChip | any;
    companies: IChip | any;
    employeeStatus: IChip | any;
    employeeRole: IChip | any;
}

export interface IUserDetailPayload {
    employeeIds: number[];
}

export interface IDetailsOptionsPayload {
    keyword: string;
}

export interface IUserDetails {
    userName: string;
    faxNumber: string;
    smsNumber: string;
    companyId: number;
    companyBrandName: string;
    statusId: number;
    statusDescription: string;
    employeeId: number;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailId: string;
    workdayId: string;
    deptHireDate: string;
    excludeFromTasks: boolean;
    crossCompanyUser: boolean;
}

export const defaultChips: IUserManagementChips = {
    eid: null,
    usernames: null,
    employeeNames: null,
    companies: null,
    employeeStatus: { id: '1', name: 'Active', value: '1' },
    employeeRole: null
}

export const initialState: IUserManagement = {
    count: 0,
    chips: defaultChips,
    appliedFilters: null,
    items: [],
    appliedFiltersFlag: false,
    empRoles: null,
    dialogeOpen: false,
    checkedRoles: null,
    isDataChanged: false,
    employeeAssociationTypeOptions: [],
    employeeAssociations: null,
    tabValue: 0,
    customTabvalue: 0,
    parentAssociationChips: [],
    childAssociationsChips: [],
    initialParentAssociationChips: [],
    initialChildAssociationsChips: [],
    associationsDiffer: false,
    addNewUser: {
        openNewUserModal: false,
        createUserPayload: {
            firstName: "",
            lastName: "",
            userName: "",
            statusId: 0,
            companyId: 0,
            faxNumber: "",
            phoneNumber: "",
            emailId: "",
        },
        errorFields: []
    },
    userDetails: [],
    userDetailFields: initialUserDetailFields,
    disableUserFieldEdit: true,
    statusOptions: [],
    companyOptions: [],
    updateUserInfoErrorFields: []
}
const getUserSearchLists = createAction<{
    firstName?: string,
    lastName?: string,
    userName?: string,
    statusId?: number,
    employeeId?: number,
    employeeRoleId?: number,
    companyId?: number,
    pageNumber?: number,
    pageSize?: number,
}>('GET_USER_LISTS');

const updateEmployeeRoles = createAction<{
    employeeId?: number,
    roleIds?: any[],
}>('UPDATE_EMPLOYEE_ROLES');

const updateEmployeeAssociation = createAction<{
    employeeId?: number,
    associations?: any[],
}>('UPDATE_EMPLOYEE_ASSOCIATION');

export const getEmpRoles = createAction<IEmployeeRolesPayload>('userManagement/getEmpRoles');
export const employeeAssociations = createAction<IEmployeeRolesPayload>('SET_EMPLOYEE_ASSOCIATIONS');

export const employeeAssociationLookups = createAction('SET_EMPLOYEE_ASSOCIATION_TYPE_OPTIONS');
export const getUserDetail = createAction<IUserDetailPayload>('GET_USER_DETAIL');
export const getDetailsOptions = createAction<IDetailsOptionsPayload>('GET_DROPDOWN_OPTIONS');

export const createNewUser = createAction<ICreateUserPayload>('CREATE_NEW_USER');
export const updateAmieUserInfo = createAction<IUserDetailsUpdatePayload>('UPDATE_USER_INFO');

const reduxSlice = createSlice({
    name: 'userManagement',
    initialState,
    reducers: {
        setData(state, action: PayloadAction<{ count: number; items: any[] }>) {
            state.items = action.payload.items;
            state.count = action.payload.count;
        },
        setChips(state, action: PayloadAction<any>) {
            state.chips = action.payload;
        },
        setSelectedFilters(state, action: PayloadAction<any>) {
            state.appliedFilters = action.payload;
        },
        setSelectedFiltersFlag(state, action: PayloadAction<any>) {
            state.appliedFiltersFlag = action.payload;
        },
        setEmpInfo(state, action: PayloadAction<any>) {
            state.empInfo = action.payload;
        },
        setDialoge(state, action: PayloadAction<boolean>) {
            state.dialogeOpen = action.payload;
        },
        setIsDataChanged(state, action: PayloadAction<boolean>) {
            state.isDataChanged = action.payload;
        },
        setEmpRoles(state, action: PayloadAction<any>) {
            state.empRoles = action.payload;
        },
        setCheckedRoles(state, action: PayloadAction<any>) {
            state.checkedRoles = action.payload;
        },
        setEmpCardInfo(state, action: PayloadAction<any>) {
            state.empCardInfo = action.payload;
        },
        setSelectedCardId(state, action: PayloadAction<any>) {
            state.selectedCardId = action.payload;
        },
        setEmployeeAssociationTypeOptions(state, action: PayloadAction<any>) {
            state.employeeAssociationTypeOptions = action.payload;
        },
        setemployeeAssociations(state, action: PayloadAction<any>) {
            state.employeeAssociations = action.payload;
        },
        setTabValue(state, action: PayloadAction<any>) {
            state.tabValue = action.payload;
        },
        setCustomTabValue(state, action: PayloadAction<any>) {
            state.customTabvalue = action.payload;
        },
        setParentAssociationChips(state, action: PayloadAction<any>) {
            state.parentAssociationChips = action.payload;
        },
        setChildAssociationsChips(state, action: PayloadAction<any>) {
            state.childAssociationsChips = action.payload;
        },
        setInitialParentAssociationChips(state, action: PayloadAction<IAssociationChip[]>) {
            state.initialParentAssociationChips = action.payload;
        },
        setInitialChildAssociationsChips(state, action: PayloadAction<IAssociationChip[]>) {
            state.initialChildAssociationsChips = action.payload;
        },
        setAssociationsDiffer(state, action: PayloadAction<boolean>) {
            state.associationsDiffer = action.payload;
        },
        setUserDetails(state, action: PayloadAction<IUserDetails[]>) {
            state.userDetails = action.payload;
        },
        setUserDetailsFields(state, action: PayloadAction<IUserDetailFields>) {
            state.userDetailFields = action.payload;
        },
        setStatusOptions(state, action: PayloadAction<any>) {
            state.statusOptions = action.payload
        },
        setCompanyOptions(state, action: PayloadAction<any>) {
            state.companyOptions = action.payload
        },
        toggleCreateUserModal(state, action: PayloadAction<boolean>) {
            state.addNewUser.openNewUserModal = action.payload;
        },
        setCreateUserErrorFields(state, action: PayloadAction<Array<IUserFieldValidationError>>) {
            state.addNewUser.errorFields = action.payload;
        },
        setUpdateUserInfoErrorFields(state, action: PayloadAction<Array<IUserFieldValidationError>>) {
            state.updateUserInfoErrorFields = action.payload;
        },
        setDisableUserFieldEdit(state, action: PayloadAction<boolean>) {
            state.disableUserFieldEdit = action.payload;
        },
        reset() {
            return { ...initialState };
        }
    }
});

export const getUserManagementActions = {
    ...reduxSlice.actions, getUserSearchLists, getEmpRoles, updateEmployeeRoles,
    employeeAssociationLookups, employeeAssociations, updateEmployeeAssociation, getUserDetail,
    getDetailsOptions, createNewUser, updateAmieUserInfo
};
export const { name: userManagementSliceKey, reducer: userManagementReducer } = reduxSlice;