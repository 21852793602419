/* eslint-disable i18next/no-literal-string */
import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import DocViewerFit from 'app/components/Common/DocViewer/DocViewer';
import { CircularProgress, Grid, Paper, Alert } from 'amn-ui-core';
import GetAppIcon from '@mui/icons-material/GetApp';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachementsCredentialDetails from './AttachementsCredentialDetails';
import { getCredentialName } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/CredentialsTab/TableData/tableHelpers';
import {
  downloadFile,
  getFileNameFormat,
  isNullWithDefaultVal,
  isNullWithEmptyVal,
  isPrevSupported,
  isFileTypeHtml,
} from 'app/components/Common/DocViewer/Helpers/DocHelpers';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import { onDeleteAttachment } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/CredentialsTab/CredentialsHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { IAttachments } from 'app/models/Credentialing/Credentials';
import { candidateDetailActions } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import { selectUser } from 'oidc/user.selectors';
import { isCredentialing } from 'oidc/userRoles';
import { ViewSkillChecklist } from './ViewSkillCheckList/ViewSkillChecklist';
import { useDecision } from '@optimizely/react-sdk';
import { ff_ui_viewing_skill_checklist } from 'app/constants/FeatureFlags/Candidate/Keys';
import { candidateDetailsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { globalActions } from 'app/ApplicationRoot/Global.redux';

const useStyles: any = makeStyles()(theme => ({
  icon: {
    color: '#B9BBBC',
    cursor: 'pointer',
    '&:hover': {
      color: '#006FB9',
    },
  },
  disableIcon: {
    color: theme.palette.framework.system.lightGray,
    cursor: 'not-allowed',
    '&:hover': {
      color: theme.palette.framework.system.lightGray,
    },
  },
  link: {
    color: '#006FB9',
    cursor: 'pointer',
  },
  title: {
    textAlign: 'left',
    font: 'normal normal normal 16px/21px Roboto',
    letterSpacing: '0px',
    color: '#7d7b7b',
    opacity: 1,
    fontWeight: 500,
  },
  content: {
    marginTop: '2%',
  },
  sections: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  alert: {
    '& .MuiAlert-icon': {
      fontSize: 24,
      color: '#694D28',
    },
    height: '52px',
    fontSize: 'inherit',
  },
  seperator: {
    margin: '0 8px',
  },
  paperOverride: {
    padding: '22px 25px 25px 22px !important',
  },
  hide: {
    display: 'none',
  },
  doc: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  supportDoc: {
    color: '#797676',
    fontSize: 400,
  },
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50vh',
  },
}));

export const AttachmentsTabContent = props => {
  const { classes } = useStyles();
  const attachment: any = props.attachment;
  const attachmentList: any = props.attachmentList;
  const credential: any = props.credentials;
  const lookupsProp: any = props.credentialLookUps;
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [inlineAttachments, setInlineAttachments] = useState<IAttachments[]>([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const history = useHistory();
  const user = useSelector(selectUser);
  const [showViewSkillChecklist] = useDecision(ff_ui_viewing_skill_checklist);
  const candidateDetails = useSelector(candidateDetailsSelection);

  const { /*reqId, reqTypeId,*/ travelerId, brandId } = useParams<any>();

  const onDeleteSelected = (e, item) => {
    setInlineAttachments([item]);
    setOpenDeleteConfirmation(true);
  };

  const refreshPage = (attachmentIds?) => {
    setTimeout(function () {
      if (attachmentIds && attachmentIds.length) {
        history.replace({
          pathname: `${history.location.pathname.split('?')[0]}${`?at=${attachmentIds}`}`,
          //pathname: `${history.location.pathname.split('?')[0]}${``}`,
          state: { navigateBack: true },
        });
      } else {
        history.push({ pathname: `/candidate/${travelerId}/${brandId}`, search: '?tab=1' });
      }
    }, 1500);
  };

  const DeleteAttchmentItem = async (item: IAttachments) => {
    let processSucess = true;
    await onDeleteAttachment(props.candidateId, props.requirementID, item, attachmentList, dispatch)
      .then(res => {
        if (res) {
          //
        }
      })
      .catch(err => {
        processSucess = false;
      })
      .finally(() => {});
    return processSucess;
  };

  const handleDeleteConfirmClose = async (modalState: boolean, clearValues: boolean) => {
    setOpenDeleteConfirmation(modalState);
    if (clearValues) {
      if (inlineAttachments.length > 0 && inlineAttachments[0]) {
        const item = inlineAttachments[0];
        let processSucess = true;
        const deletedAttchements: IAttachments[] = [];
        if (item.travelerRequirementImageID) {
          setIsDeleting(true);
          dispatch(
            globalActions.setSnackBar({
              message: t('search.grid.download.deleteMessage'),
              severity: 'error',
              iconColor: 'blue',
              onSnackClose: () => setIsDeleting(false),
            }),
          );
          await DeleteAttchmentItem(item).then(result => {
            if (!result) {
              processSucess = false;
            } else {
              deletedAttchements.push(item);
            }
          });
          if (processSucess) {
            globalActions.setSnackBar({
              message: t('search.grid.delete.snackbarSuccess'),
              severity: 'success',
            });
            setIsDeleting(false);
            //update redux
            const remaingAttchements: IAttachments[] = [];
            attachmentList?.map(item => {
              const attachmentItems = deletedAttchements?.filter(t => t.sortOrder === item.sortOrder);
              if (!attachmentItems || attachmentItems.length <= 0) {
                remaingAttchements.push(item);
              }
            });
            dispatch(
              candidateDetailActions.setAttachments(
                remaingAttchements.sort(function (a, b) {
                  return a.sortOrder > b.sortOrder ? 1 : b.sortOrder > a.sortOrder ? -1 : 0;
                }),
              ),
            );
            dispatch(candidateDetailActions.setSelPreviewAttachments(remaingAttchements));
            dispatch(candidateDetailActions.setHistoryAttachments(remaingAttchements));

            const sortedAttachments = _.orderBy(
              remaingAttchements?.map((item, idx) => {
                return { ...item, sortOrder: idx };
              }),
              (o: any) => {
                return o != null ? o.lastUpdated : o;
              },
              ['desc'],
            );
            //reload view
            const filterAttachmentIds = sortedAttachments?.map(x => x.sortOrder);
            refreshPage(filterAttachmentIds);
          } else {
            dispatch(
              globalActions.setSnackBar({
                message: t('search.grid.delete.snackbarError'),
                severity: 'error',
              }),
            );
            setIsDeleting(false);
          }
        }
      }
    }
  };

  const UnsupportedFile = (doctype: { doctype: string }) => {
    const { classes } = useStyles();

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.doc}>
          {isFileTypeHtml(doctype.doctype) ? (
            <LanguageIcon className={classes.supportDoc} />
          ) : (
            <DescriptionOutlinedIcon className={classes.supportDoc} />
          )}
        </Grid>
      </Grid>
    );
  };

  /**
   * Method to render document viewer / skill checklist pdf generation
   */
  const renderDocumentForAttachments = () => {
    if (attachment?.isSkillChecklist) {
      return <ViewSkillChecklist credentialDateCompleted={credential?.credential?.dateCompleted} />;
    } else if (isPrevSupported(attachment?.document?.fileType)) {
      return <DocViewerFit docs={[attachment?.document]} preview={false} />;
    } else {
      return <UnsupportedFile doctype={attachment?.document?.fileType} />;
    }
  };

  const handleDownload = event => {
    if (attachment?.isSkillChecklist) {
      event.stopPropagation();
    } else {
      dispatch(
        globalActions.setSnackBar({
          message: t('search.grid.download.successMessage'),
          severity: 'success',
        }),
      );
      downloadFile(
        attachment?.document?.uri,
        attachment?.document?.fileData,
        getFileNameFormat(credential?.credential?.requirementType, props.attachmentsNum, attachment?.lastUpdated),
        attachment?.document?.fileType,
      );
    }
  };

  const displayDateAdded = () => {
    if (attachment?.isSkillChecklist) {
      return credential.credential.lastUpdate != null
        ? Moment(credential.credential.lastUpdate).format('MM/DD/YYYY h:mm:ss a')
        : 'N/A';
    } else {
      return attachment?.lastUpdated != null ? Moment(attachment?.lastUpdated).format('MM/DD/YYYY') : 'N/A';
    }
  };

  return (
    <React.Fragment>
      {isDeleting && <CircularProgress className={classes.MuiCircularProgressRoot} />}
      <Grid container spacing={2} className={classes.sections}>
        <Grid item xs={9}>
          {attachment !== null ? (
            <Paper className={`paper ${classes.paperOverride}`} elevation={0}>
              <Grid container className={classes.title}>
                <Grid item xs={6}>
                  {getCredentialName(credential?.credential?.requirementType, credential?.credential?.credentialName)}
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                      <Box>
                        <Box component="span" className={classes.seperator}>
                          {'Status:'}
                        </Box>
                        <Box component="span" className={classes.link}>
                          {isNullWithDefaultVal(attachment?.status)}
                        </Box>
                        <Box component="span" className={classes.seperator}>
                          {'|'}
                        </Box>
                        <Box component="span" className={classes.seperator}>
                          {'Added By:'}
                        </Box>
                        <Box component="span" className={classes.link}>
                          {attachment?.isSkillChecklist
                            ? `${credential.credential.lastUpdatedByName.first} ${credential.credential.lastUpdatedByName.last}`
                            : isNullWithDefaultVal(attachment?.lastUpdatedBy)}
                        </Box>
                        <Box component="span" className={classes.seperator}>
                          {'|'}
                        </Box>
                        <Box component="span" className={classes.seperator}>
                          {'Date Added:'}
                        </Box>
                        <Box component="span" className={classes.link}>
                          {displayDateAdded()}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item spacing={1} onClick={event => handleDownload(event)} data-testid="attachment-download">
                      <GetAppIcon className={!attachment?.isSkillChecklist ? classes.icon : classes.disableIcon} />
                    </Grid>
                    {!isFileTypeHtml(attachment?.document?.fileType) ? (
                      <Grid
                        item
                        onClick={e =>
                          !attachment?.isSkillChecklist &&
                          isCredentialing(user?.userInfo?.roles || []) &&
                          onDeleteSelected(e, attachment)
                        }
                        data-testid="attachment-delete"
                      >
                        <DeleteIcon
                          className={
                            isCredentialing(user?.userInfo?.roles || []) && !attachment?.isSkillChecklist
                              ? classes.icon
                              : classes.disableIcon
                          }
                        />
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={3} className={classes.content}>
                <Grid item xs={12} className={attachment?.status != 'Incomplete' ? classes.hide : ''}>
                  <Alert severity="warning" className={classes.alert}>
                    <div>{attachment?.status}</div>
                  </Alert>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <Box component="span">{isNullWithEmptyVal(attachment?.comments)}</Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box>
                    <Grid container item direction="column" justifyContent="space-evenly">
                      {renderDocumentForAttachments()}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          ) : (
            <Paper className={'paper'} elevation={0}>
              {'Not Attachments Found'}
            </Paper>
          )}
        </Grid>
        <Grid item xs={3}>
          <AttachementsCredentialDetails credentials={credential} lookups={lookupsProp} />
        </Grid>
      </Grid>
      <GenericDialog
        open={openDeleteConfirmation}
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
        dialogTitleProps={{
          text: t('search.grid.delete.dialogSureTitle'),
        }}
        dialogActions={[
          {
            text: t('search.grid.delete.dialogCancelButton'),
            variant: 'text',
            color: 'tertiary',
            onClick: () => handleDeleteConfirmClose(false, false),
          },
          {
            text: t('Ok'),
            variant: 'contained',
            color: 'primary',
            onClick: () => handleDeleteConfirmClose(false, true),
          },
        ]}
      >
        <span>{t('search.grid.delete.dialogDeleteContent')}</span>
      </GenericDialog>
    </React.Fragment>
  );
};

export default AttachmentsTabContent;
