import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse, Grid } from 'amn-ui-core';
import { SearchPageIcons } from 'app/ComponentLibrary/Filter/SearchPageIcons';
import { selectFilters, selectGridFiltersExpanded } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';
import {
  facilityCommunicationHistoryActions,
  IFacilityCommunicationChips,
} from '../store/FacilityCommunicationHistory.redux';
import { SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import { gridStateActions } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import {
  selectClientContacts,
  selectCommunicationTypeOpts,
  selectInitiatedBy,
  selectUnitsLookusps,
  selectUserRoles,
} from '../store/FacilityCommunicationHistory.selector';
import { CustomFilter } from 'app/ComponentLibrary/Filter';
import { CommunicationHistoryFilters, contactStatus } from './FacilityCommunicationHistoryFilterUtils';
import { IChip } from 'app/ComponentLibrary/Filter/ChipsContainer';
import { FacilityResponse } from 'app/models/Facility/FacilityDetails';
import { selectFacilityDetail } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';

const useStyles = makeStyles()({
  fillterCollapse: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '3px',
  },
  collapse: {
    paddingTop: '4px',
  },
});

const FacilityCommunicationFilters = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { reset, setValue } = useFormContext();
  const dispatch = useDispatch();
  const gridStateFilters = useSelector(selectFilters);
  const clientContacts = useSelector(selectClientContacts);
  const unitsLookusps = useSelector(selectUnitsLookusps);
  const communicationTypeOpts = useSelector(selectCommunicationTypeOpts);
  const initiatedBy = useSelector(selectInitiatedBy);
  const userRoles = useSelector(selectUserRoles);
  const facilityDetailsResponse: FacilityResponse | null = useSelector(selectFacilityDetail);

  const gridSpecs = useRef(
    new CommunicationHistoryFilters(
      {
        communicationType: [],
        initiatedByOpts: [],
        units: [],
        contactNameOpts: [],
        userRole: [],
      },
      gridStateFilters,
    ),
  );

  const filtersExpanded = useSelector(selectGridFiltersExpanded);
  const [fields, setFields] = React.useState(gridSpecs.current.getCommunicationHistoryFilters() ?? []);

  const defaultAppliedFilters: IFacilityCommunicationChips = {
    contactNameOpts: [],
    units: [],
    communicationType: [],
    initiatedBy: [],
    contactName: [],
    contactStatus: contactStatus[0],
    userRole: [],
    date: undefined,
  };

  const fetchFilterSpecs = () => {
    gridSpecs.current.filterSpecs = {
      contactNameOpts: clientContacts || [],
      units: unitsLookusps || [],
      initiatedByOpts: initiatedBy || [],
      communicationType: communicationTypeOpts || [],
      userRole: userRoles || [],
    };
    const fieldsSchema = gridSpecs.current.getCommunicationHistoryFilters();
    setFields(fieldsSchema ?? []);
  };

  const resetToDefaultFilter = () => {
    Object.entries(defaultAppliedFilters).map(item => setValue(item[0], item[1]));
    props.onApplyFilter(defaultAppliedFilters);
  };

  const resetFilter = () => {
    reset({ ...defaultAppliedFilters });
  };

  const handleChange = () => {
    dispatch(gridStateActions.setFiltersExpanded(!filtersExpanded));
  };

  React.useEffect(() => {
    if (facilityDetailsResponse?.facilityId && !props?.filterPreference?.value.filtersApplied) {
      resetToDefaultFilter();
    }
  }, [initiatedBy]);

  React.useEffect(() => {
    fetchFilterSpecs();
  }, [initiatedBy, userRoles, clientContacts, unitsLookusps, communicationTypeOpts]);

  useEffect(() => {
    if (facilityDetailsResponse?.facilityId) {
      dispatch(
        facilityCommunicationHistoryActions.getUnitsAndClientContactLookups(facilityDetailsResponse?.facilityId),
      );
      dispatch(
        facilityCommunicationHistoryActions.getUserRolesAndInitiatedByLookups(facilityDetailsResponse?.facilityId),
      );
    }
  }, [facilityDetailsResponse?.facilityId]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={11}>
        <Collapse timeout={200} in={filtersExpanded} collapsedSize={45} className={classes.collapse}>
          <div>
            <CustomFilter
              fields={fields}
              justifyContent={'flex-start'}
              version2
              resetBtn
              onResetForm={() => resetFilter()}
              analytics={{
                caller: t('FacilityCommunicationHistory.communicationHistory'),
                onApply: true,
                properties: {
                  category: SearchType.facility,
                },
              }}
            />
          </div>
        </Collapse>
      </Grid>
      <Grid item xs={1} className={classes.fillterCollapse}>
        <SearchPageIcons
          resetlbutton
          funnelButton
          checked={filtersExpanded}
          onResetForm={() => resetToDefaultFilter()}
          onAdvancedFilterClick={() => handleChange()}
        />
      </Grid>
    </Grid>
  );
};

export default FacilityCommunicationFilters;
