import { Box, Button, Divider, Skeleton } from 'amn-ui-core';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React from 'react';
import { Title } from '../../../ProfileTab/Cards/styles';
import { useSelector } from 'react-redux';
import { selectAuditData } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { useTranslation } from 'react-i18next';
import { RowSection } from '../../Common/RowSection';
import { useDecision } from '@optimizely/react-sdk';
import {
  ff_summaryTabIconsUpdate,
  ff_ui_Candidate_Summary_Manage_Audits,
} from 'app/constants/FeatureFlags/Candidate/Keys';
import { usePromiseTracker } from 'react-promise-tracker';
import { useStyles } from './utils';
import { theme } from 'styles/global-styles';

function AuditSummaryUI({ setAuditModalOpen, setAddAuditOpen, setIsAddAudit, setFilterModel }) {
  const auditData = useSelector(selectAuditData);
  const { t } = useTranslation();
  const [summaryTabIconsUpdateDecision] = useDecision(ff_summaryTabIconsUpdate);
  const [addAuditFeature] = useDecision(ff_ui_Candidate_Summary_Manage_Audits);
  const { promiseInProgress } = usePromiseTracker({ area: 'get-candidate-details', delay: 0 });
  const { classes } = useStyles();

  const handleNavigate = () => {
    setIsAddAudit(true);
    setAddAuditOpen(true);
  };

  return (
    <>
      <LayoutGridItem style={{ width: '100%' }}>
        <Box className={classes.container}>
          <Box className={classes.children}>
            <LayoutGrid direction="column" spacing={1}>
              <Title className={classes.title} style={{ width: '100%' }}>
                {t('candidate.auditCard.title')}
                <span
                  data-testid="all-audits-button"
                  className={
                    auditData?.totals.total > 0 ? classes.profileInlineLink : classes.profileInlineLinkDisabled
                  }
                  role="button"
                  tabIndex={-1}
                  onKeyDown={() => {}}
                  onClick={() => {
                    setAuditModalOpen(true);
                    setFilterModel({
                      items: [],
                    });
                  }}
                >
                  {t('candidate.auditCard.all')}
                </span>
              </Title>
              <span className={classes.sectionDivider}>&nbsp;</span>
              <Divider style={{ width: '100%' }} />
              <LayoutGrid
                direction="row"
                spacing={1}
                justifyContent="space-between"
                wrap="nowrap"
                className={classes.content}
              >
                <LayoutGridItem style={{ paddingTop: '25px' }}>
                  {promiseInProgress ? (
                    <LayoutGridItem mr={2}>
                      <Skeleton />
                      <Skeleton />
                    </LayoutGridItem>
                  ) : (
                    <LayoutGrid direction="column" spacing={1}>
                      {auditData?.totals?.total === 0 ? (
                        <RowSection
                          header={t('candidate.auditCard.auditUnavailable')}
                          data={''}
                          preData={t('candidate.auditCard.auditUnavailable')}
                          color={theme.palette.system.paleDarkGreen}
                        />
                      ) : (
                        <>
                          {summaryTabIconsUpdateDecision.enabled && auditData?.totals?.holdFileOut > 0 && (
                            <RowSection
                              header={
                                <span>
                                  <span data-testid="hold-file-out">{t('candidate.auditCard.holdFileOut1')}</span>
                                  <span>
                                    <Button
                                      data-testid="hold-file-out-audits-button"
                                      type="button"
                                      variant="text"
                                      disableRipple
                                      disableTouchRipple
                                      disableFocusRipple
                                      onClick={() => {
                                        setAuditModalOpen(true);
                                        setFilterModel({
                                          items: [
                                            { columnField: `holdFileOut`, operatorValue: `contains`, value: `Yes` },
                                          ],
                                        });
                                      }}
                                      classes={{
                                        root: classes.button,
                                      }}
                                    >
                                      {auditData?.totals?.holdFileOut || '0'}
                                    </Button>
                                  </span>
                                </span>
                              }
                              data={''}
                              preData={''}
                              color={theme.palette.framework.navyBlue}
                              showAlert={true}
                            />
                          )}
                          {summaryTabIconsUpdateDecision.enabled && auditData?.totals?.ongoing > 0 && (
                            <RowSection
                              header={
                                <span>
                                  <span data-testid="ongoing-audits">{t('candidate.auditCard.ongoingAudit')}</span>
                                  <span>
                                    <Button
                                      data-testid="ongoing-audits-button"
                                      type="button"
                                      variant="text"
                                      disableRipple
                                      disableTouchRipple
                                      disableFocusRipple
                                      onClick={() => {
                                        setAuditModalOpen(true);
                                        setFilterModel({
                                          items: [
                                            { columnField: `status`, operatorValue: `contains`, value: `Ongoing` },
                                          ],
                                        });
                                      }}
                                      classes={{
                                        root: classes.button,
                                      }}
                                    >
                                      {auditData?.totals?.ongoing || '0'}
                                    </Button>
                                  </span>
                                </span>
                              }
                              data={''}
                              preData={true}
                              showHalfwayProgress
                              showHalfwayProgressSecondaryIcon
                              color={theme.palette.framework.sandyBrown}
                              showAlert
                            />
                          )}
                          {auditData?.totals?.fyionly > 0 && (
                            <RowSection
                              header={
                                <span>
                                  <span data-testid="fyi-audits">{t('candidate.auditCard.fyiAudit')}</span>
                                  <span>
                                    <Button
                                      data-testid="fyi-audits-button"
                                      type="button"
                                      variant="text"
                                      disableRipple
                                      disableTouchRipple
                                      disableFocusRipple
                                      onClick={() => {
                                        setAuditModalOpen(true);
                                        setFilterModel({
                                          items: [
                                            { columnField: `status`, operatorValue: `contains`, value: `FYI only` },
                                          ],
                                        });
                                      }}
                                      classes={{
                                        root: classes.button,
                                      }}
                                    >
                                      {auditData?.totals?.fyionly || '0'}
                                    </Button>
                                  </span>
                                </span>
                              }
                              data={''}
                              preData={true}
                              showHalfwayProgress
                              showHalfwayProgressSecondaryIcon
                              showAlert
                              color={theme.palette.framework.navyBlue}
                            />
                          )}
                        </>
                      )}
                    </LayoutGrid>
                  )}
                </LayoutGridItem>
                {addAuditFeature.enabled && (
                  <LayoutGridItem onClick={handleNavigate} style={{ paddingTop: 2 }}>
                    <span className={classes.profileInlineLink}>{t('candidate.profileSummaryCard.add')}</span>
                  </LayoutGridItem>
                )}
              </LayoutGrid>
            </LayoutGrid>
          </Box>
        </Box>
      </LayoutGridItem>
    </>
  );
}

export default AuditSummaryUI;
