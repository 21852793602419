import { put, takeLatest, getContext, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { ExceptionType } from 'app/enums/Common';
import { trackException } from 'app-insights/appInsightsTracking';
import { trackPromise } from 'react-promise-tracker';
import { SharedService } from 'app/services/SharedServices/shared-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { reportsViewActions } from './slice';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { IR225ReportRequest } from 'app/models/ReportPages/IR225Report';
import { IR448ReportRequest } from 'app/models/ReportPages/IR448Report';

const TrackR225Report = (fn, ...args) => trackPromise(fn(...args), 'get-r225-report');
const TrackR448Report = (fn, ...args) => trackPromise(fn(...args), 'get-r448-report');

export function* requestR225ReportDetails(action: PayloadAction<IR225ReportRequest>) {
  try {
    yield put(reportsViewActions.setR225ReportDetails({ response: [], inputDetails: null }));
    const sharedService: SharedService = yield getContext('sharedService');
    const response = yield call(TrackR225Report, async () => {
      return sharedService.getR225Report(action.payload.request);
    });
    if (httpSuccess(response?.status)) {
      yield put(
        reportsViewActions.setR225ReportDetails({
          response: response?.data,
          inputDetails: action.payload.inputDetails,
        }),
      );
    } else if (response?.isAxiosError && (response?.response?.data || response?.response?.data.errorMessage)) {
      yield put(
        reportsViewActions.setR225ReportDetails({
          response: [],
          inputDetails: action.payload.inputDetails,
        }),
      );
      yield put(
        globalActions.setSnackBar({
          message:
            response?.response?.data?.errorMessage ??
            response?.response?.data?.title ??
            response?.response?.data ??
            action.payload.inputDetails.snackbarErrorMessage,
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    yield put(
      reportsViewActions.setR225ReportDetails({
        response: [],
        inputDetails: action.payload.inputDetails,
      }),
    );
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestR225ReportDetails',
        area: 'src/store/redux-store/report-details/saga.ts',
      },
    });
  }
}

export function* requestR448ReportDetails(action: PayloadAction<IR448ReportRequest>) {
  try {
    yield put(reportsViewActions.setR448ReportDetails({ response: [] }));
    const sharedService: SharedService = yield getContext('sharedService');
    const response = yield call(TrackR448Report, async () => {
      return sharedService.getR448Report(action.payload);
    });
    if (httpSuccess(response?.status)) {
      yield put(
        reportsViewActions.setR448ReportDetails({
          response: response?.data,
        }),
      );
    } else if (response?.isAxiosError && (response?.response?.data || response?.response?.data.errorMessage)) {
      yield put(
        reportsViewActions.setR448ReportDetails({
          response: [],
        }),
      );
      yield put(
        globalActions.setSnackBar({
          message:
            response?.response?.data?.errorMessage ??
            response?.response?.data?.title ??
            response?.response?.data,
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    yield put(
      reportsViewActions.setR448ReportDetails({
        response: []
      }),
    );
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestR448ReportDetails',
        area: 'src/store/redux-store/report-details/saga.ts',
      },
    });
  }
}

export function* reportDetailsSaga() {
  yield takeLatest(reportsViewActions.getR225ReportDetails.type, requestR225ReportDetails);
  yield takeLatest(reportsViewActions.getR448ReportDetails.type, requestR448ReportDetails);

}
