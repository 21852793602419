import { ChipsContainer } from 'app/ComponentLibrary/Filter/ChipsContainer';
import React, { createRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid } from 'amn-ui-core';
import { determineChipSelected } from '@AMIEWEB/AmWorkDesk/PlacementsDesk/filterHelper';
import { generateChips } from '@AMIEWEB/GlobalSearch/helper';
import { selectFilters } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';


const UnitOrderChips = props => {
    const { category } = props;
    const [chips, setChips] = useState({});
    const hiddenSubmitButton = createRef<HTMLButtonElement>();
    const { setValue, getValues } = useFormContext();
    const { t } = useTranslation();
    const filterObject = useSelector(selectFilters);


    const getNewSelection = (key, chip) => ({
        ...filterObject?.selectedChipFilters,
        [key]: determineChipSelected(
            chip,
            filterObject?.selectedChipFilters ? filterObject?.selectedChipFilters[key] : undefined,
        ),
    });

    const handleChipDelete = (field, chip) => {
        const fieldValues = getValues(field);
        let newFieldValues: any = null;
        setValue(field, newFieldValues, { shouldDirty: true });
        if (chip?.isSelected) {
            const newSelections = getNewSelection(field, chip);
            props.onApplyFilter(
                { ...getValues(), [field]: Array.isArray(fieldValues) ? [...(newFieldValues || [])] : null },
                newSelections,
            );
        } else hiddenSubmitButton.current?.click();
    };

    useEffect(() => {
        setChips(
            filterObject?.filters
                ? generateChips(filterObject?.filters, t, category, false, filterObject?.selectedChipFilters)
                : {},
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterObject?.filters]);

    return (
        <Grid container spacing={2}>
            <Grid item>
                <ChipsContainer chips={chips} onDelete={handleChipDelete} />
                <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
            </Grid>
        </Grid>

    )
}

export default UnitOrderChips