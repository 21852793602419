import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { makeStyles } from 'tss-react/mui';
import { InputAdornment } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const useStyles = makeStyles()(theme => ({
  root: {
    background: '#fff',
    underline: {
      '&&&:before': {
        borderBottom: 'none',
      },
      '&&:after': {
        borderBottom: 'none',
      },
    },
    border: '1px solid #888',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#5dc2a6',
    },
  },
}));

export function PhoneNumber(props) {
  const { classes } = useStyles();
  const [error, setError] = useState(props.error);
  const inputProps = {
    disableUnderline: true,
    classes: {
      root: classes.root,
    },
    endAdornment:
      props.error || error ? (
        <InputAdornment position="end">
          <ErrorOutlineIcon style={{ color: '#D9553D', height: '15px', width: '15px' }} />
        </InputAdornment>
      ) : null,
    ...props.InputProps,
  };

  const [formatValue, setFormatValue] = React.useState(props.value);
  const errorText = props.errorText;

  function onValidation(event) {
    const beforeValidation = event.target.value;

    //only allowing numbers as input
    let input = beforeValidation.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
    const first = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    //formatting the number to be displayed in (###) ### -####

    if (input.length > 6) {
      input = `(${first}) ${middle}-${last}`;
    } else if (input.length > 3) {
      input = `(${first}) ${middle}`;
    } else if (input.length > 0) {
      input = `(${first}`;
    }

    if (props.required) {
      setError(!event.target.value);
    } else setError(false);

    setFormatValue(input);
    event.target.value = input;

    props.onChange(event);
  }

  function onInvalid(event) {
    const beforeValidation = event.target.value;

    if (props.required && !event.target.value) {
      setError(true);
    }
    //only allowing numbers as input
    const input = beforeValidation.replace(/\D/g, '').substring(0, 10);
    if (input.length > 0 && input.length !== 10) {
      setError(true);
    }
  }

  return (
    <React.Fragment>
      <div>
        <TextField
          {...props}
          fullWidth
          id={props.id}
          label={props.label}
          type="phone"
          variant={props.variant}
          onChange={e => onValidation(e)}
          onFocus={props.onFocus}
          onBlur={onInvalid || props.onBlur}
          value={formatValue}
          helperText={error || props.error ? errorText : props.helperText}
          InputProps={inputProps}
          error={error || props.error}
          disabled={props.disabled}
          className={props.className}
        />
      </div>
    </React.Fragment>
  );
}
