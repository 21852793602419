export const Shifts = [
    { title: '8', id: 1 },
    { title: '10', id: 2 },
    { title: '12', id: 3 },
    { title: '7.5', id: 4 },
    { title: '11.5', id: 5 },
    { title: '9.5', id: 6 },
    { title: '7', id: 7 },
    { title: '4', id: 8 },
    { title: '5', id: 9 },
    { title: '6', id: 10 },
    { title: '9', id: 11 },
    { title: '11', id: 12 },
    { title: '14', id: 13 },
    { title: '16', id: 14 },
    { title: '18', id: 15 },
    { title: '6.4', id: 16 },
    { title: '6.5', id: 17 },
    { title: '6.8', id: 18 },
    { title: '7.25', id: 19 },
    { title: '7.4', id: 20 },
]
export const Status = [
    { title: 'Pending', id : 1 },
    { title: 'Rejected', id : 2 },
    { title: 'Pending Approval', id : 3 },
    { title: 'Approved', id : 4 },
    { title: 'Expired', id : 5 },
    { title: 'Void', id : 6 }
]