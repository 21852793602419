import { createSelector } from '@reduxjs/toolkit';
import { INotification } from 'app/models/Notification/Notification';
import { RootState } from 'types';
import { initialState } from './initial-state';

const selectDomain = (state: RootState) => state?.notificationData || initialState;

export const notificationSelection = createSelector(
  [selectDomain],
  (notificationState: INotification) => notificationState,
);
export const conversationNotes = createSelector([selectDomain], notificationState => notificationState.sms.notes);
export const newConversationNotes = createSelector([selectDomain], notificationState => notificationState.sms.newNotes);

export const attachmentDetails = createSelector([selectDomain], notificationState => notificationState.sms.attachments);
export const selectEmailData = createSelector([selectDomain], notificationState => notificationState.email);

export const selectEmailType = createSelector(
  [selectDomain],
  notificationState => notificationState.email.data?.emailType,
);
export const selectSendType = createSelector(
  [selectDomain],
  notificationState => notificationState.email.data?.sendType,
);
export const selectEmailBCC = createSelector(
  [selectDomain],
  notificationState => notificationState.email.data?.bccs,
);
export const selectSender = createSelector(
  [selectDomain],
  notificationState => notificationState.email.data?.sender,
);
export const selectSendWithoutConsent = createSelector(
  [selectDomain],
  notificationState => notificationState.email?.sendWithoutConsent
);
export const logFilters = createSelector(
  [selectDomain],
  notificationState => notificationState.logFilters,
);

export const selectForwardAttachmentUrls = createSelector(
  [selectDomain],
  notificationState => notificationState.email?.forwardAttachmentUrls,
);

export const selectInboxFilter = createSelector(
  [selectDomain],
  notificationState => notificationState.inboxUnreadFilter,
);

export const selectCandidatesConsents = createSelector(
  [selectDomain],
  notificationState => notificationState.candidateConsents,
);

export const selectSidePanelConsents = createSelector(
  [selectDomain],
  notificationState => notificationState.sidePanelConsents,);

export const selectSmsCandidateConsents = createSelector(
  [selectDomain],
  notificationState => notificationState.smsCandidateConsents,);

export const selectEmailConsentWithdrawn = createSelector(
  [selectDomain],
  notificationState => notificationState.emailConsentWithdrawn
);

export const selectCategoryTitles = createSelector(
  [selectDomain],
  notificationState => notificationState.email?.categoryTitles
);
export const selectPacketCategoryTitles = createSelector(
  [selectDomain],
  notificationState => notificationState.email?.packetCategoryTitles
);

export const selectInboxMessages = createSelector(
  [selectDomain],
  notificationState => notificationState.inboxMessages
);

export const selectUnreadCount = createSelector(
  [selectDomain],
  notificationState => notificationState.unreadCount
);

export const selectCommunicationLimitsEmail = createSelector(
  [selectDomain],
  notificationState => notificationState.communicationLimitsEmail
);
export const selectCommunicationLimitsSms = createSelector(
  [selectDomain],
  notificationState => notificationState.communicationLimitsSms
);
export const selectCommunicationLimitsDialog = createSelector(
  [selectDomain],
  notificationState => notificationState.communicationLimitsDialog
);

export const selectPinnedMessages = createSelector(
  [selectDomain],
  notificationState => notificationState.sms.pinnedMessages
);

export const selectFacilityBaseTemplate = createSelector(
  [selectDomain],
  notificationState => notificationState.facilityBaseTemplate
);

export const selectPacketFacilityTemplates = createSelector(
  [selectDomain],
  notificationState => notificationState.packetFacilityTemplates
);
export const selectPacketFacilityTemplatesById = createSelector(
  [selectDomain],
  notificationState => notificationState.packetFacilityTemplatesById
);
export const selectIsEdit = createSelector(
  [selectDomain],
  notificationState => notificationState.isEdit
);
export const selectVoiceAction = createSelector(
  [selectDomain],
  notificationState => notificationState.voiceAction
);
export const selectVoiceDisposition = createSelector(
  [selectDomain],
  notificationState => notificationState.voiceDisposition
);
export const selectVoiceLoader = createSelector(
  [selectDomain],
  notificationState => notificationState.voiceLoader
);