import React from 'react';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { AuditModal } from 'app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/Statuses/Submission/amnRequirementRows/AuditsGrid/AuditModal';
import { useTranslation } from 'react-i18next';
import { formatAuditRecords } from 'store/redux-store/placement-summary/helper';
import { refactorData } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/SummaryTab/Cards/helper';

export interface IAuditModalDialogProps {
  setOpenAuditModal: any;
  openAuditModal: any;
  auditData: any;
}

const AuditModalDialog = (props: IAuditModalDialogProps) => {
  const { setOpenAuditModal, openAuditModal, auditData } = props;
  const { t } = useTranslation();
  
  return (
    <GenericDialog
      data-testid="audit-popup-modal"
      open={openAuditModal}
      onClose={() => setOpenAuditModal(false)}
      fullWidth
      draggable
      maxWidth={'md'}
      disableEnforceFocus
      variant="blue"
      //classes={{ paper: classes.paper }}
      dialogTitleProps={{
        text: t('candidate.auditCard.title'),
        closeButton: true,
        expandable: true,
      }}
    >
      <>
        <AuditModal
          auditDetails={formatAuditRecords(refactorData(auditData?.items))}
          forceClose={() => setOpenAuditModal(false)}
          filterModel={{
            items: [],
          }}
          onFilterModelChange={()=>{}}
          /** TODO: Add action for data refresh */
          refreshData={() => {}}
        />
      </>
    </GenericDialog>
  );
};
export default AuditModalDialog;
