import React from 'react';
import { Helmet } from 'react-helmet';
import { useDecision } from '@optimizely/react-sdk';
import { ff_global_ui_walkme_injected_script } from 'app/constants/FeatureFlags/Global/keys';

export const WalkMe = () => {
  const [walkMeFlag] = useDecision(ff_global_ui_walkme_injected_script, { autoUpdate: true });
  return (
    walkMeFlag.enabled && (
      <Helmet
        script={[
          {
            type: 'text/javascript',
            innerHTML: `
            (function () {
              var walkme = document.createElement('script');
              walkme.type = 'text/javascript';
              walkme.async = true;
              walkme.src = '${globalThis?.app?.env?.REACT_APP_WALKME_CDN}';
              var s = document.getElementsByTagName('script')[0];
              s.parentNode.insertBefore(walkme, s);
              window._walkmeConfig = { smartLoad: true };
            })()
            `,
          },
        ]}
      />
    )
  );
};
