import { UseSubType } from "../Constants";

export const checkPlacementuseType = (id, notificationlogData) => {
    if (!Array.isArray(notificationlogData)) {
        return false;
    }
    for (const data of notificationlogData) {
        if (
            id === data.id &&
            data.useType === 'placement' &&
            (data.useSubType === UseSubType.PacketSubmission || data.useSubType === UseSubType.ConfirmationLetter || data.useSubType === UseSubType.PlacementRfoNotification)
        ) {
            return true;
        }
    }
    return false;
};