import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import ComparisionTable from '@AMIEWEB/Common/ComparisionTable/ComparisionTable/ComparisionTable';
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { selectModalProps } from '../store/candidateOrderMatch.selector';
import Draggable from 'react-draggable';

const useStyles = makeStyles()({
  dialogueContent: {
    padding: 0,
    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .dialogContentRoot': {
      padding: 0,
    },
  },
  dialog: {
    minWidth: '960px',
    '& .MuiDialog-paperWidthSm': {
      minWidth: '960px',
      maxWidth: '100%',
      margin: 0,
    },
  },
});
const OrderComparisionModal = props => {
  const { classes } = useStyles();
  const { tableData, tableRows, referenceData, open } = useSelector(selectModalProps);
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const handleExpanded = exp => {
    setExpanded(exp);
  };
  const handleCloseModal = (e, reason) => {
    if (reason !== 'backdropClick') {
      setExpanded(false);
      props.onClose();
    }
  };
  return (
      <GenericDialog
        open={open}
        variant="blue"
        onClose={(e, reason) => handleCloseModal(e, reason)}
        draggable={true}
        className={classes.dialog}
        dialogTitleProps={{
          text: 'Order Comparison',
          closeButton: true,
          expandable: true,
          onExpandChange: handleExpanded,
        }}
        dialogContentProps={{
          classes: {
            root: classes.dialogueContent,
          },
        }}
      >
        <div style={{ width: !expanded && 1114 }}>
          <ComparisionTable
            tableRows={tableRows}
            tableData={tableData}
            referenceData={referenceData}
            width={1134}
            height={!expanded && 554}
            expanded={expanded}
          />
        </div>
      </GenericDialog>
  );
};

export default OrderComparisionModal;
