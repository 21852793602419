import { GridTag } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { useDecision } from '@optimizely/react-sdk';
import { usePageVisitTimeTracker } from 'app-insights/appInsightsTracking';
import { PlacementSearchFilterName, placementViewInfo } from 'app/components/GlobalSearch/Placement/SearchUtils';
import { AssociatedPlacements } from 'app/components/Placement/AssociatedPlacements/AssociatedPlacements';
import {
  associatedPlacementSliceKey,
  associatedPlacementReducer,
} from 'app/components/Placement/AssociatedPlacements/store/associatedPlacements.redux';
import { associatedPlacementsSaga } from 'app/components/Placement/AssociatedPlacements/store/associatedPlacements.saga';
import { notCandidateColumns } from 'app/components/Placement/AssociatedPlacements/store/utils';
import { ff_placement_region_fields } from 'app/constants/FeatureFlags/Placement/Keys';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

export const CandidatePlacementsWrapper = () => {
  usePageVisitTimeTracker('Candidate Details:Placement Tab');
  useInjectReducer({ key: associatedPlacementSliceKey, reducer: associatedPlacementReducer });
  useInjectSaga({ key: associatedPlacementSliceKey, saga: associatedPlacementsSaga });

  const { t } = useTranslation();

  const { travelerId: candidateId } = useParams<{ travelerId: string; brandId: string }>();
  const [regionFlag] = useDecision(ff_placement_region_fields);

  const [columns, setColumns] = useState(
    placementViewInfo(t, []).filter(
      column =>
        !(regionFlag.enabled ? notCandidateColumns : [...notCandidateColumns, 'region']).some(
          notValue => notValue === column.field,
        ),
    ),
  );

  return (
    <AssociatedPlacements
      setColumns={setColumns}
      columns={columns}
      gridTag={GridTag.CandidatePlacements}
      rootFilter={{ [PlacementSearchFilterName.cid]: [candidateId] }}
    />
  );
};
