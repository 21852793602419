/* eslint-disable tss-unused-classes/unused-classes */
import { FormControlLabel, Switch } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { CustomTooltip } from '../../../../Common/Tooltips/CustomTooltip';
import { NewOrder } from 'app/models/Orders/OrderDetails';

const useStyles = makeStyles<void, 'checked' | 'track'>()((theme, _params, classes) => ({
  root: {
    align: 'center',
  },
  label: {
    fontSize: theme.typography.pxToRem(16),
  },
  switchBase: {
    [`&.${classes.checked}`]: {
      color: '#006FB9',
    },
    [`&.${classes.checked} + .${classes.track}`]: {
      backgroundColor: '#006FB9',
    },
  },
  checked: {},
  track: {},
}));

export const SwitchFormElement = ({
  order,
  onOrderPropertyChange,
  name,
  label,
  tooltip,
  id,
}: {
  order: NewOrder;
  name: string;
  onOrderPropertyChange: (property: string, value: any) => void;
  label?: string;
  tooltip?: string;
  id?: string;
}) => {
  const { classes } = useStyles();

  const handleChange = event => {
    onOrderPropertyChange(event.target.name, event.target.checked);
  };

  return (
    <FormControlLabel
      data-testid="test switch form element"
      classes={classes}
      control={
        tooltip ? (
          <CustomTooltip tooltipContent={tooltip}>
            <Switch name={name} checked={order[name]} classes={classes} onChange={handleChange} />
          </CustomTooltip>
        ) : (
          <Switch id={id} name={name} checked={order[name]} classes={classes} onChange={handleChange} />
        )
      }
      label={label}
    />
  );
};
