/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ClickAwayListener, Drawer, Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles<{
  top: any;
  chipOffBottomBy: any;
  width: any;
  backgroundColor: any;
  height: any;
  overflow: any;
}>()((theme: Theme, { top, chipOffBottomBy, width, backgroundColor, height, overflow, fullScreen }) => ({
  drawer: {
    flexShrink: 0,
    '& > .MuiPaper-root:first-child': {
      top: top !== undefined ? `${top}px` : '50px',
      backgroundColor: backgroundColor !== undefined ? backgroundColor : '#fff',
      maxHeight: `calc(100% - ${chipOffBottomBy})`,
      height: height ? height : undefined,
      zIndex: fullScreen ? 1299 : 'default',
      overflow: overflow ? overflow : undefined,
    },
  },
  scroll: {
    overflowX: 'hidden',
  },
  popper: {
    overflowY: 'unset',
    overflowX: 'unset',
  },
}));

interface DrawerFittedProps {
  [x: string]: any;
  open: boolean;
  top?: number;
  width?: number;
  height?: string;
  overflow?: string;
  fullScreen?: boolean;
  // @deprecated - shadow is now forced on all drawers
  shadow?: boolean;
  backgroundColor?: string;
  chipOffBottomBy?: string;
  onDrawerClose?: (_) => void;
  onEdited?: (_) => void;
}

export const DrawerContext = React.createContext({
  popperOpen: false,
  setPopperOpen: (componentKey: any, open: boolean) => { },
});

export const DrawerFitted = ({
  open,
  top,
  chipOffBottomBy = '0px',
  width,
  height,
  overflow,
  shadow,
  backgroundColor,
  onDrawerClose,
  onEdited,
  fullScreen = false,
  ...rest
}: DrawerFittedProps) => {
  const { classes, cx } = useStyles({ top, chipOffBottomBy, width, backgroundColor, height, overflow, fullScreen });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [popperOpenKeys, _setPopperOpenKeys] = useState<{ [key: string]: boolean }>({});
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const setPopperOpen = (componentKey: any, open: boolean) => {
    _setPopperOpenKeys(prev => {
      setIsPopperOpen(Object.values({ ...prev, [componentKey]: open }).indexOf(true) !== -1);
      return { ...prev, [componentKey]: open };
    });
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const _closeDrawer = event => {
    if (onEdited) {
      onEdited(event);
    } else {
      setIsOpen(false);
      onDrawerClose?.(event);
    }
  };

  return (
    //Removed ClickAwayListener as part of the story : 201786 - Side Panels - Push modals/content to the left instead of overlapping/hiding
    <Drawer
      classes={{ paper: cx({ [classes.popper]: isPopperOpen, [classes.scroll]: !isPopperOpen }) }}
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={isOpen}
      sx={{
        '& > .MuiPaper-root:first-child': {
          width: fullScreen ? '100%' : (width !== undefined ? `${width}px` : 'auto'),
          boxShadow: '0px 4px 10px rgb(0, 0, 0, 0.2)',
        }
      }}
    >
      <DrawerContext.Provider value={{ popperOpen: isPopperOpen, setPopperOpen }}>
        {rest.children}
      </DrawerContext.Provider>
    </Drawer>
  );
};
