export enum optionalSectionNames {
  TravelExperience = 'Travel Experience',
  Float = 'Float',
  FlexibleSchedule = 'Flexible Schedule',
  NonStandardRequirements = 'Non-Standard Requirements',
  EMR = 'EMR',
}

export enum panelOpen {
  yes = 'Yes',
  no = 'No',
}

export enum reqPref {
  req = 'Req',
  pref = 'Pref',
}


export enum UnitStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Prospect = 'Prospect',
  Delete = 'Delete',
  Closed = 'Closed',
  Pending = 'Pending',
}

export enum ContactStatus {
  Active = 1,
  Inactive = 2,
}