import { FormProvider, useForm } from 'react-hook-form';
import { IOrderBonusSave, orderDataActions } from '@AMIEWEB/Order/Store/Order.redux';
import {
  CalculationTypes,
  OrderBonusFieldNames,
  OrderBonusFormDefaultValues,
  OrderBonusFormFields,
  OrderBonusStatus,
} from '../Common/Constants';
import React, { useEffect, useMemo, useState } from 'react';
import {
  bonusApprovedMethodOptions,
  bonusBillingTypeOptions,
  bonusLessThan13WeeksOptions,
  bonusPayerOptions,
  bonusPaymentTypeOptions,
  bonusShortHourTypeOptions,
  bonusTypeOptions,
  shiftOptions,
} from '../Common/OptionsHelper';
import { useDispatch, useSelector } from 'react-redux';

import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { OrderBonusForm } from './OrderBonusForm';
import { Button, Theme, Typography } from 'amn-ui-core';
import { UseTemplate } from '../CustomComponents/UseTemplate';
import _ from 'lodash';
import { convertToFormat } from 'app/helpers/dateHelper';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { makeStyles } from 'tss-react/mui';
import { selectOrderBonusTab } from '@AMIEWEB/Order/Store/Order.selectors';
import { selectUser } from 'oidc/user.selectors';
import { useTranslation } from 'react-i18next';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { OrderBonusTextField } from '../CustomComponents/OrderBonusTextField';
import BlockIcon from '@mui/icons-material/DoDisturbAltOutlined';
import DoneIcon from '@mui/icons-material/Done';

import VoidIcon from '@mui/icons-material/RemoveCircleOutline';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import { getColor, getDecimalValues, loadBonusDetailsByBonusID } from '../Common/Utils';
import { userRoles } from 'oidc/userRoles';
import { Authorized } from 'oidc/userHelper';

const useStyles = makeStyles<{ approvalStatusColor: string; orderBonusStatusId: number }>()(
  (theme: Theme, { approvalStatusColor, orderBonusStatusId }) => ({
    dialog: {
      minWidth: '980px',
      '& .MuiDialog-paperWidthSm': {
        minWidth: '980px',
        maxWidth: '100%',
      },
      padding: 0,
      '& .MuiDialogContent-root': {
        padding: 0,
        overflow: 'hidden',
      },
      '& .dialogContentRoot': {
        padding: 0,
      },
    },
    IconStyle: {
      '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'none',
        color: `${approvalStatusColor}`,
      },
      color: `${approvalStatusColor}`,
      cursor: 'default',
    },
    disableIconStyle: {
      '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'none',
        color: theme.palette.components.button.text.secondary.color,
      },
      color: theme.palette.components.button.text.secondary.color,
      cursor: 'default',
    },
    statusButton: {
      backgroundColor: 'transparent',
      fontSize: '12px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      '& .MuiButton-startIcon': {
        marginRight: '3px',
      },
    },
    approvedButton: {
      color: orderBonusStatusId === 1 ? theme.palette.framework.system.kellyGreen : theme.palette.system.darkGrey,
      backgroundColor: 'transparent',
      '&:hover': {
        color: theme.palette.framework.system.kellyGreen,
        backgroundColor: 'transparent',
        textDecoration: 'none',
      },
    },
    voidButton: {
      color: orderBonusStatusId === 4 ? theme.palette.framework.system.navyBlue : theme.palette.system.darkGrey,
      backgroundColor: 'transparent',
      '&:hover': {
        color: theme.palette.system.navyBlue,
        backgroundColor: 'transparent',
        textDecoration: 'none',
      },
    },
    expiredButton: {
      color: orderBonusStatusId === 3 ? theme.palette.framework.chart.darkRed : theme.palette.system.darkGrey,
      backgroundColor: 'transparent',
      '&:hover': {
        color: theme.palette.framework.chart.darkRed,
        backgroundColor: 'transparent',
        textDecoration: 'none',
      },
    },
    rightContainer: {
      backgroundColor: theme.palette.framework.system.whisper,
      justifyContent: 'space-between',
    },
    cancelBtn: {
      padding: '6px 26px',
      backgroundColor: theme.palette.system.buttonGray,
      '&:hover': {
        backgroundColor: theme.palette.system.buttonGray,
      },
      color: theme.palette.common.white,
      fontSize: '12px',
      marginLeft: '-50px',
      height: '38px',
    },
    submitButton: {
      padding: '6px 28px',
      backgroundColor: theme.palette.framework.system.navyBlue,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.framework.system.navyBlue,
      },
      marginRight: '3px',
      fontSize: '12px',
      height: '38px',
      '& .MuiInputBase-root.Mui-disabled': {
        pointerEvents: 'auto',
      },
    },
  }),
);
export const OrderBonusModal = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { title, open, onClose, isNewOrderBonus, isEditAllowed, disableControls, orderId, rowData, useTemplate } =
    props;
  const formMethods = useForm({ defaultValues: OrderBonusFormDefaultValues });
  const {
    reset,
    watch,
    setValue,
    formState: { dirtyFields, touched },
  } = formMethods;
  const [openDiscardDialog, setOpenDiscardDialog] = useState<boolean>(false);
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(true);
  const [statusChangeButton, setStatusChangeButton] = useState<boolean>(false);
  const [finalValidation, setFinalValidation] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [orderBonusStatusId, setOrderBonusStatusId] = useState<number>(2);
  const approvalStatusColor = isNewOrderBonus ? getColor(OrderBonusStatus.pending) : getColor(rowData?.descStatus);
  const { classes } = useStyles({ approvalStatusColor, orderBonusStatusId });
  const requiredFields = useMemo(() => {
    return OrderBonusFormFields.filter(x => x.required === true).map(field => field.FieldName);
  }, []);
  const nonRequiredFields = useMemo(() => {
    return OrderBonusFormFields.filter(x => x.required === false).map(field => field.FieldName);
  }, []);
  const requiredWatchValues = watch(requiredFields);
  const nonRequiredWatchValues = watch(nonRequiredFields);
  const requiredFieldCheck = Object.values(requiredWatchValues).every(x => x !== null && x !== '');
  const orderBonusTab = useSelector(selectOrderBonusTab);
  const isOrderBonusCreated = orderBonusTab?.isOrderBonusCreated;
  const orderBonusShifts = orderBonusTab?.orderBonusShiftList;
  const updateOrderBonusShifts = orderBonusTab?.updateOrderBonusShift;
  const calculatedAmount = orderBonusTab?.OrderBonusCalculatedAmount;
  const { userInfo } = useSelector(selectUser);
  const approveCheck = isNewOrderBonus || (rowData?.descStatus === OrderBonusStatus.pending && isEditAllowed);

  const paymentTypeCheck = useMemo(() => {
    return (
      (requiredWatchValues?.paymentType?.ID === 4 || requiredWatchValues?.paymentType?.ID === 5) &&
      nonRequiredWatchValues?.shiftsApplicable?.length === 0
    );
  }, [requiredWatchValues?.paymentType?.ID, nonRequiredWatchValues?.shiftsApplicable]);

  const bonusAmountCheck = useMemo(() => {
    return requiredWatchValues?.bonusAmount !== ''
      ? getDecimalValues(requiredWatchValues?.bonusAmount ?? 0) === getDecimalValues(0)
      : false;
  }, [requiredWatchValues?.bonusAmount]);

  const startDateFrom = useMemo(() => {
    return requiredWatchValues.startDate && requiredWatchValues.startDate !== ''
      ? new Date(requiredWatchValues.startDate).getTime()
      : undefined;
  }, [requiredWatchValues.startDate]);

  const endDateTo = useMemo(() => {
    return requiredWatchValues.endDate && requiredWatchValues.endDate !== ''
      ? new Date(requiredWatchValues.endDate).getTime()
      : undefined;
  }, [requiredWatchValues.endDate]);

  useEffect(() => {
    if (startDateFrom && endDateTo && startDateFrom > endDateTo) {
      setErrorMessage(t('order.orderBonusTab.messages.dateError'));
      setDisableSaveButton(true);
    } else if (finalValidation && bonusAmountCheck) {
      setErrorMessage(
        t('order.orderBonusTab.messages.zeroAmountError', {
          amountType: t('order.orderBonusTab.placeHolder.bonusAmount'),
        }),
      );
      setDisableSaveButton(true);
    } else if (finalValidation && paymentTypeCheck) {
      setErrorMessage(t('order.orderBonusTab.messages.PaymentTypeError'));
      setDisableSaveButton(true);
    } else if (finalValidation && !bonusAmountCheck && !paymentTypeCheck) {
      setErrorMessage(null);
      setFinalValidation(false);
      setDisableSaveButton(false);
    } else if (
      requiredFieldCheck &&
      startDateFrom < endDateTo &&
      !finalValidation &&
      (isNewOrderBonus ||
        (isEditAllowed &&
          ((statusChangeButton && orderBonusStatusId === 1) ||
            ((statusChangeButton || !_.isEmpty(dirtyFields) || updateOrderBonusShifts) && orderBonusStatusId === 2))) ||
        (statusChangeButton && orderBonusStatusId === 3) ||
        (statusChangeButton && orderBonusStatusId === 4))
    ) {
      setDisableSaveButton(false);
    } else {
      setDisableSaveButton(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewOrderBonus, isEditAllowed, orderBonusStatusId, requiredWatchValues, nonRequiredWatchValues]);

  useEffect(() => {
    if (calculatedAmount?.returnValue === 0) {
      setValue(
        calculatedAmount?.type === CalculationTypes.bonusRate
          ? OrderBonusFieldNames.bonusAmount
          : OrderBonusFieldNames.billingAmount,
        getDecimalValues(calculatedAmount?.rate) || null,
      );
      setErrorMessage(null);
    } else if (calculatedAmount?.error) {
      setErrorMessage(calculatedAmount?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatedAmount]);

  useEffect(() => {
    if ((isEditAllowed || useTemplate) && rowData) {
      dispatch(
        orderDataActions.getOrderBonusShiftList({
          orderId: parseInt(rowData?.orderId),
          bonusId: parseInt(rowData?.bonusId),
        }),
      );
      loadBonusDetailsByBonusID(rowData).forEach(field => setValue(field.Name, field.Value, { shouldDirty: false }));
      setOrderBonusStatusId(
        rowData?.descStatus === OrderBonusStatus.approved && !useTemplate
          ? 1
          : rowData?.descStatus === OrderBonusStatus.expired && !useTemplate
          ? 3
          : rowData?.descStatus === OrderBonusStatus.void && !useTemplate
          ? 4
          : 2,
      );
    } else if (isNewOrderBonus) {
      reset({ ...OrderBonusFormDefaultValues });
      dispatch(orderDataActions.getOrderBonusCalculatedAmount(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewOrderBonus, isEditAllowed, useTemplate, rowData]);

  useEffect(() => {
    if (isOrderBonusCreated) {
      onClose();
      dispatch(orderDataActions.getOrderBonusList(orderId));
      dispatch(orderDataActions.setOrderBonusCreated(false));
      dispatch(orderDataActions.setOrderBonusShifts([]));
      dispatch(orderDataActions.setUpdateOrderBonusShifts(false));
      dispatch(
        globalActions.setSnackBar({
          message: t('order.orderBonusTab.messages.orderBonusSaveMessage', {
            updateMessage: isNewOrderBonus
              ? t('order.orderBonusTab.messages.created')
              : orderBonusStatusId === 3
              ? t('order.orderBonusTab.messages.expired')
              : orderBonusStatusId === 4
              ? t('order.orderBonusTab.messages.voided')
              : orderBonusStatusId === 1
              ? t('order.orderBonusTab.messages.approved')
              : t('order.orderBonusTab.messages.updated'),
          }),
          severity: 'success',
        }),
      );
      dispatch(
        orderDataActions.setEditOrderBonusModal({
          isEdit: false,
          bonusId: 0,
          rowData: null,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOrderBonusCreated]);

  const handleDiscardYesAction = () => {
    setOpenDiscardDialog(false);
    onClose();
  };

  const handleDiscardNoAction = () => {
    setOpenDiscardDialog(false);
  };

  const handleDiscardClose = reason => {
    if (
      reason !== 'backdropClick' &&
      (isNewOrderBonus || (isEditAllowed && !statusChangeButton)) &&
      _.isEmpty(dirtyFields) &&
      _.isEmpty(touched)
    ) {
      onClose();
      reset({ ...OrderBonusFormDefaultValues });
    } else {
      setOpenDiscardDialog(true);
    }
    dispatch(
      orderDataActions.setEditOrderBonusModal({
        isEdit: false,
        bonusId: null,
        rowData: null,
      }),
    );
    dispatch(orderDataActions.getOrderBonusCalculatedAmount(null));
    dispatch(orderDataActions.setOrderBonusShifts([]));
    dispatch(orderDataActions.setUpdateOrderBonusShifts(false));
  };

  const validationCheck = () => {
    setFinalValidation(true);
    setDisableSaveButton(true);
  };
  const validationAndSubmit = () => {
    paymentTypeCheck || bonusAmountCheck ? validationCheck() : onSubmit();
  };
  const onSubmit = () => {
    const deletedShifts =
      (orderBonusStatusId === 2 || orderBonusStatusId === 1) && updateOrderBonusShifts
        ? orderBonusShifts.filter(x => !nonRequiredWatchValues.shiftsApplicable.includes(x)).map(x => x.ID)
        : [];
    const bodyPayload: IOrderBonusSave = {
      orderId: orderId,
      bonusId: isNewOrderBonus ? 0 : rowData?.bonusId,
      statusId: orderBonusStatusId,
      bonusAmount: Number(requiredWatchValues.bonusAmount),
      bonusStartDate: convertToFormat(requiredWatchValues.startDate, 'yyyy-MM-DD'),
      bonusEndDate: convertToFormat(requiredWatchValues.endDate, 'yyyy-MM-DD'),
      totalCount: 0,
      paymentTypeId: bonusPaymentTypeOptions.find(x => x === requiredWatchValues.paymentType).ID,
      bonusTypeId: bonusTypeOptions.find(x => x === requiredWatchValues.bonusType).ID,
      payerId: bonusPayerOptions.find(x => x === requiredWatchValues.payer).ID,
      billingTypeId: bonusBillingTypeOptions.find(x => x === requiredWatchValues.billingType).ID,
      billingAmount: Number(requiredWatchValues.billingAmount),
      approvedBy: nonRequiredWatchValues.approvedBy,
      approvalTypeID: bonusApprovedMethodOptions.find(x => x === requiredWatchValues.approvalType).ID,
      approvalDate: convertToFormat(requiredWatchValues.approvalDate, 'yyyy-MM-DD'),
      startDate: convertToFormat(requiredWatchValues.startDate, 'yyyy-MM-DD'),
      endDate: convertToFormat(requiredWatchValues.endDate, 'yyyy-MM-DD'),
      bonusShortHoursTypeId: bonusShortHourTypeOptions.find(x => x === requiredWatchValues.shortHourType).ID,
      shortAssignmentBonusId: bonusLessThan13WeeksOptions.find(x => x === requiredWatchValues.lessThan13Weeks).ID,
      notes: nonRequiredWatchValues.notes,
      minimumWeeksWorked: requiredWatchValues.minWeeksWorked,
      bonusBillingAmount: Number(requiredWatchValues.bonusBillAmount),
      bonusTemplateId: 0,
      userId: userInfo?.employeeId,
      timeStamp: isEditAllowed ? rowData?.timestamp : null,
      shiftIds:
        nonRequiredWatchValues.shiftsApplicable.length > 0
          ? shiftOptions.filter(x => nonRequiredWatchValues.shiftsApplicable.includes(x)).map(item => item.ID)
          : [],
      deletedOrderBonusShifts: deletedShifts?.length > 0 ? deletedShifts : [],
    };
    dispatch(orderDataActions.saveOrderBonus(bodyPayload));
  };

  const handleApproveAction = () => {
    setOrderBonusStatusId(orderBonusStatusId !== 1 ? 1 : 2);
    setStatusChangeButton(orderBonusStatusId !== 1 ? true : false);
  };
  const handleVoidAction = () => {
    setOrderBonusStatusId(orderBonusStatusId !== 4 ? 4 : 1);
    setStatusChangeButton(orderBonusStatusId !== 4 ? true : false);
  };
  const handleExpiredAction = () => {
    setOrderBonusStatusId(orderBonusStatusId !== 3 ? 3 : 1);
    setStatusChangeButton(orderBonusStatusId !== 3 ? true : false);
  };

  const isADRoleAuthorized = useMemo(() => {
    return Authorized([userRoles.all], userInfo);
  }, [userInfo]);

  return (
    <div>
      <FormProvider {...formMethods}>
        <GenericDialog
          open={open}
          variant="blue"
          draggable
          classes={{ paper: classes.dialog }}
          disablePortal
          disableEscapeKeyDown
          dialogTitleProps={{
            text: title,
            closeButton: true,
            expandable: true,
            undoButton: disableControls ? false : true,
            onUndoClick: () => {
              if (isEditAllowed && rowData?.descStatus === OrderBonusStatus.pending) {
                loadBonusDetailsByBonusID(rowData).forEach(field =>
                  setValue(field.Name, field.Value, { shouldDirty: false }),
                );
              } else {
                reset({ ...OrderBonusFormDefaultValues });
              }
            },
          }}
          onClose={(e, reason) => {
            handleDiscardClose(reason);
          }}
        >
          <LayoutGrid container style={{ overflow: 'hidden' }}>
            <OrderBonusForm errorMessage={errorMessage} {...props} />

            <LayoutGrid container direction="column" item xs={3} className={classes.rightContainer}>
              <LayoutGrid container style={{ marginTop: 30 }}>
                <LayoutGridItem item xs={12}>
                  <OrderBonusTextField name={OrderBonusFieldNames.statusUpdatedDate} statusUpdateField {...props} />
                </LayoutGridItem>

                <LayoutGridItem item xs={12}>
                  <Typography variant="subtitle2">
                    {t('order.orderBonusTab.rightContainer.approvalStatus')}
                    <Button
                      variant="text"
                      disableRipple
                      className={statusChangeButton ? classes.disableIconStyle : classes.IconStyle}
                      startIcon={
                        isEditAllowed && rowData?.descStatus === OrderBonusStatus.approved ? (
                          <DoneIcon />
                        ) : isEditAllowed && rowData?.descStatus === OrderBonusStatus.void ? (
                          <VoidIcon />
                        ) : isEditAllowed && rowData?.descStatus === OrderBonusStatus.expired ? (
                          <BlockIcon />
                        ) : (
                          <PendingOutlinedIcon />
                        )
                      }
                    >
                      {isEditAllowed ? rowData?.descStatus : OrderBonusStatus.pending}
                    </Button>
                  </Typography>
                </LayoutGridItem>

                {rowData?.descStatus === OrderBonusStatus.approved && isEditAllowed && isADRoleAuthorized && (
                  <>
                    <LayoutGridItem item xs={12}>
                      <Button
                        variant="text"
                        disableRipple={true}
                        startIcon={<VoidIcon />}
                        className={(classes.statusButton, classes.voidButton)}
                        onClick={handleVoidAction}
                      >
                        {OrderBonusStatus.void}
                      </Button>
                    </LayoutGridItem>
                    <LayoutGridItem item xs={12}>
                      <Button
                        variant="text"
                        disableRipple={true}
                        className={(classes.statusButton, classes.expiredButton)}
                        startIcon={<BlockIcon />}
                        onClick={handleExpiredAction}
                      >
                        {OrderBonusStatus.expired}
                      </Button>
                    </LayoutGridItem>
                  </>
                )}

                {approveCheck && isADRoleAuthorized && (
                  <LayoutGridItem item xs={12}>
                    <Button
                      variant="text"
                      disableRipple={true}
                      className={(classes.statusButton, classes.approvedButton)}
                      startIcon={<DoneIcon />}
                      disabled={disableControls}
                      onClick={handleApproveAction}
                    >
                      {statusChangeButton ? OrderBonusStatus.approved : OrderBonusStatus.approve}
                    </Button>
                  </LayoutGridItem>
                )}
              </LayoutGrid>
              <LayoutGrid container style={{ marginBottom: '10%' }}>
                <LayoutGridItem item xs={6} style={{ paddingLeft: '30%' }}>
                  <Button
                    variant="contained"
                    color="tertiary"
                    className={classes.cancelBtn}
                    onClick={() => handleDiscardClose('')}
                  >
                    {t('order.orderBonusTab.rightContainer.cancel')}
                  </Button>
                </LayoutGridItem>
                <LayoutGridItem item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}
                    type="submit"
                    disabled={disableSaveButton}
                    onClick={validationAndSubmit}
                  >
                    {t('order.orderBonusTab.rightContainer.save')}
                  </Button>
                </LayoutGridItem>
              </LayoutGrid>
            </LayoutGrid>
          </LayoutGrid>
        </GenericDialog>
        {openDiscardDialog && (
          <UseTemplate
            openDialog={openDiscardDialog}
            title={t('order.orderBonusTab.confirmationTemplate.discardTitle')}
            content={t('order.orderBonusTab.confirmationTemplate.discardContent')}
            handleYesAction={handleDiscardYesAction}
            handleNoAction={handleDiscardNoAction}
            handleCancelAction={null}
          />
        )}
      </FormProvider>
    </div>
  );
};
