import React from 'react';
import styledComponent from 'styled-components';
import { makeStyles } from 'tss-react/mui';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const useStyles = makeStyles()({
  root: {
    overflow: 'hidden',
    textAlign: 'center',
  },
  nodataMessageStyle: {
    'font-size': 'x-large',
    '& svg': {
      color: '#ff8100',
      'font-size': 'xx-large',
    },
  },
});

interface Props {
  message: string;
}

export const CommonNoDataPage: React.FC<Props> = props => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <Wrapper>
        <Title>
          <p className={classes.nodataMessageStyle}>
            <ErrorOutlineIcon /> {props.message}
          </p>
        </Title>
      </Wrapper>
    </div>
  );
};

const Wrapper = styledComponent.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 320px;
  background: rgb(244, 245, 247);
`;

const Title = styledComponent.div`
  margin-top: -10vh;
  font-weight: bold;
  color: black;
  font-size: 16px;
  span {
    font-size: 16px;
  }
`;
