/* eslint-disable i18next/no-literal-string */
import { Grid, TextField } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { GridControlBuilder } from 'app/components/Common/Grid/GridControlBuilder';
import { ICredentials } from 'app/models/Credentialing/Credentials';
import { CredentialsGridType } from '../CredentialsGrid';
import { AccordionMenu } from 'app/components/Common/DropDown/AccordionMenu';
import { useTranslation } from 'react-i18next';
import { DocumentDropzoneWrapper } from '../Attachments/DocumentDropzone/DocumentDropzoneWrapper';
import { ff_tsceformIDUpdate } from 'app/constants/FeatureFlags/Candidate/Keys';
import { useDecision } from '@optimizely/react-sdk';

interface AddCredentialBuilderProps {
  credential: any;
  typeChanged: Function;
  model: any;
  onChange: Function;
  columns: any;
  rows: any;
  typeDropDownValues: any;
  submitFailedAttachments?: Function;
}

const useStyles = makeStyles()({
  control: {
    width: '100%',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
  },
  credName: {
    width: '100%',
    marginTop: '17px',
  },
  alignTextContainer: {},
  textFiledCustomStyle: {
    borderRadius: '4px',
    fontSize: '14px',
  },
  datePickerLabelStyles: {
    fontSize: '14px',
  },
  menuPaperStyle: {
    maxHeight: '200px !important',
  },
  alignDropdown: {
    width: '100%',
    marginTop: '17px',
  },
  documentDropzoneContainer: {
    marginTop: '25px !important',
  },
});

export const AddCredentialBuilder = (props: AddCredentialBuilderProps) => {
  const { credential, typeChanged, model, onChange, columns, rows, typeDropDownValues } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [tsceformIDUpdate] = useDecision(ff_tsceformIDUpdate);

  const onValueChanged = (rowKey, key: keyof ICredentials, value) => {
    onChange(key, value);
  };

  const _typeChanged = event => {
    if (credential.gridType === CredentialsGridType.miscellaneousPendingMoleculeDevelopment) {
      onValueChanged(null, 'reqTypeID', event.target.value);
    } else if (
      credential.gridType === CredentialsGridType.certificationsOtherCertifications ||
      credential.gridType === CredentialsGridType.educationAndOnlineForms
    ) {
      onValueChanged(null, 'typeID', event.target.value);
    }
    typeChanged(event);
  };

  return (
    <React.Fragment>
      <Grid container spacing={4} data-testid="credentials-Add">
        <Grid item xs={12} className={classes.alignTextContainer}>
          <Grid spacing={2} container>
            <Grid item xs={!!typeDropDownValues ? 6 : 12}>
              <TextField
                variant="filled"
                size="small"
                value={credential?.selectedTitle}
                disabled
                className={classes.credName}
                inputProps={{
                  'data-testid': 'cred-name',
                }}
                label={t('search.grid.add.credName')}
              />
            </Grid>
            {!!typeDropDownValues && (
              <Grid item xs={6} className={classes.alignDropdown}>
                <AccordionMenu
                  filterOptions={typeDropDownValues || []}
                  handleChange={_typeChanged}
                  large={true}
                  value={credential?.type?.value || 'N/A'}
                  color={undefined}
                  menuPaperStyle={classes.menuPaperStyle}
                  inputLabel={t(
                    credential?.requirementTypeID === 222
                      ? 'search.grid.add.searchTitlePMD'
                      : 'search.grid.add.searchTitle',
                  )}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {columns?.map(column => {
          const edit = {
            rowKey: 0,
            key: column.title,
            isEditing: true,
            onValueChanged: onValueChanged,
          };
          return (
            <>
              {tsceformIDUpdate.enabled && column.title === 'tsceFormID' ? (
                <></>
              ) : (
                <Grid item xs={3}>
                  <Grid container direction="column">
                    <Grid item className={classes.control}>
                      <GridControlBuilder
                        control={column.control}
                        value={model[column.title]}
                        edit={edit}
                        filterOptionsOveride={rows[`${column.title}FilterOptions`]}
                        fullWidth
                        label={column.value}
                        textFiledCustomStyle={classes.textFiledCustomStyle}
                        datePickerLabelStyles={classes.datePickerLabelStyles}
                        menuPaperStyle={classes.menuPaperStyle}
                        large={true}
                        filled={true}
                        showDatePickerIcon={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          );
        })}
      </Grid>
      <Grid className={classes.documentDropzoneContainer}>
        <DocumentDropzoneWrapper
          compressed={false}
          existingAttachments={''}
          data={''}
          isAdd={true}
          submitFailedAttachment={props.submitFailedAttachments}
          dissableUpload={false}
        />
      </Grid>
    </React.Fragment>
  );
};
