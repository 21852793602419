import { navigationProfilesAction } from '@AMIEWEB/GlobalSearch/store/NavigationProfiles.redux';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { Box, Typography, styled } from 'amn-ui-core';
import { missingField } from 'app/constants';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import styledComponent from 'styled-components';
import { colors } from 'styles/styleVariables';
import { XGridCellPadding } from './XGridDefaults';

export const XGridLinkCell =
  (
    path: string,
    fieldList: string[] = [],
    isHoverView?: boolean,
    pathCheck?: {
      checkIfCurrentPath: boolean;
      handleFalseClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, params: GridCellParams) => void;
    },
    forceFalseLink?: boolean,
  ) =>
  (params: GridCellParams) => {
    return (
      <LinkContent
        path={path}
        fieldList={fieldList}
        isHoverView={isHoverView}
        params={params}
        pathCheck={pathCheck}
        forceFalseLink={forceFalseLink}
      />
    );
  };

const LinkContent = ({
  path,
  fieldList,
  isHoverView,
  pathCheck,
  params,
  forceFalseLink,
}: {
  path: string;
  fieldList: string[];
  isHoverView?: boolean;
  pathCheck?: {
    checkIfCurrentPath: boolean;
    handleFalseClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, params: GridCellParams) => void;
  };
  forceFalseLink?: boolean;
  params: GridCellParams;
}) => {
  const dispatch = useDispatch();
  const [link, setLink] = React.useState<string>('');
  const {
    location: { pathname: currentPath },
  } = document;

  React.useEffect(() => {
    let linkBuild = path;
    fieldList?.forEach((id, index) => {
      linkBuild = linkBuild.replace(`{${index}}`, `${params.row[id]}`);
    });
    setLink(linkBuild);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, fieldList]);

  const handleLinkClick = () => {
    dispatch(navigationProfilesAction.setNavigationFlag({ navigationFromGrid: true }));
    dispatch(navigationProfilesAction.setCurrentRowId({ rowId: params?.row?.id }));
  };

  return (
    <div>
      {params?.value !== missingField ? (
        (pathCheck?.checkIfCurrentPath && link === currentPath) || forceFalseLink ? (
          <FalseLink
            style={{ padding: isHoverView ? `4px ${XGridCellPadding}px` : '0px' }}
            onClick={e => {
              pathCheck?.handleFalseClick(e, params);
            }}
          >
            {params?.value}
          </FalseLink>
        ) : (
          <LinkWrapper style={{ padding: isHoverView ? `5px ${XGridCellPadding}px` : '0px' }}>
            <Link to={link} onClick={handleLinkClick}>
              {params?.value}
            </Link>
          </LinkWrapper>
        )
      ) : (
        <MissingFieldLink>{params?.value}</MissingFieldLink>
      )}
    </div>
  );
};

const Link = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.typography.body1.fontSize,
}));

const LinkWrapper = styledComponent.div`
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    overflow: unset;
    text-overflow: unset;
  }
`;

const MissingFieldLink = styledComponent(Typography)`
  color: ${colors.amnDarkGrey};
  cursor: pointer;
  font-size: 14px;
`;

const FalseLink = styledComponent(Box)`
  color: ${colors.amnBlue};
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    overflow: unset;
    text-overflow: unset;
  }
`;
