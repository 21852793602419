import { validEmail } from 'utils/string/string';
import { IUserDetails } from '../../store/userManagement.redux';
import { IUserDetailFields, IUserDetailsUpdatePayload } from '../../Helper';
import moment from 'moment';

export type StatusOptions = {
  id: number;
  name: string;
  value: string;
};

//This Helps on Setting Default Values
export const setValues = (setValue, userData: IUserDetails) => {
  setUserDetailsInfo(setValue, userData);
};

export const statusOpts: Array<StatusOptions> = [
  { id: 1, name: 'Active', value: 'Active' },
  { id: 2, name: 'Inactive', value: 'Inactive' },
];

//Set Default Values For Personal Info
const setUserDetailsInfo = (setValue, formData) => {
  setValue('firstName', formData?.firstName);
  setValue('lastName', formData?.lastName);
  setValue('userName', formData?.userName);
  setValue('faxNumber', formData?.faxNumber);
  setValue('smsNumber', formData?.smsNumber);
  setValue('phoneNumber', formData?.phoneNumber);
  setValue('companyId', formData?.companyId);
  setValue('statusId', formData?.statusId);
  setValue('employeeId', formData?.employeeId);
  setValue('emailId', formData?.email);
  setValue('workdayId', formData?.workDayEmployeeId);
  setValue('deptHireDate', formData?.departmentHireDate);
  setValue('excludeFromTasks', formData?.excludeFromTask);
  setValue('crossCompanyUser', formData?.crossCompanyUser);
};

//Extract data from candidate response
const extractData = (data: IUserDetailFields) => {
  const userInformation: IUserDetailFields = {
    firstName: data?.firstName,
    lastName: data?.lastName,
    userName: data?.userName,
    faxNumber: data?.faxNumber,
    smsNumber: data?.smsNumber,
    employeeId: data?.employeeId,
    emailId: data?.emailId,
    workDayEmployeeId: data?.workDayEmployeeId ?? null,
    departmentHireDate: data?.departmentHireDate,
    excludeFromTask: data?.excludeFromTask,
    crossCompanyUser: data?.crossCompanyUser,
    phoneNumber: data?.phoneNumber,
    companyId: data?.companyId,
    statusId: data?.statusId,
  };
  return userInformation;
};

export const processDataUpdate = (data: IUserDetailFields, formData) => {
  const formatData = extractData(data) as IUserDetailFields;
  const updateUserDetail: IUserDetailsUpdatePayload = {
    firstName: formData.firstName ?? formatData.firstName,
    lastName: formData.lastName ?? formatData.lastName,
    userName: formData.userName ?? formatData.userName,
    faxNumber: formData.faxNumber ?? formatData.faxNumber,
    smsNumber: formData.smsNumber ?? formatData.smsNumber,
    employeeId: formData.employeeId ?? formatData.employeeId,
    email: formData.emailId ?? formatData.emailId,
    workDayEmployeeId: formData.workDayEmployeeId ? parseInt(formData.workDayEmployeeId) :
      (formatData.workDayEmployeeId ? parseInt(formatData.workDayEmployeeId) : null),
    departmentHireDate: formData.departmentHireDate ? moment(formData.departmentHireDate).format('YYYY-MM-DD') : null,
    excludeFromTask: formData.excludeFromTask ?? formatData.excludeFromTask,
    crossCompanyUser: formData.crossCompanyUser ?? formatData.crossCompanyUser,
    phoneNumber: formData.phoneNumber ?? formatData.phoneNumber,
    companyId: formData.companyId ?? formatData.companyId,
    statusId: formData.statusId ?? formatData.statusId
  }
  return updateUserDetail;
};

//Email Validations
export const errorEmailMessage = (e, name, setError) => {
  if (e?.target?.value !== '') {
    if (!validEmail(e?.target?.value)) {
      setErrorMessage(name, setError, 'Invalid format');
    }
  }
};

export const emailCheck = email => {
  return email === '' || validEmail(email);
};

const setErrorMessage = (name, setError, message) => {
  setError(name, {
    type: 'required',
    message: message,
  });
};

export const IsDateValid = (setError, Id, value) => {
  if (!value) {
    setErrorMessage(Id, setError, 'Required');
    return false;
  }
  return true;
};

export const dates = {
  convert: function (d) {
    return d ? new Date(d) : NaN;
  },
  gt: function (a: any, b: any) {
    return this.convert(a) > this.convert(b);
  },
  lt: function (a: any, b: any) {
    return this.convert(a) < this.convert(b);
  },
  eq: function (a: string, b: string) {
    return a === b;
  },
};
