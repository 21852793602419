import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Icon, Modal, Paper, Toolbar, Tooltip, Typography, materialUiXGrid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { formatTime } from 'app/components/Candidate/helper/StringFormatter';
import { selectGridState } from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import { ConversationCount } from 'app/components/Notification/Common/ConversationCount';
import { ChannelType, SendType, UseType } from 'app/components/Notification/Constants';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { ModalAppBar, ModalClose, ModalTitle } from 'app/components/Notification/styles';
import { convertToPST } from 'app/helpers/dateHelper';
import { formatPhoneNumber } from 'app/helpers/phoneHelpers';
import { CommunicationLogPayload } from 'app/models/Candidate/CandidateProfile';
import { GetUserLogs } from 'app/services/NotificationServices/NotificationService';
import { selectUser } from 'oidc/user.selectors';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Concatenate } from 'utils/string/string';
import NotesIcon from 'app/assets/images/Notification/notes.svg';
import { LogsFilterWrapper } from '../../CommunicationLogs/Filter/LogsFilterWrapper';
import { candidateDetailActions, loadGridUserLogs, loadKpis } from '../../Profile/CandidateDetails.redux';
import { candidateDetailsSelection, selectAppliedFilter } from '../../Profile/CandidateDetails.selector';
import { NotesModal } from './NotesModal';
import Kpis from './Kpis';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useEnableSmsCommunication } from 'oidc/CommunicationEnabler';
import { XGrid } from '@AMIEWEB/Common/XGrid/XGrid';
import _ from 'lodash';
import { GridSortModel } from '@mui/x-data-grid-pro';
import {
  CommunicationHistoryVisibiltyModel as CommunicationHistoryVisibiltyModel,
  CommunicationHistoryViewInfo,
} from './CommunicationHistoryViewInfo';
import { CommunictionHistoryFlattenData } from './CommunictionHistoryFlattenData';
import { SmsType } from 'app/enums/Sms';
import moment from 'moment';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import { RootState } from 'types';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { formatMultiSortColumns, formatMultiSortXGridColumns } from '@AMIEWEB/GlobalSearch/helper';
import { checkUserRolesForAccess } from './helper';
import {
  communicationNotesOrSubject,
  defaultNotes,
  INotes,
} from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/CommunicationHistory/Helpers/helper';
import { EmailModal } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/CommunicationHistory/Grid/EmailModal';

const identifyCommunicationIcon = (
  communicationLog: any,
  fontSize: string = '1.5rem',
  marginBottom: string = '7px',
  actAsBoolean: boolean = false,
) => {
  switch (communicationLog?.channel) {
    case 'Conversation':
      return actAsBoolean ? true : <ChatOutlinedIcon style={{ fontSize: fontSize, marginBottom: marginBottom }} />;
    case 'Voice':
      return actAsBoolean ? true : <CallOutlinedIcon style={{ fontSize: fontSize, marginBottom: marginBottom }} />;
    case 'Email':
      return actAsBoolean ? true : <EmailOutlinedIcon style={{ fontSize: fontSize, marginBottom: marginBottom }} />;

    default:
      return actAsBoolean ? false : null;
  }
};

const useStyles = makeStyles()(theme => ({
  channelIcon: {
    color: '#006FB9',
    paddingRight: '10px',
    fontSize: '1.25rem !important',
    overflow: 'visible',
  },
  communicationType: {
    display: 'flex',
  },
  communicationTitle: {
    paddingTop: '3px',
    paddingLeft: '6px',
  },
  conversationType: {
    color: '#006FB9',
    textDecoration: 'underline',
    cursor: 'pointer',
  },

  key: {
    color: '#B9BBBC',
    fontSize: '1rem',
    paddingBottom: '2%',
  },
  value: {
    color: '#555555',
    paddingRight: '2%',
    fontSize: '1rem',
    paddingBottom: '2%',
  },
  title: {
    color: '#333333',
    fontSize: '16px',
    fontWeight: 600,
    paddingLeft: '23px',
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  toolbar: {
    paddingBottom: '2%',
  },
  notes: { display: 'flex', cursor: 'pointer' },
  notesEditIcon: {
    cursor: 'pointer',
    fontSize: '20px',
  },
  addNote: {
    fontWeight: 500,
    marginTop: 2,
    cursor: 'pointer',
  },
  conversationNotes: {
    cursor: 'pointer',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: '750px',
    outline: 'none',
    borderRadius: '5px',
    boxShadow: '0px 0px 2px 6px #8888',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  grid: {
    padding: '0 20px 20px 20px',
  },
  divider: {
    marginTop: '1%',
    width: '94%',
  },
  subject: {
    fontSize: '18px',
    paddingBottom: '5px',
  },
  iframe: {
    maxHeight: '650px',
    minHeight: '260px',
    fontFamily: 'Roboto',
    width: '100%',
    fontSize: '14px',
    color: '#555555',
  },

  scroll: {
    color: '#555555',
    paddingRight: '2%',
    fontSize: '1rem',
    paddingBottom: '2%',
    overflowY: 'auto',
    overflowX: 'auto',
    width: '95%',
    height: '35vh',
    margin: '2%',
  },
  noUnderline: {
    color: '#555',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  tooltipArrow: {
    color: '#fff',
    '&::before': {
      border: '1px solid #555555',
    },
  },
  tooltipBackground: {
    backgroundColor: '#fff',
    color: '#555555',
    border: '1px solid #555555',
    fontSize: '12px',
  },
}));

export const CommunicationHistoryTab = () => {
  const missingField = '--';
  const notificationData = useSelector(notificationSelection);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const user = useSelector(selectUser);
  const isFilterApplied = useSelector(selectAppliedFilter);
  const [rows, setRows] = useState([]);
  const gridState = useSelector(selectGridState);
  const dispatch = useDispatch();
  const params = useParams<any>();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [notesModal, setNotesModal] = useState<INotes>(defaultNotes);
  const [data, setData] = useState({});
  const loading = useSelector(candidateDetailsSelection).isGridLoading;
  const totalRecords = useSelector(candidateDetailsSelection).gridLogsData?.totalRecords;
  const enableSmsCommunication = useEnableSmsCommunication() && !!user?.userInfo?.phoneNumber;
  const gridTag = 'CommunicationHistory';
  const [columns, setColumns] = React.useState<any[]>([...CommunicationHistoryViewInfo(t)]);
  const [defaultColumns, setDefaultColumns] = React.useState<any[]>([...CommunicationHistoryViewInfo(t)]);
  const [currentColumnVisibilityModel, setCurrentColumnVisibilityModel] = React.useState<any>(
    _.cloneDeep(CommunicationHistoryVisibiltyModel),
  );
  const [currentSortModel, setCurrentSortModel] = React.useState<any[]>([]);
  const userPreference = useSelector(selectUserPreference);
  const gridPreferences = useSelector((state: RootState) => {
    return state.userPreferenceData.userGridPreferences.find(item => item.id === gridTag);
  });
  const apiRef = materialUiXGrid.useGridApiRef();
  const isPageSizeChangeRef = useRef(false);

  const getSortModelPreferences = (sortModel: any[]) => {
    const columnPreferences = gridPreferences?.value;
    if (
      columnPreferences &&
      columnPreferences?.multiSortedColumns &&
      Array.isArray(columnPreferences?.multiSortedColumns)
    ) {
      return formatMultiSortXGridColumns(columnPreferences?.multiSortedColumns);
    }
    return sortModel;
  };

  const determineSortModel = () => {
    if (gridPreferences?.value?.multiSortedColumns.length > 0) {
      setCurrentSortModel(getSortModelPreferences(gridPreferences?.value?.multiSortedColumns));
    }
  };

  useEffect(
    () => {
      if (userPreference?.loaded) {
        determineSortModel();
        dispatch(notificationDataActions.resetFilters('isCommunicationHistoryGrid'));
        dispatch(notificationDataActions.resetChips());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userPreference?.loaded],
  );

  //creating string for delivery status
  const getDelieveryStatusMessage = obj => {
    if (obj?.deliveryStatus) {
      return obj.deliveryStatus[0].toUpperCase() + obj.deliveryStatus.slice(1);
    } else {
      return missingField;
    }
  };

  const communicationLogPayload: CommunicationLogPayload = {
    brandId: params.brandId?.toString(),
    contactId: params.travelerId?.toString(),
    channel: ['Conversation', 'Email', 'Voice'],
  };

  const getContactInfo = log => {
    const channel = log.channel;
    switch (channel) {
      case 'Email': {
        return log.message?.tos?.find(rec => rec?.contactId == params?.travelerId && rec?.brandId == params?.brandId)
          ?.email;
      }
      case 'Voice': {
        return formatPhoneNumber(
          log.message?.tos.find(Boolean).phoneNumber?.length > 10 &&
            log.message?.tos.find(Boolean).phoneNumber?.substring(0, 2) === '71'
            ? log.message?.tos
                .find(Boolean)
                .phoneNumber?.substring(2, log.message?.tos.find(Boolean).phoneNumber?.length)
            : log.message?.tos.find(Boolean).phoneNumber,
        );
      }
      case 'Conversation': {
        return formatPhoneNumber(
          log.message?.tos.find(Boolean).phoneNumber?.length > 10 &&
            log.message?.tos.find(Boolean).phoneNumber?.substring(0, 2) === '+1'
            ? log.message?.tos.find(Boolean).phoneNumber?.substring(2, log.message?.tos.find(Boolean).phoneNumber)
            : log.message?.tos.find(Boolean).phoneNumber?.length,
        );
      }
    }
  };

  const getSmsConversationType = log => {
    const hasUserAccess = checkUserRolesForAccess(log, user);
    if (enableSmsCommunication && hasUserAccess) {
      return (
        <span
          className={classes.conversationType}
          role="button"
          tabIndex={0}
          onClick={() => handleNotificationData(log)}
          onKeyPress={() => handleNotificationData(log)}
        >{`SMS`}</span>
      );
    } else
      return (
        <Tooltip
          classes={{
            tooltip: classes.tooltipBackground,
            arrow: classes.tooltipArrow,
          }}
          arrow
          title={`Access to this conversation is not available`}
        >
          <span className={classes.noUnderline}>{t('SMS')}</span>
        </Tooltip>
      );
  };

  const getCommunicationType = log => {
    return (
      <div className={classes.communicationType}>
        <Box>
          <Icon className={classes.channelIcon}>{identifyCommunicationIcon(log, '1.15rem', '7px')}</Icon>
        </Box>
        <Box className={classes.communicationTitle}>
          {log.channel === 'Voice'
            ? 'Call'
            : log.channel === 'Conversation'
            ? getSmsConversationType(log)
            : log.channel}
        </Box>
      </div>
    );
  };

  const checkWithInRange = (d1: string, d2: string) => {
    if (moment(d1) > moment(d2).startOf('day') && moment(d1) < moment(d2).endOf('day')) {
      return true;
    } else {
      return false;
    }
  };

  const handleNotificationData = async log => {
    const dailyConversationLogsPayload: CommunicationLogPayload = {
      contactId: params.travelerId?.toString(),
      channel: ['SMS'],
      pageSize: 20,
      pageNumber: 1,
      initiatedBy: (log.message && log.message.sender && log.message.sender.phoneNumber) ?? '',
      startDate: moment(new Date(log?.publishedOn)).startOf('day').toDate(),
      endDate: moment(new Date(log?.publishedOn)).endOf('day').toDate(),
    };

    const dailyConversation = await GetUserLogs(dailyConversationLogsPayload);
    dailyConversation.results = dailyConversation?.results?.filter(
      item =>
        checkWithInRange(item.publishedOn, log.publishedOn) &&
        item.message?.sender?.userId == log.message?.sender?.userId,
    );
    const sms = notificationData.sms;
    if ((sms.open || sms.minimized) && (data['body']?.length > 0 || sms?.newNotes || sms.attachments !== undefined)) {
      dispatch(
        notificationDataActions.setSmsInteraction({
          open: true,
          minimized: false,
          readonly: false,
        }),
      );
      if (notificationData.sms.data?.tos.find(Boolean)?.contactId !== log.message?.tos.find(Boolean).contactId) {
        dispatch(notificationDataActions.setSnackBarData({ channel: 'sms', manual: false, changeWarning: true }));
      }
    } else {
      const candidateName = log.message.tos.find(Boolean).name.split(' ');
      dispatch(
        notificationDataActions.setNotificationData({
          ...notificationData,

          sms: {
            ...notificationData.sms,
            chatHistory: {
              chats: dailyConversation,
              candidateDetails: {
                name: {
                  first: candidateName[0],
                  last: candidateName?.length > 1 ? candidateName[candidateName?.length - 1] : '',
                },
                travelerId: log.message.tos.find(Boolean).contactId,
              },
            },
            data: {
              publishedOn: log.publishedOn,
              ...log.message,
              channel: ChannelType.sms,
              brandId: candidateDetails.brandId?.toString(),
              associatedRecords: log.associatedRecords,
              sendType: SendType.one_to_one,
              useType: UseType.Candidate,
              userRole: Concatenate(user?.userInfo?.roles || [], ','),
            },
            open: true,
            minimized: false,
            readonly: log.message.sender.userId === user?.userInfo?.employeeId?.toString() ? false : true,
            sendType: SmsType.one_to_one,
          },
          snackbarData: null,
        }),
      );
    }
  };

  const formatData = logs => {
    const logData = logs.map((log, index) => {
      const coveringEmployeeNameObject = log?.associatedRecords?.find(record => record.name === 'coveringEmployeeName');
      const sharedDeskPublisherNameObject = log?.associatedRecords?.find(
        record => record.name === 'sharedDeskPublisherName',
      );
      return {
        rowID: log?.id,
        communicationType: getCommunicationType(log) || missingField,
        contactInfo: getContactInfo(log) || missingField,
        date: `${convertToPST(log?.publishedOn, 'MM/DD/YYYY')} | ${formatTime(
          convertToPST(log?.publishedOn, 'HH:mm'),
        )}`,
        from: coveringEmployeeNameObject?.value?.trim()
          ? coveringEmployeeNameObject?.value || missingField
          : sharedDeskPublisherNameObject?.value?.trim()
          ? sharedDeskPublisherNameObject?.value || missingField
          : log?.message?.sender.name || missingField,
        userRole:
          log.userRoles && log.userRoles?.length > 3 ? (
            <Tooltip
              classes={{
                tooltip: classes.tooltipBackground,
                arrow: classes.tooltipArrow,
              }}
              arrow
              title={Concatenate(log.userRoles, ', ')}
            >
              <span>{`${Concatenate(log.userRoles.slice(0, 2), ', ')}...`}</span>
            </Tooltip>
          ) : (
            Concatenate(log.userRoles, ', ')
          ),
        status:
          log.channel === 'Email'
            ? log?.message.tos && getDelieveryStatusMessage(log?.message.tos[0])
            : log?.response?.disposition?.replace(/([A-Z][a-z])/g, ' $1').replace(/(\d)/g, ' $1') || missingField,
        type:
          log.channel === 'Conversation' ? (
            <ConversationCount
              channel={log.channel}
              outboundCount={log?.message.outboundCount}
              inboundCount={log?.message.inboundCount}
              undeliveredCount={log?.message.undeliveredMessagesCount}
            />
          ) : log?.message.direction === null ? (
            missingField
          ) : (
            <ConversationCount channel={log?.channel} direction={log?.message?.direction} />
          ),
        notes: communicationNotesOrSubject(log, index, user, classes, setNotesModal, handleClick, t),
        placementId:
          log.associatedRecords?.find(element => (element?.name).toLowerCase() === 'placementid')?.value ||
          missingField,
        recruiter:
          log.associatedRecords?.find(element => (element?.name).toLowerCase() === 'recruiter')?.value || missingField,
      };
    });
    return logData;
  };

  useEffect(() => {
    if (candidateDetails?.gridLogsData?.results) {
      const formattedData = formatData(candidateDetails?.gridLogsData?.results);
      const newFormatedData = CommunictionHistoryFlattenData(formattedData);
      setRows(newFormatedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateDetails.gridLogsData]);

  const handleClick = (row: number, channel: string) => {
    setModalOpen(true);
    const data = candidateDetails?.gridLogsData?.results[row];
    const modalData = {};

    if (channel === 'Email') {
      modalData['title'] = 'Email Notes';
      modalData['tos'] = data.message.tos;
      modalData['ccs'] = data.message.ccs;
      modalData['bccs'] = data.message.bccs;
      modalData['subject'] = data.message.subject;
      modalData['sender'] = data.message.sender.email;
    } else {
      modalData['title'] = 'Notes';
    }
    if (channel === 'Voice') {
      modalData['notes'] = data?.response?.notes;
    } else {
      modalData['notes'] = data?.message?.body;
    }
    setData(modalData);
  };

  useEffect(() => {
    dispatch(loadKpis(communicationLogPayload));
    dispatch(loadGridUserLogs({ ...communicationLogPayload, pageSize: 50, getChannel: true }));
    dispatch(
      candidateDetailActions.getAvailableChannelFilters({
        contactId: params.travelerId?.toString(),
        brandId: params.brandId?.toString(),
      }),
    );
  }, [params.travelerId, params.brandId]);

  const handlePagination = (offset: number, page: number) => {
    dispatch(
      loadGridUserLogs({
        ...notificationData.gridLogFilters,
        pageNumber: page,
        pageSize: offset,
        brandId: params.brandId?.toString(),
        contactId: params.travelerId?.toString(),
        getChannel: true,
      }),
    );
  };

  const onPageChange = (page: number) => {
    if (isPageSizeChangeRef.current) {
      isPageSizeChangeRef.current = false;
      return;
    }
    const pagination = apiRef?.current?.state?.pagination;
    if (pagination && !isPageSizeChangeRef.current) {
      handlePagination(pagination?.pageSize, page + 1);
    }
  };

  const onPageSizeChange = (pageSize: number) => {
    isPageSizeChangeRef.current = true;
    handlePagination(gridState.pageSize, gridState.pageNumber);
  };

  const handleRefreshGrid = () => {
    if (isFilterApplied) {
      handlePagination(gridState.pageSize, gridState.pageNumber);
    } else {
      dispatch(loadGridUserLogs({ ...communicationLogPayload, pageSize: 50, getChannel: true }));
    }
  };

  const saveSortModel = React.useCallback(
    sortModel => {
      const multiSortedColumns = sortModel?.length > 0 ? formatMultiSortColumns(sortModel) : [];
      if (!_.isEqual(multiSortedColumns, gridState?.multiSortedColumns)) {
        const preferenceData = {
          id: gridTag,
          value: {
            ...(gridPreferences?.value || {}),
            multiSortedColumns: multiSortedColumns,
          },
        };
        dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridPreferences?.value, gridState?.multiSortedColumns],
  );

  const onSortModelChanged = (model: GridSortModel) => {
    saveSortModel(model);
    setCurrentSortModel(model);
  };

  return (
    <Box>
      <Box marginBottom={'5px'}>
        <LogsFilterWrapper />
      </Box>
      <Box>
        <>
          <Kpis />
          <Paper>
            <div className={classes.title}>{t('Communication History')}</div>
            <Box className={classes.grid}>
              <XGrid
                apiRef={apiRef}
                columns={columns}
                rowsPerPageOptions={[50, 100, 250, 500]}
                defaultRowsPerPage={50}
                checkboxSelection
                disableColumnMenu
                defaultColumns={defaultColumns}
                rows={rows || []}
                height={360}
                rowCount={totalRecords}
                loading={loading}
                sortModel={currentSortModel}
                disableMultipleColumnsSorting={false}
                defaultColumnVisibilityModel={CommunicationHistoryVisibiltyModel}
                columnVisibilityModel={currentColumnVisibilityModel}
                gridTag={'CommunicationHistory'}
                paginationMode="server"
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                onSortModelChange={onSortModelChanged}
                defaultSortModel={[]}
                onColumnVisibilityModelChange={(model, details) => {
                  setCurrentColumnVisibilityModel(_.cloneDeep(model));
                }}
                toolbarProps={{
                  refreshButton: true,
                  columnSelector: false,
                  refreshGrid: () => {
                    handleRefreshGrid();
                  },
                }}
              />
            </Box>
          </Paper>
        </>

        {isModalOpen && <EmailModal data={data} onClose={() => setModalOpen(false)} />}
        {notesModal?.open && (
          <NotesModal
            rowId={notesModal.rowId}
            log={notesModal.data}
            onClose={() => setNotesModal(prevState => ({ ...prevState, open: false }))}
            isPreview={notesModal.isPreview}
            open={notesModal.open}
          />
        )}
      </Box>
    </Box>
  );
};
