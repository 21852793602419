import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  Autocomplete,
  Box,
  Checkbox,
  ClickAwayListener,
  Divider,
  InputAdornment,
  Paper,
  Popper,
  StyledEngineProvider,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  Theme
} from 'amn-ui-core';
import { NumberField } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/PreferencesTab/CustomComponents/Currency';
import { Status } from 'app/enums/Facility';
import { validDecimal } from 'app/helpers/numberHelper';
import _ from 'lodash';
import React, { createContext, useContext, useState } from 'react';
import styledComponent from 'styled-components';
import { colors } from 'styles/styleVariables';
import { makeStyles } from 'tss-react/mui';
import { ContractType, RateElemenetSource, getDisplayStatusName, isOptionValueRange } from '../helper';
import { theme } from 'styles/global-styles';

const useStyles = makeStyles()((theme:Theme) => ({
  popupOpen: {
    color: colors.amnLightBlue,
  },
  /**TODO: replace with styled.comp. StartLabel EndLabel */
  rangeStartLabel: {
    marginTop: '10px !important',
    marginRight: '4px',
  },
  rangeEndLabel: {
    margin: '11px 0 0 4px',
  },
  groupHeader: {
    paddingLeft: 24,
    fontWeight: 500,
  },
  groupDividerStyle: {
    padding: '6px 0px',
  },
  input: {
    '&:before': {
      borderBottomColor: '#FFA22E !important',
    },
  },
  approved: {
    '&:before': {
      borderBottomColor:  `${theme.palette.components.chips.statusChips.approved.color} !important`,
    },
  },
  pending: {
    '&:before': {
      borderBottomColor: '#FFA22E !important',
    },
  },
  unsaved: {
    '&:before': {
      borderBottomColor: '#FFA22E !important',
    },
  },
  rejected: {
    '&:before': {
      borderBottomColor: '#CC0000 !important',
    },
  },
}));

export interface IRadioRangeOption {
  value: string;
  /** */
  object: any;
}

interface IRange {
  min: IRadioRangeOption;
  max: IRadioRangeOption;
}

export enum numberType {
  currency = 0,
  number,
  singleRate,
}

const customTheme = createTheme(
  theme,
  {
    components: {
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            fontSize: 14,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            '&$focused': { color: colors.amnLightBlue },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          colorPrimary: {
            '&$checked': { color: colors.amnLightBlue },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          underline: {
            '&:hover:not($disabled):not($error):before': {
              borderBottom: `1px solid ${colors.amnLightBlue}`,
            },
            '&:after': {
              borderBottom: `2px solid ${colors.amnLightBlue}`,
            },
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            color: 'rgba(0, 0, 0, 0.54)',
          },
        },
      },
    },
  },
);

export function isInstanceOfIRange(obj: any): obj is IRange {
  return obj && typeof obj === 'object' && 'min' in obj && 'max' in obj;
}
export const convertToDecimal = (value, decimalPoint = 2) => {
  let currency = value;
  if (currency && decimalPoint === 3) {
    if (currency.includes('.')) {
      const index = currency.indexOf('.');
      if (index === 0) currency = '0' + currency;
      if (value.length - index - 1 === 1 || value.length === index + 1) currency = currency + '00';
      if (value.length - index - 1 === 2) currency = currency + '0';
    } else currency = currency + '.000';

    return currency;
  }
  if (currency) {
    if (currency.includes('.')) {
      const index = currency.indexOf('.');
      if (index === 0) currency = '0' + currency;
      if (value.length === index + 1) currency = currency + '00';
      if (value.length - index - 1 === 1) currency = currency + '0';
    } else currency = currency + '.00';
  }
  return currency;
};

const DropDownContext = createContext<{
  applyAll: boolean;
  onApplyAll: Function;
  dropdownLabel?: string;
  labelPrefix?: string;
  labelPostfix?: string;
  _setOptions: Function;
  _options: any[];
  onChange: Function;
  onBlur?: Function;
  type: numberType;
  name?: string;
}>({
  applyAll: false,
  onApplyAll: () => {},
  _setOptions: () => {},
  _options: [],
  onChange: () => {},
  type: numberType.number,
});

const CustomRangeDropdown = ({
  value,
  options,
  onChange,
  applyAll = false,
  onApplyAll = () => {},
  labelPrefix,
  labelPostfix,
  type,
  errorMessage = null,
  isChanged,
  onBlur,
  ...props
}) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const [dropdownLabel] = useState(`range-dropdown-${props.name}`);
  const [_options, _setOptions] = useState<any[]>([]);
  const [error, setError] = useState(null);
  React.useEffect(() => {
    const opts = _.cloneDeep(options);
    const isRangeAvailable = opts.find(item => isInstanceOfIRange(item));
    if (!isRangeAvailable) {
      opts.push({ min: { value: '' }, max: { value: '' } });
    }
    (opts as any[])?.forEach(item => {
      if (!isOptionValueRange(item.value) && !isInstanceOfIRange(item)) {
        item.value = `${item.value}`;
      } else if (isOptionValueRange(item.value)) {
        item.value.minRange = `${item.value.minRange}`;
        item.value.maxRange = `${item.value.maxRange}`;
      } else if (item.min || item.max) {
        item.min.value = `${item.min.value}`;
        item.max.value = `${item.max.value}`;
      }
    });
    _setOptions(opts);
  }, [options]);

  const handleClickAway = event =>
    setOpen(
      event.target?.getAttribute?.('id') === `number-field-${props.name}-0` ||
        event.target?.getAttribute?.('id') === `number-field-${props.name}-1`,
    );

  const handleChange = (event, choice: (IRadioRangeOption | IRange)[]) => {
    const newValue = !!choice.length ? choice.pop() : null;
    if ((isInstanceOfIRange(newValue) && newValue?.min.value !== '') || !isInstanceOfIRange(newValue)) {
      onChange(newValue);
      onBlur(newValue);
    }
  };

  const onInputChange = event => {
    _setOptions(pre => {
      const found = _options.findIndex(item => item.value === event.target.value);
      if (found === -1) {
        const parts = event.target.value.split('-');
        if (type === numberType.currency) {
          pre[pre.length - 1] = {
            min: {
              value: parts.length > 0 ? convertToDecimal(parts[0]).trim() : pre[pre.length - 1].min.value,
            },
            max: {
              value: parts.length > 1 ? convertToDecimal(parts[1]).trim() : '',
            },
          };
        } else if (type === numberType.singleRate) {
          pre[pre.length - 1] = {
            min: {
              value: parts.length > 0 ? convertToDecimal(parts[0], 3).trim() : pre[pre.length - 1].min.value,
            },
            max: {
              value: parts.length > 1 ? convertToDecimal(parts[1], 3).trim() : '',
            },
          };
        } else {
          pre[pre.length - 1] = {
            min: {
              value: parts.length > 0 ? parts[0].trim() : pre[pre.length - 1].min.value,
            },
            max: {
              value: parts.length > 1 ? parts[1].trim() : '',
            },
          };
        }
      }
      return pre;
    });
  };

  // const valueCalculator = () => {
  //   const val = _options.findIndex(item => item.value === value);
  // };

  React.useEffect(() => {
    // valueCalculator();
    if (isInstanceOfIRange(value)) {
      parseFloat(value?.min?.value) > parseFloat(value?.max?.value) ? setError(errorMessage) : setError(null);
    } else {
      setError(null);
    }
  }, [value]);

  const onKeyDown = e => {
    // Restrict currency to ###.## format
    if (!!value && e.keyCode !== 8) {
      // Limits number of items in textbox to one
      e.preventDefault();
      e.stopPropagation();
    } else {
      const newVal = textFieldRef.current.value + e.key.replace(/[^0-9.-]/g, '');
      const valid =
        (type === numberType.currency &&
          /^([0-9.-]|Control|Meta|Shift|ArrowLeft|ArrowRight|Backspace|Delete|Enter)$/.test(e.key)) ||
        (type === numberType.number &&
          /^([0-9]|Control|Meta|Shift|ArrowLeft|ArrowRight|Backspace|Delete|Enter)$/.test(e.key)) ||
        (type === numberType.singleRate &&
          /^([0-9.-]|Control|Meta|Shift|ArrowLeft|ArrowRight|Backspace|Delete|Enter)$/.test(e.key)) ||
        ((e.metaKey || e.ctrlKey) && e.keyCode === 65);
      if (!valid || (newVal === '' && e.keyCode !== 8) || (e.key === '-' && e.target.selectionStart === 0)) {
        e.preventDefault();
        e.stopPropagation();
      } else if (e.keyCode === 13) {
        if (!!textFieldRef.current.value) {
          // const found = _options.findIndex(item => item.value === newVal);
          //If contract values available match it with typed  value,
          let found = _options.findIndex(
            item =>
              parseFloat(item.value).toFixed(3) === parseFloat(newVal).toFixed(3) &&
              item.source === RateElemenetSource.Contract,
          );
          if (found === -1) {
            found = _options.findIndex(
              item =>
                parseFloat(item.value).toFixed(3) === parseFloat(newVal).toFixed(3) &&
                item.source !== RateElemenetSource.Contract,
            );
          }
          if (found === -1) {
            if (!(newVal as string).includes('-')) {
              const singleIndex = _options.findIndex(item => parseFloat(item.value) === parseFloat(newVal));
              if (singleIndex !== -1) {
                onBlur(_options[singleIndex]);
                onChange(_options[singleIndex]);
              } else {
                onBlur(_options[_options.length - 1]);
                onChange(_options[_options.length - 1]);
              }
            } else {
              onBlur(_options[_options.length - 1]);
              onChange(_options[_options.length - 1]);
            }
          } else {
            onBlur(_options[found]);
            onChange(_options[found]);
          }
          setOpen(false);
        }
      } else if (e.keyCode !== 8 && e.keyCode !== 46 && e.keyCode !== 37 && e.keyCode !== 39) {
        if (type === numberType.currency) {
          const fullValue = newVal.split('-');
          if (fullValue.length > 0) {
            if (
              !validDecimal(fullValue[0], { numberLength: 3, decimalLength: 2 }) ||
              (fullValue.length > 1 && !validDecimal(fullValue[1], { numberLength: 3, decimalLength: 2 }))
            ) {
              e.preventDefault();
              e.stopPropagation();
            }
          }
        } else if (type === numberType.number && textFieldRef.current.value.length > 2) {
          e.preventDefault();
          e.stopPropagation();
        } else if (type === numberType.singleRate) {
          if (!validDecimal(newVal, { numberLength: 1, decimalLength: 3 })) {
            e.preventDefault();
            e.stopPropagation();
          }
        }
      }
    }
  };

  /**
   * Changes bordercolor based on input status
   */

  const getBordeColor = statusId => {
    switch (statusId) {
      case Status.Unsaved:
        return classes.unsaved;
      case Status.Approved:
        return classes.approved;
      case Status.Pending:
        return classes.pending;
      case Status.PendingApproval:
        return classes.pending;
      case Status.Rejected:
        return classes.rejected;
      case ContractType.OffContract:
        return classes.input;
      case ContractType.OnContract:
        return '';
    }
  };

  const textFieldRef = React.useRef<any>();
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={customTheme}>
        <DropDownContext.Provider
          value={{
            applyAll,
            onApplyAll,
            dropdownLabel,
            labelPrefix,
            labelPostfix,
            _setOptions,
            _options,
            onChange,
            onBlur,
            type,
            name: props.name,
          }}
        >
          <ClickAwayListener disableReactTree onClickAway={handleClickAway}>
            <Autocomplete
              open={open}
              /** Configured as multiple for
               *    - deselct a selected option
               *    - use renderTags to display custom label along with selected option
               */
              multiple
              blurOnSelect
              disablePortal
              value={!!value ? [value] : []}
              classes={{ popupIndicatorOpen: classes.popupOpen, inputRoot: getBordeColor(isChanged) }}
              clearIcon={null}
              popupIcon={<KeyboardArrowDownIcon style={{ opacity: 0.6 }} />}
              forcePopupIcon
              style={{ width: '250px', paddingTop: '2px', paddingBottom: '2px' }}
              options={_options}
              // @ts-ignore
              getOptionLabel={opt => (opt.value ? opt.value.toString() : 'TEST')}
              renderTags={(opts, getTagProps) =>
                opts.map((opt, index) => (
                  <CustomTag key={index.toString()} opt={opt} {...getTagProps({ index })} classes={classes} />
                ))
              }
              onChange={handleChange}
              renderInput={params => (
                <TextField
                  {...params}
                  inputRef={textFieldRef}
                  label={props.label}
                  variant="filled"
                  onFocus={() => setOpen(true)}
                  onChange={onInputChange}
                  onKeyDown={onKeyDown}
                  helperText={error}
                  error={error ? true : false}
                  autoComplete="off"
                />
              )}
              renderOption={(params, option, { selected }) => (
                <CustomOption params={params} option={option} selected={selected} numberType={type} />
              )}
              PopperComponent={PopperComponent}
              PaperComponent={PaperComponent}
              filterOptions={(opts, state) => opts}
              groupBy={option => {
                if (option?.group) return option?.group;
                else return null;
              }}
              renderGroup={params => GroupRender(params, classes, _options)}
              isOptionEqualToValue={(option, value1) => {
                return option?.rateElementId === value1?.rateElementId;
              }}
              onOpen={event => {
                setOpen(true);
              }}
              onClose={(event, reason) => {
                if (reason === 'toggleInput') setOpen(false);
              }}
            />
          </ClickAwayListener>
        </DropDownContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const PopperComponent = props => <Popper disablePortal {...props} placement="bottom-start" />;

const PaperComponent = props => {
  const { dropdownLabel } = useContext(DropDownContext);

  return (
    <Paper {...props} aria-label={dropdownLabel}>
      {props.children}
    </Paper>
  );
};

export default CustomRangeDropdown;

/** Tag renders - start */
const CustomTag = ({ opt, classes, ...props }) => {
  const { labelPrefix, labelPostfix } = useContext(DropDownContext);

  const startLabel = React.useMemo(
    () =>
      labelPrefix ? (
        <InputAdornment position="start" classes={{ positionStart: classes.rangeStartLabel }}>
          {labelPrefix}
        </InputAdornment>
      ) : null,
    [classes.rangeStartLabel, labelPrefix],
  );

  const endLabel = React.useMemo(
    () =>
      labelPostfix ? (
        <InputAdornment position="end" classes={{ positionEnd: classes.rangeEndLabel }}>
          {labelPostfix}
        </InputAdornment>
      ) : null,
    [classes.rangeEndLabel, labelPostfix],
  );

  if (!isOptionValueRange(opt.value) && !isInstanceOfIRange(opt)) {
    return (
      <div style={{ display: 'flex', marginLeft: 5 }} {...props}>
        {startLabel} {opt?.value} {endLabel}
      </div>
    );
  } else if (isOptionValueRange(opt.value)) {
    return (
      <div style={{ display: 'flex' }} {...props}>
        {startLabel}
        {
          <>
            <span>{`${opt.value.minRange} - ${opt.value.maxRange}`}</span>
          </>
        }
      </div>
    );
  } else {
    return (
      <div style={{ display: 'flex' }} {...props}>
        {startLabel}
        {opt.min?.value && !opt.max?.value && (
          <>
            <span>{opt.min?.value}</span>
          </>
        )}
        {!opt.min?.value && opt.max?.value && (
          <span>
            {0} - {opt.max?.value}
          </span>
        )}
        {opt.min?.value && opt.max?.value && (
          <span>
            {opt.min?.value} - {opt.max?.value}
          </span>
        )}
        {endLabel}
      </div>
    );
  }
};
/** Tag renders - end */

/** Option renders - start */

export const getColor = statusId => {
  switch (statusId) {
    case Status.Pending:
    case Status.PendingApproval:
      return { background: '#FC9508 !important', hover: '#FFF4E5 !important' };
    case Status.Approved:
      return { background: `${theme.palette.success.main} !important`, hover: '#E7F5E6 !important' };
    case Status.Rejected:
      return { background: '#CC0000 !important', hover: '#FAE5E5 !important' };
    default:
      return { background: 'transparent', hover: 'transparent' };
  }
};

const CustomOption = ({
  params,
  selected,
  option,
  numberType,
}: {
  params: any;
  selected: boolean;
  option: { min: { value: string }; max: { value: string }; statusId: { value: number } };
  numberType: numberType;
}) => {
  const [onHover, setOnHover] = useState(false);
  return (
    <Box
      sx={{
        margin: '2px 0px',
        '&:hover': {
          backgroundColor: getColor(option?.statusId).hover,
        },
        borderLeft: `${
          !!option?.min || !!option?.max || getColor(option?.statusId).background === 'transparent' ? '0px' : '5px'
        } 
      solid 
      ${!!option?.min || !!option?.max ? 'transparent' : getColor(option?.statusId).background}`,
      }}
      data-shift={getColor(option?.statusId).hover !== 'transparent'}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <Box
        {...params}
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingLeft: '0px',
          paddingTop: '0px',
          paddingBottom: '0px',
          fontSize: '12px',
        }}
        sx={{
          '[data-shift="true"] &:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <Checkbox
          size="small"
          color="primary"
          checked={selected}
          style={{ marginRight: 2 }}
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<RadioButtonCheckedIcon />}
        />
        {!!option?.min || !!option?.max ? (
          <RangeRender option={option} selected={selected} numType={numberType} />
        ) : (
          <SingleRender option={option} onHover={onHover} />
        )}
      </Box>
    </Box>
  );
};

const RangeRender = ({
  option,
  selected,
  numType,
}: {
  option: { min: { value: string }; max: { value: string } };
  selected: boolean;
  numType: numberType;
}) => {
  const { labelPostfix, labelPrefix, _setOptions, _options, onChange, type, name, onBlur } =
    useContext(DropDownContext);
  const inputRef1 = React.useRef<HTMLInputElement>();
  const inputRef2 = React.useRef<HTMLInputElement>();
  const [input1, setInput1] = useState<string | undefined>();
  const [input2, setInput2] = useState<string | undefined>();

  React.useEffect(() => {
    if (option) {
      setInput1(option.min.value);
      setInput2(option.max.value);
    }
  }, [option.min.value, option.max.value]);

  const inputProps = labelPrefix
    ? {
        startAdornment: <InputAdornment position="start">{labelPrefix}</InputAdornment>,
      }
    : labelPostfix
    ? {
        endAdornment: <InputAdornment position="end">{labelPostfix}</InputAdornment>,
      }
    : {};

  return (
    <Box style={{ display: 'flex', gap: 12 }}>
      <NumberField
        id={`number-field-${name}-0`}
        variant="outlined"
        value={input1}
        size="small"
        InputProps={inputProps}
        inputRef={(element: HTMLInputElement) => {
          inputRef1.current = element;
        }}
        onClick={e => {
          if (selected) e.stopPropagation();
          inputRef1.current?.focus();
        }}
        onChange={e => {
          if (
            (type === numberType.currency && validDecimal(e.target.value, { numberLength: 3, decimalLength: 2 })) ||
            (type === numberType.number && /^([0-9]{0,3})$/.test(e.target.value)) ||
            (type === numberType.singleRate && validDecimal(e.target.value, { numberLength: 1, decimalLength: 3 }))
          ) {
            setInput1(e.target.value);
            const val = updateOptionsArray(_options, _setOptions, e.target.value, 'min');
            if (!val?.min?.value && !val?.max?.value) onChange(null);
            else onChange(val);
          }
        }}
        onBlur={() => {
          let val;
          if (type === numberType.currency) {
            val = updateOptionsArray(_options, _setOptions, convertToDecimal(input1), 'min');
          } else if (type === numberType.singleRate) {
            val = updateOptionsArray(_options, _setOptions, convertToDecimal(input1, 3), 'min');
          } else {
            val = updateOptionsArray(_options, _setOptions, input1, 'min');
          }
          if (!val?.min?.value && !val?.max?.value) {
            onBlur(null);
            onChange(null);
          } else {
            onBlur(val);
            onChange(val);
          }
        }}
      />
      {numType === 0 && (
        <NumberField
          id={`number-field-${name}-1`}
          variant="outlined"
          value={input2}
          size="small"
          InputProps={inputProps}
          inputRef={(element: HTMLInputElement) => {
            inputRef2.current = element;
          }}
          onClick={e => {
            if (selected) e.stopPropagation();
            inputRef2.current?.focus();
          }}
          onChange={e => {
            if (
              (type === numberType.currency && validDecimal(e.target.value, { numberLength: 3, decimalLength: 2 })) ||
              (type === numberType.number && /^([0-9]{0,3})$/.test(e.target.value))
            ) {
              setInput2(e.target.value);
              const val = updateOptionsArray(_options, _setOptions, e.target.value, 'max');
              if (!val?.min?.value && !val?.max?.value) onChange(null);
              else onChange(val);
            }
          }}
          onBlur={() => {
            let val;
            if (type === numberType.currency) {
              val = updateOptionsArray(_options, _setOptions, convertToDecimal(input2), 'max');
            } else if (type === numberType.singleRate) {
              val = updateOptionsArray(_options, _setOptions, convertToDecimal(input2, 3), 'max');
            } else {
              val = updateOptionsArray(_options, _setOptions, input2, 'max');
            }
            if (!val?.min?.value && !val?.max?.value) {
              onBlur(null);
              onChange(null);
            } else {
              onBlur(val);
              onChange(val);
            }
          }}
        />
      )}
    </Box>
  );
};

const SingleRender = ({ option, onHover }) => {
  const { labelPostfix, labelPrefix } = useContext(DropDownContext);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        // width: '100%',
      }}
    >
      {labelPrefix ? (
        <OptLabelRender hasPrefix color="secondary">
          {labelPrefix}
        </OptLabelRender>
      ) : null}
      <RenderValueStatus option={option} onHover={onHover} labelPostfix={labelPostfix} />
    </Box>
  );
};

const GroupRender = (params, classes, _options) => {
  const uniqueShifts = new Set();
  _options?.forEach(dropDownItem => {
    if (dropDownItem?.group) {
      uniqueShifts.add(dropDownItem?.group);
    }
  });
  return (
    <>
      <Box key={params.key}>
        <Box className={params.group ? classes.groupDividerStyle : ''}>
          <Typography classes={{ root: classes.groupHeader }}>{params.group}</Typography>
        </Box>
        {params.children}
        {!(params.key !== 0 && params.group === null) && uniqueShifts.size > 1 && (
          <Divider sx={{ margin: '12px 0' }} variant="middle" />
        )}
      </Box>
    </>
  );
};

/**
 * Renders the option status on hovering
 */

const RenderValueStatus = ({ onHover, option, labelPostfix }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '180px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {isOptionValueRange(option.value) ? `${option.value.minRange} - ${option.value.maxRange}` : option.value}
        {labelPostfix ? <OptLabelRender hasPostfix={labelPostfix}>{labelPostfix}</OptLabelRender> : null}{' '}
      </Box>
      <Box
        color="secondary"
        sx={{
          '[data-shift="true"] &': {
            display: 'none',
          },
          '[data-shift="true"]:hover &': {
            display: 'block',
            color: getColor(option?.statusId).background,
          },
          marginLeft: 'auto',
          paddingRight: '8px',
        }}
      >{`${onHover ? getDisplayStatusName(option?.statusId) : ''}`}</Box>
    </div>
  );
};
/** Option renders - end */

/**
 * Updates the Dropdown options based on inputs in textbox
 * @params _setOptions -setter for options
 * @params input -text field value
 * @params objKey -min or max field to
 * @returns
 */
const updateOptionsArray = (_options, _setOptions, input, objKey) => {
  let newVal: any = null;
  if (objKey === 'min') newVal = { min: { value: input }, max: { value: _options[_options.length - 1].max.value } };
  else if (objKey === 'max')
    newVal = { min: { value: _options[_options.length - 1].min.value }, max: { value: input } };
  _setOptions(pre => {
    pre[pre.length - 1] = newVal;
    return pre;
  });
  return newVal;
};

const OptLabelRender = styledComponent(Box)<{ hasPostfix?: boolean; hasPrefix?: boolean }>`
  margin-left: ${props => (props.hasPostfix ? '8px' : undefined)};
  margin-right: ${props => (props.hasPrefix ? '0px' : undefined)};
`;
