import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  TextField,
  Theme,
  Paper,
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  AutocompleteInputChangeReason,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { ITypeAheadOption } from 'app/models/Orders/OrderDetails';
import { deepEqual } from 'utils/common/comparison';
import CloseIcon from '@mui/icons-material/Close';
import { DisableAppScroll, EnableAppScroll } from 'app/layout/Layout';
import { colors } from 'styles/styleVariables';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExpandMoreOutlined from '@mui/icons-material/ExpandMoreOutlined';
import { theme } from 'styles/global-styles';

const useStyles: any = makeStyles()((theme: Theme) => ({
  underline: {
    // '&:hover': {
    //   '&:before': {
    //     borderBottom: '1px solid #009AD9',
    //   },
    // },
    // '&:before': {
    //   borderBottom: '1px solid #009AD9',
    // },
  },
  focused: {
    // backgroundColor: '#ffffff !important',
  },
  historyLabel: {
    padding: '10px 16px 0px 16px',
    fontWeight: 100,
    color: '#555555',
  },
}));

const customTextFieldTheme = createTheme(theme, {
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          background: '#fff !important',
        },
        underline: {
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: `1px solid ${colors.amnLightBlue}`,
          },
          '&:after': {
            borderBottom: `2px solid ${colors.amnLightBlue}`,
          },
        },
      },
    },
  },
});

export const TypeAhead = props => {
  const {
    label,
    options,
    isError,
    isDisabled,
    helperText,
    placeholder,
    isOptionsLoading,
    hasAsyncOptions,
    hideForOneResult,
    hideUntilTyped,
    group,
    renderOption,
    renderOptionHistory,
    renderGroup,
    popperComponent,
    customClasses,
    getOptionsLabel,
    noOptionsText,
    searchOnCharacterCount = 0,
    historyKey,
    historyLength = 3,
    historyTitle = '',
    variant,
    size,
    startAdornment,
    inputValue,
    removeCloseIcon,
    forcePopupIcon = true,
    autoFocus = false,
    style = undefined,
    isCustomTheme = false,
  } = props;
  const { classes } = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [textFieldValue, setTextFieldValue] = useState<any>(null);
  const [singleResult, setSingleResult] = useState<ITypeAheadOption | null>(null);
  const [history, setHistory] = useState<ITypeAheadOption[]>([]);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const autocompleteRef: React.RefObject<any> = React.useRef();
  const inputRef: React.RefObject<any> = React.useRef();

  const getHistory = useCallback(() => {
    let localHistory: any;
    const foundHistory = localStorage.getItem('searchHistory');
    if (foundHistory === null) {
      localHistory = {
        [historyKey]: [],
      };
    } else localHistory = JSON.parse(foundHistory || '');
    return localHistory;
  }, [historyKey]);

  useEffect(() => {
    if (historyKey) {
      const localHistory = getHistory();
      setHistory(localHistory[historyKey]);
    }
  }, [getHistory, historyKey]);

  useEffect(() => {
    setTextFieldValue(inputValue);
    if (inputValue === null) {
      autocompleteRef?.current?.getElementsByClassName('MuiAutocomplete-clearIndicator')[0]?.click();
      autocompleteRef?.current?.getElementsByClassName('MuiInputBase-input')[0]?.blur();
      setOpen(false);
      setShowHistory(false);
    }
  }, [inputValue]);

  const onChange = (
    event: ChangeEvent<{}>,
    choice: ITypeAheadOption | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ITypeAheadOption> | undefined,
  ) => {
    props.onChange(choice);
    setTextFieldValue(`${choice?.label || ''}${choice?.label2 || ''}`);
    if (props.onChangeReflection) props.onChangeReflection(choice);

    if (historyKey && choice !== null) {
      let localHistory = [...history];
      let foundIndex = -1;
      localHistory?.forEach((item, index) => {
        if (deepEqual(item, choice)) {
          foundIndex = index;
        }
      });
      if (foundIndex !== -1) localHistory.splice(foundIndex, 1);
      localHistory = [choice, ...localHistory].slice(0, historyLength);
      setHistory(localHistory);
      localStorage.setItem('searchHistory', JSON.stringify({ ...getHistory(), [historyKey]: localHistory }));
    }
  };

  const onInputChange = (
    event: React.ChangeEvent<{}>,
    newInputValue: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    if (hideForOneResult) {
      const found = options.filter(
        item => `${item.label || ''}${item.label2 || ''}`.toLowerCase().indexOf(newInputValue.toLowerCase()) >= 0,
      );
      if (found.length === 1) {
        setSingleResult(found[0]);
      } else setSingleResult(null);
    }
    setTextFieldValue(newInputValue || null);
    if (props.onInputChange && searchOnCharacterCount <= newInputValue.length)
      props.onInputChange(newInputValue, reason);
  };

  const onKeyDown = event => {
    if (event.key === 'Enter' && singleResult) {
      onChange(event, singleResult, 'selectOption');
      setOpen(false);
      setShowHistory(false);
    }
  };

  const onFocus = event => {
    if (typeof props.onFocus === 'function') props.onFocus(event);
    setOpen(true);
    if (historyKey && history?.length > 0) {
      setShowHistory(true);
    }
  };

  const shouldShowHistory = () => showHistory && (textFieldValue || '').length === 0;

  const showPopper = (): boolean => {
    return props.invokeInitialAsyncData
      ? true
      : shouldShowHistory()
      ? true
      : searchOnCharacterCount > (textFieldValue || '').length
      ? false
      : hideUntilTyped && (textFieldValue || '') === ''
      ? false
      : hasAsyncOptions
      ? open && textFieldValue
      : open;
  };

  return (
    <Autocomplete
      ref={autocompleteRef}
      data-testid="test"
      blurOnSelect
      clearOnBlur
      inputValue={textFieldValue || ''}
      noOptionsText={noOptionsText || undefined}
      value={props.value}
      autoHighlight={singleResult !== null}
      options={shouldShowHistory() ? history : options}
      disabled={isDisabled}
      groupBy={group && !shouldShowHistory() ? option => option.groupBy?.label || '' : undefined}
      getOptionLabel={getOptionsLabel || ((option: ITypeAheadOption) => option.label || '')}
      popupIcon={props.popupIcon ? props.popupIcon : open ? <ExpandMoreOutlined /> : <KeyboardArrowDownIcon />}
      forcePopupIcon={forcePopupIcon}
      renderTags={() => null}
      loading={isOptionsLoading}
      classes={{ ...customClasses } || undefined}
      clearIcon={removeCloseIcon ? null : <CloseIcon fontSize="small" />}
      open={showPopper()}
      onOpen={() => {
        setOpen(true);
        (shouldShowHistory() ? history.length : options.length) && DisableAppScroll();
        if (historyKey && history?.length > 0) {
          setShowHistory(true);
        }
      }}
      onClose={() => {
        setOpen(false);
        EnableAppScroll();
        setShowHistory(false);
      }}
      renderInput={params =>
        !isCustomTheme ? (
          <TextField
            ref={inputRef}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
            error={isError}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            onBlur={props.onBlur}
            helperText={helperText}
            label={label}
            placeholder={placeholder}
            {...params}
            variant={variant || 'filled'}
            size={size || undefined}
            InputProps={{
              ...params.InputProps,
              classes: variant ? {} : { underline: classes.underline, focused: classes.focused },
              startAdornment: startAdornment || undefined,
              'aria-label': 'single-select-type-ahead',
            }}
          />
        ) : (
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={customTextFieldTheme}>
              <TextField
                ref={inputRef}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={autoFocus}
                error={isError}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                onBlur={props.onBlur}
                helperText={helperText}
                label={label}
                placeholder={placeholder}
                {...params}
                variant={variant || 'filled'}
                size={size || undefined}
                InputProps={{
                  ...params.InputProps,
                  classes: variant ? {} : { underline: classes.underline, focused: classes.focused },
                  startAdornment: startAdornment || undefined,
                  'aria-label': 'single-select-type-ahead',
                }}
              />
            </ThemeProvider>
          </StyledEngineProvider>
        )
      }
      onChange={onChange}
      onInputChange={onInputChange}
      renderGroup={renderGroup || undefined}
      renderOption={
        renderOption || renderOptionHistory ? (shouldShowHistory() ? renderOptionHistory : renderOption) : undefined
      }
      PopperComponent={popperComponent || undefined}
      PaperComponent={
        !shouldShowHistory()
          ? undefined
          : p => {
              return (
                <Paper>
                  <div className={classes.historyLabel}>{historyTitle}</div>
                  {p.children}
                </Paper>
              );
            }
      }
      style={style}
    />
  );
};
