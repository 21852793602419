import React from 'react';
import { missingField } from 'app/constants';
import styledComponent from 'styled-components';
import { useTranslation } from 'react-i18next';

export const OrderInfoPayItem = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <div id="placement-order-info-pay">
      <RowDataDiv data-testid="order-info-weekly-gross-pay-item">
        {`${t('placement.profile.orderInformation.orderInfoPayItem.weeklyGrossPay')}:`}
        {props.orderInformation?.weeklyGrossPay ? ` $${props.orderInformation?.weeklyGrossPay}` : ` ${missingField}`}
      </RowDataDiv>
    </div>
  );
};

const RowDataDiv = styledComponent.div`
  font-size: 14px;
  white-space: pre-wrap;
`;
