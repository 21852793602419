import { IDrawerContactPartner, IFacilityContacts } from '@AMIEWEB/Common/Drawer/HeaderContactDrawer';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips';
import { Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { missingField } from 'app/constants';
import React from 'react';
import { GetMomentOfDate } from 'utils/dates/moment';
import { ConcatenateX, isStrNull } from 'utils/string/string';
import { SkillSetType } from 'app/models/Unit/CreateUnit';
import { SkillSet } from 'app/models/Orders/OrderDetails';

export interface IHeaderRow {
  onMeasureWidth?: (width: number) => void;
  rowHead: string;
  rowContent: string;
  classes: any;
  id: string;
  type?: string;
  fixedWidth?: number;
  isFeatureFlagEnabled?: boolean;
}

export const getName = (data: { firstName: string; lastName: string }) => {
  return data && data.firstName ? `${data.firstName || ''} ${data.lastName || ''}` : missingField;
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  if (phoneNumber) {
    const cleaned = phoneNumber.replace(/\D/g, '');
    const areaCode = cleaned.slice(0, 3);
    const centralOfficeCode = cleaned.slice(3, 6);
    const lineNumber = cleaned.slice(6, 10);
    return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
  } else {
    return missingField;
  }
};

export const getDate = (date: string) => {
  const moment = GetMomentOfDate(date);
  if (moment.isValid()) return moment.format('MM/DD/YYYY');
  return '';
};

export const getFacilityHeaderAddressTemplate = (location): string => {
  if (location?.address1) {
    const addressComponents = [
      { value: location.address1, appendComma: !isStrNull(location.address2) },
      { value: location.address2, appendComma: true },
      { value: location.city, appendComma: true },
      { value: location.state, appendComma: false },
      { value: location.zipCode, appendComma: false },
    ];
    return addressComponents
      .map(({ value, appendComma }) => {
        const strValue = isStrNull(value);
        return strValue ? `${strValue}${appendComma ? ',' : ''}` : null;
      })
      .filter(Boolean)
      .join(' ');
  }
  return missingField;
};

export function getKeyInternalPartners(facilityDetailsResponse) {
  const keyInternalPartners: IDrawerContactPartner[] = [
    {
      userId: facilityDetailsResponse.employeeContacts?.recruiter?.userId,
      userRole: 'Recruiter',
      firstName: facilityDetailsResponse.employeeContacts?.recruiter?.firstName,
      lastName: facilityDetailsResponse.employeeContacts?.recruiter?.lastName,
      email: facilityDetailsResponse.employeeContacts?.recruiter?.email,
      phone: facilityDetailsResponse.employeeContacts?.recruiter?.phoneNumber,
      im: facilityDetailsResponse.employeeContacts?.recruiter?.im,
    },
    {
      userId: facilityDetailsResponse.employeeContacts?.regionalDirector?.userId,
      userRole: 'Regional Director',
      firstName: facilityDetailsResponse.employeeContacts?.regionalDirector?.firstName,
      lastName: facilityDetailsResponse.employeeContacts?.regionalDirector?.lastName,
      email: facilityDetailsResponse.employeeContacts?.regionalDirector?.email,
      phone: facilityDetailsResponse.employeeContacts?.regionalDirector?.phoneNumber,
      im: facilityDetailsResponse.employeeContacts?.regionalDirector?.im,
    },
    {
      userId: facilityDetailsResponse.employeeContacts?.accountManager?.userId,
      userRole: 'Account Manager',
      firstName: facilityDetailsResponse.employeeContacts?.accountManager?.firstName,
      lastName: facilityDetailsResponse.employeeContacts?.accountManager?.lastName,
      email: facilityDetailsResponse.employeeContacts?.accountManager?.email,
      phone: facilityDetailsResponse.employeeContacts?.accountManager?.phoneNumber,
      im: facilityDetailsResponse.employeeContacts?.accountManager?.im,
    },
    {
      userId: facilityDetailsResponse.employeeContacts?.credentialingAnalyst?.userId,
      userRole: 'Credentialing Analyst',
      firstName: facilityDetailsResponse.employeeContacts?.credentialingAnalyst?.firstName,
      lastName: facilityDetailsResponse.employeeContacts?.credentialingAnalyst?.lastName,
      email: facilityDetailsResponse.employeeContacts?.credentialingAnalyst?.email,
      phone: facilityDetailsResponse.employeeContacts?.credentialingAnalyst?.phoneNumber,
      im: facilityDetailsResponse.employeeContacts?.credentialingAnalyst?.im,
    },
    {
      userId: facilityDetailsResponse.employeeContacts?.clinicalManager?.userId,
      userRole: 'Clinical Manager',
      firstName: facilityDetailsResponse.employeeContacts?.clinicalManager?.firstName,
      lastName: facilityDetailsResponse.employeeContacts?.clinicalManager?.lastName,
      email: facilityDetailsResponse.employeeContacts?.clinicalManager?.email,
      phone: facilityDetailsResponse.employeeContacts?.clinicalManager?.phoneNumber,
      im: facilityDetailsResponse.employeeContacts?.clinicalManager?.im,
    },
  ];
  return keyInternalPartners;
}

export function getKeyClientContacts(facilityDetailsResponse) {
  const keyClientContacts: IDrawerContactPartner[] = [];
  facilityDetailsResponse.facilityContacts?.map(contactPar => {
    const contact = contactPar as IFacilityContacts;
    if (contact?.actions) {
      keyClientContacts.push({
        facilityId: facilityDetailsResponse?.facilityId,
        facilityName: facilityDetailsResponse?.facilityName,
        userId: contact?.contactId,
        userRole: contact?.title,
        firstName: contact?.firstName,
        lastName: contact?.lastName,
        phone: contact?.phoneNumber?.[0].phoneNumber,
        doNotCall: contact?.doNotCall,
        email: contact?.email,
        description: ConcatenateX(
          contact?.actions?.map(action => action.description),
          ', ',
        ),
        statusId: contact?.statusId,
        units: [],
      } as IDrawerContactPartner);
    }
  });
  return keyClientContacts;
}

const useStyles = makeStyles()(theme => ({
  tooltipColumnsContainer: {
    display: 'flex',
    marginTop: '-15px',
    marginBottom: '-15px',
  },
  tooltipColumn: {
    flex: 1,
    marginLeft: '-25px',
  },
  blueBarText: {
    fontSize: '14px !important',
    fontWeight: '500 !important',
    color: `${theme.palette.framework.system.doveGray} !important`,
  },
  skillSetCounter: {
    color: theme.palette.primary.main,
    display: 'inline',
    cursor: 'pointer',
  },
}));

export const SkillSetContent = ({
  skillSets,
  isOrderHeader = false,
}: {
  skillSets: SkillSetType[] | SkillSet[];
  isOrderHeader?: boolean;
}) => {
  const { classes } = useStyles();

  const content = skillSets
    ? skillSets
        ?.map(element => `${element.discipline}${isOrderHeader ? ' - ' : ' '}${element.specialty ?? ''}`)
        .join(', ')
    : missingField;

  const skillSetsArray = content.split(', ').map(skill => skill.trim());
  const getTooltipContent = () => {
    const chunkArray = arr => {
      return arr.reduce((acc, _, i) => (i % 10 === 0 ? [...acc, arr.slice(i, i + 10)] : acc), []);
    };
    const chunkedSkillSets = chunkArray(skillSetsArray);

    return (
      <div className={classes.tooltipColumnsContainer}>
        {chunkedSkillSets.map((chunk, index: React.Key) => (
          <ul key={index} className={classes.tooltipColumn}>
            {chunk.map((skill, index) => (
              <li key={index}>
                <Typography sx={{ fontSize: '14px' }}>{skill}</Typography>
              </li>
            ))}
          </ul>
        ))}
      </div>
    );
  };

  return (
    <CustomTooltip tooltipContent={getTooltipContent()} hideToolTip={skillSetsArray.length <= 2}>
      <Typography variant="body1" className={classes.blueBarText} pl={!isOrderHeader ? 1 : 0}>
        {skillSetsArray.length > 2 ? (
          <>
            {`${skillSetsArray?.slice(0, 2).join(', ')} `}
            <Typography variant="body1" className={classes.skillSetCounter}>
              +{skillSetsArray.length - 2}
            </Typography>
          </>
        ) : (
          content
        )}
      </Typography>
    </CustomTooltip>
  );
};
