/* eslint-disable tss-unused-classes/unused-classes */
/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from 'tss-react/mui';
import { CustomFilter } from 'app/ComponentLibrary/Filter';
import React, { useState, useEffect, createRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { defaultFilterChipSelected, FacilityUnitActions } from '../Store/FacilityUnit.redux';
import { selectFilterSpecs, selectUnitsSourceData } from '../Store/FacilityUnit.selector';
import { defaultFilterValues, getFilterSpecs } from './UnitFilterConfig';

const useStyles = makeStyles()({
  unit: {
    minWidth: 130,
  },
  subSpecialty: {
    minWidth: 125,
  },
  lastUpdatedBy: {
    minWidth: 140,
  },
  fyreIntegration: {
    minWidth: 150,
  },
  costCenter: {
    minWidth: 125,
  },
});

export const UnitFilter = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const filterSpecs = useSelector(selectFilterSpecs);
  const [fields, setFields] = useState<any>(null);
  const dispatch = useDispatch();
  const hiddenSubmitButton = createRef<HTMLButtonElement>();
  const flattenedData = useSelector(selectUnitsSourceData);

  const {
    setValue,
    formState: { dirtyFields },
  } = useFormContext();

  const resetFilter = () => {
    Object.entries(defaultFilterValues).map(item => setValue(item[0], item[1]));
    dispatch(FacilityUnitActions.setFilterChipSelected(defaultFilterChipSelected));
    setValue('lastUpdatedDate', null);
    //Reset
    props.onApplyFilter(defaultFilterValues, defaultFilterChipSelected);
  };

  useEffect(() => {
    Object.entries(defaultFilterValues).map(item => setValue(item[0], item[1]));
    dispatch(FacilityUnitActions.setFilterChipSelected(defaultFilterChipSelected));
    //Reset
    props.onApplyFilter(defaultFilterValues, defaultFilterChipSelected);
  }, []);

  useEffect(() => {
    if (filterSpecs) {
      setFields(getFilterSpecs(filterSpecs, t, classes));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSpecs]);

  const onFilterChangeValues = (fieldName, newValue) => {
    if (['startDate', 'reminderDate', 'daysBeforeStart'].includes(fieldName)) {
      //CHECK: To Generated Chip after Submit, Comment below Code & Uncomment Above
      hiddenSubmitButton.current?.click();
    }
  };

  useEffect(() => {
    if (flattenedData) {
      resetFilter();
    }
  }, [flattenedData]);

  return (
    <>
      {filterSpecs && fields && (
        <CustomFilter
          fields={fields}
          onResetForm={() => resetFilter()}
          justifyContent={'flex-start'}
          version2
          falseProp={dirtyFields}
          onChangeValues={onFilterChangeValues}
          analytics={{
            caller: 'Unit Filter',
            onApply: true,
            properties: {
              category: 'Unit Grid',
            },
          }}
        />
      )}
      <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
    </>
  );
};
