import React from 'react';
import { Grid } from 'amn-ui-core';
import Information from './UnitRequirements/Information';
import { SkillSetWrapper } from './UnitRequirements/Experience/SkillSetWrapper';

export const UnitRequirementsSection = ({ unitInformationRef, unitExperienceRef }) => {
  return (
    <>
      <Grid container ref={unitInformationRef}>
        <Information />
      </Grid>
      <Grid container ref={unitExperienceRef}>
        <SkillSetWrapper />
      </Grid>
    </>
  );
};
