import { RootState } from 'types';
import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './candidateOrderMatch.redux';

const selectDomain = (state: RootState) => state?.candidateOrderMatch || initialState;

export const selectCandidateOrderMatch = createSelector([selectDomain], data => data);
export const selectOrderMatchChips = createSelector([selectDomain], data => data.chips);
export const selectCandidateOrderMatchId =  createSelector([selectDomain], data => data.id);
export const selectPayload = createSelector([selectDomain], data => data.partialPayload);
export const selectAppliedFilters = createSelector([selectDomain], data => data.appliedFilters);
export const selectModalProps = createSelector([selectDomain], data => ({
    tableData: data?.tableData,
    referenceData: data?.referenceData,
    tableRows: data?.tableRows,
    open: data?.openModal
}));
export const selectCandidateLookUpPrefilter = createSelector([selectDomain], data => data.candidateLookUpPrefilter);
