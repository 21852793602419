import { takeLatest, put, call, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { trackPromise } from 'react-promise-tracker';
import { amPlacementsAction } from './AmPlacementsDesk.redux';
import { getActivePlacementsByAccountManager } from 'app/services/PlacementServices/PlacementServices';
import { amPlacementFlattenData } from './AmGridHelper';
import { getAmPipelineCounts } from './amCountHelper';
import { getActiveUsersByLoggedInUserId } from 'app/services/CandidateServices/CandidateServices';
import { selectUser } from 'oidc/user.selectors';
import {
  filterUserChoices,
  getAccountManagerOpts,
  createFilterChips,
  getFilteredData,
  createAdvancedFilterChips,
  getFilterChipSelected,
} from './filterHelper';
import { getMultiSelectOptions } from 'app/ComponentLibrary/Filter/helper';
import { selectAmPlacementDeskChips, selectFilterChipSelected } from './AmPlacementsDesk.selector';
import { gridStateActions } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import { TFunction } from 'i18next';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { httpSuccess } from 'app/services/serviceHelpers';

const TrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'AccountManger-Placements-call');

export function* requestAccountManagerPlacements(
  action: PayloadAction<{
    accountManagerId: number[];
    filter?: {
      values: any;
      setValue: (
        name: string,
        value: unknown,
        options?:
          | Partial<{
              shouldValidate: boolean;
              shouldDirty: boolean;
            }>
          | undefined,
      ) => void;
      t: TFunction;
    };
  }>,
) {
  try {
    const response = yield call(TrackWrapper, getActivePlacementsByAccountManager, action.payload.accountManagerId);
    const filterChipSelected = yield select(selectFilterChipSelected);
    const filterChips = yield select(selectAmPlacementDeskChips);

    if (response) {
      const flattenedData = amPlacementFlattenData(response.amPlacements);
      const counts = getAmPipelineCounts(flattenedData);
      const filterSpecs = {
        candidateOpts: getMultiSelectOptions(response.filterSpecs.candidateOpts),
        disciplineOpts: getMultiSelectOptions(response.filterSpecs.disciplineOpts),
        specialtyOpts: getMultiSelectOptions(response.filterSpecs.specialtyOpts),
        facilityOpts: getMultiSelectOptions(response.filterSpecs.facilityOpts),
        cityOpts: getMultiSelectOptions(response.filterSpecs.cityOpts),
        stateOpts: getMultiSelectOptions(response.filterSpecs.stateOpts),
        recruiterOpts: getMultiSelectOptions(response.filterSpecs.recruiterOpts),
        mspClientOpts: getMultiSelectOptions(response.filterSpecs.mspClientOpts, true),
        affiliateOpts: getMultiSelectOptions(response.filterSpecs.affiliateOpts, true),
        staffingOpts: getMultiSelectOptions(response.filterSpecs.staffingOpts, true),
        technologyOpts: getMultiSelectOptions(response.filterSpecs.technologyOpts, true),
        orderPriorityOpts: getMultiSelectOptions(response.filterSpecs.orderPriorityOpts, true),
      };
      yield put(amPlacementsAction.setCounts(counts));
      yield put(amPlacementsAction.setFlattenedData(flattenedData));
      yield put(amPlacementsAction.setFilterSpecs(filterSpecs));
      yield put(amPlacementsAction.setDataLoaded(true));

      if (action.payload.filter) {
        const updatedFilter = filterUserChoices(
          filterSpecs,
          action.payload.filter.values,
          action.payload.filter.setValue,
        );
        const newfilterChipSelected = getFilterChipSelected(updatedFilter, filterChipSelected);
        const filteredData = getFilteredData(updatedFilter, flattenedData, newfilterChipSelected);
        const newfilterChips = createFilterChips(updatedFilter, filterChipSelected);
        const newAdvancedFilterChips = createAdvancedFilterChips(updatedFilter, action.payload.filter.t);
        yield put(gridStateActions.setFilterAttributes({ filterObject: Object.entries(updatedFilter) }));
        yield put(amPlacementsAction.setFilterChips({ ...filterChips, ...newfilterChips, ...newAdvancedFilterChips }));
        yield put(amPlacementsAction.setFilteredData(filteredData));
        yield put(amPlacementsAction.setFilterChipSelected(newfilterChipSelected));
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestAccountManagerPlacements',
        area: 'src/app/components/AmWorkDesk/PlacementsDesk/AmPlacementsDesk.saga.ts',
      },
    });
  }
}

export function* requestActiceAccountManagers(
  action: PayloadAction<{
    userId: number;
  }>,
) {
  try {
    const response = yield call(TrackWrapper, getActiveUsersByLoggedInUserId);
    if (httpSuccess(response.status)) {
      const appUser = yield select(selectUser);
      const managers = getAccountManagerOpts(response.data ?? [], appUser.userInfo);
      yield put(amPlacementsAction.setActiveAccountManagers(managers));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestActiceAccountManagers',
        area: 'src/app/components/AmWorkDesk/PlacementsDesk/AmPlacementsDesk.saga.ts',
      },
    });
  }
}

export function* accountManagerPlacementSaga() {
  yield takeLatest(amPlacementsAction.getAmPlacementActions.type, requestAccountManagerPlacements);
  yield takeLatest(amPlacementsAction.getActiceAccountManagers.type, requestActiceAccountManagers);
}
