import React, { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { WhiteTooltip } from '@AMIEWEB/Common';
import { cloneDeep } from 'lodash';
export function UserCheckedChips({ data = [], onDataChange }) {
  const [renderingData, setRenderingData] = useState([]);

  // Generate userChecked nodes from the data for rendering
  useEffect(() => {
    const nodes = [];

    const traverse = (items, parentPath = []) => {
      items?.forEach(item => {
        const currentPath = [...parentPath, item];
        if (item.userChecked) {
          nodes.push({ path: currentPath, label: getPathLabel(currentPath), value: item.value });
        }
        if (item.children) {
          traverse(item.children, currentPath);
        }
      });
    };

    traverse(data);
    setRenderingData(nodes);
  }, [data]);

  const handleDeleteAndCheckParent = labelToRemove => {
    // Function to recursively delete the node and check if its parent should be removed
    const deleteNodeAndCheckParent = (nodes, parentPath = []) => {
      return nodes.reduce((acc, node) => {
        const currentPath = [...parentPath, node];
        if (getPathLabel(currentPath) === labelToRemove) {
          // Remove only the current node if it has no children and is not userChecked
          if (!node.userChecked && (!node.children || node.children.length === 0)) {
            return acc.filter(item => item !== node);
          }
          // Otherwise, return the accumulator as is (retain the node)
          return acc;
        }
        // Recursively process children
        if (node.children) {
          node.children = deleteNodeAndCheckParent(node.children, currentPath);
        }
        // Add the node to the accumulator
        acc.push(node);
        return acc;
      }, []);
    };

    // Update data by deleting the specified node and checking its parent
    const updatedData = deleteNodeAndCheckParent(cloneDeep(data));

    // Update parent component
    onDataChange(updatedData);
  };

  // Helper function to generate label from path
  const getPathLabel = path => {
    return path.map(item => item.name).join('-');
  };

  return (
    <div>
      {renderingData.map((node, index) => (
        <React.Fragment key={node.label}>
          <WhiteTooltip title={node.label.length > 30 ? node.label : ''} arrow>
            <Chip
              label={node.label}
              onDelete={() => handleDeleteAndCheckParent(node.label)}
              deleteIcon={<CloseSharpIcon fontSize="small" />}
              variant="outlined"
              size="small"
              sx={{ mr: 1, mb: 1 }}
            />
          </WhiteTooltip>
          {index !== renderingData.length - 1 && <br />} {/* Add new line if not the last chip */}
        </React.Fragment>
      ))}
    </div>
  );
}
