import { TextField } from 'amn-ui-core';
import React from 'react';

export const CustomTextField = ({ ...props }) => {
  return (
    <TextField
      sx={{ width: 160 }}
      variant="filled"
      size="small"
      {...props}
      InputLabelProps={{ ...props.InputLabelProps, shrink: !!props.value ? true : undefined }}
    />
  );
};
