import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClientContactGrid } from '../ClientContact/ClientContactGrid';
import { gridStateActions } from '../Common/Grid/GridStateManagement/GridState.redux';
import { selectUser } from 'oidc/user.selectors';
import { RecruiterWorkDesk } from '../RecruiterWorkDesk/RecruiterWorkDesk';
import { navigationStickActions } from '../GlobalNavigationMenu/NavigationStick.redux';
import { selectNavigationStick } from '../GlobalNavigationMenu/NavigationStick.selectors';
import { AmWorkDesk } from '../AmWorkDesk';
import { useInjectReducer } from 'utils/redux-injectors';
import { pipelineChoiceSliceKey, pipelineChoiceReducer } from '../Common/WorkDesk/PipelineChoice/pipelineChoice.redux';
import CredentialingWorkDesk from '../CredentialingWorkDesk/CredentialingWorkDesk';
import { selectSearchAttributes } from 'store/redux-store/global-search/selectors';
import { FilterChoice } from 'store/redux-store/global-search/types';
import { PricingWorkDesk } from '@AMIEWEB/PricingWorkDesk/PricingWorkDesk';
import { CoverageWorkDesk } from '@AMIEWEB/AmWorkDesk/CoverageDesk/CoverageWorkDesk';
const useStyles = makeStyles()(theme => ({
  componentContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: '#f5f5f5',
    display: 'fit-content',
  },
}));

// Order of variables are important
export enum HomePage {
  AM_WORK_DESK = 'accountManagementWorkDesk',
  RECRUITER_WORK_DESK = 'recruiterWorkDesk',
  CREDENTIAL = 'credential',
  CREDENTIALING_WORK_DESK = 'credentialingWorkDesk',
  PRICING_WORK_DESK = 'pricingWorkDesk',
  COVERAGE = 'coverage',
}

export const AppHome: React.FC = () => {
  const { classes } = useStyles();
  useInjectReducer({ key: pipelineChoiceSliceKey, reducer: pipelineChoiceReducer });
  const searchAttributes = useSelector(selectSearchAttributes);
  const appUser = useSelector(selectUser);
  const { selectedSubMenu } = useSelector(selectNavigationStick);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(navigationStickActions.setDefaultMenuOptions(appUser.userInfo));
    // Below code ensures home key is highted with broser back btn clicks
    dispatch(navigationStickActions.setSelectedMenuByKey('home'));
    return () => {
      //Action dispatched to reset grid state when performing global search
      dispatch(gridStateActions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid className={classes.componentContainer}>
      {searchAttributes.filterOption === FilterChoice.Candidate && !searchAttributes.searchString ? (
        <>
          {selectedSubMenu?.key === HomePage.RECRUITER_WORK_DESK ? (
            <RecruiterWorkDesk />
          ) : selectedSubMenu?.key === HomePage.AM_WORK_DESK ? (
            <AmWorkDesk />
          ) : selectedSubMenu?.key === HomePage.CREDENTIALING_WORK_DESK ? (
            <CredentialingWorkDesk />
          ) : selectedSubMenu?.key === HomePage.PRICING_WORK_DESK ? (
            <PricingWorkDesk />
          ) : selectedSubMenu?.key === HomePage.COVERAGE ? (
            <CoverageWorkDesk />
          ) : undefined}
        </>
      ) : searchAttributes.filterOption === FilterChoice.ClientContact ? (
        <ClientContactGrid searchString={searchAttributes.searchString} />
      ) : undefined}
    </Grid>
  );
};
