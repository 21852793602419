import moment from "moment";
import { IGetRequestTimeOff } from "./types";
import { IEmrListToTransform } from "app/models/Candidate/CandidateProfile";

export const formatRequestedTimeOffList = (requestTimeOffList: IGetRequestTimeOff[]) => {
    return requestTimeOffList.map((requestItem, index: number) => {
      const noOfDays = getNumberOfDays(new Date(requestItem.startDate), new Date(requestItem.endDate));
      return {
        id: index,
        key: `${formatDate(requestItem.startDate)} - ${formatDate(requestItem.endDate)} (${noOfDays} days)`,
        label: `${formatDate(requestItem.startDate)} - ${formatDate(requestItem.endDate)}`,
        startDate: new Date(formatDate(requestItem.startDate)),
        endDate: new Date(formatDate(requestItem.endDate)),
      };
    });
  };
  
  /**
 * Method to get number of days between start date and end date
 * @param startDate
 * @param endDate
 */
function getNumberOfDays(startDate: Date, endDate: Date) {
    const diffInDays = moment(endDate).diff(moment(startDate), 'day', false);
    return diffInDays+1;
  }
  
  /**
   * Method to convert date to mm/dd/yyyy format
   */
  const formatDate = (date: string) => {
    return moment(date).format('MM/DD/YYYY');
  };

  export const transformEMRData = (transFormEmrList: IEmrListToTransform[]) => {
    return transFormEmrList.map((emrItem: IEmrListToTransform) => ({
      id: emrItem.equipmentID,
      value: emrItem.description,
      name: emrItem.description,
      labelPrefix: 'EMR',
      brandId: emrItem.brandId,
      candidateId: emrItem.candidateId,
    }));
  };
  