import { TypeAheadCustomWrapper } from 'app/components/ClientContact/Common/TypeAheadCustomWrapper';
import { IOptionsList } from 'app/models/Tasks/Tasks';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectActionsList, selectClientContactDetails } from '../store/ClientContact.selector';
import { selectAllOption } from '../Transformers/FormatData';

export const CCAction = ({ selectActionValues, disabled }: { selectActionValues: (e) => void; disabled?: boolean }) => {
  const selectActionsListOptions = useSelector(selectActionsList);
  const selectedClientContactDetails = useSelector(selectClientContactDetails);
  const [options, setoptions] = useState<IOptionsList[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<IOptionsList[]>([]);

  useEffect(() => {
    if (selectedClientContactDetails && selectedClientContactDetails?.actions?.length > 0) {
      const actionsList = [
        ...selectedClientContactDetails?.actions?.map(opt => ({
          id: opt.id?.toString(),
          value: opt.description,
          name: opt.description,
        })),
      ];
      if (selectActionsListOptions?.length - 1 === selectedClientContactDetails?.actions?.length) {
        actionsList?.unshift({ id: '0', name: 'SELECT ALL', value: 'SELECT ALL' });
      }
      setSelectedOptions(actionsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientContactDetails?.contactId, selectActionsListOptions]);

  useEffect(() => {
    setoptions(selectActionsListOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectActionsListOptions]);

  return (
    <React.Fragment>
      <TypeAheadCustomWrapper
        optionsList={options}
        selectAllValue={selectAllOption}
        setSelectedOptions={selectActionValues}
        selectedValues={selectedOptions}
        isDisabled={disabled}
      />
    </React.Fragment>
  );
};
