import React, { useEffect, useState } from 'react';
import { Grid, Skeleton, Typography } from 'amn-ui-core';
import { useDispatch, useSelector } from 'react-redux';
import { serviceActions } from 'store/redux-store/new-placement/async-actions';
import {
  selectCandidate,
  selectCueNoteEmployees,
  selectNewPlacementChoice,
  selectOrder,
} from 'store/redux-store/new-placement/selectors';
import { useFormContext } from 'react-hook-form';
import FormAutoComplete from './FormAutoComplete';
import {
  generateDefaultCuteNoteText,
  getCueNotEmployeeRequestPayload,
  isChecked,
  getDefaultCueNoteOption,
  setupCueNoteData,
} from './helper';
import FormTextArea from './FormTextArea';
import { ICueNoteTransformed, ISelectedCueNoteStatus } from './model';
import { useTranslation } from 'react-i18next';
import FormCheckbox from './FormCheckbox';
import { useReadOnly } from 'oidc/userRoles';

const CueNoteConfirmation = ({ 
  selectedStatus,  
}: { 
  selectedStatus: ISelectedCueNoteStatus | undefined, 
}) => {
  const { watch, getValues } = useFormContext();
  const { readOnly } = useReadOnly();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cueNoteEmployeeList: ICueNoteTransformed[] = useSelector(selectCueNoteEmployees);
  const candidateData = useSelector(selectCandidate);
  const { order } = useSelector(selectNewPlacementChoice);
  const cueNoteData = setupCueNoteData(selectedStatus);
  const isCueNotesDefaultSelected = isChecked(selectedStatus);
  const [defaultCuedToOption, setDefaultCuedToOption] = useState<ICueNoteTransformed | null>(null);
  const isSendCueNoteChecked = watch('shouldSendCueNote');
  const cueToValue = watch('cuedTo');
  const formState = getValues();
  const generateCueNoteText = generateDefaultCuteNoteText(cueNoteData, formState, candidateData, order);
  const newPlacementOrderDetails = useSelector(selectOrder);

  useEffect(() => {
    if (!cueNoteEmployeeList?.length)
      dispatch(serviceActions.getCueNotesEmployee(getCueNotEmployeeRequestPayload(formState)));
  }, []);
  newPlacementOrderDetails?.primaryAccountManagerId
  
  useEffect(() => {
    const orderAccountManagerId = newPlacementOrderDetails?.primaryAccountManagerId;
    if (orderAccountManagerId && cueNoteEmployeeList?.length)
      setDefaultCuedToOption(getDefaultCueNoteOption(cueNoteEmployeeList, orderAccountManagerId));
  }, [newPlacementOrderDetails, cueNoteEmployeeList]);

  return (
    <Grid container item>
      <Grid item>
        <Typography variant="body1" sx={{ color: theme => theme.palette.system.black, fontSize: '14px' }}>
          {t('placement.create.cueNotes.cueNoteTitle', { placementStatus: selectedStatus?.activityStatus })}
        </Typography>
      </Grid>
      <Grid container item>
        <Grid item>
          <FormCheckbox
            name="shouldSendCueNote"
            label={t('placement.create.cueNotes.sendCueNote')}
            defaultChecked={isCueNotesDefaultSelected}
            disabled={readOnly}
          />
        </Grid>
        <Grid container item pl="30px" direction="column" rowGap="20px">
          <Grid item>
            {cueNoteEmployeeList?.length ? (
              <FormAutoComplete
                name="cuedTo"
                label={'Cued To *'}
                options={cueNoteEmployeeList}
                disabled={!isSendCueNoteChecked}
                defaultValue={defaultCuedToOption}
              />
            ) : (
              <Skeleton variant="rectangular" width="100%" height={56} />
            )}
          </Grid>
          <Grid item>
            <FormTextArea
              name="cueNotes"
              id="cue-note-create-placement-input"
              disabled={!isSendCueNoteChecked}
              label={isSendCueNoteChecked && cueToValue?.role !== 'No Cue' ? 'Cue Note*' : 'Cue Note'}
              inputValue={generateCueNoteText}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item pt={'20px'}>
        <Typography
          variant="body1"
          sx={{ color: theme => theme.palette.system.black, fontSize: '14px', fontWeight: 'medium' }}
        >
          {t('placement.create.cueNotes.confirmation')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CueNoteConfirmation;
