/* eslint-disable @typescript-eslint/naming-convention */
import { createAction } from '@reduxjs/toolkit';
import { IConfirmationDoc } from './types';
import { ITemplate } from '../confirmation-template-editor/types';
import { IConfirmationDocPayload } from './async-actions';

const onInitAction = createAction<{ placementId: number }>('ON_INIT_PLACEMENT_CONFIRMATION_TEMPLATE_EDITOR');
const business_OnConfirmationDocClick = createAction<{ card: ITemplate | IConfirmationDoc | null }>(
  'ON_PLACEMENT_CONFIRMATION_DOC_CLICK',
);
const business_switchToDefaultTemplateDraftView = createAction<IConfirmationDocPayload>('PLACEMENT_SWITCH_TO_TEMPLATE');
const business_closeConfirmationEditor = createAction<IConfirmationDocPayload>('PLACEMENT_CLOSE_CONFIRMATION_EDITOR');
const business_downloadSelectedConfirmationtDoc = createAction('PLACEMENT_DOWNLOAD_SELECTED_CLIENT_CONFIRMATION');
const business_updateConfirmationtDocStatusToSent = createAction<{
  placementId: number;
  documentId: string;
}>('PLACEMENT_BUSINESS_UPDATE_CONFIRMATION_DOC_TO_SENT');

export const businessActions = {
  onInitAction,
  business_OnConfirmationDocClick,
  business_switchToDefaultTemplateDraftView,
  business_closeConfirmationEditor,
  business_downloadSelectedConfirmationtDoc,
  business_updateConfirmationtDocStatusToSent,
};
