import { TaskStatus, TaskStatusReason } from 'app/models/Tasks/Tasks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentExternalTask,
  selectInternalTaskDetailsData,
  selectIsExternal,
  selectStatusUpdateId,
  selectTaskComments,
  selectTaskStatusUpdateFlag,
} from '../store/Tasks.selectors';
import WarningIcon from '@mui/icons-material/Warning';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useReadOnly } from 'oidc/userRoles';
import { useStatusUpdateNotification } from '@AMIEWEB/Notification/Tasks/StatusUpdateNotification';
import { taskDetailsActions } from '../store/Tasks.redux';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { CircularProgress, FormControlLabel, Grid, Radio, RadioGroup } from 'amn-ui-core';
import { TaskFormBanner } from '@AMIEWEB/Common';
import { makeStyles } from 'tss-react/mui';
import { colors } from 'styles/styleVariables';
import { getExternalTaskDetails } from 'app/services/TaskServices/TaskServices';
import moment from 'moment-timezone';
import { CheckIdsForCreatedInError } from '../SagaTransformers/formatInternalTaskData';
import { usePromiseTracker } from 'react-promise-tracker';

export const statusModalStyles = makeStyles()(() => ({
  // Modal styles
  modalContainer: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '444px',
      '& .MuiDialogContent-root': {
        overflow: 'initial',
      },
    },
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
  },
  firstGridItem: {
    marginTop: '30px',
  },
  banner: {
    paddingBottom: '1px !important',
  },
  validationLoader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: '50%',
    zIndex: 999,
  },
  refreshModalTitle: {
    fontSize: '20px',
    color: colors.darkText,
    fontWeight: 600,
    textAlign: 'center',
  },
  refreshModalBody: {
    fontSize: '14px',
    color: colors.text,
    marginTop: '10px',
    marginBottom: '8px',
    textAlign: 'center',
  },
  refreshModal: {
    '& .MuiDialogActions-root': {
      justifyContent: 'center',
    },
  },
}));

export const ExternalTaskStatusUpdateModal = ({ open, task }: { open: boolean; task: any }) => {
  const { t } = useTranslation();
  const { classes } = statusModalStyles();
  const dispatch = useDispatch();
  const statusUpdateTask = useSelector(selectStatusUpdateId);
  const taskStatusFlag = useSelector(selectTaskStatusUpdateFlag);
  const detailsBody = useSelector(selectCurrentExternalTask);
  const isExternal = useSelector(selectIsExternal);
  const internalTaskDetails = useSelector(selectInternalTaskDetailsData);
  const commentList = useSelector(selectTaskComments);
  const [serverErrorBanner, setServerErrorBanner] = useState(false);
  const [showRefreshModal, setShowRefreshModal] = useState(false);
  const [showReasons, setShowReason] = useState(false);
  const [latestDetail, setLatestDetail] = useState<any>();
  const [initialStatus, setInitialStatus] = useState(1);
  const [excludeIds, setExcludeIds] = useState<number[]>([]);
  const { promiseInProgress: isCallingTaskdetailsAPI } = usePromiseTracker({ area: 'get-task-details', delay: 0 });
  const { promiseInProgress: isCallingStatusUpdateAPI } = usePromiseTracker({ area: 'update-task-status', delay: 0 });
  const [statusReason, setStatusReason] = useState({
    statusId: undefined,
    statusUpdateReason: '',
  } as any);
  const { readOnly } = useReadOnly();

  const formMethods = useForm({
    mode: 'onSubmit',
  });
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { isDirty },
  } = formMethods;

  useStatusUpdateNotification();

  const handleConfirmClick = async () => {
    if (!readOnly) {
      handleSubmit(submitTask)().catch(err => {
        setServerErrorBanner(true);
      });
    }
  };

  const submitTask = async formData => {
    const statusUpdate = {
      ...statusReason,
      excludeTaskIds: statusReason?.statusUpdateReasonId === 5 ? [] : excludeIds,
      statusUpdateReason:
        formData.statusUpdateReason === TaskStatusReason.OTHER ? formData.otherReason : statusReason.statusUpdateReason,
      taskId: statusUpdateTask?.taskId,
    };
    if (statusUpdate?.statusId === TaskStatus.Completed) {
      statusUpdate.statusUpdateReason = t('notification.taskStatusModal.statusLabels.successfullComplete');
    } else if (statusUpdate?.statusId === TaskStatus.Cancelled && statusUpdate?.statusUpdateReason === '') {
      statusUpdate.statusUpdateReason = t('notification.taskStatusModal.statusLabels.createdError');
    }
    statusUpdate.categorySubType1 = detailsBody.categorySubType1;
    statusUpdate.categorySubType2 = detailsBody.categorySubType2;
    dispatch(taskDetailsActions.updateExternalTaskStatus({ update: statusUpdate, initialStatus: initialStatus }));
  };

  const onCloseCall = () => {
    onClose();
    dispatch(taskDetailsActions.setDetailsModalOpen(false));
  };

  const onCloseDefault = () => {
    onClose();
    dispatch(taskDetailsActions.setDetailsModalOpen(true));
  };

  const onClose = () => {
    dispatch(taskDetailsActions.setShowTaskStatusModal(undefined));
    dispatch(taskDetailsActions.setTaskStatusUpdateFlag(undefined));
    reset({});
  };

  const disableSubmit = () => {
    return !isDirty;
  };

  async function fetchLatest() {
    const latestData: any = await getExternalTaskDetails(detailsBody);
    setLatestDetail(latestData.data);
  }

  useEffect(() => {
    const createdByErrorIds = CheckIdsForCreatedInError(internalTaskDetails, commentList);
    setExcludeIds(createdByErrorIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showReasons) {
      setValue('taskReason', t('notification.taskStatusModal.cancelReason.candidateUnresponsive'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showReasons]);

  useEffect(() => {
    setServerErrorBanner(false);
    if (!statusUpdateTask) {
      onCloseDefault();
      dispatch(
        globalActions.setSnackBar({
          message: t('notification.createTask.taskEditSuccess'),
          severity: 'success',
        }),
      );
    } else if (taskStatusFlag === 'ERROR') {
      setServerErrorBanner(true);
      dispatch(
        globalActions.setSnackBar({
          message: t('notification.createTask.taskEditFailure'),
          severity: 'error',
        }),
      );
      dispatch(taskDetailsActions.setStatusAppliedAll(false));
    } else {
      if (taskStatusFlag === 'SHOWMODAL' && isExternal) {
        setShowRefreshModal(true);
      }
    }
    setInitialStatus(statusUpdateTask?.statusId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusUpdateTask, taskStatusFlag]);

  useEffect(() => {
    if (showRefreshModal) fetchLatest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showRefreshModal]);

  return (
    <>
      {!showRefreshModal ? (
        <FormProvider {...formMethods}>
          <GenericDialog
            open={open}
            fullWidth
            onClose={onCloseCall}
            variant="blue"
            className={classes.modalContainer}
            dialogTitleProps={{
              text: t('notification.taskStatusModal.modalTitle'),
              closeButton: true,
            }}
            dialogActions={[
              {
                text: t('notification.taskStatusModal.cancelBtn'),
                variant: 'contained',
                color: 'tertiary',
                useDialogOnCloseEvent: true,
              },
              {
                text: t('notification.taskStatusModal.submitBtn'),
                variant: 'contained',
                disabled: readOnly || disableSubmit() || isCallingTaskdetailsAPI || isCallingStatusUpdateAPI,
                tooltipProps: {
                  tooltipContent: t('global.readOnlyTooltip'),
                  disabled: !readOnly,
                },
                onClick: async e => {
                  handleConfirmClick();
                },
              },
            ]}
          >
            <Grid container spacing={5} data-testid="task-status-update-modal-container">
              {(isCallingTaskdetailsAPI || isCallingStatusUpdateAPI) && (
                <CircularProgress className={classes.validationLoader} />
              )}
              {serverErrorBanner && (
                <Grid item xs={12} className={classes.banner}>
                  <TaskFormBanner message={t('notification.taskStatusModal.apiCallError')} />
                </Grid>
              )}
              <Grid item xs={12} className={classes.firstGridItem} data-testid="task-status-radio-btns-group">
                <Controller
                  control={control}
                  name={'taskStatus'}
                  rules={{ required: true }}
                  render={({ ref, onChange, ...rest }) => (
                    <RadioGroup aria-labelledby="task-status-update-modal-group" name="task-status-update-modal">
                      <FormControlLabel
                        value={4}
                        checked={
                          statusReason.statusId === 4 && statusReason.statusUpdateReasonId === TaskStatusReason.ERROR
                        }
                        control={<Radio />}
                        label={t('notification.taskStatusModal.statusLabels.createdError')}
                        data-testid="task-status-radio-btn-createdError"
                        onChange={e => {
                          setStatusReason(status => ({
                            statusId: 4,
                            statusUpdateReason: t('notification.taskStatusModal.statusLabels.createdError'),
                            statusUpdateReasonId: TaskStatusReason.ERROR,
                          }));
                          setShowReason(false);
                          onChange(4);
                        }}
                      />
                    </RadioGroup>
                  )}
                />
              </Grid>
            </Grid>
          </GenericDialog>
        </FormProvider>
      ) : (
        <GenericDialog
          open={showRefreshModal}
          className={classes.refreshModal}
          dialogActions={[
            {
              text: t('notification.taskStatusModal.refreshModal.btn'),
              variant: 'contained',
              onClick: () => {
                dispatch(taskDetailsActions.setTaskStatusUpdateFlag('REFRESH'));
                setShowRefreshModal(false);
              },
            },
          ]}
        >
          <div className={classes.flexContainer}>
            <WarningIcon style={{ fill: '#C9010D', height: '60px', width: '60px', marginBottom: '30px' }} />
            <div className={classes.refreshModalTitle}>{t('notification.taskStatusModal.refreshModal.title')}</div>
            <div className={classes.refreshModalBody}>
              {latestDetail?.statusUpdatedByName +
                ', ' +
                moment.tz(latestDetail?.statusUpdatedDate, 'America/Los_Angeles').format('L hh:mm [PST]') +
                ' '}
              {t('notification.taskStatusModal.refreshModal.body')}
            </div>
          </div>
        </GenericDialog>
      )}
    </>
  );
};
