/* eslint-disable i18next/no-literal-string */
import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { Grid, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { missingField } from 'app/constants';

const useStyles = makeStyles()({
  root: {
    padding: '12px',
  },
  table: {
    border: `1px solid lightgray`,
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    backgroundColor: '#F5F5F5',
  },
  td: {
    textAlign: 'left',
    padding: '3px',
    paddingLeft: '12px',
    border: `1px solid lightgray`,
  },
});

// TODO: @RIJASH - MOVE THIS LOGIC TO SAGA, Due Date: 1st Nov 2022
const gradeAverage = (list: any[]) => {
  let average = 0;
  if (list.length > 0) {
    list?.forEach(item => {
      average += item.RatingTypeId;
    });
    return Math.ceil(average / list.length);
  }
  return 0;
};

// TODO: @RIJASH - MOVE THIS LOGIC TO SAGA, Due Date: 1st Nov 2022
const letterGrade = ratingTypeId => {
  switch (ratingTypeId) {
    case 1:
      return 'A';
    case 2:
      return 'B';
    case 3:
      return 'C';
    case 4:
      return 'D';
    default:
      return missingField;
  }
};

export const EvaluationsCellHoverview = params => {
  return <EvaluationsCellHoverviewContent params={params} />;
};

const EvaluationsCellHoverviewContent = ({ params }: { params: GridCellParams }) => {
  const { classes, cx } = useStyles();

  return (
    <>
      {((params.row[params.field] ?? []) as any[]).length > 0 && (
        <Grid container spacing={2} className={classes.root}>
          <Grid item xs={12}>
            <Typography variant="body2" style={{ fontWeight: 500 }}>{`Evaluation Average: ${letterGrade(
              gradeAverage((params.row[params.field] ?? []) as any[]),
            )}`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <table className={classes.table}>
              <tr>
                <th className={cx(classes.td, classes.th)}>
                  <Typography variant="body2">Category</Typography>
                </th>
                <th className={cx(classes.td, classes.th)}>
                  <Typography variant="body2">Rating</Typography>
                </th>
              </tr>
              {((params.row[params.field] ?? []) as any[])?.map((item, i) => (
                <tr key={i}>
                  <td className={classes.td}>
                    <Typography variant="body2">{item.categoryType}</Typography>
                  </td>
                  <td className={classes.td}>
                    <Typography variant="body2">{`${letterGrade(item.RatingTypeId)} - ${item.ratingType}`}</Typography>
                  </td>
                </tr>
              ))}
            </table>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export const EvaluationsCell = params => {
  return <EvaluationsCellContent params={params} />;
};

const EvaluationsCellContent = ({ params }: { params: GridCellParams }) => {
  return <div>{letterGrade(gradeAverage((params.row[params.field] ?? []) as any[]))}</div>;
};
