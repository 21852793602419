import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Avatar, FormControl, InputLabel, MenuItem, Select } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectActiveCoverage } from 'store/redux-store/user-coverage/selectors';
import { makeStyles } from 'tss-react/mui';
import { formatEmployeeName, getInitials } from './FormatData';
import { Controller, useFormContext } from 'react-hook-form';
import { selectUser } from 'oidc/user.selectors';

const useStyles = makeStyles()(theme => ({
  inputLabel: {
    fontSize: '12px',
    color: theme.palette.framework.system.doveGray,
    '&.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '&:focus': {
      color: `${theme.palette.framework.system.doveGray} !important`,
    },
  },
  oboName: {
    paddingLeft: '8px',
    marginRight: '20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  oboCheckmark: {
    marginLeft: 'auto',
    width: '18px',
    color: theme.palette.primary.main,
  },
}));

export const SelectWithAvatar = props => {
  const { selectedCoverageOption, handleCoverageSelection } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const userInfo = useSelector(selectUser);
  const activeCoverage = useSelector(selectActiveCoverage);
  const { setValue, control, errors, clearErrors } = useFormContext();

  const filterUniqueCoverage = () => {
    const activeUserWithCoverage = [
      {
        id: userInfo?.userInfo?.employeeId,
        coveredEmployeeFirstName: userInfo?.userInfo?.firstName,
        coveredEmployeeLastName: userInfo?.userInfo?.lastName,
        coveredEmployeeId: userInfo?.userInfo?.employeeId,
      },
      ...activeCoverage,
    ];
    const uniqueCoverageList = new Set();
    return activeUserWithCoverage.filter(employee => {
      if (uniqueCoverageList.has(employee.coveredEmployeeId)) {
        return false;
      } else {
        uniqueCoverageList.add(employee.coveredEmployeeId);
        return true;
      }
    });
  };

  const activeCoverageList = filterUniqueCoverage();

  return (
    <Controller
      control={control}
      name={'onBehalfOf'}
      rules={{
        required: true,
      }}
      render={({ ref, onChange, ...rest }) => (
        <FormControl size="small" sx={{ minWidth: '186px' }}>
          <InputLabel id="obo-select-label" className={classes.inputLabel} error={errors.onBehalfOf}>
            {t('notification.createTask.oboOf')}
          </InputLabel>

          <Select
            variant="outlined"
            color="primary"
            IconComponent={ExpandMoreOutlinedIcon}
            onChange={e => {
              setValue('onBehalfOf', e.target.value, { shouldDirty: true });
              clearErrors('onBehalfOf');
            }}
            renderValue={selected => {
              const selectedItem = activeCoverageList?.find(item => item?.id === selected);
              return formatEmployeeName(selectedItem?.coveredEmployeeFirstName, selectedItem?.coveredEmployeeLastName);
            }}
            labelId="obo-select-label"
            label={t('notification.createTask.oboOf')}
            MenuProps={{
              sx: { maxHeight: '300px' },
            }}
            error={errors?.onBehalfOf}
          >
            {activeCoverageList?.map((item, index) => {
              const empName = formatEmployeeName(item.coveredEmployeeFirstName, item.coveredEmployeeLastName);
              return (
                <MenuItem
                  sx={{ maxWidth: '200px' }}
                  disableTouchRipple
                  key={index}
                  value={item.id}
                  aria-labelledby={item.id}
                  onClick={() => handleCoverageSelection(item)}
                >
                  {
                    <>
                      <Avatar
                        sx={theme => ({
                          width: '24px',
                          height: '24px',
                          background:
                            index === 0
                              ? theme.palette.system.violet
                              : index % 2 === 1
                              ? theme.palette.system.cyanBlue
                              : theme.palette.system.lightSkyBlue,
                          color: theme.palette.components.avatar.color,
                          fontSize: '12px !important',
                        })}
                      >
                        {getInitials(empName)}
                      </Avatar>
                      <span className={classes.oboName}>{` ${empName}`}</span>
                      {selectedCoverageOption === item.id && <CheckIcon className={classes.oboCheckmark} />}
                    </>
                  }
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    />
  );
};
