/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui';
import { CustomFilter } from 'app/ComponentLibrary/Filter';
import { SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import { getPlacementStatusTreeOptions, getSearchFilterOptions } from 'app/services/SharedServices/SharedServices';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse, Grid } from 'amn-ui-core';
import { SearchPageIcons } from 'app/ComponentLibrary/Filter/SearchPageIcons';
import { gridStateActions } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { selectGridFiltersExpanded } from '../../Common/Grid/GridStateManagement/GridState.selectors';
import { getMultiSelectOptions } from '@AMIEWEB/GlobalSearch/helper';
import { AssociatedPlacementsFilter } from './FilterUtils';
import { useFormContext } from 'react-hook-form';
import { associatedPlacementActions } from './store/associatedPlacements.redux';
import { PlacementSearchFilterName } from '@AMIEWEB/GlobalSearch/Placement/SearchUtils';
import { ff_placement_region_fields } from 'app/constants/FeatureFlags/Placement/Keys';
import { useDecision } from '@optimizely/react-sdk';

const useStyles = makeStyles()({
  accountManagerField: {
    minWidth: 200,
  },
  placementRecruiterField: {
    minWidth: 155,
  },
  archiveOptsField: {
    minWidth: 126,
  },
  amDivisionField: {
    minWidth: 126,
  },
  affiliateField: {
    minWidth: 130,
  },
  fillterCollapse: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '3px',
  },
  collapse: {
    paddingTop: '4px',
  },
});

export const PlacementsFilters = ({ defaultFilterValues, saveFilterPreferences }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { reset, setValue } = useFormContext();
  const [regionFlag] = useDecision(ff_placement_region_fields);
  const gridSpecs = useRef(
    new AssociatedPlacementsFilter(
      {
        statusOpts: [],
        extensionOpts: [],
        regionOpts: [],
      },
      t,
      regionFlag.enabled,
    ),
  );
  const [fields, setFields] = useState<any>(gridSpecs.current.getPlacementsFilterSpecs());
  const fetchPlacementFilterSpecs = async () => {
    getSearchFilterOptions('extensionStatuses', true).then(res => {
      gridSpecs.current.filterSpecs.extensionOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getPlacementsFilterSpecs();
      setFields(fieldsSchema);
    });
    getPlacementStatusTreeOptions().then(res => {
      gridSpecs.current.filterSpecs.statusOpts = res || [];
      const fieldsSchema = gridSpecs.current.getPlacementsFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('orderplacementregions', true).then(res => {
      gridSpecs.current.filterSpecs.regionOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getPlacementsFilterSpecs();
      setFields(fieldsSchema);
    });
  };

  const resetFilter = () => {
    dispatch(
      gridStateActions.setFilterAttributes({
        filterObject: Object.entries(defaultFilterValues),
        selectedChipFilters: null,
      }),
    );
    reset({ ...defaultFilterValues });
    setValue(PlacementSearchFilterName.pid, []);
    setValue(PlacementSearchFilterName.oid, []);
    setValue(PlacementSearchFilterName.facility, []);
    setValue(PlacementSearchFilterName.extensionStatus, []);
    setValue(PlacementSearchFilterName.startDate, null);
    setValue(PlacementSearchFilterName.endDate, null);
    setValue(PlacementSearchFilterName.recruiter, []);
    setValue(PlacementSearchFilterName.accountManager, []);
    setValue(PlacementSearchFilterName.credentialingAnalyst, []);
    setValue(PlacementSearchFilterName.placementCuedTo, null);
    setValue(PlacementSearchFilterName.region, null);
    setTimeout(() => {
      dispatch(associatedPlacementActions.setApplyFilter(true));
    }, 300);
    if (saveFilterPreferences) {
      saveFilterPreferences(defaultFilterValues);
    }
  };

  const filtersExpanded = useSelector(selectGridFiltersExpanded);
  const handleChange = () => {
    dispatch(gridStateActions.setFiltersExpanded(!filtersExpanded));
  };

  useEffect(() => {
    trackPromise(fetchPlacementFilterSpecs(), 'global-search-call');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={11}>
        <Collapse timeout={200} in={filtersExpanded} collapsedSize={45} className={classes.collapse}>
          <CustomFilter
            fields={fields}
            justifyContent={'flex-start'}
            version2
            resetBtn
            analytics={{
              caller: t('search.globalSearch.globalSearchFilter'),
              onApply: true,
              properties: {
                category: SearchType.placement,
              },
            }}
          />
        </Collapse>
      </Grid>
      <Grid item xs={1} className={classes.fillterCollapse}>
        <SearchPageIcons
          resetlbutton
          funnelButton
          onResetForm={() => resetFilter()}
          onAdvancedFilterClick={() => {
            handleChange();
          }}
          checked={filtersExpanded}
        />
      </Grid>
    </Grid>
  );
};
