import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getColumns } from './utils';
import { AMNModalGrid } from 'app/components/Common/AMNModalGrid';
import { useDecision } from '@optimizely/react-sdk';
import { ff_placement_ui_requirement_check } from 'app/constants/FeatureFlags/Placement/Keys';
import { GridType } from '@AMIEWEB/Common/types';

export const ReferencesModal = ({
  referenceRecords: rows,
  filterModel,
  onFilterModelChange,
  refreshData,
  serviceTrackerId = '',
  sectionName,
  gridHeight = 0,
  primaryModal = false,
  gridLabel = false,
  numberOfMonth = 0,
}) => {
  const { t } = useTranslation();
  const columns = getColumns(t, sectionName);
  const [requirementCheckFeatureFlag] = useDecision(ff_placement_ui_requirement_check);
  const [translationKey, setTranslationKey] = useState<string>();
  useEffect(() => {
    if (requirementCheckFeatureFlag.enabled) {
      setTranslationKey(
        primaryModal ? 'placement.profile.tabs.primaryReferanceGrid' : 'placement.profile.tabs.referencesGrid',
      );
    } else {
      setTranslationKey('placement.profile.tabs.referanceGridDefault');
    }
  }, []);

  return (
    <AMNModalGrid
      rows={rows}
      filterModel={filterModel}
      onFilterModelChange={onFilterModelChange}
      refreshData={refreshData}
      serviceTrackerId={serviceTrackerId}
      gridHeight={gridHeight}
      columnsParams={columns}
      translationKey={translationKey}
      defaultSortColumn={'referenceDate'}
      XGRID_COLUMN_SORT_DIRECTION={'desc'}
      marginBottom={'36px'}
      gridLabel={gridLabel}
      numberOfMonth={numberOfMonth}
      gridType={GridType.references}
    />
  );
};
