import { Chip, Grid, Typography } from 'amn-ui-core';
import React from 'react';
import { ContractStatus } from 'store/redux-store/placement-candidate-contract-doc-editor/types';
import { makeStyles } from 'tss-react/mui';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ClassicDocumentIcon from 'app/assets/images/IconImages/ClassicDocumentIcon.svg';
import PassportIcon from 'app/assets/images/CandidateHeader/Passport-enabled.svg';
import { colors } from 'styles/styleVariables';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import { CustomTooltip } from '@AMIEWEB/Common';
import { useTranslation } from 'react-i18next';
import { missingField } from 'app/constants';
import { ConfirmationStatus } from 'store/redux-store/placement-client-confirmation-doc-editor/types';

export const useDocCardStyles = makeStyles<{ isCardClickable: boolean }>()((theme, { isCardClickable }) => ({
  container: {
    borderRadius: 4,
    padding: 12,
    backgroundColor: isCardClickable
      ? theme.palette.components.grid.content.backgroundColor
      : theme.palette.system.grayShaded,
    border: `1px solid ${theme.palette.system.borderColor1}`,
    '&:hover': isCardClickable
      ? {
          backgroundColor: theme.palette.system.cardBackground,
          cursor: 'pointer',
        }
      : undefined,
  },
  selectedContainer: {
    backgroundColor: theme.palette.system.cardBackground,
    border: `2px solid ${theme.palette.system.navyBlue}`,
  },
  icon: {
    color: theme.palette.system.darkGray,
  },
  selected: {
    color: colors.amnBlue,
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 14,
    whiteSpace: 'nowrap',
    maxWidth: 275,
  },
  updatedContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: '4px',
  },
}));

export const DocumentCard = ({
  card,
  isCardClickable,
  isCreatedFromClassic,
  isSelected,
  publishedToPassport = false,
  datesComponent,
  ...props
}) => {
  const { classes, cx, t } = props;

  const [openBubbleTooltip, setBubbleTooltip] = React.useState(false);
  const handleHover = React.useCallback(
    state => {
      if (!isCardClickable) setBubbleTooltip(state);
    },
    [isCardClickable],
  );

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      classes={{ root: cx(classes.container, { [classes.selectedContainer]: isSelected }) }}
      component="div"
      onClick={isCardClickable ? props.onClick : undefined}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      <Grid item sx={{ paddingTop: 1 }}>
        {isCreatedFromClassic ? (
          <img src={ClassicDocumentIcon} alt="ClassicDocumentIcon" />
        ) : (
          <DescriptionOutlinedIcon classes={{ root: cx(classes.icon, { [classes.selected]: isSelected }) }} />
        )}
      </Grid>
      <Grid item sx={{ width: '100%' }}>
        <Grid container>
          <Grid item xs={12} sx={{ marginLeft: 1 }}>
            <Grid container direction="row" justifyContent="space-between" wrap="nowrap">
              <Grid item display="flex" sx={{ alignItems: 'flex-end' }} alignContent="flex-end" flexWrap="wrap" gap={1}>
                <Typography
                  color={!isSelected ? 'system.darkGray' : 'system.navyBlue'}
                  classes={{ root: classes.title }}
                >
                  {card.version}
                </Typography>
                {publishedToPassport ? (
                  <CustomTooltip
                    tooltipContent={t('placement.profile.tabs.documentTab.publishedToPassport')}
                    value={<img src={PassportIcon} alt="passport" />}
                    standardMargin
                  />
                ) : null}
                <UpdateWarningFlag
                  updateDateString={card.view.cardUpdateDateString}
                  isDraftManualEdit={card.isDraftManualEdit}
                />
              </Grid>
              <Grid item>
                {isCardClickable ? (
                  <StatusBubble status={card.status} cardType={props.cardType} />
                ) : (
                  <CustomTooltip
                    tooltipContent={t('templateEditor.disableToolTip.disabledDueToStatus')}
                    open={openBubbleTooltip}
                    placement="bottom-end"
                    isStandardContent
                    standardMargin
                  >
                    <StatusBubble status={card.status} cardType={props.cardType} />
                  </CustomTooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
          {datesComponent}
        </Grid>
      </Grid>
    </Grid>
  );
};

export const CardDate = ({ title, name = undefined, date, classes }) => (
  <Grid classes={{ root: classes.updatedContainer }} item xs={12}>
    <Typography
      variant="body2"
      component={'span'}
      sx={theme => ({ color: theme.palette.framework.system.doveGray, paddingRight: '2px' })}
    >
      <Typography
        variant="body2"
        component={'span'}
        fontWeight={'medium'}
        sx={theme => ({ color: theme.palette.framework.system.doveGray, paddingRight: '2px' })}
      >
        {title}
      </Typography>
      {name ?? date ?? missingField}
    </Typography>
    {name ? (
      <Typography
        sx={theme => ({ whiteSpace: 'preline', color: theme.palette.framework.system.doveGray })}
        variant="body2"
        component={'span'}
      >
        {`(${date})`}
      </Typography>
    ) : null}
  </Grid>
);

export const UpdateWarningFlag = ({ isDraftManualEdit, updateDateString }) => {
  const { t } = useTranslation();
  return isDraftManualEdit ? (
    <CustomTooltip
      tooltipContent={
        <>
          <Typography
            variant="body2"
            component={'div'}
            sx={theme => ({ color: theme.palette.framework.system.doveGray })}
          >
            {t(`templateEditor.flagTooltip.lineOne`)}
          </Typography>
          <Typography
            fontWeight={'medium'}
            variant="body2"
            component={'span'}
            sx={theme => ({ color: theme.palette.framework.system.doveGray })}
          >
            {t('templateEditor.flagTooltip.lineTwo')}
          </Typography>
          <Typography
            variant="body2"
            component={'span'}
            sx={theme => ({ color: theme.palette.framework.system.doveGray })}
          >
            {updateDateString}
          </Typography>
        </>
      }
    >
      <OutlinedFlagIcon sx={{ color: 'system.orangeWarning' }} />
    </CustomTooltip>
  ) : null;
};

export const StatusBubble: React.FC<{ status; cardType?: 'confirmation-doc' | 'contract-doc' }> = ({
  status,
  cardType = 'contract-doc',
}) => {
  const { blueBubble, greenBubble, orangeBubble } = React.useMemo(
    () =>
      cardType === 'confirmation-doc'
        ? {
            greenBubble: status === ConfirmationStatus.sent,
            blueBubble: [ConfirmationStatus.draft, ConfirmationStatus.preDraft].includes(status),
            grayBubble: [ConfirmationStatus.notSent, ConfirmationStatus.pending].includes(status),
            orangeBubble: status === ConfirmationStatus.okToSend,
          }
        : {
            greenBubble: status === ContractStatus.signed,
            blueBubble: status === ContractStatus.draft,
            grayBubble: [ContractStatus.publishedVoid, ContractStatus.pending, ContractStatus.requestContract].includes(
              status,
            ),
            orangeBubble: [ContractStatus.created, ContractStatus.published, ContractStatus.okToSend].includes(status),
          },
    [cardType, status],
  );
  return (
    <Chip
      variant={blueBubble ? 'new' : greenBubble ? 'approved' : orangeBubble ? 'pending' : 'canceled'}
      label={status}
    />
  );
};
