import React, { useEffect, useState } from 'react';
import ErrorIcon from 'app/assets/images/Notification/deliverError.svg';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { CapitalizeEveryFirstLetter } from 'utils/string/string';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { useSelector } from 'react-redux';
import ReplyIcon from '@mui/icons-material/Reply';
import { selectEmailDeliveryAssociateRecords } from '@AMIEWEB/Tasks/store/Tasks.selectors';
import { HubEventType } from '../Constants';

const XGridDeliveryStatusCell = (displayKey: string) => (params: GridCellParams) => {
  const [associatedRecords, setAssociatedRecords] = useState<string[]>([]);
  const errorFields = ['bounce', 'dropped', 'blocked', 'spam report', 'unsubscribed'];
  const deliveryStatus = params?.row[displayKey];
  const error = errorFields?.includes(deliveryStatus);
  const [resent, setResent] = useState<boolean>(false);

  const failingReason = (deliveryStatus: string): string => {
    let failingReason = '';
    switch (deliveryStatus?.toLowerCase()) {
      case HubEventType.EmailBlocked:
        failingReason = 'The receiving server denied the message.';
        break;
      case HubEventType.EmailBounced:
        failingReason = 'The receiving server denied the message.';
        break;
      case HubEventType.EmailSpam:
        failingReason = 'The recipient marked the email as spam.';
        break;
      case HubEventType.EmailUnsubscribed:
        failingReason = 'The recipient unsubscribed to emails.';
        break;
      default:
        failingReason =
          'The email address is invalid, has unsubscribed, or the recipient previously marked these emails as spam.';
        break;
    }
    return failingReason;
  };
 
  const emailAssociatedRecords = useSelector(selectEmailDeliveryAssociateRecords);

  useEffect(()=>{
    if(associatedRecords?.includes(params?.row?.email)){
      setResent(true);
    }
  },[params])

  useEffect(()=>{
    const assocRecords:string[] = [];
    emailAssociatedRecords?.forEach((assocRecord)=>{
      if(assocRecord?.name === 'ForwardedTo'){
        assocRecords.push(assocRecord?.value);
      }
    })
    setAssociatedRecords([...assocRecords]);
  },[emailAssociatedRecords])

  return (
      <div> 
      <CustomTooltip arrow tooltipContent={error ? `${failingReason(deliveryStatus)}` : ''}>
      <span>
          <div style={{ display: 'flex' }}>
            <p style={error ? { color: '#CC0000' } : { color: '#333333', fontWeight: 'normal' }}>
              {CapitalizeEveryFirstLetter(deliveryStatus)}
            </p>
            {error && <img src={ErrorIcon} alt={`delivery error`} style={{ margin: '0 0 3px 2px' }} />}
            {resent && <p><ReplyIcon style={{transform: 'rotateY(180deg)', color : '#CBCBCB', paddingBottom: '5px'}}/></p>}
          </div>
        </span>
      </CustomTooltip>
    </div>
  );
};

export default XGridDeliveryStatusCell;
