import React, { useEffect } from 'react';
import { TextField } from 'amn-ui-core';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  text: {
    maxHeight: 450,
    overflow: 'auto',
  },
}));

const FormTextArea = ({
  id,
  disabled,
  label,
  inputValue,
  name,
}: {
  id: string;
  name: string;
  disabled: boolean;
  label: string;
  inputValue: string;
}) => {
  const { classes } = useStyles();
  const { control, setValue } = useFormContext();
  const handleChange = event => {
    setValue(name, event.target.value);
  };

  const handleClear = () => {
    setValue(name, '');
  };

  useEffect(() => {
    if (inputValue) setValue(name, inputValue);
  }, [inputValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ ref, onChange, value, ...rest }) => (
        <TextField
          className={classes.text}
          id={id}
          label={label}
          multiline
          minRows={3}
          defaultValue="Default Value"
          variant="filled"
          fullWidth
          value={value}
          disabled={disabled}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{ position: 'absolute', right: 0, top: '18px' }}>
                <IconButton
                  disabled={disabled}
                  onClick={handleClear}
                  sx={{
                    '&:hover': {
                      color: theme => theme.palette.system.darkRed,
                    },
                  }}
                >
                  <CloseIcon sx={{ height: '20px' }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{
            maxLength: 4000,
          }}
        />
      )}
    />
  );
};

export default FormTextArea;
