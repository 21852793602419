import { Grid } from 'amn-ui-core';
import React, { useContext } from 'react';
import { SearchField as CandidateSearchField } from '../CreatePlacement/Candidate/CandidateSearch';
import { SearchField as OrderSearchField } from '../CreatePlacement/Order/OrderSearch';
import { CreatePlacementContext } from '../CreatePlacement/CreatePlacementWrapper';

export const CandidateOrderSelector = () => {
  const { isDefaultCandidate, isDefaultOrder } = useContext(CreatePlacementContext);
  return (
    <Grid container spacing={2}>
      <Grid item sx={{ minWidth: 315 }}>
        <CandidateSearchField
          isDefaultCandidate={isDefaultCandidate}
          isDefaultOrder={isDefaultOrder}
          isCandidateRefreshRequest={false}
          useNewCreatePlacementModalFunctions
          sessionKey=""
          variant="filled"
          label="Candidate"
          size="small"
        />
      </Grid>
      <Grid item sx={{ minWidth: 315 }}>
        <OrderSearchField
          isDefaultCandidate={isDefaultCandidate}
          isDefaultOrder={isDefaultOrder}
          isOrderRefreshRequest={false}
          sessionKey=""
          variant="filled"
          label="Order ID"
          size="small"
        />
      </Grid>
    </Grid>
  );
};
