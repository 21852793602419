import { candidateDetailsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { selectUnit } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';
import { selectFacility } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { searchNotificationData } from '@AMIEWEB/GlobalSearch/helper';
import { UseType, SendType } from '@AMIEWEB/Notification/Constants';
import { selectOrderDetailsUIView } from '@AMIEWEB/Order/Store/Order.selectors';
import { EmailDefaultValues } from 'app/models/Email/IEmail';
import { SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import { IToken } from 'app/models/Notification/Notification';
import _ from 'lodash';
import { selectUser } from 'oidc/user.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { lookupSelector } from 'store/redux-store/lookup/lookup.selectors';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { selectPlacementDetails } from 'store/redux-store/placement-details/selectors';
import { Concatenate } from 'utils/string/string';

export const useHeaderOptions = (searchOption?: string) => {
  const history = useLocation();
  const currentUrl = window.location.href?.split('?')[0];
  const searchType = searchOption || history.pathname.split('/')[1];
  const user = useSelector(selectUser);
  const candidateData = useSelector(candidateDetailsSelection);
  const placementData = useSelector(selectPlacementDetails);
  const orderData = useSelector(selectOrderDetailsUIView);
  const facilityData = useSelector(selectFacility);
  const notificationData = useSelector(notificationSelection);
  const notificationUser = searchNotificationData(user);
  const lookupData = useSelector(lookupSelector);
  const disciplineLookup = lookupData.disciplines;
  const unitData = useSelector(selectUnit);
  const dispatch = useDispatch();

  const getSkillSets = () => {
    const verifiedSkills = candidateData.groupedSkillsSets.filter(item => item?.isVerified) || [];
    const skills = verifiedSkills?.map(item => {
      const disciplineDescription = disciplineLookup.find(ele => ele?.object?.Abbreviation == item?.disciplineAbbr)
        ?.object?.Description;
      const specialties: any[] = item?.specialties.map(key => key?.specialtyAbbr);
      return `${disciplineDescription} (${specialties.join(',')})`;
    });
    return skills;
  };

  const getEmailSubject = () => {
    switch (searchType) {
      case SearchType.candidate:
        const skills = getSkillSets();
        return `${candidateData.travelerId && `Candidate ID: ${candidateData.travelerId || ''}`}${
          (candidateData.name.first || candidateData.name.last) &&
          `   Name: ${Concatenate([candidateData.name.first, candidateData.name.last], ' ')}`
        }${candidateData.brand && `   Brand: ${candidateData.brand || ''}`}${
          skills?.length > 0 && `   Skill Set: ${skills?.join(',')}`
        }${
          candidateData.recruiter.first
            ? `   Recruiter: ${Concatenate(
                [candidateData.recruiter.first || '', candidateData.recruiter.last || ''],
                ' ',
              )}`
            : ''
        }`;
      case SearchType.placement:
        return `${placementData.header.placementId && `Placement ID: ${placementData.header.placementId || ''}`}${
          (placementData.header.candidate.firstName || placementData.header.candidate.lastName) &&
          `   Candidate: ${
            Concatenate(
              [placementData.header.candidate.firstName || '', placementData.header.candidate.lastName || ''],
              ' ',
            ) || ''
          } (${placementData.header.candidate.id || ''})`
        }${placementData.header.placementUnit && `   Unit Name: ${placementData.header.placementUnit || ''}`}${
          placementData.header.facility.name &&
          `   Facility Name: ${placementData.header.facility.name || ''} (${placementData.header.facility.id})`
        }`;
      case SearchType.order:
        return `${orderData.orderDetails.orderId && `Order ID: ${orderData.orderDetails.orderId || ''}`}${
          orderData.orderDetails.unit.name && `   Unit Name: ${orderData.orderDetails.unit.name || ''}`
        }${
          orderData.orderDetails.facility.facilityId &&
          `   Facility ID: ${orderData.orderDetails.facility.facilityId || ''}`
        }${orderData.orderDetails.facility.name && `   Facility Name: ${orderData.orderDetails.facility.name || ''}`}`;
      case SearchType.facility:
        return `${
          facilityData.facilityResponse.facilityId && `Facility ID: ${facilityData.facilityResponse.facilityId || ''}`
        }${
          facilityData.facilityResponse.facilityName && `   Name: ${facilityData.facilityResponse.facilityName || ''}`
        }${
          (facilityData.facilityResponse.employeeContacts.accountManager?.firstName ||
            facilityData.facilityResponse.employeeContacts.accountManager?.lastName) &&
          `   AM: ${Concatenate(
            [
              facilityData.facilityResponse.employeeContacts.accountManager?.firstName || '',
              facilityData.facilityResponse.employeeContacts.accountManager?.lastName || '',
            ],
            ' ',
          )}`
        }${
          (facilityData.facilityResponse.employeeContacts.regionalDirector?.firstName ||
            facilityData.facilityResponse.employeeContacts.regionalDirector?.lastName) &&
          `   Regional Director: ${Concatenate(
            [
              facilityData.facilityResponse.employeeContacts.regionalDirector?.firstName || '',
              facilityData.facilityResponse.employeeContacts.regionalDirector?.lastName || '',
            ],
            ' ',
          )}`
        }`;
      case SearchType.unit:
        return `${unitData.unitId && `Unit ID: ${unitData.unitId || ''}`}${
          unitData.name && `   Name: ${unitData.name || ''}`
        }${unitData.facilityId && `   Facility ID: ${unitData.facilityId || ''}`}${
          unitData.linkedFacilityDetails.facilityName &&
          `   Facility Name: ${unitData.linkedFacilityDetails.facilityName || ''}`
        }`;
    }
  };

  const copyIdToClipBoard = () => {
    switch (searchType) {
      case SearchType.candidate:
        navigator.clipboard.writeText(`${candidateData.travelerId || ''}`);
        return;
      case SearchType.placement:
        navigator.clipboard.writeText(`${placementData.header.placementId || ''}`);
        return;
      case SearchType.order:
        navigator.clipboard.writeText(`${orderData.orderDetails?.orderId || ''}`);
        return;
      case SearchType.facility:
        navigator.clipboard.writeText(`${facilityData.facilityResponse.facilityId || ''}`);
        return;
      case SearchType.unit:
        navigator.clipboard.writeText(`${unitData.unitId || ''}`);
        return;
    }
  };

  const sendEmail = () => {
    const body = `<p>&nbsp;</p><p>&nbsp;</p><p>Link To Profile : <a href="${currentUrl}">${currentUrl}</a><br/><br/>{{signature}}</p>`;
    const substitutions: IToken[] = [
      {
        name: 'Signature',
        input: '{{signature}}',
      },
    ];
    if (!notificationData.email.open && notificationData.email.minimized) {
      dispatch(
        notificationDataActions.setEmailInteraction({
          open: true,
          minimized: false,
        }),
      );
      if (!_.isEqual(notificationData.email.data!.tos, [])) {
        dispatch(notificationDataActions.setSnackBarData({ channel: 'email', manual: false, changeWarning: true }));
      }
    } else {
      dispatch(
        notificationDataActions.setEmailData({
          data: {
            ...EmailDefaultValues(),
            ...notificationUser,
            substitutions: substitutions,
            useType: UseType.General,
            emailType: SendType.one_to_one,
            body: body,
            subject: getEmailSubject(),
            tos: [],
          },
          open: true,
          minimized: false,
        }),
      );
    }
  };

  const copyObjectToClipBoard = () => {
    const dataToBeCopied = getEmailSubject();
    navigator.clipboard.writeText(dataToBeCopied);
    return;
  };

  const copyUrlToClipBoard = () => {
    navigator.clipboard.writeText(currentUrl);
    return;
  };
  return { copyIdToClipBoard, copyObjectToClipBoard, sendEmail, copyUrlToClipBoard, getEmailSubject };
};
