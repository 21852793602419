import { TableRow, TableCell, Collapse } from 'amn-ui-core';
import React from 'react';
import { SubGrid } from './SubGrid';

interface ExpandedGridTableRowProps {
  [key: string]: any;
  row: any;
  rowKey: number;
  columns: any;
  hasEditMenu: boolean;
  fullWidth: boolean;
  editingIndex: number | null;
  centerHoverView;
  showEditControl;
  stopEditing;
  _onValueChanged;
  didEdit;
  disableRowColors;
  _onGridClose;
  hasExpandableRow;
  isLastRow;
  classes;
  restrictRolePermissions?;
}

export const ExpandedGridTableRow = ({
  row,
  rowKey,
  columns,
  hasEditMenu,
  fullWidth,
  editingIndex,
  classes,
  centerHoverView,
  showEditControl,
  stopEditing,
  _onValueChanged,
  didEdit,
  disableRowColors,
  _onGridClose,
  hasExpandableRow,
  isLastRow,
  restrictRolePermissions,
  ...rest
}: ExpandedGridTableRowProps) => {
  return (
    <React.Fragment>
      {row.expandable ? (
        <TableRow
          data-editingrow={row?.isEditing && rowKey === editingIndex}
          className={`${classes.subTable} ${classes.expandedtableRow}`}
        >
          <TableCell
            colSpan={columns.length + (hasExpandableRow ? 1 : 0) + (hasEditMenu ? 1 : 0)}
            style={{ padding: 0 }}
            className={`${classes.subTableCell} ${isLastRow ? classes.subTableLastBorder : ''}`}
          >
            <Collapse
              in={row.expanded}
              timeout="auto"
              unmountOnExit
              onExit={() => {
                if (row.versionRows) {
                  // collapse any rows that were expanded
                  for (const r of row.versionRows) {
                    r.expanded = false;
                  }
                }
              }}
            >
              <SubGrid
                columns={columns}
                row={row}
                fullWidth={fullWidth}
                isLastRow={isLastRow}
                centerHoverView={centerHoverView}
                showEditControl={showEditControl}
                handleEditCancel={() => stopEditing(rowKey)}
                edit={{
                  rowKey: rowKey,
                  isEditing: row?.isEditing,
                  onValueChanged: _onValueChanged,
                }}
                didEdit={didEdit}
                disableRowColors={disableRowColors}
                restrictRolePermissions={restrictRolePermissions}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </React.Fragment>
  );
};
