import { createAction } from '@reduxjs/toolkit';

const getCandidateDetails = createAction<{ candidateId: number; brandId: number }>('GET_CANDIDATE_DETAILS');
const getSummaryTabDetails = createAction<{ candidateId: number; brandId: number }>('GET_CANDIDATE_SUMMARY_DETAILS');
const getProfessionalProfileTabDetails = createAction<{ candidateId: number; brandId: number }>(
  'GET_CANDIDATE_PROFESSIONAL_PROFILE_DETAILS',
);
const getCandidateCredentialsTabDetails = createAction<{ candidateId: number; brandId: number }>(
  'GET_CANDIDATE_CREDENTIALS_DETAILS',
);
const getCandidatePersonalInfoTabDetails = createAction<{ candidateId: number; brandId: number }>(
  'GET_CANDIDATE_PERSONAL_INFO_DETAILS',
);

export const serviceActions = {
  getCandidateDetails,
  getSummaryTabDetails,
  getProfessionalProfileTabDetails,
  getCandidateCredentialsTabDetails,
  getCandidatePersonalInfoTabDetails,
};
