import { createSlice } from '@reduxjs/toolkit';
import { serviceActions } from './async-actions';
import { initialState } from './types';

export const slice = createSlice({
  name: 'candidateDetailsView',
  initialState: initialState,
  reducers: {},
});

export const { name: candidateProfileDetailsSliceKey, reducer: candidateProfileDetailsReducer } = slice;

export const candidateProfileDetailsActions = { ...slice.actions, ...serviceActions };
