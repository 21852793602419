import React from 'react';
import { createSvgIcon } from 'amn-ui-core';

export const AddDateTimeIcon = createSvgIcon(
  <>
    <rect id="Rectangle_4691" data-name="Rectangle 4691" width="30" height="30" rx="15" fill="none" />
    <path
      id="calendar_add_on_FILL0_wght300_GRAD0_opsz24"
      d="M155.07-843.392v-3.288h-3.288v-1.644h3.288v-3.288h1.644v3.288H160v1.644h-3.288v3.288Zm-13.087-2.466a1.912,1.912,0,0,1-1.406-.575,1.912,1.912,0,0,1-.575-1.406v-12.476a1.912,1.912,0,0,1,.575-1.406,1.912,1.912,0,0,1,1.406-.575H143.5v-2.318h1.686v2.318H151.3v-2.318h1.644v2.318h1.517a1.912,1.912,0,0,1,1.406.575,1.912,1.912,0,0,1,.575,1.406v6.474a6.728,6.728,0,0,0-.822-.051,6.728,6.728,0,0,0-.822.051v-2.091H141.645v8.093a.322.322,0,0,0,.105.232.322.322,0,0,0,.232.105h7.492a6.724,6.724,0,0,0,.051.822,4.327,4.327,0,0,0,.185.822Zm-.337-11.718H154.8v-2.74a.322.322,0,0,0-.105-.232.322.322,0,0,0-.232-.105H141.982a.322.322,0,0,0-.232.105.322.322,0,0,0-.105.232Zm0,0v0Z"
      transform="translate(-134.001 869.003)"
      fill="inherit"
    />
  </>,
  'AddDateTimeIcon',
);
