import { RootState } from 'types';
import { initialState } from './utils';
import { createSelector } from '@reduxjs/toolkit';

const selectDomain = (state: RootState) => state.placementCandidateContract || initialState;

export const selectPlacementContractTemplatesEditor = createSelector([selectDomain], state => state);

export const selectTemplates = createSelector([selectDomain], state => state.templates);

export const selectSelectedTemplate = createSelector([selectDomain], state => state.selectedTemplate);

export const selectMergeTokenList = createSelector([selectDomain], state => state.mergeTokenList);

export const selectDocStatusExists = createSelector([selectDomain], state => ({
  amieWebDraftExists: state.amieWebDraftExists,
  classicDraftExists: state.classicDraftExists,
  amieWebCreatedExists: state.amieWebCreatedExists,
}));

export const selectDocumentView = createSelector([selectDomain], state => ({
  document: state.document,
  renderPreviewScreen: state.renderPreviewScreen,
  renderPdfView: state.renderPdfView,
}));
