import React from 'react';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import CueNoteConfirmation from './CueNoteConfirmation';
import { ISelectedCueNoteStatus } from './model';
import { useReadOnly } from 'oidc/userRoles';
import { createPlacementTabIDs } from '@AMIEWEB/Placement/CreatePlacement/helper';

const useCueNoteStyles = makeStyles()(() => ({
  dialog: {
    width: '600px',
    height: 'auto',
  },
  dialogContent: {
    overflow: 'hidden',
  },
}));

const CueNotesDialog = ({
  status,
  open,
  selectedStatus,
  handleConfirmation,
  onClose,
  submitting,
}: {
  open: boolean;
  status: ISelectedCueNoteStatus;
  selectedStatus: ISelectedCueNoteStatus | undefined;
  handleConfirmation: Function;
  onClose: Function;
  submitting: boolean;
}) => {
  const { classes } = useCueNoteStyles();
  const { t } = useTranslation();
  const { readOnly } = useReadOnly();

  return (
    <GenericDialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={(e, reason: string) => {
        if (reason === 'backdropClick' && submitting) return;
        onClose();
      }}
      classes={{
        paperFullWidth: classes.dialog,
      }}
      dialogTitleProps={{
        text: t('placement.create.cueNotes.setToRequestFileOut', { placementStatus: selectedStatus?.activityStatus }),
      }}
      dialogActions={[
        {
          id: createPlacementTabIDs.createPlacementCancelButton,
          text: t('undoModal.confirmation.cancel'),
          variant: 'text',
          color: 'tertiary',
          onClick: () => onClose(),
          disabled: submitting,
        },
        {
          id: createPlacementTabIDs.createPlacementSubmitButton,
          text: t('undoModal.confirmation.confirm'),
          variant: 'contained',
          onClick: () => handleConfirmation(status),
          loading: submitting,
          disabled: readOnly || submitting,
          tooltipProps: readOnly
            ? {
                tooltipContent: t('global.readOnlyTooltip'),
              }
            : undefined,
        },
      ]}
      dialogContentProps={{ classes: { root: classes.dialogContent } }}
    >
      <CueNoteConfirmation selectedStatus={selectedStatus} />
    </GenericDialog>
  );
};

export default CueNotesDialog;
