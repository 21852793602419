import { Chip } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { IEmailAttachment } from 'app/models/Email/IEmail';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getAttachmentIcon } from '../../Common/AttachmentFileTypes';

interface PreviewAttachmentsWithUrlProps {
  attachmentUrls: IEmailAttachment[];
}

const useStyles = makeStyles()(() => ({
  Chip: {
    padding: '1.5% 0',
    margin: '5px 5px 5px 3px',
    borderRadius: '5px',
    height: '2vh',
  },
}));

const PreviewAttachmentsWithUrl = ({ attachmentUrls }: PreviewAttachmentsWithUrlProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const result = (
    <div>
      {attachmentUrls.map(url => (
        <Chip
          key={url.fileName}
          className={classes.Chip}
          variant="outlined"
          size="medium"
          icon={<img src={getAttachmentIcon(url.fileName.split('.')[1].toLowerCase())} alt={t('fileicon')} />}
          label={url.fileName.length > 15 ? url.fileName.substring(0, 15) + ' ...' : url.fileName}
        />
      ))}
    </div>
  );
  return result;
};

export default PreviewAttachmentsWithUrl;
