import styledComponent from 'styled-components';
import { Paper, Accordion } from 'amn-ui-core';
import { APPLICATIONQUESTIONCONSTANT } from './ApplicationQuestionsCard';

export const PaperCard = styledComponent(Paper)`
  width: 100%;
  border: 1px solid #ececec;
  border-radius: 4px;
  padding: 10px 25px 25px 22px;
`;

export const Row = styledComponent.tr`
  display: flex;
  justify-content: space-between;
  margin-bottom: '6px';
`;

export const RowHead = styledComponent.th`
  width: 65%;
  color: #333333;
  font-size: 14px;
  text-align: left;
`;

export const RowContent = styledComponent.td`
  color: #003e68;
  font-size: 14px;
  text-align: end;
  font-weight: 600;
`;
export const QuestionRowContent = styledComponent.td<{ status?: string }>`
  color: ${props =>
    props?.status === APPLICATIONQUESTIONCONSTANT.FAIL ? "red" : "green"};
  font-size: 14px;
  text-align: end;
  font-weight: 600;
`;
export const Title = styledComponent.div`
  color: #52429a;
  font-size: 20px;
  text-align: start;
`;

export const SubTitle = styledComponent.div`
  font-size: 14px;
  color: #333333;
  font-weight: 600;
  text-align: start;
`;

export const TextContent = styledComponent.div`
  font-size: 14px;
  color: #333333;
  text-align: start;
`;

export const AccordionCard = styledComponent(Accordion)`
  1px solid #ECECEC
`;

export const AccordContent = styledComponent.div`
  width: 100%;
`;
