/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { Expandable, CustomTooltip, XGridChipCell, XGridToolbar } from '@AMIEWEB/Common';
import { Title } from 'app/components/Order/OrderDetails/OrderPreferences/customComponents/OrderPreferencesDesign';
import { Box, Stack, Paper, Link } from 'amn-ui-core';
import { XGrid } from 'app/components/Common/XGrid/XGrid';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { DataGridProProps, GridCellParams, GridColumns, useGridApiRef } from '@mui/x-data-grid-pro';
import { XGridDefaultColumn } from '@AMIEWEB/Common/XGrid/cells/XGridDefaults';
import { useDispatch, useSelector } from 'react-redux';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import {
  isUploadDocumentModalOpen,
  selectFacilityDetail,
  selectFacilityMiscellaneousData,
  selectOffContract,
  selectUpdatedOffContract,
} from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { Shifts, Status } from './OffContract';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { formatDate } from '@AMIEWEB/Order/OrderDetails/helper';
import { useHistory } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';
import { OffContractDetailsPanel } from './OffContractDetails/OffContractDetailsPanel';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { selectGridSelection } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.selector';
import { UploadApprovalDocuments } from './OffContractTable/UploadApprovalDocuments';
import { selectUser } from 'oidc/user.selectors';
import { isAccountManagement, isClientContractAdminUser, isReadOnly } from 'oidc/userRoles';
import { gridSelectionActions } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.redux';
import { DescriptionOutlined } from '@mui/icons-material';
import { colors } from 'styles/styleVariables';
import { XGridDocCell } from '@AMIEWEB/Order/OrderCreationForm/RateCard/OffContractRateElementTable/XGridDocCell';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import { missingField } from 'app/constants';
import { IOffContract } from '@AMIEWEB/Facility/FacilityStore/Facility.model';

const useStyles = makeStyles()({
  grid: {
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #CCCCCC',
    fontSize: '14px',
    '& .MuiDataGrid-main-main': {
      minHeight: 'auto',
    },
  },
  expandedRow: {
    backgroundColor: '#EFF4F9',
  },
  boxComponent: {
    height: 'auto',
  },
});
export const OffContractRateElements = () => {
  const facilityDetailResponse = useSelector(selectFacilityDetail);
  const apiRef = useGridApiRef();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState<any>([]);
  const offContractRates = useSelector(selectOffContract);
  const data = useSelector(selectFacilityMiscellaneousData);
  const history = useHistory();
  const [sortModel, setSortModel] = useState<any>();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [expandedRowsId, setExpandedRowsId] = useState([]);
  const isUpdated = useSelector(selectUpdatedOffContract);
  const { promiseInProgress: isCallingAPI } = usePromiseTracker({ area: 'off-contract-rate-elements', delay: 0 });
  const { promiseInProgress: rateElementsUpdated } = usePromiseTracker({ area: 'update-rate-elements', delay: 0 });
  const [width, setWidth] = React.useState(null);
  const [addDocsModalOpen, setAddDocsModalOpen] = useState<boolean>(false);
  const { selectedData: gridSelections } = useSelector(selectGridSelection);
  const appUser = useSelector(selectUser);
  const isUploadModalOpen = useSelector(isUploadDocumentModalOpen);
  const handleViewportInnerSizeChange = React.useCallback(() => {
    const dimensions = apiRef?.current?.getRootDimensions();
    setWidth(dimensions?.viewportInnerSize.width);
  }, [apiRef?.current]);
  const [contentDetailHeight, setContentDetailHeight] = useState<number>(0);

  React.useEffect(() => {
    return apiRef.current.subscribeEvent('viewportInnerSizeChange', handleViewportInnerSizeChange);
  }, [apiRef, handleViewportInnerSizeChange]);

  const onSortModelChange = model => {
    setSortModel(model);
  };

  const getDisciplineName = (val: number) => {
    if (val) {
      const discipline = data?.disciplineOpts.find(ele => ele.object.ID === val);
      return discipline?.object?.Abbreviation;
    }
    return missingField;
  };

  const getSpecialtyName = (val: number) => {
    if (val) {
      const specialty = data?.specialtyOpts.find(ele => ele.object.SpecialtyID === val);
      return specialty?.label;
    }
    return missingField;
  };

  const getSubSpecialtyName = (val: number) => {
    if (val) {
      const subSpecialty = data?.subSpecialtyOpts.find(ele => ele.object.SubSpecialtyID === val);
      return subSpecialty?.label;
    }
    return missingField;
  };

  const handleCreateAtLink = (val: string) => {
    const createdAtPage = val.charAt(0);
    const pageId = val.replace(/[^0-9]/g, '');
    if (createdAtPage === 'P') {
      history.push({ pathname: `/placement/${pageId}` });
    } else {
      const payload = { open: true, order: { orderId: pageId } };
      dispatch(globalActions.setFacilityDrawerData({ open: false, facility: undefined }));
      dispatch(globalActions.setOrderDrawerData(payload));
    }
  };

  const getLink = (val: string) => {
    let value = '--';
    if (val) {
      if (val.charAt(0) === 'F') {
        return val;
      }
      return <Link onClick={() => handleCreateAtLink(val)}>{val}</Link>;
    } else {
      return value;
    }
  };

  const handleRefreshGrid = () => {
    fetchGridData();
  };
  const handleResetColumns = () => {
    setColumns(offContractGridColumns);
  };

  const getSortColumnName = name => {
    switch (name) {
      case 'discipline':
        return 'disciplineName';
      case 'specialty':
        return 'specialtyName';
      case 'subSpecialty':
        return 'subSpecialtyName';
      case 'rateElementName':
        return 'rateElementNameId';
      case 'rateValue':
        return 'value';
      case 'shift':
        return 'shiftId';
      case 'status':
        return 'statusId';
      case 'reason':
        return 'reason';
      case 'startDate':
        return 'effectiveStartDate';
      case 'endDate':
        return 'effectiveEndDate';
      case 'createdOn':
        return 'dateCreated';
      case 'createdBy':
        return 'createdBy';
      case 'dateLastUpdated':
        return 'dateLastUpdated';
      case 'lastUpdatedBy':
        return 'lastUpdatedBy';
      default:
        return null;
    }
  };

  const fetchGridData = () => {
    const payload: IOffContract = {
      facilityId: facilityDetailResponse?.facilityId,
      skillsetFilter: [],
      rateElementNameIds: [],
      statusIds: [],
      sortColumn: sortModel && sortModel.length > 0 ? getSortColumnName(sortModel[0].field) : null,
      sortOrder: sortModel && sortModel.length > 0 ? sortModel[0].sort?.toUpperCase() : null,
      pageNumber: pageNumber + 1,
      pageSize: pageSize,
    };
    dispatch(facilityActions.getOffContractRateElements(payload));
  };

  const getDetailPanelHeight = React.useCallback(() => contentDetailHeight, [contentDetailHeight]);

  const getDetailPanelContent = React.useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(({ row }) => {
    return (
      <Stack
        sx={{
          height: '100%',
          boxSizing: 'border-box',
          width,
        }}
        direction="column"
      >
        <Paper
          sx={{
            position: 'sticky',
            left: 0,
            width: '73vw',
            p: '24px',
            boxShadow: '0px 15px 10px -15px #00000029',
            borderBottom: '1px solid #CCCCCC',
          }}
        >
          <Stack direction="column" spacing={1} sx={{ height: 1 }}>
            <OffContractDetailsPanel
              fetchGridData={fetchGridData}
              facilityId={facilityDetailResponse?.facilityId}
              row={row}
              getColor={getColor}
              getValue={getValue}
              contentDetailHeight={contentDetailHeight}
              setContentDetailHeight={setContentDetailHeight}
            />
          </Stack>
        </Paper>
      </Stack>
    );
  }, []);

  const onPageChange = (page: number) => {
    setPageNumber(page);
  };
  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };

  const getRowClassName = params => {
    return expandedRowsId.includes(params.id) ? classes.expandedRow : '';
  };

  useEffect(() => {
    if (!rateElementsUpdated && facilityDetailResponse?.facilityId) {
      fetchGridData();
      dispatch(gridSelectionActions.reset());
    }
  }, [rateElementsUpdated]);

  const tooltipContent = {
    message: 'Approval Documents',
  };

  const offContractGridColumns: GridColumns = [
    {
      ...XGridDefaultColumn,
      field: 'discipline',
      headerName: 'Discipline',
      sortable: true,
      flex: 0,
      width: 100,
      headerClassName: 'super-app-theme--header',
    },
    {
      ...XGridDefaultColumn,
      field: 'specialty',
      headerName: 'Specialty',
      flex: 0,
      sortable: true,
      width: 100,
      headerClassName: 'super-app-theme--header',
    },
    {
      ...XGridDefaultColumn,
      field: 'subSpecialty',
      headerName: 'Sub Specialty',
      flex: 0,
      width: 120,
      headerClassName: 'super-app-theme--header',
    },
    {
      ...XGridDefaultColumn,
      field: 'rateElementName',
      headerName: 'Rate Element',
      flex: 0,
      width: 120,
      headerClassName: 'super-app-theme--header',
    },
    {
      ...XGridDefaultColumn,
      field: 'rateValue',
      headerName: 'Rate Value',
      headerAlign: 'right',
      align: 'right',
      flex: 0,
      width: 150,
      headerClassName: 'super-app-theme--header',
      renderCell: params => {
        return getRateValue(params?.row?.value, params?.row?.valueMax, params?.row?.rateElementName);
      },
    },
    {
      ...XGridDefaultColumn,
      field: 'shift',
      headerName: 'Shift',
      headerAlign: 'right',
      align: 'right',
      flex: 0,
      width: 80,
      headerClassName: 'super-app-theme--header',
    },
    {
      ...XGridDefaultColumn,
      field: 'status',
      headerName: 'Status',
      flex: 0,
      width: 140,
      headerClassName: 'super-app-theme--header',
      renderCell: XGridChipCell({
        size: 'small',
        dynamicColor: (params: GridCellParams) => {
          return getColor(params.value);
        },
        renderValue: (params: GridCellParams) => {
          return getValue(params.value);
        },
      }),
    },
    {
      ...XGridDefaultColumn,
      field: 'document',
      headerName: 'Documents',
      flex: 0,
      width: 31,
      headerClassName: 'super-app-theme--header',
      renderHeader: () => (
        <CustomTooltip tooltipContent={tooltipContent.message} arrow={true}>
          <DescriptionOutlined style={{ color: colors.darkText }} />
        </CustomTooltip>
      ),
      renderCell: params => <XGridDocCell {...params} />,
    },
    {
      ...XGridDefaultColumn,
      field: 'reason',
      headerName: 'Reason',
      flex: 0,
      width: 100,
      headerClassName: 'super-app-theme--header',
    },
    {
      ...XGridDefaultColumn,
      field: 'effectiveStartDate',
      headerName: 'Start Date',
      flex: 0,
      width: 100,
      headerClassName: 'super-app-theme--header',
      renderCell: params => {
        return getValue(formatDate(params?.row?.effectiveStartDate));
      },
    },
    {
      ...XGridDefaultColumn,
      headerClassName: 'super-app-theme--header',
      field: 'effectiveEndDate',
      headerName: 'End Date',
      flex: 0,
      width: 100,
      renderCell: params => {
        return getValue(formatDate(params?.row?.effectiveEndDate));
      },
    },
    {
      ...XGridDefaultColumn,
      headerClassName: 'super-app-theme--header',
      field: 'dateCreated',
      headerName: 'Created On',
      flex: 0,
      width: 100,
      renderCell: params => {
        return getValue(formatDate(params?.row?.dateCreated));
      },
    },
    {
      ...XGridDefaultColumn,
      headerClassName: 'super-app-theme--header',
      field: 'createdBy',
      headerName: 'Created By',
      flex: 0,
      width: 100,
    },
    {
      ...XGridDefaultColumn,
      headerClassName: 'super-app-theme--header',
      field: 'createdAt',
      headerName: 'Created At',
      flex: 0,
      width: 100,
      renderCell: params => {
        return getLink(params?.row?.createdAt);
      },
    },
    {
      ...XGridDefaultColumn,
      headerClassName: 'super-app-theme--header',
      field: 'dateLastUpdated',
      headerName: 'Last Updated',
      flex: 0,
      width: 120,
      renderCell: params => {
        return getValue(formatDate(params?.row?.dateLastUpdated));
      },
    },
    {
      ...XGridDefaultColumn,
      headerClassName: 'super-app-theme--header',
      field: 'lastUpdatedBy',
      headerName: 'Last Updated By',
      flex: 0,
      width: 150,
      renderCell: params => {
        return getValue(params?.row?.lastUpdatedBy);
      },
    },
  ];

  useEffect(() => {
    if (!isUploadModalOpen && isUpdated) {
      dispatch(facilityActions.setUpdatedOffContract(false));
      setExpandedRowsId([]);
    }
  }, [isUpdated, isUploadModalOpen]);

  useEffect(() => {
    if (facilityDetailResponse?.facilityId) {
      fetchGridData();
    }
  }, [sortModel, pageNumber, pageSize]);

  useEffect(() => {
    if (facilityDetailResponse?.facilityId) {
      setColumns(offContractGridColumns);
      fetchGridData();
    }
  }, [facilityDetailResponse?.facilityId]);

  const getReasonValue = (reason: string, reasonId: number) => {
    let val = '--';
    if (reason) {
      if (reasonId === 9) {
        val = `Other - ${reason}`;
      } else {
        val = reason;
      }
    }
    return val;
  };

  useEffect(() => {
    const rowData = offContractRates?.rateElements?.map((item, index) => ({
      ...item,
      status: getStatusName(item.statusId),
      reason: getReasonValue(item.reason, item.reasonId),
      discipline: getDisciplineName(item.disciplineId),
      specialty: getSpecialtyName(item.specialtyId),
      subSpecialty: getSubSpecialtyName(item.subSpecialtyId),
      rateValue: item.value,
      shift: getShift(item.shiftId),
      id: index + 1,
    }));
    setRows(rowData);
  }, [offContractRates, data]);

  const leftActions = () => {
    if (
      (isAccountManagement(appUser?.userInfo?.roles || []) ||
        isClientContractAdminUser(appUser?.userInfo?.roles || [])) &&
      !isReadOnly(appUser?.userInfo?.roles || [])
    ) {
      return [
        {
          title: 'Approval Documents',
          Icon: CloudUploadOutlinedIcon,
          disabled: !gridSelections.length,
          onClick: () => {
            dispatch(facilityActions.setUploadDocumentModalOpen(true));
            setAddDocsModalOpen(true);
          },
        },
      ];
    } else {
      return [];
    }
  };

  const grid = useMemo(() => {
    return (
      <React.Fragment>
        <XGrid
          sx={{
            '& .super-app-theme--header': {
              fontSize: '12px',
            },
            '& .MuiDataGrid-detailPanel': {
              overflow: 'visible',
            },
          }}
          apiRef={apiRef}
          page={pageNumber}
          pageSize={pageSize}
          loading={isCallingAPI}
          paginationMode={'server'}
          sortingOrder={['desc', 'asc']}
          columns={columns}
          rows={rows || []}
          detailPanelExpandedRowIds={expandedRowsId}
          getRowClassName={getRowClassName}
          className={classes.grid}
          headerHeight={60}
          disableTitleCount={true}
          title=""
          rowCount={offContractRates?.totalCount}
          rowCountDisplay={offContractRates?.totalCount}
          hideFooter={rows?.length < 1}
          autoHeight={rows?.length > 0}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          checkboxSelection
          onDetailPanelExpandedRowIdsChange={(id, details) => {
            setExpandedRowsId(id);
          }}
          components={{
            DetailPanelExpandIcon: ExpandMoreOutlinedIcon,
            DetailPanelCollapseIcon: ExpandLessOutlinedIcon,
            Toolbar: XGridToolbar,
          }}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          selectionModel={gridSelections}
          toolbarProps={{
            refreshButton: true,
            resetButton: true,
            refreshGrid: () => {
              handleRefreshGrid();
            },
            resetColumns: () => {
              handleResetColumns();
              dispatch(
                globalActions.setSnackBar({
                  message: 'Columns have been reset.',
                  severity: 'success',
                }),
              );
            },
            leftActions: leftActions(),
          }}
        />
      </React.Fragment>
    );
  }, [
    columns,
    rows,
    data,
    offContractRates,
    onPageChange,
    onPageSizeChange,
    pageSize,
    pageNumber,
    isUpdated,
    expandedRowsId,
  ]);
  return (
    <React.Fragment>
      <Expandable
        expanded
        titleComponent={
          <Title>
            <PostAddIcon style={{ marginRight: '5px' }} />
            {t('order.facility.rateCard.offContractRateElement.title')}
          </Title>
        }
      >
        <Box className={classes.boxComponent}>{grid}</Box>
      </Expandable>
      <UploadApprovalDocuments
        appUser={appUser}
        addDocsModalOpen={addDocsModalOpen}
        setAddDocsModalOpen={setAddDocsModalOpen}
        gridSpecs={{ gridSelections, rows }}
      />
    </React.Fragment>
  );
};

export const getValue = (val: any) => {
  let value = '--';
  if (val) {
    value = val;
  }
  return value;
};

export const getRateValue = (value: number, valueMax: number, rateElement: string) => {
  let val = '--';
  if (rateElement === 'GWW') {
    val = `${value} Hrs`;
  } else if (rateElement === 'Mileage Reimbursement') {
    val = `$ ${value.toFixed(3)}`;
  } else {
    if (valueMax > 0) {
      val = `$ ${value.toFixed(2)} - $ ${valueMax.toFixed(2)}`;
    } else {
      val = `$ ${value.toFixed(2)}`;
    }
  }
  return val;
};

export const getShift = (val: number) => {
  let value = 'All';
  if (val) {
    const shift = Shifts.find(ele => ele.id === val);
    value = shift.title;
  }
  return value;
};
export const getStatusName = (val: number) => {
  let value = '--';
  if (val) {
    const status = Status.find(ele => ele.id === val);
    value = status.title;
  }
  return value;
};

export const getColor = (val: string) => {
  switch (val) {
    case 'Rejected':
      return '#CB0813';
    case 'Expired':
      return '#CB0813';
    case 'Void':
      return '#CB0813';
    case 'Approved':
      return '#5FB661';
    case 'Pending':
      return '#FC9508';
    case 'Pending Approval':
      return '#FC9508';
    default:
      return '#000000';
  }
};
