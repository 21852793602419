import { Grid, IconButton } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionModal, IActionModalProps } from './ActionModal';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips';
import { useDispatch } from 'react-redux';
import { pConfirmationDocEditorActions } from 'store/redux-store/placement-client-confirmation-doc-editor/slice';
import { IPublishConfirmationDocTokensPayload } from 'store/redux-store/placement-client-confirmation-doc-editor/async-actions';
import PublishTokenIcon from 'app/assets/images/publish_icon_active.svg';
import PublishTokenIconDisabled from 'app/assets/images/publish_icon_disable.svg';
import { usePromiseTracker } from 'react-promise-tracker';

export const PublishTokensAction = ({
  previewMode,
  placementId,
  selectedTemplate,
  disablePreview = false,
  getCurrentTemplateText = null,
  editor,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { promiseInProgress: isCallingPublishTokensAPI } = usePromiseTracker({
    area: 'placement-client-confirmation-templates-publish-tokens',
    delay: 0,
  });

  // @ts-ignore
  const [modalProps, setModalProps] = React.useState<IActionModalProps>({ open: false });

  // @ts-ignore
  const closeModal = React.useCallback(() => setModalProps({ open: false }), []);

  const handleContactsModal = () => {
    setModalProps({
      open: true,
      title: t('cancelModal.confirmation.selectContacts'),
      message: props.contactsWarningMessage,
      primaryBtns: [
        {
          text: t('cancelModal.confirmation.selectContact'),
          onClick: () => {
            closeModal();
            props.setSideView('contacts');
          },
        },
      ],
      secondaryBtns: [
        {
          text: t('cancelModal.confirmation.cancel'),
          onClick: closeModal,
        },
      ],
    });
  };

  const handlePublishTokensModal = (currentTemplateText: string) => {
    setModalProps({
      open: true,
      title: t('templateEditor.publishTokensModalTitle'),
      message: t('templateEditor.publishTokensModalMessage'),
      primaryBtns: [
        {
          text: t('templateEditor.yes'),
          onClick: () => {
            closeModal();
            dispatch(
              pConfirmationDocEditorActions.publishConfirmationDocTokens({
                selectedTemplate: selectedTemplate,
                requestBody: {
                  placementId: placementId,
                  templateId: selectedTemplate?.templateId,
                  templateText: currentTemplateText,
                  templateName: selectedTemplate?.templateName,
                  isAutoSave: false,
                },
                callback: (result) => {
                  if(editor?.current && result){
                    editor.current.setContent(result);
                    editor.current.undoManager.add(); // Add to the undo stack
                    editor.current.fire('change'); // Trigger the onChange event manually
                  }
                }

              })
            );

          },
        },
      ],
      secondaryBtns: [
        {
          text: t('cancelModal.confirmation.cancel'),
          onClick: closeModal,
        },
      ],
    });
  };

  const handlePublishTokens = () => {
    if (props.isContactTokensSelected && !props.atleastOneContactIsSelected) {
      handleContactsModal();
      return;
    }
    const currentTemplateText = getCurrentTemplateText();
    if (!!currentTemplateText && selectedTemplate) {
      handlePublishTokensModal(currentTemplateText);
    }
  };

  return (
    <>
      {!previewMode && (
        <Grid item>
          <div style={{ display: 'flex' }}>
            <CustomTooltip disabled={disablePreview} tooltipContent={t('templateEditor.publishTokens')}>
              <IconButton
                color="primary"
                size="small"
                disableFocusRipple
                disableTouchRipple
                disableRipple
                disabled={isCallingPublishTokensAPI || disablePreview}
                sx={{ padding: '0px' }}
              >
                <img
                  color="primary"
                  alt="publish-tokens"
                  src={isCallingPublishTokensAPI || disablePreview ? PublishTokenIconDisabled : PublishTokenIcon}
                  onClick={handlePublishTokens}
                />
              </IconButton>
            </CustomTooltip>
          </div>
        </Grid>
      )}
      <ActionModal {...modalProps} />
    </>
  );
};
