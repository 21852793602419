import { IGroupedSkillset } from 'app/components/Common/SkillsetTree/SkillsetTree';
import {
  ISkillCheckListItem,
  ISkillChecklistPassport,
} from 'app/components/Credentialing/CredentialingAttachmentsViewer/AttachmentsViewer/ViewSkillCheckList/Config/model';
import { ITypeAheadOption } from 'app/components/Tasks/CustomComponents/ControlledTypeAheadWithAvatar';
import { Credentials } from '../Credentialing/Credentials';
import { IPlacementChangeHistory } from '../Placement/PlacementChangeHistory';
import { IPreferredTimeToReach } from './SellingNotes';

export interface Name {
  prefix: string;
  first: string;
  middle?: string | null;
  last: string;
}

export interface Name2 {
  firstName?: string;
  middleName?: string;
  lastName?: string;
}

export interface Address {
  latitude?: any;
  address1: string;
  address2?: string;
  city: string;
  stateProvinceId: string;
  state: string;
  country: string;
  zip?: string;
  longitude?: any;
}

export interface VerifiedSkill {
  yearsOfExperience: number;
  monthsOfExperience: number;
  discipline: string;
  disciplineId?: number;
  disciplineAbbr: string;
  specialty: string;
  specialtyId?: string;
  specialtyAbbr: string;
  subSpecialtyId?: any;
  subSpecialtyAbbr?: string;
  specialties: Specialty[];
  interverStatus?: string | null;
  interviewRejected?: boolean | null;
  interviewRating?: string | null;
  matchingDegree?: boolean | null;
  matchingMajor?: boolean | null;
  matchingDegreesMajors?: DegreesMajors[] | null;
  interviewDate?: Date | null;
  requestedOn?: string;
}

export interface UnVerifiedSkill {
  yearsOfExperience: number;
  monthsOfExperience: number;
  disciplineId: number;
  disciplineAbbr: string;
  specialtyId: number;
  specialtyAbbr: string;
  subSpecialtyId?: any;
  subSpecialtyAbbr?: any;
  interviewRejected?: any;
  interviewReason?: any;
  interverStatus?: any;
  interviewRating: string;
  interviewDate?: any;
  requestedOn?: any;
  matchingDegree: boolean;
  matchingMajor: boolean;
  matchingDegreesMajors?: any;
  isMissingDisciplineEducation: boolean;
  isVerified: boolean;
}

export interface DegreesMajors {
  degree: string | null;
  major: string | null;
  month: number | null;
  year: number | null;
  isGraduationDateValid: boolean | null;
}
export interface Specialty {
  specialty: string;
  specialtyAbbr: string;
}
export interface Recruiter {
  first: string;
  last: string;
  recruiterId: number;
  email: string;
}

export interface License {
  type: string;
  state: string;
  licenseNumber: string;
  expirationOn: string;
  dateCompleted?: string;
  hearsayLicense?: boolean;
  verified?: boolean;
  requirementType?: string;
}
export interface IHearsayCertification {
  id?: number;
  certificationName?: string;
  expirationDate?: Date;
  issuedBy?: string;
}

export interface Location {
  address1: string;
  address2?: string;
  city: string;
  stateProvinceID: string;
  state: string;
  country: string;
  countryId?: number;
  zip: string;
}

export interface Education {
  graduatedOn: string;
  graduatedOnYear: number;
  graduatedOnMonth: number;
  school: string;
  schoolId?: number;
  majorId: number;
  major: string;
  degreeId: number;
  degree: string;
  location: Location;
}

export interface candidateWorkExperience {
  totalYearsOfExp: number;
  totalMonthsOfExp: number;
  skillsets: skillsets[];
  hasGraduationDate?: boolean;
  employmentGapDays?: employmentGapDays[] | null;
}

export interface employmentGapDays {
  endDate?: string;
  gapDays?: number;
  startDate?: string;
}
export interface skillsets {
  totalYearsOfExperience: number;
  totalMonthsOfExperience: number;
  lastThreeYearsYearsOfExperience: number;
  lastThreeYearsMonthsOfExperience: number;
  disciplineAbbr: string;
  specialtyAbbr: string;
}

export interface Employment {
  startedOn: string;
  endedOn: string;
  yearsOfExperience: number;
  monthsOfExperience: number;
  discipline: string;
  disciplineAbbr: string;
  disciplineId?: number;
  specialty: string;
  specialtyAbbr: string;
  specialtyId?: number;
  position: string;
  approvedReferenceCount: number;
  facilityName: string;
  location: Location;
  unit: string;
  travelCompany: string;
  travelCompanyId?: number;
  staffingAgency: string;
  isTeachingHospital: string;
  numberOfBedsInFacility: number;
  currentlyEmployed: boolean;
  placementID: number;
  reasonForLeaving: string;
  workHistoryId?: number;
  timeStamp?: string;
  equipmentProcedures?: string;
}

export interface HoverReasonForLeaving {
  name: string;
  reasonForLeaving: string;
}

export interface Placement {
  placementDays: number;
  weeksBooked: number;
  placementInfo: PlacementInfo[];
  fileSent: number;
}

export interface PlacementInfo {
  placementId: number; //
  candidateId: number; //
  orderId?: number;
  amnDivision?: string;
  candidateName?: Name2;
  facilityId?: number;
  facilityName: string; //
  dateEntered?: string;
  availabilityDate?: string;
  extensionRequestType?: string;
  contractAffiliate?: string;
  fileSentDate: string; //
  vmsRequisitionNumber?: string;
  placementTypeId?: string;
  placementStatus: string; //
  placementStatusFilterId?: string;
  gender?: string;
  dob?: string;
  discipline?: string;
  specialty?: string;
  id?: number;
  startDate: string; //
  endDate: string; //
  brandId?: string;
  brand?: string;
  facilityAddress?: any;
  notes?: string;
  complianceNotes?: string;
  email?: string;
  goesBy: string;
  newGraduate?: string;
  ccsId?: string;
  ccsName?: Name2;
  vmsId?: string;
  vmsRequestNo?: string;
  vmsName?: string;
  linkToTask?: string;
  statusChangeDate?: string;
  placementStatusTypeId?: number;
  credentialAnalystInfo: PlacementContact;
  recruiterInfo: PlacementContact;
  accountManagerInfo: PlacementContact;
  clientManagerInfo: PlacementContact;
  ccsInfo: PlacementContact;
}

export interface PlacementContact {
  employeeID: number;
  userName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

export interface Experience {
  totalYearsOfExperience: number;
  totalMonthsOfExperience: number;
  totalApprovedReferenceCount: number;
  education: Education[];
  employment: Employment[];
  skillsets: VerifiedSkill[];
  maxEmploymentGap: number;
  candidateWorkExperience: candidateWorkExperience;
}

export interface EmergencyContact {
  name: string;
  phone: string;
  relationship: string;
  extensionNotes: string;
  countryDialingCode: string;
}

export interface ICommunicationLog {
  ctiActivityHistoryID: number;
  ctiActivityTypeID: number;
  dateCreated: any;
  employeeid: number;
  endDateTime: any;
  id: number;
  notes: string;
  startDateTime: any;
  type: string;
}

export interface IUserCommunicationLog {
  totalRecords: number | undefined;
  pageSize: number;
  pageNumber: number;
  results: any[];
  nextPageExists: boolean;
  lastContactedOn?: string | Date;
  [key: string]: any;
}

export interface IPhoneNumber {
  phoneTypeId: number;
  phoneTypeDescription: string;
  phoneNumber?: string | null;
  countryDialingCode: string;
  extensionNotes?: string;
  timeStamp?: string;
}

export type SaveStatusType = 0 | 1 | 2 | '';

export interface ICandidateDetails {
  travelerId: number;
  candidateGuid: string;
  brandId: number;
  brand: string;
  name: Name;
  goesBy?: string | null;
  other: string;
  primaryPhoneNumber?: string | null;
  mobilePhoneNumber?: string | null;
  primaryEmail: string;
  secondaryEmail: string;
  verifiedSkills: VerifiedSkill[];
  unVerifiedSkills: UnVerifiedSkill[];
  groupedSkillsSets: IGroupedSkillset[];
  address: Address;
  candidateStatusId: number;
  candidateStatus: string;
  audits: Audit[];
  placementStatus: string;
  recruiter: Recruiter;
  availableOn: string;
  callBackDate: string;
  lastContactedOn: string;
  gender: string;
  dateOfBirth: string;
  socialSecurityNumber: any;
  ssnTimestamp: string;
  adpId: number;
  nuid: string;
  candidateSellingNotes: string;
  candidateSummary: string;
  licenses: License[];
  experience: Experience;
  supervisor: ISupervisorData;
  investigationSuspension: boolean;
  investigationSuspensionExplanation?: string;
  rightToWorkVerification: boolean;
  visaExplaination?: string;
  emergencyContact: EmergencyContact;
  placement: Placement;
  activePlacements: PlacementInfo[] | null | undefined;
  credentials: Credentials;
  gridLogsData: IUserCommunicationLog;
  chatHistory: IUserCommunicationLog;
  communicationLog: IUserCommunicationLog; //Todo: update the interface to match notification log data
  defaultChannels?: any[];
  channelValues: any[];
  divisionType?: string;
  newAttachments?: any[];
  existingAttachments?: any[];
  selPreviewAttachments?: any[];
  historyAttachments?: any[];
  channels?: string[];
  loading: boolean;
  disableLoadMore?: boolean;
  scrolled?: boolean;
  reLoading?: boolean;
  isPreview?: boolean;
  isGridLoading: boolean;
  isFilterApplied?: boolean;
  isEducationActive?: boolean;
  referenceGrid?: { isLoading: boolean; data: any[] };
  kpis: any;
  shiftsRequested: string[];
  timeOffRequests: { startDate: string; endDate: string }[];
  contactDrawerOpened?: boolean;
  primaryEmailLastUpdated: string;
  secondaryEmailLastUpdated: string;
  primaryPhoneNumberLastUpdated: string;
  engagementDate: string;
  mobilePhoneNumberLastUpdated: string;
  covidVaccinationstatus: string;
  inactivityReasonId?: number;
  inactivityReason?: string;
  amieTravelerTimestamp?: string;
  addressData?: AddressData[];
  originalAddressData?: AddressData[];
  phoneNumbers?: IPhoneNumber[];
  applicationDate?: string;
  hasAcceptedMobileTermsAndConditions?: string;
  consentLink?: string;
  skillSetTree?: ISkillsetTree;
  addSkillSetTree?: ISkillsetTree;
  skillsChecklist?: any;
  masterCopyDropDowns?: any;
  disciplineData?: any;
  specialtyData?: any;
  isAddLicense?: boolean;
  addUnverifiedCredType?: string;
  auditData?: IAuditData;
  referenceData?: any;
  interviewReasonList?: any;
  changeHistory?: {
    rows?: IPlacementChangeHistory[];
    loading?: boolean;
  };
  references?: any;
  emrList?: IEmrList[];
  equipmentsProcedure: IEmrList[];
  bestTimeToContactId?: number;
  preferredTimeToReach?: IPreferredTimeToReach[];
  sellingNotesItems: any;
  raceEthnicity: {
    ID: string;
    Description: string;
  };
  raceEthnicityOptions: ITypeAheadOption[];
  ethnicityId?: string;
  genderSelection: {
    ID: string;
    Description: string;
  };
  genderOptions: ITypeAheadOption[];
  httpStatus: {
    statusCode: number | undefined;
    pageName: string | undefined;
  };
  ethnicityDescription?: string;
  viewSkillsChecklistPassport: ISkillCheckListItem;
  tagsList: any;
  candidateSSN: ICandidateSSN;
  candidateNUID: ICandidateNUID;
  candidateDateOfBirth: ICandidateDateOfBirth;
  candidateSaveStatus: ICandidateSaveStatus;
  placementSkillsetExperience: any;
  requestTimeOff: { id: number; key: string }[];
  applicationQuestions?: {
    licenseInvestigationSuspension: string;
    investigationSuspensionExplanation: string;
    usRightToWorkVerification: string;
    visaType: string;
  };
  requestReferenceSaveStatus: any;
  passportSkillChecklist: ISkillChecklistPassport;
  openSkillChecklistDocument: boolean;
  contactTabSaveStatus: {
    primaryPhoneNumberSaveResult: SaveStatusType;
    secondaryPhoneNumberSaveResult: SaveStatusType;
    primaryEmailSaveResult: SaveStatusType;
    secondaryEmailSaveResult: SaveStatusType;
    primaryPhoneExtNotesSaveResult: SaveStatusType;
    secondaryPhoneExtNotesSaveResult: SaveStatusType;
  };
  editExperience: IEditExperience;
  hearsayCertifications: IHearsayCertification[];
  interviews?: any;
  okToContact?: boolean;
  currentlyEmployed?: boolean;
  taxData?: ITaxData[];
  taxLetterInfo?: ITaxLetterInfo[];
  preferences?: ICandidatePreferences;
  editAuditData?: IEditAuditData;
  hasActivePlacements?: boolean;
  personalInfoError?: IPersonalInfoError[];
  candidateConsentLinks?: ICandidateConsentLinks[];
}

export interface ICandidateDetailsError {
  screen: string;
  isError: boolean | undefined;
}

export interface ISupervisorData {
  interested: boolean;
  availabilityDate: string | null;
  maxNumber: number;
  available: number;
  skillsets: ISupervisorSkillsets[];
  caseLoad: number;
}

export interface IPostSupervisorData {
  travelerId: number;
  interested: boolean;
  availabilityDate: string | null;
  maxNumber: number | null;
  skillsets?: IPostSupervisorSkillsets[];
  currentEmployeeId: number;
  currentEmployeeName: string;
}

export interface ISupervisorSkillsets {
  travelerSupervisorSkillsetID: number;
  disciplineId: number;
  discipline: string;
  specialtyId: number;
  specialty: string;
  statusId: number;
  status: string;
  reasonId: number;
  rejectionReason: string;
}

export interface IPostSupervisorSkillsets {
  travelerSupervisorSkillsetID: number;
  disciplineID: number;
  specialtyID: number;
  statusID: number;
  reasonID: number;
}
export interface IDeleteSupervisorSkillsets {
  candidateId: number;
  travelerSupervisorSkillsetIds: number[];
  currentEmployeeId: number;
  currentEmployeeName: string;
}

export interface IDeleteSupervisorSkillsetData {
  candidateId: number;
  travelerSupervisorSkillsetIds: number[];
  skillsets: any[];
  currentEmployeeId: number;
  currentEmployeeName: string;
}

export interface AddressData {
  travelerAddressId: number;
  addressTypeId: number;
  addressType: string;
  address1: string | null;
  address2: string | null;
  city: string | null;
  stateProvinceID: string | null;
  state: string | null;
  zip: string | null;
  country: string | null;
  latitude: number | null;
  longitude: number | null;
  dateMoveIn: string | null;
  dateMoveOut: string | null;
  lastUpdate: string | null;
  housingProperty: string | null;
  housingPropertyPhone: string | null;
  timeStamp?: string;
  addressDates?: AddressDates[];
}
export interface AddressDates {
  travelerAddressId: number;
  addressDateRangeID?: number;
  fromDate: string;
  toDate?: string;
  insertDate?: string;
  lastUpdateUserID: number;
  addressRoles: AddressRoles[];
  timeStamp?: string;
}
export interface AddressRoles {
  description: string;
  addressRoleID: number;
  selected: number;
}

export interface Audit {
  auditId: number;
  travelerId: number;
  holdFileOut: boolean;
  auditStatusDescription: string;
  auditTypeDescription: string;
  auditCategoryDescription: string;
  auditSubCategoryDescription: string;
}

export interface IAuditData {
  items: IAuditItems[];
  totals: IAuditTotals;
}

export interface IAuditItems {
  actionable: boolean;
  auditAction: string | null;
  auditActionId: number | null;
  auditCategory: string | null;
  auditCategoryId: number | null;
  auditId: number | null;
  auditTypeId: number | null;
  auditSubCategory: string | null;
  auditSubCategoryId: number | null;
  auditType: string | null;
  description: string | null;
  group: string | null;
  holdFileOut: boolean;
  insertDate: string | null;
  insertedByUser: string | null;
  insertedByUserId: number | null;
  lastUpdated: string | null;
  lastUpdatedById: number | null;
  lastUpdatedByUser: string | null;
  personResponsible: string | null;
  personResponsibleId: number | null;
  placementId: number | null;
  status: string | null;
  statusId: number | null;
  travelerId: number | null;
}

export interface IEditAuditData {
  editAuditDataDetails: any;
  rowData: any;
}

export interface IPersonalInfoError {
  isError: boolean;
  errorMessage: string;
  errorField: string;
}
export interface ICandidateConsentLinks {
  travelerID: string,
  consentLink: string
}

export interface ISecondaryResponsiblePartyList {
  employeeRoleId: number | null;
  role: string | null;
}

export interface IAuditTotals {
  fyionly: number;
  holdFileOut: number;
  ongoing: number;
  total: number;
}

export interface ITaxData {
  candidateId: number;
  taxDataID: number | null;
  type: string | null;
  allowances: number | null;
  maritalStatusID: number | null;
  maritalStatus: string | null;
  exemptStatus: string | null;
  addWithHoldings: string | null;
  otherWithHoldings: string | null;
  effectiveDate: string | null;
  currentEmployeeId: number;
  currentEmployeeName: string;
}

export interface ITaxLetterInfo {
  taxLetterInfoID: number | null;
  taxLetter: string | null;
  updateDate: string | null;
  insertDate: string | null;
}

export interface ISelectedTaxLetterInfo {
  taxLetterInfoID: number | null;
  pWithhold: string | null;
  pEffectiveDate: string | null;
}

export interface ICandidatePreferences {
  travelerId: number | null;
  housingInfoUpdated: Date | string | null;
  travelerKeyFactsUpdated: string | null;
  isVerifiedNoAdults: boolean | null;
  isVerifiedNoChildren: boolean | null;
  isVerifiedNoPets: boolean | null;
  travelsWithUpdated: Date | string | null;
  housingType: string | null;
  housingTypeId: number | null;
  furnitureExtras: IFurnitureExtra[];
  typeOfBeds: ITypeOfBed[];
  keyFacts: IKeyFact[];
  adults: IAdult[];
  children: IChild[];
  pets: IPet[];
  currentEmployeeId?: number | null;
  currentEmployeeName?: string | null;
}

export interface IFurnitureExtra {
  id: number | null;
  description: string | null;
}

export interface ITypeOfBed {
  id: number | null;
  description: string | null;
}

export interface IKeyFact {
  id: number | null;
  description: string | null;
}

export interface IAdult {
  id: number | null;
  name: string | null;
  relationship: string | null;
  isUIDeleted?: boolean;
}

export interface IChild {
  id: number | null;
  gender: string | null;
  birthYear: string | number | null;
  isUIDeleted?: boolean;
}

export interface IPet {
  id: number | null;
  breed: string | null;
  type: string | null;
  details: string | null;
  weight: string | number | null;
  isUIDeleted?: boolean;
}

export interface PlacementContact {
  contactName: string;
  name: Name2;
  phone: string;
  email: string;
  message: string;
}

export interface CommunicationLogPayload {
  brandId?: string;
  contactId: string;
  pageSize?: number;
  pageNumber?: number;
  channel?: string[];
  dated?: string;
  initiatedBy?: string;
  userRole?: string;
  startDate?: Date;
  endDate?: Date;
  periodType?: string;
}

//Personal Information
export interface IPersonalInformation {
  candidateId: number;
  brandId: number;
  firstName: string;
  lastName: string;
  middleName?: string | null;
  goesBy?: string | null;
  ssn: string;
  dob: string;
  primaryEmail: string;
  secondaryEmail: string;
  addresses: AddressData[] | undefined;
  phoneNumbers: IPhoneNumber[] | undefined;
  currentEmployeeId: number;
  currentEmployeeName: string;
  timeStamp?: string;
  //ethnicityId?: string;
  gender?: string;
  ethnicityDescription?: string;
  emergencyContactInfo?: {
    name: string;
    countryDialingCode: string;
    phone: string;
    extensionNotes: string;
    relationship: string;
  };
  applicationQuestions?: {
    licenseInvestigationSuspension: string;
    investigationSuspensionExplanation: string;
    usRightToWorkVerification: string;
    visaType: string;
  };
}

export interface IDeleteSkillSet {
  candidateId: number;
  disciplineId: number;
  specialtyId: number;
  subSpecialtyId: number;
  currentEmployeeId: number;
  currentEmployeeName: string;
}

export interface IAddress {
  travelerAddressId: number;
  addressTypeId: number;
  addressType: string;
  address1: string;
  address2?: string;
  city: string;
  stateProvinceID: string;
  state: string;
  zip?: string;
  country: string;
  extensionNotes?: string;
  latitude: number | null;
  longitude: number | null;
  dateMoveIn: string;
  dateMoveOut: string;
  lastUpdate: string;
  timeStamp?: string;
}

export interface IEducationInfo {
  candidateId: number;
  brandId: number;
  schoolId: number | null | undefined;
  graduatedOnYear: number | null | undefined;
  graduatedOnMonth: number | null | undefined;
  graduatedOn: string;
  school: string | null | undefined;
  majorId: number | null | undefined;
  major: string;
  degreeId: number | null | undefined;
  degree: string;
  location: {
    address1: string | null | undefined;
    address2: string | null;
    city: string;
    stateProvinceID: string | null | undefined;
    state: string | null | undefined;
    zip: string | null;
    country: string;
    countryId: number | null | undefined;
    latitude: number | null;
    longitude: number | null;
  };
  currentEmployeeId: number;
  currentEmployeeName: string;
}

export interface IReferenceData {
  candidateId: number;
  travelerReferenceID: number;
  workHistoryID: number;
  facilityName: string;
  startDate: string | null;
  endDate: string | null;
  city: string;
  state: string;
  supervisorName: string;
  title: string;
  referenceTypeID: number;
  referenceType: string;
  referenceDate: string | null;
  comments: string;
  email: string;
  contactPreferenceTypeID: number;
  contactPreferenceType: string;
  bestTimeToReachID: number;
  bestTimeToReach: string;
  currentEmployeeId: number;
  currentEmployeeName: string;
  phoneNumbers: {
    phoneTypeDescription: string;
    phoneTypeID: number;
    phoneNumber: string;
    timeStamp: string;
  }[];
}

export interface IMasterCopy {
  states: [] | any;
  countries: [] | any;
  degrees: [] | any;
  graduationMonth: [] | any;
  graduationYear: [] | any;
  majors: [] | any;
  disciplines: [] | any;
  specialties: [] | any;
  brands: [] | any;
  discSpecMapping: [] | any;
}

export interface IEmrList {
  id: number;
  value: string;
  name: string;
  labelPrefix: string;
  brandId?: number;
  candidateId?: number;
}

export interface IEmrListToTransform {
  description: string;
  equipmentID: number;
  brandId: number;
  candidateId: number;
}

export interface IPostEmrRequest {
  brandId: number;
  candidateId: number;
  currentEmployeeId: number;
  currentEmployeeName: string;
  equipmentIds: number[];
}

export interface IPersonalInfoNonFormFields {
  raceEthnicityOptions: ITypeAheadOption[];
  emergencyContactCountryCode: string;
  emergencyPhoneNumber: string;
}

export interface ICandidateSSN {
  candidateId: number;
  brandId: number;
  currentEmployeeId: number;
  currentEmployeeName: string;
  ssn: string;
  timestamp: string;
  ignoreTimestamp: boolean;
}

export interface ICandidateDateOfBirth {
  candidateId: number;
  dob: string;
  currentEmployeeId: number;
  currentEmployeeName: string;
  timestamp: string;
  ignoreTimestamp: boolean;
}

export interface ICandidateNUID {
  travelerId: number;
  nuid: string;
}

export interface ICandidateSaveStatus {
  ssnSaveStatus: SaveStatus;
  dobSaveStatus: SaveStatus;
  genderSaveStatus: SaveStatus;
}

export enum SaveStatus {
  Failure = 0,
  InProgress = 1,
  Success = 2,
  Undefined = '',
}

export enum ExperienceEditType {
  None,
  TimeOff,
  WorkExperience,
}

export interface IEditExperience {
  isModalOpen: boolean;
  type: ExperienceEditType;
  workExperience: any;
  timeOff: any;
  rowData: any;
}

export interface IHearsayCertificationRequestData {
  value: number;
  name: string;
  requestOptionTypeId?: number;
  issuedBy?: string;
  expirationDate?: string;
}

export interface IHearsayCertificationRequest {
  candidateId: number;
  brandId: number;
  data: IHearsayCertificationRequestData[];
}

export interface IHearsayLicense {
  travelerId: number;
  companyId: number;
  requirementTypeID: number;
  stateProvinceID: string;
  expirationDate: string;
  licenseNumber: string;
  requirementType: string;
}

export interface IDeleteHearsayLicense {
  travelerId: number;
  companyId: number;
  travelerHearsayLicensureId: number;
  currentEmployeeId: number;
  currentEmployeeName: string;
}

export interface IDeleteHearsayCertification {
  travelerId: number;
  companyId: number;
  hearsayCertificationId: number;
  travelerHearsayCertificationId: number;
  currentEmployeeId: number;
  currentEmployeeName: string;
}

export interface IHearsayLicenseRequest {
  candidateId: number;
  brandId: number;
  licenseName: string;
  data: IHearsayLicense;
}

export interface IAuditPostData {
  travelerId: number | null;
  auditId: number | null;
  auditTypeID: number | null;
  auditType: string | null;
  auditCategoryID: number | null;
  auditCategory: string | null;
  auditSubCategoryID: number | null;
  auditSubCategory: string | null;
  statusID: number | null;
  status: string | null;
  auditActionID: number | null;
  auditAction: string | null;
  personResponsibleID: number | null;
  personResponsible: string | null;
  description: string;
  holdFileOut: boolean;
  placementID: number | null;
  currentEmployeeId: number | null;
  currentEmployeeName: string | null;
}

export interface IDeleteAudit {
  travelerId: number;
  auditId: number;
  currentEmployeeId: number;
  currentEmployeeName: string;
}

export interface IDeleteTravelerPayroll {
  candidateId: number;
  taxDataIds: number[];
  currentEmployeeId: number;
  currentEmployeeName: string;
}

export interface IDeleteTravelerTaxInfo {
  candidateId: number;
  TaxLetterInfoIds: number[];
  currentEmployeeId: number;
  currentEmployeeName: string;
}

export interface IPostTaxLetterInfo {
  candidateId: number;
  taxLetterInfoID: number | null;
  taxLetter: string | null;
  updateDate: string | null;
  currentEmployeeId: number;
  currentEmployeeName: string;
}

export interface ISkillsetTree {
  verifiedByQM: boolean;
  skillsetStatusTimeStamp: any[];
  candidateSkillSets: ISkillsetTreeDiscipline[];
}

export interface ISkillsetTreeDiscipline {
  abbreviation: string;
  disciplineId: number;
  fullDescription: string;
  specialties: ISkillsetTreeSpecialty[];
  uiUnverifiedFlag: boolean;
  uiVerifiedFlag: boolean;
  unverified: boolean;
  verified: boolean;
  isAdded?: boolean;
}

export interface ISkillsetTreeSpecialty {
  active: boolean;
  description: string;
  fullDescription: string;
  specilityId: number;
  subSpecilities: ISkillsetTreeSubSpecialty[];
  uiUnverifiedFlag: boolean;
  uiVerifiedFlag: boolean;
  unverified: boolean;
  verified: boolean;
  isAdded?: boolean;
}

export interface ISkillsetTreeSubSpecialty {
  description: string;
  fullDescription: string;
  subSpecialtyID: number;
  uiUnverifiedFlag: boolean;
  uiVerifiedFlag: boolean;
  unverified: boolean;
  verified: boolean;
  isAdded?: boolean;
}

export interface IUpdateSkillsetTreeData {
  candidateId: number;
  skillsetTree: ISkillsetTree;
  currentEmployeeId: number;
  currentEmployeeName: string;
}
