import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomRangeDropdown, { isInstanceOfIRange } from '../common/CustomRangeDropdown';
import { numberType } from '../common/CustomRangeDropdown';
import { ContractType, getShiftId, RateElemenetSource, RateElements, refactorValue } from '../helper';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { selectUser } from 'oidc/user.selectors';
import {
  selectContractDetails,
  selectBillRateSelections,
  selectOcbrDetails,
  selectCreateOrderMiscellaneousData,
  selectRateFeatureProps,
  selectOcbrSavedDetails,
} from 'store/redux-store/create-order-ocbr/selector';
import { createOrderOcbrActions } from 'store/redux-store/create-order-ocbr/slice';
import { IShiftContract } from 'store/redux-store/create-order-ocbr/types';
import { extractBillRates, extractSavedBillRates } from './helper';
import { Concatenate } from 'utils/string/string';

export const BillRateField = ({ label, name, shiftRates = [], shiftName }) => {
  const { t } = useTranslation();
  const { billRates: ocbrDetails, effectiveDates: ocbrEffectiveDates, isFlatRate } = useSelector(selectOcbrDetails);
  const { billRates: ocbrSavedDetails } = useSelector(selectOcbrSavedDetails);
  const dispatch = useDispatch();
  const contractDetails = useSelector(selectContractDetails);
  const { userInfo } = useSelector(selectUser);
  const { uniqueShifts } = useSelector(selectRateFeatureProps);

  const rateSelections = useSelector(selectBillRateSelections);
  const discipline = useSelector(selectCreateOrderMiscellaneousData);

  const { value, ocbr } = React.useMemo(() => {
    const selection = rateSelections[name];
    return !selection ? { value: null, ocbr: ContractType.OnContract } : selection;
  }, [rateSelections]);

  const handleChange = newValue => {
    dispatch(
      createOrderOcbrActions.setBillRateSelection({
        [name]: {
          value: newValue,
          ocbr: determineOcbr(newValue, contractDetails, shiftRates, uniqueShifts, isFlatRate),
        },
      }),
    );
  };
  const checkIsBillRateOcbr = newValue => {
    if (newValue?.statusId) {
      return true;
    }
    const billrateMatched = (contractDetails?.billRates || []).find(
      item =>
        item?.disciplineId === shiftRates[0]?.disciplineId &&
        item?.specialtyId === shiftRates[0]?.specialtyId &&
        item?.subspecialtyId === shiftRates[0]?.subSpecialtyId,
    );
    //Check for wild card bill rate
    let wildCardBillRate = null;
    if (billrateMatched?.shifts.length > 1) {
      wildCardBillRate = billrateMatched?.shifts.find((item: IShiftContract) => item?.shiftId === null);
    }
    const shiftRatesCopy = [...shiftRates];
    //CASE 1 No shift  and single bill rate available in contract
    const isTrueCase1 = billrateMatched?.shifts.length === 1 && billrateMatched?.shifts[0].shiftId === null;
    //Case 2: Contract has single bill rate tied to single shift:
    const isTrueCase2 = billrateMatched?.shifts.length === 1 && billrateMatched?.shifts[0].shiftId !== null;

    //Case 3 Contract has single bill rate tied to multiple shifts:
    const valueArr = shiftRatesCopy.map(p => {
      if (p?.value) return p.value;
    });
    const isSameValue = new Set(valueArr).size === 1 && shiftRatesCopy.length > 0;

    const contractShiftArr = shiftRatesCopy.map(p => {
      if (p?.groupShiftId) return p?.groupShiftId;
    });
    const selectedShiftArr = uniqueShifts.map(item => getShiftId(item));
    const isTrueCase3 = _.isEqual(contractShiftArr, selectedShiftArr) && isSameValue;

    //CASE 5 - WIldcard
    const isTrueCase5 = wildCardBillRate && refactorValue(wildCardBillRate?.regularRate, 2) === newValue?.value;

    if (shiftRates?.length > 0) {
      const contractValues = shiftRates?.filter(item => item.source === RateElemenetSource.Contract);
      const foundMatch = contractValues?.some(item => {
        if (isInstanceOfIRange(item) && isInstanceOfIRange(newValue)) {
          return item?.min?.value === newValue?.min?.value && item?.max?.value === newValue?.max?.value;
        } else if (isInstanceOfIRange(item) && !isInstanceOfIRange(newValue)) {
          return false;
        } else if (!isInstanceOfIRange(item) && isInstanceOfIRange(newValue)) {
          return false;
        } else {
          return item?.value === newValue?.value;
        }
      });
      //contract has multiple shits and flat rate toggle is on
      if (isFlatRate) {
        if (isTrueCase1) {
          return foundMatch ? false : true;
        } else if (isTrueCase2) {
          return true;
        } else if (isTrueCase5) {
          return false;
        } else if (shiftRatesCopy.length > 0) {
          return isTrueCase3 ? false && !foundMatch : true;
        }
      } else {
        //Case 5 if wild card bill rate present
        if (isTrueCase5) {
          return false;
        } else if (isTrueCase2 && foundMatch && !uniqueShifts.length) {
          //single billrate with singkle shift in contract,Autopopulated value ,No shift in order
          //UI show as pass but pass fail not applied
          return null;
        } else if (isSameValue && foundMatch && !uniqueShifts.length) {
          //same bill rate tied to multiple shift and order has no shift
          //UI show as pass but pass fail not applied
          return null;
        } else {
          //If no billrates
          return isInstanceOfIRange(newValue) && !newValue?.min?.value && !newValue?.max?.value ? true : !foundMatch;
        }
      }
    }
  };

  /**
   * Updates the Redux with new  value when user enters a billrate
   * @param newVal the values from input field
   */
  const updateBillRates = newVal => {
    const isOcbr = checkIsBillRateOcbr(newVal);
    const avoidFlatRate = false;
    if (newVal !== undefined) {
      const billRates = extractBillRates(
        newVal,
        ocbrDetails,
        uniqueShifts,
        shiftRates[0],
        isOcbr,
        isFlatRate,
        RateElements.TravelBillRate,
        ocbrEffectiveDates,
        contractDetails,
        Concatenate([userInfo.firstName, userInfo.lastName], ' '),
        shiftRates,
        avoidFlatRate,
        discipline,
        shiftName,
      );
      const savedBillRates = extractSavedBillRates(
        newVal,
        ocbrSavedDetails,
        uniqueShifts,
        shiftRates[0],
        isOcbr,
        isFlatRate,
        RateElements.TravelBillRate,
        ocbrEffectiveDates,
        contractDetails,
        Concatenate([userInfo.firstName, userInfo.lastName], ' '),
        shiftRates,
        avoidFlatRate,
        discipline,
        shiftName,
      );
      dispatch(createOrderOcbrActions.setOcbrSavedBillRates(savedBillRates));
      dispatch(createOrderOcbrActions.business_updateBillRates({ billRates }));
      dispatch(createOrderOcbrActions.business_deriveRateSelections({ isFlatRate, billRates }));
    }
  };

  return (
    <>
      <CustomRangeDropdown
        defaultValue=""
        value={value}
        options={shiftRates}
        onChange={newVal => {
          handleChange(newVal);
        }}
        onBlur={newVal => {
          updateBillRates(newVal);
        }}
        label={label}
        name={name}
        labelPrefix="$"
        labelPostfix={undefined}
        type={numberType.currency}
        errorMessage={t('order.newOrder.rateCard.minMaxRangeError')}
        isChanged={ocbr}
      />
    </>
  );
};

export const determineOcbr = (value, contractDetails, shiftRates, uniqueShifts, isFlatRate) => {
  if (value?.statusId) {
    return value.statusId;
  } else if (value === null) {
    return ContractType.OnContract;
  } else {
    const billrateMatched = (contractDetails?.billRates || []).find(
      item =>
        item?.disciplineId === shiftRates[0]?.disciplineId &&
        item?.specialtyId === shiftRates[0]?.specialtyId &&
        item?.subspecialtyId === shiftRates[0]?.subSpecialtyId,
    );
    //Check for wild card bill rate
    const wildCardBillRate =
      billrateMatched?.shifts.length > 1 ? billrateMatched?.shifts.find(item => item?.shiftId === null) : null;

    const shiftRatesCopy = [...shiftRates];
    //CASE 1 No shift  and single bill rate available in contract
    const isTrueCase1 = billrateMatched?.shifts.length === 1 && billrateMatched?.shifts[0].shiftId === null;
    //Case 2: Contract has single bill rate tied to single shift:
    const isTrueCase2 = billrateMatched?.shifts.length === 1 && billrateMatched?.shifts[0].shiftId !== null;

    //Case 3 Contract has single bill rate tied to multiple shifts:
    const valueArr = shiftRatesCopy.map(p => {
      if (p?.value) return p.value;
    });
    const isSameValue = new Set(valueArr).size === 1 && shiftRatesCopy.length > 0;

    const contractShiftArr = shiftRatesCopy.map(p => {
      if (p?.groupShiftId) return p?.groupShiftId;
    });
    const selectedShiftArr = uniqueShifts.map(item => getShiftId(item));
    const isTrueCase3 = _.isEqual(contractShiftArr, selectedShiftArr) && isSameValue;

    //CASE 5 - WIldcard
    const isTrueCase5 = wildCardBillRate && refactorValue(wildCardBillRate?.regularRate, 2) === value?.value;

    if (shiftRates?.length > 0) {
      const contractValues = shiftRates?.filter(item => item.source === RateElemenetSource.Contract);
      const foundMatch = contractValues?.some(item => {
        if (isInstanceOfIRange(item) && isInstanceOfIRange(value)) {
          return item?.min?.value === value?.min?.value && item?.max?.value === value?.max?.value;
        } else if (isInstanceOfIRange(item) && !isInstanceOfIRange(value)) {
          return false;
        } else if (!isInstanceOfIRange(item) && isInstanceOfIRange(value)) {
          return false;
        } else {
          return item?.value === value?.value;
        }
      });
      //contract has multiple shits and flat rate toggle is on
      if (isFlatRate) {
        if (isTrueCase1) {
          return foundMatch ? ContractType.OnContract : ContractType.OffContract;
        } else if (isTrueCase2) {
          return ContractType.OffContract;
        } else if (isTrueCase5) {
          return ContractType.OnContract;
        } else if (shiftRatesCopy.length > 0) {
          return isTrueCase3 ? ContractType.OnContract : ContractType.OffContract;
        }
      } else {
        //Case 5 if wild card bill rate present
        if (isTrueCase5) {
          return ContractType.OnContract;
        } else if (isTrueCase2 && foundMatch && !uniqueShifts.length) {
          //single billrate with singkle shift in contract,Autopopulated value ,No shift in order
          //UI show as pass but pass fail not applied
          return ContractType.OnContract;
        } else if (isSameValue && foundMatch && !uniqueShifts.length) {
          //same bill rate tied to multiple shift and order has no shift
          //UI show as pass but pass fail not applied
          return ContractType.OnContract;
        } else {
          //If no billrates
          return isInstanceOfIRange(value) && !value?.min?.value && !value?.max?.value
            ? ContractType.OffContract
            : !foundMatch
            ? ContractType.OffContract
            : ContractType.OnContract;
        }
      }
    }
  }
};
