import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMultiTypeAheadOption } from 'app/components/Common/TypeAheads/MultiSelectTypeAhead';
import {
  CandidateJobPreference,
  PreferredWeeklyPayRate,
  PreferredStartDate,
} from 'app/models/Candidate/CandidateJobPreference';
import { DeepMap, FormState } from 'react-hook-form';
import { ICheckbox } from '../CustomComponents/CheckboxContainer';
import { ITypeAheadOption } from '../CustomComponents/ControlledTypeAhead';
import {
  GetFacilityTypeCheckboxes,
  GetJobExpLevelsCheckboxes,
  GetPlacementTypeCheckboxes,
  GetPlacementLengthCheckboxes,
  GetShiftLengthCheckboxes,
  GetShiftTimeCheckboxes,
} from '../FormatData';

export interface IPreference {
  preferenceAPIresponse: CandidateJobPreference;
  formData: IPreferenceForm;
  preferenceSaveEvent: boolean;
  recruiterEnterdDataExists: boolean;
  miscellaneousSpecs: {
    disciplineOpts: ITypeAheadOption[];
    specialtyOpts: ITypeAheadOption[];
  };
}

export interface IMiscellaneousFields {
  avStartDate: string | null;
  isStartDateReadOnly: boolean;
  weeklyPayRate: number | string | null;
  isPayRateReadOnly: boolean;
}
export interface IPreferenceForm {
  skillSets: { discipline: ITypeAheadOption; specialty: ITypeAheadOption | undefined; isReadOnly: boolean }[];
  clientSettings: IMultiTypeAheadOption[];
  locations: { location: ITypeAheadOption; radius: ITypeAheadOption | null; isReadOnly: boolean }[];
  placementTypes: ICheckbox[];
  jobExperiences: ICheckbox[];
  shiftTimes: ICheckbox[];
  shiftLengths: ICheckbox[];
  placementLengths: ICheckbox[];
  facilityTypes: ICheckbox[];
  timeOffRequests: { key: number; value: { start: string; end: string }; isReadOnly: boolean }[];
  miscellaneousFields: IMiscellaneousFields;
}

export const initialPreferenceState: CandidateJobPreference = {
  candidateId: 0,
  disciplinesAndSpecialties: [],
  placementLengths: [],
  locations: [],
  preferredStartDate: {} as PreferredStartDate,
  placementTypes: [],
  jobExperienceLevels: [],
  clientSettings: [],
  shiftTimes: [],
  shiftLengths: [],
  facilityTypes: [],
  timeOffRequests: [],
  preferredWeeklyPayRate: {} as PreferredWeeklyPayRate,
};

export const initialFormState: IPreferenceForm = {
  skillSets: [],
  clientSettings: [],
  locations: [],
  placementTypes: GetPlacementTypeCheckboxes(),
  jobExperiences: GetJobExpLevelsCheckboxes(),
  shiftTimes: GetShiftTimeCheckboxes(),
  shiftLengths: GetShiftLengthCheckboxes(),
  placementLengths: GetPlacementLengthCheckboxes(),
  facilityTypes: GetFacilityTypeCheckboxes(),
  timeOffRequests: [],
  miscellaneousFields: {
    avStartDate: null,
    isStartDateReadOnly: false,
    weeklyPayRate: '',
    isPayRateReadOnly: false,
  },
};

export type PreferenceFormState = FormState<{
  skillSet: any[];
  preferredLocations: any[];
  placementTypes: any[];
  jobExperiences: any[];
  clientSettings: any[];
  facilityTypes: any[];
  placementLengths: any[];
  shiftLengths: any[];
  shiftTimes: any[];
  weeklyPayRate: any;
  avStartDate: any;
  timeOffRequests: any[];
}>;

export type PreferenceDirtyFields = DeepMap<
  {
    skillSet: { discipline: ITypeAheadOption; specialty: ITypeAheadOption | undefined; isReadOnly: boolean }[];
    clientSettings: IMultiTypeAheadOption[];
    preferredLocations: { location: ITypeAheadOption; radius: ITypeAheadOption | null; isReadOnly: boolean }[];
    placementTypes: ICheckbox[];
    jobExperiences: ICheckbox[];
    shiftTimes: ICheckbox[];
    shiftLengths: ICheckbox[];
    placementLengths: ICheckbox[];
    facilityTypes: ICheckbox[];
    avStartDate: Date;
    weeklyPayRate: number;
    timeOffRequests: { key: number; value: { start: Date; end: Date }; isReadOnly: boolean }[];
  },
  true
>;

const requestPreferencesAction = createAction<{ candidateId: number; recruiterId: number | null }>('GET_PREFERENCES');
const savePreferencesAction = createAction<{
  formData: any;
  dirtyFields: PreferenceDirtyFields;
  userData: any;
}>('SAVE_PREFERENCES');

const preferenceSlice = createSlice({
  name: 'preferences',
  initialState: {
    preferenceAPIresponse: initialPreferenceState,
    formData: initialFormState,
    preferenceSaveEvent: false,
    recruiterEnterdDataExists: false,
    miscellaneousSpecs: {
      disciplineOpts: [] as ITypeAheadOption[],
      specialtyOpts: [] as ITypeAheadOption[],
    },
  },
  reducers: {
    setPreferences(state, action: PayloadAction<IPreference>) {
      return {
        ...action.payload,
      };
    },
    setSkillSetsChosen(
      state,
      action: PayloadAction<
        { discipline: ITypeAheadOption; specialty: ITypeAheadOption | undefined; isReadOnly: boolean }[]
      >,
    ) {
      state.formData.skillSets = action.payload;
    },
    setClientSettingsChosen(state, action: PayloadAction<IMultiTypeAheadOption[]>) {
      state.formData.clientSettings = action.payload;
    },
    setLocationsChosen(
      state,
      action: PayloadAction<{ location: ITypeAheadOption; radius: ITypeAheadOption | null; isReadOnly: boolean }[]>,
    ) {
      state.formData.locations = action.payload;
    },
    setPlacementTypeCheckboxes(state, action: PayloadAction<ICheckbox[]>) {
      state.formData.placementTypes = action.payload;
    },
    setJobExperiencesCheckboxes(state, action: PayloadAction<ICheckbox[]>) {
      state.formData.jobExperiences = action.payload;
    },
    setShiftTimeCheckBoxes(state, action: PayloadAction<ICheckbox[]>) {
      state.formData.shiftTimes = action.payload;
    },
    setShiftLengthCheckBoxes(state, action: PayloadAction<ICheckbox[]>) {
      state.formData.shiftLengths = action.payload;
    },
    setPlacementLengthCheckBoxes(state, action: PayloadAction<ICheckbox[]>) {
      state.formData.placementLengths = action.payload;
    },
    setFacilityTypeCheckBoxes(state, action: PayloadAction<ICheckbox[]>) {
      state.formData.facilityTypes = action.payload;
    },
    setTimeOffRequestsChosen(
      state,
      action: PayloadAction<{ key: number; value: { start: string; end: string }; isReadOnly: boolean }[]>,
    ) {
      state.formData.timeOffRequests = action.payload;
    },
    setMiscellaneousFields(
      state,
      action: PayloadAction<{
        avStartDate?: string | null;
        isStartDateReadOnly?: boolean;
        weeklyPayRate?: number | null;
        isPayRateReadOnly?: boolean;
      }>,
    ) {
      if (action.payload.avStartDate !== undefined)
        state.formData.miscellaneousFields.avStartDate = action.payload.avStartDate;
      if (action.payload.isStartDateReadOnly !== undefined)
        state.formData.miscellaneousFields.isStartDateReadOnly = action.payload.isStartDateReadOnly;
      if (action.payload.weeklyPayRate !== undefined)
        state.formData.miscellaneousFields.weeklyPayRate = action.payload.weeklyPayRate;
      if (action.payload.isPayRateReadOnly !== undefined)
        state.formData.miscellaneousFields.isPayRateReadOnly = action.payload.isPayRateReadOnly;
    },
    setPreferenceSaveEvent(state, action: PayloadAction<boolean>) {
      state.preferenceSaveEvent = action.payload;
    },
    setRecruiterEnterdDataExists(state, action: PayloadAction<boolean>) {
      state.recruiterEnterdDataExists = action.payload;
    },
    setMiscellaneousSpecsByKey(state, action: PayloadAction<{ key: 'disciplineOpts' | 'specialtyOpts'; data: any }>) {
      return {
        ...state,
        miscellaneousSpecs: {
          ...state.miscellaneousSpecs,
          [action.payload.key]: action.payload.data,
        },
      };
    },
  },
});

const { actions } = preferenceSlice;

export const { name: preferenceSliceKey, reducer: preferenceReducer } = preferenceSlice;
export const preferenceActions = {
  ...actions,
  requestPreferencesAction,
  savePreferencesAction,
};
