/* eslint-disable tss-unused-classes/unused-classes */
import { FormControl, TextField } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { NewOrder } from 'app/models/Orders/OrderDetails';
import React from 'react';

const useStyles = makeStyles<void, 'focused'>()((theme, _params, classes) => ({
  element: {
    width: '100%',
    height: '100%',
  },
  root: {
    border: '1px solid #d2d2d1',
    overflow: 'hidden',
    fontSize: 16,
    borderRadius: 4,
    backgroundColor: '#fff',
    '&:hover': {
      borderColor: '#aaa',
      borderRadius: 4,
      backgroundColor: '#fff',
    },
    [`&.${classes.focused}`]: {
      borderColor: '#aaa',
      backgroundColor: '#fff',
    },
  },
  focused: {},
  labelRoot: {
    fontSize: 16,
    '&:focus': {
      color: '#000',
    },
  },
}));

export const TextEntryElement = ({
  order,
  onOrderPropertyChange,
  label,
  name,
  id,
}: {
  order: NewOrder;
  onOrderPropertyChange: (property: string, value: any) => void;
  label: string;
  name: string;
  id?: string;
}) => {
  const { classes } = useStyles();

  const handleChange = event => {
    onOrderPropertyChange(name, event.target.value);
  };

  return (
    <FormControl data-testid="test text element" variant="filled" className={classes.element}>
      <TextField
        id={id}
        variant="filled"
        label={label}
        value={order[name]}
        onChange={handleChange}
        InputProps={{
          inputProps: { maxLength: 120 },
          classes: classes,
          disableUnderline: true,
        }}
        InputLabelProps={{
          classes: { root: classes.labelRoot },
        }}
      />
    </FormControl>
  );
};
