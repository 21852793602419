import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Link } from 'amn-ui-core';
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Button,
  Container,
  Box,
  Paper,
  AppBar,
  Toolbar,
  Typography,
  Donut,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(theme => ({
  title: {
    flexGrow: 1,
  },
  formContainer: {
    marginTop: '40px',
    padding: '20px',
  },
  formControl: {
    marginBottom: '10px',
  },
}));

interface IExampleFormValues {
  firstName: string;
  lastName: string;
  email: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

interface IExampleFormProps {
  onSubmit(formValue: IExampleFormValues): void;
}

export const ExampleForm: React.FC<IExampleFormProps> = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const initialFormValues: IExampleFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  };

  const formValidationSchema = Yup.object({
    firstName: Yup.string().required('First name is required.'),
    lastName: Yup.string().required('Last name is required.'),
    email: Yup.string().required('Email is required.').email('Email must be valid.'),
    address1: Yup.string().required('Address is required.'),
    address2: Yup.string(),
    city: Yup.string().required('City is required.'),
    state: Yup.string().required('State is required.'),
    zip: Yup.string().required('Zip is required.'),
  });

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {t('exampleForm.formHeader')}
          </Typography>
          <Link href="/">
            {t('global.home')}
          </Link>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm">
        <Paper elevation={2} className={classes.formContainer}>
          <Formik onSubmit={props.onSubmit} initialValues={initialFormValues} validationSchema={formValidationSchema}>
            {formProps => (
              <Form>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Field name="firstName">
                      {({ field, form }) => (
                        <FormControl
                          fullWidth
                          error={form.errors.firstName && form.touched.firstName}
                          className={classes.formControl}
                        >
                          <InputLabel htmlFor="firstName">{t('exampleForm.labelFirstName')}</InputLabel>
                          <Input type="text" id="firstName" {...field} />
                          <FormHelperText>{form.errors.firstName ? form.errors.firstName : ''}</FormHelperText>
                        </FormControl>
                      )}
                    </Field>
                    <Box width="40px" />
                    <Field name="lastName">
                      {({ field, form }) => (
                        <FormControl
                          error={form.errors.lastName && form.touched.lastName}
                          fullWidth
                          className={classes.formControl}
                        >
                          <InputLabel htmlFor="lastName">{t('exampleForm.labelLastName')}</InputLabel>
                          <Input type="text" id="lastName" {...field} />
                          <FormHelperText>{form.errors.lastName ? form.errors.lastName : ''}</FormHelperText>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Field name="email">
                    {({ field, form }) => (
                      <FormControl error={form.errors.email && form.touched.email} className={classes.formControl}>
                        <InputLabel htmlFor="email">{t('exampleForm.labelEmail')}</InputLabel>
                        <Input type="email" id="email" {...field} />
                        <FormHelperText>
                          {form.errors.email ? form.errors.email : "We'll never share your email."}
                        </FormHelperText>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="address1">
                    {({ field, form }) => (
                      <FormControl
                        error={form.errors.address1 && form.touched.address1}
                        fullWidth
                        className={classes.formControl}
                      >
                        <InputLabel htmlFor="address1">{t('exampleForm.labelAddress1')}</InputLabel>
                        <Input type="text" id="address1" {...field} />
                        <FormHelperText>{form.errors.address1 ? form.errors.address1 : ''}</FormHelperText>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="address2">
                    {({ field, form }) => (
                      <FormControl
                        error={form.errors.address2 && form.touched.address2}
                        fullWidth
                        className={classes.formControl}
                      >
                        <InputLabel htmlFor="address2">{t('exampleForm.labelAddress2')}</InputLabel>
                        <Input type="text" id="address2" {...field} />
                        <FormHelperText>{form.errors.address2 ? form.errors.address2 : ''}</FormHelperText>
                      </FormControl>
                    )}
                  </Field>
                  <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Field name="city">
                      {({ field, form }) => (
                        <FormControl
                          error={form.errors.city && form.touched.city}
                          fullWidth
                          className={classes.formControl}
                        >
                          <InputLabel htmlFor="city">{t('exampleForm.labelCity')}</InputLabel>
                          <Input type="text" id="city" {...field} />
                          <FormHelperText>{form.errors.city ? form.errors.city : ''}</FormHelperText>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="state">
                      {({ field, form }) => (
                        <FormControl error={form.errors.state && form.touched.state} className={classes.formControl}>
                          <InputLabel htmlFor="state">{t('exampleForm.labelState')}</InputLabel>
                          <Input type="text" id="state" {...field} />
                          <FormHelperText>{form.errors.state ? form.errors.state : ''}</FormHelperText>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="zip">
                      {({ field, form }) => (
                        <FormControl
                          error={form.errors.zip && form.touched.zip}
                          fullWidth
                          className={classes.formControl}
                        >
                          <InputLabel htmlFor="zip">{t('exampleForm.labelZip')}</InputLabel>
                          <Input type="text" id="zip" {...field} />
                          <FormHelperText>{form.errors.zip ? form.errors.zip : ''}</FormHelperText>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Button type="submit" color="primary">
                    {t('global.button.submit')}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Paper>
        <Donut
          colors={['#50DDD3', '#8D78ED', '#6639B7']}
          data={[
            {
              name: 'Group A',
              value: 435,
            },
            {
              name: 'Group B',
              value: 260,
            },
            {
              name: 'Group C',
              value: 275,
            },
          ]}
          labelTitle="all orders"
          onClick={undefined}
        />
      </Container>
    </>
  );
};
