import React from 'react';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { usePromiseTracker } from 'react-promise-tracker';
import { selectSaveModalState } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';

export const InternalContactSaveModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(facilityActions.setSaveModalState({ open: false, data: undefined }));
  };

  const { promiseInProgress: isSaving } = usePromiseTracker({
    area: 'update-facility-internal-contact',
    delay: 0,
  });

  const { open, data } = useSelector(selectSaveModalState);

  const handleNoAction = () => {
    dispatch(
      facilityActions.requestUpdateInternalContact({
        updateInternalContact: data.updatedInternalContactDetail,
        setInternalContact: data.displayInternalContact,
      }),
    );
    handleClose();
  };
  const handleYesAction = () => {
    dispatch(
      facilityActions.requestUpdateInternalContact({
        updateInternalContact: { ...data.updatedInternalContactDetail, isParentUpdateRequired: true },
        setInternalContact: data.displayInternalContact,
      }),
    );
  };

  return (
    <GenericDialog
      open={open}
      maxWidth="sm"
      dialogTitleProps={{
        text: t('facility.internalContacts.saveModal.applyUpdate'),
      }}
      dialogActions={[
        {
          text: t('detailsPage.confirmation.cancel'),
          onClick: () => handleClose(),
          disabled: isSaving,
        },
        {
          text: t('detailsPage.confirmation.no'),
          onClick: handleNoAction,
          variant: 'contained',
          disabled: isSaving,
          color: 'tertiary',
        },
        {
          text: t('detailsPage.confirmation.yes'),
          onClick: handleYesAction,
          variant: 'contained',
          loading: isSaving,
          disabled: isSaving,
        },
      ]}
    >
      {`Would you also like to update the ${data?.updatedInternalContactDetail?.key} at the Order and Placement level?`}
    </GenericDialog>
  );
};
