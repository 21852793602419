import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { selectSessionLoaded } from 'app/ApplicationRoot/Global.selectors';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { decrementSession, getSession, incrementSession, StorageType } from './sessionHelpers';

export const useSessionStorage = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    window.addEventListener(
      'load',
      confirmEntrance(() => {
        dispatch(globalActions.setSessionLoaded());
      }),
    );
    return () => {
      window.removeEventListener('load', confirmEntrance());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    window.addEventListener('unload', confirmExit);
    return () => {
      window.removeEventListener('unload', confirmExit);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function confirmExit() {
    decrementSession(true, StorageType.localStorage);
  }

  const confirmEntrance = (afterLoad?: () => void) => () => {
    if (!pageAccessedByReload && getSession(StorageType.localStorage).count === 0)
      decrementSession(false, StorageType.localStorage);
    incrementSession(StorageType.localStorage);
    afterLoad?.();
  };

  const pageAccessedByReload =
    (window.performance.navigation && window.performance.navigation.type === 1) ||
    window.performance
      .getEntriesByType('navigation')
      .map(nav => nav.entryType)
      .includes('reload');
};

export const useSessionLoaded = () => {
  const loaded = useSelector(selectSessionLoaded);
  return loaded;
};
