import React, { useMemo, useState } from 'react';
import { Button, ClickAwayListener, Fade, Grid, Paper, Popper } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { EnableAppScroll } from 'app/layout/Layout';
import { useEffect } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import _, { isArray, throttle } from 'lodash';
import { ISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import { CustomTypeAhead } from 'app/ComponentLibrary/Filter/TypeAheadDropdown/CustomTypeAhead';

const useStyles = makeStyles<{
  open: boolean;
  isSelected: boolean;
  removeActionContainer: boolean;
  anchorRef: boolean;
}>()((theme, props) => ({
  popper: {
    marginTop: 4,
    zIndex: 5000,
  },
  paperDropdown: {
    boxShadow: '0px 2px 8px #0000004D',
  },
  headerContainer: {
    minWidth: 250,
    padding: '12px 20px 6px',
  },
  actionContainer: {
    visibility: props.removeActionContainer ? 'hidden' : undefined,
    backgroundColor: '#ffffff',
    borderTop: props.removeActionContainer ? undefined : '1px solid #cccccc',
    padding: props.removeActionContainer ? 0 : '6px 20px',
    height: props.removeActionContainer ? 0 : undefined,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  button: {
    fontSize: 14,
    textTransform: 'none',
    '&:hover, &:focus': {
      background: 'none',
    },
  },
  inActive: {
    color: '#888888',
  },
  clearAllButton: {
    padding: 0,
  },
  actionButton: {
    padding: '6px 0px',
  },
  countTag: {
    color: '#555555',
    fontSize: 14,
    fontWeight: 600,
  },
}));

export const TypeAheadCustomDropdown = props => {
  const {
    isMultiSelect,
    value: optionsChoosed,
    open = false,
    selected,
    selectAllValue,
    options: providedOptions,
    hiddenInput,
    /** Prop to utilize external anchor element */
    anchorRef,
    anchorEl,
    removeActionContainer = false,
  } = props;
  const { t } = useTranslation();
  const [options, setOptions] = useState<ISelection[]>([]);
  const [anchorEl2, setAnchorEl2] = useState<HTMLElement | null>(anchorEl);
  const trackerArea = `${props.name}-async-typeAhead`;
  const { promiseInProgress } = usePromiseTracker({ area: trackerArea, delay: 0 });
  const [isNewSelectionApplied, setIsNewSelectionApplied] = useState<boolean>(false);
  const prevState = optionsChoosed;
  const { classes } = useStyles({
    isSelected: isMultiSelect ? optionsChoosed && optionsChoosed.length > 0 : optionsChoosed !== null,
    open,
    anchorRef,
    removeActionContainer,
  });

  const fetchOptions = async request => {
    const response = await props.fetchOptions(request);
    setOptions(
      (response || []).map(opt => ({
        id: opt.value,
        name: opt.name,
        value: opt.value,
      })),
    );
  };

  const onApplyEvent = () => {
    setIsNewSelectionApplied(true);
    if (isArray(optionsChoosed)) {
      const selectedValues = _.cloneDeep(optionsChoosed).sort(function (a, b) {
        return a.value === b.value ? 0 : a.value > b.value ? 1 : -1;
      });
      props.apply(selectedValues);
    } else {
      props.apply(optionsChoosed);
    }
    closeActions();
  };

  const closeActions = () => {
    EnableAppScroll();
    setAnchorEl2(null);
    props.showPopper(false);
  };

  const cancelChanges = () => {
    props.onChange(selected);
    closeActions();
    props.showPopper(false);
  };

  const clearAll = () => props.onChange(isMultiSelect ? [] : null);

  const handleClose = () => {
    !isNewSelectionApplied && props.onChange(prevState);
    closeActions();
  };

  const throttleService = useMemo(
    () => throttle(request => trackPromise(fetchOptions(request), trackerArea), 2000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const configureMultiSelectTag = () =>
    (options && options.length && selectAllValue && optionsChoosed && optionsChoosed.length === options.length - 1) ||
    (optionsChoosed && optionsChoosed.some(item => item.value === selectAllValue))
      ? `${t('search.filter.allTag')} (${options.length ? options.length - 1 : 0})`
      : optionsChoosed && optionsChoosed.length
      ? `(${optionsChoosed.length})`
      : '';

  useEffect(
    () => {
      /** Condition ensures selectAllValue is removed if any options are manually removed - say chip deletion */
      if (
        isMultiSelect &&
        selectAllValue &&
        options &&
        optionsChoosed?.length !== options.length &&
        optionsChoosed?.some(item => item.value === selectAllValue)
      )
        props.onChange(_.cloneDeep(optionsChoosed).splice(1, optionsChoosed.length - 1));
      if (isMultiSelect) {
        configureMultiSelectTag();
      }
      return EnableAppScroll();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(optionsChoosed), JSON.stringify(options)],
  );

  useEffect(() => {
    if ((providedOptions || []).length > 0 && !props.fetchOptions) {
      setOptions(providedOptions);
    }
  }, [props.fetchOptions, providedOptions]);

  return (
    <Popper className={classes.popper} open={open} anchorEl={anchorEl2} placement="bottom-start">
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={cancelChanges}>
          <Fade {...TransitionProps} in={open} timeout={300}>
            <Paper classes={{ root: classes.paperDropdown }}>
              <Grid
                item
                container
                justifyContent={isMultiSelect ? 'space-between' : 'flex-end'}
                alignItems="center"
                classes={{ root: classes.headerContainer }}
              >
                {isMultiSelect ? (
                  <div className={classes.countTag}>{`${
                    selectAllValue &&
                    options &&
                    selected &&
                    selected.length &&
                    options?.length &&
                    selected.length === options?.length
                      ? options?.length - 1
                      : selected?.length || 0
                  } ${t('search.filter.Selected')}`}</div>
                ) : null}
                {!props.hideClearAll ? (
                  <Button
                    className="clear-all-button"
                    aria-label="clear-all-button"
                    variant="text"
                    color="primary"
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    onClick={() => clearAll()}
                    disabled={!isMultiSelect}
                    classes={{ text: ` ${classes.button} ${classes.clearAllButton}` }}
                  >
                    {t('search.filter.clearAll')}
                  </Button>
                ) : null}
              </Grid>
              <CustomTypeAhead
                enableCustomScroll
                removeCloseIcon
                variant="outlined"
                size="small"
                handleClose={handleClose}
                options={options}
                isLoading={promiseInProgress}
                onInputChange={throttleService}
                hiddenInput={hiddenInput}
                {...props}
              />
              {!props.hideApply ? (
                <div className={classes.actionContainer}>
                  <Grid item container justifyContent="flex-end" alignItems="center">
                    <Button
                      className="clear-all-button"
                      aria-label="cancel-button"
                      variant="text"
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      onClick={() => cancelChanges()}
                      classes={{ text: `${classes.button} ${classes.actionButton} ${classes.inActive}` }}
                    >
                      {t('search.filter.cancel')}
                    </Button>
                    <Button
                      className="clear-all-button"
                      aria-label="apply-button"
                      variant="text"
                      color="primary"
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      onClick={() => onApplyEvent()}
                      classes={{ text: `${classes.button} ${classes.actionButton} ` }}
                      id={props.hiddenApplyBtn}
                    >
                      {t('search.filter.apply')}
                    </Button>
                  </Grid>
                </div>
              ) : null}
            </Paper>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  );
};
