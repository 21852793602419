
export const calculateDisplayChipsLength = (displayChips) => {
    let diplayLength = 0;
    if (displayChips && displayChips.length > 0) {
        displayChips.forEach((item, index) => {
            if (index <= 4) {
                diplayLength += item?.length + 10;
            }
        });
    }

    return diplayLength;
};

export const calculateEachDisplayChipsLength = (displayChips, skillsetChipWidth, index) => {
    let diplayChipLength: any[] = [];

    if (displayChips && displayChips.length > 0) {
        displayChips.forEach((item, index) => {
            let itemLength = item.length * 1.455;
            itemLength = itemLength < 8 ? 8 : itemLength;
            diplayChipLength.push({ value: item, chipLength: itemLength });
        });
    }
    const leastLength = diplayChipLength.find(x => x.chipLength < 30);

    if (leastLength && diplayChipLength.length === 3) {
        const otherChipsLength = (90 - leastLength.chipLength) / 2;

        diplayChipLength.forEach((item, index) => {
            if (index > 0) {
                item.chipLength = otherChipsLength + '%';
            }
            else {
                item.chipLength = item.chipLength + '%';
            }
        });

        return diplayChipLength[index]?.chipLength ? diplayChipLength[index]?.chipLength : '10%';
    }
    else if (leastLength && diplayChipLength.length === 4) {
        const otherChipsLength = (90 - leastLength.chipLength) / 3;

        diplayChipLength.forEach((item, index) => {
            if (index > 0) {
                item.chipLength = otherChipsLength + '%';
            }
            else {
                item.chipLength = item.chipLength + '%';
            }
        });

        return diplayChipLength[index]?.chipLength ? diplayChipLength[index]?.chipLength : '10%';
    }
    else if (leastLength && diplayChipLength.length > 4) {
        const otherChipsLength = (86 - leastLength.chipLength) / 3;

        diplayChipLength.forEach((item, index) => {
            if (index > 0 && index <= 3) {
                item.chipLength = otherChipsLength + '%';
            }
            else if (index === 0) {
                item.chipLength = item.chipLength + '%';
            }
        });

        return diplayChipLength[index]?.chipLength ? diplayChipLength[index]?.chipLength : '10%';
    }

    return skillsetChipWidth;
};