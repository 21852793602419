import React from 'react';
import { CardDate, DocumentCard, useDocCardStyles } from '../customComponents/DocumentCard';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { ConfirmationStatus, IConfirmationDoc } from 'store/redux-store/placement-client-confirmation-doc-editor/types';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { DocSource } from 'store/redux-store/placement-candidate-contract-doc-editor/types';
import { Grid, Typography } from 'amn-ui-core';
import { missingField } from 'app/constants';

export const ConfirmationDocCard: React.FC<{
  card: IConfirmationDoc;
  isSelected: boolean;
  [key: string]: any;
}> = ({ card, isSelected, ...props }) => {
  const { t } = useTranslation();
  const { isCreatedFromClassic, isCardClickable } = React.useMemo(
    () => ({
      isCreatedFromClassic: card.source === DocSource.classic,
      isCardClickable: ![ConfirmationStatus.pending, ConfirmationStatus.okToSend].includes(card.status),
    }),
    [card],
  );

  const { classes, cx } = useDocCardStyles({ isCardClickable });

  const datesComponent = React.useMemo(
    () => (
      <>
        {getConfirmationCardDates({ card, classes, t })(card.status)}
        <Grid classes={{ root: classes.updatedContainer }} item xs={12}>
          <Typography
            variant="body2"
            component={'span'}
            sx={theme => ({ color: theme.palette.framework.system.doveGray, paddingRight: '2px' })}
          >
            <Typography
              variant="body2"
              component={'span'}
              fontWeight={'medium'}
              sx={theme => ({ color: theme.palette.framework.system.doveGray, paddingRight: '2px' })}
            >
              {t('templateEditor.templateCard.template')}
            </Typography>
            {card.templateName ?? missingField}
          </Typography>
        </Grid>
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [card],
  );

  return (
    <DocumentCard
      {...props}
      {...{ classes, cx, t }}
      card={card}
      isSelected={isSelected}
      isCreatedFromClassic={isCreatedFromClassic}
      isCardClickable={isCardClickable}
      datesComponent={datesComponent}
      cardType={props.cardType}
    />
  );
};

const getConfirmationCardDates = ({ card, classes, t }: { card: IConfirmationDoc; classes; t: TFunction }) =>
  customSwitch({
    [ConfirmationStatus.draft]: (
      <CardDate
        title={t('templateEditor.templateCard.generatedBy')}
        name={card.createdByName}
        date={card.view.draftGeneratedDate}
        classes={classes}
      />
    ),
    [ConfirmationStatus.notSent]: (
      <CardDate
        title={t('templateEditor.templateCard.createdBy')}
        name={card.createdByName}
        date={card.view.docCreatedDate}
        classes={classes}
      />
    ),
    [ConfirmationStatus.sent]:
      card.source === DocSource.classic ? (
        <CardDate title={t('templateEditor.templateCard.sent')} date={card.view.sentDate} classes={classes} />
      ) : (
        <CardDate
          title={t('templateEditor.templateCard.sent')}
          name={card.sentByName}
          date={card.view.sentDate}
          classes={classes}
        />
      ),
    [ConfirmationStatus.pending]: (
      <CardDate
        title={t('templateEditor.templateCard.lastUpdatedDate')}
        date={card.view.updateDate}
        classes={classes}
      />
    ),
    [ConfirmationStatus.okToSend]: (
      <CardDate
        title={t('templateEditor.templateCard.lastUpdatedDate')}
        date={card.view.updateDate}
        classes={classes}
      />
    ),
  })(
    <CardDate
      title={t('templateEditor.templateCard.statusChangedBy')}
      name={card.updatedByName}
      date={card.view.updateDate}
      classes={classes}
    />,
  );
