import React, { useMemo } from 'react';
import { PaperCard, Title } from '../styles';
import { Button, Grid, IconButton, Skeleton } from 'amn-ui-core';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import { useEducationalHistoryStyles } from './utils';
import { Timeline } from '@mui/lab';
import { TimelineSection } from './TimeLineSection';
import { Concatenate } from 'utils/string/string';
import { getDateRange, getExperienceData } from './helper';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from 'app/assets/images/Credentialing/PersonalInfo/edit.svg';
import { theme } from 'styles/global-styles';

function EmployeementCardUI({ employmentHistories, handleClick, editWorkHistory, setOpenAddPop }) {
  const { t } = useTranslation();
  const { classes } = useEducationalHistoryStyles();
  const { promiseInProgress } = usePromiseTracker({ area: 'get-candidate-details', delay: 0 });
  const timelineSections = useMemo(() => {
    return employmentHistories?.map((history, key) => (
      <TimelineSection
        key={key}
        facilityName={history.facilityName ? history.facilityName : ' '}
        discipline={history.disciplineAbbr ? history.disciplineAbbr : ' '}
        specialty={history.specialtyAbbr ? history.specialtyAbbr : ' '}
        dateRange={getDateRange(history.startedOn, history.endedOn)}
        place={Concatenate([history.location.city, history.location.state, history.location.country], ', ')}
        experience={getExperienceData(history.yearsOfExperience, history.monthsOfExperience)}
        approvedReferenceCount={history.approvedReferenceCount > 0 ? history.approvedReferenceCount : ' '}
      />
    ));
  }, [employmentHistories]);
  return (
    <PaperCard elevation={0} data-testid="employeeCard-item">
      {promiseInProgress ? (
        <Grid item mr={2}>
          <Skeleton height={100} />
          <Skeleton height={100} />
          <Skeleton height={100} />
        </Grid>
      ) : (
        <>
          <Grid container>
            <Grid item xs={6}>
              <Title>{t('candidate.employmentHistoryCard.title')}</Title>
            </Grid>
            <Grid item xs={6} className={classes.viewLink}>
              <Button
                aria-label="view-link"
                variant="text"
                disableRipple
                disableTouchRipple
                disableFocusRipple
                disabled={employmentHistories.length === 0}
                style={{ background: 'none' }}
                classes={{ text: classes.linkButton }}
                onClick={handleClick}
              >
                {t('candidate.employmentHistoryCard.viewHistory')}
              </Button>
              <IconButton
                color="secondary"
                onClick={e => {
                  editWorkHistory(e);
                }}
                size="medium"
              >
                <img src={EditIcon} alt="Edit" />
              </IconButton>
              <IconButton
                color="secondary"
                onClick={() => {
                  setOpenAddPop(true);
                }}
                style={{ padding: 0 }}
                size="medium"
              >
                <AddCircleOutlineIcon style={{ color: theme.palette.system.cornflowerBlue, padding: 0 }} />
              </IconButton>
            </Grid>
          </Grid>

          <Grid item>
            <Timeline classes={{ root: classes.timeLineRoot }} position="left">
              {timelineSections}
            </Timeline>
          </Grid>
        </>
      )}
    </PaperCard>
  );
}

export default EmployeementCardUI;
