import { createAction, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IClientContact, IClientContactDetails, IClientContactUpdated } from 'app/models/ClientContact/ClientContact';
import { IOptionsList } from 'app/models/Tasks/Tasks';

const saveClientContact = createAction<any>('SAVE_CLIENT_CONTACT');
const getFacilityAndUnitClientContacts = createAction<any>('GET_FACILITY_AND_UNIT_CLIENT_CONTACTS');
const getFacilityUnits = createAction<any>('GET_FACILITY_UNITS');
const getActionsList = createAction('GET_ACTIONS_LIST');
const getClientContactById = createAction<number>('GET_CLIENT_CONTACT_BY_ID');
const deleteClientContactById = createAction<number>('DELETE_CLIENT_CONTACT_BY_ID');
const updateClientContactDetails = createAction<any>('UPDATE_CLIENT_CONTACT');

export const initialState: IClientContact = {
  createClientContact: undefined,
  createdContactId: undefined,
  facilityUnits: [],
  actionList: [],
  clientContact: null,
  isClientContactUpdated: null,
  isEditClientContactOpen: false,
};

export const clientContactSlice = createSlice({
  name: 'clientContacts',
  initialState,
  reducers: {
    setCreatedContactId(state, action: PayloadAction<number | undefined>) {
      state.createdContactId = action.payload || undefined;
    },
    setFacilityUnits(state, action: PayloadAction<IOptionsList[] | undefined>) {
      state.facilityUnits = action.payload || undefined;
    },
    setActionList(state, action: PayloadAction<IOptionsList[]>) {
      state.actionList = action.payload;
    },
    setClientContactDetails(state, action: PayloadAction<IClientContactDetails | undefined>) {
      state.clientContact = action.payload;
    },
    setClientContactUpdateStatus(state, action: PayloadAction<IClientContactUpdated>) {
      state.isClientContactUpdated = action.payload;
    },
    setIsClientContactEditModelOpen(state, action: PayloadAction<boolean>) {
      state.isEditClientContactOpen = action.payload;
    },
    reset() {},
  },
});

export const { name: clientContactSliceKey, reducer: clientContactReducer } = clientContactSlice;

export const clientContactActions = {
  ...clientContactSlice.actions,
  saveClientContact,
  updateClientContactDetails,
  getFacilityAndUnitClientContacts,
  getFacilityUnits,
  getActionsList,
  getClientContactById,
  deleteClientContactById,
};
