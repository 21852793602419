import React from 'react';
import LeftMessage from 'app/assets/images/Notification/LeftMessage.svg';
import CouldntConnect from 'app/assets/images/Notification/CouldntConnect.svg';
import NoConnect from 'app/assets/images/Notification/NoConnect.svg';
import SpokeToCandidate from 'app/assets/images/Notification/SpokeToCandidate.svg';
import { ICallOutcome } from '../../Constants';

export const OutcomeIcon = (isClientContact = false) => [
  {
    name: 'Phone Disconnected',
    icon: <img src={CouldntConnect} alt="Phone Disconnected" />,
    value: ICallOutcome.PhoneDisconnected,
  },
  {
    name: "Couldn't Connect/VM Full",
    icon: <img src={NoConnect} alt="Couldn't Connect/VM Full" />,
    value: ICallOutcome.CouldntConnect,
  },
  {
    name: 'Left Message',
    icon: <img src={LeftMessage} alt="Left Message" />,
    value: ICallOutcome.LeftMessage,
  },
  {
    name: isClientContact ? 'Spoke to Client' : 'Spoke to Candidate',
    icon: <img src={SpokeToCandidate} alt="Spoke to Candidate" />,
    value: isClientContact ? ICallOutcome.SpokeToClient : ICallOutcome.SpokeToCandidate,
  },
];
