/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable i18next/no-literal-string */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import 'react-phone-input-2/lib/style.css';
import { Theme, TextField, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { candidateDetailsSelection } from '../../../Profile/CandidateDetails.selector';
import { contactTypes, emailCheck, errorEmailMessage } from './PersonalInfoHelper';
import { FormInputCountryDropDown } from './Controls/FormInputCountryDropDown';
import { cloneDeep } from 'lodash';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: '0px 10px',
  },
  textColor: {
    color: '#0D6FB9',
    textAlign: 'left',
    font: 'normal normal medium 14px / 26px Roboto',
    fontWeight: 500,
    paddingLeft: 4,
  },
  inlineLink: {
    color: '#006FB9',
    'font-size': '14px',
    'font-weight': '600',
    'padding-left': '0.3rem',
    'text-decoration': 'underline',
    'white-space': 'nowrap',
    cursor: 'pointer',
  },
  inlineLinkDisabled: {
    color: '#dddddd',
    'font-size': '14px',
    'font-weight': '600',
    'padding-left': '0.3rem',
    'text-decoration': 'underline',
    'white-space': 'nowrap',
    cursor: 'not-allowed',
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  '.react-tel-input.country-list.search': {
    width: 5,
  },
}));

export const ContactInfoSection = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { control, register, setError, errors, clearErrors } = useFormContext();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const [secondaryEmail, setSecondaryEmail] = useState<boolean>(false);
  const [typeOptions, setTypeOptions] = useState<{ ID: number; Description: string }[]>(contactTypes);
  const [phoneNumbers, setPhoneNumbers] = useState<any[]>(
    candidateDetails.phoneNumbers?.filter(x => x.phoneNumber !== '') || [],
  );
  const [softDelPhnNumbers, setSoftDelPhnNumbers] = useState<Map<number, boolean>>(new Map<number, boolean>());

  useEffect(() => {
    let tempTypes = contactTypes;
    phoneNumbers.map(item => {
      tempTypes = tempTypes.filter(x => x.ID !== item.phoneTypeId);
    });
    setTypeOptions(tempTypes);
  }, [phoneNumbers]);

  const addPhone = () => {
    if (typeOptions.length > 0) {
      const newPhoneType = typeOptions[0];
      setPhoneNumbers([
        ...phoneNumbers,
        {
          phoneTypeId: newPhoneType.ID,
          phoneTypeDescription: newPhoneType.Description,
          phoneNumber: '',
          countryDialingCode: '',
          extensionNotes: null,
          timeStamp: '',
        },
      ]);
      setTypeOptions(typeOptions.filter((item, index) => index !== 0));
      props.checkContactEdit();
    }
  };

  const addEmail = () => {
    setSecondaryEmail(true);
  };

  const getTypeOptions = type => {
    const permanentField2Type = 3;
    const current = contactTypes.find(item => item.ID === type);
    const clonePhoneNumberOptions = cloneDeep(typeOptions);
    const phoneNumberOptions = clonePhoneNumberOptions.filter(item => item.ID !== permanentField2Type);
    return [...phoneNumberOptions, current];
  };

  const onChangeType = (current, newType) => {
    const updatedPhoneNumbers = phoneNumbers.map(item => {
      if (item.phoneTypeDescription === current) {
        return {
          ...item,
          phoneTypeDescription: newType,
        };
      } else {
        return item;
      }
    });
    setPhoneNumbers(updatedPhoneNumbers);
  };

  const handleChange = e => {
    switch (e?.target?.name) {
      case 'ciPrimaryEmail':
        errorEmailMessage(e, 'ciPrimaryEmail', setError);
        break;
      case 'ciSecondaryEmail':
        errorEmailMessage(e, 'ciSecondaryEmail', setError);
        break;
    }
  };

  const softDelete = (index: number) => {
    const deletedMaps = new Map<number, boolean>();
    softDelPhnNumbers.forEach((val, key) => {
      deletedMaps.set(key, val);
    });
    deletedMaps.set(index, true);
    setSoftDelPhnNumbers(deletedMaps);
  };

  useEffect(() => {
    if (phoneNumbers.length > 0 && softDelPhnNumbers.size === phoneNumbers.length) {
      props.handleShowInfoMessage();
    }
  }, [softDelPhnNumbers]);

  const undoDelete = (index: number) => {
    const deletedMaps = new Map<number, boolean>();
    softDelPhnNumbers.forEach((val, key) => {
      deletedMaps.set(key, val);
    });
    deletedMaps.delete(index);
    setSoftDelPhnNumbers(deletedMaps);
  };

  useEffect(() => {
    if (candidateDetails?.secondaryEmail) setSecondaryEmail(true);
  }, [candidateDetails.secondaryEmail]);

  return (
    <>
      <LayoutGrid container direction="column" className={classes.root}>
        <LayoutGridItem item style={{ paddingLeft: '6px' }}>
          <Typography className={classes.textColor} variant="subtitle1" gutterBottom component="div">
            {t('candidate.profileInformation.contactInformation')}
          </Typography>
        </LayoutGridItem>
        <LayoutGrid container direction="row" justifyContent="space-between">
          <LayoutGridItem item style={{ paddingLeft: 24 }}>
            <Typography variant="subtitle1" gutterBottom component="div" style={{ fontWeight: 500 }}>
              {t('candidate.profileInformation.phone')}
            </Typography>
          </LayoutGridItem>
          <LayoutGridItem item>
            <span
              className={typeOptions.length > 0 ? classes.inlineLink : classes.inlineLinkDisabled}
              onClick={() => addPhone()}
            >
              {t('candidate.profileSummaryCard.add')}
            </span>
          </LayoutGridItem>
        </LayoutGrid>

        {phoneNumbers.map((items, index) => {
          return (
            <Controller
              name={`phoneNumbers[${index}]`}
              control={control}
              render={({ ref, value, onChange, ...rest }) => (
                <FormInputCountryDropDown
                  key={index}
                  controllerName={`phoneNumbers[${index}]`}
                  name={items.phoneTypeDescription.replace(/\s/g, '')}
                  contactTypeName={items.phoneTypeDescription}
                  contactNumber={`${items.countryDialingCode}${items.phoneNumber}`}
                  options={getTypeOptions(items.phoneTypeId)}
                  onChangeType={onChangeType}
                  checkContactEdit={props.checkContactEdit}
                  isSoftDeleted={softDelPhnNumbers.has(index)}
                  onSoftDelete={() => softDelete(index)}
                  undoDelete={() => undoDelete(index)}
                  disabled={items.phoneTypeId === 3}
                />
              )}
            />
          );
        })}

        <LayoutGridItem item>
          <LayoutGrid container direction="row" justifyContent="space-between" marginTop="-24px">
            <LayoutGridItem item style={{ paddingLeft: 12, paddingTop: 20 }}>
              <Typography variant="subtitle1" gutterBottom component="div" style={{ fontWeight: 500 }}>
                {t('candidate.profileInformation.email')}
              </Typography>
            </LayoutGridItem>
            <LayoutGridItem item>
              <span
                className={!secondaryEmail ? classes.inlineLink : classes.inlineLinkDisabled}
                onClick={() => addEmail()}
              >
                {t('candidate.profileSummaryCard.add')}
              </span>
            </LayoutGridItem>
          </LayoutGrid>
        </LayoutGridItem>
        <LayoutGridItem item paddingTop="0px !important">
          <LayoutGrid container direction="row" data-testid="container" justifyContent="flex-start" spacing={4}>
            <LayoutGridItem item xs={10}>
              <Controller
                name="ciPrimaryEmail"
                control={control}
                render={({ ref, value, onChange, onBlur, ...rest }) => (
                  <TextField
                    variant="filled"
                    label="Email"
                    fullWidth
                    {...rest}
                    onChange={handleChange}
                    onBlur={e => {
                      if (emailCheck(e?.target?.value)) {
                        clearErrors(['ciPrimaryEmail']);
                      }
                    }}
                    inputRef={register}
                    error={!!errors['ciPrimaryEmail']}
                    helperText={errors['ciPrimaryEmail'] ? errors.ciPrimaryEmail.message : ''}
                  />
                )}
              />
            </LayoutGridItem>
          </LayoutGrid>
        </LayoutGridItem>
        {(candidateDetails.secondaryEmail || secondaryEmail) && (
          <LayoutGridItem item>
            <LayoutGrid container direction="row" data-testid="container" justifyContent="flex-start" spacing={4}>
              <LayoutGridItem item xs={10}>
                <Controller
                  name="ciSecondaryEmail"
                  control={control}
                  render={({ ref, value, onChange, ...rest }) => (
                    <TextField
                      variant="filled"
                      label="Email"
                      fullWidth
                      {...rest}
                      onChange={handleChange}
                      onBlur={e => {
                        if (emailCheck(e?.target?.value)) {
                          clearErrors(['ciSecondaryEmail']);
                        }
                      }}
                      inputRef={register}
                      error={!!errors['ciSecondaryEmail']}
                      helperText={errors['ciSecondaryEmail'] ? errors.ciSecondaryEmail.message : ''}
                    />
                  )}
                />
              </LayoutGridItem>
            </LayoutGrid>
          </LayoutGridItem>
        )}
      </LayoutGrid>
    </>
  );
};
