/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui';
import { Tooltip } from 'amn-ui-core';
import React from 'react';

const useStyles = makeStyles()({
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#333333',
    opacity: 1,
    textAlign: 'center',
    font: 'normal normal normal 12px/20px Roboto',
    letterSpacing: '0px',
    width: '174px',
  },
  arrow: {
    color: '#ffffff',
    opacity: 1,
    '&::before': {
      boxShadow: '0px 3px 6px #00000029',
      border: '1px solid #E3DFDF',
    },
  },
  tooltipPlacementBottom: {
    margin:'0px !important'
  },
});
interface CueNoteTooltipProps {
  tooltipContent?: any;
  children?: any;
  placement?: 'top' | 'right' | 'bottom' | 'left';
}
export function CueNoteTooltip(props: CueNoteTooltipProps) {
  const { classes } = useStyles();

  return (
    <Tooltip
      children={props.children}
      title={props.tooltipContent}
      arrow
      classes={classes}
      placement={props.placement}
    />
  );
}
