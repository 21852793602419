import { DateRange, DateRangePicker } from 'app/ComponentLibrary/DateRangeNew';
import { missingDate } from 'app/constants';
import { convertToFormat } from 'app/helpers/dateHelper';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& input': {
      fontSize: '14px',
    },
  },
}));
export const TextFieldCalendar = ({ open, value, onChange, closePicker }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [errorMessage, setErrorMessage] = useState(false);

  const _onChange = (dateRange: DateRange) => {
    const rangeAlreadyExists = (value as { startDate: string; endDate: string }[]).some(item => {
      const s = moment(convertToFormat(item.startDate, 'YYYY-MM-DD'));
      const e = moment(convertToFormat(item.endDate || '1900-01-01', 'YYYY-MM-DD'));
      const newS = moment(convertToFormat(dateRange.startDate, 'YYYY-MM-DD'));
      const newE = moment(convertToFormat(dateRange.endDate || '1900-01-01', 'YYYY-MM-DD'));
      if (s.isSame(newS, 'D')) return true;
      else if (newS.isBetween(s, e, null, '[]')) {
        return true;
      } else if (newE.isBetween(s, e, null, '[]')) {
        return true;
      } else if (s.isBetween(newS, newE, null, '[]')) {
        return true;
      } else if (e.isBetween(newS, newE, null, '[]')) {
        return true;
      }
      return false;
    });
    setErrorMessage(false);
    setTimeout(() => {
      setErrorMessage(rangeAlreadyExists);
    }, 200);
  };

  const onDateAdd = (startDate, endDate) => {
    onChange(
      [
        ...value,
        {
          startDate: convertToFormat(startDate, 'yyyy-MM-DDT00:00:00'),
          endDate: convertToFormat(endDate, 'yyyy-MM-DDT00:00:00'),
        },
      ].sort((v1, v2) => {
        const p1 = moment(!v1.startDate ? missingDate : v1.startDate);
        const p2 = moment(!v2.startDate ? missingDate : v2.startDate);
        return p1 < p2 ? -1 : p1 > p2 ? 1 : 0;
      }),
    );
  };

  const _onApply = (dateRange: DateRange) => {
    if (!dateRange.startDate && !dateRange.endDate) return;
    else if (moment(dateRange.startDate).isSame(moment(dateRange.endDate), 'D')) {
      dateRange.endDate = undefined;
    }
    onDateAdd(dateRange.startDate, dateRange.endDate);
    closePicker(null);
  };

  return (
    <DateRangePicker
      secondaryLabel={t('placement.profile.requirementsAndQualifications.timeOffRequest.secondaryLabel')}
      variant="outlined"
      elevation={0}
      popper={false}
      wrapperClass={classes.wrapper}
      quickSelect={false}
      onCancel={closePicker}
      applyButtonLabel={t('placement.profile.requirementsAndQualifications.timeOffRequest.applyButtonLabel')}
      open={open}
      onApply={_onApply}
      onChange={_onChange}
      error={errorMessage}
      helperText={
        errorMessage ? t('placement.profile.requirementsAndQualifications.timeOffRequest.helperText') : undefined
      }
      dualValidation
      clickAway
    />
  );
};
