import React, { useEffect, useState } from 'react';
import { Button, Radio } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { NestedFilter } from './NestedFilter';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';

const useStyles = makeStyles<{ removeActionContainer: boolean }>()((theme, props) => ({
  filters: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
  },
  filtersHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filterBody: {
    display: 'flex',
    flexDirection: 'column',
    '&>div>div': {
      margin: '6px',
    },
  },
  body: {
    display: 'flex',
  },
  countTag: {
    color: '#555555',
    fontSize: 14,
    fontWeight: 600,
    paddingLeft: '6px',
  },
  button: {
    fontSize: '12px',
    fontWeight: 'bold',
    textTransform: 'none',
    '&:hover, &:focus': {
      background: 'none',
    },
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    visibility: props.removeActionContainer ? 'hidden' : undefined,
    backgroundColor: '#ffffff',
    borderTop: props.removeActionContainer ? undefined : '1px solid #cccccc',
    padding: props.removeActionContainer ? 0 : '6px 20px',
    height: props.removeActionContainer ? 0 : undefined,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  actionButton: {
    padding: '6px 0px',
  },

  inActive: {
    color: '#888888',
  },
  radioGroup: {
    padding: '2px 1rem 2px 2px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    fontSize: '12px',
    margin: '6px',
  },
}));

export function FiltersPopUp({
  filters,
  handleClose,
  selectedCount,
  handleApply,
  value,
  controlName,
  handleCancel,
  resetNestedControlErrors,
  isFormControlsValid,
  nestedlabelPrefix,
  showOperations = true,
  hasDefaultValue = false,
  manipulateDefaultValue,
}) {
  const { classes } = useStyles({ removeActionContainer: false }); // TODO: MUI 5 - @Anuj Vadav, If this parameter isn't being used, then consider removing it from the styles
  const { t } = useTranslation();
  const [items, setItems] = useState([filters]);
  const { setValue } = useFormContext();
  const [selectedCheckBox, setSelectedCheckbox] = useState('Or');

  useEffect(() => {
    setSelectedCheckbox((value && value.operation) || 'Or');
    if (value && value.value && value.value.length > 1) {
      const totalItemsToAdd = [];
      for (let i = 0; i < value.value.length; i++) totalItemsToAdd.push([...filters]);
      setItems(totalItemsToAdd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const removeItem = (index: number) => {
    const _items = _.cloneDeep(items).filter((_x, id) => id !== index);
    setItems(_items);
    if (value.value.length >= index) {
      resetNestedControlErrors();
      const _values = _.cloneDeep(value.value).filter((_x, id) => id !== index);
      setValue(controlName, { ...value, value: _values });
      isFormControlsValid();
    }
  };

  const onHandleRadioChange = event => {
    const _values = _.cloneDeep(value);
    _values.operation = event.target.value;
    setValue(controlName, _values);
  };

  const result = (
    <>
      <div className={classes.filters}>
        <div className={classes.filtersHeader}>
          <span className={classes.countTag}>{`${selectedCount} ${t('search.filter.Selected')}`}</span>
          <Button
            className="clear-all-button"
            aria-label="clear-all-button"
            variant="text"
            color="primary"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() => {
              setItems([filters]);
              handleClose();
            }}
            classes={{ text: ` ${classes.button}` }}
          >
            {t('search.filter.clearAll')}
          </Button>
        </div>
        <div className={classes.body}>
          {showOperations && items.length > 1 ? (
            <section className={classes.radioGroup}>
              <div>
                <Radio
                  checked={selectedCheckBox === 'Or'}
                  onChange={onHandleRadioChange}
                  value="Or"
                  name={'radio-' + controlName}
                />
                <span>Or</span>
              </div>
              <div>
                <Radio
                  checked={selectedCheckBox === 'And'}
                  onChange={onHandleRadioChange}
                  value="And"
                  name={'radio-' + controlName}
                />
                <span>And</span>
              </div>
            </section>
          ) : null}

          <div className={classes.filterBody}>
            {items.map((ifilters, index) => (
              <NestedFilter
                items={ifilters}
                nestedlabelPrefix={nestedlabelPrefix}
                key={index}
                index={index}
                value={value}
                controlName={controlName}
                removeItem={() => removeItem(index)}
                showClearIcon={items.length > 1}
              />
            ))}
          </div>
        </div>

        <Button
          className="clear-all-button"
          aria-label="clear-all-button"
          variant="text"
          color="primary"
          disableRipple
          disableFocusRipple
          disableTouchRipple
          onClick={() => {
            if (!isFormControlsValid()) return;
            hasDefaultValue
              ? manipulateDefaultValue(controlName, setValue, value, selectedCheckBox)
              : setItems(stateFilters => [...stateFilters, filters]);
          }}
          classes={{ text: ` ${classes.button}` }}
          disabled={!(selectedCount === items.length)}
          style={{ width: 'fit-content' }}
        >
          {t('search.filter.addMore')}
        </Button>
      </div>
      <div className={classes.actionContainer}>
        <Button
          className="clear-all-button"
          aria-label="cancel-button"
          variant="text"
          color="primary"
          disableRipple
          disableFocusRipple
          disableTouchRipple
          onClick={handleCancel}
          classes={{ text: `${classes.button} ${classes.actionButton} ${classes.inActive}` }}
        >
          {t('search.filter.cancel')}
        </Button>
        <Button
          className="clear-all-button"
          aria-label="apply-button"
          variant="text"
          color="primary"
          disableRipple
          disableFocusRipple
          disableTouchRipple
          onClick={handleApply}
          classes={{ text: `${classes.button} ${classes.actionButton}` }}
        >
          {t('search.filter.apply')}
        </Button>
      </div>
    </>
  );

  return result;
}
