import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { UnitDetailsTextArea } from '../../../Common/UnitDetailsCommonTextArea';
import { Grid, Typography } from 'amn-ui-core';
import { Controller, useFormContext } from 'react-hook-form';

const useStyles = makeStyles()(theme => ({
  note: {
    font: 'italic normal normal 12px/16px Roboto',
    letterSpacing: ' 0px',
    color: '#9A9D9F',
    opacity: '1',
  },
}));
export const JobDescriptionContainer = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { control } = useFormContext();

  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={'externalJobDescription'}
          render={({ ref, onChange, ...rest }) => (
            <UnitDetailsTextArea
              fieldLabel={t('facility.units.unitDetails.unitDetails.jobDescription.externalJobDescription')}
              fieldName={'externalJobDescription'}
              id={'externalJobDescription'}
              onChange={onChange}
              isDisabled={false}
              inputValue={rest.value}
              defaultInputValue={''}
              showMaxCount
              maxCharacters={8000}
              customHelperText={
                <Typography className={classes.note} component="span" variant="body2">
                  {t('facility.units.unitDetails.unitDetails.jobDescription.notes')}
                </Typography>
              }
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
