import { AppBar, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import styledComponent from 'styled-components';

export const useBulkSmsStyles = makeStyles()(theme => ({
  headerNavigation: { marginLeft: 'auto', marginRight: '2%', marginTop: '2%' },
  paper: {
    position: 'fixed',
    bottom: '5px',
    zIndex: -1,
    backgroundColor: theme.palette.system.smokeWhite,
    overflowY: 'hidden',
    overflowX: 'hidden',
    width: '418px',
    outline: 'none',
    boxShadow: '0px 0px 2px 6px #8888',
    borderRadius: '6px',
    maxHeight: '545px',
  },
  paperRight: {
    right: '35px',
  },
  paperRightWithDrawerOpened: {
    right: '460px',
  },
  input: {
    fontSize: '16px',
  },
  modalToolbar: {
    background: '#88888',
    paddingBottom: '8px',
    paddingLeft: '15px',
  },
  messageBox: {
    backgroundColor: theme.palette.system.white,
    marginTop: '1%',
    marginLeft: '11px',
    marginBottom: '8px',
    '& .MuiInputBase-root': {
      height: '414px',
      width: '360px',
      display: 'inline-flex',
      flexDirection: 'column',
    },
    '& .MuiOutlinedInput-root': {
      position: 'initial',
    },
  },
  messageBoxAlert: {
    backgroundColor: theme.palette.system.white,
    marginTop: '1%',
    marginLeft: '11px',
    marginBottom: '8px',
    '& .MuiInputBase-root': {
      height: '374px',
      width: '360px',
      display: 'inline-flex',
      flexDirection: 'column',
    },
    '& .MuiOutlinedInput-root': {
      position: 'initial',
    },
  },
  messageBoxCollapsed: {
    backgroundColor: theme.palette.system.white,
    marginTop: '1%',
    marginLeft: '8px',
    marginBottom: '8px',
    '& .MuiInputBase-root': {
      height: '370px',
      width: '360px',
      display: 'inline-flex',
      flexDirection: 'column',
    },
    '& .MuiOutlinedInput-root': {
      position: 'initial',
    },
  },
  messageBoxCollapsedAlert: {
    backgroundColor: theme.palette.system.white,
    marginTop: '1%',
    marginLeft: '8px',
    marginBottom: '8px',
    '& .MuiInputBase-root': {
      height: '327px',
      width: '360px',
      display: 'inline-flex',
      flexDirection: 'column',
    },
    '& .MuiOutlinedInput-root': {
      position: 'initial',
    },
  },
  avatar: {
    margin: '2% 2% 0 0',
    color: theme.palette.system.white,
    fontSize: '23px',
    width: '40px',
    height: '40px',
    '& .makeStyles-avatar-630': {
      width: '40px',
      height: '40px',
    },
  },
  modalIcon: {
    color: '#BDBEBF',
    cursor: 'pointer',
    fontSize: '1.5rem',
    margin: '5px',
  },
  tooltipArrow: {
    color: theme.palette.framework.main,
    '&::before': {
      border: '1px solid #555555',
    },
  },
  tooltipBackground: {
    backgroundColor: theme.palette.framework.main,
    color: theme.palette.system.davyGray,
    border: `1px solid ${theme.palette.system.davyGray}`,
    fontSize: '12px',
  },
  emojiGrid: {
    background: `${theme.palette.system.white} 0% 0% no-repeat padding-box`,
    boxShadow: '0px 0px 10px #0000004D',
    pointerEvents: 'auto',
    width: '290px',
    marginBottom: '0px',
    maxWidth: '600px !important',
    paddingLeft: 0,
    position: 'absolute',
    left: '-279px',
    top: '-262px',
  },
  grid: {
    marginTop: '5px',
    marginBottom: '5px',
    marginLeft: '0px',
    width: '100%',
  },
  emoji: {
    fontSize: '22px',
    cursor: 'pointer',
    width: '20px',
    'flex-basis': '12%',
  },
  attachmentChip: {
    margin: '5px 0 10px 0',
    borderRadius: '5px',
    background: theme.palette.system.grayChip,
    border: `1px solid ${theme.palette.system.fadedGrey}`,
  },
  flex: {
    display: 'flex',
  },
  actions: {
    listStyle: 'none',
    marginLeft: '16px',
    marginBottom: '15px',
    padding: 0,
  },
  actionsAlert: {
    listStyle: 'none',
    marginLeft: '16px',
    marginBottom: '15px',
    padding: 0,
  },
  bulksmsIcons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  bulksmsIcon: {
    marginTop: '10px',
  },
  headerText: {
    color: theme.palette.system.lightNavyBlue,
    marginTop: '6px',
    fontWeight: 700,
  },
  hide: {
    display: 'none',
  },
  templates: {
    height: '40px',
    width: '260px',
    minheight: '15px',
    background: theme.palette.system.smokeWhite,
    padding: 0,
    marginLeft: '8px',
    marginBottom: '8px',
  },
  heading: {
    padding: '13px',
  },
  headingText: {
    fontSize: '14px',
    fontWeight: 600,
  },
  popover: {
    overflow: 'visible',
    '& .MuiPopover-paper': {
      overflow: 'visible',
    },
    '& .MuiPaper-root': {
      overflow: 'visible',
    },
  },
  disabledIcon: {
    width: '24px',
    height: '24px',
  },
  sendingMessage: {
    display: 'inline-flex',
    position: 'absolute',
    bottom: '15px',
    right: '50px',
  },
}));

export const ModalAppBar = styledComponent(AppBar)`
  width: 100%;
  max-height: 10vh;
  background: #f4f5f7;
  box-shadow: none;
  border-bottom: 2px solid #0000004d;
`;

export const MessageTime = styledComponent(Typography)`
margin-top: 3%;
font-size: 12px;
color: #afafaf;
`;
