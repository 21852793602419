import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { determineChipSelected } from '../../PlacementStatus/Helpers/PlacementStatusFilterHelper';
import { getExpiringPipelineCounts } from '../Helpers/ExpiringCredentialHelpers';
import {
  defaultChips,
  defaultFilterChipSelected,
  IFilterChipSelected,
  IFilterSpecs,
  IExpiringFilterChips,
  IExpiringFilter,
} from '../Models/ExpiringDocumentInterfaces';

export interface ICredentialingDashboardExpiringDocumentsState {
  today: any;
  tomorrow: any;
  sevenDays: any;
  fourteenDays: any;
  thirtyDays: any;
  onAssignment: any;
}

export interface ICredentialingExpiringCredentialCounts {
  today: number[];
  tomorrow: number[];
  nextSevenDays: number[];
  nextFourteenDays: number[];
  nextThirtyDays: number[];
  allExpiring: number[];
  onAssignment: number[];
}

export interface ICredentialingDashboardExpiringState {
  statusID: number;
  flattenedData: any[];
  filteredData?: any[];
  filterSpecs?: IFilterSpecs;
  filterChipSelected: IFilterChipSelected;
  chips: IExpiringFilterChips;
  counts: ICredentialingExpiringCredentialCounts;
  loading: boolean;
}

export const initialState: ICredentialingDashboardExpiringState = {
  flattenedData: [],
  statusID: -2,
  filteredData: undefined,
  filterSpecs: undefined,
  filterChipSelected: defaultFilterChipSelected,
  chips: defaultChips,
  counts: {
    today: [0, 0, 0],
    tomorrow: [0, 0, 0],
    nextSevenDays: [0, 0, 0],
    nextFourteenDays: [0, 0, 0],
    nextThirtyDays: [0, 0, 0],
    allExpiring: [0, 0, 0],
    onAssignment: [0, 0, 0],
  },
  loading: false,
};

const expiringDocumentSlice = createSlice({
  name: 'credentialingDashboardExpiring',
  initialState: initialState,
  reducers: {
    setFlattenedData(state, action: PayloadAction<any[]>) {
      state.flattenedData = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setCounts(state, action: PayloadAction<ICredentialingExpiringCredentialCounts>) {
      return {
        ...state,
        counts: action.payload,
      };
    },
    setStatusID(state, action: PayloadAction<number>) {
      return {
        ...state,
        statusID: action.payload,
      };
    },
    setFilteredData(state, action: PayloadAction<any[] | undefined>) {
      state.filteredData = action.payload;
      state.counts = getExpiringPipelineCounts(action.payload || state.flattenedData);
    },
    setFilterSpecs(state, action: PayloadAction<IFilterSpecs>) {
      state.filterSpecs = action.payload;
    },
    setFilterChips(state, action: PayloadAction<IExpiringFilterChips>) {
      state.chips = action.payload;
    },
    setFilterChipSelected(state, action: PayloadAction<IFilterChipSelected>) {
      state.filterChipSelected = action.payload;
    },
    setFilterChipSelectedByKey(state, action: PayloadAction<{ key: string; selected: any | null }>) {
      state.filterChipSelected = {
        ...state.filterChipSelected,
        [action.payload.key]: determineChipSelected(
          action.payload.selected,
          state.filterChipSelected[action.payload.key],
        ),
      };
    },
    reset() {
      return { ...initialState };
    },
  },
});

const credentialingWorkDeskExpiringData = createAction<{
  userId: number;
  filter?: {
    values: IExpiringFilter;
    setValue: (
      name: string,
      value: unknown,
      options?:
        | Partial<{
            shouldValidate: boolean;
            shouldDirty: boolean;
          }>
        | undefined,
    ) => void;
  };
}>('GET_EXPIRING_CREDENTIALS_DATA');

const { actions } = expiringDocumentSlice;

export const {
  reducer: credentialingDashboardExpiringReducer,
  name: credentialingDashboardExpiringSliceKey,
} = expiringDocumentSlice;
export const credentialingDashboardExpiringActions = {
  ...actions,
  credentialingWorkDeskExpiringData,
};
