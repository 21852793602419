import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import HomeIcon from 'app/assets/images/MergeTokens/HomeIcon.svg';
import FacilityNameIcon from 'app/assets/images/MergeTokens/FacilityNameIcon.svg';
import ContactIcon from 'app/assets/images/MergeTokens/ContactIcon.svg';
import ApprovedTimeOffIcon from 'app/assets/images/MergeTokens/ApprovedTimeOffIcon.svg';
import BillRateIcon from 'app/assets/images/MergeTokens/BillRateIcon.svg';
import CallbackBillrateIcon from 'app/assets/images/MergeTokens/CallbackBillrateIcon.svg';
import ContractDatesIcon from 'app/assets/images/MergeTokens/ContractDatesIcon.svg';
import EndDateIcon from 'app/assets/images/MergeTokens/EndDateIcon.svg';
import FacilityCityIcon from 'app/assets/images/MergeTokens/FacilityCityIcon.svg';
import FaxIcon from 'app/assets/images/MergeTokens/FaxIcon.svg';
import ZipPostalCodeIcon from 'app/assets/images/MergeTokens/ZipPostalCodeIcon.svg';
import HAMCompanyIcon from 'app/assets/images/MergeTokens/HAMCompanyIcon.svg';
import HAMCompanyIDIcon from 'app/assets/images/MergeTokens/HAMCompanyIDIcon.svg';
import EmailIcon from 'app/assets/images/MergeTokens/EmailIcon.svg';
import PhoneIcon from 'app/assets/images/MergeTokens/PhoneIcon.svg';
import CalendarIcon from 'app/assets/images/MergeTokens/CalendarIcon.svg';
import PlacementIDIcon from 'app/assets/images/MergeTokens/PlacementIDIcon.svg';
import OrderVMSReqIcon from 'app/assets/images/MergeTokens/OrderVMSReqIcon.svg';
import PlacementDisciplineIcon from 'app/assets/images/MergeTokens/PlacementDisciplineIcon.svg';
import PlacementExpectedWorkHoursIcon from 'app/assets/images/MergeTokens/PlacementExpectedWorkHoursIcon.svg';
import ConfirmationTitleIcon from 'app/assets/images/MergeTokens/ConfirmationTitleIcon.svg';
import StartDateIcon from 'app/assets/images/MergeTokens/StartDateIcon.svg';
import TodayDateIcon from 'app/assets/images/MergeTokens/TodayDateIcon.svg';
import DOBIcon from 'app/assets/images/MergeTokens/DOBIcon.svg';
import FirstNameIcon from 'app/assets/images/MergeTokens/FirstNameIcon.svg';
import GenderIcon from 'app/assets/images/MergeTokens/GenderIcon.svg';
import LastNameIcon from 'app/assets/images/MergeTokens/LastNameIcon.svg';
import MiddleInitialIcon from 'app/assets/images/MergeTokens/MiddleInitialIcon.svg';
import MiddleNameIcon from 'app/assets/images/MergeTokens/MiddleNameIcon.svg';
import SSNIcon from 'app/assets/images/MergeTokens/SSNIcon.svg';
import UnitNameIcon from 'app/assets/images/MergeTokens/UnitNameIcon.svg';
import DoubleTimeBRIcon from 'app/assets/images/MergeTokens/DoubleTimeBRIcon.svg';
import ClockIcon from 'app/assets/images/MergeTokens/ClockIcon.svg';
import AddressPinIcon from 'app/assets/images/MergeTokens/AddressPinIcon.svg';
import VerifiedShieldIcon from 'app/assets/images/MergeTokens/VerifiedShieldIcon.svg';
import CoordinatorIcon from 'app/assets/images/MergeTokens/CoordinatorIcon.svg';
import ImageIcon from 'app/assets/images/MergeTokens/ImageIcon.svg';
import SignatureIcon from 'app/assets/images/MergeTokens/SignatureIcon.svg';
import DottedClockIcon from 'app/assets/images/MergeTokens/DottedClockIcon.svg';
import GWWIcon from 'app/assets/images/MergeTokens/GWWIcon.svg';
import AddendumOrdinalNumber from 'app/assets/images/MergeTokens/AddendumOrdinalNumber.svg';
import AdditionalPremiumPay from 'app/assets/images/MergeTokens/AdditionalPremiumPay.svg';
import DollarSign from 'app/assets/images/MergeTokens/DollarSign.svg';
import ApprovedByName from 'app/assets/images/MergeTokens/ApprovedByName.svg';
import BrandHandbook from 'app/assets/images/MergeTokens/BrandHandbook.svg';
import CallBackRate from 'app/assets/images/MergeTokens/CallBackRate.svg';
import CellPhoneStipend from 'app/assets/images/MergeTokens/CellPhoneStipend.svg';
import CompanyLegalName from 'app/assets/images/MergeTokens/CompanyLegalName.svg';
import ContractLanguage from 'app/assets/images/MergeTokens/ContractLanguage.svg';
import CorporateTitle from 'app/assets/images/MergeTokens/CorporateTitle.svg';
import Location from 'app/assets/images/MergeTokens/Location.svg';
import Calendar from 'app/assets/images/MergeTokens/Calendar.svg';
import Description from 'app/assets/images/MergeTokens/Description.svg';
import Effective from 'app/assets/images/MergeTokens/Effective.svg';
import EndDate from 'app/assets/images/MergeTokens/EndDate.svg';
import ErrorText from 'app/assets/images/MergeTokens/ErrorText.svg';
import Exceptions from 'app/assets/images/MergeTokens/Exceptions.svg';
import Frequency from 'app/assets/images/MergeTokens/Frequency.svg';
import FromTime from 'app/assets/images/MergeTokens/FromTime.svg';
import Guaranteed from 'app/assets/images/MergeTokens/Guaranteed.svg';
import Holiday from 'app/assets/images/MergeTokens/Holiday.svg';
import Housing from 'app/assets/images/MergeTokens/Housing.svg';
import StartDate from 'app/assets/images/MergeTokens/StartDate.svg';
import Meals from 'app/assets/images/MergeTokens/Meals.svg';
import CalendarDots from 'app/assets/images/MergeTokens/CalendarDots.svg';
import ContractEdit from 'app/assets/images/MergeTokens/ContractEdit.svg';
import OnCallRate from 'app/assets/images/MergeTokens/OnCallRate.svg';
import Discipline from 'app/assets/images/MergeTokens/Discipline.svg';
import Payer from 'app/assets/images/MergeTokens/Payer.svg';
import People from 'app/assets/images/MergeTokens/People.svg';
import PetType from 'app/assets/images/MergeTokens/PetType.svg';
import Hours from 'app/assets/images/MergeTokens/Hours.svg';
import Folder from 'app/assets/images/MergeTokens/Folder.svg';
import Undo from 'app/assets/images/MergeTokens/Undo.svg';
import Signature from 'app/assets/images/MergeTokens/Signature.svg';
import PlacementSkillSetIcon from 'app/assets/images/MergeTokens/PlacementSkillSetIcon.svg';
import TravelerEducationIcon from 'app/assets/images/MergeTokens/TravelerEducationIcon.svg';
import TravelerPermanentAddressFullIcon from 'app/assets/images/MergeTokens/TravelerPermanentAddressFullIcon.svg';


export const getContractMergeToken = customSwitch({
  AddendumOrdinalNumber: { icon: AddendumOrdinalNumber },
  AdditionalPremiumPay: { icon: AdditionalPremiumPay },
  Amount: { icon: DollarSign },
  // 'CapAmount': { icon: DollarSign , label: t('Cap Amount') },
  AmountPerMile: { icon: DollarSign },
  AmountToTraveler: { icon: DollarSign },
  ApprovedByName: { icon: ApprovedByName },
  ArrivalAmountPerMile: { icon: DollarSign },
  ArrivalCap: { icon: DollarSign },
  BonusAmount: { icon: DollarSign },
  BrandHandbook: { icon: BrandHandbook },
  CallBackRate: { icon: CallBackRate },
  CellPhoneStipend: { icon: CellPhoneStipend },
  ChargeRate: { icon: DollarSign },
  CompanyLegalName: { icon: CompanyLegalName },
  CompanyProgramName: { icon: CompanyLegalName },
  ContractID: { icon: ApprovedByName },
  // 'ContractID': { icon: ApprovedByName },
  ContractLanguage: { icon: ContractLanguage },
  CorporateTitle: { icon: CorporateTitle },
  CountyOfCompany: { icon: Location },
  Days: { icon: Calendar },
  Description: { icon: Description },
  Effective: { icon: Effective },
  EffectiveDate: { icon: Calendar },
  EligibilityDate: { icon: Calendar },
  EndDate: { icon: EndDate },
  EndingAmountPerMile: { icon: DollarSign },
  EndingCap: { icon: DollarSign },
  ErrorText: { icon: ErrorText },
  Exceptions: { icon: Exceptions },
  FacilityLocation: { icon: Location },
  FacilityName: { icon: CorporateTitle },
  // 'FacilityName': { icon: CorporateTitle },
  Frequency: { icon: Frequency },
  FromTime: { icon: FromTime },
  GWW: { icon: Guaranteed },
  HolidayRate: { icon: DollarSign },
  HolidayVerbiage: { icon: Holiday },
  HousingDescription: { icon: Housing },
  InitialEndDate: { icon: EndDate },
  InitialStartDate: { icon: StartDate },
  InsuranceAmount: { icon: DollarSign },
  InterimCap: { icon: DollarSign },
  MealsPerDiem: { icon: Meals },
  MinReqHours: { icon: Hours },
  MinReqShift: { icon: CalendarDots },
  Modification: { icon: ContractEdit },
  MonthlyAmount: { icon: DollarSign },
  NewEndDate: { icon: EndDate },
  NewStartDate: { icon: StartDate },
  OnCallPayRate: { icon: OnCallRate },
  OnCallRate: { icon: OnCallRate },
  OnlineOrientation: { icon: DollarSign },
  OrderDiscipline: { icon: Discipline },
  Orientation: { icon: DollarSign },
  Payer: { icon: Payer },
  PayRate: { icon: DollarSign },
  // RegularPayRate: { icon: DollarSign },
  People: { icon: People },
  PerDiemAdjustments: { icon: DollarSign },
  PerDiemAdjustmentsSubsidy: { icon: DollarSign },
  PetTypes: { icon: PetType },
  PlacementEndDate: { icon: EndDate },
  // PlacementEndDate: { icon: EndDate },
  PlacementID: { icon: ApprovedByName },
  // PlacementId: { icon: ApprovedByName },
  PlacementRequiredHours: { icon: Hours },
  PlacementStartDate: { icon: StartDate },
  PreceptorRate: { icon: DollarSign },
  PublicLink: { icon: Folder },
  Refundable: { icon: Undo },
  RegularRate: { icon: DollarSign },
  RequiredHours: { icon: Hours },
  Settlement: { icon: DollarSign },
  Shift: { icon: CalendarDots },
  ShiftOffered: { icon: CalendarDots },
  Signature: { icon: Signature },
  StartDate: { icon: StartDate },
  StateOfCompany: { icon: Location },
  TimeOffDates: { icon: Calendar },
  TimeOffEndDate: { icon: EndDate },
  TimeOffStartDate: { icon: StartDate },
  // CurrentDate: { icon: Calendar },
  TodaysDate: { icon: Calendar },
  // TodaysDate: { icon: Calendar },
  TotalAmount: { icon: DollarSign },
  ToTime: { icon: Hours },
  // EmployeeName: { icon: ApprovedByName },
  TravelerName: { icon: ApprovedByName },
  UHBRate: { icon: DollarSign },
})({ icon: null });

export const getConfirmationMergeToken = customSwitch({
  FacilityName: { icon: FacilityNameIcon },
  ApprovedTimeOff: { icon: ApprovedTimeOffIcon },
  BillRate: { icon: BillRateIcon },
  CallBackBillRate: { icon: CallbackBillrateIcon },
  ContractDates: { icon: ContractDatesIcon },
  DoubleTimeBillRate: { icon: DoubleTimeBRIcon },
  EndDate: { icon: EndDateIcon },
  FacilityAddress: { icon: AddressPinIcon },
  FacilityCity: { icon: AddressPinIcon },
  TCCName: { icon: CoordinatorIcon },
  FacilityState: { icon: AddressPinIcon },
  FacilityZipPostalCode: { icon: ZipPostalCodeIcon },
  HAMCompany: { icon: HAMCompanyIcon },
  HAMCompanyID: { icon: HAMCompanyIDIcon },
  HAMEmail: { icon: EmailIcon },
  HAMFaxNumber: { icon: FaxIcon },
  HAMName: { icon: ContactIcon },
  HAMPhoneNumber: { icon: PhoneIcon },
  HolidayBillRate: { icon: BillRateIcon },
  MinReqHours: { icon: DottedClockIcon },
  MinReqHoursBillRate: { icon: BillRateIcon },
  NumWeeks: { icon: CalendarIcon },
  OnCallBillRate: { icon: BillRateIcon },
  'OrderVMSReq#': { icon: OrderVMSReqIcon },
  OvertimeBillRate: { icon: BillRateIcon },
  PlacementDiscipline: { icon: PlacementDisciplineIcon },
  PlacementExpectedWorkHours: { icon: PlacementExpectedWorkHoursIcon },
  PlacementID: { icon: PlacementIDIcon },
  BookingCompany: { icon: HAMCompanyIcon },
  ContractEffectiveDate: { icon: TodayDateIcon },
  ContractGWWExceptions: { icon: GWWIcon },
  ContractHours: { icon: ClockIcon },
  SendConfirmationToName: { icon: ContactIcon },
  SendConfirmationToNameTitle: { icon: ConfirmationTitleIcon },
  SendConfirmationToNameTitleFax: { icon: FaxIcon },
  SendConfirmationToNameFax: { icon: ConfirmationTitleIcon },
  Shift: { icon: ClockIcon },
  StartDate: { icon: StartDateIcon },
  TodayDate: { icon: TodayDateIcon },

  CompanyAddress: { icon: AddressPinIcon },
  CompanyCity: { icon: AddressPinIcon },
  CompanyState: { icon: AddressPinIcon },
  CompanyZipCode: { icon: ZipPostalCodeIcon },

  TravelerAddress1: { icon: HomeIcon },
  TravelerAddress2: { icon: HomeIcon },
  TravelerCity: { icon: FacilityCityIcon },
  TravelerDisciplines: { icon: PlacementDisciplineIcon },
  TravelerDOBMMDD: { icon: DOBIcon },
  TravelerDOB: { icon: DOBIcon },
  TravelerEMail: { icon: EmailIcon },
  TravelerFirstName: { icon: FirstNameIcon },
  TravelerGender: { icon: GenderIcon },
  TravelerLastName: { icon: LastNameIcon },
  TravelerMainPhone: { icon: PhoneIcon },
  TravelerMiddleInitial: { icon: MiddleInitialIcon },
  TravelerMiddleName: { icon: MiddleNameIcon },
  TravelerSSN4: { icon: SSNIcon },
  TravelerSSN6: { icon: SSNIcon },
  TravelerSSN: { icon: SSNIcon },
  TravelerState: { icon: AddressPinIcon },
  TravelerZip: { icon: ZipPostalCodeIcon },
  TravelerPermanentAddressFull: { icon: TravelerPermanentAddressFullIcon },
  TravelerStateLicense: { icon: VerifiedShieldIcon },
  UnitName: { icon: UnitNameIcon },
  UnitHAMSignature: { icon: SignatureIcon },
  'IMG: AlliedLogoEFO.jpg,55,55': { icon: ImageIcon },
  'IMG: AMNLogoWithTagline.jpg,40,40': { icon: ImageIcon },
  'IMG: NCLogoWithTagline.jpg,40,40': { icon: ImageIcon },
  'IMG: OGPILogoWithTagline.jpg,40,40': { icon: ImageIcon },
  GuaranteedWorkWeek: { icon: GWWIcon },
  PlacementSkillSet: { icon: PlacementSkillSetIcon },
  OrderSkillSet: { icon: PlacementSkillSetIcon },
  TravelerEducation: { icon: TravelerEducationIcon },
})({ icon: null });
