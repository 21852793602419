import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CustomSideFilter } from '../../../../ComponentLibrary/Filter';
import { getAdvancedFilterSpecs, recPlacementsFilterName } from './filterUtils';

export const AdvancedFilter = props => {
  const { t } = useTranslation();
  const { setValue, getValues } = useFormContext();
  const fields = getAdvancedFilterSpecs(t);

  const onReset = () => {
    setValue(recPlacementsFilterName.nonContactedDate, null, { shouldDirty: false });
    setValue(recPlacementsFilterName.lastApplicationDateRange, null, { shouldDirty: false });
    setValue(recPlacementsFilterName.availableToStartDateRange, null, { shouldDirty: false });
    setValue(recPlacementsFilterName.callBackDateRange, null, { shouldDirty: false });
    setValue(recPlacementsFilterName.activeCues, null, { shouldDirty: false });

    const nullObject = {
      nonContactedDate: null,
      lastApplicationDateRange: null,
      availableToStartDateRange: null,
      callBackDateRange: null,
    };
    props.onApplyFilter({
      ...getValues(),
      ...nullObject,
    });
  };

  return <CustomSideFilter fields={fields} onReset={onReset} />;
};
