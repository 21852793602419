import { missingField } from 'app/constants';
import { httpSuccess } from 'app/services/serviceHelpers';
import { GetMomentOfDate } from 'utils/dates/moment';
import { extractTransportationOpts, extractTypeAheadPicklistOpts } from './sagaHelper';

export const formatAuditRecords = auditDetails =>
  auditDetails.map((item, index) => {
    const insertDateMoment = GetMomentOfDate(item.insertDate);
    const updateDateMoment = GetMomentOfDate(item.lastUpdated);

    return {
      id: index,
      auditId: item.auditId,
      auditTypeId: item.auditTypeId,
      status: item.status ?? missingField,
      holdFileOut: item.holdFileOut ?? missingField,
      strikeApproved: item.strikeApproved ?? missingField,
      auditType: item.auditType ?? missingField,
      auditCategory: item.auditCategory ?? missingField,
      auditSubCategory: item.auditSubCategory ?? missingField,
      auditAction: item.auditAction ?? missingField,
      personResponsible: item.personResponsible ?? missingField,
      description: item.description ?? missingField,
      placementId: item.placementId ?? missingField,
      insertDate: insertDateMoment.isValid() ? insertDateMoment.format('MM/DD/YYYY hh:mm:ss A') : missingField,
      insertedByUser: item.insertedByUser ?? missingField,
      lastUpdated: updateDateMoment.isValid() ? updateDateMoment.format('MM/DD/YYYY hh:mm:ss A') : missingField,
      lastUpdatedByUser: item.lastUpdatedByUser ?? missingField,
      actionable: item.actionable ?? missingField,
      group: item.group ?? missingField,
      isSelected: false,
    };
  });

export function extract(data) {
  const _data = Object.entries<any>(data).reduce((results, [key, value]) => {
    if (!!value && typeof value === 'object' && 'status' in value)
      results[key] = httpSuccess(value.status) ? value.data : null;

    return results;
  }, {});
  return {
    ...data,
    data: { ..._data, nextStatusRequirement: data.nextStatusRequirement },
    status: 200,
    message: null,
  };
}

export const extractOpts = (key , data) =>
key === 'transportationOpts'
  ? extractTransportationOpts(data || [])
  : extractTypeAheadPicklistOpts(data || [], item =>
      key === 'airportCodes'
        ? item.value
        : key === 'travelTimePreferenceOpts'
        ? { travelTimePreferenceId: parseInt(item.key), travelTimePreference: item.value }
        : item,
    );

