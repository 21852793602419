/* eslint-disable i18next/no-literal-string */
import React, { useContext } from 'react';
import { Expandable } from 'app/components/Common/Expandable/Expandable';
import ScreenIcon from 'app/assets/images/IconImages/ScreenIcon.svg';
import { IconTitle } from './Titles/IconTitle';
import { CandidatePlacements } from 'app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/CandidatePlacements';
import { useDispatch, useSelector } from 'react-redux';
import { selectPlacementCandidateRecords } from 'store/redux-store/placement-details/selectors';
import { usePromiseTracker } from 'react-promise-tracker';
import { placementDetailsAction } from 'store/redux-store/placement-details/slice';
import { AddOnContext } from './PlacementSummaryTab';

export const Placements = ({ ...props }) => {
  const dispatch = useDispatch();
  const { placementId } = useContext(AddOnContext);
  const { promiseInProgress } = usePromiseTracker({ area: 'placement-candididate-placement-count-details', delay: 0 });
  const { 
    isCandidatePlacementCountsLoaded 
  } = useSelector(selectPlacementCandidateRecords);

    // Function to handle fetching data
    const handleExpand = React.useCallback((isExpanding) => {
      if (isExpanding && !(isCandidatePlacementCountsLoaded ?? false)) {
        dispatch(
          placementDetailsAction.getPlacementCandidatePlacementCountAction({ 
            placementId, 
            isInitLoad: false  
          })
        )
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dispatch, isCandidatePlacementCountsLoaded]);

  return (
    <Expandable
      id="placement-summary-placements"
      expanded={false}
      loading={promiseInProgress}
      titleComponent={<IconTitle title={'Placements'} icon={<img src={ScreenIcon} alt="Screen" />} />}
      onChange={handleExpand}
    >
      <CandidatePlacements />
    </Expandable>
  );
};
