import { isArray, orderBy, uniqBy } from 'lodash';
import moment from 'moment';
import { getDropDownOption } from '../Audit/Helpers/AuditAddHelpers';
import { GetMomentOfDate } from 'utils/dates/moment';
import { DegreesMajors } from 'app/models/Candidate/CandidateProfile';
import states from 'app/assets/jsons/State.json';
import Lookups from 'app/assets/jsons/Credentials/Lookups.json';
import { userRoles } from 'oidc/userRoles';
import treeData from 'app/assets/jsons/placementStatusFilter.json';
import _ from 'lodash';
import { RequirementType } from 'app/models/Credentialing/enums/RequirementType';
import { ISkillSetExpiration, ISkillSetExpiryStatus } from 'app/enums/SkillSet';
import { ISkillCheckListDetails } from 'app/models/Candidate/SkillSet';
import { Authorized } from 'oidc/userHelper';

interface workGapObjType {
  gapDuration: number;
  startedOn: Date;
  endedOn: Date;
}
interface IArrayOfDates {
  startedOn?: any;
  graduatedOnYear: any;
  graduatedOnMonth: any;
}
export const refactorData = arrayData => {
  if (isArray(arrayData)) {
    return arrayData.map(item => {
      return {
        ...item,
        holdFileOut: item?.holdFileOut ? 'Yes' : 'No',
        actionable: item?.actionable ? 'Yes' : 'No',
      };
    });
  } else return [];
};
/**
 * Helper to return true if received DateRangeB lies within DateRangeA span
 * @param dateRangeA1
 * @param dateRangeA2
 * @param dateRangeB1
 * @param dateRangeB2
 */
export const isDateRangesOverlap = (dateRangeA1, dateRangeA2, dateRangeB1, dateRangeB2) => {
  return (
    moment(dateRangeA1).valueOf() <= moment(moment(dateRangeB2).format('MM/DD/YYYY')).valueOf() &&
    moment(dateRangeA2).valueOf() >= moment(moment(dateRangeB1).format('MM/DD/YYYY')).valueOf()
  );
};
/**
 * Helper to get latest graduation date
 * @param arrayOfDates
 */
export const getLatestDate = (arrayOfDates: IArrayOfDates[]) => {
  if (arrayOfDates?.length !== 0) {
    const latestDate = arrayOfDates?.reduce((r, o) => {
      if (o?.graduatedOnYear !== r?.graduatedOnYear) {
        return o?.graduatedOnYear > r?.graduatedOnYear ? o : r;
      } else {
        return o?.graduatedOnMonth > r?.graduatedOnMonth ? o : r;
      }
    });
    return latestDate;
  } else return {};
};
/**
 * Helper to get earliest experience start date
 * @param arrayOfDates
 */
export const getEarliestDate = (arrayOfDates: IArrayOfDates[]) => {
  if (arrayOfDates?.length !== 0) {
    return arrayOfDates.reduce((r, o) => (o?.startedOn < r?.startedOn ? o : r))?.startedOn;
  } else return {};
};
/**
 * Helper to create work experience details array
 * @param workExpArray
 */
export const getWorkGapDetails = workExpArray => {
  const workGapDetails: Array<workGapObjType> = [];
  workExpArray?.forEach(item => {
    if (item?.workGap > 120) {
      const workGapObj: any = {};
      workGapObj.gapDuration = item.workGap;
      workGapObj.startedOn = item.previousEndedOn;
      workGapObj.endedOn = item.startedOn;
      workGapDetails.push(workGapObj);
    }
  });
  return workGapDetails;
};
/**
 * Helper to get graduation date
 * @param data
 */
export const extractDate = data => {
  var datePattern = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
  if (!datePattern.test(data?.graduatedOn)) {
    const month = data?.graduatedOnMonth === null ? '01' : data?.graduatedOnMonth;
    const year = data?.graduatedOnYear;
    let date;
    switch (month) {
      case '01' || '03' || '05' || '07' || '08' || '10' || '12':
        date = '31';
        break;
      case '02':
        year % 4 === 0 ? (date = '29') : (date = '28');
        break;
      default:
        date = '30';
        break;
    }
    return `${month}/${date}/${year}`;
  } else {
    return data?.graduatedOn;
  }
};

export const formatData = (data, lookupSelection, auditSubCategory, auditCategory) => {
  const formattedData = {
    pType: getDropDownOption(lookupSelection?.auditType?.find(item => item.name === data.auditType)),
    pCategory: getDropDownOption(auditCategory),
    pSubCategory: getDropDownOption(auditSubCategory),
    pActionable: getDropDownOption(lookupSelection?.auditAction?.find(item => item.name === data.actionable)),
    pGroup: data.group,
    pStatus: getDropDownOption(lookupSelection?.auditStatus?.find(item => item.name === data.status)),
    pHoldFileOut: data.holdFileOut === 'Yes',
    pAction: getDropDownOption(lookupSelection?.auditAction?.find(item => item.name === data.auditAction)),
    pPrimaryResponsibleParty: getDropDownOption(
      lookupSelection?.auditResponsible?.find(item => item.name === data.personResponsible),
    ),
    pDescription: data.description,
    isPlacementRequired: false,
    isSubcategoryRequired: false,
    primaryResponsibleChipValues: [],
    pPlacementID: data.placementId === '--' ? null : data.placementId,
  };
  return formattedData;
};

export interface Info {
  dateOfBirth: string | null;
  socialSecurityNumber: string;
  gender: string;
  phone: string;
  email: string;
  emergencyContact: string;
  address: string;
}

export interface ICandidateProfileSummaryDetails {
  candidateId: number;
  brandId: number;
  currentEmployeeId: number;
  currentEmployeeName: string;
  ssn: string;
  dob: string | null;
  timestamp?: string;
}

export interface CandidateMatch {
  skillset: string;
  interviewStatus?: number;
  tooltipMessage?: any;
}

export enum EducationTooltipType {
  education,
  groupedList,
  verifiedList,
}

export enum Type {
  TEMPORARY = 'Temporary',
  PERMANENT = 'Permanent',
  CRISIS = 'Crisis',
  PREP_ACT = 'Prep Act',
  AHA = 'AHA',
  HOSPITAL = 'Hospital',
  OTHER = 'Other',
  CHSF = 'CHSF',
  AHSI = 'AHSI',
  MILITARY = 'Military',
  RED_CROSS = 'Red Cross',
}

export enum CredentialType {
  TEMPORARY = 191,
  PERMANENT = 192,
  CRISIS = 1002,
  PREP_ACT = 1024,
  AHA = 241,
  RED_CROSS = 242,
  HOSPITAL = 243,
  OTHER = 244,
  CHSF = 298,
  AHSI = 299,
  MILITARY = 535,
}

export enum TooltipMessage {
  LicenseExpired = 'Licensure Expired',
  LicenseExpiringSoon = 'Licensure Expiring Soon',
  LicenseIncomplete = 'Licensure Incomplete',
  CertificationExpired = 'Certificate Expired',
  CertificationExpiringSoon = 'Certificate Expiring Soon',
  CertificationIncomplete = 'Certificate Incomplete',
}

export enum DateValue {
  CURRENT = 'Current',
  SOON = 'Soon',
}

export enum HearsayFields {
  State = 'State',
  License = 'License',
  Certification = 'Certification',
  LicenseNumber = 'License Number',
  ExpirationDate = 'Expiration Date',
  IssuedBy = 'Issued By',
}

export enum CredentialName {
  Licenses = 'Licenses',
  Certification = 'Certifications',
  HearsayCertification = 'Hearsay Certification',
  HearsayLicense = 'Hearsay License',
}
export interface CandidateVirtualInterview {
  skillset: string;
  interviewStatus: boolean;
  toolTipMessage?: any;
  interviewForFuture?: boolean;
  scheduledIncompleteInterview?: boolean;
  noVirtualInterview?: boolean;
}

export const workExperianceUserRoles = [
  userRoles.recruitment,
  userRoles.clinical_Manager,
  userRoles.clinical_Director,
  userRoles.clinical_ReviewAnalyst,
  userRoles.clinical_QualificationsSpecialist,
  userRoles.recruitment_Leadership,
  userRoles.recruitment_TeamMember,
];

export const ssnViewableUserRoles = [
  userRoles.accountManagement_Leadership,
  userRoles.accountManagement_TeamMember,
  userRoles.recruitment_TeamMember,
  userRoles.recruitment_Leadership,
  userRoles.credentialing_Leadership,
  userRoles.credentialing_TeamMember,
  userRoles.clinical_Director,
  userRoles.clinical_Manager,
  userRoles.clinical_QualificationsSpecialist,
  userRoles.clinical_ReviewAnalyst,
  userRoles.payrollSpecialist,
  userRoles.housingAccountExecutive,
  userRoles.timeProcessingSpecialist,
  userRoles.workersCompCoordinator,
  userRoles.riskManagement,
  userRoles.complianceAuditSpecialist,
  userRoles.benefitsCoordinator,
  userRoles.customerSupport_Leadership,
  userRoles.customerSupport_TeamMember,
];

export const calenderViewableUserRoles = [
  userRoles.accountManagement_Leadership,
  userRoles.accountManagement_TeamMember,
  userRoles.recruitment_TeamMember,
  userRoles.recruitment_Leadership,
  userRoles.credentialing_Leadership,
  userRoles.credentialing_TeamMember,
  userRoles.clinical_Director,
  userRoles.clinical_Manager,
  userRoles.clinical_QualificationsSpecialist,
  userRoles.clinical_ReviewAnalyst,
  userRoles.payrollSpecialist,
  userRoles.customerSupport_Leadership,
  userRoles.customerSupport_TeamMember,
  userRoles.housingAccountExecutive,
  userRoles.timeProcessingSpecialist,
  userRoles.workersCompCoordinator,
  userRoles.riskManagement,
  userRoles.complianceAuditSpecialist,
  userRoles.benefitsCoordinator,
];

export const rolePermission = [
  userRoles.credentialing_Leadership,
  userRoles.credentialing_TeamMember,
  userRoles.clinical_QualificationsSpecialist,
  userRoles.payrollSpecialist,
];

export const UserRolePermission = [
  userRoles.credentialing_Leadership,
  userRoles.credentialing_TeamMember,
  userRoles.clinical_QualificationsSpecialist,
];

export const qualificationCardPermissions = [
  userRoles.recruitment,
  userRoles.recruitment_Leadership,
  userRoles.recruitment_TeamMember,
  userRoles.accountManagement,
  userRoles.accountManagement_Leadership,
  userRoles.accountManagement_TeamMember,
];

export const handleChangeValue = (property, value, info, setInfo) => {
  let dob;
  let ssn;
  if (property === 'dob') {
    const momentDob = GetMomentOfDate(value ? value : info.dateOfBirth);
    dob = momentDob.isValid() ? momentDob.format('MM/DD/YYYY') : '';
  } else {
    ssn = value;
  }
  const processedData: Info = {
    dateOfBirth: dob ? dob : info.dateOfBirth,
    socialSecurityNumber: ssn || property === 'ssn' ? ssn : info.socialSecurityNumber,
    gender: info.gender,
    phone: info.phone,
    email: info.email,
    emergencyContact: info.emergencyContact,
    address: info.address,
  };
  setInfo(processedData);
};

export const resetDob = (info, setInfo, candidateDetails) => {
  const momentOfDOB = GetMomentOfDate(candidateDetails.dateOfBirth);
  const processedData: Info = {
    ...info,
    dateOfBirth: momentOfDOB.isValid() ? momentOfDOB.format('MM/DD/YYYY') : null,
  };
  setInfo(processedData);
};

export const resetSsn = (info, setInfo, candidateDetails) => {
  const processedData: Info = {
    ...info,
    socialSecurityNumber: candidateDetails.socialSecurityNumber ? formatSsn(candidateDetails.socialSecurityNumber) : '',
  };
  setInfo(processedData);
};

export const formatSsn = (ssn: any) => {
  //Code Updated by ->Abhishek Wilson : ssn is not recieved as string
  //Navigate to sms modal and associate unkown number to a candidate ...ssn is recieved as a object
  //---------------------///
  let _ssn = ssn;
  if (typeof ssn === 'object') {
    _ssn = ssn.ssn;
  }
  const formattedSsn = _ssn?.replace(/-/g, '');
  return formattedSsn;
};

export const validateIncompleteRecord = (metaData, data) => {
  var isIncomplete;
  if (metaData && data) {
    if (metaData.matchingDegree && metaData.matchingMajor && data.isGraduationDateValid && metaData.isVerified)
      return false;
    if (
      (metaData.matchingDegree &&
        metaData.matchingMajor &&
        (!data.isGraduationDateValid || data.isGraduationDateValid == null) &&
        metaData.isVerified) ||
      (metaData.matchingDegree && !metaData.matchingMajor && data.isGraduationDateValid && metaData.isVerified)
    )
      isIncomplete = true;

    if (metaData.matchingDegree && metaData.matchingMajor && !metaData.isVerified) isIncomplete = true;
  }

  return isIncomplete;
};

export const checkInvalidRecord = metaData => {
  if (metaData.isMissingDisciplineEducation || !metaData.matchingDegree || metaData.matchingDegreesMajors == null)
    return true;
};

export const pickHighestDegree = (data: DegreesMajors[] | null, statusFlag: any) => {
  if (!data) return null;
  const arrangeData: DegreesMajors[] = [];
  [
    'Doctorate',
    'Masters',
    'Bachelors',
    'Associates',
    'Certificate Program',
    'Diploma',
    'On the Job Training',
    'Other',
    'ADN',
    'ASN',
    'BSN',
    'LPN',
    'MS',
    'MSN',
    'NP/Masters',
    'Pharm.D',
    'PHD',
    'RN',
    'RPH',
    'RT',
  ].map(item => {
    const idx = data?.findIndex(
      d =>
        (d.isGraduationDateValid === true &&
          !statusFlag &&
          d.degree?.toString().toLowerCase().replace(/[`']+/g, '') === item.toString().toLowerCase()) ||
        (statusFlag && d.degree?.toString().toLowerCase().replace(/[`']+/g, '') === item.toString().toLowerCase()),
    );
    if (idx >= 0) {
      arrangeData.push(data[idx]);
    }
  });

  return arrangeData.length > 0 ? arrangeData[0] : null;
};

export const isPropValuesEqual = (subject, target, propNames) =>
  propNames.every(propName => subject[propName] === target[propName]);

export const getUniqueItemsByProperties = (items, propNames) => {
  const propNamesArray = Array.from(propNames);
  return items?.filter(
    (item, index, array) => index === array?.findIndex(foundItem => isPropValuesEqual(foundItem, item, propNamesArray)),
  );
};

export const checkInterviewDateOver = interviewDate => {
  return interviewDate !== null ? moment().valueOf() > moment(interviewDate).valueOf() : false;
};
export const checkInterviewDateUpcoming = interviewDate => {
  return interviewDate !== null ? moment().valueOf() < moment(interviewDate).valueOf() : false;
};

export const checkScheduledIncompleteInterview = item => {
  return (
    item.interverStatus !== 'Completed' &&
    item.interverStatus !== 'Rejected' &&
    checkInterviewDateOver(item?.interviewDate)
  );
};

export const getExpirationDateLabel = expDate => {
  if (expDate) {
    const date = new Date(expDate);
    date.setDate(date.getDate() + 1095);
    const current = new Date();
    current.setDate(current.getDate() + 90);
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (date.getTime() > current.getTime()) {
      const formatDate = moment(date).format('MM/DD/YYYY');
      return `Valid thru ${formatDate}`;
    } else if (date.getTime() >= yesterday.getTime() && date.getTime() <= current.getTime()) {
      const formatDate = moment(date).format('MM/DD/YYYY');
      return `Expiring ${formatDate}`;
    } else if (date.getTime() < yesterday.getTime()) {
      const formatDate = moment(date).format('MM/DD/YYYY');
      return `Expired ${formatDate}`;
    }
  } else {
    return `No Expiration Date`;
  }
};

export const generateHeader = (data, verifiedSkillSets, allSkillSets) => {
  let isValid = false;
  let isVerified = false;
  let expired = false;
  let isExpiring = false;
  verifiedSkillSets?.map(skillSet => {
    if (
      skillSet.disciplineAbbr == data.discipline &&
      skillSet.specialtyAbbr == data.specialty &&
      skillSet.subSpecialtyAbbr == data.subSpecialty
    )
      isVerified = true;
  });
  allSkillSets.map(skillSet => {
    if (
      skillSet.disciplineAbbr == data.discipline &&
      skillSet.specialtyAbbr == data.specialty &&
      skillSet.subSpecialtyAbbr == data.subSpecialty
    )
      isValid = true;
  });
  const diff = getDifference(data?.matchingSkillsChecklist?.dateCompleted, 'Current', false);
  if (diff) {
    if (diff < -1095) expired = true;
    if (diff > -1095 && diff < -1005) isExpiring = true;
  }
  if (!isValid && data.matchingSkillsChecklist?.requirementTypeID > 0 && !expired && !isExpiring)
    return `${data?.matchingSkillsChecklist?.description} - Skillset addition pending`;
  if (
    isValid &&
    !isVerified &&
    !expired &&
    data.matchingSkillsChecklist?.dateCompleted !== null &&
    (data.matchingSkillsChecklist?.requirementID > 0 || (data.disciplineID == 0 && data.requirementTypeID > 0))
  )
    return `${data?.matchingSkillsChecklist?.description} - Skillset verification pending`;
  if (isValid && data.matchingSkillsChecklist == null)
    return `${data.discipline}, ${data.specialty} - Missing skills checklist`;
  if (!isValid && data.matchingSkillsChecklist == null)
    return `${data.discipline}, ${data.specialty} - Skills checklist not added`;
  else return data?.matchingSkillsChecklist?.description;
};

export const getDifference = (value1, value2, condition) => {
  var d1 = moment(value1);
  var d2;
  if (value2 == 'Current') {
    d2 = moment(new Date());
  } else {
    d2 = moment(value2, 'M/D/YYYY');
  }
  var diffDays = d1.diff(d2, 'days');
  if (diffDays < -1095 && (condition == true || condition == -1)) {
    if (condition) return 'EXPIRED';
    else return diffDays;
  }
  if (diffDays > -1095 && diffDays < -1005) {
    if (condition !== -1) {
      if (condition) return `EXPIRING IN ${1095 + diffDays} DAYS`;
      else return diffDays;
    }
  }
  if (diffDays <= 0 && !condition) {
    return diffDays;
  } else {
    return undefined;
  }
};

export const validateChecked = (data, verifiedSkillSets) => {
  let isChecked = false;
  verifiedSkillSets?.map(skillSet => {
    if (
      skillSet.disciplineAbbr == data.discipline &&
      skillSet.specialtyAbbr == data.specialty &&
      skillSet.subSpecialtyAbbr == data.subSpecialty &&
      data.matchingSkillsChecklist?.requirementID > 0
    )
      isChecked = true;
  });
  const diff = getDifference(data?.matchingSkillsChecklist?.dateCompleted, 'Current', false);
  if (diff) {
    if (diff < -1095) isChecked = false;
  }
  return isChecked;
};

export const validateIncomplete = (data, verifiedSkillSets, allSkillSets) => {
  let isValid = false;
  let isVerified = false;
  let isExpired = false;
  let isExpiring = false;
  verifiedSkillSets.forEach(skillSet => {
    if (
      skillSet.disciplineAbbr == data.discipline &&
      skillSet.specialtyAbbr == data.specialty &&
      skillSet.subSpecialtyAbbr == data.subSpecialty &&
      data.matchingSkillsChecklist?.requirementID > 0
    )
      isVerified = true;
  });
  allSkillSets.forEach(skillSet => {
    if (
      skillSet.disciplineAbbr == data.discipline &&
      skillSet.specialtyAbbr == data.specialty &&
      skillSet.subSpecialtyAbbr == data.subSpecialty
    )
      isValid = true;
  });
  const diff = getDifference(data?.matchingSkillsChecklist?.dateCompleted, 'Current', false);
  if (diff) {
    if (diff < -1095) isExpired = true;
    if (diff > -1095 && diff < -1005) isExpiring = true;
  }
  return (
    (isValid && !isVerified && !isExpired) ||
    (!isValid && data.matchingSkillsChecklist?.requirementID > 0 && !isExpired) ||
    (!isValid &&
      data.disciplineID == 0 &&
      data.matchingSkillsChecklist?.requirementTypeID > 0 &&
      !isExpired &&
      !isExpiring)
  );
};

export const validateAlert = (data, verifiedSkillSets, allSkillSets) => {
  let isValid = false;
  let isExpired = false;
  let isExpiring = false;
  verifiedSkillSets.forEach(skillSet => {
    if (
      skillSet.disciplineAbbr == data.discipline &&
      skillSet.specialtyAbbr == data.specialty &&
      skillSet.subSpecialtyAbbr == data.subSpecialty &&
      data.matchingSkillsChecklist?.requirementID > 0
    )
      isValid = true;
  });
  allSkillSets.forEach(skillSet => {
    if (
      skillSet.disciplineAbbr == data.discipline &&
      skillSet.specialtyAbbr == data.specialty &&
      skillSet.subSpecialtyAbbr == data.subSpecialty
    )
      isValid = true;
  });
  const diff = getDifference(data?.matchingSkillsChecklist?.dateCompleted, 'Current', false);
  if (diff) {
    if (diff < -1095) isExpired = true;
    if (diff > -1095 && diff < -1005) isExpiring = true;
  }
  return isExpired || (!isValid && isExpiring);
};

export const getWarningText = (dateCompleted, isMissingChecklist = false) => {
  if (isMissingChecklist) return 'Missing Skill Checklist';
  if (dateCompleted) {
    const date = new Date(dateCompleted);
    date.setDate(date.getDate() + 1095);
    const current = new Date();
    current.setDate(current.getDate() + 90);
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (date.getTime() > current.getTime()) {
      return '';
    } else if (date.getTime() >= yesterday.getTime() && date.getTime() <= current.getTime()) {
      return 'Expiring Soon';
    } else if (date.getTime() < yesterday.getTime()) {
      return 'Expired';
    }
  } else {
    return '';
  }
};

export const compareDates = (value1, value2) => {
  let d2;
  const d1 = new Date(value1);
  if (value2 == DateValue.CURRENT) {
    d2 = new Date();
  } else if (value2 == DateValue.SOON) {
    const current = new Date();
    current.setDate(current.getDate() + 30);
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return d1.getTime() >= yesterday.getTime() && d1.getTime() <= current.getTime();
  } else {
    d2 = new Date(value2);
  }
  return d1 >= d2;
};

export const updateLicensesTypeState = (licenseTypes: any[], candidateDetails, setCandidateLicenses) => {
  if (candidateDetails?.licenses && candidateDetails.licenses?.length > 0 && licenseTypes && licenseTypes.length > 0) {
    let licenses = [];

    candidateDetails.licenses?.forEach(item => {
      if (item.hearsayLicense) {
        const type = licenseTypes.find(x => x.name === item.type);
        licenses.push({ requirementType: type ? type.value : item.type, ...item });
      }
    });
    setCandidateLicenses(licenses);
  }
};

export const getWarningColor = message => {
  if (message == 'Expired') return '#BD3B4D';
  else if (message == 'Expiring Soon') return '#F39E44';
  else return 'grey';
};

export const getWarningMessage = (value1, value2) => {
  var d1 = moment(value1);
  var d2;
  if (value2 == DateValue.CURRENT) {
    d2 = moment(new Date());
  } else {
    d2 = moment(value2, 'M/D/YYYY');
  }
  var diffDays = d1.diff(d2, 'days');
  if (diffDays < 0) {
    return 'Expired';
  }
  if (diffDays >= 0 && diffDays < 30) {
    return 'Expiring Soon';
  } else {
    return undefined;
  }
};

export const getState = id => {
  return states.states.find(item => item.StateNumber == id)?.Description
    ? states.states.find(item => item.StateNumber == id)?.Description
    : '--  ';
};

export const getStateById = id => {
  return states.states.find(item => item.ID == id)?.Description
    ? states.states.find(item => item.ID == id)?.Description
    : '--  ';
};

export const getExpirationMessage = (item, type) => {
  if (item.expirationDate) {
    const date = new Date(item.expirationDate);
    const current = new Date();
    current.setDate(current.getDate() + 30);
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (date.getTime() >= yesterday.getTime() && date.getTime() <= current.getTime()) {
      return type == 'cert' ? TooltipMessage.CertificationExpiringSoon : TooltipMessage.LicenseExpiringSoon;
    } else if (date.getTime() < yesterday.getTime()) {
      return type == 'cert' ? TooltipMessage.CertificationExpired : TooltipMessage.LicenseExpired;
    } else {
      return null;
    }
  }
};

export const getCompletionMessage = (item, type) => {
  if (item.dateCompleted) {
    const date = new Date(item.dateCompleted);
    const expirationDate = new Date(item.expirationDate);
    const current = new Date();
    current.setDate(current.getDate() - 1);
    if (!(date.getTime() < current.getTime()) && expirationDate.getTime() > current.getTime()) {
      return type == 'cert' ? TooltipMessage.CertificationIncomplete : TooltipMessage.LicenseIncomplete;
    } else {
      return null;
    }
  }
};

export const getCredentialType = (typeId: number | undefined) => {
  switch (typeId) {
    case CredentialType.TEMPORARY:
      return Type.TEMPORARY;
    case CredentialType.PERMANENT:
      return Type.PERMANENT;
    case CredentialType.CRISIS:
      return Type.CRISIS;
    case CredentialType.PREP_ACT:
      return Type.PREP_ACT;
    case CredentialType.AHA:
      return Type.AHA;
    case CredentialType.CHSF:
      return Type.CHSF;
    case CredentialType.AHSI:
      return Type.AHSI;
    case CredentialType.HOSPITAL:
      return Type.HOSPITAL;
    case CredentialType.OTHER:
      return Type.OTHER;
    case CredentialType.MILITARY:
      return Type.MILITARY;
  }
};

export const getType = id => {
  const otherCertificationTypes = Lookups.find(
    item => item.subCategory == 'Other Certifications' && item.controlType == 'Drop Down List',
  )!.lookups;
  const certType = otherCertificationTypes.find(item => item.value == id)?.description;
  if (certType) {
    return certType;
  } else {
    return 'Certificate';
  }
};

export const groupBy = items => {
  return items.reduce(function (r, a) {
    r[a.workHistoryId] = r[a.workHistoryId] || [];
    r[a.workHistoryId].push(a);
    return r;
  }, Object.create(null));
};

export const placementStatuses = treeData.filter(
  item => item.name === 'In Submission' || item.name === 'Offer' || item.name === 'No Active Placements',
);

export const allowedStatuses: any[] =
  placementStatuses?.flatMap(status => status?.children?.map(child => child?.name)) || [];

export const itemCounter = info => {
  let count = 0;
  if (info.dateOfBirth !== null) {
    count++;
  }
  if (info.socialSecurityNumber !== '') {
    count++;
  }
  if (info.gender !== '') {
    count++;
  }

  if (info.phone !== '') {
    count++;
  }
  if (info.email !== '') {
    count++;
  }
  if (info.emergencyContact !== '') {
    count++;
  }
  if (info.address !== '') {
    count++;
  }
  return count;
};

export const updateDependency = ({
  candidateDetails,
  setLicenses,
  setCertifications,
  setFluStatusData,
  setCovidStatusData,
}) => {
  const ids: { reqTypeId: number; stateId: number; licenseNumber: string }[] = [];
  let distinctIds: { reqTypeId: number; stateId: number; licenseNumber: string }[] = [];
  let distinctCredentials: any = [];
  const licenseData = _.cloneDeep(candidateDetails?.credentials?.licenses);
  licenseData
    ?.sort((a, b) => new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime())
    .map(item => {
      const temp = { reqTypeId: item.requirementTypeID, stateId: item.stateID, licenseNumber: item.licenseNumber };
      ids.push(temp);
    });
  distinctIds = _.uniqWith(ids, _.isEqual);

  distinctIds?.map(item => {
    let latest;
    let position;
    licenseData.map((cred, index) => {
      if (
        item.reqTypeId == cred.requirementTypeID &&
        item.stateId == cred.stateID &&
        item.licenseNumber == cred.licenseNumber
      ) {
        var d1 = moment(new Date());
        var d2 = moment(cred.dateCompleted ? cred.dateCompleted : cred.lastUpdate);
        if (d1.diff(d2, 'days') < latest || latest == undefined) {
          latest = d1.diff(d2, 'days');
          position = index;
        }
      }
      if (index == licenseData.length - 1) {
        distinctCredentials.push(licenseData[position]);
      }
      return null;
    });
    return null;
  });
  setLicenses(distinctCredentials);

  distinctCredentials = [];
  const certIds: { reqTypeId: number }[] = [];
  let distinctCertIds: { reqTypeId: number }[] = [];
  const certData = _.cloneDeep(candidateDetails?.credentials?.certifications);
  certData
    ?.sort((a, b) => new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime())
    .map(item => {
      const temp = { reqTypeId: item.requirementTypeID };
      certIds.push(temp);
    });
  distinctCertIds = _.uniqWith(certIds, _.isEqual);

  distinctCertIds?.map(item => {
    let latest;
    let position;
    certData.map((cred, index) => {
      if (item.reqTypeId == cred.requirementTypeID) {
        var d1 = moment(new Date());
        var d2 = moment(cred.dateCompleted ? cred.dateCompleted : cred.lastUpdate);
        if (d1.diff(d2, 'days') < latest || latest == undefined) {
          latest = d1.diff(d2, 'days');
          position = index;
        }
      }
      if (index == certData.length - 1) {
        distinctCredentials.push(certData[position]);
      }
      return null;
    });
    return null;
  });
  setCertifications(distinctCredentials);

  if (!candidateDetails.credentials) {
    setFluStatusData([]);
    setCovidStatusData([]);
  } else {
    const fluData = candidateDetails.credentials.employeeHealth?.filter(
      e => e.requirementTypeID === RequirementType.FLUVACCINATION,
    );
    const covidData = candidateDetails.credentials.employeeHealth?.filter(
      e => e.requirementTypeID === RequirementType.COVIDVACCINATION,
    );
    setFluStatusData(fluData);
    setCovidStatusData(covidData);
  }
};

export const getExpirationDateText = (
  expDate: string,
  isMatchingSkillSet: boolean | undefined,
  getExpiryDate = false,
) => {
  if (isMatchingSkillSet && expDate) {
    const date = new Date(expDate);
    date.setDate(date.getDate() + 1095);
    const current = new Date();
    current.setDate(current.getDate() + 90);
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (getExpiryDate) {
      return moment(date).format('MM/DD/YYYY');
    }
    if (date.getTime() > current.getTime()) {
      return ISkillSetExpiration.NOT_EXPIRED;
    } else if (date.getTime() >= yesterday.getTime() && date.getTime() <= current.getTime()) {
      return ISkillSetExpiration.EXPIRING_SOON;
    } else if (date.getTime() < yesterday.getTime()) {
      return ISkillSetExpiration?.EXPIRED;
    }
  } else if (!isMatchingSkillSet) {
    return `No Expiration Date`;
  }
};

export const setupSkillChecklistElements = (
  skillChecklist,
  selectedMatchingChecklist: ISkillCheckListDetails[] | any,
) => {
  skillChecklist?.filter(item => {
    if (item?.matchingSkillsChecklist?.length) {
      const tempMatchingSkillSet = item?.matchingSkillsChecklist.map(matchingSkillSetItem => ({
        dateCompleted: matchingSkillSetItem?.dateCompleted,
        description: matchingSkillSetItem?.description,
        expirationDate: matchingSkillSetItem?.expirationDate,
        requirementID: matchingSkillSetItem?.requirementID,
        requirementTypeID: matchingSkillSetItem?.requirementTypeID,
        discipline: item?.discipline,
        specialty: item?.specialty,
        verified: item?.verified,
        expirationType: getExpirationDateText(
          matchingSkillSetItem?.dateCompleted,
          item?.matchingSkillsChecklist?.length > 0,
        ),
        matchingSkillsChecklist: true,
        fullDescription: matchingSkillSetItem?.fullDescription,
      }));
      selectedMatchingChecklist.push(tempMatchingSkillSet);
    }
  });
};

export const getFullName = (item: ISkillCheckListDetails) => {
  if (item?.matchingSkillsChecklist || item?.requirementID === null) {
    return item?.fullDescription;
  } else {
    const name = `${item?.discipline} ${item?.specialty}`;
    return name;
  }
};

export const setExpiryDate = (expDate: string) => {
  const date = new Date(expDate);
  date.setDate(date.getDate() + 1095);
  return date;
};

export const mapAllSkillChecklistElements = (
  flattenedChecklist,
  emptySkillChecklist,
  unMatchedCheckList,
  combinedCheckList,
) => {
  // find unique skill checklist elements by requirementID
  const uniqueChecklist: any = uniqBy(flattenedChecklist, 'requirementID');
  if (uniqueChecklist?.length || emptySkillChecklist?.length) {
    combinedCheckList = [].concat(uniqueChecklist, emptySkillChecklist, unMatchedCheckList);
    if (combinedCheckList?.length) {
      combinedCheckList = combinedCheckList.map((item: ISkillCheckListDetails) => ({
        ...item,
        fullName: getFullName(item),
        expiryStatus:
          item.expirationType === ISkillSetExpiration.NOT_EXPIRED
            ? ISkillSetExpiryStatus.NOT_EXPIRED
            : ISkillSetExpiryStatus.EXPIRED,
        formattedDate: getExpirationDateText(item?.dateCompleted, item?.matchingSkillsChecklist, true),
        dateExpiry: setExpiryDate(item?.dateCompleted),
      }));
      // final combined skill checklist order by expiryStatus
      combinedCheckList = orderBy(combinedCheckList, ['expiryStatus', 'dateExpiry'], ['desc', 'desc']);
    }
  }
  return combinedCheckList;
};

export const getVerifiedNonVerifiedSkillSets = (
  currentRequirements: ISkillCheckListDetails[],
  verifiedValue: number,
) => {
  const verifiedSkillSet = currentRequirements
    ?.filter(item => item.verified === verifiedValue)
    .map(item => `${item.discipline} - ${item.specialty}`);
  return verifiedSkillSet.toString();
};

export const checkAccessPermission = user => {
  return Authorized(
    [userRoles.recruitment, userRoles.recruitment_Leadership, userRoles.recruitment_TeamMember],
    user.userInfo,
  );
};

export const checkNoVirtualInterview = item => {
  const status = item.interverStatus !== 'Completed' || item.interverStatus === 'Rejected';
  return status;
};
