import React from 'react';
import { Grid, GridSize } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';

interface Props {
  gridSize?: boolean | GridSize | undefined;
  contentLabel: string;
  contentValue: string;
  id?: string;
}

const useStyles = makeStyles()(() => ({
  label: {
    fontWeight: 500,
  },
}));

export const InnerLabelValueSection = (props: Props) => {
  const { classes } = useStyles();
  const { gridSize, contentLabel, contentValue } = props;
  return (
    <Grid item xs={gridSize}>
      <Grid container direction="column" spacing={1}>
        <Grid item className={classes.label}>
          {contentLabel}
        </Grid>
        <Grid item id={props.id}>
          {contentValue}
        </Grid>
      </Grid>
    </Grid>
  );
};
