import { ISubmissionPreferences, OrderPreferences } from 'app/models/Orders/OrderPreference';

export const initialSubmissionPrefState: ISubmissionPreferences = {
  submissionLimits: { activePlacementCounts: null },
  existingRFOs: [],
  readyToSubmitPlacementIds: [],
  distributionList: [],
  wfcToRfo: false,
  candidateTasks: false,
  rfoToSent: false,
  sendPacket: false,
  savePacket: false,
  submissionEmailSetup: null,
  submissionPacketSetup: null,
  isStrike: null,
  disableAutomation: true,
};

const initialAutomationStatus = {
  automation: false,
  editable: false,
  changedByUserId: 0,
  changedByUser: '',
  modifiedDate: null,
};

export const initialAutomation = {
  submission: initialAutomationStatus,
  interview: initialAutomationStatus,
  offer: initialAutomationStatus,
  booking: initialAutomationStatus,
  assignment: initialAutomationStatus,
  isAlwaysDisabled: false,
};

export const initialState: OrderPreferences = {
  submissionPreferences: initialSubmissionPrefState,
  offerPreferences: undefined,
  interviewPreferences: undefined,
  bookingPreferences: undefined,
  assignmentPreferences: undefined,
  alert: null,
  automationStatusInProgress: null,
  updateInprogress: false,
  clientContacts: [],
  miscellaneous: {
    disableEmailContainer: true,
    disablePacketContainer: true,
  },
};
