import React, { useEffect } from 'react';
import { Select, MenuItem } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

interface Props {
  defaultInputValue: string | number;
  defaultInputList: any[];
  formattingFunc?: (item: any) => string;
  inputName?: string;
  onChange?: (newValue: any) => void;
  dropDownStyle?: string;
  value?: any;
  iconStyle?: string;
  inputPaddingLeft?: number | string;
  inputPaddingTop?: number | string;
  marginLeft?: number | string;
  minWidth?: number | string;
  bodyMarginTop?: number | string;
  disabled?: boolean;
  selectMenuStyle?: any;
  req?: boolean;
  id?: string;
  size?: 'small' | 'medium';
}

const useStyles = makeStyles<{
  bodyMarginTop: string | number;
  inputPaddingLeft: string | number;
  inputPaddingTop: string | number;
  minWidth: string | number;
  marginLeft: string | number;
}>()((theme, props) => ({
  selectField: {
    marginTop: props.bodyMarginTop && props.bodyMarginTop,
    height: 'auto',
    backgroundColor: '#F7F7F7',
  },
  dropdownStyle: {
    border: '1px solid #D1D1D1',
    boxShadow: 'none',
    borderRadius: 3,
    marginTop: '0.5em',
    overflowY: 'auto',
    minHeight: '5%',
    maxHeight: '20%',
    minWidth: props.minWidth && props.minWidth,
    width: 'fit-content',
    marginLeft: props.marginLeft ? props.marginLeft : 0,
  },
  menu: {
    color: '#333333',
    fontSize: 12,
    '&:hover, &:focus': {
      backgroundColor: '#F7F7F7',
    },
  },
  disabled: {
    cursor: 'not-allowed !important',
  },
  textareastyle: {
    backgroundColor: '#fff',
    border: 'thin #e7e2e2 solid',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
    },
  },
  reqTextareastyle: {
    backgroundColor: '#fff',
    border: 'thin #02b9ff solid',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
    },
  },
}));

export const SimpleDropdown = (props: Props) => {
  const { classes } = useStyles({
    marginLeft: props.marginLeft,
    inputPaddingLeft: props.inputPaddingLeft,
    inputPaddingTop: props.inputPaddingTop,
    minWidth: props.minWidth,
    bodyMarginTop: props.bodyMarginTop,
  });
  const {
    defaultInputList,
    defaultInputValue,
    inputName,
    formattingFunc,
    dropDownStyle,
    value,
    iconStyle,
    disabled = false,
    id,
  } = props;
  useEffect(() => {
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDownStyle]);

  return (
    <Select
      id={id}
      className={disabled ? `${classes.disabled} ${classes.selectField}` : `${classes.selectField}`}
      defaultValue={defaultInputValue}
      classes={{ icon: `${iconStyle && iconStyle}`, selectMenu: props.selectMenuStyle }}
      name={inputName && inputName}
      value={value && value}
      onChange={props.onChange}
      size={props.size}
      disabled={disabled}
      inputProps={{
        className: disabled ? '' : `${props.req ? classes.reqTextareastyle : classes.textareastyle}`,
      }}
      MenuProps={{
        classes: { paper: classes.dropdownStyle },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      IconComponent={ExpandMoreOutlinedIcon}
    >
      {defaultInputList.map(item => {
        return (
          <MenuItem key={item} value={item} className={classes.menu}>
            {formattingFunc ? formattingFunc(item) : item}
          </MenuItem>
        );
      })}
    </Select>
  );
};
