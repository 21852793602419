import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PlacementRemindersTotalSection } from './PlacementRemindersTotalSection';
import { selectCounts } from '../Store/PlacementReminders.selector';
import { pipelineChoiceKeys } from 'app/components/Common/WorkDesk/PipelineChoice/pipelineChoice.redux';
import { useParams } from 'react-router-dom';
import { ReminderWorkDeskPipeline } from './ReminderWorkDeskPipeline';

interface RouteParams {
  status?: string;
}

type StatusType = 'today' | 'scheduled' | 'all';

export const PlacementReminderPipeline = props => {
  const { t } = useTranslation();
  const [reminderTotals, setReminderTotals] = useState<any>([]);
  const [statusId, setStatusId] = useState<StatusType>('all');
  const { status } = useParams<RouteParams>();
  const counts = useSelector(selectCounts);

  useEffect(() => {
    if (status && window.location.pathname.endsWith(status)) setStatusId(status as StatusType);
    else setStatusId('all');
  }, [status]);

  useEffect(() => {
    setReminderTotals([
      {
        data: {
          title: t('home.credentialingDashboard.placementReminders.todayTitle'),
          id: 'today',
          count: counts?.today || 0,
        },
        backgroundClass: 'style-7',
      },
      {
        data: {
          title: t('home.credentialingDashboard.placementReminders.scheduledTitle'),
          id: 'scheduled',
          count: counts?.scheduled || 0,
        },
        backgroundClass: 'style-1',
      },
      {
        data: {
          title: t('home.credentialingDashboard.placementReminders.allTitle'),
          id: 'all',
          count: counts?.all || 0,
        },
        backgroundClass: 'style-2',
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counts]);

  return (
    <ReminderWorkDeskPipeline
      data={reminderTotals}
      selection={statusId}
      gridSelectionKey={pipelineChoiceKeys.CRED_PLACEMENT_STATUS_WDG}
      noValuePipelineText={'noValuePipelineText'}
      pipelineTotal={<PlacementRemindersTotalSection />}
      shrink={props.stickChosen !== null}
      isCredentialing={true}
    />
  );
};
