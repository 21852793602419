import { XGrid } from '@AMIEWEB/Common';
import { GridSelectionModel } from '@mui/x-data-grid-pro';
import { Grid } from 'amn-ui-core';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { gridTags } from 'app/models/AutoTaskManagement/AutoTaskManagement';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAutoTaskGridData,
  selectIsAutoTaskDetailsModified,
  selectSelectedRowId,
} from 'store/redux-store/placement-requests/selectors';
import { placementRequestsAction } from 'store/redux-store/placement-requests/slice';
import useDocumentTitle from 'utils/customHooks/useDocumentTitle';
import { ConfirmationDialog } from '../../common/ConfirmationDialog';
import { IDialogModal } from '../../common/PageActions';
import { getAutoTasksGridColumns } from './utils';

export const AutoTasksGrid = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const gridData = useSelector(selectAutoTaskGridData);
  const selectedRowId = useSelector(selectSelectedRowId);
  const isAutoTaskDetailsModified = useSelector(selectIsAutoTaskDetailsModified);
  const [sortModel, setSortModel] = useState<any[]>([{ field: 'description', sort: 'asc' }]);
  const [columns, setColumns] = useState<any>(getAutoTasksGridColumns(t, selectedRowId));
  const [dialogModal, setDialogModal] = useState<IDialogModal>({ open: false, type: null });
  const { promiseInProgress: isLoading } = usePromiseTracker({ area: 'fetch-auto-task-grid-data', delay: 0 });
  const { promiseInProgress: autoTaskSaveInProgress } = usePromiseTracker({
    area: 'update-auto-task-management',
    delay: 0,
  });
  const { promiseInProgress: autoTaskEmployeeRolesLoader } = usePromiseTracker({ area: 'fetch-auto-task-employee-roles', delay: 0 });

  useDocumentTitle({ title: `Auto Tasks`, revertToPreviousTitle: true });
  useEffect(() => {
    setColumns(getAutoTasksGridColumns(t, selectedRowId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowId]);
  useEffect(() => {
    if (!autoTaskSaveInProgress) setDialogModal({ open: false });
  }, [autoTaskSaveInProgress]);

  const onRowSelect = id => {
    if (id) {
      if (!isAutoTaskDetailsModified) {
        dispatch(placementRequestsAction.business_setSelectedTaskIdAndRow(id));
      } else {
        setDialogModal({ open: true, selectedRowId: id });
      }
    }
  };
  const handleKeepChanges = () => {
    setDialogModal({ open: false });
  };
  const handleDiscard = () => {
    dispatch(placementRequestsAction.business_setSelectedTaskIdAndRow(dialogModal.selectedRowId));
    setDialogModal({ open: false });
  };

  const handleSave = () => {
    dispatch(
      placementRequestsAction.business_handleSaveOnPageChange({
        selectedId: dialogModal.selectedRowId,
        pageChange: false,
      }),
    );
  };

  const sortChangeHandler = model => {
    setSortModel(model);
  };

  const resetColumns = () => {
    setColumns(getAutoTasksGridColumns(t, selectedRowId));
  };
  const saveDialogActions = [
    {
      text: t(`autoTaskManagement.button.cancel`),
      onClick: handleKeepChanges,
      disabled: autoTaskSaveInProgress,
    },
    {
      text: t(`autoTaskManagement.button.discard`),
      color: 'tertiary',
      variant: 'contained',
      onClick: handleDiscard,
      disabled: autoTaskSaveInProgress,
    },
    {
      text: t(`autoTaskManagement.button.save`),
      variant: 'contained',
      color: 'primary',
      onClick: handleSave,
      loading: autoTaskSaveInProgress,
      disabled: autoTaskSaveInProgress,
    },
  ];

  return (
    <Grid>
      <XGrid
        gridTag={gridTags.AUTOTASKS}
        rows={gridData || []}
        columns={columns}
        pagination={false}
        autoHeight={false}
        height={window.innerHeight - 210}
        onSelectionModelChange={(model: GridSelectionModel) => {
          onRowSelect(parseInt(model[0]?.toString()));
        }}
        sortModel={sortModel}
        onSortModelChange={sortChangeHandler}
        disableMultipleSelection={true}
        hideFooter
        getRowId={row => row.autoTaskCategoryId}
        inDialog
        title={t('autoTaskManagement.grid.title')}
        selectionModel={[]}
        loading={isLoading || autoTaskEmployeeRolesLoader}
        toolbarProps={{
          refreshButton: true,
          columnSelector: true,
          columnSelectorProps: {
            fallbackPlacements: [
              'bottom-end',
              'bottom',
              'bottom-start',
              'top-start',
              'top',
              'top-end',
              'left',
              'right',
            ],
          },
          resetColumns: () => {
            resetColumns();
            dispatch(
              globalActions.setSnackBar({
                message: t('autoTaskManagement.snackBar.columnsReset'),
                severity: 'success',
              }),
            );
          },
          refreshGrid: () => {
            dispatch(placementRequestsAction.business_handleRefreshGrid({ softReset: false }));
            setSortModel([{ field: 'description', sort: 'asc' }]);
          },
        }}
      />
      <ConfirmationDialog
        dialogeOpen={dialogModal.open}
        dialogActions={saveDialogActions}
        dialogTitle={t(`autoTaskManagement.dialogModal.saveChanges`)}
        dialogeText={t(`autoTaskManagement.dialogModal.saveAndExit`)}
      />
    </Grid>
  );
};
