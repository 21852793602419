import { ICredentials } from '../../../../../../../../app/models/Credentialing/Credentials';

import { groupBy, nestedChildrenSorting } from '../../../../../../../../app/helpers/arrayHelpers';

export const groupedHumanResources = (list: any[], subCateg: string) => {
  let finalData: any[] = [];
  switch (subCateg) {
    case 'Background Checks':
      const data1 = groupBy<keyof ICredentials>(
        list.filter(item => item.requirementTypeID === 211),
        ['typeID'],
        [
          { item: 'expirationDate', type: 'shortDate' },
          { item: 'dateCompleted', type: 'shortDate' },
          { item: 'issuedDate', type: 'shortDate' },
          { item: 'lastUpdate', type: 'longDate' },
        ],
      );

      const data2 = groupBy<keyof ICredentials>(
        list.filter(item => item.requirementTypeID !== 211),
        ['credentialName'],
        [
          { item: 'expirationDate', type: 'shortDate' },
          { item: 'dateCompleted', type: 'shortDate' },
          { item: 'issuedDate', type: 'shortDate' },
          { item: 'lastUpdate', type: 'longDate' },
        ],
      );
      finalData = { ...data1, ...data2 };

      break;

    case 'Drug Screens':
      const drugfirstData = groupBy<keyof ICredentials>(
        list.filter(item => item.requirementTypeID === 25),
        ['panelTypeID'],
        [
          { item: 'expirationDate', type: 'shortDate' },
          { item: 'dateCompleted', type: 'shortDate' },
          { item: 'issuedDate', type: 'shortDate' },
          { item: 'lastUpdate', type: 'longDate' },
        ],
      );

      const drugsecondData = groupBy<keyof ICredentials>(
        list.filter(item => item.requirementTypeID !== 25),
        ['credentialName'],
        [
          { item: 'expirationDate', type: 'shortDate' },
          { item: 'dateCompleted', type: 'shortDate' },
          { item: 'issuedDate', type: 'shortDate' },
          { item: 'lastUpdate', type: 'longDate' },
        ],
      );
      finalData = { ...drugfirstData, ...drugsecondData };

      break;

    case 'Identifications':
      const firstData = groupBy<keyof ICredentials>(
        list.filter(item => item.requirementTypeID === 48),
        ['stateID'],
        [
          { item: 'expirationDate', type: 'shortDate' },
          { item: 'dateCompleted', type: 'shortDate' },
          { item: 'issuedDate', type: 'shortDate' },
          { item: 'lastUpdate', type: 'longDate' },
        ],
      );

      const secondData = groupBy<keyof ICredentials>(
        list.filter(item => item.requirementTypeID !== 48),
        ['credentialName'],
        [
          { item: 'expirationDate', type: 'shortDate' },
          { item: 'dateCompleted', type: 'shortDate' },
          { item: 'issuedDate', type: 'shortDate' },
          { item: 'lastUpdate', type: 'longDate' },
        ],
      );
      finalData = { ...firstData, ...secondData };
      break;

    case 'Forms':
      const formfirstData = groupBy<keyof ICredentials>(
        list.filter(item => item.requirementTypeID === 117),
        ['applicationCompanyID'],
        [
          { item: 'expirationDate', type: 'shortDate' },
          { item: 'dateCompleted', type: 'shortDate' },
          { item: 'issuedDate', type: 'shortDate' },
          { item: 'lastUpdate', type: 'longDate' },
        ],
      );

      const formsecondData = groupBy<keyof ICredentials>(
        list.filter(item => item.requirementTypeID !== 117),
        ['credentialName'],
        [
          { item: 'expirationDate', type: 'shortDate' },
          { item: 'dateCompleted', type: 'shortDate' },
          { item: 'issuedDate', type: 'shortDate' },
          { item: 'lastUpdate', type: 'longDate' },
        ],
      );
      finalData = { ...formfirstData, ...formsecondData };
      break;

    case 'ClinicalQualifications':
      const ClinicalQualificationsfirstData = groupBy<keyof ICredentials>(
        list.filter(item => item.requirementTypeID === 81 || item.requirementTypeID === 215),
        ['credentialName'],
        [
          { item: 'dateCompleted', type: 'shortDate' },
          { item: 'lastUpdate', type: 'longDate' },
        ],
      );

      const ClinicalQualificationssecondData = groupBy<keyof ICredentials>(
        list.filter(item => item.requirementTypeID !== 81 && item.requirementTypeID !== 215),
        ['credentialName'],
        [
          { item: 'expirationDate', type: 'shortDate' },
          { item: 'dateCompleted', type: 'shortDate' },
          { item: 'issuedDate', type: 'shortDate' },
          { item: 'lastUpdate', type: 'longDate' },
        ],
      );
      finalData = { ...ClinicalQualificationsfirstData, ...ClinicalQualificationssecondData };

      break;
  }

  return finalData;
};

export const childSorting = (list: any[], lookups?: any[]) => {
  let finalData: any[] = [];
  if (list.length && lookups) {
    if (
      lookups
        .filter(p => p.requirementTypeID === list[0].requirementTypeID)
        .filter(l => l.lookUpField === 'ExpirationDate').length
    ) {
      finalData = nestedChildrenSorting<keyof ICredentials>(list, [
        { item: 'expirationDate', type: 'shortDate' },
        { item: 'lastUpdate', type: 'longDate' },
      ]);
    } else {
      finalData = nestedChildrenSorting<keyof ICredentials>(list, [{ item: 'lastUpdate', type: 'longDate' }]);
    }
  }
  return finalData;
};
