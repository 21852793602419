import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { StatusDropDownValues, StatusDropdown } from '../StatusDropDown/StatusDropDown';
import { Avatar, Tabs, Typography } from 'amn-ui-core';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { selectClientContractDrawer } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.selector';
import { useTranslation } from 'react-i18next';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { selectUser } from 'oidc/user.selectors';
import { FacilityContactsActions } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.redux';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const useStyles = makeStyles()(theme => ({
  tabRoot: {
    [theme.breakpoints.up(380)]: {
      maxWidth: '115px !important',
    },
    color: theme.palette.system.neutralGray,
    fontSize: '16px !important',
  },
  tabSelected: {
    color: `${theme.palette.system.violet} !important`,
  },
  avatarProfile: {
    height: 72,
    width: 72,
    marginRight: 10,
    fontSize: 27,
    backgroundColor: theme.palette.system.neutralGray,
    fontWeight: 500,
    textTransform: 'uppercase',
    position: 'inherit',
  },

  indicator: {
    background: `${theme.palette.system.violet} !important`,
  },
}));

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && (
        <Box sx={theme => ({ p: 3, backgroundColor: theme.palette.framework.system.whisper, height: '100vh' })}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const TabsSection = ({ tabs }: { tabs }) => {
  const { classes } = useStyles();
  const [tabSelectedValue, setValue] = useState(0);
  const [selectedTabDetail, setSelectedTabDetail] = useState(tabs[0]?.component);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    const selectedTabComponent = tabs.find(tab => tab.id === newValue);
    setSelectedTabDetail(selectedTabComponent);
  };

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabSelectedValue}
          classes={{ indicator: `${'tab-indicator profileTab'} ${classes.indicator}` }}
          onChange={handleChange}
          variant="fullWidth"
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              className={tabSelectedValue === 0 ? 'active-tab-profile' : 'default-tab profileTab'}
              label={tab.name}
              classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            />
          ))}
        </Tabs>
      </Box>
      <CustomTabPanel value={tabSelectedValue} index={0}>
        {selectedTabDetail}
      </CustomTabPanel>
    </Box>
  );
};

const UserInfoPanel = () => {
  const dispatch = useDispatch();
  const userData = useSelector(selectUser);
  const [status, setStatus] = useState(StatusDropDownValues.ACTIVE);
  const isAuthorized = Authorized(
    [userRoles.accountManagement, userRoles.accountManagement_Leadership, userRoles.accountManagement_TeamMember],
    userData.userInfo,
  );
  const { classes } = useStyles();
  const clientContractDrawer = useSelector(selectClientContractDrawer);
  //Handles the status action from StatusDropdown
  const handleStatus = value => {
    setStatus(value);
    dispatch(
      FacilityContactsActions.updateFacilityUnitNotesStatus({
        status: StatusDropDownValues.INACTIVE,
      }),
    );
  };
  return (
    <LayoutGridItem container p="12px" columnGap={2}>
      <LayoutGridItem xs={2}>
        <Avatar className={classes.avatarProfile} id="side-panel-avatar">
          {clientContractDrawer.nameAbbreviation}
        </Avatar>
      </LayoutGridItem>
      <LayoutGridItem xs={6}>
        <Typography component="div" color="system.navyBlue" sx={{ fontSize: '22px !important', fontWeight: 'medium' }}>
          {clientContractDrawer.name}
        </Typography>
        <Typography component="div" variant="body1">
          {clientContractDrawer.role}
        </Typography>
      </LayoutGridItem>
      <LayoutGridItem xs={3} sx={{ display: 'flex' }}>
        <StatusDropdown
          handleStatus={handleStatus}
          isDisabled={status === StatusDropDownValues.INACTIVE || !isAuthorized}
          isReadOnly={status === StatusDropDownValues.INACTIVE || !isAuthorized}
        />
      </LayoutGridItem>
    </LayoutGridItem>
  );
};

const HeaderNavigationPanel = ({ handleClose }: { handleClose: () => void }) => {
  const { t } = useTranslation();

  return (
    <>
      <LayoutGridItem
        container
        sx={{ display: 'flex', alignItems: 'center', height: '47px', pl: '12px', cursor: 'pointer' }}
      >
        <LayoutGridItem item xs={9}>
          <Typography variant="body1" color="system.black" fontWeight="medium">
            {t('facility.clientContact')}
          </Typography>
        </LayoutGridItem>
        <LayoutGridItem item xs={3} sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <KeyboardArrowUpIcon sx={{ color: 'system.neutralGray' }} />
          <KeyboardArrowDownIcon sx={{ color: 'system.neutralGray' }} />
          <CloseIcon sx={{ color: 'system.neutralGray' }} onClick={() => handleClose()} />
        </LayoutGridItem>
      </LayoutGridItem>
    </>
  );
};

export { HeaderNavigationPanel, UserInfoPanel, TabsSection };
