import { ISendToAttentionTo } from '@AMIEWEB/Order/OrderDetails/OrderPreferences/SubmissionPreferences/SubmissionHelper';
import { Tabs } from './SendToButtonGroup';

export enum FormNames {
  SendTo = 'sendTo',
  AttentionTo = 'attentionTo',
  EmailSettings = 'emailSettings',
  EmailTemplate = 'emailTemplate',
  PacketTemplates = 'packetTemplate',
  NoteToFacility = 'noteToFacility',
}

export enum TemplateUserTypes {
    Facility = 1,
}

export const deriveAttentionToOpts = (clientContacts = [], attentionToSelections = [], disablePacketContainer = true) =>
  (clientContacts || []).map(contact => {
    const selectedContact = (attentionToSelections || []).find(selected => selected?.contactId === contact?.contactId);
    return {
      contactId: contact.contactId,
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      title: contact.title,
      isChecked: disablePacketContainer ? false : selectedContact?.isChecked,
      tabSelected: selectedContact?.tabSelected ?? Tabs.To,
    };
  });

export const deriveSendToOpts = (clientContacts = [], sendToSelections = [], disableEmailContainer = true) =>
  (clientContacts || []).map(contact => {
    const selectedContact = (sendToSelections || []).find(selected => selected?.contactId === contact?.contactId);
    return {
      contactId: contact.contactId,
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      title: contact.title,
      isChecked: disableEmailContainer ? false : sendToSelections.length > 0 ? selectedContact?.isChecked : true,
      tabSelected: selectedContact?.tabSelected ?? Tabs.To,
    };
  });

export const isSendToExisted = (data: ISendToAttentionTo[]) => {
  const sendTo = data.length > 0 ? data.filter(d => d.isChecked) : [];
  return sendTo.length > 0;
};

export interface IEmptyToken {
  name: string;
  input: string;
}

export const getEmptySubstitutions = () => {
  const substitutions: IEmptyToken[] = [];

  substitutions.push({
    name: 'Signature',
    input: '{{signature}}',
  });

  substitutions.push({
    name: 'Candidate First Name',
    input: '{{candidate_first_name}}',
  });

  substitutions.push({
    name: 'Candidate Last Name',
    input: '{{candidate_last_name}}',
  });

  substitutions.push({
    name: 'Candidate Goes By',
    input: '{{candidate_goes_by}}',
  });

  substitutions.push({
    name: 'Candidate Phone Number',
    input: '{{candidate_phone_number}}',
  });

  substitutions.push({
    name: 'Candidate Email',
    input: '{{candidate_email_address}}',
  });

  substitutions.push({
    name: 'Placement Selling Notes',
    input: '{{selling_notes}}',
  });

  substitutions.push({
    name: 'Requested Time Off',
    input: '{{time_off}}',
  });

  substitutions.push({
    name: 'Active Licenses',
    input: '{{active_licences}}',
  });

  substitutions.push({
    name: 'Active Certifications',
    input: '{{active_certifications}}',
  });
  substitutions.push({
    name: 'Candidate Discipline',
    input: '{{candidate_discipline}}',
  });

  substitutions.push({
    name: 'Candidate Discipline Full Text',
    input: '{{candidate_discipline_full_text}}',
  });

  substitutions.push({
    name: 'Candidate Speciality',
    input: '{{candidate_speciality}}',
  });

  substitutions.push({
    name: 'Candidate Speciality Full Text',
    input: '{{candidate_speciality_full_text}}',
  });

  substitutions.push({
    name: 'Order Skill Set',
    input: '{{order_skillsets}}',
  });

  substitutions.push({
    name: 'Available Start Date',
    input: '{{available_start_date}}',
  });

  substitutions.push({
    name: 'Facility Unit',
    input: '{{facility_unit}}',
  });

  substitutions.push({
    name: 'COVID Vaccine Status',
    input: '{{candidate_covid_vaccine_status}}',
  });

  substitutions.push({
    name: 'Flu Vaccine Status',
    input: '{{candidate_flu_vaccine_status}}',
  });

  substitutions.push({
    name: 'Account Manager First Name',
    input: '{{account_manager_first_name}}',
  });

  substitutions.push({
    name: 'Account Manager Last Name',
    input: '{{account_manager_last_name}}',
  });

  substitutions.push({
    name: 'Account Manager Email Address',
    input: '{{account_manager_email_address}}',
  });

  substitutions.push({
    name: 'Account Manager Phone Number',
    input: '{{account_manager_phone_number}}',
  });

  substitutions.push({
    name: 'Total Years of Experience',
    input: '{{total_years_of_experience}}',
  });

  substitutions.push({
    name: 'Current AMN Traveler',
    input: '{{current_amn_traveler}}',
  });

  substitutions.push({
    name: 'VMS Req Number',
    input: '{{vms_request_number}}',
  });

  substitutions.push({
    name: 'Order Shift',
    input: '{{order_shift}}',
  });

  substitutions.push({
    name: 'Duration of Assignment',
    input: '{{duration_of_assignment}}',
  });

  substitutions.push({
    name: 'GWW (Hours)',
    input: '{{order_gww}}',
  });

  substitutions.push({
    name: 'EMR',
    input: '{{candidate_emr}}',
  });

  substitutions.push({
    name: 'Placement Start Date',
    input: '{{placement_start_date}}',
  });

  substitutions.push({
    name: 'Placement End Date',
    input: '{{placement_end_date}}',
  });

  substitutions.push({
    name: 'Candidate Id',
    input: '{{candidate_id}}',
  });

  substitutions.push({
    name: 'Placement Id',
    input: '{{placement_id}}',
  });

  substitutions.push({
    name: 'License Number',
    input: '{{license_number}}',
  });

  return substitutions;
};

export const checkSendToError = (data, errors) => {
  return data?.rfoToSent && data?.sendPacket?.saveAndSend && errors?.hasOwnProperty('sendTo');
}
