import React from 'react';
import { Theme, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: '0px 10px 0px 5px',
  },
  title: {
    color: theme.palette.components.typography.color,
    textAlign: 'left',
    font: 'normal normal bold 20px/26px Roboto',
    paddingLeft: 10,
    marginBottom: 0,
  },
}));

export const ContractDetailSection = ({ content, title, id }: { content: any; title: string; id: string }) => {
  const { classes } = useStyles();

  return (
    <LayoutGrid container direction="column" className={classes.root}>
      <Typography   className={classes.title} variant="subtitle1" gutterBottom component="div">
        {title}
      </Typography>

      <LayoutGridItem
        sx={{ paddingTop: '6px !important', paddingBottom: '12px !important', display: 'flex' }}
        item
        data-testId={id}
      >
        {content}
      </LayoutGridItem>
    </LayoutGrid>
  );
};
