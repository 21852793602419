import React, { useState, useEffect } from 'react';
import { Grid, IconButton, Chip, Theme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { ControlledTypeAhead, ITypeAheadOption } from './CustomComponents/ControlledTypeAhead';
import { useTranslation } from 'react-i18next';
import { Title } from './styles';
import { Controller, useFormContext } from 'react-hook-form';
import { Concatenate } from 'utils/string/string';
import { useDispatch, useSelector } from 'react-redux';
import { selectMiscellaneousData, selectSkillSetsChosen } from './PreferenceForm/Preference.selectors';
import { preferenceActions } from './PreferenceForm/Preference.redux';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  addButtonRoot: {
    color: '#006FB9',
    background: 'none !important',
    padding: '0 !important',
  },
  titleGridRoot: {
    paddingBottom: theme.spacing(2),
  },
  buttonGridRoot: {
    padding: '0 !important',
    margin: '0 0 1.5% 0 !important',
  },
  chip: {
    margin: '0 16px 16px 0',
  },
}));

export const SkillSet = props => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { control, register, watch, errors, setValue, setError, clearErrors } = useFormContext();

  //for filtering needs based on candidateSelection
  const [filteredDiscplines, setFilteredDiscplines] = useState<ITypeAheadOption[]>([]);
  const [specHelperText, setSpecHelperText] = useState<string>(' ');
  const { disciplineOpts, specialtyOpts } = useSelector(selectMiscellaneousData);

  //data source of user's choice
  const chosenSkillSet = useSelector(selectSkillSetsChosen);
  const [chips, setChips] = useState<JSX.Element[]>([]);

  const disciplinesInSpecialtyCountObject = specialtyOpts.reduce(
    (countObject, spec) => (
      // eslint-disable-next-line no-sequences
      (countObject[spec?.object?.DisciplineID] = (countObject[spec?.object?.DisciplineID] || 0) + 1), countObject
    ),
    {},
  );

  useEffect(() => {
    props.setFieldValue('skillSet', chosenSkillSet);
    const chipElements = chosenSkillSet.map((skill, index) => {
      const specialityString = skill?.specialty ? skill.specialty.label : '';
      const label = Concatenate([skill?.discipline?.label, specialityString], ', ');
      if (skill?.isReadOnly) return <Chip key={index} label={label} className={classes.chip} />;
      return (
        <Chip
          key={index}
          label={label}
          className={classes.chip}
          onDelete={() => {
            const data = chosenSkillSet.filter(entry => entry !== skill);
            dispatch(preferenceActions.setSkillSetsChosen(data));
            setValue('skillSet', data, { shouldDirty: true });
            props.onValueChange();
          }}
        />
      );
    });
    setChips(chipElements);
    filterDisciplines();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenSkillSet]);

  const discipline = watch('discipline') as ITypeAheadOption;
  const specialty = watch('specialty') as ITypeAheadOption | undefined;

  const AddSkill = () => {
    const isReadOnly = false;
    const isAlreadyChosen = chosenSkillSet.some(
      skill => discipline?.object?.ID === skill.discipline?.object?.ID && specialty === skill.specialty,
    );
    if (isAlreadyChosen) {
      setError('discipline', { message: t('candidate.preferenceForm.choiceExist') });
      specialty && setError('specialty', { message: t('candidate.preferenceForm.choiceExist') });
      return;
    }
    const data = [...chosenSkillSet, { discipline, specialty, isReadOnly }];
    dispatch(preferenceActions.setSkillSetsChosen(data));
    setValue('skillSet', data, { shouldDirty: true });
    setValue('discipline', null);
    setValue('specialty', null);
    props.onValueChange();
  };

  const validateIfSelected = () => {
    if (!discipline) {
      setError('specialty', { message: t('candidate.preferenceForm.chooseDisciplineText') });
      return;
    } else if (filterSpecialties().length === 0)
      setSpecHelperText(t('candidate.preferenceForm.specialtiesUnAvailbale'));
    clearErrors('specialty');
  };

  const clearSpecialtyAttributes = () => {
    clearErrors('specialty');
    setSpecHelperText(' ');
    if (!discipline) setValue('specialty', null);
  };

  const clearDiscplineAttributes = () => {
    if (!discipline) clearErrors('discipline');
  };

  const onSelectionChange = choice => {
    if (choice) {
      clearErrors('specialty');
      clearErrors('discipline');
    }
  };

  const filterDisciplines = () => {
    const disciplineCountObject = chosenSkillSet.reduce(
      (countObject, skill) => (
        // eslint-disable-next-line no-sequences
        (countObject[skill.discipline?.object?.ID] = (countObject[skill.discipline?.object?.ID] || 0) + 1), countObject
      ),
      {},
    );

    const countKeys = Object.keys(disciplineCountObject);
    const exhaustedDisciplineIds = countKeys.filter(
      key => disciplineCountObject[key] >= disciplinesInSpecialtyCountObject[key] + 1,
    );
    setFilteredDiscplines(
      disciplineOpts.filter(opt => !exhaustedDisciplineIds.some(id => id === opt?.object?.ID.toString())),
    );
  };

  const filterSpecialties = () =>
    specialtyOpts
      .filter(
        opt =>
          opt?.object?.DisciplineID === discipline?.object?.ID &&
          !chosenSkillSet.some(chosen => opt?.object?.SpecialtyID === chosen.specialty?.object?.SpecialtyID),
      )
      .sort((optA, optB) =>
        optA?.object?.Description > optB?.object?.Description
          ? 1
          : optB?.object?.Description > optA?.object?.Description
          ? -1
          : 0,
      );

  return (
    <Grid container classes={{ root: classes.root }} direction="column" data-testid="container">
      <Grid item classes={{ root: classes.titleGridRoot }}>
        <Title>{t('candidate.preferenceForm.skillsetTitle')}</Title>
        <Controller
          name="skillSet"
          control={control}
          inputRef={register}
          defaultValue={chosenSkillSet}
          render={({ ref, ...rest }) => <input type="hidden" {...rest} />}
        />
      </Grid>
      <Grid item container alignItems="center" spacing={2}>
        <Grid item style={{ width: 300 }}>
          <ControlledTypeAhead
            register={register}
            name="discipline"
            label={t('candidate.preferenceForm.disciplineLabel')}
            control={control}
            options={filteredDiscplines}
            onChange={discipline => {
              setValue('specialty', null);
              onSelectionChange(discipline);
            }}
            onBlur={() => clearDiscplineAttributes()}
            isError={errors.discipline ? true : false}
            helperText={errors.discipline ? errors.discipline.message : ' '}
          />
        </Grid>
        <Grid item style={{ width: 300 }}>
          <ControlledTypeAhead
            register={register}
            name="specialty"
            label={t('candidate.preferenceForm.specialtyLabel')}
            control={control}
            options={discipline ? filterSpecialties() : []}
            onChange={specialty => onSelectionChange(specialty)}
            onFocus={() => validateIfSelected()}
            onBlur={() => clearSpecialtyAttributes()}
            isError={errors.specialty ? true : false}
            helperText={errors.specialty ? errors.specialty.message : specHelperText}
          />
        </Grid>
        <Grid item classes={{ root: classes.buttonGridRoot }}>
          <IconButton
            color="primary"
            type="button"
            classes={{ root: classes.addButtonRoot }}
            disabled={discipline === null}
            disableTouchRipple
            disableFocusRipple
            onClick={() => AddSkill()}
            aria-label="skill-add-button"
            size="small">
            <AddBoxIcon style={{ fontSize: 60 }} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item>{chips}</Grid>
    </Grid>
  );
};
