import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';

import { initialState } from './JobInterest.redux';

const selectDomain = (state: RootState) => state?.jobInterest || initialState;

export const selectJobInterests = createSelector([selectDomain], jobInterests => jobInterests.data);
export const selectJobInterestsCount = createSelector([selectDomain], jobInterests => ({
  totalCount: jobInterests.count,
  filteredCount: jobInterests.filteredCount,
}));
export const selectJobInterestsGridData = createSelector([selectDomain], jobInterests => jobInterests.flattenedData);
export const selectInterestLocations = createSelector([selectDomain], jobInterests => jobInterests.jobLocations);
export const selectFilterSourceData = createSelector([selectDomain], jobInterests => jobInterests.filterSourceData);
export const selectInterestServiceFailure = createSelector([selectDomain], jobInterests => jobInterests.serviceFailure);
export const selectInterestDrawerData = createSelector([selectDomain], jobInterests => jobInterests.drawerData);
