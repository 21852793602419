/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  autocomplete: {
    transition: 'opacity 0.25s',
    '&:hover': {
      '& [data-id="edit-icon"]': {
        opacity: 1,
      },
      transition: 'opacity 0.25s',
    },
  },
  inlineEditMode: {
    cursor: 'default',
    '&.editEnabled': {
      'border-bottom': '1px solid #000',
    },
    '& .MuiAutocomplete-input': {
      cursor: 'default',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: '0px',
    },
    '& .MuiOutlinedInput-root': {
      height: '28px',
      width: '139px',
    },
    '& .MuiAutocomplete-input:first-child': {
      width: '70%',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#1a1919',
      backgroundColor: '#fff !important',
    },
  },
  editItem: {
    opacity: 0.75,
    transition: 'opacity 0.15s',
    color: '#8d8686',
    cursor: 'pointer',
    position: 'relative',
    top: '1px',
    '&.enabled': {
      opacity: 1,
    },
    '& svg ': {
      height: '17px',
      width: '17px',
    },
  },
  textareastyle: {
    padding: '0px',
    overflowWrap: 'break-word',
    backgroundColor: '#fff !important',
    '&:focus-visible': {
      outline: 'none',
      background: '#f2f2f2',
    },
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    },
  },
  root: {
    height: '20px',
    width: '110px',
    overflow: 'hidden',
    fontSize: 14,
    paddingRight: '0px !important',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      '&$popupIndicator': {
        opacity: 1,
        transition: 'opacity 0.25s',
      },
    },
    '&$focused': {
      backgroundColor: '#fff',
    },
  },
  textAreaRoot: {
    width: '100%',
    overflow: 'wrap',
    fontSize: 14,
    paddingRight: '0px !important',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      '&$popupIndicator': {
        opacity: 1,
        transition: 'opacity 0.25s',
      },
    },
    '&$focused': {
      backgroundColor: '#fff',
    },
  },
  normalText: {
    'font-weight': 'normal',
  },
  inlineBoxEditItemPadding: {
    padding: '0 0.4rem',
  },
  inlineBoxEditMainCheckItem: {
    'padding-left': '0',
    'padding-right': '0',
    '& svg': {
      width: '25px',
      height: '25px',
    },
  },
  inlineBoxEditMainLblItem: {
    'padding-right': '0.1rem',
  },
  inlineBoxEditMainAcceptItem: {
    'padding-right': '0.2rem',
    '& svg': {
      color: '#006FB9',
      width: '20px',
      height: '20px',
      cursor: 'pointer',
    },
  },
  inlineBoxEditMainRejectItem: {
    'padding-left': '0.2rem',
    '& svg': {
      color: '#8f9a8e',
      width: '20px',
      height: '20px !important',
      cursor: 'pointer',
    },
  },
  inlineBoxEditInputItem: {
    width: 220,
    'border-top': '0',
    'border-left': '0',
    'border-right': '0',
    'border-bottom-color': '#bababa',
    '&:focus-visible': {
      outline: 'none',
      background: '#f2f2f2',
    },
  },
  saveSuccessText: {
    fontSize: '11px',
    paddingLeft: '4px',
    justifyContent: 'center',
    color: '#008000',
  },
  saveFailedText: {
    fontSize: '11px',
    justifyContent: 'center',
    color: '#ff0000',
  },
  ssnStyle: {
    height: '30px',
  },
  textareastyleHeight: {
    height: 'auto',
    width: '100%',
  },
}));
