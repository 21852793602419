import React from 'react';
import StackSnackBar from './StackSnackBar';
import QueueSnackBar from './QueueSnackBar';

const GlobalSnackBar = () => {
  return (
    <>
      <QueueSnackBar />
      <StackSnackBar />
    </>
  );
};

export default GlobalSnackBar;
