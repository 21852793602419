import { InlineAutoComplete } from 'app/components/Common/EditPage/InlineAutoComplete';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectEditableRequirementTypes,
  selectPicklists,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { TransportationRow } from './TransportationTable';

export const TravelTimePreference = () => {
  const { t } = useTranslation();
  const { travelTimePreferenceOpts } = useSelector(selectPicklists);
  const { travelTimePreference } = useSelector(selectEditableRequirementTypes);

  return (
    <TransportationRow
      id="placement-offer-transportation-travel-time-preference"
      label={t('placement.profile.summary.offer.transportation.travelTimePreference')}
      requirement={travelTimePreference?.requirement}
      controlComponent={
        <Controller
          name="travelTimePreferenceChosen"
          render={({ ref, ...rest }) => (
            <InlineAutoComplete
              {...rest}
              /** Input value is used to override textfield incase DOM is rerendered */
              inputValue={rest.value?.label}
              options={travelTimePreferenceOpts}
              style={{ maxWidth: '160px' }}
            />
          )}
        />
      }
    />
  );
};
