/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui';
import { CustomFilter } from 'app/ComponentLibrary/Filter';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse, Grid } from 'amn-ui-core';
import { SearchPageIcons } from 'app/ComponentLibrary/Filter/SearchPageIcons';
import { gridStateActions } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { selectGridState } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';
import { useFormContext } from 'react-hook-form';
import { UnitOrdersFilter } from './filterUtils';

const useStyles = makeStyles()({
    fillterCollapse: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '3px',
    },
    collapse: {
        paddingTop: '4px',
    },
});

const UnitOrderFilter = props => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { reset } = useFormContext();
    const gridState = useSelector(selectGridState);
    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(false);
    const gridSpecs = useRef(new UnitOrdersFilter({}, t));
    const [fields, setFields] = useState<any>(gridSpecs.current.getUnitOrderFilterSpecs());
    const defaultFilterValues = {
        dateEnteredRange: null,
        maxFilesSentId: null,
        positions: null
    }
    const resetFilter = () => {
        reset({ ...defaultFilterValues });
        dispatch(
            gridStateActions.setFilterAttributes({
                ...gridState.filterAttributes,
                filterObject: Object.entries(defaultFilterValues),
            }),
        );
        props.onClickHandler(defaultFilterValues, {});
    };

    const handleChange = () => {
        setFiltersExpanded(!filtersExpanded);
    };


    return (
        <Grid container spacing={2}>
            <Grid item xs={11}>
                <Collapse timeout={200} in={filtersExpanded} collapsedSize={45} className={classes.collapse}>
                    <CustomFilter
                        fields={fields}
                        justifyContent={'flex-start'}
                        version2
                        resetBtn
                        analytics={{
                            caller: t('Unit Order Tab'),
                            onApply: true,
                        }}
                    />
                </Collapse>
            </Grid>
            <Grid item xs={1} className={classes.fillterCollapse}>
                <SearchPageIcons
                    resetlbutton
                    onResetForm={() => resetFilter()}
                    onAdvancedFilterClick={() => {
                        handleChange();
                    }}
                    checked={filtersExpanded}
                />
            </Grid>
        </Grid>
    )
}

export default UnitOrderFilter