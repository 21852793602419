import { Breadcrumbs, Link } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

export type CustomBreadcrumProps = {
  items: { title: string | ReactNode; path?: string; clickable?: boolean }[];
  onClick?: (event, item) => void;
};

const useStyles = makeStyles()(theme => ({
  root: {
    fontSize: '16px',
  },
}));

export const CustomBreadcrums = ({ items, onClick }: CustomBreadcrumProps) => {
  const { classes } = useStyles();
  const history = useHistory();

  const handleClick = (event, item) => {
    if (item.path) history.push(item.path);
    onClick?.(event, item);
  };

  return (
    <Breadcrumbs className={classes.root}>
      {items?.map(item => (
        <Link onClick={event => handleClick(event, item)}>
          {item.title}
        </Link>
      ))}
    </Breadcrumbs>
  );
};
