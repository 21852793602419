import React from 'react';
import { Avatar, GridSize } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { Requirementlabel as Requirementlabel } from '../UnitPreviewDesign';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { CustomTooltip } from '@AMIEWEB/Common';

interface Props {
  gridSize?: boolean | GridSize | undefined;
  isRequired: boolean;
  isDocumentRequired?: boolean;
  contentValue?: {
    section1: string;
    section2?: boolean;
    section3?: string;
    section4?: string;
  };
  id?: string;
  hideReqPrefText?: boolean;
}

const useStyles = makeStyles()(theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      fontSize: '14px !important',
    },
  },
  chip: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '12px',
  },
  section1: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '50%',
    display: 'block',
  },
  documentIcon: {
    fill: `${theme.palette.components.icons.secondary.backgroundColor} !important`,
    fontSize: 'large',
    marginLeft: '4px',
  },
  green: {
    width: '22px',
    height: '22px',
    fontSize: 12,
  },
  blue: {
    width: '22px',
    height: '22px',
    fontSize: '12px',
  },
  labelContainer: {
    display: 'flex',
  },
}));

export const CertificationRequirementLabel = (props: Props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { isRequired, isDocumentRequired, contentValue } = props;

  const isRequiredText = isRequired ? t('order.createUnit.required') : t('order.createUnit.preferred');
  const tooltipContent = contentValue?.section2 ? `CERT - ${contentValue.section1}` : `${contentValue.section1}`;

  return (
    <div id={props.id} className={classes.chip}>
      <CustomTooltip
        tooltipContent={`(${isRequiredText}) ${tooltipContent}`}
        placement="bottom"
        isStandardContent={true}
        contentStyle={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}
      >
        <div className={classes.labelContainer}>
          <Avatar variant={isRequired?'primary':'green'} className={`${isRequired ? classes.blue : classes.green}`}>{isRequired ? 'R' : 'P'}</Avatar>
          {contentValue?.section1?.length > 0 && (
            <div style={{ display: 'contents' }}>
              <Requirementlabel id={`${props.id}-section1`} className={`${classes.root}${classes.section1}`}>
                {contentValue?.section2 ? `CERT - ${contentValue.section1}` : `${contentValue.section1}`}
              </Requirementlabel>
            </div>
          )}
          {isDocumentRequired && <DescriptionOutlinedIcon className={classes.documentIcon} />}
        </div>
      </CustomTooltip>
    </div>
  );
};
