/* eslint-disable i18next/no-literal-string */
import React from 'react';
import { Radio, RadioGroup, FormControl, FormControlLabel, FormLabel } from 'amn-ui-core';
import { Controller, useFormContext } from 'react-hook-form';
import { FormInputProps } from './FormInputProps';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';

export const FormInputMultiRadioButton: React.FC<FormInputProps> = ({
  name,
  label,
  require,
  message,
  inputValue,
  handleChange,
  selectableAddresses,
}) => {
  const { control } = useFormContext();

  return (
    <FormControl size={'small'} variant={'outlined'}>
      <Controller
        render={({ ref, value, onChange, ...rest }) => {
          return (
            <LayoutGrid container>
              <LayoutGridItem item>
                <RadioGroup value={inputValue} onChange={e => handleChange(e?.target?.value)} {...rest} row>
                  <FormControlLabel
                    key={'TaxHome'}
                    value={'Tax Home'}
                    control={<Radio disabled={!selectableAddresses?.taxHome} />}
                    label={'Tax Home'}
                  />
                  <FormControlLabel
                    key={'Permanent'}
                    value={'Permanent'}
                    control={<Radio disabled={!selectableAddresses?.permanent} />}
                    label={'Permanent'}
                  />
                  <FormControlLabel
                    key={'Other'}
                    value={'Other'}
                    control={<Radio disabled={!selectableAddresses?.other} />}
                    label={'Other'}
                  />
                  <FormControlLabel
                    key={'CompanyAssignment'}
                    value={'Company Assignment'}
                    control={<Radio disabled={!selectableAddresses?.companyAssignment} />}
                    label={'Company Assignment'}
                  />
                  <FormControlLabel
                    key={'CompanyAssignmentHotel'}
                    value={'Company Assignment - Hotel'}
                    control={<Radio disabled={!selectableAddresses?.companyAssignmentHotel} />}
                    label={'Company Assignment - Hotel'}
                  />
                </RadioGroup>
              </LayoutGridItem>
            </LayoutGrid>
          );
        }}
        control={control}
        name={name}
        rules={{
          required: require,
        }}
      />
      <FormLabel component="legend">{label}</FormLabel>
    </FormControl>
  );
};
