/**
 * Method to download pdf from url (blob storage location)
 * @param data
 */
export const pdfFileDownloader = (data: any, fileName: string) => {
  // create file link in browser's memory
  const href = URL.createObjectURL(data);
  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};
