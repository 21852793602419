import { XGridChipCell } from '@AMIEWEB/Common';
import { XGridDefaultColumn } from '@AMIEWEB/Common/XGrid/cells/XGridDefaults';
import { XGridBonusIdCell } from '@AMIEWEB/Order/OrderDetails/OrderBonus/CustomComponents/XGridBonusIdCell';
import { GridCellParams, GridColumns } from '@mui/x-data-grid';
import { Link } from 'amn-ui-core';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { missingField } from 'app/constants';
import React from 'react';
import { useDispatch } from 'react-redux';

const getColor = (status: any) => {
  switch (status) {
    case 'Pending':
      return '#FC9508';
    case 'Complete':
      return '#13A01E';
    default:
      return '#75787B';
  }
};

interface GridCellProps {
  [key: string]: any;
}

const PricingDeskOrderLinkRenderer = React.memo(function PricingDeskOrderLinkRenderer(cellParams: GridCellProps) {
  const dispatch = useDispatch();
  const handleLink = params => {
    const payload = { open: true, order: { orderId: params?.value } };
    dispatch(globalActions.setFacilityDrawerData({ open: false, facility: undefined }));
    dispatch(globalActions.setOrderDrawerData(payload));
  };
  return (
    cellParams?.value &&
    !isNaN(cellParams?.value) && <Link onClick={() => handleLink(cellParams)}>{cellParams?.value}</Link>
  );
});

export const PricingDeskOrderLink = (params: GridCellParams) => {
  return <PricingDeskOrderLinkRenderer {...params} />;
};

const PricingDeskFaclityLinkRenderer = React.memo(function PricingDeskFaclityLinkRenderer(cellParams: GridCellProps) {
  const dispatch = useDispatch();
  const handleLink = params => {
    const payload = { open: true, facility: { facilityId: params?.value } };
    dispatch(globalActions.setOrderDrawerData({ open: false, order: undefined }));
    dispatch(globalActions.setFacilityDrawerData(payload));
  };
  return (
    cellParams?.value &&
    !isNaN(cellParams?.value) && <Link onClick={() => handleLink(cellParams)}>{cellParams?.value}</Link>
  );
});

export const PricingDeskFaclityLink = (params: GridCellParams) => {
  return <PricingDeskFaclityLinkRenderer {...params} />;
};

export const getAmount = (value: number) => {
  if (value === null || value === undefined) {
    return missingField;
  } else if (value === 0) {
    return `$ ${getDecimalValues(value)}`;
  } else {
    return `$ ${getDecimalValues(value)}`;
  }
};

const getDecimalValues = (num: number) => {
  return Number(num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] as unknown).toFixed(2);
};

export const pricingDeskColumns = (): GridColumns => [
  {
    ...XGridDefaultColumn,
    field: 'division',
    headerName: 'Division',
    flex: 0,
    width: 150,
  },
  {
    ...XGridDefaultColumn,
    field: 'clientType',
    headerName: 'Client Type',
    flex: 0,
    width: 100,
  },
  {
    ...XGridDefaultColumn,
    field: 'mspClient',
    headerName: 'MSP Client',
    flex: 0,
    width: 150,
  },
  {
    ...XGridDefaultColumn,
    field: 'facilityID',
    headerName: 'FID',
    flex: 0,
    width: 75,
    renderCell: PricingDeskFaclityLink,
  },
  {
    ...XGridDefaultColumn,
    field: 'facilityStatus',
    headerName: 'Facility Status',
    flex: 0,
    width: 125,
  },
  {
    ...XGridDefaultColumn,
    field: 'facilityName',
    headerName: 'Facility',
    flex: 0,
    width: 250,
  },
  {
    ...XGridDefaultColumn,
    field: 'unitName',
    headerName: 'Unit',
    flex: 0,
    width: 75,
  },
  {
    ...XGridDefaultColumn,
    field: 'orderID',
    headerName: 'OID',
    flex: 0,
    width: 75,
    renderCell: PricingDeskOrderLink,
  },
  {
    ...XGridDefaultColumn,
    field: 'orderPriority',
    headerName: 'Order Priorities',
    flex: 0,
    width: 450,
  },
  {
    ...XGridDefaultColumn,
    field: 'skillSets',
    headerName: 'Skill Set',
    flex: 0,
    width: 250,
  },
  {
    ...XGridDefaultColumn,
    field: 'shifts',
    headerName: 'Shifts',
    flex: 0,
    width: 100,
  },
  {
    ...XGridDefaultColumn,
    field: 'orderBillRate',
    headerName: 'Order Bill Rate',
    flex: 0,
    width: 120,
    align: 'right',
    renderCell: params => {
      return getAmount(params?.value);
    },
  },
  {
    ...XGridDefaultColumn,
    field: 'maxFilesSent',
    headerName: 'MFS',
    flex: 0,
    width: 75,
  },
  {
    ...XGridDefaultColumn,
    field: 'positionsAvailable',
    headerName: 'Positions',
    flex: 0,
    width: 75,
  },
  {
    ...XGridDefaultColumn,
    field: 'pricingStatus',
    headerName: 'Pricing Status',
    flex: 0,
    width: 150,
    renderCell: XGridChipCell({
      size: 'small',
      dynamicColor: (params: GridCellParams) => {
        return getColor(params.value);
      },
    }),
  },
  {
    ...XGridDefaultColumn,
    field: 'bonusAmount',
    headerName: 'Bonus Amount',
    flex: 0,
    width: 100,
    align: 'right',
    renderCell: params => {
      return getAmount(params?.value);
    },
  },
  {
    ...XGridDefaultColumn,
    field: 'bonusStartDate',
    headerName: 'Bonus Start Date',
    flex: 0,
    width: 150,
  },
  {
    ...XGridDefaultColumn,
    field: 'bonusEndDate',
    headerName: 'Bonus End Date',
    flex: 0,
    width: 150,
  },
  {
    ...XGridDefaultColumn,
    field: 'bonusID',
    headerName: 'Order Bonus ID',
    flex: 0,
    width: 110,
    renderCell: params => XGridBonusIdCell({ gridCellParams: params, isPricingDesk: true }),
  },
  {
    ...XGridDefaultColumn,
    field: 'bonusStatus',
    headerName: 'Order Bonus Status',
    flex: 0,
    width: 150,
  },
  {
    ...XGridDefaultColumn,
    field: 'bonusType',
    headerName: 'Bonus Type',
    flex: 0,
    width: 150,
  },
  {
    ...XGridDefaultColumn,
    field: 'orderCreationDate',
    headerName: 'Order Creation Date',
    flex: 0,
    width: 150,
  },
  {
    ...XGridDefaultColumn,
    field: 'statusChangedDate',
    headerName: 'Status Changed Date',
    flex: 0,
    width: 150,
  },
  {
    ...XGridDefaultColumn,
    field: 'statusChangedBy',
    headerName: 'Status Changed By',
    flex: 0,
    width: 150,
  },
];
