import { GridColumns } from '@mui/x-data-grid-pro';
import { XGridDefaultColumn } from 'app/components/Common/XGrid/cells/XGridDefaults';
import { XGridLinkCell } from 'app/components/Common/XGrid/cells/XGridLinkCell';
import { XGridOverflowCell } from 'app/components/Common/XGrid/cells/XGridOverflowCell';
import { IGroupedListItem, TaskEntity } from 'app/models/Tasks/Tasks';
import { TaskDetailsRenderer } from '../TaskDetailsRenderer';
import i18next from 'i18next';
import { ModsLinkRadio } from '../ModsLinkRadio';
import { materialUiXGrid } from 'amn-ui-core';
import { missingField } from 'app/constants';
import { FormatDate } from '@AMIEWEB/Notification/Tasks/Common/FormatData';
import { TaskRecipientAvatars } from '../RecipientAvatars';
import { AssignHeader } from './AssignHeader';
import { XGridSideBarViewCell } from '@AMIEWEB/Common/XGrid/cells/XGridSideBarViewCell';
import { TaskXGridOverflowCell } from '@AMIEWEB/Common';

export const getStatusName = (val: number) => {
  switch (val) {
    case 1:
      return 'New';
    case 2:
      return 'Pending';
    case 3:
      return 'Completed';
    case 4:
      return 'Cancelled';
    default:
      return '--';
  }
};

export const getStatusChipColor = (val: number) => {
  switch (val) {
    case 1:
      return '#009AD9';
    case 2:
      return '#F28D17';
    case 3:
      return '#13A01E';
    case 4:
      return '#888888';
    default:
      return '#FFFFFF';
  }
};

export const defaultTaskWordDeskColumn = {
  ...XGridDefaultColumn,
  flex: 0,
};

export const getTaskGridColumns = (t, taskEntityEnum, exclude = [] as string[]): GridColumns => [
  {
    ...materialUiXGrid.GRID_CHECKBOX_SELECTION_COL_DEF,
    renderHeader: AssignHeader,
    renderCell: ModsLinkRadio,
  },
  {
    ...defaultTaskWordDeskColumn,
    field: 'type',
    headerName: t('notification.taskManagement.gridHeaders.type'),
    width: 60,
    hide: false,
    sortable: false,
  },
  {
    ...defaultTaskWordDeskColumn,
    field: 'sentTo',
    headerName: t('notification.taskManagement.gridHeaders.assignedTo'),
    width: 180,
    hide: false,
  },
  {
    ...defaultTaskWordDeskColumn,
    headerName: t('notification.taskManagement.gridHeaders.createdBy'),
    field: 'createdBy',
    width: 110,
    hide: false,
    renderCell: TaskDetailsRenderer,
  },
  {
    ...defaultTaskWordDeskColumn,
    headerName: t('notification.taskManagement.gridHeaders.createdDate'),
    field: 'sentDate',
    width: 110,
    hide: false,
    renderCell: TaskDetailsRenderer,
  },
  {
    ...defaultTaskWordDeskColumn,
    headerName: t('notification.taskManagement.gridHeaders.taskcategory'),
    field: 'taskCategory',
    width: 210,
    hide: false,
    renderCell: TaskDetailsRenderer,
  },
  {
    ...defaultTaskWordDeskColumn,
    headerName: t('notification.taskManagement.gridHeaders.task'),
    field: 'task',
    width: 210,
    hide: false,
    renderCell: TaskDetailsRenderer,
  },
  {
    ...defaultTaskWordDeskColumn,
    headerName: t('notification.taskManagement.gridHeaders.details'),
    field: 'description',
    width: 210,
    hide: false,
    renderCell: TaskDetailsRenderer,
  },
  {
    ...defaultTaskWordDeskColumn,
    headerName: t('notification.taskManagement.gridHeaders.status'),
    field: 'status',
    width: 120,
    hide: false,
    sortable: false,
    renderCell: TaskDetailsRenderer,
  },
  {
    ...defaultTaskWordDeskColumn,
    headerName: t('notification.taskManagement.gridHeaders.dueDate'),
    field: 'dueDate',
    width: 120,
    hide: false,
    renderCell: TaskDetailsRenderer,
  },
  {
    ...defaultTaskWordDeskColumn,
    headerName: t('notification.taskManagement.gridHeaders.recipientStatus'),
    field: 'recipientStatus',
    width: 180,
    hide: false,
    renderCell: TaskRecipientAvatars,
    sortable: false,
  },
  {
    ...defaultTaskWordDeskColumn,
    headerName: t('notification.taskManagement.gridHeaders.placementId'),
    field: 'placementId',
    width: 120,
    hide: taskEntityEnum === TaskEntity.PLACEMENTS,
    renderCell: XGridOverflowCell(XGridLinkCell('/placement/{0}', ['placementId'], true))(
      XGridLinkCell('/placement/{0}', ['placementId']),
    ),
  },
  {
    ...defaultTaskWordDeskColumn,
    headerName: t('notification.taskManagement.gridHeaders.lastComment'),
    field: 'lastComment',
    width: 240,
    sortable: false,
    renderCell: TaskXGridOverflowCell(XGridSideBarViewCell('/task/comment'))(XGridSideBarViewCell('/task/comment')),
  },
  {
    ...defaultTaskWordDeskColumn,
    headerName: t('notification.taskManagement.gridHeaders.candidate'),
    field: 'candidateName',
    width: 180,
    hide: taskEntityEnum === TaskEntity.CANDIDATES,
    renderCell: XGridOverflowCell(XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId'], true))(
      XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId']),
    ),
  },
  {
    ...defaultTaskWordDeskColumn,
    headerName: t('notification.taskManagement.gridHeaders.candidateId'),
    field: 'candidateId',
    width: 120,
    hide: taskEntityEnum === TaskEntity.CANDIDATES,
    renderCell: XGridOverflowCell(XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId'], true))(
      XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId']),
    ),
  },
  {
    ...defaultTaskWordDeskColumn,
    headerName: t('notification.taskManagement.gridHeaders.orderId'),
    field: 'orderId',
    width: 90,
    hide: taskEntityEnum === TaskEntity.ORDERS,
    renderCell: XGridOverflowCell(XGridLinkCell('/order/{0}', ['orderId'], true))(
      XGridLinkCell('/order/{0}', ['orderId']),
    ),
  },
  {
    ...defaultTaskWordDeskColumn,
    headerName: t('notification.taskManagement.gridHeaders.facility'),
    field: 'facilityName',
    width: 210,
    hide: taskEntityEnum === TaskEntity.FACILITIES,
    renderCell: XGridOverflowCell(XGridLinkCell('/facility/{0}', ['facilityId'], true))(
      XGridLinkCell('/facility/{0}', ['facilityId']),
    ),
  },
  {
    ...defaultTaskWordDeskColumn,
    headerName: t('notification.taskManagement.gridHeaders.facilityId'),
    field: 'facilityId',
    width: 120,
    hide: taskEntityEnum === TaskEntity.FACILITIES,
    renderCell: XGridOverflowCell(XGridLinkCell('/facility/{0}', ['facilityId'], true))(
      XGridLinkCell('/facility/{0}', ['facilityId']),
    ),
  },
];

export const filterDataList = (data, selectedFilters, userId) => {
  const filteredAssignedToList: any[] = [];
  let activeUser: any = null;
  const dataList: any[] = [];
  data?.forEach(element => {
    element?.assignedTo?.forEach(assigned => {
      selectedFilters?.assignedToIds?.forEach(selected => {
        if (selected === assigned?.assignedToId && selectedFilters.taskStatusIds?.includes(assigned.statusId)) {
          filteredAssignedToList.push(assigned);
          if (assigned?.assignedToId === userId) {
            activeUser = assigned;
          }
        }
      });
    });
    const activeUserStatusExists = (selectedFilters?.taskStatusIds || []).find(
      statusId => statusId === activeUser?.statusId,
    );
    const formattedData = {
      ...element,
      assignedToList: filteredAssignedToList?.map(i => `${i?.assignedToFirstName} ${i?.assignedToLastName}`),
      taskStatusId: activeUserStatusExists && activeUser ? activeUser?.statusId : 0,
    };
    dataList.push(formattedData);
    filteredAssignedToList?.splice(0, filteredAssignedToList?.length);
    activeUser = null;
  });
  return dataList;
};

export const tasksColumnVisibilityModel = {
  type: true,
  sentTo: true,
  createdBy: true,
  sentDate: true,
  taskCategory: true,
  task: true,
  description: true,
  status: true,
  dueDate: true,
  recipientStatus: true,
  placementId: true,
  candidateName: true,
  candidateId: true,
  orderId: true,
  facilityName: true,
  facilityId: true,
};

export const taskColumnVisibiltyModelOnTaskEntityChange = entityValue => {
  const model = {
    type: true,
    sentTo: true,
    createdBy: true,
    sentDate: true,
    taskCategory: true,
    task: true,
    description: true,
    status: true,
    dueDate: true,
    recipientStatus: true,
    placementId: entityValue === TaskEntity.PLACEMENTS ? false : true,
    candidateName: entityValue === TaskEntity.CANDIDATES ? false : true,
    candidateId: entityValue === TaskEntity.CANDIDATES ? false : true,
    orderId: entityValue === TaskEntity.ORDERS ? false : true,
    facilityName: entityValue === TaskEntity.FACILITIES ? false : true,
    facilityId: entityValue === TaskEntity.FACILITIES ? false : true,
  };
  return model;
};

export const checkRequiredFilters = (filters: any) => {
  let severity: null | 'warning' | 'error' = null;
  let message: string = '';

  if (!filters?.taskStatusIds?.length) {
    severity = 'error';
    message = i18next.t('search.globalSearch.task.requiredStatusFilterBannerMessage');
  }
  if (
    !(
      filters?.assignedToIds?.length ||
      filters?.candidateIds?.length ||
      filters?.candidateName?.length ||
      filters?.placementIds?.length ||
      filters?.orderIds?.length ||
      filters?.facilityIds?.length ||
      filters?.facility?.length
    )
  ) {
    severity = severity ?? 'warning';
    message += ` ${i18next.t('search.globalSearch.task.requiredFilterBannerMessage')}`;
  }
  return severity
    ? {
        severity: severity,
        message: message,
      }
    : null;
};

export const formatPlacementGridDetails = (groupPlacementData: IGroupedListItem[]) => {
  const formattedData = groupPlacementData?.map(data => {
    const groupDetails = {
      ...data,
      placementStatus: data?.placementStatus ? data?.placementStatus : missingField,
      placementStartDate: data?.placementStartDate ? FormatDate(data?.placementStartDate, 'MM/DD/YYYY') : missingField,
    };
    return groupDetails;
  });
  return formattedData;
};
