import { AccountInfo } from '@azure/msal-browser';
import { HubConnection } from '@microsoft/signalr';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IUser {
  userInfo?: IUserInfo;
  error?: boolean;
}
interface IEmployeeExternalSystemDetails {
  cfo: boolean;
  placementCopy?: boolean;
  [key: string]: any;
}

export interface IUserSmsForwardPreference {
  userId: string;
  preferences: {
    forwardInboundSmsToEmail: boolean;
  };
}

export interface IEmployeeSharedProfile {
  employeeId: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  email?: string;
}
export interface IUserInfo {
  employeeId?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  roles?: string[];
  multiroled?: boolean;
  device?: string;
  phoneNumber?: string | null;
  directNumber?: string;
  ntUserName?: string;
  companyId?: number;
  companyBrandName?: string;
  hubConnection?: HubConnection;
  externalSystemDetails?: IEmployeeExternalSystemDetails;
  forwardInboundSmsToEmail?: boolean;
  sharedProfile?: IEmployeeSharedProfile;
}

export const initialState: IUser = {
  userInfo: {
    employeeId: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    roles: undefined,
    multiroled: undefined,
    phoneNumber: undefined,
    directNumber: undefined,
    device: undefined,
    ntUserName: undefined,
    companyId: undefined,
    companyBrandName: undefined,
    hubConnection: undefined,
    externalSystemDetails: undefined,
    forwardInboundSmsToEmail: undefined,
    sharedProfile: undefined,
  },
  error: false,
};

const requestUserDevicePreference = createAction<{ user: AccountInfo }>('user/devicePreferences');
const requestUserInfo = createAction<{ user: AccountInfo; accessToken: string }>('user/userInfo');
const changeUserPreference = createAction<IUserSmsForwardPreference>('user/userInfo/forwardInboundSmsToEmail');

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser(state, action: PayloadAction<IUser>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setError(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        error: action.payload,
      };
    },
    setForwardSmsToEmail(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          forwardInboundSmsToEmail: action.payload,
        },
      };
    },
  },
});

const { actions } = userSlice;

export const { reducer: userReducer, name: userSliceKey } = userSlice;
export const userActions = {
  ...actions,
  requestUserInfo,
  requestUserDevicePreference,
  changeUserPreference,
};
