/* eslint-disable tss-unused-classes/unused-classes */
/* eslint-disable react-hooks/exhaustive-deps */
import { addDays } from 'date-fns';
import React, { useEffect } from 'react';
import {
  Views,
  assignCoverageVar,
} from './assignHelper';
import { UseFormMethods } from 'react-hook-form';
import { Grid } from 'amn-ui-core';
import { DatePicker } from './DatePicker';
import { SelectTimePicker } from './TimePicker';
import { useTranslation } from 'react-i18next';
import { IRequestCoverage } from 'app/models/UserCoverage/UserCoverage';

export const DateTimePicker = ({
  view,
  isAllDay,
  editCoverageData,
  isCallingAPI,
  formMethods,
}: {
  view: Views;
  isAllDay: boolean;
  editCoverageData: any;
  isCallingAPI: boolean;
  formMethods: UseFormMethods<IRequestCoverage>;
}) => {
  const { t } = useTranslation();
  const { watch, setValue } = formMethods;

  const startDate = watch(assignCoverageVar.startDate);
  const endDate = watch(assignCoverageVar.endDate);
  const startTime = watch(assignCoverageVar.startTime);
  const endTime = watch(assignCoverageVar.endTime);

  const getMinDate = () => {
    const date = addDays(new Date(), -1);
    date.setFullYear(new Date().getFullYear() - 2);
    return date;
  };

  useEffect(() => {
    if (editCoverageData) {
      if (editCoverageData?.startTime) {
        setValue(assignCoverageVar.startDate, editCoverageData.startDate);
      } else {
        setValue(assignCoverageVar.startDate, editCoverageData.startDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCoverageData]);

  return (
    <>
      <Grid container item>
        <Grid item xs={12}>
          <DatePicker
            name={'coverageDateRange'}
            placeholder={t('notification.profileDrawer.requestCoverage.datePicker')}
            isCallingAPI={isCallingAPI}
            minDate={getMinDate()}
            formMethods={formMethods}
            maxWidth={400}
            startDate={startDate ? new Date(startDate as string) : null}
            endDate={endDate ? new Date(endDate as string) : null}
            data={editCoverageData}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={4}>
        {!isAllDay && (
          <Grid item xs={6}>
            <SelectTimePicker
              name={assignCoverageVar.startTime}
              label={t('notification.coverage.assignModal.startTime')}
              isAllDay={isAllDay}
              isCallingAPI={isCallingAPI}
              maxTime={startDate === endDate ? endTime : ''}
              formMethods={formMethods}
            />
          </Grid>
        )}
        {!isAllDay && (
          <Grid item xs={6}>
            <SelectTimePicker
              name={assignCoverageVar.endTime}
              label={t('notification.coverage.assignModal.endTime')}
              isAllDay={isAllDay}
              isCallingAPI={isCallingAPI}
              minTime={startDate === endDate ? startTime : ''}
              formMethods={formMethods}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
