import { selectEditFacilityClientContacts } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { List } from 'amn-ui-core';
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';
import { ContactCheckbox, getDescription, SelectAllCheckbox } from '@AMIEWEB/Common/ContactsDrawer/ContactList';
import { ContactItem } from '@AMIEWEB/Common/ContactsDrawer/ContactItem';

const useStyles = makeStyles()(theme => ({
  root: {
    padding: '0px 5px',
  },
}));

type ClientContactsProps = {
  allContactsSelected: () => void;
  handleSelectAll: () => void;
  handleClick: (id: number) => void;
  disableIndividualIcons: boolean;
};

const ClientContacts: React.FC<ClientContactsProps> = props => {
  const { classes } = useStyles();
  const { handleSelectAll, allContactsSelected, handleClick, disableIndividualIcons } = props;

  const keyClientContacts = useSelector(selectEditFacilityClientContacts);

  const isSelectAllDisabled = () => {
    return keyClientContacts?.filter(contact => (contact?.email || contact?.phoneNumber) && contact?.id)?.length === 0;
  };

  return (
    <List className={classes.root}>
      <SelectAllCheckbox
        handleSelectAll={handleSelectAll}
        disabled={isSelectAllDisabled()}
        checked={allContactsSelected?.()}
      />
      {keyClientContacts?.map((contact, index) =>
        contact?.title ? (
          <ContactItem
            {...contact}
            key={index}
            enableCommunication
            isClientContact
            normalOrder
            enableVoiceCommunication
            showCheckbox
            isFacilityContact
            facilityId={contact?.facilityId}
            disableMailIcon={disableIndividualIcons}
            disablePhoneIcon={disableIndividualIcons}
            description={getDescription(contact?.description)}
            checkbox={
              <ContactCheckbox
                id={contact?.id}
                title={contact?.title}
                disabled={!contact?.email}
                checked={contact?.checked}
                handleClick={handleClick}
              />
            }
          />
        ) : (
          <></>
        ),
      )}
    </List>
  );
};

export default ClientContacts;
