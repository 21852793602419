import { Grid, Paper, Chip, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { convertToFormat } from 'app/helpers/dateHelper';
import { useTranslation } from 'react-i18next';
import { CandidatePacketStatus, IPlacementSubmitPacket } from 'store/redux-store/placement-documents/types';
import { colors } from 'styles/styleVariables';
import { useDebouncedClick } from 'utils/hooks';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';

const getPacketStatusChip = (t, classes) =>
  customSwitch({
    [CandidatePacketStatus.sent]: {
      label: t('placement.profile.submitPacket.dialog.content.existingPacket.sentChip'),
      variant: 'approved',
    },
    [CandidatePacketStatus.okToSend]: {
      label: t('placement.profile.submitPacket.dialog.content.existingPacket.okToSendChip'),
      variant: 'pending',
    },
    [CandidatePacketStatus.notSent]: {
      label: t('placement.profile.submitPacket.dialog.content.existingPacket.notSentChip'),
      variant: 'canceled',
    },
  })({
    label: null,
    variant:undefined
  });

const usePacketStyles = makeStyles()(theme => ({
  packetCard: {
    height: 82,
    border: '1px solid #E3DFDF',
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    boxShadow: 'none',
  },
  packetCardFocused: {
    border: `2px solid ${colors.amnBlue}`,
    backgroundColor: `${theme.palette.system.cardBackground}`,
    boxShadow: '0px 2px 5px #0000001A',
  },
  chip: {
    height: 27,
    paddingTop: 2,
  },

  version: {
    fontWeight: 450,
    fontSize: 16,
  },
  selected: {
    color: colors.amnBlue,
  },
  hovarableLink: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  icon: {
    color: colors.darkText,
  },
}));

interface PacketCardProps {
  packet: IPlacementSubmitPacket;
  selected?: boolean;
  onClick?: (packet: IPlacementSubmitPacket) => void;
  inlineDownload?: (packet: IPlacementSubmitPacket, isDoubleClick: boolean) => void;
}

// TODO: Updated document icon padding/margin
export const PacketCard = ({ packet, selected, onClick, inlineDownload, ...props }: PacketCardProps) => {
  const { classes, cx } = usePacketStyles();
  const { t } = useTranslation();

  //use custom hook to detect double click
  const _onClick = useDebouncedClick(() => {
    onClick?.(packet);
  });

  const _inlineDownload = event => {
    event.preventDefault();
    inlineDownload?.(packet, false);
  };

  const _inlineDoubleClickDownload = () => {
    inlineDownload?.(packet, true);
    if (!selected) {
      onClick?.(packet);
    }
  };

  const { chipLabel, variant, hasSentDate } = React.useMemo(() => {
    const { label, variant } = getPacketStatusChip(t, classes)(packet.status);
    return {
      chipLabel: label,
      variant,
      hasSentDate: !!packet.sentDate,
    };
  }, [packet]);

  return (
    <Paper
      className={cx(classes.packetCard, { [classes.packetCardFocused]: selected })}
      elevation={2}
      onClick={_onClick}
      onDoubleClick={_inlineDoubleClickDownload}
    >
      <Grid
        container
        spacing={1}
        wrap="nowrap"
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: '10px' }}
      >
        <Grid item style={{ padding: hasSentDate ? '0 0 6px 6px' : undefined }}>
          <DescriptionOutlinedIcon classes={{ root: cx(classes.icon, { [classes.selected]: selected }) }} />
        </Grid>
        <Grid item>
          <Grid container style={{ marginTop: '18px' }}>
            <Grid item xs={12}>
              <Typography classes={{ root: cx(classes.version, { [classes.selected]: selected }) }}>
                <span onClick={selected && _inlineDownload} className={selected && classes.hovarableLink}>
                  {t('placement.profile.submitPacket.dialog.content.existingPacket.version', {
                    version: packet.version,
                  })}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                {t('placement.profile.submitPacket.dialog.content.existingPacket.dateCreated', {
                  date: convertToFormat(packet.createdDate, 'MM/DD/YYYY', true),
                })}
              </Typography>
            </Grid>
            {hasSentDate ? (
              <Grid item xs={12}>
                <Typography variant="body2">
                  {t('placement.profile.submitPacket.dialog.content.existingPacket.sentDate', {
                    date: convertToFormat(packet.sentDate, 'MM/DD/YYYY', true),
                  })}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item>
          <Chip className={cx(classes.chip)} variant={variant} label={chipLabel} />
        </Grid>
      </Grid>
    </Paper>
  );
};
