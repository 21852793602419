import { CircularProgress } from 'amn-ui-core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Cancel } from 'app/components/Common/CancelModal/Cancel';
import _ from 'lodash';
import { useReadOnly } from 'oidc/userRoles';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { selectUser } from 'oidc/user.selectors';
import { IReferenceRequest } from 'app/models/Candidate/Tags';
import treeData from 'app/assets/jsons/placementStatusFilter.json';
import moment from 'moment';
import {
  candidateDetailsSelection,
  requestReferenceSaveStatusSelector,
  selectReferences,
} from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { httpSuccess } from 'app/services/serviceHelpers';
import { workExperienceActions } from 'store/redux-store/candidate-work-experience/slice';
import { usePromiseTracker } from 'react-promise-tracker';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { RequestReferenceFormFields } from './RequestReferenceFormFields';
import { useRequestReferenceModalStyles } from './style';
import { referenceTypeOptions, IDropdownOptions, getAllowedStatuses } from './helper';

export const RequestReferenceModal = props => {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const { classes } = useRequestReferenceModalStyles();
  const referenceTypes = referenceTypeOptions();
  const { t } = useTranslation();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const formMethods = useForm({
    mode: 'all',
  });
  const [cancel, setCancel] = useState<boolean>(false);
  const user = useSelector(selectUser);
  const requestReferenceSaveStatus = useSelector(requestReferenceSaveStatusSelector);
  const [addRefRequestDisabled, setAddRefRequestDisabled] = useState<boolean>(false);
  const [placementOptions, setPlacementOptions] = useState<IDropdownOptions[]>([]);
  const { readOnly } = useReadOnly();
  const {
    handleSubmit,
    formState: { dirtyFields, errors, touched },
  } = formMethods;
  const selReferences = useSelector(selectReferences);

  const { promiseInProgress: postReferenceRequestFromModalTracker } = usePromiseTracker({
    area: 'post-reference-request-work-experience',
    delay: 0,
  });

  useEffect(() => {
    const allowedStatuses = getAllowedStatuses(treeData);
    setPlacementOptions(
      candidateDetails?.placement?.placementInfo
        ?.filter(item => allowedStatuses?.includes(item.placementStatus))
        ?.map(item => {
          const startDate = item.startDate ? `, ${moment(item.startDate).format('MM/DD/YYYY')}` : '';
          const endDate = item.endDate && item.startDate ? ` - ${moment(item.endDate).format('MM/DD/YYYY')}` : '';
          return {
            ID: item.placementId,
            Description: `${item.placementId}, ${item.facilityName}${startDate}${endDate}`,
          };
        }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      selReferences?.items?.find(
        item =>
          item.phoneNumbers?.length > 0 &&
          item.supervisorName?.length > 0 &&
          (item.contactPreferenceTypeID === 1 || item.contactPreferenceTypeID === 2),
      )
    ) {
      if (candidateDetails?.okToContact || !candidateDetails?.currentlyEmployed) {
        setAddRefRequestDisabled(false);
      } else if (
        candidateDetails?.experience?.employment?.find(item => moment(item.endedOn).isSameOrBefore(moment()))
      ) {
        setAddRefRequestDisabled(false);
      } else {
        setAddRefRequestDisabled(true);
      }
    } else {
      setAddRefRequestDisabled(true);
    }
  }, [candidateDetails, selReferences]);

  const onSubmit = (data: any) => {
    const requestData: IReferenceRequest = {
      travelerId: candidateDetails.travelerId,
      companyId: candidateDetails.brandId,
      employeeId: user.userInfo?.employeeId,
      placementId: data.placementId ? placementOptions.find(item => item.Description === data.placementId)?.ID : null,
      employeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
      referenceRequestTypeID: referenceTypes?.find(obj => obj.Description === data.referenceType).ID,
      note: data.notes,
      notes: data.notes,
      referenceType: data.referenceType,
      brandId: candidateDetails.brandId,
      status: 'New',
      requestedById: user.userInfo?.employeeId,
    };
    dispatch(workExperienceActions.postReferenceRequestFromWorkExpAction(requestData));
  };

  useEffect(() => {
    if (httpSuccess(requestReferenceSaveStatus?.status)) {
      handleClose();
      dispatch(candidateDetailActions.setRequestReferenceSaveStatus(null));
    }
  }, [requestReferenceSaveStatus]);

  const onError = () => {};

  const handleCancel = () => {
    if (!_.isEmpty(errors) || !_.isEmpty(dirtyFields) || !_.isEmpty(touched)) setCancel(true);
    else yesAction();
  };
  const yesAction = () => {
    handleClose?.();
    setCancel(false);
  };
  const noAction = () => {
    setCancel(false);
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <GenericDialog
            variant={'blue'}
            classes={{ paper: classes.dialogContainer }}
            open={open}
            disablePortal
            disableEscapeKeyDown
            dialogTitleProps={{
              text: t('candidate.dialogActions.RequestReference'),
              closeButton: true,
              expandable: true,
            }}
            dialogActions={[
              {
                classes: { root: classes.dailogActions },
                onClick: handleCancel,
                text: 'CANCEL',
                variant: 'contained',
                color: 'tertiary',
              },
              {
                classes: { root: classes.dailogActions },
                text: t('candidate.dialogActions.Request'),
                variant: 'contained',
                type: 'submit',
                disabled: addRefRequestDisabled || readOnly,
                tooltipProps: readOnly
                  ? {
                      tooltipContent: t('global.readOnlyTooltip'),
                    }
                  : undefined,
              },
            ]}
            dialogContentProps={{
              classes: { root: classes.dialogContent },
            }}
            onClose={handleCancel}
          >
            {selReferences && (
              <RequestReferenceFormFields
                refTypeOptions={referenceTypes}
                placementOptions={placementOptions}
                addRefRequestDisabled={addRefRequestDisabled}
              />
            )}
            {postReferenceRequestFromModalTracker && <CircularProgress className={classes.MuiCircularProgressRoot} />}
          </GenericDialog>
          {cancel && <Cancel openDialog={cancel} handleConfirmAction={yesAction} handleCancelAction={noAction} />}
        </form>
      </FormProvider>
    </>
  );
};
