import { takeLatest, put, call } from 'redux-saga/effects';
import { getPlacementReminders } from 'app/services/PlacementServices/PlacementServices';
import { placementReminderActions } from './PlacementReminders.redux';
import moment from 'moment';
import { IPlacementReminder } from 'app/models/Credentialing/PlacementReminder';
import { daysFromToday } from 'app/helpers/dateHelper';
import { getMultiSelectOptions } from 'app/ComponentLibrary/Filter/helper';
import { setPlacementVolumeTotals } from '../Helpers/PlacementRemindersFilterHelper';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';

/**
 * Placement Reminders.
 * @param action
 */

export function* requestCredentialingWorkDeskPlacementReminderList() {
  try {
    yield put(placementReminderActions.setLoading(true));
    const results = yield call(async () => {
      const r = await getPlacementReminders('all');
      return {
        placementsByRemindersList: r,
      };
    });

    if (results && results?.placementsByRemindersList) {
      const responseData = results?.placementsByRemindersList;
      const _flattenedData = formatPlacementReminders(responseData.placements);
      const counts = setPlacementVolumeTotals(_flattenedData);
      yield put(placementReminderActions.setCounts(counts));
      yield put(placementReminderActions.setFlattenedData(_flattenedData));
      yield put(placementReminderActions.setStateID('all'));
      const filterSpecs = {
        placementIDOpts: getMultiSelectOptions(responseData.filterSpecs.placementIDOpts),
        placementStatusOpts: getMultiSelectOptions(responseData.filterSpecs.placementStatusOpts),
        candidateIdOpts: getMultiSelectOptions(responseData.filterSpecs.candidateIdOpts),
        candidateNameOpts: getMultiSelectOptions(responseData.filterSpecs.candidateNameOpts),
        clientNameOpts: getMultiSelectOptions(responseData.filterSpecs.clientNameOpts),
        reminderStatusOpts: getMultiSelectOptions(responseData.filterSpecs.reminderStatusOpts),
        reminderTypeOpts: getMultiSelectOptions(responseData.filterSpecs.reminderTypeOpts),
      };
      yield put(placementReminderActions.setFilterSpecs(filterSpecs));
      yield put(placementReminderActions.setLoading(false));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestCredentialingWorkDeskPlacementReminderList',
        area: 'src/app/components/CredentialingWorkDesk/PlacementReminders/Store/PlacementReminders.saga.ts',
      },
    });
    yield put(placementReminderActions.setLoading(false));
  }
}

export function* credentialWorkDeskPlacementReminderSaga() {
  yield takeLatest(
    placementReminderActions.requestCredentialingWorkDeskPlacementReminderList.type,
    requestCredentialingWorkDeskPlacementReminderList,
  );
}

export const formatPlacementReminders = response => {
  return response.map((item: IPlacementReminder) => ({
    placementId: item.placementId,
    candidateName: `${item?.candidateName?.firstName || ''} ${item?.candidateName?.lastName || ''}`,
    candidateLastName: item?.candidateName?.lastName || '',
    placementStatus: item.placementStatus,
    startDate: moment(item.startDate).format('MM/DD/yyyy'),
    startDateNoFormat: item.startDate,
    daysBeforeStart: daysFromToday(moment(item.startDate), true),
    followUpDate: moment(item.followUpDate).format('MM/DD/yyyy'),
    followUpDateNoFormat: item.followUpDate,
    reminderType: item.eventType,
    reminderStatus: item.status,
    facilityId: item.facilityId,
    clientName: item.facilityName,
    travelerId: item.candidateId,
    brandId: item.brandId,
    eventTypeId: item.eventTypeId,
  }));
};
