import { Typography } from 'amn-ui-core';
import React from 'react';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import { RatingValue } from '../Config/enums';
import { getRatingTitle } from '../Config/helper';
import { RatingConfiguration, Specify } from '../Config/constants';
import ActiveCheckButton from 'app/assets/images/CandidateProfile/check_button_active.svg';
import NonActiveCheckButton from 'app/assets/images/CandidateProfile/check_button_nonactive.svg';
import ActiveCheckButtonGray from 'app/assets/images/CandidateProfile/check_button_active_gray.svg';
import NonActiveCheckButtonGray from 'app/assets/images/CandidateProfile/check_button_nonactive_gray.svg';

/**
 *  Component which renders rating color panel with text
 */
export const RatingContainer = ({
  ratingId,
  showDescription,
  width,
  yesOrNoType,
  hasAnswer,
  textType,
}: {
  ratingId?: number;
  showDescription?: boolean;
  width?: string;
  yesOrNoType?: boolean;
  hasAnswer?: boolean;
  textType?: boolean;
}) => {
  const selectedRating = RatingConfiguration?.find(item => item.id === ratingId);
  const colorPanelArray: any = selectedRating ? selectedRating?.color : [];
  const ratingTitle = getRatingTitle(ratingId, showDescription);
  return (
    <>
      {yesOrNoType && (
        <LayoutGridItem item xs={3} sx={{ display: 'flex', marginTop: '1px' }}>
          <>
            {hasAnswer && (
              <>                
                <img src={ActiveCheckButton} alt="emr-active-checkbox"/>
                <img src={NonActiveCheckButtonGray} alt="emr-active-checkbox" style={{ paddingLeft: '6px' }} />
                <Typography style={{ paddingLeft: '6px', whiteSpace: 'nowrap' }}>{hasAnswer ? 'Yes' : 'No'}</Typography>
              </>
            )}

            {!hasAnswer && (
              <>
                <img src={ActiveCheckButtonGray} alt="emr-active-checkbox" />
                <img src={NonActiveCheckButton} alt="emr-active-checkbox" style={{ paddingLeft: '6px' }} />
                <Typography style={{ paddingLeft: '6px', whiteSpace: 'nowrap' }}>{hasAnswer ? 'Yes' : 'No'}</Typography>
              </>
            )}
            
          </>
        </LayoutGridItem>
      )}

      {!yesOrNoType &&
        !textType &&
        colorPanelArray.map(itemColor => {
          return (
            <span
              style={{
                background: itemColor,
                width: '15px',
                height: '15px',
                display: 'inline-block',
                marginLeft: '2px',
              }}
            />
          );
        })}
      {!yesOrNoType && !textType && (
        <span style={{ marginLeft: '10px', fontSize: '14px', width: width ? width : '151px' }}>{ratingTitle}</span>
      )}
    </>
  );
};

/**
 * Component rendering the rating field and its corresponding rating panel
 */
export const RatingPanel = ({ currentItem, key }: { currentItem; key: number }) => {
  return (
    <>
      <LayoutGridItem container item key={key} direction="row">
        <LayoutGridItem item xs={6}>
          <Typography sx={{ fontSize: '12px !important', color: '#333333' }}>
            {currentItem?.text?.toLowerCase()?.includes(Specify)
              ? `${currentItem.text} - ${currentItem.tValue ?? 'N/A'}`
              : currentItem?.text}
          </Typography>
        </LayoutGridItem>

        <LayoutGridItem item sx={{ display: 'flex', alignItems: 'center' }} xs={6}>
          <RatingContainer
            ratingId={currentItem?.hasAnswer && currentItem.iValue ? currentItem?.iValue : RatingValue.No_Experience}
            yesOrNoType={currentItem?.type === 'Yes/No' ? true : false}
            hasAnswer={currentItem?.hasAnswer && currentItem?.iValue == 1}
            textType={currentItem?.type === 'Text'}
          />
        </LayoutGridItem>
      </LayoutGridItem>
    </>
  );
};
