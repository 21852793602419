import { navigationStickActions, settingMenu } from '@AMIEWEB/GlobalNavigationMenu/NavigationStick.redux';
import { selectNavigationStick } from '@AMIEWEB/GlobalNavigationMenu/NavigationStick.selectors';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectIsAutoTaskDetailsModified } from 'store/redux-store/placement-requests/selectors';
import {
  placementRequestsAction, placementRequestsReducer, placementRequestsSliceKey
} from 'store/redux-store/placement-requests/slice';
import { ConfirmationDialog } from './common/ConfirmationDialog';
import { AutoTaskManagementWrapper } from './componenets/AutoTaskManagementWrapper';
import { placementRequestsSaga } from 'store/redux-store/placement-requests/saga';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

export const AutoTaskManagementPage = () => {
  useInjectSaga({ key: placementRequestsSliceKey, saga: placementRequestsSaga });
  useInjectReducer({ key: placementRequestsSliceKey, reducer: placementRequestsReducer })
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { selectedMenu } = useSelector(selectNavigationStick);
  const isDirty = useSelector(selectIsAutoTaskDetailsModified);


  const [triggerExit, setTriggerExit] = useState({
    isExit: false,
    path: '',
  });
  const [isVisibleDialog, setVisibleDialog] = useState(false);

  const handleGoToIntendedPage = useCallback(location => history.push(location), [history]);

  useEffect(() => {
    if (triggerExit.isExit) {
      dispatch(placementRequestsAction.reset());
      handleGoToIntendedPage(triggerExit.path);
    }
    //@ts-ignore
    const unblock = history.block(location => {
      if (location.pathname !== '/settings/auto-task-management' && isDirty) {
        setVisibleDialog(true);
      }
      setTriggerExit(obj => ({ ...obj, path: location.pathname }));
      if (triggerExit.isExit || !isDirty) {
        return true;
      }
      return false;
    });

    return () => {
      unblock();
    };
  }, [handleGoToIntendedPage, history, triggerExit.isExit, triggerExit.path, isDirty]);

  /** Logic to highlight sidenav if routes are directly accessed */
  React.useEffect(() => {
    if (!['settings', 'templateEditor'].includes(selectedMenu?.key)) {
      const menu = settingMenu.subMenu.find(x => x.key === 'autoTaskManagement');

      dispatch(navigationStickActions.setSelectedMenu(settingMenu));
      dispatch(navigationStickActions.setSelectedSubMenu(menu));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const keepChanges = () => {
    setVisibleDialog(false);
    const menu = settingMenu.subMenu.find(x => x.key === 'autoTaskManagement');
    dispatch(navigationStickActions.setSelectedMenu(settingMenu));
    dispatch(navigationStickActions.setSelectedSubMenu(menu));
  };
  const handleDiscard = () => {
    setTriggerExit(obj => ({
      ...obj,
      isExit: true,
    }));
    setVisibleDialog(false);
  };

  const handleSave = () => {
    dispatch(placementRequestsAction.updateAutoTaskManagement());
    setTriggerExit(obj => ({
      ...obj,
      isExit: true,
    }));
    setVisibleDialog(false);
  };

  const saveDialogActions = [
    {
      text: t(`autoTaskManagement.button.cancel`),
      onClick: keepChanges,
    },
    {
      text: t(`autoTaskManagement.button.discard`),
      onClick: handleDiscard,
      color: 'tertiary',
      variant: 'contained',
    },
    {
      text: t(`autoTaskManagement.button.save`),
      variant: 'contained',
      color: 'primary',
      onClick: handleSave,
    },
  ];
  return (
    <>
      <AutoTaskManagementWrapper />
      <ConfirmationDialog
        dialogeOpen={isVisibleDialog}
        keepChanges={keepChanges}
        handleDiscard={handleDiscard}
        dialogActions={saveDialogActions}
        dialogTitle={t(`autoTaskManagement.dialogModal.saveChanges`)}
        dialogeText={t(`autoTaskManagement.dialogModal.saveAndExit`)}
      />
    </>
  );
};
