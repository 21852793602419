import React from 'react';
import { Avatar, Grid, GridSize } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { IconLabel, Contentlabel as ContentLabel } from '../UnitPreviewDesign';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

interface Props {
  gridSize?: boolean | GridSize | undefined;
  isRequired: boolean;
  isDocumentRequired?: boolean;
  contentValue?: string;
  id?: string;
}

const useStyles = makeStyles()(theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      fontSize: '12px !important',
    },
  },
  documentIcon: {
    fill: `${theme.palette.components.icons.secondary.backgroundColor} !important`,
    fontSize: 'large',
    marginLeft: 10,
    marginTop: 2,
  },
  green: {
    width: 22,
    height: 22,
    fontSize: 12,
  },
  blue: {
    width: 22,
    height: 22,
    fontSize: 12,
  },
}));

export const InnerIconValueSection = (props: Props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { gridSize, isRequired, isDocumentRequired, contentValue } = props;
  return (
    <Grid item xs={gridSize}>
      <Grid container direction="row" spacing={1} id={props.id}>
        {contentValue && <ContentLabel className={classes.root}>{contentValue}</ContentLabel>}
        {/* <Icon className={classes.icon}> */}
        <Avatar variant={isRequired ?'primary':'green'} className={` ${isRequired ? classes.blue : classes.green}`}>{isRequired ? 'R' : 'P'}</Avatar>
        {/* </Icon> */}
        {isRequired ? (
          <IconLabel className={classes.root}>{t('order.createUnit.required')}</IconLabel>
        ) : (
          <IconLabel className={classes.root}>{t('order.createUnit.preferred')}</IconLabel>
        )}
        {isDocumentRequired && <DescriptionOutlinedIcon className={classes.documentIcon} />}
      </Grid>
    </Grid>
  );
};
