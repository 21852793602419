import React, { useState } from 'react';
import { Grid, TextField, IconButton, Box } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { taskDetailsActions } from '../../store/Tasks.redux';
import { useDispatch, useSelector } from 'react-redux';
import { selectedUpdatedId } from '@AMIEWEB/Tasks/store/Tasks.selectors';

const MAX_COMMENT_LIMIT = 4000;

const CommentForm: React.FC<{ isDisabled?: boolean }> = ({ isDisabled = false }) => {
  const { t } = useTranslation();
  const [text, setText] = useState<string>(null);
  const isError = text?.length > MAX_COMMENT_LIMIT;
  const disableSave = !text || text?.length <= 0 || isError;
  const dispatch = useDispatch();
  const updatedId = useSelector(selectedUpdatedId);

  const handleSave = () => {
    dispatch(taskDetailsActions.saveTaskCommentAction(text));
    setText(null);
  };

  const onChange = e => {
    setText(e.target.value);
    if (updatedId) dispatch(taskDetailsActions.setTaskUpdatedId(null));
  };
  return (
    <Grid container sx={{ paddingTop: '10px' }} spacing={2}>
      <Grid item xs={11}>
        <TextField
          variant="outlined"
          multiline
          maxRows={5}
          size="small"
          placeholder={t('notification.taskDetails.addAComment')}
          sx={{ width: '100%' }}
          value={text}
          onChange={e => {
            onChange(e);
          }}
          error={isError}
          disabled={isDisabled}
          helperText={
            isError && (
              <Box component="span" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{t('notification.voice.maxCharLimit')}</span>
                <span>{`${text?.length}/${MAX_COMMENT_LIMIT}`}</span>
              </Box>
            )
          }
        />
      </Grid>
      <Grid
        xs={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '10px',
        }}
      >
        <IconButton
          color="primary"
          type="button"
          disableRipple
          disableFocusRipple
          sx={{
            color: theme => (disableSave ? theme.palette.system.silverSand : theme.palette.primary.main),
          }}
          disabled={isDisabled || disableSave}
          onClick={handleSave}
          aria-label="comment-add-button"
          size="small"
        >
          <AddBoxIcon style={{ height: 34, width: 34 }} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default CommentForm;
