import { IRequirementRowPadding } from 'app/models/Placement/PlacementSummary';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import {
  selectEditableFields,
  selectEditableRequirementTypes,
  selectOfferInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { ConfirmationCheckbox } from '../../TableComponents/Confirmations/ConfirmationCheckbox';
import { ConfirmationsRow } from '../../TableComponents/Confirmations/ConfirmationsTable';
import { selectPlacementStatus } from 'store/redux-store/placement-status/selectors';

export const DatesConfirmed = ({ hasRequirements }: IRequirementRowPadding) => {
  const { currentPlacementStatus } = useSelector(selectPlacementStatus);
  const { t } = useTranslation();
  const { datesConfirmed: initialValue } = useSelector(selectEditableFields);
  const { requirementFilter } = useSelector(selectOfferInfo);
  const { datesConfirmed } = useSelector(selectEditableRequirementTypes);
  const { control } = useFormContext();

  const { display } = useRequirementFilter('offer-confirmation', requirementFilter, datesConfirmed?.requirement);

  return display ? (
    <Controller
      control={control}
      name="datesConfirmed"
      render={({ ref, ...rest }) => (
        <ConfirmationsRow
          id="placement-offer-confirmations-dates-confirmed"
          lastUpdatedBy={rest?.value?.lastUpdatedBy}
          lastUpdatedDate={rest?.value?.lastUpdatedAt}
          requirement={datesConfirmed?.requirement}
          hasOtherRequirements={hasRequirements}
          controlComponent={
            <ConfirmationCheckbox
              label={t('placement.profile.summary.offer.confirmations.datesConfirmed')}
              defaultState={initialValue}
              disabled={
                currentPlacementStatus?.activityStatusId >= 15 && currentPlacementStatus?.activityStatusId !== 16
              }
              {...rest}
            />
          }
        />
      )}
    />
  ) : null;
};
