import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { HomePage } from '../AppHome/AppHome';
import { DefaultHomeSwitch } from '../AppHome/DefaultHomeSwitch';
import { CustomBackdrop } from '../Common/Backdrop/CustomBackdrop';
import { amPlacementsReducer, amPlacementsSliceKey } from './PlacementsDesk/AmPlacementsDesk.redux';
import { accountManagerPlacementSaga } from './PlacementsDesk/AmPlacementsDesk.saga';
import { PlacementsDeskWrapper } from './PlacementsDesk/PlacementsDeskWrapper';
import useDocumentTitle from 'utils/customHooks/useDocumentTitle';
import { pageNames } from 'app/constants/PageNames';
import { trackPageView } from 'app-insights/appInsightsTracking';
import { useTranslation } from 'react-i18next';
import { CoverageDeskWrapper } from './CoverageDesk/CoverageDeskWrapper';
import { useDecision } from '@optimizely/react-sdk';
import { ff_task_ui_createcoveragetab } from 'app/constants/FeatureFlags/EmployeeCoverage/keys';
import { GenericTabs } from '@AMIEWEB/Common/Tabs/GenericTabs';

const useStyles = makeStyles()(theme => ({
  root: {
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: theme.palette.framework.system.whisper,
    flexWrap: 'nowrap',
  },
}));

export const AmWorkDesk = () => {
  const { t } = useTranslation();
  useInjectReducer({ key: amPlacementsSliceKey, reducer: amPlacementsReducer });
  useInjectSaga({ key: amPlacementsSliceKey, saga: accountManagerPlacementSaga });
  const { promiseInProgress } = usePromiseTracker({ area: 'AccountManger-Placements-call' });
  const { classes } = useStyles();
  const [coverageFlag] = useDecision(ff_task_ui_createcoveragetab);

  useDocumentTitle({ title: pageNames.workdeskAccountManagement });

  const tabs = React.useMemo(() => {
    const _tabs = [
      { label: 'My Work Desk', component: <PlacementsDeskWrapper /> },
      {
        label: `${t('home.coverageWorkDesk.coverageTab')}`,
        component: <CoverageDeskWrapper workDeskDomain={HomePage.AM_WORK_DESK} />,
      },
    ];
    if (!coverageFlag.enabled) _tabs.splice(1, 1);
    return _tabs;
  }, [coverageFlag.enabled, t]);

  React.useEffect(() => {
    trackPageView({ name: pageNames.workdeskAccountManagement });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <CustomBackdrop open={promiseInProgress} />
      <GenericTabs
        tabs={tabs}
        modifyUrl
        dense
        addOnComponent={<DefaultHomeSwitch page={HomePage.AM_WORK_DESK} enlarged={true} />}
      />
    </div>
  );
};
