import { missingField } from 'app/constants';
import { validDecimal } from 'app/helpers/numberHelper';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import moment from 'moment';

export const getExistingRow = (payrollShiftDiffEditRequests, cellParams) => {
  return payrollShiftDiffEditRequests?.find(
    row => row.shiftDifferentialId === cellParams?.cellParams?.row?.shiftDifferentialId,
  );
};

export const getNewRow = (payrollShiftDiffEditRequests, RowId) => {
  return payrollShiftDiffEditRequests?.find(row => row.id === RowId);
};

export const findRow = (cellParams, payrollShiftDiffEditRequests, RowId) => {
  return cellParams?.cellParams?.row?.shiftDifferentialId
    ? getExistingRow(payrollShiftDiffEditRequests, cellParams)
    : getNewRow(payrollShiftDiffEditRequests, RowId);
};

export const findIndex = (updatedEndDate, cellParams, payrollShiftDiffEditRequests, RowId) => {
  return payrollShiftDiffEditRequests?.findIndex(
    cellParams?.cellParams?.row?.shiftDifferentialId
      ? row => row.shiftDifferentialId === updatedEndDate.shiftDifferentialId
      : row => row.id === RowId,
  );
};

export const isValidTime = time => {
  const newDate = moment(time);
  const newVal = newDate.isValid() ? moment(newDate).format('YYYY-MM-DDTHH:mm') : null;
  return newVal != null;
};

export const isInValidAmount = amount => {
  return (
    isNullOrUndefined(amount) ||
    amount === missingField ||
    !validDecimal(amount.toString(), { numberLength: 6, decimalLength: 2 })
  );
};

export const isInValidTime = time => {
  return isNullOrUndefined(time) || time === missingField || !isValidTime(time);
};

export const isInValidDdlItem = item => {
  return isNullOrUndefined(item) || item === missingField;
};

export const TemplateTypes = {
  Custom: 1,
  Standard: 2,
};

export const PLACEMENT_MODS_CONSTANTS = {
  CURRENT :'CURRENT'
}
