import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  IconButton,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { withStyles } from 'tss-react/mui';
import EmptyState from 'app/assets/images/emptyState.svg';
import { ChipsContainer } from 'app/ComponentLibrary/Filter/ChipsContainer';
import { generateChips } from 'app/components/GlobalSearch/helper';
import { SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CapitalizeFirstLetter } from 'utils/string/string';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { trim } from 'lodash';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import _ from 'lodash';
import Warning from 'app/assets/images/IconImages/warning.svg';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { useHistory, useLocation } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { SessionKey, setSessionValue } from 'utils/customHooks/sessionStorage/sessionHelpers';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { generateTaskChips } from '@AMIEWEB/Tasks/TaskManagement/TaskFilters/TaskFilterUtilitis';
import { GenericTabs, Orientations } from '../Tabs/GenericTabs';

const useStyles = makeStyles()(theme=>({
  dialog: {
    minWidth: '900px',
    height: '601px',
  },
  filters: {
    border: '1px solid #EBEBEB',
  },
  filterContent: {
    height: '100%',
    paddingLeft: '0px',
    overflow: 'hidden',
  },
  tabPanelWrapper: {
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
  },
  tabPanel: {
    marginTop: '20px',
    padding: '8px 25px 25px 25px',
    width: '712px',
    overflow: 'auto',
  },
  title: {
    fontSize: '16px',
    color: '#333333',
    fontWeight: 'bold',
    paddingBottom: '15px',
  },
  filterNameText: {
    display: 'flex',
    color: '#106FB9',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    alignSelf: 'center',
  },
  filterNameTitle: {
    color: '#333333',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 500,
  },
  divider: {
    borderBottom: '1px solid #EBEBEB',
  },
  text: {
    font: 'italic normal normal 14px/20px Roboto',
    color: '#333333',
  },
  iconDeleted: {
    padding: '5px',
  },
  deletedText: {
    paddingTop: '8px',
  },
  containerDeleted: {
    backgroundColor: '#FFF4E5',
    color: '#663B01',
    verticalAlign: 'center',
    marginRight: '-20px',
  },
  deletedFilterNameTitle: {
    color: '#333333',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    margin: '20px',
    marginLeft: '0px',
    marginTop: '5px',
  },
  buttonUndo: {
    color: '#663B01',
  },
  filterName: {
    marginTop: '-10px',
    marginBottom: '20px',
    height: '56px',
    width: '300px',
    fontSize: '14px',
    backgroundColor: theme.palette.components.textfield.blankField.backgroundColor,  
    '& .MuiFilledInput-input': {
      padding: '18px',
      fontSize: '14px',
      '&:hover': {
        backgroundColor:theme.palette.components.textfield.blankField.backgroundColor,
      },
    },
  },
  indicator: {
    width: '5px',
    left: '0px',
  },
  accordion: {
    background: theme.palette.components.accordion.secondary.backgroundColor,
    '&.MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  accordionSummaryRoot: {
    '&.Mui-expanded': {
      minHeight: '48px !important',
    },
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  accordionDeletedBackground: {
    background: '#FFF4E5',
  },
  filterTabs: {
    background: '#F5F5F5',
    width: '200px',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
  },
  dialogContent: {
    padding: '0px',
  },
  modal: {
    width: '380px',
    height: '192px',
    bgcolor: 'red',
    boxShadow: '20px',
    p: 2,
  },
  modalText: {
    margin: '0px 20px',
    fontSize: '14px',
    textAllign: 'left',
  },
  arrowIcon: {
    height: '20px',
    marginLeft: '2px',
  },
}))

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
interface Props {
  category: string;
  onClose: () => void;
  open: boolean;
}

const CustomTab = withStyles(Tab, {
  root: {
    marginLeft: '10px',
    textTransform: 'none',
    color: '#555555',
    alignItems: 'flex-start !important',
    justifyContent: 'flex-start',
    '& .MuiTab-wrapper': {
      alignItems: 'flex-start !important',
      justifyContent: 'flex-start',
    },
  },
});

const Accordion = withStyles(MuiAccordion, (_theme, _params, classes) => ({
  root: {
    [`&.${classes.expanded}`]: {
      margin: 'auto',
      background: 'white',
    },
    [`&.${classes.root}:before`]: {
      height: 0,
    },
  },
  expanded: {},
}));

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Typography component={'span'}>{children}</Typography>}
    </div>
  );
};

const preventEventTransmission = event => {
  event.preventDefault();
  event.stopPropagation();
};

const ManageFilter = (props: Props) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { category, onClose, open } = props;
  const [filter, setFilters] = useState<any>(null);
  const [selectedFilter, setSelectedFilter] = useState<any>({});
  const [gridName, setGridName] = useState(category);
  const { userGridPreferences: userPreference } = useSelector(selectUserPreference);
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [gridId, setGridId] = useState<string>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const [filterSet, setFilterSet] = useState<any>(null);

  /**
   * UI components are mapped to a deep clone of all grid user preferences, so application state is not changed until user requests.
   */
  const [gridPreferences, setGridPreferences] = useState(
    _.cloneDeep(userPreference)
      .map(item => ({
        ...item,
        value: {
          ...item.value,
          filterAttributes: {
            ...item.value?.filterAttributes,
            filterSet: item.value?.filterAttributes?.filterSet
              ?.sort((a, b) =>
                a.name?.toLowerCase() < b.name?.toLowerCase()
                  ? -1
                  : a.name?.toLowerCase() > b.name?.toLowerCase()
                  ? 1
                  : 0,
              )
              ?.map(item => {
                return { ...item, originalName: item.name };
              }),
          },
        },
      }))
      ?.sort((a, b) => (a.value?.gridTag < b.value?.gridTag ? -1 : a.value?.gridTag > b.value?.gridTag ? 1 : 0)),
  );

  useEffect(() => {
    const currentGrid = gridPreferences.filter(item => item.id === `GlobalSearchGrid-${category}`)[0]?.value;
    setFilters(currentGrid?.filterAttributes);
    if (currentGrid?.selectedFilter) {
      setSelectedFilter(currentGrid?.selectedFilter);
    }
    setGridId(`GlobalSearchGrid-${category}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterCount = tab => {
    return (
      gridPreferences.filter(item => item.id === `GlobalSearchGrid-${tab}`)[0]?.value?.filterAttributes?.filterSet
        ?.length || 0
    );
  };

  const tabs = [
    {
      index: 0,
      name: SearchType.candidate,
      label: `Candidate Filters (${getFilterCount(SearchType.candidate)})`,
    },
    {
      index: 1,
      name: SearchType.facility,
      label: `Facility Filters (${getFilterCount(SearchType.facility)})`,
    },
    {
      index: 2,
      name: SearchType.order,
      label: `Order Filters (${getFilterCount(SearchType.order)})`,
    },
    {
      index: 3,
      name: SearchType.placement,
      label: `Placement Filters (${getFilterCount(SearchType.placement)})`,
    },
    {
      index: 4,
      name: SearchType.tasks,
      label: `Task Filters (${getFilterCount(SearchType.tasks)})`,
    },
  ];

  const [selectedTab, setSelectedTab] = useState(tabs.filter(item => item.name === category)[0]?.index);

  const handleChange = (event: React.ChangeEvent<{}>, newValue) => {
    const tab = tabs.filter(item => item.index === newValue)[0].name;
    setGridName(tab);
    const currentGrid = gridPreferences.filter(item => item.id === `GlobalSearchGrid-${tab}`)[0]?.value;
    const filters = currentGrid?.filterAttributes;
    setFilters(filters);
    if (currentGrid?.selectedFilter) {
      setSelectedFilter(currentGrid?.selectedFilter);
    }
    setSelectedTab(newValue);
    setGridId(`GlobalSearchGrid-${tab}`);
  };

  const handleFilter = (panel: string) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleFilterSetNameChange = (index, newName: string) => {
    if (newName?.length > 30) return;
    const duplicate = !!filter?.filterSet.find(
      (item, ix) => index !== ix && trim(item.originalName)?.toLowerCase() === trim(newName)?.toLowerCase(),
    );
    let selectedFilterTemp = selectedFilter;
    filter?.filterSet.map((item, i) => {
      if (i === index && selectedFilterTemp.name === item.originalName && newName !== item.originalName) {
        selectedFilterTemp = {
          name: newName,
          value: item.value,
          isDefault: item.isDefault,
        };
      }
    });
    const filterAttributes = {
      ...filter,
      filterSet: filter?.filterSet.map((item, ix) =>
        ix === index
          ? {
              ...item,
              name: newName,
              modified: newName !== item.originalName,
              errorText: duplicate ? 'Name Already In Use' : newName ? '' : 'Name Must Be Given',
            }
          : item,
      ),
    };

    setGridPreferences(
      gridPreferences.map(item =>
        item.id === gridId
          ? {
              ...item,
              value: {
                ...item?.value,
                filterAttributes: filterAttributes,
                selectedFilter: selectedFilterTemp,
              },
            }
          : item,
      ),
    );
    setFilters(filterAttributes);
    setExpanded(newName);
  };

  const setDeleted = (index, state) => {
    const filterAttributes = {
      ...filter,
      filterSet: filter?.filterSet.map((item, ix) =>
        ix === index
          ? {
              ...item,
              deleted: state,
            }
          : item,
      ),
    };
    setGridPreferences(
      gridPreferences.map(item =>
        item.id === gridId
          ? {
              ...item,
              value: {
                ...item?.value,
                filterAttributes: filterAttributes,
              },
            }
          : item,
      ),
    );
    setFilters(filterAttributes);
  };

  const hasChanges = () => {
    return !!gridPreferences.find(item =>
      item.value?.filterAttributes?.filterSet?.find(item => item.modified || item.deleted),
    );
  };

  const hasErrors = () => {
    return !!gridPreferences.find(item =>
      item.value?.filterAttributes?.filterSet?.find(item => item.errorText?.length > 0),
    );
  };

  const handleSaveFilters = () => {
    setSessionValue(SessionKey[`GlobalSearchGrid-${gridName}`], { stopCache: true });
    setSessionValue(SessionKey[`GlobalSearchGrid-${gridName}Save`], {});
    const modifiedGridPreferences = gridPreferences.filter(item =>
      item.value?.filterAttributes?.filterSet?.find(item => item.modified || item.deleted),
    );
    dispatch(
      userDevicePreferenceActions.saveFilterPreferenceAction(
        userPreference?.map(item => {
          const modifiedGridPreference = modifiedGridPreferences?.find(mgp => mgp.id === item.id);
          if (modifiedGridPreference) {
            const modifiedFilterSet = modifiedGridPreference.value?.filterAttributes?.filterSet;
            /** TODO: Account for deleting all filters at once - use Absolute Defaults */
            const selectedFiter = modifiedGridPreference.value.selectedFilter;
            return {
              ...item,
              value: {
                ...item.value,
                selectedFilter: selectedFiter,
                filterAttributes: {
                  ...item.value?.filterAttributes,
                  filterSet: item.value?.filterAttributes?.filterSet
                    ?.map(item => ({ ...item, name: modifiedFilterSet?.find(i => i.originalName === item.name)?.name }))
                    ?.filter(item => !modifiedFilterSet?.find(i => i.originalName === item.name && i.deleted)),
                },
              },
            };
          }
          return item;
        }),
      ),
    );
    dispatch(
      globalActions.setSnackBar({
        message: 'Filters updated successfully!',
        severity: 'success',
      }),
    );
    props.onClose();
  };

  const handleModalOpen = item => {
    setFilterSet(item);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleApply = () => {
    const gridPreferences = userPreference.filter(values => values.id.split('-')[1] === gridName)[0];
    const values = JSON.parse(JSON.stringify(gridPreferences)).value;

    values.selectedFilter = {
      name: filterSet.originalName,
      value: filterSet.value,
      isDefault: filterSet.isDefault,
    };
    if (!location.pathname.includes(gridName)) {
      if (gridName === 'tasks') {
        history.push({ pathname: `/${gridName}`, state: { navigateBack: true } });
      } else {
        history.push({ pathname: `/search/${gridName}`, state: { navigateBack: true } });
      }
      setSessionValue(SessionKey[`GlobalSearchGrid-${gridName}`], {
        name: filterSet.originalName,
        filters: filterSet.value,
        selectedFilter: filterSet.value,
      });
    } else {
      setSessionValue(SessionKey[`GlobalSearchGrid-${gridName}`], { stopCache: true });
    }
    setSessionValue(SessionKey[`GlobalSearchGrid-${gridName}Save`], {});

    setSessionValue(SessionKey.searchString, {});
    dispatch(
      userDevicePreferenceActions.saveUserGridPreferenceAction({
        id: gridPreferences.id,
        value: values,
      }),
    );

    dispatch(
      globalActions.setSnackBar({
        message: 'Filters applied successfully!',
        severity: 'success',
      }),
    );
    props.onClose();
  };

  return (
    <>
      <GenericDialog
        open={open}
        classes={{ paper: classes.dialog }}
        onClose={onClose}
        variant="blue"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        dialogTitleProps={{
          text: 'Manage Filters',
          closeButton: true,
        }}
        dialogContentProps={{
          className: classes.dialogContent,
        }}
        dialogActions={[
          {
            text: 'Cancel',
            variant: 'text',
            color: 'tertiary',
            useDialogOnCloseEvent: true,
          },
          {
            text: 'Save',
            variant: 'contained',
            color: 'primary',
            disabled: !hasChanges() || hasErrors(),
            onClick: handleSaveFilters,
          },
        ]}
      >
        <Box display="flex" className={classes.filterContent}>
          <Box className={classes.filterTabs}>
            <GenericTabs hidePanel hideBorder orientation={Orientations.vertical} tabs={tabs} onChange={handleChange}/>
          </Box>
          <div id="filter-tab-panel-container" className={classes.tabPanelWrapper}>
            <Box className={classes.tabPanel}>
              <Typography
                className={classes.title}
                style={filter?.filterSet?.length === 0 ? { color: 'grey' } : {}}
              >{`${CapitalizeFirstLetter(gridName)} Filters (${filter?.filterSet?.length || 0})`}</Typography>
              <Box className={classes.filters}>
                {tabs.map((tab, index) => (
                  <TabPanel value={selectedTab} index={tab.index} key={index}>
                    <Box>
                      {filter &&
                        filter?.filterSet?.map((item, index) => {
                          return (
                            <>
                              <Accordion
                                className={classes.accordion}
                                expanded={expanded === item.name}
                                onChange={handleFilter(item.name)}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1bh-content"
                                  id="panel1bh-header"
                                  className={item.deleted && classes.accordionDeletedBackground}
                                  classes={{ root: classes.accordionSummaryRoot }}
                                >
                                  <Grid container direction={'row'}>
                                    {item.deleted && (
                                      <Grid container className={classes.containerDeleted}>
                                        <Grid item className={classes.iconDeleted}>
                                          <img src={Warning} />
                                        </Grid>
                                        <Grid item className={classes.deletedText} xs={10}>
                                          {`This filter set will be deleted on `}
                                          <b>{`Save`}</b>
                                        </Grid>
                                        <Grid item xs={1}>
                                          <Button
                                            className={classes.buttonUndo}
                                            variant={'text'}
                                            disableElevation={true}
                                            onClick={e => {
                                              setDeleted(index, false);
                                              preventEventTransmission(e);
                                            }}
                                          >{`undo`}</Button>
                                        </Grid>
                                      </Grid>
                                    )}
                                    {!item.deleted && (
                                      <>
                                        <Grid item xs={11} className={classes.filterNameText}>
                                          <Typography
                                            className={
                                              expanded === item.originalName
                                                ? classes.filterNameText
                                                : classes.filterNameTitle
                                            }
                                          >
                                            {CapitalizeFirstLetter(item.originalName)}
                                          </Typography>
                                          {expanded === item.originalName && (
                                            <ArrowForwardIcon
                                              color="primary"
                                              onClick={() => handleModalOpen(item)}
                                              className={classes.arrowIcon}
                                            />
                                          )}
                                        </Grid>
                                        {expanded === item.originalName && (
                                          <Grid item xs={1}>
                                            <IconButton
                                              color="primary"
                                              onClick={e => {
                                                setDeleted(index, true);
                                                preventEventTransmission(e);
                                              }}
                                              size="small"
                                            >
                                              <DeleteOutlineIcon style={{ color: '#106FB9' }} />
                                            </IconButton>
                                          </Grid>
                                        )}
                                      </>
                                    )}
                                  </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Box className={classes.accordionDetails}>
                                    {item.deleted && (
                                      <Typography className={classes.deletedFilterNameTitle}>
                                        {CapitalizeFirstLetter(item.originalName)}
                                      </Typography>
                                    )}
                                    <TextField
                                      id="filled-basic"
                                      variant="filled"
                                      value={item.name}
                                      disabled={item.deleted}
                                      className={classes.filterName}
                                      error={item.errorText?.length > 0}
                                      helperText={item.errorText}
                                      placeholder={`Add Filter Name`}
                                      onChange={e => handleFilterSetNameChange(index, e.target?.value)}
                                    />
                                    <ChipsContainer
                                      chips={
                                        category !== SearchType.tasks
                                          ? generateChips(Object.fromEntries(item.value), t, gridName, true)
                                          : generateTaskChips(Object.fromEntries(item.value), true)
                                      }
                                    />
                                  </Box>
                                </AccordionDetails>
                              </Accordion>
                              {index + 1 !== filter.filterSet?.length && <Box className={classes.divider}></Box>}
                            </>
                          );
                        })}
                      {filter !== null && (filter === undefined || filter.filterSet?.length === 0) && (
                        <Box m={2} pl={10}>
                          <img src={EmptyState} />
                          <span className={classes.text}>{`No custom filters set for ${CapitalizeFirstLetter(
                            gridName,
                          )} page.`}</span>
                        </Box>
                      )}
                    </Box>
                  </TabPanel>
                ))}
              </Box>
            </Box>
          </div>
        </Box>
      </GenericDialog>
      <GenericDialog
        open={openModal}
        classes={{ paper: classes.modal }}
        onClose={handleModalClose}
        variant="white"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        dialogTitleProps={{
          text: 'Apply Filter?',
          closeButton: false,
        }}
        dialogContentProps={{
          className: classes.dialogContent,
        }}
        dialogActions={[
          {
            text: 'Cancel',
            variant: 'text',
            color: 'tertiary',
            useDialogOnCloseEvent: true,
          },
          {
            text: 'Yes',
            variant: 'contained',
            color: 'primary',
            disabled: false,
            onClick: handleApply,
          },
        ]}
      >
        <Typography className={classes.modalText}>
          {t('Applying this filer will close the Manage Filters Modal and redirect to the respective result page.')}
        </Typography>
      </GenericDialog>
    </>
  );
};

export default ManageFilter;
