import { Typography } from 'amn-ui-core';
import { missingField } from 'app/constants';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RequirementsRow } from '../../TableComponents/TableComponents';
import {
  selectEditableRequirementTypes,
  selectServiceFailures,
  selectSubmissionInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { usePromiseTracker } from 'react-promise-tracker';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { SSN } from 'app/components/Placement/PlacementDetails/Edit/Components/SSN';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import { TableSection } from '../../../customComponents/TableSection';
import { getRequirementCheck } from '../../../helper';
import { ff_placement_ui_requirement_check } from 'app/constants/FeatureFlags/Placement/Keys';
import { useDecision } from '@optimizely/react-sdk';

export const SocialSecurityNum = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [requirementCheckFlag] = useDecision(ff_placement_ui_requirement_check);
  const { orderSsn } = useSelector(selectEditableRequirementTypes);

  const { promiseInProgress: promiseInProgressOrderSsn } = usePromiseTracker({
    area: 'placement-submission-Ssn-order-requirement',
    delay: 0,
  });
  const { promiseInProgress } = usePromiseTracker({
    area: 'pplacement-submission-mice-details-order-requirement',
    delay: 0,
  });
  const {
    orderReq: {
      order: { orderSsn: orderSsnInfo },
    },
    requirementFilter,
  } = useSelector(selectSubmissionInfo);
  const { miceDetailsOrderRequirement: serviceFailed } = useSelector(selectServiceFailures);
  const { placementId } = useContext(AddOnContext);
  const { orderSsn: orderSsnValue } = useSelector(selectEditableRequirementTypes);

  const { display } = useRequirementFilter(
    'submission-order',
    requirementFilter,
    requirementCheckFlag.enabled && orderSsnInfo?.type ? orderSsn?.requirement : null,
  );

  return (
    <RequirementsRow
      id="placement-submission-order-ssn"
      title={t('placement.profile.summary.submission.order.socialSecurityNum.title')}
      requirementCell={
        orderSsnInfo?.type && requirementCheckFlag.enabled ? (
          <RowKey
            orderSsnValue={orderSsnValue?.requirement ?? -1}
            message={t('placement.profile.summary.submission.order.socialSecurityNum.line1')}
          />
        ) : (
          <Typography variant="body1">{missingField}</Typography>
        )
      }
      candidateCell={<SSN id="ssn-text-field-submission-order-req" />}
      loading={requirementCheckFlag.enabled ? promiseInProgressOrderSsn : promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() =>
        requirementCheckFlag.enabled
          ? dispatch(placementSummaryActions.getSsnStatusOrderRequirement({ placementId, isRetryingService: true }))
          : dispatch(placementSummaryActions.getMiscDetailsOrderRequirement({ placementId, isRetryingService: true }))
      }
      display={display}
    />
  );
};

const RowKey = ({ orderSsnValue, message }) => (
  <TableSection
    items={[
      {
        icon: !!orderSsnValue ? getRequirementCheck(orderSsnValue) : null,
        primaryContent: !!orderSsnValue ? message : missingField,
      },
    ]}
  />
);
