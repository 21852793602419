import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{
  isSelected: boolean;
  open: boolean;
  anchorRef: boolean;
}>()((theme, { isSelected, open, anchorRef }) => ({
  root: {},
  outlineAnchor: {
    borderRadius: 4,
    width: '100%',
    minHeight: '36.7px',
    justifyContent: 'space-between',
    textTransform: 'none',
    color: open
      ? theme.palette.system.violet
      : isSelected
      ? theme.palette.system.davyGray
      : theme.palette.system.dimGrey2,
    fontSize: 12,
    fontWeight: 400,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.system.white,
    },
    visibility: anchorRef ? 'hidden' : undefined,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    '&:hover': {
      borderColor: 'black',
    },
  },
  popper: {
    marginTop: 4,
    zIndex: 5000,
  },
  paperDropdown: {
    boxShadow: `0px 2px 8px ${theme.palette.system.semiTransparentBlack}`,
    width: '375px',
  },
  headerContainer: {
    minWidth: 250,
    padding: '12px 20px 6px',
  },
  headerContainerNoSearchBoxSingleOption: {
    minWidth: 250,
    padding: '12px 20px 6px',
  },
  actionContainer: {
    backgroundColor: theme.palette.system.white,
    borderTop: `1px solid ${theme.palette.system.silver}`,
    padding: '6px 20px',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  button: {
    fontSize: 14,
    textTransform: 'none',
    '&:hover, &:focus': {
      background: 'none',
    },
  },
  inActive: {
    color: theme.palette.framework.system.tertiaryGrey,
  },
  clearAllButton: {
    padding: 0,
  },
  actionButton: {
    padding: '6px 0px',
  },
  countTag: {
    color: theme.palette.system.davyGray,
    fontSize: 14,
    fontWeight: 600,
  },
  valueContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxWidth: '90%',
  },
  subCertValue: {
    color: theme.palette.framework.system.charcoal,
    marginTop: '2px',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));
