import { makeStyles } from 'tss-react/mui';

export const useStyes = makeStyles()(theme => ({
  normalText: {
    'font-weight': 'normal',
    width: '100%',
  },
  inlineBoxEditItemPadding: {
    padding: '0 0.4rem',
  },
  inlineBoxEditMainCheckItem: {
    'padding-left': '0',
    'padding-right': '0.2rem',
    '& img': {
      width: '25px',
      height: '25px',
    },
    '& svg': {
      width: '25px',
      height: '25px',
    },
  },
  inlineBoxEditMainLblItem: {
    paddingLeft: '3px',
  },
  yellowAlert: {
    marginLeft: 1,
  },
}));
