import { LayoutGridItem } from 'app/layout/LayoutGrid';
import React from 'react';
import { RowContainer } from 'app/components/Facility/FacilityDetails/FacilityTabs/CustomComponents/styles';
import { Row, RowHead, RowContent } from '../../ProfileTab/Cards/styles';

export const RowSectionTitle: React.FC<{
  header: any;
  data: any;
  isNormalRow?: boolean;
  handleClick: (property: string) => void;
}> = props => {
  return (
    <LayoutGridItem style={{ width: '100%' }}>
      <RowContainer>
        <Row>
          <RowHead style={{ wordBreak: 'keep-all' }}>{props.header}</RowHead>
          <RowContent
            onClick={() => {
              props.handleClick(props.data);
            }}
            style={{ paddingTop: 6, paddingBottom: 0 }}
          >
            {props.data}
          </RowContent>
        </Row>
      </RowContainer>
      {props.children}
    </LayoutGridItem>
  );
};
