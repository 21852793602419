import React, { useContext, useEffect } from 'react';
import { InlineEditableField } from 'app/components/Common/EditPage/InlineEditableField';
import { Controller, useFormContext } from 'react-hook-form';
import { addYears } from 'date-fns';
import { DrawerContext } from 'app/components/Common/Drawer/Drawer';
import moment from 'moment';
import { missingField } from 'app/constants';
import { DateRangePicker } from 'app/ComponentLibrary/DateRangeNew';
import { createPlacementTabIDs } from '../../helper';
import { CreatePlacementContext } from '../../CreatePlacementWrapper';
import { useSelector } from 'react-redux';
import { selectNewPlacementChoice } from '../../../../../../store/redux-store/new-placement/selectors';
import { isInitialStatus, isWFC } from '../../../NextPlacementStatus/StatusDefaults';
import { selectPlacementStatus } from 'store/redux-store/placement-status/selectors';

export const AvailableStartDate = ({ ...props }) => {
  const { control } = useFormContext();
  const { setPopperOpen } = useContext(DrawerContext);

  const { setCurrentFocusId } = useContext(CreatePlacementContext);

  const { candidate } = useSelector(selectNewPlacementChoice);
  const { currentPlacementStatus } = useSelector(selectPlacementStatus);

  useEffect(() => {
    return () => {
      setPopperOpen?.('availableStartDate', false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Controller
      control={control}
      name={'availabilityDate'}
      render={({ ref, onChange, ...rest }) => {
        const availableStart = moment(rest.value);
        return (
          <InlineEditableField
            id={createPlacementTabIDs.createPlacementAvailableStartDate}
            {...rest}
            inputProps={{
              size: 'inline',
              variant: 'outlined',
              initialDateRange: {
                startDate: availableStart.isValid() ? new Date(rest.value) : undefined,
                endDate: availableStart.isValid() ? new Date(rest.value) : undefined,
                useLabelAsValue: true,
              },
              maxDate: addYears(new Date(), 20),
              quickSelect: false,
              actionBar: false,
              single: true,
              onOpen: open => setPopperOpen?.('availableStartDate', open),
              useMaxWidth: true,
            }}
            label={availableStart.isValid() ? availableStart.format('MM/DD/YYYY') : missingField}
            Control={DateRangePicker}
            onChange={newValue => {
              if (newValue.startDate !== undefined) {
                const newDate = moment(newValue.startDate);
                const setTo = newDate.isValid() ? moment(newDate).format('YYYY-MM-DDTHH:mm:ss') : null;
                onChange(setTo);
              } else {
                onChange(null);
              }
              // Uncomment if you want to be able to clear the date
              // else onChange(null);
            }}
            setCurrentFocus={() => setCurrentFocusId(createPlacementTabIDs.createPlacementAvailableStartDate)}
            disabled={!candidate || !(isWFC(currentPlacementStatus) || isInitialStatus(currentPlacementStatus))}
          />
        );
      }}
    />
  );
};
