import React, { useState } from 'react';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { Grid, Typography } from 'amn-ui-core';
import { useFormContext } from 'react-hook-form';
import { Tabs } from '../Common/SendToButtonGroup';

const useStyles = makeStyles<{ isExpanded: boolean }>()((theme, { isExpanded = false }) => ({
  title: {
    fontSize: 20,
  },
  body: {
    '& table': {
      maxWidth: '100% !important',
      width: '100% !important',
      height: !isExpanded ? '48vh' : '54vh',
    },
    '& td.container-padding': {
      paddingBottom: '0 !important',
    },
  },
  modalContent: {
    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .dialogContentRoot': {
      padding: 0,
    },
    overflow: 'hidden',
  },
  dialogContent2: {
    fontSize: '15px',
    overflow: 'hidden',
  },
  recipientGrid: {
    padding: '16px 24px',
    borderBottom: `1px solid ${theme.palette.system.borderColor1}`,
  },
  key: {
    color: theme.palette.system.fadedGrey,
    fontSize: '1rem',
    paddingBottom: '2%',
  },
  value: {
    color: theme.palette.system.doveGray,
    paddingRight: '2%',
    fontSize: '1rem',
    paddingBottom: '2%',
  },
  scrollBody: {
    overflowY: 'auto',
    height: !isExpanded ? 'calc(100vh - 280px)' : 'calc(100vh - 120px)',
    padding: '10px 10px 0px 10px',
  },
}));

interface IPreviewTemplateModal {
  open: boolean;
  onCancel: () => void;
  dialogTitleText: string;
  templateHtmlContent: string;
}

export const PreviewTemplateModal = ({
  open,
  onCancel,
  dialogTitleText,
  templateHtmlContent,
}: IPreviewTemplateModal) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { classes } = useStyles({ isExpanded });

  const handleExpandChange = (expanded: boolean) => setIsExpanded(expanded);
  const { watch } = useFormContext();
  const sendTo = watch('sendTo');

  const getSendToValues = type => {
    return sendTo?.filter(s => s?.tabSelected === type && s?.isChecked).map(i => i?.firstName + ' ' + i?.lastName);
  };

  const isSelected = () => {
    const selected = sendTo?.filter(s => s?.isChecked);
    return selected?.length > 0;
  };

  return (
    <GenericDialog
      open={open}
      draggable
      disablePortal
      disableEscapeKeyDown
      dialogTitleProps={{
        text: t('Preview: ').concat(dialogTitleText),
        expandable: true,
        classes: { root: classes.title },
        closeButton: true,
        onExpandChange: handleExpandChange,
      }}
      dialogContentProps={{
        classes: { root: classes.dialogContent2 },
      }}
      fullWidth={true}
      className={classes.modalContent}
      maxWidth={'md'}
      variant={'blue'}
      onClose={onCancel}
    >
      <div>
        {isSelected() && (
          <Grid className={classes.recipientGrid}>
            <Typography>
              {getSendToValues(Tabs.To)?.length > 0 && <span className={classes.key}>{`To: `}</span>}
              {<span className={classes.value}>{getSendToValues(Tabs.To)?.join(', ')}</span>}
              {getSendToValues(Tabs.CC)?.length > 0 && <span className={classes.key}>{`CC: `}</span>}
              {<span className={classes.value}>{getSendToValues(Tabs.CC)?.join(', ')}</span>}
              {getSendToValues(Tabs.BCC)?.length > 0 && <span className={classes.key}>{`BCC: `}</span>}
              {<span className={classes.value}>{getSendToValues(Tabs.BCC)?.join(', ')}</span>}
            </Typography>
          </Grid>
        )}
        <Grid className={classes.scrollBody}>
          <div className={classes.body} dangerouslySetInnerHTML={{ __html: templateHtmlContent }}></div>
        </Grid>
      </div>
    </GenericDialog>
  );
};
