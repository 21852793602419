/* eslint-disable tss-unused-classes/unused-classes */
import { GridCellParams } from '@mui/x-data-grid-pro';
import { List, ListItem, ListItemAvatar, ListItemText, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { Concatenate } from 'utils/string/string';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export const AgeCompetencyCell = params => <AgeCompetencyContent params={params} />;

const AgeCompetencyContent = ({ params }: { params: GridCellParams }) => {
  const [displayValue, setDisplayValue] = useState('');
  const ageCompetencies = (params.row[params.field] ?? []) as any[];

  useEffect(() => {
    const ages = ageCompetencies.map(item => item.ageType);
    setDisplayValue(Concatenate(ages, ', '));
  }, [ageCompetencies]);

  return <div>{displayValue}</div>;
};

export const AgeCompetencyHoverCell = params => <AgeCompetencyHoverContent params={params} />;

const AgeCompetencyHoverContent = ({ params }: { params: GridCellParams }) => {
  const { classes: listClasses } = useListStyle();
  const ageCompetencies = (params.row[params.field] ?? []) as any[];

  return (
    <List disablePadding classes={{ root: listClasses.root }}>
      {ageCompetencies.map((item, i) => (
        <ListItem key={i} disableGutters classes={{ root: listClasses.listItemRoot }}>
          <ListItemAvatar classes={{ root: listClasses.avatar }}>
            <CheckCircleOutlineIcon fontSize="small" />
          </ListItemAvatar>
          <ListItemText classes={{ root: listClasses.itemText }}>
            {<Typography variant="body2">{item.ageType}</Typography>}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

const useListStyle = makeStyles()({
  root: {
    padding: 6,
  },
  listItemRoot: {
    padding: 0,
  },
  itemText: {
    marginTop: '8px',
  },
  avatar: {
    minWidth: 24,
  },
});
