import React from 'react';
import { Box } from 'amn-ui-core';
import FunnelIconOutline from 'app/assets/images/IconImages/FunnelIconOutline.svg';
import FunnelIconWithBlueOutLine from 'app/assets/images/IconImages/FunnelIconWithBlueOutLine.svg';

interface PropType {
  iconStyle?: any;
  toggle?: boolean;
}
export const FunnelButton = ({ iconStyle, toggle }: PropType) => {
  return (
    <Box>
      <img
        style={iconStyle ? iconStyle : { height: 36, width: 39 }}
        alt="funnel-icon"
        src={toggle ? FunnelIconWithBlueOutLine : FunnelIconOutline}
      />
    </Box>
  );
};
