/* eslint-disable @typescript-eslint/dot-notation */
import { Box, Button, CircularProgress, Grid, Skeleton, Typography } from 'amn-ui-core';
import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { ActionModal } from '../customComponents';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { CustomTooltip, OverflowTooltip } from '@AMIEWEB/Common/Tooltips';
import {
  IContractDoc,
  ContractStatus,
  DocSource,
} from 'store/redux-store/placement-candidate-contract-doc-editor/types';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDocumentView,
  selectDocStatusExists,
} from 'store/redux-store/placement-candidate-contract-doc-editor/selector';
import { pContractDocEditorActions } from 'store/redux-store/placement-candidate-contract-doc-editor/slice';
import { usePromiseTracker } from 'react-promise-tracker';
import { StatusBubble, UpdateWarningFlag } from '../customComponents/DocumentCard';
import { selectUser } from 'oidc/user.selectors';
import { isCandidateContractUser } from 'oidc/userRoles';
import { CustomTooltipProps } from '@AMIEWEB/Common/Tooltips/CustomTooltip';
import styled from 'styled-components';
import { placementStatusAction } from 'store/redux-store/placement-status/slice';
import { PromiseTrackerKeys } from 'app/constants/PromiseTrackerKeys';
import { placementSummaryActions } from 'store/redux-store/placement-summary/slice';
import { selectPlacementDetails } from 'store/redux-store/placement-details/selectors';

const useStyles = makeStyles()(theme => ({
  container: {
    padding: '0 12px 12px 0',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  titleRoot: {
    display: 'flex',
    flexDirection: 'row',
    gap: 6,
    alignItems: 'center',
  },
  titleSkeleton: {
    width: `calc(100% - ${theme.spacing(2)})`,
    /**
     * Code: marginBottom of 17 instead of 12px -
     * ensure section doesn't hop vertically when switching to loader
     */
    marginBottom: 17,
  },
  title: {
    color: theme.palette.system.darkGray,
    fontWeight: 450,
    fontSize: 16,
    whiteSpace: 'pre',
  },
  subTitle: {
    color: theme.palette.text.primary,
    fontSize: 14,
    maxWidth: 'inherit',
  },
  noTemplateSelected: {
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0px',
    color: theme.palette.system.lightGrey,
    opacity: 1,
    padding: '2px 0 6px 0',
  },
  btnContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'end',
  },
  secondaryBtn: {
    backgroundColor: theme.palette.system.buttonGray,
    ':hover': {
      backgroundColor: theme.palette.system.buttonDarkGray,
    },
  },
}));

export const EngineTemplateTitle: React.FC<{
  isTemplateSelected: boolean;
  templateTitle: string | null;
  isDirty: boolean;
  userIsReadOnly: boolean;
  onCancel: (callbackFn, ...args) => void;
  onSave: () => void;
  onCreate: () => void;
  templates: IContractDoc[];
  selectedTemplate: IContractDoc;
  forceModalCloseFlag?: boolean;
  [key: string]: any;
}> = ({
  isTemplateSelected,
  templateTitle,
  isDirty,
  userIsReadOnly,
  onCancel,
  onSave,
  onCreate,
  templates = [],
  selectedTemplate,
  forceModalCloseFlag,
  ...props
}) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { placementId } = useParams<{ placementId: string }>();

  const { amieWebDraftExists, classicDraftExists, amieWebCreatedExists } = useSelector(selectDocStatusExists);
  const { renderPreviewScreen, document } = useSelector(selectDocumentView);
  const { placementOrderType } = useSelector(selectPlacementDetails);
  const { userInfo } = useSelector(selectUser);

  // @ts-ignore
  const [modalProps, setModalProps] = React.useState<IActionModalProps>({ open: false });
  // @ts-ignore
  const closeModal = React.useCallback(() => setModalProps({ open: false }), []);

  const dispatch = useDispatch();

  const { promiseInProgress: isPullingPdfDoc } = usePromiseTracker({
    area: PromiseTrackerKeys.placements.requestPdfDoc,
  });
  const { promiseInProgress: isSavingingDraft } = usePromiseTracker({ area: PromiseTrackerKeys.placements.saveDraft });
  const { promiseInProgress: isDeletingDraft } = usePromiseTracker({ area: PromiseTrackerKeys.placements.deleteDraft });
  const { promiseInProgress: isCreatingDoc } = usePromiseTracker({
    area: PromiseTrackerKeys.placements.createDocument,
  });
  const { promiseInProgress: isPublishingDoc } = usePromiseTracker({
    area: PromiseTrackerKeys.placements.publishDocument,
  });
  const { promiseInProgress: isVoidingDoc } = usePromiseTracker({ area: PromiseTrackerKeys.placements.voidDocument });

  const handleCreate = React.useCallback(() => {
    if (classicDraftExists) {
      setModalProps({
        open: true,
        title: t('templateEditor.modal.title.overrideClassicDraft'),
        message: t('templateEditor.modal.message.overrideClassicDraft'),
        primaryBtns: [
          {
            text: t('cancelModal.confirmation.yes'),
            onClick: () => {
              onCreate?.();
              closeModal();
            },
          },
        ],
        secondaryBtns: [
          {
            text: t('cancelModal.confirmation.cancel'),
            onClick: closeModal,
          },
        ],
      });
      return;
    }
    onCreate?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classicDraftExists, onCreate]);

  useEffect(() => {
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceModalCloseFlag]);

  const handleDeleteDraft = React.useCallback(() => {
    setModalProps({
      open: true,
      title: t('templateEditor.modal.title.deleteDraft'),
      message: t('templateEditor.modal.message.deleteDraftAction'),
      primaryBtns: [
        {
          text: t('cancelModal.confirmation.yes'),
          onClick: () => {
            dispatch(pContractDocEditorActions.deleteContractDraft({ placementId: parseInt(placementId) }));
            closeModal();
          },
        },
      ],
      secondaryBtns: [
        {
          text: t('cancelModal.confirmation.cancel'),
          onClick: closeModal,
        },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amieWebDraftExists, classicDraftExists, placementId]);

  const handleDeleteDoc = React.useCallback(() => {
    setModalProps({
      open: true,
      title: t('templateEditor.modal.title.deleteCandidateContract'),
      message: t('templateEditor.modal.message.deleteCandidateContract'),
      primaryBtns: [
        {
          text: t('cancelModal.confirmation.yes'),
          onClick: () => {
            dispatch(
              pContractDocEditorActions.deleteContractDoc({
                placementId: parseInt(placementId),
                contractId: selectedTemplate?.id,
              }),
            );
            closeModal();
          },
        },
      ],
      secondaryBtns: [
        {
          text: t('cancelModal.confirmation.cancel'),
          onClick: closeModal,
        },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placementId, selectedTemplate?.documentId]);

  const isUserDisabled = React.useMemo(
    () => userIsReadOnly || !isCandidateContractUser(userInfo?.roles ?? []),
    [userInfo?.roles, userIsReadOnly],
  );

  const isCallingAPI = React.useMemo(
    () => isDeletingDraft || isCreatingDoc || isPublishingDoc || isVoidingDoc || isSavingingDraft,
    [isDeletingDraft, isCreatingDoc, isPublishingDoc, isVoidingDoc, isSavingingDraft],
  );

  const deleteBtn = React.useMemo(
    () => ({
      children: t('templateEditor.delete'),
      disabled:
        isUserDisabled || isCallingAPI
          ? true
          : ![ContractStatus.draft, ContractStatus.created].includes(selectedTemplate?.status) || renderPreviewScreen,
      onClick: isDeletingDraft
        ? undefined
        : selectedTemplate?.status === ContractStatus.draft
        ? handleDeleteDraft
        : handleDeleteDoc,
      startIcon: isDeletingDraft ? (
        <CircularProgress sx={{ color: theme => theme.palette.system.primary }} size={12} />
      ) : undefined,
      tooltipProps: {
        disabled: !isUserDisabled,
        tooltipContent: t('templateEditor.disableToolTip.accessRoleRestriction'),
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      handleDeleteDoc,
      handleDeleteDraft,
      isUserDisabled,
      renderPreviewScreen,
      selectedTemplate?.status,
      isDeletingDraft,
      isCallingAPI,
    ],
  );

  const saveBtn = React.useMemo(
    () => ({
      children: t('templateEditor.save'),
      /** Enabled for a new template, as well as if dirty otherwise: Disabled if user is readonly  */
      disabled: isUserDisabled || isCallingAPI ? true : !isDirty || renderPreviewScreen,
      onClick: isCallingAPI ? undefined : onSave,
      startIcon: isSavingingDraft ? (
        <CircularProgress sx={{ color: theme => theme.palette.system.primary }} size={12} />
      ) : undefined,
      tooltipProps: {
        disabled: !isUserDisabled,
        tooltipContent: t('templateEditor.disableToolTip.accessRoleRestriction'),
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDirty, isUserDisabled, onSave, renderPreviewScreen, isSavingingDraft, isCallingAPI],
  );

  const createBtn = React.useMemo(
    () => {
      const disabled = isUserDisabled ? true : amieWebCreatedExists || !isTemplateSelected;
      return {
        children: t('templateEditor.create'),
        disabled: disabled || isCallingAPI,
        onClick: isCallingAPI ? undefined : handleCreate,
        startIcon: isCreatingDoc ? (
          <CircularProgress sx={{ color: theme => theme.palette.system.primary }} size={12} />
        ) : undefined,
        tooltipProps: {
          disabled: !disabled,
          tooltipContent: isUserDisabled
            ? t('templateEditor.disableToolTip.accessRoleRestriction')
            : amieWebCreatedExists
            ? t('templateEditor.disableToolTip.createdContractExists')
            : null,
        },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleCreate, isTemplateSelected, isUserDisabled, isCreatingDoc, isCallingAPI],
  );

  const publishBtn = React.useMemo(
    () => {
      const pId = parseInt(placementId);
      return {
        children: t('templateEditor.publish'),
        disabled: isUserDisabled || isCallingAPI,
        tooltipProps: {
          disabled: !isUserDisabled,
          tooltipContent: t('templateEditor.disableToolTip.accessRoleRestriction'),
        },
        onClick: () =>
          dispatch(
            pContractDocEditorActions.publishContractDoc({
              placementId: pId,
              contractId: selectedTemplate?.id,
            }),
          ),
        startIcon: isPublishingDoc ? (
          <CircularProgress sx={{ color: theme => theme.palette.system.primary }} size={12} />
        ) : undefined,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTemplate?.id, isUserDisabled, isPublishingDoc, isCallingAPI],
  );

  const downloadBtn = React.useMemo(
    () => ({
      disabled: isCallingAPI,
      children: t('templateEditor.download'),
      onClick: () => dispatch(pContractDocEditorActions.business_DownloadSelectedContractDoc()),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCallingAPI],
  );

  const voidBtn = React.useMemo(
    () => {
      const pId = parseInt(placementId);
      return {
        children: t('templateEditor.void'),
        disabled: isUserDisabled || isCallingAPI,
        tooltipProps: {
          disabled: !isUserDisabled,
          tooltipContent: t('templateEditor.disableToolTip.accessRoleRestriction'),
        },
        onClick: () =>
          dispatch(
            pContractDocEditorActions.voidContractDoc({
              placementId: pId,
              contractId: selectedTemplate?.id,
            }),
          ),
        startIcon: isVoidingDoc ? (
          <CircularProgress sx={{ color: theme => theme.palette.system.primary }} size={12} />
        ) : undefined,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTemplate?.id, isUserDisabled, isVoidingDoc, isCallingAPI],
  );

  const cancelBtn = React.useMemo(
    () => ({
      children: t('templateEditor.cancel'),
      disabled: !isTemplateSelected || renderPreviewScreen || isCallingAPI,
      classes: { containedSecondary: classes.secondaryBtn },
      color: 'secondary',
      onClick: () =>
        setModalProps({
          open: true,
          title: t('cancelModal.undoConfirmation'),
          message: t('templateEditor.modal.message.discardTemplateChanges'),
          primaryBtns: [
            {
              text: t('cancelModal.confirmation.yes'),
              onClick: () => {
                onCancel(closeModal, { resetContacts: true });
              },
            },
          ],
          secondaryBtns: [
            {
              text: t('cancelModal.confirmation.cancel'),
              onClick: closeModal,
            },
          ],
        }),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isTemplateSelected, onCancel, renderPreviewScreen, isCallingAPI],
  );

  const closeBtn = React.useMemo(
    () => ({
      disabled: isCallingAPI,
      children: t('templateEditor.close'),
      classes: { containedSecondary: classes.secondaryBtn },
      color: 'secondary',
      onClick: () => {
        const searchParams = new URLSearchParams(history.location.search);
        searchParams.delete('view');
        searchParams.delete('default_selection');
        searchParams.delete('documentId');
        searchParams.delete('source');
        history.push({ ...history.location, search: searchParams.toString() });

        /** Code: Refresh WhatsNext Container */
        dispatch(placementStatusAction.getPlacementStatusDetails({ placementId: props.placementId }));
        dispatch(placementSummaryActions.getBookingRequirement({ placementId: props.placementId, placementOrderType }));
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, props.placementId, isCallingAPI],
  );

  const btnRender = React.useMemo(() => {
    const btns: {
      children: string;
      disabled: boolean;
      onClick: () => void;
      startIcon?: JSX.Element;
      classes?: any;
      color?: 'secondary';
      tooltipProps?: CustomTooltipProps;
    }[] = customSwitch({
      [ContractStatus.draft]: [deleteBtn, saveBtn, createBtn],
      [ContractStatus.created]:
        selectedTemplate?.source === DocSource.web ? [deleteBtn, publishBtn, downloadBtn] : [downloadBtn],
      [ContractStatus.published]: selectedTemplate?.source === DocSource.web ? [voidBtn, downloadBtn] : [downloadBtn],
      [ContractStatus.publishedVoid]: [downloadBtn],
      [ContractStatus.signed]: [downloadBtn],
      [ContractStatus.pending]: [],
      [ContractStatus.requestContract]: [],
      [ContractStatus.okToSend]: [],
    })([])(selectedTemplate?.status);

    // @ts-ignore
    btns.unshift(isDirty ? cancelBtn : closeBtn);
    return btns;
  }, [
    cancelBtn,
    closeBtn,
    createBtn,
    deleteBtn,
    downloadBtn,
    isDirty,
    publishBtn,
    saveBtn,
    selectedTemplate?.source,
    selectedTemplate?.status,
    voidBtn,
  ]);

  const titleDisplay = React.useMemo(
    () =>
      !isTemplateSelected ? (
        <Typography className={classes.noTemplateSelected}>{t('templateEditor.noDocSelection')}</Typography>
      ) : (
        <div className={classes.titleRoot}>
          <StatusBubble
            status={renderPreviewScreen ? ContractStatus.draft : selectedTemplate?.status}
          />
          <UpdateWarningFlag
            isDraftManualEdit={selectedTemplate?.isDraftManualEdit}
            updateDateString={selectedTemplate?.view?.cardUpdateDateString}
          />
          <div className={classes.title}>{templateTitle}</div>
          {document?.documentId ? (
            <>
              <LeftBracket className={classes.subTitle} />
              {/* @ts-ignore */}
              <ResponsiveContainer numOfBtns={btnRender.length}>
                <OverflowTooltip value={document?.documentId} contentClass={classes.subTitle} />
              </ResponsiveContainer>
              <RightBracket className={classes.subTitle} />
            </>
          ) : null}
        </div>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      btnRender.length,
      document?.documentId,
      isTemplateSelected,
      renderPreviewScreen,
      selectedTemplate?.status,
      selectedTemplate?.isDraftManualEdit,
      selectedTemplate?.view?.cardUpdateDateString,
      templateTitle,
    ],
  );

  return isPullingPdfDoc ? (
    <Skeleton classes={{ root: classes.titleSkeleton }} />
  ) : (
    <Grid container direction="row" justifyContent="space-between" classes={{ container: classes.container }}>
      <Grid item classes={{ root: cx({ [classes.titleContainer]: isTemplateSelected }) }}>
        {titleDisplay}
      </Grid>
      <Grid item classes={{ root: classes.btnContainer }}>
        <Grid container direction="row" gap={2}>
          {btnRender.map(({ tooltipProps, ...btn }, index) =>
            !!tooltipProps ? (
              <CustomTooltip key={index.toString()} standardMargin {...tooltipProps}>
                <Button variant="contained" size="small" type="button" {...btn} />
              </CustomTooltip>
            ) : (
              <Button key={index.toString()} variant="contained" size="small" type="button" {...btn} />
            ),
          )}
        </Grid>
      </Grid>
      <ActionModal {...modalProps} />
    </Grid>
  );
};

const getIncrementMaxWidth = (numOfBtns, defaultWidth) =>
  customSwitch({
    4: `${defaultWidth}px`,
    3: `${defaultWidth + 95}px`,
    2: `${defaultWidth + 160}px`,
    1: '100%',
  })(`${defaultWidth}px`)(numOfBtns);

const ResponsiveContainer = styled(Box)`
  @media (max-width: 1520px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 255)};
  }
  @media (max-width: 1500px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 235)};
  }
  @media (max-width: 1480px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 215)};
  }
  @media (max-width: 1460px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 195)};
  }
  @media (max-width: 1440px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 175)};
  }
  @media (max-width: 1420px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 155)};
  }
  @media (max-width: 1400px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 135)};
  }
  @media (max-width: 1380px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 115)};
  }
  @media (max-width: 1360px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 95)};
  }
  @media (max-width: 1340px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 75)};
  }
  @media (max-width: 1320px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 50)};
  }
  @media (max-width: 1300px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 40)};
  }
  @media (max-width: 1280px) {
    max-width: 100%;
  }
`;

const LeftBracket = styled.span`
  margin-right: -6px;
  &:after {
    content: '(';
  }
`;

const RightBracket = styled.span`
  margin-left: -6px;
  &:after {
    content: ')';
  }
`;
