import React from 'react';
import { FilterCompTypes } from '../utils';
import ClearIcon from '@mui/icons-material/Clear';
import { useFormContext } from 'react-hook-form';
import { TypeAheadDropdown } from '../TypeAheadDropdown/TypeAheadDropdown';
import { DateRangePicker } from 'app/ComponentLibrary/DateRangeNew';
import _ from 'lodash';

export function NestedFilter({ items, removeItem, index, value, controlName, showClearIcon, nestedlabelPrefix }) {
  const { getValues, errors, clearErrors, setValue } = useFormContext();

  const result = items.map(item => {
    if (item.type === FilterCompTypes.TYPEAHEADDROPDOWN) {
      return (
        <TypeAheadDropdown
          key={index}
          onChange={newValue => {
            if (!newValue) return;
            const _values = _.cloneDeep(
              getValues(controlName) || {
                type: 'NestedFilter',
                operation: 'Or',
                nestedlabelPrefix: nestedlabelPrefix,
              },
            );
            _values.value = _values.value || [];
            _values.value[index] = _values.value[index] || {};
            _values.value[index][item.name] = {
              ...newValue,
              labelPrefix: item.labelPrefixV1,
              position: item.position,
              useLabelName: !!item.useLabelName,
            };
            _values.value = item.getValue ? item.getValue(_values.value, index) : _values.value;
            clearErrors(`${controlName}-${index}-${item.name}`);
            setValue(controlName, _values);
          }}
          apply={values => {
            if (!values) return;
          }}
          hasControlError={
            !!(
              errors &&
              errors[`${controlName}-${index}-${item.name}`] &&
              errors[`${controlName}-${index}-${item.name}`].message
            )
          }
          controlErrorText="(*) required"
          hideClearAll
          hideApply
          value={
            value && value.value && value.value[index] && value.value[index][item.name]
              ? value.value[index][item.name]
              : item.isMultiSelect
              ? []
              : null
          }
          fetchOptions={item.fetchOptionsFromNestedValue 
            ? async (request: any) => await item.fetchOptionsFromNestedValue(value, index) 
            : item.fetchOptions}
          {...item}
          isNested={true}
          name={`${controlName}-${item.name}`}
        />
      );
    } else if (item.type === FilterCompTypes.DATERANGEV2) {
      return (
        <DateRangePicker
          onChange={newValue => {
            const _values = _.cloneDeep(
              getValues(controlName) || {
                type: 'NestedFilter',
                operation: 'Or',
                nestedlabelPrefix: nestedlabelPrefix,
              },
            );
            _values.value = _values.value || [];
            _values.value[index] = _values.value[index] || {};
            _values.value[index][item.name] = { ...newValue, labelPrefix: item.labelPrefixV1, position: item.position };
            setValue(controlName, _values);
          }}
          trailingIconShow
          initialDateRange={
            value && value.value && value.value[index] && value.value[index][item.name]
              ? value.value[index][item.name]
              : null
          }
          keepClickAwayVal
          {...item}
          actionBar={false}
          name={`${controlName}-${item.name}`}
        />
      );
    }
  });
  if (showClearIcon)
    result.push(<ClearIcon style={{ cursor: 'pointer', marginTop: '0.75rem' }} onClick={() => removeItem()} />);

  return <div style={{ display: 'flex' }}>{result}</div>;
}
