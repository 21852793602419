import { FilterCompTypes } from 'app/ComponentLibrary/Filter/utils';

export enum amnCommunicationLogsName {
  channel = 'channel',
  initiatedBy = 'initiatedBy',
  logDateRange = 'logDateRange',
  userRole = 'userRoles',
}

export const getFilterSpecs = (channelOpts, userRoleOpts, initiatedByOpts, filtersFlag) => [
  {
    filterName: 'Communication Type',
    name: amnCommunicationLogsName.channel,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    options: getChannelOpts(channelOpts),
    isMultiSelect: true,
    isDisabled: filtersFlag
  },
  {
    filterName: 'Initiated By',
    name: amnCommunicationLogsName.initiatedBy,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    options: getOptions(initiatedByOpts),
    nonNullable: true,
    isDisabled:filtersFlag
  },
  {
    filterName: 'Date',
    name: amnCommunicationLogsName.logDateRange,
    type: FilterCompTypes.DATERANGEV2,
    variant: 'outlined',
    clickAway: true,
    secondaryLabel: 'Date Range',
    isDisabled:filtersFlag

  },
  {
    filterName: 'User Role',
    name: amnCommunicationLogsName.userRole,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    options: getOptions(userRoleOpts),
    isDisabled:filtersFlag
  },
];

const getChannelOpts = options =>
  options.map((opt, id) => ({
    id,
    name: opt,
    value: opt === 'Call' ? 'Voice' : opt === 'SMS' ? 'Conversation' : opt,
  }));

const getOptions = options =>
  options.map((opt, id) => ({
    id,
    name: opt,
    value: opt,
  }));

// const initiatedByOpts = [
//   {
//     id: 0,
//     name: 'All',
//     value: 'All',
//   },
//   {
//     id: 1,
//     name: 'Me',
//     value: 'Me',
//   },
// ];

export const defaultValues = {
  [amnCommunicationLogsName.channel]: [],
  [amnCommunicationLogsName.initiatedBy]: null,
  [amnCommunicationLogsName.userRole]: null,
  [amnCommunicationLogsName.logDateRange]: null,
};
