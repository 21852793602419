import { pageAttributesActions } from 'app/components/Placement/PlacementDetails/Edit/store/placementPageAttributes.redux';
import { TimeOffRequest } from 'app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/RequirementsAndQualifications/TimeOffRequest';
import { selectPlacementStatus } from 'store/redux-store/placement-status/selectors';
import { missingDate } from 'app/constants';
import { convertToFormat } from 'app/helpers/dateHelper';
import _ from 'lodash';
import moment from 'moment';
import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CreatePlacementContext } from '../../CreatePlacementWrapper';
import { createPlacementTabIDs } from '../../helper';
import { isWFC, isInitialStatus } from '../../../NextPlacementStatus/StatusDefaults';
import { selectNewPlacementChoice } from '../../../../../../store/redux-store/new-placement/selectors';
import styledComponent from 'styled-components';

export const PlacementTimeOffRequest = ({ ...props }) => {
  const { control, setValue, watch } = useFormContext();
  const dispatch = useDispatch();
  const { setCurrentFocusId } = useContext(CreatePlacementContext);
  const { candidate } = useSelector(selectNewPlacementChoice);
  const { currentPlacementStatus } = useSelector(selectPlacementStatus);

  const timeOffData = watch('requestedTimeOff');
  const { requestedTimeOff } = timeOffData;
  const onChipDelete = originalValue => timeOff => {
    const val: any[] = _.cloneDeep(originalValue);
    const index = (val as any[]).findIndex(
      obj => obj.startDate === timeOff.startDate && obj.endDate === timeOff.endDate,
    );
    val.splice(index, 1);
    setValue('requestedTimeOff', { ...timeOffData, requestedTimeOff: val}, { shouldDirty: true });
  };

  const onDateAdd = originalValue => (startDate, endDate) => {
    setValue(
      'requestedTimeOff',
      {
        ...timeOffData,
        requestedTimeOff: [
          ...originalValue,
          {
            startDate: convertToFormat(startDate, 'yyyy-MM-DDT00:00:00'),
            endDate: convertToFormat(endDate, 'yyyy-MM-DDT00:00:00'),
          },
        ].sort((v1, v2) => {
          const p1 = moment(!v1.startDate ? missingDate : v1.startDate);
          const p2 = moment(!v2.startDate ? missingDate : v2.startDate);
          return p1 < p2 ? -1 : p1 > p2 ? 1 : 0;
        }),
      },
      { shouldDirty: true },
    );
  };

  const setPageEditAttribute = open => () => {
    dispatch(pageAttributesActions.setPopperOpen(open));
    if (!open) {
      document.getElementById(createPlacementTabIDs.createPlacementRequestedTimeOff)?.focus();
    }
  };

  return (
    <Controller
      control={control}
      name="requestedTimeOff"
      render={({ ref, onChange, ...rest }) => (
        <TimeOffRequest
          data={requestedTimeOff}
          onDateAdd={onDateAdd(requestedTimeOff)}
          onChange={() => {}}
          onChipDelete={onChipDelete(requestedTimeOff)}
          id={createPlacementTabIDs.createPlacementRequestedTimeOff}
          onOpen={() => {
            setPageEditAttribute(true);
            setCurrentFocusId(createPlacementTabIDs.createPlacementRequestedTimeOff);
          }}
          onClose={setPageEditAttribute(false)}
          // id={`${editAccessor}-${editableFields.requestedTimeOff}`}
          arrow={false}
          placement="top-end"
          disableControl={!candidate || !(isWFC(currentPlacementStatus) || isInitialStatus(currentPlacementStatus))}
          LabelRenderer={<KeyCell>{'Requested Time Off'}</KeyCell>}
        />
      )}
    />
  );
};

const KeyCell = styledComponent.span`
  color: #333333;
  font-size: 13px;
  font-weight: 400;
`;
