import { IConfirmationDoc, IPlacementClientConfirmationDocEditor } from './types';
import { initialState as editorInitialState } from '../confirmation-template-editor/utils';

export const initialState: IPlacementClientConfirmationDocEditor = {
  ...editorInitialState,
  documents: [],
  facilityDefaultTemplate: null,
  contacts: [],
  editableContacts: [],
  hasDraftTemplate: false,
  pageAttributes: {
    sidePanel: 'templates',
  }
};

export function instanceOfConfirmationDoc(object): object is IConfirmationDoc {
  return object && typeof object === 'object' && 'documentId' in object && 'version' in object;
}
