import { IRequirementRowPadding } from 'app/models/Placement/PlacementSummary';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import {
  selectBookingInfo,
  selectEditableFields,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { ConfirmationCheckbox } from '../../TableComponents/Confirmations/ConfirmationCheckbox';
import { ConfirmationsRow } from '../../TableComponents/Confirmations/ConfirmationsTable';

export const Arrived = ({ hasRequirements }: IRequirementRowPadding) => {
  const { t } = useTranslation();
  const { arrived: initialValue } = useSelector(selectEditableFields);
  const {
    confirmation: { arrived },
    requirementFilter,
  } = useSelector(selectBookingInfo);
  const { control } = useFormContext();

  const { display } = useRequirementFilter('booking-confirmation', requirementFilter, arrived?.type);

  return display ? (
    <Controller
      control={control}
      name="arrived"
      render={({ ref, ...rest }) => (
        <ConfirmationsRow
          id="placement-booking-confirmation-arrived"
          requirement={arrived?.type}
          hasOtherRequirements={hasRequirements}
          lastUpdatedBy={rest?.value?.lastUpdatedBy}
          lastUpdatedDate={rest?.value?.lastUpdatedAt}
          controlComponent={
            <ConfirmationCheckbox
              label={t('placement.profile.summary.booking.confirmations.arrived')}
              defaultState={initialValue}
              disabled
              {...rest}
            />
          }
        />
      )}
    />
  ) : null;
};
