/**
 * Create the store with dynamic reducers
 */

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createInjectorsEnhancer, forceReducerReload } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
import { batchedSubscribe } from 'redux-batched-subscribe';
import debounce from 'lodash.debounce';

import { createReducer } from './reducers';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { CandidateService } from 'app/services/CandidateServices/candidate-service';
import { OrderService } from 'app/services/OrderServices/order-service';
import { CredentialingService } from 'app/services/CredentialingServices/credentialing-service';
import { FacilityService } from 'app/services/FacilityServices/facility-service';
import { SharedService } from 'app/services/SharedServices/shared-service';
import { TaskService } from 'app/services/TaskServices/task-service';
import rootSaga from './redux-store/saga/rootSaga';
import { PlacementModsService } from 'app/services/PlacementModsServices/placementmods-service';
import { TemplateService } from 'app/services/TemplateService/template-service';
import { CoverageService } from 'app/services/CoverageServices/coverage-service';
import { PlacementRequestService } from 'app/services/PlacementRequestService/placementRequestService';
import { NotificationService } from 'app/services/NotificationServices/notification-service';
import { MarginToolService } from 'app/services/MarginToolServices/margin-tool-service';
// import { loadUser } from 'redux-oidc';
// import { userManager } from '../oidc/userManager';

export function configureAppStore() {
  const candidateService = CandidateService.createInstance();
  const credentialingService = CredentialingService.createInstance();
  const facilityService = FacilityService.createInstance();
  const orderService = OrderService.createInstance();
  const placementService = PlacementService.createInstance();
  const sharedService = SharedService.createInstance();
  const taskService = TaskService.createInstance();
  const templateService = TemplateService.createInstance();
  const notificationService = NotificationService.createInstance();
  const placementModsService = PlacementModsService.createInstance();
  const coverageService = CoverageService.createInstance();
  const placementRequestService = PlacementRequestService.createInstance();
  const marginToolService = MarginToolService.createInstance();

  const reduxSagaMonitorOptions = {
    context: {
      candidateService,
      credentialingService,
      facilityService,
      orderService,
      placementService,
      sharedService,
      taskService,
      templateService,
      notificationService,
      placementModsService,
      coverageService,
      placementRequestService,
      marginToolService,
    },
  };
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with saga middleware
  const middlewares = [sagaMiddleware];
  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ];

  //Set up debounce function for global redux subscription notifications
  if (globalThis?.app?.env?.REACT_APP_RENDERING_DEBOUNCE) {
    const debounceNotify = debounce(notify => notify());
    //Add debounce enhancer to the front of the enhancer array, so it can set up hooks first
    enhancers.unshift(batchedSubscribe(debounceNotify));
  }

  const store = configureStore({
    reducer: createReducer(),
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: false,
        context: {
          candidateService,
          credentialingService,
          facilityService,
          orderService,
          placementService,
          sharedService,
          taskService,
          placementModsService,
          coverageService,
          autoTaskManagementService: placementRequestService,
          marginToolService,
        },
      }),
      ...middlewares,
    ],
    devTools: process.env.NODE_ENV !== 'production',
    enhancers,
  });

  sagaMiddleware.run(rootSaga);

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      forceReducerReload(store);
    });
  }

  return {
    store,
    candidateService,
    credentialingService,
    facilityService,
    orderService,
    placementService,
    sharedService,
    taskService,
    templateService,
    notificationService,
    placementModsService,
    coverageService,
    placementRequestService,
    marginToolService,
  };
}
