import { makeStyles } from 'tss-react/mui';
import { colors } from 'styles/styleVariables';
import { Theme } from 'amn-ui-core';

export const useInformationalHeaderStyles = makeStyles()((theme: Theme, props) => ({
  container: {
    flexWrap: 'nowrap',
    width: '100%',
  },
  orderHeaderContainer: {
    flexWrap: 'nowrap',
    width: '100%',
    marginLeft: '-2px',
    marginTop: '-1px',
  },
  alignCenterDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    margin: '0 12px',
  },
  rowContentDefaultColor: {
    color: '#555555 !important',
  },
  orderRowContentDefaultColor: {
    color: `${theme.palette.system.black} !important`,
  },
  rowHead: {
    fontSize: 12,
    fontWeight: 400,
    whiteSpace: 'nowrap',
  },
  headerLinkContent: {
    fontSize: 16,
    color: '#006FB9',
    'font-weight': '600',
  },
  link: {
    color: colors.amnBlue,
    textDecoration: 'underline',
    fontWeight: 450,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  statusOrange: {
    backgroundColor: '#f28d17 !important',
  },
  statusRed: {
    backgroundColor: '#cc0000 !important',
  },
  containerAvatar: {
    display: 'flex',
    justifyContent: 'start',
  },
  myAvatarAlignment: {
    display: 'flex',
    flexDirection: 'row',
  },
  detailsContainerWrap: {
    flexWrap: 'nowrap',
    width: '100%',
    paddingLeft: '1rem',
  },
  orderDetailsContainerWrap: {
    flexWrap: 'nowrap',
    width: '100%',
  },
  actionContainer: {
    backgroundColor: '#6699CC70',
    width: 'inherit',
    display: 'flex',
    padding: '7px',
    minHeight: 38,
    justifyContent: 'space-between',
    'margin-top': '0.6rem',
    'margin-bottom': '0.6rem',
    'border-radius': '0.2rem',
  },
  orderActionContainer: {
    backgroundColor: `${theme.palette.system.lightPaleBlue}`,
    width: 'inherit',
    display: 'flex',
    padding: '9px 12px 8px 12px',
    minHeight: 38,
    justifyContent: 'space-between',
    'margin-top': '6px',
    'border-radius': '0.2rem',
  },
  rowHeadCustom: {
    color: '#003E68',
    fontWeight: 600,
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    },
  },
  orderHeaderRowCustom: {
    color: `${theme.palette.system.deepBlue}`,
    fontWeight: 500,
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    },
  },
  rowContentCustom: {
    'font-weight': '600',
    'margin-top': '0',
    'padding-top': '0.12rem',
    fontSize: 14,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    },
  },
  orderRowContentCustom: {
    'font-weight': '500',
    'margin-top': '0',
    'padding-top': '0.12rem',
    fontSize: 14,
    color: `${theme.palette.system.black} !important`,
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    },
  },
  address: {
    color: theme.palette.system.black,
    fontWeight: 500,
    paddingTop: 3,
  },
  blueBarText: {
    fontSize: '14px !important',
    fontWeight: '500 !important',
    color: `${theme.palette.framework.system.doveGray} !important`,
  },
  headerInfo: {
    display: 'flex',
    margin: '12px 0 0 0px',
  },
  fieldContainer: {
    color: theme.palette.system.black,
    display: 'flex',
    alignItems: 'baseline',
    flexWrap: 'nowrap',
    paddingBottom: theme.spacing(1),
  },
  orderHeaderFieldContainer: {
    color: theme.palette.system.black,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    paddingBottom: theme.spacing(1),
  },
  fieldSection: {
    display: 'flex',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  fieldHead: {
    fontSize: 12,
    fontWeight: 600,
  },
  orderFieldHead: {
    fontSize: 12,
    fontWeight: 500,
  },
  fieldContent: {
    color: theme.palette.system.black,
    fontWeight: 400,
    fontSize: 14,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '250px !important',
  },
  fieldContentContainer: {
    marginLeft: '24px',
    overflow: 'hidden',
  },
  orderHeaderfieldContentContainer: {
    marginLeft: '9px',
    overflow: 'hidden',
  },
  headerPhoneNumber: {
    fontSize: 14,
    color: theme.palette.system.primary,
    fontWeight: '600',
    overflow: 'hidden',
  },
  circleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.system.paleBlue,
    },
  },
  OrderCircleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.system.paleBlue,
    },
  },
  unitDivider: {
    margin: '0 12px',
  },
  orderDivider: {
    margin: '0 24px',
    height: '115px',
  },

  dialogActionsRoot: {
    fontSize: '12px',
  },
  facilityName: {
    fontSize: '14px !important',
    color: `${theme.palette.primary.main}`,
    fontWeight: '500 !important',
    cursor: 'pointer',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
  },
  orderHeaderFaciltyName: {
    fontSize: '18px !important',
    color: `${theme.palette.system.navyBlue} !important`,
    cursor: 'pointer',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    fontWeight: '500 !important',
  },
  unitName: {
    fontSize: '18px !important',
    fontWeight: '600 !important',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
  },
  orderHeaderUnitName: {
    fontSize: '14px !important',
    color: `${theme.palette.system.navyBlue} !important`,
    wordWrap: 'break-word',
    cursor: 'pointer',
    fontWeight: '500 !important',
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    maxWidth: '100%',
  },
  contractAffiliateFieldContent: {
    color: theme.palette.system.black,
    fontWeight: '400 !important',
    fontSize: '14px !important',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    maxWidth: '250px !important',
  },
  circleContainerDisabled: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.system.lightGrey} !important`,
  },
  icon: { color: theme.palette.primary.main, width: '19px' },
  iconDisabled: { color: theme.palette.system.lightGrey, width: '19px' },
}));
