/* eslint-disable tss-unused-classes/unused-classes */
import { Divider, Skeleton, Typography } from 'amn-ui-core';
import { missingField } from 'app/constants';
import { selectUser } from 'oidc/user.selectors';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useSelector } from 'react-redux';
import { UnitCard } from '../../../Facility/FacilityDetails/FacilityTabs/CustomComponents/UnitCard';
import { useExternalJobQualificationStyles } from './Unit.styles';
import { useSelectedUnitDetails, useUnitStatusInActive } from './unitHelper';

const ExternalJobQualifications = () => {
  // #region hooks
  const { t } = useTranslation();
  const userData = useSelector(selectUser);
  const isAuthorized = Authorized(
    [userRoles.accountManagement, userRoles.accountManagement_TeamMember, userRoles.accountManagement_Leadership],
    userData.userInfo,
  );
  const selectedUnitDetail = useSelectedUnitDetails();
  const { classes: externalJobClasses } = useExternalJobQualificationStyles();
  const isUnitInActive = useUnitStatusInActive();
  const { promiseInProgress: isFacilityWithFilterDetailsLoading } = usePromiseTracker({
    area: 'get-facility-details-with-filters',
    delay: 0,
  });

  // #region skeletons
  const titleSkeleton = <Skeleton variant="rectangular" width={'30%'} height={18} />;

  const externalJdAndRequiredQualificationSkeleton = (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
      <Skeleton variant="rectangular" height={13} />
      <Skeleton variant="rectangular" height={13} width={'85%'} />
      <Skeleton variant="rectangular" height={13} width={'95%'} />
      <Skeleton variant="rectangular" height={13} width={'95%'} />
      <Skeleton variant="rectangular" height={13} width={'50%'} />
    </div>
  );

  const preferredQualificationSkeleton = (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
      <Skeleton variant="rectangular" height={13} />
      <Skeleton variant="rectangular" height={13} width={'85%'} />
    </div>
  );

  const internalNotesSkeleton = <Skeleton variant="rectangular" width={'10%'} height={18} />;
  // #endregion

  // #region sub-components
  const ScrollableContainer = ({ children }) => (
    <div style={{ maxHeight: '108px', overflowY: 'scroll', whiteSpace: 'pre-line' }}>{children}</div>
  );

  const externalJobDescriptionTitle = (
    <Typography className={externalJobClasses.subSectionHeading}>
      {t('facility.units.jobDetails.externalJobDescription')}
    </Typography>
  );

  const externalJobDescriptionValue = (
    <ScrollableContainer>
      <Typography sx={{ fontSize: '14px' }}>{selectedUnitDetail?.externalJobDescription || missingField}</Typography>
    </ScrollableContainer>
  );

  const requiredQualificationTitle = (
    <Typography className={externalJobClasses.subSectionHeading}>
      {t('facility.units.jobDetails.requiredQualification')}
    </Typography>
  );

  const requiredQualificationValue = (
    <ScrollableContainer>
      <Typography sx={{ fontSize: '14px' }}>{selectedUnitDetail?.requiredQualifications || missingField}</Typography>
    </ScrollableContainer>
  );

  const additionalRequirementQualificationTitle = (
    <Typography className={externalJobClasses.subSectionHeading}>
      {t('facility.units.jobDetails.additionalRequiredQualifications')}
    </Typography>
  );

  const additionalRequirementQualificationValue = (
    <ScrollableContainer>
      <Typography sx={{ fontSize: '14px' }}>
        {selectedUnitDetail?.additionalRequiredQualifications || missingField}
      </Typography>
    </ScrollableContainer>
  );

  const preferredQualificationTitle = (
    <Typography className={externalJobClasses.subSectionHeading}>
      {t('facility.units.jobDetails.preferredQualification')}
    </Typography>
  );

  const preferredQualificationValue = (
    <ScrollableContainer>
      <Typography sx={{ fontSize: '14px' }}>{selectedUnitDetail?.preferredQualifications || missingField}</Typography>
    </ScrollableContainer>
  );

  const internalNotesTitle = (
    <Typography className={externalJobClasses.subSectionHeading}>
      {t('facility.units.internalJobNotes.viewPageTitle')}
    </Typography>
  );

  const internalNotesValue = (
    <ScrollableContainer>
      <Typography sx={{ fontSize: '14px' }}>{selectedUnitDetail?.internalNotes || missingField}</Typography>
    </ScrollableContainer>
  );
  // #endregion

  return (
    <>
      <UnitCard
        title={`${t('facility.units.jobDetails.title')}`}
        rightIcon={true}
        showEditIcon={isAuthorized && !isUnitInActive}
        skeletonWidth={218}
        isDataLoading={isFacilityWithFilterDetailsLoading}
      >
        <div style={{ padding: '12px', display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {/** Required Qualifications */}
          <div>
            <div style={{ paddingBottom: '12px' }}>
              {isFacilityWithFilterDetailsLoading ? titleSkeleton : requiredQualificationTitle}
            </div>
            <div>
              {isFacilityWithFilterDetailsLoading
                ? externalJdAndRequiredQualificationSkeleton
                : requiredQualificationValue}
            </div>
          </div>

          <Divider />

          {/** Additional Required Qualifications */}
          <div>
            <div style={{ paddingBottom: '12px' }}>
              {isFacilityWithFilterDetailsLoading ? titleSkeleton : additionalRequirementQualificationTitle}
            </div>
            <div>
              {isFacilityWithFilterDetailsLoading
                ? preferredQualificationSkeleton
                : additionalRequirementQualificationValue}
            </div>
          </div>

          <Divider />

          {/** Preferred Qualifications */}
          <div>
            <div style={{ paddingBottom: '12px' }}>
              {isFacilityWithFilterDetailsLoading ? titleSkeleton : preferredQualificationTitle}
            </div>
            <div>
              {isFacilityWithFilterDetailsLoading ? preferredQualificationSkeleton : preferredQualificationValue}
            </div>
          </div>

          <Divider />

          {/** Internal Notes */}
          <div>
            <div style={{ paddingBottom: '12px' }}>
              {isFacilityWithFilterDetailsLoading ? titleSkeleton : internalNotesTitle}
            </div>
            <div>{isFacilityWithFilterDetailsLoading ? internalNotesSkeleton : internalNotesValue}</div>
          </div>

          <Divider />

          {/* External Job Details */}
          <div>
            <div style={{ paddingBottom: '12px' }}>
              {isFacilityWithFilterDetailsLoading ? titleSkeleton : externalJobDescriptionTitle}
            </div>
            <div>
              {isFacilityWithFilterDetailsLoading
                ? externalJdAndRequiredQualificationSkeleton
                : externalJobDescriptionValue}
            </div>
          </div>
        </div>
      </UnitCard>
    </>
  );
};

export default ExternalJobQualifications;
