import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { IPlacementRequirementKey } from 'app/models/Placement/PlacementDetails';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { trackPromise } from 'react-promise-tracker';
import { put, getContext, fork, call, select } from 'redux-saga/effects';
import { SetPlacementFailRiskRequirementMessage } from 'store/redux-store/new-placement/saga';
import { resetFailureStatus } from '../saga';
import { IPlacementSummaryRequest, placementSummaryActions } from '../slice';
import InitPlacementSummary, { IConsolidatedRequirementResponse } from '../init.types';
import { selectPlacementConsolidatedDetails } from '../consolidatedStoreSelectors';

const TrackArbitrationAgreementRequirement = (fn, ...args) =>
  trackPromise(fn(...args), 'arbitartion-agreement-requirement');

export function* requestArbitrationAgreementRequirements(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('arbitrationAgreementRequirements'));
    const placementService: PlacementService = yield getContext('placementService');
    if (action.payload?.arbitrationAgreementFlagEnabled) {
      let response;

      const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
      if(action.payload.isInitLoad && consolidatedDetails){
        response = {
          status: consolidatedDetails.summary?.assignment?.arbitrationStatusDetails?.status,
          data: consolidatedDetails.summary?.assignment?.arbitrationStatusDetails?.data,
        };
      }

      if (!response?.status || !response?.data) {
        response = yield call(
          TrackArbitrationAgreementRequirement,
          placementService.getArbitrationAgreementRequirements,
          action.payload.placementId,
        );
      }

      if (httpSuccess(response?.status)) {
        yield fork(common, response?.data);
      } else {
        yield put(
          placementSummaryActions.setServiceFailureByKey({
            key: 'arbitrationAgreementRequirements',
            failureStatus: true,
          }),
        );
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getArbitrationAgreementRequirements',
        area: 'src/store/redux-store/placement-summary/common-saga/arbitrationAgreementRequirement.ts',
      },
    });
  }
}

export function* processArbitrationAgreementRequirements(
  response: IConsolidatedRequirementResponse<any>,
  context: InitPlacementSummary,
) {
  try {
    if (!httpSuccess(response.status)) {
      context.setServiceFailureByKey({ key: 'arbitrationAgreementRequirements', failureStatus: true });
      return;
    }

    yield fork(common, response.data);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'processArbitrationAgreementRequirements',
        area: 'src/store/redux-store/placement-summary/common-saga/arbitrationAgreementRequirement.ts',
      },
    });
  }
}

function* common(data) {
  yield fork(SetPlacementFailRiskRequirementMessage, {
    payload: {
      messageId: data?.candidateRequirement?.messageId,
      version: data?.candidateRequirement?.messageVersion,
      passFailType: data.candidateRequirement.type,
      data: data?.candidateRequirement?.data,
      messageTypeId: IPlacementRequirementKey.arbitrationAgreement,
      tooltipTypeParam: undefined,
      nextStatusRequirementParam: data.nextStatusRequirement,
    },
    type: placementSummaryActions.setFailedRequirement.type,
  });
}
