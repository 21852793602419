/* eslint-disable tss-unused-classes/unused-classes */
import React from 'react';
import { Theme, Tooltip, TooltipProps } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';

const StyledTootltip = makeStyles<{ id: string }>()((theme: Theme, props) => ({
  arrow: {
    '&:before': {
      border: `1px solid ${theme.palette.system.borderColor}`,
    },
    color: theme.palette.components.tooltip.arrowColor,
  },
  tooltip: {
    minWidth: '4rem',
    maxWidth: props.id === 'task' ? '36rem' : '300px',
    fontFamily: 'Roboto',
    fontSize: '12px',
    lineHeight: '18px',
    color: theme.palette.framework.system.doveGray,
    backgroundColor: theme.palette.components.tooltip.backgroundColor,
    border: `1px solid ${theme.palette.system.borderColor}`,
  },
}));

export const WhiteTooltip = (props: TooltipProps, arrow?: boolean) => {
  const { classes } = StyledTootltip({ id: props.id });

  return <Tooltip  arrow={arrow} enterDelay={500} classes={classes} id={props.id} {...props} />;
};
