import { XGrid } from 'app/components/Common/XGrid/XGrid';
import { useClinicalQualificationsListStyles } from './ClinicalQualifications.style';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  clinicalQualificationsData,
  getDetailedGridTableColumns,
  getMainTableColumns,
  getVersionColumns,
} from '../Helpers/ClinicalQualificationsHelper';
import { XGridToolbar } from '@AMIEWEB/Common/XGrid/Toolbar/XGridToolbar';
import { AttachmentAction } from '../../Attachments/AttachmentHoverView';
import { useDispatch, useSelector } from 'react-redux';
import { DataGridProProps, GridRowModel } from '@mui/x-data-grid-pro';
import { ExpandedDetailPanel } from '../Common/ExpandedDetailPanel';
import { AttachmentPreviewDialog } from '../../Attachments/AttachmentPreview/AttachmentPreviewDialog';
import { VersionPanel } from '../Common/VersionPanel';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { selectUser } from 'oidc/user.selectors';
import { findCredentialByRequirementID, onDeleteCredential, onEditSave } from '../../CredentialsHelpers';
import _ from 'lodash';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { materialUiXGrid } from 'amn-ui-core';
import moment from 'moment';
import { useReadOnly, userRoles } from 'oidc/userRoles';
import { useTranslation } from 'react-i18next';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { Paper, TableContainer } from 'amn-ui-core';
import { GetMomentOfDate } from 'utils/dates/moment';
import { Authorized } from 'oidc/userHelper';

export const ClinicalQualificaitonsTab = props => {
  const { credentialsGridType, credentialingLookups, credentials } = props;

  const { classes } = useClinicalQualificationsListStyles();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [versionOpen, setVersionOpen] = useState<boolean>(false);
  const [columns, setColumns] = useState<any>([]);
  const [enableDelete, setEnableDelete] = useState<boolean>(true);
  const [enableDeleteInVersion, setEnableDeleteInVersion] = useState<boolean>(true);
  const [clearChildMessages, setClearChildMessages] = useState<boolean>(false);
  const [detailsPanelHeight, setDetailsPanelHeight] = useState<number | 'auto'>('auto');
  const [rows, setRows] = useState<any[]>([]);
  const apiRef = materialUiXGrid.useGridApiRef();
  const [selectedRowID, setSelectedRowID] = useState<number>(0);
  const { readOnly } = useReadOnly();
  const { t } = useTranslation();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [selectedCredential, setSelectedCredential] = useState<any>(null);
  const [selectedRowToDelete, setSelectedRowToDelete] = useState<any>(null);
  const [isCredAnalyst, setisCredAnalyst] = useState<boolean>(false);
  const canEdit =
    user.userInfo.roles?.includes(userRoles.credentialing_TeamMember) ||
    user.userInfo.roles?.includes(userRoles.credentialing_Leadership) ||
    user.userInfo?.roles?.includes(userRoles.credentialing);

  let clinicalQualificationData = [];

  const [showAttachmentPreview, setShowAttachmentPreview] = useState<{
    open: boolean;
    action: AttachmentAction;
    requirementID: number;
    requirementTypeID: number;
    travelerId: number;
    brandId: number;
  }>({
    open: false,
    action: AttachmentAction.view,
    requirementID: 0,
    requirementTypeID: 0,
    travelerId: 0,
    brandId: 0,
  });

  const willDelete = () => {
    setDeleteDialogOpen(true);
  };

  const onDelete = () => {
    setDeleteDialogOpen(false);
    setEnableDelete(true);
    setEnableDeleteInVersion(true);
    const promise = new Promise((resolve, reject) => {
      onDeleteCredential(selectedRowID, _.cloneDeep(credentials), dispatch)
        .then(response => {
          resolve(response);
          dispatch(
            globalActions.setSnackBar({ message: 'The record has been deleted successfully.', severity: 'success' }),
          );
        })
        .catch(error => {
          dispatch(
            globalActions.setSnackBar({ message: 'There was an error deleting the record.', severity: 'error' }),
          );
        });
    });
    return promise;
  };

  const checkBoxChange = (rowID, isSelected) => {
    const rowData = clinicalQualificationData;
    clinicalQualificationData = rowData.map(x => {
      return {
        ...x,
        isSelected: x.rowID === rowID ? isSelected : false,
        versionRows:
          x.versionRows?.map(version => {
            return {
              ...version,
              isSelected: false,
            };
          }) || [],
      };
    });
    setSelectedCredential(rowData?.find(x => x.rowId === rowID));
    setRows(clinicalQualificationData);
  };

  const checkBoxChangeVersion = (rowID, isSelected) => {
    const rowData = clinicalQualificationData;
    const newRows = rowData?.map(item => {
      if (item.versionRows?.find(version => version.rowID === rowID)) {
        return {
          ...item,
          isSelected: false,
          versionRows: item.versionRows?.map(version => {
            if (version.rowID === rowID) {
              setSelectedRowToDelete(version);
              return {
                ...version,
                isSelected: isSelected,
              };
            }
            return {
              ...version,
              isSelected: false,
            };
          }),
        };
      }
      return item;
    });
    setRows(newRows);
  };

  const bulkdelete = [
    {
      key: 'deleteClinicalQualifications',
      Icon: DeleteOutlinedIcon,
      disabled: isCredAnalyst ? enableDelete && enableDeleteInVersion : true,
      onClick: willDelete,
      tooltipProps: {
        tooltipContent: 'Delete Clinical Qualification/s',
      },
    },
  ];

  const onAttachmentDialogClose = () => {
    setShowAttachmentPreview({ ...showAttachmentPreview, open: false });
  };

  const getGridColumns = () => {
    return getMainTableColumns(
      true,
      false,
      true,
      setClearChildMessages,
      setShowAttachmentPreview,
      setVersionOpen,
      setEnableDelete,
      setSelectedRowID,
      checkBoxChange,
      canEdit,
    );
  };

  const credential = findCredentialByRequirementID(selectedRowID, credentials);

  const getGridRows = () => {
    const clinicalQualificationRows = clinicalQualificationsData.getTableRows(
      credentials.clinicalQualifications.filter(item => item.subCategory === 'Clinical Qualifications'),
      credentialingLookups,
      false,
      0,
      true,
    );

    clinicalQualificationData = clinicalQualificationRows;
    setRows(clinicalQualificationRows);
  };

  useEffect(() => {
    setColumns(getGridColumns());
    getGridRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials, credentialingLookups, credentialsGridType]);

  useEffect(() => {
    setisCredAnalyst(
      Authorized(
        [userRoles.credentialing, userRoles.credentialing_Leadership, userRoles.credentialing_TeamMember],
        user.userInfo,
      ),
    );
  }, [user]);

  const getDetailPanelContent = useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(
    ({ row }) => {
      let detailRows = [];
      let detailColumns = [];
      if (row?.detailRows && row?.detailRows.length) {
        detailRows = row?.detailRows[0][0]?.rows;
        detailColumns = row?.detailRows[0][0]?.columns;
      }
      if (versionOpen) {
        setDetailsPanelHeight(row?.versionRows?.length * 50 + 50);
        return (
          <VersionPanel
            title={''}
            versionCount={row?.versionRows?.length}
            columns={getVersionColumns(
              detailColumns,
              setClearChildMessages,
              setShowAttachmentPreview,
              setEnableDeleteInVersion,
              setSelectedRowID,
              checkBoxChangeVersion,
            )}
            rows={row?.versionRows || []}
          />
        );
      } else {
        return (
          <ExpandedDetailPanel
            title={''}
            apiRef={apiRef}
            rows={detailRows}
            rowID={row?.rowID}
            columns={getDetailedGridTableColumns(detailColumns, canEdit)}
            secondGridTitle={`Versions (${row?.versionRows?.length})`}
            secondGridColumns={getVersionColumns(
              detailColumns,
              setClearChildMessages,
              setShowAttachmentPreview,
              setEnableDeleteInVersion,
              setSelectedRowID,
              checkBoxChangeVersion,
            )}
            secondGridRows={row?.versionRows || []}
            dataUnavailableMessage={'No historical Clinical Qualifications available'}
            processRowUpdate={processDetailRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          />
        );
      }
    },
    [credentials],
  );

  const resetColumns = () => {
    setColumns(getGridColumns());
    setVersionOpen(false);
    setEnableDelete(true);
    setEnableDeleteInVersion(true);
    setSelectedRowID(0);
  };

  const updateRow = (updatedRow: any) => {
    const promise = new Promise((resolve, reject) => {
      onEditSave(
        updatedRow,
        _.cloneDeep(credentials),
        credentialsGridType,
        credentialingLookups,
        dispatch,
        {
          first: user.userInfo?.firstName,
          last: user.userInfo?.lastName,
        },
        () => {},
        user?.userInfo?.employeeId || 0,
      )
        .then(response => {
          resolve(response);
          dispatch(globalActions.setSnackBar({ message: t('search.grid.edit.successMessage'), severity: 'success' }));
        })
        .catch(error => {
          reject(error);
        });
    });
    return promise;
  };

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel) => {
      const row = clinicalQualificationData.filter(x => x.rowID === newRow.rowID);
      if (row && row.length) {
        row[0]['dateCompleted'] = moment(newRow.dateCompleted).format('MM/DD/yyyy');
        await updateRow(row[0]);
        return newRow;
      }
    },
    [credentials],
  );

  const processDetailRowUpdate = useCallback(
    async (newRow: GridRowModel) => {
      const updatedRow = newRow;
      const rowIds = apiRef.current?.state.rows?.ids;
      const editRows = apiRef.current.state.editRows;
      if (rowIds.length) {
        const row = clinicalQualificationData.filter(x => rowIds.includes(x.rowID));
        if (row && row.length) {
          const editValues = editRows[row[0].rowID];
          const detailRow = row[0].detailRows[0][0].rows[0];
          Object.entries(editValues)?.forEach(([key, value]) => {
            if (detailRow.hasOwnProperty(`${key}FilterOptions`) && typeof editValues[key]?.value === 'string') {
              detailRow[key] = detailRow[`${key}FilterOptions`]?.find(
                item => item.description === editValues[key]?.value,
              )?.value;
            } else if (key === 'expirationDate') {
              const moment = GetMomentOfDate(editValues[key]?.value);
              detailRow[key] = moment.isValid() ? moment.format('MM/DD/YYYY') : null;
            } else {
              detailRow[key] = editValues[key]?.value;
            }
          });

          row[0].detailRows[0][0].rows = [detailRow];
          await updateRow(row[0]);
          return detailRow;
        }
      }
    },
    [credentials],
  );

  const handleProcessRowUpdateError = useCallback(
    (error: Error) => {
      dispatch(globalActions.setSnackBar({ message: t('search.grid.edit.errorMessage'), severity: 'error' }));
    },
    [credentials],
  );

  const ClinicalQualificationsXGrid = useMemo(() => {
    return (
      <>
        <XGrid
          title="Clinical Qualifications"
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          getRowId={model => model.rowID}
          editMode="row"
          experimentalFeatures={{ columnGrouping: true, newEditingApi: true }}
          rowHeight={60}
          headerHeight={70}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => 'auto'}
          disableSelectionOnClick={true}
          pagination={rows?.length > 20}
          components={{
            Toolbar: XGridToolbar,
          }}
          className={classes.grid}
          toolbarProps={{
            rightActions: bulkdelete,
            bulkDelete: bulkdelete,
            refreshButton: true,
            resetButton: true,
            resetColumns: () => resetColumns(),
            refreshGrid: () => {
              resetColumns();
            },
          }}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
        />
      </>
    );
  }, [credentials, resetColumns, versionOpen]);

  return (
    <>
      {ClinicalQualificationsXGrid}
      <AttachmentPreviewDialog
        data={showAttachmentPreview}
        onClose={onAttachmentDialogClose}
        clearChildMessages={clearChildMessages}
        setClearChildMessages={setClearChildMessages}
      />
      <TableContainer component={Paper}>
        <GenericDialog
          open={deleteDialogOpen}
          maxWidth="sm"
          disableEscapeKeyDown
          dialogTitleProps={{
            text: t('search.grid.delete.dialogTitle'),
          }}
          dialogActions={[
            {
              text: t('search.grid.delete.dialogCancelButton'),
              variant: 'contained',
              color: 'tertiary',
              onClick: () => setDeleteDialogOpen(false),
            },
            {
              text: t('search.grid.delete.dialogDeleteButton'),
              variant: 'contained',
              onClick: () => onDelete(),
            },
          ]}
          dialogContentProps={{
            variant: 'text',
          }}
        >
          <React.Fragment>
            <div>
              <span>
                {!readOnly && credential?.credential?.canDelete === true
                  ? t('search.grid.delete.dialogSpecificContentOne')
                  : t('search.grid.delete.disallowDialogSpecificContentOne')}
              </span>
              <span style={{ fontWeight: 'bold' }}>
                {` ${credential?.credential?.requirementType} - ${credential?.credential?.credentialName}`}
              </span>
            </div>
            <div>
              <span>
                {!readOnly && credential?.credential?.canDelete === true
                  ? t('search.grid.delete.dialogSpecificContentTwo')
                  : ''}
              </span>
            </div>
          </React.Fragment>
        </GenericDialog>
      </TableContainer>
    </>
  );
};
