import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { call, put, getContext, select } from 'redux-saga/effects';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import { placementDetailsAction } from 'store/redux-store/placement-details/slice';
import { resetFailureStatus } from '../saga';
import { IPlacementSummaryRequest, placementSummaryActions } from '../slice';
import InitPlacementSummary, { IConsolidatedRequirementResponse } from '../init.types';
import { trackPromise } from 'react-promise-tracker';
import { selectPlacementConsolidatedDetails } from '../consolidatedStoreSelectors';

const TrackDatesOrderReqWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-dates-order-requirement');

export function* requestDatesOrderRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('datesOrderRequirement'));
    const placementService: PlacementService = yield getContext('placementService');
    const placementOrderType = yield select(selectPlacementOrderType);

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if(action.payload.isInitLoad && consolidatedDetails){
      response = {
        status : consolidatedDetails.summary?.submission?.orderSubmissionBusinessRequrement?.dates?.status,
        data : consolidatedDetails.summary?.submission?.orderSubmissionBusinessRequrement?.dates?.data
      }
    }

    if(!response?.status || !response?.data){
      response = yield call(
        TrackDatesOrderReqWrapper,
        placementService.getPlacementSummaryDates_OrderReq,
        action.payload.placementId,
        placementOrderType,
      );
    }

    if (httpSuccess(response?.status)) {
      const { data } = response;
      yield put(placementSummaryActions.setSubmissionInfoByKey({ key: 'dates', isOrderReq: true, ...data }));
      yield put(placementDetailsAction.resetForm());
    } else
      yield put(placementSummaryActions.setServiceFailureByKey({ key: 'datesOrderRequirement', failureStatus: true }));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestDatesOrderRequirement',
        area: 'src/store/redux-store/placement-summary/common-saga/orderDatesRequirements.tss',
      },
    });
  }
}

export function processDatesOrderRequirement(
  response: IConsolidatedRequirementResponse<any>,
  context: InitPlacementSummary,
) {
  try {
    if (!httpSuccess(response?.status)) {
      context.setServiceFailureByKey({ key: 'datesOrderRequirement', failureStatus: true });
      return;
    }

    context.setSubmissionInfoByKey({ key: 'dates', isOrderReq: true, ...response.data });
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'processDatesOrderRequirement',
        area: 'src/store/redux-store/placement-summary/common-saga/orderDatesRequirements.ts',
      },
    });
  }
}
