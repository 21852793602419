import React from 'react';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectExistingPlacements, selectUpdateInprogres } from 'store/redux-store/unit-preferences/selectors';
import { ExistingUnitRFOs } from './ExistingUnitRFOs';
import { makeStyles } from 'tss-react/mui';
import { CircularProgress, Grid } from 'amn-ui-core';
import { usePromiseTracker } from 'react-promise-tracker';

const useStyles = makeStyles()((theme) => ({
  dialogueContent: {
    padding: '20px 24px'
  },
  dialog: {
    padding: '10px',
  },
  validationLoader: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 999,
  },
}))
export const UnitAutomationAction = ({ open, onCancel, onConfirm, isSubmittingAction }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const existingPlacements = useSelector(selectExistingPlacements);
  const updateInprogres = useSelector(selectUpdateInprogres);
  
  const { promiseInProgress: updatingReadyToSubmitStatus } = usePromiseTracker({
    area: 'unit-order-preference-get-ready-to-submit-rfo-pids',
    delay: 0,
  });

  return (
    <>
      {(updateInprogres && updatingReadyToSubmitStatus) ?
        <CircularProgress className={classes.validationLoader} />
        :
        <GenericDialog
          open={open}
          draggable
          className={classes.dialog}
          dialogTitleProps={{
            text: existingPlacements?.length ? t('facility.unitPreference.existingPlacementsFound') : t('facility.unitPreference.existingPlacementsNotFound'),
            expandable: existingPlacements?.length ? true : false,
          }}
          dialogContentProps={{
            classes: {
              root: classes.dialogueContent,
            },
          }}
          dialogActions={[
            {
              text: t('Cancel'),
              variant: 'text',
              color: 'tertiary',
              onClick: onCancel,
            },
            {
              text: t('Confirm'),
              variant: 'contained',
              disabled: isSubmittingAction,
              loading: isSubmittingAction,
              onClick: onConfirm,
            },
          ]}
          fullWidth={existingPlacements?.length > 0}
          maxWidth={existingPlacements?.length > 0 ? 'md' : undefined}
          variant={existingPlacements?.length > 0 ? 'blue' : undefined}
        >
          <ExistingUnitRFOs />
        </GenericDialog>
      }
    </>
  );
};
