import { Box, materialUiXGrid } from 'amn-ui-core';
import { GridSelectionModel, GridSortModel } from '@mui/x-data-grid-pro';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useState } from 'react';
import { orderColumnVisibilityModel, orderViewInfo } from './OrdersGridUtils';
import { selectFilters, selectGridState } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';
import { useDispatch, useSelector } from 'react-redux';

import { SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import { XGrid } from '@AMIEWEB/Common/XGrid/XGrid';
import _ from 'lodash';
import { getFilterRequest } from '@AMIEWEB/GlobalSearch/helper';
import { globalSearchActions } from 'store/redux-store/global-search/slice';
import { selectSearchResults } from 'store/redux-store/global-search/selectors';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';

export const OrderSearchGrid = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const apiRef = materialUiXGrid.useGridApiRef();
  const gridState = useSelector(selectGridState);
  const [currentColumns] = useState<any[]>([...orderViewInfo(t)]);
  const [columnVisibilityModel, setcolumnVisibilityModel] = React.useState<any>(
    _.cloneDeep(orderColumnVisibilityModel),
  );
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const { copyOrderBonusProps, setCopyOrderBonusProps } = props;
  const { promiseInProgress } = usePromiseTracker({ area: 'global-search-call', delay: 0 });
  const [defaultSortModel] = React.useState<any>({ column: 'orderId', direction: 'desc' });

  const { items: rows, count } = useSelector(selectSearchResults);
  const appliedFilters = useSelector(selectFilters);

  const handleFetchData = React.useCallback(
    (size: number, number: number, sortedColumn?: { column: string; direction: any } | undefined | null) => {
      if (gridState?.filterAttributes?.filters) {
        dispatch(
          globalSearchActions.getSearchResults({
            searchType: SearchType.order,
            keyword: '',
            pageNumber: number,
            pageSize: size,
            sortedColumn: sortedColumn,
            filter: gridState?.filterAttributes?.filters
              ? getFilterRequest(Object.fromEntries(gridState?.filterAttributes?.filters))
              : {},
            translation: t,
          }),
        );
        setCopyOrderBonusProps({ ...copyOrderBonusProps, disableCopyButton: true });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridState.sortedColumn, gridState.pageNumber, gridState.pageSize, appliedFilters],
  );

  const onSortModelChanged = (model: GridSortModel) => {
    const pagination = apiRef?.current?.state?.pagination;
    if (pagination) {
      handleFetchData(
        pagination.pageSize,
        pagination.page + 1,
        model.length > 0
          ? {
              column: model[0].field,
              direction: model[0].sort,
            }
          : undefined,
      );
    }
  };

  const onPageChange = (page: number) => {
    const pagination = apiRef?.current?.state?.pagination;
    if (pagination) handleFetchData(pagination.pageSize, page + 1, gridState?.sortedColumn ?? defaultSortModel);
  };

  const onPageSizeChange = (pageSize: number) => {
    if (gridState.pageSize !== pageSize) handleFetchData(pageSize, 1, gridState?.sortedColumn ?? defaultSortModel);
  };

  const onRowsSelectionHandler = row => {
    if (row) {
      setCopyOrderBonusProps({
        ...copyOrderBonusProps,
        disableCopyButton: false,
        searchResult: { orderId: row?.orderId, facility: row?.facility },
      });
    }
  };

  return (
    <>
      <LayoutGrid>
        <LayoutGridItem xs={12}>
          <Box>
            <XGrid
              title={'Orders'}
              apiRef={apiRef}
              columns={currentColumns}
              columnVisibilityModel={columnVisibilityModel}
              rows={rows || []}
              inDialog
              height={490}
              rowsPerPageOptions={[250, 300, 400, 500]}
              defaultRowsPerPage={250}
              paginationMode="server"
              sortingMode="server"
              disableMultipleSelection
              loading={promiseInProgress}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              rowCount={count}
              onSortModelChange={model => onSortModelChanged(model)}
              selectionModel={selectionModel}
              onSelectionModelChange={(model: GridSelectionModel) => {
                setSelectionModel(model);
                let data = rows && model.length > 0 ? rows.find(x => x.id === model[0]?.toString()) : null;
                onRowsSelectionHandler(data);
              }}
              onColumnVisibilityModelChange={model => {
                setcolumnVisibilityModel(_.cloneDeep(model));
              }}
            />
          </Box>
        </LayoutGridItem>
      </LayoutGrid>
    </>
  );
};
