import { SortColumn } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { createAction } from '@reduxjs/toolkit';
import { SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import { TFunction } from 'i18next';

const getArchivablePlacements = createAction<{ result?: (rows: any[]) => void }>('GET_ARCHIVABLE_PLACEMENTS');

const archivePlacements = createAction('ARCHIVE_PLACEMENTS');

const getSearchResults = createAction<{
  searchType: SearchType;
  keyword: string;
  filter?: any;
  pageNumber: number;
  pageSize: number;
  sortedColumn?: { column: string; direction: 'asc' | 'desc' } | undefined | null;
  translation: TFunction;
  navigationDataLoad?: boolean;
  multiSortedColumns?: SortColumn[] | undefined | null;
}>('GET_GLOBAL_SEARCH_RESULTS');

const bulkVerifyOrders = createAction<{
  orderIds: any[];
  DateVerified: string;
  VerifiedByUserId: number;
}>('BULK_VERIFY_ORDERS');

export const serviceActions = { getArchivablePlacements, getSearchResults, archivePlacements, bulkVerifyOrders };
