import React from 'react';
import { Grid,  } from 'amn-ui-core';
import styledComponent from 'styled-components';
import { colors } from 'styles/styleVariables';

interface StatusTitleProps {
  title: string;
  titleSupportComponent?: any;
  icon: any;
  titleContentStyle?: any;
}

export const IconTitle = ({ title, icon, titleSupportComponent, titleContentStyle }: StatusTitleProps) => {
  return (
    <Grid container spacing={2} wrap="nowrap" alignItems="center">
      <Grid item style={{ display: 'flex', width: '2rem' }}>
        {icon}
      </Grid>
      <Grid item>
        <Title customTitleStyle={titleContentStyle}>{title}</Title>
      </Grid>
      {titleSupportComponent && (
        <FlexGrid item>
          {typeof titleSupportComponent === 'string' ? <Title customTitleStyle={titleContentStyle}>{titleSupportComponent}</Title> : titleSupportComponent}
        </FlexGrid>
      )}
    </Grid>
  );
};

const Title = styledComponent(({ customTitleStyle, ...rest }) => React.createElement(Grid, rest))`
  color: ${props => (props.customTitleStyle?.color ?? colors.text)};
  font-size: ${props => (props.customTitleStyle?.fontSize ?? '16px')};
  font-weight: ${props => (props.customTitleStyle?.fontWeight ?? '500')};
  padding-top: ${props => (props.customTitleStyle?.paddingTop ?? '2px')};
`;

const FlexGrid = styledComponent(Grid)`
  display: flex;
`;
