import { ChipsContainer } from 'app/ComponentLibrary/Filter';
import { instanceOfISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import React, { createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { placementReminderActions } from '../Store/PlacementReminders.redux';
import { selectFilterChipSelected } from '../Store/PlacementReminders.selector';

export const PlacementRemindersChips = props => {
  const dispatch = useDispatch();
  const filterChipSelected = useSelector(selectFilterChipSelected);
  const hiddenSubmitButton = createRef<HTMLButtonElement>();
  // Filter
  const formMethods = useFormContext();
  const { getValues, setValue } = formMethods;

  const determineChipSelected = (newChip, selected) =>
    newChip.isSelected ? null : [instanceOfISelection(newChip) ? newChip : newChip.value];

  const instanceOfISelectionstatus = object => {
    return object && typeof object === 'object';
  };

  //EVENTS
  const handleChipDelete = (field, chip) => {
    if (field === 'pipelineSelected') {
      dispatch(placementReminderActions.setStateID('all'));
    } else {
      const chosenValue = getValues(field);
      if (chosenValue && chosenValue.length > 0 && Array.isArray(chosenValue)) {
        const newValues = [...chosenValue];
        if (field === 'placementStatus') {
          newValues.splice(
            newValues.findIndex(opt => (instanceOfISelectionstatus(chip) ? opt.value : opt) === chip.label),
            1,
          );
        } else {
          newValues.splice(
            newValues.findIndex(opt => (instanceOfISelection(chip) ? opt.value : opt) === chip.value),
            1,
          );
        }
        setValue(field, newValues);
      } else {
        setValue(field, null);
      }
      const newChipSelection = {
        ...filterChipSelected,
        [field]: null,
      };
      dispatch(placementReminderActions.setFilterChipSelected(newChipSelection));
      props.onApplyFilter(getValues(), newChipSelection);
    }
  };

  const handleChipClick = (key, chip) => {
    if (!['pipelineSelected'].includes(key)) {
      const newChipSelection = {
        ...filterChipSelected,
        [key]: determineChipSelected(chip, filterChipSelected[key]),
      };
      dispatch(placementReminderActions.setFilterChipSelected(newChipSelection));
      props.onApplyFilter(getValues(), newChipSelection);
    }
  };

  return (
    <>
      <ChipsContainer
        chips={props.chips}
        onDelete={(field, chip, index) => handleChipDelete(field, chip)}
        onClick={(field, chip, index) => handleChipClick(field, chip)}
      />
      <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
    </>
  );
};
