import {
  ICallOutcome,
  IDirection,
  ChannelType,
  SendType,
  HubEventType,
  UseType,
  UseSubType,
  UserRoles,
} from 'app/components/Notification/Constants';
import { IEmail, IEmailData } from '../Email/IEmail';
import { ISms } from '../Sms/ISms';
import { ISnackBarSeverity } from '../Global/Global';
import { Dispatch } from 'react';
import { IUser } from 'oidc/user.redux';
import { IUserDetails } from '@AMIEWEB/Settings/UserManagement/store/userManagement.redux';
import { IUserPreference } from 'oidc/UserDevicePreference/userPreference.redux';
import { ICandidateDetails } from '../Candidate/CandidateProfile';
import { IMessage } from '../Sms/ISmsResult';

export interface INotification {
  inbox: IInboxMessage[];
  nextPageExists: boolean | false;
  pageNumber: number | 0;
  pageSize: number | 20;
  email: IEmail;
  voice: {
    data: any | null;
    open: Boolean;
    minimized: Boolean;
    showDisposition: Boolean;
  };
  sms: ISms;
  log: {
    channel?: ChannelType;
    data: any | null;
    open: Boolean;
    minimized: Boolean;
  };
  inboxUnreadFilter?: any;
  logFilters?: LogFilters;
  snackbarData: NotificationSnackbarProps | null;
  expanded?: { [key: string]: any };
  dropdown?: { [key: string]: boolean };
  showLogsFilter?: Boolean;
  gridLogFilters?: LogFilters;
  chips?: ILogsFilterChips;
  activeToasterCount: number;
  candidateConsents?: ICandidateConsents;
  sidePanelConsents?: ICandidateConsents;
  smsCandidateConsents?: ICandidateConsents;
  emailConsentWithdrawn?: ICandidateBrand[];
  inboxMessages: any;
  unreadCount: IUnreadSmsCount[];
  communicationLimitsEmail?: ICandidateBrand[];
  communicationLimitsSms?: ICandidateBrand[];
  communicationLimitsDialog?: ICommunicationLimitsDialog;
  facilityBaseTemplate?: string;
  packetFacilityTemplates?: any[];
  packetFacilityTemplatesById?: ITemplateType;
  isEdit?: boolean;
  voiceAction?: IVoiceAction;
  voiceDisposition?: IDisposition;
  voiceLoader?: boolean;
}

export interface IVoiceAction {
  minimize: boolean;
  showDisposition: boolean;
  openDeviceSelection: boolean;
}

export interface IUnreadSmsCount {
  phoneNumber: string;
  count: number;
}

export interface IInboxQuery {
  userId: string;
  email: string;
  phoneNumber: string;
  filters?: any;
  pageSize: number;
  pageNumber: number;
  search?: string;
  searchType?: string;
  sharedUserId?: string;
}

export interface IModalInteraction {
  open: boolean;
  minimized: boolean;
  showDisposition?: boolean;
  readonly?: boolean;
}

export interface LogFilters {
  channel: string[];
  initiatedBy: string;
  periodType?: string;
  startDate?: string;
  endDate?: string;
  userRole?: string;
}

export interface IChip {
  label: string;
  value?: any;
  enableDelete?: boolean;
  isSelected?: boolean;
}
export interface IAssociationChip {
  label: string;
  value?: any;
  enableDelete?: boolean;
  isSelected?: boolean;
  associationTypeId?: any;
  associationWithEmployeeId?: any;
  employeeId?: any;
}

export interface ILogsFilterChips {
  channel?: IChip[];
  initatedBy?: IChip | null;
  userRole?: IChip | null;
  date?: IChip | null;
}

export const defaultLogsChips: ILogsFilterChips = {
  channel: [],
  initatedBy: undefined,
  userRole: undefined,
  date: undefined,
};

export interface NotificationProps {
  data: PublishNotificationData | {};
  notificationResponse: NotificationSnackbarProps;
}

export interface ReadStatusProps {
  readOn: Date;
  readBy: string;
}

export interface NotificationMessageProps {
  sender: IEmployee;
  tos: ContactProps[];
  ccs?: ContactProps[];
  bccs?: ContactProps[];
  outboundCount?: number;
  inboundCount?: number;
  unreadMessageCount?: number;
  subject?: string;
  attachmentUrls?: string[];
  readStatus?: string;
}

export interface NotificationLogProps {
  id: string;
  manualLog: boolean;
  channel: ChannelType;
  message: NotificationMessageProps;
  response: any;
  associatedRecords?: IAssociatedRecordId[];
  useType: string | undefined;
  brand: string | undefined;
  sendType?: SendType;
}

export interface NotificationSnackbarProps {
  channel?: ChannelType;
  manual?: Boolean;
  changeWarning?: Boolean;
  showCloseAction: boolean;
  showResponse: boolean;
  severity: ISnackBarSeverity;
  message: string | undefined;
}

export interface PublishNotificationData {
  manualLog: boolean;
  sender: IEmployee;
  channel: ChannelType;
  associatedRecords?: IAssociatedRecordId[];
  tos: ContactProps[];
  useType: string | undefined;
  brand: string | undefined;
  formName?: string;
  purpose?: string;
  sendType?: SendType;
}

export interface IEmployee {
  name: string;
  email: string | undefined;
  senderId: string | undefined;
  userId: string | undefined;
  NtUserName: string | undefined;
  deviceName?: string | undefined;
  lineNumber?: string | undefined;
  phoneNumber?: string | undefined | null;
  editFlag?: boolean;
}
interface ISubstitutions {
  [key: string]: string;
}

export interface ContactProps {
  name?: string | undefined;
  email: string | undefined;
  phoneNumber?: string | undefined;
  contactId?: string | number | undefined;
  contactType?: string | undefined;
  brand?: string | undefined;
  brandId?: string | undefined;
  substitution?: any;
  fixed?: boolean;
  recruiter?: any;
  recruiterId?: any;
  editFlag?: boolean;
  [key: string]: any;
  clientContactDetails?: IClientContactStatusAndUnits;
  substitutions?: ISubstitutions;
}
export interface IAssociatedRecordId {
  name: string;
  value: string;
}
export interface IToken {
  name: string;
  input?: string;
  value?: any;
}

export interface IDisposition {
  CTIActivityHistoryId: string;
  id: string;
  notes: string;
  outcome: string;
  disposition: ICallOutcome;
  date: Date;
  time: Date;
  direction: IDirection;
  user: string;
  phoneNumber: string;
  sender: IEmployee;
  initiatedOn: Date;
}

export enum IEmailType {
  Auto = 'Auto',
  Manual = 'Manual',
}

export interface UserDeviceProps {
  selectedDevice: string;
  selectedLine: string;
  deviceList: DeviceProps[];
  deviceLine: [];
  isDefaultDeviceAndLine: boolean;
}

interface DeviceProps {
  deviceName: string;
  lines: [];
}

export interface HubEventListenerProps {
  user: string;
  eventName: HubEventType;
  log: any;
  coverageEnabled?: boolean;
}

export interface IUserPreferenceState<V> {
  id: string;
  value?: V;
}

export interface IUpdateConversationBrandPayload {
  conversationId?: string;
  userId: string;
  userPhone?: string;
  brand: string;
  brandId?: string;
  contactId?: string;

  contactPhone?: string;
  publishedDate?: string;
  associatedRecords?: IAssociatedRecord[];
}

export interface IChatHistory {
  chats: any;
  candidateDetails: any;
}

export interface IAssociateCandidatePayload {
  userId: string;
  contactId: string;
  contactPhone: string;
  brand: string;
  contactName: string;
  associatedRecords?: IAssociatedRecord[];
  brandId?: string;
}

export interface IGlobalConsents {
  emails: boolean | null;
  texts: boolean | null;
  calls: boolean | null;
}
export interface ICandidateConsents {
  candidateId: any;
  brandId: any;
  globalConsents?: IGlobalConsents;
  hasEmailConsents: boolean;
  hasCallConsents: boolean;
  hasTextConcents: boolean;
  withDrawnEmailConsents: Array<string>;
  withDrawnTextsConcents: Array<string>;
  withDrawnCallsConsents: Array<string>;
}

export enum ConsentType {
  candidateDetails = 'candidateDetails',
  sidePanels = 'sidePanels',
  smsModal = 'smsModal',
}

export interface ICandidateBrand {
  contactId?: string | any;
  brandId?: string | any;
}
export interface IBulkSmscandidateConsent {
  contactIds?: Array<string>;
  candidateData?: Array<any>;
}

export interface ILimitsContact {
  candidateId?: string | any;
  brandId?: string | any;
}

export interface ICommunicationLimitsPayload {
  channel: ChannelType;
  contacts: ILimitsContact[];
  bulkSmsData?: IBulkSmscandidateConsent;
}

export interface ICommunicationLimitsDialog {
  open: boolean;
  key: string;
}

export interface ITemplates {
  category: string;
  channel: string;
}
export interface ICandidateConsents {
  candidateIds: Array<string>;
  brandId: any;
  consentType: ConsentType;
}
export interface ITemplatesByCategory {
  category: string;
  userType: number;
  userId: number;
}

export interface ISaveTemplate {
  title: string;
  id: string;
  body: string;
  description: string;
  category: string;
  isPrivate: boolean;
  substitutions: any[];
  channel: string;
  userType: string;
  userId: string;
  createdBy: string;
}
export interface IUpdateTemplate {
  title: string;
  id: string;
  body: string;
  description: string;
  category: string;
  isPrivate: boolean;
  substitutions: any[];
  channel: string;
  userType: string;
  userId: string;
  updatedBy: string;
  updatedOn: Date;
}

export interface ITemplateType {
  id: string;
  title: string;
  category: string;
  body: string;
  description: string;
  substitutions: any[];
  isPrivate: boolean;
  subject?: string;
}

export interface ITemplate {
  id: string;
  title: string;
  category: string;
  body: string;
  description: string;
  substitutions: any[];
  isPrivate: boolean;
  subject?: string;
}
export interface IEmailTemplate {
  showTemplate: boolean;
  selectedTemplate: ITemplate | null;
  publicTemplates: any[];
  personalTemplates: any[];
  publicCategory: any[];
  personalCategory: any[];
  selectedPublicCategory: string;
  selectedPersonalCategory: string;
  templateTab: string;
}

export interface ITemplateChangeTracker {
  isEdited: boolean;
  showConfirmation: boolean;
  changedTemplate: any;
}

export enum TemplateType {
  Public = 'Public',
  Personal = 'Personal',
}

export interface ISenderMenu {
  name: string;
  email: string;
  phoneNumber?: string;
  id: string;
  coveredUser?: boolean;
}

export interface IEmailSender {
  name: string;
  email: string;
  userId: string;
  senderId: string;
  NtUserName: string;
}

export interface IPublishEmail {
  dispatch: Dispatch<any>;
  notificationData: IEmailData;
  attachments: File[];
  urlAttachments: File[];
  gridTag: string;
  emailType: string;
  emailConsents: ICandidateBrand[];
  communicationLimitsEmail: ICandidateBrand[];
  emailSendType: SendType;
  senderMenuValue: ISenderMenu;
  user: IUser;
  userDetails: IUserDetails[];
  userPreference: IUserPreference;
  sharedDeskFlag: boolean;
  globalData: INotification;
  originalAttachmentUrls: string[];
  candidateDetails: ICandidateDetails;
  handleSubmitResponse: any;
  successMessage: string;
  errorMessage: string;
  loader: any;
  setLoader: any;
  candidateConsentLinks?: any;
}

interface IAssociatedRecord {
  name: string;
  value: string;
}

interface ITo {
  name: string;
  email: string;
  phoneNumber: string;
  contactId?: string | number | null;
  deliveryStatus: string;
  brandId?: string | number | null;
  brand: string;
  recruiterId: string;
}

export interface ISaveCallLogPayload {
  CTIActivityHistoryId?: string;
  manualLog?: boolean;
  channel?: string;
  id?: string;
  brand?: string;
  brandId?: string;
  useType?: number;
  useSubType?: number;
  sendType?: string;
  associatedRecords?: IAssociatedRecord[];
  campaignId?: string;
  direction?: IDirection;
  sender?: IEmployee;
  tos?: ITo[];
  reason?: number;
  disposition?: ICallOutcome;
  formName?: number;
  classicOutcome?: number;
  notes?: string;
  initiatedOn?: Date;
  userRole?: string;
}

export interface ISmsPayload {
  associatedRecords: {
    name: string;
    value: string | number;
  }[];
  channel: ChannelType;
  body?: string;
  sender?: IEmployee;
  useType?: UseType;
  useSubType?: UseSubType;
  sendType: SendType;
  tos?: {
    readByRecipient?: boolean | null;
    subject?: string | null;
    brandId?: string | undefined;
    brand?: string | undefined;
    recruiterId?: string | undefined;
    contactId?: string | number | undefined;
    deliveryStatus?: string | null;
    name?: string;
    email?: string | undefined;
    phoneNumber?: string;
  }[];
  brand?: string | null;
  brandId?: string | null;
  userRole?: string | any;
  id: string;
  conversationId?: string;
  expand?: boolean;
  inProgress?: boolean;
  error?: boolean;
  processed?: boolean;
  direction?: string;
  message: IMessage;
  [key: string]: any;
  deliveryStatus?: string;
  campaignId?: string;
  publishedOn: string | null;
  initiatedOn: string | null;
  userRoles: UserRoles[];
}

export interface IPerson {
  name?: string;
  email?: string;
  phoneNumber?: string;
}

export interface IContact extends IPerson {
  contactId?: string;
  deliveryStatus?: string;
  clientContactDetails?: IClientContactStatusAndUnits;
}

export interface ISender extends IPerson {
  userId?: string;
  senderType?: string;
}

export interface INotificationSender extends ISender {
  ntUserName?: string;
}

export interface IContactWithBrand extends IContact {
  brandId?: string;
  brand?: string;
  recruiterId?: string;
}

export interface INotificationContact extends IContactWithBrand {
  readByRecipient?: boolean;
  subject?: string;
}

export interface IAttachment {
  contentUrl?: string;
  thumbnailUrl?: string;
  dateDeliveredToKofax?: Date;
}

export interface IReadStatus {
  isRead?: boolean;
  readBy?: string;
  readOn?: Date;
}

export interface IConversation {
  id?: string;
  publishedOn?: Date;
  direction?: string;
  deliveryStatus?: string;
}

export interface INotificationResponse {
  vendor?: string;
  messageId?: string;
  isQueued?: boolean;
  disposition?: string;
  classicOutcome?: string;
  notes?: string;
  errors: string[];
  warnings: string[];
  queuedOn?: Date;
}

export interface INotificationMessage {
  //Common
  sender: INotificationSender;
  tos: INotificationContact[];
  templateId?: string;
  brand?: string;
  readStatus: IReadStatus;
  //Alert & sms
  body: string;
  outboundCount?: number;
  inboundCount?: number;
  unreadMessagesCount?: number;
  undeliveredMessagesCount?: number;
  conversations: IConversation[];
  //Alerts
  level?: string;
  groupName?: string;
  targetArea?: string;
  status?: string;
  //Email
  subject?: string;
  ccs?: IContact[];
  bccs?: IContact[];
  replyTo?: IPerson;
  attachmentUrls?: string[];
  attachments?: IAttachment[];
  //Voice
  reason?: string;
  formName?: string;
  direction?: string;
}

export interface INotificationLog {
  id?: string;
  conversationId?: string;
  correlationId?: string;
  partitionKey?: string;
  brand?: string;
  useType?: string;
  useSubType?: string;
  sendType?: string;
  channel?: string;
  userRoles?: string[];
  deliveryStatus?: string;
  campaignId?: string;
  associatedRecords?: IAssociatedRecord[];
  message?: INotificationMessage;
  response?: INotificationResponse;
  publishedOn?: Date;
  initiatedOn?: Date;
  queuedOn?: Date;
}

export interface IInboxMessage {
  phoneNumber?: string;
  brand?: string;
  log: INotificationLog;
  name: string;
  travelerId: string;
  unreadLogs: number;
}

export interface IRecentMessages {
  pinnedMessages: any[];
  messages: any;
  pinnedMessage?: any;
  pinnedLimitReached?: boolean;
}

export interface IInboxReadStatusCommand {
  sender: ISender;
  contactId?: string;
  isRead: boolean;
  channel: ChannelType;
  readOn: Date;
  phoneNumber?: string;
  markAllAsRead?: boolean;
}

interface IClientContactStatusAndUnits {
  statusId: string;
  unitIds: string[];
}

export interface VoiceData {
  tos: Array<{
    phoneNumber: string;
    contactId?: string;
    name?: string;
  }>;
  sender: {
    deviceName: string;
    lineNumber: string;
  };
  isInternal?: boolean;
  notes?: string;
  disposition?: string | null;
  initiatedOn?: Date;
  date?: string;
  time?: string;
  direction?: IDirection;
  user?: string;
  phoneNumber?: string;
  CTIActivityHistoryId?: string;
  id?: string;
  associatedRecords?: Array<{
    name: string;
    value: string | null;
  }>;
  manualLog?: boolean;
  useType?: string;
  channel?: string;
}

export interface IInitiateCall {
  data: VoiceData;
}

export interface ISaveCallLog {
  callLog: ISaveCallLogPayload;
  isManualLog: boolean;
}


export interface IPassportPublish {
  brand: string,
  brandId: string,
  useType: string,
  useSubType: string,
  sendType: string,
  associatedRecords?: Array<{
    name: string;
    value: any;
  }>;
  sender: {
    userId: string,
    name: string,
    email: string,
    phoneNumber: string
  },
  tos: [
    {
      brandId: string,
      brand: string,
      contactId: string,
      name: string,
      email: string,
      phoneNumber: string
    }
  ]
}
export const enum InitiationPoints {
  marginToolInternalEmail = 'marginToolInternalEmail',
  marginToolCandidateEmail = 'marginToolCandidateEmail',
}
