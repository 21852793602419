import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { Grid } from 'amn-ui-core';

interface Props {
  data: {
    image?: string | null;
    text?: string | null;
  };
}

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
  },
  text: {
    paddingLeft: '8px',
  },
}));

export const IconWithText = (props: Props) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container className={classes.container}>
      <Grid item xs={1}>
        {props.data.image && <img src={props.data.image} alt={t('icon')}></img>}
      </Grid>
      <Grid item xs={8} className={classes.text}>
        {props.data.text}
      </Grid>
    </Grid>
  );
};
