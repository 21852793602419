import { Grid, Paper, Divider, IconButton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { PanelStick } from './PanelStick';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles()({
  root: {
    position: 'fixed',
    minWidth: 40,
    top: '99px',
    right: '28px',
    bottom: 0,
    width: 'inherit',
  },
  rootV0: {
    position: 'fixed',
    minWidth: 40,
    bottom: 6,
    top: 100,
  },
  container: {
    height: '100%',
  },
  panelStick: {
    height: '100%',
    maxWidth: 40,
    border: '1px solid #ECECEC',
  },
  choiceContainer: {
    width: 'calc(100% - 40px)',
    padding: 12,
  },
  choiceContainerX: {
    width: '100%',
    padding: 12,
  },
  choiceTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: '#333333',
    paddingBottom: '2%',
  },
  choiceTitleX: {
    fontSize: 16,
    fontWeight: 500,
    color: '#797979',
    paddingBottom: '2%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  customScroll: {
    maxHeight: 'calc(100vh - 10.5rem)',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  closeBttonRoot: {
    padding: 0,
  },
});
export const SidePanel = props => {
  const { classes } = useStyles();
  const { stickChosen, items, iconArray, version0 = false, scrollable = true, enableCloseIcon = false } = props;
  return (
    <Paper classes={{ root: version0 ? classes.rootV0 : classes.root }}>
      <Grid container direction="row-reverse" classes={{ container: classes.container }}>
        {!(stickChosen !== null && enableCloseIcon) && (
          <Grid item classes={{ root: classes.panelStick }}>
            <PanelStick iconArray={iconArray} stickChosen={stickChosen} handleChoice={props.handleChoice} />
          </Grid>
        )}
        {stickChosen !== null && (
          <Grid
            item
            container
            direction="column"
            classes={{ root: enableCloseIcon ? classes.choiceContainerX : classes.choiceContainer }}
          >
            <Grid item classes={{ root: enableCloseIcon ? classes.choiceTitleX : classes.choiceTitle }}>
              {items[stickChosen].title}

              {enableCloseIcon && (
                <IconButton
                  color="primary"
                  aria-label="close"
                  onClick={() => props.handleChoice(stickChosen)}
                  classes={{ root: classes.closeBttonRoot }}
                  size="small">
                  <CloseIcon />
                </IconButton>
              )}
            </Grid>
            <Divider />
            <Grid item classes={{ root: scrollable ? classes.customScroll : undefined }}>
              {items[stickChosen].component}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
