import { CustomExpandable } from '@AMIEWEB/Order/OrderDetails/OrderPreferences/customComponents';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BookingContent } from './BookingContent';
import { selectFacilityTemplateStatus, selectUpdateStatus } from 'store/redux-store/facility-preferences/selectors';
import { useSelector } from 'react-redux';
import { placementStage } from '@AMIEWEB/Order/OrderDetails/OrderPreferences/store/OrderPreference.redux';
import { usePromiseTracker } from 'react-promise-tracker';
import { CircularProgress } from 'amn-ui-core';

export const BookingPreferences = () => {
  const { t } = useTranslation();
  const isLoading = useSelector(selectFacilityTemplateStatus);

  const { promiseInProgress: isSavingFacilityTemplate } = usePromiseTracker({
    area: `save-facility-template-${placementStage.submission}`,
    delay: 0,
  });

  const titleAddOns = useMemo(
    () =>
      isSavingFacilityTemplate || isLoading ? (
        <div style={{ display: 'flex', flexWrap: 'wrap', alignContent: 'center' }}>
          <CircularProgress size={16} />
        </div>
      ) : null,
    [isSavingFacilityTemplate, isLoading],
  );

  return (
    <CustomExpandable
      expanded
      unmountOnExit={false}
      content={<BookingContent />}
      label={t('Booking')}
      switchState={false}
      handleSwitchChange={() => {}}
      hiddenSwitch
      titleAddOn={titleAddOns}
    />
  );
};
