import { OrderPrioritiesData, ShiftResponse } from 'app/models/Orders/OrderDetails';

export const getOrderDetailsShiftData = (shifts?: ShiftResponse[]) => {
  let value = '';
  shifts &&
    shifts.map((item, index) => {
      if (index !== shifts.length - 1) value += item.description + ', ';
      else value += item.description;
    });
  return value.length > 0 ? value : '-';
};

export const getOrderDetailsOrderPrioritiesData = (priorities?: OrderPrioritiesData[]) => {
  let value = '';
  priorities &&
    priorities.map((item, index) => {
      if (index !== priorities.length - 1) value += item.description + ', ';
      else value += item.description;
    });
  return value.length > 0 ? value : '-';
};

export const CONSTANTS = {
  GWW : 'GWW',
  MileageReimbursement:'Mileage Reimbursement',
  ExpectedHours:'Expected Hours',
  Travel:'Travel',
  Local:'Local'
}
