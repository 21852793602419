import { TypeAheadDropdown } from 'app/ComponentLibrary/Filter/TypeAheadDropdown/TypeAheadDropdown';
import React, { useEffect, useRef, useState } from 'react';
import { ISelection } from 'app/ComponentLibrary/Filter/TypeAheadDropdown/CustomTypeAhead';
import { Concatenate } from 'utils/string/string';
import { Controller, useFormContext } from 'react-hook-form';
import { InputAdornment, TextField } from 'amn-ui-core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styledComponent from 'styled-components';

const StyledAdornment = styledComponent(InputAdornment)`
  margin-bottom: 12px;
  color: rgba(85, 85, 85, 1);
`;

export const FormMultiSelectDropDown = props => {
  const { getValues } = useFormContext();
  const { fieldName, label, data, defaultValue } = props;
  const anchorAccessorId = `${fieldName}-anchor-accessor`;
  const hiddenApplyBtn = `${fieldName}-hidden-btn-accessor`;
  const [value, setValues] = useState<ISelection[]>([]);
  const [renderedText, setRenderedText] = useState('');
  const anchorRef = useRef<any>(null);
  const { control, setValue, errors } = useFormContext();

  const handleOpen = () => {
    const btn = document.getElementById(anchorAccessorId);
    /** btn click is required to invoke TypeAheadDropdown opener */
    btn?.click();
  };

  useEffect(() => {
    setRenderedText(
      Concatenate(
        value.map(item => item.name),
        ', ',
      ),
    );
    setValue(fieldName, value);
  }, [fieldName, setValue, value]);

  useEffect(() => {
    if (data?.length) {
      setValues(defaultValue ?? data);
    }
  }, [data, defaultValue]);

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={{
        required: true,
      }}
      render={() => (
        <>
          <TextField
            onClick={handleOpen}
            name={fieldName}
            variant="filled"
            value={renderedText}
            fullWidth
            ref={anchorRef}
            id={`${fieldName}-auto-generate-selling-notes-dropdown`}
            label={label}
            error={!!errors[fieldName]}
            helperText={errors[fieldName] ? (errors[fieldName]?.type === 'required' ? 'Required' : 'Required') : ''}
            multiline
            InputProps={{
              endAdornment: (
                <StyledAdornment position="end" onClick={handleOpen}>
                  <KeyboardArrowDownIcon />
                </StyledAdornment>
              ),
            }}
          />
          <TypeAheadDropdown
            name="selling-notes-dropdown"
            value={getValues(fieldName)}
            anchorRef={anchorRef}
            onChange={newValue => {
              setValues(newValue);
              props.setChanged?.();
            }}
            options={data ?? []}
            isMultiSelect
            anchorAccesorid={anchorAccessorId}
            hiddenApplyBtn={hiddenApplyBtn}
            removeActionContainer
            customClass={props.customClass}
          />
        </>
      )}
    />
  );
};
