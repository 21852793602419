import React, { useEffect, useState } from 'react';
import { CircularProgress } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { ICustomHeader, gridHoverBehaviour } from 'app/models/Grid';
import { NoDataPage } from '../Common/NoDataPage/NoDataPage';
import { CustomGrid } from '../Common/Grid/Grid';
import { getClientContact } from 'app/services/FacilityServices/FacilityServices';
import Block from 'app/assets/images/Block.svg';
import { convertToFormat } from 'app/helpers/dateHelper';
import { PagingConstant } from 'app/constants/PagingConstant';
import { trackPageView, usePageVisitTimeTracker } from 'app-insights/appInsightsTracking';
import { IClientInfo } from 'app/models/ClientContact/ClientContact';
import { FilterChoice } from 'store/redux-store/global-search/types';
import useDocumentTitle from 'utils/customHooks/useDocumentTitle';
import { pageNames } from 'app/constants/PageNames';

const useStyles = makeStyles()(theme => ({
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50vh',
  },
}));

let serverPageSize = PagingConstant.SERVER_PAGESIZE;
let serverPageNumber = 1;
const headerData: ICustomHeader[] = [
  {
    title: 'name',
    value: 'Name',
    clickable: true,
    sortable: true,
    navigationIds: ['clientContactId'],
    navigationExp: 'clientcontact/{0}',
  },
  {
    title: 'title',
    value: 'Title',
    sortable: true,
  },
  { title: 'email', value: 'Email' },
  { title: 'status', value: 'Status', sortable: true },
  { title: 'dateOfLastContact', value: 'Date of Last Contact', sortable: true, sortByField: 'lastContactDate' },
  { title: 'accountManager', value: 'Account Manager', sortable: true },
  { title: 'phoneNumber', value: 'Phone Number' },
  {
    title: 'facilityName',
    value: 'Facility Name',
    sortable: true,
    clickable: true,
    navigationIds: ['facilityId'],
    navigationExp: '/facility/{0}',
  },
  {
    title: 'unitList',
    value: 'Unit List',
    multiLine: true,
    hasHoverView: true,
    hoverBehaviour: gridHoverBehaviour.popUp,
  },
  {
    title: 'contactType',
    value: 'Contact Type',
    multiLine: true,
    hasHoverView: true,
    hoverBehaviour: gridHoverBehaviour.popUp,
  },
  { title: 'AMNDivision', value: 'AMN Division', sortable: true },
  {
    title: 'serviceStatus',
    value: 'Credit/Service Status',
    sortable: true,
    iconWithText: true,
    sortByField: 'serviceStatusText',
  },
];

export const ClientContactGrid = props => {
  const { classes } = useStyles();
  const [clientInfo, setClientInfo] = useState<IClientInfo[]>([]);
  const [pagedClientInfo, setPagedClientInfo] = useState<IClientInfo[]>([]);
  const [inProgress, setInProgress] = useState<boolean>(true);
  const [noData, setNoData] = useState<boolean>(true);
  const [pageData, setPageData] = useState<{ pageSize: number; pageNumber: number }>({ pageSize: 20, pageNumber: 1 });
  const [firstLoad, setFirstLoad] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showPageLoad, setShowPageLoad] = useState(true);
  usePageVisitTimeTracker('Client Contact Search Grid');
  useDocumentTitle({ title: pageNames.clientContacts });

  React.useEffect(() => {
    trackPageView({ name : pageNames.clientContacts });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async (serverPage = 1, offset = 20, clientPage = 1) => {
    setInProgress(true);
    const response = await getClientContact(props.searchString, serverPage);
    setInProgress(false);
    setFirstLoad(false);
    if (response && response.clientContacts.length === 0) setNoData(true);
    else {
      setNoData(false);
      const data = flattenData(response.clientContacts);
      setClientInfo(data);
      setShowPageLoad(false);
      setTotalRecords(response.totalCount);
      serverPageSize = response.pageSize;
      paginate(data, offset, clientPage);
    }
  };

  const paginate = (data = clientInfo, offset = 20, clientPage = 1) => {
    const page = clientPage % (serverPageSize / offset) || serverPageSize / offset;
    const previousPage = page - 1;
    const modifiedData = data.slice(previousPage * pageData.pageSize, previousPage * offset + offset);
    setPageData({ pageSize: offset, pageNumber: clientPage });
    setPagedClientInfo(modifiedData);
  };

  const handlePagination = (offset, page) => {
    if (clientInfo.length === 0) return;
    const newServerPage = Math.ceil((offset * page) / serverPageSize);
    if (serverPageNumber != newServerPage) {
      serverPageNumber = newServerPage;
      setShowPageLoad(true);
      fetchData(serverPageNumber, offset, page);
    } else {
      paginate(clientInfo, offset, page);
    }
  };

  const getName = (data: { firstName: string | null; lastName: string | null }) => {
    if (data.firstName) return data.firstName + ' ' + data.lastName;
    return null;
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchString]);

  const flattenData = response =>
    response.map(item => ({
      clientContactId: item.clientContactId,
      name: item.firstName + ' ' + item.lastName,
      title: item.title,
      email: item.email,
      status: item.status,
      dateOfLastContact: convertToFormat(item.dateLastContacted, 'MM/DD/YYYY'),
      lastContactDate: new Date(item.dateLastContacted),
      accountManager: getName(item.accountManager),
      phoneNumber: item.phoneNumber,
      facilityName: item.facilityName,
      facilityId: item.facilityId,
      unitList: sliceFlattenObject(item.units),
      unitListHoverViewData: flattenObject(item.units),
      contactType: sliceFlattenObject(item.contactTypes),
      contactTypeHoverViewData: flattenObject(item.contactTypes),
      AMNDivision: item.amnDivision,
      serviceStatus: getServiceStatus(item.creditStatus),
      serviceStatusText: item.creditStatus,
    })) as IClientInfo[];

  const flattenObject = resArray =>
    resArray.map(function (obj) {
      return obj.name || obj.description;
    });

  const sliceFlattenObject = resArray => {
    const slicedData = resArray.slice(0, 3);
    return flattenObject(slicedData);
  };

  const getServiceStatus = (status: string) => {
    if (status === 'No bk/ext allowed') {
      return {
        image: <img src={Block} />,
        text: status,
      };
    }
    return {
      image: null,
      text: status,
    };
  };

  return (
    <div>
      {inProgress && firstLoad && <CircularProgress className={classes.MuiCircularProgressRoot} />}
      {noData
        ? !inProgress && <NoDataPage searchTerm={props.searchString} category={FilterChoice.ClientContact} />
        : !firstLoad && (
            <CustomGrid
              columns={headerData}
              rows={pagedClientInfo}
              searchTerm={props.searchString}
              component={'Client Contact'}
              gridName={'clientContactGrid'}
              onPagination={handlePagination}
              fixedItemsPerPage={pageData.pageSize}
              defaultPageView={pageData.pageSize.toString()}
              currentPageNumber={pageData.pageNumber}
              totalRecordsCount={totalRecords}
              onPageSizeChange={pageSize => handlePagination(pageSize, 1)}
              stickyTable
              footerVisibility
              showPageLoad={showPageLoad}
              analytics={{
                tableName: 'Client Contact Search Grid',
                columnSort: true,
                itemsPerPage: true,
              }}
            ></CustomGrid>
          )}
    </div>
  );
};
