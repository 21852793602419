import _ from 'lodash';
import countries from 'app/assets/jsons/Country.json';
import statesData from 'app/assets/jsons/State.json';

export const months = [
  { id: 1, Description: 'January' },
  { id: 2, Description: 'February' },
  { id: 3, Description: 'March' },
  { id: 4, Description: 'April' },
  { id: 5, Description: 'May' },
  { id: 6, Description: 'June' },
  { id: 7, Description: 'July' },
  { id: 8, Description: 'August' },
  { id: 9, Description: 'September' },
  { id: 10, Description: 'October' },
  { id: 11, Description: 'November' },
  { id: 12, Description: 'December' },
];

export const getGraduationYear = () => {
  const max = new Date().getUTCFullYear();
  const min = max - 60;
  const yearRange = _.range(min, max + 11);
  return yearRange.map((i, idx) => {
    return { id: idx + 1, Description: i };
  });
};

export const getMonth = (month: number) => {
  return months?.find(d => d.id === month)?.Description;
};

export const getMonthId = (month: string) => {
  return months?.find(d => d.Description === month)?.id;
};

export const flattenEduData = rows => {
  return rows?.map((i, idx) => {
    const stateVal =
      i?.location?.state && i?.location?.state.length === 2
        ? i?.location?.state || '--'
        : getId(statesData.states, i?.location?.state) || '--';
    return {
      id: idx,
      schoolId: i?.schoolId || 0,
      school: i?.school || '--',
      degree: i?.degree || '--',
      degreeId: i?.degreeId || 0,
      major: i?.major || '--',
      majorId: i?.majorId || 0,
      country: i?.location?.country || '--',
      state: countryCheck(i?.location?.country) ? stateVal : i?.location?.state || '--',
      stateProvinceID: getStateNum(statesData.states, i?.location?.state) || null,
      city: i?.location?.city || '--',
      zip: i?.location?.zip || '--',
      graduatedOnMonth: getMonth(i?.graduatedOnMonth) || 0,
      graduatedOnYear: i?.graduatedOnYear || 0,
      graduatedOn: i?.graduatedOn || '--',
      countryId: countries.countries.find(x => x.Description == i?.location?.country)?.ID,
    };
  }) as IEducationInBoundData[];
};

export interface IEducationInBoundData {
  id: any;
  schoolId: any;
  school: any;
  degree: any;
  degreeId: any;
  major: any;
  majorId: any;
  country: any;
  state: any;
  stateProvinceID: any;
  city: any;
  zip: any;
  graduatedOnMonth: any;
  graduatedOnYear: any;
  graduatedOn: any;
}

export const getVal = val => {
  return val === '--' || val === 0 ? null : val;
};

export const getId = (data, val) => {
  return data.find(d => d.Description === val)?.ID;
};

export const getStateNum = (data, val) => {
  return data.find(d => d.Description === val)?.StateNumber;
};

export const getStateDesc = val => {
  return statesData.states.find(d => d.ID === val)?.Description;
};

export const parseDropDown = data => {
  return data?.map(item => {
    return { ID: item.value, Description: item.name };
  });
};

export const valCharLimit = (str: any, limit: number) => {
  return str?.length > 0 && str?.length < limit;
};
export const valSpecialChar = (str: any) => {
  const regEx = /[@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
  var allFoundCharacters = str.match(regEx);
  return allFoundCharacters.length > 0 ? false : true;
};
export const valAlphaNumeric = str => {
  const regEx = /^[A-Za-z0-9]+$/i;
  return str.match(regEx);
};

export const emptyCheck = val => {
  return val === '--' || val === null;
};

export const getStates = async () => {
  return statesData.states;
};

export const countryCheck = country => {
  return country === 'USA' || country === 'Canada';
};
