import React from 'react';
import { Grid, ThemeProvider, StyledEngineProvider,useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { CustomMultiLineTextField } from 'app/components/Order/OrderCreationForm/Common/CustomMultiLineTextField';
import { NewUnit } from 'app/models/Unit/CreateUnit';
import { useTranslation } from 'react-i18next';
import { UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { CustomTooltip } from 'app/components/Common/Tooltips';

interface Props {
  unit: NewUnit;
  onUnitPropChange: (property: string, value: any) => void;
  disableEdit?: boolean;
}
const useStyles = makeStyles()((theme) => ({
  body: {
    paddingBottom: 12,
  },
  tooltipPlacementBottom: {
    margin: 0,
    marginTop: 22,
  },
}));
export const ClassicRequired = (props: Props) => {
  const { unit, onUnitPropChange, disableEdit } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme= useTheme();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUnitPropChange(event.target.name, event.target.value);
  };
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <UnitDetailsBody variant="outlined">
          <Grid container direction="row" spacing={1} className={classes.body}>
            <Grid item>
              <UnitCardsTitle>{t('order.createUnit.classicRequirement.title')}</UnitCardsTitle>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  {!disableEdit ? (
                    <CustomMultiLineTextField
                      id={'createUnitclassicRequirementrequiredLableTxt'}
                      value={unit?.requiredQualifications}
                      isdisabled={disableEdit}
                      name="requiredQualifications"
                      label={t('order.createUnit.classicRequirement.requiredLable')}
                      onChange={handleChange}
                      placeholder={t('order.createUnit.externalJobInternalNotes.placeHolder')}
                    />
                  ) : (
                    <CustomTooltip
                      tooltipContent={t('order.createUnit.classicRequirement.noValueText')}
                      tooltipPlacementBottom={classes.tooltipPlacementBottom}
                    >
                      <CustomMultiLineTextField
                        id={'createUnitclassicRequirementrequiredLableTxt'}
                        value={unit?.requiredQualifications}
                        isdisabled={disableEdit}
                        name="requiredQualifications"
                        label={t('order.createUnit.classicRequirement.requiredLable')}
                        placeholder={t('order.createUnit.externalJobInternalNotes.placeHolder')}
                      />
                    </CustomTooltip>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {!disableEdit ? (
                    <CustomMultiLineTextField
                      id={'createUnitclassicRequirementprefLableTxt'}
                      value={unit?.preferredQualifications}
                      isdisabled={disableEdit}
                      name="preferredQualifications"
                      label={t('order.createUnit.classicRequirement.PreferedLable')}
                      onChange={handleChange}
                      placeholder={t('order.createUnit.externalJobInternalNotes.placeHolder')}
                    />
                  ) : (
                    <CustomTooltip
                      tooltipContent={t('order.createUnit.classicRequirement.noValueText')}
                      tooltipPlacementBottom={classes.tooltipPlacementBottom}
                    >
                      <CustomMultiLineTextField
                        id={'createUnitclassicRequirementprefLableTxt'}
                        value={unit?.preferredQualifications}
                        isdisabled={disableEdit}
                        name="preferredQualifications"
                        label={t('order.createUnit.classicRequirement.PreferedLable')}
                        onChange={handleChange}
                        placeholder={t('order.createUnit.externalJobInternalNotes.placeHolder')}
                      />
                    </CustomTooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </UnitDetailsBody>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
