import { materialUiXGrid, useTheme } from 'amn-ui-core';
import React, { useEffect, useMemo, useState } from 'react';
import { pricingDeskColumns } from './PricingDeskUtils';
import { DrawerFitted, XGrid, calculateComponentRemainingHeight } from '@AMIEWEB/Common';
import { formatDate } from '@AMIEWEB/Order/OrderDetails/helper';
import { missingField } from 'app/constants';
import { useDispatch, useSelector } from 'react-redux';
import { globalSearchActions } from 'store/redux-store/global-search/slice';
import { OrderPreviewDrawerViewer } from '@AMIEWEB/GlobalSearch/Order/OrderPreviewDrawerViewer';
import { FacilityPreviewDrawerViewer } from '@AMIEWEB/GlobalSearch/Facility/FacilityPreviewDrawerViewer';
import { selectFacilityDrawer, selectOrderDrawer } from 'app/ApplicationRoot/Global.selectors';
import { getHomePageSupportComponentHeight } from 'app/layout/pages/HomePage';
import { useWindowResize } from 'utils/customHooks/useWindowResize';
import { selectOrderBonusTab, selectOrderBonuses } from '@AMIEWEB/Order/Store/Order.selectors';
import { IOrderBonus, IOrderBonusCommand, orderDataActions, orderDataSliceKey } from '@AMIEWEB/Order/Store/Order.redux';
import { useInjectSaga } from 'redux-injectors';
import { orderDataSaga } from '@AMIEWEB/Order/Store/Order.saga';
import { selectFiterAttributes } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';
import { usePromiseTracker } from 'react-promise-tracker';
import { IOrderBonusFilters, bonusTypeOptions, orderBonusFilterName, pricingStatusOptions } from './PricingFilterUtils';
import { useTranslation } from 'react-i18next';
import { IXGridBulkActionButton } from '@AMIEWEB/Common/XGrid/Toolbar/XGridToolbar';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { OrderBonusModal } from '@AMIEWEB/Order/OrderDetails/OrderBonus/SaveOrderBonus/OrderBonusModal';
import { OrderBonusStatus } from '@AMIEWEB/Order/OrderDetails/OrderBonus/Common/Constants';
import { UseTemplate } from '@AMIEWEB/Order/OrderDetails/OrderBonus/CustomComponents/UseTemplate';
import { CopyOrderBonusModal } from '@AMIEWEB/Order/OrderDetails/OrderBonus/CopyOrderBonus/CopyOrderBonusModal';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { selectUser } from 'oidc/user.selectors';

export const PricingOrderBonus = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const apiRef = materialUiXGrid.useGridApiRef();
  const [columns, setColumns] = useState<any>(pricingDeskColumns());
  const [rows, setRows] = useState([]);
  const [copyButton, setCopyButton] = useState<{
    disabled: boolean;
    clicked: boolean;
    selectedBonusId: number;
    sourceOrderId: number;
  }>({
    disabled: true,
    clicked: false,
    selectedBonusId: null,
    sourceOrderId: null,
  });
  const [deleteButton, setDeleteButton] = useState<{
    disabled: boolean;
    clicked: boolean;
  }>({
    disabled: true,
    clicked: false,
  });
  const [deleteRecords, setDeleteRecords] = useState<{
    selectedOrderId: number;
    pendingStatusRowsSelected: number[];
    totalRowsSelected: number;
  }>({
    selectedOrderId: null,
    pendingStatusRowsSelected: [],
    totalRowsSelected: null,
  });
  const [editButton, setEditButton] = useState<{ clicked: boolean; selectedData: any }>({
    clicked: false,
    selectedData: null,
  });
  const dispatch = useDispatch();
  const facilityDrawer = useSelector(selectFacilityDrawer);
  const orderDrawer = useSelector(selectOrderDrawer);
  const [sortModel, setSortModel] = React.useState<any>([]);
  const [gridHeight, setGridHeight] = React.useState<number | undefined>(50);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(250);
  const [gridParams, setGridParams] = useState<IOrderBonusCommand>({
    bonusTypeIds: [10, 12],
    pageNumber: 1,
    facilityIds: null,
    facilityStatusIds: null,
    maxFilesSentId: 0,
    mspClientIds: null,
    orderBillRate: null,
    orderIds: null,
    positions: null,
    sortColumn: null,
    sortOrder: null,
    statusIds: [2],
    disciplineIds: null,
    divisionIds: null,
    orderPriorityIds: null,
    orderShiftIds: null,
    specialtyIds: null,
    subSpecialtyIds: null,
  });
  const { promiseInProgress: isCallingAPI } = usePromiseTracker({ area: 'get-order-bonuses', delay: 0 });
  useInjectSaga({ key: orderDataSliceKey, saga: orderDataSaga });
  const { userInfo } = useSelector(selectUser);
  const orderBonuses = useSelector(selectOrderBonuses);
  const gridFilters = useSelector(selectFiterAttributes);
  const orderBonusTab = useSelector(selectOrderBonusTab);
  const orderBonusList = orderBonusTab?.orderBonusList;
  const editOrderBonusModal = orderBonusTab?.editBonusModal;
  const { promiseInProgress: isCallingOrderBonusListAPI } = usePromiseTracker({
    area: 'get-order-bonus-list',
    delay: 0,
  });
  const { promiseInProgress: isCallingDeleteOrderBonusAPI } = usePromiseTracker({
    area: 'delete-order-bonus',
    delay: 0,
  });
  const { promiseInProgress: isGetOrderBonusListCallingAPI } = usePromiseTracker({ area: 'get-order-bonus-list', delay: 0 });
  const updateGridHeight = () =>
    setGridHeight(calculateComponentRemainingHeight('home-page-paper', getHomePageSupportComponentHeight, 12));
  useWindowResize(updateGridHeight, updateGridHeight);

  const handleRefreshGrid = () => {
    fetchRowData();
  };
  const handleResetColumns = () => {
    setColumns(pricingDeskColumns());
  };
  const onSortModelChange = e => {
    setSortModel(e);
    if (e && e.length > 0) {
      const payload: IOrderBonusCommand = {
        ...gridParams,
        sortColumn: e[0]?.field === 'statusChangedBy' ? 'changedByFirstName' : e[0]?.field,
        sortOrder: e[0]?.sort?.toString()?.toUpperCase(),
        pageNumber: 1,
      };
      setGridParams(payload);
    }
  };
  const formatRowData = () => {
    const rowData: IOrderBonus[] = orderBonuses.orderBonuses.map((item, index) => ({
      ...item,
      division: item.division ? item.division : missingField,
      clientType: item.clientType ? item.clientType : missingField,
      mspClient: item.mspClient ? item.mspClient : missingField,
      facilityID: item.facilityId ? item.facilityId : missingField,
      facilityStatus: item.facilityStatus ? item.facilityStatus : missingField,
      facilityName: item.facilityName ? item.facilityName : missingField,
      unitName: item.unitName ? item.unitName : missingField,
      orderID: item.orderId ? item.orderId : missingField,
      orderPriority: item.orderPriority ? item.orderPriority.trim() : missingField,
      skillSets: item.skillSets ? item.skillSets : missingField,
      shifts: item.shifts ? item.shifts : missingField,
      orderBillRate: item.orderBillRate,
      positionsAvailable: item.positionsAvailable ? item.positionsAvailable : missingField,
      pricingStatus: item.pricingStatus ? item.pricingStatus : missingField,
      bonusAmount: item.bonusAmount,
      bonusStartDate: item.bonusStartDate
        ? formatDate(item.bonusStartDate)
          ? formatDate(item.bonusStartDate)
          : missingField
        : missingField,
      bonusEndDate: item.bonusEndDate
        ? formatDate(item.bonusEndDate)
          ? formatDate(item.bonusEndDate)
          : missingField
        : missingField,
      bonusID: item.bonusId ? item.bonusId : missingField,
      statusID: item.statusId ? item.statusId : missingField,
      bonusStatus: item.bonusStatus ? item.bonusStatus : missingField,
      bonusType: item.bonusType ? item.bonusType : missingField,
      orderCreationDate: item.orderCreationDate
        ? formatDate(item.orderCreationDate)
          ? formatDate(item.orderCreationDate)
          : missingField
        : missingField,
      statusChangedDate: item.statusChangedDate
        ? formatDate(item.statusChangedDate, 'dateAnd12HourTime')
          ? formatDate(item.statusChangedDate, 'dateAnd12HourTime')
          : missingField
        : missingField,
      statusChangedBy:
        item.changedByFirstName || item.changedByLastName
          ? item.changedByFirstName + ' ' + item.changedByLastName
          : missingField,
      id: index + 1,
    }));
    setRows(rowData);
    if (rowData && rowData.length > 0) {
      setTotalCount(rowData[0].totalCount);
    } else {
      setTotalCount(0);
    }
  };
  const fetchRowData = () => {
    dispatch(orderDataActions.getOrderBonuses({ ...gridParams, pageSize: pageSize }));
  };
  useEffect(() => {
    if (gridFilters && gridFilters?.filters) {
      fetchRowData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridParams, pageSize]);
  useEffect(() => {
    formatRowData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBonuses]);

  useEffect(() => {
    if (gridFilters && gridFilters?.filters && gridFilters?.filters[0][0] === 'division') {
      const payload: IOrderBonusFilters = getPayloadWithFilters();
      setGridParams({
        ...gridParams,
        bonusTypeIds: payload?.bonusType,
        statusIds: getStatusIds(payload?.pricingStatus),
        facilityIds: payload?.facility,
        facilityStatusIds: payload?.facilityStatus,
        orderIds: payload?.OID,
        maxFilesSentId: payload?.MFS,
        mspClientIds: payload?.mspClient,
        orderBillRate: payload?.orderBillRate,
        positions: payload?.positions,
        pageSize: pageSize,
        sortColumn: props.resetGridParams ? null : gridParams.sortColumn,
        sortOrder: props.resetGridParams ? null : gridParams.sortOrder,
        disciplineIds: payload.discipline,
        divisionIds: payload.division,
        orderPriorityIds: payload.orderPriorityIds,
        orderShiftIds: payload.shift,
        specialtyIds: payload.specialty,
        subSpecialtyIds: payload.subSpecialty,
      });
    }

    if (props.resetGridParams) {
      props.setResetGridParams(false);
      setSortModel([]);
      setPageSize(250);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridFilters]);

  const getStatusIds = ids => {
    let statusIds = [];
    if (ids) {
      if (ids?.includes(1)) {
        statusIds = ids?.concat([3, 4]);
      } else {
        statusIds = ids;
      }
    }
    return statusIds;
  };

  const getPayloadWithFilters = () => {
    let payload = {};
    const keys = Object.keys(gridFilters?.selectedChipFilters);
    let accecptedKeys = [];
    keys?.forEach(key => {
      setAppliedFiltersByKey(key, payload, accecptedKeys);
    });
    setRawFilter(accecptedKeys, payload);
    return payload;
  };

  const setAppliedFiltersByKey = (key, payload, accecptedKeys) => {
    const filters = gridFilters?.selectedChipFilters[key];
    if (filters) {
      accecptedKeys.push(key);
      switch (key) {
        case orderBonusFilterName.MFS:
          payload[key] = 0;
          break;
        default:
          setSelectedFilterByKey(key, payload, filters);
          break;
      }
    }
  };

  const setSelectedFilterByKey = (key, payload, filters) => {
    if (typeof filters[0] === 'object') {
      if (key === orderBonusFilterName.positions || key === orderBonusFilterName.orderBillRate) {
        payload[key] = filters[0]?.value;
      } else if (key === orderBonusFilterName.pricingStatus) {
        payload[key] = filters?.map(filter => pricingStatusOptions.find(option => option.name === filter.name).id);
      } else if (key === orderBonusFilterName.bonusType) {
        payload[key] = filters?.map(filter => bonusTypeOptions.find(option => option.name === filter.name).id);
      } else if (
        key === orderBonusFilterName.mspClient ||
        key === orderBonusFilterName.facilityStatus ||
        key === orderBonusFilterName.discipline ||
        key === orderBonusFilterName.specialty ||
        key === orderBonusFilterName.subSpecialty ||
        key === orderBonusFilterName.shift ||
        key === orderBonusFilterName.orderPriority ||
        key === orderBonusFilterName.division
      ) {
        payload[key] = filters?.map(filter => filter.value)?.filter(msp => msp !== 'All');
      } else {
        payload[key] = filters?.map(filter => filter.id);
      }
    } else {
      const filterValue = filters[0];
      if (filterValue) {
        if (key === orderBonusFilterName.positions || key === orderBonusFilterName.orderBillRate) {
          payload[key] = filters[0]?.value;
        } else if (key === orderBonusFilterName.pricingStatus) {
          payload[key] = filters?.map(filter => pricingStatusOptions.find(option => option.name === filter).id);
        } else if (key === orderBonusFilterName.bonusType) {
          payload[key] = filters?.map(filter => bonusTypeOptions.find(option => option.name === filter).id);
        } else if (
          key === orderBonusFilterName.mspClient ||
          key === orderBonusFilterName.facilityStatus ||
          key === orderBonusFilterName.discipline ||
          key === orderBonusFilterName.specialty ||
          key === orderBonusFilterName.subSpecialty ||
          key === orderBonusFilterName.shift ||
          key === orderBonusFilterName.orderPriority ||
          key === orderBonusFilterName.division
        ) {
          payload[key] = filters?.map(filter => filter.value)?.filter(msp => msp !== 'All');
        } else {
          payload[key] = [filters[0]?.id];
        }
      }
    }
  };

  const setRawFilter = (keys, payload) => {
    gridFilters?.filters?.forEach(filter => {
      const filterType = filter[0];
      const filterValue = filter[1] as any;
      if (!keys.includes(filterType) && filterValue) {
        if (filterType === orderBonusFilterName.positions) {
          payload[filterType] = filterValue?.value;
        } else if (filterType === orderBonusFilterName.orderBillRate) {
          payload[filterType] = filterValue;
        } else if (filterType === orderBonusFilterName.MFS) {
          payload[filterType] = filterValue?.value === 2 ? 0 : 1;
        } else if (
          filterType === orderBonusFilterName.mspClient ||
          filterType === orderBonusFilterName.facilityStatus ||
          filterType === orderBonusFilterName.discipline ||
          filterType === orderBonusFilterName.specialty ||
          filterType === orderBonusFilterName.subSpecialty ||
          filterType === orderBonusFilterName.shift ||
          filterType === orderBonusFilterName.orderPriority ||
          filterType === orderBonusFilterName.division
        ) {
          payload[filterType] = filterValue?.map(fv => fv.value)?.filter(msp => msp !== 'All');
        } else {
          payload[filterType] = filterValue?.length > 0 ? filterValue?.map(fv => fv?.id) : undefined;
        }
      }
    });
  };

  const onPageChange = (page: number) => {
    const payload: IOrderBonusCommand = { ...gridParams, pageNumber: page + 1 };
    setGridParams(payload);
  };
  const onPageSizeChange = (offset: number) => {
    setPageSize(offset);
  };
  useEffect(() => {
    if (editOrderBonusModal.isEdit && editOrderBonusModal.rowData == null && orderBonusList.length > 0) {
      setEditButton({
        clicked: editOrderBonusModal.isEdit,
        selectedData:
          orderBonusList.length > 0 ? orderBonusList.find(x => x.bonusId === editOrderBonusModal?.bonusId) : null,
      });
    }
  }, [editOrderBonusModal, orderBonusList]);

  useEffect(() => {
    if (orderBonusTab?.isOrderBonusCopied) {
      setCopyButton({
        ...copyButton,
        clicked: false,
      });
      setRows([]);
      fetchRowData();
    }
    if (orderBonusTab?.isOrderBonusDeleted) {
      setDeleteButton({
        ...deleteButton,
        clicked: false,
      });
      setDeleteRecords({
        selectedOrderId: null,
        pendingStatusRowsSelected: [],
        totalRowsSelected: null,
      });
      setRows([]);
      fetchRowData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBonusTab?.isOrderBonusDeleted, orderBonusTab?.isOrderBonusCopied]);

  const handleCopyOrderBonusModal = () => {
    setCopyButton({
      ...copyButton,
      clicked: true,
    });
  };

  const handleDeleteOrderBonusModal = () => {
    setDeleteButton({
      ...deleteButton,
      clicked: true,
    });
  };

  const handleDeleteAction = () => {
    dispatch(
      orderDataActions.deleteOrderBonus({
        orderId: deleteRecords?.selectedOrderId,
        bonusIds: deleteRecords?.pendingStatusRowsSelected,
        totalRows: deleteRecords?.totalRowsSelected,
      }),
    );
    setDeleteButton({
      ...deleteButton,
      clicked: false,
    });
  };

  const handleDeleteNoAction = () => {
    setDeleteButton({
      ...deleteButton,
      clicked: false,
    });
  };

  const handleCancelAction = () => {
    setEditButton({
      ...editButton,
      clicked: false,
    });
  };
  const handleRowSelection = rowSelection => {
    setCopyButton({
      disabled: rowSelection.length === 1 ? false : true,
      clicked: false,
      selectedBonusId: rowSelection.length === 1 ? rows.find(row => row.id === rowSelection[0]).bonusId : null,
      sourceOrderId: rowSelection.length === 1 ? rows.find(row => row.id === rowSelection[0]).orderId : null,
    });
    setDeleteButton({
      ...deleteButton,
      disabled:
        rowSelection
          .map(id => rows.filter(row => row.id === id && row.bonusStatus !== OrderBonusStatus.pending))
          .filter(validRow => validRow.length > 0).length > 0 || rowSelection.length === 0
          ? true
          : false,
    });
    setDeleteRecords({
      selectedOrderId: rowSelection.length > 0 ? rows.find(row => row.id === rowSelection[0]).orderId : null,
      pendingStatusRowsSelected:
        rowSelection.length > 0
          ? rowSelection
              .map(id => rows.filter(row => row.id === id && row.bonusStatus === OrderBonusStatus.pending))
              .filter(validRow => validRow.length > 0)
              .map(x => x[0].bonusId)
          : [],
      totalRowsSelected: rowSelection.length > 0 ? rowSelection.length : null,
    });
  };

  const isADRoleAuthorized = useMemo(() => {
    return Authorized(
      [
        userRoles.all
      ],
      userInfo,
    );
  }, [userInfo]);

  const rightActions = useMemo<IXGridBulkActionButton[]>(() => {
    return isADRoleAuthorized
      ? [
          {
            key: 'copy-orderBonus-key',
            Icon: ContentCopyOutlinedIcon,
            onClick: handleCopyOrderBonusModal,
            disabled: copyButton?.disabled,
            tooltipProps: {
              tooltipContent: copyButton?.disabled
                ? t('order.orderBonusTab.rightActions.copyIconDisableMessage')
                : t('order.orderBonusTab.rightActions.copyIconMessage'),
            },
          },
          {
            key: 'delete-orderBonus-key',
            Icon: DeleteOutlinedIcon,
            onClick: handleDeleteOrderBonusModal,
            disabled: deleteButton?.disabled,
            tooltipProps: {
              tooltipContent: deleteButton?.disabled
                ? t('order.orderBonusTab.rightActions.deleteIconDisableMessage')
                : t('order.orderBonusTab.rightActions.deleteIconMessage'),
            },
          },
        ]
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyButton?.disabled, deleteButton?.disabled, isADRoleAuthorized]);

  const grid = useMemo(() => {
    return (
      <XGrid
        apiRef={apiRef}
        columns={columns}
        rows={rows}
        page={gridParams?.pageNumber - 1}
        pageSize={pageSize}
        loading={
          isCallingAPI || isCallingOrderBonusListAPI || isCallingDeleteOrderBonusAPI || isGetOrderBonusListCallingAPI
        }
        autoHeight={false}
        height={gridHeight}
        rowsPerPageOptions={[50, 100, 250, 500]}
        disableSelectionOnClick
        rowCount={totalCount}
        sortModel={sortModel}
        title="Order Bonuses"
        pagination={true}
        paginationMode="server"
        defaultRowsPerPage={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortModelChange={onSortModelChange}
        toolbarProps={{
          rightActions: rightActions,
          refreshButton: true,
          resetButton: true,
          refreshGrid: () => {
            handleRefreshGrid();
          },
          resetColumns: () => {
            handleResetColumns();
          },
        }}
        checkboxSelection
        onSelectionModelChange={rowSelection => {
          handleRowSelection(rowSelection);
        }}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, rows, onSortModelChange, orderBonuses, pageSize]);

  return (
    <React.Fragment>
      {grid}
      {(orderDrawer?.open || facilityDrawer?.open) && (
        <DrawerFitted
          onDrawerClose={() => {
            dispatch(globalSearchActions.setDrawerData({ open: false, data: undefined }));
          }}
          width={orderDrawer?.open ? 450 : 400}
          top={0}
          backgroundColor={theme.palette.framework.system.whisper}
          open={orderDrawer?.open || facilityDrawer?.open || false}
        >
          {orderDrawer?.open && <OrderPreviewDrawerViewer orderId={orderDrawer?.order.orderId} />}
          {facilityDrawer?.open && <FacilityPreviewDrawerViewer facilityId={facilityDrawer.facility.facilityId} />}
        </DrawerFitted>
      )}
      {editButton?.clicked &&  !isGetOrderBonusListCallingAPI &&(
        <OrderBonusModal
          open={editButton?.clicked}
          title={t('order.orderBonusTab.modalTitles.editOrderBonus')}
          rowData={editButton?.clicked && editButton?.selectedData ? editButton?.selectedData : null}
          orderId={editButton?.selectedData?.orderId}
          disableControls={
            editButton?.clicked && editButton?.selectedData?.descStatus === OrderBonusStatus.pending ? false : true
          }
          isNewOrderBonus={false}
          isEditAllowed={editButton?.clicked}
          useTemplate={false}
          onClose={() => {
            handleCancelAction();
          }}
        />
      )}
      {copyButton?.clicked && (
        <CopyOrderBonusModal
          open={copyButton?.clicked}
          title={t('order.orderBonusTab.modalTitles.copyOrderBonus')}
          sourceOrderId={copyButton?.sourceOrderId}
          bonusTemplateId={copyButton?.selectedBonusId}
          onClose={() => {
            setCopyButton({
              ...copyButton,
              clicked: false,
            });
          }}
        />
      )}
      {deleteButton?.clicked && (
        <UseTemplate
          openDialog={deleteButton?.clicked}
          title={t('order.orderBonusTab.confirmationTemplate.deleteTitle')}
          content={t('order.orderBonusTab.confirmationTemplate.deleteContent')}
          yesActionTitle={
            deleteButton?.clicked
              ? t('order.orderBonusTab.confirmationTemplate.delete')
              : t('detailsPage.confirmation.yes')
          }
          noActionTitle={t('detailsPage.confirmation.cancel')}
          handleYesAction={handleDeleteAction}
          handleNoAction={handleDeleteNoAction}
          handleCancelAction={null}
        />
      )}
    </React.Fragment>
  );
};
