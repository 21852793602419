import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IComparisionTableState {
    tableRows?: Array<any> | [];
    tableData?: Array<any> | [];
    referenceData?: object | null;
    expandableCells?: object;
}

interface IExpandCellsActionProps {
    expanded: boolean | false,
    maxHeight: number | undefined,
    cellClicked: undefined,
}

export const initialState: IComparisionTableState = {
    tableRows : [],
    tableData: [],
    referenceData: {},
    expandableCells: {},
}


const reduxSlice = createSlice({
    name: 'comparisionTable',
    initialState,
    reducers: {
        setTableData(state, action: PayloadAction<Array<any>>){
            state.tableData = action.payload
        },
        setTableRows(state, action: PayloadAction<Array<any>>){
            state.tableData = action.payload
        },
        setReferenceData(state, action: PayloadAction<object>){
            state.referenceData = action.payload
        },
        setInitialExpandableCells(state, action: PayloadAction<object>){
            state.expandableCells = action.payload
        },
        setExpandableCells(state, action: PayloadAction<any>){
            state.expandableCells = action.payload
        },
    }, 
})

export const { actions: comparisionTableActions,  name: comparisionTableSliceKey, reducer: comparisionTableReducer } = reduxSlice;

