import { GridCellParams } from '@mui/x-data-grid-pro';
import { Chip, Grid,useTheme  } from 'amn-ui-core';
import { convertHexToRGBA } from 'app/helpers/colorHelpers';
import React from 'react';
import { CustomTooltip } from '../../Tooltips/CustomTooltip';

interface XGridChipCellProps {
  color?: (params) => string;
  disabled?: (params) => boolean;
  showSecondaryColor?: (params) => boolean;
  size?: 'small' | 'medium' | undefined;
  deleteIcon?: any;
  onDelete?: (event, params) => void;
  trailingComponent?: (params: GridCellParams) => any;
  renderValue?: (params: GridCellParams) => any;
  dynamicColor?: (params: GridCellParams) => any;
  showHandIcon?: boolean;
}

export const XGridChipCell = (props: XGridChipCellProps) => params => {
  return <XGridChipCellContent props={props} params={params} />;
};

export const XGridChipCellContent = ({ props, params }: { props: XGridChipCellProps; params: GridCellParams }) => {
  const trailing = React.useMemo(() => {
    const result = props?.trailingComponent?.(params);
    return result && <div style={{ paddingLeft: '6px' }}>{result}</div>;
  }, [params, props]);

  const theme = useTheme();
  const chipList = React.useMemo(() => {
    if (typeof params.value !== 'string') {
      const chips = [];
      params.value?.map((item, index) => {
        chips.push(
          <Chip
            key={index}
            size={props.size}
            style={{
              color: props.disabled?.(params)
                ? '#8E8E8E'
                : props.showSecondaryColor?.(params) === true
                ? props.color(params)
                : props.dynamicColor?.(params) || props.color(params) || '#000000',
              backgroundColor:
                props.showSecondaryColor?.(params) === true
                  ? props.dynamicColor?.(params)
                  : convertHexToRGBA(
                      props.disabled?.(params)
                        ? '#8E8E8E'
                        : props.dynamicColor?.(params) || props.color(params) || '#000000',
                      0.07,
                    ),
              padding: '12px 5px',
              margin: '3px',
            }}
            label={item}
            deleteIcon={props.deleteIcon}
            onDelete={props.onDelete && !props.disabled ? event => props.onDelete?.(event, params) : undefined}
          />,
        );
      });
      if (chips.length > 3) {
        const tempChips = chips.slice(0, 2);
        const remainingChips = chips.slice(2, chips.length);
        tempChips.push(
          <CustomTooltip
            style={{ padding: 0 }}
            tooltipContent={
              <Grid container direction="row">
                {remainingChips.map((item, index) => {
                  return (
                    <Grid item key={index} style={{ padding: '5px 0px' }}>
                      {item}
                    </Grid>
                  );
                })}
              </Grid>
            }
            arrow
            placement="bottom"
          >
            <Chip
              style={{
                color: theme.palette.components.chips.tags.additionalChip.color,
                backgroundColor: convertHexToRGBA(
                  props.disabled?.(params)
                    ? '#8E8E8E'
                    : props.dynamicColor?.(params) || props.color(params) || '#000000',
                  0.07,
                ),
              }}
              label={`+${remainingChips.length}`}
            />
          </CustomTooltip>,
        );
        return tempChips;
      } else {
        return chips;
      }
    }
  }, [params, props]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {typeof params.value !== 'string' ? (
        chipList.map((item, index) => {
          return (
            <Grid item key={index}>
              {item}
            </Grid>
          );
        })
      ) : (
        <Chip
          size={props.size}
          style={{
            color:
              // TODO: @Brittany Kraemer - use the disabled function prop to check for the value instead of hard-coding the value "Signed" in the reusable component
              props.disabled?.(params) && params.value !== 'Signed'
                ? '#8E8E8E'
                : props.showSecondaryColor?.(params) === true
                ? props.color(params)
                : props.dynamicColor?.(params) || props.color(params) || '#000000',
            border: `1px solid ${
              props.disabled?.(params) && params.value !== 'Signed'
                ? '#8E8E8E'
                : props.dynamicColor?.(params) || props.color(params) || '#000000'
            }`,
            backgroundColor:
              props.showSecondaryColor?.(params) === true
                ? props.dynamicColor?.(params)
                : convertHexToRGBA(
                    props.disabled?.(params) && params.value !== 'Signed'
                      ? '#8E8E8E'
                      : props.dynamicColor?.(params) || props.color(params) || '#000000',
                    0.07,
                  ),
            cursor: props?.showHandIcon ? 'pointer' : 'default',
          }}
          label={props.renderValue ? props.renderValue(params) : params.value}
          deleteIcon={props.deleteIcon}
          onDelete={props.onDelete && !props.disabled ? event => props.onDelete?.(event, params) : undefined}
        />
      )}
      {trailing}
    </div>
  );
};
