import React, { useEffect } from 'react';
import { LayoutGrid, LayoutGridItem } from '../../../../../layout/LayoutGrid';
import { Box } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { GridColumns } from '@mui/x-data-grid-pro';
import { XGrid } from 'app/components/Common/XGrid/XGrid';
import { XGridDefaultColumn } from 'app/components/Common/XGrid/cells/XGridDefaults';
import { xGridDateSort } from 'app/components/Common/XGrid/sorting/XGridSorting';
import { XGridOverflowCell } from 'app/components/Common/XGrid/cells/XGridOverflowCell';
import { XGridHistoricalHoverView } from 'app/components/Common/XGrid/cells/HoverViewRenderers/XGridHistoricalHoverView';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()({
  container: {
    padding: '24px 0px',
  },
});

export const ChangeHistory = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [columns, setColumns] = React.useState<any>([]);
  const [sortModel, setSortModel] = React.useState<any>([
    {
      field: 'created',
      sort: 'desc',
    },
  ]);

  const getHistoryGridColumns = (t, exclude = [] as string[]): GridColumns => [
    {
      ...XGridDefaultColumn,
      field: 'fieldChanged',
      headerName: 'Field',
    },
    {
      ...XGridDefaultColumn,
      field: 'oldValue',
      headerName: 'Old Value',
      renderCell: XGridOverflowCell(XGridHistoricalHoverView)(),
    },
    {
      ...XGridDefaultColumn,
      field: 'newValue',
      headerName: 'New Value',
      renderCell: XGridOverflowCell(XGridHistoricalHoverView)(),
    },
    {
      ...XGridDefaultColumn,
      field: 'userName',
      headerName: 'Changed By',
    },
    {
      ...XGridDefaultColumn,
      field: 'created',
      headerName: 'Date',
      type: 'date',
      sortComparator: xGridDateSort,
    },
  ];

  const fetchChangeHistoryColumns = () => {
    const column = getHistoryGridColumns(t, []);
    setColumns(column);
  };

  useEffect(() => {
    fetchChangeHistoryColumns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Grid2 = React.useMemo(() => {
    return (
      <XGrid
        columns={columns}
        rows={[]}
        headerHeight={45}
        autoHeight
        disableSelectionOnClick
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        toolbarProps={
          {resetColumns: () => {
            fetchChangeHistoryColumns()
            setSortModel([
              {
                field: 'created',
                sort: 'desc',
              },
            ])
          }}
        }
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  return (
    <LayoutGrid>
      <LayoutGridItem xs={12}>
        <Box className={classes.container}>{Grid2}</Box>
      </LayoutGridItem>
    </LayoutGrid>
  );
};
