import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { candidateDetailsSelection } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { getCandidateInterivew } from 'app/services/CandidateServices/CandidateServices';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { candidateInterviewActions } from './interviews.slice';
import i18next from 'i18next';

export function* getInterviews() {
  const candidateDetail = yield select(candidateDetailsSelection);

  try {
    if (candidateDetail.travelerId > 0) {
      yield put(candidateInterviewActions.setIsLoading(true));
      const interviewResponse = yield call(getCandidateInterivew, candidateDetail.travelerId);
      yield put(candidateInterviewActions.setCandidateInterviews(interviewResponse));
      yield put(candidateInterviewActions.setIsLoading(false));
    }
  } catch (error) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

function* interviewWatcher() {
  yield takeLatest(candidateInterviewActions.getCandidateInterviews, getInterviews);
}

export function* candidateInterviewSaga() {
  yield all([interviewWatcher()]);
}
