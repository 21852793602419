import { GridColumns, GridFilterOperator } from '@mui/x-data-grid-pro';
import { XGridDefaultColumn, XGRID_COLUMN_TYPE } from 'app/components/Common/XGrid/cells/XGridDefaults';
import { XGridOverflowCell } from 'app/components/Common/XGrid/cells/XGridOverflowCell';
import { XGridFilterSelect } from 'app/components/Common/XGrid/FilterInputs/XGridFilterSelect';
import { AgeCompetencyHoverCell, AgeCompetencyCell } from './AgeCompetencyCell';
import { EvaluationsCell, EvaluationsCellHoverview } from './EvaluationsCell';
import { xGridDateSort } from 'app/components/Common/XGrid/sorting/XGridSorting';

const defaultColumnProps = { ...XGridDefaultColumn, flex: 0, filterable: true };
const DEFAULT_COLUMN_WIDTH = 120;

const getColumnDefinition = (t, key) => {
  return {
    ...defaultColumnProps,
    field: key,
    headerName: t(`placement.profile.tabs.referencesGrid.columns.${key}`),
  };
};

const getColumnName = (t, key) => {
  return t(`placement.profile.tabs.referencesGrid.columns.${key}`);
};

export const getColumns = (t, category) => {
  const columns: GridColumns = [
    {
      ...getColumnDefinition(t, 'referenceDate'),
      width: 155,
      type: XGRID_COLUMN_TYPE.DATE,
      sortComparator: xGridDateSort,
    },
    { ...getColumnDefinition(t, 'status'), width: DEFAULT_COLUMN_WIDTH },
    { ...getColumnDefinition(t, 'facility'), width: DEFAULT_COLUMN_WIDTH },
    { ...getColumnDefinition(t, 'city'), width: DEFAULT_COLUMN_WIDTH },
    { ...getColumnDefinition(t, 'state'), width: DEFAULT_COLUMN_WIDTH },
    {
      ...getColumnDefinition(t, 'startDate'),
      width: DEFAULT_COLUMN_WIDTH,
      type: XGRID_COLUMN_TYPE.DATE,
      sortComparator: xGridDateSort,
    },
    {
      ...getColumnDefinition(t, 'endDate'),
      width: DEFAULT_COLUMN_WIDTH,
      type: XGRID_COLUMN_TYPE.DATE,
      sortComparator: xGridDateSort,
    },
    {
      ...getColumnDefinition(t, 'discipline'),
      width: DEFAULT_COLUMN_WIDTH,
    },
    {
      ...getColumnDefinition(t, 'specialty'),
      width: DEFAULT_COLUMN_WIDTH,
    },
    {
      ...getColumnDefinition(t, 'supervisorName'),
      width: DEFAULT_COLUMN_WIDTH,
    },
    { ...getColumnDefinition(t, 'title'), width: DEFAULT_COLUMN_WIDTH },
    { ...getColumnDefinition(t, 'amnTitle'), width: DEFAULT_COLUMN_WIDTH },
    {
      ...getColumnDefinition(t, 'travelAssignment'),
      width: DEFAULT_COLUMN_WIDTH,
    },
    { ...getColumnDefinition(t, 'comment'), field: 'comment', width: DEFAULT_COLUMN_WIDTH },
    {
      ...getColumnDefinition(t, 'referenceEvaluations'),
      width: DEFAULT_COLUMN_WIDTH,
      renderCell: XGridOverflowCell(EvaluationsCellHoverview)(EvaluationsCell),
    },
    {
      ...getColumnDefinition(t, 'ageCompetencies'),
      width: DEFAULT_COLUMN_WIDTH,
      renderCell: XGridOverflowCell(AgeCompetencyHoverCell)(AgeCompetencyCell),
    },
    {
      ...getColumnDefinition(t, 'contactInformationComplete'),
      width: DEFAULT_COLUMN_WIDTH,
      hide: true,
    },
    {
      ...getColumnDefinition(t, 'phoneType1'),
      width: DEFAULT_COLUMN_WIDTH,
      hide: true,
    },
    {
      ...getColumnDefinition(t, 'phoneNumber1'),
      width: DEFAULT_COLUMN_WIDTH,
      hide: true,
    },
    {
      ...getColumnDefinition(t, 'phoneType2'),
      width: DEFAULT_COLUMN_WIDTH,
      hide: true,
    },
    {
      ...getColumnDefinition(t, 'phoneNumber2'),
      width: DEFAULT_COLUMN_WIDTH,
      hide: true,
    },
    {
      ...getColumnDefinition(t, 'callPreference'),
      width: DEFAULT_COLUMN_WIDTH,
      hide: true,
    },
    {
      ...getColumnDefinition(t, 'bestTime'),
      width: DEFAULT_COLUMN_WIDTH,
      hide: true,
    },
    { ...getColumnDefinition(t, 'email'), width: 120, hide: true },
    {
      ...getColumnDefinition(t, 'eligibleForRehire'),
      width: DEFAULT_COLUMN_WIDTH,
      hide: true,
    },
    {
      ...getColumnDefinition(t, 'explanation'),
      width: DEFAULT_COLUMN_WIDTH,
      hide: true,
    },
    { ...getColumnDefinition(t, 'unit'), width: 120, hide: true },
    {
      ...getColumnDefinition(t, 'unitSpecialty'),
      width: DEFAULT_COLUMN_WIDTH,
      hide: true,
    },
    {
      ...getColumnDefinition(t, 'positionsHeld'),
      width: DEFAULT_COLUMN_WIDTH,
      hide: true,
    },
    { ...getColumnDefinition(t, 'beds'), width: 120, hide: true },
    {
      ...getColumnDefinition(t, 'averageCaseload'),
      width: DEFAULT_COLUMN_WIDTH,
      hide: true,
    },
    {
      ...getColumnDefinition(t, 'unitDescription'),
      width: DEFAULT_COLUMN_WIDTH,
      hide: true,
    },
    {
      ...getColumnDefinition(t, 'shiftWorked'),
      width: DEFAULT_COLUMN_WIDTH,
      hide: true,
    },
    {
      ...getColumnDefinition(t, 'supervisorExperience'),
      width: DEFAULT_COLUMN_WIDTH,
      hide: true,
    },
    {
      ...getColumnDefinition(t, 'shareable'),
      width: DEFAULT_COLUMN_WIDTH,
      hide: true,
      description: getColumnName(t, 'shareableDescription'),
    },
    {
      ...getColumnDefinition(t, 'shared'),
      width: DEFAULT_COLUMN_WIDTH,
      hide: true,
      description: getColumnName(t, 'sharedDescription'),
    },
  ] as GridColumns;

  if (category === 'References') {
    columns.unshift({
      ...getColumnDefinition(t, 'referenceType'),
      width: 165,
      filterOperators: [
        {
          label: 'equals any',
          value: 'includes',
          getApplyFilterFn: filterItem => {
            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
              return null;
            }
            return params => {
              return filterItem.value?.includes(`${params.value}`);
            };
          },
          InputComponent: XGridFilterSelect,
          InputComponentProps: {
            type: XGRID_COLUMN_TYPE.ARRAY,
            options: ['Checkster', 'Evaluation', 'Online', 'SSC Eval', 'Telephone', 'Web', 'Written'],
          },
        },
      ] as GridFilterOperator[],
    });
  } else {
    columns.unshift({
      ...getColumnDefinition(t, 'referenceType'),
      width: 165,
    });
  }

  return columns;
};
