import { makeStyles } from 'tss-react/mui';
import { colors } from 'styles/styleVariables';

export const useFacilityDrawerStyles = makeStyles()(() => ({
  divider: {
    margin: '0 0 12px 0',
    height: 2,
  },
  avatarProfile: {
    height: 75,
    width: 75,
    marginRight: 10,
    fontSize: 30,
    backgroundColor: '#a3a1fb',
    fontWeight: 500,
    textTransform: 'uppercase',
    position: 'inherit',
  },
  drawerRoot: {
    flexGrow: 1,
    width: 'inherit',
  },
  profileSegment: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '20px 20px',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: '-16%',
  },
  profileInfo: {
    padding: '0',
  },
  details: {
    wordBreak: 'keep-all',
    fontSize: '14px',

    padding: '4px 0px 4px 0px',

    display: 'flex',
  },
  closeIconPad: {
    fontSize: '1.5rem',
    color: '#888888',
  },
  closeIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  profileInfoName: {
    fontSize: '18px',
    color: '#333333',
    fontWeight: 600,
  },
  bodyCandidateProfileDrawer: {
    paddingTop: '4rem',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '100vh',
  },
  profileDisplay: {
    display: 'flex',
  },
  avatarOrderProfile: {
    fontSize: '14px !important',
    textAlign: 'center',
    position: 'relative',
    'margin-top': '5px',
  },
  avatarOrderProfileIcon: {
    'font-size': '3rem',
  },
  link: {
    color: colors.amnBlue,
    textDecoration: 'underline',
    cursor: 'default',
    fontWeight: 450,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  headerLinkContent: {
    fontSize: 16,
    fontWeight: 450,
    margin: '0 5%',
    color: '#888888',
  },
  button: {
    'font-size': '0.7rem',
    padding: '0.3rem 1rem',
  },
  drawerOrderIdItem: {
    'font-size': '0.9rem',
  },
  drawerLeftArraow: {
    position: 'relative',
    padding: '0',
    margin: '0',
    'margin-right': '0',
    'margin-left': '0.2rem',
    'font-size': '1.2rem',
    top: '-1px',
    cursor: 'pointer',
  },
  drawerRightArraow: {
    position: 'relative',
    padding: '0',
    margin: '0',
    'font-size': '1.2rem',
    'margin-left': '0.3rem',
    'margin-right': '1rem',
    top: '-1px',
    cursor: 'pointer',
  },
  divisionBusinessRelationshipLabel: {
    'font-weight': '500',
    color: '#1e1e1e',
    position: 'relative',
    top: '2px',
  },
  drawerFacilityContactNameSection: {
    color: '#8e8e8e',
  },
  drawerFacilityContactNameSectionicon: {
    'font-size': '21px',
    'padding-left': '2px',
  },
  drawerFacilityAddressSectionContainer: {
    padding: '0',
  },
  drawerFacilityAddressSection: {
    'word-break': 'break-all',
    color: '#858585',
    'font-weight': '200',
    'padding-left': '0',
    'font-size': '13px',
  },
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50vh',
  },
  tooltip: {
    display: 'grid',
  },
  tooltipArrow: {
    color: '#fff',
    '&::before': {
      border: '1px solid #555555',
    },
  },
  tooltipBackground: {
    backgroundColor: '#fff',
    color: '#555555',
    border: '1px solid #555555',
    fontSize: '12px',
  },
  viewOrderLink: {
    'font-size': '14px',
  },
}));
