import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChip } from 'app/ComponentLibrary/Filter/ChipsContainer';
import { ITypeAheadOption } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/PreferencesTab/CustomComponents/ControlledTypeAhead';
import { ISelection } from '../../../ComponentLibrary/Filter/CustomSelect';
import { getTileCounts } from './WorkdeskFilter/filterHelper';
import { IPlacementFilter } from './WorkdeskFilter/filterUtils';

export interface IPlacementCounts {
  inSubmission: number;
  waitingForClearance: number;
  requestFileOut: number;
  sent: number;
  interviewed: number;
  offer: number;
  offerByFacility: number;
  offerBookedByTraveler: number;
  facilityConfirms: number;
  travelerAccepts: number;
  bookedCategory: number;
  bookedSubCategory: number;
  bookingApproved: number;
  waitingGoodToGo: number;
  postponementWarning: number;
  goodToGo: number;
  onAssignment: number;
}

export interface ICandidateCounts {
  candidateCount: number;
  inSubmission: number;
  waitingForClearance: number;
  requestFileOut: number;
  sent: number;
  interviewed: number;
  offer: number;
  offerByFacility: number;
  offerBookedByTraveler: number;
  facilityConfirms: number;
  travelerAccepts: number;
  bookedCategory: number;
  bookedSubCategory: number;
  bookingApproved: number;
  waitingGoodToGo: number;
  postponementWarning: number;
  goodToGo: number;
  onAssignment: number;
}

export interface ITileCounts {
  nonContactedDate30: number;
  nonContactedDate20: number;
  callBackToday: number;
  callBackThisWeek: number;
  lastApplicationToday: number;
  lastApplicationThisWeek: number;
  availableToStartThisWeek: number;
  availableToStartNextMonth: number;
}

export interface IFilterSpecs {
  candidateNameOpts: ISelection[];
  disciplineOpts: ISelection[];
  specialtyOpts: ISelection[];
  facilityNameOpts: ISelection[];
  cityOpts: ISelection[];
  stateOpts: ISelection[];
  arbitrationStatusOpts: ISelection[];
}

export interface IPlacementDesk {
  countObject: {
    placements: IPlacementCounts;
    candidates: ICandidateCounts;
  } | null;
  dataLoaded?: boolean;
  flattenedData: any[];
  filteredData: {
    countObject: {
      placements: IPlacementCounts;
      candidates: ICandidateCounts;
    };
    flattenedData: any[];
  } | null;
  filterSpecs: IFilterSpecs;
  falseRecruiters: ITypeAheadOption[];
  chips: IPlacementFilterChips;
  tileCounts: { static: ITileCounts; dynamic: ITileCounts };
}

export interface IPlacementFilterChips {
  placementRecruiter: IChip[];
  pipelineSelected: IChip[];
  candidateName: IChip[];
  discipline: IChip[];
  specialty: IChip[];
  availabilityStartDateRange: IChip | null;
  startDateRange: IChip | null;
  endDateRange: IChip | null;
  facilityName: IChip[];
  city: IChip[];
  state: IChip[];
  nonContactedDate: IChip | null;
  lastApplicationDateRange: IChip | null;
  availableToStartDateRange: IChip | null;
  callBackDateRange: IChip | null;
  activeCues: IChip | null;
  arbitrationStatus: IChip[];
}

export const defaultChips: IPlacementFilterChips = {
  placementRecruiter: [],
  pipelineSelected: [],
  candidateName: [],
  discipline: [],
  specialty: [],
  availabilityStartDateRange: null,
  startDateRange: null,
  endDateRange: null,
  facilityName: [],
  city: [],
  state: [],
  nonContactedDate: null,
  lastApplicationDateRange: null,
  availableToStartDateRange: null,
  callBackDateRange: null,
  activeCues: null,
  arbitrationStatus: [],
};

export const initialTileCounts = {
  static: {
    nonContactedDate30: 0,
    nonContactedDate20: 0,
    callBackToday: 0,
    callBackThisWeek: 0,
    lastApplicationToday: 0,
    lastApplicationThisWeek: 0,
    availableToStartThisWeek: 0,
    availableToStartNextMonth: 0,
  },
  dynamic: {
    nonContactedDate30: 0,
    nonContactedDate20: 0,
    callBackToday: 0,
    callBackThisWeek: 0,
    lastApplicationToday: 0,
    lastApplicationThisWeek: 0,
    availableToStartThisWeek: 0,
    availableToStartNextMonth: 0,
  },
};

export const initialState: IPlacementDesk = {
  countObject: null,
  flattenedData: [],
  filteredData: null,
  filterSpecs: {
    candidateNameOpts: [],
    disciplineOpts: [],
    specialtyOpts: [],
    facilityNameOpts: [],
    cityOpts: [],
    stateOpts: [],
    arbitrationStatusOpts: [],
  },
  falseRecruiters: [],
  chips: defaultChips,
  tileCounts: initialTileCounts,
};

const getRecruiterPlacementAction = createAction<{
  recruiterId: number[];
  filter?: {
    values: IPlacementFilter;
    setValue: (
      name: string,
      value: unknown,
      options?:
        | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
        }>
        | undefined,
    ) => void;
  };
  arbitrationAgreement: boolean
}>('GET_RECRUITER_PLACEMENTS');

const getFalseRecruitersAction = createAction<{
  userId: number;
}>('GET_POTENTIAL_RECRUITERS');

const getRecStaticCountAction = createAction<{
  userId: number; //currently assume it is recruiterID
}>('GET_REC_STATIC_COUNT');

const recruiterPlacementSlice = createSlice({
  name: 'recruiterPlacements',
  initialState,
  reducers: {
    setCounts(state, action: PayloadAction<{ placements: IPlacementCounts; candidates: ICandidateCounts }>) {
      state.countObject = action.payload;
    },
    setFlattenedData(state, action: PayloadAction<any[]>) {
      state.flattenedData = action.payload;
    },
    setDataLoaded(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        dataLoaded: action.payload,
      };
    },
    setFilterSpecs(state, action: PayloadAction<IFilterSpecs>) {
      state.filterSpecs = action.payload;
    },
    setFilteredData(
      state,
      action: PayloadAction<{
        countObject: {
          placements: IPlacementCounts;
          candidates: ICandidateCounts;
        };
        flattenedData: any[];
      } | null>,
    ) {
      if (action.payload)
        state.tileCounts = { ...state.tileCounts, dynamic: getTileCounts(action.payload.flattenedData) };
      state.filteredData = action.payload;
    },
    setFilterChips(state, action: PayloadAction<IPlacementFilterChips>) {
      state.chips = action.payload;
    },
    setFalseRecruiters(state, action: PayloadAction<ITypeAheadOption[]>) {
      state.falseRecruiters = action.payload;
    },
    setTileDynamicCounts(state, action: PayloadAction<ITileCounts>) {
      state.tileCounts = { ...state.tileCounts, dynamic: action.payload };
    },
    setTileStaticCounts(state, action: PayloadAction<ITileCounts>) {
      state.tileCounts = { ...state.tileCounts, static: action.payload };
    },
    reset() {
      return { ...initialState };
    },
  },
});

const { actions } = recruiterPlacementSlice;
export const { reducer: recruiterPlacementReducer, name: recruiterPlacementSliceKey } = recruiterPlacementSlice;
export const recruiterPlacementActions = {
  ...actions,
  getRecruiterPlacementAction,
  getFalseRecruitersAction,
  getRecStaticCountAction,
};
