import {
  StatusUpdateModalVersion,
  TaskStatus,
  TaskStatusReason,
  CoverageAction,
  ICoverageData,
  TaskCoverageActionType,
  TaskCoverageDescription,
  ICoverageActionDetails,
  ICurrentUserStatus,
  TaskDescription,
  ActionType,
} from 'app/models/Tasks/Tasks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentExternalTask,
  selectInternalTaskDetailsData,
  selectIsExternal,
  selectStatusUpdateId,
  selectTaskComments,
  selectTaskStatusUpdateFlag,
} from '../store/Tasks.selectors';
import WarningIcon from '@mui/icons-material/Warning';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useReadOnly } from 'oidc/userRoles';
import { taskDetailsActions } from '../store/Tasks.redux';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import {
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from 'amn-ui-core';
import { TaskFormBanner } from '@AMIEWEB/Common';
import { makeStyles } from 'tss-react/mui';
import { colors } from 'styles/styleVariables';
import { selectUser } from 'oidc/user.selectors';
import {
  CoverageCreatorStatus,
  CoverageRecipientStatus,
  CreatorStatus,
  RecipientStatus,
  checkButtonDisableStatusByCoverage,
} from './helper';
import { getExternalTaskDetails } from 'app/services/TaskServices/TaskServices';
import moment from 'moment-timezone';
import { CheckIdsForCreatedInError } from '../SagaTransformers/formatInternalTaskData';
import { CoverageConfirmationModal } from '@AMIEWEB/Common/CoverageConfirmation/CoverageConfirmation';
import { getAssignee } from '../TaskDetails/helper';
import { usePromiseTracker } from 'react-promise-tracker';
import { SelectWithAvatar } from '../CreateTask/SelectWithAvatar';
import { selectActiveCoverage } from 'store/redux-store/user-coverage/selectors';
import { ICoverageDetails } from 'app/models/UserCoverage/UserCoverage';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { getSharedDeskAssociatedUser } from '../EditTask/helper';
import { getEmployeeId } from '@AMIEWEB/Notification/Tasks/Common_v2/utils';

export const statusModalStyles = makeStyles()(() => ({
  // Modal styles
  modalContainer: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '444px',
      '& .MuiDialogContent-root': {
        overflow: 'initial',
      },
    },
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
  },
  firstGridItem: {
    marginTop: '30px',
  },
  coverageFirstItem: {
    marginTop: '0px',
  },
  // Modal Body Styles
  inputs: {
    width: '100%',
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
  },
  banner: {
    paddingBottom: '1px !important',
  },
  validationLoader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: '50%',
    zIndex: 999,
  },
  reasonsContainer: {
    marginLeft: '25px',
    display: 'flex',
    flexFlow: 'column',
  },
  otherInput: {
    marginLeft: '27px',
  },
  refreshModalTitle: {
    fontSize: '20px',
    color: colors.darkText,
    fontWeight: 600,
    textAlign: 'center',
  },
  refreshModalBody: {
    fontSize: '14px',
    color: colors.text,
    marginTop: '10px',
    marginBottom: '8px',
    textAlign: 'center',
  },
  refreshModal: {
    '& .MuiDialogActions-root': {
      justifyContent: 'center',
    },
  },
  labelStyle: {
    marginLeft: '30px',
  },
}));

export const StatusUpdateModal = ({
  open,
  version = StatusUpdateModalVersion.PARENT_TASK,
  task,
}: {
  open: boolean;
  version?: StatusUpdateModalVersion;
  task: any;
}) => {
  const { t } = useTranslation();
  const { classes } = statusModalStyles();
  const dispatch = useDispatch();
  const activeCoverage = useSelector(selectActiveCoverage);
  const statusUpdateTask = useSelector(selectStatusUpdateId);
  const taskStatusFlag = useSelector(selectTaskStatusUpdateFlag);
  const detailsBody = useSelector(selectCurrentExternalTask);
  const isExternal = useSelector(selectIsExternal);
  const userInfo = useSelector(selectUser);
  const internalTaskDetails = useSelector(selectInternalTaskDetailsData);
  const commentList = useSelector(selectTaskComments);

  const { promiseInProgress: isCallingTaskdetailsAPI } = usePromiseTracker({ area: 'get-task-details', delay: 0 });
  const { promiseInProgress: isCallingStatusUpdateAPI } = usePromiseTracker({ area: 'update-task-status', delay: 0 });

  const [serverErrorBanner, setServerErrorBanner] = useState(false);
  const [showRefreshModal, setShowRefreshModal] = useState(false);
  const [showReasons, setShowReason] = useState(false);
  const [showOther, setShowOther] = useState(false);
  const [showConfirmDialog, setConfirmDialog] = useState(null);
  const [createdInErrorWarning, setCreatedInErrorWarning] = useState(null);
  const [createdByUserOptions, setCreatedByUserOptions] = useState(null);
  const [recipientUserOptions, setRecipientUserOptions] = useState(null);
  const [currentUserDetails, setCurrentUserDetails] = useState<ICurrentUserStatus | null>(null);
  const [latestDetail, setLatestDetail] = useState<any>();
  const [isApplyAll, setApplyAll] = useState(null);
  const [excludeIds, setExcludeIds] = useState<number[]>([]);
  const [isProvidingCoverage, setIsProvidingCoverage] = useState<boolean>(null);
  const [selectedCoverageOption, setSelectedCoverageOption] = useState<any>(null);
  const [selectedCoverageId, setSelectedCoverageId] = useState<number>(0);
  const [coverageActionDetails, setCoverageActionDetails] = useState<ICoverageActionDetails | null>(null);
  const [coverageData, setCoverageData] = useState<ICoverageData>({ openDialog: false });
  const [statusReason, setStatusReason] = useState({
    statusId: undefined,
    statusUpdateReason: '',
  } as any);

  const hsgId = 6000;
  const { readOnly } = useReadOnly();

  const formMethods = useForm({
    mode: 'onSubmit',
  });
  const {
    handleSubmit,
    control,
    reset,
    setError,
    clearErrors,
    trigger,
    setValue,
    formState: { isDirty, errors },
  } = formMethods;

  const checkCoverage = () => {
    if (activeCoverage?.length > 0) {
      if (selectedCoverageOption) {
        return false;
      } else {
        setError('onBehalfOf', {
          type: 'required',
          message: 'Required',
        });
        return true;
      }
    } else {
      const hsgRecipient = getAssignee(task, { employeeId: hsgId });
      const currentUserAsRecipient = RecipientStatus(task, version, userInfo?.userInfo);
      if (hsgRecipient && !currentUserAsRecipient) {
        setCoverageData({
          openDialog: true,
          action:
            statusReason?.statusId === TaskStatus.Completed
              ? CoverageAction.UPDATE_STATUS_COMPLETE
              : statusReason?.statusId === TaskStatus.Cancelled
              ? CoverageAction.UPDATE_STATUS_CANCEL
              : statusReason?.statusId <= hsgRecipient.statusId
              ? CoverageAction.REVERT_STATUS
              : CoverageAction.EDIT_TASK,
          coveredUser: hsgRecipient,
        });
        return true;
      }
      return false;
    }
  };

  const handleConfirmClick = async () => {
    if (isApplyAll) {
      setConfirmDialog(true);
    }
    if (statusReason?.statusUpdateReasonId === TaskStatusReason.ERROR) {
      return setCreatedInErrorWarning(true);
    }
    if (!readOnly && !isApplyAll) {
      if (showOther) {
        await trigger('otherReason');
      }
      handleSubmit(submitTask)().catch(err => {
        setServerErrorBanner(true);
      });
    }
  };

  const handleCoverageSelection = (data: ICoverageDetails) => {
    setShowReason(false);
    setStatusReason({
      statusId: undefined,
      statusUpdateReason: '',
    });
    setSelectedCoverageOption(data?.id);
    setSelectedCoverageId(data?.coveredEmployeeId ?? 0);
    if (data.id.toString() !== userInfo?.userInfo.employeeId.toString()) {
      setCoverageActionDetails({
        coveredByEmployeeId: data?.coveringEmployeeId,
        coveredByEmployeeFirstName: data?.coveringEmployeeFirstName,
        coveredByEmployeeLastName: data?.coveringEmployeeLastName,
        coveredForEmployeeId: data?.coveredEmployeeId,
        coveredForEmployeeFirstName: data?.coveredEmployeeFirstName,
        coveredForEmployeeLastName: data?.coveredEmployeeLastName,
        actionById: userInfo?.userInfo?.employeeId,
        actionDate: moment.tz(new Date(), 'America/Los_Angeles').format(),
        actionType: TaskCoverageActionType.UPDATE_TASK_STATUS,
        actionTypeDescription: TaskCoverageDescription.UPDATE_TASK_STATUS,
      } as ICoverageActionDetails);
    } else {
      setCoverageActionDetails(null);
    }
  };

  const submitTask = async formData => {
    const statusUpdate = {
      ...statusReason,
      excludeTaskIds: statusReason?.statusUpdateReasonId === 5 ? [] : excludeIds,
      statusUpdateReason:
        formData.statusUpdateReason === TaskStatusReason.OTHER ? formData.otherReason : statusReason.statusUpdateReason,
      taskId: statusUpdateTask?.taskId,
      taskCoverageAction: coverageActionDetails,
      sharedDeskAssociatedUser: isNullOrUndefined(userInfo?.userInfo?.sharedProfile)
        ? undefined
        : statusReason?.statusId === TaskStatus.Completed
        ? getSharedDeskAssociatedUser(userInfo?.userInfo, ActionType.StatusUpdate, TaskDescription.StatusUpdate)
        : getSharedDeskAssociatedUser(userInfo?.userInfo, ActionType.CancelTask, TaskDescription.CancelTask),
    };
    if (statusUpdate?.statusId === TaskStatus.Completed) {
      statusUpdate.statusUpdateReason = t('notification.taskStatusModal.statusLabels.successfullComplete');
    } else if (statusUpdate?.statusId === TaskStatus.Cancelled && statusUpdate?.statusUpdateReason === '') {
      statusUpdate.statusUpdateReason = t('notification.taskStatusModal.statusLabels.createdError');
    }
    if (version === StatusUpdateModalVersion.PARENT_TASK) {
      if (isExternal) {
        statusUpdate.categorySubType1 = detailsBody.categorySubType1;
        statusUpdate.categorySubType2 = detailsBody.categorySubType2;
        dispatch(
          taskDetailsActions.updateExternalTaskStatus({
            update: statusUpdate,
            initialStatus: statusUpdateTask?.statusId,
          }),
        );
      } else {
        dispatch(
          taskDetailsActions.saveStatusTaskAction({
            update: statusUpdate,
            coverageData: coverageData?.decision ? coverageData : null,
          }),
        );
      }
    } else if (version === StatusUpdateModalVersion.SUBTASK) {
      dispatch(
        taskDetailsActions.updateSubtaskStatusAction({
          update: statusUpdate,
          coverageData: coverageData?.decision ? coverageData : null,
        }),
      );
    } else {
      onCloseCall();
    }
  };

  const onCloseCall = () => {
    onClose();
    dispatch(taskDetailsActions.setDetailsModalOpen(false));
  };

  const onCloseDefault = () => {
    onClose();
    dispatch(taskDetailsActions.setDetailsModalOpen(true));
  };

  const onClose = () => {
    dispatch(taskDetailsActions.setShowTaskStatusModal(undefined));
    dispatch(taskDetailsActions.setTaskStatusUpdateFlag(undefined));
    reset({});
  };

  const disableSubmit = () => {
    return !isDirty || statusReason?.statusId === undefined;
  };

  const checkCoverageActionDisable = () => {
    if (activeCoverage?.length > 0) {
      return checkButtonDisableStatusByCoverage(
        task,
        activeCoverage,
        selectedCoverageId,
        userInfo,
        version === StatusUpdateModalVersion.SUBTASK,
      );
    } else {
      return false;
    }
  };

  async function fetchLatest() {
    const latestData: any = await getExternalTaskDetails(detailsBody);
    setLatestDetail(latestData.data);
  }

  useEffect(() => {
    const createdByErrorIds = CheckIdsForCreatedInError(internalTaskDetails, commentList);
    setExcludeIds(createdByErrorIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showReasons) {
      setValue('taskReason', t('notification.taskStatusModal.cancelReason.candidateUnresponsive'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showReasons]);

  useEffect(() => {
    setServerErrorBanner(false);
    if (!statusUpdateTask) {
      onCloseDefault();
      dispatch(
        globalActions.setSnackBar({
          message: t('notification.createTask.taskEditSuccess'),
          severity: 'success',
        }),
      );
    } else if (taskStatusFlag === 'ERROR') {
      setServerErrorBanner(true);
      dispatch(
        globalActions.setSnackBar({
          message: t('notification.createTask.taskEditFailure'),
          severity: 'error',
        }),
      );
      dispatch(taskDetailsActions.setStatusAppliedAll(false));
    } else {
      if (taskStatusFlag === 'SHOWMODAL' && isExternal) {
        setShowRefreshModal(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusUpdateTask, taskStatusFlag]);

  useEffect(() => {
    if (statusReason?.statusUpdateReasonId === TaskStatusReason.ERROR) {
      setApplyAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusReason]);

  useEffect(() => {
    if (activeCoverage?.length > 0) {
      if (selectedCoverageId > 0) {
        const recipientData = CoverageRecipientStatus(task, version, selectedCoverageId);
        const createdByData = CoverageCreatorStatus(task, selectedCoverageId);
        if (recipientData && createdByData?.isCreatedByUser) {
          setRecipientUserOptions(recipientData);
          setCreatedByUserOptions(createdByData);
        } else if (recipientData) {
          setRecipientUserOptions(recipientData);
        } else if (createdByData?.isCreatedByUser) {
          setCreatedByUserOptions(createdByData);
        } else {
          setRecipientUserOptions(null);
          setCreatedByUserOptions(null);
        }
      }
    } else {
      const recipientData = RecipientStatus(task, version, { employeeId: parseInt(getEmployeeId(userInfo?.userInfo)) });
      const hsgRecipient = RecipientStatus(task, version, { employeeId: hsgId });
      const createdByData = CreatorStatus(task, { employeeId: parseInt(getEmployeeId(userInfo?.userInfo)) });
      if (recipientData && createdByData?.isCreatedByUser) {
        setRecipientUserOptions(recipientData);
        setCreatedByUserOptions(createdByData);
      } else if (recipientData) {
        setRecipientUserOptions(recipientData);
      } else if (createdByData?.isCreatedByUser) {
        setCreatedByUserOptions(createdByData);
      } else if (hsgRecipient) {
        setRecipientUserOptions(hsgRecipient);
      } else {
        setRecipientUserOptions(null);
        setCreatedByUserOptions(null);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, selectedCoverageId]);

  useEffect(() => {
    if (showRefreshModal) fetchLatest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showRefreshModal]);

  useEffect(() => {
    if (isProvidingCoverage !== null) {
      setCoverageData({ ...coverageData, openDialog: false, decision: isProvidingCoverage });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProvidingCoverage]);

  useEffect(() => {
    if (coverageData?.decision && coverageData?.openDialog === false) {
      handleConfirmClick();
    } else if (coverageData?.decision === false && coverageData?.openDialog === false) {
      setIsProvidingCoverage(null);
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverageData]);

  useEffect(() => {
    if (createdByUserOptions?.isCreatedByUser) {
      setCurrentUserDetails({
        isReverting: false,
        statusId: createdByUserOptions?.statusId,
      });
    } else if (recipientUserOptions?.isRecipientUser) {
      setCurrentUserDetails({
        isReverting:
          recipientUserOptions?.statusId === TaskStatus.Completed ||
          recipientUserOptions?.statusId === TaskStatus.Cancelled,
        statusId: recipientUserOptions?.statusId,
      });
    } else {
      setCurrentUserDetails(null);
    }
    //
  }, [createdByUserOptions, recipientUserOptions]);

  return (
    <>
      {!isExternal && !showRefreshModal ? (
        <FormProvider {...formMethods}>
          <GenericDialog
            open={open}
            fullWidth
            onClose={onCloseCall}
            variant="blue"
            className={classes.modalContainer}
            dialogTitleProps={{
              text:
                version === StatusUpdateModalVersion.SUBTASK
                  ? t('notification.taskStatusModal.subtaskModalTitle')
                  : t('notification.taskStatusModal.modalTitle'),
              closeButton: true,
            }}
            dialogActions={[
              {
                text: t('notification.taskStatusModal.cancelBtn'),
                variant: 'contained',
                color: 'tertiary',
                useDialogOnCloseEvent: true,
              },
              {
                text: t('notification.taskStatusModal.submitBtn'),
                variant: 'contained',
                disabled:
                  readOnly ||
                  disableSubmit() ||
                  isCallingTaskdetailsAPI ||
                  isCallingStatusUpdateAPI ||
                  checkCoverageActionDisable(),
                tooltipProps: {
                  tooltipContent: t('global.readOnlyTooltip'),
                  disabled: !readOnly,
                },
                onClick: async e => {
                  const coverage = !isExternal && checkCoverage();
                  if (!coverage) {
                    handleConfirmClick();
                  }
                },
              },
            ]}
          >
            <Grid container spacing={5} data-testid="task-status-update-modal-container">
              {(isCallingTaskdetailsAPI || isCallingStatusUpdateAPI) && (
                <CircularProgress className={classes.validationLoader} />
              )}
              {serverErrorBanner && (
                <Grid item xs={12} className={classes.banner}>
                  <TaskFormBanner message={t('notification.taskStatusModal.apiCallError')} />
                </Grid>
              )}
              {activeCoverage?.length > 0 && (
                <Grid item xs={12} sx={{ marginTop: '24px' }}>
                  <SelectWithAvatar
                    selectedCoverageOption={selectedCoverageOption}
                    handleCoverageSelection={handleCoverageSelection}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                className={activeCoverage?.length > 0 ? classes.coverageFirstItem : classes.firstGridItem}
                data-testid="task-status-radio-btns-group"
              >
                <Controller
                  control={control}
                  name={'taskStatus'}
                  rules={{ required: true }}
                  render={({ ref, onChange, ...rest }) => (
                    <RadioGroup aria-labelledby="task-status-update-modal-group" name="task-status-update-modal">
                      {currentUserDetails?.isReverting && (
                        <React.Fragment>
                          <FormControlLabel
                            value={1}
                            checked={statusReason.statusId === 1}
                            control={<Radio />}
                            label={t('notification.taskStatusModal.statusLabels.new')}
                            onChange={e => {
                              setStatusReason(status => ({ statusUpdateReason: '', statusId: 1 }));
                              setShowReason(false);
                              setShowOther(false);
                              onChange(1);
                            }}
                          />
                          <FormControlLabel
                            value={1}
                            checked={statusReason.statusId === 2}
                            control={<Radio />}
                            label={t('notification.taskStatusModal.statusLabels.pending')}
                            onChange={e => {
                              setStatusReason(status => ({ statusUpdateReason: '', statusId: 2 }));
                              setShowReason(false);
                              setShowOther(false);
                              onChange(2);
                            }}
                          />
                        </React.Fragment>
                      )}
                      <FormControlLabel
                        value={3}
                        checked={statusReason.statusId === 3}
                        control={<Radio />}
                        disabled={
                          currentUserDetails?.isReverting
                            ? currentUserDetails?.statusId === TaskStatus.Completed
                            : recipientUserOptions
                            ? false
                            : true
                        }
                        label={t('notification.taskStatusModal.statusLabels.complete')}
                        data-testid="task-status-radio-btn-complete"
                        onChange={e => {
                          setStatusReason(status => ({ statusUpdateReason: '', statusId: 3 }));
                          setShowReason(false);
                          setShowOther(false);
                          onChange(3);
                        }}
                      />

                      <FormControlLabel
                        value={4}
                        checked={
                          statusReason.statusId === 4 && statusReason.statusUpdateReasonId !== TaskStatusReason.ERROR
                        }
                        control={<Radio />}
                        label={t('notification.taskStatusModal.statusLabels.unableComplete')}
                        data-testid="task-status-radio-btn-unableComplete"
                        disabled={
                          currentUserDetails?.isReverting
                            ? currentUserDetails?.statusId === TaskStatus.Cancelled
                            : recipientUserOptions
                            ? false
                            : true
                        }
                        onChange={e => {
                          setStatusReason(status => ({
                            statusUpdateReason: t('notification.taskStatusModal.cancelReason.candidateUnresponsive'),
                            statusId: 4,
                            statusUpdateReasonId: TaskStatusReason.NORESPONSE_CANDIDATE,
                          }));
                          setShowReason(true);
                          onChange(4);
                        }}
                      />
                      {showReasons && (
                        <Controller
                          control={control}
                          name={'taskReason'}
                          render={({ ref, onChange, ...rest }) => (
                            <div className={classes.reasonsContainer}>
                              <FormControlLabel
                                value={t('notification.taskStatusModal.cancelReason.candidateUnresponsive')}
                                checked={statusReason.statusUpdateReasonId === TaskStatusReason.NORESPONSE_CANDIDATE}
                                control={<Radio />}
                                label={t('notification.taskStatusModal.cancelReason.candidateUnresponsive')}
                                data-testid="task-status-radio-cancel-reason-candidate-unresponsive"
                                onChange={() => {
                                  setStatusReason(status => ({
                                    ...status,
                                    statusUpdateReason: t(
                                      'notification.taskStatusModal.cancelReason.candidateUnresponsive',
                                    ),
                                    statusUpdateReasonId: TaskStatusReason.NORESPONSE_CANDIDATE,
                                  }));
                                  setShowOther(false);
                                  onChange(t('notification.taskStatusModal.cancelReason.candidateUnresponsive'));
                                }}
                              />
                              <FormControlLabel
                                value={t('notification.taskStatusModal.cancelReason.clientUnresponsive')}
                                checked={statusReason.statusUpdateReasonId === TaskStatusReason.NORESPONSE_CLIENT}
                                control={<Radio />}
                                label={t('notification.taskStatusModal.cancelReason.clientUnresponsive')}
                                data-testid="task-status-radio-cancel-reason-client-unresponsive"
                                onChange={e => {
                                  setStatusReason(status => ({
                                    ...status,
                                    statusUpdateReason: t(
                                      'notification.taskStatusModal.cancelReason.clientUnresponsive',
                                    ),
                                    statusUpdateReasonId: TaskStatusReason.NORESPONSE_CLIENT,
                                  }));
                                  setShowOther(false);
                                  onChange(t('notification.taskStatusModal.cancelReason.cancelReason'));
                                }}
                              />
                              <FormControlLabel
                                value={t('notification.taskStatusModal.cancelReason.teamUnresponsive')}
                                checked={statusReason.statusUpdateReasonId === TaskStatusReason.NORESPONSE_TEAM}
                                control={<Radio />}
                                label={t('notification.taskStatusModal.cancelReason.teamUnresponsive')}
                                data-testid="task-status-radio-cancel-reason-team-unresponsive"
                                onChange={e => {
                                  setStatusReason(status => ({
                                    ...status,
                                    statusUpdateReason: t('notification.taskStatusModal.cancelReason.teamUnresponsive'),
                                    statusUpdateReasonId: TaskStatusReason.NORESPONSE_TEAM,
                                  }));
                                  setShowOther(false);
                                  onChange(t('notification.taskStatusModal.cancelReason.teamUnresponsive'));
                                }}
                              />
                              <FormControlLabel
                                value={t('notification.taskStatusModal.cancelReason.declined')}
                                checked={statusReason.statusUpdateReasonId === TaskStatusReason.DECLINED}
                                control={<Radio />}
                                label={t('notification.taskStatusModal.cancelReason.declined')}
                                data-testid="task-status-radio-cancel-reason-declined"
                                onChange={e => {
                                  setStatusReason(status => ({
                                    ...status,
                                    statusUpdateReason: t('notification.taskStatusModal.cancelReason.declined'),
                                    statusUpdateReasonId: TaskStatusReason.DECLINED,
                                  }));
                                  setShowOther(false);
                                  onChange(t('notification.taskStatusModal.cancelReason.declined'));
                                }}
                              />
                              <FormControlLabel
                                value={t('notification.taskStatusModal.cancelReason.other')}
                                checked={showOther}
                                control={<Radio />}
                                label={t('notification.taskStatusModal.cancelReason.other')}
                                data-testid="task-status-radio-cancel-reason-other"
                                onChange={e => {
                                  setShowOther(true);
                                  setStatusReason(status => ({
                                    ...status,
                                    statusUpdateReason: t('notification.taskStatusModal.cancelReason.other'),
                                    statusUpdateReasonId: TaskStatusReason.OTHER,
                                  }));
                                  onChange(t('notification.taskStatusModal.cancelReason.other'));
                                }}
                              />
                              {showOther && (
                                <Controller
                                  control={control}
                                  name={'otherReason'}
                                  rules={{
                                    required: true,
                                    maxLength: {
                                      value: 100,
                                      message: t('notification.taskStatusModal.maxLimitLabel'),
                                    },
                                  }}
                                  render={({ ref, onChange, ...rest }) => (
                                    <div className={classes.otherInput}>
                                      <TextField
                                        variant="filled"
                                        color="primary"
                                        inputProps={{ maxLength: 100 }}
                                        className={classes.inputs}
                                        error={isDirty && errors?.otherReason ? true : false}
                                        disabled={false}
                                        id="otherReason"
                                        label={t('notification.taskStatusModal.otherLabel')}
                                        data-testid="task-status-input-other"
                                        onChange={e => {
                                          const text = e.target.value;
                                          if (text?.length === 100) {
                                            setError('otherReason', {
                                              type: 'maxLength',
                                              message: t('notification.taskStatusModal.maxLimitLabel'),
                                            });
                                          } else if (text?.length <= 100) {
                                            clearErrors('otherReason');
                                          }
                                          setStatusReason(status => ({
                                            ...status,
                                            statusUpdateReason: text,
                                          }));
                                          onChange(e);
                                        }}
                                        helperText={isDirty && errors?.otherReason && errors?.otherReason?.message}
                                      />
                                    </div>
                                  )}
                                />
                              )}
                            </div>
                          )}
                        />
                      )}
                      {createdByUserOptions?.isCreatedByUser && !currentUserDetails?.isReverting && (
                        <FormControlLabel
                          value={4}
                          checked={
                            statusReason.statusId === 4 && statusReason.statusUpdateReasonId === TaskStatusReason.ERROR
                          }
                          disabled={!createdByUserOptions?.isCreatedByUser}
                          control={<Radio />}
                          label={t('notification.taskStatusModal.statusLabels.createdError')}
                          data-testid="task-status-radio-btn-createdError"
                          onChange={e => {
                            setStatusReason(status => ({
                              statusId: 4,
                              statusUpdateReason: t('notification.taskStatusModal.statusLabels.createdError'),
                              statusUpdateReasonId: TaskStatusReason.ERROR,
                            }));
                            setShowReason(false);
                            setShowOther(false);
                            onChange(4);
                          }}
                        />
                      )}
                    </RadioGroup>
                  )}
                />
              </Grid>
              {statusReason?.statusUpdateReasonId !== TaskStatusReason.ERROR && (
                <Controller
                  control={control}
                  name={'applyAll'}
                  render={({ ref, onChange, ...rest }) => (
                    <div className={classes.labelStyle}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>{t('notification.taskStatusModal.statusLabels.applyAll')}</Typography>
                        <Switch
                          checked={isApplyAll}
                          onChange={e => {
                            setApplyAll(e.target.checked);
                            dispatch(taskDetailsActions.setStatusAppliedAll(e.target.checked));
                          }}
                          name="applyAll"
                          color="primary"
                        />
                        <Typography>{t('detailsPage.confirmation.yes')}</Typography>
                      </Stack>
                    </div>
                  )}
                />
              )}
            </Grid>
            <GenericDialog
              open={showConfirmDialog}
              className={classes.refreshModal}
              dialogTitleProps={{
                text: t('notification.taskStatusModal.statusLabels.statusDialog'),
              }}
              dialogActions={[
                {
                  text: t('notification.taskStatusModal.cancelBtn'),
                  variant: 'contained',
                  color: 'tertiary',
                  onClick: e => {
                    setConfirmDialog(false);
                    dispatch(taskDetailsActions.setStatusAppliedAll(false));
                  },
                },
                {
                  text: t('notification.taskStatusModal.submitBtn'),
                  variant: 'contained',
                  disabled: readOnly || disableSubmit(),
                  onClick: async e => {
                    setConfirmDialog(false);
                    if (!readOnly) {
                      if (showOther) {
                        await trigger('otherReason');
                      }
                      handleSubmit(submitTask)().catch(err => {
                        setServerErrorBanner(true);
                      });
                    }
                  },
                },
              ]}
            >
              <React.Fragment>
                <div>{t('notification.taskStatusModal.statusLabels.statusConfirmMessage')}</div>
              </React.Fragment>
            </GenericDialog>
            <GenericDialog
              open={createdInErrorWarning}
              className={classes.refreshModal}
              dialogTitleProps={{
                text: t('notification.taskStatusModal.statusLabels.statusDialog'),
              }}
              dialogActions={[
                {
                  text: t('notification.taskStatusModal.cancelBtn'),
                  variant: 'contained',
                  color: 'tertiary',
                  onClick: e => {
                    setCreatedInErrorWarning(false);
                  },
                },
                {
                  text: t('notification.taskStatusModal.submitBtn'),
                  variant: 'contained',
                  disabled: readOnly || disableSubmit(),
                  onClick: async e => {
                    setCreatedInErrorWarning(false);
                    if (!readOnly) {
                      if (showOther) {
                        await trigger('otherReason');
                      }
                      handleSubmit(submitTask)().catch(err => {
                        setServerErrorBanner(true);
                      });
                    }
                  },
                },
              ]}
            >
              {version === StatusUpdateModalVersion.PARENT_TASK
                ? t('notification.taskStatusModal.statusLabels.createdInErrorParent')
                : t('notification.taskStatusModal.statusLabels.createdInErrorSubTask')}
            </GenericDialog>
          </GenericDialog>
          <CoverageConfirmationModal
            openDialog={coverageData?.openDialog}
            handleDecision={setIsProvidingCoverage}
            action={coverageData?.action}
            coveredUser={coverageData?.coveredUser}
          />
        </FormProvider>
      ) : (
        <GenericDialog
          open={showRefreshModal}
          className={classes.refreshModal}
          dialogActions={[
            {
              text: t('notification.taskStatusModal.refreshModal.btn'),
              variant: 'contained',
              onClick: () => {
                dispatch(taskDetailsActions.setTaskStatusUpdateFlag('REFRESH'));
                setShowRefreshModal(false);
              },
            },
          ]}
        >
          <div className={classes.flexContainer}>
            <WarningIcon style={{ fill: '#C9010D', height: '60px', width: '60px', marginBottom: '30px' }} />
            <div className={classes.refreshModalTitle}>{t('notification.taskStatusModal.refreshModal.title')}</div>
            <div className={classes.refreshModalBody}>
              {latestDetail?.statusUpdatedByName +
                ', ' +
                moment.tz(latestDetail?.statusUpdatedDate, 'America/Los_Angeles').format('L hh:mm [PST]') +
                ' '}
              {t('notification.taskStatusModal.refreshModal.body')}
            </div>
          </div>
        </GenericDialog>
      )}
    </>
  );
};
