import AddIcCallIcon from '@mui/icons-material/AddIcCallOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import FilterIcon from 'app/assets/images/Notification/Filter.svg';
import { ConversationCount } from 'app/components/Notification/Common/ConversationCount';
import { ChannelType, ContactType, SendType, UseType } from 'app/components/Notification/Constants';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { convertDateToPST, convertToPST } from 'app/helpers/dateHelper';
import { CommunicationLogPayload, ICandidateDetails, IPhoneNumber } from 'app/models/Candidate/CandidateProfile';
import { updateCandidatePersonalInfo } from 'app/services/CandidateServices/CandidateServices';
import { GetUserLogs } from 'app/services/NotificationServices/NotificationService';
import _ from 'lodash';
import moment from 'moment';
import { selectUser } from 'oidc/user.selectors';
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CapitalizeFirstLetter, Concatenate } from 'utils/string/string';
import { formatTime } from '../../helper/StringFormatter';
import { candidateDetailActions, loadUserLogs } from '../Profile/CandidateDetails.redux';
import {
  autoScroll,
  candidateDetailsSelection,
  communicationLog,
  disableLoadMore,
  gridLoadingSelector,
  saveContactTabStatus,
} from '../Profile/CandidateDetails.selector';
import { ICandidateHeaderDetails } from './CandidateHeader';
import { CustomContactEdit } from './Common/CustomContactEdit';
import CommunicationLogsFilter, { DATED } from './CommunicationLogsFilter';
import { useEnableSmsCommunication } from 'oidc/CommunicationEnabler';
import { ICandidateDrawerConsent } from '@AMIEWEB/Common/Drawer/SidePanel';
import { checkUserRolesForAccess } from '../CandidateTabPanel/CommunicationHistoryTab/helper';

const useStyles = makeStyles()(theme => ({
  root: {
    flexGrow: 1,
    padding: '10px 0px 10px 24px',
    backgroundColor: '#fff',
    position: 'inherit',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
  },
  filterTitle: {
    color: '#333333',
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: '1%',
    paddingTop: '12px',
  },
  emailSubject: {
    display: 'grid',
  },
  smsNotes: {
    fontWeight: 400,
  },
  titleDiffer: {
    fontSize: '16px',
    fontWeight: 600,
    paddingTop: '10px',
    color: '#333333',
    paddingBottom: '10px',
  },
  loadMoreButton: {
    width: '335px',
    height: '38px',
    marginTop: '10px',
  },
  communicationTimeLine: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 0 10px 20px',
  },
  filterIcon: { border: '1px solid #888', borderRadius: '5px', height: '3.45vh' },

  appliedFilterIcon: {
    color: '#006FB9',
    border: '1px solid #006FB9',
    borderRadius: '5px',
    height: '3.45vh',
  },

  small: {
    margin: '7%',
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  avatarBackground: {
    backgroundColor: '#006FB9',
  },
  box: {
    backgroundColor: theme.palette.framework.system.whisper,
  },
  comDetails: {
    fontSize: '1rem',
    color: '#555555',
    flexDirection: 'column',
    width: '300px',
  },
  conversationComment: {
    width: '250px',
    paddingTop: 5,
    color: '#555555',
    fontSize: '0.95rem',
  },
  noCommunication: {
    alignSelf: 'center',
    color: '#555555',
    fontWeight: 500,
    paddingTop: '65px',
  },
  tooltipArrow: {
    color: '#fff',
    '&::before': {
      border: '1px solid #555555',
    },
  },
  tooltipBackground: {
    backgroundColor: '#fff',
    color: '#555555',
    border: '1px solid #555555',
    fontSize: '12px',
  },

  divider: {
    margin: '3% 0 0 0',
    height: '2px',
    color: '#e3dfdf',
    width: '105%',
  },
  alt: { color: '#006fb9', fontWeight: 500, fontSize: '0.95rem' },
  header: { fontSize: '0.95rem', lineHeight: '25px' },
  expandIcon: {
    right: '-80px',
    position: 'relative',
    borderRadius: '50%',
    borderWidth: '0.5px',
    background: '#eaeaea',
    color: '#838383',
  },
  primaryLogInfo: {
    fontSize: 15,
    wordBreak: 'break-all',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    paddingRight: '5px',
  },
}));

interface IContactDrawer {
  onClose: () => void;
  isSearchGrid?: boolean;
  rowId?: string;
  candidateDetails?: ICandidateDetails;
  handleNotificationData?: (ChannelType, ContactType, manualLog?) => void;
  updateCandidateContactInfo?: (rowID: string, candidateInfo: ICandidateDetails) => void;
  isViewDetails?: boolean;
  consentsData?: ICandidateDrawerConsent;
}

export interface ICreateCommunicationLogChart {
  source: any;
  color?: string;
  alt: string;
  classes: any;
  data?: any;
  isLast: boolean;
  index: number;
}

export const ContactTab = (props: IContactDrawer) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const {
    isSearchGrid,
    rowId,
    updateCandidateContactInfo,
    handleNotificationData,
    consentsData = {
      hasCallConsents: true,
      hasEmailConsents: true,
      hasTextConsents: true,
    },
  } = props;
  const notificationData = useSelector(notificationSelection);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isLoading = useSelector(gridLoadingSelector);
  const communicationLogRef = useRef<HTMLDivElement | null>(null);
  let candidateDetails = useSelector(candidateDetailsSelection);
  const candidateLogs = useSelector(communicationLog);
  const disableLoadMoreButton = useSelector(disableLoadMore);
  if (
    props?.candidateDetails &&
    (!candidateDetails ||
      candidateDetails?.travelerId === 0 ||
      !(
        candidateDetails?.travelerId === props?.candidateDetails?.travelerId &&
        candidateDetails?.brandId === props?.candidateDetails?.brandId
      ))
  ) {
    // dispatch(
    //   candidateDetailActions.setCandidateDetails({
    //     ...props.candidateDetails,
    //   }),
    // );
    candidateDetails = props.candidateDetails;
  }

  const statusType = useSelector(saveContactTabStatus);

  const communicationsLog = candidateLogs?.results
    ?.slice()
    .filter(com => com.channel === 'Conversation' || com.channel === 'Voice' || com.channel === 'Email');

  const [primaryEmail, setPrimaryEmail] = useState<string>(candidateDetails?.primaryEmail || '');
  const [secondaryEmail, setSecondaryEmail] = useState<string>(candidateDetails?.secondaryEmail || '');

  const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState<string>(
    candidateDetails?.phoneNumbers?.find(x => x.phoneTypeId === 1)?.phoneNumber || '',
  );
  const [primaryPhoneNumberExtNotes, setPrimaryPhoneNumberExtNotes] = useState<string>(
    (candidateDetails?.phoneNumbers || []).find(x => x.phoneNumber === candidateDetails?.primaryPhoneNumber)
      ?.extensionNotes || '',
  );

  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState<string>(
    candidateDetails?.phoneNumbers?.find(x => x.phoneTypeId === 6)?.phoneNumber || '',
  );
  const [secondaryPhoneNumberExtNotes, setSecondaryPhoneNumberExtNotes] = useState<any>(
    (candidateDetails?.phoneNumbers || []).find(x => x.phoneNumber === candidateDetails?.mobilePhoneNumber)
      ?.extensionNotes || '',
  );
  const scrolled = useSelector(autoScroll);
  const enableSmsCommunication = useEnableSmsCommunication();

  const handleConversationIconClick = async log => {
    const dailyConversationLogsPayload: CommunicationLogPayload = {
      contactId: log.message.tos?.find(Boolean)?.contactId,
      channel: ['SMS'],
      initiatedBy: (log.message && log.message.sender && log.message.sender.phoneNumber) ?? '',
      startDate: moment(new Date(log.publishedOn)).startOf('day').toDate(),
      endDate: moment(new Date(log.publishedOn)).endOf('day').toDate(),
      pageSize: log?.message?.outboundCount + log?.message?.inboundCount,
      pageNumber: 1,
    };

    var dailyConversation = await GetUserLogs(dailyConversationLogsPayload);

    const data = {
      publishedOn: log.publishedOn,
    };
    const sms = notificationData.sms;
    if ((sms.open || sms.minimized) && (data['body']?.length > 0 || sms?.newNotes || sms.attachments !== undefined)) {
      dispatch(
        notificationDataActions.setSmsInteraction({
          open: true,
          minimized: false,
          readonly: false,
        }),
      );
      if (notificationData.sms.data?.tos.find(Boolean)?.contactId !== log.message?.tos.find(Boolean).contactId) {
        dispatch(notificationDataActions.setSnackBarData({ channel: 'sms', manual: false, changeWarning: true }));
      }
    } else {
      const candidateName = log.message.tos.find(Boolean).name.split(' ');
      dispatch(
        notificationDataActions.setNotificationData({
          ...notificationData,

          sms: {
            ...notificationData.sms,
            chatHistory: {
              chats: dailyConversation,
              candidateDetails: {
                name: {
                  first: candidateName[0],
                  last: candidateName?.length > 1 ? candidateName[candidateName?.length - 1] : '',
                },
              },
            },
            data: {
              publishedOn: log.publishedOn,
              ...log.message,
              channel: ChannelType.sms,
              brand: candidateDetails.brand,
              brandId: candidateDetails.brandId?.toString(),
              associatedRecords: log.associatedRecords,
              sendType: SendType.one_to_one,
              useType: UseType.Candidate,
              userRole: Concatenate(user?.userInfo?.roles || [], ','),
            },
            open: true,
            minimized: false,
            readonly: log.message.sender.userId === user?.userInfo?.employeeId?.toString() ? false : true,
          },
          snackbarData: null,
        }),
      );
    }
  };

  const identifySmsCommunicationIcon = (communicationLog, fontSize) => {
    const hasUserAccess = checkUserRolesForAccess(communicationLog, user);
    if (enableSmsCommunication && hasUserAccess) {
      return (
        <ChatOutlinedIcon
          style={{ fontSize: fontSize, cursor: 'pointer' }}
          onClick={() => handleConversationIconClick(communicationLog)}
        />
      );
    } else
      return (
        <Tooltip
          classes={{
            tooltip: classes.tooltipBackground,
            arrow: classes.tooltipArrow,
          }}
          arrow
          title={`Access to this conversation is not available`}
        >
          <ChatOutlinedIcon style={{ fontSize: fontSize }} />
        </Tooltip>
      );
  };

  const identifyCommunicationIcon = (
    communicationLog: any,
    fontSize: string = '1.5rem',
    actAsBoolean: boolean = false,
  ) => {
    switch (communicationLog?.channel) {
      case 'Conversation':
        return actAsBoolean ? true : identifySmsCommunicationIcon(communicationLog, fontSize);

      case 'Voice':
        return actAsBoolean ? true : <CallOutlinedIcon style={{ fontSize: fontSize }} />;
      case 'Email':
        return actAsBoolean ? true : <EmailOutlinedIcon style={{ fontSize: fontSize }} />;

      default:
        return actAsBoolean ? false : null;
    }
  };

  const Header = (props: ICreateCommunicationLogChart) => {
    const { source, alt, classes, data, isLast = false, index } = props;

    const EmailSubject = alt === 'Email' && data.message?.subject ? CapitalizeFirstLetter(data.message?.subject) : '';
    const Notes =
      (alt === 'Conversation' || alt === 'Voice') && data.response?.notes
        ? CapitalizeFirstLetter(data.response?.notes)
        : '';

    return (
      <Grid container spacing={2} data-testid="log-container">
        <Grid item xs={2} md={2}>
          <Avatar className={`${classes.small} ${classes.avatarBackground}`}>{source}</Avatar>
        </Grid>
        <Grid item xs={9} md={9}>
          <Grid container>
            <Grid item xs={8} md={8}>
              <div className={classes.comDetails} data-testid="Communication-details">
                <Typography className={classes.header}>
                  <span className={classes.alt} data-testid="Communication-type">
                    {alt === 'Voice' ? 'Call ' : alt === 'Conversation' ? 'SMS ' : alt + ' '}
                  </span>
                  <span data-testid="communication-date">{convertToPST(data?.publishedOn, 'MM/DD/YYYY')}</span>
                  <span style={{ padding: '0 2.5%' }}>{'|'}</span>
                  {alt === 'Conversation' ? (
                    <ConversationCount
                      outboundCount={data?.message.outboundCount}
                      inboundCount={data?.message.inboundCount}
                      undeliveredCount={data.message.undeliveredMessagesCount}
                    />
                  ) : (
                    `${formatTime(convertToPST(data?.publishedOn, 'HH:mm'))} PST`
                  )}
                </Typography>
              </div>
              <div className={classes.conversationComment} data-testid="sender-details">
                {(alt === 'Conversation' || alt === 'Voice' || alt === 'Email') && (
                  <span
                    className={`${classes.smsNotes} ${alt === 'Email' || alt === 'Voice' ? classes.emailSubject : undefined
                      }`}
                  >
                    <span className={classes.primaryLogInfo}>
                      <Tooltip
                        arrow
                        classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
                        title={Concatenate(data.userRoles || [], ', ')}
                        data-testid="user-roles"
                      >
                        <span
                          className={classes.primaryLogInfo}
                          data-testid="sender-name"
                        >{`${data.message?.sender?.name}`}</span>
                      </Tooltip>
                      {alt === 'Voice' ? (
                        <span className={classes.primaryLogInfo}>{`- ${CapitalizeFirstLetter(
                          data?.response?.disposition?.replace(/([A-Z][a-z])/g, ' $1').replace(/(\d)/g, ' $1') || '',
                        )}`}</span>
                      ) : alt === 'Email' ? (
                        data?.message?.tos &&
                          data?.message?.tos?.find(x => x?.contactId == candidateDetails?.travelerId)?.deliveryStatus ? (
                          <span className={classes.primaryLogInfo}>{`- ${CapitalizeFirstLetter(
                            data?.message?.tos?.find(x => x?.contactId == candidateDetails?.travelerId)?.deliveryStatus,
                          )}`}</span>
                        ) : null
                      ) : (
                        <span className={classes.primaryLogInfo}>{`-`}</span>
                      )}
                    </span>

                    {alt === 'Email'
                      ? EmailSubject.length === 0
                        ? 'No Subject'
                        : notificationData?.expanded?.[index]
                          ? EmailSubject
                          : EmailSubject.length > 45
                            ? EmailSubject.substring(0, 45) + '...'
                            : EmailSubject
                      : Notes.length === 0
                        ? 'No Note'
                        : notificationData?.expanded?.[index]
                          ? Notes
                          : Notes?.length > 45
                            ? Notes.substring(0, 45) + '...'
                            : Notes}
                  </span>
                )}
              </div>
            </Grid>
            <Grid item xs={4} md={4}>
              {notificationData?.expanded?.[index] ? (
                <ExpandLessIcon
                  style={{ cursor: 'pointer' }}
                  className={classes.expandIcon}
                  onClick={() => {
                    dispatch(notificationDataActions.setExpanded({ [index]: false }));
                  }}
                  data-testid="expand-less-icon"
                />
              ) : ((alt === 'Conversation' || alt === 'Voice') && Notes && Notes.length > 45) ||
                (alt === 'Email' && EmailSubject && EmailSubject.length > 45) ? (
                <ExpandMoreIcon
                  style={{
                    cursor:
                      data.channel === 'Conversation' && (data?.response?.notes?.length < 45 || !data?.response?.notes)
                        ? 'content-menu'
                        : 'pointer',
                    opacity:
                      data.channel === 'Conversation' &&
                        (data?.response?.notes?.length === 0 || !data?.response || data?.response?.notes?.length < 45)
                        ? '50%'
                        : '100%',
                  }}
                  className={classes.expandIcon}
                  data-testid="expand-more-icon"
                  onClick={() => {
                    dispatch(notificationDataActions.setExpanded({ [index]: true }));
                  }}
                />
              ) : (
                <div style={{ marginRight: '5px' }} />
              )}
            </Grid>
            <Collapse
              in={notificationData.expanded ? notificationData?.expanded[`${index}`] : false}
              timeout="auto"
              unmountOnExit
              style={{ marginTop: '6%' }}
            >
              {/* {Content(alt, data, classes)} */}
            </Collapse>
            {!isLast && <Divider className={classes.divider} />}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const CreateCommunicationLogChart = (props: ICreateCommunicationLogChart) => {
    return <div style={{ margin: '3% 0 0 0 ' }}>{Header(props)}</div>;
  };

  const handleLoadMoreOnClick = () => {
    const channels = _.cloneDeep(notificationData.logFilters?.channel);
    const initiatedBy = _.cloneDeep(notificationData.logFilters?.initiatedBy);
    const VoiceIndex = channels?.indexOf('Call');
    if (VoiceIndex !== undefined && VoiceIndex !== -1 && channels) {
      channels[VoiceIndex] = 'Voice';
    }
    const SMSIndex = channels?.indexOf('SMS');
    if (SMSIndex !== undefined && SMSIndex !== -1 && channels) {
      channels[SMSIndex] = 'Conversation';
    }

    const pageNumber = candidateLogs.pageNumber;
    const existingLogs = candidateLogs?.results;
    const payload = {
      startDate: notificationData.logFilters?.startDate
        ? new Date(notificationData.logFilters?.startDate || '')
        : undefined,
      endDate: notificationData.logFilters?.startDate
        ? new Date(notificationData.logFilters?.endDate || '')
        : undefined,
      periodType: DATED.find(x => x.value === notificationData.logFilters?.periodType)?.key || '',
      brandId: candidateDetails.brandId?.toString(),
      contactId: `${candidateDetails.travelerId}`,
      pageSize: candidateDetails.communicationLog.pageSize,
      channel: channels.length > 0 ? channels : ['Conversation', 'Email', 'Voice'],
      initiatedBy: initiatedBy === 'Me' ? user?.userInfo?.email : initiatedBy,
      pageNumber: pageNumber + 1,
    };
    dispatch(loadUserLogs({ payload, existingLogs }));
  };

  const handleChangeValue = (property, value) => {
    switch (property) {
      case 'primarynumber':
        setPrimaryPhoneNumber(value);
        break;
      case 'primarynumber-extensionnotes':
        setPrimaryPhoneNumberExtNotes(value);
        break;
      case 'primaryemail':
        setPrimaryEmail(value);
        break;
      case 'secondarynumber':
        setSecondaryPhoneNumber(value);
        break;
      case 'secondarynumber-extensionnotes':
        setSecondaryPhoneNumberExtNotes(value);
        break;

      case 'secondaryemail':
        setSecondaryEmail(value);
        break;
    }
  };

  const getPhoneNumber = () => {
    const primaryPhone: IPhoneNumber | undefined = (candidateDetails?.phoneNumbers || []).find(x => x.phoneTypeId == 1);

    const secondaryPhone: IPhoneNumber | undefined = (candidateDetails?.phoneNumbers || []).find(
      x => x.phoneTypeId == 6,
    );

    var phoneNumbers: IPhoneNumber[] | null = [];
    if (primaryPhone && primaryPhoneNumber?.length < 1) {
      phoneNumbers.push({
        ...primaryPhone,
        phoneNumber: null,
        extensionNotes: primaryPhoneNumberExtNotes,
        timeStamp: primaryPhone.timeStamp,
      });
    }
    if (secondaryPhone && secondaryPhoneNumber?.length < 1) {
      phoneNumbers.push({
        ...secondaryPhone,
        phoneNumber: null,
        extensionNotes: secondaryPhoneNumberExtNotes,
        timeStamp: secondaryPhone.timeStamp,
      });
    }
    if (primaryPhone && primaryPhoneNumber?.length > 0) {
      phoneNumbers.push({
        ...primaryPhone,
        phoneNumber: primaryPhoneNumber.length > 0 ? primaryPhoneNumber : null,
        extensionNotes: primaryPhoneNumberExtNotes,
        timeStamp: primaryPhone.timeStamp,
      });
    }
    if (secondaryPhone && secondaryPhoneNumber?.length > 0) {
      phoneNumbers.push({
        ...secondaryPhone,
        phoneNumber: secondaryPhoneNumber.length > 0 ? secondaryPhoneNumber : null,
        extensionNotes: secondaryPhoneNumberExtNotes,
        timeStamp: secondaryPhone.timeStamp,
      });
    }
    if (!primaryPhone && primaryPhoneNumber?.length > 0) {
      phoneNumbers.unshift({
        phoneTypeId: 1,
        phoneTypeDescription: 'Current',
        countryDialingCode: '+1',
        phoneNumber: primaryPhoneNumber.length > 0 ? primaryPhoneNumber : null,
        extensionNotes: primaryPhoneNumberExtNotes,
      });
    }
    if (!secondaryPhone && secondaryPhoneNumber?.length > 0) {
      phoneNumbers.unshift({
        phoneTypeId: 6,
        phoneTypeDescription: 'Cell phone',
        countryDialingCode: '+1',
        phoneNumber: secondaryPhoneNumber.length > 0 ? secondaryPhoneNumber : null,
        extensionNotes: secondaryPhoneNumberExtNotes,
      });
    }
    return phoneNumbers;
  };

  const replaceYesOrNo = (value: boolean | string) => {
    switch (value) {
      case true:
        return 'Y';
      case false:
        return 'N';
      case 'Y':
        return 'Y';
      case 'N':
        return 'N';
      default:
        return '';
    }
  };

  const handleSave = async (property: any) => {
    const newPhoneNumbers = getPhoneNumber();
    let updatedCandidateDetails = candidateDetails;
    updatedCandidateDetails = {
      ...updatedCandidateDetails,
      phoneNumbers: newPhoneNumbers,
      primaryEmail: primaryEmail,
      secondaryEmail: secondaryEmail?.length > 0 ? secondaryEmail : null,
      primaryPhoneNumber: primaryPhoneNumber.length > 0 ? primaryPhoneNumber : null,
      mobilePhoneNumber: secondaryPhoneNumber.length > 0 ? secondaryPhoneNumber : null,
    }
    dispatch(candidateDetailActions.setCandidateDetails(updatedCandidateDetails));
    switch (property) {
      case 'primarynumber':
        dispatch(candidateDetailActions.setContactTabSaveStatus({ primaryPhoneNumberSaveResult: 1 }));
        break;
      case 'primarynumber-extensionnotes':
        dispatch(candidateDetailActions.setContactTabSaveStatus({ primaryPhoneExtNotesSaveResult: 1 }));
        break;
      case 'primaryemail':
        dispatch(candidateDetailActions.setContactTabSaveStatus({ primaryEmailSaveResult: 1 }));
        break;
      case 'secondarynumber':
        dispatch(candidateDetailActions.setContactTabSaveStatus({ secondaryPhoneNumberSaveResult: 1 }));
        break;
      case 'secondarynumber-extensionnotes':
        dispatch(candidateDetailActions.setContactTabSaveStatus({ secondaryPhoneExtNotesSaveResult: 1 }));
        break;
      case 'secondaryemail':
        dispatch(candidateDetailActions.setContactTabSaveStatus({ secondaryEmailSaveResult: 1 }));
        break;
    }
    const updatedHeaderDetails: ICandidateHeaderDetails = {
      candidateId: candidateDetails?.travelerId,
      brandId: candidateDetails?.brandId,
      firstName: candidateDetails?.name.first || '',
      lastName: candidateDetails?.name.last || '',
      middleName: candidateDetails?.name.middle,
      goesBy: candidateDetails.goesBy,
      phoneNumbers: newPhoneNumbers,
      primaryEmail: primaryEmail,
      secondaryEmail: secondaryEmail?.length > 0 ? secondaryEmail : null,

      currentEmployeeId: user.userInfo?.employeeId || 0,
      currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
      timestamp: candidateDetails?.amieTravelerTimestamp as string,

      //These fields are not considered by the update candidate personalInfo endpoint
      primaryPhoneNumber: primaryPhoneNumber.length > 0 ? primaryPhoneNumber : null,
      mobilePhoneNumber: secondaryPhoneNumber.length > 0 ? secondaryPhoneNumber : null,
      availabilityDate: candidateDetails.availableOn,
      callBackDate: candidateDetails.callBackDate,
      recruiterId: candidateDetails.recruiter.recruiterId,
      candidateStatusId: candidateDetails.candidateStatusId,
      emergencyContactInfo: candidateDetails.emergencyContact?.phone != null ? candidateDetails.emergencyContact : null,
      applicationQuestions: {
        licenseInvestigationSuspension: replaceYesOrNo(candidateDetails.investigationSuspension),
        investigationSuspensionExplanation: candidateDetails.investigationSuspensionExplanation || '',
        usRightToWorkVerification: replaceYesOrNo(candidateDetails.rightToWorkVerification),
        visaType: candidateDetails.visaExplaination || '',
      },
    };
    const result = await updateCandidatePersonalInfo(updatedHeaderDetails);
    if (result) {
      dispatch(
        candidateDetailActions.setCandidateDetails({
          ...candidateDetails,
          phoneNumbers: result.phoneNumbers || undefined,
          primaryEmail: result?.primaryEmail || null,
          secondaryEmail: result?.secondaryEmail || null,
          primaryPhoneNumber: primaryPhoneNumber,
          mobilePhoneNumber: secondaryPhoneNumber,
        }),
      );
      switch (property) {
        case 'primarynumber':
          dispatch(candidateDetailActions.setContactTabSaveStatus({ primaryPhoneNumberSaveResult: 2 }));
          break;
        case 'primarynumber-extensionnotes':
          dispatch(candidateDetailActions.setContactTabSaveStatus({ primaryPhoneExtNotesSaveResult: 2 }));
          break;
        case 'primaryemail':
          dispatch(candidateDetailActions.setContactTabSaveStatus({ primaryEmailSaveResult: 2 }));
          break;
        case 'secondarynumber':
          dispatch(candidateDetailActions.setContactTabSaveStatus({ secondaryPhoneNumberSaveResult: 2 }));
          break;
        case 'secondarynumber-extensionnotes':
          dispatch(candidateDetailActions.setContactTabSaveStatus({ secondaryPhoneExtNotesSaveResult: 2 }));
          break;
        case 'secondaryemail':
          dispatch(candidateDetailActions.setContactTabSaveStatus({ secondaryEmailSaveResult: 2 }));
          break;
      }
    } else {
      dispatch(candidateDetailActions.setCandidateDetails(candidateDetails));
      switch (property) {
        case 'primarynumber':
          dispatch(candidateDetailActions.setContactTabSaveStatus({ primaryPhoneNumberSaveResult: 0 }));
          setPrimaryPhoneNumber(candidateDetails?.phoneNumbers?.find(x => x.phoneTypeId === 1)?.phoneNumber || '');
          break;
        case 'primarynumber-extensionnotes':
          dispatch(candidateDetailActions.setContactTabSaveStatus({ primaryPhoneExtNotesSaveResult: 0 }));
          setPrimaryPhoneNumberExtNotes(
            (candidateDetails?.phoneNumbers || [])?.find(x => x.phoneNumber === candidateDetails?.primaryPhoneNumber)
              ?.extensionNotes || '',
          );
          break;
        case 'primaryemail':
          dispatch(candidateDetailActions.setContactTabSaveStatus({ primaryEmailSaveResult: 0 }));
          setPrimaryEmail(candidateDetails?.primaryEmail || '');
          break;
        case 'secondarynumber':
          dispatch(candidateDetailActions.setContactTabSaveStatus({ secondaryPhoneNumberSaveResult: 0 }));
          setSecondaryPhoneNumber(candidateDetails?.phoneNumbers?.find(x => x.phoneTypeId === 6)?.phoneNumber || '');
          break;
        case 'secondarynumber-extensionnotes':
          dispatch(candidateDetailActions.setContactTabSaveStatus({ secondaryPhoneExtNotesSaveResult: 0 }));
          setSecondaryPhoneNumberExtNotes(
            (candidateDetails?.phoneNumbers || [])?.find(x => x.phoneNumber === candidateDetails?.mobilePhoneNumber)
              ?.extensionNotes || '',
          );
          break;
        case 'secondaryemail':
          dispatch(candidateDetailActions.setContactTabSaveStatus({ secondaryEmailSaveResult: 0 }));
          setSecondaryEmail(candidateDetails?.secondaryEmail || '');
          break;
      }
    }
    setTimeout(() => {
      dispatchValues(result);
    }, 1000);
    const dispatchValues = result => {
      if (result) {
        const updatedCandidateInfo = {
          ...candidateDetails,
          phoneNumbers: result.phoneNumbers,
          amieTravelerTimestamp: result.timeStamp,
          primaryPhoneNumber: primaryPhoneNumber,
          primaryEmail: primaryEmail,
          mobilePhoneNumber: secondaryPhoneNumber,
          secondaryEmail: secondaryEmail,
          primaryPhoneNumberLastUpdated: result.primaryPhoneNumberLastUpdated,
          primaryEmailLastUpdated: result.primaryEmailLastUpdated,
          mobilePhoneNumberLastUpdated: result.mobilePhoneNumberLastUpdated,
          secondaryEmailLastUpdated: result.secondaryEmailLastUpdated,
        };
        if (updateCandidateContactInfo || isSearchGrid) {
          updateCandidateContactInfo && updateCandidateContactInfo(rowId ?? '', updatedCandidateInfo);
        } else {
          dispatch(candidateDetailActions.setCandidateDetails(updatedCandidateInfo));
          switch (property) {
            case 'primarynumber':
              dispatch(candidateDetailActions.setContactTabSaveStatus({ primaryPhoneNumberSaveResult: '' }));
              break;
            case 'primarynumber-extensionnotes':
              dispatch(candidateDetailActions.setContactTabSaveStatus({ primaryPhoneExtNotesSaveResult: '' }));
              break;
            case 'primaryemail':
              dispatch(candidateDetailActions.setContactTabSaveStatus({ primaryEmailSaveResult: '' }));
              break;
            case 'secondarynumber':
              dispatch(candidateDetailActions.setContactTabSaveStatus({ secondaryPhoneNumberSaveResult: '' }));
              break;
            case 'secondarynumber-extensionnotes':
              dispatch(candidateDetailActions.setContactTabSaveStatus({ secondaryPhoneExtNotesSaveResult: '' }));
              break;
            case 'secondaryemail':
              dispatch(candidateDetailActions.setContactTabSaveStatus({ secondaryEmailSaveResult: '' }));
              break;
          }
        }
      }
    };
  };

  useEffect(() => {
    if (notificationData.showLogsFilter && !scrolled) {
      communicationLogRef?.current?.scrollIntoView({
        behavior: 'auto',
      });
      dispatch(candidateDetailActions.setScrolled(true));
    }
  }, [notificationData.showLogsFilter, scrolled]);
  const resetPrimaryPhoneNumber = () => {
    setPrimaryPhoneNumber(candidateDetails?.phoneNumbers?.find(x => x.phoneTypeId === 1)?.phoneNumber || '');
  };
  const resetPrimaryPhoneExtensionNotes = () => {
    setPrimaryPhoneNumberExtNotes(
      (candidateDetails?.phoneNumbers || []).find(x => x.phoneNumber === candidateDetails?.primaryPhoneNumber)
        ?.extensionNotes || '',
    );
  };
  const resetSecondaryPhoneNumber = () => {
    setSecondaryPhoneNumber(candidateDetails?.phoneNumbers?.find(x => x.phoneTypeId === 6)?.phoneNumber || '');
  };
  const resetSecondaryPhoneExtensionNotes = () => {
    setSecondaryPhoneNumberExtNotes(
      (candidateDetails?.phoneNumbers || []).find(x => x.phoneNumber === candidateDetails?.mobilePhoneNumber)
        ?.extensionNotes || '',
    );
  };
  const resetPrimaryEmail = () => {
    setPrimaryEmail(candidateDetails?.primaryEmail || '');
  };
  const resetSecondaryEmail = () => {
    setSecondaryEmail(candidateDetails?.secondaryEmail || '');
  };

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={1}>
          <CustomContactEdit
            data={primaryPhoneNumber}
            handleNotificationData={handleNotificationData}
            handleChangeValue={handleChangeValue}
            handleSave={handleSave}
            fieldType="number"
            fieldName={'primarynumber'}
            resetValue={resetPrimaryPhoneNumber}
            lastUpdatedDate={convertDateToPST(candidateDetails?.primaryPhoneNumberLastUpdated)}
            saveStatus={statusType?.primaryPhoneNumberSaveResult}
            extensionSaveStatus={statusType?.primaryPhoneExtNotesSaveResult}
            extensionNotes={primaryPhoneNumberExtNotes}
            resetExtensionNotes={resetPrimaryPhoneExtensionNotes}
            consentsData={consentsData}
          />
          <CustomContactEdit
            data={secondaryPhoneNumber}
            fieldType="number"
            handleNotificationData={handleNotificationData}
            handleChangeValue={handleChangeValue}
            handleSave={handleSave}
            fieldName={'secondarynumber'}
            resetValue={resetSecondaryPhoneNumber}
            lastUpdatedDate={convertDateToPST(candidateDetails?.mobilePhoneNumberLastUpdated)}
            saveStatus={statusType?.secondaryPhoneNumberSaveResult}
            extensionSaveStatus={statusType?.secondaryPhoneExtNotesSaveResult}
            extensionNotes={secondaryPhoneNumberExtNotes}
            resetExtensionNotes={resetSecondaryPhoneExtensionNotes}
            consentsData={consentsData}
          />

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <CustomContactEdit
            data={primaryEmail}
            handleNotificationData={handleNotificationData}
            handleChangeValue={handleChangeValue}
            fieldName={'primaryemail'}
            fieldType="email"
            handleSave={handleSave}
            resetValue={resetPrimaryEmail}
            lastUpdatedDate={convertDateToPST(candidateDetails?.primaryEmailLastUpdated)}
            saveStatus={statusType?.primaryEmailSaveResult}
            //For now we are not showing extension notes for primary email
            extensionSaveStatus={null}
            resetExtensionNotes={() => { }}
            consentsData={consentsData}
          />
          <CustomContactEdit
            data={secondaryEmail}
            handleNotificationData={handleNotificationData}
            handleChangeValue={handleChangeValue}
            handleSave={handleSave}
            fieldType="email"
            fieldName={'secondaryemail'}
            resetValue={resetSecondaryEmail}
            lastUpdatedDate={convertDateToPST(candidateDetails?.secondaryEmailLastUpdated)}
            saveStatus={statusType?.secondaryEmailSaveResult}
            //For now we are not showing extension notes for secondary email
            resetExtensionNotes={() => { }}
            extensionSaveStatus={null}
            consentsData={consentsData}
          />
        </Grid>
      </div>
      <Box className={classes.box} style={{ paddingBottom: props?.isViewDetails ? '70px' : '' }}>
        <Grid item xs={12}>
          <div ref={communicationLogRef} className={classes.communicationTimeLine}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={6}>
                <div ref={communicationLogRef} className={classes.titleDiffer} data-testid="title">
                  {t('Communication Log')}
                </div>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  onClick={() => {
                    dispatch(notificationDataActions.setShowFilters(!notificationData.showLogsFilter));
                  }}
                  className={notificationData?.showLogsFilter ? classes.appliedFilterIcon : classes.filterIcon}
                  aria-label="delete"
                  disabled={communicationsLog && communicationsLog?.length === 0}
                  size="small"
                  data-testid="filter-button"
                >
                  <img src={FilterIcon} alt="Filter" />
                </IconButton>
              </Grid>
              <Grid item xs={4}>
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<AddIcCallIcon />}
                  data-testid="log-call-button"
                  style={{
                    cursor: !(notificationData?.log?.open || notificationData?.log?.minimized)
                      ? 'pointer'
                      : 'context-menu',
                  }}
                  onClick={() =>
                    handleNotificationData !== undefined &&
                    !(notificationData?.log?.open || notificationData?.log?.minimized) &&
                    handleNotificationData(
                      ChannelType.voice,
                      candidateDetails?.primaryPhoneNumber
                        ? ContactType.primary
                        : candidateDetails?.mobilePhoneNumber
                          ? ContactType.secondary
                          : null,
                      true,
                    )
                  }
                >
                  {`Log Call`}
                </Button>
              </Grid>
            </Grid>
            {notificationData?.showLogsFilter && (
              <Box>
                <Typography className={classes.filterTitle}>{`Filters`}</Typography>
                <CommunicationLogsFilter candidateData={props.candidateDetails} />
                <Divider className={classes.divider} />
              </Box>
            )}
            <Grid container>
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    left: '50%',
                    bottom: '5%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 9999,
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {communicationsLog && communicationsLog?.length > 0
                    ? communicationsLog
                      ?.sort((a, b) => {
                        const dateA = new Date(a.publishedOn as string);
                        const dateB = new Date(b.publishedOn as string);
                        return dateB.getTime() - dateA.getTime();
                      })
                      .map((communicationLog, index) => {
                        return (
                          <CreateCommunicationLogChart
                            source={identifyCommunicationIcon(communicationLog)}
                            alt={communicationLog?.channel ?? 'Email'}
                            classes={classes}
                            data={communicationLog}
                            isLast={index + 1 === communicationsLog?.length}
                            index={index}
                          />
                        );
                      })
                    : !isLoading && (
                      <div
                        className={classes.noCommunication}
                        data-testid="no-logs"
                      >{`No Communication Log Exists`}</div>
                    )}
                </>
              )}
              {!isLoading &&
                candidateDetails?.communicationLog?.nextPageExists &&
                communicationsLog?.length > candidateDetails?.communicationLog.pageSize - 1 && (
                  <Button
                    className={classes.loadMoreButton}
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => handleLoadMoreOnClick()}
                    data-testid="load-more-button"
                    disabled={disableLoadMoreButton}
                  >
                    {t('Load More')}
                  </Button>
                )}
            </Grid>
          </div>
        </Grid>
      </Box>
    </>
  );
};
