import React from 'react';
import { useTranslation } from 'react-i18next';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';

export const Delete = ({
  openDialog,
  handleCancelAction,
  handleDeleteAction,
  deleteButtonId,
  confirmButtonId,
}: {
  openDialog: boolean;
  handleCancelAction: any;
  handleDeleteAction: any;
  deleteButtonId?: string;
  confirmButtonId?: string;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <GenericDialog
        open={openDialog}
        maxWidth="xs"
        dialogTitleProps={{ text: t('deleteModal.deleteConfirmation') }}
        dialogContentProps={{
          variant: 'text',
        }}
        dialogActions={[
          {
            id: deleteButtonId,
            text: t('deleteModal.confirmation.cancel'),
            onClick: handleCancelAction,
            color: 'tertiary',
          },
          {
            id: confirmButtonId,
            text: t('deleteModal.confirmation.yes'),
            onClick: handleDeleteAction,
            variant: 'contained',
          },
        ]}
      >
        {t('deleteModal.deleteMessage')}
      </GenericDialog>
    </>
  );
};
