import { GlanceTile } from 'app/components/Common/GlanceTile/GlanceTile';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import VerifiedIcon from 'app/assets/images/CandidateProfile/verified-24px.svg';
import EventIcon from 'app/assets/images/CandidateProfile/event-24px.svg';
import FileSent from 'app/assets/images/CandidateProfile/FilesSent.svg';
import EventNoteIcon from 'app/assets/images/CandidateProfile/event_note-24px.svg';
import PersonSearch from 'app/assets/images/CandidateProfile/person_search-24px.svg';
import LastContacted from 'app/assets/images/CandidateProfile/LastContacted.svg';
import JobInterested from 'app/assets/images/CandidateProfile/job_Interest-24px.svg';
import { makeStyles } from 'tss-react/mui';
import { RouteParams } from '../Profile/CandidateProfile';
import { CustomModal } from 'app/components/Common/CustomModal/CustomModal';
import { GetMomentOfDate } from 'utils/dates/moment';
import { ModalTableHeader } from 'app/models/Orders/OrderDetails';
import { PlacementInfo } from 'app/models/Candidate/CandidateProfile';
import { usePromiseTracker } from 'react-promise-tracker';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { candidateDetailsSelection, placementSkillsetExperienceSelector } from '../Profile/CandidateDetails.selector';
import { panelActions, PanelChoice } from '../PanelOptions/PanelOption.redux';
import { jobMatchActions } from '../CandidateJobMatchGrid/JobMatch.redux';
import { selectJobMatchCount, selectMatchServiceFailure } from '../CandidateJobMatchGrid/JobMatch.selectors';
import {
  selectJobInterestsCount,
  selectInterestServiceFailure,
} from '../CandidateJobInterestGrid/JobInterest.selector';
import { jobInterestActions } from '../CandidateJobInterestGrid/JobInterest.redux';
import { Concatenate } from 'utils/string/string';
import { convertToPST } from 'app/helpers/dateHelper';
import { useDecision } from '@optimizely/react-sdk';
import {
  ff_candidate_ui_job_interests,
  ff_candidate_ui_job_match,
  ff_candidate_ui_view_SupervisorCaseLoad,
} from 'app/constants/FeatureFlags/Candidate/Keys';
import { PlacementOrderType } from 'app/models/Placement/PlacementDetails';
import { candidateDetailActions } from '../Profile/CandidateDetails.redux';
import { missingField } from 'app/constants';
import { formatEngagementDate, getExperienceData, getTotalValidExperienceFromSkillset } from './helper';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { Box, Skeleton, Typography, useTheme } from 'amn-ui-core';
import { selectWorkExperienceView } from 'store/redux-store/candidate-work-experience/selector';

const PlacementsHeader: ModalTableHeader[] = [
  { title: 'placementId', value: 'Placement ID' },
  { title: 'facilityName', value: 'Facility Name' },
  { title: 'startDate', value: 'Start Date' },
  { title: 'endDate', value: 'End Date' },
  { title: 'placementStatus', value: 'Status' },
];

const SkillsHeader: ModalTableHeader[] = [
  { title: 'skillset', value: 'Skill Set' },
  { title: 'lastThreeYearsYearsOfExperience', value: 'Last 3 Years' },
  { title: 'totalYearsOfExperience', value: 'Total Experience' },
];

const FilesHeader: ModalTableHeader[] = [
  { title: 'placementId', value: 'Placement ID' },
  { title: 'dateSent', value: 'Date Sent' },
  { title: 'facilityName', value: 'Facility Name' },
  { title: 'placementStatus', value: 'Status' },
];

enum PlacementStatus {
  FilesSent = 'Sent',
  RequestFileOut = 'Request File Out',
}

const useStyles = makeStyles()(theme => ({
  container: {
    flexWrap: 'nowrap',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  grid: {
    display: 'inline-grid',
    width: '100%',
  },
}));

export const QuickGlanceBar = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const params = useParams<RouteParams>();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const { workExperienceData } = useSelector(selectWorkExperienceView);
  const { totalCount: jobMatchCount } = useSelector(selectJobMatchCount);
  const { totalCount: jobInterestCount } = useSelector(selectJobInterestsCount);
  const jobMatchServiceFailed = useSelector(selectMatchServiceFailure);
  const jobInterestServiceFailed = useSelector(selectInterestServiceFailure);
  const placementSkillsetExperience = useSelector(placementSkillsetExperienceSelector);
  const { promiseInProgress: matchPromise } = usePromiseTracker({ area: 'job-match-call', delay: 0 });
  const { promiseInProgress: interestPromise } = usePromiseTracker({ area: 'job-interest-call', delay: 0 });
  const [enableJobInterests] = useDecision(ff_candidate_ui_job_interests);
  const [enableJobMatch] = useDecision(ff_candidate_ui_job_match);
  const [enableSupervisorKPITile] = useDecision(ff_candidate_ui_view_SupervisorCaseLoad);
  const theme = useTheme();

  const { promiseInProgress } = usePromiseTracker({ area: 'get-candidate-details', delay: 0 });

  const [modalProps, setModalProps] = useState<{
    onChange: () => void;
    title: string;
    subTitle?: any | undefined;
    columns: any[];
    rows: any[];
  } | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (params.travelerId) {
      const candidateId = parseInt(params.travelerId);
      if (enableJobMatch?.enabled) {
        dispatch(jobMatchActions.getJobMatchCountAction(candidateId));
      }

      if (enableJobInterests?.enabled) {
        dispatch(jobInterestActions.getJobInterestCountAction(candidateId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      candidateDetails.placement?.placementInfo?.length > 0 &&
      candidateDetails.placement?.placementInfo[0]?.placementId &&
      !placementSkillsetExperience
    ) {
      const placementId = candidateDetails.placement?.placementInfo[0]?.placementId;
      dispatch(
        candidateDetailActions.getPlacementSkillsetExperience({
          placementId,
          placementOrderType: PlacementOrderType.Default,
        }),
      );
    }
  }, [placementSkillsetExperience]);

  const totalExperienceData = getExperienceData(
    workExperienceData?.candidateWorkExperience?.totalYearsOfExp,
    workExperienceData?.candidateWorkExperience?.totalMonthsOfExp,
  );
  const totalEngagementTime = useMemo(() => {
    return formatEngagementDate(candidateDetails?.engagementDate);
  }, [candidateDetails?.engagementDate]);
  const flattenPlacementData = (placementsInfo: PlacementInfo[]) =>
    placementsInfo.reduce((resultArray, info) => {
      const startedMoment = GetMomentOfDate(info.startDate);
      const endedMoment = GetMomentOfDate(info.endDate);
      return startedMoment.isValid() || endedMoment.isValid()
        ? [
            ...resultArray,
            {
              placementId: info.placementId,
              facilityName: info.facilityName ? info.facilityName : '',
              startDate: startedMoment.isValid() ? startedMoment.format('MM/DD/YYYY') : '',
              endDate: endedMoment.isValid() ? endedMoment.format('MM/DD/YYYY') : '',
              placementStatus: info.placementStatus ? info.placementStatus : '',
            },
          ]
        : resultArray;
    }, [] as any[]);

  const flattenFilesData = (placementsInfo: PlacementInfo[]) =>
    placementsInfo.reduce((resultArray, info) => {
      const filesSentMoment = GetMomentOfDate(info.fileSentDate);
      return info.placementStatus === PlacementStatus.FilesSent ||
        info.placementStatus === PlacementStatus.RequestFileOut
        ? [
            ...resultArray,
            {
              placementId: info.placementId,
              facilityName: info.facilityName ? info.facilityName : '',
              dateSent: filesSentMoment.isValid() ? filesSentMoment.format('MM/DD/YYYY') : '',
              placementStatus: info.placementStatus ? info.placementStatus : '',
            },
          ]
        : resultArray;
    }, [] as any[]);

  function getExperienceDataWithFormating(years: number | undefined, months: number | undefined) {
    if ((years == null && months == null) || (years === 0 && months === 0)) return missingField;
    else if (years == null || years === 0) return `${months} mo`;
    else if (months == null || months === 0) return `${years} yr`;
    else return `${years} yr, ${months} mo`;
  }

  const flattenSkillsetDataV2 = () =>
    workExperienceData?.candidateWorkExperience?.skillsets
      ?.filter(e => e?.totalYearsOfExperience !== 0 || e?.totalMonthsOfExperience !== 0)
      .reduce(
        (resultArray, info) => [
          ...resultArray,
          {
            skillset: Concatenate([info.disciplineAbbr, info.specialtyAbbr], '-'),
            lastThreeYearsYearsOfExperience: (
              <>
                {getExperienceDataWithFormating(
                  info.lastThreeYearsYearsOfExperience,
                  info.lastThreeYearsMonthsOfExperience,
                )}
              </>
            ),
            totalYearsOfExperience: (
              <>{getExperienceDataWithFormating(info.totalYearsOfExperience, info.totalMonthsOfExperience)}</>
            ),
          },
        ],
        [] as any[],
      );

  const displayFiles = () => {
    setModalProps({
      onChange: () => setModalProps(null),
      title: t('candidate.glanceBarModals.files'),
      columns: FilesHeader,
      rows: candidateDetails?.placement?.placementInfo
        ? flattenFilesData(candidateDetails.placement.placementInfo)
        : [],
    });
  };
  const displayPlacements = () => {
    setModalProps({
      onChange: () => setModalProps(null),
      title: t('candidate.glanceBarModals.placements'),
      columns: PlacementsHeader,
      rows: candidateDetails?.placement?.placementInfo
        ? flattenPlacementData(candidateDetails.placement.placementInfo)
        : [],
    });
  };
  const displaySkills = () => {
    if (workExperienceData?.skillsets?.length === 0) return;
    setModalProps?.({
      onChange: () => setModalProps(null),
      title: `${t('placement.profile.summary.submission.amn.experince.title')}`,
      subTitle: (
        <span style={{ fontSize: '14px' }}>
          {`${t('placement.profile.summary.submission.amn.experince.yearsOfExperience')}: `}{' '}
          <b>
            {getExperienceData(
              workExperienceData?.candidateWorkExperience?.totalYearsOfExp,
              workExperienceData?.candidateWorkExperience?.totalMonthsOfExp,
            )}
          </b>
        </span>
      ),
      columns: SkillsHeader,
      rows: flattenSkillsetDataV2(),
    });
  };

  const showJobInterestGrid = () => {
    if (enableJobInterests?.enabled) {
      dispatch(panelActions.setPanelOptions({ panelOption: PanelChoice.JobInterestGrid }));
      window.history.pushState(
        null,
        '',
        `${window.location.pathname}?panel=${PanelChoice.JobInterestGrid.toLowerCase()}`,
      );
    }
  };

  const showJobMatchGrid = () => {
    if (enableJobMatch?.enabled) {
      dispatch(panelActions.setPanelOptions({ panelOption: PanelChoice.JobMatchGrid }));
      window.history.pushState(null, '', `${window.location.pathname}?panel=${PanelChoice.JobMatchGrid.toLowerCase()}`);
    }
  };

  const lastContactedOn = candidateDetails?.lastContactedOn
    ? convertToPST(candidateDetails?.lastContactedOn, 'MM/DD/YYYY')
    : null;

  const renderTitle = (title1?: string, title2?: string) => {
    return (
      <Box>
        {title1 && <Typography sx={{ fontSize: '12px !important' }}>{title1}</Typography>}
        {title2 && <Typography sx={{ fontSize: '12px !important' }}>{title2}</Typography>}
      </Box>
    );
  };

  const renderTooltip = (first: string, last: string) => {
    return (
      <>
        {first}
        <br /> {last}
      </>
    );
  };

  const glanceTileArray = [
    {
      tileIcon: <img src={VerifiedIcon} alt="Verified" />,
      title: renderTitle(t('candidate.glanceBarTitles.experience')),
      data: workExperienceData?.skillsets?.length > 0 ? totalExperienceData : '--',
      clickAble: workExperienceData?.skillsets?.length > 0,
      handleClick: displaySkills,
      showTooltip: workExperienceData?.skillsets?.length === 0,
      tooltipText: t('candidate.glanceBarToolTips.experience'),
    },
    ...(enableSupervisorKPITile?.enabled
      ? [
          {
            tileIcon: <img src={EventIcon} alt="Event" />,
            title: renderTitle(t('candidate.glanceBarTitles.supervisor'), t('candidate.glanceBarTitles.caseLoad')),
            data:
              candidateDetails?.supervisor && candidateDetails?.supervisor?.maxNumber > 0
                ? `${candidateDetails?.supervisor?.caseLoad} / ${candidateDetails?.supervisor?.maxNumber} `
                : '0',
            showTooltip: candidateDetails?.supervisor && candidateDetails?.supervisor?.maxNumber > 0 ? true : false,
            background: theme.palette.system.fadedBlue,
            fontColor: theme.palette.framework.system.skyBlue,
            tooltipText: renderTooltip(
              `${t('candidate.glanceBarToolTips.candidateAssociated')} (${candidateDetails?.supervisor?.caseLoad})`,
              `/ ${t('candidate.glanceBarToolTips.desiredPlacement')} (${candidateDetails?.supervisor?.maxNumber})`,
            ),
          },
        ]
      : []),
    {
      tileIcon: <img src={EventIcon} alt="Event" />,
      title: renderTitle(t('candidate.glanceBarTitles.nextPlacement'), t('candidate.glanceBarTitles.startsIn')),
      data:
        candidateDetails?.placement?.placementDays !== null && candidateDetails?.placement?.placementDays >= 0
          ? `${candidateDetails?.placement?.placementDays} ${t('candidate.glanceBarTitles.days')}`
          : '--',
      showTooltip:
        candidateDetails?.placement?.placementDays !== null && candidateDetails?.placement?.placementDays >= 0
          ? false
          : true,
      tooltipText: t('candidate.glanceBarToolTips.placement'),
    },
    {
      tileIcon: <img src={FileSent} alt="File Sent" />,
      title: renderTitle(t('candidate.glanceBarTitles.files'), t('candidate.glanceBarTitles.sent')),
      data: candidateDetails?.placement?.fileSent ? `${candidateDetails?.placement?.fileSent}` : '0',
      clickAble: true,
      handleClick: displayFiles,
      background: theme.palette.system.fadedBlue,
      fontColor: theme.palette.framework.system.skyBlue,
    },
    {
      tileIcon: <img src={EventNoteIcon} alt="Event Note" />,
      title: renderTitle(t('candidate.glanceBarTitles.netWeeks'), t('candidate.glanceBarTitles.booked')),
      data: candidateDetails?.placement?.weeksBooked ? `${candidateDetails?.placement?.weeksBooked}` : '0',
      dataUnit: candidateDetails?.placement?.weeksBooked ? t('candidate.glanceBarTitles.weeks') : '',
      clickAble: true,
      handleClick: displayPlacements,
      background: theme.palette.system.fadedBlue,
      fontColor: theme.palette.framework.system.skyBlue,
    },
    ...(enableJobMatch?.enabled
      ? [
          {
            tileIcon: <img src={PersonSearch} alt="Person Search" />,
            title: renderTitle(t('candidate.glanceBarTitles.job'), t('candidate.glanceBarTitles.matches')),
            handleClick: !enableJobMatch?.enabled || jobMatchServiceFailed ? null : showJobMatchGrid,
            clickAble: enableJobMatch?.enabled && !jobMatchServiceFailed && jobMatchCount !== null && jobMatchCount > 0,
            showTooltip: !jobMatchServiceFailed && !matchPromise && (jobMatchCount || 0) === 0 ? true : false,
            data: jobMatchCount?.toString() || '0',
            tooltipText: t('candidate.jobMatch.unavailableTooltip'),
            showLoader: matchPromise,
            showError: jobMatchServiceFailed,
            background: theme.palette.system.fadedBlue,
            fontColor: theme.palette.framework.system.skyBlue,
          },
        ]
      : []),
    ...(enableJobInterests?.enabled
      ? [
          {
            tileIcon: <img src={JobInterested} alt="Job interested" />,
            title: renderTitle(t('candidate.glanceBarTitles.job'), t('candidate.glanceBarTitles.interests')),
            handleClick: !enableJobInterests?.enabled || jobInterestServiceFailed ? null : showJobInterestGrid,
            clickAble:
              enableJobInterests?.enabled &&
              !jobInterestServiceFailed &&
              jobInterestCount !== null &&
              jobInterestCount > 0,
            showTooltip: !jobInterestServiceFailed && !interestPromise && (jobInterestCount || 0) === 0 ? true : false,
            data: jobInterestCount?.toString() || '0',
            tooltipText: t('candidate.jobInterest.unavailableTooltip'),
            showLoader: interestPromise,
            showError: jobInterestServiceFailed,
          },
        ]
      : []),
    {
      tileIcon: <img src={LastContacted} alt="Last contacted" />,
      title: renderTitle(t('candidate.glanceBarTitles.last'), t('candidate.glanceBarTitles.contacted')),
      data: lastContactedOn ?? '--',
    },
    {
      tileIcon: <img src={VerifiedIcon} alt="Verified" />,
      title: renderTitle(t('candidate.glanceBarTitles.engagementDate')),
      data: totalEngagementTime ?? '--',
    },
  ];

  return (
    <>
      {promiseInProgress ? (
        <Skeleton variant="rectangular" height={65} />
      ) : (
        <>
          <Box sx={{ width: '100%' }}>
            <LayoutGrid container wrap="nowrap" spacing={2} sx={{ overflow: 'auto' }}>
              {glanceTileArray.map((tile, key) => (
                <LayoutGridItem item xs={4}>
                  <GlanceTile
                    key={key}
                    tileIcon={tile.tileIcon}
                    title={tile.title}
                    data={tile.data}
                    dataUnit={tile.dataUnit}
                    clickAble={tile.clickAble}
                    OnClick={tile.handleClick}
                    showTooltip={tile.showTooltip}
                    tooltipText={tile.tooltipText}
                    showLoader={tile.showLoader}
                    showError={tile.showError}
                    analytics={{ groupTitle: 'Candidate Profile' }}
                    background={tile?.background}
                    fontColor={tile?.fontColor}
                  />
                </LayoutGridItem>
              ))}
            </LayoutGrid>
          </Box>
          {modalProps && <CustomModal {...modalProps} />}
        </>
      )}
    </>
  );
};
