import { Typography } from 'amn-ui-core';
import { convertToFormat } from 'app/helpers/dateHelper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Concatenate } from 'utils/string/string';
import { KeyValueRow } from '../TableComponents/KeyValueTable/KeyValueRow';
import { ExtensionEditWrapper, ExtEditTarget } from './ExtensionEditIcon';

export const ExtDateRow = ({ 
  extContainerIndex,
  duration,
  startDate,
  endDate,
  extensionStatusId
}) => {
  const { t } = useTranslation();

  const content = React.useMemo(() => {
    const dateString = Concatenate([
      convertToFormat(startDate, 'MM/DD/yyyy'), 
      convertToFormat(endDate, 'MM/DD/yyyy')
    ], ' - ');
    return (
      <Typography variant="body1">{Concatenate([duration.toString(), 'Weeks:', dateString], ' ')}</Typography>
    );
  }, [duration, endDate, startDate]);

  return (
    <KeyValueRow
      id="placement-ext-status-row"
      rowTitle={t('placement.profile.summary.extension.date')}
      loading={false}
    >
      <ExtensionEditWrapper 
        target={ExtEditTarget.date}
        extContainerIndex={extContainerIndex}
        extensionStatusId={extensionStatusId}
      >
        {content}
      </ExtensionEditWrapper>
    </KeyValueRow>
  );
}