import { useDecision } from '@optimizely/react-sdk';
import {
  ff_Notifications_Email,
  ff_Notifications_Sms,
  ff_Notifications_Voice,
  ff_notifications_onetrust_consents,
} from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';
import { SearchGrid, searchGridBulkEmailFFKey, searchGridBulkSmsFFKey } from 'app/constants/FeatureFlags/smsKeys';

export const useEnableVoiceCommunication = () => {
  const [notificationVoice] = useDecision(ff_Notifications_Voice);
  if (notificationVoice) return notificationVoice.enabled;
  return true;
};

export function useEnableEmailCommunication() {
  const [notificationEmail] = useDecision(ff_Notifications_Email);
  if (notificationEmail) return notificationEmail.enabled;
  else return true;
}

export function useEnableSmsCommunication() {
  const [notificationSms] = useDecision(ff_Notifications_Sms);
  if (notificationSms) return notificationSms.enabled;
  else return true;
}

export function useEnableBulkSms(workDesk: SearchGrid) {
  const [bulkSms] = useDecision(searchGridBulkSmsFFKey[workDesk]);
  if (bulkSms) return bulkSms.enabled;
  else return true;
}

export function useEnableBulkEmail(workDesk: SearchGrid) {
  const [bulkEmail] = useDecision(searchGridBulkEmailFFKey[workDesk]);
  if (bulkEmail) return bulkEmail.enabled;
  else return true;
}

export function useEnableOneTrustConsents() {
  const [oneTrustConsents] = useDecision(ff_notifications_onetrust_consents);
  if (oneTrustConsents) return oneTrustConsents.enabled;
  else return true;
}