/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Typography, FormControlLabel, Checkbox, RadioGroup, FormControl, Radio } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import lightbulb from 'app/assets/images/light-bulb.png';
import orderReason from 'app/assets/jsons/OrderCreation/OrderReason.json';
import reasonForMaxFilesSent from 'app/assets/jsons/OrderCreation/ReasonForMaxFilesSent.json';
import { colors } from 'styles/styleVariables';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { NumberEntryElement } from './Controls/NumberEntryElement';
import { DropDownElement } from './Controls/DropDownElement';
import { SwitchFormElement } from './Controls/SwitchFormElement';
import { TextEntryElement } from './Controls/TextEntryElement';
import { NewOrder } from 'app/models/Orders/OrderDetails';
import { formatShiftInputData } from '../helper';
import { ShiftsAvailableContainer } from '../Dates/ShiftsAvailableContainer';
import { OrderDateRangePicker } from './Controls/OrderDateRangePicker';
import { NonContractedBillRates } from './Controls/NonContractedBillRates';
import { AMNDivision, AMNDivisionType } from 'app/models/enums/AMNDivisionType';
import { useSelector } from 'react-redux';
import { selectOrderDetailsOrderType, selectOrderRegions } from '@AMIEWEB/Order/Store/Order.selectors';

const useStyles = makeStyles()(theme => ({
  gridContainer: {
    width: '100%',
    margin: 2.5,
    border: '1px solid #eee',
    borderRadius: 4,
    padding: 10,
  },
  lightbulb: {
    height: '19px',
    width: '20px',
    marginRight: '10px',
  },
  stepperHelper: {
    textAlign: 'center',
    color: colors.text,
  },
  stepperWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: 15,
  },
}));
interface Props {
  facilityDivision: number;
  order: NewOrder;
  onOrderPropertyChange: (property: string, value: any) => void;
  showMandetryStyle?: boolean;
}

export const NewOrderDetails = (props: Props) => {
  const orderTypes = useSelector(selectOrderDetailsOrderType);
  const regions = useSelector(selectOrderRegions);

  const { facilityDivision, order, onOrderPropertyChange, showMandetryStyle } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const STRIKE = 'STRIKE';
  const defaultTypeValue = orderTypes?.find(order =>
    facilityDivision === AMNDivisionType.hsg ? order.Description.toUpperCase() === STRIKE : order[0],
  );
  const defaultRegionValue = regions.find(region =>
    facilityDivision === AMNDivisionType.hsg ? region.Description.toUpperCase() === 'UNASSIGNED' : null,
  );

  const dissabledOrderTypes: number[] = [];
  if (facilityDivision === AMNDivisionType.localStaffing) {
    orderTypes.map(x => {
      if (x.Description !== AMNDivision.Local) {
        dissabledOrderTypes.push(x.ID);
      }
    });
  } else if (facilityDivision === AMNDivisionType.amnNurse || facilityDivision === AMNDivisionType.inDemand) {
    dissabledOrderTypes.push(2);
  }

  return (
    <LayoutGrid>
      <LayoutGridItem item xs={12} justifyContent="center" className={classes.stepperHelper}>
        <div className={classes.stepperWrapper}>
          <img src={lightbulb} alt={`info`} className={classes.lightbulb} />
          <Typography>{`Fill out the Order Details in below form and click on next`}</Typography>
        </div>
      </LayoutGridItem>
      <LayoutGridItem xs={12}>
        <LayoutGrid direction="column" className={classes.gridContainer}>
          <LayoutGridItem>
            <Typography variant="h6">{t('order.newOrder.generalDetailsHeader')}</Typography>
          </LayoutGridItem>
          <LayoutGridItem>
            <LayoutGrid direction="row">
              <LayoutGridItem xs={12}>
                <LayoutGrid>
                  <LayoutGridItem xs={4}>
                    <NumberEntryElement
                      id={'newOrderpositionsAvailabletxt'}
                      order={order}
                      req={showMandetryStyle ? !order.positionsAvailable : false}
                      onOrderPropertyChange={onOrderPropertyChange}
                      label={t('order.newOrder.positionsAvailable')}
                      name={`positionsAvailable`}
                      placeholder={`1-9999`}
                      limitThousand
                      required
                    />
                  </LayoutGridItem>
                  <LayoutGridItem xs={4}>
                    <DropDownElement
                      id={'newOrderTypeDropDown'}
                      order={order}
                      req={showMandetryStyle ? !order.type : false}
                      onOrderPropertyChange={onOrderPropertyChange}
                      options={orderTypes}
                      defaultOption={defaultTypeValue}
                      disabled={facilityDivision === AMNDivisionType.hsg}
                      dissabledOption={dissabledOrderTypes}
                      label={t('order.newOrder.orderType')}
                      name={`type`}
                    />
                  </LayoutGridItem>
                  <LayoutGridItem xs={4}>
                    <DropDownElement
                      id={'newOrderRegionDropDown'}
                      order={order}
                      req={showMandetryStyle ? !order.region : false}
                      onOrderPropertyChange={onOrderPropertyChange}
                      options={regions}
                      defaultOption={defaultRegionValue}
                      label={t('order.newOrder.region')}
                      name={`region`}
                    />
                  </LayoutGridItem>
                </LayoutGrid>
              </LayoutGridItem>
            </LayoutGrid>
          </LayoutGridItem>
        </LayoutGrid>
      </LayoutGridItem>
      <LayoutGridItem xs={12}>
        <LayoutGrid direction="row" spacing={2}>
          <LayoutGridItem xs={6}>
            <LayoutGrid direction="column">
              <LayoutGridItem>
                <LayoutGrid direction="column" className={classes.gridContainer}>
                  <LayoutGridItem>
                    <Typography variant="h6">{t('order.newOrder.shiftsAvailable')}</Typography>
                  </LayoutGridItem>
                  <LayoutGridItem xs={12}>
                    <ShiftsAvailableContainer
                      listWidth={12}
                      checkCreateOrder
                      chipLocation="bottom"
                      defaultInputShiftList={(order && order.shifts.map(item => formatShiftInputData(item))) || []}
                      onOrderPropertyChange={onOrderPropertyChange}
                      disableEdit={false}
                    />
                  </LayoutGridItem>
                </LayoutGrid>
              </LayoutGridItem>
              <LayoutGridItem>
                <LayoutGrid direction="column" className={classes.gridContainer}>
                  <LayoutGridItem>
                    <Typography variant="h6">{t('order.newOrder.placementLengthDateHoursHeader')}</Typography>
                  </LayoutGridItem>
                  <LayoutGridItem>
                    <LayoutGridItem>
                      <OrderDateRangePicker order={order} handlePropertyChange={onOrderPropertyChange} />
                    </LayoutGridItem>
                  </LayoutGridItem>
                  <LayoutGridItem>
                    <LayoutGrid direction="row">
                      <LayoutGridItem xs={6}>
                        <NumberEntryElement
                          id={'newOrderexpectedHourstxt'}
                          order={order}
                          onOrderPropertyChange={onOrderPropertyChange}
                          label={t('order.newOrder.expectedHours')}
                          name={`expectedHours`}
                          placeholder={`0-99`}
                          limitHundred
                        ></NumberEntryElement>
                      </LayoutGridItem>
                      <LayoutGridItem xs={6}>
                        <NumberEntryElement
                          id={'newOrderguaranteedHourstxt'}
                          order={order}
                          onOrderPropertyChange={onOrderPropertyChange}
                          label={t('order.newOrder.guaranteedHours')}
                          name={`guaranteedHours`}
                          placeholder={`0-99`}
                          limitHundred
                        ></NumberEntryElement>
                      </LayoutGridItem>
                    </LayoutGrid>
                  </LayoutGridItem>
                </LayoutGrid>
              </LayoutGridItem>
            </LayoutGrid>
          </LayoutGridItem>
          <LayoutGridItem xs={6}>
            <LayoutGrid direction="column">
              <LayoutGridItem>
                <LayoutGrid direction="column" className={classes.gridContainer}>
                  <LayoutGridItem>
                    <LayoutGrid direction="row" alignItems="center">
                      <LayoutGridItem>
                        <Typography variant="h6">{t('order.newOrder.maxFilesSentHeader')}</Typography>
                      </LayoutGridItem>
                      <LayoutGridItem>
                        <SwitchFormElement
                          id={'newOrdermaxFilesSentCheck'}
                          order={order}
                          onOrderPropertyChange={onOrderPropertyChange}
                          name={`maxFilesSent`}
                        ></SwitchFormElement>
                      </LayoutGridItem>
                    </LayoutGrid>
                  </LayoutGridItem>
                  {order.maxFilesSent && (
                    <LayoutGridItem>
                      <DropDownElement
                        id={'newOrderreasonForMaxFilesSentDropDown'}
                        order={order}
                        onOrderPropertyChange={onOrderPropertyChange}
                        options={reasonForMaxFilesSent}
                        label={t('order.newOrder.reasonForMaxFilesSent')}
                        name={`reasonForMaxFilesSent`}
                      />
                    </LayoutGridItem>
                  )}
                </LayoutGrid>
              </LayoutGridItem>
              <LayoutGridItem>
                <LayoutGrid direction="column" className={classes.gridContainer}>
                  <LayoutGridItem>
                    <Typography variant="h6">{t('order.newOrder.technologyInformationHeader')}</Typography>
                  </LayoutGridItem>
                  <LayoutGridItem>
                    <TextEntryElement
                      id={'newOrdervmsReqNumberTxt'}
                      order={order}
                      onOrderPropertyChange={onOrderPropertyChange}
                      label={t('order.newOrder.vmsReqNumber')}
                      name={`VMSOrderNumber`}
                    />
                  </LayoutGridItem>
                  <LayoutGridItem>
                    <TextEntryElement
                      id={'newOrdervmsWebLinkTxt'}
                      order={order}
                      onOrderPropertyChange={onOrderPropertyChange}
                      label={t('order.newOrder.vmsWebLink')}
                      name={`weblink`}
                    />
                  </LayoutGridItem>
                </LayoutGrid>
              </LayoutGridItem>
              <LayoutGridItem>
                <LayoutGrid direction="column" className={classes.gridContainer}>
                  <LayoutGridItem>
                    <Typography variant="h6">{t('order.newOrder.orderReasonHeader')}</Typography>
                  </LayoutGridItem>
                  <LayoutGridItem>
                    <DropDownElement
                      id={'newOrderorderReasonDropDown'}
                      order={order}
                      onOrderPropertyChange={onOrderPropertyChange}
                      options={orderReason}
                      label={t('order.newOrder.orderReason')}
                      name={`orderReason`}
                    />
                  </LayoutGridItem>
                </LayoutGrid>
              </LayoutGridItem>
              <LayoutGridItem>
                <LayoutGrid direction="column" className={classes.gridContainer}>
                  <LayoutGridItem>
                    <Typography variant="h6">{t('order.newOrder.additionalInfoHeader')}</Typography>
                  </LayoutGridItem>
                  <LayoutGridItem>
                    <SwitchFormElement
                      id={'newOrderhideFacilityDescriptionCheck'}
                      order={order}
                      onOrderPropertyChange={onOrderPropertyChange}
                      name={`hideFacilityDescription`}
                      label={t('order.newOrder.hideFacilityDescription')}
                      tooltip={t('order.newOrder.hideFacilityDescriptionTooltip')}
                    ></SwitchFormElement>
                  </LayoutGridItem>
                  <LayoutGridItem>
                    <SwitchFormElement
                      id={'newOrdersuppressExternalPostingCheck'}
                      order={order}
                      onOrderPropertyChange={onOrderPropertyChange}
                      name={`suppressExternalPosting`}
                      label={t('order.newOrder.suppressExternalPosting')}
                      tooltip={t('order.newOrder.suppressExternalPostingTooltip')}
                    ></SwitchFormElement>
                  </LayoutGridItem>
                  <LayoutGridItem>
                    <SwitchFormElement
                      id={'newOrderclinicalFellowshipCheck'}
                      order={order}
                      onOrderPropertyChange={onOrderPropertyChange}
                      name={`CF`}
                      label={t('order.newOrder.clinicalFellowship')}
                      tooltip={t('order.newOrder.clinicalFellowshipTooltip')}
                    ></SwitchFormElement>
                  </LayoutGridItem>
                </LayoutGrid>
              </LayoutGridItem>
              {facilityDivision === AMNDivisionType.allied && (
                <LayoutGridItem>
                  <LayoutGrid direction="column" className={classes.gridContainer}>
                    <LayoutGridItem>
                      <Typography variant="h6">{t('order.newOrder.nonContractedBillRatesHeader')}</Typography>
                    </LayoutGridItem>
                    <LayoutGridItem>
                      <LayoutGrid direction="row" alignItems="center">
                        <LayoutGridItem>
                          <Typography variant="h6">{t('order.newOrder.negotiableRate')}</Typography>
                        </LayoutGridItem>
                        <LayoutGridItem>
                          <FormControl>
                            <RadioGroup
                              id={'newOrdernegotiableRateRadio'}
                              row
                              value={order.negotiableRate}
                              onChange={event => onOrderPropertyChange(`negotiableRate`, event.target.value)}
                            >
                              <FormControlLabel
                                id={'newOrdernegotiableRateRadioNo'}
                                value={`No`}
                                control={<Radio disableFocusRipple disableRipple disableTouchRipple />}
                                label={'No'}
                              />
                              <FormControlLabel
                                id={'newOrdernegotiableRateRadioYes'}
                                value={`Yes`}
                                control={<Radio disableFocusRipple disableRipple disableTouchRipple />}
                                label={'Yes'}
                              />
                            </RadioGroup>
                          </FormControl>
                        </LayoutGridItem>
                      </LayoutGrid>
                    </LayoutGridItem>
                    <LayoutGridItem>
                      <NumberEntryElement
                        id={'newOrderorderBillRateTxt'}
                        order={order}
                        onOrderPropertyChange={onOrderPropertyChange}
                        label={t('order.newOrder.orderBillRate')}
                        name={`orderBillRate`}
                        monetary
                      />
                    </LayoutGridItem>
                    <LayoutGridItem>
                      <TextEntryElement
                        id={'newOrdernotesTxt'}
                        order={order}
                        onOrderPropertyChange={onOrderPropertyChange}
                        label={t('order.newOrder.notes')}
                        name={`notes`}
                      />
                    </LayoutGridItem>
                    <LayoutGridItem>
                      <LayoutGrid direction="row" alignItems="center">
                        <LayoutGridItem>
                          <Typography variant="h6">{t('order.newOrder.addBillRateRange')}</Typography>
                        </LayoutGridItem>
                        <LayoutGridItem>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id={'newOrderaddBillRateRangeCheck'}
                                data-testid="test add bill rate range"
                                disableFocusRipple
                                disableRipple
                                disableTouchRipple
                                checked={order.addBillRateRange}
                                onChange={() => onOrderPropertyChange('addBillRateRange', !order.addBillRateRange)}
                                color="secondary"
                              />
                            }
                            label="Yes"
                          />
                        </LayoutGridItem>
                      </LayoutGrid>
                    </LayoutGridItem>
                    {order.addBillRateRange && (
                      <LayoutGridItem xs={12}>
                        <NonContractedBillRates
                          order={order}
                          onOrderPropertyChange={onOrderPropertyChange}
                        ></NonContractedBillRates>
                      </LayoutGridItem>
                    )}
                  </LayoutGrid>
                </LayoutGridItem>
              )}
            </LayoutGrid>
          </LayoutGridItem>
        </LayoutGrid>
      </LayoutGridItem>
    </LayoutGrid>
  );
};
