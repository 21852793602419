import React from 'react';
import { useSelector } from 'react-redux';
import { selectNewPlacementChoice } from '../../../../../store/redux-store/new-placement/selectors';
import {
  Paper,
  Box,
  Divider,
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  Table,
  TableBody,
  TableRow,
  TableCell,
  useTheme
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import EMailBoxIcon from 'app/assets/images/Placement/email.svg';
import CallIcon from 'app/assets/images/Placement/call.svg';
import styledComponent from 'styled-components';
import { colors } from 'styles/styleVariables';
import { useTranslation } from 'react-i18next';
import { missingField } from 'app/constants';
import { SkillsetTree } from '../../../Common/SkillsetTree/SkillsetTree';
import {
  AvailableStartDate,
  Recruiter,
  RequestedShifts,
  PlacementSellingPoints,
  PlacementTimeOffRequest,
} from './Edit';

const useStyles = makeStyles()(theme=>({
  root: {
    padding: 12,
    border: '1px solid #E3DFDF',
    boxShadow: '0px 1px 5px #0000001F',
    maxHeight: 380,
    minHeight: 380,
    overflowX: 'hidden',
  },
  contactSection: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const SelectedCandidate = () => {
  const { t } = useTranslation();
  const { candidate } = useSelector(selectNewPlacementChoice);
  const { classes } = useStyles();
  const theme= useTheme();  
  
const overriddenTheme = createTheme(
  theme,
  {
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: '#FCFCFD',
            color: '#333333',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '6px 0px',
            verticalAlign: 'baseline',
            border: 'transparent !important',
            width: '50%',
          },
        },
      },
    },
  },
);


  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={overriddenTheme}>
        <Paper classes={{ root: classes.root }}>
          <Box className={classes.contactSection}>
            <Box>
              <img src={EMailBoxIcon} />
              <Contact>{candidate?.email || missingField}</Contact>
            </Box>
            <Box>
              <img src={CallIcon} />
              <Contact>{candidate?.phone || missingField}</Contact>
            </Box>
          </Box>
          <Divider style={{ margin: '8px 0px' }} />
          <Table>
            <TableBody>
              <TableRow>
                <KeyCell>{t('placement.create.popup.brand')}</KeyCell>
                <ValueCell>{candidate?.brand || missingField}</ValueCell>
              </TableRow>
              <TableRow>
                <KeyCell>{t('placement.create.popup.recruiter')}</KeyCell>
                <ValueCell>
                  <Recruiter />
                </ValueCell>
              </TableRow>
              <TableRow>
                <KeyCell>{t('placement.create.popup.availableStartDate')}</KeyCell>
                <ValueCell>
                  <AvailableStartDate />
                </ValueCell>
              </TableRow>
              <TableRow>
                <KeyCell>{t('placement.create.popup.requestedShifts')}</KeyCell>
                <ValueCell>
                  <RequestedShifts />
                </ValueCell>
              </TableRow>
              <ConditionalRow
                header={t('placement.create.popup.verifiedSkill')}
                hasMissingField={!candidate?.groupedSkillsets.length}
              >
                <SkillsetTree skillset={candidate?.groupedSkillsets || []} />
              </ConditionalRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <PlacementTimeOffRequest />
                </TableCell>
              </TableRow>
              <ConditionalRow header={t('placement.create.popup.sellingpoint')}>
                <PlacementSellingPoints />
              </ConditionalRow>
            </TableBody>
          </Table>
        </Paper>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const ConditionalRow = props =>
  !props.hasMissingField ? (
    <>
      <TableRow>
        <KeyCell colSpan={2}>{props.header}</KeyCell>
      </TableRow>
      <TableRow>
        <ValueCell colSpan={2}>{props.children}</ValueCell>
      </TableRow>
    </>
  ) : (
    <TableRow>
      <KeyCell>{props.header}</KeyCell>
      <ValueCell>{missingField}</ValueCell>
    </TableRow>
  );

const Contact = styledComponent.span`
  color: ${colors.amnBlue};
  font-size: 14px;
  font-weight: 450;
  margin-left: 8px;
  line-height: 28px;
  vertical-align: middle;
`;

const KeyCell = styledComponent(TableCell)`
  color: #333333;
  font-size: 13px;
  font-weight: 400;
`;

const ValueCell = styledComponent(TableCell)`
  color: #555555;
  font-size: 14px;
  font-weight: 450;
`;
