import React from 'react';
import { useTranslation } from 'react-i18next';
import { MileageReimbursement } from './MileageReimbursement';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  container: {
    overflowY: 'hidden',
    overflowX: 'auto',
    display: 'block',
    marginLeft: '14em',
  },
  title: {
    width: '15%',
    fontSize: '14px',
    textAlign: 'start',
    minWidth: 150,
    wordBreak: 'keep-all',
    position: 'absolute',
    left: 0,
    paddingTop: '21px',
    fontWeight: 500,
  },
  title2: {
    width: '15%',
    fontSize: '14px',
    textAlign: 'start',
    minWidth: 150,
    wordBreak: 'keep-all',
    position: 'absolute',
    left: 0,
    paddingTop: '56px',
    fontWeight: 500,
  },
  divider: {
    borderRight: '1px solid #e4d9d9',
    height: '110%',
  },
  itemStyle: {
    padding: '6px',
  },
}));

export const MileageTable = ({ label, name, shiftRates = [] }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <table className={classes.container}>
      <tr>
        <th className={classes.title}>{t('order.newOrder.rateCard.mileageReimbursement')}</th>
        <td className={classes.itemStyle}>
          {' '}
          <MileageReimbursement label={label} name={name} shiftRates={shiftRates} />
        </td>
      </tr>
    </table>
  );
};
