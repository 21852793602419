import { takeLatest, put, call, select } from 'redux-saga/effects';
import { FacilityContactsActions } from './FacilityContacts.redux';
import {
  getFacilityClientContacts,
  updateNotesStatus,
  updateStarFacilityClientContacts,
} from 'app/services/FacilityServices/FacilityServices';
import { formatFacilityContacts } from '../Grid/FacilityContactsHeaders';
import { PayloadAction } from '@reduxjs/toolkit';
import { getMultiSelectOptions, getSigleSelectOptions } from 'app/ComponentLibrary/Filter/helper';
import { trackEvent, trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import {
  selectClientContractDrawer,
  selectContactsGridData,
  selectContactsSourceData,
} from './FacilityContacts.selector';
import { selectFacility } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { formatClientContacts } from '@AMIEWEB/Unit/ClientContact/UnitClientContactHeader';
import { UnitContact } from 'app/models/Unit/CreateUnit';
import { httpSuccess } from 'app/services/serviceHelpers';
import { ContactStatus } from 'app/enums/Unit';
import { trackPromise } from 'react-promise-tracker';
import { gridSelectionActions } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.redux';
import { selectUser } from 'oidc/user.selectors';
import { getStatusIdForContacts, setUpdatedByClientContract } from '@AMIEWEB/Unit/ClientContact/helper';
import { StatusDropDownValues } from '@AMIEWEB/Common/StatusDropDown/StatusDropDown';
import { selectGridSelection } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.selector';

const TrackPostNotesStatusUnits = (fn, ...args) => trackPromise(fn(...args), 'post-notes-status-units');

/**
 * Placement Reminders.
 * @param action
 */
export function* requestFacilityClientContactsList(action: PayloadAction<{ id: number }>) {
  try {
    const user = yield select(selectUser);
    const currentEmployeeName = user.userInfo?.firstName + ' ' + user.userInfo?.lastName;
    yield put(FacilityContactsActions.setLoading(true));
    const res = yield getFacilityClientContacts(action.payload.id, 1, 1000);
    if (httpSuccess(res.status)) {
      const responseData = res.data;
      const filterSpecs = {
        contactPreferenceOpts: getMultiSelectOptions(responseData.filterSpecs.contactPreferenceOpts),
        nameOpts: getMultiSelectOptions(responseData.filterSpecs.nameOpts),
        titleOpts: getMultiSelectOptions(responseData.filterSpecs.titleOpts),
        statusOpts: getSigleSelectOptions(responseData.filterSpecs.statusOpts),
        unitNameOpts: getMultiSelectOptions(responseData.filterSpecs.unitNameOpts),
        actionsOpts: getMultiSelectOptions(responseData.filterSpecs.actionsOpts),
      };
      yield put(FacilityContactsActions.setFilterSpecs(filterSpecs));
      yield put(FacilityContactsActions.setStateID('Active'));
      const flattenedData = formatFacilityContacts(responseData.clientContacts, currentEmployeeName);
      yield put(FacilityContactsActions.setFlattenedData(flattenedData));
      yield put(FacilityContactsActions.setLoading(false));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestFacilityClientContactsList',
        area: 'src/app/components/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.saga.ts',
      },
    });
  } finally {
    yield put(FacilityContactsActions.setLoading(false));
  }
}

export function* requestFacilityClientContactsListForUnitEdit(
  action: PayloadAction<{ id: number; unitContacts: UnitContact[] }>,
) {
  try {
    const user = yield select(selectUser);
    const currentEmployeeName = user.userInfo?.firstName + ' ' + user.userInfo?.lastName;
    yield put(FacilityContactsActions.setLoading(true));
    const res = yield getFacilityClientContacts(action.payload.id, 1, 1000);
    if (httpSuccess(res.status)) {
      const formattedData = formatClientContacts(
        res.data.clientContacts.filter(x => x.statusId === ContactStatus.Active),
        action.payload.unitContacts && action.payload.unitContacts.length > 0 ? action.payload.unitContacts : [],
        currentEmployeeName,
      );
      const selectedIndicesSet = formattedData?.reduce((acc, curr, index) => {
        if (curr.selected) {
          acc.push(index);
        }
        return acc;
      }, []);
      yield put(FacilityContactsActions.setFlattenedData(formattedData));
      yield put(FacilityContactsActions.setFilteredData(formattedData));
      yield put(
        gridSelectionActions.setGridSelections({
          selectedData: selectedIndicesSet,
          isCompareGridOpen: false,
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestFacilityClientContactsListRaw',
        area: 'src/app/components/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.saga.ts',
      },
    });
  } finally {
    yield put(FacilityContactsActions.setLoading(false));
  }
}

export function* updateStarIconForUnitEdit(
  action: PayloadAction<{ contactId: number; id: number; isNewUnitEdit: boolean }>,
) {
  const contactsFilteredData = yield select(selectContactsGridData);
  const contactsSourceData = yield select(selectContactsSourceData);
  let { selectedData } = yield select(selectGridSelection);
  const filteredClientContactUpdate = contactsFilteredData?.map(item => {
    if (action.payload.isNewUnitEdit && item.contactId === action.payload.contactId && !item.selected) {
      selectedData = [...selectedData, action.payload.id];
    }
    return item.contactId === action.payload.contactId && item.selected
      ? { ...item, isPrimary: !item.isPrimary }
      : item.contactId === action.payload.contactId && !item.selected
      ? action.payload.isNewUnitEdit
        ? { ...item, isPrimary: !item.isPrimary, selected: !item.selected }
        : { ...item, isPrimary: item.isPrimary }
      : item;
  });
  const clientContactsUpdate = contactsSourceData?.map(item => {
    return item.contactId === action.payload.contactId && item.selected
      ? { ...item, isPrimary: !item.isPrimary }
      : item.contactId === action.payload.contactId && !item.selected
      ? action.payload.isNewUnitEdit
        ? { ...item, isPrimary: !item.isPrimary, selected: !item.selected }
        : { ...item, isPrimary: item.isPrimary }
      : item;
  });
  if (action.payload.isNewUnitEdit) {
    yield put(gridSelectionActions.setGridSelections({ selectedData: selectedData, isCompareGridOpen: false }));
  }
  yield put(FacilityContactsActions.setFlattenedData(clientContactsUpdate));
  yield put(FacilityContactsActions.setFilteredData(filteredClientContactUpdate));
}

export function* updateStarIcon(action: PayloadAction<{ contactId: number }>) {
  try {
    const contactId = action.payload.contactId;
    const filteredClientContacts = yield select(selectContactsGridData);
    const facilitySelector = yield select(selectFacility);
    const filterResult = filteredClientContacts.filter(t => t.contactId === contactId)[0];
    const filteredClientContactUpdate = filteredClientContacts?.map(item => {
      return item.contactId === contactId ? { ...item, isPrimary: !item.isPrimary } : item;
    });
    yield put(FacilityContactsActions.setFilteredData(filteredClientContactUpdate));
    const filteStarrResult = filteredClientContacts.filter(t => t.isPrimary === true && t.contactId !== contactId);
    let starResult: any[] = [];
    if (filteStarrResult && filteStarrResult.length > 0) {
      filteStarrResult.forEach(element => {
        starResult.push({
          clientContactId: element.contactId,
          isPrimary: element.isPrimary,
        });
      });
      starResult.push({
        clientContactId: contactId,
        isPrimary: !filterResult.isPrimary,
      });
    } else {
      starResult.push({
        clientContactId: contactId,
        isPrimary: !filterResult.isPrimary,
      });
    }
    const updatedStarClientContacts: any = {
      facilityId: facilitySelector?.facilityResponse?.facilityId,
      primaryFacilityContacts: starResult,
    };
    const result = yield call(() => updateStarFacilityClientContacts(updatedStarClientContacts));
    if (result) {
      yield put(
        globalActions.setSnackBar({
          message: `The contact ${contactId} has been updated successfully`,
          severity: 'success',
        }),
      );
    } else {
      yield put(
        globalActions.setSnackBar({
          message: `There is some error while updating the contact ${contactId}`,
          severity: 'error',
        }),
      );
    }
    trackEvent({
      type: 'click',
      name: 'Facility:Client Contacts Starred In Contacts Tab',
      properties: { contactStarredTab: 'Contact Starred' },
    });
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonUIError,
        functionName: 'updateStarIcon',
        area: 'src/app/components/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.saga.ts',
      },
    });
  }
}

export function* postFacilityNotesStatus(action: PayloadAction<{ notes?: string; status?: string }>) {
  try {
    const user = yield select(selectUser);
    const clientContractData = yield select(selectClientContractDrawer);
    const contactsFlattenedData = yield select(selectContactsGridData);
    const currentEmployeeName = user.userInfo?.firstName + ' ' + user.userInfo?.lastName;
    const requestPayload = {
      contactId: clientContractData?.contactId,
      notes: action.payload?.notes ?? clientContractData.notes,
      statusId: getStatusIdForContacts(action.payload?.status || clientContractData.status),
      currentEmployeeId: user.userInfo?.employeeId || 0,
      currentEmployeeName: currentEmployeeName,
    };
    const response = yield call(TrackPostNotesStatusUnits, updateNotesStatus, requestPayload);
    if (httpSuccess(response.status)) {
      yield put(
        FacilityContactsActions.setFacilityClientContactDrawer({
          ...clientContractData,
          ...action.payload,
          statusLastUpdated: setUpdatedByClientContract(response?.data?.statusLastUpdated),
        }),
      );

      const updatedClientContacts = contactsFlattenedData?.map(item => {
        return item.contactId === clientContractData?.contactId
          ? {
              ...item,
              ...action.payload,
              statusLastUpdated: setUpdatedByClientContract(response?.data?.statusLastUpdated),
            }
          : item;
      });
      const filterActiveStatusClientContacts = updatedClientContacts.filter(
        item => item.status === StatusDropDownValues.ACTIVE,
      );
      yield put(FacilityContactsActions.setFlattenedData(filterActiveStatusClientContacts));
      yield put(FacilityContactsActions.setFilteredData(filterActiveStatusClientContacts));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'postFacilityNotesStatus',
        area: 'src/app/components/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.saga.ts',
      },
    });
  }
}

export function* facilityClientContactsSaga() {
  yield takeLatest(FacilityContactsActions.requestFacilityClientContactsList.type, requestFacilityClientContactsList);
  yield takeLatest(
    FacilityContactsActions.requestFacilityClientContactsListForUnitEdit.type,
    requestFacilityClientContactsListForUnitEdit,
  );
  yield takeLatest(FacilityContactsActions.updateStarIconForContactsGrid.type, updateStarIcon);
  yield takeLatest(FacilityContactsActions.updateStarIconForContactsGridForUnitEdit.type, updateStarIconForUnitEdit);
  yield takeLatest(FacilityContactsActions.updateFacilityUnitNotesStatus.type, postFacilityNotesStatus);
}
