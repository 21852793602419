import { gridStateActions } from '../../../Common/Grid/GridStateManagement/GridState.redux';
import { selectFiterAttributes } from '../../../Common/Grid/GridStateManagement/GridState.selectors';
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CustomFilter } from '../../../../ComponentLibrary/Filter/CustomFilter';
import { selectFilterSourceData } from './JobInterest.selector';
import { OrderStatus, InterestDate, getJobInterestFilterData } from './utils';
import { getFilter } from './helper';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { getDateValue } from 'utils/dates/dateExtraction';

const useStyles = makeStyles()({
  selectField: {
    minWidth: 160,
  },
});

export const GridFilter = props => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const filterSourceData = useSelector(selectFilterSourceData);
  const appliedFilterAttributes = useSelector(selectFiterAttributes);
  const dispatch = useDispatch();
  const formMethods = useForm({
    defaultValues: {
      orderStatus: OrderStatus[0].value,
      interestDate: InterestDate[1].value,
      fromDate: '',
      toDate: '',
      states: [],
      skillSets: [],
    },
  });
  const {
    setValue,
    getValues,
    setError,
    clearErrors,
    handleSubmit,
    formState: { dirtyFields },
  } = formMethods;

  const [formSourceData, setFormSourceData] = useState(
    getJobInterestFilterData(
      filterSourceData?.states,
      filterSourceData?.skillSets.map(item => `${item.disciplineAbbr} ${item.specialtyAbbr} `),
      t,
      classes.selectField,
    ),
  );

  const setFormDateFields = (fromDate, toDate?) => {
    setValue('fromDate', fromDate);
    if (toDate !== undefined) setValue('toDate', toDate);
    else setValue('toDate', fromDate);
  };

  const onFilterChangeValues = fieldName => {
    if (fieldName === 'interestDate') {
      const interestDateFilter = getValues(fieldName);
      const endOfToday = getDateValue(0, true);
      clearErrors('toDate');
      clearErrors('fromDate');
      if (interestDateFilter === InterestDate[1].value) {
        setFormDateFields(null, null);
      } else if (interestDateFilter === InterestDate[0].value || interestDateFilter === InterestDate[2].value) {
        const today = getDateValue(0);
        setFormDateFields(today, endOfToday);
      } else if (interestDateFilter === InterestDate[3].value) {
        const yesterday = getDateValue(-1);
        setFormDateFields(yesterday, endOfToday);
      } else if (interestDateFilter === InterestDate[4].value) {
        const last7Days = getDateValue(-7);
        setFormDateFields(last7Days, endOfToday);
      } else if (interestDateFilter === InterestDate[5].value) {
        const last30Days = getDateValue(-30);
        setFormDateFields(last30Days, endOfToday);
      } else if (interestDateFilter === InterestDate[6].value) {
        const last90Days = getDateValue(-90);
        setFormDateFields(last90Days, endOfToday);
      } else if (interestDateFilter === InterestDate[7].value) {
        const last120Days = getDateValue(-120);
        setFormDateFields(last120Days, endOfToday);
      }
      clearErrors('toDate');
      clearErrors('fromDate');
    } else {
      if (fieldName === 'fromDate' || fieldName === 'toDate') {
        const interestDateFilter = getValues('interestDate');
        const fromDate = getValues('fromDate');
        const toDate = getValues('toDate');
        if (interestDateFilter !== InterestDate[0].value) setValue('interestDate', InterestDate[0].value);
        if (fromDate && toDate && new Date(fromDate) > new Date(toDate)) {
          setError('toDate', { message: t('search.filter.dateIncompatible') });
          setError('fromDate', { message: t('search.filter.dateIncompatible') });
        } else {
          clearErrors('toDate');
          clearErrors('fromDate');
        }
      }
    }
  };

  const resetForm = (newSourceData?) => {
    const newFilterDefaultValues = Object.entries({
      orderStatus: OrderStatus[0].value,
      interestDate: InterestDate[5].value,
      fromDate: getDateValue(-30),
      toDate: getDateValue(0),
      states: [],
      skillSets: [],
    });
    newFilterDefaultValues?.forEach(item => setValue(item[0], item[1], { shouldDirty: true }));
  };

  const onFiltering = formData => {
    const filters = getFilter(formData, dirtyFields, filterSourceData);
    dispatch(gridStateActions.setFilterAttributes({ filterObject: Object.entries(formData), dirtyFields }));
    dispatch(gridStateActions.setPageNumber(1));
    props.onApplyFilter(filters);
  };

  useEffect(() => {
    const newSourceData = getJobInterestFilterData(
      filterSourceData?.states,
      filterSourceData?.skillSets.map(item => `${item.disciplineAbbr} ${item.specialtyAbbr} `),
      t,
      classes.selectField,
    );
    setFormSourceData(newSourceData);
    if (appliedFilterAttributes)
      appliedFilterAttributes.filters?.forEach(item => setValue(item[0], item[1], { shouldDirty: true }));
    else resetForm(newSourceData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSourceData]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onFiltering)}>
        <CustomFilter
          formMethods={formMethods}
          fields={formSourceData}
          onChangeValues={onFilterChangeValues}
          onResetForm={() => resetForm()}
          justifyContent={'flex-start'}
          version0
          analytics={{
            caller: 'Job Interest Filter',
            onApply: true,
          }}
        />
      </form>
    </FormProvider>
  );
};
