import React, { useRef, useState } from 'react';
import { trackEvent } from 'app-insights/appInsightsTracking';
import { SignalREventType } from 'app/enums/Common';
import { selectUser } from 'oidc/user.selectors';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import EmailDeliveryStatus, {
  IDeliveryStatusProps,
} from '@AMIEWEB/Notification/EmailDeliverStatus/EmailDeliveryStatus';
import { HubEventType } from '@AMIEWEB/Notification/Constants';
import { StyledToastContainer } from '../utils';
import ClearIcon from '@mui/icons-material/Clear';
import { makeStyles } from 'tss-react/mui';
import { taskDetailsActions } from '@AMIEWEB/Tasks/store/Tasks.redux';
import { getNotificationType } from '../Common_v2/utils';
import { NotificationCategory } from 'app/models/Tasks/Tasks';
import { AlertFeed } from '../Common_v2/Feeds/AlertFeed';
import { getNotificationData } from './FormatData';
import { selectDismissNotificationId } from '@AMIEWEB/Tasks/store/Tasks.selectors';

const useStyles = makeStyles()(theme => ({
  closeIcon: {
    display: 'block',
    height: '27px',
    width: '27px',
    background: theme.palette.framework.system.white,
    border: '1px solid #E2DFDF',
    borderRadius: '15px',
    padding: '3px',
    boxShadow: `0px 0px 6px ${theme.palette.system.lightBlack}`,
    cursor: 'pointer',
    transition: '0.3s ease',
    position: 'absolute',
    left: '-12px',
    top: '-12px',
  },
  fixedPos: {
    position: 'fixed',
  },
}));

const isEmailNotified = (event: string) => {
  let ev = event.toLowerCase();
  return (
    ev === HubEventType.EmailBlocked ||
    ev === HubEventType.EmailBounced ||
    ev === HubEventType.EmailDelivered ||
    ev === HubEventType.EmailDropped ||
    ev === HubEventType.EmailProcessed ||
    ev === HubEventType.EmailUnsubscribed ||
    ev === HubEventType.EmailSpam
  );
};

export const EmailDeliveryNotifications = () => {
  const user = useSelector(selectUser);
  const dismissedNotificationId = useSelector(selectDismissNotificationId);
  const [openEmailDeliveryModal, setEmailDeliveryModal] = useState(false);
  const emailDeliveryData = useRef<IDeliveryStatusProps>();
  const { classes } = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (dismissedNotificationId) {
      toast.dismiss(dismissedNotificationId);
      dispatch(taskDetailsActions.setDismissNotificationId(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dismissedNotificationId]);

  const closeNotification = (e, closeToast) => {
    closeToast();
    e.stopPropagation();
  };

  const CloseButton = ({ closeToast }) => {
    return <ClearIcon className={classes.closeIcon} onClick={e => closeNotification(e, closeToast)} />;
  };

  useEffect(
    () => {
      const hubConnection = user?.userInfo?.hubConnection;
      if (hubConnection) {
        hubConnection.on('Broadcast', (receivedUser, eventName, log) => {
          trackEvent({
            type: 'event',
            name: `SignalR Hub`,
            properties: {
              NotificationType: SignalREventType.EmailDelivery,
              UserId: receivedUser,
              CorrelationId: log?.correlationId,
              Log: log,
              Description: `Received email delivery message notification for user: ${receivedUser}`,
            },
          });

          if (isEmailNotified(eventName)) {

              //TODO: change the log type to get channel and use type
              dispatch(
                taskDetailsActions.incrementNotificationCount({
                  notificationType: getNotificationType({
                    useType: log?.useType,
                    useSubType: log?.useSubType,
                    channelType: NotificationCategory.Email,
                  }),
                }),
              );
            const formattedNotification = getNotificationData({
              ...log.emailMessage,
              isNotificationUnread: true,
            });
            toast(
                <AlertFeed
                  key={0}
                  index={0}
                  notifyData={formattedNotification}
                  pushNotification={true}
                />,
              {
                toastId: log.emailMessage.id,
                containerId: 'EmailDeliveryNotification',
                position: 'top-right',
                closeButton: true,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 5000,
              },
            );

            return;
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <>
      <div className={classes.fixedPos}>
        <StyledToastContainer
          enableMultiContainer
          containerId={'EmailDeliveryNotification'}
          limit={3}
          closeButton={CloseButton}
        />
      </div>
      <EmailDeliveryStatus {...emailDeliveryData.current} open={openEmailDeliveryModal} />
    </>
  );
};
