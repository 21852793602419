import { taskDetailsActions, taskDetailsReducer, taskDetailsSliceKey } from 'app/components/Tasks/store/Tasks.redux';
import { taskDetailsSaga } from 'app/components/Tasks/store/Tasks.saga';
import { selectCreatedTaskData, selectTaskId, SelectedContainer } from 'app/components/Tasks/store/Tasks.selectors';
import {
  NotificationBrand,
  NotificationSender,
  StatusUpdateModalVersion,
  Task,
  TaskEntity,
  TaskNotificationType,
} from 'app/models/Tasks/Tasks';
import moment from 'moment-timezone';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { Concatenate } from 'utils/string/string';
import { getTaskEntityValue } from './Common/FormatData';
import { selectPlacementHeaderData } from 'store/redux-store/placement-details/selectors';
import { selectFacility } from 'app/components/Facility/FacilityStore/Facility.selector';
import { selectOrderDetailsUIView } from 'app/components/Order/Store/Order.selectors';
import { candidateDetailsSelection } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';

export const useCreateTaskNotification = () => {
  useInjectSaga({ key: taskDetailsSliceKey, saga: taskDetailsSaga });
  useInjectReducer({ key: taskDetailsSliceKey, reducer: taskDetailsReducer });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const createdId = useSelector(selectTaskId);
  const createdTaskData = useSelector(selectCreatedTaskData);
  const currentContainer = useSelector(SelectedContainer);
  const placementHeaderData = useSelector(selectPlacementHeaderData);
  const facilityData = useSelector(selectFacility);
  const orderData = useSelector(selectOrderDetailsUIView);
  const candidateData = useSelector(candidateDetailsSelection);

  const formatUserData = user => {
    const name = user?.label?.split(' ');
    const formatedUser = {
      name: name[0] + ' ' + name[1],
      email:'',
      phoneNumber: '',
      contactId: user?.empId?.toString(),
    };
    return formatedUser;
  };

  const getAssignedToList = () => {
    const assignedToArray: any[] = [];
    createdTaskData?.assignedTo?.forEach(user => {
      if (createdTaskData?.sentByUser?.id !== user.empId) {
        const userData = formatUserData(user);
        assignedToArray.push(userData);
      }
    });

    createdTaskData?.cc?.forEach(user => {
      if (createdTaskData?.sentByUser?.id !== user.empId) {
        const userData = formatUserData(user);
        assignedToArray.push(userData);
      }
    });
    return assignedToArray;
  };

  const getTaskCreatedNotifications = async () => {
    let entityId: string = '0';
    let primaryText: string = '';
    if (currentContainer === TaskEntity.PLACEMENTS) {
      entityId = placementHeaderData?.candidate?.id?.toString();
      primaryText = Concatenate(
        [
          placementHeaderData?.candidate?.firstName,
          ' ',
          placementHeaderData?.candidate?.middleName || '',
          ' ',
          placementHeaderData?.candidate?.lastName,
        ],
        '',
      );
    } else if (currentContainer === TaskEntity.FACILITIES) {
      entityId = createdTaskData?.facilityId?.toString();
      primaryText = facilityData?.facilityResponse?.facilityName;
    } else if (currentContainer === TaskEntity.ORDERS) {
      entityId = createdTaskData?.facilityId?.toString();
      primaryText = orderData?.facilityDetails?.facilityResponse?.facilityName;
    } else if (currentContainer === TaskEntity.CANDIDATES) {
      entityId = createdTaskData?.candidateId?.toString();
      primaryText = Concatenate(
        [candidateData?.name?.first, ' ', candidateData?.name?.middle || '', ' ', candidateData?.name?.last],
        '',
      );
    } else {
      entityId = '0';
    }

    if (primaryText) {
      const taskNotificationPayload = {
        brand: NotificationBrand,
        useType: Task,
        useSubType: TaskNotificationType.TASKCREATED.toString(),
        sendType: 'Bulk',
        sender: NotificationSender,
        ttlValue: 2,
        ttlPeriod: 'w',
        associatedRecords: [
          {
            name: 'TaskId',
            value: createdId?.toString(),
          },
          {
            name: 'TaskName',
            value: createdTaskData?.task,
          },
          {
            name: 'DueDate',
            value: createdTaskData?.dueDate,
          },
          {
            name: 'CurrentContainer',
            value: getTaskEntityValue(currentContainer, t),
          },
          {
            name: 'PrimaryContext',
            value: primaryText,
          },
          {
            name: 'PlacementId',
            value: createdTaskData?.placementId ? createdTaskData?.placementId?.toString() : '',
          },
          {
            name: 'FacilityId',
            value: createdTaskData?.facilityId ? createdTaskData?.facilityId?.toString() : '',
          },
          {
            name: 'UnitId',
            value: createdTaskData?.unitId ? createdTaskData?.unitId?.toString() : '',
          },
          {
            name: 'CandidateId',
            value: createdTaskData?.candidateId ? createdTaskData?.candidateId?.toString() : '',
          },
          {
            name: 'OrderId',
            value: createdTaskData?.orderId ? createdTaskData?.orderId?.toString() : '',
          },
          {
            name: 'Description',
            value: createdTaskData?.description ? createdTaskData?.description : '',
          },
          {
            name: 'TaskCategory',
            value: createdTaskData?.taskCategoryText ? createdTaskData?.taskCategoryText : '',
          },
          {
            name: 'EntityId',
            value: entityId,
          },
          {
            name: 'SentDate',
            value: moment.tz(new Date(), 'America/Los_Angeles').format(),
          },
          {
            name: 'Version',
            value: StatusUpdateModalVersion.PARENT_TASK.toString(),
          },
        ],
        body: '',
        level: 1,
        templateId: '',
        tos: getAssignedToList(),
        ccs: [],
        groupName: '',
      };
      if (taskNotificationPayload?.tos?.length > 0) {
        if (createdTaskData?.companyId > 0) {
          taskNotificationPayload?.associatedRecords?.push({
            name: 'BrandId',
            value: createdTaskData?.companyId?.toString(),
          });
        }
        dispatch(taskDetailsActions.sendTaskCreatedNotification(taskNotificationPayload));
      }
    }
  };
  useEffect(() => {
    if (createdId && createdId > 0 && createdTaskData) {
      getTaskCreatedNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdId, createdTaskData]);
};
