import React, { useState } from 'react';
import { Grid, Typography, TextField, IconButton, InputAdornment, Chip } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from '../../ComponentLibrary/DateRange/components/DateRangePicker';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { differenceInCalendarMonths, differenceInDays } from 'date-fns';
import _ from 'lodash';
const useStyles = makeStyles()(theme => ({
  header: {
    paddingBottom: '1%',
    fontSize: 16,
    color: '#52429A',
  },
  addButton: {
    color: '#006FB9',
    padding: '0',
  },
  dateSeperator: {
    display: 'grid',
    justifyContent: 'center',
    alignContent: 'center',
    maxWidth: 40,
  },
  picker: {
    marginTop: '1%',
  },
  months: {
    maxWidth: 'fit-Content',
  },
  addButtonGrid: {
    paddingLeft: '0.5%',
  },
  chip: {
    margin: '0 16px 16px 0',
  },
  calendarIcon: {
    opacity: 0.6,
  },
}));

export default function TimeOffRequest(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [start, setStart] = useState<string | null>(null);
  const [end, setEnd] = useState<string | null>(null);
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const { classes } = useStyles();
  const yesterday = new Date(Date.now() - 864e5);

  const handleTimeRange = range => {
    if (!props.value) props.onChange([{ key: 0, value: { start: start, end: end }, isReadOnly: false }]);
    else
      props.onChange([
        ...props.value,
        { key: props.value.length, value: { start: start, end: end }, isReadOnly: false },
      ]);
    setStart(null);
    setEnd(null);
    setButtonEnabled(true);
    setOpen(false);
  };

  const handleDateRange = range => {
    const { startDate, endDate } = range;
    setStart(startDate);
    setEnd(endDate);
    setButtonEnabled(false);
  };
  const handleDelete = data => {
    const timeOffRange = _.cloneDeep(props.value.filter(chip => chip.key !== data.key));
    timeOffRange.map((item, index) => {
      item.key = index;
    });
    props.onChange(timeOffRange);
  };

  const getTimeElapsed = (startDate, endDate) => {
    const days = Math.abs(differenceInDays(startDate, endDate));
    const months = Math.abs(differenceInCalendarMonths(startDate, endDate));
    if (days < 30) {
      return '(' + days + ' ' + (days < 2 ? 'day' : 'days') + ')';
    } 
    return '(' + months + ' ' + (months < 2 ? 'month' : 'months') + ')';
  }

  return (
    <Grid container>
      <Typography className={classes.header}>{t('candidate.timeOff.TimeOffRequest')}</Typography>
      <Grid item container>
        <Grid item sm={2} className={classes.months}>
          <TextField
            id="filled-required"
            label="Start Date"
            value={start !== null ? Moment(start).format('MM/DD/YYYY') : ''}
            placeholder={t('candidate.timeOff.Placeholder')}
            variant="filled"
            onKeyDown={event => {
              event.preventDefault();
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarTodayIcon className={classes.calendarIcon} />
                </InputAdornment>
              ),
              // classes: { underline: classes.textfieldUnderline, focused: classes.textfieldFocused },
              'aria-label': 'start-date',
            }}
            onClick={() => setOpen(prevState => !prevState)}
          />
        </Grid>
        <Grid item sm={1} className={classes.dateSeperator}>
          <Typography variant="subtitle1">{t('candidate.timeOff.To')}</Typography>
        </Grid>
        <Grid item sm={2} className={classes.months}>
          <TextField
            id="filled-required"
            label="End Date"
            value={end !== null ? Moment(end).format('MM/DD/YYYY') : ''}
            placeholder={t('candidate.timeOff.Placeholder')}
            variant="filled"
            onKeyDown={event => {
              event.preventDefault();
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarTodayIcon className={classes.calendarIcon} />
                </InputAdornment>
              ),
              // classes: { underline: classes.textfieldUnderline, focused: classes.textfieldFocused },
              'aria-label': 'end-date',
            }}
            onClick={() => setOpen(prevState => !prevState)}
          />
        </Grid>
        <Grid item sm={1} className={classes.addButtonGrid}>
          <IconButton
            color="primary"
            type="button"
            disabled={buttonEnabled}
            disableTouchRipple
            disableFocusRipple
            onClick={handleTimeRange}
            className={classes.addButton}
            aria-label="add-date-range"
            size="small">
            <AddBoxIcon style={{ fontSize: 60 }} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item sm={12} className={classes.picker}>
        {open ? <DateRangePicker open minDate={yesterday} onChange={range => handleDateRange(range)} /> : null}
      </Grid>
      <Grid item sm={12}>
        {props.value &&
          props.value.map((data, index) => {
            const value = data.value.start && data.value.end ? `${
              Moment(data.value.start).format('MM/DD/YYYY') + ' - ' + Moment(data.value.end).format('MM/DD/YYYY') + ' ' + getTimeElapsed(data.value.start, data.value.end)
            }` : '--';
            if (data.isReadonly) return <Chip key={index} label={value} className={classes.chip} />;
            return <Chip key={index} label={value} onDelete={() => handleDelete(data)} className={classes.chip} />;
          })}
      </Grid>
    </Grid>
  );
}
