import React, { useEffect, useState } from 'react';
import {  Theme, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import WorkIcon from '@mui/icons-material/Work';
import { green } from '@mui/material/colors';
import {
  ComparisonGrid,
  IRowAttributes,
  GetCompareStatus,
  IRowAttributeValues,
} from '../../../Common/ComparisonGrid/ComparisonGrid';
import { Concatenate } from 'utils/string/string';
import { useSelector, useDispatch } from 'react-redux';
import { selectGridSelection } from 'app/components/Common/Grid/GridSelection/GridSelection.selector';
import { gridSelectionActions } from 'app/components/Common/Grid/GridSelection/GridSelection.redux';
import { CandidateDetails } from './CandidateDetails';
import { useTranslation } from 'react-i18next';
import { candidateDetailsSelection } from '../Profile/CandidateDetails.selector';
import { breadcrumbActions } from '../../../Common/BreadcrumbNavigation/BreadcrumbNavigation.redux';
import { getNavData } from '../CandidateJobMatchGrid/gridUtils';
import { selectJobMatches } from '../CandidateJobMatchGrid/JobMatch.selectors';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';

interface IOrder {
  orderId: number;
  rank: number;
  candidateInterestRating: string;
  candidateOfferRating: string;
  facilityName: string;
  facilityCityState: string;
  unit: string;
  weeklyGrossPay: string;
  gww: string | JSX.Element;
  predictiveGtgRating: string;
}

interface IPreference {
  skillset: JSX.Element | undefined;
  location: JSX.Element | undefined;
  jobType: JSX.Element | undefined;
  jobExperienceLevel: JSX.Element | undefined;
  clientSetting: JSX.Element | undefined;
  startDate: JSX.Element | undefined;
  placementLength: JSX.Element | undefined;
  shiftTime: JSX.Element | undefined;
  shiftLength: JSX.Element | undefined;
}

const useStyles = makeStyles()((theme: Theme) =>
  ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      outline: 'none',
      borderRadius: '4px 4px 0px 0px',
      maxWidth: '90vw',
      maxHeight: '91vh',
      overflow: 'auto',
    },
    container: {
      flexWrap: 'nowrap',
    },
  }));

export const JobMatchComparison = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const jobMatches = useSelector(selectJobMatches);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const { selectedData, isCompareGridOpen } = useSelector(selectGridSelection);

  const [comparisons, setComparisons] = useState<{ orders: IOrder[]; preferences: IPreference[] }>({
    orders: [],
    preferences: [],
  });
  const [attributeValues, setAttributeValues] = useState<{
    facility: IRowAttributeValues[];
    order: IRowAttributeValues[];
  }>({
    facility: [],
    order: [],
  });
  const dispatch = useDispatch();

  const rowAttributes: IRowAttributes[] = [
    {
      key: 'facilityCityState',
      title: 'Facility City, State',
    },
    {
      key: 'gww',
      title: 'GWW',
    },
    {
      key: 'facilityName',
      fieldIsALink: true,
      attributeValues: attributeValues.facility,
    },
    {
      key: 'orderId',
      fieldIsALink: true,
      attributeValues: attributeValues.order,
    },
    {
      key: 'predictiveGtgRating',
      title: 'Predictive GTG Rating',
    },
  ];

  const orderNavData = getNavData('Order Details', candidateDetails?.travelerId, candidateDetails?.brandId);
  const facilityNavData = getNavData('Facility Details', candidateDetails?.travelerId, candidateDetails?.brandId);

  const handleClose = () => {
    dispatch(gridSelectionActions.setGridSelections({ selectedData: selectedData, isCompareGridOpen: false }));
  };

  const handleNavigation = navigateTo => {
    if (navigateTo.includes('orderId')) {
      dispatch(breadcrumbActions.setNavigationData(orderNavData));
    }

    if (navigateTo.includes('facilityName')) {
      dispatch(breadcrumbActions.setNavigationData(facilityNavData));
    }
  };

  useEffect(() => {
    const comparison: { orders: IOrder[]; preferences: IPreference[] } = { orders: [], preferences: [] };
    const facilityLinks: { link: string }[] = [];
    const orderLinks: { link: string }[] = [];

    selectedData?.forEach(selectedOrderId => {
      const item = jobMatches.find(order => order.orderId === selectedOrderId);
      if (item) {
        const facilityLocation = Concatenate([item.city, item.state], ', ');
        const preferenceMatch = item.candidateJobMatchComparison.comparisonPreferenceMatch;

        const order: IOrder = {
          orderId: item.orderId,
          rank: item.rank,
          candidateInterestRating: item.candidateInterestRating !== null ? item.candidateInterestRating + '%' : 'N/A',
          candidateOfferRating: item.candidateOfferRating !== null ? item.candidateOfferRating + '%' : 'N/A',
          predictiveGtgRating: item.predictiveGoodToGoScore !== null ? item.predictiveGoodToGoScore + '%' : 'N/A',
          facilityName: item.facilityName ? item.facilityName : 'N/A',
          facilityCityState: facilityLocation ? facilityLocation : 'N/A',
          unit: item.orderData.unit ? item.orderData.unit : 'N/A',
          weeklyGrossPay: item.weeklyTakeHomePay !== null ? `$${item.weeklyTakeHomePay}/Week` : 'N/A',
          gww: item.orderData.gww ? <WorkIcon style={{ color: green[500] }} /> : ' ',
        };

        facilityLinks.push({ link: `/facility/${item.facilityId}` });
        orderLinks.push({ link: `/order/${item.orderId}` });

        const preference: IPreference = {
          skillset: GetCompareStatus(preferenceMatch.skillSet.isMatch, preferenceMatch.skillSet.fieldValue),
          location: GetCompareStatus(preferenceMatch.location.isMatch, preferenceMatch.location.fieldValue),
          jobType: GetCompareStatus(preferenceMatch.jobType.isMatch, preferenceMatch.jobType.fieldValue),
          jobExperienceLevel: GetCompareStatus(
            preferenceMatch.jobExperienceLevel.isMatch,
            preferenceMatch.jobExperienceLevel.fieldValue,
          ),
          clientSetting: GetCompareStatus(
            preferenceMatch.clientSettings.isMatch,
            preferenceMatch.clientSettings.fieldValue,
          ),
          startDate: GetCompareStatus(preferenceMatch.startDate.isMatch, preferenceMatch.startDate.fieldValue),
          placementLength: GetCompareStatus(
            preferenceMatch.placementLength.isMatch,
            preferenceMatch.placementLength.fieldValue,
          ),
          shiftTime: GetCompareStatus(preferenceMatch.shiftTime.isMatch, preferenceMatch.shiftTime.fieldValue),
          shiftLength: GetCompareStatus(preferenceMatch.shiftLength.isMatch, preferenceMatch.shiftLength.fieldValue),
        };
        comparison.orders.push(order);
        comparison.preferences.push(preference);
      }
    });
    setComparisons(comparison);
    setAttributeValues({ facility: facilityLinks, order: orderLinks });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData]);

  return (
    <>
      {isCompareGridOpen && (
         <GenericDialog
         variant={'blue'}
         classes={{ paper: classes.modal }}
         open={isCompareGridOpen || false}
         disableEscapeKeyDown
         maxWidth="lg"
         fullWidth
         dialogTitleProps={{
           text: `${t('candidate.jobMatchComparison.title')}`,
           closeButton: true,
           expandable: true,
         }}
         onClose={handleClose}
       >
             <Grid container direction="column" spacing={2} classes={{ container: classes.container }}>
               <Grid item>
                 <CandidateDetails />
               </Grid>
               <Grid item>
                 <ComparisonGrid
                  comparisonData={[
                    {
                      sectData: comparisons.orders,
                      headerKey: 'orderId',
                      rowAttributes: rowAttributes,
                    },
                    { sectData: comparisons.preferences, headerTitle: t('Preference Match') },
                  ]}
                  handleClick={navigateTo => handleNavigation(navigateTo)}
                />
              </Grid>
            </Grid>
       </GenericDialog>
      )}
    </>
  );
};
