import React from 'react';

const defaultDocumentTitle = 'AMIE Web';

/**
 * Update the document title for your page
 * @param title String value for the document title
 * @param revertToPreviousTitle Setting this to true will reset the document title to what it was previously on unmount of the component it's used in
 */
const useDocumentTitle = ({ title, revertToPreviousTitle }: { title?: string; revertToPreviousTitle?: boolean }) => {
  const previousTitle = document.title;
  React.useEffect(() => {
    document.title = title ?? defaultDocumentTitle;
    return () => {
      if (revertToPreviousTitle) document.title = previousTitle;
      else document.title = defaultDocumentTitle;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, revertToPreviousTitle]);
};

export default useDocumentTitle;
