import React, { useEffect, useState } from 'react';
import { Grid, List, ListItem, ListItemText, Skeleton } from 'amn-ui-core';
import { UnitCard } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/CustomComponents/UnitCard';
import { missingField } from 'app/constants';
import { LayoutGrid } from 'app/layout/LayoutGrid';
import { useSelectedUnitDetails, useUnitStatusInActive, isAuthorizedForAddEdit } from './unitHelper';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { usePromiseTracker } from 'react-promise-tracker';
import { useStylesOrderPriority } from './Unit.styles';
import { OrderPriorityType } from 'app/models/Unit/CreateUnit';
import { sortBy } from 'lodash';
import { VirtualInterviewRatingsId } from '@AMIEWEB/NewOrder/constants';

const UnitOrderPriorities = () => {
  const { classes } = useStylesOrderPriority();
  const { t } = useTranslation();
  const [numberOfOrderPriorities, setnumberOfOrderPriorities] = useState<number>(0);
  const [orderPriorities, setOrderPriorities] = useState<OrderPriorityType[]>(null);
  const selectedUnitDetail = useSelectedUnitDetails();
  const orderPriority = selectedUnitDetail?.orderPriorities;
  const userData = useSelector(selectUser);
  const isAuthorized = isAuthorizedForAddEdit(userData);
  const isUnitInActive = useUnitStatusInActive();
  const { promiseInProgress: isFacilityWithFilterDetailsLoading } = usePromiseTracker({
    area: 'get-facility-details-with-filters',
    delay: 0,
  });

  useEffect(() => {
    if (orderPriority?.length) {
      const updatedOrderPriority = orderPriority?.map(item => {
        if (item?.id === 0 && VirtualInterviewRatingsId?.hasOwnProperty(item?.description)) {
          const id = Number(VirtualInterviewRatingsId[item?.description]);
          return {
            ...item,
            id,
          };
        }
        return item;
      });

      setOrderPriorities(sortBy(updatedOrderPriority, ['id'], ['asc']));
    }

    setnumberOfOrderPriorities(orderPriority?.length ?? 0);
  }, [selectedUnitDetail]);

  return (
    <UnitCard
      title={t('facility.units.orderPriorities.title', { orderPriorities: numberOfOrderPriorities })}
      rightIcon={true}
      showEditIcon={isAuthorized && !isUnitInActive}
      skeletonWidth={130}
      isDataLoading={isFacilityWithFilterDetailsLoading}
    >
      {isFacilityWithFilterDetailsLoading ? (
        <LayoutGrid sx={{ p: '12px', m: '0' }}>
          <Grid display={'flex'} flexDirection={'column'} gap={1}>
            <Skeleton variant="rectangular" width={265} height={13} />
            <Skeleton variant="rectangular" width={179} height={13} />
          </Grid>
        </LayoutGrid>
      ) : (
        <LayoutGrid sx={{ p: '12px', m: '0' }}>
          {numberOfOrderPriorities ? (
            <List className={classes.list}>
              {orderPriorities?.map(item => (
                <ListItem key={item.id} className={classes.listItem}>
                  <ListItemText primary={item.id + '. ' + item.description} className={classes.listItemText} />
                </ListItem>
              ))}
            </List>
          ) : (
            missingField
          )}
        </LayoutGrid>
      )}
    </UnitCard>
  );
};

export default UnitOrderPriorities;
