import { missingField } from 'app/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useSelector } from 'react-redux';
import { TableSection } from '../../../customComponents/TableSection';
import { getRequirementCheck } from '../../../helper';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import {
  selectSubmissionInfo,
  selectServiceFailures,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { RequirementsRow } from '../../TableComponents/TableComponents';

export const WorkHistory = () => {
  const { t } = useTranslation();
  const {
    amnReq: {
      amn: { workHistory },
    },
    requirementFilter,
  } = useSelector(selectSubmissionInfo);
  const { workHistorySubmissionRequirement: serviceFailed } = useSelector(selectServiceFailures);

  const { promiseInProgress } = usePromiseTracker({
    area: 'placement-submission-work-history-submission-requirement',
    delay: 0,
  });

  const { display } = useRequirementFilter('submission-amn', requirementFilter, workHistory?.type);

  return display ? (
    <RequirementsRow
      id="placement-submission-amn-work-history"
      title={t('placement.profile.summary.submission.amn.workHistory.title')}
      requirementCell={<RowKey workHistory={workHistory} t={t} />}
      candidateCell={missingField}
      loading={promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() => {}}
    />
  ) : null;
};

const RowKey = ({ workHistory, t }) => {
  return (
    <TableSection
      items={[
        {
          icon: workHistory ? getRequirementCheck(workHistory.type) : null,
          primaryContent: t(workHistory?.message?.primary),
        },
      ]}
    />
  );
};
