import { Box, Checkbox, FormControlLabel, FormGroup, Grid, InputAdornment, TextField } from 'amn-ui-core';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { PacketTemplate } from 'app/constants/OrderCreationForm';
import BrandedPacket from 'app/assets/images/Order/BrandedPacket.svg';
import UnbrandedPacket from 'app/assets/images/Order/UnbrandedPacket.svg';
import { Tooltip } from 'amn-ui-core/dist/components/Tooltip/Tooltip';
import { IpacketTemplate } from '@AMIEWEB/Order/OrderDetails/OrderPreferences/SubmissionPreferences/SubmissionHelper';
import { PacketTemplateDropDown } from '@AMIEWEB/Common/SubmissionPreference/SubmissionPacket/PacketTemplateDropDown';

const useStyles = makeStyles<{ disabled: boolean }>()((theme, { disabled = false }) => ({
  contentRoot: {
    paddingTop: '10px',
    alignItems: 'center',
    margin: '5px 13px 12px 10px',
    width: '94%',
  },
  tooltipBackground: {
    backgroundColor: `${theme.palette.system.davyGray}`,
    color: `${theme.palette.components.tooltip.backgroundColor}`,
    opacity: 1,
    textAlign: 'left',
    font: '12px/30px Roboto',
    boxShadow: `0px 3px 6px ${theme.palette.system.darkBlackBlue}`,
    border: `1px solid ${theme.palette.system.davyGray}`,
    lineHeight: '1rem',
    maxWidth: '585px',
  },
  tooltipWidth: {
    maxWidth: '490px',
  },
  checkbox: {
    marginTop: '10px',
    width: '88%',
  },
  tooltipArrow: {
    color: `${theme.palette.system.davyGray}`,
    opacity: 100,
    '&::before': {
      boxShadow: `0px 3px 6px ${theme.palette.system.backgroundGrey}`,
      border: `1px solid ${theme.palette.system.darkGrey}`,
    },
  },
  scrollableContent: {
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '410px',
    overflowY: 'auto',
  },
  thumbnails: {
    width: '540px',
    height: '348px',
    marginBottom: '5px',
    marginTop: '5px',
  },
  BillRateField: {
    marginLeft: '15px',
    paddingLeft: '15px',
    width: '96%',
  },
  labels: {
    maxHeight: '410px',
  },
  checkBoxLabel: {
    fontSize: '14px',
  },
  containerDisable: {
    pointerEvents: 'none',
  },
  InfoOutlinedIcon: {
    opacity: disabled ? 0.5 : 1,
    alignSelf: 'center',
  },
}));

export interface IPacketFields {
  Template: string;
}

export const initialPacketState: IpacketTemplate = {
  amnBrand: false,
  anonymize: false,
  showCandidateHighlights: false,
  showBillRate: false,
  billRate: null,
  templateValue: null, // 'AMNDefault',
};

export const SubmissionPacketSelector = ({ value = initialPacketState, onChange, disabled }) => {
  const { t } = useTranslation();
  const { cx, classes } = useStyles({ disabled });

  const { amnBrand, anonymize, showCandidateHighlights, showBillRate, billRate, templateValue } = value;

  const handleCheckboxChange = (checkboxName: 'amnBrand' | 'anonymize' | 'showBillRate' | 'showCandidateHighlights') => {
    onChange({ ...value, [checkboxName]: !value[checkboxName] });
  };

  const handleSelectedOptionChange = option => {
    onChange({ ...value, templateValue: option?.value });
  };

  const selectedOption = React.useMemo(
    () => disabled ? null : PacketTemplate.find(template => template.value === templateValue) ?? PacketTemplate[0],
    [disabled, templateValue],
  );

  return (
    <Grid
      display="flex"
      direction="column"
      spacing={1}
      justifyContent="space-between"
      wrap="nowrap"
      className={cx(classes.contentRoot, { [classes.containerDisable]: disabled })}
    >
      <PacketTemplateDropDown
        label={t('Template')}
        options={PacketTemplate}
        name={'Template'}
        isDisabled={disabled}
        selectedOption={selectedOption}
        setSelectedOption={handleSelectedOptionChange}
      />
      <FormGroup className={`${classes.checkbox}`}>
        <CheckboxInfo
          checked={amnBrand}
          disabled={disabled}
          label={t('AMN Brand')}
          classProps={{ cx, classes }}
          onChange={() => handleCheckboxChange('amnBrand')}
        >
          <div className={classes.scrollableContent}>
            <div className={classes.labels}>
              <span>{t('Preferences.PacketTemplatesPreferences.AMNBranding')}</span>
              {<img src={BrandedPacket} alt="Branded Packet" className={classes.thumbnails} />}
              <span>{t('Preferences.PacketTemplatesPreferences.AMNUBranding')}</span>
              {<img src={UnbrandedPacket} alt="Unbranded Packet" className={classes.thumbnails} />}
            </div>
          </div>
        </CheckboxInfo>
        {/* 
        Hiding Anonymize Checkbox for 
        User Story 348945: Submission Preferences - Disable Anonymize Checkbox  */}
        {/* <CheckboxInfo
          checked={anonymize}
          disabled={disabled}
          label={t('Anonymize')}
          classProps={{ cx, classes }}
          onChange={() => handleCheckboxChange('anonymize')}
          applyTooltipWidth
        >
          <div className={classes.labels}>
            <span>{t('Preferences.PacketTemplatesPreferences.CandidateDetails')}</span>
          </div>
        </CheckboxInfo> */}
        <CheckboxInfo
          checked={showCandidateHighlights}
          disabled={disabled}
          label={t('Show Candidate Highlights')}
          classProps={{ cx, classes }}
          onChange={() => handleCheckboxChange('showCandidateHighlights')}
          applyTooltipWidth
        >
          <div className={classes.labels}>
            <span>{t('Preferences.PacketTemplatesPreferences.CandidateHighlights')}</span>
          </div>
        </CheckboxInfo>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={showBillRate}
                onChange={() => {
                  handleCheckboxChange('showBillRate');
                }}
                disabled={disabled}
              />
            }
            label={t('Show Bill Rate')}
            classes={{ label: classes.checkBoxLabel }}
            data-testid="packet-templates-billrate-checkbox"
          />
          <Box className={classes.BillRateField}>
            <BillRateField
              disabled={disabled || !showBillRate}
              billRate={billRate}
              onChange={newValue => onChange({ ...value, billRate: newValue })}
            />
          </Box>
        </Box>
      </FormGroup>
    </Grid>
  );
};

const CheckboxInfo = ({ checked, onChange, label, disabled, classProps: { cx, classes }, ...props }) => (
  <Box sx={{ display: 'flex' }}>
    <FormControlLabel
      control={
        <Checkbox
          disabled={disabled}
          checked={checked}
          onChange={() => {
            onChange('amnBrand');
          }}
        />
      }
      label={label}
      classes={{ label: classes.checkBoxLabel }}
    />
    <Tooltip
      classes={{
        tooltip: cx(classes.tooltipBackground, { [classes.tooltipWidth]: props.applyTooltipWidth }),
        arrow: classes.tooltipArrow,
      }}
      arrow
      title={props.children}
      placement="bottom-start"
    >
      <InfoOutlinedIcon className={classes.InfoOutlinedIcon} />
    </Tooltip>
  </Box>
);

const BillRateField = ({ disabled = false, billRate, onChange }) => {
  const textFieldRef = useRef(null);
  const { t } = useTranslation();

  const handleChange = val => {
    const newValue = val.replace(/[^0-9.-]/g, '');
    if (/^(\$)?(\d+(\.\d{0,2})?)?$/.test(newValue) && (newValue === '' || parseFloat(newValue) <= 999999.99))
      onChange(newValue);
  };

  return (
    <TextField
      disabled={disabled}
      data-testid="bill-rate"
      id="filled-basic"
      label="Bill rate"
      variant="filled"
      value={disabled ? '' : billRate}
      fullWidth
      onChange={e => handleChange(e.target.value)}
      inputRef={textFieldRef}
      InputProps={{
        startAdornment: !disabled && <InputAdornment position="start">{t('currency.dollar')}</InputAdornment>,
      }}
    />
  );
};
