import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CustomTypeAhead, ICustomOption } from '../CustomComponents/CustomTypeAhead';
import { Controller, useFormContext } from 'react-hook-form';
import throttle from 'lodash/throttle';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectCreatedPlacement } from '../../../../../store/redux-store/new-placement/selectors';
import { newPlacementActions } from '../../../../../store/redux-store/new-placement/slice';
import { getSessionValue, SessionKey } from 'utils/customHooks/sessionStorage/sessionHelpers';
import { getOrderOptions } from 'app/services/OrderServices/OrderServices';
import { createPlacementTabIDs } from '../helper';
import { CreatePlacementContext } from '../CreatePlacementWrapper';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';

export const SearchField = ({ isDefaultCandidate, isDefaultOrder, isOrderRefreshRequest, sessionKey, ...props }) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const newPlacementId = useSelector(selectCreatedPlacement);
  const [options, setOptions] = useState<ICustomOption[]>([]);
  const { promiseInProgress } = usePromiseTracker({ area: 'order-throttle-area', delay: 0 });
  const [showHistory, setShowHistory] = useState<boolean>(true);
  const dispatch = useDispatch();

  const { setCurrentFocusId } = useContext(CreatePlacementContext);

  const fetchOptions = async request => {
    setShowHistory(false);
    setOptions([]);
    await getOrderOptions(request)
      .then(res =>
        setOptions(
          res.map(
            opt =>
              ({
                object: opt,
                primaryLabel: opt.orderId.toString(),
                secondaryLabel: opt.facility,
              } as ICustomOption),
          ),
        ),
      )
      .catch(error => {
        setOptions([]);
        trackException({
          exception: error,
          properties: {
            name: ExceptionType.APIResponseError,
            functionName: 'fetchOptions',
            area: 'src/app/components/Placement/CreatePlacement/Order/OrderSearch.tsx',
          },
        });
      });
  };

  const throttleService = useMemo(
    () => throttle(request => trackPromise(fetchOptions(request), 'order-throttle-area'), 2000),
    [],
  );

  const setSessionOptions = () => {
    setShowHistory(true);
    const recentOrders = getSessionValue(SessionKey.recentOrders);
    const newOpts = recentOrders
      ?.filter(x => x.orderId && x.facility)
      ?.map(
        opt => ({ object: opt, primaryLabel: opt.orderId.toString(), secondaryLabel: opt.facility } as ICustomOption),
      );
    if (newOpts) {
      setOptions(newOpts);
    }
    return newOpts;
  };

  const autoPopulate = (removeOrderSelection = false) => {
    const newOpts = setSessionOptions();
    if (newOpts?.length && !removeOrderSelection) {
      setValue('order', newOpts[0], { shouldDirty: true });
      dispatch(newPlacementActions.getOrderAction({ orderId: newOpts[0].object.orderId as number }));
    } else removeOrderSelection && dispatch(newPlacementActions.setOrder(null));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => isDefaultCandidate && autoPopulate(), []);

  return (
    <Controller
      control={control}
      name="order"
      render={({ ref, onChange, ...rest }) => (
        <CustomTypeAhead
          id={createPlacementTabIDs.createPlacementOrderSearch}
          onInputChange={throttleService}
          options={options}
          loading={promiseInProgress}
          searchOnCharacterCount={3}
          label={props.label ?? t('placement.create.popup.jobApplying')}
          disabled={!isOrderRefreshRequest && (isDefaultOrder || newPlacementId !== null)}
          onChange={(event, newValue) => {
            newValue
              ? dispatch(newPlacementActions.getOrderAction({ orderId: newValue.object.orderId }))
              : autoPopulate(true);
            onChange(newValue);
          }}
          {...rest}
          showHistory={showHistory}
          resetHistory={() => setSessionOptions()}
          historyTitle={t('placement.create.dropdown.recentOrders')}
          onClick={() => setCurrentFocusId(createPlacementTabIDs.createPlacementOrderSearch)}
          variant={props.variant}
          size={props.size}
        />
      )}
    />
  );
};
