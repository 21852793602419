import React from 'react';
import { missingField } from 'app/constants';
import styledComponent from 'styled-components';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  list: {
    paddingLeft: theme.spacing(3),
    marginTop: 0,
  },
}));

export const OrderInfoPrioritiesItem = ({ ...props }) => {
  const { classes } = useStyles();

  const getUnique = (arr, index) => {
    const unique = arr
      .map(e => e[index])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e])
      .map(e => arr[e]);
    return unique;
  };

  return (
    <div id="placement-order-info-priorities">
      <RowDataDiv data-testid="order-info-order-priorities-item">
        {props.orderInformation?.orderPriorities && props.orderInformation?.orderPriorities.length > 0 ? (
          <ul className={classes.list}>
            {getUnique(props.orderInformation?.orderPriorities, 'description').map((item, index) => {
              return <li key={index}>{item.description}</li>;
            })}
          </ul>
        ) : (
          ` ${missingField}`
        )}
      </RowDataDiv>
    </div>
  );
};

const RowDataDiv = styledComponent.div`
  font-size: 14px;
  white-space: pre-wrap;
`;
