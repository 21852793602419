import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBookingInfo } from '../../../../../../../../store/redux-store/placement-summary/selectors';
import { Requirements } from 'app/models/Placement/PlacementRequirements';
import { placementSummaryActions } from '../../../../../../../../store/redux-store/placement-summary/slice';

/**
 * BOOKING CONFIRMATION REQUIREMENT COUNTS
 */
const useBookingConfirmationRequirementCounts = () => {
  const {
    confirmation: { arrived, directDeposit, wisely },
  } = useSelector(selectBookingInfo);

  const ConfirmationRequirementCounts = React.useMemo(() => {
    /** BOOKING CONFIRMATIONS REQUIREMENT COUNT CALCULATIONS */
    const counts = new Requirements();
    // Arrived
    counts.add(arrived?.type);
    // Direct Deposit
    counts.add(directDeposit?.type);
    // Wisely
    counts.add(wisely?.type);

    return counts;
  }, [arrived, directDeposit, wisely]);

  return ConfirmationRequirementCounts;
};

/**
 * BOOKING CREDENTIALING REQUIREMENT COUNTS
 */
const useBookingTransportationRequirementCounts = () => {
  const {
    credentialing: { gtgComplete, finalizedByQs },
  } = useSelector(selectBookingInfo);

  const transportationRequirementCounts = React.useMemo(() => {
    /** BOOKING CREDENTIALING REQUIREMENT COUNT CALCULATIONS */
    const counts = new Requirements();
    // GTG Complete
    counts.add(gtgComplete?.type);
    // Finalized by QS
    counts.add(finalizedByQs?.type);
    // COVID
    return counts;
  }, [gtgComplete, finalizedByQs]);

  return transportationRequirementCounts;
};

export const useBookingRequirementCounts = () => {
  const dispatch = useDispatch();
  const confirmationCounts = useBookingConfirmationRequirementCounts();
  const credentialingCounts = useBookingTransportationRequirementCounts();

  React.useEffect(() => {
    dispatch(
      placementSummaryActions.setRequirementCounts({
        bookingConfirmationReqCounts: confirmationCounts,
        bookingCredentialingReqCounts: credentialingCounts,
      }),
    );
  }, [confirmationCounts, dispatch, credentialingCounts]);

  return {
    confirmationCounts,
    credentialingCounts,
  };
};
