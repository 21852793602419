import { createSvgIcon } from 'amn-ui-core';
import React from 'react';

export const PinIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24.168" viewBox="0 0 24 24.168">
    <g id="Group_18064" data-name="Group 18064" transform="translate(-112 -48.832)">
      <g id="Group_11385" data-name="Group 11385" transform="translate(112 48.944)">
        <rect id="Rectangle_4645" data-name="Rectangle 4645" width="24" height="24" fill="none" />
      </g>
      <g id="Group_11386" data-name="Group 11386" transform="matrix(0.719, 0.695, -0.695, 0.719, 123.704, 43.976)">
        <path
          id="Path_24448"
          data-name="Path 24448"
          d="M14,4V9a4.985,4.985,0,0,0,1,3H9a4.943,4.943,0,0,0,1-3V4h4m3-2H7A1,1,0,0,0,7,4H8V9a3,3,0,0,1-3,3v2h5.97v7l1,1,1-1V14H19V12h0a3,3,0,0,1-3-3V4h1a1,1,0,0,0,0-2Z"
          fill="#0f6fb9"
        />
      </g>
      <path
        id="Path_24449"
        data-name="Path 24449"
        d="M3.41,2.86,2,4.27C5.07,7.95,18.73,21,18.73,21l1.41-1.41Z"
        transform="translate(114.93 47.083)"
        fill="#0f6fb9"
        opacity="0.001"
      />
      <g id="push_pin_black_24dp" transform="translate(112 48.888)">
        <g id="Group_11385-2" data-name="Group 11385">
          <rect id="Rectangle_4645-2" data-name="Rectangle 4645" width="24" height="24" fill="none" />
        </g>
        <g id="Group_11386-2" data-name="Group 11386" transform="matrix(0.719, 0.695, -0.695, 0.719, 11.704, -4.968)">
          <path
            id="Path_24448-2"
            data-name="Path 24448"
            d="M14,4V9a4.985,4.985,0,0,0,1,3H9a4.943,4.943,0,0,0,1-3V4h4m3-2H7A1,1,0,0,0,7,4H8V9a3,3,0,0,1-3,3v2h5.97v7l1,1,1-1V14H19V12h0a3,3,0,0,1-3-3V4h1a1,1,0,0,0,0-2Z"
            fill="#0f6fb9"
          />
        </g>
      </g>
    </g>
  </svg>,
  'PinIcon',
);

export const UnpinIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24.168" viewBox="0 0 24 24.168">
    <g id="Group_18063" data-name="Group 18063" transform="translate(-112 -48.832)">
      <g id="Group_11385" data-name="Group 11385" transform="translate(112 48.944)">
        <rect id="Rectangle_4645" data-name="Rectangle 4645" width="24" height="24" fill="none" />
      </g>
      <g id="Group_11386" data-name="Group 11386" transform="matrix(0.719, 0.695, -0.695, 0.719, 123.704, 43.976)">
        <path
          id="Path_24448"
          data-name="Path 24448"
          d="M14,4V9a4.985,4.985,0,0,0,1,3H9a4.943,4.943,0,0,0,1-3V4h4m3-2H7A1,1,0,0,0,7,4H8V9a3,3,0,0,1-3,3v2h5.97v7l1,1,1-1V14H19V12h0a3,3,0,0,1-3-3V4h1a1,1,0,0,0,0-2Z"
          fill="#0f6fb9"
        />
      </g>
      <path
        id="Path_24449"
        data-name="Path 24449"
        d="M3.41,2.86,2,4.27C5.07,7.95,18.73,21,18.73,21l1.41-1.41Z"
        transform="translate(114.93 47.083)"
        fill="#0f6fb9"
        opacity="0.001"
      />
      <g id="push_pin_black_24dp" transform="translate(112 48.888)">
        <g id="Group_11385-2" data-name="Group 11385">
          <rect id="Rectangle_4645-2" data-name="Rectangle 4645" width="24" height="24" fill="none" />
        </g>
        <g id="Group_11386-2" data-name="Group 11386" transform="matrix(0.719, 0.695, -0.695, 0.719, 11.704, -4.968)">
          <path
            id="Path_24448-2"
            data-name="Path 24448"
            d="M14,4V9a4.985,4.985,0,0,0,1,3H9a4.943,4.943,0,0,0,1-3V4h4m3-2H7A1,1,0,0,0,7,4H8V9a3,3,0,0,1-3,3v2h5.97v7l1,1,1-1V14H19V12h0a3,3,0,0,1-3-3V4h1a1,1,0,0,0,0-2Z"
            fill="#0f6fb9"
          />
        </g>
        <path
          id="Path_24449-2"
          data-name="Path 24449"
          d="M3.41,2.86,2,4.27C5.07,7.95,18.73,21,18.73,21l1.41-1.41Z"
          transform="translate(2.93 -1.86)"
          fill="#0f6fb9"
        />
      </g>
    </g>
  </svg>,
  'UnpinIcon',
);
