/* eslint-disable i18next/no-literal-string */
import { Button } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

export const ErrorPage = ({ text }: { text?: string }) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div className={'center-fullscreen'}>
      <h1>
        <div>{text ?? t('global.genericError')}</div>
        <Button
          onClick={() => {
            history.go(0);
          }}
        >
          {t('global.errorBoundary.tryAgain')}
        </Button>
      </h1>
    </div>
  );
};
