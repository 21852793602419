/* eslint-disable tss-unused-classes/unused-classes */
/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from 'tss-react/mui';
import { FilterType } from 'app/enums/Common';
import { getSortedData, getUnique } from 'app/helpers/arrayHelpers';
import { IStateCityZip } from 'app/models/Candidate/IStateCityZip';
import { defaultFilterName, SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import {
  fetchDisciplineAndSpecialty,
  getCitiesAndZipsByStates,
  getSearchFilterOptions,
} from 'app/services/SharedServices/SharedServices';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'types';
import { SessionKey, removeSessionValue, StorageType } from 'utils/customHooks/sessionStorage/sessionHelpers';
import { CustomFilter } from '../../../ComponentLibrary/Filter';
import { selectFilters, selectGridFiltersExpanded } from '../../Common/Grid/GridStateManagement/GridState.selectors';
import { getAllCitiesAndZips } from '../Candidate/FilterUtils';
import { getMultiSelectOptions } from '../helper';
import { MFSOpts as mfsOpts, OrderFilter, PositionOpts, VaccinatedOpts as vaccinatedOpts } from './FilterUtils';
import _ from 'lodash';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { Collapse, Grid, debounce } from 'amn-ui-core';
import { SearchPageIcons } from 'app/ComponentLibrary/Filter/SearchPageIcons';
import { gridStateActions } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { orderSearchFilterName } from './SearchUtils';
import { updateTreeNodes } from '@AMIEWEB/Common/TreeView/TreeViewHelpers';

const useStyles = makeStyles()({
  businessRelationship: {
    width: 170,
  },
  facilityStatus: {
    width: 145,
  },
  subSpecialty: {
    width: 140,
  },
  accountManager: {
    width: 165,
  },
  covidVaccination: {
    width: 194,
  },
  orderPriority: {
    width: 118,
  },
  fillterCollapse: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '3px',
  },
  collapse: {
    paddingTop: '4px',
  },
});

export const OrderSearchFilters = ({ defaultFilterValues }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { reset, setValue } = useFormContext();
  const gridStateFilters = useSelector(selectFilters);
  const [allSpecialities, setAllSpecialities] = useState<any[]>([]);
  const [allSubSpecialities, setAllSubSpecialities] = useState<any[]>([]);
  const allCitiesandZipsData = useRef<IStateCityZip[]>();
  const gridSpecs = useRef(
    new OrderFilter(
      {
        facilityStatusesOpts: [],
        divisionOpts: [],
        businessRelationshipsOpts: [],
        orderTypesOpts: [],
        disciplinesOpts: [],
        statesOpts: [],
        compactStatesOpts: [],
        specialtiesOpts: [],
        subSpecialtiesOpts: [],
        facilityOpts: [],
        mspClientOpts: [],
        orderReasonOpts: [],
        accountManagerOpts: [],
        orderPrioritiesOpts: [],
        shiftOpts: [],
        mfsOpts,
        positionOpts: [...PositionOpts],
        vaccinatedOpts,
        startDateRange: [],
        cityOptions: [],
        zipOptions: [],
        orderIds: [],
        regionOpts: [],
      },
      classes,
      gridStateFilters,
    ),
  );
  const [fields, setFields] = useState(gridSpecs.current.getOrderFilterSpecs());
  const filterPreferences = useSelector((state: RootState) => {
    return state.userPreferenceData.userGridPreferences.find(item => item.id === 'GlobalSearchGrid-order');
  });

  const getDisciplinesMultiSelectOptions = options => {
    const array = options?.reduce(
      (resultArray, item) => [
        ...resultArray,
        {
          value: item?.value?.slice(item?.value?.indexOf('_') + 1),
          name: item.name,
          corelation: item.value,
        },
      ],
      [],
    );
    const sortedData = getSortedData(array || [], FilterType.string);
    return sortedData;
  };

  const findspecialities = disciplines => {
    const specialtiesArray: any[] = [];
    if (disciplines?.length > 0) {
      disciplines?.forEach(item => {
        const specialties = allSpecialities.filter(
          curr => curr?.corelation?.slice(0, curr?.corelation?.indexOf('_')) === item?.value,
        );
        if (specialties) {
          specialtiesArray.push(...specialties);
        }
      });
      const sortedData = getSortedData(specialtiesArray, FilterType.string);
      const uniqueSpecialities = getUnique(
        sortedData?.filter(x => x.name),
        'name',
      );
      if (uniqueSpecialities?.length >= 1) {
        gridSpecs.current.filterSpecs.specialtiesOpts = getMultiSelectOptions(uniqueSpecialities);
        const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
        setFields(fieldsSchema);
        findSubSpecialities(specialtiesArray);
      } else {
        gridSpecs.current.filterSpecs.subSpecialtiesOpts = [];
        gridSpecs.current.filterSpecs.specialtiesOpts = getMultiSelectOptions(uniqueSpecialities);
        const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
        setFields(fieldsSchema);
      }
    } else {
      gridSpecs.current.filterSpecs.specialtiesOpts = getUnique(
        allSpecialities?.filter(x => x.name),
        'name',
      );
      const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
      setFields(fieldsSchema);
      findSubSpecialities([]);
    }
  };

  const findSubSpecialities = specialities => {
    const subSpecialtiesArray: any[] = [];
    if (specialities?.length > 0) {
      specialities?.forEach(item => {
        allSubSpecialities?.forEach(curr => {
          if (
            curr?.corelation?.substr(0, curr?.corelation?.lastIndexOf('_')) ===
            item?.corelation?.slice(item?.corelation?.indexOf('_') + 1)
          ) {
            if (curr?.name !== 'All') {
              subSpecialtiesArray.push(curr);
            }
          }
        });
      });
      const sortedData = getSortedData(subSpecialtiesArray, FilterType.string);
      const uniqueSubSpecialities = getUnique(
        sortedData?.filter(x => x.name),
        'name',
      );
      gridSpecs.current.filterSpecs.subSpecialtiesOpts = getMultiSelectOptions(uniqueSubSpecialities);
      const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
      setFields(fieldsSchema);
    } else {
      gridSpecs.current.filterSpecs.subSpecialtiesOpts = getUnique(
        allSubSpecialities?.filter(x => x.name),
        'name',
      );
    }
  };

  const fetchFilterSpecs = async key => {
    let states = [];
    getSearchFilterOptions('facilitystatuses', true).then(res => {
      gridSpecs.current.filterSpecs.facilityStatusesOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('divisions', true).then(res => {
      gridSpecs.current.filterSpecs.divisionOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('businessrelationships', true).then(res => {
      gridSpecs.current.filterSpecs.businessRelationshipsOpts = getMultiSelectOptions(res || [], {
        withoutAllOption: true,
      });
      const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('ordertypes', true).then(res => {
      gridSpecs.current.filterSpecs.orderTypesOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('disciplines', true).then(res => {
      gridSpecs.current.filterSpecs.disciplinesOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('active_discipline_specialties', true).then(res => {
      const allSpecOpts = getMultiSelectOptions(getDisciplinesMultiSelectOptions(res || []), {
        withoutAllOption: true,
      })?.filter(x => x.name);
      gridSpecs.current.filterSpecs.specialtiesOpts = allSpecOpts;
      setAllSpecialities(allSpecOpts);
      const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('specialty_subspecialties', true).then(res => {
      const allSubSpecOpts = getUnique(
        getMultiSelectOptions(getDisciplinesMultiSelectOptions(res || []), { withoutAllOption: true })?.filter(
          x => x.name,
        ),
        'name',
      );
      gridSpecs.current.filterSpecs.subSpecialtiesOpts = allSubSpecOpts;
      setAllSubSpecialities(allSubSpecOpts);
      const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('accountmanagers', true).then(res => {
      gridSpecs.current.filterSpecs.accountManagerOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('orderpriorities', true).then(res => {
      gridSpecs.current.filterSpecs.orderPrioritiesOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('shiftlist', true).then(res => {
      gridSpecs.current.filterSpecs.shiftOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('facility', true).then(res => {
      gridSpecs.current.filterSpecs.facilityOpts = getMultiSelectOptions(res || []);
      const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('mspclients', true).then(res => {
      gridSpecs.current.filterSpecs.mspClientOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('orderreason', true).then(res => {
      gridSpecs.current.filterSpecs.orderReasonOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('orderplacementregions', true).then(res => {
      gridSpecs.current.filterSpecs.regionOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
      setFields(fieldsSchema);
    });
    const [statesLookups, compactStatesOpts] = await Promise.all([
      getSearchFilterOptions('states', true),
      getSearchFilterOptions('compact_states', true),
    ]);
    states = getMultiSelectOptions(statesLookups || [], { withoutAllOption: true });
    const compactStates = compactStatesOpts?.map(item => {
      const stateAbbrs = item.name?.split(',');
      return {
        name: item?.value?.replace('?', "'")?.replace('Only ', '')?.replace('LIC', 'Licence'),
        value: states.filter(s => stateAbbrs.includes(s.value)),
        isCompactOpt: true,
      };
    });
    allCitiesandZipsData.current = (await getCitiesAndZipsByStates({
      states: [statesLookups?.[0]?.name],
      cities: undefined,
    })) as IStateCityZip[];
    const AllCitiesAndZips = getAllCitiesAndZips(allCitiesandZipsData.current);
    gridSpecs.current.filterSpecs.statesOpts = getMultiSelectOptions([...compactStates, ...statesLookups] || [], {
      withoutAllOption: true,
    });

    gridSpecs.current.filterSpecs.cityOptions = AllCitiesAndZips?.cities;
    gridSpecs.current.filterSpecs.zipOptions = AllCitiesAndZips?.zips;
    const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
    setFields(fieldsSchema);
  };
  
  const updateGridState = useCallback(
    async (selectedStates: string[], selectedCities: string[], gridStateFilters: any) => {
      if (!gridStateFilters || !allCitiesandZipsData.current) return;
      let filteredZips: IStateCityZip[];
      let filteredCities: IStateCityZip[];
      if (!selectedStates.length) filteredCities = allCitiesandZipsData.current;
      else { 
        filteredCities = await getCitiesAndZipsByStates({
            states: selectedStates,
            cities: [],
          }) as IStateCityZip[];
      }
 
      if (!selectedCities.length) filteredZips = filteredCities;
      else {
        filteredZips = await getCitiesAndZipsByStates({
            states: selectedStates,
            cities: selectedCities,
          }) as IStateCityZip[];
      }
 
      gridSpecs.current.filterSpecs.cityOptions = getAllCitiesAndZips(filteredCities).cities;
      gridSpecs.current.filterSpecs.zipOptions = getAllCitiesAndZips(filteredZips).zips;
      gridSpecs.current.gridStateFilters = gridStateFilters;
      const fieldsSchema = gridSpecs.current.getOrderFilterSpecs();
      setFields(fieldsSchema);
    },
    [],
  );

  const resetFilter = () => {
    const foundDefaultIndex = (filterPreferences.value?.filterAttributes?.filterSet || [])?.find(
      item => item.name === defaultFilterName || item.isDefault,
    );
    const filterPrefClone = _.cloneDeep(filterPreferences);

    if (!foundDefaultIndex) {
      filterPrefClone.value.selectedFilter = null;
    } else {
      // Default filter found, select it
      filterPrefClone.value.selectedFilter = foundDefaultIndex;
    }
    removeSessionValue(SessionKey['GlobalSearchGrid-order'], StorageType.sessionStorage);
    dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(filterPrefClone));
    setValue(orderSearchFilterName.orderIds, null);
    setValue(orderSearchFilterName.region, null);
    reset(!isNullOrUndefined(foundDefaultIndex) ? Object.fromEntries(foundDefaultIndex?.value) : null);
  };

  const filtersExpanded = useSelector(selectGridFiltersExpanded);
  const handleChange = () => {
    dispatch(gridStateActions.setFiltersExpanded(!filtersExpanded));
  };

  useEffect(() => {
    trackPromise(fetchFilterSpecs(''), 'gloabl-search-call');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allSpecialities.length > 0) {
      findspecialities(gridStateFilters?.filters?.disciplineIds);
    }
  }, [gridStateFilters?.filters?.disciplineIds, allSpecialities]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (gridStateFilters?.filters?.specialtyIds?.length > 0) {
      findSubSpecialities(gridStateFilters?.filters?.specialtyIds);
    } else {
      findspecialities(gridStateFilters?.filters?.disciplineIds);
    }
  }, [gridStateFilters?.filters?.specialtyIds]);

  const getDisciplineAndSpecialty = async savedDisciplineIds => {
    let preferenceValues = null;
    let newSkillSetFilter = [];

    const abortController = new AbortController();
    const disciplineAndSpecialty = (await fetchDisciplineAndSpecialty('', abortController.signal, true)) as any;

    if (disciplineAndSpecialty?.treeStructure?.length > 0) {
      const skillSetTree = disciplineAndSpecialty?.treeStructure;
      const savedSpecialtyIds = gridStateFilters?.filters?.specialtyIds as any[];
      const savedSubSpecialtyIds = gridStateFilters?.filters?.subSpecialtyIds as any[];

      //If Specialty array is not empty, then pick only the valid and active values of Discipline and Specialty combination.
      if (savedSpecialtyIds?.length > 0) {
        for (const disciplineId of savedDisciplineIds) {
          let specialtyChildren = [];
          let node = skillSetTree?.find(t => t.value == disciplineId?.value);
          if (node) {
            for (const specialtyId of savedSpecialtyIds) {
              const specialtyNode = node?.children?.find(
                child => child?.value == `${node?.value}-${specialtyId?.value}`,
              );

              //If Specialty is not present under Discipline, then don't pick anything at all.
              if (specialtyNode) {
                if (savedSubSpecialtyIds?.length > 0) {
                  let subSpecialtyChildren = [];

                  for (const subSpecialtyId of savedSubSpecialtyIds) {
                    const subSpecialtyNode = specialtyNode?.children?.find(
                      child => child?.value == `${node?.value}-${specialtyId?.value}-${subSpecialtyId?.value}`,
                    );

                    if (subSpecialtyNode) {
                      subSpecialtyNode.userChecked = true;
                      subSpecialtyChildren = [...subSpecialtyChildren, subSpecialtyNode];
                    }
                  }
                  if (subSpecialtyChildren?.length > 0) {
                    specialtyNode.userChecked = true;
                    specialtyChildren = [...specialtyChildren, { ...specialtyNode, children: subSpecialtyChildren }];
                  }
                } else {
                  specialtyNode.userChecked = true;
                  specialtyChildren = [...specialtyChildren, { ...specialtyNode, children: [] }];
                }
              }
            }
          }

          if (specialtyChildren?.length > 0) {
            let disciplineNode = {
              name: node?.name,
              value: node?.value,
              userChecked: true,
              children: specialtyChildren,
            };
            newSkillSetFilter = [...newSkillSetFilter, disciplineNode];
          }
        }
      }
      //If Specialty array is empty, then pick only the valid and active values of Discipline.
      else {
        for (const disciplineId of savedDisciplineIds) {
          let node = skillSetTree?.find(t => t.value == disciplineId?.value);
          if (node) {
            node.userChecked = true;
            newSkillSetFilter = [...newSkillSetFilter, { ...node, children: [] }];
          }
        }
      }
    }

    preferenceValues = _.cloneDeep(filterPreferences)?.value;

    const filterSet = preferenceValues?.filterAttributes?.filterSet;
    const selectedFilter = preferenceValues?.selectedFilter;
    const selectedFilterSet = filterSet.find(filter => filter.name === selectedFilter?.name).value;

    if (newSkillSetFilter?.length > 0) {
      newSkillSetFilter[0].filterName = 'skillsetFilter';
      const skillSets = {
        allowableMismatch: false,
        isVerifiedOnly: false,
        operation: 'Or',
        skillsets: updateTreeNodes(newSkillSetFilter),
      };

      selectedFilterSet.find(arr => arr[0] === 'skillsetFilter')[1] = skillSets;
    }
    selectedFilterSet.find(arr => arr[0] === 'disciplineIds')[1] = [];
    selectedFilterSet.find(arr => arr[0] === 'specialtyIds')[1] = [];
    selectedFilterSet.find(arr => arr[0] === 'subSpecialtyIds')[1] = [];

    let pref = preferenceValues?.filterAttributes?.filterSet?.find(filter => filter.name === selectedFilter?.name);
    if (pref?.value) {
      pref = selectedFilterSet;
    }

    preferenceValues.selectedFilter.value = selectedFilterSet;

    return preferenceValues;
  };

  useEffect(() => {
    const fetchData = async () => {
      let preferenceValues = null;

      if (gridStateFilters && gridStateFilters.filters) {
        const savedDisciplineIds = gridStateFilters.filters.disciplineIds as any[];

        if (savedDisciplineIds?.length > 0) {
          preferenceValues = await getDisciplineAndSpecialty(savedDisciplineIds);
        }
      }

      if (preferenceValues !== null && preferenceValues !== undefined && Object.keys(preferenceValues).length !== 0) {
        dispatch(
          userDevicePreferenceActions.saveUserGridPreferenceAction({
            id: 'GlobalSearchGrid-order',
            value: preferenceValues,
          }),
        );
      }
    };

    fetchData();
  }, [gridStateFilters]);

  useEffect(() => {
    if (!gridStateFilters || !allCitiesandZipsData.current) return;
    const selectedStates = !(gridStateFilters?.filters?.states as any[])?.length
      ? []
      : (gridStateFilters?.filters?.states as any[]).reduce(
          (result, item) => (!item?.isCompactOpt ? [...result, item] : result),
          [],
        );

    const selectedCities = (
      !(gridStateFilters?.filters?.city as any[])?.length ? [] : gridStateFilters?.filters?.city
    ) as any[];
    updateGridState(
      selectedStates.map(x => x.name),
      selectedCities.map(x => x.name),
      gridStateFilters,
    );
  }, [gridStateFilters, updateGridState]);

  return (
    <Grid container wrap="nowrap" display="flex">
      <Grid item flex="1 1 auto">
        <Collapse timeout={200} in={filtersExpanded} collapsedSize={45} className={classes.collapse}>
          <CustomFilter
            fields={fields}
            justifyContent={'flex-start'}
            version2
            resetBtn
            analytics={{
              caller: t('search.globalSearch.globalSearchFilter'),
              onApply: true,
              properties: {
                category: SearchType.order,
              },
            }}
          />
        </Collapse>
      </Grid>
      <Grid item flex="0 0 auto" className={classes.fillterCollapse}>
        <SearchPageIcons
          resetlbutton
          funnelButton
          checked={filtersExpanded}
          onResetForm={() => resetFilter()}
          onAdvancedFilterClick={() => handleChange()}
        />
      </Grid>
    </Grid>
  );
};
