import React, { createRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { gridStateActions } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import {
  defaultCoverageFilterValues,
  getCoverageFilterSpecs,
  getDefaultCoverageFilterValues,
} from './Tabs/Request/filterUtils';
import { CustomFilter } from 'app/ComponentLibrary/Filter/CustomFilter';

export const CoverageFilter = () => {
  const {
    reset,
    formState: { dirtyFields },
  } = useFormContext();
  const { t } = useTranslation();
  const hiddenSubmitButton = createRef<HTMLButtonElement>();
  const { userInfo } = useSelector(selectUser);
  const fields = getCoverageFilterSpecs(t);
  const dispatch = useDispatch();

  const resetFilter = () => {
    const defaultFilterValues = getDefaultCoverageFilterValues(userInfo);
    reset({ ...defaultFilterValues });
    dispatch(
      gridStateActions.setFilterAttributes({
        filterObject: Object.entries(defaultFilterValues)
      }),
    );
  };

  return (
    <React.Fragment>
      <CustomFilter
        fields={fields}
        onResetForm={() => resetFilter()}
        justifyContent={'flex-start'}
        // false Prop added to instantiate dirtyFields from react-hook-form: formState, without consuming
        falseProp={dirtyFields}
        version2
        analytics={{
          caller: 'Coverage WorkDesk',
          onApply: true,
          properties: {
            category: 'Coverage',
          },
        }}
      />
      <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
    </React.Fragment>
  );
};
