import { missingField } from 'app/constants';
import { GetMomentOfDate } from './moment';

export const getDateValue = (offsetDays, addEndValue = false, value?) => {
  let date;
  if (value) date = new Date(value);
  else date = new Date();
  if (offsetDays) date.setDate(date.getDate() + offsetDays);
  if (addEndValue) date.setHours(23, 59, 59, 999);
  else date.setHours(0, 0, 0, 0);
  return date;
};

export const getFirstOfMonth = (nextMonth = false) => {
  // January - December : 0 - 11
  let date = new Date();
  date = new Date(date.getFullYear(), date.getMonth() + (!nextMonth ? 0 : 1));
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getLastOfMonth = (nextMonth = false) => {
  // January - December : 0 - 11
  let date = new Date();
  date = new Date(date.getFullYear(), date.getMonth() + (!nextMonth ? 1 : 2), 0);
  date.setHours(23, 59, 59, 999);
  return date;
};

export const getAmnStartOfWeek = () => {
  const today = new Date().getDay();
  const offset = today !== 6 ? -(today + 1) : 0;
  return getDateValue(offset);
};

export const getAmnStartOfMonth = () => {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  return firstDay;
};

export const getAllRangeStart = () => {
  const firstDay = new Date().setFullYear(
    new Date().getFullYear() - 10,
    new Date().getMonth(),
    new Date().getDate() + 1,
  );
  return firstDay;
};

export const getAllRangeEnd = () => {
  const firstDay = new Date().setFullYear(new Date().getFullYear() + 10);
  return firstDay;
};

export const getAmnEndOfMonth = () => {
  const date = new Date();
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return lastDay;
};

export const getAmnEndOfWeek = () => {
  const today = new Date().getDay();
  const offset = today !== 6 ? -(today - 5) : 0;
  return getDateValue(offset, true);
};

export const getDateRangeLabel = (
  startDate: string | Date,
  endDate: string | Date,
  startDefault = 'Unknown',
  endDefault = 'Unknown',
  nullValue = missingField,
) => {
  const startMoment = GetMomentOfDate(startDate);
  const endMoment = GetMomentOfDate(endDate);
  return startMoment.isValid() && endMoment.isValid()
    ? `${startMoment.format('MM/DD/YYYY')} - ${endMoment.format('MM/DD/YYYY')}`
    : startMoment.isValid() && !endMoment.isValid()
    ? `${startMoment.format('MM/DD/YYYY')} - ${endDefault}`
    : !startMoment.isValid() && endMoment.isValid()
    ? `${startDefault} - ${endMoment.format('MM/DD/YYYY')}`
    : nullValue;
};

export const getDateRangeLabelWithNull = (
  startDate: string | Date,
  endDate: string | Date,
  startDefault = 'Unknown',
  endDefault = 'Unknown',
  nullValue = missingField,
) => {
  const startMoment = GetMomentOfDate(startDate);
  const endMoment = GetMomentOfDate(endDate);
  if (startDate && endDate) {
    return startMoment.isValid() && endMoment.isValid()
      ? `${startMoment.format('MM/DD/YYYY')} - ${endMoment.format('MM/DD/YYYY')}`
      : startMoment.isValid() && !endMoment.isValid()
      ? `${startMoment.format('MM/DD/YYYY')} - ${endDefault}`
      : !startMoment.isValid() && endMoment.isValid()
      ? `${startDefault} - ${endMoment.format('MM/DD/YYYY')}`
      : nullValue;
  } else if (startDate) {
    return startMoment.isValid()
      ? `${startMoment.format('MM/DD/YYYY')}`
      : startMoment.isValid()
      ? `${startMoment.format('MM/DD/YYYY')}`
      : !startMoment.isValid()
      ? `${startDefault}`
      : nullValue;
  } else if (endDate) {
    return endMoment.isValid()
      ? `${endMoment.format('MM/DD/YYYY')}`
      : !endMoment.isValid()
      ? `${endDefault}`
      : endMoment.isValid()
      ? `${endMoment.format('MM/DD/YYYY')}`
      : nullValue;
  }
};
