/* eslint-disable tss-unused-classes/unused-classes */
/* eslint-disable react-hooks/exhaustive-deps */
import React  from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { LocalizationProvider, TextField, TimePicker } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { assignCoverageVar, checkTimeValidation } from './assignHelper';
import { IRequestCoverage } from 'app/models/UserCoverage/UserCoverage';
import moment from 'moment';

export const timePickerStyles = makeStyles()((theme, props) => ({
  inputs: {
    width: '100%',
    overflow: 'auto',
    '&:hover': {
      '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
        color: theme.palette.primary.main,
      },
    },
  },
  picker: {
    backgroundColor: 'transparent !important',
    border: 'none',
    width: '115px',
    '& *': {
      backgroundColor: 'transparent !important',
    },
  },
}));

export const SelectTimePicker = ({
  name,
  label,
  isCallingAPI,
  isAllDay,
  minTime,
  maxTime,
  formMethods,
}: {
  name: string;
  label: string;
  isCallingAPI: boolean;
  isAllDay: boolean;
  minTime?: any;
  maxTime?: any;
  formMethods: UseFormMethods<IRequestCoverage>;
}) => {
  const { control, errors, getValues, setValue, watch } = formMethods;
  const { classes } = timePickerStyles();
  const startTime = watch(assignCoverageVar.startTime);
  const startDate = watch(assignCoverageVar.startDate);
  const endDate = watch(assignCoverageVar.endDate);


  const isTimeValid = value => {
    if (!isAllDay) {
      const date1 = moment(startDate, 'M/D/YYYY');
      const date2 = moment(endDate, 'M/D/YYYY');
      const isSameDay = date1.isSame(date2, 'day');
      return name === assignCoverageVar.endTime
        ? checkTimeValidation(value, isSameDay, true, startTime)
        : checkTimeValidation(value, isSameDay);
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={{ validate: isTimeValid }}
      render={({ ref, onChange, ...rest }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            label={label}
            value={getValues(name)}
            views={['hours', 'minutes']}
            className={classes.picker}
            minTime={minTime ? minTime : ''}
            disabled={isCallingAPI}
            maxTime={maxTime ? maxTime : ''}
            onChange={time => {
              setValue(name, time);
              onChange(time);
            }}
            renderInput={({ inputProps, ...restParams }) => (
              <>
                <TextField
                  {...restParams}
                  fullWidth
                  id="endTime"
                  className={classes.inputs}
                  aria-label="change time"
                  variant="filled"
                  error={errors[name]}
                  helperText={errors[name]?.message ?? ''}
                  inputProps={{
                    ...inputProps,
                    autoComplete: 'off',
                    placeholder: 'hh:mm am|pm',
                  }}
                />
              </>
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
};
