import React from 'react';
import { DatePicker, LocalizationProvider, TextField } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const useStyles = makeStyles<{ height: any }>()((theme, { height }) => ({
  root: {
    width: '160px',
  },
  adornedEnd: {
    paddingRight: '12px',
    height: height ? `${height}px` : 'unset',
  },
}));

export const CustomCalendar = props => {
  const { hasHelperText = true } = props;
  const { classes } = useStyles({ height: props.height });
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        inputFormat="MM/dd/yyyy"
        className={props.fullwidth ? null : classes.root}
        renderInput={p => (
          <TextField
            {...p}
            aria-label="change-date"
            variant={props?.textFieldVariant || "outlined"}
            size="small"
            error={props.errorObject ? true : false}
            helperText={hasHelperText ? (props.errorObject ? props.errorObject.message : ' ') : undefined}
            placeholder={`MM/DD/YYYY`}
            InputProps={{
              ...p.InputProps,
              classes: {
                adornedEnd: classes.adornedEnd,
              },
            }}
            sx={{ width: '100%' }}
          />
        )}
        {...props}
      />
    </LocalizationProvider>
  );
};
