import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './initialState';

const selectDomain = (store: RootState) => store.facilityPreferences || initialState;

export const selectUpdateStatus = createSelector([selectDomain], preferences => preferences.updateInprogress);
export const selectFacilityTemplateStatus = createSelector(
  [selectDomain],
  preferences => preferences.templateUpdateInProgress,
);

export const selectConfirmationTemplates = createSelector(
  [selectDomain],
  preferences => preferences.confirmationTemplates,
);

export const selectFacilityTemplate = createSelector([selectDomain], preferences => preferences.facilityTemplate);

export const selectFacilityPreferences = createSelector([selectDomain], preferences => preferences);

export const selectFacilitytemplate = createSelector([selectDomain], preferences => preferences.bookingTemplate);

export const selectConfirmationDialog = createSelector([selectDomain], preferences => preferences.confirmationDialog);

export const selectTabValue = createSelector([selectDomain], preferences => preferences.tabValue);

export const selectPreferenceChanged = createSelector([selectDomain], preferences => preferences.isPreferenceChanged);

export const selectBookingFacilityDirty = createSelector([selectDomain],
                                          preferences => preferences.bookingFacilityDirty);

export const selectBookingFacilityReset = createSelector([selectDomain],
                                            preferences => preferences.bookingFacilityReset);

export const selectMiscellaneousDate = createSelector([selectDomain],data => data.miscellaneous);

export const selectExistingUnitsForFacility = createSelector([selectDomain], preferences => preferences.existingUnits);

export const selectUpdatePreferenceDialog = createSelector([selectDomain], data => data.updateDialogOpen);

export const selectPreferenceSelection = createSelector([selectDomain], data => data.preferenceSelection);
