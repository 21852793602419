import { defaultDateRange } from '@AMIEWEB/Common/Textfield/Defaults';
import { GridCellParams } from '@mui/x-data-grid-pro';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRangePicker as DateRangeNew } from 'app/ComponentLibrary/DateRangeNew';
import { materialUiXGrid } from 'amn-ui-core';

export const RenderEditDateCell = (params: GridCellParams) => {
  return <EditGritDateCell params={params} />;
};

const EditGritDateCell = ({ params }: { params: GridCellParams }) => {
  const date = new Date();
  const api = materialUiXGrid.useGridApiContext();
  const [selected, setSelected] = useState<string>(params.value);

  useEffect(() => {
    setSelected(params.value);
  }, [params]);

  const handleEditChange = async event => {
    const editValue = event.startDate ? event.startDate : null;
    await api?.current.setEditCellValue({ id: params.id, field: params.field, value: editValue });
    setSelected(editValue);
  };

  return (
    <div style={{ width: '100%' }}>
      <DateRangeNew
        placeholder="MM/DD/YYYY"
        onChange={event => {
          handleEditChange(event);
        }}
        value={selected}
        variant="standard"
        size="inline"
        actionBar={false}
        single
        clickAway
        clear
        trailingIconShow={true}
        disableUnderline={true}
        showIcon={true}
        isDateIconReq={true}
        helperBackgroundColor={'transparent'}
        initialDateRange={
          moment(selected).format('MM/DD/YYYY') !== 'Invalid date'
            ? {
                startDate: new Date(selected),
                endDate: new Date(selected),
                label: moment(selected).format('MM/DD/YYYY'),
                useLabelAsValue: false,
              }
            : {
                startDate: new Date(),
                endDate: new Date(),
                label: '',
                useLabelAsValue: true,
              }
        }
        definedRanges={defaultDateRange(date)}
      />
    </div>
  );
};
