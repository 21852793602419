import { FilterCompTypes, FilterLayoutTypes } from 'app/ComponentLibrary/Filter/utils';
import { addDays, startOfMonth, endOfMonth } from 'date-fns';

export enum amPlacementsFilterName {
  placementAccountManager = 'placementAccountManager',
  candidate = 'candidate',
  placementRecruiter = 'placementRecruiter',
  mspClient = 'mspClient',
  contractAffiliate = 'contractAffiliate',
  facility = 'facility',
  city = 'city',
  state = 'state',
  discipline = 'discipline',
  specialty = 'specialty',
  staffingVendor = 'staffingVendor',
  technologyVendor = 'technologyVendor',
  orderPriorities = 'orderPriorities',
  activeCueDateRange = 'activeCueDateRange',
  allActiveCues = 'allActiveCues',
}

export const defaultFilterValues = {
  [amPlacementsFilterName.placementAccountManager]: [],
  [amPlacementsFilterName.candidate]: [],
  [amPlacementsFilterName.placementRecruiter]: [],
  [amPlacementsFilterName.mspClient]: [],
  [amPlacementsFilterName.contractAffiliate]: [],
  [amPlacementsFilterName.facility]: [],
  [amPlacementsFilterName.city]: [],
  [amPlacementsFilterName.state]: [],
  [amPlacementsFilterName.discipline]: [],
  [amPlacementsFilterName.specialty]: [],
  [amPlacementsFilterName.staffingVendor]: [],
  [amPlacementsFilterName.technologyVendor]: [],
  [amPlacementsFilterName.orderPriorities]: [],
  [amPlacementsFilterName.activeCueDateRange]: null,
  [amPlacementsFilterName.allActiveCues]: false,
};

export const getFilterSpecs = (filterSpecs, accountManagers, t, styleClasses) => [
  {
    filterName: t('home.amWorkDesk.placementFilter.accountManager'),
    name: amPlacementsFilterName.placementAccountManager,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    options: accountManagers,
    isMultiSelect: true,
    fallbackPlacements:['top-start', 'bottom-start', 'right'],
  },
  {
    filterName: t('home.amWorkDesk.placementFilter.candidate'),
    name: amPlacementsFilterName.candidate,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.candidateOpts,
    selectAllValue: 'All',
  },
  {
    filterName: t('home.amWorkDesk.placementFilter.placementRecruiter'),
    name: amPlacementsFilterName.placementRecruiter,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.recruiterOpts,
    selectAllValue: 'All',
  },
  {
    filterName: t('home.amWorkDesk.placementFilter.mspClient'),
    name: amPlacementsFilterName.mspClient,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.mspClientOpts,
    selectAllValue: 'All',
  },
  {
    filterName: t('home.amWorkDesk.placementFilter.contractAffiliate'),
    name: amPlacementsFilterName.contractAffiliate,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.affiliateOpts,
    selectAllValue: 'All',
  },
  {
    filterName: t('home.amWorkDesk.placementFilter.facility'),
    name: amPlacementsFilterName.facility,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.facilityOpts,
    selectAllValue: 'All',
  },
  {
    filterName: t('home.amWorkDesk.placementFilter.city'),
    name: amPlacementsFilterName.city,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.cityOpts,
    selectAllValue: 'All',
  },
  {
    filterName: t('home.amWorkDesk.placementFilter.state'),
    name: amPlacementsFilterName.state,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.stateOpts,
    selectAllValue: 'All',
  },
  {
    filterName: t('home.amWorkDesk.placementFilter.discipline'),
    name: amPlacementsFilterName.discipline,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.disciplineOpts,
    selectAllValue: 'All',
  },
  {
    filterName: t('home.amWorkDesk.placementFilter.specialty'),
    name: amPlacementsFilterName.specialty,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.specialtyOpts,
    selectAllValue: 'All',
  },
  {
    filterName: t('home.amWorkDesk.placementFilter.staffingVendor'),
    name: amPlacementsFilterName.staffingVendor,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.staffingOpts,
    selectAllValue: 'All',
  },
  {
    filterName: t('home.amWorkDesk.placementFilter.technologyVendor'),
    name: amPlacementsFilterName.technologyVendor,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.technologyOpts,
    selectAllValue: 'All',
  },
  {
    filterName: t('home.amWorkDesk.placementFilter.orderPriorities'),
    name: amPlacementsFilterName.orderPriorities,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    options: filterSpecs.orderPriorityOpts,
    selectAllValue: 'All',
  },
];

export const getAdvancedFilterSpecs = (
  t,
  inputDate,
): {
  options?: any;
  name: string;
  type: FilterCompTypes;
  componentType?: FilterLayoutTypes;
  filterName: string;
  titleToggle?: boolean;
  titleToggleValue?: any;
  onSelectFieldChange: (...pros) => void;
  definedRanges: any[];
}[] => [
  {
    name: amPlacementsFilterName.activeCueDateRange,
    type: FilterCompTypes.DATERANGE,
    componentType: FilterLayoutTypes.SINGLE,
    filterName: t('home.amWorkDesk.placementFilter.activeCueDateRange'),
    titleToggle: true,
    onSelectFieldChange: value => {
      if (value === true) return { startDate: null, endDate: null, label: 'All Active Cues', useLabelAsValue: true };
      else if (!!value) return value;
      else return null;
    },
    definedRanges: [
      {
        label: 'Today',
        startDate: inputDate,
        endDate: inputDate,
        useLabelAsValue: false,
      },
      {
        label: 'Last 2 Days',
        startDate: addDays(inputDate, -2),
        endDate: addDays(inputDate, -1),
        useLabelAsValue: false,
      },
      {
        label: 'Last 7 Days',
        startDate: addDays(inputDate, -6),
        endDate: inputDate,
        useLabelAsValue: false,
      },
      {
        label: 'This Month',
        startDate: startOfMonth(inputDate),
        endDate: endOfMonth(inputDate),
        useLabelAsValue: false,
      },
    ],
  },
];
export const isOneAccountManagerSelected = (appliedFilterValues:any) => {
  return (!appliedFilterValues ||
     appliedFilterValues?.filters?.[amPlacementsFilterName.placementAccountManager]?.length > 0)
}
