import { CustomGrid } from 'app/components/Common/Grid/Grid';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const missingField = '--';

export const ActivePlacementColumn = t => [
  {
    headerName: t('PID'),
    field: 'placementId',
    width: 90,
  },
  {
    headerName: t('Status'),
    field: 'status',
    width: 180,
  },
  {
    headerName: t('Brand'),
    field: 'brand',
    width: 160,
  },
  { headerName: t('Assigned CA'), field: 'assignedCA', width: 130 },
  {
    headerName: t('Days to Start'),
    field: 'daysToStart',
    width: 145,
  },
  { headerName: t('Facility'), field: 'facility' },
];

export const CustomGridColumn = t => [
  {
    value: t('PID'),
    title: 'placementId',
    sortable: true,
  },

  {
    value: t('Status'),
    title: 'status',
    sortable: true,
  },
  {
    value: t('Brand'),
    title: 'brand',
    sortable: true,
  },
  {
    value: t('Assigned CA'),
    title: 'assignedCA',
    sortable: true,
  },
  {
    value: t('Days to Start'),
    title: 'daysToStart',
    sortable: true,
    isNumber: true,
  },
  {
    value: t('Facility'),
    title: 'facility',
    sortable: true,
  },
];

const tableRows = [
  {
    id: 1,
    placementId: '1',
    status: '1',
    brand: 'amn',
    assignedCA: '1',
    daysToStart: '1',
    facility: '1',
  },
];

export const PlacementDisplay = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <CustomGrid
        columns={CustomGridColumn(t)}
        rows={tableRows}
        component={'Placements'}
        showCount={false}
        disableGutters
        showPagination={false}
        fullWidth={true}
        enableStateManagement
      />
      {/* <XGrid
        rows={tableRows}
        columns={ActivePlacementColumn(t)}
        disableSelectionOnClick
        pagination={false}
        hideFooter
      /> */}
    </React.Fragment>
  );
};
