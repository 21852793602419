import { Checkbox, FormControlLabel } from 'amn-ui-core';
import React from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export const CustomCheckbox: React.FC<{
  label: string; 
  value: boolean; 
  onChange: (val: boolean) => void, 
  autoFocus?: boolean,
  size?: "small" | "medium",
  disabled?: boolean;
}> = ({ 
  label, 
  value: checked, 
  onChange, 
  autoFocus = false,
  size = "small",
  ...props 
}) => {
  const ref = React.useRef<any>();

  React.useEffect(() => {
    if(autoFocus) 
      ref.current?.focus();
  }, [autoFocus]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon />}
          onChange={(e, newValue) => 
            onChange(newValue)
          }
          checked={checked}
          disableFocusRipple
          disableTouchRipple
          style={{ background: 'none' }}
          size={size}
        />
      }
      label={label}
      data-testid="clickable-item"
      {...props}
    />
  );
}