import { missingField } from 'app/constants';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import { RequirementsRow } from '../../TableComponents/TableComponents';
import { selectServiceFailures } from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { getRequirementCheck } from '../../../helper';
import { TableSection } from '../../../customComponents/TableSection';
import { GetMomentOfDate } from 'utils/dates/moment';
import { Tooltip, Avatar } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { TFunction } from 'i18next';
import { ICredentials } from 'app/models/Credentialing/Credentials';
import styledComponent from 'styled-components';
import { CredentialingListedCertifications } from '../../../../PlacementSummaryTab/RequirementsAndQualifications/CredentialingListedCertifications';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import _ from 'lodash';
import { requirementType } from 'app/models/Placement/PlacementSummary';
import { IOperation } from 'app/models/Placement/PlacementDetails';
import { WhiteTooltip } from '@AMIEWEB/Common/Tooltips/WhiteTooltip';

const useStyles = makeStyles()(theme => ({
  tooltipArrow: {
    color: theme.palette.common.white,
    '&::before': {
      border: `1px solid ${theme.palette.system.davyGray}`,
    },
  },
  tooltipBackground: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.system.davyGray,
    border: `1px solid ${theme.palette.system.davyGray}`,
    fontSize: '12px',
  },
  expDateColor: {
    color: '#AB0012',
  },
  blue: {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: theme.palette.system.navyBlue,
    width: '22px',
    height: '22px',
    fontSize: '14px',
    display: 'inline-flex',
    margin: '0 0.4rem 0 0.4rem',
  },
  green: {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: theme.palette.framework.system.green,
    width: '22px',
    height: '22px',
    fontSize: '14px',
    display: 'inline-flex',
    margin: '0 0.4rem 0 0.4rem',
  },
  documentIcon: {
    fill: `${theme.palette.system.lightBlue} !important`,
    fontSize: '18px',
    marginTop: 2,
    marginLeft: 0,
    position: 'relative',
    top: '-1px',
  },
  certificationDetails: {
    position: 'relative',
    top: '-2px',
  },
  clickable: {
    cursor: 'pointer',
    color: theme.palette.system.navyBlue,
    fontWeight: 450,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  ListedCertificationsRisk: {
    position: 'relative',
    margin: '0.2rem 0.5rem',
  },
  listedCertifiSpan: {
    'font-size': '14px',
    'font-weight': 'normal',
  },
  container: {
    width: '220px',
    display: 'contents',
  },
  documentIconGrey: {
    fill: `${theme.palette.system.neutralGray} !important`,
    marginTop: 1,
    marginLeft: 8,
  },
}));

export const CertificationsRow = ({
  filterKey,
  requirementFilter,
  req: { order: orderReqItem, candidate: candidateReqItem },
}) => {
  const { t } = useTranslation();
  const { groupedSkillsetRequirement: serviceFailed } = useSelector(selectServiceFailures);
  const { filter } = useRequirementFilter(filterKey, requirementFilter);
  const [display, setDisplay] = useState(true);
  const { placementId } = useContext(AddOnContext);
  const { promiseInProgress } = usePromiseTracker({
    area: 'placement-submission-grouped-skillset-requirement',
    delay: 0,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const state =
      /**True if filter is not applied */
      !filter.length
        ? true
        : /** Check if risk is applied */
          ((candidateReqItem?.data.isCandidateCertsExpiring || candidateReqItem?.data.isCredentialCertsExpiring) &&
            filter.includes(requirementType.risk)) ||
          /** pass / fail is satisfied by candidate certificates in orderRequirement column and applied in filter */
          (filter || []).some(item =>
            orderReqItem.data.operation === '|'
              ? item === orderReqItem.type
              : orderReqItem.data.certifications.some(cert => cert.type === item),
          );

    setDisplay(state);
  }, [candidateReqItem, filter, orderReqItem]);

  return display ? (
    <RequirementsRow
      id="placement-submission-order-certifications"
      title={t('placement.profile.summary.submission.order.certification.title')}
      displayProp="flex"
      requirementCell={
        orderReqItem && orderReqItem?.data?.certifications?.length > 0 ? (
          <RowKey
            certifications={orderReqItem?.data?.certifications || []}
            requirementFilter={filter}
            t={t}
            orderReqItem={orderReqItem}
          />
        ) : (
          missingField
        )
      }
      candidateCell={
        <RowValue
          candidateCertifications={candidateReqItem?.data.candidateCertResponse || []}
          credentialCertifications={candidateReqItem?.data.candidateCredCertListResponse || []}
          isCredentialCertsExpiring={candidateReqItem?.data.isCredentialCertsExpiring}
          t={t}
        />
      }
      loading={promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() => dispatch(placementSummaryActions.getGroupedSkillSetRequirements({ placementId }))}
    />
  ) : null;
};

const RowKey = ({ certifications, requirementFilter, t, orderReqItem }) => {
  const { classes } = useStyles();
  const [data, setData] = useState<any[]>([]);
  const {
    data: { operation },
  } = orderReqItem;
  /** US #148489: Accounts for filtering logic */
  useEffect(() => {
    const clonedFilter = _.cloneDeep(requirementFilter);
    _.remove(clonedFilter, item => item === requirementType.risk);
    setData(
      !clonedFilter.length || operation === '|'
        ? [...certifications]
        : certifications.filter(item => clonedFilter.includes(item.type)),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certifications, requirementFilter]);

  return (
    <div className={classes.container}>
      <OperationRequirment requirement={orderReqItem?.type} operation={orderReqItem?.data?.operation} t={t} />
      <TableSection
        items={data.map(certificationItem => {
          return {
            icon: certificationItem && certificationItem.type ? getRequirementCheck(certificationItem.type) : null,
            primaryContent: (
              <>
                <div className={classes.certificationDetails}>
                  <span>{`${certificationItem.abbreviation ?? missingField}`}</span>
                  <span>
                    <WhiteTooltip
                      title={
                        certificationItem.isRequired ? t('placement.common.required') : t('placement.common.preferred')
                      }
                    >
                      <Avatar className={` ${certificationItem.isRequired ? classes.blue : classes.green}`}>
                        {certificationItem.isRequired ? 'R' : 'P'}
                      </Avatar>
                    </WhiteTooltip>
                  </span>
                  <span>
                    {certificationItem.documentAttachmentRequired ? (
                      <WhiteTooltip title={t('placement.common.documentPresent')}>
                        <DescriptionOutlinedIcon className={classes.documentIcon} />
                      </WhiteTooltip>
                    ) : null}
                  </span>
                </div>
              </>
            ),
          };
        })}
      />
    </div>
  );
};

const Credentials = ({
  t,
  certifications,
  onCredentialCertificationsToggle,
  isCredentialCertsExpiring = false,
  id,
}: {
  t: TFunction;
  certifications: ICredentials[];
  onCredentialCertificationsToggle: (count) => () => void;
  isCredentialCertsExpiring: boolean;
  id?: string;
}) => {
  const { classes, cx } = useStyles();

  return (
    <ColumnDiv id={id}>
      <RowDiv>
        <RowKeyX>
          <>
            <span className={isCredentialCertsExpiring ? classes.ListedCertificationsRisk : ''}>
              {isCredentialCertsExpiring ? getRequirementCheck(3) : <></>}
            </span>
            <span className={classes.listedCertifiSpan}>
              {t('placement.profile.summary.submission.order.certification.line2')}
              <span
                className={cx({
                  [classes.clickable]: certifications?.length > 0,
                })}
                onClick={onCredentialCertificationsToggle(certifications?.length)}
                onKeyDown={onCredentialCertificationsToggle(certifications?.length)}
                aria-hidden="true"
              >
                {certifications ? certifications.length : `  0`}
              </span>
            </span>
          </>
        </RowKeyX>
      </RowDiv>
    </ColumnDiv>
  );
};

const RowValue = ({ candidateCertifications, credentialCertifications, isCredentialCertsExpiring, t }) => {
  const { classes } = useStyles();
  const [openCredentials, setOpenCredentials] = useState<boolean>(false);
  const onCredentialCertificationsToggle = count => () => {
    if (count > 0) setOpenCredentials(prevState => !prevState);
  };

  const calculateDateExp = certificationItem => {
    const expDate = GetMomentOfDate(certificationItem.expirationDate).format('MM/DD/YYYY');
    if (certificationItem.expirationDate && GetMomentOfDate(certificationItem.expirationDate).isValid()) {
      return expDate;
    } else {
      return missingField;
    }
  };

  return (
    <>
      {candidateCertifications.length ? (
        <TableSection
          items={candidateCertifications.map(certificationItem => ({
            icon:
              certificationItem.isExpired === false ? (
                <Tooltip
                  title={t('placement.profile.summary.submission.order.certification.line1')}
                  arrow
                  placement="bottom"
                  classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
                >
                  {getRequirementCheck(3)}
                </Tooltip>
              ) : null,
            primaryContent: (
              <>
                <div>
                  <span>{`${certificationItem.certification} ${t(
                    'placement.profile.summary.submission.order.certification.line3',
                  )} ${certificationItem.issuedBy ? certificationItem.issuedBy : missingField}`}</span>
                  <span>
                    {certificationItem?.documentExists ? (
                      <WhiteTooltip title={t('placement.common.documentPresent')}>
                        <DescriptionOutlinedIcon fontSize="small" className={classes.documentIcon} />
                      </WhiteTooltip>
                    ) : (
                      <WhiteTooltip title={t('placement.common.documentAbsent')}>
                        <DescriptionOutlinedIcon fontSize="small" className={classes.documentIconGrey} />
                      </WhiteTooltip>
                    )}
                  </span>
                </div>
                <div>
                  <span>{t('placement.profile.summary.submission.order.certification.line4')}</span>
                  <span className={certificationItem.isExpired === true ? classes.expDateColor : ''}>
                    {calculateDateExp(certificationItem)}
                  </span>
                </div>
              </>
            ),
          }))}
        />
      ) : (
        missingField
      )}
      <Credentials
        certifications={credentialCertifications}
        t={t}
        onCredentialCertificationsToggle={onCredentialCertificationsToggle}
        id={'listPlacementSummaryCandidateCredentialsDetailsContainer'}
        isCredentialCertsExpiring={isCredentialCertsExpiring}
      />
      <CredentialingListedCertifications
        certifications={credentialCertifications}
        open={openCredentials}
        onCredentialCertificationsToggle={onCredentialCertificationsToggle}
      />
    </>
  );
};

const OperationRequirment = ({ requirement, operation, t }) => (
  <TableSection
    items={[
      {
        icon: operation === IOperation.OR && !!requirement ? getRequirementCheck(requirement) : null,
        primaryContent: getRequirmentText(operation, t),
      },
    ]}
  />
);

function getRequirmentText(requirement, t) {
  switch (requirement) {
    case IOperation.AND:
      return (
        <>
          <span style={{ fontWeight: 500 }}>{t(`placement.profile.summary.submission.order.commons.all`)}</span>
          <span>{t(`placement.profile.summary.submission.order.certification.message`)}</span>
        </>
      );
    case IOperation.OR:
      return (
        <>
          <span style={{ fontWeight: 500 }}>{t(`placement.profile.summary.submission.order.commons.atleast`)}</span>
          <span>{t(`placement.profile.summary.submission.order.certification.message`)}</span>
        </>
      );
    default:
      return '';
  }
}

const RowKeyX = styledComponent.div`
  font-size: 12px;
  font-weight: 500;
  color: #333333;
  // below css ensure positioning
  // w.r.t different font sizes along the row
  display: contents;
`;

const ColumnDiv = styledComponent.div`
  display: flex;
  flex-direction: column;
`;

const RowDiv = styledComponent.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
