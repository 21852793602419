import React from 'react';
import { GraphicStepper } from 'app/ComponentLibrary/GraphicStepper/GraphicStepper';
import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';
import { pipelineChoiceActions } from 'app/components/Common/WorkDesk/PipelineChoice/pipelineChoice.redux';

const useStyles = makeStyles()({
  container: {
    flexWrap: 'nowrap',
    background: '#FCFCFD',
    border: '1px solid #F5F5F5',
    padding: '1%',
  },
  total: {
    padding: '0 2% 0 1%',
    maxWidth: 140,
  },
});

export const WorkDeskPipeline = props => {
  const { pipelineTotal, data, selection, noValuePipelineText, gridSelectionKey } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const handleClick = (category, subcategory, value, event) =>
    selection.some(item => item.category === category && item.subcategory === subcategory)
      ? dispatch(
          pipelineChoiceActions.setPipelineChoice({
            key: gridSelectionKey,
            selection: [...selection].filter(item => !(item.category === category && item.subcategory === subcategory)),
          }),
        )
      : event.ctrlKey || event.metaKey
      ? // checks if user trying to combine the category to its same subcategories
        selection.some(item => item.category === category)
        ? subcategory === null
          ? //click on category ==> remove all subcategories within that category
            dispatch(
              pipelineChoiceActions.setPipelineChoice({
                key: gridSelectionKey,
                selection: [...selection.filter(item => item.category !== category), { category, subcategory, value }],
              }),
            )
          : //click on subcategory ==> remove the category
            dispatch(
              pipelineChoiceActions.setPipelineChoice({
                key: gridSelectionKey,
                selection: [
                  ...selection.filter(item => !(item.category === category && item.subcategory === null)),
                  { category, subcategory, value },
                ],
              }),
            )
        : dispatch(
            pipelineChoiceActions.setPipelineChoice({
              key: gridSelectionKey,
              selection: [...selection, { category, subcategory, value }],
            }),
          )
      : dispatch(
          pipelineChoiceActions.setPipelineChoice({
            key: gridSelectionKey,
            selection: [{ category, subcategory, value }],
          }),
        );

  return (
    <Grid item container direction="row" classes={{ container: classes.container }}>
      <Grid item container alignItems="center" classes={{ root: classes.total }}>
        {pipelineTotal}
      </Grid>
      <Grid item xs={12}>
        <GraphicStepper
          items={data}
          handleItemClick={handleClick}
          selected={selection}
          noValueText={noValuePipelineText}
        />
      </Grid>
    </Grid>
  );
};
