/* eslint-disable i18next/no-literal-string */
import React from 'react';
import { Expandable } from 'app/components/Common/Expandable/Expandable';
import { IconTitle } from './Titles/IconTitle';
import ClockIcon from 'app/assets/images/IconImages/clockIcon.svg';
import { CandidateStrikeStatus } from './CandidateStrikeStatus';
import { useTranslation } from 'react-i18next';

export const StrikeStatus = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <Expandable
      id="placement-summary-strike-status"
      expanded={true}
      titleComponent={
        <IconTitle title={t('placement.profile.eventStatus.title')} icon={<img src={ClockIcon} alt="Clock" />} />
      }
    >
      <CandidateStrikeStatus />
    </Expandable>
  );
};
