import { IGraphicStepper } from 'app/ComponentLibrary/GraphicStepper/GraphicStepper';
import { gridStateActions, GridTag } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import {
  checkForPipelineEquality,
  placementStatus,
  placementSubStatuses,
} from 'app/components/RecruiterWorkDesk/MyPlacementsDesk/utils';
import { simpleGroupBy } from 'app/helpers/arrayHelpers';
import { selectUser } from 'oidc/user.selectors';
import React, { useEffect, useState, createRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { pipelineChoiceActions, pipelineChoiceKeys } from '../../Common/WorkDesk/PipelineChoice/pipelineChoice.redux';
import { selectAmPlacementsGrid } from '../../Common/WorkDesk/PipelineChoice/pipelineChoice.selectors';
import { Aggregate } from './Aggregate';
import { amPlacementsAction, IFilterChipSelected } from './AmPlacementsDesk.redux';
import {
  selectAmPlacementGridData,
  selectPipelineCounts,
  selectAmPlacementDeskChips,
  selectAmPlacementSourceData,
  selectFilterChipSelected,
  selectAmPlacementDataLoaded,
} from './AmPlacementsDesk.selector';
import {
  notDefaultColumns,
  notInSubmissionColumns,
  notOfferColumns,
  notBookedColumns,
  notOnAssignmentColumns,
  getAmPlacementColumns,
  defaultColumnVisiblityModel,
} from './AmUtils';
import { PlacementsFilter } from './PlacementsFilter';
import { isAccountManagement } from 'oidc/userRoles';
import { amPlacementsFilterName, defaultFilterValues, isOneAccountManagerSelected } from './filterUtils';
import {
  determineChipSelected,
  createFilterChips,
  createAdvancedFilterChips,
  getFilteredData,
  getFilterChipSelected,
} from './filterHelper';
import { AdvancedFilter } from './AdvancedFilter';
import { selectFilters, selectGridState } from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import { Concatenate } from 'utils/string/string';
import { instanceOfISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import { HomePage } from 'app/layout/pages/HomePage';
import { ChipsContainer } from 'app/ComponentLibrary/Filter/ChipsContainer';
import { WorkDeskPipeline } from 'app/components/Common/WorkDesk/WorkDeskPipeline';
import { XGrid } from 'app/components/Common/XGrid/XGrid';
import _ from 'lodash';
import { getHomePageSupportComponentHeight } from 'app/layout/pages/HomePage';
import { calculateComponentRemainingHeight } from 'app/components/Common/XGrid/Hooks/useGridHeight';
import { useWindowResize } from 'utils/customHooks/useWindowResize';
import { DrawerFitted } from 'app/components/Common/Drawer/Drawer';
import { CandidateDrawerPreviewer } from 'app/components/GlobalSearch/Candidate/CandidateDrawerPreviewer';
import { selectGridSelection } from 'app/components/Common/Grid/GridSelection/GridSelection.selector';
import {
  useEnableBulkEmail,
  useEnableBulkSms,
  useEnableEmailCommunication,
  useEnableSmsCommunication,
} from 'oidc/CommunicationEnabler';
import { useBulkCommunication } from 'app/components/Notification/Common/BulkCommunication';
import { globalSearchActions } from 'store/redux-store/global-search/slice';
import { selectSearchResults } from 'store/redux-store/global-search/selectors';
import { navigationProfilesAction } from '@AMIEWEB/GlobalSearch/store/NavigationProfiles.redux';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { materialUiXGrid,useTheme } from 'amn-ui-core';
import { SearchGrid } from 'app/constants/FeatureFlags/smsKeys';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { SearchRecommendation } from '@AMIEWEB/Tasks/TaskManagement/SearchRecommendation';
import { GridColumns, SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import { RootState } from 'types';
import { GridApi, GridSortModel, GridColumns as XGridColumns, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';

interface IFilterValues {
  id: any;
  value: any;
  [key: string]: any;
}

interface DateRangeFilters {
  startDate: string;
  endDate: string;
  [key: string]: any;
}

interface IPipeline {
  category: number;
  subcategory: number | null;
  value?: any;
}

interface IFilters {
  placementAccountManager: Array<IFilterValues>;
  candidate: Array<IFilterValues>;
  placementRecruiter: Array<IFilterValues>;
  mspClient: Array<IFilterValues>;
  contractAffiliate: Array<IFilterValues>;
  facility: Array<IFilterValues>;
  city: Array<IFilterValues>;
  state: Array<IFilterValues>;
  discipline: Array<IFilterValues>;
  specialty: Array<IFilterValues>;
  staffingVendor: Array<IFilterValues>;
  technologyVendor: Array<IFilterValues>;
  orderPriorities: Array<IFilterValues>;
  activeCueDateRange: DateRangeFilters;
  allActiveCues: any;
}

export const PlacementsDeskWrapper = () => {
  const { t } = useTranslation();
  const apiRef: any = materialUiXGrid.useGridApiRef();
  const gridTag = 'accountManagement';
  const filterPreferences = 'account-Management-Filters';
  const pipelineSelection = useSelector(selectAmPlacementsGrid);
  const counts = useSelector(selectPipelineCounts);
  const { userInfo } = useSelector(selectUser);
  const flattenedData = useSelector(selectAmPlacementGridData);
  const placementsSourceData = useSelector(selectAmPlacementSourceData);
  const chips = useSelector(selectAmPlacementDeskChips);
  const filterChipSelected = useSelector(selectFilterChipSelected);
  const appliedFilterValues = useSelector(selectFilters);
  const userPreference = useSelector(selectUserPreference);
  const hiddenSubmitButton = createRef<HTMLButtonElement>();
  const { items, drawerData } = useSelector(selectSearchResults);
  const { selectedData } = useSelector(selectGridSelection);
  const dataLoaded = useSelector(selectAmPlacementDataLoaded);
  const enableSmsCommunication = useEnableSmsCommunication();
  const enableEmailCommunication = useEnableEmailCommunication();
  const enableBulkSms = useEnableBulkSms(SearchGrid.accountManagementWorkDesk);
  const enableBulkEmail = useEnableBulkEmail(SearchGrid.accountManagementWorkDesk);
  const MAX_ALLOWED_EMAIL_SELECTION = 250;
  const MAX_ALLOWED_SMS_SELECTION = 150;
  const CREATE_MESSAGE_WARNING = `Maximum of ${MAX_ALLOWED_SMS_SELECTION} recipients permitted. Please reduce your selections and try again.`;
  const CREATE_EMAIL_WARNING = `Maximum of ${MAX_ALLOWED_EMAIL_SELECTION} recipients permitted. Please reduce your selections and try again.`;
  const gridState = useSelector(selectGridState);
  const theme= useTheme();
  const gridPreferences = useSelector((state: RootState) => {
    return state.userPreferenceData.userGridPreferences.find(item => item.id === gridTag);
  });
  const filterPreference = useSelector((state: RootState) => {
    return state.userPreferenceData.userGridPreferences.find(item => item.id === filterPreferences);
  });
  const formMethods = useForm({ defaultValues: defaultFilterValues, shouldUnregister: false });
  const {
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { dirtyFields },
  } = formMethods;
  const dispatch = useDispatch();

  const [pipelineCounts, setPipelineCounts] = useState<IGraphicStepper[]>([]);
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [currentColumns, setCurrentColumns] = React.useState<any[]>(getAmPlacementColumns(t, false));
  const [currentColumnVisibilityModel, setCurrentColumnVisibilityModel] = React.useState<any>(
    _.cloneDeep(defaultColumnVisiblityModel),
  );
  const { handleCreateEmail, handleSmsSelection, handleSelectedSmsData, enableSmsCreate } = useBulkCommunication();
  const activate = enableSmsCreate();
  const [gridHeight, setGridHeight] = React.useState<number | undefined>(50);
  const [showResetButton, setShowResetButton] = React.useState<boolean>(false);
  const [isSortModalChanged, setIsSortModalChanged] = React.useState<boolean>(false);
  const columns = getAmPlacementColumns(t, false);
  const updateGridHeight = () => {
    if (isOneAccountManagerSelected(appliedFilterValues))
      setGridHeight(calculateComponentRemainingHeight('home-page-paper', getHomePageSupportComponentHeight, 70));
    else setGridHeight(380);
  };
  useWindowResize(updateGridHeight, updateGridHeight);
  const [preferencesLoaded, setPreferencesLoaded] = React.useState<boolean>(false);
  const pipeLineChoice = React.useRef<any>([]);

  const saveFilterPreferences = (values: IFilters | any, pipeline?: Array<IPipeline> | undefined) => {
    const filters = values || appliedFilterValues?.filters;
    const pipelineSelected = pipeline || pipeLineChoice.current;
    const preferenceData = {
      id: filterPreferences,
      value: {
        pipelineSelected: pipelineSelected,
        filtersApplied: filters,
      },
    };
    filters && dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
    dispatch(
      pipelineChoiceActions.setPipelineChoice({
        key: pipelineChoiceKeys.AM_WORK_DESK_GRID,
        selection: pipelineSelected,
      }),
    );
  };

  const savePipelineSelection = pipelineSelection => {
    pipeLineChoice.current = pipelineSelection;
    const pipelineChoice = filterPreference?.value?.pipelineSelected;
    const isPipelineEqual = checkForPipelineEquality(pipelineSelection, pipelineChoice);
    const preferenceData = {
      id: filterPreferences,
      value: {
        ...filterPreference?.value,
        pipelineSelected: pipelineSelection,
      },
    };
    !isPipelineEqual && dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
  };

  const onApplyFilter = (values, selectedChips?: IFilterChipSelected, pipelineSelected?) => {
    dispatch(globalActions.closeBanner());
    dispatch(gridStateActions.setFilterAttributes({ filterObject: values }));
    dispatch(gridStateActions.setPageNumber(1));
    if (dirtyFields[amPlacementsFilterName.placementAccountManager]) {
      const accountManagerIds: number[] = values[amPlacementsFilterName.placementAccountManager]?.map(
        data => data?.value,
      );
      dispatch(
        amPlacementsAction.getAmPlacementActions({
          accountManagerId: accountManagerIds,
          filter: { values, setValue, t },
        }),
      );
      const defaultFilterValuesTemp = _.cloneDeep(defaultFilterValues);
      if (values && values.activeCueDateRange) {
        defaultFilterValuesTemp.activeCueDateRange = values.activeCueDateRange;
      }
      reset({
        ...defaultFilterValuesTemp,
        [amPlacementsFilterName.placementAccountManager]: values
          ? values[amPlacementsFilterName.placementAccountManager]
          : null,
      });
    } else if (values?.placementAccountManager?.length === 0) {
      dispatch(amPlacementsAction.setFilteredData([]));
      dispatch(
        amPlacementsAction.setFilterChips({
          ...chips,
          placementAccountManager: [],
        }),
      );
      reset({ ...defaultFilterValues });
      dispatch(
        globalActions.setBanner({
          message: t('home.amWorkDesk.noSelectionMessage'),
          severity: 'warning',
          justify: 'center',
        }),
      );
    } else {
      const newfilterChipSelected = getFilterChipSelected(values, selectedChips || filterChipSelected);
      const filteredData = getFilteredData(values, placementsSourceData, newfilterChipSelected);
      dispatch(
        amPlacementsAction.setFilterChips({
          ...chips,
          ...createFilterChips(values, selectedChips || filterChipSelected),
          ...createAdvancedFilterChips(values, t),
        }),
      );
      dispatch(amPlacementsAction.setFilteredData(filteredData));
      dispatch(amPlacementsAction.setFilterChipSelected(newfilterChipSelected));
    }
    saveFilterPreferences(values, pipelineSelected);
  };

  const handleChipDelete = (field, { value: chipValue, isSelected }) => {
    if (field === 'pipelineSelected') {
      dispatch(
        pipelineChoiceActions.setPipelineChoice({
          key: pipelineChoiceKeys.AM_WORK_DESK_GRID,
          selection: [
            ...pipelineSelection.filter(
              item => !(item.category === chipValue.category && item.subcategory === chipValue.subcategory),
            ),
          ],
        }),
      );
    } else {
      const chosenValue = getValues(field);
      if (Array.isArray(chosenValue)) {
        const newValues = [...chosenValue];
        const typeConfirmation = instanceOfISelection(chipValue);
        newValues.splice(
          newValues.findIndex(opt => (typeConfirmation ? opt.value : opt) === chipValue.value),
          1,
        );
        setValue(field, newValues);
      } else setValue(field, null);
      if (isSelected) {
        const newChipSelection = {
          ...filterChipSelected,
          [field]: null,
        };
        dispatch(amPlacementsAction.setFilterChipSelected(newChipSelection));
        onApplyFilter(getValues(), newChipSelection);
      } else hiddenSubmitButton.current?.click();
    }
  };

  const loadGridData = (selected?: { category: string[]; subcategory: string[] }) => {
    let rowData = [...flattenedData];
    if (selected) {
      rowData = [
        ...flattenedData.filter(
          item => selected.category.includes(item.placementType) || selected.subcategory.includes(item.statusAbbr),
        ),
      ];
      dispatch(
        amPlacementsAction.setFilterChips({
          ...chips,
          pipelineSelected: [
            ...pipelineSelection.map(item => ({ label: item.value, enableDelete: true, value: item })),
          ],
        }),
      );
    } else {
      dispatch(
        amPlacementsAction.setFilterChips({
          ...chips,
          pipelineSelected: [],
        }),
      );
    }

    setTableData(rowData);
    dispatch(
      globalSearchActions.setSearchResults({
        count: rowData?.length,
        items: rowData,
        miscellaneous: {
          positionCount: 0,
          jobMatchHealth: false,
          jobInterestHealth: false,
        },
      }),
    );
  };

  const handleStateChange = state => {
    const clonedTable = _.cloneDeep(tableData);
    const searchItem = state.sorting.sortedRows.reduce((resultArray, i) => {
      const element = clonedTable.find(j => j.id === i);
      return element ? [...resultArray, element] : [...resultArray];
    }, []);
    if (searchItem.length === tableData.length) {
      searchItem.map((item, index) => (item.indexCounter = index));
    }
  };

  const saveSortModel = React.useCallback(
    sortModel => {
      const sortedColumn =
        sortModel?.length > 0
          ? {
              column: sortModel[0].field,
              direction: sortModel[0].sort,
            }
          : undefined;
      if (!_.isEqual(sortedColumn, gridState?.sortedColumn)) {
        const preferenceData = {
          id: gridTag,
          value: {
            ...(gridPreferences?.value || {}),
            sortedColumn: sortedColumn,
          },
        };
        dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
      }
    },
    [currentColumnVisibilityModel, dispatch, gridPreferences?.value, gridState?.sortedColumn],
  );

  const savePreferences = React.useCallback(
    ({
      field,
      columns: gridColumns,
      visibilityModel,
    }: {
      field?: string;
      columns: XGridColumns;
      visibilityModel: GridColumnVisibilityModel;
    }) => {
      if (!currentColumns || currentColumns.length === 0 || !userPreference.loaded) return;
      const currentGridColumns = new GridColumns(gridColumns).columns;

      if (currentGridColumns && currentGridColumns.length > 0 && currentGridColumns[0].field === '__check__')
        currentGridColumns.shift();

      const comparableColumns = new GridColumns(
        gridPreferences?.value?.columns,
        gridPreferences?.value?.columnVisibilityModel,
      );
      const columnsEqual = comparableColumns.isEqual(currentGridColumns);
      const visibilityEqual = comparableColumns.isVisibilityModelEqual(visibilityModel);

      if (!columnsEqual || !visibilityEqual) {
        const preferenceData = {
          id: gridTag,
          value: {
            ...(gridPreferences?.value || {}),
            columns: currentGridColumns,
            columnVisibilityModel: visibilityModel,
          },
        };
        dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
        setCurrentColumnVisibilityModel(visibilityModel);
        setShowResetButton(true);
      }
    },
    [currentColumns, userPreference.loaded, gridPreferences?.value, dispatch],
  );

  const reArrangeColumns = (notColumns: string[], flexColumns) => {
    const newNotColumns =
      appliedFilterValues && appliedFilterValues?.filters[amPlacementsFilterName.activeCueDateRange] !== null
        ? notColumns.filter(col => col !== 'lastCueNote' && col !== 'cuedFrom')
        : notColumns;
    setTableColumns(
      getAmPlacementColumns(t, flexColumns).reduce(
        (resultArray, item) => (newNotColumns.includes(item.field) ? resultArray : [...resultArray, item]),
        [] as any[],
      ),
    );
  };

  const handleChipClick = (key, chip) => {
    // filter-by-chip provision is not implemented for below fields
    if (!['pipelineSelected', amPlacementsFilterName.activeCueDateRange].includes(key)) {
      const newChipSelection = {
        ...filterChipSelected,
        [key]: determineChipSelected(chip, filterChipSelected[key]),
      };
      dispatch(amPlacementsAction.setFilterChipSelected(newChipSelection));
      onApplyFilter(getValues(), newChipSelection);
    }
  };

  useEffect(() => {
    const selectedGroups = simpleGroupBy(pipelineSelection, 'category');
    const categoriesSelected = Object.keys(selectedGroups);
    if (preferencesLoaded) {
      savePipelineSelection(pipelineSelection);
    }
    // reArrangeColumns(
    //   Object.keys(categoriesSelected).length !== 1
    //     ? notDefaultColumns
    //     : pipelineSelection[0].category === 0
    //     ? notInSubmissionColumns
    //     : pipelineSelection[0].category === 1
    //     ? notOfferColumns
    //     : pipelineSelection[0].category === 2
    //     ? notBookedColumns
    //     : notOnAssignmentColumns,
    //   Object.keys(categoriesSelected).length !== 1,
    // );

    pipelineSelection.length
      ? loadGridData({
          category: [
            // categories are unique selections --> check for unique values in selectedGroups
            ...categoriesSelected.reduce(
              (resultArray, item) =>
                selectedGroups[item].length === 1 && selectedGroups[item][0].subcategory === null
                  ? [...resultArray, selectedGroups[item][0].value]
                  : resultArray,
              [] as string[],
            ),
          ],
          subcategory: [
            ...pipelineSelection.reduce(
              (resultArray, item) => (item.subcategory !== null ? [...resultArray, item.value] : resultArray),
              [] as string[],
            ),
          ],
        })
      : loadGridData();
  }, [pipelineSelection, flattenedData]);

  useEffect(() => {
    setPipelineCounts([
      {
        category: {
          tag: `${t('home.recruiterWorkDesk.inSubmission')} (${counts.inSubmission})`,
          showTooltip: counts.inSubmission ? false : true,
          value: placementStatus.inSubmission,
          color: '#006FB9',
        },
        subcategory: [
          {
            tag: t('home.recruiterWorkDesk.wfc'),
            data: counts.waitingForClearance,
            value: placementSubStatuses.wfc,
          },
          {
            tag: t('home.recruiterWorkDesk.rfo'),
            data: counts.requestFileOut,
            value: placementSubStatuses.rfo,
          },
          {
            tag: t('home.recruiterWorkDesk.sent'),
            data: counts.sent,
            value: placementSubStatuses.sent,
          },
          {
            tag: t('home.recruiterWorkDesk.int'),
            data: counts.interviewed,
            value: placementSubStatuses.int,
          },
        ],
      },
      {
        category: {
          tag: `${t('home.recruiterWorkDesk.offer')} (${counts.offer})`,
          showTooltip: counts.offer ? false : true,
          value: placementStatus.offer,
          color: 'rgb(0, 139, 149)',
        },
        subcategory: [
          {
            tag: t('home.recruiterWorkDesk.obf'),
            data: counts.offerByFacility,
            value: placementSubStatuses.obf,
          },
          {
            tag: t('home.recruiterWorkDesk.obbt'),
            data: counts.offerBookedByTraveler,
            value: placementSubStatuses.obbt,
          },
          {
            tag: t('home.recruiterWorkDesk.fc'),
            data: counts.facilityConfirms,
            value: placementSubStatuses.fc,
          },
          {
            tag: t('home.recruiterWorkDesk.ta'),
            data: counts.travelerAccepts,
            value: placementSubStatuses.ta,
          },
        ],
      },
      {
        category: {
          tag: `${t('home.recruiterWorkDesk.booked')} (${counts.bookedCategory})`,
          showTooltip: counts.bookedCategory ? false : true,
          value: placementStatus.booked,
          color: '#003C69',
        },
        subcategory: [
          {
            tag: t('home.recruiterWorkDesk.booked'),
            data: counts.bookedSubCategory,
            value: placementSubStatuses.booked,
          },
          {
            tag: t('home.recruiterWorkDesk.ba'),
            data: counts.bookingApproved,
            value: placementSubStatuses.ba,
          },
          {
            tag: t('home.recruiterWorkDesk.wgtg'),
            data: counts.waitingGoodToGo,
            value: placementSubStatuses.wgtg,
          },
          {
            tag: t('home.recruiterWorkDesk.ppw'),
            data: counts.postponementWarning,
            value: placementSubStatuses.ppw,
          },
          {
            tag: t('home.recruiterWorkDesk.gtg'),
            data: counts.goodToGo,
            value: placementSubStatuses.gtg,
          },
        ],
      },
      {
        category: {
          tag: `${t('home.recruiterWorkDesk.onAssignment')} (${counts.onAssignment})`,
          showTooltip: counts.onAssignment ? false : true,
          value: placementStatus.onAssignment,
          color: theme.palette.components.chips.statusChips.approved.color,
        },
        subcategory: [
          {
            tag: t('home.recruiterWorkDesk.oa'),
            data: counts.onAssignment,
            value: placementSubStatuses.oa,
          },
        ],
      },
    ]);
  }, [counts]);

  const getColumnPreferences = (cols: any[]) => {
    try {
      const columnPreferences = gridPreferences?.value;
      if (columnPreferences && columnPreferences.columns) {
        const newColumns = cols.map(column => {
          const foundIndex = columnPreferences.columns.findIndex(item => item?.field === column?.field);
          if (foundIndex !== -1) {
            return {
              ...column,
              ...columnPreferences.columns[foundIndex],
            };
          } else {
            return column;
          }
        });
        return newColumns.sort((a, b) => {
          return (
            columnPreferences.columns.findIndex(i => i.field === a.field) -
            columnPreferences.columns.findIndex(i => i.field === b.field)
          );
        });
      }
      return cols;
    } catch (err) {
      return cols;
    }
  };

  const getVisibilityPreferences = (cols: any) => {
    const columnPreferences = gridPreferences?.value;
    if (columnPreferences && columnPreferences?.columnVisibilityModel) {
      return columnPreferences?.columnVisibilityModel;
    }
    return cols;
  };

  const getSortModelPreferences = (sortModel: any[]) => {
    const columnPreferences = gridPreferences?.value;
    if (columnPreferences && columnPreferences?.sortedColumn) {
      const preferenceSortModel = columnPreferences?.sortedColumn;
      const defaultModel = defaultSort[0];
      if (
        defaultModel?.field === preferenceSortModel?.column &&
        defaultModel?.sort === preferenceSortModel?.direction
      ) {
        setIsSortModalChanged(false);
      } else {
        setIsSortModalChanged(true);
      }
      return [{ field: preferenceSortModel?.column, sort: preferenceSortModel?.direction }];
    }
    return sortModel;
  };

  useEffect(() => {
    if (userInfo && userInfo.employeeId && !filterPreference) {
      dispatch(amPlacementsAction.getAmPlacementActions({ accountManagerId: [userInfo.employeeId] }));
      dispatch(
        gridStateActions.setGridState({
          gridTag: GridTag.AmPlacementDesk,
          pageSize: 250,
          pageNumber: 1,
          previewItemId: -1,
          showPreviewDialog: false,
          sortedColumn: { column: 'status', direction: 'asc' },
          filterAttributes: undefined,
        }),
      );
      isAccountManagement(userInfo.roles || []) &&
        dispatch(amPlacementsAction.getActiceAccountManagers({ userId: userInfo.employeeId }));

      const manager = {
        id: 0,
        value: userInfo.employeeId,
        name: Concatenate([userInfo.firstName || '', userInfo.lastName || ''], ' '),
      };
      dispatch(
        amPlacementsAction.setFilterChips({
          ...chips,
          [amPlacementsFilterName.placementAccountManager]: [
            {
              label: `${t('home.amWorkDesk.placementFilter.accountManager')}: ${manager.name}`,
              enableDelete: true,
              value: userInfo.employeeId,
            },
          ],
        }),
      );
      reset({
        ...defaultFilterValues,
        [amPlacementsFilterName.placementAccountManager]: [manager] as any[],
      });
    }

    return () => {
      dispatch(amPlacementsAction.reset());
      dispatch(gridStateActions.reset());
      dispatch(globalActions.closeBanner());

      /** To be refactored: Below reset action can impact the breadcrumb action once implemented */
      dispatch(pipelineChoiceActions.resetByKey(pipelineChoiceKeys.AM_WORK_DESK_GRID));
    };
  }, []);

  useEffect(() => {
    const appliedFilters = filterPreference?.value?.filtersApplied;
    const pipelineSelected = filterPreference?.value?.pipelineSelected;
    if (appliedFilters || (pipelineSelected?.length > 0 && dataLoaded)) {
      if (!preferencesLoaded) {
        setPreferencesLoaded(true);
        isAccountManagement(userInfo.roles || []) &&
          dispatch(amPlacementsAction.getActiceAccountManagers({ userId: userInfo.employeeId }));
        if (!!appliedFilters) {
          dispatch(gridStateActions.setGrid(GridTag.AmPlacementDesk));
          Object.keys(appliedFilters).forEach(key => {
            setValue(key, appliedFilters[key], {
              shouldDirty:
                appliedFilters?.placementAccountManager?.length > 0 &&
                key === amPlacementsFilterName.placementAccountManager,
            });
          });
          onApplyFilter(appliedFilters, undefined, pipelineSelected);
          dispatch(gridStateActions.setFilterAttributes({ filterObject: Object.entries(appliedFilters) }));
        }
        if (pipelineSelected?.length > 0) {
          pipeLineChoice.current = pipelineSelected;
          dispatch(
            pipelineChoiceActions.setPipelineChoice({
              key: pipelineChoiceKeys.AM_WORK_DESK_GRID,
              selection: pipelineSelected,
            }),
          );
        }
      }
    }
  }, [filterPreference, preferencesLoaded, dataLoaded]);

  const sidePanelItems = [
    {
      title: 'Quick View',
      component: <div style={{ minWidth: 236 }} />,
    },
    { title: 'Advanced Filter', component: <AdvancedFilter /> },
  ];

  // X-Grid Sorting
  const defaultSort = [{ field: 'statusAge', sort: 'asc' }];
  const [xGridSortModel, setxGridSortModel] = React.useState<any>(defaultSort);
  // maintain the previous sort model if the currently sorted column is removed from the grid
  const [prevXGridSortModel, setPrevXGridSortModel] = React.useState<any>(defaultSort);
  const onSortModelChange = model => {
      if (model.length > 0 && model[0].field === defaultSort[0].field && model[0].sort === defaultSort[0].sort) {
        setIsSortModalChanged(false);
      } else {
        setIsSortModalChanged(true);
      }
    setPrevXGridSortModel(model);
    setxGridSortModel(model);
    saveSortModel(model);
  };

  React.useEffect(() => {
    if (prevXGridSortModel?.length > 0 && tableColumns.map(item => item.field).includes(prevXGridSortModel[0].field)) {
      setxGridSortModel([...prevXGridSortModel]);
    } else if (tableColumns.map(item => item.field).includes(defaultSort[0].field)) {
      setxGridSortModel([...defaultSort]);
    } else {
      setxGridSortModel(undefined);
    }
  }, [tableColumns]);

  useEffect(() => {
    const sortedRows = apiRef.current.getSortedRows();
    dispatch(
      globalSearchActions.setSearchResults({
        items: sortedRows,
        count: sortedRows?.length,
        miscellaneous: {},
      }),
    );
    dispatch(
      navigationProfilesAction.setNavigationData({
        items: sortedRows,
      }),
    );
  }, [xGridSortModel, items?.length]);

  const leftActions = React.useMemo(() => {
    return gridState.gridTag === gridTag && items.length > 0
      ? [
          {
            title: 'global.xgrid.toolbar.email',
            Icon: EmailOutlinedIcon,
            disabled:
              !enableEmailCommunication ||
              !enableBulkEmail ||
              selectedData?.length < 1 ||
              selectedData?.length > MAX_ALLOWED_EMAIL_SELECTION,
            onClick: handleCreateEmail,
            tooltipProps: {
              tooltipContent: CREATE_EMAIL_WARNING,
              disabled: selectedData?.length <= MAX_ALLOWED_EMAIL_SELECTION,
            },
          },
          {
            title: 'global.xgrid.toolbar.sms',
            Icon: ChatOutlinedIcon,
            disabled:
              !activate ||
              !enableSmsCommunication ||
              !enableBulkSms ||
              handleSelectedSmsData()?.length < 1 ||
              handleSelectedSmsData()?.length > MAX_ALLOWED_SMS_SELECTION,
            onClick: handleSmsSelection,
            tooltipProps: {
              tooltipContent: CREATE_MESSAGE_WARNING,
              disabled: handleSelectedSmsData()?.length <= MAX_ALLOWED_SMS_SELECTION,
            },
          },
        ]
      : undefined;
  }, [
    CREATE_EMAIL_WARNING,
    CREATE_MESSAGE_WARNING,
    activate,
    enableEmailCommunication,
    enableSmsCommunication,
    gridState.gridTag,
    handleCreateEmail,
    handleSelectedSmsData,
    handleSmsSelection,
    items.length,
    selectedData?.length,
    t,
  ]);
  const determineGridColumns = () => {
    const currentGridColumns = getColumnPreferences(getAmPlacementColumns(t, false));
    const defaultColumns = new GridColumns(getAmPlacementColumns(t, false)).columns;
    const currentVisibilityModel = getVisibilityPreferences(defaultColumnVisiblityModel);
    const comparableColumns = new GridColumns(currentGridColumns, currentVisibilityModel);
    const columnsEqual = comparableColumns.isEqual(defaultColumns);
    const visibilityEqual = comparableColumns.isVisibilityModelEqual(defaultColumnVisiblityModel);
    columnsEqual && visibilityEqual ? setShowResetButton(false) : setShowResetButton(true);
    setCurrentColumns(currentGridColumns);
  };

  const determineVisibilityModel = () => {
    const currentVisibilityModel = getVisibilityPreferences(defaultColumnVisiblityModel);
    setCurrentColumnVisibilityModel(currentVisibilityModel);
  };

  const determineSortModel = () => {
    const currentGridSortModel = getSortModelPreferences(defaultSort);
    setxGridSortModel(currentGridSortModel);
    if (
      !gridState?.sortedColumn ||
      (gridState?.sortedColumn &&
        (gridState.sortedColumn.column !== currentGridSortModel[0].field ||
          gridState.sortedColumn.direction !== currentGridSortModel[0].sort))
    ) {
      dispatch(
        gridStateActions.setSortedColumn({
          column: currentGridSortModel[0].field,
          direction: currentGridSortModel[0].sort,
        }),
      );
    }
  };

  React.useEffect(() => {
    dispatch(gridStateActions.setPageNumber(1));
  }, [dispatch, tableData]);

  React.useEffect(() => {
    if (userPreference?.loaded) {
      dispatch(gridStateActions.setGrid(gridTag));
      determineGridColumns();
      determineVisibilityModel();
      determineSortModel();
    }
  }, [userPreference]);
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(filter => onApplyFilter(filter))}>
        <HomePage
          title={t('home.amWorkDesk.myWorkDesk.title')}
          stickIconArray={[3, 2]}
          dataVisualization={
            <WorkDeskPipeline
              data={pipelineCounts}
              selection={pipelineSelection}
              gridSelectionKey={pipelineChoiceKeys.AM_WORK_DESK_GRID}
              noValuePipelineText={t('home.amWorkDesk.pipeline.noValueText')}
              pipelineTotal={<Aggregate />}
              shrink={false}
            />
          }
          filters={<PlacementsFilter onApplyFilter={onApplyFilter} />}
          chips={<ChipsContainer chips={chips} onDelete={handleChipDelete} onClick={handleChipClick} />}
          table={
            <XGrid
              apiRef={apiRef}
              columns={currentColumns}
              rows={tableData || []}
              autoHeight={false}
              height={gridHeight}
              rowsPerPageOptions={[20, 50, 100, 250]}
              disableSelectionOnClick
              rowCount={(tableData || []).length}
              defaultColumnVisibilityModel={defaultColumnVisiblityModel}
              columnVisibilityModel={currentColumnVisibilityModel}
              sortModel={xGridSortModel}
              onSortModelChange={onSortModelChange}
              onStateChange={handleStateChange}
              checkboxSelection
              onColumnResizeStop={_columns => {
                savePreferences({
                  field: undefined,
                  columns: _columns,
                  visibilityModel: _.cloneDeep(currentColumnVisibilityModel ?? defaultColumnVisiblityModel),
                });
              }}
              onColumnOrderChange={(params, event, details, _columns) => {
                savePreferences({
                  field: params.field,
                  columns: _columns,
                  visibilityModel: _.cloneDeep(currentColumnVisibilityModel ?? defaultColumnVisiblityModel),
                });
              }}
              onColumnVisibilityModelChange={(model, details) => {
                dispatch(gridStateActions.setColumnVisibilityModel(model));
                setCurrentColumnVisibilityModel(_.cloneDeep(model));
                savePreferences({
                  field: undefined,
                  columns: _.cloneDeep(apiRef?.current?.getAllColumns()),
                  visibilityModel: _.cloneDeep(model),
                });
              }}
              toolbarProps={{
                leftActions: leftActions,
                resetColumns: () => {
                  savePreferences({
                    field: undefined,
                    columns: _.cloneDeep(getAmPlacementColumns(t, false)),
                    visibilityModel: _.cloneDeep(defaultColumnVisiblityModel),
                  });
                  dispatch(globalActions.setSnackBar({ message: t('search.grid.columnsResest'), severity: 'success' }));
                  onSortModelChange(defaultSort);
                },
                resetButton: isSortModalChanged || showResetButton,
              }}
              components={{
                NoRowsOverlay: isOneAccountManagerSelected(appliedFilterValues)
                  ? undefined
                  : () => <SearchRecommendation entity={SearchType.amWorkdesk} />,
              }}
            />
          }
          sidePanelItems={sidePanelItems}
        />
        {drawerData?.open && (
          <DrawerFitted
            onDrawerClose={() => {
              dispatch(globalSearchActions.setDrawerData({ open: false, data: undefined }));
            }}
            width={400}
            top={0}
            backgroundColor={theme.palette.framework.system.whisper}
            open={drawerData?.open || false}
          >
            {drawerData?.data ? <CandidateDrawerPreviewer isSearchGrid={true} isWorkDesk={true} /> : null}
          </DrawerFitted>
        )}
        <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
      </form>
    </FormProvider>
  );
};
