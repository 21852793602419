import { MFSOpts, PositionOpts } from "@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/OrderMatchTab/OrderMatchesGrid/OrderMatchFilterUtils"
import { FilterCompTypes } from "app/ComponentLibrary/Filter/utils"
import i18next from "i18next"

export enum UnitOrderFilterName {
    mfs = 'maxFilesSentId',
    positions = 'positions',
    orderAgeDateRange = 'dateEnteredRange',
}
export class UnitOrdersFilter {
    constructor(public filterSpecs: any, public t: any) { }

    getUnitOrderFilterSpecs = () => {
        return [
            {
                filterName: i18next.t('search.globalSearch.order.filterNames.orderAgeDateRange'),
                name: UnitOrderFilterName.orderAgeDateRange,
                placeholder: i18next.t('search.globalSearch.order.filterNames.orderAgeDateRange'),
                version3: true,
                type: FilterCompTypes.DATERANGEV2,
                variant: 'outlined',
                secondaryLabel: i18next.t('search.globalSearch.order.filterNames.orderAgeDateRange'),
                useMaxWidth: true,
                maxWidth: 210,
                clickAway: true,
                disablePortal: true,
                quickSelect: false,
            },
            {
                filterName: i18next.t('search.globalSearch.order.filterNames.mfs'),
                name: UnitOrderFilterName.mfs,
                type: FilterCompTypes.TYPEAHEADDROPDOWN,
                options: MFSOpts,
                hideClearAll: true,
            },
            {
                filterName: i18next.t('search.globalSearch.order.filterNames.positions'),
                name: UnitOrderFilterName.positions,
                type: FilterCompTypes.TYPEAHEADDROPDOWN,
                options: PositionOpts,
                hideClearAll: true,
            },
        ];
    }
}
