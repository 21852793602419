import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './JobPreferences.redux';

const selectDomain = (state: RootState) => state?.candidateJobPreferences || initialState;

export const selectJobPreferencesData = createSelector([selectDomain], jobPreferences =>({
    jobPreferencesData: jobPreferences.data,
    jobPreferencesGridData: jobPreferences.flattenedData,
    loading: jobPreferences.loading
}));

export const selectJobPreferencesModalProps = createSelector([selectDomain], jobPreferences =>({
    id: jobPreferences.id,
}))

export const selectPreferenceId = createSelector([selectDomain], jobPreferences => jobPreferences.preferenceId);
