import {
  getCurrencyString,
  getMileageCurrencyString,
  getShiftValue,
  getshiftDisplayValue,
} from '@AMIEWEB/Order/OrderCreationForm/RateCard/helper';
import { IOrderRateElement } from '../../types';
import { Concatenate } from 'utils/string/string';
import { groupBy } from 'app/helpers/arrayHelpers';
import { CONSTANTS } from '@AMIEWEB/Order/OrderDetails/OrderDetailsContent/helper';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { XGridDefaultColumn } from '@AMIEWEB/Common/XGrid/cells/XGridDefaults';
import { OffContractTableCell } from '@AMIEWEB/Order/OrderDetails/OrderDetailsContent/OrderDetailsDataComponents/RateCard/OrderDetailsRateCard';
import { missingField } from 'app/constants';

export class OrderRateManipulator {
  #rateColumns: any[];

  #skillsetColumns: any[];

  #skillsets: any[];

  #rates: any[] = [];

  constructor(rateElements: IOrderRateElement[], shiftIds: number[]) {
    const _rateElements = rateElements ?? [];
    const _shiftIds = shiftIds ?? [];
    this.#getRateElementColumns(_rateElements, _shiftIds);
    this.#deriveRateElements(_rateElements);

    const rates = _rateElements.filter(x => x.rateElementNameId === 26 || x.rateElementNameId === 25);
    this.#getSkillsetColumns(rates, _shiftIds);
    this.#deriveSkillsets(rates);
  }

  get = () => ({
    rateColumns: this.#rateColumns,
    skillsetColumns: this.#skillsetColumns,
    rates: this.#rates,
    skillsets: this.#skillsets,
  });

  #getRateElementColumns = (rates: IOrderRateElement[], shiftIds: number[]) => {
    const defaultColumns: GridColDef[] = [
      {
        ...XGridDefaultColumn,
        field: 'rateElement',
        headerName: 'Rate Element',
        headerClassName: 'super-app-theme--header',
        flex: 0,
        width: 180,
        pinnable: true,
        renderCell: (params: GridCellParams) => OffContractTableCell(params),
      },
    ];

    const shifts = this.#deriveShifts(shiftIds);
    //Always display ALL shifts in Rates elements Table card
    const updatedShifts = shifts.some(x => x.shiftId === null)
      ? shifts
      : [
          {
            shiftId: null,
            shiftValue: getShiftValue(null),
            shiftDisplayValue: getshiftDisplayValue(null),
          },
          ...shifts,
        ];

    this.#rateColumns = updatedShifts.reduce(
      (results, item) => [
        ...results,
        {
          ...XGridDefaultColumn,
          field: item.shiftValue,
          headerName: item.shiftDisplayValue,
          hideable: false,
          pinnable: false,
          headerClassName: 'super-app-theme--header',
          flex: 1,
          minWidth: 100,
          cellClassName: item.shiftValue == 'allShifts' ? 'super-app-theme--header' : 'super-app-theme--cell',
          align: 'right',
          headerAlign: 'right',
          renderCell: params => {
            return params.value || missingField;
          },
        },
      ],
      defaultColumns,
    );
  };

  #getSkillsetColumns = (rates: IOrderRateElement[], shiftIds: number[]) => {
    const defaultColumns: GridColDef[] = [
      {
        ...XGridDefaultColumn,
        field: 'skillSet',
        headerName: 'Skill Set',
        headerClassName: 'super-app-theme--header',
        flex: 0,
        width: 125,
        pinnable: true,
      },
      {
        ...XGridDefaultColumn,
        field: 'billRate',
        headerName: 'Bill Rate',
        headerClassName: 'super-app-theme--header',
        flex: 0,
        width: 100,
        pinnable: true,
        renderCell: (params: GridCellParams) => OffContractTableCell(params),
      },
    ];

    const shifts = this.#deriveShifts(shiftIds);

    this.#skillsetColumns = shifts.reduce(
      (results, item) => [
        ...results,
        {
          ...XGridDefaultColumn,
          field: item.shiftValue,
          headerName: item.shiftDisplayValue,
          hideable: false,
          pinnable: false,
          headerClassName: 'super-app-theme--header',
          flex: 1,
          minWidth: 100,
          cellClassName: item?.shiftValue == 'allShifts' ? 'super-app-theme--header' : 'super-app-theme--cell',
          align: 'right',
          headerAlign: 'right',
          renderCell: params => {
            return params.value || missingField;
          },
        },
      ],
      defaultColumns,
    );
  };

  #deriveShifts = (
    shiftIds: number[],
  ): {
    shiftId: any;
    shiftValue: string;
    shiftDisplayValue: string;
  }[] =>
    shiftIds.length > 0
      ? shiftIds
          .sort((a, b) => a - b)
          .map(shiftId => ({
            shiftId,
            shiftValue: getShiftValue(shiftId), //TO DO:Get shifts from UI itself
            shiftDisplayValue: getshiftDisplayValue(shiftId),
          }))
      : [
          {
            shiftId: null,
            shiftValue: getShiftValue(null), //TO DO:Get shifts from UI itself
            shiftDisplayValue: getshiftDisplayValue(null),
          },
        ];

  #deriveRateElements = (rates: IOrderRateElement[]) => {
    const rateElementRows = [];
    // process gww (rateElementNameId = 5)
    const gwwElements = rates?.filter(x => x.rateElementNameId === 5);
    if (!!gwwElements?.length) {
      const gwwObj = gwwElements.reduce(
        (results, item) => ({ ...results, [getShiftValue(item.shiftId)]: item.value+"" }),
        {},
      );
      rateElementRows.push({
        ...gwwObj,
        rateElement: CONSTANTS.GWW,
        toolTipContent: this.#getToolTipContent(gwwElements),
      });
    }

    // process Expected Hours (rateElementNameId = 28)
    const expectedHourElements = rates?.filter(x => x.rateElementNameId === 28);
    if (!!expectedHourElements?.length) {
      const expectedHourObj = expectedHourElements.reduce(
        (results, item) => ({ ...results, [getShiftValue(item.shiftId)]: item.value+"" }),
        {},
      );
      rateElementRows.push({
        ...expectedHourObj,
        rateElement: CONSTANTS.ExpectedHours,
        toolTipContent: [],
      });
    }

    // process Mileage (rateElementNameId = 27)
    const mileageElements = rates?.filter(x => x.rateElementNameId === 27);
    if (!!mileageElements?.length) {
      const mileageObj = mileageElements.reduce(
        (results, item) => ({ ...results, [getShiftValue(item.shiftId)]: item.value }),
        {},
      );
      rateElementRows.push({
        ...mileageObj,
        rateElement: CONSTANTS.MileageReimbursement,
        toolTipContent: this.#getToolTipContent(mileageElements),
        [getShiftValue(null)]: getMileageCurrencyString(mileageElements[0].value, '$'),
      });
    } else {
      //Display Mileage Element Always - Even if no value in response
      rateElementRows.push({
        ...{ [getShiftValue(null)]: missingField },
        rateElement: CONSTANTS.MileageReimbursement,
        toolTipContent: this.#getToolTipContent([]),
        [getShiftValue(null)]: missingField,
      });
    }
    // this.#rates = rateElementRows;

    this.#rates = rateElementRows.map((x, index) => ({ ...x, id: index + 1 }));
  };

  #deriveSkillsets = (rateElements: IOrderRateElement[]) => {
    const processedSkills = [];
    // const billrates = rateElements.filter(x => x.rateElementNameId === 26 || x.rateElementNameId === 25);

    const groupedRateElemnts = groupBy(rateElements, ['disciplineAbbreviation']);

    Object.entries<IOrderRateElement[]>(groupedRateElemnts)?.forEach(skill => {
      const rates = skill[1];
      const localBillRates = rates.filter(x => x.rateElementNameId === 25);
      const travelBillRates = rates.filter(x => x.rateElementNameId === 26);

      const discipline = skill[0].split(':').shift();

      // process BillRate: CONSTANTS.Local,
      if (!!localBillRates.length) {
        this.#deriveSkillsRecursively(localBillRates, CONSTANTS.Local, 'specialtyName', discipline, processedSkills);
      }

      // process BillRate: CONSTANTS.Travel,
      if (!!travelBillRates.length) {
        this.#deriveSkillsRecursively(travelBillRates, CONSTANTS.Travel, 'specialtyName', discipline, processedSkills);
      }
    });
    this.#skillsets = processedSkills.map((x, index) => ({ ...x, id: index + 1 }));
  };

  #deriveSkillsRecursively = (
    rates: IOrderRateElement[],
    rateType: string,
    groupingKey: 'specialtyName' | 'subSpecialtyName',
    parentSkill: string,
    skillRows,
  ) => {
    const groupedRates = Object.entries<IOrderRateElement[]>(groupBy(rates, [groupingKey]));
    const shiftsHoldSameCurrency = groupedRates.every(grp =>
      this.#checkIfShiftsHoldSameCurrency(groupedRates[0][1], grp[1]),
    );

    if (groupingKey === 'specialtyName') {
      groupedRates?.forEach((grp, index) => {
        if (!grp[1].every(x => x.subSpecialtyId === grp[1][0].subSpecialtyId)) {
          this.#deriveSkillsRecursively(
            grp[1],
            rateType,
            'subSpecialtyName',
            Concatenate([parentSkill, grp[0].split(':').shift()], ' - '),
            skillRows,
          );
          groupedRates.splice(index, 1);
        }
      });
    }

    if (shiftsHoldSameCurrency && !!groupedRates.length)
      skillRows.push({
        skillSet:
          groupedRates.length > 1
            ? parentSkill
            : Concatenate(
                [parentSkill, groupedRates[0][1][0]?.specialtyName, groupedRates[0][1][0]?.subSpecialtyName],
                ' - ',
              ),
        toolTipContent: this.#getToolTipContent(rates),
        billRate: rateType,
        ...this.#deriveShiftObjsWithCurrency(rates),
      });
    else
      groupedRates?.forEach(grp => {
        skillRows.push({
          skillSet: Concatenate([parentSkill, grp[0].split(':').shift()], ' - '),
          toolTipContent: this.#getToolTipContent(grp[1]),
          billRate: rateType,
          ...this.#deriveShiftObjsWithCurrency(grp[1]),
        });
      });
  };

  #checkIfShiftsHoldSameCurrency = (arr1: IOrderRateElement[], arr2: IOrderRateElement[]) =>
    arr1.every(x1 => {
      const arr2Element = arr2.find(x2 => x2.shiftId === x1.shiftId);
      if (!arr2Element) return false;
      return x1.value === arr2Element.value && x1.valueMax === arr2Element.valueMax;
    });

  #deriveShiftObjsWithCurrency = (rates: IOrderRateElement[]) =>
    rates.reduce(
      (results, rate) => ({
        ...results,
        [getShiftValue(rate.shiftId)]: getCurrencyString(rate.value, rate.valueMax, '$'),
      }),
      {},
    );

  #getToolTipContent = (rateElements: IOrderRateElement[]) =>
    rateElements.reduce((results, item) => {
      if (!item.offContractId || results.some(x => x.shiftId === item.shiftId)) {
        return results;
      }
      const isRateElement = item.rateElementName === CONSTANTS.GWW || item.rateElementName === CONSTANTS.ExpectedHours;
      return [
        ...results,
        {
          shiftId: item.shiftId,
          name: item.rateElementName,
          shift: getshiftDisplayValue(item.shiftId),
          value:
            item.rateElementName === CONSTANTS.MileageReimbursement
              ? getMileageCurrencyString(item?.value, '$', isRateElement)
              : getCurrencyString(
                  item?.value,
                  item?.valueMax,
                  isRateElement ? (item?.value > 1 ? 'Hours' : 'Hour') : '$',
                  isRateElement,
                ),
          status: item.statusId,
        },
      ];
    }, []);
}
