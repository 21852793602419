import { Status } from 'app/enums/Facility';
import _, { isArray } from 'lodash';
import moment from 'moment';
import { Concatenate } from 'utils/string/string';
import { formatShiftInputData } from '../helper';
import shifts from './common/shifts.json';
import { IContractDetailsRequest, IOffContractRateElements } from 'store/redux-store/create-order-ocbr/types';
import { missingField } from 'app/constants';
import { convertToPSTMoment } from 'app/helpers/dateHelper';

/**
 *
 * @param disciplineFullName
 * @param disciplineWithAbbreviation
 * @returns a string array with descipline abbr,speciality and sub specilaity
 */
export const convertToAbbreviation = (disciplineFullName, disciplineWithAbbreviation) => {
  if (disciplineFullName !== undefined) {
    return disciplineFullName.reduce((resultArray, item) => {
      const disciplineAbbr = disciplineWithAbbreviation?.find(disc => disc.object.ID === item.disciplineId)?.object
        ?.Abbreviation;

      resultArray.push({
        skillSetCombined: Concatenate([disciplineAbbr, item?.specialty, item?.subSpecialty], ', '),
        disciplineId: item?.disciplineId,
        disciplineAbbreviation: disciplineAbbr,
        specialtyId: item?.specialtyId,
        specialtyName: item?.specialty,
        subSpecialtyId: item?.subSpecialtyId ? item?.subSpecialtyId : 0,
        subSpecialtyName: item?.subSpecialtyId ? item?.subSpecialty : '',
      });
      return resultArray;
    }, [] as string[]);
  } else return [];
};

export const getDiciplineName = (disciplineId, specilaityId, subSpecialtyId, skillSetData) => {
  const disciplineAbbr = skillSetData.disciplineOpts.find(disc => disc.object.ID === disciplineId)?.object
    ?.Abbreviation;

  const disciplineName = skillSetData.disciplineOpts.find(disc => disc.object.ID === disciplineId)?.object?.Description;

  const specialityName = skillSetData.specialtyOpts.find(
    spec => spec.object.DisciplineID === disciplineId && spec.object.SpecialtyID === specilaityId,
  )?.object?.Description;

  const subSpecilityName = skillSetData.subSpecialtyOpts.find(
    subSpec => subSpec.object.SpecialtyID === specilaityId && subSpec.object.SubSpecialtyID === subSpecialtyId,
  )?.object?.Description;

  return {
    disciplineAbbr,
    disciplineName,
    specialityName,
    subSpecilityName,
  };
};
export const getUniqueShiftValues = shiftDetailsArr => {
  return _.sortBy(_.uniq((shiftDetailsArr ?? []).join().match(/\d+(\.\d+)?/gi) as string[]), shift =>
    parseFloat(shift),
  );
};

export const getUniqueShiftDetailsId = shiftDetails => {
  const uniqueShifts = new Set();

  // Extract unique shift values from descriptions
  shiftDetails.forEach(shift => {
    const shiftValues = shift.description.match(/[\d.]+/g);
    if (shiftValues) {
      shiftValues.forEach(value => uniqueShifts.add(parseFloat(value)));
    }
  });

  // Convert the set back to an array
  const uniqueShiftsArray = Array.from(uniqueShifts);
  uniqueShiftsArray.sort((a: number, b: number) => {
    return a - b;
  });
  const res = [];
  uniqueShiftsArray.map(shift => {
    const obj = {
      id: getShiftId(shift + ''),
      description: shift + '',
    };
    res.push(obj);
  });
  return res;
};

export const getUniqueShiftDetails = shiftDetails => {
  const uniqueShifts = [];

  shiftDetails.forEach(shift => {
    const shiftDescription = shift.description.split(' ')[0]; // Extract the shift part before the space
    const existingShift = uniqueShifts.find(s => s.description === shiftDescription);

    if (!existingShift) {
      uniqueShifts.push({
        id: shift.id,
        description: shiftDescription,
      });
    }
  });
  return uniqueShifts;
};

export const deriveFieldName = strArray => Concatenate(strArray, '_').trim().toLowerCase();

//Create payload for Service to get billRates
export const getContractDetailsPayload = (facilityId, shifts = [], skillsets = []): IContractDetailsRequest => ({
  facilityId,
  referentialDate: moment().format('yyyy-MM-DDTHH:mm:ss'),
  shifts: getShiftsIdArr(shifts),
  skillSets: getSkillsetPayload(skillsets),
});

export const getSkillsetPayload = skillsetArr =>
  skillsetArr.map(item => ({
    ...item,
    subSpecialtyId: item.subSpecialtyId ?? 0,
  }));

export const getShiftsIdArr = shiftsSelected => shiftsSelected.map(item => formatShiftInputData(item).id);

export const refactorValue = (value, decimalPosition) => parseFloat(value).toFixed(decimalPosition);

/**
 * Checks whether the object has a single value or min max range
 * @param data
 * @returns if it is a range it returns true
 */
export const isRangeValue = (data = {}) => (data?.hasOwnProperty('min') || data?.hasOwnProperty('max') ? true : false);

/**
 * Creates an object based on whether it is range or not
 * @param data
 * @returns
 */
export const refactorInputValue = data => {
  if (isRangeValue(data)) {
    return { isRange: true, value: { max: data?.max?.value + '', min: data?.min?.value + '' } };
  } else if (isOptionValueRange(data?.value)) {
    return { isRange: true, value: { max: data?.value?.maxRange + '', min: data?.value.minRange + '' } };
  } else {
    return { isRange: false, value: data?.value + '' };
  }
};
export const refactorInputSingleValue = data => {
  if (isRangeValue(data)) {
    return { isRange: false, value: data?.min?.value + '' };
  } else {
    return { isRange: false, value: data?.value + '' };
  }
};

export const getShiftId = shiftName => {
  const val = shifts.find(item => item.shiftName === shiftName)?.shiftId;
  return val;
};

export const getShiftName = shiftId => {
  if (shiftId === null) return null;
  const val = shifts.find(item => item.shiftId === shiftId)?.shiftName;
  return val;
};

// Helper functions for order detail page rate card

export const getShiftValue = shiftId => {
  if (shiftId === null) return 'allShifts';
  let shiftName = getShiftName(shiftId);
  return shiftName + 'H';
};

export const getshiftDisplayValue = shiftId => {
  if (shiftId === null) return 'All Shifts';
  let shiftName = getShiftName(shiftId);
  shiftName = shiftName + ' hr';
  return shiftName;
};
export const getSkillSetAbbreviation = (disciplinename, specialityname, subspecialityname) => {
  let skilsetname = disciplinename;
  if (specialityname != null || specialityname != undefined) {
    skilsetname += '-' + specialityname;
  }
  if (subspecialityname != null || subspecialityname != undefined) {
    skilsetname += '-' + subspecialityname;
  }
  return skilsetname;
};

export const getCurrencyString = (value, valueMax, rateValue, removeDecimal = false) => {
  const vString = removeDecimal ? value : value.toFixed(2);

  const vMString = removeDecimal ? valueMax : valueMax !== null && valueMax.toFixed(2);

  return !valueMax
    ? value < 0
      ? missingField
      : removeDecimal
      ? `${vString} ${rateValue}`
      : `${rateValue}${vString}`
    : `${rateValue}${vString} - ${rateValue}${vMString}`;
};

export const getMileageCurrencyString = (value, rateValue, removeDecimal = false) => {
  let returnValue = missingField;
  if(value > -1)
  {
  const vString = !removeDecimal && value.toFixed(3);
  return `${rateValue}${vString}`;
  }
  else return returnValue;
};

export const getDisplayStatusName = statusId => {
  switch (statusId) {
    case Status.Approved:
      return 'Approved';
    case Status.Pending:
      return 'Pending';
    case Status.PendingApproval:
      return 'Pending Approval';
    case Status.Rejected:
      return 'Rejected';
    case Status.Unsaved:
      return 'Unsaved';
    default:
      return '';
  }
};
/**
 * CHecks whther the option received is a range or not
 * @param option
 * @returns true if option is a range
 */
export const isOptionValueRange = option => {
  if (option) {
    if (typeof option === 'object' && 'minRange' in option) return true;
    else return false;
  }
};

/**
 *
 * @param type type of rate elment
 * @param rateElements complete array of rate elments
 * @returns gives the required type of rate elments
 */
export const getFacilityRateElements = (type, rateElements: IOffContractRateElements[]) => {
  const rateElementsOfRequiredType = (rateElements ?? []).filter(rateEle => rateEle.rateElementNameId === type);
  return rateElementsOfRequiredType;
};
/**
 * Matches the skillsets of rate element
 * @param skillSet
 * @param rateElements
 */

export const getFacilityRateSkillsMatched = (skillSet, rateElements: IOffContractRateElements[]) => {
  const matchedTravelBillRates = rateElements.filter(billRate => {
    return (
      (billRate.disciplineId === skillSet.disciplineId &&
        billRate.specialtyId === 0 &&
        billRate.subSpecialtyId === 0) ||
      (billRate.disciplineId === skillSet.disciplineId &&
        billRate.specialtyId === skillSet.specialtyId &&
        billRate.subSpecialtyId === 0) ||
      (billRate.disciplineId === skillSet.disciplineId &&
        billRate.specialtyId === skillSet.specialtyId &&
        billRate.subSpecialtyId === skillSet.subSpecialtyId)
    );
  });
  return matchedTravelBillRates;
};

export const getFacilityMileageSkillsMatched = (selectedSkillsets, rateElements: IOffContractRateElements[]) => {
  let matchedTravelMileageRates = [];
  selectedSkillsets.forEach(skillSet => {
    const matchedFacilityMileageSkillSet = getFacilityRateSkillsMatched(skillSet, rateElements);
    matchedTravelMileageRates.push(...matchedFacilityMileageSkillSet);
  });
  return matchedTravelMileageRates;
};

/**
 *
 * @param dateRange start and end date
 * @param rateElements array of rate elments
 * @returns array of rate elments within the range
 */
export const getRatesWithinDateRanges = (dateRange, rateElements: IOffContractRateElements[]) => {
  let rateElemntsWithinRange = [];
  let startdate = convertToPSTMoment(dateRange?.startDate);
  let enddate = convertToPSTMoment(dateRange?.endDate);
  if (!dateRange?.startDate) {
    return rateElemntsWithinRange;
  } else {
    rateElemntsWithinRange = rateElements.filter(rateEle => {
      const outOfDateRange = compareMomentObjects(
        startdate,
        convertToPSTMoment(rateEle?.effectiveEndDate),
        DateComparisonKeys.AFTER_OR_SAME,
      );
      return (
        (compareMomentObjects(
          startdate,
          convertToPSTMoment(rateEle?.effectiveStartDate),
          DateComparisonKeys.AFTER_OR_SAME,
        ) &&
          compareMomentObjects(
            enddate,
            convertToPSTMoment(rateEle?.effectiveEndDate),
            DateComparisonKeys.BEFORE_OR_SAME,
          )) ||
        (compareMomentObjects(
          startdate,
          convertToPSTMoment(rateEle?.effectiveStartDate),
          DateComparisonKeys.AFTER_OR_SAME,
        ) &&
          compareMomentObjects(
            enddate,
            convertToPSTMoment(rateEle?.effectiveEndDate),
            DateComparisonKeys.AFTER_OR_SAME,
          )) ||
        (compareMomentObjects(
          startdate,
          convertToPSTMoment(rateEle?.effectiveStartDate),
          DateComparisonKeys.AFTER_OR_SAME,
        ) &&
          rateEle?.effectiveEndDate == null &&
          !outOfDateRange)
      );
    });
  }
  return rateElemntsWithinRange;
};
export const compareMomentObjects = (momentObj1: moment.Moment, momentObj2: moment.Moment, key) => {
  switch (key) {
    case DateComparisonKeys.AFTER_OR_SAME:
      return momentObj1.isAfter(momentObj2, 'day') || momentObj1.isSame(momentObj2, 'day');
    case DateComparisonKeys.BEFORE_OR_SAME:
      return momentObj1.isBefore(momentObj2, 'day') || momentObj1.isSame(momentObj2, 'day');
    case DateComparisonKeys.AFTER:
      return momentObj1.isAfter(momentObj2, 'day');
    case DateComparisonKeys.BEFORE:
      return momentObj1.isBefore(momentObj2, 'day');
  }
};

export const DateComparisonKeys = {
  AFTER_OR_SAME: 'afterOrSame',
  BEFORE_OR_SAME: 'beforeOrSame',
  AFTER: 'after',
  BEFORE: 'before',
};

/**
 * Get the rateElements with valid status only
 * @param rateElements
 * @returns
 */
export const getRatesWithValidRanges = (rateElements: IOffContractRateElements[]) => {
  const ratesWithValidRanges = rateElements.filter(rateEle => {
    return (
      rateEle.statusId === Status.Approved ||
      rateEle.statusId === Status.Pending ||
      rateEle.statusId === Status.PendingApproval ||
      rateEle.statusId === Status.Rejected
    );
  });
  return ratesWithValidRanges;
};
export const getRatesWithShiftMatch = (shiftId, rateElements: IOffContractRateElements[]) => {
  const ratesWithShiftMatch = rateElements.filter(rateEle => {
    return (rateEle.shiftId ?? 0) === (shiftId === null ? 0 : shiftId);
  });
  return ratesWithShiftMatch;
};

export const refactorFacilityRateValue = (minValue, maxValue) => {
  if (!maxValue) {
    return refactorValue(minValue, 2);
  } else {
    return { minRange: refactorValue(minValue, 2), maxRange: refactorValue(maxValue, 2) };
  }
};
export const refactorFacilityMileageValue = (minValue, maxValue) => {
  if (!maxValue) {
    return refactorValue(minValue, 3);
  } else {
    return { minRange: refactorValue(minValue, 3), maxRange: refactorValue(maxValue, 3) };
  }
};
export const refactorFacilityGwwValue = (minValue, maxValue) => {
  if (!maxValue) {
    return refactorValue(minValue, 0);
  } else {
    return { minRange: refactorValue(minValue, 0), maxRange: refactorValue(maxValue, 0) };
  }
};
/**
 * Adds the index to the items in array
 * @param array
 * @returns array with indexed objects
 */
export const addIndexToItems = array => {
  const modifiedArray = array.map((obj, index) => ({ ...obj, index: index }));
  return modifiedArray;
};

export const getcombinedOptionsWithDefault = (array, skillset, shift) => {
  const defaultOption = {
    min: { value: '' },
    max: { value: '' },
    index: array.length,
    disciplineId: skillset?.disciplineId,
    specialtyId: skillset?.specialtyId,
    subSpecialtyId: skillset?.subSpecialtyId,
    shift: shift,
    shiftID: shift === null ? null : getShiftId(shift),
    source: RateElemenetSource.Order,
  };
  array.push(defaultOption);
  return array;
};

export const validateBillRateSource = (option, source) => {
  if (option?.source === source) return true;
  else return false;
};

/**
 * Sort the array based on the groups
 * @param options
 * @returns
 */

export const groupAndSortOptions = (options: any[]) => {
  const groupedOptions = {} as any;
  options?.forEach((option: any) => {
    if (!groupedOptions[option.groupShiftId === null ? '0' : getShiftName(option.groupShiftId)]) {
      groupedOptions[option.groupShiftId === null ? '0' : getShiftName(option.groupShiftId)] = [];
    }
    groupedOptions[option.groupShiftId === null ? '0' : getShiftName(option.groupShiftId)].push(option);
  });
  const temp = Object.values(groupedOptions).flat();
  return temp;
};

/**
 * Checks whether the order end date is more than off contract end date
 * @param orderEndDate
 * @param OffContractEndDate
 * @returns true if the logic to create a new rate element passes
 */
export const isNewRateElementRequired = (orderEndDate, OffContractEndDate) => {
  const orderEndDateOnly = convertToPSTMoment(orderEndDate);
  const OffContractEndDateOnly = convertToPSTMoment(OffContractEndDate);
  if (OffContractEndDate) {
    return orderEndDate
      ? compareMomentObjects(OffContractEndDateOnly, orderEndDateOnly, DateComparisonKeys.BEFORE)
      : true;
  } else {
    return false;
  }
};

/**
 * Checks whether the order start date is more than off contract end date
 * @param orderStartDate
 * @param OffContractStartDate
 */
export const removeRateElements = (orderStartDate, OffContractStartDate) => {
  const orderStartDateOnly = convertToPSTMoment(orderStartDate);
  const OffContractStartDateOnly = convertToPSTMoment(OffContractStartDate);
  if (OffContractStartDateOnly) {
    return orderStartDateOnly
      ? compareMomentObjects(orderStartDateOnly, OffContractStartDateOnly, DateComparisonKeys.BEFORE)
      : true;
  } else {
    return false;
  }
};

/**
 * Checks for Discipline are equal
 * @param skillSet
 * @param input
 * @returns
 */
export const isDisciplineMatch = (skillSet, input) => {
  if (
    (skillSet.disciplineId === input.disciplineId && input.specialtyId === 0 && input.subSpecialtyId === 0) ||
    (skillSet.disciplineId === input.disciplineId &&
      skillSet.specialtyId === input.specialtyId &&
      input.subSpecialtyId === 0) ||
    (skillSet.disciplineId === input.disciplineId &&
      skillSet.specialtyId === input.specialtyId &&
      skillSet.subSpecialtyId === input.subSpecialtyId)
  ) {
    return true;
  } else return false;
};
export const getGwwDefaultValue = (billRatesDetails, shiftName) => {
  //Contract does not have GWW
  if (
    billRatesDetails &&
    isArray(billRatesDetails?.rateElements) &&
    billRatesDetails?.rateElements[0].hoursPerPayCycle === null
  ) {
    return null;
  } else {
    const value = (billRatesDetails?.rateElements || []).find(
      (obj, index) => getShiftId(obj?.shift) === getShiftId(shiftName),
    );
    return value;
  }
};

//Ids for the Rate Elements
export enum RateElements {
  Regular = 1,
  RegularMax = 2,
  Local = 3,
  LocalMax = 4,
  GWW = 5,
  OTFactor = 6,
  OTTotal = 7,
  AdditionalOTAmount = 8,
  DTFactor = 9,
  DTTotal = 10,
  AdditionalDTAmount = 11,
  HolidayFactor = 12,
  HolidayTotal = 13,
  AdditionalHolidayAmount = 14,
  OnCallAmount = 15,
  CallBackFactor = 16,
  CallBackTotal = 17,
  AdditionalCallBackAmount = 18,
  AdditionalChargeAmount = 19,
  OrientationFactor = 20,
  Orientation = 21,
  AdditionalPreceptorAmount = 22,
  HousingProvider = 23,
  LunchPenalty = 24,
  LocalBillRate = 25,
  TravelBillRate = 26,
  MileageReimbursement = 27,
  ExpectedHours = 28,
}

export enum RateElemenetSource {
  Contract = 1,
  Facility = 2,
  Order = 3,
}
export enum ContractType {
  OffContract = 0,
  OnContract = -1,
}

/**
 * Expected Hours default values
 */
export const defaultValues = [{ index: 0, min: { value: '' }, max: { value: '' }, source: RateElemenetSource.Order }];

export const isValidDate = date => {
  const parsedDate = moment(date, 'MM/DD/YYYY', true);
  return parsedDate.isValid();
};
export const getHelperText = date => {
  if (!date) return 'Required';
  if (!isValidDate(date)) return 'Invalid Date Format';
};

export const getDateValidationStatusAndMessage = (date, dateType, effectiveDates) => {
  let result = {
    valid: true,
    message: ''
  }

  const selectedDate = moment(date, 'MM/DD/YYYY', true);

  if(!date){
    result.valid = false;
    result.message = 'Required'
  }

  else if(!selectedDate.isValid()){
    result.valid = false;
    result.message = 'Invalid Date'
  }

  else if(dateType === 'enddate'){
    const startDate = moment(effectiveDates.startDate);
    if(selectedDate.isSameOrBefore(startDate)){
      result.valid = false;
      result.message = 'End Date must be after the Start Date'
    }
  }
  
  else if(dateType === 'startdate'){
    const endDate = moment(effectiveDates.endDate);
    if(selectedDate.isSameOrAfter(endDate)){
      result.valid = false;
      result.message = 'Start Date must be before the End Date'
    }
  }

  return result;
}

export const getUniqueShiftIds = shifts => {
  const uniqueShifts = getUniqueShiftValues(shifts);
  return uniqueShifts.map(x => getShiftId(x)) || [];
};

export const createUpdateOrderSkillsetPayload = ({ facilityId, response, userInfo }) => ({
  facilityId,
  updateOrderPayload: {
    orderId: response.data.orderId,
    skillsets: response.data.skillsets,
    shiftIds: getUniqueShiftIds((response.data.shifts ?? []).map(x => x.description)),
    currentUserId: userInfo.userId,
    currentUserName: userInfo.userName,
  },
});
