import React from 'react';
import { Grid, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(theme => ({
  footerDataCell: {
    fontWeight: 500,
    textAlign: 'right',
    padding: '6px 20px 6px 6px',
  },
  gridLabel: {
    fontWeight: 500,
    padding: 6,
  },
  gridData: {
    padding: 6,
  },
  alternativeRow: { backgroundColor: theme.palette.framework.system.backgroundGrey },
  gridDataCollection: {
    ':nth-of-type(2)': {
      boxShadow: `0px 0px 0px ${theme.palette.framework.system.defaultGray}, 0px -1px 0px ${theme.palette.framework.system.defaultGray}`,
    },
  },
  gridRightAlign: {
    textAlign: 'right',
  },

  gridCenterAlign: {
    textAlign: 'right',
    padding: '6px 20px 6px 6px',
  },
  gridBoldData: {
    ':last-child': {
      fontWeight: 500,
    },
  },
}));

export interface ICustomGridRows {
  cellSize: number;
  labelRowClass: string;
  labelRow: string;
  dataRowClass: string;
  dataRow: string;
}
export interface IFooterRowProps {
  labelCellSize: number;
  dataCellSize: number;
  rowData: string | number;
}
export interface CustomGridProps {
  index?: number;
  rows: ICustomGridRows[];
  isFooterAvailable?: boolean;
}

export const CustomGrid = (gridProps: CustomGridProps) => {
  const { classes,cx } = useStyles();
  const gridHeader =
    gridProps.index === 0 &&
    gridProps.rows.map((gridRowInfo, idx) => (
      <Grid
        key={idx}
        xs={6}
        md={gridRowInfo.cellSize}
        className={cx(classes.gridLabel, { 
          [classes.gridRightAlign]: gridRowInfo.labelRowClass === 'gridRightAlign', 
          [classes.gridCenterAlign]: gridRowInfo.dataRowClass === 'gridCenterAlign'  
         })}
      >
        <Typography variant={'body1'}>{gridRowInfo.labelRow}</Typography>
      </Grid>
    ));

  const gridRows = gridProps.rows.map((rowInfo, idx) => (
    <Grid
      key={idx}
      item
      xs={6}
      md={rowInfo.cellSize}
      className={cx(classes.gridData, { 
        [classes.gridRightAlign]: rowInfo.dataRowClass === 'gridRightAlign', 
        [classes.gridCenterAlign]: rowInfo.dataRowClass === 'gridCenterAlign'  
      })}
    >
      {_.split(rowInfo?.dataRow, '\n').length > 1 ? (
        _.split(rowInfo?.dataRow, '\n').map((str, ix) => <Typography key={ix}>{str}</Typography>)
      ) : (
        <Typography variant={'body1'}>{rowInfo.dataRow}</Typography>
      )}
    </Grid>
  ));

  return (
    <React.Fragment>
      <Grid container>{gridHeader}</Grid>
      <Grid
        container
        className={cx(classes.gridDataCollection, {
          [classes.gridBoldData]: gridProps?.isFooterAvailable,
          [classes.alternativeRow]: gridProps?.index % 2 === 0,
        })}
      >
        {gridRows}
      </Grid>
    </React.Fragment>
  );
};

export interface IFooterRowProps {
  labelCellSize: number;
  dataCellSize: number;
  rowData: string | number;
}
export interface ICustomGridFooterProps {
  rowsCount: number;
  gridFooter: IFooterRowProps;
}
export const CustomGridFooter = (gridFooterRowProps: ICustomGridFooterProps) => {
  const { classes,cx } = useStyles();
  const { t } = useTranslation();
  return (
    <Grid
      container
      sx={{ minHeight: 40 }}
      className={cx({
        [classes.alternativeRow]: gridFooterRowProps?.rowsCount % 2 === 0,
      })}
    >
      <Grid item xs={6} md={gridFooterRowProps?.gridFooter?.labelCellSize} className={`${classes.gridLabel}`}>
        <Typography variant={'body1'}>{t('reportPages.r225.total')}</Typography>
      </Grid>
      <Grid item xs={6} md={gridFooterRowProps?.gridFooter?.dataCellSize} className={`${classes.footerDataCell}`}>
        <Typography variant={'body1'}>{gridFooterRowProps?.gridFooter?.rowData}</Typography>
      </Grid>
    </Grid>
  );
};
