/* eslint-disable tss-unused-classes/unused-classes */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'app/ComponentLibrary/DateRangeNew';
import { Controller, UseFormMethods } from 'react-hook-form';
import { assignCoverageVar, checkDateValidation } from './assignHelper';
import { IRequestCoverage } from 'app/models/UserCoverage/UserCoverage';
import { DateRange } from 'app/ComponentLibrary/DateRange/types';
import { FormatDate } from '@AMIEWEB/Notification/Tasks/Common/FormatData';

export const DatePicker = ({
  name,
  placeholder,
  isCallingAPI,
  minDate,
  formMethods,
  maxWidth,
  startDate = null,
  endDate = null,
  data,
}: {
  name: string;
  placeholder: string;
  isCallingAPI: boolean;
  minDate: any;
  formMethods: UseFormMethods<IRequestCoverage>;
  maxWidth?: number;
  startDate?: Date;
  endDate?: Date;
  data?: any;
}) => {
  const { control, errors, setValue } = formMethods;
  const initialStartDate = data?.startDate ?? null;
  const initialEndDate = data?.endDate ?? null;
  const [initialDateRange, setInitialDateRange] = useState<DateRange | null>(null);

  const isDateValid = value => {
    return checkDateValidation(data ? FormatDate( new Date(startDate?.setHours(18)), 'MM/DD/YYYY') : value?.startDate, false, minDate);
  };

  useEffect(() => {
    if (startDate && endDate) {
      setInitialDateRange({ startDate: startDate, endDate: endDate });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <Controller
      control={control}
      name={name}
      rules={{ validate: isDateValid }}
      render={({ ref, onChange, value, ...rest }) => (
        <DateRangePicker
          textFieldSize="medium"
          variant="filled"
          placeholder={placeholder}
          useMaxWidth={true}
          value={{
            startDate: startDate,
            endDate: endDate,
          }}
          initialDateRange={initialDateRange}
          minDate={minDate}
          maxWidth={maxWidth}
          isDisabled={isCallingAPI}
          actionBar={false}
          clickAway={true}
          keepClickAwayVal={true}
          popperPlacement="bottom"
          fallbackPlacements={['bottom-start']}
          onChange={e => {
            const initialStartDateValue = new Date(e?.startDate?.setHours(18));
            const newStartDateValue = e?.startDate ? FormatDate(initialStartDateValue, 'MM/DD/YYYY') : null;
            const newEndDateValue = e?.endDate ? FormatDate(e?.endDate, 'MM/DD/YYYY') : null;
            setValue(assignCoverageVar.startDate, newStartDateValue);
            setValue(assignCoverageVar.endDate, newEndDateValue);
            if (initialStartDate !== newStartDateValue || initialEndDate !== newEndDateValue) {
              onChange(() =>
                setValue(name, { startDate: newStartDateValue, endDate: newEndDateValue }, { shouldDirty: true }),
              );
            } else {
              onChange({ startDate: newStartDateValue, endDate: newEndDateValue });
            }
          }}
          fullWidth
          trailingIconShow={true}
          quickSelect={false}
          error={errors[name] ? true : false}
          helperText={errors[name]?.message ?? ''}
        />
      )}
    />
  );
};
