import { Box, Paper, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { SidePanel } from 'app/ComponentLibrary/SidePanel/SidePanel';
import { CustomBreadcrumProps, CustomBreadcrums } from 'app/components/Common/BreadcrumbNavigation/CustomBreadcrums';
import { CustomTabs, customTabsProps } from 'app/components/Common/Tabs/CustomTabs';
import { ITaskStickChosen, TaskEntity } from 'app/models/Tasks/Tasks';
import React, { useState } from 'react';
// import { Grid container spacing={2}, Grid item } from '../Grid container spacing={2}';
import { Page } from '../Page';

const useStyles = makeStyles<{ shrink: boolean; taskShrink: boolean, fullHeight: boolean }>()((theme, props) => ({
  sidePanelPadding: {
    paddingTop: '10px',
    '& .MuiContainer-root': {
      paddingleft: '18px',
      paddingright: '0px',
    },
    '& .MuiContainer-maxWidthLg': {
      paddingleft: '18px',
      paddingright: '0px',
    },
  },
  alartstyleCreate: {
    marginTop: '45px',
  },
  sidePanel: {
    '@media (min-width: 1200px)': {
      width: props.shrink ? 40 : '297px',
      '& .MuiPaper-root.MuiPaper-elevation1': {
        top: '113px',
      },
    },

    '@media (min-width: 700px) and (max-width: 1200px)': {
      width: '40px',
    },
  },
  taskSidePanel: {
    '@media (min-width: 1200px)': {
      width: props.shrink ? 10 : '297px',
    },

    '@media (min-width: 700px) and (max-width: 1200px)': {
      width: '10px',
    },
  },
  root: {
    minHeight: props.fullHeight ? '100%' : 'calc(100vh - 105px)',
    padding: 15,
    display: 'absolute',
  },
  taskRoot: {
    '@media (min-width: 1200px)': {
      minHeight:  props.fullHeight ? '100%' : 'calc(100vh - 105px)',
      padding: 15,
      display: 'absolute',
      width: props.taskShrink ? '100%' : 'calc(100% - 310px)',
    },

    '@media (min-width: 700px) and (max-width: 1200px)': {
      width: '100%',
      display: 'relative',
    },
  },
  mainPanelRoot: {
    '@media (min-width: 1200px)': {
      width: props.shrink ? 'calc(100% - 40px)' : 'calc(100% - 297px)',
      margin: '3px 3px',
    },

    '@media (min-width: 700px) and (max-width: 1200px)': {
      width: 'calc(100% - 40px)',
    },
  },
  taskMainPanelRoot: {
    '@media (min-width: 1200px)': {
      width: props.taskShrink ? 'calc(100% - 0px)' : 'calc(100% - 10px)',
      margin: '3px 3px',
    },

    '@media (min-width: 700px) and (max-width: 1200px)': {
      width: 'calc(100% - 0px)',
    },
  },
}));

/**
 * Order of components
 *
 * title + title actions,
 * head,
 * filters,
 * chips,
 * statTiles,
 * tabs,
 * children,
 * @param fullPage full page height of paper component
 */

export const DEFAULT_PAGE_SIZE = 250;
export const DEFAULT_PAGE_NUMBER = 1;

export const DetailsPage = ({
  title,
  titleActions,
  breadcrum,
  navigationActions,
  head,
  progress,
  filters,
  chips,
  statTiles,
  tabs,
  fullPage,
  isCreateForm,
  stickIconArray,
  sidePanelItems,
  sideAddOn,
  gridName,
  paddingBottom,
  removeExtraTopSpace,
  category,
  searchString,
  onFilterSetChange,
  taskStickChosen = { entity: TaskEntity.ALL, stick: null },
  currentContainer = TaskEntity.ALL,
  elevation,
  infoBanner = <></>,
  isLinkTaskMod = false,
  fullHeight,
  ...props
}: {
  [key: string]: any;
  title?: string;
  titleActions?: React.ReactNode;
  breadcrum?: CustomBreadcrumProps;
  navigationActions?: React.ReactNode;
  head?: React.ReactNode;
  progress?: React.ReactNode;
  filters?: React.ReactNode;
  chips?: React.ReactNode;
  statTiles?: React.ReactNode;
  tabs?: customTabsProps;
  fullPage?: boolean;
  isCreateForm?: boolean;
  sideAddOn?: React.ReactNode;
  paddingBottom?: string;
  onFilterSetChange?: Function;
  taskStickChosen?: ITaskStickChosen;
  currentContainer?: any;
  elevation?: number;
  infoBanner?: React.ReactNode;
  isLinkTaskMod?: boolean;
  fullHeight?: boolean;
}) => {
  const [stickChosen, setStickChosen] = useState<number | null>(null);
  const { classes, cx } = useStyles({ taskShrink: taskStickChosen.stick === null, shrink: stickChosen === null, fullHeight: fullHeight || false});
  const handleStickChoice = stick => setStickChosen(stick === stickChosen ? null : stick);

  return (
    <Page standardPadding={!isLinkTaskMod}>
      {infoBanner}
      <Grid
        container
        spacing={2}
        direction="column"
        style={{ paddingBottom: paddingBottom }}
        className={cx({ [classes.alartstyleCreate]: isCreateForm })}
      >
        {(breadcrum || title || titleActions || navigationActions) && (
          <Grid item>
            <Grid
              container
              wrap="nowrap"
              alignContent="center"
              justifyContent="space-between"
              alignItems='center'
              style={{ height: removeExtraTopSpace ? 'auto' : '2.4rem' }}
            >
              <Grid item>
                <div style={{ display: 'flex' }}>
                  {breadcrum && <CustomBreadcrums {...breadcrum} />}
                  {title && <Box className="page-title">{title}</Box>}
                  {!!navigationActions && navigationActions}
                </div>
              </Grid>
              {titleActions && (
                <Grid item style={{ display: 'flex', textAlign: 'end' }}>
                  {titleActions}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} style={{ width: '100%' }}>
          <Paper
            id="details-page-paper"
            style={elevation === 0 ? { minHeight: 0 } : {}}
            elevation={elevation === 0 ? elevation : undefined}
            className={elevation !== 0 ? cx('paper-2 layout') : undefined}
            classes={{
              root: currentContainer !== TaskEntity.ALL ? classes.taskRoot : classes.root,
            }}
          >
            <Grid container wrap="nowrap">
              <Grid
                item
                xs={12}
                className={currentContainer !== TaskEntity.ALL ? classes.taskMainPanelRoot : classes.mainPanelRoot}
              >
                <Grid container spacing={2}>
                  {head && (
                    <Grid item id="details-page-head" xs={12}>
                      {head}
                    </Grid>
                  )}
                  {progress && (
                    <Grid item id="details-page-progress" xs={12}>
                      {progress}
                    </Grid>
                  )}
                  {filters && (
                    <Grid item id="details-page-filters" xs={12}>
                      {filters}
                    </Grid>
                  )}
                  {chips && (
                    <Grid item id="details-page-chips" xs={12}>
                      {chips}
                    </Grid>
                  )}
                  {statTiles && (
                    <Grid item id="details-page-stat-tiles" xs={12}>
                      {statTiles}
                    </Grid>
                  )}
                  {tabs && (
                    <Grid item id="details-page-tabs" xs={12}>
                      <CustomTabs {...tabs} />
                    </Grid>
                  )}
                  {props.children && (
                    <Grid item xs={12}>
                      {props.children}
                    </Grid>
                  )}                  
                </Grid>
              </Grid>
              {stickIconArray && sidePanelItems && (
                <Grid item className={currentContainer !== TaskEntity.ALL ? classes.taskSidePanel : classes.sidePanel}>
                  {
                    <SidePanel
                      className={classes.sidePanelPadding}
                      items={sidePanelItems}
                      stickChosen={stickChosen}
                      handleChoice={handleStickChoice}
                      iconArray={stickIconArray}
                      enableCloseIcon
                      scrollable={true}
                    />
                  }
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Page>
  );
};

export const getDetailsPageSupportComponentHeight = () => {
  const a = document.getElementById('details-page-head')?.offsetHeight;
  const b = document.getElementById('details-page-progress')?.offsetHeight;
  const c = document.getElementById('details-page-filters')?.offsetHeight;
  const d = document.getElementById('details-page-chips')?.offsetHeight;
  const e = document.getElementById('details-page-stat-tiles')?.offsetHeight;
  const f = document.getElementById('details-page-tabs')?.offsetHeight;
  let spacing = 0;
  if (a) spacing += 1;
  if (b) spacing += 1;
  if (c) spacing += 1;
  if (d) spacing += d === 12 ? 3 : 1;
  if (e) spacing += 1;
  if (f) spacing += 1;
  spacing = spacing * 12;
  return (a ?? 0) + (b ?? 0) + (c ?? 0) + (d ?? 0) + (e ?? 0) + (f ?? 0) + (spacing || 12);
};
