import React from 'react';
import { Stepper, Step, StepLabel, StepConnector, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { withStyles } from 'tss-react/mui';
import CustomStepIcon from './CustomStepIcon';
const useStyles = makeStyles()(theme => ({
  labelContainer: {
    marginTop: '-70px',
  },
  label: {
    color: theme.palette.grey[500],
    fontSize: '14px',
  },
  activeLabel: {
    color: '#006FB9 !important',
    fontSize: '14px',
  },
  completedLabel: {
    color: '#006FB9 !important',
    fontSize: '14px',
  },
  gridMargin: {
    marginTop: '40px',
    // marginBottom: '40px',
  },
  stepperPadding: {
    // paddingTop: '25px',
    // paddingBottom: '10px',
    margin: '0 auto',
  },
}));

const CustomConnector = withStyles(StepConnector, (theme, _params, classes) => ({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    [`& .${classes.line}`]: {
      borderColor: '#006FB9',
    },
  },
  completed: {
    [`& .${classes.line}`]: {
      borderColor: '#006FB9',
    },
  },
  line: {
    borderColor: theme.palette.grey[300],
    borderTopWidth: 2,
  },
}));

const TopLabelStepper = props => {
  const { classes } = useStyles();
  const { activeStep } = props;

  return (
    <>
      <Grid item xs={12} className={classes.gridMargin}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<CustomConnector />}
          className={classes.stepperPadding}
        >
          {props.steps.map((item: number, index: number) => {
            return (
              <Step key={index}>
                <StepLabel
                  classes={{
                    labelContainer: classes.labelContainer,
                    label: classes.label,
                    active: classes.activeLabel,
                    completed: classes.completedLabel,
                  }}
                  StepIconComponent={CustomStepIcon}
                >
                  {item}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
    </>
  );
};

export default TopLabelStepper;
