import React from 'react';
import ResponsiveGridChips from './ResponsiveGridChips';

const ResponsiveChipCell = (params: any) => {
  const data = params?.value || [];
  const width = params?.colDef?.width;
  return (
    <div>
      <ResponsiveGridChips chips={data} cellWidth={width} />
    </div>
  );
};

export default ResponsiveChipCell;
