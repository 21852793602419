import React from 'react';
import { Grid, Chip, IconButton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import styledComponent from 'styled-components';
import CalendarIcon from 'app/assets/images/IconImages/AddCalendarIcon.svg';
import { DateRange, DateRangePicker } from 'app/ComponentLibrary/DateRangeNew';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { getDateRangeLabelWithNull } from 'utils/dates/dateExtraction';
import { missingField } from 'app/constants';
import moment from 'moment';
import { convertToFormat } from 'app/helpers/dateHelper';

const useStyles = makeStyles()(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& input': {
      fontSize: '14px',
    },
  },
  calendarIcon: {
    cursor: 'pointer',
  },
  chip: {
    maxWidth: '50ch',
    margin: '5px 0px 0px 0px',
  },
  calendarButton: {
    padding: 0,
    margin: 0,
    '&:focus': {
      border: '2px solid #006FB9',
      borderRadius: '3px',
      marginTop: '-4px',
    },
  },
  tooltip: {
    padding: '0px !important',
  },
}));

interface ITimeOffRequest {
  data: ITimeOffRequestData[];
  onChipDelete: (timeOff: ITimeOffRequestData) => void;
  onDateAdd: (startDate?: Date, endDate?: Date) => void;
  onChange: (startDate?: Date, endDate?: Date) => void;
  disableLabel?: boolean;
  disableControl?: boolean;
  id: string;
  onOpen?: () => void;
  onClose?: () => void;
  inputProps?: any;
  popperProps?: any;
  arrow?: boolean;
  placement?: any;
  labelStyle?: any;
  LabelRenderer?: JSX.Element;
}

interface ITimeOffRequestData {
  startDate?: string;
  endDate?: string;
}

export const TimeOffRequest = ({
  data = [],
  onChipDelete,
  onDateAdd,
  onChange,
  disableLabel,
  disableControl,
  id,
  onOpen,
  onClose,
  inputProps,
  popperProps,
  arrow = true,
  placement,
  labelStyle = undefined,
  LabelRenderer,
}: ITimeOffRequest) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [datePickerOpen, setDatePickerOpen] = React.useState<boolean>(false);
  const calendarRef = React.useRef<any>();
  const [dates, setDates] = React.useState<{ data: ITimeOffRequestData; formatted: string }[]>([]);
  const [errorMessage, setErrorMessage] = React.useState<boolean>();

  React.useEffect(() => {
    setDates(
      data?.reduce((prev, timeOff) => {
        const val = getDateRangeLabelWithNull(timeOff?.startDate || '', timeOff?.endDate || '');
        if (val)
          prev.push({
            data: timeOff,
            formatted: val,
          });
        return prev;
      }, [] as any[]),
    );
  }, [data]);

  const OpenDatePicker = () => {
    setDatePickerOpen(true);
    onOpen?.();
  };

  const CloseDatePicker = () => {
    setDatePickerOpen(false);
    onClose?.();
  };

  const _onCancel = () => {
    CloseDatePicker();
    setErrorMessage(undefined);
  };

  const _onApply = (dateRange: DateRange) => {
    if (!dateRange.startDate && !dateRange.endDate) return;
    else if (moment(dateRange.startDate).isSame(moment(dateRange.endDate), 'D')) {
      dateRange.endDate = undefined;
    }
    onDateAdd(dateRange.startDate, dateRange.endDate);
    CloseDatePicker();
  };

  const _onChange = (dateRange: DateRange) => {
    const rangeAlreadyExists = (data as { startDate: string; endDate: string }[]).some(item => {
      const s = moment(convertToFormat(item.startDate, 'YYYY-MM-DD'));
      const e = moment(convertToFormat(item.endDate || '1900-01-01', 'YYYY-MM-DD'));
      const newS = moment(convertToFormat(dateRange.startDate, 'YYYY-MM-DD'));
      const newE = moment(convertToFormat(dateRange.endDate || '1900-01-01', 'YYYY-MM-DD'));
      if (s.isSame(newS, 'D')) return true;
      else if (newS.isBetween(s, e, null, '[]')) {
        return true;
      } else if (newE.isBetween(s, e, null, '[]')) {
        return true;
      } else if (s.isBetween(newS, newE, null, '[]')) {
        return true;
      } else if (e.isBetween(newS, newE, null, '[]')) {
        return true;
      }
      return false;
    });
    setErrorMessage(false);
    setTimeout(() => {
      setErrorMessage(rangeAlreadyExists);
    }, 200);
    onChange(dateRange.startDate, dateRange.endDate);
  };

  return (
    <Grid container direction="column">
      <Grid item container spacing={1} xs={12} alignItems="center" wrap="nowrap">
        {!disableLabel && (
          <Grid item>
            {LabelRenderer ? (
              LabelRenderer
            ) : (
              <Key style={labelStyle}>{t('placement.profile.requirementsAndQualifications.timeOffRequest.title')}</Key>
            )}
          </Grid>
        )}
        <Grid item>
          {!disableControl && (
            <CustomTooltip
              interactive
              placement={placement}
              open={datePickerOpen}
              disableMaxWidth
              popperProps={popperProps}
              arrow={arrow}
              isStandardContent
              classes={{
                tooltip: classes.tooltip
              }}
              tooltipContent={
                <div style={{ paddingTop: '12px' }}>
                  <DateRangePicker
                    secondaryLabel={t('placement.profile.requirementsAndQualifications.timeOffRequest.secondaryLabel')}
                    variant="outlined"
                    elevation={0}
                    popper={false}
                    wrapperClass={classes.wrapper}
                    quickSelect={false}
                    onCancel={_onCancel}
                    applyButtonLabel={t(
                      'placement.profile.requirementsAndQualifications.timeOffRequest.applyButtonLabel',
                    )}
                    open={datePickerOpen}
                    onApply={_onApply}
                    onChange={_onChange}
                    error={errorMessage}
                    helperText={
                      errorMessage
                        ? t('placement.profile.requirementsAndQualifications.timeOffRequest.helperText')
                        : undefined
                    }
                    dualValidation
                    clickAway
                    {...inputProps}
                  />
                </div>
              }
            >
              <IconButton
                className={classes.calendarButton}
                id={id}
                onClick={OpenDatePicker}
                color="primary"
                size="small">
                <img src={CalendarIcon} alt="Calendar" ref={calendarRef} className={classes.calendarIcon} />
              </IconButton>
            </CustomTooltip>
          )}
          {disableControl && (
            <CustomTooltip tooltipContent={<div>{t('placement.profile.edit.disableText')}</div>}>
              <img
                src={CalendarIcon}
                alt="Calendar"
                ref={calendarRef}
                className={classes.calendarButton}
                onClick={OpenDatePicker}
              />
            </CustomTooltip>
          )}
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        {dates && dates.length > 0
          ? dates.map((timeOff, index) => (
              <Grid item xs={12} key={`placement-time-off-${index}`}>
                <Chip
                  disabled={disableControl}
                  variant="outlined"
                  size="small"
                  clickable={false}
                  label={timeOff.formatted}
                  className={classes.chip}
                  onDelete={() => onChipDelete(timeOff.data)}
                />
              </Grid>
            ))
          : missingField}
      </Grid>
    </Grid>
  );
};

const Key = styledComponent.div`
  font-size: 12px;
  font-weight: 500;
  color: #333333;
`;
