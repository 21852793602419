import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { addDays, subYears } from 'date-fns';
import { DateRangePicker } from 'app/ComponentLibrary/DateRangeNew';
import { FormatSystemDate } from 'app/components/Notification/Tasks/Common/FormatData';
import { Controller } from 'react-hook-form';
import { checkDateValidation } from '@AMIEWEB/AmWorkDesk/CoverageDesk/CoverageModal/assignHelper';

const StrikeDetailsDatePickerStyles = makeStyles()(theme => ({
  dateContainer: {
    '& .MuiInputLabel-filled.MuiInputLabel-marginDense': {
      transform: 'translate(12px, 22px) scale(1)',
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '14px',
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: 'translate(12px, 10px) scale(0.75)',
    },
  },
  dateInput: {
    '& .MuiFilledInput-input': {
      height: '1.3736em',
      padding: '27px 12px 10px',
      fontfamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif',
      fontSize: '12px',
    },
  },
}));

export const StrikeDetailsDatePicker = props => {
  const { formMethods: controls, name, dateValue, isCallingAPI, placeholder, DateCheck } = props;
  const { classes } = StrikeDetailsDatePickerStyles();
  const [selectedDateValue, setSelectedDateValue] = useState<any>(dateValue);
  const date = addDays(subYears(new Date(), 2), -1);
  const { control, clearErrors, setValue, watch, errors } = controls;
  const startDate = watch('effectiveDate');

  useEffect(() => {
    setValue(name, selectedDateValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateValue]);

  const getMinDate = useCallback(() => {
    if (DateCheck) {
      if (name === 'endDate') {
        return addDays(new Date(DateCheck), -1);
      }
      return date;
    }
    return date;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DateCheck]);
  const getMaxDate = useCallback(() => {
    if (DateCheck) {
      if (name === 'effectiveDate') {
        return addDays(new Date(DateCheck), -1);
      }
      return undefined;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DateCheck]);

  const isDateValid = value => {
    return name === 'endDate'
      ? checkDateValidation(value, true, startDate)
      : name.includes('travelDate')
      ? checkDateValidation(value, false, undefined, false)
      : checkDateValidation(value);
  };

  return (
    <Grid item className={classes.dateContainer}>
      <Controller
        control={control}
        name={name}
        defaultValue={selectedDateValue}
        rules={{ validate: isDateValid }}
        render={({ ref, onChange, value, ...rest }) => (
          <DateRangePicker
            variant="filled"
            placeholder={placeholder}
            className={classes.dateInput}
            single={true}
            initialDateRange={
              selectedDateValue
                ? {
                    startDate: new Date(selectedDateValue),
                    endDate: new Date(selectedDateValue),
                  }
                : undefined
            }
            value={selectedDateValue || null}
            minDate={getMinDate()}
            maxDate={getMaxDate()}
            isDisabled={isCallingAPI}
            disablePortal={true}
            actionBar={false}
            clickAway={true}
            trailingIconShow={true}
            keepClickAwayVal={true}
            onChange={e => {
              const selectedDate = e?.endDate ? FormatSystemDate(e.endDate, 'YYYY-MM-DDTHH:mm:ss') : '';
              onChange(selectedDate);
              setSelectedDateValue(selectedDate);

              if (selectedDateValue) {
                clearErrors(name);
              }
            }}
            error={errors[name]}
            helperText={errors[name]?.message ?? ''}
            onBlurText={selectedDate => {
              setSelectedDateValue(selectedDate);
            }}
            onInlineEdit={() => {
              clearErrors(name);
            }}
            definedRanges={[]}
            useMaxWidth={true}
            maxWidth={186}
          />
        )}
      />
    </Grid>
  );
};
