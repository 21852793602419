import { TimeOffRequest } from '@AMIEWEB/Common/TimeOffRequest';
import { ActionAccessorHook } from '@AMIEWEB/Placement/PlacementDetails/Edit/Components/ActionAccessorHook';
import { pageAttributesActions } from '@AMIEWEB/Placement/PlacementDetails/Edit/store/placementPageAttributes.redux';
import { selectEditStatus, selectPageAttributes } from '@AMIEWEB/Placement/PlacementDetails/Edit/store/placementPageAttributes.selector';
import { editableFields } from '@AMIEWEB/Placement/PlacementDetails/editUtils';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { selectEditableData } from 'store/redux-store/placement-details/selectors';

export const SummaryTimeOffRequest = () => {
    const { control, watch } = useFormContext();
    const { editOrderStack } = useSelector(selectPageAttributes);
    const { requestedTimeOff: defaultValue } = useSelector(selectEditableData);
    const { requestedTimeOff: status } = useSelector(selectEditStatus);
    const dispatch = useDispatch();
    const undoChecker = () => !editOrderStack.some(key => key === 'requestedTimeOff');  
    const requestedTimeOff = watch('requestedTimeOff');
  
    return (
      <Controller
        name="requestedTimeOff"
        control={control}
        rules={{ required: 'Required' }}
        render={({ ref, onChange, ...rest }) => (
          <>
            <ActionAccessorHook
              actionChecker={undoChecker}
              accessAction={() => onChange(defaultValue)}
              hookDependancies={[editOrderStack]}
            />
            <TimeOffRequest
              {...rest}
              value={requestedTimeOff}
              orientation="vertical"
              disabledContent={status.disabled}
              onChange={(newValue) => {
                onChange(newValue);
                dispatch(pageAttributesActions.pushToEditOrderStack(editableFields.requestedTimeOff));
              }}
            />
          </>
        )}
      />
    );
  };