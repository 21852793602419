/* eslint-disable tss-unused-classes/unused-classes */
import { Typography, Tooltip, Box, Paper, Skeleton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { trackEvent } from 'app-insights/appInsightsTracking';

export enum GlanceStatus {
  red = 'Red',
  lightGreen = 'LightGreen',
  ligthBlue = 'LightBlue',
  ligthRed = 'LightRed',
  green = 'Green',
  neutral = 'Neutral',
}

const usetooltipStyles: any = makeStyles()({
  container: {
    width: '100%',
  },
  customTooltip: {
    backgroundColor: '#ffffff',
    color: '#555555',
    fontSize: 12,
    opacity: 1,
    width: 200,
    textAlign: 'center',
    font: 'normal normal normal 12px/14px Roboto',
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #E5E5E5',
    borderRadius: 4,
    marginTop: 5,
  },
  customArrow: {
    color: '#ffffff',
    opacity: 1,
    marginRight: 15,
    '&::before': {
      boxShadow: '0px 3px 6px #00000029',
      border: '1px solid #E5E5E5',
    },
  },
  skeleton: {
    borderRadius: 4,
  },
});

const useStyles: any = makeStyles<{ clickable: boolean; applyCustomStyles: boolean }>()(
  (theme, { clickable, applyCustomStyles }) => ({
    tileContainer: {
      display: 'flex',
      flexDirection: 'row',
      borderRadius: 3,
      border: '1px solid #E2E2E2',
      minHeight: applyCustomStyles ? 48 : 40,
      cursor: clickable ? 'pointer' : 'auto',
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 45,
      borderRadius: '2px 0px 0px 2px',
      color: '#333333',
      fontSize: applyCustomStyles ? 12 : 14,
      padding: applyCustomStyles ? '5px 12px' : '10px',
      lineHeight: applyCustomStyles ? '14px' : undefined,
      whiteSpace: 'nowrap',
    },
    data: {
      display: 'flex',
      alignItems: 'center',
      color: '#fff',
      padding: applyCustomStyles ? '12px 10px' : '8px 16px',
      fontSize: applyCustomStyles ? 17 : 16,
      whiteSpace: 'nowrap',
    },
    nullData: {
      padding: '10px 22px',
    },
    statusRed: {
      background: '#CC0015',
    },
    statusGreen: {
      background: theme.palette.components.paper.status.green.backgroundColor
    },
    statusLightGreen: {
      background: theme.palette.system.lightPaleGreen,
      color: `${theme.palette.framework.system.kellyGreen} !important`,
    },
    statusLightBlue: {
      background: theme.palette.system.fadedBlue,
      color: `${theme.palette.framework.system.skyBlue} !important`,
    },
    statusLightRed: {
      background: theme.palette.system.lightPaleRed,
      color: `${theme.palette.system.errorIconRed} !important`,
    },
    statusNeutral: {
      color: `${theme.palette.system.charCoalGray} !important`,
      fontWeight: 'bold',
      background: theme.palette.system.lightDeepGray,
      borderRight: `1px solid ${theme.palette.framework.system.lightGray}`,
    },
  }),
);

export const GlanceTile = props => {
  const { classes } = usetooltipStyles();
  const tooltipText = props.tooltipText || ' ';

  return (
    <Box className={classes.container}>
      {props.isLoading ? (
        <Skeleton variant="rectangular" height={40} classes={{ rectangular: classes.skeleton }} />
      ) : props.showTooltip && tooltipText ? (
        <Tooltip
          title={tooltipText}
          arrow
          classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }}
          placement={'bottom'}
        >
          <Typography>
            <Tile {...props} />
          </Typography>
        </Tooltip>
      ) : (
        <Tile {...props} />
      )}
    </Box>
  );
};

const Tile = props => {
  const { colorCode = GlanceStatus.neutral, data = null } = props.status;
  const { applyCustomStyles } = props;
  const { classes } = useStyles({ clickAble: props.clickAble, applyCustomStyles });

  const handleClick = () => {
    if (props.clickAble && props.OnClick) {
      props.OnClick(props.status.data);
      if (props.analytics)
        trackEvent({ type: 'click', name: `${props.analytics.groupTitle} KPI`, properties: { kpi: props.title } });
    }
  };

  return (
    <Paper elevation={0} className={classes.tileContainer} onClick={handleClick}>
      <div className={`${data ? classes.data : classes.nullData} ${classes[`status${colorCode}`]}`}>{data}</div>
      <div className={classes.title}>{props.title}</div>
    </Paper>
  );
};
