import { FormControl, InputLabel, MenuItem, Select, TextField } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { withStyles } from 'tss-react/mui';
import React from 'react';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

const useStyles = makeStyles()({
  root: {
    border: '1px solid #E7E2E2',
    minWidth: 300,
  },
  dropdown: {
    marginTop: '-0.5em !important',
  },
  label: {
    left: 8,
  },
});

export const CustomSelect = ({ label, value: selected, options = [] as string[], ...props }) => {
  const { classes } = useStyles();
  return (
    <FormControl fullWidth>
      <InputLabel classes={{ formControl: classes.label }}>{label}</InputLabel>
      <Select
        variant="filled"
        value={selected}
        inputProps={{ className: classes.root }}
        IconComponent={ExpandMoreOutlinedIcon}
        MenuProps={{
          classes: { paper: classes.dropdown },
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          //   'aria-label': `create-placement-select-${name}`,
        }}
        disabled={props.disabled}
      >
        {options.map(option => (
          <MenuItem value={option}>{option}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const CustomNumberField = withStyles(TextField, {
  root: {
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
    },
    '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});
