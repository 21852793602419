import { GRID_DETAIL_PANEL_TOGGLE_FIELD, GridColDef, GridValueSetterParams } from '@mui/x-data-grid-pro';
import { VersionGridCell } from '../Common/GridCells/VersionIcon';
import React from 'react';
import { DocumentGridCell } from '../Common/GridCells/DocumentGridCell';
import { CustomDetailPanelToggle } from '../Common/GridCells/CustomDetailToggle';
import moment from 'moment';
import { ICredentials } from 'app/models/Credentialing/Credentials';
import { CredentialingLookup } from 'app/models/Credentialing/CredentialingLookups';
import { childSorting, groupedHumanResources } from '../../TableData/HumanResources/HumanResourcesGroupingHelper';
import { nestedChildren } from 'app/helpers/arrayHelpers';
import {
  detailColumnsAndRows,
  getCredentialName,
  getCredentialNameNoFormat,
  getDate,
  getDateNoFormat,
  getDateText,
  getMetadataForReqID,
} from '../../TableData/tableHelpers';
import { gridControlType } from 'app/models/Grid';
import { Checkbox } from 'amn-ui-core';
import { RenderEditDropdownCell } from '../Common/GridCells/EditGridDropdown';
import { RenderEditDateCell } from '../Common/GridCells/EditGridDatePicker';
import { RenderEditTextCell } from '../Common/GridCells/EditGridTextCell';

export const getMainTableColumns = (
  sortable: boolean = true,
  viewingDuplicates: boolean = false,
  historical: boolean = false,
  setClearChildMessages?,
  setShowAttachmentPreview?,
  setVersionOpen?,
  setEnableDelete?,
  setSelectedRowID?,
  checkBoxChange?,
  canEdit: boolean = false,
) => {
  const headers: GridColDef[] = [
    {
      field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
      hide: true,
    },
    {
      field: 'detailPanelToggle', //GRID_DETAIL_PANEL_TOGGLE_FIELD,
      headerName: '',
      renderCell: params => <CustomDetailPanelToggle param={params} setVersionOpen={setVersionOpen} />,
      flex: 20,
      align: 'center',
    },
    {
      field: 'isSelected',
      headerName: '',
      width: 60,
      renderCell: params => (
        <Checkbox
          checked={params.row?.isSelected}
          onChange={(event, param) => {
            setEnableDelete(!event.target.checked);
            setSelectedRowID(params.row.rowID);
            params.row[params.field] = event.target.checked;
            checkBoxChange(params.row.rowID, event.target.checked);
          }}
        />
      ),
      align: 'center',
    },
    {
      field: 'credentialNameNoFormat',
      headerName: 'Credential Name',
      sortable: sortable,
      flex: 250,
    },
    {
      field: 'dateCompleted',
      headerName: 'Date Completed',
      sortable: sortable,
      editable: canEdit,
      type: 'date',
      flex: 100,
      valueFormatter: param => (param.value ? moment(param.value).format('MM/DD/YYYY') : 'N/A'),
      renderEditCell: RenderEditDateCell,
    },
    {
      field: 'lastUpdate',
      headerName: 'Updated Date',
      sortable: sortable,
      flex: 100,
      valueFormatter: param => (param.value ? moment(param.value).format('MM/DD/YYYY') : 'N/A'),
    },
    {
      field: 'versioning',
      headerName: 'Versions',
      renderCell: params => <VersionGridCell param={params} setVersionOpen={setVersionOpen} />,
      align: 'center',
    },
    {
      field: 'documentIconHoverViewComponent',
      headerName: 'Document',
      flex: 100,
      renderCell: param => (
        <DocumentGridCell
          param={param}
          setClearChildMessages={setClearChildMessages}
          setShowAttachmentPreview={setShowAttachmentPreview}
        />
      ),
      align: 'center',
    },
  ];

  return headers;
};

export const getDetailedGridTableColumns = (columns, canEdit) => {
  let gridColumnHeaders: GridColDef<any>[] = [];

  if (columns && columns.length) {
    columns.forEach(column => {
      const isEditable = canEdit && isEditableColumn(column);
      let gridColumn: GridColDef = {
        field: column.title,
        headerName: column.value,
        sortable: false,
        disableColumnMenu: true,
        width: 150,
        editable: isEditable,
        valueGetter: params => {
          return getColumnValue(column, params.row);
        },
      };

      if (isEditable) {
        if (column.control?.type === gridControlType.dropDown) {
          gridColumn = {
            ...gridColumn,
            renderEditCell: RenderEditDropdownCell({
              options: [],
            }),
          };
        } else if (column.control?.type === gridControlType.date) {
          gridColumn = {
            ...gridColumn,
            type: 'date',
            valueSetter: (params: GridValueSetterParams) => {
              if (column.control?.type === gridControlType.date) {
                const field = column.title;
                params.row[field] = params.value ? moment(params.value).format('MM/DD/yyyy') : params.value;
              }
              return { ...params.row };
            },
            renderEditCell: RenderEditDateCell,
          };
        } else if (
          column.control?.type === gridControlType.textfield ||
          column.control?.type === gridControlType.textfieldnumber
        ) {
          gridColumn = {
            ...gridColumn,
            renderEditCell: RenderEditTextCell({
              type: column.control?.type === gridControlType.textfieldnumber ? 'number' : 'text',
            }),
          };
        }
      }

      gridColumnHeaders.push(gridColumn);
    });
  }

  return gridColumnHeaders;
};

export const getVersionColumns = (
  columns,
  setClearChildMessages,
  setShowAttachmentPreview,
  setEnableDeleteInVersion,
  setSelectedRowID,
  checkBoxChangeVersion,
) => {
  const lastColumns: GridColDef[] = [
    {
      field: 'lastUpdate',
      headerName: 'Updated Date',
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      valueFormatter: param => (param.value ? moment(param.value).format('MM/DD/YYYY') : 'N/A'),
    },
    {
      field: 'documentIconHoverViewComponent',
      headerName: 'Document',
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      renderCell: param => (
        <DocumentGridCell
          param={param}
          setClearChildMessages={setClearChildMessages}
          setShowAttachmentPreview={setShowAttachmentPreview}
        />
      ),
      align: 'center',
    },
  ];

  const expirationDateColumn = {
    field: 'expirationDate',
    headerName: 'Expiration Date',
    sortable: false,
    disableColumnMenu: true,
    width: 150,
    valueFormatter: param => (param.value && param.value !== 'N/A' ? moment(param.value).format('MM/DD/YYYY') : 'N/A'),
  };

  let versionColumns: GridColDef[] = [
    {
      field: 'isSelected',
      headerName: '',
      width: 60,
      renderCell: param => (
        <Checkbox
          checked={param.value}
          onChange={event => {
            setEnableDeleteInVersion(!event.target.checked);
            setSelectedRowID(param.id);
            param.row[param.field] = event.target.checked;
            checkBoxChangeVersion(param.row.rowID, event.target.checked);
          }}
        />
      ),
      align: 'center',
    },
    {
      field: 'credentialNameNoFormat',
      headerName: 'Credential Name',
      sortable: false,
      disableColumnMenu: true,
      width: 600,
    },
    {
      field: 'dateCompleted',
      headerName: 'Date Completed',
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      valueFormatter: param => (param.value ? moment(param.value).format('MM/DD/YYYY') : 'N/A'),
    },
  ];

  // check detail has expiration date column
  if (columns && columns.filter(x => x.title === 'expirationDate').length === 0) {
    versionColumns.push(expirationDateColumn);
  }

  // detail columns
  if (columns && columns.length) {
    columns.forEach(column => {
      versionColumns.push({
        field: column.title,
        headerName: column.value,
        sortable: false,
        disableColumnMenu: true,
        width: 150,
        valueGetter: params => {
          let value = 'N/A';
          if (params.row?.detailRows && params.row?.detailRows.length) {
            const detailRows = params.row?.detailRows[0][0]?.rows;
            value = getColumnValue(column, detailRows[0]);
          }
          return value;
        },
      });
    });
  }

  versionColumns = [...versionColumns, ...lastColumns];
  return versionColumns;
};

const getColumnValue = (col: any, row: any) => {
  if (!row) {
    return 'N/A';
  }

  const value = row[col.title];
  switch (col.control?.type) {
    case gridControlType.dropDown:
      return row[`${col.title}FilterOptions`]?.find(item => item.value === row[col.title])?.description || 'N/A';
    case gridControlType.date:
    case gridControlType.dateLabel:
      return value && value !== 'N/A' && value !== null ? moment(value).format('MM/DD/yyyy') : 'N/A';
    case gridControlType.checkbox:
      return value ? 'Yes' : 'No';
    default:
      return value || 'N/A';
  }
};

const isEditableColumn = (col: any) => {
  switch (col.control?.type) {
    case gridControlType.dropDown:
    case gridControlType.checkbox:
    case gridControlType.date:
    case gridControlType.textarea:
    case gridControlType.textfield:
    case gridControlType.textfieldnumber:
      return true;
    default:
      return false;
  }
};

const flattenData = (
  clinicalQualifications: ICredentials[],
  lookups: CredentialingLookup[],
  viewingDuplicates = false,
  level: number = 0,
  expandable: boolean = true,
) => {
  let nestedItems = clinicalQualifications;

  if (level === 0) {
    const grouped = groupedHumanResources(clinicalQualifications, 'ClinicalQualifications');
    nestedItems = nestedChildren(grouped);
  }

  if (level > 0) {
    nestedItems = childSorting(nestedItems, lookups);
  }

  const credentials: any[] = [];
  nestedItems?.forEach((item: ICredentials) => {
    const fieldLookups = getMetadataForReqID(lookups, item);

    const l: any = {
      rowID: item.requirementID,
      isSelected: false,
      credentialName: getCredentialName(item.requirementType, item.credentialName),
      credentialNameNoFormat: getCredentialNameNoFormat(item.requirementType, item.credentialName),
      dateCompleted: getDate(fieldLookups, 'DateCompleted', item.dateCompleted),
      expirationDate: getDate(fieldLookups, 'ExpirationDate', item.expirationDate),
      expirationDateNoFormat: getDateNoFormat(fieldLookups, 'ExpirationDate', item.expirationDate),
      lastUpdate: getDateText(fieldLookups, 'LastUpdate', item.lastUpdate),
      lastUpdateNoFormat: getDateNoFormat(fieldLookups, 'LastUpdate', item.lastUpdate),
      isExpirationDateEditable: fieldLookups.filter(x => x.lookUpField === 'ExpirationDate').length,

      expandable: expandable,
      detailRows: [[...detailColumnsAndRows(item, fieldLookups, ['DateCompleted', 'LastUpdate'])]],

      versionRows:
        level === 0 && item['children'] !== undefined
          ? flattenData(item['children'], lookups, false, level + 1, false)
          : null,
    };

    credentials.push(l);
  });

  if (level === 0) {
    return credentials.sort(
      viewingDuplicates
        ? (a: any, b: any) => (a.lastUpdateNoFormat < b.lastUpdateNoFormat ? 1 : -1)
        : (a: any, b: any) => (a.expirationDateNoFormat > b.expirationDateNoFormat ? 1 : -1),
    );
  } else return credentials;
};

export const clinicalQualificationsData = {
  getTableColumns: getMainTableColumns,
  getTableRows: flattenData,
};
