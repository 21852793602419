import { Expandable } from '@AMIEWEB/Common';
import { BoxContainer } from '@AMIEWEB/Common/SubmissionPreference/Common/BoxContainer';
import { SendToContacts } from '@AMIEWEB/Common/SubmissionPreference/Common/PreferenceContacts';
import { BulkEmailChoice } from '@AMIEWEB/Common/SubmissionPreference/SubmissionEmail/BulkEmailSettings';
import { Title } from '@AMIEWEB/Order/OrderDetails/OrderPreferences/customComponents/OrderPreferencesDesign';
import { Grid } from 'amn-ui-core';
import { LayoutGrid } from 'app/layout/LayoutGrid';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { EmailTemplates } from '@AMIEWEB/Common/SubmissionPreference/SubmissionEmail/EmailTemplates';
import { FormNames } from '@AMIEWEB/Common/SubmissionPreference/Common/helper';
import { useSelector } from 'react-redux';
import { selectPacketFacilityTemplates, selectPacketCategoryTitles } from 'store/redux-store/notification/notification.selector';
import { useDecision } from '@optimizely/react-sdk';
import { ff_placement_automation_preferences_add_facility_templates } from 'app/constants/FeatureFlags/Global/keys';
import { userRoles } from 'oidc/userRoles';
import { Authorized } from 'oidc/userHelper';
import { selectUser } from 'oidc/user.selectors';

interface ISubmissionEmailProps {
  disabled: boolean;
  facilityId: number;
}

export const SubmissionEmailWrapper = ({ disabled, facilityId }: ISubmissionEmailProps) => {
  const { t } = useTranslation();
  const categoryTitles = useSelector(selectPacketCategoryTitles);
  const facilityTitles = useSelector(selectPacketFacilityTemplates);
  const user = useSelector(selectUser);
  const [addTemplateFlag] = useDecision(ff_placement_automation_preferences_add_facility_templates);
  const [showAddEditTemplate, setShowAddEditTemplate] = React.useState<boolean>(false);
  const [tabValue, setTabValue] = React.useState<number>(0);

  const { control, errors } = useFormContext();

  return (
    <Expandable
      expanded
      titleComponent={<Title>{t('facility.units.unitDetails.preferencesTab.submissionPacketEmailSetup')}</Title>}
      unmountOnExit={false}
    >
      <Grid container height={456}>
        <LayoutGrid display="flex" direction="row" spacing={2} wrap="nowrap">
          <Grid item sx={{ minWidth: '170px' }} xs={2}>
            <BoxContainer disabled={disabled} title="Bulk Email Settings">
              <Controller
                name={FormNames.EmailSettings}
                control={control}
                render={({ ref, ...rest }) => <BulkEmailChoice {...rest} disabled={disabled} />}
              />
            </BoxContainer>
          </Grid>
          <Grid item xs={5}>
            <BoxContainer
              disabled={disabled}
              title="Email Template"
              addOnComponent={
                tabValue === 1 &&
                addTemplateFlag.enabled &&
                Authorized(
                  [
                    userRoles.accountManagement,
                    userRoles.accountManagement_Leadership,
                    userRoles.accountManagement_TeamMember,
                  ],
                  user.userInfo,
                ) && (
                  <AddIcon
                    sx={{ color: theme => theme.palette.system.neutralGray, fontSize: '21px', cursor: 'pointer' }}
                    onClick={() => setShowAddEditTemplate(true)}
                  />
                )
              }
            >
              <Controller
                name={FormNames.EmailTemplate}
                control={control}
                render={({ ref, ...rest }) => (
                  <EmailTemplates
                    {...rest}
                    disabled={disabled}
                    cards={categoryTitles}
                    facilityCards={facilityTitles}
                    showAddEditTemplate={showAddEditTemplate}
                    setShowAddEditTemplate={setShowAddEditTemplate}
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                    facilityId={facilityId}
                  />
                )}
              />
            </BoxContainer>
          </Grid>
          <Grid item xs={5}>
            <BoxContainer
              disabled={disabled}
              title="Send To*"
              addOnComponent={<AddIcon sx={{ color: theme => theme.palette.system.neutralGray, fontSize: '21px' }} />}
              error={errors[FormNames.SendTo]}
            >
              <Controller
                name={FormNames.SendTo}
                control={control}
                render={({ ref, ...rest }) => <SendToContacts {...rest} disabled={disabled} />}
              />
            </BoxContainer>
          </Grid>
        </LayoutGrid>
      </Grid>
    </Expandable>
  );
};
