/* eslint-disable tss-unused-classes/unused-classes */
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import MessageOutlinedIcon from '@mui/icons-material/ChatOutlined';
import CallBackIcon from '@mui/icons-material/FlagOutlined';
import HomeIconOutlined from '@mui/icons-material/HomeOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MailOutlineIcon from '@mui/icons-material/MailOutlineRounded';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import EmailDisabled from 'app/assets/images/CandidateHeader/email-Disabled.svg';
import PassportDisabled from 'app/assets/images/CandidateHeader/Passport-disabled.svg';
import PassportEnabled from 'app/assets/images/CandidateHeader/Passport-enabled.svg';
import VoiceLastContacted from 'app/assets/images/CandidateHeader/phone-callback.svg';
import PhoneDisabled from 'app/assets/images/CandidateHeader/PhoneDisabled.svg';
import MessageDisabled from 'app/assets/images/CandidateHeader/Text-Disabled.svg';
import CloseIcon from 'app/assets/images/close-24px.svg';
import MailBoxIcon from 'app/assets/images/MailBox.svg';
import MessageIcon from 'app/assets/images/Message.svg';
import PhoneIcon from 'app/assets/images/Phone.svg';
import { ContactList } from 'app/components/Common/ContactsDrawer/ContactList';
import { DrawerFitted } from 'app/components/Common/Drawer/Drawer';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { MessageDisabledTooltip } from 'app/components/Common/Tooltips/MessageDisabledTooltip';
import { EllipsesTextWithToolTip, nameInitial } from 'app/components/Notification/Common/StringHelper';
import { ChannelType, ContactType, MISSING_FIELD } from 'app/components/Notification/Constants';
import { OrderTab } from 'app/components/Order/OrderDetails/OrderHeader/OrderTab';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import { ICandidateDetails } from 'app/models/Candidate/CandidateProfile';
import { OrderHeaderSection } from 'app/models/Orders/OrderDetails';
import moment from 'moment';
import { selectUser } from 'oidc/user.selectors';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'amn-ui-core';
import { container } from 'styles/styleVariables';
import { Concatenate } from 'utils/string/string';
import { CandidateSummaryTab } from './CandidateSummaryTab';
import { ContactTab } from './ContactTab';
import {
  useEnableEmailCommunication,
  useEnableSmsCommunication,
  useEnableVoiceCommunication,
} from 'oidc/CommunicationEnabler';
import { InlineDropdown } from '@AMIEWEB/Common/EditPage/InlineDropdown';
import { usePromiseTracker } from 'react-promise-tracker';
import { getSearchLookups } from 'app/services/SharedServices/SharedServices';
import PublicIcon from '@mui/icons-material/Public';
import { selectEditPlacementRegion, selectPlacementHeaderData } from 'store/redux-store/placement-details/selectors';
import { placementDetailsAction } from 'store/redux-store/placement-details/slice';
import { selectPlacementStatus } from 'store/redux-store/placement-status/selectors';
import {
  activityTypeIds,
  assignmentActivityStatusIds,
  placementRegionUnlocked,
} from '@AMIEWEB/Placement/NextPlacementStatus/StatusDefaults';
import { getNumberOfDays } from '../CandidateTabPanel/ProfileTab/Cards/RequestTimeOff/helper';
import { useTranslation } from 'react-i18next';
import { PlacementContactsTab } from '@AMIEWEB/Placement/PlacementDetails/PlacementDetailsHeader/PlacementContactsTab';

const useStyles = makeStyles()(theme => ({
  title: {
    color: '#52429A',
    fontSize: 20,
    fontWeight: 600,
  },
  divider: {
    margin: '0 0 12px 0',
    height: 2,
  },
  textContent: {
    textAlign: 'start',
    alignSelf: 'column',
    color: '#003e68',
    fontSize: 12,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
  },

  avatarProfile: {
    height: 80,
    width: 80,
    marginRight: 10,
    fontSize: 30,
    backgroundColor: '#003C69',
    fontWeight: 500,
    border: `5px solid ${theme.palette.components.avatar.green.backgroundColor}`,
    textTransform: 'uppercase',
    position: 'inherit',
  },
  headerNavigation: {
    backgroundColor: '#FFF',
  },
  candidateAvatarProfile: {
    height: 50,
    width: 50,
    marginRight: 10,
    fontSize: 25,
    backgroundColor: theme.palette.components.avatar.green.backgroundColor,
    fontWeight: 500,
    textTransform: 'uppercase',
    position: 'inherit',
  },

  tabContainer: {
    borderBottom: 'none !important',
    padding: '0px !important',
    width: 'auto',
    maxWidth: '420px',
    borderRadius: `${container.borderRadius} ${container.borderRadius} 0px 0px !important`,
  },
  drawerRoot: {
    flexGrow: 1,
    width: 'inherit',
  },
  profileSegment: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '20px 20px 5px 25px',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: '-16%',
  },
  profileInfo: {
    padding: '0px 0px 10px 12px',
  },
  details: {
    wordBreak: 'keep-all',
    fontSize: '14px',

    padding: '4px 0px 4px 0px',

    display: 'flex',
  },
  detailsText: {
    paddingLeft: '6px',
    alignSelf: 'center',
    color: '#006fb9',
    fontWeight: 600,
    width: '68%',
    wordBreak: 'break-all',
  },

  detailsTextAction: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  tabsSelection: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
  },
  closeIconPad: {
    '&:hover': {
      backgroundColor: 'white',
    },
    fontSize: '1.5rem',
    color: '#888888',
  },
  closeIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  profileInfoName: {
    fontSize: '18px',
    color: '#333333',
    fontWeight: 600,
  },
  profileInfoID: {
    fontSize: '14px',
    color: '#333333',
    fontWeight: 600,
  },
  tooltip: {
    display: 'grid',
  },
  colorOveride: {
    color: '#555555 !important',
  },
  header: {
    width: 'inherit',
    backgroundColor: '#fff',
    position: 'fixed',
    zIndex: 2000,
  },
  bodyCandidateProfileDrawer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '100vh',
  },
  profileTab: {
    position: 'relative',
    marginTop: '-2%',
  },
  tabRoot: {
    [theme.breakpoints.up(380)]: {
      minWidth: '125px !important',
    },
  },
  tabRootPlacement: {
    [theme.breakpoints.up(380)]: {
      minWidth: '100px',
      maxWidth: '100px',
    },
  },
  profileDisplay: {
    display: 'flex',
  },
  orderBackground: {
    backgroundColor: `${theme.palette.framework.system.white} !important`,
  },
  contactsBackground: {
    backgroundColor: theme.palette.framework.system.backgroundLightGrey,
  },
  avatarOrderProfile: {
    fontSize: '14px !important',
    textAlign: 'center',
  },
  headerLinkContent: {
    fontSize: 16,
    fontWeight: 450,
    margin: '0 5%',
  },
  candidateStatus: {
    display: 'inline',
    fontSize: '14px',
    borderRadius: '15px',
    padding: '3px 10px 5px 10px',
    letterSpacing: '0',
    color: '#FFF',
    background: '#006fb9',
    marginLeft: '5px',
  },
  candidateSkill: {
    color: '#797979',
    fontSize: '15px',
    paddingTop: '1px',
  },
  showSkill: {
    color: '#006FB9',
    fontSize: '15px',
    fontWeight: 600,
    paddingLeft: '5px',
  },
  contactAction: {
    display: 'grid',
  },
  address: {
    color: '#797979',
    fontSize: '15px',
    paddingLeft: '10px',
  },

  goesBy: {
    width: '100vw',
    fontWeight: 400,
  },
  candidateInfo: {
    padding: '0 0 10px 25px',
  },
  lastContacted: {
    background: '#E3DFDF',
    borderRadius: '5px',
    maxWidth: '150px',
    padding: '2px 8px',
    display: 'inline-block',
  },
  lastContactedIcon: { fontSize: '20px', paddingRight: '3px' },

  contactedText: {
    display: 'contents',
    fontSize: '12px',
    fontWeight: 700,
    paddingLeft: '5px',
  },
  brand: {
    fontWeight: 'bold',
    color: '#333',
    fontSize: 14,
  },
  recruiterAvatar: {
    background: '#888',
    color: '#fff',
    height: '22px',
    width: '22px',
    display: 'inline-flex',
    fontSize: 9,
  },
  recruiterName: {
    paddingLeft: '8px',
    color: '#333',
    fontSize: 13,
  },
  notificationIcon: {
    float: 'left',
    marginRight: '7px',
  },
  brandDivider: {
    marginTop: 45,
    marginBottom: 10,
    width: 350,
    height: 2,
  },
  candidateKpi: {
    marginTop: 15,
  },
  tooltipArrow: {
    color: '#ffffff',
    opacity: 100,
    '&::before': {
      boxShadow: '0px 3px 6px #00000029',
      border: '1px solid #8C8C8C',
    },
  },
  tooltipPlacementBottom: {
    margin: 0,
  },
  tooltipBackground: {
    backgroundColor: '#ffffff',
    color: '#0D2A3B',
    opacity: 1,
    textAlign: 'left',
    font: '12px/30px Roboto',
    letterSpacing: '0px',
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #8C8C8C',
    lineHeight: '1rem',
  },

  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50vh',
  },
  drawerArrow: {
    position: 'relative',
    padding: '0',
    margin: '0',
    'font-size': '1.2rem',
    marginLeft: '1rem',
    top: '10px',
    cursor: 'pointer',
    color: '#888',
    marginBottom: '20px',
  },
  viewDetails: {
    background: '#FFF',
    position: 'fixed',
    bottom: '0px',
    height: '70px',
    width: 'inherit',
  },
  viewDetailsBtn: {
    height: '35px',
    fontSize: '12px',
    left: '120px',
    top: '15px',
  },
  inlineIcon: {
    'font-size': '1.25rem',
    marginTop: '3px',
  },
  regionContainer: {
    wordBreak: 'break-all',
    fontSize: '14px',
    padding: '0px 0px 0px 6px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0px',
    backgroundColor: `${theme.palette.framework.system.white} !important`,
  },
  filledOverride: {
    backgroundColor: theme.palette.framework.system.white,
    '&:hover': {
      backgroundColor: theme.palette.framework.system.whisper,
    },
  },
  regionTitle: {
    fontSize: '12px !important',
    color: theme.palette.system.dimGrey,
    fontWeight: 400,
  },
  contactHeader: {
    padding: '16px 0px'
  }
}));

interface IAdditionalSkillProps {
  count: number;
  skills: string[];
}

enum TabValue {
  contactsTab = 0,
}

const RegionContainer = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { locationRegionName, locationRegionId, placementEndDate, placementId } =
    useSelector(selectPlacementHeaderData);
  const { currentPlacementStatus } = useSelector(selectPlacementStatus);

  const editRegion = useSelector(selectEditPlacementRegion);
  const dispatch = useDispatch();

  const getDisplayedRegion = () => {
    let displayedRegion;
    if (editRegion?.id) {
      displayedRegion = { name: editRegion?.name, id: editRegion?.value };
    } else {
      displayedRegion = { name: locationRegionName, id: locationRegionId };
    }
    return {
      id: displayedRegion?.value,
      name: displayedRegion?.name ?? '',
    };
  };

  const ignoreLookupSearchForSpecialCharacter = (searchKey: string) => {
    const lastCharacter = searchKey?.slice(-1);
    const specialCharacterPattern = /[!@#$%^&*()_+[\]{};:'"\\|,.<>/?]/;
    return specialCharacterPattern.test(lastCharacter);
  };

  const onChange = (input: { id: number; name: string; value: string }) => {
    dispatch(placementDetailsAction.editPlacementRegion({ id: input.id, name: input.name }));
  };

  const onCancel = () => {
    dispatch(placementDetailsAction.editPlacementRegion({ undefined }));
  };

  const fetchOptions = async (request: string) => {
    if (ignoreLookupSearchForSpecialCharacter(request)) return;
    return await getSearchLookups('placementregion', request ?? '');
  };

  const handleSavePlacementRegion = () => {
    const payload = {
      regionId: editRegion?.id,
      regionName: editRegion?.name,
    };
    dispatch(
      placementDetailsAction.updatePlacementRegion({
        ...payload,
      }),
    );
  };

  // activityTypeId - 5 - Assignment
  // 5 23 On Assignment
  // 5 24 Assignment Complete
  // 5 25 Canceled by Traveler
  // 5 26 Canceled by Facility
  // 5 27 Canceled by AMH
  // 5 29 Canceled by Settlement

  const isUnlocked = React.useCallback(() => {
    if (currentPlacementStatus?.activityTypeId === activityTypeIds.assignment) {
      if (
        ([
          assignmentActivityStatusIds.assignmentComplete,
          assignmentActivityStatusIds.canceledByTraveler,
          assignmentActivityStatusIds.canceledByFacility,
          assignmentActivityStatusIds.canceledByAMH,
          assignmentActivityStatusIds.canceledBySettlement,
        ].includes(currentPlacementStatus?.activityStatusId) &&
          !placementEndDate) ||
        currentPlacementStatus?.activityStatusId === assignmentActivityStatusIds.onAssignment
      )
        return true;
      else if (
        [
          assignmentActivityStatusIds.assignmentComplete,
          assignmentActivityStatusIds.canceledByTraveler,
          assignmentActivityStatusIds.canceledByFacility,
          assignmentActivityStatusIds.canceledByAMH,
          assignmentActivityStatusIds.canceledBySettlement,
        ].includes(currentPlacementStatus?.activityStatusId)
      ) {
        const days = getNumberOfDays(new Date(placementEndDate), new Date());
        return days <= 730;
      } else {
        return false;
      }
    } else {
      return placementRegionUnlocked(currentPlacementStatus);
    }
  }, [placementId, currentPlacementStatus, placementEndDate]);

  const getLockReason = React.useCallback(() => {
    if (
      !!placementEndDate &&
      currentPlacementStatus?.activityTypeId === activityTypeIds.assignment &&
      [
        assignmentActivityStatusIds.assignmentComplete,
        assignmentActivityStatusIds.canceledByTraveler,
        assignmentActivityStatusIds.canceledByFacility,
        assignmentActivityStatusIds.canceledByAMH,
        assignmentActivityStatusIds.canceledBySettlement,
      ].includes(currentPlacementStatus?.activityStatusId) &&
      getNumberOfDays(new Date(placementEndDate), new Date()) >= 730
    ) {
      return t(`placement.placementRegionFieldError.PlacementEndDateError`);
    } else if (!!placementEndDate && getNumberOfDays(new Date(placementEndDate), new Date()) >= 730) {
      return t(`placement.placementRegionFieldError.PlacementStatusAndEndDateError`);
    } else {
      return t(`placement.placementRegionFieldError.PlacementStatusError`);
    }
  }, [placementId, currentPlacementStatus, placementEndDate]);

  const { promiseInProgress: isSaving } = usePromiseTracker({ area: 'update-placement-region' });
  return (
    <>
      <Grid container padding={'4px 0px 15px 0px'}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Typography className={classes.regionTitle}>Region</Typography>
          </Grid>
          <InlineDropdown
            placeholder="Region"
            value={getDisplayedRegion()}
            onChange={newValue => onChange(newValue)}
            fetchOptions={fetchOptions}
            onApply={handleSavePlacementRegion}
            isDirty={!!editRegion?.id && !editRegion?.helperText}
            helperText={editRegion?.helperText ?? ''}
            error={editRegion?.error ?? ''}
            isSaving={isSaving}
            lockReason={getLockReason()}
            disabled={!isUnlocked()}
            inputRootClass={{ [classes.filledOverride]: true }}
            removeGutters
            onCancel={onCancel}
          />
        </Grid>
      </Grid>
    </>
  );
};

interface ICandidateProfileDrawer {
  isDrawer?: boolean;
  closeProfileDrawer: () => void;
  openProfileDrawer: boolean;
  avatarText?: string;
  candidateName?: string;
  candidateDetails?: ICandidateDetails;
  orderDetails?: OrderHeaderSection;
  placementDetails?: any;
  isTitleALink?: boolean;
  titleLinkTo?: string;
  handleNotificationData?: (ChannelType, ContactType, manualLog?) => void;
  isSearchGrid?: boolean;
  navigate?: (direction: boolean) => void;
  viewDetails?: () => void;
  previewCandidateExists?: boolean;
  nextCandidateExists?: boolean;
  rowId?: string;
  updateCandidateContactInfo?: (rowID: string, candidateInfo: ICandidateDetails) => void;
}

export const HeaderContactDrawerOrder = (props: ICandidateProfileDrawer) => {
  const {
    isDrawer = true,
    closeProfileDrawer,
    openProfileDrawer,
    avatarText,
    candidateName: title,
    candidateDetails,
    orderDetails,
    placementDetails,
    isTitleALink,
    handleNotificationData,
    isSearchGrid = false,
    navigate,
    viewDetails,
    previewCandidateExists = false,
    nextCandidateExists = false,
    updateCandidateContactInfo,
    rowId,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const userPreference = useSelector(selectUserPreference);
  const { classes } = useStyles();
  const [tabSelectedValue, setTabSelectedValue] = useState<number>(0);
  const user = useSelector(selectUser);
  const theme = useTheme();
  const { t } = useTranslation();

  const enableVoiceCommunication = useEnableVoiceCommunication();
  const enableEmailCommunication = useEnableEmailCommunication();
  const enableSmsCommunication = useEnableSmsCommunication();

  useEffect(() => {
    return () => {
      dispatch(placementDetailsAction.setFocusedHeight(0));
    };
  }, []);

  const candidateSkills = () => {
    const verifiedSkills: string[] = [];
    const verifyDuplicateSkills: Map<string, boolean> = new Map<string, boolean>();
    candidateDetails?.verifiedSkills?.forEach(skill => {
      let res = `${skill.disciplineAbbr} - ${skill.specialtyAbbr}`;
      if (skill.subSpecialtyAbbr) res += ', ' + skill.subSpecialtyAbbr;
      if (!verifyDuplicateSkills.has(res)) {
        verifiedSkills.push(res);
        verifyDuplicateSkills.set(res, true);
      }
    });
    return verifiedSkills;
  };

  const candidateLocation = () => {
    var location = '';
    if (candidateDetails?.address?.city) {
      location = `${candidateDetails?.address?.city}`;
    }
    if (candidateDetails?.address?.city && candidateDetails?.address?.state) {
      location = `${location}, `;
    }
    if (candidateDetails?.address?.state) {
      location = `${location}${candidateDetails?.address?.state}`;
    }
    return location.length ? location : 'N/A';
  };

  const candidateInfo: ICandidateDetails | undefined = candidateDetails;
  const candidateName =
    title ||
    (!!candidateDetails &&
      Concatenate(
        [candidateDetails?.name?.first, candidateDetails?.name?.middle || '', candidateDetails?.name?.last],
        ' ',
      ));

  const additionalSkills: IAdditionalSkillProps = {
    count: candidateInfo && candidateSkills().length > 1 ? candidateSkills().length - 1 : 0,
    skills: candidateInfo && candidateInfo?.verifiedSkills ? candidateSkills().filter((x, index) => !!index) : [],
  };

  const LastContacted = () => {
    return candidateDetails?.lastContactedOn ? (
      <Tooltip
        classes={{
          tooltip: classes.tooltipBackground,
          arrow: classes.tooltipArrow,
        }}
        arrow
        title={`Last Contacted`}
      >
        <div id="lastContacted" className={`${classes.lastContacted} ${classes.notificationIcon}`}>
          {candidateDetails?.communicationLog?.results?.[0]?.channel === 'Conversation' ? (
            <MessageOutlinedIcon className={classes.lastContactedIcon} />
          ) : candidateDetails?.communicationLog?.results?.[0]?.channel === 'Voice' ? (
            <img src={VoiceLastContacted} alt="Voice last contacted" className={classes.lastContactedIcon} />
          ) : candidateDetails?.communicationLog?.results?.[0]?.channel === 'Email' ? (
            <MailOutlineIcon className={classes.lastContactedIcon} />
          ) : null}
          {
            <Typography className={classes.contactedText} variant="subtitle1">{`${moment(
              candidateDetails?.lastContactedOn,
            ).fromNow(moment(candidateDetails?.lastContactedOn).fromNow().length > 15)}`}</Typography>
          }
        </div>
      </Tooltip>
    ) : null;
  };

  const contacts = !orderDetails
    ? !placementDetails
      ? []
      : placementDetails.keyInternalPartners
    : orderDetails.contacts;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const phoneNumber =
    (!orderDetails
      ? !placementDetails
        ? candidateInfo?.primaryPhoneNumber
        : placementDetails?.facility?.phoneNumber
        ? (placementDetails.facility.countryDialingCode || '+1 ') + placementDetails.facility.phoneNumber
        : null
      : orderDetails?.location?.phoneNumber) ||
    candidateInfo?.mobilePhoneNumber ||
    'N/A';

  const handleProfileDrawerTabChange = (event, newValue) => {
    if (newValue !== tabSelectedValue) {
      setTabSelectedValue(newValue);
    }
  };

  const TabPanel = ({ children, value, index, classes, ...other }) => {
    return (
      <div
        className="tab"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            className={`paper ${classes.tabContainer} ${
              !!orderDetails || !!placementDetails
                ? tabSelectedValue === TabValue.contactsTab && !!placementDetails
                  ? classes.contactsBackground
                  : classes.orderBackground
                : ''
            }`}
          >
            {children}
          </Box>
        )}
      </div>
    );
  };

  const DrawerContent = () => {
    return (
      <>
        {
          <div className={classes.drawerRoot}>
            <LayoutGridItem container xs={12} direction="row" spacing={2} className={classes.contactHeader}>
              <LayoutGridItem container xs={10}>
                {!candidateInfo && (
                  <span className={`${classes.closeIcon} ${classes.headerLinkContent}`}>{`Key Contacts`}</span>
                )}
                {isSearchGrid && (
                  <>
                    <IconButton
                      color="primary"
                      className={classes.drawerArrow}
                      onClick={() => {
                        navigate && navigate(false);
                      }}
                      onKeyDown={() => {
                        navigate && navigate(false);
                      }}
                      disabled={!previewCandidateExists}
                      size="small"
                    >
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      className={classes.drawerArrow}
                      onClick={() => {
                        navigate && navigate(true);
                      }}
                      onKeyDown={() => {
                        navigate && navigate(true);
                      }}
                      disabled={!nextCandidateExists}
                      size="small"
                    >
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  </>
                )}
              </LayoutGridItem>
              <LayoutGridItem container xs={2} lg={2} sm={2} md={2} justifyContent={'center'}>
                <IconButton
                  color="primary"
                  onClick={closeProfileDrawer}
                  className={classes.closeIconPad}
                  data-testid="dt-id-close-drawer"
                  size="small"
                >
                  <img src={CloseIcon} alt="Close" />
                </IconButton>
              </LayoutGridItem>
            </LayoutGridItem>

            <Divider orientation="horizontal" classes={{ root: classes.divider }} />
            <Grid item xs={12} className={classes.bodyCandidateProfileDrawer} id="body-contact">
              <Grid container justifyContent="flex-end" style={{ paddingRight: '30px', paddingBottom: '50px' }}>
                {!!candidateDetails &&
                  candidateDetails?.candidateStatus &&
                  `${candidateDetails?.candidateStatus}${candidateName}`.length > 30 && (
                    <>
                      {candidateDetails?.hasAcceptedMobileTermsAndConditions === 'Y' ? (
                        <img src={PassportEnabled} alt="Passport enabled" />
                      ) : (
                        <img src={PassportDisabled} alt="Passport disabled" />
                      )}
                      <Typography className={classes.candidateStatus}>{candidateDetails?.candidateStatus}</Typography>
                    </>
                  )}
              </Grid>
              <Grid item xs={12} className={classes.profileSegment}>
                <Grid item xs={!!candidateDetails ? 2 : 3}>
                  <Avatar
                    className={`${!!candidateDetails ? classes.candidateAvatarProfile : classes.avatarProfile} ${
                      !!orderDetails || !!placementDetails ? classes.avatarOrderProfile : ''
                    }`}
                  >
                    {!!candidateDetails
                      ? Concatenate(
                          [candidateDetails.name?.first?.charAt(0), candidateDetails.name?.last?.charAt(0)],
                          '',
                        )
                      : avatarText}
                  </Avatar>
                </Grid>
                <Grid
                  item
                  xs={!!candidateDetails ? 10 : 9}
                  className={classes.profileDisplay}
                  justifyContent="space-between"
                >
                  <Grid item>
                    <div className={classes.profileInfo}>
                      <Grid item className={classes.profileInfoName}>
                        {isTitleALink ? (
                          <Link href={`/facility/${placementDetails?.facility?.id}`}>
                            {title && EllipsesTextWithToolTip(title as string, placementDetails ? 50 : 15)}
                          </Link>
                        ) : !!candidateDetails ? (
                          candidateName
                        ) : (
                          title && EllipsesTextWithToolTip(title as string, 15)
                        )}
                      </Grid>
                      {!orderDetails && !placementDetails && (
                        <Grid item className={classes.profileInfoID}>{`CID ${candidateDetails?.travelerId}`}</Grid>
                      )}
                    </div>
                    <div>
                      {placementDetails && (
                        <Grid item className={classes.details}>
                          <CallOutlinedIcon color="disabled" fontSize="small" />
                          <span id={'candidateSideBarPopupHeaderPhone'} className={classes.detailsText}>
                            {phoneNumber}
                          </span>
                        </Grid>
                      )}
                      {orderDetails && (
                        <Grid item className={classes.details}>
                          <CallOutlinedIcon color="disabled" fontSize="small" />
                          <span id={'candidateSideBarPopupHeaderPhone'} className={classes.detailsText}>
                            {phoneNumber}
                          </span>
                        </Grid>
                      )}
                    </div>
                    <div>
                      {placementDetails && (
                        <Grid item className={classes.details}>
                          <HomeIconOutlined color="disabled" fontSize="small" />
                          <span className={`${classes.detailsText} ${classes.colorOveride}`}>
                            {Concatenate(
                              [
                                placementDetails?.facility?.address1 || '',
                                placementDetails?.facility?.address2 || '',
                                placementDetails?.facility?.city || '',
                                placementDetails?.facility?.state || '',
                                placementDetails?.facility?.postalCode || '',
                                placementDetails?.facility?.country || '',
                              ],
                              ', ',
                            )}
                          </span>
                        </Grid>
                      )}
                      {placementDetails && (
                        <Grid item className={classes.details}>
                          <Grid item xs={12} className={classes.details}>
                            <PublicIcon color="disabled" fontSize="small" className={classes.inlineIcon} />
                            <Grid item xs={12} className={`${classes.regionContainer}`}>
                              <RegionContainer />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {orderDetails && (
                        <Grid item className={classes.details}>
                          <HomeIconOutlined color="disabled" fontSize="small" />
                          <span className={`${classes.detailsText} ${classes.colorOveride}`}>
                            {Concatenate(
                              [orderDetails?.location?.addressLine1 || '', orderDetails?.location?.addressLine2 || ''],
                              ', ',
                            )}
                          </span>
                        </Grid>
                      )}
                    </div>
                  </Grid>
                  {!candidateDetails && (
                    <Grid item>
                      <MoreVertIcon />
                    </Grid>
                  )}
                  {!!candidateDetails && `${candidateDetails?.candidateStatus}${candidateName}`.length < 30 && (
                    <Grid item>
                      {candidateDetails?.hasAcceptedMobileTermsAndConditions === 'Y' ? (
                        <img src={PassportEnabled} alt="Passport enabled" />
                      ) : (
                        <img src={PassportDisabled} alt="Passport disabled" />
                      )}
                      <Typography className={classes.candidateStatus}>
                        {candidateDetails?.candidateStatus as string}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {!!candidateDetails && (
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  id="candidateHeader"
                  className={classes.candidateInfo}
                >
                  {candidateDetails?.goesBy && (
                    <Typography
                      className={classes.goesBy}
                      variant="subtitle1"
                    >{`Goes by "${candidateDetails?.goesBy}"`}</Typography>
                  )}
                  <div style={{ display: 'flex' }}>
                    {candidateDetails?.verifiedSkills?.length ? (
                      <Typography variant="body2" className={classes.candidateSkill}>
                        {Concatenate(
                          [
                            candidateDetails?.verifiedSkills[0]?.disciplineAbbr,
                            candidateDetails?.verifiedSkills[0]?.specialtyAbbr,
                          ],
                          ', ',
                        )}
                      </Typography>
                    ) : (
                      MISSING_FIELD
                    )}
                    {additionalSkills.count && candidateDetails ? (
                      <CustomTooltip
                        disableMaxWidth={true}
                        tooltipContent={additionalSkills.skills.map((skill: string) => (
                          <div>{`• ${skill}`}</div>
                        ))}
                      >
                        <div className={classes.showSkill}>{`+${additionalSkills.count}`}</div>
                      </CustomTooltip>
                    ) : null}
                    <Typography className={classes.address}>
                      {`• `}
                      <LocationOnIcon color="error" fontSize="small" />
                      {candidateLocation()}
                    </Typography>
                  </div>
                  <div className={classes.candidateKpi}>
                    <div className={classes.notificationIcon}>
                      {enableEmailCommunication ? (
                        handleNotificationData && candidateDetails?.primaryEmail ? (
                          <CustomTooltip
                            tooltipPlacementBottom
                            disableMaxWidth={true}
                            tooltipContent={
                              <span className={classes.tooltip}>
                                <span>{candidateDetails?.primaryEmail}</span>{' '}
                              </span>
                            }
                          >
                            <img
                              src={MailBoxIcon}
                              alt="MailBox"
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => handleNotificationData(ChannelType.email, ContactType.primary)}
                            />
                          </CustomTooltip>
                        ) : handleNotificationData && candidateDetails?.secondaryEmail ? (
                          <CustomTooltip
                            tooltipPlacementBottom
                            disableMaxWidth={true}
                            tooltipContent={
                              <span className={classes.tooltip}>
                                <span>{candidateDetails?.secondaryEmail}</span>{' '}
                              </span>
                            }
                          >
                            <img
                              src={MailBoxIcon}
                              alt="MailBox"
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => handleNotificationData(ChannelType.email, ContactType.secondary)}
                            />
                          </CustomTooltip>
                        ) : (
                          <CustomTooltip
                            tooltipPlacementBottom
                            disableMaxWidth={true}
                            tooltipContent={
                              <span className={classes.tooltip}>
                                <span>{`No email on file`}</span>{' '}
                              </span>
                            }
                          >
                            <img src={EmailDisabled} alt="Email disabled" />
                          </CustomTooltip>
                        )
                      ) : (
                        <img src={EmailDisabled} alt="Email disabled" />
                      )}
                    </div>
                    <div className={classes.notificationIcon}>
                      {enableVoiceCommunication ? (
                        handleNotificationData &&
                        candidateDetails?.primaryPhoneNumber &&
                        userPreference &&
                        userPreference?.deviceList &&
                        userPreference?.deviceList?.length !== 0 &&
                        userPreference?.deviceLine.length ? (
                          <CustomTooltip
                            tooltipPlacementBottom
                            disableMaxWidth={true}
                            tooltipContent={
                              <span className={classes.tooltip}>
                                <span>{candidateDetails?.primaryPhoneNumber}</span>{' '}
                              </span>
                            }
                          >
                            <img
                              src={PhoneIcon}
                              alt="Phone"
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => handleNotificationData(ChannelType.voice, ContactType.primary)}
                            />
                          </CustomTooltip>
                        ) : handleNotificationData &&
                          candidateDetails?.mobilePhoneNumber &&
                          userPreference &&
                          userPreference?.deviceList &&
                          userPreference?.deviceList.length !== 0 &&
                          userPreference?.deviceLine.length !== 0 ? (
                          <CustomTooltip
                            tooltipPlacementBottom
                            disableMaxWidth={true}
                            tooltipContent={
                              <span className={classes.tooltip}>
                                <span>{candidateDetails?.mobilePhoneNumber}</span>{' '}
                              </span>
                            }
                          >
                            <img
                              src={PhoneIcon}
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => handleNotificationData(ChannelType.voice, ContactType.secondary)}
                              alt="PhoneIcon"
                            />
                          </CustomTooltip>
                        ) : (
                          <CustomTooltip
                            tooltipPlacementBottom
                            tooltipContent={
                              (userPreference &&
                                userPreference?.deviceList &&
                                userPreference.deviceList?.length === 0) ||
                              userPreference.deviceLine?.length === 0 ? (
                                <>
                                  <span>{`No device found for the user`}</span>
                                </>
                              ) : (
                                <span className={classes.tooltip}>
                                  <span>{`No number on file`}</span>{' '}
                                </span>
                              )
                            }
                          >
                            <img src={PhoneDisabled} alt="PhoneDisabled" />
                          </CustomTooltip>
                        )
                      ) : (
                        <img src={PhoneDisabled} alt="PhoneDisabled" />
                      )}
                    </div>
                    <div className={classes.notificationIcon}>
                      {enableSmsCommunication ? (
                        !user.userInfo?.phoneNumber ? (
                          <MessageDisabledTooltip>
                            <img src={MessageDisabled} alt="MessageDisabled" />
                          </MessageDisabledTooltip>
                        ) : (
                          <CustomTooltip
                            tooltipPlacementBottom
                            tooltipContent={
                              <span className={classes.tooltip}>
                                {candidateDetails?.primaryPhoneNumber || candidateDetails?.mobilePhoneNumber ? (
                                  <>
                                    <span>{candidateDetails?.primaryPhoneNumber || ''}</span>{' '}
                                    <span>{candidateDetails?.mobilePhoneNumber || ''}</span>
                                  </>
                                ) : (
                                  <span>{`No number on file`}</span>
                                )}
                              </span>
                            }
                          >
                            {candidateDetails?.primaryPhoneNumber ? (
                              <img
                                src={MessageIcon}
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  handleNotificationData && handleNotificationData(ChannelType.sms, ContactType.primary)
                                }
                                alt="MessageIcon"
                              />
                            ) : candidateDetails?.mobilePhoneNumber ? (
                              <img
                                src={MessageIcon}
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  handleNotificationData &&
                                  handleNotificationData(ChannelType.sms, ContactType.secondary)
                                }
                                alt="MessageIcon"
                              />
                            ) : (
                              <img src={MessageDisabled} alt="MessageDisabled" />
                            )}
                          </CustomTooltip>
                        )
                      ) : (
                        <img src={MessageDisabled} alt="MessageDisabled" />
                      )}
                    </div>

                    <LastContacted />

                    <Tooltip
                      classes={{
                        tooltip: classes.tooltipBackground,
                        arrow: classes.tooltipArrow,
                      }}
                      arrow
                      title={`Call Back`}
                    >
                      <div id="callBack" className={`${classes.lastContacted} ${classes.notificationIcon}`}>
                        <CallBackIcon
                          color={
                            Date.parse(candidateInfo?.callBackDate || '') - Date.parse(new Date().toString()) < 0
                              ? 'error'
                              : 'primary'
                          }
                        />
                        <Typography className={classes.contactedText} variant="subtitle1">
                          {candidateInfo?.callBackDate
                            ? `${moment(candidateInfo?.callBackDate).format('MM/DD/YYYY')} `
                            : `N/A`}
                        </Typography>
                      </div>
                    </Tooltip>
                    <div>
                      <Divider classes={{ root: classes.brandDivider }} />
                    </div>
                  </div>
                  <Grid container style={{ paddingBottom: '10px' }}>
                    <Grid item xs={5} style={{ display: 'grid' }}>
                      <Typography variant="body2">{`Brand`}</Typography>
                      <span className={classes.brand}>{candidateInfo?.brand ?? MISSING_FIELD}</span>
                    </Grid>

                    <Grid item xs={6} style={{ display: 'grid' }}>
                      <Typography variant="body2">{`Recruiter`}</Typography>
                      <span className={classes.brand}>
                        {(candidateInfo?.recruiter.first || candidateInfo?.recruiter.last) && (
                          <Avatar className={classes.recruiterAvatar}>
                            {nameInitial(
                              Concatenate(
                                [candidateInfo?.recruiter.first as string, candidateInfo?.recruiter.last as string],
                                ' ',
                              ),
                            )}
                          </Avatar>
                        )}
                        <span className={classes.recruiterName}>
                          {candidateInfo?.recruiter && (candidateInfo?.recruiter.first || candidateInfo?.recruiter.last)
                            ? Concatenate(
                                [candidateInfo?.recruiter.first as string, candidateInfo?.recruiter.last as string],
                                ' ',
                              ) || MISSING_FIELD
                            : MISSING_FIELD}
                        </span>
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <div className={classes.profileTab}>
                  <Paper elevation={0} className={`paper ${classes.tabContainer} `}>
                    <Tabs
                      value={tabSelectedValue}
                      classes={{ indicator: 'tab-indicator profileTab' }}
                      onChange={handleProfileDrawerTabChange}
                      variant="fullWidth"
                    >
                      {(!!orderDetails || !!placementDetails) && (
                        <Tab
                          className={tabSelectedValue === 0 ? 'active-tab-profile' : 'default-tab profileTab'}
                          label={
                            !!placementDetails
                              ? t(`placement.placementDrawaer.contacts`)
                              : t(`placement.placementDrawaer.key-internal`)
                          }
                          classes={{ root: !!placementDetails ? classes.tabRootPlacement : classes.tabRoot }}
                        />
                      )}
                      {!orderDetails && !placementDetails && (
                        <Tab
                          className={tabSelectedValue === 0 ? 'active-tab-profile' : 'default-tab profileTab'}
                          label={'Summary'}
                          classes={{ root: classes.tabRoot }}
                        />
                      )}
                      {!placementDetails && (
                        <Tab
                          className={tabSelectedValue === 1 ? 'active-tab-profile' : 'default-tab profileTab'}
                          label={
                            !!orderDetails
                              ? t(`placement.placementDrawaer.key-client`)
                              : t(`placement.placementDrawaer.contact`)
                          }
                          classes={{ root: classes.tabRoot }}
                        />
                      )}
                    </Tabs>
                  </Paper>
                  <TabPanel value={tabSelectedValue} index={0} classes={classes}>
                    {!!orderDetails ? (
                      <OrderTab contactDetails={contacts} />
                    ) : !!placementDetails ? (
                      <PlacementContactsTab />
                    ) : !!candidateInfo ? (
                      <>
                        <CandidateSummaryTab
                          candidateDetails={candidateInfo}
                          viewDetails={!!candidateDetails && isSearchGrid ? viewDetails : undefined}
                        />
                        {viewDetails &&
                          history.location.pathname !==
                            '/candidate/' +
                              `${candidateDetails?.travelerId}` +
                              '/' +
                              `${candidateDetails?.brandId}` && (
                            <div className={classes.viewDetails}>
                              <Divider />
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={viewDetails}
                                onKeyPress={viewDetails}
                                className={classes.viewDetailsBtn}
                              >{`View Full Details`}</Button>
                            </div>
                          )}
                      </>
                    ) : null}
                  </TabPanel>
                  <TabPanel value={tabSelectedValue} index={1} classes={classes}>
                    {!!orderDetails ? null : !!placementDetails ? (
                      <ContactList contacts={placementDetails} enableCommunication clientContact />
                    ) : (
                      <>
                        <ContactTab
                          onClose={closeProfileDrawer}
                          isSearchGrid={isSearchGrid}
                          rowId={rowId}
                          candidateDetails={candidateInfo}
                          handleNotificationData={handleNotificationData}
                          updateCandidateContactInfo={updateCandidateContactInfo}
                          isViewDetails={viewDetails ? true : false}
                        />
                        {viewDetails && (
                          <div className={classes.viewDetails}>
                            <Divider />
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={viewDetails}
                              onKeyPress={viewDetails}
                              className={classes.viewDetailsBtn}
                            >{`View Full Details`}</Button>
                          </div>
                        )}
                      </>
                    )}
                  </TabPanel>
                  <TabPanel value={tabSelectedValue} index={2} classes={classes}>
                    {null}
                  </TabPanel>
                </div>
              </Grid>
            </Grid>
          </div>
        }
      </>
    );
  };

  return isDrawer ? (
    <DrawerFitted
      open={openProfileDrawer}
      onDrawerClose={closeProfileDrawer}
      width={425}
      top={0}
      backgroundColor={theme.palette.framework.system.white}
    >
      <DrawerContent />
    </DrawerFitted>
  ) : (
    <DrawerContent />
  );
};
