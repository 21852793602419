import { ComparisionTableColumnTypes } from "@AMIEWEB/Common/ComparisionTable/types";
import HeaderCell from "../OrderComparisionModal/OrderComparisionTableCells/HeaderCell";
import SkillSetsCell from "../OrderComparisionModal/OrderComparisionTableCells/SkillSetsCell";

export const getOrderComparisionTableRows = () => [
    {
        name: 'id',
        displayText: '',
        height: 52,
        renderCell: HeaderCell,
    },
    {
        name: 'skillsets',
        displayText: 'Skill Sets',
        height: 113,
        renderCell: SkillSetsCell,
        isExpandable: true,
        expandColumnDataType: 'Array',
    },
    {
        name: 'billRate',
        displayText: 'Bill Rate',
        height: 42,
    },
    {
        name: 'shifts',
        displayText: 'Shifts',
        height: 62,
        isExpandable: true,
    },
    {
        name: 'city',
        displayText: 'City',
        height: 62,
        isExpandable: true,
    },
    {
        name: 'state',
        displayText: 'State',
        height: 62,
        isExpandable: true,
    },
    {
        name: 'distance',
        displayText: 'Distance (mi.)',
        height: 42,
    },
    {
        name: 'notes',
        displayText: 'Notes',
        height: 115,
        isExpandable: true,
    },
    {
        name: 'accountManager',
        displayText: 'Account Manager',
        height: 42,
    },
    {
        name: 'facility',
        displayText: 'Facility',
        height: 50,
    },
    {
        name: 'startdate',
        displayText: 'Start Date',
        height: 50,
    },
    {
        name: 'positions',
        displayText: 'Positions',
        height: 42,
    },
    {
        name: 'maxFilesSent',
        displayText: 'MFS',
        height: 42,
    },

];