import { Grid } from 'amn-ui-core';
import { selectUser } from 'oidc/user.selectors';
import { isReadOnly } from 'oidc/userRoles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedUserRoles, selectUserRolesOptionsList } from 'store/redux-store/placement-requests/selectors';
import { placementRequestsAction } from 'store/redux-store/placement-requests/slice';
import { makeStyles } from 'tss-react/mui';
import { ListSelector } from '../../common/ListSelector';
import { PageActions } from '../../common/PageActions';
import { AutoTaskDetails } from './AutoTaskDetails';
const useStyles = makeStyles()(theme => ({
  pageActionsContainer: {
    flexGrow: 0,
  },
  headingStyle: {
    fontSize: '16Spx',
    fontWeight: 500,
    marginTop: '12px',
    marginBottom: '12px',
  },
  billRateModificationContainer: {
    flexDirection: 'column',
    height: '497px',
    overflow: 'hidden',
  },
  rolesConatiner: { flex: 1, overflow: 'hidden' },
}));
export interface IUserRoles {
  id: string;
  description: string;
}

export const UserRolesSection = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userRolesOptionsList: IUserRoles[] = useSelector(selectUserRolesOptionsList);
  const appUser = useSelector(selectUser);
  const selectedUserRoles = useSelector(selectSelectedUserRoles);
  const { promiseInProgress: optionsListLoader } = usePromiseTracker({
    area: 'fetch-auto-task-employee-roles',
    delay: 0,
  });
  const { promiseInProgress: chipsGroupLoader } = usePromiseTracker({ area: 'fetch-auto-task-grid-data', delay: 0 });

  const handleSelection = selectedItems => {
    dispatch(placementRequestsAction.business_setAssignedUserRoles(selectedItems));
  };

  const getTitle = (title: string) => {
    switch (title) {
      case 'list':
        return t(`userManagement.availableRole`) + `(${userRolesOptionsList.length})`;

      case 'chip':
        return t(`userManagement.selectedRloes`) + `(${selectedUserRoles.length})`;
      default:
        break;
    }
  };
  const toolTipContent = {
    messageOne: t('autoTaskManagement.roleDetails.userRoleTooltipMessage'),
    messageTwo: t('autoTaskManagement.roleDetails.assignedRoleTooltipMessage'),
  };
  return (
    <>
      <Grid container classes={{ root: classes.billRateModificationContainer }}>
        {/* Header Section */}
        <Grid item>
          <AutoTaskDetails isDisabled={isReadOnly(appUser?.userInfo?.roles || [])} />
        </Grid>
        <Grid item className={classes.headingStyle}>
          {t('autoTaskManagement.roleDetails.title')}
        </Grid>
        {/* Body Section */}
        <Grid item classes={{ root: classes.rolesConatiner }}>
          <ListSelector
            list={userRolesOptionsList}
            selectedValues={selectedUserRoles}
            handleSelection={handleSelection}
            listTitle={getTitle('list')}
            chipsGroupTitile={getTitle('chip')}
            toolTipContent={toolTipContent}
            isDisabled={isReadOnly(appUser?.userInfo?.roles || [])}
            helperText={t('autoTaskManagement.roleDetails.noRoleAssigned')}
            optionsListLoader={optionsListLoader}
            chipsGroupLoader={chipsGroupLoader || optionsListLoader}
            isSelectAllReq  
          />
        </Grid>
        <Grid item classes={{ root: classes.pageActionsContainer }}>
          <PageActions />
        </Grid>
      </Grid>
    </>
  );
};
