import { makeStyles } from 'tss-react/mui';
import React from 'react';
import DocViewer, {
  PDFRenderer,
  MSDocRenderer,
  IHeaderOverride,
  PNGRenderer,
  JPGRenderer,
  ImageProxyRenderer,
  BMPRenderer,
  MSGRenderer,
  TIFFRenderer,
} from 'react-doc-viewer';
import { deCodeText, isFileTypeHtml, isFileTypeText } from './Helpers/DocHelpers';
import PDFPreviewRenderer from './Renderers/PDFPreviewRenderer';
import DocTXTRenderer from './Renderers/DocTxtRenderer';

const useStyles = makeStyles()(theme => ({
  documentViewItem: {
    '& canvas': {
      width: '100% !important',
      height: '100% !important',
      position: 'relative',
      left: '-40px',
    },
  },
}));

//Here we can overide the document viewer header as per our need
const docViewerHeader: IHeaderOverride = (state, previousDocument, nextDocument) => {
  if (!state.currentDocument || state.config?.header?.disableFileName) {
    return null;
  }

  return <></>;
};

//
// ─── RENDER HTML ────────────────────────────────────────────────────────────────
//
// const HTMLRender = props => {
//   return (
//     <iframe
//       src={props.uri}
//       style={{ width: props.preview ? '250px' : '100%', height: props.preview ? '200px' : '550px' }}
//       //className={props.preview ? classes.thumbnail : ''}
//       title="html"
//     />
//   );
// };

//
// ─── RENDER PDF ────────────────────────────────────────────────────────────────
//
// const PDFRender = props => {
//   return (
//     <embed
//       src={props.fileData}
//       style={{ width: props.preview ? '250px' : '100%', height: props.preview ? '200px' : '550px' }}
//       title="pdf"
//       id="pdf-render"
//     />
//   );
// };

const DocViewerFit = props => {
  const fileType = props?.docs[0].fileType;
  console.log(props);

  const fileData = isFileTypeText(fileType) ? deCodeText(props?.docs[0].fileData) : props?.docs[0].fileData;

  const uri = isFileTypeHtml(fileType) ? props?.docs[0].uri : '';
  const doc = [{ uri: uri, fileType: fileType, fileData: fileData }];
  return <>{<DocViewerLoad isPreview={props?.preview} docs={doc} />}</>;
};

//
// ─── LOAD DIFFERENT DOCUMENT TYPES ──────────────────────────────────────────────
//
const DocViewerLoad = (props: any) => {
  const { classes } = useStyles();
  const PDFRender = props.isPreview ? PDFPreviewRenderer : PDFRenderer;
  return (
    <>
      <DocViewer
        documents={props.docs}
        style={{ zoom: '100%', width: props.isPreview ? '200px' : '', height: props.isPreview ? '200px' : '' }}
        className={classes.documentViewItem}
        pluginRenderers={[
          PNGRenderer,
          ImageProxyRenderer,
          BMPRenderer,
          JPGRenderer,
          MSDocRenderer,
          MSGRenderer,
          TIFFRenderer,
          DocTXTRenderer,
          PDFRender,
        ]}
        config={{ header: { overrideComponent: docViewerHeader } }}
      />
    </>
  );
};

export default DocViewerFit;
