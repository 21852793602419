import { takeLatest, put, call, getContext } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { jobInterestActions } from './JobInterest.redux';
import { trackPromise } from 'react-promise-tracker';
import { jobInterestFlattenData } from '../../helper/gridHelper';
import { SkillSet } from 'app/models/Candidate/CandidateJobInterest';
import { TFunction } from 'i18next';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { isPositiveNumber } from 'app/helpers/componentHelpers';
import { getCandidateJobInterestFilterOrderBase } from 'app/services/CandidateServices/CandidateServices';
import { CandidateService } from 'app/services/CandidateServices/candidate-service';

const TrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'job-interest-call');

/**
 * Candidate job preference get action
 * @param action
 */
export function* getJobInterests(
  action: PayloadAction<{
    travelerId: number;
    brandId: number;
    limit: number;
    offset: number;
    filterEnable: boolean;
    orderStatus?: string;
    interestDate?: string;
    fromDate?: string;
    toDate?: string;
    states?: string;
    skillSets?: SkillSet[];
    t: TFunction;
  }>,
) {
  try {
    const response = yield call(TrackWrapper, getCandidateJobInterestFilterOrderBase, {
      travelerId: action.payload.travelerId,
      brandId: action.payload.brandId,
      limit: action.payload.limit,
      offset: action.payload.offset,
      filterEnable: action.payload.filterEnable,
      orderStatus: action.payload.orderStatus,
      fromDate: action.payload.fromDate,
      toDate: action.payload.toDate,
      states: action.payload.states,
      skillSets: action.payload.skillSets,
    });
    if (response) {
      if (response.status === 500) yield put(jobInterestActions.setServiceFailure(true));
      else {
        const locations = response.displayJobInterestData.map(item => item.facilityCoordinates);
        const flattenedData = jobInterestFlattenData(response.displayJobInterestData, action.payload.t);
        yield put(jobInterestActions.setJobInterests(response.displayJobInterestData));
        yield put(jobInterestActions.setGridData(flattenedData));
        yield put(jobInterestActions.setJobLocations(locations));
        yield put(jobInterestActions.setCount(response.count));
        yield put(jobInterestActions.setFilteredCount(response.filteredCount));
        yield put(
          jobInterestActions.setFilterSourceData({
            states: response.filterSource.states,
            skillSets: response.filterSource.skillSets,
          }),
        );
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getJobInterests',
        area: 'src/app/components/Candidate/CandidateProfile/CandidateJobInterestGrid/JobInterest.saga.ts',
      },
    });
  }
}

export function* getJobInterestCount(action: PayloadAction<number>) {
  try {
    if (isPositiveNumber(action.payload)) {
      const candidateService: CandidateService = yield getContext('candidateService');
      const response = yield call(TrackWrapper, candidateService.getCandidateJobInterestCount, action.payload);
      if (response) {
        if (response.status === 200)
          yield put(jobInterestActions.setCount(response.data.total));
        else yield put(jobInterestActions.setServiceFailure(true));
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getJobInterestCount',
        area: 'src/app/components/Candidate/CandidateProfile/CandidateJobInterestGrid/JobInterest.saga.ts',
      },
    });
  }
}

export function* jobInterestSaga() {
  yield takeLatest(jobInterestActions.getJobInterestAction.type, getJobInterests);
  yield takeLatest(jobInterestActions.getJobInterestCountAction.type, getJobInterestCount);
}
