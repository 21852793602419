import styledComponent from 'styled-components';
import { BestTimeToCall } from 'app/components/Placement/PlacementDetails/Edit/Components/BestTimeToCall';
import { editableFields, editAccessor } from 'app/components/Placement/PlacementDetails/editUtils';
import { colors } from 'styles/styleVariables';
import { Typography } from 'amn-ui-core';
import { missingField } from 'app/constants';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RequirementsRow } from '../../TableComponents/TableComponents';
import {
  selectServiceFailures,
  selectSubmissionInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { usePromiseTracker } from 'react-promise-tracker';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';

export const Interview = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    orderReq: {
      order: { miceDetails: orderReqItem },
      candidate: { miceDetails: candidateReqItem },
    },
    requirementFilter,
  } = useSelector(selectSubmissionInfo);

  const { promiseInProgress } = usePromiseTracker({
    area: 'pplacement-submission-mice-details-order-requirement',
    delay: 0,
  });
  const { miceDetailsOrderRequirement: serviceFailed } = useSelector(selectServiceFailures);
  const { placementId } = useContext(AddOnContext);
  const { display } = useRequirementFilter('submission-order', requirementFilter, orderReqItem?.type);

  return display ? (
    <RequirementsRow
      id="placement-submission-order-interview"
      title={t('placement.profile.summary.submission.order.interview.title')}
      requirementCell={<Typography variant="body1">{missingField}</Typography>}
      candidateCell={<RowValue candidate={candidateReqItem} t={t} />}
      loading={promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() =>
        dispatch(placementSummaryActions.getMiscDetailsOrderRequirement({ placementId, isRetryingService: true }))
      }
    />
  ) : null;
};

const RowValue = ({ candidate, t }) => (
  <div>
    <Typography variant="body1">{t('placement.profile.summary.submission.order.interview.line1')}</Typography>
    <BestTimeToCall id={`${editAccessor}-${editableFields.bestTimeToCall}`} />
    <br />
    <Typography variant="body1">{t('placement.profile.summary.submission.order.interview.line2')}</Typography>
    <CustomLink>
      <Typography variant="body1">{candidate?.data ? candidate?.data?.phoneNumber : missingField}</Typography>
    </CustomLink>
  </div>
);

const CustomLink = styledComponent.div`
  color: ${colors.amnBlue};
  text-decoration: none;
  font-weight: 450;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
