import React from 'react';
import styledComponent from 'styled-components';
import { Select as AMNSelect, MenuItem, FormControl, Box } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { colors } from 'styles/styleVariables';
import { useSelector } from 'react-redux';
import { IPlacementStatus, IPlacementStatusRequirement } from 'app/models/Placement/PlacementDetails';
import { DefaultCurrentPlacementStatus, isInitialStatus } from '../../NextPlacementStatus/StatusDefaults';
import { usePlacementStatusDisable } from '@AMIEWEB/Placement/NextPlacementStatus/Helper';
import { checkIfPriorStatusRequirementsFail } from '@AMIEWEB/Placement/NextPlacementStatus/PlacementStatus';
import { CustomTooltip } from '@AMIEWEB/Common';
import { selectNewPlacementChoice } from 'store/redux-store/new-placement/selectors';
import { PlacementOrderType } from 'app/models/Placement/PlacementDetails';
import { useFormContext } from 'react-hook-form';
import { TimeOffMode } from '@AMIEWEB/Common/TimeOffRequest';
import { useTranslation } from 'react-i18next';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import { ff_new_create_placement_modal_layout } from 'app/constants/FeatureFlags/Placement/Keys';
import { useDecision } from '@optimizely/react-sdk';

const useStyles = makeStyles<{ inlineDense: boolean }>()((theme, { inlineDense }) => ({
  placementStatusChangeDDIcon: {
    color: 'white !important',
  },
  placementStatusMenuItem: {
    textTransform: 'uppercase',
    fontSize: '12px',
  },
  placementStatusInput: {
    textTransform: 'uppercase',
    fontSize: '12px',
    paddingTop: inlineDense ? '12px' : '17px',
    paddingBottom: inlineDense ? '12px' : '16px',
    fontWeight: 500,
  },
  selectRoot: {
    color: 'white',
    fontSize: '14px',
    fontWeight: 500,
    height: inlineDense ? undefined : '33px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  rootDisabled: {
    backgroundColor: '#cccccc',
    color: 'white !important',
    '-webkit-text-fill-color': 'white !important',
    '&:hover': {
      cursor: 'not-allowed !important',
    },
  },
  select: {
    display: 'flex',
    '& fieldset': {
      borderColor: 'transparent !important',
    },
  },
  iconWrapper: inlineDense
    ? {
        top: '10px',
      }
    : {
        borderLeft: '1px solid rgba(255,255,255, 0.4)',
        borderRight: 'none',
        paddingLeft: '6px',
        paddingRight: '0px',
        height: '70%',
        marginTop: '-7px',
        display: 'flex',
        alignItems: 'center',
        top: '15px',
        '&.MuiSelect-iconOpen': {
          borderLeft: 'none',
          borderRight: '1px solid rgba(255,255,255, 0.4)',
          paddingLeft: '0px',
          paddingRight: '6px',
        },
      },
}));

interface SelectPlacementStatusProps {
  availablePlacementStatus: IPlacementStatus[];
  currentPlacementStatus: IPlacementStatus | undefined;
  failedRequirements?: IPlacementStatusRequirement;
  onStatusSelected?: (status: IPlacementStatus) => void;
  loading?: boolean;
  [key: string]: any;
}

export const SelectPlacementStatus = ({
  availablePlacementStatus,
  currentPlacementStatus = DefaultCurrentPlacementStatus,
  failedRequirements,
  onStatusSelected,
  loading,
  ...props
}: SelectPlacementStatusProps) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles({ inlineDense: props.inlineDense });
  const _onChange = event => {
    onStatusSelected?.(JSON.parse(event.target.value as string));
  };
  const { createScreenEnabled } = useSelector(selectNewPlacementChoice);
  const orderType = useSelector(selectPlacementOrderType);
  const { getValues } = useFormContext();
  const getRequestedTimeOffData = getValues('requestedTimeOff');
  const [{ enabled: newLayoutEnabled }] = useDecision(ff_new_create_placement_modal_layout);

  const isAddTimeOffValid = () => {
    return (
      getRequestedTimeOffData?.requestedTimeOffMode === TimeOffMode.AddTimeOff &&
      !getRequestedTimeOffData?.requestedTimeOff?.length &&
      newLayoutEnabled
    );
  };

  const validateDisabled = () => {
    return isAddTimeOffValid();
  };

  const validateOrderTypeAndRequestedTimeOff = () => {
    const isStrikeOrderInHsg = orderType === PlacementOrderType.StrikeOrderInHsg && createScreenEnabled;
    return isStrikeOrderInHsg || isAddTimeOffValid();
  };

  const disableControl = React.useMemo(() => {
    return (
      loading ||
      (availablePlacementStatus || []).length === 0 ||
      (isInitialStatus(currentPlacementStatus) &&
        Object.keys(failedRequirements || {}).length === availablePlacementStatus.length &&
        !createScreenEnabled) ||
      validateOrderTypeAndRequestedTimeOff()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    availablePlacementStatus,
    createScreenEnabled,
    currentPlacementStatus,
    failedRequirements,
    loading,
    orderType,
    getRequestedTimeOffData?.requestedTimeOff?.length,
    getRequestedTimeOffData?.requestedTimeOffMode,
  ]);

  return (
    <FormControl
      color="primary"
      size="small"
      variant="outlined"
      style={{ width: '100%' }}
    >
      <CustomTooltip
        tooltipContent={t('placement.placementStatusPickList.timeOffDatesNotSelected')}
        disabled={!validateDisabled()}
      >
        <Select
          IconComponent={props => (
            <Box {...props} className={cx(props.className, classes.iconWrapper)}>
              <ExpandMoreIcon />
            </Box>
          )}
          className={classes.select}
          classes={{
            select: classes.selectRoot,
            icon: classes.placementStatusChangeDDIcon,
          }}
          inputProps={{
            className: cx(classes.placementStatusInput, {
              [classes.rootDisabled]: disableControl,
            }),
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          variant="outlined"
          color="primary"
          disabled={disableControl}
          value={JSON.stringify(currentPlacementStatus)}
          onChange={_onChange}
        >
          <MenuItem
            className={classes.placementStatusMenuItem}
            style={{ display: 'none' }}
            key={JSON.stringify(currentPlacementStatus)}
            value={JSON.stringify(currentPlacementStatus)}
          >
            {createScreenEnabled && orderType !== PlacementOrderType.StrikeOrderInHsg
              ? t('placement.create.createScreen')
              : currentPlacementStatus.activityStatus}
          </MenuItem>
          {availablePlacementStatus.map((status, indx) => (
            <StatusOpt
              status={status}
              failedRequirements={failedRequirements}
              hasPriorFailedRequirements={checkIfPriorStatusRequirementsFail(
                failedRequirements,
                availablePlacementStatus[indx - 1],
              )}
              styleClasses={classes}
              key={indx.toString()}
              itemIndex={indx}
              value={JSON.stringify(status)}
              createScreenEnabled={createScreenEnabled}
            />
          ))}
        </Select>
      </CustomTooltip>
    </FormControl>
  );
};

interface IMenuProps {
  status;
  hasPriorFailedRequirements;
  failedRequirements;
  value: any;
  [key: string]: any;
  createScreenEnabled?: boolean;
}
//usePlacementStatusDisable picklist in create placement modal
const StatusOpt = React.forwardRef<HTMLLIElement, IMenuProps>((props: IMenuProps, ref) => {
  const { status, hasPriorFailedRequirements, failedRequirements, createScreenEnabled, ...rest } = props;
  const { disabled, toolTipContent } = usePlacementStatusDisable({
    status,
    hasFailedRequirements:
      ((failedRequirements || {})[`${status.activityTypeId}:${status.activityStatusId}`]?.length || 0) > 0,
    hasPriorFailedRequirements,
    manualAllowed: false,
    isCreatePlacement: true,
    failedRequirements: (failedRequirements || {})[`${status.activityTypeId}:${status.activityStatusId}`],
    createScreenEnabled: createScreenEnabled,
    allFailedRequirements: failedRequirements,
  });

  return (
    <CustomTooltip tooltipContent={toolTipContent} disabled={!disabled} placement={'left'} isStandardContent>
      <MenuItem
        {...rest}
        ref={ref}
        disabled={disabled}
        id={`placement-status-menu-item-${props.itemIndex}`}
        className={props.styleClasses.placementStatusMenuItem}
      >
        {status.activityStatus}
      </MenuItem>
    </CustomTooltip>
  );
});

const Select = styledComponent(AMNSelect)`
  background-color: ${colors.amnBlue};
  color: white;
`;
