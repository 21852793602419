import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { IUserPreferenceState } from 'app/models/Notification/Notification';
import {
  initialEditorPreference,
  initialEmailSignature,
  initialGlobalDropDown,
  initialInboxFilters,
  initialNotificationPreference,
  initialPinnedMessages,
  initialRecentHistoryNull,
  PreferenceCosmosKeys,
} from './utils';
import { businessActions } from './business.actions';
import { IRecentHistory } from 'app/models/Global/RecentHistoryModels';

export interface IEditorPreference {
  pinnedTemplates: {
    id: number;
    timeStamp: string;
  }[];
  editorWidth: number | null;
}

export interface IUserPreferenceState<V> {
  id: string;
  value?: V;
}

export interface IUserPreference {
  existing?: IDeviceLine;
  current?: IDeviceLine;
  deviceList: [];
  deviceLine: [];
  default?: boolean;
  userGridPreferences: IUserPreferenceState<any>[];
  emailSignature: IUserPreferenceState<
    {
      name: string;
      input: string;
      value: string | boolean;
    }[]
  >;
  notificationPreference: IUserPreferenceState<
    {
      name: string;
      enabled: boolean;
    }[]
  >;
  globalSearchDropDown: IUserPreferenceState<string>;
  pinnedMessages: IUserPreferenceState<any>;
  recentHistory?: IUserPreferenceState<IRecentHistory>;
  inboxFilter?: IUserPreferenceState<any[]>;
  loaded?: boolean;
  editors: {
    settingsConfirmationTemplateEditor: IUserPreferenceState<IEditorPreference>;
    settingsContractTemplateEditor: IUserPreferenceState<IEditorPreference>;
    placementConfirmationDocEditor: IUserPreferenceState<IEditorPreference>;
    placementContractDocEditor: IUserPreferenceState<IEditorPreference>;
  };
}

interface IDeviceLine {
  device?: string;
  line?: string;
}

export type EditorType =
  | 'settingsConfirmationTemplateEditor'
  | 'settingsContractTemplateEditor'
  | 'placementConfirmationDocEditor'
  | 'placementContractDocEditor';

export const initialState: IUserPreference = {
  existing: undefined,
  current: undefined,
  deviceList: [],
  deviceLine: [],
  default: true,
  userGridPreferences: [],
  emailSignature: initialEmailSignature,
  notificationPreference: initialNotificationPreference,
  globalSearchDropDown: initialGlobalDropDown,
  pinnedMessages: initialPinnedMessages,
  recentHistory: initialRecentHistoryNull,
  inboxFilter: initialInboxFilters,
  loaded: false,
  editors: {
    settingsConfirmationTemplateEditor: {
      id: PreferenceCosmosKeys.settingsConfirmationTemplateEditor,
      value: initialEditorPreference,
    },
    settingsContractTemplateEditor: {
      id: PreferenceCosmosKeys.settingsContractTemplateEditor,
      value: initialEditorPreference,
    },
    placementConfirmationDocEditor: {
      id: PreferenceCosmosKeys.placementConfirmationDocEditor,
      value: initialEditorPreference,
    },
    placementContractDocEditor: { id: PreferenceCosmosKeys.placementContractDocEditor, value: initialEditorPreference },
  },
};

const saveFilterPreferenceAction = createAction<any>('SAVE_FILTER_PREFERENCE');
const saveUserGridPreferenceAction = createAction<any>('SAVE_USER_GRID_PREFERENCE');
const savePinnedMessagePreferenceAction = createAction<any>('SAVE_PINNED_MESSAGE_PREFERENCE');
const saveGlobalSearchPreferenceAction = createAction<any>('SAVE_GLOBAL_SEARCH_PREFERENCE');
const saveEmailSignaturePreferenceAction = createAction<any>('SAVE_EMAIL_SIGNATURE_PREFERENCE');
const saveNotificationPreferenceAction = createAction<any>('SAVE_NOTIFICATION_PREFERENCE');
const saveUserDevicePreferenceAction = createAction<any>('SAVE_USER_DEVICE_PREFERENCE');
const saveUserRecentHistoryPreferenceAction = createAction<any>('SAVE_RECENT_HISTORY_PREFERENCE');
const saveInboxFiltersAction = createAction<any>('SAVE_INBOX_FILTERS');
const saveEditorPreferences = createAction<{ editor: EditorType; preference: IUserPreferenceState<IEditorPreference> }>(
  'SAVE_EDITOR_PREFERENCE',
);

const userDevicePreferenceSlice = createSlice({
  name: 'userPreferenceData',
  initialState,
  reducers: {
    setUserPreference(state, action: PayloadAction<IUserPreference>) {
      const newState = { ...state, ...action.payload };
      return newState as IUserPreference;
    },
    setUserGridPreferences(state, action: PayloadAction<IUserPreferenceState<string>[]>) {
      return { ...state, userGridPreferences: action.payload };
    },
    setGridPreferencesById(state, action: PayloadAction<IUserPreferenceState<string>>) {
      const foundIndex = state.userGridPreferences.findIndex(item => item.id === action.payload.id);
      if (foundIndex !== -1) {
        state.userGridPreferences[foundIndex] = action.payload;
      } else {
        return {
          ...state,
          userGridPreferences: [...state.userGridPreferences, action.payload],
        };
      }
    },
    setUserDevicePreferenceAction(state, action: PayloadAction<IUserPreferenceState<{ deviceName; lineNumber }>>) {
      return {
        ...state,
        current: { device: action.payload.value.deviceName, line: action.payload.value.lineNumber },
        existing: { device: action.payload.value.deviceName, line: action.payload.value.lineNumber },
      };
    },
    setEmailSignaturePreferences(state, action: PayloadAction<any>) {
      state.emailSignature = action.payload;
    },
    setUserGridPreference(state, action: PayloadAction<IUserPreferenceState<string>>) {
      return state.userGridPreferences.find(p => p?.id === action.payload.id)
        ? {
            ...state,
            userGridPreferences: state.userGridPreferences.map(p => (p.id === action.payload.id ? action.payload : p)),
          }
        : { ...state, userGridPreferences: [...state.userGridPreferences, action.payload] };
    },
    setGlobalSearchDropDown(state, action: PayloadAction<any>) {
      state.globalSearchDropDown = action.payload;
    },
    setPinnedMessages(state, action: PayloadAction<any>) {
      state.pinnedMessages = action.payload;
    },
    setNotificationPreferences(state, action: PayloadAction<any>) {
      state.notificationPreference = action.payload;
    },
    setRecentHistoryPreferences(state, action: PayloadAction<any>) {
      state.recentHistory = action.payload;
    },
    setInboxFilterPreferences(state, action: PayloadAction<IUserPreferenceState<any[]>>) {
      state.inboxFilter = action.payload;
    },
    setEditorPreference(
      state,
      action: PayloadAction<{ editor: EditorType; preference: IUserPreferenceState<IEditorPreference> }>,
    ) {
      state.editors[action.payload.editor] = action.payload.preference;
    },
  },
});

const { actions } = userDevicePreferenceSlice;

export const { name: userDevicePreferenceKey, reducer: userDevicePreferenceReducer } = userDevicePreferenceSlice;

export const userDevicePreferenceActions = {
  ...actions,
  ...businessActions,
  saveUserDevicePreferenceAction,
  saveFilterPreferenceAction,
  saveUserGridPreferenceAction,
  saveGlobalSearchPreferenceAction,
  savePinnedMessagePreferenceAction,
  saveEmailSignaturePreferenceAction,
  saveNotificationPreferenceAction,
  saveUserRecentHistoryPreferenceAction,
  saveInboxFiltersAction,
  saveEditorPreferences,
};
