/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui';

export const useInterviewTabStyles = makeStyles<{ size: string }>()((theme, { size }) => ({
  container: {
    paddingTop: '10px',
    height: '600px',
  },
  buttonText: {
    paddingTop: '2px',
  },
  button: {
    textTransform: 'none',
  },
  interviewIcon: {
    backgroundColor: '#006FB9',
    textTransform: 'none',
    fontWeight: 'normal',
    color: '#fff',
    justifyContent: 'start',
    border: '1px solid #006fb9',
    '&:hover': {
      backgroundColor: '#f8f8f8',
      color: '#006FB9',
      border: '1px solid #006fb9',
    },
  },
  customFontSize: {
    fontSize: '25px !important',
  },
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50vh',
  },
  bodyScroll: {
    maxHeight: 570,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  dialogContainer: {
    width: '80%',
    maxWidth: '80%',
  },
  dialogContentRoot: {
    padding: '1.5rem',
    fontWeight: 500,
  },
  titleRoot: {
    padding: size === 'small' ? '5px 10px' : '16px 24px',
  },
  shape: {
    borderRadius: '0px',
  },
  dialogTitle: {
    color: '#fff',
    fontSize: '12px',
    backgroundColor: theme.palette.framework.system.skyBlue,
    display: 'flex',
    'justify-content': 'space-between',
    'align-items': 'center',
    '& button': {
      'background-color': 'transparent',
      color: '#fff',
    },
    '& svg': {
      position: 'relative',
      'margin-left': '6px',
      'margin-right': '6px',
      top: '-1px',
    },
  },
  missingInterviewsContainer: {
    textAlign: 'center',
    paddingTop: '125px',
  },
  missingInterviewsText: {
    fontStyle: 'italic',
    fontSize: '14px',
  },
  fontTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    paddingTop: '10px',
    paddingLeft: '7px',
  },
  actionNoBtn: {
    color: '#ffffff !important',
    backgroundColor: '#8E9195 !important',
  },
  snackBarActionItems: {
    color: '#2196f3',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  unionIcon: {
    paddingLeft: '8px',
    paddingBottom: '3px',
  },
  fieldsMarkedLabel: {
    color: '#707070',
    fontSize: '14px',
  },
  linkButton: {
    // textDecoration: 'underline',
    '&:hover': { textDecoration: 'underline' },
    textTransform: 'none',
    color: '#006FB9',
    margin: '0 0',
    fontSize: '14px',
  },
}));
