import { Box, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import Warning from 'app/assets/images/IconImages/warning.svg';
import React from 'react';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles()({
  banner: {
    backgroundColor: '#FEF6EE',
    border: '1px solid #FFA22E',
    width: '100%',
    padding: '12px 0px',
  },
  icon: {
    marginRight: '12px',
  },
  grid: {
    height: '100%',
  },
  text: {
    color: '#663B01',
    fontSize: '14px',
    alignSelf: 'center',
  },
});

export const ChangeBanner = ({ message }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      dispatch(globalActions.closeBanner());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div>
        <Box className={classes.banner}>
          <Grid container justifyContent="center" direction={'row'} alignContent="center" className={classes.grid}>
            <img src={Warning} alt="Warning" className={classes.icon} />
            <Grid item className={classes.text}>
              {message}
            </Grid>
          </Grid>
        </Box>
      </div>
    </React.Fragment>
  );
};
