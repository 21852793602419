import { useEffect, useState } from 'react';
import { ScreenSize, useScreenSize } from './ResponsiveHooks';

type screenSizesAvailable = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const MediaQuery = ({
  exact,
  minWidth,
  maxWidth,
  ...rest
}: {
  [key: string]: any;
  minWidth?: screenSizesAvailable;
  maxWidth?: screenSizesAvailable;
  exact?: screenSizesAvailable;
}) => {
  const screenSize = useScreenSize();
  const [exactSize, setExactSize] = useState<ScreenSize>(ScreenSize.none);
  const [minimumSize, setMinimumSize] = useState<ScreenSize>(ScreenSize.xs);
  const [maximumSize, setMaximumSize] = useState<ScreenSize>(ScreenSize.xl);

  const stringSizeToNumeric = (value: string): ScreenSize => {
    switch (value) {
      case 'xl':
        return ScreenSize.xl;
      case 'lg':
        return ScreenSize.lg;
      case 'md':
        return ScreenSize.md;
      case 'sm':
        return ScreenSize.sm;
      case 'xs':
        return ScreenSize.xs;
      default:
        return ScreenSize.none;
    }
  };

  useEffect(() => {
    if (exact) {
      setExactSize(stringSizeToNumeric(exact));
    } else {
      if (minWidth) setMinimumSize(stringSizeToNumeric(minWidth));
      if (maxWidth) setMaximumSize(stringSizeToNumeric(maxWidth));
    }
  }, [exact, minWidth, maxWidth]);

  return (exact && screenSize === exactSize) ||
    ((minWidth || maxWidth || !exact) && minimumSize <= screenSize && screenSize <= maximumSize)
    ? rest.children
    : null;
};
