import React from 'react';
import { RowSection } from '../../../Common/RowSection';
import { Button } from 'amn-ui-core';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips';
import { useWorkExperienceStyles } from '../../WorkExperience/WorkExperienceCard.style';
import { theme } from 'styles/global-styles';
import _ from 'lodash';
import DisplayToolTip from './DisplayToolTip';

const ApproveUnApprove = ({ title, status, summaryTabIconsUpdateDecision }) => {
  const APPROVEDSTATUS = 'Approved';
  const { classes } = useWorkExperienceStyles();

  return (
    <>
      <RowSection
        header={
          <span>
            <span>{`${title}: `}</span>
            <span>
              <Button
                type="button"
                variant="text"
                disableRipple
                disableTouchRipple
                disableFocusRipple
                classes={{
                  root: status === 0 ? classes.buttonNoCount : classes.button,
                }}
              >
                {status !== 0 ? (
                  <CustomTooltip
                    style={{ color: theme.palette.framework.system.tertiaryGrey }}
                    tooltipContent={<DisplayToolTip status={title} />}
                    tooltipPlacementBottom={classes.tooltipBottom}
                    interactive={true}
                  >
                    <div style={{ fontSize: '14px', color: theme.palette.framework.system.navyBlue }}>{status}</div>
                  </CustomTooltip>
                ) : (
                  `0`
                )}
              </Button>
            </span>
          </span>
        }
        data={''}
        preData={
          summaryTabIconsUpdateDecision.enabled
            ? title === APPROVEDSTATUS
              ? true
              : ''
            : title === APPROVEDSTATUS && status > 0
            ? true
            : ''
        }
        color={
          summaryTabIconsUpdateDecision.enabled
            ? theme.palette.system.darkGreenForIcon
            : theme.palette.framework.system.navyBlue
        }
        showHalfwayProgress={
          summaryTabIconsUpdateDecision.enabled
            ? title !== APPROVEDSTATUS
              ? true
              : false
            : title !== APPROVEDSTATUS && status > 0
            ? true
            : false
        }
        showHalfwayProgressSecondaryIcon={summaryTabIconsUpdateDecision.enabled ? true : false}
      />
    </>
  );
};

export default ApproveUnApprove;
