import {
  IRequirementCounts,
  IRequirementTreeFilter,
  RequirementFilterKey,
  requirementType,
} from 'app/models/Placement/PlacementSummary';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { removeDuplicates } from 'app/helpers/arrayHelpers';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { IOperationContainer, instanceOfIOperationContainer } from '../GroupedRequirements/types';

export const useRequirementFilter = (
  key: RequirementFilterKey,
  filterSelections: IRequirementTreeFilter | null,
  fieldStatus?: requirementType,
) => {
  const [display, setDisplay] = useState(true);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<requirementType[]>([]);

  useEffect(() => {
    if (!!filterSelections) {
      const node = searchRequirementsTree(key, filterSelections) as IRequirementTreeFilter | undefined;
      if (node) {
        setDisplay(
          /**assign = true - if no filter is applied */
          !node.filter.length ||
            /**assign = true - if filter satisfies, including null || undefined values */
            //@ts-ignore
            node.filter.includes(fieldStatus),
        );
        setSelectedFilter(node.filter);
        setIsFilterApplied(node.filter.length > 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelections, fieldStatus]);

  return { display, isFilterApplied, filter: selectedFilter };
};

export const useFilterIterator = ({ requirementFilter, iterator, filterKey }) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  iterator.map(({ type }) => useRequirementFilter(filterKey, requirementFilter, type));

export const deriveFilter = (
  key: RequirementFilterKey,
  filterTree: IRequirementTreeFilter,
  newFilter: requirementType[],
) => {
  const newFilterTree = _.cloneDeep(filterTree);
  const node = searchRequirementsTree(key, newFilterTree) as IRequirementTreeFilter | undefined;
  if (node) {
    node.filter = [...newFilter];
    /** Parent filter overwrites child filter */
    if (node.children) overWriteChildNodes(node, newFilter);
    /** Upadate Parent if child filter is removed */
    revertParentNode(key, newFilterTree, newFilter);
  }
  return newFilterTree;
};

const searchRequirementsTree = (key: RequirementFilterKey, filterTree: IRequirementTreeFilter) => {
  if (filterTree.key === key) return filterTree;
  else if (!filterTree.children || !filterTree.children?.length) return;
  else
    for (const index in filterTree.children) {
      const filter = searchRequirementsTree(key, filterTree.children[index]);
      if (!!filter) return filter;
    }
};

const overWriteChildNodes = (tree: IRequirementTreeFilter, filter: requirementType[]) => {
  if (!!tree.children) {
    /** overwrite all child data with new filter and avoid duplicates */
    tree.children.map(item => {
      item.filter = [...removeDuplicates(item.filter, filter)];
      !!item.children && overWriteChildNodes(item, filter);
      return item;
    });
    /**
     * check if a common value of children is not existing in parent
     * if existing splice entirely from children
     */
    const intersectionInChildren = _.intersection(...tree.children.map(child => child.filter));
    intersectionInChildren?.forEach(commonItem => {
      if (!filter.includes(commonItem))
        tree.children?.forEach(child => _.remove(child.filter, childItem => childItem === commonItem));
    });
  }
};

const revertParentNode = (key: RequirementFilterKey, tree: IRequirementTreeFilter, filter: requirementType[]) => {
  if (tree.key === key) return;
  if (tree.children) {
    const node = searchRequirementsTree(key, tree);
    if (!!node) {
      const newFilter = filter.reduce(
        (results, item) =>
          tree.children?.every(child => child.filter.includes(item)) ? [...results, item] : [...results],
        [] as requirementType[],
      );
      tree.filter = [...newFilter];
      for (const index in tree.children) revertParentNode(key, tree.children[index], filter);
    } else return;
  }
};

/** Code to open accordion */
export const useAccordionState = (appliedFilter: requirementType[], fieldStatuses: (requirementType | undefined)[]) => {
  const [expand, setExapnd] = useState(false);
  useEffect(() => {
    setExapnd(appliedFilter.some(item => fieldStatuses.includes(item)));
  }, [appliedFilter, fieldStatuses]);
  return { expand };
};

export const cleanChildFilterByKey = (
  containerKey: RequirementFilterKey,
  tree: IRequirementTreeFilter,
  counts: IRequirementCounts,
) => {
  _.difference(possibleFiltersInContainer, tree.filter).forEach(diffItem =>
    tree.children?.map(childFilter => {
      if (childFilter.key === containerKey)
        childFilter.filter = [
          ...childFilter.filter.filter(
            filterItem =>
              /**if filter is not applied on parent && if filter is not valid for child */
              filterItem !== diffItem || (filterItem === diffItem && counts[selectFieldByEnum(filterItem)] > 0),
          ),
        ];
    }),
  );
};

export const cleanChildFiltersRecursively = (container: IOperationContainer, filterTree) => {
  for (let i = 0; i < container.nodes.length; i++) {
    const node = container.nodes[i];
    if (node.excludeFromFilter) continue;
    if (instanceOfIOperationContainer(node)) cleanChildFiltersRecursively(node, filterTree);
    else cleanChildFilterByKey(node.key, filterTree, node.counts);
  }
};

const selectFieldByEnum = customSwitch({
  [requirementType.pass]: 'passed',
  [requirementType.fail]: 'failed',
  [requirementType.risk]: 'risks',
})(undefined);

const possibleFiltersInContainer = Object.values(requirementType).filter(item => typeof item === 'number');
