import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Concatenate } from 'utils/string/string';
import { candidateDetailsSelection, selectSkillSetTree } from '../../../Profile/CandidateDetails.selector';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { selectUser } from 'oidc/user.selectors';
import {
  IReason,
  ISkillsetGroup,
  ISkillsetItem,
  SkillsetType,
  combineDuplicates,
  getSkillsetUpdateItem,
} from './Helpers/SkillsetHelper';
import { useTranslation } from 'react-i18next';
import { ISkillsetTreeDiscipline, IUpdateSkillsetTreeData } from 'app/models/Candidate/CandidateProfile';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';

const useStyles = makeStyles<{ expanded: boolean }>()(expanded => ({
  dialog: {
    minWidth: '960px',
    '& .MuiDialog-paperWidthSm': {
      minWidth: '960px',
      maxWidth: '100%',
    },
  },
  dialogueContent: {
    padding: 0,
    width: '100%',
    overflowX: 'hidden',
    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .dialogContentRoot': {
      padding: 0,
    },
  },
  dialogContent2: {
    fontSize: '14px',
  },
  dialogTitle2: {
    fontSize: '20px',
  },
}));

interface SkillSetDialogProps {
  open: boolean;
  handleClose: () => void;
  children: JSX.Element;
  showConfirmation: boolean;
  handleOnExpanded: (expanded: boolean) => void;
  columns: ISkillsetGroup;
}

export const SkillSetDialog = (props: SkillSetDialogProps) => {
  const { open, handleClose, children, showConfirmation, handleOnExpanded, columns } = props;
  const { travelerId: candidateId } = useParams<{ travelerId: string; brandId: string }>();
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const { classes } = useStyles({ expanded: expanded });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const candidateSkillSetTree = useSelector(selectSkillSetTree);
  const user = useSelector(selectUser);
  const [alertOpen, setAlertOpen] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const candidateName = Concatenate(
    [candidateDetails?.name?.first, candidateDetails?.name?.middle || '', candidateDetails?.name?.last],
    ' ',
  );

  useEffect(() => {
    setSaveEnabled(
      Authorized(
        [
          userRoles.clinical_Manager,
          userRoles.clinical_ReviewAnalyst,
          userRoles.clinical_Director,
          userRoles.recruitment,
          userRoles.recruitment_Leadership,
          userRoles.recruitment_TeamMember,
        ],
        user.userInfo,
      ),
    );
  }, [user]);

  const handleCancel = (e, reason?: string) => {
    if (reason === IReason.backdropClick) return;
    if (showConfirmation) {
      handleClose();
    } else {
      setAlertOpen(true);
    }
  };

  const handleYesAction = () => {
    handleClose();
  };

  const handleExpanded = () => {
    handleOnExpanded(!expanded);
    setExpanded(!expanded);
  };

  const handleSave = async () => {
    const updatedSkillSets: ISkillsetTreeDiscipline[] = [];
    let isVerifiedSkillset = false;

    Object.entries(columns).map(([columnId, column], index) => {
      column.items.forEach((skillsetItem: ISkillsetItem) => {
        const skillset = getSkillsetUpdateItem(skillsetItem.skillset, candidateSkillSetTree, columns);

        if (skillset) {
          updatedSkillSets.push(skillset);
          if (skillsetItem.skillset.type === SkillsetType.verified) isVerifiedSkillset = true;
        }
      });
    });

    // Get already present unverified skillset
    const existingUnverifiedSkillset = candidateDetails?.skillSetTree?.candidateSkillSets?.filter(
      skillset => skillset.unverified === true,
    );

    const combinedSkillset = combineDuplicates(updatedSkillSets, candidateSkillSetTree.candidateSkillSets);

    // Traverse each skillset & set value of uiUnverifiedFlag
    combinedSkillset?.forEach(skillset => {
      if (skillset.unverified) {
        // If no existing unverified skillset exists, we're adding a new skillset
        if (existingUnverifiedSkillset?.length === 0) {
          skillset.uiUnverifiedFlag = true;
          skillset.specialties.forEach(speciality => {
            speciality.uiUnverifiedFlag = true;
          });
        } else {
          // Check if discipline already exists
          const isDisciplineExists = existingUnverifiedSkillset?.find(
            existingSkillset => existingSkillset?.disciplineId === skillset?.disciplineId,
          );
          if (isDisciplineExists) {
            skillset.uiUnverifiedFlag = false;
            skillset?.specialties?.forEach(speciality => {
              // Check if speciality already exists within the existing discipline
              const isSpecialityExists = isDisciplineExists?.specialties?.find(
                existingSpeciality =>
                  existingSpeciality.unverified && existingSpeciality?.specilityId === speciality?.specilityId,
              );
              if (isSpecialityExists) {
                speciality.uiUnverifiedFlag = false;
              } else {
                speciality.uiUnverifiedFlag = true;
              }
            });
          } else {
            // Discipline doesn't exist, adding new skillset
            skillset.uiUnverifiedFlag = true;
            skillset?.specialties?.forEach(speciality => (speciality.uiUnverifiedFlag = true));
          }
        }
      }
    });

    const payload: IUpdateSkillsetTreeData = {
      candidateId: candidateDetails.travelerId,
      skillsetTree: {
        ...candidateSkillSetTree,
        candidateSkillSets: combinedSkillset,
      },
      currentEmployeeId: user.userInfo?.employeeId || 8410,
      currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
    };

    dispatch(
      candidateDetailActions.updateCandidateSkillsetTree({
        data: payload,
        customSaveMessage: isVerifiedSkillset
          ? t('candidate.skillsets.saveVerified')
          : t('candidate.skillsets.saveSkillset'),
      }),
    );
    handleClose();
  };

  return (
    <>
      <GenericDialog
        variant={'blue'}
        fullWidth
        classes={{
          paper: classes.dialog,
        }}
        open={open}
        disablePortal
        disableEscapeKeyDown
        dialogTitleProps={{
          text: `${t('candidate.skillsets.manageSkillSet')} | ${candidateName} (${candidateId})`,
          closeButton: true,
          expandable: true,
          onExpandChange: handleExpanded,
        }}
        dialogContentProps={{
          classes: { root: classes.dialogueContent },
        }}
        dialogActions={[
          {
            text: t('candidate.skillsets.cancel'),
            variant: 'contained',
            color: 'tertiary',
            onClick: e => handleCancel(e),
          },
          {
            text: t('candidate.skillsets.save'),
            variant: 'contained',
            color: 'primary',
            disabled: showConfirmation,
            onClick: () => handleSave(),
          },
        ]}
        onClose={handleCancel}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </GenericDialog>
      <GenericDialog
        open={alertOpen}
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
        dialogTitleProps={{
          text: t('candidate.skillsets.discardChanges'),
          classes: { root: classes.dialogTitle2 },
        }}
        dialogContentProps={{
          classes: { root: classes.dialogContent2 },
        }}
        dialogActions={[
          {
            text: t('candidate.skillsets.cancel'),
            variant: 'text',
            color: 'tertiary',
            onClick: () => setAlertOpen(false),
          },
          {
            text: t('candidate.skillsets.yes'),
            variant: 'contained',
            color: 'tertiary',
            
            onClick: () => handleYesAction(),
          },
        ]}
      >
        <span>{t('candidate.skillsets.closeDescription')}</span>
      </GenericDialog>
    </>
  );
};
