/* eslint-disable i18next/no-literal-string */
import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FormInputProps } from './FormInputProps';

const useStyles = makeStyles()(() => ({
  checkboxPadding: {
    padding: 0,
    marginRight: 8,
  },
  checkboxLabel: {
    fontSize: 14,
    paddingLeft: 8,
    // paddingRight: 23,
    color: '#333333',
  },
  disabledCheckbox: {
    pointerEvents: 'none',
    cursor: 'default',
  },
}));

interface Props {
  isChecked?: boolean;
  color?: string;
  isDisabled?: boolean;
  handleChecked?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checkboxLabel: string;
  labelStyle?: string;
  checkboxErrorName?: string;
}

export const FormInputCheckbox: React.FC<FormInputProps & Props> = ({
  name,
  label,
  require,
  message,
  options,
  isChecked,
  color,
  isDisabled,
  handleChecked,
  checkboxLabel,
  labelStyle,
  checkboxErrorName,
  customCheckBox,
}) => {
  const { classes } = useStyles();
  const { control, setValue, clearErrors } = useFormContext();
  const [checked, setChecked] = useState(isChecked);

  const handleSelect = event => {
    if (!customCheckBox) clearErrors(checkboxErrorName);
    setValue(name, event.target.checked);
    setChecked(event.target.checked);
    handleChecked?.(event.target.checked);
  };
  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  useEffect(() => {
    setValue(name, checked);
  }, [name, checked, setValue]);

  const checkboxClass = isDisabled
    ? `${labelStyle} ${classes.checkboxLabel} ${classes.disabledCheckbox}`
    : `${labelStyle} ${classes.checkboxLabel}`;
  return (
    <FormControl size={'small'} variant={'outlined'}>
      <FormControlLabel
        control={
          <Controller
            name={name}
            render={({ ref, value, onChange, ...rest }) => {
              return (
                <Checkbox
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  checked={checked}
                  disabled={isDisabled ? true : false}
                  onChange={event => handleSelect(event)}
                  className={classes.checkboxPadding}
                  color={color || 'primary'}
                />
              );
            }}
            control={control}
          />
        }
        label={checkboxLabel}
        className={checkboxClass}
      />
    </FormControl>
  );
};
