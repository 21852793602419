import _isArray from 'lodash/isArray';

export const returnTaskFilterBody = (filterName, filter, data, remove = false) => {
    if (!data || !filter) return;
    const temp = { ...data };
    const formattedFilterData = _isArray(filter) ? filter.map(f => f.value) : filter;
    if (remove && temp[filterName]) {
      temp[filterName] = temp[filterName]?.filter(t => t !== formattedFilterData?.value) || [];
    } else if (temp[filterName]) {
      temp[filterName] = formattedFilterData;
    }
    return temp;
  };