import { CustomGrid } from 'app/components/Common/Grid/Grid';
import { selectGridState } from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import React from 'react';
import { useSelector } from 'react-redux';

const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_PAGE_SIZE = 20;

export const WorkDeskGrid = props => {
  const { tableColumns, tableData, gridName } = props;
  const gridState = useSelector(selectGridState);

  return (
    <CustomGrid
      columns={tableColumns}
      rows={tableData}
      currentPageNumber={DEFAULT_PAGE_NUMBER}
      fixedItemsPerPage={DEFAULT_PAGE_SIZE}
      defaultPageView={gridState.pageSize !== 0 ? gridState.pageSize.toString() : DEFAULT_PAGE_SIZE.toString()}
      defaultSortDirection={gridState.sortedColumn?.direction}
      defaultSortedColumn={gridState.sortedColumn?.column}
      pageOptions={[10, 20, 50, 100]}
      disableGutters
      noPadding
      gridName={gridName}
      stickyTable
      showCount
      enableStateManagement
      resultsTitle={props.resultsTitle || 'Showing Results'}
    />
  );
};
