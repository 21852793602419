import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IModsDetails,
  IPlacementModification,
  IPlacementMods,
  preferenceModInitialState,
} from 'app/models/Placement/PlacementMods';
import { serviceActions } from './async-actions';
import { businessActions } from './business-action';

export const initialState: IPlacementMods = {
  placementModsList: null,
  refreshPlacementModsList: null,
  openRefreshDialog: false,
  refreshModMessage: '',
  currentModDetails: null,
  defaultModDetails: null,
  startDateReasonCodes: null,
  additionalPremiumPay: null,
  expandedModsDialgog: null,
  exceptionPayRateGww: null,
  effectiveDateTypes: null,
  insuranceTypes: null,
  pricingReasons: null,
  billRateGwwShiftList: [],
  cancelledHoursFrequencyList: [],
  payeeList: [],
  reimbursementCategoryAndFrequency: [],
  isEditMod: false,
  isOpenModForAdding: null,
  modSavedStatus: null,
  isUndoModal: null,
  insuranceEffectiveType: null,
  isEditExistingMod: false,
  changeToPending: false,
  initiators: [],
  isGridModAltered: false,
  isCustomTemplateType: false,
  originCustomTemplateChanged: null,
  editTimeOffRows: [],
  editUtilitiesRows: [],
  editFurnitureRows: [],
  editPayrollShiftDiffRows: [],
  timeOffHoursErrors: [],
  timeOffStartDateErrors: [],
  furnitureQuantityErrors: [],
  furnitureCostToTravelerErrors: [],
  timeOffEndDateErrors: [],
  shiftDifferentialStartTimeError: [],
  shiftDifferentialEndTimeError: [],
  shiftDifferentialAmountError: [],
  shiftDifferentialTypeError: [],
  shiftDifferentialDescriptionTypeError: [],
  isGridModError: false,
  shiftDifferentialTypes: [],
  shiftDifferentialDescriptionTypes: [],
  shiftDifferentialTemplateTypes: [],
  selectedTaskForMod: null,
  preferenceMod: preferenceModInitialState,
  shiftDifferentialTemplateItems: [],
  standard: false,
  openTaskGridFromHistory: null,
  isApprovalStatusSelected: null,
  isTemplateChangedInShift: false,
  editBillingShiftDiffRows: [],
  doesHousingBannerDisplay: false,
  holdBannerShownState: false,
  isEmiLmoDisabled: false,
  isEmiLmoUndo: false,
  pendingMods: [],
  placementModHistory: [],
  bookingApprovedCheckboxSpecs: {
    isCheckBoxSelected: false,
    /** 
     * value = true ensures checkbox is disabled by default,
     * untill service gives response
     */
    isCheckBoxDisabled: true,
    pendingModCount: 0,
    travelerOptionTimestamp: null,
    divisionTypeId: null,
    divisionType: null,
    activityTypeId: null,
    activityStatusId: null,
    approvedBy: null,
    approvedByName: null,
    approvalDate: null,
    disabledReasons: {},
  },
};

const slice = createSlice({
  name: 'placementMods',
  initialState,
  reducers: {
    setPlacementModsList(state, action: PayloadAction<Array<IModsDetails> | undefined>) {
      return {
        ...state,
        placementModsList: action.payload,
      };
    },
    setRefreshPlacementModList(state, action: PayloadAction<Array<IModsDetails> | undefined>) {
      return {
        ...state,
        refreshPlacementModsList: action.payload,
      };
    },
    setRefreshModDetails(state, action: PayloadAction<string>) {
      return {
        ...state,
        openRefreshDialog: true,
        refreshModMessage: action.payload,
      };
    },
    setCloseRefreshModDetails(state) {
      return {
        ...state,
        openRefreshDialog: false,
      };
    },
    setCurrentModsDetails(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        currentModDetails: action.payload,
      };
    },
    setDefaultModDetails(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        defaultModDetails: action.payload,
      };
    },
    setStartDateReasonCode(state, action: PayloadAction<any[] | null>) {
      return {
        ...state,
        startDateReasonCodes: action.payload,
      };
    },
    setInitiators(state, action: PayloadAction<any[] | null>) {
      return {
        ...state,
        initiators: action.payload,
      };
    },
    setAdditionalPremiumPay(state, action: PayloadAction<any[] | null>) {
      return {
        ...state,
        additionalPremiumPay: action.payload,
      };
    },
    setExceptionPayrateGww(state, action: PayloadAction<any[] | null>) {
      return {
        ...state,
        exceptionPayRateGww: action.payload,
      };
    },
    setExpandedModsDialog(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        expandedModsDialgog: action.payload,
      };
    },
    setInsuranceType(state, action: PayloadAction<any[] | null>) {
      return {
        ...state,
        
        insuranceTypes: action.payload,
      };
    },

    setEffectiveDateTypes(state, action: PayloadAction<any[] | null>) {
      return {
        ...state,
        effectiveDateTypes: action.payload,
      };
    },

    setPricingReasons(state, action: PayloadAction<any[] | null>) {
      return {
        ...state,
        pricingReasons: action.payload,
      };
    },

    setRateShiftList(state, action: PayloadAction<any[] | null>) {
      return {
        ...state,
        billRateGwwShiftList: action.payload,
      };
    },

    setCancellationHoursFrequencyList(state, action: PayloadAction<any[] | null>) {
      return {
        ...state,
        cancelledHoursFrequencyList: action.payload,
      };
    },

    setPayeeList(state, action: PayloadAction<any[] | null>) {
      return {
        ...state,
        payeeList: action.payload,
      };
    },

    setReimbursementCategoryAndFrequency(state, action: PayloadAction<any[] | null>) {
      return {
        ...state,
        reimbursementCategoryAndFrequency: action.payload,
      };
    },
    setOpenModForAdding(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        isOpenModForAdding: action.payload,
      };
    },
    setIsModEdit(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        isEditMod: action.payload,
      };
    },
    setStandard(state, action: PayloadAction<boolean | null>) {
      return {
        ...state,
        standard: action.payload,
      };
    },
    setHousingBannerDisplay(state, action: PayloadAction<boolean | null>) {
      return {
        ...state,
        doesHousingBannerDisplay: action.payload,
      };
    },
    setBannerShownState(state, action: PayloadAction<boolean | null>) {
      return {
        ...state,
        holdBannerShownState: action.payload,
      };
    },
    setModSavedStatus(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        modSavedStatus: action.payload,
      };
    },
    setIsUndoModal(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        isUndoModal: action.payload,
      };
    },
    setInsuranceEffectiveType(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        insuranceEffectiveType: action.payload,
      };
    },

    setIsEditExistingMod(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        isEditExistingMod: action.payload,
      };
    },
    setChangeToPending(state, action: PayloadAction<boolean | null>) {
      return {
        ...state,
        changeToPending: action.payload,
      };
    },
    setEditTimeOffRows(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        editTimeOffRows: action.payload,
      };
    },
    setEditUtilitiesRows(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        editUtilitiesRows: action.payload,
      };
    },
    setEditFurnitureRows(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        editFurnitureRows: action.payload,
      };
    },
    setEditPayrollShiftDiffRows(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        editPayrollShiftDiffRows: action.payload,
      };
    },
    setGridModAltered(state, action: PayloadAction<boolean | null>) {
      return {
        ...state,
        isGridModAltered: action.payload,
      };
    },

    setTimeOffHoursError(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        timeOffHoursErrors: action.payload,
      };
    },

    setTimeOffStartDateError(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        timeOffStartDateErrors: action.payload,
      };
    },

    setFurnitureQuantityError(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        furnitureQuantityErrors: action.payload,
      };
    },

    setFurnitureCostToTravelError(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        furnitureCostToTravelerErrors: action.payload,
      };
    },
    setTimeOffEndDateError(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        timeOffEndDateErrors: action.payload,
      };
    },

    setShiftDifferentialStartTimeError(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        shiftDifferentialStartTimeError: action.payload,
      };
    },

    setShiftDifferentialEndTimeError(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        shiftDifferentialEndTimeError: action.payload,
      };
    },

    setShiftDifferentialAmountError(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        shiftDifferentialAmountError: action.payload,
      };
    },
    setShiftDifferentialTypeError(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        shiftDifferentialTypeError: action.payload,
      };
    },

    setShiftDifferentialDescriptionTypeError(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        shiftDifferentialDescriptionTypeError: action.payload,
      };
    },

    setGridModError(state, action: PayloadAction<boolean | null>) {
      return {
        ...state,
        isGridModError: action.payload,
      };
    },
    setShiftDifferentialTypes(state, action: PayloadAction<any[] | null>) {
      return {
        ...state,
        shiftDifferentialTypes: action.payload,
      };
    },
    setShiftDifferentialDescriptionTypes(state, action: PayloadAction<any[] | null>) {
      return {
        ...state,
        shiftDifferentialDescriptionTypes: action.payload,
      };
    },
    setShiftDifferentialTemplateTypes(state, action: PayloadAction<any[] | null>) {
      return {
        ...state,
        shiftDifferentialTemplateTypes: action.payload,
      };
    },
    setSelectedTaskForMod(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        selectedTaskForMod: action.payload,
      };
    },
    setShiftDifferentialTemplateItems(state, action: PayloadAction<any[] | null>) {
      return {
        ...state,
        shiftDifferentialTemplateItems: action.payload,
      };
    },
    setCustomTemplateType(state, action: PayloadAction<boolean | null>) {
      return {
        ...state,
        isCustomTemplateType: action.payload,
      };
    },
    setOpenTaskGrid(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        openTaskGridFromHistory: action.payload,
      };
    },

    setOriginCustomTemplateChanged(state, action: PayloadAction<boolean | null>) {
      return {
        ...state,
        originCustomTemplateChanged: action.payload,
      };
    },

    setTemplateChangedInShift(state, action: PayloadAction<boolean | null>) {
      return {
        ...state,
        isTemplateChangedInShift: action.payload,
      };
    },

    //*START - EDIT PREFERENCE REDUCERS
    // START ADULT GRID
    setEditAdultRows(state, action: PayloadAction<any[] | null>) {
      return {
        ...state,
        preferenceMod: {
          ...state.preferenceMod,
          adult: {
            ...state.preferenceMod.adult,
            editAdultRows: action.payload,
          },
        },
      };
    },
    setPreferenceAdultNameError(state, action: PayloadAction<any[] | null>) {
      return {
        ...state,
        preferenceMod: {
          ...state.preferenceMod,
          adult: {
            ...state.preferenceMod.adult,
            adultNameError: action.payload,
          },
        },
      };
    },
    setPreferenceAdultRelationshipError(state, action: PayloadAction<any[] | null>) {
      return {
        ...state,
        preferenceMod: {
          ...state.preferenceMod,
          adult: {
            ...state.preferenceMod.adult,
            adultRelationshipError: action.payload,
          },
        },
      };
    },
    setAdultGridmodError(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        preferenceMod: {
          ...state.preferenceMod,
          adult: {
            ...state.preferenceMod.adult,
            isAdultGridmoderror: action.payload,
          },
        },
      };
    },
    setAdultGridmodAltered(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        preferenceMod: {
          ...state.preferenceMod,
          adult: {
            ...state.preferenceMod.adult,
            isAdultGridmodAltered: action.payload,
          },
        },
      };
    },
    // END ADULT GRID
    // START CHILDREN GRID
    setEditChildrenRows(state, action: PayloadAction<any[] | null>) {
      return {
        ...state,
        preferenceMod: {
          ...state.preferenceMod,
          children: {
            ...state.preferenceMod.children,
            editChildrenRows: action.payload,
          },
        },
      };
    },
    setPreferenceChildrenGenderError(state, action: PayloadAction<any[] | null>) {
      return {
        ...state,
        preferenceMod: {
          ...state.preferenceMod,
          children: {
            ...state.preferenceMod.children,
            childrenGenderError: action.payload,
          },
        },
      };
    },
    setPreferenceChildrenAgeError(state, action: PayloadAction<any[] | null>) {
      return {
        ...state,
        preferenceMod: {
          ...state.preferenceMod,
          children: {
            ...state.preferenceMod.children,
            childrenAgeError: action.payload,
          },
        },
      };
    },
    setChildrenGridModError(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        preferenceMod: {
          ...state.preferenceMod,
          children: {
            ...state.preferenceMod.children,
            isChildrenGridModError: action.payload,
          },
        },
      };
    },
    setChildrenGridModAltered(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        preferenceMod: {
          ...state.preferenceMod,
          children: {
            ...state.preferenceMod.children,
            isChildrenGridModAltered: action.payload,
          },
        },
      };
    },
    // END CHILDREN GRID
    // START PETS GRID
    setEditPetsRows(state, action: PayloadAction<any[] | null>) {
      return {
        ...state,
        preferenceMod: {
          ...state.preferenceMod,
          pets: {
            ...state.preferenceMod.pets,
            editPetsRows: action.payload,
          },
        },
      };
    },
    setPetsGridModAltered(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        preferenceMod: {
          ...state.preferenceMod,
          pets: {
            ...state.preferenceMod.pets,
            isPetsGridModAltered: action.payload,
          },
        },
      };
    },
    setPetsGridModError(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        preferenceMod: {
          ...state.preferenceMod,
          pets: {
            ...state.preferenceMod.pets,
            isPetsGridModError: action.payload,
          },
        },
      };
    },
    setPreferencePetsTypeError(state, action: PayloadAction<any[] | null>) {
      return {
        ...state,
        preferenceMod: {
          ...state.preferenceMod,
          pets: {
            ...state.preferenceMod.pets,
            petsTypeError: action.payload,
          },
        },
      };
    },

    // END PETS GRID
    //*END - EDIT PREFERENCE REDUCERS
    setApprovalStatusSelected(state, action: PayloadAction<boolean | null>) {
      return {
        ...state,
        isApprovalStatusSelected: action.payload,
      };
    },

    setEditBillingShiftDiffRows(state, action: PayloadAction<any | null>) {
      return {
        ...state,
        editBillingShiftDiffRows: action.payload,
      };
    },

    setEmiLmoDisabled(state, action: PayloadAction<boolean | null>) {
      return {
        ...state,
        isEmiLmoDisabled: action.payload,
      };
    },
    setEmiLmoUndo(state, action: PayloadAction<boolean | null>) {
      return {
        ...state,
        isEmiLmoUndo: action.payload,
      };
    },
    setPendingMods(state, action: PayloadAction<IPlacementModification[]>) {
      state.pendingMods = action.payload;
    },
    setModificationHistory(state, action: PayloadAction<IPlacementModification[]>) {
      state.placementModHistory = action.payload;
    },
    setBookingApprovedCheckboxSpec(state, action: PayloadAction<any>) {
      state.bookingApprovedCheckboxSpecs = action.payload;
    },
  },
});

const { actions } = slice;
export const { reducer: PlacementModsReducer, name: PlacementModsSliceKey } = slice;
export const placementModsAction = { ...actions, ...serviceActions, ...businessActions };
