import { GridCellParams } from '@mui/x-data-grid-pro';
import { Box } from 'amn-ui-core';
import React from 'react';

interface XGridIconCellProps {
  icon: string | JSX.Element;
  alt?: string;
  align?: string;
  onClick?: (params: GridCellParams, event) => void;
  hasTitle?: boolean;
  getTitle?: (params: GridCellParams) => any;
}

/**
 * Cell renderer to display an icon, either an imported icon, or a react component
 * @param params X-Grid Params: `GridCellParams`
 * @returns JSXElement
 */
export const XGridIconCell =
  ({ icon, alt = '', align = 'center', onClick, hasTitle = false, getTitle }: XGridIconCellProps) =>
  (params: GridCellParams) => {
    return (
      <React.Fragment>
        <div style={{ justifyItems: align, width: '100%' }}>
          <Box onClick={e => onClick?.(params, e)}>
            {typeof icon === 'string' && <img style={{ justifySelf: align }} src={icon} alt={alt} />}
            {typeof icon !== 'string' && icon}
            {hasTitle && getTitle(params)}
          </Box>
        </div>
      </React.Fragment>
    );
  };
