import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { HomePage } from '../AppHome/AppHome';
import { DefaultHomeSwitch } from '../AppHome/DefaultHomeSwitch';
import { CustomBackdrop } from '../Common/Backdrop/CustomBackdrop';
import useDocumentTitle from 'utils/customHooks/useDocumentTitle';
import { pageNames } from 'app/constants/PageNames';
import { trackPageView } from 'app-insights/appInsightsTracking';
import { PricingDesk } from './PricingDesk/PricingDesk';
import { GenericTabs } from '@AMIEWEB/Common/Tabs/GenericTabs';

const useStyles = makeStyles()({
  root: {
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: '#F5F5F5',
    flexWrap: 'nowrap',
  },
});

export const PricingWorkDesk = () => {
  const { classes } = useStyles();
  useDocumentTitle({ title: pageNames.workdeskPricing });
  React.useEffect(() => {
    trackPageView({ name: pageNames.workdeskPricing });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs = [{ label: 'My Work Desk', component: <PricingDesk /> }];
  return (
    <div className={classes.root}>
      <CustomBackdrop open={false} />
      <GenericTabs
        tabs={tabs}
        modifyUrl
        dense
        addOnComponent={<DefaultHomeSwitch page={HomePage.PRICING_WORK_DESK} />}
      />
    </div>
  );
};
