import { PayloadAction } from '@reduxjs/toolkit';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import {
  isInterviewed,
  isInterviewScheduled,
  isRFO,
  isSent,
  placementStatusOptions,
} from 'app/components/Placement/NextPlacementStatus/StatusDefaults';
import { missingDate, missingField } from 'app/constants';
import { PromiseTrackerKeys } from 'app/constants/PromiseTrackerKeys';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import {
  EPLacementStatusRequirementTooltipType,
  getPlacementOrderTypeValue,
  IPlacementRequirementKey,
  IPlacementStatus,
  IPlacementStatusRequirementMessage,
} from 'app/models/Placement/PlacementDetails';
import { IServiceFailureKey, requirementType } from 'app/models/Placement/PlacementSummary';
import {
  syncPlacementWithPeopleSoft,
  getPeopleSoftDetails as getPeopleSoftDetailsAPI,
} from 'app/services/PlacementServices/PlacementServices';
import { httpSuccess } from 'app/services/serviceHelpers';
import moment from 'moment';
import { manuallyIncrementPromiseCounter, manuallyResetPromiseCounter, trackPromise } from 'react-promise-tracker';
import { call, put, select, takeLatest, delay, getContext, all, takeEvery, fork } from 'redux-saga/effects';
import { placementDetailsAction } from '../placement-details/slice';
import { selectPlacementDetails, selectPlacementOrderType } from '../placement-details/selectors';
import { placementStatusAction } from '../placement-status/slice';
import { IPlacementSummaryRequest, placementSummaryActions, IAuditRequest } from './slice';
import {
  extractReferenceAndEmploymentVerifications,
  ServiceConstants,
} from './sagaHelper';
import { formatAuditRecords } from './helper';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { CredentialingService } from 'app/services/CredentialingServices/credentialing-service';
import { SetPlacementFailRiskRequirementMessage } from 'store/redux-store/new-placement/saga';
import { GetPlacementSummaryMessage } from '../new-placement/saga';
import { selectPlacementStatus } from '../placement-status/selectors';
import { SkillRequirementMapper } from './SkillRequirementMapper';
import { formatPlacementGoogleMapLink } from '../placement-details/helper';
import {
  requestLicenseOrderRequirement,
  requestMiceDetailsOrderRequirement,
  requestDatesOrderRequirement,
  requestTransportationData,
  requestBookingConfirmationRequirement,
  requestOfferConfirmationRequirement,
  requestOfferTravelDetailsRequirement,
  requestAirportCodesPicklist,
  requestTransportationPicklist,
  requestTravelTimePicklist,
  requestBookingCredentialingRequirement,
  requestBookingRequirements,
  requestCertificationOrderRequirement,
  requestBookingApprovedRequirements,
  requestWaitingGoodToGoRequirements,
  requestArbitrationAgreementRequirements,
  requestCovidOrderRequirement,
  requestScheduleOrderRequirement,
  requestVirtualInterviewRequirement,
  requestAssignmentInformationRequirement,
  requestGroupedSkillsetRequirement,
} from './common-saga';
import { selectPlacementConsolidatedDetails } from './consolidatedStoreSelectors';

const TrackSkillsetReqWrapper = (fn, ...args) => trackPromise(fn(...args), 'placement-submission-skillset-requirement');
const TrackStatusAmnReqWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-status-amn-requirement');
const TrackAuditAmnReqWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-audit-amn-requirement');
const TrackEducationAmnReqWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-education-amn-requirement');
const TrackExperienceAmnReqWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-experience-requirement');
const TrackWorkHistorySubmissionReqWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-work-history-submission-requirement');
const TrackReferenceAmnReqWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-reference-amn-requirement');
const TrackSkillsChecklistReqWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-skills-checklist-requirement');
const TrackApplicationAmnReqWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-application-amn-requirement');
const TrackAuditDetailsWrapper = (fn, ...args) => trackPromise(fn(...args), 'placement-submission-audit-records');
const TrackReferenceAndEmploymentVerificationWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-reference-emp-verfication-records');

const TrackStatusOrderReqWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-status-order-requirement');
const TrackFacilityStatusOrderReqWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-facility-status-order-requirement');
const TrackReferenceOrderReqWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-reference-order-requirement');
const TrackCandidateRecordsRightWrapper = (fn, ...args) => trackPromise(fn(...args), 'candidate-records-right');
const TrackMilesRadiusOrderReqWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-miles-radius-order-requirement');
const TrackRefreshDrivingDistance = (fn, ...args) => trackPromise(fn(...args), 'refresh-placement-driving-distance');

const TrackDocumentOrderReqWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-document-order-requirement');
const TrackOrderInfoWrapper = (fn, ...args) => trackPromise(fn(...args), 'placement-order-info-requirement');

export const TrackOfferConfirmationsWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-offer-confirmations-data');
const TrackPeopleSoftSync = (fn, ...args) => trackPromise(fn(...args), PromiseTrackerKeys.placements.peopleSoftSync);
const TrackPeopleSoft = (fn, ...args) => trackPromise(fn(...args), PromiseTrackerKeys.placements.peopleSoftGetDetails);
const TrackOrderDOBOrderReqWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-DOB-order-requirement');
const TrackOrderSsnOrderReqWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-Ssn-order-requirement');
const TrackGroupedSkillsetReq = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-grouped-skillset-requirement');
export const resetFailureStatus = (key: IServiceFailureKey) =>
  placementSummaryActions.setServiceFailureByKey({ key, failureStatus: false });
const TrackClassicPacketInOkToSend = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-classic-submission-packet-in-ok2send');

/**
 * Set the failed requirement for the given status array. If it is a certain status,
 * the failed requirement may be applied to other statuses as well
 * @param nextStatusRequirement Array of IPlacementStatus, and the status the failed requirement will be applied to
 * @param requirement `IPlacementStatusRequirementMessage`
 */
export function* setFailedRequirement(
  action: PayloadAction<{
    nextStatusRequirement: IPlacementStatus[];
    requirement: IPlacementStatusRequirementMessage;
  }>,
) {
  try {
    const { currentPlacementStatus: cPStatus } = yield select(selectPlacementStatus);
    for (const status of action.payload.nextStatusRequirement) {
      yield put(
        placementStatusAction.setFailedRequirementForStatus({
          status,
          requirement: action.payload.requirement,
        }),
      );
      /** CODE:
       * US #121654: Avoid all the failures under SubmitPacket if status RFO to Sent and currentPlacement status is sent/interview/intShecduled
       * if above condition is failing add the requirement under submit packet
       * */
      if (isRFO(status) && !(isInterviewed(cPStatus) || isSent(cPStatus) || isInterviewScheduled(cPStatus))) {
        yield put(
          placementStatusAction.setFailedRequirementForStatus({
            status: placementStatusOptions.submitPacket,
            requirement: action.payload.requirement,
          }),
        );
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonUIError,
        functionName: 'setFailedRequirement',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

/** Submission AMN Requirements - Start */
function* requestSkillsetRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    const placementOrderType = yield select(selectPlacementOrderType);
    const placementService: PlacementService = yield getContext('placementService');

    yield put(resetFailureStatus('skillsetAmnRequirement'));
    yield put(resetFailureStatus('skillsetOrderRequirement'));
    yield put(resetFailureStatus('strikeSkillsetAmnRequirement'));
    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.summary?.submission?.amnSubmissionBusinessRequrement?.skillSet?.status,
        data: consolidatedDetails.summary?.submission?.amnSubmissionBusinessRequrement?.skillSet?.data
      }
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackSkillsetReqWrapper,
        placementService.getPlacementSummarySkillSet_Req,
        action.payload.placementId,
        placementOrderType,
      );
    }

    if (httpSuccess(response?.status)) {
      const data = GetPlacementSummaryMessage(response, placementOrderType);

      // AMN SKILLSET
      yield put(
        placementSummaryActions.setSubmissionInfoByKey({
          key: 'skillset',
          amnRequirement: data.amnRequirement,
          candidateRequirement: data.candidateRequirement,
          orderRequirement: null,
        }),
      );

      yield fork(SetPlacementFailRiskRequirementMessage, {
        payload: {
          messageId: data?.amnRequirement?.messageId,
          version: 'v1',
          passFailType: data.amnRequirement?.data?.verifiedByCmType,
          data: data?.amnRequirement?.data,
          messageTypeId: IPlacementRequirementKey.amnSkillset,
          tooltipTypeParam: undefined,
          nextStatusRequirementParam: data.nextStatusRequirement,
        },
        type: placementSummaryActions.setFailedRequirement.type,
      });
      // ORDER SKILL SET
      yield put(
        placementSummaryActions.setSubmissionInfoByKey({
          key: 'skillset',
          isOrderReq: true,
          orderRequirement: data.orderRequirement,
          candidateRequirement: data.candidateRequirement,
          amnRequirement: null,
        }),
      );

      // STRIKE SKILLSET
      yield put(placementSummaryActions.setSubmissionInfoByKey({ key: 'strikeSkillset', ...data }));

      yield fork(SetPlacementFailRiskRequirementMessage, {
        payload: {
          messageId: data?.amnRequirement?.messageId,
          version: 'v2',
          passFailType: data.amnRequirement?.data?.strikeMatchType,
          data: data?.amnRequirement?.data,
          messageTypeId: IPlacementRequirementKey.amnVerifiedSkillset,
          tooltipTypeParam: EPLacementStatusRequirementTooltipType.messageWithSupportList,
          nextStatusRequirementParam: data.nextStatusRequirement,
        },
        type: placementSummaryActions.setFailedRequirement.type,
      });
    } else {
      yield put(placementSummaryActions.setServiceFailureByKey({ key: 'skillsetAmnRequirement', failureStatus: true }));
      yield put(
        placementSummaryActions.setServiceFailureByKey({ key: 'skillsetOrderRequirement', failureStatus: true }),
      );
      yield put(
        placementSummaryActions.setServiceFailureByKey({ key: 'strikeSkillsetAmnRequirement', failureStatus: true }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestSkillsetRequirement',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

function* requestStatusAmnRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('statusAmnRequirement'));
    const placementService: PlacementService = yield getContext('placementService');

    const placementOrderType = yield select(selectPlacementOrderType);
    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.summary?.submission?.amnSubmissionBusinessRequrement?.status?.status,
        data: consolidatedDetails.summary?.submission?.amnSubmissionBusinessRequrement?.status?.data
      }
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackStatusAmnReqWrapper,
        placementService.getPlacementSummaryStatus_AmnReq,
        action.payload.placementId,
        placementOrderType,
      );
    }

    if (httpSuccess(response?.status)) {
      const data = GetPlacementSummaryMessage(response, placementOrderType);

      yield put(placementSummaryActions.setSubmissionInfoByKey({ key: 'status', ...data }));

      yield fork(SetPlacementFailRiskRequirementMessage, {
        payload: {
          messageId: data?.amnRequirement?.messageId,
          version: data?.amnRequirement?.messageVersion,
          passFailType: data?.amnRequirement?.type,
          data: data?.candidateRequirement?.data?.status,
          messageTypeId: IPlacementRequirementKey.amnCandidateStatus,
          tooltipTypeParam: EPLacementStatusRequirementTooltipType.messageWithSupportList,
          nextStatusRequirementParam: data.nextStatusRequirement,
        },
        type: placementSummaryActions.setFailedRequirement.type,
      });
    } else
      yield put(placementSummaryActions.setServiceFailureByKey({ key: 'statusAmnRequirement', failureStatus: true }));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestStatusAmnRequirement',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

function* requestAuditAmnRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('auditAmnRequirement'));
    const placementService: PlacementService = yield getContext('placementService');

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.summary?.submission?.amnSubmissionBusinessRequrement?.audits?.status,
        data: consolidatedDetails.summary?.submission?.amnSubmissionBusinessRequrement?.audits?.data
      }
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackAuditAmnReqWrapper,
        placementService.getPlacementSummaryAudit_AmnReq,
        action.payload.placementId,
        action.payload.placementOrderType,
      );
    }

    if (httpSuccess(response?.status)) {
      const data = GetPlacementSummaryMessage(response, action.payload.placementOrderType);

      yield put(placementSummaryActions.setSubmissionInfoByKey({ key: 'audit', ...data }));
      yield fork(SetPlacementFailRiskRequirementMessage, {
        payload: {
          messageId: data?.amnRequirement?.messageId,
          version: data?.amnRequirement?.messageVersion,
          passFailType: data.amnRequirement.type,
          data: data?.candidateRequirement?.data?.holdFileOut,
          messageTypeId: IPlacementRequirementKey.amnAudit,
          tooltipTypeParam: undefined,
          nextStatusRequirementParam: data.nextStatusRequirement,
        },
        type: placementSummaryActions.setFailedRequirement.type,
      });
    } else
      yield put(placementSummaryActions.setServiceFailureByKey({ key: 'auditAmnRequirement', failureStatus: true }));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestAuditAmnRequirement',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

function* requestEducationAmnRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('educationAmnRequirement'));
    const placementService: PlacementService = yield getContext('placementService');

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.summary?.submission?.amnSubmissionBusinessRequrement?.education?.status,
        data: consolidatedDetails.summary?.submission?.amnSubmissionBusinessRequrement?.education?.data
      }
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackEducationAmnReqWrapper,
        placementService.getPlacementSummaryEducation_AmnReq,
        action.payload.placementId,
        action.payload.placementOrderType,
      );
    }

    if (httpSuccess(response?.status)) {
      const data = GetPlacementSummaryMessage(response, action.payload.placementOrderType);

      // AMN Requirementeducation

      yield fork(SetPlacementFailRiskRequirementMessage, {
        payload: {
          messageId: data?.amnRequirement?.messageId,
          version: data?.amnRequirement?.messageVersion,
          passFailType: data?.amnRequirement?.type,
          data: data?.amnRequirement?.data,
          messageTypeId: IPlacementRequirementKey.amnEducation,
          tooltipTypeParam: EPLacementStatusRequirementTooltipType.messageWithSupportList,
          nextStatusRequirementParam: data.nextStatusRequirement,
        },
        type: placementSummaryActions.setFailedRequirement.type,
      });

      yield put(
        placementSummaryActions.setSubmissionInfoByKey({
          key: 'education',
          ...data,
          amnRequirement: data.amnRequirement,
        }),
      );
    } else
      yield put(
        placementSummaryActions.setServiceFailureByKey({ key: 'educationAmnRequirement', failureStatus: true }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestEducationAmnRequirement',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

function* requestExperinceRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('experienceRequirement'));
    const placementService: PlacementService = yield getContext('placementService');

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.summary?.submission?.amnSubmissionBusinessRequrement?.experience?.status,
        data: consolidatedDetails.summary?.submission?.amnSubmissionBusinessRequrement?.experience?.data
      }
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackExperienceAmnReqWrapper,
        placementService.getPlacementSummaryExperience_Req,
        action.payload.placementId,
        action.payload.placementOrderType,
      );
    }

    if (httpSuccess(response?.status)) {
      const data = GetPlacementSummaryMessage(response, action.payload.placementOrderType);

      yield fork(SetPlacementFailRiskRequirementMessage, {
        payload: {
          messageId: data?.amnRequirement?.messageId,
          version: data?.amnRequirement?.messageVersion,
          passFailType: data?.amnRequirement?.type,
          data: data?.amnRequirement?.data,
          messageTypeId: IPlacementRequirementKey.amnExperience,
          tooltipTypeParam: EPLacementStatusRequirementTooltipType.messageWithSupportList,
          nextStatusRequirementParam: data.nextStatusRequirement,
        },
        type: placementSummaryActions.setFailedRequirement.type,
      });

      yield put(
        placementSummaryActions.setSubmissionInfoByKey({
          key: 'experience',
          ...data,
          amnRequirement: data.amnRequirement,
        }),
      );
      yield put(placementSummaryActions.setSubmissionInfoByKey({ key: 'experience', isOrderReq: true, ...data }));
    } else
      yield put(placementSummaryActions.setServiceFailureByKey({ key: 'experienceRequirement', failureStatus: true }));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestExperinceRequirement',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

function* requestWorkHistorySubmissionRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('workHistorySubmissionRequirement'));
    const orderType = yield select(selectPlacementOrderType);
    const placementService: PlacementService = yield getContext('placementService');

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      let amnWorkHistory = consolidatedDetails.summary?.submission?.amnSubmissionBusinessRequrement?.workHistory?.data;
      if (amnWorkHistory) {
        const updatedAmnWorkHistory = {
          ...amnWorkHistory,
        };
        updatedAmnWorkHistory.orderRequirement = 
        consolidatedDetails.summary?.submission?.orderSubmissionBusinessRequrement?.workHistory?.data?.orderRequirement;
        amnWorkHistory = updatedAmnWorkHistory;
      }

      response = {
        status: consolidatedDetails.summary?.submission?.amnSubmissionBusinessRequrement?.workHistory?.status,
        data: amnWorkHistory
      }
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackWorkHistorySubmissionReqWrapper,
        placementService.getPlacementDetailWorkHistory,
        action.payload.placementId,
        orderType,
      );
    }

    if (httpSuccess(response?.status)) {
      const data = GetPlacementSummaryMessage(response, orderType);

      yield put(
        placementSummaryActions.setSubmissionInfoByKey({
          key: 'workHistory',
          ...data,
        }),
      );

      yield fork(SetPlacementFailRiskRequirementMessage, {
        payload: {
          messageId: data?.amnRequirement?.messageId,
          version: data?.amnRequirement?.messageVersion,
          passFailType: data?.amnRequirement?.type,
          data: data?.amnRequirement?.data,
          messageTypeId: IPlacementRequirementKey.amnWorkHistory,
          tooltipTypeParam: EPLacementStatusRequirementTooltipType.message,
          nextStatusRequirementParam: data.nextStatusRequirement,
        },
        type: placementSummaryActions.setFailedRequirement.type,
      });
      if (action.payload.requirementCheckFlagEnabled) {
        const { data: orderData } = response;

        yield put(
          placementSummaryActions.setSubmissionInfoByKey({
            key: 'workHistory',
            isOrderReq: true,
            ...orderData,
          }),
        );

        yield fork(SetPlacementFailRiskRequirementMessage, {
          payload: {
            messageId: orderData?.orderRequirement?.messageId,
            version: orderData?.orderRequirement?.messageVersion,
            passFailType: orderData?.orderRequirement?.type,
            data: orderData?.orderRequirement?.data,
            messageTypeId: IPlacementRequirementKey.orderWorkHistory,
            tooltipTypeParam: EPLacementStatusRequirementTooltipType.message,
            nextStatusRequirementParam: orderData.nextStatusRequirement,
          },
          type: placementSummaryActions.setFailedRequirement.type,
        });
      }
    } else
      yield put(
        placementSummaryActions.setServiceFailureByKey({
          key: 'workHistorySubmissionRequirement',
          failureStatus: true,
        }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'workHistorySubmissionRequirement',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

function* requestReferenceAmnRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    const placementOrderType = yield select(selectPlacementOrderType);
    yield put(resetFailureStatus('referenceAmnRequirement'));
    const placementService: PlacementService = yield getContext('placementService');

    const response = yield call(
      TrackReferenceAmnReqWrapper,
      placementService.getPlacementSummaryReferences_AmnReq,
      action.payload.placementId,
      placementOrderType,
    );

    if (httpSuccess(response?.status)) {
      const { data } = response;

      yield put(
        placementSummaryActions.setSubmissionInfoByKey({
          key: 'references',
          ...data,
          amnRequirement: null,
        }),
      );

      // if (data.candidateRequirement.type === requirementType.risk) {
      //   yield all(
      //     data.nextStatusRequirement.map(status =>
      //       put(
      //         placementStatusAction.setWarningRequirementForStatus({
      //           status,
      //           requirement: {
      //             id: IPlacementRequirementKey.amnReferences,
      //             message: 'No Approved References',
      //           },
      //         }),
      //       ),
      //     ),
      //   );
      // }
    } else
      yield put(
        placementSummaryActions.setServiceFailureByKey({ key: 'referenceAmnRequirement', failureStatus: true }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestReferenceAmnRequirement',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

function* requestSkillsChecklistRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('skillsChecklistRequirement'));
    const orderType = yield select(selectPlacementOrderType);
    const placementService: PlacementService = yield getContext('placementService');

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.summary?.submission?.amnSubmissionBusinessRequrement?.skillsChecklist?.status,
        data: consolidatedDetails.summary?.submission?.amnSubmissionBusinessRequrement?.skillsChecklist?.data
      }
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackSkillsChecklistReqWrapper,
        placementService.getPlacementSummarySkillsChecklist,
        action.payload.placementId,
        orderType,
      );
    }

    if (httpSuccess(response?.status)) {
      const data = GetPlacementSummaryMessage(response, orderType);
      // ORDER Requirement
      yield put(
        placementSummaryActions.setSubmissionInfoByKey({
          key: 'skillsCheckList',
          isOrderReq: true,
          ...data,
        }),
      );
      // END ORDER Requirement

      // AMN Requirement
      yield put(
        placementSummaryActions.setSubmissionInfoByKey({
          key: 'skillsCheckList',
          ...data,
        }),
      );
      yield fork(SetPlacementFailRiskRequirementMessage, {
        payload: {
          messageId: data?.amnRequirement?.messageId,
          version: data?.amnRequirement?.messageVersion,
          passFailType: data?.amnRequirement?.type,
          data: data?.amnRequirement?.data,
          messageTypeId: IPlacementRequirementKey.amnSkillsChecklist,
          tooltipTypeParam: EPLacementStatusRequirementTooltipType.messageWithSupportList,
          nextStatusRequirementParam: data.nextStatusRequirement,
        },
        type: placementSummaryActions.setFailedRequirement.type,
      });

      // END AMN Requirement
    } else
      yield put(
        placementSummaryActions.setServiceFailureByKey({ key: 'skillsChecklistRequirement', failureStatus: true }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestSkillsChecklistRequirement',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

function* requestApplicationAmnRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('applicationAmnRequirement'));
    const placementService: PlacementService = yield getContext('placementService');
    const placementOrderType = yield select(selectPlacementOrderType);

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.summary?.submission?.amnSubmissionBusinessRequrement?.application?.status,
        data: consolidatedDetails.summary?.submission?.amnSubmissionBusinessRequrement?.application?.data
      }
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackApplicationAmnReqWrapper,
        placementService.getPlacementSummaryApplication_AmnReq,
        action.payload.placementId,
        placementOrderType,
      );
    }

    if (httpSuccess(response?.status)) {
      const { data } = response;
      yield put(
        placementSummaryActions.setSubmissionInfoByKey({
          key: 'application',
          ...data,
        }),
      );

      yield fork(SetPlacementFailRiskRequirementMessage, {
        payload: {
          messageId: 1017,
          version: 'v1',
          passFailType: data.amnRequirement.data.rightToWorkVerificationType,
          data: data.candidateRequirement.data.rightToWorkVerification,
          messageTypeId: IPlacementRequirementKey.amnUsRightToWork,
          tooltipTypeParam: undefined,
          nextStatusRequirementParam: data.nextStatusRequirement,
        },
        type: placementSummaryActions.setFailedRequirement.type,
      });

      yield fork(SetPlacementFailRiskRequirementMessage, {
        payload: {
          messageId: 1021,
          version: 'v1',
          passFailType: data.amnRequirement.data.suspensionType,
          data: data.candidateRequirement.data.suspension,
          messageTypeId: IPlacementRequirementKey.amnSuspension,
          tooltipTypeParam: undefined,
          nextStatusRequirementParam: data.nextStatusRequirement,
        },
        type: placementSummaryActions.setFailedRequirement.type,
      });
    } else
      yield put(
        placementSummaryActions.setServiceFailureByKey({ key: 'applicationAmnRequirement', failureStatus: true }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestApplicationAmnRequirement',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}
/** Submission AMN Requirements - End */

/** Submission Order Requirements - Start */
function* requestStatusOrderRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('statusOrderRequirement'));

    const placementService: PlacementService = yield getContext('placementService');
    const placementOrderType = yield select(selectPlacementOrderType);

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.summary?.submission?.orderSubmissionBusinessRequrement?.status?.status,
        data: consolidatedDetails.summary?.submission?.orderSubmissionBusinessRequrement?.status?.data
      }
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackStatusOrderReqWrapper,
        placementService.getPlacementSummaryStatus_OrderReq,
        action.payload.placementId,
        placementOrderType,
      );
    }

    if (httpSuccess(response?.status)) {
      const { data } = response;
      yield put(placementSummaryActions.setSubmissionInfoByKey({ key: 'status', isOrderReq: true, ...data }));

      yield fork(SetPlacementFailRiskRequirementMessage, {
        payload: {
          messageId: data?.orderRequirement?.messageId,
          version: data?.orderRequirement?.messageVersion,
          passFailType: data.orderRequirement.type,
          data: data?.orderRequirement?.data,
          messageTypeId: IPlacementRequirementKey.orderMaxFilesSent,
          tooltipTypeParam: undefined,
          nextStatusRequirementParam: data.nextStatusRequirement,
        },
        type: placementSummaryActions.setFailedRequirement.type,
      });
    } else
      yield put(placementSummaryActions.setServiceFailureByKey({ key: 'statusOrderRequirement', failureStatus: true }));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestStatusOrderRequirement',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

function* requestFacilityStatusOrderRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('facilityStatusOrderRequirement'));

    const placementService: PlacementService = yield getContext('placementService');
    const placementOrderType = yield select(selectPlacementOrderType);

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.summary?.offer?.facilityStatus?.status,
        data: consolidatedDetails.summary?.offer?.facilityStatus?.data
      }
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackFacilityStatusOrderReqWrapper,
        placementService.getPlacementSummaryFacilityStatus_OrderReq,
        action.payload.placementId,
        placementOrderType,
      );
    }

    if (httpSuccess(response?.status)) {
      const { data } = response;
      yield put(placementSummaryActions.setSubmissionInfoByKey({ key: 'facilityStatus', isOrderReq: true, ...data }));

      yield fork(SetPlacementFailRiskRequirementMessage, {
        payload: {
          messageId: data?.orderRequirement?.messageId,
          version: data?.orderRequirement?.messageVersion,
          passFailType: data?.orderRequirement?.type,
          data: data?.orderRequirement?.data,
          messageTypeId: IPlacementRequirementKey.facilityStatus,
          tooltipTypeParam: undefined,
          nextStatusRequirementParam: data?.nextStatusRequirement,
        },
        type: placementSummaryActions.setFailedRequirement.type,
      });
    } else
      yield put(
        placementSummaryActions.setServiceFailureByKey({ key: 'facilityStatusOrderRequirement', failureStatus: true }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestFacilityStatusOrderRequirement',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

export function* requestDOBStatusOrderRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('dobStatusOrderRequirement'));

    const placementService: PlacementService = yield getContext('placementService');
    const placementOrderType = yield select(selectPlacementOrderType);

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.summary?.submission?.orderSubmissionBusinessRequrement?.dob?.status,
        data: consolidatedDetails.summary?.submission?.orderSubmissionBusinessRequrement?.dob?.data
      }
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackOrderDOBOrderReqWrapper,
        placementService.getPlacementSummaryDOBStatus_OrderReq,
        action.payload.placementId,
        placementOrderType,
      );
    }

    if (httpSuccess(response?.status)) {
      const { data } = response;
      yield put(placementSummaryActions.setSubmissionInfoByKey({ key: 'orderDob', isOrderReq: true, ...data }));
    } else
      yield put(
        placementSummaryActions.setServiceFailureByKey({ key: 'dobStatusOrderRequirement', failureStatus: true }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestDOBStatusOrderRequirement',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

export function* requestSsnStatusOrderRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('ssnStatusOrderRequirement'));

    const placementService: PlacementService = yield getContext('placementService');
    const placementOrderType = yield select(selectPlacementOrderType);

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.summary?.submission?.orderSubmissionBusinessRequrement?.ssn?.status,
        data: consolidatedDetails.summary?.submission?.orderSubmissionBusinessRequrement?.ssn?.data
      }
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackOrderSsnOrderReqWrapper,
        placementService.getPlacementSummarySsnStatus_OrderReq,
        action.payload.placementId,
        placementOrderType,
      );
    }

    if (httpSuccess(response?.status)) {
      const { data } = response;
      yield put(placementSummaryActions.setSubmissionInfoByKey({ key: 'orderSsn', isOrderReq: true, ...data }));
    } else
      yield put(
        placementSummaryActions.setServiceFailureByKey({ key: 'ssnStatusOrderRequirement', failureStatus: true }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestSsnStatusOrderRequirement',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

function* requestWorkHistoryOrderRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('workHistoryOrderRequirement'));
    /*const response = yield call(
      TrackExperienceOrderReqWrapper,
      getPlacementSummaryExperience_OrderReq,
      action.payload.placementId,
    );

    if (httpSuccess(response.status)) {
      const { data } = response;
      yield put(placementSummaryActions.setSubmissionInfoByKey({ key: 'workHistory', isOrderReq: true, ...data }));
    } else{
      yield put(
        placementSummaryActions.setServiceFailureByKey({ key: 'workHistoryOrderRequirement', failureStatus: true }),
      );
    }*/
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestWorkHistoryOrderRequirement',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

function* requestReferenceOrderRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('referenceOrderRequirement'));
    const placementService: PlacementService = yield getContext('placementService');
    const placementOrderType = yield select(selectPlacementOrderType);

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.summary?.submission?.orderSubmissionBusinessRequrement?.references?.status,
        data: consolidatedDetails.summary?.submission?.orderSubmissionBusinessRequrement?.references?.data
      }
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackReferenceOrderReqWrapper,
        placementService.getPlacementSummaryReference_OrderReq,
        action.payload.placementId,
        placementOrderType,
      );
    }

    if (httpSuccess(response?.status)) {
      const { data } = response;

      if (action.payload.requirementCheckFlagEnabled) {
        yield fork(SetPlacementFailRiskRequirementMessage, {
          payload: {
            messageId: data?.orderRequirement?.messageId,
            version: data?.orderRequirement?.messageVersion,
            passFailType: data?.orderRequirement?.type,
            data: data?.orderRequirement?.data,
            messageTypeId: IPlacementRequirementKey.orderReferences,
            tooltipTypeParam: undefined,
            nextStatusRequirementParam: data?.nextStatusRequirement,
          },
          type: placementSummaryActions.setFailedRequirement.type,
        });
      }

      yield fork(SetPlacementFailRiskRequirementMessage, {
        payload: {
          messageId: data?.orderRequirement?.messageId,
          version: data?.orderRequirement?.messageVersion,
          passFailType: data?.orderRequirement?.type,
          data: data?.orderRequirement?.data,
          messageTypeId: IPlacementRequirementKey.orderReferences,
          tooltipTypeParam: undefined,
          nextStatusRequirementParam: data?.nextStatusRequirement,
        },
        type: placementSummaryActions.setFailedRequirement.type,
      });

      yield fork(SetPlacementFailRiskRequirementMessage, {
        payload: {
          messageId: data?.candidateRequirement?.messageId,
          version: data?.candidateRequirement?.messageVersion,
          passFailType: data?.candidateRequirement?.type,
          data: data?.candidateRequirement?.data,
          messageTypeId: IPlacementRequirementKey.orderReferences,
          tooltipTypeParam: undefined,
          nextStatusRequirementParam: [placementStatusOptions.waitingForClearance],
        },
        type: placementSummaryActions.setFailedRequirement.type,
      });

      yield put(placementSummaryActions.setSubmissionInfoByKey({ key: 'references', isOrderReq: true, ...data }));
    } else
      yield put(
        placementSummaryActions.setServiceFailureByKey({ key: 'referenceOrderRequirement', failureStatus: true }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestReferenceOrderRequirement',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

function* processMilesOrderRequirement(data) {
  try {
    const candidateAddress = data.candidateRequirement.data;
    const facilityAddress = data.orderRequirement.data;

    if (candidateAddress && facilityAddress)
      yield put(
        placementDetailsAction.setDrivingDistanceMapLink(
          formatPlacementGoogleMapLink(
            {
              address: candidateAddress.candidateAddress1,
              state: candidateAddress.candidateState,
              city: candidateAddress.candidateCity,
              zip: candidateAddress.candidateZip,
            },
            {
              address: facilityAddress.facilityAddress1,
              state: facilityAddress.facilityState,
              city: facilityAddress.facilityCity,
              zip: facilityAddress.facilityZip,
            },
          ),
        ),
      );

    if (data.candidateRequirement.data.candidateAddressLastUpdated === missingDate)
      data.candidateRequirement.data.candidateAddressLastUpdated = null;
    yield put(placementSummaryActions.setSubmissionInfoByKey({ key: 'milesRadius', isOrderReq: true, ...data }));

    yield fork(SetPlacementFailRiskRequirementMessage, {
      payload: {
        messageId: data?.orderRequirement?.messageId,
        version: data?.orderRequirement?.messageVersion,
        passFailType: data.orderRequirement.type,
        data: data?.orderRequirement?.data,
        messageTypeId: IPlacementRequirementKey.orderRadius,
        tooltipTypeParam: undefined,
        nextStatusRequirementParam: data.nextStatusRequirement,
      },
      type: placementSummaryActions.setFailedRequirement.type,
    });
  } catch (exception) {
    trackException({
      exception,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'processMilesOrderRequirement',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

function* requestMilesRadiusOrderRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('milesRadiusOrderRequirement'));
    const placementOrderType = yield select(selectPlacementOrderType);
    const placementService: PlacementService = yield getContext('placementService');

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.summary?.submission?.orderSubmissionBusinessRequrement?.radiusRule?.status,
        data: consolidatedDetails.summary?.submission?.orderSubmissionBusinessRequrement?.radiusRule?.data
      }
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackMilesRadiusOrderReqWrapper,
        placementService.getPlacementSummaryMilesRadius_OrderReq,
        action.payload.placementId,
        placementOrderType,
      );
    }

    if (httpSuccess(response?.status)) {
      yield fork(processMilesOrderRequirement, response.data);
    } else
      yield put(
        placementSummaryActions.setServiceFailureByKey({ key: 'milesRadiusOrderRequirement', failureStatus: true }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestMilesRadiusOrderRequirement',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

export function* refreshPlacementDrivingDistance() {
  try {
    const {
      placementOrderType,
      header: { placementId },
    } = yield select(selectPlacementDetails);

    const placementService: PlacementService = yield getContext('placementService');
    const response = yield call(TrackRefreshDrivingDistance, placementService.refreshPlacementDrivingDistance, {
      placementId,
      placementOrderType: getPlacementOrderTypeValue(placementOrderType),
    });

    if (httpSuccess(response?.status)) {
      yield call(processMilesOrderRequirement, response.data);
      const candidateAddress = response.data.candidateRequirement.data;
      yield put(
        placementDetailsAction.setCandidateAddress({
          address1: candidateAddress.candidateAddress1,
          city: candidateAddress.candidateCity,
          stateProvinceID: candidateAddress.candidateState,
          zip: candidateAddress.candidateZip,
          addressType: candidateAddress.addressType,
          addressTypeId: candidateAddress.addressTypeId,
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'refreshDrivingDistance',
        area: 'src/app/components/Placement/PlacementDetails/store/PlacementDetails.saga.ts',
      },
    });
  }
}

/*function* requestInterviewOrderRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('interviewOrderRequirement'));
    const response = yield call(
      TrackInterviewOrderReqWrapper,
      getPlacementSummaryInterview_OrderReq,
      action.payload.placementId,
    );
    if (httpSuccess(response.status)) {
      const { data } = response;
      yield put(placementSummaryActions.setSubmissionInfoByKey({ key: 'interview', isOrderReq: true, ...data }));
    } else
      yield put(
        placementSummaryActions.setServiceFailureByKey({ key: 'interviewOrderRequirement', failureStatus: true }),
      );
  } catch (error) {
    console.log('ERROR', error);
  }
}*/

/*function* requestDobOrderRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('dobOrderRequirement'));
    const response = yield call(TrackDobOrderReqWrapper, getPlacementSummaryDOB_OrderReq, action.payload.placementId);
    if (httpSuccess(response.status)) {
      const { data } = response;
      yield put(placementSummaryActions.setSubmissionInfoByKey({ key: 'dob', isOrderReq: true, ...data }));
    } else
      yield put(placementSummaryActions.setServiceFailureByKey({ key: 'dobOrderRequirement', failureStatus: true }));
  } catch (error) {
    console.log('ERROR', error);
  }
}*/

/*function* requestSsnOrderRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('ssnOrderRequirement'));
    const response = yield call(TrackSsnOrderReqWrapper, getPlacementSummarySSN_OrderReq, action.payload.placementId);
    if (httpSuccess(response.status)) {
      const { data } = response;
      yield put(placementSummaryActions.setSubmissionInfoByKey({ key: 'ssn', isOrderReq: true, ...data }));
    } else
      yield put(placementSummaryActions.setServiceFailureByKey({ key: 'ssnOrderRequirement', failureStatus: true }));
  } catch (error) {
    console.log('ERROR', error);
  }
}*/

function* requestDocumentOrderRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('documentsOrderRequirement'));
    const placementService: PlacementService = yield getContext('placementService');

    const response = yield call(
      TrackDocumentOrderReqWrapper,
      placementService.getPlacementSummaryDocuments_OrderReq,
      action.payload.placementId,
    );
    if (httpSuccess(response?.status))
      yield put(
        placementSummaryActions.setSubmissionInfoByKey({ key: 'documents', isOrderReq: true, ...response.data }),
      );
    else
      yield put(
        placementSummaryActions.setServiceFailureByKey({ key: 'documentsOrderRequirement', failureStatus: true }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestDocumentOrderRequirement',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

/**Grouped Skillset Start */



function* requestOrderInformationRequirment(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('orderInformationRequirement'));
    const placementService: PlacementService = yield getContext('placementService');

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.summary?.orderInformation?.status,
        data: consolidatedDetails.summary?.orderInformation?.data
      }
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackOrderInfoWrapper,
        placementService.getPlacementOrderInformation,
        action.payload.placementId,
      );
    }

    if (httpSuccess(response?.status)) yield put(placementSummaryActions.setOrderInformation(response.data));
    else
      yield put(
        placementSummaryActions.setServiceFailureByKey({ key: 'orderInformationRequirement', failureStatus: true }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestOrderInformationRequirment',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

function* requestAuditDetails(action: PayloadAction<IAuditRequest>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');

    const response = yield call(
      TrackAuditDetailsWrapper,
      placementService.getAuditDetails,
      action.payload.candidateId,
      action.payload.placementOrderType,
    );
    if (httpSuccess(response?.status))
      yield put(placementSummaryActions.setAuditDetails(formatAuditRecords(response.data)));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestAuditDetails',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

function* requestReferenceAndEmploymentVerificationRecords(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.common?.candidateReferenceDetails?.status,
        data: consolidatedDetails.common?.candidateReferenceDetails?.data
      }
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        TrackReferenceAndEmploymentVerificationWrapper,
        placementService.getReferenceAndEmploymentVerficationRecords,
        action.payload.placementId,
      );
    }

    if (httpSuccess(response?.status)) {
      const records = extractReferenceAndEmploymentVerifications(response.data);
      yield put(placementSummaryActions.setReferenceAndEmploymentVerificationRecords(records));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestReferenceAndEmploymentVerificationRecords',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

export function* refreshEditableData(params: { placementId: number }) {
  try {
    yield call(requestOfferConfirmationRequirement, {
      payload: { placementId: params.placementId, ...ServiceConstants.offerConfirmation },
      type: 'GET_OFFER_CONFIRMATION',
    });
    yield call(requestTransportationData, {
      payload: { placementId: params.placementId, ...ServiceConstants.offerTransportation },
      type: 'GET_OFFER_INFO_TRANSPORTATION_DATA',
    });
    yield call(requestBookingConfirmationRequirement, {
      payload: { placementId: params.placementId, ...ServiceConstants.bookingConfirmation },
      type: 'GET_BOOKING_CONFIRMATION_REQUIREMENT',
    });
    yield call(requestBookingCredentialingRequirement, {
      payload: { placementId: params.placementId, ...ServiceConstants.bookingCredentialing },
      type: 'GET_BOOKING_CREDENTIALING_REQUIREMENT',
    });
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'refreshEditableData',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

/** Booking - End */

export function* syncWithPeopleSoft(action: PayloadAction<{ placementId: number }>) {
  try {
    manuallyIncrementPromiseCounter(PromiseTrackerKeys.placements.peopleSoftSync);

    const response = yield call(TrackPeopleSoftSync, syncPlacementWithPeopleSoft, [action.payload.placementId]);

    if (!httpSuccess(response?.status)) {
      yield put(globalActions.setSnackBar({ message: 'PeopleSoft sync failed', severity: 'error' }));
      yield put(
        placementSummaryActions.setPeopleSoft({
          lastSync: missingField,
          syncStatus: 2,
          syncStatusLabel: 'Failure',
          description: 'PeopleSoft sync failed. Please try again.',
          awaitingResultsCount: 0,
        }),
      );
      throw new Error('sync failed');
    }
    manuallyResetPromiseCounter(PromiseTrackerKeys.placements.peopleSoftSync);
    manuallyIncrementPromiseCounter(PromiseTrackerKeys.placements.peopleSoftAwaitResults);

    // Wait 30 seconds before checking if results are available from PeopleSoft
    for (let i = 0; i < 120; i++) {
      yield delay(125);
      yield put(placementSummaryActions.setPeopleSoftAwaitingResultsCount(Math.min((i / 120) * 100, 95)));
    }
    yield call(requestPeopleSoftDetails, {
      payload: { placementId: action.payload.placementId },
      type: placementSummaryActions.getPeopleSoftDetails.type,
    });
    yield put(placementSummaryActions.setPeopleSoftAwaitingResultsCount(100));
    manuallyResetPromiseCounter(PromiseTrackerKeys.placements.peopleSoftAwaitResults);
  } catch (error) {
    manuallyResetPromiseCounter(PromiseTrackerKeys.placements.peopleSoftSync);
    manuallyResetPromiseCounter(PromiseTrackerKeys.placements.peopleSoftAwaitResults);
  }
}

export function* requestPeopleSoftDetails(action: PayloadAction<{ placementId: number }>) {
  try {
    manuallyIncrementPromiseCounter(PromiseTrackerKeys.placements.peopleSoftGetDetails);

    const response = yield call(TrackPeopleSoft, getPeopleSoftDetailsAPI, action.payload.placementId);

    if (httpSuccess(response?.status) && response?.data) {
      const data = Array.isArray(response.data) ? (response.data as any[])[response.data.length - 1] : response.data;
      const lastSync = moment(data.createdDate);
      yield put(
        placementSummaryActions.setPeopleSoft({
          lastSync: lastSync.isValid() ? lastSync.format('MM/DD/YYYY hh:mm:ss A') : missingField,
          syncStatus: data.result === 3 ? 2 : data.result,
          syncStatusLabel: data.message ?? missingField, //data.status === 1 ? 'Successful' : data.status === 2 ? 'Failure' : missingField,
          description: data.description ?? missingField,
          awaitingResultsCount: 0,
        }),
      );
    }
    manuallyResetPromiseCounter(PromiseTrackerKeys.placements.peopleSoftGetDetails);
  } catch (error) {
    manuallyResetPromiseCounter(PromiseTrackerKeys.placements.peopleSoftGetDetails);
    console.error('ERROR: getPeopleSoftDetails', error);
  }
}
export function* requestSubmissionPacketDetails(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = {
        status: consolidatedDetails.common?.submissionPacketStatusDetails?.status,
        data: consolidatedDetails.common?.submissionPacketStatusDetails?.data
      }
    }

    if (!response?.status || !response?.data) {
      response = yield call(
        placementService.getSubmissionPacketDetails,
        action.payload.placementId,
        action.payload.placementOrderType,
      );
    }

    if (httpSuccess(response?.status)) {
      /** Placeholder Store state into redux */
      yield put(placementSummaryActions.setSubmissionPacketDetail(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestSubmissionPacketDetails',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

export function* checkForClassicSubmissionPacketsInOkToSend(action: PayloadAction<{
  placementId: number, isInitLoad?: boolean | false
}>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if (action.payload.isInitLoad && consolidatedDetails) {
      response = consolidatedDetails.common.okToSendPacketStatus;
    }

    if (!response?.status) {
      response = yield call(
        TrackClassicPacketInOkToSend,
        placementService.getClassicPacketInOkToSendExists,
        action.payload.placementId,
      );
    }

    if (httpSuccess(response?.status)) {
      yield put(placementSummaryActions.setClassicSubmissionPacketInOkToSendExists(response?.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'checkForClassicSubmissionPacketsInOkToSend',
        area: 'src/app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/store/placementSummary.saga.ts',
      },
    });
  }
}

export function* placementSummarySaga() {
  /** Failed reuirements - start */
  /** Code: takeEvery is used since the action shall be dispatched for dozens of unrelated reuirements */
  yield takeEvery(placementSummaryActions.setFailedRequirement.type, setFailedRequirement);
  /** Failed reuirements - end */
  yield takeLatest(placementSummaryActions.syncWithPeopleSoftAction.type, syncWithPeopleSoft);
  yield takeLatest(placementSummaryActions.getPeopleSoftDetails.type, requestPeopleSoftDetails);
  yield takeLatest(placementSummaryActions.getSkillsetRequirement.type, requestSkillsetRequirement);
  yield takeLatest(placementSummaryActions.getStatusAmnRequirement.type, requestStatusAmnRequirement);
  yield takeLatest(placementSummaryActions.getAuditAmnRequirement.type, requestAuditAmnRequirement);
  yield takeLatest(placementSummaryActions.getEducationAmnRequirement.type, requestEducationAmnRequirement);
  yield takeLatest(placementSummaryActions.getExperienceRequirement.type, requestExperinceRequirement);
  yield takeLatest(
    placementSummaryActions.workHistorySubmissionRequirement.type,
    requestWorkHistorySubmissionRequirement,
  );
  yield takeLatest(placementSummaryActions.getAuditRecords.type, requestAuditDetails);
  yield takeLatest(
    placementSummaryActions.getReferenceAndEmploymentVerificationRecords.type,
    requestReferenceAndEmploymentVerificationRecords,
  );

  yield takeLatest(placementSummaryActions.getReferenceAmnRequirement.type, requestReferenceAmnRequirement);
  yield takeLatest(placementSummaryActions.getSkillSChecklistRequirement.type, requestSkillsChecklistRequirement);
  yield takeLatest(placementSummaryActions.getApplicationAmnRequirement.type, requestApplicationAmnRequirement);

  yield takeLatest(placementSummaryActions.getStatusOrderRequirement.type, requestStatusOrderRequirement);
  yield takeLatest(
    placementSummaryActions.getFacilityStatusOrderRequirement.type,
    requestFacilityStatusOrderRequirement,
  );
  yield takeLatest(placementSummaryActions.getSsnStatusOrderRequirement.type, requestSsnStatusOrderRequirement);
  yield takeLatest(placementSummaryActions.getDOBStatusOrderRequirement.type, requestDOBStatusOrderRequirement);
  yield takeLatest(placementSummaryActions.getDatesOrderRequirement.type, requestDatesOrderRequirement);
  yield takeLatest(placementSummaryActions.getScheduleOrderRequirement.type, requestScheduleOrderRequirement);
  yield takeLatest(placementSummaryActions.getWorkHistoryOrderRequirement.type, requestWorkHistoryOrderRequirement);
  yield takeLatest(placementSummaryActions.getReferenceOrderRequirement.type, requestReferenceOrderRequirement);
  yield takeLatest(placementSummaryActions.getLicenseOrderRequirement.type, requestLicenseOrderRequirement);
  yield takeLatest(placementSummaryActions.getCertificationOrderRequirement.type, requestCertificationOrderRequirement);
  yield takeLatest(placementSummaryActions.getMilesRadiusOrderRequirement.type, requestMilesRadiusOrderRequirement);
  yield takeLatest(placementSummaryActions.refreshDrivingDistance.type, refreshPlacementDrivingDistance);
  yield takeLatest(placementSummaryActions.getMiscDetailsOrderRequirement.type, requestMiceDetailsOrderRequirement);
  yield takeLatest(placementSummaryActions.getCovidOrderRequirement.type, requestCovidOrderRequirement);
  yield takeLatest(placementSummaryActions.getDocumentsOrderRequirement.type, requestDocumentOrderRequirement);
  yield takeLatest(placementSummaryActions.getGroupedSkillSetRequirements.type, requestGroupedSkillsetRequirement);
  yield takeLatest(placementSummaryActions.getVirtualInterviewRequirement.type, requestVirtualInterviewRequirement);
  yield takeLatest(
    placementSummaryActions.getAssignmentInformationRequirement.type,
    requestAssignmentInformationRequirement,
  );
  yield takeLatest(placementSummaryActions.getOrderInformationRequirement.type, requestOrderInformationRequirment);
  yield takeLatest(placementSummaryActions.getTransportationData.type, requestTransportationData);
  yield takeLatest(placementSummaryActions.getOfferConfirmationRequirement.type, requestOfferConfirmationRequirement);
  yield takeLatest(placementSummaryActions.getOfferTravelDetailsRequirement.type, requestOfferTravelDetailsRequirement);
  yield takeLatest(
    placementSummaryActions.getBookingConfirmationRequirement.type,
    requestBookingConfirmationRequirement,
  );
  yield takeLatest(
    placementSummaryActions.getBookingCredentialingRequirement.type,
    requestBookingCredentialingRequirement,
  );
  yield takeLatest(placementSummaryActions.getBookingRequirement.type, requestBookingRequirements);
  yield takeLatest(placementSummaryActions.getBookingApprovedRequirements.type, requestBookingApprovedRequirements);
  yield takeLatest(placementSummaryActions.getWaitingGoodToGoRequirements.type, requestWaitingGoodToGoRequirements);

  //** Pick lists */
  yield takeLatest(placementSummaryActions.getTransportationPicklists.type, requestAirportCodesPicklist);
  yield takeLatest(placementSummaryActions.getTransportationPicklists.type, requestTransportationPicklist);
  yield takeLatest(placementSummaryActions.getTransportationPicklists.type, requestTravelTimePicklist);

  yield takeLatest(placementSummaryActions.getSubmissionPacketDetails.type, requestSubmissionPacketDetails);
  yield takeLatest(
    placementSummaryActions.checkForClassicSubmissionPacketsInOkToSend.type,
    checkForClassicSubmissionPacketsInOkToSend,
  );
  yield takeLatest(
    placementSummaryActions.getArbitrationAgreementRequirements.type,
    requestArbitrationAgreementRequirements,
  );
}
