import { takeLatest, put, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { jobMatchActions } from './JobMatch.redux';
import {
  getCandidateJobMatchCount,
  getCandidateJobMatchOrderBase,
} from 'app/services/CandidateServices/CandidateServices';
import { trackPromise } from 'react-promise-tracker';
import { jobMatchFlattenData } from '../../helper/gridHelper';
import { TFunction } from 'i18next';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';

const TrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'job-match-call');
/**
 * Candidate job preference get action
 * @param action
 */
export function* getJobMatches(
  action: PayloadAction<{
    travelerId: number;
    brandId: number;
    limit: number;
    offset: number;
    matchFilter?: string;
    t: TFunction;
  }>,
) {
  try {
    const response = yield call(
      TrackWrapper,
      getCandidateJobMatchOrderBase,
      action.payload.travelerId,
      action.payload.brandId,
      action.payload.limit,
      action.payload.offset,
      action.payload.matchFilter,
    );
    if (response) {
      if (response.status === 500) yield put(jobMatchActions.setServiceFailure(true));
      else {
        const locations = response.displayJobMatchData.map(item => item.facilityCoordinates);
        const flattenedData = jobMatchFlattenData(response.displayJobMatchData, action.payload.t);
        yield put(jobMatchActions.setJobMatches(response.displayJobMatchData));
        yield put(jobMatchActions.setGridData(flattenedData));
        yield put(jobMatchActions.setJobLocations(locations));
        yield put(jobMatchActions.setCount(response.count));
        yield put(jobMatchActions.setFilteredCount(response.filteredCount));
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getJobMatches',
        area: 'src/app/components/Candidate/CandidateProfile/CandidateJobMatchGrid/JobMatch.saga.ts',
      },
    });
  }
}

export function* getJobMatchCount(action: PayloadAction<number>) {
  try {
    const response = yield call(TrackWrapper, getCandidateJobMatchCount, action.payload);
    if (response) {
      if (response.status === 200) yield put(jobMatchActions.setCount(response.count));
      else yield put(jobMatchActions.setServiceFailure(true));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getJobMatchCount',
        area: 'src/app/components/Candidate/CandidateProfile/CandidateJobMatchGrid/JobMatch.saga.ts',
      },
    });
  }
}

export function* jobMatchSaga() {
  yield takeLatest(jobMatchActions.getJobMatchAction.type, getJobMatches);
  yield takeLatest(jobMatchActions.getJobMatchCountAction.type, getJobMatchCount);
}
