import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './RecruiterPlacements.redux';

const nullCount = {
  inSubmission: 0,
  waitingForClearance: 0,
  requestFileOut: 0,
  sent: 0,
  interviewed: 0,
  offer: 0,
  offerByFacility: 0,
  offerBookedByTraveler: 0,
  facilityConfirms: 0,
  travelerAccepts: 0,
  bookedCategory: 0,
  bookedSubCategory: 0,
  bookingApproved: 0,
  waitingGoodToGo: 0,
  postponementWarning: 0,
  goodToGo: 0,
  onAssignment: 0,
};

const selectDomain = (state: RootState) => state.recruiterPlacements || initialState;

export const selectWorkDeskCounts = createSelector(
  [selectDomain],
  recruiterPlacements =>
    recruiterPlacements.filteredData?.countObject ||
    recruiterPlacements.countObject || {
      candidates: { ...nullCount, candidateCount: 0 },
      placements: { ...nullCount },
    },
);

export const selectWorkDeskGridData = createSelector(
  [selectDomain],
  recruiterPlacements => recruiterPlacements.flattenedData,
);

export const selectFilterSpecs = createSelector([selectDomain], recruiterPlacements => recruiterPlacements.filterSpecs);

export const selectDisplayGridData = createSelector([selectDomain], recruiterPlacements =>
  recruiterPlacements.filteredData ? recruiterPlacements.filteredData.flattenedData : recruiterPlacements.flattenedData,
);

export const selectRecWorkdeskDataLoaded = createSelector([selectDomain], state => state.dataLoaded);

export const selectFilterChips = createSelector([selectDomain], recruiterPlacements => recruiterPlacements.chips);

export const selectFalseRecruiters = createSelector(
  [selectDomain],
  recruiterPlacements => recruiterPlacements.falseRecruiters,
);

export const selectTileCounts = createSelector([selectDomain], recruiterPlacements => recruiterPlacements.tileCounts);
