import {
  Box,
  Toolbar,
  Avatar,
  Grid,
  TextField,
  ClickAwayListener,
  Tooltip,
  IconButton,
  Typography,
  Chip,
  Popover,
  Alert,
  useTheme,
} from 'amn-ui-core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import PersonIcon from '@mui/icons-material/Person';
import RefreshIcon from '@mui/icons-material/Refresh';
import { EMOJI_LIST } from '../Sms/Helpers/EmojiList';
import { ToasterContent } from '../../Common/ToasterContent';
import { toast, Zoom } from 'react-toastify';
import { ChannelType, SendType, UseSubType, UseType } from '../../Constants';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  attachmentDetails,
  notificationSelection,
  selectCommunicationLimitsDialog,
} from 'store/redux-store/notification/notification.selector';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { NotificationContext } from '../../NotificationWrapper';
import { SmsType } from 'app/enums/Sms';
import { getAttachmentIcon } from '../../Common/AttachmentFileTypes';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { selectUser } from 'oidc/user.selectors';
import { SelectOptions } from './SelectOptions';
import { getBulkInboxDataToUpdate } from '../Sms/Helpers/messageHelpers';
import { publishSms } from 'app/services/NotificationServices/NotificationService';
import PreviewIcon from 'app/assets/images/Notification/BulkSms/images/Preview.svg';
import SaveIcon from 'app/assets/images/Notification/BulkSms/images/Save.svg';
import AttachmentIcon from 'app/assets/images/Notification/BulkSms/images/Attachment.svg';
import EmojiIcon from 'app/assets/images/Notification/BulkSms/images/Emoji.svg';
import SendIcon from 'app/assets/images/Notification/BulkSms/images/Send.svg';
import SendDisabledIcon from 'app/assets/images/Notification/BulkSms/images/SendDisabled.svg';
import { selectSearchResults } from 'store/redux-store/global-search/selectors';
import {
  selectCandidateDrawer,
  selectDetailsPageDrawer,
  selectFacilityDrawer,
  selectOrderDrawer,
  selectSmsIconDrawer,
} from '../../../../ApplicationRoot/Global.selectors';
import { Dot } from '../Sms/Helpers/dots';
import { CustomBackdrop } from '@AMIEWEB/Common/Backdrop/CustomBackdrop';
import { usePromiseTracker } from 'react-promise-tracker';
import { ff_notifications_enable_shareddesk_communication } from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { selectCoveredInbox } from 'store/redux-store/user-coverage/selectors';
import { MessageTime, ModalAppBar, useBulkSmsStyles } from './BulkSms.styles';
import { getFileFromUrl, getMultiSelectOptions } from './MessageHelpers';

export const BulkSms = (props: any) => {
  const theme = useTheme();
  const { classes } = useBulkSmsStyles();

  const { promiseInProgress: trackCommunicationLimits } = usePromiseTracker({
    area: 'communication-limits-saga',
    delay: 0,
  });

  const inputRef = useRef<any>(null);
  const [sharedDeskFlag] = useDecision(ff_notifications_enable_shareddesk_communication);

  const [isEmojiListOpen, setEmojiListOpen] = useState(false);
  const [attachments, setAttachments] = useState<any>();
  const validFileFormats = /(\.jpeg|\.jpg|\.png)$/i;
  const { t } = useTranslation();
  const globalData = useSelector(notificationSelection);
  const user = useSelector(selectUser);
  const [candidateData, setCandidatesData] = useState(globalData?.sms?.data?.selectedCandidates);
  const AllCandidateData = globalData?.sms?.data?.candidateData;
  const [showTemplates, setShowTemplates] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { onClose } = useContext(NotificationContext);
  const [smsBody, setSmsBody] = useState<string>(globalData.sms.data.messageBody || '');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const attachment = useSelector(attachmentDetails);
  const [errCandidates, setErrCandidates] = useState<number>(0);
  const { drawerData } = useSelector(selectSearchResults);
  const candidateDrawer = useSelector(selectCandidateDrawer);
  const facilityDrawer = useSelector(selectFacilityDrawer);
  const orderDrawer = useSelector(selectOrderDrawer);
  const detailsPageDrawer = useSelector(selectDetailsPageDrawer);
  const smsIconDrawer = useSelector(selectSmsIconDrawer);
  const limitsDialog = useSelector(selectCommunicationLimitsDialog);
  const coveredInbox = useSelector(selectCoveredInbox);

  const isLoggedUserInActiveInbox = coveredInbox?.activeInbox.coveredUserId === String(user?.userInfo?.employeeId);

  const hasActiveSharedInbox =
    sharedDeskFlag?.enabled &&
    !isLoggedUserInActiveInbox &&
    !!coveredInbox?.activeInbox &&
    coveredInbox?.activeInbox?.coveredUserId == user.userInfo?.sharedProfile?.employeeId;

  const drawerOpened =
    drawerData.open ||
    candidateDrawer.open ||
    facilityDrawer.open ||
    orderDrawer.open ||
    detailsPageDrawer.open ||
    smsIconDrawer?.open;

  const [sendInitiated, setSendInitiated] = useState(false);

  const consentWithdrawnCount = AllCandidateData.filter(function (item) {
    return item.consents === false || item.communicationLimits === true;
  }).length;

  const hasCommunicationLimits = AllCandidateData.some(function (item) {
    return item.communicationLimits === true;
  });

  const getFile = async () => {
    if (attachment) {
      const attachmentTemp = await getFileFromUrl(attachment.url, attachment.name, attachment.type);
      setAttachments(attachmentTemp);
      setShowTemplates(true);
    }
  };

  useEffect(() => {
    getFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const errCandidates = AllCandidateData?.filter(c => c?.phoneNumber === undefined);
    setErrCandidates(errCandidates?.length);
  }, []);

  useEffect(() => {
    if (limitsDialog?.open && limitsDialog?.key === ChannelType.sms) {
      handleClose();
    }
  }, [limitsDialog?.open]);

  const handleMinimize = () => {
    const toasterId = ChannelType.sms;
    dispatch(
      notificationDataActions.setSmsInteraction({
        open: false,
        minimized: true,
      }),
    );
    toast(
      <ToasterContent
        channel={ChannelType.sms}
        id={toasterId}
        info={{ channel: ChannelType.sms }}
        header={sendInitiated ? 'Bulk Message Sending...' : `${candidateData?.length} Candidates`}
        onClose={onClose}
        type={SmsType.bulk}
      />,
      {
        toastId: toasterId,
        containerId: 'channel',
        position: 'bottom-right',
        closeButton: false,
        hideProgressBar: true,
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
        transition: Zoom,
      },
    );
  };

  const handleEmojiSelection = (emoji: { id?: string; name?: string; value: any }) => {
    const cursor = inputRef.current.selectionStart;
    const body =
      smsBody && smsBody.length !== cursor
        ? smsBody.substring(0, cursor) + emoji.value + smsBody.substring(cursor)
        : smsBody && smsBody.length === cursor
        ? smsBody + emoji.value
        : emoji.value;
    setSmsBody(body);
    inputRef.current.focus();
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSmsBody(event.target.value);
  };

  const handleClose = () => {
    dispatch(
      notificationDataActions.setSmsInteraction({
        open: false,
        minimized: false,
      }),
    );
    dispatch(notificationDataActions.setAttachments(undefined));
    toast.dismiss(ChannelType.sms);
    dispatch(notificationDataActions.setCommunicationLimitsSms([]));
  };

  const handleAddAttachments = (event: React.ChangeEvent<HTMLInputElement>, attachmentFile?: undefined) => {
    if (event.target.id === 'sms-btn-upload' || attachmentFile) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const newAttachment = attachmentFile ?? event.target.files[0];
      if (attachments && attachmentFile) {
        dispatch(
          globalActions.setSnackBar({
            message: 'notification.sms.validation.maxFilesSent',
            severity: 'error',
          }),
        );
        return;
      }
      if (!validFileFormats.exec(newAttachment.name.toLowerCase())) {
        dispatch(
          globalActions.setSnackBar({
            message: 'notification.sms.validation.validFileTypes',
            severity: 'error',
          }),
        );
      } else if (newAttachment.size / 1024 > 600) {
        dispatch(
          globalActions.setSnackBar({
            message: 'notification.sms.validation.maxFileSize',
            severity: 'error',
          }),
        );
      } else {
        setAttachments(newAttachment);
        setShowTemplates(true);
        dispatch(
          notificationDataActions.setAttachments({
            url: URL.createObjectURL(newAttachment),
            name: newAttachment.name,
            type: newAttachment!
              .name!.substring(newAttachment!.name!.lastIndexOf('.'), newAttachment!.name!.length)
              .toLowerCase(),
          }),
        );
      }
    }
  };

  const handleDeleteAttachment = () => {
    setAttachments(undefined);
    setShowTemplates(false);
    dispatch(notificationDataActions.setAttachments(undefined));
  };
  //TODO change the type of tos to ContactProps[] eta-12/11/2022

  const handleInboxUpdate = (
    tos: any[],
    messageBody: string | undefined,
    attachments: any,
    sender: {
      name: string;
      email: string | undefined;
      senderId: number | undefined;
      userId: number | undefined;
      NtUserName: string | undefined;
      deviceName: string | undefined;
      phoneNumber: string | undefined;
    },
  ) => {
    const updatedInboxData = getBulkInboxDataToUpdate(tos, messageBody, attachments, sender, globalData.inbox);
    dispatch(notificationDataActions.updateInbox(updatedInboxData));
  };

  const handleSend = async () => {
    setSendInitiated(true);
    const sender = {
      name: user?.userInfo?.firstName + ' ' + user?.userInfo?.lastName,
      email: hasActiveSharedInbox ? coveredInbox?.activeInbox?.coveredUserEmail : user?.userInfo?.email,
      senderId: user?.userInfo?.employeeId,
      userId: user?.userInfo?.employeeId,
      NtUserName: user?.userInfo?.ntUserName,
      deviceName: user?.userInfo?.device,
      phoneNumber: hasActiveSharedInbox ? coveredInbox?.activeInbox?.phoneNumber : user?.userInfo?.phoneNumber,
    };
    const tos = [...candidateData];
    const body = smsBody;
    const payload = {
      brand: user?.userInfo?.companyBrandName,
      brandId: user?.userInfo?.companyId?.toString(),
      useType: UseType.General,
      useSubType: UseSubType.CandidateSearch,
      sendType: tos?.length > 1 ? SendType.bulk : SendType.one_to_one,
      associatedRecords: hasActiveSharedInbox
        ? [
            {
              name: 'sharedUserId',
              value: user?.userInfo?.sharedProfile?.employeeId,
            },
          ]
        : [],
      sender: sender,
      tos: tos,
      body: body,
    };
    const messageForm = new FormData();
    messageForm.append('publishSms', JSON.stringify(payload));
    messageForm.append('attachments', (attachments as Blob) ?? undefined);

    await publishSms(messageForm)
      .then(response => {
        handleInboxUpdate(tos, body, attachments as Blob, sender);
        dispatch(
          globalActions.setSnackBar({
            message: 'The Message has been sent successfully!',
            severity: 'success',
          }),
        );
        handleClose();
        handleInboxUpdate(tos, body, response.data.attachmentUrls, sender);
      })
      .catch(err => {
        dispatch(
          globalActions.setSnackBar({
            message: err.message,
            severity: 'error',
          }),
        );
        setSendInitiated(false);
      });

    dispatch(notificationDataActions.setCommunicationLimitsSms([]));
  };

  const handleOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handlePopoverClose = (event: {}) => {
    setAnchorEl(null);
    setOpen(false);
  };

  const selectCandidates = newValue => {
    setCandidatesData(newValue);
    dispatch(
      notificationDataActions.setSmsData({
        ...globalData.sms,
        data: {
          candidateData: AllCandidateData,
          selectedCandidates: newValue,
          messageBody: smsBody,
          attachments: attachments,
        },
      }),
    );
  };

  return (
    <>
      <div>
        <Box className={`${classes.paper} ${drawerOpened ? classes.paperRightWithDrawerOpened : classes.paperRight}`}>
          {trackCommunicationLimits ? (
            <CustomBackdrop open />
          ) : (
            <>
              <ModalAppBar id="draggableArea" position="relative">
                <Toolbar disableGutters className={classes.modalToolbar}>
                  <Avatar
                    className={classes.avatar}
                    style={{ background: '#34B233' }}
                    onMouseEnter={event => handleOpen(event)}
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                  >
                    {candidateData ? candidateData?.length : <PersonIcon />}
                  </Avatar>
                  <Popover
                    id="mouse-over-popover"
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    onClose={event => handlePopoverClose(event)}
                    className={classes.popover}
                    disableRestoreFocus
                    style={{ overflow: 'visible' }}
                  >
                    <SelectOptions
                      defaultValue={candidateData}
                      onChange={newValue => selectCandidates(newValue)}
                      options={getMultiSelectOptions(AllCandidateData)}
                      removeCloseIcon
                      isMultiSelect
                      enableCustomScroll
                    />
                  </Popover>
                  <Grid>
                    <Grid item className={classes.headerText}>
                      <div>
                        {`Candidates`}
                        <p style={{ color: theme.palette.system.darkRed, fontSize: '12px', margin: '0' }}>
                          {consentWithdrawnCount > 0 &&
                            (!open && !hasCommunicationLimits
                              ? `SMS consent withdrawn`
                              : hasCommunicationLimits
                              ? `${consentWithdrawnCount} errors have been found`
                              : `${consentWithdrawnCount} SMS consent withdrawn`)}
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                  <div className={classes.headerNavigation}>
                    <RefreshIcon className={classes.modalIcon} />
                    <IndeterminateCheckBoxOutlinedIcon onClick={handleMinimize} className={classes.modalIcon} />
                    <CloseIcon className={classes.modalIcon} onClick={handleClose} />
                  </div>
                </Toolbar>
              </ModalAppBar>

              {candidateData?.length < 1 ? (
                <Alert style={{ padding: '6px 50px' }} severity="warning">
                  {t(`Please select a candidate to send SMS`)}
                </Alert>
              ) : errCandidates > 0 ? (
                <Alert style={{ padding: '6px 50px' }} severity="warning">
                  {t(
                    `${errCandidates} ${
                      errCandidates > 1 ? "candidates don't" : "candidate doesn't"
                    } have a phone number`,
                  )}
                </Alert>
              ) : (
                <></>
              )}

              <div className={classes.flex}>
                <div>
                  <div className={classes.heading}>
                    <Typography className={classes.headingText}>{t(`Bulk SMS`)}</Typography>
                  </div>
                  <TextField
                    onChange={event => handleTextChange(event)}
                    onBlur={event => {
                      dispatch(
                        notificationDataActions.setSmsData({
                          ...globalData.sms,
                          data: {
                            candidateData: globalData?.sms?.data?.candidateData,
                            selectedCandidates: candidateData,
                            messageBody: smsBody,
                            attachments: attachments,
                          },
                        }),
                      );
                    }}
                    value={smsBody || globalData.sms.data.messageBody}
                    inputRef={inputRef}
                    className={
                      !showTemplates
                        ? candidateData?.length < 1 || errCandidates > 0
                          ? classes.messageBoxAlert
                          : classes.messageBox
                        : candidateData?.length < 1 || errCandidates > 0
                        ? classes.messageBoxCollapsedAlert
                        : classes.messageBoxCollapsed
                    }
                    placeholder={t('Enter Message')}
                    variant="outlined"
                    multiline
                    maxRows={22}
                    inputProps={{ maxLength: 1600 }}
                    InputProps={{
                      className: classes.input,
                      autoFocus: true,
                    }}
                    disabled={sendInitiated}
                    fullWidth
                  />
                  <Grid>
                    {sendInitiated && (
                      <Grid className={classes.sendingMessage}>
                        <MessageTime style={{ marginRight: '5px', fontSize: '12px' }}>{`Sending Message`}</MessageTime>
                        {[...Array(3)].map(() => (
                          <Dot>
                            <FiberManualRecordIcon style={{ fontSize: '10px' }} />
                          </Dot>
                        ))}
                      </Grid>
                    )}
                  </Grid>
                  <div className={showTemplates ? classes.templates : classes.hide}>
                    {/* 
                  ****TO BE Implemented further****
              <div className={classes.flex}>
                <Typography>{`Templates`}</Typography>
                <IconButton
                  aria-label="show templates"
                  component="span"
                  onClick={() => setShowTemplates(!showTemplates)}
                >
                  // <ArrowIcon />
                </IconButton>
              </div> */}
                    {/* <div className={!showTemplates ? classes.hide : ''}>
                <ul className={classes.templatesList}>
                  <li className={classes.templatesList}>{`Sample 1`}</li>
                  <li className={classes.templatesList}>{`Sample 2`}</li>
                  <li className={classes.templatesList}>{`Sample 3`}</li>
                </ul>
              </div> */}

                    <Grid item xs={9}>
                      {attachments && (
                        <Chip
                          className={classes.attachmentChip}
                          icon={
                            <img
                              src={
                                attachments &&
                                getAttachmentIcon(attachments && attachments?.name?.split('.')?.pop()?.toLowerCase())
                              }
                              alt={t('FileIcon')}
                            />
                          }
                          label={
                            attachments?.name?.length > 15
                              ? attachments?.name.substring(0, 15) + ' ...'
                              : attachments?.name
                          }
                          deleteIcon={<CloseIcon />}
                          onDelete={() => handleDeleteAttachment()}
                        />
                      )}
                    </Grid>
                  </div>
                </div>
                <div className={classes.bulksmsIcons}>
                  <ul
                    className={candidateData?.length < 1 || errCandidates > 0 ? classes.actionsAlert : classes.actions}
                  >
                    <li>
                      <IconButton color="primary" aria-label="save message" component="span" size="small">
                        <img src={SaveIcon} alt={`save button`} />
                      </IconButton>
                    </li>
                    <li className={classes.bulksmsIcon}>
                      <IconButton color="primary" aria-label="preview message" component="span" size="small">
                        <img src={PreviewIcon} alt={`preview button`} />
                      </IconButton>
                    </li>
                    <li className={classes.bulksmsIcon}>
                      <label htmlFor="sms-btn-upload">
                        <input
                          disabled={attachments !== undefined}
                          style={{ display: 'none' }}
                          id="sms-btn-upload"
                          name="sms-btn-upload"
                          type="file"
                          onChange={event => handleAddAttachments(event)}
                        />
                        <IconButton
                          color="primary"
                          disabled={attachments !== undefined}
                          aria-label="upload picture"
                          component="span"
                          size="small"
                        >
                          <img src={AttachmentIcon} alt={`attachment`} />
                        </IconButton>
                      </label>
                    </li>
                    <li className={classes.bulksmsIcon}>
                      <ClickAwayListener onClickAway={() => setEmojiListOpen(false)}>
                        <Tooltip
                          classes={{
                            tooltip: classes.emojiGrid,
                          }}
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={() => setEmojiListOpen(false)}
                          open={isEmojiListOpen}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title={
                            <Grid className={classes.grid} alignItems="center" container spacing={3}>
                              {EMOJI_LIST.map(emoji => {
                                return (
                                  <Grid
                                    item
                                    xs={2}
                                    key={emoji.id}
                                    className={classes.emoji}
                                    onClick={() => handleEmojiSelection(emoji)}
                                  >
                                    <Tooltip
                                      title={emoji.name}
                                      classes={{
                                        tooltip: classes.tooltipBackground,
                                        arrow: classes.tooltipArrow,
                                      }}
                                      arrow
                                    >
                                      <span>{emoji.value}</span>
                                    </Tooltip>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          }
                        >
                          <IconButton
                            color="primary"
                            onClick={() => setEmojiListOpen(!isEmojiListOpen)}
                            aria-label="select emoji"
                            component="span"
                            size="small"
                          >
                            <img src={EmojiIcon} alt={`emjoi button`} />
                          </IconButton>
                        </Tooltip>
                      </ClickAwayListener>
                    </li>
                    <li className={classes.bulksmsIcon}>
                      <IconButton
                        color="primary"
                        aria-label="send message"
                        component="span"
                        onClick={event => handleSend()}
                        disabled={
                          !(
                            candidateData?.length > 0 &&
                            (smsBody?.length > 0 || attachments !== undefined) &&
                            !sendInitiated
                          )
                        }
                        size="small"
                      >
                        {candidateData?.length > 0 &&
                        (smsBody?.length > 0 || attachments !== undefined) &&
                        !sendInitiated ? (
                          <img src={SendIcon} alt={`send message`} />
                        ) : (
                          <img src={SendDisabledIcon} alt={`send message disabled`} className={classes.disabledIcon} />
                        )}
                      </IconButton>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          )}
        </Box>
      </div>
    </>
  );
};
