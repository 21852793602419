import React, { useEffect } from 'react';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  DatePicker,
  LocalizationProvider,
  ThemeProvider,
  StyledEngineProvider,
  TextField,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { secondaryTheme } from 'styles/global-styles';
import { addWeek } from '../../../OrderDetails/helper';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const useStyles = makeStyles<void, 'focused'>()((_theme, _params, classes) => ({
  element: {
    width: '100%',
    height: '100%',
  },
  root: {
    width: '100%',
    border: '1px solid #d2d2d1',
    overflow: 'hidden',
    fontSize: 16,
    borderRadius: 4,
    backgroundColor: '#fff',
    '&:hover': {
      borderColor: '#aaa',
      borderRadius: 4,
      backgroundColor: '#fff',
    },
    [`&.${classes.focused}`]: {
      borderColor: '#aaa',
      backgroundColor: '#fff',
    },
  },
  focused: {},
  labelRoot: {
    fontSize: 16,
    '&:focus': {
      color: '#000',
    },
  },
  error: {
    border: '1px solid #ff0000',
    overflow: 'hidden',
    fontSize: 16,
    borderRadius: 4,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
    [`&.${classes.focused}`]: {
      backgroundColor: '#fff',
    },
  },
}));

export const OrderDateRangePicker = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { handlePropertyChange, order } = props;
  const [selectedStartDate, setSelectedStartDate] = React.useState<Date | null>(order.startDate);
  const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(order.endDate);
  const [selectedPlacementLength, setSelectedPlacementLength] = React.useState<number | null>(order.placementLength);
  const [hasStartDate, setHasStartDate] = React.useState<boolean>(false);
  const [hasEndDate, setHasEndDate] = React.useState<boolean>(false);
  const [asap, setASAP] = React.useState<boolean>(order ? order.ASAP : false);
  const [flexibleDates, setFlexibleDates] = React.useState<boolean>(order ? order.flexibleDates : false);

  useEffect(() => {
    setHasStartDate(selectedStartDate?.toLocaleDateString() !== 'Invalid Date');
    setHasEndDate(selectedEndDate?.toLocaleDateString() !== 'Invalid Date');
    if (
      (selectedStartDate && selectedEndDate && selectedEndDate < selectedStartDate) ||
      (order.ASAP && selectedEndDate && selectedEndDate.toLocaleDateString() === new Date().toLocaleDateString())
    ) {
      setHasStartDate(false);
      setHasEndDate(false);
    } else {
      handlePropertyChange('endDate', selectedEndDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStartDate, selectedEndDate]);

  useEffect(() => {
    if (selectedPlacementLength && selectedStartDate && selectedPlacementLength > 0) {
      if (selectedEndDate && selectedStartDate) {
        if (selectedEndDate !== selectedStartDate) {
          setSelectedEndDate(addWeek(selectedStartDate, selectedPlacementLength));
        }
      } else {
        setSelectedEndDate(addWeek(selectedStartDate, selectedPlacementLength));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlacementLength, selectedStartDate]);

  const handleStartDateChange = (date: Date | null) => {
    if (date?.toLocaleDateString() === 'Invalid Date') {
      setHasStartDate(false);
      return setSelectedStartDate(date);
    }
    setHasStartDate(true);
    setSelectedStartDate(date);
    handlePropertyChange('startDate', date);
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date?.toLocaleDateString() === 'Invalid Date') {
      setHasEndDate(false);
      return setSelectedEndDate(date);
    }
    if (date && selectedStartDate && date <= selectedStartDate) {
      setSelectedStartDate(date);
    }
    setHasEndDate(true);
    setSelectedEndDate(date);
  };

  const validateControl = value => {
    if ((value <= 99 && value >= 0) || value === null) {
      return true;
    }
    return false;
  };

  const handlePlacementLength = event => {
    if (validateControl(event.target.value)) {
      setSelectedPlacementLength(event.target.value);
      handlePropertyChange(event.target.name, event.target.value);
    }
  };

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'ASAP') setASAP(event.target.checked);
    if (event.target.name === 'flexibleDates') setFlexibleDates(event.target.checked);
    handlePropertyChange(event.target.name, event.target.checked);
  };

  return (
    <LayoutGrid style={{ padding: 5 }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={secondaryTheme}>
            <LayoutGrid direction="column" className={classes.element}>
              <LayoutGridItem xs={12} data-testid="test placementLength">
                <TextField
                  id={'newOrderplacementLengthtxt'}
                  name="placementLength"
                  label={t('order.newOrder.placementLength')}
                  variant="filled"
                  type="number"
                  value={order.placementLength}
                  defaultValue={13}
                  placeholder={'0-99*'}
                  onChange={handlePlacementLength}
                  className={classes.element}
                  InputProps={{
                    classes: classes,
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    classes: { root: classes.labelRoot },
                  }}
                />
              </LayoutGridItem>
              <LayoutGridItem xs={12}>
                <LayoutGrid direction="row" spacing={2}>
                  <LayoutGridItem xs={6}>
                    <DatePicker
                      data-testId="test start date"
                      inputFormat="MM/dd/yyyy"
                      label={t('order.newOrder.startDate')}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      className={classes.element}
                      renderInput={p => (
                        <TextField
                          {...p}
                          id="newOrderplacementstartDatePicker"
                          variant="filled"
                          aria-label="change date"
                          placeholder="MM/DD/YYYY"
                          error={!hasStartDate}
                          helperText={!hasStartDate ? 'Invalid Date Format' : undefined}
                          InputProps={{
                            ...p.InputProps,
                            disableUnderline: true,
                            classes: { ...classes, root: !hasStartDate ? classes.error : classes.root },
                          }}
                          InputLabelProps={{
                            ...p.InputLabelProps,
                            classes: { root: classes.labelRoot },
                          }}
                        />
                      )}
                    />
                  </LayoutGridItem>
                  <LayoutGridItem xs={6}>
                    <DatePicker
                      data-testid="test end date"
                      inputFormat="MM/dd/yyyy"
                      label={t('order.newOrder.endDate')}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      className={classes.element}
                      renderInput={p => (
                        <TextField
                          {...p}
                          id={'newOrderplacementendDatePicker'}
                          variant="filled"
                          aria-label="change date"
                          placeholder="MM/DD/YYYY"
                          error={!hasEndDate}
                          helperText={!hasEndDate ? 'Invalid Date Format' : undefined}
                          InputProps={{
                            ...p.InputProps,
                            disableUnderline: true,
                            classes: { ...classes, root: !hasEndDate ? classes.error : classes.root },
                          }}
                          InputLabelProps={{
                            ...p.InputLabelProps,
                            classes: { root: classes.labelRoot },
                          }}
                        />
                      )}
                    />
                  </LayoutGridItem>
                </LayoutGrid>
              </LayoutGridItem>
              <LayoutGridItem>
                <Divider light={true} style={{ marginTop: 2.5 }}></Divider>
              </LayoutGridItem>
              <LayoutGridItem>
                <LayoutGrid direction="row" spacing={2}>
                  <LayoutGridItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={'newOrderplacementasapcheckbox'}
                          data-testid="test asap"
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          checked={asap}
                          onChange={handleSelect}
                          name="ASAP"
                          color="secondary"
                        />
                      }
                      label="ASAP"
                    />
                  </LayoutGridItem>
                  <LayoutGridItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={'newOrderplacementflexibleDatescheckbox'}
                          data-testid="test flex dates"
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          checked={flexibleDates}
                          onChange={handleSelect}
                          name="flexibleDates"
                          color="secondary"
                        />
                      }
                      label="Flexible Dates"
                    />
                  </LayoutGridItem>
                </LayoutGrid>
              </LayoutGridItem>
              <LayoutGridItem>
                <Divider light={true} style={{ marginBottom: 2.5 }}></Divider>
              </LayoutGridItem>
            </LayoutGrid>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </LayoutGrid>
  );
};
