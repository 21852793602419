import React from 'react';
import { List, ListItem, ListItemText } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(() => ({
  menuItem: {
    padding: 2,
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
}));

const KebabMenuItem = ({
  handleClick,
  id,
  message,
  handleDisableValidation,
}: {
  handleClick: Function;
  id: string;
  message: string;
  handleDisableValidation?: () => boolean;
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <List style={{ padding: 0 }}>
      {
        <ListItem
          id={id}
          button
          className={classes.menuItem}
          disabled={handleDisableValidation()}
          onClick={() => handleClick()}
        >
          <ListItemText
            primary={t(message)}
            primaryTypographyProps={{
              variant: 'body2',
            }}
            style={{ color: '#555555', fontSize: '12px' }}
          />
        </ListItem>
      }
    </List>
  );
};

export default KebabMenuItem;
