import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectEditableRequirementTypes,
  selectPicklists,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { getRequirementCheck } from '../../../helper';

const useStyles = makeStyles()({
  radioRoot: {
    marginRight: '24px',
  },
  radio: {
    padding: 0,
    paddingRight: '6px',
  },
});

interface TransportationsHeaderProps {
  disabled?: boolean;
}

export const TransportationsHeader = ({ disabled = false, ...props }: TransportationsHeaderProps) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const { transportationOpts } = useSelector(selectPicklists);
  const { transportation } = useSelector(selectEditableRequirementTypes);

  return (
    <Grid container spacing={4} wrap="nowrap" alignItems="center">
      {transportation !== null && (
        <Grid style={{ width: '32px', minWidth: '32px' }} item>
          {getRequirementCheck(transportation?.requirement)}
        </Grid>
      )}
      <Grid item>
        <Typography>{t('placement.profile.summary.offer.transportation.title')}</Typography>
      </Grid>
      <Grid item>
        <Controller
          name="transportationChosen"
          render={({ value, onChange }) => (
            <RadioGroup
              value={value?.transportationId}
              row
              onChange={(event, value) => {
                const newValue = transportationOpts.find(item => item?.transportationId === Number.parseInt(value));
                onChange(newValue);
              }}
            >
              {transportationOpts.map(item => (
                <FormControlLabel
                  disabled={disabled}
                  classes={{ root: classes.radioRoot }}
                  className={cx('input', { disabled: disabled })}
                  value={item?.transportationId}
                  control={<Radio size="medium" className={classes.radio} />}
                  label={item.transportation}
                />
              ))}
            </RadioGroup>
          )}
        />
      </Grid>
    </Grid>
  );
};
