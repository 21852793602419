/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, CircularProgress, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { Row, RowContent, RowHead } from '../../ProfileTab/Cards/styles';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'app/ComponentLibrary/DateRangeNew';
import { addDays } from 'date-fns';
import moment from 'moment';
import { withReadOnly } from 'oidc/withReadOnly';
import { useReadOnly } from 'oidc/userRoles';
import ErrorOutline from 'app/assets/images/CandidateProfile/error_outline-24px.svg';
import { RowContainer } from '@AMIEWEB/Candidate/Common/RowContainer';
import { CustomTooltip } from '@AMIEWEB/Common';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { useDispatch, useSelector } from 'react-redux';
import { candidateDetailsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';

const useStyles = makeStyles()(theme => ({
  inlineEditMode: {
    cursor: 'default',
    '&.editEnabled': {
      'border-bottom': '1px solid #000',
    },
    '& .MuiAutocomplete-input': {
      cursor: 'default',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: '0px',
    },
    '& .MuiOutlinedInput-inputAdornedEnd': {
      'padding-left': '5px !important',
    },
    '& .MuiOutlinedInput-root': {
      height: '21px',
      minWidth: '120px',
      maxWidth: '120px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      'border-top': '0',
      'border-left': '0',
      'border-right': '0',
      'border-bottom-color': '#bababa',
      'border-radius': 'revert',
      '&:focus-visible': {
        outline: 'none',
        background: '#f2f2f2',
      },
    },
    '& .MuiAutocomplete-input:first-child': {
      width: '70%',
    },
    '& .MuiIconButton-root': {
      'padding-left': '0px !important',
      'padding-right': '5px !important',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#1a1919',
      backgroundColor: '#fff !important',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(6px, 6px) scale(1)',
    },
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      marginTop: '-6px',
    },
  },
  normalText: {
    'font-weight': 'normal',
  },
  inlineBoxEditItemPadding: {
    padding: '0 0.4rem',
  },
  inlineBoxEditMainCheckItem: {
    'padding-left': '0',
    'padding-right': '0',
    '& svg': {
      width: '25px',
      height: '25px',
    },
  },
  inlineBoxEditMainAcceptItem: {
    'padding-right': '0.2rem',
    '& svg': {
      color: '#006FB9',
      width: '20px',
      height: '20px',
      cursor: 'pointer',
    },
  },
  inlineBoxEditMainRejectItem: {
    'padding-left': '0.2rem',
    '& svg': {
      color: '#8f9a8e',
      width: '20px',
      height: '20px !important',
      cursor: 'pointer',
    },
  },
  disabledItem: {
    '& svg': {
      color: '#8f9a8e',
      width: '20px',
      height: '20px !important',
      cursor: 'default',
    },
  },
  saveSuccessText: {
    fontSize: '11px',
    paddingLeft: '4px',
    justifyContent: 'center',
    color: '#008000',
  },
  saveFailedText: {
    fontSize: '11px',
    paddingLeft: '4px',
    justifyContent: 'center',
    color: '#ff0000',
  },
}));
export const EditCalendar = props => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const [isHandleClick, setIsHandleClick] = useState<boolean>(false);
  const [showIcon, setShowIcon] = useState<boolean>(true);
  const [dob, setDob] = useState<any>('');
  const { readOnly } = useReadOnly();
  const date = new Date();
  const dispatch = useDispatch();
  const candidateDetails = useSelector(candidateDetailsSelection);

  useEffect(() => {
    if (props.isViewable) {
      setDob(props.data);
    }
  }, [props.isViewable]);

  useEffect(() => {
    if (props.isViewable) {
      setDob(props.data);
      setShowIcon(true);
    }
  }, [props.data]);

  const enableInlineEditMode = () => {
    if (props.isEditable) {
      setIsHandleClick(true);
    }
  };

  const handlesave = () => {
    setIsHandleClick(false);
    toggleDateIcon();
    props.handleSave('dob');
  };

  const toggleDateIcon = () => {
    var x = document.getElementById('cal-Icon-Id');
    if (x) {
      x.style.visibility = 'hidden';
    }
  };

  const close = () => {
    props.resetDob();
    setIsHandleClick(false);
    if (dob !== null) {
      setShowIcon(true);
    }
  };
  const onInlineEdit = (data: any) => {
    if (data == '' || data == '_/__/____') {
      setShowIcon(false);
    } else {
      setShowIcon(true);
    }
  };

  return (
    <LayoutGridItem item>
      <RowContainer>
        <Row style={{ height: '30px' }}>
          <RowHead style={{ wordBreak: 'keep-all' }}>
            <div className={classes.normalText}>
              <Grid container>
                <Grid item>
                  <Checkbox
                    disableFocusRipple
                    disableRipple
                    disabled={true}
                    className={`${classes.inlineBoxEditItemPadding} ${classes.inlineBoxEditMainCheckItem}`}
                    icon={
                      dob !== null && props.data !== null && showIcon ? (
                        <CheckCircleIcon style={{ color: props.color }} />
                      ) : (
                        <img src={ErrorOutline} alt="Error" />
                      )
                    }
                    checkedIcon={<CheckCircleIcon />}
                  />
                  <span className={`${classes.inlineBoxEditItemPadding}`}>
                    {`${t('candidate.profileSummaryCard.dateOfBirth')}:`}
                  </span>
                </Grid>
                <Grid item>
                  <span className={classes.inlineEditMode}>
                    <Grid container>
                      <CustomTooltip
                        tooltipContent={t('candidate.profileSummaryCard.protectedDataTooltip')}
                        contentStyle={{ alignSelf: 'center' }}
                        disabled={props.isViewable}
                      >
                        <Grid item>
                          <div style={{ display: 'inline-flex' }}>
                            {props.isViewable ? (
                              <>
                                <RowContainer>
                                  <Row>
                                    <DateRangePicker
                                      onChange={event => {
                                        dispatch(
                                          candidateDetailActions.setCandidateDetails({
                                            ...candidateDetails,
                                            dateOfBirth: event.startDate?.toString() || '',
                                          }),
                                        );
                                        enableInlineEditMode();
                                        props.handleChangeValue(
                                          'dob',
                                          event && event.startDate?.toString() !== ''
                                            ? event.startDate?.toString()
                                            : props.data,
                                        );
                                      }}
                                      onInlineEdit={onInlineEdit}
                                      onCancel={close}
                                      actionBar={false}
                                      open={false}
                                      variant="outlined"
                                      single
                                      clickAway
                                      isDisabled={!props.isEditable}
                                      maxWidth={110}
                                      showIcon={true}
                                      customIconPadding={'-20px'}
                                      isDateIconReq={true}
                                      maxDate={new Date()}
                                      initialDateRange={
                                        dob
                                          ? {
                                              startDate: new Date(dob),
                                              endDate: new Date(dob),
                                              label: moment(new Date(dob)).format('MM/DD/YYYY'),
                                              useLabelAsValue: dob || false,
                                            }
                                          : undefined
                                      }
                                      minDate={new Date('01-01-1940')}
                                      definedRanges={[
                                        {
                                          label: '1 Day',
                                          startDate: addDays(date, -1),
                                          endDate: addDays(date, -1),
                                          useLabelAsValue: false,
                                        },
                                        {
                                          label: '2 Days',
                                          startDate: addDays(date, -2),
                                          endDate: addDays(date, -2),
                                          useLabelAsValue: false,
                                        },
                                        {
                                          label: '7 Days',
                                          startDate: addDays(date, -7),
                                          endDate: addDays(date, -7),
                                          useLabelAsValue: false,
                                        },
                                        {
                                          label: '20 Days',
                                          startDate: addDays(date, -20),
                                          endDate: addDays(date, -20),
                                          useLabelAsValue: false,
                                        },
                                        {
                                          label: '30 Days',
                                          startDate: addDays(date, -30),
                                          endDate: addDays(date, -30),
                                          useLabelAsValue: false,
                                        },
                                        {
                                          label: '60 Days',
                                          startDate: addDays(date, -60),
                                          endDate: addDays(date, -60),
                                          useLabelAsValue: false,
                                        },
                                      ]}
                                    ></DateRangePicker>
                                  </Row>
                                  <Row style={{ width: 'fit-content' }}>
                                    {props.saveStatus == 1 && (
                                      <CircularProgress style={{ width: '12px', height: '12px' }} />
                                    )}
                                    {props.saveStatus == 2 && (
                                      <>
                                        <CheckIcon style={{ width: '15px', height: '15px', color: '#008000' }} />
                                        <label className={classes.saveSuccessText}>{`Saved`}</label>
                                      </>
                                    )}
                                    {props.saveStatus == 0 && props.saveStatus !== '' && (
                                      <>
                                        <CloseIcon style={{ width: '15px', height: '15px', color: '#ff0000' }} />
                                        <label className={classes.saveFailedText}>{`Failed to update`}</label>
                                      </>
                                    )}
                                  </Row>
                                </RowContainer>
                              </>
                            ) : props.data ? (
                              '**/**/****'
                            ) : (
                              '--'
                            )}
                          </div>
                        </Grid>
                      </CustomTooltip>
                      <Grid item ml={1}>
                        {isHandleClick && (
                          <span style={{ display: 'inline-flex' }}>
                            {withReadOnly('span')({
                              className: cx(classes.inlineBoxEditItemPadding, classes.inlineBoxEditMainAcceptItem, {
                                [classes.disabledItem]: readOnly,
                              }),
                              onClick: () => {
                                if (!readOnly) handlesave();
                              },
                              'aria-hidden': 'true',
                              children: <CheckIcon data-testid="saveIcon" />,
                            })}
                            <span
                              className={`${classes.inlineBoxEditItemPadding} ${classes.inlineBoxEditMainRejectItem}`}
                              onClick={() => {
                                close();
                              }}
                              onKeyDown={() => {
                                close();
                              }}
                              aria-hidden="true"
                            >
                              <CloseIcon data-testid="closeIcon" />
                            </span>
                          </span>
                        )}
                      </Grid>
                    </Grid>
                  </span>
                </Grid>
              </Grid>
            </div>
          </RowHead>
          <RowContent></RowContent>
        </Row>
      </RowContainer>
    </LayoutGridItem>
  );
};
