import { totalCountNodes } from '@AMIEWEB/Common/TreeView/TreeViewHelpers';
import { candidateSearchFilterName } from '@AMIEWEB/GlobalSearch/Candidate/SearchUtils';
import {
  dropDownFilterOptions,
  getFacilitySearchLookups,
  getFilteredOptions,
  getOrderIdSearchLookups,
} from '@AMIEWEB/GlobalSearch/helper';
import { AutocompleteChangeReason } from 'amn-ui-core';
import { ISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import { FilterCompTypes } from 'app/ComponentLibrary/Filter/utils';
import {
  getFilteredCities,
  getFilteredZips,
  upgradeBasedOnSearchOption,
} from 'app/components/GlobalSearch/Candidate/FilterUtils';
import { getSearchLookups } from 'app/services/SharedServices/SharedServices';
import i18next from 'i18next';

export enum OrderMatchFlterName {
  division = 'divisionIds',
  businessRelationship = 'businessRelationshipIds',
  facilityStatus = 'facilityStatusIds',
  orderType = 'orderTypeIds',
  skillsetFilter = 'skillsetFilter',
  discipline = 'disciplineIds',
  specialty = 'specialtyIds',
  subSpecialty = 'subSpecialtyIds',
  state = 'states',
  city = 'city',
  zip = 'zipCode',
  radius = 'radius',
  facility = 'facilityIds',
  mspClient = 'mspClientIds',
  contractAffiliates = 'affiliateIds',
  orderAgeDateRange = 'dateEnteredRange',
  orderEndDateRange = 'endDateRange',
  billRate = 'billrate',
  paysGww = 'paysGww',
  guaranteedHours = 'guaranteedHours',
  expectedHours = 'expectedHours',
  reason = 'reasonids',
  education = 'education',
  accountManager = 'amUserIds',
  orderPriority = 'orderPriorityIds',
  shift = 'shiftIds',
  weeklyGrossPay = 'weeklyGrossPay',
  startDateRange = 'orderStartDateRange',
  mfs = 'maxFilesSentId',
  positions = 'positions',
  vaccinated = 'vaccinatedIds',
  orderIds = 'orderIds',
  region = 'region',
}
export const EducationOpts = [
  { id: 0, value: 'clinicalFellowshipYear', name: 'Clinical Fellow', labelPrefix: 'Education' },
  { id: 1, value: 'newGrad', name: 'New Grad', labelPrefix: 'Education' },
];
export const MFSOpts = [
  { id: 0, value: 1, name: 'Yes', labelPrefix: 'MFS' },
  { id: 1, value: 2, name: 'No', labelPrefix: 'MFS' },
];
export const PaysGwwOpts = [
  { id: 0, value: true, name: 'Yes', labelPrefix: 'GWW' },
  { id: 1, value: false, name: 'No', labelPrefix: 'GWW' },
];
export const VaccinatedOpts = [
  { id: 0, value: 1, name: 'Yes', labelPrefix: 'Vaccinated' },
  { id: 1, value: 2, name: 'No', labelPrefix: 'Vaccinated' },
];

export const PositionOpts = [
  {
    id: 0,
    value: { min: 0, max: 0 },
    name: '0',
    labelPrefix: i18next.t('search.globalSearch.order.filterNames.positions'),
  },
  {
    id: 1,
    value: { min: 1, max: 999 },
    name: '>0',
    labelPrefix: i18next.t('search.globalSearch.order.filterNames.positions'),
  },
  {
    id: 2,
    value: { min: 1, max: 10 },
    name: '1-10',
    labelPrefix: i18next.t('search.globalSearch.order.filterNames.positions'),
  },
  {
    id: 3,
    value: { min: 10, max: 50 },
    name: '10-50',
    labelPrefix: i18next.t('search.globalSearch.order.filterNames.positions'),
  },
  {
    id: 4,
    value: { min: 50, max: 99 },
    name: '50-99',
    labelPrefix: i18next.t('search.globalSearch.order.filterNames.positions'),
  },
  {
    id: 5,
    value: { min: 100, max: 999 },
    name: '>99',
    labelPrefix: i18next.t('search.globalSearch.order.filterNames.positions'),
  },
] as unknown as ISelection[];

export class OrderMatchSearchFilters {
  constructor(public filterSpecs: any, public gridStateFilters: any) { }

  getOrderMatchFilters = () => {
    const date = new Date();
    return [
      {
        filterName: i18next.t('home.pricingWorkDesk.filterHeading.OID'),
        name: OrderMatchFlterName.orderIds,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: getOrderIdSearchLookups,
        isMultiSelect: true,
        applyOnClickAway: true,
        searchOnCharacterCount: 1,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.division'),
        name: OrderMatchFlterName.division,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        options: this.filterSpecs?.divisionOpts,
        selectAllValue: 'All',
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.businessRelationship'),
        name: OrderMatchFlterName.businessRelationship,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        options: this.filterSpecs.businessRelationshipsOpts,
        selectAllValue: 'All',
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.facilityStatus'),
        name: OrderMatchFlterName.facilityStatus,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        options: this.filterSpecs.facilityStatusesOpts,
        selectAllValue: 'All',
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.type'),
        name: OrderMatchFlterName.orderType,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        options: this.filterSpecs.orderTypesOpts,
        selectAllValue: 'All',
      },
      {
        filterName: i18next.t('search.globalSearch.candidate.filterNames.skillSet'),
        name: candidateSearchFilterName.candidateSkillSet,
        type: FilterCompTypes.CANDIDATESKILLSET,
        isMultiSelect: true,
        options: [],
        placeholder: 'Select',
        selectAllValue: 'All',
        version2: true,
        returnsObjectAsValue: true,
        size: 'large',
        isExact: true,
        customSelectedCount: value => totalCountNodes(value),
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.state'),
        name: OrderMatchFlterName.state,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        options: this.filterSpecs.statesOpts,
        selectAllValue: 'All',
        compactValues: this.filterSpecs.compactStates,
        handleChangeCallback: (newValue = [], reason: AutocompleteChangeReason, { option }, onChange) => {
          if (option.value === 'All') {
            onChange(reason === 'selectOption' ? this.filterSpecs.statesOpts : []);
            return;
          }
          const compactValuesSelected = newValue.reduce(
            (results, item) => (item.isCompactOpt ? [...results, ...item.value] : results),
            [],
          );
          let newSelections = this.filterSpecs.statesOpts.reduce((results, item) => {
            let isItemSelected =
              newValue.some(x => x.value === item.value) || compactValuesSelected.some(x => x.value === item.value);

            return isItemSelected ? [...results, item] : results;
          }, []);

          if (reason === 'removeOption' && option.isCompactOpt) {
            newSelections = newSelections.reduce(
              (results, item) => (option.value.some(opt => opt.name === item.name) ? results : [...results, item]),
              [],
            );
            const compactValuesPresent = newSelections.filter(item => item?.isCompactOpt === true);
            compactValuesPresent.forEach(opt => {
              newSelections = [...newSelections, ...opt.value];
            });
            const uniqueValues = newSelections.filter((val, index, self) => {
              return self.findIndex(p => p.value === val.value) === index;
            });
            newSelections = uniqueValues;
          } else if (reason === 'removeOption' && !option.isCompactOpt) {
            const parentIndex = newSelections.findIndex(
              item => item.isCompactOpt && item.value.some(x => x.value === option.value),
            );
            parentIndex !== -1 && newSelections.splice(parentIndex, 1);

            const childIndex = newSelections.findIndex(item => item.value === option.value);
            childIndex !== -1 && newSelections.splice(childIndex, 1);
          }

          onChange(newSelections);
        },
        filterOptions: (options, { inputValue }) => {
          return dropDownFilterOptions(options, inputValue);
        },
      },
      {
        filterName: i18next.t('search.globalSearch.candidate.filterNames.city'),
        name: OrderMatchFlterName.city,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async (key: string) => {
          if (!key) return getFilteredCities(this.filterSpecs.cityOptions, key);
          const cityOptions = await upgradeBasedOnSearchOption(key, 'c', this.gridStateFilters);
          return cityOptions;
        },
        isMultiSelect: true,
      },
      {
        filterName: i18next.t('search.globalSearch.candidate.filterNames.zip'),
        name: OrderMatchFlterName.zip,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async (key: string) => {
          if (!key) return getFilteredZips(this.filterSpecs.zipOptions, key);
          const zipOptions = await upgradeBasedOnSearchOption(key, 'z', this.gridStateFilters);
          return zipOptions;
        },
        isMultiSelect: false,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.radius'),
        name: OrderMatchFlterName.radius,
        type: FilterCompTypes.TEXTFIELDNUMBER,
        placeholder: 'Radius',
        disabled: this.gridStateFilters?.filters?.zipCode ? false : true,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.facility'),
        name: OrderMatchFlterName.facility,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: getFacilitySearchLookups,
        isMultiSelect: true,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.mspClient'),
        name: OrderMatchFlterName.mspClient,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        options: this.filterSpecs.mspClientOpts,
        selectAllValue: 'All',
        isMultiSelect: true,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.contractAffiliates'),
        name: OrderMatchFlterName.contractAffiliates,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookups('contractaffiliates', key),
        isMultiSelect: true,
      },

      {
        filterName: i18next.t('search.globalSearch.order.filterNames.orderAgeDateRange'),
        name: OrderMatchFlterName.orderAgeDateRange,
        placeholder: i18next.t('search.globalSearch.order.filterNames.orderAgeDateRange'),
        version3: true,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: i18next.t('search.globalSearch.order.filterNames.orderAgeDateRange'),
        useMaxWidth: true,
        maxWidth: 210,
        clickAway: true,
        disablePortal: true,
        quickSelect: false,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.orderEndDateRange'),
        name: OrderMatchFlterName.orderEndDateRange,
        placeholder: i18next.t('search.globalSearch.order.filterNames.orderEndDateRange'),
        version3: true,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: i18next.t('search.globalSearch.order.filterNames.orderEndDateRange'),
        useMaxWidth: true,
        maxWidth: 210,
        clickAway: true,
        disablePortal: true,
        quickSelect: false,
      },
      {
        filterName: i18next.t('Bill Rate'),
        name: OrderMatchFlterName.billRate,
        type: FilterCompTypes.NUMBERRANGE,
        placeholder: i18next.t('Bill Rate'),
        numberRangeType: 'currency',
      },

      {
        filterName: i18next.t('search.globalSearch.order.filterNames.paysGww'),
        name: OrderMatchFlterName.paysGww,
        placeholder: i18next.t('search.globalSearch.order.filterNames.paysGww'),
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        options: PaysGwwOpts,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.guaranteedHours'),
        name: OrderMatchFlterName.guaranteedHours,
        type: FilterCompTypes.NUMBERRANGE,
        placeholder: i18next.t('search.globalSearch.order.filterNames.guaranteedHours'),
        numberRangeType: 'hours',
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.expectedHours'),
        name: OrderMatchFlterName.expectedHours,
        type: FilterCompTypes.NUMBERRANGE,
        placeholder: i18next.t('search.globalSearch.order.filterNames.expectedHours'),
        numberRangeType: 'hours',
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.reason'),
        name: OrderMatchFlterName.reason,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        options: this.filterSpecs.orderReasonOpts,
        selectAllValue: 'All',
        isMultiSelect: true,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.education'),
        name: OrderMatchFlterName.education,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        options: EducationOpts,
        isMultiSelect: true,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.accountManager'),
        name: OrderMatchFlterName.accountManager,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        options: this.filterSpecs.accountManagerOpts,
        selectAllValue: 'All',
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.orderPriority'),
        name: OrderMatchFlterName.orderPriority,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        options: this.filterSpecs.orderPrioritiesOpts,
        selectAllValue: 'All',
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.shift'),
        name: OrderMatchFlterName.shift,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        options: this.filterSpecs.shiftOpts,
        selectAllValue: 'All',
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.weeklyGrossPay'),
        name: OrderMatchFlterName.weeklyGrossPay,
        type: FilterCompTypes.NUMBERRANGE,
        placeholder: 'Weekly Gross',
        numberRangeType: 'currency',
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.startDateRange'),
        name: OrderMatchFlterName.startDateRange,
        placeholder: i18next.t('search.globalSearch.order.filterNames.startDateRange'),
        version3: true,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: i18next.t('search.globalSearch.order.filterNames.startDateRange'),
        useMaxWidth: true,
        maxWidth: 210,
        clickAway: true,
        disablePortal: true,
        quickSelect: false,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.mfs'),
        name: OrderMatchFlterName.mfs,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        options: MFSOpts,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.positions'),
        name: OrderMatchFlterName.positions,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        options: PositionOpts,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.vaccinated'),
        name: OrderMatchFlterName.vaccinated,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        options: VaccinatedOpts,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.region'),
        name: OrderMatchFlterName.region,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.regionOpts, key),
        applyOnClickAway: true,
        applyOnEnter: true,
        isSelectAll: true,
        searchOnCharacterCount: 1,
      },
    ];
  };
}

export const convertToTreeStructure = data => {
  const tree = [];

  const disciplineMap = new Map();

  data?.forEach(item => {
    const { disciplineId, disciplineAbbr, specialtyId, specialtyAbbr, subSpecialtyId, subSpecialtyAbbr } = item;

    // Find or create the discipline node
    if (!disciplineMap?.has(disciplineId)) {
      disciplineMap?.set(disciplineId, {
        name: disciplineAbbr,
        value: disciplineId,
        children: [],
        userChecked: true,
      });
      tree.push(disciplineMap?.get(disciplineId));
    }

    const disciplineNode = disciplineMap?.get(disciplineId);

    // Find or create the specialty node
    let specialtyNode = disciplineNode?.children?.find(child => child?.value == `${disciplineId}-${specialtyId}`);
    if (!specialtyNode) {
      specialtyNode = {
        name: specialtyAbbr,
        value: `${disciplineId}-${specialtyId}`,
        children: [],
        userChecked: true,
      };
      disciplineNode?.children?.push(specialtyNode);
    }

    // Add the subspecialty node if it exists
    if (subSpecialtyId !== null && subSpecialtyAbbr !== null) {
      specialtyNode?.children?.push({
        name: subSpecialtyAbbr,
        value: `${disciplineId}-${specialtyId}-${subSpecialtyId}`,
        userChecked: true,
      });
    }
  });

  return tree;
};
