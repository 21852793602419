import { Box, Grid } from 'amn-ui-core';
import { IUpdateAutoTaskManagementResponse } from 'app/models/AutoTaskManagement/AutoTaskManagement';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { placementRequestsSaga } from 'store/redux-store/placement-requests/saga';
import { selectAutoTaskUpdateResponse } from 'store/redux-store/placement-requests/selectors';
import {
  placementRequestsAction,
  placementRequestsReducer,
  placementRequestsSliceKey,
} from 'store/redux-store/placement-requests/slice';
import { makeStyles } from 'tss-react/mui';
import { BannerMessage } from '../common/BannerMessage';
import AutoTaskChips from './AutoTaskChips/AutoTaskChips';
import AutoTaskFilters from './AutoTaskFilters/AutoTaskFilters';
import { AutoTasksGrid } from './AutoTasksGrid/AutoTasksGrid';
import { UserRolesSection } from './UserRolesSection/UserRolesSection';
import { ResponseCodes } from 'store/redux-store/placement-requests/helper';

const useStyles = makeStyles()(theme => ({
  formStyle: {
    height: 'calc(100vh - 50px)',
    width: '100%',
    paddingRight: '11px',
  },
  containerStyle: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  mainBodyContainer: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  headerContainer: {
    margin: 10,
    marginBottom: '5px',
  },
  contentContainer: {
    flexDirection: 'row',
    flexGrow: 1,
    border: '1px solid #ccc',
    padding: '10px',
    margin: '10px',
    marginTop: '0px',
    boxShadow: '0px 4px 6px rgba(0.1,0.1,0.1,0.1)',
    backgroundColor: theme.palette.common.white,
  },
  gridContainer: {
    border: '1px solid #ccc',
    padding: '10px',
    paddingRight: '0px',
    boxShadow: '0px 4px 6px rgba(0.1,0.1,0.1,0.1)',
    minWidth: 508,
    overflowX: 'scroll',
    overflowY: 'scroll',
    flexGrow: 1,
  },
  formContainer: {
    border: '1px solid #ccc',
    padding: '10px',

    width: '100%',
    backgroundColor: theme.palette.components.grid.content.backgroundColor,
    [theme.breakpoints.up('lg')]: {
      width: 730,
      marginLeft: '10px',
    },
  },
  bannerContainer: {
    margin: '10px',
  },
}));

export const AutoTaskManagementWrapper = () => {
  const { handleSubmit, reset, formState, watch, ...restFormMethods } = useForm();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const autoTaskUpdateResponse = useSelector(selectAutoTaskUpdateResponse);
  useInjectSaga({ key: placementRequestsSliceKey, saga: placementRequestsSaga });
  useInjectReducer({ key: placementRequestsSliceKey, reducer: placementRequestsReducer });

  useEffect(() => {
    dispatch(placementRequestsAction.business_initiateAutoTaskManagementPage());
    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onApplyFilters = (filters, subFilter?) => {
    dispatch(placementRequestsAction.business_handleApplyFilters({ filters, subFilter }));
  };

  const handleRefreshAction = () => {
    dispatch(placementRequestsAction.business_handleRefreshGrid({ softReset: true }));
    dispatch(placementRequestsAction.setAutoTaskUpdateResponse({} as IUpdateAutoTaskManagementResponse));
  }
  return (
    <>
      <FormProvider {...{ handleSubmit, reset, formState, watch, ...restFormMethods }}>
        <form className={classes.formStyle} onSubmit={handleSubmit((filters: any) => onApplyFilters(filters))}>
          <Grid container classes={{ root: classes.containerStyle }}>
            <Grid container item classes={{ root: classes.mainBodyContainer }}>
              {autoTaskUpdateResponse?.responseCode === ResponseCodes.RefreshErrorCode && (
                <Grid item classes={{ root: classes.bannerContainer }}>
                  <BannerMessage
                    refreshButton={true}
                    message={t(`autoTaskManagement.banner.errorSomeoneUpdatedBeforeYou`)}
                    refreshAction={handleRefreshAction}
                  />
                </Grid>
              )}
              <Grid item classes={{ root: classes.headerContainer }}>
                <Box className="page-title">{t('autoTaskManagement.title')}</Box>
              </Grid>
              <Grid container item classes={{ root: classes.contentContainer }}>
                <Grid container padding={1}>
                  <Grid item spacing={1}>
                    <AutoTaskFilters handleSubmit={onApplyFilters} />
                  </Grid>
                  <Grid item spacing={1}>
                    <AutoTaskChips handleSubmit={onApplyFilters} />
                  </Grid>
                </Grid>
                <Grid item spacing={1} classes={{ root: classes.gridContainer }}>
                  <AutoTasksGrid />
                </Grid>
                <Grid item spacing={1} classes={{ root: classes.formContainer }}>
                  <UserRolesSection />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};
