/* eslint-disable i18next/no-literal-string */
import React from 'react';
import _ from 'lodash';
import { Expandable } from 'app/components/Common/Expandable/Expandable';
import { IconTitle } from './Titles/IconTitle';
import { List, ListItem, ListItemText, ListItemAvatar, Typography, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { getPlacementListIcon } from 'app/components/Placement/PlacementDetails/helper';
import { useTranslation } from 'react-i18next';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { EPlacementExternalSystems, EPlacementSubmissionPacketType } from 'app/models/Placement/PlacementDetails';

const useStyles = makeStyles()(theme => ({
  root: {
    maxHeight: 190,
    overflowY: 'scroll',
  },
  primary: {
    color: theme.palette.framework.system.charcoal,
    fontWeight: 450,
  },
  secondary: {
    color: theme.palette.framework.system.doveGray,
    marginTop: '1px',
    fontSize: '0.9rem',
    marginLeft: '6px',
  },
  item: {
    padding: 0,
    fontSize: 16,
  },
  icon: {
    minWidth: 26,
    flexShrink: 0,
    marginTop: 2,
  },
  listContainer: {
    paddingLeft: '36px',
  },
  iconClass: {
    color: theme.palette.system.dimDarkRed,
  },
  iconText: {
    color: theme.palette.system.dimDarkRed,
    position: 'relative',
    left: '-1.04rem',
    top: '0.1rem',
    fontSize: '14px',
    fontWeight: 600,
  },
}));

export const SubmissionPacketError = ({ expanded = true, loading = false, ...props }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { submissionPacketDetailData } = props;

  return (
    <Expandable
      id="placement-summary-risks"
      titleComponent={
        <IconTitle
          title={t('placement.profile.submitPacket.dialog.title.SubmissionError')}
          icon={
            submissionPacketDetailData?.isAutomated ? (
              <>
                <RadioButtonUncheckedIcon className={classes.iconClass} />
                <Typography variant="body1" className={classes.iconText}>
                  {EPlacementSubmissionPacketType.Automated}
                </Typography>
              </>
            ) : null
          }
          titleSupportComponent={null}
        />
      }
      disabled={!loading && submissionPacketDetailData?.submissionError === null}
      expanded={
        expanded === false ? false : loading || (expanded && submissionPacketDetailData?.submissionError !== null)
      }
      loading={loading}
    >
      <List
        disablePadding
        classes={{ root: classes.root }}
        data-testid="placementSubmissionError-itemContainer"
        id={'placementSummaryDetailsSubmissionErrorContainer'}
      >
        {submissionPacketDetailData?.submissionError !== null && (
          <ListItem
            classes={{ root: classes.item }}
            className={classes.listContainer}
            alignItems="flex-start"
            aria-label="placementsubmissionerror-item"
          >
            <ListItemAvatar classes={{ root: classes.icon }}>{getPlacementListIcon(-1)}</ListItemAvatar>
            <ListItemText
              secondary={t(submissionPacketDetailData?.submissionError)}
              classes={{
                primary: classes.primary,
                secondary: classes.secondary,
              }}
            />
          </ListItem>
        )}
      </List>
    </Expandable>
  );
};

export const SubmissionErrorContainer = ({ submissionPacketDetail, loadingRequirements }) => {
  return (
    submissionPacketDetail?.type === EPlacementExternalSystems.Type &&
    !_.isEmpty(submissionPacketDetail?.submissionError) && (
      <Grid item xs={12}>
        <SubmissionPacketError loading={loadingRequirements} submissionPacketDetailData={submissionPacketDetail} />
      </Grid>
    )
  );
};
