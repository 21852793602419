import { Button } from 'amn-ui-core';
import React, { useState } from 'react';
import TopLabelStepper from './TopLabelStepper';

const steps = [
  'Review Facility',
  'Define Unit',
  'Order Details',
  'Order Descriptions',
  'Dates',
  'Additional Information',
];
const StepperTest = props => {
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep(prevActiveStep => {
      const activeStep = prevActiveStep < steps.length ? prevActiveStep + 1 : prevActiveStep;
      //props.onStepChange(activeStep);
      return activeStep;
    });
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => {
      //props.onStepChange(prevActiveStep - 1);
      return prevActiveStep - 1;
    });
  };
  return (
    <>
      <TopLabelStepper steps={steps} activeStep={activeStep} onStepChange={step => console.log(step)}></TopLabelStepper>
      <Button disabled={activeStep === 0} onClick={handleBack}>
        {activeStep != 0 ? 'Back' : ''}
      </Button>
      <Button variant="contained" color="primary" onClick={handleNext}>
        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
      </Button>
    </>
  );
};
export default StepperTest;
