import { createAction } from '@reduxjs/toolkit';
import {
  IContractDetailsRequest,
  IFacilityRateElementDetailsCommand,
  IOffContractDetailsCommand,
  IUpdateOrder,
  IUpdateOrderShifts,
} from './types';

const getContractDetailsAction = createAction<IContractDetailsRequest>('GET_OCBR_CONTRACT_DETAILS');
const getOffContractRateElements = createAction<{
  facilityId: number;
}>('GET_OCBR_OFF_CONTRACT_RATE_ELEMENTS');
const saveOffcontractDetails = createAction<IOffContractDetailsCommand>('SAVE_OFF_CONTRACT_DETAILS');
const saveFacilityOffContractRateElementDocuments = createAction<IFacilityRateElementDetailsCommand>(
  'SAVE_FACILITY_RATE_ELEMENTS_DOC_DETAILS',
);
const saveDocuments = createAction<number>('SAVE_DOCUMENTS');
const getMiscellaneousData = createAction('GET_CREATE_ORDER_MISCELLANEOUS_DATA');
const updateOrderSkillsets = createAction<IUpdateOrder>('UPDATE_ORDER_SKILLSETS');
const updateOrderShifts = createAction<IUpdateOrderShifts>('UPDATE_ORDER_SHIFTS');

export const serviceActions = {
  getMiscellaneousData,
  /** OCBR */
  getContractDetailsAction,
  getOffContractRateElements,
  saveOffcontractDetails,
  saveFacilityOffContractRateElementDocuments,
  saveDocuments,
  updateOrderSkillsets,
  updateOrderShifts,
};
