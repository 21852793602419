import { Paper, Popper, Typography } from 'amn-ui-core';
import React from 'react';
import { XGridDefaultHoverView } from './HoverViewRenderers/XGridDefaultHoverView';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

interface GridCellExpandProps {
  width: number;
  fullWidth: boolean;
  hoverRenderer?: (value) => JSX.Element;
  forceHoverView?: boolean;
  cellRenderer?: (value) => JSX.Element;
  checkCellContentAccess?: (value, userRoles) => boolean;
  [key: string]: any;
}

function isOverflown(element: Element): boolean {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

const GridCellExpand = React.memo(function GridCellExpand(props: GridCellExpandProps) {
  const { t } = useTranslation();
  const { fullWidth } = props;
  const wrapper = React.useRef<HTMLDivElement | null>(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown =
      isOverflown(cellValue.current!) || Array.isArray(props.value) || (props.forceHoverView ?? false);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent: KeyboardEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  const customHoverRenderer = React.useMemo(() => props.hoverRenderer?.(props) || props.cellRenderer?.(props), [props]);
  const customCellRenderer = React.useMemo(() => props.cellRenderer?.(props), [props]);

  return (
    <div
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={clsx({ 'x-grid-centered-cell': props.colDef.align === 'center' })}
      style={{
        width: fullWidth ? '100%' : 'unset',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        position: 'relative',
      }}
    >
      <div
        ref={cellDiv}
        style={{
          height: 1,
          width: 1,
          display: 'block',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
      <div ref={cellValue} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {customCellRenderer ? (
          customCellRenderer
        ) : !props.checkCellContentAccess?.(props.row, props.userRoles) ? (
          Array.isArray(props.value) ? (
            props.value.join(', ')
          ) : (
            props.value
          )
        ) : (
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'red' }}>
            {t('candidate.auditCard.restrictedDataText')}
          </div>
        )}
      </div>
      {showPopper && (
        <Popper
          placement="right-start"
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{
            maxWidth: '35vw',
            /** Updated ZIndex for displaying poppers within Generic dialog */
            zIndex: 1300,
          }}
          sx={{
            marginLeft: '-9px !important',
            marginTop: '-8px !important',
          }}
        >
          <Paper
            elevation={1}
            style={{
              minHeight: wrapper.current!.offsetHeight - 3,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {customHoverRenderer || (
              <React.Fragment>
                {Array.isArray(props.value) ? (
                  <ul style={{ paddingRight: 24 }}>
                    {props.value.map((item, i) => (
                      <li key={i}>
                        <Typography variant="body2" style={{ padding: 0, fontSize: '14px' }}>
                          {item}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                ) : (
                  XGridDefaultHoverView(props)
                )}
              </React.Fragment>
            )}
          </Paper>
        </Popper>
      )}
    </div>
  );
});

export const XGridOverflowCell =
  (
    hoverRenderer?,
    forceHoverView = false,
    fullWidth = false,
    checkCellContentAccess?: (value, userRoles) => boolean,
    userRoles?,
  ) =>
  (cellRenderer?) =>
  params => {
    return (
      <GridCellExpand
        hoverRenderer={hoverRenderer}
        cellRenderer={cellRenderer}
        width={params.colDef.computedWidth}
        forceHoverView={forceHoverView}
        fullWidth={fullWidth}
        checkCellContentAccess={checkCellContentAccess}
        userRoles={userRoles}
        {...params}
      />
    );
  };
