import { makeStyles } from 'tss-react/mui';
import { CreateTextField } from 'app/models/Orders/CreateUnit';
import React from 'react';
import { CustomTextField } from './CustomTextField';

const placeholderText = 'Type comments here...';

const useStyles = makeStyles()((theme) => ({
  textareastyle: {
    backgroundColor: '#fff',
    border: 'thin #e7e2e2 solid',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
    },
  },
  reqTextareastyle: {
    backgroundColor: '#fff',
    border: 'thin #D90000 solid',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
    },
  },
  errorTextareastyle: {
    backgroundColor: '#fff',
    border: `1.5px ${theme.palette.system.errorRed} solid`,
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
    },
  },
}));

export const  CustomMultiLineTextField = (props: CreateTextField) => {
  const { classes } = useStyles();
  return (
    <CustomTextField
      id={props.id}
      data-testid="render-textField"
      multiline
      fullWidth
      rows={7}
      disabled={props.isdisabled}
      InputProps={{
        className: props.isdisabled
          ? ''
          : props.error
          ? classes.errorTextareastyle
          : `${props.req ? classes.reqTextareastyle : classes.textareastyle}`,
        disableUnderline: true,
      }}
      variant="filled"
      placeholder={props.placeholder ? props.placeholder : placeholderText}
      error={props.error}
      helperText={props.helperText}
      {...props}
    />
  );
};
