import React, { useState } from 'react';
import { Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInputText } from '../Controls/FormInputText';
import { CountryPhoneNumber } from './CountryPhoneNumber';
import { useDispatch, useSelector } from 'react-redux';
import { candidateDetailActions } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { candidateDetailsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';

const useEmergencyContactStyles = makeStyles()(() => ({
  root: {
    margin: '0px !important',
    width: '100% !important',
    paddingBottom: '10px',
  },
  textColor: {
    color: '#0D6FB9',
    textAlign: 'left',
    font: 'normal normal medium 14px / 26px Roboto',
    fontWeight: 500,
    paddingLeft: 10,
    paddingTop: 8,
  },
}));

const enum CountryCode {
  US = 'us',
}

/**
 * Component to render emergency contact section in edit personal info modal
 */
export const EmergencyContact = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { classes } = useEmergencyContactStyles();
  const { control, setValue, setError, clearErrors, errors } = useFormContext();
  const [valueError, setValueError] = useState(false);
  const [previousDialCode, setPreviousDialCode] = useState('');
  const candidateDetailsSel = useSelector(candidateDetailsSelection);
  const isEmergencyContactError = errors?.emergencyPhoneNumber?.message?.length > 0 ?? null;

  const patchFieldValues = (value: string | null, countryCode: string, phone: string) => {
    dispatch(candidateDetailActions.updateEmergencyContactCountryCode({ countryCode: countryCode, phone: phone }));
    setValue('emergencyPhoneNumber', value);
  };

  const getPhoneNumberLength = (contactNumber: string): number => {
    const matches = contactNumber?.match(/\d/g);
    return matches ? matches?.length : 0;
  };

  const validatePhoneNumber = (countryCode: string, phoneNumber: string) => {
    const phoneNumberLength = getPhoneNumberLength(phoneNumber);
    if (countryCode === CountryCode.US) {
      if (phoneNumberLength < 10) {
        setError('emergencyPhoneNumber', { type: 'manual', message: t('candidate.profileInformation.errorMessage') });
      } else if (phoneNumberLength === 10) {
        {
          clearErrors('emergencyPhoneNumber');
        }
      }
    } else {
      if (phoneNumberLength < 5) {
        setError('emergencyPhoneNumber', { type: 'manual', message: t('candidate.profileInformation.errorMessage') });
      } else {
        clearErrors('emergencyPhoneNumber');
      }
    }
  };

  /**
   * Method to split phone number and country code
   */
  const handleChange = (value: string, country, e, formattedValue) => {
    const getPhoneNumberCountryCode = formattedValue.split(' ');
    const setPhoneNumber = getPhoneNumberCountryCode.slice(1);
    const result = setPhoneNumber.join('');
    setPreviousDialCode(country?.countryCode);
    const phoneNumberLength = getPhoneNumberLength(result);
    if (isEmergencyContactError) {
      if (country?.countryCode === CountryCode.US) {
        phoneNumberLength === 10 && setValueError(false);
      } else {
        phoneNumberLength >= 5 && setValueError(false);
      }
    }
    if (country?.countryCode !== previousDialCode) {
      setValueError(false);
    }
    if (value) {
      patchFieldValues(value, country.dialCode, result);
      validatePhoneNumber(country?.countryCode, result);
    } else {
      patchFieldValues(null, '', '');
    }
  };

  const validateError = () => {
    setValueError(isEmergencyContactError);
  };

  return (
    <LayoutGrid container direction="column" className={classes.root} spacing={4}>
      <Typography className={classes.textColor} variant="subtitle1" gutterBottom component="div">
        {t('candidate.profileSummaryCard.emergencyContact')}
      </Typography>
      <LayoutGridItem item xs={5} sx={{ width: '80%' }} paddingLeft="12px !important">
        <FormInputText
          name="emergencyContactName"
          label={t('candidate.emergencyContactSection.name')}
          require={false}
          isCheckBox={candidateDetailsSel.emergencyContact.name ? true : false}
          maxLength={255}
        />
      </LayoutGridItem>
      <LayoutGridItem item xs={5} sx={{ width: '80%' }} paddingLeft="12px !important">
        <FormInputText
          name="relationship"
          label={t('candidate.emergencyContactSection.relationship')}
          require={false}
          isCheckBox={candidateDetailsSel.emergencyContact.relationship ? true : false}
          maxLength={255}
        />
      </LayoutGridItem>
      <LayoutGridItem container direction="row" paddingLeft="12px !important">
        <LayoutGridItem xs={5}>
          <Controller
            control={control}
            name="emergencyPhoneNumber"
            render={({ value }) => (
              <CountryPhoneNumber
                onChange={handleChange}
                value={value}
                error={valueError}
                onBlur={validateError}
                errorMessage={errors?.emergencyPhoneNumber?.message}
              />
            )}
          />
        </LayoutGridItem>
        <LayoutGridItem xs={3} style={{ marginLeft: '24px' }}>
          <FormInputText
            name="extNote"
            label={t('candidate.emergencyContactSection.extNote')}
            require={false}
            isCheckBox={candidateDetailsSel.emergencyContact.extensionNotes ? true : false}
            maxLength={260}
          />
        </LayoutGridItem>
      </LayoutGridItem>
    </LayoutGrid>
  );
};
