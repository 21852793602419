export enum ISkillSetExpiration {
  NOT_EXPIRED = 'Not Expired',
  EXPIRED = 'Expired',
  EXPIRING_SOON = 'Expiring Soon',
  MISSING_SKILL_SET = 'Missing Skill Checklist',
}

export enum ISkillSetExpiryStatus {
  NOT_EXPIRED = 1,
  EXPIRED = 0,
}
