/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui';

const useCandidateHeaderStyle = makeStyles()(theme => ({
  container: {
    flexWrap: 'nowrap',
  },
  containerSecond: {
    marginTop: '10px',
  },
  title: {
    color: '#34B233',
    fontSize: 16,
    display: 'inline-block',
    cursor: 'pointer',
  },
  divider: {
    margin: '0 3px',
  },
  textContent: {
    textAlign: 'left',
    alignSelf: 'column',
    color: '#333333 !important',
    fontSize: '14px !important',
    textTransform: 'uppercase',
    fontWeight: 'initial',
  },
  textContentSecond: {
    textAlign: 'left',
    alignSelf: 'column',
    color: '#555555 !important',
    fontSize: '14px !important',
  },
  avatarName: {
    height: 50,
    width: 55,
    cursor: 'pointer',
    fontSize: 20,
    fontWeight: 500,
    backgroundColor: '#34B233',
    textTransform: 'uppercase',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  },
  avatarText: {
    height: 50,
    width: 130,
    marginRight: 10,
    fontSize: 20,
    fontWeight: 500,
    paddingLeft: 10,
    justifyContent: 'start',
    backgroundColor: '#237422',
    textTransform: 'uppercase',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    cursor: 'pointer',
  },

  customTooltip: {
    backgroundColor: '#ffffff',
    color: '#0D2A3B',
    opacity: 1,
    width: '119px',
    textAlign: 'left',
    font: 'normal normal normal 12px/20px IBM Plex Sans',
    letterSpacing: '0px',
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #8C8C8C',
  },
  customArrow: {
    color: '#ffffff',
    opacity: 1,
    '&::before': {
      boxShadow: '0px 3px 6px #00000029',
      border: '1px solid #8C8C8C',
    },
  },
  containerAvatar: {
    display: 'flex',
    justifyContent: 'start',
  },
  myAvatarAlignment: {
    display: 'flex',
    flexDirection: 'row',
  },
  contactDrawer: {
    marginTop: 10,
  },
  userIcon: {
    backgroundColor: '#F8F8F8',
    width: '95%',
    justifyContent: 'start',
    border: '1px solid #006fb9',
    '&:hover': {
      backgroundColor: '#006FB9',
      color: '#fff',
      border: '1px solid #006fb9',
    },
  },
  interactIcons: {
    paddingTop: '6px',
  },
  candidateStatus: {
    backgroundColor: '#34B233',
    fontSize: '14px',
    width: 'max-content',
    padding: '8px',
    height: '27px',
    justifyContent: 'space-between',
    minWidth: '153px',
    [theme.breakpoints.up('lg')]: {
      width: 'inherit !important',
    },
  },
  statusSecond: {
    fontSize: '12px !important',
    width: '128px !important',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    minWidth: '0px !important',
  },

  placementStatus: {
    backgroundColor: '#003C69',
    height: '27px',
    fontSize: '14px',
    width: 'max-content',
    padding: '8px',
    minWidth: '153px',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  placementStatusText: {
    display: 'block',
  },
  brand: {
    color: '#E89120 !important',
    fontWeight: 500,
    paddingLeft: '5px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px !important',
    },
  },
  brandContent: {
    width: 'calc(65%)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'inline-block',
    position: 'relative',
    marginBottom: '-5px',
  },
  dateContent: {
    width: '278px',
    '& #dateRangeContainer, #disabledContainer': {
      width: '85px',
    },
    '& #disabledText': {
      width: '85px',
    },
    '& #hiddenText': {
      width: '85px',
    },
  },
  inDemand: {
    color: '#003E68 !important',
    fontWeight: 500,
  },
  rowHeadDefaultColor: {
    color: '#333333 !important',
  },
  rowContentDefaultColor: {
    color: '#555555 !important',
  },
  rowHead: {
    fontSize: '14px !important',
    fontWeight: 400,
  },
  rowContent: {
    fontSize: '14px !important',
    fontWeight: 500,
  },
  myRow: {
    flexGrow: 1,
  },
  contactIcons: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  goesBy: {
    width: '165px',
  },
  brandInfo: {
    width: '210px',
  },
  status: {
    width: '322px',
  },
  alignContent: {
    display: 'table-row',
  },
  alignContentCustom: {
    // flexDirection: 'column',
    padding: '3px 0px 0px 6px !important',
  },
  myTableLayout: {
    display: 'table',
    borderCollapse: 'separate',
  },
  defaultAlign: {
    display: 'table-cell',
    padding: '7px 4px 5px 0px',
  },
  customInDemand: {
    display: 'flex',
    fontSize: '12px',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
  },
  lowRatioLayout: {
    fontSize: '12px !important',
    whiteSpace: 'nowrap',
  },
  smallerDefaultAlign: {
    display: 'table-cell',
    padding: '5px 20px 5px 0px',
  },
  buttonText: {
    paddingTop: '2px',
  },
  customFontSize: {
    fontSize: '25px !important',
  },
  tooltip: {
    display: 'grid',
  },
  tooltipArrow: {
    color: '#fff',
    '&::before': {
      border: '1px solid #E5E5E5',
      boxShadow: '0px 3px 6px #00000029',
    },
  },
  tooltipBackground: {
    backgroundColor: '#fff',
    color: '#555555',
    fontSize: '12px',
    border: '1px solid #E5E5E5',
    boxShadow: '0px 3px 6px #00000029',
    maxWidth: 'none',
  },
  tooltipSize: {
    maxWidth: '500px',
  },
  tooltipPopper: {
    zIndex: 200,
    '& .MuiTooltip-popper': {
      zIndex: 200,
    },
  },
  actionContainer: {
    fontSize: '14px',
    backgroundColor: `${theme.palette.system.blueSecondary}`,
    display: 'flex',
    padding: '12px',
    borderRadius: '4px',
    minHeight: 38,
    justifyContent: 'space-between',
    marginTop: '12px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: '7px !important',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px !important',
    },
  },
  rowHeadCustom: {
    color: `${theme.palette.framework.system.doveGray}`,
    fontWeight: 500,
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    },
  },
  rowHeadSkillset: {
    color: `${theme.palette.framework.system.doveGray}`,
    paddingLeft: 6
  },
  chipToolTip:{
    fontWeight: 'normal'
  },
  rowHeadSkillsetBox:{
    display: 'flex',
    justifyContent: 'flex-end'
  },
  rowContentCustom: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    },
  },
  addressText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '14px !important',
    fontWeight: 'normal',
    color: `${theme.palette.framework.system.doveGray}`,
  },
  addInfo: {
    color: '#006FB9',
    paddingLeft: '5px',
    textDecoration: 'underline',
  },
  candidateStatusText: {
    textOverflow: 'ellipsis',
    display: 'block',
    overflow: 'hidden',
  },
  statusContainer: {
    width: 'min-content',
  },
  name: {
    textOverflow: 'ellipsis',
    width: '120px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  candidateStatusDropdown: {
    borderRadius: '5px',
    height: 28,
    width: 155,
    color: '#FFF ',
    backgroundColor: '#006FB9',
    '&.MuiInput-root.Mui-focused': {
      backgroundColor: '#006FB9',
    },
    '&.MuiInput-root': {
      '&:hover': {
        backgroundColor: '#006FB9',
      },
    },
  },
  candidateStatusAuditFileHoldStyle: {
    backgroundColor: '#CC001C !important',
    borderRadius: '5px',
    height: 28,
    width: 155,
    color: '#FFF !important',
    '&.MuiInput-root.Mui-focused': {
      backgroundColor: '#006FB9',
    },
    '&.MuiInput-root': {
      '&:hover': {
        backgroundColor: '#006FB9',
      },
    },
  },
  candidateStatusAuditOngoingStyle: {
    backgroundColor: '#F28D2F !important',
    borderRadius: '5px',
    height: 28,
    width: 155,
    color: '#FFF !important',
    '&.MuiInput-root.Mui-focused': {
      backgroundColor: '#006FB9',
    },
    '&.MuiInput-root': {
      '&:hover': {
        backgroundColor: '#006FB9',
      },
    },
  },
  failIconContainer: {
    borderRadius: '20px',
    border: '.5px solid #CCCCCC',
    padding: '2px 5px',
    height: 28,
    margin: '0 3px',
  },

  dropdownStyle: {
    marginTop: 29,
    width: 155,
    height: 166,
  },
  dropDownStyle: {
    marginTop: ' -10px',
    width: 155,
    height: 166,
  },
  selectFocus: {
    '&.MuiSelect-select': {
      paddingLeft: 7,
    },
    '&.Mui-disabled': {
      WebkitTextFillColor: '#FFFFFF !important',
    },
  },

  disabledSelect: {
    borderRadius: '5px',
    height: 28,
    width: 155,
    color: '#FFF',
    backgroundColor: '#D0D0D0',
    '&.MuiInput-root.Mui-focused': {
      backgroundColor: '#D0D0D0',
    },
    '&.MuiInput-root': {
      '&:hover': {
        backgroundColor: '#D0D0D0',
      },
    },
  },
  dropDownItem: {
    '&.MuiListItem-gutters': {
      paddingLeft: 7,
      paddingRight: 7,
      height: 26,
    },
  },
  dialog: {
    maxWidth: 427,
    overflow: 'hidden',
  },
  reasonCodeMenu: {
    marginTop: 53,
    width: 306,
    height: 179,
  },
  reasonInfoIcon: {
    marginTop: 4,
    marginLeft: 4,
  },
  iconButton: {
    padding: '0px',
  },
  chip: {
    background: '#fff',
    margin: '0px 5px',
  },
  tooltipChip: {
    background: '#fff',
    color: '#0F6FB9',
    fontWeight: 500,
    margin: '0px 5px',
  },
  verifiedIcon: {
    height: '5px',
    width: '5px',
    textAlign: 'center',
    marginTop: '5px',
  },
  tooltipPlacementBottom: {
    margin: 0,
    marginTop: 22,
    padding: '8px 8px',
    fontSize: '14px',
    color: '#000',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  menuItem: {
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  tags: {
    flexBasis: '15%',
    flexGrow: 1,
    msOverflowStyle: 'none',
    '@media (min-width: 1260px) and (max-width: 1515px)': {
      maxWidth: '110px',
    },
  },
  mticonstyle: {
    width: '17px',
    height: '17px',
    color: '#8d8686 !important',
  },
  goesByTextArea: {
    width: '100px',
    'border-top': '0',
    'border-left': '0',
    'border-right': '0',
    'border-bottom-color': '#bababa',
    '&:focus-visible': {
      outline: 'none',
      background: '#f2f2f2',
    },
  },
  datepickerCustomeClass: {
    '& .MuiFormControl-root > div :before, .MuiFormControl-root > div hover:before': {
      borderBottom: 'none !important',
    },
    '& .MuiFormControl-root > div :after': {
      borderBottom: 'none !important',
    },
  },
  withdrawIcons: {
    height: '31px',
    width: '37px',
  },
  gridIcons: {
    padding: '12px 5px 0px 0px !important',
  },
}));

export default useCandidateHeaderStyle;
