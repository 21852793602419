import React from 'react';
import { Autocomplete, TextField } from 'amn-ui-core';
import { Controller, useFormContext } from 'react-hook-form';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const AutoCompleteRenderDropdowns = ({
  label,
  options,
  id,
  name,
  clearIcon = true,
}: {
  label: string;
  options: any;
  id: string;
  name: string;
  clearIcon?: boolean;
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ onChange, ...props }) => (
        <Autocomplete
          id={id}
          fullWidth
          disablePortal
          options={options}
          getOptionLabel={option => option?.Description}
          onChange={(e, data) => onChange(data)}
          renderInput={params => (
            <TextField
              {...params}
              label={label}
              variant="filled"
              inputProps={{
                ...params.inputProps,
                readOnly: clearIcon ? false : true,
              }}
            />
          )}
          popupIcon={false ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          clearIcon={clearIcon}
          {...props}
        />
      )}
    />
  );
};

export default AutoCompleteRenderDropdowns;
