import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableCell, ColumnHeader } from '../../../CommonTableComponents/CommonTableComponents';
import RequestIcon from 'app/assets/images/IconImages/RequestIcon.svg';
import { makeStyles } from 'tss-react/mui';
import { IconButton } from 'amn-ui-core';
import { colors } from 'styles/styleVariables';
import { OrderSpecs } from './OrderSpecs';
import { InterviewSpecs } from './InterviewSpecs';
import { CustomTooltip } from '@AMIEWEB/Common';
import { useSelector } from 'react-redux';
import { selectPlacementHeaderData } from 'store/redux-store/placement-details/selectors';
import { Concatenate } from 'utils/string/string';
import { ColumnTruncator } from './ColumnTruncator';

const useStyles = makeStyles()(() => ({
  candidateTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  requestIconBtn: {
    padding: 0,
    '&:hover': {
      // rgb value of colors.amnBlue
      backgroundColor: 'rgb(0,111,185, 0.4)',
    },
  },
  requestIcon: {
    '&:hover': {
      fill: colors.amnBlue,
    },
  },
}));

export const VirtualInterviewContainer = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [forceTruncation, setForceTruncation] = useState(true);

  const {
    candidate: { firstName, lastName },
  } = useSelector(selectPlacementHeaderData);

  return (
    <Table>
      <thead>
        <TableCell style={{ width: '40%' }}>
          <ColumnHeader variant="body1">{t('Order')}</ColumnHeader>
        </TableCell>
        <TableCell>
          <div className={classes.candidateTitle}>
            <ColumnHeader variant="body1">{Concatenate([firstName, lastName], ' ')}</ColumnHeader>
            <IconButton
              disabled
              style={{ visibility: 'hidden' }}
              color="primary"
              classes={{ colorPrimary: classes.requestIconBtn }}
            >
              <CustomTooltip
                standardMargin
                tooltipContent={t('placement.profile.summary.interview.virtualInterviewContainer.requestTooltip')}
                placement={'bottom'}
              >
                <img src={RequestIcon} alt="Request" className={classes.requestIcon} />
              </CustomTooltip>
            </IconButton>
          </div>
        </TableCell>
      </thead>
      <tbody>
        <TableCell>
          <ColumnTruncator forceTruncation={forceTruncation} setForceTruncation={setForceTruncation} maxHeight={250}>
            <OrderSpecs />
          </ColumnTruncator>
        </TableCell>
        <TableCell>
          <ColumnTruncator forceTruncation={forceTruncation} setForceTruncation={setForceTruncation} maxHeight={250}>
            <InterviewSpecs />
          </ColumnTruncator>
        </TableCell>
      </tbody>
    </Table>
  );
};
