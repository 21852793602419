/* eslint-disable @typescript-eslint/naming-convention */
import { Grid, IconButton } from 'amn-ui-core';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchField } from '../../Search/SearchField';
import { makeStyles } from 'tss-react/mui';
import { TemplateCard } from './TemplateCard';
import { CustomTooltip } from '@AMIEWEB/Common';
import { ITemplate } from 'store/redux-store/confirmation-template-editor/types';
import TemplateNotFound from 'app/assets/images/Template/TemplateNotFound.svg';
import TemplateSearchNotFound from 'app/assets/images/Template/TemplateSearchNotFound.svg';
import _ from 'lodash';
import { ActionModal, IActionModalProps } from '../customComponents/ActionModal';
import { Add } from '@mui/icons-material';
import { convertToPST, sortDates } from 'app/helpers/dateHelper';
import { IEditorPreference } from 'oidc/UserDevicePreference/userPreference.redux';

const useStyles = makeStyles()(theme => ({
  textField: {
    height: 40,
    width: '100%',
  },
  content: {
    height: 'fit-content',
    width: '100%',
    backgroundColor: theme.palette.components.grid.content.backgroundColor,
    alignSelf: 'center',
  },
  templatesContainer: {
    height: '800px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: 'calc(100vh - 208px)',
    flexWrap: 'nowrap',
  },
  searchBarContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    position: 'sticky',
    top: '0px',
    zIndex: 2,
    backgroundColor: theme.palette.system.grayChip,
  },
  addButton: {
    marginLeft: '5px',
    marginTop: '7px',
  },
  messageBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'inherit',
    paddingLeft: '20px',
  },
  message: {
    fontSize: '14px',
    color: theme.palette.system.darkGray,
    fontStyle: 'italic',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    ' span': {
      padding: '1rem 2rem 1rem 2rem',
    },
  },
}));

export const TemplatesWrapper: React.FC<{
  templates: ITemplate[];
  selectedTemplate: ITemplate | null;
  handleDelete: (item: number) => void;
  onTemplateClick: (item: ITemplate) => void;
  isDeletingDraft?: boolean;
  userIsReadOnly: boolean;
  selectedTemplateIsDirty: boolean;
  onCancel: (callbackFn?) => void;
  restrictToMergeEngine: boolean;
  editorPreference: IEditorPreference;
  updatePreferences: (pref) => void;
}> = ({
  templates,
  selectedTemplate,
  handleDelete,
  onTemplateClick,
  onCancel,
  isDeletingDraft,
  userIsReadOnly,
  selectedTemplateIsDirty,
  restrictToMergeEngine,
  editorPreference: { pinnedTemplates = [], ...restPreferences },
  updatePreferences,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  // @ts-ignore
  const [modalProps, setModalProps] = useState<IActionModalProps>({ open: false });
  // @ts-ignore
  const closeModal = React.useCallback(() => setModalProps({ open: false }), []);

  const [searchQuery, setSearchQuery] = useState('');

  const filteredTemplates = useMemo(() => {
    if (templates.length === 0) {
      return null;
    }

    const _templates = _.cloneDeep(templates);

    const { _drafts, _pinnedTemplates, _unpinnedTemplates } = _templates.reduce(
      (results, template) => {
        if (!template.templateName?.toLowerCase().includes(searchQuery.toLowerCase())) return results;
        const pinnedTemplate = pinnedTemplates.find(x => x.id == template.templateId);
        if (template.isDraft) results._drafts.push(template);
        // eslint-disable-next-line eqeqeq
        else if (pinnedTemplate)
          results._pinnedTemplates.push({ ...template, pinCard: true, pinnedTimestamp: pinnedTemplate.timeStamp });
        else results._unpinnedTemplates.push(template);
        return results;
      },
      { _drafts: [], _pinnedTemplates: [], _unpinnedTemplates: [] },
    );

    _drafts.sort((a, b) => sortDates(a.updatedOn, b.updatedOn, 'desc'));
    _pinnedTemplates.sort((a, b) => sortDates(a.pinnedTimestamp, b.pinnedTimestamp, 'desc'));
    _unpinnedTemplates.sort((a, b) => sortDates(a.updatedOn, b.updatedOn, 'desc'));

    return [..._drafts, ..._pinnedTemplates, ..._unpinnedTemplates];
  }, [templates, searchQuery, pinnedTemplates]);

  const messageContent = useMemo(() => {
    if (filteredTemplates === null) {
      return (
        <>
          <img src={TemplateNotFound} alt="No templates available" />
          <span>{t('templateEditor.templateName.search.noConfirmationTemplateAvailable')}</span>
        </>
      );
    } else if (filteredTemplates.length === 0) {
      return (
        <>
          <img src={TemplateSearchNotFound} alt="No results found" />
          <span>{t('templateEditor.templateName.search.noResults')}</span>
        </>
      );
    }
    return '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTemplates]);

  const openModal = React.useCallback(
    (item: number) =>
      setModalProps({
        open: true,
        title: t('templateEditor.modal.title.deleteDraft'),
        message: t('templateEditor.modal.message.deleteDraft'),
        primaryBtns: [
          {
            text: t('cancelModal.confirmation.delete'),
            onClick: () => handleDelete(item),
          },
        ],
        secondaryBtns: [
          {
            text: t('cancelModal.confirmation.cancel'),
            onClick: closeModal,
          },
        ],
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleTemplateClick = React.useCallback(
    (templateSelection: ITemplate | null) => {
      if (selectedTemplateIsDirty) {
        setModalProps({
          open: true,
          title: t('cancelModal.undoConfirmation'),
          message: t('cancelModal.saveConfirmation'),
          primaryBtns: [
            {
              text: t('Yes'),
              onClick: () => {
                onCancel(closeModal);
                onTemplateClick(templateSelection);
              },
            },
          ],
          secondaryBtns: [
            {
              text: t('cancelModal.confirmation.cancel'),
              onClick: closeModal,
            },
          ],
        });
      } else onTemplateClick(templateSelection);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTemplateIsDirty, onTemplateClick],
  );

  const initializeNewTemplate = React.useCallback(
    () =>
      // @ts-ignore
      handleTemplateClick({
        id: Number.MIN_VALUE.toString(),
        templateId: Number.MIN_VALUE,
        templateName: searchQuery ?? null,
        templateText: '',
        isDraft: true,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchQuery, handleTemplateClick],
  );

  const onPin = React.useCallback(
    id => {
      const newPreference = {
        ...restPreferences,
        pinnedTemplates: [...pinnedTemplates, { id, timeStamp: convertToPST(new Date()) }],
      };
      updatePreferences(newPreference);
    },
    [pinnedTemplates, restPreferences, updatePreferences],
  );

  const onUnpin = React.useCallback(
    id => {
      const newPreference = { ...restPreferences, pinnedTemplates: pinnedTemplates.filter(x => x.id !== id) };
      updatePreferences(newPreference);
    },
    [pinnedTemplates, restPreferences, updatePreferences],
  );

  return (
    <Grid container className={classes.templatesContainer} direction="column" spacing={2}>
      <Grid className={classes.searchBarContainer} container item>
        <Grid item classes={{ root: classes.content }}>
          <SearchField
            autoFocus
            inheritWidth
            name={'search-template-tokens'}
            placeholder={t('appHeader.searchPlaceHolder')}
            adornAtStart
            onChange={e => setSearchQuery(e.target.value)}
            searchFieldClasses={{ textField: classes.textField }}
          />
        </Grid>
        {restrictToMergeEngine ? null : (
          <Grid item style={{ padding: 0, height: '40px', width: '40px', position: 'relative', top: '-1px' }}>
            <CustomTooltip tooltipContent={userIsReadOnly ? `Read Only` : `New Template`}>
              <IconButton
                color="primary"
                className={classes.addButton}
                size="medium"
                disableFocusRipple
                disableTouchRipple
                onClick={initializeNewTemplate}
                disabled={userIsReadOnly}
              >
                <Add />
              </IconButton>
            </CustomTooltip>
          </Grid>
        )}
      </Grid>

      {!filteredTemplates || filteredTemplates.length === 0 ? (
        <Grid className={classes.messageBox}>
          <div className={classes.message}>{messageContent}</div>
        </Grid>
      ) : (
        filteredTemplates.map(template => (
          <Grid key={template.id} item style={{ position: 'relative', zIndex: 1 }}>
            <TemplateCard
              key={template.id}
              template={template}
              isSelected={selectedTemplate?.id === template.id}
              onClick={() => {
                handleTemplateClick(selectedTemplate?.id === template.id ? null : template);
              }}
              onDelete={() => openModal(template.templateId)}
              restrictToMergeEngine={restrictToMergeEngine}
              pinCard={template.pinCard}
              onPin={() => onPin(template.templateId)}
              onUnpin={() => onUnpin(template.templateId)}
              pinnable
            />
          </Grid>
        ))
      )}
      <ActionModal {...modalProps} loading={isDeletingDraft} />
    </Grid>
  );
};
