import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../types';

import { initialState } from './ExpiringCredential.redux';

const selectDomain = (state: RootState) => state?.credentialingDashboardExpiring || initialState;

export const selectExpiringGridData = createSelector(
  [selectDomain],
  credentialingState => credentialingState.filteredData || credentialingState.flattenedData,
);

export const selectExpiringSourceData = createSelector([selectDomain], data => data.flattenedData);

export const selectFilterSpecs = createSelector([selectDomain], credentialingState => credentialingState.filterSpecs);

export const selectFilterChipSelected = createSelector(
  [selectDomain],
  credentialingState => credentialingState.filterChipSelected,
);

export const selectExpiringChips = createSelector([selectDomain], credentialingState => credentialingState.chips);

export const selectExpiringCounts = createSelector([selectDomain], credentialingState => credentialingState.counts);

export const selectExpiringLoading = createSelector([selectDomain], credentialingState => credentialingState.loading);
