import React from 'react';
import { missingField } from 'app/constants';
import { MoreTextBlock } from '../../CustomComponents/MoreTextBlock';
import { useTranslation } from 'react-i18next';

export const OrderInfoPreferredQualifications = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <div id="placement-order-info-preferred-qualifications" data-testid="order-info-preferred-qualifications-item">
      <MoreTextBlock
        title={t('placement.profile.orderInformation.orderInformation.orderInfoRow.preferredQualifications')}
        text={
          props.orderInformation?.preferredQualifications
            ? props.orderInformation?.preferredQualifications
            : `${missingField}`
        }
        charLimit={170}
        id={'placementDetailsTabsPreferredQualificationsDetails'}
      />
    </div>
  );
};
