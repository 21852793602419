import React from 'react';
import { InputAdornment, TextField } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';
import { withStyles } from 'tss-react/mui';

export const NumberField = withStyles(TextField, {
  root: {
    '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
});

export const Currency = props => {
  const { t } = useTranslation();
  const { value: currencyValue, label, disabled } = props;

  function handleValue(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, newValue) {
    let beforeValidation;
    !newValue.includes('.')
      ? (beforeValidation = newValue.substring(0, 4))
      : (beforeValidation = newValue.substring(0, 7));
    var regex = /^\d+(\.\d{0,2})?$/g;
    if (beforeValidation.length === 0) {
      props.onChange('');
    } else if (!regex.test(beforeValidation)) {
      props.onChange(currencyValue);
    } else {
      props.onChange(beforeValidation);
    }
  }

  const handleBlur = () => {
    let currency = currencyValue;
    if (currency) {
      if (currency.includes('.')) {
        const index = currency.indexOf('.');
        if (currencyValue.length - index - 1 === 1) currency = currency + '0';
      } else currency = currency + '.00';
    }
    props.onChange(currency);
    props.onBlur(currency);
  };

  return (
    <NumberField
      className={props.className}
      label={label}
      variant="filled"
      type="number"
      onChange={e => handleValue(e, e.target.value)}
      onKeyDown={e => e.key === 'e' && e.preventDefault()}
      onBlur={handleBlur}
      disabled={disabled}
      placeholder={props.placeholder}
      style={props.style}
      value={currencyValue}
      InputProps={{
        startAdornment: <InputAdornment position="start">{t('$')}</InputAdornment>,
        // classes: { underline: classes.underline, focused: classes.focused },
      }}
      inputProps={{ 'aria-label': label }}
    />
  );
};
