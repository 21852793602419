import { ITypeAheadOption } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/PreferencesTab/CustomComponents/ControlledTypeAhead';

export const formatTypeAheadVirtualRatingOptions = (options: string[]) =>
  options.map(item => {
    const option: ITypeAheadOption = {
      object: item,
      label: item,
    };
    return option;
  });

export const formatTypeAheadEMR = (options: { id: number; name: string }[]) =>
  options.map(item => {
    const option: ITypeAheadOption = {
      object: item,
      label: item.name,
    };
    return option;
  });

export const formatTypeAheadDisciplineOptions = (
  options: { abbreviation: string; description: string; id: number; name: string | null }[],
) =>
  options.map(item => ({
    object: {
      ID: item.id,
      Abbreviation: item.abbreviation,
      Description: item.description,
    },
    label: item.description,
  }));

export const formatTypeAheadSubSpecialtyOptions = (
  options: { description: string; specialtyId: number; subSpecialtyId: number }[],
) =>
  options.map(item => ({
    object: {
      SpecialtyID: item.specialtyId,
      SubSpecialtyID: item.subSpecialtyId,
      Description: item.description,
    },
    label: item.description,
  }));

export const formatTypeAheadSpecialtyOptions = (
  options: { specialtyId: number; disciplineId: number; description: string }[],
) =>
  options.map(item => ({
    object: {
      SpecialtyID: item.specialtyId,
      DisciplineID: item.disciplineId,
      Description: item.description,
    },
    label: item.description,
  }));
