
export const CommunictionHistoryFlattenData = items =>
  items.map((item, index) => {
    const data = {
      id: `${item.rowID}-${index}`,
      indexCounter: index,
      communicationType: item.communicationType,
      contactInfo:item.contactInfo,
      date:item.date,
      from:item.from,
      userRole:item.userRole,
      status:item.status,
      type:item.type,
      notes:item.notes,
      placementId:item.placementId,
      recruiter:item.recruiter,
    };
    return data;
  });
