import { Autocomplete, Box, ClickAwayListener, Popper, TextField, materialUiXGrid } from 'amn-ui-core';
import React, { useState, useEffect } from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { makeStyles } from 'tss-react/mui';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useSelector } from 'react-redux';
import { lookupSelector } from 'store/redux-store/lookup/lookup.selectors';

const useStyles = makeStyles()({
  hidden: {
    display: 'none',
  },
  dropdownStyle: {
    maxHeight: '350px',
    minWidth: '200px',
  },
  adjustment: {
    transform: 'translate(-9px, 0px)',
  },
  optionItem: {
    padding: '5px',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  optionItemSelected: {
    padding: '5px',
    backgroundColor: '#EFEFEF',
  },
  optionDisabled: {
    padding: '5px',
    color: '#CECECE',
  },
  textField: {
    '& .MuiInputBase-root': {
      background: '#fff',
      height: '45px',
      '&:hover': {
        background: '#fff',
      },
      '&:focus': {
        background: '#fff',
      },
    },
  },
});

interface EditGridDropdownProps {
  options: any[];
  customWidth?: string;
  renderValue?: (value: string, options: any) => JSX.Element;
  useCustomOptions?: (api: any, params: GridCellParams, lookups?: any) => { ID: number; Description: string }[];
  onChange?: (data: any) => void;
  isDisabled?: ({ value, params }: { value: string; params: GridCellParams }) => boolean;
  getOptionDisabled?: (api: any, params: GridCellParams, option: { ID: number; Description: string }) => boolean;
}

export const RenderEditDropdownCell =
  ({ ...props }: EditGridDropdownProps) =>
  (params: GridCellParams) => {
    return <EditGridDropdown params={params} props={props} />;
  };

const EditGridDropdown = ({ props, params }: { props: EditGridDropdownProps; params: GridCellParams }) => {
  const { classes, cx } = useStyles();
  const api = materialUiXGrid.useGridApiContext();
  const id = params.row.rowID;
  const field = params.colDef.field;
  const [selected, setSelected] = useState<string>(params.value);
  const lookups = useSelector(lookupSelector);
  const [dropdownOptions, setDropdownOptions] = useState<{ ID: number; Description: string }[]>(
    props.useCustomOptions?.(api, params, lookups) || props.options,
  );
  const [editing, setEditing] = useState<boolean>(false);

  useEffect(() => {
    setSelected(params.value);
  }, [params]);

  useEffect(() => {
    setDropdownOptions(props.useCustomOptions?.(api, params, lookups) || props.options);
  }, [params.row.discipline]);

  useEffect(() => {
    setEditing(false);
  }, [selected]);

  const handleEditChange = async (e, value) => {
    if (!props.getOptionDisabled?.(api, params, value)) {
      await api?.current.setEditCellValue({ id, field, value: value?.Description });
      api?.current.stopCellEditMode({ id, field });
      api?.current?.updateRows([
        {
          ...params.row,
          [params.colDef.field]: value?.Description,
        },
      ]);
      setSelected(value?.Description);
      props.onChange(api?.current.getVisibleRowModels());
    }
  };

  const handleChange = (e, value) => {
    setSelected(value?.Description);
    api?.current?.updateRows([
      {
        ...params.row,
        [params.colDef.field]: value?.Description,
      },
    ]);
    props.onChange(api?.current.getVisibleRowModels());
  };

  return props.renderValue ? (
    <React.Fragment>
      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => setEditing(false)}>
        <div style={{ width: '100%' }} className={cx({ [classes.hidden]: !editing })}>
          <Autocomplete
            sx={{ width: props.customWidth }}
            forcePopupIcon={true}
            popupIcon={<ExpandMoreOutlinedIcon />}
            blurOnSelect
            disableClearable
            clearOnBlur
            freeSolo
            disabled={props.isDisabled?.({ value: selected, params })}
            options={dropdownOptions || props.options}
            getOptionLabel={option => option?.Description}
            onChange={handleChange}
            getOptionDisabled={option => props.getOptionDisabled?.(api, params, option)}
            value={dropdownOptions?.find(item => item.Description === (selected || params.value) || null)}
            renderInput={autoParams => (
              <TextField
                sx={{
                  transform: 'translate(-10px, 0px)',
                  paddingRight: '10px',
                  '& .MuiInputBase-root': {
                    background: 'transparent',
                    height: '45px',
                    '&:hover': {
                      background: 'transparent',
                    },
                    '&:focus': {
                      background: 'transparent',
                    },
                  },
                }}
                className={classes.textField}
                variant="standard"
                InputProps={{
                  ...autoParams.InputProps,
                }}
                {...autoParams}
              />
            )}
            renderOption={(autoParams, option, { selected: isSelected }) => (
              <li
                {...autoParams}
                className={
                  selected === option.Description
                    ? classes.optionItemSelected
                    : props.getOptionDisabled?.(api, params, option)
                    ? classes.optionDisabled
                    : classes.optionItem
                }
              >
                {option.Description}
              </li>
            )}
            PopperComponent={PopperComponent}
          />
        </div>
      </ClickAwayListener>
      <Box
        onClick={() => setEditing(true)}
        className={cx({
          [classes.hidden]: editing,
        })}
        style={{ width: '100%', minHeight: '20px' }}
      >
        {selected ? props.renderValue?.(selected, dropdownOptions) || selected : '--'}
      </Box>
    </React.Fragment>
  ) : (
    <div style={{ width: '100%' }}>
      <Autocomplete
        forcePopupIcon={true}
        popupIcon={<ExpandMoreOutlinedIcon />}
        blurOnSelect
        disableClearable
        clearOnBlur
        freeSolo
        open={props.isDisabled?.({ value: selected, params }) ? false : true}
        disabled={props.isDisabled?.({ value: selected, params })}
        options={dropdownOptions || props.options}
        getOptionLabel={option => option?.Description}
        onChange={handleEditChange}
        getOptionDisabled={option => props.getOptionDisabled?.(api, params, option)}
        value={dropdownOptions?.find(item => item.Description === (selected || params.value)) || null}
        renderInput={autoParams => (
          <TextField
            sx={{
              '& .MuiInputBase-root': {
                background: 'transparent',
                height: '45px',
                '&:hover': {
                  background: 'transparent',
                },
                '&:focus': {
                  background: 'transparent',
                },
              },
            }}
            className={classes.textField}
            variant="standard"
            InputProps={{
              ...autoParams.InputProps,
            }}
            {...autoParams}
          />
        )}
        renderOption={(autoParams, option, { selected: isSelected }) => (
          <li
            {...autoParams}
            className={
              selected === option.Description
                ? classes.optionItemSelected
                : props.getOptionDisabled?.(api, params, option)
                ? classes.optionDisabled
                : classes.optionItem
            }
          >
            {option.Description}
          </li>
        )}
        PopperComponent={PopperComponent}
      />
    </div>
  );
};
const PopperComponent = props => (
  <Popper
    {...props}
    placement="bottom"
    disablePortal={false}
    modifiers={[
      {
        name: 'flip',
        enabled: false,
        options: {
          altBoundary: true,
          rootBoundary: 'document',
          padding: 8,
        },
      },
      {
        name: 'preventOverflow',
        enabled: false,
        options: {
          altAxis: false,
          altBoundary: false,
          tether: false,
          rootBoundary: 'document',
          padding: 8,
        },
      },
    ]}
  />
);
