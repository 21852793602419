import { ApplicationInsights, DistributedTracingModes, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const reactPlugin = new ReactPlugin();
const history = createBrowserHistory();
const connectionString =
  globalThis.REACT_APP_APPLICATION_INSIGHTS_KEY ?? globalThis?.app?.env?.REACT_APP_APPLICATION_INSIGHTS_KEY;
const ai = new ApplicationInsights({
  config: {
    connectionString: connectionString,
    enableAutoRouteTracking: false, // Set to false for custom page load time tracking
    autoTrackPageVisitTime: false, // Set to false for custom page load time tracking
    disableFetchTracking: true,

    disableCorrelationHeaders: false,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
    extensions: [reactPlugin],
    correlationHeaderExcludedDomains: ['cdn.optimizely.com', 'r.logr-ingest.com'],
    extensionConfig: {
      [reactPlugin.identifier]: { history: history },
    },
    cookieCfg: {
      setCookie(name, value) {
        document.cookie = `${name}=${value};secure`;
      },
    },
  },
});

const dns = (globalThis?.app?.env?.REACT_APP_BASE_URL as string).split('//')[1];
const islocalDev = dns.includes('localhost');
if (!islocalDev) {
    ai.loadAppInsights();
}


ai.addTelemetryInitializer((envelope: ITelemetryItem) => {
  envelope.tags = envelope.tags || [];
  envelope.tags.push({ 'ai.cloud.role': 'AMIE WEB' });
});

var telemetryTraceContextInitializer = envelope => {
  // const telemetryTraceContext = ai.context?.telemetryTrace;
  // if (telemetryTraceContext !== undefined) {
  //   // Add trace properties in the same format used by Microsoft.Extensions.Logging
  //   if (envelope.name === 'Microsoft.ApplicationInsights.{0}.RemoteDependency') {
  //     envelope.baseData = envelope.baseData ?? {};
  //     envelope.baseData.properties = envelope.baseData.properties ?? {};
  //     envelope.baseData.properties.TraceId = telemetryTraceContext.traceID ?? '';
  //     // For AppDependency, parentID is the parent and the span need to be parsed from ID
  //     envelope.baseData.properties.SpanId = envelope.baseData.id?.slice(-17, -1) ?? '';
  //     envelope.baseData.properties.ParentId = telemetryTraceContext.parentID ?? '';
  //   } else {
  //     envelope.baseData = envelope.baseData ?? {};
  //     envelope.baseData.properties = envelope.baseData.properties ?? {};
  //     envelope.baseData.properties.TraceId = telemetryTraceContext.traceID ?? '';
  //     // AppInsights treats traces as children of the current span, so stores
  //     // current span-id in the parentID field.
  //     // The startSpan() function then stores parent-id in the previousParentID field.
  //     envelope.baseData.properties.SpanId = telemetryTraceContext.parentID ?? '';
  //     //  envelope.baseData.properties.ParentId = telemetryTraceContext.previousParentID ?? '';
  //   }
  // }
};
ai.addTelemetryInitializer(telemetryTraceContextInitializer);

export default (Component, componentName?, className?) =>
  withAITracking<any>(reactPlugin, Component, componentName, className);
export const appInsights = ai.appInsights;
export const appInsightsContext = ai.context;
export { reactPlugin };
export const setAuthenticatedUserContext = (userId, accountId) => {
  ai.setAuthenticatedUserContext(userId, accountId, true);
};
