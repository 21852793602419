/* eslint-disable tss-unused-classes/unused-classes */
/* eslint-disable i18next/no-literal-string */
import React, { useCallback, useEffect, useState } from 'react';
import { Box, ClickAwayListener, InputAdornment, Paper, TextField } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import SearchIcon from '@mui/icons-material/Search';
import { ITypeAheadOption } from 'app/models/Orders/OrderDetails';
import { deepEqual } from 'utils/common/comparison';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const levelPadding: number = 20;

const useStyles = makeStyles()({
  root: {
    fontSize: '14px',
    display: 'inline-block',
    width: '100%',
    position: 'relative',
  },
  titleDisabled: {
    color: '#333333',
    fontSize: '14px',
    fontWeight: 600,
  },
  title: {
    color: '#555555',
    fontSize: '14px',
  },
  historyTitle: {
    color: '#555555',
    fontSize: '14px',
    paddingLeft: '5px',
    fontWeight: 500,
  },
  selectable: {
    '&:hover': {
      backgroundColor: '#EFEFEF',
    },
    cursor: 'pointer',
  },
  searchIcon: {
    color: '#bfbfbf',
  },
  textField: {
    fontSize: '14px',
  },
  overflowHidden: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  renderOption: {
    color: '#555555',
    fontSize: '14px',
  },
  renderGroup: {
    color: '#333333',
    fontSize: '16px',
    fontWeight: 500,
    padding: '18px 16px 6px 16px',
  },
  option: {
    padding: '4px 16px',
    fontSize: '14px',
  },
  requirementType: {
    fontWeight: 500,
  },
  input: {
    padding: '6px 30px 6px 6px !important',
    fontSize: '14px',
  },

  history: {
    paddingRight: '10px',
    color: '#555555',
  },
  historyIcon: {
    height: '20px',
    width: '20px',
  },
});
interface MultiGroupTypeAheadProps {
  value: string;
  options: any[];
  onChange: (event) => void;
  onSelect: (option) => void;
  disabled?: boolean;
  placeholder?: string;
  historyKey?: any;
  historyLength?: number;
  searchOnCharacterCount?: number;
  hideUntilTyped?: boolean;
  onFocus?: Function;
  shouldHaveAutoFocus?: boolean;
  isInDialog?: boolean;
}

export const MultiGroupTypeAhead = (props: MultiGroupTypeAheadProps) => {
  const {
    value,
    options,
    onChange,
    onSelect,
    disabled,
    placeholder,
    historyKey,
    historyLength = 3,
    shouldHaveAutoFocus = true,
    isInDialog = false,
  } = props;
  const [open, setOpen] = useState(false);
  const [history, setHistory] = useState<ITypeAheadOption[]>([]);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [val, setVal] = useState('');
  const { classes } = useStyles();
  const [width, setWidth] = useState(null);
  const autocompleteRef: React.RefObject<any> = React.useRef();
  const ref = React.createRef();
  const inputRef = useCallback(node => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);
  const getHistory = useCallback(() => {
    let localHistory: any;
    const foundHistory = localStorage.getItem('searchHistory');
    if (foundHistory === null) {
      localHistory = {
        [historyKey]: [],
      };
    } else localHistory = JSON.parse(foundHistory || '');
    return localHistory;
  }, [historyKey]);

  useEffect(() => {
    if (historyKey) {
      const localHistory = getHistory();
      setHistory(localHistory[historyKey]);
    }
  }, [getHistory, historyKey]);

  useEffect(() => {
    setVal(value);
    if (value === null) {
      autocompleteRef?.current?.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();
      autocompleteRef?.current?.getElementsByClassName('MuiInputBase-input')[0].blur();
      setOpen(false);
      setShowHistory(false);
    }
  }, [value]);

  const _onChange = event => {
    setVal(event.target.value);

    onChange(event);
    if (event.target.value.length >= 2) {
      setOpen(true);
      setShowHistory(false);
    } else if (event.target.value.length === 0 && history.length > 0) {
      setShowHistory(true);
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const _onKeyUp = event => {
    if (event.key === 'Escape') {
      setOpen(false);
    } else if (event.key === 'Enter') {
      _onChange(event);
    }
  };
  const onFocus = event => {
    if (typeof props.onFocus === 'function') props.onFocus(event);
    if (!!historyKey && history.length > 0 && (value?.length || 0) === 0) {
      setOpen(true);
      setShowHistory(true);
    }
  };

  const onKeyDown = event => {
    if (event.key === 'Enter') {
      _onChange(event);
    }
  };

  React.useEffect(() => {
    inputRef(ref.current);
  });

  const onClick = option => {
    setOpen(false);
    setVal(option.selectedTitle);
    onSelect(option);
    if (historyKey && option !== null) {
      let localHistory: any = [...history];
      let foundIndex = -1;
      localHistory.forEach((item, index) => {
        if (deepEqual(item, option)) {
          foundIndex = index;
        }
      });
      if (foundIndex !== -1) localHistory.splice(foundIndex, 1);
      localHistory = [option, ...localHistory].slice(0, historyLength);
      setHistory(localHistory);
      localStorage.setItem('searchHistory', JSON.stringify({ ...getHistory(), [historyKey]: localHistory }));
    }
  };
  const handleClick = option => {
    setOpen(false);
    setVal(option.selectedTitle);
    onSelect(option);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={classes.root}>
        <TextField
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={shouldHaveAutoFocus}
          innerRef={ref}
          variant="outlined"
          style={{
            position: 'relative',
            width: '100%',
            flexGrow: 1,
          }}
          size="small"
          value={val}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          onKeyUp={_onKeyUp}
          onChange={_onChange}
          onFocus={onFocus}
          disabled={disabled}
          inputProps={{
            'data-testid': 'typeahead-textbox',
            classes: {
              input: classes.textField,
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            ),
          }}
        />
        {open && options ? (
          <Paper
            style={{
              position: isInDialog ? undefined : 'absolute',
              zIndex: 1000,
              padding: '10px 15px 10px 0px',
              maxHeight: '400px',
              overflow: 'overlay',
              display: isInDialog ? 'flex' : 'block',
              width: isInDialog ? `${width}px` : 'max-content',
            }}
          >
            {options?.length > 0 && !showHistory && Renderer(null, options, 0, classes, onClick)}
            {showHistory && renderOptionHistory(classes, history, handleClick)}
            {options?.length === 0 && !showHistory && (
              <div style={{ padding: '5px 15px', fontSize: '1rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>
                No matching data Found
              </div>
            )}
          </Paper>
        ) : (
          []
        )}
      </div>
    </ClickAwayListener>
  );
};

const renderOptionHistory = (classes, history, handleClick?, keyDown?) => (
  <React.Fragment>
    <div className={`${classes.historyTitle}`}>{`Recent Searches`}</div>
    {history.map(item => {
      return (
        <Box
          className={`${classes.renderOption} ${classes.overflowHidden} ${classes.selectable}`}
          onClick={() => handleClick(item)} /*style={{paddingLeft: `${isSub}`}}*/
        >
          <span className={classes.history}>
            <AccessTimeIcon className={classes.historyIcon} />
          </span>
          <span className={classes.requirementType}>{item.selectedTitle}</span>
        </Box>
      );
    })}
  </React.Fragment>
);

const Renderer = (props: null, options: any[], level: number, classes, onClick?: (option: any) => void) => {
  const optionStyle = `4px 10px 4px ${level === 0 ? 10 : levelPadding * level + 10}px`;
  const renderOption = (option, groupTitle, groupLength?) => {
    return (
      <React.Fragment>
        <Box
          onClick={event => {
            if (option.selectable) onClick?.(option);
          }}
          className={`${option.selectable && classes.selectable}`}
          style={{ padding: optionStyle }}
        >
          {
            <div className={`${!option.selectable && !option.attention ? classes.titleDisabled : classes.title}`}>
              {option.title}
            </div>
          }
        </Box>
        {groupTitle && groupLength && (
          <Box className={`${classes.titleDisabled}`} style={{ padding: optionStyle }}>
            <div style={{ paddingLeft: `${levelPadding}px` }}>{`${groupTitle} (${groupLength})`}</div>
          </Box>
        )}
      </React.Fragment>
    );
  };

  return (
    <div style={{ width: '100%' }}>
      {options.map(item => {
        return (
          <div>
            {renderOption(
              item.option,
              item.groupTitle,
              !!item.group && item.group.length > 0 ? item.group?.length : null,
            )}

            {item.group !== null &&
              item.group !== undefined &&
              item.group.length > 0 &&
              Renderer(null, item.group, level + 1, classes, onClick)}
          </div>
        );
      })}
    </div>
  );
};
