import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './ClientContact.redux';

const selectDomain = (state: RootState) => state?.clientContacts || initialState;

export const selectClientContact = createSelector([selectDomain], contact => contact);

export const selectContactId = createSelector([selectDomain], contact => contact.createdContactId);

export const selectFacilityUnits = createSelector([selectDomain], contact => contact.facilityUnits);

export const selectActionsList = createSelector([selectDomain], contact => contact.actionList);

export const selectClientContactDetails = createSelector([selectDomain], contact => contact.clientContact);

export const selectClientContactUpdated = createSelector([selectDomain], contact => contact.isClientContactUpdated);

export const selectIsClientContactEditModalOpen = createSelector(
  [selectDomain],
  contact => contact.isEditClientContactOpen,
);
