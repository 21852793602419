import React from 'react';
import useDocumentTitle from 'utils/customHooks/useDocumentTitle';
import { UserDetailsPage } from './UserDetailsPage';
import { useDispatch, useSelector } from 'react-redux';
import { navigationStickActions, settingMenu } from '@AMIEWEB/GlobalNavigationMenu/NavigationStick.redux';
import { selectNavigationStick } from '@AMIEWEB/GlobalNavigationMenu/NavigationStick.selectors';

export const UserManagement = () => {
  useDocumentTitle({ title: 'User Management' });

  const { selectedMenu } = useSelector(selectNavigationStick);

  const dispatch = useDispatch();

  /** Logic to highlight sidenav if routes are directly accessed */
  React.useEffect(() => {
    if (!['settings', 'userManagement'].includes(selectedMenu.key)) {
      const subMenu = settingMenu.subMenu.find(x => x.key === 'userManagement');
        dispatch(navigationStickActions.setSelectedMenuByKey('settings'));
        dispatch(navigationStickActions.setSelectedSubMenu(subMenu));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ maxHeight: '100%' }}>
      <UserDetailsPage />
    </div>
  );
};
