/* eslint-disable i18next/no-literal-string */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  Typography,
  Paper,
  Container,
  Grid,
  Divider,
  Checkbox,
  IconButton,
  Backdrop,
  CircularProgress,
  Button,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { orderBy } from 'lodash';
import { CustomFilter } from '../Filter/Filter';
import CompareDrawer from '../CompareDrawer/CompareDrawer';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { gridStateActions, GridTag } from './GridStateManagement/GridState.redux';
import CloseIcon from '@mui/icons-material/Close';
import { trackEvent } from 'app-insights/appInsightsTracking';
import { GridTableRow } from './GridTableRow';
import { ExpandedGridTableRow } from './ExpandedGridTableRow';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import ErrorIcon from 'app/assets/images/glance-tile-error-icon.svg';
import { CustomGridProps } from 'app/models/Grid';
import { formatToAmericanNotaion } from 'app/helpers/numberHelper';
import { ExceptionWaiveGridTableRow } from './ExceptionWaiveGridTableRow';
import { ICustomHeader } from 'app/models/Grid';
import { FacilityContactsActions } from 'app/components/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.redux';
import { selectedContactsData } from 'app/components/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.selector';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { selectUser } from 'oidc/user.selectors';
import { isCredentialing, userRoles } from 'oidc/userRoles';
import { Authorized } from 'oidc/userHelper';
import { useEnableEmailCommunication } from 'oidc/CommunicationEnabler';
import { Pagination } from 'app/ComponentLibrary/Pagination/Pagination';
import { globalActions } from 'app/ApplicationRoot/Global.redux';

const useRowStyles: any = makeStyles<
  {
    maxHeight: number | string;
    disableRowColors: boolean;
    colLineHeight: number;
    hasControls: boolean;
    isNested: boolean;
    staticFirstColumn: any;
  },
  'tableHeaderCell'
>()((theme, _params, classes) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
    fontFamily: 'Roboto',
    color: '#333333',
    fontSize: '12px',
  },
  title: {
    margin: '20px',
    fontWeight: 'bold',
  },
  titleNoMargin: {
    fontWeight: 'bold',
  },
  containerSticky: {
    marginTop: '15px',
    maxHeight: !!_params.maxHeight ? _params.maxHeight : window.innerHeight / 1.9,
  },
  container: {
    marginTop: '15px',
  },

  table: {
    width: '1238px',
    margin: '10px',
    fontSize: '11px',
    '&:hover': {
      backgroundColor: _params.disableRowColors ? '#FFF' : '#ECECEC !important',
    },
  },
  tableContainer: {
    minWidth: '100%',
    width: 'max-content',
  },
  tableHeader: {
    color: '#555555',
    textAlign: 'left',
    fontWeight: 'bold',
    wordBreak: 'keep-all',
    padding: '8px 12px',
    overflowY: 'hidden',
    '&:hover': {
      [`& .${classes.tableHeaderCell}`]: {
        visibility: 'visible',
        cursor: 'pointer',
      },
    },
  },
  tableHead: {
    backgroundColor: theme.palette.framework.system.whisper,
    fontSize: '13px',
    width: '1238px',
    height: '28px',
    fontWeight: 'bold',
  },
  tableHeaderCell: {
    fontWeight: 'bold',
    marginLeft: '10px',
    textAlign: 'left',
  },
  tableHeaderCellHidden: {
    visibility: 'hidden',
  },
  headerStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    lineHeight: _params.colLineHeight || '',
  },
  arrowPosition: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  divider: {
    margin: '20px',
    padding: '1px',
  },
  paper: {
    padding: '30px',
    margin: '10px 10px 10px 10px',
  },
  paperNoMargin: {
    padding: '20px',
    paddingBottom: _params.hasControls ? '50px' : '0px',
    backgroundColor: _params.isNested ? 'transparent' : '#FFF',
  },
  paperNoPadding: {
    // 1px padding to show border
    padding: '0px 1px',
    backgroundColor: _params.isNested ? 'transparent' : '#FFF',
  },
  footer: {
    height: '50px',
    marginTop: '10px',
  },
  gridContainer: {
    maxWidth: '3000px',

    overflowY: 'hidden',
    overflowX: 'hidden',
  },
  searchTerm: {
    color: theme.palette.framework.system.skyBlue,
    margin: '30px 10px 10px 10px',
  },
  footerNoData: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '20px',
  },
  expandedTableCell: {
    width: '58px',
  },
  kababMenu: {
    width: '80px',
  },

  backdrop: {
    zIndex: 1500,
  },
  // static first column
  staticHeaderCell: {
    position: 'sticky',
    background: theme.palette.framework.system.whisper,
    boxShadow: 'inset -4px 0 4px -4px rgba(0,0,0,0.30)',
    WebkitBoxShadow: 'inset -4px 0 4px -4px rgba(0,0,0,0.30)',
    width: _params.staticFirstColumn ? `${_params.staticFirstColumn['width']}px` : 'auto',
    zIndex: 500,
    left: 0,
  },
  stickyHeaderRowCell: {
    fontWeight: 'bold',
    lineHeight: '0.3',
  },
  checkboxCell: {
    textAlign: 'center',
  },
  errorIcon: {
    width: 22,
    height: 22,
    marginTop: -4,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dialogCancel: {
    fontSize: '14px',
  },
  titleCancel: {
    fontSize: '20px',
  },
}));

const MAX_ALLOWED_EMAIL_SELECTION = 50;
const CREATE_MESSAGE_WARNING = `Maximum of ${MAX_ALLOWED_EMAIL_SELECTION} recipients permitted. Please reduce your selections and try again.`;

export const CustomGrid = (props: CustomGridProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialCurrentPageData: any[] = [];
  const {
    columns,
    resultsTitle = 'Showing Results',
    secondaryTitle = null,
    thirdTitle = null,
    rows,
    searchTerm = '',
    component = '',
    fixedItemsPerPage,
    defaultSortDirection = 'asc',
    disableGutters,
    noPadding = false,
    fullWidth = false,
    minFullWidth = false,
    showViewOptions = true,
    showCount = true,
    showTitleWithoutCount = false,
    showPagination = true,
    showActivePage = true,
    pageOptions,
    defaultPageView,
    centerHoverView = false,
    hasEditMenu = false,
    rowExpand = false,
    rowSelectionKey,
    selectionLimit,
    enableSelectionDrawer,
    enableTotalRecordsSelection,
    rowSearch,
    onSearchComplete,
    minCellWidth,
    colLineHeight,
    onEditSave,
    onEditSaveComplete,
    onValueChanged,
    canEdit = () => true,
    onPagination,
    totalRecordsCount,
    totalRecordsCountDisplay,
    currentPageNumber,
    onPageSizeChange,
    showPageLoad = false,
    hasSingleEditIcon = false,
    onSingleEditIconClick,
    initialEditingIndex = null,
    showEditControl = true,
    disableRowColors = false,
    onSave = () => {},
    stickyTable = false,
    maxHeight,
    isInDialog = false,
    addingCredential,
    resultTitleClass = '',
    enableStateManagement = false,
    serverSidePagination = false,
    serverSideSorting = false,
    defaultSortedColumn = null,
    rowIconAction,
    starIconAction,
    deleteActions,
    onStateNavigation = _ => {},
    staticFirstColumn = null,
    CustomizedFilter = null,
    isNested,
    onGridClose,
    hasCloseButton = false,
    analytics,
    hasPermisions,
    onSorting,
    titleActions = null,
    handleSelectedRows,
    onElementClick,
    onCheckedRows,
    highlightedRow = null,
    createMessageButton,
    createClientContactButtonIcon,
    handleCreateMessageOnlick,
    handleCreateClientContactOnlick,
    /** END Main grid parent edit controls */
  } = props;
  const { classes } = useRowStyles({
    minCellWidth: minCellWidth,
    disableRowColors: disableRowColors,
    colLineHeight: colLineHeight,
    hasControls: isInDialog,
    minFullWidth: minFullWidth,
    staticFirstColumn: staticFirstColumn,
    isNested: isNested,
    maxHeight: maxHeight,
  });
  const user = useSelector(selectUser);
  const [searchString, setSearchString] = useState('');
  const [rowData, setRowData] = useState(rows);
  const [itemsPerPage, setItemsPerPage] = useState(
    fixedItemsPerPage || (defaultPageView ? Number(defaultPageView) : 100),
  );
  // const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageData, _setCurrentPageData] = useState(initialCurrentPageData);
  const currentPageDataRef = useRef(currentPageData);
  const setCurrentPageData = data => {
    currentPageDataRef.current = data;
    _setCurrentPageData(_.cloneDeep(data));
  };
  const [columnToSort, setColumnToSort] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>(defaultSortDirection || 'asc');
  const [sortIcon, setSortIcon] = useState(false);
  const [includeFilter, setIncludeFilter] = useState(false);
  // const [includeCustomFilter, setIncludeCustomFilter] = useState(showFilters);
  const [rowCount, setRowCount] = useState(0);
  const [hasExpandableRow, setHasExpandableRow] = useState(false);
  const [editingIndex, _setEditingIndex] = useState<number | null>(null);
  const editingIndexRef = useRef(editingIndex);
  const setEditingIndex = data => {
    editingIndexRef.current = data;
    _setEditingIndex(data);
  };
  const enableEmailCommunication = useEnableEmailCommunication();

  /* Row selection Start */
  const [selectDisabled, setSelectDisabled] = useState(false);
  const [selectedRows, setSelectedRows]: any = useState([]);
  const [entireSelection, setEntireSelection] = useState<{ checked: boolean; indeterminate: boolean }>({
    checked: false,
    indeterminate: false,
  });
  /* Row selection End */

  const [refList, setRefList] = useState<any>([]);
  const [searchHighlight, setSearchHighlight] = useState<boolean>(false);
  const [searchFoundIndex, setSearchFoundIndex] = useState<number | null>(null);
  const [dialogPop, setDialogPop] = useState<boolean>(false);
  const [timerPop, setTimerPop] = useState<boolean | undefined>(undefined);
  const [editRowKey, setEditRowKey] = useState<number | null | any>(null);
  const [didEdit, setDidEdit] = useState<boolean>(false);

  const [originalEditRow, setOriginalEditRow] = useState<any>(null);
  const [postingEdit, setPostingEdit] = useState<boolean>(false);
  const [openEditNotAvailable, setOpenEditNotAvailable] = useState<boolean>(false);
  const [stickyHead, setStickyHead] = useState<boolean>(false);
  const [paginationCountCheck, setPaginationCountCheck] = useState<boolean>(false);
  // Delete
  const [deleteDialogContent, setDeleteDialogContent] = useState<string | undefined>('');
  const [deleteDialogTitle, setDeleteDialogTitle] = useState<string | undefined>('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deletableRecord, setDeletableRecord] = useState<boolean>(false);
  // generic alert
  const [alertRowID, setAlertRowID] = useState<any>(null);
  const [genericAlertTitle, setGenericAlertTitle] = useState('');
  const [genericAlertContent, setGenericAlertContent] = useState('');
  const [genericAlertOpen, setGenericAlertOpen] = useState(false);
  // snackbar
  const [deleteSnackbarOpen, setDeleteSnackbarOpen] = useState<boolean>(false);
  // versioning
  const [viewSubgrid, setViewSubgrid] = useState<boolean>(true);
  // analytics
  const [numberOfColumnsSorted, setNumberOfColumnsSorted] = useState<number>(0);
  const selectedContacts = useSelector(selectedContactsData);

  const invertIcon = {
    asc: false,
    desc: true,
  };
  const viewOptions = pageOptions ? pageOptions : [20, 50, 100];
  const defaultNumberOfRecords = defaultPageView ? defaultPageView : '100';
  let previousPage = 0;
  let rowLength = 0;
  let modifiedData: any[] = [];
  const checkFilters = () => {
    const availabilitycheck = columns.map(item => {
      return item.hasOwnProperty('filterable') && item['filterable'];
    });
    return availabilitycheck.includes(true);
  };

  const _onNavigation = (link, header) => {
    if (analytics?.columnSort || analytics?.navigation) {
      trackEvent({
        type: 'navigation',
        name: `${analytics.tableName}`,
        properties: {
          numberOfColumnsSorted: numberOfColumnsSorted,
          columnSelected: header.value,
        },
      });
      setNumberOfColumnsSorted(0);
    }
    props.onNavigation?.(link);
  };

  //#region Handlers
  const handleDataChange = (value, page) => {
    previousPage = value - 1;
    if (onPagination) {
      setCurrentPage(value);
      onPagination(itemsPerPage, value);
      enableStateManagement &&
        dispatch(gridStateActions.setPageAttributes({ pageSize: itemsPerPage, pageNumber: value }));
    } else {
      modifiedData = rowData.slice(previousPage * itemsPerPage, previousPage * itemsPerPage + itemsPerPage);
      createRefs(modifiedData);
      setCurrentPageData(modifiedData);
      setCurrentPage(value);
      enableStateManagement &&
        dispatch(gridStateActions.setPageAttributes({ pageSize: itemsPerPage, pageNumber: value }));
    }
  };

  const handleTotalPages = (event, resultPerPage) => {
    const changedValue = event?.target?.value || event;
    if (analytics?.itemsPerPage) {
      trackEvent({
        type: 'event',
        name: `${analytics.tableName}:Items Per Page`,
        properties: { itemsPerPage: changedValue },
      });
    }
    setItemsPerPage(event?.target?.value || event);
    enableStateManagement && dispatch(gridStateActions.setPageSize(event?.target?.value || event));
    if (onPageSizeChange) onPageSizeChange(event?.target?.value || event);
    if (changedValue === 'All') {
      // setTotalPages(1);
      setCurrentPageData(rowData);
      setCurrentPage(1);
      enableStateManagement && dispatch(gridStateActions.setPageNumber(1));
    } else {
      if (props.totalRecordsCount && props.totalRecordsCount > 0) {
        // setTotalPages(Math.ceil(props.totalRecordsCount / changedValue));
        previousPage = 0;
        rowLength = props.totalRecordsCount;
        if (changedValue >= rowLength) {
          setCurrentPageData(rowData);
          setCurrentPage(1);
          enableStateManagement && dispatch(gridStateActions.setPageNumber(1));
        } else {
          modifiedData = rowData.slice(previousPage * changedValue, previousPage * changedValue + changedValue);
          setCurrentPageData(modifiedData);
          setCurrentPage(1);
          enableStateManagement && dispatch(gridStateActions.setPageNumber(1));
        }
      } else {
        // setTotalPages(Math.ceil(rowData.length / changedValue));
        previousPage = 0;
        rowLength = rowData.length;
        if (changedValue >= rowLength) {
          setCurrentPageData(rowData);
          setCurrentPage(1);
          enableStateManagement && dispatch(gridStateActions.setPageNumber(1));
        } else {
          modifiedData = rowData.slice(previousPage * changedValue, previousPage * changedValue + changedValue);
          setCurrentPageData(modifiedData);
          setCurrentPage(1);
          enableStateManagement && dispatch(gridStateActions.setPageNumber(1));
        }
      }
    }
  };

  /**
   * To acknowledge behaviour, sorting shall happen if exist in current grid columns(ie., if columnIndex !== -1).
   * forcedSortDirection, newRowData --> called from usEffect when grid is expected to load with sorted data.
   */
  const handleSort = (
    columnKey,
    forcedSortDirection: 'asc' | 'desc' | null = null,
    newRowData: any | null = null,
    notifyParent = true,
  ) => {
    const columnIndex = columns.findIndex(item => item.title === columnKey);
    if (columnIndex !== -1) {
      if (analytics?.columnSort) {
        setNumberOfColumnsSorted(numberOfColumnsSorted + 1);
        trackEvent({
          type: 'event',
          name: `${analytics.tableName}: Column Sorted`,
          properties: { column: columns[columnIndex].value },
        });
      }
      setColumnToSort(columnKey);
      const direction: 'asc' | 'desc' = forcedSortDirection
        ? forcedSortDirection
        : columnToSort === columnKey
        ? sortDirection === 'asc'
          ? 'desc'
          : 'asc'
        : 'asc';
      const sortedData = orderBy(
        newRowData || rowData,
        [
          /**sorting for string is given less precedence since missing field for all data types is "--" */
          row =>
            columns[columnIndex].isNumber && typeof row[columnKey] !== 'number'
              ? Number.MIN_SAFE_INTEGER
              : _.isString(row[columns[columnIndex].sortByField || columnKey])
              ? row[columns[columnIndex].sortByField || columnKey].toLowerCase()
              : row[columns[columnIndex].sortByField || columnKey],
        ],
        direction,
      );
      setRowData(sortedData);
      previousPage = currentPage - 1;
      modifiedData = sortedData.slice(0, itemsPerPage);
      setCurrentPageData(modifiedData);

      // Operation when clicked on sort icon on Grid -> to retain state in redux
      (forcedSortDirection === null || newRowData === null) &&
        enableStateManagement &&
        dispatch(gridStateActions.setSortedColumn({ column: columnKey, direction }));

      if (enableStateManagement && props.modifyData && notifyParent) props.modifyData(modifiedData);

      setSortDirection(direction);
      setSortIcon(invertIcon[direction]);
      if (!onPagination) {
        setCurrentPage(1);
        enableStateManagement && dispatch(gridStateActions.setPageNumber(1));
      }
    }
  };

  const forceSortingAttributes = (column, direction) => {
    setSortIcon(invertIcon[direction]);
    setColumnToSort(column);
  };

  const handleServerSort = (column, forcedSortDirection: 'asc' | 'desc' | null = null) => {
    const direction: 'asc' | 'desc' = forcedSortDirection
      ? forcedSortDirection
      : columnToSort === column
      ? sortDirection === 'asc'
        ? 'desc'
        : 'asc'
      : 'asc';
    setSortIcon(invertIcon[direction]);
    setColumnToSort(column);

    setSortDirection(direction);

    enableStateManagement && dispatch(gridStateActions.setSortedColumn({ column, direction }));

    onSorting && onSorting(itemsPerPage, currentPage, { column, direction });
  };

  const handleCustomFilter = (value, isClearFilter: false) => {
    if (isClearFilter && onPagination) {
      setInitialFilterState(rows);
      setRowCount(totalRecordsCount ?? rows.length);
      onPagination(itemsPerPage, 1, false, false);
      enableStateManagement && dispatch(gridStateActions.setPageAttributes({ pageSize: itemsPerPage, pageNumber: 1 }));
    } else {
      setInitialFilterState(value);
      setRowCount(value.length);
    }
  };

  const setInitialFilterState = selectedRows => {
    setRowData(selectedRows);
    // setTotalPages(Math.ceil(selectedRows.length / itemsPerPage));
    previousPage = currentPage - 1;
    const rowlength = selectedRows.length;
    if (itemsPerPage > rowlength) {
      setCurrentPageData(selectedRows);
      setCurrentPage(1);
      enableStateManagement && dispatch(gridStateActions.setPageNumber(1));
    } else {
      modifiedData = selectedRows.slice(previousPage * itemsPerPage, previousPage * itemsPerPage + itemsPerPage);
      setCurrentPageData(modifiedData);
    }
  };

  const _onGridClose = () => {
    onGridClose?.();
    if (!isNested) {
      const index = currentPageData.map(item => item.viewVersions).indexOf(true);
      onVersionView(index);
    }
  };

  const onVersionView = index => {
    setViewSubgrid(!viewSubgrid);
    for (let i = 0; i < currentPageData.length; i++) {
      if (i !== index) {
        currentPageData[i].viewVersions = false;
        currentPageData[i].expanded = false;
      }
    }
    currentPageData[index].expanded = true;
    currentPageData[index].viewVersions = true;
  };
  //#endregion Handlers

  const expandRow = (index: number, expand: boolean) => {
    setViewSubgrid(true);
    const a = [...currentPageData];
    a[index].expanded = expand;
    a[index].viewVersions = false;
    setCurrentPageData(a);
  };

  //#region Row Adding
  /**
   * Parent Save button event handler
   */
  const _onSave = () => {
    onSave?.(editingIndex !== null ? currentPageData[editingIndex] : null);
  };
  //#endregion Row Adding

  //#region Row Editing
  /**
   * Determines if the element on the screen contains on onClick event in its tree
   */
  const isElementClickable = useCallback(obj => {
    if (typeof obj?.onclick === 'function' && obj.nodeName !== 'BODY') return true;
    if (obj.parentNode) return isElementClickable(obj.parentNode);
    else return false;
  }, []);

  /**
   * Detects if a click is outside of an editing row
   */
  const isClickInsideElement = useCallback((obj: Element) => {
    if (typeof obj?.className !== 'object' && obj?.getAttribute?.('data-editingrow') === 'true') return true;
    if (obj.parentNode) return isClickInsideElement(obj.parentNode);
    else return false;
  }, []);

  const windowClose = useCallback(e => {
    e.preventDefault();
    // Note: most browsers do not allow custom text
    return 'You are currently editing a credential, are you sure you want to exit?';
  }, []);

  /**
   * Update the state for the current row when a value changes
   * @param rowKey the index of the row being edited
   * @param key the object key of the item in the row being edited
   * @param value the new value to store
   */
  const _onValueChanged = (rowKey, key, value) => {
    if (!didEdit) {
      setDidEdit(true);
      const origTmp = _.cloneDeep(currentPageData[rowKey]);
      setOriginalEditRow(origTmp);
    }

    const tmp = { ...currentPageData[rowKey] };

    if (tmp.hasOwnProperty(key)) {
      tmp[key] = value;
    } else {
      tmp.detailRows?.forEach(rows => {
        rows.forEach(rowsColumns => {
          rowsColumns.rows.forEach(row => {
            if (row.hasOwnProperty(key)) {
              row[key] = value;
            }
          });
        });
      });
    }

    const updatedRow = onValueChanged?.(key, value, tmp, (title, description) => {
      // callback for custom dialog
      setGenericAlertTitle(title);
      setGenericAlertContent(description);
      setGenericAlertOpen(true);
    });

    const newPageData = _.cloneDeep(currentPageData);
    // onRowUpdate?.(updatedRow);
    if (updatedRow !== null && updatedRow !== undefined) {
      newPageData[rowKey] = updatedRow;
      setCurrentPageData(newPageData);
    } else {
      newPageData[rowKey] = tmp;
      setCurrentPageData(newPageData);
    }
  };
  /** dialog content render according to grid in add or edit mode */

  const dialogContentRender = () => {
    if (addingCredential) {
      return didEdit ? t('search.grid.add.dialogContentSession') : t('search.grid.add.dialogContentWithoutEditSession');
    } else
      return didEdit
        ? t('search.grid.edit.dialogContentSession')
        : t('search.grid.edit.dialogContentWithoutEditSession');
  };

  /**
   * Begin editing the given row of the current page
   * @param index row index to start the edit for
   */
  const editRow = useCallback(
    (rowID: number, overrideCurrentPageData?) => {
      const a = overrideCurrentPageData ? [...overrideCurrentPageData] : [...currentPageData];
      let foundIndex = -1;
      for (let i = 0; i < a.length; i++) {
        if (`${a[i].rowID}` === `${rowID}`) {
          foundIndex = i;
          break;
        } else if (a[i].versionRows) {
          for (let j = 0; j < a[i].versionRows.length; j++) {
            if (`${a[i].versionRows[j].rowID}` === `${rowID}`) {
              foundIndex = i;
              break;
            }
          }
        }
      }

      if (canEdit(a[foundIndex])) {
        a[foundIndex].isEditing = true;
        a[foundIndex].expanded = true;
        a[foundIndex].viewVersions = false;
        setEditingIndex(foundIndex);
        setCurrentPageData(a);
        setOriginalEditRow(null);
        setDidEdit(false);
        setTimerPop(false);
        trackEvent({ type: 'click', name: `${analytics?.tableName}:Edit Button Clicked` });
      } else {
        setOpenEditNotAvailable(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPageData],
  );

  /**
   * Stop editing the given row of the current page
   * @param index row index to stop the edit for
   */
  const stopEditing = useCallback(
    (index: number, dialogFlow = true): boolean => {
      if (didEdit && dialogFlow) {
        // dialog
        setDialogPop(true);
        return false;
      } else {
        const a = [...currentPageData];
        setEditingIndex(null);
        if (didEdit && originalEditRow) {
          setDidEdit(false);
          a[index] = {
            ...originalEditRow,
            isEditing: false,
          };
          setCurrentPageData(a);
          setOriginalEditRow(null);
        } else {
          a[index].isEditing = false;
          setCurrentPageData(a);
        }
        setRowData(rowData.map(item => ({ ...item, isEditing: false })));
        endEditingMessagesAndTimers();
        return true;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPageData, didEdit, originalEditRow],
  );

  /**
   * End all timers and dialogs on the grid
   */
  const endEditingMessagesAndTimers = () => {
    setDialogPop(false);
    setTimerPop(undefined);
  };

  /**
   * Detects clicks on root element if the event listener is set
   */
  const didClick = useCallback(
    e => {
      if (postingEdit) return;
      // const isClickable = isElementClickable(e.target);
      const clickOutsideElement = !isClickInsideElement(e.target);
      if (clickOutsideElement) {
        if (didEdit) {
          e.stopPropagation();
          e.preventDefault();
          setDialogPop(true);
        } else if (editingIndex !== null) {
          stopEditing(editingIndex);
        }
      }
    },
    [didEdit, editingIndex, isClickInsideElement, postingEdit, stopEditing],
  );

  /**
   * restart the edit session timer
   */
  const handleTimerInitiation = () => {
    setTimerPop(false);
  };

  /**
   * show save confirmation dialog
   */
  const handleSaveInitiation = () => {
    handleEditSave(editingIndex);
    setTimerPop(undefined);
  };

  /**
   * Save the current edits of a given row
   * @param rowKey the index of the row of the current page to save the data for
   */
  const handleEditSave = rowKey => {
    if (onEditSave && editingIndex !== null) {
      setPostingEdit(true);
      //@Ayush to declare after successful api call
      setDialogPop(false);

      onEditSave(currentPageData[editingIndex])
        .then(response => {
          const tmpData = [...currentPageData];
          if (response.data !== null && response.data !== undefined) {
            response.data.isEditing = response.continueEditing;
            response.data.expanded = true;
            tmpData[editingIndex] = response.data;

            setCurrentPageData(tmpData);
            dispatch(
              globalActions.setSnackBar({
                message: t('search.grid.edit.successMessage'),
                severity: 'success',
              }),
            );
            // set main row data with updates
            const editingRowID = rowData.map(item => item.rowID).indexOf(response.rowID);
            const tmpRowData = [...rowData];
            tmpRowData[editingRowID] = response.data;
            setRowData(tmpRowData);

            //stop editing without setting state on current page data
            if (!response.continueEditing) {
              setEditingIndex(null);
              endEditingMessagesAndTimers();
            }
          } else {
            //remove record from current page data
            tmpData.splice(editingIndex, 1);
            setCurrentPageData(tmpData);
            //remove record from main row data
            const editingRowID = rowData.map(item => item.rowID).indexOf(response.rowID);
            const tmpRowData = [...rowData];
            tmpRowData.splice(editingRowID, 1);
            setRowData(tmpRowData);
            setRowCount(tmpRowData.length);
            // setTotalPages(Math.ceil(tmpRowData.length / itemsPerPage));
            dispatch(
              globalActions.setSnackBar({
                message: t('search.grid.edit.successMessage'),
                severity: 'success',
              }),
            );
          }

          setDidEdit(false);
          setOriginalEditRow(null);
          setPostingEdit(false);
          setTimerPop(false);
          onEditSaveComplete?.(response.rowID);
        })
        .catch(error => {
          setPostingEdit(false);
          dispatch(
            globalActions.setSnackBar({
              message: error,
              severity: 'error',
            }),
          );
        });
    }
  };

  const _willDelete = () => {
    if (deleteActions) {
      const { title, content, canDeleteRecord } =
        deleteActions.willDelete(alertRowID) || t('search.grid.delete.dialogContent');
      setDeleteDialogTitle(title);
      setDeleteDialogContent(content);
      setDeleteDialogOpen(true);
      setDeletableRecord(canDeleteRecord);
    }
  };

  const handleDelete = () => {
    setDeleteDialogOpen(false);
    setPostingEdit(true);
    deleteActions
      ?.onDelete(alertRowID)
      .then(success => {
        const tmpData = [...currentPageData];
        const currentPageIndex = tmpData.filter(x => x.rowID !== alertRowID).length
          ? tmpData.filter(x => x.rowID !== alertRowID).length
          : tmpData.map(item => item.rowID).indexOf(alertRowID);
        tmpData.splice(currentPageIndex, 1);
        setCurrentPageData(tmpData);
        //remove record from main row data

        const rowDataIndex = rowData.filter(x => x.rowID !== alertRowID).length
          ? rowData.filter(x => x.rowID !== alertRowID).length
          : rowData.map(item => item.rowID).indexOf(alertRowID);
        const tmpRowData = [...rowData];
        tmpRowData.splice(rowDataIndex, 1);
        setRowData(tmpRowData);
        setRowCount(tmpRowData.length);
        // setTotalPages(Math.ceil(tmpRowData.length / itemsPerPage));
        setAlertRowID(null);
        setPostingEdit(false);
        setDeleteSnackbarOpen(true);
        dispatch(
          globalActions.setSnackBar({
            message: t('search.grid.delete.snackbarSuccess'),
            severity: 'success',
          }),
        );
      })
      .catch(error => {
        setAlertRowID(null);
        setPostingEdit(false);
        dispatch(
          globalActions.setSnackBar({
            message: t('search.grid.delete.snackbarError'),
            severity: 'error',
          }),
        );
      });
  };

  /** Close add search pop up */
  const handlePopupClose = rowID => {
    onSingleEditIconClick?.(rowID);
  };

  /**
   * set 10 minute timeout while editing a row
   */
  useEffect(() => {
    let interval;
    if (editingIndex || timerPop === false) {
      interval = setTimeout(() => {
        setTimerPop(true);
      }, 600000);
    }
    return () => clearTimeout(interval);
  }, [editingIndex, timerPop]);

  /**
   * Add event listeners for clicking outside editing node, and closing the window
   */
  useEffect(() => {
    if (editingIndex !== null) {
      document.querySelector('#root')?.addEventListener('click', didClick);
      window.onbeforeunload = windowClose;
    }
    return () => {
      window.onbeforeunload = null;
      document.querySelector('#root')?.removeEventListener('click', didClick);
    };
  }, [didClick, editingIndex, windowClose]);
  //#endregion Row Editing

  //#region Row selection
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRowSelect = (event, selection, rowId) => {
    const newSelections = _.cloneDeep(selectedRows);
    if (event.target.checked) {
      newSelections.push(selection);
      setSelectedRows(newSelections);
      selectionLimit && newSelections.length > selectionLimit ? setSelectDisabled(true) : setSelectDisabled(false);
      currentPageData.find(item => item[rowSelectionKey as string] === selection[rowSelectionKey as string])[
        'selected'
      ] = true;
      if (props.gridName === GridTag.FacilityClientContactsGrid) {
        const selectedData = JSON.parse(JSON.stringify(currentPageData.filter(item => item.selected === true)));
        dispatch(FacilityContactsActions.setSelectedContacts(selectedData));
      }
      setEntireSelection({
        checked: newSelections.length === currentPageData.length,
        indeterminate: newSelections.length > 0 && newSelections.length !== currentPageData.length,
      });
      handleSelectedRows && handleSelectedRows(newSelections);
      enableStateManagement && props.modifyData
        ? props.modifyData(_.cloneDeep(currentPageData))
        : setCurrentPageData(currentPageData);

      if (onCheckedRows) {
        onCheckedRows(newSelections, event.target.checked, rowId);
      }
    } else removeRowSelection(selection, rowId);
  };

  const handleEntireSelection = event => {
    setEntireSelection({ checked: event.target.checked, indeterminate: false });
    const clonedData = _.cloneDeep(currentPageData);
    if (event.target.checked) {
      setSelectedRows(clonedData);
      clonedData?.forEach(item => (item['selected'] = true));
      if (props.gridName === GridTag.FacilityClientContactsGrid) {
        dispatch(FacilityContactsActions.setSelectedContacts(clonedData));
      }
    } else {
      setSelectedRows([]);
      clonedData?.forEach(item => (item['selected'] = false));
      if (props.gridName === GridTag.FacilityClientContactsGrid) {
        dispatch(FacilityContactsActions.setSelectedContacts([]));
      }
    }
    enableStateManagement && props.modifyData ? props.modifyData(clonedData) : setCurrentPageData(clonedData);
  };

  const removeRowSelection = (selection, rowId) => {
    const newSelections = selectedRows.filter(
      item => item[rowSelectionKey as string] !== selection[rowSelectionKey as string],
    );
    setEntireSelection({ checked: false, indeterminate: newSelections.length > 0 });
    setSelectedRows(newSelections);
    if (props.gridName === GridTag.FacilityClientContactsGrid) {
      dispatch(FacilityContactsActions.setSelectedContacts(newSelections));
    }
    selectionLimit && newSelections.length > selectionLimit ? setSelectDisabled(true) : setSelectDisabled(false);
    currentPageData.find(item => item[rowSelectionKey as string] === selection[rowSelectionKey as string])['selected'] =
      false;
    enableStateManagement && props.modifyData
      ? props.modifyData(_.cloneDeep(currentPageData))
      : setCurrentPageData(currentPageData);
    handleSelectedRows && handleSelectedRows(newSelections);
    onCheckedRows?.(newSelections, false, rowId);
  };

  const clearAllSelections = () => {
    setEntireSelection({ checked: false, indeterminate: false });
    setSelectedRows([]);
    const currentPage: any = currentPageData;
    currentPage.map(item => (item['selected'] = false));
    setSelectDisabled(false);
    enableStateManagement && props.modifyData
      ? props.modifyData(_.cloneDeep(currentPageData))
      : setCurrentPageData(currentPageData);
  };
  //#endregion Row Selection
  const handleClick = (event, key, rowID) => {
    setEditRowKey(rowID);
    setAlertRowID(rowID);
  };
  //#endregion Row Selection

  //#region Row Search
  const createRefs = (data, returnIndex?) => {
    const tmpRefList: any[] = [];
    let returnRef;
    for (let i = 0; i < data.length; i++) {
      const ref = React.createRef();
      tmpRefList.push(ref);
      if (i === returnIndex) returnRef = ref;
    }
    setRefList(tmpRefList);
    return returnRef;
  };

  /**
   * Perform a search on the grid using a unique identifier in the row.
   * rowSearch contains your unique key to look for, and the value of that key
   * E.g., `{ key: 'rowID', value: 123 }`
   */
  const gridSearch = useCallback(
    sortColumn => {
      if (rowSearch && columns && columns.length > 0) {
        let sortedData = rows;
        let found = false;
        if (sortColumn !== null) {
          if (columns[sortColumn]?.sortByField)
            sortedData = orderBy(rows, columns[sortColumn].sortByField, sortDirection);
          else sortedData = orderBy(rows, columns[sortColumn].title, sortDirection);
        }

        let page = 1;
        for (let i = 0; i < sortedData.length; i += itemsPerPage) {
          const newCurrentPageData = sortedData.slice(i, i + itemsPerPage);

          let foundSearchIndex = -1;
          let foundSearchSubIndex = -1;
          for (let i = 0; i < newCurrentPageData.length; i++) {
            if (`${newCurrentPageData[i].rowID}` === `${rowSearch.value}`) {
              foundSearchIndex = i;
              break;
            } else if (newCurrentPageData[i].versionRows) {
              for (let j = 0; j < newCurrentPageData[i].versionRows.length; j++) {
                if (`${newCurrentPageData[i].versionRows[j].rowID}` === `${rowSearch.value}`) {
                  foundSearchIndex = i;
                  foundSearchSubIndex = j;
                  break;
                }
              }
            }
          }

          if (foundSearchIndex !== -1 || foundSearchSubIndex !== -1) {
            found = true;
            for (let j = 0; j < newCurrentPageData.length; j++) {
              newCurrentPageData[j].expanded = false;
              newCurrentPageData[j].isEditing = false;
              newCurrentPageData[j].viewVersions = false;
            }
            setRowData(
              rowData.map(item => ({
                ...item,
                expanded: false,
              })),
            );
            newCurrentPageData[foundSearchIndex].expanded = newCurrentPageData[foundSearchIndex].detailRows?.length > 0;
            if (foundSearchSubIndex !== -1) {
              newCurrentPageData[foundSearchIndex].viewVersions = true;
              setSearchFoundIndex(newCurrentPageData[foundSearchIndex].versionRows[foundSearchSubIndex].rowID);
            } else {
              setSearchFoundIndex(newCurrentPageData[foundSearchIndex].rowID);
            }
            const ref = createRefs(newCurrentPageData, foundSearchIndex - i);
            setSearchHighlight(true);
            setCurrentPage(page);
            enableStateManagement && dispatch(gridStateActions.setPageNumber(page));
            setCurrentPageData(newCurrentPageData);
            setTimeout(() => {
              ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
              setTimeout(() => {
                setSearchHighlight(false);
                setSearchFoundIndex(null);
                onSearchComplete?.();
              }, 3000);
            }, 500);
            if (rowSearch.isEditing !== undefined && rowSearch.isEditing === true) {
              if (foundSearchSubIndex !== -1) {
                editRow(
                  newCurrentPageData[foundSearchIndex].versionRows[foundSearchSubIndex].rowID,
                  newCurrentPageData,
                );
              } else {
                editRow(newCurrentPageData[foundSearchIndex].rowID, newCurrentPageData);
              }
            }

            break;
          }
          page++;
        }
        if (!found) onSearchComplete?.();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemsPerPage, rowSearch, rows],
  );
  //#endregion Row Search

  //#region Generic Alert
  const GenericAlertSubmit = () => {
    setGenericAlertOpen(false);
    setGenericAlertTitle('');
    setGenericAlertContent('');
  };
  //#endregion Regneric Alert

  const onSnackBarClose = () => {
    setDeleteSnackbarOpen(false);
  };

  useEffect(() => {
    gridSearch(columns.map(item => item.defaultSort).indexOf(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSearch, gridSearch]);

  useEffect(() => {
    setSearchString(searchTerm);
    setHasExpandableRow(rows.map(item => item.expandable).indexOf(true) !== -1);
    setItemsPerPage(fixedItemsPerPage || 100);
    if (analytics?.itemsPerPage) {
      trackEvent({
        type: 'event',
        name: `${analytics.tableName}:Items Per Page`,
        properties: { itemsPerPage: fixedItemsPerPage || (defaultPageView ? Number(defaultPageView) : 100) },
      });
    }
    const totalRows = props.totalRecordsCount ?? rows.length;
    // setTotalPages(Math.ceil(totalRows / itemsPerPage));
    setCurrentPage(currentPageNumber || 1);
    enableStateManagement &&
      dispatch(
        gridStateActions.setPageAttributes({ pageSize: fixedItemsPerPage || 100, pageNumber: currentPageNumber || 1 }),
      );
    // Logic to separate from serverSidePagination and Client side pagination
    modifiedData =
      // eslint-disable-next-line react-hooks/exhaustive-deps
      itemsPerPage > 0 && !serverSidePagination
        ? rows.slice(
            enableStateManagement ? (fixedItemsPerPage || 100) * ((currentPageNumber || 1) - 1) : 0,
            enableStateManagement ? itemsPerPage * (currentPageNumber || 1) : itemsPerPage,
          )
        : rows;
    var selectedItems = modifiedData.filter(item => item.selected);
    const selectedRowsX = _.cloneDeep(selectedRows);
    selectedItems?.map(itemX => {
      var existing = selectedRowsX.find(item => item[rowSelectionKey as string] === itemX[rowSelectionKey as string]);
      if (!existing || existing.length <= 0) {
        selectedRowsX.push(itemX);
      }
    });
    setSelectedRows(selectedRowsX);

    const modifiedDataX = _.cloneDeep(modifiedData);
    modifiedDataX?.map(itemX => {
      var existing = selectedRowsX.find(item => item[rowSelectionKey as string] === itemX[rowSelectionKey as string]);
      if (!existing || existing.length <= 0) {
        itemX.selected = false;
      } else {
        itemX.selected = true;
      }
    });

    setCurrentPageData(modifiedDataX);
    setIncludeFilter(checkFilters());
    setRowCount(totalRows);

    const defaultColumnSort = columns.find(item => item.defaultSort);
    if (defaultColumnSort) {
      setColumnToSort(defaultColumnSort['title']);
    }
    setSortIcon(invertIcon[defaultSortDirection]);

    // Sets rowData after sorting if defaultSortedColumn exits
    if (enableStateManagement && defaultSortedColumn && defaultSortDirection && columns.length) {
      serverSideSorting
        ? forceSortingAttributes(defaultSortedColumn, defaultSortDirection)
        : handleSort(defaultSortedColumn, defaultSortDirection, rows, false);
    } else setRowData(rows);

    createRefs(modifiedData);
    if (rowSearch) {
      gridSearch(columns.map(item => item.defaultSort).indexOf(true));
    }
    if (initialEditingIndex !== null && initialEditingIndex !== undefined) {
      setTimerPop(false);
      setEditingIndex(initialEditingIndex);
    }
  }, [rows, props.totalRecordsCount]);

  useEffect(() => {
    setRowCount(totalRecordsCount ?? rows.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSnackbarOpen]);

  useEffect(() => {
    setStickyHead(props.stickyTable ? true : false);
    setPaginationCountCheck(props.footerVisibility ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.stickyTable, props.footerVisibility, itemsPerPage]);

  const restrictRolePermissions = columnMetaData => {
    return (
      Authorized(
        [userRoles.recruitment, userRoles.recruitment_Leadership, userRoles.recruitment_TeamMember],
        user?.userInfo,
      ) &&
      !isCredentialing(user?.userInfo?.roles || []) &&
      columnMetaData == 'verifiedID'
    );
  };

  return (
    <div className={classes.root}>
      {enableSelectionDrawer && (
        <CompareDrawer
          open={selectedRows.length > 0}
          data={selectedRows}
          removeData={removeRowSelection}
          clearAll={clearAllSelections}
          itemTag="orderId"
        />
      )}
      <Container disableGutters={disableGutters} className={classes.gridContainer} maxWidth="xl">
        {/* Adding Title bar action button on Top right corner of grid  */}
        <div style={{ float: 'right' }}>{titleActions}</div>
        {searchTerm && (
          <Typography variant="h6" className={disableGutters ? classes.titleNoMargin : classes.title}>
            {component + t(' ')}
            <span>
              {searchString.length > 0 ? (
                <span>
                  {t('/')}
                  <span className={classes.searchTerm}>{searchString}</span>
                </span>
              ) : (
                ''
              )}
            </span>
          </Typography>
        )}
        <Paper className={noPadding ? classes.paperNoPadding : disableGutters ? classes.paperNoMargin : classes.paper}>
          <span>
            {includeFilter && <CustomFilter columns={columns} rows={rows} onChange={handleCustomFilter} />}
            {includeFilter && <Divider className={classes.divider}></Divider>}
            {CustomizedFilter && CustomizedFilter}
          </span>
          {showCount ? (
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', flexGrow: 1, margin: 'auto' }}>
                <Typography className={resultTitleClass || ''} variant="subtitle1">
                  {`${resultsTitle} ${
                    /* added regex to denote american number notation */
                    !showTitleWithoutCount
                      ? `(${formatToAmericanNotaion(
                          selectedRows.length > 0 ? selectedRows.length : totalRecordsCountDisplay ?? rowCount,
                        )})`
                      : ''
                  }`}
                  {secondaryTitle ? secondaryTitle : ''}
                  {thirdTitle ? thirdTitle : ''}
                </Typography>
              </div>
              {createMessageButton && (
                <CustomTooltip
                  arrow
                  tooltipContent={selectedContacts.length > MAX_ALLOWED_EMAIL_SELECTION ? CREATE_MESSAGE_WARNING : ``}
                >
                  <span>
                    <Button
                      variant="contained"
                      disabled={
                        !enableEmailCommunication ||
                        selectedContacts.length < 1 ||
                        selectedContacts.length > MAX_ALLOWED_EMAIL_SELECTION
                      }
                      color="primary"
                      onClick={handleCreateMessageOnlick}
                    >
                      {createMessageButton}
                    </Button>
                  </span>
                </CustomTooltip>
              )}
              {createClientContactButtonIcon && (
                <div style={{ margin: 'auto 0px auto 12px' }}>
                  <CustomTooltip arrow tooltipContent={t('clientContact.createClientContact')}>
                    <span>
                      <IconButton
                        color="primary"
                        onClick={handleCreateClientContactOnlick}
                        style={{ padding: '0px' }}
                        size="small"
                      >
                        {createClientContactButtonIcon}
                      </IconButton>
                    </span>
                  </CustomTooltip>
                </div>
              )}
              {hasCloseButton && (
                <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
                  <IconButton color="primary" size="small" onClick={_onGridClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
              )}
            </div>
          ) : null}
          <TableContainer component={Paper} className={`${stickyHead ? classes.containerSticky : classes.container}`}>
            <form
              onSubmit={data => {
                data.preventDefault();
                handleSaveInitiation();
              }}
            >
              <Table
                stickyHeader={props.footerVisibility ? paginationCountCheck : stickyTable}
                className={classes.tableContainer}
              >
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    {rowSelectionKey ? (
                      <TableCell
                        className={`${classes.tableHeader} ${classes.expandedTableCell} ${classes.checkboxCell}`}
                      >
                        {enableTotalRecordsSelection ? (
                          <Checkbox
                            checked={entireSelection.checked}
                            indeterminate={entireSelection.indeterminate}
                            disableFocusRipple
                            disableTouchRipple
                            style={{ background: 'none', padding: '0px' }}
                            onChange={handleEntireSelection}
                          />
                        ) : (
                          t('Select')
                        )}
                      </TableCell>
                    ) : null}
                    {starIconAction && (
                      <TableCell className={`${classes.tableHeader}`}>
                        {starIconAction && starIconAction.title !== undefined ? starIconAction.title : null}
                      </TableCell>
                    )}
                    {hasExpandableRow ? (
                      <TableCell
                        className={`${classes.tableHeader} ${classes.expandedTableCell} ${
                          rowExpand ? classes.staticHeaderCell : ''
                        }`}
                      >
                        {rowExpand ? `Status` : null}
                      </TableCell>
                    ) : (
                      rowExpand && (
                        <TableCell
                          className={`${classes.tableHeader} ${classes.expandedTableCell} ${
                            rowExpand ? classes.staticHeaderCell : ''
                          }`}
                        >
                          {rowExpand ? `Status` : null}
                        </TableCell>
                      )
                    )}
                    {columns.map((header, key) => (
                      <React.Fragment key={key}>
                        {/* {staticFirstColumn && key === 1 && (
                          <TableCell className={`${classes.staticSecondHeaderCell}`} />
                        )} */}
                        {!header.hidden && (
                          <TableCell
                            className={`${classes.tableHeader} ${
                              staticFirstColumn && key === 0 ? classes.staticHeaderCell : ''
                            }`}
                            onClick={() =>
                              header['sortable'] &&
                              (serverSideSorting ? handleServerSort(header['title']) : handleSort(header['title']))
                            }
                            colSpan={header?.colSpan || 0}
                          >
                            {header.columnHeaderHoverView ? (
                              <CustomTooltip tooltipContent={header.columnHeaderHoverText}>
                                <Grid container>
                                  <Grid item sm={12} className={classes.headerStyle}>
                                    <div>{header.value}</div>
                                    <span className={classes.arrowPosition}>
                                      {header['sortable'] && (
                                        <span
                                          key={key}
                                          className={`${classes.tableHeaderCell} ${
                                            (columnToSort !== null && columnToSort !== header['title']) ||
                                            (columnToSort === null && !header['defaultSort'])
                                              ? classes.tableHeaderCellHidden
                                              : ''
                                          }`}
                                        >
                                          {sortIcon && columnToSort === header['title'] && columnToSort !== null ? (
                                            <ArrowDownwardIcon />
                                          ) : (
                                            <ArrowUpwardIcon />
                                          )}
                                        </span>
                                      )}
                                    </span>
                                  </Grid>
                                </Grid>
                              </CustomTooltip>
                            ) : (
                              <Grid container>
                                <Grid item sm={12} className={classes.headerStyle}>
                                  <div>{header.value}</div>
                                  <span className={classes.arrowPosition}>
                                    {header.headerIcon && (header.headerIcon.redAlert || header.headerIcon.infoIcon) ? (
                                      <CustomTooltip tooltipContent={header.columnHeaderHoverText}>
                                        {header.headerIcon.redAlert ? (
                                          <img src={ErrorIcon} className={classes.errorIcon} />
                                        ) : header.headerIcon.infoIcon ? (
                                          <InfoOutlinedIcon fontSize="small" color="disabled" />
                                        ) : null}
                                      </CustomTooltip>
                                    ) : (
                                      header['sortable'] && (
                                        <span
                                          key={key}
                                          className={`${classes.tableHeaderCell} ${
                                            (columnToSort !== null && columnToSort !== header['title']) ||
                                            (columnToSort === null && !header['defaultSort'])
                                              ? classes.tableHeaderCellHidden
                                              : ''
                                          }`}
                                        >
                                          {sortIcon && columnToSort === header['title'] && columnToSort !== null ? (
                                            <ArrowDownwardIcon />
                                          ) : (
                                            <ArrowUpwardIcon />
                                          )}
                                        </span>
                                      )
                                    )}
                                  </span>
                                </Grid>
                              </Grid>
                            )}
                          </TableCell>
                        )}
                      </React.Fragment>
                    ))}
                    {rowIconAction && (
                      <TableCell className={`${classes.tableHeader} ${classes.kababMenu}`}>
                        {rowIconAction && rowIconAction.title !== undefined ? rowIconAction.title : null}
                      </TableCell>
                    )}
                    {hasEditMenu && <TableCell className={`${classes.tableHeader} ${classes.kababMenu}`} />}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentPageData.map((row, rowKey) => (
                    <React.Fragment key={rowKey}>
                      <GridTableRow
                        row={row}
                        columns={columns}
                        rowKey={rowKey}
                        refList={refList}
                        editingIndex={editingIndex}
                        searchHighlight={searchHighlight}
                        handleRowSelect={handleRowSelect}
                        selectDisabled={selectDisabled}
                        searchFoundIndex={searchFoundIndex}
                        disableRowColors={disableRowColors}
                        rowSelectionKey={rowSelectionKey}
                        hasExpandableRow={hasExpandableRow}
                        staticFirstColumn={staticFirstColumn}
                        highlightedRow={highlightedRow}
                        component={component}
                        _onNavigation={_onNavigation}
                        onElementClick={columnKey => onElementClick?.(rowKey, columnKey, row)}
                        onStateNavigation={onStateNavigation}
                        _onValueChanged={_onValueChanged}
                        expandRow={expandRow}
                        centerHoverView={centerHoverView}
                        gridName={props.gridName}
                        onVersionView={onVersionView}
                        rowIconAction={rowIconAction}
                        starIconAction={starIconAction}
                        hasEditMenu={hasEditMenu}
                        rowExpand={rowExpand}
                        hasSingleEditIcon={hasSingleEditIcon}
                        canEdit={canEdit}
                        handlePopupClose={handlePopupClose}
                        setOpenEditNotAvailable={setOpenEditNotAvailable}
                        analytics={analytics}
                        handleClick={handleClick}
                        hasPermissions={hasPermisions}
                        editRow={editRow}
                        editRowKey={editRowKey}
                        _willDelete={_willDelete}
                        classes={classes}
                        expandedSubRow={false}
                        restrictRolePermissions={restrictRolePermissions}
                      />
                      <ExpandedGridTableRow
                        row={row}
                        rowKey={rowKey}
                        columns={columns}
                        isLastRow={rowKey === currentPageData.length - 1}
                        hasEditMenu={hasEditMenu}
                        rowExpand={rowExpand}
                        fullWidth={fullWidth}
                        editingIndex={editingIndex}
                        centerHoverView={centerHoverView}
                        showEditControl={showEditControl}
                        stopEditing={stopEditing}
                        _onValueChanged={_onValueChanged}
                        didEdit={didEdit}
                        disableRowColors={disableRowColors}
                        _onGridClose={_onGridClose}
                        hasExpandableRow={hasExpandableRow}
                        classes={classes}
                        restrictRolePermissions={restrictRolePermissions}
                      />
                      {row.expandable && row.viewVersions && row.versionRows && row.versionColumns && (
                        <React.Fragment>
                          {row.versionRows.map((versionRow, versionKey) => (
                            <GridTableRow
                              row={versionRow}
                              columns={row.versionColumns}
                              rowKey={versionKey}
                              refList={refList}
                              editingIndex={editingIndex}
                              searchHighlight={searchHighlight}
                              handleRowSelect={handleRowSelect}
                              selectDisabled={selectDisabled}
                              searchFoundIndex={searchFoundIndex}
                              disableRowColors={disableRowColors}
                              rowSelectionKey={rowSelectionKey}
                              hasExpandableRow={hasExpandableRow}
                              staticFirstColumn={staticFirstColumn}
                              highlightedRow={highlightedRow}
                              component={component}
                              _onNavigation={_onNavigation}
                              onElementClick={columnKey => onElementClick?.(rowKey, columnKey, row)}
                              onStateNavigation={onStateNavigation}
                              _onValueChanged={_onValueChanged}
                              expandRow={expandRow}
                              centerHoverView={centerHoverView}
                              gridName={props.gridName}
                              onVersionView={onVersionView}
                              rowIconAction={rowIconAction}
                              starIconAction={starIconAction}
                              hasEditMenu={hasEditMenu}
                              rowExpand={rowExpand}
                              hasSingleEditIcon={hasSingleEditIcon}
                              canEdit={canEdit}
                              handlePopupClose={handlePopupClose}
                              setOpenEditNotAvailable={setOpenEditNotAvailable}
                              analytics={analytics}
                              handleClick={handleClick}
                              hasPermissions={hasPermisions}
                              editRow={editRow}
                              editRowKey={editRowKey}
                              _willDelete={_willDelete}
                              classes={classes}
                              expandedSubRow={true}
                              restrictRolePermissions={restrictRolePermissions}
                            />
                          ))}
                        </React.Fragment>
                      )}
                      {row.expandable && row.expanded && row?.exceptionWaiveRows && row?.exceptionWaiveColumns && (
                        <React.Fragment>
                          <TableRow className={`${classes.table} ${classes.tableHead}`}>
                            {row?.exceptionWaiveColumns?.map((header: ICustomHeader, key) => (
                              <React.Fragment key={key}>
                                <TableCell
                                  className={`${key === 0 ? `${classes.staticHeaderCell}` : ''} ${
                                    classes.stickyHeaderRowCell
                                  }`}
                                  colSpan={header?.colSpan || 0}
                                >
                                  {header.value}
                                </TableCell>
                              </React.Fragment>
                            ))}
                          </TableRow>
                        </React.Fragment>
                      )}
                      {row.expandable && row.expanded && row?.exceptionWaiveRows && row?.exceptionWaiveColumns && (
                        <React.Fragment>
                          {row.exceptionWaiveRows.map((exceptionWaiveRow, exceptionWaiveKey) => (
                            <ExceptionWaiveGridTableRow
                              row={exceptionWaiveRow}
                              rowKey={exceptionWaiveKey}
                              columns={row.exceptionWaiveColumns}
                              isLastRow={rowKey === currentPageData.length - 1}
                              hasEditMenu={hasEditMenu}
                              rowExpand={rowExpand}
                              fullWidth={fullWidth}
                              editingIndex={editingIndex}
                              centerHoverView={centerHoverView}
                              showEditControl={showEditControl}
                              stopEditing={stopEditing}
                              _onValueChanged={_onValueChanged}
                              didEdit={didEdit}
                              disableRowColors={disableRowColors}
                              _onGridClose={_onGridClose}
                              hasExpandableRow={hasExpandableRow}
                              classes={classes}
                              searchHighlight={searchHighlight}
                              searchFoundIndex={searchFoundIndex}
                              onStateNavigation={onStateNavigation}
                            />
                          ))}
                        </React.Fragment>
                      )}
                      {row?.isEditing && (
                        <React.Fragment>
                          {/* Session Dialog */}
                          <GenericDialog
                            open={timerPop || false}
                            maxWidth="md"
                            disableEscapeKeyDown
                            dialogTitleProps={{
                              text: t('search.grid.edit.dialogTitleSession'),
                            }}
                            dialogActions={[
                              {
                                text: didEdit
                                  ? t('search.grid.edit.cancelTextSession')
                                  : t('search.grid.edit.cancelTextWithoutEditSession'),
                                variant: 'contained',
                                color: 'tertiary',
                                onClick: () => stopEditing(rowKey, false),
                              },
                              {
                                text: addingCredential
                                  ? t('search.grid.add.submitTextSession')
                                  : t('search.grid.edit.submitTextSession'),
                                variant: 'contained',
                                onClick: handleTimerInitiation,
                              },
                            ]}
                          >
                            {dialogContentRender()}
                          </GenericDialog>
                          {/* Edit Cancel Dialog */}
                          <GenericDialog
                            open={dialogPop || false}
                            maxWidth="sm"
                            disableEscapeKeyDown
                            dialogTitleProps={{
                              text: t('cancelModal.undoConfirmation'),
                              classes: { root: classes.titleCancel },
                            }}
                            dialogActions={[
                              {
                                text: t('cancelModal.confirmation.cancel'),
                                variant: 'text',
                                color: 'tertiary',
                                onClick: () => setDialogPop(false),
                              },
                              {
                                text: t('cancelModal.confirmation.yes'),
                                variant: 'contained',
                                onClick: () => stopEditing(rowKey, false),
                                disabled: !didEdit,
                              },
                            ]}
                            dialogContentProps={{
                              classes: { root: classes.dialogCancel },
                            }}
                          >
                            {t('cancelModal.saveConfirmation')}
                          </GenericDialog>
                          {/* Generic alert */}
                          <GenericDialog
                            open={genericAlertOpen || false}
                            maxWidth="md"
                            disableEscapeKeyDown
                            dialogTitleProps={{
                              text: genericAlertTitle,
                            }}
                            dialogActions={[
                              {
                                text: t('search.grid.edit.genericAlertOkButton'),
                                variant: 'contained',
                                onClick: GenericAlertSubmit,
                                disabled: !didEdit,
                              },
                            ]}
                            dialogContentProps={{
                              variant: 'text',
                            }}
                          >
                            {genericAlertContent}
                          </GenericDialog>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </form>
            {/* Edit not allowed dialog */}
            <GenericDialog
              open={openEditNotAvailable || false}
              maxWidth="md"
              disableEscapeKeyDown
              dialogTitleProps={{
                text: t('search.grid.edit.editNotAvailableDialogTitle'),
              }}
              dialogActions={[
                {
                  text: t('search.grid.edit.editNotAvailableDialogTextOk'),
                  variant: 'contained',
                  onClick: () => {
                    setOpenEditNotAvailable(false);
                  },
                },
              ]}
              dialogContentProps={{
                variant: 'text',
              }}
            >
              {t('search.grid.edit.editNotAvailableDialogContent')}
            </GenericDialog>
            {/* Delete Dialog */}
            <GenericDialog
              open={deleteDialogOpen || false}
              maxWidth="md"
              disableEscapeKeyDown
              dialogTitleProps={{
                text: deleteDialogTitle || '',
              }}
              dialogActions={[
                {
                  text: deletableRecord ? t('search.grid.delete.dialogCancelButton') : '',
                  variant: 'contained',
                  color: 'tertiary',
                  onClick: () => setDeleteDialogOpen(false),
                },
                {
                  text: deletableRecord
                    ? t('search.grid.delete.dialogDeleteButton')
                    : t('search.grid.delete.dialogOkButton'),
                  variant: 'contained',
                  onClick: () => (deletableRecord ? handleDelete() : setDeleteDialogOpen(false)),
                },
              ]}
              dialogContentProps={{
                variant: 'text',
              }}
            >
              {deleteDialogContent}
            </GenericDialog>
          </TableContainer>

          {rows.length === 0 && (
            <Grid container className={classes.footer}>
              <Grid item xs={12} className={classes.footerNoData}>
                {t('search.grid.noRecords')}
              </Grid>
            </Grid>
          )}
          {showPagination && rows.length !== 0 && (
            <Grid container spacing={0} className={classes.footer}>
              <Grid item xs={12}>
                {/* TODO: MUI 5 - @Aston Martin, Review this new pagination as the component implementation has changed */}
                <Pagination
                  pagination={{
                    page: currentPage,
                    resultsPerPage: itemsPerPage || parseInt(defaultNumberOfRecords || '0'),
                    totalResults: props.totalRecordsCount || rowData.length,
                  }}
                  pageOptions={viewOptions ?? [10, 20, 30, 50]}
                  hideResultsPerPageSelection={!showViewOptions}
                  hidePageCount={!showActivePage}
                  isLoading={false}
                  handlePageChange={handleDataChange}
                  handleResultsPerPageChange={handleTotalPages}
                />
                {/* <Pagination
                  count={totalPages}
                  color="secondary"
                  page={currentPage}
                  onChange={handleDataChange}
                  className={classes.pagination}
                  size="small"
                /> */}
              </Grid>
              {/* {showViewOptions && (
                <Grid item sm={2} className={classes.footerGrid}>
                  <React.Fragment>
                    <Select
                      color="primary"
                      id="default"
                      labelId="default-label"
                      defaultValue={defaultNumberOfRecords}
                      className={classes.footerPageSelector}
                      onChange={handleTotalPages}
                      disableUnderline={true}
                      MenuProps={{ classes: { list: classes.pageOptionList } }}
                    >
                      {viewOptions.map((item, key) => (
                        <option value={item} key={key}>
                          {item}
                        </option>
                      ))}
                    </Select>
                    <Typography className={classes.paginationItemSelect} variant="body2">
                      {`Items per page`}
                    </Typography>
                  </React.Fragment>
                </Grid>
              )}
              {showActivePage && (
                <Grid item sm={showViewOptions ? 5 : 6} className={classes.footerPageGrid}>
                  <Typography variant="body2">
                    {currentPage} {`of`} {totalPages} {`page(s)`}
                  </Typography>
                </Grid>
              )} */}
            </Grid>
          )}
        </Paper>
      </Container>
      <Backdrop className={classes.backdrop} open={postingEdit || showPageLoad}>
        <CircularProgress />
      </Backdrop>
    </div>
  );
};
