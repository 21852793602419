import { Box, Grid } from 'amn-ui-core';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { CallPurpose, ChannelType, FormName, UseSubType, UseType } from '@AMIEWEB/Notification/Constants';
import SmsEnabledIcon from 'app/assets/images/Communications/SMS Enabled.svg';
import SmsDisabledIcon from 'app/assets/images/Communications/SMS Disabled.svg';
import CallEnabledIcon from 'app/assets/images/Communications/Call Enabled.svg';
import CallDisabledIcon from 'app/assets/images/Communications/Call Disabled.svg';
import { CapitalizeEveryFirstLetter, Concatenate } from 'utils/string/string';
import { ContactProps, IEmployee } from 'app/models/Notification/Notification';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { selectFacility } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { IFacilityClientContactsList } from 'app/models/Facility/FacilityDetails';
import { getDefaultSender } from '@AMIEWEB/Candidate/CandidateProfile/CandidateHeader/NotificationHelper';

const useStyles = makeStyles()((theme) => ({
  phoneType: {
    width: 100,
    paddingLeft: 24,
  },
  phoneNumber: {
    width: 150,
    fontSize: 'small',
    display: 'flex',
    color: theme.palette.framework.system.navyBlue,
  },
  phoneNumberDisabled: {
    width: 150,
    fontSize: 'small',
    display: 'flex',
    color: theme.palette.framework.system.charcoal,
    cursor: 'default',
  },
  phoneNumberHover: {
    width: 150,
    fontSize: 'small',
    textDecoration: 'underline',
    color: theme.palette.framework.system.navyBlue,
    display: 'flex',
  },
  phoneDescription: {
    margin: '6px 0px',
    fontWeight: 500,
    fontSize: 12,
  },
  wrapper: {
    marginTop: 10,
    minWidth: 255,
    maxWidth: 300,
  },
  callNumber: {
    margin: '6px 2px',
    fontSize: 14,
    fontWeight: 400,
    textWrap: 'nowrap',
    cursor: 'pointer',
  },
  callNumberDisabled: {
    margin: '6px 2px',
    fontSize: 14,
    fontWeight: 400,
    textWrap: 'nowrap',
    cursor: 'default',
  },
  warningMessage: {
    color: theme.palette.framework.system.red,
    textAlign: 'left',
  },
  warningMessageContainer: {
    margin: '0px 26px',
  },
}));

interface ICommunicationStackProps {
  channelType: ChannelType;
  phoneNumbers: Array<any>;
  backDrop?: any;
  contactDetails?: IFacilityClientContactsList;
}
const ClientContactCommunicationStack = (props: ICommunicationStackProps) => {
  const { channelType, phoneNumbers, backDrop, contactDetails } = props;
  const { classes } = useStyles();

  const CommunicationItems = item => {
    const enabledIcon = channelType === ChannelType.voice ? CallEnabledIcon : SmsEnabledIcon;
    const disabledIcon = channelType === ChannelType.voice ? CallDisabledIcon : SmsDisabledIcon;
    const [hover, setHover] = React.useState<boolean>(false);
    const facilitySelector = useSelector(selectFacility);
    const facilityResponse = facilitySelector?.facilityResponse;
    const notificationGlobalData = useSelector(notificationSelection);
    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    const initiateCommunication = (channelType: ChannelType, candidatePhoneNumber: string) => {
      let data = {};
      const sender: IEmployee = getDefaultSender(user);
      const candidate: ContactProps = {
        name: contactDetails?.name,
        email: contactDetails?.email,
        phoneNumber: candidatePhoneNumber,
        contactId: contactDetails?.contactId,
        contactType: 'facility',
        clientContactDetails: {
          statusId: contactDetails?.statusId?.toString(),
          unitIds: [],
        },
      };

      switch (channelType) {
        case ChannelType.voice:
          data = {
            manualLog: false,
            channel: ChannelType.voice,
            associatedRecords: [
              { name: 'facilityId', value: facilityResponse?.facilityId?.toString() },
              { name: 'facilityName', value: facilityResponse?.facilityName?.toString() },
              { name: 'clientContactId', value: contactDetails?.contactId?.toString() },
            ],
            sender: sender,
            tos: [candidate],
            useType: UseType.ClientContacts,
            UseSubType: UseSubType.ClientContactGrid,
            userRole: Concatenate(user?.userInfo?.roles || [], ','),
            formName: FormName[FormName.Contact],
            reason: CallPurpose[CallPurpose.CustomerService],
          };
          if (!notificationGlobalData.voice.open && notificationGlobalData.voice.minimized) {
            dispatch(
              notificationDataActions.setVoiceInteraction({
                open: true,
                minimized: false,
                showDisposition: true,
              }),
            );
            if (notificationGlobalData.voice.data?.tos?.find(Boolean).contactId !== candidate?.contactId) {
              dispatch(
                notificationDataActions.setSnackBarData({ channel: 'voice', manual: false, changeWarning: true }),
              );
            }
          } else {
            dispatch(
              notificationDataActions.setVoiceData({
                data: data,
                open: true,
                minimized: false,
              }),
            );
          }
          break;
      }
    };

    return (
      <Grid direction={'row'} xs={12}>
        <Grid style={{ display: 'flex', justifyContent: 'space-around', margin: '0px 0' }} data-testid="phone-element">
          <Box className={classes.phoneType}>
            <p className={classes.phoneDescription} data-testid="phone-type">
              {CapitalizeEveryFirstLetter(item?.phoneType || '')}
            </p>
          </Box>
          <Box
            className={hover ? classes.phoneNumberHover : classes.phoneNumber}
            onClick={() => {
              backDrop && backDrop(true);
              initiateCommunication(channelType, item?.phoneNumber);
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            data-testid="communication-handler-wrapper"
          >
            <img src={hover ? enabledIcon : disabledIcon} style={{ width: 25, margin: '5px 8px' }} />
            <p className={classes.callNumber} data-testid="phone-number">
              {item?.phoneNumber || ''}
            </p>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box className={classes.wrapper} data-testid="phone-number-wrapper">
      <Grid container spacing={2}>
        {phoneNumbers?.map(ele => (
          <CommunicationItems {...ele} />
        ))}
      </Grid>
    </Box>
  );
};

export default ClientContactCommunicationStack;
