import { createAction, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { INotificationLog } from 'app/models/ActivityFeed/IActivityLogs';
import { IGetActivityFeedQuery } from 'app/models/ActivityFeed/IGetActivityFeed';
import { ITaskComment } from 'app/models/Tasks/Comments';
import {
  IGridData,
  IGridPaging,
  ITask,
  ITaskComments,
  ITaskDetails,
  ITaskGridBody,
  ITaskStatusCheck,
  IGroupedList,
  TaskEntity,
  IExternalTask,
  ICueRecipient,
  ICueNoteResponse,
  ICueNote,
  ICueNoteParent,
  ICueNoteContent,
  IEntityId,
  ICueListForPlacement,
  IExternalTaskSubType,
  ITaskStickChosen,
  IStatusModal,
  IFormatedExternalTaskData,
  IFormatedInternalTaskData,
  IInternalTaskModalDetails,
  IExternalTaskModalDetails,
  IEmailDeliveryAssociateRecords,
  StatusUpdateModalVersion,
  IDueDateView,
  NotificationCategory,
  IEditSubTask,
} from 'app/models/Tasks/Tasks';
import { businessActions } from './business-actions';
import _ from 'lodash';
import { getNotificationCount } from '@AMIEWEB/Notification/Tasks/Common_v2/utils';

const getTaskAction = createAction<number>('GET_TASK_DETAILS');

const getTaskCommentsAction = createAction<number>('GET_TASK_COMMENTS');

const saveSubTaskAction = createAction<ITaskComments>('SAVE_TASK_COMMENT');

const editSubTaskAction = createAction<IEditSubTask>('EDIT_TASK_COMMENT');

const saveTaskAction = createAction<ITask>('SAVE_TASK');

const saveExternalTaskAction = createAction<IExternalTask>('SAVE_EXTERNAL_TASK');

const saveCueNoteAction = createAction<ICueNote>('SAVE_CUE_NOTE');

const editTaskAction = createAction<any>('EDIT_TASK');

const saveStatusTaskAction = createAction<any>('UPDATE_STATUS_TASK');

const updateSubtaskStatusAction = createAction<any>('UPDATE_SUBTASK_STATUS');

const taskGridDataAction = createAction<ITaskGridBody>('GET_TASK_GRID_LIST');

const getInternalTaskListAction = createAction<any>('GET_INTERNAL_TASK_LIST');

const getTaskGroupedListAction = createAction<any>('GET_TASK_GROUPED_LIST');

const getCategoryListAction = createAction('GET_CATEGORY_LIST');

const getExternalCategoryListAction = createAction('GET_EXTERNAL_CATEGORY_LIST');

const sendTaskCreatedNotification = createAction<any>('SEND_TASK_CREATED_NOTIFICATION');

const sendSubTaskCreatedNotification = createAction<any>('SEND_COMMENT_CREATED_NOTIFICATION');

const sendTaskUpdatedNotification = createAction<any>('SEND_TASK_UPDATED_NOTIFICATION');

const sendCueNoteCreatedNotification = createAction<any>('SEND_CUE_CREATED_NOTIFICATION');

const sendNotificationReadStatus = createAction<any>('SEND_NOTIFICATION_READ_STATUS');

const getUnReadNotifications = createAction<any>('GET_UNREAD_NOTIFICATIONS');

const getUnReadNotificationsCounts = createAction<any>('GET_UNREAD_NOTIFICATIONS_COUNTS');

const deleteAttachments = createAction<any>('DELETE_ATTACHMENTS');

const getExternalTaskList = createAction<any>('GET_EXTERNAL_TASKS');

const updateExternalTaskStatus = createAction<any>('UPDATE_EXTERNAL_TASK_STATUS');

const getExternalTaskDetails = createAction<any>('GET_EXTERNAL_TASK_DETAILS');

const getCueNoteRecipient = createAction<any>('GET_CUE_NOTE_RECIPIENT');

const getCueNotesForPlacements = createAction<ICueListForPlacement>('GET_CUE_NOTES_LIST');

const sendUpdateExternalTask = createAction<any>('SEND_UPDATE_EXTERNAL_TASK');

const getNotificationLogData = createAction<IGetActivityFeedQuery>('GET_NOTIFICATION_LOG_DATA');

const getEmailDeliveryAssociateRecords = createAction<IEmailDeliveryAssociateRecords>(
  'GET_EMAIL_DELIVERY_ASSOCIATE_RECORDS',
);

const getExternalSubCategoryList = createAction<number>('GET_EXTERNAL_TASK_SUB_CATEGORY');

const saveTaskCommentAction = createAction<ITaskComments>('SAVE_TASK_DETAIL_COMMENT');

export const initialState: ITaskDetails = {
  newTask: undefined,
  newId: undefined,
  externalTaskFlagChanged: undefined,
  internalTaskFlagChanged: undefined,
  internalTaskDetailsData: undefined,
  externalTaskDetailsData: undefined,
  commentList: [],
  newComment: {},
  taskStatusUpdateFlag: undefined,
  taskStatusUpdateModalId: undefined,
  showTaskStatusModal: undefined,
  currentContainer: undefined,
  internalTaskListData: undefined,
  externalTaskListData: undefined,
  updatedId: undefined,
  updatedData: undefined,
  taskEntity: TaskEntity.ALL,
  groupedList: {
    paging: undefined,
    items: [],
  },
  taskGridData: {
    paging: undefined,
    items: [],
  },
  categoryList: [],
  errors: {
    GET_TASK_DETAILS: false,
    GET_TASK_COMMENTS: false,
    SAVE_TASK_COMMENT: false,
    SAVE_TASK: false,
    EDIT_TASK: false,
    GET_TASK_GRID_FILTERS: false,
  },
  createdTaskData: undefined,
  taskStatusData: undefined,
  notificationContainer: undefined,
  statusContainer: undefined,
  notificationPrimaryContext: undefined,
  unreadNotificationLog: undefined,
  placementBrandId: undefined,
  newTaskAttachments: undefined,
  existingAttachments: undefined,
  createdComment: false,
  isAttachmentDeleted: undefined,
  companyId: undefined,
  externalTaskId: undefined,
  externalTaskCreatedData: undefined,
  externalCategoryList: [],
  externalTaskList: undefined,
  isExternal: false,
  currentExternalTask: undefined,
  taskStickChosen: { entity: 0, stick: null },
  cueRecipientList: [],
  cueNotesList: [],
  cueNoteId: undefined,
  createdCueNoteData: undefined,
  lastRecipientDetails: undefined,
  checkPanelChange: false,
  sidePanelChoosen: false,
  isAMIEWebRequest: false,
  isExternalTaskUpdated: false,
  cueNoteReplyParent: undefined,
  isCueNoteCreated: false,
  cueCreatedData: undefined,
  notificationLogData: [],
  entityId: undefined,
  isUserCueTo: false,
  currentUserCued: false,
  activityFeedFilterList: [],
  cueListforPlacementOptions: {} as ICueListForPlacement,
  subCategory1: undefined,
  subCategory2: undefined,
  internalTaskdata: undefined,
  externalTaskdata: undefined,
  isDetailsModelOpen: false,
  emailDeliveryAssociateRecors: [],
  versionUpdated: null,
  isStatusAppliedAll: false,
  subTaskName: false,
  dueDateFirstView: [],
  isUpdatingTask: false,
  isFilterApplied: false,
  notifications: {
    unreadTaskNotifications: 0,
    unreadOrderMatchNotifications: 0,
    unreadAlertNotifications: 0,
  },
  dismissNotificationId: null,
  requestedAlertCategory: '',
};

export const taskDetailsSlice = createSlice({
  name: 'taskDetails',
  initialState,
  reducers: {
    setTaskDataId(state, action: PayloadAction<number | undefined>) {
      state.newId = action.payload || undefined;
    },
    setExternalTaskDataId(state, action: PayloadAction<string | undefined>) {
      state.externalTaskId = action.payload || undefined;
    },
    setInternalTaskChangedFlag(state, action: PayloadAction<IFormatedInternalTaskData | undefined>) {
      state.internalTaskFlagChanged = action.payload || undefined;
    },
    setExternalTaskChangedFlag(state, action: PayloadAction<IFormatedExternalTaskData | undefined>) {
      state.externalTaskFlagChanged = action.payload || undefined;
    },
    setInternalTaskDetailsModal(state, action: PayloadAction<IInternalTaskModalDetails | undefined>) {
      state.internalTaskDetailsData = action.payload || undefined;
    },
    setExternalTaskDetailsModal(state, action: PayloadAction<IExternalTaskModalDetails | undefined>) {
      state.externalTaskDetailsData = action.payload || undefined;
    },
    setTaskComments(state, action: PayloadAction<ITaskComments[] | undefined>) {
      state.commentList = action.payload || undefined;
    },
    prependNewComments(state, action: PayloadAction<ITaskComments>) {
      state.commentList = [action.payload, ...(state.commentList || [])];
    },
    setCurrentComments(state, action: PayloadAction<ITaskComments | undefined>) {
      state.newComment = action.payload || undefined;
    },
    setTaskStatusUpdateFlag(state, action: PayloadAction<string | undefined>) {
      state.taskStatusUpdateFlag = action.payload || undefined;
    },
    setTaskStatusUpdateModalId(state, action: PayloadAction<ITaskStatusCheck | undefined>) {
      state.taskStatusUpdateModalId = action.payload || undefined;
    },
    setShowTaskStatusModal(state, action: PayloadAction<IStatusModal | undefined>) {
      state.showTaskStatusModal = action.payload || undefined;
    },
    setCurrentContainer(state, action: PayloadAction<number | undefined>) {
      state.currentContainer = action.payload || undefined;
    },
    setVersionUpdated(state, action: PayloadAction<StatusUpdateModalVersion | null>) {
      state.versionUpdated = action.payload || null;
    },
    setDetailsModalOpen(state, action: PayloadAction<boolean>) {
      state.isDetailsModelOpen = action.payload;
    },
    setExternalTaskListData(state, action: PayloadAction<IFormatedExternalTaskData[] | undefined>) {
      state.externalTaskListData = action.payload || undefined;
    },
    setInternalTaskListData(state, action: PayloadAction<IFormatedInternalTaskData[] | undefined>) {
      state.internalTaskListData = action.payload || undefined;
    },
    setErrorObject(state, action: PayloadAction<any | undefined>) {
      state.errors = { ...state.errors, ...action.payload } || undefined;
    },
    setTaskUpdatedId(state, action: PayloadAction<number | undefined>) {
      state.updatedId = action.payload || undefined;
    },
    setEntityId(state, action: PayloadAction<IEntityId | undefined>) {
      state.entityId = action.payload || undefined;
    },
    setTaskUpdatedData(state, action: PayloadAction<ITask | undefined>) {
      state.updatedData = action.payload || undefined;
    },
    setTaskEntity(state, action: PayloadAction<number>) {
      state.taskEntity = action.payload || 0;
    },
    setTaskGroupedList(state, action: PayloadAction<IGroupedList>) {
      state.groupedList = action.payload || undefined;
    },
    setExternalTaskData(state, action: PayloadAction<IFormatedExternalTaskData>) {
      state.externalTaskdata = action.payload || undefined;
    },
    setInternalTaskData(state, action: PayloadAction<IFormatedInternalTaskData>) {
      state.internalTaskdata = action.payload || undefined;
    },
    setTaskGridData(state, action: PayloadAction<{ paging: IGridPaging; items: IGridData[] } | null>) {
      return {
        ...state,
        taskGridData: {
          paging: action.payload?.paging || undefined,
          items: action.payload?.items || [],
        },
      };
    },
    setCategorizedList(state, action: PayloadAction<any[] | undefined>) {
      state.categoryList = action.payload || undefined;
    },
    setExternalCategorizedList(state, action: PayloadAction<any[] | undefined>) {
      state.externalCategoryList = action.payload || undefined;
    },
    setPlacementBrandId(state, action: PayloadAction<any | undefined>) {
      state.placementBrandId = action.payload || undefined;
    },
    setCreatedTaskData(state, action: PayloadAction<ITask | undefined>) {
      state.createdTaskData = action.payload || undefined;
    },
    setCreatedExternalTaskData(state, action: PayloadAction<IExternalTask | undefined>) {
      state.externalTaskCreatedData = action.payload || undefined;
    },
    setTaskStatusData(state, action: PayloadAction<any | undefined>) {
      state.taskStatusData = action.payload || undefined;
    },
    setTaskStickChosen(state, action: PayloadAction<ITaskStickChosen>) {
      state.taskStickChosen = action.payload;
    },
    setDuedateFirstView(state, action: PayloadAction<IDueDateView[]>) {
      state.dueDateFirstView = action.payload;
    },
    setSidePanelChosen(state, action: PayloadAction<boolean>) {
      state.sidePanelChoosen = action.payload;
    },
    setTaskNotificationContainer(state, action: PayloadAction<any | undefined>) {
      state.notificationContainer = action.payload || undefined;
    },
    setStatusNotificationContainer(state, action: PayloadAction<any | undefined>) {
      state.statusContainer = action.payload || undefined;
    },
    setNotificationPrimaryContext(state, action: PayloadAction<any | undefined>) {
      state.notificationPrimaryContext = action.payload || undefined;
    },
    setUnreadNotificationLog(state, action: PayloadAction<any | undefined>) {
      state.unreadNotificationLog = action.payload || undefined;
    },
    setTaskAttachments(state, action: PayloadAction<any | undefined>) {
      state.newTaskAttachments = action.payload || undefined;
    },
    setExistingTaskAttachments(state, action: PayloadAction<any | undefined>) {
      state.existingAttachments = action.payload || undefined;
    },
    setCommentsCreated(state, action: PayloadAction<boolean | undefined>) {
      state.createdComment = action.payload || undefined;
    },
    setTaskCount(state, action: PayloadAction<number>) {
      const data = [...(state.internalTaskListData ?? [])];
      const updateTask = data?.find(task => task?.taskId === action.payload);
      if (updateTask) {
        const replaceUpdateTask = { ...updateTask, subTaskCount: updateTask?.subTaskCount + 1 };
        Object.assign(updateTask, replaceUpdateTask);
      }
      state.internalTaskListData = data;
    },
    setAttachmentDeleted(state, action: PayloadAction<any | undefined>) {
      state.isAttachmentDeleted = action.payload || undefined;
    },
    setExternalTaskList(state, action: PayloadAction<any | undefined>) {
      state.externalTaskList = action.payload || undefined;
    },
    setIsExternalTask(state, action: PayloadAction<any | boolean>) {
      state.isExternal = action.payload || undefined;
    },
    setCurrentExternalTask(state, action: PayloadAction<any | undefined>) {
      state.currentExternalTask = action.payload || undefined;
    },
    setCueRecipientList(state, action: PayloadAction<ICueRecipient[]>) {
      state.cueRecipientList = action.payload || undefined;
    },
    setCueNotesList(state, action: PayloadAction<ICueNoteResponse[]>) {
      state.cueNotesList = action.payload || undefined;
    },
    setCueNoteDataId(state, action: PayloadAction<number | undefined>) {
      state.cueNoteId = action.payload || undefined;
    },
    setCreatedCueNoteData(state, action: PayloadAction<ICueNote | undefined>) {
      state.createdCueNoteData = action.payload || undefined;
    },
    setLastRecipientDetails(state, action: PayloadAction<ICueRecipient | undefined>) {
      state.lastRecipientDetails = action.payload || undefined;
    },
    setCheckPanelChange(state, action: PayloadAction<boolean>) {
      state.checkPanelChange = action.payload;
    },
    setIsUpdatingTaskFlag(state, action: PayloadAction<boolean>) {
      state.isUpdatingTask = action.payload;
    },
    setUserCuedTo(state, action: PayloadAction<boolean>) {
      state.isUserCueTo = action.payload;
    },
    setCurrentUserCuedTo(state, action: PayloadAction<boolean>) {
      state.currentUserCued = action.payload;
    },
    setIsExternalTaskUpdated(state, action: PayloadAction<boolean>) {
      state.isExternalTaskUpdated = action.payload;
    },
    setCueNoteReplyParent(state, action: PayloadAction<ICueNoteParent | undefined>) {
      state.cueNoteReplyParent = action.payload;
    },
    setIsCueNoteCreated(state, action: PayloadAction<boolean>) {
      state.isCueNoteCreated = action.payload;
    },
    setCueNoteCreatedData(state, action: PayloadAction<ICueNoteContent | undefined>) {
      state.cueCreatedData = action.payload;
    },
    setNotificationLogData(state, action: PayloadAction<INotificationLog>) {
      state.notificationLogData = action.payload;
    },
    setEmailDeliveryAssociateRecords(state, action: PayloadAction<IEmailDeliveryAssociateRecords[]>) {
      state.emailDeliveryAssociateRecors = action.payload;
    },
    setActivityFeedFilter(state, action: PayloadAction<any[]>) {
      state.activityFeedFilterList = action.payload;
    },
    setIsFilterApplied(state, action: PayloadAction<boolean>) {
      state.isFilterApplied = action.payload;
    },
    setCueListforPlacementOptions(state, action: PayloadAction<ICueListForPlacement>) {
      state.cueListforPlacementOptions = action.payload;
    },
    setExternalTaskSubCategory1(state, action: PayloadAction<IExternalTaskSubType[]>) {
      state.subCategory1 = action.payload || [];
    },
    setExternalTaskSubCategory2(state, action: PayloadAction<IExternalTaskSubType[]>) {
      state.subCategory2 = action.payload || [];
    },
    setStatusAppliedAll(state, action: PayloadAction<boolean>) {
      state.isStatusAppliedAll = action.payload;
    },
    setTaskNameFromSubtask(state, action: PayloadAction<boolean>) {
      state.subTaskName = action.payload;
    },
    setDismissNotificationId(state, action: PayloadAction<string | null>) {
      state.dismissNotificationId = action.payload;
    },

    setTaskCommentCreated(state, action: PayloadAction<ITaskComment>) {
      state.internalTaskDetailsData.comments = [
        ...state.internalTaskDetailsData.comments,
        {
          ...action.payload,
          createDate: new Date().toISOString(),
        },
      ];
    },
    // #region Notification Split - Design
    setUnreadNotificationCount(
      state,
      action: PayloadAction<{
        unreadAlertNotifications?: number;
        unreadOrderMatchNotifications?: number;
        unreadTaskNotifications?: number;
      }>,
    ) {
      state.notifications = {
        ...state.notifications,
        unreadAlertNotifications: getNotificationCount(
          action.payload?.unreadAlertNotifications,
          state.notifications.unreadAlertNotifications,
        ),
        unreadOrderMatchNotifications: getNotificationCount(
          action.payload?.unreadOrderMatchNotifications,
          state.notifications.unreadOrderMatchNotifications,
        ),
        unreadTaskNotifications: getNotificationCount(
          action.payload?.unreadTaskNotifications,
          state.notifications.unreadTaskNotifications,
        ),
      };
    },
    incrementNotificationCount(state, action: PayloadAction<{ notificationType: string; incrementBy?: number }>) {
      const { notificationType, incrementBy } = action.payload;
      switch (notificationType) {
        case NotificationCategory.Task:
          state.notifications = {
            ...state.notifications,
            unreadTaskNotifications: (state.notifications.unreadTaskNotifications || 0) + (incrementBy || 1),
          };
          break;
        case NotificationCategory.Preferences:
          state.notifications = {
            ...state.notifications,
            unreadOrderMatchNotifications:
              (state.notifications.unreadOrderMatchNotifications || 0) + (incrementBy || 1),
          };
          break;
        case NotificationCategory.CueNote:
        case NotificationCategory.Email:
        case NotificationCategory.Alert:
          state.notifications = {
            ...state.notifications,
            unreadAlertNotifications: (state.notifications.unreadAlertNotifications || 0) + (incrementBy || 1),
          };
          break;
        default:
          break;
      }
    },
    decrementNotificationCount(state, action: PayloadAction<{ notificationType: string; decrementBy?: number }>) {
      const { notificationType, decrementBy } = action.payload;
      switch (notificationType) {
        case NotificationCategory.Task:
          state.notifications = {
            ...state.notifications,
            unreadTaskNotifications: Math.max(
              0,
              (state.notifications.unreadTaskNotifications || 0) - (decrementBy || 1),
            ),
          };
          break;
        case NotificationCategory.Preferences:
          state.notifications = {
            ...state.notifications,
            unreadOrderMatchNotifications: Math.max(
              0,
              (state.notifications.unreadOrderMatchNotifications || 0) - (decrementBy || 1),
            ),
          };
          break;
        case NotificationCategory.Alert:
        case NotificationCategory.Email:
        case NotificationCategory.CueNote:
          state.notifications = {
            ...state.notifications,
            unreadAlertNotifications: Math.max(
              0,
              (state.notifications.unreadAlertNotifications || 0) - (decrementBy || 1),
            ),
          };
          break;
        default:
          break;
      }
    },
    appendNotificationLogData(state, action: PayloadAction<INotificationLog>) {
      state.notificationLogData = {
        ...action.payload,
        results: [...(state.notificationLogData?.results || []), ...action.payload.results],
      };
    },
    setRequestedAlertCategory(state, action: PayloadAction<string | null>) {
      state.requestedAlertCategory = action.payload;
    },
    setNotificationUnreadStatus(state, action: PayloadAction<Array<{ id: string; isNotificationUnread?: boolean }>>) {
      const results = _.cloneDeep(state.notificationLogData?.results);
      const idToStatusMap = new Map(action.payload.map(({ id, isNotificationUnread }) => [id, isNotificationUnread]));
      results?.forEach(item => {
        if (idToStatusMap.has(item.id)) {
          item.isNotificationUnread = idToStatusMap.get(item.id);
        }
      });
      state.notificationLogData = {
        ...state.notificationLogData,
        results: results,
      };
    },
    // #endregion
    reset() {
      return { ...initialState };
    },
  },
});

export const { name: taskDetailsSliceKey, reducer: taskDetailsReducer } = taskDetailsSlice;

export const taskDetailsActions = {
  ...taskDetailsSlice.actions,
  ...businessActions,
  getTaskAction,
  getTaskCommentsAction,
  saveSubTaskAction,
  editSubTaskAction,
  saveTaskAction,
  saveExternalTaskAction,
  editTaskAction,
  getInternalTaskListAction,
  getTaskGroupedListAction,
  taskGridDataAction,
  saveStatusTaskAction,
  updateSubtaskStatusAction,
  getCategoryListAction,
  getExternalCategoryListAction,
  sendTaskCreatedNotification,
  sendSubTaskCreatedNotification,
  sendCueNoteCreatedNotification,
  sendNotificationReadStatus,
  getUnReadNotifications,
  getUnReadNotificationsCounts,
  deleteAttachments,
  getExternalTaskList,
  updateExternalTaskStatus,
  getCueNotesForPlacements,
  getExternalTaskDetails,
  getCueNoteRecipient,
  saveCueNoteAction,
  sendUpdateExternalTask,
  getNotificationLogData,
  getEmailDeliveryAssociateRecords,
  getExternalSubCategoryList,
  sendTaskUpdatedNotification,
  saveTaskCommentAction,
};
