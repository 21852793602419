import React, { useEffect, useState } from 'react';
import { Grid } from 'amn-ui-core';
import { GlanceTile } from '../../../../../Common/GlanceTile/NewGlanceTile';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectExistingPlacements, selectOrderActivePlacementCounts, selectReadyToSubmitPlacementIds } from 'store/redux-store/unit-preferences/selectors';
import { extraxtTileArray } from './UnitUtil';

export const ExistingUnitPlacementGlance = () => {
  const { t } = useTranslation();
  const orderActivePlacementCounts = useSelector(selectOrderActivePlacementCounts);
  const existingPlacements = useSelector(selectExistingPlacements);
  const readyToSubmitPlacementIds = useSelector(selectReadyToSubmitPlacementIds);

  const [glanceTileArray, setGlanceTileArray] = useState(
    extraxtTileArray(t, orderActivePlacementCounts, readyToSubmitPlacementIds),
  );

  useEffect(() => {
    setGlanceTileArray(extraxtTileArray(t, orderActivePlacementCounts, readyToSubmitPlacementIds));
}, [t, orderActivePlacementCounts, readyToSubmitPlacementIds, existingPlacements]);

  return (
    <Grid container spacing={3}>
      {glanceTileArray?.map(tile => (
        <Grid item>
          <GlanceTile {...tile} />
        </Grid>
      ))}
    </Grid>
  );
};
