import React from 'react';
import NoResultsImage from 'app/assets/images/No-Results.svg';
import { Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()(theme =>
  ({
    root: {
      height: '350px',
      padding: '40px',
      paddingTop: '80px',
    },
    image: {
      margin: '0 40px',
      padding: '0 40px',
    },
    textBox: {
      textAlign: 'center',
      fontStyle: 'italic',
      fontSize: '13px',
    },
  }));

export const NoResults = props => {
  const { classes } = useStyles();
  const { text } = props;
  return (
    <div className={classes.root}>
      <div className={classes.image}>
        <img src={NoResultsImage} alt="No Result" />
      </div>
      <div>
        <Typography className={classes.textBox}>{`There are no results for "${text}"`}</Typography>
        <Typography className={classes.textBox}>{`Please try again`}</Typography>
      </div>
    </div>
  );
};
