import React, { useEffect, useState } from 'react';
import { Grid, ThemeProvider, StyledEngineProvider,useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { CustomMultiLineTextField } from 'app/components/Order/OrderCreationForm/Common/CustomMultiLineTextField';
import { OrderConstants } from 'app/constants/OrderCreationForm';
import { NewUnit } from 'app/models/Unit/CreateUnit';
import { useTranslation } from 'react-i18next';
import { InstructionText, UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { useSelector } from 'react-redux';
import { selectOrderFormValid } from 'app/components/Order/Store/Order.selectors';
import queryString from 'query-string';

interface Props {
  unit: NewUnit;
  onUnitPropChange: (property: string, value: any) => void;
  showMandetryStyle?: boolean;
}
const useStyles = makeStyles()((theme) => ({
  body: {
    paddingBottom: 12,
  },
  instructionalText: {
    paddingBottom: 16,
  },
}));
export const ExternalJobInternalNotes = (props: Props) => {
  const { unit, onUnitPropChange } = props;
  const { classes } = useStyles();
  const theme=useTheme();
  const { t } = useTranslation();
  const [reqExtJob, setReqExtJob] = useState(false);
  const isOrderFormValid = useSelector(selectOrderFormValid);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUnitPropChange(event.target.name, event.target.value);
  };
  const newOrderUrl = queryString.parse(window.location.search).neworder;

  useEffect(() => {
    setReqExtJob(isOrderFormValid && newOrderUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOrderFormValid]);

  return (
    <div>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UnitDetailsBody variant="outlined">
            <Grid container direction="column" spacing={1} className={classes.body}>
              <Grid item>
                <UnitCardsTitle>{t('order.createUnit.externalJobInternalNotes.title')}</UnitCardsTitle>
              </Grid>
              <Grid item>
                <CustomMultiLineTextField
                  id={'createUnitExternalJobDescriptionTxt'}
                  value={unit?.externalJobDescription}
                  name="externalJobDescription"
                  req={reqExtJob ? unit?.externalJobDescription === '' || unit?.externalJobDescription === null : false}
                  label={
                    reqExtJob
                      ? `${t('order.createUnit.externalJobInternalNotes.externalJobDescription')} *`
                      : t('order.createUnit.externalJobInternalNotes.externalJobDescription')
                  }
                  onChange={handleChange}
                  placeholder={t('order.createUnit.externalJobInternalNotes.placeHolder')}
                />
                <div className={classes.instructionalText}>
                  <InstructionText>{OrderConstants.UNIT_CREATION_EXTERNAL_JOB_HELPER}</InstructionText>
                </div>
              </Grid>
              <Grid item>
                <CustomMultiLineTextField
                  id={'createUnitInternalNotesTxt'}
                  value={unit?.internalNotes}
                  name="internalNotes"
                  label={t('order.createUnit.externalJobInternalNotes.internalNotes')}
                  onChange={handleChange}
                  placeholder={t('order.createUnit.externalJobInternalNotes.placeHolder')}
                />
              </Grid>
            </Grid>
          </UnitDetailsBody>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};
