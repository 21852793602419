import { missingField } from 'app/constants';
import { ICoverageResponseData } from 'app/models/UserCoverage/UserCoverage';
import { getAllEmployees } from 'app/services/TaskServices/TaskServices';
import moment from 'moment-timezone';
import { Concatenate } from 'utils/string/string';

const concatName = (firstName, lastName) => {
  if (firstName) return Concatenate([firstName || '', lastName || ''], ' ');
  return missingField;
};

export const extractTime = (dateTime: Date) => {
  const parsedDate = moment(dateTime?.toLocaleString(), 'M/D/YYYY, h:mm:ss A');
  const formattedTime = parsedDate.format('HH:mm:ss');
  return formattedTime;
};

export const formatCoverageResponse = response => {
  const coverageResponse: ICoverageResponseData[] = [];
  response?.map(data => {
    const startDate = new Date(data?.startDate)?.toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const extractedStartDate = startDate.split(',')[0];
    const endDate = new Date(data?.endDate)?.toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const extractedEndDate = endDate.split(',')[0];
    const createdDate = new Date(data?.createDate)
      ?.toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .split(',')[0];
    const coverageData: ICoverageResponseData = {
      id: data?.id,
      coveredByEmployeeId: data?.coveringEmployeeId,
      coverageForEmployeeId: data?.coveredEmployeeId,
      coveredBy: concatName(data?.coveringEmployeeFirstName, data?.coveringEmployeeLastName),
      coverageFor: concatName(data?.coveredEmployeeFirstName, data?.coveredEmployeeLastName),
      createdBy: concatName(data?.createByFirstName, data?.createByLastName),
      createdDate: createdDate,
      dateRange: Concatenate([extractedStartDate || '', extractedEndDate || ''], ' - '),
      lastUpdatedBy: concatName(data?.lastUpdateByFirstName, data?.lastUpdateByLastName),
      notes: data?.notes ? data?.notes : missingField,
      numDays: data?.totalDaysOfCoverage,
      startTime: data?.startDate,
      endTime: data?.endDate,
      startTimeValue: startDate,
      endTimeValue: endDate,
      displayCoverageDuration: data?.displayCoverageDuration,
      displayCoverageDurationInDays: data?.displayCoverageDurationInDays,
    };
    coverageResponse.push(coverageData);
  });
  return coverageResponse;
};

export const timeConverter = data => {
  const pstDateString = new Date(data)?.toLocaleString('en-US', {
    timeZone: 'America/Los_Angeles',
  });
  const formatDateTime = new Date(pstDateString);
  return formatDateTime;
};

export const retrieveAssignDetails = async (input, id) => {
  const employeeDetails = await getAllEmployees(input);
  let matchedResult = null;
  matchedResult = employeeDetails?.find(employeeData => employeeData?.employeeId === id);
  return matchedResult;
};

export const checkAllDayValue = (startTime, endTime) => {
  const startdateTime = new Date(startTime)?.toLocaleString('en-US', {
    timeZone: 'America/Los_Angeles',
  });
  const startDateArray = startdateTime?.split(', '); // Split the string into date and time parts
  const startTimeArray = startDateArray[1]?.split(' ');
  const extractedStartTime = startTimeArray[0];

  const endDateTime = new Date(endTime)?.toLocaleString('en-US', {
    timeZone: 'America/Los_Angeles',
  });
  const endDateArray = endDateTime?.split(', '); // Split the string into date and time parts
  const endTimeArray = endDateArray[1]?.split(' ');
  const extractedEndTime = endTimeArray[0];
  if (
    (extractedStartTime === '1:00:00' || extractedStartTime === '12:00:00') &&
    (extractedEndTime === '11:59:59' || extractedEndTime === '10:59:59')
  ) {
    // 1:00:00 and 10:59:59 to account for Daylight Savings Time changes
    return true;
  }
  return false;
};

export const formatCoverageDetails = (coverageData, coveredByData, coverageForData) => {
  const startTime = extractTime(timeConverter(coverageData?.startTime));
  const endTime = extractTime(timeConverter(coverageData?.endTime));
  const dateValue = coverageData?.dateRange?.split(' - ');
  const allDayValue = checkAllDayValue(coverageData?.startTime, coverageData?.endTime);
  const coverageDetails = {
    id: coverageData?.id,
    allDay: allDayValue,
    coveredEmployeeId: coverageForData?.employeeId,
    coveredEmployeeFirstName: coverageForData?.employeeFirstName,
    coveredEmployeeLastName: coverageForData?.employeeLastName,
    coveringEmployeeId: coveredByData?.employeeId,
    coveringEmployeeFirstName: coveredByData?.employeeFirstName,
    coveringEmployeeLastName: coveredByData?.employeeLastName,
    startDate: dateValue?.length > 0 ? dateValue[0] : '',
    endDate: dateValue?.length > 0 ? dateValue[1] : '',
    startTime: allDayValue ? '' : timeConverter(coverageData?.startTime),
    endTime: allDayValue ? '' : timeConverter(coverageData?.endTime),
    notes: coverageData?.notes === missingField ? '' : coverageData?.notes,
    createByEmployeeId: 0,
    createByFirstName: '',
    createByLastName: '',
    createDate: coverageData?.createdDate,
    startTimeValue: allDayValue ? '' : startTime,
    endTimeValue: allDayValue ? '' : endTime,
    statusId: 4,
  };
  return coverageDetails;
};
