import { CustomTooltip } from 'app/components/Common/Tooltips';
import { isWaitingGoodToGo, placementStatusOptions } from 'app/components/Placement/NextPlacementStatus/StatusDefaults';
import { placementStatusAction } from 'store/redux-store/placement-status/slice';
import { selectPlacementStatus } from 'store/redux-store/placement-status/selectors';
import { IRequirementRowPadding } from 'app/models/Placement/PlacementSummary';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import {
  selectEditableFields,
  selectEditableRequirementTypes,
  selectOfferInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { ConfirmationCheckbox } from '../../TableComponents/Confirmations/ConfirmationCheckbox';
import { ConfirmationsRow } from '../../TableComponents/Confirmations/ConfirmationsTable';

export const TravelConfirmed = ({ hasRequirements }: IRequirementRowPadding) => {
  const { t } = useTranslation();
  const { travelConfirmed: initialValue } = useSelector(selectEditableFields);
  const { requirementFilter } = useSelector(selectOfferInfo);
  const { control } = useFormContext();
  const { travelConfirmed: travelConfirmedReqType } = useSelector(selectEditableRequirementTypes);
  const { statusChangeSource, selectedStatus, availablePlacementStatus } = useSelector(selectPlacementStatus);
  const dispatch = useDispatch();
  const { setValue } = useFormContext();

  const { display } = useRequirementFilter(
    'offer-confirmation',
    requirementFilter,
    travelConfirmedReqType?.requirement,
  );

  React.useEffect(() => {
    // Reset travelConfirmed to initial value when status change dialog cancel button is pressed
    if (selectedStatus === undefined && statusChangeSource === 'travelConfirmed') {
      setValue('travelConfirmed', initialValue);
      dispatch(placementStatusAction.setSelectedStatus({ status: undefined, statusChangeSource: null }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue, selectedStatus, statusChangeSource]);

  return display ? (
    <Controller
      control={control}
      name="travelConfirmed"
      render={({ ref, onChange, ...rest }) => (
        <ConfirmationsRow
          id="placement-offer-confirmations-travel-confirmed"
          lastUpdatedBy={rest?.value?.lastUpdatedBy}
          lastUpdatedDate={rest?.value?.lastUpdatedAt}
          requirement={travelConfirmedReqType?.requirement}
          hasOtherRequirements={hasRequirements}
          controlComponent={
            <>
              <CustomTooltip
                standardMargin
                isStandardContent
                disabled={!travelConfirmedReqType?.requiredDependency}
                tooltipContent={t('placement.profile.summary.offer.confirmations.line1')}
              >
                <ConfirmationCheckbox
                  label={t('placement.profile.summary.offer.confirmations.travelConfirmed')}
                  defaultState={initialValue}
                  disabled={
                    travelConfirmedReqType?.requiredDependency ||
                    availablePlacementStatus.findIndex(item => isWaitingGoodToGo(item)) === -1
                  }
                  onChange={data => {
                    if (data?.checked && availablePlacementStatus.findIndex(item => isWaitingGoodToGo(item)) !== -1) {
                      dispatch(
                        placementStatusAction.setSelectedStatus({
                          status: placementStatusOptions.waitingGoodToGo,
                          statusChangeSource: 'travelConfirmed',
                        }),
                      );
                    }
                    onChange(data);
                  }}
                  {...rest}
                />
              </CustomTooltip>
            </>
          }
        />
      )}
    />
  ) : null;
};
