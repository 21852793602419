import { CompactStatus, ILicenseStateData, IOrderLicense } from '@AMIEWEB/Order/Store/Order.redux';

export const getSeparatedLicenseDataByStates = (licenseData: IOrderLicense[]) => {
  const transformedLicenses = licenseData?.flatMap(license => {
    if (Array.isArray(license?.licenseStateDetails) && license?.licenseStateDetails?.length > 0) {
      // Create new licenses for each state
      return license.licenseStateDetails.map(stateDetails => ({
        ...license,
        isMultiState:
          license.compactStatus === CompactStatus.compact
            ? true
            : license.compactStatus === CompactStatus.single
            ? false
            : null,
        state: stateDetails?.state?.name ?? null,
        stateAbbreviation: stateDetails?.state?.value ?? null,
        documentAttachmentRequired: stateDetails?.documentAttachmentRequired ?? false,
        isRequired: stateDetails?.isRequired ?? false,
        isError: license.isError,
      }));
    }
    return license;
  });

  return transformedLicenses;
};

export const transformExistingLicenseData = (
  isDuplicate: boolean,
  existingSkillsetLicenseData: any[],
  states: any[],
) => {
  const updatedRequirements = existingSkillsetLicenseData?.flatMap(requirement => {
    if (requirement?.compactStatus === CompactStatus.single && requirement?.licenseStateDetails?.length) {
      // Create new requirements for each licenseStateDetails
      return requirement.licenseStateDetails.map(stateDetails => ({
        ...requirement,
        licenseStateDetails: [stateDetails],
      }));
    } else {
      return [requirement]; // Keep the original requirement
    }
  });

  const savedLicenses = updatedRequirements?.map(item => ({
    requirementTypeId: isDuplicate ? item.licenseId : item.requirementTypeId,
    stateAbbreviation: isDuplicate
      ? item.licenseStateDetails[0]?.state?.value
      : item.requirementState?.stateAbbreviation,
    state: isDuplicate ? item.licenseStateDetails[0]?.state?.name : item.requirementState?.state,
    licenseAbbreviation: isDuplicate ? item.licenseAbbreviation : item.requirementAbbreviation,
    licenseDescription: isDuplicate ? item.licenseDescription : item.requirementDescription,
    isRequired: isDuplicate ? item.licenseStateDetails[0]?.isRequired : item.isRequired,
    isMultiState: isDuplicate ? item.compactStatus === CompactStatus.compact : item.isMultiState,
    documentAttachmentRequired: isDuplicate
      ? item.licenseStateDetails[0]?.documentAttachmentRequired
      : item.documentAttachmentRequired,
  }));

  let formattedLicenses: IOrderLicense[] = [];

  if (savedLicenses?.length) {
    formattedLicenses = savedLicenses?.reduce((acc, license) => {
      const currentItem = acc[acc.length - 1];
      const stateValue = states?.find(s => s?.value === license?.stateAbbreviation);
      if (
        currentItem &&
        currentItem.licenseId === license.requirementTypeId &&
        license.state &&
        license.stateAbbreviation &&
        stateValue
      ) {
        const stateDetails: ILicenseStateData = {
          state: stateValue,
          isRequired: license?.isRequired,
          documentAttachmentRequired: license?.documentAttachmentRequired,
        };
        currentItem.licenseStateDetails.push(stateDetails);
      } else {
        const licenseDetails: IOrderLicense = {
          licenseDescription: license?.licenseDescription,
          licenseAbbreviation: license?.licenseAbbreviation,
          licenseId: license?.requirementTypeId,
          compactStatus:
            license?.isMultiState === true
              ? CompactStatus?.compact
              : license?.isMultiState === false
              ? CompactStatus?.single
              : null,
          abbreviation: license?.stateAbbreviation,
          licenseStateDetails: [
            {
              state: stateValue,
              isRequired: license?.isRequired,
              documentAttachmentRequired: license?.documentAttachmentRequired,
            },
          ],
          isError: { compactStatus: false, state: false },
          isSelected: true,
        };
        acc.push(licenseDetails);
      }
      return acc;
    }, []);
  }

  return formattedLicenses;
};
