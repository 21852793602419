import React, { useEffect, useState } from 'react';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { Typography,useTheme } from 'amn-ui-core';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { missingField } from 'app/constants';
import TextArea from '@AMIEWEB/Common/TextArea/TextArea';
import { selectUser } from 'oidc/user.selectors';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { usePromiseTracker } from 'react-promise-tracker';
import { APIStatus } from 'app/enums/ApiStatus';
import { FacilityContactsActions } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.redux';
import { selectClientContractDrawer } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.selector';
import ClientContractPanel from '@AMIEWEB/Common/SidePanel/ClientContractPanel';

const useClientContactStyles = makeStyles()(theme=>({
  icon: {
    cursor: 'pointer !important',
    width: '28px',
    padding: '1px',
    color: theme.palette.system.neutralGray,
    '&:hover': {
      borderRadius: '50%',
      background: theme.palette.system.lightSteelBlue,
      color: theme.palette.system.steelBlue,
    },
  },
}))

enum IFacilityClientContactTabs {
  SUMMARY,
}

export const ClientContactDrawer = () => {
  const clientContractDrawerData = useSelector(selectClientContractDrawer);
  const tabConfiguration = [{ id: IFacilityClientContactTabs.SUMMARY, name: 'Summary', component: <SummaryTab /> }];
  const dispatch = useDispatch();

  return (
    <>
      <ClientContractPanel
        tabs={tabConfiguration}
        open={clientContractDrawerData?.open}
        handleClose={() =>
          dispatch(FacilityContactsActions.setFacilityClientContactDrawer({ ...clientContractDrawerData, open: false }))
        }
      />
    </>
  );
};

const SummaryTab = () => {
  const { classes } = useClientContactStyles();
  const userData = useSelector(selectUser);
  const clientContractDrawerData = useSelector(selectClientContractDrawer);
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [saveStatus, setSaveStatus] = useState<number | string>('');
  const methods = useForm({ mode: 'all', defaultValues: { notes: clientContractDrawerData?.notes } });
  const { reset, setValue, control, getValues } = methods;
  const dispatch = useDispatch();
  const theme= useTheme();
  const { promiseInProgress: postNotesStatusLoading } = usePromiseTracker({
    area: 'post-notes-status-units',
    delay: 0,
  });
  const notes = clientContractDrawerData?.notes;

  const isAuthorized = Authorized(
    [userRoles.accountManagement, userRoles.accountManagement_Leadership, userRoles.accountManagement_TeamMember],
    userData.userInfo,
  );

  /**
   * Method to call on check icon click
   */
  const onCheckIconClick = () => {
    dispatch(FacilityContactsActions.updateFacilityUnitNotesStatus({ notes: getValues('notes') }));
    setSaveStatus(APIStatus.isLoading);
  };

  /**
   * Method to call on close icon click
   */
  const onCloseIconClick = () => {
    reset();
    setEditMode(false);
  };

  useEffect(() => {
    if (isEditMode) setValue('notes', notes === missingField ? '' : notes);
  }, [isEditMode]);

  useEffect(() => {
    if (postNotesStatusLoading) {
      setSaveStatus(APIStatus.Completed);
      setTimeout(() => {
        setSaveStatus('');
        setEditMode(false);
      }, 3000);
    }
  }, [postNotesStatusLoading]);

  return (
    <LayoutGrid container direction="column" bgcolor={theme.palette.framework.system.whisper}>
      <LayoutGridItem container>
        <LayoutGridItem item xs={11}>
          <Typography sx={theme=>({ color: theme.palette.components.typography.color, fontSize: '16px', fontWeight: 'medium' })}>Notes</Typography>
        </LayoutGridItem>
        {isAuthorized && !isEditMode && (
          <LayoutGridItem item xs={1}>
            <CreateOutlinedIcon onClick={() => setEditMode(true)} className={classes.icon} />
          </LayoutGridItem>
        )}
      </LayoutGridItem>
      <LayoutGridItem container>
        {isEditMode ? (
          <FormProvider {...methods}>
            <Controller
              name={'notes'}
              control={control}
              render={({ value }) => (
                <TextArea
                  value={value}
                  onChange={event => setValue('notes', event?.target?.value)}
                  onCheckIconClick={onCheckIconClick}
                  onCloseIconClick={onCloseIconClick}
                  saveStatus={saveStatus}
                />
              )}
            />
          </FormProvider>
        ) : (
          <LayoutGridItem container direction="column">
            {clientContractDrawerData.statusLastUpdated && (
              <Typography variant="body2" sx={{ color: 'system.neutralGray' }} component="div">
                {clientContractDrawerData.statusLastUpdated}
              </Typography>
            )}
            <Typography component="div">{notes === missingField || notes === '' ? missingField : notes}</Typography>
          </LayoutGridItem>
        )}
      </LayoutGridItem>
    </LayoutGrid>
  );
};

export default ClientContactDrawer;
