import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { useInputCountryDropdownStyles } from '../Controls/FormInputCountryDropDown';
import 'react-phone-input-2/lib/style.css';

/**
 * Component to render phone number with country flag
 */
export const CountryPhoneNumber = ({
  onChange,
  isDisabled = false,
  value,
  label,
  name,
  className,
  inlineStyle,
  countryCodeEditable = true,
  onKeyDown,
  userDetailsTab,
  error = false,
  onBlur,
  errorMessage,
}: {
  onChange;
  onBlur?: any;
  isDisabled?: boolean;
  value: string;
  label?: string;
  name?: string;
  className?: string;
  inlineStyle?: any;
  countryCodeEditable?: boolean;
  onKeyDown?: any;
  userDetailsTab?: boolean;
  error?: boolean;
  errorMessage?: string;
}) => {
  const { classes: phoneNumberClasses } = useInputCountryDropdownStyles();
  return (
    <>
      <PhoneInput
        onKeyDown={onKeyDown}
        containerClass={`${className ? className : ''} ${phoneNumberClasses.arrowClass}`}
        disabled={isDisabled}
        country={'us'}
        placeholder={'(...) ...-....'}
        enableSearch={true}
        disableSearchIcon={true}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        specialLabel={label}
        inputProps={{
          label: label,
          name: name,
        }}
        searchPlaceholder="Search Country"
        preferredCountries={['us', 'ca', 'mx']}
        countryCodeEditable={countryCodeEditable}
        dropdownClass={phoneNumberClasses.countryDropdown}
        buttonStyle={{
          border: 0,
          borderBottom: error ? '2px solid #f44336' : label ? '1px solid black' : '2px solid #8e8e8e',
          borderRight: '1px solid lightgrey',
          background: isDisabled ? 'rgba(0,0,0,0.08)' : label ? '#e7e6e6' : '#f4f4f4',
        }}
        inputStyle={{
          height: label ? (userDetailsTab ? 48 : 64) : 54,
          paddingTop: userDetailsTab ? 14 : 'none',
          width: label ? '100%' : '96%',
          border: 0,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderBottom: error ? '2px solid #f44336' : label ? '1px solid #8e8e8e' : '2px solid #8e8e8e',
          color: isDisabled ? (label ? '#000000' : 'rgba(0,0,0,0.38)') : '#333333',
          background: isDisabled ? (label ? 'rgba(0,0,0,0.04)' : 'rgba(0,0,0,0.12)') : '#f4f4f4',
          ...inlineStyle,
        }}
        searchStyle={{
          width: 'fit-content',
        }}
        dropdownStyle={{ position: 'absolute', top: '-220px' }}
      />
      <p className={phoneNumberClasses.errorText}>{error && errorMessage}</p>
    </>
  );
};
