export const getFormattedSubmissionEmail = data => {
    let submissionEmail = {} as ISubmissionEmail;
    submissionEmail = {
      bulkEmailSettings: data?.emailSettings,
      emailTemplateId: data?.emailTemplate,
      sendTo: data?.sendTo?.filter(x => x.isChecked),
    };
    return JSON.stringify(submissionEmail);
  };
  
  export const getFormattedSubmissionPacket = data => {
    let submissionPacket = {} as ISubmissionPacket;
    submissionPacket = {
      packetTemplate: data?.packetTemplate,
      attentionTo: data?.attentionTo?.filter(x => x.isChecked),
      noteToFacility: data?.noteToFacility,
    };
    return JSON.stringify(submissionPacket);
  };
  
  export interface ISubmissionEmail {
    bulkEmailSettings: string;
    emailTemplateId: string;
    sendTo: ISendToAttentionTo[];
  }
  
  export interface ISubmissionPacket {
    packetTemplate: IpacketTemplate;
    attentionTo: ISendToAttentionTo[];
    noteToFacility: string;
  }
  
  export interface ISendToAttentionTo {
    firstName: string;
    lastName: string;
    email: string;
    title: string;
    isChecked: boolean;
    tabSelected: string;
    contactId?: string;
  }
  
  export interface IpacketTemplate {
    amnBrand: boolean;
    anonymize: boolean;
    showBillRate: boolean;
    billRate: number;
    templateValue: string;
  }
  