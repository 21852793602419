import { DateRangePicker } from 'app/ComponentLibrary/DateRangeNew';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import moment from 'moment';
import { editableFields } from '../../editUtils';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { selectPlacementProgress } from '../../../../../../store/redux-store/placement-details/selectors';
import { selectEditStatus } from '../store/placementPageAttributes.selector';
import { Typography, Skeleton } from 'amn-ui-core';
import { missingField } from 'app/constants';
import { usePromiseTracker } from 'react-promise-tracker';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { useTranslation } from 'react-i18next';

interface DateOfBirthProps {
  [x: string]: any;
}

export const DateOfBirth = ({ ...props }: DateOfBirthProps) => {
  const { control } = useFormContext();
  const [readOnly, setReadOnly] = React.useState<boolean>(true);
  const [hidden, setHidden] = React.useState<boolean>(true);
  const { userInfo } = useSelector(selectUser);
  const { dob: status } = useSelector(selectEditStatus);
  const { categoryProgress } = useSelector(selectPlacementProgress);
  const { promiseInProgress } = usePromiseTracker({ area: 'candidate-dob', delay: 0 });
  const { setError, clearErrors, errors } = useFormContext();
  const { t } = useTranslation();

  React.useEffect(() => {
    const isNotInOrAboveBookingStage = categoryProgress.find(item => item.tag === 'Booking' && item.state === 1);
    // Editable
    if (
      // RECRUITER WHERE PLACEMENT IS LESS THAN BOOKED STATUS
      ((Authorized([userRoles.recruitment_Leadership, userRoles.recruitment_TeamMember], userInfo) || false) &&
        isNotInOrAboveBookingStage !== undefined) ||
      // ALWAYS EDIT ROLES
      Authorized(
        [
          userRoles.credentialing_Leadership,
          userRoles.credentialing_TeamMember,
          userRoles.clinical_QualificationsSpecialist,
        ],
        userInfo,
      )
    ) {
      // USER CAN EDIT
      setReadOnly(false);
      setHidden(false);
    } else if (
      Authorized(
        [
          userRoles.accountManagement_Leadership,
          userRoles.accountManagement_TeamMember,
          userRoles.recruitment_Leadership,
          userRoles.recruitment_TeamMember,
          userRoles.credentialing_Leadership,
          userRoles.credentialing_TeamMember,
          userRoles.clinical_Manager,
          userRoles.clinical_QualificationsSpecialist,
          userRoles.clinical_ReviewAnalyst,
          userRoles.clinical_Director,
        ],
        userInfo,
      )
    ) {
      // READONLY - USER CAN VIEW FULL SSN
      setReadOnly(true);
      setHidden(false);
    } else {
      setHidden(true);
    }
  }, [categoryProgress, userInfo]);

  const value: any = useWatch({ name: 'dob' });

  return (
    <Controller
      control={control}
      name={editableFields.dob}
      render={({ ref, onChange, ...rest }) => {
        const dob = moment(value ?? null);
        return (
          <React.Fragment>
            {promiseInProgress ? (
              <Skeleton />
            ) : hidden ? (
              <Typography>{!!value ? '**/**/****' : missingField}</Typography>
            ) : readOnly ? (
              <Typography>{dob.isValid() ? dob.format('MM/DD/YYYY') : missingField}</Typography>
            ) : (
              <DateRangePicker
                onChange={newValue => {
                  if (newValue.startDate !== undefined) {
                    const newDate = moment(newValue.startDate);
                    const setTo = newDate.isValid() ? moment(newDate).format('YYYY-MM-DDTHH:mm:ss') : null;
                    clearErrors('dob');
                    onChange(setTo);
                  } else {
                    setError('dob', {
                      type: 'custom',
                      message: t('placement.profile.summary.submission.order.availableStartDateError'),
                    });
                    onChange(null);
                  }
                }}
                onCancel={() => {}}
                actionBar={false}
                clear
                open={false}
                variant="standard"
                size="inline"
                single
                clickAway
                // CANDIDATE LOGIC COMMENTED OUT
                isDisabled={readOnly || status?.disabled}
                maxWidth={110}
                showIcon={true}
                isDateIconReq={true}
                quickSelect={false}
                initialDateRange={{
                  startDate: dob.isValid() ? new Date(dob.format('YYYY-MM-DDTHH:mm:ss')) : undefined,
                  endDate: dob.isValid() ? new Date(dob.format('YYYY-MM-DDTHH:mm:ss')) : undefined,
                  useLabelAsValue: true,
                }}
                value={value}
                minDate={new Date('1/1/1940')}
                maxDate={new Date()}
                error={!isNullOrUndefined(errors?.dob)}
                helperText={!isNullOrUndefined(errors?.dob) ? errors?.dob?.message : undefined}
              />
            )}
          </React.Fragment>
        );
      }}
    />
  );
};
