import React from 'react';
import { Box } from 'amn-ui-core';
import ResetIcon from 'app/assets/images/IconImages/ResetButton.svg';
import ReserBlueIcon from 'app/assets/images/IconImages/ReserBlueIcon.svg';

interface PropType {
  iconStyle?: any;
  toggleReset?: boolean;
}
export const ResetButton = ({ iconStyle, toggleReset }: PropType) => {
  return (
    <Box >
      <img
        style={iconStyle ? iconStyle : { height: 36, width: 39 }}
        alt="reset-icon"
        src={toggleReset ? ReserBlueIcon : ResetIcon}
      />
    </Box>
  );
};
