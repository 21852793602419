import { RowContainer } from '@AMIEWEB/Placement/PlacementDetails/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styledComponent from 'styled-components';
import { makeStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import { selectInterviewInfo } from 'store/redux-store/placement-summary/selectors';
import { getRequirementCheck } from '../../../helper';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';

const useStyles = makeStyles()(() => ({
  titleCell: {
    textAlign: 'start',
    display: 'flex',
    gap: 8,
  },
  title: {
    fontWeight: 500,
    paddingTop: 2,
  },
}));

export const OrderSpecs = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const {
    virtualInterview: {
      order: {
        type,
        view: { rating, vaAutoOffer, skillset, matchingSkillset },
      },
    },
    requirementFilter,
  } = useSelector(selectInterviewInfo);
  const { display } = useRequirementFilter('interview-virtual', requirementFilter, type);

  return (
    <RowContainer>
      <tr>
        <td colSpan={2}>
          <div className={classes.titleCell}>
            {!type ? null : <span>{getRequirementCheck(type)}</span>}
            <span className={classes.title}>
              {t('placement.profile.summary.interview.virtualInterviewContainer.title')}
            </span>
          </div>
        </td>
      </tr>
      <tr>
        <RowKey>{t('placement.profile.summary.interview.virtualInterviewContainer.orderSpecs.rating')}</RowKey>
        <RowValue>{rating}</RowValue>
      </tr>
      <tr>
        <RowKey>{t('placement.profile.summary.interview.virtualInterviewContainer.orderSpecs.vaAutoOffer')}</RowKey>
        <RowValue>{vaAutoOffer}</RowValue>
      </tr>
      <tr>
        <RowKey>{t('placement.profile.summary.interview.virtualInterviewContainer.orderSpecs.skillset')}</RowKey>
        <RowValue>{skillset}</RowValue>
      </tr>
      <tr>
        <RowKey>
          {t('placement.profile.summary.interview.virtualInterviewContainer.orderSpecs.matchingSkillset')}
        </RowKey>
        <RowValue>{matchingSkillset}</RowValue>
      </tr>
    </RowContainer>
  );
};

const RowKey = styledComponent.th`
  text-align: start;
  vertical-align: baseline;
  font-weight: 450;
  font-size: 12px;
  width: 45%;
`;

const RowValue = styledComponent.td`
  text-align: start;
  font-size: 14px;
`;
