import { GridColDef, GridValueSetterParams } from '@mui/x-data-grid-pro';
import { missingField } from 'app/constants';
import { ITaxData } from 'app/models/Candidate/CandidateProfile';
import { XGridDefaultColumn } from 'app/components/Common/XGrid/cells/XGridDefaults';
import { GridDropdownCell } from './PayrollDropdownCell';
import { GridTextboxCell } from './PayrollTextCell';
import { GridDateCell } from './PayrollDatePicker';
import moment from 'moment';

export const flattenTravelerPayrollData = (data: ITaxData[]) => {
  return data
    ? data.map((item, index) => {
      return {
        rowID: item.taxDataID || index,
        type: item.type || missingField,
        maritialStatus: item.maritalStatus || missingField,
        allowances: item.allowances ? item.allowances.toString() : missingField,
        exemptStatus: item.exemptStatus ? item.exemptStatus === 'Y' ? 'Yes' : 'No' : missingField,
        additionalWithholdings: item.addWithHoldings || missingField,
        otherWithholdings: item.otherWithHoldings || missingField,
        effectiveDate: item.effectiveDate ? moment(item.effectiveDate).format('MM/DD/YYYY') : missingField,
        isSelected: false,
      };
    })
    : [];
};

export const newTravelerPayrollRow = (data: ITaxData[]) => {
  return {
    rowID: data.length ? data.length + 1 : 1,
    type: missingField,
    maritialStatus: missingField,
    allowances: missingField,
    exemptStatus: missingField,
    additionalWithholdings: missingField,
    otherWithholdings: missingField,
    effectiveDate:  missingField,
    isSelected: false,
  };
};

export const valCharLimit = (str: any, limit: number) => {
  return str?.length > 0 && str?.length <= limit;
};

export const valNumeric = (str: any) => {
  const regEx = /^\d{0,4}$/;
  return str.match(regEx);
};

export const valNumericStartsWithSymbol = (str: any) => {
  const regEx = /^[$%](0|[1-9][0-9]*)$/;
  return str.match(regEx);
};

export const updateRowData = (row: any, value, maritalStatusOptions: any) => {
  if (value) {
    const option = maritalStatusOptions.find(x => x.description === value);
    if (option) {
      row['maritialStatus'] = option.description;
      row['maritalStatusId'] = option.id;
    }
  }
  return row;
}

export const getTravelerPayrollColumns = (props: { updateRow: (rowID, params) => void , hasAddEditAccess: boolean, maritalStatusOptions: any[]}) => {
  const headers: GridColDef[] = [
    {
      field: 'type',
      headerName: 'Type',
      disableColumnMenu: true,
      sortable: false,
      valueFormatter: param => param.value ?? missingField,
      width: 125,
      editable:props.hasAddEditAccess,
      renderCell: GridTextboxCell({
        placeholder: 'Enter Type',
        type:'text',
        editOnSingleClick: true,
        disableCellEdit: ({ value, params }) => !props.hasAddEditAccess,
        mandatory: ({ value, params }) => true,
        validator: ({ value, params }) => {
          return valCharLimit(value, 255);
        },
        onEndEdit: ({ value, params }) => {
          if (props.hasAddEditAccess) {
            if (valCharLimit(value, 255) && params.row[params.field] !== value) {
              params.row[params.field] = value;
              params.row = updateRowData(params.row, params.row['maritialStatus'], props.maritalStatusOptions);
              props.updateRow(params.row.rowID, params.row);
            }
          }
        },
      }),
    },
    {
      field: 'maritialStatus',
      headerName: 'Maritial Status',
      sortable: false,
      valueFormatter: param => param.value ?? missingField,
      width: 175,
      renderCell: GridDropdownCell({
        placeholder: 'Enter Maritial Status',
        editOnSingleClick: true,
        mandatory: false,
        disableCellEdit: !props.hasAddEditAccess,
        maritialOptions: props.maritalStatusOptions,
        onEndEdit: ({ value, params }) => {
          if (props.hasAddEditAccess) {
            if (params.row[params.field] !== value) {
              params.row = updateRowData(params.row, value, props.maritalStatusOptions);
              props.updateRow(params.row.rowID, params.row);
            }
          }
        }
      }),
    },
    {
      field: 'allowances',
      headerName: 'Allowances',
      sortable: false,
      valueFormatter: param => param.value ?? missingField,
      width: 150,
      editable:props.hasAddEditAccess,
      renderCell: GridTextboxCell({
        placeholder: 'Enter Allowances',
        type:'number',
        editOnSingleClick: true,
        disableCellEdit: ({ value, params }) => !props.hasAddEditAccess,
        validator: ({ value, params }) => {
          if (value)
            return valCharLimit(value, 4);
          else
            return true;
        },
        onEndEdit: ({ value, params }) => {
          if (props.hasAddEditAccess) {
            if (valNumeric(value) && valCharLimit(value, 4) && params.row[params.field] !== value) {
              params.row[params.field] = value;
              params.row = updateRowData(params.row, params.row['maritialStatus'], props.maritalStatusOptions);
              props.updateRow(params.row.rowID, params.row);
            }
          }
        },
        externalValidator: ({ value, params }) => value !== missingField && !valNumeric(value) ? 'Character Limit is 4 digit value!' : ''        
      }),
    },
    {
      field: 'exemptStatus',
      headerName: 'Exempt Status',
      sortable: false,
      valueFormatter: param => param.value ?? missingField,
      width: 175,   
      editable:props.hasAddEditAccess,   
      renderCell: GridDropdownCell({
        placeholder: 'Enter Exempt Status',
        editOnSingleClick: true,
        disableCellEdit: !props.hasAddEditAccess,
        mandatory: false,
        onEndEdit: ({ value, params }) => {
          if (props.hasAddEditAccess) {
            if (!!value && params.row[params.field] !== value) {
              params.row[params.field] = value;
              params.row = updateRowData(params.row, params.row['maritialStatus'], props.maritalStatusOptions);
              props.updateRow(params.row.rowID, params.row);
            }
          }
        },
        validator: ({ value, params }) => {
          return !!value;
        },
      }),
    },
    {
      field: 'additionalWithholdings',
      headerName: 'Additional Withholdings',
      sortable: false,
      valueFormatter: param => param.value ?? missingField,
      width: 175,
      editable:props.hasAddEditAccess,
      renderCell: GridTextboxCell({
        placeholder: 'Enter Additional Withholdings',
        editOnSingleClick: true,
        type:'text',
        disableCellEdit: ({ value, params }) => !props.hasAddEditAccess,
        validator: ({ value, params }) => {
          if (value)
            return valNumericStartsWithSymbol(value);
          else
            return true;          
        },
        onEndEdit: ({ value, params }) => {
          if (props.hasAddEditAccess) {
            if (valNumericStartsWithSymbol(value) && params.row[params.field] !== value) {
              params.row[params.field] = value;
              params.row = updateRowData(params.row, params.row['maritialStatus'], props.maritalStatusOptions);
              props.updateRow(params.row.rowID, params.row);
            }
          }
        },
        externalValidator: ({ value, params }) =>  value !== missingField && !valNumericStartsWithSymbol(value) ? 'Invalid Format' : ''
      }),
    },
    {
      field: 'otherWithholdings',
      headerName: 'Other Withholdings',
      sortable: false,
      valueFormatter: param => param.value ?? missingField,
      width: 150,
      editable:props.hasAddEditAccess,
      renderCell: GridTextboxCell({
        placeholder: 'Enter other Withholdings',
        type:'text',
        editOnSingleClick: true,
        disableCellEdit: ({ value, params }) => !props.hasAddEditAccess,
        validator: ({ value, params }) => {
          if (value)
            return valNumericStartsWithSymbol(value);
          else
            return true; 
        },
        onEndEdit: ({ value, params }) => {
          if (props.hasAddEditAccess) {
            if (valNumericStartsWithSymbol(value) && params.row[params.field] !== value) {
              params.row[params.field] = value;
              params.row = updateRowData(params.row, params.row['maritialStatus'], props.maritalStatusOptions);
              props.updateRow(params.row.rowID, params.row);
            }
          }
        },
        externalValidator: ({ value, params }) =>  value !== missingField && !valNumericStartsWithSymbol(value) ? 'Invalid Format' : ''
      }),
    },
    {
      field: 'effectiveDate',
      headerName: 'Effective Date',
      sortable: false,
      valueFormatter: param => param.value ?? missingField,
      width: 150,
      editable:props.hasAddEditAccess,
      renderCell: GridDateCell({
        placeholder: 'Effective Date',
        editOnSingleClick: true,
        disableCellEdit: ({ value, params }) => !props.hasAddEditAccess,
        mandatory: ({ value, params }) => true,
        validator: ({ value, params }) => {
          if (value)
            return true;
          else
            return false; 
        },
        onEndEdit: ({ value, params }) => {
          if (props.hasAddEditAccess && params.row[params.field] !== value) {
            params.row[params.field] = !value ? null : moment(value).format('MM/DD/YYYY');
            params.row = updateRowData(params.row, params.row['maritialStatus'], props.maritalStatusOptions);
            props.updateRow(params.row.rowID, params.row);
          }
        },
        tooltip: ({ value, params }) => !value ? 'Effective Date is required' : ''
      }),
    },
  ];
  return headers;
};