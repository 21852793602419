import { Divider, Grid, Typography } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FundamentalInfo } from './FundamentalInfo';
import { Skillset } from './Skillset';
import { ShiftInfo } from './ShiftInfo';
import { SelectScreen } from './Edit/SelectScreen';
import { SelectSkillset } from './Edit/SelectSkillset';
import { useSelector } from 'react-redux';
import {
  selectMiscellaneousData,
  selectNewPlacementChoice,
  selectScreenSelectionEnabled,
} from 'store/redux-store/new-placement/selectors';

export const OrderSection = () => {
  const { t } = useTranslation();
  const { order } = useSelector(selectNewPlacementChoice);
  const screenSelectionEnabled = useSelector(selectScreenSelectionEnabled);
  const { orderSkillOpts } = useSelector(selectMiscellaneousData);

  return (
    <Grid container flexDirection="column" spacing={2}>
      <Grid item>
        <Typography variant="subtitle2" color="charcoal">
          {t('Order Information')}
        </Typography>
      </Grid>
      <Grid item>
        <FundamentalInfo {...order} />
      </Grid>
      <Grid item sx={{ maxHeight: '500px', overflow: 'auto', paddingBottom: '24px' }}>
        <Grid container flexDirection="column" spacing={2}>
          <Grid item>
            <Skillset skills={order?.groupedSkillsets} />
          </Grid>
          <Grid item>
            <ShiftInfo {...order} />
          </Grid>
          {orderSkillOpts.length > 1 ? (
            <>
              <Grid item>
                <Divider variant="wideMargin" orientation="horizontal" />
              </Grid>
              <Grid item>
                <SelectSkillset options={orderSkillOpts} />
              </Grid>
            </>
          ) : null}
          {screenSelectionEnabled ? (
            <>
              <Grid item>
                <Divider variant="wideMargin" orientation="horizontal" />
              </Grid>
              <Grid item sx={{ marginRight: '24px' }}>
                <SelectScreen />
              </Grid>
            </>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};
