import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, Popper, FormControl, Grid, Typography, IconButton } from 'amn-ui-core';
import { useSelector } from 'react-redux';
import { candidateDetailsSelection } from '../../Profile/CandidateDetails.selector';
import { lookupSelector } from 'store/redux-store/lookup/lookup.selectors';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { DateRangePicker } from 'app/ComponentLibrary/DateRangeNew';
import { addDays } from 'date-fns';
import moment from 'moment';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { LicenseAndCertificationDelete } from './LicenseAndCertificationDelete';
import { HearsayFields } from './Cards/helper';
import { useCredentialsAddHearsayStyles } from './style';
import { CredentialsAddHearsayProps } from './types';
import { filterOptions, getIssuedByTypes } from './helper';

export const CredentialsAddHearsay = (props: CredentialsAddHearsayProps) => {
  const {
    onChangeHearsayCertification,
    credentialingAllLookups,
    selectedHearsayCertMetaData,
    deleteHearsayCertification,
  } = props;
  const { t } = useTranslation();
  const { classes } = useCredentialsAddHearsayStyles();
  const lookups = useSelector(lookupSelector);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const [certificationValue, setCertificationValue] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [issuedByOpen, setIssuedByOpen] = useState<boolean>(false);
  const [issuedByValue, setIssuedByValue] = useState<string>('');
  const { control, errors, getValues, setValue, clearErrors } = useFormContext();
  const [issuedByOptions, setIssuedByOptions] = useState<any[]>([]);
  const [expirationDateValue, setExpirationDateValue] = useState<any>();
  const date = new Date();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);

  useEffect(() => {
    if (selectedHearsayCertMetaData) {
      let issuedOptions = [];

      if (selectedHearsayCertMetaData.certificationName) {
        const data = lookups?.hearsayCertifications?.find(
          item => item.name.split(' - ')[0].trim() === selectedHearsayCertMetaData.certificationName,
        );
        onChangeHearsayCertification(HearsayFields.Certification, data ? data : null);
        setCertificationValue(selectedHearsayCertMetaData.certificationName);
        issuedOptions = getIssuedByTypes(
          data && data.value ? data.value : undefined,
          lookups,
          credentialingAllLookups,
          setIssuedByOptions,
        );
      }
      if (selectedHearsayCertMetaData.issuedBy) {
        const data = issuedOptions.find(item => item.description === selectedHearsayCertMetaData.issuedBy);
        onChangeHearsayCertification(HearsayFields.IssuedBy, data ? data : null);
        setIssuedByValue(selectedHearsayCertMetaData.issuedBy);
      }
      if (selectedHearsayCertMetaData.expirationDate) {
        handleExpirationDateChange(new Date(selectedHearsayCertMetaData.expirationDate));
      }
    }
  }, []);

  const PopperComponent = props => (
    <Popper {...props} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)} />
  );

  const PopperComponentIssuedBy = props => (
    <Popper {...props} onMouseEnter={() => setIssuedByOpen(true)} onMouseLeave={() => setIssuedByOpen(false)} />
  );

  const hearsayOptions: string[] = lookups?.hearsayCertifications.map(item => {
    return item.name;
  });

  const onInputChange = (event, newInputValue: string) => {
    if (newInputValue !== '') setOpen(true);
    else setOpen(false);
    clearErrors('pState');
    const data = lookups?.hearsayCertifications?.find(item => item.name === newInputValue);
    onChangeHearsayCertification(HearsayFields.Certification, data ? data : null);
    setCertificationValue(newInputValue);
    onChangeHearsayCertification(HearsayFields.IssuedBy, null);
    setIssuedByValue(null);
    getIssuedByTypes(data && data.value ? data.value : undefined, lookups, credentialingAllLookups, setIssuedByOptions);
  };

  const handleExpirationDateChange = event => {
    setValue('pExpirationDate', event, { shouldDirty: true });
    clearErrors('pExpirationDate');
    const data = event;
    onChangeHearsayCertification(HearsayFields.ExpirationDate, data ? data : null);
    setExpirationDateValue(data ? moment(data).format('MM/DD/YYYY') : null);
  };

  const onCertificationInputChange = (event, newInputValue) => {
    if (newInputValue) {
      const data = lookups?.hearsayCertifications?.find(item => item.name === newInputValue);
      onChangeHearsayCertification(HearsayFields.Certification, data ? data : null);
      setCertificationValue(newInputValue);
      setOpen(false);
    }
  };

  const onIssuedByInputChange = (event, newInputValue: string) => {
    if (newInputValue !== '') setIssuedByOpen(true);
    else setIssuedByOpen(false);

    const data = issuedByOptions?.find(item => item.description === newInputValue);
    onChangeHearsayCertification(HearsayFields.IssuedBy, data ? data : null);
    setIssuedByValue(newInputValue);
  };

  const onIssuedByChange = (event, newInputValue) => {
    if (newInputValue) {
      const data = issuedByOptions?.find(item => item.description === newInputValue);
      onChangeHearsayCertification(HearsayFields.IssuedBy, data ? data : null);
      setIssuedByValue(newInputValue);
      setIssuedByOpen(false);
    }
  };

  const issuedByFilterOptions = (options, state) => {
    const newOptions: any = [];
    const inputValue = state.inputValue ? state.inputValue : issuedByValue ? issuedByValue : '';

    options.forEach(element => {
      if (element.toLowerCase().includes(inputValue?.toLowerCase())) newOptions.push(element);
    });
    return newOptions;
  };

  const getOptionDisabled = (option: string) => {
    return (
      candidateDetails?.hearsayCertifications?.filter(item => {
        const optionAbbr = option.split(' - ')?.[0];
        return optionAbbr === item.certificationName;
      })?.length > 0
    );
  };

  return (
    <Grid container direction="column" spacing={3}>
      {selectedHearsayCertMetaData && (
        <Grid item paddingTop="3px !important" mb="-12px">
          <Grid container display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography fontSize="14px">{t('candidate.qualificationsCard.unverifiedCertification')}</Typography>
            </Grid>
            <Grid
              item
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              onClick={() => setOpenDeleteDialog(true)}
            >
              <IconButton color="secondary" size="medium" classes={{ root: classes.deleteIcon }}>
                <DeleteOutlinedIcon sx={{ mr: '3px' }} />
                <Typography fontWeight="600" fontSize="12px !important" mr="-8px" classes={classes.deleteText}>
                  {t('candidate.qualificationsCard.delete')}
                </Typography>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item>
        <FormControl variant="filled" style={{ width: '100%' }}>
          <Controller
            render={({ ref, value, onChange, ...rest }) => (
              <Autocomplete
                open={open}
                disabled={
                  selectedHearsayCertMetaData &&
                  selectedHearsayCertMetaData.travelerHearsayCertificationId &&
                  selectedHearsayCertMetaData.travelerHearsayCertificationId > 0
                }
                onMouseEnter={() => {
                  if (!selectedHearsayCertMetaData) {
                    setOpen(true);
                  }
                }}
                onMouseLeave={() => setOpen(false)}
                renderInput={params => (
                  <TextField
                    label={t('candidate.qualificationsCard.certificationLabel')}
                    placeholder={t('candidate.qualificationsCard.certificationLabel')}
                    variant="filled"
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      classes: { input: classes.textInput },
                    }}
                  />
                )}
                onInputChange={onInputChange}
                onChange={onCertificationInputChange}
                options={hearsayOptions || []}
                filterOptions={filterOptions}
                clearIcon={false}
                popupIcon={false}
                value={certificationValue?.length ? certificationValue : ''}
                inputValue={certificationValue?.length ? certificationValue : ''}
                PopperComponent={PopperComponent}
                getOptionDisabled={getOptionDisabled}
              ></Autocomplete>
            )}
            control={control}
            name={'pHearsayCertification'}
            error={!!errors['pHearsayCertification']}
            helperText={
              errors['pHearsayCertification']
                ? errors['pHearsayCertification']?.type === 'required'
                  ? 'Required'
                  : ''
                : ''
            }
            rules={{
              required: true,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item>
        <Controller
          render={({ ref, value, onChange, ...rest }) => (
            <Autocomplete
              open={issuedByOpen}
              onMouseEnter={() => setIssuedByOpen(true)}
              onMouseLeave={() => setIssuedByOpen(false)}
              renderInput={params => (
                <TextField
                  label={t('candidate.qualificationsCard.certificationIssuedBy')}
                  placeholder={t('candidate.qualificationsCard.certificationIssuedBy')}
                  variant="filled"
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    classes: { input: classes.textInput },
                  }}
                />
              )}
              popupIcon={false}
              onInputChange={onIssuedByInputChange}
              onChange={onIssuedByChange}
              options={
                issuedByOptions.map(x => {
                  return x.description;
                }) || []
              }
              filterOptions={issuedByFilterOptions}
              clearIcon={false}
              value={issuedByValue?.length ? issuedByValue : ''}
              inputValue={issuedByValue?.length ? issuedByValue : ''}
              PopperComponent={PopperComponentIssuedBy}
            ></Autocomplete>
          )}
          control={control}
          name={'pIssuedBy'}
          error={!!errors['pIssuedBy']}
          helperText={errors['pIssuedBy'] ? (errors['pIssuedBy']?.type === 'required' ? 'Required' : '') : ''}
        />
      </Grid>
      <Grid item width="50%">
        <Controller
          name={'pExpirationDate'}
          control={control}
          defaultValue={expirationDateValue}
          render={({ ref, value, onChange, ...rest }) => (
            <>
              <DateRangePicker
                onChange={event => {
                  handleExpirationDateChange(event?.startDate?.toString() || null);
                }}
                actionBar={false}
                single
                variant="filled"
                placeholder={t('candidate.qualificationsCard.expirationDate')}
                clickAway
                keepClickAwayVal={true}
                value={expirationDateValue}
                className={classes.datePickerInput}
                maxWidth={300}
                fullWidth
                showIcon={true}
                isDateIconReq={true}
                trailingIconShow={true}
                initialDateRange={expirationDateValue}
                minDate={new Date('01-01-1940')}
                error={!!errors.pExpirationDate}
                definedRanges={[
                  {
                    label: '1 Day',
                    startDate: addDays(date, 1),
                    endDate: addDays(date, 1),
                    useLabelAsValue: false,
                  },
                  {
                    label: '2 Days',
                    startDate: addDays(date, 2),
                    endDate: addDays(date, 2),
                    useLabelAsValue: false,
                  },
                  {
                    label: '7 Days',
                    startDate: addDays(date, 7),
                    endDate: addDays(date, 7),
                    useLabelAsValue: false,
                  },
                  {
                    label: '20 Days',
                    startDate: addDays(date, 20),
                    endDate: addDays(date, 20),
                    useLabelAsValue: false,
                  },
                  {
                    label: '30 Days',
                    startDate: addDays(date, 30),
                    endDate: addDays(date, 30),
                    useLabelAsValue: false,
                  },
                  {
                    label: '60 Days',
                    startDate: addDays(date, 60),
                    endDate: addDays(date, 60),
                    useLabelAsValue: false,
                  },
                ]}
                {...rest}
              ></DateRangePicker>
            </>
          )}
        />
      </Grid>
      <LicenseAndCertificationDelete
        openDeleteDialog={openDeleteDialog}
        onDeleteDialogClose={() => setOpenDeleteDialog(false)}
        deleteHearsayLicense={() => {
          setOpenDeleteDialog(false), deleteHearsayCertification();
        }}
        type={'Certification'}
      />
    </Grid>
  );
};
