import { CircularProgress, Theme } from 'amn-ui-core';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';
import { Cancel } from 'app/components/Common/CancelModal/Cancel';
import { useDispatch, useSelector } from 'react-redux';
import {
  candidateDetailsSelection,
} from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { selectUser } from 'oidc/user.selectors';
import { useTranslation } from 'react-i18next';
import { PreferencesEditContent } from './PreferencesEditContent';
import { IPreferenceGridRow, defaultValues, formatAdultsData, formatChildrenData, formatPetsData, formatTypeAhead } from './Helpers/PreferencesHelper';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import moment from 'moment';
import { ICandidatePreferences } from 'app/models/Candidate/CandidateProfile';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContainer: {
    minWidth: '960px',
  },
  dialogContent: {
    padding: 0,
    overflow: 'hidden',
  },
  dialogTitleProps: {
    height: '50px',
    padding: '10px 20px',
  },
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50vh',
  },
}));

export const PreferencesEditModal = props => {
  const { open, handleClose } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cancel, setCancel] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const user = useSelector(selectUser);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const [gridRows, setGridRows] = useState<IPreferenceGridRow>({
    adults: [],
    children: [],
    pets: [],
  });
  const formMethods = useForm({ mode: 'all', defaultValues: defaultValues });
  const {
    handleSubmit,
    formState: { dirtyFields, errors, touched },
  } = formMethods;

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    const payload: ICandidatePreferences = {
      travelerId: candidateDetails.travelerId,
      housingInfoUpdated: moment().format('YYYY-MM-DDTHH:mm:ss'),
      travelerKeyFactsUpdated: moment().format('YYYY-MM-DDTHH:mm:ss'),
      isVerifiedNoAdults: gridRows.adults.filter(item => item.rowID === 0 && item.isSelected).length > 0,
      isVerifiedNoChildren: gridRows.children.filter(item => item.rowID === 0 && item.isSelected).length > 0,
      isVerifiedNoPets: gridRows.pets.filter(item => item.rowID === 0 && item.isSelected).length > 0,
      travelsWithUpdated: moment().format('YYYY-MM-DDTHH:mm:ss'),
      housingType: data.housingType ? data.housingType.label : null,
      housingTypeId: data.housingType ? data.housingType.object.ID : null,
      currentEmployeeId: user.userInfo.employeeId,
      currentEmployeeName: `${user.userInfo.firstName} ${user.userInfo.lastName}`,
      furnitureExtras: formatTypeAhead(data.furnitureExtras),
      typeOfBeds: formatTypeAhead(data.typeOfBed),
      keyFacts: formatTypeAhead(data.keyFacts),
      adults: formatAdultsData(gridRows.adults, candidateDetails.preferences.adults),
      children: formatChildrenData(gridRows.children, candidateDetails.preferences.children),
      pets: formatPetsData(gridRows.pets, candidateDetails.preferences.pets),
    };
    dispatch(candidateDetailActions.updatePreferencesData(payload));
    handleClose();
    setIsLoading(false);
  };

  const onError = (data, e) => {};

  const handleCancel = () => {
    if (!_.isEmpty(errors) || !_.isEmpty(dirtyFields) || !_.isEmpty(touched)) {
      setCancel(true);
    } else {
      yesAction();
    }
  };
  const yesAction = () => {
    handleClose();
    setCancel(false);
  };
  const noAction = () => {
    setCancel(false);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        {isLoading ? (
          <CircularProgress className={classes.MuiCircularProgressRoot} />
        ) : (
          <GenericDialog
            variant={'blue'}
            classes={{ paper: classes.dialogContainer }}
            open={open}
            disablePortal
            maxWidth="md"
            disableEscapeKeyDown
            dialogTitleProps={{
              classes: { root: classes.dialogTitleProps },
              text: t('candidate.preferenceCard.editPreferences'),
              closeButton: true,
              expandable: true,
            }}
            dialogActions={[
              {
                onClick: handleCancel,
                text: t('candidate.preferenceCard.cancel'),
                variant: 'contained',
                color: 'tertiary',
              },
              {
                text: t('candidate.preferenceCard.save'),
                variant: 'contained',
                type: 'submit',
                disabled: false,
              },
            ]}
            dialogContentProps={{
              classes: { root: classes.dialogContent },
            }}
            onClose={handleCancel}
          >
            <PreferencesEditContent 
              gridRows={gridRows}
              setGridRows={setGridRows}
            />
          </GenericDialog>
        )}
        {cancel && <Cancel openDialog={cancel} handleConfirmAction={yesAction} handleCancelAction={noAction} />}
      </form>
    </FormProvider>
  );
};
