import { DateOfBirth } from 'app/components/Placement/PlacementDetails/Edit/Components/DateOfBirth';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectEditableRequirementTypes } from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { TransportationRow } from './TransportationTable';

export const TransportationDateOfBirth = () => {
  const { t } = useTranslation();
  const { dob } = useSelector(selectEditableRequirementTypes);
  return (
    <TransportationRow
      id="placement-offer-transportation-dob"
      label={t('placement.profile.summary.offer.transportation.dateOfBirth')}
      controlComponent={<DateOfBirth />}
      requirement={dob?.requirement}
    />
  );
};
