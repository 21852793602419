import { GridTag, IGridState, gridStateActions } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { selectFilters, selectGridState } from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import { XGrid } from 'app/components/Common/XGrid/XGrid';
import { getHomePageSupportComponentHeight } from 'app/layout/pages/HomePage';
import { getCoverageRequestColumns } from './requestUtils';
import GroupAddIcon from '@mui/icons-material/GroupAddOutlined';
import GroupRemoveIcon from '@mui/icons-material/GroupRemoveOutlined';
import { coverageActions } from 'store/redux-store/user-coverage/async-actions';
import {
  selectCoverageCreatedId,
  selectCoverageUpdatedId,
  selectDenyCoverageId,
  selectRequestsGridData,
} from 'store/redux-store/user-coverage/selectors';
import { AssignCoverage } from '../../CoverageModal/AssignCoverage';
import { ISortModel, checkRequiredFilters, coverageColumnVisibilityModel, formatFilterAttributes } from './gridHelper';
import { IRequestCoverageProp } from 'app/models/UserCoverage/UserCoverage';
import { GridCallbackDetails, GridSortModel } from '@mui/x-data-grid';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { usePromiseTracker } from 'react-promise-tracker';
import { SearchRecommendation } from '@AMIEWEB/Tasks/TaskManagement/SearchRecommendation';
import { SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import { GridApi, GridRowParams, GridSelectionModel } from '@mui/x-data-grid-pro';
import _, { isEmpty } from 'lodash';
import { formatCoverageDetails, retrieveAssignDetails } from '../../Transformer';
import { DenyCoverageModal } from '../../CoverageModal/DenyCoverageModal';

export const CoverageRequestGrid = ({ workDeskDomain = null }: { workDeskDomain: string }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const apiRef = React.useRef<GridApi>();
  const filterAttributes = useSelector(selectFilters);
  const coverageData = useSelector(selectRequestsGridData);
  const gridState = useSelector(selectGridState);
  const coverageCreatedId = useSelector(selectCoverageCreatedId);
  const coverageUpdatedId = useSelector(selectCoverageUpdatedId);
  const deniedCoverageList = useSelector(selectDenyCoverageId);
  const { promiseInProgress: isLoading } = usePromiseTracker({ area: 'fetch-coverage-requests', delay: 0 });
  const { promiseInProgress: isDenyCoverageLoading } = usePromiseTracker({ area: 'deny-coverage-requests', delay: 0 });

  const gridStateRef = useRef<IGridState>(gridState);
  gridStateRef.current = gridState;
  const defaultSortModel: ISortModel[] = [{ field: 'createdDate', sort: 'desc' }];
  const defaultColumnVisibilityModel = coverageColumnVisibilityModel;
  const tableColumns = getCoverageRequestColumns(t);

  const [tableData, setTableData] = useState<any[]>([]);
  const [gridHeight, setGridHeight] = React.useState<number | undefined>(45);
  const [searchSuggestion, setSearchSuggestion] = useState<any>();
  const [resetGridButton, setResetGridButton] = React.useState<boolean>(false);
  const [currentSortModel, setCurrentSortModel] = React.useState<ISortModel[]>(defaultSortModel);
  const [currentColumnVisibilityModel, setCurrentColumnVisibilityModel] = React.useState<any>(
    _.cloneDeep(defaultColumnVisibilityModel),
  );
  const [openAssignCoverage, setOpenAssignCoverage] = React.useState<boolean>(false);
  const [selectedCoverageData, setSelectedCoverageData] = useState<any>(null);
  const [displayBackDrop, setBackDrop] = React.useState<boolean>(false);
  const [openDenyCoverageModal, setOpenDenyCoverageModal] = React.useState<boolean>(false);
  const [coverageSelectionModel, setCoverageSelectionModel] = React.useState<any[]>([]);

  const updateGridHeight = () => {
    const a = document.getElementById('home-page-paper')?.offsetHeight;
    const b = getHomePageSupportComponentHeight();
    if (a && b) {
      // -100 to account for title
      setGridHeight(a - b - 100);
    } else {
      setGridHeight(400);
    }
  };

  const resizeGrid = React.useCallback(event => {
    updateGridHeight();
  }, []);

  React.useEffect(() => {
    updateGridHeight();
    window.addEventListener('resize', resizeGrid, true);
    return () => {
      window.removeEventListener('resize', resizeGrid);
    };
  }, [coverageData, resizeGrid]);

  const fetchData = (pageNumber, pageSize, sortModel) => {
    const filterData = formatFilterAttributes(filterAttributes);
    const fetchPayload: IRequestCoverageProp = {
      ...filterData,
      pageNumber: pageNumber > 0 ? pageNumber : 1,
      pageSize: pageSize,
      sortedColumn: sortModel,
      statusIds: [4],
    };
    if (checkRequiredFilters(fetchPayload)) {
      dispatch(coverageActions.getCoverageRequestList(fetchPayload));
      setSearchSuggestion(undefined);
      dispatch(globalActions.closeBanner());
    } else {
      dispatch(
        globalActions.setBanner({
          message: t('search.globalSearch.coverage.requiredFilterBannerMessage'),
          severity: 'warning',
          justify: 'center',
        }),
      );
      setTableData([]);
      dispatch(coverageActions.setCoverageRequestGridData({ ...coverageData, coverageRequests: [], totalCount: 0 }));
      setSearchSuggestion(<SearchRecommendation entity={SearchType.coverage} />);
    }
  };

  useEffect(() => {
    if (coverageData?.coverageRequests) {
      apiRef?.current?.setSelectionModel([]);
      setTableData(coverageData?.coverageRequests);
    }
  }, [coverageData]);

  useEffect(() => {
    if (filterAttributes) {
      const pagination = apiRef?.current?.state?.pagination;
      fetchData(pagination.page, pagination.pageSize, gridState?.sortedColumn ?? currentSortModel[0]);
    }
  }, [filterAttributes]);

  useEffect(() => {
    if (coverageCreatedId || coverageUpdatedId) {
      const pagination = apiRef?.current?.state?.pagination;
      fetchData(pagination.page, pagination.pageSize, gridState?.sortedColumn ?? currentSortModel[0]);
    }
  }, [coverageCreatedId, coverageUpdatedId]);

  useEffect(() => {
    if (deniedCoverageList?.length > 0) {
      const successDeniedCoverage = deniedCoverageList?.map(coverage => {
        if (coverage?.success === true) {
          return coverage;
        }
      });
      dispatch(
        globalActions.setSnackBar({
          message: t('search.globalSearch.coverage.snackBarMessage', {
            successDeniedCoverageCount: successDeniedCoverage?.length,
            coverageSelectionModelCount: deniedCoverageList?.length,
          }),
          severity: 'success',
        }),
      );
      dispatch(coverageActions.setDenyCoverageList([]));
      if (filterAttributes) {
        const pagination = apiRef?.current?.state?.pagination;
        fetchData(pagination.page, pagination.pageSize, gridState?.sortedColumn ?? currentSortModel[0]);
      }
      setCoverageSelectionModel([]);
    }
  }, [deniedCoverageList]);

  useEffect(() => {
    if (selectedCoverageData) {
      setBackDrop(false);
      setOpenAssignCoverage(true);
    }
  }, [selectedCoverageData]);

  useEffect(() => {
    dispatch(
      gridStateActions.setGridState({
        gridTag: GridTag.CoverageRequestsGrid,
        pageSize: 50,
        pageNumber: 1,
        previewItemId: -1,
        showPreviewDialog: false,
        sortedColumn: { column: 'createdDate', direction: 'desc' },
      }),
    );
  }, []);

  const leftActions = React.useMemo(() => {
    const addCoverageDisabled = !isEmpty(coverageSelectionModel);
    return gridState.gridTag === 'CoverageRequestsGrid'
      ? [
          {
            title: t('home.coverageWorkDesk.tabs.request.assignBtnLabel'),
            Icon: GroupAddIcon,
            disabled: addCoverageDisabled,
            onClick: () => {
              setOpenAssignCoverage(true);
            },
            enableReadOnly: true,
            tooltipProps: {
              tooltipContent: 'Click to begin',
              disabled: true,
            },
          },
        ]
      : undefined;
  }, [gridState?.gridTag, coverageSelectionModel]);
  {
  }
  const RightActions = React.useMemo(() => {
    const denyCoverageDisabled = isEmpty(coverageSelectionModel);
    return gridState.gridTag === 'CoverageRequestsGrid'
      ? [
          {
            Icon: GroupRemoveIcon,
            disabled: denyCoverageDisabled,
            onClick: () => {
              setOpenDenyCoverageModal(true);
            },
            enableReadOnly: true,
            tooltipProps: {
              tooltipContent: t('home.coverageWorkDesk.tabs.request.denyCoverageBtnLabel'),
              disabled: denyCoverageDisabled,
            },
          },
        ]
      : undefined;
  }, [gridState?.gridTag, coverageSelectionModel]);

  const onPageChange = React.useCallback(
    (page: number, details: GridCallbackDetails) => {
      const pagination = apiRef?.current?.state?.pagination;
      if (pagination) {
        fetchData(page + 1, pagination.pageSize, gridState?.sortedColumn ?? currentSortModel[0]);
      }
    },
    [gridState.sortedColumn, gridState.pageSize, fetchData],
  );

  const onPageSizeChange = React.useCallback(
    (pageSize: number, details: GridCallbackDetails) => {
      const pagination = apiRef?.current?.state?.pagination;
      if (pagination) {
        fetchData(1, pageSize, gridState?.sortedColumn ?? currentSortModel[0]);
      }
    },
    [gridState.pageSize, gridState.sortedColumn, fetchData],
  );

  const onSortModelChanged = React.useCallback(
    (model: GridSortModel, details: GridCallbackDetails) => {
      const pagination = apiRef?.current?.state?.pagination;
      if (pagination) {
        fetchData(
          pagination.page + 1,
          pagination.pageSize,
          model?.length > 0
            ? {
                column: model[0].field,
                direction: model[0].sort,
              }
            : { column: 'createdDate', direction: 'desc' },
        );
      }
      setCurrentSortModel(model);
    },
    [gridState.pageNumber, gridState.pageSize, fetchData],
  );

  const onSelectionModelChange = (selectionModel: GridSelectionModel, details: GridCallbackDetails<any>) => {
    setCoverageSelectionModel(selectionModel);
  };

  const onRowSelected = async (params: GridRowParams) => {
    setBackDrop(true);
    let coveredByData = null;
    let coverageForData = null;
    await retrieveAssignDetails(params?.row?.coveredBy, params?.row?.coveredByEmployeeId)?.then(
      data => (coveredByData = data),
    );
    await retrieveAssignDetails(params?.row?.coverageFor, params?.row?.coverageForEmployeeId)?.then(
      data => (coverageForData = data),
    );
    setTimeout(() => {
      if (coveredByData && coverageForData) {
        const formattedCoverageDetails = formatCoverageDetails(params?.row, coveredByData, coverageForData);
        setSelectedCoverageData(formattedCoverageDetails);
      } else {
        setBackDrop(false);
        dispatch(
          globalActions.setSnackBar({
            message: t('common.failedLoad'),
            severity: 'error',
          }),
        );
      }
    }, 500);
  };

  const handleRefreshGrid = () => {
    const pagination = apiRef?.current?.state?.pagination;
    fetchData(pagination.page, pagination.pageSize, gridState?.sortedColumn ?? currentSortModel[0]);
  };

  const handleResetColumns = () => {
    const pagination = apiRef?.current?.state?.pagination;
    setCurrentColumnVisibilityModel(defaultColumnVisibilityModel);
    dispatch(gridStateActions.setSortedColumn({ column: 'createdDate', direction: 'desc' }));

    if (!(currentSortModel[0]?.field === 'createdDate' && currentSortModel[0]?.sort === 'desc')) {
      fetchData(pagination.page, pagination.pageSize, defaultSortModel[0]);
    }
  };

  return (
    <>
      <XGrid
        apiRef={apiRef}
        loading={isLoading || displayBackDrop || isDenyCoverageLoading}
        gridTag={GridTag.CoverageRequestsGrid}
        title={t('home.coverageWorkDesk.tabs.request.tableHeader')}
        columns={tableColumns}
        rows={tableData || []}
        autoHeight={false}
        height={gridHeight}
        rowsPerPageOptions={[50, 100, 150, 200, 250]}
        defaultRowsPerPage={gridState.pageSize || 50}
        pagination={true}
        paginationMode="server"
        sortingMode="server"
        disableSelectionOnClick
        checkboxSelection
        selectionModel={coverageSelectionModel}
        onSelectionModelChange={onSelectionModelChange}
        rowCount={coverageData && coverageData?.totalCount}
        onPageChange={onPageChange}
        onSortModelChange={onSortModelChanged}
        onPageSizeChange={onPageSizeChange}
        defaultSortModel={defaultSortModel}
        sortModel={currentSortModel}
        onRowClick={onRowSelected}
        columnVisibilityModel={currentColumnVisibilityModel}
        onColumnVisibilityModelChange={(model, details) => {
          setResetGridButton(true);
          setCurrentColumnVisibilityModel(_.cloneDeep(model));
        }}
        toolbarProps={{
          leftActions: leftActions,
          rightActions: RightActions,
          refreshButton: true,
          resetButton: resetGridButton,
          refreshGrid: () => {
            handleRefreshGrid();
          },
          resetColumns: () => {
            setResetGridButton(false);
            handleResetColumns();
            setCurrentSortModel(defaultSortModel);
            dispatch(
              globalActions.setSnackBar({
                message: 'Columns have been reset.',
                severity: 'success',
              }),
            );
          },
        }}
        components={{
          NoRowsOverlay: filterAttributes?.filters && searchSuggestion ? () => searchSuggestion : undefined,
        }}
      />
      <AssignCoverage
        open={openAssignCoverage}
        editCoverageData={selectedCoverageData}
        handleClose={() => {
          setSelectedCoverageData(null);
          setOpenAssignCoverage(false);
        }}
      />
      {openDenyCoverageModal && (
        <DenyCoverageModal
          openDialog={openDenyCoverageModal}
          setOpenDialog={setOpenDenyCoverageModal}
          selectedDenyCoverage={coverageSelectionModel}
        />
      )}
    </>
  );
};
