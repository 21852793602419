import CallOutlined from '@mui/icons-material/CallOutlined';
import { IconButton } from 'amn-ui-core';
import React from 'react';
import ClientContactCommunicationStack from './CommunicationStack';
import { ChannelType } from '@AMIEWEB/Notification/Constants';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips';
import { IFacilityClientContactsList } from 'app/models/Facility/FacilityDetails';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(theme => ({
  table: {
    borderCollapse: 'separate',
    borderSpacing: '8px',
  },
  icon: {
    fontSize: '22px',
  },
  tooltipContent: {
    fontSize: '14px',
    color: theme.palette.framework.system.tertiaryGrey,
  },
}));

interface IContactsGridTooltipProps {
  type: ChannelType;
  value: Array<string>;
  contact: IFacilityClientContactsList;
}

export const ContactsGridTooltip = (props: IContactsGridTooltipProps) => {
  const { value, type, contact } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  const PhoneTooltipEnabled = ({ data }) => (
    <CustomTooltip
      tooltipPlacementBottom
      disableMaxWidth={true}
      tooltipContent={
        <div>
          <table className={classes.table}>
            {data && (
              <ClientContactCommunicationStack
                phoneNumbers={data}
                channelType={ChannelType.voice}
                backDrop={flag => flag}
                contactDetails={contact}
              />
            )}
          </table>
        </div>
      }
    >
      <IconButton size="medium" color="secondary">
        <CallOutlined className={classes.icon} />
      </IconButton>
    </CustomTooltip>
  );

  const PhoneTooltipDisabled = (
    <CustomTooltip
      tooltipPlacementBottom
      disableMaxWidth={true}
      tooltipContent={<span className={classes.tooltipContent}>{t('clientContactModal.noNumber')}</span>}
    >
      <IconButton size="medium" color="secondary" disabled>
        <CallOutlined className={classes.icon} />
      </IconButton>
    </CustomTooltip>
  );

  if (type === ChannelType.voice) {
    return value && value.length > 0 ? (
      <div>
        <PhoneTooltipEnabled data={value} />
      </div>
    ) : (
      PhoneTooltipDisabled
    );
  }

  return <></>;
};
