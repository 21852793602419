import { Grid, Typography } from 'amn-ui-core';
import { XGrid } from 'app/components/Common/XGrid/XGrid';
import { makeStyles } from 'tss-react/mui';
import React from 'react';

const expandedDetailPanelStyles = makeStyles()({
  root: {
    padding: '20px',
  },
  gridItem: {
    paddingLeft: '30px',
    width: '100%',
  },
  expandedGrid: {
    //width: '31rem',
    border: 'none',
    borderTop: '1px solid #D5D5D5',
    fontSize: '14px',
    overflowX: 'scroll',
    '& .MuiDataGrid-row': {
      borderBottom: '1px solid #D5D5D5',
    },
    '& .MuiDataGrid-main': {
      minHeight: '110px',
    },
  },
});

export const VersionPanel = (props) => {
  const { classes } = expandedDetailPanelStyles();
  return (
    <Grid container direction="column" alignItems={'center'} className={classes.root}>
      <Grid item xs={12} alignSelf={'baseline'} className={classes.gridItem}>
        <Typography variant="subtitle1" style={{ paddingBottom: '7px', fontWeight: 500 }}>
          {`Versions (${props.versionCount})`}
        </Typography>
      </Grid>
      <Grid item className={classes.gridItem}>
        <XGrid
          title={props.title}
          rows={props.rows || []}
          columns={props.columns || []}
          getRowId={model => model.rowID}
          autoHeight={true}
          rowHeight={60}
          headerHeight={70}
          components={{
            Toolbar: null,
          }}
          className={classes.expandedGrid}
          hideFooter={true}
          disableColumnSelector
          disableMultipleSelection={true}
          disableSelectionOnClick={true}
          showColumnRightBorder={false}
        />
      </Grid>
    </Grid>
  );
}