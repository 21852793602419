import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(theme => ({
    container: {
      border: `1px solid ${theme.palette.system.paleGray}`,
      background: theme.palette.components.grid.content.backgroundColor,
      marginTop: '10px',
      borderRadius: '5px',
    },
    titleBar: {
      padding: '12px',
      marginLeft: '0px',
      marginTop: '0px',
      width: '100%',
      color: theme.palette.system.darkGray,
      fontSize: 16,
      fontWeight: 500,
      display: 'flex',
      justifyContent: 'space-between',
    },
    icon: {
      cursor: 'pointer !important',
      width: '28px',
      padding: '1px',
      color: theme.palette.system.neutralGray,
      '&:hover': {
        borderRadius: '50%',
        background: theme.palette.system.lightSteelBlue,
        color: theme.palette.system.navyBlue,
      },
    },
    onHover: {
      '&:hover': {
        color: theme.palette.system.navyBlue,
        background: theme.palette.system.dimFadedBlack,
      },
    },
    onHoverUnderline: {
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.system.navyBlue,
        textDecoration: 'underline',
      },
    },
    iconHovered: {
      color: theme.palette.framework.system.charcoal,
    },
    titleBarBorder: {
      background: theme.palette.system.offWhite,
      borderBottom: `1px solid ${theme.palette.system.paleGray}`,
    },
  }));