import React from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from 'tss-react/mui';

const useStyes = makeStyles()(theme => ({
  clickable: {
    cursor: 'default !important',
    color: '#0586db !important',
    fontWeight: 450,
  },
  inlineTable: {
    'font-size': '12px',
    'margin-left': '0.5rem',
    'margin-bottom': '0.5rem',
    display: 'table',
    '&.headOnlyTable': {
      'border-collapse': 'separate',
      'border-spacing': '0 0.5rem',
    },
    '& thead': {
      color: '#555555',
      'font-weight': '500',
    },
    '& tbody': {
      color: '#333333',
      'font-weight': '600',
    },
    '& svg': {
      'font-size': '1.3rem',
    },
  },
}));

export interface IColAttributes {
  title: string;
  heighlight?: boolean;
}

export interface IInlineTableWidget {
  customTableClass?: string;
  columns: IColAttributes[];
  rowData: any[][];
}

export const InlineTableWidget = (props: IInlineTableWidget) => {
  const { customTableClass, columns, rowData } = props;

  const { classes } = useStyes();

  return (
    <>
      <table className={`${classes.inlineTable} ${customTableClass}`}>
        <thead>
          {columns.map((col, index) => (
            <td key={index}>{col.title}</td>
          ))}
        </thead>
        <tbody>
          {rowData.map((row, index) => (
            <tr key={index}>
              {row.map((rowData, index1) => (
                <td key={index1}>
                  {columns[index1].heighlight ? <span className={classes.clickable}>{rowData}</span> : rowData}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
