import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { CoverageCard } from './CoverageCard';
import { selectActiveCoverage } from 'store/redux-store/user-coverage/selectors';
import { useTheme } from 'amn-ui-core'; 
const useStyles = makeStyles()(theme => ({
  subContainer: {
    display: 'grid',
  },
  field: {
    marginBottom: '12px',
    display: 'grid',
  },
}));

export const ActiveCoverage = () => {
  const theme = useTheme();
  const { classes } = useStyles();
  const activeCoverage = useSelector(selectActiveCoverage);

  return (
    <div id={'activeCoverageContainer'} className={classes.subContainer}>
      {activeCoverage?.map((request, index) => (
        <CoverageCard
          data={request}
          avatarColor={
            index % 3 === 0
              ? theme.palette.system.cyanBlue
              : index % 3 === 1
              ? theme.palette.system.lightSkyBlue
              : theme.palette.system.darkRed
          }
        />
      ))}
    </div>
  );
};
// theme.palette.system.lightSkyBlue
