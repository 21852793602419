import LinearProgress from '@mui/material/LinearProgress';
import { AppBar, Box, Button, IconButton, Typography } from 'amn-ui-core';
import styledComponent from 'styled-components';

export const ModalAppBar = styledComponent(AppBar)`
  width: 100%;
  border-radius: 0px 0px 0 0;
  background: #009ad9;
  padding-left: 20px;
  cursor: all-scroll;
  height: 40px;
  padding-bottom: 2%;
`;

export const ModalTitle = styledComponent(Typography)`
  flex-grow: 1;
  padding: 1%;
  color: #ffffff;
`;

export const ModalClose = styledComponent(IconButton)`
  padding-right: 1%;
  position: relative;
  float: right;
  color: #ffffff;
`;

export const NavigationButtons = styledComponent(Button)`
  margin: 0.5%;
  height: 4.5vh;
  color: #ffffff;
  background: #006fb9;
  padding-bottom: 1%;
`;

export const ProgressBar = styledComponent(LinearProgress)`
  width: 100%;
  height: 8px;
`;

export const Title = styledComponent(Typography)`
  text-align: left;
  font: normal normal medium 18px/24px Roboto;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  padding-left: 2%;
`;

export const TabBox = styledComponent(Box)`
  margin: 5%;
`;

export const ToolTipStyle = () => ({
  tooltipArrow: {
    color: '#fff',
    '&::before': {
      border: '1px solid #555555',
    },
  },
  tooltipBackground: {
    backgroundColor: '#fff',
    color: '#555555',
    border: '1px solid #555555',
    fontSize: '12px',
  },
});
