export enum CovidVaccinationStatus {
  BoosterEligible = 'Booster Eligible',
  Booster1Eligible = 'Booster 1 Eligible',
  Unknown = 'Unknown',
  DeclinationPersonal = 'Declination - Personal',
  DeclinationMedical = 'Declination - Medical',
  DeclinationReligious = 'Declination - Religious',
  DeclinationSubmittedPersonal = 'Declination Submitted - Personal',
  DeclinationSubmittedMedical = 'Declination Submitted - Medical',
  DeclinationSubmittedReligious = 'Declination Submitted - Religious',
  PartiallyVaccinated = 'Partially Vaccinated',
  DeclinationApprovedPersonal = 'Declination Approved - Personal',
  DeclinationApprovedMedical = 'Declination Approved - Medical',
  DeclinationApprovedReligious = 'Declination Approved - Religious',
  Booster2UptoDate = 'Booster 2/Up to Date',
  BoosterUptoDate = 'Booster/Up to Date',
  FullyVaccinated = 'Fully Vaccinated',
  Vaccinated = 'Vaccinated',
  VaccineInternational = 'Vaccine-International',
}
