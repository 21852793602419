import React, { useEffect, useState } from 'react';
import { TextField, Autocomplete, Popper, Grid, FormControl, Typography, IconButton } from 'amn-ui-core';
import { useSelector } from 'react-redux';
import { candidateDetailsSelection } from '../../Profile/CandidateDetails.selector';
import { lookupSelector } from 'store/redux-store/lookup/lookup.selectors';
import { useTranslation } from 'react-i18next';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Controller, useFormContext } from 'react-hook-form';
import { FormInputText } from '../PersonalInfoTab/Form/Controls/FormInputText';
import { DateRangePicker } from 'app/ComponentLibrary/DateRangeNew';
import { addDays } from 'date-fns';
import moment from 'moment';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { LicenseAndCertificationDelete } from './LicenseAndCertificationDelete';
import { HearsayFields } from './Cards/helper';
import { useLicenseAddHearsayStyles } from './style';
import { LicenseAddHearsayProps } from './types';
import { filterOptions } from './helper';

export const LicenseAddHearsay = (props: LicenseAddHearsayProps) => {
  const { onChangeHearsayLicense, selectedHearsayLicenseMetaData, deleteHearsayLicense } = props;
  const { t } = useTranslation();
  const { classes } = useLicenseAddHearsayStyles();
  const lookups = useSelector(lookupSelector);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const [stateValue, setStateValue] = useState<string>('');
  const [licenseValue, setLicenseValue] = useState<string>('');
  const [licenseOpen, setLicenseOpen] = useState<boolean>(false);
  const [stateOpen, setStateOpen] = useState<boolean>(false);
  const { control, errors, getValues, setValue, clearErrors } = useFormContext();
  const [expirationDateValue, setExpirationDateValue] = useState<any>();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);
  const date = new Date();

  useEffect(() => {
    if (selectedHearsayLicenseMetaData) {
      if (selectedHearsayLicenseMetaData.requirementType) {
        const data = lookups?.licenseTypes?.find(item => item.value === selectedHearsayLicenseMetaData.requirementType);
        onChangeHearsayLicense(HearsayFields.License, data ? data : null);
        setLicenseValue(selectedHearsayLicenseMetaData.requirementType);
      }
      if (selectedHearsayLicenseMetaData.state) {
        const data = lookups?.states?.find(item => item.ID === selectedHearsayLicenseMetaData.state);
        onChangeHearsayLicense(HearsayFields.State, data ? data : null);
        setStateValue(data.Description);
        setStateOpen(false);
      }
      if (selectedHearsayLicenseMetaData.licenseNumber) {
        handleEditLicenseNumber(selectedHearsayLicenseMetaData.licenseNumber);
      }
      if (selectedHearsayLicenseMetaData.expirationOn) {
        handleExpirationDateChange(new Date(selectedHearsayLicenseMetaData.expirationOn));
      }
    }
  }, []);
  const PopperComponentLicense = props => (
    <Popper {...props} onMouseEnter={() => setLicenseOpen(true)} onMouseLeave={() => setLicenseOpen(false)} />
  );

  const PopperComponentState = props => (
    <Popper {...props} onMouseEnter={() => setStateOpen(true)} onMouseLeave={() => setStateOpen(false)} />
  );

  const licenseHearsayOptions: string[] = lookups?.licenseTypes?.map(item => {
    return item.value;
  });

  const stateOptions: string[] = lookups?.states?.map(item => {
    return item.Description;
  });

  const onLicenseInputChange = (event, newInputValue: string) => {
    if (newInputValue !== '') setLicenseOpen(true);
    else setLicenseOpen(false);
    const data = lookups?.licenseTypes?.find(item => item.value === newInputValue);
    onChangeHearsayLicense(HearsayFields.License, data ? data : null);
    setLicenseValue(newInputValue);
  };

  const onChangeLicense = (event, newInputValue) => {
    if (newInputValue) {
      const data = lookups?.licenseTypes?.find(item => item.value === newInputValue);
      onChangeHearsayLicense(HearsayFields.License, data ? data : null);
      setLicenseValue(newInputValue);
      setLicenseOpen(false);
    }
  };

  const onStateInputChange = (event, newInputValue: string) => {
    if (newInputValue !== '') setStateOpen(true);
    else setStateOpen(false);
    const data = lookups?.states?.find(item => item.Description === newInputValue);
    onChangeHearsayLicense(HearsayFields.State, data ? data : null);
    setStateValue(newInputValue);
  };

  const onChangeState = (event, newInputValue) => {
    if (newInputValue) {
      const data = lookups?.states?.find(item => item.Description === newInputValue);
      onChangeHearsayLicense(HearsayFields.State, data ? data : null);
      setStateValue(newInputValue);
      setStateOpen(false);
    }
  };

  const getOptionDisabled = (option: string) => {
    const optionLicenseName = lookups?.licenseTypes?.find(item => item.value === licenseValue)?.name;
    const optionStateName = lookups?.states?.find(item => item.Description === option)?.ID;
    return (
      candidateDetails?.licenses?.filter(
        item => item.hearsayLicense === true && item.state === optionStateName && item.type === optionLicenseName,
      )?.length > 0
    );
  };

  const handleExpirationDateChange = event => {
    setValue('pExpirationDate', event, { shouldDirty: true });
    clearErrors('pExpirationDate');
    const data = event;
    onChangeHearsayLicense(HearsayFields.ExpirationDate, data ? data : null);
    setExpirationDateValue(data ? moment(data).format('MM/DD/YYYY') : null);
  };

  const handleEditLicenseNumber = event => {
    setValue('pLicenseNumber', event, { shouldDirty: true });
    const data = event;
    onChangeHearsayLicense(HearsayFields.LicenseNumber, data ? data : null);
  };

  const handleAddLicenseNumber = event => {
    setValue('pLicenseNumber', event.target.value, { shouldDirty: true });
    const data = event.target.value;
    onChangeHearsayLicense(HearsayFields.LicenseNumber, data ? data : null);
  };
  return (
    <Grid container direction="column" spacing={3}>
      {selectedHearsayLicenseMetaData && (
        <Grid item paddingTop="3px !important" mb="-12px">
          <Grid container display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography fontSize="14px">{t('candidate.qualificationsCard.unverifiedLicense')}</Typography>
            </Grid>
            <Grid
              item
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              onClick={() => setOpenDeleteDialog(true)}
            >
              <IconButton color="secondary" size="medium" classes={{ root: classes.deleteIcon }}>
                <DeleteOutlinedIcon sx={{ mr: '3px' }} />
                <Typography fontWeight="600" fontSize="12px !important" mr="-8px" classes={classes.deleteText}>
                  {t('candidate.qualificationsCard.delete')}
                </Typography>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item>
        <FormControl variant="filled" style={{ width: '100%' }}>
          <Controller
            render={({ ref, value, onChange, ...rest }) => (
              <Autocomplete
                open={licenseOpen}
                disabled={
                  selectedHearsayLicenseMetaData &&
                  selectedHearsayLicenseMetaData.travelerHearsayLicensureID &&
                  selectedHearsayLicenseMetaData.travelerHearsayLicensureID > 0
                }
                onMouseEnter={() => {
                  if (!selectedHearsayLicenseMetaData) {
                    setLicenseOpen(true);
                  }
                }}
                onMouseLeave={() => setLicenseOpen(false)}
                renderInput={params => (
                  <TextField
                    name={'pHearsayLicense'}
                    label={t('candidate.qualificationsCard.licenseLabel')}
                    placeholder={t('candidate.qualificationsCard.licenseLabel')}
                    variant="filled"
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      classes: { input: classes.textInput },
                    }}
                  />
                )}
                popupIcon={<ArrowDownIcon />}
                onInputChange={onLicenseInputChange}
                onChange={onChangeLicense}
                options={licenseHearsayOptions || []}
                filterOptions={filterOptions}
                clearIcon={false}
                value={licenseValue?.length ? licenseValue : ''}
                inputValue={licenseValue?.length ? licenseValue : ''}
                PopperComponent={PopperComponentLicense}
              ></Autocomplete>
            )}
            control={control}
            name={'pHearsayLicense'}
            error={!!errors['pHearsayLicense']}
            helperText={
              errors['pHearsayLicense'] ? (errors['pHearsayLicense']?.type === 'required' ? 'Required' : '') : ''
            }
            rules={{
              required: true,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl variant="filled" style={{ width: '100%' }}>
          <Controller
            render={({ ref, value, onChange, ...rest }) => (
              <Autocomplete
                open={stateOpen}
                onMouseEnter={() => setStateOpen(true)}
                onMouseLeave={() => setStateOpen(false)}
                renderInput={params => (
                  <TextField
                    label={t('candidate.qualificationsCard.stateLabel')}
                    placeholder={t('candidate.qualificationsCard.stateLabel')}
                    variant="filled"
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      classes: { input: classes.textInput },
                    }}
                  />
                )}
                popupIcon={<ArrowDownIcon />}
                onInputChange={onStateInputChange}
                onChange={onChangeState}
                options={stateOptions || []}
                filterOptions={filterOptions}
                clearIcon={false}
                value={stateValue?.length ? stateValue : ''}
                inputValue={stateValue?.length ? stateValue : ''}
                PopperComponent={PopperComponentState}
                getOptionDisabled={licenseValue.length > 1 ? getOptionDisabled : options => false}
              ></Autocomplete>
            )}
            control={control}
            name={'pState'}
            error={!!errors['pState']}
            helperText={errors['pState'] ? (errors['pState']?.type === 'required' ? 'Required' : '') : ''}
            rules={{
              required: true,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item>
        <FormInputText
          name="pLicenseNumber"
          label={t('candidate.qualificationsCard.licenseNumber')}
          handleChange={handleAddLicenseNumber}
          inputValue={getValues('pLicenseNumber')}
          message={errors['pLicenseNumber']?.message || ''}
          multiline
          spellCheck
        />
      </Grid>
      <Grid item width="50%">
        <Controller
          name={'pExpirationDate'}
          control={control}
          defaultValue={expirationDateValue}
          render={({ ref, value, onChange, ...rest }) => (
            <>
              <DateRangePicker
                onChange={event => {
                  handleExpirationDateChange(event?.startDate?.toString() || null);
                }}
                actionBar={false}
                single
                variant="filled"
                placeholder={t('candidate.qualificationsCard.expirationDate')}
                clickAway
                keepClickAwayVal={true}
                value={expirationDateValue}
                className={classes.datePickerInput}
                maxWidth={300}
                fullWidth
                showIcon={true}
                isDateIconReq={true}
                trailingIconShow={true}
                initialDateRange={expirationDateValue}
                minDate={new Date('01-01-1940')}
                error={!!errors.pExpirationDate}
                definedRanges={[
                  {
                    label: '1 Day',
                    startDate: addDays(date, 1),
                    endDate: addDays(date, 1),
                    useLabelAsValue: false,
                  },
                  {
                    label: '2 Days',
                    startDate: addDays(date, 2),
                    endDate: addDays(date, 2),
                    useLabelAsValue: false,
                  },
                  {
                    label: '7 Days',
                    startDate: addDays(date, 7),
                    endDate: addDays(date, 7),
                    useLabelAsValue: false,
                  },
                  {
                    label: '20 Days',
                    startDate: addDays(date, 20),
                    endDate: addDays(date, 20),
                    useLabelAsValue: false,
                  },
                  {
                    label: '30 Days',
                    startDate: addDays(date, 30),
                    endDate: addDays(date, 30),
                    useLabelAsValue: false,
                  },
                  {
                    label: '60 Days',
                    startDate: addDays(date, 60),
                    endDate: addDays(date, 60),
                    useLabelAsValue: false,
                  },
                ]}
                {...rest}
              ></DateRangePicker>
            </>
          )}
        />
      </Grid>
      <LicenseAndCertificationDelete
        openDeleteDialog={openDeleteDialog}
        onDeleteDialogClose={() => setOpenDeleteDialog(false)}
        deleteHearsayLicense={() => {
          setOpenDeleteDialog(false), deleteHearsayLicense();
        }}
        type={'License'}
      />
    </Grid>
  );
};
