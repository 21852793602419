import {
  Button,
  ClickAwayListener,
  Grid,
  InputAdornment,
  Paper,
  Popper,
  PopperProps,
  TextField,
  materialUiXGrid,
  FormControlLabel,
  Checkbox,
  Typography,
  PopperPlacementType,
} from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import SearchIcon from '@mui/icons-material/Search';
import ResetIcon from '@mui/icons-material/Replay';
import ClearIcon from '@mui/icons-material/Clear';
import { colors } from 'styles/styleVariables';
import _ from 'lodash';
import {
  GridCallbackDetails,
  GridColDef,
  GridColumnVisibilityModel,
  GridEventListener,
  MuiEvent,
  useGridApiEventHandler,
} from '@mui/x-data-grid-pro';

const useStyles = makeStyles()(() => ({
  column: {
    width: 'min-content',
  },
  adornment: {
    color: '#888888',
  },
  icon: {
    width: '20px',
    height: '20px',
  },
}));

export interface XGridColumnSelectorProps {
  fallbackPlacements?: PopperPlacementType[];
  maxHeight?: number;
}

interface XGridColumnSelectorInternalProps extends PopperProps, XGridColumnSelectorProps {
  onColumnSelected?: (column, checked) => void;
  onClose?: (event) => void;
  onResetColumns?: () => void;
  resetButton?: boolean;
}

export const XGridColumnSelector = ({
  open,
  anchorEl,
  onColumnSelected,
  onClose,
  onResetColumns,
  resetButton,
  fallbackPlacements,
  maxHeight = 300,
  ...props
}: XGridColumnSelectorInternalProps) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const apiRef = materialUiXGrid.useGridApiContext();
  const [columns, setColumns] = React.useState<GridColDef[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({});
  const [searchValue, setSearchValue] = React.useState<string | undefined>();

  const updateColumns = search => {
    setColumns(
      !!search
        ? apiRef.current?.getAllColumns().filter(item => item.headerName.toLowerCase().includes(search?.toLowerCase()))
        : apiRef.current?.getAllColumns(),
    );
  };

  const updateColumnVisibility = React.useCallback(() => {
    setColumnVisibilityModel(
      // Hack to get most recent visibile columns. Column visibility model is unreliable
      _.cloneDeep(apiRef.current?.getVisibleColumns()).reduce(
        // eslint-disable-next-line no-sequences
        (dict, item, index) => ((dict[item.field] = true), dict),
        {},
      ),
    );
  }, [apiRef]);

  React.useEffect(() => {
    updateColumnVisibility();
  }, [updateColumnVisibility]);

  const handleColumnChangeEvent: GridEventListener<'columnsChange'> = (
    params: string[],
    event: MuiEvent<{}>,
    details: GridCallbackDetails,
  ) => {
    updateColumns(searchValue);
    updateColumnVisibility();
  };

  const handleColumnVisibilityModelEvent: GridEventListener<'columnVisibilityModelChange'> = (
    params: GridColumnVisibilityModel,
    event: MuiEvent<{}>,
    details: GridCallbackDetails,
  ) => {
    updateColumnVisibility();
  };

  useGridApiEventHandler(apiRef, 'columnsChange', handleColumnChangeEvent);
  useGridApiEventHandler(apiRef, 'columnVisibilityModelChange', handleColumnVisibilityModelEvent);

  const handleColumnSelection = (column: GridColDef, checked: boolean) => {
    apiRef.current?.setColumnVisibility(column.field, checked);
    onColumnSelected?.(column, checked);
  };

  const handleSearchValueChange = event => {
    setSearchValue(event.target.value);
    updateColumns(event.target.value);
  };

  const clearSearchValue = () => {
    setSearchValue('');
    setColumns(apiRef.current?.getAllColumns());
  };

  const onReset = (event) => {
    onClose?.(event);
    onResetColumns();
    event.preventDefault();
    event.stopPropagation();
  }

  return (
    <ClickAwayListener
      onClickAway={event => {
        if (open) {
          onClose?.(event);
          event.preventDefault();
          event.stopPropagation();
        }
      }}
    >
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        sx={{ zIndex: 4 }}
        modifiers={[
          {
            name: 'flip',
            options: {
              fallbackPlacements: fallbackPlacements,
            },
          },
        ]}
      >
        <Paper elevation={3} sx={{ padding: '12px', maxWidth: '280px' }}>
          <Grid container spacing={2}>
            <Grid item container xs={12} alignItems="center">
              <Grid item xs={7}>
                <Typography variant="body1">{t('global.xgrid.toolbar.columnSelector.chooseColumns')}</Typography>
              </Grid>
              <Grid
                item
                container
                xs={5}
                spacing={1}
                justifyContent="flex-end"
                alignItems="center"
                sx={{ color: colors.amnLightBlue }}
              >
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                  {resetButton && (
                    <Button
                      onMouseDown={(e) => onReset(e)}
                      variant="text"
                      sx={{ padding: '0px', textTransform: 'capitalize' }}
                      startIcon={<ResetIcon className={classes.icon} />}
                    >
                      {t('global.xgrid.toolbar.columnSelector.reset')}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ width: 'min-content' }}>
              <TextField
                value={searchValue}
                onChange={handleSearchValueChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={cx(classes.adornment, classes.icon)} />
                    </InputAdornment>
                  ),
                  endAdornment: !!searchValue ? (
                    <InputAdornment position="end" onClick={clearSearchValue} sx={{ cursor: 'pointer' }}>
                      <ClearIcon className={cx(classes.adornment, classes.icon)} />
                    </InputAdornment>
                  ) : undefined,
                }}
                placeholder={t('global.xgrid.toolbar.columnSelector.search')}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={{ width: 'min-content' }}>
              <Grid container sx={{ overflow: 'auto', maxHeight: `${maxHeight ?? 300}px` }}>
                {columns.map((column, i) => (
                  <Grid
                    item
                    xs={12}
                    key={i}
                    className={classes.column}
                    sx={{ width: 'min-content', overflowY: 'auto' }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          key={`column-checked-${columnVisibilityModel[column.field]}`}
                          onClick={event => {
                            handleColumnSelection(column, !columnVisibilityModel[column.field]);
                          }}
                          checked={columnVisibilityModel[column.field]}
                        />
                      }
                      label={column.headerName}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
};
