import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { call, put, getContext, select } from 'redux-saga/effects';
import { resetFailureStatus } from '../saga';
import { IPlacementSummaryRequest, placementSummaryActions } from '../slice';
import { trackPromise } from 'react-promise-tracker';
import InitPlacementSummary, { IConsolidatedRequirementResponse } from '../init.types';
import { selectPlacementConsolidatedDetails } from '../consolidatedStoreSelectors';

const TrackMiceDetailsOrderReqWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'placement-submission-mice-details-order-requirement');

export function* requestMiceDetailsOrderRequirement(action: PayloadAction<IPlacementSummaryRequest>) {
  try {
    yield put(resetFailureStatus('miceDetailsOrderRequirement'));
    const placementService: PlacementService = yield getContext('placementService');

    let response;

    const consolidatedDetails = yield select(selectPlacementConsolidatedDetails);
    if(action.payload.isInitLoad && consolidatedDetails){
      response = {
        status : consolidatedDetails.summary?.interview?.orderReqMiscDetails?.status,
        data : consolidatedDetails.summary?.interview?.orderReqMiscDetails?.data
      }
    }

    if(!response?.status || !response?.data){
      response = yield call(
        TrackMiceDetailsOrderReqWrapper,
        placementService.getPlacementSummaryMiscDetails_OrderReq,
        action.payload.placementId,
      );
    }
    
    if (httpSuccess(response?.status)) {
      const { data } = response;
      yield put(placementSummaryActions.setSubmissionInfoByKey({ key: 'miceDetails', isOrderReq: true, ...data }));
    } else
      yield put(
        placementSummaryActions.setServiceFailureByKey({ key: 'miceDetailsOrderRequirement', failureStatus: true }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestMiceDetailsOrderRequirement',
        area: 'src/store/redux-store/placement-summary/common-saga/miscellaneousDetailsOrderRequirement.ts',
      },
    });
  }
}

export function processMiceDetailsOrderRequirement(
  response: IConsolidatedRequirementResponse<any>,
  context: InitPlacementSummary,
) {
  try {
    if (!httpSuccess(response?.status)) {
      context.setServiceFailureByKey({ key: 'miceDetailsOrderRequirement', failureStatus: true });
      return;
    }

    context.setSubmissionInfoByKey({ key: 'miceDetails', isOrderReq: true, ...response.data });
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'processMiceDetailsOrderRequirement',
        area: 'src/store/redux-store/placement-summary/common-saga/miscellaneousDetailsOrderRequirement.ts',
      },
    });
  }
}
