import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPlacementPipelineCounts } from '../Helpers/PlacementStatusHelpers';
import { determineChipSelected } from '../Helpers/PlacementStatusFilterHelper';
import {
  defaultChips,
  defaultFilterChipSelected,
  IFilterChipSelected,
  IFilterSpecs,
  IPlacementFilter,
  IPlacementFilterChips,
  IPlacementsStatusPage,
} from '../Models/PlacementStatusInterfaces';

export const initialState: IPlacementsStatusPage = {
  flattenedData: [],
  filteredData: undefined,
  pipelineData: [],
  counts: null,
  expiringTotalCount: 0,
  filterSpecs: undefined,
  filterChipSelected: defaultFilterChipSelected,
  chips: defaultChips,
  loading: false,
};

export const placementStatus = createSlice({
  name: 'credentialingWorkDeskPlacementStatus',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setCounts(state, action: PayloadAction<any>) {
      state.counts = action.payload;
    },
    setExpiringTotalCount(state, action: PayloadAction<any>) {
      state.expiringTotalCount = action.payload;
    },
    setFlattenedData(state, action: PayloadAction<any[]>) {
      state.flattenedData = action.payload;
    },
    setDataLoaded(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        dataLoaded: action.payload,
      }
    },
    setFilteredData(state, action: PayloadAction<any[] | undefined>) {
      state.filteredData = action.payload;
      state.counts = getPlacementPipelineCounts(action.payload || state.flattenedData);
    },
    setFilterSpecs(state, action: PayloadAction<IFilterSpecs>) {
      state.filterSpecs = action.payload;
    },
    setFilterChips(state, action: PayloadAction<IPlacementFilterChips>) {
      state.chips = action.payload;
    },
    setPipelineData(state, action: PayloadAction<any[]>) {
      state.pipelineData = action.payload;
    },
    setFilterChipSelected(state, action: PayloadAction<IFilterChipSelected>) {
      state.filterChipSelected = action.payload;
    },
    setFilterChipSelectedByKey(state, action: PayloadAction<{ key: string; selected: any | null }>) {
      state.filterChipSelected = {
        ...state.filterChipSelected,
        [action.payload.key]: determineChipSelected(
          action.payload.selected,
          state.filterChipSelected[action.payload.key],
        ),
      };
    },
    reset() {
      return { ...initialState };
    },
  },
});

const credentialingWorkDeskPlacementStatusData = createAction<{
  userId: number;
  filter?: {
    values: IPlacementFilter;
    setValue: (
      name: string,
      value: unknown,
      options?:
        | Partial<{
            shouldValidate: boolean;
            shouldDirty: boolean;
          }>
        | undefined,
    ) => void;
  };
}>('GET_PLACEMENT_STATUS_DATA');

const { actions } = placementStatus;

export const { reducer: placementStatusReducer, name: placementStatusSliceKey } = placementStatus;
export const placementStatusActions = {
  ...actions,
  credentialingWorkDeskPlacementStatusData,
};
