import { FilterCompTypes } from "app/ComponentLibrary/Filter/utils";
import { getCoverageSearchLookups } from "app/services/CoverageServices/CoverageServices";
import { IUserInfo } from "oidc/user.redux";
import { Concatenate } from "utils/string/string";
import { CoverageRequestsGridKeys } from "./gridHelper";

export const defaultCoverageFilterValues = {
  [CoverageRequestsGridKeys.coverageFor]: [],
  [CoverageRequestsGridKeys.coveredBy]: [],
  [CoverageRequestsGridKeys.dateRange]: null,
  [CoverageRequestsGridKeys.createdBy]: [],
};

export const getDefaultCoverageFilterValues = (userInfo:IUserInfo) => {
  const defaultValue = {
    [CoverageRequestsGridKeys.coverageFor]:userInfo?.employeeId
    ? [
        {
          id: userInfo.employeeId,
          name: Concatenate([userInfo.firstName || '', userInfo.lastName || ''], ' '),
          value: userInfo.employeeId,
        },
      ]
    : [],
    [CoverageRequestsGridKeys.coveredBy]:[],
    [CoverageRequestsGridKeys.dateRange]:null,
    [CoverageRequestsGridKeys.createdBy]:[],
    
  };
  return defaultValue;
};

export const getCoverageFilterSpecs = t => [
  {
    filterName: t('search.globalSearch.coverage.coverageFor'),
    name: CoverageRequestsGridKeys.coverageFor,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    fetchOptions: async keyword => await getCoverageSearchLookups(keyword),
  },
  {
    filterName: t('search.globalSearch.coverage.coveredBy'),
    name: CoverageRequestsGridKeys.coveredBy,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    fetchOptions: async keyword => await getCoverageSearchLookups(keyword),
  },
  {
    filterName: t('search.globalSearch.coverage.dateRange'),
    name: CoverageRequestsGridKeys.dateRange,
    type: FilterCompTypes.DATERANGEV2,
    variant: 'outlined',
    secondaryLabel: t('search.globalSearch.coverage.dateRange'),
    useMaxWidth: true,
    maxWidth: 210,
    clickAway: true,
    disablePortal: true,
    quickSelect: false,
  },
  {
    filterName: t('search.globalSearch.coverage.createdBy'),
    name: CoverageRequestsGridKeys.createdBy,
    type: FilterCompTypes.TYPEAHEADDROPDOWN,
    isMultiSelect: true,
    fetchOptions: async keyword => await getCoverageSearchLookups(keyword)
  },
];