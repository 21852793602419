/* eslint-disable i18next/no-literal-string */
import React, { useEffect, useState } from 'react';
import { NumberField } from '../../components/Candidate/CandidateProfile/CandidateTabPanel/PreferencesTab/CustomComponents/Currency';
import { InputAdornment } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles()(theme => ({
  root: {
    background: '#fff',
    opacity: 1,
  },
  textField: {
    fontSize: 14,
    width: 85,
    height: 40,
    borderRadius: '4px',
    '& input::placeholder': {
      fontSize: 14,
    },
    '&:hover, &:focus': {
      background: '#F7F7F7',
      color: 'primary',
    },
  },
  typography: {
    padding: theme.spacing(1),
  },
  dividerContainer: {
    position: 'relative',
    padding: '0px 12px',
    height: 40,
    width: 24,
    fontSize: 14,
  },
  divider: {
    position: 'absolute',
    top: '50%',
  },
}));

export interface INumberRange {
  min: number | null;
  max: number | null;
}

export function instanceOfINumberRange(object: any): object is INumberRange {
  return object && typeof object === 'object' && 'min' in object && 'max' in object;
}
//check if objects min and max key are null, then return null
export const validRange = object =>
  instanceOfINumberRange(object) && object.min === null && object.max === null ? null : object;

export const NumberRange = props => {
  const { classes } = useStyles();
  const [min, setMin] = useState<number | null>(null);
  const [max, setMax] = useState<number | null>(null);

  const validateMin = () => (min === null ? min : (min || 0) <= (max || 9999) ? min : null);
  const validateMax = () => (max === null ? max : max >= (min || 0) && max <= 9999 ? max : null);

  const handleChange = (type: 'min' | 'max') => {
    const newRange = type === 'min' ? { max, min: validateMin() } : { min, max: validateMax() };
    /**Temporary condition to retain open state - form reloading due to props.onChange*/
    props.onChange(validRange(newRange));
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      const newRange = !min ? ({ min: 0, max } as INumberRange) : { min, max };
      props.onChange(newRange);
    }
    if (['e', 'E', '+', '-'].includes(event.key)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (props.range) {
      setMin(props.range.min);
      setMax(props.range.max);
    } else {
      setMin(null);
      setMax(null);
    }
  }, [props.range]);

  return (
    <div className={classes.root}>
      <Typography className={classes.typography}>
        <NumberField
          type="number"
          inputProps={{}}
          value={min !== null ? min : ''}
          onBlur={event => handleChange('min')}
          onChange={event => setMin(event.target.value !== '' ? parseFloat(event.target.value) : null)}
          onKeyDown={handleKeyDown}
          variant="outlined"
          placeholder="Min"
          InputProps={{
            classes: { root: classes.textField },
            startAdornment:
              props.numberRangeType === 'currency' ? (
                <InputAdornment position="start">{'$'}</InputAdornment>
              ) : (
                <InputAdornment style={{ display: 'none' }} position="start">{``}</InputAdornment>
              ),
            endAdornment:
              props.numberRangeType === 'hours' ? (
                <InputAdornment position="end">{`Hr.`}</InputAdornment>
              ) : (
                <InputAdornment style={{ display: 'none' }} position="end">{``}</InputAdornment>
              ),
          }}
        />
        <span className={classes.dividerContainer}>
          <span className={classes.divider}>{'-'}</span>
        </span>
        <NumberField
          type="number"
          inputProps={{}}
          value={max !== null ? max : ''}
          onBlur={e => handleChange('max')}
          onChange={event => setMax(event.target.value !== '' ? parseFloat(event.target.value) : null)}
          onKeyDown={handleKeyDown}
          variant="outlined"
          placeholder="Max"
          InputProps={{
            classes: { root: classes.textField },
            startAdornment:
              props.numberRangeType === 'currency' ? (
                <InputAdornment position="start">{'$'}</InputAdornment>
              ) : (
                <InputAdornment style={{ display: 'none' }} position="start">{``}</InputAdornment>
              ),
            endAdornment:
              props.numberRangeType === 'hours' ? (
                <InputAdornment position="end">{`Hr`}</InputAdornment>
              ) : (
                <InputAdornment style={{ display: 'none' }} position="end">{``}</InputAdornment>
              ),
          }}
        />
      </Typography>
    </div>
  );
};
