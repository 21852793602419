import React from 'react';
import { missingField } from 'app/constants';
import styledComponent from 'styled-components';
import { Concatenate } from 'utils/string/string';
import { Avatar } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(theme => ({
  green: {
    width: 22,
    height: 22,
    fontSize: 12,
    marginLeft: '0.3rem',
  },
  blue: {
    width: 22,
    height: 22,
    fontSize: 12,
    marginLeft: '0.3rem',
  },
}));

export const OrderInfoScheduleItem = ({ ...props }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <div id="placement-order-info-schedule">
      <RowDataDiv data-testid="order-info-shifts-item">
        {`${t('placement.profile.orderInformation.orderInfoScheduleItem.shifts')}:`}
        {props.orderInformation?.shifts && props.orderInformation?.shifts.length
          ? Concatenate(props.orderInformation?.shifts, ', ')
          : ` ${missingField}`}
      </RowDataDiv>
      <RowDataDiv style={{ height: '10px' }}>{` `}</RowDataDiv>
      <RowDataDiv data-testid="order-info-flexible-schedule-item">
        {props.orderInformation?.flexibleSchedule ? (
          <div>
            <RowDataDiv>
              <div>{t('placement.profile.orderInformation.orderInfoScheduleItem.flexibleSchedule')}</div>
              <Avatar
               variant={props.orderInformation?.flexibleSchedule?.isRequired ?'primary':'green'}
                className={` ${props.orderInformation?.flexibleSchedule?.isRequired ? classes.blue : classes.green}`}
              >
                {props.orderInformation?.flexibleSchedule?.isRequired ? 'R' : 'P'}
              </Avatar>
            </RowDataDiv>
            {props.orderInformation?.flexibleSchedule?.workDuringWeekend ? (
              <RowDataDiv data-testid="order-info-flexible-schedule-weekend-days-item">
                <div>{`${t('placement.profile.orderInformation.orderInfoScheduleItem.weekendDays')}: `}</div>
                {props.orderInformation?.flexibleSchedule?.workDuringWeekend}
              </RowDataDiv>
            ) : (
              <></>
            )}
            {props.orderInformation?.flexibleSchedule?.workDuringHoliday ? (
              <RowDataDiv data-testid="order-info-flexible-schedule-holidays-item">
                <div>{`${t('placement.profile.orderInformation.orderInfoScheduleItem.holidays')}: `}</div>
                {props.orderInformation?.flexibleSchedule?.workDuringHoliday}
              </RowDataDiv>
            ) : (
              <></>
            )}
          </div>
        ) : (
          `${t('placement.profile.orderInformation.orderInfoScheduleItem.flexibleSchedule')}: ${missingField}`
        )}
      </RowDataDiv>
      <RowDataDiv style={{ height: '10px' }}>{` `}</RowDataDiv>
      <RowDataDiv data-testid="order-info-float-item">
        {props.orderInformation?.float ? (
          <div>
            <RowDataDiv>
              <div>{`${t('placement.profile.orderInformation.orderInfoScheduleItem.float')}:`}</div>
              <Avatar variant={props.orderInformation?.float?.isRequired ?'primary':'green'} className={` ${props.orderInformation?.float?.isRequired ? classes.blue : classes.green}`}>
                {props.orderInformation?.float?.isRequired ? 'R' : 'P'}
              </Avatar>
            </RowDataDiv>
            {props.orderInformation?.float?.floatToUnit ? (
              <RowDataDiv data-testid="order-info-flexible-schedule-unit-item">
                <div>{`${t('placement.profile.orderInformation.orderInfoScheduleItem.unit')}: `}</div>
                {props.orderInformation?.float?.floatToUnit}
              </RowDataDiv>
            ) : (
              <></>
            )}
            {props.orderInformation?.float?.floatToLocation ? (
              <RowDataDiv data-testid="order-info-flexible-schedule-location-item">
                <div>{`${t('placement.profile.orderInformation.orderInfoScheduleItem.location')}: `}</div>
                {props.orderInformation?.float?.floatToLocation}
              </RowDataDiv>
            ) : (
              <></>
            )}
            {props.orderInformation?.float?.floatToMiles ? (
              <RowDataDiv data-testid="order-info-flexible-schedule-miles-item">
                <div>{`${t('placement.profile.orderInformation.orderInfoScheduleItem.miles')}: `}</div>
                {props.orderInformation?.float?.floatToMiles}
              </RowDataDiv>
            ) : (
              <></>
            )}
          </div>
        ) : (
          `${t('placement.profile.orderInformation.orderInfoScheduleItem.float')}: ${missingField}`
        )}
      </RowDataDiv>
    </div>
  );
};

const RowDataDiv = styledComponent.div`
  font-size: 14px;
  white-space: pre-wrap;
  display: flex;
  flex-direction: row;
`;
