/* eslint-disable i18next/no-literal-string */
import { Box, Button, Divider, Grid, Theme, InputAdornment, TextField } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useEffect } from 'react';
import { TreeView } from '@mui/lab';
import ArrowDownIcon from 'app/assets/images/CandidateHeader/ManageSkillSet/arrow-down.svg';
import ArrowUpIcon from 'app/assets/images/CandidateHeader/ManageSkillSet/arrow-up.svg';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { selectUser } from 'oidc/user.selectors';
import { userRoles } from 'oidc/userRoles';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { withReadOnly } from 'oidc/withReadOnly';
import { Authorized } from 'oidc/userHelper';
import SearchIcon from '@mui/icons-material/Search';
import { StyledTreeItem } from './Common/StyledTreeItem';
import { useTranslation } from 'react-i18next';
import { IReason, ISkillsetGroup, ISkillsetTreeItem, SkillsetType, combineDuplicates } from './Helpers/SkillsetHelper';
import {
  candidateDetailsSelection,
  selectSkillSetTree,
} from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { ISkillsetTreeDiscipline, IUpdateSkillsetTreeData } from 'app/models/Candidate/CandidateProfile';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 400,
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
    padding: '4px 0px 18px 24px',
  },
  wrapper: {
    padding: 20,
    paddingBottom: 10,
    paddingRight: 0,
    paddingLeft: 0,
    width: '100%',
  },
  buttonBar: {
    paddingRight: '12px',
    paddingTop: '0px !important',
  },
  addButton: {
    color: '#006FB9',
    fontWeight: 600,
  },
  searchInput: {
    marginLeft: '5px',
    width: '100%',
    borderRadius: '2px',
    backgroundColor: 'white',
  },
  searchTextInput: {
    backgroundColor: 'white',
    marginLeft: '-6px',
  },
  searchIcon: {
    '&.MuiInputAdornment-positionStart': {
      marginRight: 0,
      backgroundColor: 'white',
    },
    '&.MuiInputAdornment-root': {
      height: '2.01em',
      width: '2.5rem',
    },
  },
  inputAdornmentWrapper: {
    backgroundColor: 'white',
    height: '30px',
  },
  dialogContent: {
    fontSize: '14px',
  },
  dialogTitle: {
    fontSize: '20px',
  },
  textboxContainer: {
    width: '100%',
    padding: '0px 18px 0px 30px !important',
  },
  buttonItem: {
    paddingTop: '2px !important',
  },
}));

interface SkillSetTreeProps {
  handleClose: () => void;
  handleToggle: (value: boolean) => void;
  expanded: boolean;
  columns: ISkillsetGroup;
}

export const SkillSetTree = (props: SkillSetTreeProps) => {
  const { expanded, columns, handleToggle, handleClose } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [expandedRows, setExpandedRows] = React.useState<string[]>([]);
  const [candidateSkillSets, setCandidateSkillSets] = React.useState<ISkillsetTreeItem[]>([]);
  const [candidateSkillSetsClone, setCandidateSkillSetsClone] = React.useState<ISkillsetTreeItem[]>([]);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const candidateSkillSetTree = useSelector(selectSkillSetTree);
  const [treeData, setTreeData] = React.useState<ISkillsetTreeDiscipline[]>(
    candidateSkillSetTree?.candidateSkillSets ?? [],
  );
  const [treeDataClone, setTreeDataClone] = React.useState<ISkillsetTreeDiscipline[]>(
    candidateSkillSetTree?.candidateSkillSets ?? [],
  );
  const [hasAddUnverifiedAccess, setHasAddUnverifiedAccess] = React.useState<boolean>(false);
  const [hasAddVerifiedAccess, setHasAddVerifiedAccess] = React.useState<boolean>(false);
  const [skillSetSearchText, setSkillSetSearchText] = React.useState<string>('');
  const [isTreeFiltered, setIsTreeFiltered] = React.useState<boolean>(false);
  const [cancel, setCancel] = React.useState<boolean>(false);
  var skillSetTree = _.cloneDeep(treeData);

  useEffect(() => {
    setHasAddUnverifiedAccess(
      Authorized(
        [
          userRoles.recruitment,
          userRoles.recruitment_TeamMember,
          userRoles.recruitment_Leadership,
          userRoles.clinical_Manager,
          userRoles.clinical_ReviewAnalyst,
          userRoles.clinical_Director,
        ],
        user.userInfo,
      ),
    );
    setHasAddVerifiedAccess(
      Authorized(
        [userRoles.clinical_Manager, userRoles.clinical_ReviewAnalyst, userRoles.clinical_Director],
        user.userInfo,
      ),
    );
  }, [user.userInfo]);

  //only expand if icon was clicked
  const onNodeToggle = (event, nodeIds) => {
    event.persist();
    const iconClicked = event.target.closest('.MuiTreeItem-content');
    if (iconClicked) {
      setExpandedRows(nodeIds);
    }
  };

  useEffect(() => {
    const candidateSkillSets: { disciplineAbbr; specialtyAbbr?; subSpecialtyAbbr? }[] = [];
    candidateSkillSetTree?.candidateSkillSets?.map(discipline => {
      if (discipline.verified || discipline.unverified)
        if (
          discipline.specialties &&
          (discipline.specialties.filter(item => item.verified).length > 0 ||
            discipline.specialties.filter(item => item.unverified).length > 0)
        ) {
          discipline.specialties.map(specialty => {
            if (specialty.verified || specialty.unverified) {
              if (
                specialty.subSpecilities &&
                (specialty.subSpecilities.filter(item => item.verified).length > 0 ||
                  specialty.subSpecilities.filter(item => item.unverified).length > 0)
              ) {
                return specialty.subSpecilities.map(subspecialty => {
                  if (subspecialty.verified || subspecialty.unverified) {
                    candidateSkillSets.push({
                      disciplineAbbr: discipline.abbreviation,
                      specialtyAbbr: specialty.description,
                      subSpecialtyAbbr: subspecialty.description,
                    });
                  }
                });
              } else {
                candidateSkillSets.push({
                  disciplineAbbr: discipline.abbreviation,
                  specialtyAbbr: specialty.description,
                });
              }
            }
          });
        } else {
          candidateSkillSets.push({
            disciplineAbbr: discipline.abbreviation,
          });
        }
    });
    setCandidateSkillSets(candidateSkillSets);
    setCandidateSkillSetsClone(candidateSkillSets);
    setTreeData(candidateSkillSetTree?.candidateSkillSets ?? []);
  }, [candidateSkillSetTree]);

  const updateAssociatedNodes = (data, checked) => {
    if (checked) {
      skillSetTree.map(discipline => {
        if (discipline.abbreviation == data.disciplineAbbr) {
          discipline.unverified = true;
          discipline.specialties.map(specialty => {
            if (specialty.description == data.specialtyAbbr) {
              specialty.unverified = true;
              if (data.subSpecialtyAbbr) {
                specialty.subSpecilities.map(subSpecialty => {
                  if (subSpecialty.description == data.subSpecialtyAbbr) {
                    subSpecialty.unverified = true;
                  }
                });
              }
            }
          });
        }
      });
    } else {
      if (!data.subSpecialtyAbbr) {
        skillSetTree.map(discipline => {
          if (discipline.abbreviation == data.disciplineAbbr) {
            discipline.specialties.map(specialty => {
              if (specialty.description == data.specialtyAbbr) {
                specialty.unverified = false;
                if (specialty.subSpecilities) {
                  specialty.subSpecilities.map(subSpecialty => {
                    subSpecialty.unverified = false;
                  });
                }
              }
            });
          }
          if (discipline.specialties.filter(item => item.unverified == true).length == 0) discipline.unverified = false;
        });
      } else {
        skillSetTree.map(discipline => {
          if (discipline.abbreviation == data.disciplineAbbr) {
            discipline.specialties.map(specialty => {
              if (specialty.description == data.specialtyAbbr) {
                specialty.subSpecilities.map(subSpecialty => {
                  if (subSpecialty.description == data.subSpecialtyAbbr) {
                    subSpecialty.unverified = false;
                  }
                });
              }
              if (specialty.subSpecilities) {
                if (specialty.subSpecilities.filter(item => item.unverified == true).length == 0 && specialty.verified)
                  specialty.unverified = false;
              }
            });
          }
          if (discipline.specialties.filter(item => item.unverified == true).length == 0 && discipline.verified)
            discipline.unverified = false;
        });
      }
    }
    const candidateSkillSets: { disciplineAbbr; specialtyAbbr?; subSpecialtyAbbr? }[] = [];
    skillSetTree?.map(discipline => {
      if (discipline.verified || discipline.unverified)
        if (
          discipline.specialties &&
          (discipline.specialties.filter(item => item.verified).length > 0 ||
            discipline.specialties.filter(item => item.unverified).length > 0)
        ) {
          discipline.specialties.map(specialty => {
            if (specialty.verified || specialty.unverified) {
              if (
                specialty.subSpecilities &&
                (specialty.subSpecilities.filter(item => item.verified).length > 0 ||
                  specialty.subSpecilities.filter(item => item.unverified).length > 0)
              ) {
                specialty.subSpecilities.map(subspecialty => {
                  if (subspecialty.verified || subspecialty.unverified) {
                    candidateSkillSets.push({
                      disciplineAbbr: discipline.abbreviation,
                      specialtyAbbr: specialty.description,
                      subSpecialtyAbbr: subspecialty.description,
                    });
                  }
                });
              } else {
                candidateSkillSets.push({
                  disciplineAbbr: discipline.abbreviation,
                  specialtyAbbr: specialty.description,
                });
              }
            }
          });
        } else {
          candidateSkillSets.push({
            disciplineAbbr: discipline.abbreviation,
          });
        }
    });
    setCandidateSkillSets(candidateSkillSets);
    setTreeData(skillSetTree);
  };

  const validateDisabledNode = (data, nodeType) => {
    let disabled = false;
    if (nodeType == 'specialty') {
      candidateSkillSetsClone.map(item => {
        if (item.disciplineAbbr === data.disciplineAbbr && item.specialtyAbbr === data.specialtyAbbr) disabled = true;
      });
      return disabled;
    } else {
      candidateSkillSetsClone.map(item => {
        if (
          item.disciplineAbbr === data.disciplineAbbr &&
          item.specialtyAbbr === data.specialtyAbbr &&
          item.subSpecialtyAbbr === data.subSpecialtyAbbr
        )
          disabled = true;
      });
      return disabled;
    }
  };

  const validateSubSpecialtyNode = data => {
    let checked = false;
    candidateSkillSets?.forEach(item => {
      if (
        item.disciplineAbbr == data.disciplineAbbr &&
        item.specialtyAbbr == data.specialtyAbbr &&
        item.subSpecialtyAbbr == data.subSpecialtyAbbr
      )
        checked = true;
    });
    return checked;
  };

  const SubSpecialtyNodes = props => {
    return (
      <>
        {props?.specialty?.subSpecilities?.map((subspecialty, idx) => {
          const newIdx = `${props.idx}.${idx}`;
          return (
            <StyledTreeItem
              key={idx}
              nodeId={newIdx}
              labelText={subspecialty.description}
              parent={false}
              data={{
                disciplineAbbr: props.data.disciplineAbbr,
                specialtyAbbr: props.data.specialtyAbbr,
                subSpecialtyAbbr: subspecialty.description,
              }}
              checked={validateSubSpecialtyNode({
                disciplineAbbr: props.data.disciplineAbbr,
                specialtyAbbr: props.data.specialtyAbbr,
                subSpecialtyAbbr: subspecialty.description,
              })}
              disabled={
                !hasAddUnverifiedAccess ||
                !props.specialty.active ||
                validateDisabledNode(
                  {
                    disciplineAbbr: props.data.disciplineAbbr,
                    specialtyAbbr: props.data.specialtyAbbr,
                    subSpecialtyAbbr: subspecialty.description,
                    retired: props.specialty.active,
                  },
                  'subSpecialty',
                )
              }
              nodeType={3}
              updateAssociatedNodes={updateAssociatedNodes}
              isTreeFiltered={isTreeFiltered}
              searchText={skillSetSearchText}
            />
          );
        })}
      </>
    );
  };

  const validateSpecialtyNode = data => {
    let checked = false;
    candidateSkillSets.map(item => {
      if (item.disciplineAbbr == data.disciplineAbbr && item.specialtyAbbr == data.specialtyAbbr) checked = true;
    });
    return checked;
  };

  const SpecialtyNodes = props => {
    return (
      <>
        {props?.discipline?.specialties?.map((specialty, idx) => {
          const newIdx = `${props.idx}.${idx}`;
          return (
            <StyledTreeItem
              key={idx}
              nodeId={newIdx}
              labelText={specialty.description}
              parent={false}
              checked={validateSpecialtyNode({
                disciplineAbbr: props.data.disciplineAbbr,
                specialtyAbbr: specialty.description,
              })}
              disabled={
                !hasAddUnverifiedAccess ||
                !specialty.active ||
                validateDisabledNode(
                  {
                    disciplineAbbr: props.data.disciplineAbbr,
                    specialtyAbbr: specialty.description,
                    retired: specialty.active,
                  },
                  'specialty',
                )
              }
              data={{ disciplineAbbr: props.data.disciplineAbbr, specialtyAbbr: specialty.description }}
              updateAssociatedNodes={updateAssociatedNodes}
              isTreeFiltered={isTreeFiltered}
              searchText={skillSetSearchText}
            >
              {specialty.subSpecilities && (
                <SubSpecialtyNodes
                  specialty={specialty}
                  idx={newIdx + 1}
                  data={{ disciplineAbbr: props.data.disciplineAbbr, specialtyAbbr: specialty.description }}
                />
              )}
            </StyledTreeItem>
          );
        })}
      </>
    );
  };
  const PrepareSkillSetTree = props => {
    return (
      <>
        {treeDataClone?.map((discipline, idx) => {
          return (
            <StyledTreeItem
              key={idx}
              nodeId={idx.toString()}
              labelText={discipline.abbreviation}
              parent={true}
              updateAssociatedNodes={updateAssociatedNodes}
              isTreeFiltered={isTreeFiltered}
              searchText={skillSetSearchText}
            >
              <SpecialtyNodes idx={idx} discipline={discipline} data={{ disciplineAbbr: discipline.abbreviation }} />
            </StyledTreeItem>
          );
        })}
      </>
    );
  };

  const handleCancel = value => {
    if (value == 1) {
      if (_.isEqual(candidateSkillSetTree?.candidateSkillSets, treeData)) {
        handleClose();
      } else {
        setCancel(true);
      }
    }
    if (value == 2) setCancel(false);
    if (value == 3) handleClose();
  };

  const handleAdd = async (type: SkillsetType) => {
    handleToggle(false);
    const newSkillSets: ISkillsetTreeItem[] = [];
    candidateSkillSets?.forEach(x => {
      let isNewSkillset = false;
      candidateSkillSetsClone.forEach(y => {
        if (y.disciplineAbbr === x.disciplineAbbr) {
          if (y.specialtyAbbr) {
            if (y.specialtyAbbr === x.specialtyAbbr) {
              if (y.subSpecialtyAbbr) {
                if (y.subSpecialtyAbbr === x.subSpecialtyAbbr) isNewSkillset = true;
              }
              if (!y.subSpecialtyAbbr && !x.subSpecialtyAbbr) isNewSkillset = true;
            }
          }
        }
      });
      if (!isNewSkillset) {
        newSkillSets.push(x);
      }
    });

    const newSkillsetTree = candidateSkillSetTree.candidateSkillSets.map(discipline => {
      if (newSkillSets.find(skillSet => discipline.abbreviation === skillSet.disciplineAbbr)) {
        return {
          ...discipline,
          isAdded: true,
          unverified: type === SkillsetType.unverified || discipline.unverified,
          verified: type === SkillsetType.verified || discipline.verified,
          specialties: discipline.specialties?.map(specialty => {
            if (
              newSkillSets.find(
                skillSet =>
                  discipline.abbreviation === skillSet.disciplineAbbr &&
                  specialty.description === skillSet.specialtyAbbr,
              )
            ) {
              return {
                ...specialty,
                isAdded: true,
                unverified: type === SkillsetType.unverified || specialty.unverified,
                verified: type === SkillsetType.verified || specialty.verified,
                subSpecilities: specialty.subSpecilities?.map(subspecialty => {
                  if (
                    newSkillSets.find(
                      skillSet =>
                        discipline.abbreviation === skillSet.disciplineAbbr &&
                        specialty.description === skillSet.specialtyAbbr &&
                        subspecialty.description === skillSet.subSpecialtyAbbr,
                    )
                  ) {
                    return {
                      ...subspecialty,
                      isAdded: true,
                      unverified: type === SkillsetType.unverified || subspecialty.unverified,
                      verified: type === SkillsetType.verified || subspecialty.verified,
                    };
                  }
                  return subspecialty;
                }),
              };
            }
            return specialty;
          }),
        };
      } else {
        return discipline;
      }
    });

    dispatch(
      candidateDetailActions.setAddSkillSetTree({
        ...candidateSkillSetTree,
        candidateSkillSets: newSkillsetTree,
      }),
    );
  };

  const expandRelevantNodes = (filteredItems, keyword) => {
    const expandedNodes: string[] = [];
    filteredItems.map((discipline, x) => {
      discipline.specialties.map((specialty, y) => {
        if (specialty.description?.toLowerCase().includes(keyword.toLowerCase())) expandedNodes.push(`${x}`);
        specialty.subSpecilities?.map(subSpecialty => {
          if (subSpecialty.description?.toLowerCase().includes(keyword.toLowerCase())) {
            expandedNodes.push(`${x}`);
            expandedNodes.push(`${x}.${y}`);
          }
        });
      });
    });
    const unique = expandedNodes.filter((v, i, a) => a.indexOf(v) === i);
    setExpandedRows(unique);
  };

  const filterSkillsetNodes = keyword => {
    const updatedSkillsetTree: any[] = [];
    skillSetTree.map(discipline => {
      let isInSearchList = false;
      if (
        discipline.abbreviation?.toLowerCase().includes(keyword.toLowerCase()) ||
        discipline.fullDescription?.toLowerCase().includes(keyword.toLowerCase())
      ) {
        updatedSkillsetTree.push(discipline);
        isInSearchList = true;
      } else {
        discipline.specialties.map(specialty => {
          if (specialty.description?.toLowerCase().includes(keyword.toLowerCase())) {
            if (!isInSearchList) {
              updatedSkillsetTree.push(discipline);
              isInSearchList = true;
            }
          } else {
            specialty.subSpecilities?.map(subSpecialty => {
              if (subSpecialty.description?.toLowerCase().includes(keyword.toLowerCase())) {
                if (!isInSearchList) {
                  updatedSkillsetTree.push(discipline);
                  isInSearchList = true;
                }
              }
            });
          }
        });
      }
    });
    setTreeDataClone(updatedSkillsetTree);
    expandRelevantNodes(updatedSkillsetTree, keyword);
  };

  let timer; // Timer identifier
  const waitTime = 500; // Wait time in milliseconds

  const handleSkillsetSearch = searchText => {
    setSkillSetSearchText(searchText);
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (searchText?.length > 1) {
        setIsTreeFiltered(true);
        filterSkillsetNodes(searchText);
      } else if (searchText?.length == 0) {
        setTreeDataClone(candidateSkillSetTree?.candidateSkillSets ?? []);
        setExpandedRows([]);
        setIsTreeFiltered(false);
      }
    }, waitTime);
  };
  return (
    <>
      <LayoutGrid container direction="column" spacing={2} className={classes.wrapper}>
        <LayoutGridItem item>
          <Box className={classes.title}>{t('candidate.skillsets.skillSet')}</Box>
        </LayoutGridItem>
        <LayoutGridItem className={classes.textboxContainer}>
          <TextField
            className={classes.searchInput}
            placeholder={t('candidate.skillsets.searchDisciplineSpecialty')}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.searchTextInput },
              startAdornment: (
                <div className={classes.inputAdornmentWrapper}>
                  <InputAdornment position="start" className={classes.searchIcon}>
                    <SearchIcon style={{ color: '#A8A8A8', marginLeft: '6px', marginTop: '2px', height: '20px' }} />
                  </InputAdornment>
                </div>
              ),
            }}
            size="small"
            onChange={e => handleSkillsetSearch(e.target.value)}
          />
        </LayoutGridItem>
        <LayoutGridItem item style={expanded ? { height: '407px' } : { height: '68vh' }}>
          <TreeView
            expanded={expandedRows}
            onNodeToggle={onNodeToggle}
            aria-label={t('candidate.skillsets.skillSets')}
            defaultExpanded={['3']}
            defaultCollapseIcon={<img src={ArrowUpIcon} alt="Up" />}
            defaultExpandIcon={<img src={ArrowDownIcon} alt="Down" />}
            defaultEndIcon={<div style={{ width: 24 }} />}
            style={{ maxHeight: expanded ? 400 : 560, flexGrow: 1, overflowY: 'auto', paddingLeft: 18 }}
            multiSelect
          >
            <PrepareSkillSetTree />
          </TreeView>
        </LayoutGridItem>
        <Divider sx={{ margin: expanded ? '7px 5px 7px 5px' : '100px 5px 7px 5px' }} />
        <LayoutGridItem item>
          <Grid container spacing={2} justifyContent="flex-end" wrap="nowrap" className={classes.buttonBar}>
            <Grid item className={classes.buttonItem}>
              {hasAddUnverifiedAccess &&
                withReadOnly(Button)({
                  disableElevation: true,
                  disableRipple: true,
                  disableFocusRipple: true,
                  disableTouchRipple: true,
                  variant: 'text',
                  className: classes.addButton,
                  disabled: !hasAddUnverifiedAccess || _.isEqual(candidateSkillSetTree?.candidateSkillSets, treeData),
                  onClick: () => handleAdd(SkillsetType.unverified),
                  children: t('candidate.skillsets.addUnverified'),
                })}
            </Grid>
            <Grid item className={classes.buttonItem}>
              {hasAddVerifiedAccess &&
                withReadOnly(Button)({
                  disableElevation: true,
                  disableRipple: true,
                  disableFocusRipple: true,
                  disableTouchRipple: true,
                  variant: 'text',
                  className: classes.addButton,
                  disabled: !hasAddVerifiedAccess || _.isEqual(candidateSkillSetTree?.candidateSkillSets, treeData),
                  onClick: () => handleAdd(SkillsetType.verified),
                  children: t('candidate.skillsets.addVerified'),
                })}
            </Grid>
          </Grid>
        </LayoutGridItem>
      </LayoutGrid>
      <GenericDialog
        open={cancel}
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
        onClose={(event, reason) => {
          if (reason === IReason.backdropClick) return;
          handleCancel(2);
        }}
        dialogTitleProps={{
          text: t('candidate.skillsets.discardChanges'),
          classes: { root: classes.dialogTitle },
        }}
        dialogContentProps={{ classes: { root: classes.dialogContent } }}
        dialogActions={[
          {
            text: t('candidate.skillsets.cancel'),
            variant: 'text',
            color: 'tertiary',
            onClick: () => handleCancel(2),
          },
          {
            text: t('candidate.skillsets.yes'),
            variant: 'contained',
            color: 'primary',
            onClick: () => handleCancel(3),
          },
        ]}
      >
        <span>{t('candidate.skillsets.closeDescription')}</span>
      </GenericDialog>
    </>
  );
};
