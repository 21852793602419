import React from 'react';
import { missingField } from 'app/constants';
import styledComponent from 'styled-components';
import { useTranslation } from 'react-i18next';

export const OrderInfoHoursItem = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <div id="placement-order-info-hours">
      <RowDataDiv data-testid="order-info-expected-hours-item">
        {`${t('placement.profile.orderInformation.orderInfoHoursItem.expectedHours')}:`}
        {props.orderInformation?.expectedHours ? ` ${props.orderInformation?.expectedHours}` : ` ${missingField}`}
      </RowDataDiv>
      <RowDataDiv data-testid="order-info-guaranteed-hours-item">
        {`${t('placement.profile.orderInformation.orderInfoHoursItem.guaranteedHours')}:`}
        {props.orderInformation?.orderGww ? ` ${props.orderInformation?.orderGww}` : ` ${missingField}`}
      </RowDataDiv>
      <RowDataDiv data-testid="order-info-contract-guaranteed-hours-item">
        {`${t('placement.profile.orderInformation.orderInfoHoursItem.contractGuaranteedHours')}:`}
        {props.orderInformation
          ? ` ${props.orderInformation?.contractGww === true ? t('global.Yes') : t('global.No')}`
          : ` ${missingField}`}
      </RowDataDiv>
    </div>
  );
};

const RowDataDiv = styledComponent.div`
  font-size: 14px;
  white-space: pre-wrap;
`;
