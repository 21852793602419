import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { Grid, IconButton, Typography } from '@mui/material';
import { ITaskComments, TaskStatus, TaskStatusReason } from 'app/models/Tasks/Tasks';
import moment from 'moment-timezone';
import { CustomTooltip } from '@AMIEWEB/Common';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import {
  getRecipientsForAvatars,
  getCommentCoveredByEmployee,
  formatCoveredByUsers,
  getCommentAssigneeCoveredEmployee,
} from './helper';
import ReplyIcon from '@mui/icons-material/Reply';
import { generateReplyRecipients } from './helper';
import { SubTaskOnContext } from './TaskDetails';
import { RecipientStatusIcon } from './StatusIcon';
import { TaskRecipientAvatars } from '../TaskManagement/RecipientAvatars';
import { missingField } from 'app/constants';
import { selectActiveCoverage } from 'store/redux-store/user-coverage/selectors';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { TaskDetailsDateRenderer } from './TaskDueDateRenderer';
import { useTheme, Link } from 'amn-ui-core';
import {
  checkCreatedByUserStatusEligibility,
  checkIfActiveUserRecipient,
  checkOtherUserStatus,
  fetchCoverageRecipientUserDetails,
  isActiveUserCoveringCreatedBy,
  isActiveUserCoveringRecipients,
  isAssigneeStatusCompletedCancelled,
  isRecipientHsg,
  isUserOnlyRecipientNotCovering,
} from './newTaskStatusHelper';
import { taskDetailsActions } from '../store/Tasks.redux';
import { TaskPriorityIcon } from './TaskPriorityIcon';
import { getEmployeeId } from '@AMIEWEB/Notification/Tasks/Common_v2/utils';

const useStyles = makeStyles()({
  card: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #D9D9D9',
    borderRadius: '7px',
    boxShadow: '0px 3px 6px #00000029',
    marginBottom: '14px',
  },
  cardContent: {
    padding: '24px',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  gridContainers: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '6px',
  },
  subject: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#333333',
    opacity: 1,
    fontWeight: 500,
    marginLeft: '9px',
    display: 'inline-grid',
  },
  truncation: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  descriptionTitle: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0px',
    opacity: 1,
    color: '#333333',
    marginTop: '16px',
    fontWeight: 500,
    '&.MuiTypography-root': {
      fontSize: '14px',
    },
  },
  description: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0px',
    opacity: 1,
    color: '#333333',
    margin: '9px 2px 2px 0px',
  },
  replyBtnContainer: {
    margin: 'auto 0px auto 6px',
  },
  headerRight: {
    marginLeft: 'auto',
    display: 'flex',
  },
  // these 2 styles are used in child components
  statusIconBtn: {
    padding: '0',
    marginRight: '2px',
  },
  statusIconDeniedBtn: {
    padding: '0',
    marginRight: '2px',
    color: 'red',
  },
});

export const SubTaskItem = ({
  comment,
  setReplyRecipients,
  setSubtaskModalOpen,
  setComment,
  setTask,
  setIsPopperOpen,
  setSubTaskName,
  setEnableTaskName,
}: {
  comment: ITaskComments;
  setReplyRecipients: (e) => void;
  setSubtaskModalOpen: (e) => void;
  setComment: (e) => void;
  setTask?: (e) => void;
  setIsPopperOpen?: (e) => void;
  setSubTaskName?: (e) => void;
  setEnableTaskName?: (e) => void;
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState<boolean>(false);
  const [userStatusId, setUserStatusId] = useState<number>(0);
  const [textHeight, setTextHeight] = useState<any>('40px');
  const userInfo = useSelector(selectUser);
  const activeCoverage = useSelector(selectActiveCoverage);
  const user = userInfo?.userInfo;
  const [isEligableToUpdate, setEligableToUpdate] = useState(false);
  const [coverageUser, setCoverageUser] = useState<any>(null);
  const [disabled, setDisabled] = useState(true);
  const { expanded } = useContext(SubTaskOnContext);
  const hsgId = 6000;
  const theme = useTheme();

  const handleReplyClick = () => {
    updateSubTask(true);
    dispatch(taskDetailsActions.setTaskNameFromSubtask(true));
  };
  const handleEditClick = () => {
    updateSubTask(false);
    dispatch(taskDetailsActions.setTaskNameFromSubtask(true));
  };

  const updateSubTask = (isReply: boolean = false) => {
    const { createdBy, tos, ccs } = comment;
    const editRecipients = {
      assignedTo: tos,
      cc: ccs,
    };

    const recipients = isReply
      ? generateReplyRecipients(
          createdBy,
          tos,
          ccs,
          user,
          comment?.taskCoverageAction?.length > 0 ? formatCoveredByUsers(comment?.taskCoverageAction) : null,
        )
      : editRecipients;
    setReplyRecipients(recipients);
    setSubtaskModalOpen(true);
    setSubTaskName(comment?.subject);
    if (!isReply) {
      setComment(comment);
    }
  };

  const updateStatusId = (activeUserId, reversible) => {
    let statusId: number = 0;
    comment?.tos?.forEach(assigned => {
      if (assigned?.id === activeUserId) {
        statusId = assigned?.statusId;
      }
    });
    comment?.ccs?.forEach(ccUser => {
      if (ccUser?.id === activeUserId) {
        statusId = ccUser?.statusId;
      }
    });
    if (comment?.createdBy.id === activeUserId) {
      statusId = 2;
    }
    if (statusId > 0) {
      setEligableToUpdate(true);
      setDisabled(false);
      setEnableTaskName(false);
    } else {
      const isCreatedInError = comment?.taskStatusDetails?.some(
        data => data?.statusUpdateReasonId === TaskStatusReason.ERROR,
      );
      if (reversible && !isCreatedInError) {
        setEligableToUpdate(true);
        setDisabled(false);
        setEnableTaskName(false);
      } else {
        setEligableToUpdate(false);
        setDisabled(true);
        setEnableTaskName(true);
      }
    }
    if (activeUserId === hsgId && activeCoverage?.length > 0) {
      setEligableToUpdate(false);
      setDisabled(true);
      setEnableTaskName(true);
    }
    setUserStatusId(activeUserId === hsgId && statusId === TaskStatus.New ? TaskStatus.Pending : statusId);
  };

  const checkCreatorStatusUpdate = () => {
    const checkCreatorStatus = checkCreatedByUserStatusEligibility(comment, activeCoverage, parseInt(getEmployeeId(userInfo?.userInfo)));
    if (checkCreatorStatus) {
      setUserStatusId(checkCreatorStatus?.statusId);
    } else {
      setUserStatusId(TaskStatus.Pending);
    }
    if (checkCreatorStatus && checkCreatorStatus?.statusId < 3) {
      setEligableToUpdate(true);
      setDisabled(false);
      setEnableTaskName(false);
    } else {
      setEligableToUpdate(false);
      setDisabled(true);
      setEnableTaskName(true);
    }
    if (isActiveUserCoveringCreatedBy(activeCoverage, comment?.createdBy?.id)) {
      setCoverageUser({
        firstName: user?.firstName,
        id: user?.employeeId,
        lastName: user?.lastName,
        statusId: checkCreatorStatus && checkCreatorStatus?.statusId > 3 ? checkCreatorStatus?.statusId : 2,
      });
    }
  };

  useEffect(() => {
    const pendingStatusId = TaskStatus.Pending;
    if (isUserOnlyRecipientNotCovering(comment, activeCoverage, parseInt(getEmployeeId(userInfo?.userInfo)))) {
      const isActiveUserRecipient = checkIfActiveUserRecipient(comment, parseInt(getEmployeeId(userInfo?.userInfo)));
      if (isActiveUserRecipient) {
        updateStatusId(parseInt(getEmployeeId(userInfo?.userInfo)), true);
      } else {
        setUserStatusId(pendingStatusId);
        setEligableToUpdate(false);
        setDisabled(true);
        setEnableTaskName(true);
      }
    } else if (
      activeCoverage?.length > 0 &&
      isActiveUserCoveringRecipients(comment, activeCoverage, user?.employeeId)
    ) {
      const coveredUser = fetchCoverageRecipientUserDetails(comment, activeCoverage, user?.employeeId);
      if (coveredUser) {
        setCoverageUser(coveredUser);
        updateStatusId(coveredUser?.id, false);
      } else {
        setUserStatusId(pendingStatusId);
      }
    } else if (
      comment?.createdBy?.id === user?.employeeId ||
      isActiveUserCoveringCreatedBy(activeCoverage, comment?.createdBy?.id)
    ) {
      checkCreatorStatusUpdate();
    } else if (isRecipientHsg(comment, hsgId)) {
      updateStatusId(hsgId, true);
    } else {
      const otherUserStatusValue = checkOtherUserStatus(comment);
      setUserStatusId(otherUserStatusValue?.statusId);
      setEligableToUpdate(false);
      setDisabled(true);
      setEnableTaskName(true);
    }

    if (isAssigneeStatusCompletedCancelled(comment, parseInt(getEmployeeId(userInfo?.userInfo)))) {
      setDisabled(true);
      setEnableTaskName(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment]);

  return (
    <>
      <div className={classes.card}>
        <div className={classes.cardContent}>
          <Grid
            container
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              justifyContent: 'space-between',
            }}
          >
            <div className={classes.gridContainers}>
              <div className={classes.flexContainer}>
                <RecipientStatusIcon
                  classes={classes}
                  details={comment}
                  isExternal={false}
                  isEligableToUpdate={isEligableToUpdate}
                  isSubtask={true}
                  statusDetails={{ statusId: userStatusId }}
                  coverageUser={coverageUser}
                />

                <div
                  className={classes.subject}
                  style={
                    userStatusId === TaskStatus.Completed || userStatusId === TaskStatus.Cancelled
                      ? {
                          textDecorationLine: 'line-through',
                          color: theme.palette.system.neutralGray,
                          opacity: 0.5,
                        }
                      : userStatusId === TaskStatus.New
                      ? {
                          color: theme.palette.system.primary,
                        }
                      : { color: theme.palette.framework.system.charcoal }
                  }
                >
                  <div className={classes.truncation}>{comment?.subject}</div>
                </div>
                <div className={classes.headerRight}>
                <div className={classes.replyBtnContainer}>
                  <TaskPriorityIcon taskPriorityId={comment.taskPriorityId} />
                </div>
                  <div className={classes.replyBtnContainer}>
                    <CustomTooltip standardMargin arrow tooltipContent={t('notification.taskDetails.replyBtnText')}>
                      <IconButton
                        color="secondary"
                        size="medium"
                        disableRipple
                        onClick={handleReplyClick}
                        disabled={false}
                      >
                        <ReplyIcon />
                      </IconButton>
                    </CustomTooltip>
                  </div>
                  <div className={classes.replyBtnContainer}>
                    <CustomTooltip standardMargin arrow tooltipContent={t('search.grid.editMenu.edit')}>
                      <IconButton
                        color="secondary"
                        size="medium"
                        disabled={disabled}
                        disableRipple
                        onClick={handleEditClick}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </CustomTooltip>
                  </div>
                  <div style={{ paddingLeft: '9px' }}>
                    <TaskDetailsDateRenderer
                      details={comment}
                      isSubtask={true}
                      userStatusId={userStatusId}
                      setIsPopperOpen={setIsPopperOpen}
                      coverageUser={coverageUser}
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: '2px', marginLeft: '36px' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography className={classes.descriptionTitle}>
                    {t('notification.taskDetails.description')}
                  </Typography>
                </div>
                {comment?.comment ? (
                  <div className={classes.description}>
                    <Typography
                      id="typography"
                      variant="body1"
                      style={{
                        lineHeight: '20px',
                        height: comment?.comment?.length < 230 ? 'auto' : textHeight,
                        overflow: 'hidden',
                        whiteSpace: 'pre-line',
                        minHeight: '40px',
                      }}
                    >
                      {comment?.comment}
                    </Typography>
                    <Link
                      data-testid="task-details-show-btn"
                      component="button"
                      onClick={e => {
                        e.preventDefault();
                        setShowMore(s => !s);
                        if (!showMore) {
                          setTextHeight('auto');
                        } else {
                          setTextHeight('40px');
                        }
                      }}
                      hidden={comment?.comment?.length <= 230}
                    >
                      {showMore ? t('notification.subTask.viewLess') : t('notification.subTask.viewMore')}
                    </Link>
                  </div>
                ) : (
                  missingField
                )}
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    height: '30px',
                    margin: '10px 12px 0px 0px',
                    overflow: expanded ? 'auto hidden' : null,
                    maxWidth: expanded ? '70%' : null,
                  }}
                >
                  {TaskRecipientAvatars(
                    null,
                    true,
                    getRecipientsForAvatars(comment),
                    comment,
                    5,
                    comment?.createdBy,
                    getCommentCoveredByEmployee(comment),
                    getCommentAssigneeCoveredEmployee(comment),
                    moment(comment?.sentDate).format('L hh:mm A'),
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
};
