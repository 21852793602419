import { useDecision } from '@optimizely/react-sdk';
import { Grid, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { GenericDialog, IGenericDialogButton } from 'app/components/Alerts/GenericDialog';
import { ChannelType, FormName, SendType, UseSubType, UseType } from 'app/components/Notification/Constants';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { selectOrderDistributionList } from 'app/components/Order/OrderDetails/OrderPreferences/store/OrderPreference.selector';
import { ff_placement_ui_download_packet, ff_submitPacketMarkAsSent } from 'app/constants/FeatureFlags/Placement/Keys';
import { IPlacementStatus } from 'app/models/Placement/PlacementDetails';
import { applyDataToTemplate, GetTemplatesByCategory } from 'app/services/NotificationServices/TemplateService';
import { selectUser } from 'oidc/user.selectors';
import { useReadOnly } from 'oidc/userRoles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Concatenate } from 'utils/string/string';
import { getSubstitutions } from '../../PlacementDetails/helper';
import { placementContactsFlattened } from '../../PlacementDetails/PlacementDetailsHeader/utils';
import {
  selectPlacementSummary,
  selectSubmissionInfo,
} from '../../../../../store/redux-store/placement-summary/selectors';
import {
  selectComparisonData,
  selectPlacementContactsData,
  selectPlacementCandidateRecords,
  selectPlacementHeaderData,
  selectMiscellaneousPlacementData,
} from '../../../../../store/redux-store/placement-details/selectors';
import { isInterviewed, isInterviewScheduled, isSent, placementStatusOptions } from '../StatusDefaults';
import { SubmitPacketDialogContent } from './SubmitPacketDialogContent';
import { ContactProps } from 'app/models/Notification/Notification';
import { EmailDefaultValues } from 'app/models/Email/IEmail';
import { useEnableEmailCommunication } from 'oidc/CommunicationEnabler';
import { placementDocumentsActions } from 'store/redux-store/placement-documents/slice';
import {
  IPlacementSubmitPacket,
  PlacementDocumentFileNamePrefix,
  SubmitPacketSteps,
} from 'store/redux-store/placement-documents/types';
import { selectPlacementDocuments } from 'store/redux-store/placement-documents/selector';
import { usePromiseTracker } from 'react-promise-tracker';
import { placementStatusAction } from 'store/redux-store/placement-status/slice';
import { convertToPST } from 'app/helpers/dateHelper';
import { selectPlacementStatus } from 'store/redux-store/placement-status/selectors';
import { getPlacementDocumentFiles } from 'app/services/SharedServices/SharedServices';
import { extractMimeType, base64DocumentToBlob, blobToURL } from 'app/helpers/fileHelper';
import { CustomBackdrop } from '@AMIEWEB/Common';
import { httpSuccess } from 'app/services/serviceHelpers';
import { getCandidateById, getEMRList } from 'app/services/CandidateServices/CandidateServices';
import { orderBy } from 'lodash';
import moment from 'moment';
import { GetMomentOfDate } from 'utils/dates/moment';
import { getCandidateCredentials } from 'app/services/CredentialingServices';
import { RequirementType } from 'app/models/Credentialing/enums/RequirementType';
import { getOrderDetailsById } from 'app/services/OrderServices/OrderServices';
import { getCredentialLookups } from 'app/services/CredentialingServices/CredentialingServices';
import { LookupFields } from 'app/models/Credentialing/enums/Lookup';
import { getCandidateSellingPointsByPid } from 'app/services/PlacementServices/PlacementServices';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import { signatureSubstitutionToken } from '@AMIEWEB/GlobalSearch/helper';
import {
  getExpirationDate,
  getFluVaccinationStatus,
  getUniqueArray,
} from '@AMIEWEB/Common/TemplateEditor/ClientConfirmation/helper';

const useStyles = makeStyles()({
  dialogContainer: {
    width: '600px',
    maxWidth: '600px',
  },
  dialogConfirmationContainer: {
    width: '500px',
    maxWidth: '500px',
  },
});

interface SubmitPacketDialogProps {
  open: boolean;
  onClose?: (event) => void;
  markPlacementAsSent?: (status: IPlacementStatus) => void;
  resetError?: () => void;
  loading?: boolean;
  submitting?: boolean;
  error?: boolean | null;
  [key: string]: any;
}

export const submitPacketModalQueryString = 'submit_packet';

const dialogStyle = currentStep => {
  switch (currentStep) {
    case SubmitPacketSteps.initial:
      return 'blue';
    case SubmitPacketSteps.markAsSent:
    case SubmitPacketSteps.download:
    case SubmitPacketSteps.sendNow:
    case SubmitPacketSteps.setToSendPostDownload:
      return 'white';
    default:
      return 'blue';
  }
};

export const SubmitPacketDialog = ({
  open,
  onClose,
  markPlacementAsSent,
  resetError,
  loading,
  submitting,
  error,
  ...props
}: SubmitPacketDialogProps) => {
  const { classes, cx } = useStyles();
  const [markAsSentDecision] = useDecision(ff_submitPacketMarkAsSent, { autoUpdate: true });
  const [downloadDecision] = useDecision(ff_placement_ui_download_packet, { autoUpdate: true });
  const { t } = useTranslation();
  const notificationData = useSelector(notificationSelection);
  const { userInfo } = useSelector(selectUser);
  const placementDetails = useSelector(selectPlacementHeaderData);
  const placementContacts = useSelector(selectPlacementContactsData);
  const { disciplineData } = useSelector(selectMiscellaneousPlacementData);
  const { currentPlacementStatus } = useSelector(selectPlacementStatus);
  const placementSummary = useSelector(selectPlacementSummary);
  const flattenedPlacementDetails = placementContactsFlattened(placementContacts);
  const userPreference = useSelector(selectUserPreference);
  const user = useSelector(selectUser);
  const list = useSelector(selectOrderDistributionList);
  const dispatch = useDispatch();
  const { readOnly } = useReadOnly();
  const enableEmailCommunication = useEnableEmailCommunication();

  const { candidate: candidateDetails } = useSelector(selectComparisonData);
  const { candidateSellingPointCounts } = useSelector(selectPlacementCandidateRecords);
  const [loader, setLoader] = React.useState<boolean>(false);

  const {
    orderReq: {
      candidate: { experience: candidateReqItem, certifications, licenses },
    },
  } = useSelector(selectSubmissionInfo);

  const {
    submitPackets,
    selectedPacket,
    miscellaneous: { currentSubmitPacketStep: currentStep, errorProps },
  } = useSelector(selectPlacementDocuments);

  const setCurrentStep = (step: SubmitPacketSteps) =>
    dispatch(placementDocumentsActions.setMiscellaneousField({ key: 'currentSubmitPacketStep', data: step }));

  const { promiseInProgress: downloading } = usePromiseTracker({
    area: 'download-placement-submit-packet-documents',
    delay: 0,
  });

  const errorContent = React.useMemo(
    () =>
      error
        ? {
            error,
            errorMessage: error
              ? t('placement.profile.placementStatusUpdate.packetDownloadError')
              : t('placement.profile.placementStatusUpdate.error'),
          }
        : errorProps
        ? {
            error: true,
            errorMessage: errorProps.message,
            errorSeverity: errorProps.severity,
          }
        : undefined,
    [error, errorProps],
  );

  React.useEffect(() => {
    // blur any buttons from previous step
    resetError?.();
    if ('activeElement' in document) (document?.activeElement as any)?.blur?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  React.useEffect(() => {
    if (open && !!placementDetails?.placementId && placementDetails?.placementId > 0) {
      dispatch(placementDocumentsActions.getPlacementSubmitPackets({ placementId: placementDetails?.placementId }));
    }
    return () => {
      dispatch(placementDocumentsActions.resetSubmitPacktes());
    };
  }, [open, placementDetails?.placementId]);

  const _onCancel = (event, reason) => {
    if (reason === 'backdropClick' && currentStep !== SubmitPacketSteps.initial) return;
    if (currentStep !== SubmitPacketSteps.markAsSent) onClose?.(event);
  };

  const onPacketSelected = React.useCallback((packet: IPlacementSubmitPacket) => {
    dispatch(placementDocumentsActions.setSelectedPacket(packet));
  }, []);

  const inlinePacketDownload = React.useCallback(
    (packet: IPlacementSubmitPacket, isDoubleClick: boolean) => {
      if (isDoubleClick || (!isDoubleClick && selectedPacket)) {
        !isDoubleClick && dispatch(placementDocumentsActions.setSelectedPacket(packet));
        Object.assign(document.createElement('a'), {
          target: '_blank',
          rel: 'noopener noreferrer',
          href: `/placement/document/${packet.documentId}`,
        }).click();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPacket],
  );

  const handleNotificationData = async () => {
    setLoader(true);
    try {
      const [
        candidateData,
        candidateCreds,
        candidateEmrList,
        orderDetails,
        sellingPoints,
        lookupselection,
        templates,
        selectedPacketData,
      ] = await Promise.all([
        getCandidateById(placementDetails.candidate.id, placementDetails.brandId),
        getCandidateCredentials(placementDetails.candidate.id.toString()),
        getEMRList(placementDetails.candidate.id, placementDetails.brandId),
        getOrderDetailsById(`${placementDetails.orderId}`),
        getCandidateSellingPointsByPid(placementDetails.placementId),
        getCredentialLookups('all'),
        GetTemplatesByCategory('Packet Submission', 'email'),
        getPlacementDocumentFiles(selectedPacket.documentId),
      ]);

      const candidateEmr = candidateEmrList?.data
        .filter(item => item?.candidateId == placementDetails.candidate.id && item.brandId == placementDetails.brandId)
        ?.map(item => item?.description);
      const ssn = candidateData?.socialSecurityNumber?.replace(/\d/g, '#');
      const skillSets = candidateData?.verifiedSkills?.map(item => `${item?.disciplineAbbr}-${item?.specialtyAbbr}`);
      const fluData = candidateCreds.employeeHealth?.filter(
        e => e.requirementTypeID === RequirementType.FLUVACCINATION,
      );
      const firstReport = orderBy(fluData, ['dateCompleted'], ['desc'])[0];
      const type = lookupselection
        ?.filter(e => e.requirementTypeID === RequirementType.FLUVACCINATION)
        ?.find(item => item.lookUpField === LookupFields.TypeID)
        ?.lookups?.find(e => e.value === firstReport?.typeID);

      const expirationDate = getExpirationDate(firstReport?.dateCompleted);

      const CandidateFludata = {
        type: type?.description,
        dateCompleted: firstReport?.dateCompleted,
        expirationDate: expirationDate,
        tsceFormID: firstReport?.tsceFormID,
      };
      const activeLicences: Array<string> = [];
      licenses?.data?.map(item => {
        const expirationDate =
          GetMomentOfDate(item.expirationDate).format('MM/DD/YYYY') !== 'Invalid date'
            ? GetMomentOfDate(item.expirationDate).format('MM/DD/YYYY')
            : undefined;
        if (moment(expirationDate).isSameOrAfter(moment(), 'day')) {
          return activeLicences.push(
            `${item?.requirementType || ''} ${item.state} ${item?.level == 'Multi-state' ? 'Compact' : ''}${
              item?.licenseNumber ? '#' + item?.licenseNumber : ''
            }${expirationDate ? ' (exp ' + expirationDate + ')' : ''}`,
          );
        }
      });
      const activeCertifications: Array<string> = [];
      candidateCreds?.certifications?.map(item => {
        const expirationDate =
          GetMomentOfDate(item.expirationDate).format('MM/DD/YYYY') !== 'Invalid date'
            ? GetMomentOfDate(item.expirationDate).format('MM/DD/YYYY')
            : undefined;
        if (moment(expirationDate).isSameOrAfter(moment(), 'day')) {
          return activeCertifications.push(
            `${item.requirementType || ''}${expirationDate ? ' (exp ' + expirationDate + ')' : ''}`,
          );
        }
      });
      const uniqueLicences = getUniqueArray(activeLicences);
      const uniqueCertifications = getUniqueArray(activeCertifications);
      const fluVaccinationStatus = getFluVaccinationStatus(CandidateFludata);
      const vmsReqNumber = placementDetails?.vmsReqDetails?.vmsReqNo;
      const vmsName = placementDetails?.technologyVendorName;
      const licencesTableItems = uniqueLicences?.map(item => {
        return { licenseItem: item };
      });
      const certifiactionsTableItems = uniqueCertifications?.map(item => {
        return { certificationItem: item };
      });
      const candidateDiscipline = Concatenate(
        candidateDetails?.skillsets?.map(skill => Concatenate([skill?.disciplineAbbr], ', ')),
        '',
      );
      const orderSkillSets = [];
      placementSummary.submissionInfo.orderReq.order.skillset.data.map(item => {
        item?.specialties.map(ele => {
          orderSkillSets.push(`${item?.disciplineAbbr || ''}-${ele?.specialtyAbbr || ''}`);
        });
      });
      const values = {
        facilityUnit: placementDetails?.placementUnit,
        accountManagerFirstName: flattenedPlacementDetails?.accountManager?.firstName,
        accountManagerLastName: flattenedPlacementDetails?.accountManager?.lastName,
        accountManagerEmailAddress: flattenedPlacementDetails?.accountManager?.email,
        accountManagerPhoneNumber: flattenedPlacementDetails?.accountManager?.phoneNumber,
        placementSellingPoints: `${sellingPoints?.placementSellingPoints?.replaceAll('\n', '<br/>') || ''}`,
        candidateFirstName: placementDetails.candidate?.firstName,
        candidateLastName: placementDetails.candidate?.lastName,
        candidateGoesBy: placementDetails.candidate?.goesByName,
        candidateDiscipline: candidateDetails?.skillsets,
        candidateDob:
          (candidateData?.dateOfBirth && GetMomentOfDate(candidateData?.dateOfBirth).format('MM/DD/YYYY')) || '',
        currentAMNTraveler: candidateSellingPointCounts?.currentAmnTraveler,
        candidateDisciplineText: disciplineData,
        candidatePhoneNumber: placementDetails.candidate?.phoneNumber?.[0]?.phoneNumber,
        candidateEmail: placementDetails.candidatePrimaryEmail,
        availableStartDate: placementDetails.availabilityDate,
        requestedTimeOff: candidateDetails?.requestedTimeOff,
        licenses: licenses?.data,
        certifications: certifications.data?.candidateCredCertListResponse,
        totalYearsofExperience: `${
          candidateData?.experience?.totalYearsOfExp ? candidateData?.experience?.totalYearsOfExp?.toString() + 'Y' : ''
        } ${
          candidateData?.experience?.totalMonthsOfExperience
            ? candidateData?.experience?.totalMonthsOfExperience?.toString() + 'M'
            : ''
        }`,
        COVIDVaccineStatus: candidateData.covidVaccinationstatus,
        experience: candidateReqItem,
        orderShift: orderDetails.shifts?.[0]?.description || '',
        orderGww: orderDetails.gww && orderDetails.gww == true ? 'Yes' : 'No',
        ssn: ssn,
        fluVaccinationStatus: fluVaccinationStatus,
        candidateEmr: candidateEmr,
        assignmentDuration: placementSummary.orderInformation.numberOfWeeks
          ? `${placementSummary.orderInformation.numberOfWeeks} weeks`
          : null,
        vmsReqNumber: vmsReqNumber,
        candidateSkillSets: skillSets,
        orderSkillSets: orderSkillSets,
        activeCertifications: uniqueCertifications.join(', '),
        activeLicences: uniqueLicences.join(', '),
      };
      const substitutions = getSubstitutions(values);
      let fileData;

      const sender = {
        name: Concatenate([userInfo?.firstName, userInfo?.lastName], ' '),
        email: userInfo?.email,
        userId: `${userInfo?.employeeId}`,
        NtUserName: userInfo?.ntUserName,
        senderId: '',
      };
      const template = templates.filter(data => data.title === 'Default');
      const accountManager: ContactProps = {
        name: Concatenate(
          [
            flattenedPlacementDetails?.accountManager.firstName,
            flattenedPlacementDetails?.accountManager.middleName || '',
            flattenedPlacementDetails?.accountManager.lastName,
          ],
          ' ',
        ),
        email: flattenedPlacementDetails?.accountManager?.email,
      };
      const distributionListContacts: ContactProps[] = list.map(data => {
        const firstName = data.firstName || '';
        const lastName = data.lastName || '';
        return {
          name: Concatenate([firstName, lastName], ' '),
          email: data?.email!,
          contactId: data?.id,
        };
      });

      const sendFileToClientContacts = placementContacts?.facilityContacts?.filter(item => {
        return item.actions.some(action => action.description == 'Send file to');
      });
      const keyClientContactsTos: Array<ContactProps> = sendFileToClientContacts?.map(item => {
        const firstName = item.firstName || '';
        const lastName = item.lastName || '';
        return {
          name: Concatenate([firstName, lastName], ' '),
          email: item?.email != '' ? item?.email : null,
          contactId: item?.id,
        };
      });
      const tos = [...distributionListContacts, ...keyClientContactsTos];
      if (httpSuccess(selectedPacketData?.status)) {
        const { data: document } = selectedPacketData;
        if (document?.filebytes) {
          const fileType = extractMimeType(document.fileName);
          if (fileType) {
            const { blob } = base64DocumentToBlob(document.filebytes, fileType);
            const url = blobToURL(blob);
            fileData = {
              blobUrl: url,
              fileName: document.fileName,
              fileType: fileType,
              documentPacket: selectedPacket,
            };
          }
        } else {
          dispatch(
            placementDocumentsActions.setMiscellaneousField({
              key: 'errorProps',
              data: {
                message: 'An error occurred while retrieving the packet to send. Please try again.',
                severity: 'error',
                error: true,
              },
            }),
          );
          setLoader(false);
          return;
        }
      } else {
        dispatch(
          placementDocumentsActions.setMiscellaneousField({
            key: 'errorProps',
            data: {
              message: 'An error occurred while retrieving the packet to send. Please try again.',
              severity: 'error',
              error: true,
            },
          }),
        );
        setLoader(false);
        return;
      }
      const payload = {
        body: template[0]?.body ? `${template[0]?.body}` : '',
        tableData: {
          licenses: {
            partialTemplateName: 'license',
            partialTemplate: '<li>{{licenseItem}}</li>',
            partialData: licencesTableItems,
          },
          certifications: {
            partialTemplateName: 'certification',
            partialTemplate: '<li>{{certificationItem}}</li>',
            partialData: certifiactionsTableItems,
          },
        },
        substitutions: substitutions.reduce((acc, item) => {
          if (item.name !== 'Signature') {
            acc[item.input.slice(2, -2)] = item.value || '';
          }
          return acc;
        }, {}),
        signatureSubstitutions: signatureSubstitutionToken(user, userPreference),
        brand: user.userInfo?.companyBrandName,
        id: null,
        skipSignatureSubstitution: true,
      };
      const body = await applyDataToTemplate(payload);
      onClose?.(false);
      const data = {
        useType: UseType.Placement,
        useSubType: UseSubType.PacketSubmission,
        channel: ChannelType.email,
        emailType: SendType.one_to_one,
        selectedTemplate:
          template.length > 0
            ? template[0]
            : {
                title: '',
                id: '',
                body: '',
                description: '',
                category: '',
                isPrivate: false,
                substitutions: [],
              },
        selectedPublicCategory: 'Packet Submission',
        publicTemplates: templates?.filter(template => template?.isPrivate !== true),
        sendType: SendType.one_to_one,
        body: body ? `${body}` : '',
        sender: sender,
        substitutions: substitutions,
        tableData: {
          licenses: {
            partialTemplateName: 'license',
            partialTemplate: '<li>{{licenseItem}}</li>',
            partialData: licencesTableItems,
          },
          certifications: {
            partialTemplateName: 'certification',
            partialTemplate: '<li>{{certificationItem}}</li>',
            partialData: certifiactionsTableItems,
          },
        },
        tos: tos,
        ccs: flattenedPlacementDetails?.accountManager?.email ? [accountManager] : [],
        bccs: [sender],
        packetSubmission: true,
        associatedRecords: [
          { name: 'placementId', value: `${placementDetails?.placementId}` },
          { name: 'travelerId', value: `${placementDetails?.candidate?.id}` },
          { name: 'brandId', value: `${placementDetails?.brandId}` },
          { name: 'facilityId', value: `${placementDetails?.facility?.id}` },
          {
            name: 'candidate',
            value: Concatenate([placementDetails.candidate?.firstName, placementDetails.candidate?.lastName], ' '),
          },
          { name: 'documentId', value: `${selectedPacket.documentId}` },
          { name: 'publisherId', value: `${userInfo.employeeId}` },
          { name: 'publisherName', value: `${userInfo.firstName || ''} ${userInfo.lastName || ''}` },
          { name: 'regionalDirectorId', value: `${placementContacts?.regionalDirector.id}` },
        ],
        correlationId: null,
        userRole: Concatenate(userInfo?.roles || [], ','),
        formName: FormName.Placement,
        manualLog: false,
        subject: `AMN Healthcare - ${placementDetails.candidate?.firstName || ''} ${
          placementDetails.candidate.lastName || ''
        } - ${candidateDiscipline} - ${placementDetails?.placementUnit}${
          vmsName && vmsName !== '' ? ` - ${vmsName}${vmsReqNumber ? `: ${vmsReqNumber}` : ''}` : ''
        }`,
        attachmentData: fileData,
      };
      if (!notificationData.email.open && notificationData.email.minimized) {
        dispatch(
          notificationDataActions.setEmailInteraction({
            open: true,
            minimized: false,
          }),
        );
        dispatch(notificationDataActions.setSnackBarData({ channel: 'email', manual: false, changeWarning: true }));
      } else {
        dispatch(
          notificationDataActions.setNotificationData({
            ...notificationData,

            email: {
              data: {
                ...EmailDefaultValues(),
                ...data,
              },
              open: true,
              minimized: false,
            },
            snackbarData: null,
          }),
        );
      }
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const initalStepDialogButtons = React.useMemo(() => {
    const conditional =
      isInterviewed(currentPlacementStatus) ||
      isInterviewScheduled(currentPlacementStatus) ||
      isSent(currentPlacementStatus);

    const btns = [
      {
        text: t('placement.profile.submitPacket.dialog.buttons.cancel'),
        //@ts-ignore
        onClick: _onCancel,
        color: 'tertiary',
      },
      {
        text: t('placement.profile.submitPacket.dialog.buttons.markAsSent'),
        variant: 'contained',
        onClick: event => {
          setCurrentStep(SubmitPacketSteps.markAsSent);
        },
        disabled: readOnly || !markAsSentDecision.enabled,
        color: 'tertiary',
      },
      {
        text: t('placement.profile.submitPacket.dialog.buttons.download'),
        variant: 'contained',
        disabled: loading || downloading || !selectedPacket || !downloadDecision.enabled,
        loading: downloading,
        onClick: event => {
          dispatch(
            placementDocumentsActions.downloadSubmitPacket({
              docId: selectedPacket.documentId,
              avoidStepUpdate: conditional,
            }),
          );
        },
        color: 'tertiary',
      },
      {
        text: t('placement.profile.submitPacket.dialog.buttons.sendNow'),
        variant: 'contained',
        disabled: !enableEmailCommunication || readOnly || loading || !selectedPacket,
        onClick: event => {
          handleNotificationData();
        },
      },
    ] as IGenericDialogButton[];
    if (conditional) btns.splice(1, 1);
    return btns;
  }, [
    currentPlacementStatus,
    readOnly,
    markAsSentDecision,
    loading,
    downloading,
    selectedPacket,
    enableEmailCommunication,
    handleNotificationData,
  ]);

  const markAsSentButtons: IGenericDialogButton[] = [
    {
      text: t('placement.profile.submitPacket.dialog.buttons.cancel'),
      onClick: () => {
        setCurrentStep(SubmitPacketSteps.initial);
      },
      color: 'tertiary',
      disabled: submitting,
    },
    {
      text: submitting
        ? t('placement.profile.placementStatusUpdate.inSubmissionButton')
        : t('placement.profile.placementStatusUpdate.confirmButton'),
      variant: 'contained',
      onClick: event => {
        markPlacementAsSent?.(placementStatusOptions.sent);
      },
      disabled: readOnly || submitting,
      loading: submitting,
    },
  ];

  const downloadButtons: IGenericDialogButton[] = [
    {
      text: t('placement.profile.submitPacket.dialog.buttons.notNow'),
      onClick: event => {
        setCurrentStep(SubmitPacketSteps.initial);
      },
      color: 'tertiary',
    },
    {
      text: t('placement.profile.placementStatusUpdate.markAsSent'),
      variant: 'contained',
      onClick: event => {
        setCurrentStep(SubmitPacketSteps.setToSendPostDownload);
      },
      disabled: readOnly || !markAsSentDecision.enabled,
    },
  ];

  const setToSendPostDownloadBtns: IGenericDialogButton[] = [
    {
      text: t('placement.profile.submitPacket.dialog.buttons.cancel'),
      onClick: event => {
        setCurrentStep(SubmitPacketSteps.download);
      },
      color: 'tertiary',
      disabled: submitting,
    },
    {
      text: submitting
        ? t('placement.profile.placementStatusUpdate.inSubmissionButton')
        : t('placement.profile.placementStatusUpdate.confirmButton'),
      variant: 'contained',
      onClick: event => {
        const user = Concatenate([userInfo?.firstName, userInfo?.lastName], ' ');
        const packetName = `${PlacementDocumentFileNamePrefix.submission}${placementDetails.candidate.firstName}_${placementDetails.candidate.lastName}_${placementDetails.placementId}`;
        dispatch(
          placementStatusAction.saveSubmissionPacketsToCosmos({
            ...selectedPacket,
            placementId: placementDetails.placementId,
            packetName,
            template: selectedPacket.template,
            lastUpdatedByUserId: userInfo.employeeId,
            lastUpdatedBy: user,
            sentByUserId: userInfo.employeeId,
            sentByUser: user,
            sentDate: convertToPST(new Date(), 'yyyy-MM-DDTHH:mm:ss'),
            status: 'Sent',
          }),
        );
        markPlacementAsSent?.(placementStatusOptions.sent);
      },
      disabled: readOnly || submitting || !markAsSentDecision.enabled,
      loading: submitting,
    },
  ];

  const dialogButtons = (): IGenericDialogButton[] => {
    switch (currentStep) {
      case SubmitPacketSteps.initial:
        return initalStepDialogButtons;
      case SubmitPacketSteps.markAsSent:
        return markAsSentButtons;
      case SubmitPacketSteps.download:
        return downloadButtons;
      case SubmitPacketSteps.sendNow:
        return [];
      case SubmitPacketSteps.setToSendPostDownload:
        return setToSendPostDownloadBtns;
      default:
        return [];
    }
  };

  const dialogTitle = () => {
    switch (currentStep) {
      case SubmitPacketSteps.initial:
        return t('placement.profile.submitPacket.dialog.title.initial');
      case SubmitPacketSteps.markAsSent:
        return t('placement.profile.submitPacket.dialog.title.markAsSent');
      case SubmitPacketSteps.download:
        return t('placement.profile.submitPacket.dialog.title.download');
      case SubmitPacketSteps.sendNow:
        return t('placement.profile.submitPacket.dialog.title.sendNow');
      case SubmitPacketSteps.setToSendPostDownload:
        return t('placement.profile.submitPacket.dialog.title.setToSendPostDownload');
      default:
        return t('placement.profile.submitPacket.dialog.title.initial');
    }
  };

  const dialogContent = React.useMemo(() => {
    switch (currentStep) {
      case SubmitPacketSteps.initial:
        return (
          <SubmitPacketDialogContent
            loading={loading}
            packets={submitPackets}
            selectedPacket={selectedPacket}
            onPacketSelected={onPacketSelected}
            inlinePacketDownload={inlinePacketDownload}
            packetGenLinkProps={{
              placementId: placementDetails.placementId,
              candidateId: placementDetails.candidate.id,
              queryString: { modal: submitPacketModalQueryString },
            }}
          />
        );
      case SubmitPacketSteps.markAsSent:
        return (
          <SentConfirmation
            msg={t('placement.profile.placementStatusUpdate.confirmationSentNoPacketLineOne')}
            promptMsg={t('placement.profile.placementStatusUpdate.confirmationToSent')}
          />
        );
      case SubmitPacketSteps.download:
        return (
          <Typography variant="body1">{t('placement.profile.submitPacket.dialog.content.sendToYourself')}</Typography>
        );
      case SubmitPacketSteps.sendNow:
        return t('placement.profile.submitPacket.dialog.title.initial');
      case SubmitPacketSteps.setToSendPostDownload:
        return (
          <SentConfirmation
            msg={t('placement.profile.placementStatusUpdate.confirmationSendPacketLineOne')}
            promptMsg={t('placement.profile.placementStatusUpdate.confirmationToSent')}
          />
        );
      default:
        return t('placement.profile.submitPacket.dialog.title.initial');
    }
  }, [
    currentStep,
    loading,
    submitPackets,
    selectedPacket,
    onPacketSelected,
    inlinePacketDownload,
    placementDetails.placementId,
    placementDetails.candidate.id,
    t,
  ]);

  return (
    <GenericDialog
      open={open}
      variant={dialogStyle(currentStep)}
      onClose={_onCancel}
      classes={{
        paper: cx({
          [classes.dialogContainer]: currentStep === SubmitPacketSteps.initial,
          [classes.dialogConfirmationContainer]: currentStep !== SubmitPacketSteps.initial,
        }),
      }}
      dialogTitleProps={{
        text: dialogTitle(),
        closeButton: true,
        onClose: () => {
          if (currentStep === SubmitPacketSteps.markAsSent) setCurrentStep(SubmitPacketSteps.initial);
        },
      }}
      dialogActions={dialogButtons()}
      disableEscapeKeyDown={currentStep !== SubmitPacketSteps.initial}
      dialogContentProps={{ ...errorContent }}
      {...props}
    >
      <Grid container spacing={3}>
        {loader && <CustomBackdrop open />}
        <Grid item xs={12}>
          {dialogContent}
        </Grid>
      </Grid>
    </GenericDialog>
  );
};

const SentConfirmation = ({ msg, promptMsg }) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant="body1">{msg}</Typography>
    </Grid>
    <Grid item xs={12} style={{ fontWeight: 500 }}>
      <Typography variant="body1">{promptMsg}</Typography>
    </Grid>
  </Grid>
);
