import { SearchField } from '@AMIEWEB/Common/Search/SearchField';
import { Grid } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { GenerateHeader } from '.';
import TemplateNotFound from 'app/assets/images/Template/TemplateNotFound.svg';
import TemplateSearchNotFound from 'app/assets/images/Template/TemplateSearchNotFound.svg';
import { IConfirmationDoc } from 'store/redux-store/placement-client-confirmation-doc-editor/types';
import { IContractDoc } from 'store/redux-store/placement-candidate-contract-doc-editor/types';
import { ContractDocCard } from '../CandidateContract/ContractDocCard';
import { ConfirmationDocCard } from '../ClientConfirmation/ConfirmationDocCard';

const useStyles = makeStyles()(theme => ({
  templatesContainer: {
    height: '800px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: 'calc(100vh - 208px)',
    flexWrap: 'nowrap',
  },
  searchBarContainer: {
    position: 'sticky',
    top: '0px',
    zIndex: 2,
    backgroundColor: theme.palette.system.grayChip,
  },
  textField: {
    height: 40,
    width: '100%',
  },
  content: {
    height: 'fit-content',
    width: '100%',
    backgroundColor: theme.palette.components.grid.content.backgroundColor,
    alignSelf: 'center',
  },
  messageBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'inherit',
    paddingLeft: '20px',
  },
  message: {
    fontSize: '14px',
    color: theme.palette.system.darkGray,
    fontStyle: 'italic',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    ' span': {
      padding: '1rem 2rem 1rem 2rem',
    },
  },
}));

export function instanceIfConfirmationCard(
  object,
  cardType: 'confirmation-doc' | 'contract-doc',
): object is IConfirmationDoc {
  return cardType === 'confirmation-doc';
}

export const DocumentsPanel: React.FC<{
  documentList: (IContractDoc | IConfirmationDoc)[];
  handleGenerate: Function;
  onDocumentClick: (doc: IContractDoc | IConfirmationDoc) => void;
  selectedDoc: IContractDoc | IConfirmationDoc;
  noDocCreatedMessage: string;
  noDocAvailbaleMessage: string;
  docType: 'confirmation-doc' | 'contract-doc';
}> = ({
  documentList,
  handleGenerate,
  onDocumentClick,
  selectedDoc,
  noDocCreatedMessage,
  noDocAvailbaleMessage,
  docType,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = React.useState('');

  const filterDocuments = React.useCallback(
    (list: (IConfirmationDoc | IContractDoc)[]) =>
      list.filter(x => (!!searchQuery ? x.version.toLowerCase().includes(searchQuery.toLowerCase()) : x)),
    [searchQuery],
  );

  const { hasSearchResults, _documentList, _draftList, _noTemplates, hasDraftDoc, hasOnlyDraftDoc } =
    React.useMemo(() => {
      const { draftList, docList } = documentList.reduce(
        (results, item) => {
          if (item.isDraft) results.draftList.push(item);
          else results.docList.push(item);
          return results;
        },
        { draftList: [], docList: [] },
      );
      const filteredDocList = filterDocuments(docList);
      const filteredDraftList = filterDocuments(draftList);
      // TODO: Simplify filtering and message determination
      return {
        hasSearchResults: filteredDocList.length > 0 || filteredDraftList.length > 0,
        _documentList: filteredDocList,
        _draftList: filteredDraftList,
        _noTemplates: docList.length === 0,
        hasDraftDoc: draftList.length > 0,
        hasOnlyDraftDoc: draftList.length > 0 && docList.length === 0,
      };
    }, [filterDocuments, documentList]);

  const noTemplateMessage = React.useMemo(() => {
    if (hasOnlyDraftDoc)
      return (
        <>
          <img src={TemplateNotFound} alt="No templates available" />
          <span>{noDocCreatedMessage}</span>
        </>
      );
    if (_noTemplates)
      return (
        <>
          <img src={TemplateNotFound} alt="No templates available" />
          <span>{noDocAvailbaleMessage}</span>
        </>
      );
    if (!hasSearchResults) {
      return (
        <>
          <img src={TemplateSearchNotFound} alt="No results found" />
          <span>{t('templateEditor.templateName.search.noResults')}</span>
        </>
      );
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasOnlyDraftDoc, hasSearchResults, _noTemplates]);

  return (
    <Grid container classes={{ container: classes.templatesContainer }} direction="column" spacing={2}>
      <Grid className={classes.searchBarContainer} container item>
        <Grid item classes={{ root: classes.content }}>
          <SearchField
            autoFocus
            inheritWidth
            name={'search-editor-documents'}
            placeholder={t('appHeader.searchPlaceHolder')}
            adornAtStart
            onChange={e => setSearchQuery(e.target.value)}
            searchFieldClasses={{ textField: classes.textField }}
          />
        </Grid>
      </Grid>
      {hasDraftDoc ? (
        <>
          <Grid item xs={12} sx={{ maxHeight: 30 }}>
            <GenerateHeader generate onClick={handleGenerate} title={t('Draft')} isGenerating={false} />
          </Grid>
          <DocumentList
            documentList={_draftList}
            selectedDoc={selectedDoc}
            onDocumentClick={onDocumentClick}
            cardType={docType}
          />
        </>
      ) : null}
      <Grid item xs={12} sx={{ maxHeight: 30 }}>
        <GenerateHeader generate={!hasDraftDoc} onClick={handleGenerate} title={t('Documents')} isGenerating={false} />
      </Grid>
      {!!noTemplateMessage ? (
        <Grid item className={classes.messageBox}>
          <div className={classes.message}>{noTemplateMessage}</div>
        </Grid>
      ) : (
        <DocumentList
          documentList={_documentList}
          selectedDoc={selectedDoc}
          onDocumentClick={onDocumentClick}
          cardType={docType}
        />
      )}
    </Grid>
  );
};

export const DocumentList = ({ documentList, selectedDoc, onDocumentClick, cardType }) =>
  instanceIfConfirmationCard(documentList[0], cardType)
    ? documentList.map(doc => {
        const selected = selectedDoc?.documentId === doc.documentId;
        return (
          <Grid key={doc.documentId} item style={{ position: 'relative', zIndex: 1 }}>
            <ConfirmationDocCard
              card={doc}
              isSelected={selected}
              onClick={() => onDocumentClick(selected ? null : doc)}
              cardType={cardType}
            />
          </Grid>
        );
      })
    : documentList.map(doc => {
        const selected = selectedDoc?.documentId === doc.documentId;
        return (
          <Grid key={doc.documentId} item style={{ position: 'relative', zIndex: 1 }}>
            <ContractDocCard card={doc} isSelected={selected} onClick={() => onDocumentClick(selected ? null : doc)} />
          </Grid>
        );
      });
