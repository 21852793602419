import { ISelection } from 'app/ComponentLibrary/Filter/TypeAheadDropdown/CustomTypeAhead';
import { FilterCompTypes } from 'app/ComponentLibrary/Filter/utils';
import { addDays } from 'date-fns';
import { getAllRangeEnd, getAllRangeStart } from 'utils/dates/dateExtraction';
import {
  getLastContactedDate,
  getCallBackDateRange,
  getAvailableToStartDateRange,
  getLastApplicationDateRange,
} from './filterHelper';

export interface IPlacementFilter {
  placementRecruiter: any[];
  candidateName: ISelection[];
  discipline: ISelection[];
  specialty: ISelection[];
  availabilityStartDateRange: string | null;
  startDateRange: string | null;
  endDateRange: string | null;
  facilityName: ISelection[];
  city: ISelection[];
  state: ISelection[];
  nonContactedDate: string | null;
  lastApplicationDateRange: string | null;
  availableToStartDateRange: string | null;
  callBackDateRange: string | null;
  activeCues: string | null;
  arbitrationStatus: ISelection[];
}

export enum recPlacementsFilterName {
  placementRecruiter = 'placementRecruiter',
  status = 'status',
  candidateName = 'candidateName',
  discipline = 'discipline',
  specialty = 'specialty',
  availabilityStartDateRange = 'availabilityStartDateRange',
  startDateRange = 'startDateRange',
  endDateRange = 'endDateRange',
  facilityName = 'facilityName',
  city = 'city',
  state = 'state',
  nonContactedDate = 'nonContactedDate',
  lastApplicationDateRange = 'lastApplicationDateRange',
  availableToStartDateRange = 'availableToStartDateRange',
  callBackDateRange = 'callBackDateRange',
  activeCues = 'activeCues',
  arbitrationStatus = 'arbitrationStatus',
}

export const getFilterSpecs = (filterSpecs, falseRecruiters, t, arbitrationAgreementFlag: boolean): any[] => {
  const filters = [
    {
      filterName: t('home.recruiterWorkDesk.placementFilter.placementRecruiter'),
      name: recPlacementsFilterName.placementRecruiter,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      options: falseRecruiters,
      fallbackPlacements: ['top-start', 'bottom-start', 'right']
    },
    {
      filterName: t('home.recruiterWorkDesk.placementFilter.candidate'),
      name: recPlacementsFilterName.candidateName,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      options: filterSpecs.candidateNameOpts,
      selectAllValue: 'All',
    },
    {
      filterName: t('home.recruiterWorkDesk.placementFilter.discipline'),
      name: recPlacementsFilterName.discipline,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      options: filterSpecs.disciplineOpts,
      selectAllValue: 'All',
    },
    {
      filterName: t('home.recruiterWorkDesk.placementFilter.specialty'),
      name: recPlacementsFilterName.specialty,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      options: filterSpecs.specialtyOpts,
      selectAllValue: 'All',
    },
    {
      filterName: t('home.recruiterWorkDesk.placementFilter.availabilityStartDateRange'),
      name: recPlacementsFilterName.availabilityStartDateRange,
      type: FilterCompTypes.DATERANGEV2,
      variant: 'outlined',
      secondaryLabel: t('home.recruiterWorkDesk.placementFilter.availabilityStartDateRange'),
      useMaxWidth: true,
      maxWidth: 210,
      clickAway: true,
      disablePortal: true,
      quickSelect: false,
    },
    {
      filterName: t('home.recruiterWorkDesk.placementFilter.startDateRange'),
      name: recPlacementsFilterName.startDateRange,
      type: FilterCompTypes.DATERANGEV2,
      variant: 'outlined',
      secondaryLabel: t('home.recruiterWorkDesk.placementFilter.startDateRange'),
      useMaxWidth: true,
      maxWidth: 210,
      clickAway: true,
      disablePortal: true,
      quickSelect: false,
    },
    {
      filterName: t('home.recruiterWorkDesk.placementFilter.endDateRange'),
      name: recPlacementsFilterName.endDateRange,
      type: FilterCompTypes.DATERANGEV2,
      variant: 'outlined',
      secondaryLabel: t('home.recruiterWorkDesk.placementFilter.endDateRange'),
      useMaxWidth: true,
      maxWidth: 210,
      clickAway: true,
      disablePortal: true,
      quickSelect: false,
    },
    {
      filterName: t('home.recruiterWorkDesk.placementFilter.facilityName'),
      name: recPlacementsFilterName.facilityName,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      options: filterSpecs.facilityNameOpts,
      selectAllValue: 'All',
    },
    {
      filterName: t('home.recruiterWorkDesk.placementFilter.city'),
      name: recPlacementsFilterName.city,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      options: filterSpecs.cityOpts,
      selectAllValue: 'All',
    },
    {
      filterName: t('home.recruiterWorkDesk.placementFilter.state'),
      name: recPlacementsFilterName.state,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      options: filterSpecs.stateOpts,
      selectAllValue: 'All',
    },
    {
      filterName: t('home.recruiterWorkDesk.placementFilter.activeCues'),
      name: recPlacementsFilterName.activeCues,
      type: FilterCompTypes.DATERANGEV2,
      variant: 'outlined',
      secondaryLabel: t('home.recruiterWorkDesk.placementFilter.activeCues'),
      useMaxWidth: true,
      maxWidth: 210,
      clickAway: true,
      disablePortal: true,
      definedRanges: [
        {
          label: 'All',
          startDate: getAllRangeStart(),
          endDate: getAllRangeEnd(),
          useLabelAsValue: false,
        },
        {
          label: 'Today',
          startDate: new Date(),
          endDate: new Date(),
          useLabelAsValue: false,
        },
        {
          label: 'Last 2 Days',
          startDate: addDays(new Date(), -2),
          endDate: new Date(),
          useLabelAsValue: false,
        },
        {
          label: 'Last 7 Days',
          startDate: addDays(new Date(), -7),
          endDate: new Date(),
          useLabelAsValue: false,
        },
        {
          label: 'Last 60 Days',
          startDate: addDays(new Date(), -60),
          endDate: new Date(),
          useLabelAsValue: false,
        },
      ],
    }
  ]
  const arbitrationFilter =
    [{
      filterName: t('home.recruiterWorkDesk.placementFilter.arbitrationStatus'),
      name: recPlacementsFilterName.arbitrationStatus,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      options: filterSpecs.arbitrationStatusOpts,
    }]

  const filterList = arbitrationAgreementFlag ? [...filters, ...arbitrationFilter] : filters
  return filterList;
};
////////Advanced Filter/////////

////////Last Contacted//////////

const lastContactedOpts = [
  { id: 0, name: '1 Day', value: '1 Day' },
  { id: 1, name: '2 Days', value: '2 Days' },
  { id: 2, name: '7 Days', value: '7 Days' },
  { id: 3, name: '20 Days', value: '20 Days' },
  { id: 4, name: '30 Days', value: '30 Days' },
  { id: 5, name: '60 Days', value: '60 Days' },
];

const lastApplicationOpts = [
  { id: 0, name: 'Today', value: 'Today' },
  { id: 1, name: 'This Week', value: 'This Week' },
  { id: 2, name: 'Last 30 Days', value: 'Last 30 Days' },
  { id: 3, name: 'Last 60 Days', value: 'Last 60 Days' },
];

const availableToStartOpts = [
  { id: 0, name: 'This Week', value: 'This Week' },
  { id: 1, name: 'This Month', value: 'This Month' },
  { id: 2, name: 'Next Month', value: 'Next Month' },
];

const callBackOpts = [
  { id: 0, name: 'Today', value: 'Today' },
  { id: 1, name: 'This Week', value: 'This Week' },
  { id: 2, name: 'Last 30 Days', value: 'Last 30 Days' },
  { id: 3, name: 'Last 60 Days', value: 'Last 60 Days' },
];

export const getAdvancedFilterSpecs = t => [
  {
    name: recPlacementsFilterName.nonContactedDate,
    type: FilterCompTypes.CALENDAR,
    filterName: t('home.recruiterWorkDesk.placementFilter.nonContactedDate'),
    selectLabel: t('Last'),
    options: lastContactedOpts,
    onSelectFieldChange: value => getLastContactedDate(lastContactedOpts.findIndex(opt => opt.value === value)),
  },
  {
    name: recPlacementsFilterName.lastApplicationDateRange,
    type: FilterCompTypes.DATERANGE,
    filterName: t('home.recruiterWorkDesk.placementFilter.lastApplicationDateRange'),
    options: lastApplicationOpts,
    onSelectFieldChange: value =>
      getLastApplicationDateRange(lastApplicationOpts.findIndex(opt => opt.value === value)),
  },
  {
    name: recPlacementsFilterName.availableToStartDateRange,
    type: FilterCompTypes.DATERANGE,
    filterName: t('home.recruiterWorkDesk.placementFilter.availableToStartDateRange'),
    options: availableToStartOpts,
    onSelectFieldChange: value =>
      getAvailableToStartDateRange(availableToStartOpts.findIndex(opt => opt.value === value)),
  },
  {
    name: recPlacementsFilterName.callBackDateRange,
    type: FilterCompTypes.DATERANGE,
    filterName: t('home.recruiterWorkDesk.placementFilter.callBackDateRange'),
    options: callBackOpts,
    onSelectFieldChange: value => getCallBackDateRange(callBackOpts.findIndex(opt => opt.value === value)),
  },
];

export const defaultValues: IPlacementFilter = {
  placementRecruiter: [],
  candidateName: [],
  discipline: [],
  specialty: [],
  availabilityStartDateRange: null,
  startDateRange: null,
  endDateRange: null,
  facilityName: [],
  city: [],
  state: [],
  nonContactedDate: null,
  lastApplicationDateRange: null,
  availableToStartDateRange: null,
  callBackDateRange: null,
  activeCues: null,
  arbitrationStatus: [],
};
