import { Typography, Avatar } from 'amn-ui-core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { BoxContainer } from '../../CustomComponents/BoxContainer';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import { selectUser } from 'oidc/user.selectors';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { useSelector } from 'react-redux';
import { EditReferences } from './EditReferences';
import { selectFacility, selectIsFacilityByIdRequestSuccess } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips/CustomTooltip';
import { ReferencesSkeleton } from '../Utils';
import moment from 'moment';
import { missingField } from 'app/constants';
import { useReferencesStyles } from './styles';

export const FacilityReferences = () => {
  const { t } = useTranslation();
  const { classes } = useReferencesStyles();
  const userData = useSelector(selectUser);
  const facility = useSelector(selectFacility);
  const facilityDetailsResponse = facility?.facilityResponse;
  const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
  const isAuthorized = Authorized(
    [
      userRoles.clinical_ClientContractSpecialist,
      userRoles.accountManagement,
      userRoles.accountManagement_Leadership,
      userRoles.accountManagement_TeamMember,
    ],
    userData.userInfo,
  );
  const facilityLoading = useSelector(selectIsFacilityByIdRequestSuccess);

  return (
    <>
      <BoxContainer
        title={t('facility.references.title')}
        icon={<FolderSharedOutlinedIcon fontSize="small" />}
        onClick={() => setPopupOpen(true)}
        rightIcon={isAuthorized ? <CreateOutlinedIcon /> : false}
        iconToolTip="Edit"
      >
        {facilityLoading ? (
          <ReferencesSkeleton />
        ) : (
          <LayoutGridItem container direction="column" rowSpacing={2}>
            <LayoutGridItem container>
              {facilityDetailsResponse?.submissionRequiredReference ? (
                <CustomTooltip tooltipContent={t('facility.references.tooltipContent')}>
                  <Avatar className={classes.avatar}>{t('facility.references.requiredAvatarText')}</Avatar>
                </CustomTooltip>
              ) : null}
            </LayoutGridItem>
            <LayoutGridItem container>
              <LayoutGridItem item>
                <Typography className={classes.textBold}>{t('facility.references.referencesRequired')}</Typography>
              </LayoutGridItem>
              <LayoutGridItem item ml="36px">
                <Typography variant="body1" sx={{ color: 'system.black' }}>
                  {facilityDetailsResponse?.numberOfReferenceRequired || missingField}
                </Typography>
              </LayoutGridItem>
            </LayoutGridItem>
            <LayoutGridItem container>
              <LayoutGridItem item>
                <Typography className={classes.textBold}>{t('facility.references.referenceToSubmit')}</Typography>
              </LayoutGridItem>
              <LayoutGridItem item ml="25px">
                <Typography variant="body1" sx={{ color: 'system.black' }}>
                  {facilityDetailsResponse?.numberOfReferenceToRFO || missingField}
                </Typography>
              </LayoutGridItem>
            </LayoutGridItem>
            <LayoutGridItem container>
              <LayoutGridItem item>
                <Typography className={classes.textBold}>{t('facility.references.withinLastMonth')}</Typography>
              </LayoutGridItem>
              <LayoutGridItem item ml="86px">
                <Typography variant="body1" sx={{ color: 'system.black' }}>
                  {facilityDetailsResponse?.entireWorkHistory
                    ? t('facility.references.entireWorkHistory')
                    : `${facilityDetailsResponse?.withinLastMonthsReferenceChanged ?? missingField} months`}
                </Typography>
              </LayoutGridItem>
            </LayoutGridItem>
            <LayoutGridItem container>
              <LayoutGridItem item>
                <Typography className={classes.textBold}>{t('facility.references.supervisorAndAbove')}</Typography>
              </LayoutGridItem>
              <LayoutGridItem item ml="49px">
                <Typography variant="body1" sx={{ color: 'system.black' }}>
                  {facilityDetailsResponse?.supervisorAndAboveReference ? 'Yes' : 'No'}
                </Typography>
              </LayoutGridItem>
            </LayoutGridItem>
            <LayoutGridItem container>
              <LayoutGridItem item>
                <Typography className={classes.textBold}>{t('facility.references.lastUpdated')}</Typography>
              </LayoutGridItem>
              <LayoutGridItem item ml="95px">
                <Typography variant="body1" sx={{ color: 'system.black' }}>
                  {facilityDetailsResponse?.facilityReferenceChangedDate
                    ? moment(facilityDetailsResponse?.facilityReferenceChangedDate).format('MM/DD/YYYY hh:mm A')
                    : missingField}
                </Typography>
              </LayoutGridItem>
            </LayoutGridItem>
            <LayoutGridItem container>
              <LayoutGridItem item>
                <Typography className={classes.textBold}>{t('facility.references.lastUpdatedBy')}</Typography>
              </LayoutGridItem>
              <LayoutGridItem item ml="78px">
                <Typography variant="body1" sx={{ color: 'system.black' }}>
                  {facilityDetailsResponse?.facilityReferenceChangedBy?.firstName &&
                  facilityDetailsResponse?.facilityReferenceChangedBy?.lastName
                    ? `${facilityDetailsResponse?.facilityReferenceChangedBy?.firstName} ${facilityDetailsResponse?.facilityReferenceChangedBy?.lastName}`
                    : missingField}
                </Typography>
              </LayoutGridItem>
            </LayoutGridItem>
          </LayoutGridItem>
        )}
      </BoxContainer>
      {isPopupOpen && <EditReferences isPopupOpen={isPopupOpen} setPopupOpen={setPopupOpen} />}
    </>
  );
};
