import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ClientConfirmationSteps,
  IPlacementClientConfirmationDoc,
  IPlacementSubmitPacket,
  PlacementDocument,
  PlacementDocuments,
  SubmitPacketSteps,
} from 'store/redux-store/placement-documents/types';
import { serviceActions } from './async-actions';

export const initialState: PlacementDocuments = {
  documents: undefined,
  submitPackets: [],
  clientConfirmationDocs: [],
  miscellaneous: {
    currentSubmitPacketStep: SubmitPacketSteps.initial,
    currentClientConfirmationStep: ClientConfirmationSteps.initial,
  },
  logId: '',
  emailLogs: [],
  openEmailDeliveryStatus: false
};

const PlacementDocumentsSlice = createSlice({
  name: 'placementDocuments',
  initialState,
  reducers: {
    setPlacementDocuments(state, action: PayloadAction<PlacementDocument[]>) {
      return {
        ...state,
        documents: action.payload,
      };
    },
    setSubmitPackets(state, action: PayloadAction<IPlacementSubmitPacket[]>) {
      return {
        ...state,
        submitPackets: action.payload,
      };
    },
    setClientConfirmationDocs(state, action: PayloadAction<IPlacementClientConfirmationDoc[]>) {
      return {
        ...state,
        clientConfirmationDocs: action.payload,
      };
    },
    setSelectedPacket(state, action: PayloadAction<IPlacementSubmitPacket>) {
      return {
        ...state,
        selectedPacket: state.selectedPacket?.documentId !== action.payload?.documentId ? action.payload : undefined,
      };
    },
    setSelectedClientConfirmationDoc(state, action: PayloadAction<IPlacementClientConfirmationDoc>) {
      return {
        ...state,
        selectedConfirmationDoc:
          state.selectedConfirmationDoc?.documentId !== action.payload?.documentId ? action.payload : undefined,
      };
    },
    setMiscellaneousField(
      state,
      action: PayloadAction<{
        key: 'currentSubmitPacketStep' | 'errorProps' | 'currentClientConfirmationStep';
        data: any;
      }>,
    ) {
      return {
        ...state,
        miscellaneous: {
          ...state.miscellaneous,
          [action.payload.key]: action.payload.data,
        },
      };
    },
    setOpenEmailDeliveryStatus(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        openEmailDeliveryStatus : action.payload
      }
    },
    setLogId(state, action: PayloadAction<string>) {
      return {
        ...state,
        logId : action.payload
      }
    },
    setEmailLogs(state, action: PayloadAction<any[]>){
      return {
        ...state,
        emailLogs : action.payload
      }
    },
    resetSubmitPacktes(state) {
      return {
        ...state,
        submitPackets: [],
        selectedPacket: undefined,
        miscellaneous: {
          ...state.miscellaneous,
          errorProps: undefined,
          currentSubmitPacketStep: SubmitPacketSteps.initial,
        },
      };
    },
    resetClientConfirmationDocs(state) {
      return {
        ...state,
        clientConfirmationDocs: [],
        selectedConfirmationDoc: undefined,
        miscellaneous: {
          ...state.miscellaneous,
          errorProps: undefined,
          currentClientConfirmationStep: ClientConfirmationSteps.initial,
        },
      };
    },
    reset() {
      return {
        ...initialState,
      };
    },
  },
});

const { actions } = PlacementDocumentsSlice;
export const { reducer: placementDocumentsReducer, name: placementDocumentsSliceKey } = PlacementDocumentsSlice;
export const placementDocumentsActions = {
  ...actions,
  ...serviceActions,
};
