import { useEffect, useState } from 'react';
import { CONSTANTS_NOTIFICATIONS } from '../Common/FormatData';
import {
  getEmployeeId,
  getNotificationType,
  getRenderMenuItems,
  markNotificationAsRead,
  sendAcknowledgeCueNote,
} from './utils';
import { ActionPanel } from './ActionPanel/ActionPanel';
import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch } from 'react-redux';
import { SessionKey, StorageType, setSessionValue } from 'utils/customHooks/sessionStorage/sessionHelpers';
import { taskDetailsActions } from '@AMIEWEB/Tasks/store/Tasks.redux';
import { NotificationCategory } from 'app/models/Tasks/Tasks';
import { useTranslation } from 'react-i18next';

export const RenderActionPanel = props => {
  const {
    args: {
      showActionPanel,
      classes,
      getMenuClass,
      notifyData,
      setAnchorEl2,
      isUnread,
      handleNotificationClick,
      userInfo,
      pageNumber,
      setRecordDetails,
      record,
      pushNotification = false,
    },
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openGoToPopper, setopenGoToPopper] = useState(false);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [_isUnread, _setIsUnread] = useState(isUnread);
  const renderList = getRenderMenuItems(notifyData?.contentProps, _isUnread, openGoToPopper);
  const { id, useType, useSubType, channel } = notifyData?.contentProps;
  const notificationCategory = getNotificationType({ useType, useSubType, channelType: channel });
  const disableGetActivityFetch = true;
  useEffect(() => {_setIsUnread(isUnread)},[isUnread])

  const onClickActionMenu = ({ name, event }) => {
    if (name === CONSTANTS_NOTIFICATIONS.ACTION_PANEL.GO_TO) {
      setopenGoToPopper(true);
      setAnchorEl2(event.currentTarget);
      event.stopPropagation();
    } else if (name === CONSTANTS_NOTIFICATIONS.ACTION_PANEL.MARK_READ) {
      markNotificationAsRead(
        [id],
        parseInt(getEmployeeId(userInfo)),
        dispatch,
        notificationCategory,
        pageNumber,
        isUnread,
      );
      pushNotification && dispatch(taskDetailsActions.setDismissNotificationId(id));
      event.stopPropagation();
    } else if (name === CONSTANTS_NOTIFICATIONS.ACTION_PANEL.OPEN_TASK) {
      handleNotificationClick(notifyData?.contentProps);
      markNotificationAsRead(
        [id],
        parseInt(getEmployeeId(userInfo)),
        dispatch,
        notificationCategory,
        pageNumber,
        isUnread,
        disableGetActivityFetch,
      );
      pushNotification && dispatch(taskDetailsActions.setDismissNotificationId(id));
      event.stopPropagation();
    } else if (name === CONSTANTS_NOTIFICATIONS.ACTION_PANEL.CREATE_PID) {
      markNotificationAsRead(
        [id],
        parseInt(getEmployeeId(userInfo)),
        dispatch,
        notificationCategory,
        pageNumber,
        isUnread,
        disableGetActivityFetch,
      );
      if (record && record?.candidateId && record?.BrandId && record?.orderId) {
        setSessionValue(
          SessionKey.createPlacementNotifications,
          {
            orderId: parseInt(record?.OrderId),
            brandId: parseInt(record?.BrandId),
            candidateId: parseInt(record?.CandidateId),
          },
          StorageType.sessionStorage,
        );
      }
      setRecordDetails({ ...record, openCreatePlacementForm: true });

      event.stopPropagation();
    } else if (name === CONSTANTS_NOTIFICATIONS.ACTION_PANEL.VIEW_CUE) {
      handleNotificationClick(notifyData?.contentProps);
      markNotificationAsRead(
        [id],
        parseInt(getEmployeeId(userInfo)),
        dispatch,
        notificationCategory,
        pageNumber,
        isUnread,
        disableGetActivityFetch,
      );
      event.stopPropagation();
    } else if (name === CONSTANTS_NOTIFICATIONS.ACTION_PANEL.NO_CUE) {
      sendAcknowledgeCueNote({ employeeId: parseInt(getEmployeeId(userInfo)), dispatch, notifyData, t });
      markNotificationAsRead(
        [id],
        parseInt(getEmployeeId(userInfo)),
        dispatch,
        notificationCategory,
        pageNumber,
        isUnread,
        disableGetActivityFetch,
      );
      pushNotification && dispatch(taskDetailsActions.setDismissNotificationId(id));
      event.stopPropagation();
    } else if (name === CONSTANTS_NOTIFICATIONS.ACTION_PANEL.VIEW_STATUS) {
      handleNotificationClick(notifyData?.contentProps);
      markNotificationAsRead(
        [id],
        parseInt(getEmployeeId(userInfo)),
        dispatch,
        notificationCategory,
        pageNumber,
        isUnread,
        disableGetActivityFetch,
      );
      event.stopPropagation();
    }
    !(name === CONSTANTS_NOTIFICATIONS.ACTION_PANEL.GO_TO) && _setIsUnread(false);
  };
  if (showActionPanel) {
    return (
      <>
        <div className={classes.actionPanelContainer}>
          <ActionPanel
            renderList={renderList}
            onClickActionMenu={onClickActionMenu}
            setopenGoToPopper={setopenGoToPopper}
            isUnread={_isUnread}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className={getMenuClass(notifyData?.contentProps)}>
          <div
            style={{
              marginLeft: notifyData.channel === NotificationCategory.Email ? '-40px' : '20px',
              marginTop: notifyData.channel === NotificationCategory.Email && '12px',
            }}
          >
            <ArrowBackIosIcon fontSize="small" />
          </div>
        </div>
      </>
    );
  }
};
