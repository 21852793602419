import React from 'react';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { useTranslation } from 'react-i18next';

export const TemplateDeleteDialog = props => {
  const { templateName, openDeleteDialog, onDeleteDialogClose, deletePersonalTemplate } = props;
  const { t } = useTranslation();
  return (
    <GenericDialog
      open={openDeleteDialog}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          event.preventDefault();
          event.stopProgagation();
          return;
        }
      }}
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
      dialogTitleProps={{
        style: { paddingTop: '20px' },
        text: 'Delete Template?',
        closeButton: false,
      }}
      dialogActions={[
        {
          text: 'CANCEL',
          variant: 'text',
          onClick: () => onDeleteDialogClose(),
        },
        {
          text: 'DELETE',
          variant: 'contained',
          color: 'primary',
          onClick: () => deletePersonalTemplate(),
        },
      ]}
    >
      <span>
        <span data-testid={'template-delete-confirmation'}>{t('notification.emails.deleteTemplateDialogContent.1')}<span style={{ fontWeight: 500 }}>{templateName}</span>{t('notification.emails.deleteTemplateDialogContent.2')}</span>
      </span>
    </GenericDialog>
  );
};
