import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectEditableRequirementTypes,
  selectServiceFailures,
  selectSubmissionInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { RequirementsRow } from '../../TableComponents/TableComponents';
import { ListSection } from '../../../customComponents/ListSection';
import { missingField } from 'app/constants';
import styledComponent from 'styled-components';
import { editableFields, editAccessor } from 'app/components/Placement/PlacementDetails/editUtils';
import { RequestedShifts } from 'app/components/Placement/PlacementDetails/Edit/Components/RequestedShifts';
import { usePromiseTracker } from 'react-promise-tracker';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import { Typography } from 'amn-ui-core';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';

export const Schedule = () => {
  const { t } = useTranslation();
  const {
    orderReq: {
      order: { schedule: orderReq },
    },
    requirementFilter,
  } = useSelector(selectSubmissionInfo);
  const { shiftOffered } = useSelector(selectEditableRequirementTypes);
  const { promiseInProgress } = usePromiseTracker({
    area: 'placement-submission-schedule-order-requirement',
    delay: 0,
  });
  const { scheduleOrderRequirement: serviceFailed } = useSelector(selectServiceFailures);
  const { placementId } = useContext(AddOnContext);
  const dispatch = useDispatch();
  const { display } = useRequirementFilter('submission-order', requirementFilter, shiftOffered?.requirement);

  return display ? (
    <RequirementsRow
      id="placement-submission-order-schedule"
      title={t('placement.profile.summary.submission.order.schedule.title')}
      requirementCell={
        orderReq ? (
          <RowKey
            schedule={{
              ...orderReq,
              data: {
                ...orderReq?.data,
                shiftOffered: { ...orderReq?.data?.shiftOffered, type: shiftOffered?.requirement },
              },
            }}
          />
        ) : (
          missingField
        )
      }
      candidateCell={<RowValue t={t} />}
      loading={promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() =>
        dispatch(placementSummaryActions.getScheduleOrderRequirement({ placementId, isRetryingService: true }))
      }
    />
  ) : null;
};

const RowKey = ({ schedule }) => {
  return <ListSection listType="schedule" items={[{ ...schedule.data }]} />;
};

const RowValue = ({ t }) => {
  return (
    <RowDiv variant="body1">
      <ShiftTag>{`${t('placement.profile.summary.submission.order.schedule.line1')}:`}</ShiftTag>
      <RequestedShifts id={`${editAccessor}-${editableFields.requestedShift}`} />
    </RowDiv>
  );
};

const RowDiv = styledComponent(Typography)`
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

const ShiftTag = styledComponent.div`
  white-space: nowrap;
`;
