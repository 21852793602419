import { Paper, TableContainer, Table, TableRow, TableCell, AppBar, Toolbar, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { CompareReference } from '../../../models/enums/CompareReference';
import GreenCheckIcon from 'app/assets/images/IconImages/green-tick-icon.svg';
import RedCrossIcon from 'app/assets/images/IconImages/RedCrossIcon.svg';
import BlueQstIcon from 'app/assets/images/IconImages/question-mark.svg';
import SvgIcon from '@mui/material/SvgIcon';
import styledComponent from 'styled-components';
import { Link } from 'amn-ui-core';

const useStyles = makeStyles()(theme => ({
  root: {
    maxHeight: 500,
    overflow: 'auto',
    border: '1px solid #d1d1d1',
  },
  tableRoot: {
    backGroundColor: '#ffffff',
  },
  tableHead: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#555555',
    borderRight: '2px solid #d1d1d1',
    whiteSpace: 'nowrap',
  },
  tableBody: {
    fontSize: 12,
    borderRight: '1px solid #d1d1d1',
    whiteSpace: 'nowrap',
  },
  sectTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#555555',
  },
  sectBar: {
    padding: 0,
  },
  headerBar: {
    backgroundColor: 'rgba(209,209,209,0.23)',
  },
  headerBarShadow: {
    boxShadow: 'none',
    backgroundColor: 'rgba(209,209,209,0.03)',
  },
  denseToolbar: {
    paddingLeft: theme.spacing(2),
  },
  headColumn: {
    backgroundColor: '#ffffff',
  },
  headerBarCell: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#555555',
    whiteSpace: 'nowrap',
    zIndex: 10,
  },
  emptyBarCell: {
    zIndex: 11,
  },
  emptyBodyCell: {
    backgroundColor: 'rgba(209,209,209,0.13)',
  },
}));

export interface IRowAttributes {
  key: string;
  title?: string;
  fieldIsALink?: boolean;
  attributeValues?: IRowAttributeValues[];
}

export interface IRowAttributeValues {
  link?: string;
}

export const ComparisonGrid = props => {
  const { comparisonData } = props;
  const { classes } = useStyles();

  return (
    <TableContainer elevation={0} component={Paper} classes={{ root: classes.root }}>
      <Table stickyHeader classes={{ root: classes.tableRoot }} data-testid="comparison-grid">
        {comparisonData.map((sect, index) => (
          <TableSects section={sect} key={index} handleClick={props.handleClick} />
        ))}
      </Table>
    </TableContainer>
  );
};

const TableSects = props => {
  return (
    <>
      <thead>{GetTableHeader(props)}</thead>
      <tbody>{GetTableRows(props)}</tbody>
    </>
  );
};

const GetTableHeader = props => {
  const { classes } = useStyles();
  const { section } = props;
  if (section.headerKey) {
    const rowAttribute =
      section.rowAttributes && section.rowAttributes.find(attribute => attribute.key === section.headerKey);
    return (
      <TableRow classes={{ root: classes.headerBar }}>
        <TableCell variant="head" color="inherit" classes={{ head: classes.emptyBarCell }}></TableCell>
        {section.sectData.map((item, colIndex) => (
          <TableCell variant="head" color="inherit" classes={{ head: classes.headerBarCell }} key={colIndex}>
            {rowAttribute && rowAttribute.fieldIsALink ? (
              <Link
                href={rowAttribute.attributeValues[colIndex].link}
                onClick={() => props.handleClick(rowAttribute.key)}
              >
                {item[section.headerKey]}
              </Link>
            ) : (
              item[section.headerKey]
            )}
          </TableCell>
        ))}
      </TableRow>
    );
  } else if (section.headerTitle)
    return (
      <TableRow>
        <TableCell variant="head" classes={{ head: classes.sectBar }}>
          <AppBar position="static" classes={{ root: classes.headerBarShadow }}>
            <Toolbar variant="dense" classes={{ dense: classes.denseToolbar }}>
              <Typography classes={{ root: classes.sectTitle }} color="inherit">
                {section.headerTitle}
              </Typography>
            </Toolbar>
          </AppBar>
        </TableCell>
        {section.sectData.map((item, colIndex) => (
          <TableCell classes={{ body: classes.emptyBodyCell }} variant="body" key={colIndex}></TableCell>
        ))}
      </TableRow>
    );
};

const GetTableRows = props => {
  const { classes } = useStyles();
  const { section } = props;
  const keys = Object.keys(section.sectData[0]);
  if (section.headerKey) keys.splice(0, 1);
  return keys.map((key, rowIndex) => {
    const rowAttribute = section.rowAttributes && section.rowAttributes.find(attribute => attribute.key === key);

    return (
      <TableRow key={rowIndex}>
        <TableCell variant="head" classes={{ head: classes.tableHead, stickyHeader: classes.headColumn }}>
          {rowAttribute && rowAttribute.title ? rowAttribute.title : GetTitle(key)}
        </TableCell>
        {section.sectData.map((item, colIndex) => (
          <TableCell variant="body" classes={{ body: classes.tableBody }} key={colIndex}>
            {rowAttribute && rowAttribute.fieldIsALink ? (
              <Link
                href={rowAttribute.attributeValues[colIndex].link}
                onClick={() => props.handleClick(rowAttribute.key)}
              >
                {item[key]}
              </Link>
            ) : (
              item[key]
            )}
          </TableCell>
        ))}
      </TableRow>
    );
  });
};

const GetTitle = (name: string) => {
  name = name.charAt(0).toUpperCase() + name.slice(1);
  const words = name.match(/([A-Z]?[^A-Z]*)/g)?.slice(0, -1);
  if (words) return words.join(' ');
  return name.charAt(0).toUpperCase() + name.slice(1);
};

export const GetCompareStatus = (status: string, fieldValue?: string) => {
  switch (status) {
    case CompareReference.Match:
      if (fieldValue)
        return (
          <>
            <SvgIcon>
              <img src={GreenCheckIcon} />
            </SvgIcon>
            <FieldValue>{fieldValue}</FieldValue>
          </>
        );
      return (
        <SvgIcon>
          <img src={GreenCheckIcon} />
        </SvgIcon>
      );
    case CompareReference.NotMatch:
      if (fieldValue)
        return (
          <>
            <SvgIcon>
              <img src={RedCrossIcon} />
            </SvgIcon>
            <FieldValue>{fieldValue}</FieldValue>
          </>
        );
      return (
        <SvgIcon>
          <img src={RedCrossIcon} />
        </SvgIcon>
      );
    case CompareReference.Unknown:
      if (fieldValue)
        return (
          <>
            <SvgIcon>
              <img src={BlueQstIcon} />
            </SvgIcon>
            <FieldValue>{fieldValue}</FieldValue>
          </>
        );
      return (
        <SvgIcon>
          <img src={BlueQstIcon} />
        </SvgIcon>
      );
  }
};

const FieldValue = styledComponent.span`
  padding-left: 15px;
`;
