import { tooltipType } from 'app/components/Common/Tooltips';
import { ICustomHeader } from 'app/models/Grid';
import { getDateValue } from 'utils/dates/dateExtraction';
import { FilterCompTypes } from '../../../../ComponentLibrary/Filter/utils';
import { PanelChoice } from '../PanelOptions/PanelOption.redux';

interface IGridColumns extends Omit<ICustomHeader, 'title'> {
  title:
    | 'interestDate'
    | 'orderId'
    | 'facilityName'
    | 'city'
    | 'state'
    | 'expectedStartDate'
    | 'disciplineSpecialty'
    | 'shifts'
    | 'placementLength'
    | 'accountManager'
    | 'weeklyTakeHomePay'
    | 'milesAway'
    | 'radiusRules'
    | 'positionsAvailable'
    | 'filesInProgress'
    | 'summary';
}

export const getjobInterestGridHeaders = t =>
  [
    {
      value: t('candidate.jobInterestHeaders.interestDate'),
      title: 'interestDate',
      sortable: true,
    },
    {
      value: t('candidate.jobInterestHeaders.orderID'),
      title: 'orderId',
      clickable: true,
      sortable: true,
      isNumber: true,
    },
    {
      value: t('candidate.jobInterestHeaders.facilityName'),
      title: 'facilityName',
      clickable: true,
      sortable: true,
      navigationIds: ['facilityId'],
      navigationExp: '/facility/{0}',
      hasHoverView: true,
    },
    { value: t('candidate.jobInterestHeaders.city'), title: 'city', sortable: true },
    { value: t('candidate.jobInterestHeaders.state'), title: 'state', sortable: true },
    {
      value: t('candidate.jobInterestHeaders.expectedStartDate'),
      title: 'expectedStartDate',
      sortable: true,
      sortByField: 'expectedStartDateNoFormat',
    },
    {
      value: t('candidate.jobInterestHeaders.skillSet'),
      title: 'disciplineSpecialty',
      sortable: true,
      multiLine: true,
    },
    { value: t('candidate.jobInterestHeaders.shifts'), title: 'shifts', sortable: true },
    {
      value: t('candidate.jobInterestHeaders.placementLength'),
      title: 'placementLength',
      sortable: true,
      isNumber: true,
    },
    {
      value: t('candidate.jobInterestHeaders.accountManager'),
      title: 'accountManager',
      sortable: true,
      tooltip: tooltipType.IterableKeyTooltip,
      tooltipAttributes: 'accountManagerAttributes',
    },
    {
      value: t('candidate.jobInterestHeaders.weeklyGrossPay'),
      title: 'weeklyTakeHomePay',
      sortable: true,
      sortByField: 'weeklyTakeHomePayValue',
    },
    { value: t('candidate.jobInterestHeaders.milesAway'), title: 'milesAway', sortable: true, isNumber: true },
    { value: t('candidate.jobInterestHeaders.radiusRule'), title: 'radiusRules', sortable: true, isNumber: true },
    {
      value: t('candidate.jobInterestHeaders.positionAvailable'),
      title: 'positionsAvailable',
      sortable: true,
      isNumber: true,
    },
    {
      value: t('candidate.jobInterestHeaders.filesInProgress'),
      title: 'filesInProgress',
      sortable: true,
      hasHoverView: true,
    },
    { value: t('candidate.jobInterestHeaders.summaryInfo'), icon: true, title: 'summary', sortable: false },
  ] as IGridColumns[];

export const getNavData = (lastBreadcrumb: string, travelerId: number, brandId: number) => ({
  backButtonURL: `candidate/${travelerId}/${brandId}?panel=${PanelChoice.JobInterestGrid.toLowerCase()}`,
  breadcrumbHierarchy: [
    {
      title: 'Professional Profile',
      order: 1,
      returnURL: `candidate/${travelerId}/${brandId}`,
      clickable: true,
    },
    {
      title: 'Job Interest',
      order: 2,
      returnURL: `candidate/${travelerId}/${brandId}?panel=${PanelChoice.JobInterestGrid.toLowerCase()}`,
      clickable: true,
    },
    {
      title: lastBreadcrumb,
      order: 3,
      returnURL: '',
      clickable: false,
    },
  ],
});

export const OrderStatus = [
  {
    id: 0,
    name: 'All',
    value: 'All',
  },
  {
    id: 1,
    name: 'Open',
    value: 'Open',
  },
  {
    id: 2,
    name: 'Closed',
    value: 'Closed',
  },
];

export const InterestDate = [
  {
    id: 0,
    name: 'Custom',
    value: 'Custom',
  },
  {
    id: 1,
    name: 'All Time',
    value: 'All Time',
  },
  {
    id: 2,
    name: 'Today',
    value: 'Today',
  },
  {
    id: 3,
    name: 'Yesterday',
    value: 'Yesterday',
  },
  {
    id: 4,
    name: 'Last 7 Days',
    value: 'Last 7 Days',
  },
  {
    id: 5,
    name: 'Last 30 Days',
    value: 'Last 30 Days',
  },
  {
    id: 6,
    name: 'Last 90 Days',
    value: 'Last 90 Days',
  },
  {
    id: 7,
    name: 'Last 120 Days',
    value: 'Last 120 Days',
  },
];

export const getJobInterestFilterData = (states, skillSet, t, selectFieldStyle) => [
  {
    filterName: t('candidate.jobInterestFilter.orderStatus'),
    name: 'orderStatus',
    type: FilterCompTypes.SELECT,
    options: OrderStatus,
    selectFieldStyle,
  },
  {
    filterName: t('candidate.jobInterestFilter.interestDate'),
    name: 'interestDate',
    type: FilterCompTypes.SELECT,
    options: InterestDate,
    selectFieldStyle,
  },
  {
    filterName: t('candidate.jobInterestFilter.fromDate'),
    name: 'fromDate',
    type: FilterCompTypes.CALENDAR,
    maxDate: new Date(),
  },
  {
    filterName: t('candidate.jobInterestFilter.toDate'),
    name: 'toDate',
    type: FilterCompTypes.CALENDAR,
    minDate: new Date(),
  },
  {
    filterName: t('candidate.jobInterestFilter.state'),
    name: 'states',
    type: FilterCompTypes.SELECT,
    options: [
      {
        id: 0,
        name: 'All',
        value: 'All',
      },
      ...states.map((state, index) => {
        return {
          id: index,
          name: state,
          value: state,
        };
      }),
    ],
    isMultiSelect: true,
    selectAllValue: 'All',
    selectFieldStyle,
    placeholder: 'Select',
    version0: true,
  },
  {
    filterName: t('candidate.jobInterestFilter.skillSet'),
    name: 'skillSets',
    type: FilterCompTypes.SELECT,
    options: [
      {
        id: 0,
        name: 'All',
        value: 'All',
      },
      ...skillSet.map((skill, index) => {
        return {
          id: index + 1,
          name: skill,
          value: skill,
        };
      }),
    ],
    isMultiSelect: true,
    selectAllValue: 'All',
    selectFieldStyle,
    placeholder: 'Select',
    version0: true,
  },
];

export const filterDefaultValues = {
  orderStatus: OrderStatus[0].value,
  interestDate: InterestDate[5].value,
  fromDate: getDateValue(-30),
  toDate: getDateValue(0, true),
  states: [],
  skillSets: [],
};
