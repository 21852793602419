import { CircularProgress, Grid, TextField, InputAdornment, Tooltip } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import React, { useEffect, useRef, useState } from 'react';
import { ChannelType, ContactType } from 'app/components/Notification/Constants';
import PhoneIcon from 'app/assets/images/Phone.svg';
import MessageIcon from 'app/assets/images/Message.svg';
import PhoneDisabled from 'app/assets/images/CandidateHeader/PhoneDisabled.svg';
import MessageDisabled from 'app/assets/images/CandidateHeader/Text-Disabled.svg';
import ContactTabCallConsent from 'app/assets/images/IconImages/ContactTabCallConsent.svg';
import ContactTabEmailConsent from 'app/assets/images/IconImages/ContactTabEmailConsent.svg';
import ContactTabTextConsent from 'app/assets/images/IconImages/ContactTabTextConsent.svg';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MailBoxIcon from 'app/assets/images/MailBox.svg';
import EmailDisabled from 'app/assets/images/CandidateHeader/email-Disabled.svg';
import MaskedInput from 'react-text-mask';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { MessageDisabledTooltip } from 'app/components/Common/Tooltips/MessageDisabledTooltip';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import {
  useEnableEmailCommunication,
  useEnableSmsCommunication,
  useEnableVoiceCommunication,
} from 'oidc/CommunicationEnabler';
import { ICandidateDrawerConsent } from '@AMIEWEB/Common/Drawer/SidePanel';
const useStyles = makeStyles()({
  phoneInfo: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 0px 5px 10px',
    alignItems: 'center',
    marginBottom: '6px',
  },
  align: {
    display: 'flex',
    flexDirection: 'row',
  },
  numberStyle: {
    width: '111px',
    marginRight: '5px',
    '& .MuiFormHelperText-contained': {
      margin: 0,
      fontSize: '10px !important',
    },
  },
  numberStyleError: {
    width: '111px',
    marginRight: '5px',
    '& .MuiFormHelperText-contained': {
      margin: 0,
      color: 'red',
      fontSize: '10px !important',
    },
  },
  extensionStyle: {
    width: '120px',
    marginRight: '5px',
    marginLeft: '15px',
    '& .MuiFormHelperText-contained': {
      margin: 0,
      fontSize: '10px !important',
    },
  },
  extensionStyleError: {
    width: '111px',
    marginRight: '5px',
    marginLeft: '15px',
    '& .MuiFormHelperText-contained': {
      margin: 0,
      fontSize: '10px !important',
    },
  },

  emailStyle: {
    width: '200px',
    '& .MuiFormHelperText-contained': {
      margin: 0,
      fontSize: '10px !important',
    },
  },
  emailStyleerror: {
    width: '200px',
    '& .MuiFormHelperText-contained': {
      margin: 0,
      color: 'red',
      fontSize: '10px !important',
    },
  },
  emailIconStyle: {
    display: 'flex',
    float: 'right',
    paddingRight: 25,
  },

  textareastyle: {
    backgroundColor: '#fff !important',
    border: 'thin #ececec solid',
    'border-top': '0',
    'border-left': '0',
    'border-right': '0',
    'border-bottom-color': '#bababa',
    '&:focus-visible': {
      outline: 'none',
      background: '#f2f2f2',
    },
    '& .MuiFilledInput-inputHiddenLabel': {
      paddingTop: '1Px',
      paddingBottom: '1px',
      paddingLeft: 0,
    },
    '&:hover': {
      backgroundColor: '#fff',
      'border-color': '#bababa',
      'border-width': '2px',
    },
    '&:focus': {
      backgroundColor: '#fff',
      'border-color': '#bababa',
      'border-width': '2px',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
      'border-color': '#006FB9',
      'border-width': '2px',
    },
    '& .MuiFilledInput-input': {
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& .MuiInputBase-input': {
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  inlineBoxEditItemPadding: {
    padding: '0 0rem',
  },
  inlineBoxEditMainAcceptItem: {
    'padding-right': '0rem',
    '& svg': {
      color: '#006FB9',
      width: '20px',
      height: '20px',
      cursor: 'pointer',
    },
  },
  inlineBoxEditMainRejectItem: {
    'padding-left': '0.2rem',
    '& svg': {
      color: '#8f9a8e',
      width: '20px',
      height: '20px !important',
      cursor: 'pointer',
    },
  },
  saveSuccessText: {
    fontSize: '11px',
    paddingLeft: '4px',
    justifyContent: 'center',
    color: '#008000',
  },
  saveFailedText: {
    fontSize: '11px',
    paddingLeft: '4px',
    justifyContent: 'center',
    color: '#ff0000',
  },
  toolTipAdjust: {
    width: '8px',
    marginLeft: '1px',
  },
  tooltipArrow: {
    color: '#ffffff',
    opacity: 100,
    '&::before': {
      boxShadow: '0px 3px 6px #00000029',
      border: '1px solid #8C8C8C',
    },
  },
  tooltipBackground: {
    backgroundColor: '#ffffff',
    color: '#0D2A3B',
    opacity: 1,
    textAlign: 'left',
    font: '12px/30px Roboto',
    letterSpacing: '0px',
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #8C8C8C',
    lineHeight: '1rem',
  },
  editAlign: {
    marginTop: '10px',
  },
});
export interface dependentPropsInterface {
  data: any;
  fieldName: string;
  fieldType: string;
  handleNotificationData?: (ChannelType, ContactType, manualLog?) => void;
  handleSave: (property: string) => void;
  resetValue: () => void;
  handleChangeValue: (property: string, value: any) => void;
  lastUpdatedDate: string;
  saveStatus: any;
  extensionNotes?: string | null;
  extensionSaveStatus: any;
  resetExtensionNotes: () => void;
  consentsData?: ICandidateDrawerConsent;
}
interface handleClickPropInterface {
  [key: string]: boolean;
}

const TextMaskCustom = props => {
  return (
    <MaskedInput
      {...props}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    />
  );
};

export const CustomContactEdit = (props: dependentPropsInterface) => {
  const {
    data,
    fieldName,
    fieldType,
    handleNotificationData,
    handleSave,
    resetValue,
    handleChangeValue,
    lastUpdatedDate,
    saveStatus,
    extensionNotes,
    extensionSaveStatus,
    resetExtensionNotes,
    consentsData,
  } = props;
  const [isHandleClick, setIsHandleClick] = useState<handleClickPropInterface>({});
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const [toolTip, setToolTip] = useState<boolean>(true);
  const { classes } = useStyles(props, {
    props: props,
  });
  const user = useSelector(selectUser);
  const userPreference = useSelector(selectUserPreference);
  const inputRef = useRef<any>(null);
  const inputExtensionRef = useRef<any>(null);
  const [clickedText, setClickedState] = useState<number>();

  const enableEmailCommunication = useEnableEmailCommunication();
  const enableSmsCommunication = useEnableSmsCommunication();
  const enableVoiceCommunication = useEnableVoiceCommunication();

  const enableInlineEditMode = (field: string) => {
    setToolTip(false);
    setIsHandleClick({ ...isHandleClick, [field]: true });
  };
  useEffect(() => {
    if (clickedText === 0 && isHandleClick[fieldName]) inputRef?.current.firstChild.firstChild.focus();
    else if (clickedText === 1 && isHandleClick[`${fieldName}-extensionnotes`])
      inputExtensionRef?.current.firstChild.firstChild.focus();
  }, [clickedText, isHandleClick, fieldName]);

  const close = (field: string) => {
    setToolTip(true);
    setIsHandleClick({ ...isHandleClick, [field]: false });
    setIsValidEmail(false);
    field === `${fieldName}-extensionnotes` ? resetExtensionNotes() : resetValue();
  };
  const checkValidation = (field: string, value: string) => {
    //add validation
    handleChangeValue(field, value);
  };
  const handleSaveCheck = (field: string) => {
    if (
      (fieldType === 'number' && !data.includes('_')) ||
      data === '' ||
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(data)
    ) {
      setIsValidEmail(false);
      setIsHandleClick({ ...isHandleClick, [field]: false });
      handleSave(field);
      setToolTip(true);
    } else {
      setIsValidEmail(true);
    }
  };
  const NumberTextField = React.useMemo(() => {
    return (
      <>
        <TextField
          variant="filled"
          ref={inputRef}
          InputProps={{
            className: classes.textareastyle,
            disableUnderline: true,
            inputComponent: TextMaskCustom,
          }}
          InputLabelProps={{
            className: ``,
          }}
          helperText={
            <span>
              {saveStatus === 1 && <CircularProgress style={{ width: '12px', height: '12px' }} />}
              {saveStatus === 2 && (
                <>
                  <CheckIcon style={{ width: '15px', height: '15px', color: '#008000' }} />
                  <label className={classes.saveSuccessText}>{`Saved`}</label>
                </>
              )}
              {saveStatus === 0 && saveStatus !== '' && (
                <>
                  <CloseIcon style={{ width: '15px', height: '15px', color: '#ff0000' }} />
                  <label className={classes.saveFailedText}>{`Failed to update`}</label>
                </>
              )}
              {saveStatus === '' && !isHandleClick[fieldName]
                ? lastUpdatedDate === 'Invalid date'
                  ? 'N/A'
                  : lastUpdatedDate
                : !isValidEmail
                ? ''
                : 'Number is not valid'}
            </span>
          }
          className={!isValidEmail ? classes.numberStyle : classes.numberStyleError}
          value={data}
          defaultValue={data}
          hiddenLabel
          onClick={() => {
            setClickedState(0);
            enableInlineEditMode(fieldName);
          }}
          onChange={event => {
            checkValidation(fieldName, event.target.value);
          }}
          type={'string'}
          {...props}
        />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    isHandleClick[fieldName],
    isHandleClick[`${fieldName}-extensionnotes`],
    isValidEmail,
    lastUpdatedDate,
    saveStatus,
  ]);

  const handleFieldClickActions = field => {
    return (
      <span className={classes.editAlign}>
        <span
          className={`${classes.inlineBoxEditItemPadding} ${classes.inlineBoxEditMainAcceptItem}`}
          onClick={() => {
            handleSaveCheck(field);
          }}
          aria-hidden="true"
        >
          <CheckIcon data-testid="saveIcon" />
        </span>
        <span
          className={`${classes.inlineBoxEditItemPadding} ${classes.inlineBoxEditMainRejectItem}`}
          onClick={() => {
            close(field);
          }}
          onKeyDown={() => {
            close(field);
          }}
          aria-hidden="true"
        >
          <CloseIcon data-testid="closeIcon" />
        </span>
      </span>
    );
  };

  return (
    <Grid item className={classes.phoneInfo} xs={12}>
      <Grid
        item
        xs={isHandleClick[fieldName] || isHandleClick[`${fieldName}-extensionnotes`] ? 12 : 9}
        className={classes.align}
      >
        {fieldType === 'number' ? (
          <>
            {NumberTextField}
            {isHandleClick[fieldName] && handleFieldClickActions(fieldName)}
            <TextField
              variant="filled"
              inputProps={{ maxLength: 15 }}
              InputProps={{
                className: classes.textareastyle,
                disableUnderline: true,
              }}
              ref={inputExtensionRef}
              InputLabelProps={{
                className: ``,
              }}
              helperText={
                <span>
                  {extensionSaveStatus === 1 && <CircularProgress style={{ width: '12px', height: '12px' }} />}
                  {extensionSaveStatus === 2 && (
                    <>
                      <CheckIcon style={{ width: '15px', height: '15px', color: '#008000' }} />
                      <label className={classes.saveSuccessText}>{`Saved`}</label>
                    </>
                  )}
                  {extensionSaveStatus === 0 && extensionSaveStatus !== '' && (
                    <>
                      <CloseIcon style={{ width: '15px', height: '15px', color: '#ff0000' }} />
                      <label className={classes.saveFailedText}>{`Failed to update`}</label>
                    </>
                  )}
                </span>
              }
              className={!isValidEmail ? classes.extensionStyle : classes.extensionStyleError}
              value={extensionNotes}
              defaultValue={''}
              hiddenLabel
              onClick={() => {
                setClickedState(1);
                enableInlineEditMode(`${fieldName}-extensionnotes`);
              }}
              onChange={event => {
                checkValidation(`${fieldName}-extensionnotes`, event.target.value);
              }}
              type={'string'}
              {...props}
            />
            {isHandleClick[`${fieldName}-extensionnotes`] && handleFieldClickActions(`${fieldName}-extensionnotes`)}
          </>
        ) : (
          <>
            <TextField
              variant="filled"
              InputProps={{
                className: classes.textareastyle,
                disableUnderline: true,
                endAdornment:
                  toolTip && data && data.length > 30 ? (
                    <>
                      {' '}
                      <CustomTooltip standardMargin tooltipContent={data}>
                        <InputAdornment position="end" className={classes.toolTipAdjust}>
                          {'...'}
                        </InputAdornment>
                      </CustomTooltip>
                    </>
                  ) : (
                    ''
                  ),
              }}
              InputLabelProps={{
                className: ``,
              }}
              helperText={
                <span>
                  {saveStatus === 1 && <CircularProgress style={{ width: '12px', height: '12px' }} />}
                  {saveStatus === 2 && (
                    <>
                      <CheckIcon style={{ width: '15px', height: '15px', color: '#008000' }} />
                      <label className={classes.saveSuccessText}>{`Saved`}</label>
                    </>
                  )}
                  {saveStatus === 0 && saveStatus !== '' && (
                    <>
                      <CloseIcon style={{ width: '15px', height: '15px', color: '#ff0000' }} />
                      <label className={classes.saveFailedText}>{`Failed to update`}</label>
                    </>
                  )}
                  {saveStatus === '' && !isHandleClick[fieldName]
                    ? lastUpdatedDate === 'Invalid date'
                      ? 'N/A'
                      : lastUpdatedDate
                    : !isValidEmail
                    ? ''
                    : 'Email is not valid'}
                </span>
              }
              className={!isValidEmail ? classes.emailStyle : classes.emailStyleerror}
              value={data}
              defaultValue={''}
              hiddenLabel
              onClick={() => {
                enableInlineEditMode(fieldName);
              }}
              onChange={event => {
                checkValidation(fieldName, event.target.value);
              }}
              type={'string'}
              {...props}
            />
            {isHandleClick[fieldName] && handleFieldClickActions(fieldName)}
          </>
        )}
      </Grid>
      {fieldType === 'number'
        ? !isHandleClick[fieldName] &&
          !isHandleClick[`${fieldName}-extensionnotes`] && (
            <Grid item xs={3}>
              {enableVoiceCommunication ? (
                handleNotificationData !== undefined &&
                data !== '' &&
                userPreference &&
                userPreference.deviceList &&
                userPreference.deviceList?.length !== 0 &&
                userPreference.deviceLine?.length !== 0 ? (
                  consentsData?.hasCallConsents ? (
                    <img
                      src={PhoneIcon}
                      alt="Phone"
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        handleNotificationData(
                          ChannelType.voice,
                          fieldName === 'primarynumber'
                            ? ContactType.primary
                            : fieldName === 'secondarynumber'
                            ? ContactType.secondary
                            : null,
                        )
                      }
                    />
                  ) : (
                    <Tooltip
                      classes={{
                        tooltip: classes.tooltipBackground,
                        arrow: classes.tooltipArrow,
                      }}
                      arrow
                      title={<span>{`Candidate has opted out of Call communication.`} </span>}
                    >
                      <img src={ContactTabCallConsent} alt="Candidate has opted out of Call communication." />
                    </Tooltip>
                  )
                ) : (
                  <Tooltip
                    classes={{
                      tooltip: classes.tooltipBackground,
                      arrow: classes.tooltipArrow,
                    }}
                    arrow
                    title={
                      (userPreference && userPreference?.deviceList && userPreference?.deviceList?.length === 0) ||
                      (userPreference && userPreference?.deviceLine && userPreference?.deviceLine?.length === 0) ? (
                        <>
                          <span>{`No device found for the user`}</span>
                        </>
                      ) : (
                        <span>{`No Number on file`} </span>
                      )
                    }
                  >
                    <img src={PhoneDisabled} alt="Phone Disabled" />
                  </Tooltip>
                )
              ) : (
                <img src={PhoneDisabled} alt="Phone Disabled" />
              )}
              <span style={{ paddingLeft: '7px', display: 'inline-block' }}>
                {enableSmsCommunication ? (
                  !user.userInfo?.phoneNumber ? (
                    <MessageDisabledTooltip>
                      <img src={MessageDisabled} alt="Message disabled" />
                    </MessageDisabledTooltip>
                  ) : handleNotificationData !== undefined && data !== '' ? (
                    consentsData?.hasTextConsents ? (
                      <img
                        src={MessageIcon}
                        alt="Message"
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          handleNotificationData(
                            ChannelType.sms,
                            fieldName === 'primarynumber'
                              ? ContactType.primary
                              : fieldName === 'secondarynumber'
                              ? ContactType.secondary
                              : null,
                          )
                        }
                      />
                    ) : (
                      <Tooltip
                        classes={{
                          tooltip: classes.tooltipBackground,
                          arrow: classes.tooltipArrow,
                        }}
                        arrow
                        title={<span>{`Candidate has opted out of SMS communication.`} </span>}
                      >
                        <img src={ContactTabTextConsent} alt="Candidate has opted out of SMS communication." />
                      </Tooltip>
                    )
                  ) : (
                    <img src={MessageDisabled} alt="Message disabled" />
                  )
                ) : (
                  <img src={MessageDisabled} alt="Message disabled" />
                )}
              </span>
            </Grid>
          )
        : !isHandleClick[fieldName] && (
            <Grid item container justifyContent="flex-end" xs={3} className={classes.emailIconStyle}>
              {enableEmailCommunication ? (
                handleNotificationData !== undefined && data !== '' ? (
                  consentsData?.hasEmailConsents ? (
                    <img
                      src={MailBoxIcon}
                      alt="Mailbox"
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        handleNotificationData(
                          ChannelType.email,
                          fieldName === 'primaryemail'
                            ? ContactType.primary
                            : fieldName === 'secondaryemail'
                            ? ContactType.secondary
                            : null,
                        )
                      }
                    />
                  ) : (
                    <Tooltip
                      classes={{
                        tooltip: classes.tooltipBackground,
                        arrow: classes.tooltipArrow,
                      }}
                      arrow
                      title={<span>{`Candidate has opted out of Email communication.`} </span>}
                    >
                      <img src={ContactTabEmailConsent} alt="Candidate has opted out of Email communication." />
                    </Tooltip>
                  )
                ) : (
                  <img src={EmailDisabled} alt="Email disabled" />
                )
              ) : (
                <img src={EmailDisabled} alt="Email disabled" />
              )}
            </Grid>
          )}
    </Grid>
  );
};
