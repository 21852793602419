import { Grid } from 'amn-ui-core';
import React, { useContext } from 'react';
import { Brand } from '../Info/Brand';
import { Recruiter } from './Recruiter';
import { AvailableStartDate } from './AvailableStartDate';
import { RequestedShift } from './RequestedShift';
import { BestTimeToCall } from './BestTimeToCall';
import { TravelPartner } from './TravelPartner';
import { GenericDialogContext } from '@AMIEWEB/Alerts/GenericDialog';

export const PrimaryEditableFields = ({ brand }) => {
  const { fullscreen } = useContext(GenericDialogContext);

  const editableFields = React.useMemo(
    () => (
      <>
        <Grid item>
          <AvailableStartDate />
        </Grid>
        <Grid item>
          <RequestedShift />
        </Grid>
        <Grid item>
          <BestTimeToCall />
        </Grid>
      </>
    ),
    [],
  );

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Brand value={brand} />
      </Grid>
      <Grid item>
        <Recruiter />
      </Grid>
      {fullscreen ? (
        editableFields
      ) : (
        <Grid item>
          <Grid container spacing={2}>
            {editableFields}
          </Grid>
        </Grid>
      )}
      <Grid item>
        <TravelPartner />
      </Grid>
    </Grid>
  );
};
