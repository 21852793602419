import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/types';
import { initialState } from './userPreference.redux';

const selectDomain = (state: RootState) => state?.userPreferenceData || initialState;

export const selectUserPreference = createSelector([selectDomain], userState => userState);

export const selectInboxFilters = createSelector([selectDomain], userState => userState.inboxFilter);

export const selectEditorPreferences = createSelector([selectDomain], userState => ({
  ...userState.editors,
  hasLoadedPreferences: userState.loaded,
}));
