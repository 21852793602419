import React from 'react';
import { DocRenderer, textFileLoader } from 'react-doc-viewer';
import styledComponent from 'styled-components';

const DocTXTRenderer: DocRenderer = ({ mainState: { currentDocument } }) => {
  const fileData = currentDocument?.fileData as string;
  return <Container id="txt-renderer">{fileData}</Container>;
};

export default DocTXTRenderer;

DocTXTRenderer.fileTypes = ['txt', 'text/plain'];
DocTXTRenderer.weight = 0;
DocTXTRenderer.fileLoader = textFileLoader;

const Container = styledComponent.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
`;
