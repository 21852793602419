import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { IGetActivityFeedQuery } from 'app/models/ActivityFeed/IGetActivityFeed';
import baseAxiosInstance from 'utils/BaseApi';
import { httpSuccess } from '../serviceHelpers';

const taskBase = globalThis?.app?.env?.REACT_APP_TASKS_SERVICE_BASE_URL;
const sharedBase = globalThis?.app?.env?.REACT_APP_TRACK_CHANGE_SERVICE_BASE_URL;
const notificationBaseUrl = globalThis?.app?.env?.REACT_APP_NOTIFICATION_SERVICE_BASE_URL;

export async function saveTaskMetaData(body) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/task/save`, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'saveTaskMetaData',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return error;
  }
}

export async function saveExternalTaskMetaData(body) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/external-task/create`, body);
    if (!httpSuccess(response.status)) {
      return -1;
    }
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'saveExternalTaskMetaData',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return error;
  }
}

export async function editTaskMetaData(body) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/task/update`, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'editTaskMetaData',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return error;
  }
}

export async function getTaskDetailsByPlacementId(body) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/task/list-byplacement`, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getTaskDetailsByPlacementId',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    throw error;
  }
}

export async function getTaskDetails(taskId) {
  try {
    const response = await baseAxiosInstance.get(`${taskBase}/task/details/${taskId}`);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getTaskDetails',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return error;
  }
}

// #region Subtasks

export async function getComments(rootTaskId: number) {
  try {
    const response = await baseAxiosInstance.get(`${taskBase}/comment/list/${rootTaskId}`);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getComments',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return error;
  }
}

export async function saveComment(body) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/comment/save`, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'saveComment',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return error;
  }
}

// #endregion

export async function getPlacementEmployees(placementId: string) {
  try {
    const response = await baseAxiosInstance.get(`${taskBase}/task/placement-employees/${placementId}`);
    if (!httpSuccess(response.status)) {
      return [];
    }
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getPlacementEmployees',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return [];
  }
}

export async function getAllEmployees(searchKey: string) {
  try {
    const response = await baseAxiosInstance.get(`${sharedBase}/search/active-employees/${searchKey}`);
    if (!httpSuccess(response.status)) {
      return [];
    }
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getAllEmployees',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return [];
  }
}

export async function getCategoryList() {
  try {
    const response = await baseAxiosInstance.get(`${taskBase}/task/category-tasks`);
    if (!httpSuccess(response.status)) {
      return [];
    }
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCategoryList',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return error;
  }
}
export async function getExternalCategoryList() {
  try {
    const response = await baseAxiosInstance.get(`${taskBase}/external-task/category-tasks`);
    if (!httpSuccess(response.status)) {
      return [];
    }
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getExternalCategoryList',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    throw error;
  }
}

export async function getStatusList() {
  try {
    const response = await baseAxiosInstance.get(`${taskBase}/task/statuses`);
    if (!httpSuccess(response.status)) {
      return [];
    }
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getStatusList',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return error;
  }
}

export async function getTaskReadStatus(body) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/task/read`, body);
    if (!httpSuccess(response.status)) {
      return null;
    }
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getTaskReadStatus',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return error;
  }
}

export async function updateTaskStatus(body) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/task/update-status`, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateTaskStatus',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return error;
  }
}

export async function getTaskDetailsByCandidateId(body) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/task/list-bycandidate`, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getTaskDetailsByCandidateId',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    throw error;
  }
}

export async function getTaskDetailsByFacilityId(body) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/task/list-byfacility`, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getTaskDetailsByFacilityId',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    throw error;
  }
}

export async function getTaskData(body, cancelToken) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/task-management/tasks`, body, { cancelToken });
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getTaskData',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    throw error;
  }
}

export async function getTaskDetailsByOrderId(body) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/task/list-byorder`, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getTaskDetailsByOrderId',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    throw error;
  }
}

export async function getTaskGroupedList(body) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/task-management/groups`, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getTaskGroupedList',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
  }
}
export async function getTaskGridFilters(filters: any) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/task-management/filters`, filters);
    if (!httpSuccess(response.status)) {
      return -1;
    }
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getTaskGridFilters',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return error;
  }
}

// #region Notifications
export async function postNotification(body) {
  try {
    const response = await baseAxiosInstance.post(`${notificationBaseUrl}/alert/publish`, body);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postNotification',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
  }
}

export async function GetAlertEmailData(id: any) {
  try {
    const response = await baseAxiosInstance.get(`${notificationBaseUrl}/alert/email/${id}`);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'GetAlertEmailData',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    throw error;
  }
}

export async function UpdateAlertEmailData(id: any, body: any) {
  try {
    const response = await baseAxiosInstance.patch(`${notificationBaseUrl}/alert/email/${id}`, body);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'UpdateAlertEmailData',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
  }
}

// #endregion

export async function DeleteAttachments(body) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/task/delete-attachment`, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'DeleteAttachments',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return error;
  }
}

export async function GetExternalTaskList(body) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/external-task/list`, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'GetExternalTaskList',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    throw error;
  }
}

export async function UpdateExternalTaskStatus(body) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/external-task/update-status`, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'UpdateExternalTaskStatus',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return error;
  }
}

export async function getExternalTaskDetails(body) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/external-task/details`, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getExternalTaskDetails',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return error;
  }
}

/**
 * Get Task Kpi .
 * @constructor
 * @param {object} payload - Payload specifying user info, channel and period to fetch KPI metrics.
 */
export async function getTaskKpi(payload: {
  userId: string;
  channel?: string[];
  startDate?: Date | string | null;
  endDate?: Date | string | null;
}) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/kpi/tasks`, payload);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getTaskKpi',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    throw error;
  }
}

export async function updateExternalTask(body) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/external-task/update`, body);
    if (!httpSuccess(response.status)) {
      return -1;
    }
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateExternalTask',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return error;
  }
}

export async function getExternalTaskSubCategory(taskNameId: number) {
  try {
    const response = await baseAxiosInstance.get(`${taskBase}/external-task/task-name/${taskNameId}/subnames`);
    if (!httpSuccess(response.status)) {
      return -1;
    }
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getExternalTaskSubCategory',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return error;
  }
}

// #region Cue Notes

export async function getCueNoteRecipient(body) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/cue-note/recipients`, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCueNoteRecipient',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return error;
  }
}
export async function getCueNotesByPlacementId(body) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/cue-note/list-byplacement`, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCueNotesByPlacementId',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    throw error;
  }
}
export async function createCueNote(body) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/cue-note/create`, body);
    if (!httpSuccess(response.status)) {
      return -1;
    }
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'createCueNote',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return error;
  }
}

export async function saveTaskComment(body) {
  try {
    const response = await baseAxiosInstance.post(`${taskBase}/task/task-comment/save`, body);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'saveTaskComment',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    return error;
  }
}

export async function getCueNotesCountByPlacementId(placementId: number) {
  try {
    const response = await baseAxiosInstance.get(`${taskBase}/cue-note/count/${placementId}`);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCueNotesCountByPlacementId',
        area: 'src/app/services/TaskServices/TaskServices.ts',
      },
    });
    throw error;
  }
}

// #endregion
