import { Grid } from 'amn-ui-core';
import moment from 'moment';
import React from 'react';
import { TooltipMessage, getCompletionMessage, getCredentialType, getExpirationMessage } from '../../helper';
import { useSelector } from 'react-redux';
import { CredentialsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../utils';

function GetLicenseToolTip({ license }) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const credentials = useSelector(CredentialsSelection);
  const typeId = credentials.licenses.find(x => x.requirementID == license.requirementID)?.typeID;
  const type = getCredentialType(typeId);
  const expirationMessage = license.expirationDate
    ? getExpirationMessage(license, 'license')
    : t('candidate.qualificationsCard.licenseExpirationMessage');
  const completedMessage = license.dateCompleted
    ? getCompletionMessage(license, 'license')
    : t('candidate.qualificationsCard.licenseCompletedMessage');
  const verifiedMessage = !license.dateVerified ? t('candidate.qualificationsCard.licenseVerifiedMessage') : null;

  return (
    <>
      <Grid container direction="column">
        {(expirationMessage || verifiedMessage || completedMessage) && (
          <Grid item>
            <Grid container direction="column" style={{ paddingBottom: '10px' }}>
              {expirationMessage && (
                <Grid item className={classes.tooltipRowContent}>
                  {expirationMessage}
                </Grid>
              )}
              {verifiedMessage && (
                <Grid item className={classes.tooltipRowContent}>
                  {verifiedMessage}
                </Grid>
              )}
              {completedMessage && (
                <Grid item className={classes.tooltipRowContent}>
                  {completedMessage}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        <Grid item>
          <span style={{ fontWeight: 'bold' }} className={classes.tooltipRowHeader}>
            {expirationMessage == TooltipMessage.LicenseExpired
              ? t('candidate.qualificationsCard.expiredOn')
              : t('candidate.qualificationsCard.expiresOn')}
          </span>
          <span className={classes.tooltipRowContent}>
            {license.expirationDate ? moment(license.expirationDate).format('MM/DD/YYYY') : `--`}
          </span>
        </Grid>
        <Grid item>
          <span style={{ fontWeight: 'bold' }} className={classes.tooltipRowHeader}>
            {t('candidate.qualificationsCard.completedOn')}
          </span>
          <span className={classes.tooltipRowContent}>
            {license.dateCompleted ? moment(license.dateCompleted).format('MM/DD/YYYY') : `--`}
          </span>
        </Grid>
        <Grid item>
          <span style={{ fontWeight: 'bold' }} className={classes.tooltipRowHeader}>
            {t('candidate.qualificationsCard.type')}
          </span>
          <span className={classes.tooltipRowContent}>{type ? type : `--`}</span>
        </Grid>
        <Grid item>
          <span style={{ fontWeight: 'bold' }} className={classes.tooltipRowHeader}>
            {t('candidate.qualificationsCard.licenseNumber1')}
          </span>
          <span className={classes.tooltipRowContent}> {license.licenseNumber ? license.licenseNumber : `--`}</span>
        </Grid>
      </Grid>
    </>
  );
}

export default GetLicenseToolTip;
