import React, { useState, useEffect } from 'react';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { TypeAheadDropdown } from 'app/ComponentLibrary/Filter/TypeAheadDropdown/TypeAheadDropdown';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { candidateDetailActions } from '../../Profile/CandidateDetails.redux';
import { selectUser } from 'oidc/user.selectors';
import { ICandidateDetails } from 'app/models/Candidate/CandidateProfile';
import { candidateDetailsSelection } from '../../Profile/CandidateDetails.selector';
import { IconButton } from '@mui/material';
import _ from 'lodash';
import { ClassicTags } from './CandidateTagHelper';

const useTagStyles = makeStyles()(theme => ({
  containerDiv: {
    width: '45px',
    height: '45px',
  },
}));

/**
 * Component to render pop over on clicking add icon in tags
 */
export const AddTagsIcon = ({
  anchorAccessorId,
  tagsOptions,
  tagsList,
  setTagsList,
  isRecruiter,
  candidateDetails,
}: {
  anchorAccessorId: string;
  tagsOptions;
  tagsList: any[];
  setTagsList: Function;
  isRecruiter: boolean;
  candidateDetails: ICandidateDetails;
}) => {
  const { t } = useTranslation();
  const { classes } = useTagStyles();
  const anchorRef = React.useRef<any>(null);
  const dispatch = useDispatch();
  const [selectedTags, setSelectedTags] = useState([]);
  const [options, setOptions] = useState(tagsOptions);
  const user = useSelector(selectUser);
  const candidateInfo = useSelector(candidateDetailsSelection);

  useEffect(() => {
    setSelectedTags(tagsList);
    setDisabledOptions(tagsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsList]);

  /**
   * Method to open type ahead dropdown using a anchor element
   */
  const handleOpen = () => {
    /** btn click is required to invoke TypeAheadDropdown opener */
    const btn = document.getElementById(anchorAccessorId);
    btn?.click();
  };

  /**
   * Method to handle apply/save for tags
   */
  const handleApply = () => {
    const readyOnlyTags = tagsList?.filter(tag => tag?.isHidden);
    const postReqBody = {
      candidateId: candidateDetails?.travelerId || candidateInfo?.travelerId,
      brandId: candidateDetails?.brandId || candidateInfo?.brandId,
      currentEmployeeId: user.userInfo.employeeId,
      currentEmployeeName: user.userInfo.firstName + user.userInfo.lastName,
      candidateTags: selectedTags?.concat(readyOnlyTags),
    };
    onOptionsChange(selectedTags);
    const tags = selectedTags?.concat(readyOnlyTags).sort((a, b) => (a.name < b.name ? -1 : 1));
    setTagsList(tags);
    dispatch(candidateDetailActions.postTags(postReqBody));
  };

  const setDisabledOptions = values => {
    const selectedInterestRatingItem = values?.find(
      item =>
        item.value === ClassicTags.VaMilExp ||
        item.value === ClassicTags.KaizerHot ||
        item.value === ClassicTags.KaizerWarm,
    );
    if (selectedInterestRatingItem) {
      const updatedOptions = _.cloneDeep(tagsOptions)?.map(opt => {
        if (
          (opt.value === ClassicTags.VaMilExp ||
            opt.value === ClassicTags.KaizerHot ||
            opt.value === ClassicTags.KaizerWarm) &&
          opt.value !== selectedInterestRatingItem.value
        )
          opt.disabled = true;
        else opt.disabled = false;
        return opt;
      });
      setOptions(updatedOptions);
    } else {
      const resetOptions = _.cloneDeep(tagsOptions)?.map(opt => {
        opt.disabled = false;
        return opt;
      });
      setOptions(resetOptions);
    }
  };

  const onOptionsChange = values => {
    setSelectedTags(values);
    setDisabledOptions(values);
  };

  return (
    <div className={classes.containerDiv}>
      <CustomTooltip
        style={{ color: '#888888' }}
        tooltipContent={t('candidate.candidateHeader.addTags')}
        placement="bottom-start"
        isInlineContent={true}
      >
        <IconButton color="secondary" size="medium">
          <AddIcon id="add-icon-tags" ref={anchorRef} onClick={handleOpen} />
        </IconButton>
      </CustomTooltip>
      <TypeAheadDropdown
        name="tags-type-ahead-control"
        value={selectedTags?.filter(tag => !tag?.isHidden)}
        anchorRef={anchorRef}
        onChange={newValue => onOptionsChange(newValue)}
        options={options ?? []}
        isMultiSelect
        anchorAccesorid={anchorAccessorId}
        getOptionDisabled={option => option.disabled}
        apply={handleApply}
      />
    </div>
  );
};
