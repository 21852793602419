/* eslint-disable i18next/no-literal-string */
import React, { useEffect, useState } from 'react';
import { Expandable } from 'app/components/Common/Expandable/Expandable';
import { IconTitle } from './Titles/IconTitle';
import { CandidatePlacementSupervision } from './CandidatePlacementSupervision';
import RedWarning from 'app/assets/images/IconImages/RedWarningIcon.svg';
import GreenCheck from 'app/assets/images/IconImages/GreenCheckCircleIcon.svg';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import { useSelector } from 'react-redux';
import { selectPlacementSupervisee, selectPlacementSupervision } from 'store/redux-store/placement-details/selectors';

export const PlacementSupervision = ({ ...props }) => {
  const selPlacementSupervision = useSelector(selectPlacementSupervision);
  const selPlacementSupervisee = useSelector(selectPlacementSupervisee);
  const [containerVals, setContainerVals] = useState<any[]>([]);
  
  useEffect(() => {
    if(props?.title === 'Supervisor'){
    setContainerVals([...selPlacementSupervision]);
    }
    else{
    setContainerVals([...selPlacementSupervisee]);
    }
  }, [selPlacementSupervision, selPlacementSupervisee, props?.title]);

  const Title = () => {
    return (
      <IconTitle
        title={props?.title}
        titleSupportComponent={
          selPlacementSupervision?.length > 0 || selPlacementSupervisee?.length > 0 ? (
            <img src={GreenCheck} alt="Pass" style={{ width: '16px', height: '16px' }} />
          ) : (
            <img src={RedWarning} alt="Fail" style={{ width: '16px', height: '16px' }} />
          )
        }
        icon={<SupervisorAccountOutlinedIcon style={{ marginRight: '-5px' }} />}
      />
    );
  };

  return (
    <Expandable id="placement-supervision" expanded={false} titleComponent={<Title />}>
      <CandidatePlacementSupervision title={props?.title} containerVals={containerVals} />
    </Expandable>
  );
};
