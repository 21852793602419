/* eslint-disable i18next/no-literal-string */
import { Expandable, IExpandableProps } from 'app/components/Common/Expandable/Expandable';
import { IRequirementCounts, requirementType } from 'app/models/Placement/PlacementSummary';
import React from 'react';
import { StatusTitle } from '../Titles/StatusTitle';

interface RequirementsContainerProps extends Omit<IExpandableProps, 'titleComponent'> {
  counts: IRequirementCounts;
  title: string;
  id?: string;
  filter?: requirementType[];
  handleFilterChange?: Function;
  [key: string]: any;
}

export const RequirementsContainer = ({
  counts,
  title,
  id,
  filter,
  handleFilterChange,
  isPrimary = false,
  excludeFromFilter,
  ...props
}: RequirementsContainerProps) => {
  return (
    <Expandable
      id={id}
      titleComponent={
        <StatusTitle
          title={title}
          counts={counts}
          filter={filter}
          handleFilterChange={handleFilterChange}
          isPrimary={isPrimary}
          excludeFromFilter={excludeFromFilter}
        />
      }
      {...props}
    >
      {props.children}
    </Expandable>
  );
};
