import React from 'react';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import {  Grid, Theme, Typography } from 'amn-ui-core';
import styledComponent from 'styled-components';

const useStyles = makeStyles()((theme: Theme) =>
  ({
    root: {
      height: 'auto',
      maxWidth: '530px',
      minWidth: '525px',
    },
    innerRoot: {
      paddingBottom: '8px',
    },
    valueRight: {
      textAlign: 'right',
      fontSize: 12,
      color: '#003E68',
      opacity: 1,
      fontWeight: 600,
      padding: '5px',
      margin: 0,
      lineHeight: '16px',
    },
    valueLeft: {
      textAlign: 'left',
      fontSize: 12,
      color: '#333333',
      opacity: 1,
      padding: 0,
      margin: 0,
      lineHeight: '16px',
    },
    key: {
      textAlign: 'left',
      font: 'normal normal normal 12px/30px Roboto',
      color: '#333333',
      opacity: '1',
      fontWeight: 600,
      padding: 0,
      margin: 0,
      minWidth: '65px',
    },
    header: {
      textAlign: 'left',
      font: 'normal normal normal 20px/26px Roboto',
      color: '#52429A',
      opacity: 1,
    },
  }));

export const HoverGlance = props => {
  const { classes } = useStyles();
  const header = (
    <div>
      <Typography variant="h6"> {props.headerText}</Typography>
    </div>
  );

  const glance = props.data.map((value, index) => {
    // if (typeof value[1] === 'object') return <div />;
    if (value.key) {
      return (
        <Grid container key={index} className={classes.innerRoot}>
          <Grid item xs={4} className={classes.key}>
            {value.key}
          </Grid>
          <Grid item xs={8} className={classes.valueRight}>
            {value.value != null ? value.value : 'Missing Data'}
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container key={index}>
          <Grid item xs={12} className={classes.valueLeft}>
            {value.value ? value.value : 'Missing Data'}
          </Grid>
        </Grid>
      );
    }
  });
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.header}>
        {header}
        {glance}
      </Grid>
    </Grid>
  );
};

HoverGlance.propTypes = {
  type: PropTypes.string,
  component: PropTypes.string,
  header: PropTypes.string,
  link: PropTypes.object,
  row: PropTypes.string,
  headerText: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};
export const Row = styledComponent.tr`
  display: flex;
  justify-content: space-between;
`;

export const RowHead = styledComponent.th`
  text-align: left;
`;

export const RowContent = styledComponent.td`
  text-align: end;
`;
