import React from 'react';
import { ICustomHeader, gridControlType } from 'app/models/Grid';
import NoteIcon from 'app/assets/images/Notes.png';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CredentialingLookup } from 'app/models/Credentialing/CredentialingLookups';
import {
  getCredentialName,
  getDate,
  getDateNoFormat,
  getDropDownValue,
  getLookups,
  getDateText,
  getCredentialNameNoFormat,
  detailColumnsAndRows,
  getMetadataForReqID,
  getCredentialNameBold,
  getDropDownDescription,
} from '../tableHelpers';
import { ICredentials } from 'app/models/Credentialing/Credentials';
import VersionDetailsHoverView from '../VersionDetailsHoverView';
import HistoryIcon from '@mui/icons-material/History';
import { groupBy, nestedChildren } from 'app/helpers/arrayHelpers';
import { AttachmentAction, CredentialAttachmentToolTip } from '../../Attachments/AttachmentHoverView';
import { childSorting } from '../HumanResources/HumanResourcesGroupingHelper';

const getMainTableColumns = (
  sortable: boolean = true,
  viewingDuplicates: boolean = false,
  historical: boolean = false,
) => {
  const headers: ICustomHeader[] = [
    {
      title: 'credentialName',
      value: 'Credential Name',
      sortable: sortable,
      sortByField: 'credentialNameNoFormat',
    },
    {
      title: 'reqTypeID',
      value: 'Requirement Type',
      sortByField: 'reqTypeIDDescription',
      sortable: sortable,
      control: {
        type: gridControlType.dropDown,
        disabled: true,
      },
    },
    {
      title: 'typeID',
      value: 'Type',
      sortByField: 'typeIDDescription',
      sortable: sortable,
      control: {
        type: gridControlType.dropDown,
        disabled: true,
      },
    },

    {
      title: 'dateCompleted',
      value: 'Date Completed',

      sortable: sortable,
      sortByField: 'dateCompletedNoFormat',
      control: {
        type: gridControlType.date,
      },
    },
    {
      title: 'expirationDate',
      value: 'Expiration Date',
      sortable: sortable,
      defaultSort: !viewingDuplicates,
      sortByField: 'expirationDateNoFormat',
      control: {
        type: gridControlType.date,
        disabled: true,
      },
    },
    {
      title: 'lastUpdate',
      value: 'Updated Date',
      sortable: sortable,
      defaultSort: viewingDuplicates,
      sortByField: 'lastUpdateNoFormat',
      control: {
        type: gridControlType.label,
      },
    },
    {
      title: 'versioning',
      value: 'Versions',
      icon: true,
      versioning: !historical,
    },
    {
      title: 'documentIcon',
      value: 'Document',
      // clickable: true,
      navigationIds: [''],
      navigationExp: '/',
      hasHoverView: true,
      customHoverView: true,
    },
  ];
  return headers;
};

export const groupedPendingMiscellaneous = list => {
  return groupBy<keyof ICredentials>(
    list,
    ['tsceFormID', 'reqTypeID'],
    [
      { item: 'expirationDate', type: 'shortDate' },
      { item: 'dateCompleted', type: 'shortDate' },
      { item: 'issuedDate', type: 'shortDate' },
      { item: 'lastUpdate', type: 'longDate' },
    ],
  );
};

const flattenData = (
  credentials: ICredentials[],
  lookups: CredentialingLookup[],
  viewingDuplicates = false,
  onAttachmentAction: (
    action: AttachmentAction,
    reqID: number,
    requirementTypeID: number,
    travelerId: number,
    brandId: number,
  ) => void,
  level: number = 0,
  expandable: boolean = true,
) => {
  let nestedItems = credentials;
  if (level === 0) {
    const grouped = groupedPendingMiscellaneous(credentials);
    nestedItems = nestedChildren(grouped);
  }
  if (level > 0) {
    nestedItems = childSorting(nestedItems, lookups);
  }
  const miscellaneouses: any[] = [];
  nestedItems?.forEach((item: ICredentials) => {
    const fieldLookups = getMetadataForReqID(lookups, item);

    const l: any = {
      rowID: item.requirementID,
      credentialName:
        level === 0 ? (
          getCredentialName(item.requirementType, item.credentialName)
        ) : (
          <VersionDetailsHoverView
            credential={[
              ...detailColumnsAndRows(
                item,
                fieldLookups,
                ['ReqTypeID', 'TypeID', 'DateCompleted', 'ExpirationDate', 'LastUpdate'],
                true,
              ),
            ]}
            fieldLookups={fieldLookups}
            title={getCredentialName(item.requirementType, item.credentialName)}
            titleBold={getCredentialNameBold(item.requirementType, item.credentialName)}
          />
        ),
      credentialNameNoFormat: getCredentialNameNoFormat(item.requirementType, item.credentialName),
      reqTypeID: getDropDownValue(fieldLookups, 'ReqTypeID', item.reqTypeID),
      reqTypeIDFilterOptions: getLookups(fieldLookups, 'ReqTypeID', item.reqTypeID),
      reqTypeIDDescription: getDropDownDescription(fieldLookups, 'ReqTypeID', item.reqTypeID),
      typeID: getDropDownValue(fieldLookups, 'TypeID', item.typeID),
      typeIDDescription: getDropDownDescription(fieldLookups, 'TypeID', item.typeID),
      typeIDFilterOptions: getLookups(fieldLookups, 'TypeID', item.typeID),
      dateCompleted: getDate(fieldLookups, 'DateCompleted', item.dateCompleted),
      dateCompletedNoFormat: getDateNoFormat(fieldLookups, 'DateCompleted', item.dateCompleted),
      expirationDate: getDate(fieldLookups, 'ExpirationDate', item.expirationDate),
      expirationDateNoFormat: getDateNoFormat(fieldLookups, 'ExpirationDate', item.expirationDate),
      lastUpdate: getDateText(fieldLookups, 'LastUpdate', item.lastUpdate),
      lastUpdateNoFormat: getDateNoFormat(fieldLookups, 'LastUpdate', item.lastUpdate),
      versioning: level === 0 ? <HistoryIcon /> : null,
      documentIcon: NoteIcon,
      moreIcon: <MoreVertIcon />,
      expandable: expandable,
      detailRows: [
        [
          ...detailColumnsAndRows(item, fieldLookups, [
            'ReqTypeID',
            'TypeID',
            'DateCompleted',
            'ExpirationDate',
            'LastUpdate',
          ]),
        ],
      ],
      documentIconHoverViewComponent: (
        <CredentialAttachmentToolTip
          onSelect={onAttachmentAction}
          requirementID={item.requirementID}
          requirementTypeID={item.requirementTypeID}
          travelerId={0}
          brandId={0}
        />
      ),
      versionRows:
        level === 0 && item['children'] !== undefined
          ? flattenData(item['children'], lookups, false, onAttachmentAction, level + 1, false)
          : null,
      versionColumns: getMainTableColumns(true, false, true),
    };
    miscellaneouses.push(l);
  });

  if (level === 0) {
    return miscellaneouses.sort(
      viewingDuplicates
        ? (a: any, b: any) => (a.lastUpdateNoFormat < b.lastUpdateNoFormat ? 1 : -1)
        : (a: any, b: any) => (a.expirationDateNoFormat > b.expirationDateNoFormat ? 1 : -1),
    );
  } else return miscellaneouses;
};

export const pendingMoleculeDevelopmentData = {
  getTableColumns: getMainTableColumns,
  getTableRows: flattenData,
};
