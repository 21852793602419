import { createSelector } from '@reduxjs/toolkit';
import { IGlobal } from 'app/models/Global/Global';
import { RootState } from 'types';
import { initialState } from './Global.redux';

const selectDomain = (state: RootState) => state?.global || initialState;

export const selectNewAppVersionAvailable = createSelector(
  [selectDomain],
  (globalState: IGlobal) => globalState.newAppVersionAvailable,
);

export const selectSessionLoaded = createSelector([selectDomain], (globalState: IGlobal) => globalState.sessionLoaded);

export const selectGlobalSnackBar = createSelector(
  [selectDomain],
  (globalState: IGlobal) => globalState.globalSnackbar,
);

export const selectGlobalBanner = createSelector([selectDomain], (globalState: IGlobal) => globalState.banner);

export const isProfileOpen = createSelector([selectDomain], (globalState: IGlobal) => globalState.isProfileOpen);

export const selectCandidateDrawer = createSelector(
  [selectDomain],
  (globalState: IGlobal) => globalState.candidateDrawerData,
);

export const selectFacilityDrawer = createSelector(
  [selectDomain],
  (globalState: IGlobal) => globalState.facilityDrawerData,
);

export const selectOrderDrawer = createSelector([selectDomain], (globalState: IGlobal) => globalState.orderDrawerData);

export const selectDetailsPageDrawer = createSelector(
  [selectDomain],
  (globalState: IGlobal) => globalState.detailsPageDrawer,
);

export const selectSmsIconDrawer = createSelector([selectDomain], (globalState: IGlobal) => globalState.smsIconDrawer);

export const selectCandidateProfileDrawer = createSelector(
  [selectDomain],
  (globalState: IGlobal) => globalState.candidateProfileDrawer,
);

export const selectActiveStep = createSelector([selectDomain], (globalState: IGlobal) => globalState.activeStep);

export const selectDeletedAttachments = createSelector(
  [selectDomain],
  (globalState: IGlobal) => globalState.deletedAttachments,
);

export const selectEmailSignature = createSelector(
  [selectDomain],
  (globalState: IGlobal) => globalState.emailSignature,
);

export const emailSignatureExpanded = createSelector(
  [selectDomain],
  (globalState: IGlobal) => globalState.isEmailSignatureExpanded,
);

export const selectNotificationPreference = createSelector(
  [selectDomain],
  (globalState: IGlobal) => globalState.notificationPreference,
);

export const selectCatastrophicDialog = createSelector(
  [selectDomain],
  (globalState: IGlobal) => globalState.catastrophicDialog,
);

export const selectFailedServices = createSelector(
  [selectDomain],
  (globalState: IGlobal) => globalState.failedServices,
);
