import { Grid } from 'amn-ui-core';
import React from 'react';
import LeftContainer from './LeftContainer';
import RightContainer from './RightContainer';

const ParentContainer = props => {

    return (
        <Grid container spacing={2} xs={12} style={{ marginTop: '0.5px' }}  data-testId='userManagementContainer'>
            <Grid item xs={4}>
                <LeftContainer onApplyFilter={props.onApplyFilter} setPageSize={props.setPageSize} />
            </Grid>
            <Grid item xs={8} >
                <RightContainer />
            </Grid>
        </Grid>
    );
};

export default ParentContainer;
