import { Box } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { CustomGrid } from 'app/components/Common/Grid/Grid';
import { ICredentials } from 'app/models/Credentialing/Credentials';
import { ICustomHeader } from 'app/models/Grid';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()({
  form: {
    maxHeight: '350px',
  },
});

export const CredentialingListedCertifications = ({
  certifications,
  open,
  onCredentialCertificationsToggle,
}: {
  certifications: ICredentials[];
  open: boolean;
  onCredentialCertificationsToggle: (count) => () => void;
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const onClose = () => {
    onCredentialCertificationsToggle(certifications.length)();
  };

  const getColumnDefinition = (t, key) => {
    return {
      title: key,
      value: t(`placement.profile.requirementsAndQualifications.credentialingListedCertifications.columns.${key}`),
      sortByField: key,
      sortable: true,
    };
  };

  const columns: ICustomHeader[] = [
    {
      ...getColumnDefinition(t, 'subCategory'),
      defaultSort: true,
    },
    {
      ...getColumnDefinition(t, 'credentialName'),
    },
    {
      ...getColumnDefinition(t, 'issuedByOrType'),
    },
    {
      ...getColumnDefinition(t, 'verifiedID'),
    },
    {
      ...getColumnDefinition(t, 'dateVerified'),
    },
    {
      ...getColumnDefinition(t, 'issuedDate'),
    },
    {
      ...getColumnDefinition(t, 'expirationDate'),
    },
  ];

  return (
    <React.Fragment>
      <GenericDialog
        variant="blue"
        maxWidth="lg"
        fullWidth
        open={open}
        dialogTitleProps={{
          text: t('placement.profile.requirementsAndQualifications.credentialingListedCertifications.dialogTitle'),
          closeButton: true,
        }}
        onClose={onClose}
      >
        <Box className={classes.form} id={'credentialingListedCertifications'}>
          <CustomGrid
            columns={columns}
            rows={certifications}
            searchTerm={undefined}
            component={''}
            showCount={false}
            disableGutters
            noPadding={true}
            fullWidth
            showPagination={false}
            showViewOptions={false}
            currentPageNumber={1}
            fixedItemsPerPage={1000}
            maxHeight={'335px'}
            disablePaper
            centerHoverView
            stickyTable={true}
            isInDialog={false}
            // analytics={{
            //   tableName: 'Credentials Grid',
            // }}
          />
        </Box>
      </GenericDialog>
    </React.Fragment>
  );
};
