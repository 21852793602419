import { Button } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorIcon from 'app/assets/images/glance-tile-error-icon.svg';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  message: {
    alignSelf: 'center',
  },
  button: {
    color: theme.palette.system.navyBlue,
    fontWeight: 450,
    padding: '0px 8px',
    '&:hover': {
      background: 'none',
    },
  },
  img: {
    marginTop: '-6px',
  },
}));

export const ErrorAction = ({ message = undefined, tryAgainText = undefined, onTryAgain }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <img className={classes.img} src={ErrorIcon} alt="error" />
      <div className={classes.message}>{message ?? t('Data failed to load.')}</div>
      <Button
        type="button"
        disableRipple
        disableTouchRipple
        disableFocusRipple
        variant="text"
        onClick={onTryAgain}
        classes={{ root: classes.button }}
      >
        {tryAgainText ?? t('Try Again')}
      </Button>
    </div>
  );
};
