import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { Backdrop, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#222222',
    position: 'absolute',
  },
  cloudIcon: {
    fontSize: '150px',
    color: theme.palette.components.icons.secondary.backgroundColor,
    position: 'relative',
    left: '40%',
  },
  helperText: {
    color: '#FFF',
    fontSize: '25px',
    position: 'relative',
    left: '30%',
  },
  smsCloudIcon: {
    fontSize: '75px',
    color: theme.palette.components.icons.secondary.backgroundColor,
    position: 'relative',
    left: '40%',
  },
  smsHelperText: {
    color: '#FFF',
    fontSize: '15px',
    position: 'relative',
    left: '30%',
  },
}));

export const UploadFileBackdrop = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Backdrop className={classes.backdrop} open={props.open}>
      <LayoutGrid>
        <LayoutGridItem xs={12}>
          <CloudUploadOutlinedIcon color="inherit" className={classes.cloudIcon} />
        </LayoutGridItem>
        <LayoutGridItem xs={12}>
          <Typography className={classes.helperText}>{t('Drop your files here to upload')}</Typography>
        </LayoutGridItem>
      </LayoutGrid>
    </Backdrop>
  );
};

export const UploadFileBackdropSms = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Backdrop className={classes.backdrop} open={props.open} {...props.getRootProps()}>
      <LayoutGrid>
        <LayoutGridItem xs={12}>
          <CloudUploadOutlinedIcon color="inherit" className={classes.smsCloudIcon} />
        </LayoutGridItem>
        <LayoutGridItem xs={12}>
          <Typography className={classes.smsHelperText}>{t('Drop your files here to upload')}</Typography>
        </LayoutGridItem>
      </LayoutGrid>
    </Backdrop>
  );
};
