import {
  isInterviewScheduled,
  isInterviewed,
  isOfferByFacility,
  isRFO,
  isSent,
  isWFC,
  placementStatusOptions,
} from 'app/components/Placement/NextPlacementStatus/StatusDefaults';
import { placementStatusAction } from 'store/redux-store/placement-status/slice';
import { selectPlacementStatus } from 'store/redux-store/placement-status/selectors';
import {
  IPlacementRequirementKey,
  IPlacementStatus,
  IPlacementStatusRequirementMessage,
  PlacementOrderType,
} from 'app/models/Placement/PlacementDetails';
import {
  IPlacementEditableRequirementDeps,
  IPlacementEditableRequirements,
  requirementType,
} from 'app/models/Placement/PlacementSummary';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import { selectSubmissionInfo } from 'store/redux-store/placement-summary/selectors';
import moment from 'moment';
import { isNullOrUndefined } from '@microsoft/applicationinsights-core-js';

/**
 * Removes existing fail and risk requirements to prevent duplicates
 * and ensure only one requirement exists for the requirement type
 */
export const useEditRequirementsHelper = () => {
  const dispatch = useDispatch();
  const { failedRequirements, warningRequirements } = useSelector(selectPlacementStatus);

  const getExistingRequirementStatusForType = React.useCallback(
    (
      requirementId: IPlacementRequirementKey,
    ): {
      status: any;
      requirementType: requirementType;
    } | null => {
      const failed = Object.entries(failedRequirements ?? {});
      for (const item of failed) {
        for (const req of item[1]) {
          if (req.id === requirementId) {
            return { status: item[1], requirementType: requirementType.fail };
          }
        }
      }
      const warning = Object.entries(warningRequirements ?? {});
      for (const item of warning) {
        for (const req of item[1]) {
          if (req.id === requirementId) {
            return { status: item[1], requirementType: requirementType.risk };
          }
        }
      }
      return null;
    },
    [failedRequirements, warningRequirements],
  );

  const removeExistingRequirementById = React.useCallback(
    (id: IPlacementRequirementKey) => {
      const existingReq = getExistingRequirementStatusForType(id);
      if (existingReq !== null) {
        if (existingReq.requirementType === requirementType.fail) {
          dispatch(
            placementStatusAction.removeFailedRequirement({
              requirement: { id },
            }),
          );
        } else if (existingReq.requirementType === requirementType.risk) {
          dispatch(
            placementStatusAction.removeWarningRequirement({
              requirement: { id },
            }),
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getExistingRequirementStatusForType],
  );

  return { getExistingRequirementStatusForType, removeExistingRequirementById };
};

/**
 * Custom hook to automatically remove and apply pass/fail/risk requirements
 */
export const useEditableRequirement = <T, P>({
  data,
  secondaryData,
  requirementId,
  requirementKey,
  failRequirement,
  riskRequirement,
  requirementCheck,
  shouldAddFailedRequirement = true,
  formReset = false,
}: {
  data: T;
  secondaryData?: P;
  requirementId: IPlacementRequirementKey;
  dependencyRequirementIds?: IPlacementRequirementKey[];
  failRequirement: IPlacementStatusRequirementMessage;
  riskRequirement?: IPlacementStatusRequirementMessage;
  requirementKey: keyof IPlacementEditableRequirements;
  requirementCheck: (data, secondaryData?) => IPlacementEditableRequirementDeps;
  // Used for situations where the failed icon needs to show, but the message should not show
  shouldAddFailedRequirement?: boolean;
  formReset?:boolean;
}) => {
  const dispatch = useDispatch();
  const { removeExistingRequirementById } = useEditRequirementsHelper();
  const { findStatusForRequirement } = useStatusForRequirement();

  React.useEffect(() => {
    removeExistingRequirementById(requirementId);
    const statusList = findStatusForRequirement(requirementId);

    if (statusList) {
      const calculatedRequirementType = requirementCheck(data, secondaryData);
      if (calculatedRequirementType.requirement === requirementType.fail) {
        // FAIL
        dispatch(placementSummaryActions.setEditableRequirementTypes({ [requirementKey]: calculatedRequirementType }));
        if (shouldAddFailedRequirement) {
          dispatch(
            placementSummaryActions.setFailedRequirement({
              nextStatusRequirement: statusList,
              requirement: failRequirement,
            }),
          );
        }
      } else if (calculatedRequirementType.requirement === requirementType.risk && riskRequirement) {
        // RISK
        dispatch(placementSummaryActions.setEditableRequirementTypes({ [requirementKey]: calculatedRequirementType }));
        dispatch(
          placementStatusAction.setWarningRequirement({
            requirement: riskRequirement,
          }),
        );
      } else if (calculatedRequirementType.requirement === requirementType.pass) {
        // PASS
        dispatch(placementSummaryActions.setEditableRequirementTypes({ [requirementKey]: calculatedRequirementType }));
      } else {
        dispatch(placementSummaryActions.setEditableRequirementTypes({ [requirementKey]: undefined }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data, 
    findStatusForRequirement, 
    secondaryData, 
    shouldAddFailedRequirement, 
    formReset, 
    requirementId, 
    requirementKey
  ]);
};

/**
 * Determines what status to apply a fail or risk requirement to
 * Provide list of placement status as list
 * @returns Function that returns an `IPlacementStatus` array
 */
export const useStatusForRequirement = () => {
  const placementOrderType = useSelector(selectPlacementOrderType);
  const { currentPlacementStatus } = useSelector(selectPlacementStatus);
  const {
    orderReq: {
      order: { orderSsn, orderDob },
    },
  } = useSelector(selectSubmissionInfo);
  // NOTE: If you need different statuses between failed/risk, return two arrays from this function
  // one for failed and one for risk and use the appropriate array in your caller's function.
  const findStatusForRequirement = React.useCallback(
    (id: IPlacementRequirementKey): IPlacementStatus[] | null => {
      switch (id) {
        case IPlacementRequirementKey.extensionTimeOff:
          return [placementStatusOptions.onAssignment];
        case IPlacementRequirementKey.availableStartDate:
          return [
            placementStatusOptions.requestFileOut,
            ...(placementOrderType === PlacementOrderType.Default ? [] : [placementStatusOptions.travelerAccepts]),
          ];
        case IPlacementRequirementKey.arrivalTime:
        case IPlacementRequirementKey.arrivalDate:
          return [
            ...(placementOrderType === PlacementOrderType.Default ? [] : [placementStatusOptions.waitingGoodToGo]),
          ];
        case IPlacementRequirementKey.ssn:
        case IPlacementRequirementKey.dob:
        case IPlacementRequirementKey.datesConfirmed:
        case IPlacementRequirementKey.dnsCleared:
        case IPlacementRequirementKey.travelConfirmed:
        case IPlacementRequirementKey.gender:
        case IPlacementRequirementKey.transportation:
        case IPlacementRequirementKey.travelTimePreference:
        case IPlacementRequirementKey.departureAirport:
        case IPlacementRequirementKey.departureItinerary:
          return [placementStatusOptions.waitingGoodToGo];
        case IPlacementRequirementKey.placementDate:
          return placementOrderType === PlacementOrderType.StrikeOrderInHsg
            ? [placementStatusOptions.travelerAccepts]
            : [
                placementStatusOptions.travelerAccepts,
                placementStatusOptions.waitingGoodToGo,
                placementStatusOptions.offerBookedByTraveler,
                placementStatusOptions.offerByFacility,
              ];

        case IPlacementRequirementKey.orderShiftOffered:
          return [placementStatusOptions.travelerAccepts, placementStatusOptions.waitingGoodToGo];
        case IPlacementRequirementKey.facilityStatus:
          return [
            placementStatusOptions.offerBookedByTraveler,
            placementStatusOptions.offerByFacility,
            placementStatusOptions.travelerAccepts,
          ];
        case IPlacementRequirementKey.orderDOB:
          return getWhatsNextContainer({
            currPlacementStatus: currentPlacementStatus,
            placementOrderType: placementOrderType,
            isDataRequired: !!orderDob?.type,
          });
        case IPlacementRequirementKey.orderSsn:
          return getWhatsNextContainer({
            currPlacementStatus: currentPlacementStatus,
            placementOrderType: placementOrderType,
            isDataRequired: !!orderSsn?.type,
          });
        default:
          break;
      }
      return null;
    },
    [currentPlacementStatus, orderSsn?.type, orderDob?.type, placementOrderType],
  );
  return { findStatusForRequirement };
};

const getWhatsNextContainer = ({
  currPlacementStatus,
  placementOrderType,
  isDataRequired,
}: {
  currPlacementStatus: IPlacementStatus;
  placementOrderType: PlacementOrderType;
  isDataRequired: boolean;
}) => {
  let whatsNextContainer = [];
  if (isWFC(currPlacementStatus) && isDataRequired) {
    if (placementOrderType !== PlacementOrderType.Default) {
      whatsNextContainer.push(placementStatusOptions.travelerAccepts);
    } else {
      whatsNextContainer.push(placementStatusOptions.requestFileOut);
    }
  } else if (isRFO(currPlacementStatus)) {
    if (isDataRequired && placementOrderType === PlacementOrderType.Default) {
      whatsNextContainer.push(placementStatusOptions.submitPacket);
    }
  } else if (
    isSent(currPlacementStatus) ||
    isInterviewed(currPlacementStatus) ||
    isInterviewScheduled(currPlacementStatus)
  ) {
    if (placementOrderType === PlacementOrderType.Default) {
      whatsNextContainer.push(placementStatusOptions.offerBookedByTraveler);
      if (isDataRequired) {
        whatsNextContainer.push(placementStatusOptions.submitPacket);
      }
    }
  } else if (isOfferByFacility(currPlacementStatus)) {
    if (placementOrderType === PlacementOrderType.Default) {
      whatsNextContainer.push(placementStatusOptions.travelerAccepts);
    }
  }
  return whatsNextContainer;
};

export const isDobAvailable = dob => {
  if (dob === null || dob === undefined) return false;
  if (dob.toString().includes('*')) return true;
  return moment(dob).isValid();
};

export const isSsnAvailable = (ssn: string | null | undefined) =>
  !(isNullOrUndefined(ssn) || ssn === '') &&
  (new RegExp(/\d{3}-\d{2}-\d{4}/g).test(ssn) || (ssn !== null && ssn?.toString().includes('*')));
