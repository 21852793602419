export function formatPhoneNumber(phoneNumberString?: string) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

export const unformatPhoneNumber = (number: string) => {
  return number?.replace(/\D/g, '').substring(0, 10);
};

export const TwilioExtensionCode = '+1';
export const JabberExtensionCode = '71';
