import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import {
  TextField,
  Checkbox,
  Popper,
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  Box,
  Grid,
  Autocomplete,
  AutocompleteCloseReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import SearchIcon from '@mui/icons-material/Search';
import { theme } from 'styles/global-styles';

const useStyles = makeStyles()(() => ({
  checked: {
    color: `${theme.palette.framework.system.skyBlue} !important`,
  },
  icon: {
    '&:hover': {
      fill: theme.palette.framework.system.skyBlue,
    },
  },

  textField: {
    fontSize: 12,
    color: '#52429A',
    '&::placeholder': {
      opacity: 1,
      fontSize: 12,
      color: '#C0C0C0',
    },
  },
  popperDisablePortal: {
    position: 'relative',
    width: '-webkit-fill-available !important',
  },
  fullWidth: {
    padding: '0px',
  },
  paper: {
    margin: 0,
  },
  option: {
    paddingLeft: 10,
  },
  listBox: {
    /** Ensure utmost five options are within dropdown
     * and list does not toggle when anchor is at the screen center
     */
    maxHeight: 250,
  },
  customTextField: {
    display: 'none',
    '& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.MuiAutocomplete-inputRoot': {
      paddingRight: '0px',
      paddingLeft: '3px',
    },
  },
  SearchIcon: {
    opacity: 0.6,
    paddingLeft: '3px',
  },
  helperText: {
    fontSize: '0.75rem',
    margin: '3px 0px 0px 14px',
    textAlign: 'left',
    fontFamily: 'Roboto, Helvetica, Arial, sansSerif',
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
  },
}));

const smallTextFieldTheme = createTheme(
  {
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            height: '2.7rem',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: { height: '2.7rem' },
        },
      },
    },
  },
  [theme],
);

const errorTextFieldTheme = createTheme(theme, {
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          height: '4rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: { height: '4rem' },
      },
    },
  },
});

const getObjectValue = obj => (obj ? (typeof obj.value === 'object' ? obj.id : obj.value) : obj);

export const TypeAheadCustomComponent = props => {
  const { t } = useTranslation();
  const {
    label,
    options,
    isError,
    isDisabled,
    helperText,
    customClasses,
    noOptionsText,
    size,
    enableCustomScroll,
    removeCloseIcon,
    isMultiSelect,
    filterName,
    searchOnCharacterCount = 3,
    selectAllValue = null,
    isLoading = false,
    nonNullable = false,
    hiddenInput,
  } = props;
  const { classes, cx } = useStyles();

  const [textFieldValue, setTextFieldValue] = useState<any>('');

  const handleClose = (event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
    if (reason === 'toggleInput' || reason === 'blur') {
      return;
    }
    props.handleClose();
  };

  const onInputChange = (event, newInputValue: string, reason: AutocompleteInputChangeReason) => {
    setTextFieldValue(prevValue => (reason === 'input' ? newInputValue : prevValue));
    if (reason === 'input' && props.fetchOptions && (!newInputValue || searchOnCharacterCount <= newInputValue.length))
      props.onInputChange(newInputValue);
  };

  /** choice is returned as an array for both multiselect & nullable single-select
   *  but is an object for non-nullable single-select
   */
  const handleChange = (
    event,
    choice: ISelection[] | ISelection | null,
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails,
  ) => {
    /** Multi-select behavior */
    if (isMultiSelect && Array.isArray(choice)) {
      /** check - clicks on selectAllValue option */
      if (selectAllValue && details?.option?.value === selectAllValue) {
        props.onChange(choice.some(item => item.value === selectAllValue) ? options : []);
        return;
      } /** choose  selectAllValue if all values have been selected*/ else if (
        selectAllValue &&
        choice.length === options.length - 1 &&
        !choice.some(item => item.value === selectAllValue)
      ) {
        props.onChange(options);
        return;
      }
      /** splice the selectAllValue if click is on a differnt option and selectAllValue is selected */
      choice.some(item => item.value === selectAllValue) && choice.splice(0, 1);
      props.onChange(choice);
    } /** SIngle-select behavior */ else {
      /** Choice would be an non-array object when it is nonNullable */
      props.onChange(!Array.isArray(choice) ? choice : Array.isArray(choice) && choice.length ? choice.pop() : null);
    }
  };

  return (
    <Grid container spacing={2}>
      <Box
        width={'100%'}
        minWidth={'24rem'}
        border={1}
        borderColor="grey.500"
        borderRadius={1}
        // TODO: @Kevin George - See if this is still needed after MUI V5 upgrade
        // display={false}
      >
        <Autocomplete
          open
          multiple={!nonNullable}
          clearOnBlur
          fullWidth
          onClose={handleClose}
          filterOptions={props.fetchOptions && (opt => opt)}
          noOptionsText={noOptionsText || undefined}
          value={isMultiSelect || nonNullable ? props.value : props.value ? [props.value] : []}
          options={options}
          getOptionDisabled={isDisabled ? options => true : options => false}
          loading={isLoading}
          disableCloseOnSelect
          disablePortal
          isOptionEqualToValue={(option, value) => getObjectValue(option) === getObjectValue(value)}
          getOptionLabel={(option: ISelection) => option.name}
          popupIcon={props.popupIcon ? props.popupIcon : null}
          forcePopupIcon={true}
          renderTags={() => null}
          classes={
            {
              listbox: classes.listBox,
              popperDisablePortal: classes.popperDisablePortal,
              paper: classes.paper,
              fullWidth: classes.fullWidth,
              option: classes.option,
              ...customClasses,
            } || undefined
          }
          clearIcon={removeCloseIcon ? null : <CloseIcon fontSize="small" />}
          onInputChange={onInputChange}
          inputValue={textFieldValue}
          renderInput={params => (
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={isError ? errorTextFieldTheme : smallTextFieldTheme}>
                <TextField
                  error={isError}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus={false}
                  helperText={helperText}
                  label={label}
                  placeholder={t('appHeader.searchPlaceHolder')}
                  {...params}
                  className={cx({ [classes.customTextField]: hiddenInput })}
                  size={size || undefined}
                  InputProps={{
                    ...params.InputProps,
                    classes: { input: classes.textField },
                    startAdornment: (
                      <div style={{ paddingLeft: '9px' }}>
                        <SearchIcon className={classes.SearchIcon} />
                      </div>
                    ),
                    'aria-label': 'filter-type-ahead',
                  }}
                  FormHelperTextProps={{
                    classes: { error: classes.helperText },
                  }}
                  onKeyDown={(event: any) => {
                    if (event.key === 'Backspace' && !textFieldValue) {
                      event.stopPropagation();
                    }
                  }}
                />
              </ThemeProvider>
            </StyledEngineProvider>
          )}
          onChange={handleChange}
          renderOption={(p, option, { selected }) => (
            <li id={option.value} {...p}>
              <Checkbox
                size="small"
                classes={{ checked: classes.checked }}
                icon={
                  props.isMultiSelect ? (
                    <CheckBoxOutlineBlankIcon classes={{ root: classes.icon }} />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )
                }
                checkedIcon={!isMultiSelect ? <RadioButtonCheckedIcon color="secondary" /> : undefined}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          PopperComponent={PopperComponent}
          aria-label={`custom-typeAhead-${filterName}`}
        />
        {props.children}
      </Box>
    </Grid>
  );
};

const PopperComponent = props => (
  <Popper
    {...props}
    sx={{ width: 'fit-content', position: 'relative !important', transform: 'unset !important' }}
    placement="bottom-start"
  />
);
