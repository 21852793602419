import { ISkillSetExpiration } from 'app/enums/SkillSet';
import _ from 'lodash';
import { groupBy } from '../Cards/helper';

export const getWarningColor = (message: string) => {
  if (message === ISkillSetExpiration.EXPIRED) return '#BD3B4D';
  else if (message === ISkillSetExpiration.EXPIRING_SOON || message === ISkillSetExpiration.MISSING_SKILL_SET)
    return '#F39E44';
  else return 'grey';
};

export const validateEmailPhone = (enableSummaryIconsUpdate, candidateDetails, field) => {
  if (
    enableSummaryIconsUpdate?.enabled &&
    (candidateDetails?.primaryEmail || candidateDetails?.phoneNumbers?.length) &&
    !field
  ) {
    return true;
  } else {
    return false;
  }
};

export const getExperianceEmployee = experienceEmployments => {
  const groupByWorkHistoryList = groupBy(experienceEmployments);
  const cherryPick = _.uniqBy(experienceEmployments, 'workHistoryId');
  cherryPick?.map((data: any) => {
    const currentItem: any = Object.entries(groupByWorkHistoryList).find(
      item => item[0] === data?.workHistoryId?.toString(),
    );

    return {
      count: currentItem ? currentItem[1].length : 0,
      facilityName: data.facilityName,
      startedOn: data.startedOn,
      endedOn: data.endedOn,
    };
  });
};
