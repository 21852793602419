import React from 'react';
import { Grid, GridDirection, GridSize } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

interface Props {
  labelContent: {
    label: string;
    size: GridSize;
  }[];
  valueContent: {
    value?: string | undefined | null;
    size: GridSize;
    docIcon?: boolean | null | undefined;
  }[];
  direction?: GridDirection;
  id?: string;
}

const useStyles = makeStyles()(theme => ({
  label: {
    fontWeight: 500,
  },

  documentIcon: {
    fill: `${theme.palette.components.icons.secondary.backgroundColor} !important`,
    fontSize: 'large',
    marginLeft: 10,
  },
}));

export const ValueContainer = (props: Props) => {
  const { labelContent, valueContent, direction } = props;
  const { classes } = useStyles();
  return (
    <>
      <Grid data-testid="label-test" container direction={direction ? direction : 'row'} spacing={2} id={props.id}>
        {labelContent.map((item, key) => {
          return (
            <Grid
              item
              xs={item.size}
              className={classes.label}
              id={`${props.id}_Col_${key}`}
              style={{ paddingBottom: '3px', paddingTop: '0px' }}
            >
              {item.label}
            </Grid>
          );
        })}
        {valueContent.map((item, key) => {
          return (
            <Grid
              item
              xs={item.size}
              id={`${props.id}_Val_${key}`}
              style={{ paddingLeft: item.value === `-` ? '10px' : '', paddingTop: '0px' }}
            >
              {item.value && item.value}
              {item.docIcon && <DescriptionOutlinedIcon className={classes.documentIcon} />}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
