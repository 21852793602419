import React from 'react';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { makeStyles } from 'tss-react/mui';

const useStyles: any = makeStyles()({
  dialogTitle: {
    fontSize: '20px',
  },
});

export enum HearsayType {
  License = 'License',
  Certification = 'Certification',
}

export const LicenseAndCertificationDelete = props => {
  const { openDeleteDialog, onDeleteDialogClose, deleteHearsayLicense, type } = props;
  const { classes } = useStyles();
  return (
    <GenericDialog
      open={openDeleteDialog}
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
      dialogTitleProps={{
        classes: { root: classes.dialogTitle },
        text: 'Are you sure you want to Delete?',
        closeButton: false,
      }}
      dialogActions={[
        {
          text: 'Cancel',
          color: 'tertiary',
          onClick: () => onDeleteDialogClose(),
        },
        {
          text: 'Yes',
          variant: 'contained',
          color: 'primary',
          onClick: () => deleteHearsayLicense(),
        },
      ]}
    >
      <span>
        <span style={{ fontSize: '14px' }}>
          {type === HearsayType.License
            ? `This action will delete the unverified license.`
            : `This action will delete the unverified certification.`}
        </span>
      </span>
    </GenericDialog>
  );
};
