import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { createContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UnitPreferences } from './UnitPreferences';
import { unitPreferenceActions } from 'store/redux-store/unit-preferences/slice';
import { useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import {
  notificationDataActions,
  notificationDataKey,
  notificationDataReducer,
} from 'store/redux-store/notification/notification.redux';
import { notificationSaga } from 'store/redux-store/notification/notification.saga';
import { initialSubmissionPreferences } from 'store/redux-store/unit-preferences/initialState';
import { TemplateUserTypes } from '@AMIEWEB/Common/SubmissionPreference/Common/helper';
import { TemplateCategory } from '@AMIEWEB/Common/SubmissionPreference/SubmissionEmail/AddTemplateModal';

export const AddOnContext = createContext<{ facilityId: number; unitId: number }>({ facilityId: 0, unitId: 0 });

export const UnitPreferencesWrapper = () => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string; unitId: string }>();
  useInjectReducer({ key: notificationDataKey, reducer: notificationDataReducer });
  useInjectSaga({ key: notificationDataKey, saga: notificationSaga });

  useEffect(() => {
    const unitId = parseInt(params.unitId);
    const facilityId = parseInt(params.id);
    dispatch(unitPreferenceActions.getActivePlacementCounts({ unitId }));
    dispatch(notificationDataActions.getTemplatesByCategory({ category: TemplateCategory.PacketSubmission }));
    dispatch(
      notificationDataActions.getFacilityTemplatesByCategory({
        category: TemplateCategory.PacketSubmission,
        userType: TemplateUserTypes.Facility,
        userId: facilityId,
      }),
    );
    dispatch(notificationDataActions.getFacilityBaseTemplate({userType: TemplateUserTypes.Facility}));
    dispatch(unitPreferenceActions.getUnitPreferences({ unitId }));
    if (unitId && facilityId) {
      dispatch(unitPreferenceActions.getFacilityUnitClientContacts({ facilityId, unitId }));
      dispatch(unitPreferenceActions.getExistingOrders({ unitId, facilityId }));
    }
    return () => {
      dispatch(unitPreferenceActions.setConfirmationDialog(false));
      dispatch(unitPreferenceActions.setTabValue(0));
      dispatch(unitPreferenceActions.setIsPreferenceChaged(false));
      dispatch(unitPreferenceActions.setPreferences({...initialSubmissionPreferences}));
    };
  }, [params.unitId, params.id, dispatch]);

  return (
    <AddOnContext.Provider value={{ facilityId: parseInt(params.id), unitId: parseInt(params.unitId) }}>
      <LayoutGrid direction="column" spacing={2} data-testid="unit-preference-layout">
        <LayoutGridItem>
          <UnitPreferences />
        </LayoutGridItem>
      </LayoutGrid>
    </AddOnContext.Provider>
  );
};
