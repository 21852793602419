import { Autocomplete, Grid, TextField } from 'amn-ui-core';
import { CategorizedAutoComplete } from 'app/components/Common/Search/CategorizedAutoComplete';
import { IExternalTaskSubType, ISubCategoryDependentData, ITaskCategorizedList } from 'app/models/Tasks/Tasks';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectSubCategory1, selectSubCategory2 } from '../store/Tasks.selectors';

export const ExternalTaskCategoryInputs = ({
  classes,
  isCallingAPI,
  formMethods,
  externalTaskOptionsList,
  taskCategoryValue,
  subCategory1,
  subCategory2,
  setSubCategory1Value,
  setSubCategory2Value,
  isEdit = false,
  showSubCategoriesField = false,
  subCategory1Label,
  subCategory2Label,
}: {
  classes: any;
  isCallingAPI: boolean;
  formMethods: any;
  externalTaskOptionsList: any;
  taskCategoryValue: ITaskCategorizedList;
  subCategory1?: ISubCategoryDependentData;
  subCategory2?: ISubCategoryDependentData;
  setSubCategory1Value?: (e) => void;
  setSubCategory2Value?: (e) => void;
  isEdit?: boolean;
  showSubCategoriesField: boolean;
  subCategory1Label: string;
  subCategory2Label: string;
}) => {
  const { t } = useTranslation();
  const {
    control,
    clearErrors,
    setValue,
    register,
    formState: { isDirty, errors },
  } = formMethods;
  const subCategory1List = useSelector(selectSubCategory1);
  const subCategory2List = useSelector(selectSubCategory2);

  const [subCategory1OptionsList, setSubCategory1OptionsList] = useState<IExternalTaskSubType[]>([]);
  const [subCategory2OptionsList, setSubCategory2OptionsList] = useState<IExternalTaskSubType[]>([]);
  const initialSubNames: IExternalTaskSubType[] = [];

  const CategoryVar = {
    categorySubType1: 'categorySubType1',
    categorySubType2: 'categorySubType2',
  };

  const handleInputChange = (options, key) => {
    const filteredList = options?.filter(opt => opt.label?.toLowerCase().includes(key?.toLowerCase()));
    return filteredList;
  };

  const handleCategory1Change = (event?: any, v?: string) => {
    const categoryList1 = handleInputChange(subCategory1List, v);
    setSubCategory1OptionsList(categoryList1);
  };

  const handleCategory2Change = (event?: any, v?: string) => {
    const categoryList2 = handleInputChange(subCategory2List, v);
    setSubCategory2OptionsList(categoryList2);
  };

  const formValueAddition = (value, category) => {
    if (!value) {
      setValue(category, null);
    } else {
      setValue(category, {
        subTypeId: value.subTypeId,
        subTypeText: category === CategoryVar.categorySubType1 ? subCategory1Label : subCategory2Label,
        subTypeDataId: value.id,
        subTypeDataText: value.label,
      } as ISubCategoryDependentData);
    }
  };

  useEffect(() => {
    setSubCategory1OptionsList(subCategory1List);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategory1List]);

  useEffect(() => {
    setSubCategory2OptionsList(subCategory2List);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategory2List]);

  useEffect(() => {
    if (isEdit && showSubCategoriesField && subCategory1List && subCategory2List) {
      setValue('categorySubType1', subCategory1);
      setValue('categorySubType2', subCategory2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSubCategoriesField, subCategory1List, subCategory2List]);

  useEffect(() => {
    if (isEdit) {
      setValue('task', taskCategoryValue?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  useEffect(() => {
    if (subCategory1) {
      const category1: IExternalTaskSubType = {
        id: subCategory1?.subTypeDataId,
        label: subCategory1?.subTypeDataText,
        subTypeId: subCategory1?.subTypeId,
      };
      setSubCategory1Value(category1);
      formValueAddition(category1, CategoryVar.categorySubType1);
      clearErrors(CategoryVar.categorySubType1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategory1]);

  useEffect(() => {
    const category2: IExternalTaskSubType = {
      id: subCategory2?.subTypeDataId,
      label: subCategory2?.subTypeDataText,
      subTypeId: subCategory2?.subTypeId,
    };
    setSubCategory2Value(category2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategory2]);

  return (
    <React.Fragment>
      <Grid item xs={12} style={{ marginTop: '0.6rem' }} data-testid="task-type-input">
        <Controller
          control={control}
          name={'task'}
          rules={{ required: true }}
          render={({ ref, onChange, ...rest }) => (
            <CategorizedAutoComplete
              optionsList={externalTaskOptionsList}
              disabled={true}
              error={errors?.task ? true : false || (isDirty && errors?.task) ? true : false}
              className={classes.inputs}
              classes={{ paper: classes.paper }}
              labelClass={classes.fieldLabel}
              id={'task'}
              value={taskCategoryValue?.id}
              label={t('notification.createTask.taskLabel')}
              noOptionsText={t('notification.createTask.noMatchesFound')}
              helperText={(errors?.task && t('required')) || (isDirty && errors?.task && t('required'))}
            />
          )}
        />
      </Grid>
      {showSubCategoriesField && subCategory1List && (
        <React.Fragment>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={'categorySubType1'}
              render={({ ref, onChange, value, ...rest }) => (
                <Autocomplete
                  filterSelectedOptions
                  disabled={isCallingAPI}
                  onInputChange={handleCategory1Change}
                  defaultValue={subCategory1List?.find(c => c.id === subCategory1?.subTypeDataId)}
                  options={subCategory1OptionsList || initialSubNames}
                  filterOptions={option => option}
                  getOptionLabel={(option: IExternalTaskSubType) => option?.label}
                  noOptionsText={t('notification.createTask.noResultLabel')}
                  onChange={(e, val) => {
                    formValueAddition(val, CategoryVar.categorySubType1);
                    setSubCategory1Value(val);
                    clearErrors(CategoryVar.categorySubType1);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={subCategory1Label}
                      variant="filled"
                      className={classes.inputs}
                      InputLabelProps={{
                        className: classes.fieldLabel,
                      }}
                      error={Object.keys(errors).includes('categorySubType1')}
                      helperText={Object.keys(errors).includes('categorySubType1') && t('required')}
                      {...register('categorySubType1', { required: true })}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            {subCategory2List && (
              <Controller
                control={control}
                name={'categorySubType2'}
                render={({ ref, onChange, value, ...rest }) => (
                  <Autocomplete
                    blurOnSelect
                    filterSelectedOptions
                    disabled={isCallingAPI}
                    defaultValue={subCategory2List?.find(c => c.id === subCategory2?.subTypeDataId)}
                    options={subCategory2OptionsList || initialSubNames}
                    onInputChange={handleCategory2Change}
                    filterOptions={option => option}
                    getOptionLabel={(option: IExternalTaskSubType) => option?.label}
                    noOptionsText={t('notification.createTask.noResultLabel')}
                    onChange={(e, val) => {
                      formValueAddition(val, 'categorySubType2');
                      setSubCategory2Value(val);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={subCategory2Label}
                        InputLabelProps={{
                          className: classes.fieldLabel,
                        }}
                        variant="filled"
                        className={classes.inputs}
                        {...register('categorySubType2', { required: false })}
                      />
                    )}
                  />
                )}
              />
            )}
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
