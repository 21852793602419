import { Contacts } from 'app/models/Orders/OrderDetails';

export const createOrderContacts = (contacts: Contacts) => {
  return [
    {
      contactName: 'Regional Director',
      name: {
        firstName: contacts?.regionalDirector?.firstName,
        lastName: contacts?.regionalDirector?.lastName,
      },
      phoneNumber:  contacts?.regionalDirector?.phoneNumber ,
      email: contacts?.regionalDirector?.email,
      contactId: contacts?.regionalDirector?.userId,
      // message:  contacts?.accountManager?.message,
    },
    {
      contactName: 'Account Manager',
      name: {
        firstName: contacts?.accountManager?.firstName,
        lastName: contacts?.accountManager?.lastName,
      },
      phoneNumber:  contacts?.accountManager?.phoneNumber ,
      email: contacts?.accountManager?.email,
      contactId: contacts?.accountManager?.userId,
      // message:  contacts?.accountManager?.message,
    },
    {
      contactName: 'Credentialing Analyst',
      name: {
        firstName: contacts?.credentialingAnalyst?.firstName,
        lastName: contacts?.credentialingAnalyst?.lastName,
      },
      phoneNumber:  contacts?.credentialingAnalyst?.phoneNumber ,
      email: contacts?.credentialingAnalyst?.email,
      contactId: contacts?.credentialingAnalyst?.userId,
      // message:  contacts?.accountManager?.message,
    },
    {
      contactName: 'Clinical Manager',
      name: {
        firstName: contacts?.clinicalManager?.firstName,
        lastName: contacts?.clinicalManager?.lastName,
      },
      phoneNumber:  contacts?.clinicalManager?.phoneNumber ,
      email: contacts?.clinicalManager?.email,
      contactId: contacts?.clinicalManager?.userId,
      // message:  contacts?.accountManager?.message,
    },
  ];
};
