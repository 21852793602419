import React from 'react';
import { missingField } from 'app/constants';
import { MoreTextBlock } from '../../CustomComponents/MoreTextBlock';
import { useTranslation } from 'react-i18next';

export const OrderInfoRequiredQualifications = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <div id="placement-order-info-required-qualifications" data-testid="order-info-required-qualifications-item">
      <MoreTextBlock
        title={t('placement.profile.orderInformation.orderInformation.orderInfoRow.requiredQualifications')}
        text={
          props.orderInformation?.requiredQualifications ? props.orderInformation?.requiredQualifications : missingField
        }
        charLimit={170}
        id={'placementDetailsTabsRequiredQualificationsDetails'}
      />
    </div>
  );
};
