import { ChipsContainer } from 'app/ComponentLibrary/Filter';
import { instanceOfISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import {
  pipelineChoiceActions,
  pipelineChoiceKeys,
} from 'app/components/Common/WorkDesk/PipelineChoice/pipelineChoice.redux';
import { selectCredExpiringDaysGrid } from 'app/components/Common/WorkDesk/PipelineChoice/pipelineChoice.selectors';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { determineChipSelected } from '../Helpers/tiersFilterHelper';
import { expiringCredentialsSubStatus } from '../Models/ExpiringDocumentInterfaces';
import { credentialingDashboardExpiringActions } from '../Store/ExpiringCredential.redux';
import { selectFilterChipSelected } from '../Store/ExpiringCredential.selector';

export const ExpiringDayChips = props => {
  const dispatch = useDispatch();
  const filterChipSelected = useSelector(selectFilterChipSelected);
  const daySelection = useSelector(selectCredExpiringDaysGrid);
  // Filter
  const { getValues, setValue } = useFormContext();

  //EVENTS
  const handleChipDelete = (field, chip) => {
    //console.log(daySelection);
    if (field === 'tiersSelected') {
      if (daySelection.length > 1) {
        dispatch(
          pipelineChoiceActions.setPipelineChoice({
            key: pipelineChoiceKeys.CRED_EXPIRING_DAYS,
            selection: [
              ...daySelection.filter(
                item => !(item.category === chip.value.category && item.subcategory === chip.value.subcategory),
              ),
            ],
          }),
        );
      } else {
        dispatch(
          pipelineChoiceActions.setPipelineChoice({
            key: pipelineChoiceKeys.CRED_EXPIRING_DAYS,
            selection: [{ category: 5, subcategory: null, value: expiringCredentialsSubStatus.allExpiring }],
          }),
        );
      }
    } else {
      const chosenValue = getValues(field);
      if (chosenValue && chosenValue.length > 0 && Array.isArray(chosenValue)) {
        const newValues = [...chosenValue];
        newValues.splice(
          newValues.findIndex(opt => (instanceOfISelection(chip) ? opt.value : opt) === chip.value),
          1,
        );
        setValue(field, newValues);
      } else {
        setValue(field, null);
      }
      const newChipSelection = {
        ...filterChipSelected,
        [field]: null,
      };
      dispatch(credentialingDashboardExpiringActions.setFilterChipSelected(newChipSelection));
      props.onApplyFilter(getValues(), newChipSelection);
    }
  };

  const handleChipClick = (key, chip) => {
    //filter-by-chip provision is not implemented for below fields
    if (!['tiersSelected'].includes(key)) {
      const newChipSelection = {
        ...filterChipSelected,
        [key]: determineChipSelected(chip, filterChipSelected[key]),
      };
      dispatch(credentialingDashboardExpiringActions.setFilterChipSelected(newChipSelection));
      props.onApplyFilter(getValues(), newChipSelection);
    }
  };

  return (
    <>
      <ChipsContainer
        chips={props.chips}
        onDelete={(field, chip, index) => handleChipDelete(field, chip)}
        onClick={(field, chip, index) => handleChipClick(field, chip)}
      />
      <button style={{ display: 'none' }} type="submit" />
    </>
  );
};
