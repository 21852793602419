import React from 'react';
import { useWorkExperienceStyles } from '../WorkExperience/WorkExperienceCard.style';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { Box, Divider, Skeleton } from 'amn-ui-core';
import { Title } from '../../../ProfileTab/Cards/styles';
import ExperienceSection from '../WorkExperience/ExperienceSection/ExperienceSection';
import { RowSectionTitle } from '../../Common/RowSectionTitle';
import { RowSection } from '../../Common/RowSection';
import { useTranslation } from 'react-i18next';
import ApproveUnApprove from './SubComponent/ApproveUnApprove';
import { useSelector } from 'react-redux';
import {
  selectReferenceData,
  selectReferences,
} from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDecision } from '@optimizely/react-sdk';
import { ff_candidate_ui_manage_references, ff_summaryTabIconsUpdate } from 'app/constants/FeatureFlags/Candidate/Keys';
import { theme } from 'styles/global-styles';

function ExperienceUI({
  referenceDecision,
  showAllWorkExperience,
  showAllReferences,
  filterReferenceBy,
  addReferenceAuthorized,
  handleNavigation,
}) {
  const { classes } = useWorkExperienceStyles();
  const { t } = useTranslation();
  const selReferences = useSelector(selectReferences);
  const [showAddReference] = useDecision(ff_candidate_ui_manage_references);
  const { promiseInProgress } = usePromiseTracker({ area: 'get-candidate-summary-tab-details', delay: 0 });
  const referenceData = useSelector(selectReferenceData);
  const APPROVEDSTATUS = 'Approved';
  const UNAPPROVEDSTATUS = 'Unapproved';
  const [summaryTabIconsUpdateDecision] = useDecision(ff_summaryTabIconsUpdate);
  return (
    <>
      <LayoutGridItem>
        <Box className={classes.container}>
          <Box className={classes.children}>
            <LayoutGrid direction="column" spacing={1}>
              <Title className={classes.title}>{t('Work Experience')}</Title>
              <span className={classes.sectionDivider}>&nbsp;</span>
              <Divider />
              <ExperienceSection classes={classes} navigate={showAllWorkExperience} />
              {referenceDecision?.enabled && (
                <>
                  <span className={classes.sectionDivider}>&nbsp;</span>
                  <Divider />
                  <span className={classes.sectionDivider}>&nbsp;</span>
                  <RowSectionTitle
                    handleClick={() => {}}
                    header={
                      <div className={classes.boxInlineHeadLabelTxt}>
                        {t('Reference')}
                        <span
                          className={
                            selReferences?.items?.length > 0
                              ? classes.profileInlineLink
                              : classes.profileInlineLinkDisabled
                          }
                          onClick={() => {
                            showAllReferences();
                            filterReferenceBy('');
                          }}
                        >
                          {t('candidate.profileSummaryCard.all')}
                        </span>
                      </div>
                    }
                    data={
                      showAddReference?.enabled &&
                      addReferenceAuthorized && (
                        <span
                          className={classes.profileInlineLink}
                          onClick={() => {
                            handleNavigation();
                          }}
                        >
                          {t('candidate.profileSummaryCard.add')}
                        </span>
                      )
                    }
                  />
                  <span className={classes.sectionDivider}>&nbsp;</span>
                  <Divider />
                  <LayoutGrid
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    wrap="nowrap"
                    className={classes.content}
                  >
                    <LayoutGridItem style={{ paddingTop: '25px' }}>
                      {promiseInProgress ? (
                        <>
                          <Skeleton />
                          <Skeleton />
                        </>
                      ) : (
                        <LayoutGrid direction="column" spacing={1}>
                          {selReferences?.items?.length === 0 ? (
                            <RowSection
                              header={t(`candidate.workExperienceCard.noReferences`)}
                              data={''}
                              showHalfwayProgress={true}
                              showHalfwayProgressSecondaryIcon={true}
                              showAlert={false}
                              preData={''}
                              color={theme.palette.system.darkGreenForIcon}
                            />
                          ) : (
                            <>
                              {referenceData?.totals?.referencesApproved > 0 && (
                                <Box className={classes.alignContainer}>
                                  <div
                                    onClick={() => {
                                      showAllReferences();
                                      filterReferenceBy(APPROVEDSTATUS);
                                    }}
                                  >
                                    <ApproveUnApprove
                                      title={APPROVEDSTATUS}
                                      status={referenceData?.totals?.referencesApproved}
                                      summaryTabIconsUpdateDecision={summaryTabIconsUpdateDecision}
                                    />
                                  </div>
                                </Box>
                              )}
                              {referenceData?.totals?.referencesApproved === 0 && (
                                <ApproveUnApprove
                                  title={APPROVEDSTATUS}
                                  status={referenceData?.totals?.referencesApproved}
                                  summaryTabIconsUpdateDecision={summaryTabIconsUpdateDecision}
                                />
                              )}
                              {referenceData?.totals?.referencesUnapproved > 0 && (
                                <Box className={classes.alignContainer}>
                                  <div
                                    onClick={() => {
                                      showAllReferences();
                                      filterReferenceBy(UNAPPROVEDSTATUS);
                                    }}
                                  >
                                    <ApproveUnApprove
                                      title={UNAPPROVEDSTATUS}
                                      status={referenceData?.totals?.referencesUnapproved}
                                      summaryTabIconsUpdateDecision={summaryTabIconsUpdateDecision}
                                    />
                                  </div>
                                </Box>
                              )}
                              {referenceData?.totals?.referencesUnapproved === 0 && (
                                <ApproveUnApprove
                                  title={UNAPPROVEDSTATUS}
                                  status={referenceData?.totals?.referencesUnapproved}
                                  summaryTabIconsUpdateDecision={summaryTabIconsUpdateDecision}
                                />
                              )}
                            </>
                          )}
                        </LayoutGrid>
                      )}
                    </LayoutGridItem>
                  </LayoutGrid>
                </>
              )}
            </LayoutGrid>
          </Box>
        </Box>
      </LayoutGridItem>
    </>
  );
}

export default ExperienceUI;
