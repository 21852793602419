import { IconButton, Theme, useTheme } from 'amn-ui-core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomGrid } from 'app/components/Common/Grid/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { candidateDetailsSelection } from '../Profile/CandidateDetails.selector';
import { panelActions, PanelChoice } from '../PanelOptions/PanelOption.redux';
import { breadcrumbActions } from '../../../Common/BreadcrumbNavigation/BreadcrumbNavigation.redux';
import { usePromiseTracker } from 'react-promise-tracker';
import {
  selectJobInterestsGridData,
  selectJobInterestsCount,
  selectFilterSourceData,
  selectInterestServiceFailure,
  selectInterestDrawerData,
  selectJobInterests,
} from './JobInterest.selector';
import { jobInterestActions } from './JobInterest.redux';
import { filterDefaultValues, getjobInterestGridHeaders, getNavData } from './utils';
import queryString from 'query-string';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { OrderMapView } from './OrderMapView';
import { gridStateActions, GridTag } from '../../../Common/Grid/GridStateManagement/GridState.redux';
import { selectGridState, selectFilters } from '../../../Common/Grid/GridStateManagement/GridState.selectors';
import { GridFilter } from './GridFilter';
import { SkillSet } from 'app/models/Candidate/CandidateJobInterest';
import { getFilter } from './helper';
import { DetailsPageGrid } from 'app/layout/pages/DetailsPageGrid';
import { DrawerFitted } from 'app/components/Common/Drawer/Drawer';
import { OrderPreviewDrawerViewer } from 'app/components/GlobalSearch/Order/OrderPreviewDrawerViewer';
import { useDecision } from '@optimizely/react-sdk';
import { ff_candidate_ui_job_interests } from 'app/constants/FeatureFlags/Candidate/Keys';
import { globalActions } from 'app/ApplicationRoot/Global.redux';

const useStyles = makeStyles()((theme: Theme) => ({
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50vh',
  },
  iconButton: {
    padding: '0 6px',
  },
}));

const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_PAGE_NUMBER = 1;

export const JobInterestGrid = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const candidateDetails = useSelector(candidateDetailsSelection);
  const flattenedData = useSelector(selectJobInterestsGridData);
  const apiResponse = useSelector(selectJobInterests);
  const drawerData = useSelector(selectInterestDrawerData);
  const { totalCount, filteredCount } = useSelector(selectJobInterestsCount);
  const gridState = useSelector(selectGridState);
  const appliedFilterValues = useSelector(selectFilters);
  const filterSourceData = useSelector(selectFilterSourceData);
  const interestServiceFailure = useSelector(selectInterestServiceFailure);

  const view = queryString.parse(window.location.search).view;
  const [isGridListed, setGridListed] = useState<boolean>(view !== 'map');

  const { promiseInProgress } = usePromiseTracker({ area: 'job-interest-call', delay: 0 });

  const orderNavData = getNavData('Order Details', candidateDetails?.travelerId, candidateDetails?.brandId);
  const facilityNavData = getNavData('Facility Details', candidateDetails?.travelerId, candidateDetails?.brandId);
  const [enableJobInterests] = useDecision(ff_candidate_ui_job_interests);

  const pageBreadCrumbs = [
    { title: t('candidate.gridTitle'), clickable: true, onClick: () => handleNavigateBack() },
    { title: t('candidate.jobInterestGrid.title') },
  ];

  const handleNavigation = navigateTo => {
    if (navigateTo.includes('order')) {
      dispatch(breadcrumbActions.setNavigationData(orderNavData));
    }

    if (navigateTo.includes('facility')) {
      dispatch(breadcrumbActions.setNavigationData(facilityNavData));
    }
  };

  const handleNavigateBack = () => {
    window.history.pushState(null, '', `${window.location.pathname}?tab=1`);
    //Action dispatched to reset grid state when navigating back
    dispatch(gridStateActions.reset());
    dispatch(panelActions.setPanelOptions({ panelOption: PanelChoice.Tabs }));
  };

  const enableGridView = () => {
    setGridListed(true);
    window.history.pushState(
      null,
      '',
      `${window.location.pathname}?panel=${PanelChoice.JobInterestGrid.toLowerCase()}`,
    );
  };

  const enableMapView = () => {
    setGridListed(false);
    window.history.pushState(
      null,
      '',
      `${window.location.pathname}?panel=${PanelChoice.JobInterestGrid.toLowerCase()}&view=map`,
    );
  };

  const fetchData = (
    offset = 20,
    page: number | null = null,
    filterParams?: {
      orderStatus?: string;
      fromDate?: string;
      toDate?: string;
      states?: string[];
      skillSets?: SkillSet[];
    },
  ) => {
    if (enableJobInterests?.enabled && candidateDetails.travelerId && candidateDetails.brandId) {
      const candidateId = candidateDetails.travelerId;
      const brandId = candidateDetails.brandId;
      const newOffset = ((page || 1) - 1) * offset;
      dispatch(
        jobInterestActions.getJobInterestAction({
          travelerId: candidateId,
          brandId,
          limit: offset,
          offset: newOffset,
          filterEnable: filterParams ? true : false,
          orderStatus: filterParams?.orderStatus,
          fromDate: filterParams?.fromDate,
          toDate: filterParams?.toDate,
          states: filterParams?.states,
          skillSets: filterParams?.skillSets,
          t,
        }),
      );
    }
  };

  const handlePagination = (offset, page) => {
    const filters = getFilter(appliedFilterValues?.filters, appliedFilterValues?.dirtyFields, filterSourceData);
    fetchData(offset, page, filters);
  };

  const filterGridData = filters => {
    fetchData(gridState.pageSize || DEFAULT_PAGE_SIZE, DEFAULT_PAGE_NUMBER, filters);
  };

  const enableDrawer = (rowKey: number, columnKey: string) => {
    const selectedorder = apiResponse[rowKey];
    const displayValue = flattenedData[rowKey][columnKey];
    dispatch(jobInterestActions.setDrawerData({ open: true, data: { displayValue, ...selectedorder } }));
  };

  useEffect(() => {
    if (gridState.gridTag !== GridTag.JobInterestGrid) {
      dispatch(
        gridStateActions.setGridState({
          gridTag: GridTag.JobInterestGrid,
          pageSize: DEFAULT_PAGE_SIZE,
          pageNumber: DEFAULT_PAGE_NUMBER,
          previewItemId: -1,
          showPreviewDialog: false,
          sortedColumn: null,
          filterAttributes: {
            filters: Object.entries(filterDefaultValues),
            dirtyFields: { interestDate: true, fromDate: true, toDate: true },
            selectedChipFilters: {},
          },
        }),
      );
      fetchData(DEFAULT_PAGE_SIZE, DEFAULT_PAGE_NUMBER, {
        fromDate: filterDefaultValues.fromDate,
        toDate: filterDefaultValues.toDate,
      });
    } else {
      const filters = getFilter(appliedFilterValues?.filters, appliedFilterValues?.dirtyFields, filterSourceData);
      const pageSize = gridState.pageSize || DEFAULT_PAGE_SIZE;
      const pageNumber = gridState.pageNumber || DEFAULT_PAGE_NUMBER;
      fetchData(
        pageSize,
        pageNumber,
        filters || { fromDate: filterDefaultValues.fromDate, toDate: filterDefaultValues.toDate },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (interestServiceFailure) {
      dispatch(
        globalActions.setSnackBar({
          message: t('API Error, Filtering Unsuccessful'),
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interestServiceFailure]);

  return (
    <>
      {promiseInProgress ? (
        <CircularProgress className={classes.MuiCircularProgressRoot} />
      ) : (
        <>
          <DetailsPageGrid
            breadcrumbs={pageBreadCrumbs}
            title={`${t('candidate.jobInterest.resultsTitle')} (${
              filteredCount !== null ? filteredCount : totalCount
            })`}
            handleNavigateBack={handleNavigateBack}
            iconButtons={[
              <IconButton
                color="primary"
                disableTouchRipple
                disableFocusRipple
                style={{ background: 'none' }}
                classes={{ root: classes.iconButton }}
                aria-label="grid-view-button"
                onClick={() => enableGridView()}
                size="small"
              >
                <FormatListBulletedIcon
                  style={{ color: isGridListed ? '#0098DB' : undefined, opacity: isGridListed ? 1 : 0.6 }}
                />
              </IconButton>,
              <IconButton
                color="primary"
                disableTouchRipple
                disableFocusRipple
                style={{ background: 'none' }}
                classes={{ root: classes.iconButton }}
                aria-label="map-view-button"
                onClick={() => enableMapView()}
                size="small"
              >
                <LocationOnOutlinedIcon
                  style={{ color: isGridListed ? undefined : '#0098DB', opacity: isGridListed ? 0.6 : 1 }}
                />
              </IconButton>,
            ]}
          >
            {isGridListed ? (
              <CustomGrid
                columns={getjobInterestGridHeaders(t)}
                rows={flattenedData}
                fixedItemsPerPage={gridState.pageSize || DEFAULT_PAGE_SIZE}
                defaultPageView={
                  gridState.pageSize !== 0 ? gridState.pageSize.toString() : DEFAULT_PAGE_SIZE.toString()
                }
                defaultSortDirection={gridState.sortedColumn?.direction}
                defaultSortedColumn={gridState.sortedColumn?.column}
                currentPageNumber={gridState.pageNumber || DEFAULT_PAGE_NUMBER}
                pageOptions={[20, 50, 100]}
                showCount={false}
                disableGutters
                noPadding
                gridName={GridTag.JobInterestGrid}
                onNavigation={handleNavigation}
                onPagination={handlePagination}
                onPageSizeChange={pageSize => handlePagination(pageSize, 1)}
                minCellWidth={'100px'}
                totalRecordsCount={filteredCount || totalCount || 0}
                enableStateManagement
                serverSidePagination
                CustomizedFilter={<GridFilter onApplyFilter={filterGridData} />}
                onElementClick={enableDrawer}
              />
            ) : (
              <OrderMapView />
            )}
          </DetailsPageGrid>
        </>
      )}
      <DrawerFitted
        onDrawerClose={() => {
          dispatch(jobInterestActions.setDrawerData({ open: false, data: undefined }));
        }}
        width={450}
        top={0}
        backgroundColor={theme.palette.framework.system.whisper}
        open={drawerData?.open || false}
      >
        {drawerData?.data ? <OrderPreviewDrawerViewer type={1} /> : null}
      </DrawerFitted>
    </>
  );
};
