import { GridCellParams } from "@mui/x-data-grid-pro";
import { Autocomplete, TextField, materialUiXGrid } from "amn-ui-core";
import React, {  useEffect } from "react";
import { useState } from "react";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
    optionItem: {
        padding: '5px',
        '&:hover': {
            backgroundColor: '#f5f5f5',
        }
    },
    optionItemSelected: {
        padding: '5px',
        backgroundColor: '#EFEFEF',
    },
    textField: {
        '& .MuiInputBase-root': {
            background: '#fff',
            height: '45px',
            '&:hover': {
                background: '#fff',
            },
            '&:focus': {
                background: '#fff',
            }
        },
    },
});

interface EditGridDropdownProps {
    options: any[];
    customWidth?: string;
    valueField?: string;
}

export const RenderEditDropdownCell =
    ({ ...props }: EditGridDropdownProps) =>
        (params: GridCellParams) => {
            return <EditGridDropdown params={params} props={props} />;
        };

const EditGridDropdown = ({ props, params }: { props: EditGridDropdownProps; params: GridCellParams }) => {
    const { classes } = useStyles();
    const api = materialUiXGrid.useGridApiContext();
    const row = params.row;
    const field = params.colDef.field;
    const [selected, setSelected] = useState<string>(params.value);
    const [dropdownOptions, setDropdownOptions] = useState<{ ID: number; Description: string }[]>(props.options);

    useEffect(() => {
        setSelected(params.value);
    }, [params]);

    const getDropdownOptions = () => {
        let options = props.options;
        if (!options.length) {
            options = row[`${field}FilterOptions`]?.map(item => {
                return { ID: item.value, Description: item.description };
            });
        }

        setDropdownOptions(options);
    };

    useEffect(() => {
        getDropdownOptions();
    }, [params.row[`${field}FilterOptions`]]);

    const handleEditChange = async (e, value) => {
        await api?.current.setEditCellValue({ id: params.id, field: params.field, value: value?.Description });
        setSelected(value?.Description);
    };

    return  (
        <div style={{ width: '100%' }}>
            <Autocomplete
                forcePopupIcon={true}
                popupIcon={<ExpandMoreOutlinedIcon />}
                blurOnSelect
                disableClearable
                clearOnBlur
                options={dropdownOptions || props.options}
                getOptionLabel={option => option?.Description}
                onChange={handleEditChange}
                value={dropdownOptions?.find(item => item.Description === (selected || params.value)) || null}
                renderInput={autoParams => (
                    <TextField
                        sx={{
                            '& .MuiInputBase-root': {
                                background: 'transparent',
                                height: '45px',
                                '&:hover': {
                                    background: 'transparent',
                                },
                                '&:focus': {
                                    background: 'transparent',
                                }
                            },
                        }}
                        className={classes.textField}
                        variant="standard"
                        InputProps={{
                            ...autoParams.InputProps,
                        }}
                        {...autoParams}
                    />
                )}
                renderOption={(autoParams, option, { selected: isSelected }) => (
                    <li
                        {...autoParams}
                        className={
                            selected === option.Description
                                ? classes.optionItemSelected
                                : classes.optionItem
                        }
                    >
                        {option.Description}
                    </li>
                )}
            />
        </div>
    );
};