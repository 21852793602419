import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState, initialSubmissionPreferences } from './initialState';

const selectDomain = (state: RootState) => state.unitPreferences || initialState;

export const selectUnitPreference = createSelector(
  [selectDomain],
  preferences => preferences.submissionPreferences || initialSubmissionPreferences,
);

export const selectFacilityUnitClientContacts = createSelector([selectDomain], data => data.facilityUnitClientContacts);

export const selectPreferenceChanged = createSelector([selectDomain], data => data.isPreferenceChanged);

export const selectConfirmationDialog = createSelector([selectDomain], data => data.confirmationDialog);

export const selectTabValue = createSelector([selectDomain], data => data.tabValue);

export const selectMiscellaneousData = createSelector([selectDomain], data => data.miscellaneous);

export const selectExistingOrdersForUnit = createSelector([selectDomain], data => data.existingOrders);

export const selectExistingPlacements = createSelector([selectDomain], data => data.existingPlacements);

export const selectOrderActivePlacementCounts = createSelector([selectDomain], data => data.orderActivePlacementCounts);

export const selectReadyToSubmitPlacementIds = createSelector([selectDomain], data => data.readyToSubmitPlacementIds);

export const selectUpdatePreferenceDialog = createSelector([selectDomain], data => data.updateDialogOpen);

export const selectUpdateInprogres = createSelector([selectDomain], data => data.updateInprogres);

export const selectPreferenceSelectionModal = createSelector([selectDomain], data => data.preferenceSelectionModal);
