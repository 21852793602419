import React from 'react';
import { Loading, RowTitle, TableCell } from '../../../CommonTableComponents/CommonTableComponents';

export const KeyValueRow = ({
    id,
    rowTitle,
    loading,
    transparentTop = false,
    transparentBottom = false,
  ...props
}) => {
  const cellStyle = {
      borderTop: transparentTop ? 'transparent' : undefined,
      borderBottom: transparentBottom ? 'transparent' : undefined,
      paddingTop: transparentTop ? 0 : undefined,
      paddingBottom: transparentBottom ? 0 : undefined,
  };
  return (
    <tr>
      <TableCell id={'test'} style={{ width: '30%', ...cellStyle }}>
        <RowTitle>{rowTitle}</RowTitle>
      </TableCell>
      <TableCell id={'test'} style={{ width: '70%', ...cellStyle }}>
        {loading ? <Loading /> : props.children}
      </TableCell>
    </tr>
  );
}

