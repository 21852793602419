import { missingField } from 'app/constants';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useSelector, useDispatch } from 'react-redux';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import { RequirementsRow } from '../../TableComponents/TableComponents';
import {
  selectServiceFailures,
  selectSubmissionInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { getRequirementCheck } from '../../../helper';
import { TableSection } from '../../../customComponents/TableSection';
import { GetMomentOfDate } from 'utils/dates/moment';
import { Avatar } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import { Concatenate } from 'utils/string/string';
import { OverflowTooltip } from '@AMIEWEB/Common';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { colors } from 'styles/styleVariables';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HearingIcon from 'app/assets/images/IconImages/HearingIcon.svg';
import { VerticalContentPopover } from '@AMIEWEB/Common/Tooltips';

const useStyles = makeStyles()(theme => ({
  blue: {
    width: 20,
    height: 20,
    fontSize: 14,
    display: 'inline-flex',
  },
  green: {
    width: 20,
    height: 20,
    fontSize: 14,
    display: 'inline-flex',
  },
  overflow: {
    maxWidth: 200,
    fontSize: 14,
    fontWeight: 450,
  },
  documentIcon: {
    fill: `${theme.palette.system.lightBlue} !important`,
    marginTop: 1,
    marginLeft: 8,
  },
  secondaryText: {
    color: colors.darkText,
    fontSize: 14,
  },
  primary: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  keyValue: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    gap: 6,
  },
  expiredDate: {
    color: '#AB0012',
  },
}));

const useFilterIterator = ({ requirementFilter, licenses }) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  licenses.map(({ type }) => useRequirementFilter('submission-order', requirementFilter, type));

export const License = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    orderReq: {
      order: { licenses: orderReqItem },
      candidate: { licenses: candidateReqItem },
    },
    requirementFilter,
  } = useSelector(selectSubmissionInfo);
  const { promiseInProgress } = usePromiseTracker({
    area: 'placement-submission-license-order-requirement',
    delay: 0,
  });
  const { licenseOrderRequirement: serviceFailed } = useSelector(selectServiceFailures);
  const { placementId } = useContext(AddOnContext);
  const dispatch = useDispatch();
  const [forceTruncation, setForceTruncation] = React.useState(true);

  return (
    <RequirementsRow
      id="placement-submission-order-license"
      title={t('placement.profile.summary.submission.order.license.title')}
      requirementCell={
        (orderReqItem?.data?.orderLicensesDetails ?? []).length > 0 ? (
          <VerticalContentPopover
            maxHeight={300}
            forceTruncation={forceTruncation}
            setForceTruncation={setForceTruncation}
            value={<RowKey licenseData={orderReqItem} requirementFilter={requirementFilter} classes={classes} t={t} />}
          />
        ) : (
          missingField
        )
      }
      candidateCell={
        (candidateReqItem?.data ?? []).length > 0 ? (
          <VerticalContentPopover
            maxHeight={300}
            forceTruncation={forceTruncation}
            setForceTruncation={setForceTruncation}
            value={
              <RowValue licenseData={candidateReqItem} requirementFilter={requirementFilter} classes={classes} t={t} />
            }
          />
        ) : (
          missingField
        )
      }
      loading={promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() =>
        dispatch(placementSummaryActions.getLicenseOrderRequirement({ placementId, isRetryingService: true }))
      }
    />
  );
};
const RowKey = ({ licenseData, requirementFilter, classes, t }) => {
  const {
    data: { orderLicensesDetails: licenses },
  } = licenseData;
  const displayIterator = useFilterIterator({ requirementFilter, licenses });

  const items = React.useMemo(
    () =>
      licenses.reduce(
        (
          results,
          {
            type,
            requirementTypeId,
            stateAbbreviation,
            state,
            licenseAbbreviation,
            licenseDescription,
            isRequired,
            isMultiState,
            isCompactLicenseAccepted,
            documentAttachmentRequired,
          },
          index,
        ) => {
          if (!displayIterator[index].display) return results;
          const _stateAbbr = stateAbbreviation || missingField;
          const _license = Concatenate([licenseAbbreviation, licenseDescription], ' - ') || missingField;

          const SecondaryContent = () => (
            <div className={classes.secondaryText}>
              {isMultiState ? (
                <div>
                  {isCompactLicenseAccepted ? (
                    <div>{t('placement.profile.summary.submission.order.license.compactLicenseAccepted')}</div>
                  ) : null}
                  <div>
                    {Concatenate(
                      [t('placement.profile.summary.submission.order.license.facilityStateTag'), _stateAbbr],
                      ' ',
                    )}
                  </div>
                </div>
              ) : (
                Concatenate([t('placement.profile.summary.submission.order.license.stateTag'), _stateAbbr], ' ')
              )}
            </div>
          );

          const PrimaryContent = () => (
            <div className={classes.primary}>
              <OverflowTooltip value={_license} contentClass={classes.overflow} />
              <Avatar variant={isRequired ? 'primary' : 'green'} className={isRequired ? classes.blue : classes.green}>
                {isRequired ? 'R' : 'P'}
              </Avatar>
              {documentAttachmentRequired ? (
                <DescriptionOutlinedIcon fontSize="small" className={classes.documentIcon} />
              ) : null}
            </div>
          );
          const _icon = getRequirementCheck(type);
          results.push({
            // Code: Null coalescing op used to maintain alignment, if icon is null
            icon: _icon ?? <div style={{ visibility: 'hidden', padding: 14 }} />,
            primaryContent: <PrimaryContent />,
            secondaryContent: <SecondaryContent />,
          });
          return results;
        },
        [],
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [licenses, displayIterator],
  );
  return <TableSection items={items} avatarMargin={3} />;
};

const RowValue = ({ licenseData, requirementFilter, classes, t }) => {
  const { data: licenses = [] } = licenseData;
  const displayIterator = useFilterIterator({ requirementFilter, licenses });
  const items = React.useMemo(
    () =>
      licenses.length > 0
        ? licenses.reduce(
            (
              results,
              {
                licenseAbbreviation,
                licenseDescription,
                type,
                singleOrCompact,
                state,
                licenseType,
                expirationDate,
                licenseNumber,
                isHearsay,
                isExpired,
                isRisk,
                placementDisciplineId,
                disciplines,
              },
              index,
            ) => {
              // if (type !== null && !displayIterator[index].display) return results;
              const _license = Concatenate([licenseAbbreviation, licenseDescription], ' - ') || missingField;
              const _stateAbbr = Concatenate([state || missingField, singleOrCompact || missingField], ' - ');
              const _expMoment = GetMomentOfDate(expirationDate);

              const SecondaryContent = () => (
                <div className={classes.secondaryText}>
                  <div className={classes.keyValue}>
                    <span>{t('placement.profile.summary.submission.order.license.stateTag')}</span>
                    <span>{_stateAbbr}</span>
                  </div>
                  <div className={classes.keyValue}>
                    <span>{t('placement.profile.summary.submission.order.license.licenseType')}</span>
                    <span>{licenseType ? licenseType : missingField}</span>
                  </div>
                  <div className={classes.keyValue}>
                    <span>{t('placement.profile.summary.submission.order.license.expDate')}</span>
                    <span className={isExpired ? classes.expiredDate : undefined}>
                      {_expMoment.isValid() ? _expMoment.format('MM/DD/YYYY') : missingField}
                    </span>
                  </div>
                  <div className={classes.keyValue}>
                    <span>{t('placement.profile.summary.submission.order.license.licenseNo')}</span>
                    <span>{licenseNumber || missingField}</span>
                  </div>
                </div>
              );

              const PrimaryContent = () => (
                <div className={classes.primary}>
                  <OverflowTooltip value={_license} contentClass={classes.overflow} />
                  {isHearsay ? (
                    <img src={HearingIcon} alt="HearingIcon" style={{ marginLeft: 3 }} />
                  ) : (
                    <DoneAllIcon fontSize="small" sx={{ color: '#888888' }} />
                  )}
                </div>
              );

              results.push({
                icon: getRequirementCheck(type),
                primaryContent: <PrimaryContent />,
                secondaryContent: <SecondaryContent />,
              });

              return results;
            },
            [],
          )
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [licenses, displayIterator],
  );

  return <TableSection items={items} />;
};
