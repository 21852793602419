import React from 'react';
import { useTranslation } from 'react-i18next';
import { IContractDoc, DocSource, ContractStatus } from 'store/redux-store/placement-candidate-contract-doc-editor/types';
import { CardDate, DocumentCard, useDocCardStyles } from '../customComponents/DocumentCard';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { TFunction } from 'i18next';

export const ContractDocCard: React.FC<{
    card: IContractDoc;
    isSelected: boolean;
    [key: string]: any;
  }> = ({ card, isSelected, ...props }) => {
    const { t } = useTranslation();
    const { isCreatedFromClassic, isCardClickable, publishedToPassport } = React.useMemo(
      () => ({
        isCreatedFromClassic: card.source === DocSource.classic,
        isCardClickable: ![ContractStatus.pending, ContractStatus.requestContract].includes(card.status),
        publishedToPassport: [ContractStatus.published, ContractStatus.signed].includes(card.status),
      }),
      [card],
    );
  
    const { classes, cx } = useDocCardStyles({ isCardClickable });
  
    return (
      <DocumentCard
        {...props}
        {...{ classes, cx, t }}
        card={card}
        isSelected={isSelected}
        isCreatedFromClassic={isCreatedFromClassic}
        isCardClickable={isCardClickable}
        publishedToPassport={publishedToPassport}
        datesComponent={getContractCardDates({ card, classes, t })(card.status)}
      />
    );
  };

  const getContractCardDates = ({ card, classes, t }: { card: IContractDoc; classes; t: TFunction }) =>
    customSwitch({
      [ContractStatus.draft]: (
        <CardDate
          title={t('templateEditor.templateCard.generatedBy')}
          name={card.draftCreatedByName}
          date={card.view.draftGeneratedDate}
          classes={classes}
        />
      ),
      [ContractStatus.created]: (
        <>
          <CardDate
            title={t('templateEditor.templateCard.createdBy')}
            name={card.docCreatedByName}
            date={card.view.docCreatedDate}
            classes={classes}
          />
          <CardDate
            title={t('templateEditor.templateCard.generatedBy')}
            name={card.draftCreatedByName}
            date={card.view.draftGeneratedDate}
            classes={classes}
          />
        </>
      ),
      [ContractStatus.published]:
        card.source === DocSource.classic ? (
          <CardDate
            title={t('templateEditor.templateCard.statusChangedBy')}
            name={card.updatedByName}
            date={card.view.updateDate}
            classes={classes}
          />
        ) : (
          <CardDate
            title={t('templateEditor.templateCard.publishedBy')}
            name={card.publishedByName}
            date={card.view.publishedDate}
            classes={classes}
          />
        ),
      [ContractStatus.publishedVoid]: (
        <>
          <CardDate
            title={t('templateEditor.templateCard.publishedBy')}
            name={card.publishedByName}
            date={card.view.publishedDate}
            classes={classes}
          />
          <CardDate
            title={t('templateEditor.templateCard.voidedBy')}
            name={card.voidedByName}
            date={card.view.voidedDate}
            classes={classes}
          />
        </>
      ),
      [ContractStatus.signed]:
        card.source === DocSource.classic ? (
          <>
            <CardDate
              title={t('templateEditor.templateCard.statusChangedBy')}
              name={card.updatedByName}
              date={card.view.updateDate}
              classes={classes}
            />
            <CardDate title={t('templateEditor.templateCard.signed')} date={card.view.signedDate} classes={classes} />
          </>
        ) : (
          <>
            <CardDate
              title={t('templateEditor.templateCard.publishedBy')}
              name={card.publishedByName}
              date={card.view.publishedDate}
              classes={classes}
            />
            <CardDate title={t('templateEditor.templateCard.signed')} date={card.view.signedDate} classes={classes} />
          </>
        ),
    })(
      <CardDate
        title={t('templateEditor.templateCard.statusChangedBy')}
        name={card.updatedByName}
        date={card.view.updateDate}
        classes={classes}
      />,
    );
    