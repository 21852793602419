import { Box, Skeleton } from 'amn-ui-core';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectAuditData } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { useTranslation } from 'react-i18next';
import { RowSection } from '../../Common/RowSection';
import { useDecision } from '@optimizely/react-sdk';
import {
  ff_summaryTabIconsUpdate,
  ff_ui_Candidate_Summary_Manage_Audits,
} from 'app/constants/FeatureFlags/Candidate/Keys';
import { usePromiseTracker } from 'react-promise-tracker';
import { useStyles } from './utils';
import { CandidateContainer } from '../../Common/CandidateContainer';
import AddIcon from '@mui/icons-material/Add';
import AuditsIcon from 'app/assets/images/IconImages/AuditsIcon.svg';
import { theme } from 'styles/global-styles';

function AuditSummaryUIV2({ setAuditModalOpen, setAddAuditOpen, setIsAddAudit, setFilterModel }) {
  const auditData = useSelector(selectAuditData);
  const { t } = useTranslation();
  const [summaryTabIconsUpdateDecision] = useDecision(ff_summaryTabIconsUpdate);
  const [addAuditFeature] = useDecision(ff_ui_Candidate_Summary_Manage_Audits);
  const { promiseInProgress } = usePromiseTracker({ area: 'get-candidate-details', delay: 0 });
  const { classes } = useStyles();

  const handleNavigate = () => {
    setIsAddAudit(true);
    setAddAuditOpen(true);
  };

  return (
    <>
      <LayoutGridItem style={{ width: '100%' }} pb="12px">
        <Box className={classes.container}>
          <CandidateContainer
            title={`${t('candidate.auditCard.title')} (all)`}
            icon={<img src={AuditsIcon} alt="AuditsIcon" />}
            rightIcon={addAuditFeature.enabled && <AddIcon />}
            iconToolTip="Add Audit"
            onClick={handleNavigate}
            enableLink={true}
            onClickAll={() => {
              setAuditModalOpen(true);
              setFilterModel({
                items: [],
              });
            }}
          >
            <LayoutGridItem>
              {promiseInProgress ? (
                <LayoutGridItem mr={2}>
                  <Skeleton />
                  <Skeleton />
                </LayoutGridItem>
              ) : (
                <LayoutGrid direction="column" spacing={1}>
                  {auditData?.totals?.total === 0 ? (
                    <RowSection
                      header={t('candidate.auditCard.auditUnavailable')}
                      data={''}
                      preData={t('candidate.auditCard.auditUnavailable')}
                      color={theme.palette.system.paleDarkGreen}
                    />
                  ) : (
                    <>
                      {summaryTabIconsUpdateDecision.enabled && auditData?.totals?.holdFileOut > 0 && (
                        <RowSection
                          header={
                            <span
                              data-testid="hold-file-out"
                              className={classes.header}
                              onClick={() => {
                                setAuditModalOpen(true);
                                setFilterModel({
                                  items: [{ columnField: `holdFileOut`, operatorValue: `contains`, value: `Yes` }],
                                });
                              }}
                            >
                              {t('candidate.auditCard.holdFileOut1')}
                              <span className={classes.digit}>{auditData?.totals?.holdFileOut || '0'}</span>
                            </span>
                          }
                          data={''}
                          preData={''}
                          color={theme.palette.framework.navyBlue}
                          showAlert={true}
                        />
                      )}
                      {summaryTabIconsUpdateDecision.enabled && auditData?.totals?.ongoing > 0 && (
                        <RowSection
                          header={
                            <span
                              data-testid="ongoing-audits"
                              className={classes.header}
                              onClick={() => {
                                setAuditModalOpen(true);
                                setFilterModel({
                                  items: [{ columnField: `status`, operatorValue: `contains`, value: `Ongoing` }],
                                });
                              }}
                            >
                              {t('candidate.auditCard.ongoingAudit')}
                              <span className={classes.digit}>{auditData?.totals?.ongoing || '0'}</span>
                            </span>
                          }
                          data={''}
                          preData={true}
                          showHalfwayProgress
                          showHalfwayProgressSecondaryIcon
                          color={theme.palette.framework.sandyBrown}
                          showAlert
                        />
                      )}
                      {auditData?.totals?.fyionly > 0 && (
                        <RowSection
                          header={
                            <span
                              data-testid="fyi-audits"
                              className={classes.header}
                              onClick={() => {
                                setAuditModalOpen(true);
                                setFilterModel({
                                  items: [{ columnField: `status`, operatorValue: `contains`, value: `FYI only` }],
                                });
                              }}
                            >
                              {t('candidate.auditCard.fyiAudit')}
                              <span className={classes.digit}>{auditData?.totals?.fyionly || '0'}</span>
                            </span>
                          }
                          data={''}
                          preData={true}
                          showHalfwayProgress
                          showHalfwayProgressSecondaryIcon
                          showAlert
                          color={theme.palette.framework.navyBlue}
                        />
                      )}
                    </>
                  )}
                </LayoutGrid>
              )}
            </LayoutGridItem>
          </CandidateContainer>
        </Box>
      </LayoutGridItem>
    </>
  );
}

export default AuditSummaryUIV2;
