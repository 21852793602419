import React from 'react';
import { Box } from 'amn-ui-core';
import KeyboardArrowDownIcon from 'app/assets/images/IconImages/KeyboardArrowDownIcon.svg';
import KeyboardArrowDownIconWithBlue from 'app/assets/images/IconImages/KeyboardArrowDownIconWithBlue.svg';

interface PropType {
  iconStyle?: any;
  toggleArrow?: boolean;
}
export const KeyBoardArrowButton = ({ iconStyle, toggleArrow }: PropType) => {
  return (
    <Box>
      <img
        style={iconStyle ? iconStyle : { height: 36, width: 39 }}
        alt="KeyBoardArrow-icon"
        src={toggleArrow ? KeyboardArrowDownIconWithBlue : KeyboardArrowDownIcon}
      />
    </Box>
  );
};
