export const httpSuccess = status => {
  // 204 omitted
  switch (status) {
    case 200:
    case 201:
    case 202:
    case 203:
    case 205:
    case 207:
    case 208:
    case 226:
      return true;
    default:
      return false;
  }
};

export const httpServerError = status => {
  return status >= 500 && status <= 511;
};

export const httpClientError = status => {
  return status >= 400 && status <= 451;
};

export const encodeBody = (body: { [key: string]: any }) => {
  if (process.env.NODE_ENV !== 'production' && globalThis?.app?.env?.REACT_APP_LOCAL_API) return body;
  return encodeURI(JSON.stringify(body)).replace(/\//g, '%2F').replace(/\//g, '%5C');
};

export const encodeString = (value: string) => {
  if (process.env.NODE_ENV !== 'production' && globalThis?.app?.env?.REACT_APP_LOCAL_API) return value;
  return encodeURIComponent(value);
};