import React, { useEffect, useState } from 'react';
import { Grid } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { Cancel } from '@AMIEWEB/Common/CancelModal/Cancel';
import { Undo } from '@AMIEWEB/Common/UndoModal/Undo';
import { useDispatch, useSelector } from 'react-redux';
import { selectFacility } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { SimpleCheckbox } from '@AMIEWEB/Unit/Common/SimpleCheckbox';
import { SingleLineInput } from '@AMIEWEB/Unit/Common/SingleLineInput';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { IUpdateReferencesCommand } from '@AMIEWEB/Facility/FacilityStore/Facility.model';
import { selectUser } from 'oidc/user.selectors';
import { userRoles } from 'oidc/userRoles';
import { Authorized } from 'oidc/userHelper';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips';
import { usePromiseTracker } from 'react-promise-tracker';
import { updateFacilityReferencePromiseKey } from '@AMIEWEB/Facility/FacilityStore/Facility.saga';
import { isWholeNumber } from '@AMIEWEB/Order/OrderCreationForm/helper';
import { useEditReferencesStyles } from './styles';

export const EditReferences = props => {
  const { t } = useTranslation();
  const { classes } = useEditReferencesStyles();
  const dispatch = useDispatch();
  const { isPopupOpen, setPopupOpen } = props;
  const facility = useSelector(selectFacility);
  const { userInfo } = useSelector(selectUser);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [undoDialogOpen, setUndoDialogOpen] = useState<boolean>(false);
  const facilityDetailsResponse = facility?.facilityResponse;
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const { promiseInProgress: isBusyUpdating } = usePromiseTracker({
    area: updateFacilityReferencePromiseKey,
    delay: 0,
  });
  const [updatingReferences, setUpdatingReferences] = useState<boolean>(false);

  const {
    numberOfReferenceRequired,
    numberOfReferenceToRFO,
    submissionRequiredReference,
    supervisorAndAboveReference,
    withinLastMonthsReferenceChanged,
    entireWorkHistory,
  } = facilityDetailsResponse;

  const {
    handleSubmit,
    formState: { isDirty },
    reset,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      numberOfReferenceRequired: numberOfReferenceRequired ?? null,
      numberOfReferencesToRFO: numberOfReferenceToRFO ?? null,
      submissionRequiredReference: submissionRequiredReference ?? false,
      supervisorAndAboveReference: supervisorAndAboveReference ?? false,
      withinLastMonthsReferenceChanged: withinLastMonthsReferenceChanged ?? null,
      entireWorkHistory: entireWorkHistory ?? false,
    },
  });
  const [referancesToStartFocused, setReferancesToStartFocused] = useState<boolean>();
  const [referancesToRFOFocused, setReferancesToRFOFocused] = useState<boolean>();
  const [entireWorkHistoryFocused, setEntireWorkHistoryFocused] = useState<boolean>(false);

  const formValues = useWatch({
    control,
    name: [
      'entireWorkHistory',
      'numberOfReferencesToRFO',
      'numberOfReferenceRequired',
      'withinLastMonthsReferenceChanged',
    ],
  });

  useEffect(() => {
    const isRequired = formValues.numberOfReferencesToRFO > 0;
    setValue('submissionRequiredReference', isRequired);
  }, [formValues.numberOfReferencesToRFO]);

  useEffect(() => {
    formValues.entireWorkHistory
      ? setEntireWorkHistoryFocused(false)
      : formValues.withinLastMonthsReferenceChanged == null
      ? setEntireWorkHistoryFocused(false)
      : setEntireWorkHistoryFocused(true);
  }, [formValues.entireWorkHistory]);

  const isClientContactSpecialist = Authorized([userRoles.clinical_ClientContractSpecialist], userInfo);

  const onSubmit = data => {
    const updatedReferences: IUpdateReferencesCommand = {
      ...data,
      numberOfReferenceRequired: isNaN(parseInt(data?.numberOfReferenceRequired))
        ? 0
        : parseInt(data?.numberOfReferenceRequired),
      numberOfReferencesToRFO: isNaN(parseInt(data?.numberOfReferencesToRFO))
        ? 0
        : parseInt(data?.numberOfReferencesToRFO),
      withinLastMonthsReferenceChanged: isNaN(parseInt(data?.withinLastMonthsReferenceChanged))
        ? null
        : parseInt(data?.withinLastMonthsReferenceChanged),
      facilityId: facilityDetailsResponse?.facilityId,
      currentEmployeeId: userInfo.employeeId,
      currentEmployeeName: `${userInfo?.firstName} ${userInfo?.lastName}`,
    };
    dispatch(facilityActions.updateReferencesAction(updatedReferences));
    setUpdatingReferences(true);
  };

  useEffect(() => {
    if (!isBusyUpdating && updatingReferences) {
      setPopupOpen(false);
      setUpdatingReferences(false);
    }
  }, [isBusyUpdating, setPopupOpen, isPopupOpen]);

  const onDiscardChanges = () => {
    setConfirmDialogOpen(false);
    setPopupOpen(false);
  };

  const onClose = () => {
    if (isDirty) setConfirmDialogOpen(true);
    else setPopupOpen(false);
  };
  let showTimeout;
  let hideTimeout;

  const handleMouseEnter = () => {
    showTimeout = setTimeout(() => {
      setIsHovered(true);
    }, 200);
  };

  const handleMouseLeave = () => {
    clearTimeout(showTimeout);
    hideTimeout = setTimeout(() => {
      setIsHovered(false);
    }, 200);
  };

  useEffect(() => {
    return () => {
      clearTimeout(showTimeout);
      clearTimeout(hideTimeout);
    };
  }, [showTimeout, hideTimeout]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GenericDialog
          fullWidth
          variant={'blue'}
          classes={{
            paper: classes.dialogContainer,
          }}
          open={isPopupOpen}
          disablePortal
          disableEscapeKeyDown
          dialogTitleProps={{
            text: t('facility.editReferences'),
            closeButton: true,
            expandable: true,
            undoButton: true,
            onUndoClick: () => setUndoDialogOpen(true),
            disableUndo: !isDirty,
          }}
          dialogActions={[
            {
              text: t('global.button.cancel'),
              variant: 'contained',
              color: 'tertiary',
              disabled: isBusyUpdating,
              onClick: onClose,
            },
            {
              text: t('global.button.save'),
              variant: 'contained',
              color: 'primary',
              type: 'submit',
              disabled: !isDirty || isBusyUpdating,
              loading: isBusyUpdating,
            },
          ]}
          dialogContentProps={{
            classes: { root: classes.dialogContent },
          }}
          onClose={onClose}
        >
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={4} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <CustomTooltip
                open={!isClientContactSpecialist && isHovered}
                placement="bottom"
                arrow={true}
                tooltipContent={t('facility.references.tooltipText')}
              >
                <Controller
                  name={'numberOfReferenceRequired'}
                  control={control}
                  render={({ onChange, ...rest }) => (
                    <SingleLineInput
                      id={'numberOfReferenceRequired'}
                      inputDefaultValue={rest.value}
                      inputLabel={t('facility.references.referencesRequired')}
                      inputName={'numberOfReferenceRequired'}
                      inputValue={rest.value}
                      handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        onChange(parseInt(event.target.value));
                      }}
                      handleWarning={() => setReferancesToStartFocused(true)}
                      handleBlur={() => {
                        setReferancesToStartFocused(false);
                        rest.onBlur();
                      }}
                      shrinkLabel={referancesToStartFocused || isWholeNumber(formValues.numberOfReferenceRequired)}
                      inputType={'number'}
                      fieldStyle={classes.fieldStyle}
                      isdisabled={!isClientContactSpecialist}
                    />
                  )}
                />
              </CustomTooltip>
            </Grid>
            <Grid item xs={4}>
              <Controller
                name={'numberOfReferencesToRFO'}
                control={control}
                render={({ onChange, ...rest }) => (
                  <SingleLineInput
                    id={'numberOfReferenceToRFO'}
                    inputDefaultValue={rest.value}
                    inputLabel={t('facility.references.referenceToSubmit')}
                    inputName={'numberOfReferenceToRFO'}
                    inputValue={rest.value}
                    handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onChange(parseInt(event.target.value));
                    }}
                    shrinkLabel={referancesToRFOFocused || isWholeNumber(formValues.numberOfReferencesToRFO)}
                    handleWarning={() => setReferancesToRFOFocused(true)}
                    handleBlur={() => {
                      setReferancesToRFOFocused(false);
                      rest.onBlur();
                    }}
                    inputType={'number'}
                    fieldStyle={classes.fieldStyle}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                control={control}
                name={'submissionRequiredReference'}
                render={({ ref, onChange, ...rest }) => (
                  <SimpleCheckbox
                    id={'submissionRequired'}
                    isChecked={rest.value}
                    handleChecked={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onChange(event.target.checked);
                    }}
                    isdisabled={true}
                    checkboxLabel={t('facility.references.submissionRequrement')}
                    checkboxName={'submission-required-reference'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name={'withinLastMonthsReferenceChanged'}
                control={control}
                render={({ onChange, ...rest }) => (
                  <SingleLineInput
                    id={'withinLastMonthsReferenceChanged'}
                    inputDefaultValue={rest.value}
                    inputLabel={t('facility.references.withinLastMonth')}
                    inputName={'withinLastMonthsReferenceChanged'}
                    inputValue={formValues.entireWorkHistory ? '' : rest.value ?? ''}
                    handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onChange(parseInt(event.target.value));
                    }}
                    shrinkLabel={
                      entireWorkHistoryFocused ||
                      (isWholeNumber(formValues.withinLastMonthsReferenceChanged) && !formValues.entireWorkHistory)
                    }
                    handleWarning={() => setEntireWorkHistoryFocused(true)}
                    handleBlur={() => {
                      setEntireWorkHistoryFocused(false);
                      rest.onBlur();
                    }}
                    inputType={'number'}
                    fieldStyle={!formValues.entireWorkHistory ? classes.fieldStyle : classes.fieldStyleNonHover}
                    showEndAdornment={
                      entireWorkHistoryFocused ||
                      (isWholeNumber(formValues.withinLastMonthsReferenceChanged) && !formValues.entireWorkHistory)
                    }
                    endAdornmentValue={t('facility.references.months')}
                    isdisabled={formValues.entireWorkHistory}
                  />
                )}
              />
            </Grid>

            <Grid item xs={4} mt={'10px'}>
              <Controller
                control={control}
                name={'entireWorkHistory'}
                render={({ ref, onChange, ...rest }) => (
                  <SimpleCheckbox
                    id={'entireWorkHistory'}
                    isChecked={rest.value}
                    handleChecked={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onChange(event.target.checked);
                      if (event.target.checked) setValue('withinLastMonthsReferenceChanged', null);
                    }}
                    isdisabled={false}
                    checkboxLabel={t('facility.references.entireWorkHistory')}
                    checkboxName={'entire-work-history-reference'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                control={control}
                name={'supervisorAndAboveReference'}
                render={({ ref, onChange, ...rest }) => (
                  <SimpleCheckbox
                    id={'supervisorAndAbove'}
                    isChecked={rest.value}
                    handleChecked={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onChange(event.target.checked);
                    }}
                    isdisabled={false}
                    checkboxLabel={t('facility.references.supervisorAndAbove')}
                    checkboxName={'supervisor-and-above-Reference'}
                  />
                )}
              />
            </Grid>
          </Grid>
        </GenericDialog>
      </form>
      {confirmDialogOpen && (
        <Cancel
          openDialog={confirmDialogOpen}
          handleCancelAction={() => {
            setConfirmDialogOpen(false);
          }}
          handleConfirmAction={onDiscardChanges}
          cancelButtonId={'facility-references-discard-changes-cancel-button'}
          confirmButtonId={'facility-references-discard-changes-confirm-button'}
        />
      )}
      {undoDialogOpen && (
        <Undo
          openDialog={undoDialogOpen}
          handleConfirmAction={() => {
            reset();
            setUndoDialogOpen(false);
          }}
          handleCancelAction={() => {
            setUndoDialogOpen(false);
          }}
        />
      )}
    </>
  );
};
