import { missingDate } from 'app/constants';
import { convertToFormat } from 'app/helpers/dateHelper';
import moment from 'moment';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TimeOffRequest } from './TimeOffRequest';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { selectEditStatus } from '../../../Edit/store/placementPageAttributes.selector';
import { pageAttributesActions } from '../../../Edit/store/placementPageAttributes.redux';

export const SummaryRequestedTimeOff = ({
  disableLabel,
  disableControl,
  id,
  labelStyle,
  labelRenderer,
}: {
  disableLabel?: boolean;
  disableControl?: boolean;
  id: string;
  labelStyle?: any;
  labelRenderer?: JSX.Element;
}) => {
  const dispatch = useDispatch();
  const { control, watch, setValue } = useFormContext();
  const value = watch('requestedTimeOff');
  const requestedTimeOff = value.requestedTimeOff;
  const { requestedTimeOff: status } = useSelector(selectEditStatus);

  const onChipDelete = originalValue => timeOff => {
    const val: any[] = _.cloneDeep(originalValue);
    const index = (val as any[]).findIndex(
      obj => obj.startDate === timeOff.startDate && obj.endDate === timeOff.endDate,
    );
    val.splice(index, 1);
    setValue('requestedTimeOff', val, { shouldDirty: true });
  };

  const onDateAdd = originalValue => (startDate, endDate) => {
    setValue(
      'requestedTimeOff',
      [
        ...originalValue,
        {
          startDate: convertToFormat(startDate, 'yyyy-MM-DDT00:00:00'),
          endDate: convertToFormat(endDate, 'yyyy-MM-DDT00:00:00'),
        },
      ].sort((v1, v2) => {
        const p1 = moment(!v1.startDate ? missingDate : v1.startDate);
        const p2 = moment(!v2.startDate ? missingDate : v2.startDate);
        return p1 < p2 ? -1 : p1 > p2 ? 1 : 0;
      }),
      { shouldDirty: true },
    );
  };

  const onDateChange = originalValue => (startDate, endDate) => {};

  const setPageEditAttribute = open => () => {
    dispatch(pageAttributesActions.setPopperOpen(open));
    if (!open) {
      document.getElementById(id)?.focus();
    }
  };

  return (
    <Controller
      control={control}
      name="requestedTimeOff"
      render={({ ref, onChange, ...rest }) => (
        <TimeOffRequest
          data={requestedTimeOff}
          onDateAdd={onDateAdd(requestedTimeOff)}
          onChipDelete={onChipDelete(requestedTimeOff)}
          onChange={onDateChange(requestedTimeOff)}
          disableLabel={disableLabel}
          disableControl={status?.disabled}
          id={id}
          onOpen={setPageEditAttribute(true)}
          onClose={setPageEditAttribute(false)}
          labelStyle={labelStyle}
          LabelRenderer={labelRenderer}
          // id={`${editAccessor}-${editableFields.requestedTimeOff}`}
        />
      )}
    />
  );
};
