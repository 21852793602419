/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Grid, ThemeProvider, StyledEngineProvider,useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { reqPref } from 'app/enums/Unit';
import { ReqPrefSection } from '../Common/ReqPrefSection';
import { CustomDivider, UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { ComboSection } from './ComboSection';
import { useTranslation } from 'react-i18next';
import { FlexibleScheduleType } from 'app/models/Unit/CreateUnit';
import { IToggleProperty, ToggleNoSelection } from '../ToggleNoSelection';
import { selectIsEditUnit, selectOrderCreationProcessStep } from 'app/components/Order/Store/Order.selectors';
import { useSelector } from 'react-redux';
import { SimpleCheckbox } from '../Common/SimpleCheckbox';
import { selectIsUnitSelected } from 'app/components/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';

interface Props {
  onUnitPropChange: (property: string, value: any) => void;
  disableEdit?: boolean;
  hasRadio?: boolean;
  flexibleScheduleData?: any | null;
}

const useStyles = makeStyles()((theme) => ({
  comboSection: {
    paddingTop: 17,
  },
  holidayFieldWidth: {
    width: '110%',
  },
  comboSectionDivider: {
    margin: '0px 12px 0px 12px',
    opacity: 1,
    border: '1px solid rgb(209,209,209)',
  },
}));
export const FlexibleSchedule = (props: Props) => {
  const { onUnitPropChange, disableEdit, flexibleScheduleData } = props;
  const [yesNoOptionSelected, setYesNoOptionSelected] = React.useState<boolean>(flexibleScheduleData ? true : false);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme= useTheme();
  const initialData = flexibleScheduleData
    ? flexibleScheduleData
    : {
        isRequired: true,
        workDuringWeekend: null,
        workDuringHoliday: null,
      };
  const [flexibleSchedule, setFlexibleSchedule] = React.useState<FlexibleScheduleType>(initialData);

  const [showTextField, setShowTextfield] = React.useState<{
    isWorkDuringWeekend: boolean;
    isWorkDuringHoliday: boolean;
  }>({
    isWorkDuringHoliday: false,
    isWorkDuringWeekend: false,
  });
  const [showPanel, setShowPanel] = React.useState(false);
  const isOrderEditUnit = useSelector(selectIsEditUnit);
  const isUnitEditUnit = useSelector(selectIsUnitSelected);
  const isEditUnit = isUnitEditUnit ? isUnitEditUnit : isOrderEditUnit;
  const storeOrderCreationProcessStep = useSelector(selectOrderCreationProcessStep);

  useEffect(() => {
    if (flexibleScheduleData || initialData) {
      setFlexibleSchedule(flexibleScheduleData || initialData);
      setShowTextfield({
        isWorkDuringWeekend: flexibleScheduleData?.workDuringWeekend
          ? flexibleScheduleData?.workDuringWeekend
          : initialData.workDuringWeekend,
        isWorkDuringHoliday: flexibleScheduleData?.workDuringHoliday
          ? flexibleScheduleData?.workDuringHoliday
          : initialData.workDuringHoliday,
      });
      // setShowPanel(true);
      if ((isEditUnit || storeOrderCreationProcessStep || yesNoOptionSelected) && flexibleScheduleData) {
        setShowPanel(true);
      } else {
        setShowPanel(false);
      }
    }
  }, []);

  const toggleData: IToggleProperty = {
    showPanel,
    setShowPanel,
    onUnitPropChange,
    propertyName: 'flexibleSchedule',
    propertyValue: flexibleSchedule,
  };
  const toggleNoSelection = ToggleNoSelection(toggleData);

  const validateControl = (value: number) => {
    return value <= 99;
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      if (event.target.name === 'isWorkDuringWeekend') {
        setFlexibleSchedule({
          ...flexibleSchedule,
          workDuringWeekend: null,
        });
      } else if (event.target.name === 'isWorkDuringHoliday') {
        setFlexibleSchedule({
          ...flexibleSchedule,
          workDuringHoliday: null,
        });
      }
    }
    setShowTextfield({
      ...showTextField,
      [event.target.name]: event.target.checked,
    });
  };

  const handleCheckBoxChangeX = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleNoSelection.handleShowPanelX(event.target.checked);
    setYesNoOptionSelected(event.target.checked);
  };

  const handleChange = event => {
    if (event.target.type === 'radio') {
      if (event.target.value === reqPref.req)
        setFlexibleSchedule({ ...flexibleSchedule, isRequired: event.target.value === reqPref.req });
      else setFlexibleSchedule({ ...flexibleSchedule, isRequired: false });
    } else {
      let value = event.target.value;
      if (isNaN(value) || value == '') {
        return setFlexibleSchedule({
          ...flexibleSchedule,
          [event.target.name]: null,
        });
      }

      if (validateControl(parseInt(value))) {
        value = parseInt(value);
        setFlexibleSchedule({
          ...flexibleSchedule,
          [event.target.name]: value >= 0 ? value : 0,
        });
      }
    }
  };

  useEffect(() => {
    /*  if (showTextField.isWorkDuringHoliday || showTextField.isWorkDuringWeekend) {
      onUnitPropChange('flexibleSchedule', flexibleSchedule);
    } else onUnitPropChange('flexibleSchedule', null); */
    toggleNoSelection.onPropChange(showPanel, undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flexibleSchedule]);

  return (
    <div>
      {/* TODO: Remove this theme wrapper since it uses the global theme */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UnitDetailsBody variant="outlined">
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={1}>
                  <Grid item>
                    <UnitCardsTitle>{t('order.createUnit.flexibleSchedule.title')}</UnitCardsTitle>
                  </Grid>
                  <SimpleCheckbox
                    id={'createUnitFlexibleScheduleEnableCheck'}
                    isChecked={yesNoOptionSelected ? true : false}
                    isdisabled={disableEdit}
                    hasRadio={false}
                    handleChecked={handleCheckBoxChangeX}
                    checkboxLabel={`Yes`}
                    checkboxName={'no-cbx'}
                  />
                </Grid>
                {showPanel && (
                  <>
                    <ReqPrefSection
                      isRequired={flexibleSchedule.isRequired}
                      handleReqPref={handleChange}
                      isdisabled={disableEdit}
                      id={'createUnitFlexibleScheduleReqPrefRadio'}
                    />
                    <Grid container direction="row" className={classes.comboSection}>
                      <ComboSection
                        id={'createUnitFlexibleScheduleWorkDuringWeekendCheck'}
                        isComboChecked={showTextField.isWorkDuringWeekend}
                        handleComboChecked={handleCheckBoxChange}
                        comboCheckedLabel={t('order.createUnit.flexibleSchedule.workDuringWeekend')}
                        comboCheckedName={'isWorkDuringWeekend'}
                        comboInputName={'workDuringWeekend'}
                        comboDefaultValue={flexibleSchedule.workDuringWeekend}
                        comboInputLabel={t('order.createUnit.flexibleSchedule.workDuringWeekend')}
                        comboInputValue={flexibleSchedule.workDuringWeekend}
                        handleComboChange={handleChange}
                        isdisabled={disableEdit}
                      />

                      <CustomDivider
                        className={classes.comboSectionDivider}
                        orientation="vertical"
                        flexItem
                        variant="middle"
                      />
                      <ComboSection
                        id={'createUnitFlexibleScheduleWorkDuringHolidayCheck'}
                        isComboChecked={showTextField.isWorkDuringHoliday}
                        handleComboChecked={handleCheckBoxChange}
                        comboCheckedLabel={t('order.createUnit.flexibleSchedule.workDuringHoliday')}
                        comboCheckedName={'isWorkDuringHoliday'}
                        comboInputName={'workDuringHoliday'}
                        comboDefaultValue={flexibleSchedule.workDuringHoliday}
                        comboInputLabel={t('order.createUnit.flexibleSchedule.workDuringHoliday')}
                        comboInputValue={flexibleSchedule.workDuringHoliday}
                        handleComboChange={handleChange}
                        gridValue={5}
                        fieldStyle={classes.holidayFieldWidth}
                        isdisabled={disableEdit}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </UnitDetailsBody>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};
