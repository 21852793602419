import { Box, Divider, Grid, InputAdornment, TextField, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { ICandidateDetails } from 'app/models/Candidate/CandidateProfile';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styledComponent from 'styled-components';
import _ from 'lodash';
import { MISSING_FIELD } from 'app/components/Notification/Constants';
import { TagsWrapper } from './TagsWrapper/TagsWrapper';
import { ff_candidate_tags } from 'app/constants/FeatureFlags/Candidate/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { decodeBase64String } from 'app/helpers/componentHelpers';

const SummaryHeader = styledComponent(Typography)`
  color: #666;
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 10px;
`;
const SummaryDivider = styledComponent(Divider)`
  height: 2px;
  margin: 10px 0 15px 0;
`;

const useStyles = makeStyles()(theme => ({
  root: {
    background: '#FFF',
    padding: '25px',
  },
  summary: {
    color: '#555555',
    fontSize: '14px',
    paddingTop: '15px',
    marginBottom: '50px',
  },
  keyInfo: {
    color: '#555555',
    fontSize: '14px',
    paddingTop: '10px',
  },
  keyInfoValue: {
    color: '#333333',
    fontSize: '14px',
    fontWeight: 600,
    paddingTop: '10px',
  },
  keyDate: {
    padding: '10px 0',
  },
  adornedStart: {
    padding: '0 5px',
    color: '#333333',
    fontSize: '12px',
    fontWeight: 600,
    height: 35,
    maxHeight: 35,
  },
  kpiValue: {
    fontSize: '16px',
  },
  kpi: {
    border: 0,
    background: theme.palette.components.textfield.blankField.backgroundColor,
    borderRadius: '5px',
    width: 105,
    fontSize: '12px',
    marginRight: '5px',
  },
  kpiHeader: {
    padding: '2px',
    background: theme.palette.components.textfield.blankField.backgroundColor,
    fontSize: '12px',
    border: '0px',
    color: '#333',
    fontWeight: 500,
  },
  notchedOutline: {
    borderWidth: '0px',
    borderRadius: `5px ${theme.palette.components.textfield.blankField.backgroundColor}`,
  },
  showMore: {
    fontWeight: 500,
    fontSize: '15px',
    color: '#006fb9',
    cursor: 'pointer',
  },
}));

interface ICandidateSummaryProps {
  candidateDetails: ICandidateDetails;
  viewDetails?: () => void;
}
export const CandidateSummaryTab = (props: ICandidateSummaryProps) => {
  const [showTagsSection] = useDecision(ff_candidate_tags);
  const { candidateDetails } = props;
  const { classes } = useStyles();
  const [showFullSummary, setShowFullSummary] = React.useState(candidateDetails?.candidateSummary?.length < 150);
  const [summary, setSummary] = useState('');

  useEffect(() => {
    setSummary(candidateDetails?.candidateSummary ? decodeBase64String(candidateDetails?.candidateSummary) : '');
  }, [candidateDetails?.candidateSummary]);

  const getLastEndedActivePlacementDate = () => {
    const sortedActivePlacement = _.cloneDeep(candidateDetails?.activePlacements)?.sort((a, b) => {
      return moment(new Date(a.startDate)).isBefore(moment(new Date(b.startDate))) ? -1 : 1;
    });
    if (sortedActivePlacement && sortedActivePlacement?.length > 0) {
      return sortedActivePlacement[sortedActivePlacement?.length - 1]
        ? moment(new Date(sortedActivePlacement[sortedActivePlacement?.length - 1].endDate)).format('MM/DD/YYYY')
        : MISSING_FIELD;
    }
    return MISSING_FIELD;
  };

  const getLastPlacementEndDate = () => {
    const sortedPlacementInfo = _.cloneDeep(candidateDetails?.placement?.placementInfo).sort((a, b) => {
      return moment(new Date(a.startDate)).isBefore(moment(new Date(b.startDate))) ? -1 : 1;
    });
    if (sortedPlacementInfo && sortedPlacementInfo?.length > 0) {
      return sortedPlacementInfo[sortedPlacementInfo?.length - 1].endDate
        ? moment(new Date(sortedPlacementInfo[sortedPlacementInfo?.length - 1].endDate)).format('MM/DD/YYYY')
        : MISSING_FIELD;
    }
    return MISSING_FIELD;
  };

  const lastAssignmentEnded =
    candidateDetails?.activePlacements && candidateDetails?.activePlacements?.length > 0
      ? getLastEndedActivePlacementDate()
      : getLastPlacementEndDate();

  const nextPlacementStartDate = () => {
    const sortedPlacementInfo = _.cloneDeep(candidateDetails?.activePlacements)?.sort((a, b) => {
      return moment(new Date(a.startDate)).isBefore(moment(new Date(b.startDate))) ? -1 : 1;
    });

    if (sortedPlacementInfo && sortedPlacementInfo?.length > 0) {
      const nextPlacementDateDifference =
        (new Date(sortedPlacementInfo[0]?.endDate) as any) - (new Date(sortedPlacementInfo[0]?.startDate) as any);
      return new Date(nextPlacementDateDifference).getUTCDate() - 1 > 1
        ? moment(new Date(sortedPlacementInfo[0].startDate)).format('MM/DD/YYYY') || MISSING_FIELD
        : MISSING_FIELD;
    }
    return MISSING_FIELD;
  };

  const KPI = (candidateDetails: { header: string; value: string }) => {
    return (
      <TextField
        variant="outlined"
        size="small"
        multiline
        classes={{ root: classes.kpi }}
        disabled
        InputProps={{
          classes: {
            root: classes.kpiHeader,
            notchedOutline: classes.notchedOutline,
          },
          startAdornment: (
            <InputAdornment className={classes.adornedStart} position="start">
              <Typography style={{ color: candidateDetails.value ? '#333' : '#888' }} className={classes.kpiValue}>
                {candidateDetails.value}
              </Typography>
            </InputAdornment>
          ),
        }}
        value={candidateDetails.header}
      />
    );
  };

  return (
    <div className={classes.root}>
      <KPI
        value={
          candidateDetails?.experience?.totalYearsOfExperience
            ? `${Math.round(
                candidateDetails?.experience?.totalYearsOfExperience +
                  candidateDetails?.experience?.totalMonthsOfExperience / 12,
              )}`
            : `--`
        }
        header={`Years Experience`}
      />
      <KPI
        value={candidateDetails?.activePlacements ? `${candidateDetails?.activePlacements.length}` : `00`}
        header={`Active Placements`}
      />
      <KPI
        value={candidateDetails?.placement?.weeksBooked ? `${candidateDetails?.placement?.weeksBooked}` : `00`}
        header={`Weeks Booked`}
      />
      {showTagsSection?.enabled && (
        <Box style={{ marginTop: '12px' }}>
          <TagsWrapper candidateDetails={candidateDetails} />
        </Box>
      )}
      <div className={classes.keyDate}>
        <Grid container>
          <Grid item xs={6} className={classes.keyInfo}>
            <span>{`Available to Start`}</span>
          </Grid>
          <Grid item xs={6} className={classes.keyInfoValue}>
            {candidateDetails.availableOn ? moment(candidateDetails.availableOn).format('MM/DD/YYYY') : MISSING_FIELD}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} className={classes.keyInfo}>
            <span>
              {candidateDetails?.activePlacements && candidateDetails?.activePlacements?.length > 0
                ? `Current Assignment Ends`
                : `Last Assignment Ended`}
            </span>
          </Grid>
          <Grid item xs={6} className={classes.keyInfoValue}>
            {lastAssignmentEnded}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} className={classes.keyInfo}>
            <span>{`Next Placement Starts`}</span>
          </Grid>
          <Grid item xs={6} className={classes.keyInfoValue}>
            {nextPlacementStartDate()}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} className={classes.keyInfo}>
            <span>{`Last Application Date`}</span>
          </Grid>
          <Grid item xs={6} className={classes.keyInfoValue}>
            {candidateDetails?.applicationDate
              ? moment(new Date(candidateDetails?.applicationDate)).format('MM/DD/YYYY') || MISSING_FIELD
              : MISSING_FIELD}
          </Grid>
        </Grid>
      </div>
      <SummaryDivider variant="middle" />
      <SummaryHeader variant="body1">{`Candidate Summary`}</SummaryHeader>
      {summary !== '' ? (
        !showFullSummary ? (
          <>
            <Typography variant="body1" className={classes.summary}>
              {summary.substring(0, 150)}
              {summary && summary.length > 150 && (
                <div
                  role="button"
                  tabIndex={-1}
                  className={classes.showMore}
                  color="primary"
                  onClick={() => setShowFullSummary(true)}
                  onKeyDown={() => setShowFullSummary(true)}
                >{`More...`}</div>
              )}
            </Typography>
          </>
        ) : (
          <Typography variant="body1" className={classes.summary}>
            {summary}
            {summary && summary.length > 250 && (
              <div
                role="button"
                tabIndex={-1}
                className={classes.showMore}
                color="primary"
                onClick={() => setShowFullSummary(false)}
                onKeyDown={() => setShowFullSummary(false)}
              >{`Less...`}</div>
            )}
          </Typography>
        )
      ) : (
        <Typography variant="body1" className={classes.summary}>
          {'NA'}{' '}
        </Typography>
      )}
    </div>
  );
};
