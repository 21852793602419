/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui';
import { CustomFilter } from 'app/ComponentLibrary/Filter';
import {
  pipelineChoiceKeys,
  pipelineChoiceActions,
} from 'app/components/Common/WorkDesk/PipelineChoice/pipelineChoice.redux';
import React, { useState, useEffect } from 'react';
import { createRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { defaultFilterChipSelected } from '../Models/PlacementStatusInterfaces';
import { placementStatusActions } from '../Store/PlacementStatus.redux';
import { selectFilterSpecs } from '../Store/PlacementStatus.selector';
import { defaultFilterValues, getFilterSpecs } from './PlacementFilterConfig';

const useStyles = makeStyles()({
  placementIdField: {
    minWidth: 125,
  },
  candidateNameField: {
    minWidth: 140,
  },
  placementStartDateField: {
    minWidth: 134,
  },
  placementStatusField: {
    minWidth: 150,
  },
  clientNameField: {
    minWidth: 125,
  },
  disciplineSpecialtyField: {
    minWidth: 150,
  },
  candidateIdField: {
    minWidth: 125,
  },
});

export const PlacementFilter = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const filterSpecs = useSelector(selectFilterSpecs);
  const [fields, setFields] = useState<any>(undefined);
  const dispatch = useDispatch();
  const hiddenSubmitButton = createRef<HTMLButtonElement>();

  const {
    setValue,
    formState: { dirtyFields },
  } = useFormContext();

  const resetFilter = () => {
    Object.entries(defaultFilterValues).map(item => setValue(item[0], item[1]));
    dispatch(placementStatusActions.setFilterChipSelected(defaultFilterChipSelected));
    //Reset
    dispatch(placementStatusActions.setCounts(null));
    props.onApplyFilter(defaultFilterValues, defaultFilterChipSelected);
    dispatch(
      pipelineChoiceActions.setPipelineChoice({
        key: pipelineChoiceKeys.CRED_PLACEMENT_STATUS_WDG,
        selection: [],
      }),
    );
  };

  useEffect(() => {
    if (filterSpecs) {
      setFields(getFilterSpecs(filterSpecs, t, classes));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSpecs]);

  const onFilterChangeValues = (fieldName, newValue) => {
    if (['startDate'].includes(fieldName)) {
      //Here we can add date chips if required.
      hiddenSubmitButton.current?.click();
    }
  };

  return (
    <>
      {filterSpecs && fields && (
        <CustomFilter
          fields={fields}
          onResetForm={() => resetFilter()}
          justifyContent={'flex-start'}
          version2
          falseProp={dirtyFields}
          onChangeValues={onFilterChangeValues}
          analytics={{
            caller: 'Credentialing WorkDesk Filter',
            onApply: true,
            properties: {
              category: 'Placement Status',
            },
          }}
        />
      )}
      <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
    </>
  );
};
