import React from 'react';
import { Theme, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { FacilityRoles } from './FacilityRoles/FacilityRoles';
import { AtAGlance } from './AtAGlance/AtAGlance';
import { TechnologyVendor } from './TechVendor/TechnologyVendor';
import { DrugScreenPolicy } from './DrugScreenPolicy/DrugScreenPolicy';
import { FacilityReferences } from './References/References';

export const useFacilityTabStyles = makeStyles<{ size: string }>()((theme: Theme) => ({
  root: {
    width: '100%',
  },
  contentCard: {
    minWidth: '335px',
    alignItems: 'start',
  },
}));

interface Props {
  isCreditHold: boolean;
}

export const FacilityGeneralTab = (props: Props) => {
  const { isCreditHold } = props;
  const { classes } = useFacilityTabStyles({ size: '' });

  return (
    <>
      <div className={`wrap`}>
        <Grid container classes={{ root: classes.root }}>
          <Grid item container className={classes.contentCard} sm={4} xs={4} md={4} lg={4} style={{ paddingRight: '12px' }}>
            <AtAGlance isCreditHold={isCreditHold} />
          </Grid>
          <Grid item container className={classes.contentCard} sm={4} xs={4} md={4} lg={4} style={{ paddingRight: '12px' }}>
            <FacilityRoles />
          </Grid>
          <Grid container item xs={4} direction="column" spacing={2}>
            <Grid item>
              <TechnologyVendor />
            </Grid>
            <Grid item>
              <FacilityReferences />
            </Grid>
            <Grid item>
              <DrugScreenPolicy />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
